import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'

export const getListNews = createAsyncThunk(
  'sector/sectorConstituents/overview/GET_NEWS_OTHER',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SectorConstituentsOverviewService.getListNews(data)
      if (response.success) {
        return { data: response.data, totalPage: response.totalPage }
      }
      return rejectWithValue('Error')
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getListReport = createAsyncThunk(
  'sector/sectorConstituents/overview/GET_LIST_REPORT',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SectorConstituentsOverviewService.getListReport(
        data,
      )
      if (response.success) {
        return { data: response.data, totalPage: response.totalPage }
      }
      return rejectWithValue('Error')
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
