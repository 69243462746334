import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import NetValueBreakdown from './netValueBreakdown'
import {
  selectFilterName,
  selectStateTimeFrame,
} from './netValueBreakdown/store/slice'

const PanelNetValueBreakdown = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const indexInfo = useSelector(selectIndexInfo)
  const nameScreen = UseI18n(
    'constants.route.market.marketInDepth.directorDeal.LABEL',
  )
  const chartName = UseI18n(
    'market.marketInDepth.directorDeal.NET_VALUE_BREAKDOWN',
  )
  const tabName = useSelector(selectStateTimeFrame)
  const currentFilterItemName = useSelector(selectFilterName)

  return (
    <Panel
      title={
        I18n.t('market.marketInDepth.directorDeal.NET_VALUE_BREAKDOWN_OF') +
        ' ' +
        indexInfo.groupName
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={`${UseI18n(
        'market.marketInDepth.directorDeal.INTERNAL_TRADING',
      )} ${UseI18n(currentFilterItemName)} (${indexInfo.groupName})`}
      downloadJpgParams={{
        domId: 'netValueBreakdown',
        nameScreen,
        chartName,
        tabName,
        tickerName: indexInfo?.groupCode,
      }}
    >
      <NetValueBreakdown
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelNetValueBreakdown
