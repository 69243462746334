import { SizeTracker } from '../../../../../common/sizeTracker'
import TopIssuerTab from './TopIssuerTab'
import TopIssuerTable from './TopIssuerTable'

export const Content = ({ width }) => {
  return (
    <div className="h-100">
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopIssuerTab />
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <TopIssuerTable width={width} />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
