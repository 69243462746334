import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { formatDataTable } from '../helper'
import {
  getBondIssueInformationData,
  getCouponInterestRateData,
  getInterestAndPrincipalPaidData,
  getRelativeToEquityData,
  getRemainingMaturitiesData,
} from './thunk'

export const keys = {
  BOND_ISSUE_INFORMATION: 'bondIssueInformation',
  RELATIVE_TO_EQUITY: 'relativeToEquity',
  REMAINING_MATURITIES: 'remainingMaturities',
  COUPON_INTEREST_RATE: 'couponInterestRate',
  INTEREST_AND_PRINCIPAL_PAID: 'interestAndPrincipalPaid',
}

const initialState = {
  loading: {
    [keys.BOND_ISSUE_INFORMATION]: true,
    [keys.RELATIVE_TO_EQUITY]: true,
    [keys.REMAINING_MATURITIES]: true,
    [keys.COUPON_INTEREST_RATE]: true,
    [keys.INTEREST_AND_PRINCIPAL_PAID]: true,
  },
  data: {
    [keys.BOND_ISSUE_INFORMATION]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
    },
    [keys.RELATIVE_TO_EQUITY]: {
      data: [],
      list: [
        'outstandingBond',
        // 'outstandingBond2',
        'issueValue',
        'valueOfCancelBond',
        'valueOfRedemption',
        // 'issueValue2',
        'equity',
        // 'valueOfCancelBond',
      ],
    },
    [keys.REMAINING_MATURITIES]: {
      data: [],
      list: [
        'valueOfOutstandingBond',
        'averageCouponInterestRate',
        'averageCouponInterestRateAllDuration',
      ],
    },
    [keys.COUPON_INTEREST_RATE]: {
      data: [],
      list: ['valueOfOutstandingBond', 'averageDuration', 'averageAllDuration'],
    },
    [keys.INTEREST_AND_PRINCIPAL_PAID]: {
      data: [],
      list: ['principalPaid', 'couponPaid'],
    },
  },
  filter: {
    [keys.BOND_ISSUE_INFORMATION]: {
      CompanyStatus: [15, 22],
      CompanyCollateral: 'All',
      Related: true,
    },
    [keys.RELATIVE_TO_EQUITY]: {
      timeType: 'Quarterly',
    },
    [keys.REMAINING_MATURITIES]: {
      activeTab: 1,
    },
    [keys.INTEREST_AND_PRINCIPAL_PAID]: {
      timeType: 'Monthly',
    },
  },
}

export const slice = createSlice({
  name: 'bond/corporateBond/companyBond',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } =
        state.data[keys.BOND_ISSUE_INFORMATION]

      const sortIds = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      )
      const newIds = sortIds.filter((item) => item !== 'Total')

      state.data[keys.BOND_ISSUE_INFORMATION] = {
        ...state.data[keys.BOND_ISSUE_INFORMATION],
        ids: !!newIds.length ? [...newIds, 'Total'] : [],
      }
    },
    changeFilter: (state, action) => {
      state.filter[action.payload.label] = {
        ...state.filter[action.payload.label],
        [action.payload.key]: action.payload.value,
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBondIssueInformationData.pending, (state) => {
      state.loading[keys.BOND_ISSUE_INFORMATION] = true
    })
    builder.addCase(getBondIssueInformationData.fulfilled, (state, action) => {
      const { data, locale } = action.payload
      const dataFormatted = formatDataTable(data, locale)

      state.data[keys.BOND_ISSUE_INFORMATION] = {
        ...state.data[keys.BOND_ISSUE_INFORMATION],
        data: dataFormatted,
        ids: dataFormatted.map((item) => item.id),
        initialIds: dataFormatted.map((item) => item.id),
        dataById: keyBy(dataFormatted, 'id'),
        levels: dataFormatted,
      }
      state.loading[keys.BOND_ISSUE_INFORMATION] = false
    })
    builder.addCase(getBondIssueInformationData.rejected, (state, action) => {
      state.loading[keys.BOND_ISSUE_INFORMATION] = action.payload
    })
    builder.addCase(getRelativeToEquityData.pending, (state) => {
      state.loading[keys.RELATIVE_TO_EQUITY] = true
    })
    builder.addCase(getRelativeToEquityData.fulfilled, (state, action) => {
      state.data[keys.RELATIVE_TO_EQUITY] = {
        ...state.data[keys.RELATIVE_TO_EQUITY],
        data: action.payload,
      }
      state.loading[keys.RELATIVE_TO_EQUITY] = false
    })
    builder.addCase(getRelativeToEquityData.rejected, (state, action) => {
      state.loading[keys.RELATIVE_TO_EQUITY] = action.payload
    })
    builder.addCase(getRemainingMaturitiesData.pending, (state) => {
      state.loading[keys.REMAINING_MATURITIES] = true
    })
    builder.addCase(getRemainingMaturitiesData.fulfilled, (state, action) => {
      state.data[keys.REMAINING_MATURITIES] = {
        ...state.data[keys.REMAINING_MATURITIES],
        data: action.payload,
      }
      state.loading[keys.REMAINING_MATURITIES] = false
    })
    builder.addCase(getRemainingMaturitiesData.rejected, (state, action) => {
      state.loading[keys.REMAINING_MATURITIES] = action.payload
    })
    builder.addCase(getCouponInterestRateData.pending, (state) => {
      state.loading[keys.COUPON_INTEREST_RATE] = true
    })
    builder.addCase(getCouponInterestRateData.fulfilled, (state, action) => {
      state.data[keys.COUPON_INTEREST_RATE] = {
        ...state.data[keys.COUPON_INTEREST_RATE],
        data: action.payload,
      }
      state.loading[keys.COUPON_INTEREST_RATE] = false
    })
    builder.addCase(getCouponInterestRateData.rejected, (state, action) => {
      state.loading[keys.COUPON_INTEREST_RATE] = action.payload
    })
    builder.addCase(getInterestAndPrincipalPaidData.pending, (state) => {
      state.loading[keys.INTEREST_AND_PRINCIPAL_PAID] = true
    })
    builder.addCase(
      getInterestAndPrincipalPaidData.fulfilled,
      (state, action) => {
        state.data[keys.INTEREST_AND_PRINCIPAL_PAID] = {
          ...state.data[keys.INTEREST_AND_PRINCIPAL_PAID],
          data: action.payload,
        }
        state.loading[keys.INTEREST_AND_PRINCIPAL_PAID] = false
      },
    )
    builder.addCase(
      getInterestAndPrincipalPaidData.rejected,
      (state, action) => {
        state.loading[keys.INTEREST_AND_PRINCIPAL_PAID] = action.payload
      },
    )
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

// Bond Issue Information
export const selectFilterBondIssueInformation = (state) =>
  state[slice.name].filter[keys.BOND_ISSUE_INFORMATION]
export const selectDataBondIssueInformation = (state) =>
  state[slice.name].data[keys.BOND_ISSUE_INFORMATION]
export const selectItemDataTable = (state) =>
  state[slice.name].data[keys.BOND_ISSUE_INFORMATION].dataById

export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.BOND_ISSUE_INFORMATION].dataById[id],
    attr,
  )

// Relative To Equity
export const selectFilterRelativeToEquity = (state) =>
  state[slice.name].filter[keys.RELATIVE_TO_EQUITY]
export const selectDataRelativeToEquity = (state) =>
  state[slice.name].data[keys.RELATIVE_TO_EQUITY]

// Remaining Maturities And Coupon Interest Rate
export const selectFilterRemainingMaturities = (state) =>
  state[slice.name].filter[keys.REMAINING_MATURITIES]
export const selectDataRemainingMaturities = (state) =>
  state[slice.name].data[keys.REMAINING_MATURITIES]
export const selectDataCouponInterestRate = (state) =>
  state[slice.name].data[keys.COUPON_INTEREST_RATE]

// Interest And Principal Paid
export const selectFilterInterestAndPrincipalPaid = (state) =>
  state[slice.name].filter[keys.INTEREST_AND_PRINCIPAL_PAID]
export const selectDataInterestAndPrincipalPaid = (state) =>
  state[slice.name].data[keys.INTEREST_AND_PRINCIPAL_PAID]

export const { resetStore, changeFilter, sort } = slice.actions

register(slice.name, slice.reducer)
