import { useEffect, useMemo, useState } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../common/html/Button'
import { uuid } from '../../../utils/Common'
import { randomColor } from '../../chart/helper'
import {
  changeChartTabIndicatorSelected,
  changeChartTabSecurities,
  changeInListYAxisCustom,
  changeTreeSecuritiesIndicator,
  selectChartTabIndicatorSelected,
  selectEquations,
  selectTreeSecuritiesIndicator,
  selectTypeChart,
  selectViewType,
} from '../../chart/store/slice'
import { LIST_TYPE, SEC_GROUP } from '../../common/constants'
import { getUsedColorsBySecuritiesAndEquations } from '../../common/helpers/colorHelper'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { changeSecurities } from '../common/store/slice'
import { POSITION } from '../popupChangeYAxisPosition'
import styles from '../styleTheme.module.css'
import { getSecuritiesIndicatorOnApply } from './helper'
import {
  addIndicator,
  removeIndicator,
  selectDataLatestPeriod,
} from './store/slice'
import style from './style.module.css'

export const PopupFooter = ({
  typeIndicator,
  itemChoose,
  onCancel,
  indicatorItemReplace,
  category,
}) => {
  const dispatch = useDispatch()

  const { favoriteColors, defaultChartSettings } = useGetUserSettings()

  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const equations = useSelector(selectEquations)
  const indicatorSelected = useSelector(selectChartTabIndicatorSelected())
  const typeChart = useSelector(selectTypeChart)
  const viewType = useSelector(selectViewType)
  const dataLatestPeriod = useSelector(selectDataLatestPeriod)

  const [isFirstMount, setFirstMount] = useState(true)

  const getUsedColors = useMemo(() => {
    return getUsedColorsBySecuritiesAndEquations(
      treeSecuritiesIndicator,
      equations,
    )
  }, [treeSecuritiesIndicator, equations])

  const changeSyncSecurities = (typeSecurities, value) => {
    dispatch(
      changeTreeSecuritiesIndicator({
        ...treeSecuritiesIndicator,
        [typeSecurities]: value,
      }),
    )
    dispatch(
      changeSecurities({
        typeSecurities,
        value: Object.keys(value).map((val) => +val),
      }),
    )
    dispatch(
      changeChartTabSecurities({
        [typeSecurities]: Object.keys(value).map((val) => +val),
      }),
    )
  }

  const handleApply = () => {
    const uniqueID = uuid()
    if (Object.keys(itemChoose).length) {
      const payload = {
        ...itemChoose,
        value: {
          ...itemChoose.value,
          parentId: category,
          dataLatestPeriod,
          color: randomColor(getUsedColors, favoriteColors),
          uniqueID,
        },
      }

      const result = getSecuritiesIndicatorOnApply({
        item: payload,
        treeSecuritiesIndicator,
        typeIndicator,
        typeChart,
        viewType,
        usedColors: getUsedColors,
        indicatorItemReplace,
        favoriteColors,
        isOrientationLeft:
          defaultChartSettings?.settings?.positionGroupXAxis === POSITION.LEFT,
      })

      switch (typeIndicator) {
        case SEC_GROUP.COMPANY:
          changeSyncSecurities(LIST_TYPE.COMPANIES, result)
          break
        case SEC_GROUP.INDEX:
          changeSyncSecurities(LIST_TYPE.INDICES, result)
          break
        case SEC_GROUP.SECTOR:
          changeSyncSecurities(LIST_TYPE.SECTOR, result)
          break
        default:
          break
      }
      if (indicatorItemReplace) {
        if (indicatorItemReplace?.uniqueID) {
          dispatch(
            removeIndicator({
              indicatorId: +indicatorItemReplace.id,
              indicatorTimeFrequency: indicatorItemReplace.timeFrequency,
              typeIndicator: typeIndicator,
              uniqueID: indicatorItemReplace?.uniqueID,
            }),
          )
          dispatch(
            changeChartTabIndicatorSelected({
              [payload.typeIndicator]: [
                ...indicatorSelected[payload.typeIndicator].filter(
                  (item) =>
                    item.id !== indicatorItemReplace.id &&
                    item.uniqueID !== indicatorItemReplace?.uniqueID,
                ),
                payload.value,
              ],
            }),
          )
          dispatch(
            changeInListYAxisCustom({
              replaceId: [
                indicatorItemReplace.id,
                indicatorItemReplace?.timeFrequency,
                indicatorItemReplace?.uniqueID,
              ].join('_'),
              updateId: [
                itemChoose?.value?.id,
                itemChoose?.value?.timeFrequency,
                indicatorItemReplace?.uniqueID,
              ].join('_'),
            }),
          )
        } else {
          dispatch(
            removeIndicator({
              indicatorId: +indicatorItemReplace.id,
              indicatorTimeFrequency: indicatorItemReplace.timeFrequency,
              typeIndicator: typeIndicator,
            }),
          )
          dispatch(
            changeChartTabIndicatorSelected({
              [payload.typeIndicator]: [
                ...indicatorSelected[payload.typeIndicator].filter(
                  (item) => item.id !== indicatorItemReplace.id,
                ),
                payload.value,
              ],
            }),
          )
          dispatch(
            changeInListYAxisCustom({
              replaceId: [
                indicatorItemReplace.id,
                indicatorItemReplace?.timeFrequency,
              ].join('_'),
              updateId: [
                itemChoose?.value?.id,
                itemChoose?.value?.timeFrequency,
              ].join('_'),
            }),
          )
        }
      } else {
        dispatch(
          changeChartTabIndicatorSelected({
            [payload.typeIndicator]: [
              ...indicatorSelected[payload.typeIndicator],
              payload.value,
            ],
          }),
        )
      }
      dispatch(addIndicator(payload))
      onCancel()
    }
  }

  useEffect(() => {
    if (
      isFirstMount &&
      JSON.stringify(indicatorItemReplace) !== JSON.stringify(itemChoose.value)
    ) {
      setFirstMount(false)
    }
  }, [itemChoose])

  return (
    <div className={style.groupButtonModal}>
      <Button
        className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
        onClick={onCancel}
      >
        <Translate value={'common.button.BUTTON_CANCEL'} />
      </Button>
      <Button
        className={`btn w-80 h-20 ${styles.buttonBlueStyle}`}
        onClick={handleApply}
      >
        <Translate value={'common.button.BUTTON_APPLY'} />
      </Button>
    </div>
  )
}
