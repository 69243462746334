import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import TimeFrame from '../../common/TimeFrame'
import { getTimeRange as getTimeFrequency } from '../panelExchangeRate/store/slice'
import {
  DROPDOWN_OPTION,
  TIME_FRAME_OPTIONS_DAILY,
  TIME_FRAME_OPTIONS_MONTHLY,
  TIME_FRAME_OPTIONS_WEEKLY,
} from './constants'
import {
  getDropdownValue,
  getTimeRangeDaily,
  getTimeRangeMonthly,
  getTimeRangeWeekly,
  handleDropdownValue,
  handleTimeRangeDaily,
  handleTimeRangeMonthly,
  handleTimeRangeWeekly,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const dropdownValue = useSelector(getDropdownValue)
  const timeFrequency = useSelector(getTimeFrequency)
  const timeRangeDaily = useSelector(getTimeRangeDaily)
  const timeRangeWeekly = useSelector(getTimeRangeWeekly)
  const timeRangeMonthly = useSelector(getTimeRangeMonthly)

  let timeRange = undefined
  let changeTimeRange = undefined
  let timeFrameOption = undefined

  switch (timeFrequency) {
    case 'Daily':
      timeRange = timeRangeDaily
      changeTimeRange = (tab) => {
        dispatch(handleTimeRangeDaily(tab.value))
      }
      timeFrameOption = TIME_FRAME_OPTIONS_DAILY
      break
    case 'Weekly':
      timeRange = timeRangeWeekly
      changeTimeRange = (tab) => {
        dispatch(handleTimeRangeWeekly(tab.value))
      }
      timeFrameOption = TIME_FRAME_OPTIONS_WEEKLY
      break
    default:
      timeRange = timeRangeMonthly
      changeTimeRange = (tab) => {
        dispatch(handleTimeRangeMonthly(tab.value))
      }
      timeFrameOption = TIME_FRAME_OPTIONS_MONTHLY
      break
  }

  const changeDropdownValue = (value) => {
    dispatch(handleDropdownValue(value))
  }

  return (
    <div className="d-flex align-center j-b">
      <div style={{ width: 182 }}>
        <SelectCustom
          isI18n={true}
          selectData={DROPDOWN_OPTION}
          value={dropdownValue}
          handleChange={changeDropdownValue}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <TimeFrame
        timeRange={timeRange}
        onChangeTab={changeTimeRange}
        timeFrameOption={timeFrameOption}
      />
    </div>
  )
}

export default Header
