import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import useGetExchange from '../../../common/masterData/useGetExchange'
import useGetICB from '../../../common/masterData/useGetICB'
import Popup from '../../../common/popup'
import TextEllipsis from '../../../common/textEllipsis'
import { keyBy } from '../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import { convertDataSubmit } from '../helper'
import style from '../index.module.css'
import {
  key,
  selectAddedPeriod,
  selectAddMoreConditionByIds,
  selectAddMoreConditionIds,
  selectChecked,
  selectConditionIds,
  selectConditions,
  selectParentChecked,
  selectScreenerDate,
} from '../store/slice'
import { saveTemplateThunk } from '../store/thunk'

const PopupSaveTemplate = ({ handleHidePopupSaveAs, templates }) => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const { ICBs } = useGetICB()
  const { exchanges } = useGetExchange()

  const sectorsParent = useSelector(selectParentChecked(key.SECTOR))
  const sectorsChildren = useSelector(selectChecked(key.SECTOR))
  const exchangesParent = useSelector(selectParentChecked(key.EXCHANGE))
  const exchangesChildren = useSelector(selectChecked(key.EXCHANGE))
  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)
  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)
  const addedPeriod = useSelector(selectAddedPeriod)
  const screenerDate = useSelector(selectScreenerDate)
  const conditionIds = useSelector(selectConditionIds)
  const conditions = useSelector(selectConditions)

  const [templateName, setTemplateName] = useState('')
  const [error, setError] = useState()
  const [ICBsById, setICBsById] = useState({})
  const [exchangesById, setExchangesById] = useState({})

  const sectors = sectorsParent.concat(sectorsChildren)
  const exchangesChecked = exchangesParent.concat(exchangesChildren)

  useEffect(() => {
    setICBsById(keyBy(ICBs, 'icbId'))
  }, [ICBs])

  useEffect(() => {
    setExchangesById(keyBy(exchanges, 'groupId'))
  }, [exchanges])

  const handleChangeInput = (e) => {
    const value = e.target.value
    setTemplateName(value)
    if (value === '') {
      setError('tool.smartScreening.TEMPLATE_REQUIRE')
    } else if (
      templates.some((template) => template.templateName === value.trim())
    ) {
      setError('tool.smartScreening.TEMPLATE_EXIST_2')
    } else {
      setError(null)
    }
  }

  const handleSaveTemplate = () => {
    handleHidePopupSaveAs()
    dispatch(
      saveTemplateThunk({
        parameter: convertDataSubmit({
          ICBsById,
          sectors,
          exchangesById,
          exchanges: exchangesChecked,
          screenerDate: getISOStartOrEndOfDay(screenerDate, timezone, true),
          conditionIds,
          conditions,
          addMoreConditionIds,
          addMoreConditionByIds,
          addedPeriod,
        }),
        templateName,
      }),
    )
  }

  const disabledButton = error !== null

  return (
    <Popup>
      <div className="modal modal-small w-450">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.smartScreening.BTN_SAVE_AS" />
            </Span>
          </h3>
          <a onClick={handleHidePopupSaveAs} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className="content">
            <div className="form-control form-vertical ali-center j-b">
              <label className={`mb-0 ${style.labelPopup}`}>
                <Span>
                  <TextEllipsis text="tool.smartScreening.LABEL_NAME_TEMPLATE" />
                </Span>
              </label>
              <input
                value={templateName}
                type="text"
                className={`w-300 bg-grey-4 ${style.inputPopup}`}
                onChange={handleChangeInput}
              />
            </div>
            {error && (
              <div className="text-error t-right">
                <Span>
                  <Translate value={error} />
                </Span>
              </div>
            )}
          </div>
          <div className="mt-40 group-btn justify-content-end">
            <Button
              onClick={handleHidePopupSaveAs}
              className={`btn normal h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              onClick={handleSaveTemplate}
              disabled={disabledButton}
              className={`btn btn-blue ${
                disabledButton ? 'diasble' : ''
              } h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_SAVE" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupSaveTemplate.propTypes = {
  handleHidePopupSaveAs: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
}

export default PopupSaveTemplate
