import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../../utils/Value'
import { tableConstants } from '../constants'
import { formatTableData } from '../helper'
import { getDataTable } from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/bank/assetQuality/dataTable',
  initialState: {
    ids: tableConstants.map((item) => item.id),
    dataByIds: {},
    data: [],
    groupColumns: [],
    loading: true,
    timeFrequency: 'Yearly',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDataTable.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataTable.fulfilled, (state, action) => {
      const { groupColumns, valueByIds } = formatTableData(action)

      state.data = action.payload
      state.groupColumns = groupColumns
      state.dataByIds = valueByIds
      state.loading = false
    })
    builder.addCase(getDataTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectData = (state) => state[slice.name].data
export const selectDataByIds = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[id], attr)
export const selectTableLoading = (state) => state[slice.name].loading
export const selectTimeFrequency = (state) => state[slice.name].timeFrequency

register(slice.name, slice.reducer)
