import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../utils/EventEmitter'
import { ScrollComponent } from '../../ScrollComponent'
import { Input } from '../../html/Input'
import { Span } from '../../html/Span'
import { Loading } from '../../loading'
import PopupEvent from '../../popup/PopupEvent'
import { SizeTracker } from '../../sizeTracker'
import TextEllipsis from '../../textEllipsis'
import style from './index.module.css'

const idDivFooterScroll = 'div-footer-scroll'

const enterKey = 13

const ItemTemplate = ({
  item,
  index,
  listFullTemplate,
  handleRenameTemplate,
  handleDeleteTemplate,
  handleGetTemplate,
  handleGetFiinXTemplate,
  isFiinXTemplate,
}) => {
  const inputRef = useRef()
  const templateRef = useRef()

  const [isHover, setIsHover] = useState(false)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isDropdownTop, setIsDropdownTop] = useState(false)
  const [isShowEdit, setIsShowEdit] = useState(false)
  const [valueInput, setValueInput] = useState(item.templateName)
  const [error, setError] = useState(null)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const handleMouseOver = () => {
    setIsHover(true)
  }

  const handleMouseOut = () => setIsHover(false)

  const handleOpenDropdown = () => {
    const heightFromFooter =
      document.querySelector(`#${idDivFooterScroll}`).getBoundingClientRect()
        .top - templateRef.current.getBoundingClientRect().top
    if (heightFromFooter <= 130) setIsDropdownTop(true)
    else setIsDropdownTop(false)

    setIsShowDropdown(true)
  }

  const handleCloseDropdown = () => setIsShowDropdown(false)

  const handleShowEdit = () => {
    setIsShowEdit(true)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    setValueInput(value)
    if (
      listFullTemplate.current.some(
        (template) =>
          template.templateName === value.trim() &&
          template.templateId !== item.templateId,
      )
    )
      setError('tool.smartScreening.TEMPLATE_EXIST')
    else setError(null)
  }

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (
      valueInput.trim() &&
      !error &&
      valueInput.trim() !== item.templateName
    ) {
      handleRenameTemplate({ newName: valueInput, item })
    } else {
      setValueInput(item.templateName)
      setError(null)
    }
    setIsShowEdit(false)
    setIsHover(false)
    setIsShowDropdown(false)
  }

  const getTemplate = () => {
    if (!isFiinXTemplate) {
      handleGetTemplate(item)
    } else {
      handleGetFiinXTemplate(item)
    }
  }

  const deleteTemplate = () => {
    handleDeleteTemplate(item)
    hidePopupDelete()
  }

  const openPopupDelete = () => setIsShowPopupDelete(true)

  const hidePopupDelete = () => setIsShowPopupDelete(false)

  const copyClipBoard = () => {
    setIsShowDropdown(false)
    const shareLink =
      window.location.origin +
      window.location.pathname +
      '?shareCode=' +
      item.shareCode
    copy(shareLink)

    // Popup
    EventEmitter.dispatch(
      BUTTON_TEXT_POPUP_ERROR,
      I18n.t('common.button.BUTTON_OK'),
    )
    EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
    EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
    EventEmitter.dispatch(SHOW_POPUP_ERROR, [
      <div>
        <div className="centerItems mb-20">
          <img src="/iconLike.svg" alt="Like" width={38} height={46} />
        </div>
        <div className="centerItems">
          <Translate value="tool.dataExplorer.COPY_TEMPLATE_SUCCESS" />
        </div>
        <Input
          ref={inputRef}
          type="text"
          value={shareLink}
          className={style.copyLinkInput}
          readOnly
        />
      </div>,
    ])
  }

  return (
    <>
      <div
        ref={templateRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        className={`item-temp d-flex ${isHover ? 'hover' : ''} ${
          style.itemTemplate
        }`}
      >
        <span>{index + 1}.</span>
        {isShowEdit ? (
          <>
            <div className={`position-relative ${style.w88}`}>
              <Input
                onBlur={handleSubmit}
                onKeyDown={handlePressEnter}
                ref={inputRef}
                type="text"
                value={valueInput}
                onChange={handleChangeInput}
                className={`${style.inputTemplate} w-100`}
              />
              {error && (
                <div
                  style={{ maxWidth: `${inputRef.current?.offsetWidth}px` }}
                  className={`box-notice ${
                    ![0, 1].includes(index)
                      ? style.tooltipError
                      : style.tooltipErrorBottom
                  }`}
                >
                  <Span style={{ fontSize: 9 }}>
                    {<Translate value={error} dangerousHTML />}
                  </Span>
                </div>
              )}
            </div>
            <button
              onClick={handleSubmit}
              className={`btn-ok cursor-pointer ${style.btnOk}`}
            >
              <i className="icon-true" />
            </button>
          </>
        ) : (
          <div
            className={`${style.nameTemplate} ${style.w88} d-flex ali-center`}
          >
            <div
              onDoubleClick={getTemplate}
              className={`cursor-pointer ${style.textTemplate}`}
            >
              <TextEllipsis isI18n={false} text={item.templateName} />
            </div>
            {!isFiinXTemplate && (
              <div
                onMouseEnter={handleOpenDropdown}
                onMouseLeave={handleCloseDropdown}
                className="hover-dropdown d-flex justify-content-center"
                style={{ display: isShowDropdown ? 'block' : undefined }}
              >
                <button className="btn-more">
                  <i className="icon-menu-dot-hor" />
                </button>
                <div
                  className={`dropdown-container ${
                    isShowDropdown ? 'active' : ''
                  } ${isDropdownTop ? style.dropdownTop : ''}`}
                >
                  <ul>
                    <li>
                      <button
                        onClick={handleShowEdit}
                        className="btn-watchlist"
                      >
                        <i className="icon-edit" />
                        <Translate value="tool.smartScreening.RENAME" />
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={openPopupDelete}
                        className="btn-watchlist"
                      >
                        <i className="icon-trash" />
                        <Translate value="tool.smartScreening.DELETE" />
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={copyClipBoard}
                        className="btn-watchlist d-flex ali-center"
                      >
                        <i className={`icon-duplicate ${style.iconCopyLink}`} />
                        <TextEllipsis text="tool.dataExplorer.SHARE_TEMPLATE" />
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {isShowPopupDelete && (
        <PopupEvent
          title={
            <Translate value="tool.smartScreening.TITLE_DELETE_TEMPLATE" />
          }
          message={
            <>
              <Translate value="tool.smartScreening.ASK_DELETE_TEMPLATE" />{' '}
              {item.templateName}
            </>
          }
          isShow={isShowPopupDelete}
          handleClose={hidePopupDelete}
          btnCancel={{
            btnName: <Translate value="common.button.BUTTON_DELETE" />,
            event: deleteTemplate,
          }}
          btnSubmit={{
            btnName: <Translate value="common.button.BUTTON_CANCEL" />,
            event: hidePopupDelete,
          }}
        />
      )}
    </>
  )
}

ItemTemplate.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  listFullTemplate: PropTypes.object.isRequired,
}

const typeTemplates = [
  { value: 1, label: 'tool.dataExplorer.PERSONAL' },
  { value: 2, label: 'tool.dataExplorer.FIINX_TEMPLATE' },
]

const Template = ({
  templates,
  fiinXTemplates,
  handleRenameTemplate,
  handleDeleteTemplate,
  handleGetTemplate,
  loading,
  isEconomy,
  isHasFiinXTemplate,
  handleGetFiinXTemplate,
}) => {
  const listFullTemplate = useRef([])

  const [valueSearch, setValueSearch] = useState('')
  const [typeTemplate, setTypeTemplate] = useState(typeTemplates[0].value)

  useEffect(() => {
    if (listFullTemplate.current.length === 0) {
      listFullTemplate.current = templates
    }
  }, [templates])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const isFiinXTemplate = typeTemplate === typeTemplates[1].value

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div className="h-100 nav-box-tab mt-0">
            <div
              className={`form-search-inbox mb-10 ${
                isEconomy ? style.searchInputEconomy : ''
              }`}
            >
              <Input
                type="text"
                value={valueSearch}
                onChange={handleChangeInput}
                className={`search-inbox bg-grey h-20 ${style.searchInput}`}
                placeholder={I18n.t('tool.smartScreening.SEARCH')}
              />
              <button type="button" className={style.searchButton}>
                <i className="icon-search-2" />
              </button>
            </div>
            {isHasFiinXTemplate && (
              <div className="mt-8 d-flex j-b">
                {typeTemplates.map((item) => (
                  <label
                    key={item.value}
                    onClick={() => setTypeTemplate(item.value)}
                    className={`cursor-pointer d-flex ali-center ${
                      typeTemplate === item.value ? style.radioActive : ''
                    }`}
                  >
                    <input
                      type="radio"
                      className={`radiobox radiobox2 cursor-pointer ${style.radio}`}
                      checked={typeTemplate === item.value}
                      readOnly
                    />
                    <Translate value={item.label} />
                  </label>
                ))}
              </div>
            )}
            {size.height && (
              <div
                className="box_template position-relative mt-8"
                style={{ height: `calc(100% - ${size.height + 8}px)` }}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <ScrollComponent>
                    <div className="lst-temp">
                      {(!isFiinXTemplate ? templates : fiinXTemplates)
                        .filter((item) => {
                          return valueSearch.trim()
                            ? item.templateName
                                .toUpperCase()
                                .includes(valueSearch.trim().toUpperCase())
                            : true
                        })
                        .map((item, index) => {
                          return (
                            <ItemTemplate
                              key={item.templateId}
                              item={item}
                              index={index}
                              valueSearch={valueSearch}
                              listFullTemplate={listFullTemplate}
                              handleRenameTemplate={handleRenameTemplate}
                              handleDeleteTemplate={handleDeleteTemplate}
                              handleGetTemplate={handleGetTemplate}
                              handleGetFiinXTemplate={handleGetFiinXTemplate}
                              isFiinXTemplate={isFiinXTemplate}
                            />
                          )
                        })}
                    </div>
                  </ScrollComponent>
                )}
                <div id={idDivFooterScroll} />
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}

export default Template
