import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { PanelBreakEvenPoint } from './breakEvenPoint/PanelBreakEvenPoint'
import PanelCharting from './charting/PanelCharting'
import { PanelPriceDepth } from './priceDepth/PanelPriceDepth'
import { Summary } from './summary'
import PanelTimeAndSales from './timeSale/PanelTimeAndSales'

const MAP_KEY = {
  SUMMARY: 'SUMMARY',
  BREAK_EVEN_POINT: 'BREAK_EVEN_POINT',
  PRICE_DEPTH: 'PRICE_DEPTH',
  CHARTING: 'CHARTING',
  TIME_SALE: 'TIME_SALE',
}

const MIN_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 2.6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.BREAK_EVEN_POINT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4.2) / 10 - COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.PRICE_DEPTH}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.2) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.CHARTING}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.8) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.TIME_SALE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.2) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MIN_WIDTH_RESPONSIVE =
  MIN_SIZE[MAP_KEY.SUMMARY].minWidth +
  MIN_SIZE[MAP_KEY.BREAK_EVEN_POINT].minWidth +
  MIN_SIZE[MAP_KEY.PRICE_DEPTH].minWidth +
  COMPONENT.MARGIN * 2

const MAP_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: `calc(26% - ${COMPONENT.MARGIN}px)`,
    height: `calc(48% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.BREAK_EVEN_POINT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.BREAK_EVEN_POINT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.PRICE_DEPTH,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CHARTING,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TIME_SALE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.BREAK_EVEN_POINT}`]: {
    width: `calc(42% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(48% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(26% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.BREAK_EVEN_POINT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.BREAK_EVEN_POINT}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 2.6) / 10 + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_DEPTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRICE_DEPTH}`]: {
    width: `calc(32% - ${COMPONENT.MARGIN}px)`,
    height: `calc(48% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(68% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.PRICE_DEPTH}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRICE_DEPTH}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.8) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHARTING}`]: {
    width: `calc(68% - ${COMPONENT.MARGIN}px)`,
    height: `calc(52% - ${COMPONENT.MARGIN}px)`,
    top: `calc(48% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.CHARTING}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.CHARTING}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.TIME_SALE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TIME_SALE}`]: {
    width: `calc(32% - ${COMPONENT.MARGIN}px)`,
    height: `calc(52% - ${COMPONENT.MARGIN}px)`,
    top: `calc(48% + ${COMPONENT.MARGIN}px)`,
    left: `calc(68% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.TIME_SALE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.TIME_SALE}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.8) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const MIN_SIZE_SETTING_SCREEN_SMALL = {
  [MAP_KEY.SUMMARY]: {
    minWidth: 418,
    minHeight: 298,
  },
  [MAP_KEY.BREAK_EVEN_POINT]: {
    minWidth: 418,
    minHeight: 298,
  },
  [MAP_KEY.PRICE_DEPTH]: {
    minWidth: 418,
    minHeight: 298,
  },
  [MAP_KEY.TIME_SALE]: {
    minWidth: 418,
    minHeight: 298,
  },
  [MAP_KEY.CHARTING]: {
    minWidth: 840,
    minHeight: 398,
  },
}

const MAP_SIZE_SCREEN_SMALL = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(30% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SUMMARY],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.BREAK_EVEN_POINT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.BREAK_EVEN_POINT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.PRICE_DEPTH,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TIME_SALE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.BREAK_EVEN_POINT}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(30% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.BREAK_EVEN_POINT],
    minTop: 0,
    minLeft: MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SUMMARY] + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRICE_DEPTH}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(30% - ${COMPONENT.MARGIN}px)`,
    top: `calc(30% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.PRICE_DEPTH],
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SUMMARY].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.TIME_SALE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.TIME_SALE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CHARTING,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.TIME_SALE}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(30% - ${COMPONENT.MARGIN}px)`,
    top: `calc(30% + ${COMPONENT.MARGIN}px)`,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.TIME_SALE],
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.BREAK_EVEN_POINT].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.PRICE_DEPTH] + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHARTING}`]: {
    width: `100%`,
    height: `calc(40% - ${COMPONENT.MARGIN}px)`,
    top: `calc(60% + 2 * ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.CHARTING],
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SUMMARY].minHeight +
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.PRICE_DEPTH].minHeight +
      4 * COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.PRICE_DEPTH,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TIME_SALE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <Panel
          title="market.coveredWarrant.SUMMARY"
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SUMMARY}
          sizes={sizes}
          setSizes={setSizes}
        >
          <Summary />
        </Panel>

        <PanelBreakEvenPoint
          mapKey={MAP_KEY}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelPriceDepth
          panelKey={MAP_KEY.PRICE_DEPTH}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelCharting
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CHARTING}
          sizes={sizes}
          setSizes={setSizes}
          windowZoom={true}
        />

        <PanelTimeAndSales
          panelKey={MAP_KEY.TIME_SALE}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SCREEN_SMALL,
  MIN_WIDTH_RESPONSIVE,
)(Content)
