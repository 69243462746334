import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { handleActiveTemplate } from '../../template/store/slice'
import {
  getListDisplay,
  resetListDisplay,
} from '../companyNews/company/store/slice'
import {
  getListChecked as getListCheckedCorporate,
  resetCorporateNews,
} from '../companyNews/corporateNews/store/slice'
import {
  getListChecked as getListCheckedMarket,
  resetMarketNews,
} from '../companyNews/marketNews/store/slice'
import style from './index.module.css'
import SaveButton from './SaveButton'
import { getDate, handleDate, handleLoading } from './store/slice'
import { downloadNews } from './store/thunk'

const GroupButton = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const listDisplay = useSelector(getListDisplay)
  const listCheckedCorporate = useSelector(getListCheckedCorporate)
  const listCheckedMarket = useSelector(getListCheckedMarket)
  const date = useSelector(getDate)
  const currentDate = getISOStartOrEndOfDay(date, timeZone)

  const organizationIds = Object.keys(listDisplay).filter(
    (id) => listDisplay[id].isDisplay && listDisplay[id].isChecked,
  )
  const corporateIds = Object.keys(listCheckedCorporate).filter(
    (id) => listCheckedCorporate[id],
  )
  const marketIds = Object.keys(listCheckedMarket).filter(
    (id) => listCheckedMarket[id],
  )

  const templateParameters = JSON.stringify({
    organizationIds,
    corporateIds,
    marketIds,
  })

  const handleExportNews = async () => {
    const params = {
      organizationIds: organizationIds.toString(),
      newsIds: [...corporateIds, marketIds].toString(),
      publicDate: currentDate,
    }

    dispatch(handleLoading(true))
    await dispatch(downloadNews(params))
    dispatch(handleLoading(false))
  }

  const handleReset = () => {
    dispatch(resetMarketNews())
    dispatch(resetCorporateNews())
    dispatch(resetListDisplay())
    dispatch(handleDate(new Date()))
    dispatch(handleActiveTemplate(null))
  }

  return (
    <div className={style.wrapGroupButton}>
      <div>
        <button onClick={handleReset}>
          <Span>
            <Translate value={'newsReport.dailyReport.dailyReport.RESET'} />
          </Span>
        </button>
        <SaveButton
          templateParameters={templateParameters}
          date={currentDate}
        />
        <button className={style.exportButton} onClick={handleExportNews}>
          <Span>
            <i className="icon-print" style={{ marginRight: 8 }} />
            <Translate value={'newsReport.dailyReport.dailyReport.EXPORT'} />
          </Span>
        </button>
      </div>
    </div>
  )
}

export default GroupButton
