import TextEllipsis from '../../../../common/textEllipsis'
import useWindowDevicePixelRatio from '../../../hooks/useWindowDevicePixelRatio'
import style from './style.module.css'

const Item = ({
  item,
  nameKey,
  formatName,
  color,
  appendStyle,
  showBtnDelete,
  isDashed,
  isFullScreen,
  onDelete,
  onHover,
  onUnHover,
}) => {
  const { reverseFormatWithZoom } = useWindowDevicePixelRatio()

  const onClick = () => {
    if (showBtnDelete) {
      onDelete && onDelete(item)
    }
  }

  const handleHoverItem = () => {
    onHover && onHover(item)
  }

  const handleUnHoverItem = () => {
    onUnHover && onUnHover(item)
  }

  return (
    <div
      style={{
        ...appendStyle,
        borderRadius: reverseFormatWithZoom(4),
        borderWidth: reverseFormatWithZoom(1),
      }}
      className={style.item}
      onMouseEnter={handleHoverItem}
      onMouseLeave={handleUnHoverItem}
    >
      {isDashed && isDashed(item) ? (
        <div className={`${style.color} d-flex j-b`} style={{ width: 13 }}>
          <div
            className={style.colorDashed}
            style={{ backgroundColor: color(item) }}
          />
          <div
            className={style.colorDashed}
            style={{ backgroundColor: color(item) }}
          />
          <div
            className={style.colorDashed}
            style={{ backgroundColor: color(item) }}
          />
        </div>
      ) : (
        <div className={style.color} style={{ backgroundColor: color(item) }} />
      )}
      <div
        className={style.content}
        style={!isFullScreen ? { maxWidth: '100px' } : {}}
      >
        <TextEllipsis
          isI18n={false}
          text={formatName ? formatName(item) : item[nameKey]}
          zIndexTooltip={999}
          className="canvas-chart-footer"
        />
      </div>
      <i
        data-html2canvas-ignore="true"
        className={`icon-delete-stroke ${style.iconDelete}`}
        style={{
          cursor: showBtnDelete ? 'pointer' : 'default',
          opacity: showBtnDelete ? 1 : 0,
        }}
        onClick={onClick}
      />
    </div>
  )
}

export default Item
