import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Loading } from '../../../../../common/loading'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime'
import { indicators, quarters } from '../../constants'
import style from '../style.module.css'
import { selectListSector, selectSectorLever } from '../tableSector/store/slice'
import ChartComponent from './ChartComponent'
import { IndicatorSelectOption } from './IndicatorSelectOption'
import { QuarterSelectOption } from './QuarterSelectOption'
import { YearSelectOption } from './YearSelectOption'
import {
  changeQuarter,
  changeYear,
  clearDataChart,
  selectDataChartValuation,
  selectIndicator,
  selectLoadingValuationChart,
  selectQuarter,
  selectYear,
} from './store/slice'
import { getValuation } from './store/thunk'

export const Valuation = ({ width, height }) => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const maxMinTable = maxMinTableById['FGFB_CORA_DG_FinancialRatioDailyTTMICB']

  const locale = useSelector((state) => state.i18n.locale)
  const isLoadingValuation = useSelector(selectLoadingValuationChart)
  const quarter = useSelector(selectQuarter)
  const year = useSelector(selectYear)
  const indicator = useSelector(selectIndicator)
  const dataValuation = useSelector(selectDataChartValuation)
  const listSector = useSelector(selectListSector)
  const levelSector = useSelector(selectSectorLever)

  useEffect(() => {
    if (listSector.length && quarter && year) {
      const params = {
        icbIds: listSector.map((sector) => sector.icbId),
        quarter: quarter,
        year: year,
      }
      dispatch(getValuation(params))
    } else {
      dispatch(clearDataChart())
    }
  }, [
    JSON.stringify(listSector.map((item) => item.icbId)),
    quarter,
    year,
    locale,
  ])

  useEffect(() => {
    if (maxMinTable) {
      dispatch(changeQuarter(maxMinTable.max_LengthReport || quarters.ANNUAL))
      dispatch(
        changeYear(
          maxMinTable.max_YearReport || Number(getCurrentDateTime(FORMAT.YEAR)),
        ),
      )
    }
  }, [maxMinTable])

  const getChartKey = () => {
    if (indicator === indicators['P/E']) {
      return {
        barKeyMin: 'minRTD0022',
        barKeyMax: 'maxRTD0022',
        barKey: 'rangeRTD0022',
        scatterDiamondKey: 'rtD0022',
        scatterLineKey: 'avgRTD0022',
      }
    } else if (indicator === indicators['P/B']) {
      return {
        barKeyMin: 'minRTD0019',
        barKeyMax: 'maxRTD0019',
        barKey: 'rangeRTD0019',
        scatterDiamondKey: 'rtD0019',
        scatterLineKey: 'avgRTD0019',
      }
    } else {
      return {
        barKeyMin: 'minRTD0027',
        barKeyMax: 'maxRTD0027',
        barKey: 'rangeRTD0027',
        scatterDiamondKey: 'rtD0027',
        scatterLineKey: 'avgRTD0027',
      }
    }
  }

  const dataChart = dataValuation.map((item) => ({
    ...item,
    icbName: item.icbName + ' L' + levelSector,
    rangeRTD0022: [item.minRTD0022, item.maxRTD0022],
    rangeRTD0019: [item.minRTD0019, item.maxRTD0019],
    rangeRTD0027: [item.minRTD0027, item.maxRTD0027],
  }))

  return (
    <div className={`${style.valuation} position-relative h-100`}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex justify-content-end box-price-chart">
                <div className="form-control" style={{ marginBottom: 20 }}>
                  <div className="select-cus d-flex">
                    <IndicatorSelectOption />
                    <YearSelectOption />
                    <QuarterSelectOption />
                  </div>
                </div>
              </div>
              {isLoadingValuation ? (
                <Loading />
              ) : (
                <>
                  {size.height && (
                    <div
                      id="sectorStatisticsValuationChartId"
                      className="position-relative"
                      style={{ height: height - size.height || 0 }}
                    >
                      {dataChart.length ? (
                        <SizeTracker>
                          {(size1) => {
                            return (
                              <>
                                <ChartComponent
                                  data={dataChart}
                                  width={width}
                                  height={height - size.height - size1.height}
                                  barKey={getChartKey().barKey}
                                  barKeyMax={getChartKey().barKeyMax}
                                  barKeyMin={getChartKey().barKeyMin}
                                  keyXAxis={'icbName'}
                                  scatterDiamondKey={
                                    getChartKey().scatterDiamondKey
                                  }
                                  scatterLineKey={getChartKey().scatterLineKey}
                                  indicator={indicator}
                                />
                                <Footer
                                  key={width}
                                  list={[
                                    {
                                      text: I18n.t(
                                        `sector.sectorStatistics.overview.RANGE_${indicator}`,
                                      ),
                                      type: TYPE.SQUARE,
                                      before: {
                                        bgColor: '#facc5c',
                                      },
                                    },
                                    {
                                      text: I18n.t(
                                        `sector.sectorStatistics.overview.AVERAGE_${indicator}`,
                                      ),
                                      type: TYPE.LINE,
                                      before: {
                                        bgColor: '#dc6f00',
                                      },
                                    },
                                    {
                                      text: I18n.t(
                                        `sector.sectorStatistics.overview.CURRENT_${indicator}`,
                                      ),
                                      renderIcon: (
                                        <div className="d-flex align-center mr-5">
                                          <ScatterDiamondShape />
                                        </div>
                                      ),
                                    },
                                  ]}
                                  appendStyle={{ marginTop: 8 }}
                                />
                              </>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </div>
                  )}
                </>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

Valuation.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
