import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import {
  fillColor,
  leftLabel,
  leftUnit,
  mappingDisplayName,
  xAxisKey,
} from './constants'

export const ChartComponent = ({
  width,
  height,
  data,
  leftKeys,
  activeName,
  maxYear,
}) => {
  const footerData = () => {
    let keys = []
    if (leftKeys && leftKeys.length) {
      keys = [...leftKeys]
    }
    return keys.map((i, key) => ({
      text: mappingDisplayName(maxYear)[i] ?? i,
      type: FOOTER_TYPE.LINE,
      color: fillColor[key],
    }))
  }

  const getContent = () => {
    return [
      {
        keys: leftKeys,
        yAxisId: 'leftChart',
        unit: leftUnit,
        decimalLength: 2,
      },
      {
        keys: [],
        yAxisId: 'rightChart',
      },
    ]
  }

  const getFormatMappingDisplayName = (key, tooltipData) => {
    return `${tooltipData[xAxisKey]}-${mappingDisplayName(maxYear)[key]}`
  }

  const renderLineChart = () => {
    return leftKeys.map((key, index) => (
      <Line
        key={key}
        yAxisId="leftChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[index]}
        strokeWidth={2}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  if (!data.length) return <NoData />

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              yAxis={[
                {
                  id: 'leftChart',
                  keys: leftKeys,
                  orientation: 'left',
                  isLineChart: true,
                  label: leftLabel ? I18n.t(leftLabel) : leftLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                },
                {
                  id: 'rightChart',
                  keys: [],
                  orientation: 'right',
                  yAxisWidth: 50,
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              tickFormatter={(val) => val}
              tooltipSchema={{
                title: {
                  formatValue: () => activeName,
                },
                content: getContent(),
                mappingDisplayName: mappingDisplayName(maxYear),
                formatMappingDisplayName: getFormatMappingDisplayName,
              }}
            >
              {renderLineChart()}
            </ChartContainer>
          )}
          <FooterContainer
            key={size}
            data={footerData()}
            numRow={1}
            numItemPerRow={5}
          />
        </>
      )}
    </SizeTracker>
  )
}

ChartComponent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
ChartComponent.defaultProps = {
  data: [],
}
