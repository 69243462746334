import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { exchangeConstants } from '../constants'
import {
  getDataTable,
  getTickerLatestPrice,
  getTooltipScoreResult,
  getTooltipTicker,
} from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/ranking',
  initialState: {
    exchangeCode: exchangeConstants.ALL,
    ids: [],
    initialIds: [],
    dataByIds: {},
    initialDataByIds: {},
    tooltipScoreData: [],
    tooltipTickerData: [],
    tooltipScoreLoading: true,
    tooltipTickerLoading: true,
    loading: true,
    tickerLatestPrice: {},
    tickerPriceLoading: false,
  },
  reducers: {
    changeTableData: (state, action) => {
      if (!state.initialIds) return

      if (action.payload) {
        const newData = Object.values(state.initialDataByIds).filter(
          (item) =>
            item.ticker.toLowerCase().includes(action.payload.toLowerCase()) ||
            item.organizationShortName
              .toLowerCase()
              .includes(action.payload.toLowerCase()),
        )

        state.dataByIds = keyBy(newData, 'organizationId')
        state.ids = newData.map((item) => item.organizationId)
      } else {
        state.dataByIds = state.initialDataByIds
        state.ids = state.initialIds
      }
    },
    changeExchangeCode: (state, action) => {
      state.exchangeCode = action.payload
    },
    resetTooltipScore: (state) => {
      state.tooltipScoreData = []
      state.tooltipScoreLoading = true
    },
    resetTooltipTicker: (state) => {
      state.tooltipTickerData = []
      state.tooltipTickerLoading = true
    },
  },
  extraReducers: (builder) => {
    // Table data
    builder.addCase(getDataTable.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataTable.fulfilled, (state, action) => {
      const data = action.payload
        .map((item) => {
          const listHistory = [...item.listHistory].sort((a, b) => {
            if (a.realQuarter && b.realQuarter) {
              return (
                moment()
                  .set('year', a.realYear)
                  .quarter(a.realQuarter === 5 ? 4 : a.realQuarter)
                  .unix() -
                moment()
                  .set('year', b.realYear)
                  .quarter(b.realQuarter === 5 ? 4 : b.realQuarter)
                  .unix()
              )
            }

            return a.periodId - b.periodId
          })
          let prevHistory =
            listHistory[listHistory.length > 2 ? listHistory.length - 2 : 0]

          if (item.periodId) {
            const findIndex = listHistory.findIndex(
              (history) => history.periodId === item.periodId,
            )

            if (findIndex >= 0) {
              prevHistory = listHistory[findIndex > 0 ? findIndex - 1 : 0]
            }
          }

          const rankCompare = prevHistory
            ? item.rankValue > prevHistory.rankValue
              ? -1
              : item.rankValue < prevHistory.rankValue
              ? 1
              : 0
            : 0

          return {
            ...item,
            rankCompare,
          }
        })
        .sort((a, b) => a.rankValue - b.rankValue)

      state.dataByIds = keyBy(data, 'organizationId')
      state.ids = data.map((item) => item.organizationId)
      state.initialDataByIds = keyBy(data, 'organizationId')
      state.initialIds = data.map((item) => item.organizationId)
      state.loading = false
    })
    builder.addCase(getDataTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    // Tooltip score
    builder.addCase(getTooltipScoreResult.pending, (state) => {
      state.tooltipScoreLoading = true
    })
    builder.addCase(getTooltipScoreResult.fulfilled, (state, action) => {
      state.tooltipScoreData = action.payload
      state.tooltipScoreLoading = false
    })
    builder.addCase(getTooltipScoreResult.rejected, (state, action) => {
      state.tooltipScoreLoading = action.payload.loading
    })
    // Tooltip ticker
    builder.addCase(getTooltipTicker.pending, (state) => {
      state.tooltipTickerLoading = true
    })
    builder.addCase(getTooltipTicker.fulfilled, (state, action) => {
      state.tooltipTickerData = action.payload.length ? action.payload[0] : []
      state.tooltipTickerLoading = false
    })
    builder.addCase(getTooltipTicker.rejected, (state, action) => {
      state.tooltipTickerLoading = action.payload.loading
    })
    // Ticker latest price
    builder.addCase(getTickerLatestPrice.pending, (state) => {
      state.tickerPriceLoading = true
    })
    builder.addCase(getTickerLatestPrice.fulfilled, (state, action) => {
      state.tickerLatestPrice = action.payload.length
        ? action.payload[0].priceInfo
        : {}
      state.tickerPriceLoading = false
    })
    builder.addCase(getTickerLatestPrice.rejected, (state, action) => {
      state.tickerPriceLoading = action.payload.loading
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectDataByKey = (state) => state[slice.name].dataByIds
export const selectDataByIds = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name]?.dataByIds[id], attr)
export const selectTooltipScoreData = (state) =>
  state[slice.name].tooltipScoreData
export const selectTooltipTickerData = (state) =>
  state[slice.name].tooltipTickerData
export const selectLoading = (state) => state[slice.name].loading
export const selectTooltipScoreLoading = (state) =>
  state[slice.name].tooltipScoreLoading
export const selectTooltipTickerLoading = (state) =>
  state[slice.name].tooltipTickerLoading
export const selectExchangeCode = (state) => state[slice.name].exchangeCode
export const selectTickerLatestPrice = (state) =>
  state[slice.name].tickerLatestPrice
export const selectTickerPriceLoading = (state) =>
  state[slice.name].tickerPriceLoading
export const {
  changeTableData,
  changeExchangeCode,
  resetTooltipScore,
  resetTooltipTicker,
} = slice.actions

register(slice.name, slice.reducer)
