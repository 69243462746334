import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { getFontSize } from '../../../../../utils/FontSize'
import Col from '../../common/Col'
import Row from '../../common/Row'
import TableData from '../../common/TableData'
import TableHeaderHorizontal from '../../common/TableHeaderHorizontal'
import { FIELD_NAME } from '../../configs'
import {
  chooseDataByGroup,
  handleChildrenTableData,
  mappingDataCollapseTable,
} from '../../helper'
import style from '../../index.module.css'
import { selectChoose, selectIncomeStructure } from '../../store/slice'
import { handleCollapse, selectCollapseTableData } from '../store/slice'
import FooterChildrenTable from './FooterChildrenTable'
import HeaderChildrenTable from './HeaderChildrenTable'
import ValueProportion from './ValueProportion'

const ChildrenTable = ({ height }) => {
  const dispatch = useDispatch()
  const headerRef = useRef(null)
  const footerRef = useRef(null)
  const fontSizeGlobal = getFontSize()
  const [headerFooterHeight, setHeaderFooterHeight] = useState({
    header: 0,
    footer: 0,
  })
  const [dataTable, setDataTable] = useState([])
  const { LDR, LLCR } = FIELD_NAME

  const {
    left1TableData,
    left2TableData,
    left3TableData,
    right1TableData,
    right2TableData,
    right3TableData,
  } = useSelector(selectCollapseTableData)
  const incomeStructureData = useSelector(selectIncomeStructure)
  const choose = useSelector(selectChoose)

  useEffect(() => {
    if (
      left1TableData.length &&
      left2TableData.length &&
      left3TableData.length &&
      right1TableData.length &&
      right2TableData.length &&
      right3TableData.length &&
      incomeStructureData.length
    ) {
      const handleMappingLeft1 = mappingDataCollapseTable(
        left1TableData,
        incomeStructureData,
      )
      const handleMappingLeft2 = mappingDataCollapseTable(
        left2TableData,
        incomeStructureData,
      )
      const handleMappingLeft3 = mappingDataCollapseTable(
        left3TableData,
        incomeStructureData,
      )
      const handleMappingRight1 = mappingDataCollapseTable(
        right1TableData,
        incomeStructureData,
      )
      const handleMappingRight2 = mappingDataCollapseTable(
        right2TableData,
        incomeStructureData,
      )
      const handleMappingRight3 = mappingDataCollapseTable(
        right3TableData,
        incomeStructureData,
      )
      setDataTable([
        ...handleChildrenTableData(
          handleMappingLeft1,
          handleMappingRight1,
          true,
        ),
        ...handleChildrenTableData(handleMappingLeft2, handleMappingRight2),
        ...handleChildrenTableData(handleMappingLeft3, handleMappingRight3),
      ])
    }
  }, [
    left1TableData,
    left2TableData,
    left3TableData,
    right1TableData,
    right2TableData,
    right3TableData,
    incomeStructureData,
  ])

  useEffect(() => {
    if (headerRef.current && footerRef.current) {
      setHeaderFooterHeight({
        header: headerRef.current.getBoundingClientRect().height,
        footer: footerRef.current.getBoundingClientRect().height,
      })
    }
  }, [fontSizeGlobal, dataTable])

  const changeCollapse = (
    currentColId,
    currentGroup,
    relativeColId,
    relativeGroup,
    level,
  ) => {
    if (level === 1) {
      dispatch(
        handleCollapse({
          colId: relativeColId,
          group: relativeGroup,
        }),
      )
    }
    dispatch(
      handleCollapse({
        colId: currentColId,
        group: currentGroup,
      }),
    )
  }

  return (
    <div style={{ height: height || 0 }}>
      <ScrollComponent
        verticalTrackTop={headerFooterHeight.header}
        verticalTrackBottom={headerFooterHeight.footer}
      >
        <table className="w-100">
          <thead
            className={`${style.childrenTableHeader} ${style.childrenTableSticky}`}
            ref={headerRef}
          >
            <HeaderChildrenTable />
          </thead>
          <tbody>
            <ValueProportion />
            {dataTable.length > 0 && (
              <>
                {dataTable.map((item, index) => {
                  return (
                    <Row
                      key={index}
                      styleProps={{
                        borderBottomColor:
                          item?.isContributionToNIMLeft ||
                          item?.isContributionToNIMRight
                            ? '#3e485a'
                            : '',
                      }}
                    >
                      <Col
                        isChooseTHHorizontal
                        isClick={item.isDropdownLeft}
                        styleProps={{
                          paddingLeft: 0,
                          borderRightColor: '#1b2029',
                        }}
                        id={item?.leftData.indicator}
                        isChoose={
                          choose === item?.leftData.indicator &&
                          choose !== LDR &&
                          choose !== LLCR
                        }
                        onClick={() =>
                          changeCollapse(
                            item?.leftId,
                            chooseDataByGroup(item.leftGroup),
                            item?.rightId,
                            chooseDataByGroup(item.rightGroup),
                            item.leftLevel,
                          )
                        }
                        chart1Type={item?.leftData.chart1Type}
                        chart2Type={item?.leftData.chart2Type}
                        indicator={item?.leftData.indicator}
                        fieldName={item?.leftData.fieldName}
                        isHoverOutline={false}
                      >
                        <TableHeaderHorizontal
                          title={item.leftTitle}
                          level={item.leftLevel}
                          isDropdown={item.isDropdownLeft}
                          isActive={item.isActiveLeft}
                          isContributionToNIM={item?.isContributionToNIMLeft}
                        />
                      </Col>
                      <Col
                        styleProps={{ borderRightColor: '#1b2029' }}
                        id={item?.leftAlias}
                        isChoose={choose === item?.leftAlias}
                        chart1Type={item?.leftData.chart1Type}
                        chart2Type={item?.leftData.chart2Type}
                        fieldName={item?.leftData.fieldName}
                        fieldName_1={''}
                        indicator={item?.leftData.indicator}
                        relativeIndicator={item?.leftData.relativeIndicator}
                        alias={item?.leftAlias}
                        valueFormat={item?.leftData.format}
                      >
                        <TableData
                          isFlexEnd
                          alias={item?.leftAlias}
                          parentData={item?.leftValue}
                          childrenData={item?.leftRelativeValue}
                          afterChildrenData={
                            item?.leftRelativeIndicator === '%YoY' ? 'YoY' : ''
                          }
                          relativeField={item?.leftData.relativeField}
                          valueFormat={item?.leftData.format}
                          multiplier={item?.leftData.multiplier}
                          isContributionToNIM={item?.isContributionToNIMLeft}
                        />
                      </Col>
                      <Col
                        id={item?.leftAlias1}
                        isChoose={choose === item?.leftAlias1}
                        chart1Type={item?.leftData.chart1Type}
                        chart2Type={item?.leftData.chart2Type}
                        fieldName={item?.leftData.fieldName}
                        fieldName_1={item?.leftData.fieldName_1}
                        indicator={item?.leftData.indicator}
                        relativeIndicator={item?.leftData.chart1Legend2}
                        alias={item?.leftAlias1}
                      >
                        <TableData
                          isFlexEnd
                          isAssetYieldCOF
                          alias={item?.leftAlias1}
                          parentData={item?.leftValue1}
                          childrenData={item?.leftRelativeValue1}
                          afterChildrenData={
                            item?.leftRelativeIndicator1 === '%YoY' ? 'YoY' : ''
                          }
                          relativeField={item?.leftData.relativeField}
                        />
                      </Col>
                      <Col
                        isChooseTHHorizontal
                        isClick={item.isDropdownRight}
                        styleProps={{
                          paddingLeft: 0,
                          borderRightColor: '#1b2029',
                        }}
                        id={item?.rightData?.indicator}
                        isChoose={choose === item?.rightData?.indicator}
                        onClick={() =>
                          changeCollapse(
                            item?.rightId,
                            chooseDataByGroup(item.rightGroup),
                            item?.leftId,
                            chooseDataByGroup(item.leftGroup),
                            item.rightLevel,
                          )
                        }
                        chart1Type={item?.rightData?.chart1Type}
                        chart2Type={item?.rightData?.chart2Type}
                        indicator={item?.rightData?.indicator}
                        fieldName={item?.rightData?.fieldName}
                        isHoverOutline={false}
                      >
                        <TableHeaderHorizontal
                          title={item.rightTitle}
                          level={item.rightLevel}
                          isDropdown={item.isDropdownRight}
                          isActive={item.isActiveRight}
                          isContributionToNIM={item?.isContributionToNIMRight}
                        />
                      </Col>
                      <Col
                        styleProps={{ borderRightColor: '#1b2029' }}
                        id={item?.rightAlias}
                        isChoose={choose === item?.rightAlias}
                        chart1Type={item?.rightData?.chart1Type}
                        chart2Type={item?.rightData?.chart2Type}
                        fieldName={item?.rightData?.fieldName}
                        fieldName_1={''}
                        indicator={item?.rightData?.indicator}
                        relativeIndicator={item?.rightData?.relativeIndicator}
                        alias={item.rightAlias}
                        valueFormat={item?.rightData?.format}
                      >
                        <TableData
                          isFlexEnd
                          alias={item.rightAlias}
                          parentData={item?.rightValue}
                          childrenData={item?.rightRelativeValue}
                          afterChildrenData={
                            item?.rightRelativeIndicator === '%YoY' ? 'YoY' : ''
                          }
                          relativeField={item?.rightData?.relativeField}
                          valueFormat={item?.rightData?.format}
                          multiplier={item?.rightData?.multiplier}
                          isContributionToNIM={item?.isContributionToNIMRight}
                        />
                      </Col>
                      <Col
                        id={item?.rightAlias1}
                        isChoose={choose === item?.rightAlias1}
                        chart1Type={item?.rightData?.chart1Type}
                        chart2Type={item?.rightData?.chart2Type}
                        fieldName={item?.rightData?.fieldName}
                        fieldName_1={item?.rightData?.fieldName_1}
                        indicator={item?.rightData?.indicator}
                        relativeIndicator={item?.rightData?.chart2Legend2}
                        alias={item?.rightAlias1}
                      >
                        <TableData
                          isFlexEnd
                          isAssetYieldCOF
                          alias={item?.rightAlias1}
                          parentData={item?.rightValue1}
                          childrenData={item?.rightRelativeValue1}
                          afterChildrenData={
                            item?.rightRelativeIndicator1 === '%YoY'
                              ? 'YoY'
                              : ''
                          }
                          relativeField={item?.rightData?.relativeField}
                        />
                      </Col>
                    </Row>
                  )
                })}
              </>
            )}
          </tbody>
          <FooterChildrenTable ref={footerRef} />
        </table>
      </ScrollComponent>
    </div>
  )
}

export default ChildrenTable
