import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { ReactComponent as IconDashboardTemplate } from '../../icon/icon-dashboard-template.svg'
import PopupEditLayout from '../../popup/popupEditLayout'
import style from './index.module.css'

const ChangeLayoutBtn = () => {
  const [isShowEditLayout, setIsShowEditLayout] = useState(false)

  const handleShowEditLayout = () => {
    setIsShowEditLayout(true)
  }
  return (
    <div>
      <Button className={style.btnGray} onClick={handleShowEditLayout}>
        <IconDashboardTemplate height={12} style={{ marginRight: 3 }} />
        <Translate value="financialChart.EDIT_LAYOUT" />
      </Button>
      <PopupEditLayout
        isShow={isShowEditLayout}
        handleShow={setIsShowEditLayout}
      />
    </div>
  )
}

export default ChangeLayoutBtn
