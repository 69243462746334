import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import style from './common.module.css'

const Unit = ({ title, styleProps }) => {
  return (
    <div style={styleProps}>
      <Span style={{ fontSize: 11 }} className={style.unit}>
        <Translate value={title} />
      </Span>
    </div>
  )
}

Unit.propType = {
  title: PropTypes.string.isRequired,
  styleProps: PropTypes.object,
}

Unit.defaultProps = {
  styleProps: {},
}

export default Unit
