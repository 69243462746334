import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { SESSION } from '../../../../common/chart/constants'
import { Span } from '../../../../common/html/Span'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { listTab } from '../constans'
import { encodeSizePanel } from '../helper'
import style from '../index.module.css'
import {
  handleContributionTabActive,
  selectContributionTabActive,
} from '../store/slice'
import { getContributionThunk } from '../store/thunk'
import ContentContribution from './ContentContribution'

export const idContribution = 'contribution'

const Contribution = ({ sizes, panelRefs, panelKey, setSizes }) => {
  const dispatch = useDispatch()

  const indexInfo = useSelector(selectIndexInfo)
  const tabActive = useSelector(selectContributionTabActive)

  const interval = useRef()

  const sizePanel = encodeSizePanel(
    sizes[panelKey].width,
    sizes[panelKey].height,
  )

  const startTimeSession = new Date().setHours(
    SESSION.start.hour,
    SESSION.start.minute,
    0,
    0,
  )

  const endTimeSession = new Date().setHours(
    SESSION.end.hour,
    SESSION.end.minute,
    0,
    0,
  )

  useEffect(() => {
    if (indexInfo.groupId) {
      const body = {
        GroupId: indexInfo.groupId,
        Top: 15,
      }
      dispatch(getContributionThunk(body))
      interval.current = setInterval(() => {
        const now = new Date().getTime()
        if (now > endTimeSession) {
          clearIntervalRealtime()
        } else if (now >= startTimeSession) {
          dispatch(getContributionThunk(body))
        }
      }, 30 * 1000)
    }
    return () => {
      if (interval.current) {
        clearIntervalRealtime()
      }
    }
  }, [indexInfo.groupId])

  const clearIntervalRealtime = () => {
    clearInterval(interval.current)
    interval.current = undefined
  }

  const handleChangeTab = (item) =>
    dispatch(handleContributionTabActive(item.value))

  return (
    <Panel
      title="market.marketInDepth.indices.CONTRIBUTION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('market.marketInDepth.indices.CONTRIBUTION')}
      downloadJpgParams={{
        domId: idContribution,
        nameScreen: I18n.t(
          'market.marketInDepth.indices.NAME_SCREEN_LOWERCASE',
        ),
        chartName: I18n.t(
          'market.marketInDepth.indices.CHART_NAME_CONTRIBUTION',
        ),
        tickerName: indexInfo.groupName,
      }}
    >
      <PreOpenMarket>
        <SizeTracker>
          {(size) => (
            <div className="h-100">
              <div
                className={`w-100 ${style.pb15px} d-flex justify-content-end`}
              >
                <DispatchActionTab
                  data={listTab}
                  activeTab={tabActive}
                  onChangeTab={handleChangeTab}
                  itemStyle={{ fontWeight: 500, fontSize: 10 }}
                />
              </div>
              {size.height && (
                <div
                  id={idContribution}
                  className={`${style.plr25px} h-100`}
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <ContentContribution
                    sizes={sizePanel}
                    tabActive={tabActive}
                  />
                </div>
              )}
              <div className={style.labelFooter}>
                <Span style={{ fontSize: 10 }}>
                  <Translate value="market.marketInDepth.indices.CONTRIBUTION_TO_INDEX" />
                </Span>
              </div>
            </div>
          )}
        </SizeTracker>
      </PreOpenMarket>
    </Panel>
  )
}

export default Contribution
