import { securityTypes } from '../constants'
import AssetStructureContent from './assetStructure'
import PerformanceContent from './performance'

export const Security = ({ type, dataType }) => {
  return type === securityTypes.ASSET_STRUCTURE ? (
    <AssetStructureContent dataType={dataType} />
  ) : type === securityTypes.PERFORMANCE ? (
    <PerformanceContent dataType={dataType} />
  ) : (
    ''
  )
}
