import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { timeTypeOptions } from '../../../../constants'
import { getRevenueInvestmentChartData } from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/security/performance/revenueInvestment',
  initialState: {
    data: [],
    loading: true,
    timeType: timeTypeOptions[0].value,
  },
  reducers: {
    changeTimeType: (state, action) => {
      state.timeType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRevenueInvestmentChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getRevenueInvestmentChartData.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = action.payload
      },
    )
    builder.addCase(getRevenueInvestmentChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartData = (state) => state[slice.name].data
export const selectChartLoading = (state) => state[slice.name].loading
export const selectTimeType = (state) => state[slice.name].timeType
export const { changeTimeType } = slice.actions

register(slice.name, slice.reducer)
