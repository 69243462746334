import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import { SLICE_NAME } from './slice'

export const getBasicInfo = createAsyncThunk(
  'common/GET_STOCK_BASIC_INFO',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getBasicInfo(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
  {
    condition: (params, { getState }) => {
      const requestOrgId = params.OrganizationId
      const isPending = getState()[SLICE_NAME].pendingRequests[requestOrgId]
      return !isPending
    },
  },
)
