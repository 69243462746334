export const overview = {
  OVERVIEW: 'Overview',
  YEARLY: 'Yearly',
  MONTHLY: `Acc․ Monthly`,
  REGISTERED_CAPITAL: 'Registered Capital',
  DISBURSED_CAPITAL: 'Disbursed Capital',
  NEW_REGISTRATION: 'New Registration',
  ADDITIONAL_REGISTRATION: 'Additional Registration',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE:
    'No․ of Project Registered for capital contribution, share purchase',
  NEW_APPROVAL_PROJECT: 'No of New Approval Project',
  ADDITIONAL_CAPITAL: 'No․ of Project Registered for Additional Capital',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE_1:
    'No․ of Project Registered for capital contribution, share purchase',
  NEW_AND_ADDITIONAL_REGISTRATION: 'Registration Capital',
  NO_OF_NEW_APPROVAL_PROJECT: 'No of New Approval Project',
  TITLE_PANEL_TABLE: 'Total registered FDI capital/Disbursed FDI',
  TITLE_PANEL_CHART: 'Total registered FDI capital/Disbursed FDI',
  TITLE_PANEL_KEY_INDICATOR: 'Key indicator',
  TITLE_PANEL_1: 'Vietnam inward FDI flows by Country of origin',
  TITLE_PANEL_2: 'Vietnam inward FDI flows to province',
  TITLE_PANEL_3: 'FDI structure',
  TITLE_PANEL_4: 'Vietnam inward FDI flows by Industry',
  LABEL_TABLE: 'Unit: million USD',
  MILLION_USD: 'Million USD',
  PROJECTS: 'Projects',
  PROJECTS_NUMBER: 'Projects Number',
  LABEL_PROJECTS: 'new projects',
  LABEL_MILLION_USD: '(Million USD)',
  BILLION_USD: 'Bil․ USD',
  OTHERS: 'Others',
  NAME_SCREEN: 'Economy_Overview',
  LABEL_PROJECTS_CHART: '(Projects)',
  REGISTRATION_TOTAL: 'Registered FDI capital',
}

export const fdiByCountries = {
  TITLE_SCREEN: 'FDI by country',
  FDI_BY_ORIGIN_COUNTRY: 'Foreign investment by origin country',
  CAPITAL: 'Registered Capital',
  NUMBER_OF_PROJECT: 'Number of project',
  YEARLY: 'Yearly',
  ACC_MONTHLY: 'Acc․ Monthly',
  SEARCH: 'Country',
  LABEL_TABLE: 'Unit: million USD',
  FDI_STRUCTURE_BY_ORIGIN_COUNTRY:
    'Foreign investment structure by origin country',
  LABEL_CHART: 'Foreign investment structure in',
  REGISTERED_CAPITAL: 'Total registered capital',
  NEW_REGISTRATION: 'Newly registered capital',
  ADDITIONAL_REGISTRATION: 'Additional registered capital',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE:
    'Capital contribution and share purchase',
  NUMBER_OF_NEW_REGISTRATION_PROJECT: 'Number of new projects',
  MILLION_USD: 'Million USD',
  PROJECTS: 'Project',
  OTHERS: 'Others',
  COMPOSITION_OF_FDI_INFLOWS_BY_ORIGIN_COUNTRY:
    'Composition of Foreign investment by origin country',
  FDI_STRUCTURE_BY_COUNTRY: 'Foreign investment structure by origin country',
  COUNTRY_CONTINENT: 'COUNTRY/CONTINENT',
  LABEL_MILLION_USD: '(Million USD)',
  LABEL_PROJECTS: '(Project)',
}

export const fdiByIndustry = {
  TITLE_SCREEN: 'FDI by Industry',
  CAPITAL: 'Registered Capital',
  NUMBER_OF_PROJECT: 'Number of project',
  FDI_NO_OF_NEW_PROJECTS: 'Number of new projects',
  FDI_NEW_REGISTERED_CAPITAL: 'Newly registered capital',
  FDI_NO_OF_PROJECT_REGISTERED_FOR_ADDITIONAL_CAPITAL:
    'No of project registered for additional capital',
  FDI_REGISTERED_ADDITIONAL_CAPITAL: 'Additional registered capital',
  FDI_PROJECTS_REGISTERED_FOR_SHARE_PURCHASE:
    'Projects registered for share purchase',
  FDI_VALUE_REGISTERED_FOR_SHARE_PURCHASE:
    'Capital contribution and share purchase',
  FDI_NEW_REGISTERED_AND_ADDITIONAL_CAPITAL: 'Total registered capital',
  FDI_STRUCTURE_BY_INDUSTRY: 'Foreign investment structure by industry',
  COMPOSITION_OF_FDI_INFLOWS_BY_INDUSTRY:
    'Composition of foreign investment by industry',
  MILLION_USD: '(Million USD)',
  PROJECTS: '(Projects)',
  FDI_BY_INDUSTRY: 'Foreign Investment by industry',
  INDUSTRY: 'Industry',
  LABEL_TABLE: 'Unit: million USD',
  YEARLY: 'Yearly',
  ACC_MONTHLY: 'Acc․ Monthly',
  OTHERS: 'Others',
  NAME_SCREEN: 'Economy_FDI by Industry',
  MILLION_USD_UNIT: 'Million USD',
  PROJECTS_UNIT: 'Projects',
  LABEL_MILLION_USD: '(Million USD)',
  LABEL_PROJECTS: '(Project)',
}

export const fdiByProvinces = {
  TITLE_SCREEN: 'FDI by Province',
  CAPITAL: 'Registered Capital',
  NUMBER_OF_PROJECT: 'Number of project',
  YEARLY: 'Yearly',
  ACC_MONTHLY: 'Acc․ Monthly',
  FDI_BY_PROVINCE: 'Foreign Investment by province',
  FDI_STRUCTURE_BY_PROVINCE: 'Foreign Investment structure by province',
  COMPOSITION_OF_FDI_INFLOWS_BY_PROVINCE:
    'Composition of foreign Investment by province',
  MILLION_USD: '(Million USD)',
  PROJECTS: '(Projects)',
  REGISTERED_CAPITAL: 'Total registered capital',
  NEW_REGISTRATION: 'Newly registered capital',
  ADDITIONAL_REGISTRATION: 'Additional registered capital',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE:
    'Capital contribution and share purchase',
  NUMBER_OF_NEW_REGISTRATION_PROJECT: 'Number of new projects',
  PERIOD: 'Period',
  PROVINCE: 'Province',
  VALUE: 'Value (%)',
  OTHERS: 'Others',
  LABEL_CHART: 'Foreign Investment structure in',
  SEARCH: 'Province',
  NAME_SCREEN: 'Economy_FDI by Province',
  PROVINCE1: 'Province',
  MILLION_USD_UNIT: 'Million USD',
  PROJECTS_UNIT: 'Projects',
  LABEL_MILLION_USD: '(Million USD)',
  LABEL_PROJECTS: '(Project)',
}
