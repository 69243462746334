export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
}

export const indicator = [
  {
    index: 'index1',
    name: 'economy.fdi.overview.DISBURSED_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    key: 'fdiTypeId_1',
    isFormatMillion: true,
  },
  {
    index: 'index2',
    childrenId: ['a1', 'a2'],
    name: 'economy.fdi.overview.REGISTERED_CAPITAL',
    key: 'fdiTypeId_2',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    isFormatMillion: true,
  },
  {
    index: 'a1',
    parentId: 'index2',
    childrenId: ['b1', 'b2'],
    name: 'economy.fdi.overview.REGISTRATION_TOTAL',
    key: 'fdiTypeId_0',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    isFormatMillion: true,
  },
  {
    index: 'b1',
    parentId: 'a1',
    name: 'economy.fdi.overview.NEW_REGISTRATION',
    key: 'fdiTypeId_3',
    level: TABLE_ROW_LEVEL.LEVEL_3,
    isFormatMillion: true,
  },
  {
    index: 'b2',
    parentId: 'a1',
    name: 'economy.fdi.overview.ADDITIONAL_REGISTRATION',
    key: 'fdiTypeId_4',
    level: TABLE_ROW_LEVEL.LEVEL_3,
    isFormatMillion: true,
  },
  {
    index: 'a2',
    parentId: 'index2',
    name: 'economy.fdi.overview.CAPITAL_CONTRIBUTION_SHARE_PURCHASE',
    key: 'fdiTypeId_5',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    isFormatMillion: true,
  },
  {
    index: 'index3',
    childrenId: ['c1', 'c2', 'c3'],
    name: 'economy.fdi.overview.PROJECTS_NUMBER',
    key: '',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'c1',
    parentId: 'index3',
    name: 'economy.fdi.overview.NEW_APPROVAL_PROJECT',
    key: 'fdiTypeId_6',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'c2',
    parentId: 'index3',
    name: 'economy.fdi.overview.ADDITIONAL_CAPITAL',
    key: 'fdiTypeId_7',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'c3',
    parentId: 'index3',
    name: 'economy.fdi.overview.CAPITAL_CONTRIBUTION_SHARE_PURCHASE_1',
    key: 'fdiTypeId_8',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
]
