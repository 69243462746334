export const DEFAULT_TIME_ZONE_OFFSET = '+07:00'

export const MAP_INFO_USER = {
  userName: 'fullName',
  email: 'email',
  phoneNumber: 'phone',
  favoriteContact: 'favouriteContact',
  accountType: 'accountType',
  expiryDate: 'expiryDate',
}

export const SETTING_LANGUAGE = {
  vi: 1,
  en: 2,
}

export const FONT_SIZE_ID = {
  SMALL: 1,
  MEDIUM: 2,
  LARGE: 3,
}

export const FAVORITE_CONTACT = {
  EMAIL: 1,
  PHONE: 2,
}

export const ACCOUNT_TYPE = {
  TRIAL_ACCOUNT: 'FiinX.Trial',
  PAID_ACCOUNT: 'FiinX.Premium',
}
