import { keyBy } from '../../../../../utils/Common'
import {
  ASSET_QUALITY_KEYS,
  CREDIT_KEYS,
  MEAN_ROW_ID,
  OWNERSHIP_KEYS,
  PERFORMANCE_KEYS,
  PROFITABILITY_KEYS,
  TABLE_TYPE,
  VALUATION_KEYS,
} from '../constant'

const getCalcValue = (tableType) => {
  switch (tableType) {
    case TABLE_TYPE.PERFORMANCE:
      return Object.values(PERFORMANCE_KEYS)
    case TABLE_TYPE.VALUATION:
      return Object.values(VALUATION_KEYS)
    case TABLE_TYPE.OWNERSHIP:
      return Object.values(OWNERSHIP_KEYS)
    case TABLE_TYPE.CREDIT:
      const values = Object.values(CREDIT_KEYS)
      Object.values(ASSET_QUALITY_KEYS).forEach((value) => {
        if (!values.includes(value)) {
          values.push(value)
        }
      })
      return values
    case TABLE_TYPE.PROFITABILITY:
      return Object.values(PROFITABILITY_KEYS)
    default:
      return []
  }
}

export const getMaxMinColValue = (data, tableType) => {
  const result = {}

  const colIds = getCalcValue(tableType)
  colIds.forEach((id) => (result[id] = { max: 0, min: 0 }))

  data.forEach((item) => {
    Object.keys(result).forEach((key) => {
      result[key].max = Math.max(result[key].max, item[key])
      result[key].min = Math.min(result[key].min, item[key])
    })
  })

  return result
}

const getTotalColValue = (data, tableType) => {
  const result = {}

  const colIds = getCalcValue(tableType)
  colIds.forEach((id) => (result[id] = 0))

  data.forEach((item) => {
    Object.keys(result).forEach(
      (key) => (result[key] += Number(item[key]) || 0),
    )
  })

  return result
}

export const getMeanValue = (data, tableType) => {
  const result = {}

  const totalColValue = getTotalColValue(data, tableType)
  Object.keys(totalColValue).forEach((key) => {
    result[key] = totalColValue[key] / data.length
  })

  return result
}

export const handleReCalcMaxMeanValue = (state, newResponseOrgIds) => {
  const data = Object.values(state.valueById).filter((item) =>
    newResponseOrgIds.includes(item.organizationId),
  )
  const maxMinColValue = getMaxMinColValue(data, state.tableType)
  const meanValue = getMeanValue(data, state.tableType)

  state.maxMinValueByColId = maxMinColValue
  state.valueById[MEAN_ROW_ID] = meanValue
}

export const handleResponseTableChart = (state, action) => {
  const data = action.payload || []
  const responseOrgIds = data.map((ticker) => ticker.organizationId)
  const maxMinColValue = getMaxMinColValue(data, state.tableType)
  const meanValue = getMeanValue(data, state.tableType)

  state.valueById = {
    ...keyBy(data, 'organizationId'),
    [MEAN_ROW_ID]: meanValue,
  }
  state.responseOrgIds = responseOrgIds
  state.initialResponseOrgIds = responseOrgIds
  state.maxMinValueByColId = maxMinColValue
  state.loading = false
  state.reCalcWidths = !state.reCalcWidths
}
