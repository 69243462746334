import { Translate } from 'react-redux-i18n'
import { Span } from '../../html/Span'
import SelectCustom from '../../selectCustom'
import { ALL_TYPE } from '../constants'
import style from './index.module.css'

export const NewsTypeSelect = ({
  changeFilter,
  categoryDropdownRef,
  newsType,
  categoryValue,
  categories,
}) => {
  return (
    <div>
      <label className={style.titleSelect}>
        <Span style={{ fontSize: 11 }}>
          <Translate value={'common.news.NEWS_TYPE'} />
        </Span>
      </label>
      <SelectCustom
        isI18n
        appendDropdownRef={categoryDropdownRef}
        value={newsType || categoryValue}
        selectData={[
          {
            newsCategoryId: ALL_TYPE,
            newsCategoryName: 'common.ALL',
          },
          ...categories.filter((item) => !item.parentNewsCategoryId),
        ].map((item) => {
          return {
            value: item.newsCategoryId,
            name: item.newsCategoryName,
          }
        })}
        handleChange={(value) =>
          changeFilter(newsType ? { newsType: value } : { category: value })
        }
      />
    </div>
  )
}
