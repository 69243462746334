export const indices = {
  NAME_SCREEN_UPPERCASE: 'INDICES',
  NAME_SCREEN_LOWERCASE: 'Indices',
  INDEX_TITLE: 'VIETNAM',
  SECTOR_TITLE: 'SECTOR',
  INDEX_TAB: 'INDEX',
  CODE: 'INDEX',
  CLOSE_PRICE: 'LAST',
  PRICE_CHANGE: 'CHANGE',
  PERCENT_PRICE_CHANGE: '% 1D CHANGE',
  TOTAL_VOLUME: 'VOLUME',
  TOTAL_VALUE: 'VALUE',
  NAME: 'SECTOR',
  PROSPECT: 'WORLDWIDE',
  WORLD_INDEX: 'INDEX',
  COMMODITIES: 'COMMODITY',
  GLOBAL_INDEX_NAME: 'INDEX',
  COMMODITY_NAME: 'COMMODITY',
  MARKET_WATCH: 'MARKET WATCH',
  VALUE_BILLION: 'Value (Billion VND)',
  TODAY_TOTAL_TRADING: 'Today Total Trading Value',
  YESTERDAY_TOTAL_TRADING: 'Yesterday Total Trading Value',
  AVG_TOTAL_TRADING_1W: 'Avg Total Trading Value 1W',
  AVG_TOTAL_TRADING_1M: 'Avg Total Trading Value 1M',
  AVG_TOTAL_TRADING_6M: 'Avg Total Trading Value 6M',
  AVG_TOTAL_TRADING_1Y: 'Avg Total Trading Value 1Y',
  AVG_TOTAL_TRADING_3M: 'Avg Total Trading Value 3M',
  FOREIGN_BUY: 'Foreign Buy',
  FOREIGN_SELL: 'Foreign Sell',
  NET_FOREIGN: 'Net Foreign',
  FOREIGN_TRADING: 'FOREIGN TRADING',
  VOLUME_MILLION: 'Volume (Million Shares)',
  INDEX_POINT: 'Index (Point)',
  BUY_UP_VOLUME: 'Buy up Volume',
  SELL_DOWN_VOLUME: 'Sell down Volume',
  BUY_UP: 'Buy up',
  SELL_DOWN: 'Sell down',
  BU_SD_TITLE: 'TIMES & BU/ SD VOLUME',
  CONTRIBUTION: 'CONTRIBUTION',
  CONTRIBUTION_TO_INDEX: 'Contribution to index %',
  TOOLTIP_CONTRIBUTION: 'Contribution:',
  TOOLTIP_PRICE: 'Price:',
  TOOLTIP_VOLUME: 'Volume:',
  TOOLTIP_VALUE: 'Value:',
  CHART_NAME_MARKET_WATCH: 'Market watch',
  CHART_NAME_FOREIGN_TRADING: 'Foreign trading',
  CHART_NAME_CONTRIBUTION: 'Contribution',
  SECTOR_OVERVIEW: 'Sector Overview',
  FINANCIAL_ANALYSIS: 'Financial Analysis',
  SEGMENTATION: 'Segmentation',
  RANKING: 'Ranking',
  RATIO: 'Ratio',
  VALUATION: 'Valuation',
  MILLION_PRICE: ' Mn.',
  MILLION: ' Mn.',
  BILLION: ' Bn.',
  CONTRIBUTION_VOLUMN: 'Thou. shares',
  CONTRIBUTION_VALUE: 'Mn.',
  BUSD_MILLION: ' Mn.',
  LIQUIDLITY: 'Liquidlity',
  INTRADAY: 'Intraday',
  REALTIME: 'Real Time',
  END_OF_DAY: 'End of Day',
  INTRADAY_TRADING_DATE: 'TRADING DATE',
  INTRADAY_CLOSE_INDEX: 'LAST',
  INTRADAY_PERCENT_INDEX_CHANGE: '%',
  INTRADAY_VOLUME: 'VOLUME',
  INTRADAY_TOTAL_MATCH_VOLUME: 'TOTAL MATCH VOLUME (SHARE)',
  INTRADAY_MATCH_VOLUME: 'MATCH VOLUME',
  INTRADAY_TOTAL_VOLUME: 'TOTAL VOLUME (SHARE)',
}

export const directorDeal = {
  DEAL: 'Deal',
  NO: 'NO',
  PUBLIC_DATE: 'PUBLIC DATE',
  END_DATE: 'END DATE',
  TICKER: 'TICKER',
  OWNER: 'OWNER',
  POSITION: 'POSITION',
  TYPE: 'TYPE',
  TOTAL_EST_VALUE: 'TOTAL EST.VALUE',
  SHARE_REGISTERED: 'SHARE REGISTERED',
  PERCENT_VOL: '% VOL',
  STATE: 'STATUS',
  PERCENT_OWN: '% OWN',
  BUY: 'Buy',
  SELL: 'Sell',
  TABLE_HEAD_TEXT: 'Value: x 1,000,000.',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  HALF_A_YEAR: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',
  ALL_TYPE: 'All Types',
  ALL_TICKERS: 'All Tickers',
  ALL_STATUS: 'All Status',
  PAGE: 'Page',
  TYPE_BUY: 'Buy',
  TYPE_SELL: 'Sell',
  STATUS_PENDING: 'Pending',
  STATUS_DONE: 'Done',
  STATUS_PAUSE: 'Pause',
  STATUS_PLAN: 'Plan',
  STATUS_CANCEL_PLAN: 'Cancel Plan',
  STATUS_CHANGE_PLAN: 'Change Plan',
  LB_FROM: 'From',
  LB_TO: 'To',
  LB_CLEAR: 'Clear',
  LB_DIRECTOR_DEAL: 'Director Deal',
  LB_TICKERS: 'Tickers',
  SHARE_ACQUIRED: 'SHARES ACQUIRED',
  NET_VALUE_BREAKDOWN: 'Net value Breakdown',
  NET_VALUE_OF_INTERNAL_TRADING: 'Net value of internal trading',
  NET_VALUE: 'Value (Billion VND)',
  DIRECTORS_AND_RELATED_PEOPLE: 'Directors & related people',
  INSTITUTIONAL_SHAREHOLDERS: 'Institutional Shareholders',
  NO_OF_TRANSACTIONS: 'No.of transactions',
  TRANSACTION: 'Transaction',
  NET: 'Net',
  DIRECTORS_AND_RELATED_PEOPLE_1: 'Directors & related people: ',
  INSTITUTIONAL_SHAREHOLDERS_1: 'Institutional Shareholders: ',
  NO_OF_TRANSACTIONS_1: 'No.of transactions: ',
  BUY_1: 'Buy: ',
  SELL_1: 'Sell: ',
  NET_1: 'Net: ',
  LB_FOOTER_CHART:
    'Value of the last period is the total value of executed deals and planned deals to end in this period.',
  TOOLTIP_TOTAL_EST_VALUE:
    'For executed deals, value is equal to share acquired multiplied close price at ending date. For other types, value is equal to share register multiplied the newest close price.',
  TOOLTIP_PERCENT_VOL:
    'Proportion of the acquired (for executed deals) or registered (for others) share number to total stock volume at the public date of the deal',
  NO_TICKER: 'No ticker',
  BILLION: ' Bn.',
  OWNER_PLACEHOLDER: 'Owner',
  INTERNAL_TRADING: 'Internal Trading of',
  NET_VALUE_BREAKDOWN_OF: 'Net value Breakdown of',
  NET_VALUE_OF_INTERNAL_TRADING_OF: 'Net value of internal trading of',
  ALL_TRANSACTION: 'All transactions',
  INSTITUTION: 'Institution',
  INDIVIDUAL: 'Individual',
  RELATED_PERSON: 'Related person',
  TYPE_PUBLIC_DATE: 'Public date',
  TYPE_END_DATE: 'End date',
  TABLE_HEAD_TEXT_DATA_CALCULATE_FROM:
    'Value: x 1,000,000. Data is calculated from %{startDate} to %{endDate}',
}

export const valuation = {
  LB_VALUATION: 'Valuation',
  LB_VALUATION_CHART: 'Valuation chart',
  TABLE_FOOTER_TEXT: 'The data is calculated as of ',
  '3M': '3M',
  '6M': '6M',
  '9M': '9M',
  '1Y': '1Y',
  '3Y': '3Y',
  ALL: 'All',
  PRICE_TO_EARNINGS: 'P/E',
  PRICE_TO_BOOK: 'P/B',
  LB_COMPARE: 'Compare',
  CLEAR_ALL: 'Clear all',
  INDICES: 'Indices',
  SECTOR: 'Sector',
  TICKER: 'Ticker',
  MESS_WARNING_COMPARE:
    'Maximum 19 indicators are added. You cannot add more indicators.',
  ICB_LEVEL_1: 'ICB Level 1',
  ICB_LEVEL_2: 'ICB Level 2',
  ICB_LEVEL_3: 'ICB Level 3',
  ICB_LEVEL_4: 'ICB Level 4',
  ICB_LEVEL_5: 'ICB Level 5',
  LABEL_LOWER_LIMIT: 'Lower limit',
  LABEL_UPPER_LIMIT: 'Upper limit',
  LOWER_LIMIT_PE: 'Lower limit (Avg P/E 90 days)',
  UPPER_LIMIT_PE: 'Upper limit (Avg P/E 90 days)',
  LOWER_LIMIT_PB: 'Lower limit (Avg P/B 90 days)',
  UPPER_LIMIT_PB: 'Upper limit (Avg P/B 90 days)',
  LABEL_INDEX_POINT: 'Index (Point)',
  LABEL_PRICE: 'Price (Thousand VND) ',
  LABEL_PE: 'P/E (Times)',
  LABEL_PB: 'P/B (Times)',
  LB_COUNT_COMPARE: 'Selected: ',
  LB_TIMES: '(Times)',
  LB_POINT: '(Points)',
}

export const indexStatistics = {
  PRICE_DATA: 'PRICE DATA',
  VALUE_INVESTOR: 'NET VALUE BY INVESTOR',
  NET_VALUE: 'NET VALUE BREAKDOWN',

  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',

  SCREEN_LABEL: 'Index Statistics',
  TABLE_FOOTER_TEXT: 'The data is calculated as of ',

  // TABS
  OVER_VIEW: 'Overview',
  ORDER_STATISTICS: 'Order Statistics',
  FOREIGN: 'Foreign',
  PROPRIETARY: 'Proprietary',
  STATISTICS_INVESTOR: 'Statistics by investor',

  // COMPONENT
  valueInvestor: {
    NET_VALUE: 'NET VALUE',
    PERCENT_VALUE: '% VALUE',
    NET_VALUE_UNIT: 'Value (Billion VND)',
    PERCENT_VALUE_UNIT: 'Value',
    PRICE_UNIT: 'Price (Thousand VND)',
    INDEX_POINT: 'Index (Point)',

    LOCAL_INSTITUTION: 'Local Institution',
    FOREIGN: 'Foreign',
    PROPRIETARY: 'Proprietary',
    LOCAL_INDIVIDUAL: 'Local Individual',
    BILLION: 'Bn.',
  },

  netValue: {
    VALUE_UNIT: 'Value (Billion VND)',
    BUY: 'Buy',
    SELL: 'Sell',
    NET: 'Net',
    BILLION: 'Bn.',
    VALUE_BREAKDOWN: 'Value breakdown',
  },

  listType: {
    FOREIGN: 'Foreign',
    LOCAL_INSTITUTION: 'Local Institution',
    LOCAL_INDIVIDUAL: 'Local Individual',
    PROPRIETARY: 'Proprietary',
  },

  hyperlink: {
    COMPANY_OVERVIEW: 'Company Overview',
    COMPANY_NEWS: 'Company News',
    FINANCIAL_DATA: 'Financial Data',
    BUSINESS_MODAL: 'Business Model',
    SECTOR_OVERVIEW: 'Sector Overview',
    TECHNICAL_CHART: 'Technical Chart',
  },

  transactionType: {
    ALL: 'All transactions',
    MATCH: 'Match',
    DEAL: 'Deal',
  },

  priceData: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',
    CUSTOM: 'Custom',
    DAILY_DETAIL: 'Latest',
    WEEKLY_DETAIL: 'WTD',
    MONTHLY_DETAIL: 'MTD',
    QUARTERLY_DETAIL: 'QTD',
    YEARLY_DETAIL: 'YTD',
    CUSTOM_DETAIL: 'Custom',

    DATE: 'DATE',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR',

    FROM_DATE: 'From',
    TO_DATE: 'To',
    CLEAR_DATE: 'Clear',

    tableType: {
      OVERVIEW: 'Overview',
      DETAIL: 'Detail',
    },

    PRICE_RATE_PRICE_VOLUME_VALUE:
      'Price: x 1,000. Volume: x 1,000. Value: x 1,000,000.',
    PRICE_RATE_VOLUME_VALUE: 'Volume: x 1,000. Value: x 1,000,000.',

    overview: {
      DATE: 'DATE',
      TICKER: 'TICKER',
      LAST: 'LAST',
      CHANGE: 'CHANGE',
      PERCENT_CHANGE_DAILY: '%1D CHANGE',
      PERCENT_CHANGE_WEEKLY: '%1W Change',
      PERCENT_CHANGE_MONTHLY: '%1M Change',
      PERCENT_CHANGE_YEARLY: '%1Y Change',
      PERCENT_CHANGE_QUARTERLY: '%1Q Change',
      PERCENT_CHANGE_CUSTOM: '% CHANGE',
      OPEN: 'OPEN',
      HIGH: 'HIGH',
      LOW: 'LOW',
      CLOSE: 'CLOSE',
      AVG_CLOSE_PRICE: 'CLOSE',
      MATCHED_VOLUME: 'MATCHED VOL.',
      MATCHED_VALUE: 'MATCHED VAL.',
      PUT_THROUGH_VALUE: 'PUT THROUGH VAL.',
      PUT_THROUGH_VOLUME: 'PUT THROUGH VOL.',
      TOTAL_VOL: 'TOTAL VOL.',
      TOTAL_VALUE: 'TOTAL VAL.',
      MARKET_CAP: 'MARKET CAP',
    },
    orderStatistic: {
      DATE: 'DATE',
      TICKER: 'TICKER',
      UNMATCH_BUY_VOL: 'UNMATCHED BUY VOL.',
      UNMATCH_SELL_VOL: 'UNMATCHED SELL VOL.',
      NR_BUY_ORDER: 'NR. OF BUY ORDER',
      BUY_VOL: 'BUY VOL.',
      VOL_BUY_ORDER: 'VOL/BUY ORDER',
      NR_SELL_ORDER: 'NR. OF SELL ORDER',
      SELL_VOL: 'SELL VOL.',
      VOL_SELL_ORDER: 'VOL/SELL ORDER',
      NET_VOL: 'NET VOL.',
    },
    foreign: {
      DATE: 'DATE',
      TICKER: 'TICKER',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      FOREIGN_CR_ROOM: 'FOREIGN CURRENT ROOM',
      FOREIGN_TOTAL_ROOM: 'FOREIGN TOTAL ROOM',
      PERCENT_FOREIGN_TOTAL_ROOM: '% FOREIGN TOTAL ROOM',
      FOREIGN_OWNED: 'FOREIGN OWNED',
      PERCENT_FOREIGN_OWNED: '% FOREIGN OWNED',
    },
    proprietary: {
      DATE: 'DATE',
      TICKER: 'TICKER',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      MATCH_NET_VOL: 'NET MATCHED VOL.',
      MATCH_NET_VAL: 'NET MATCHED VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      DEAL_NET_VOL: 'NET DEAL VOL.',
      DEAL_NET_VAL: 'NET DEAL VAL.',
    },
    statisticsInvest: {
      DATE: 'DATE',
      TICKER: 'TICKER',
      LOCAL_INDIVIDUAL: 'LOCAL INDIVIDUAL',
      LOCAL_INSTITUTION: 'LOCAL INSTITUTION',
      FOREIGN_INDIVIDUAL: 'FOREIGN INDIVIDUAL',
      FOREIGN_INSTITUTION: 'FOREIGN INSTITUTION',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      MATCH_NET_VOL: 'NET MATCHED VOL.',
      MATCH_NET_VAL: 'NET MATCHED VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      DEAL_NET_VOL: 'NET DEAL VOL.',
      DEAL_NET_VAL: 'NET DEAL VAL.',
    },
  },
}

export const localInstitution = {
  NAME_SCREEN: 'Local Institution',
  NET_VALUE: 'Net Value',
  TOP_NET_VALUE: 'top net value',
  SECTOR_SEGMENTATION: 'sector segmentation',
  VALUE_LABEL: 'Value (Billion VND)',
  INDEX_LABEL: 'Index (Point)',
  LOCAL_INSTITUTION_NET_VALUE: 'Proprietary Net Value',
  BUY: 'BUY',
  SELL: 'SELL',
  NOTE_TOP_NET_TABLE: 'Price: x1,000. Volume: x1,000. Value: x1,000,000',
  TICKER: 'TICKER',
  PRICE: 'PRICE',
  TOTAL_VOLUME: 'TOTAL VOL.',
  TOTAL_BUY_VALUE: 'TOTAL BUY VAL.',
  TOTAL_SELL_VALUE: 'TOTAL SELL VAL.',
  NET_TOTAL_VALUE: 'NET TOTAL VAL.',
  PERCENT_LOCAL_INSTITUTION: '% PROPRIETARY',
  SECTOR: 'SECTOR',
  TOTAL: 'Total',
  BUY_VAL: 'BUY VAL.',
  SELL_VAL: 'SELL VAL.',
  NET_VAL: 'NET VAL.',
  OTHERS: 'Others',
  BILLION: 'Bn.',
  MILLION: 'Mn.',
  NOTE_SECTOR: 'Value: x 1,000,000',
  LB_FROM: 'From',
  CLEAR: 'Clear',
  TOTAL_BUY_VOLUME: 'TOTAL BUY VOLUME',
  TOTAL_SELL_VOLUME: 'TOTAL SELL VOLUME',
}

export const foreign = {
  NAME_SCREEN: 'Foreign',
  NET_VALUE: 'Net Value',
  OWNERSHIP: 'Ownership',
  OWNERSHIP_CHART: 'Ownership',
  ALL: 'All',
  TOP_NET_VALUE: 'top net value',
  VALUE_BY_SECTOR_SEGMENTATION: 'value by sector segmentation',
  UNIT_BILLION: 'Value (Billion)',
  POINT: 'Index (Point)',
  FOREIGN_NET_VALUE: 'Foreign Net Value',
  OWNERSHIP_LB_LEFT: 'Fr. Owned',
  FOREIGN_OWNERSHIP: 'Foreign Ownership',
  NOTE_TOP_NET_TABLE: 'Price: x1,000. Volume: x1,000. Value: x1,000,000',
  TICKER: 'TICKER',
  PRICE: 'PRICE',
  TOTAL_VOLUME: 'TOTAL VOLUME',
  TOTAL_BUY_VALUE: 'TOTAL BUY VALUE',
  TOTAL_SELL_VALUE: 'TOTAL SELL VALUE',
  NET_TOTAL_VALUE: 'NET TOTAL VALUE',
  PERCENT_OWN: '% OWN',
  SECTOR: 'SECTOR',
  TOTAL: 'Total',
  BUY_VAL: 'BUY VAL.',
  SELL_VAL: 'SELL VAL.',
  NET_VAL: 'NET VAL.',
  OTHERS: 'Others',
  BUY: 'BUY',
  SELL: 'SELL',
  BILLION: 'Bn.',
  MILLION: 'Mn.',
  NOTE_SECTOR: 'Value: x 1,000,000',
  LB_FROM: 'From',
  CLEAR: 'Clear',
}
