export const COLORS_LINE_CHART = [
  '#c7ff58',
  '#9c54e4',
  '#185999',
  '#ecffdf',
  '#f57f07',
  '#c52e14',
  '#975000',
  '#1a36a9',
  '#57426d',
  '#f9c105',
  '#a103fc',
  '#fc6203',
  '#03fca5',
  '#03d7fc',
  '#fc03f4',
  '#90fc03',
  '#628200',
  '#3452eb',
  '#eb3462',
  '#9b8e9e',
]

export const TURN_OVER_MONTHLY_DATE_FILTER = [
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
  { title: '5Y', value: 'FiveYears' },
  { title: '10Y', value: 'TenYears' },
  { title: 'economy.importExport.importExport.ALL', value: 'AllTime' },
]

export const TURN_OVER_YEARLY_DATE_FILTER = [
  { title: '5Y', value: 'FiveYears' },
  { title: '7Y', value: 'SevenYears' },
  { title: '10Y', value: 'TenYears' },
  { title: '20Y', value: 'TwentyYears' },
  { title: 'economy.importExport.importExport.ALL', value: 'AllTime' },
]
