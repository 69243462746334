import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import { selectFundInfo, selectPriceData, subscribeFund } from './store/slice'
import { getLatestPrice } from './store/thunk'

export const PriceData = () => {
  const dispatch = useDispatch()
  const fundInfo = useSelector(selectFundInfo)
  const priceData = useSelector(selectPriceData)

  useEffect(() => {
    if (fundInfo.organizationId) {
      dispatch(
        getLatestPrice({
          Ids: fundInfo.organizationId,
          GroupType: GROUP_TYPE.FUND,
        }),
      )
    }
  }, [fundInfo.organizationId])

  return (
    <div className="info-box">
      <Realtime
        channel={ChannelConfig.StockChannel + fundInfo.organizationId}
        action={subscribeFund}
      >
        <SummaryInfoPrice data={{ ...priceData, isTicker: true }} />
        {/* <AdditionalInfoPrice data={{ ...priceData, isTicker: true }} /> */}
      </Realtime>
    </div>
  )
}
