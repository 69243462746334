import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeTickerId,
  selectActiveSheet,
  selectTickerId,
} from '../../../../common/dataExplorer/corporate/store/slice'
import { keyBy } from '../../../../utils/Common'

const HandleFilterTickerInSector = ({ listTicker, setListChecked }) => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const tickerId = useSelector(selectTickerId(activeSheet))

  const [listTickerById, setListTickerById] = useState(null)

  useEffect(() => {
    if (listTicker?.length) {
      setListTickerById(keyBy(listTicker, 'organizationId'))
    } else {
      setListTickerById(null)
    }
  }, [listTicker])

  useEffect(() => {
    if (listTickerById) {
      const newTickerId = []
      let isDispatchChangeTickerId = false
      tickerId.forEach((id) => {
        if (listTickerById[id]) {
          newTickerId.push(id)
        } else {
          isDispatchChangeTickerId = true
        }
      })
      setListChecked(newTickerId)
      if (isDispatchChangeTickerId) {
        dispatch(changeTickerId(newTickerId))
      }
    }
  }, [listTickerById, tickerId])

  return <></>
}

export default HandleFilterTickerInSector
