import { useState } from 'react'
import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import ExportSetting from './panelExportSetting'
import Preview from './panelPreview'
import SelectedItems from './panelSelectedItems'
import TradingDataFilter from './TradingDataFilter'

const MAP_KEY = {
  FILTER: 'FILTER',
  SELECTED_ITEMS: 'SELECTED_ITEMS',
  EXPORT_SETTING: 'EXPORT_SETTING',
  PREVIEW: 'PREVIEW',
}

const MAP_SIZE = {
  [MAP_KEY.FILTER]: {
    width: `calc(16% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(100%)`,
    top: 0,
    left: 0,
    minWidth: 160 - 2 * COMPONENT.MARGIN,
    minHeight: 500,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.SELECTED_ITEMS,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.SELECTED_ITEMS]: {
    width: `calc(24% - ${2 * COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: '16%',
    minWidth: 240 - 2 * COMPONENT.MARGIN,
    minHeight: 500,
    minTop: 0,
    minLeft: 160,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.EXPORT_SETTING,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.PREVIEW,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.EXPORT_SETTING]: {
    width: `calc(60%)`,
    height: 190 - 2 * COMPONENT.MARGIN,
    top: 0,
    left: '40%',
    minWidth: 600,
    minHeight: 180 - 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 400,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.PREVIEW]: {
    width: `calc(60%)`,
    height: `calc(100% - ${190}px)`,
    top: 190,
    left: '40%',
    minWidth: 600,
    minHeight: 310,
    minTop: 190,
    minLeft: 400,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState(false)

  return (
    <>
      <PanelFilter
        title={'tool.dataExplorer.tradingData.FILTER'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <TradingDataFilter />
      </PanelFilter>
      <Panel
        title={'tool.dataExplorer.tradingData.SELECTED_ITEMS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SELECTED_ITEMS}
        sizes={sizes}
        setSizes={setSizes}
      >
        <SelectedItems />
      </Panel>
      <Panel
        title={'tool.dataExplorer.tradingData.EXPORT_SETTING'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.EXPORT_SETTING}
        sizes={sizes}
        setSizes={setSizes}
      >
        <ExportSetting />
      </Panel>
      <Panel
        title={'tool.dataExplorer.tradingData.PREVIEW'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PREVIEW}
        sizes={sizes}
        setSizes={setSizes}
        isLoadingDownloadCsv={isLoadingDownloadCsv}
      >
        <Preview setIsLoadingDownloadCsv={setIsLoadingDownloadCsv} />
      </Panel>
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
