import moment from 'moment'
export const FILTER_MIN_YEAR = 2004

export const TABLE_INDICATORS = {
  date: 'date',
  ticker: 'ticker',
  event: 'event',
  description: 'description',
  exrightDate: 'exrightDate',
  recordDate: 'recordDate',
  payoutDate: 'payoutDate',
  issueDate: 'issueDate',
  valuePerShare: 'valuePerShare',
  exerciseRate: 'exerciseRate',
  dividendYear: 'dividendYear',
  duration: 'duration',
  source: 'source',
  period: 'period',
  netRevenue: 'netRevenue',
  revenueGrowth: 'revenueGrowth',
  netProfit: 'netProfit',
  profitGrowth: 'profitGrowth',
  listingDate: 'listingDate',
  method: 'method',
  issueVolumn: 'issueVolume',
  totalValue: 'totalValue',
  year: 'year',
  status: 'status',
  address: 'address',
  meetingDate: 'meetingDate',
  symbol: 'symbol',
  growth: 'growth',
  type: 'type',
  futureName: 'futureName',
  owner: 'owner',
  position: 'position',
  shareRegister: 'shareRegister',
  shareAcquire: 'shareAcquire',
  ownedPercentage: 'ownedPercentage',
  exerciseRatio: 'exerciseRatio',
  exerciseDate: 'exerciseDate',
  actionTypeName: 'actionTypeName',
  city: 'city',
  time: 'time',
  organizationName: 'organizationName',
  shareBeforeTrade: 'shareBeforeTrade',
  shareAfterTrade: 'shareAfterTrade',
  ownershipAfterTrade: 'ownedPercentage',
  endDate: 'endDateId',
  roleOwner: 'roleOwner',
  relative: 'relative',
  oldExchange: 'oldExchange',
  newExchange: 'newExchange',
  startDateId: 'startDateId',
}

export const TABLE_INDICATORS_SUMMARY = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.valuePerShare,
  TABLE_INDICATORS.source,
]

export const TABLE_INDICATORS_ALIGN_LEFT = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.address,
  TABLE_INDICATORS.city,
  TABLE_INDICATORS.time,
  TABLE_INDICATORS.source,
  TABLE_INDICATORS.period,
  TABLE_INDICATORS.method,
  TABLE_INDICATORS.listingDate,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.oldExchange,
  TABLE_INDICATORS.newExchange,
  TABLE_INDICATORS.organizationName,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.relative,
  TABLE_INDICATORS.position,
  TABLE_INDICATORS.roleOwner,
  TABLE_INDICATORS.startDateId,
]

export const TABLE_INDICATORS_ALIGN_RIGHT = [
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.valuePerShare,
  TABLE_INDICATORS.dividendYear,
  TABLE_INDICATORS.issueVolumn,
  TABLE_INDICATORS.totalValue,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.ownershipAfterTrade,
]

export const TABLE_INDICATORS_DATE = [
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.listingDate,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.issueDate,
  TABLE_INDICATORS.payoutDate,
  TABLE_INDICATORS.startDateId,
]

export const TABLE_INDICATORS_FORMAT_PERCENTAGE = [
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.ownershipAfterTrade,
]

export const TABLE_INDICATORS_FORMAT_VALUE = [
  TABLE_INDICATORS.valuePerShare,
  TABLE_INDICATORS.issueVolumn,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
]

export const TABLE_INDICATORS_FORMAT_MIL = [TABLE_INDICATORS.totalValue]

export const annualGeneralMeetingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.address,
  TABLE_INDICATORS.city,
  TABLE_INDICATORS.time,
  TABLE_INDICATORS.source,
]
export const aGMResolutionIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const additionalListingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const nameChangeIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const recorddateforballotIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const keyPersonnelChangeIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const bODMeetingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const finalAnnualResultsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const quarterlyResultsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const yearInterimResultIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const mergerAndAcquisitionIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const newListingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const otherEventsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const reListingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const listingSuspensionIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const treasuryStocksIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.startDateId,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const liquidationIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const cashDividendIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.valuePerShare,
  TABLE_INDICATORS.dividendYear,
  TABLE_INDICATORS.period,
  TABLE_INDICATORS.source,
]
export const shareIssueIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.listingDate,
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.issueVolumn,
  TABLE_INDICATORS.totalValue,
  TABLE_INDICATORS.method,
  TABLE_INDICATORS.dividendYear,
  TABLE_INDICATORS.period,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.source,
]
export const extraordinaryGeneralMeetingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.address,
  TABLE_INDICATORS.city,
  TABLE_INDICATORS.time,
  TABLE_INDICATORS.source,
]
export const exchangeSwitchingIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.oldExchange,
  TABLE_INDICATORS.newExchange,
  TABLE_INDICATORS.source,
]
export const directorDealIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.organizationName,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.ownershipAfterTrade,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.position,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const directorDealIndividualtransactionsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.organizationName,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.ownershipAfterTrade,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.position,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const directorDealInstitutionaltransactionsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.organizationName,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.ownershipAfterTrade,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.position,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]
export const directorDealRelatedPersontransactionsIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.organizationName,
  TABLE_INDICATORS.roleOwner,
  TABLE_INDICATORS.shareBeforeTrade,
  TABLE_INDICATORS.shareRegister,
  TABLE_INDICATORS.actionTypeName,
  TABLE_INDICATORS.shareAcquire,
  TABLE_INDICATORS.shareAfterTrade,
  TABLE_INDICATORS.ownershipAfterTrade,
  TABLE_INDICATORS.status,
  TABLE_INDICATORS.position,
  TABLE_INDICATORS.relative,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.endDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.source,
]

export const summaryIndicators = [
  TABLE_INDICATORS.ticker,
  TABLE_INDICATORS.date,
  TABLE_INDICATORS.recordDate,
  TABLE_INDICATORS.exrightDate,
  TABLE_INDICATORS.exerciseDate,
  TABLE_INDICATORS.event,
  TABLE_INDICATORS.exerciseRatio,
  TABLE_INDICATORS.valuePerShare,
  TABLE_INDICATORS.source,
]

export const FIINX_EVENT_TYPE = {
  annualGeneralMeeting: {
    id: 1,
  },
  aGMResolution: {
    id: 2,
  },
  additionalListing: {
    id: 3,
  },
  nameChange: {
    id: 4,
  },
  recordDateForBallot: {
    id: 5,
  },
  keyPersonnelChange: {
    id: 6,
  },
  bODMeeting: {
    id: 7,
  },
  finalAnnualResults: {
    id: 23,
  },
  quarterlyResults: {
    id: 24,
  },
  yearInterimResult: {
    id: 25,
  },
  mergerAndAcquisition: {
    id: 26,
  },
  newListing: {
    id: 28,
  },
  otherEvents: {
    id: 29,
  },
  reListing: {
    id: 30,
  },
  listingSuspension: {
    id: 31,
  },
  treasuryStocks: {
    id: 32,
  },
  liquidation: {
    id: 33,
  },
  cashDividend: {
    id: 20,
  },
  shareIssue: {
    id: 22,
  },
  extraordinaryGeneralMeeting: {
    id: 21,
  },
  exchangeSwitching: {
    id: 27,
  },
  directorDeal: {
    id: 16,
  },
  directorDealIndividualTransactions: {
    id: 17,
  },
  directorDealInstitutionalTransactions: {
    id: 18,
  },
  directorDealRelatedPersonTransactions: {
    id: 19,
  },
}
export const exceptGroupIdCheckHOSE = [4, 5, 6]

export const FILTER_FIELD_CODES = {
  BOND_TICKER: 'BondTicker',
  ISSUE_DATE: 'IssueDateId',
  MATURITY_DATE: 'MaturityDateId',
  DURATION: 'DurationName',
  ISSUE_VALUE: 'IssueValue',
  OUT_VALUE: 'Outsdval',
  REMAIN_DURATION: 'RemainDuration',
  COUPON: 'RBD0009',
  COUPON_FIXED: 'FixedInterestRate',
  TRADING_DATE: 'TradingDateId',
  COLLATERAL: 'Collateral',
  PAYMENT: 'PaymentGuarantee',
  ORGANIZATION: 'OrganizationShortName',
  STATUS: 'ActiveStatusName',
  INDUSTRY: 'IcbName',
  REDEMPTION_DATE: 'RedemptionEndDateId',
  BOND_TYPE: 'BondTypeName',
}

export const ISSUE_DATE_OPTIONS = [
  {
    name: 'YTD',
    date: [moment().startOf('year'), moment()],
  },
  {
    name: 'QTD',
    date: [moment().startOf('quarter'), moment()],
  },
  {
    name: 'MTD',
    date: [moment().startOf('month'), moment()],
  },
  {
    name: 'WTD',
    date: [moment().startOf('week'), moment()],
  },
]

export const MATURITY_DATE_OPTIONS = [
  {
    name: 'bond.bondScreening.filter.YTD',
    date: [moment(), moment().endOf('year')],
  },
  {
    name: 'bond.bondScreening.filter.QTD',
    date: [moment(), moment().endOf('quarter')],
  },
  {
    name: 'bond.bondScreening.filter.MTD',
    date: [moment(), moment().endOf('month')],
  },
]
