import { Loading } from '../../../../common/loading'
import Tree from '../../../../common/tree'
import { getAllChildrenItem } from '../../../../common/tree/helper'
import style from '../style.module.css'

const ListEconomy = ({
  valueSearch,
  loadingEconomy,
  indicatorsTree,
  securitiesState,
  setSecuritiesState,
}) => {
  const setListChecked = (value) => {
    setSecuritiesState({
      ...securitiesState,
      economy: value.map((item) => {
        const detailItem = item.split('_')
        return {
          id: +detailItem[0],
          timeFrequency: detailItem[1],
        }
      }),
    })
  }

  const getListChecked = () => {
    return securitiesState.economy.map(
      (item) => `${item.id}_${item.timeFrequency}`,
    )
  }

  const handleDoubleClickItem = (item) => {
    const dataAdd = getAllChildrenItem(item).map((el) => ({
      id: +el.parentId,
      timeFrequency: el.timeFrequency,
    }))
    setSecuritiesState((oldState) => {
      const dataEco = [...oldState.economy, ...dataAdd].reduce((unique, o) => {
        if (
          !unique.some(
            (obj) => obj.id === o.id && obj.timeFrequency === o.timeFrequency,
          )
        ) {
          unique.push(o)
        }
        return unique
      }, [])
      return {
        ...oldState,
        economy: dataEco,
      }
    })
  }

  return (
    <>
      <div className="h-100 ">
        {loadingEconomy ? (
          <div className="position-relative h-100">
            <Loading isBlack />
          </div>
        ) : (
          <div
            style={{
              listStyle: 'none',
              margin: 0,
              paddingLeft: 8,
              height: '100%',
            }}
          >
            <Tree
              valueSearch={valueSearch}
              treeData={indicatorsTree}
              idKey="id"
              nameKey="name"
              sortKey="order"
              doubleClickItem={handleDoubleClickItem}
              isHaveCheckbox={true}
              listChecked={getListChecked()}
              setListChecked={setListChecked}
              classItemStyle={style.itemStyle}
              classCheckboxStyle={style.checkboxStyle}
              bgColorScroll="#D9D9D9"
              isHaveCheckboxAll
              isCheckAllByValueSearch
              titleCheckboxAll="financialChart.ALL"
              isCheckedWhenClickText
            />
          </div>
        )}
      </div>
    </>
  )
}

export default ListEconomy
