import { useSelector } from 'react-redux'
import { selectIcbInfoById } from '../../../common/masterData/store/slice'
import TextEllipsis from '../../../common/textEllipsis'

const SectorName = ({ icbId, isEllipsis = true, className }) => {
  const sectorName = useSelector(selectIcbInfoById(icbId, 'icbName'))
  const sectorLevel = useSelector(selectIcbInfoById(icbId, 'icbLevel'))

  const text = sectorName + ' L' + sectorLevel

  return (
    <div className={className}>
      {isEllipsis ? <TextEllipsis isI18n={false} text={text} /> : text}
    </div>
  )
}

export default SectorName
