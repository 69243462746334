import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../../common/sizeTracker'
import TimeFrame from '../../common/TimeFrame'
import {
  getCheckboxChecked,
  getValueMOM,
} from '../panelMoneySupplyCredit/store/slice'
import LineChart from './LineChart'
import { TIME_FRAME_OPTIONS } from './constants'
import { getLineChartData, getTimeRange, handleTimeRange } from './store/slice'
import { getMoneySupplyOutstandingLoanGrowth } from './store/thunk'

const ChartComponent = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()

  const timeRange = useSelector(getTimeRange)
  const lineChartData = useSelector(getLineChartData)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const valueMoM = useSelector(getValueMOM)
  const isValue = valueMoM === 'Value'

  const onChangeTab = (tab) => {
    dispatch(handleTimeRange(tab.value))
  }

  useEffect(() => {
    const monetaryTypeIds = Object.values(checkboxChecked)
    if (monetaryTypeIds.length) {
      dispatch(
        getMoneySupplyOutstandingLoanGrowth({
          monetaryTypeIds,
          timeRange,
          sbvType: valueMoM,
        }),
      )
    }
  }, [checkboxChecked, timeRange, valueMoM])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <TimeFrame
              timeRange={timeRange}
              onChangeTab={onChangeTab}
              timeFrameOption={TIME_FRAME_OPTIONS}
              styleProps={{ justifyContent: 'flex-end' }}
            />
            {typeof sizes.height === 'number' && (
              <LineChart
                width={width}
                height={height - sizes.height}
                data={lineChartData}
                keyXAxis={'monthYear'}
                keyYAxisProp={Object.keys(checkboxChecked)}
                isValue={isValue}
                isFullScreen={isFullScreen}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

ChartComponent.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default ChartComponent
