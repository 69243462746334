import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ANNOUNCEMENT_TYPE } from '../constants'
import { HeaderCommon } from '../HeaderCommon'
import {
  selectNewsBusinesses,
  selectNewsBusinessLoading,
  selectNewsCategoriesLoading,
  selectNewsEarningLoading,
  selectNewsEarnings,
  selectNewsSourcesLoading,
  selectNewsTransactionLoading,
  selectNewsTransactions,
} from '../store/slice'

export const Header = () => {
  const businesses = useSelector(selectNewsBusinesses)
  const earnings = useSelector(selectNewsEarnings)
  const transactions = useSelector(selectNewsTransactions)
  const isBusinessLoading = useSelector(selectNewsBusinessLoading)
  const isTransactionLoading = useSelector(selectNewsTransactionLoading)
  const isEarningLoading = useSelector(selectNewsEarningLoading)
  const isSourcesLoading = useSelector(selectNewsSourcesLoading)
  const isCategoriesLoading = useSelector(selectNewsCategoriesLoading)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const announcementType =
    params.get('announcementType') || ANNOUNCEMENT_TYPE.EARNING

  const getData = () => {
    switch (announcementType) {
      case ANNOUNCEMENT_TYPE.BUSINESS:
        return {
          data: businesses,
          isLoading: isBusinessLoading,
        }
      case ANNOUNCEMENT_TYPE.EARNING:
        return {
          data: earnings,
          isLoading: isEarningLoading,
        }
      case ANNOUNCEMENT_TYPE.TRANSACTION:
        return {
          data: transactions,
          isLoading: isTransactionLoading,
        }
      default:
        throw Error('Pls set announcement type correctly!')
    }
  }

  const { data: objectData, isLoading } = getData()
  const { data } = objectData

  return (
    <HeaderCommon
      isLoading={isLoading || isSourcesLoading || isCategoriesLoading}
      data={data.length > 0 ? data[0] : {}}
    />
  )
}
