import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import Dropdown, { positionDropdown } from '../dropdown'
import { Span } from '../html/Span'
import style from './index.module.css'

const paddingTooltip = 9
const heightArrowTooltip = 9 //set in CSS class box-notice
const maxWidthTooltip = 300

const DropdownTooltip = ({
  containerRef,
  isOpenTooltip,
  zIndexTooltip,
  isI18n,
  text,
  isHtml,
  styleTextTooltip,
}) => {
  const [positionTooltip, setPositionTooltip] = useState(
    positionDropdown.TOP_CENTER,
  )

  useEffect(() => {
    if (containerRef.current) {
      const { left, right } = containerRef.current.getBoundingClientRect()
      if (left < maxWidthTooltip)
        setPositionTooltip(positionDropdown.TOP_LEFT_RIGHT)
      else if (window.innerWidth - right < maxWidthTooltip)
        setPositionTooltip(positionDropdown.TOP_RIGHT_LEFT)
      else setPositionTooltip(positionDropdown.TOP_CENTER)
    }
  }, [containerRef.current])

  const customPosition = (position) => {
    return {
      ...position,
      top: position?.top ? position.top - 1 : position?.top,
    }
  }

  return (
    <Dropdown
      parentRef={containerRef}
      isShow={isOpenTooltip}
      position={positionTooltip}
      zIndex={zIndexTooltip}
      appendStyle={{ pointerEvents: 'none' }}
      customPosition={customPosition}
    >
      <div
        className={`box-notice ${style.tooltip} ${
          positionTooltip === positionDropdown.TOP_LEFT_RIGHT
            ? style.tooltipLeft
            : positionTooltip === positionDropdown.TOP_RIGHT_LEFT
            ? style.tooltipRight
            : ''
        }`}
        style={{
          padding: `${paddingTooltip}px`,
          bottom: `${heightArrowTooltip}px`,
          maxWidth: `${maxWidthTooltip}px`,
        }}
      >
        {isI18n ? (
          <Span style={{ fontSize: 9, ...styleTextTooltip }}>
            <Translate value={text} dangerousHTML className={style.breakWord} />
          </Span>
        ) : isHtml ? (
          <Span style={{ fontSize: 9, ...styleTextTooltip }}>
            <div
              dangerouslySetInnerHTML={{ __html: text }}
              className={style.breakWord}
            ></div>
          </Span>
        ) : (
          <Span style={{ fontSize: 9, ...styleTextTooltip }}>
            <span className={style.breakWord}>{text}</span>
          </Span>
        )}
      </div>
    </Dropdown>
  )
}

DropdownTooltip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  isI18n: PropTypes.bool,
  zIndexTooltip: PropTypes.number,
  containerRef: PropTypes.object.isRequired,
  isOpenTooltip: PropTypes.bool.isRequired,
  dropdownStyle: PropTypes.object,
  styleTextTooltip: PropTypes.object,
}

DropdownTooltip.defaultProps = {
  isI18n: true,
  zIndexTooltip: Z_INDEX.MODAL + 1,
  dropdownStyle: {},
  isHtml: false,
  styleTextTooltip: {},
}

export default DropdownTooltip
