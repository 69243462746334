import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Z_INDEX } from '../../constants/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  FULL_COMPONENT,
} from '../../utils/EventEmitter'
import { groupScrollComponentClass } from '../ScrollGroupComponent'
import className from './zoomWindow.module.css'

const ZoomWindow = ({
  panelRefs,
  panelKey,
  classVerticalResize,
  classHorizontalResize,
  sizes,
  setSizes,
  handleCustom,
}) => {
  const [fullScreen, setFullScreen] = useState(false)
  const [initSizesComponent, setInitSizesComponent] = useState()

  const elVerResize = document.getElementsByClassName(classVerticalResize)[0]
  const elHorResize = document.getElementsByClassName(classHorizontalResize)[0]

  const setStyleDisplayForElResize = (el, type = '') => {
    if (el) {
      el.style.display = type
    }
  }

  const onClickSetFullScreen = () => {
    setFullScreen(true)
    setInitSizesComponent({ initSizes: sizes })
    EventEmitter.dispatch(FULL_COMPONENT, panelKey)
  }

  const onClickClearFullScreen = () => {
    setFullScreen(false)
    setSizes(initSizesComponent.initSizes)
    setTimeout(() => {
      EventEmitter.dispatch(CLOSE_FULL_COMPONENT, panelKey)
    }, 0)
  }

  useEffect(() => {
    if (panelRefs) {
      if (fullScreen) {
        panelRefs.style.zIndex = Z_INDEX.ZOOM_WINDOW

        panelRefs.classList.add(className.maximise)

        setStyleDisplayForElResize(elVerResize, 'none')
        setStyleDisplayForElResize(elHorResize, 'none')

        setInitSizesComponent({ initSizes: sizes })
        EventEmitter.dispatch(FULL_COMPONENT)
      } else {
        panelRefs.style.zIndex = null

        panelRefs.classList.remove(className.maximise)

        setStyleDisplayForElResize(elVerResize)
        setStyleDisplayForElResize(elHorResize)

        setSizes(initSizesComponent.initSizes)
        setTimeout(() => {
          EventEmitter.dispatch(CLOSE_FULL_COMPONENT)
        }, 0)
      }
    }
    handleCustom?.(fullScreen)
  }, [fullScreen])

  const elGroupScroll = document.querySelector(
    `#${groupScrollComponentClass} .scrollbars div:first-child`,
  )

  const elWrappedComponentId = document.querySelector(
    `#${'WrappedComponentId'}`,
  )

  return (
    <>
      {fullScreen ? (
        <a className="cursor-pointer">
          <i className="icon-minus" onClick={onClickClearFullScreen} />
        </a>
      ) : (
        <a className="cursor-pointer">
          <i className="icon-window" onClick={onClickSetFullScreen} />
        </a>
      )}
      {fullScreen &&
        createPortal(
          <style>
            {`.${className.maximise}{
             height: calc(100% + ${
               elGroupScroll?.style?.marginBottom || '-0px'
             }) !important;
             width:  ${
               elWrappedComponentId?.offsetWidth
                 ? `${elWrappedComponentId?.offsetWidth}px !important;`
                 : '100% !important;'
             }
          }`}
          </style>,
          document.body,
        )}
    </>
  )
}

export default ZoomWindow
