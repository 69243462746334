import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../../common/selectCustom'
import { LOAN_SECTOR } from '../../constant'
import { changeLoanSector, selectLoanSector } from '../store/slice'

const LoanSectorFilter = () => {
  const dispatch = useDispatch()

  const loanSector = useSelector(selectLoanSector)

  const handleChangeLoanSector = (value) => dispatch(changeLoanSector(value))

  return (
    <div style={{ width: 259 }}>
      <SelectCustom
        isI18n={true}
        selectData={Object.keys(LOAN_SECTOR).map((key) => ({
          name: 'corporate.businessModel.penetrationAnalysis.loanSector.' + key,
          value: LOAN_SECTOR[key],
        }))}
        value={loanSector}
        handleChange={handleChangeLoanSector}
        maxItemDropdown={Object.keys(LOAN_SECTOR).length}
        appendStyle={{ fontWeight: 'normal' }}
      />
    </div>
  )
}

export default LoanSectorFilter
