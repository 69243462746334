import { useDispatch, useSelector } from 'react-redux'
import { DispatchActionTabWithCalendar } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { TABLE_TIME_RANGES } from './constants'
import { getSortFieldDefaultByFrequency } from './helpers'
import { changeFilter, selectFilters } from './store/slice'

export const Filter = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilters)

  const handleChangeTimeRange = (item) => {
    dispatch(
      changeFilter({
        frequency: item.value,
        sortField: getSortFieldDefaultByFrequency({ frequency: item.value }),
        sortOrder: 1,
        page: 1,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        frequency: payload.activeTab,
        startDate: payload.startDate,
        endDate: payload.endDate,
        sortField: getSortFieldDefaultByFrequency({
          frequency: payload.activeTab,
        }),
        sortOrder: 1,
        page: 1,
      }),
    )
  }

  return (
    <DispatchActionTabWithCalendar
      id="navDataTable"
      tabs={TABLE_TIME_RANGES}
      activeTab={filter.frequency}
      startDate={filter.startDate}
      endDate={filter.endDate}
      datePreset={TABLE_TIME_RANGES}
      onChangeTab={handleChangeTimeRange}
      onChangeCalendar={handleChangeCalendar}
    />
  )
}
