import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import TextEllipsis from '../../../../common/textEllipsis'
import { BOND_ROUTE } from '../../../../constants/routes/bondRoute'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../../corporateBond/bondSecurity/bondSecurity/Filter/store/slice'
import { INDICATOR_GROUPS } from '../../constants'
import style from './index.module.css'

export const TdTicker = ({
  ticker,
  value,
  indicatorGroup,
  getOrganizationById,
  handleEdit,
  handleDelete,
}) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const tdRef = useRef()
  const iconRef = useRef()

  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const isDropdownTop =
    window.innerHeight - tdRef.current?.getBoundingClientRect()?.bottom < 72

  const redirectToSecurityMaster = (bond) => {
    if (indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER) {
      const orgInfo = getOrganizationById(ticker)
      let params = {}
      if (orgInfo) {
        params = {
          organizationId: orgInfo.organizationId,
          groupId: null,
          ticker: orgInfo.ticker,
          companyName: orgInfo.organizationShortName,
          exchange: orgInfo.exchangeCode,
          sector: orgInfo.sector,
        }
      }
      navigate(BOND_ROUTE.components.issuers.route, params)
    } else {
      dispatch(changeBondTicker(bond))
      dispatch(changeBasicInfo(null))
      navigate(BOND_ROUTE.components['bond-security'].route)
    }
  }

  const handleIconClick = () => {
    if (indicatorGroup !== INDICATOR_GROUPS.BOND_CREATING) return

    setIsShowDropdown(true)
  }

  return (
    <div
      className="d-flex ali-center justify-content-space-between"
      style={{ gap: 8 }}
      ref={tdRef}
    >
      <div style={{ width: 'calc(100% - 24px)' }} className="d-flex ali-center">
        <a
          onClick={() => redirectToSecurityMaster(value)}
          className="cursor-pointer"
          style={{ overflow: 'hidden' }}
        >
          <TextEllipsis
            text={value}
            isI18n={false}
            appendStyle={{ color: '#57ACFF' }}
          />
        </a>
      </div>
      <div
        ref={iconRef}
        className="d-flex ali-center justify-content-center"
        style={{ width: 16, height: 16, flexShrink: 0 }}
      >
        <i
          className={`${
            indicatorGroup === INDICATOR_GROUPS.BOND_CREATING
              ? 'icon-menu-dot-hor'
              : 'icon-plus-circle-line'
          } cursor-pointer`}
          onClick={handleIconClick}
        />
      </div>
      <Dropdown
        className="dropdown-bond-list"
        position={
          isDropdownTop
            ? positionDropdown.TOP_RIGHT_RIGHT
            : positionDropdown.BOTTOM_RIGHT_RIGHT
        }
        appendStyle={{
          background: '#3c424b',
          minWidth: 127,
          borderRadius: 4,
        }}
        isShow={isShowDropdown}
        parentRef={iconRef}
      >
        <HandleClickOutside
          handleClickOutside={() => {
            setIsShowDropdown(false)
          }}
          exceptRef={tdRef}
        >
          <div>
            <div
              className={`${style.dropdownItem} `}
              onClick={(e) => {
                e.stopPropagation()
                setIsShowDropdown(false)
                handleEdit()
              }}
            >
              <i
                className="icon-edit"
                style={{ color: '#AEBCD2', fontSize: 10 }}
              />
              <Translate value="common.button.BUTTON_EDIT" />
            </div>
            <div
              className={`${style.dropdownItem} `}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              <i
                className="icon-plus"
                style={{ color: '#AEBCD2', fontSize: 10 }}
              />
              <Translate value="bond.bondScreening.bondList.CREATE_WATCHLIST" />
            </div>
            <div
              className={`${style.dropdownItem}`}
              onClick={(e) => {
                e.stopPropagation()
                setIsShowDropdown(false)
                handleDelete()
              }}
            >
              <i
                className="icon-trash"
                style={{ color: '#AEBCD2', fontSize: 10 }}
              />
              <Translate value="common.button.BUTTON_DELETE" />
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
