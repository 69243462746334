import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import SearchTicker from '../../../../common/dataExplorer/filter/SearchTicker'
import { Span } from '../../../../common/html/Span'
import { LIST_TYPE } from '../../../common/constants'
import { ReactComponent as IconMenuMoreWhite } from '../../../icon/icon-menu-more.svg'
import PopupSecurities from '../popupSecurities'
import {
  changeEquationSecurities,
  selectEquationSecurities,
} from '../store/slice'
import style from '../style.module.css'

const SecuritiesSearch = () => {
  const securitiesRef = useRef()

  const dispatch = useDispatch()

  const equationSecurities = useSelector(selectEquationSecurities)

  const [isOpenSecurities, setOpenSecurities] = useState(false)

  const handleCloseSecurities = () => {
    setOpenSecurities(false)
  }

  const handleDeleteSecurities = () => {
    dispatch(changeEquationSecurities())
  }

  const handleAddTicker = ([item]) => {
    let newCompanies = equationSecurities.companies || []
    const orgId = +item.organizationId

    if (!newCompanies.find((val) => val === orgId)) {
      newCompanies = [...newCompanies, orgId]
    } else {
      newCompanies = newCompanies.filter((val) => val !== orgId)
    }

    dispatch(
      changeEquationSecurities({
        value: newCompanies,
        type: LIST_TYPE.COMPANIES,
      }),
    )
  }

  return (
    <div className="d-flex justify-content-space-between">
      <div className="align-center">
        <Span className={style.textOrange}>
          <Translate value="financialChart.SECURITIES_1" />
        </Span>
        <div style={{ width: 280 }}>
          <SearchTicker
            inputStyle={{ height: 24 }}
            isHideTitle={true}
            handleAddTicker={handleAddTicker}
            isThemeWhite
          />
        </div>
        <IconMenuMoreWhite
          className="cursor-pointer"
          height={24}
          ref={securitiesRef}
          onClick={() => setOpenSecurities(true)}
        />
        <PopupSecurities
          parentRef={securitiesRef}
          isShow={isOpenSecurities}
          onClose={handleCloseSecurities}
        />
      </div>
      <div className="centerItems" onClick={handleDeleteSecurities}>
        <i
          className="icon-trash cursor-pointer mr-8"
          style={{ color: '#8B8B8B' }}
        />
      </div>
    </div>
  )
}

export default SecuritiesSearch
