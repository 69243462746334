import { Construction } from './construction'
import { OverviewSteel } from './overviewSteel'
import { Price } from './price'
import { SteelCoat } from './steelCoat'
import { SteelCoil } from './steelCoil'
import { SteelPipe } from './steelPipe'

export const STEEL_TAB = {
  OVERVIEW: 'Overview',
  CONSTRUCTION: 'Construction',
  PIPE: 'Pipe',
  COAT: 'Coat',
  COIL: 'Coil',
  PRICE: 'Price',
}

export const NAVIGATION_STEEL = [
  {
    title: 'sector.sectorSpecific.steel.OVERVIEW_TAB',
    component: <OverviewSteel />,
    value: STEEL_TAB.OVERVIEW,
  },
  {
    title: 'sector.sectorSpecific.steel.CONSTRUCTION_TAB',
    component: <Construction />,
    value: STEEL_TAB.CONSTRUCTION,
  },
  {
    title: 'sector.sectorSpecific.steel.STEEL_PIPE_TAB',
    component: <SteelPipe />,
    value: STEEL_TAB.PIPE,
  },
  {
    title: 'sector.sectorSpecific.steel.STEEL_COAT_TAB',
    component: <SteelCoat />,
    value: STEEL_TAB.COAT,
  },
  {
    title: 'sector.sectorSpecific.steel.STEEL_COIL_TAB',
    component: <SteelCoil />,
    value: STEEL_TAB.COIL,
  },
  {
    title: 'sector.sectorSpecific.steel.PRICE_TAB',
    component: <Price />,
    value: STEEL_TAB.PRICE,
  },
]

const START_YEAR = 2013
const currentYearByDateJs = new Date().getFullYear()

const YEARS_LIST = [...Array(currentYearByDateJs - START_YEAR + 1)].map(
  (_, index) => currentYearByDateJs - index,
)

export const YEARS_FILTER = YEARS_LIST.map((item) => ({
  name: item,
  value: item,
}))

export const LEVEL = {
  FIRST: 1,
  SECOND: 2,
  THIRD: 3,
}

export const TOP_PARENT_ID = 1
