export const financial = {
  UNIT_LABEL: 'Đơn vị',
  TRILLION: '(Nghìn Tỷ)',
  UNIT_BILLION: 'Tỷ VND',
  UNIT_TRILLION: 'Nghìn Tỷ VND',
  TITLE_PANEL: 'Thông số tài chính',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  PEERS_COMPARISON: 'SO SÁNH CÙNG NGÀNH',
  GROWTH_TIMELINE: 'Tăng trưởng theo thời gian',
  SHORT_UNIT_TRILLION: 'Nghìn tỷ',
  corporate: {
    Profitability: 'Tỷ suất lợi nhuận',
    'Net Revenue': 'Doanh thu thuần',
    'Net Revenue Growth': 'Tăng trưởng doanh thu thuần (%YoY)',
    'Net Income': 'Lợi nhuận sau thuế',
    'Net Profit Margin': 'Biên lợi nhuận ròng',
    'Gross Margin': 'Biên lợi nhuận gộp (%)',
    'EBIT Margin': 'Biên EBIT',
    'SG&A/Net Revenue': 'Chi phí bán hàng & quản lí DN/ Doanh thu thuần (%)',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',

    'Capital Structure': 'Cấu trúc vốn',
    'Total Assets': 'Tổng tài sản',
    'Total Assets Growth': 'Tăng trưởng TTS (%YoY)',
    "Owner's Equity": 'Vốn chủ sở hữu',
    "Owner's Equity Growth": 'Tăng trưởng VCSH (%YoY)',
    'Total Liabilities/Total Assets': 'Tổng nợ/Tổng tài sản (%)',
    "Total Liabilities/Owner's Equity": 'Tổng nợ/VCSH (%)',

    'Solvency Efficiency': 'Hiệu quả thanh toán',
    'Current Ratio': 'Hệ số thanh toán hiện thời',
    'Quick Ratio': 'Hệ số thanh toán nhanh',
    'Interest rate Coverage': 'Khả năng thanh toán lãi vay',

    'Operation Efficiency': 'Hiệu quả hoạt động',
    'Asset Turnover': 'Hệ số quay vòng tài sản',
    Inventory: 'Hàng tồn kho',
    'Days of inventory on hand (DOH)': 'Thời gian xử lý HTK (ngày)',
    Receivables: 'Phải thu khách hàng',
    'Day Sales Outstanding': 'Thời gian thu tiền bình quân (ngày)',
    'Trade Payables': 'Phải trả khách hàng',
    'Number of days of payables': 'Thời gian thanh toán bình quân (ngày)',
    'Cash Conversion Cycle': 'Chu kỳ Tiền mặt (ngày)',
  },
  bank: {
    'P & L': 'P & L',
    TOI: 'Tổng thu nhập hoạt động',
    'TOI Growth (YoY)': 'Tăng trưởng tổng thu nhập HĐ (%YoY)',
    NII: 'Thu nhập lãi thuần',
    'NII Growth (YoY)': 'Tăng trưởng TN lãi thuần (%YoY)',
    NFI: 'Thu nhập từ phí',
    'NFI/TOI': 'Thu nhập phí/Tổng TN hoạt động (%)',
    'Operating Expense': 'Chi phí hoạt động',
    CIR: 'Chi phí HĐ/ Thu nhập HĐ (%)',
    PPoP: 'Thu nhập trước dự phòng',
    'PPoP Growth (YoY)': 'Tăng trưởng thu nhập trước DPRRTD (%YoY)',
    PBT: 'Lợi nhuận trước thuế',
    'PBT Growth (YoY)': 'Tăng trưởng LN trước thuế (%YoY)',

    'Loans & Asset Quality': 'Cho vay & Chất lượng tài sản',
    'Total Assets': 'Tổng tài sản',
    'Total Assets Growth': 'Tăng trưởng TTS (%YoY)',
    'Earning Assets': 'Tài sản sinh lời',
    YOEA: 'Lợi suất trên TS sinh lời (%)',
    NIM: 'Biên lãi thuần',
    Credit: 'Tín dụng',
    'Credit Growth (YoY)': 'Tăng trưởng tín dụng (%YoY)',
    'Loan to Customers': 'Cho vay khách hàng',
    NPL: 'Tỷ lệ nợ xấu (%)',
    Provisions: 'Dự phòng rủi ro tín dụng',
    LLCrs: 'Tỷ lệ bao phủ nợ xấu',
    'Deposit from Customers': 'Tiền gửi khách hàng',
    'Deposit from Customers Growth': 'Tăng trưởng tiền gửi khách hàng (%YoY)',
    "Owner's Equity": 'Vốn chủ sở hữu',
    "Owner's Equity Growth": 'Tăng trưởng VCSH (%YoY)',
    'Loan to Total Assets': 'Cho vay khách hàng/TTS',
    LDR: 'Tỷ lệ cho vay/ Tiền gửi',

    Profitability: 'Tỷ suất lợi nhuận',
    ROA: 'ROA',
    ROE: 'ROE',
    'Gross Margin': 'Biên lợi nhuận gộp (%)',
    'Net Profit Margin': 'Biên lợi nhuận ròng',

    'Capital and Liquidity': 'Vốn & Thanh khoản',
    CAR: 'CAR (%)',
    COF: 'COF (%)',
    CASA: 'CASA',
    'CASA Ratio': 'CASA/ Tổng tiền gửi (%)',
    'Current Deposits for MLT Loan (%)':
      'Cho vay trung & dài hạn/Tiền gửi không kỳ hạn (%)',
  },
  security: {
    'Total Assets': 'Tổng tài sản',
    'Total Assets Growth (%YoY)': 'Tăng trưởng (%YoY)',
    'Margin Lending': 'Cho vay ký quỹ',
    'Margin Lending Growth (%YoY)': 'Tăng trưởng (%YoY)',
    "Margin Lending/ Owner's Equity": 'Cho vay ký quỹ/VCSH (%)',
    'Total Equity': 'VCSH',
    'Total Equity Growth (%YoY)': 'Tăng trưởng (%YoY)',
    'Chartered Capital': 'Vốn góp của CSH',
    'Chartered Capital Growth (%YoY)': 'Tăng trưởng (%YoY)',
    'Net Debt': 'Nợ vay ròng',
    'Total Debt/Total Equity': 'Tổng nợ/VCSH',
    'Brokerage Fee': 'Doanh thu phí môi giới',
    'Brokerage Margin': 'Biên lợi nhuận doanh thu phí môi giới (%)',
    'Gross Profit': 'Lợi nhuận gộp',
    'Gross Margin': 'Biên lợi nhuận gộp (%)',
    'Net Profit': 'Lợi nhuận thuần',
    'Net Margin': 'Biên lợi nhuận thuần (%)',
    ROA: 'ROA',
    ROE: 'ROE',
  },
  insurance: {
    'Gross Written Premium': 'Thu phí bảo hiểm',
    'Gross Written PremiumGrowth (%YoY)': 'Tăng trưởng (%YoY)',
    'Life Insurance': 'Bảo hiểm nhân thọ',
    'Life Insurance Growth (%YoY)': 'Tăng trưởng (%YoY)',
    'Non-Life Insurance': 'Bảo hiểm phi nhân thọ',
    'Non-Life Insurance Growth (%YoY)': 'Tăng trưởng (%YoY)',
    NPATMI: 'Lợi nhuận sau thuế của chủ sở hữu, tập đoàn',
    'NPATMI Growth (%YoY)': 'Tăng trưởng (%YoY)',
    'Claim Ratio (%)': 'Tỷ lệ bồi thường (%)',
    'Combined Ratio (%)': 'Tỷ lệ kết hợp (%)',
  },
}

export const keyIndicators = {
  NAME: ' ',
  MWG: 'Doanh nghiệp',
  GROWTH: 'Tăng trưởng YoY',
  INDUSTRY: 'Ngành',
  GROWTH_IN: 'Tăng trưởng YoY',
  TITLE_PANEL: 'Chỉ Số Chính',
  corporate: {
    'Market Cap (Billion VND)': 'Vốn hóa (Tỷ VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Tỷ suất cổ tức',
  },
  bank: {
    'Market Cap (Billion VND)': 'Vốn hóa (Tỷ VND)',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    'Preprovision ROA': 'ROA trước dự phòng',
    EPS: 'EPS',
    BVPS: 'BVPS',
    NIM: 'NIM',
    NPL: 'NPL',
  },
  security: {
    'Market Cap (Billion VND)': 'Vốn hóa (Tỷ VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Tỷ suất cổ tức',
  },
  insurance: {
    'Market Cap (Billion VND)': 'Vốn hóa (Tỷ VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Tỷ suất cổ tức',
  },
}

export const news = {
  TITLE_PANEL: 'TIN TỨC',
}

export const summary = {
  TITLE_PANEL: 'Tổng quan',
  CHAIRMAN: 'Chủ tịch HĐQT',
  CEO: 'Tổng giám đốc',
  WEBSITE: 'Website',
  MARGIN_STATUS: 'Trạng thái Ký Quỹ',
  OUTSTANDING_SHARES: 'CPLH (Triệu CP)',
  FREE_FLOAT: 'Tỷ lệ Free Float',
  AVERAGE_VOLUME: 'KLGDTB 10D (CP)',
  TELEPHONE: 'Số điện thoại',
  FOREIGN_HOLDING: 'Sở hữu nước ngoài',
  STATE_HOLDING: 'Sở hữu nhà nước',
}
