import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class PortfolioProxy extends ProxyBase {
  getPortfolio() {
    return this.get('GetPortfolio')
  }
  getPortfolioInvestment(params) {
    return this.get('GetPortfolioInvestment/Default', params)
  }
  postPortfolioInvestment(params) {
    return this.post('GetPortfolioInvestment', params)
  }
  getExpectedCashflowData(params) {
    return this.post('GetExpectedCashFlowOfPrincipalAndInterest', params)
  }
  downloadExpectedCashflowData(params) {
    return this.post(
      'DownloadExpectedCashFlowOfPrincipalAndInterest',
      params,
      {},
      'download',
    )
  }
  addPortfolio(params) {
    return this.post('AddPortfolio', params)
  }
  updatePortfolio(params) {
    return this.put('UpdatePortfolio', params)
  }
  updatePortfolioDetails(params) {
    return this.put('UpdatePortfolioDetails', params)
  }
  deletePortfolio(params) {
    return this.delete('DeletePortfolio', params)
  }
  downloadPortfolioInvestment(params) {
    return this.post('DownloadPortfolioInvestment', params, {}, 'download')
  }
}

export default new PortfolioProxy(ServiceProxyConfig.Bond.Portfolio.ServiceUrl)
