import { keyBy } from '../../../../utils/Common'
import { LEVEL, TOP_PARENT_ID } from '../constants'
import { PARENT_ID_MAPPING } from './constants'

const steelTypeIdKey = 'steelTypeId'

export const getSteelTypesFilter = (steelTypesList) => {
  const getChildrenOfSteelStypes = (steelTypesId) => {
    return steelTypesList?.filter(
      (item) => item.parentSteelTypeId === steelTypesId,
    )
  }

  return steelTypesList?.map((item) => ({
    ...item,
    level: !item.parentSteelTypeId
      ? TOP_PARENT_ID
      : item.parentSteelTypeId === TOP_PARENT_ID
      ? LEVEL.SECOND
      : LEVEL.THIRD,
    children: keyBy(getChildrenOfSteelStypes(item.steelTypeId), steelTypeIdKey),
  }))
}

export const getTreeSteelTypesFilter = (steelTypesList) => {
  const steelTypesFilter = getSteelTypesFilter(steelTypesList)

  const secondParentSteelTypes = steelTypesFilter?.filter(
    (item) => item.level === LEVEL.SECOND,
  )

  const firstLevelParentSteelTypes = steelTypesFilter?.find(
    (item) => item.level === LEVEL.FIRST,
  )

  if (firstLevelParentSteelTypes) {
    firstLevelParentSteelTypes['children'] = keyBy(
      secondParentSteelTypes,
      steelTypeIdKey,
    )
  }

  const treeSteelTypesFilter = {
    treeSteelTypes: firstLevelParentSteelTypes,
  }

  return treeSteelTypesFilter
}

export const getSteelTypesParentById = (steelTypesList, parentId) => {
  return steelTypesList.find((item) => item.steelTypeId === parentId)
    ?.steelTypeName
}

export const getXAxisKey = (month, year, locale) => {
  if (locale === 'en') {
    return month === 12
      ? year
      : month === 1
      ? `Jan-${year}`
      : `${month}M-${year}`
  } else {
    return month === 12
      ? year
      : month === 1
      ? `Th01-${year}`
      : `${month}Th-${year}`
  }
}

export const getDefaultMaxYear = (
  steelTypesFilter,
  currentSteelTypeId,
  defaultMaxDate,
) => {
  const currentSteelTypeParentId = (currentSteelTypeId) => {
    const currentSteelType = steelTypesFilter?.find(
      (item) => item.steelTypeId === currentSteelTypeId,
    )
    const currentLevel = currentSteelType?.level
    if (currentLevel === LEVEL.FIRST || currentLevel === LEVEL.SECOND) {
      return currentSteelTypeId
    } else {
      return currentSteelType?.parentSteelTypeId
    }
  }

  const currentSteelTypeParent = PARENT_ID_MAPPING.find(
    (item) => item.value === currentSteelTypeParentId(currentSteelTypeId),
  )?.title

  return defaultMaxDate && currentSteelTypeId === LEVEL.FIRST
    ? Math.max(...defaultMaxDate?.map((date) => date.year))
    : defaultMaxDate?.find((item) => item.type === currentSteelTypeParent)?.year
}
