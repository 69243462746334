import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseI18n from '../../../../common/hooks/useI18n'
import { Span } from '../../../../common/html/Span'
import { selectFilter } from '../../store/slice'
import DropdownBox from './DropdownBox'
import style from './index.module.css'

const MyWatchListFilter = () => {
  const selectRef = useRef()
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const filter = useSelector(selectFilter)

  const handleHide = () => setIsShowDropdown(false)
  const handleToggle = () => setIsShowDropdown((isShow) => !isShow)

  const placeholderText = UseI18n('market.event.SELECT_WL_PLH')

  return (
    <div className="mb-40">
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.event.WATCHLIST" />
      </Span>
      <div className="mt-6" ref={selectRef} onClick={handleToggle}>
        <span
          className={`${style.select} ${
            isShowDropdown ? style.showDropdown : ''
          }`}
        >
          <Span
            style={{ fontSize: filter.watchList ? 12 : 11 }}
            className={`${style.text} ${
              !filter.watchList ? style.selectPlaceholder : ''
            }`}
          >
            {filter.watchList?.watchListName || placeholderText}
          </Span>
          <i
            className={`${
              !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
            } ${style.icon}`}
          />
        </span>
      </div>
      <DropdownBox
        isShow={isShowDropdown}
        parentRef={selectRef}
        handleHide={handleHide}
        exceptOutsideRef={selectRef}
      />
    </div>
  )
}

export default MyWatchListFilter
