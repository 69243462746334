import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { getPriceColor } from '../../../../utils/Color'
import { FORMAT } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { matchingOrderFormat, matchingOrderTableSchema } from './constants'

const getTypeText = (val) => {
  if (val === 2) {
    return 'SD'
  } else if (val === 1) {
    return 'BU'
  } else {
    return ''
  }
}

const getColorString = (val) => {
  return val === 'BU' ? '#59c761' : '#ff4752'
}

const getColorCompareNumber = (referencePrice, rowData, priceData) => {
  if (!referencePrice || !rowData?.matchPrice) {
    return '#ececec'
  }

  const ceilingPrice = priceData?.priceInfo?.ceilingPrice
  const floorPrice = priceData?.priceInfo?.floorPrice

  return getPriceColor(
    rowData.matchPrice,
    referencePrice,
    ceilingPrice,
    floorPrice,
  )
}

const ColorCellType = ({ val, colorFunc, format }) => {
  return (
    <div style={{ color: colorFunc(val) }}>{format ? format(val) : val}</div>
  )
}

export const getTableSchema = (referencePrice, getRowData, priceData) => {
  return Object.keys(matchingOrderTableSchema).map((key) => {
    const title = `market.equityTrading.deepTransaction.matchingOrder.${key}`
    const colId = matchingOrderTableSchema[key]
    const result = {
      colId,
      title,
    }

    if (matchingOrderTableSchema.TIME === colId) {
      return {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => <DateTimeSpan date={val} formatDate={FORMAT.TIME} />,
      }
    }

    const customSchema = {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (matchingOrderTableSchema.TYPE === colId) {
      return {
        ...customSchema,
        render: (val) => {
          return (
            <ColorCellType val={getTypeText(val)} colorFunc={getColorString} />
          )
        },
      }
    }

    if (
      [
        matchingOrderTableSchema.PRICE,
        matchingOrderTableSchema.CHANGE,
      ].includes(colId)
    ) {
      return {
        ...customSchema,
        render: (val, rowId) => {
          return (
            <ColorCellType
              val={val}
              colorFunc={(_) =>
                getColorCompareNumber(
                  referencePrice,
                  getRowData(rowId),
                  priceData,
                )
              }
              format={(val) =>
                formatVal(val ? val / matchingOrderFormat[key] : val)
              }
            />
          )
        },
      }
    }

    if (matchingOrderTableSchema.PERCENT_CHANGE === colId) {
      return {
        ...customSchema,
        render: (val, rowId) => {
          return (
            <ColorCellType
              val={val}
              colorFunc={(_) =>
                getColorCompareNumber(
                  referencePrice,
                  getRowData(rowId),
                  priceData,
                )
              }
              format={(val) => valToPercent(val)}
            />
          )
        },
      }
    }

    return {
      ...customSchema,
      render: (val) => formatVal(val ? val / matchingOrderFormat[key] : val),
    }
  })
}
