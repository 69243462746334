import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  checkAll,
  deleteIndexSector,
  selectActiveSheet,
  selectFullIndexSectorDisplay,
  selectIndexSectorById,
  uncheckAll,
} from '../store/slice'

const CheckboxAll = () => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const indexSectorDisplayId = useSelector(
    selectFullIndexSectorDisplay(activeSheet),
  )
  const indexSectorById = useSelector(selectIndexSectorById(activeSheet))

  const isHaveCheck = indexSectorDisplayId.some(
    (id) => indexSectorById[id]?.check,
  )

  const handleCheckAll = () => {
    dispatch(checkAll())
  }

  const handleUncheckAll = () => {
    dispatch(uncheckAll())
  }

  const handleDeleteIndexSector = () => {
    dispatch(deleteIndexSector())
  }

  return (
    <div
      className={`d-flex ali-center justify-content-center ${style.checkboxContainer}`}
    >
      {!isHaveCheck ? (
        <label className={`d-flex justify-content-center ${style.checkboxAll}`}>
          <input
            type="checkbox"
            className={`checkbox line cursor-pointer ${style.inputCheckAll}`}
            onClick={handleCheckAll}
            checked={false}
            readOnly
          />
        </label>
      ) : (
        <div className={style.btnMinus}>
          <button onClick={handleUncheckAll} className="cursor-pointer">
            -
          </button>
        </div>
      )}
      <div className={style.checkAllBorder} />
      <button onClick={handleDeleteIndexSector} className={style.btnTrash}>
        <i className="icon-trash" />
      </button>
    </div>
  )
}

export default CheckboxAll
