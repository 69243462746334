import PropTypes from 'prop-types'
import { getThreeColorByValue } from '../../../../utils/Color'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'

const ChildrenData = ({ className, childrenData, isMinus }) => {
  const checkMinus = isMinus ? childrenData * -1 : childrenData
  return (
    <div
      className={style.childrenData}
      style={{
        color: getThreeColorByValue(childrenData),
        fontSize: getFontSize(),
      }}
    >
      <span
        className={`${className} ${style.iconCaret}`}
        style={{
          fontSize: getFontSize(8),
          color: getThreeColorByValue(childrenData),
        }}
      />
      {`${formatVal(checkMinus * 100)}%`}
    </div>
  )
}

ChildrenData.propTypes = {
  className: PropTypes.string,
  childrenData: PropTypes.any,
  isMinus: PropTypes.bool,
}

ChildrenData.defaultProps = {
  isMinus: false,
}

export default ChildrenData
