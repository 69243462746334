import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { ChartComponent } from './ChartComponent'
import { COLOR_LINE_KEYS, TITLE_LINE_KEYS } from './constants'
import { selectData, selectLoading } from './store/slice'
import { getCoveredWarrantBreakEvenPoint } from './store/thunk'

export const BreakEvenPoint = ({ width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const warrantInfo = useSelector(selectWarrantInfo)
  const data = useSelector(selectData)
  const isLoading = useSelector(selectLoading)

  const lineKeys = [
    'breakEvenPoint',
    'closePriceAdjusted',
    'adjustedExercisePrice',
  ]

  // Use effect
  useEffect(() => {
    if (warrantInfo.coveredWarrantId) {
      dispatch(
        getCoveredWarrantBreakEvenPoint({
          coveredWarrantId: warrantInfo.coveredWarrantId,
          TimeRange: 'AllTime',
        }),
      )
    }
  }, [warrantInfo.coveredWarrantId])

  return (
    <div id="breakEvenPoint">
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : data && data.length > 0 ? (
                  <>
                    <div className="h-100 position-relative">
                      <ChartComponent
                        data={data}
                        width={width}
                        height={height - size.height}
                        keyXAxis={'tradingDateId'}
                        lineKeys={lineKeys}
                      />
                    </div>
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            )}
            <div>
              <Footer
                key={width}
                list={lineKeys.map((item, index) => ({
                  text: I18n.t(TITLE_LINE_KEYS[index]),
                  type: TYPE.LINE,
                  before: {
                    bgColor: COLOR_LINE_KEYS[index],
                  },
                }))}
              />
            </div>
          </>
        )}
      </SizeTracker>
    </div>
  )
}

BreakEvenPoint.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
