import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { convertSortKeyToNumber, keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { getMaxMinByObjKey, valByKeyWithDot } from '../../../../utils/Value'
import {
  TABLE_COL_KEYS,
  TABLE_TABS,
  TIME_RANGE_TYPES,
  VALUE_TYPES,
} from '../constants'
import { getMaxMinDataByColId } from '../helpers'
import { formatSectorAllocationData } from '../helpers/sectorAllocationHelpers'
import { getFundManagementCompanyThunk, getTableDataThunk } from './thunk'

const defaultFilter = {
  fund: [],
  management: [],
  structure: [],
  type: [],
  status: ['1'],
  frequency: [],
  timeRange: TIME_RANGE_TYPES.MOST_RECENT,
  month: '',
  year: '',
}

const defaultFilterTable = {
  page: 1,
  totalPage: 1,
  sortField: '',
  sortOrder: 1,
}

const initialState = {
  filters: defaultFilter,
  filtersTable: {
    [TABLE_TABS.KEY_METRICS]: defaultFilterTable,
    [TABLE_TABS.PERFORMANCE]: defaultFilterTable,
    [TABLE_TABS.FUND_FLOW]: defaultFilterTable,
    [TABLE_TABS.FUND_INDEX]: defaultFilterTable,
    [TABLE_TABS.SECTOR_ALLOCATION]: defaultFilterTable,
    [TABLE_TABS.ASSET_ALLOCATION]: defaultFilterTable,
  },
  tableTab: TABLE_TABS.KEY_METRICS,
  valueType: VALUE_TYPES.RELATIVE,
  reCalcWidth: false,
  loading: false,
  fundManagementLoading: false,
  data: [],
  ids: [],
  initialIds: [],
  dataById: {},
  fundManagements: [],
  sectorColumns: [],
  maxMinByColId: {
    [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_MOST_RECENT]: { max: 0, min: 0 },
    [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_3M]: { max: 0, min: 0 },
    [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_YTD]: { max: 0, min: 0 },
    [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_1Y]: { max: 0, min: 0 },
  },
}

export const slice = createSlice({
  name: 'fund/fundCenter/statistic',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        if (Object.keys(defaultFilterTable).includes(key)) {
          state.filtersTable[state.tableTab] = {
            ...state.filtersTable[state.tableTab],
            [key]: action.payload[key],
          }
        } else {
          state.filters = {
            ...state.filters,
            [key]: action.payload[key],
          }
        }
      })
    },
    changeTableTab: (state, action) => {
      state.tableTab = action.payload
    },
    changeValueType: (state, action) => {
      state.valueType = action.payload
    },
    changeReCalcWidth: (state) => {
      state.reCalcWidth = !state.reCalcWidth
    },
    sortTableData: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
      state.filtersTable[state.tableTab] = {
        ...state.filtersTable[state.tableTab],
        sortField:
          typeof Object.values(action.payload)[0] !== 'undefined'
            ? Object.keys(action.payload)[0]
            : undefined,
        sortOrder: convertSortKeyToNumber(Object.values(action.payload)[0]),
      }
    },
  },
  extraReducers: (builder) => {
    // Get fund management company
    builder.addCase(getFundManagementCompanyThunk.pending, (state) => {
      state.fundManagementLoading = true
    })
    builder.addCase(
      getFundManagementCompanyThunk.fulfilled,
      (state, action) => {
        state.fundManagements = action.payload.map((item) => ({
          name: item.organizationShortName,
          value: item.organizationId,
        }))
        state.fundManagementLoading = false
      },
    )
    builder.addCase(getFundManagementCompanyThunk.rejected, (state, action) => {
      state.fundManagementLoading = action.payload.loading
    })
    // Get table data
    builder.addCase(getTableDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTableDataThunk.fulfilled, (state, action) => {
      let rawData = []

      if (state.tableTab === TABLE_TABS.SECTOR_ALLOCATION) {
        const { data, sectorColumns } = formatSectorAllocationData(
          action.payload.data,
        )
        rawData = data
        state.sectorColumns = sectorColumns
        state.maxMinByColId = {
          ...state.maxMinByColId,
          ...getMaxMinByObjKey(data),
        }
      } else {
        rawData = action.payload.data
      }

      const data = rawData.map((item, index) => ({
        ...item,
        id: index,
        index: (action.payload.page - 1) * action.payload.pageSize + index + 1,
      }))

      if (state.tableTab === TABLE_TABS.FUND_FLOW) {
        state.maxMinByColId = getMaxMinDataByColId(
          data,
          Object.keys(state.maxMinByColId),
        )
      }

      state.data = data
      state.ids = state.initialIds = data.map((e) => e.id)
      state.dataById = keyBy(data, 'id')
      state.filtersTable[state.tableTab].totalPage = action.payload.totalPage
      state.loading = false
      state.reCalcWidth = !state.reCalcWidth
    })
    builder.addCase(getTableDataThunk.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectTableTab = (state) => state[slice.name].tableTab
export const selectValueType = (state) => state[slice.name].valueType
export const selectFilters = (state) => {
  const tableTab = state[slice.name].tableTab
  return {
    ...state[slice.name].filters,
    ...state[slice.name].filtersTable[tableTab],
  }
}
export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectDataById = (state) => state[slice.name].dataById
export const selectReCalcWidth = (state) => state[slice.name].reCalcWidth
export const selectFundManagement = (state) => state[slice.name].fundManagements
export const selectMaxMinByColId = (state) => state[slice.name].maxMinByColId
export const selectSectorAllocationColumns = (state) =>
  state[slice.name].sectorColumns

export const { changeFilter, changeTableTab, changeValueType, sortTableData } =
  slice.actions

register(slice.name, slice.reducer)
