import { useSelector } from 'react-redux'
import { FvtplSegmentalChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectYearType } from './store/slice'

export const PanelFvtplSegmental = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const yearType = useSelector(selectYearType)

  return (
    <Panel
      title="sector.financialAnalysis.security.assetStructure.FVTPL_SEGMENTAL"
      panelRefs={panelRefs}
      panelKey={mapKey.FVTPL_SEGMENTAL}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'fvtplSegmentalChart',
        nameScreen: UseI18n(
          'sector.financialAnalysis.security.ASSET_STRUCTURE',
        ),
        chartName: UseI18n(
          'sector.financialAnalysis.security.assetStructure.FVTPL_SEGMENTAL',
        ),
        tabName: String(yearType),
        tickerName: currentInfo.ticker,
      }}
      titleJpg={UseI18n(
        'sector.financialAnalysis.security.assetStructure.FVTPL_SEGMENTAL',
      )}
    >
      <FvtplSegmentalChart
        width={formatNumber(
          sizes[mapKey.FVTPL_SEGMENTAL].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.FVTPL_SEGMENTAL].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
