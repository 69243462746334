import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { getStyle } from '../../helper'
import { UNIT_VALUE_CONSTANTS } from '../filter/constants'
import {
  selectExchangeRates,
  selectFilterLocal,
  selectFinancialFilter,
  selectItemData,
} from '../store/slice'
import {
  FORMAT_DATA_BY_UNIT,
  INDICATOR_FIX_UNIT_INT,
  TABLE_ROW_LEVEL,
} from './constants'
import style from './index.module.css'

export const OtherColumnsCell = ({ val, rowId, className, columnItem }) => {
  const item = useSelector(selectItemData(rowId))
  const financialFilter = useSelector(selectFinancialFilter)
  const filterLocal = useSelector(selectFilterLocal)
  const exchangeRates = useSelector(selectExchangeRates)

  // Get data
  const getFormatDataByUnit = () => {
    return INDICATOR_FIX_UNIT_INT.includes(item.key.toUpperCase()) &&
      item.fixUnit
      ? 1
      : FORMAT_DATA_BY_UNIT[filterLocal.unit.value]
  }

  const getFormatVal = useMemo(() => {
    if (val === 0) return EMPTY_VALUE

    if (financialFilter.commonSize.value === 'false') {
      if (item.isFormatValToPercent) {
        return valToPercent(val)
      }

      if (
        [
          UNIT_VALUE_CONSTANTS.USD,
          UNIT_VALUE_CONSTANTS.THOUSAND_USD,
          UNIT_VALUE_CONSTANTS.MILLION_USD,
        ].includes(filterLocal.unit.value)
      ) {
        return !isNaN(val)
          ? formatVal(
              val /
                (exchangeRates[`${columnItem.quarter}-${columnItem.year}`] ||
                  1) /
                getFormatDataByUnit(),
            )
          : EMPTY_VALUE
      } else {
        return !isNaN(val)
          ? formatVal(
              val / getFormatDataByUnit(),
              filterLocal.unit.value === UNIT_VALUE_CONSTANTS.VND ? 0 : 2,
            )
          : EMPTY_VALUE
      }
    } else {
      if (String(val).includes('%')) {
        const newValue = +val.replace('%', '')
        const isNumber = typeof newValue === 'number'
        return isNumber && !newValue ? EMPTY_VALUE : val
      }

      return val
    }
  }, [filterLocal.unit.value, financialFilter.commonSize.value])

  const getColorVal = useMemo(() => {
    let isLessThanZero = false

    if (String(val).includes('%')) {
      const newValue = +val.replace('%', '')
      const isNumber = typeof newValue === 'number'
      isLessThanZero = isNumber && newValue < 0
    }

    return val < 0 || isLessThanZero ? '#ff5661' : ''
  }, [val])

  if (item.level === TABLE_ROW_LEVEL.LEVEL_0) {
    return (
      <td style={getStyle(item)} className={`${className} ${style['row-lv0']}`}>
        <div className={style.otherCell}>
          <TextEllipsis text={val} isI18n={false} />
        </div>
      </td>
    )
  } else {
    return (
      <td
        className={className}
        style={{ textAlign: 'right', color: getColorVal, ...getStyle(item) }}
      >
        {getFormatVal}
      </td>
    )
  }
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
  columnItem: PropTypes.object,
}
