import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { Span } from '../../common/html/Span'
import useGetBond from '../../common/masterData/useGetBond'
import useGetICB from '../../common/masterData/useGetICB'
import useGetStock from '../../common/masterData/useGetStock'
import { SizeTracker } from '../../common/sizeTracker'
import Title from '../../common/topInfo/components/Title'
import VerticalLine from '../../common/topInfo/components/VerticalLine'
import { getAllIdTree, getAllParentChecked } from '../../common/tree/helper'
import { BOND_ROUTE } from '../../constants/routes/bondRoute'
import BondList from './BondList'
import {
  changeIsFirst,
  resetStore as resetStoreTable,
} from './BondList/Table/store/slice'
import Screening from './Screening'
import { INDICATOR_GROUPS, NAVIGATION_BOND_SCREENING } from './constants'
import style from './index.module.css'
import {
  changeIsLoadingTable,
  changeIsLoadingTemplates,
  changeTemplateList,
  changeTemplateMoreList,
  resetDataTemplate,
  resetStore,
  selectFilter,
  selectFilterParams,
  selectInitialTemplates,
  selectIsLoadingTable,
  selectIsLoadingTemplates,
  setFilterParameters,
  setInitialParentSector,
  setInitialSectors,
} from './store/slice'
import {
  getCreateBondListData,
  getIndicatorData,
  getTableDataByFilter,
  getWatchListByUserData,
  getWatchListData,
} from './store/thunk'

export const BondScreening = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const { stockById } = useGetStock(true)
  const { bondByOrganizationId } = useGetBond(true)
  const { ICBsTree } = useGetICB(true, true, undefined, undefined, true)

  // Use selector
  const { visible } = useSelector(selectFilter)
  const params = useSelector(selectFilterParams)
  const { indicatorGroup } = params
  const locale = useSelector((state) => state.i18n.locale)
  const isLoadingTable = useSelector(selectIsLoadingTable)
  const isLoadingTemplates = useSelector(selectIsLoadingTemplates)
  const initialTemplates = useSelector(selectInitialTemplates)

  // Actions
  const handleClickTab = (item) => {
    dispatch(setFilterParameters({ key: 'indicatorGroup', value: item.value }))
    dispatch(resetDataTemplate())
    dispatch(changeIsLoadingTable(true))
    dispatch(changeIsLoadingTemplates(true))
  }

  const redirectToBondIssuer = (id) => {
    const orgInfo = stockById[id] || bondByOrganizationId[id]
    let params = {}

    if (orgInfo) {
      params = {
        organizationId: orgInfo.organizationId,
        groupId: null,
        ticker: orgInfo.ticker,
        companyName: orgInfo.organizationShortName,
        exchange: orgInfo.exchangeCode,
        sector: orgInfo.sector,
      }
    }

    navigate(BOND_ROUTE.components.issuers.route, params)
  }

  // Use effect
  useEffect(() => {
    return () => {
      dispatch(resetStore())
      dispatch(resetStoreTable())
    }
  }, [])

  useEffect(() => {
    if (!!Object.keys(ICBsTree)) {
      const list = getAllIdTree(ICBsTree, 'icbCode')
      const parent = getAllParentChecked(ICBsTree, 'icbCode', list)

      dispatch(setInitialSectors(list))
      dispatch(setInitialParentSector(parent))
    }
  }, [Object.keys(ICBsTree)])

  useEffect(() => {
    dispatch(changeIsFirst(true))
    if (indicatorGroup === INDICATOR_GROUPS.BOND_CREATING) {
      dispatch(getCreateBondListData())
      dispatch(getWatchListByUserData())
      return
    }

    dispatch(getWatchListData())
  }, [locale, indicatorGroup])

  useEffect(() => {
    dispatch(getIndicatorData({ Language: locale }))
  }, [locale])

  useEffect(() => {
    if (isLoadingTable) {
      dispatch(
        getTableDataByFilter({ type: indicatorGroup, redirectToBondIssuer }),
      )
      dispatch(changeIsLoadingTable(false))
    }
  }, [isLoadingTable, indicatorGroup])

  useEffect(() => {
    if (isLoadingTemplates) {
      const data = initialTemplates?.filter(
        (item) => item.templateType === indicatorGroup,
      )

      const newData = [
        {
          templateId: 0,
          templateName: `bond.bondScreening.bondList.${
            indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
              ? 'ALL_ISSUER'
              : 'ALL_BOND'
          }`,
          templateType: params.type,
          hidden: true,
        },
        ...data,
      ]

      dispatch(changeTemplateList(newData))
      dispatch(changeTemplateMoreList(newData))
      dispatch(changeIsLoadingTemplates(false))
    }
  }, [isLoadingTemplates, indicatorGroup])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.bondScreening.common.TITLE" />
              <VerticalLine mr={28} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_BOND_SCREENING.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      indicatorGroup === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={() => handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {indicatorGroup === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                className={style.relative}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <div
                  className="d-flex h-100"
                  style={{ gap: 10, position: 'relative' }}
                >
                  {visible && (
                    <Screening redirectToBondIssuer={redirectToBondIssuer} />
                  )}
                  <BondList redirectToBondIssuer={redirectToBondIssuer} />
                </div>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
