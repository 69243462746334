import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { Loading } from '../../../common/loading'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { formatVal, valDivMillion, valToPercent } from '../../../utils/Value'
import { selectOwnershipData, selectOwnershipLoading } from './store/slice'
import style from './style.module.css'

const tableAttr = [
  {
    key: 'ticker',
    name: 'corporate.ownership.hoverTooltip.TICKER',
  },
  {
    key: 'quantity',
    name: 'corporate.ownership.hoverTooltip.QUANTITY',
  },
  {
    key: 'percentage',
    name: 'corporate.ownership.hoverTooltip.PERCENTAGE',
  },
  {
    key: 'publicDateId',
    name: 'corporate.ownership.hoverTooltip.UPDATE_DATE',
  },
  {
    key: 'value',
    name: 'corporate.ownership.hoverTooltip.VALUE',
    addition: 'corporate.ownership.hoverTooltip.MILLION',
  },
]

const OwnerShip = () => {
  const ownership = useSelector(selectOwnershipData)
  const isLoading = useSelector(selectOwnershipLoading)

  const renderData = (colId, data, className) => {
    let renderItem = ''

    switch (colId) {
      case 0:
        renderItem = (
          <div className={className} style={{ textAlign: 'center' }}>
            {data}
          </div>
        )
        break
      case 1:
        renderItem = <div className={className}>{formatVal(data, 0)}</div>
        break
      case 2:
        renderItem = <div className={className}>{valToPercent(data)}</div>
        break
      case 3:
        renderItem = (
          <div className={className}>
            <DateTimeSpan date={data} />
          </div>
        )
        break
      case 4:
        renderItem = (
          <div className={className}>{formatVal(valDivMillion(data), 1)}</div>
        )
        break

      default:
        break
    }

    return renderItem
  }

  return (
    <>
      <ScrollComponent autoHeight={true} autoHeightMax={250}>
        <div className={style.body}>
          {!isLoading ? (
            tableAttr.map((headerAttr, index) => (
              <div key={index}>
                <div
                  className={
                    [4].includes(index)
                      ? [style.headerItem, style.textRight].join(' ')
                      : style.headerItem
                  }
                  style={
                    ![0].includes(index)
                      ? { width: '120px', textAlign: 'right' }
                      : { textAlign: 'center' }
                  }
                >
                  <Translate value={headerAttr.name} />
                  {'addition' in headerAttr && (
                    <Translate
                      value={headerAttr.addition}
                      className={style.addition}
                    />
                  )}
                </div>
                {ownership.length > 0 &&
                  ownership.map((item, i) => (
                    <div key={i}>
                      <div className={style.bodyItem}>
                        {renderData(index, item[headerAttr.key], '')}
                      </div>
                      {i === ownership.length - 1 &&
                        index === tableAttr.length - 2 && (
                          <div className={style.total}>
                            <Translate value="corporate.ownership.title.TOTAL" />
                          </div>
                        )}
                      {i === ownership.length - 1 &&
                        index === tableAttr.length - 1 && (
                          <div className={style.total}>
                            {formatVal(
                              valDivMillion(
                                ownership.reduce(
                                  (accumulator, current) =>
                                    accumulator + current.value,
                                  0,
                                ),
                              ),
                              1,
                            )}
                          </div>
                        )}
                    </div>
                  ))}
              </div>
            ))
          ) : (
            <Loading />
          )}
        </div>
      </ScrollComponent>
      {ownership.length === 0 && (
        <div className="no-data">
          <Translate value={'common.NO_DATA'} />
        </div>
      )}
    </>
  )
}

export default OwnerShip
