import IndicesProxy from '../../../proxies/market/marketInDepth/IndicesProxy'
import { ServiceBase } from '../../ServiceBase'

export class IndicesService extends ServiceBase {
  getCommodities(params) {
    return this.getData(() => IndicesProxy.getCommodities(params))
  }

  downloadCommodities(params) {
    return this.getFileDownload(() => IndicesProxy.downloadCommodities(params))
  }

  getWorldIndex(params) {
    return this.getData(() => IndicesProxy.getWorldIndex(params))
  }

  downloadWorldIndex(params) {
    return this.getFileDownload(() => IndicesProxy.downloadWorldIndex(params))
  }

  downloadIndex(params) {
    return this.getFileDownload(() => IndicesProxy.downloadIndex(params))
  }

  downloadSector(params) {
    return this.getFileDownload(() => IndicesProxy.downloadSector(params))
  }

  getIndexSeries(params) {
    return this.getData(() => IndicesProxy.getIndexSeries(params))
  }

  getIndexLiquiditySeries(params) {
    return this.getData(() => IndicesProxy.getIndexLiquiditySeries(params))
  }

  getBuSd(params) {
    return this.getData(() => IndicesProxy.getBuSd(params))
  }

  getContribution(params) {
    return this.getData(() => IndicesProxy.getContribution(params))
  }

  getIndexIntraday(params) {
    return this.getData(() => IndicesProxy.getIndexIntraday(params))
  }

  getIndexEOD(params) {
    return this.getData(() => IndicesProxy.getIndexEOD(params))
  }

  downloadIndexIntraday(params) {
    return this.getFileDownload(() =>
      IndicesProxy.downloadIndexIntraday(params),
    )
  }

  downloadIndexEOD(params) {
    return this.getFileDownload(() => IndicesProxy.downloadIndexEOD(params))
  }
}

export default new IndicesService()
