import { useEffect, useRef, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import {
  changeChecked,
  changeCheckedAll,
  key,
  selectChecked,
  selectEventTypes,
  selectLoadingEventTypes,
  selectIsCheckedAll,
} from '../../store/slice'
import NameChecked from './NameChecked'
import style from './index.module.css'
import { MAX_SECURITIES_ITEM } from '../../../../financialChart/common/constants'

const EventType = () => {
  const dispatch = useDispatch()
  const parentRef = useRef()
  const eventTypes = useSelector(selectEventTypes)
  const listChecked = useSelector(selectChecked(key.EVENT_TYPE))
  const isCheckedAll = useSelector(selectIsCheckedAll(key.EVENT_TYPE))
  const listAllChecked = eventTypes.map((el) => el.fiinXEventTypeId)

  const loading = useSelector(selectLoadingEventTypes)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [filterEventTypes, setFilterEventTypes] = useState([])

  useEffect(() => {
    setFilterEventTypes(
      eventTypes
        .filter((eventType) =>
          eventType
            ? eventType.fiinXEventName
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [valueSearch, eventTypes])

  useEffect(() => {
    if (eventTypes.length > 0 && isCheckedAll)
      dispatch(
        changeCheckedAll({
          isCheckedAll: true,
          key: key.EVENT_TYPE,
          ids: listAllChecked,
        }),
      )
  }, [eventTypes])
  const handleChange = (fiinXEventTypeId, isChecked) => {
    dispatch(
      changeChecked({ isChecked, key: key.EVENT_TYPE, id: fiinXEventTypeId }),
    )
  }
  const handleChangeInput = (e) => setValueSearch(e.target.value)
  const handleChangeCheckAll = (e) => {
    dispatch(
      changeCheckedAll({
        isCheckedAll: e.target.checked,
        key: key.EVENT_TYPE,
        ids: e.target.checked ? listAllChecked : [],
      }),
    )
  }

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)
  const placeHolder = UseI18n('common.SEARCH')
  const renderEventTypes = (height) => (
    <div className="input-dropdown mb-8">
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className={`cursor-pointer h-20 ${style['span-input']}`}
        style={{
          borderRadius: isShowDropdown ? '4px 4px 0px 0px' : undefined,
        }}
      >
        <NameChecked
          eventTypes={eventTypes}
          listAllChecked={listAllChecked}
          listChecked={listChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`wrapper-tool ${style.mt3}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox bg-grey h-20 ${style.search}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
                {loading || eventTypes.length === 0 ? (
                  <Loading />
                ) : (
                  <div className="h-100">
                    <ScrollComponent
                      autoHeight={true}
                      autoHeightMin={200}
                      autoHeightMax={300}
                    >
                      <ul className={style.ulFirst}>
                        <li className={style.eventTypeName}>
                          <label className="d-flex ali-center">
                            <input
                              type="checkbox"
                              className={`checkbox line mr-8 ${style.darkCheckbox}`}
                              checked={listChecked.length === eventTypes.length}
                              onChange={handleChangeCheckAll}
                            />
                            <Translate value="market.event.SHOW_ALL" />
                          </label>
                        </li>

                        {filterEventTypes.map((event) => (
                          <li
                            className={style.eventTypeName}
                            key={event.fiinXEventTypeId}
                          >
                            <label className="d-flex ali-center">
                              <input
                                type="checkbox"
                                className={`checkbox line mr-8 ${style.darkCheckbox}`}
                                checked={listChecked.includes(
                                  event.fiinXEventTypeId,
                                )}
                                onChange={(e) =>
                                  handleChange(
                                    event.fiinXEventTypeId,
                                    e.target.checked,
                                  )
                                }
                              />
                              <span>{event.fiinXEventName}</span>
                            </label>
                          </li>
                        ))}
                      </ul>
                    </ScrollComponent>
                  </div>
                )}
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )

  return (
    <>
      <Translate
        value="market.event.EVENT_TYPES"
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />
      {/* {size.height && renderEventTypes(size.height)} */}
      {renderEventTypes()}
    </>
  )
}

export default EventType
