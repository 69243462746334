import PropTypes from 'prop-types'
import { useState } from 'react'
import { Summary } from '.'
import { Panel } from '../../../../common/panel'
import { PANEL_PADDING } from '../../../../constants/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import UseTimeZone from './../../../../common/hooks/useTimeZone'
import { TABLE_DOWNLOAD, TAB_TYPE } from './constants'

export const PanelSummary = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()
  // Use state
  const [filter, setFilter] = useState({})
  const [tab, setTab] = useState(TAB_TYPE.OVERVIEW)

  // Actions
  const downloadCsv = () => {
    return TABLE_DOWNLOAD[tab]({
      ...filter,
      StartDate: filter.StartDate
        ? getISOStartOrEndOfDay(filter.StartDate, timeZone, true)
        : undefined,
      EndDate: filter.EndDate
        ? getISOStartOrEndOfDay(filter.EndDate, timeZone, false)
        : undefined,
    })
  }

  return (
    <Panel
      title="market.equityTrading.priceStatistics.PRICE_DATA"
      panelRefs={panelRefs}
      panelKey={mapKey.SUMMARY}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <Summary
        onFilterChange={setFilter}
        onTabChange={setTab}
        width={sizes[mapKey.SUMMARY].width - PANEL_PADDING}
      />
    </Panel>
  )
}

PanelSummary.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
