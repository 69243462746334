import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectBasicInfo } from '../Filter/store/slice'
import { valueTab } from './constants'
import Content from './contents'
import Filter from './filter'
import { selectTabActive } from './store/slice'
import { getDataFileThunk, getDataThunk } from './store/thunk'

const Information = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const bondInfo = useSelector(selectBasicInfo)
  const locale = useSelector((state) => state.i18n.locale)
  const activeTab = useSelector(selectTabActive)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const getData = () => {
    if (activeTab === valueTab.new) {
      dispatch(
        getDataThunk({
          BondId: bondInfo?.bondId,
          // BondId: '6197',
        }),
      )
    } else {
      dispatch(
        getDataFileThunk({
          BondId: bondInfo?.bondId,
          // BondId: '2352',
        }),
      )
    }
  }
  useEffect(() => {
    if (bondInfo?.bondId) {
      getData()
    }
  }, [bondInfo?.bondId, locale, activeTab])

  return (
    <Panel
      title="bond.bondSecurity.common.Information"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      downloadCsv={() => {}}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                <Content width={width} />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default Information
