import _ from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { renderDate } from '../../liquidityChart/LiquidityChartTooltip'
import { BAR_COLOR_MARKET_TRADING_VALUE } from '../constants'
import { renderDateAdditional } from '../helper'
import {
  keys,
  selectFilterMarketTradingValue,
  selectLoading,
  selectMarketTradingValueData,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 10,
  fontSize: 11,
}

const MarketTradingValue = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.MARKET_TRADING_VALUE))
  const data = useSelector(selectMarketTradingValueData)
  const { timeFrequency, isTradingMethod } = useSelector(
    selectFilterMarketTradingValue,
  )
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(() => {
    return data.map((item) => ({
      ...item,
      date: `${renderDate(
        timeFrequency,
        locale,
        item.date.split(' ')[0],
        timeZone,
      )}${renderDateAdditional(
        timeFrequency,
        renderDate(timeFrequency, locale, item.date.split(' ')[0], timeZone),
      )}`,
      totalValue: valDivBillion(item.totalValue),
      totalValuePublic: valDivBillion(item.totalValuePublic),
      totalValuePrivate: valDivBillion(item.totalValuePrivate),
      matchValue: valDivBillion(item.matchValue),
      dealValue: valDivBillion(item.dealValue),
      // totalValue: formatVal(valDivBillion(item.totalValue)),
      // totalValuePublic: formatVal(valDivBillion(item.totalValuePublic)),
      // totalValuePrivate: formatVal(valDivBillion(item.totalValuePrivate)),
      // matchValue: formatVal(valDivBillion(item.matchValue)),
      // dealValue: formatVal(valDivBillion(item.dealValue)),
    }))
  }, [data])

  const maxRange = useMemo(() => {
    return _.max(data.map((item) => valDivBillion(item.totalValue)))
  }, [data])

  const renderTooltip = (dataChart) => <ChartTooltip data={dataChart} />

  const titleJpg = useMemo(() => {
    const title = I18n.t('bond.corporateBond.marketTradingValue.TITLE')

    return title
  }, [locale])

  return (
    <Panel
      title="bond.corporateBond.marketTradingValue.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isIconChart
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'marketTradingValueChartId',
        nameScreen: I18n.t(
          'bond.corporateBond.secondaryMarket.TRADE_STATISTICS',
        ),
        chartName: I18n.t('bond.corporateBond.marketTradingValue.TITLE'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="marketTradingValueChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {formatDataBarChart.length ? (
                        <>
                          <div style={{ height: height - size.height - 34 }}>
                            <ChartContainer
                              data={formatDataBarChart}
                              width={width}
                              height={height - size.height - 28}
                              keyXAxis="date"
                              xTickNum={SETTINGS.xTickNum}
                              timeFrame="CUSTOM"
                              tickFormatter={(d) => d}
                              yAxis={[
                                {
                                  id: 'left',
                                  keys: isTradingMethod
                                    ? ['matchValue', 'dealValue']
                                    : ['totalValuePublic', 'totalValuePrivate'],
                                  orientation: 'left',
                                  label: I18n.t(
                                    'bond.corporateBond.issuanceValueBySector.NET_VALUE',
                                  ),
                                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                                  tickNum: 5,
                                  // isBarChart: true,
                                  max: maxRange,
                                  min: 0,
                                },
                              ]}
                              margin={{
                                left: 0,
                                right: 16,
                                top: 20,
                                bottom: 10,
                              }}
                              renderCustomTooltip={renderTooltip}
                            >
                              {({ chartContentWidth }) => (
                                <>
                                  <Bar
                                    stackId="stackId1"
                                    isAnimationActive={false}
                                    yAxisId="left"
                                    dataKey={
                                      isTradingMethod
                                        ? 'matchValue'
                                        : 'totalValuePublic'
                                    }
                                    fill={BAR_COLOR_MARKET_TRADING_VALUE[0]}
                                    barSize={30}
                                  />
                                  <Bar
                                    stackId="stackId1"
                                    isAnimationActive={false}
                                    yAxisId="left"
                                    dataKey={
                                      isTradingMethod
                                        ? 'dealValue'
                                        : 'totalValuePrivate'
                                    }
                                    fill={BAR_COLOR_MARKET_TRADING_VALUE[1]}
                                    barSize={30}
                                  />

                                  {/* <ReferenceLine
                                yAxisId="right"
                                y={!!data.length ? data[0].totalValue * 100 : 0}
                                stroke={BAR_COLOR_MARKET_TRADING_VALUE[1]}
                                strokeWidth={1}
                                strokeDasharray="5 5"
                              /> */}
                                </>
                              )}
                            </ChartContainer>
                          </div>
                          <Footer
                            key={width}
                            list={[
                              {
                                text: isTradingMethod
                                  ? 'bond.corporateBond.marketTradingValue.MATCHED_VALUE'
                                  : 'bond.corporateBond.marketTradingValue.PUBLIC',
                                type: TYPE.SQUARE,
                                before: {
                                  bgColor: BAR_COLOR_MARKET_TRADING_VALUE[0],
                                },
                              },
                              {
                                text: isTradingMethod
                                  ? 'bond.corporateBond.marketTradingValue.PUT_THROUGH_VALUE'
                                  : 'bond.corporateBond.marketTradingValue.PRIVATE',
                                type: TYPE.SQUARE,
                                before: {
                                  bgColor: BAR_COLOR_MARKET_TRADING_VALUE[1],
                                },
                              },
                            ]}
                          />
                        </>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default MarketTradingValue
