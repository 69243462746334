import { useDispatch, useSelector } from 'react-redux'
import Radio from '../../../../common/html/Radio'
import SelectCustom from '../../../../common/selectCustom'
import { DispatchActionTabWithCalendar } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { NAV_CERT_TIME_RANGES, TICKER_OPTIONS, VALUE_TYPES } from './constants'
import { changeFilter, selectFilters } from './store/slice'

export const Filter = () => {
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)

  // Actions
  const handleChangeFilter = (value) => {
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  const handleChangeValueType = (value) => {
    dispatch(changeFilter({ valueType: value }))
  }

  const handleChangeTimeRange = (item) => {
    handleChangeFilter({ timeRange: item.value })
  }

  const handleChangeCalendar = (payload) => {
    handleChangeFilter({
      timeRange: payload.activeTab,
      startDate: payload.startDate,
      endDate: payload.endDate,
    })
  }

  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <div className="d-flex ali-center">
        <Radio
          data={Object.keys(VALUE_TYPES).map((key) => ({
            title: 'fund.fundProfile.navData.' + key,
            value: VALUE_TYPES[key],
          }))}
          value={filters.valueType}
          onChange={handleChangeValueType}
          containerClass="w-auto d-flex mr-16"
        />
        <div style={{ width: 180 }}>
          <SelectCustom
            value={filters.ticker}
            selectData={Object.keys(TICKER_OPTIONS).map((key) => ({
              name: 'fund.fundProfile.navData.navCertificate.' + key,
              value: TICKER_OPTIONS[key],
            }))}
            handleChange={(value) => handleChangeFilter({ ticker: value })}
            isI18n
          />
        </div>
      </div>
      <div className="ml-8">
        <DispatchActionTabWithCalendar
          id="navDataNavCertificate"
          tabs={NAV_CERT_TIME_RANGES}
          activeTab={filters.timeRange}
          startDate={filters.startDate}
          endDate={filters.endDate}
          datePreset={NAV_CERT_TIME_RANGES}
          onChangeTab={handleChangeTimeRange}
          onChangeCalendar={handleChangeCalendar}
        />
      </div>
    </div>
  )
}
