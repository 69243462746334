import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import AdditionalInfoPrice from '../components/AdditionalInfoPrice'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import {
  selectPriceData,
  selectSectorInfo,
  subscribeSector,
} from './store/slice'
import { getLatestPrice } from './store/thunk'

const PriceData = () => {
  const dispatch = useDispatch()
  const sectorInfo = useSelector(selectSectorInfo)
  const priceData = useSelector(selectPriceData)

  useEffect(() => {
    if (sectorInfo.icbId)
      dispatch(
        getLatestPrice({
          Ids: sectorInfo.icbId,
          GroupType: GROUP_TYPE.SECTOR,
        }),
      )
  }, [sectorInfo.icbId])

  return (
    <div className="info-box">
      <Realtime
        channel={ChannelConfig.SectorChannel + priceData.icbId}
        action={subscribeSector}
      >
        <SummaryInfoPrice data={{ ...priceData, isTicker: false }} />
        <AdditionalInfoPrice data={{ ...priceData, isTicker: false }} />
      </Realtime>
    </div>
  )
}

export default PriceData
