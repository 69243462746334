import PropTypes from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN } from '../../../../common/chart/constants'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import { ChartContainer } from '../../common/ChartContainer'
import { StackedChartWithLine } from '../../common/StackedBarLineChart'
import { TIME_FILTER_VALUE } from '../filter/constants'
import {
  getCompareChartMargin,
  getComparisonChartData,
  getGrowthTimelineChartData,
} from '../helps'
import {
  selectFinancialRatioFilter,
  selectIndicator,
  selectMappingLoading,
  selectTableConstants,
} from '../store/slice'
import { chartFillColor, chartSettings } from './constants'
import {
  selectGrowthTimelineChartData,
  selectPeerComparisonChartData,
  selectPeerComparisonChartLoading,
} from './store/slice'
import { getPeersComparisonChartData } from './store/thunk'

export const ComparisonChart = ({ height, width }) => {
  const calcFontSize = getFontSize(chartSettings.fontSize)
  const margin = { ...MARGIN, right: 0 }
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const peerComparisonChartData = useSelector(selectPeerComparisonChartData)
  const growthTimelineChartData = useSelector(selectGrowthTimelineChartData)
  const filter = useSelector(selectFinancialRatioFilter)
  const isLoading = useSelector(selectPeerComparisonChartLoading)
  const isMappingLoading = useSelector(selectMappingLoading)
  const indicator = useSelector(selectIndicator)
  const tableConstants = useSelector(selectTableConstants)

  const isFormatValToPercent = indicator.subValue?.isFormatValToPercent

  // Get data
  const getComparisonData = useMemo(() => {
    return getComparisonChartData(indicator, peerComparisonChartData, basicInfo)
  }, [JSON.stringify(indicator.subValue), peerComparisonChartData, basicInfo])

  const getGrowthTimelineData = useMemo(() => {
    return getGrowthTimelineChartData(
      indicator,
      growthTimelineChartData,
      filter,
    )
  }, [
    JSON.stringify(indicator.subValue),
    growthTimelineChartData,
    filter.viewBy.value,
  ])

  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [indicator.subValue, growthTimelineChartData, peerComparisonChartData])

  const getLeftLabel = useMemo(() => {
    if (isFormatValToPercent) {
      return ''
    }

    switch (indicator.subValue?.isFormatValue.formatValue) {
      case 10 ** 3:
        return 'corporate.financialData.financialRatio.CHART_THOUSAND_VND'
      case 10 ** 6:
        return 'corporate.financialData.financialRatio.CHART_MILLION_VND'
      case 10 ** 9:
        return 'corporate.financialData.financialRatio.CHART_BILLION_VND'
      default:
        return indicator.subValue?.unit
    }
  }, [indicator.subValue])

  const getChartUnit = useMemo(() => {
    if (isFormatValToPercent) {
      return '%'
    }

    return indicator.subValue?.unit
  }, [indicator.subValue])

  const getTopLabel = useMemo(() => {
    const data = peerComparisonChartData[0]
    let quarter = 0
    let year = filter.lastestYear.value
    let text = I18n.t(
      'corporate.financialData.financialDividend.PEER_COMPARISON',
    )

    if (data) {
      quarter = data.quarter === 5 ? 4 : data.quarter
      year = data.year
    }

    if (filter.viewBy.value === TIME_FILTER_VALUE.QUARTERLY && quarter) {
      text += ` Q${quarter}-${year}`
    } else {
      text += ` ${year}`
    }

    return text
  }, [peerComparisonChartData, filter.lastestYear.value, filter.viewBy.value])

  // Use effect
  useEffect(() => {
    if (!isLoading && basicInfo.organizationId && tableConstants.length) {
      dispatch(
        getPeersComparisonChartData({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: filter.viewBy.value,
          NumberOfPeriod: filter.numberOfPeriod.value,
          LatestYear: filter.lastestYear.value,
          Consolidated: filter.type.value,
        }),
      )
    }
  }, [filter, basicInfo.organizationId, tableConstants])

  return (
    <div id="peerComparisonChart" className="h-100">
      <ChartContainer
        key={getKey}
        data={getComparisonData}
        topLabel={getTopLabel}
        height={height}
        width={width}
        isLoading={isLoading || isMappingLoading}
      >
        {(resizeSize) => (
          <StackedChartWithLine
            data={getComparisonData}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            xAxisKey="label"
            barKeys={['comparisonValue']}
            unit={getChartUnit}
            leftLabel={getLeftLabel}
            mappingDisplayName={{
              comparisonValue: indicator.subValue?.name || '',
            }}
            margin={{
              ...margin,
              ...getCompareChartMargin(
                getComparisonData,
                getGrowthTimelineData,
                ['comparisonValue'],
                ['growthTimelineValue'],
                calcFontSize,
              ),
            }}
            isYAxisPercentLeft={isFormatValToPercent}
          />
        )}
      </ChartContainer>
    </div>
  )
}

ComparisonChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
