import { useState } from 'react'
import { GrowthSector } from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'

export const PanelGrowthSector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title="economy.cpiAndInflation.titlePanel.CHANGES_INDICATORS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(
        'economy.cpiAndInflation.titlePanel.CHANGES_INDICATORS',
      )}
      downloadJpgParams={{
        domId: 'growthSector',
        nameScreen: UseI18n('economy.cpiAndInflation.titlePanel.CPI_INFLATION'),
        chartName: UseI18n(
          'economy.cpiAndInflation.titlePanel.CHANGES_INDICATORS',
        ),
      }}
      handleCustom={setIsFullScreen}
    >
      <GrowthSector
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
