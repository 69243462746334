import { Fragment, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import FundPortfolioService from '../../../../../core/services/fund/FundPortfolioService'
import BasicChart from '../../../../common/chart/basicChart'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { Filter } from './Filter'
import { TABS } from './constants'
import { formatAssetDataApi, getAssetProps } from './helpers/assetHelpers'
import { formatSectorDataApi, getSectorProps } from './helpers/sectorHelpers'
import { selectFilters } from './store/slice'

export const AssetAllocationOverTime = ({
  id,
  width,
  height,
  isFullScreen,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const [data, setData] = useState([])

  // Get data
  const getParams = useMemo(() => {
    return {
      OrganizationId: fundInfo.organizationId,
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From: filters.startDate,
      To: filters.endDate,
    }
  }, [
    filters.timeRange,
    filters.startDate,
    filters.endDate,
    fundInfo.organizationId,
  ])

  const getProps = useMemo(() => {
    switch (filters.type) {
      case TABS.SECTORS:
        return getSectorProps({
          data,
          valueType: filters.valueType,
          isFullScreen,
        })
      case TABS.ASSETS:
      default:
        return getAssetProps({ valueType: filters.valueType, isFullScreen })
    }
  }, [
    filters.type,
    JSON.stringify(data),
    filters.valueType,
    locale,
    isFullScreen,
  ])

  const getApiProps = useMemo(() => {
    switch (filters.type) {
      case TABS.SECTORS:
        return {
          serviceApi: (params) =>
            FundPortfolioService.getSectorAllocationOverTime(params),
          formatDataApi: formatSectorDataApi,
        }
      case TABS.ASSETS:
      default:
        return {
          serviceApi: (params) =>
            FundPortfolioService.getAssetAllocationOverTime(params),
          formatDataApi: formatAssetDataApi,
        }
    }
  }, [filters.type, locale])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <Fragment key={filters.type}>
                <BasicChart
                  id={id}
                  width={width}
                  height={height - size.height}
                  params={getParams}
                  onGetDataApi={setData}
                  {...getApiProps}
                  {...getProps}
                />
              </Fragment>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
