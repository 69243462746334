import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import SelectCustom from '../../../../common/selectCustom'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { range } from '../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { selectExchangeCode } from '../slice'
import style from '../style.module.css'
import { ChartComponent } from './ChartComponent'
import { QuarterSelectOption } from './QuarterSelectOption'
import { getLatestQuarters } from './helper'
import {
  changeYear,
  selectDataTopRevenue,
  selectDataTopTotalOperatingIncome,
  selectLoading,
  selectQuarter,
  selectRetryFlag,
  selectYear,
} from './store/slice'
import {
  getTopRevenueChartData,
  getTopTotalOperatingIncomeChartData,
} from './store/thunk'

const TopRevenue = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const filterYear = useSelector(selectYear)
  const exchangeCode = useSelector(selectExchangeCode)
  const isLoading = useSelector(selectLoading)
  const dataRevenueChart = useSelector(selectDataTopRevenue)
  const dataTopTotalOperatingIncome = useSelector(
    selectDataTopTotalOperatingIncome,
  )
  const filterQuarter = useSelector(selectQuarter)
  const retryFlag = useSelector(selectRetryFlag)

  const YEARS = range(
    getCurrentDateTime(FORMAT.YEAR) * 1 - 4,
    getCurrentDateTime(FORMAT.YEAR) * 1,
  ).reverse()

  const handleChangeYear = (val) => {
    dispatch(changeYear(val))
  }

  useEffect(() => {
    if (sectorInfo.icbId && !retryFlag)
      if (typeSector === COM_GROUP.BANK) {
        dispatch(
          getTopTotalOperatingIncomeChartData({
            ICBId: sectorInfo.icbId,
            ExchangeCode: exchangeCode,
            Year: filterYear,
            Quarter: filterQuarter || undefined,
          }),
        )
      } else {
        dispatch(
          getTopRevenueChartData({
            ICBId: sectorInfo.icbId,
            ExchangeCode: exchangeCode,
            Year: filterYear,
            Quarter: filterQuarter || undefined,
          }),
        )
      }
  }, [sectorInfo.icbId, exchangeCode, filterYear, locale, filterQuarter])

  useEffect(() => {
    if (sectorInfo.icbId && retryFlag) {
      const paramsArr = getLatestQuarters(5).map(({ year, quarter }) => ({
        ICBId: sectorInfo.icbId,
        ExchangeCode: exchangeCode,
        Year: year,
        Quarter: quarter || undefined,
      }))

      if (typeSector === COM_GROUP.BANK) {
        dispatch(getTopTotalOperatingIncomeChartData(paramsArr))
      } else {
        dispatch(getTopRevenueChartData(paramsArr))
      }
    }
  }, [sectorInfo.icbId, retryFlag])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="h-100">
              <div className="d-flex">
                <QuarterSelectOption />
                <div className={style.DropDownYear}>
                  <SelectCustom
                    value={filterYear}
                    selectData={YEARS.map((year) => ({
                      name: year,
                      value: year,
                    }))}
                    handleChange={handleChangeYear}
                  />
                </div>
              </div>
              {size.height && (
                <div
                  id="panelTopRevenueId"
                  style={{ height: height - size.height }}
                >
                  {isLoading ? (
                    <div className="h-100 position-relative">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {(
                        typeSector === COM_GROUP.BANK
                          ? dataTopTotalOperatingIncome.length
                          : dataRevenueChart.length
                      ) ? (
                        <SizeTracker>
                          {(size2) => {
                            return (
                              <>
                                <div className="d-flex justify-content-center mt-8">
                                  <Span
                                    style={{
                                      fontSize: 12,
                                      textTransform: 'uppercase',
                                      fontWeight: 600,
                                    }}
                                    className="canvas-chart-title"
                                  >
                                    {typeSector === COM_GROUP.BANK ? (
                                      <Translate value="sector.sectorConstituents.overview.TOP_TOTAL_OPERATING_INCOME" />
                                    ) : (
                                      <Translate value="sector.sectorConstituents.overview.TOP_REVENUE" />
                                    )}
                                  </Span>
                                </div>
                                <div>
                                  {size.height && (
                                    <div style={{ position: 'relative' }}>
                                      <ChartComponent
                                        data={
                                          typeSector === COM_GROUP.BANK
                                            ? dataTopTotalOperatingIncome
                                            : dataRevenueChart
                                        }
                                        width={width}
                                        height={
                                          height - size.height - size2.height
                                        }
                                        lineKey="rT0054_A"
                                        keyXAxis="shortName"
                                        barKey={
                                          typeSector === COM_GROUP.BANK
                                            ? 'iS38'
                                            : 'iS3'
                                        }
                                        scatterDiamondKey="rT0054"
                                        typeSector={typeSector}
                                      />
                                    </div>
                                  )}
                                  <Footer
                                    key={width}
                                    list={[
                                      {
                                        text:
                                          typeSector === COM_GROUP.BANK
                                            ? 'sector.sectorConstituents.overview.TOTAL_OPERATING_INCOME'
                                            : 'sector.sectorConstituents.overview.NET_REVENUE',
                                        type: TYPE.SQUARE,
                                        before: {
                                          bgColor: '#1d97ef',
                                        },
                                      },
                                      {
                                        text: 'sector.sectorConstituents.overview.NET_MARGIN',
                                        renderIcon: (
                                          <ScatterDiamondShape
                                            color="#f7959c"
                                            style={{ marginRight: 5 }}
                                          />
                                        ),
                                      },
                                      {
                                        text: 'sector.sectorConstituents.overview.PEER_NET_MARGIN',
                                        type: TYPE.LINE,
                                        before: {
                                          bgColor: '#f7b80c',
                                        },
                                      },
                                    ]}
                                  />
                                </div>
                              </>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TopRevenue
