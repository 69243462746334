import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { handleLineColor } from './helper'
import style from './index.module.css'
import {
  getCheckboxChecked,
  getLineChartColor,
  handleCheckboxChecked,
  handleIsShowAlert,
  handleLineChartColor,
} from './store/slice'

const MAX_TICK = 20

const FirstColumnCell = ({
  tableHorizontalHead,
  props,
  rowId,
  onRowCollapse,
}) => {
  const dispatch = useDispatch()
  const [isCollapse, setIsCollapse] = useState(false)
  const { level, title, typeId } = tableHorizontalHead

  const checkboxChecked = useSelector(getCheckboxChecked)
  const lineChartColor = useSelector(getLineChartColor)
  const idChecked = checkboxChecked.map((item) => item.id)

  const getStyle = (level) => {
    switch (level) {
      case 2:
        return { marginRight: 32 }
      case 3:
        return { marginRight: 56 }
      case 4:
        return { marginRight: 80 }
      default:
        return { marginRight: 8 }
    }
  }

  const handleCheckbox = (id) => {
    const isChecked = idChecked.includes(id)
    let newCheckboxChecked = checkboxChecked
    if (isChecked) {
      newCheckboxChecked = checkboxChecked.filter((item) => item.id !== id)
    } else {
      if (checkboxChecked.length >= MAX_TICK) {
        dispatch(handleIsShowAlert(true))
      } else {
        newCheckboxChecked = [...checkboxChecked, { id, typeId }]
      }
    }

    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(handleLineColor(id, lineChartColor, isChecked)),
    )
  }

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <td {...props} className={style.firstCellColumn}>
      <div
        className={`d-flex align-center`}
        onClick={level === 1 ? onCollapse : () => handleCheckbox(rowId)}
      >
        {level === 1 ? (
          <button
            style={{ cursor: 'pointer', paddingLeft: 2, paddingRight: 10 }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <input
            type="checkbox"
            checked={idChecked.includes(rowId)}
            onChange={() => handleCheckbox(rowId)}
            className={`checkbox ${style.checkboxOpenMarket} `}
            style={{ ...getStyle(level), cursor: 'pointer' }}
          />
        )}
        <TextEllipsisStaticLine
          appendStyle={{ color: level === 1 && '#fecf2f' }}
          val={title}
          isI18n={false}
        />
      </div>
      <div className={style.borderLeftOpenMarket} />
    </td>
  )
}

FirstColumnCell.propTypes = {
  props: PropTypes.object.isRequired,
  level: PropTypes.number,
  tableHorizontalHead: PropTypes.object.isRequired,
  onRowCollapse: PropTypes.func.isRequired,
}

export default FirstColumnCell
