import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { register } from '../../../../../utils/ReducerRegistry'
import { listTimeFilter } from '../constants'
import {
  getCreditAndDepositChartData,
  getMarginLendingChartData,
  getSectorEarningGrowthChartData,
} from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
  timeFilter: listTimeFilter[0],
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/dependsOnSector',
  initialState,
  reducers: {
    changeTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSectorEarningGrowthChartData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getSectorEarningGrowthChartData.fulfilled,
      (state, action) => {
        const newData = action.payload.sort((a, b) => {
          if (a.quarter) {
            return (
              moment().set('year', a.year).quarter(a.quarter).unix() -
              moment().set('year', b.year).quarter(b.quarter).unix()
            )
          } else {
            return a.year - b.year
          }
        })
        state.dataChart = newData
        state.isLoading = false
      },
    )
    builder.addCase(
      getSectorEarningGrowthChartData.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )

    builder.addCase(getCreditAndDepositChartData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCreditAndDepositChartData.fulfilled, (state, action) => {
      const newData = action.payload.sort((a, b) => {
        if (a.quarter) {
          return (
            moment().set('year', a.year).quarter(a.quarter).unix() -
            moment().set('year', b.year).quarter(b.quarter).unix()
          )
        } else {
          return a.year - b.year
        }
      })
      state.dataChart = newData
      state.isLoading = false
    })
    builder.addCase(getCreditAndDepositChartData.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getMarginLendingChartData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarginLendingChartData.fulfilled, (state, action) => {
      const newData = action.payload.sort((a, b) => {
        if (a.quarter) {
          return (
            moment().set('year', a.year).quarter(a.quarter).unix() -
            moment().set('year', b.year).quarter(b.quarter).unix()
          )
        } else {
          return a.year - b.year
        }
      })
      state.dataChart = newData
      state.isLoading = false
    })
    builder.addCase(getMarginLendingChartData.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectTimeFilter = (state) => state[slice.name].timeFilter

export const { changeTimeFilter } = slice.actions

register(slice.name, slice.reducer)
