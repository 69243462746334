import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { getThreeColorByValue } from '../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import { PRICE_DATA_TIME_SELECT } from '../../common/CalendarFilter'
import { LightBorderColumnCell } from './LightBorderColumnCell'
import { GROUP_COLUMNS, GROUP_COLUMNS_DETAIL, TABLE_TYPE } from './constants'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
    case TABLE_TYPE.TICKER:
      let schema = GROUP_COLUMNS.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val) => formatVal(format ? val / format : val, count),
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {},
            tdStyle: {
              textAlign: 'right',
              whiteSpace: 'nowrap',
            },
            render: (val, rowId, { style, className }) => (
              <LightBorderColumnCell
                val={formatDateTime(
                  val,
                  getMapFormatDate(filterData?.timeFrequency),
                  locale,
                  timeZone,
                )}
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={className}
              />
            ),
          }
        }
        if (index === 5 || index === 6) {
          return {
            ...result,
            render: (val) => {
              return (
                <div
                  style={{
                    color: isNaN(val) ? '' : getThreeColorByValue(+val),
                  }}
                >
                  {formatVal(format ? val / format : val, count)}
                </div>
              )
            },
          }
        }
        return result
      })
      result.schema = schema

      let rowSpanSchema = [
        GROUP_COLUMNS.map((item) => {
          const colId = item.key
          let title = item.title
          if (colId === 'tradingDateId') {
            switch (filterData?.timeFrequency) {
              case PRICE_DATA_TIME_SELECT.DAILY:
                title = 'market.derivative.DAILY'
                break
              case PRICE_DATA_TIME_SELECT.WEEKLY:
                title = 'market.derivative.WEEKLY'
                break
              case PRICE_DATA_TIME_SELECT.MONTHLY:
                title = 'market.derivative.MONTHLY'
                break
              case PRICE_DATA_TIME_SELECT.QUARTERLY:
                title = 'market.derivative.QUARTERLY'
                break
              case PRICE_DATA_TIME_SELECT.YEARLY:
                title = 'market.derivative.YEARLY'
                break
              default:
                title = item.title
                break
            }
          }
          return {
            colId,
            renderTh: () => (
              <div
                style={{
                  textAlign: ['tradingDateId', 'ticker'].includes(item.key)
                    ? 'left'
                    : 'right',
                }}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
          }
        }),
      ]
      result.rowSpanSchema = rowSpanSchema
      break
    case TABLE_TYPE.DETAIL:
      let schemaDetail = GROUP_COLUMNS_DETAIL.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val) => formatVal(val / format, count),
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            tdStyle: {
              textAlign: 'right',
              whiteSpace: 'nowrap',
            },
            render: (val, rowId, { style, className }) => (
              <LightBorderColumnCell
                val={formatDateTime(
                  val,
                  getMapFormatDate(filterData?.timeFrequency),
                  locale,
                  timeZone,
                )}
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={className}
              />
            ),
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,

            tdStyle: {
              textAlign: 'right',
            },
            render: (val, rowId, { style, className }) => {
              const activeId = dataTable[rowId]?.activeStatusId || 0
              const organizationId = dataTable[rowId]?.FutureId || 0
              return (
                <LightBorderColumnCell
                  val={val}
                  rowId={rowId}
                  isAlignTextLeft
                  styleProps={style}
                  className={className}
                  isCWDetail={activeId === 1}
                  organizationId={organizationId}
                />
              )
            },
          }
        }
        if (index === 6 || index === 7) {
          return {
            ...result,
            render: (val) => {
              return (
                <div
                  style={{
                    color: isNaN(val) ? '' : getThreeColorByValue(+val),
                  }}
                >
                  {formatVal(format ? val / format : val, count)}
                </div>
              )
            },
          }
        }
        return result
      })
      result.schema = schemaDetail

      let rowSpanSchemaDetail = [
        GROUP_COLUMNS_DETAIL.map((item) => {
          const colId = item.key
          let title = item.title
          if (colId === 'tradingDateId') {
            switch (filterData?.timeFrequency) {
              case PRICE_DATA_TIME_SELECT.DAILY:
                title = 'market.derivative.DAILY'
                break
              case PRICE_DATA_TIME_SELECT.WEEKLY:
                title = 'market.derivative.WEEKLY'
                break
              case PRICE_DATA_TIME_SELECT.MONTHLY:
                title = 'market.derivative.MONTHLY'
                break
              case PRICE_DATA_TIME_SELECT.QUARTERLY:
                title = 'market.derivative.QUARTERLY'
                break
              case PRICE_DATA_TIME_SELECT.YEARLY:
                title = 'market.derivative.YEARLY'
                break
              default:
                title = item.title
                break
            }
          }
          return {
            colId,
            renderTh: () => (
              <div
                style={{
                  textAlign: ['tradingDateId', 'ticker'].includes(item.key)
                    ? 'left'
                    : 'right',
                }}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
          }
        }),
      ]
      result.rowSpanSchema = rowSpanSchemaDetail
      break
    default:
  }

  return result
}

const getMapFormatDate = (timeFrequency) => {
  const objMap = {
    Daily: FORMAT.DATE,
    Weekly: FORMAT.DATE,
    Monthly: FORMAT.MONTH_YEAR_NUMBER,
    Quarterly: FORMAT.QUARTER_YEAR,
    Yearly: FORMAT.YEAR,
  }
  if (!timeFrequency) {
    return FORMAT.DATE
  }
  return objMap[timeFrequency]
}
