import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import FundCenterService from '../../../../../../core/services/fund/FundCenterService'
import BasicChart from '../../../../../common/chart/basicChart'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Span } from '../../../../../common/html/Span'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TextNote } from '../../../../../common/textNote'
import { FUND_STRUCTURE_OPTIONS, FUND_TYPE_OPTIONS } from '../../../constants'
import { Filter } from '../filter'
import { formatDataApi, getSchema } from './helpers'
import { changeFilter, selectFilters } from './store/slice'

export const Top10NetAssetValue = ({ id, width, height }) => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const fundNavReport = maxMinTableById['FGFB_COFS_DR_FundNAVReport']

  const yAxisText = UseI18n('common.UNIT.BILLION_VND')

  const filters = useSelector(selectFilters)

  const [data, setData] = useState([])

  const getParams = useMemo(() => {
    return {
      Top: 10,
      Month: filters.month,
      Year: filters.year,
      FundStructureIds:
        filters.structure.join(',') ||
        FUND_STRUCTURE_OPTIONS.map((item) => item.value).join(','),
      FundTypeIds:
        filters.type.join(',') ||
        FUND_TYPE_OPTIONS.map((item) => item.value).join(','),
    }
  }, [filters])

  const getSourceNumber = useMemo(() => {
    return data.length > 0 && data[0].source ? data[0].source : 0
  }, [JSON.stringify(data)])

  const handleChange = (value) => {
    dispatch(changeFilter(value))
  }

  useEffect(() => {
    if (
      fundNavReport?.max_MonthReport &&
      fundNavReport?.max_YearOfMonthReport
    ) {
      handleChange({
        month: fundNavReport.max_MonthReport,
        year: fundNavReport.max_YearOfMonthReport,
      })
    }
  }, [fundNavReport])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter
              {...filters}
              isStructure
              isType
              isMonth
              isYear
              className="mb-24"
              onChange={handleChange}
            />
            {size.height && (
              <BasicChart
                id={id}
                width={width}
                height={height - size.height}
                params={getParams}
                serviceApi={(params) =>
                  FundCenterService.getTopNetAssetValue(params)
                }
                formatDataApi={formatDataApi}
                onGetDataApi={setData}
                schema={getSchema()}
                chartProps={{
                  chartType: 'ComposedChart',
                  yAxis: [
                    {
                      dataKeys: ['column1'],
                      labelText: yAxisText,
                    },
                  ],
                  isUseXAxisDiv: true,
                  showAllTick: true,
                }}
                footerProps={{
                  isHide: true,
                }}
                tooltipProps={{
                  title: (data) => (
                    <div className="t-italic d-flex justify-content-space-between ali-center">
                      <Span style={{ fontSize: 10, marginRight: 16 }}>
                        {data.ticker}
                      </Span>
                      <Span style={{ fontSize: 10 }}>{data.name}</Span>
                    </div>
                  ),
                }}
              />
            )}
            <TextNote
              value={
                <Translate
                  value="fund.fundCenter.overview.performance.SOURCE_FUND"
                  total={getSourceNumber}
                />
              }
              fontSize={11}
              className="mb-8"
            />
          </>
        )
      }}
    </SizeTracker>
  )
}
