import { useDispatch, useSelector } from 'react-redux'
import {
  changeCheckIndexSector,
  selectActiveSheet,
  selectIndexSectorCell,
} from '../store/slice'

const Checkbox = ({ id, colId }) => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const value = useSelector(selectIndexSectorCell(activeSheet)(id, colId))

  const handleChange = () => {
    dispatch(changeCheckIndexSector(id))
  }

  return (
    <div className="d-flex justify-content-center">
      <input
        onChange={handleChange}
        type="checkbox"
        className="checkbox"
        checked={value}
      />
    </div>
  )
}

export default Checkbox
