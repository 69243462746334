import PropTypes from 'prop-types'

export const schemaProps = PropTypes.arrayOf(
  PropTypes.shape({
    text: PropTypes.string,
    isI18n: PropTypes.bool,
    dataKey: PropTypes.string,
    unit: PropTypes.string,
    color: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    yAxisId: PropTypes.string,
    stackId: PropTypes.string,
    chart: PropTypes.shape({
      type: PropTypes.string,
      render: PropTypes.func,
    }),
    footer: PropTypes.shape({
      type: PropTypes.string,
      tooltip: PropTypes.string,
      before: PropTypes.shape({
        bgColor: PropTypes.string,
        borderBottomColor: PropTypes.string,
      }),
      after: PropTypes.shape({
        bgColor: PropTypes.string,
        borderBottomColor: PropTypes.string,
      }),
      dashedLine: PropTypes.shape({
        bgColor: PropTypes.string,
      }),
      renderIcon: PropTypes.node,
    }),
    tooltip: PropTypes.shape({
      renderTitle: PropTypes.func,
      renderValue: PropTypes.func,
      formatValue: PropTypes.func,
    }),
  }),
).isRequired

export const chartProps = PropTypes.shape({
  chartType: PropTypes.oneOf(['PieChart', 'ComposedChart']).isRequired,

  // Composed chart props
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  xAxis: PropTypes.shape({
    dataKey: PropTypes.string,
    label: PropTypes.string,
    labelPosition: PropTypes.string,
    tickCount: PropTypes.number,
    tickFormat: PropTypes.func,
    isHide: PropTypes.bool,
  }),
  yAxis: PropTypes.arrayOf(
    PropTypes.shape({
      yAxisId: PropTypes.string,
      dataKeys: PropTypes.arrayOf(PropTypes.string),
      type: PropTypes.oneOf(['number', 'category']),
      orientation: PropTypes.oneOf(['left', 'right']),
      tickCount: PropTypes.number,
      tickStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      labelText: PropTypes.string,
      labelPosition: PropTypes.string,
      decimalLength: PropTypes.number,
      isGroupBar: PropTypes.bool,
      isStackedBar: PropTypes.bool,
      isLineChart: PropTypes.bool,
      isBarChart: PropTypes.bool,
    }),
  ),
  isUseXAxisDiv: PropTypes.bool,
  showAllTick: PropTypes.bool,

  // Pie chart props
  style: PropTypes.shape({
    fontSize: PropTypes.number,
    height: PropTypes.number.isRequired,
    fill: PropTypes.string,
  }),
  labelPercent: PropTypes.func,
  labelPosition: PropTypes.number,
  lineWidth: PropTypes.number,
  radius: PropTypes.number,
  animate: PropTypes.bool,
  animationDuration: PropTypes.number,
  animationEasing: PropTypes.string,
  themeDownloadPDF: PropTypes.bool,
  onMouseOut: PropTypes.func,
  onHoveredChange: PropTypes.func,
})

export const footerProps = PropTypes.shape({
  // Footer with scroll props
  isFooterScroll: PropTypes.bool,
  isFullScreen: PropTypes.bool,
  onDelete: PropTypes.func,

  // Default footer props
  isOneRow: PropTypes.bool,
  isOneColumn: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  isDownTheLine: PropTypes.bool,
  numRow: PropTypes.number,
  numItemPerRow: PropTypes.number,
  listCheckbox: PropTypes.array,
  appendStyle: PropTypes.object,
  itemStyle: PropTypes.object,
  formatName: PropTypes.func,
  setListCheckbox: PropTypes.func,
  onMouseOverItem: PropTypes.func,
  onMouseLeaveItem: PropTypes.func,
})

export const tooltipProps = PropTypes.shape({
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  render: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
})

export const BasicChartProps = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  schema: schemaProps,
  chartProps: chartProps,
  footerProps: footerProps,
  tooltipProps: tooltipProps,
}
