import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { TABLE_ROW_LEVEL } from './constants'
import { selectItemData } from './store/slice'

const MARGIN_LEFT = 40

const FirstColumnCell = ({ val, rowId, style, className }) => {
  const item = useSelector(selectItemData(rowId))

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    return (
      <td className={className} style={style}>
        <div className="align-center">
          <Span>
            <Translate value={val} />
          </Span>
        </div>
      </td>
    )
  } else {
    return (
      <td className={className} style={style}>
        <div
          className="align-center"
          style={{
            marginLeft: MARGIN_LEFT,
            color: '#ececec66',
            fontStyle: 'italic',
          }}
        >
          <Span>
            <Translate value={val} />
          </Span>
        </div>
      </td>
    )
  }
}

export default FirstColumnCell
