import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { CompanyGrowth } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { COMPANY_GROWTH_FILTER } from '../../common/chartCompanyGrowth/constants'
import { selectCurrentCategoryFilter } from './store/slice'

export const PanelCompanyGrowth = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const nameScreen = UseI18n('sector.sectorSpecific.steel.STEEL')
  const tabName = UseI18n('sector.sectorSpecific.steel.CONSTRUCTION_TAB')

  const currentCategoryFilter = useSelector(selectCurrentCategoryFilter)
  const locale = useSelector((state) => state.i18n.locale)

  const currentCategoryName = I18n.t(
    COMPANY_GROWTH_FILTER.find((item) => item.value === currentCategoryFilter)
      .name,
  )

  const getChartNameByLocale = () => {
    let chartName = ''
    if (locale === 'en') {
      chartName = `${UseI18n(
        'sector.sectorSpecific.steel.COMPANY_GROWTH',
      )} in ${currentCategoryName}`
    } else {
      chartName = `${UseI18n(
        'sector.sectorSpecific.steel.COMPANY_GROWTH_SHORT',
      )} ${currentCategoryName} của doanh nghiệp`
    }
    return chartName
  }

  return (
    <Panel
      title="sector.sectorSpecific.steel.COMPANY_GROWTH"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={getChartNameByLocale()}
      downloadJpgParams={{
        domId: 'companyGrowth',
        nameScreen: nameScreen,
        chartName: getChartNameByLocale(),
        tabName: tabName,
      }}
    >
      <CompanyGrowth
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
