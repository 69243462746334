import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectCoveredWarrants,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getCoveredWarrantsThunk } from './store/thunk'

const useGetCoveredWarrant = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.COVERED_WARRANT))
  const coveredWarrants = useSelector(selectCoveredWarrants)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getCoveredWarrantsThunk())
    }
  }, [locale])

  return {
    loading,
    coveredWarrants,
  }
}

export default useGetCoveredWarrant
