import { useSelector } from 'react-redux'
import { PriceStatistics } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  NUM_OF_PERIOD_DOWNLOAD_DEFAULT,
  TYPE_DATA,
  TYPE_STATISTIC,
} from './constants'
import { selectCurrentTypeStatistic, selectTypeData } from './store/slice'
import { downloadPriceStatistics } from './store/thunk'

export const PanelPriceStatistics = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const typeData = useSelector(selectTypeData)
  const currentTypeStatistic = useSelector(selectCurrentTypeStatistic)

  const isYearly = currentTypeStatistic === TYPE_STATISTIC.YEARLY

  return (
    <Panel
      title="sector.sectorSpecific.price.PRICE_STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadPriceStatistics({
          TimeFrequency: isYearly ? 'Yearly' : 'Monthly',
          IsGrowth: typeData === TYPE_DATA.GROWTH,
          NumOfPeriod: NUM_OF_PERIOD_DOWNLOAD_DEFAULT,
        })
      }
    >
      <PriceStatistics
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
