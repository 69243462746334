import { createAsyncThunk } from '@reduxjs/toolkit'
import AdvancedSearchService from '../../../../../core/services/advanceSearch/AdvancedSearchService'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import SimpleSearchService from '../../../../../core/services/common/SimpleSearchService'

export const getSearchCompanyData = createAsyncThunk(
  'common/stockTopInfo/GET_SEARCH_COMPANY',
  async (params, { rejectWithValue }) => {
    const { isIncludeBond, isOnlySearchHOHAUP, ...others } = params
    const response = isIncludeBond
      ? await AdvancedSearchService.getSearchCompanyData({
          ...others,
          Term: others.Term || 'a',
        })
      : isOnlySearchHOHAUP
      ? await SimpleSearchService.searchHOHAUPCompany(others)
      : await SimpleSearchService.searchCompany(others)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getLatestPrice = createAsyncThunk(
  'common/stockTopInfo/GET_LATEST_PRICE',
  async (params, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
