import { I18n } from 'react-redux-i18n'
import { formatVal } from '../../../utils/Value'
import { RankStatusChart } from './component/RankStatusChart'
import SectorTooltip from './component/SectorTooltip'
import { RankTooltip } from './component/rankTooltip'
import { TickerTooltip } from './component/tickerTooltip'
import { rankingTableConstants, rankingTableFormat } from './constants'
import style from './index.module.css'

const getSchema = (result, textAlign) => {
  return {
    ...result,
    isI18n: false,
    thStyle: {
      textAlign: textAlign,
      fontSize: 10,
      fontWeight: 'bold',
    },
    tdStyle: {
      textAlign: textAlign,
    },
  }
}

export const getTableSchema = (dataByKey) =>
  Object.keys(rankingTableConstants).map((key, index) => {
    const title = `sector.sectorConstituents.ranking.${key}`
    const colId = rankingTableConstants[key]
    const result = {
      colId,
      title,
    }

    if (colId === rankingTableConstants.RANK_VALUE) {
      const schema = getSchema(result, 'center')

      return {
        ...schema,
        render: (val) => {
          switch (val) {
            case 1:
              return (
                <div className="centerItems">
                  <div className={style.top1Rank}>{val}</div>
                </div>
              )
            case 2:
              return (
                <div className="centerItems">
                  <div className={style.top2Rank}>{val}</div>
                </div>
              )
            case 3:
              return (
                <div className="centerItems">
                  <div className={style.top3Rank}>{val}</div>
                </div>
              )
            default:
              return val
          }
        },
      }
    }

    if (
      [
        rankingTableConstants.TICKER,
        rankingTableConstants.COMPANY_NAME,
      ].includes(colId)
    ) {
      const schema = getSchema(result, 'left')

      return {
        ...schema,
        render: (val, rowId) => <TickerTooltip value={val} rowId={rowId} />,
      }
    }

    if (colId === rankingTableConstants.SECTOR) {
      const schema = getSchema(result, 'left')
      return {
        ...schema,
        render: (val, rowId) => <SectorTooltip value={val} rowId={rowId} />,
      }
    }

    if (colId === rankingTableConstants.MARKET_CAP) {
      const schema = getSchema(result, 'right')

      return {
        ...schema,
        render: (val) => formatVal(val / rankingTableFormat.MARKET_CAP),
      }
    }

    if (
      [
        rankingTableConstants.VALUE,
        rankingTableConstants.GROWTH,
        rankingTableConstants.MOMENTUM,
        rankingTableConstants.VGM,
      ].includes(colId)
    ) {
      const schema = getSchema(result, 'center')

      return {
        ...schema,
        render: (val, rowId) => (
          <RankTooltip rowId={rowId} scoreType={colId} value={val} />
        ),
      }
    }

    if (colId === rankingTableConstants.RANKING_STATUS) {
      const schema = getSchema(result, 'center')

      return {
        ...schema,
        canCustomTd: true,
        thTooltip: I18n.t(
          'sector.sectorConstituents.ranking.NOTE_CHART_RANKING',
        ),
        render: (val) => (
          <td style={{ padding: 'unset' }}>
            <RankStatusChart data={val} />
          </td>
        ),
      }
    }

    return getSchema(result, 'left')
  })
