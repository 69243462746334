import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { CHART_TYPES } from '../../../common/chart/financialChart/constant'
import { Span } from '../../../common/html/Span'
import { LIST_TYPE } from '../../common/constants'
// import { useGetUserSettings } from '../../common/useGetUserSettings'
import { screenTypes } from '../../constants'
import { ReactComponent as IconAreaChart } from '../../icon/icon-area-chart.svg'
import { ReactComponent as IconColumnChart } from '../../icon/icon-column.svg'
import { ReactComponent as IconLineChart } from '../../icon/icon-line-chart.svg'
import { ReactComponent as IconStackChart } from '../../icon/icon-stack-column.svg'
import { changeSecurities } from '../../popup/common/store/slice'
import {
  // addIndicator,
  selectIndicatorSelected,
} from '../../popup/popupAddIndicator/store/slice'
import { changeDashboard } from '../../store/slice'
import {
  // changeChartTabIndicatorSelected,
  changeChartTabSecurities,
  changeTreeSecuritiesIndicator,
  changeTypeChart,
  selectEquations,
  selectTreeSecuritiesIndicator,
} from '../store/slice'
import { Header } from './Header'
import style from './Right.module.css'
// import {
//   INDICATOR_COMPANY_DEFAULT,
//   INDICATOR_INDEX_DEFAULT,
//   INDICATOR_SECTOR_DEFAULT,
// } from './helpers'

export const Right = () => {
  const dispatch = useDispatch()

  // const { favoriteColors } = useGetUserSettings()

  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const equations = useSelector(selectEquations)
  const indicatorSelected = useSelector(selectIndicatorSelected)

  const onGoToSettingChart = (value = CHART_TYPES.LINE) => {
    dispatch(
      changeTreeSecuritiesIndicator(
        treeSecuritiesIndicator,
        // If you want set default indicator for added securities that don't have indicator, uncomment code below
        // getTreeSecuritiesIndicators(
        //   treeSecuritiesIndicator,
        //   indicatorSelected,
        //   equations,
        //   favoriteColors,
        // ),
      ),
    )

    let securityType = ''
    let securities = {}
    // let indicatorType = ''
    // let indicator = {}

    if (
      Object.keys(treeSecuritiesIndicator.companies).length &&
      !indicatorSelected.companyIndicator.length
    ) {
      securityType = LIST_TYPE.COMPANIES
      securities = treeSecuritiesIndicator.companies
      // indicatorType = SEC_GROUP.COMPANY
      // indicator = INDICATOR_COMPANY_DEFAULT
    }

    if (
      Object.keys(treeSecuritiesIndicator.indices).length &&
      !indicatorSelected.indexIndicator.length
    ) {
      securityType = LIST_TYPE.INDICES
      securities = treeSecuritiesIndicator.indices
      // indicatorType = SEC_GROUP.INDEX
      // indicator = INDICATOR_INDEX_DEFAULT
    }

    if (
      Object.keys(treeSecuritiesIndicator.sector).length &&
      !indicatorSelected.sectorIndicator.length
    ) {
      securityType = LIST_TYPE.SECTOR
      securities = treeSecuritiesIndicator.sector
      // indicatorType = SEC_GROUP.SECTOR
      // indicator = INDICATOR_SECTOR_DEFAULT
    }

    if (securityType && Object.keys(securities).length) {
      dispatch(
        changeSecurities({
          typeSecurities: securityType,
          value: Object.keys(securities),
        }),
      )
      dispatch(
        changeChartTabSecurities({
          [securityType]: Object.keys(securities),
        }),
      )
    }

    // if (indicatorType && Object.keys(indicator).length) {
    //   dispatch(
    //     addIndicator({
    //       typeIndicator: indicatorType,
    //       value: indicator,
    //     }),
    //   )
    //   dispatch(
    //     changeChartTabIndicatorSelected({
    //       [indicatorType]: [indicator],
    //     }),
    //   )
    // }

    dispatch(changeTypeChart(value))
    dispatch(changeDashboard({ screenType: screenTypes.SETTING_CREATE_CHART }))
  }

  useEffect(() => {
    if (
      Object.values(equations).length ||
      Object.values(indicatorSelected).flat().length ||
      Object.values(treeSecuritiesIndicator.economy).length
    ) {
      onGoToSettingChart()
    }
  }, [indicatorSelected, equations, treeSecuritiesIndicator])

  return (
    <div className="flex-column h-100">
      <Header />
      <div className={style.content}>
        <div className="flex-column centerItems h-100">
          <div>
            <div className={[style.mb24, style.marginAuto].join(' ')}>
              <Span style={{ fontSize: 44 }}>
                <Translate value="financialChart.MESS_CHART_SPACE" />
              </Span>
            </div>
            <div className={[style.mb48, style.marginAuto].join(' ')}>
              <Span style={{ fontSize: 18 }}>
                <Translate value="financialChart.MESS_CHART_SPACE_1" />
              </Span>
            </div>
            <div className={style.groupIcon}>
              <IconColumnChart />
              <IconLineChart />
              <IconStackChart />
              <IconAreaChart />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
