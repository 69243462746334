import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import {
  formatChartSchemaBySecurities,
  formatChartSchemaByTime,
  formatData,
} from '../../../common/helpers'
import { getChartTimeRange } from '../../../common/helpers/chartHelpers'
import FinancialChartTable from '../../../common/table'
import { useGetNameSecuritiesIndicator } from '../../common/useGetNameSecuritiesIndicator'
import {
  keyToolHeaderSettings,
  PADDING_SIDES,
  VIEW_TYPE,
} from '../../constants'
import { selectTimeRange, selectTimeRangeDate } from '../../store/slice'
import style from '../../style.module.css'
import { ChartContent } from './ChartContent'

const ChartContainer = ({
  width,
  height,
  chartData,
  tableData,
  fullDataByTimeDataKey,
  chartSettings,
  isEdit,
  loading,
  dimensions,
  heightLeftoversContentChart,
  onChangeListLegendCustom,
  onChangeListYAxisCustom,
  onChangeStyleSettings,
  onRemoveAllTooltip,
  onClickTooltip,
}) => {
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const timeRange = useSelector(selectTimeRange)
  const timeRangeDate = useSelector(selectTimeRangeDate)

  const { getNameSecuritiesIndicator, getIndicator } =
    useGetNameSecuritiesIndicator()

  // Use state
  const [schema, setSchema] = useState()
  const [footerData, setFooterData] = useState([])

  // Get data
  const {
    settings,
    treeSecuritiesIndicator,
    equations,
    timeFrequency,
    listLegendCustom,
    styleSetting,
    viewType,
    isShowTable,
    listYAxisCustom,
  } = chartSettings

  const getChartData = useMemo(() => {
    if (!chartData.length || !schema || !schema.charts) {
      return []
    } else {
      return formatData(
        chartData,
        fullDataByTimeDataKey,
        schema.charts,
        viewType,
        timeFrequency,
        timeZone,
      )
    }
  }, [
    JSON.stringify(schema?.charts),
    chartData,
    fullDataByTimeDataKey,
    timeFrequency,
    timeZone,
  ])

  // Use effect
  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_SECURITIES) {
      const { chartSchema, chartFooter } = formatChartSchemaBySecurities({
        treeSecuritiesIndicator,
        equations,
        settings,
        getNameSecuritiesIndicator,
        listLegendCustom,
        listYAxisCustom,
        getIndicator,
        locale,
      })
      setFooterData(chartFooter)
      setSchema(chartSchema)
    } else {
      const { chartSchema, chartFooter } = formatChartSchemaByTime({
        treeSecuritiesIndicator,
        equations,
        settings,
        getNameSecuritiesIndicator,
        listLegendCustom,
        listYAxisCustom,
        getIndicator,
        data: getChartData,
        timeZone,
        timeFrequency,
        timeRange: getChartTimeRange(timeRange, timeRangeDate),
        locale,
      })
      setFooterData(chartFooter)
      setSchema(chartSchema)
    }
  }, [
    JSON.stringify(treeSecuritiesIndicator),
    JSON.stringify(equations),
    JSON.stringify(listYAxisCustom),
    JSON.stringify(listLegendCustom),
    settings,
    getChartData,
    getIndicator,
    viewType,
    locale,
  ])

  // Render
  if (loading) return <Loading isBlack />

  return (
    <div>
      <ChartContent
        data={getChartData}
        schema={schema}
        footer={footerData}
        width={dimensions?.width || width}
        height={
          !isShowTable
            ? dimensions?.height || height
            : ((height - heightLeftoversContentChart || 0) * 2) / 3
        }
        listLegendCustom={listLegendCustom}
        styleSetting={styleSetting}
        isEdit={isEdit}
        onChangeListLegendCustom={onChangeListLegendCustom}
        onChangeListYAxisCustom={onChangeListYAxisCustom}
        onChangeStyleSettings={onChangeStyleSettings}
        isShowFooter={settings?.chartLegendFooter}
        onRemoveAllTooltip={onRemoveAllTooltip}
        onClickTooltip={onClickTooltip}
        typeLegendYAxis={settings[keyToolHeaderSettings.Y_AXIS_LEGEND]}
      />
      {isShowTable && (
        <div
          style={{
            width: width - PADDING_SIDES,
            height: (height - heightLeftoversContentChart || 0) / 3,
          }}
        >
          <div className={style.contentTable}>
            <FinancialChartTable
              data={tableData}
              fullDataByTimeDataKey={fullDataByTimeDataKey}
              schema={schema}
              viewType={viewType}
              treeSecuritiesIndicator={treeSecuritiesIndicator}
              equations={equations}
              listLegendCustom={listLegendCustom}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default ChartContainer
