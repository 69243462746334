import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { keyBy } from '../../utils/Common'
import { HandleClickOutside } from '../HandleClickOutside'
import { ScrollComponent } from '../ScrollComponent'
import Dropdown from '../dropdown'
import UseI18n from '../hooks/useI18n'
import { Input } from '../html/Input'
import { Span } from '../html/Span'
import TextEllipsis from '../textEllipsis'
import { TextEllipsisStaticLine } from '../textEllipsis/TextEllipsisStaticLine'
import style from './index.module.css'

export const SelectSearch = ({
  width,
  options,
  value,
  isSearch,
  isI18n,
  selectedName,
  renderName,
  onSearch,
  onChange,
}) => {
  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  // Get data
  const optionsByValue = keyBy(options, 'value')

  const getSelectedName = useMemo(() => {
    if (selectedName) return selectedName
    if (!selected) return ''
    return isI18n
      ? I18n.t(optionsByValue[selected].name)
      : optionsByValue[selected].name
  }, [selected, JSON.stringify(optionsByValue), locale])

  // Actions
  const handleSearch = (event) => {
    const value = event.target.value
    setSearch(value)
    onSearch(value)
  }

  const handleChange = (value) => {
    setSelected(value)
    onChange(value)
  }

  const handleToggle = () => {
    setIsShowDropdown((prev) => !prev)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  // Use effect
  useEffect(() => {
    if (value !== selected) {
      setSelected(value)
    }
  }, [value])

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox`}
      style={{ width, margin: 0 }}
    >
      <Span
        onClick={handleToggle}
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        className={`search-inbox d-flex ali-center ${style.displayText}`}
      >
        <TextEllipsisStaticLine val={getSelectedName} isI18n={false} />
      </Span>
      <button type="button" onClick={handleToggle}>
        <i
          className={`${
            !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
          } ${style.iconArrow}`}
        />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearchCompany}`}
              style={{ width }}
            >
              {isSearch && (
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${style.search}`}
                    placeholder={UseI18n('common.SEARCH')}
                    value={search}
                    onChange={handleSearch}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" style={{ fontSize: 8 }} />
                  </button>
                </div>
              )}
              <ScrollComponent
                autoHeight={true}
                autoHeightMax={253}
                autoHeightMin={30}
                verticalTrackWidth={5}
                horizontalTrackWidth={5}
              >
                <ul className="list-check">
                  {options.map((item, index) => {
                    return (
                      <li key={`key-${index}`}>
                        <a onClick={() => handleChange(item.value)}>
                          <label
                            className="d-flex ali-center"
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {renderName ? (
                              renderName(item)
                            ) : (
                              <TextEllipsis
                                text={item.name}
                                zIndexTooltip={999}
                                isI18n={isI18n}
                              />
                            )}
                          </label>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

SelectSearch.defaultProps = {
  isI18n: false,
}
