export const sectorInDepth = {
  NAME_SCREEN: 'Sector in-depth',
  TAB_NAME: 'Total value by sector',
  CHART_VALUE_RELATIVE_WITH_INDEX: 'Value relative with index',
  CHART_VALUE: 'Value',
  CHART_PERCENT_VALUE: '% Value',
  SECTOR_STATISTIC: 'SECTOR STATISTIC',
  SECTOR: 'SECTOR',
  NOTE1: 'Sectors are categorized by Industry Classification Benchmark (ICB),',
  NOTE2: 'a global standard for industry classification.',
  CHANGE1D: '% 1D CHANGE',
  CHANGE1W: '% 1W CHANGE',
  CHANGE1M: '% 1M CHANGE',
  CHANGE3M: '% 3M CHANGE',
  CHANGE6M: '% 6M CHANGE',
  CHANGE1Y: '% 1Y CHANGE',
  AVG_TRADING_VALUE1D: 'AVG TOTAL VALUE 1D',
  AVG_TRADING_VALUE1W: 'AVG TOTAL VALUE 1W',
  AVG_TRADING_VALUE1M: 'AVG TOTAL VALUE 1M',
  AVG_TRADING_VALUE3M: 'AVG TOTAL VALUE 3M',
  AVG_TRADING_VALUE6M: 'AVG TOTAL VALUE 6M',
  AVG_TRADING_VALUE1Y: 'AVG TOTAL VALUE 1Y',
  AVG_TRADING_VOLUME1D: 'AVG TOTAL VOLUME 1D',
  AVG_TRADING_VOLUME1W: 'AVG TOTAL VOLUME 1W',
  AVG_TRADING_VOLUME1M: 'AVG TOTAL VOLUME 1M',
  AVG_TRADING_VOLUME3M: 'AVG TOTAL VOLUME 3M',
  AVG_TRADING_VOLUME6M: 'AVG TOTAL VOLUME 6M',
  AVG_TRADING_VOLUME1Y: 'AVG TOTAL VOLUME 1Y',
  CHANGE_VALUE1D: '% 1D CHANGE VALUE',
  CHANGE_VALUE1W: '% 1W CHANGE VALUE',
  CHANGE_VALUE1M: '% 1M CHANGE VALUE',
  CHANGE_VALUE3M: '% 3M CHANGE VALUE',
  CHANGE_VALUE6M: '% 6M CHANGE VALUE',
  CHANGE_VALUE1Y: '% 1Y CHANGE VALUE',
  CHANGE_VOLUME1D: '% 1D CHANGE VOLUME',
  CHANGE_VOLUME1W: '% 1W CHANGE VOLUME',
  CHANGE_VOLUME1M: '% 1M CHANGE VOLUME',
  CHANGE_VOLUME3M: '% 3M CHANGE VOLUME',
  CHANGE_VOLUME6M: '% 6M CHANGE VOLUME',
  CHANGE_VOLUME1Y: '% 1Y CHANGE VOLUME',
  TRADING_VALUE: 'TOTAL VALUE',
  VALUE: '% VALUE',
  TRADING_VOLUME: 'TOTAL VOLUME',
  VOLUME: '% VOLUME',
  ICB_LEVEL: 'Level',
  TITLE_CHART: 'TOTAL VALUE BY SECTOR',
  TAB_VALUE: 'VALUE',
  TAB_PERCENT_VALUE: '% VALUE',
  RELATIVE_INDEX: 'Relative with Index',
  POINT: '(Point)',
  VALUE_CONTRIBUTION: 'Value contribution',
  TOTAL_VALUE: 'Total value',
  BILLION: '(Billion VND)',
  TITLE_TOP_VALUE: 'TOP TOTAL VALUE',
  NO: 'NO',
  TICKER: 'TICKER',
  PRICE: 'PRICE',
  CHANGE: '% CHANGE',
  LOCAL_INDIVIDUAL: 'LOCAL\nINDIVIDUAL',
  LOCAL_INSTITUTION: 'LOCAL\nINSTITUTION',
  FOREIGN: 'FOREIGN',
  PROPRIETARY: 'PROPRIETARY',
  RELATIVE_VALUE: 'Value (BN. VND)',
  RELATIVE_BILLION: 'Bn. VND',
  TOTAL: 'Total',
  LAST: 'LAST',
  ONE_DAY_CHANGE: 'CHANGE',
  ONE_DAY_PERCENT_CHANGE: '% 1D CHANGE',
  ONE_DAY_VALUE: 'TOTAL VALUE',
  ONE_DAY_VOLUMN: 'TOTAL VOLUME',
  FOREIGN_VALUE: 'FR. NET TOTAL VALUE',
  FOREIGN_VOLUMN: 'FR. NET TOTAL VOLUME',
  VALUE_BILLION: 'Bn.',
}
