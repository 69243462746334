import FundProfileProxy from '../../proxies/fund/FundProfileProxy'
import { ServiceBase } from '../ServiceBase'

export class FundProfileService extends ServiceBase {
  getBasicInformation(params) {
    return this.applyMemoryCache(
      'FundProfileProxy.getBasicInformation',
      params,
    )(() => FundProfileProxy.getBasicInformation(params))
  }

  getInvestmentFees(params) {
    return this.applyMemoryCache(
      'FundProfileProxy.getInvestmentFees',
      params,
    )(() => FundProfileProxy.getInvestmentFees(params))
  }

  getDistributors(params) {
    return this.applyMemoryCache(
      'FundProfileProxy.getDistributors',
      params,
    )(() => FundProfileProxy.getDistributors(params))
  }

  getInvestmentStrategy(params) {
    return this.applyMemoryCache(
      'FundProfileProxy.getInvestmentStrategy',
      params,
    )(() => FundProfileProxy.getInvestmentStrategy(params))
  }

  getBoardOfFundManagers(params) {
    return this.applyMemoryCache(
      'FundProfileProxy.getBoardOfFundManagers',
      params,
    )(() => FundProfileProxy.getBoardOfFundManagers(params))
  }

  downloadBasicInformation(params) {
    return this.getFileDownload(() =>
      FundProfileProxy.downloadBasicInformation(params),
    )
  }

  downloadInvestmentFees(params) {
    return this.getFileDownload(() =>
      FundProfileProxy.downloadInvestmentFees(params),
    )
  }

  downloadDistributors(params) {
    return this.getFileDownload(() =>
      FundProfileProxy.downloadDistributors(params),
    )
  }

  downloadBoardOfFundManagers(params) {
    return this.getFileDownload(() =>
      FundProfileProxy.downloadBoardOfFundManagers(params),
    )
  }
}
export default new FundProfileService()
