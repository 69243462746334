import { COMPONENT } from '../../../configs/Layout'
import useGetHeaderSearch from '../../common/hooks/useGetHeaderSearch'
import { TradingView } from '../../common/tradingView'
import { withWrapper } from '../../common/withWrapper'
import { Panel } from './../../common/panel'

const MAP_KEY = {
  CHARTING: 'CHARTING',
}

const MAP_SIZE = {
  [`${MAP_KEY.CHARTING}`]: {
    width: `100%`,
    height: '100%',
    top: 0,
    left: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const TechnicalChart = ({ sizes, setSizes }) => {
  const headerSearch = useGetHeaderSearch()

  return (
    <div
      className="w-100 position-relative"
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <Panel
        title={'tool.technicalChart.TITLE_PANEL'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHARTING}
        sizes={sizes}
        setSizes={setSizes}
      >
        <TradingView
          containerId="tools/technicalChart"
          defaultSymbol={headerSearch?.ticker || headerSearch?.groupCode}
        />
      </Panel>
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(TechnicalChart)
