import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { selectViewBy } from '../store/slice'
import { selectColumnByIdCell } from './store/slice'
import style from './style.module.css'

const ThTable = ({ colId, thData }) => {
  const viewBy = useSelector(selectViewBy)
  const column = useSelector(selectColumnByIdCell(viewBy)(colId))

  return (
    <div style={thData?.thStyle || column?.thStyle || {}}>
      <Span className={style.theadContent} style={{ fontSize: 10 }}>
        {thData ? thData.title : column?.title}
      </Span>
      {(thData ? !!thData.unit : !!column?.unit) && (
        <Span className={style.additionText} style={{ fontSize: 10 }}>
          <Translate value="tool.dataExplorer.tradingData.UNIT" />
          {thData ? thData.unit : column?.unit}
        </Span>
      )}
    </div>
  )
}

export default ThTable
