import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { FinancialChart } from '../../../common/chart/financialChart'
import { SizeTracker } from '../../../common/sizeTracker'
import { Footer } from '../../common/footer'
import { genFooterData } from '../../common/footer/helper'
import { getFormatXAxisDashboardView } from '../helpers'
import { CalculateChartWidth } from './CalculateChartWidth'
import { ChartTitle } from './ChartTitle'
import styles from './index.module.css'
import { selectDownloadingChart } from './store/slice'

export const ChartContent = ({
  data,
  item,
  width,
  height,
  schema,
  footerData,
  dimensionTitle,
}) => {
  const footerRef = useRef()

  const downloadingChartID = useSelector(selectDownloadingChart)

  const [chartWidth, setChartWidth] = useState(dimensionTitle.width)

  const renderCloneChartPhoto = () => {
    return (
      (downloadingChartID === -1 || downloadingChartID === item.id) && (
        <div className="position-relative overflow-hidden">
          <div id={`chart${item.id}`} className={styles.hiddenChart}>
            {item?.detail?.nameChart && (
              <ChartTitle
                data={item}
                width={width}
                nameChart={item.detail.nameChart}
                chartId={item.id}
              />
            )}
            <FinancialChart
              width={1162}
              height={400}
              data={data}
              schema={schema}
              isHideTooltip
              typeLegendYAxis={item?.detail?.settings?.yAxisLegend}
              formatXAxis={getFormatXAxisDashboardView(
                Object.values(item?.detail?.indicatorSelected || {})
                  .flat()
                  .map((item) => item.timeFrequency),
              )}
            />
            <Footer
              key={width}
              list={genFooterData(footerData, item.detail?.listLegendCustom)}
            />
          </div>
        </div>
      )
    )
  }

  return (
    <>
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div style={{ height: height - size.height }}>
                <ScrollComponent bgColor="#D9D9D9">
                  <FinancialChart
                    width={chartWidth - 4}
                    height={height - size.height - 4}
                    data={data}
                    schema={schema}
                    isShowYAxisLegend={false}
                    formatXAxis={getFormatXAxisDashboardView(
                      Object.values(item?.detail?.indicatorSelected || {})
                        .flat()
                        .map((item) => item.timeFrequency),
                    )}
                  />
                  {renderCloneChartPhoto()}
                </ScrollComponent>
              </div>
            )}
            <div ref={footerRef} className={styles.footerChart}>
              <Footer
                key={width}
                list={genFooterData(footerData, item.detail?.listLegendCustom)}
              />
            </div>
          </>
        )}
      </SizeTracker>
      <CalculateChartWidth
        data={data}
        item={item}
        width={width}
        height={height}
        schema={schema}
        dimensionTitle={dimensionTitle}
        setChartWidth={setChartWidth}
      />
    </>
  )
}
