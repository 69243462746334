import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { getThreeColorByValue } from '../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { LinkCWDetail } from '../../common/LinkCWDetail'
import { LightBorderColumnCell } from './LightBorderColumnCell'
import {
  GROUP_COLUMNS_DETAIL,
  GROUP_COLUMNS_OVERVIEW,
  TABLE_TYPE,
} from './constants'
import style from './index.module.css'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  const BORDER_DARK = '1px solid #2b303a'
  const BORDER_LIGHT = '1px solid #546373'

  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
      let childrenGroupColumns = [
        GROUP_COLUMNS_OVERVIEW[2],
        GROUP_COLUMNS_OVERVIEW[3],
        GROUP_COLUMNS_OVERVIEW[5],
        GROUP_COLUMNS_OVERVIEW[6],
      ]
      let schemaGroupColumns = GROUP_COLUMNS_OVERVIEW.filter(
        (item) => !item.key.includes('Parent'),
      )

      result.schema = schemaGroupColumns.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count

        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={formatDateTime(val, FORMAT.DATE, locale, timeZone)}
                rowId={rowId}
                isAlignTextLeft
              />
            ),
          }
        }
        if ([2, 4, 5, 6].includes(index)) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={format ? formatVal(val / format, count) : val}
                rowId={rowId}
              />
            ),
          }
        }
        if (![0, 2, 4, 5, 6].includes(index)) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (format ? formatVal(val / format, count) : val),
          }
        }
        if (index === 7) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => formatVal(val, count),
          }
        }
        return result
      })

      result.rowSpanSchema = [
        [
          {
            colId: GROUP_COLUMNS_OVERVIEW[0].key,
            renderTh: () => (
              <div>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[0].title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[1].key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[1].title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
              top: 0,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[4].key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[4].title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[7].key,
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[7].title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[8].key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[8].title} />
                </Span>
              </div>
            ),
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[9].key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[9].title} />
                </Span>
              </div>
            ),
          },
        ],
        [
          {
            title: childrenGroupColumns[0].title,
            colId: childrenGroupColumns[0].key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
            thClassName: style.customTableStickyHead,
          },
          ...childrenGroupColumns.slice(1, 4).map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight: [1, 3].includes(childrenGroupColumns.indexOf(item))
                ? BORDER_LIGHT
                : BORDER_DARK,
            },
          })),
        ],
      ]

      let tableHeadRowTop = {}
      childrenGroupColumns.slice(0, 2).forEach((element) => {
        tableHeadRowTop[element.key] = ['orderMatchingParent']
      })
      childrenGroupColumns.slice(2, 4).forEach((element) => {
        tableHeadRowTop[element.key] = ['dealParent']
      })
      result.tableHeadRowTop = tableHeadRowTop
      break
    case TABLE_TYPE.DETAIL:
    case TABLE_TYPE.TICKER:
      let childrenGroupColumnsDetail = GROUP_COLUMNS_DETAIL.filter((item) =>
        [
          'totalMatchVolume',
          'totalMatchValue',
          'totalDealVolume',
          'totalDealValue',
        ].includes(item.key),
      )
      let schemaGroupColumnsDetail = GROUP_COLUMNS_DETAIL.filter(
        (item) => !item.key.includes('Parent'),
      )
      let schemaDetail = schemaGroupColumnsDetail.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId, { style, className }) => (
              <LightBorderColumnCell
                val={formatDateTime(val, FORMAT.DATE, locale, timeZone)}
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={className}
              />
            ),
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val, rowId, { style }) => {
              // const activeId = dataTable[rowId]?.activeStatusId || 0
              const organizationId = dataTable[rowId]?.FutureId || 0
              return <LinkCWDetail organizationId={organizationId} val={val} />
            },
          }
        }
        if (
          [
            'percentPriceChange',
            'openPrice',
            'highPrice',
            'lowPrice',
            'closePrice',
            'totalVolume',
            'totalValue',
            'totalMatchValue',
            'totalDealValue',
            'openInterest',
            'percentOpenInterestChange',
          ].includes(item.key)
        ) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={
                  ['percentPriceChange', 'percentOpenInterestChange'].includes(
                    item.key,
                  )
                    ? valToPercent(val)
                    : item.key === 'openInterest'
                    ? formatVal(val, 0)
                    : formatVal(val / format, count)
                }
                rowId={rowId}
                styleProps={
                  ['percentPriceChange'].includes(item.key) &&
                  val !== EMPTY_VALUE
                    ? {
                        color: getThreeColorByValue(val),
                      }
                    : {}
                }
              />
            ),
          }
        }
        if (['totalMatchVolume', 'totalDealVolume'].includes(item.key)) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return formatVal(val, 0)
            },
          }
        }
        return result
      })
      result.schema = schemaDetail

      let rowSpanSchemaDetail = [
        [
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            [
              'tradingDateId',
              'ticker',
              'percentPriceChange',
              'openPrice',
              'highPrice',
              'lowPrice',
              'closePrice',
            ].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div
                style={{
                  textAlign: ['tradingDateId', 'ticker'].includes(item.key)
                    ? 'left'
                    : 'right',
                }}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
          })),
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            ['totalMatchParent', 'totalDealParent'].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                <Span style={{ fontSize: 10 }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
              top: 0,
            },
          })),
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            [
              'totalVolume',
              'totalValue',
              'openInterest',
              'percentOpenInterestChange',
            ].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
          })),
        ],
        [
          {
            title: childrenGroupColumnsDetail[0].title,
            colId: childrenGroupColumnsDetail[0].key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
            thClassName: style.customTableStickyHead,
          },
          ...childrenGroupColumnsDetail.slice(1, 4).map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight: [1, 3].includes(
                childrenGroupColumnsDetail.indexOf(item),
              )
                ? BORDER_LIGHT
                : BORDER_DARK,
            },
          })),
        ],
      ]
      result.rowSpanSchema = rowSpanSchemaDetail

      let tableHeadRowTopDetail = {}
      childrenGroupColumnsDetail.slice(0, 2).forEach((element) => {
        tableHeadRowTopDetail[element.key] = ['totalMatchParent']
      })
      childrenGroupColumnsDetail.slice(2, 4).forEach((element) => {
        tableHeadRowTopDetail[element.key] = ['totalDealParent']
      })
      result.tableHeadRowTop = tableHeadRowTopDetail
      break

    default:
  }

  return result
}
