import { uuid } from '../../../../../utils/Common'
import { formatVal, valDivBillion } from '../../../../../utils/Value'

import {
  ValueFurtherInformation,
  ValueRelations,
  ValueTypeShareHolders,
} from '../../filter/FilterOption/constants'
const regexOrganizationOwnerIndividualOwner =
  /OrganizationOwner|IndividualOwner/

const regexOrganizationAndOwner = /OrganizationOwner|Owned/

const regexOwnedAffliate = /Owned|Affliate/

const regexOrganizationOwner =
  /OrganizationOwner|IndividualOwner|ChairmanAndDirector/

const regexConcurrent = /Concurrent/

const regexGuarantee = /Guarantee/
const regexDataTable = /MainSubject|ChairmanAndDirector/

const checkColorOnlyrelationType = (source, target) => {
  if (regexOrganizationOwner.test(target.relationType)) {
    return '#0096EB'
  }
  if (regexConcurrent.test(target.relationType)) {
    return '#9747FF'
  }

  if (regexGuarantee.test(target.relationType)) {
    return '#00E5E4'
  }

  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(0))
  ) {
    return '#FACC5C'
  }
  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(1))
  ) {
    return '#A6CF98'
  }
  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(2))
  ) {
    return '#DF5A49'
  }
  return '#0096EB'
}

const checkColorMultiRelationType = (source, target) => {
  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(0))
  ) {
    return '#FACC5C'
  }
  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(1))
  ) {
    return '#A6CF98'
  }
  if (
    regexOwnedAffliate.test(target.relationType) &&
    source.level &&
    source.level.split('|').includes(String(2))
  ) {
    return '#DF5A49'
  }

  if (regexOrganizationOwner.test(target.relationType)) {
    return '#0096EB'
  }

  if (regexConcurrent.test(target.relationType)) {
    return '#9747FF'
  }

  if (regexGuarantee.test(target.relationType)) {
    return '#00E5E4'
  }

  return '#0096EB'
}

const checkColor2MultiRelationType = (
  textRelations,
  relation,
  shareHolders,
) => {
  let text = textRelations.split('|')
  if (!relation.includes(ValueRelations.PaymentGuarantee)) {
    text = text.filter((e) => e !== ValueRelations.PaymentGuarantee)
  }
  if (!relation.includes(ValueRelations.SubsidiaryAffiliate)) {
    text = text.filter((e) => e !== ValueRelations.SubsidiaryAffiliate)
  }
  if (!shareHolders.includes(ValueTypeShareHolders.OrganizationOwner)) {
    text = text.filter(
      (e) => e !== 'Owned' && e !== ValueTypeShareHolders.OrganizationOwner,
    )
  }
  if (!shareHolders.includes(ValueTypeShareHolders.IndividualOwner)) {
    text = text.filter((e) => e !== ValueTypeShareHolders.IndividualOwner)
  }
  return text.join('|')
}

const checkPadingLinkNode = (source, target) => {
  if (target.relationType === 'MainSubject') {
    return 50
  }
  if (target.level && target.level.split('|')?.[0] === String(1)) {
    return 35
  }
  if (target.level && target.level.split('|')?.[0] === String(2)) {
    return 25
  }
  if (target.level && target.level.split('|')?.[0] === String(3)) {
    return 15
  }
  return 50
}
export const renderLine =
  (I18n, furtherInformations, data, relation, shareHolders) => (node, ctx) => {
    const { source, target } = node
    let color = '#0096EB'
    let padding = checkPadingLinkNode(source, target)
    const sourceNode = data.nodes.find((e) => e.id === source.id)
    const targetNode = sourceNode?.relations
      ? sourceNode.relations.find((e) => e.id === target.id)
      : null
    const targetNodeRelations = sourceNode?.relations
      ? sourceNode.relations
          .filter((e) => e.id === target.id)
          .map((e) => e.relationType)
          .join('|')
      : null

    if (source.relationType === 'ChairmanAndDirector') {
      const index = source.data.findIndex((e) => e.id === target.parentId)
      return drawArrowParent(
        source,
        {
          ...targetNode,
          // relationType: realationtypeTextCheck,
          percentage: target?.paramsPercentage?.[source.id],
          totalValue: target.totalValue,
          relationTypeMulti: target.relationTypeMulti,
          parentRelationType: target?.parentRelationType,
          paramsRelationType: target?.paramsRelationType,
          x: target.x,
          y: target.y,
        },
        ctx,
        padding,
        checkColorOnlyrelationType(source, {
          ...target,
          relationType:
            target?.paramsRelationType?.[source.data?.[index]?.id || ''] || '',
        }),
        index,
        furtherInformations,
        I18n,
      )
    }

    if (sourceNode && targetNode) {
      const realationtypeTextCheck = checkColor2MultiRelationType(
        targetNodeRelations,
        relation,
        shareHolders,
      )

      if (realationtypeTextCheck.split('|').length > 1) {
        color = checkColorMultiRelationType(source, {
          ...targetNode,
          relationType: realationtypeTextCheck,
        })
      } else {
        color = checkColorOnlyrelationType(source, {
          ...targetNode,
          relationType: realationtypeTextCheck,
        })
      }

      if (node.reversed === true) {
        if (
          regexOrganizationOwnerIndividualOwner.test(
            targetNode?.relationType || '',
          )
        ) {
          return drawArrowReverseParent(
            source,
            {
              ...targetNode,
              relationType: realationtypeTextCheck,
              percentage: target?.paramsPercentage?.[source.id],
              totalValue: null,
              relationTypeMulti: target.relationTypeMulti,
              parentRelationType: target?.parentRelationType,
              paramsRelationType: target?.paramsRelationType,
              x: target.x,
              y: target.y,
            },
            ctx,
            padding,
            color,
            furtherInformations,
            I18n,
          )
        }
      }
      if (
        ['ChairmanAndDirector'].includes(target.relationType) &&
        target.data
      ) {
        return drawArrowReverseParent(
          source,
          {
            ...targetNode,
            relationType: realationtypeTextCheck,
            percentage: target?.paramsPercentage?.[source.id],
            totalValue: target.totalValue,
            relationTypeMulti: target.relationTypeMulti,
            parentRelationType: target?.parentRelationType,
            paramsRelationType: target?.paramsRelationType,
            x: target.x,
            y: target.y,
          },
          ctx,
          padding,
          color,
          furtherInformations,
          I18n,
        )
      }

      return drawArrow(
        source,
        {
          ...targetNode,
          relationType: realationtypeTextCheck,
          percentage: target?.paramsPercentage?.[source.id],
          totalValue: target.totalValue,
          relationTypeMulti: target.relationTypeMulti,
          parentRelationType: target?.parentRelationType,
          paramsRelationType: target?.paramsRelationType,
          x: target.x,
          y: target.y,
        },
        ctx,
        padding,
        color,
        furtherInformations,
        I18n,
        relation,
      )
    }
  }

const drawArrow = (
  source,
  target,
  ctx,
  padding,
  color,
  furtherInformations,
  I18n,
  relation,
) => {
  const targetX = target.x
  const targetY = target.y
  const sourceX = source.x
  const sourceY = source.y

  const dx = targetX - sourceX
  const dy = targetY - sourceY
  const length = Math.sqrt(dx * dx + dy * dy)
  const xOffset = (dx / length) * padding
  const yOffset = (dy / length) * padding

  const offset = 10
  const x = targetX - xOffset
  const y = targetY - yOffset
  ctx.beginPath()
  ctx.moveTo(source.x, source.y)
  ctx.lineTo(x, y)
  ctx.strokeStyle = color
  ctx.stroke()

  const arrayText = [
    furtherInformations.includes(ValueFurtherInformation.OwnershipRate)
      ? target?.percentage &&
        regexOwnedAffliate.test(target?.paramsRelationType?.[source.id] || '')
        ? `${formatVal(target.percentage * 100)}%`
        : null
      : null,
    target.relationType === 'concurrent'
      ? target?.posision
      : regexGuarantee.test(target?.paramsRelationType?.[source.id] || '') &&
        relation.includes(ValueRelations.PaymentGuarantee)
      ? I18n.t('bond.interconnectionMap.chart.statusGuarantee')
      : null,
    furtherInformations.includes(ValueFurtherInformation.PaymentGuarantee) &&
    target.totalValue &&
    target?.relationTypeMulti &&
    (target?.paramsRelationType?.[source.id] || '').includes(
      ValueRelations.PaymentGuarantee,
    )
      ? formatVal(valDivBillion(target.totalValue)) +
        ' ' +
        I18n.t('bond.interconnectionMap.common.unit2')
      : null,
  ].filter((e) => e)
  let textLine = arrayText.join(', ')

  if (arrayText.length > 0) {
    const angle = Math.atan2(dy, dx)
    const textWidth = ctx.measureText(textLine).width
    const rectWidth = textWidth + 43
    const rectHeight = 12 + 4
    ctx.save()
    ctx.translate((sourceX + targetX) / 2, (sourceY + targetY) / 2)

    if (angle <= -(Math.PI / 2) || angle >= Math.PI / 2) {
      ctx.rotate(Math.PI + angle)
    } else {
      ctx.rotate(angle)
    }

    ctx.fillStyle = '#2E4162'
    ctx.strokeStyle = 'transparent'
    ctx.lineWidth = 1
    ctx.borderRadius = 4
    ctx.fillRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight)

    ctx.fontWeight = '700'
    ctx.fillStyle = '#FFFFFF'
    ctx.font = '12px Poppins'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'

    ctx.fillText(textLine, 0, 0)
    ctx.restore()
  }

  ctx.save()
  ctx.translate(x, y)

  ctx.rotate(Math.atan2(target.y - source.y, target.x - source.x))
  ctx.beginPath()
  ctx.moveTo(-offset, -offset / 2)
  ctx.lineTo(0, 0)
  ctx.lineTo(-offset, offset / 2)
  ctx.fillStyle = color
  ctx.fill()
  ctx.restore()
}

const drawArrowParent = (
  source,
  target,
  ctx,
  padding,
  color,
  index,
  furtherInformations,
  I18n,
) => {
  const targetX = target.x
  const targetY = target.y

  const sourceX = source.x
  const sourceY = source.y + index * 60

  const dx = targetX - sourceX
  const dy = targetY - sourceY
  const length = Math.sqrt(dx * dx + dy * dy)
  const xOffset = (dx / length) * padding
  const yOffset = (dy / length) * padding

  const offset = 10
  const x = targetX - xOffset
  const y = targetY - yOffset
  ctx.beginPath()
  ctx.moveTo(source.x, source.y + index * 60)
  ctx.lineTo(x, y)
  ctx.strokeStyle = color
  ctx.stroke()
  const arrayText = [
    source?.data?.[index]?.posision,
    furtherInformations.includes(ValueFurtherInformation.OwnershipRate)
      ? target?.percentage
        ? `${formatVal(target.percentage * 100)}%`
        : null
      : null,
    target.relationType === 'concurrent'
      ? target?.posision
      : target.relationType === ValueRelations.PaymentGuarantee
      ? I18n.t('bond.interconnectionMap.chart.statusGuarantee')
      : null,
    furtherInformations.includes(ValueFurtherInformation.PaymentGuarantee) &&
    target.totalValue
      ? formatVal(valDivBillion(target.totalValue)) +
        ' ' +
        I18n.t('bond.interconnectionMap.common.unit2')
      : null,
  ].filter((e) => e)
  const textLine = arrayText.join(', ')

  if (arrayText.length > 0) {
    const angle = Math.atan2(targetY - sourceY, targetX - sourceX)

    const textWidth = ctx.measureText(textLine).width
    const rectWidth = textWidth + 43
    const rectHeight = 12 + 4

    ctx.save()
    ctx.translate((sourceX + targetX) / 2, (sourceY + targetY) / 2)

    if (angle <= -(Math.PI / 2) || angle >= Math.PI / 2) {
      ctx.rotate(Math.PI + angle)
    } else {
      ctx.rotate(angle)
    }
    ctx.fillStyle = '#2E4162'
    ctx.strokeStyle = 'transparent'
    ctx.lineWidth = 1
    ctx.borderRadius = 4

    ctx.fillRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight)

    ctx.fontWeight = '700'
    ctx.fillStyle = '#FFFFFF'
    ctx.font = '12px Poppins'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText(textLine, 0, 0)
    ctx.restore()
  }

  ctx.save()
  ctx.translate(x, y)
  ctx.rotate(
    Math.atan2(target.y - (source.y + index * 30), target.x - source.x),
  )
  ctx.beginPath()
  ctx.moveTo(-offset, -offset / 2)
  ctx.lineTo(0, 0)
  ctx.lineTo(-offset, offset / 2)
  ctx.fillStyle = color
  ctx.fill()
  ctx.restore()
}
const drawArrowReverseParent = (
  source,
  target,
  ctx,
  padding,
  color,
  furtherInformations,
  I18n,
) => {
  const targetX = target.x
  const targetY = target.y

  const sourceX = source.x
  const sourceY = source.y

  const dx = sourceX - targetX
  const dy = sourceY - targetY

  const length = Math.sqrt(dx * dx + dy * dy)
  const xOffset = (dx / length) * 60
  const yOffset = (dy / length) * 60

  const offset = 10
  const x = targetX
  const y = targetY
  ctx.beginPath()
  ctx.moveTo(sourceX - xOffset, sourceY - yOffset)
  ctx.lineTo(x, y)
  ctx.strokeStyle = color
  ctx.stroke()

  const arrayText = [
    furtherInformations.includes(ValueFurtherInformation.OwnershipRate)
      ? target?.percentage
        ? `${formatVal(target.percentage * 100)}%`
        : null
      : null,
    target.relationType === 'concurrent'
      ? target?.posision
      : target.relationType === ValueRelations.PaymentGuarantee
      ? I18n.t('bond.interconnectionMap.chart.statusGuarantee')
      : null,
    furtherInformations.includes(ValueFurtherInformation.PaymentGuarantee) &&
    target.totalValue
      ? formatVal(valDivBillion(target.totalValue)) +
        ' ' +
        I18n.t('bond.interconnectionMap.common.unit2')
      : null,
  ].filter((e) => e)
  const textLine = arrayText.join(', ')
  if (arrayText.length > 0) {
    const angle = Math.atan2(targetY - sourceY, targetX - sourceX)
    const textWidth = ctx.measureText(textLine).width
    const rectWidth = textWidth + 30
    const rectHeight = 12 + 4

    ctx.save()
    ctx.translate(
      (sourceX - xOffset + targetX) / 2,
      (sourceY - yOffset + targetY) / 2,
    )
    if (angle <= -(Math.PI / 2) || angle >= Math.PI / 2) {
      ctx.rotate(Math.PI + angle)
    } else {
      ctx.rotate(angle)
    }
    ctx.fillStyle = '#2E4162'
    ctx.strokeStyle = 'transparent'
    ctx.lineWidth = 1
    ctx.borderRadius = 4

    ctx.fillRect(-rectWidth / 2, -rectHeight / 2, rectWidth, rectHeight)

    ctx.fontWeight = '700'
    ctx.fillStyle = '#FFFFFF'
    ctx.font = '12px Poppins'
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText(textLine, 0, 0)
    ctx.restore()
  }

  ctx.save()
  ctx.translate(sourceX - xOffset, sourceY - yOffset)
  ctx.rotate(Math.atan2(dy, dx))
  ctx.beginPath()
  ctx.moveTo(-offset, -offset / 2)
  ctx.lineTo(0, 0)
  ctx.lineTo(-offset, offset / 2)
  ctx.fillStyle = color
  ctx.fill()
  ctx.restore()
}

const drawComponent = (ctx, name, position, x, y, i, index) => {
  ctx.fillStyle = '#31404F'
  ctx.fillRect(x - 130, y + i, 260, 50)

  ctx.font = '12px Poppins'
  ctx.fillStyle = '#ECECEC'
  ctx.fontWeight = 600
  ctx.textAlign = 'left'

  let textY = y + 15 + index * 60
  const text = name
  const maxWidth = 130
  if (ctx.measureText(text).width > maxWidth) {
    const words = text.split(' ')
    let line = ''

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const testWidth = ctx.measureText(testLine).width

      if (testWidth > maxWidth) {
        ctx.fillText(line, x - 120, textY)
        line = words[n] + ' '
        textY += 15
      } else {
        line = testLine
      }
    }

    ctx.fillText(line, x - 120, textY)
  } else {
    ctx.fillText(text, x - 120, textY)
  }

  ctx.fontWeight = 340
  ctx.textAlign = 'right'
  let posisionTextY = y + 15 + index * 60
  const posisionText = position
  if (ctx.measureText(posisionText).width > maxWidth) {
    const words = posisionText.split(' ')
    let line = ''

    for (let n = 0; n < words.length; n++) {
      const testLine = line + words[n] + ' '
      const testWidth = ctx.measureText(testLine).width

      if (testWidth > maxWidth) {
        ctx.fillText(line, x + 120, posisionTextY)
        line = words[n] + ' '
        posisionTextY += 15
      } else {
        line = testLine
      }
    }

    ctx.fillText(line, x + 120, posisionTextY)
  } else {
    ctx.fillText(posisionText, x + 120, posisionTextY)
  }
}

export const renderNode =
  (img, I18n, hover, furtherInformations, imgNode) => (node, ctx) => {
    const label = node.name

    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillStyle = '#ECECEC'
    if (node?.type === 'popup') {
      return renderPopup(node, ctx, I18n, hover)
    }
    if (node?.relationType === 'ChairmanAndDirector' && node.data) {
      const data = node?.data || []
      data.forEach((e, index) => {
        drawComponent(
          ctx,
          e.name,
          e.posision,
          node.x - 16,
          node.y - 14,
          index * 60,
          index,
        )
      })
    }
    if (node?.relationType === 'MainSubject') {
      const outstandingBond =
        formatVal(valDivBillion(node.outstandingBond)) +
        ' ' +
        I18n.t('bond.interconnectionMap.common.unit2')
      ctx.font = '24px Poppins'
      ctx.fontWeight = '600' // Độ đậm 600
      ctx.fillText(label, node.x, node.y - 50 - 24)
      if (
        furtherInformations.includes(ValueFurtherInformation.OutstandingBond)
      ) {
        ctx.fillStyle = '#FACC5C'
        ctx.font = '12px Poppins'
        ctx.fontWeight = '500' // Độ đậm 600
        ctx.fillText(outstandingBond, node.x, node.y - 48 - 12)
      }
    } else if (node?.relationType !== 'ChairmanAndDirector') {
      const outstandingBond =
        formatVal(valDivBillion(node.outstandingBond)) +
        ' ' +
        I18n.t('bond.interconnectionMap.common.unit2')

      ctx.font = '18px Poppins'
      ctx.fontWeight = '500' // Độ đậm 500
      ctx.fillText(label, node.x, node.y - 30 - 23)

      if (
        furtherInformations.includes(ValueFurtherInformation.OutstandingBond)
      ) {
        ctx.fillStyle = '#FACC5C'
        ctx.font = '12px Poppins'
        ctx.fontWeight = '500' // Độ đậm 600
        ctx.fillText(outstandingBond, node.x, node.y - 23 - 12)
      }
    }

    if (node.relationType === 'MainSubject') {
      ctx.save()
      ctx.beginPath()
      ctx.arc(node.x, node.y, 50, 0, Math.PI * 2)
      ctx.fillStyle = '#32475C'
      ctx.fill()
      // ctx.closePath()
      ctx.beginPath()
      ctx.arc(node.x, node.y, 45, 0, Math.PI * 2)
      ctx.fillStyle = 'white'
      ctx.fill()
      // ctx.closePath()
      if (img) {
        const imgWidth = 90
        const imgHeight =
          img?.height && img?.width
            ? (img?.height * imgWidth) / img?.width
            : imgWidth
        ctx.clip()
        ctx.drawImage(
          img,
          node.x - imgWidth / 2,
          node.y - imgHeight / 2,
          imgWidth,
          imgHeight,
        )
      }
      ctx.restore()
    }
    if (
      node?.relations &&
      node?.relations.length > 0 &&
      node.parentRelationType &&
      node.parentRelationType.includes('MainSubject')
    ) {
      ctx.save()
      ctx.beginPath()
      ctx.arc(node.x, node.y, 30, 0, Math.PI * 2)
      ctx.fillStyle = '#A6CF98'
      ctx.fill()

      if (imgNode) {
        const imgWidth = 30
        const imgHeight =
          imgNode?.height && imgNode?.width
            ? (imgNode?.height * imgWidth) / imgNode?.width
            : imgWidth
        ctx.clip()
        ctx.drawImage(
          imgNode,
          node.x - imgWidth / 2,
          node.y - imgHeight / 2,
          imgWidth,
          imgHeight,
        )
      }
      ctx.restore()
    }

    if (
      node?.relationTypeMulti &&
      node?.relationTypeMulti.includes('OrganizationOwner') &&
      node.parentRelationType &&
      node.parentRelationType.includes('MainSubject')
    ) {
      ctx.save()
      ctx.beginPath()
      ctx.arc(node.x, node.y, 30, 0, Math.PI * 2)
      ctx.fillStyle = '#FACC5C'
      ctx.fill()

      if (imgNode) {
        const imgWidth = 30
        const imgHeight =
          imgNode?.height && imgNode?.width
            ? (imgNode?.height * imgWidth) / imgNode?.width
            : imgWidth
        ctx.clip()
        ctx.drawImage(
          imgNode,
          node.x - imgWidth / 2,
          node.y - imgHeight / 2,
          imgWidth,
          imgHeight,
        )
      }
      ctx.restore()
    }
  }

const filterRelationType = (
  relationType,
  shareHolders,
  relation,
  levelFilterCheck,
  checkLevel,
) => {
  if (
    regexOrganizationAndOwner.test(relationType) &&
    !shareHolders.includes(ValueTypeShareHolders.OrganizationOwner)
  ) {
    return false
  }

  if (
    relationType.includes(ValueTypeShareHolders.IndividualOwner) &&
    !shareHolders.includes(ValueTypeShareHolders.IndividualOwner)
  ) {
    return false
  }

  if (
    relationType.includes(ValueRelations.PaymentGuarantee) &&
    !relation.includes(ValueRelations.PaymentGuarantee)
  ) {
    return false
  }

  if (
    relationType.includes(ValueRelations.SubsidiaryAffiliate) &&
    !relation.includes(ValueRelations.SubsidiaryAffiliate)
  ) {
    return false
  }
  if (checkLevel > levelFilterCheck) {
    return false
  }
  return true
}

const filterCheckCompanyHide = (node, companyHide) => {
  if (
    !regexDataTable.test(node.relationType) &&
    companyHide.includes(node.id)
  ) {
    return false
  }
  return true
}

export const transformData = (
  data,
  organizationId,
  width,
  height,
  shareHolders,
  relation,
  levelFilterCheck,
  idsCompany,
) => {
  const nodesMap = {}
  let links = []
  let chairmanAndDirectorItem = null

  function recursiveTransform(
    node,
    parent,
    level,
    nodesMap,
    shareHolders,
    relation,
    levelFilterCheck,
    idsCompany,
  ) {
    const nodeId = node?.id
    const relationType = node?.relationType || ''
    const checkLevel =
      node?.relationType === 'Concurrent'
        ? node?.relationType === 'ChairmanAndDirector'
          ? 0
          : null
        : level
    const checkShareHolder = filterRelationType(
      relationType,
      shareHolders,
      relation,
      levelFilterCheck,
      checkLevel,
    )

    const compannyHide = filterCheckCompanyHide(node, idsCompany)
    if (compannyHide) {
      if (checkShareHolder) {
        if (!nodesMap[nodeId]) {
          nodesMap[nodeId] = {
            ...node,
            id: nodeId,
            name: node?.name,
            avatar: node?.avatar,
            relationType: node?.relationType,
            outstandingBond: node?.outstandingBond,
            percentage: node?.percentage,
            level: String(checkLevel),
            posision: node?.posision,
            posisionId: node?.posisionId,
            isNotHidden:
              chairmanAndDirectorItem?.data.length >= 1 &&
              node.relationType === 'ChairmanAndDirector'
                ? false
                : true,
            parentId: parent?.id,
            parentRelationType: parent?.relationType,
            relationTypeMulti: node?.relationType,
            numberRelationType: 1,
            relations: node?.relations || [],
            totalValue: node?.totalValue,
            paramsRelationType: {
              [parent?.id || '0']: node?.relationType,
            },
            paramsPercentage: {
              [parent?.id || '0']: node?.percentage,
            },
          }
          if (nodeId === organizationId) {
            nodesMap[nodeId]['x'] = width / 2
            nodesMap[nodeId]['y'] = height / 2
          }
        } else {
          const existingNode = nodesMap[nodeId]

          if (node?.relations && node?.relations.length > 0) {
            existingNode.relations = node?.relations
          }
          if (node?.percentage) {
            existingNode.percentage = node?.percentage
          }

          existingNode.paramsRelationType = {
            ...existingNode.paramsRelationType,
            [parent?.id || '']: node?.relationType,
          }
          if (node?.percentage) {
            existingNode.paramsPercentage = {
              ...existingNode.paramsPercentage,
              [parent?.id || '']: node?.percentage,
            }
          }

          if (node?.totalValue) {
            existingNode.totalValue = node?.totalValue
          }
          if (
            existingNode.level &&
            !existingNode.level.includes(String(checkLevel))
          ) {
            existingNode.level += `|${String(checkLevel) || ''}`
          }
          if (
            existingNode?.parentRelationType &&
            !existingNode.parentRelationType.includes(node?.relationType)
          ) {
            existingNode.parentRelationType += `|${parent?.relationType || ''}`
          }
          if (
            existingNode?.relationTypeMulti &&
            !existingNode.relationTypeMulti.includes(node?.relationType)
          ) {
            existingNode.relationTypeMulti += `|${node?.relationType || ''}`
            existingNode.numberRelationType += 1
          }
          if (existingNode?.relationType !== node?.relationType) {
            existingNode.relationType2 = node?.relationType
          }
        }
        if (parent) {
          if (
            regexOrganizationOwnerIndividualOwner.test(node?.relationType || '')
          ) {
            links.push({
              id: uuid(),
              source: parent.id,
              target: nodeId,
              reversed: true,
            })
          } else {
            links.push({
              id: uuid(),
              source: parent.id,
              target: nodeId,
            })
          }
        }

        if (
          parent &&
          parent.relationType === 'ChairmanAndDirector' &&
          chairmanAndDirectorItem?.data.length >= 1 &&
          chairmanAndDirectorItem?.data?.[0].id !== node.id
        ) {
          links.push({
            source: chairmanAndDirectorItem?.data?.[0].id,
            target: node.id,
          })
        }

        if (node.relations && node.relations.length > 0) {
          node.relations.forEach((childNode) => {
            recursiveTransform(
              childNode,
              node,
              checkLevel + 1,
              nodesMap,
              shareHolders,
              relation,
              levelFilterCheck,
              idsCompany,
            )
          })
        }
        if (node.relationType === 'ChairmanAndDirector') {
          if (!chairmanAndDirectorItem) {
            chairmanAndDirectorItem = {
              id: node.id,
              data: [node],
              relationType: node?.relationType,
              percentage: node.percentage,
              totalValue: node.totalValue,
              posision: node?.posision,
            }
          } else {
            chairmanAndDirectorItem.data.push(node)
          }
        }
      }
    }
  }

  recursiveTransform(
    data,
    null,
    0,
    nodesMap,
    shareHolders,
    relation,
    levelFilterCheck,
    idsCompany,
  )
  if (chairmanAndDirectorItem) {
    nodesMap[chairmanAndDirectorItem.id] = chairmanAndDirectorItem
  }

  const nodes = Object.values(nodesMap)
  return {
    nodes,
    links,
  }
}

const renderPopup = (node, ctx, I18n, hover) => {
  const textView = I18n.t('bond.interconnectionMap.chart.btnView')
  const textChangeBond = I18n.t('bond.interconnectionMap.chart.btnchangeBond')
  if (node.typeAction === 'view') {
    node.x = node.parentX
    node.y = node.parentY

    ctx.fillStyle = hover === node.typeAction ? '#5D6773' : '#31404F'
    ctx.fillRect(node.x - 80, node.y - 16, 180, 32)

    ctx.font = '12px Poppins'
    ctx.fillStyle = '#ECECEC'
    ctx.fontWeight = 400
    ctx.textAlign = 'left'
    ctx.fillText(textView, node.parentX - 70, node.parentY)
  }

  if (node.typeAction === 'change') {
    node.x = node.parentX
    node.y = node.parentY + 32
    ctx.fillStyle = hover === node.typeAction ? '#5D6773' : '#31404F'
    ctx.fillRect(node.parentX - 80, node.parentY + 16, 180, 32)

    ctx.font = '12px Poppins'
    ctx.fillStyle = '#ECECEC'
    ctx.fontWeight = 400
    ctx.textAlign = 'left'
    ctx.fillText(textChangeBond, node.parentX - 70, node.parentY + 32)
  }
}

export const checkNodeShareHolder =
  (level, shareHolders, relation, data) => (node) => {
    if (node?.level) {
      const arrayLevel = node.level.split('|')
      const indexCheckHide = arrayLevel.findIndex(
        (item) => parseInt(item) <= level || item === 'null',
      )
      if (indexCheckHide === -1) {
        return false
      }
    }
    // const sourceNode = data.nodes.find((e) => e.id === source.id)
    // const targetNodeRelationType = sourceNode?.relations
    //   ? sourceNode.relations
    //       .filter((e) => e.id === target.id)
    //       .map((e) => e.relationType)
    //       .join('|')
    //   : null

    // if (node?.relationType && node?.relationType !== 'ChairmanAndDirector') {
    //   const listRelationTypeMulti = checHideNodeByRelationType(
    //     node?.relationTypeMulti || '',
    //     shareHolders,
    //     relation,
    //   )

    //   if (!listRelationTypeMulti) {
    //     return false
    //   }
    // }
    // if (
    //   node?.parentRelationType &&
    //   node?.relationType !== 'ChairmanAndDirector'
    // ) {
    //   const listParentRelationTypeMulti = checHideNodeByRelationType(
    //     node?.parentRelationType,
    //     shareHolders,
    //     relation,
    //   )
    //   if (!listParentRelationTypeMulti) {
    //     return false
    //   }
    // }

    return node?.isNotHidden ?? true
  }

export const checkLinkShareHolder =
  (level, shareHolders, relation, data) => (node, link) => {
    // const { source, target } = node
    if (node.source?.level) {
      const arrayLevel = node.source?.level.split('|')
      const indexCheckHide = arrayLevel.findIndex(
        (item) => parseInt(item) + 1 <= level,
      )
      if (indexCheckHide === -1) {
        return false
      }
    }
    // const sourceNode = data.nodes.find((e) => e.id === source.id)

    // const targetNodeRelationType = sourceNode?.relations
    //   ? sourceNode.relations
    //       .filter((e) => e.id === target.id)
    //       .map((e) => e.relationType)
    //       .join('|')
    //   : null

    // if (sourceNode && targetNodeRelationType) {
    // if (
    //   targetNodeRelationType &&
    //   !targetNodeRelationType.includes('ChairmanAndDirector')
    // ) {
    //   const listRelationTypeMulti = checHideLinkByRelationType(
    //     targetNodeRelationType,
    //     shareHolders,
    //     relation,
    //   )

    //   if (!listRelationTypeMulti) {
    //     return false
    //   }

    //   const listparentRelationTypeMulti = checHideLinkByRelationType(
    //     target?.parentRelationType || '',
    //     shareHolders,
    //     relation,
    //   )
    //   if (!listparentRelationTypeMulti) {
    //     return false
    //   }
    // }

    if (
      node.source.isNotHidden === false ||
      node.target.isNotHidden === false
    ) {
      return false
    }
    // }

    return true
  }

export const filterDataPositionCharmain = (data) => {
  const filteredData = { ...data }

  if (filteredData?.posisionId === 3) {
    return null
  }

  if (filteredData?.relations && filteredData?.relations.length > 0) {
    filteredData.relations = filteredData.relations
      .map((relation) => filterDataPositionCharmain(relation))
      .filter((relation) => relation !== null)
  }

  return filteredData
}

export const filterDataPositionCeo = (data) => {
  const filteredData = { ...data }

  if (filteredData?.posisionId === 7) {
    return null
  }

  if (filteredData?.relations && filteredData?.relations.length > 0) {
    filteredData.relations = filteredData.relations
      .map((relation) => filterDataPositionCeo(relation))
      .filter((relation) => relation !== null)
  }

  return filteredData
}

export const filterDataPositionCeoAndChairman = (data) => {
  const filteredData = { ...data }

  if (filteredData?.posisionId === 7 || filteredData?.posisionId === 3) {
    return null
  }

  if (filteredData?.relations && filteredData?.relations.length > 0) {
    filteredData.relations = filteredData.relations
      .map((relation) => filterDataPositionCeoAndChairman(relation))
      .filter((relation) => relation !== null)
  }

  return filteredData
}
const paddingNode = 210

const totalPages = (array, limit) => {
  let pages = 1
  let currentLimit = limit

  while (array.length > currentLimit) {
    currentLimit *= 2
    pages++
  }

  return pages
}

const calculatePageForId = (
  data,
  id,
  checkRelations,
  limitNode,
  pageMax,
  ChairmanAndDirector,
) => {
  let limitPerPage = limitNode ?? 15
  let currentPage = 1
  let currentIndex = 0
  const index = data.findIndex((item) => {
    return item?.id === id
  })

  if (index === -1) {
    return -1
  }
  while (currentIndex <= index) {
    if (currentIndex + limitPerPage > index) {
      if (checkRelations) {
        if (pageMax !== currentPage) {
          return pageMax
        } else {
          return currentPage
        }
      }
      if (ChairmanAndDirector) {
        if (pageMax !== currentPage) {
          return pageMax + 1
        } else {
          return currentPage
        }
      }
      return currentPage
    }
    currentPage++
    currentIndex += limitPerPage
    limitPerPage *= 2
  }
}
export const checkLinkWith = (e, data, limitNode, idsCompany) => {
  const dataNode = data?.nodes || []
  const listDataNode = dataNode.find((node) => node.id === e.source.id)

  const lisNode = listDataNode?.relations
    ? listDataNode?.relations
        .filter((node) => !idsCompany.includes(node.id))
        .sort((node) => {
          if (node?.relations && node?.relations.length > 0) {
            return 1
          }
          return -1
        })
    : []

  const pageMax = totalPages(lisNode ?? [], limitNode)
  const checkRelations =
    e?.target.relations && e?.target.relations.length > 0 ? true : false
  const ChairmanAndDirector = e?.target?.relationType.includes(
    'ChairmanAndDirector',
  )
    ? true
    : false
  const currentPage = calculatePageForId(
    lisNode ?? [],
    e?.target?.id,
    checkRelations,
    limitNode,
    pageMax,
    ChairmanAndDirector,
  )

  if (currentPage < 0) {
    const findPosision = e?.source?.data
      ? e?.source?.data?.find((node) => {
          const checkElement = node?.relations.some(
            (i) => i?.id === e?.target?.id,
          )
          if (checkElement) return node
        })
      : null

    if (findPosision?.posision) {
      return (pageMax + 1.5) * paddingNode
    }
    return (pageMax + 1) * paddingNode
  }
  return currentPage * paddingNode
}

export const checkNodeVal = (node) => {
  if (node?.type === 'popup') {
    return 16
  }
  if (node?.relationType === 'ChairmanAndDirector' && node.data) {
    return 16
  }
  if (node.level.split('|').includes(String(1))) {
    return 30
  }
  if (node.level.split('|').includes(String(2))) {
    return 20
  }
  if (node.level.split('|').includes(String(3))) {
    return 15
  }
  return 50
}

export const checkNodeColor = (node) => {
  if (node?.type === 'popup') {
    return '#1e242e'
  }
  if (node?.relationType === 'ChairmanAndDirector' && node.data) {
    return '#1e242e'
  }

  if (
    node?.relationTypeMulti &&
    node?.relationTypeMulti.includes('OrganizationOwner')
  ) {
    return '#FACC5C'
  }

  if (
    node?.relations &&
    node?.relations.length > 0 &&
    node.parentRelationType &&
    node.parentRelationType.includes('MainSubject')
  ) {
    return '#A6CF98'
  }

  return '#BABABA'
}

export const filterItems = (node, links, arrayfilter) => {
  const items = node.filter(
    (e) => e.relationTypeMulti && !regexDataTable.test(e.relationTypeMulti),
  )
  const result = []

  function filterItem(item, parentID) {
    if (
      !arrayfilter.includes(item.id) &&
      (!item?.relations ||
        item?.relations.length === 0 ||
        item?.relations.length === undefined)
    ) {
      result.push(parentID)
    } else if (!arrayfilter.includes(item.id) && item.relations.length > 0) {
      const checkRelation = item.relations.some((relation) =>
        arrayfilter.includes(relation.id),
      )
      if (checkRelation) {
        return
      } else {
        item.relations.forEach((relation) => {
          filterItem(relation)
        })
      }

      if (!arrayfilter.includes(item.id)) {
        result.push(parentID)
      }
    }
  }

  items.forEach((e) => filterItem(e, e.id))
  const nodeHide = [...new Set(result)].filter((e) => e)
  return {
    nodes: node.filter((e) => !nodeHide.includes(e.id)),
    links: links,
  }
}
