import { useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../configs/Global'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Realtime } from '../../../../common/Realtime'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { FORMAT } from '../../../../utils/Datetime'
import {
  formatVal,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import CellHighLight from './CellHighLight'
import CellType from './CellType'
import { indicator } from './constants'
import {
  selectData,
  selectDataTimeAndSales,
  selectIds,
  selectLoading,
  selectScrollId,
  subscribeTimeAndSales,
} from './store/slice'

const TableComponent = ({ setRowTimes }) => {
  const ids = useSelector(selectIds)
  const data = useSelector(selectData)
  const isLoading = useSelector(selectLoading)
  const topInfo = useSelector(selectWarrantInfo)
  const scrollId = useSelector(selectScrollId)

  const scrollToBottomCallback = () => {
    if (scrollId) {
      setRowTimes((row) => row + 1)
    }
  }

  return (
    <div className="h-100">
      {isLoading ? (
        <Loading />
      ) : data.length > 0 ? (
        <Table
          ids={ids}
          getDataFromRedux={selectDataTimeAndSales}
          schema={Object.keys(indicator).map((key, index) => {
            const title = `market.coveredWarrant.timeAndSales.${key}`
            const colId = indicator[key]
            let result = {
              colId,
              title,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => formatVal(valDivThousand(val)),
            }

            if (index === 0) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'left',
                  textTransform: 'uppercase',
                },
                tdStyle: {
                  textAlign: 'left',
                },
                render: (val) => (
                  <DateTimeSpan date={val} formatDate={FORMAT.TIME} />
                ),
              }
            }
            if (index === 1) {
              return {
                ...result,

                render: (val) => <CellType val={val} />,
              }
            }
            if (index === 2) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={formatVal(valDivThousand(val))}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataTimeAndSales}
                  />
                ),
              }
            }
            if (index === 3) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={formatVal(valDivThousand(val))}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataTimeAndSales}
                  />
                ),
              }
            }
            if (index === 4) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={valToPercent(val)}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataTimeAndSales}
                  />
                ),
              }
            }

            return result
          })}
          stickies={{
            [indicator.TIME]: true,
            [indicator.TYPE]: true,
            [indicator.LAST_PRICE]: true,
          }}
          isLargeHead={true}
          hasFooter={false}
          isPagination={false}
          scrollToBottomCallback={scrollToBottomCallback}
        />
      ) : (
        <NoData />
      )}
      <Realtime
        channel={ChannelConfig.CoveredWarrantChannel + topInfo.coveredWarrantId}
        action={subscribeTimeAndSales}
      >
        <></>
      </Realtime>
    </div>
  )
}

export default TableComponent
