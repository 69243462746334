import { useSelector } from 'react-redux'
import { AssetAllocation } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectFilters } from './store/slice'

export const PanelAssetAllocation = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const filters = useSelector(selectFilters)

  const titlePanel = `fund.fundProfile.portfolio.${panelKey}`
  const nameScreen = UseI18n('constants.route.fund.fundProfile.portfolio.LABEL')
  const chartName = UseI18n(
    `fund.fundProfile.portfolio.assetAllocation.${panelKey}`,
  )
  const tabName = UseI18n(
    `fund.fundProfile.portfolio.${filters.type.toUpperCase()}`,
  )
  const titleJpg = UseI18n(
    `fund.fundProfile.portfolio.assetAllocation.CHART_NAME_${panelKey}_${filters.type.toUpperCase()}`,
    { month: filters.month, year: filters.year },
  )

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: panelKey,
        nameScreen,
        chartName,
        tabName,
      }}
    >
      <AssetAllocation
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
