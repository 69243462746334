import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { TIME_SELECT_VALUE, VALUE_TYPE } from '../constants'
import { checkHnxGroup, checkIpadScreen } from '../helps'
import { ChartFilter } from './ChartFilter'
import { NetValueChart } from './NetValueChart'
import { PercentValueChart } from './PercentValueChart'
import style from './index.module.css'
import {
  selectData,
  selectDateTime,
  selectLoading,
  selectValueType,
} from './store/slice'
import { getValueByInvestorChart } from './store/thunk'

export const ValueInvestor = ({ width, height, isResizeResponsive }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const valueType = useSelector(selectValueType)
  const dateType = useSelector(selectDateTime)
  const isLoading = useSelector(selectLoading)

  const isHnxGroup = useMemo(() => {
    return checkHnxGroup(basicInfo.exchangeCode)
  }, [basicInfo.exchangeCode])

  const getBarOrAreaKeys = () => {
    if (!isHnxGroup) {
      return ['localInstitution', 'proprietary', 'foreign', 'localIndividual']
    }
    return ['foreign']
  }

  const getLineKeys = () => {
    if (!isHnxGroup) {
      return ['closeIndex']
    }
  }

  const [listCheckbox, setListCheckbox] = useState(getBarOrAreaKeys())

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getValueByInvestorChart({
          OrganizationId: basicInfo.organizationId,
          Period: TIME_SELECT_VALUE[dateType],
        }),
      )
    }
  }, [dateType, basicInfo.organizationId])

  return (
    <div className={style['value-investor-container']}>
      {isHnxGroup ? (
        <NoData text="common.DATA_NOT_AVAILABLE" />
      ) : (
        <SizeTracker>
          {(size) => (
            <>
              <ChartFilter
                isIpadScreen={checkIpadScreen(width, 500)}
                dateType={dateType}
                valueType={valueType}
              />
              {size.height && (
                <div
                  id="valueInvestor"
                  className="position-relative"
                  style={{ height: height - size.height || 0 }}
                >
                  {isLoading ? (
                    <Loading />
                  ) : data.length ? (
                    <>
                      {valueType === VALUE_TYPE.NET_VALUE ? (
                        <NetValueChart
                          data={data}
                          width={width}
                          height={height - size.height}
                          dateType={dateType}
                          valueType={valueType}
                          isResizeResponsive={isResizeResponsive}
                          getBarKeys={getBarOrAreaKeys}
                          getLineKeys={getLineKeys}
                          isHnxGroup={isHnxGroup}
                          listCheckbox={listCheckbox}
                          setListCheckbox={setListCheckbox}
                        />
                      ) : (
                        <PercentValueChart
                          data={data}
                          width={width}
                          height={height - size.height}
                          dateType={dateType}
                          valueType={valueType}
                          isResizeResponsive={isResizeResponsive}
                          listCheckbox={listCheckbox}
                          setListCheckbox={setListCheckbox}
                          getAreaKeys={getBarOrAreaKeys}
                          getLineKeys={getLineKeys}
                          isHnxGroup={isHnxGroup}
                        />
                      )}
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              )}
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

ValueInvestor.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  isResizeResponsive: PropTypes.bool,
}
