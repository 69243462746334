import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import Popup from '../../../common/popup'
import { handleActiveTemplate } from './store/slice'
import { deleteTemplates, getTemplate } from './store/thunk'

const PopupDelete = ({ isShow, handleClose, id, title, activeTemplate }) => {
  const dispatch = useDispatch()

  const onSubmit = (e) => {
    if (activeTemplate === id) {
      dispatch(handleActiveTemplate(null))
    }
    dispatch(deleteTemplates({ templateId: id }))
    dispatch(getTemplate())
    handleClose(e)
  }

  return (
    <>
      {isShow && (
        <Popup>
          <div className="modal modal-small">
            <div className="modal-title">
              <h3>
                <Translate value="corporate.companySnapshot.DELETE_TEMPLATE" />
              </h3>
              <a onClick={(e) => handleClose(e)} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <Translate value="corporate.companySnapshot.TEXT_DELETE_TEMPLATE" />
              {title}
              <Translate value="corporate.companySnapshot.TEXT_DELETE_TEMPLATE1" />
              <div className="group-btn justify-content-end">
                <Button
                  onClick={onSubmit}
                  className={`btn normal w-80 h-20`}
                  style={{ fontSize: 11 }}
                >
                  <Translate value="corporate.companySnapshot.DELETE" />
                </Button>
                <Button
                  onClick={(e) => handleClose(e)}
                  className={`btn btn-blue w-80 h-20`}
                  style={{ fontSize: 11 }}
                >
                  <Translate value="corporate.companySnapshot.CANCEL" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

PopupDelete.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  activeTemplate: PropTypes.number.isRequired,
}

export default PopupDelete
