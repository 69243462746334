import { useDispatch, useSelector } from 'react-redux'
import useDidUpdateEffect from '../../../../common/hooks/useDidUpdateEffect'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import Title from '../common/Title'
import { COLORS, INDICATORS, ITEM_TYPE, TEXT_LIGHT } from '../constant'
import { EXCHANGE_CODE } from '../filter/constant'
import {
  selectIndicator,
  selectLoadingDefaultItems,
  selectSelectedItems,
} from '../filter/store/slice'
import { getIndicatorNameByValue } from '../helpers'
import UseI18n from './../../../../common/hooks/useI18n'
import ChartComponent from './ChartComponent'
import { CHART_DOM_ID } from './PanelValuationChart'
import { selectData, selectLoading, selectTimeFrame } from './store/slice'
import { getLineChart } from './store/thunk'
import TimeFrame from './TimeFrame'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const timeFrame = useSelector(selectTimeFrame)
  const loadingDefaultItems = useSelector(selectLoadingDefaultItems)
  const loadingChartData = useSelector(selectLoading)
  const data = useSelector(selectData)
  const selectedItems = useSelector(selectSelectedItems)
  const indicator = useSelector(selectIndicator)

  const indicatorName = UseI18n(getIndicatorNameByValue(indicator))

  const handleCallData = () => {
    dispatch(
      getLineChart({
        exchangeCode: EXCHANGE_CODE.ALL_EXCHANGE,
        icbIds: selectedItems
          .filter((item) => !!item.icbId && !item.isDeleted)
          .map((item) => item.icbId),
        organizationIds: selectedItems
          .filter((item) => !!item.organizationId)
          .map((item) => item.organizationId),
        period: timeFrame,
      }),
    )
  }

  useDidUpdateEffect(() => {
    handleCallData()
  }, [timeFrame])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="d-flex align-center justify-content-end mb-8">
              <TimeFrame />
            </div>
            <div id={CHART_DOM_ID}>
              <Title text="VALUATION_CHART_TITLE" suffix={indicatorName} />
              {size.height && (
                <div
                  style={{ height: height - size.height, position: 'relative' }}
                >
                  {loadingChartData || loadingDefaultItems ? (
                    <Loading />
                  ) : data.length === 0 || selectedItems.length === 0 ? (
                    <NoData />
                  ) : (
                    <ChartComponent
                      key={indicator}
                      width={width}
                      height={height - size.height}
                      data={data}
                      keyXAxis="tradingDateId"
                      items={selectedItems
                        .filter((item) => !item.isDeleted)
                        .map((item) => ({
                          ...item,
                          id: item.icbId
                            ? `${ITEM_TYPE.SECTOR}-${item.icbId}-${indicator}`
                            : `${ITEM_TYPE.TICKER}-${item.organizationId}-${indicator}`,
                          bgColor: COLORS[item.color],
                          textColor: TEXT_LIGHT.includes(item.color)
                            ? '#ececec'
                            : '#1e242e',
                        }))}
                      hasYAxisLabel={[
                        INDICATORS.marketCap,
                        INDICATORS.ev,
                      ].includes(indicator)}
                    />
                  )}
                </div>
              )}
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
