import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { ValueBreakdown } from '../../common/valueBreakdownChart'

export const PanelValueBreakdown = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const titlePanel = 'market.coveredWarrant.cwStatistics.NET_TRADING_BREAKDOWN'
  const titleJpg = I18n.t(
    'market.coveredWarrant.cwStatistics.NET_TRADING_BREAKDOWN',
  )
  const nameScreen = I18n.t('market.coveredWarrant.cwStatistics.CW_STATISTICS')
  const tabName = I18n.t('market.coveredWarrant.cwStatistics.overview.OVERVIEW')

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'valueBreakdown',
        nameScreen: nameScreen,
        chartName: titleJpg,
        tabName: tabName,
      }}
    >
      <ValueBreakdown
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
