import { Overview } from './overview'
import { SecondaryMarketStatistics } from './secondaryMarketStatistics'
import TradingStatistics from './tradingStatistics'
export const SECONDARY_MARKET_TABS = {
  OVERVIEW: 'overview',
  MARKET_STATISTICS: 'marketStatistics',
  TRADE_STATISTICS: 'tradeStatistics',
  BOARD: 'board',
}

export const NAVIGATION_SECONDARY_MARKET = [
  {
    title: 'bond.corporateBond.secondaryMarket.OVERVIEW',
    component: <Overview />,
    value: SECONDARY_MARKET_TABS.OVERVIEW,
  },
  {
    title: 'bond.corporateBond.secondaryMarket.MARKET_STATISTICS',
    component: <SecondaryMarketStatistics />,
    value: SECONDARY_MARKET_TABS.MARKET_STATISTICS,
  },
  {
    title: 'bond.corporateBond.secondaryMarket.TRADE_STATISTICS',
    component: <TradingStatistics />,
    value: SECONDARY_MARKET_TABS.TRADE_STATISTICS,
  },
]

export const BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID = ['#0096EB', '#DF5A49']
export const BAR_COLOR_LIQUIDITY_CHART = [
  '#40ABFF',
  '#FECF2F',
  '#DF5A49',
  '#FFFFFF',
]
export const BAR_COLOR_VALUE_CHART = ['#45B29D', '#FECF2F', '#FFFFFF']
export const BAR_COLOR_RELATIVE_TO_EQUITY = [
  '#185999',
  '#0096EB',
  '#FACC5C',
  '#A6CF98',
  '#DF5A49',
]
export const LIQUIDITY_CHART_TABS = [
  {
    title: 'bond.corporateBond.remainingMaturities.DAILY',
    value: 'Daily',
  },
  {
    title: 'bond.corporateBond.remainingMaturities.WEEKLY',
    value: 'Weekly',
  },
  {
    title: 'bond.corporateBond.remainingMaturities.MONTHLY',
    value: 'Monthly',
  },
  {
    title: 'bond.corporateBond.remainingMaturities.QUARTERLY',
    value: 'Quarterly',
  },
  {
    title: 'bond.corporateBond.remainingMaturities.YEARLY',
    value: 'Yearly',
  },
]

export const MONTH_QUARTER_YEAR_TABS = [
  {
    title: 'bond.corporateBond.valueChart.3M',
    value: 'ThreeMonths',
  },
  {
    title: 'bond.corporateBond.valueChart.6M',
    value: 'SixMonths',
  },
  {
    title: 'bond.corporateBond.valueChart.YTD',
    value: 'YearToDate',
  },
  {
    title: 'bond.corporateBond.valueChart.1Y',
    value: 'OneYear',
  },
  {
    title: 'bond.corporateBond.valueChart.3Y',
    value: 'ThreeYears',
  },
  {
    title: 'bond.corporateBond.valueChart.5Y',
    value: 'FiveYears',
  },
  {
    title: 'bond.corporateBond.valueChart.10Y',
    value: 'TenYears',
  },
  {
    title: 'bond.corporateBond.valueChart.ALL',
    value: 'All',
  },
]
export const VALUE_CHART_TABS = [
  {
    title: '3M',
    value: 'ThreeMonths',
  },
  {
    title: '6M',
    value: 'SixMonths',
  },
  {
    title: 'YTD',
    value: 'YearToDate',
  },
  {
    title: '1Y',
    value: 'OneYear',
  },
]
export const DURATION_YTM_TABS = [
  {
    title: 'bond.corporateBond.valueChart.DURATION',
    value: 'duration',
  },
  {
    title: 'bond.corporateBond.valueChart.YTM',
    value: 'ytm',
  },
]
