import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { LASTEST_YEARS, QUARTERS } from '../common/constant'
import style from './index.module.css'
import { changeFilter, selectFilter } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const handleChangeYear = (year) => dispatch(changeFilter({ year }))
  const handleChangeQuarter = (quarter) => dispatch(changeFilter({ quarter }))

  return (
    <div className="d-flex">
      <div className={style.select}>
        <SelectCustom
          isI18n={true}
          selectData={LASTEST_YEARS}
          value={filter.year}
          handleChange={handleChangeYear}
        />
      </div>
      <div className={style.select}>
        <SelectCustom
          isI18n={true}
          selectData={QUARTERS}
          value={filter.quarter}
          handleChange={handleChangeQuarter}
        />
      </div>
    </div>
  )
}

export default Filter
