import { CHART_TYPES } from '../../common/chart/financialChart/constant'
import { XAXIS_DATA_KEY } from '../common/constants'

export const VIEW_TYPE = {
  BY_TIME: 'ByTime',
  BY_SECURITIES: 'BySecurities',
}

export const TIME_FREQUENCY = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  TTM: 'TTM',
}

export const TIME_RANGE = {
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
  SIX_MONTHS: 'SixMonths',
  NINE_MONTHS: 'NineMonths',
  ONE_YEAR: 'OneYear',
  THREE_YEARS: 'ThreeYears',
  FIVE_YEARS: 'FiveYears',
  TEN_YEARS: 'TenYears',
  ALL_TIME: 'AllTime',
  MONTH_TO_DATE: 'MonthToDate',
  QUARTER_TO_DATE: 'QuarterToDate',
  YEAR_TO_DATE: 'YearToDate',
  REAL_TIME: 'RealTime',
}

export const COLORS = [
  '#f9c105',
  '#9c54e4',
  '#f4327b',
  '#42b9e9',
  '#27699b',
  '#f57f07',
  '#35a087',
  '#4bd9be',
  '#f1ffeb',
  '#F98E30',
  '#F1C594',
]

export const POSITION_LABEL = {
  LEFT: 'start',
  CENTER: 'center',
  RIGHT: 'end',
}

export const TYPE = {
  SQUARE_TWO_COLOR: 'SQUARE_TWO_COLOR',
  SQUARE_MARKER_LINE: 'SQUARE_MARKER_LINE',
  CIRCLE_MARKER_LINE: 'CIRCLE_MARKER_LINE',
  TRIANGLE_MARKER_LINE: 'TRIANGLE_MARKER_LINE',
  TRIANGLE: 'TRIANGLE',
  CIRCLE: 'CIRCLE',
  LINE_STROKE_DOT: 'LINE_STROKE_DOT',
  RECTANGLE: 'RECTANGLE',
  RECTANGULAR: 'RECTANGULAR',
  DASHED_LINE: 'DASHED_LINE',

  LINE: 'LINE',
  LINE_OVAL: 'LINE_OVAL',
  LINE_RHOMBUS: 'LINE_RHOMBUS',
  LINE_RECTANGLE: 'LINE_RECTANGLE',
  SQUARE: 'SQUARE',
  DOT_OVAL: 'DOT_OVAL',
  DOT_RHOMBUS: 'DOT_RHOMBUS',
  DOT_RECTANGLE: 'DOT_RECTANGLE',
}

export const MAP_TYPE_CHART_FOOTER = {
  AREA: TYPE.SQUARE,
  BAR: TYPE.SQUARE,
  STACK_BAR: TYPE.SQUARE,
  STACK_AREA: TYPE.SQUARE,
  LINE: TYPE.LINE,
  LINE_OVAL: TYPE.LINE_OVAL,
  LINE_RHOMBUS: TYPE.LINE_RHOMBUS,
  LINE_RECTANGLE: TYPE.LINE_RECTANGLE,
  DOT_OVAL: TYPE.DOT_OVAL,
  DOT_RHOMBUS: TYPE.DOT_RHOMBUS,
  DOT_RECTANGLE: TYPE.DOT_RECTANGLE,
}

export const LATEST_YEARS = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: year - v,
  }
})

export const QUARTERS = [
  {
    name: 'financialChart.QUARTER_1',
    value: 1,
  },
  {
    name: 'financialChart.QUARTER_2',
    value: 2,
  },
  {
    name: 'financialChart.QUARTER_3',
    value: 3,
  },
  {
    name: 'financialChart.QUARTER_4',
    value: 4,
  },
]

export const MONTHS = [...Array(12).keys()].map((number) => {
  return {
    name: `financialChart.MONTH_${number + 1}`,
    value: number + 1,
  }
})

export const WEEKS = [...Array(53).keys()].map((number) => {
  let weekNum = number + 1
  if (weekNum < 10) {
    weekNum = '0' + weekNum
  }
  return {
    name: `W${weekNum}`,
    value: number + 1,
  }
})

export const keyToolHeaderSettings = {
  Y_AXIS_LABEL: 'yAxisLabel',
  POSITION_Y_AXIS_LABEL: 'positionYAxisLabel',
  GROUP_Y_AXIS: 'groupYAxis',
  X_AXIS_GROUP_BY_YEAR: 'xAxisGroupByYear',
  POSITION_GROUP_Y_AXIS: 'positionGroupXAxis',
  CHART_LEGEND: 'chartLegend',
  GRID_DISPLAY: 'gridDisplay',
  CHART_LEGEND_FOOTER: 'chartLegendFooter',
  Y_AXIS_LEGEND: 'yAxisLegend',
  X_AXIS_ANGLE: 'xAxisAngle',
  VIEW_BY_LATEST_PERIOD: 'viewByLatestPeriod',
}

export const keySettingChart = {
  X_AXIS: 'xAxis',
  X_AXIS_LEGEND: 'xAxisLegend',
  Y_AXIS_POSITION: 'yAxisPosition',
  Y_AXIS_LABEL: 'yAxisLabel',
  Y_AXIS_LEGEND: 'yAxisLegend',
}

export const positionXAxis = {
  RIGHT: 'right',
  LEFT: 'left',
  CUSTOM: 'custom',
}

export const positionYAxis = {
  NEXT_TO_Y_AXIS: 'nextToYAxis',
  ABOVE_Y_AXIS: 'aboveYAxis',
}

export const typeLegendYAxis = {
  TYPE_1: 'TYPE_1',
  TYPE_2: 'TYPE_2',
  TYPE_3: 'TYPE_3',
  TYPE_4: 'TYPE_4',
  TYPE_5: 'TYPE_5',
}

export const CHART_PARAMETERS_DEFAULT = {
  typeChart: CHART_TYPES.LINE,
  viewType: VIEW_TYPE.BY_TIME,
  timeFrequency: TIME_FREQUENCY.DAILY,
  timeRange: TIME_RANGE.FIVE_YEARS,
  treeSecuritiesIndicator: {
    companies: {},
    indices: {},
    sector: {},
    economy: {},
  },
  indicators: [],
  nameChart: {
    fontSize: 17,
    name: 'Chart 1',
    bold: false,
    italic: false,
    underline: false,
    position: POSITION_LABEL.CENTER,
  },
  securities: {
    companies: [],
    indices: [],
    sector: [],
    economy: [],
    watchList: [],
  },
  indicatorSelected: {
    companyIndicator: [],
    indexIndicator: [],
    sectorIndicator: [],
  },
  equations: {},
  order: 1,
  isShowTable: false,
  settings: {
    [keyToolHeaderSettings.Y_AXIS_LABEL]: false,
    [keyToolHeaderSettings.GROUP_Y_AXIS]: true,
    [keyToolHeaderSettings.X_AXIS_GROUP_BY_YEAR]: false,
    [keyToolHeaderSettings.CHART_LEGEND]: true,
    [keyToolHeaderSettings.GRID_DISPLAY]: true,
    [keyToolHeaderSettings.CHART_LEGEND_FOOTER]: true,
    [keyToolHeaderSettings.POSITION_GROUP_Y_AXIS]: positionXAxis.CUSTOM,
    [keyToolHeaderSettings.POSITION_Y_AXIS_LABEL]: positionYAxis.NEXT_TO_Y_AXIS,
    [keyToolHeaderSettings.Y_AXIS_LEGEND]: typeLegendYAxis.TYPE_1,
    [keyToolHeaderSettings.X_AXIS_ANGLE]: 0,
    [keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD]: true,
  },
  listLegendCustom: {},
  styleSetting: {
    [keySettingChart.X_AXIS]: {
      fontSize: 12,
      bold: false,
      italic: false,
      underline: false,
    },
    [keySettingChart.X_AXIS_LEGEND]: {
      fontSize: 12,
      bold: false,
      italic: false,
      underline: false,
    },
    [keySettingChart.Y_AXIS_POSITION]: {
      fontSize: 12,
      bold: false,
      italic: false,
      underline: false,
    },
    [keySettingChart.Y_AXIS_LABEL]: {
      fontSize: 12,
      bold: false,
      italic: false,
      underline: false,
    },
  },
  listYAxisCustom: {},
}

export const listViewTime = [
  {
    name: 'financialChart.BY_TIME',
    value: VIEW_TYPE.BY_TIME,
  },
  {
    name: 'financialChart.BY_SECURITIES',
    value: VIEW_TYPE.BY_SECURITIES,
  },
]

export const listTimeFrequency = [
  { name: 'financialChart.DAILY', value: 'Daily' },
  { name: 'financialChart.WEEKLY', value: 'Weekly' },
  { name: 'financialChart.MONTHLY', value: 'Monthly' },
  { name: 'financialChart.QUARTERLY', value: 'Quarterly' },
  { name: 'financialChart.YEARLY', value: 'Yearly' },
]

export const SET_AS_DEFAULT_SETTING_KEY = 'setAsDefault'

export const PADDING_SIDES = 48 * window.devicePixelRatio

export const EXCLUDE_DATA_KEYS = [
  XAXIS_DATA_KEY.DATE,
  XAXIS_DATA_KEY.SECURITY_NAME,
]
