const colorsPieChart = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#EE7067',
  '#FFD365',
  '#18975D',
  '#F98E30',
  '#F1C594',
]

export const formatChartData = (data, dataKey, dataName, top = 5) => {
  const sortedData = [...data].sort((a, b) => b[dataKey] - a[dataKey])
  const topData = sortedData.slice(0, top)

  const result = topData.map((item, index) => ({
    text: item[dataName],
    value: item[dataKey] * 100,
    color: colorsPieChart[index],
  }))

  if (topData.length === data.length) return result

  const otherRatio =
    1 - topData.reduce((total, item) => total + item[dataKey], 0)

  return [
    ...result,
    {
      text: 'sector.sectorStatistics.overview.OTHERS',
      value: otherRatio * 100,
      color: '#ecffdf',
      isI18n: true,
    },
  ]
}
