export const deepTransaction = {
  DEEP_TRANSACTION: 'Deep Transaction',
  SUMMARY: 'Summary',
  PRICE_PERFORMANCE: 'Price Performance',
  PRICE_DEPTH: 'Price Depth',
  TIME_SALE: 'Time & Sales',
  MATCHING_ORDER: 'Matching Order',

  summary: {
    MARKET_CAP: 'Market Cap (Bn.VND)',
    OUTSTANDING: 'Outstanding (M)',
    FREE_FLOAT: 'Free float (M)',
    FREE_FLOAT_RATE: 'Free float Rate',
    FOREIGN_CURRENT_ROOM: 'Foreign Current Room (M)',
    FOREIGN_CURRENT_PERCENT: '% Foreign Current Room',
    ROE: 'ROE (%)',
    PE: 'P/E (Times)',
    ONE_DAY_CHANGE: '% 1D Change',
    ONE_WEEK_CHANGE: '% 1W Change',
    ONE_MONTH_CHANGE: '% 1M Change',
    YTD_CHANGE: '% YTD Change',
    TOTAL_VALUE: 'Total Value (Mn. VND)',
    TOTAL_VOLUME: 'Total Volume (Thousand)',
    AVG_VOL_TEN_DAY: 'Avg. Vol 10D (Thousand)',
    AVG_VOL_ONE_MONTH: 'Avg. Vol 1M (Thousand)',
  },

  pricePerformance: {
    PRICE: 'Price (Thousand VND)',
    VOLUME: 'Volume:',
    THOUSAND: 'Thousand',
  },

  priceDepth: {
    BID: 'BID',
    ASK: 'ASK',
    BUY_VOL: 'BUY VOL.',
    SELL_VOL: 'SELL VOL.',
    TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
    TOTAL_MATCHED_VOL: 'TOTAL MATCHED VOL.',
    TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
  },

  timeSale: {
    BU_VOLUME: 'BU Volume',
    SD_VOLUME: 'SD Volume',
    UNIDENTIFIED: 'Unidentified',
    VOLUME_SHARE: 'Volume (Thousand Shares)',
    PRICE_VND: 'Price (Thousand VND)',
    PRICE: 'Price',
    THOUSAND: 'Thousand',
  },

  matchingOrder: {
    TIME: 'Time',
    TYPE: 'Type',
    PRICE: 'Price',
    CHANGE: 'Change',
    PERCENT_CHANGE: '% 1D Change',
    MATCH_VOL: 'Matched Volume',
    TOTAL_MATCH_VOL: 'Total Matched Volume',
    NOTE_TEXT: 'Price: x 1,000. Volume: x 1,000.',
  },
}

export const priceStatistics = {
  PRICE_DATA: 'PRICE DATA',
  VALUE_INVESTOR: 'NET VALUE BY INVESTOR',
  NET_VALUE: 'NET VALUE BREAKDOWN',

  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',

  SCREEN_LABEL: 'Price Statistics',
  TABLE_FOOTER_TEXT: 'The data is calculated as of ',

  // TABS
  OVER_VIEW: 'Overview',
  ORDER_STATISTICS: 'Order Statistics',
  FOREIGN: 'Foreign',
  PROPRIETARY: 'Proprietary',
  STATISTICS_INVESTOR: 'Statistics by investor',

  // COMPONENT
  valueInvestor: {
    NET_VALUE: 'NET VALUE',
    PERCENT_VALUE: '% VALUE',
    NET_VALUE_UNIT: 'Value (Billion VND)',
    PERCENT_VALUE_UNIT: 'Value',
    PRICE_UNIT: 'Price (Thousand VND)',
    INDEX_POINT: 'Index (Point)',

    LOCAL_INSTITUTION: 'Local Institution',
    PROPRIETARY: 'Proprietary',
    FOREIGN: 'Foreign',
    LOCAL_INDIVIDUAL: 'Local Individual',
    BILLION: 'Bn.',
  },

  netValue: {
    VALUE_UNIT: 'Value (Billion VND)',
    BUY: 'Buy',
    SELL: 'Sell',
    NET: 'Net',
    BILLION: 'Bn.',
  },

  listType: {
    FOREIGN: 'Foreign',
    LOCAL_INSTITUTION: ' Local Institution',
    LOCAL_INDIVIDUAL: 'Local Individual',
    PROPRIETARY: 'Proprietary',
  },

  transactionType: {
    ALL: 'All transactions',
    MATCH: 'Match',
    DEAL: 'Deal',
  },

  priceData: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',

    DATE: 'DATE',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR',

    overview: {
      TABLE_HEAD_TEXT: 'Price: x 1,000. Volume: x 1,000. Value: x 1,000,000.',
      DATE: 'DATE',
      PERCENT_CHANGE_DAILY: '%1D CHANGE',
      PERCENT_CHANGE_WEEKLY: '%1W Change',
      PERCENT_CHANGE_MONTHLY: '%1M Change',
      PERCENT_CHANGE_YEARLY: '%1Y Change',
      PERCENT_CHANGE_QUARTERLY: '%1Q Change',
      OPEN: 'OPEN',
      HIGH: 'HIGH',
      LOW: 'LOW',
      CLOSE: 'CLOSE',
      MATCHED_VOLUME: 'MATCHED VOL.',
      MATCHED_VALUE: 'MATCHED VAL.',
      PUT_THROUGH_VALUE: 'PUT THROUGH VAL.',
      PUT_THROUGH_VOLUME: 'PUT THROUGH VOL.',
      TOTAL_VOL: 'TOTAL VOL.',
      TOTAL_VALUE: 'TOTAL VAL.',
      MARKET_CAP: 'MARKET CAP',
    },
    orderStatistic: {
      DATE: 'DATE',
      UNMATCH_BUY_VOL: 'UNMATCHED BUY VOL.',
      UNMATCH_SELL_VOL: 'UNMATCHED SELL VOL.',
      NR_BUY_ORDER: 'NR. OF BUY ORDER',
      BUY_VOL: 'BUY VOL.',
      VOL_BUY_ORDER: 'VOL/BUY ORDER',
      NR_SELL_ORDER: 'NR. OF SELL ORDER',
      SELL_VOL: 'SELL VOL.',
      VOL_SELL_ORDER: 'VOL/SELL ORDER',
      NET_VOL: 'NET VOL.',
    },
    foreign: {
      DATE: 'DATE',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      FOREIGN_CR_ROOM: 'FOREIGN CURRENT ROOM',
      FOREIGN_TOTAL_ROOM: 'FOREIGN TOTAL ROOM',
      PERCENT_FOREIGN_TOTAL_ROOM: '% FOREIGN TOTAL ROOM',
      FOREIGN_OWNED: 'FOREIGN OWNED',
      PERCENT_FOREIGN_OWNED: '% FOREIGN OWNED',
    },
    proprietary: {
      DATE: 'DATE',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      MATCH_NET_VOL: 'NET MATCHED VOL.',
      MATCH_NET_VAL: 'NET MATCHED VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      DEAL_NET_VOL: 'NET DEAL VOL.',
      DEAL_NET_VAL: 'NET DEAL VAL.',
    },
    statisticsInvest: {
      DATE: 'DATE',
      LOCAL_INDIVIDUAL: 'LOCAL INDIVIDUAL',
      LOCAL_INSTITUTION: 'LOCAL INSTITUTION',
      FOREIGN_INDIVIDUAL: 'FOREIGN INDIVIDUAL',
      FOREIGN_INSTITUTION: 'FOREIGN INSTITUTION',
      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      MATCH_NET_VOL: 'NET MATCHED VOL.',
      MATCH_NET_VAL: 'NET MATCHED VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
      DEAL_NET_VOL: 'NET DEAL VOL.',
      DEAL_NET_VAL: 'NET DEAL VAL.',
    },
  },
}
