import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class FDIByIndustriesProxy extends ProxyBase {
  getTotalRegisterCapitalRequest(params) {
    return this.get('TotalRegisterCapital', params)
  }

  downloadTotalRegisterCapital(params) {
    return this.get('DownloadTotalRegisterCapital', params, 'download')
  }

  getVSICName(params) {
    return this.get('VSICName', params)
  }

  getFDIStructureByIndustry(params) {
    return this.get('FDIStructureByIndustry', params)
  }

  getCompositionOfFDIInflowsByIndustryPieChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionOfFDIInflowsByIndustryPieChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionOfFDIInflowsByIndustryPieChart', params)
  }

  getCompositionOfFDIInflowsByIndustryColumnChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionOfFDIInflowsByIndustryColumnChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionOfFDIInflowsByIndustryColumnChart', params)
  }

  getFDITypeName(params) {
    return this.get('FDITypeName', params)
  }
}

export default new FDIByIndustriesProxy(
  ServiceProxyConfig.Economy.FDI.FDIByIndustries.ServiceUrl,
)
