import PropTypes from 'prop-types'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'

const FirstCellColumn = ({ val, props, level }) => {
  const customByLevel = (level) => {
    switch (level) {
      case 2:
        return { color: '#fecf2f', marginLeft: 20 }
      case 3:
        return { marginLeft: 52 }
      default:
        return { marginLeft: 76 }
    }
  }

  return (
    <td {...props}>
      <TextEllipsisStaticLine
        appendStyle={customByLevel(level)}
        val={val}
        isI18n={false}
      />
    </td>
  )
}

FirstCellColumn.propTypes = {
  val: PropTypes.string,
  props: PropTypes.object,
  level: PropTypes.number,
}

export default FirstCellColumn
