import { createSlice } from '@reduxjs/toolkit'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { register } from '../../../../../utils/ReducerRegistry'
import { TICKER_OPTIONS, VALUE_TYPES } from '../constants'

const initialState = {
  filters: {
    valueType: VALUE_TYPES.RELATIVE,
    ticker: TICKER_OPTIONS.INDEX_VN,
    timeRange: TIME_RANGES.OneYear,
    startDate: '',
    endDate: '',
  },
}

export const slice = createSlice({
  name: 'fund/fundProfile/navData/navCertificate',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {},
})

export const selectFilters = (state) => state[slice.name].filters

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
