import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { getFontSize } from '../../utils/FontSize'
import { Span } from '../html/Span'
import TextEllipsis from '../textEllipsis'
import { getItemTextWidth } from './helper'
import style from './index.module.css'

export const TIME_RANGES = {
  CUSTOM: 'CUSTOM',
  '1D': '1D',
  '1W': '1W',
  '1W_DATE': '1W_DATE',
  '2W': '2W',
  '1M': '1M',
  '3M': '3M',
  '6M': '6M',
  '9M': '9M',
  '1Y': '1Y',
  '2Y': '2Y',
  '3Y': '3Y',
  '5Y': '5Y',
  WTD: 'WTD',
  MTD: 'MTD',
  QTD: 'QTD',
  YTD: 'YTD',
  OneTick: 'OneTick',
  OneMinute: 'OneMinute',
  FiveMinutes: 'FiveMinutes',
  OneDay: 'OneDay',
  OneWeek: 'OneWeek',
  OneMonth: 'OneMonth',
  ThreeMonths: 'ThreeMonths',
  SixMonths: 'SixMonths',
  OneYear: 'OneYear',
  ThreeYears: 'ThreeYears',
  FiveYears: 'FiveYears',
  WeekToDate: 'WeekToDate',
  MonthToDate: 'MonthToDate',
  QuarterToDate: 'QuarterToDate',
  YearToDate: 'YearToDate',
  All: 'All',
}

const DispatchActionTab = ({
  activeTab,
  data,
  onChangeTab,
  itemStyle,
  containerStyle,
  itemClass,
  itemActiveClass,
  widthComponent,
  listDisabled,
  textSize,
}) => {
  const fontSize11 = getFontSize(11)
  const refNav = useRef()
  const locale = useSelector((state) => state.i18n.locale)

  const onClick = (index) => () => {
    if (listDisabled.includes(data[index]?.value)) {
      return null
    } else {
      onChangeTab(data[index])
    }
  }

  const [widthTab, setWidthTab] = useState('')

  useEffect(() => {
    if (widthComponent) {
      const widths = getItemTextWidth(
        data,
        itemStyle,
        fontSize11,
        widthComponent,
      )
      setWidthTab(widths)
    }
  }, [widthComponent, data, refNav.current, locale])

  return (
    <nav ref={refNav} className="tab-in-panel" style={containerStyle}>
      {data.map(({ title, value, customStyle }, index) => {
        return (
          <a
            key={index}
            className={[
              style.item,
              activeTab === value ? itemActiveClass || style.btnActive : '',
              listDisabled.includes(value) ? style.isDisabled : '',
              itemClass,
            ].join(' ')}
            style={{ padding: '0 10px', ...itemStyle, ...customStyle }}
            onClick={onClick(index)}
          >
            <Span
              key={fontSize11 + index}
              style={{
                fontSize: textSize,
                width: widthComponent ? widthTab[index] : '',
              }}
            >
              {widthTab[index] ? (
                <TextEllipsis text={title} isI18n={true} />
              ) : (
                <Translate value={title} />
              )}
            </Span>
          </a>
        )
      })}
    </nav>
  )
}

DispatchActionTab.propTypes = {
  activeTab: PropTypes.any.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  onChangeTab: PropTypes.func,
  itemClass: PropTypes.string,
  itemActiveClass: PropTypes.string,
  listDisabled: PropTypes.array,
  textSize: PropTypes.number,
}

DispatchActionTab.defaultProps = {
  onChangeTab: () => {},
  listDisabled: [],
  textSize: 11,
}

export default DispatchActionTab
