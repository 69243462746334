import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  selectEquations,
  selectTreeSecuritiesIndicator,
  selectTypeChart,
} from '../../chart/store/slice'
import { SEC_GROUP_INDICATOR } from '../../common/constants'
import { getUsedColorsBySecuritiesAndEquations } from '../../common/helpers/colorHelper'
import { selectIndicatorByTypeSecurities } from '../../store/slice'
import { selectWatchList } from '../common/store/slice'
import { selectIndicatorSelected } from '../popupAddIndicator/store/slice'
import { checkOrientationLeft } from '../popupAddSecurities/helpers'

const withWrapperData = (Component) => (props) => {
  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const equations = useSelector(selectEquations)
  const typeChart = useSelector(selectTypeChart)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )
  const indicatorSelected = useSelector(selectIndicatorSelected)
  const watchLists = useSelector(selectWatchList)

  const getUsedColors = useMemo(() => {
    return getUsedColorsBySecuritiesAndEquations(
      treeSecuritiesIndicator,
      equations,
    )
  }, [treeSecuritiesIndicator, equations])

  const haveOrientationLeft = useMemo(() => {
    return checkOrientationLeft(treeSecuritiesIndicator)
  }, [treeSecuritiesIndicator])

  return (
    <Component
      treeSecuritiesIndicator={treeSecuritiesIndicator}
      indicatorSelected={indicatorSelected}
      listSecuritiesEconomy={listSecuritiesEconomy}
      typeChart={typeChart}
      haveOrientationLeft={haveOrientationLeft}
      usedColors={getUsedColors}
      watchLists={watchLists}
      {...props}
    />
  )
}

export default withWrapperData
