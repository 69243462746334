import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Filter from '../../../common/dataExplorer/filter'
import { addColumn, keys, selectLoading, selectTemplates } from './store/slice'
import {
  deleteTemplateThunk,
  getTemplatesThunk,
  getTemplateThunk,
  renameTemplateThunk,
} from './store/thunk'
import useGetIndicator from './useGetIndicator'

const EconomyFilter = () => {
  const dispatch = useDispatch()

  const { indicatorsTree, loading } = useGetIndicator(false)

  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const templates = useSelector(selectTemplates)

  useEffect(() => {
    dispatch(getTemplatesThunk())
  }, [])

  const handleAddIndicator = (indicator) => {
    if (!Object.keys(indicator.children).length) {
      dispatch(addColumn(indicator))
    }
  }

  const renameTemplate = ({ newName, item }) => {
    dispatch(
      renameTemplateThunk({
        templateId: item.templateId,
        oldTemplateName: item.templateName,
        newTemplateName: newName,
      }),
    )
  }

  const deleteTemplate = (item) => {
    dispatch(deleteTemplateThunk({ templateId: item.templateId }))
  }

  const getDetailTemplate = (item) => {
    dispatch(getTemplateThunk({ TemplateId: item.templateId }))
  }

  return (
    <>
      <Filter
        loading={loadingTable}
        handleAddIndicator={handleAddIndicator}
        indicatorTree={indicatorsTree}
        loadingIndicator={loading}
        templates={templates}
        handleRenameTemplate={renameTemplate}
        handleDeleteTemplate={deleteTemplate}
        handleGetTemplate={getDetailTemplate}
        isEconomy={true}
      />
    </>
  )
}

export default EconomyFilter
