import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class LocalInstitutionProxy extends ProxyBase {
  getNetValue(params) {
    return this.get('GetNetValueProprietary', params)
  }

  getTopNetValue(params) {
    return this.get('GetTopNetTrading', params)
  }

  getSectorSegmentation(params) {
    return this.get('GetInfoSectorSegmentation', params)
  }

  downloadSectorSegmentation(params) {
    return this.get('DownloadSectorSecmentation', params, 'download')
  }

  downloadTopNetValue(params) {
    return this.get('DownloadTopNetTradingValue', params, 'download')
  }
}

export default new LocalInstitutionProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
