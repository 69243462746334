import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class MarketStatisticsProxy extends ProxyBase {
  getIssuanceValue(params) {
    return this.get('Benchmarking/IssuanceValue', params)
  }
  getAverageCouponBySector(params) {
    return this.get('Benchmarking/AverageCouponbySector', params)
  }
  getIssuanceValueBySector(params) {
    return this.get('Benchmarking/IssuanceValuebySector', params)
  }
  getIssuanceValueByCoupon(params) {
    return this.get('Benchmarking/IssuanceValuebyCouponType', params)
  }
  getAverageDurationBySector(params) {
    return this.get('Benchmarking/AverageDurationbySector', params)
  }
  downloadIssuanceValue(params) {
    return this.post('Benchmarking/IssuanceValueExport', params, {}, 'download')
  }
}

export default new MarketStatisticsProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
