import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import { valueTab } from '../infoSec/constants'
import {
  selecBondIds,
  selectActiveTab,
  selectCreateBondId,
} from '../infoSec/store/slice'
import Bottom from './Bottom'
import Content from './Content'
import Filter from './Filter'
import { resetHistoryStore, selectQty } from './store/slice'
import { exportFile, getDataHistory } from './store/thunk'

const Index = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const bondId = useSelector(selecBondIds)
  const createBondId = useSelector(selectCreateBondId)
  const activeTab = useSelector(selectActiveTab)

  const locale = useSelector((state) => state.i18n.locale)
  const qty = useSelector(selectQty)

  useEffect(() => {
    if (activeTab === valueTab.BOND_DEFAULT) {
      if (bondId) {
        dispatch(
          getDataHistory({
            BondId: bondId,
            isUserBond: false,
          }),
        )
      }

      return
    }

    if (!createBondId) {
      dispatch(resetHistoryStore())
      return
    }

    dispatch(
      getDataHistory({
        BondId: createBondId,
        isUserBond: true,
      }),
    )
  }, [bondId, locale, activeTab, createBondId])

  const downloadexel = () => {
    return exportFile({
      BondId: activeTab === valueTab.BOND_DEFAULT ? bondId : createBondId,
      quantity: qty,
      isUserBond: activeTab !== valueTab.BOND_DEFAULT,
    })
  }
  return (
    <>
      <Panel
        title="bond.valuation.valuation.history"
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadCsv={true}
        downloadCsv={() => downloadexel()}
      >
        <div className="h-100">
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <Filter />
                  {size.height && (
                    <div style={{ height: `calc(100% - ${size.height}px)` }}>
                      <Content />
                    </div>
                  )}
                  <Bottom />
                </>
              )
            }}
          </SizeTracker>
        </div>
      </Panel>
    </>
  )
}

export default Index
