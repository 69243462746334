import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorValuationService from '../../../../../../core/services/sector/sectorConstituents/SectorValuationService'
import { handleExport } from '../../../../../utils/Export'

export const getValuationTableData = createAsyncThunk(
  'sector/sectorConstituents/valuation/valuationTable/getValuationTableData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SectorValuationService.getDataTable(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadValuationDataTable = createAsyncThunk(
  'sector/sectorConstituents/valuation/valuationTable/downloadDataTable',
  async (params) => {
    const response = await SectorValuationService.downloadDataTable(params)
    handleExport(response.data, response.filename)
  },
)
