import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { keyBy } from '../../../../../utils/Common'
import { VIEW_BY } from '../constants'
import { convertType } from '../helper'
import { selectQuarter, selectTypeFilter, selectYear } from '../store/slice'
import FirstColumnCell from './FirstColumnCell'
import OtherColumnsCell from './OtherColumnsCell'
import { detailMetricsTableConstants } from './constants'
import {
  changeIds,
  selectDetailMetricsIds,
  selectDetailMetricsValue,
  selectGroupColumns,
  selectLoading,
  setDetailMetricsById,
} from './store/slice'
import { getDetailMetrics } from './store/thunk'

const DetailMetrics = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const stockInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectLoading)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  const detailMetricsIds = useSelector(selectDetailMetricsIds)
  const groupColumns = useSelector(selectGroupColumns)

  useEffect(() => {
    if (stockInfo.organizationId && filterYear) {
      const mapType = {
        [VIEW_BY.MARGIN_LENDING]: detailMetricsTableConstants.marginLending,
        [VIEW_BY.PROPRIETARY]: detailMetricsTableConstants.proprietary,
        [VIEW_BY.TREASURY]: detailMetricsTableConstants.treasury,
      }
      dispatch(changeIds(mapType[typeFilter]))
      dispatch(setDetailMetricsById(keyBy(mapType[typeFilter], 'index')))
      dispatch(
        getDetailMetrics({
          OrganizationId: stockInfo.organizationId,
          BusinessActivity: convertType(typeFilter),
          Quarter: filterQuarter ? filterQuarter : undefined,
          Year: filterYear,
        }),
      )
    }
  }, [locale, stockInfo, typeFilter, filterYear, filterQuarter])

  if (isLoading) {
    return <Loading />
  }

  return (
    <div className="h-100">
      {groupColumns.length > 1 ? (
        <Table
          ids={detailMetricsIds}
          columnDraggable={false}
          getDataFromRedux={selectDetailMetricsValue}
          isLoading={isLoading}
          rowDraggable={false}
          changeIds={changeIds}
          schema={groupColumns.map((item, index) => {
            const title = item.title
            const colId = item.key
            const result = {
              colId,
              title,
            }

            if (index === 0) {
              return {
                ...result,
                isI18n: true,
                thStyle: {
                  textAlign: 'left',
                  fontSize: 10,
                },
                canCustomTd: true,
                render: (val, rowId, { style, className }) => {
                  return (
                    <FirstColumnCell
                      val={val}
                      rowId={rowId}
                      style={style}
                      className={className}
                    />
                  )
                },
              }
            }

            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'right',
                fontSize: 10,
              },
              canCustomTd: true,
              render: (val, rowId) => {
                return <OtherColumnsCell val={val} rowId={rowId} />
              },
            }
          })}
          stickyFirstColumn
          isPagination={false}
          hasFooter={false}
          resizable={false}
          defaultScrollToRight
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default DetailMetrics
