import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { FORMAT, getCurrentDateTime } from '../../../utils/Datetime'
import HeaderOneCalendarRange from '../../calendar/headers/HeaderOneCalendarRange'
import OneCalendarRange from '../../calendar/OneCalendarRange'
import Dropdown, { positionDropdown } from '../../dropdown'
import { HandleClickOutside } from '../../HandleClickOutside'
import { Span } from '../../html/Span'
import { ScrollComponent } from '../../ScrollComponent'
import SelectCustom from '../../selectCustom'
import { TIME } from '../constants'
import { DetailNewsTypeSelect } from './DetailNewsTypeSelect'
import style from './index.module.css'
import { NewsTypeSelect } from './NewsTypeSelect'
import { SourceSelect } from './SourceSelect'

const FILTER_MIN_YEAR = 2004

export const Filter = ({
  isTransparentBg,
  changeFilter,
  sources,
  categories,
  sourceValue,
  categoryValue,
  filterStartDate,
  filterEndDate,
  time,
  height,
  newsTypeValue,
  position,
  isShowNewType,
}) => {
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)
  const sourceDropdownRef = useRef()
  const categoryDropdownRef = useRef()
  const timeDropdownRef = useRef()
  const monthContentDropdownRef = useRef()
  const yearContentDropdownRef = useRef()

  const onClick = () => {
    setIsShow(!isShow)
  }

  const hidden = () => {
    setIsShow(false)
  }

  const headerOneCalendarRange = HeaderOneCalendarRange({
    startYear: FILTER_MIN_YEAR,
    endYear: +getCurrentDateTime(FORMAT.YEAR),
    zIndex: 13,
    monthContentDropdownRef,
    yearContentDropdownRef,
  })

  const renderContent = () => {
    return (
      <>
        {isShowNewType && (
          <NewsTypeSelect
            categories={categories}
            categoryDropdownRef={categoryDropdownRef}
            newsType={newsTypeValue}
            categoryValue={categoryValue}
            changeFilter={changeFilter}
          />
        )}
        {newsTypeValue && (
          <DetailNewsTypeSelect
            categories={categories}
            categoryDropdownRef={categoryDropdownRef}
            categoryValue={categoryValue}
            newsType={newsTypeValue}
            changeFilter={changeFilter}
            isShowNewType={isShowNewType}
          />
        )}
        <SourceSelect
          appendDropdownRef={sourceDropdownRef}
          changeFilter={changeFilter}
          sources={sources}
          value={sourceValue}
        />
        <div className="mt-30 mb-10">
          <label className={style.titleSelect}>
            <Span style={{ fontSize: 11 }}>
              <Translate value={'common.news.TIME'} />
            </Span>
          </label>
          <SelectCustom
            appendDropdownRef={timeDropdownRef}
            value={time}
            isI18n
            selectData={Object.keys(TIME).map((key) => {
              return {
                value: TIME[key],
                name: 'common.news.time.' + key,
              }
            })}
            handleChange={(value) => changeFilter({ time: value })}
          />
        </div>
        {time === TIME.Custom && (
          <div className={style.calendarWrapper}>
            <OneCalendarRange
              startDate={filterStartDate ? new Date(filterStartDate) : null}
              endDate={filterEndDate ? new Date(filterEndDate) : null}
              customHeader={headerOneCalendarRange}
              minDate={new Date(FILTER_MIN_YEAR, 0)}
              maxDate={new Date(new Date().getFullYear(), 11, 31)}
              handleChange={([startDate, endDate]) => {
                changeFilter({ startDate, endDate })
              }}
            />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      <div
        className={[style.iconWrapper, 'cursor-pointer'].join(' ')}
        style={{ backgroundColor: isTransparentBg ? '' : '#3c424b' }}
        ref={ref}
        onClick={onClick}
      >
        <i className="icon-filter" />
      </div>
      {isShow && (
        <Dropdown parentRef={ref} isShow position={position}>
          <HandleClickOutside
            handleClickOutside={hidden}
            exceptRef={[
              ref,
              categoryDropdownRef,
              sourceDropdownRef,
              timeDropdownRef,
              monthContentDropdownRef,
              yearContentDropdownRef,
            ]}
          >
            <div className={style.container}>
              {height && (
                <ScrollComponent autoHeight autoHeightMax={height}>
                  {renderContent()}
                </ScrollComponent>
              )}
              {!height && renderContent()}
            </div>
          </HandleClickOutside>
        </Dropdown>
      )}
    </>
  )
}

Filter.propTypes = {
  isTransparentBg: PropTypes.bool,
  changeFilter: PropTypes.func.isRequired,
  sources: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  sourceValue: PropTypes.any.isRequired,
  categoryValue: PropTypes.any.isRequired,
  filterStartDate: PropTypes.string.isRequired,
  filterEndDate: PropTypes.string,
  time: PropTypes.string.isRequired,
  height: PropTypes.number,
  position: PropTypes.string,
  isShowNewType: PropTypes.bool,
}

Filter.defaultProps = {
  isTransparentBg: false,
  position: positionDropdown.BOTTOM_RIGHT_LEFT,
  isShowNewType: true,
}
