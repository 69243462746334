import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Input } from '../../common/html/Input'
import TextEllipsis from '../../common/textEllipsis'
import { tableKeys } from '../constants'
import style from '../index.module.css'
import {
  hideEditWatchlist,
  selectEditKey,
  selectWatchlistsById,
} from '../store/slice'
import { updateMasterWatchListThunk } from '../store/thunk'

const errors = {
  MAX: 'watchlist.watchlistTable.validate.NAME_MAX',
  EXIST_NAME: 'watchlist.watchlistTable.validate.NAME_DUPLICATE',
}

const enterKey = 13

const WatchlistEdit = ({ value, tableKey, id }) => {
  const dispatch = useDispatch()

  const editKey = useSelector(selectEditKey(id))
  const watchlistById = useSelector(selectWatchlistsById)

  const inputRef = useRef()

  const [valueInput, setValueInput] = useState(value)
  const [error, setError] = useState('')

  const isName = tableKey === tableKeys.NAME

  useEffect(() => {
    if (tableKey === editKey) inputRef.current.focus()
  }, [tableKey, editKey])

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      handleBlurInput()
    }
  }

  const handleBlurInput = () => {
    if (!valueInput.trim() || error || valueInput.trim() === value) {
      dispatch(hideEditWatchlist(id))
      setValueInput(value)
      setError('')
    } else
      dispatch(
        updateMasterWatchListThunk({
          watchListId: +id,
          watchListName:
            tableKey === tableKeys.NAME
              ? valueInput.trim()
              : watchlistById[id].watchListName,
          description:
            tableKey === tableKeys.DESCRIPTION
              ? valueInput.trim()
              : watchlistById[id].description,
        }),
      )
  }

  const handleChangeInput = (e) => {
    setValueInput(e.target.value)
    if (tableKey === tableKeys.NAME) {
      if (e.target.value.trim().length > 100) setError(errors.MAX)
      else if (
        Object.keys(watchlistById).some(
          (key) =>
            watchlistById[key].watchListName === e.target.value.trim() &&
            +key !== +id,
        )
      )
        setError(errors.EXIST_NAME)
      else setError('')
    }
  }

  return (
    <div className={isName ? style.tdName : style.tdDescription}>
      {tableKey === editKey ? (
        <div className={`hover-dropdown w-100 ${style.hoverDropdownCus}`}>
          <Input
            onBlur={handleBlurInput}
            onKeyDown={handlePressEnter}
            onChange={handleChangeInput}
            ref={inputRef}
            className={style.input}
            type="text"
            value={valueInput}
            autoFocus
          />
          {error && (
            <div className="dropdown-container">
              {<Translate value={error} />}
            </div>
          )}
        </div>
      ) : (
        <div className={`${style.margin8} ${style.widthFieldEdit}`}>
          <TextEllipsis isI18n={false} text={value} />
        </div>
      )}
    </div>
  )
}

WatchlistEdit.propTypes = {
  value: PropTypes.string.isRequired,
  tableKey: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default WatchlistEdit
