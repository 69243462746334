import React, { useEffect, useState } from 'react'
import { ReactComponent as ScrollLeftIcon } from './iconScrollLeft.svg'
import { ReactComponent as ScrollRightIcon } from './iconScrollRight.svg'
import style from './style.module.css'

const ScrollComponentWithNextBack = ({
  children,
  scrollbarsRef,
  iconLeftScrollbarsRef,
  iconRightScrollbarsRef,
}) => {
  const [isShow, setIsShow] = useState(true)

  const scrollToLeft = () => {
    scrollbarsRef.current.scrollToLeft()
  }

  const scrollToRight = () => {
    scrollbarsRef.current.scrollToRight()
  }

  useEffect(() => {
    if (
      scrollbarsRef?.current?.getClientWidth() >=
      scrollbarsRef?.current?.getScrollWidth()
    ) {
      setIsShow(false)
    } else {
      setIsShow(true)
    }
  }, [
    scrollbarsRef?.current?.getScrollWidth(),
    scrollbarsRef?.current?.getClientWidth(),
  ])

  return (
    <div className="position-relative">
      {isShow && (
        <ScrollLeftIcon
          ref={iconLeftScrollbarsRef}
          className={style.iconArrowLeft}
          onClick={scrollToLeft}
        />
      )}
      {children}
      {isShow && (
        <ScrollRightIcon
          ref={iconRightScrollbarsRef}
          className={style.iconArrowRight}
          onClick={scrollToRight}
        />
      )}
    </div>
  )
}

export default ScrollComponentWithNextBack
