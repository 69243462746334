import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../common/loading'
import { ScrollComponent } from '../../common/ScrollComponent'
import { PanelContainer } from '../common/panel/PanelContainer'
import { selectChartLibrary, selectLoadingChartLibrary } from '../store/slice'
import { Header } from './Header'
import PanelChartLibrary from './PanelChartLibrary'

export const MyChartLibrary = () => {
  const chartLibrary = useSelector(selectChartLibrary)
  const isLoading = useSelector(selectLoadingChartLibrary)

  const [isSelect, setIsSelect] = useState(false)
  const [chartsChecked, setChartsChecked] = useState([])

  const handleCheckAll = (checked) => {
    if (checked) {
      setChartsChecked(chartLibrary)
    } else {
      setChartsChecked([])
    }
  }

  return (
    <div className="flex-column h-100">
      <Header
        chartLibrary={chartLibrary}
        isSelect={isSelect}
        setIsSelect={setIsSelect}
        handleCheckAll={handleCheckAll}
        chartsChecked={chartsChecked}
      />
      {isLoading ? (
        <Loading isBlack />
      ) : (
        <div className="flex-1">
          <ScrollComponent>
            <PanelContainer
              hasAddNewChart={false}
              appendStyle={{ padding: '8px 8px 0px' }}
            >
              {[...chartLibrary].map((item, index) => (
                <PanelChartLibrary
                  key={index}
                  item={item}
                  index={index}
                  isShowCheckbox={isSelect}
                  chartsChecked={chartsChecked}
                  setChartsChecked={setChartsChecked}
                />
              ))}
            </PanelContainer>
          </ScrollComponent>
        </div>
      )}
    </div>
  )
}
