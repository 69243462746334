import { GroupedBarLineChart } from '../../common/GroupedBarLineChart'
import { LineChart } from '../../common/LineChart'
import { StackedBarLineChart } from '../../common/StackedBarLineChart'
import {
  getEfficiencyChart,
  getGrowthChart,
  getProfitabilityChart,
  getStrengthChart,
  getValuationChart,
} from './store/thunk'

export const priceChartTab = {
  PROFITABILITY: 'Profitability  ',
  STRENGTH: 'Strength',
  EFFICIENCY: 'Efficiency',
  VALUATION: 'Valuation',
  GROWTH: 'Growth',
}

export const chartType = {
  STACK: 'stack',
  GROUP: 'group',
  LINE: 'line',
}

export const priceChartTabThunkFunc = {
  [priceChartTab.EFFICIENCY]: (params) => getEfficiencyChart(params),
  [priceChartTab.GROWTH]: (params) => getGrowthChart(params),
  [priceChartTab.PROFITABILITY]: (params) => getProfitabilityChart(params),
  [priceChartTab.STRENGTH]: (params) => getStrengthChart(params),
  [priceChartTab.VALUATION]: (params) => getValuationChart(params),
}

export const priceChartTabItems = {
  [priceChartTab.PROFITABILITY]: [
    {
      chartNumber: 1,
      chartName: 'sector.financialAnalysis.corporate.GROSS_PROFIT_MARGIN',
      leftLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getGrossProfitMarginChart',
      dataFormat: {
        rT0050: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0050: '#0096eb',
        },
        xAxisKey: 'label',
        barKeys: ['rT0050'],
        mappingDisplayName: {
          rT0050: 'sector.financialAnalysis.corporate.GROSS_PROFIT_MARGIN',
        },
        unit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 2,
      chartName: 'sector.financialAnalysis.corporate.EBITDA_MARGIN',
      leftLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getEbitdaMarginChart',
      dataFormat: {
        rT0051: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0051: '#f7959c',
        },
        xAxisKey: 'label',
        barKeys: ['rT0051'],
        mappingDisplayName: {
          rT0051: 'sector.financialAnalysis.corporate.EBITDA_MARGIN',
        },
        unit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 3,
      chartName: 'sector.financialAnalysis.corporate.NET_PROFIT_MARGIN',
      leftLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getNetProfitMarginChart',
      dataFormat: {
        rT0054: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0054: '#facc5c',
        },
        xAxisKey: 'label',
        barKeys: ['rT0054'],
        mappingDisplayName: {
          rT0054: 'sector.financialAnalysis.corporate.NET_PROFIT_MARGIN',
        },
        unit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
  ],
  [priceChartTab.STRENGTH]: [
    {
      chartNumber: 1,
      chartName: 'sector.financialAnalysis.corporate.WORKING_CAPITAL',
      leftLabel: 'sector.financialAnalysis.common.CHART_BILLION_VND',
      rightLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getWorkingCapitalChart',
      dataFormat: {
        rT0001: 10 ** 9,
        rT0018: 1,
        rT0020: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0001: '#facc5c',
          rT0018: '#45b29d',
          rT0020: '#df5a49',
        },
        xAxisKey: 'label',
        barKeys: ['rT0001'],
        lineKeys: ['rT0018', 'rT0020'],
        mappingDisplayName: {
          rT0001: 'sector.financialAnalysis.corporate.NET_WORKING_CAPITAL',
          rT0018: 'sector.financialAnalysis.corporate.CASH_RATIO',
          rT0020: 'sector.financialAnalysis.corporate.CURRENT_RATIO',
        },
        unit: 'sector.financialAnalysis.common.BILLION',
        lineUnit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 2,
      chartName: 'sector.financialAnalysis.corporate.COVERAGE_RATIO',
      leftLabel: 'sector.financialAnalysis.corporate.CHART_RATIO',
      rightLabel: 'sector.financialAnalysis.corporate.CHART_EBITDA_DEBT',
      component: GroupedBarLineChart,
      chartType: chartType.GROUP,
      thunkFuncName: 'getCoverageRatioChart',
      dataFormat: {
        rT0044: 1,
        rT0045: 1,
        ebitdaDebt: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0044: '#1d97ef',
          rT0045: '#f7959c',
          ebitdaDebt: '#ecffdf',
        },
        xAxisKey: 'label',
        barKeys: ['rT0044', 'rT0045'],
        lineKeys: ['ebitdaDebt'],
        mappingDisplayName: {
          rT0044: 'sector.financialAnalysis.corporate.INTEREST_COVERAGE_RATIO',
          rT0045: 'sector.financialAnalysis.corporate.ASSET_COVERAGE_RATIO',
          ebitdaDebt: 'sector.financialAnalysis.corporate.EBITDA_DEBT',
        },
        unit: 'sector.financialAnalysis.common.TIMES',
        lineUnit: 'sector.financialAnalysis.common.TIMES',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 3,
      chartName: 'sector.financialAnalysis.corporate.FINANCIAL_LEVERAGE',
      leftLabel: 'sector.financialAnalysis.corporate.CHART_FINANCIAL_LEVERAGE',
      rightLabel: 'sector.financialAnalysis.corporate.CHART_DEBT_EQUITY',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getFinancialLeverageChart',
      dataFormat: {
        rT0035: 1,
        rT0027: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0035: '#02a2b7',
          rT0027: '#facc5c',
        },
        xAxisKey: 'label',
        barKeys: ['rT0035'],
        lineKeys: ['rT0027'],
        mappingDisplayName: {
          rT0035: 'sector.financialAnalysis.corporate.FINANCIAL_LEVERAGE_RATIO',
          rT0027: 'sector.financialAnalysis.corporate.DEBT_EQUITY',
        },
        unit: 'sector.financialAnalysis.common.TIMES',
        lineUnit: 'sector.financialAnalysis.common.TIMES',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
  ],
  [priceChartTab.EFFICIENCY]: [
    {
      chartNumber: 1,
      chartName: 'sector.financialAnalysis.corporate.ROA_ROE',
      leftLabel: '(%)',
      component: GroupedBarLineChart,
      chartType: chartType.GROUP,
      thunkFuncName: 'getRoaRoeChart',
      dataFormat: {
        rT0059: 0.01,
        rT0060: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0059: '#02a2b7',
          rT0060: '#facc5c',
        },
        xAxisKey: 'label',
        barKeys: ['rT0059', 'rT0060'],
        mappingDisplayName: {
          rT0059: 'sector.financialAnalysis.corporate.ROA',
          rT0060: 'sector.financialAnalysis.corporate.ROE',
        },
        unit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 2,
      chartName: 'sector.financialAnalysis.corporate.DUPONT_ANALYSIS',
      leftLabel: 'sector.financialAnalysis.corporate.CHART_ASSET_NET',
      rightLabel: 'sector.financialAnalysis.corporate.CHART_FINANCIAL_LEVERAGE',
      component: GroupedBarLineChart,
      chartType: chartType.GROUP,
      thunkFuncName: 'getDupontAnalysisChart',
      dataFormat: {
        rT0003: 1,
        rT0054: 1,
        rT0035: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0003: '#1d97ef',
          rT0054: '#f7959c',
          rT0035: '#ecffdf',
        },
        xAxisKey: 'label',
        barKeys: ['rT0003', 'rT0054'],
        lineKeys: ['rT0035'],
        mappingDisplayName: {
          rT0003: 'sector.financialAnalysis.corporate.ASSET_TURNOVER',
          rT0054: 'sector.financialAnalysis.corporate.NET_PROFIT_MARGIN',
          rT0035: 'sector.financialAnalysis.corporate.FINANCIAL_LEVERAGE',
        },
        unit: 'sector.financialAnalysis.common.TIMES',
        lineUnit: 'sector.financialAnalysis.common.TIMES',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 3,
      chartName: 'sector.financialAnalysis.corporate.ASSET_MANAGEMENT',
      leftLabel: '(%)',
      rightLabel: 'sector.financialAnalysis.common.CHART_DAYS',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getAssetManagementChart',
      dataFormat: {
        rT0003: 0.01,
        rT0015: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0003: '#185999',
          rT0015: '#facc5c',
        },
        xAxisKey: 'label',
        barKeys: ['rT0003'],
        lineKeys: ['rT0015'],
        mappingDisplayName: {
          rT0003: 'sector.financialAnalysis.corporate.ASSET_TURNOVER',
          rT0015: 'sector.financialAnalysis.corporate.CASH_CONVERSION_CYCLE',
        },
        unit: '%',
        lineUnit: 'sector.financialAnalysis.common.DAYS',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
  ],
  [priceChartTab.VALUATION]: [
    {
      chartNumber: 1,
      chartName: 'sector.financialAnalysis.corporate.PE',
      component: LineChart,
      chartType: chartType.LINE,
      thunkFuncName: 'getPEChart',
      dataFormat: {
        rtD0022: 1,
        indexRTD0022: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rtD0022: '#1d97ef',
          indexRTD0022: '#f7959c',
        },
        xAxisKey: 'label',
        leftKeys: ['rtD0022', 'indexRTD0022'],
        mappingDisplayName: {
          rtD0022: 'sector.financialAnalysis.corporate.SECTOR_MEDIAN_PE',
          indexRTD0022: 'sector.financialAnalysis.corporate.INDEX_MEDIAN_PE',
        },
        isDisplayDot: false,
      },
    },
    {
      chartNumber: 2,
      chartName: 'sector.financialAnalysis.corporate.PB',
      component: LineChart,
      chartType: chartType.LINE,
      thunkFuncName: 'getPBChart',
      dataFormat: {
        rtD0019: 1,
        indexRTD0019: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rtD0019: '#45b29d',
          indexRTD0019: '#df5a49',
        },
        xAxisKey: 'label',
        leftKeys: ['rtD0019', 'indexRTD0019'],
        mappingDisplayName: {
          rtD0019: 'sector.financialAnalysis.corporate.SECTOR_MEDIAN_PB',
          indexRTD0019: 'sector.financialAnalysis.corporate.INDEX_MEDIAN_PB',
        },
        isDisplayDot: false,
      },
    },
    {
      chartNumber: 3,
      chartName: 'sector.financialAnalysis.corporate.EV_EBITDA',
      component: LineChart,
      chartType: chartType.LINE,
      thunkFuncName: 'getEvEbitdaChart',
      dataFormat: {
        rtD0027: 1,
        indexRTD0027: 1,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rtD0027: '#facc5c',
          indexRTD0027: '#185999',
        },
        xAxisKey: 'label',
        leftKeys: ['rtD0027', 'indexRTD0027'],
        mappingDisplayName: {
          rtD0027: 'sector.financialAnalysis.corporate.SECTOR_MEDIAN_EV_EBITDA',
          indexRTD0027:
            'sector.financialAnalysis.corporate.INDEX_MEDIAN_EV_EBITDA',
        },
        isDisplayDot: false,
      },
    },
  ],
  [priceChartTab.GROWTH]: [
    {
      chartNumber: 1,
      chartName: 'sector.financialAnalysis.corporate.TOTAL_ASSET_GROWTH',
      leftLabel: 'sector.financialAnalysis.common.CHART_BILLION_VND',
      rightLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getTotalAssetGrowthChart',
      dataFormat: {
        bS64: 10 ** 9,
        rT0172_A: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          bS64: '#1d97ef',
          rT0172_A: '#facc5c',
        },
        xAxisKey: 'label',
        barKeys: ['bS64'],
        lineKeys: ['rT0172_A'],
        mappingDisplayName: {
          bS64: 'sector.financialAnalysis.corporate.TOTAL_ASSET',
          rT0172_A: 'sector.financialAnalysis.corporate.GROWTH',
        },
        unit: 'sector.financialAnalysis.common.BILLION',
        lineUnit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 2,
      chartName: 'sector.financialAnalysis.corporate.NET_DEBT_GROWTH',
      leftLabel: 'sector.financialAnalysis.common.CHART_BILLION_VND',
      rightLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getNetDeptGrowthChart',
      dataFormat: {
        rT0040: 10 ** 9,
        rT0168_A: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          rT0040: '#f7959c',
          rT0168_A: '#ecffdf',
        },
        xAxisKey: 'label',
        barKeys: ['rT0040'],
        lineKeys: ['rT0168_A'],
        mappingDisplayName: {
          rT0040: 'sector.financialAnalysis.corporate.NET_DEBT',
          rT0168_A: 'sector.financialAnalysis.corporate.GROWTH',
        },
        unit: 'sector.financialAnalysis.common.BILLION',
        lineUnit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
    {
      chartNumber: 3,
      chartName: 'sector.financialAnalysis.corporate.REVENUE_GROWTH',
      leftLabel: 'sector.financialAnalysis.common.CHART_BILLION_VND',
      rightLabel: '(%)',
      component: StackedBarLineChart,
      chartType: chartType.STACK,
      thunkFuncName: 'getEbitdaGrowthChart',
      dataFormat: {
        iS3: 10 ** 9,
        rT0156_A: 0.01,
      },
      size: (resizeSize) => ({
        width: resizeSize.width,
        height: resizeSize.height,
      }),
      props: {
        fillColor: {
          iS3: '#facc5c',
          rT0156_A: '#45b29d',
        },
        xAxisKey: 'label',
        barKeys: ['iS3'],
        lineKeys: ['rT0156_A'],
        mappingDisplayName: {
          iS3: 'sector.financialAnalysis.corporate.REVENUE',
          rT0156_A: 'sector.financialAnalysis.corporate.GROWTH',
        },
        unit: 'sector.financialAnalysis.common.BILLION',
        lineUnit: '%',
        isXAxisDiv: true,
        isBreakNewLine: true,
      },
    },
  ],
}
