export const DEFAULT_SECTOR_LEVEL1_EN = {
  icbCode: '8301',
  icbId: 137,
  icbLevel: 1,
  icbName: 'Banks',
  parentICBId: 137,
}

export const DEFAULT_SECTOR_LEVEL1_VN = {
  icbCode: '8301',
  icbId: 137,
  icbLevel: 1,
  icbName: 'Ngân hàng',
  parentICBId: 137,
}

export const DEFAULT_SECTOR_LEVEL2_EN = {
  icbCode: '8300',
  icbId: 136,
  icbLevel: 2,
  icbName: 'Banks',
  parentICBId: 137,
}

export const DEFAULT_SECTOR_LEVEL2_VN = {
  icbCode: '8300',
  icbId: 136,
  icbLevel: 2,
  icbName: 'Ngân hàng',
  parentICBId: 137,
}

export const DEFAULT_SECTOR_LEVEL3_EN = {
  icbCode: '8350',
  icbId: 138,
  icbLevel: 3,
  icbName: 'Banks',
  parentICBId: 136,
}

export const DEFAULT_SECTOR_LEVEL3_VN = {
  icbCode: '8350',
  icbId: 138,
  icbLevel: 3,
  icbName: 'Ngân hàng',
  parentICBId: 136,
}

export const DEFAULT_SECTOR_LEVEL4_EN = {
  icbCode: '8355',
  icbId: 139,
  icbLevel: 4,
  icbName: 'Banks',
  parentICBId: 138,
}

export const DEFAULT_SECTOR_LEVEL4_VN = {
  icbCode: '8355',
  icbId: 139,
  icbLevel: 4,
  icbName: 'Ngân hàng',
  parentICBId: 138,
}

export const DEFAULT_SECTOR_LEVEL5_EN = {
  icbCode: '57511',
  icbId: 305,
  icbLevel: 5,
  icbName: 'Airlines',
  icbOrder: 128,
  parentICBId: 115,
}

export const DEFAULT_SECTOR_LEVEL5_VN = {
  icbCode: '57511',
  icbId: 305,
  icbLevel: 5,
  icbName: 'Hàng không',
  icbOrder: 128,
  parentICBId: 115,
}

export const MAP_DEFAULT_SECTOR = {
  1: {
    en: DEFAULT_SECTOR_LEVEL1_EN,
    vi: DEFAULT_SECTOR_LEVEL1_VN,
  },
  2: {
    en: DEFAULT_SECTOR_LEVEL2_EN,
    vi: DEFAULT_SECTOR_LEVEL2_VN,
  },
  3: {
    en: DEFAULT_SECTOR_LEVEL3_EN,
    vi: DEFAULT_SECTOR_LEVEL3_VN,
  },
  4: {
    en: DEFAULT_SECTOR_LEVEL4_EN,
    vi: DEFAULT_SECTOR_LEVEL4_VN,
  },
  5: {
    en: DEFAULT_SECTOR_LEVEL5_EN,
    vi: DEFAULT_SECTOR_LEVEL5_VN,
  },
}
