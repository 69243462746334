import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import FundNavDataService from '../../../../../core/services/fund/FundNavDataService'
import BasicChart from '../../../../common/chart/basicChart'
import UseI18n from '../../../../common/hooks/useI18n'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { Filter } from './Filter'
import { VALUE_TYPES } from './constants'
import { formatDataApi, getSchema, getYAxis } from './helpers'
import { selectFilters } from './store/slice'

export const NavCertificate = ({ id, width, height }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const yAxisText =
    filters.valueType === VALUE_TYPES.ABSOLUTE
      ? UseI18n(
          'fund.fundProfile.navData.navCertificate.NAV_CERT_Y_AXIS_NAV_CCQ_VND',
        )
      : UseI18n(
          'fund.fundProfile.navData.navCertificate.NAV_CERT_Y_AXIS_NAV_CCQ',
        )
  const yAxisIndexText = UseI18n(
    'fund.fundProfile.navData.navCertificate.NAV_CERT_Y_AXIS_INDEX',
  )

  // Get data
  const getParams = useMemo(() => {
    return {
      OrganizationId: fundInfo.organizationId,
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From: filters.timeRange === calendarTabKey ? filters.startDate : '',
      To: filters.timeRange === calendarTabKey ? filters.endDate : '',
    }
  }, [filters, fundInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <BasicChart
                id={id}
                width={width}
                height={height - size.height}
                params={getParams}
                serviceApi={(params) =>
                  FundNavDataService.getListNAVCertificate(params)
                }
                formatDataApi={(data) =>
                  formatDataApi({ data, valueType: filters.valueType, locale })
                }
                schema={getSchema({ fundInfo, filters })}
                chartProps={{
                  chartType: 'ComposedChart',
                  yAxis: getYAxis({ filters, yAxisIndexText, yAxisText }),
                }}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
