import { useSelector } from 'react-redux'
import { BalanceOfPayment } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { selectCountLimit, selectCurrentTypeStatistics } from './store/slice'
import { downloadBalanceOfPayment } from './store/thunk'

export const PanelBalanceOfPayment = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentDataType = useSelector(selectCurrentTypeStatistics)
  const countLimit = useSelector(selectCountLimit)

  return (
    <Panel
      title="economy.paymentBalance.paymentBalance.BALANCE_OF_PAYMENT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadBalanceOfPayment({
          DataType: currentDataType,
          Limit: countLimit,
        })
      }
    >
      <BalanceOfPayment
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
