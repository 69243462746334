import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'

import { register } from '../../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valDivMillion,
  valToPercent,
} from '../../../../../utils/Value'
import { getKeyRatios } from './thunk'

const initialState = {
  ids: [],
  valueById: {},
  groupColumns: [],
  levelCollapse: {},
  isLoading: true,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/keyRatios',
  initialState,
  reducers: {
    setRatioKeyById: (state, action) => {
      state.valueById = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
      state.levelCollapse = keyBy(
        action.payload.map((v) => ({
          index: v.index,
          level: v.level,
          isRowCollapse: v.isCollapse,
        })),
        'index',
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKeyRatios.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getKeyRatios.fulfilled, (state, action) => {
      const dataConvert = getUniqueListBy(convertData(action.payload), 'year')

      const groupColumns = []
      const ratioKeyArr = Object.values(state.valueById)
      dataConvert.forEach((i) => {
        groupColumns.push({
          key: i.year.toString(),
          title: i.year + (i.isTTM ? ' (TTM)' : ''),
        })
        ratioKeyArr.forEach((e) => {
          const value = i[e.key]
          e[i.year] = e.isPercent
            ? valToPercent(value)
            : e.isMillion
            ? formatVal(valDivMillion(value))
            : e.key
            ? formatVal(value)
            : ''
        })
      })

      state.groupColumns = dataConvert.length
        ? [{ key: 'name', title: '' }, ...groupColumns]
        : []
      state.isLoading = false
    })
    builder.addCase(getKeyRatios.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectValueById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueById[id], attr + '')
}
export const selectItemData = (id) => (state) => state[slice.name].valueById[id]
export const selectLoading = (state) => state[slice.name].isLoading

export const { setRatioKeyById, changeIds } = slice.actions

register(slice.name, slice.reducer)

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const convertData = (arr) => {
  let result = []
  arr.forEach((element) => {
    const object = arr
      .filter((i) => i.year === element.year)
      .reduce(
        (obj, item) =>
          Object.assign(obj, {
            icbId: item.icbId,
            year: item.year,
            isTTM: item.isTTM,
            [item.fieldName]: item.value,
          }),
        {},
      )
    result.push(object)
  })
  return result
}
