import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot, valDivBillion } from '../../../../../../utils/Value'
import { formatDataDetailMetrics } from '../detailMetrics/helper'
import {
  getBankDepositCasa,
  getBankDepositCasaMarketShare,
  getBankDepositChangeInMarketShare,
  getBankDepositDetailMetrics,
  getBankDepositFromCustomer,
  getBankDepositMarketShare,
} from './thunk'

const initialState = {
  loading: {
    depositFromCustomer: true,
    marketShare: true,
    changeInMarketShare: true,
    casa: true,
    casaMarketShare: true,
    detailMetrics: true,
  },
  data: {
    depositFromCustomer: [],
    marketShare: [],
    changeInMarketShare: [],
    casa: [],
    casaMarketShare: [],
    detailMetricsData: {},
    detailMetricsTickers: [],
  },
  reCalcWiths: false,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/bank/deposit',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    //DepositFromCustomer
    builder.addCase(getBankDepositFromCustomer.pending, (state) => {
      state.loading.depositFromCustomer = true
    })
    builder.addCase(getBankDepositFromCustomer.fulfilled, (state, action) => {
      state.data.depositFromCustomer =
        action.payload?.map((item) => ({
          ...item,
          bS158: valDivBillion(item.bS158),
          ldr: item.ldr * 100,
        })) || []
      state.loading.depositFromCustomer = false
    })
    builder.addCase(getBankDepositFromCustomer.rejected, (state, action) => {
      state.loading.depositFromCustomer = action.payload.loading
    })
    //MarketShare
    builder.addCase(getBankDepositMarketShare.pending, (state) => {
      state.loading.marketShare = true
    })
    builder.addCase(getBankDepositMarketShare.fulfilled, (state, action) => {
      state.data.marketShare = action.payload || []
      state.loading.marketShare = false
    })
    builder.addCase(getBankDepositMarketShare.rejected, (state, action) => {
      state.loading.marketShare = action.payload.loading
    })
    //ChangeInMarketShare
    builder.addCase(getBankDepositChangeInMarketShare.pending, (state) => {
      state.loading.changeInMarketShare = true
    })
    builder.addCase(
      getBankDepositChangeInMarketShare.fulfilled,
      (state, action) => {
        state.data.changeInMarketShare =
          action.payload?.map((item) => ({
            ...item,
            c5: item.c5 * 100,
          })) || []
        state.loading.changeInMarketShare = false
      },
    )
    builder.addCase(
      getBankDepositChangeInMarketShare.rejected,
      (state, action) => {
        state.loading.changeInMarketShare = action.payload.loading
      },
    )
    //Casa
    builder.addCase(getBankDepositCasa.pending, (state) => {
      state.loading.casa = true
    })
    builder.addCase(getBankDepositCasa.fulfilled, (state, action) => {
      state.data.casa =
        action.payload?.map((item) => ({
          ...item,
          nB121: valDivBillion(item.nB121),
          casar: item.casar * 100,
        })) || []
      state.loading.casa = false
    })
    builder.addCase(getBankDepositCasa.rejected, (state, action) => {
      state.loading.casa = action.payload.loading
    })
    //CasaMarketShare
    builder.addCase(getBankDepositCasaMarketShare.pending, (state) => {
      state.loading.casaMarketShare = true
    })
    builder.addCase(
      getBankDepositCasaMarketShare.fulfilled,
      (state, action) => {
        state.data.casaMarketShare = action.payload || []
        state.loading.casaMarketShare = false
      },
    )
    builder.addCase(getBankDepositCasaMarketShare.rejected, (state, action) => {
      state.loading.casaMarketShare = action.payload.loading
    })
    //DetailMetrics
    builder.addCase(getBankDepositDetailMetrics.pending, (state) => {
      state.loading.detailMetrics = true
    })
    builder.addCase(getBankDepositDetailMetrics.fulfilled, (state, action) => {
      const { data, tickers } = formatDataDetailMetrics(action.payload || [])
      state.data.detailMetricsData = data
      state.data.detailMetricsTickers = tickers
      state.reCalcWiths = !state.reCalcWiths
      state.loading.detailMetrics = false
    })
    builder.addCase(getBankDepositDetailMetrics.rejected, (state, action) => {
      state.loading.detailMetrics = action.payload.loading
    })
  },
})

export const selectLoadingDepositFromCustomer = (state) =>
  state[slice.name].loading.depositFromCustomer
export const selectLoadingMarketShare = (state) =>
  state[slice.name].loading.marketShare
export const selectLoadingChangeInMarketShare = (state) =>
  state[slice.name].loading.changeInMarketShare
export const selectLoadingCasa = (state) => state[slice.name].loading.casa
export const selectLoadingCasaMarketShare = (state) =>
  state[slice.name].loading.casaMarketShare
export const selectLoadingDetailMetrics = (state) =>
  state[slice.name].loading.detailMetrics

export const selectDataDepositFromCustomer = (state) =>
  state[slice.name].data.depositFromCustomer
export const selectDataMarketShare = (state) =>
  state[slice.name].data.marketShare
export const selectDataChangeInMarketShare = (state) =>
  state[slice.name].data.changeInMarketShare
export const selectDataCasa = (state) => state[slice.name].data.casa
export const selectDataCasaMarketShare = (state) =>
  state[slice.name].data.casaMarketShare
export const selectDetailMetricsTickers = (state) =>
  state[slice.name].data.detailMetricsTickers
export const selectDataDetailMetrics = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.detailMetricsData[id], attr)

export const selectReCalcWiths = (state) => state[slice.name].reCalcWiths

export const { resetStore } = slice.actions

register(slice.name, slice.reducer)
