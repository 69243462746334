import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  changeCheckTicker,
  selectActiveSheet,
  selectListSheet,
  selectTickerCell,
} from '../store/slice'

const Checkbox = ({ idTicker, colId }) => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const value = useSelector(selectTickerCell(activeSheet)(idTicker, colId))
  const listSheet = useSelector(selectListSheet)

  const handleChange = () => {
    dispatch(changeCheckTicker(idTicker))
  }

  const isFiinXTemplate = !!listSheet.find(
    (sheet) => sheet.value === activeSheet,
  )?.templateGroup

  return (
    <div className="d-flex justify-content-center">
      <input
        onChange={handleChange}
        type="checkbox"
        className={`checkbox ${isFiinXTemplate ? style.disabled : ''}`}
        checked={value}
        disabled={isFiinXTemplate}
      />
    </div>
  )
}

export default Checkbox
