import { VIEW_BY } from '../constants'

export const getBarChartName = (tab) => {
  const basePath = 'corporate.businessModel.penetrationAnalysis.'

  switch (tab) {
    case VIEW_BY.MARGIN_LENDING:
      return basePath + 'MARGIN_LENDING_CHANGE_IN_MARKET_SHARE'
    case VIEW_BY.PROPRIETARY:
      return basePath + 'PROPRIETARY_CHANGE_IN_MARKET_SHARE'
    case VIEW_BY.TREASURY:
      return basePath + 'TREASURY_CHANGE_IN_MARKET_SHARE'
    default:
      return ''
  }
}
