import { I18n } from 'react-redux-i18n'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../../common/chart/footer/Item'

const Lengend = ({ width }) => {
  const checkFooter = [
    {
      text: I18n.t('bond.interconnectionMap.chart.lengend.OwnerofMainIssuer'),
      type: TYPE.LINE,
      before: {
        bgColor: '#0096EB',
      },
    },
    {
      text: I18n.t(
        'bond.interconnectionMap.chart.lengend.Individualconcurrent',
      ),
      type: TYPE.LINE,
      before: {
        bgColor: '#9747FF',
      },
    },
    {
      text: I18n.t('bond.interconnectionMap.chart.lengend.Paymentguarantee'),
      type: TYPE.LINE,
      before: {
        bgColor: '#00E5E4',
      },
    },
    {
      text: I18n.t('bond.interconnectionMap.chart.lengend.RelationshipL1'),
      type: TYPE.LINE,
      before: {
        bgColor: '#FACC5C',
      },
    },
    {
      text: I18n.t('bond.interconnectionMap.chart.lengend.RelationshipL2'),
      type: TYPE.LINE,
      before: {
        bgColor: '#A6CF98',
      },
    },
    {
      text: I18n.t('bond.interconnectionMap.chart.lengend.RelationshipL3'),
      type: TYPE.LINE,
      before: {
        bgColor: '#DF5A49',
      },
    },
  ]
  return (
    <div
      style={{
        position: 'absolute',
        width: '100%',
        bottom: 0,
        background: '#1e242e',
      }}
    >
      <FooterContainer
        key={width}
        data={checkFooter}
        listCheckbox={[]}
        // setListCheckbox={setListCheckbox}
        setListCheckbox={() => {}}
        numItemPerRow={6}
      />
    </div>
  )
}

export default Lengend
