import { TYPE } from '../../../../common/chart/footer/Item'
import { PIE_CHART_COLORS, STACK_COLORS } from '../../constants'

export const getFooterData = (chartData) => {
  return chartData.map((item) => ({
    text: item.text,
    type: TYPE.CIRCLE,
    before: {
      bgColor: item.color,
    },
  }))
}

export const formatChartData = (chartData) => {
  return chartData.map((item, index) => {
    if (
      item?.nationalAccountVNTypeName === 'Khác' ||
      item?.nationalAccountVNTypeName === 'Other'
    ) {
      return {
        value: item?.gdpCompositionPercentage,
        color: PIE_CHART_COLORS.other,
        text: item?.nationalAccountVNTypeName,
      }
    }
    return {
      value: item?.gdpCompositionPercentage,
      color: PIE_CHART_COLORS.color[index],
      text: item?.nationalAccountVNTypeName,
    }
  })
}

export const getTooltipContentData = (stackKey, payload) => {
  return stackKey?.map((item) => {
    return {
      label: item.nationalAccountVNTypeName,
      value: payload[item.nationalAccountVNTypeId],
    }
  })
}

export const renderListFooter = (stackKey) => {
  const MAX_STACK = 6
  const dataLength = stackKey.length > MAX_STACK ? MAX_STACK : stackKey.length
  return stackKey.map((item, index) => {
    return {
      text: item.nationalAccountVNTypeName || 'Other',
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS?.[dataLength]?.[index],
      },
      isI18n: false,
    }
  })
}

export const handlePieChartData = (data) => {
  if (!data.length) {
    return data
  }

  const top5Indicator = data.slice(0, 5)
  const totalTop5 = top5Indicator.reduce(
    (prev, cur) => {
      return {
        gdpCompositionPercentage:
          prev.gdpCompositionPercentage + cur.gdpCompositionPercentage,
      }
    },
    { gdpCompositionPercentage: 0 },
  )
  const otherIndicator = {
    nationalAccountVNTypeId: 'Other',
    nationalAccountVNTypeName: 'Khác',
    gdpCompositionPercentage: 1 - totalTop5.gdpCompositionPercentage,
  }

  return [...top5Indicator, otherIndicator]
}

export const selectTop5Indicator = (data) => {
  if (!data.length) {
    return data
  }

  return data.slice(0, 5)
}

export const getTypeNameById = (data, dataTypeName, locale) => {
  return data.map((item) => {
    if (item.nationalAccountVNTypeId === 'Other') {
      return {
        ...item,
        nationalAccountVNTypeName: locale === 'vi' ? 'Khác' : 'Other',
      }
    }
    const typeName = dataTypeName.find(
      (data) => data.nationalAccountVNTypeId === item.nationalAccountVNTypeId,
    )
    return {
      ...item,
      nationalAccountVNTypeName: typeName?.nationalAccountVNTypeName || '',
    }
  })
}

export const handleStackedData = (data, args) => {
  if (!data.length) {
    return data
  }

  const { isValue } = args
  const quarterYear = []
  const chartData = []
  data.forEach(({ year, quarter }) => {
    const keyQuarterYear = `${quarter}-${year}`
    if (!quarterYear.includes(keyQuarterYear)) {
      quarterYear.push(keyQuarterYear)
      chartData.push(defineStackedChart(data, quarter, year, isValue))
    }
  })

  return chartData.sort(sortByQuarterYear)
}

const sortByQuarterYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.quarter > b.quarter) {
      return 1
    }

    if (a.quarter < b.quarter) {
      return -1
    }
  }

  return 0
}

const defineStackedChart = (data, quarter, year, isValue) => {
  const itemWithQuarterYear = getItemByQuarterYear(data, quarter, year)

  const dataWithoutOtherItem = itemWithQuarterYear.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.nationalAccountVNTypeId]: cur.gdpCompositionPercentage * 100,
    }
  }, {})

  return {
    ...dataWithoutOtherItem,
    Other: calOtherPercent(data, quarter, year),
    quarterYear: defineXAxisTitle(quarter, year, isValue),
    year,
    quarter,
  }
}

const calOtherPercent = (data, quarter, year) => {
  const itemWithQuarterYear = getItemByQuarterYear(data, quarter, year)

  const percentWithoutOtherItem = itemWithQuarterYear.reduce(
    (prev, cur) => {
      return {
        gdpCompositionPercentage:
          prev.gdpCompositionPercentage + cur.gdpCompositionPercentage,
      }
    },
    { gdpCompositionPercentage: 0 },
  )

  return 100 - 100 * percentWithoutOtherItem.gdpCompositionPercentage
}

const getItemByQuarterYear = (data, quarter, year) => {
  return data.filter((item) => item.year === year && item.quarter === quarter)
}

const defineXAxisTitle = (quarter, year, isValue) => {
  if (!isValue) {
    return quarter === 1 ? `Q${quarter}-${year}` : `${quarter}Q-${year}`
  }

  return `Q${quarter}-${year}`
}
