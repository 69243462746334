import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isInValidValue } from '../../../../utils/Value'
import style from '../index.module.css'
import {
  changeValueDetailPopupIndicator,
  selectValueDetailPopupIndicator,
} from '../store/slice'

const ItemRadio = ({
  defaultCheck,
  keyRadio,
  valueRadio,
  valueUncheckRadio,
  textRadio,
  disabled,
  renderContent,
  isCheckbox,
  isJbContent,
  isError,
  isOrange,
}) => {
  const dispatch = useDispatch()

  const value = useSelector(selectValueDetailPopupIndicator(keyRadio))

  useEffect(() => {
    if (defaultCheck && isInValidValue(value)) {
      dispatch(
        changeValueDetailPopupIndicator({ key: keyRadio, value: valueRadio }),
      )
    }
  }, [keyRadio])

  const handleChange = () => {
    if (!disabled) {
      if (isCheck && isCheckbox) {
        dispatch(
          changeValueDetailPopupIndicator({
            key: keyRadio,
            value: valueUncheckRadio,
          }),
        )
      } else {
        dispatch(
          changeValueDetailPopupIndicator({ key: keyRadio, value: valueRadio }),
        )
      }
    }
  }

  const isCheck = value === valueRadio

  return (
    <div
      className={`d-flex ali-center ${isJbContent ? 'j-b' : ''} ${
        style.radioContainer
      }`}
    >
      <span
        className={`d-flex ali-center cursor-pointer ${
          disabled ? style.disabled : ''
        }`}
        onClick={handleChange}
      >
        {isCheckbox ? (
          <>
            <input
              type="checkbox"
              className={`checkbox cursor-pointer ${style.checkbox} ${
                isError ? style.checkboxError : ''
              }`}
              checked={isCheck}
              readOnly
            />
            {textRadio && (
              <span className={style.textCheckbox}>{textRadio}</span>
            )}
          </>
        ) : (
          <>
            <input
              checked={isCheck}
              type="radio"
              className={`radiobox radiobox2 cursor-pointer ${style.radio} ${
                isOrange ? style.radioOrange : ''
              }`}
              readOnly
            />
            {textRadio && (
              <span
                className={
                  isCheck && !isOrange
                    ? style.activeCheck
                    : isCheck
                    ? style.activeCheckOrange
                    : ''
                }
              >
                {textRadio}
              </span>
            )}
          </>
        )}
      </span>
      {renderContent && renderContent(!isCheck || disabled)}
    </div>
  )
}

ItemRadio.propTypes = {
  defaultCheck: PropTypes.bool,
  keyRadio: PropTypes.string.isRequired,
  valueRadio: PropTypes.any.isRequired,
  textRadio: PropTypes.string,
  disabled: PropTypes.bool,
  renderContent: PropTypes.func,
  isCheckbox: PropTypes.bool,
  isJbContent: PropTypes.bool,
  isError: PropTypes.bool,
  isOrange: PropTypes.bool,
}

ItemRadio.defaultProps = {
  defaultCheck: false,
  disabled: false,
  isCheckbox: false,
  isJbContent: false,
  isError: false,
  isOrange: false,
}

export default ItemRadio
