export const bankTypes = {
  ASSET_QUALITY: 'assetQuality',
  CAPITAL_STRUCTURE: 'capitalStructure',
  PROFITABILITY: 'profitability',
}

export const securityTypes = {
  ASSET_STRUCTURE: 'assetStructure',
  PERFORMANCE: 'performance',
}

export const bankOptions = [
  {
    name: 'sector.financialAnalysis.bank.ASSET_QUALITY',
    value: bankTypes.ASSET_QUALITY,
  },
  {
    name: 'sector.financialAnalysis.bank.CAPITAL_STRUCTURE',
    value: bankTypes.CAPITAL_STRUCTURE,
  },
  {
    name: 'sector.financialAnalysis.bank.PROFITABILITY',
    value: bankTypes.PROFITABILITY,
  },
]

export const securityOptions = [
  {
    name: 'sector.financialAnalysis.security.ASSET_STRUCTURE',
    value: securityTypes.ASSET_STRUCTURE,
  },
  {
    name: 'sector.financialAnalysis.security.PERFORMANCE',
    value: securityTypes.PERFORMANCE,
  },
]

export const quarterTypeOptions = [
  {
    title: 'sector.financialAnalysis.common.ANNUAL',
    value: 5,
  },
  {
    title: 'Q1',
    value: 1,
  },
  {
    title: 'Q2',
    value: 2,
  },
  {
    title: 'Q3',
    value: 3,
  },
  {
    title: 'Q4',
    value: 4,
  },
]

export const chartYearOptions = [...new Array(5)].map((_, index) => ({
  name: (new Date().getFullYear() - index).toString(),
  value: new Date().getFullYear() - index,
}))

export const tableYearOptions = [...new Array(20)].map((_, index) => ({
  name: (new Date().getFullYear() - index).toString(),
  value: new Date().getFullYear() - index,
}))

export const timeTypes = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
}

export const timeTypeOptions = [
  {
    title: `sector.financialAnalysis.common.QUARTERLY`,
    value: timeTypes.QUARTERLY,
  },
  {
    title: `sector.financialAnalysis.common.YEARLY`,
    value: timeTypes.YEARLY,
  },
]
