import { createSlice } from '@reduxjs/toolkit'
import { register } from '../utils/ReducerRegistry'

const slice = createSlice({
  name: 'logout',
  initialState: {
    isShow: false,
  },
  reducers: {
    setIsShow: (state, action) => {
      state.isShow = action.payload
    },
  },
})

export const { setIsShow } = slice.actions
export const selectIsShow = (state) => state[slice.name].isShow

register(slice.name, slice.reducer)
