import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { invertKeyBy, keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { tableConstants } from '../constants'
import { getDataIssuePlan } from './thunk'

const nextYear = new Date()
nextYear.setFullYear(nextYear.getFullYear() + 2)

const initialState = {
  ids: [],
  initialIds: [],
  groupColumns: tableConstants,
  data: [],
  isLoading: false,
  filter: {
    FromDate: new Date(),
    EndDate: nextYear,
  },
}

export const slice = createSlice({
  name: 'demo/overview/issuesPlan',
  initialState,
  reducers: {
    updateFilter: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.key]: actions.payload.value,
      }
    },
    updateMultiFilter: (state, actions) => {
      state.filter = {
        ...state.filter,
        ...actions.payload,
      }
    },
    sortData: (state, actions) => {
      if (actions.payload.hasOwnProperty('organizationShortName')) {
        const data = state.data
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['organizationShortName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.organizationShortName.localeCompare(
              b?.organizationShortName,
              actions.payload.locale,
            ),
          )
          state.ids = idList.map((e) => e.bondId)
        }
        if (actions.payload['organizationShortName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.organizationShortName.localeCompare(
                b?.organizationShortName,
                actions.payload.locale,
              ),
            )
            .reverse()
          state.ids = idList.map((e) => e.bondId)
        }
        if (actions.payload['organizationShortName'] === undefined) {
          state.ids = state.initialIds
        }
        return
      }
      state.ids = getIdsFromProps(
        state.ids,
        state.data,
        actions.payload,
        state.initialIds,
        0,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataIssuePlan.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataIssuePlan.fulfilled, (state, action) => {
      const data = keyBy(action.payload, 'bondId')
      state.ids = action.payload.map((e) => e.bondId)
      state.initialIds = action.payload.map((e) => e.bondId)
      state.data = data
      state.isLoading = false
    })
    builder.addCase(getDataIssuePlan.rejected, (state, action) => {
      state.ids = []
      state.initialIds = []
      state.data = {}
      state.isLoading = action.payload
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectInitialData = (state) => state[slice.name].data

export const selectData = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].data[id], attr)
}
export const selectLoading = (state) => state[slice.name].isLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectFilter = (state) => state[slice.name].filter

export const { updateFilter, updateMultiFilter, sortData } = slice.actions

register(slice.name, slice.reducer)
