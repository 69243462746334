import { useDispatch } from 'react-redux'
import { updateCharts } from '../../chart/store/thunk'
import { Title } from '../../common/Title'
import { updateChart } from '../../store/slice'

export const ChartTitle = ({
  data,
  width,
  nameChart,
  isEdit,
  setIsEdit,
  updateTitle,
}) => {
  const dispatch = useDispatch()

  const onSubmitUpdateTitle = (data) => {
    if (updateTitle) {
      updateTitle({
        ...data.detail,
        nameChart: {
          ...data.detail.nameChart,
          name: data.name,
        },
      })
    } else {
      dispatch(
        updateCharts({
          ...data,
          detail: JSON.stringify({
            ...data.detail,
            nameChart: {
              ...data.detail.nameChart,
              name: data.name,
            },
          }),
          name: data.name,
        }),
      )
      dispatch(
        updateChart({
          id: data?.id,
          value: JSON.stringify({
            ...data.detail,
            nameChart: {
              ...data.detail.nameChart,
              name: data.name,
            },
          }),
          name: data.name,
        }),
      )
    }
  }

  return (
    <div className="align-center justify-content-center">
      <Title
        appendStyle={{
          fontWeight: 600,
        }}
        data={{ ...data, name: nameChart?.name }}
        fontSize={12}
        isEdit={isEdit}
        maxWidth={width}
        setIsEdit={(isEdit) => setIsEdit(isEdit)}
        onSubmit={onSubmitUpdateTitle}
        isI18n={false}
      />
    </div>
  )
}
