import { mapKeyIndicator, SEC_GROUP } from '../../common/constants'
import { getChartSchemaParams } from '../../common/helpers'

export const getSecuritiesIndicatorOnApply = ({
  item,
  treeSecuritiesIndicator,
  typeIndicator,
  typeChart,
  viewType,
  usedColors,
  indicatorItemReplace = {},
  favoriteColors,
  isOrientationLeft,
}) => {
  const { companies, indices, sector } = treeSecuritiesIndicator
  let result = {}
  const keyIndicatorReplace = indicatorItemReplace.uniqueID
    ? [
        mapKeyIndicator[typeIndicator],
        'indicator',
        indicatorItemReplace?.id,
        indicatorItemReplace?.timeFrequency,
        indicatorItemReplace?.uniqueID,
      ].join('_')
    : [
        mapKeyIndicator[typeIndicator],
        'indicator',
        indicatorItemReplace?.id,
        indicatorItemReplace?.timeFrequency,
      ].join('_')

  const getResult = (key, value, result) => {
    const dataKey = [
      mapKeyIndicator[typeIndicator],
      key,
      item.value?.id,
      item.value?.timeFrequency,
    ].join('_')
    const dataKeyDuplicate = [
      mapKeyIndicator[typeIndicator],
      key,
      item.value?.id,
      item.value?.timeFrequency,
      item.value?.uniqueID,
    ].join('_')

    const itemReplace = value.find(
      (item) =>
        item.dataKeyBySecurity === keyIndicatorReplace ||
        item.dataKeyBySecurityDuplicate === keyIndicatorReplace,
    )

    return {
      ...result,
      [key]: [
        ...value.filter(
          (item) =>
            item.dataKeyBySecurity !== keyIndicatorReplace &&
            item.dataKeyBySecurityDuplicate !== keyIndicatorReplace,
        ),
        getChartSchemaParams({
          item: item.value,
          securityType: mapKeyIndicator[typeIndicator],
          dataKey,
          typeChart,
          viewType,
          usedColors,
          itemReplace,
          favoriteColors,
          dataKeyDuplicate,
          isOrientationLeft: isOrientationLeft,
        }),
      ],
    }
  }

  switch (typeIndicator) {
    case SEC_GROUP.COMPANY:
      for (const [key, value] of Object.entries(companies)) {
        result = getResult(key, value, result)
      }
      break
    case SEC_GROUP.INDEX:
      for (const [key, value] of Object.entries(indices)) {
        result = getResult(key, value, result)
      }
      break
    case SEC_GROUP.SECTOR:
      for (const [key, value] of Object.entries(sector)) {
        result = getResult(key, value, result)
      }
      break
    default:
      break
  }
  return result
}
