import { COM_GROUP } from '../../../../constants/Group'

export const TIME_FILTER_VALUE = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  SIX_MONTHS: '6 Months',
  NINE_MONTHS: '9 Months',
}

export const TYPE_OF_RATIO_BANK = [
  {
    name: 'corporate.financialData.financialRatio.CAPITAL_ADEQUACY',
    value: 'capitalAdequacy',
    fieldName: 'A',
  },
  {
    name: 'corporate.financialData.financialRatio.ASSET_QUALITY',
    value: 'assetQuality',
    fieldName: 'B',
  },
  {
    name: 'corporate.financialData.financialRatio.EFFICIENCY',
    value: 'efficiency',
    fieldName: 'C',
  },
  {
    name: 'corporate.financialData.financialRatio.LIQUIDITY_ASSET',
    value: 'liquidityAsset',
    fieldName: 'D',
  },
  {
    name: 'corporate.financialData.financialRatio.PROFITABILITY',
    value: 'profitability',
    fieldName: 'E',
  },
  {
    name: 'corporate.financialData.financialRatio.GROWTH',
    value: 'growth',
    fieldName: 'F',
  },
]

export const TYPE_OF_RATIO_OTHER = [
  {
    name: 'corporate.financialData.financialRatio.ACTIVITY_RATIO',
    value: 'activeRatio',
    fieldName: 'A',
  },
  {
    name: 'corporate.financialData.financialRatio.LIQUIDITY_RATIO',
    value: 'liquidityRatio',
    fieldName: 'B',
  },
  {
    name: 'corporate.financialData.financialRatio.SOLVENCY_RATIO',
    value: 'solvencyRatio',
    fieldName: 'C',
  },
  {
    name: 'corporate.financialData.financialRatio.PROFITABILITY_RATIO',
    value: 'profitabilityRatio',
    fieldName: 'D',
  },
  {
    name: 'corporate.financialData.financialRatio.CASH_FLOW_RATIO',
    value: 'cashFlowRatio',
    fieldName: 'E',
  },
  {
    name: 'corporate.financialData.financialRatio.GROWTH_RATIO',
    value: 'growthRatio',
    fieldName: 'F',
  },
  {
    name: 'corporate.financialData.financialRatio.VALUATION_RATIO',
    value: 'valuationRatio',
    fieldName: 'G',
  },
]

export const VIEW_BY = [
  {
    name: 'corporate.financialData.common.YEARLY',
    value: TIME_FILTER_VALUE.YEARLY,
  },
  {
    name: 'corporate.financialData.common.QUARTERLY',
    value: TIME_FILTER_VALUE.QUARTERLY,
  },
  // {
  //   name: 'corporate.financialData.common.SIX_MONTHS',
  //   value: TIME_FILTER_VALUE.SIX_MONTHS,
  // },
  // {
  //   name: 'corporate.financialData.common.NINE_MONTHS',
  //   value: TIME_FILTER_VALUE.NINE_MONTHS,
  // },
]

export const NUMBER_OF_PERIOD = [...new Array(11)].map((_, index) =>
  !index
    ? { name: 'corporate.financialData.common.ALL', value: 1000 }
    : {
        name: String(index),
        value: String(index),
      },
)

export const TYPE = [
  {
    name: 'corporate.financialData.common.CONSOLIDATED',
    value: 'true',
  },
  {
    name: 'corporate.financialData.common.SEPARATED',
    value: 'false',
  },
]

export const LASTEST_YEAR = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: String(year - v),
  }
})

export const FILTER_OPTIONS = [
  {
    title: 'corporate.financialData.common.TYPE_OF_RATIO',
    options: {
      [COM_GROUP.BANK]: TYPE_OF_RATIO_BANK,
      Other: TYPE_OF_RATIO_OTHER,
    },
    field: 'typeOfRatio',
  },
  {
    title: 'corporate.financialData.common.VIEW_BY',
    options: VIEW_BY,
    field: 'viewBy',
  },
  {
    title: 'corporate.financialData.common.NUMBER_OF_PERIOD',
    options: NUMBER_OF_PERIOD,
    field: 'numberOfPeriod',
  },
  // {
  //   title: 'corporate.financialData.common.TYPE',
  //   options: TYPE,
  //   field: 'type',
  // },
  {
    title: 'corporate.financialData.common.LASTEST_YEAR',
    options: LASTEST_YEAR,
    field: 'lastestYear',
  },
]
