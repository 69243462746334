export const YELLOW_COLOR = '#facc5c'
export const BLUE_COLOR = '#40abff'

export const NOT_QUARTER_CONST = 5

const START_YEAR = 2014

const YEARS_LIST = [...Array(new Date().getFullYear() - START_YEAR + 1)].map(
  (_, index) => new Date().getFullYear() - index,
)

export const YEARS_FILTER = YEARS_LIST.map((item) => ({
  name: item,
  value: item,
}))

export const QUARTERS_FILTER = [1, 2, 3, 4, NOT_QUARTER_CONST].map((item) => ({
  name: item === NOT_QUARTER_CONST ? 'market.marginLoans.YEARLY' : `Q${item}`,
  value: item,
}))

export const COLOR_STRUCTURE_CHART = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#40abff',
  '#fff6df',
]
