import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import SelectCustom from '../../../common/selectCustom'
import { SELECT_TIME_RANGE_OPTIONS } from '../constants'
import { changeFilterTimeRange, selectFilter } from '../store/slice'

const FilterTimeRange = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const setValueTimeRange = (val) => {
    dispatch(changeFilterTimeRange(val))
  }

  return (
    <div className="mb-40">
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.topMover.TIME_RANGE" />
      </Span>
      <div className="mt-8">
        <SelectCustom
          value={filter.timeRange}
          isI18n={true}
          selectData={Object.keys(SELECT_TIME_RANGE_OPTIONS).map((key) => ({
            name: SELECT_TIME_RANGE_OPTIONS[key].text,
            value: key,
          }))}
          handleChange={setValueTimeRange}
        />
      </div>
    </div>
  )
}

export default FilterTimeRange
