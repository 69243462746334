import { useSelector } from 'react-redux'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import { selectDealValue } from './store/slice'

const ColCellTicker = ({ val, rowId }) => {
  const organizationId = useSelector(selectDealValue(rowId, 'organizationId'))

  return <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
}

export default ColCellTicker
