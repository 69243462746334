export const tableConstants = [
  {
    id: 'ticker',
    name: '',
  },
  {
    id: 'bS249',
    name: 'sector.financialAnalysis.security.assetStructure.FINANCIAL_FVTPL',
    isPercent: false,
    isDecimal: true,
    multiplier: 10 ** 9,
  },
  {
    id: 'fvtplyoy',
    name: 'sector.financialAnalysis.security.assetStructure.YOY_GROWTH',
    isPercent: true,
    isDecimal: false,
    multiplier: 1,
  },
  {
    id: 'bS251',
    name: 'sector.financialAnalysis.security.assetStructure.FINANCIAL_AFS',
    isPercent: false,
    isDecimal: true,
    multiplier: 10 ** 9,
  },
  {
    id: 'afsyoy',
    name: 'sector.financialAnalysis.security.assetStructure.YOY_GROWTH',
    isPercent: true,
    isDecimal: false,
    multiplier: 1,
  },
  {
    id: 'nS239',
    name: 'sector.financialAnalysis.security.assetStructure.MARGIN_LENDING',
    isPercent: false,
    isDecimal: true,
    multiplier: 10 ** 9,
  },
  {
    id: 'mlyoy',
    name: 'sector.financialAnalysis.security.assetStructure.YOY_GROWTH',
    isPercent: true,
    isDecimal: false,
    multiplier: 1,
  },
  {
    id: 'bS95',
    name: 'sector.financialAnalysis.security.assetStructure.OWNER_EQUITY',
    isPercent: false,
    isDecimal: true,
    multiplier: 10 ** 9,
  },
  {
    id: 'oeyoy',
    name: 'sector.financialAnalysis.security.assetStructure.YOY_GROWTH',
    isPercent: true,
    isDecimal: false,
    multiplier: 1,
  },
  {
    id: 'mloe',
    name: 'sector.financialAnalysis.security.assetStructure.MARGIN_AND_OWNER',
    isPercent: true,
    isDecimal: false,
    multiplier: 1,
  },
]
