import { COM_GROUP, ICB_ID } from '../../constants/Group'

const useSectorGroup = (icbId) => {
  if (ICB_ID[COM_GROUP.BANK].includes(icbId)) {
    return COM_GROUP.BANK
  } else if (ICB_ID[COM_GROUP.SECURITIES].includes(icbId)) {
    return COM_GROUP.SECURITIES
  } else {
    return COM_GROUP.CORPORATE
  }
}

export default useSectorGroup
