import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ChangeComponents } from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { COMPONENTS_FILTER } from './constants'
import { selectCurrentComponent } from './store/slice'

export const PanelChangeComponents = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentComponent = useSelector(selectCurrentComponent)
  const currentComponentName = COMPONENTS_FILTER.find(
    (item) => item.value === currentComponent,
  ).name

  const titlePanel = `${UseI18n(
    'economy.paymentBalance.paymentBalance.CHANGE_COMPONENTS',
  )} ${UseI18n(currentComponentName)}`
  const titleJpg = `${I18n.t(
    'economy.paymentBalance.paymentBalance.CHANGE_COMPONENTS',
  )} ${I18n.t(currentComponentName)}`
  const nameScreen = I18n.t(
    'economy.paymentBalance.paymentBalance.BALANCE_OF_PAYMENT',
  )

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'changeComponents',
        nameScreen: nameScreen,
        chartName: titleJpg,
      }}
    >
      <ChangeComponents
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
