import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import useGetICB from '../../../../common/masterData/useGetICB'
import { getAllIdItem } from '../../../../common/tree/helper'
import { handleDuplicateArray, keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'
import { changeCheckedSector, selectSectorsTemplate } from '../../store/slice'
import DropdownSector from './DropdownSector'

const Sector = () => {
  const dispatch = useDispatch()

  const sectorsTemplate = useSelector(selectSectorsTemplate)

  const { ICBsTree, ICBs, loading, ICBTreeNotDeleteChildren } = useGetICB(
    true,
    true,
    undefined,
    undefined,
    true,
  )

  const [sectorByIds, setSectorByIds] = useState({})

  useEffect(() => {
    setSectorByIds(keyBy(ICBs, 'icbCode'))
  }, [ICBs])

  useEffect(() => {
    if (sectorsTemplate.length > 0) {
      dispatch(
        changeCheckedSector(
          handleDuplicateArray(
            sectorsTemplate.reduce((arr, item) => {
              const idsChildren = getAllIdItem(
                ICBTreeNotDeleteChildren[sectorByIds[item].icbId],
                'icbId',
              )
              return arr.concat(idsChildren)
            }, []),
          ),
        ),
      )
    }
  }, [sectorsTemplate])

  return (
    <>
      <h5 className={style.labelFilter}>
        <Span style={{ fontSize: 11 }}>
          <Translate value="tool.smartScreening.SECTOR_LOW" />
        </Span>
      </h5>
      <div className="input-dropdown mb-8">
        <DropdownSector ICBsTree={ICBsTree} ICBs={ICBs} loading={loading} />
      </div>
    </>
  )
}

export default Sector
