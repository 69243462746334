import PropTypes from 'prop-types'
import React from 'react'
import { Z_INDEX } from '../../constants/Common'
import blackLoadingImage from './blackLoading.gif'
import styles from './index.module.css'
import whiteLoadingImage from './whiteLoading.gif'

export const Loading = ({ isBlack, style }) => {
  return (
    <div
      className={styles.loadingSpinner}
      style={{ zIndex: Z_INDEX.LOADING, ...style }}
    >
      <img
        alt="loading"
        src={isBlack ? blackLoadingImage : whiteLoadingImage}
      />
    </div>
  )
}

Loading.propTypes = {
  isBlack: PropTypes.bool,
  style: PropTypes.object,
}

Loading.defaultProps = {
  isBlack: false,
  style: {},
}
