import { common } from './common'
import { settingGeneral } from './settingGeneral'
import { settingProfile } from './settingProfile'

const setting = {
  common,
  settingGeneral,
  settingProfile,
}

export default setting
