import { I18n, Translate } from 'react-redux-i18n'
import { keyBy } from '../../utils/Common'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  MODAL_STYLE_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../../utils/EventEmitter'
import { TIME_FREQUENCY } from './constants'

const convertArrayToObject = (array, key) => {
  const initialValue = {}
  return array.reduce((obj, item) => {
    return { ...obj, [item[key]]: item }
  }, initialValue)
}

const getObjSeparateDeepest = (item) => {
  const objMap = {
    daily: {
      id: item.id + '_daily',
      parentId: item.id,
      name: I18n.t('financialChart.DAILY'),
      nameDisplay: [item.name, `(${I18n.t('financialChart.DAILY')})`].join(' '),
      secNameDisplay: [
        item.secName,
        `(${I18n.t('financialChart.DAILY')})`,
      ].join(' '),
      timeFrequency: TIME_FREQUENCY.DAILY,
      idPayload: item.id,
      unit: item.unit,
    },
    weekly: {
      id: item.id + '_weekly',
      parentId: item.id,
      name: I18n.t('financialChart.WEEKLY'),
      nameDisplay: [item.name, `(${I18n.t('financialChart.WEEKLY')})`].join(
        ' ',
      ),
      secNameDisplay: [
        item.secName,
        `(${I18n.t('financialChart.WEEKLY')})`,
      ].join(' '),
      timeFrequency: TIME_FREQUENCY.WEEKLY,
      idPayload: item.id,
      unit: item.unit,
    },
    monthly: {
      id: item.id + '_monthly',
      parentId: item.id,
      name: I18n.t('financialChart.MONTHLY'),
      nameDisplay: [item.name, `(${I18n.t('financialChart.MONTHLY')})`].join(
        ' ',
      ),
      secNameDisplay: [
        item.secName,
        `(${I18n.t('financialChart.MONTHLY')})`,
      ].join(' '),
      timeFrequency: TIME_FREQUENCY.MONTHLY,
      idPayload: item.id,
      unit: item.unit,
    },
    quarterly: {
      id: item.id + '_quarterly',
      parentId: item.id,
      name: I18n.t('financialChart.QUARTERLY'),
      nameDisplay: [item.name, `(${I18n.t('financialChart.QUARTERLY')})`].join(
        ' ',
      ),
      secNameDisplay: [
        item.secName,
        `(${I18n.t('financialChart.QUARTERLY')})`,
      ].join(' '),
      timeFrequency: TIME_FREQUENCY.QUARTERLY,
      idPayload: item.id,
      unit: item.unit,
    },
    yearly: {
      id: item.id + '_yearly',
      parentId: item.id,
      name: I18n.t('financialChart.YEARLY'),
      nameDisplay: [item.name, `(${I18n.t('financialChart.YEARLY')})`].join(
        ' ',
      ),
      secNameDisplay: [
        item.secName,
        `(${I18n.t('financialChart.YEARLY')})`,
      ].join(' '),
      timeFrequency: TIME_FREQUENCY.YEARLY,
      idPayload: item.id,
      unit: item.unit,
    },
    ttm: {
      id: item.id + '_ttm',
      parentId: item.id,
      name: 'TTM',
      nameDisplay: [item.name, '(TTM)'].join(' '),
      secNameDisplay: [item.secName, '(TTM)'].join(' '),
      timeFrequency: TIME_FREQUENCY.TTM,
      idPayload: item.id,
      unit: item.unit,
    },
  }
  const arrResult = []
  Object.keys(item).forEach(
    (i) =>
      Object.values(TIME_FREQUENCY).includes(i) &&
      item[i] &&
      arrResult.push(objMap[i]),
  )
  arrResult.forEach((e) => {
    e = ''
  })
  return convertArrayToObject(arrResult, 'id')
}

const separateDeepestData = (data, result = {}) => {
  Object.values(data).forEach((e) => {
    if (Object.values(e.children).length) {
      separateDeepestData(e.children, result)
    } else {
      result[e.parentId] = {
        ...result[e.parentId],
        [e.id]: {
          ...e,
          children: getObjSeparateDeepest(e),
        },
      }
      delete data[e.id]
    }
  })

  return result
}

export const handleTreeIndicators = (indicators) => {
  const data = keyBy(
    indicators.map((e) => ({ ...e, children: {} })),
    'id',
  )
  indicators
    .slice()
    .reverse()
    .forEach((e) => {
      if (data[e.parentId]) {
        data[e.parentId].children[e.id] = data[e.id]
        delete data[e.id]
      }
    })
  const deepestIndicators = separateDeepestData(data)

  return { data, deepestIndicators }
}

export const getDeepestIndicatorsWithTimeFrequency = (indicators) => {
  const { deepestIndicators } = handleTreeIndicators(indicators)
  return Object.values(deepestIndicators).reduce((arr, item) => {
    let children = []
    Object.values(item).forEach((childItem) => {
      children = [...children, ...Object.values(childItem.children)]
    })
    return [...arr, ...children]
  }, [])
}

export const getPopupSaveDashboardSuccess = (name) => {
  EventEmitter.dispatch(
    BUTTON_TEXT_POPUP_ERROR,
    I18n.t('common.button.BUTTON_OK'),
  )
  EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
  EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
  EventEmitter.dispatch(MODAL_STYLE_POPUP_ERROR, {
    backgroundColor: '#ffffff',
    color: '#4E4E4E',
    fontWeight: 400,
  })
  EventEmitter.dispatch(SHOW_POPUP_ERROR, [
    <div className="mb-20">
      <div className="centerItems mb-20">
        <img src="/iconLike.svg" alt="Like" width={38} height={46} />
      </div>
      <div>
        <Translate value="financialChart.MESS_SAVE_CHART" />
        {name}
        <Translate value="financialChart.MESS_SAVE_CHART_1" />
      </div>
    </div>,
  ])
}
