import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../../configs/Layout'
import { withWrapper } from '../../../../../common/withWrapper'
import { PanelDataTable } from './dataTable/PanelDataTable'
import { PanelOperatingSale } from './operatingSale/PanelOperatingSale'
import { PanelRevenueBrokerage } from './revenueBrokerage/PanelRevenueBrokerage'
import { PanelRevenueInvestment } from './revenueInvestment/PanelRevenueInvestment'
import { PanelRevenueProprietary } from './revenueProprietary/PanelRevenueProprietary'

const MAP_KEY = {
  DATA_TABLE: 'DATA_TABLE',
  OPERATING_SALE: 'OPERATING_SALE',
  REVENUE_BROKERAGE: 'REVENUE_BROKERAGE',
  REVENUE_PROPRIETARY: 'REVENUE_PROPRIETARY',
  REVENUE_INVESTMENT: 'REVENUE_INVESTMENT',
}

const MAP_SIZE = {
  [`${MAP_KEY.DATA_TABLE}`]: {
    width: `calc(100% / 3 * 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 2 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.OPERATING_SALE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.OPERATING_SALE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.REVENUE_BROKERAGE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.REVENUE_PROPRIETARY,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.REVENUE_INVESTMENT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.OPERATING_SALE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REVENUE_BROKERAGE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.REVENUE_PROPRIETARY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REVENUE_PROPRIETARY}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.REVENUE_INVESTMENT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REVENUE_INVESTMENT}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const PerformanceContent = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelDataTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelOperatingSale
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelRevenueBrokerage
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelRevenueProprietary
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelRevenueInvestment
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

PerformanceContent.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  dataType: PropTypes.string,
}

export default withWrapper(MAP_SIZE, panelRefs)(PerformanceContent)
