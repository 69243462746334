export const TIME_RANGE = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  ACC_QUARTER: 'AccQuarterly',
}

export const TIME_RANGE_OPTIONS = [
  { title: 'economy.fiscal.stateBudget.YEAR', value: TIME_RANGE.YEARLY },
  { title: 'economy.fiscal.stateBudget.QUARTER', value: TIME_RANGE.QUARTERLY },
  {
    title: 'economy.fiscal.stateBudget.ACC_QUARTER',
    value: TIME_RANGE.ACC_QUARTER,
  },
]
