import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/corporate/OverviewService'

export const getNewsInfo = createAsyncThunk(
  'corporate/overview/news/GET_NEWS_INFO',
  async (data, { rejectWithValue }) => {
    const response = await OverviewService.getNewsInfo(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
