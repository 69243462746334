import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from 'lodash'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { EXCHANGE_CODE } from '../../constants'
import { getOrganizationsByExchangeAndICB, getWatchList } from './thunk'

const initialState = {
  tickers: [],
  listExchangeChecked: [],
  listParentExchangeChecked: [],
  exchangeFirstMountPopup: true,
  listSectorChecked: [],
  listParentSectorChecked: [],
  sectorFirstMountPopup: true,
  selectedExchange: EXCHANGE_CODE.ALL_EXCHANGE,
  watchList: [],
  watchListInfoById: {},
  isLoading: {
    companies: true,
    watchList: true,
  },
  securities: {
    companies: [],
    indices: [],
    sector: [],
    economy: [],
    watchList: [],
  },
}

export const slice = createSlice({
  name: 'financialChart/popupAddSecurities',
  initialState,
  reducers: {
    changeSectorChecked: (state, action) => {
      state.listSectorChecked = action.payload
    },
    changeListParentSectorChecked: (state, action) => {
      state.listParentSectorChecked = action.payload
    },
    changeSectorFirstMountPopup: (state, action) => {
      state.sectorFirstMountPopup = action.payload
    },
    changeExchangeChecked: (state, action) => {
      state.listExchangeChecked = action.payload
    },
    changeListParentExchangeChecked: (state, action) => {
      state.listParentExchangeChecked = action.payload
    },
    changeExchangeFirstMountPopup: (state, action) => {
      state.exchangeFirstMountPopup = action.payload
    },
    changeSelectedExchange: (state, action) => {
      state.selectedExchange = action.payload
    },
    changeSecurities: (state, action) => {
      state.securities = {
        ...state.securities,
        [action.payload.typeSecurities]: action.payload.value,
      }
    },
    changeFullSecurities: (state, action) => {
      state.securities = action.payload
    },
    changeSecuritiesEconomyById: (state, action) => {
      const { id, timeFrequency } = action.payload
      state.securities.economy = state.securities.economy.map((item) =>
        item.id === id && item.timeFrequency === timeFrequency
          ? { ...item, ...action.payload }
          : item,
      )
    },
    resetSecurities: (state) => {
      state.securities = initialState.securities
    },
  },
  extraReducers: (builder) => {
    //GetOrgIdsByIcb
    builder.addCase(getOrganizationsByExchangeAndICB.pending, (state) => {
      state.isLoading.companies = true
    })
    builder.addCase(
      getOrganizationsByExchangeAndICB.fulfilled,
      (state, action) => {
        const data = action.payload
        state.tickers = [
          ...data
            .filter((item) => !!item.ticker)
            .sort((a, b) => a.ticker?.localeCompare(b.ticker)),
          ...data
            .filter((item) => !item.ticker)
            .sort((a, b) => a.taxCode?.localeCompare(b.taxCode)),
        ]
        state.isLoading.companies = false
      },
    )
    builder.addCase(
      getOrganizationsByExchangeAndICB.rejected,
      (state, action) => {
        state.isLoading.companies = action.payload.loading
      },
    )
    //GetWatchList
    builder.addCase(getWatchList.pending, (state) => {
      state.isLoading.watchList = true
    })
    builder.addCase(getWatchList.fulfilled, (state, action) => {
      state.watchList = action.payload
      state.watchListInfoById = keyBy(action.payload, 'watchListId')
      state.isLoading.watchList = false
    })
    builder.addCase(getWatchList.rejected, (state, action) => {
      state.isLoading.watchList = action.payload.loading
    })
  },
})

export const selectListExchangeChecked = (state) =>
  state[slice.name].listExchangeChecked
export const selectListParentExchangeChecked = (state) =>
  state[slice.name].listParentExchangeChecked
export const selectExchangeFirstMountPopup = (state) =>
  state[slice.name].exchangeFirstMountPopup
export const selectListSectorChecked = (state) =>
  state[slice.name].listSectorChecked
export const selectListParentSectorChecked = (state) =>
  state[slice.name].listParentSectorChecked
export const selectSectorFirstMountPopup = (state) =>
  state[slice.name].sectorFirstMountPopup
export const selectTickers = (state) => state[slice.name].tickers
export const selectSelectedExchange = (state) =>
  state[slice.name].selectedExchange
export const selectWatchList = (state) => state[slice.name].watchList
export const selectWatchListInfoById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].watchListInfoById[id], attr)
export const selectIsLoading = (key) => (state) =>
  valByKeyWithDot(state[slice.name].isLoading, key)
export const selectSecurities = (state) => state[slice.name].securities
export const selectSecuritiesByName = (name) => (state) =>
  valByKeyWithDot(state[slice.name].securities, name)

export const {
  changeExchangeChecked,
  changeExchangeFirstMountPopup,
  changeListParentExchangeChecked,
  changeListParentSectorChecked,
  changeSectorChecked,
  changeSectorFirstMountPopup,
  changeSelectedExchange,
  changeSecurities,
  changeFullSecurities,
  changeSecuritiesEconomyById,
  resetSecurities,
} = slice.actions

register(slice.name, slice.reducer)
