import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialDataService from '../../../../../../core/services/corporate/FinancialDataService'

export const getPriceEarningRatioDividendYieldChartData = createAsyncThunk(
  'corporate/financialData/dividend/chart/GET_PRICE_EARNING_RATIO_DIVIDEND_YIELD_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getPriceEarningRatioDividendYieldChartData(
          data,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getEarningsAgainstDividendsChartData = createAsyncThunk(
  'corporate/financialData/dividend/chart/GET_EARNINGS_AGAINST_DIVIDEND_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialDataService.getEarningsAgainstDividendsChartData(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
