import { isEmpty } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber, invertKeyBy } from '../../../utils/Common'
import {
  FORMAT,
  compareTwoDateTime,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { valueTab } from '../infoSec/constants'
import {
  selecBondIds,
  selecBondInfo,
  selecEditlocale,
  selectActiveTab as selectActiveTabType,
  selectCouponInput,
  selectCreateBondId,
  selectStatusSuccess,
  updateStatusEditlocale,
  updateStatusSuccess,
} from '../infoSec/store/slice'
import { getBondCashFlowScenario } from '../infoSec/store/thunk'
import Bottom from './Bottom'
import Content from './Content'
import FormTop from './FormTop'
import { tabInterrestOption } from './constants'
import {
  selectActiveTab,
  selectDataTab,
  selectInfoSameIndustry,
  selectListTab,
  selectStateSameIndustry,
  selectStatusGetBondCashFlow,
  updateStatusGetBondCashFlow,
} from './store/slice'
import { exportFile, getUserBondCashFlowList } from './store/thunk'

const Index = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()

  const dispatch = useDispatch()
  const statusSuccess = useSelector(selectStatusSuccess)
  const bondInfo = useSelector(selecBondInfo)
  const bondIds = useSelector(selecBondIds)
  const filter = useSelector(selectStateSameIndustry)
  const statusGetBondCashFlow = useSelector(selectStatusGetBondCashFlow)
  const dataTab = useSelector(selectDataTab)
  const active = useSelector(selectActiveTab)
  const listTab = useSelector(selectListTab)
  const info = useSelector(selectInfoSameIndustry)
  const couponInput = useSelector(selectCouponInput)
  const createBondId = useSelector(selectCreateBondId)
  const activeTab = useSelector(selectActiveTabType)

  const addcash = useMemo(() => {
    let value = 0
    if (!isEmpty(info)) {
      const item = info.find((e) => e.bondPaymentTypeId === 4)
      value = item?.parValue || 0
    }
    return value
  }, [info])

  const totalCashFlow = useMemo(() => {
    let value = 0
    const data = invertKeyBy(dataTab?.[active]?.data || {})
    if (!isEmpty(data)) {
      const dataformat = data.filter(
        (e) => compareTwoDateTime(e.tradingDateId, filter.tradingDate) === 1,
      )
      dataformat.forEach((e) => {
        value += parseInt(e?.estimatedValue)
      })
    }
    return value
  }, [active, filter.tradingDate, dataTab?.[active]?.data])

  const statusEditlocale = useSelector(selecEditlocale)
  useEffect(() => {
    if (statusGetBondCashFlow && !statusEditlocale) {
      dispatch(
        getUserBondCashFlowList({
          bondId: activeTab === valueTab.BOND_DEFAULT ? bondIds : createBondId,
          isUserBond: activeTab !== valueTab.BOND_DEFAULT,
        }),
      )
      dispatch(updateStatusGetBondCashFlow(false))
    }
    dispatch(updateStatusEditlocale(false))
  }, [statusGetBondCashFlow, bondIds, createBondId, activeTab])

  useEffect(() => {
    if (
      (statusSuccess && activeTab === valueTab.BOND_DEFAULT && bondIds) ||
      (statusSuccess && activeTab === valueTab.BOND_CREATED && createBondId)
    ) {
      const params = {
        BondIds: activeTab === valueTab.BOND_DEFAULT ? bondIds : createBondId,
        TradingDateId: formatDateTime(
          filter.paymentDate,
          FORMAT.YEAR_MONTH_DAY,
        ),
        InterestBasisTypeId: filter.interestBasis,
        InterestRate: bondInfo.interestRate,
        BondInterestId: bondInfo.BondInterestId,
        // floatInterestBenchmarkId: bondInfo.floatInterestBenchmarkId,
        isUserBond: activeTab !== valueTab.BOND_DEFAULT,
      }

      if (
        parseFloat(couponInput / 100).toFixed(4) !==
        parseFloat(bondInfo.interestRate).toFixed(4)
      ) {
        params.floatInterestBenchmarkId = bondInfo.floatInterestBenchmarkId
      }

      dispatch(
        getBondCashFlowScenario({
          param: params,
        }),
      )
      dispatch(updateStatusSuccess(false))
    }
  }, [statusSuccess, activeTab, bondIds, createBondId])

  const chechDate = () => {
    const date1 = moment(filter.paymentDate)
    const date2 = moment(bondInfo.maturityDateId)
    const monthsDiff = date2.diff(date1, 'months')
    const dayDiff = date2.diff(date1, 'days')

    let result
    if (dayDiff < 30) {
      result = `${dayDiff} ${I18n.t('common.calendar.DAY')}`
    } else if (dayDiff > 30 && monthsDiff < 36) {
      result = `${parseFloat(dayDiff / 30).toFixed(2)} ${I18n.t(
        'common.calendar.MONTH',
      )}`
    } else {
      result = `${parseFloat(monthsDiff / 12).toFixed(2)} ${I18n.t(
        'common.calendar.YEAR',
      )}`
    }
    return result
  }

  const exportFileChange = () => {
    const dataInvert = invertKeyBy(dataTab?.[active]?.data)
    const tab = listTab.find((e) => e.value === active)
    const interes = tabInterrestOption.find(
      (e) => e.value === filter.interestBasis,
    )

    const params = {
      bondId: activeTab === valueTab.BOND_DEFAULT ? bondIds : createBondId,
      bondTicker: bondInfo?.ticker,
      bondCashFlowName: tab?.title,
      tradingDate: getISOStartOrEndOfDay(filter.tradingDate, timeZone, true),
      payDate: getISOStartOrEndOfDay(filter.paymentDate, timeZone, true),
      exrightDate: filter.exDate
        ? getISOStartOrEndOfDay(filter.exDate, timeZone, true)
        : null,
      tradingVolumne: filter.TradingVolume,
      totalParValue: filter.TradingVolume * addcash,
      totalExpected: totalCashFlow * parseInt(filter?.TradingVolume ?? 0),
      totalInvestment:
        (filter?.dirtyprice || 0) * parseInt(filter.TradingVolume),
      interestBasis: interes.name,
      ytm: parseFloat(filter.ytm) / 100,
      dirtyPrice: filter.dirtyprice,
      cleanPrice: filter.cleanPrice,
      accruedInterest: filter.accInterest,
      yieldToMaturity: filter.ytmRender ? filter.ytmRender / 100 : 0,
      remainingDuration: chechDate(),
      lstBondCashFlow: dataInvert.map((e) => ({
        bondId: e.bondId,
        bondCashFlowId: e.bondCashFlowId,
        bondCashFlowName: e?.bondCashFlowName || '',
        bondCashFlowDetailId: e.bondCashFlowDetailId,
        bondCashFlowDetailName: e?.bondCashFlowDetailName
          ? e?.bondCashFlowDetailName
          : I18n.t(
              `bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.typeCashFlow.A${e.bondPaymentTypeId}`,
            ),
        bondPaymentTypeId: e.bondPaymentTypeId,
        tradingDateId: e.tradingDateId,
        couponInterestRate: e.couponInterestRate / 100,
        parValue: e.parValue,
        estimatedValue: e.estimatedValue,
        accInterest: e.accInterest,
        dirtyPrice: 0,
        cleanPrice: 0,
        paymentCalendarId: e.paymentCalendarId,
        paymentCalendarMonth: e.paymentCalendarMonth,
        paymentStatusId: e.paymentStatusId,
        cashFlowTypeId: e.cashFlowTypeId,
      })),
    }
    return exportFile(params)
  }

  return (
    <>
      <Panel
        title="bond.valuation.valuation.infoValuation"
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadCsv={true}
        downloadCsv={() => exportFileChange()}
      >
        <div className="h-100">
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <FormTop
                    size={size}
                    width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
                    height={formatNumber(
                      sizes[panelKey].height - HEADER_PANEL_HEIGHT,
                    )}
                  />
                  {size.height && (
                    <div style={{ height: `calc(100% - ${size.height}px)` }}>
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <>
                              <div
                                style={{
                                  height: `calc(100%)`,
                                }}
                              >
                                <Content />
                              </div>
                            </>
                          )
                        }}
                      </SizeTracker>
                    </div>
                  )}
                  <Bottom />
                </>
              )
            }}
          </SizeTracker>
        </div>
      </Panel>
    </>
  )
}

export default Index
