import { Filter } from '.'
import PanelFilter from '../../../../common/panelFilter'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelFilterTable = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <PanelFilter
      title="common.FILTER"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <Filter
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </PanelFilter>
  )
}
