import moment from 'moment'
import { EMPTY_VALUE } from '../../../../constants/Common'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { LINE_CHART_COLORS } from '../../constants'
import {
  COLLAPSE_ID_4,
  COLLAPSE_ID_5,
  DEFAULT_COLUMN_COLOR,
  DEFAULT_COLUMN_KEY,
} from '../config'

export const NUMBER_OF_COL = 40
const STEP = NUMBER_OF_COL / 2
const DEFAULT_DAILY_YEAR = 1
const DEFAULT_WEEKLY_YEAR = 5
const DEFAULT_MONTHLY_YEAR = 10
const MAX_DAILY_YEAR_RANGE = 2

export const handleCollapseLevel = (data) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: { level: cur.level, isRowCollapse: cur.level === 1 },
    }
  }, {})
}

export const handleMappingData = (payload) => {
  if (!payload.length) {
    return []
  }

  const parentRows = handleDuplicateByKey(payload, ['stt']).map((item) => ({
    id: item.stt,
    title: item.type,
    level: 1,
    typeId: item.typeId,
  }))
  const allRows = []

  parentRows.forEach((item) => {
    const children = payload
      .filter((child) => child.stt === item.id)
      .map((child) => ({
        id: `${item.id}-${child.typeId}-${child.childType}`,
        title: child.name,
        level: 2,
        typeId: child.typeId,
      }))
    allRows.push(item, ...children)
  })

  return allRows
}

export const defineGroupColumn = (payload, timeZone, locale) => {
  if (!payload.length) {
    return []
  }

  const allDate = [...new Set(payload.map((item) => item.publicDateId))]
  return allDate
    .map((date) => ({
      date,
      dateFormat: formatDateTime(date, FORMAT.DATE, locale, timeZone),
      monthYear: formatMonthYear(new Date(date)),
    }))
    .sort((a, b) =>
      moment(a.dateFormat, FORMAT.DATE[locale]).diff(
        moment(b.dateFormat, FORMAT.DATE[locale]),
      ),
    )
}

export const keyByDate = (payload) => {
  if (!payload.length) {
    return {}
  }

  const result = {}
  payload.forEach((item) => {
    const key = `${item.stt}-${item.typeId}-${item.childType}`
    const val = isPercentFormat(key)
      ? item.value * 100
      : item.value / 1000000000

    if (!result[key]) {
      result[key] = {
        [item.publicDateId]: val,
      }
    } else {
      result[key][item.publicDateId] = val
    }
  })

  return result
}

export const initLineColor = (checkboxChecked, tableMapping) => {
  return LINE_CHART_COLORS.map((color, index) => {
    const indicator = tableMapping.find(
      (item) => item.id === checkboxChecked[index]?.id,
    )
    return {
      color: handleColor(checkboxChecked[index]?.id, color),
      author: checkboxChecked[index]?.id,
      indicatorName: indicator?.title,
    }
  })
}

export const handleLineColor = (id, lineChartColor, isChecked) => {
  if (isChecked) {
    return lineChartColor.map((item) => {
      if (item.author === id) {
        return {
          ...item,
          author: undefined,
        }
      }
      return item
    })
  } else {
    let count = 0
    return lineChartColor.map((item) => {
      if (Object.values(DEFAULT_COLUMN_COLOR).includes(item.color)) {
        if (
          (DEFAULT_COLUMN_COLOR.sellOutright === item.color &&
            DEFAULT_COLUMN_KEY.sellOutright === id) ||
          (DEFAULT_COLUMN_COLOR.reverseRepo === item.color &&
            DEFAULT_COLUMN_KEY.reverseRepo === id)
        ) {
          return {
            ...item,
            author: id,
          }
        }
        return item
      } else {
        if (
          count === 0 &&
          item.author === undefined &&
          !Object.values(DEFAULT_COLUMN_KEY).includes(id)
        ) {
          count++
          return {
            ...item,
            author: id,
          }
        }
        return item
      }
    })
  }
}

export const handleColor = (id, lineColor) => {
  if (id === DEFAULT_COLUMN_KEY.sellOutright) {
    return DEFAULT_COLUMN_COLOR.sellOutright
  }

  if (id === DEFAULT_COLUMN_KEY.reverseRepo) {
    return DEFAULT_COLUMN_COLOR.reverseRepo
  }

  return lineColor
}

export const handlePages = (groupColumns) => {
  if (groupColumns.length === 0) {
    return {}
  }

  if (groupColumns.length <= NUMBER_OF_COL) {
    return { 1: [0, groupColumns.length] }
  }

  const totalPages = Math.ceil(groupColumns.length / STEP) - 1
  const arr = [...Array(totalPages).keys()].map((item) => item + 1).reverse()
  const result = {}
  let firstCol = groupColumns.length - NUMBER_OF_COL
  let lastCol = groupColumns.length

  arr.forEach((item) => {
    firstCol = firstCol < 0 ? 0 : firstCol
    result[item] = [firstCol, lastCol]
    lastCol -= STEP
    firstCol -= STEP
  })

  return result
}

export const initCurrentPage = (payload) => {
  if (!payload.length) {
    return undefined
  }

  const allDate = handleDuplicateByKey(payload, ['publicDateId']).map(
    (item) => item.publicDateId,
  )

  if (allDate.length / NUMBER_OF_COL < 1) return 1

  return Math.ceil(allDate.length / STEP) - 1
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const isPercentFormat = (str) => {
  return str.startsWith(COLLAPSE_ID_4) || str.startsWith(COLLAPSE_ID_5)
}

export const formatMonthYear = (date) => {
  if (!date) {
    return EMPTY_VALUE
  }

  const month = new Date(date).getMonth() + 1
  const year = new Date(date).getFullYear()

  return `${month < 10 ? '0' : ''}${month}-${year}`
}

export const getDefaultRangeYear = (timeRange) => {
  if (timeRange === 'Daily') {
    return DEFAULT_DAILY_YEAR
  }

  return timeRange === 'Weekly' ? DEFAULT_WEEKLY_YEAR : DEFAULT_MONTHLY_YEAR
}

export const getDisabledRow = (data) => {
  const rowsCollapse = data.filter((item) => item.level === 1)
  return rowsCollapse.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: true,
    }
  }, {})
}

export const getDateRange = (fromDate, toDate, timeZone, previousYear) => {
  let params = {
    DateFrom: previousYear,
    DateTo: getISOStartOrEndOfDay(new Date(), timeZone, true),
  }

  if (!fromDate && !toDate) {
    return params
  }

  if (fromDate) {
    params.DateFrom = getISOStartOrEndOfDay(fromDate, timeZone, true)
  }

  if (toDate) {
    params.DateTo = getISOStartOrEndOfDay(toDate, timeZone, true)
  }

  params = handleLimitDaily(params, timeZone)

  return params
}

const handleLimitDaily = (params, timeZone) => {
  const toYear = new Date(params.DateTo).getFullYear()
  const fromYear = new Date(params.DateFrom).getFullYear()
  if (toYear - fromYear > MAX_DAILY_YEAR_RANGE) {
    return {
      ...params,
      DateFrom: getISOStartOrEndOfDay(
        new Date().setFullYear(toYear - MAX_DAILY_YEAR_RANGE),
        timeZone,
        true,
      ),
    }
  }

  return params
}
