import EconomyProxy from '../../../proxies/tool/dataExplorer/EconomyProxy'
import { ServiceBase } from '../../ServiceBase'

class IndicesSectorServices extends ServiceBase {
  getIndicator(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.economy.getIndicator',
      params,
    )(() => EconomyProxy.getIndicator(params))
  }

  getResult(params) {
    return this.getData(() => EconomyProxy.getResult(params))
  }

  exportExcel(params) {
    return this.getFileDownload(() => EconomyProxy.exportExcel(params))
  }
}

export default new IndicesSectorServices()
