import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'

const GAP_ITEM = 8
const MARGIN_LEFT = 16

const TdSelect = ({
  val,
  rowId,
  dataTable,
  props,
  onRowCollapse,
  listDataItemCollapse,
  listSelectRowIIC,
}) => {
  const refChecked = useRef()

  const [isCollapse, setIsCollapse] = useState(false)

  const lv1 = dataTable
    .filter((item) => item.iivnTypeLevel === 1)
    .some((item) => item.iivnTypeName === val)
  const lv2 = dataTable
    .filter((item) => item.iivnTypeLevel === 2)
    .some((item) => item.iivnTypeName === val)

  const handleChecked = () => {
    return listSelectRowIIC?.some((item) => item.iivnTypeId === rowId)
  }

  const dataTableItem = listDataItemCollapse?.find(
    (item) => item.iivnTypeId === rowId,
  )

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <td
      {...props}
      style={{
        ...props.style,
        gap: GAP_ITEM,
        cursor: 'pointer',
      }}
    >
      <div className="d-flex align-center">
        <div ref={refChecked}>
          <input
            type="checkbox"
            checked={handleChecked()}
            className={`checkbox`}
            style={{ marginRight: '8px', cursor: 'pointer' }}
            onChange={() => {}}
          />
        </div>
        <div
          className="d-flex"
          style={{
            marginLeft: (dataTableItem?.iivnTypeLevel - 1) * MARGIN_LEFT || 0,
          }}
        >
          {dataTableItem?.isCollapsible ? (
            <button
              style={{ cursor: 'pointer' }}
              className="align-center"
              onClick={onClickCollapse}
            >
              <i
                className={`${
                  isCollapse ? 'icon-caret-right' : 'icon-caret-down'
                }`}
                style={{ fontSize: '8px', color: '#8c9194' }}
              />
            </button>
          ) : (
            <></>
          )}
        </div>
        <div
          style={{
            width: `calc(100% - ${refChecked.current?.offsetWidth}px)`,
          }}
        >
          {lv1 ? (
            <TextEllipsisStaticLine
              isI18n={false}
              val={val}
              appendStyle={{
                color: '#fecf2f',
              }}
            />
          ) : lv2 ? (
            <TextEllipsisStaticLine
              isI18n={false}
              val={val}
              appendStyle={{
                color: '#ececec',
                marginLeft: MARGIN_LEFT * 2 - GAP_ITEM,
              }}
            />
          ) : (
            <TextEllipsisStaticLine
              isI18n={false}
              val={val}
              appendStyle={{
                color: '#ececec',
                marginLeft: MARGIN_LEFT * 3 - GAP_ITEM,
              }}
            />
          )}
        </div>
      </div>
    </td>
  )
}

TdSelect.propTypes = {
  val: PropTypes.string,
  rowId: PropTypes.number,
  dataTable: PropTypes.array,
  onRowCollapse: PropTypes.func,
  listDataItemCollapse: PropTypes.any,
}

export default TdSelect
