import { common } from './common'
import { coveredWarrant } from './coveredWarrant'
import { derivative } from './derivative'
import * as equityTrading from './equityTrading'
import { event } from './event'
import { heatmap } from './heatmap'
import * as marketInDepth from './marketInDepth'
import { newsOrReport } from './newsOrReport'
import { sectorInDepth } from './sectorInDepth'
import { topMover } from './topMover'
import { marginLoans } from './marginLoans'

const market = {
  common,
  marketInDepth,
  equityTrading,
  heatmap,
  event,
  topMover,
  sectorInDepth,
  derivative,
  coveredWarrant,
  newsOrReport,
  marginLoans,
}

export default market
