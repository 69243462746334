import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'
import { handleExport } from '../../../../../utils/Export'
import { TIME_FILTER_TYPE } from '../constants'

const NOT_ACC_VALUE = 'NotAccValue'
const ACC_VALUE = 'AccValue'
const IMPORT = 'Import'
const EXPORT = 'Export'

export const getImportByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportByProduct',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByProduct(params)
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_FILTER_TYPE.MONTHLY
          ? ACC_VALUE
          : NOT_ACC_VALUE,
      ExImConstant: IMPORT,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByProductChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByProductChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByProduct(params)
    const responseName = await OverviewService.getLocationName()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByProductAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByProductAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByProductChildrenAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByProductChildrenAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportByProduct',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByProduct(params)
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_FILTER_TYPE.MONTHLY
          ? ACC_VALUE
          : NOT_ACC_VALUE,
      ExImConstant: EXPORT,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }

    return rejectWithValue(response.errors)
  },
)

export const getExportByProductChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByProductChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByProduct(params)
    const responseName = await OverviewService.getLocationName()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByProductAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByProductAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByProductChildrenAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExortByProductChildrenAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByProductAfterChangeFilter = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByProductAfterChangeFilter',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByProduct({
      DataType: params.DataType,
      Limit: params.Limit,
    })
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_FILTER_TYPE.MONTHLY
          ? ACC_VALUE
          : NOT_ACC_VALUE,
      ExImConstant: IMPORT,
    })

    const responseChildren = await OverviewService.getImportByProduct({
      DataType: params.DataType,
      Limit: params.Limit,
      vnTypeIds: params.vnTypeIds,
    })
    const responseChildrenName = await OverviewService.getLocationName()

    if (
      response.success &&
      responseName.success &&
      responseChildren.success &&
      responseChildrenName.success
    ) {
      return {
        data: response.data,
        nameData: responseName.data,
        childrenData: responseChildren.data,
        childrenNameData: responseChildrenName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByProductAfterChangeFilter = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByProductAfterChangeFilter',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByProduct({
      DataType: params.DataType,
      Limit: params.Limit,
    })
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_FILTER_TYPE.MONTHLY
          ? ACC_VALUE
          : NOT_ACC_VALUE,
      ExImConstant: EXPORT,
    })

    const responseChildren = await OverviewService.getExportByProduct({
      DataType: params.DataType,
      Limit: params.Limit,
      vnTypeIds: params.vnTypeIds,
    })
    const responseChildrenName = await OverviewService.getLocationName()

    if (
      response.success &&
      responseName.success &&
      responseChildren.success &&
      responseChildrenName.success
    ) {
      return {
        data: response.data,
        nameData: responseName.data,
        childrenData: responseChildren.data,
        childrenNameData: responseChildrenName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportDownloadByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportDownloadByProduct',
  async (data) => {
    const response = await OverviewService.getImportDownloadByProduct(data)
    handleExport(response.data, response.filename)
  },
)

export const getExportDownloadByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportDownloadByProduct',
  async (data) => {
    const response = await OverviewService.getExportDownloadByProduct(data)
    handleExport(response.data, response.filename)
  },
)
