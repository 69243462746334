import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import Popup from '../../common/popup'
import stylePopup from '../../common/popup/index.module.css'
import { Z_INDEX } from '../../constants/Common'
import { zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'

const PopupPrevious = ({ handleClosePopup, form, handleHideDetail }) => {
  const error = form.useValueError('watchListName')

  return (
    <Popup
      zIndex={Z_INDEX.MODAL + 1}
      zIndexOverlay={zIndexOverlayPopup.alertEvent}
    >
      <div className="modal alert">
        <a
          onClick={handleClosePopup}
          className={`close-modal ${stylePopup.closeIcon}`}
        >
          <i className="icon-delete-stroke" />
        </a>
        <div className="modal-content">
          <p className={`icon-alert ${stylePopup.margin0}`}>
            <i className={`icon-warning ${stylePopup.fontSize29}`} />
          </p>
          <div className={`content ${style.textJustify}`}>
            <Translate value="watchlist.watchlistDetail.ASK_1" />
            <br />
            <Translate value="watchlist.watchlistDetail.ASK_2" />
          </div>
          <div className="group-btn mb-10 d-flex w-100">
            <Button
              onClick={handleHideDetail}
              className={`btn normal h-20 ${style.mr16} ${style.fontWeightNormal} ${style.btnDontSave}`}
            >
              <Translate value="watchlist.watchlistDetail.BTN_NOT_SAVE" />
            </Button>
            <div className="justify-content-end d-flex">
              <Button
                onClick={handleClosePopup}
                className={`btn normal h-20 ${style.BtnCancel} ${style.fontWeightNormal}`}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={error ? undefined : form.handleSubmit}
                className={`btn btn-blue h-20 ${style.W80} ${
                  style.fontWeightNormal
                } ${error ? 'diasble' : ''}`}
              >
                <Translate value="common.button.BUTTON_SAVE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupPrevious.propTypes = {
  handleClosePopup: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  handleHideDetail: PropTypes.func.isRequired,
}

const ButtonPrevious = ({ handleHideDetail, form }) => {
  const [isShowPopupPrevious, setIsShowPopupPrevious] = useState(false)

  const handleOpenPopup = () => setIsShowPopupPrevious(true)

  const handleClosePopup = () => setIsShowPopupPrevious(false)

  return (
    <>
      <Button onClick={handleOpenPopup} className="btn normal">
        <Translate value="watchlist.watchlistDetail.BTN_PREVIOUS" />
      </Button>
      {isShowPopupPrevious && (
        <PopupPrevious
          handleHideDetail={handleHideDetail}
          form={form}
          handleClosePopup={handleClosePopup}
        />
      )}
    </>
  )
}

ButtonPrevious.propTypes = {
  form: PropTypes.object.isRequired,
  handleHideDetail: PropTypes.func.isRequired,
}

export default ButtonPrevious
