import { TYPE } from '../../footer/Item'

export const getFooterData = (data) => {
  return data.map((item) => ({
    type: TYPE.CIRCLE,
    before: {
      bgColor: item.color,
    },
    ...item,
  }))
}
