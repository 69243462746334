import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import style from './index.module.css'

export const LightBorderColumnCell = ({
  val,
  rowId,
  isAlignTextLeft,
  isTicker,
}) => {
  return (
    <td
      className={`${style.borderRightColumnCellTd} ${
        isAlignTextLeft ? style.alignTextLeft : style.alignTextRight
      }`}
    >
      {isTicker && val.length ? (
        <LinkTicker organizationId={rowId}>{val}</LinkTicker>
      ) : (
        val
      )}
    </td>
  )
}
