import { createAsyncThunk } from '@reduxjs/toolkit'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import SimpleSearchService from '../../../../../core/services/common/SimpleSearchService'

export const getSearchIndex = createAsyncThunk(
  'common/topInfo/index/GET_SEARCH_INDIES',
  async (params, { rejectWithValue }) => {
    const response = await SimpleSearchService.searchIndies(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getLatestPrice = createAsyncThunk(
  'common/indexTopInfo/GET_LATEST_PRICE',
  async (params, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
