import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import TextEllipsis from '../../../../common/textEllipsis'
import { FILTER_BOND_INFORMATION_TYPES } from '../../constants'
import {
  changeLoadingSearchBond,
  selectFilterSearchBondAnhUserBond,
} from '../../store/slice'
import {
  fetchBondAndUserBondData,
  getBondAndUserBondData,
  getBondDetailData,
} from '../../store/thunk'
import style from './index.module.css'

export const FilterBond = ({ width }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const { loading, data, page, pageSize, enableInfinity } = useSelector(
    selectFilterSearchBondAnhUserBond,
  )

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handleClickItem = (item) => {
    const params = {
      [FILTER_BOND_INFORMATION_TYPES.BOND_ID]: item.bondId,
      [FILTER_BOND_INFORMATION_TYPES.IS_USER_BOND]: item?.isUserBond ?? false,
    }

    dispatch(getBondDetailData(params))
    handleHide()
  }

  const handleSearchMore = () => {
    if (!enableInfinity) {
      return
    }

    const payload = {
      Language: locale,
      Page: page,
      PageSize: pageSize,
    }

    if (!!keySearch.length) {
      payload.Term = keySearch
    }

    dispatch(fetchBondAndUserBondData(payload))
  }

  useEffect(() => {
    if (isShowDropdown) {
      dispatch(changeLoadingSearchBond(true))

      const timeout = setTimeout(() => {
        const payload = {
          Language: locale,
          Page: 1,
          PageSize: 10,
        }

        if (!!keySearch.length) {
          payload.Term = keySearch
        }

        dispatch(getBondAndUserBondData(payload))
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [keySearch.length, isShowDropdown])

  const placeholderInput = useMemo(
    () => I18n.t('bond.portfolio.bondInformation.ADD_BOND_TICKER'),
    [locale],
  )

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {loading ? (
                <div style={{ height: 29 }}>
                  <Loading />
                </div>
              ) : !!data?.length ? (
                <ScrollComponent
                  autoHeight={true}
                  autoHeightMax={200}
                  scrollToBottomCallback={handleSearchMore}
                >
                  <ul className="list-check">
                    {data.map((item, index) => {
                      return (
                        <li
                          key={`key-${index}`}
                          onClick={() => handleClickItem(item)}
                        >
                          <a style={{ height: 20, padding: '0 8px', gap: 16 }}>
                            <label className="d-flex ali-center cursor-pointer">
                              <TextEllipsis
                                text={`${
                                  item?.bondTicker?.toUpperCase() ??
                                  item?.ticker?.toUpperCase() ??
                                  ''
                                } - ${item?.organizationName ?? ''}`}
                                appendStyle={{ fontWeight: 340, flex: 1 }}
                                isI18n={false}
                                zIndexTooltip={999}
                              />

                              <Span
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginLeft: 8,
                                }}
                              >
                                {item?.isUserBond && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8 12L3.87163 14.1704C3.63687 14.2938 3.36249 14.0945 3.40733 13.8331L4.19577 9.23607L0.855851 5.98045C0.665926 5.79532 0.770729 5.47277 1.0332 5.43463L5.64886 4.76393L7.71305 0.581435C7.83043 0.343596 8.16958 0.343597 8.28696 0.581435L10.3511 4.76393L14.9668 5.43463C15.2293 5.47277 15.3341 5.79532 15.1441 5.98045L11.8042 9.23607L12.5927 13.8331C12.6375 14.0945 12.3631 14.2938 12.1284 14.1704L8 12Z"
                                      fill="url(#paint0_linear_186_480)"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_186_480"
                                        x1="-4.37204"
                                        y1="11.7128"
                                        x2="11.7692"
                                        y2="20.5613"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#23DAE7" />
                                        <stop offset="1" stopColor="#3597FF" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                )}
                              </Span>
                            </label>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
