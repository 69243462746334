import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeChartTabActive,
  selectChartTabsData,
  setChartActiveID,
} from '../../chart/store/slice'
import { postCharts } from '../../chart/store/thunk'
import { screenTypes } from '../../constants'
import {
  addChart,
  changeDashboard,
  selectDashboard,
  selectDashboardChartData,
} from '../../store/slice'
import { updateAndReloadDashboard } from '../../store/thunk'
import { getChartNameBySampleName, getChartOrderByListChart } from '../helpers'
import { changeChartEditNameID } from './store/slice'

const usePanelChart = (item, isShowEditDB = false, indexChartEdit = null) => {
  const dispatch = useDispatch()

  // Use selector
  const chartTabs = useSelector(selectChartTabsData)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const { data = {} } = useSelector(selectDashboard)

  // Actions
  const editChart = useCallback(() => {
    if (isShowEditDB) {
      dispatch(changeDashboard({ screenType: screenTypes.EDIT_CHART }))
    }
    if (indexChartEdit) {
      dispatch(changeChartTabActive(indexChartEdit))
      dispatch(setChartActiveID(item?.id))
    }
  }, [isShowEditDB, indexChartEdit, item?.id])

  const duplicateChart = useCallback(async () => {
    const param = {
      dashboardId: data.id,
      linked: false,
      name: getChartNameBySampleName(
        Object.values(chartTabs).map((i) => i.nameChart?.name),
        item?.detail?.nameChart?.name || item.name,
      ),
      detail: JSON.stringify({
        ...item.detail,
        nameChart: {
          ...item.detail?.nameChart,
          name: getChartNameBySampleName(
            Object.values(chartTabs).map((i) => i.nameChart?.name),
            item?.detail?.nameChart?.name || item.name,
          ),
        },
        order: getChartOrderByListChart(dashboardChartData),
      }),
      order: getChartOrderByListChart(dashboardChartData),
    }
    await dispatch(postCharts(param))
      .unwrap()
      .then(async (dataChart) => {
        dispatch(addChart(dataChart))
        dispatch(
          updateAndReloadDashboard({
            ...data,
          }),
        )
        dispatch(changeChartEditNameID(dataChart.id))
      })
  }, [
    JSON.stringify(data),
    JSON.stringify(chartTabs),
    JSON.stringify(item),
    JSON.stringify(dashboardChartData),
  ])

  return {
    editChart,
    duplicateChart,
  }
}

export default usePanelChart
