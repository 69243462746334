import { useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal, valDivMillion } from '../../../../../utils/Value'
import { valueTab } from './constants'
import style from './index.module.css'
import { selectActive, selectData } from './store/slice'

const checkColor = (value) => {
  const number = parseFloat(value)
  if (number > 0) {
    return '#59c761'
  }
  if (number < 0) {
    return ' #ff4752'
  }
  return '#ffc31e'
}

const BodyTopLeft = ({ widthTopLeft }) => {
  const data = useSelector(selectData)
  const active = useSelector(selectActive)
  const typePrice = active === valueTab.dirtyPrice ? true : false
  return (
    <div className={style.column}>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.change1D'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 340,
              color: typePrice
                ? checkColor(data?.tradingInformation?.dirtyChange1Day)
                : checkColor(data?.tradingInformation?.cleanChange1Day),
            }}
          >
            <TextEllipsis
              isI18n={false}
              text={`${
                typePrice
                  ? formatVal(data?.tradingInformation?.dirtyChange1Day * 100)
                  : formatVal(data?.tradingInformation?.cleanChange1Day * 100)
              }%`}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.change1W'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft, textAlign: 'end' }}>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 340,
              color: typePrice
                ? checkColor(data?.tradingInformation?.dirtyChange1Week)
                : checkColor(data?.tradingInformation?.cleanChange1Week),
            }}
          >
            <TextEllipsis
              isI18n={false}
              text={`${
                typePrice
                  ? formatVal(data?.tradingInformation?.dirtyChange1Week * 100)
                  : formatVal(data?.tradingInformation?.cleanChange1Week * 100)
              }%`}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.change1M'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft }}>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 340,
              textAlign: 'end',
              color: typePrice
                ? checkColor(data?.tradingInformation?.dirtyChange1Month)
                : checkColor(data?.tradingInformation?.cleanChange1Month),
            }}
          >
            <TextEllipsis
              isI18n={false}
              text={`${
                typePrice
                  ? formatVal(data?.tradingInformation?.dirtyChange1Month * 100)
                  : formatVal(data?.tradingInformation?.cleanChange1Month * 100)
              }%`}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.changeYTD'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft }}>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 340,
              textAlign: 'end',
              color: typePrice
                ? checkColor(data?.tradingInformation?.dirtyChangeYTD)
                : checkColor(data?.tradingInformation?.cleanChangeYTD),
            }}
          >
            <TextEllipsis
              isI18n={false}
              text={`${
                typePrice
                  ? formatVal(data?.tradingInformation?.dirtyChangeYTD * 100)
                  : formatVal(data?.tradingInformation?.cleanChangeYTD * 100)
              }%`}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.TotalValue'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopLeft }}>
          <Span style={{ fontSize: 12, fontWeight: 340, textAlign: 'end' }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(
                valDivMillion(data?.tradingInformation?.totalValue),
              )}
            />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default BodyTopLeft
