import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import { Button } from '../html/Button'
import Popup from './index'
import style from './index.module.css'

export const POPUP_ICON_STATUS = {
  WARNING: 'warning',
  LIKE: 'like',
}

const PopupAlert = ({
  message,
  isShow,
  handleClose,
  zIndexOverlay,
  zIndex,
  buttonText,
  iconStatus,
  isShowButton,
  modalStyle,
  buttonContainerStyle,
  buttonStyle,
  customButton,
}) => {
  return (
    <>
      {isShow && (
        <Popup zIndex={zIndex} zIndexOverlay={zIndexOverlay}>
          <div style={modalStyle} className="modal alert">
            <a
              onClick={handleClose}
              className={`close-modal ${style.closeIcon}`}
            >
              <i className="icon-delete-stroke" />
            </a>
            <div className="modal-content">
              <p className={`icon-alert ${style.margin0}`}>
                {iconStatus === POPUP_ICON_STATUS.WARNING ? (
                  <i className={`icon-warning ${style.fontSize29}`} />
                ) : iconStatus === POPUP_ICON_STATUS.LIKE ? (
                  <img src="/iconLike.svg" alt="Like" width={29} height={29} />
                ) : (
                  ''
                )}
              </p>
              <div
                className={`content d-flex justify-content-center ${style.textJustify}`}
              >
                {message}
              </div>
              {isShowButton &&
                (customButton ?? (
                  <div
                    style={buttonContainerStyle}
                    className="group-btn justify-content-end mb-10"
                  >
                    <Button
                      onClick={handleClose}
                      className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                      style={{ fontSize: 11, ...buttonStyle }}
                    >
                      {<Translate value={buttonText} />}
                    </Button>
                  </div>
                ))}
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

PopupAlert.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  zIndexOverlay: PropTypes.number,
  buttonText: PropTypes.string,
  iconStatus: PropTypes.string,
  isShowButton: PropTypes.bool,
  modalStyle: PropTypes.object,
  customButton: PropTypes.element,
}

PopupAlert.defaultProps = {
  zIndex: Z_INDEX.MODAL,
  zIndexOverlay: Z_INDEX.MODAL + 1,
  buttonText: 'common.button.BUTTON_CANCEL',
  iconStatus: POPUP_ICON_STATUS.WARNING,
  isShowButton: true,
  modalStyle: {},
}

export default PopupAlert
