import { useEffect, useState } from 'react'
import chartLayoutService from '../../../../core/services/common/ChartLayoutService'
import { SharePopup } from '../../shareButton/SharePopup'
import { withWidget } from '../context'

const ShareTemplate = ({ changeSharePopupStatus, widget }) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    widget.save((chartState) => {
      chartLayoutService
        .getChartLayoutShareInfo({
          chartLayout: JSON.stringify(chartState),
        })
        .then((res) => {
          const searchParams = new URLSearchParams(res.data)
          const url = `${
            window.location.origin
          }/charting?${searchParams.toString()}`
          setUrl(url)
        })
    })
  }, [])

  return <SharePopup url={url} setIsShow={changeSharePopupStatus} />
}

export default withWidget(ShareTemplate)
