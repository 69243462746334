import { range } from 'd3-array'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { languageObjs } from '../../../../../configs/Language.js'
import { useGetMonths } from '../../../../common/calendar/helper.js'
import SelectCustom from '../../../../common/selectCustom/index.js'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime.js'
import {
  CHART_TYPE,
  MIN_DATE_FILTER,
  TIME_FREQUENCY,
} from '../../common/constants'
import { getLatestTime } from '../../common/helper.js'
import iconColumnChart from '../../common/icons/iconColumnChart.svg'
import iconPieChart from '../../common/icons/iconPieChart.svg'
import {
  enMonthsLowerCase,
  viMonthsLowerCase,
} from '../../overview/constants.js'
import { selectTimeFrequency } from '../totalRegisteredFDI/store/slice.js'
import {
  changeFilterMonth,
  changeFilterYear,
  changeFilterYearByYearly,
  changeTypeChart,
  selectChartType,
  selectFilterMonth,
  selectFilterYear,
  selectRetryFlag,
  selectYearByYearly,
} from './store/slice.js'
import { getDataColumnChart, getDataPieChart } from './store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const MONTHS = useGetMonths(false, true)
  const YEARS = range(
    MIN_DATE_FILTER,
    getCurrentDateTime(FORMAT.YEAR) * 1 + 1,
  ).reverse()

  const locale = useSelector((state) => state.i18n.locale)
  const filterMonth = useSelector(selectFilterMonth)
  const filterYear = useSelector(selectFilterYear)
  const chartType = useSelector(selectChartType)
  const timeFrequency = useSelector(selectTimeFrequency)
  const filterYearByYearly = useSelector(selectYearByYearly)
  const retryFlag = useSelector(selectRetryFlag)

  const onMonthChange = (value) => {
    dispatch(changeFilterMonth(value))
  }

  const onYearChange = (value) => {
    if (timeFrequency === TIME_FREQUENCY.ACC_MONTHLY) {
      dispatch(changeFilterYear(value))
    } else {
      dispatch(changeFilterYearByYearly(value))
    }
  }

  const handleIconClick = () => {
    dispatch(
      changeTypeChart(
        chartType === CHART_TYPE.PIE_CHART
          ? CHART_TYPE.BAR_CHART
          : CHART_TYPE.PIE_CHART,
      ),
    )
  }

  useEffect(() => {
    const payload = {
      Month: timeFrequency === TIME_FREQUENCY.ACC_MONTHLY ? filterMonth : 12,
      Year:
        timeFrequency === TIME_FREQUENCY.ACC_MONTHLY
          ? filterYear
          : filterYearByYearly,
      TimeFrequency: timeFrequency,
    }
    if (!retryFlag)
      if (chartType === CHART_TYPE.PIE_CHART) {
        dispatch(getDataPieChart(payload))
      } else {
        dispatch(getDataColumnChart(payload))
      }
  }, [
    locale,
    filterMonth,
    filterYear,
    chartType,
    timeFrequency,
    filterYearByYearly,
  ])

  useEffect(() => {
    const paramsArr =
      timeFrequency === TIME_FREQUENCY.ACC_MONTHLY
        ? getLatestTime(10).map(({ year, month }) => ({
            Month: month,
            Year: year,
            TimeFrequency: timeFrequency,
          }))
        : range(
            getCurrentDateTime(FORMAT.YEAR) * 1 - 10,
            getCurrentDateTime(FORMAT.YEAR) * 1 + 1,
          )
            .reverse()
            .map((year) => ({
              Month: 12,
              Year: year,
              TimeFrequency: timeFrequency,
            }))

    if (retryFlag)
      if (chartType === CHART_TYPE.PIE_CHART) {
        dispatch(getDataPieChart(paramsArr))
      } else {
        dispatch(getDataColumnChart(paramsArr))
      }
  }, [retryFlag])

  return (
    <div className="d-flex justify-content-space-between">
      <div className="cursor-pointer" onClick={handleIconClick}>
        <img
          src={
            chartType === CHART_TYPE.PIE_CHART ? iconColumnChart : iconPieChart
          }
          alt="iconchart"
        />
      </div>
      <div className="d-flex">
        {timeFrequency === TIME_FREQUENCY.ACC_MONTHLY ? (
          <div style={{ width: 120 }}>
            <SelectCustom
              value={filterMonth}
              selectData={MONTHS.map((month, index) => ({
                name:
                  locale === languageObjs.en
                    ? enMonthsLowerCase[index]
                    : viMonthsLowerCase[index],
                value: index + 1,
              }))}
              handleChange={onMonthChange}
            />
          </div>
        ) : null}
        <div className="w-80 ml-10">
          <SelectCustom
            value={
              timeFrequency === TIME_FREQUENCY.ACC_MONTHLY
                ? filterYear
                : filterYearByYearly
            }
            selectData={YEARS.map((year) => ({
              name: year,
              value: year,
            }))}
            handleChange={onYearChange}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
