import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../dropdown'
import { Span } from '../../../html/Span'
import style from './index.module.css'

const SETTINGS = {
  fontSize: 11,
  fontStyle: 'normal',
  fontWeight: 500,
  color: '#fdfdfd',
}

export const ChartTooltip = ({
  parentRef,
  data,
  isOpen,
  tooltipContentData,
}) => {
  return (
    <Dropdown
      parentRef={parentRef}
      isShow={isOpen}
      position={positionDropdown.TOP_CENTER}
    >
      <div className={`box-notice ${style.tooltipContainer}`}>
        {tooltipContentData.map((item) => (
          <div key={item.keyValue} className={`d-flex j-b mb-8 ${style.mb8px}`}>
            {item.label && (
              <label className={style.mr12px}>
                <Span
                  style={{
                    fontSize: item.fontSize || SETTINGS.fontSize,
                    fontStyle: item.fontStyle || SETTINGS.fontStyle,
                    color: SETTINGS.color,
                    fontWeight: 400,
                  }}
                >
                  <Translate value={item.label} />:
                </Span>
              </label>
            )}
            <div>
              <Span
                style={{
                  fontSize: item.fontSize || SETTINGS.fontSize,
                  fontStyle: item.fontStyle || SETTINGS.fontStyle,
                  fontWeight: item.fontWeightValue || SETTINGS.fontWeight,
                  color: SETTINGS.color,
                }}
              >
                {item.formatValue(data[item.keyValue])}
              </Span>
            </div>
          </div>
        ))}
      </div>
    </Dropdown>
  )
}

ChartTooltip.propTypes = {
  parentRef: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  tooltipContentData: PropTypes.array,
}
