export const tableAttr = [
  {
    name: 'corporate.peerValuation.consensus.HIGHEST',
    keyValue: 'maxHighestPrice',
    keyValueFormat: 10 ** 3,
    keyPercentage: 'highestPercentage',
  },
  {
    name: 'corporate.peerValuation.consensus.AVERAGE',
    keyValue: 'avg',
    keyValueFormat: 10 ** 3,
    keyPercentage: 'avgPercentage',
  },
  {
    name: 'corporate.peerValuation.consensus.LOWEST',
    keyValue: 'minHighestPrice',
    keyValueFormat: 10 ** 3,
    keyPercentage: 'lowestPercentage',
  },
]

export const textColors = {
  GREATER: '#00de8b',
  LESS: '#eb505a',
}

export const textUpDown = {
  UPSIDE: 'corporate.peerValuation.consensus.UPSIDE',
  DOWNSIDE: 'corporate.peerValuation.consensus.DOWNSIDE',
}
