import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeFrame as selectPeriodPriceChart } from '../priceChart/store/slice'
import { getPriceChartData } from '../priceChart/store/thunk'
import { selectPage, selectPageSize } from '../report/store/slice'
import { getReportData } from '../report/store/thunk'
import { selectTimeFrame as selectPeriodValuationChart } from '../valuationChart/store/slice'
import { getLineChart } from '../valuationChart/store/thunk'
import { getValuationTableData } from '../valuationTable/store/thunk'
import Compare from './Compare'
import Indicator from './Indicator'
import ListItem from './ListItem'
import Reset from './Reset'
import { EXCHANGE_CODE } from './constant'
import ComparePopup from './popup'
import {
  resetStore,
  selectCallDataFlag,
  selectSelectedItems,
} from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <div className="flex-column j-b h-100">
      <Indicator />
      <Compare />
      <ListItem />
      <Reset />
      <ComparePopup />
      <ListentCallData />
    </div>
  )
}

const ListentCallData = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const callDataFlag = useSelector(selectCallDataFlag)
  const selectedItems = useSelector(selectSelectedItems)
  const priceChartPeriod = useSelector(selectPeriodPriceChart)
  const valuationChartPeriod = useSelector(selectPeriodValuationChart)
  const reportPage = useSelector(selectPage)
  const reportPageSize = useSelector(selectPageSize)

  const handleCallData = (onlyReportData = false) => {
    if (!selectedItems.length) return

    const body = {
      exchangeCode: EXCHANGE_CODE.ALL_EXCHANGE,
      icbIds: selectedItems
        .filter((item) => !!item.icbId && !item.isDeleted)
        .map((item) => item.icbId),
      organizationIds: selectedItems
        .filter((item) => !!item.organizationId)
        .map((item) => item.organizationId),
    }

    if (onlyReportData) {
      dispatch(
        getReportData({
          ...body,
          page: reportPage || 1,
          pageSize: reportPageSize,
        }),
      )
    } else {
      dispatch(
        getLineChart({
          ...body,
          period: valuationChartPeriod,
        }),
      )
      dispatch(
        getPriceChartData({
          ...body,
          period: priceChartPeriod,
        }),
      )
      dispatch(getReportData({ ...body, page: 1, pageSize: reportPageSize }))
      dispatch(getValuationTableData(body))
    }
  }

  useEffect(() => {
    handleCallData()
  }, [callDataFlag])

  useEffect(() => {
    handleCallData(true)
  }, [locale, reportPage])

  return <></>
}

export default Filter
