import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import { getTimeFrequencyIndicator } from '../../../chart/helper'
import { equationIndicatorLimit, indicatorDefaultData } from '../constants'
import { getIndicatorAlias, getIndicatorAliasUsed } from '../helpers'
import {
  changeEquationIndicators,
  removeEquationIndicator,
  selectEquationIndicators,
} from '../store/slice'
import style from './style.module.css'

const MetricItemTree = ({ data, onGetIndicatorLatestPeriod }) => {
  const dispatch = useDispatch()

  const equationIndicators = useSelector(selectEquationIndicators)

  const arrChildren = Object.values(data.children)
  const [isCollapse, setIsCollapse] = useState(false)

  const handleClickParents = () => {
    if (arrChildren.length) {
      setIsCollapse(!isCollapse)
      return
    }

    if (Object.keys(equationIndicators).includes(data.id)) {
      dispatch(removeEquationIndicator({ id: data.id }))
    } else {
      if (
        getIndicatorAliasUsed(equationIndicators).length <
        equationIndicatorLimit
      ) {
        onGetIndicatorLatestPeriod(
          data.idPayload,
          getTimeFrequencyIndicator(data),
        )
        dispatch(
          changeEquationIndicators({
            id: data.id,
            value: {
              indicatorId: data.idPayload,
              indicatorTypeId: data.id,
              nameDisplay: data.nameDisplay,
              unit: data.unit,
              timeFrequency: getTimeFrequencyIndicator(data),
              data: {
                0: {
                  ...indicatorDefaultData,
                  alias: getIndicatorAlias(equationIndicators),
                },
              },
            },
          }),
        )
      }
    }
  }

  const handleClickChildren = (item) => {
    if (Object.keys(equationIndicators).includes(item.id)) {
      dispatch(removeEquationIndicator({ id: item.id }))
    } else {
      if (
        getIndicatorAliasUsed(equationIndicators).length <
        equationIndicatorLimit
      ) {
        onGetIndicatorLatestPeriod(item.idPayload, item.timeFrequency)
        dispatch(
          changeEquationIndicators({
            id: item.id,
            value: {
              indicatorId: item.idPayload,
              indicatorTypeId: item.id,
              nameDisplay: item.nameDisplay,
              unit: item.unit,
              timeFrequency: item.timeFrequency,
              data: {
                0: {
                  ...indicatorDefaultData,
                  alias: getIndicatorAlias(equationIndicators),
                },
              },
            },
          }),
        )
      }
    }
  }

  return (
    <div>
      <div
        className={style.indicator}
        key={data.id}
        onClick={handleClickParents}
      >
        <div
          className={`${style.indicatorName} d-flex ali-center w-100`}
          style={{
            color: Object.keys(equationIndicators).includes(String(data.id))
              ? '#2f9aee'
              : '#4E4E4E',
          }}
        >
          {arrChildren.length ? (
            <>
              {isCollapse ? (
                <i className={`${style.i} icon-caret-down`} />
              ) : (
                <i className={`${style.i} icon-caret-right`} />
              )}
            </>
          ) : null}
          <TextEllipsis isI18n={false} text={data.name} zIndexTooltip={12} />
        </div>
        {!arrChildren.length ? (
          <div
            className={style.indicatorRadio}
            style={{
              opacity: Object.keys(equationIndicators).includes(String(data.id))
                ? 1
                : 0,
            }}
          >
            <input
              type="radio"
              className={`radiobox radiobox2 cursor-pointer`}
              checked={Object.keys(equationIndicators).includes(
                String(data.id),
              )}
              readOnly
            />
          </div>
        ) : null}
      </div>
      {isCollapse &&
        arrChildren.map((itemChildren) => (
          <div
            className={style.indicator}
            style={{ marginLeft: 34 }}
            key={itemChildren.id}
            onClick={() => handleClickChildren(itemChildren)}
          >
            <div
              className={`${style.indicatorName} d-flex ali-center w-100`}
              style={{
                color: Object.keys(equationIndicators).includes(
                  String(itemChildren.id),
                )
                  ? '#2f9aee'
                  : '#4E4E4E',
              }}
            >
              <TextEllipsis
                isI18n={false}
                text={itemChildren.name}
                zIndexTooltip={12}
              />
            </div>
            <div className={style.indicatorCheckbox}>
              <input
                type="checkbox"
                className={`checkbox line `}
                checked={Object.keys(equationIndicators).includes(
                  String(itemChildren.id),
                )}
                value={itemChildren.id}
                readOnly
              />
            </div>
          </div>
        ))}
    </div>
  )
}

export default MetricItemTree
