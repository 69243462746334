import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FDIByIndustriesService from '../../../../../../core/services/economy/fdi/FDIByIndustriesService'

export const getInvestmentStructure = createAsyncThunk(
  'economy/fdi/fdiIndustries/GET_DATA_GET_INVESTMENT_STRUCTURE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByIndustriesService.getFDIStructureByIndustry(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getVSICName = createAsyncThunk(
  'economy/fdi/fdiIndustries/GET_VSIC_NAME',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByIndustriesService.getVSICName(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
