export const title = {
  structure: 'Structure',
  overview: 'OVERVIEW',
  detailOwnership: 'Detail Ownership',
  majorOwnership: 'MAJOR OWNERSHIP',
}

export const chart = {
  ForeignOwned: 'Foreign-Owned',
  StateOwned: 'State-Owned',
  Others: 'Others',
  Management: 'Management',
  Funds: 'Funds',
}

export const ownership = {
  MAJOR_SHAREHOLDER: 'ShareHolders',
  MANAGEMENT_AND_DIRECTOR: 'Management and directors',
  DIRECTOR_DEALS: 'Director deals',
  SUBSIDIARIES: 'Subsidiaries',
  ASSOCIATE: 'Associate',
  INTERNAL_TRANSACTIONS: 'Internal Transactions',
  TOTAL: 'Total',
}

export const majorShareHolder = {
  TICKER: 'name',
  SHARES: 'shares',
  PERCENTAGE: 'percentage',
  LAST_UPDATE: 'last update',
  LATEST_TRANSACTION: 'Last Transaction',
  SHARE_VOLUME: 'share volume',
  PRICE_RANGE: 'Estimate Value',
  TRANSACTION_DATES: 'transaction date',
  THOUSAND: '(thousand)',
  BILLION_VND: '(Billion VND)',
  EST_VALUE_TOOLTIP:
    'Estimate value is equal to share acquired multiplied close price at ending date',
}

export const hoverTooltip = {
  HISTORICAL_DEALS: 'Historical deals',
  OWNERSHIP: 'Ownership',
  START_DATE: 'Start date',
  TYPE: 'Type',
  REGISTER: 'Register',
  RESULT: 'result',
  SHARE: 'Share',
  BEFORE: '(Before)',
  AFTER: '(After)',
  PERCENTAGE: 'Percentage',
  TICKER: 'Ticker',
  QUANTITY: 'Quantity',
  UPDATE_DATE: 'Update date',
  VALUE: 'value',
  MILLION: '(million VND)',
}

export const director = {
  TICKER: 'name',
  TITLE: 'Title',
  SHARES: 'Shares',
  PERCENTAGE: 'Percentage',
  LAST_UPDATE: 'last update',
  SHARE_VOLUME: 'Share Volume',
  LATEST_TRANSACTION: 'latest transaction',
  PRICE_RANGE: 'Estimate Value',
  TRANSACTION_DATES: 'transaction date',
  THOUSAND: '(thousand)',
  BILLION_VND: '(Billion VND)',
  EST_VALUE_TOOLTIP:
    'Estimate value is equal to share acquired multiplied close price at ending date',
}

export const associate = {
  TICKER: '<b>Ticker</b>',
  ASSOCIATE: '<b>Subsidiaries & Associates</b>',
  STATUS: '<b>Status</b>',
  CHAPTER_CAPITAL:
    '<b>CHAPTER CAPITAL</b> <br/> <i style="font-weight: 300">(billion VND)</i>',
  CAPITAL_CONTRIBUTION:
    '<b>CAPITAL CONTRIBUTION</b> <br/> <i style="font-weight: 300">(billion VND)</i>',
  OWNERSHIP: '<b>% OWNERSHIP</b>',
  STATUS_DELISTED: 'Delisted',
  STATUS_LISTING: 'Listing',
  STATUS_PLAN: 'Plan',
  STATUS_UNLISTED: 'Unlisted',
}

export const subsidiaries = {
  TICKER: '<b>Ticker</b>',
  SUBSIDIARIES: '<b>Subsidiaries</b>',
  STATUS: '<b>Status</b>',
  CHAPTER_CAPITAL:
    '<b>CHARTER CAPITAL</b></br><i style="font-weight: 300">(billion VND)</i>',
  CAPITAL_CONTRIBUTION:
    '<b>CAPITAL CONTRIBUTION</b></br><i style="font-weight: 300">(billion VND)</i>',
  OWNERSHIP: '<b>% OWNERSHIP</b>',
  STATUS_DELISTED: 'Delisted',
  STATUS_LISTING: 'Listing',
  STATUS_PLAN: 'Plan',
  STATUS_UNLISTED: 'Unlisted',
}

export const deals = {
  NAME: 'Name',
  NUMBER_OF_TRANSACTION_SHARES: 'Number of transaction shares',
  ANNOUNCEMENT_DATE: 'Announcement date',
  TRANSACTION_DATES: 'Transaction dates',
}
