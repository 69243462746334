import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DropdownMoreDashboard from '.'
import { Span } from '../../../common/html/Span'
import {
  selectDashboardSelectedPopup,
  setDashBoardSelected,
  setDashBoardSelectedDetail,
} from './store/slice'
import style from './style.module.css'

const PopupContentMenu = ({
  buttonSaveRef,
  treeMenu,
  selectMultipleItems,
  isAddNewDashboard,
  newDashboardName,
  setNewDashboardName,
  setIsAddNewDashboard,
  setIsEnterAddNewDashboard,
  isChartLibrary,
  isShowChart,
  searchKey,
}) => {
  const dispatch = useDispatch()
  const dashboardSelected = useSelector(selectDashboardSelectedPopup)

  const [collapseOpen, setCollapseOpen] = useState(true)

  const getChildrenStyle = (val) => {
    if (isChartLibrary && !isShowChart) {
      return {
        cursor: 'no-drop',
        opacity: 0.4,
      }
    }
    if (val) {
      return {
        backgroundColor: '#afdff9',
        borderRadius: 'unset',
      }
    }
  }

  const getParentStyle = () => {
    if (isChartLibrary && !isShowChart && dashboardSelected === treeMenu.id) {
      return {
        backgroundColor: '#afdff9',
        borderRadius: 'unset',
      }
    }
  }

  const onClick = () => {
    if (isChartLibrary && !isShowChart) {
      dispatch(setDashBoardSelected(treeMenu.id))
      dispatch(setDashBoardSelectedDetail(treeMenu))
    } else {
      setCollapseOpen(!collapseOpen)
    }
  }

  return (
    <>
      <div
        className={style.contentMenuParent}
        onClick={onClick}
        style={getParentStyle()}
      >
        <Span style={{ fontSize: 12 }} className="d-flex ali-center">
          {isChartLibrary && !isShowChart ? (
            <div style={{ marginLeft: 16 }}></div>
          ) : collapseOpen ? (
            <i
              className="icon-caret-down mr-8"
              style={{ fontSize: 8, color: '#b0b7bb' }}
            />
          ) : (
            <i
              className="icon-caret-right mr-8"
              style={{ fontSize: 8, color: '#b0b7bb' }}
            />
          )}
          {treeMenu?.name}
        </Span>
      </div>
      {treeMenu?.children.length > 0 && (
        <div style={{ display: collapseOpen ? 'block' : 'none' }}>
          <DropdownMoreDashboard
            tree={treeMenu?.children}
            buttonSaveRef={buttonSaveRef}
            containerStyle={{ margin: 'unset' }}
            groupDashboardClass={style.groupDashboardClass}
            dashboardClass={style.dashboardClass}
            chartClass={style.chartClass}
            childrenStyle={getChildrenStyle}
            isAddNewDashboard={isAddNewDashboard}
            newDashboardName={newDashboardName}
            setNewDashboardName={setNewDashboardName}
            setIsAddNewDashboard={setIsAddNewDashboard}
            setIsEnterAddNewDashboard={setIsEnterAddNewDashboard}
            isShowChart={isShowChart}
            selectMultipleItems={selectMultipleItems}
            searchKey={searchKey}
            isChartLibrary={isChartLibrary}
          />
        </div>
      )}
    </>
  )
}

export default PopupContentMenu
