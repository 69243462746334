import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainerWithZoom } from '../../../../../common/chart/rechart/ChartContainerWithZoom'
import { DateTimeSpan } from '../../../../../common/DateTimeSpan'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { arrColors, VALUE_ORDER } from '../../constants'
import style from '../style.module.css'
import { calcRelativeData } from './helper'
import {
  changeSectorActive,
  changeShowLabelExactly,
  selectSectorActive,
} from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 28, left: 48 }
const MARGIN_ZOOM_X = { left: 10, right: 0, top: 28, bottom: -8 }
const SETTINGS = {
  yTickNum: 8,
  xTickNum: 5,
}

const renderTooltip = (tooltipData, lineKeys, orderValue) => {
  if (lineKeys.length <= 10) {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            <DateTimeSpan
              date={tooltipData.tradingDateId}
              formatDate={FORMAT.DATE}
            />
          </Span>
        </div>
        <div className="d-flex">
          <div>
            {lineKeys.map((item) => (
              <div key={item.icbId} className="d-flex j-b">
                <div style={{ paddingRight: 10 }}>
                  <Span style={{ fontSize: 11, fontWeight: 400 }}>
                    {item.icbName}:
                  </Span>
                </div>
                <div>
                  <Span style={{ fontSize: 11, fontWeight: 500 }}>
                    {orderValue === VALUE_ORDER.RELATIVE
                      ? valToPercent(tooltipData[item.icbId], true)
                      : formatVal(tooltipData[item.icbId])}
                  </Span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>
            <DateTimeSpan
              date={tooltipData.tradingDateId}
              formatDate={FORMAT.DATE}
            />
          </Span>
        </div>
        <div className="d-flex">
          <div>
            {lineKeys.slice(0, Math.ceil(lineKeys.length / 2)).map((item) => (
              <div key={item.icbId} className="d-flex j-b">
                <div style={{ paddingRight: 10 }}>
                  <Span style={{ fontSize: 11, fontWeight: 400 }}>
                    {item.icbName}:
                  </Span>
                </div>
                <div style={{ paddingRight: 10 }}>
                  <Span style={{ fontSize: 11, fontWeight: 500 }}>
                    {orderValue === VALUE_ORDER.RELATIVE
                      ? valToPercent(tooltipData[item.icbId], true)
                      : formatVal(tooltipData[item.icbId])}
                  </Span>
                </div>
              </div>
            ))}
          </div>
          <div>
            {lineKeys.slice(Math.ceil(lineKeys.length / 2)).map((item) => (
              <div key={item.icbId} className="d-flex j-b">
                <div style={{ paddingRight: 10 }}>
                  <Span style={{ fontSize: 11, fontWeight: 400 }}>
                    {item.icbName}:
                  </Span>
                </div>
                <div>
                  <Span style={{ fontSize: 11, fontWeight: 500 }}>
                    {orderValue === VALUE_ORDER.RELATIVE
                      ? valToPercent(tooltipData[item.icbId], true)
                      : formatVal(tooltipData[item.icbId])}
                  </Span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
    )
  }
}

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  timeFrame,
  showLabelExactly,
  locale,
  orderValue,
}) => {
  const hoveredItem = useSelector(selectSectorActive)
  const heightLabel = getFontSize(18)
  const timeZone = UseTimeZone()

  const transformData = useMemo(() => {
    return orderValue === VALUE_ORDER.RELATIVE ? calcRelativeData : undefined
  }, [orderValue])

  const getYAxis = () => {
    if (orderValue === VALUE_ORDER.RELATIVE) {
      return [
        {
          id: 'right',
          keys: lineKeys.map((item) => item.icbId),
          isLineChart: true,
          orientation: 'right',
          hasReferenceLabel: true,
          referenceLabelWidth: 94,
          referenceLabelHeight: heightLabel,
          tickNum: SETTINGS.yTickNum,
          unitYAxis: '%',
          labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
          showLabelExactly: showLabelExactly,
          lineKeys: lineKeys,
          lineKey: 'icbId',
          renderReferenceLabel: ({ data, key, item }) => {
            return (
              <CustomLabel
                value={data[key]}
                item={item}
                showLabelExactly={showLabelExactly}
                orderValue={orderValue}
              />
            )
          },
        },
      ]
    } else {
      return [
        {
          id: 'right',
          keys: lineKeys.map((item) => item.icbId),
          isLineChart: true,
          orientation: 'right',
          hasReferenceLabel: true,
          referenceLabelWidth: 94,
          referenceLabelHeight: heightLabel,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('sector.sectorStatistics.overview.CHART_POINT'),
          labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
          showLabelExactly: showLabelExactly,
          lineKeys: lineKeys,
          lineKey: 'icbId',
          renderReferenceLabel: ({ data, key, item }) => {
            return (
              <CustomLabel
                value={data[key]}
                item={item}
                showLabelExactly={showLabelExactly}
                orderValue={orderValue}
              />
            )
          },
        },
      ]
    }
  }

  return (
    <ChartContainerWithZoom
      data={data}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      timeFrame={timeFrame}
      tickFormatter={(value) =>
        formatDateTime(value, FORMAT.DATE, locale, timeZone)
      }
      zoomRightYBarPosition="left"
      renderCustomTooltip={(data) => {
        return renderTooltip(data, lineKeys, orderValue)
      }}
      margin={MARGIN}
      yAxis={getYAxis()}
      yTickNum={SETTINGS.yTickNum}
      marginZoomY={{ left: 30 }}
      transformData={transformData}
      marginZoomX={MARGIN_ZOOM_X}
    >
      {lineKeys.map((lineKey) => (
        <Line
          key={lineKey.icbId}
          yAxisId="right"
          dataKey={lineKey.icbId}
          type="linear"
          stroke={arrColors[lineKey.colorIndex].bgColor}
          dot={false}
          activeDot={false}
          strokeOpacity={
            !hoveredItem ? 1 : hoveredItem === lineKey.icbId ? 1 : 0.1
          }
          isAnimationActive={false}
          strokeWidth={
            !hoveredItem ? 1.5 : hoveredItem === lineKey.icbId ? 2.5 : 1.5
          }
        />
      ))}
    </ChartContainerWithZoom>
  )
}

export default ChartComponent

const CustomLabel = ({ value, item, showLabelExactly, orderValue }) => {
  const dispatch = useDispatch()
  const refLabel = useRef()
  const COLUMN_GAP = 3
  const fontSize = getFontSize(12)

  const hoveredItem = useSelector(selectSectorActive)

  const [widthTextValue, setWidthTextValue] = useState(0)

  const onHover = () => {
    dispatch(changeSectorActive(item.icbId))
    dispatch(changeShowLabelExactly(false))
  }

  const onLeave = () => {
    dispatch(changeSectorActive(null))
  }

  const setOpacityLabel = () => {
    if (!hoveredItem || hoveredItem === item.icbId) {
      return 1
    } else {
      if (showLabelExactly) {
        if (hoveredItem === item.icbId) {
          return 1
        } else {
          return 0
        }
      } else {
        return 0.2
      }
    }
  }

  useEffect(() => {
    if (refLabel.current) {
      setWidthTextValue(refLabel.current?.offsetWidth)
    }
  }, [refLabel.current])
  return (
    <div
      className={style.labelChart}
      style={{
        background: item.bgColor,
        opacity: setOpacityLabel(),
        color: item.textColor,
        height: '100%',
      }}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          height: '100%',
          padding: '0px 3px',
          gap: COLUMN_GAP,
        }}
      >
        <div
          style={{
            width: `calc(100% - ${widthTextValue + COLUMN_GAP}px)`,
          }}
        >
          <TextEllipsis
            appendStyle={{ fontSize: fontSize }}
            text={item.icbName}
            isI18n={false}
          />
        </div>
        <div ref={refLabel} style={{ fontSize }}>
          {orderValue === VALUE_ORDER.RELATIVE
            ? valToPercent(value, true)
            : formatVal(value)}
        </div>
      </div>
    </div>
  )
}
