import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { changeStateNameChart } from '../../../store/slice'
import SettingStyle from './SettingStyle'
import style from './style.module.css'
import useGetDataStyle from './useGetDataStyle'

const TitleChart = () => {
  const dispatch = useDispatch()

  const { nameChart } = useGetDataStyle()

  const handleChangeStateNameChart = (data) => {
    dispatch(changeStateNameChart(data))
  }

  return (
    <div className="mb-8 mt-8">
      <div className={style.label}>
        <Translate value="financialChart.TITLE_CHART" />
      </div>
      <SettingStyle
        style={nameChart}
        setStyle={handleChangeStateNameChart}
        fontSizeMax={50}
      />
    </div>
  )
}

export default TitleChart
