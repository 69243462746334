import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { formatVal, valToPercent } from '../../../../utils/Value'
import style from '../index.module.css'

const ChartTooltip = ({ data }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 11, fontWeight: 400 }}
      >
        {data.text}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.portfolio.overviewOfPortfolioSize.BOND_SIZE" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(data.buyValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.portfolio.overviewOfPortfolioSize.AVG_COUPON" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {valToPercent(
              data.coupon,
              true,
              false,
              2,
              I18n.t('bond.portfolio.overviewOfPortfolioSize.COUPON_UNIT'),
            )}
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.portfolio.overviewOfPortfolioSize.ALL_AVG_COUPON" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {valToPercent(
              data.allCoupon,
              true,
              false,
              2,
              I18n.t('bond.portfolio.overviewOfPortfolioSize.COUPON_UNIT'),
            )}
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      name: PropTypes.string,
      buyValue: PropTypes.number,
      coupon: PropTypes.number,
      allCoupon: PropTypes.number,
    }),
  ).isRequired,
}

export default ChartTooltip
