import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import {
  getDropdownOptions,
  getDropDownValue,
  handleDropdownValue,
} from '../panelMoneySupplyCredit/store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const dropdownValue = useSelector(getDropDownValue)
  const dropdownOptions = useSelector(getDropdownOptions)

  const changeDropdownValue = (value) => {
    dispatch(handleDropdownValue(value))
  }

  return (
    <div className="d-flex align-center j-b" style={{ width: 268 }}>
      <SelectCustom
        isI18n={true}
        selectData={dropdownOptions}
        value={dropdownValue}
        handleChange={changeDropdownValue}
        appendStyle={{ fontWeight: 'normal' }}
        styleContainer={{ width: '100%' }}
      />
    </div>
  )
}

export default Header
