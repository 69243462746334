export const DROPDOWN_OPTION = [
  { name: 'economy.monetary.monetary.BUY', value: 'bidRateCash' },
  { name: 'economy.monetary.monetary.TRANSFER', value: 'bidRateTransfer' },
  { name: 'economy.monetary.monetary.SELL', value: 'askRate' },
]

export const TIME_FRAME_OPTIONS = [
  {
    title: '1M',
    value: 'OneMonth',
  },
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
]

export const TIME_FRAME_OPTIONS_DAILY = [
  { title: '1M', value: 'OneMonth' },
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: 'MTD', value: 'MonthToDate' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
  { title: 'economy.monetary.monetary.ALL', value: 'AllTime' },
]

export const TIME_FRAME_OPTIONS_WEEKLY = [
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
  { title: 'economy.monetary.monetary.ALL', value: 'AllTime' },
]

export const TIME_FRAME_OPTIONS_MONTHLY = [
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
  { title: '5Y', value: 'FiveYears' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
  { title: 'economy.monetary.monetary.ALL', value: 'AllTime' },
]
