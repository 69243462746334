import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'

export const POPUP_TYPES = {
  SETTING_COLUMN: 1,
  SAVE_TEMPLATE: 2,
  CREATE_WATCHLIST: 3,
  CREATE_BOND: 4,
  UPDATE_BOND: 4,
}

export const SAVE_TEMPLATE_TYPES = {
  SAVE: 1,
  UPDATE: 2,
}

export const SAVE_TEMPLATE_RADIOS = [
  {
    name: 'bond.bondScreening.filter.SAVE',
    value: SAVE_TEMPLATE_TYPES.SAVE,
  },
  {
    name: 'bond.bondScreening.filter.UPDATE',
    value: SAVE_TEMPLATE_TYPES.UPDATE,
  },
]

export const MAX_TEMPLATE = 100

export const FILTER_TIME_RANGE_TABS = [
  { title: 'MTD', value: TIME_RANGES.MonthToDate },
  { title: 'QTD', value: TIME_RANGES.QuarterToDate },
  { title: 'YTD', value: TIME_RANGES.YearToDate },
  { title: '3Y', value: TIME_RANGES.ThreeYears },
  { title: '5Y', value: TIME_RANGES.FiveYears },
  { title: 'common.ALL', value: TIME_RANGES.All },
]
