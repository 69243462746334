import { TIME_FREQUENCY } from '../../common/constants'
import { titleData } from '../../common/helper'

export const convertData = (arr, timeFrequency) => {
  let objList = {}
  const result = []
  arr.forEach((item) => {
    objList[titleData(item.month, item.year, timeFrequency)] = {
      ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
      [`fdiTypeId_${item.fdiTypeId}`]: item.value,
    }
  })
  Object.keys(objList).map((key) =>
    result.push({
      XAxis: key,
      ...objList[key],
    }),
  )
  if (timeFrequency === TIME_FREQUENCY.ACC_MONTHLY) {
    result.reverse()
  }
  return result.map((item) => ({
    ...item,
    fdiTypeId_15: item.fdiTypeId_15 / 1000000,
    fdiTypeId_17: item.fdiTypeId_17 / 1000000,
    fdiTypeId_19: item.fdiTypeId_19 / 1000000,
    fdiTypeId_20: item.fdiTypeId_20 / 1000000,
  }))
}
