import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InfoSec from '../../../../../../../core/services/bond/valuation/infoSec'
import { changeBasicInfo, name } from './slice'

export const getSearchData = createAsyncThunk(
  'bond/security/getFilter',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await InfoSec.getListBond()

      if (response.success) {
        const data = response?.data || []
        const allState = getState()
        const state = allState[name]

        const item = data.find(
          (e) => e?.bondId === params?.bondId || e?.ticker === params?.bondId,
        )

        if (item) {
          dispatch(changeBasicInfo(item))
        } else {
          const resDefault = await InfoSec.getDefautBondId()

          const itemDefault = data.find(
            (e) => e?.bondId === resDefault?.data?.bondId,
          )

          if (itemDefault && !state?.bondSelect) {
            dispatch(changeBasicInfo(itemDefault))
          }
        }

        return response.data
      }
      return rejectWithValue('Error')
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
