import { I18n } from 'react-redux-i18n'
import { getRoundedTickValues } from '../../../../../common/chart/helper'
import { Span } from '../../../../../common/html/Span'

export const XAxisForBlockBar = ({ maxValLeft, maxValRight }) => {
  const renderXAxis = () => {
    if (maxValLeft && maxValRight) {
      return (
        <>
          {getRoundedTickValues([maxValLeft, maxValLeft / 2], 2, false).map(
            (item) => (
              <Span key={item} className="canvas-chart-title">
                {item.toFixed(2)}
              </Span>
            ),
          )}
          <Span className="canvas-chart-title">0</Span>
          {getRoundedTickValues([maxValRight / 2, maxValRight], 2, false).map(
            (item) => (
              <Span key={item} className="canvas-chart-title">
                {item.toFixed(2)}
              </Span>
            ),
          )}
        </>
      )
    } else if (!maxValLeft) {
      return (
        <>
          <Span className="canvas-chart-title" style={{ width: '22px' }}></Span>
          <Span className="canvas-chart-title" style={{ width: '22px' }}></Span>
          <Span className="canvas-chart-title">0</Span>
          {getRoundedTickValues([maxValRight / 2, maxValRight], 2, false).map(
            (item) => (
              <Span key={item} className="canvas-chart-title">
                {item.toFixed(2)}
              </Span>
            ),
          )}
        </>
      )
    } else if (!maxValRight) {
      return (
        <>
          {getRoundedTickValues([maxValLeft, maxValLeft / 2], 2, false).map(
            (item) => (
              <Span key={item} className="canvas-chart-title">
                {item.toFixed(2)}
              </Span>
            ),
          )}
          <Span className="canvas-chart-title">0</Span>
          <Span className="canvas-chart-title" style={{ width: '22px' }}></Span>
          <Span className="canvas-chart-title" style={{ width: '22px' }}></Span>
        </>
      )
    }
  }

  return (
    <>
      <div
        style={{
          margin: 'auto',
          width: '50%',
          paddingTop: '10px',
        }}
        className={`align-center j-b`}
      >
        {renderXAxis()}
      </div>
      <div
        style={{
          padding: '10px 0',
          color: '#75797f',
        }}
        className="centerItems canvas-chart-title"
      >
        {I18n.t(
          'economy.importExport.importExport.overview.FOOTER_VALUE_BILLION_USD',
        )}
      </div>
    </>
  )
}
