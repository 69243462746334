import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { convertDateFromDatePicker, useGetDayTitle } from '../helper'
import styleCommon from '../index.module.css'
import Range from './Range'
import style from './index.module.css'

const OneCalendarRange = ({
  hightlightDates,
  startDate,
  endDate,
  handleChange,
  customHeader,
  minDate,
  maxDate,
  showRange,
  btnDeleteDate,
  selected,
  excludeDateIntervals,
}) => {
  const getDayTitle = useGetDayTitle()
  const calendarRef = useRef()

  useEffect(() => {
    handleOpenCalendar()
    document
      .getElementById('root')
      .classList.add('react-datepicker-ignore-onclickoutside')
    return () =>
      document
        .getElementById('root')
        .classList.remove('react-datepicker-ignore-onclickoutside')
  }, [])

  const handleOpenCalendar = () => {
    calendarRef.current.setOpen(true)
  }

  const handleChangeDateTime = (dates) => {
    return handleChange(convertDateFromDatePicker(dates))
  }

  return (
    <div className={styleCommon.container}>
      {showRange && (
        <Range
          startDate={startDate}
          endDate={endDate}
          handleChange={handleChangeDateTime}
          btnDeleteDate={btnDeleteDate}
        />
      )}
      <div className={style.oneCalendarRange}>
        <DatePicker
          selected={selected ?? new Date()}
          onCalendarClose={handleOpenCalendar}
          ref={calendarRef}
          shouldCloseOnSelect={false}
          startDate={startDate}
          endDate={endDate}
          onChange={handleChange}
          highlightDates={hightlightDates}
          formatWeekDay={getDayTitle}
          customInput={<></>}
          renderCustomHeader={customHeader}
          minDate={minDate}
          maxDate={maxDate}
          selectsRange
          inline
          excludeDateIntervals={excludeDateIntervals}
        />
      </div>
    </div>
  )
}

OneCalendarRange.propTypes = {
  hightlightDates: PropTypes.array,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  handleChange: PropTypes.func.isRequired,
  customHeader: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  showRange: PropTypes.bool,
  btnDeleteDate: PropTypes.bool,
}

OneCalendarRange.defaultProps = {
  showRange: true,
  btnDeleteDate: false,
}

export default OneCalendarRange
