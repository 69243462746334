import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import TopInfo from '../../../../common/topInfo/stockTopInfo'
import Content from './Content'
import { resetStore } from './store/slice'

export const Synthetic = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <>
      <div style={{ paddingBottom: '4px' }}>
        <TopInfo height="30px" isHidePriceData isIncludeBond />
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 34px)`,
        }}
      >
        <Content />
      </div>
    </>
  )
}
