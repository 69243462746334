import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { uniq } from 'lodash'
import AdvancedSearchService from '../../../../core/services/advanceSearch/AdvancedSearchService'
import BondListService from '../../../../core/services/bond/bondScreening/BondListService'
import CreateBondService from '../../../../core/services/bond/bondScreening/CreateBondService'
import SimpleSearchService from '../../../../core/services/common/SimpleSearchService'
import WatchlistService from '../../../../core/services/watchlist/WatchlistService'
import { getAllIdTree, getAllParentChecked } from '../../../common/tree/helper'
import { handleExport } from '../../../utils/Export'
import {
  changeHeaders,
  changeLoadingTable,
} from '../BondList/Table/store/slice'
import { getDataBondList } from '../BondList/Table/store/thunk'
import { INDICATOR_GROUPS, TAB_LIST, TAB_TYPES } from '../constants'
import {
  getICBs,
  getSortFieldByIndicatorGroup,
  renderAddedInformaticsParams,
  renderConditionParams,
} from '../helper'
import { changeParameters, paramsDefault, setInitialSort } from './slice'

export const getTemplatesData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_INITIAL_TEMPLATE',
  async ({ rejectWithValue }) => {
    try {
      const response = await BondListService.getTemplates()

      if (response.success) {
        return response?.data ?? []
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplatesDataBySave = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TEMPLATE_BY_SAVE',
  async (params, { rejectWithValue }) => {
    try {
      const { type, isSave } = params

      const response = await BondListService.getTemplates()

      if (response.success) {
        const data = response.data?.filter((item) => item.templateType === type)

        const templates = [
          {
            templateId: 0,
            templateName: `bond.bondScreening.bondList.${
              type === INDICATOR_GROUPS.ALL_ISSUER ? 'ALL_ISSUER' : 'ALL_BOND'
            }`,
            templateType: type,
            hidden: true,
          },
          ...data,
        ]

        const index = templates.findIndex(
          (item) => item.templateName === isSave.templateName,
        )

        if (index !== -1) {
          const newTemplates = templates.filter(
            (item) =>
              item.templateName !== isSave.templateName &&
              item.templateId !== TAB_TYPES.RAW,
          )

          let newTemplateIds = []

          if (index >= 7) {
            newTemplateIds = [
              ...newTemplates.slice(0, 6),
              templates[index],
              ...newTemplates.slice(6),
            ]
          } else {
            newTemplateIds = [
              ...newTemplates.slice(0, index),
              templates[index],
              ...newTemplates.slice(index + 1),
            ]
          }

          return { data: newTemplateIds, isSave: templates[index], templates }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplatesDataByUpdate = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TEMPLATE_BY_UPDATE',
  async (params, { rejectWithValue }) => {
    try {
      const { type, isUpdate } = params

      const response = await BondListService.getTemplates()

      if (response.success) {
        const data = response.data?.filter((item) => item.templateType === type)

        const templates = [
          {
            templateId: 0,
            templateName: `bond.bondScreening.bondList.${
              type === INDICATOR_GROUPS.ALL_ISSUER ? 'ALL_ISSUER' : 'ALL_BOND'
            }`,
            templateType: type,
            hidden: true,
          },
          ...data,
        ]

        const index = templates.findIndex(
          (item) => item.templateId === isUpdate.templateId,
        )

        if (index !== -1) {
          const newTemplates = templates.filter(
            (item) =>
              item.templateId !== isUpdate.templateId &&
              item.templateId !== TAB_TYPES.RAW,
          )

          let newTemplateIds = []

          if (index >= 7) {
            newTemplateIds = [
              ...newTemplates.slice(0, 6),
              { ...templates[index], templateName: isUpdate.templateName },
              ...newTemplates.slice(6),
            ]
          } else {
            newTemplateIds = [
              ...newTemplates.slice(0, index),
              { ...templates[index], templateName: isUpdate.templateName },
              ...newTemplates.slice(index + 1),
            ]
          }

          return { data: newTemplateIds, isUpdate, templates }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplatesDataByDelete = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TEMPLATE_By_DELETE',
  async (params, { rejectWithValue }) => {
    try {
      const { type } = params

      const response = await BondListService.getTemplates()

      if (response.success) {
        const data = response.data?.filter((item) => item.templateType === type)

        return [
          {
            templateId: 0,
            templateName: `bond.bondScreening.bondList.${
              type === INDICATOR_GROUPS.ALL_ISSUER ? 'ALL_ISSUER' : 'ALL_BOND'
            }`,
            templateType: type,
            hidden: true,
          },
          ...data,
        ]
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplateByIdData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TEMPLATE_BY_ID',
  async (data, { rejectWithValue, dispatch, getState }) => {
    dispatch(changeLoadingTable(true))

    try {
      const state = getState()
      const bondListState = state['bond/bondScreening/bondAggregator']
      const masterData = state['common/masterData']
      const ICBsTree = getICBs(masterData?.ICBs ?? [])
      const sectors = getAllIdTree(ICBsTree, 'icbCode')
      const parent = getAllParentChecked(ICBsTree, 'icbCode', sectors)

      const { params, redirectToBondIssuer } = data

      if (TAB_LIST.includes(params.TemplateId)) {
        if (params.TemplateId === TAB_TYPES.ALL) {
          const conditionsParams = renderConditionParams(
            bondListState.initialConditions,
            bondListState.initialAddedInformatics,
          )

          const addedInformaticsParams = renderAddedInformaticsParams(
            bondListState.initialAddedInformatics,
          )

          const newParams = {
            ...paramsDefault.parameter,
            sectors: [],
            conditions: conditionsParams,
            addedInformations:
              !!Object.keys(bondListState?.columns) &&
              bondListState?.columns?.id === TAB_TYPES.ALL
                ? bondListState?.columns?.data
                : addedInformaticsParams,
            pageSize: 300,
            pageIndex: 1,
            indicatorGroup:
              bondListState?.params?.parameter?.indicatorGroup ??
              INDICATOR_GROUPS.BOND_AGGREGATOR,
            sortBy: getSortFieldByIndicatorGroup(
              '',
              bondListState?.params?.parameter?.indicatorGroup,
            ),
          }

          dispatch(
            changeParameters({
              ...paramsDefault,
              parameter: {
                ...newParams,
                sectors: uniq([...parent, ...sectors]),
              },
            }),
          )

          dispatch(
            setInitialSort({
              sortBy: newParams.sortBy,
              order: paramsDefault.parameter.order,
            }),
          )

          dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

          return
        }

        const template = bondListState.raw

        const newParams = {
          ...template.parameter,
          sectors:
            uniq(template.parameter.sectors)?.length ===
            uniq([...parent, ...sectors])?.length
              ? []
              : uniq(template.parameter.sectors),
          conditions: template.parameter.conditions,
          addedInformations:
            !!Object.keys(bondListState?.columns) &&
            bondListState?.columns?.id === TAB_TYPES.RAW
              ? bondListState?.columns?.data
              : template.parameter.addedInformations,
          pageSize: 300,
          pageIndex: 1,
        }

        dispatch(changeParameters(template))
        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          setInitialSort({
            sortBy: template.parameter.sortBy,
            order: template.parameter.order,
          }),
        )

        return
      }

      const response = await BondListService.getTemplateById(params)

      if (response.success) {
        const template = response.data

        const parentIds = getAllParentChecked(
          ICBsTree,
          'icbCode',
          template.parameters?.sectors ?? [],
        )

        const newParams = {
          ...template.parameters,
          sectors:
            uniq([...parentIds, ...template.parameters?.sectors])?.length ===
            uniq([...parent, ...sectors])?.length
              ? []
              : uniq([...parentIds, ...template.parameters?.sectors]),
          conditions: template.parameters.conditions,
          addedInformations:
            !!Object.keys(bondListState?.columns) &&
            bondListState?.columns?.id === template.templateId
              ? bondListState?.columns?.data
              : template.parameters.addedInformations,
          pageSize: 300,
          pageIndex: 1,
        }

        const { parameters, ...restTemplate } = template

        dispatch(
          changeParameters({
            ...restTemplate,
            parameter: parameters,
          }),
        )

        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          setInitialSort({
            sortBy: template.parameters.sortBy,
            order: template.parameters.order,
          }),
        )

        return template
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const saveTemplateData = createAsyncThunk(
  'bond/bondScreening/bondList/SAVE_TEMPLATE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { ids, ...restParams } = params

      const response = await BondListService.saveTemplate(restParams)

      if (response.success) {
        dispatch(changeHeaders(ids))
        dispatch(
          setInitialSort({
            sortBy: restParams.parameter.sortBy,
            order: restParams.parameter.order,
          }),
        )

        return params
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const renameTemplateByIdData = createAsyncThunk(
  'bond/bondScreening/bondList/RENAME_TEMPLATE_BY_ID',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await BondListService.renameTemplateById(params)

      if (response.success) {
        return params
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updateTemplateByIdData = createAsyncThunk(
  'bond/bondScreening/bondList/UPDATE_TEMPLATE_BY_ID',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const { ids, ...restParams } = params

      const response = await BondListService.updateTemplateById(restParams)

      if (response.success) {
        dispatch(changeHeaders(ids))
        dispatch(
          setInitialSort({
            sortBy: restParams.parameter.sortBy,
            order: restParams.parameter.order,
          }),
        )

        return params
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const deleteTemplateByIdData = createAsyncThunk(
  'bond/bondScreening/bondList/DELETE_TEMPLATE_BY_ID',
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const { templateId, redirectToBondIssuer } = data
      const response = await BondListService.deleteTemplateById({ templateId })

      if (response.success) {
        const state = getState()
        const bondListState = state['bond/bondScreening/bondAggregator']

        const conditionsParams = renderConditionParams(
          bondListState.initialConditions,
          bondListState.initialAddedInformatics,
        )
        const addedInformaticsParams = renderAddedInformaticsParams(
          bondListState.initialAddedInformatics,
        )

        const masterData = state['common/masterData']

        const ICBsTree = getICBs(masterData?.ICBs ?? [])
        const sectors = getAllIdTree(ICBsTree, 'icbCode')
        const parent = getAllParentChecked(ICBsTree, 'icbCode', sectors)

        const newParams = {
          ...paramsDefault.parameter,
          sectors: [],
          conditions: conditionsParams,
          addedInformations: addedInformaticsParams,
          pageSize: 300,
          pageIndex: 1,
          indicatorGroup: bondListState?.params?.parameter?.indicatorGroup,
          sortBy: getSortFieldByIndicatorGroup(
            '',
            bondListState?.params?.parameter?.indicatorGroup,
          ),
        }

        dispatch(
          changeParameters({
            ...paramsDefault,
            parameter: {
              ...newParams,
              sectors: uniq([...parent, ...sectors]),
            },
          }),
        )

        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          setInitialSort({
            sortBy: newParams.sortBy,
            order: paramsDefault.parameter.order,
          }),
        )

        return response.success
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondListData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchMoreBondListData = createAsyncThunk(
  'bond/bondScreening/bondList/FETCH_MORE_BOND_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBond(params)

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getIndicatorData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_INDICATOR',
  async (params, { rejectWithValue }) => {
    try {
      const [response1, response2] = await Promise.all([
        BondListService.getFilterIndicator(params),
        BondListService.getAllIndicator(params),
      ])

      return {
        filter: response1?.data ?? [],
        table: response2?.data ?? [],
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTableDataByFilter = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TABLE_BY_FILTER',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const { type, redirectToBondIssuer } = params

      const state = getState()
      const bondListState = state['bond/bondScreening/bondAggregator']
      const locale = state.i18n.locale

      const data1 = bondListState.initialFilter?.filter(
        (item) => item.indicatorGroup === type,
      )

      const filterData =
        type === INDICATOR_GROUPS.BOND_CREATING
          ? [
              {
                indicatorId: 99999,
                parentIndicatorId: 99999,
                indicatorGroup: type,
                orderNumber: 100,
                isFilter: true,
                isDefault: data1.every((item) => item.isDefault),
                indicatorName: locale === 'vi' ? 'Thông tin chung' : 'Overview',
              },
              ...data1.map((item) => ({ ...item, parentIndicatorId: 99999 })),
            ]
          : data1

      const data2 = bondListState.initialTable?.filter(
        (item) => item.indicatorGroup === type,
      )

      const tableData =
        type === INDICATOR_GROUPS.BOND_CREATING
          ? [
              {
                indicatorId: 99999,
                parentIndicatorId: 99999,
                indicatorGroup: type,
                orderNumber: 100,
                isFilter: false,
                isDefault: data2.every((item) => item.isDefault),
                indicatorName: locale === 'vi' ? 'Thông tin chung' : 'Overview',
              },
              ...data2.map((item) => ({ ...item, parentIndicatorId: 99999 })),
            ]
          : data2

      const masterData = state['common/masterData']
      const ICBsTree = getICBs(masterData?.ICBs ?? [])
      const sectors = getAllIdTree(ICBsTree, 'icbCode')
      const parent = getAllParentChecked(ICBsTree, 'icbCode', sectors)

      if (bondListState.template) {
        const newParams = {
          ...bondListState.template.parameter,
          sectors:
            uniq(bondListState.template.parameter)?.length ===
            uniq([...parent, ...sectors])?.length
              ? []
              : uniq(bondListState.template.parameter),
        }

        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          changeParameters({
            ...bondListState.template,
            parameter: {
              ...newParams,
              sectors: uniq(bondListState.template.parameter),
            },
          }),
        )

        dispatch(
          setInitialSort({
            sortBy: bondListState.template.parameter.sortBy,
            order: bondListState.template.parameter.order,
          }),
        )
      } else if (bondListState.raw) {
        const newParams = {
          ...bondListState.raw.parameter,
          sectors:
            uniq(bondListState.raw.parameter)?.length ===
            uniq([...parent, ...sectors])?.length
              ? []
              : uniq(bondListState.raw.parameter),
        }

        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          changeParameters({
            ...paramsDefault,
            parameter: {
              ...newParams,
              sectors: uniq(bondListState.raw.parameter),
            },
          }),
        )
        dispatch(
          setInitialSort({
            sortBy: bondListState.raw.parameter.sortBy,
            order: bondListState.raw.parameter.order,
          }),
        )
      } else {
        const conditionsParams = renderConditionParams(filterData, tableData)
        const addedInformaticsParams = renderAddedInformaticsParams(tableData)

        const newParams = {
          ...paramsDefault.parameter,
          sectors: [],
          indicatorGroup: type,
          conditions: conditionsParams,
          addedInformations: addedInformaticsParams,
          pageSize: 300,
          pageIndex: 1,
          sortBy: getSortFieldByIndicatorGroup('', type),
        }

        dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

        dispatch(
          changeParameters({
            ...paramsDefault,
            parameter: {
              ...newParams,
              sectors: uniq([...parent, ...sectors]),
            },
          }),
        )
        dispatch(
          setInitialSort({
            sortBy: newParams.sortBy,
            order: paramsDefault.parameter.order,
          }),
        )
      }

      return { filterData, tableData }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      dispatch(changeLoadingTable(false))
      return rejectWithValue(false)
    }
  },
)

export const getBondListCount = createAsyncThunk(
  'bond/bondScreening/bondList/GET_BOND_LIST_COUNT',
  async (params, { rejectWithValue }) => {
    try {
      const response = await BondListService.getBondListCount(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const exportBondListData = createAsyncThunk(
  'bond/bondScreening/bondList/EXPORT_BOND_LIST',
  async (data) => {
    const response = await BondListService.exportBondList(data)
    handleExport(response.data, response.filename)
  },
)

export const getWatchListData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlist(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getWatchListByUserData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_WATCH_LIST_BY_USER',
  async (params, { rejectWithValue }) => {
    try {
      const response = await BondListService.getWatchlistByUser(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const createBondData = createAsyncThunk(
  'bond/bondScreening/bondList/CREATE_BOND_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CreateBondService.createBond(params)

      if (response.success) {
        return response.success
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updateBondData = createAsyncThunk(
  'bond/bondScreening/bondList/UPDATE_BOND_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const { ticker, ...restParams } = params

      const response = await CreateBondService.updateBond(restParams)

      if (response.success) {
        return ticker
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const deleteBondData = createAsyncThunk(
  'bond/bondScreening/bondList/DELETE_BOND_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const { ticker, bondUserId } = params

      const response = await CreateBondService.deleteBond({ bondUserId })

      if (response.success) {
        return ticker
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getCreateBondListData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_CREATE_BOND_LIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CreateBondService.getAllBond(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondByIdData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_BOND_BY_ID_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CreateBondService.getBondById(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondAndUserBondData = createAsyncThunk(
  'bond/bondScreening/GET_BOND_AND_USER_BOND',
  async (params, { rejectWithValue }) => {
    try {
      const response = await AdvancedSearchService.getSearchCompanyData(params)

      if (response.success) {
        return {
          data: response.data,
          hasNextPage: response.hasNextPage,
          page: params.Page,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchBondAndUserBondData = createAsyncThunk(
  'bond/bondScreening/FETCH_MORE_BOND_AND_USER_BOND',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompanyNotSaveCache(
        params,
      )

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
