export const listFilter = [
  {
    name: 'market.marketInDepth.directorDeal.DIRECTORS_AND_RELATED_PEOPLE',
    value: true,
  },
  {
    name: 'market.marketInDepth.directorDeal.INSTITUTIONAL_SHAREHOLDERS',
    value: false,
  },
]
