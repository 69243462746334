import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPProvinceService from '../../../../../../core/services/economy/gdp/GDPProvinceService'
import { getRangeYear } from '../../panelGDPProvince/helper'
import { handleRangeYear, handleYear } from './slice'

export const getGRDPStructureInByProvince = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGRDPStructureProvince/getGRDPStructureInByProvince',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPProvinceService.getGRDPStructureInByProvince(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getLocationName = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGRDPStructureProvince/getLocationName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPProvinceService.getLocationName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxPeriod = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGRDPStructureProvince/getMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await GDPProvinceService.getMaxPeriod(params)
      if (res.data.length === 2) {
        const maxYearStructure = res.data[1]?.maxYear
          ? res.data[1].maxYear
          : new Date().getFullYear()
        dispatch(handleYear(maxYearStructure))
        dispatch(handleRangeYear(getRangeYear(res.data[1])))
      }
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
