import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'

export const getExportYear = createAsyncThunk(
  'sector/sectorSpecific/fishery/overviewFishery/exportChart',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FisheryService.getExportYear(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOverviewIds = createAsyncThunk(
  'sector/sectorSpecific/fishery/overviewFishery/getOverviewIds',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getOverviewIds(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getMaxDate = createAsyncThunk(
  'sector/sectorSpecific/fishery/overviewFishery/exportChart/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
