import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TIME_FRAME_TOP_NET_VALUE } from './constants'
import { changeTimeFrame, selectTimeFrame } from './store/slice'

const TimeFrame = () => {
  const dispatch = useDispatch()
  const timeFrame = useSelector(selectTimeFrame)

  const handleChangeTimeFrame = ({ value }) => {
    dispatch(changeTimeFrame(value))
  }

  return (
    <DispatchActionTab
      data={Object.keys(TIME_FRAME_TOP_NET_VALUE).map((key) => {
        return {
          title: TIME_FRAME_TOP_NET_VALUE[key].title,
          value: TIME_FRAME_TOP_NET_VALUE[key].value,
        }
      })}
      activeTab={timeFrame}
      onChangeTab={handleChangeTimeFrame}
    />
  )
}

export default TimeFrame
