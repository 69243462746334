import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  ORIGINAL_NUMBER_FIRST,
  ORIGINAL_NUMBER_FIRST_NOT_YEARLY,
} from '../../../constants'
import { TAB_TYPE } from '../../constants'
import {
  addColorIdToData,
  addColorIdToDataDefault,
  convertDataTable,
  convertGroupColumn,
  getTimeMaxCallApi,
  getTimeMinCallApi,
} from '../../helper'
import { DEFAULT_MIN_TIME } from '../../iip/store/slice'
import { getDataTableIIICompareByTimeRange } from './thunk'
const slice = createSlice({
  name: 'economy/productionAndConsumption/production/table/iii',
  initialState: {
    dataTable: [],
    dataById: {},
    ids: [],
    groupColumns: [],
    loading: false,

    listSelectRowIII: [],
    dataWithColorId: [],
    minTimeCallApi: DEFAULT_MIN_TIME,
    maxTimeCallApi: DEFAULT_MIN_TIME,
    prevData: [],
    initIds: [],
    prevScrollWidth: 0,
    isShowAlert: false,
    dataSortLevel: [],
  },
  reducers: {
    addSelectListTableIII: (state, action) => {
      state.listSelectRowIII = [...state.listSelectRowIII, action.payload]
    },
    removeSelectListTableIII: (state, action) => {
      state.listSelectRowIII = state.listSelectRowIII.filter(
        (item) => item.iivnTypeId !== action.payload,
      )
    },
    changeMinTimeCallApi: (state, action) => {
      state.minTimeCallApi = { ...action.payload }
    },
    resetDataIII: (state, action) => {
      state.dataById = {}
      state.groupColumns = []
      state.prevData = []
      state.minTimeCallApi = DEFAULT_MIN_TIME
      state.listSelectRowIII = []
    },
    sortTableIII: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initIds,
        0,
        state.dataSortLevel,
      )
    },
    handlePrevScrollWidth: (state, action) => {
      state.prevScrollWidth = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataTableIIICompareByTimeRange.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getDataTableIIICompareByTimeRange.fulfilled,
      (state, action) => {
        state.prevData = [...action.payload.dataIII, ...state.prevData]
        const dataTitle = action.payload.titleIII
        const data = convertDataTable(state.prevData, dataTitle, TAB_TYPE.III)
        const groupColumns = [{ key: 'iivnTypeName', title: '' }]
        const filterGroupColumns = convertGroupColumn(data)
        filterGroupColumns?.forEach((item) => {
          groupColumns.push({
            key: item,
            title: item,
          })
        })
        state.dataTable = data
        state.groupColumns = groupColumns
        state.dataById = keyBy(data, 'iivnTypeId')
        state.dataWithColorId = addColorIdToData(data)
        state.listSelectRowIII = addColorIdToDataDefault(
          data,
          action?.meta?.arg,
          state.listSelectRowIII,
        )
        state.minTimeCallApi = getTimeMinCallApi(action.payload.dataIII)
        if (
          [ORIGINAL_NUMBER_FIRST, ORIGINAL_NUMBER_FIRST_NOT_YEARLY].includes(
            action.meta.arg.Limit,
          )
        ) {
          state.maxTimeCallApi = getTimeMaxCallApi(action.payload.dataIII)
        }
        state.ids = dataTitle?.map((item) => item.iivnTypeId)
        state.initIds = state.ids
        state.dataSortLevel = action.payload.titleIII?.map((item) => {
          return {
            id: item.iivnTypeId,
            level: item.iivnTypeLevel,
            parentId: item.parentIIVNTypeId,
          }
        })
        state.loading = false
      },
    )
    builder.addCase(getDataTableIIICompareByTimeRange.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectCellValueDataTableIII = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}

export const selectIdIII = (state) => state[slice.name].ids
export const selectDataTableIII = (state) => state[slice.name].dataTable
export const selectGroupColumnTableIII = (state) =>
  state[slice.name].groupColumns
export const selectLoadingTableIII = (state) => state[slice.name].loading
export const selectDataColorIdIII = (state) => state[slice.name].dataWithColorId
export const selectListSelectRowIII = (state) =>
  state[slice.name].listSelectRowIII
export const selectMinTimeCallApi = (state) => state[slice.name].minTimeCallApi
export const selectMaxTimeCallApi = (state) => state[slice.name].maxTimeCallApi
export const getPrevScrollWidth = (state) => state[slice.name].prevScrollWidth
export const getIsShowAlert = (state) => state[slice.name].isShowAlert

export const {
  addSelectListTableIII,
  removeSelectListTableIII,
  changeMinTimeCallApi,
  resetDataIII,
  sortTableIII,
  handlePrevScrollWidth,
  handleIsShowAlert,
} = slice.actions

register(slice.name, slice.reducer)
