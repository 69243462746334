import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelGDPCompositionBySector from './panelGDPCompositionBySector/PanelGDPCompositionBySector'
import PanelGDPCompositionLeftChild from './panelGDPCompositionBySector/panelGDPCompositionLeftChild/PanelGDPCompositionLeftChild'
import PanelGDPCompositionRightChild from './panelGDPCompositionBySector/panelGDPCompositionRightChild/PanelGDPCompositionRightChild'
import PanelGDPGrowthBySector from './panelGDPGrowthBySector/PanelGDPGrowthBySector'
import PanelGDPGrowthForecast from './panelGDPGrowthForecast/PanelGDPGrowthForecast'
import PanelTableTotalGDP from './panelTotalGDP/PanelTableTotalGDP'

const FILTER_GROUP_HEIGHT = 36
const HEADER_PANEL_HEIGHT = 25
const PANEL_PADDING = 8

const MAP_KEY = {
  TOTAL_GDP: 'TOTAL_GDP',
  GDP_GROWTH_BY_SECTOR: 'GDP_GROWTH_BY_SECTOR',
  GDP_GROWTH_FORECAST: 'GDP_GROWTH_FORECAST',
  GDP_COMPOSITION_BY_SECTOR: 'GDP_COMPOSITION_BY_SECTOR',
  GDP_COMPOSITION_LEFT_CHILD: 'GDP_COMPOSITION_LEFT_CHILD',
  GDP_COMPOSITION_RIGHT_CHILD: 'GDP_COMPOSITION_RIGHT_CHILD',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.TOTAL_GDP]: {
    minWidth: 700,
    minHeight: 300,
  },
  [MAP_KEY.GDP_GROWTH_BY_SECTOR]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.GDP_GROWTH_FORECAST]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.GDP_COMPOSITION_BY_SECTOR]: {
    minWidth: 700,
    minHeight: 300,
  },
  [MAP_KEY.GDP_COMPOSITION_LEFT_CHILD]: {
    minWidth: 340,
    minHeight: 232,
  },
  [MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD]: {
    minWidth: 340,
    minHeight: 232,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.TOTAL_GDP}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GDP_GROWTH_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GDP_GROWTH_BY_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GDP_GROWTH_FORECAST,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_BY_SECTOR,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_LEFT_CHILD,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GDP_GROWTH_BY_SECTOR}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH_BY_SECTOR],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP].minWidth + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.TOTAL_GDP,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.TOTAL_GDP,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GDP_GROWTH_FORECAST,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_BY_SECTOR,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_GROWTH_FORECAST}`]: {
    width: `calc(100%/3  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH_FORECAST],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GDP_COMPOSITION_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_LEFT_CHILD,
        ratioWidth: 0.5,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD,
        ratioWidth: 0.5,
        ratioLeft: 0.5,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_COMPOSITION_BY_SECTOR}`]: {
    width: `calc(200%/3  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100%/3  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_COMPOSITION_BY_SECTOR],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP].minHeight + 2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH_FORECAST].minWidth +
      2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.GDP_GROWTH_FORECAST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_LEFT_CHILD,
        ratioWidth: 0.5,
        ratioLeft: 0.5,
      },
      {
        key: MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD,
        ratioWidth: 0.5,
        ratioLeft: 0.5,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_COMPOSITION_LEFT_CHILD}`]: {
    width: `calc(100%/3  - ${(11 / 2) * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${
      FILTER_GROUP_HEIGHT + HEADER_PANEL_HEIGHT + COMPONENT.MARGIN
    }px)`,
    top: `calc(50% + ${
      FILTER_GROUP_HEIGHT + HEADER_PANEL_HEIGHT + COMPONENT.MARGIN
    }px)`,
    left: `calc(100%/3  + ${5 * COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_COMPOSITION_LEFT_CHILD],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP].minHeight +
      2 * COMPONENT.MARGIN +
      FILTER_GROUP_HEIGHT +
      HEADER_PANEL_HEIGHT,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH_FORECAST].minWidth +
      2 * COMPONENT.MARGIN +
      PANEL_PADDING,
    verticalResize: [
      {
        key: MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD}`]: {
    width: `calc(100%/3  - ${(11 / 2) * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${
      FILTER_GROUP_HEIGHT + HEADER_PANEL_HEIGHT + COMPONENT.MARGIN
    }px)`,
    top: `calc(50% + ${
      FILTER_GROUP_HEIGHT + HEADER_PANEL_HEIGHT + COMPONENT.MARGIN
    }px)`,
    left: `calc(200%/3  + ${2 * COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_GDP].minHeight +
      2 * COMPONENT.MARGIN +
      FILTER_GROUP_HEIGHT +
      HEADER_PANEL_HEIGHT,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_GROWTH_FORECAST].minWidth +
      MIN_SIZE_SETTING[MAP_KEY.GDP_COMPOSITION_LEFT_CHILD].minWidth +
      4 * COMPONENT.MARGIN +
      PANEL_PADDING,
    verticalResize: [
      {
        key: MAP_KEY.GDP_COMPOSITION_LEFT_CHILD,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelTableTotalGDP
          panelRefs={panelRefs}
          panelKey={MAP_KEY.TOTAL_GDP}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGDPGrowthBySector
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_GROWTH_BY_SECTOR}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGDPGrowthForecast
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_GROWTH_FORECAST}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGDPCompositionBySector
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_COMPOSITION_BY_SECTOR}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGDPCompositionLeftChild
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_COMPOSITION_LEFT_CHILD}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGDPCompositionRightChild
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_COMPOSITION_RIGHT_CHILD}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
