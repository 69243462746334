import { useDispatch } from 'react-redux'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { QuarterAndYearSelection } from '../common/QuarterAndYearSelection'
import { AddTicker } from './AddTicker'
import { ResetButton } from './ResetButton'
import { changeCurrentQuarter, changeCurrentYear } from './store/slice'

export const FilterListTable = ({ currentQuarter, currentYear }) => {
  const dispatch = useDispatch()

  const handleQuarterChange = (item) => {
    dispatch(changeCurrentQuarter(item))
  }
  const handleYearChange = (item) => {
    dispatch(changeCurrentYear(item))
  }

  return (
    <div className="d-flex j-b">
      <div className="row">
        <AddTicker />
        <VerticalLine ml={-7} mr={12} styleProps={{ height: 20 }} />
        <QuarterAndYearSelection
          handleQuarterChange={handleQuarterChange}
          handleYearChange={handleYearChange}
          currentYear={currentYear}
          currentQuarter={currentQuarter}
        />
      </div>
      <ResetButton />
    </div>
  )
}
