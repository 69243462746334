import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import {
  selectDataFDF,
  selectFinancialDataValue,
} from '../../filter/store/slice'

const FinancialDataTable = () => {
  const data = useSelector(selectDataFDF)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div
      style={{ height: dimensions.height, paddingBottom: 32 }}
      className="mt-8"
    >
      {data.financialData.groupColumns.length ? (
        <Table
          ids={data.financialData.ids}
          columnDraggable={false}
          getDataFromRedux={selectFinancialDataValue}
          isLoading={false}
          rowDraggable={false}
          schema={data.financialData.groupColumns.map((item, index) => {
            const title = item.title
            const colId = item.key
            const result = {
              colId,
              title,
              tdStyle: {
                textAlign: 'right',
                fontSize: 9,
              },
              thStyle: {
                padding: 11,
                textAlign: 'right',
                fontWeight: 'bold',
                fontSize: 9,
              },
            }
            if (index === 0) {
              return {
                ...result,
                thStyle: {
                  padding: 11,
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: 9,
                },
                canCustomTd: true,
                render: (val) => (
                  <td style={{ fontSize: 9 }}>
                    <Translate value={val} />
                  </td>
                ),
              }
            }
            return result
          })}
          isPagination={false}
          hasFooter={false}
          resizable={false}
          dimensionsTable={setDimensions}
          hasTooltip={false}
        />
      ) : null}
      <div className="mt-6">
        <span
          style={{
            fontSize: 10,
            fontStyle: 'italic',
            fontWeight: 300,
          }}
        >
          <Translate value="corporate.companySnapshot.NOTE_FINANCIAL_DATA_CHART" />
        </span>
      </div>
    </div>
  )
}

export default FinancialDataTable
