import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
// import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { PopupHeaderCommon } from '../../../../../common/popup/PopupHeaderCommon'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import TableHeader from './TableHeader'
import { Column } from './constants'
import {
  selectDataValue,
  selectIdsHistory,
  selectLoadingCompany,
} from './store/slice'

const PopupHistory = ({ closePopupHistory }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const loading = useSelector(selectLoadingCompany)
  const ids = useSelector(selectIdsHistory)
  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  // if (loading) {
  //   return <Loading />
  // }

  return (
    <PopupHeaderCommon
      setIsShow={closePopupHistory}
      title="bond.bondSecurity.tradingInformation.popup.title"
      isHidePadding={true}
    >
      <div style={{ width: '677px', height: '241px' }}>
        {ids.length !== 0 ? (
          <Table
            ids={ids}
            columnDraggable={false}
            getDataFromRedux={selectDataValue}
            isLoading={loading}
            rowDraggable={false}
            renderHeader={(stateSort, sortColumn) =>
              renderThead(Column, stateSort, sortColumn)
            }
            schema={Column.map((item, index) => {
              const title = I18n.t(item.title)
              const colId = item.key
              const result = {
                colId,
                title,
              }
              switch (index) {
                case 0:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td style={{ ...item?.attrs?.style }}>
                          <Span>{value + 1}</Span>
                        </td>
                      )
                    },
                  }
                case 1:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td style={{ ...item?.attrs?.style }}>
                          <Span>
                            <TextEllipsis text={value} />
                          </Span>
                        </td>
                      )
                    },
                  }
                case 2:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td style={{ ...item?.attrs?.style }}>
                          <Span>
                            <TextEllipsis
                              text={formatDateTime(value, FORMAT.DATE, locale)}
                            />
                          </Span>
                        </td>
                      )
                    },
                  }
                case 3:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td>
                          <Span>
                            <TextEllipsis text={value} />
                          </Span>
                        </td>
                      )
                    },
                  }
                case 4:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td>
                          <Span>
                            <TextEllipsis text={value} />
                          </Span>
                        </td>
                      )
                    },
                  }
                case 5:
                  return {
                    ...result,
                    canCustomTd: true,
                    render: (value, rowId, props) => {
                      return (
                        <td>
                          <Span
                            onClick={() => window.open(value)}
                            style={{ fontSize: 10, cursor: 'pointer' }}
                          >
                            <i className="icon-download" />
                          </Span>
                        </td>
                      )
                    },
                  }
                default:
                  return {}
              }
            })}
            stickyFirstColumn={false}
            isPagination={false}
            hasFooter={false}
            resizable={false}
          />
        ) : (
          <NoData />
        )}
      </div>
    </PopupHeaderCommon>
  )
}

export default PopupHistory
