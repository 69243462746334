import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab.js'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { Toggle } from '../../../../../common/toggle/index.js'
import { DATE_LIQUIDITY_TABS } from '../constants.js'
import { changeFilter, keys, selectFilterAverageYtm } from '../store/slice.js'
import { getAverageYTMofIssuer } from '../store/thunk.js'
import { AVERAGE_YTM_INTEREST } from './constants.js'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const { interest, ytmChange, TimeRange, StartDate, EndDate } = useSelector(
    selectFilterAverageYtm,
  )

  // Actions
  const handleChangeTCPH_TP = (item) => {
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'interest',
        value: item.value,
      }),
    )
  }

  const handleChangeExchangePrice = () => {
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'ytmChange',
        value: !ytmChange,
      }),
    )
  }

  const handleChangeActiveTab = (item) => {
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'TimeRange',
        value: item.value,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'TimeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'StartDate',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.AVERAGE_YTM,
        key: 'EndDate',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload = {
      IsReal: interest === 'real',
      IsYTM: interest === 'real' ? ytmChange : false,
    }

    if (TimeRange !== calendarTabKey) {
      if (interest === 'real' && ytmChange) {
        payload.TimeRange = TimeRange
      }
    } else {
      payload.StartDate = StartDate
      payload.EndDate = EndDate
    }

    dispatch(getAverageYTMofIssuer(payload))
  }, [locale, interest, ytmChange, TimeRange, StartDate, EndDate])

  return (
    <>
      <div
        className="d-flex justify-content-space-between ali-center mb-8"
        style={{ height: 20 }}
      >
        <div
          className="d-flex ali-center justify-content-space-between"
          style={{ gap: 8 }}
        >
          <DispatchActionTab
            data={AVERAGE_YTM_INTEREST}
            activeTab={interest}
            onChangeTab={handleChangeTCPH_TP}
            itemStyle={{
              width: '100%',
              fontWeight: 600,
            }}
            listDisabled={['notReal']}
          />
          {interest === 'real' && (
            <div>
              <Toggle
                handleClick={handleChangeExchangePrice}
                label="bond.corporateBond.averageYtmInterest.YTM_CHANGE"
                isOn={ytmChange}
              />
            </div>
          )}
        </div>

        {interest === 'real' && ytmChange && (
          <DispatchActionTabWithCalendar
            id="overviewAverageYtm"
            tabs={DATE_LIQUIDITY_TABS}
            activeTab={TimeRange}
            startDate={StartDate}
            endDate={EndDate}
            datePreset={DATE_LIQUIDITY_TABS}
            excludeDateIntervals={[]}
            onChangeTab={handleChangeActiveTab}
            onChangeCalendar={handleChangeCalendar}
          />
        )}
      </div>
    </>
  )
}

export default Filter
