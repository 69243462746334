import { useSelector } from 'react-redux'
import { GrowthYearly } from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { selectDataTable } from '../cpiAndInflationTable/store/slice'
import { selectCPIId } from './store/slice'

export const PanelGrowthYearly = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const currentCPIId = useSelector(selectCPIId)
  const dataTable = useSelector(selectDataTable)

  const currentCPIName = dataTable?.find(
    (item) => item.cpivnTypeId === currentCPIId,
  )?.cpivnTypeName

  return (
    <Panel
      title="economy.cpiAndInflation.titlePanel.CHANGES_OVER_YEARS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={`${UseI18n(
        'economy.cpiAndInflation.titlePanel.CHANGES_OVER_YEARS',
      )} ${UseI18n(
        'economy.cpiAndInflation.titlePanel.OF',
      )} ${currentCPIName} `}
      downloadJpgParams={{
        domId: 'growthYearly',
        nameScreen: UseI18n('economy.cpiAndInflation.titlePanel.CPI_INFLATION'),
        chartName: UseI18n(
          'economy.cpiAndInflation.titlePanel.CHANGES_OVER_YEARS',
        ),
      }}
    >
      <GrowthYearly
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
