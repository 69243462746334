import SegmentationProxy from '../../../proxies/sector/sectorConstituents/SegmentationProxy'
import { ServiceBase } from '../../ServiceBase'

class SegmentationService extends ServiceBase {
  getIndicators(params) {
    return this.applyMemoryCache(
      'SegmentationService.getIndicators',
      params,
    )(() => SegmentationProxy.getIndicators(params))
  }

  getPointInTime(params) {
    return this.getData(() => SegmentationProxy.getPointInTime(params))
  }

  getTimeSeries(params) {
    return this.getData(() => SegmentationProxy.getTimeSeries(params))
  }

  getGeneralData(params) {
    return this.applyMemoryCache(
      'SegmentationService.getGeneralData',
      params,
    )(() => SegmentationProxy.getGeneralData(params))
  }

  getTemplates(params) {
    return this.getData(() => SegmentationProxy.getTemplates(params))
  }

  getTemplateByShareId(params) {
    return this.getData(() => SegmentationProxy.getTemplateByShareId(params))
  }

  getTemplateDetail(params) {
    return this.getData(() => SegmentationProxy.getTemplateDetail(params))
  }

  addTemplate(params) {
    return this.getData(() => SegmentationProxy.addTemplate(params))
  }

  updateTemplate(params) {
    return this.getData(() => SegmentationProxy.updateTemplate(params))
  }

  deleteTemplate(params) {
    return this.getData(() => SegmentationProxy.deleteTemplate(params))
  }
}

export default new SegmentationService()
