export const topMover = {
  TOP_MOVER: 'Top cổ phiếu',
  FILTER: 'Bộ lọc',
  POSITIVE: 'Tích cực',
  NEGATIVE: 'Tiêu cực',
  TABLE_HEADER_TEXT: 'Giá: x 1,000. Khối lượng: x 1,000. Giá trị: x 1,000,000.',
  MARKET_CAP: 'Vốn hóa (Tỷ VND)',
  TIME_RANGE: 'Khung thời gian',
  ONE_DAY: '1 Ngày',
  ONE_WEEK: '1 Tuần',
  ONE_MONTH: '1 Tháng',
  THREE_MONTHS: '3 Tháng',
  SIX_MONTHS: '6 Tháng',
  ONE_YEAR: '1 Năm',
  TICKER: 'MÃ',
  SECTOR: 'NGÀNH',
  MATCH_PRICE: 'GIÁ',
  TOTAL_VOLUME: 'TỔNG KHỐI LƯỢNG',
  TOTAL_VALUE: 'TỔNG GIÁ TRỊ',
  CONTRIBUTION: 'ĐÓNG GÓP',
  PERCENT_CONTRIBUTION: '% ĐÓNG GÓP',
  NEWS: 'TIN TỨC',
  EXCHANGE: 'Sàn',
  TOP: 'Top',
  LB_SECTOR: 'Ngành',
  TABLE_HEADER_TEXT_TOP_BREAKOUT: 'Tỉ lệ giữa tổng KLGD hiện tại với KLGDTB ',
  TABLE_HEADER_TEXT_TOP_BREAKOUT1: ' lớn hơn ',
  ONE_POINT_TWO_TIMES: '1.2 lần',
  ONE_POINT_FIVE_TIMES: '1.5 lần',
  TWO_TIMES: '2 lần',
  FIVE_TIMES: '5 lần',
  TEN_TIMES: '10 lần',
  LB_CONTRIBUTION: 'Tỷ trọng ảnh hưởng chỉ số',
  LB_VOLUME: 'Khối lượng',
  LB_VALUE: 'Giá trị',
  LB_GAINER: 'Tăng giá',
  LB_LOSER: 'Giảm giá',
  LB_BREAKOUT: 'Đột phá',
  LB_NEW_HIGH: 'Vượt đỉnh',
  LB_NEW_LOW: 'Thủng đáy',
  LB_NET_FOREIGN_VOLUME: 'Khối lượng NN ròng',
  LB_NET_FOREIGN_VALUE: 'Giá trị NN ròng',
  LB_ALL_SECTOR: 'Tất cả ngành',
  LB_ALL_INDEX: 'Tất cả sàn',
  FOREIGN_BUY_VOLUME_TOTAL: 'KLNN Mua',
  FOREIGN_SELL_VOLUME_TOTAL: 'KLNN Bán',
  FOREIGN_NET_VOLUME_TOTAL: 'KLNN Ròng',
  AVG_TOTAL_VOLUME: 'KLTB 1 Tuần',
  AVG_TOTAL_VOLUME_OneWeek: 'KLTB 1 Tuần',
  AVG_TOTAL_VOLUME_OneMonth: 'KLTB 1 Tháng',
  AVG_TOTAL_VOLUME_ThreeMonths: 'KLTB 3 Tháng',
  AVG_TOTAL_VOLUME_SixMonths: 'KLTB 6 Tháng',
  AVG_TOTAL_VOLUME_OneYear: 'KLTB 1 Năm',
  TIMES: 'lần',
  BUY: 'Mua',
  SELL: 'Bán',
  LOWEST_PRICE: 'Đáy 1 ngày',
  LOWEST_PRICE_OneWeek: 'Đáy 1 tuần',
  LOWEST_PRICE_OneMonth: 'Đáy 1 tháng',
  LOWEST_PRICE_ThreeMonths: 'Đáy 3 tháng',
  LOWEST_PRICE_SixMonths: 'Đáy 6 tháng',
  LOWEST_PRICE_OneYear: 'Đáy 1 năm',
  FOREIGN_BUY_VALUE_TOTAL: 'GTNN Mua',
  FOREIGN_SELL_VALUE_TOTAL: 'GTNN Bán',
  FOREIGN_NET_VALUE_TOTAL: 'GTNN Ròng',
  HIGHEST_PRICE: 'Đỉnh 1 ngày',
  HIGHEST_PRICE_OneWeek: 'Đỉnh 1 tuần',
  HIGHEST_PRICE_OneMonth: 'Đỉnh 1 tháng',
  HIGHEST_PRICE_ThreeMonths: 'Đỉnh 3 tháng',
  HIGHEST_PRICE_SixMonths: 'Đỉnh 6 tháng',
  HIGHEST_PRICE_OneYear: 'Đỉnh 1 năm',
  PERCENT_PRICE_CHANGE: '% Thay đổi',
  PERCENT_PRICE_CHANGE_OneWeek: '% Thay đổi 1W',
  PERCENT_PRICE_CHANGE_OneMonth: '% Thay đổi 1M',
  PERCENT_PRICE_CHANGE_ThreeMonths: '% Thay đổi 3M',
  PERCENT_PRICE_CHANGE_SixMonths: '% Thay đổi 6M',
  PERCENT_PRICE_CHANGE_OneYear: '% Thay đổi 1Y',
  PRICE_CHANGE: 'Thay đổi',
  PRICE_CHANGE_OneWeek: 'Thay đổi 1W',
  PRICE_CHANGE_OneMonth: 'Thay đổi 1M',
  PRICE_CHANGE_ThreeMonths: 'Thay đổi 3M',
  PRICE_CHANGE_SixMonths: 'Thay đổi 6M',
  PRICE_CHANGE_OneYear: 'Thay đổi 1Y',
}
