import { useSelector } from 'react-redux'
import { TableProprietary } from '.'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import { PRICE_DATA_TIME_SELECT } from '../../common/constants'
import { TABLE_TYPE } from './constants'
import { selectFilter } from './store/slice'
import {
  downloadProprietaryDetail,
  downloadProprietaryOverview,
  downloadProprietaryTicker,
} from './store/thunk'

export const PanelProprietary = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()

  const filterData = useSelector(selectFilter)

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  const getDownloadCsv = () => {
    if (filterData?.currentCWId) {
      return downloadProprietaryTicker({
        CoveredWarrantId: filterData?.currentCWId,
        StartDate: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        EndDate: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
        TimeFilter: filterData?.timeFrequency || PRICE_DATA_TIME_SELECT.DAILY,
      })
    } else if (filterData?.tableType === TABLE_TYPE.OVERVIEW) {
      return downloadProprietaryOverview({
        StartDate: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        EndDate: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
        TimeFilter: filterData?.timeFrequency || PRICE_DATA_TIME_SELECT.DAILY,
      })
    } else {
      return downloadProprietaryDetail({
        StartDate: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        EndDate: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
        TimeFilter: filterData?.timeFrequency || PRICE_DATA_TIME_SELECT.DAILY,
      })
    }
  }

  return (
    <Panel
      title="market.coveredWarrant.cwStatistics.PRICE_DATA"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => getDownloadCsv()}
    >
      <TableProprietary
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
