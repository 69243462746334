import { useSelector } from 'react-redux'
import { LIST_TYPE, SEC_GROUP } from '../../../common/constants'
import { selectChartTabIndicatorSelected } from '../../store/slice'
import style from '../../style.module.css'
import AddMoreIndicator from '../AddMoreIndicator'
import IndicatorItemCard from '../IndicatorItemCard'
import SecuritiesItem from '../SecuritiesItemCard'

const SecuritiesChildrenSector = ({
  sectors,
  indicatorLatestPeriod,
  isViewByLatestPeriod,
}) => {
  const indicatorSelected = useSelector(
    selectChartTabIndicatorSelected(SEC_GROUP.SECTOR),
  )

  return (
    <div className={style.securitiesChildren}>
      {Object.keys(sectors)
        .sort((a, b) => +a - +b)
        .map((item) => (
          <SecuritiesItem
            key={item}
            itemId={+item}
            itemType={LIST_TYPE.SECTOR}
            dataChildren={sectors[item]}
          />
        ))}
      <AddMoreIndicator typeIndicator={SEC_GROUP.SECTOR} />
      {indicatorSelected.length
        ? indicatorSelected.map((indicator, index) => (
            <IndicatorItemCard
              key={
                indicator.uniqueID
                  ? [indicator.uniqueID, indicator.id].join('_')
                  : indicator.id
              }
              index={index}
              indicatorItem={indicator}
              typeIndicator={SEC_GROUP.SECTOR}
              itemType={LIST_TYPE.SECTOR}
              indicatorLatestPeriod={indicatorLatestPeriod}
              isViewByLatestPeriod={isViewByLatestPeriod}
            />
          ))
        : null}
    </div>
  )
}

SecuritiesChildrenSector.propTypes = {}

export default SecuritiesChildrenSector
