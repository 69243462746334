import { PropTypes } from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectIndicatorLatestPeriodLoading } from '../../store/slice'
import { PADDING_SIDES, positionXAxis } from '../constants'
import {
  changeChartTabSecurities,
  changeListLegendCustom,
  changeListYAxisCustom,
  changeStyleSetting,
  handleClickedItems,
  removeAllTooltip,
  selectChartTabActiveData,
  selectDataChartBySecurities,
  selectDataChartByTime,
  selectDataChartEquation,
  selectLoading,
} from '../store/slice'
import style from '../style.module.css'
import ChartContainer from './chartContainer'
import { MenuFooterContainer } from './MenuFooterContainer'
import { useSettingChartData } from './useSettingChartData'
import XAxisAndTimeFrequency from './XAxisAndTimeFrequency'

const ContentChildrenRight = ({ height, width, isEditChart }) => {
  const dispatch = useDispatch()

  const menuFooterContainerRef = useRef(null)
  const xAxisAndTimeFrequencyRef = useRef(null)

  // Use selector
  const dataChartByTime = useSelector(selectDataChartByTime)
  const dataChartBySecurities = useSelector(selectDataChartBySecurities)
  const dataChartEquation = useSelector(selectDataChartEquation)
  const loading = useSelector(selectLoading)
  const chartTabActiveData = useSelector(selectChartTabActiveData)

  const indicatorLatestPeriodLoading = useSelector(
    selectIndicatorLatestPeriodLoading,
  )

  const { viewType, settings } = chartTabActiveData

  // Use state
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })
  const [heightLeftoversContentChart, setHeightLeftoversContentChart] =
    useState(0)

  // Get data
  const { chartData, tableData, fullDataByTimeDataKey } = useSettingChartData({
    chartSettings: chartTabActiveData,
    dataChartByTime,
    dataChartBySecurities,
    dataChartEquation,
  })

  // Actions
  const handleChangeListLegendCustom = (data) => {
    dispatch(changeListLegendCustom(data))
  }

  const handleChangeStyleSetting = (data) => {
    dispatch(changeStyleSetting(data))
  }

  const handleChangeListYAxisCustom = (data) => {
    if (settings.positionGroupXAxis !== positionXAxis.CUSTOM) {
      const isDiffSettingPosition = Object.values(data).some(
        (item) => item.orientation !== settings.positionGroupXAxis,
      )
      if (isDiffSettingPosition) {
        dispatch(
          changeChartTabSecurities({
            positionGroupXAxis: positionXAxis.CUSTOM,
          }),
        )
      }
    }
    dispatch(changeListYAxisCustom(data))
  }

  const handleClickTooltip = (data) => {
    dispatch(handleClickedItems(data))
  }

  const handleRemoveAllTooltip = () => {
    dispatch(removeAllTooltip())
  }

  // Use effect
  useEffect(() => {
    setHeightLeftoversContentChart(
      menuFooterContainerRef.current?.offsetHeight +
        xAxisAndTimeFrequencyRef.current?.offsetHeight,
    )
    setDimensions({
      width: width - PADDING_SIDES,
      height:
        height -
        (menuFooterContainerRef.current?.offsetHeight +
          xAxisAndTimeFrequencyRef.current?.offsetHeight),
    })
  }, [
    menuFooterContainerRef.current,
    xAxisAndTimeFrequencyRef.current,
    height,
    width,
    viewType,
  ])

  // Render
  return (
    <>
      <XAxisAndTimeFrequency
        ref={xAxisAndTimeFrequencyRef}
        width={width - PADDING_SIDES}
      />
      <div
        className={style.content}
        style={{
          marginLeft: 16,
          marginRight: 16,
          height: height - heightLeftoversContentChart || 0,
        }}
      >
        <ChartContainer
          width={width}
          height={height}
          chartData={chartData}
          tableData={tableData}
          fullDataByTimeDataKey={fullDataByTimeDataKey}
          chartSettings={chartTabActiveData}
          isEdit
          loading={
            indicatorLatestPeriodLoading ||
            Object.values(loading).some((val) => val)
          }
          dimensions={dimensions}
          heightLeftoversContentChart={heightLeftoversContentChart}
          onChangeListLegendCustom={handleChangeListLegendCustom}
          onChangeListYAxisCustom={handleChangeListYAxisCustom}
          onClickTooltip={handleClickTooltip}
          onRemoveAllTooltip={handleRemoveAllTooltip}
          onChangeStyleSettings={handleChangeStyleSetting}
        />
      </div>
      <MenuFooterContainer
        menuFooterContainerRef={menuFooterContainerRef}
        width={width - PADDING_SIDES}
        height={height - heightLeftoversContentChart || 0}
        isEditChart={isEditChart}
        onChangeDimensions={setDimensions}
      />
    </>
  )
}

export default ContentChildrenRight

ContentChildrenRight.propTypes = {
  isEditChart: PropTypes.bool,
}

ContentChildrenRight.defaultProps = {
  isEditChart: false,
}
