import PropTypes from 'prop-types'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import style from './common.module.css'

const RADIO_LIST = [
  {
    value: 'USD',
    label: 'USD',
  },
  {
    value: 'VND',
    label: 'VND',
  },
]

const Header = ({ title, isPublicDebt, radioValue, handleChangeRadio }) => {
  return (
    <div className={style.header}>
      <Title title={title} />
      {isPublicDebt && (
        <>
          <VerticalLine />
          <div className="d-flex">
            {RADIO_LIST.map(({ value, label }) => {
              return (
                <div
                  key={value}
                  className={`d-flex align-center ${style.wrapRadio}
                ${value === radioValue && style.radioActive}
                `}
                  onClick={() => handleChangeRadio(value)}
                >
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    checked={value === radioValue}
                    onChange={() => handleChangeRadio(value)}
                    style={{ cursor: 'pointer' }}
                  />
                  <Span style={{ fontSize: 12 }}>{label}</Span>
                </div>
              )
            })}
          </div>
        </>
      )}
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  isPublicDebt: PropTypes.bool,
  handleChangeRadio: PropTypes.func,
}

Header.defaultProps = {
  isPublicDebt: false,
  handleChangeRadio: () => {},
}

export default Header
