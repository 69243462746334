import { debounce } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { categoryDefault } from '.'
import { Input } from '../../../common/html/Input'
import { keyBy } from '../../../utils/Common'
import { SEC_GROUP, SEC_GROUP_INDICATOR } from '../../common/constants'
import { selectIndicatorBySecurities } from '../../store/slice'
import { handleTreeIndicators } from '../helper'
import styles from '../styleTheme.module.css'
import Category from './Category'
import Metric from './Metric'
import style from './style.module.css'

export const PopupContent = ({
  typeIndicator,
  category,
  indicator,
  setCategory,
  setIndicator,
  setItemChoose,
  indicatorItem,
}) => {
  const indicators = useSelector(selectIndicatorBySecurities)
  const mapData = {
    [SEC_GROUP.COMPANY]: indicators[SEC_GROUP_INDICATOR.COMPANY],
    [SEC_GROUP.SECTOR]: indicators[SEC_GROUP_INDICATOR.SECTOR],
    [SEC_GROUP.ECONOMY]: indicators[SEC_GROUP_INDICATOR.ECONOMY],
    [SEC_GROUP.INDEX]: indicators[SEC_GROUP_INDICATOR.INDEX],
  }

  const [keyword, setKeyword] = useState('')
  const [treeData, setTreeData] = useState({
    category: null,
    metric: null,
  })

  const getIndicatorsById = useMemo(() => {
    return keyBy(mapData[typeIndicator], 'id')
  }, [typeIndicator, indicators])

  const handleSetCategory = (value) => {
    setCategory(value)
  }

  const handleSetIndicator = (value) => {
    setIndicator(value)
    if (!category) {
      const detailIndicatorId = value.split('_')
      const parentId = getIndicatorsById[detailIndicatorId[0]]?.parentId || null
      setCategory(parentId)
    }
  }

  const handleSetKeyword = (value) => {
    setKeyword(value)
    if (value) {
      setCategory(null)
    } else {
      if (indicatorItem) {
        setCategory(indicatorItem?.parentId)
      } else {
        setCategory(categoryDefault[typeIndicator])
      }
    }
  }

  useEffect(() => {
    const { data, deepestIndicators } = handleTreeIndicators(
      mapData[typeIndicator],
    )
    setTreeData({
      category: data,
      metric: deepestIndicators,
    })
  }, [typeIndicator, indicators])

  return (
    <>
      <div className={style.searchInput}>
        <div className="form-search-inbox m-0" style={{ width: 212 }}>
          <Input
            type="text"
            autoFocus
            className={`search-inbox ${styles.inputStyle}`}
            placeholder={I18n.t('financialChart.SEARCH_INDICATORS')}
            onChange={debounce((e) => handleSetKeyword(e.target.value), 500)}
          />
          <button type="button">
            <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
          </button>
        </div>
      </div>
      <div className={style.modalContent}>
        <Category
          treeData={treeData.category || {}}
          category={category}
          setCategory={handleSetCategory}
          keyword={keyword}
        />
        <Metric
          treeData={treeData.metric || {}}
          category={category}
          indicator={indicator}
          setIndicator={handleSetIndicator}
          keyword={keyword}
          typeIndicator={typeIndicator}
          setItemChoose={setItemChoose}
        />
      </div>
    </>
  )
}
