import { languageObjs } from '../../../../../configs/Language'
import { getLanguage } from '../../../../utils/Language'
import { removeAccents } from '../../../../utils/Search'
import { PIE_CHART_COLORS } from '../../constants'

export const formatChartData = (chartData) => {
  return chartData.map((item, index) => {
    if (item?.key === 'Khác' || item?.key === 'Others') {
      return {
        value: item?.percentageValue,
        color: PIE_CHART_COLORS.other,
        text: item?.key,
      }
    }
    return {
      value: item?.percentageValue,
      color: PIE_CHART_COLORS.color[index],
      text: item?.key,
    }
  })
}

export const getTop5Indicator = (payload) => {
  if (!payload.length) {
    return []
  }
  const language = getLanguage()
  const dataSorted = sortByValue(payload)
  const top5Indicator = dataSorted.slice(0, 5)
  const totalTop5 = top5Indicator.reduce(
    (prev, current) => {
      return {
        percentageValue: prev.percentageValue + current.percentageValue,
      }
    },
    { percentageValue: 0 },
  )
  const otherIndicator = {
    nationalAccountVNTypeName: language === languageObjs.vi ? 'Khác' : 'Others',
    percentageValue: 1 - totalTop5.percentageValue,
  }

  return [...top5Indicator, otherIndicator].map((item) => ({
    key: item.nationalAccountVNTypeName,
    percentageValue: item.percentageValue,
  }))
}

const sortByValue = (payload) => {
  return payload.sort((a, b) => a.percentageValue - b.percentageValue).reverse()
}

export const handleLocationName = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => {
    return {
      ...item,
      locationNameWithoutAccent: removeAccents(item.locationName),
    }
  })
}
