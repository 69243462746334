import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { ID_DOM_PANEL_PRODUCTION } from '../../chartFirst/constants'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { getYearlyQuarterValue } from '../../table/store/slice'
import ActionTab from './ActionTab'
import { ChartComponent } from './ChartComponent'
import { AVERAGE_ID, GROWTH_ID, PURCHASE_ID } from './helper'
import {
  getKeyWithTitle,
  getTimeRangeAcc,
  getTimeRangeYearly,
  selectChartData,
} from './store/slice'
import { getPurchasingManagersIndex } from './store/thunk'

const IndexBuyChart = ({ width, height }) => {
  const dispatch = useDispatch()
  const { YEARLY } = PRICE_DATA_TIME_SELECT

  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeAcc = useSelector(getTimeRangeAcc)
  const chartData = useSelector(selectChartData)
  const keyWithTitle = useSelector(getKeyWithTitle)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(
      getPurchasingManagersIndex({
        TimeRange:
          yearlyQuarterValue === YEARLY ? timeRangeYearly : timeRangeAcc,
      }),
    )
  }, [yearlyQuarterValue, timeRangeYearly, timeRangeAcc, locale])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <ActionTab />
            {typeof sizes.height === 'number' && (
              <div id={ID_DOM_PANEL_PRODUCTION.SECOND_CHART}>
                <ChartComponent
                  data={chartData}
                  width={width}
                  height={height - sizes.height}
                  lineKey={GROWTH_ID}
                  keyXAxis="monthYear"
                  barKey={PURCHASE_ID}
                  averageKey={AVERAGE_ID}
                  locale={locale}
                  keyWithTitle={keyWithTitle}
                />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

IndexBuyChart.propsType = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default IndexBuyChart
