import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  DividendTableConstant,
  cashDividendTable,
  dividendTableTitle,
  dividendType,
  dividendTypeTab,
  stockDividendTable,
} from './constants'
import {
  changeCashDivIds,
  changeStockDivIds,
  selectCashDivIds,
  selectDividendValue,
  selectLoading,
  selectStockDivIds,
  sortCashDivIds,
  sortStockDivIds,
} from './store/slice'
import { getFinancialDataDividendTable } from './store/thunk'

const DividendTable = () => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const cashDivIds = useSelector(selectCashDivIds)
  const stockDivIds = useSelector(selectStockDivIds)
  const isLoading = useSelector(selectLoading)
  const basicInfo = useSelector(selectBasicInfo)

  // Use state
  const [activeTab, setActiveTab] = useState(dividendType.CASH_DIVIDEND)

  // Get data
  const dividendTable = useMemo(() => {
    return activeTab === dividendType.CASH_DIVIDEND
      ? cashDividendTable
      : stockDividendTable
  }, [activeTab])

  const getIds = useMemo(() => {
    return activeTab === dividendType.CASH_DIVIDEND ? cashDivIds : stockDivIds
  }, [activeTab, cashDivIds, stockDivIds])

  const changeIds = useMemo(() => {
    return activeTab === dividendType.CASH_DIVIDEND
      ? changeCashDivIds
      : changeStockDivIds
  }, [activeTab])

  // Actions
  const onChangeTab = (tab) => {
    setActiveTab(tab.value)
  }

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getFinancialDataDividendTable({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId, locale])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="mb-8">
            <DispatchActionTab
              data={dividendTypeTab}
              activeTab={activeTab}
              itemStyle={{ fontWeight: 600, padding: '0 16px' }}
              onChangeTab={onChangeTab}
            />
          </div>
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              <Table
                key={activeTab}
                ids={getIds}
                columnDraggable={false}
                getDataFromRedux={selectDividendValue}
                isLoading={isLoading}
                rowDraggable={true}
                changeIds={changeIds}
                sort={
                  activeTab === dividendType.CASH_DIVIDEND
                    ? sortCashDivIds
                    : sortStockDivIds
                }
                schema={Object.keys(dividendTable).map((key, index) => {
                  const title = dividendTableTitle[key]
                  const colId = dividendTable[key]
                  const result = {
                    colId,
                    title,
                  }
                  let textAlign = 'left'
                  if (
                    [
                      dividendTable.EXERCISE_RATE,
                      dividendTable.ISSUE_VOLUME,
                      dividendTable.PLAN_VOLUME,
                      dividendTable.DPS,
                      dividendTable.DURATION,
                      dividendTable.DIVIDEND_YEAR,
                      dividendTable.CURRENCY,
                    ].includes(colId)
                  ) {
                    textAlign = 'right'
                  }
                  return {
                    ...result,
                    isI18n: false,
                    thStyle: {
                      textAlign: textAlign,
                      fontSize: 10,
                    },
                    tdStyle: {
                      textAlign: textAlign,
                    },
                    render: (val) => {
                      return [
                        dividendTable.DECLARATION_DATE,
                        dividendTable.EX_DEVIDEND_DATE,
                        dividendTable.RECORD_DATE,
                        dividendTable.PAYMENT_DATE,
                      ].includes(colId) ? (
                        <DateTimeSpan date={val} />
                      ) : (
                        <>
                          {DividendTableConstant[key].isFormatValue
                            ? DividendTableConstant[key].formatType ===
                              'percent'
                              ? valToPercent(val)
                              : DividendTableConstant[key].formatType ===
                                'noFixed'
                              ? formatVal(val, 0)
                              : formatVal(val)
                            : val}
                        </>
                      )
                    },
                  }
                })}
                stickyFirstColumn={true}
                isLargeHead={true}
                hasFooter={false}
                resizable={false}
              />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

export default DividendTable
