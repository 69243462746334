import { Valuation } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import style from '../style.module.css'

const PanelValuation = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const nameScreen = UseI18n('sector.sectorStatistics.overview.SECTOR_SUMMARY')
  const chartName = UseI18n('sector.sectorStatistics.overview.VALUATION')

  return (
    <Panel
      title="sector.sectorStatistics.overview.VALUATION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      headerClass={style.panelHeader}
      windowClass={style.windowClass}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('sector.sectorStatistics.overview.VALUATION')}
      downloadJpgParams={{
        domId: 'sectorStatisticsValuationChartId',
        nameScreen,
        chartName,
      }}
    >
      <div style={{ height }}>
        <Valuation width={width} height={height} />
      </div>
    </Panel>
  )
}

export default PanelValuation
