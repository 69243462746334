import { SizeTracker } from '../../../common/sizeTracker'
import style from './index.module.css'
import PopupDetail from './PopupDetail'
import TableEvent from './TableEvent'
import { EVENT_TABLE_ID } from './TableEvent/TickerCell'

const EventTable = () => {
  return (
    <div className={style.root} id={EVENT_TABLE_ID}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              {(size.height || size.height === 0) && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <TableEvent />
                </div>
              )}
              <PopupDetail />
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default EventTable
