import { useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { Panel } from '../../common/panel'
import { selectBasicInfo } from '../../common/topInfo/stockTopInfo/store/slice'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import Charts from './charts'

const PanelStructureOwnership = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const nameScreen = UseI18n('constants.route.corporate.ownership.LABEL')
  const chartName = UseI18n('corporate.ownership.title.structure')
  const tabName = ''

  return (
    <>
      <Panel
        title={'corporate.ownership.title.structure'}
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadJpg={true}
        downloadJpgParams={{
          domId: 'structureOwnershipChartId',
          nameScreen,
          chartName,
          tabName,
          tickerName: basicInfo.companyName,
        }}
      >
        <Charts
          width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
          height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        />
      </Panel>
    </>
  )
}

export default PanelStructureOwnership
