import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getMonthInDataRange } from '../../../../common/helper'
import {
  getExportGrowthByProduct,
  getIdsMapping,
  getImportGrowthByProduct,
  getMaxDateDetail,
} from '../../store/thunk'

const slice = createSlice({
  name: 'economy/importExport/importExportByProduct/getImportGrowthByProduct',
  initialState: {
    isLoading: true,
    origData: [],
    data: [],
    ids: [],
    yearArr: [],
    activeCategory: null,
    isAcc: false,
    currentActiveName: null,
    maxDate: null,
    activeIdAccMonth: null,
    listIdAccMonth: [],
    isDiffId: null,
  },
  reducers: {
    changeActiveCategory: (state, action) => {
      state.activeCategory = action.payload
    },
    changeIsAccGrowthChart: (state, action) => {
      state.isAcc = action.payload
    },
    setActiveIdAccMonth: (state, action) => {
      state.activeIdAccMonth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportGrowthByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getImportGrowthByProduct.fulfilled, (state, action) => {
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          month: item.realMonth,
          year: item.realYear,
          value: item.value / 1000000,
        })) || []
      state.currentActiveName = payloadData[0]?.vnTypeName
      state.origData = payloadData
      state.data = convertData(payloadData)
      state.yearArr = [...getYearInDataRange(payloadData)]
      state.isLoading = false
    })
    builder.addCase(getImportGrowthByProduct.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getExportGrowthByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getExportGrowthByProduct.fulfilled, (state, action) => {
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          month: item.realMonth,
          year: item.realYear,
          value: item.value / 1000000,
        })) || []

      state.origData = payloadData
      state.currentActiveName = payloadData[0]?.vnTypeName
      state.data = convertData(payloadData)
      state.yearArr = [...getYearInDataRange(payloadData)]
      state.isLoading = false
    })
    builder.addCase(getExportGrowthByProduct.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })

    builder.addCase(getIdsMapping.pending, (state) => {
      state.listIdAccMonth = []
    })
    builder.addCase(getIdsMapping.fulfilled, (state, action) => {
      state.listIdAccMonth = action.payload.map((item) => item.vnTypeId)
      const isDiffId = !state.listIdAccMonth?.includes(state.activeCategory)
      state.isDiffId = isDiffId
    })
    builder.addCase(getIdsMapping.rejected, (state) => {
      state.listIdAccMonth = []
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectIds = (state) => state[slice.name].ids
export const selectActiveCategory = (state) => state[slice.name].activeCategory
export const selectYearArr = (state) => state[slice.name].yearArr
export const selectIsAcc = (state) => state[slice.name].isAcc
export const selectCurrentActiveName = (state) =>
  state[slice.name].currentActiveName
export const selectMaxDate = (state) => state[slice.name].maxDate
export const selectListIdAccMonth = (state) => state[slice.name].listIdAccMonth
export const selectActiveIdAccMonth = (state) =>
  state[slice.name].activeIdAccMonth
export const selectIsDiffId = (state) => state[slice.name].isDiffId

export const {
  changeActiveCategory,
  changeIsAccGrowthChart,
  setActiveIdAccMonth,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data) => {
  const getMonthInData = getMonthInDataRange(data)

  const getMonthKeyFromRange = getMonthInData
    .map((item) => item.month)
    .sort((prev, curr) => {
      return prev - curr
    })

  const getDataByMonth = (month) => {
    return data
      .filter((item) => item.month === month)
      .map((item) => ({
        value: item.value ?? 0,
        year: item.year ?? 0,
      }))
  }

  const getMonthValueFromRange = getMonthInData.map((item) => [
    ...getDataByMonth(item.month),
  ])

  const getDataKeyIdValueFromRange = getMonthValueFromRange.length
    ? getMonthValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.year]: e.value }
        }),
      )
    : []

  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getMonthKeyFromRange[i],
  }))
}

const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  return [...new Set(allYears)]
}
