import { useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { Panel } from '../../common/panel'
import { selectBasicInfo } from '../../common/topInfo/stockTopInfo/store/slice'
import { downloadAssociates } from './associate/store/thunk'
import { downloadManagementAndDirectors } from './director/store/thunk'
import { downloadMajorShareHolders } from './majorSharehoders/store/thunk'
import { downloadSubsidiaries } from './subsidiaries/store/thunk'
import { TabContent, tabData } from './tabContent'
import { selectIdTab } from './tabContent/store/slice'
import { selectLoadingDownloadCsv } from './tooltipInfo/store/slice'

const PanelDetailOwnership = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)
  const idTab = useSelector(selectIdTab)
  const isLoadingDownloadCsv = useSelector(selectLoadingDownloadCsv)

  const nameScreen = UseI18n('constants.route.corporate.ownership.LABEL')
  const chartName = UseI18n('corporate.ownership.title.detailOwnership')
  const tabName = UseI18n(tabData[idTab].title)

  const objDownloadCsv = {
    OrganizationId: basicInfo.organizationId,
  }

  const objDownloadJpg = {
    domId: 'directorDealChartId',
    nameScreen,
    chartName,
    tabName,
    tickerName: basicInfo.ticker,
  }

  const mapDetailOwnershipTab = {
    MAJOR_SHAREHOLDER: 0,
    MANAGEMENT_AND_DIRECTOR: 1,
    SUBSIDIARIES: 2,
    ASSOCIATE: 3,
  }

  const mapFucHandleDownload = {
    [mapDetailOwnershipTab.MAJOR_SHAREHOLDER]:
      downloadMajorShareHolders(objDownloadCsv),
    [mapDetailOwnershipTab.MANAGEMENT_AND_DIRECTOR]:
      downloadManagementAndDirectors(objDownloadCsv),
    [mapDetailOwnershipTab.SUBSIDIARIES]: downloadSubsidiaries(objDownloadCsv),
    [mapDetailOwnershipTab.ASSOCIATE]: downloadAssociates(objDownloadCsv),
  }

  return (
    <Panel
      title={'corporate.ownership.title.detailOwnership'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => mapFucHandleDownload[idTab]}
      downloadJpgParams={objDownloadJpg}
      isLoadingDownloadCsv={isLoadingDownloadCsv}
    >
      <TabContent width={width} height={height} />
    </Panel>
  )
}

export default PanelDetailOwnership
