import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { VIEW_TYPE } from '../../constants'
import { getTimeFrequencyByIndicator } from '../../helpers'
import { selectIndicatorsLatestPeriod } from '../store/slice'
import { getListIndicatorLatestPeriod } from '../store/thunk'

export const DataByLatestPeriod = ({
  data,
  setLoadingIndicatorLatestPeriod,
}) => {
  const dispatch = useDispatch()

  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const { viewType, indicatorSelected, securities, equations } = data.detail
  const { economy } = securities
  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected

  useEffect(() => {
    const asyncFn = async () => {
      setLoadingIndicatorLatestPeriod(true)

      if (viewType === VIEW_TYPE.BY_SECURITIES) {
        const indicators = []

        if (companyIndicator.length) {
          companyIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: item.timeFrequency,
              })
          })
        }

        if (indexIndicator.length) {
          indexIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: item.timeFrequency,
              })
          })
        }

        if (sectorIndicator.length) {
          sectorIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: item.timeFrequency,
              })
          })
        }

        if (economy.length) {
          economy.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: item.timeFrequency,
              })
          })
        }

        if (equations && Object.keys(equations).length) {
          Object.values(equations).forEach((equation) =>
            Object.values(equation.equationIndicators).forEach((item) => {
              const indicatorLatestPeriod =
                indicatorsLatestPeriod[
                  `${item.indicatorId}_${getTimeFrequencyByIndicator(item)}`
                ]

              ;(!indicatorLatestPeriod ||
                !Object.keys(indicatorLatestPeriod).length) &&
                indicators.push({
                  indicatorId: item.indicatorId,
                  timeFrequency: item.timeFrequency,
                })
            }),
          )
        }

        await dispatch(getListIndicatorLatestPeriod(indicators))
      }

      setLoadingIndicatorLatestPeriod(false)
    }
    asyncFn()
  }, [])

  return <></>
}
