export const SORT_TYPES = {
  DEFAULT: 'default',
  ASC: 'asc',
  DESC: 'desc',
}

export const widthColumn = 190

export const heightTr = 30

export const defaultPage = 1

export const pageSize = 100000
