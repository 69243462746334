import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectPriceData } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { ChartContent } from './ChartContent'
import { ChartHeader } from './ChartHeader'
import { TablePriceDepth } from './tablePriceDepth/TablePriceDepth'
import { MULTIPLIER_FORMAT_DATA } from './tablePriceDepth/constants'

export const PriceDepth = ({ height }) => {
  // Use selector
  const data = useSelector(selectPriceData)

  // Get data
  const NUMBER_BAR = 3

  const getMaxBuyVolume = () => {
    const bestBidVol = [...Array(NUMBER_BAR)].map(
      (_, index) => `best${index + 1}BidVolume`,
    )
    const arrBestBidVol = bestBidVol.map((key) =>
      data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
    )

    return Math.max(...arrBestBidVol) / MULTIPLIER_FORMAT_DATA.totalBuyVolume
  }

  const getMaxSellVolume = () => {
    const bestAskVol = [...Array(NUMBER_BAR)].map(
      (_, index) => `best${index + 1}OfferVolume`,
    )
    const arrBestAskVol = bestAskVol.map((key) =>
      data.bidAskInfo ? Number(data.bidAskInfo[key]) : 0,
    )
    return Math.max(...arrBestAskVol) / MULTIPLIER_FORMAT_DATA.totalSellVolume
  }

  const getMaxBuySellVolume = useMemo(() => {
    return Math.max(getMaxBuyVolume(), getMaxSellVolume())
  }, [data])

  return (
    <>
      <SizeTracker>
        {(size) => (
          <div
            className="h-100"
            id="priceDepth"
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <div>
              <ChartHeader />
              <ChartContent
                totalBuyVolume={getMaxBuySellVolume}
                totalSellVolume={getMaxBuySellVolume}
                data={data}
                format={MULTIPLIER_FORMAT_DATA}
                numberBar={NUMBER_BAR}
              />
            </div>
            {size.height && (
              <div style={{ height: height - size.height }}>
                <TablePriceDepth height={height - size.height} />
              </div>
            )}
          </div>
        )}
      </SizeTracker>
    </>
  )
}

PriceDepth.propTypes = {
  height: PropTypes.number.isRequired,
}
