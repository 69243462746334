export const news = {
  LATEST_NEWS: 'Tin Mới Nhất',
  CORPORATE_ANNOUNCEMENT: 'Công bố thông tin',
  EARNING: 'Kết Quả Kinh Doanh',
  TRANSACTIONS: 'Giao dịch cổ phiếu',
  OTHER_BUSINESS_ACTIVITIES: 'Tin khác',
  ANALYTIC_NEWS: 'Tin Phân tích',
  DETAIL: 'Chi tiết',
  OTHER_COMPANY_NEWS: 'TIN DOANH NGHIỆP KHÁC',
  NEWS: 'Tin Tức',
  SHARE: 'Chia sẻ',
}
