import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import PanelMarketCap from './marketCap/PanelMarketCap'
import PanelNoteIcb from './PanelNoteIcb'
import PanelPricePerformance from './pricePerformance/chartPricePerformance/PanelPricePerformance'
import PanelValuation from './pricePerformance/chartValuation/PanelValuation'
import PanelTableSector from './pricePerformance/tableSector/PanelTableSector'
import PanelSectorsReport from './sectorsReport/PanelSectorsReport'

const MAP_KEY = {
  WRAPPER: 'WRAPPER',
  TABLE_SECTOR: 'TABLE_SECTOR',
  PRICE_PERFORMANCE: 'PRICE_PERFORMANCE',
  VALUATION: 'VALUATION',
  MARKET_CAP: 'MARKET_CAP',
  SECTORS_REPORT: 'SECTORS_REPORT',
  NOTE_ICB: 'NOTE_ICB',
}

const HEIGHT_HEADER_WRAPPER = 28
const HEIGHT_NOTE_ICB = 40

const MIN_SIZE_SETTING = {
  [MAP_KEY.WRAPPER]: {
    minWidth: 831,
    minHeight: 658,
  },
  [MAP_KEY.TABLE_SECTOR]: {
    minWidth: 236,
    minHeight: 630,
  },
  [MAP_KEY.PRICE_PERFORMANCE]: {
    minWidth: 593,
    minHeight: 370,
  },
  [MAP_KEY.VALUATION]: {
    minWidth: 593,
    minHeight: 258,
  },
  [MAP_KEY.MARKET_CAP]: {
    minWidth: 355,
    minHeight: 398,
  },
  [MAP_KEY.SECTORS_REPORT]: {
    minWidth: 255,
    minHeight: 258,
  },
  [MAP_KEY.NOTE_ICB]: {
    minWidth: 1190,
    minHeight: HEIGHT_NOTE_ICB,
  },
}

const minWidthResponsive =
  MIN_SIZE_SETTING[MAP_KEY.WRAPPER].minWidth +
  MIN_SIZE_SETTING[MAP_KEY.MARKET_CAP].minWidth +
  COMPONENT.MARGIN * 2

const MAP_SIZE = {
  [MAP_KEY.WRAPPER]: {
    width: `calc(70% - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${COMPONENT.MARGIN + HEIGHT_NOTE_ICB}px)`,
    top: 0,
    minTop: 0,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.WRAPPER],
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.TABLE_SECTOR,
        ratioWidth: -0.3,
        ratioRight: 0.5,
      },
      {
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioWidth: -0.7,
        ratioLeft: 0.3,
      },
      {
        key: MAP_KEY.VALUATION,
        ratioWidth: -0.7,
        ratioLeft: 0.3,
      },
      {
        key: MAP_KEY.MARKET_CAP,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.SECTORS_REPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.TABLE_SECTOR]: {
    width: `calc(20% - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN + HEIGHT_NOTE_ICB
    }px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    ...MIN_SIZE_SETTING[MAP_KEY.TABLE_SECTOR],
    left: 0,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.VALUATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.PRICE_PERFORMANCE]: {
    width: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(60% - ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN + HEIGHT_NOTE_ICB / 2
    }px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    left: `calc(20% + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_SECTOR].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.PRICE_PERFORMANCE],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.VALUATION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.VALUATION]: {
    width: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(40% - ${COMPONENT.MARGIN + HEIGHT_NOTE_ICB / 2}px)`,
    top: `calc(60% + ${COMPONENT.MARGIN - HEIGHT_NOTE_ICB / 2}px)`,
    left: `calc(20% + ${COMPONENT.MARGIN}px)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.PRICE_PERFORMANCE].minHeight +
      HEIGHT_HEADER_WRAPPER +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TABLE_SECTOR].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.VALUATION],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [MAP_KEY.MARKET_CAP]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(60% - ${COMPONENT.MARGIN + HEIGHT_NOTE_ICB / 2}px)`,
    top: 0,
    minTop: 0,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.WRAPPER].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.MARKET_CAP],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.SECTORS_REPORT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.SECTORS_REPORT]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(40% - ${COMPONENT.MARGIN + HEIGHT_NOTE_ICB / 2}px)`,
    top: `calc(60% + ${COMPONENT.MARGIN - HEIGHT_NOTE_ICB / 2}px)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.MARKET_CAP].minHeight + 2 * COMPONENT.MARGIN,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.WRAPPER].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.SECTORS_REPORT],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.NOTE_ICB]: {
    width: '100%',
    height: HEIGHT_NOTE_ICB,
    top: `calc(100% - ${COMPONENT.MARGIN + HEIGHT_NOTE_ICB}px)`,
    minTop: MIN_SIZE_SETTING[MAP_KEY.WRAPPER].minHeight + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.NOTE_ICB],
    left: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const MIN_SIZE_SETTING_SCREEN_SMALL = {
  [MAP_KEY.WRAPPER]: {
    minWidth: 840,
    minHeight: 598,
  },
  [MAP_KEY.TABLE_SECTOR]: {
    minWidth: 250,
    minHeight: 570,
  },
  [MAP_KEY.PRICE_PERFORMANCE]: {
    minWidth: 586,
    minHeight: 320,
  },
  [MAP_KEY.VALUATION]: {
    minWidth: 586,
    minHeight: 246,
  },
  [MAP_KEY.MARKET_CAP]: {
    minWidth: 418,
    minHeight: 349,
  },
  [MAP_KEY.SECTORS_REPORT]: {
    minWidth: 418,
    minHeight: 349,
  },
  [MAP_KEY.NOTE_ICB]: {
    minWidth: 840,
    minHeight: HEIGHT_NOTE_ICB,
  },
}

export const MAP_SIZE_SCREEN_SMALL = {
  [MAP_KEY.WRAPPER]: {
    width: `100%`,
    height: `calc(60% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.WRAPPER],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.TABLE_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioHeight: -0.35,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.VALUATION,
        ratioHeight: -0.65,
        ratioTop: 0.35,
      },
      {
        key: MAP_KEY.MARKET_CAP,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.SECTORS_REPORT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.TABLE_SECTOR]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(60% - ${HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.TABLE_SECTOR],
    left: 0,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.VALUATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.PRICE_PERFORMANCE]: {
    width: `calc(70% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(35% - ${HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    left: `calc(30% + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.TABLE_SECTOR].minWidth +
      2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.PRICE_PERFORMANCE],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.VALUATION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.VALUATION]: {
    width: `calc(70% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(25% - ${2 * COMPONENT.MARGIN}px)`,
    top: `calc(35% + ${COMPONENT.MARGIN}px)`,
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.PRICE_PERFORMANCE].minHeight +
      HEIGHT_HEADER_WRAPPER +
      2 * COMPONENT.MARGIN,
    left: `calc(30% + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.TABLE_SECTOR].minWidth +
      2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.VALUATION],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [MAP_KEY.MARKET_CAP]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(40% - ${2 * COMPONENT.MARGIN + HEIGHT_NOTE_ICB}px)`,
    top: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.WRAPPER].minHeight +
      2 * COMPONENT.MARGIN,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.MARKET_CAP],
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.SECTORS_REPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.SECTORS_REPORT]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(40% - ${2 * COMPONENT.MARGIN + HEIGHT_NOTE_ICB}px)`,
    top: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.WRAPPER].minHeight +
      2 * COMPONENT.MARGIN,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.MARKET_CAP].minWidth +
      2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SECTORS_REPORT],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.NOTE_ICB]: {
    width: '100%',
    height: HEIGHT_NOTE_ICB - COMPONENT.MARGIN,
    top: `calc(100% - ${2 * COMPONENT.MARGIN + HEIGHT_NOTE_ICB}px)`,
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.WRAPPER].minHeight +
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.SECTORS_REPORT].minHeight +
      2 * COMPONENT.MARGIN,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.NOTE_ICB],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <div className="w-100 h-100 position-relative">
      <Panel
        title={'sector.sectorStatistics.overview.PRICE_PERFORMANCE'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.WRAPPER}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelTableSector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TABLE_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.TABLE_SECTOR].width - PANEL_PADDING}
        height={sizes[MAP_KEY.TABLE_SECTOR].height - HEADER_PANEL_HEIGHT}
      />
      <PanelPricePerformance
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PRICE_PERFORMANCE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PRICE_PERFORMANCE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PRICE_PERFORMANCE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelValuation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUATION}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.VALUATION].width - PANEL_PADDING}
        height={sizes[MAP_KEY.VALUATION].height - HEADER_PANEL_HEIGHT || 0}
      />
      <PanelMarketCap
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_CAP}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelSectorsReport
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SECTORS_REPORT}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelNoteIcb
        panelRefs={panelRefs}
        panelKey={MAP_KEY.NOTE_ICB}
        sizes={sizes}
        setSizes={setSizes}
      />
    </div>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SCREEN_SMALL,
  minWidthResponsive,
)(Content)
