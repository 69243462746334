import { useSelector } from 'react-redux'
import { ExportStatisticTable } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { TIME_FREQ } from './constants'
import {
  selectCountLimit,
  selectFrequencyType,
  selectIsValOrGrowth,
  selectMaxMonth,
  selectMaxYear,
} from './store/slice'
import { getExporterDownloadStatistics } from './store/thunk'

export const PanelExportStatisticTable = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const isValOrGrowth = useSelector(selectIsValOrGrowth)
  const currentTimeFrequency = useSelector(selectFrequencyType)
  const countLimit = useSelector(selectCountLimit)

  return (
    <Panel
      title="sector.sectorSpecific.fishery.exporters.EXPORT_STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv
      downloadCsv={() =>
        getExporterDownloadStatistics({
          TimeFrequency: currentTimeFrequency,
          ValueGrowthType: isValOrGrowth,
          Year: maxYear,
          Month: currentTimeFrequency === TIME_FREQ.YEARLY ? null : maxMonth,
          Limit: countLimit,
        })
      }
    >
      <ExportStatisticTable
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
