import { useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import TextEllipsis from '../../../common/textEllipsis'
import { formatVal } from '../../../utils/Value'
import { CASHFLOW_KEYS } from '../constants'
import { formatTradingDate } from '../helper'
import {
  keys,
  selectDataCashflowTableById,
  selectDataExpected,
  selectFilterExpected,
  selectLoading,
  sortCashflow,
} from '../store/slice'

const CashflowTable = () => {
  const { ids, data } = useSelector(selectDataExpected)
  const loading = useSelector(selectLoading(keys.EXPECTED))
  const { activeMoment } = useSelector(selectFilterExpected)
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <>
      {loading ? (
        <Loading />
      ) : !!data.length ? (
        <Table
          ids={ids}
          columnDraggable={false}
          getDataFromRedux={selectDataCashflowTableById}
          isLoading={loading}
          rowDraggable={false}
          hasFooter={false}
          stickyFirstColumn={true}
          hasTooltip={false}
          resizable={false}
          sort={sortCashflow}
          schema={Object.keys(CASHFLOW_KEYS).map((key) => {
            const title = `bond.portfolio.expected.${key}`
            const colId = CASHFLOW_KEYS[key]
            const result = {
              colId,
              title,
              disableSort: false,
              thStyle: {
                textAlign: 'right',
                textTransform: 'uppercase',
                height: 24,
                fontSize: 12,
                fontWeight: 600,
              },
              tdStyle: {
                textAlign: 'right',
                height: 16,
                width: '25%',
              },
            }

            return {
              ...result,
              isI18n: false,
              canCustomTd: true,
              render: (value, colId, props) => {
                return (
                  <td {...props}>
                    <TextEllipsis
                      isI18n={false}
                      text={
                        result?.colId !== CASHFLOW_KEYS.TRADING_DATE
                          ? formatVal(value)
                          : formatTradingDate({
                              date: parseInt(value) * 1000,
                              type: activeMoment,
                              locale,
                            })
                      }
                    />
                  </td>
                )
              },
            }
          })}
        />
      ) : (
        <NoData />
      )}
    </>
  )
}

export default CashflowTable
