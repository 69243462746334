import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import {
  convertSortKeyToNumber,
  convertSortKeyToString,
} from '../../../../utils/Common'
import { Filter } from './Filter'
import { TABLE_COL_KEYS } from './constants'
import { getSortFieldDefaultByFrequency, navDataSchema } from './helpers'
import {
  changeFilter,
  selectDataCellById,
  selectFilters,
  selectIds,
  selectLoading,
  selectReCalcWidth,
  sortTableData,
} from './store/slice'
import { withGetData } from './withGetData'

const NavDataTable = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectIds)
  const filter = useSelector(selectFilters)
  const loading = useSelector(selectLoading)
  const reCalcWidth = useSelector(selectReCalcWidth)
  const fundInfo = useSelector(selectFundInfo)

  const [valueSort, setValueSort] = useState({})

  const getNoDataText = useMemo(() => {
    const frequency =
      filter.frequency === calendarTabKey
        ? I18n.t('common.FREQUENCY.DAILY')
        : I18n.t('common.FREQUENCY.' + filter.frequency.toUpperCase())
    return (
      <Translate
        value="fund.fundProfile.navData.navDataTable.NO_DATA_TEXT_TABLE"
        ticker={fundInfo.ticker}
        frequency={frequency}
      />
    )
  }, [fundInfo.ticker, filter.frequency, locale])

  // Actions
  const handleChangePage = (page) => {
    if (page) {
      dispatch(changeFilter({ page }))
    }
  }

  const handleChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeFilter({
          sortField: Object.keys(data)[0],
          sortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(
        changeFilter({
          sortField: getSortFieldDefaultByFrequency({
            frequency: filter.frequency,
          }),
          sortOrder: 1,
        }),
      )
    }
  }

  useEffect(() => {
    const sortField =
      filter.sortField ||
      getSortFieldDefaultByFrequency({
        frequency: filter.frequency,
      })
    setValueSort({ [sortField]: convertSortKeyToString(filter.sortOrder) })
  }, [filter.frequency])

  useEffect(() => {
    handleChangePage(1)
  }, [fundInfo.organizationId])

  return (
    <Table
      ids={ids}
      schema={navDataSchema({
        filter,
        locale,
      })}
      getDataFromRedux={selectDataCellById}
      renderContentPagination={() => <Filter />}
      isLoading={loading}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      hasTooltip={false}
      hasPagination={true}
      currentPage={filter.page}
      totalPage={filter.totalPage}
      reCalcWidths={reCalcWidth}
      stickies={{
        [TABLE_COL_KEYS.COL_DATE]: true,
        [TABLE_COL_KEYS.COL_WEEK]: true,
        [TABLE_COL_KEYS.COL_MONTH]: true,
        [TABLE_COL_KEYS.COL_QUARTER]: true,
        [TABLE_COL_KEYS.COL_YEAR]: true,
      }}
      noDataText={getNoDataText}
      valueSort={valueSort}
      sort={sortTableData}
      handleChangePage={handleChangePage}
      handleSortField={handleChangeSortField}
    />
  )
}

export default withGetData(NavDataTable)
