import { useEffect, useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import style from '../style.module.css'

export const ItemReposition = ({
  card,
  index,
  moveCard,
  dragDropItem,
  cards,
  setCards,
  layout,
}) => {
  const ref = useRef(null)
  const { order, text } = card

  const [{ handlerId, isOver, canDrop }, drop] = useDrop({
    accept: 'card',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }
    },
    hover(item) {
      if (!ref.current) {
        return
      }

      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return moveCard(undefined, undefined)
      }

      moveCard(dragIndex, hoverIndex)
    },
  })

  const [{ isDragging }, drag, preview] = useDrag({
    type: 'card',
    item: () => {
      return { order, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      const insert = (arr, index, newItem) => [
        ...arr.slice(0, index),
        newItem,
        ...arr.slice(index),
      ]

      if (dragDropItem.dragIndex !== undefined) {
        const arr = [...cards]
        const dragItem = arr.splice(dragDropItem.dragIndex, 1)
        const result = insert(arr, dragDropItem.hoverIndex, dragItem[0])
        setCards(result)
      }
    },
  })

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true })
  }, [])

  const isActive = canDrop && isOver
  const opacity = isDragging ? 0.2 : 1

  drag(drop(ref))

  return (
    <div
      className={`${isActive ? style.cardHover : ''} ${style.itemReposition}`}
      style={{ ...style, opacity, width: layout[index]?.width }}
      ref={ref}
      data-handler-id={handlerId}
    >
      <div className="w-100" style={{ padding: '0px 14px' }}>
        <TextEllipsisStaticLine isI18n={false} val={text} lineClamp={2} />
      </div>
    </div>
  )
}
