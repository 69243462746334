import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import SmartScreeningService from '../../../../core/services/tool/SmartScreeningService'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Button } from '../../../common/html/Button'
import useGetExchange from '../../../common/masterData/useGetExchange'
import useGetICB from '../../../common/masterData/useGetICB'
import PopupEvent from '../../../common/popup/PopupEvent'
import { keyBy } from '../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import { maxTemplate } from '../constant'
import { convertDataSubmit } from '../helper'
import style from '../index.module.css'
import {
  key,
  resetAddMoreCondition,
  resetCondition,
  resetResult,
  resetScreeningCount,
  resetTemplate,
  selectAddMoreConditionByIds,
  selectAddMoreConditionIds,
  selectAddedPeriod,
  selectChecked,
  selectConditionIds,
  selectConditions,
  selectParentChecked,
  selectScreenerDate,
  selectTemplate,
} from '../store/slice'
import { getResultThunk, updateTemplateThunk } from '../store/thunk'
import PopupSaveTemplate from './PopupSaveTemplate'
import TotalRecord from './TotalRecord'

export const SET_TEMPLATES = 'SET_TEMPLATES'

const GroupButton = () => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const { ICBs } = useGetICB()
  const { exchanges } = useGetExchange()

  const sectorsParent = useSelector(selectParentChecked(key.SECTOR))
  const sectorsChildren = useSelector(selectChecked(key.SECTOR))
  const exchangesParent = useSelector(selectParentChecked(key.EXCHANGE))
  const exchangesChildren = useSelector(selectChecked(key.EXCHANGE))
  const screenerDate = useSelector(selectScreenerDate)
  const conditionIds = useSelector(selectConditionIds)
  const conditions = useSelector(selectConditions)
  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)
  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)
  const addedPeriod = useSelector(selectAddedPeriod)
  const template = useSelector(selectTemplate)

  const [ICBsById, setICBsById] = useState({})
  const [exchangesById, setExchangesById] = useState({})
  const [isShowPopupSave, setIsShowPopupSaveAs] = useState(false)
  const [isShowPopupReset, setIsShowPopupReset] = useState(false)
  const [templates, setTemplates] = useState([])

  const sectors = sectorsParent.concat(sectorsChildren)
  const exchangesChecked = exchangesParent.concat(exchangesChildren)

  useEffect(() => {
    const asyncFn = async () => {
      const response = await SmartScreeningService.getTemplates({
        PageSize: maxTemplate,
      })
      setTemplates(response?.data)
    }
    asyncFn()
  }, [])

  useEffect(() => {
    setICBsById(keyBy(ICBs, 'icbId'))
  }, [ICBs])

  useEffect(() => {
    setExchangesById(keyBy(exchanges, 'groupId'))
  }, [exchanges])

  useEffect(() => {
    EventEmitter.subscribe(SET_TEMPLATES, setTemplates)
    return () => EventEmitter.unsubscribe(SET_TEMPLATES, setTemplates)
  }, [])

  const handleResetCondition = () => {
    dispatch(resetAddMoreCondition())
    dispatch(resetResult())
    dispatch(resetCondition())
    dispatch(resetTemplate())
    dispatch(resetScreeningCount())
    handleHidePopupReset()
  }

  const resultThunkParams =
    conditionIds.length === 0
      ? {}
      : convertDataSubmit({
          ICBsById,
          sectors,
          exchangesById,
          exchanges: exchangesChecked,
          screenerDate: getISOStartOrEndOfDay(screenerDate, timezone, true),
          conditionIds,
          conditions,
          addMoreConditionIds: addMoreConditionIds?.filter(
            (id) => addMoreConditionByIds[id]?.checked,
          ),
          addMoreConditionByIds,
          addedPeriod,
        })

  const handleApplyCondition = () => {
    dispatch(resetTemplate())
    dispatch(getResultThunk(resultThunkParams))
  }

  const handleSave = () => {
    if (template && template.templateId) {
      dispatch(
        updateTemplateThunk({
          parameter: convertDataSubmit({
            ICBsById,
            sectors,
            exchangesById,
            exchanges: exchangesChecked,
            screenerDate: getISOStartOrEndOfDay(screenerDate, timezone, true),
            conditionIds,
            conditions,
            addMoreConditionIds,
            addMoreConditionByIds,
            addedPeriod,
          }),
          templateName: template.templateName,
          templateId: template.templateId,
        }),
      )
    }
  }

  const handleShowPopupSaveAs = () => {
    if (templates.length >= maxTemplate) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('tool.smartScreening.MAX_TEMPLATE_1'),
        I18n.t('tool.smartScreening.MAX_TEMPLATE_2'),
      ])
    } else {
      setIsShowPopupSaveAs(true)
    }
  }

  const handleHidePopupSaveAs = () => setIsShowPopupSaveAs(false)

  const handleShowPopupReset = () => setIsShowPopupReset(true)
  const handleHidePopupReset = () => setIsShowPopupReset(false)

  const disabledApply = conditionIds.length === 0
  const disabledSave = conditionIds.length === 0

  return (
    <>
      <div className={`d-flex align-center`}>
        <Button
          style={{ fontSize: 11, marginRight: 16 }}
          onClick={handleShowPopupReset}
          className="btn bg-grey"
        >
          <Translate value="tool.smartScreening.RESET" />
        </Button>
        <TotalRecord resultThunkParams={resultThunkParams} />
      </div>
      <div className="group-btn">
        <Button
          style={{ fontSize: 11 }}
          onClick={handleShowPopupSaveAs}
          disabled={disabledSave}
          className={`btn btn-blue w-80 ${style.mr16} ${
            disabledSave ? style.disabled : ''
          }`}
        >
          {template && template.templateId ? (
            <Translate value="common.button.BUTTON_SAVE_AS" />
          ) : (
            <Translate value="common.button.BUTTON_SAVE" />
          )}
        </Button>
        {template && template.templateId && (
          <Button
            style={{ fontSize: 11 }}
            onClick={handleSave}
            disabled={disabledSave}
            className={`btn btn-blue w-80 ${style.mr16} ${
              disabledSave ? style.disabled : ''
            }`}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        )}
        <Button
          style={{ fontSize: 11 }}
          disabled={disabledApply}
          className={`btn btn-blue w-80 ${disabledApply ? style.disabled : ''}`}
          onClick={handleApplyCondition}
        >
          <Translate value="tool.smartScreening.APPLY" />
        </Button>
      </div>
      {isShowPopupSave && (
        <PopupSaveTemplate
          handleHidePopupSaveAs={handleHidePopupSaveAs}
          templates={templates}
          setTemplates={setTemplates}
        />
      )}
      <PopupEvent
        title={<Translate value="tool.smartScreening.RESET" />}
        message={<Translate value="tool.smartScreening.ASK_RESET" />}
        isShow={isShowPopupReset}
        handleClose={handleHidePopupReset}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: handleHidePopupReset,
        }}
        btnSubmit={{
          btnName: <Translate value="tool.smartScreening.RESET" />,
          event: handleResetCondition,
        }}
      />
    </>
  )
}

export default GroupButton
