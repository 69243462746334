import React, { useRef } from 'react'
import { Translate } from 'react-redux-i18n'
import { Label } from '../../common/html/Label'
import { Span } from '../../common/html/Span'
import TextEllipsis from '../../common/textEllipsis'
import { SearchCompany } from '../linkChart/SearchCompany'
import { CancelBtn } from './CancelBtn'
import style from './EditMenu.module.css'
import SaveBtn from './SaveBtn'

export const EditMenu = () => {
  const groupBtnRightRef = useRef()

  return (
    <div className={style.menuEdit}>
      <div
        className="d-flex align-center"
        style={{
          width: `calc(100% - ${groupBtnRightRef.current?.offsetWidth}px)`,
          paddingLeft: 16,
        }}
      >
        <Label className={style.linkedSecurities}>
          <Span className={style.text}>
            <Translate value="financialChart.LINKED_SECURITIES" />:
          </Span>
        </Label>
        <SearchCompany />
        <TextEllipsis
          className={style.linkChartDescription}
          text="financialChart.LINK_CHART_DESC"
        />
      </div>
      <div ref={groupBtnRightRef} className={style.groupBtnRight}>
        <SaveBtn />
        <CancelBtn />
      </div>
    </div>
  )
}
