import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { changeTabType } from '../store/slice'
import { TAB_TYPE } from './constants'
import { Foreign } from './foreign'
import style from './index.module.css'
import { OrderStatistic } from './orderStatistic'
import { Overview } from './overview'
import { Proprietary } from './proprietary'
import { StatisticsInvest } from './statisticInvest'

const TAB_COMPONENT_DATA = {
  [TAB_TYPE.OVERVIEW]: {
    title: 'market.equityTrading.priceStatistics.OVER_VIEW',
    Component: Overview,
  },
  [TAB_TYPE.ORDER_STATISTIC]: {
    title: 'market.equityTrading.priceStatistics.ORDER_STATISTICS',
    Component: OrderStatistic,
  },
  [TAB_TYPE.FOREIGN]: {
    title: 'market.equityTrading.priceStatistics.FOREIGN',
    Component: Foreign,
  },
  [TAB_TYPE.PROPRIETARY]: {
    title: 'market.equityTrading.priceStatistics.PROPRIETARY',
    Component: Proprietary,
  },
  [TAB_TYPE.STATISTIC_INVEST]: {
    title: 'market.equityTrading.priceStatistics.STATISTICS_INVESTOR',
    Component: StatisticsInvest,
  },
}

export const Summary = ({ width, onTabChange, onFilterChange }) => {
  const dispatch = useDispatch()

  // Use state
  const [tabType, setTabType] = useState(TAB_TYPE.OVERVIEW)

  // Actions
  const onChangeTab = (type) => () => {
    dispatch(changeTabType(type))
    setTabType(type)
    onTabChange(type)
  }

  const renderComponent = useMemo(() => {
    const Component = TAB_COMPONENT_DATA[tabType].Component
    return (
      <Component
        width={width}
        tabType={tabType}
        onFilterChange={onFilterChange}
      />
    )
  }, [tabType, width])

  return (
    <div
      className="d-flex h-100"
      style={{
        flexFlow: 'column',
      }}
    >
      <div className={style.tabLinkContainer}>
        {Object.keys(TAB_COMPONENT_DATA).map((tab) => (
          <a
            key={tab}
            className={[
              style.tabLink,
              tabType === tab ? style.tabLinkActive : '',
            ].join(' ')}
            onClick={onChangeTab(tab)}
          >
            <Span style={{ fontSize: 12 }}>
              <Translate value={TAB_COMPONENT_DATA[tab].title} />
            </Span>
            {tabType === tab && (
              <div className={style.tabLinkActiveBorderBottom}></div>
            )}
          </a>
        ))}
      </div>
      {renderComponent}
    </div>
  )
}

Summary.propTypes = {
  width: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}
