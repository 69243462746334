import { isEqual, sortBy, uniq } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  changeFilter,
  changeLoadingBondList,
  selectFilterPriceBoard,
  selectLoadingBondList,
  selectSearchFilter,
} from '../store/slice'
import { fetchMoreBondListData, getBondListData } from '../store/thunk'
import style from './style.module.css'

export const FilterBond = ({
  keySearch,
  setKeySearch,
  isShowDropdown,
  setIsShowDropdown,
}) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const { bondId } = useSelector(selectFilterPriceBoard)
  const loading = useSelector(selectLoadingBondList)
  const locale = useSelector((state) => state.i18n.locale)
  const { data, initialData, page, pageSize, enableInfinity } =
    useSelector(selectSearchFilter)

  const [bondIds, setBondIds] = useState([])
  const [isSearch, setIsSearch] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    dispatch(changeFilter({ key: 'bondId', value: bondIds }))
    setIsSearch(false)
  }

  const handleClickItem = (id) => {
    if (!bondIds.includes(id)) {
      setBondIds([...bondIds, id])
      return
    }

    setBondIds(bondIds.filter((item) => item !== id))
  }

  const handleClickParentItem = (item) => {
    const ids = bondIds.filter((child) => item?.bondIds?.includes(child))

    if (ids?.length !== item?.bondIds?.length) {
      setBondIds(uniq([...bondIds, ...item?.bondIds]))
      return
    }

    setBondIds(bondIds.filter((id) => !item?.bondIds?.includes(id)))
  }

  const handleClickAllItem = () => {
    const ids = data.map((item) => item.bondId).filter((item) => item)
    setBondIds(bondIds.length < ids.length ? ids : [])
  }

  const handleClickItemSearch = () => {
    if (!!bondIds.length) {
      setBondIds([])
      setIsSearch(!!keySearch.length ? true : false)
      return
    }

    const ids = data.map((item) => item.bondId).filter((item) => item)
    setBondIds(bondIds.length < ids.length ? ids : [])
    setIsSearch(!!bondIds.length && !keySearch.length ? false : true)
  }

  const handleSearchMore = () => {
    if (!enableInfinity || !keySearch.length) {
      return
    }

    const payload = {
      Language: locale,
      Page: page,
      PageSize: pageSize,
    }

    if (!!keySearch.length) {
      payload.Term = keySearch
    }

    dispatch(fetchMoreBondListData(payload))
  }

  useEffect(() => {
    if (isShowDropdown) {
      dispatch(changeLoadingBondList(true))

      const timeout = setTimeout(() => {
        const payload = {
          Language: locale,
          Page: 1,
          PageSize: pageSize,
        }

        if (!!keySearch.length) {
          payload.Term = keySearch
        }

        dispatch(getBondListData(payload))
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [keySearch, isShowDropdown])

  useEffect(() => {
    if (isShowDropdown) {
      if (
        !isSearch &&
        (!!keySearch.length ||
          (!keySearch.length &&
            !isEqual(
              sortBy(bondId),
              sortBy(
                initialData.map((item) => item.bondId).filter((item) => item),
              ),
            )))
      ) {
        setIsSearch(true)
      }
    }
  }, [keySearch, isShowDropdown, bondId, initialData])

  useEffect(() => {
    if (isShowDropdown && !!bondId.length) {
      setBondIds(bondId)
    }
  }, [isShowDropdown, bondId])

  const placeholderInput = useMemo(
    () => I18n.t('bond.corporateBond.priceBoard.SEARCH_PLACEHOLDER'),
    [locale],
  )

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width: 210 }}
            >
              {loading ? (
                <div style={{ height: 29 }}>
                  <Loading />
                </div>
              ) : !!data.length ? (
                <ScrollComponent
                  autoHeight={true}
                  autoHeightMax={200}
                  scrollToBottomCallback={handleSearchMore}
                >
                  <ul className="list-check">
                    {isSearch ? (
                      <li>
                        <a>
                          <label className="d-flex ali-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="checkbox mr-8"
                              onChange={() => handleClickItemSearch()}
                              checked={!!bondIds.length}
                            />

                            <TextEllipsis
                              text={
                                !!bondIds.length
                                  ? `Đã chọn ${bondIds.length}`
                                  : 'Tất cả'
                              }
                              zIndexTooltip={999}
                              isI18n={false}
                            />
                          </label>
                        </a>
                      </li>
                    ) : (
                      <li>
                        <a>
                          <label className="d-flex ali-center cursor-pointer">
                            <input
                              type="checkbox"
                              className="checkbox mr-8"
                              onChange={() => handleClickAllItem()}
                              checked={isEqual(
                                sortBy(bondIds),
                                sortBy(
                                  initialData
                                    .map((item) => item.bondId)
                                    .filter((item) => item),
                                ),
                              )}
                            />

                            <TextEllipsis
                              text="Tất cả"
                              zIndexTooltip={999}
                              isI18n={false}
                            />
                          </label>
                        </a>
                      </li>
                    )}
                    {data.map((item, index) => {
                      const ids = bondIds.filter((child) =>
                        item?.bondIds?.includes(child),
                      )

                      return (
                        <li key={`key-${index}`}>
                          <a>
                            <label
                              className="d-flex ali-center"
                              style={{
                                cursor: item.bondId ? 'pointer' : 'default',
                              }}
                            >
                              {item?.ticker && (
                                <input
                                  type="checkbox"
                                  className="checkbox mr-8"
                                  checked={
                                    ids?.length === item?.bondIds?.length
                                  }
                                  onChange={() => handleClickParentItem(item)}
                                />
                              )}
                              {item?.bondId && (
                                <input
                                  type="checkbox"
                                  className="checkbox mr-8"
                                  checked={
                                    bondIds.includes(item.bondId) ?? false
                                  }
                                  onChange={() => handleClickItem(item.bondId)}
                                  style={{
                                    marginLeft: 20,
                                  }}
                                />
                              )}

                              {item?.ticker && (
                                <TextEllipsis
                                  text={`${item?.ticker} - ${
                                    item.organizationShortName ?? ''
                                  }`}
                                  zIndexTooltip={999}
                                  isI18n={false}
                                />
                              )}

                              {item?.bondId && (
                                <TextEllipsis
                                  text={`${item?.bondTicker} `}
                                  zIndexTooltip={999}
                                  isI18n={false}
                                />
                              )}
                            </label>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
