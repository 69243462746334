import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { HorizontalStackedBarSeries } from '../../../common/chart/customCharts/horizontalStackedBarSeries'
import { Footer } from '../../../common/chart/footer'
import { getTextWidth } from '../../../common/chart/helper'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { PANEL_PADDING } from '../../../constants/Common'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import { listIconBarChart } from './constants'
import { selectDataChart, selectLoadingChart } from './store/slice'

const SETTINGS = {
  padding: 30,
  marginBottom: 40,
  tickNumber: 5,
  fontSize: 12,
}

const fillColor = {
  buMatchVolume: '#3da967',
  sdMatchVolume: '#e0505b',
  unMatchVolume: '#32a6e8',
}

const HorizontalStackBarComponent = ({ width, height }) => {
  const fontSize = getFontSize(SETTINGS.fontSize)

  const dataBarChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoadingChart)

  const maxLeftLabelWidth =
    Math.max(
      ...dataBarChart.map((item) =>
        item.priceFrom !== item.priceTo
          ? getTextWidth(
              `${formatVal(item.priceFrom)} - ${formatVal(item.priceTo)}`,
              fontSize,
            )
          : getTextWidth(formatVal(item.priceFrom), fontSize),
      ),
    ) + SETTINGS.padding

  const getTooltipContentData = () => [
    {
      label: 'market.equityTrading.deepTransaction.timeSale.BU_VOLUME',
      keyValue: 'buMatchVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
    {
      label: 'market.equityTrading.deepTransaction.timeSale.SD_VOLUME',
      keyValue: 'sdMatchVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
    {
      label: 'market.equityTrading.deepTransaction.timeSale.UNIDENTIFIED',
      keyValue: 'unMatchVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
  ]

  return (
    <div className="h-100" id="derivativeTimeAndSalesChart">
      {isLoading ? (
        <Loading />
      ) : (
        <SizeTracker>
          {(size) => {
            return (
              <>
                {dataBarChart.length ? (
                  <>
                    {size.height && (
                      <div style={{ height: `calc(100% - ${size.height}px)` }}>
                        <HorizontalStackedBarSeries
                          data={dataBarChart}
                          width={width - PANEL_PADDING * 2}
                          height={height - size.height}
                          yAxisCompareKey="referencePrice"
                          yAxisMaxKey="priceTo"
                          yAxisMinKey="priceFrom"
                          barKeys={[
                            'buMatchVolume',
                            'sdMatchVolume',
                            'unMatchVolume',
                          ]}
                          margin={{
                            left: maxLeftLabelWidth,
                            bottom: SETTINGS.marginBottom,
                          }}
                          isShowTotal={true}
                          isFillColorYAxis={true}
                          tooltipContentData={getTooltipContentData()}
                          fillColor={fillColor}
                          tickFormat={(val) => formatVal(val, 0)}
                          formatYAxisLabel={(val) => formatVal(val)}
                        />
                      </div>
                    )}
                    <div style={{ paddingTop: 10 }}>
                      <Footer key={width} list={listIconBarChart} />
                    </div>
                  </>
                ) : (
                  <NoData />
                )}
              </>
            )
          }}
        </SizeTracker>
      )}
    </div>
  )
}

export default HorizontalStackBarComponent
