export const chartFillColor = ['#45b29d', '#facc5c', '#df5a49']

export const chartPieKeys = ['nB72_A', 'nB73_A', 'nB74_A']

export const chartMappingDisplayName = {
  nB72_A: 'sector.financialAnalysis.bank.capitalStructure.SHORT_TERM_LOAN',
  nB73_A: 'sector.financialAnalysis.bank.capitalStructure.MEDIUM_TERM_LOAN',
  nB74_A: 'sector.financialAnalysis.bank.capitalStructure.LONG_TERM_LOAN',
}

export const chartFormat = {
  nB72_A: 0.01,
  nB73_A: 0.01,
  nB74_A: 0.01,
}
