import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Loading } from '../../../common/loading'
import useGetICB from '../../../common/masterData/useGetICB'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import CompanyOverview from '../component/companyOverview'
import FinancialStatements from '../component/financialStatements'
import MarketData from '../component/marketData'
import PeerAnalysisAndFinancialData from '../component/peerAnalysisAndFinancialData'
import {
  selectDataFDF,
  selectLoadingPDF,
  selectReportDate,
  selectShowPreview,
} from '../filter/store/slice'
import './index.css'
import { changeNumPages, selectZoomFile } from './store/slice'
import style from './style.module.css'

const pageHeightA4 = 1123 - 40 //29.7cm - padding

const Sample = () => {
  const dispatch = useDispatch()
  const isLoadingPDF = useSelector(selectLoadingPDF)
  const dataTopInfo = useSelector(selectBasicInfo)
  const zoomFile = useSelector(selectZoomFile)
  const showPreview = useSelector(selectShowPreview)
  const data = useSelector(selectDataFDF)
  const { ICBs } = useGetICB()
  const reportDate = useSelector(selectReportDate)
  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()

  const handleICBs = () => {
    if (ICBs.length) {
      return ICBs.filter(
        (i) =>
          i.parentICBId === data.summary.icbId ||
          i.icbId === data.summary.icbId,
      )
        .map((item) => item.icbName)
        .join('/ ')
    }
    return ''
  }

  const splitContent = () => {
    const els = document.getElementById('A4')?.childNodes
    let page = []
    let longPage = 0
    const arrPage = []
    if (els) {
      Array.from(els).forEach((div, index) => {
        longPage += div?.offsetHeight
        if (!page.length) {
          page = [div]
        } else if (longPage <= pageHeightA4) {
          page.push(div)
        } else {
          arrPage.push(page)
          if (index + 1 === els.length) {
            arrPage.push([div])
          }
          longPage = div?.offsetHeight
          page = [div]
        }
      })
    }
    return arrPage
  }

  useEffect(() => {
    const elsA4 = document.getElementById('A4Dom')
    if (!isLoadingPDF && showPreview) {
      setTimeout(() => {
        const pages = splitContent()
        dispatch(changeNumPages(pages.length))
        pages.forEach((page) => {
          let div = document.createElement('div')
          div.classList.add('A4')
          page.map((item) => div.append(item))
          elsA4.appendChild(div)
        })
      }, 500)
    }
  }, [showPreview, isLoadingPDF])

  if (isLoadingPDF) return <Loading />

  if (!showPreview) return <></>

  if (showPreview)
    return (
      <>
        <div id="downloadPDF" style={{ zoom: zoomFile }}>
          <div id="A4Dom"></div>
        </div>
        <div
          id="A4"
          className="A4"
          style={{ opacity: 0, height: 1, paddingBottom: 0 }}
        >
          <div className={style.titlePage}>
            <div>
              <span
                style={{
                  fontSize: 30,
                  fontWeight: '300',
                  margin: 'auto 0px',
                }}
              >
                <Translate value="corporate.companySnapshot.COMPANY_SNAPSHOT" />
              </span>
            </div>
            <div>
              <img src="/logoFiinProX.svg" alt="Logo" height={40} />
            </div>
          </div>
          <div className={style.infoTop}>
            <div>
              <span
                style={{
                  fontSize: 22,
                  color: '#136FA9',
                  marginBottom: 10,
                  fontWeight: 'bold',
                }}
              >
                {`${dataTopInfo.ticker || dataTopInfo.taxCode} - ${
                  dataTopInfo.companyName
                } (${dataTopInfo.exchange})`}
              </span>
              <br />
              <div className="mt-8">
                <span style={{ fontSize: 16.5, fontWeight: 400 }}>
                  <Translate value="corporate.companySnapshot.SECTOR" />
                </span>
                <span style={{ fontSize: 16.5, fontWeight: 400 }}>
                  {handleICBs()}
                </span>
              </div>
              <div className="d-flex mt-8">
                <div className={style.w25l}>
                  <div className={`${style.spaceBetweenContent}`}>
                    <div className={style.titleIndex}>
                      <Translate value="corporate.companySnapshot.TICKER" />
                    </div>
                    <div className={style.valueIndex}>
                      {data.summary.ticker || dataTopInfo.taxCode}
                    </div>
                  </div>
                  <div className={style.spaceBetweenContent}>
                    <div className={style.titleIndex}>
                      <Translate value="corporate.companySnapshot.EXCHANGE" />
                    </div>
                    <div className={style.valueIndex}>
                      {dataTopInfo.exchange}
                    </div>
                  </div>
                </div>
                <div className={style.w40}>
                  <div className={`${style.spaceBetweenContent}`}>
                    <div className={style.titleIndex}>
                      <Translate value="corporate.companySnapshot.OUTSTANDING_SHARE_1" />
                    </div>
                    <div className={style.valueIndex}>
                      {formatVal(data.summary.outstandingShare, 0)}
                    </div>
                  </div>
                  <div className={style.spaceBetweenContent}>
                    <div className={style.titleIndex}>
                      <Translate value="corporate.companySnapshot.FREE_FLOAT_SHARE" />
                    </div>
                    <div className={style.valueIndex}>
                      {formatVal(data.summary.freeFloat, 0)}
                    </div>
                  </div>
                </div>
                <div className={style.w25r}>
                  <div className={style.spaceBetweenContent}>
                    <div className={style.titleIndex}>
                      <Translate value="corporate.companySnapshot.REPORT_DATE" />
                    </div>
                    <div className={style.valueIndex}>
                      {formatDateTime(
                        reportDate,
                        FORMAT.YEAR_MONTH_DAY,
                        locale,
                        timezone,
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CompanyOverview />
          <PeerAnalysisAndFinancialData />
          <MarketData />
          <FinancialStatements />
          <div className="mt-30" style={{ height: 1000 }}>
            <span className={style.textBlue1}>
              <Translate value="corporate.companySnapshot.DISCLAIMER" />
            </span>
            <br />
            <div className={style.textBlueBland} style={{ padding: '8px 0px' }}>
              <Translate value="corporate.companySnapshot.TEXT_DISCLAIMER" />
            </div>
            <span className={style.textBlue1}>
              <Translate value="corporate.companySnapshot.CONTACT" />
            </span>
            <br />
            <div className={style.textBlueBland}>
              <Translate
                dangerousHTML
                value="corporate.companySnapshot.TEXT_CONTACT"
              />
            </div>
            <br />
            <span className={style.textBlueBland}>
              <Translate
                dangerousHTML
                value="corporate.companySnapshot.TEXT_CONTACT1"
              />
            </span>
          </div>
        </div>
      </>
    )
}

export default Sample
