export const common = {
  title: 'Interconnection Map',
  unit: '(bil. VND)',
  unit2: 'bil. VND',
  alertPopup:
    'The Ownership rate range is between 5% and 100%. Please re-enter valid value',
  titlePopup: 'Confirmation',
  contentPopup:
    'Are you sure you want to apply Displayed Options instead of Filter Company?',
  btnCancel: 'No',
  btnConfirm: 'Yes',
}

export const filter = {
  title: 'DISPLAYED OPTIONS',
  ShareHoldersType: {
    title: 'Share Holders',
    IndividualOwner: 'Individual Owner',
    OrganizationOwner: 'Organization Owner',
  },
  OwnershipRate: {
    title: 'Ownership Rate (%)',
  },
  Position: {
    tilte: 'Position',
    Chairman: 'Chairman of Management Board ',
    CEO: 'CEO',
  },
  Relations: {
    title: 'Relations',
    SubsidiaryAffiliate: 'Subsidiary & Affiliate',
    PaymentGuarantee: 'Payment Guarantee',
  },
  FurtherInformation: {
    titele: 'Further Information',
    OwnershipRate: 'Ownership Rate',
    OutstandingBond: 'Outstanding Bond',
    PaymentGuarantee: 'Payment Guarantee (Value)',
  },
}

export const table = {
  title: 'OUTSTANDING BOND',
  table: {
    BondTicker: 'Bond Ticker',
    Outstanding: 'Outstanding Bond',
    Total: 'Total',
    unit: '(bil. VND)',
  },
}

export const chart = {
  title: 'OWNERSHIP MAP',
  filter: {
    Level1: '1 Level',
    Level2: '2 Level',
    Level3: '3 Level',
    labelRangeFilter: 'Custom Density',
  },
  lengend: {
    OwnerofMainIssuer: 'Owner of Main Issuer',
    Individualconcurrent: 'Individual concurrent',
    Paymentguarantee: 'Payment guarantee',
    RelationshipL1: 'Relationship L1',
    RelationshipL2: 'Relationship L2',
    RelationshipL3: 'Relationship L3',
  },
  btnView: 'Organization Information',
  btnchangeBond: 'Switch to Main Object',
  statusGuarantee: 'Guarantee',
}

export const businessInformation = {
  title: 'COMPANY INFORMATION',
  btnOpenPopup: 'Filter Company',
  btnCancel: 'Cancel',
  btnApply: 'Apply',
  filter: {
    placeholerBusiness: 'Search Company',
  },
  table: {
    checkBox: ' ',
    Name: 'Name',
    CharterCapital: 'Charter Capital',
    unitBillMoney: '(Bil.VND)',
    Own: '% Own',
    Guarantee: 'Guarantee',
    IssueValue: 'Outstanding Bond',
  },
}
