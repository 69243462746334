import { createSlice, current } from '@reduxjs/toolkit'
import moment from 'moment'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { barKeys, lineKeys, xAxisKey } from '../chart/constants'
import { formatChartData } from '../constants'
import { getBarChartDataWithDummy, getLineChartDataWithDummy } from '../helps'
import {
  getHistory,
  getHistoryStackColumn,
  getTargetPriceDetail,
} from './thunk'

const initialState = {
  chartLineData: [],
  chartColumnData: [],
  tableData: [],
  tableIds: [],
  tableInitialIds: [],
  tableByIds: {},
  chartLineLoading: true,
  chartColumnLoading: true,
  tableLoading: true,
}

export const slice = createSlice({
  name: 'corporate/peersValuation/consensus/consensusHistory',
  initialState,
  reducers: {
    sort: (state, action) => {
      state.tableIds = getIdsFromProps(
        state.tableIds,
        state.tableByIds,
        action.payload,
        state.tableInitialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistory.pending, (state) => {
      state.chartLineLoading = true
    })
    builder.addCase(getHistory.fulfilled, (state, action) => {
      let prevItem = {}
      const data = getLineChartDataWithDummy(action.payload || [])
      const maxDayData = data
        .sort(
          (a, b) =>
            new Date(b.tradingDateId).getTime() -
            new Date(a.tradingDateId).getTime(),
        )
        .find((item) => lineKeys.some((key) => typeof item[key] === 'number'))
      state.chartLineData = data
        .sort(
          (a, b) =>
            new Date(a.tradingDateId).getTime() -
            new Date(b.tradingDateId).getTime(),
        )
        .map((item) => {
          const obj = {}
          if (
            maxDayData &&
            new Date(maxDayData.tradingDateId).getTime() >=
              new Date(item.tradingDateId).getTime()
          ) {
            lineKeys.forEach(
              (key) =>
                (obj[key] = item[key]
                  ? item[key] / formatChartData[key]
                  : prevItem[key]
                  ? prevItem[key]
                  : 0),
            )
          } else {
            lineKeys.forEach((key) => (obj[key] = undefined))
          }

          prevItem = { ...item, ...obj }

          return {
            ...item,
            ...obj,
            [xAxisKey]: item.tradingDateId,
          }
        })
      state.chartLineLoading = false
    })
    builder.addCase(getHistory.rejected, (state) => {
      state.chartLineLoading = true
    })
    builder.addCase(getHistoryStackColumn.pending, (state) => {
      state.chartColumnLoading = true
    })
    builder.addCase(getHistoryStackColumn.fulfilled, (state, action) => {
      const chartLineData = [...current(state.chartLineData)].sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
      const minDay = chartLineData[0].tradingDateId
      const maxDay = chartLineData[chartLineData.length - 1].tradingDateId

      state.chartColumnData = getBarChartDataWithDummy(
        action.payload || [],
        minDay,
        maxDay,
      )
        .sort(
          (a, b) =>
            new Date(a.year, a.month - 1, 1).getTime() -
            new Date(b.year, b.month - 1, 1).getTime(),
        )
        .map((item) => {
          const obj = {}
          barKeys.forEach((key) => {
            obj[key] = item[key] ? item[key] / formatChartData[key] : 0
          })

          return {
            ...item,
            ...obj,
            [xAxisKey]: moment()
              .set('date', 1)
              .set('month', item.month - 1)
              .set('year', item.year)
              .format(),
          }
        })
      state.chartColumnLoading = false
    })
    builder.addCase(getHistoryStackColumn.rejected, (state) => {
      state.chartColumnLoading = true
    })
    builder.addCase(getTargetPriceDetail.pending, (state) => {
      state.tableLoading = true
    })
    builder.addCase(getTargetPriceDetail.fulfilled, (state, action) => {
      state.tableLoading = false
      const data =
        action.payload?.map((item, index) => ({
          ...item,
          id: index,
        })) || []

      state.tableData = data
      state.tableByIds = keyBy(data, 'id')
      state.tableInitialIds = data.map((v) => v.id)
      state.tableIds = data.map((v) => v.id)
    })
    builder.addCase(getTargetPriceDetail.rejected, (state) => {
      state.tableLoading = true
    })
  },
})

export const selectChartLineData = (state) => state[slice.name].chartLineData
export const selectChartColumnData = (state) =>
  state[slice.name].chartColumnData
export const selectTableCellDataById = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name]?.tableByIds[colId], attr)
export const selectTableData = (state) => state[slice.name].tableData
export const selectTableIds = (state) => state[slice.name].tableIds
export const selectTableByIds = (state) => state[slice.name].tableByIds
export const selectChartLineLoading = (state) =>
  state[slice.name].chartLineLoading
export const selectChartColumnLoading = (state) =>
  state[slice.name].chartColumnLoading
export const selectTableLoading = (state) => state[slice.name].tableLoading
export const { sort } = slice.actions

register(slice.name, slice.reducer)
