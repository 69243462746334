import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isInValidValue } from '../../../../utils/Value'
import SelectCustom from '../../../selectCustom'
import {
  changeValueDetailPopupIndicator,
  selectValueDetailPopupIndicator,
} from '../store/slice'

const ItemSelect = ({
  defaultValue,
  keySelect,
  selectData,
  disabled,
  funcSelect,
  funcChange,
}) => {
  const dispatch = useDispatch()

  const functionSelect = funcSelect || selectValueDetailPopupIndicator
  const functionChange = funcChange || changeValueDetailPopupIndicator

  const valueStore = useSelector(functionSelect(keySelect))

  const value = valueStore || defaultValue

  useEffect(() => {
    if (isInValidValue(valueStore)) {
      dispatch(
        functionChange({
          key: keySelect,
          value: defaultValue,
        }),
      )
    }
  }, [keySelect])

  const handleChange = (value) => {
    dispatch(functionChange({ key: keySelect, value: value }))
  }

  return (
    <div className="w-100">
      <SelectCustom
        value={value}
        selectData={selectData}
        handleChange={handleChange}
        isDisable={disabled}
      />
    </div>
  )
}

ItemSelect.propTypes = {
  defaultValue: PropTypes.any,
  keySelect: PropTypes.string.isRequired,
  selectData: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
}

ItemSelect.defaultProps = {
  defaultValue: '',
  disabled: false,
}

export default ItemSelect
