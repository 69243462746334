import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { invertKeyBy, keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getDataThunk } from '../../filter/FilterOption/store/thunk'
import { transformData } from '../helper'

const name = 'bond/corporateInfo/filter'
const initialState = {
  visible: false,
  data: {},
  ids: [],
  initialIds: [],
  loading: false,
  ListIdSelect: [],
  dataOld: {},
  filterName: '',
  idsFilter: [],
  listIdsSave: [],
  listNodeHide: [],
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    updateVisible: (state, action) => {
      state.visible = action.payload
    },
    sortData: (state, actions) => {
      if (actions.payload.hasOwnProperty('name')) {
        const data = state.data
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['name'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.name.localeCompare(b?.name, actions.payload.locale),
          )
          state.ids = idList.map((e) => e.id)
          state.idsFilter = idList.map((e) => e.id)
        }
        if (actions.payload['name'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.name.localeCompare(b?.name, actions.payload.locale),
            )
            .reverse()
          state.ids = idList.map((e) => e.id)
          state.idsFilter = idList.map((e) => e.id)
        }
        if (actions.payload['name'] === undefined) {
          state.ids = state.initialIds
          state.idsFilter = state.initialIds
        }
        return
      }
      state.ids = getIdsFromProps(
        state.ids,
        state.data,
        actions.payload,
        state.initialIds,
        0,
      )
      state.idsFilter = getIdsFromProps(
        state.ids,
        state.data,
        actions.payload,
        state.initialIds,
        0,
      )
    },
    updateListId: (state, action) => {
      state.ListIdSelect = action.payload
    },
    filterByText: (state, action) => {
      const text = action.payload
      state.filterName = text
      if (text) {
        const listIds = [...state.idsFilter]
        const dataStateJSON = JSON.parse(JSON.stringify(state.data))
        const dataFilterBy = invertKeyBy(dataStateJSON).filter((e) => {
          return e.name.toLowerCase().includes(text.toLowerCase())
        })
        state.ids = listIds.filter((e) => dataFilterBy.some((i) => i.id === e))
      } else {
        state.ids = state.idsFilter
      }
    },
    updaetListIdSave: (state, action) => {
      state.listIdsSave = action.payload
    },
    updateListNodeHide: (state, action) => {
      state.listNodeHide = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      const data = transformData(action.payload)
      state.data = keyBy(data.nodes, 'id')
      state.dataOld = keyBy(data.nodes, 'id')
      state.ids = data.ids
      state.initialIds = data.ids
      state.idsFilter = data.ids
      state.loading = false
      state.ListIdSelect = data.ids
      state.listIdsSave = data.ids
    })
    builder.addCase(getDataThunk.rejected, (state, action) => {
      state.data = {}
      state.dataOld = {}
      state.ids = []
      state.initialIds = []
      state.ListIdSelect = []
      state.listIdsSave = []
      state.idsFilter = []
      state.loading = action.payload
    })
  },
})
export const selectLoadingTableCompany = (state) => state[slice.name].loading
export const selectVisible = (state) => state[slice.name].visible
export const selectIdsCompany = (state) => state[slice.name].ids
export const selectDataCompany = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name]?.data?.[id], attr)
}
export const selectListIdSelect = (state) => state[slice.name].ListIdSelect
export const selectFilterName = (state) => state[slice.name].filterName
export const selectListIdSave = (state) => state[slice.name].listIdsSave
export const selectListNodeHide = (state) => state[slice.name].listNodeHide
export const selectListCompany = (state) => state[slice.name].data

export const {
  updateVisible,
  sortData,
  updateListId,
  filterByText,
  updaetListIdSave,
  updateListNodeHide,
} = slice.actions

register(slice.name, slice.reducer)
