import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import { FUND_STRUCTURE_OPTIONS } from '../../constants'

export const FundStructure = ({ value, onChange }) => {
  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={180}
        isOptionAll
        isI18n
        textEmpty="fund.fundCenter.ALL_STRUCTURE"
        textAll="fund.fundCenter.ALL_STRUCTURE"
        options={FUND_STRUCTURE_OPTIONS}
        onChange={(value) => onChange({ structure: value })}
      />
    </div>
  )
}
