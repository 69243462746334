import { createAsyncThunk } from '@reduxjs/toolkit'
import BalanceOfPaymentService from '../../../../../core/services/economy/BalanceOfPaymentService'
import { handleExport } from '../../../../utils/Export'

export const balanceOfPaymentTableContent = createAsyncThunk(
  'economy/paymentBalance/getByTimeRange',
  async (params, { rejectWithValue }) => {
    const response = await BalanceOfPaymentService.getByTimeRange(params)
    const responseName = await BalanceOfPaymentService.getNameOrder()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const balanceOfPaymentTableContentAfterScroll = createAsyncThunk(
  'economy/paymentBalance/getByTimeRangeAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await BalanceOfPaymentService.getByTimeRange(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadBalanceOfPayment = createAsyncThunk(
  'economy/paymentBalance/downloadBalanceOfPayment',
  async (data) => {
    const response = await BalanceOfPaymentService.getDownloadBalanceOfPayment(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
