import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { SizeTrackerContainer } from '../../../../common/sizeTracker/SizeTrackerContainer'
import TextEllipsis from '../../../../common/textEllipsis'
import { MAX_INDICATOR } from '../constant'
import {
  changeCheckAllIndicator,
  changeCheckIndicatorItem,
  removeCheckedIndicator,
  selectCheckedIndicatorsById,
  selectCheckedIndicatorsId,
  selectListCheckedIndicatorsById,
  selectLoading,
  selectViewBy,
} from '../store/slice'
import { CheckboxAll } from './CheckboxAll'
import { COLUMN_KEYS } from './constants'
import { SelectedListItem } from './SelectedListItem'
import style from './style.module.css'

export const SelectedIndicators = () => {
  // Use selector
  const viewBy = useSelector(selectViewBy)
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const checkedIndicatorsId = useSelector(selectCheckedIndicatorsId(viewBy))
  const addIndicatorLoading = useSelector(selectLoading('addIndicator'))

  // Get data
  const numberCheckedIndicator = useMemo(() => {
    return Object.values(checkedIndicatorsById).filter((item) => item.isCheck)
      .length
  }, [checkedIndicatorsById])

  return (
    <SizeTrackerContainer>
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex j-b">
              <Span style={{ fontSize: 12 }} className={style.title}>
                <Translate value="tool.dataExplorer.tradingData.LB_INDICATORS" />
              </Span>
              <CheckboxAll
                dataId={checkedIndicatorsId}
                dataById={checkedIndicatorsById}
                onRemoveItem={removeCheckedIndicator}
                onCheckAllItem={changeCheckAllIndicator}
                isMaxCheck={numberCheckedIndicator === MAX_INDICATOR}
              />
            </div>
            <div className="d-flex j-b mb-20">
              <Span
                style={{
                  marginRight: 8,
                  whiteSpace: 'nowrap',
                }}
              >
                <Translate value="tool.dataExplorer.tradingData.SELECTED" />
                {`${numberCheckedIndicator}/${MAX_INDICATOR}`}
              </Span>
              <TextEllipsis
                text="tool.dataExplorer.tradingData.MAX_INDICATOR_WARNING"
                appendStyle={{
                  opacity: 0.4,
                  fontStyle: 'italic',
                }}
              />
            </div>
            {/* 20px === padding */}
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height + 20}px)` }}>
                <SelectedListItem
                  ids={checkedIndicatorsId}
                  columnKeys={COLUMN_KEYS.indicator}
                  selectDataById={selectCheckedIndicatorsById}
                  onChangeCheck={changeCheckIndicatorItem}
                  checkboxKey="isCheck"
                  loading={addIndicatorLoading}
                  isMaxCheck={numberCheckedIndicator === MAX_INDICATOR}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </SizeTrackerContainer>
  )
}
