import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class DerivationProxy extends ProxyBase {
  getSummary(params) {
    return this.get('Summary', params)
  }

  downloadSummary(params) {
    return this.get('DownloadSummary', params, 'download')
  }

  getTimeAndSales(params) {
    return this.get('TimeAndSales', params)
  }

  getTimeAndSalesChart(params) {
    return this.get('TimeAndSalesChart', params)
  }

  getDerivative(params) {
    return this.get('GetDerivative', params)
  }

  //Derivative
  getProprietaryOverview(params) {
    return this.get('Derivative/ProprieataryOverview', params)
  }
  downloadProprietaryOverview(params) {
    return this.get(
      'Derivative/DownloadProprieataryOverview',
      params,
      'download',
    )
  }
  getProprietaryDetail(params) {
    return this.get('Derivative/ProprieataryDetail', params)
  }
  downloadProprietaryDetail(params) {
    return this.get('Derivative/DownloadProprieataryDetail', params, 'download')
  }
  getProprietaryTicker(params) {
    return this.get('Derivative/ProprieataryTicker', params)
  }
  downloadProprietaryTicker(params) {
    return this.get('Derivative/DownloadProprieataryTicker', params, 'download')
  }

  //Order Statistics
  getOrderStatisticsOverview(params) {
    return this.get('OrderStatistics/Overview', params)
  }
  getOrderStatisticsTicker(params) {
    return this.get('OrderStatistics/Ticker', params)
  }
  getOrderStatisticsDetail(params) {
    return this.get('OrderStatistics/Detail', params)
  }
  downloadOrderStatisticsOverview(params) {
    return this.get('OrderStatistics/DownloadOverview', params, 'download')
  }
  downloadStatisticsTicker(params) {
    return this.get('OrderStatistics/DownloadTicker', params, 'download')
  }
  downloadStatisticsDetail(params) {
    return this.get('OrderStatistics/DownloadDetail', params, 'download')
  }

  //Historical Price
  getHistoricalOverview(params) {
    return this.get('HistorycalPrice/Overview', params)
  }
  getHistoricalTicker(params) {
    return this.get('HistorycalPrice/Ticker', params)
  }
  getHistoricalDetail(params) {
    return this.get('HistorycalPrice/Detail', params)
  }
  downloadHistoricalOverview(params) {
    return this.get('HistorycalPrice/DownloadOverview', params, 'download')
  }
  downloadHistoricalTicker(params) {
    return this.get('HistorycalPrice/DownloadTicker', params, 'download')
  }
  downloadHistoricalDetail(params) {
    return this.get('HistorycalPrice/DownloadDetail', params, 'download')
  }

  //Foreign
  getForeignOverview(params) {
    return this.get('Foreign/Overview', params)
  }
  getForeignTicker(params) {
    return this.get('Foreign/Ticker', params)
  }
  getForeignDetail(params) {
    return this.get('Foreign/Detail', params)
  }
  downloadForeignOverview(params) {
    return this.get('Foreign/DownloadOverview', params, 'download')
  }
  downloadForeignTicker(params) {
    return this.get('Foreign/DownloadTicker', params, 'download')
  }
  downloadForeignDetail(params) {
    return this.get('Foreign/DownloadDetail', params, 'download')
  }
}

export default new DerivationProxy(
  ServiceProxyConfig.Market.Derivative.ServiceUrl,
)
