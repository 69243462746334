import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { keyBy } from '../../../../utils/Common'
import { listTimeFilter } from '../constants'
import { indicator } from './constants'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import {
  changeIds,
  changeTimeFilter,
  resetDataTable,
  selectGroupColumns,
  selectLoading,
  selectPayloadCallBack,
  selectStructureIds,
  selectStructureValue,
  selectTimeFilter,
  setDataKeyById,
} from './store/slice'
import { getTableData } from './store/thunk'
import style from './style.module.css'

const TableData = ({ width, height }) => {
  const dispatch = useDispatch()
  const [isFirstTime, setIsFirstTime] = useState(true)

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const timeFilter = useSelector(selectTimeFilter)
  const groupColumns = useSelector(selectGroupColumns)
  const structureIds = useSelector(selectStructureIds)
  const payloadCallBack = useSelector(selectPayloadCallBack)

  const onChangeTab = ({ value }) => {
    dispatch(resetDataTable())
    dispatch(changeTimeFilter(value))
  }

  const scrollToLeftCallback = () => {
    if (payloadCallBack.year && payloadCallBack.month) {
      setTimeout(() => {
        dispatch(
          getTableData({
            TimeFrequency: timeFilter,
            Year: payloadCallBack.year,
            Month: payloadCallBack.month,
            NumberOfPeriod: 5,
          }),
        )
      })
      setIsFirstTime(false)
    }
  }

  useEffect(() => {
    dispatch(
      getTableData({
        TimeFrequency: timeFilter,
        NumberOfPeriod: 20,
      }),
    )
    dispatch(changeIds(indicator))
    dispatch(setDataKeyById(keyBy(indicator, 'index')))
    setIsFirstTime(true)
  }, [locale, timeFilter])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div
              className="d-flex justify-content-space-between ali-center"
              style={{ marginBottom: 8 }}
            >
              <DispatchActionTab
                onChangeTab={onChangeTab}
                data={listTimeFilter.map((item) => ({
                  title: I18n.t(item.label),
                  value: item.value,
                }))}
                activeTab={timeFilter}
              />
              <Span className={style.labelTable} style={{ fontSize: 10 }}>
                <Translate value="economy.fdi.overview.LABEL_TABLE" />
              </Span>
            </div>
            {size.height && (
              <div
                id="panelDependsOnSectorId"
                style={{ height: height - size.height }}
              >
                <Table
                  ids={structureIds}
                  columnDraggable={false}
                  getDataFromRedux={selectStructureValue}
                  isLoading={isLoading}
                  rowDraggable={false}
                  changeIds={changeIds}
                  schema={groupColumns.map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    const result = {
                      colId,
                      title,
                    }
                    if (index === 0) {
                      return {
                        ...result,
                        isI18n: true,
                        thStyle: {
                          textAlign: 'left',
                          fontSize: 10,
                        },
                        canCustomTd: true,
                        render: (val, rowId, { className, style }) => {
                          return (
                            <FirstColumnCell
                              val={val}
                              rowId={rowId}
                              className={className}
                              style={{
                                ...style,
                                width: '33%',
                                minWidth: width / 3 || 0,
                              }}
                            />
                          )
                        },
                      }
                    }
                    return {
                      ...result,
                      isI18n: true,
                      thStyle: {
                        textAlign: 'right',
                        fontSize: 10,
                      },
                      canCustomTd: true,
                      render: (val, rowId, props) => {
                        return (
                          <OtherColumnsCell
                            val={val}
                            rowId={rowId}
                            colIndex={index}
                            numCol={groupColumns.length}
                            props={props}
                          />
                        )
                      },
                    }
                  })}
                  stickyFirstColumn
                  isPagination={false}
                  hasFooter={false}
                  resizable={false}
                  scrollToLeftCallback={scrollToLeftCallback}
                  isLazyLoadLeft={true}
                  defaultScrollToRight={isFirstTime}
                />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableData
