import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { orderBy, sortBy } from 'lodash'
import moment from 'moment'
import OutStandingBons from '../../../../../../../core/services/bond/overview/outStandingBonds'
import { VALUE_TIME } from '../../constants'
import { renderTimeType } from '../../helper'
import { name } from '../../store/slice'

export const getDataValueOfBonds = createAsyncThunk(
  'overview/ouStandingBonds/getData',
  async (data, { rejectWithValue, getState }) => {
    try {
      const allState = getState()
      const state = allState[name]
      const percent = 1000000000
      const response = await OutStandingBons.getDataChart(data)
      if (response.success) {
        const dataList = response?.data || []
        const dataFormat = dataList.map((e) => ({
          ...e,
          issueValue: e.issueValue / percent,
          outstandingBond: e.outstandingBond / percent,
          redemptionValue: e.redemptionValue / percent,
          valueOfCancelBond: e.valueOfCancelBond / percent,
        }))

        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1
        const currentYear = currentDate.getFullYear()
        const currentTime = moment()
        const currentQuarter = currentTime.quarter()
        let dataReturn = []
        let dataSort = []
        if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataSort = orderBy(
            dataFormat,
            (item) => {
              const dates = item.date.split('-')

              return !!dates?.length
                ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                : item.date
            },
            'asc',
          )
        } else {
          dataSort = sortBy(dataFormat, (item) => {
            const [month, year] = item.date.split('-')
            return new Date(`${year}-${month}`)
          })
        }

        if (state?.activeTab === VALUE_TIME.MONTHLY) {
          dataReturn = dataSort.map((e) => {
            const [month, year] = e.date.split('-')
            if (
              parseInt(month) === currentMonth &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                date:
                  moment(e.date, 'M-YYYY').format('MM-YYYY') +
                  renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              date: moment(e.date, 'M-YYYY').format('MM-YYYY'),
            }
          })
        } else if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataReturn = dataSort.map((e) => {
            const [quarter, year] = e.date.split('-')

            if (
              parseInt(quarter.slice(1), 10) === currentQuarter &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                date: e.date + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              date: e.date,
            }
          })
        } else {
          dataReturn = dataSort.map((e) => {
            if (parseInt(e.date) === currentYear) {
              return {
                ...e,
                date: e.date + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              date: e.date,
            }
          })
        }

        return dataReturn
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
