export const latePaymentTypeValue = {
  ALL: 'ALL',
  Origin: 'Origin',
  Interest: 'Interest',
}

export const jobType = {
  ALL: 'ALL',
  JOB: 'JOB',
}

export const latePaymentType = [
  {
    label: 'bond.overview.expectedCash.filter.methodType.all',
    value: jobType.ALL,
  },
  {
    label: 'bond.overview.expectedCash.filter.methodType.job',
    value: jobType.JOB,
  },
]

export const CouponType = [
  {
    label: 'bond.overview.expectedCash.filter.CouponType.all',
    value: latePaymentTypeValue.ALL,
  },
  {
    label: 'bond.overview.expectedCash.filter.CouponType.origin',
    value: latePaymentTypeValue.Origin,
  },
  {
    label: 'bond.overview.expectedCash.filter.CouponType.interest',
    value: latePaymentTypeValue.Interest,
  },
]
