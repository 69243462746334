import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import useWindowDevicePixelRatio from '../../../../../../common/hooks/useWindowDevicePixelRatio'
import style from '../index.module.css'
import ItemContent from './ItemContent'

const HalfContentContribution = ({
  data,
  isPositive,
  sizes,
  maxValue,
  timeRequest,
}) => {
  const containerRef = useRef()
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [size, setSize] = useState(null)

  useEffect(() => {
    setSize({
      width: formatWithZoom(containerRef.current.getBoundingClientRect().width),
      height: formatWithZoom(
        containerRef.current.getBoundingClientRect().height,
      ),
    })
  }, [sizes])

  return (
    <div ref={containerRef} className={style.halfContentContainer}>
      {size && (
        <>
          {data.map((item, index) => (
            <ItemContent
              key={index}
              position={index + 1}
              size={size}
              item={item}
              isPositive={isPositive}
              maxValue={maxValue}
              timeRequest={timeRequest}
            />
          ))}
        </>
      )}
    </div>
  )
}

HalfContentContribution.propTypes = {
  data: PropTypes.array,
  isPositive: PropTypes.bool,
  sizes: PropTypes.string,
  numberData: PropTypes.number,
  maxValue: PropTypes.number,
}

HalfContentContribution.defaultProps = {
  isPositive: false,
}

export default HalfContentContribution
