import { chain } from 'lodash'
import moment from 'moment'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import { EXPECTED_MOMENT_TYPES } from './constants'

export const getDateWithTimezone = (date, timeZone) =>
  new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))

export const formatItem = (id, item, initialBondVolume, watchListId) => {
  const bondVolume =
    initialBondVolume ??
    (!isNaN(item?.bondVolume) ? item?.bondVolume : undefined)

  const buyValue =
    !isNaN(item?.buyPrice) && !isNaN(bondVolume)
      ? bondVolume * item.buyPrice
      : undefined

  const extraDiscounts =
    !isNaN(buyValue) &&
    !isNaN(item?.parValue) &&
    !isNaN(item?.couponInterestRate) &&
    !isNaN(item?.paymentCalendarMonth ?? 12) &&
    !isNaN(item?.countLastPaymentDate) &&
    !isNaN(item?.countDistanceDate)
      ? buyValue -
        item?.parValue *
          bondVolume *
          (1 +
            (item?.couponInterestRate /
              (12 / (item?.paymentCalendarMonth ?? 12))) *
              (item?.countLastPaymentDate / item?.countDistanceDate))
      : undefined

  const maturityDate = item?.maturityDateId
    ? moment(item?.maturityDateId)
    : undefined
  const valuationDate = item?.valuationDate
    ? moment(item?.valuationDate)
    : undefined
  const tradingDate = item?.tradingDateId
    ? moment(item?.tradingDateId)
    : undefined

  const maturityDateDiffValuationDate =
    maturityDate && valuationDate
      ? maturityDate.diff(valuationDate, 'days')
      : undefined

  const maturityDateDiffTradingDate =
    maturityDate && valuationDate
      ? maturityDate.diff(tradingDate, 'days')
      : undefined

  const unallocatedExtra =
    !isNaN(maturityDateDiffValuationDate) &&
    !isNaN(maturityDateDiffTradingDate) &&
    !isNaN(extraDiscounts)
      ? (extraDiscounts * maturityDateDiffValuationDate) /
        (maturityDateDiffTradingDate + 1)
      : undefined

  const bookValue =
    !isNaN(item?.parValue) &&
    !isNaN(bondVolume) &&
    !isNaN(item?.accruedInterest) &&
    !isNaN(unallocatedExtra)
      ? item?.parValue * bondVolume + item?.accruedInterest + unallocatedExtra
      : undefined

  const totalValue =
    !isNaN(bondVolume) && !isNaN(item?.dirtyPrice)
      ? item?.dirtyPrice * bondVolume
      : !isNaN(bondVolume) && !isNaN(item?.parValue)
      ? item?.parValue * bondVolume
      : undefined

  const profitLoss =
    !isNaN(totalValue) && !isNaN(bookValue) ? totalValue - bookValue : undefined

  return {
    ...item,
    id,
    interestBasisTypeId: item?.interestBasisTypeId ?? 1,
    buyValue,
    extraDiscounts,
    unallocatedExtra,
    bookValue,
    totalValue,
    profitLoss,
    bondVolume,
    watchListId,
    ytm: !isNaN(item?.ytm) ? item?.ytm * 100 : undefined,
  }
}

export const formatDataTable = (id, nextData, bondVolume = [], watchListId) =>
  nextData.map((item, index) =>
    formatItem(
      id + index + 1,
      item,
      !!bondVolume.length ? bondVolume[index] : undefined,
      watchListId,
    ),
  )

export const formatDataCashflowTable = ({ data, type, locale }) => {
  if (!data?.length) return []

  const dataFormatDate = data.map((item) => {
    let date

    switch (type) {
      case EXPECTED_MOMENT_TYPES.DAY:
        date = moment(item.tradingDateId).unix()
        break
      case EXPECTED_MOMENT_TYPES.MONTH:
        date = moment(`${item.month}/${item.year}`, 'MM/YYYY').unix()
        break
      case EXPECTED_MOMENT_TYPES.QUARTER:
        date = moment(item.year, FORMAT.YEAR[locale])
          .quarter(item.quarter)
          .unix()
        break

      case EXPECTED_MOMENT_TYPES.YEAR:
        date = moment(item.year, FORMAT.YEAR[locale]).unix()
        break

      default:
        break
    }
    return {
      ...item,
      tradingDateId: date,
    }
  })

  const newData = chain(dataFormatDate)
    .groupBy('tradingDateId')
    .map((value, key) => {
      return {
        tradingDateId: key,
        couponValue:
          value.reduce((total, item) => (total += item?.couponValue ?? 0), 0) /
          1000000,
        principalValue:
          value.reduce(
            (total, item) => (total += item?.principalValue ?? 0),
            0,
          ) / 1000000,
        total:
          value.reduce((total, item) => (total += item?.total ?? 0), 0) /
          1000000,
      }
    })
    .value()

  return newData.map((item, index) => ({ ...item, id: index + 1 }))
}

export const formatTradingDate = ({ date, type, locale }) => {
  switch (type) {
    case EXPECTED_MOMENT_TYPES.DAY:
      return moment(date).format(FORMAT.DATE[locale])
    case EXPECTED_MOMENT_TYPES.MONTH:
      return moment(date).format(FORMAT.MONTH_YEAR_SLASH[locale])
    case EXPECTED_MOMENT_TYPES.QUARTER:
      return `Q${moment(date).quarter()}/${moment(date).year()}`
    case EXPECTED_MOMENT_TYPES.YEAR:
      return moment(date).format(FORMAT.YEAR[locale])
    default:
      return date
  }
}

export const renderTotalItem = (data) => {
  const totalBuyValue = data.reduce(
    (total, item) => (total += item?.buyValue ?? 0),
    0,
  )

  const totalWeight = data.reduce(
    (total, item) => (total += item?.weighted ?? 0),
    0,
  )

  const totalCoupon = data.reduce(
    (total, item) =>
      (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
    0,
  )

  const totalYtm = data.reduce(
    (total, item) => (total += (item?.rbD0001 ?? 0) * (item?.buyValue ?? 0)),
    0,
  )

  const totalRbD0003 = data.reduce(
    (total, item) => (total += (item?.rbD0003 ?? 0) * (item?.buyValue ?? 0)),
    0,
  )

  const totalRbD0004 = data.reduce(
    (total, item) => (total += (item?.rbD0004 ?? 0) * (item?.buyValue ?? 0)),
    0,
  )

  const totalTotalValue = data.reduce(
    (total, item) => (total += item?.totalValue ?? 0),
    0,
  )

  const totalProfitLoss = data.reduce(
    (total, item) => (total += item?.profitLoss ?? 0),
    0,
  )

  const totalBookValue = data.reduce(
    (total, item) => (total += item?.bookValue ?? 0),
    0,
  )

  return {
    ticker: 'bond.portfolio.common.TOTAL',
    bondTypeName: '',
    organizationShortName: '',
    icbName: '',
    issueDateId: '',
    maturityDateId: '',
    nextPaymentDate: '',
    parValue: '',
    couponInterestRate: totalBuyValue !== 0 ? totalCoupon / totalBuyValue : 0,
    rbD0008: '',
    parentId: 'total',
    id: 'total',
    paymentCalendarMonth: '',
    interestBasisTypeId: '',
    tradingDateId: '',
    valuationDate: '',
    buyPrice: '',
    buyValue: totalBuyValue,
    extraDiscounts: '',
    unallocatedExtra: '',
    lastPaymentDate: '',
    accruedInterest: '',
    bookValue: totalBookValue,
    ytm: '',
    dirtyPrice: '',
    cleanPrice: '',
    totalValue: totalTotalValue,
    profitLoss: totalProfitLoss,
    remainDuration: '',
    lastTradingDateId: '',
    rbD0001: totalBuyValue !== 0 ? totalYtm / totalBuyValue : 0,
    lastDirtyPrice: '',
    lastCleanPrice: '',
    rbD0003: totalBuyValue !== 0 ? totalRbD0003 / totalBuyValue : 0,
    rbD0004: totalBuyValue !== 0 ? totalRbD0004 / totalBuyValue : 0,
    rbD0005: '',
    rbD0006: '',
    creditQ: '',
    weighted: totalWeight,
    bondEventTypeName: '',
  }
}
