import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class ComparableProxy extends ProxyBase {
  getDataProxy(params) {
    return this.post('PeersComparision', params)
  }
  getCreditRatingCoefficientProxy(params) {
    return this.get('CreditRatingCoefficient', params)
  }
  getLiabilitiesToEquityRatioProxy(params) {
    return this.get('LiabilitiesToEquityRatio', params)
  }
  getFundsFromOperationsToDebtProxy(params) {
    return this.get('FundsFromOperationsToDebt', params)
  }
  getDebtToEBITDAProxy(params) {
    return this.get('DebtToEBITDA', params)
  }
  exportFileProxy(params) {
    return this.post('DownloadPeersComparision', params, {}, 'download')
  }
}

export default new ComparableProxy(
  ServiceProxyConfig.Bond.ComparableAnalysis.ServiceUrl,
)
