import { keyToolHeaderSettings } from '../../chart/constants'
import { LIST_TYPE, VIEW_TYPE } from '../constants'
import { getDataQueryPayloadParams } from './dashboardApiParamsAndResponseHelpers'

export const getCsvHeaders = ({
  chartSettings,
  indicatorLatestPeriod,
  getSecuritiesName,
  getIndicatorNameTimeFrequency,
}) => {
  const headers = []
  const { viewType, treeSecuritiesIndicator, listLegendCustom } = chartSettings

  if (Object.keys(treeSecuritiesIndicator.companies).length) {
    Object.keys(treeSecuritiesIndicator.companies).forEach((id) => {
      treeSecuritiesIndicator.companies[id].forEach((indicator) => {
        headers.push(
          getHeaderColumnData({
            id,
            securityType: LIST_TYPE.COMPANIES,
            indicator,
            indicatorLatestPeriod,
            viewType,
            listLegendCustom,
            getSecuritiesName,
            getIndicatorNameTimeFrequency,
          }),
        )
      })
    })
  }

  if (Object.keys(treeSecuritiesIndicator.indices).length) {
    Object.keys(treeSecuritiesIndicator.indices).forEach((id) => {
      treeSecuritiesIndicator.indices[id].forEach((indicator) => {
        headers.push(
          getHeaderColumnData({
            id,
            securityType: LIST_TYPE.INDICES,
            indicator,
            indicatorLatestPeriod,
            viewType,
            listLegendCustom,
            getSecuritiesName,
            getIndicatorNameTimeFrequency,
          }),
        )
      })
    })
  }

  if (Object.keys(treeSecuritiesIndicator.sector).length) {
    Object.keys(treeSecuritiesIndicator.sector).forEach((id) => {
      treeSecuritiesIndicator.sector[id].forEach((indicator) => {
        headers.push(
          getHeaderColumnData({
            id,
            securityType: LIST_TYPE.SECTOR,
            indicator,
            indicatorLatestPeriod,
            viewType,
            listLegendCustom,
            getSecuritiesName,
            getIndicatorNameTimeFrequency,
          }),
        )
      })
    })
  }

  if (Object.keys(treeSecuritiesIndicator.economy).length) {
    Object.values(treeSecuritiesIndicator.economy).forEach((indicator) => {
      headers.push(
        getHeaderColumnData({
          securityType: LIST_TYPE.ECONOMY,
          indicator,
          indicatorType: LIST_TYPE.ECONOMY,
          indicatorLatestPeriod,
          viewType,
          listLegendCustom,
          getSecuritiesName,
          getIndicatorNameTimeFrequency,
        }),
      )
    })
  }

  return headers
}

const getHeaderColumnData = ({
  id = '',
  securityType,
  indicator,
  indicatorType,
  indicatorLatestPeriod,
  viewType,
  listLegendCustom,
  getSecuritiesName,
  getIndicatorNameTimeFrequency,
}) => {
  const securityName =
    securityType === LIST_TYPE.ECONOMY
      ? listLegendCustom[indicator.dataKey] ||
        getIndicatorNameTimeFrequency(
          indicator.indicatorId,
          indicator.indicatorTimeFrequency,
          indicatorType,
        )
      : getSecuritiesName(securityType, id)
  const indicatorName =
    securityType === LIST_TYPE.ECONOMY
      ? ''
      : listLegendCustom[indicator.dataKeyDuplicate] ||
        getIndicatorNameTimeFrequency(
          indicator.indicatorId,
          indicator.indicatorTimeFrequency,
          indicatorType,
        )

  let data = {
    id: indicator.dataKey,
    securityType: securityType,
    securityId: id,
    securityName,
    indicatorId: indicator.indicatorId,
    indicatorName,
    indicatorUnit: indicator.indicatorUnit,
    indicatorTimeFrequency: indicator.indicatorTimeFrequency,
    multiplier: indicator.multiplier,
  }

  if (viewType === VIEW_TYPE.BY_SECURITIES) {
    const periodKey = `${indicator.indicatorId}_${indicator.indicatorTimeFrequency}`
    const indicatorName =
      securityType === LIST_TYPE.ECONOMY
        ? ''
        : listLegendCustom[indicator.dataKeyBySecurityDuplicate] ||
          getIndicatorNameTimeFrequency(
            indicator.indicatorId,
            indicator.indicatorTimeFrequency,
            indicatorType,
          )
    data = {
      ...data,
      // id: indicator.dataKeyBySecurity,
      indicatorName,
      ...((indicatorLatestPeriod && indicatorLatestPeriod[periodKey]) || {}),
    }
  }

  return data
}

export const getCsvParams = ({ chartSettings, indicatorLatestPeriod }) => {
  const { viewType, securities, indicatorSelected, settings } = chartSettings
  const { companies, indices, sector, economy } = securities
  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected

  const securitiesPayload = []

  if (companyIndicator.length && companies.length) {
    securitiesPayload.push({
      securitiesType: 'Companies',
      dataIds: companies,
      indicators: companyIndicator,
    })
  }

  if (indexIndicator.length && indices.length) {
    securitiesPayload.push({
      securitiesType: 'Indices',
      dataIds: indices,
      indicators: indexIndicator,
    })
  }

  if (sectorIndicator.length && sector.length) {
    securitiesPayload.push({
      securitiesType: 'Sector',
      dataIds: sector,
      indicators: sectorIndicator,
    })
  }

  if (economy.length) {
    securitiesPayload.push({
      securitiesType: 'Economy',
      dataIds: [],
      indicators: economy,
    })
  }

  if (securitiesPayload.length) {
    return getDataQueryPayloadParams(
      viewType,
      securitiesPayload,
      indicatorLatestPeriod,
      settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
    )
  }

  return
}
