import moment from 'moment'

export const handleTemplateData = (payload) => {
  if (!payload.length) {
    return {}
  }

  const result = {}
  payload.forEach((item) => {
    if (!result[item.publicDateId]) {
      result[item.publicDateId] = {
        isOpen: true,
        templateSaved: [
          {
            id: item.templateId,
            title: item.templateName,
          },
        ],
      }
    } else {
      const newTemplate = {
        id: item.templateId,
        title: item.templateName,
      }
      result[item.publicDateId].templateSaved.push(newTemplate)
    }
  })

  return result
}

export const handleTemplateSetting = (payload) => {
  if (!payload.length) {
    return {}
  }

  const result = {}
  payload.forEach((item) => {
    result[item.templateId] = {
      name: item.templateName,
      setting: JSON.parse(item.templateParameters),
    }
  })

  return result
}

export const handleListDate = (listDate) => {
  if (!listDate.length) {
    return []
  }

  return [...listDate].sort((a, b) => moment(a).diff(moment(b))).reverse()
}

export const handleListCollapse = (previousList, listDate) => {
  if (!listDate.length) {
    return {}
  }

  const result = {}
  listDate.forEach((item, index) => {
    result[item] = index === 0
  })

  return {
    ...result,
    ...previousList,
  }
}

export const handleTickerIds = (array) => {
  const cloneArray = JSON.parse(JSON.stringify(array))
  const arrayHasTicker = cloneArray.filter((item) => item.ticker)
  const arrayWithOutTicker = cloneArray.filter((item) => !item.ticker)
  return [...arrayHasTicker, ...arrayWithOutTicker]
    .filter((item) => item.ticker)
    .sort(compare)
    .map((item) => item.organizationId)
}

const compare = (a, b) => {
  if (a.ticker < b.ticker) {
    return -1
  }
  if (a.ticker > b.ticker) {
    return 1
  }
  return 0
}
