import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { ScrollComponent } from '../../../common/ScrollComponent'
import style from '../index.module.css'
import { selectConditionIds } from '../store/slice'
import ItemCondition from './ItemCondition'
import LoadingCondition from './LoadingCondition'

const TableCondition = ({ sizes }) => {
  const conditionIds = useSelector(selectConditionIds)

  const containerRef = useRef()

  const [heightContainer, setHeightContainer] = useState(0)

  useEffect(() => {
    setHeightContainer(containerRef.current?.offsetHeight)
  }, [sizes])

  return (
    <ScrollComponent>
      <div ref={containerRef} className={`${style.relative} h-100`}>
        <LoadingCondition />
        <table className={`w-100 ${style.tableCondition}`}>
          <thead>
            <tr>
              <th>
                <Span style={{ fontSize: 10 }}>
                  <Translate value="tool.smartScreening.FIELD" />
                </Span>
              </th>
              <th>
                <div className="d-flex">
                  <p className={`${style.w48} ${style.mr4}`}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.TYPE" />
                    </Span>
                  </p>
                  <p className={style.w48}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.INTERIM" />
                    </Span>
                  </p>
                </div>
              </th>
              <th>
                <div className="d-flex j-b">
                  <p className={`${style.w45} ${style.mr10}`}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.FROM_VALUE" />
                    </Span>
                  </p>
                  <p className={style.w45}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.TO_VALUE" />
                    </Span>
                  </p>
                </div>
              </th>
              <th>
                <Span style={{ fontSize: 10 }}>
                  <Translate value="tool.smartScreening.UNIT" />
                </Span>
              </th>
              <th>
                <div className="d-flex">
                  <p className={style.mCriteria}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.ADDITIONAL_CRITERIA" />
                    </Span>
                  </p>
                  <p>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.smartScreening.VALUE" />
                    </Span>
                  </p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                style={{
                  '--height': `${heightContainer}px`,
                }}
              ></td>
              <td
                style={{
                  '--height': `${heightContainer}px`,
                }}
              ></td>
              <td
                style={{
                  '--height': `${heightContainer}px`,
                }}
              ></td>
              <td
                style={{
                  '--height': `${heightContainer}px`,
                }}
              ></td>
              <td
                style={{
                  '--height': `${heightContainer}px`,
                }}
              ></td>
            </tr>
            {conditionIds.map((indicatorId) => (
              <ItemCondition
                key={indicatorId}
                indicatorId={indicatorId}
                totalRow={conditionIds.length}
              />
            ))}
          </tbody>
        </table>
      </div>
    </ScrollComponent>
  )
}

export default TableCondition
