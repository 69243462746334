import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TIME_FREQUENCY, TYPE_FORMAT } from '../../../common/constants'
import { getMinData } from '../../../common/helper'
import { TABLE_ROW_LEVEL, VIEW_BY } from '../constant'
import { convertData } from '../helper'
import { getFDITypeName, getLocationName, getTableData } from './thunk'

const initialState = {
  isLoading: true,
  isMappingLoading: true,
  viewBy: VIEW_BY.CAPITAL,
  status: undefined,
  listFDITypeName: [],
  timeFrequency: TIME_FREQUENCY.YEARLY,
  rawData: [],
  valueById: {},
  ids: [],
  groupColumns: [],
  levelCollapse: {},
  listLocationName: [],
  tableConstants: [],
  locationChecked: {},
  payloadCallBack: {
    month: undefined,
    year: undefined,
    prevMonth: undefined,
    prevYear: undefined,
  },
  dataTable: [],
  levels: [],
  initialIds: [],
  isSpreadTable: false,
  typeFormatData: TYPE_FORMAT.CAPITAL,
}

const slice = createSlice({
  name: 'economy/fdi/fdiByCountries/tableData',
  initialState,
  reducers: {
    changeViewBy: (state, action) => {
      state.viewBy = action.payload
    },
    changeFilterStatus: (state, action) => {
      state.status = action.payload
    },
    changeTimeFrequency: (state, action) => {
      state.timeFrequency = action.payload
    },
    changeLocationChecked: (state, action) => {
      state.locationChecked = action.payload
    },
    resetDataTable: (state) => {
      state.dataTable = []
      state.payloadCallBack = {
        month: undefined,
        year: undefined,
        prevMonth: undefined,
        prevYear: undefined,
      }
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      state.locationChecked = state.valueById[rowId]
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.valueById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    setIsSpreadTable: (state, action) => {
      state.isSpreadTable = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFDITypeName.pending, (state) => {})
    builder.addCase(getFDITypeName.fulfilled, (state, action) => {
      const data = action.payload
      state.listFDITypeName = data.map((item) => ({
        value: item.fdiTypeOrder,
        name: item.fdiTypeName,
      }))

      state.status = state.viewBy
        ? data[3]?.fdiTypeOrder
        : data[0]?.fdiTypeOrder
    })
    builder.addCase(getFDITypeName.rejected, (state) => {})
    builder.addCase(getLocationName.pending, (state) => {
      state.isMappingLoading = true
    })
    builder.addCase(getLocationName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.listLocationName = data
      const tableConstants = data.map((i) => {
        const level =
          i.locationLevel === 1
            ? TABLE_ROW_LEVEL.LEVEL_1
            : TABLE_ROW_LEVEL.LEVEL_2
        if (level === TABLE_ROW_LEVEL.LEVEL_1) {
          const childrenId = data
            .filter((v) => v.parentLocationId === i.locationId)
            .map((v) => v.locationId)
          return {
            index: i.locationId,
            childrenId: childrenId,
            name: i.locationName,
            level: level,
            key: i.locationId,
            isCollapse: true,
            locationId: i.locationId,
            locationName: i.locationName,
          }
        } else {
          return {
            index: i.locationId,
            parentId: i.parentLocationId,
            name: i.locationName,
            key: i.locationId,
            level: level,
            locationId: i.locationId,
            locationName: i.locationName,
          }
        }
      })

      state.locationChecked = data[0]
      state.initialIds = state.ids = tableConstants.map((item) => item.index)
      state.levelCollapse = keyBy(tableConstants, 'index')
      state.valueById = keyBy(tableConstants, 'index')
      state.isMappingLoading = false
      state.levels = tableConstants.map((item) => ({
        id: item.locationId,
        parentId: item.parentId || item.locationId,
        level: item.level,
      }))
    })
    builder.addCase(getLocationName.rejected, (state) => {
      state.isMappingLoading = false
    })
    builder.addCase(getTableData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTableData.fulfilled, (state, action) => {
      const tableData = action.payload || []

      state.payloadCallBack = {
        month: getMinData(tableData).minMonth,
        year: getMinData(tableData).minYear,
        prevMonth: state.payloadCallBack.month,
        prevYear: state.payloadCallBack.year,
      }

      state.dataTable =
        state.timeFrequency === TIME_FREQUENCY.ACC_MONTHLY
          ? [...tableData, ...state.dataTable]
          : [...state.dataTable, ...tableData]

      const data = convertData(state.dataTable, state.timeFrequency)
      const groupColumns = []
      const dataKeyArr = Object.values(state.valueById)
      data.forEach((item) => {
        groupColumns.push({
          key: item.name,
          title: item.name,
        })
        dataKeyArr.forEach((e) => {
          const value = item[e.key]
          e[item.name] = value
        })
      })
      state.groupColumns = state.dataTable.length
        ? [
            {
              key: 'name',
              title: 'economy.fdi.fdiByCountries.COUNTRY_CONTINENT',
            },
            ...groupColumns,
          ]
        : []
      state.typeFormatData = state.viewBy
        ? TYPE_FORMAT.CAPITAL
        : TYPE_FORMAT.NUMBER_OF_PROJECT
      state.isLoading = false
    })
    builder.addCase(getTableData.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectViewBy = (state) => state[slice.name].viewBy
export const selectFilterStatus = (state) => state[slice.name].status
export const selectListFDITypeName = (state) =>
  state[slice.name].listFDITypeName
export const selectMappingLoading = (state) =>
  state[slice.name].isMappingLoading
export const selectRawData = (state) => state[slice.name].rawData
export const selectFDIByCountriesIds = (state) => state[slice.name].ids
export const selectFDIByCountriesValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueById[id], attr)
}
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectItemData = (id) => (state) => state[slice.name].valueById[id]
export const selectTimeFrequency = (state) => state[slice.name].timeFrequency
export const selectLocationName = (state) => state[slice.name].listLocationName
export const selectLocationChecked = (state) =>
  state[slice.name].locationChecked
export const selectPayloadCallBack = (state) =>
  state[slice.name].payloadCallBack
export const selectIsSpreadTable = (state) => state[slice.name].isSpreadTable
export const selectTypeFormatData = (state) => state[slice.name].typeFormatData

export const {
  changeViewBy,
  changeFilterStatus,
  changeTimeFrequency,
  changeLocationChecked,
  changeIndicator,
  resetDataTable,
  sort,
  setIsSpreadTable,
} = slice.actions

register(slice.name, slice.reducer)
