import { useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Input } from '../../html/Input'
import { Span } from '../../html/Span'
import { TextArea } from '../../html/TextArea'
import { PopupHeaderCommon } from '../../popup/PopupHeaderCommon'
import { withWidget } from '../context'
import { takeScreenShot } from '../helper'
import { createChartLayout } from '../store/thunk'
import style from './index.module.css'

const SaveTemplate = ({ changeSaveTemplateStatus, widget }) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const textAreaRef = useRef()

  const onSubmit = async (e) => {
    e.preventDefault()
    const screenShot = await takeScreenShot(widget)
    widget.save((chartState) => {
      const data = {
        name: inputRef.current?.value,
        description: textAreaRef.current?.value,
        layout: JSON.stringify(chartState),
        thumbnails: screenShot,
      }
      dispatch(createChartLayout(data))
      changeSaveTemplateStatus(false)
    })
  }

  return (
    <PopupHeaderCommon
      setIsShow={changeSaveTemplateStatus}
      title="common.tradingView.SAVE_TEMPLATE"
    >
      <>
        <div className={style.nameRow}>
          <div className="mr-10">
            <Span style={{ fontSize: 11 }}>
              <Translate value="common.tradingView.NAME_OF_TEMPLATE" />
            </Span>
          </div>
          <Input
            className={style.nameInput}
            style={{ fontSize: 11 }}
            ref={inputRef}
          />
        </div>
        <div className={style.descRow}>
          <div className="mr-10">
            <Span style={{ fontSize: 11 }}>
              <Translate value="common.tradingView.DESCRIPTION" />
            </Span>
          </div>
          <TextArea
            className={style.descTextArea}
            style={{ fontSize: 11 }}
            ref={textAreaRef}
          />
        </div>
        <div className={style.footerContainer}>
          <Button
            onClick={() => changeSaveTemplateStatus(false)}
            className={`btn normal w-80 h-20 ${style.cancelBtn}`}
            style={{ fontSize: 11 }}
          >
            <Translate value="common.button.BUTTON_CANCEL" />
          </Button>
          <Button
            onClick={onSubmit}
            className={`btn btn-blue w-80 h-20 ${style.createBtn}`}
            style={{ fontSize: 11 }}
          >
            <Translate value="common.button.BUTTON_CREATE" />
          </Button>
        </div>
      </>
    </PopupHeaderCommon>
  )
}

export default withWidget(SaveTemplate)
