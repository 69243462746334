import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MasterService from '../../../../../core/services/common/MasterService'
import SimpleSearchService from '../../../../../core/services/common/SimpleSearchService'
import SegmentationService from '../../../../../core/services/sector/sectorConstituents/SegmentationService'
import WatchlistService from '../../../../../core/services/watchlist/WatchlistService'

export const getSearchCompanies = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getSearchCompanies',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchCompany(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getWatchList = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getWatchList',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlist(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTickerByWLId = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getTickerByWLId',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlistDetail(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrganizationsByExchange = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getOrganizationsByExchange',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterService.getOrganizationsByExchange(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrganizationsByIcb = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getOrganizationsByIcb',
  async ({ ICBId }, { rejectWithValue }) => {
    try {
      const response = await MasterService.getOrganizationsByIcb({ ICBId })
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicators = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getIndicators',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getIndicators(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPointInTime = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getPointInTime',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getPointInTime(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTimeSeries = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getTimeSeries',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getTimeSeries(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getGeneralData = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getGeneralData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getGeneralData(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTemplates = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getTemplates',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getTemplates(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTemplateDetail = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getTemplateDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getTemplateDetail(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTemplateByShareId = createAsyncThunk(
  'sector/sectorConstituents/segmentation/getTemplateByShareId',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SegmentationService.getTemplateByShareId(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const addTemplate = createAsyncThunk(
  'sector/sectorConstituents/segmentation/addTemplate',
  async (data, { dispatch }) => {
    const response = await SegmentationService.addTemplate(data)
    if (response.success) {
      dispatch(getTemplates())
    }
  },
)

export const updateTemplate = createAsyncThunk(
  'sector/sectorConstituents/segmentation/updateTemplate',
  async (data, { dispatch }) => {
    const response = await SegmentationService.updateTemplate(data)
    if (response.success) {
      dispatch(getTemplates())
    }
  },
)

export const deleteTemplate = createAsyncThunk(
  'sector/sectorConstituents/segmentation/deleteTemplate',
  async (data, { dispatch }) => {
    const response = await SegmentationService.deleteTemplate(data)
    if (response.success) {
      dispatch(getTemplates())
    }
  },
)
