import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../../common/selectCustom'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import { YEAR_OPTIONS } from '../../constants'
import { changeYearActive, selectYearActive } from '../store/slice'

export const YearSelectOption = ({ typeDataTime }) => {
  const dispatch = useDispatch()
  const activeYear = useSelector(selectYearActive)

  const onChange = (val) => {
    dispatch(changeYearActive(val))
  }

  return (
    <div
      className={[
        'w-80',
        typeDataTime !== PRICE_DATA_TIME_SELECT.YEARLY && 'ml-8',
      ].join(' ')}
    >
      <SelectCustom
        value={activeYear}
        selectData={YEAR_OPTIONS}
        handleChange={onChange}
      />
    </div>
  )
}
