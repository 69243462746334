export const chartFillColor = {
  toig: '#1d97ef',
  nig: '#f7959c',
}

export const chartLeftKeys = ['toig', 'nig']

export const chartMappingDisplayName = {
  toig: 'sector.financialAnalysis.bank.profitability.TOTAL_OPERATING',
  nig: 'sector.financialAnalysis.bank.profitability.NET_INCOME_GROWTH',
}

export const chartFormat = {
  toig: 0.01,
  nig: 0.01,
}
