import { Translate } from 'react-redux-i18n'
import style from './index.module.css'

export const NoData = ({ text }) => {
  return (
    <div className={style.container}>
      <div className="no-data">
        <i className="icon-search-none" />
        <Translate value={text || 'common.NO_DATA'} />
      </div>
    </div>
  )
}
