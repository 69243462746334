import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../common/html/Span'
import { SizeTracker } from '../../common/sizeTracker'
import {
  changeCurrentTypeStatistics,
  keepCurrentSelectedIndexes,
  selectCurrentTypeStatistics,
  selectListCategory,
} from './balanceOfPaymentTable/store/slice'
import {
  setIsYearlyGrowthChart,
  setLoadingGrowthChart,
} from './changeBalance/store/slice'
import {
  changeIsYearlyComponentsChart,
  changeTypeStatisticsComponentsChart,
  keepListCheckbox,
} from './changeComponents/store/slice'
import { BALANCE_PAYMENT_FILTER } from './constants'
import Content from './Content'
import style from './index.module.css'

const PaymentBalance = () => {
  const dispatch = useDispatch()

  const listCategoriesTable = useSelector(selectListCategory)
  const currentType = useSelector(selectCurrentTypeStatistics)

  const currentSelectedIndexes = listCategoriesTable?.map((item) => item.index)

  const handleChangeFilter = (item) => {
    dispatch(changeCurrentTypeStatistics(item.value))
    dispatch(setLoadingGrowthChart(true))
    dispatch(
      changeIsYearlyComponentsChart(
        item.value === BALANCE_PAYMENT_FILTER[0].value,
      ),
    )
    dispatch(
      setIsYearlyGrowthChart(item.value === BALANCE_PAYMENT_FILTER[0].value),
    )
    dispatch(changeTypeStatisticsComponentsChart(item.value))
    dispatch(keepCurrentSelectedIndexes(currentSelectedIndexes))
    dispatch(keepListCheckbox(true))
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="top-info">
              <ul
                className={`list-check d-flex mt-0`}
                style={{ marginLeft: 5 }}
              >
                {BALANCE_PAYMENT_FILTER.map((item) => (
                  <li key={item.value} className="mb-0">
                    <label>
                      <input
                        type="radio"
                        className="radiobox radiobox2"
                        checked={item.value === currentType}
                        onChange={() => handleChangeFilter(item)}
                      />
                      <Span
                        style={{ fontSize: 12 }}
                        className={
                          item.value === currentType ? style.active : ''
                        }
                      >
                        <Translate value={item.title} />
                      </Span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
            {size.height && (
              <div
                className="row w-100 position-relative"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default PaymentBalance
