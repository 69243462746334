import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class UserBondCashFlowProxy extends ProxyBase {
  getListUserBondCashFlow(params) {
    return this.get('List', params)
  }
  checkNameProxy(params) {
    return this.get('CheckName', params)
  }
  addNewProxy(params) {
    return this.post('AddNew', params)
  }
  updateProxy(params) {
    return this.put('Update', params)
  }
  deleteRowProxy(params) {
    return this.delete('Delete', params)
  }
}

export default new UserBondCashFlowProxy(
  ServiceProxyConfig.Bond.UserBondCashFlow.ServiceUrl,
)
