export const SIMPLE_SEARCH_PARAMS = {
  page: 1,
  pageSize: 25,
}

export const DEFAULT_INDEX = {
  groupId: 11,
  groupName: 'VNINDEX',
  groupCode: 'VNINDEX',
  exchangeCode: 'HOSE',
}
