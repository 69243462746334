import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPProvinceService from '../../../../../../core/services/economy/gdp/GDPProvinceService'

export const getProvincesByGRDP = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelTop10Provinces/getProvincesByGRDP',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPProvinceService.getProvincesByGRDP(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
