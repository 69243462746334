import { keyBy } from '../../../../utils/Common'
import {
  getAccMonthAndYearKey,
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../common/helper'
import { DATA_TYPE_LIST, TIME_FILTER_TYPE } from './constants'

const FIRST_LEVEL = 1
const SECOND_LEVEL = 2

export const reOrderedNameData = (data, reOrderedIds) => {
  const getOverallProductData = data?.filter(
    (item) => item.level === FIRST_LEVEL,
  )

  const getCountries = data?.filter((item) => item.level === SECOND_LEVEL)

  const uniqueCountries = Object.values(
    getCountries.reduce((acc, curr) => {
      acc[curr.id + '|' + curr.name] = curr
      return acc
    }, {}),
  )

  const uniqueProducts = Object.values(
    getOverallProductData.reduce((acc, curr) => {
      acc[curr.id + '|' + curr.name] = curr
      return acc
    }, {}),
  )

  const getCountriesOfProduct = (productId) => {
    return uniqueCountries.filter((item) => item.parentId === productId)
  }

  const reOrderUniqueProducts = reOrderedIds
    ? reOrderedIds
        ?.filter((item) => typeof item === 'number')
        .map((item) => uniqueProducts?.find((prod) => prod.id === item))
    : uniqueProducts

  return reOrderUniqueProducts
    .map((item) => [item, ...getCountriesOfProduct(item.id)])
    .flat(1)
}

export const handleGeneralState = (state, data) => {
  state.listOrigParentCategory = data
  state.idsCategory = state.initialIds = data?.map((v) => v.id)
  state.data = data
  state.dataById = keyBy(data, 'id')

  state.isChangeLocale = false
  state.isChangeFilter = false

  state.levels = data.map((item) => ({
    id: item.id,
    parentId: item.parentId,
    level: item.level,
  }))

  state.levelCollapse = keyBy(
    data?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.isCollapse,
    })),
    'id',
  )

  const getGroupColumns = data.length
    ? data
        ?.map((item) => Object.keys(item))[0]
        .filter((item) => /\d/.test(item))
    : []

  const monthInColKey = (colKey) => {
    return Number(colKey.slice(0, 2)) < 10
      ? Number(colKey.slice(1, 2))
      : Number(colKey.slice(0, 2))
  }
  const yearInColKey = (colKey) => {
    return colKey.slice(3, 7)
  }

  const getFilterGroupColumns = () => {
    if (state.currentTypeFilter === TIME_FILTER_TYPE.YEARLY) {
      return getGroupColumns.map((item) =>
        getYearKey(Number(item), state.maxDate, state.locale),
      )
    } else if (state.currentTypeFilter === TIME_FILTER_TYPE.MONTHLY) {
      return reOrderByMonthAndYear(getGroupColumns)
    } else {
      return reOrderByMonthAndYear(getGroupColumns)?.map((col) =>
        getAccMonthAndYearKey(
          monthInColKey(col),
          yearInColKey(col),
          state.locale,
        ),
      )
    }
  }

  const filterGroupColumns = getFilterGroupColumns()
  const groupColumns = []
  filterGroupColumns?.forEach((item) => {
    groupColumns.push({
      key: getGroupColumns[filterGroupColumns.indexOf(item)],
      title: item,
    })
  })
  state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]

  state.isChangeFilter = false

  state.currentMinMonth = groupColumns[0]?.key.slice(0, 2)
  state.currentMinYear = groupColumns[0]?.key.slice(3, 7)
}

export const handleImportByProduct = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadData =
    action.payload?.data
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: item.vnTypeId,
        vnTypeId: item.vnTypeId,
        name: getNameById(payloadNameData, item.vnTypeId),
        year: item.realYear,
        month: item.realMonth,
        level: FIRST_LEVEL,
        isCollapse: true,
        value:
          state.typeData === DATA_TYPE_LIST.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueProduct = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: FIRST_LEVEL,
    isCollapse: true,
    vnTypeId: item.vnTypeId,
  }))

  state.payloadNameData = uniqueProduct
  state.payloadData = payloadData

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data
  state.listCategory = state.listOrigParentCategory.slice(0, 5)

  handleGeneralState(state, data)
  state.rowsCollapse = state.idsCategory
}

export const handleImportByProductChildren = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.locationId,
      name: item.locationName,
    })) || []

  const payloadData =
    action.payload?.data?.map((item) => ({
      id: `${item.locationId}_${item.vnTypeId}`,
      parentId: action.meta.arg.vnTypeIds,
      locationId: item.locationId,
      productId: item.vnTypeId,
      year: item.realYear,
      month: item.realMonth,
      level: SECOND_LEVEL,
      name: getNameById(payloadNameData, item.locationId),
      isCollapse: false,
      value:
        state.typeData === DATA_TYPE_LIST.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []

  const uniqueLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  )
    ?.filter((item) => item.locationId)
    .map((item) => ({
      id: item.id,
      name: item.name,
      level: SECOND_LEVEL,
      parentId: item.parentId,
      locationId: item.locationId,
      isCollapse: false,
    }))

  state.payloadNameData = state.sortType
    ? reOrderedNameData(
        [...state.payloadNameData, ...uniqueLocation],
        state.idsCategory,
      )
    : reOrderedNameData([...state.payloadNameData, ...uniqueLocation])

  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...state.payloadData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]
  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByProductAfterScroll = (state, action) => {
  state.isLoading = false

  const payloadData =
    action.payload
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: item.vnTypeId,
        vnTypeId: item.vnTypeId,
        productId: item.vnTypeId,
        year: item.realYear,
        month: item.realMonth,
        level: FIRST_LEVEL,
        isCollapse: true,
        value:
          state.typeData === DATA_TYPE_LIST.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...state.payloadData,
  ]

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data
  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByProductChildrenAfterScroll = (state, action) => {
  state.isLoading = false

  const payloadData =
    action.payload?.map((item) => ({
      id: `${item.locationId}_${item.vnTypeId}`,
      parentId: item.vnTypeId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      year: item.realYear,
      month: item.realMonth,
      level: SECOND_LEVEL,
      isCollapse: false,
      value:
        state.typeData === DATA_TYPE_LIST.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...state.payloadData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]

  state.listCategory = [...state.listCategory]
  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByProductAfterChangeFilter = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadChildrenNameData =
    action.payload?.childrenNameData.map((item) => ({
      id: item.locationId,
      name: item.locationName,
    })) || []

  const payloadData =
    action.payload?.data
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: item.vnTypeId,
        vnTypeId: item.vnTypeId,
        name: getNameById(payloadNameData, item.vnTypeId),
        year: item.realYear,
        month: item.realMonth,
        level: FIRST_LEVEL,
        isCollapse: true,
        value:
          state.typeData === DATA_TYPE_LIST.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []
  const uniqueProduct = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: FIRST_LEVEL,
    isCollapse: true,
    vnTypeId: item.vnTypeId,
  }))

  const payloadChildrenData =
    action.payload?.childrenData.map((item) => ({
      id: `${item.locationId}_${item.vnTypeId}`,
      parentId: item.vnTypeId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      year: item.realYear,
      month: item.realMonth,
      level: SECOND_LEVEL,
      name: getNameById(payloadChildrenNameData, item.locationId),
      isCollapse: false,
      value:
        state.typeData === DATA_TYPE_LIST.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  const uniqueLocation = Object.values(
    payloadChildrenData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  )
    ?.filter((item) => item.locationId)
    .map((item) => ({
      id: item.id,
      name: item.name,
      level: SECOND_LEVEL,
      parentId: item.parentId,
      locationId: item.locationId,
      isCollapse: false,
    }))

  state.payloadNameData = reOrderedNameData([
    ...uniqueLocation,
    ...uniqueProduct,
  ])
  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...payloadChildrenData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]

  handleGeneralState(state, data)
  state.listCategory = [...state.listCategory].map((item) => ({
    ...item,
    name: getNameById(uniqueProduct, item.id),
  }))
  state.rowsCollapse = [...state.rowsCollapse]
}

export const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}

export const handleDuplicates = (payload, arrKey) => {
  if (!payload.length) {
    return []
  }

  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        arrKey.every((key) => item[key] === element[key]),
      )
    )
  })
}

const fullDataWithVal = (
  dataContainVal,
  currentTypeFilter,
  dataWithoutVal,
  typeData,
) => {
  const getDataKeyFromRange =
    currentTypeFilter === TIME_FILTER_TYPE.YEARLY
      ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
      : getMonthAndYearInDataRange(dataContainVal).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )

  const getDataValueFromRange =
    currentTypeFilter === TIME_FILTER_TYPE.YEARLY
      ? getYearInDataRange(dataContainVal).map((item) => [
          ...getDataByYear(dataContainVal, item.year),
        ])
      : getMonthAndYearInDataRange(dataContainVal).map((item) => [
          ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
        ])

  const getCorrespondingDataWithKey = (data, item) => {
    return data[data.indexOf(data.find((i) => i.id === item.id))]?.value
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
          typeData,
        )),
    )

    return dataKeyAndValRange
  }

  return dataWithoutVal.map((item) => ({
    ...item,
    type: typeData,
    ...getKeyAndValRange(item),
  }))
}

const getNameById = (data, id) => {
  return data?.find((item) => item.id === id)?.name || ''
}
