import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { keyTableSectorsReport } from '../constants'
import { selectListSector } from '../pricePerformance/tableSector/store/slice'
import {
  clearDataSectorsReportTable,
  selectLoadingSectorsReport,
  selectReCalcWidthsTable,
  selectSectorsReportIds,
  selectSectorsReportValue,
} from './store/slice'
import { getSectorsReport } from './store/thunk'
import TdDownload from './TdDownload'

const SectorsReport = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoadingSectorsReport)
  const ids = useSelector(selectSectorsReportIds)
  const reCalcWidths = useSelector(selectReCalcWidthsTable)
  const listSector = useSelector(selectListSector)

  useEffect(() => {
    if (listSector.length) {
      dispatch(
        getSectorsReport({
          ICBIds: listSector.map((sector) => sector.icbId),
        }),
      )
    } else {
      dispatch(clearDataSectorsReportTable())
    }
  }, [JSON.stringify(listSector.map((item) => item.icbId)), locale])

  return (
    <div className="h-100">
      <Table
        ids={ids}
        getDataFromRedux={selectSectorsReportValue}
        isLoading={isLoading}
        columnDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        reCalcWidths={reCalcWidths}
        schema={Object.keys(keyTableSectorsReport).map((key, index) => {
          const title = `sector.sectorStatistics.overview.${key}`
          const colId = keyTableSectorsReport[key]
          let result = {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
          }
          if (index === 0) {
            result = {
              ...result,
              render: (val) => <DateTimeSpan date={val} />,
            }
          }
          if (index === 1 || index === 2) {
            result = {
              ...result,
              canCustomTd: true,
              render: (val) => (
                <td style={{ width: '20%', maxWidth: 40 }}>
                  <TextEllipsis isI18n={false} text={val} />
                </td>
              ),
            }
          }
          if (index === 3) {
            result = {
              ...result,
              canCustomTd: true,
              render: (val) => (
                <td style={{ width: '60%', maxWidth: 60 }}>
                  <TextEllipsis isI18n={false} text={val} />
                </td>
              ),
            }
          }
          if (index === 4) {
            result = {
              ...result,
              renderTh: () => <></>,
              render: (val) => {
                return <TdDownload val={val} />
              },
            }
          }
          return result
        })}
      />
    </div>
  )
}

export default SectorsReport
