import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { ALL_TYPE } from '../../../../common/news/constants'
import { getFilterDate } from '../../../../common/news/helper'
import { Item } from '../../../../common/news/item'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import {
  resetAnalytic,
  selectAnalyticCategories,
  selectAnalyticFilterData,
  selectAnalyticSources,
  selectNewsAnalyticLoading,
  selectNewsAnalytics,
  selectNewsCategoriesAndSourcesAnalyticLoading,
} from '../store/slice'
import { getNewsAnalytic } from '../store/thunk'

export const ListItem = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const newsAnalytics = useSelector(selectNewsAnalytics)
  const isLoading = useSelector(selectNewsAnalyticLoading)
  const isCategoriesAndSourcesAnalyticLoading = useSelector(
    selectNewsCategoriesAndSourcesAnalyticLoading,
  )
  const filterData = useSelector(selectAnalyticFilterData)
  const sources = useSelector(selectAnalyticSources)
  const categories = useSelector(selectAnalyticCategories)
  const basicInfo = useSelector(selectBasicInfo)
  const timeZone = UseTimeZone()

  useEffect(() => {
    if (
      sources.length > 0 &&
      categories.length > 0 &&
      basicInfo.organizationId
    ) {
      const dates = getFilterDate(
        filterData.time,
        filterData.startDate,
        filterData.endDate,
      )
      if (dates.startDate && !dates.endDate) return

      dispatch(
        getNewsAnalytic({
          OrganizationId: basicInfo.organizationId,
          Page: 1,
          PageSize: 10,
          NewsCategoryIds:
            filterData.category === ALL_TYPE ? null : filterData.category,
          NewsSourceIds:
            filterData.source === ALL_TYPE ? null : filterData.source,
          StartDate: dates.startDate
            ? getISOStartOrEndOfDay(dates.startDate, timeZone, true)
            : null,
          EndDate: dates.endDate
            ? getISOStartOrEndOfDay(dates.endDate, timeZone, false)
            : null,
          Term: filterData.search || null,
        }),
      )
    }
  }, [filterData, sources, categories, basicInfo.organizationId, timeZone])

  useEffect(() => {
    return () => {
      dispatch(resetAnalytic())
    }
  }, [])

  if (isLoading || isCategoriesAndSourcesAnalyticLoading) {
    return <Loading />
  }

  if (
    sources.length === 0 ||
    categories.length === 0 ||
    newsAnalytics.length === 0
  ) {
    return <NoData />
  }

  const onAction = (newsId) => () => {
    navigate(
      FEATURES.corporate.components['news-events'].components['news-detail']
        .route,
      basicInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <ScrollComponent>
      {newsAnalytics.map((item, index) => {
        return (
          <div className="mb-20" key={index}>
            <Item
              item={item}
              isSpaceBetween
              lineClamp={2}
              onAction={onAction(item.newsId)}
            />
          </div>
        )
      })}
    </ScrollComponent>
  )
}
