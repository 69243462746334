import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { VIEW_BY } from './constants'

const initialState = {
  exchangeCode: VIEW_BY.LISTED,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview',
  initialState,
  reducers: {
    changeExchangeCode: (state, action) => {
      state.exchangeCode = action.payload
    },
  },
})

export const selectExchangeCode = (state) => state[slice.name].exchangeCode

export const { changeExchangeCode } = slice.actions

register(slice.name, slice.reducer)
