import { useSelector } from 'react-redux'
import { Span } from '../../../../../../common/html/Span'
import { Table as TableContent } from '../../../../../../common/table'
import { formatVal } from '../../../../../../utils/Value'
import { GROUP_COLUMNS_LENDING } from '../../constants'
import {
  keys,
  selectDataTableByIdLendingCom,
  selectLendingComData,
  selectLoading,
} from '../../store/slice'
import TableHeader from './TableHeader'

const TableLendingCompany = () => {
  const isLoading = useSelector(selectLoading(keys.LENDING_COM))
  const { ids } = useSelector(selectLendingComData)

  return (
    <>
      <TableContent
        isLoading={isLoading}
        ids={ids}
        getDataFromRedux={selectDataTableByIdLendingCom}
        renderHeader={(stateSort, sortColumn) => (
          <TableHeader tableHeaderColAttr={GROUP_COLUMNS_LENDING} />
        )}
        schema={GROUP_COLUMNS_LENDING.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          switch (index) {
            case 0:
            case 1:
              return {
                ...result,
                tdStyle: {
                  minWidth: 120,
                  maxWidth: 120,
                  boxSizing: 'border-box',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{value}</Span>
                  </td>
                ),
              }
            case 2:
              return {
                ...result,
                tdStyle: {
                  minWidth: 120,
                  boxSizing: 'border-box',
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(value)}</Span>
                  </td>
                ),
              }

            default:
              return {
                ...result,
                tdStyle: {
                  minWidth: 170,
                  boxSizing: 'border-box',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{value}</Span>
                  </td>
                ),
              }
          }
        })}
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
      />
    </>
  )
}

export default TableLendingCompany
