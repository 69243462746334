import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../common/panel'
import { PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import { FinancialMetric } from './financialMetric'
import { selectTimeFilter } from './financialMetric/store/slice'
import { downloadFinancialMetrics } from './financialMetric/store/thunk'
import { selectBasicInfoOverview } from './store/slice'

export const PanelFinancialTable = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const basicInfo = useSelector(selectBasicInfoOverview)
  const timeFilter = useSelector(selectTimeFilter)

  return (
    <Panel
      title={'corporate.overview.financial.TITLE_PANEL'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadFinancialMetrics({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: timeFilter.value,
        })
      }
    >
      <FinancialMetric
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
      />
    </Panel>
  )
}

PanelFinancialTable.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
