import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { formatVal, valDivBillion } from '../../../../../../utils/Value'
import DeleteOrganization from '../DeleteOrganization'
import { COLUMNS_KEYS, DATA_TYPE } from '../constant'
import TdCellTornado from './TdCellTornado'
import styleTable from './index.module.css'

const MIN_WIDTH = 160
// const MAX_WIDTH = 250

export const getSchema = ({ currentOrgId, isShowChart, dataType }) => {
  return Object.keys(COLUMNS_KEYS).map((key, index) => {
    const title = `bond.comparable.comparableAnalysis.table${dataType}.${key}`
    const colId = COLUMNS_KEYS[key]

    return {
      colId,
      isI18n: false,
      thStyle: {
        verticalAlign: 'baseline',
      },
      renderTh: () => {
        let textAlign
        switch (colId) {
          case COLUMNS_KEYS.companyName:
            textAlign = 'left'
            break
          case COLUMNS_KEYS.bS64:
            textAlign = isShowChart ? 'left' : 'right'
            break
          default:
            textAlign = isShowChart ? 'center' : 'right'
        }

        if (colId === COLUMNS_KEYS.bS64) {
          return (
            <>
              <div style={{ textAlign }} className={styleTable.thCell}>
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate dangerousHTML value={title} />
                </Span>
              </div>
              <div style={{ textAlign }} className={styleTable.additionText}>
                <Span style={{ fontSize: 12 }}>
                  <Translate
                    dangerousHTML
                    value={'bond.comparable.common.billMoney'}
                  />
                </Span>
              </div>
            </>
          )
        }

        if (colId === COLUMNS_KEYS.ticker) {
          return (
            <div
              style={{
                textAlign: 'left',
                // maxWidth: MAX_WIDTH,
                whiteSpace: 'normal',
                minWidth: 100,
              }}
              className={styleTable.thCell}
            >
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <Translate dangerousHTML value={title} />
              </Span>
            </div>
          )
        }

        return (
          <div
            style={{
              textAlign,
              // maxWidth: MAX_WIDTH,
              whiteSpace: 'normal',
            }}
            className={styleTable.thCell}
          >
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <Translate dangerousHTML value={title} />
            </Span>
          </div>
        )
      },
      tdStyle: {
        textAlign:
          colId === COLUMNS_KEYS.delete
            ? 'center'
            : [COLUMNS_KEYS.ticker, COLUMNS_KEYS.companyName].includes(colId)
            ? 'left'
            : 'right',
        minWidth:
          colId !== COLUMNS_KEYS.delete && colId !== COLUMNS_KEYS.ticker
            ? MIN_WIDTH
            : 0,
      },
      render: (val, rowId) => {
        switch (colId) {
          case COLUMNS_KEYS.delete:
            return rowId !== 'total' ? (
              <DeleteOrganization organizationId={rowId} />
            ) : (
              <div className={styleTable.borderTopLight} />
            )
          case COLUMNS_KEYS.ticker:
            return rowId !== 'total' ? (
              <Span
                className={
                  rowId === currentOrgId ? styleTable.firstRowColor : ''
                }
                style={{ fontSize: 12, fontWeight: 340 }}
              >
                {val}
              </Span>
            ) : (
              <div className={styleTable.borderTopLight}>
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate
                    value={`bond.comparable.comparableAnalysis.table${dataType}.Medium`}
                  />
                </Span>
              </div>
            )
          case COLUMNS_KEYS.companyName:
            return rowId !== 'total' ? (
              <Span
                className={
                  rowId === currentOrgId ? styleTable.firstRowColor : ''
                }
                style={{ fontSize: 12, fontWeight: 340 }}
              >
                <TextEllipsis isI18n={false} text={val} />
              </Span>
            ) : (
              <div className={styleTable.borderTopLight} />
            )
          case COLUMNS_KEYS.bS64:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={false}
                    transformValue={(val) => valDivBillion(val)}
                    isTwoSide={false}
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {formatVal(valDivBillion(val))}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      transformValue={(val) => valDivBillion(val)}
                      isTwoSide={false}
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {formatVal(valDivBillion(val))}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0027:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.STOCK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.STOCK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.STOCK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.STOCK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0037:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.STOCK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.STOCK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.STOCK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.STOCK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0046:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.BANK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.BANK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.BANK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.BANK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0075:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={
                      dataType === DATA_TYPE.STOCK ||
                      dataType === DATA_TYPE.BANK
                        ? true
                        : false
                    }
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.STOCK || dataType === DATA_TYPE.BANK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={
                        dataType === DATA_TYPE.STOCK ||
                        dataType === DATA_TYPE.BANK
                          ? true
                          : false
                      }
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.STOCK ||
                      dataType === DATA_TYPE.BANK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0093:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.BANK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.BANK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.BANK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.BANK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0328:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.BANK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.BANK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.BANK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.BANK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0372:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )
          case COLUMNS_KEYS.rT0378:
            return rowId !== 'total' ? (
              <>
                {isShowChart ? (
                  <TdCellTornado
                    value={val}
                    colId={colId}
                    isPercent={dataType === DATA_TYPE.BANK ? true : false}
                    isFormatPositiveValue
                  />
                ) : (
                  <Span
                    className={
                      rowId === currentOrgId ? styleTable.firstRowColor : ''
                    }
                    style={{ fontSize: 12, fontWeight: 340 }}
                  >
                    {dataType === DATA_TYPE.BANK
                      ? formatVal(val * 100)
                      : formatVal(val)}
                  </Span>
                )}
              </>
            ) : (
              <div className={styleTable.borderTopLight}>
                <>
                  {isShowChart ? (
                    <TdCellTornado
                      value={val}
                      colId={colId}
                      isPercent={dataType === DATA_TYPE.BANK ? true : false}
                      isFormatPositiveValue
                    />
                  ) : (
                    <Span
                      className={
                        rowId === currentOrgId ? styleTable.firstRowColor : ''
                      }
                      style={{ fontSize: 12, fontWeight: 600 }}
                    >
                      {dataType === DATA_TYPE.BANK
                        ? formatVal(val * 100)
                        : formatVal(val)}
                    </Span>
                  )}
                </>
              </div>
            )

          default:
            return val
        }
      },
    }
  })
}
