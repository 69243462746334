import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemRadio from './common/ItemRadio'
import RadioCalendar from './common/RadioCalendar'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import { deepCopy } from './helper'
import style from './index.module.css'

const IndicatorShareholders = ({
  handleSubmit,
  handleCancel,
  title,
  minDate,
  maxDate,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    if (detail.mostRecent) {
      delete detail.date
    }
    handleSubmit({ ...data, detail })
  }
  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`d-flex ali-center ${style.isContent}`}>
            <ItemRadio
              defaultCheck={true}
              keyRadio="mostRecent"
              valueRadio={true}
              textRadio={I18n.t('common.popup.MOST_RECENT_2')}
            />
            <ItemRadio
              keyRadio="mostRecent"
              valueRadio={false}
              textRadio={I18n.t('common.popup.DATE')}
              renderContent={(isDisabled) => (
                <RadioCalendar
                  keySelect="date"
                  disabled={isDisabled}
                  defaultValue={maxDate}
                  maxDate={maxDate}
                  minDate={minDate}
                />
              )}
            />
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              submit={convertData}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

IndicatorShareholders.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(IndicatorShareholders)
