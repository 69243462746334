import CoveredWarrantProxy from '../../proxies/market/CoveredWarrantProxy'
import { ServiceBase } from '../ServiceBase'

class CoveredWarrantService extends ServiceBase {
  getAll(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getAll',
      params,
    )(() => CoveredWarrantProxy.getAll(params))
  }

  getCoveredWarrantSummary(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getCoveredWarrantSummary',
      params,
    )(() => CoveredWarrantProxy.getCoveredWarrantSummary(params))
  }

  getCoveredWarrantBreakEvenPoint(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getCoveredWarrantBreakEvenPoint',
      params,
    )(() => CoveredWarrantProxy.getCoveredWarrantBreakEvenPoint(params))
  }

  getCoveredWarrantPriceDepth(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getCoveredWarrantPriceDepth',
      params,
    )(() => CoveredWarrantProxy.getCoveredWarrantPriceDepth(params))
  }

  getCoveredWarrantTimeAndSales(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getCoveredWarrantTimeAndSales',
      params,
    )(() => CoveredWarrantProxy.getCoveredWarrantTimeAndSales(params))
  }

  getCoveredWarrantTimeAndSalesChart(params) {
    return this.applyMemoryCache(
      'CoveredWarrantService.getCoveredWarrantTimeAndSalesChart',
      params,
    )(() => CoveredWarrantProxy.getCoveredWarrantTimeAndSalesChart(params))
  }

  downloadTimeAndSales(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadTimeAndSales(params),
    )
  }

  getOverviewStatistics(params) {
    return this.getData(() => CoveredWarrantProxy.getOverviewStatistics(params))
  }

  getNetValueByInvestorChartOverview(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getNetValueByInvestorChartOverview(params),
    )
  }

  getNetTradingValueChartOverview(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getNetTradingValueChartOverview(params),
    )
  }

  getIssuerOrganizationIds(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getIssuerOrganizationIds(params),
    )
  }

  getUnderlyingIds(params) {
    return this.getData(() => CoveredWarrantProxy.getUnderlyingIds(params))
  }

  downloadOverviewStatistics(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadOverviewStatistics(params),
    )
  }

  getHistoricalPriceOverview(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getHistoricalPriceOverview(params),
    )
  }

  getHistoricalPriceDetail(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getHistoricalPriceDetail(params),
    )
  }

  getHistoricalPriceTicker(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getHistoricalPriceTicker(params),
    )
  }

  getNetValueByInvestorChartTicker(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getNetValueByInvestorChartTicker(params),
    )
  }

  getNetTradingValueChartTicker(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getNetTradingValueChartTicker(params),
    )
  }

  getCoverWarrants(params) {
    return this.getData(() => CoveredWarrantProxy.getCoverWarrants(params))
  }

  downloadHistoricalOverview(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadHistoricalOverview(params),
    )
  }

  downloadHistoricalDetail(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadHistoricalDetail(params),
    )
  }

  downloadHistoricalTicker(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadHistoricalTicker(params),
    )
  }

  getForeignOverview(params) {
    return this.getData(() => CoveredWarrantProxy.getForeignOverview(params))
  }

  getForeignDetail(params) {
    return this.getData(() => CoveredWarrantProxy.getForeignDetail(params))
  }

  getForeignTicker(params) {
    return this.getData(() => CoveredWarrantProxy.getForeignTicker(params))
  }

  downloadForeignOverview(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadForeignOverview(params),
    )
  }

  downloadForeignDetail(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadForeignDetail(params),
    )
  }

  downloadForeignTicker(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadForeignTicker(params),
    )
  }

  getOrderedStatisticsOverview(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getOrderedStatisticsOverview(params),
    )
  }

  getOrderedStatisticsDetail(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getOrderedStatisticsDetail(params),
    )
  }

  getOrderedStatisticsTicker(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getOrderedStatisticsTicker(params),
    )
  }

  downloadOrderedStatisticsOverview(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadOrderedStatisticsOverview(params),
    )
  }

  downloadOrderedStatisticsDetail(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadOrderedStatisticsDetail(params),
    )
  }

  downloadOrderedStatisticsTicker(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadOrderedStatisticsTicker(params),
    )
  }

  getProprietaryOverview(params) {
    return this.getData(() =>
      CoveredWarrantProxy.getProprietaryOverview(params),
    )
  }

  getProprietaryDetail(params) {
    return this.getData(() => CoveredWarrantProxy.getProprietaryDetail(params))
  }

  getProprietaryTicker(params) {
    return this.getData(() => CoveredWarrantProxy.getProprietaryTicker(params))
  }

  downloadProprietaryOverview(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadProprietaryOverview(params),
    )
  }

  downloadProprietaryDetail(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadProprietaryDetail(params),
    )
  }

  downloadProprietaryTicker(params) {
    return this.getFileDownload(() =>
      CoveredWarrantProxy.downloadProprietaryTicker(params),
    )
  }
}

export default new CoveredWarrantService()
