import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { convertArr } from '../../helper'
import { VALUE_BREAKDOWN_FILTER } from '../constants'
import {
  getNetTradingValueChartOverview,
  getNetTradingValueChartTicker,
} from './thunk'

const slice = createSlice({
  name: 'market/coveredWarrant/statistics/valueBreakdown',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    currentComponentFilter: VALUE_BREAKDOWN_FILTER[0].value,
  },
  reducers: {
    setLoadingChart: (state, action) => {
      state.isLoading = action.payload
    },
    changeCurrentComponentFilter: (state, action) => {
      if (state.currentComponentFilter !== action.payload) {
        state.isLoading = true
      }
      state.currentComponentFilter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetTradingValueChartOverview.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getNetTradingValueChartOverview.fulfilled,
      (state, action) => {
        state.isLoading = false
        const payloadData = action.payload || []
        state.origData = payloadData
        state.data = convertArr(payloadData)
      },
    )
    builder.addCase(
      getNetTradingValueChartOverview.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )

    builder.addCase(getNetTradingValueChartTicker.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getNetTradingValueChartTicker.fulfilled,
      (state, action) => {
        state.isLoading = false
        const payloadData = action.payload || []
        state.origData = payloadData
        state.data = convertArr(payloadData)
      },
    )
    builder.addCase(getNetTradingValueChartTicker.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectCurrentComponentFilter = (state) =>
  state[slice.name].currentComponentFilter

export const { setLoadingChart, changeCurrentComponentFilter } = slice.actions

register(slice.name, slice.reducer)
