import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { listFilter } from './constants'
import {
  changeFilterName,
  changeIsInstitution,
  selectFilter,
} from './store/slice'
import style from './style.module.css'

const NetValueBreakdownDropdown = () => {
  const dispatch = useDispatch()

  const stateSearchTypes = useSelector(selectFilter)

  const getCurrentFilterName = (value) => {
    return listFilter
      ?.filter((item) => item.value === value)
      ?.map((item) => item.name)
  }

  const setStateType = (data) => {
    dispatch(changeIsInstitution(data))
    dispatch(changeFilterName(...getCurrentFilterName(data)))
  }

  return (
    <div className={`${style.inputDropdown}`}>
      <SelectCustom
        value={stateSearchTypes}
        isI18n={true}
        selectData={listFilter.map((item) => ({
          name: item.name,
          value: item.value,
        }))}
        handleChange={setStateType}
      />
    </div>
  )
}

export default NetValueBreakdownDropdown
