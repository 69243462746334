import { PropTypes } from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Span } from '../../../common/html/Span'
import { ScrollComponent } from '../../../common/ScrollComponent'
import TextEllipsis from '../../../common/textEllipsis'
import style from '../style.module.css'

const ICON_WIDTH = 40
const NUMBER_ICON_MENU_FOOTER_TAB = 4

const Tabs = ({ menuTabRef, activeTab, data, onChangeTab }) => {
  const navRef = useRef()

  const [navWidth, setNavWidth] = useState(0)

  const getMaxNavWidth = useMemo(() => {
    return (
      menuTabRef.current?.offsetWidth ||
      0 - ICON_WIDTH * NUMBER_ICON_MENU_FOOTER_TAB
    )
  }, [menuTabRef?.current?.offsetWidth])

  const getWidth = useMemo(() => {
    if (!menuTabRef?.current || !navRef.current) return
    return navWidth < getMaxNavWidth ? navWidth : getMaxNavWidth
  }, [navWidth, getMaxNavWidth])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setNavWidth(navRef.current?.offsetWidth || 0)
      clearTimeout(timeout)
    }, 100)
    return () => clearTimeout(timeout)
  }, [data])

  useEffect(() => {
    if (
      data.find(({ value }) => +value === activeTab) &&
      getMaxNavWidth <= getWidth
    ) {
      const el = document.getElementById('chartTab' + activeTab)
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    }
  }, [activeTab, data, getMaxNavWidth, getWidth])

  return (
    <div className="h-100" style={{ maxWidth: '100%', width: getWidth }}>
      <ScrollComponent horizontalTrackWidth={0} horizontalTrackWidthHover={0}>
        <div className="d-flex ali-center h-100">
          <nav ref={navRef} className="d-flex" style={{ width: 'fit-content' }}>
            {data.map(({ name, value, chartID }, index) => (
              <a
                id={'chartTab' + value}
                key={index}
                className={[
                  style.itemTab,
                  activeTab === +value ? style.activeTab : '',
                ].join(' ')}
                onClick={() => onChangeTab(value, chartID)}
              >
                <Span>
                  <TextEllipsis
                    appendStyle={{ maxWidth: 200 }}
                    text={name}
                    isI18n={false}
                  />
                </Span>
              </a>
            ))}
          </nav>
        </div>
      </ScrollComponent>
    </div>
  )
}

export default Tabs

Tabs.propTypes = {
  activeTab: PropTypes.any.isRequired,
  data: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
}

Tabs.defaultProps = {
  onChangeTab: () => {},
}
