import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceDataService from '../../../../core/services/common/PriceDataService'
import SectorInDepthService from '../../../../core/services/market/SectorInDepthService'
import { handleExport } from '../../../utils/Export'

export const idVnIndex = 11
export const smallMidLargeCap = [8, 12, 14]

export const getSectorInDepthThunk = createAsyncThunk(
  'market/sectorInDepth/GET_SECTOR_IN_DEPTH',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SectorInDepthService.getSectorInDepth(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getLastestSectorThunk = createAsyncThunk(
  'market/sectorInDepth/GET_LASTEST_SECTOR',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await PriceDataService.getLatestSectors(data)
      if (response.success) {
        if (data.GroupId === idVnIndex) {
          dispatch(
            getLastestIndexThunk({
              arrGroupId: smallMidLargeCap,
              groupId: data.GroupId,
            }),
          )
        }
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getLastestPriceThunk = createAsyncThunk(
  'market/sectorInDepth/GET_LASTEST_PRICE',
  async (data, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice({
      GroupType: 'Sector',
      Ids: data.arrIcbId.join(','),
      GroupId: data.groupId,
    })
    if (response.success) {
      return response.data.map((item) => {
        return { ...item.priceInfo }
      })
    }
    return rejectWithValue(response.errors)
  },
)

export const getLastestIndexThunk = createAsyncThunk(
  'market/sectorInDepth/GET_LASTEST_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice({
      GroupType: 'Index',
      Ids: data.arrGroupId.join(','),
      GroupId: data.groupId,
    })
    if (response.success) {
      return response.data.map((item, index) => {
        return { ...item.priceInfo, groupId: data.arrGroupId[index] }
      })
    }
    return rejectWithValue(response.errors)
  },
)

export const getTotalValueBySectorThunk = createAsyncThunk(
  'market/sectorInDepth/GET_TOTAL_VALUE_BY_SECTOR',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SectorInDepthService.getTotalValueBySector(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(false)
      }
      return rejectWithValue(true)
    }
  },
)

export const getTopValueThunk = createAsyncThunk(
  'market/sectorInDepth/GET_TOP_VALUE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SectorInDepthService.getTopValue(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadSectorInDepthThunk = createAsyncThunk(
  'market/sectorInDepth/DOWNLOAD_SECTOR_IN_DEPTH',
  async (data) => {
    const response = await SectorInDepthService.downloadSectorInDepth(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadTopValueThunk = createAsyncThunk(
  'market/sectorInDepth/DOWNLOAD_TOP_VALUE',
  async (data) => {
    const response = await SectorInDepthService.downloadTopValue(data)
    handleExport(response.data, response.filename)
  },
)
