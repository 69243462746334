import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../../constants/Common'
import EventEmitter from '../../../utils/EventEmitter'
import { isInValidValue } from '../../../utils/Value'
import useWindowDevicePixelRatio from '../../hooks/useWindowDevicePixelRatio'
import { Span } from '../../html/Span'
import Sort from './Sort'
import { SORT_TYPES } from './constant'
import style from './index.module.css'
import { changeSort, selectSort } from './store/slice'
import withWrapperCell from './withWrapperCell'

export const RESET_DEFAULT_SORT = 'RESET_DEFAULT_SORT'
export const MOUSE_OUT_TH = 'MOUSE_OUT_TH'

let dragColumnIndex = null
let tableDragClone = null
let isDraging = false

const ThCell = withWrapperCell(
  ({
    index,
    thCustomData,
    selectColumnId,
    selectColumnCell,
    sortColumn,
    swapColumn,
    renderTh,
    tableThTop,
    isSwap,
    isSort,
    isThCustomData,
    customClassName,
    isCustormHeader,
    widthColumn,
  }) => {
    const dispatch = useDispatch()
    const { formatWithZoom } = useWindowDevicePixelRatio()

    const id = useSelector(selectColumnId(index))
    let column = useSelector(selectColumnCell(id))
    const typeSort = useSelector(selectSort(id)) || SORT_TYPES.DEFAULT

    const thRef = useRef()
    const timeout = useRef()

    const [isHover, setIsHover] = useState(false)
    const [isDragOver, setIsDragOver] = useState(false)
    const [thTop, setThTop] = useState(0)

    if (isThCustomData) {
      if (thCustomData) column = thCustomData
      else column = undefined
    }

    const getThId = () => {
      if (isThCustomData && thCustomData) {
        return thCustomData.colId
      }
      return id
    }

    useEffect(() => {
      let interval
      if (tableThTop) {
        const thIds = isThCustomData
          ? tableThTop[thCustomData?.colId]
          : tableThTop[id]

        if (!thIds) return

        interval = setInterval(() => {
          const els = []

          thIds.forEach((id) => {
            const el = document.getElementById(id)
            if (el) els.push(el)
          })

          if (els.length === thIds.length) {
            const thTop = els
              .map((el) => el.offsetHeight)
              .reduce((total, num) => total + num, 0)
            setThTop(thTop)
            clearInterval(interval)
          }
        }, 1)
      }

      return () => clearInterval(interval)
    }, [tableThTop, thCustomData, id])

    useEffect(() => {
      EventEmitter.subscribe(MOUSE_OUT_TH, mouseOut)
      return () => EventEmitter.unsubscribe(MOUSE_OUT_TH, mouseOut)
    }, [])

    const sort = () => {
      if (!isSort) return
      const keySortType = Object.keys(SORT_TYPES)
      const newIndexKey =
        (keySortType.findIndex((key) => SORT_TYPES[key] === typeSort) + 1) %
        keySortType.length
      dispatch(changeSort({ [id]: SORT_TYPES[keySortType[newIndexKey]] }))
      sortColumn(id, SORT_TYPES[keySortType[newIndexKey]])
    }

    const onDragStart = (e) => {
      e.dataTransfer.setData('text', '')
      setIsHover(false)
      isDraging = true
      const thClone = e.target.firstChild.cloneNode(true)
      tableDragClone = document.createElement('div')
      tableDragClone.innerHTML = thClone.outerHTML
      e.dataTransfer.setDragImage(
        tableDragClone,
        tableDragClone.offsetWidth / 2,
        20,
      )
      document.body.appendChild(tableDragClone)

      dragColumnIndex = index
    }

    const onDragOver = (e) => {
      e.preventDefault()
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
      if (!isDragOver) {
        setIsDragOver(true)
      }
    }

    const onDragLeave = (e) => {
      e.preventDefault()
      if (isDragOver) {
        timeout.current = setTimeout(() => setIsDragOver(false), 150)
      }
    }

    const onDragEnd = () => {
      dragColumnIndex = null
      document.body.removeChild(tableDragClone)
      tableDragClone = null
      setIsDragOver(false)
      isDraging = false
    }

    const onDrop = (e) => {
      e.preventDefault()
      if (isInValidValue(dragColumnIndex) || index === dragColumnIndex) {
        return
      }
      swapColumn(dragColumnIndex, index)
      dragColumnIndex = null
      setIsDragOver(false)
      isDraging = false
    }

    const mouseOver = () => {
      if (!isDraging) {
        setIsHover(true)
      }
    }

    const mouseOut = () => setIsHover(false)
    return (
      <>
        {column && (
          <th
            id={getThId()}
            ref={thRef}
            onClick={sort}
            style={{
              zIndex: Z_INDEX.STICKY_THEAD,
              top: thTop,
              ...column?.style,
            }}
            className={`${style.thSticky} ${
              !isCustormHeader && style.thIndicator
            } ${customClassName.thSticky || ''}`}
            rowSpan={column.rowSpan}
            colSpan={column.colSpan}
            draggable={isSwap}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onDragEnd={onDragEnd}
            onMouseEnter={mouseOver}
            onMouseLeave={mouseOut}
          >
            <div
              className={`h-100 position-relative ${style.textLeft} ${style.divTh}`}
              style={{ ...column?.appendStyleTh }}
            >
              {isSwap && isHover && (
                <div
                  style={{
                    width: widthColumn,
                    height:
                      formatWithZoom(
                        thRef.current?.getBoundingClientRect().height,
                      ) + 'px',
                  }}
                  className={
                    isCustormHeader
                      ? style.indicatorHoverCustorm
                      : style.indicatorHover
                  }
                />
              )}
              {isDragOver && (
                <div
                  style={{
                    height:
                      formatWithZoom(
                        thRef.current?.getBoundingClientRect().height - 1,
                      ) + 'px',
                  }}
                  className={
                    isCustormHeader
                      ? style.indicatorDragOverCustom
                      : style.indicatorDragOver
                  }
                />
              )}
              {renderTh ? (
                renderTh(column?.title, id, column)
              ) : (
                <Span style={{ fontSize: 10 }}>{column?.title}</Span>
              )}
            </div>
            <Sort type={typeSort} />
          </th>
        )}
      </>
    )
  },
)

const ThCellEmpty = ({ rowIndex, colIndex, tableThRowSpan }) => {
  const [thTop, setThTop] = useState(0)

  useEffect(() => {
    if (rowIndex !== 0) {
      const rowThEls = []
      ;[...new Array(rowIndex)].forEach((_, index) => {
        const emptyThNodes = []
        const thNodes = document.querySelectorAll(
          `${'#rowTh' + index} .${style.thSticky}`,
        )

        for (let i = 0; i < thNodes.length; i++) {
          if (!thNodes[i].classList.contains(style.columnThSticky)) {
            emptyThNodes.push(thNodes[i])
          }
        }

        if (emptyThNodes[colIndex]) {
          rowThEls.push(emptyThNodes[colIndex])
        }
      })

      if (rowThEls.length) {
        const thTop = rowThEls
          .map((el) => el?.offsetHeight || 0)
          .reduce((total, num) => total + num, 0)
        setThTop(thTop)
      }
    }
  }, [])

  const getThTopByThIds = () => {
    if (rowIndex !== 0) {
      const thIds = []
      ;[...new Array(rowIndex)].forEach(
        (_, index) =>
          tableThRowSpan[index][0] &&
          thIds.push(tableThRowSpan[index][0].colId),
      )

      if (!thIds.length) return 0

      return thIds
        .map((thId) => {
          const el = document.getElementById(thId)
          return el?.offsetHeight || 0
        })
        .reduce((total, num) => total + num, 0)
    }

    return 0
  }

  return (
    <th
      style={{ top: getThTopByThIds() || thTop }}
      className={style.thSticky}
    />
  )
}

const ThCellInfo = ({
  title,
  sortColumn,
  idColumn,
  renderTh,
  disableSort,
  appendStyleTh,
  rowSpan,
  colSpan,
  isSticky,
  isSort,
  customClassName,
}) => {
  const dispatch = useDispatch()

  const typeSort = useSelector(selectSort(idColumn)) || SORT_TYPES.DEFAULT

  const sort = () => {
    if (!isSort) return
    const keySortType = Object.keys(SORT_TYPES)
    const newIndexKey =
      (keySortType.findIndex((key) => SORT_TYPES[key] === typeSort) + 1) %
      keySortType.length
    dispatch(changeSort({ [idColumn]: SORT_TYPES[keySortType[newIndexKey]] }))
    sortColumn(idColumn, SORT_TYPES[keySortType[newIndexKey]])
  }

  return (
    <th
      onClick={disableSort ? undefined : sort}
      style={{
        zIndex: !isSticky
          ? Z_INDEX.STICKY_THEAD
          : Z_INDEX.TH_TABLE_STICKY_FIRST_COLUMN,
        ...(appendStyleTh || {}),
      }}
      className={`${style.thSticky} ${customClassName.thSticky || ''} ${
        isSticky
          ? [style.columnThSticky, customClassName.columnThSticky || ''].join(
              ' ',
            )
          : ''
      }`}
      rowSpan={rowSpan}
      colSpan={colSpan}
    >
      {renderTh ? (
        renderTh()
      ) : (
        <div
          className={`h-100 d-flex ${style.mr6} ${style.textLeft} ${style.thInfo}`}
        >
          <Span style={{ fontSize: 10 }}>
            <Translate value={title} />
          </Span>
        </div>
      )}
      {!disableSort && <Sort type={typeSort} />}
    </th>
  )
}

const ThInfo = memo(({ cellInfo, sortColumn, isSort, customClassName }) => {
  return (
    <>
      {cellInfo.map((item, index) => {
        return (
          <ThCellInfo
            key={index}
            title={item.title}
            sortColumn={sortColumn}
            idColumn={item.id}
            renderTh={item.renderTh}
            disableSort={item.disableSort}
            appendStyleTh={item.appendStyleTh}
            rowSpan={item.rowSpan}
            colSpan={item.colSpan}
            isSticky={item.isSticky}
            isSort={isSort}
            customClassName={customClassName}
          />
        )
      })}
    </>
  )
})

export const EMPTY_COLUMN_LEFT_TABLE = 15 // fix bug header move when scroll

const Thead = ({
  selectColumnId,
  selectColumnCell,
  cellInfo,
  sortColumn,
  swapColumn,
  columnIndex,
  renderTh,
  isSwap,
  isSort,
  tableThRowSpan,
  tableThTop,
  customClassName,
  isCustormHeader,
  widthColumn,
}) => {
  return (
    <thead>
      {tableThRowSpan &&
        tableThRowSpan.map(
          (row, rowIndex) =>
            row.length > 0 && (
              <tr key={rowIndex} id={'rowTh' + rowIndex}>
                {rowIndex === 0 && (
                  <>
                    <ThInfo
                      cellInfo={cellInfo}
                      sortColumn={sortColumn}
                      isSort={isSort}
                      customClassName={customClassName}
                    />
                  </>
                )}
                {[...Array(EMPTY_COLUMN_LEFT_TABLE).keys()].map((id) => (
                  <ThCellEmpty
                    key={id}
                    rowIndex={rowIndex}
                    colIndex={id}
                    tableThRowSpan={tableThRowSpan}
                  />
                ))}
                {columnIndex.map((index) => (
                  <ThCell
                    key={index}
                    index={index}
                    thCustomData={row[index]}
                    selectColumnId={selectColumnId}
                    selectColumnCell={selectColumnCell}
                    sortColumn={sortColumn}
                    swapColumn={swapColumn}
                    renderTh={renderTh}
                    tableThTop={tableThTop}
                    isSwap={isSwap}
                    isCustormHeader={isCustormHeader}
                    isSort={isSort}
                    isThCustomData={row.some((item) => item.isThCustomData)}
                    customClassName={customClassName}
                    widthColumn={widthColumn}
                  />
                ))}
              </tr>
            ),
        )}
      {!tableThRowSpan && (
        <tr>
          <ThInfo
            cellInfo={cellInfo}
            sortColumn={sortColumn}
            isSort={isSort}
            customClassName={customClassName}
          />
          {[...Array(EMPTY_COLUMN_LEFT_TABLE).keys()].map((id) => (
            <th key={id} className={style.thSticky} />
          ))}
          {columnIndex.map((index) => (
            <ThCell
              key={index}
              index={index}
              selectColumnId={selectColumnId}
              selectColumnCell={selectColumnCell}
              sortColumn={sortColumn}
              swapColumn={swapColumn}
              renderTh={renderTh}
              isSwap={isSwap}
              isSort={isSort}
              customClassName={customClassName}
              isCustormHeader={isCustormHeader}
              widthColumn={widthColumn}
            />
          ))}
        </tr>
      )}
    </thead>
  )
}

Thead.propTypes = {
  selectColumnId: PropTypes.func.isRequired,
  selectColumnCell: PropTypes.func.isRequired,
  sortColumn: PropTypes.func,
  swapColumn: PropTypes.func,
  columnIndex: PropTypes.array.isRequired,
  isSwap: PropTypes.bool,
  isSort: PropTypes.bool,
  customClassName: PropTypes.object,
  widthColumn: PropTypes.any,
}

Thead.defaultProps = {
  widthColumn: '190px',
}

export default Thead
