export const common = {
  TABLE: 'Table',
  FILTER: 'Filter',
  CHART: 'Chart',
  NO_DATA_SEARCH: 'No data search',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  SIX_MONTHS: '6 Months',
  NINE_MONTHS: '9 Months',
  CONSOLIDATED: 'Consolidated',
  SEPARATED: 'Separated',
  TYPE_OF_RATIO: 'Type of Ratio',
  VIEW_BY: 'View by',
  NUMBER_OF_PERIOD: 'Number of period',
  TYPE: 'Type',
  LASTEST_YEAR: 'Latest year',
  STATEMENT: 'Statement',
  COMMON_SIZE: 'Common size',
  CURRENCY: 'Currency',
  UNIT: 'Unit',
  FINANCIAL_DATA: 'Financial Data',
  ALL: 'All',
}

export const financialDividend = {
  DIVIDEND: 'Dividend',
  CORPORATE_DIVIDEND: 'Corporate Dividend',
  DECLARATION_DATE: 'Declaration Date',
  EX_DEVIDEND_DATE: 'Ex-Dividend Date',
  RECORD_DATE: 'Record Date',
  PAYMENT_DATE: 'Payment Date',
  EXERCISE_RATE: 'Exercise Rate',
  PLAN_VOLUME: 'Plan Volume',
  ISSUE_VOLUME: 'Issue Volume',
  CASH_DIVIDEND: 'Cash Dividend',
  STOCK_DIVIDEND: 'Stock Dividend',
  DIVIDEND_YEAR: 'Dividend Year',
  DURATION: 'Duration',
  DPS: 'DPS',
  CURRENCY: 'Currency',
  PE: 'P/E',
  DIV_YIELD: 'Div Yield',
  EPS: 'EPS',
  DIVIDEND_PAYOUT_RATIO: 'Dividend Payout Ratio',
  PRICE_EARNING_RATIO: 'Price Earnings Ratio - Dividend Yield',
  EARNING_AGAINST_DIVIDEND: 'Earnings Against Dividends',
  EARNING_AGAINST_DIVIDEND_VND: 'Earnings Against Dividends (VND)',
  DIVIDEND_PAYOUT_RATIO_PERCENT: 'Dividend Payout Ratio',
  PE_TIME: 'P/E (Times)',
  DIV_YIELD_PERCENT: 'Div Yield',
  DIVIDEND_HISTORY: 'Dividend History',
  FINANCIAL_RATIO_BY_YEAR: 'Financial Ratio by Year (Quarter)',
  SALE_PER_SQUARE_VND: 'Sale per Square (Billion VND)',
  PEER_COMPARISON: 'Peers Comparison',
  SALE_PER_SQUARE: 'Sale per Square',
}

export const financialRatio = {
  FINANCIAL_RATIOS: 'Financial Ratios',
  ACTIVITY_RATIO: 'Activity Ratio',
  LIQUIDITY_RATIO: 'Liquidity Ratio',
  SOLVENCY_RATIO: 'Solvency Ratio',
  PROFITABILITY_RATIO: 'Profitability Ratio',
  CASH_FLOW_RATIO: 'Cashflow Ratio',
  GROWTH_RATIO: 'Growth Ratio',
  VALUATION_RATIO: 'Valuation Ratio',
  BANKING_SECTOR_RATIO: 'Banking Sector Ratio',
  CORPORATE_FINANCIAL_RATIO: 'Corporate Financial Ratio',
  GROWTH_TIMELINE: 'Growth Timeline',
  SALE_PER_SQUARE: 'Sale per Square',
  SALE_PER_SQUARE_VND: 'Sale per Square (Billion VND)',
  SALES_PER_SQUARE: 'Sales per Square',
  COMPARISON: 'Comparison',
  PEER_COMPARISON: 'Peer Comparison',
  CAPITAL_ADEQUACY: 'Capital Adequacy',
  ASSET_QUALITY: 'Asset Quality',
  EFFICIENCY: 'Efficiency',
  LIQUIDITY_ASSET: 'Liquidity & Assets',
  PROFITABILITY: 'Profitability',
  GROWTH: 'Growth',
  BY_YEAR: 'by Year',
  BY_QUARTER: 'by Quarter',
  BY_SIX_MONTHS: 'by 6 Months',
  BY_NINE_MONTHS: 'by 9 Months',
  CHART_THOUSAND_VND: '(Thousand VND)',
  CHART_MILLION_VND: '(Million VND)',
  CHART_BILLION_VND: '(Billion VND)',
}

export const financialStatement = {
  FINANCIAL_STATEMENT: 'Financial Statement',
  BALANCE_SHEET: 'Balance Sheet',
  INCOME_STATEMENT: 'Income Statement',
  CASH_FLOW: 'Cash Flow Statement',
  NOTE: 'Note to account',
  YES: 'Yes',
  NO: 'No',
  VND: 'VND',
  MILLION_VND: 'Million VND',
  BILLION_VND: 'Billion VND',
  USD: 'USD',
  THOUSAND_USD: 'Thousand USD',
  MILLION_USD: 'Million USD',
  CORPORATE_FINANCIAL_STATEMENT: 'Corporate Financial Statement',
  TOI_STRUCTURE: 'Toi Structure',
  CHART_VND: '(VND)',
  CHART_MILLION_VND: '(Million VND)',
  CHART_BILLION_VND: '(Billion VND)',
  CHART_USD: '(USD)',
  CHART_THOUSAND_USD: '(THOUSAND USD)',
  CHART_MILLION_USD: '(Million USD)',
  NII: 'NII',
  NFI: 'NFI',
  OTHER_INCOME: 'Other Incomes',
  PROFIT_LOSS: 'Profit & Loss',
  TOI: 'TOI',
  PREPROVISON_PROFIT: 'Preprovision Profit',
  PBT: 'PBT',
  REVENUE_PROFIT: 'Revenue & Profit',
  NET_REVENUE: 'Net Revenue',
  PROFIT_FINANCIAL_ACTIVITIES: 'Profit from financial activities',
  NET_PROFIT: 'Net Profit',
  TOTAL_INSURANCE: 'Total Insurance Claim Settlement Expenses',
  CLAIM_RATIO: 'Claim Ratio',
  OPERATING_SALE: 'Operating Sales',
  BROKERAGE: 'Brokerage',
  MARGIN_LENDING: 'Margin Lending',
  FINANCIAL_ASSET: 'Financial Assets',
  OTHER: 'Others',
  OPERATING_EXPENSES_STRUCTURE: 'Operating Expenses Structure',
  REVENUE: 'Revenue',
  EBIT: 'EBIT',
  PROFITABILITY: 'Profitability',
  GROSS_MARGIN: 'Gross Margin',
  EBIT_MARGIN: 'EBIT Margin',
  NET_MARGIN: 'Net Margin',
  BALANCE_SHEET_STRUCTURE: 'Balance Sheet Structure',
  LOAN: 'Loan',
  G_BOND: 'G-Bond',
  C_BOND: 'C-Bond',
  OTHER_ASSET: 'Other Assets',
  CASA: 'CASA',
  TERM_DEPOSIT: 'Term Deposit',
  OTHER_LIABILITIES: 'Other Liabilities',
  SHAREHOLDER_EQUITY: "Shareholder's Equity",
  DEPOSIT_CREDIT_GROWTH: 'Deposit Growth vs. Credit Growth',
  CHART_PERCENT: '(%)',
  LDR: 'LDR',
  DEPOSIT_GROWTH: 'Deposit Growth',
  CREDIT_GROWTH: 'Credit Growth',
  CASH_AND_EQUIVALENTS: 'Cash and Cash Equivalents',
  RECEIVABLES: 'Receivables',
  INVESTMENT: 'Investment',
  RESERVES: 'Reserves',
  OWNER_EQUITY: "Owner's Equity",
  UNEARNED_PREMIUM_RESERVE: 'Unearned Premium Reserve',
  CATASHTROPHE_RESERVE: 'Catastrophe Reserve',
  CLAIM_RESERVE: 'Claim Reserve',
  OTHER_RESERVE: 'Other Reserve',
  BORROWING: 'Borrowing',
  MARGIN_LENDING_GROWTH: 'Margin Lending vs Growth',
  FVTPL: 'FVTPL',
  HTM: 'HTM',
  INVENTORY: 'Inventory',
  FIXED_ASSET: 'Fixed Assets',
  TRADE_PAYABLES: 'Trade Payables',
  SHORT_LONG_TERM: 'Short Term & Long Term Borrowing',
  ST_BORROWING: 'Short-term Borrowing',
  LT_BORROWING: 'Long-term Borrowing',
  CASH_FLOW_STRUCTURE: 'Cash Flow Structure',
  CFO: 'CFO',
  CFI: 'CFI',
  CFF: 'CFF',
  OPERATING_CASH_FLOW_BREAKDOWN: 'Operating Cash Flow Breakdown',
  PLUS_LOAN: '+/- Loans',
  PLUS_CUSTOMER_DEPOSIT: '+/- Customer Deposit',
  PLUS_TRADING_SECURITIES: '+/- Trading securities',
  OTHER_CHANGES: 'Other Changes',
  OPERATING_CASH_FLOW: 'Operating Cash Flow',
  PLUS_FVPL: '+/- FVTPL',
  PLUS_HTM: '+/- HTM',
  PLUS_LENDING: '+/- Lending',
  PLUS_RECEIVABLE: '+/- Receivable',
  PLUS_PAYABLE: '+/- Payable',
  PLUS_INVENTORY: '+/- Inventory',
  TRADING: 'Trading',
  AGRICULTURE_FORESTRY: 'Agriculture and Forestry',
  MANUFACTURING: 'Manufacturing',
  CONSTRUCTION: 'Construction',
  PERSONAL_PUBLIC_SERVICE: 'Personal and Public Services',
  WAREHOUSING_TRANSPORTATION: 'Warehousing, Transportation and Communication',
  TRAINING_EDUCATION: 'Training and Education',
  ESTATE_AGENT_CONSULTANT: 'Estate Agents and Consultants',
  HOTEL_RESTAURANT: 'Hotels and Restaurants',
  FINANCIAL_SERVICE: 'Financial Services',
  LOAN_TERM: 'Loans By Term',
  SHORT_TERM_LOAN: 'Short Term Loans',
  MEDIUM_TERM_LOAN: 'Medium Term Loans',
  LONG_TERM_LOAN: 'Long Term Loans',
  INSURANCE_PREMIUM_BREAKDOWN: 'Insurance Premium Breakdown',
  LIFE_INSURANCE: 'Life insurance',
  HEALTH_INSURANCE: 'Health insurance',
  PROPERTY_DAMAGE_INSURANCE: 'Property and damage insurance',
  CARGO_INSURANCE: 'Cargo insurance',
  AVIATION_INSURANCE: 'Aviation insurance',
  MOTOR_VEHICLE_INSURANCE: 'Motor vehicle insurance',
  FIRE_INSURANCE: 'Fire insurance',
  MARINE_INSURANCE: 'Marine insurance',
  LIABILITY_INSURANCE: 'Liability insurance',
  CREDIT_FINANCIAL_INSURANCE: 'Credit insurance and financial risks',
  BUSINESS_DAMAGE_INSURANCE: 'Business damage insurance',
  AGRICULTURAL_INSURANCE: 'Agricultural insurance',
  OTHER_INSURANCE: 'Other insurance',
  FINANCIAL_INCOME: 'Financial Income',
  INTEREST_DEPOSIT: 'Interest on Deposits',
  INTEREST_BOND: 'Interest on Bond, treasury bill, exchange bill',
  OTHER_INTEREST: 'Other Interest',
  FINANCIAL_ASSET_STRUCTURE: 'Financial Assets Structure',
  LISTED_SECURITIES: 'Listed Securities',
  NON_LISTED_SECURITIES: 'Non-listed Securities',
  BOND: 'Bond',
  INVENTORY_STRUCTURE: 'Inventory Structure',
  GOOD_TRANSIT: 'Goods in transit',
  RAW_MATERIAL: 'Raw materials',
  TOOL_SUPPLIES: 'Tools and supplies',
  FINISHED_GOOD: 'Finished goods',
  MERCHANDISE_INVENTORIES: 'Merchandise inventories',
  FINANCIAL_EXPENSES: 'Financial Expenses',
  INTEREST_EXPENSES: 'Interest Expenses',
  OTHER_FINANCIAL_EXPENSES: 'Other Financial Expenses',
  LOAN_INDUSTRY: 'Loans By Industry',
  AUDIT_STATUS: 'Audit Status',
  AUDIT_FIRM: 'Audit Firm',
  AUDIT_OPINION_TYPE_NAME: 'Audit Opinion Type',
  FIINPRO_VERSION: 'FiinPro version (old)',
  FIINPRO_X_VERSION: 'FiinPro-X version',
}
