import { useMemo } from 'react'
import SelectCustom from '../../../../common/selectCustom'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { TIME_FREQUENCY } from '../../constants'
import {
  indicatorKeyByTimeFrequency,
  listMonthly,
  listQuarterly,
  listWeekly,
  listYearly,
} from '../constants'
import style from '../style.module.css'

export const IndicatorSelectTime = ({
  dataKey,
  data,
  indicatorsLatestPeriod,
  onChangeIndicator,
}) => {
  const getTimeOptions = (timeFrequency) => {
    switch (timeFrequency.toLowerCase()) {
      case TIME_FREQUENCY.WEEKLY.toLowerCase():
        return listWeekly
      case TIME_FREQUENCY.MONTHLY.toLowerCase():
        return listMonthly
      default:
        return listQuarterly
    }
  }

  const getDataTimeFrequency = useMemo(() => {
    const latestPeriod =
      indicatorsLatestPeriod[
        `${data.indicatorId}_${getTimeFrequencyByIndicator(data)}`
      ]
    const timeFrequencyKey =
      indicatorKeyByTimeFrequency[
        getTimeFrequencyByIndicator(data).toLowerCase()
      ]
    let timeFrequency = latestPeriod ? latestPeriod[timeFrequencyKey] : ''

    if (data.data[dataKey] && data.data[dataKey][timeFrequencyKey]) {
      timeFrequency = data.data[dataKey][timeFrequencyKey]
    }

    return timeFrequency
  }, [data, dataKey, indicatorsLatestPeriod])

  const getDataYear = useMemo(() => {
    let year =
      indicatorsLatestPeriod[
        `${data.indicatorId}_${getTimeFrequencyByIndicator(data)}`
      ]?.year

    if (data.data[dataKey]?.year) {
      year = data.data[dataKey]?.year
    }

    return year
  }, [data, dataKey, indicatorsLatestPeriod])

  return (
    <div className="d-flex">
      {data.timeFrequency.toLowerCase() !==
        TIME_FREQUENCY.YEARLY.toLowerCase() && (
        <div className={style.equationListItemTime}>
          <SelectCustom
            selectData={getTimeOptions(data.timeFrequency)}
            value={getDataTimeFrequency}
            handleChange={(val) =>
              onChangeIndicator(
                val,
                dataKey,
                data,
                getTimeFrequencyByIndicator(data),
              )
            }
            appendStyleDivValue={{ backgroundColor: '#F2F2F2' }}
            themeWhite
          />
        </div>
      )}
      <div className={style.equationListItemTime}>
        <SelectCustom
          selectData={listYearly}
          value={getDataYear}
          handleChange={(val) =>
            onChangeIndicator(val, dataKey, data, TIME_FREQUENCY.YEARLY)
          }
          appendStyleDivValue={{ backgroundColor: '#F2F2F2' }}
          themeWhite
        />
      </div>
    </div>
  )
}
