import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  getDataByQuarterAndYear,
  getDataByYear,
  getQuarterAndYearInDataRange,
  getQuarterAndYearKey,
  getQuarterInDataRange,
  getYearInDataRange,
  getYearKey,
} from '../../common/helper'
import { BALANCE_PAYMENT_FILTER } from '../../constants'
import { getItemsByIndexes, reOrderByMonthAndYear } from '../helper'
import {
  balanceOfPaymentTableContent,
  balanceOfPaymentTableContentAfterScroll,
} from './thunk'

const slice = createSlice({
  name: 'economy/paymentBalance/balanceOfPaymentTable',
  initialState: {
    idsCategory: [],
    dataById: {},
    isLoading: false,
    isContentLoading: false,
    listCategory: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    listOrigParentCategory: [],
    commodity: null,
    listNameOrder: [],
    currentTypeStatistics: BALANCE_PAYMENT_FILTER[1].value,
    isChangeFilter: false,
    rowsCollapse: [],
    currentSelectedIndexes: [],
    countLimit: 0,
  },
  reducers: {
    addCategoryToList: (state, action) => {
      state.isGetData = true
      state.listCategory = [...state.listCategory, action.payload]
    },
    removeCategoryFromList: (state, action) => {
      state.isGetData = false
      state.listCategory = state.listCategory.filter(
        (category) => category.id !== action.payload,
      )
    },
    changeCurrentTypeStatistics: (state, action) => {
      if (state.currentTypeStatistics !== action.payload) {
        state.isChangeFilter = true
      }
      state.currentTypeStatistics = action.payload
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
    keepCurrentSelectedIndexes: (state, action) => {
      state.currentSelectedIndexes = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(balanceOfPaymentTableContent.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(balanceOfPaymentTableContent.fulfilled, (state, action) => {
      state.isContentLoading = false
      state.isChangeFilter = false
      state.countLimit = action.meta.arg.Limit

      const payloadNameData =
        action.payload?.nameData?.map((item, index) => ({
          ...item,
          quarter: item.realQuarter,
          year: item.realYear,
          id: item.boPVNTypeId,
          name: item.boPVNTypeName,
          level: item.boPVNTypeLevel,
          parentId: item.parentBoPVNTypeId,
          index: index,
        })) || []

      const payloadData =
        action.payload?.data?.map((item) => ({
          ...item,
          quarter: item.realQuarter,
          year: item.realYear,
          id: item.boPVNTypeId,
          name: item.boPVNTypeName,
          level: item.boPVNTypeLevel,
        })) || []

      const isYearly =
        state.currentTypeStatistics === BALANCE_PAYMENT_FILTER[0].value

      const data = fullDataWithVal(payloadData, payloadNameData, isYearly)

      state.idsCategory = data?.map((v) => v.id)
      state.payloadData = payloadData
      state.data = data
      state.dataById = keyBy(data, 'id')
      state.listCategory = getItemsByIndexes(data, state.currentSelectedIndexes)

      const getGroupColumns = data?.map((item) => Object.keys(item))[0]
      const filterGroupColumns = isYearly
        ? getGroupColumns
            ?.filter((item) => item.includes('20'))
            .sort((prevYear, currYear) => currYear - prevYear)
        : reOrderByMonthAndYear(
            getGroupColumns?.filter((item) => item.includes('-')),
          )

      const groupColumns = []
      filterGroupColumns?.forEach((item) => {
        groupColumns.unshift({
          key: item,
          title: item,
        })
      })
      state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]

      state.rowsCollapse = payloadNameData
        .filter((item, index) => item.level === 1 && index !== 0)
        .map((item) => item.id)

      state.currentMinMonth = getQuarterInDataRange(payloadData)
        .map((item) => item.quarter)
        .reverse()[0]
      state.currentMinYear = getYearInDataRange(payloadData)
        .map((item) => item.year)
        .reverse()[0]
    })
    builder.addCase(balanceOfPaymentTableContent.rejected, (state) => {
      state.isContentLoading = false
    })

    builder.addCase(
      balanceOfPaymentTableContentAfterScroll.pending,
      (state) => {
        state.isContentLoading = true
      },
    )
    builder.addCase(
      balanceOfPaymentTableContentAfterScroll.fulfilled,
      (state, action) => {
        state.isContentLoading = false
        state.countLimit += action.meta.arg.Limit

        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            quarter: item.realQuarter,
            year: item.realYear,
            id: item.boPVNTypeId,
            name: item.boPVNTypeName,
            level: item.boPVNTypeLevel,
          })) || []

        const isYearly =
          state.currentTypeStatistics === BALANCE_PAYMENT_FILTER[0].value

        const data = fullDataWithVal(payloadData, state.data, isYearly)

        state.idsCategory = data?.map((v) => v.id)
        state.payloadData = payloadData
        state.data = data
        state.dataById = keyBy(data, 'id')
        state.listCategory = [...state.listCategory]

        const getGroupColumns = data?.map((item) => Object.keys(item))[0]
        const filterGroupColumns = isYearly
          ? getGroupColumns
              ?.filter((item) => item.includes('20'))
              .sort((prevYear, currYear) => currYear - prevYear)
          : reOrderByMonthAndYear(
              getGroupColumns?.filter((item) => item.includes('-')),
            )
        const groupColumns = []
        filterGroupColumns?.forEach((item) => {
          groupColumns.unshift({
            key: item,
            title: item,
          })
        })
        state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]

        state.currentMinMonth = getQuarterInDataRange(payloadData)
          .map((item) => item.quarter)
          .reverse()[0]
        state.currentMinYear = getYearInDataRange(payloadData)
          .map((item) => item.year)
          .reverse()[0]
      },
    )
    builder.addCase(
      balanceOfPaymentTableContentAfterScroll.rejected,
      (state) => {
        state.isContentLoading = false
      },
    )
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectLoadingTableContent = (state) =>
  state[slice.name].isContentLoading
export const selectCategoryIds = (state) => state[slice.name].idsCategory
export const selectCategoryDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCategory = (state) => state[slice.name].listCategory
export const selectDataTable = (state) => state[slice.name].data
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectOrigParentCategory = (state) =>
  state[slice.name].listOrigParentCategory
export const selectListNameOrder = (state) => state[slice.name].listNameOrder
export const selectCurrentTypeStatistics = (state) =>
  state[slice.name].currentTypeStatistics
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectCountLimit = (state) => state[slice.name].countLimit

export const {
  addCategoryToList,
  removeCategoryFromList,
  changeCurrentTypeStatistics,
  setRowsCollapse,
  keepCurrentSelectedIndexes,
} = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (dataContainVal, dataWithOnlyTitle, isYearly) => {
  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
    : getQuarterAndYearInDataRange(dataContainVal).map((item) =>
        getQuarterAndYearKey(item.quarter, item.year),
      )

  const getDataValueFromRange = isYearly
    ? getYearInDataRange(dataContainVal).map((item) => [
        ...getDataByYear(dataContainVal, item.year),
      ])
    : getQuarterAndYearInDataRange(dataContainVal).map((item) => [
        ...getDataByQuarterAndYear(dataContainVal, item.quarter, item.year),
      ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return dataWithOnlyTitle.map((item) => ({
    ...item,
    childrenIds: getChildrenIds(dataWithOnlyTitle, item.id),
    ...getKeyAndValRange(item),
  }))
}

const getChildrenIds = (data, id) => {
  return data
    .filter((item) => item.level > 1 && item.parentId === id)
    .map((item) => item.id)
}
