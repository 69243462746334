import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { languageObjs } from '../../../../configs/Language'
import { Loading } from '../../../common/loading'
import { ScrollComponent } from '../../../common/ScrollComponent'
import TextEllipsis from '../../../common/textEllipsis'
import {
  ALL_EVENT_ID,
  getChecked,
  getEventTypes,
  getEventTypesLoading,
  handleCheckboxChecked,
} from '../store/slice'
import style from '../style.module.css'

const CHECKBOX_MARGIN = 16
const CHECKBOX_WIDTH = 12

const EventTypes = ({ width, locale, tab }) => {
  const dispatch = useDispatch()

  const eventTypes = useSelector(getEventTypes)
  const eventTypesLoading = useSelector(getEventTypesLoading)
  const checked = useSelector(getChecked)
  const currentChecked = checked[tab]

  const allItem = {
    macroEventTypeId: ALL_EVENT_ID,
    macroEventTypeName: locale === languageObjs.vi ? 'Tất cả' : 'All',
  }

  const changeCheckbox = (id) => {
    const isChecked = currentChecked.includes(id)

    if (id === ALL_EVENT_ID) {
      handleAllChecked(isChecked)
    } else {
      handleOtherChecked(id, isChecked)
    }
  }

  const handleAllChecked = (isChecked) => {
    if (isChecked) {
      dispatch(
        handleCheckboxChecked({
          ...checked,
          [tab]: [],
        }),
      )
    } else {
      const ids = eventTypes.map((item) => item.macroEventTypeId)
      dispatch(
        handleCheckboxChecked({
          ...checked,
          [tab]: [ALL_EVENT_ID, ...ids],
        }),
      )
    }
  }

  const handleOtherChecked = (id, isChecked) => {
    let newChecked = {}
    if (isChecked) {
      newChecked = {
        ...checked,
        [tab]: currentChecked.filter(
          (item) => item !== id && item !== ALL_EVENT_ID,
        ),
      }
    } else {
      const newCurrentChecked = [...currentChecked, id]
      const newTabChecked =
        newCurrentChecked.length === eventTypes.length
          ? [...newCurrentChecked, ALL_EVENT_ID]
          : newCurrentChecked
      newChecked = {
        ...checked,
        [tab]: newTabChecked,
      }
    }

    dispatch(handleCheckboxChecked(newChecked))
  }

  if (eventTypesLoading) {
    return <Loading />
  }

  return (
    <div style={{ flex: 1 }}>
      <ScrollComponent>
        {eventTypes.length !== 0 &&
          [allItem, ...eventTypes].map((item) => (
            <div
              key={item?.macroEventTypeId}
              className={`d-flex align-center ${style.itemTypes}`}
              onClick={() => changeCheckbox(item.macroEventTypeId)}
            >
              <input
                type="checkbox"
                className={`checkbox ${style.checkbox}`}
                checked={currentChecked.includes(item.macroEventTypeId)}
                readOnly={true}
              />
              <div
                className={`${style.pointer}`}
                style={{
                  maxWidth: width - CHECKBOX_MARGIN - CHECKBOX_WIDTH,
                }}
              >
                <TextEllipsis isI18n={false} text={item?.macroEventTypeName} />
              </div>
            </div>
          ))}
      </ScrollComponent>
    </div>
  )
}

EventTypes.propTypes = {
  width: PropTypes.number,
  locale: PropTypes.string,
  tab: PropTypes.string,
}

export default EventTypes
