import { createAsyncThunk } from '@reduxjs/toolkit'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'
import { handleExport } from '../../../../../../utils/Export'

export const getDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/securities/panelDetailMetrics/DETAIL_METRICS',
  async (params, { rejectWithValue }) => {
    const response = await PenetrationAnalysisService.getSecDetailMetrics(
      params,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getDownloadDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/securities/panelDetailMetrics/DOWNLOAD_DETAIL_METRICS',
  async (data) => {
    const response =
      await PenetrationAnalysisService.getDownloadSecDetailMetrics(data)
    handleExport(response.data, response.filename)
  },
)
