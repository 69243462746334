import React, { useCallback, useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import Popup from '../../../common/popup'
import useGetLayoutDashboard, {
  lengthChartInLayout,
} from '../../chart/common/useGetLayoutDashboard'
import { screenTypes } from '../../constants'
import {
  changeDashboard,
  changePositionChart,
  selectDashboard,
  selectDashboardChartData,
} from '../../store/slice'
import { updateAndReloadDashboard, updateCharts } from '../../store/thunk'
import style from '../style.module.css'
import styles from '../styleTheme.module.css'
import { ItemReposition } from './ItemReposition'
import { LayoutTabs } from './LayoutTabs'

const PopupEditLayout = ({ isShow, handleShow }) => {
  const dispatch = useDispatch()
  const dashboardChartData = useSelector(selectDashboardChartData)
  const { data = {} } = useSelector(selectDashboard)

  const [layout, setLayout] = useState(data?.layout || 'layout_1')
  const [cards, setCards] = useState([])
  const [dragDropItem, setDragDropItem] = useState({
    dragIndex: undefined,
    hoverIndex: undefined,
  })
  const lengthChart = cards.length
  const { layoutDisplay } = useGetLayoutDashboard(layout, 8, 1)

  const changeLayout = (layout) => {
    setLayout(layout)
  }

  const onClose = () => {
    handleShow(false)
  }

  const onApply = () => {
    handleShow(false)
    dispatch(
      updateAndReloadDashboard({
        ...data,
        layout: layout,
        maxChartCount: lengthChartInLayout[layout],
      }),
    )
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        data: {
          ...data,
          layout: layout,
          maxChartCount: lengthChartInLayout[layout],
        },
      }),
    )
    cards
      .map((i, index) => ({
        ...i,
        order: index + 1,
        detail: JSON.stringify({ ...JSON.parse(i.detail), order: index + 1 }),
      }))
      .forEach((element) => {
        if (typeof element.id === 'number') {
          dispatch(updateCharts(element))
        }
      })
    dispatch(
      changePositionChart(
        cards.map((i, index) => ({
          ...i,
          order: index + 1,
          detail: JSON.stringify({ ...JSON.parse(i.detail), order: index + 1 }),
        })),
      ),
    )
  }

  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setDragDropItem({ dragIndex: dragIndex, hoverIndex: hoverIndex })
  }, [])

  useEffect(() => {
    setCards(
      [...dashboardChartData]
        .sort((a, b) => a.order - b.order)
        .map((i, index) => {
          const detail = { ...JSON.parse(i.detail), order: index + 1 }
          return {
            ...i,
            detail: JSON.stringify(detail),
            text: detail.nameChart.name,
            order: index + 1,
          }
        }),
    )
  }, [dashboardChartData])

  useEffect(() => {
    setLayout(data?.layout)
  }, [data?.layout])

  const renderCard = useCallback(
    (card, index) => {
      return (
        <ItemReposition
          key={card.order}
          index={index}
          card={card}
          moveCard={moveCard}
          dragDropItem={dragDropItem}
          cards={cards}
          setCards={setCards}
          layout={layoutDisplay}
        />
      )
    },
    [JSON.stringify(dragDropItem), layout, cards],
  )

  if (isShow) {
    return (
      <Popup overlayCustom={styles.overlayCustom}>
        <div className="modal" style={{ width: 594, height: 580 }}>
          <div className={`modal-title ${styles.titleStyle}`}>
            <Translate value="financialChart.EDIT_LAYOUT" />
            <a onClick={onClose} className="close-modal">
              <i className="icon-delete-stroke" style={{ color: '#646464' }} />
            </a>
          </div>
          <div
            className={`modal-content position-relative ${styles.modalContentStyle}`}
            style={{ height: 516 }}
          >
            <LayoutTabs
              layout={layout}
              lengthChart={lengthChart}
              changeLayout={changeLayout}
            />
            <div className={style.changeLayout}>
              <div className={style.textOrange}>
                <Translate value="financialChart.REPOSITION_CHART" />
              </div>
              <DndProvider backend={HTML5Backend}>
                <div className={style.layoutReposition}>
                  {cards
                    .slice(0, layoutDisplay.length)
                    .map((card, index) => renderCard(card, index))}
                </div>
              </DndProvider>
            </div>
            <div className={style.textNumberOfChart}>
              <Translate value="financialChart.NUMBER_OF_CHART" />
              &nbsp;
              {lengthChart}
            </div>
            <div className={`group-btn justify-content-end ${style.groupBtn}`}>
              <Button
                onClick={onClose}
                className={`btn w-80 ${styles.buttonGrayStyle}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={onApply}
                className={`btn w-80 ${styles.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_APPLY" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

export default PopupEditLayout
