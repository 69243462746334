import { Translate } from 'react-redux-i18n'
import style from '../style.module.css'

export const FCLoading = ({ title, isLoading }) => {
  return (
    isLoading && (
      <div className={style.downloadContainer}>
        <Translate value={title} />
      </div>
    )
  )
}

FCLoading.defaultProps = {
  title: 'financialChart.DOWNLOADING',
}
