import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { CalendarSearch } from './CalendarSearch'
import style from './index.module.css'
import { selectFilter, updateFilter } from './store/slice'
import { getDataIssuePlan } from './store/thunk'

const Search = () => {
  const locale = useSelector((state) => state.i18n.locale)
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)

  useEffect(() => {
    dispatch(
      getDataIssuePlan({
        FromDate: formatDateTime(filter.FromDate, FORMAT.YEAR_MONTH_DAY),
        EndDate: formatDateTime(filter.EndDate, FORMAT.YEAR_MONTH_DAY),
      }),
    )
  }, [filter, locale])

  // useEffect(() => {
  //   dispatch(
  //     updateMultiFilter({
  //       FromDate: formatDateTime(filter.FromDate, FORMAT.YEAR_MONTH_DAY),
  //       EndDate: formatDateTime(filter.EndDate, FORMAT.YEAR_MONTH_DAY),
  //     }),
  //   )
  // }, [commomTopIssuer])

  const changeFilter = (value, key) => {
    dispatch(updateFilter({ value, key }))
  }
  return (
    <div className={`${style.financialMetricHeader}`}>
      <CalendarSearch
        dateFrom={filter.FromDate}
        dateTo={filter.EndDate}
        onDateFromChange={(e) => changeFilter(e, 'FromDate')}
        onDateToChange={(e) => changeFilter(e, 'EndDate')}
        limitDatepicker={{
          minYear: '',
          maxYear: '',
          minDate: new Date(),
          maxDate: '',
        }}
      />
    </div>
  )
}

export default Search
