import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Area } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { PreOpenMarket } from '../../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { valDivBillion } from '../../../../../utils/Value'
import {
  keys,
  selectFilterMarketLiquidity,
  selectLoading,
  selectMarketLiquidityData,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const MarketLiquidity = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.MARKET_LIQUIDITY))
  const data = useSelector(selectMarketLiquidityData)
  const locale = useSelector((state) => state.i18n.locale)
  const { TimeRange } = useSelector(selectFilterMarketLiquidity)

  const formatDataBarChart = useMemo(
    () =>
      data.map((item) => ({
        tradingDate: formatDateTime(item.tradingDate, FORMAT.DATE, locale),
        totalValueToday: valDivBillion(item.totalValueToday),
        totalValueLastday: valDivBillion(item.totalValueLastday),
      })),
    [data, locale],
  )

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const timeType = useMemo(() => {
    switch (TimeRange) {
      case 'OneWeek':
        return I18n.t('bond.corporateBond.marketLiquidity.ONE_WEEK')
      case 'OneMonth':
        return I18n.t('bond.corporateBond.marketLiquidity.ONE_MONTH')
      case 'ThreeMonths':
        return I18n.t('bond.corporateBond.marketLiquidity.THREE_MONTHS')
      default:
        return ''
    }
  }, [TimeRange, locale])

  const renderTooltip = (dataChart) => (
    <ChartTooltip dataChart={dataChart} timeType={timeType} />
  )

  return (
    <Panel
      title="bond.corporateBond.marketLiquidity.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.marketLiquidity.TITLE')}
      downloadJpgParams={{
        domId: 'marketLiquidityChartData',
        nameScreen: I18n.t('bond.corporateBond.secondaryMarket.TITLE'),
        chartName: I18n.t('bond.corporateBond.marketLiquidity.TITLE'),
      }}
    >
      <PreOpenMarket>
        <SizeTracker>
          {(size) => (
            <>
              <Filter />
              {size.height && (
                <div
                  id="marketLiquidityChartData"
                  style={{ height: height - size.height }}
                >
                  {isLoading ? (
                    <Loading />
                  ) : !!formatDataBarChart.length ? (
                    <>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - size.height - 28}
                        keyXAxis="tradingDate"
                        yAxis={[
                          {
                            id: 'left',
                            keys: ['totalValueToday', 'totalValueLastday'],
                            orientation: 'left',
                            label: I18n.t(
                              'bond.corporateBond.marketLiquidity.LABEL',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 5,
                            min: 0,
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 0 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            <Area
                              dataKey="totalValueLastday"
                              fill="#FFC859"
                              yAxisId="left"
                              stroke="none"
                              fillOpacity={1}
                              isAnimationActive={false}
                              activeDot={false}
                            />
                            <Area
                              dataKey="totalValueToday"
                              fill="#009CFF"
                              yAxisId="left"
                              stroke="none"
                              fillOpacity={0.8}
                              isAnimationActive={false}
                              activeDot={false}
                            />
                          </>
                        )}
                      </ChartContainer>
                      <Footer
                        key={width}
                        list={[
                          {
                            text: I18n.t(
                              'bond.corporateBond.marketLiquidity.TOTAL_TODAY',
                              { time: timeType },
                            ),
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: '#009CFF',
                            },
                            isI18n: false,
                          },
                          {
                            text: I18n.t(
                              'bond.corporateBond.marketLiquidity.TOTAL_YESTERDAY',
                            ),
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: '#FFC859',
                            },
                            isI18n: false,
                          },
                        ]}
                      />
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </PreOpenMarket>
    </Panel>
  )
}

export default MarketLiquidity
