import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import {
  DEFAULT_COLUMN_COLOR,
  DEFAULT_COLUMN_KEY,
  MONEY_MARKET_SYNC_ID,
} from '../../config'
import {
  getLineChartColor,
  getTableMapping,
} from '../../panelOpenMarket/store/slice'
import style from '../index.module.css'
import Tooltip from './Tooltip'
import {
  handleComboChartData,
  handleComboChartKey,
  handleLineChartHasAuthor,
  handleTooltipTwoColumn,
  isPercentFormat,
} from './helper'
import { getActiveLine } from './store/slice'

const YAXIS_LEFT = 'left'
const YAXIS_RIGHT = 'right'
const HEADER_HEIGHT = 25

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}
const ChartComponent = ({
  data,
  height,
  width,
  ids,
  keyXAxis,
  heightListIndicator,
}) => {
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const activeLine = useSelector(getActiveLine)
  const lineChartColor = useSelector(getLineChartColor)
  const tableMapping = useSelector(getTableMapping)

  const { lineKey, columnKey } = handleComboChartKey(ids)
  const comboDataChart = handleComboChartData(data, ids, timeZone, locale)

  const lineChartHasAuthor = handleLineChartHasAuthor(
    lineChartColor,
    tableMapping,
  )

  const { leftColumn, rightColumn } = handleTooltipTwoColumn(lineChartHasAuthor)
  const lineKeyWithColor = lineChartHasAuthor.filter(
    (item) => !Object.values(DEFAULT_COLUMN_KEY).includes(item.author),
  )

  const hasYAxisRight = [...columnKey, ...lineKey].some((item) =>
    isPercentFormat(item),
  )
  const keyYAxis = hasYAxisRight
    ? [
        {
          id: YAXIS_LEFT,
          keys: [...columnKey, ...lineKey].filter(
            (item) => !isPercentFormat(item),
          ),
          orientation: YAXIS_LEFT,
          isLineChart: true,
          label: I18n.t('economy.monetary.monetary.BILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
        },
        {
          id: YAXIS_RIGHT,
          keys: [...columnKey, ...lineKey].filter((item) =>
            isPercentFormat(item),
          ),
          orientation: YAXIS_RIGHT,
          isLineChart: true,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          tickNum: SETTINGS.yTickNum,
          unitYAxis: '%',
        },
      ]
    : [
        {
          id: YAXIS_LEFT,
          keys: [...columnKey, ...lineKey],
          orientation: YAXIS_LEFT,
          isLineChart: true,
          label: I18n.t('economy.monetary.monetary.BILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
        },
      ]

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return (
                  <Tooltip key={item.author} item={item} payload={payload} />
                )
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return (
                  <Tooltip key={item.author} item={item} payload={payload} />
                )
              })}
          </div>
        </div>
      </>
    )
  }

  return (
    <ChartContainer
      data={comboDataChart}
      height={height - heightListIndicator - HEADER_HEIGHT}
      width={width}
      keyXAxis={keyXAxis}
      yAxis={keyYAxis}
      renderCustomTooltip={renderTooltip}
      syncId={MONEY_MARKET_SYNC_ID}
      timeFrame={TIME_RANGES.CUSTOM}
      isNotFormatXAxis
      margin={{
        ...MARGIN_RECHARTS,
        right: hasYAxisRight ? 0 : 42,
      }}
    >
      {({ chartContentWidth }) => {
        return (
          <>
            {columnKey.map((barKey) => {
              return (
                <Bar
                  key={barKey}
                  isAnimationActive={false}
                  yAxisId={YAXIS_LEFT}
                  dataKey={barKey}
                  barSize={getColumnSizeInBarChart(
                    chartContentWidth,
                    columnKey.length,
                  )}
                  fill={
                    barKey === DEFAULT_COLUMN_KEY.sellOutright
                      ? DEFAULT_COLUMN_COLOR.sellOutright
                      : DEFAULT_COLUMN_COLOR.reverseRepo
                  }
                  stackId={'stackId'}
                />
              )
            })}
            {lineKeyWithColor.map((item, index) => {
              return (
                <Line
                  key={item.author}
                  isAnimationActive={false}
                  yAxisId={
                    !isPercentFormat(item.author) ? YAXIS_LEFT : YAXIS_RIGHT
                  }
                  dataKey={item.author}
                  stroke={item.color}
                  strokeWidth={
                    activeLine === item.author
                      ? SETTINGS.strokeActiveWidth
                      : SETTINGS.strokeWidth
                  }
                  dot={false}
                  strokeOpacity={
                    activeLine !== item.author && activeLine
                      ? SETTINGS.blur
                      : SETTINGS.normalOrHover
                  }
                  activeDot={false}
                  connectNulls={true}
                />
              )
            })}
          </>
        )
      }}
    </ChartContainer>
  )
}

export default ChartComponent
