import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import PanelDetailMetrics from './detailMetrics/PanelDetailMetrics'
import PanelMarketShare from './marketShare/PanelMarketShare'
import PanelRevenueBreakdown from './revenueBreakdown/PanelRevenueBreakdown'
import PanelRevenueChart from './revenueChart/PanelRevenueChart'

export const MAP_KEY = {
  WRAPPER: 'WRAPPER',
  REVENUE_BREAKDOWN: 'REVENUE_BREAKDOWN',
  MARKET_SHARE: 'MARKET_SHARE',
  REVENUE_CHART: 'REVENUE_CHART',
  DETAIL_METRICS: 'DETAIL_METRICS',
}

const HEIGHT_HEADER_WRAPPER = 28
const MIN_HEIGHT = 270

const MAP_SIZE = {
  [`${MAP_KEY.WRAPPER}`]: {
    width: '100%',
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    minHeight: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT,
    minWidth:
      PANEL_PADDING + 3 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.REVENUE_BREAKDOWN,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.MARKET_SHARE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.REVENUE_CHART,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.REVENUE_BREAKDOWN}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${HEIGHT_HEADER_WRAPPER}px - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: PANEL_PADDING / 2,
    minLeft: PANEL_PADDING / 2,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_SHARE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARKET_SHARE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + COMPONENT.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.REVENUE_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REVENUE_CHART}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 * 2 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft:
      PANEL_PADDING / 2 + 2 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DETAIL_METRICS}`]: {
    width: '100%',
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minLeft: 0,
    minWidth:
      PANEL_PADDING + 3 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    minHeight: MIN_HEIGHT,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const CorporateContent = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title="corporate.businessModel.penetrationAnalysis.MARKET_SHARE"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.WRAPPER}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelRevenueBreakdown
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REVENUE_BREAKDOWN}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.REVENUE_BREAKDOWN].width - PANEL_PADDING}
        height={sizes[MAP_KEY.REVENUE_BREAKDOWN].height - HEADER_PANEL_HEIGHT}
      />
      <PanelMarketShare
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_SHARE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.MARKET_SHARE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.MARKET_SHARE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelRevenueChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REVENUE_CHART}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.REVENUE_CHART].width - PANEL_PADDING}
        height={sizes[MAP_KEY.REVENUE_CHART].height - HEADER_PANEL_HEIGHT}
      />
      <PanelDetailMetrics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DETAIL_METRICS}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(CorporateContent)
