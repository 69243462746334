import FinancialChartProxy from '../../proxies/financialChart/FinancialChartProxy'
import { ServiceBase } from '../ServiceBase'

class FinancialChartService extends ServiceBase {
  getChartsById(params) {
    return this.getData(() => FinancialChartProxy.getChartsById(params))
  }

  getCharts(params) {
    return this.getData(() => FinancialChartProxy.getCharts(params))
  }

  getSharedCharts(params) {
    return this.getData(() => FinancialChartProxy.getSharedCharts(params))
  }

  postCharts(params) {
    return this.getData(() => FinancialChartProxy.postCharts(params))
  }

  updateCharts(params) {
    return this.getData(() => FinancialChartProxy.updateCharts(params))
  }

  deleteCharts(params) {
    return this.getData(() => FinancialChartProxy.deleteCharts(params))
  }

  postChartUserSettings(params) {
    return this.getData(() => FinancialChartProxy.postChartUserSettings(params))
  }

  getChartUserSettings(params) {
    return this.getData(() => FinancialChartProxy.getChartUserSettings(params))
  }
}

export default new FinancialChartService()
