import { useSelector } from 'react-redux'
import { dashboardTypes } from '../constants'
import { MyChartLibrary } from '../myChartLibrary'
import { NewDashboard } from '../newDashboard'
import { selectDashboard } from '../store/slice'
import { Content } from './Content'

const getMapContent = (props) => ({
  [dashboardTypes.CHART_OF_THE_DAY]: <Content {...props} />,
  [dashboardTypes.DASHBOARD_TEMPLATE]: <Content {...props} />,
  [dashboardTypes.MY_DASHBOARD]: <Content {...props} />,
  [dashboardTypes.MY_CHART_LIBRARY]: <MyChartLibrary {...props} />,
  [dashboardTypes.NEW_DASHBOARD]: <NewDashboard {...props} />,
})

export const Switch = (props) => {
  const { dashboardType } = useSelector(selectDashboard)

  return getMapContent(props)[dashboardType]
}
