import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { changeFinancialChartFilter } from '../chart/store/slice'
import {
  changeFilterLocal,
  changeFinancialFilter,
  selectFilterLocal,
  selectFinancialFilter,
} from '../store/slice'
import { UNIT } from './constants'
import style from './index.module.css'

const FilterOptionItem = ({ data }) => {
  const parentDropdownRef = useRef()
  const dispatch = useDispatch()

  // Use selector
  const financialFilter = useSelector(selectFinancialFilter)
  const filterLocal = useSelector(selectFilterLocal)

  // Actions
  const onSelectItem = (value) => {
    const item = getOptions.find((item) => item.value === value)
    if (data.field === 'unit') {
      dispatch(changeFilterLocal({ field: data.field, option: item }))
    } else {
      dispatch(changeFinancialFilter({ field: data.field, option: item }))
      dispatch(changeFinancialChartFilter({ field: data.field, option: item }))
    }
  }

  // Get data
  const getOptions = useMemo(() => {
    return data.field === 'unit'
      ? UNIT[filterLocal.currency.value]
      : data.options
  }, [filterLocal.currency.value, data.field])

  return (
    <div
      className={`input-dropdown ${style['input-dropdown']}`}
      ref={parentDropdownRef}
    >
      <Span style={{ fontSize: 10 }} className={style['title-dropdown']}>
        <Translate value={data.title} />
      </Span>
      <SelectCustom
        isI18n={true}
        selectData={getOptions}
        value={
          data.field === 'unit'
            ? filterLocal[data.field].value
            : financialFilter[data.field].value
        }
        handleChange={onSelectItem}
        appendStyle={{ fontWeight: 'normal' }}
      />
    </div>
  )
}

FilterOptionItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FilterOptionItem
