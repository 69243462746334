import { Span } from '../../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'
import { isPercentFormat } from './helper'

const Tooltip = ({ payload, item }) => {
  const fontSize = getFontSize(11)
  const val = payload[`${item.author}`]

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <TextEllipsisStaticLine
        appendStyle={{ fontSize, fontWeight: 400 }}
        lineClamp={2}
        isI18n={false}
        val={`${item.indicatorName}:`}
      />
      <Span
        style={{ marginLeft: 10, fontSize: 11 }}
        className={`${style.toolTipContentValue} ${
          typeof val === 'number' && val < 0 && style.redColor
        }`}
      >
        {formatVal(val)}
        {typeof val === 'number' && isPercentFormat(item.author) && '%'}
      </Span>
    </div>
  )
}

export default Tooltip
