export const TYPE_IMPORT_EXPORT = {
  IMPORT: 'Import',
  EXPORT: 'Export',
}

export const LIST_RADIO_IMPORT_EXPORT = [
  {
    title: 'economy.importExport.importExport.FILTER_IMPORT',
    value: TYPE_IMPORT_EXPORT.IMPORT,
  },
  {
    title: 'economy.importExport.importExport.FILTER_EXPORT',
    value: TYPE_IMPORT_EXPORT.EXPORT,
  },
]
