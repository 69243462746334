import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { VIEW_TYPE_RADIO } from '../constants'
import { changeFilter, selectViewType } from '../store/slice'

export const FilterTimeOptions = () => {
  const dispatch = useDispatch()

  // Use selector
  const viewType = useSelector(selectViewType)

  // Actions
  const onRadioChange = (e) => {
    const value = e.target.value
    dispatch(changeFilter({ viewType: value }))
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Span style={{ opacity: 0.4, fontSize: 10 }}>
        <Translate value={'sector.financialAnalysis.corporate.TIME'} />
      </Span>
      <ul
        style={{ width: 'auto' }}
        className="list-check d-flex align-center mt-8"
      >
        {VIEW_TYPE_RADIO.map((item, index) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2 small"
                value={item.value}
                checked={item.value === viewType}
                onChange={onRadioChange}
              />
              <Span
                style={{
                  color: item.value === viewType ? '#2f9aee' : '',
                }}
              >
                <Translate value={item.title} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

FilterTimeOptions.propTypes = {
  onChange: PropTypes.func,
}
