import PropTypes from 'prop-types'
import { useLayoutEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  formatterByName,
  labelFormatterByTimeRange,
} from '../../../../common/chart/financialChart/helper'
import { Span } from '../../../../common/html/Span'
import { selectTooltipItemById } from '../../store/slice'
import style from './index.module.css'

const DEFAULT_OFFSET = 10

const TooltipItem = ({ activeIndex, timeRange, tooltipSchema }) => {
  const activeIndexData = useSelector(selectTooltipItemById(activeIndex))
  const tooltipRef = useRef()

  const { clientX, clientY } = activeIndexData.baseEvent
  const { activeLabel, activePayload } = activeIndexData.clickPosition
  const payloadHasData = activePayload.filter((item) => item.value)

  const [top, setTop] = useState(clientY + DEFAULT_OFFSET)
  const [left, setLeft] = useState(clientX + DEFAULT_OFFSET)

  useLayoutEffect(() => {
    const chart = document.querySelector('.recharts-cartesian-grid')
    if (!chart || !tooltipRef.current) {
      return
    }

    const { right: chartRight, bottom: chartBottom } =
      chart.getBoundingClientRect()
    const { width: tooltipWidth, height: tooltipHeight } =
      tooltipRef.current.getBoundingClientRect()
    const isMoveLeft = chartRight - clientX - DEFAULT_OFFSET < tooltipWidth
    const isMoveTop = chartBottom - clientY - DEFAULT_OFFSET < tooltipHeight

    if (isMoveLeft) {
      setLeft(clientX - tooltipWidth - DEFAULT_OFFSET)
    }
    if (isMoveTop) {
      setTop(clientY - tooltipHeight - DEFAULT_OFFSET)
    }
  })

  if (payloadHasData.length === 0) return null

  return (
    <div
      ref={tooltipRef}
      style={{ top, left }}
      className={['recharts-default-tooltip', style.tooltipItemContainer].join(
        ' ',
      )}
    >
      <Span>{labelFormatterByTimeRange(timeRange)(activeLabel)}</Span>
      <div className={style.tooltipItem}>
        {payloadHasData.map((payload) => {
          const { value, name } = payload
          const formattedValue = formatterByName(tooltipSchema)(value, name)
          return Array.isArray(formattedValue) ? (
            <div key={payload.dataKey} className={style.marginTopBottom4}>
              <Span>{formattedValue[1]}</Span>
              <Span style={{ minWidth: 16 }}>:</Span>
              <Span>{formattedValue[0]}</Span>
            </div>
          ) : (
            formattedValue
          )
        })}
      </div>
    </div>
  )
}

TooltipItem.propTypes = {
  activeIndex: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  timeRange: PropTypes.string,
  tooltipSchema: PropTypes.array.isRequired,
}

export default TooltipItem
