import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemRadio from './common/ItemRadio'
import RadioCalendar from './common/RadioCalendar'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import { deepCopy } from './helper'
import style from './index.module.css'

const TradingData = ({
  handleSubmit,
  handleCancel,
  title,
  minDate,
  maxDate,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    if (detail.time !== 'Specific') {
      delete detail.tradingDate
    }
    handleSubmit({ ...data, detail })
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div>
            <div className="d-flex ali-center j-b">
              <div className={style.tdItem}>
                <ItemRadio
                  defaultCheck={true}
                  keyRadio="time"
                  valueRadio="MostRecent"
                  textRadio={I18n.t('common.popup.MOST_RECENT_2')}
                />
                <ItemRadio
                  keyRadio="time"
                  valueRadio="SixMonthsAgo"
                  textRadio={I18n.t('common.popup.SIX_MONTH_AGO')}
                />
              </div>
              <div className={style.tdItem}>
                <ItemRadio
                  keyRadio="time"
                  valueRadio="OneWeekAgo"
                  textRadio={I18n.t('common.popup.ONE_WEEK_AGO')}
                />
                <ItemRadio
                  keyRadio="time"
                  valueRadio="NineMonthsAgo"
                  textRadio={I18n.t('common.popup.NINE_MONTH_AGO')}
                />
              </div>
              <div className={style.tdItem}>
                <ItemRadio
                  keyRadio="time"
                  valueRadio="OneMonthAgo"
                  textRadio={I18n.t('common.popup.ONE_MONTH_AGO')}
                />
                <ItemRadio
                  keyRadio="time"
                  valueRadio="OneYearAgo"
                  textRadio={I18n.t('common.popup.ONE_YEAR_AGO')}
                />
              </div>
              <div className={style.tdItem}>
                <ItemRadio
                  keyRadio="time"
                  valueRadio="ThreeMonthsAgo"
                  textRadio={I18n.t('common.popup.THREE_MONTH_AGO')}
                />
                <ItemRadio
                  keyRadio="time"
                  valueRadio="TwoYearsAgo"
                  textRadio={I18n.t('common.popup.TWO_YEAR_AGO')}
                />
              </div>
            </div>
            <div className={style.tdItem}>
              <ItemRadio
                keyRadio="time"
                valueRadio="Specific"
                textRadio={I18n.t('common.popup.DATE')}
                renderContent={(isDisabled) => (
                  <RadioCalendar
                    keySelect="tradingDate"
                    disabled={isDisabled}
                    defaultValue={maxDate}
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                )}
              />
            </div>
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              submit={convertData}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

TradingData.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(TradingData)
