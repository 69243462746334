import { createAsyncThunk } from '@reduxjs/toolkit'
import SectorStatisticsOverviewService from '../../../../../../core/services/sector/sectorStatistics/OverviewService'

export const getMarketCap = createAsyncThunk(
  'sector/sectorStatistics/overview/marketCap/MARKET_CAP',
  async (params, { rejectWithValue }) => {
    const response = await SectorStatisticsOverviewService.getMarketCap(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
