import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import {
  selectChartSelected,
  setChartReplaceChecked,
  setChartSelected,
} from '../../common/dropdownMoreDashboard/store/slice'
import style from '../styleTheme.module.css'
import { PopupContent } from './PopupContent'
import PopupGroupButton from './PopupGroupButton'

export const TYPE_POPUP = {
  REPLACE_CHART: 'replace_chart',
  ADD_CHART: 'add_chart',
}

const PopupSelectChart = ({
  handleShow,
  typePopup,
  itemActive,
  chartTab,
  currentChartCount,
  maxChartCount,
}) => {
  const dispatch = useDispatch()
  const chartsSelected = useSelector(selectChartSelected)

  const onClose = () => {
    handleShow(false)
    dispatch(setChartSelected([]))
    dispatch(setChartReplaceChecked({}))
  }

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div
        className={`modal modal-small ${style.modal}`}
        style={{ width: 600 }}
      >
        <div className={`modal-title ${style.titleStyle}`}>
          <h3>
            <Span>
              {typePopup === TYPE_POPUP.ADD_CHART ? (
                <Translate value="financialChart.ADD_CHART" />
              ) : (
                <Translate value="financialChart.REPLACE_CHART" />
              )}
            </Span>
          </h3>
          <a onClick={onClose} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div
          className={`modal-content ${style.modalContentStyle}`}
          style={{ paddingTop: 8 }}
        >
          <PopupContent
            selectMultipleItems={typePopup === TYPE_POPUP.ADD_CHART}
          />
          {checkDisableAddChart(
            maxChartCount,
            chartsSelected.length,
            currentChartCount,
          ) ? (
            <div className="mt-8">
              <Span
                style={{
                  color: '#c48b09',
                  fontSize: 11,
                }}
              >
                <Translate value="financialChart.DB_IS_OUT_OF_SPACE" />
              </Span>
            </div>
          ) : null}
          <PopupGroupButton
            onClose={onClose}
            typePopup={typePopup}
            itemActive={itemActive}
            chartTab={chartTab}
            disableBtnSubmit={checkDisableAddChart(
              maxChartCount,
              chartsSelected.length,
              currentChartCount,
            )}
          />
        </div>
      </div>
    </Popup>
  )
}

PopupSelectChart.propTypes = {
  handleShow: PropTypes.func,
  typePopup: PropTypes.string,
}

export default PopupSelectChart

const checkDisableAddChart = (maxCount, addCount, currentCount) => {
  return addCount + currentCount > maxCount
}
