import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../../utils/Value'
import { TYPE_VAL_GROWTH } from '../exportStatisticTable/constants'
import {
  selectIsValOrGrowth,
  selectListItems,
} from '../exportStatisticTable/store/slice'
import style from '../index.module.css'
import { COLORS_LINE_CHART } from './constants'
import { selectActiveItem } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 40, bottom: 20 }

export const ChartComponent = ({ data, width, height, keyXAxis, lineKeys }) => {
  const listItems = useSelector(selectListItems)
  const activeItem = useSelector(selectActiveItem)
  const isValOrGrowth = useSelector(selectIsValOrGrowth)

  const exportTurnOverTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listItems.map((item) => (
              <tr className={style.trTable} key={item.id}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>{item.name}</Span>:
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{ fontSize: 11 }}
                    className={
                      valueTooltip[item.id] < 0 ? style.negativeValColor : ''
                    }
                  >
                    {formatVal(valueTooltip[item.id])}{' '}
                    {valueTooltip[item.id]
                      ? isValOrGrowth === TYPE_VAL_GROWTH.VAL
                        ? I18n.t('sector.sectorSpecific.fishery.MILLION_USD')
                        : I18n.t('sector.sectorSpecific.fishery.PERCENT')
                      : ''}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                label:
                  isValOrGrowth === TYPE_VAL_GROWTH.VAL
                    ? `(${I18n.t('sector.sectorSpecific.fishery.MILLION_USD')})`
                    : ``,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                unitYAxis: isValOrGrowth !== TYPE_VAL_GROWTH.VAL ? '%' : '',
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              exportTurnOverTooltip(payload, decimalLengths)
            }
            timeFrame={TIME_RANGES.CUSTOM}
            isNotFormatXAxis
          >
            {listItems.map((item) => (
              <Line
                key={item.id}
                yAxisId="left"
                dataKey={item.id}
                type="linear"
                stroke={COLORS_LINE_CHART[listItems.indexOf(item)]}
                dot={false}
                activeDot={false}
                strokeOpacity={
                  !activeItem ? 1 : activeItem === item.id ? 1 : 0.1
                }
                isAnimationActive={false}
                strokeWidth={
                  !activeItem ? 1.5 : activeItem === item.id ? 2.5 : 1.5
                }
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}
