import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { RADIO_EXPORT_TYPE } from '../constant'
import { changeFilterExportType, selectFilter } from '../store/slice'
import style from './style.module.css'

const RadioExportType = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const handleChangeFilterExportType = (e) => {
    dispatch(changeFilterExportType(e.target.value))
  }

  return (
    <div>
      <div className={style.titleListRadio}>
        <Span style={{ fontSize: 12 }}>
          <Translate value="tool.dataExplorer.tradingData.EXPORT_FILE" />
        </Span>
      </div>
      <ul className={`list-check ml-25 ${style.listCheck}`}>
        {RADIO_EXPORT_TYPE.map((item) => {
          return (
            <li key={item.value} className={style.pdRadio}>
              <label className="fs-12 align-center">
                <input
                  type="radio"
                  className={`radiobox radiobox2 ${style.radio}`}
                  name={item.name}
                  value={item.value}
                  checked={item.value === filter.exportType}
                  onChange={handleChangeFilterExportType}
                />
                <Span
                  style={{
                    color: item.value === filter.exportType ? '#2f9aee' : '',
                  }}
                >
                  <Translate value={item.title} />
                </Span>
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default RadioExportType
