import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import TextEllipsis from '../../../common/textEllipsis'
import { FEATURES } from '../../../constants/routes'
import { selectTopMoverValue } from '../store/slice'
import style from './style.module.css'

const CellNewsCustom = ({ val, rowId }) => {
  const navigate = useCustomNavigate()
  const publicDate = useSelector(selectTopMoverValue(rowId, 'publicDate'))
  const newsId = useSelector(selectTopMoverValue(rowId, 'newsId'))
  const refColTime = useRef()
  const PADDING_CELL = 8
  const GAP_ITEM = 10

  const onClick = () => {
    navigate(
      FEATURES.market.components['news-report-detail'].route +
        '?newsId=' +
        newsId,
    )
  }

  return (
    <td style={{ width: '100%', maxWidth: 0 }}>
      <div
        className="d-flex justify-content-space-between"
        style={{ gap: GAP_ITEM }}
      >
        <div
          className={style.textContentCellNews}
          style={{
            width: `calc(100% - ${
              refColTime.current?.offsetWidth + GAP_ITEM + PADDING_CELL
            }px)`,
          }}
          onClick={onClick}
        >
          <TextEllipsis isI18n={false} text={val} />
        </div>
        <div ref={refColTime}>
          <DateTimeSpan date={publicDate} />
        </div>
      </div>
    </td>
  )
}

export default CellNewsCustom
