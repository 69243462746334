export const overview = {
  //Valuation
  NAME: 'Tổng Quan',
  VALUATION: 'Định giá',
  LATEST_COMPANY_RATIO: 'Chỉ số DN mới nhất',
  LATEST_SECTOR_RATIO: 'Chỉ số ngành mới nhất',
  COMPANY_RATIO_RANGE: 'Khoảng dao động chỉ số DN',
  CURRENT_TOOLTIP:
    'P/E ở các năm trước được tính tại ngày GD cuối cùng của năm và P/E của năm nay được tính tại ngày GD gần nhất.',
  chart: {
    PE: {
      title: 'P/E',
      min: 'P/E Min',
      max: 'P/E Max',
      current: 'P/E hiện tại',
      endYear: 'P/E cuối năm',
      currentPeer: 'P/E ngành hiện tại',
      endYearPeer: 'P/E ngành cuối năm',
    },
    PB: {
      title: 'P/B',
      min: 'P/B Min',
      max: 'P/B Max',
      current: 'P/B hiện tại',
      endYear: 'P/B cuối năm',
      currentPeer: 'P/B ngành hiện tại',
      endYearPeer: 'P/B ngành cuối năm',
    },
    PS: {
      title: 'P/S',
      min: 'P/S Min',
      max: 'P/S Max',
      current: 'P/S hiện tại',
      endYear: 'P/S cuối năm',
      currentPeer: 'P/S ngành hiện tại',
      endYearPeer: 'P/S ngành cuối năm',
    },
    EVSALE: {
      title: 'EV/SALE',
      min: 'EV/SALE Min',
      max: 'EV/SALE Max',
      current: 'EV/SALE hiện tại',
      endYear: 'EV/SALE cuối năm',
      currentPeer: 'EV/SALE ngành hiện tại',
      endYearPeer: 'EV/SALE ngành cuối năm',
    },
  },
  //Peer Comparison
  PEERS_COMPARISON: 'So sánh cùng ngành',
  ADD_TICKER: 'Thêm mã',
  PERFORMANCE: 'Biến động giá',
  OWNERSHIP: 'Sở hữu',
  CREDIT: 'Tín dụng',
  ASSET_QUALITY: 'Chất lượng tài sản',
  PROFITABILITY: 'Khả năng sinh lợi',
  BALANCE_SHEET: 'Cân đối kế toán',
  INCOME_STATEMENT: 'Kết quả kinh doanh',
  exchanges: {
    ALL_EXCHANGE: 'Tất cả sàn',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
    OTC: 'OTC',
    PRIVATE: 'Private',
  },
  SELECT_SECTOR: 'Ngành',
  ALL_SECTOR: 'Tất cả ngành',
  SELECTED_COUNT: 'Đã chọn:',
  MAX_ITEMS_TEXT: 'Số lượng mã không vượt quá 100.',
  USE_WL: 'Danh mục của tôi',
  TITLE: 'Danh mục',
  DESCRIPTION: 'Mô tả',
  DELETE_ALL_TICKER: 'Xóa tất cả',
  DELETE_MESSAGE: 'Bạn muốn xóa <b><i>Tất cả mã</i></b>?',
  QUARTER_1: 'Quý 1',
  QUARTER_2: 'Quý 2',
  QUARTER_3: 'Quý 3',
  QUARTER_4: 'Quý 4',
  ANNUAL: 'Cả năm',
  VND: 'VND',
  MILLION_VND: 'Triệu VND',
  BILLION_VND: 'Tỷ VND',
  USD: 'USD',
  THOUSAND_USD: 'Nghìn USD',
  MILLION_USD: 'Triệu USD',
  table: {
    mean: 'Trung bình',
    delete: ' ',
    ticker: 'MÃ',
    companyName: 'TÊN CÔNG TY',
    marketCap: 'VỐN HÓA<p>(Tỷ VND)</p>',
    lastPrice: 'GIÁ ĐÓNG CỬA<p>(VND)</p>',
    //Performance
    percent1DPriceChange: 'THAY ĐỔI 1D<p>(%)</p>',
    percent1WPriceChange: 'THAY ĐỔI 1W<p>(%)</p>',
    percent1MPriceChange: 'THAY ĐỔI 1M<p>(%)</p>',
    percent3MPriceChange: 'THAY ĐỔI 3M<p>(%)</p>',
    percent6MPriceChange: 'THAY ĐỔI 6M<p>(%)</p>',
    percent9MPriceChange: 'THAY ĐỔI 9M<p>(%)</p>',
    percent1YPriceChange: 'THAY ĐỔI 1Y<p>(%)</p>',
    percentYTDPriceChange: 'THAY ĐỔI YTD<p>(%)</p>',
    //Valuation
    ev: 'EV<p>(Tỷ VND)</p>',
    evEbitda: 'EV/EBITDA',
    pS: 'P/S',
    eps: 'EPS',
    pE: 'P/E<p>&nbsp;</p>',
    bvps: 'BVPS<p>&nbsp;</p>',
    pB: 'P/B<p>&nbsp;</p>',
    dividendYield: 'LỢI SUẤT CỔ TỨC<p>(%)</p>',
    //Ownership
    stateHolding: 'SỞ HỮU NHÀ NƯỚC<p>(%)</p>',
    foreignHolding: 'SỞ HỮU NN<p>(%)</p>',
    otherHolding: 'SỞ HỮU KHÁC<p>(%)</p>',
    foreignRemainRoom: 'ROOM NN CÒN LẠI<p>(%)</p>',
    maximumForeignHolding: 'TỔNG ROOM NN ĐƯỢC PHÉP SH<p>(%)</p>',
    managementAffiliateHolding: 'SỞ HỮU BLĐ & NGƯỜI LIÊN QUAN<p>(%)</p>',
    institutionHolding: 'SỞ HỮU TỔ CHỨC<p>(%)</p>',
    //Credit
    fiinRating: 'FIIN<br />XẾP HẠNG',
    netDebtEquity: 'NỢ RÒNG/<br />VCSH',
    longDebtEquity: 'NỢ VAY DÀI HẠN/<br />VCSH',
    debtEquity: 'NỢ VAY/<br />VCSH',
    shortDebtEquity: 'NỢ VAY NGẮN HẠN/<br />VCSH',
    liabilitiesEquity: 'TỔNG NỢ/<br />VCSH',
    ebitdaInterestCorverage: 'TỶ LỆ BAO PHỦ EBITDA<br />TRÊN LÃI VAY',
    financialLeverage: 'ĐÒN BẨY<br />TÀI CHÍNH',
    equityLoans: 'VCSH/<br />DƯ NỢ CHO VAY',
    loanDepositRatio: 'TỶ LỆ DƯ NỢ/<br />TIỀN GỬI',
    nonperformingLoanRatio: 'TỶ LỆ NỢ XẤU',
    loanLossReservesNPLs: 'TRÍCH LẬP DỰ PHÒNG/<br />NỢ XẤU',
    loanLossReservesLoans: 'TRÍCH LẬP DỰ PHÒNG/<br />CHO VAY KHÁCH HÀNG',
    provisionOutstandingLoans: 'CHI PHÍ DPRR/<br />CHO VAY KHÁCH HÀNG',
    sPRating: 'S&P<br />XẾP HẠNG',
    moodyRating: "MOODY'S<br />XẾP HẠNG",
    fitchRanking: 'FITCH<br />XẾP HẠNG',
    //Profit
    revenueGrowth: 'TĂNG TRƯỞNG DOANH THU (TTM) YoY',
    grossMargin: 'BIÊN LN GỘP',
    netMargin: 'BIÊN LN RÒNG',
    ebitdaMargin: 'BIÊN LN EBITDA',
    nim: 'NIM',
    pretaxProfitMargin: 'BIÊN LN TRƯỚC THUẾ',
    roa: 'ROA',
    roe: 'ROE',
    roic: 'ROIC',
  },
  ALL: 'Tất cả',
  MY_WATCHLIST: 'Danh mục của tôi',
  DATA_CALCULATE_NOTE: 'Dữ liệu được tính toán vào',
}

export const consensus = {
  NAME: 'Khuyến Nghị',
  CONSENSUS_HISTORY: 'KHUYẾN NGHỊ TỪ CTCK',
  CONSENSUS_TARGET_PRICE: 'GIÁ MỤC TIÊU KHUYẾN NGHỊ',
  RECOMMENDATION: 'KHUYẾN NGHỊ',
  BUY_PERCENTAGE: 'Mua',
  SELL_PERCENTAGE: 'Bán',
  HOLD_PERCENTAGE: 'Nắm giữ',
  NEUTRAL_PERCENTAGE: 'Trung lập',
  NO_RECOMMENDATION_PERCENTAGE: 'Không khuyến nghị',
  ACTUAL_PRICE: 'Giá thực tế',
  TARGET_PRICE: 'Giá mục tiêu',
  TICKER: 'MÃ',
  DATE: 'NGÀY KHUYẾN NGHỊ',
  REPORT: 'BÁO CÁO',
  RECOMMENDATION_TYPE_NAME: 'KHUYẾN NGHỊ',
  PRICE_TARGET: 'GIÁ MỤC TIÊU',
  DFCP: '% THAY ĐỔI GIÁ TỪ\nNGÀY RA BÁO CÁO',
  DOWNLOAD_REPORT: 'TẢI XUỐNG',
  CHART_THOUSAND_VND: 'Nghìn VND',
  LATEST_RECOMMEND: 'Khuyến nghị mới nhất:',
  BUY: 'Mua*',
  SELL: 'Bán*',
  HOLD: 'Nắm giữ*',
  NEUTRAL: 'Trung lập*',
  NO_RECOMMENDATION: 'Không khuyến nghị*',
  HIGHEST: 'Cao nhất',
  AVERAGE: 'Trung bình',
  LOWEST: 'Thấp nhất',
  UPSIDE: 'Tăng giá',
  DOWNSIDE: 'Giảm giá',
  CHART_UNIT_THOUSAND: 'Giá (Nghìn VND)',
  DISCLAIMER:
    '*Điều khoản miễn trách: Khuyến nghị này được tính toán tự động trên FiinProX dựa trên các báo cáo công bố công khai, dữ liệu phát triển nội bộ và các nguồn khác được cho là đáng tin cậy, nhưng chưa được kiểm chứng độc lập bởi FiinGroup. FiinGroup sẽ không đại diện hoặc đảm bảo đối với tính chính xác, đúng đắn và đầy đủ của những thông tin trong tính năng này. Khuyến nghị này chỉ nhằm mục đích cung cấp thông tin cho các khách hàng sử dụng FiinProX, và không phải là một lời đề nghị hoặc mời chào mua hoặc bán bất kỳ mã chứng khoán nào được đề cập ở đây. Các diễn biến trong quá khứ, nếu có, không hàm ý cho những kết quả tương lai. Nhà đầu tư phải có quyết định đầu tư dựa trên những ý kiến độc lập tùy theo tình trạng tài chính hay mục tiêu đầu tư cụ thể.',
}
