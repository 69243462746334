import OverviewProxy from '../../../proxies/economy/importExport/OverviewProxy'
import { ServiceBase } from '../../ServiceBase'

class OverviewService extends ServiceBase {
  getBalanceOfTrade(params) {
    return this.getData(() => OverviewProxy.getBalanceOfTrade(params))
  }

  getImportExportAndTradeBalance(params) {
    return this.getData(() =>
      OverviewProxy.getImportExportAndTradeBalance(params),
    )
  }

  getGrowthTradeBalance(params) {
    return this.getData(() => OverviewProxy.getGrowthTradeBalance(params))
  }

  getTopTradeGood(params) {
    return this.getData(() => OverviewProxy.getTopTradeGood(params))
  }

  getTopTradePartner(params) {
    return this.getData(() => OverviewProxy.getTopTradePartner(params))
  }

  getDownloadTradeBalance(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getDownloadTradeBalance(params),
    )
  }

  getIdsMapping(params) {
    return this.getData(() => OverviewProxy.getIdsMapping(params))
  }

  getLocationName(params) {
    return this.getData(() => OverviewProxy.getLocationName(params))
  }

  getTradeAgreementLocationName(params) {
    return this.getData(() =>
      OverviewProxy.getTradeAgreementLocationName(params),
    )
  }

  getImportByProduct(params) {
    return this.getData(() => OverviewProxy.getImportByProduct(params))
  }

  getImportChangeTurnoverByProduct(params) {
    return this.getData(() =>
      OverviewProxy.getImportChangeTurnoverByProduct(params),
    )
  }

  getImportContributeByProduct(params) {
    return this.getData(() =>
      OverviewProxy.getImportContributeByProduct(params),
    )
  }

  getImportGrowthByProduct(params) {
    return this.getData(() => OverviewProxy.getImportGrowthByProduct(params))
  }

  getImportDownloadByProduct(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getImportDownloadByProduct(params),
    )
  }

  getExportByProduct(params) {
    return this.getData(() => OverviewProxy.getExportByProduct(params))
  }

  getExportChangeTurnoverByProduct(params) {
    return this.getData(() =>
      OverviewProxy.getExportChangeTurnoverByProduct(params),
    )
  }

  getExportContributeByProduct(params) {
    return this.getData(() =>
      OverviewProxy.getExportContributeByProduct(params),
    )
  }

  getExportGrowthByProduct(params) {
    return this.getData(() => OverviewProxy.getExportGrowthByProduct(params))
  }

  getExportDownloadByProduct(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getExportDownloadByProduct(params),
    )
  }

  getImportByLocation(params) {
    return this.getData(() => OverviewProxy.getImportByLocation(params))
  }

  getImportTradeAgreementsByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getImportTradeAgreementsByLocation(params),
    )
  }

  getImportChangeTurnoverByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getImportChangeTurnoverByLocation(params),
    )
  }

  getImportTradeAgreementsChangeTurnoverByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getImportTradeAgreementsChangeTurnoverByLocation(params),
    )
  }

  getImportContributeByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getImportContributeByLocation(params),
    )
  }

  getImportDownloadByLocation(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getImportDownloadByLocation(params),
    )
  }

  getImportTradeAgreementsDownloadByLocation(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getImportTradeAgreementsDownloadByLocation(params),
    )
  }

  getExportByLocation(params) {
    return this.getData(() => OverviewProxy.getExportByLocation(params))
  }

  getExportTradeAgreementsByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getExportTradeAgreementsByLocation(params),
    )
  }

  getExportChangeTurnoverByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getExportChangeTurnoverByLocation(params),
    )
  }

  getExportTradeAgreementsChangeTurnoverByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getExportTradeAgreementsChangeTurnoverByLocation(params),
    )
  }

  getExportContributeByLocation(params) {
    return this.getData(() =>
      OverviewProxy.getExportContributeByLocation(params),
    )
  }

  getExportDownloadByLocation(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getExportDownloadByLocation(params),
    )
  }

  getExportTradeAgreementsDownloadByLocation(params) {
    return this.getFileDownload(() =>
      OverviewProxy.getExportTradeAgreementsDownloadByLocation(params),
    )
  }

  getMaxDateOverview(params) {
    return this.getData(() => OverviewProxy.getMaxDateOverview(params))
  }

  getMaxDateDetail(params) {
    return this.getData(() => OverviewProxy.getMaxDateDetail(params))
  }
}

export default new OverviewService()
