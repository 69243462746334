export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.DATE',
  },
  {
    key: 'unmatchedBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.UNMATCHED_BUY',
  },
  {
    key: 'unmatchedSellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.UNMATCHED_SELL',
  },
  {
    key: 'buyOrders',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.BUY_ORDERS',
  },
  {
    key: 'buyVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.BUY_VOLUME',
  },
  {
    key: 'avgBuyVolumePerOrder',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.AVG_BUY',
  },
  {
    key: 'sellOrders',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.SELL_ORDERS',
  },
  {
    key: 'sellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.SELL_VOLUME',
  },
  {
    key: 'avgSellVolumePerOrder',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.AVG_SELL',
  },
  {
    key: 'netBuySellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.NET_BUY_SELL',
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.DATE',
  },
  {
    key: 'cwCode',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.TICKER',
  },
  {
    key: 'unmatchedBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.UNMATCHED_BUY',
  },
  {
    key: 'unmatchedSellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.UNMATCHED_SELL',
  },
  {
    key: 'buyOrders',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.BUY_ORDERS',
  },
  {
    key: 'buyVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.BUY_VOLUME',
  },
  {
    key: 'avgBuyVolumePerOrder',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.AVG_BUY',
  },
  {
    key: 'sellOrders',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.SELL_ORDERS',
  },
  {
    key: 'sellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.SELL_VOLUME',
  },
  {
    key: 'avgSellVolumePerOrder',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.AVG_SELL',
  },
  {
    key: 'netBuySellVolume',
    title: 'market.coveredWarrant.cwStatistics.orderStatistics.NET_BUY_SELL',
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.coveredWarrant.cwStatistics.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.coveredWarrant.cwStatistics.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
