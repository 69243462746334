import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class IndexStatisticProxy extends ProxyBase {
  getIndexStatisticsByInvestorOverview(params) {
    return this.get('GetIndexStatisticsByInvestorOverview', params)
  }

  getIndexStatisticsByInvestorDetail(params) {
    return this.get('GetIndexStatisticsByInvestorDetail', params)
  }

  downloadIndexStatisticsByInvestorOverview(params) {
    return this.get(
      'DownloadIndexStatisticsByInvestorOverview',
      params,
      'download',
    )
  }

  downloadIndexStatisticsByInvestorDetail(params) {
    return this.get(
      'DownloadIndexStatisticsByInvestorDetail',
      params,
      'download',
    )
  }

  getIndexOrderStatisticsOverview(params) {
    return this.get('GetIndexOrderStatisticsOverview', params)
  }

  getIndexOrderStatisticsDetail(params) {
    return this.get('GetIndexOrderStatisticsDetail', params)
  }

  downloadIndexOrderStatisticsOverview(params) {
    return this.get('DownloadIndexOrderStatisticsOverview', params, 'download')
  }

  downloadIndexOrderStatisticsDetail(params) {
    return this.get('DownloadIndexOrderStatisticsDetail', params, 'download')
  }

  getIndexStatisticsOverviewOverview(params) {
    return this.get('GetIndexStatisticsOverview', params)
  }

  getIndexStatisticsOverviewDetail(params) {
    return this.get('GetIndexStatisticsOverviewDetail', params)
  }

  downloadStatisticsOverviewOverview(params) {
    return this.get('DownloadStatisticsOverview', params, 'download')
  }

  downloadStatisticsOverviewDetail(params) {
    return this.get('DownloadStatisticsOverviewDetail', params, 'download')
  }

  getIndexStatisticsForeignOverview(params) {
    return this.get('GetForeignIndex', params)
  }

  getIndexStatisticsForeignDetail(params) {
    return this.get('GetForeignTickers', params)
  }

  downloadStatisticsForeignOverview(params) {
    return this.get('DownloadForeignIndex', params, 'download')
  }

  downloadStatisticsForeignDetail(params) {
    return this.get('DownloadForeignTickers', params, 'download')
  }

  getProprietaryOverview(params) {
    return this.get('IndexStatistic/ProprieataryOverview', params)
  }

  getProprietaryDetail(params) {
    return this.get('IndexStatistic/ProprieataryDetail', params)
  }

  downloadProprietaryOverview(params) {
    return this.get(
      'IndexStatistic/DownloadProprieataryOverview',
      params,
      'download',
    )
  }

  downloadProprietaryDetail(params) {
    return this.get(
      'IndexStatistic/DownloadProprieataryDetail',
      params,
      'download',
    )
  }

  getStatisticsValueByInvestorChart(params) {
    return this.get('GetStatisticsValueByInvestorChart', params)
  }

  getStatisticsNetTradingValueIndividualChart(params) {
    return this.get('GetStatisticsNetTradingValue_IndividualChart', params)
  }

  getStatisticsNetTradingValueForeignChart(params) {
    return this.get('GetStatisticsNetTradingValue_ForeignChart', params)
  }

  getStatisticsNetTradingValueInstitutionChart(params) {
    return this.get('GetStatisticsNetTradingValue_InstitutionChart', params)
  }

  getStatisticsNetTradingValueProprietaryChart(params) {
    return this.get('GetStatisticsNetTradingValue_ProprietaryChart', params)
  }

  getIndexStatisticsByInvestorOverviewTotalAvg(params) {
    return this.get('GetIndexStatisticsByInvestorOverviewTotalAvg', params)
  }

  getIndexStatisticsByInvestorDetailTotalAvg(params) {
    return this.get('GetIndexStatisticsByInvestorDetailTotalAvg', params)
  }

  getIndexStatisticsOverviewTotal(params) {
    return this.get('IndexStatistic/OverviewTotal', params)
  }

  getIndexStatisticsOverviewDetailTotal(params) {
    return this.get('IndexStatistic/Overview/DetailTotal', params)
  }

  getIndexStatisticsOrderStatisticsOverviewTotal(params) {
    return this.get('IndexStatistic/OrderStatistics/OverviewTotal', params)
  }

  getIndexStatisticsOrderStatisticsDetailTotal(params) {
    return this.get('IndexStatistic/OrderStatistics/DetailTotal', params)
  }

  getIndexStatisticsProprietaryOverviewTotal(params) {
    return this.get('IndexStatistic/ProprieataryOverviewTotal', params)
  }

  getIndexStatisticsProprietaryDetailTotal(params) {
    return this.get('IndexStatistic/Proprietary/DetailTotal', params)
  }

  getIndexStatisticsForeignOverviewTotal(params) {
    return this.get('IndexStatistic/ForeignOverviewTotal', params)
  }

  getIndexStatisticsForeignDetailTotal(params) {
    return this.get('IndexStatistic/Foreign/DetailTotal', params)
  }
}

export default new IndexStatisticProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
