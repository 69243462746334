import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getMaxDate } from '../../averageTradingValue/store/thunk'
import {
  getDataByQuarterAndYear,
  getQuarterAndYearInDataRange,
  getQuarterAndYearKey,
} from '../../common/helper'
import { GROUP_COLUMNS, TOTAL_ID } from '../constants'
import { sortObjByKey } from '../helper'
import {
  getMarginGrowth,
  getOrganizationsInfo,
  getOrganizationsListPopup,
  getTopCompany,
} from './thunk'

const NOT_QUARTER_CONST = 5

const slice = createSlice({
  name: 'market/marginLoans/marginGrowth',
  initialState: {
    isLoading: true,
    isListOrganizationsLoading: true,
    isWatchListLoading: true,
    isDefaultLoading: {
      isListOrgLoading: true,
      isTopCompanyLoading: true,
    },
    data: [],
    dataById: {},
    ids: [],
    initialIds: [],
    maxDate: {
      year: null,
      quarter: null,
    },
    filter: {
      currentYear: null,
      currentQuarter: null,
    },
    topCompanyId: [],
    listOrganizations: [],
    groupColumns: [],
    currentCompaniesIds: [],
    isAllTab: true,
    listOrganizationsPopup: [],
    filteredListOrganizationsPopup: [],
    isAllTickersSelected: false,
  },
  reducers: {
    changeCurrentYear: (state, action) => {
      state.filter.currentYear = action.payload
    },
    changeCurrentQuarter: (state, action) => {
      state.filter.currentQuarter = action.payload
    },
    changeCurrentCompaniesIds: (state, action) => {
      state.currentCompaniesIds = action.payload
    },
    changeIsAllTab: (state, action) => {
      state.isAllTab = action.payload
    },
    changeListOrganizationsPopup: (state, action) => {
      state.filteredListOrganizationsPopup = action.payload
    },
    selectAllTickers: (state, action) => {
      state.isAllTickersSelected = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
        2,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarginGrowth.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getMarginGrowth.fulfilled, (state, action) => {
      state.isLoading = false

      const isYearly = state.filter.currentQuarter === NOT_QUARTER_CONST

      const payloadData = isYearly
        ? action.payload?.map((item) => ({
            ...item,
            quarter: item.realQuarter,
            year: item.realYear,
            id: item.organizationId === -1 ? TOTAL_ID : item.organizationId,
            value: item.nS239,
            marEquityValue: item.marEquity,
            name: item.organizationShortName,
          })) || []
        : action.payload
            ?.filter((item) => item.realQuarter !== NOT_QUARTER_CONST)
            .map((item) => ({
              ...item,
              quarter: item.realQuarter,
              year: item.realYear,
              id: item.organizationId === -1 ? TOTAL_ID : item.organizationId,
              value: item.nS239,
              marEquityValue: item.marEquity,
              name: item.organizationShortName,
            })) || []

      state.origData = payloadData
      const convertedData = convertData(payloadData)

      const getTimeGroupColumns = getQuarterAndYearInDataRange(payloadData).map(
        (item) => getQuarterAndYearKey(item.quarter, item.year),
      )

      const groupColumns = [
        ...GROUP_COLUMNS.slice(0, 4),
        ...getTimeGroupColumns.map((item) => ({
          key: item,
          title: item,
        })),
        GROUP_COLUMNS[4],
        ...getTimeGroupColumns.map((item, index) => ({
          key: `marEquity_${item}`,
          title: index ? item : item,
        })),
      ]

      const allIds = [...new Set(payloadData.map((item) => item.id))]
      const totalAndOtherIds = allIds.slice(0, 2)
      const sortedOrganizationIdsList = sortObjByKey(
        convertedData.slice(2, allIds.length),
        getTimeGroupColumns[0],
      )?.map((item) => item.id)

      const ids = [
        ...sortedOrganizationIdsList,
        totalAndOtherIds[1],
        totalAndOtherIds[0],
      ]

      if (state.isAllTickersSelected) {
        const convertedDataWithoutOther = convertedData.filter(
          (item) => item.id !== 0,
        )
        state.data = convertedDataWithoutOther
        state.dataById = keyBy(convertedDataWithoutOther, 'id')
        state.ids = state.initialIds = ids.filter((item) => item !== 0)
      } else if (
        ids.includes(0) &&
        ids.includes(TOTAL_ID) &&
        ids.length === 2
      ) {
        const totalData = convertData(
          payloadData.filter((item) => item.organizationId === -1),
        )
        state.data = [{ ...totalData }]
        state.dataById = { total: totalData.length ? totalData[0] : {} }
        state.ids = state.initialIds = [TOTAL_ID]
      } else {
        state.data = convertedData
        state.dataById = keyBy(convertedData, 'id')
        state.ids = state.initialIds = ids
      }

      state.groupColumns = groupColumns
    })
    builder.addCase(getMarginGrowth.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getTopCompany.pending, (state) => {
      state.topCompanyId = []
      state.isDefaultLoading.isTopCompanyLoading = true
    })
    builder.addCase(getTopCompany.fulfilled, (state, action) => {
      state.topCompanyId =
        action.payload?.map((item) => item.organizationId) || []
      state.isDefaultLoading.isTopCompanyLoading = false
    })
    builder.addCase(getTopCompany.rejected, (state) => {
      state.topCompanyId = []
      state.isDefaultLoading.isTopCompanyLoading = false
    })

    builder.addCase(getOrganizationsInfo.pending, (state) => {
      state.listOrganizations = []
      state.isDefaultLoading.isListOrgLoading = true
    })
    builder.addCase(getOrganizationsInfo.fulfilled, (state, action) => {
      state.listOrganizations = action.payload || []
      state.isDefaultLoading.isListOrgLoading = false
    })
    builder.addCase(getOrganizationsInfo.rejected, (state) => {
      state.listOrganizations = []
      state.isDefaultLoading.isListOrgLoading = false
    })

    builder.addCase(getOrganizationsListPopup.pending, (state) => {
      state.listOrganizationsPopup = []
      state.isListOrganizationsLoading = true
    })
    builder.addCase(getOrganizationsListPopup.fulfilled, (state, action) => {
      state.listOrganizationsPopup = action.payload || []
      state.filteredListOrganizationsPopup = action.payload || []
      state.isListOrganizationsLoading = false
    })
    builder.addCase(getOrganizationsListPopup.rejected, (state, action) => {
      state.listOrganizationsPopup = []
      state.isListOrganizationsLoading = action.payload.loading
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.maxDate = {}
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.maxDate.quarter = action.payload?.realQuarter
      state.maxDate.year = action.payload?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.maxDate = {}
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectData = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectFilter = (state) => state[slice.name].filter
export const selectIds = (state) => state[slice.name].ids
export const selectMaxDate = (state) => state[slice.name].maxDate
export const selectDataById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListOrganizations = (state) =>
  state[slice.name].listOrganizations
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectTopCompanyId = (state) => state[slice.name].topCompanyId
export const selectCurrentCompaniesIds = (state) =>
  state[slice.name].currentCompaniesIds
export const selectListOrganizationsLoading = (state) =>
  state[slice.name].isListOrganizationsLoading
export const selectIsAllTab = (state) => state[slice.name].isAllTab
export const selectWatchlist = (state) => state[slice.name].watchlist
export const selectListOrganizationsPopup = (state) =>
  state[slice.name].listOrganizationsPopup
export const selectFilteredListOrganizationsPopup = (state) =>
  state[slice.name].filteredListOrganizationsPopup
export const selectDefaultLoading = (state) =>
  state[slice.name].isDefaultLoading

export const {
  changeCurrentQuarter,
  changeCurrentYear,
  changeCurrentCompaniesIds,
  changeIsAllTab,
  changeIds,
  changeListOrganizationsPopup,
  selectAllTickers,
  sort,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data) => {
  const uniqueItems = Object.values(
    data
      .map((item) => ({ id: item.id, ticker: item.ticker, name: item.name }))
      .reduce((acc, curr) => {
        acc[curr.id + '|' + curr.ticker] = curr
        return acc
      }, {}),
  )

  const getDataKeyFromRange = getQuarterAndYearInDataRange(data).map((item) =>
    getQuarterAndYearKey(item.quarter, item.year),
  )
  const getDataValueFromRange = getQuarterAndYearInDataRange(data).map(
    (item) => [...getDataByQuarterAndYear(data, item.quarter, item.year)],
  )

  const getMarEquityDataKeyFromRange = getDataKeyFromRange.map(
    (item) => `marEquity_${item}`,
  )

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getCorrespondingMarEquityDataDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.marEquityValue ??
      EMPTY_VALUE
    )
  }

  //put values of all date keys into original data
  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  const getMarEquityKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getMarEquityDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingMarEquityDataDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return uniqueItems.map((item) => ({
    ...item,
    ...getKeyAndValRange(item),
    ...getMarEquityKeyAndValRange(item),
  }))
}
