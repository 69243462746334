import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Col from '../../common/Col'
import Row from '../../common/Row'
import TableData from '../../common/TableData'
import ToiTableHead from '../../common/ToiTableHead'
import { TYPE_CONFIG } from '../../configs'
import { mappingData } from '../../helper'
import style from '../../index.module.css'
import { selectChoose, selectIncomeStructure } from '../../store/slice'
import { selectHeaderChildrenTable } from '../store/slice'

const HeaderChildrenTable = () => {
  const { GR } = TYPE_CONFIG
  const [mappingIncomeStructure, setMappingIncomeStructure] = useState([])

  const headerChildrenTableData = useSelector(selectHeaderChildrenTable)
  const incomeStructureData = useSelector(selectIncomeStructure)
  const choose = useSelector(selectChoose)

  useEffect(() => {
    if (headerChildrenTableData.length && incomeStructureData.length) {
      setMappingIncomeStructure(
        mappingData(headerChildrenTableData, incomeStructureData),
      )
    }
  }, [headerChildrenTableData, incomeStructureData])

  return (
    <>
      {mappingIncomeStructure.length > 0 &&
        mappingIncomeStructure.map((row, index) => {
          const cols = row.map((col) => {
            if (col?.type === GR) {
              return (
                <ToiTableHead
                  key={col?.indicatorMappingId}
                  colSpan={3}
                  isCollapse={col?.expandable}
                  isActive
                  styleProps={{
                    cursor: col?.expandable ? 'pointer' : 'default',
                  }}
                >
                  {col?.colOrder === 0 ? (
                    <div className={style.outlineLeft} />
                  ) : (
                    <div className={style.outlineRight} />
                  )}
                  {col?.indicator}
                </ToiTableHead>
              )
            } else {
              return (
                <Col
                  key={col?.indicatorMappingId}
                  colSpan={3}
                  id={col?.alias}
                  isChoose={choose === col?.fieldName}
                  className={style.colSticky}
                  chart1Type={col?.chart1Type}
                  chart2Type={col?.chart2Type}
                  fieldName={col?.fieldName}
                  fieldName_1={col?.fieldName_1}
                  indicator={col?.indicator}
                  relativeIndicator={col?.relativeIndicator}
                  alias={col?.alias}
                  valueFormat={col?.format}
                >
                  {col?.colOrder === 0 && choose !== col?.fieldName ? (
                    <div className={style.outlineLeft} />
                  ) : (
                    <div className={style.outlineRight} />
                  )}
                  <TableData
                    alias={col?.alias}
                    parentData={col?.value}
                    childrenData={col?.relativeValue}
                    afterChildrenData={
                      col?.relativeIndicator === '%YoY' ? 'YoY' : ''
                    }
                    relativeField={col?.relativeField}
                    valueFormat={col?.format}
                    multiplier={col?.multiplier}
                  />
                </Col>
              )
            }
          })
          return (
            <Row key={index} className={`${style.borderBottomRowHeader}`}>
              {cols.map((col) => col)}
            </Row>
          )
        })}
    </>
  )
}

export default HeaderChildrenTable
