import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { languageObjs } from '../../../../configs/Language'
import { range } from '../../../utils/Common'
import { FORMAT, formatDateTime, getQuarter } from '../../../utils/Datetime'
import { enMonthsLowerCase } from '../../calendar/helper'
import UseTimeZone from '../../hooks/useTimeZone'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import CalendarSelect from './common/CalendarSelect'
import ItemPeriod from './common/ItemPeriod'
import ItemRadio from './common/ItemRadio'
import ItemSelect from './common/ItemSelect'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import YearFromTo from './common/YearFromTo'
import style from './index.module.css'
import { selectValueDetailPopupIndicator } from './store/slice'

export const nullValue = 'null'

const SettingEconomy = ({
  handleSubmit,
  handleCancel,
  maxDate,
  defaultDetail,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const period = useSelector(selectValueDetailPopupIndicator('period'))

  const timezone = UseTimeZone()

  useAddDefaultDetail(defaultDetail)

  const arrMonth = locale === languageObjs.en ? enMonthsLowerCase : range(1, 12)
  const thisYear = new Date().getFullYear()
  const thisWeek = +formatDateTime(
    new Date(),
    FORMAT.ONLY_WEEK,
    languageObjs.en,
    timezone,
  )

  const weeks = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 53).map((week) => {
      return { value: week, name: week }
    }),
  )
  const months = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 12).map((month, index) => {
      return { value: month, name: arrMonth[index] }
    }),
  )
  const quarters = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 4).map((quarter) => {
      return { value: quarter, name: quarter }
    }),
  )
  const years = range(2000, thisYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  const submit = (data) => handleSubmit(data.detail)

  const renderSingle = () => {
    switch (period) {
      case 'daily':
        return (
          <div className="d-flex ali-center">
            <div className={style.labelTable}>
              <Span style={{ fontSize: 11 }}>
                {I18n.t('tool.dataExplorer.DATE')}
              </Span>
            </div>
            <ItemPeriod typeActive="single" periodActive="daily">
              {(disabled) => (
                <CalendarSelect
                  disabled={disabled}
                  keySelect="single.daily.fromDate"
                  mostRecentDate={maxDate}
                  defaultValue={maxDate}
                />
              )}
            </ItemPeriod>
          </div>
        )
      case 'weekly':
        return (
          <div className="h-100 d-flex ali-top">
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.WEEK')}
                </Span>
              </div>
              <ItemPeriod typeActive="single" periodActive="weekly">
                {(disabled) => (
                  <>
                    <div className={`${style.selectItem} ${style.mr28}`}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.weekly.week"
                        selectData={weeks}
                        defaultValue={thisWeek}
                      />
                    </div>
                    <div className={style.selectItem}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.weekly.fromYear"
                        selectData={years}
                        defaultValue={thisYear}
                      />
                    </div>
                  </>
                )}
              </ItemPeriod>
            </div>
          </div>
        )
      case 'monthly':
        return (
          <div className="h-100 d-flex ali-top">
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.MONTH')}
                </Span>
              </div>
              <ItemPeriod typeActive="single" periodActive="monthly">
                {(disabled) => (
                  <>
                    <div className={`${style.selectItem} ${style.mr28}`}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.monthly.month"
                        selectData={months}
                        defaultValue={new Date().getMonth() + 1}
                      />
                    </div>
                    <div className={style.selectItem}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.monthly.fromYear"
                        selectData={years}
                        defaultValue={thisYear}
                      />
                    </div>
                  </>
                )}
              </ItemPeriod>
            </div>
          </div>
        )
      case 'quarterly':
        return (
          <div className="h-100 d-flex ali-top">
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.QUARTER')}
                </Span>
              </div>
              <ItemPeriod typeActive="single" periodActive="quarterly">
                {(disabled) => (
                  <>
                    <div className={`${style.selectItem} ${style.mr28}`}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.quarterly.quarter"
                        selectData={quarters}
                        defaultValue={getQuarter()}
                      />
                    </div>
                    <div className={style.selectItem}>
                      <ItemSelect
                        disabled={disabled}
                        keySelect="single.quarterly.fromYear"
                        selectData={years}
                        defaultValue={thisYear}
                      />
                    </div>
                  </>
                )}
              </ItemPeriod>
            </div>
          </div>
        )
      case 'yearly':
        return (
          <div className="d-flex ali-center">
            <div className={style.labelTable}>
              <Span style={{ fontSize: 11 }}>
                {I18n.t('tool.dataExplorer.YEAR')}
              </Span>
            </div>
            <ItemPeriod typeActive="single" periodActive="yearly">
              {(disabled) => (
                <div className={style.selectItem}>
                  <ItemSelect
                    disabled={disabled}
                    keySelect="single.yearly.fromYear"
                    selectData={years}
                    defaultValue={thisYear}
                  />
                </div>
              )}
            </ItemPeriod>
          </div>
        )
      default:
        return ''
    }
  }

  const renderMultiple = () => {
    switch (period) {
      case 'daily':
        return (
          <>
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.FROM')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="daily">
                {(disabled) => (
                  <CalendarSelect
                    disabled={disabled}
                    keySelect="multiple.daily.fromDate"
                    keyMax="multiple.daily.toDate"
                    mostRecentDate={maxDate}
                    defaultValue={new Date(maxDate).setMonth(
                      new Date(maxDate).getMonth() - 3,
                    )}
                  />
                )}
              </ItemPeriod>
            </div>
            <div className="d-flex ali-center mt-8">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.TO')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="daily">
                {(disabled) => (
                  <CalendarSelect
                    disabled={disabled}
                    keySelect="multiple.daily.toDate"
                    keyMin="multiple.daily.fromDate"
                    mostRecentDate={maxDate}
                    defaultValue={maxDate}
                  />
                )}
              </ItemPeriod>
            </div>
          </>
        )
      case 'weekly':
        return (
          <>
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.FROM')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="weekly">
                {(disabled) => (
                  <YearFromTo
                    keyFrom="multiple.weekly.fromYear"
                    keyTo="multiple.weekly.toYear"
                    disabled={disabled}
                    maxYear={new Date(maxDate).getFullYear()}
                    appendStyleInput={{ width: '92px' }}
                    appendStyleTextTo={{
                      width: '32px',
                      fontSize: 11,
                      opacity: 0.4,
                    }}
                  />
                )}
              </ItemPeriod>
            </div>
            <div className="d-flex ali-center mt-8">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.WEEK')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="weekly">
                {(disabled) => (
                  <div className="w-100">
                    <ItemSelect
                      disabled={disabled}
                      keySelect="multiple.weekly.week"
                      selectData={weeks}
                      defaultValue={nullValue}
                    />
                  </div>
                )}
              </ItemPeriod>
            </div>
          </>
        )
      case 'monthly':
        return (
          <>
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.FROM')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="monthly">
                {(disabled) => (
                  <YearFromTo
                    keyFrom="multiple.monthly.fromYear"
                    keyTo="multiple.monthly.toYear"
                    disabled={disabled}
                    maxYear={new Date(maxDate).getFullYear()}
                    appendStyleInput={{ width: '92px' }}
                    appendStyleTextTo={{
                      width: '32px',
                      fontSize: 11,
                      opacity: 0.4,
                    }}
                  />
                )}
              </ItemPeriod>
            </div>
            <div className="d-flex ali-center mt-8">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.MONTH')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="monthly">
                {(disabled) => (
                  <div className="w-100">
                    <ItemSelect
                      disabled={disabled}
                      keySelect="multiple.monthly.month"
                      selectData={months}
                      defaultValue={nullValue}
                    />
                  </div>
                )}
              </ItemPeriod>
            </div>
          </>
        )
      case 'quarterly':
        return (
          <>
            <div className="d-flex ali-center">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.FROM')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="quarterly">
                {(disabled) => (
                  <YearFromTo
                    keyFrom="multiple.quarterly.fromYear"
                    keyTo="multiple.quarterly.toYear"
                    disabled={disabled}
                    maxYear={new Date(maxDate).getFullYear()}
                    appendStyleInput={{ width: '92px' }}
                    appendStyleTextTo={{
                      width: '32px',
                      fontSize: 11,
                      opacity: 0.4,
                    }}
                  />
                )}
              </ItemPeriod>
            </div>
            <div className="d-flex ali-center mt-8">
              <div className={style.labelTable}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t('tool.dataExplorer.QUARTER')}
                </Span>
              </div>
              <ItemPeriod typeActive="multiple" periodActive="quarterly">
                {(disabled) => (
                  <div className="w-100">
                    <ItemSelect
                      disabled={disabled}
                      keySelect="multiple.quarterly.quarter"
                      selectData={quarters}
                      defaultValue={nullValue}
                    />
                  </div>
                )}
              </ItemPeriod>
            </div>
          </>
        )
      case 'yearly':
        return (
          <div className="d-flex ali-center">
            <div className={style.labelTable}>
              <Span style={{ fontSize: 11 }}>
                {I18n.t('tool.dataExplorer.FROM')}
              </Span>
            </div>
            <ItemPeriod typeActive="multiple" periodActive="yearly">
              {(disabled) => (
                <YearFromTo
                  keyFrom="multiple.yearly.fromYear"
                  keyTo="multiple.yearly.toYear"
                  disabled={disabled}
                  maxYear={new Date(maxDate).getFullYear()}
                  appendStyleInput={{ width: '92px' }}
                  appendStyleTextTo={{
                    width: '32px',
                    fontSize: 11,
                    opacity: 0.4,
                  }}
                />
              )}
            </ItemPeriod>
          </div>
        )
      default:
        return ''
    }
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.dataExplorer.SETTING" />
            </Span>
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentEconomy}`}>
          <div className={`d-flex j-b ${style.mb16}`}>
            <ItemRadio
              keyRadio="period"
              valueRadio="daily"
              textRadio={I18n.t('tool.dataExplorer.DAILY')}
              isOrange
              defaultCheck
            />
            <ItemRadio
              keyRadio="period"
              valueRadio="weekly"
              textRadio={I18n.t('tool.dataExplorer.WEEKLY')}
              isOrange
            />
            <ItemRadio
              keyRadio="period"
              valueRadio="monthly"
              textRadio={I18n.t('tool.dataExplorer.MONTHLY')}
              isOrange
            />
            <ItemRadio
              keyRadio="period"
              valueRadio="quarterly"
              textRadio={I18n.t('tool.dataExplorer.QUARTERLY')}
              isOrange
            />
            <ItemRadio
              keyRadio="period"
              valueRadio="yearly"
              textRadio={I18n.t('tool.dataExplorer.YEARLY')}
              isOrange
            />
          </div>
          <table className={style.tableExport}>
            <thead>
              <tr>
                <th>
                  <ItemRadio
                    keyRadio="type"
                    valueRadio="single"
                    textRadio={I18n.t('tool.dataExplorer.SINGLE')}
                  />
                </th>
                <th>
                  <ItemRadio
                    keyRadio="type"
                    valueRadio="multiple"
                    textRadio={I18n.t('tool.dataExplorer.MULTIPLE')}
                    defaultCheck
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className={style.td}>{renderSingle()}</div>
                </td>
                <td>
                  <div className={style.td}>{renderMultiple()}</div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-end mt-9">
            <Button
              onClick={handleCancel}
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel} ${style.mr16}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit isCancelDisable={true} submit={submit} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default withWrapperPopupIndicator(SettingEconomy)
