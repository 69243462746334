import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Z_INDEX } from '../../../constants/Common'
import Sort from './Sort'
import { sortStateMap } from './constants'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text) => {
    return (
      <Span
        // className={style.theadContent}
        style={{ fontSize: 12, fontWeight: 600, textTransform: 'uppercase' }}
      >
        <Translate value={text} />
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={index}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              }`}
              onClick={() =>
                sortColumn({
                  [row.key]: sortStateMap[stateSort[row.key]],
                })
              }
            >
              <div className={stateSort[row?.key] ? 'mr-5' : undefined}>
                {renderTitle(row.title)}
                {row.additionText && (
                  <Span className={style.additionText} style={{ fontSize: 12 }}>
                    <Translate value={row.additionText} />
                  </Span>
                )}
              </div>

              {!row?.disableSort && !row?.hideIconSort && (
                <Sort type={stateSort[row.key]} />
              )}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
