import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getMarketCap } from './thunk'

const slice = createSlice({
  name: 'sector/sectorStatistics/overview/marketCap',
  initialState: {
    isLoading: true,
    dataChart: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMarketCap.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getMarketCap.fulfilled, (state, action) => {
      state.dataChart = action.payload
      state.isLoading = false
    })
    builder.addCase(getMarketCap.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectMarketCapChart = (state) => state[slice.name].dataChart
export const selectLoadingMarketCapChart = (state) =>
  state[slice.name].isLoading

register(slice.name, slice.reducer)
