import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { NAVIGATION_CORPORATE_BOND } from './constants'
import style from './index.module.css'
import { resetStore } from './marketStatistics/store/slice'

export const PrimaryMarket = () => {
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState(
    NAVIGATION_CORPORATE_BOND[0].value,
  )
  const [currentComponent, setCurrentComponent] = useState(
    NAVIGATION_CORPORATE_BOND[0].component,
  )

  const handleClickTab = (item) => () => {
    setCurrentTab(item.value)
    setCurrentComponent(item.component)
  }

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.corporateBond.primaryMarket.TITLE" />
              <VerticalLine mr={28} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_CORPORATE_BOND.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      currentTab === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {currentTab === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                className={style.relative}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                {currentComponent}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
