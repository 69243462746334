import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'
import { BlockBarItem } from './BlockBarItem'

export const ChartContent = ({
  totalBuyVolume,
  totalSellVolume,
  data,
  format,
  numberBar,
}) => {
  // Use selector
  const basicInfo = useSelector(selectBasicInfo)

  // Get data
  const getColor = (price) => {
    if (!price && ['ATO', 'ATC'].includes(getAtcAto())) return '#c7c8c9'

    if (
      data.priceInfo &&
      price < data.priceInfo.referencePrice / format.bestBid
    ) {
      return '#bf5757'
    } else if (
      data.priceInfo &&
      price > data.priceInfo.referencePrice / format.bestBid
    ) {
      return '#3da967'
    } else {
      return '#e59e2e'
    }
  }

  const getBidOfferData = () => {
    return [...Array(numberBar)].map((_, index) => ({
      bestBid: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Bid`]) / format.bestBid
        : 0,
      bestBidVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}BidVolume`]) /
          format.bestBidVolume
        : 0,
      bestOffer: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Offer`]) / format.bestOffer
        : 0,
      bestOfferVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}OfferVolume`]) /
          format.bestOfferVolume
        : 0,
    }))
  }

  const getWidthPercent = (bestVolume, totalVolume) => {
    if (bestVolume === 0) {
      return 0
    } else {
      return (bestVolume / totalVolume) * 100 + '%'
    }
  }

  const getAtcAto = () => {
    if (
      basicInfo.exchange === 'HOSE' &&
      moment().unix() >= moment().set('hour', 9).set('minute', 0).unix() &&
      moment().unix() <= moment().set('hour', 9).set('minute', 15).unix()
    ) {
      return 'ATO'
    }

    if (
      moment().unix() >= moment().set('hour', 14).set('minute', 30).unix() &&
      moment().unix() <= moment().set('hour', 14).set('minute', 45).unix()
    ) {
      return 'ATC'
    }

    return EMPTY_VALUE
  }

  return (
    <ScrollComponent>
      <div>
        {getBidOfferData().map((item, index) => (
          <div key={index} className={`${style.rowBlockBarItem} d-flex`}>
            <div className={`w-50 ${style.firstBlockBarItem}`}>
              <BlockBarItem
                leftValue={formatVal(item.bestBidVolume)}
                rightValue={
                  item.bestBid
                    ? formatVal(item.bestBid)
                    : index === 0
                    ? getAtcAto()
                    : 0
                }
                color={getColor(item.bestBid)}
                widthColor={getWidthPercent(item.bestBidVolume, totalBuyVolume)}
                positionColor="right"
              />
            </div>
            <div className="w-50">
              <BlockBarItem
                leftValue={
                  item.bestOffer
                    ? formatVal(item.bestOffer)
                    : index === 0
                    ? getAtcAto()
                    : 0
                }
                rightValue={formatVal(item.bestOfferVolume)}
                color={getColor(item.bestOffer)}
                widthColor={getWidthPercent(
                  item.bestOfferVolume,
                  totalSellVolume,
                )}
                positionColor="left"
              />
            </div>
          </div>
        ))}
      </div>
    </ScrollComponent>
  )
}

ChartContent.propTypes = {
  totalBuyVolume: PropTypes.number.isRequired,
  totalSellVolume: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  format: PropTypes.object,
  numberBar: PropTypes.number,
}
