export const error = {
  404: {
    title: '404 - Không tìm thấy đường dẫn',
    desc: `Trang bạn đang tìm kiếm có thể đã bị xóa, hoặc tên của nó đã bị thay
      đổi, có thể chỉ là tạm thời hoặc không có sẵn.`,
  },
  500: {
    title: 'Lỗi đang được khắc phục.',
    desc: `Thật không may, có sự cố xuất hiện trên server trang web bạn đang
      truy cập. Chúng tôi đang cố gắng khắc phục nhanh nhất có thể.`,
  },
  454: {
    desc: 'Tài khoản của bạn đã được đăng ký với thiết bị khác. Hãy đăng xuất và đăng nhập bằng thiết bị đã đăng ký.',
  },
  403: {
    desc: 'Hãy nâng cấp tài khoản để thực hiện tính năng này.',
  },
  GO_TO_HOMEPAGE: 'Quay về trang chủ',
  GO_TO_PREVIOUS_PAGE: 'Quay về trang trước',
  SIGN_OUT: 'Đăng xuất',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_FEATURE:
    'Bạn chưa được cấp quyền truy cập tính năng này.',
  PLEASE_CONTACT_THE_ADMIN:
    'Vui lòng liên hệ bộ phận chăm sóc khách hàng để được hỗ trợ.',
  ADMIN_CONTACT: 'T: (84-24) - 3562 6962 - Ext: 103',
  ACCOUNT_HAS_BEEN_LOGIN_FROM_ANOTHER_DEVICE:
    'Tài khoản của bạn đã bị đăng xuất do đang được đăng nhập trên một thiết bị khác.',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_FIRST:
    'Gói tính năng của bạn đã hết hạn. Vui lòng',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_SECOND:
    'bộ phận Chăm sóc khách hàng để được gia hạn.',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_CONTACT: 'liên hệ',
  YOUR_PACKAGE_EXPIRED_NOTE: 'Lưu ý:',
  YOUR_PACKAGE_EXPIRED_SYSTEM_COUNTDOWN:
    'Hệ thống FiinPro-X sẽ tự động đăng xuất sau',
  YOUR_PACKAGE_EXPIRED_SYSTEM_COUNTDOWN_SECOND: 'giây',
}
