import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import ChartComponent from '../../common/chartPeersComparison/ChartComponent'
import { FooterPeersComparison } from '../../common/chartPeersComparison/Footer'
import style from '../../index.module.css'
import {
  selectCurrentMonth,
  selectCurrentYear,
  selectIsAcc,
  selectIsYearly,
  selectListCompany,
} from '../steelStatistics/store/slice'
import { PEERS_COMPARISON_FILTER, PEERS_COMPARISON_KEYS } from './constants'
import {
  changeCategory,
  changeLocale,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { getConstructionPeersComparison } from './store/thunk'

export const PeersComparison = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoadingChart = useSelector(selectLoading)
  const currentYear = useSelector(selectCurrentYear)
  const currentMonth = useSelector(selectCurrentMonth)
  const isAcc = useSelector(selectIsAcc)
  const isYearly = useSelector(selectIsYearly)
  const listCompany = useSelector(selectListCompany)
  const locale = useSelector((state) => state.i18n.locale)

  const [currentCategory, setCurrentCategory] = useState(
    PEERS_COMPARISON_FILTER[0].value,
  )
  const getBarKeysByCategory = (category) => {
    switch (category) {
      case 0:
        return PEERS_COMPARISON_KEYS.slice(0, 3)
      case 1:
        return PEERS_COMPARISON_KEYS.slice(3, 6)
      case 2:
        return PEERS_COMPARISON_KEYS.slice(6, 9)
      case 3:
        return PEERS_COMPARISON_KEYS.slice(9, 12)
      default:
        return PEERS_COMPARISON_KEYS.slice(0, 3)
    }
  }

  const [currentBarKeys, setCurrentBarKeys] = useState(
    PEERS_COMPARISON_KEYS.slice(0, 3),
  )

  const [listCheckbox, setListCheckbox] = useState(currentBarKeys)

  const handleFilterChange = (item) => {
    setCurrentCategory(item)
    setCurrentBarKeys(getBarKeysByCategory(item))
    setListCheckbox(getBarKeysByCategory(item))
    dispatch(changeCategory(item))
  }

  useEffect(() => {
    if (listCompany.length && !isYearly && currentMonth && currentYear) {
      dispatch(
        getConstructionPeersComparison({
          year: currentYear,
          month: currentMonth,
          accumulated: isAcc,
          organizationIds: listCompany?.map((item) => item.organizationId),
        }),
      )
    }
  }, [listCompany])

  useEffect(() => {
    if (listCompany.length && isYearly && currentYear) {
      dispatch(
        getConstructionPeersComparison({
          year: currentYear,
          month: null,
          accumulated: false,
          organizationIds: listCompany?.map((item) => item.organizationId),
        }),
      )
    }
  }, [listCompany])

  useEffect(() => {
    dispatch(changeLocale())
  }, [locale])

  return (
    width &&
    height && (
      <div style={{ height: height }}>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '150px' }}
                >
                  <SelectCustom
                    value={currentCategory}
                    isI18n={true}
                    selectData={PEERS_COMPARISON_FILTER}
                    handleChange={handleFilterChange}
                  />
                </div>
                <div id="peersComparison">
                  {size.height && (
                    <div style={{ height: height - size.height }}>
                      {isLoadingChart ? (
                        <Loading />
                      ) : !dataChart.length ? (
                        <NoData />
                      ) : (
                        <ChartComponent
                          data={dataChart}
                          width={width}
                          height={height - size.height}
                          keyXAxis="name"
                          barKeys={currentBarKeys}
                          listCheckbox={listCheckbox}
                        />
                      )}
                    </div>
                  )}
                  <FooterPeersComparison
                    currentBarKeys={currentBarKeys}
                    listCheckbox={listCheckbox}
                    setListCheckbox={setListCheckbox}
                  />
                </div>
              </>
            )
          }}
        </SizeTracker>
      </div>
    )
  )
}
