import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class HistoryProxy extends ProxyBase {
  getHistoryProxy(params) {
    return this.get('BondDetailCashFlow', params)
  }
  exportFileProxy(params) {
    return this.post('DownloadBondDetailCashFlow', params, {}, 'download')
  }
}

export default new HistoryProxy(ServiceProxyConfig.Bond.BondAnalysis.ServiceUrl)
