import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import {
  changeStateTypes,
  selectActionTypes,
  selectFilter,
} from '../store/slice'
import { getActionTypes } from '../store/thunk'
import style from '../style.module.css'

export const DropdownSearchTypes = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filterData = useSelector(selectFilter)
  const dataActionTypes = useSelector(selectActionTypes)

  const setStateType = (data) => {
    dispatch(changeStateTypes(data))
  }

  useEffect(() => {
    dispatch(getActionTypes())
  }, [locale])

  return (
    <div className={`mr-8 ${style.spanInputSearch}`} style={{ width: 120 }}>
      <SelectCustom
        value={filterData.stateTypes}
        isI18n={true}
        selectData={dataActionTypes.map((item) => ({
          name: item.actionTypeName,
          value: item.actionTypeId,
        }))}
        handleChange={setStateType}
      />
    </div>
  )
}

export default DropdownSearchTypes
