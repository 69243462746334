import SectorConstituentsOverviewProxy from '../../../proxies/sector/sectorConstituents/OverviewProxy'
import { ServiceBase } from '../../ServiceBase'

export class SectorConstituentsOverviewService extends ServiceBase {
  getTopRevenueChartData(params) {
    return this.applyMemoryCache(
      'SectorConstituentsOverviewService.getTopRevenueChartData',
      params,
    )(() => SectorConstituentsOverviewProxy.getTopRevenueChartData(params))
  }

  getSectorEarningGrowthChartData(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getSectorEarningGrowthChartData(params),
    )
  }

  getSegmentationChartData(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getSegmentationChartData(params),
    )
  }

  getTopTotalOperatingIncomeChartData(params) {
    return this.applyMemoryCache(
      'SectorConstituentsOverviewService.getTopTotalOperatingIncomeChartData',
      params,
    )(() =>
      SectorConstituentsOverviewProxy.getTopTotalOperatingIncomeChartData(
        params,
      ),
    )
  }

  getCreditAndDepositChartData(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getCreditAndDepositChartData(params),
    )
  }

  getMarginLendingChartData(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getMarginLendingChartData(params),
    )
  }

  getKeyRatios(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getKeyRatios(params),
    )
  }

  getTopPerformance(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getTopPerformance(params),
    )
  }

  downloadKeyRatios(params) {
    return this.getFileDownload(() =>
      SectorConstituentsOverviewProxy.downloadKeyRatios(params),
    )
  }

  downloadTopPerformance(params) {
    return this.getFileDownload(() =>
      SectorConstituentsOverviewProxy.downloadTopPerformance(params),
    )
  }

  getListNews(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getListNews(params),
    )
  }

  getListReport(params) {
    return this.getData(() =>
      SectorConstituentsOverviewProxy.getListReport(params),
    )
  }
}

export default new SectorConstituentsOverviewService()
