import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'
import { List } from '../../../../common/news/List'
import { FEATURES } from '../../../../constants/routes'

export const Item = ({
  data,
  isLoading,
  numColumn,
  marginLeft,
  marginRight,
}) => {
  const navigate = useNavigate()

  const onMoreAction = () => {
    navigate(
      FEATURES.market.components['news-report-more'].route +
        '?type=' +
        data.newsCategoryId,
    )
  }
  const onDetailAction = (newsId) => () => {
    navigate(
      FEATURES.market.components['news-report-detail'].route +
        '?newsId=' +
        newsId,
    )
  }

  return (
    <List
      title={data && data.newsCategoryName ? data.newsCategoryName : ''}
      isI18nTitle={false}
      data={data ? data.news : []}
      isLoading={isLoading}
      isNodata={!!data && data.news.length === 0}
      numColum={numColumn}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onMoreAction={onMoreAction}
      onDetailAction={onDetailAction}
    />
  )
}

Item.propTypes = {
  numColumn: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
}
