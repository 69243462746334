import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import {
  OVERVIEW_OF_PORTFOLIO_TABS,
  OVERVIEW_OF_PORTFOLIO_TYPES,
} from '../constants'
import {
  keys,
  selectDataBondInformation,
  selectFilterOverviewOfPortfolio,
  selectLoading,
} from '../store/slice'
import ContributionChart from './ContributionChart'
import Filter from './Filter'
import InterestChart from './InterestChart'
import RemainingDurationChart from './RemainingDurationChart'

const OverviewOfPortfolioSize = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.BOND_INFORMATION))
  const { data } = useSelector(selectDataBondInformation)
  const { activeTab } = useSelector(selectFilterOverviewOfPortfolio)
  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTitle = useMemo(() => {
    let type = OVERVIEW_OF_PORTFOLIO_TABS.find(
      (item) => item.value === activeTab,
    )

    return type
      ? `${I18n.t('bond.portfolio.common.OVERVIEW_OF_PORTFOLIO_SIZE')}-${I18n.t(
          type.title,
        )}`
      : I18n.t('bond.portfolio.common.OVERVIEW_OF_PORTFOLIO_SIZE')
  }, [locale])

  return (
    <Panel
      title="bond.portfolio.common.OVERVIEW_OF_PORTFOLIO_SIZE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={renderTitle}
      downloadJpgParams={{
        domId: 'overviewOfPortfolioSizeId',
        nameScreen: I18n.t('bond.portfolio.common.TITLE'),
        chartName: I18n.t('bond.portfolio.common.OVERVIEW_OF_PORTFOLIO_SIZE'),
        tickerName: '',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : !!data.length ? (
        <SizeTracker>
          {(size) => (
            <>
              <Filter />
              <div id="overviewOfPortfolioSizeId">
                {size.height && (
                  <div style={{ height: height - size.height }}>
                    {(() => {
                      switch (activeTab) {
                        case OVERVIEW_OF_PORTFOLIO_TYPES.CONTRIBUTION:
                          return (
                            <ContributionChart
                              data={data.filter((item) => item?.id !== 'total')}
                              height={height - size.height}
                              width={width}
                            />
                          )
                        case OVERVIEW_OF_PORTFOLIO_TYPES.INTEREST:
                          return (
                            <InterestChart
                              data={data.filter((item) => item?.id !== 'total')}
                              height={height - size.height}
                              width={width}
                            />
                          )
                        case OVERVIEW_OF_PORTFOLIO_TYPES.DURATION:
                          return (
                            <RemainingDurationChart
                              data={data.filter((item) => item?.id !== 'total')}
                              height={height - size.height}
                              width={width}
                            />
                          )
                        default:
                          return <></>
                      }
                    })()}
                  </div>
                )}
              </div>
            </>
          )}
        </SizeTracker>
      ) : (
        <NoData />
      )}
    </Panel>
  )
}

export default OverviewOfPortfolioSize
