import moment from 'moment'
import 'moment/locale/vi'
import { I18n } from 'react-redux-i18n'
import { languageObjs } from '../../configs/Language'
import { defaultTimeZone } from '../../configs/TimeZone'
import { EMPTY_VALUE, LOCAL_STORAGE_KEY } from '../constants/Common'
import { formatVal } from './Value'

const { en, vi } = languageObjs
const defaultMinDate = '1973-01-01'

export const changeLocaleDateTime = (locale) => {
  moment.locale(locale, {
    week: {
      dow: 1,
      doy: 7,
    },
  })
}

export const FORMAT = {
  DATE_TIME: {
    [en]: 'MM/DD/YYYY HH:mm',
    [vi]: 'DD/MM/YYYY HH:mm',
  },
  DATE_FULL_TIME: {
    [en]: 'MM/DD/YYYY HH:mm:ss',
    [vi]: 'DD/MM/YYYY HH:mm:ss',
  },
  DATE: {
    [en]: 'MM/DD/YYYY',
    [vi]: 'DD/MM/YYYY',
  },
  TIME: {
    [en]: 'HH:mm:ss',
    [vi]: 'HH:mm:ss',
  },
  FILE: {
    [en]: 'YYYYMMDD',
    [vi]: 'YYYYMMDD',
  },
  CALENDAR: {
    [en]: 'M-D-YYYY',
    [vi]: 'D-M-YYYY',
  },
  YEAR: {
    [en]: 'YYYY',
    [vi]: 'YYYY',
  },
  MONTH: {
    [en]: 'MMM-YYYY',
    [vi]: 'MMM-YYYY',
  },
  MONTH_NUMBER: {
    [en]: 'MM',
    [vi]: 'MM',
  },
  WEEK: {
    [en]: 'W-YYYY',
    [vi]: 'W-YYYY',
  },
  YEAR_MONTH_DAY: {
    [en]: 'YYYY-MM-DD',
    [vi]: 'YYYY-MM-DD',
  },
  YEAR_MONTH_DAY_2: {
    [en]: 'YYYY/MM/DD',
    [vi]: 'YYYY/MM/DD',
  },
  NEWS: {
    [en]: 'HH:mm - MMM DD, YYYY',
    [vi]: 'HH:mm - DD MMMM, YYYY',
  },
  NEWS_WITHOUT_TIME: {
    [en]: 'MMM DD, YYYY',
    [vi]: 'DD MMMM, YYYY',
  },
  TIME_HOUR_MINUTE: {
    [en]: 'HH:mm',
    [vi]: 'HH:mm',
  },
  WEEK_YEAR_W: {
    [en]: '[W]W-YYYY',
    [vi]: '[W]W-YYYY',
  },
  WEEK_YEAR_NUMBER: {
    [en]: 'WW-YYYY',
    [vi]: 'WW-YYYY',
  },
  WEEK_W: {
    [en]: '[W]W',
    [vi]: '[W]W',
  },
  QUARTER: {
    [en]: 'Q',
    [vi]: 'Q',
  },
  QUARTER_Q: {
    [en]: '[Q]Q',
    [vi]: '[Q]Q',
  },
  QUARTER_YEAR: {
    [en]: '[Q]Q-YYYY',
    [vi]: '[Q]Q-YYYY',
  },
  DAY_MONTH: {
    [en]: 'MM/DD',
    [vi]: 'DD/MM',
  },
  DAY_MONTH_TH: {
    [en]: 'DD-MMM',
    [vi]: 'DD-[Th]M',
  },
  MONTH_YEAR_NUMBER: {
    [en]: 'MM-YYYY',
    [vi]: 'MM-YYYY',
  },
  MONTH_YEAR_SLASH: {
    [en]: 'MM/YYYY',
    [vi]: 'MM/YYYY',
  },
  MONTH_M: {
    [en]: 'MMM',
    [vi]: '[T]MM',
  },
  ONLY_WEEK: {
    [en]: 'W',
    [vi]: 'W',
  },
}

export const getCurrentDateTime = (
  format = FORMAT.DATE_TIME,
  locale = en,
  timeZone,
) => {
  return moment()
    .utcOffset(timeZone || getTimeZoneLocal().offset)
    .format(format[locale])
    .toString()
}

export const formatDateTime = (
  str,
  format = FORMAT.DATE_TIME,
  locale = en,
  timeZone,
) => {
  return (!str || moment(str).isValid()) &&
    moment
      .utc(str)
      .utcOffset(timeZone || getTimeZoneLocal().offset)
      .unix() > moment(defaultMinDate).unix()
    ? moment
        .utc(str)
        .utcOffset(timeZone || getTimeZoneLocal().offset)
        .format(format[locale])
        .toString()
    : EMPTY_VALUE
}

export const formatDateTimeLocal = (
  str,
  format = FORMAT.DATE_TIME,
  locale = en,
) => {
  return moment(str).isValid()
    ? moment(str).format(format[locale]).toString()
    : EMPTY_VALUE
}

export const getDateTimeFrom = (
  from,
  amount,
  unit,
  format = FORMAT.DATE_TIME,
  locale = en,
  timeZone,
) => {
  return moment
    .utc(from)
    .utcOffset(timeZone || getTimeZoneLocal().offset)
    .add(amount, unit)
    .format(format[locale])
    .toString()
}

export const compareTwoDateTime = (date1, date2) => {
  if (moment(date1) > moment(date2)) return 1
  if (moment(date1) < moment(date2)) return -1
  return 0
}

export const getQuarter = (date = new Date()) => {
  return Math.floor(new Date(date).getMonth() / 3 + 1)
}

export const getTZMinutes = (strTimeZone) => {
  const timeZoneSign = strTimeZone[0]
  const timeZoneHour = Number(timeZoneSign + strTimeZone.substring(1, 3))
  const timeZoneMinute = Number(timeZoneSign + strTimeZone.substring(4, 6))
  return timeZoneHour * 60 + timeZoneMinute
}

export const getISOStartOrEndOfDay = (str, strTimeZone, isStartOfDay) => {
  const tzMinutes = getTZMinutes(strTimeZone)

  const dateTime = isStartOfDay
    ? moment(str).startOf('day')
    : moment(str).endOf('day')
  dateTime.subtract(tzMinutes, 'minutes')

  return (
    dateTime.format('YYYY-MM-DD[T]HH:mm') + (isStartOfDay ? ':00Z' : ':59Z')
  )
}

export const getDateWithTimezone = (date, timeZone) => {
  return moment(date).utcOffset(timeZone).toDate()
}

export const saveTimeZoneLocal = (timezone) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.KEY_TIMEZONE, JSON.stringify(timezone))
}

export const getTimeZoneLocal = () => {
  const timezone = localStorage.getItem(LOCAL_STORAGE_KEY.KEY_TIMEZONE)
  return timezone ? JSON.parse(timezone) : defaultTimeZone
}

export const MONTHS = [
  {
    name: 'common.MONTH.JAN',
    value: 1,
  },
  {
    name: 'common.MONTH.FEB',
    value: 2,
  },
  {
    name: 'common.MONTH.MAR',
    value: 3,
  },
  {
    name: 'common.MONTH.APR',
    value: 4,
  },
  {
    name: 'common.MONTH.MAY',
    value: 5,
  },
  {
    name: 'common.MONTH.JUN',
    value: 6,
  },
  {
    name: 'common.MONTH.JUL',
    value: 7,
  },
  {
    name: 'common.MONTH.AUG',
    value: 8,
  },
  {
    name: 'common.MONTH.SEP',
    value: 9,
  },
  {
    name: 'common.MONTH.OCT',
    value: 10,
  },
  {
    name: 'common.MONTH.NOV',
    value: 11,
  },
  {
    name: 'common.MONTH.DEC',
    value: 12,
  },
]

export const QUARTERS = [
  {
    name: 'Q1',
    value: 1,
  },
  {
    name: 'Q2',
    value: 2,
  },
  {
    name: 'Q3',
    value: 3,
  },
  {
    name: 'Q4',
    value: 4,
  },
]

export const formatDuration = (duration, decimal = 2) => {
  if (!duration) return '--'

  return `${formatVal(
    duration > 36 ? duration / 12 : duration,
    decimal,
  )} ${I18n.t(duration > 36 ? 'common.YEARS' : 'common.MONTHS')}`
}
