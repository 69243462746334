import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import UseTimeZone from './../../../common/hooks/useTimeZone'
import SectorSegmentation from './sectorSegmentation'
import { ICB_LEVEL } from './sectorSegmentation/constants'
import { downloadSectorSegmentation } from './sectorSegmentation/store/thunk'

const PanelSectorSegmentation = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  height,
}) => {
  const indexInfo = useSelector(selectIndexInfo)
  const timeZone = UseTimeZone()

  const [dates, setDates] = useState([])

  return (
    <Panel
      title={'market.marketInDepth.foreign.VALUE_BY_SECTOR_SEGMENTATION'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadSectorSegmentation({
          GroupId: indexInfo?.groupId,
          IcbLevel: ICB_LEVEL,
          StartDate: getISOStartOrEndOfDay(dates[0], timeZone, true),
          EndDate: getISOStartOrEndOfDay(
            dates[dates.length - 1],
            timeZone,
            false,
          ),
        })
      }
    >
      <SectorSegmentation height={height} dates={dates} setDates={setDates} />
    </Panel>
  )
}

export default PanelSectorSegmentation
