import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import {
  key,
  selectLoading,
  selectLocale,
  selectQuickSelectIndicators,
} from './store/slice'
import { getQuickSelectIndicatorsThunk } from './store/thunk'

const useQuickSelectIndicator = () => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const quickSelectIndicators = useSelector(selectQuickSelectIndicators)
  const loadingQuickSelect = useSelector(
    selectLoading(key.QUICK_SELECT_INDICATORS),
  )

  const [quickSelectIndicatorsTree, setQuickSelectIndicatorsTree] = useState({})

  useEffect(() => {
    dispatch(getQuickSelectIndicatorsThunk())
  }, [locale])

  useEffect(() => {
    if (quickSelectIndicators.length > 0) {
      setQuickSelectIndicatorsTree(handleTreeData())
    }
  }, [quickSelectIndicators])

  const handleTreeData = () => {
    const quickSelectIndicatorsById = keyBy(
      quickSelectIndicators.map((item) => {
        return { ...item, children: {}, items: [] }
      }),
      'indicatorId',
    )

    quickSelectIndicators.forEach((item) => {
      if (
        item.parentIndicatorId &&
        quickSelectIndicatorsById[item.parentIndicatorId]
      ) {
        quickSelectIndicatorsById[item.parentIndicatorId].items.push(
          quickSelectIndicatorsById[item.indicatorId],
        )
        delete quickSelectIndicatorsById[item.indicatorId]
      }
    })
    return quickSelectIndicatorsById
  }

  return {
    quickSelectIndicators,
    quickSelectIndicatorsTree,
    loadingQuickSelect,
  }
}

export default useQuickSelectIndicator
