export const route = {
  market: {
    LABEL: 'Market',
    marketInDepth: {
      LABEL: 'Market In-Depth',
      indices: {
        LABEL: 'Indices',
      },
      foreign: {
        LABEL: 'Foreign',
      },
      proprietary: {
        LABEL: 'Proprietary',
      },
      directorDeal: {
        LABEL: 'Director Deal',
      },
      valuation: {
        LABEL: 'Valuation',
      },
      indexStatistics: {
        LABEL: 'Index Statistics',
      },
    },
    sectorStatistics: {
      LABEL: 'Sector In-Depth',
    },
    newsReport: {
      LABEL: 'News & Report',
    },
    events: {
      LABEL: 'Events',
    },
    heatmap: {
      LABEL: 'Heatmap',
    },
    topMover: {
      LABEL: 'Top Mover',
    },
    equityTrading: {
      LABEL: 'Equity Trading',
      deepTransaction: {
        LABEL: 'Deep Transaction',
      },
      priceStatistics: {
        LABEL: 'Price Statistics',
      },
    },
    derivative: {
      LABEL: 'Derivative',
      OVERVIEW: 'Overview',
      DERIVATIVE_STATISTICS: 'Derivative statistics',
    },
    coveredWarrant: {
      LABEL: 'Covered Warrant',
      overview: {
        LABEL: 'CW Details',
      },
      cwStatistics: {
        LABEL: 'CW Statistics',
      },
    },
    marginLoans: {
      LABEL: 'Margin Loans',
    },
    charting: {
      LABEL: 'Technical Chart',
    },
  },
  corporate: {
    LABEL: 'Corporate',
    overview: {
      LABEL: 'Overview',
    },
    newsEvents: {
      LABEL: 'News & Events',
      news: {
        LABEL: 'News',
      },
      events: {
        LABEL: 'Events',
      },
      filing: {
        LABEL: 'Filing',
      },
    },
    tradingData: {
      LABEL: 'Trading Data',
      deepTransaction: {
        LABEL: 'Deep Transaction',
      },
      priceStatistics: {
        LABEL: 'Price Statistics',
      },
    },
    ownership: {
      LABEL: 'Ownership',
    },
    businessModel: {
      LABEL: 'Business Model',
      subsidiaryAssociates: {
        LABEL: 'Subsidiary & Associates',
      },
      segmentalAnalysis: {
        LABEL: 'Segmental Analysis',
      },
      penetrationAnalysis: {
        LABEL: 'Penetration Analysis',
      },
      costOfOperation: {
        LABEL: 'Cost of Operation',
      },
    },
    financialAnalysis: {
      LABEL: 'Financial Analysis',
      debtCreditRisk: {
        LABEL: 'Debt & Credit Risk',
      },
      earningQuality: {
        LABEL: 'Earning Quality',
      },
    },
    financialData: {
      LABEL: 'Financial Data',
      financialRatios: {
        LABEL: 'Financial Ratios',
      },
      financialStatement: {
        LABEL: 'Financial Statement',
      },
      dividend: {
        LABEL: 'Dividend',
      },
    },
    peerValuation: {
      LABEL: 'Peer & Valuation',
      overview: {
        LABEL: 'Overview',
      },
      consensus: {
        LABEL: 'Consensus',
      },
    },
    companySnapshot: {
      LABEL: 'Company Snapshot',
    },
  },
  sector: {
    LABEL: 'Sector',
    sectorStatistics: {
      LABEL: 'Sector Statistics',
      overview: {
        LABEL: 'Overview',
      },
      ratios: {
        LABEL: 'Detail',
      },
    },
    sectorSpecific: {
      LABEL: 'Sector Specific',
      fishery: {
        LABEL: 'Fishery',
      },
      steel: {
        LABEL: 'Steel',
      },
    },
    sectorConstituents: {
      LABEL: 'Sector Constituents',
      overview: {
        LABEL: 'Overview',
      },
      newsReport: {
        LABEL: 'News & Report',
      },
      events: {
        LABEL: 'Events',
      },
      financialAnalysis: {
        LABEL: 'Financial Analysis',
      },
      segmentation: {
        LABEL: 'Segmentation',
      },
      ranking: {
        LABEL: 'Ranking',
      },
      ratio: {
        LABEL: 'Ratio',
      },
      valuation: {
        LABEL: 'Valuation',
      },
    },
  },
  economy: {
    LABEL: 'Economy',
    newsAndReport: {
      LABEL: 'News & Report',
    },
    event: {
      LABEL: 'Events',
    },
    gdp: {
      LABEL: 'GDP',
      totalGDP: {
        LABEL: 'Total GDP',
      },
      gdpByProvince: {
        LABEL: 'GDP by Province',
      },
      gdpBySpendingMethod: {
        LABEL: 'GDP by Spending Method',
      },
    },
    cpiAndInflation: {
      LABEL: 'CPI & Inflation',
    },
    importExport: {
      LABEL: 'Import/Export',
      overview: {
        LABEL: 'Overview',
      },
      exportImportByCommodities: {
        LABEL: 'Export/Import by Commodities',
      },
      exportImportByCountries: {
        LABEL: 'Export/Import by Countries',
      },
    },
    fdi: {
      LABEL: 'FDI',
      overview: {
        LABEL: 'Overview',
      },
      fdiByCountries: {
        LABEL: 'FDI by Country',
      },
      fdiByIndustries: {
        LABEL: 'FDI by Industry',
      },
      fdiByProvinces: {
        LABEL: 'FDI by Province',
      },
    },
    paymentBalance: {
      LABEL: 'Balance of Payment',
    },
    monetary: {
      LABEL: 'Monetary',
      openMarket: {
        LABEL: 'Open Market',
      },
      'moneySupplies-Credit': {
        LABEL: 'Money Supply - Credit',
      },
      exchangeRate: {
        LABEL: 'Exchange Rate',
      },
      interestRate: {
        LABEL: 'Interest Rate',
        borrowingInterest: {
          LABEL: 'Borrowing Interest',
        },
        lendingRate: {
          LABEL: 'Lending Rate',
        },
      },
    },
    fiscal: {
      LABEL: 'Fiscal',
      stateBudget: {
        LABEL: 'State Budget',
      },
      publicDebt: {
        LABEL: 'Public Debt',
      },
    },
    productionAndConsumption: {
      LABEL: 'Production & Consumption',
      production: {
        LABEL: 'Production',
      },
      consumption: {
        LABEL: 'Consumption',
      },
    },
  },
  tool: {
    LABEL: 'Tool',
    smartScreening: {
      LABEL: 'Smart Screening',
    },
    technicalChart: {
      LABEL: 'Technical Chart',
    },
    dataExplorer: {
      LABEL: 'Data Explorer',
      corporate: {
        LABEL: 'Corporate',
      },
      indicesSector: {
        LABEL: 'Index & Sector',
      },
      tradingData: {
        LABEL: 'Trading Data',
      },
      economy: {
        LABEL: 'Economy',
      },
    },
    scatterPlot: {
      LABEL: 'Scatter Plot',
    },
  },
  search: {
    LABEL: 'Advanced Search',
  },
  bond: {
    LABEL: 'Bond',
    governmentBond: {
      LABEL: 'Government Bond',
    },
    corporateBond: {
      LABEL: 'Corporate Bond',
      primaryMarket: {
        LABEL: 'Primary Market',
      },
      secondaryMarket: {
        LABEL: 'Secondary Market',
      },
      issuers: {
        LABEL: 'Issuers',
      },
      valuation: {
        LABEL: 'Analysis & Valuation',
      },
      bondSecurity: {
        LABEL: 'Security Description',
      },
    },
    bondBuyback: {
      LABEL: 'Bond Buyback',
    },
    bondScreening: {
      LABEL: 'Bond List',
    },
    portfolio: {
      LABEL: 'Portfolio',
    },
    priceBoard: {
      LABEL: 'Price Board',
    },
    interconnectionMap: {
      LABEL: 'Interconnection Map',
    },
  },
  newsReport: {
    LABEL: 'News & Report',
    dailyReport: {
      LABEL: 'Daily Report',
    },
  },
  fund: {
    LABEL: 'Fund',
    fundCenter: {
      LABEL: 'Fund Center',
      overview: {
        LABEL: 'Overview',
      },
      statistic: {
        LABEL: 'Statistic',
      },
      holding: {
        LABEL: 'Holding',
      },
    },
    fundProfile: {
      LABEL: 'Fund Profile',
      profile: {
        LABEL: 'Fund Profile',
      },
      portfolio: {
        LABEL: 'Portfolio',
      },
      navData: {
        LABEL: 'Nav Data',
      },
      financialReport: {
        LABEL: 'Financial Report',
      },
      periodicalReport: {
        LABEL: 'Periodical Report',
      },
    },
  },
  financialChart: {
    LABEL: 'Financial Chart',
    financialChart: {
      LABEL: 'Financial Chart',
    },
    scatterPlot: {
      LABEL: 'Scatter Plot',
    },
  },
}
