import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import style from './index.module.css'
import { selectIsExchangePrice } from './store/slice'

const Footer = () => {
  const isExchangePrice = useSelector(selectIsExchangePrice)

  return (
    <div className={style.footer}>
      <Span style={{ color: '#85939C', fontStyle: 'italic' }}>
        <Translate
          value={`bond.corporateBond.priceBoard.${
            isExchangePrice ? 'NOTE' : 'NOTE_2'
          }`}
        />
      </Span>
    </div>
  )
}

export default Footer
