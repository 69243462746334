import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { OVERVIEW_GROUP_ID } from '../../../common/topInfo/stockTopInfo/constants'
import { selectBasicInfoOverview } from '../store/slice'
import { listTimeFilter } from './constants'
import style from './index.module.css'
import {
  changeTimeFilter,
  resetIndicator,
  selectTimeFilter,
} from './store/slice'

export const FinancialMetricTab = () => {
  const dispatch = useDispatch()
  const timeFilter = useSelector(selectTimeFilter)
  const basicInfo = useSelector(selectBasicInfoOverview)

  const onChangeTab = (data) => {
    dispatch(resetIndicator())
    dispatch(
      changeTimeFilter({
        label: data.title,
        value: data.value,
      }),
    )
  }

  return (
    <div className={`${style.financialMetricHeader}`}>
      <DispatchActionTab
        onChangeTab={onChangeTab}
        data={listTimeFilter.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        activeTab={timeFilter.value}
      />
      <span className={`${style.alignItemsCenter} font-inline`}>
        <Translate value="corporate.overview.financial.UNIT_LABEL" />: &nbsp;
        <Translate
          value={`${
            basicInfo?.groupId === OVERVIEW_GROUP_ID.BANK
              ? 'corporate.overview.financial.UNIT_TRILLION'
              : 'corporate.overview.financial.UNIT_BILLION'
          }`}
        />
      </span>
    </div>
  )
}
