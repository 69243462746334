import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CreateBondService from '../../../../../core/services/bond/bondScreening/CreateBondService'
import InfoSec from '../../../../../core/services/bond/valuation/infoSec'
import { handleExport } from '../../../../utils/Export'
import {
  nameStateSecInfo,
  updateBondIds,
  updateCouponInput,
  updateStatusEditlocale,
} from './slice'

export const getBondCashFlowScenario = createAsyncThunk(
  'bond/valuation/infoSec/BONDCASHFLOWSCENARIO',
  async (data, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getBondCashFlowScenarioApi(data.param)
      if (response.success) {
        return {
          data: response.data,
          editDate: data?.editDate,
        }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getAccruedInterest = createAsyncThunk(
  'bond/valuation/infoSec/getAccruedInterest',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getAccruedInterest(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDefaultBondId = createAsyncThunk(
  'bond/valuation/infoSec/getDefaultBondId',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await InfoSec.getDefautBondId(params)
      if (response.success) {
        dispatch(updateBondIds(response.data.bondId))
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataListBond = createAsyncThunk(
  'bond/valuation/infoSec/GET_DATA',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await InfoSec.getListBond(params)
      if (response.success) {
        const allState = getState()
        const state = allState[nameStateSecInfo]
        if (!state.BondIds) {
          dispatch(getDefaultBondId())
        }
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getCreditEvent = createAsyncThunk(
  'bond/valuation/infoSec/getCreditEventACTion',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoSec.getCreditEventApi(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataBondInfo = createAsyncThunk(
  'bond/valuation/infoSec/BOND_INFO',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await InfoSec.getBondInfo(params.data)

      if (response.success) {
        if (!params?.data?.isUserBond) {
          dispatch(
            getCreditEvent({
              BondId: params.data.BondIds,
            }),
          )
        }
        if (params.isEditlocale) {
          dispatch(updateStatusEditlocale(true))
        }
        dispatch(updateCouponInput(response.data.interestRate * 100))
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const exportFile = createAsyncThunk(
  'demo/valuation/infoSec/exportFile',
  async (data) => {
    const response = await InfoSec.exportFileApi(data)
    handleExport(response.data, response.filename)
  },
)

export const createBondThunk = createAsyncThunk(
  'bond/valuation/infoSec/CREATE_BOND_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CreateBondService.createBond(params)

      if (response.success) {
        const responseBondList = await CreateBondService.getAllBond()

        if (responseBondList.success) {
          const bond = responseBondList?.data.find(
            (item) => item.bondTicker === params?.ticker,
          )

          return {
            status: response.success,
            data: responseBondList?.data,
            bond,
          }
        }

        return response.success
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getCreateBondListData = createAsyncThunk(
  'bond/valuation/infoSec/GET_CREATE_BOND_LIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CreateBondService.getAllBond(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
