import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPriceData } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { changePriceDataReferencePrice } from './store/slice'

export const UpdateReferencePrice = () => {
  const dispatch = useDispatch()

  // Use selector
  const priceData = useSelector(selectPriceData)

  // Use effect
  useEffect(() => {
    if (priceData?.priceInfo?.referencePrice) {
      dispatch(
        changePriceDataReferencePrice(
          Number(priceData?.priceInfo?.referencePrice) || 0,
        ),
      )
    }
  }, [priceData?.priceInfo?.referencePrice])

  return <></>
}
