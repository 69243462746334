export const GDP_SECTOR_VALUE = {
  VALUE: 'VALUE',
  GROWTH_YOY: 'GROWTH_YOY',
}

export const TIME_FRAME_OPTIONS = [
  {
    title: 'economy.gdp.gdp.YEARLY',
    value: 'Yearly',
  },
  { title: 'economy.gdp.gdp.QUARTERLY', value: 'Quarterly' },
  { title: 'economy.gdp.gdp.ACC_QUARTERLY', value: 'AccQuarterly' },
]
