import { formatVal, valDivMillion } from '../../../../../utils/Value'
import styles from './index.module.css'

export const TotalCell = ({
  val,
  style,
  className,
  formatColor,
  noBorderLeft,
}) => {
  return (
    <td
      style={style}
      className={`${className} ${styles.totalCell} ${
        noBorderLeft ? styles.noBorderLeft : ''
      }`}
    >
      {formatColor ? (
        <span style={{ color: val >= 0 ? '#59c761' : '#ff4752' }}>
          {formatVal(valDivMillion(val), 1)}
        </span>
      ) : (
        formatVal(valDivMillion(val), 1)
      )}
    </td>
  )
}
