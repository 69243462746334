import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { STATEMENT_CONSTANTS } from '../../constants'
import {
  LASTEST_YEAR,
  NUMBER_OF_PERIOD,
  TYPE,
  VIEW_BY,
} from '../../filter/constants'
import { getDataTitle } from '../../helps'
import {
  getAssetBreakdownChartData,
  getBalanceSheetStructureChartData,
  getCashFlowStructureChartData,
  getDGAndCGChartData,
  getFinancialAssetsStructureChartData,
  getFinancialExpensesChartData,
  getFinancialIncomeChartData,
  getFinancialRatioChartData,
  getInsurancePremiumBreakdownChartData,
  getInventoryStructureChartData,
  getLoansByIndustryChartData,
  getLoansByTermChartData,
  getMarginLendingChartData,
  getOperatingCashFlowChartData,
  getOperatingExpensesStructureChartData,
  getOperatingSalesStructureChartData,
  getPeersComparisonChartData,
  getProfitAndLossChartData,
  getProfitabilityChartData,
  getReservesChartData,
  getRevenueAndProfitChartData,
  getSTAndLTBorrowingChartData,
  getTICSEChartData,
  getTOIStructureChartData,
} from './thunk'

export const slice = createSlice({
  name: 'corporate/financialData/financialStatement/chart',
  initialState: {
    statement: STATEMENT_CONSTANTS.BALANCE_SHEET,
    data: {},
    loading: {},
    filter: {
      viewBy: VIEW_BY[0],
      numberOfPeriod: NUMBER_OF_PERIOD[4],
      type: TYPE[0],
      lastestYear: LASTEST_YEAR[0],
    },
  },
  reducers: {
    changeStatement: (state, action) => {
      state.statement = action.payload
    },
    changeFinancialChartFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.field]: action.payload.option,
      }
    },
  },
  extraReducers: (builder) => {
    // getBalanceSheetStructureChartData
    builder.addCase(getBalanceSheetStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getBalanceSheetStructureChartData: true,
      }
    })
    builder.addCase(
      getBalanceSheetStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getBalanceSheetStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getBalanceSheetStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getBalanceSheetStructureChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getBalanceSheetStructureChartData: action.payload.loading,
        }
      },
    )
    // getDGAndCGChartData
    builder.addCase(getDGAndCGChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getDGAndCGChartData: true,
      }
    })
    builder.addCase(getDGAndCGChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getDGAndCGChartData: false,
      }
      state.data = {
        ...state.data,
        getDGAndCGChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getDGAndCGChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getDGAndCGChartData: action.payload.loading,
      }
    })
    // getTOIStructureChartData
    builder.addCase(getTOIStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getTOIStructureChartData: true,
      }
    })
    builder.addCase(getTOIStructureChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getTOIStructureChartData: false,
      }
      state.data = {
        ...state.data,
        getTOIStructureChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getTOIStructureChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getTOIStructureChartData: action.payload.loading,
      }
    })
    // getProfitAndLossChartData
    builder.addCase(getProfitAndLossChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getProfitAndLossChartData: true,
      }
    })
    builder.addCase(getProfitAndLossChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getProfitAndLossChartData: false,
      }
      state.data = {
        ...state.data,
        getProfitAndLossChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getProfitAndLossChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getProfitAndLossChartData: action.payload.loading,
      }
    })
    // getCashFlowStructureChartData
    builder.addCase(getCashFlowStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getCashFlowStructureChartData: true,
      }
    })
    builder.addCase(
      getCashFlowStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getCashFlowStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getCashFlowStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(getCashFlowStructureChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getCashFlowStructureChartData: action.payload.loading,
      }
    })
    // getOperatingCashFlowChartData
    builder.addCase(getOperatingCashFlowChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getOperatingCashFlowChartData: true,
      }
    })
    builder.addCase(
      getOperatingCashFlowChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getOperatingCashFlowChartData: false,
        }
        state.data = {
          ...state.data,
          getOperatingCashFlowChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(getOperatingCashFlowChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getOperatingCashFlowChartData: action.payload.loading,
      }
    })
    // getLoansByIndustryChartData
    builder.addCase(getLoansByIndustryChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getLoansByIndustryChartData: true,
      }
    })
    builder.addCase(getLoansByIndustryChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getLoansByIndustryChartData: false,
      }
      state.data = {
        ...state.data,
        getLoansByIndustryChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getLoansByIndustryChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getLoansByIndustryChartData: action.payload.loading,
      }
    })
    // getLoansByTermChartData
    builder.addCase(getLoansByTermChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getLoansByTermChartData: true,
      }
    })
    builder.addCase(getLoansByTermChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getLoansByTermChartData: false,
      }
      state.data = {
        ...state.data,
        getLoansByTermChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getLoansByTermChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getLoansByTermChartData: action.payload.loading,
      }
    })
    // getFinancialRatioChartData
    builder.addCase(getFinancialRatioChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getFinancialRatioChartData: true,
      }
    })
    builder.addCase(getFinancialRatioChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getFinancialRatioChartData: false,
      }
      state.data = {
        ...state.data,
        getFinancialRatioChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getFinancialRatioChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getFinancialRatioChartData: action.payload.loading,
      }
    })
    // getPeersComparisonChartData
    builder.addCase(getPeersComparisonChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getPeersComparisonChartData: true,
      }
    })
    builder.addCase(getPeersComparisonChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getPeersComparisonChartData: false,
      }
      state.data = {
        ...state.data,
        getPeersComparisonChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getPeersComparisonChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getPeersComparisonChartData: action.payload.loading,
      }
    })
    // getAssetBreakdownChartData
    builder.addCase(getAssetBreakdownChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getAssetBreakdownChartData: true,
      }
    })
    builder.addCase(getAssetBreakdownChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getAssetBreakdownChartData: false,
      }
      state.data = {
        ...state.data,
        getAssetBreakdownChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getAssetBreakdownChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getAssetBreakdownChartData: action.payload.loading,
      }
    })
    // getOperatingSalesStructureChartData
    builder.addCase(getOperatingSalesStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getOperatingSalesStructureChartData: true,
      }
    })
    builder.addCase(
      getOperatingSalesStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getOperatingSalesStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getOperatingSalesStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getOperatingSalesStructureChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getOperatingSalesStructureChartData: action.payload.loading,
        }
      },
    )
    // getOperatingExpensesStructureChartData
    builder.addCase(getOperatingExpensesStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getOperatingExpensesStructureChartData: true,
      }
    })
    builder.addCase(
      getOperatingExpensesStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getOperatingExpensesStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getOperatingExpensesStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getOperatingExpensesStructureChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getOperatingExpensesStructureChartData: action.payload.loading,
        }
      },
    )
    // getMarginLendingChartData
    builder.addCase(getMarginLendingChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getMarginLendingChartData: true,
      }
    })
    builder.addCase(getMarginLendingChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getMarginLendingChartData: false,
      }
      state.data = {
        ...state.data,
        getMarginLendingChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getMarginLendingChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getMarginLendingChartData: action.payload.loading,
      }
    })
    // getFinancialAssetsStructureChartData
    builder.addCase(getFinancialAssetsStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getFinancialAssetsStructureChartData: true,
      }
    })
    builder.addCase(
      getFinancialAssetsStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getFinancialAssetsStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getFinancialAssetsStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getFinancialAssetsStructureChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getFinancialAssetsStructureChartData: action.payload.loading,
        }
      },
    )
    // getSTAndLTBorrowingChartData
    builder.addCase(getSTAndLTBorrowingChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getSTAndLTBorrowingChartData: true,
      }
    })
    builder.addCase(getSTAndLTBorrowingChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getSTAndLTBorrowingChartData: false,
      }
      state.data = {
        ...state.data,
        getSTAndLTBorrowingChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getSTAndLTBorrowingChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getSTAndLTBorrowingChartData: action.payload.loading,
      }
    })
    // getRevenueAndProfitChartData
    builder.addCase(getRevenueAndProfitChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getRevenueAndProfitChartData: true,
      }
    })
    builder.addCase(getRevenueAndProfitChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getRevenueAndProfitChartData: false,
      }
      state.data = {
        ...state.data,
        getRevenueAndProfitChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getRevenueAndProfitChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getRevenueAndProfitChartData: action.payload.loading,
      }
    })
    // getProfitabilityChartData
    builder.addCase(getProfitabilityChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getProfitabilityChartData: true,
      }
    })
    builder.addCase(getProfitabilityChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getProfitabilityChartData: false,
      }
      state.data = {
        ...state.data,
        getProfitabilityChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getProfitabilityChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getProfitabilityChartData: action.payload.loading,
      }
    })
    // getInventoryStructureChartData
    builder.addCase(getInventoryStructureChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getInventoryStructureChartData: true,
      }
    })
    builder.addCase(
      getInventoryStructureChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getInventoryStructureChartData: false,
        }
        state.data = {
          ...state.data,
          getInventoryStructureChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getInventoryStructureChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getInventoryStructureChartData: action.payload.loading,
        }
      },
    )
    // getFinancialExpensesChartData
    builder.addCase(getFinancialExpensesChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getFinancialExpensesChartData: true,
      }
    })
    builder.addCase(
      getFinancialExpensesChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getFinancialExpensesChartData: false,
        }
        state.data = {
          ...state.data,
          getFinancialExpensesChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(getFinancialExpensesChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getFinancialExpensesChartData: action.payload.loading,
      }
    })
    // getReservesChartData
    builder.addCase(getReservesChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getReservesChartData: true,
      }
    })
    builder.addCase(getReservesChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getReservesChartData: false,
      }
      state.data = {
        ...state.data,
        getReservesChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getReservesChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getReservesChartData: action.payload.loading,
      }
    })
    // getTICSEChartData
    builder.addCase(getTICSEChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getTICSEChartData: true,
      }
    })
    builder.addCase(getTICSEChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getTICSEChartData: false,
      }
      state.data = {
        ...state.data,
        getTICSEChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getTICSEChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getTICSEChartData: action.payload.loading,
      }
    })
    // getInsurancePremiumBreakdownChartData
    builder.addCase(getInsurancePremiumBreakdownChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getInsurancePremiumBreakdownChartData: true,
      }
    })
    builder.addCase(
      getInsurancePremiumBreakdownChartData.fulfilled,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getInsurancePremiumBreakdownChartData: false,
        }
        state.data = {
          ...state.data,
          getInsurancePremiumBreakdownChartData:
            action.payload.data?.map((item) => ({
              ...item,
              label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
            })) || [],
        }
      },
    )
    builder.addCase(
      getInsurancePremiumBreakdownChartData.rejected,
      (state, action) => {
        state.loading = {
          ...state.loading,
          getInsurancePremiumBreakdownChartData: action.payload.loading,
        }
      },
    )
    // getFinancialIncomeChartData
    builder.addCase(getFinancialIncomeChartData.pending, (state) => {
      state.loading = {
        ...state.loading,
        getFinancialIncomeChartData: true,
      }
    })
    builder.addCase(getFinancialIncomeChartData.fulfilled, (state, action) => {
      state.loading = {
        ...state.loading,
        getFinancialIncomeChartData: false,
      }
      state.data = {
        ...state.data,
        getFinancialIncomeChartData:
          action.payload.data?.map((item) => ({
            ...item,
            label: getDataTitle(action.meta.arg.TimeFilter, item, '-'),
          })) || [],
      }
    })
    builder.addCase(getFinancialIncomeChartData.rejected, (state, action) => {
      state.loading = {
        ...state.loading,
        getFinancialIncomeChartData: action.payload.loading,
      }
    })
  },
})

export const selectChartLoading = (state) => state[slice.name].loading
export const selectChartData = (state) => state[slice.name].data
export const { changeComGroup, changeStatement, changeFinancialChartFilter } =
  slice.actions

register(slice.name, slice.reducer)
