export const PEERS_COMPARISON_KEYS = [
  'totalProduction',
  'totalConsumption',
  'totalInventory',

  'productionGal',
  'consumptionGal',
  'inventoryGal',

  'productionPainted',
  'consumptionPainted',
  'inventoryPainted',

  'productionOther',
  'consumptionOther',
  'inventoryOther',
]

export const PEERS_COMPARISON_FILTER = [
  {
    name: 'sector.sectorSpecific.steelCoat.TOTAL',
    value: 0,
  },
  {
    name: 'sector.sectorSpecific.steelCoat.STEEL_GAL',
    value: 1,
  },
  {
    name: 'sector.sectorSpecific.steelCoat.STEEL_PAINTED',
    value: 2,
  },
  {
    name: 'sector.sectorSpecific.steelCoat.OTHER',
    value: 3,
  },
]
