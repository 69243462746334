import TopInfo from '../../../../common/topInfo/stockTopInfo'
import Content from './Content'

const Comparable = () => {
  return (
    <>
      <div style={{ paddingBottom: '4px' }}>
        <TopInfo height="30px" isHidePriceData isIncludeBond />
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 34px)`,
        }}
      >
        <Content />
      </div>
    </>
  )
}

export default Comparable
