import { createAsyncThunk } from '@reduxjs/toolkit'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'

export const getImportContributionByProduct = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportContributionByProduct',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getImportContributionByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportContributionByMarket = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportContributionByMarket',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getImportContributionByMarket(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
