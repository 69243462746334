import TopInfo from '../../../../common/topInfo/stockTopInfo'
import Content from './Content'

export const DebtAndLoan = () => {
  // const dispatch = useDispatch()
  // const activeTab = useSelector(selectFilterDebtAndLoan)

  // const handleChangeActiveTab = (item) => {
  //   dispatch(
  //     changeFilterDebtAndLoan({
  //       value: item.value,
  //     }),
  //   )
  // }

  return (
    <>
      <div
        style={{ paddingBottom: '4px' }}
        className="d-flex justify-content-space-between"
      >
        <TopInfo height="30px" isHidePriceData isIncludeBond />
        {/* <DispatchActionTab
          data={QUARTER_YEAR_TABS}
          activeTab={activeTab}
          onChangeTab={handleChangeActiveTab}
          containerStyle={{ width: '150px' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        /> */}
      </div>
      <div
        style={{
          height: `calc(100% - 44px)`,
          position: 'relative',
        }}
      >
        <Content />
      </div>
    </>
  )
}
