import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { ValueInvestor } from '../../common/valueInvestorChart'
import { selectFilter } from '../tableStatisticsForeign/store/slice'
import { selectDataChart, selectLoading } from './store/slice'
import {
  getNetValueByInvestorChartOverview,
  getNetValueByInvestorChartTicker,
} from './store/thunk'

export const PanelValueInvestor = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const filterData = useSelector(selectFilter)

  const titlePanel = 'market.coveredWarrant.cwStatistics.NET_VALUE_INVESTOR'
  const titleJpg = I18n.t(
    'market.coveredWarrant.cwStatistics.NET_VALUE_INVESTOR',
  )
  const nameScreen = I18n.t('market.coveredWarrant.cwStatistics.CW_STATISTICS')
  const tabName = I18n.t('market.coveredWarrant.cwStatistics.foreign.FOREIGN')

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'valueInvestor',
        nameScreen: nameScreen,
        chartName: titleJpg,
        tabName: tabName,
      }}
    >
      <ValueInvestor
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        dataChart={dataChart}
        isLoading={isLoading}
        filterData={filterData}
        getNetValueByInvestorChartTicker={getNetValueByInvestorChartTicker}
        getNetValueByInvestorChartOverview={getNetValueByInvestorChartOverview}
      />
    </Panel>
  )
}
