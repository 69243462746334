import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { PRICE_DATA_TIME_SELECT, TRANSACTION_TYPES } from '../../constants'
import { formatValNumber, getFormatDateTime } from '../../helps'
import ColorCellType from '../ColorCell'
import { TABLE_TYPE } from '../constants'
import {
  priceDataTableCol,
  tableColByTransactionType,
  tableFormatValue,
  tableHeadColDummy,
  tableHeaderColAttr,
  tableHeaderColByTransactionType,
  tableHeadTab,
} from './constants'
import { ThCellTab } from './ThCellTab'

const getValue = (val, colId) => {
  return val / tableFormatValue[colId]
}

export const getTableSchema = (
  activeType,
  { TimeFilter: timeFilter },
  locale,
  dataByIds,
  thTab,
  timeZone,
  transactionType,
) => {
  return Object.keys(priceDataTableCol[activeType][thTab])
    .filter(
      (key) =>
        !(
          activeType === TABLE_TYPE.DETAIL &&
          priceDataTableCol[activeType][thTab][key] ===
            priceDataTableCol.detail[thTab].DATE &&
          timeFilter === PRICE_DATA_TIME_SELECT.CUSTOM
        ) && tableColByTransactionType[transactionType].includes(key),
    )
    .map((key, index) => {
      const title = `market.marketInDepth.indexStatistics.priceData.statisticsInvest.${key}`
      const colId = priceDataTableCol[activeType][thTab][key]
      const result = {
        colId,
        title,
      }

      if (
        ![
          priceDataTableCol[activeType][thTab].DATE,
          priceDataTableCol.detail[thTab].TICKER,
        ].includes(colId)
      ) {
        const customSchema = {
          ...result,
          isI18n: false,
          tdStyle: {
            textAlign: 'right',
          },
        }

        if (
          [
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_VAL,
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_DEAL_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_DEAL_VAL,
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_MATCH_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INDI_NET_MATCH_VAL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_VAL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_DEAL_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_DEAL_VAL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_MATCH_VOL,
            priceDataTableCol[activeType][thTab].LOCAL_INSTI_NET_MATCH_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_DEAL_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_DEAL_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_MATCH_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INDI_NET_MATCH_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_DEAL_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_DEAL_VAL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_MATCH_VOL,
            priceDataTableCol[activeType][thTab].FOREIGN_INSTI_NET_MATCH_VAL,
          ].includes(colId)
        ) {
          return {
            ...customSchema,
            render: (val) => {
              return (
                <ColorCellType
                  val={getValue(val, colId)}
                  format={(val) => formatValNumber(val)}
                />
              )
            },
          }
        }

        return {
          ...customSchema,
          render: (val) => formatValNumber(getValue(val, colId)),
        }
      }

      const customSchema = {
        ...result,
        isI18n: false,
        tdStyle: {
          textAlign: 'left',
        },
      }

      if (colId === priceDataTableCol.detail[thTab].TICKER) {
        return {
          ...customSchema,
          disableSort: true,
          render: (val, rowId) => (
            <LinkTicker organizationId={dataByIds[rowId]?.organizationId}>
              {val}
            </LinkTicker>
          ),
        }
      }

      if (colId === priceDataTableCol.overview[thTab].DATE) {
        switch (timeFilter) {
          case PRICE_DATA_TIME_SELECT.DAILY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.DATE'
            break
          case PRICE_DATA_TIME_SELECT.WEEKLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.WEEK'
            break
          case PRICE_DATA_TIME_SELECT.MONTHLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.MONTH'
            break
          case PRICE_DATA_TIME_SELECT.QUARTERLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.QUARTER'
            break
          case PRICE_DATA_TIME_SELECT.YEARLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.YEAR'
            break
          default:
            break
        }
        return {
          ...customSchema,
          render: (val) => {
            const date = getFormatDateTime(val, timeFilter, locale, timeZone)
            return (
              <div style={{ width: 'max-content' }}>
                {date === EMPTY_VALUE ? val : date}
              </div>
            )
          },
        }
      }

      return customSchema
    })
}

export const getTableRowSpanSchema = (
  activeType,
  { TimeFilter: timeFilter },
  thTab,
  width,
  transactionType,
) =>
  tableHeaderColAttr[activeType][thTab].map((row, rowIndex) => {
    return row
      .filter(
        (col) =>
          !(
            activeType === TABLE_TYPE.DETAIL &&
            col.title === 'DATE' &&
            timeFilter === PRICE_DATA_TIME_SELECT.CUSTOM
          ) &&
          (rowIndex === 0 ||
            tableHeaderColByTransactionType[transactionType].includes(
              col.title,
            )),
      )
      .map((col, colIndex) => {
        let title = `market.marketInDepth.indexStatistics.priceData.statisticsInvest.${col.title}`

        if (col.colId === priceDataTableCol.overview[thTab].DATE) {
          switch (timeFilter) {
            case PRICE_DATA_TIME_SELECT.DAILY:
              title = 'market.marketInDepth.indexStatistics.priceData.DATE'
              break
            case PRICE_DATA_TIME_SELECT.WEEKLY:
              title = 'market.marketInDepth.indexStatistics.priceData.WEEK'
              break
            case PRICE_DATA_TIME_SELECT.MONTHLY:
              title = 'market.marketInDepth.indexStatistics.priceData.MONTH'
              break
            case PRICE_DATA_TIME_SELECT.QUARTERLY:
              title = 'market.marketInDepth.indexStatistics.priceData.QUARTER'
              break
            case PRICE_DATA_TIME_SELECT.YEARLY:
              title = 'market.marketInDepth.indexStatistics.priceData.YEAR'
              break
            default:
              break
          }
        }

        const result = {
          ...col,
          isI18n: false,
          title,
          thStyle: {
            ...col.thStyle,
            fontSize: 10,
            fontWeight: 'bold',
          },
        }

        if (
          col.colSpan ||
          col.colId === priceDataTableCol.detail[thTab].TICKER
        ) {
          result.disableSort = true
        }

        if (Object.values(tableHeadTab).includes(col.colId)) {
          result.renderTh = ({ left }) => (
            <ThCellTab left={left} width={width} />
          )
        }

        if (col.colId === tableHeadColDummy) {
          result.renderTh = () => <></>

          if (transactionType !== TRANSACTION_TYPES.ALL) {
            result.colSpan = 5
          }
        }

        return result
      })
  })
