import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { groupScrollComponentClass } from '../../common/ScrollGroupComponent'
import { Z_INDEX } from '../../constants/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  COMPONENT_RESIZE_START,
  FULL_COMPONENT,
} from '../../utils/EventEmitter'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { COMPONENT } from './../../../configs/Layout'

let newWidth = null
let newHeight = null
let diff = null
const DEFAULT_POSITION = -6

export const RESIZE_TYPE = {
  RESIZE_X: 'RESIZE_X',
  RESIZE_Y: 'RESIZE_Y',
}

export const Resize = ({
  setSizes,
  sizes,
  panelKey,
  resizeClassName,
  type,
  size,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [isShow, setIsShow] = useState(false)
  const [disable, setDisable] = useState(false)
  const [position, setPosition] = useState(DEFAULT_POSITION)

  useEffect(() => {
    const disableResize = () => {
      setDisable(true)
    }
    EventEmitter.subscribe(FULL_COMPONENT, disableResize)
    return () => EventEmitter.unsubscribe(FULL_COMPONENT, disableResize)
  }, [])

  useEffect(() => {
    const allowResize = () => {
      setDisable(false)
    }
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, allowResize)
    return () => EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, allowResize)
  }, [])

  const onMouseEnter = () => {
    if (!disable) {
      setIsShow(true)
    }
  }

  const onMouseLeave = () => {
    if (position === DEFAULT_POSITION) {
      setIsShow(false)
    }
  }

  const ref = useRef({})
  const onResizeColumn = (e) => {
    const minWidth = sizes[panelKey].minWidth || COMPONENT.MIN_SIZE.WIDTH
    const minHeight = sizes[panelKey].minHeight || COMPONENT.MIN_SIZE.HEIGHT
    if (type === RESIZE_TYPE.RESIZE_X) {
      const width =
        sizes[panelKey].width +
        (formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
          ref.current.clientX)
      if (width < minWidth) {
        return
      }

      newWidth = Math.max(width, minWidth)
      diff = newWidth - sizes[panelKey].width

      sizes[panelKey].verticalResize.forEach(({ key, ratioWidth }) => {
        const width = sizes[key].width - ratioWidth * diff
        const itemMinWidth = sizes[key].minWidth || COMPONENT.MIN_SIZE.WIDTH
        if (width <= itemMinWidth) {
          diff = (sizes[key].width - itemMinWidth) / ratioWidth
        }
      })

      newWidth = sizes[panelKey].width + diff
      setPosition(position - diff)
    } else {
      const height =
        sizes[panelKey].height +
        (formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true) -
          ref.current.clientY)
      if (height < minHeight) {
        return
      }
      newHeight = Math.max(height, minHeight)
      diff = newHeight - sizes[panelKey].height

      sizes[panelKey].horizontalResize.forEach(({ key, ratioHeight }) => {
        const height = sizes[key].height - ratioHeight * diff
        const itemMinHeight = sizes[key].minHeight || COMPONENT.MIN_SIZE.HEIGHT
        if (height <= itemMinHeight) {
          diff = (sizes[key].height - itemMinHeight) / ratioHeight
        }
      })

      newHeight = sizes[panelKey].height + diff
      setPosition(position - diff)
    }
  }

  const onStopResizeColumn = () => {
    if (diff) {
      setPosition(DEFAULT_POSITION)

      if (type === RESIZE_TYPE.RESIZE_X) {
        const obj = {}
        sizes[panelKey].verticalResize.forEach(
          ({ key, ratioWidth, ratioLeft }) => {
            obj[key] = {
              ...sizes[key],
              width: sizes[key].width - ratioWidth * diff,
              left: sizes[key].left + ratioLeft * diff,
            }
          },
        )

        setSizes({
          ...sizes,
          [panelKey]: {
            ...sizes[panelKey],
            width: newWidth,
          },
          ...obj,
        })
      } else {
        const obj = {}
        sizes[panelKey].horizontalResize.forEach(
          ({ key, ratioHeight, ratioTop }) => {
            obj[key] = {
              ...sizes[key],
              height: sizes[key].height - ratioHeight * diff,
              top: sizes[key].top + ratioTop * diff,
            }
          },
        )

        setSizes({
          ...sizes,
          [panelKey]: {
            ...sizes[panelKey],
            height: newHeight,
          },
          ...obj,
        })
      }

      newWidth = null
      newHeight = null
      diff = null
      EventEmitter.dispatch(COMPONENT_RESIZE)
    }
    setIsShow(false)
    if (
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      )
    ) {
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.overflow = 'scroll'
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.marginBottom = '-17px'
    }
    window.removeEventListener('mousemove', onResizeColumn)
    window.removeEventListener('mouseup', onStopResizeColumn)
    window.removeEventListener('touchmove', onResizeColumn)
    window.removeEventListener('touchend', onStopResizeColumn)
  }

  const onResizeStart = (e) => {
    EventEmitter.dispatch(COMPONENT_RESIZE_START)
    setIsShow(true)
    ref.current.clientX = formatWithZoom(
      e.clientX || e.touches?.[0]?.clientX || 0,
      true,
    )
    ref.current.clientY = formatWithZoom(
      e.clientY || e.touches?.[0]?.clientY || 0,
      true,
    )
    if (
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      )
    ) {
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.overflow = 'hidden'
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.marginBottom = '0'
    }
    window.addEventListener('mousemove', onResizeColumn)
    window.addEventListener('mouseup', onStopResizeColumn)
    window.addEventListener('touchmove', onResizeColumn)
    window.addEventListener('touchend', onStopResizeColumn)
  }

  const getStyle = () => {
    const styles = {
      backgroundColor: isShow ? '#454b56' : '',
      zIndex: Z_INDEX.PANEL_RESIZE,
    }

    if (type === RESIZE_TYPE.RESIZE_X) {
      return {
        ...styles,
        right: position,
        height: formatWithZoom(size.height),
        cursor: isShow ? 'ew-resize' : 'default',
      }
    }

    return {
      ...styles,
      bottom: position,
      width: formatWithZoom(size.width),
      cursor: isShow ? 'ns-resize' : 'default',
    }
  }

  return (
    <div
      className={resizeClassName}
      style={getStyle()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onResizeStart}
      onMouseDown={onResizeStart}
    />
  )
}

Resize.propTypes = {
  setSizes: PropTypes.func.isRequired,
  sizes: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  resizeClassName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
}
