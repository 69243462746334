import SectorsReport from '.'
import { Panel } from '../../../../common/panel'

const PanelSectorsReport = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title={'sector.sectorStatistics.overview.SECTORS_REPORT'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
    >
      <SectorsReport />
    </Panel>
  )
}

export default PanelSectorsReport
