import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import style from '../../index.module.css'
import { selectWatchlistTickerByIds } from '../../store/slice'

const Count = () => {
  const tickerByIds = useSelector(selectWatchlistTickerByIds)

  const tickerIds = Object.keys(tickerByIds)
  const totalChecked = tickerIds.filter((id) => tickerByIds[id].checked).length
  const total = tickerIds.length

  return (
    <div className={`mt-8 ${style.disabled}`}>
      <Translate value="tool.smartScreening.SELECTED" />: {totalChecked}/{total}
    </div>
  )
}

export default Count
