import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { YEAR_OPTIONS } from '../../../constants'
import { getTop5Indicator, handleLocationName } from '../helper'
import {
  getGRDPStructureInByProvince,
  getLocationName,
  getMaxPeriod,
} from './thunk'

const initialState = {
  locationId: 628,
  pieChartLoading: true,
  pieChartData: [],
  year: new Date().getFullYear(),
  rangeYear: YEAR_OPTIONS,
  locationName: [],
  locationLoading: true,
  limitChartLoading: true,
}

const slice = createSlice({
  name: 'economy/gdp/gdpByProvince/panelGRDPStructureProvince',
  initialState,
  reducers: {
    handleLocationId: (state, action) => {
      state.locationId = action.payload
    },
    handleYear: (state, action) => {
      state.year = action.payload
    },
    handleRangeYear: (state, action) => {
      state.rangeYear = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGRDPStructureInByProvince.pending, (state) => {
      state.pieChartLoading = true
    })
    builder.addCase(getGRDPStructureInByProvince.fulfilled, (state, action) => {
      state.pieChartLoading = false
      state.pieChartData = getTop5Indicator(action.payload || [])
    })
    builder.addCase(getGRDPStructureInByProvince.rejected, (state, action) => {
      state.pieChartLoading = action.payload.isLoading
    })
    builder.addCase(getLocationName.pending, (state) => {
      state.locationLoading = true
    })
    builder.addCase(getLocationName.fulfilled, (state, action) => {
      state.locationLoading = false
      state.locationName = handleLocationName(action.payload || [])
    })
    builder.addCase(getLocationName.rejected, (state, action) => {
      state.locationLoading = action.payload.isLoading
    })
    builder.addCase(getMaxPeriod.pending, (state) => {
      state.limitChartLoading = true
    })
    builder.addCase(getMaxPeriod.fulfilled, (state, action) => {
      state.limitChartLoading = false
    })
    builder.addCase(getMaxPeriod.rejected, (state, action) => {
      state.limitChartLoading = action.payload.isLoading
    })
  },
})

export const { handleCity, handleYear, handleLocationId, handleRangeYear } =
  slice.actions
export const getPieChartLoading = (state) => state[slice.name].pieChartLoading
export const getPieChartData = (state) => state[slice.name].pieChartData
export const getCity = (state) => state[slice.name].city
export const getYear = (state) => state[slice.name].year
export const getLocationId = (state) => state[slice.name].locationId
export const selectLocationName = (state) => state[slice.name].locationName
export const getLocationNameLoading = (state) =>
  state[slice.name].locationNameLoading
export const getRangeYear = (state) => state[slice.name].rangeYear
export const getLimitChartLoading = (state) =>
  state[slice.name].limitChartLoading

register(slice.name, slice.reducer)
