import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DetailMetrics from '.'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { convertType } from '../helper'
import { selectQuarter, selectTypeFilter, selectYear } from '../store/slice'
import { resetStore } from './store/slice'
import { getDownloadDetailMetrics } from './store/thunk'

const PanelDetailMetrics = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const stockInfo = useSelector(selectBasicInfo)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <Panel
      title={'corporate.businessModel.penetrationAnalysis.DETAIL_METRICS'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv={true}
      downloadCsv={() =>
        getDownloadDetailMetrics({
          OrganizationId: stockInfo.organizationId,
          BusinessActivity: convertType(typeFilter),
          Quarter: filterQuarter ? filterQuarter : undefined,
          Year: filterYear,
        })
      }
    >
      <DetailMetrics />
    </Panel>
  )
}

export default PanelDetailMetrics
