import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom/index.js'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { TIME_FREQUENCY_TABS, listRemainingDurationType } from '../constants.js'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectFilterMarketTradingValueBySector,
} from '../store/slice.js'
import { getMarketTradingValueBySectorData } from '../store/thunk.js'
import FilterSectorTradingValueBySector from './FilterSectorTradingValueBySector.js'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const methodType = useSelector(selectActiveMethodType)
  const { timeRange, top, remainingDurationType, From, To, icbIds } =
    useSelector(selectFilterMarketTradingValueBySector)

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE_BY_SECTOR,
        key: 'timeRange',
        value: item.value,
      }),
    )
  }

  const handleChangeRemainingDurationType = (item) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE_BY_SECTOR,
        key: 'remainingDurationType',
        value: item,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE_BY_SECTOR,
        key: 'timeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE_BY_SECTOR,
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE_BY_SECTOR,
        key: 'To',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload =
      timeRange === calendarTabKey
        ? {
            issueMethodId: methodType,
            icbIds,
            top,
            remainingDurationType,
            From,
            To,
          }
        : {
            issueMethodId: methodType,
            timeRange: timeRange,
            icbIds,
            top,
            remainingDurationType,
          }

    dispatch(getMarketTradingValueBySectorData(payload))
  }, [
    locale,
    methodType,
    timeRange,
    top,
    From,
    To,
    icbIds.length,
    remainingDurationType,
  ])

  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 8 }}
      >
        <div style={{ width: 150, marginRight: 8 }}>
          <SelectCustom
            value={remainingDurationType ?? listRemainingDurationType[0].value}
            selectData={listRemainingDurationType}
            handleChange={(e) => handleChangeRemainingDurationType(e)}
            isI18n={true}
          />
        </div>
        <FilterSectorTradingValueBySector />
      </div>
      <DispatchActionTabWithCalendar
        id="marketTradingValueBySector"
        tabs={TIME_FREQUENCY_TABS}
        activeTab={timeRange}
        startDate={From}
        endDate={To}
        datePreset={TIME_FREQUENCY_TABS}
        excludeDateIntervals={[]}
        onChangeTab={handleChangeTimeType}
        onChangeCalendar={handleChangeCalendar}
      />
    </div>
  )
}

export default Filter
