import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FEATURES } from '../../constants/routes'
import {
  getSearchItemMoreOptions,
  getStockIndicesSectorInfo,
  highlightString,
} from '../../utils/Search'
import { SEARCH_TYPE } from '../constants'
import style from './index.module.css'
import { SearchItemDropdown } from './SearchItemDropdown'

export const SecOverviewItem = (props) => {
  const { searchStr, data, getBasicInfo } = props
  const navigate = useCustomNavigate()

  const [isActive, setIsActive] = useState(false)

  const getIcbName = () => {
    let icbName = ''
    let icbNameHighlight
    if (data.icbName) {
      icbName = data.icbName
      icbNameHighlight = data.highlight.icbName
    } else if (data.en_IcbName) {
      icbName = data.en_IcbName
      icbNameHighlight = data.highlight.en_IcbName
    } else {
      return
    }
    return highlightString(icbName, icbNameHighlight, searchStr)
  }

  const onToggleMoreOption = (value) => {
    setIsActive(value)
  }

  const onRedirect = () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components.overview
        .route,
      getStockIndicesSectorInfo(data, getBasicInfo),
    )
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div
        className={`d-flex align-center j-b search-content ${
          style['search-content']
        } ${isActive ? style['search-content-active'] : ''}`}
      >
        <div>
          <div className={style['item-title']}>{getIcbName()}</div>
          <div className="date">
            <Translate value="layout.advancedSearch.ICB_LEVEL" />
            <span> {data.icbLevel}</span>
          </div>
        </div>
        <SearchItemDropdown
          data={data}
          options={getSearchItemMoreOptions(SEARCH_TYPE.SECTOR, [], FEATURES)}
          onToggle={onToggleMoreOption}
          getBasicInfo={getBasicInfo}
        />
      </div>
    </a>
  )
}

SecOverviewItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
