import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { NAVIGATION_STEEL, STEEL_TAB } from './constants'
import {
  changeMonthConstructionTab,
  changeYearConstructionTab,
  selectDefaultMaxMonthConstructionTab,
  selectDefaultMaxYearConstructionTab,
} from './construction/steelStatistics/store/slice'
import { getLatestPeriods } from './construction/steelStatistics/store/thunk'
import style from './index.module.css'
import {
  changeMonthCoatTab,
  changeYearCoatTab,
  selectDefaultMaxMonthCoatTab,
  selectDefaultMaxYearCoatTab,
} from './steelCoat/steelStatistics/store/slice'
import {
  changeMonthCoilTab,
  changeYearCoilTab,
  selectDefaultMaxMonthCoilTab,
  selectDefaultMaxYearCoilTab,
} from './steelCoil/steelStatistics/store/slice'
import {
  changeMonthPipeTab,
  changeYearPipeTab,
  selectDefaultMaxMonthPipeTab,
  selectDefaultMaxYearPipeTab,
} from './steelPipe/steelStatistics/store/slice'

export const Steel = () => {
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState(NAVIGATION_STEEL[0].value)
  const [currentComponent, setCurrentComponent] = useState(
    NAVIGATION_STEEL[0].component,
  )

  const defaultMaxMonthConstructionTab = useSelector(
    selectDefaultMaxMonthConstructionTab,
  )
  const defaultMaxYearConstructionTab = useSelector(
    selectDefaultMaxYearConstructionTab,
  )
  const defaultMaxMonthCoatTab = useSelector(selectDefaultMaxMonthCoatTab)
  const defaultMaxYearCoatTab = useSelector(selectDefaultMaxYearCoatTab)
  const defaultMaxMonthCoilTab = useSelector(selectDefaultMaxMonthCoilTab)
  const defaultMaxYearCoilTab = useSelector(selectDefaultMaxYearCoilTab)
  const defaultMaxMonthPipeTab = useSelector(selectDefaultMaxMonthPipeTab)
  const defaultMaxYearPipeTab = useSelector(selectDefaultMaxYearPipeTab)

  const handleDefaultDateByTab = (val) => {
    switch (val) {
      case STEEL_TAB.CONSTRUCTION:
        dispatch(changeMonthConstructionTab(defaultMaxMonthConstructionTab))
        dispatch(changeYearConstructionTab(defaultMaxYearConstructionTab))
        break
      case STEEL_TAB.COAT:
        dispatch(changeMonthCoatTab(defaultMaxMonthCoatTab))
        dispatch(changeYearCoatTab(defaultMaxYearCoatTab))
        break
      case STEEL_TAB.COIL:
        dispatch(changeMonthCoilTab(defaultMaxMonthCoilTab))
        dispatch(changeYearCoilTab(defaultMaxYearCoilTab))
        break
      case STEEL_TAB.PIPE:
        dispatch(changeMonthPipeTab(defaultMaxMonthPipeTab))
        dispatch(changeYearPipeTab(defaultMaxYearPipeTab))
        break
      default:
        dispatch(changeMonthConstructionTab(defaultMaxMonthConstructionTab))
        dispatch(changeYearConstructionTab(defaultMaxYearConstructionTab))
    }
  }

  const handleClickTab = (item) => () => {
    setCurrentTab(item.value)
    setCurrentComponent(item.component)
    handleDefaultDateByTab(item.value)
  }

  useEffect(() => {
    dispatch(getLatestPeriods())
  }, [])

  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="sector.sectorSpecific.steel.STEEL" />
              <VerticalLine mr={12} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_STEEL.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      currentTab === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {currentTab === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    {currentComponent}
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
