import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseFontSize from '../../../common/hooks/useFontSize'
import SelectCustom from '../../../common/selectCustom'
import SelectBorderBlue from '../../../common/selectCustom/SelectBorderBlue'
import { VIEW_BY } from './constants'
import { changeExchangeCode, selectExchangeCode } from './slice'
import style from './style.module.css'

const HeaderViewBy = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const typeFontSize = UseFontSize()

  const RADIO_VIEW_BY = [
    {
      name: I18n.t('sector.sectorConstituents.overview.LB_LISTED'),
      value: VIEW_BY.LISTED,
    },
    {
      name: I18n.t('sector.sectorConstituents.overview.LB_PRIVATE'),
      value: VIEW_BY.PRIVATE,
      disableSelect: true,
    },
    {
      name: I18n.t('sector.sectorConstituents.overview.LB_ALL'),
      value: VIEW_BY.ALL,
      disableSelect: true,
    },
  ]

  const viewBy = useSelector(selectExchangeCode)

  const setFilterTime = (data) => {
    dispatch(changeExchangeCode(data))
  }

  return (
    <div className={`${style.width110px} ${style.ml28}`}>
      <SelectCustom
        key={locale + typeFontSize}
        renderSelect={SelectBorderBlue}
        selectData={RADIO_VIEW_BY}
        value={viewBy}
        handleChange={setFilterTime}
      />
    </div>
  )
}

export default HeaderViewBy
