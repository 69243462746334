import { downloadForeign } from './foreign/store/thunk'
import { downloadOrderStatistic } from './orderStatistic/store/thunk'
import { downloadOverview } from './overview/store/thunk'
import { downloadProprietary } from './proprietary/store/thunk'
import { downloadByInvestor } from './statisticInvest/store/thunk'

export const TAB_TYPE = {
  OVERVIEW: 'overview',
  ORDER_STATISTIC: 'overStatistic',
  FOREIGN: 'foreign',
  PROPRIETARY: 'proprietary',
  STATISTIC_INVEST: 'statisticInvest',
}

export const TABLE_DOWNLOAD = {
  [TAB_TYPE.OVERVIEW]: (params) => downloadOverview(params),
  [TAB_TYPE.FOREIGN]: (params) => downloadForeign(params),
  [TAB_TYPE.ORDER_STATISTIC]: (params) => downloadOrderStatistic(params),
  [TAB_TYPE.PROPRIETARY]: (params) => downloadProprietary(params),
  [TAB_TYPE.STATISTIC_INVEST]: (params) => downloadByInvestor(params),
}
