import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import iconColumnChart from '../icons/iconColumnChart.svg'
import iconLineChart from '../icons/iconLineChart.svg'
import { CHART_TYPE } from './constant'
import style from './index.module.css'
import { changeChartType, selectChartType } from './store/slice'
import { getColumnChart, getLineChart } from './store/thunk'

const ChartType = ({ width }) => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const chartType = useSelector(selectChartType)

  const handleIconClick = () => {
    dispatch(
      changeChartType(
        chartType === CHART_TYPE.COLUMN ? CHART_TYPE.LINE : CHART_TYPE.COLUMN,
      ),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId) {
      const params = {
        OrganizationId: basicInfo.organizationId,
      }
      dispatch(
        chartType === CHART_TYPE.COLUMN
          ? getColumnChart(params)
          : getLineChart(params),
      )
    }
  }, [chartType, basicInfo?.organizationId])

  return (
    <div className="d-flex">
      <div className="cursor-pointer" onClick={handleIconClick}>
        <img
          src={
            chartType === CHART_TYPE.COLUMN ? iconLineChart : iconColumnChart
          }
          alt="iconchart"
        />
      </div>
      {chartType === CHART_TYPE.COLUMN && (
        <>
          <div className={style.verticalLine} />
          <Footer
            key={width}
            appendStyle={{ height: 20, justifyContent: 'flex-start' }}
            list={[
              {
                text: 'corporate.peerValuation.overview.LATEST_COMPANY_RATIO',
                type: TYPE.RECTANGLE,
                before: {
                  bgColor: '#facc5c',
                },
                tooltip: 'corporate.peerValuation.overview.CURRENT_TOOLTIP',
              },
              {
                text: 'corporate.peerValuation.overview.COMPANY_RATIO_RANGE',
                type: TYPE.SQUARE,
                before: {
                  bgColor: '#185999',
                },
              },
              {
                text: 'corporate.peerValuation.overview.LATEST_SECTOR_RATIO',
                renderIcon: <ScatterDiamondShape style={{ marginRight: 5 }} />,
              },
            ]}
          />
        </>
      )}
    </div>
  )
}

export default ChartType
