export const topMoverIndicator = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  FOREIGN_BUY_VOLUME_TOTAL: 'foreignBuyVolumeTotal',
  FOREIGN_SELL_VOLUME_TOTAL: 'foreignSellVolumeTotal',
  NEWS: 'title',
}

export const contributionIndicator = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  CONTRIBUTION: 'contribution',
  PERCENT_CONTRIBUTION: 'percentContribution',
  NEWS: 'title',
}

export const breakoutIndicator = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  AVG_TOTAL_VOLUME: 'avgTotalVolume',
  FOREIGN_BUY_VOLUME_TOTAL: 'foreignBuyVolumeTotal',
  FOREIGN_SELL_VOLUME_TOTAL: 'foreignSellVolumeTotal',
  NEWS: 'title',
}

export const newHighIndicator = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  HIGHEST_PRICE: 'maxHighPriceAdjusted',
  FOREIGN_BUY_VOLUME_TOTAL: 'foreignBuyVolumeTotal',
  FOREIGN_SELL_VOLUME_TOTAL: 'foreignSellVolumeTotal',
  NEWS: 'title',
}

export const newLowIndicator = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  LOWEST_PRICE: 'minLowPriceAdjusted',
  FOREIGN_BUY_VOLUME_TOTAL: 'foreignBuyVolumeTotal',
  FOREIGN_SELL_VOLUME_TOTAL: 'foreignSellVolumeTotal',
  NEWS: 'title',
}

export const topNetForeignVolume = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  FOREIGN_BUY_VOLUME_TOTAL: 'foreignBuyVolumeTotal',
  FOREIGN_SELL_VOLUME_TOTAL: 'foreignSellVolumeTotal',
  FOREIGN_NET_VOLUME_TOTAL: 'foreignNetVolumeTotal',
  NEWS: 'title',
}

export const topNetForeignValue = {
  TICKER: 'ticker',
  EXCHANGE: 'exchange',
  SECTOR: 'icbName',
  MATCH_PRICE: 'matchPrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  FOREIGN_BUY_VALUE_TOTAL: 'foreignBuyValueTotal',
  FOREIGN_SELL_VALUE_TOTAL: 'foreignSellValueTotal',
  FOREIGN_NET_VALUE_TOTAL: 'foreignNetValueTotal',
  NEWS: 'title',
}

export const TYPE_TOP_MOVER = {
  CONTRIBUTION: 'TopContribution',
  VOLUME: 'TopVolume',
  VALUE: 'TopValue',
  GAINER: 'TopGainer',
  LOSER: 'TopLoser',
  BREAKOUT: 'TopBreakout',
  NEW_HIGH: 'TopNewHigh',
  NEW_LOW: 'TopNewLow',
  TOP_NET_FOREIGN_VOLUME: 'TopNetForeignVolume',
  TOP_NET_FOREIGN_VALUE: 'TopNetForeignValue',
}

export const SELECT_TYPE_TOP_MOVER = {
  [TYPE_TOP_MOVER.CONTRIBUTION]: {
    text: 'market.topMover.LB_CONTRIBUTION',
    value: TYPE_TOP_MOVER.CONTRIBUTION,
  },
  [TYPE_TOP_MOVER.VOLUME]: {
    text: 'market.topMover.LB_VOLUME',
    value: TYPE_TOP_MOVER.VOLUME,
  },
  [TYPE_TOP_MOVER.VALUE]: {
    text: 'market.topMover.LB_VALUE',
    value: TYPE_TOP_MOVER.VALUE,
  },
  [TYPE_TOP_MOVER.GAINER]: {
    text: 'market.topMover.LB_GAINER',
    value: TYPE_TOP_MOVER.GAINER,
  },
  [TYPE_TOP_MOVER.LOSER]: {
    text: 'market.topMover.LB_LOSER',
    value: TYPE_TOP_MOVER.LOSER,
  },
  [TYPE_TOP_MOVER.BREAKOUT]: {
    text: 'market.topMover.LB_BREAKOUT',
    value: TYPE_TOP_MOVER.BREAKOUT,
  },
  [TYPE_TOP_MOVER.NEW_HIGH]: {
    text: 'market.topMover.LB_NEW_HIGH',
    value: TYPE_TOP_MOVER.NEW_HIGH,
  },
  [TYPE_TOP_MOVER.NEW_LOW]: {
    text: 'market.topMover.LB_NEW_LOW',
    value: TYPE_TOP_MOVER.NEW_LOW,
  },
  [TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME]: {
    text: 'market.topMover.LB_NET_FOREIGN_VOLUME',
    value: TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME,
  },
  [TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE]: {
    text: 'market.topMover.LB_NET_FOREIGN_VALUE',
    value: TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE,
  },
}

export const SELECT_VOLUME = {
  ONE_POINT_TWO_TIMES: 1.2,
  ONE_POINT_FIVE_TIMES: 1.5,
  TWO_TIMES: 2,
  FIVE_TIMES: 5,
  TEN_TIMES: 10,
}

export const SELECT_TIME_OPTIONS = [
  {
    name: 'market.topMover.ONE_POINT_TWO_TIMES',
    value: SELECT_VOLUME.ONE_POINT_TWO_TIMES,
  },
  {
    name: 'market.topMover.ONE_POINT_FIVE_TIMES',
    value: SELECT_VOLUME.ONE_POINT_FIVE_TIMES,
  },
  {
    name: 'market.topMover.TWO_TIMES',
    value: SELECT_VOLUME.TWO_TIMES,
  },
  {
    name: 'market.topMover.FIVE_TIMES',
    value: SELECT_VOLUME.FIVE_TIMES,
  },
  {
    name: 'market.topMover.TEN_TIMES',
    value: SELECT_VOLUME.TEN_TIMES,
  },
]

export const arrayExchanges = [
  { groupCode: 'market.topMover.LB_ALL_INDEX', exchangeCode: 'Listed' },
  {
    groupCode: 'HOSE',
    exchangeCode: 'VNIndex',
  },
  {
    groupCode: 'HNX',
    exchangeCode: 'HNXIndex',
  },
  {
    groupCode: 'UPCoM',
    exchangeCode: 'UPCOMIndex',
  },
]

export const arrayExchangesTopContribution = [
  {
    groupCode: 'HOSE',
    exchangeCode: 'VNIndex',
  },
  {
    groupCode: 'HNX',
    exchangeCode: 'HNXIndex',
  },
  {
    groupCode: 'UPCoM',
    exchangeCode: 'UPCOMIndex',
  },
]

export const SELECT_TIME_RANGE = {
  ONE_DAY: 'OneDay',
  ONE_WEEK: 'OneWeek',
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
  SIX_MONTHS: 'SixMonths',
  ONE_YEAR: 'OneYear',
}

export const SELECT_TIME_RANGE_OPTIONS = {
  [SELECT_TIME_RANGE.ONE_DAY]: {
    text: 'market.topMover.ONE_DAY',
    value: SELECT_TIME_RANGE.ONE_DAY,
    textValTopBreakout: 'market.topMover.ONE_WEEK',
  },
  [SELECT_TIME_RANGE.ONE_WEEK]: {
    text: 'market.topMover.ONE_WEEK',
    value: SELECT_TIME_RANGE.ONE_WEEK,
    textValTopBreakout: 'market.topMover.ONE_WEEK',
  },
  [SELECT_TIME_RANGE.ONE_MONTH]: {
    text: 'market.topMover.ONE_MONTH',
    value: SELECT_TIME_RANGE.ONE_MONTH,
    textValTopBreakout: 'market.topMover.ONE_MONTH',
  },
  [SELECT_TIME_RANGE.THREE_MONTHS]: {
    text: 'market.topMover.THREE_MONTHS',
    value: SELECT_TIME_RANGE.THREE_MONTHS,
    textValTopBreakout: 'market.topMover.THREE_MONTHS',
  },
  [SELECT_TIME_RANGE.SIX_MONTHS]: {
    text: 'market.topMover.SIX_MONTHS',
    value: SELECT_TIME_RANGE.SIX_MONTHS,
    textValTopBreakout: 'market.topMover.SIX_MONTHS',
  },
  [SELECT_TIME_RANGE.ONE_YEAR]: {
    text: 'market.topMover.ONE_YEAR',
    value: SELECT_TIME_RANGE.ONE_YEAR,
    textValTopBreakout: 'market.topMover.ONE_YEAR',
  },
}

export const VALUE_ORDER = {
  DESC: 0,
  ASC: 1,
}

export const FILTER_COUNT_TOP = 50
