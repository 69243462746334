export const ChannelConfig = {
  StockChannel: 'Realtime.Stock',
  BidAskChannel: 'Realtime.BidAsk.Stock',
  IndexChannel: 'Realtime.Index',
  DerivativeChannel: 'Realtime.Derivative',
  DerivativeBidAskChannel: 'Realtime.BidAsk.Derivative',
  SectorChannel: 'Realtime.Sector',
  CoveredWarrantChannel: 'Realtime.CoveredWarrant',
  CoveredWarrantBidAskChannel: 'Realtime.BidAsk.CoveredWarrant',
  UserLoginChannel: 'UserLogin',
  BidAskBondChannel: 'Realtime.BidAsk.Bond',
  BondChannel: 'Realtime.Bond',
  BidAskBondDealChannel: 'Realtime.BidAsk.BondDeal',
  BondIssuerChannel: 'Realtime.BondIssuer',
  BondIssuerMatchChannel: 'Realtime.BondIssuerMatch',
  BondIssuerDealChannel: 'Realtime.BondIssuerDeal',
}

export const groupHOHAUP = '91'
