import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'

export const TABLE_TABS = {
  STOCK: 'stock',
  // BOND: 'bond',
}

export const SECURITY_HOLDING_TABS = {
  CURRENT: 'current',
  HISTORY: 'history',
}

export const HOLDING_OPTIONS = {
  CONTRIBUTION: 'contribution',
  VOLUME: 'volume',
}

export const TABLE_TIME_RANGES = [
  {
    title: 'YTD',
    value: TIME_RANGES.YearToDate,
  },
  {
    title: '1Y',
    value: TIME_RANGES.OneYear,
  },
  {
    title: '3Y',
    value: TIME_RANGES.ThreeYears,
  },
  {
    title: '5Y',
    value: TIME_RANGES.FiveYears,
  },
]

export const TABLE_COL_KEYS = {
  COL_STOCK_PUBLIC_DATE: 'endDateId',
  COL_STOCK_TICKER: 'ticker',
  COL_STOCK_NAME: 'organizationName',
  COL_STOCK_CLOSE_PRICE: 'closePrice',
  COL_STOCK_PERCENT_CHANGE: 'totalPercentChange1MonthClosePriceAdjusted',
  COL_STOCK_FUND_HOLDING: 'fundHolding',
  COL_STOCK_VOLUME: 'volume',
  COL_STOCK_VOLUME_CHANGE: 'volumeChange',
  COL_STOCK_OUTSTANDING: 'outstandingSharePercent',
  COL_STOCK_VALUE: 'value',
  COL_STOCK_FUND_OWNER_CHANGE: 'valueChange',
  COL_STOCK_VALUE_ICB: 'valueICB',
  COL_STOCK_FUND_OWNER_CHANGE_ICB: 'valueChangeICB',
  COL_STOCK_FUND_NAV: 'portfolioWeight',
}

export const TABLE_COL_FORMATS = {
  COL_STOCK_CLOSE_PRICE: 1,
  COL_STOCK_PERCENT_CHANGE: 1,
  COL_STOCK_VOLUME: 1,
  COL_STOCK_VOLUME_CHANGE: 1,
  COL_STOCK_OUTSTANDING: 1,
  COL_STOCK_VALUE: 10 ** 9,
  COL_STOCK_FUND_OWNER_CHANGE: 10 ** 9,
  COL_STOCK_VALUE_ICB: 10 ** 9,
  COL_STOCK_FUND_OWNER_CHANGE_ICB: 10 ** 9,
  COL_STOCK_FUND_NAV: 1,
  COL_STOCK_VOLUME_ICB: 10 ** 3,
}

export const TABLE_STOCK_CURRENT_SECURITY_HOLDING_KEYS = [
  'COL_STOCK_TICKER',
  'COL_STOCK_NAME',
  'COL_STOCK_CLOSE_PRICE',
  'COL_STOCK_PERCENT_CHANGE',
  'COL_STOCK_VOLUME',
  'COL_STOCK_VOLUME_CHANGE',
  'COL_STOCK_OUTSTANDING',
  'COL_STOCK_VALUE',
  'COL_STOCK_FUND_OWNER_CHANGE',
  'COL_STOCK_FUND_NAV',
].map((key) => ({
  title: 'fund.fundProfile.portfolio.portfolioTable.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_STOCK_CURRENT_SECURITY_HOLDING_TH_ROW_KEYS = [
  [
    'COL_STOCK_TICKER',
    'COL_STOCK_NAME',
    'COL_STOCK_CLOSE_PRICE',
    'COL_STOCK_PERCENT_CHANGE',
    'COL_STOCK_FUND_HOLDING',
  ].map((key) => ({
    title: 'fund.fundProfile.portfolio.portfolioTable.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
  [
    'COL_STOCK_VOLUME',
    'COL_STOCK_VOLUME_CHANGE',
    'COL_STOCK_OUTSTANDING',
    'COL_STOCK_VALUE',
    'COL_STOCK_FUND_OWNER_CHANGE',
    'COL_STOCK_FUND_NAV',
  ].map((key) => ({
    title: 'fund.fundProfile.portfolio.portfolioTable.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]

export const TABLE_STOCK_SECURITY_HOLDING_HISTORY_KEYS = [
  'COL_STOCK_PUBLIC_DATE',
].map((key) => ({
  title: 'fund.fundProfile.portfolio.portfolioTable.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
}
