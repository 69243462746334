import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../../common/textEllipsis'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine'
import { ItemSearch } from '../ItemSearch'
import {
  changeIsAccMonthlyGrowthChart,
  changeIsValueOrGrowthChart,
  changeIsYearlyGrowthChart,
} from '../importGrowth/store/slice'
import style from '../index.module.css'
import {
  TIME_FREQUENCY,
  TIME_FREQUENCY_FILTER,
  TYPE_STATISTIC,
  VAL_GROWTH_FILTER,
  VAL_GROWTH_TYPE,
} from './constants'
import {
  changeCurrentMinMonth,
  changeIsValueOrGrowth,
  changeTimeFrequencyType,
  selectTimeFrequencyType,
} from './store/slice'

const RESPONSIVE_WIDTH = 850

export const FilterList = ({ width, isValueOrGrowth }) => {
  const leftHeaderRef = useRef()
  const radioBoxRef = useRef()

  const dispatch = useDispatch()

  const currentTimeFrequency = useSelector(selectTimeFrequencyType)

  const handleChangeTimeFreq = (item) => {
    dispatch(changeTimeFrequencyType(item.value))
    dispatch(changeCurrentMinMonth(null))
    dispatch(changeIsYearlyGrowthChart(item.value === TIME_FREQUENCY.YEARLY))
    dispatch(
      changeIsAccMonthlyGrowthChart(item.value === TIME_FREQUENCY.ACC_MONTHLY),
    )
  }

  const handleValueOrGrowth = (item) => {
    dispatch(changeIsValueOrGrowth(item.value))
    dispatch(changeIsValueOrGrowthChart(item.value))
    dispatch(changeCurrentMinMonth(null))
  }

  return width > RESPONSIVE_WIDTH ? (
    <>
      <div style={{ width: width }} className="d-flex j-b">
        <div className="d-flex" ref={leftHeaderRef}>
          <div style={{ marginTop: '3px', width: '200px' }}>
            <ItemSearch isInTable statisticType={TYPE_STATISTIC.MARKET} />
          </div>
        </div>
        <div className="d-flex">
          <ul className={`list-check d-flex mt-0`} ref={radioBoxRef}>
            {VAL_GROWTH_FILTER.map((item) => (
              <li key={item.value} className="mb-0">
                <label>
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    checked={item.value === isValueOrGrowth}
                    onChange={() => handleValueOrGrowth(item)}
                  />
                  <Span
                    style={{ fontSize: 12 }}
                    className={
                      item.value === isValueOrGrowth ? style.active : ''
                    }
                  >
                    <TextEllipsis text={item.title} />
                  </Span>
                </label>
              </li>
            ))}
          </ul>
          <VerticalLine mr={12} ml={12} />
          <div style={{ marginTop: '3px' }}>
            <DispatchActionTab
              data={TIME_FREQUENCY_FILTER}
              activeTab={currentTimeFrequency}
              onChangeTab={handleChangeTimeFreq}
              itemStyle={{
                fontWeight: 500,
                fontSize: 11,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              widthComponent={
                width -
                leftHeaderRef.current?.getBoundingClientRect().width -
                radioBoxRef.current?.getBoundingClientRect().width -
                50
              }
            />
          </div>
        </div>
      </div>
      <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
        {isValueOrGrowth === VAL_GROWTH_TYPE.GROWTH
          ? `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
              'sector.sectorSpecific.fishery.PERCENT',
            )}`
          : `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
              'sector.sectorSpecific.fishery.MILLION_USD',
            )}`}
      </Span>
    </>
  ) : (
    <>
      <div className="d-flex" ref={leftHeaderRef}>
        <div style={{ marginTop: '3px', width: '200px' }}>
          <ItemSearch isInTable statisticType={TYPE_STATISTIC.MARKET} />
        </div>
      </div>
      <div style={{ width: width, marginTop: '8px' }} className="d-flex j-b">
        <Span
          style={{ color: '#75797f', fontStyle: 'italic', marginTop: '6px' }}
        >
          {isValueOrGrowth === VAL_GROWTH_TYPE.GROWTH
            ? `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
                'sector.sectorSpecific.fishery.PERCENT',
              )}`
            : `${I18n.t('sector.sectorSpecific.fishery.UNIT')}: ${I18n.t(
                'sector.sectorSpecific.fishery.MILLION_USD',
              )}`}
        </Span>
        <div className="d-flex">
          <ul className={`list-check d-flex mt-0`} ref={radioBoxRef}>
            {VAL_GROWTH_FILTER.map((item) => (
              <li key={item.value} className="mb-0">
                <label>
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    checked={item.value === isValueOrGrowth}
                    onChange={() => handleValueOrGrowth(item)}
                  />
                  <Span
                    style={{ fontSize: 12 }}
                    className={
                      item.value === isValueOrGrowth ? style.active : ''
                    }
                  >
                    <TextEllipsis text={item.title} />
                  </Span>
                </label>
              </li>
            ))}
          </ul>
          <VerticalLine mr={12} ml={12} />
          <div style={{ marginTop: '3px' }}>
            <DispatchActionTab
              data={TIME_FREQUENCY_FILTER}
              activeTab={currentTimeFrequency}
              onChangeTab={handleChangeTimeFreq}
              itemStyle={{
                fontWeight: 500,
                fontSize: 11,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              widthComponent={
                width -
                leftHeaderRef.current?.getBoundingClientRect().width -
                radioBoxRef.current?.getBoundingClientRect().width -
                50
              }
            />
          </div>
        </div>
      </div>
    </>
  )
}
