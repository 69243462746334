import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import style from '../index.module.css'
import { TdSelect } from './TdSelect'
import { VAL_GROWTH_TYPE } from './constants'
import {
  addItemToList,
  removeItemFromList,
  selectActiveItem,
  selectDataTableById,
  selectGroupColumns,
  selectItemDataTableById,
  selectItemIds,
  selectListItems,
  selectLoadingTable,
  selectRowsCollapse,
  setRowsCollapse,
  sort,
} from './store/slice'

const HORIZONTAL_TRACK_LEFT = 28

export const TableComponent = ({
  dataTable,
  minCurrentMonth,
  setNewMaxMonth,
  isFirstRender,
  setIsFirstRenderImportMarketTab,
}) => {
  const dispatch = useDispatch()

  const itemIds = useSelector(selectItemIds)
  const isLoading = useSelector(selectLoadingTable)
  const listItems = useSelector(selectListItems)
  const groupColumns = useSelector(selectGroupColumns)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const activeItem = useSelector(selectActiveItem)
  const dataTableById = useSelector(selectDataTableById)

  const [idsDisplay, setIdsDisplay] = useState([])

  const onRowClick = (rowId) => {
    if (!listItems?.some((item) => item.id === rowId)) {
      const findItemInData = dataTable?.find((item) => item.id === rowId)
      const item = {
        id: rowId,
        name: findItemInData.name,
        productId: findItemInData.productId,
        locationId: findItemInData.locationId,
        productName: findItemInData.seafoodProductName,
        locationName: findItemInData.locationName,
        isGroupRow: findItemInData.isGroupRow,
      }
      dispatch(addItemToList(item))
    } else {
      dispatch(removeItemFromList(rowId))
    }
  }

  const onRowCollapse = (rowId, isCollapse, isDisplaySameId) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
        const childrenIds = dataTableById[rowId].childrenIds.map(
          (id) => `${id}_${dataTableById[rowId].locationId}`,
        )
        setIdsDisplay([...idsDisplay.filter((id) => !childrenIds.includes(id))])
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
      setIdsDisplay([
        ...idsDisplay,
        ...(dataTableById[rowId]?.childrenIds?.map(
          (id) => `${id}_${dataTableById[rowId].locationId}`,
        ) || []),
        ...(isDisplaySameId
          ? dataTable.reduce(
              (arr, item) =>
                item.childrenIds?.includes(dataTableById[rowId]?.productId)
                  ? [
                      ...arr,
                      ...item.childrenIds.map(
                        (id) => `${id}_${item.locationId}`,
                      ),
                    ]
                  : arr,
              [],
            )
          : []),
      ])
    }
  }

  const levelCollapse = keyBy(
    dataTable?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.level === 1,
    })),
    'id',
  )

  const multipleActiveRowsIds =
    activeItem &&
    dataTable
      .filter(
        (item) => !item.isGroupRow && item.productId === activeItem.productId,
      )
      .map((item) => item.id)

  const scrollToLeftCallback = () => {
    setNewMaxMonth(minCurrentMonth)
    dispatch(setIsFirstRenderImportMarketTab(false))
  }

  useEffect(() => {
    if (dataTable.length) {
      const rows = []
      let isPushedChildLevel1 = false
      dataTable.forEach((item) => {
        if (item.level === 1) {
          rows.push(item.id)

          if (
            item.childrenIds?.length &&
            (!isPushedChildLevel1 ||
              item.id === activeItem?.id ||
              item.childrenIds?.includes(activeItem?.productId))
          ) {
            item.childrenIds.forEach((id) =>
              rows.push(`${id}_${item.locationId}`),
            )
            isPushedChildLevel1 = true
          }
        }
      })
      setIdsDisplay(rows)
    }
  }, [dataTable])

  useEffect(() => {
    if (activeItem?.id) {
      onRowCollapse(activeItem.id, false, true)
    }
  }, [activeItem?.id])

  return (
    <Table
      ids={itemIds}
      idsDisplay={idsDisplay}
      getDataFromRedux={selectItemDataTableById}
      isLoading={isLoading}
      columnDraggable={false}
      horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
      hasTooltip={false}
      hasFooter={false}
      resizable={false}
      onRowClick={onRowClick}
      stickyFirstColumn
      schema={groupColumns.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val, rowId) => {
              return (
                <TdSelect
                  val={val}
                  rowId={rowId}
                  onRowCollapse={onRowCollapse}
                  dataTableItem={dataTable?.find((item) => item.id === rowId)}
                  listItems={listItems}
                  isRowCollapse={rowsCollapse.includes(rowId)}
                />
              )
            },
          }
        }
        if (index !== 0) {
          result = {
            ...result,
            thStyle: {
              minWidth: 100,
              textAlign: 'right',
            },
            render: (val) => {
              return (
                <span className={val < 0 ? style.negativeValColor : ''}>
                  {dataTable[0]?.type &&
                  dataTable[0]?.type === VAL_GROWTH_TYPE.GROWTH
                    ? valToPercent(val)
                    : formatVal(val)}
                </span>
              )
            },
          }
        }
        return result
      })}
      isCollapse={true}
      rowsCollapse={rowsCollapse}
      levelCollapse={levelCollapse}
      defaultActiveRowId={activeItem?.id ?? listItems[0]?.id}
      multipleActiveRowsIds={multipleActiveRowsIds}
      isLazyLoadLeft={true}
      scrollToLeftCallback={scrollToLeftCallback}
      defaultScrollToRight={isFirstRender}
      sort={sort}
    />
  )
}
