export const PRICE_DATA_TIME_SELECT = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
}

export const PRODUCTION_INDEX_DATA = [
  {
    title: 'economy.productionAndConsumption.production.YEARLY',
    value: 'Yearly',
  },
  {
    title: 'economy.productionAndConsumption.production.QUARTERLY',
    value: 'Quarterly',
  },
  {
    title: 'economy.productionAndConsumption.production.ACC_QUARTERLY',
    value: 'AccQuarterly',
  },
]

export const DATA_SELECT_VALUE_PRODUCT = {
  VALUE: 'VALUE',
  GROWTH: 'GROWTH',
}

export const TABLE_TYPE_PERIOD = {
  CUR_PERIOD: 'CurPeriod',
  PRE_PERIOD: 'PrePeriod',
}

export const ORIGINAL_NUMBER_FIRST = 17

export const ORIGINAL_NUMBER_FIRST_NOT_YEARLY = 40

export const ORIGINAL_NUMBER = 10

export const LINE_CHART_COLORS = [
  '#ecffdf',
  '#9c54e4',
  '#c7ff58',
  '#f57f07',
  '#559668',
  '#ff2500',
  '#744d23',
  '#f9c105',
  '#85d4ff',
  '#56c10a',
  '#009fe6',
  '#9d9e9e',
  '#f4327b',
  '#930037',
  '#57426d',
  '#4bd9be',
  '#c52e14',
  '#00763e',
  '#975000',
  '#1a36a9',
]
