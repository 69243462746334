import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Area, Line } from 'recharts'
import { getColorByValueChart } from '../../../utils/Color'
import { SizeTracker } from '../../sizeTracker'
import { AXIS_LABEL_POSITION } from '../constants'
import { FooterContainer } from '../footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../footer/Item'
import { getRadiusOfScatter, getXAxisFormatAndTicks } from '../helper'
import { ChartContainer } from '../rechart/ChartContainer'
import UseTimeZone from './../../hooks/useTimeZone'

export const StackedAreaLineChart = ({
  data,
  width,
  height,
  areaKeys,
  lineKeys,
  keyXAxis,
  decimalLength,
  margin,
  timeFrame,
  fillColor,
  mappingDisplayName,
  unit,
  lineUnit,
  leftLabel,
  rightLabel,
  valueKeysColor,
  formatTooltipTitle,
  isFooterResize,
  isFooterCheckbox,
  listCheckbox,
  setListCheckbox,
}) => {
  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  // Get data
  const getDisplayAreaKeys = useMemo(() => {
    return (areaKeys || []).filter((key) => listCheckbox.includes(key))
  }, [listCheckbox])

  // Footer
  const footerData = () => {
    return [
      ...areaKeys.map((key) => ({
        text: mappingDisplayName[key] ?? key,
        type: FOOTER_TYPE.SQUARE,
        color: fillColor[key],
        dataKey: isFooterCheckbox ? key : undefined,
      })),
      ...lineKeys.map((key) => ({
        text: mappingDisplayName[key] ?? key,
        type: FOOTER_TYPE.CIRCLE_MARKER_LINE,
        color: fillColor[key],
      })),
    ]
  }

  // Render tooltip
  const { format } = getXAxisFormatAndTicks({
    data,
    keyXAxis: keyXAxis,
    timeRange: timeFrame,
    width,
    locale,
    timeZone,
  })

  const getFormatValueColor = (val, key) => {
    return valueKeysColor.includes(key) && getColorByValueChart(val)
  }

  const getContent = () => {
    return [
      {
        keys: getDisplayAreaKeys,
        yAxisId: 'areaChart',
        unit: unit,
        decimalLength: decimalLength,
        formatValueColor: getFormatValueColor,
      },
      {
        keys: lineKeys,
        yAxisId: 'lineChart',
        unit: lineUnit,
        decimalLength: decimalLength,
        formatValueColor: getFormatValueColor,
      },
    ]
  }

  // Render
  const renderAreaChart = () => {
    return getDisplayAreaKeys.map((key) => (
      <Area
        key={key}
        stackId="stackArea"
        yAxisId="areaChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fillOpacity={1}
        fill={fillColor[key]}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  const renderLineChart = (chartContentWidth) => {
    return lineKeys.map((key) => (
      <Line
        key={key}
        yAxisId="lineChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={2}
        dot={{
          strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
        }}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker key={isFooterResize}>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={keyXAxis}
              yAxis={[
                {
                  id: 'areaChart',
                  keys: getDisplayAreaKeys,
                  orientation: 'left',
                  yAxisWidth: margin.left,
                  isLineChart: true,
                  tickValues: [0, 25, 50, 75, 100],
                  label: leftLabel ? I18n.t(leftLabel) : undefined,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  unitYAxis: '%',
                },
                {
                  id: 'lineChart',
                  keys: lineKeys,
                  orientation: 'right',
                  isLineChart: true,
                  yAxisWidth: margin.right,
                  label: rightLabel ? I18n.t(rightLabel) : undefined,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                },
              ]}
              timeFrame={timeFrame}
              allowEscapeViewBoxY
              tooltipSchema={{
                title: {
                  formatDate: format,
                  formatValue: formatTooltipTitle,
                },
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {({ chartContentWidth }) => (
                <>
                  {renderAreaChart()}
                  {renderLineChart(chartContentWidth)}
                </>
              )}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            numItemPerRow={isFooterResize ? footerData().length : 3}
            listCheckbox={listCheckbox}
            setListCheckbox={setListCheckbox}
          />
        </>
      )}
    </SizeTracker>
  )
}

StackedAreaLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  areaKeys: PropTypes.array,
  lineKeys: PropTypes.array,
  keyXAxis: PropTypes.string.isRequired,
  decimalLength: PropTypes.number,
  margin: PropTypes.object,
  timeFrame: PropTypes.string,
  fillColor: PropTypes.object.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
  unit: PropTypes.string,
  lineUnit: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  valueKeysColor: PropTypes.array,
  isFooterResize: PropTypes.bool,
  isFooterCheckbox: PropTypes.bool,
}

StackedAreaLineChart.defaultProps = {
  areaKeys: [],
  lineKeys: [],
  valueKeysColor: [],
  isFooterResize: false,
  isFooterCheckbox: false,
}
