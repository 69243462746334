import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../common/sizeTracker'
import { changeChartTabActiveData } from '../../chart/store/slice'
import { dashboardTypes, screenTypes } from '../../constants'
import iconChartOfTheDay from '../../icon/icon-chart-of-the-day.svg'
import iconFavorite from '../../icon/icon-favorite.svg'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { changeDashboard, selectDashboard } from '../../store/slice'
import { Title } from '../Title'
import { useGetUserSettings } from '../useGetUserSettings'
import style from './index.module.css'

export const Left = ({ data, dashboardType }) => {
  const dispatch = useDispatch()

  const { defaultDashboard } = useGetUserSettings()

  const { screenType } = useSelector(selectDashboard)

  const [isEdit, setIsEdit] = useState(false)

  const renderIcon = () => {
    if (dashboardType === dashboardTypes.CHART_OF_THE_DAY) {
      return (
        <img
          alt="icon chart of the day"
          src={iconChartOfTheDay}
          height={18}
          width={18}
        />
      )
    }

    return (
      defaultDashboard?.id &&
      defaultDashboard?.id === data?.id && (
        <img alt="icon favorite" src={iconFavorite} height={18} width={18} />
      )
    )
  }

  const onClick = () => {
    setIsEdit(true)
  }

  const onSubmitUpdateTitle = (data) => {
    dispatch(changeDashboard({ data }))
    dispatch(changeChartTabActiveData({ name: data.name }))
  }

  return (
    <div className="d-flex w-100">
      <SizeTracker className={style.left}>
        {(size) => {
          return (
            <>
              {renderIcon()}
              {size.width && (
                <Title
                  className={style.title}
                  data={data}
                  fontSize={20}
                  isEdit={isEdit}
                  maxWidth={`calc(100% - ${size.width}px)`}
                  setIsEdit={setIsEdit}
                  onSubmit={onSubmitUpdateTitle}
                  isI18n={false}
                />
              )}
              {screenType === screenTypes.EDIT_CHART && (
                <IconRename className="cursor-pointer" onClick={onClick} />
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
