import { useSelector } from 'react-redux'

import { SizeTracker } from '../../../../../../common/sizeTracker'
import { Table } from '../../../../../../common/table'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import { selectIsShowChart } from '../header/store/slice'
import {
  selectDataType,
  selectLoading,
  selectReCalcWidths,
  selectRowIds,
  selectValueById,
  sort,
} from '../store/slice'
import style from './index.module.css'
import { getSchema } from './schemas'

const TableChart = () => {
  const loading = useSelector(selectLoading)
  const basicInfo = useSelector(selectBasicInfo)
  const ids = useSelector(selectRowIds)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const isShowChart = useSelector(selectIsShowChart)
  const dataType = useSelector(selectDataType)

  const idsFormart =
    basicInfo.organizationId && ids.length > 0
      ? [
          basicInfo.organizationId,
          ...ids.filter((id) => id !== basicInfo.organizationId),
        ]
      : []

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div
              style={{ height: `calc(100% - ${size.height || 0}px)` }}
              className={style.tableCustom}
            >
              <Table
                ids={idsFormart}
                getDataFromRedux={selectValueById}
                isLoading={loading}
                schema={getSchema({
                  currentOrgId: basicInfo.organizationId,
                  isShowChart: isShowChart,
                  dataType: dataType,
                })}
                sort={sort}
                reCalcWidths={reCalcWidths}
                hasFooter={false}
                hasTooltip={false}
                stickies={{
                  delete: true,
                  ticker: true,
                  organizationShortName: true,
                }}
                stickyBottomRowCount={1}
                stickyBottomRLeft
              />
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableChart
