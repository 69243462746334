import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { getTab } from '../../store/slice'
import {
  TABLE_TYPE,
  TABLE_TYPE_RADIO,
  TABLE_TYPE_RADIO_PPI,
  TAB_TYPE,
} from '../constants'
import {
  getRadioValuePPI,
  handleIsAllowCallApi,
  handleRadioValuePPI,
} from '../generalIndex/store/slice'
import { resetDataIIP } from '../iip/store/slice'
import {
  changeTypePeriod,
  getYearlyQuarterValue,
  selectTypeDataTime,
  selectTypePeriod,
} from '../store/slice'
import style from './style.module.css'

const ComparisonType = () => {
  const dispatch = useDispatch()

  const typeDataTime = useSelector(selectTypeDataTime)
  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const typePeriod = useSelector(selectTypePeriod)
  const radioValuePPI = useSelector(getRadioValuePPI)
  const tab = useSelector(getTab)

  const radioTypeData =
    tab === TAB_TYPE.GENERAL_INDEX ? TABLE_TYPE_RADIO_PPI : TABLE_TYPE_RADIO
  const currentRadioValue =
    tab === TAB_TYPE.GENERAL_INDEX ? radioValuePPI : typePeriod
  const currentTimeRange =
    tab === TAB_TYPE.GENERAL_INDEX ? yearlyQuarterValue : typeDataTime

  const isDisableRadio = (valueRadio) => {
    return (
      (currentTimeRange === PRICE_DATA_TIME_SELECT.ACC_MONTHLY ||
        currentTimeRange === 'AccQuarterly') &&
      valueRadio === TABLE_TYPE.PRE_PERIOD
    )
  }

  const onRadioClick = (value) => {
    if (tab === TAB_TYPE.GENERAL_INDEX) {
      dispatch(handleRadioValuePPI(value))
      dispatch(handleIsAllowCallApi(true))
    } else {
      dispatch(changeTypePeriod(value))
    }
    dispatch(resetDataIIP())
  }

  return (
    <ul
      style={{ width: 'auto', color: '#8b8b8b' }}
      className="list-check d-flex align-center mt-0"
    >
      <Translate
        style={{ color: 'white' }}
        value={'economy.productionAndConsumption.production.COMPARE_TO'}
      />
      &nbsp;&nbsp;&nbsp;&nbsp;
      {radioTypeData.map((item) => (
        <li key={item.value} className="mb-0">
          <label
            className={`fs-12 ${
              isDisableRadio(item.value) && style.disableLabel
            }`}
          >
            <input
              type="radio"
              className={`radiobox radiobox2 ${
                isDisableRadio(item.value) && style.disableInput
              }`}
              checked={item.value === currentRadioValue}
              onChange={() => onRadioClick(item.value)}
              disabled={isDisableRadio(item.value)}
            />

            <Span
              style={{
                color: item.value === currentRadioValue ? '#2f9aee' : '',
                fontSize: 12,
                maxWidth: '100px',
              }}
            >
              <TextEllipsis isI18n={true} text={item.title} />
            </Span>
          </label>
        </li>
      ))}
    </ul>
  )
}

export default ComparisonType
