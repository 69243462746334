import useWindowDevicePixelRatio from '../../../../common/hooks/useWindowDevicePixelRatio'
import { SizeTracker } from '../../../../common/sizeTracker'
import Filter from './Filter'
import Chart from './chart'
import Lengend from './lengend'

const Result = ({ width, height }) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <div
                id="orceGraph2Dcustorm"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                  width: size.width,
                  zoom: `${formatWithZoom(100, true)}%`,
                }}
              >
                <Chart width={width} height={height - size.height} />
              </div>
            )}
            <Lengend width={width} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Result
