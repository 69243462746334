export const importExport = {
  IMPORT_EXPORT: 'Xuất/Nhập khẩu',

  OVERVIEW: 'Tổng quan',
  EXPORT_IMPORT_BY_COUNTRIES: 'Xuất/nhập khẩu theo quốc gia',
  EXPORT_IMPORT_BY_COMMODITIES: 'Xuất/nhập khẩu theo mặt hàng',

  UNIT: 'Đơn vị',

  FILTER_IMPORT: 'Nhập khẩu',
  FILTER_EXPORT: 'Xuất khẩu',
  FILTER_YEARLY: 'Hàng năm',
  FILTER_MONTHLY: 'Hàng tháng',
  FILTER_MONTHLY_ACC: 'Lũy kế tháng',
  ALL: 'Tất cả',

  RADIO_BUTTON_GROWTH: 'Tăng trưởng',
  RADIO_BUTTON_VALUE: 'Giá trị',

  YOY_GROWTH: 'Tăng trưởng YoY(%)',

  MILLION_USD: 'Triệu USD',
  THOUSAND_BILLION_USD: 'nghìn tỷ USD',
  BILLION_USD: 'Tỷ USD',
  PERCENT: '%',

  RADIO_BUTTON_RELATIVE: 'Tăng trưởng YoY',
  RADIO_BUTTON_ABSOLUTE: 'Giá trị',

  WARNING_MESS: 'Số lượng chỉ tiêu được chọn không vượt quá 20',

  overview: {
    BALANCE_OF_TRADE: 'Cán cân thương mại',
    GROWTH_CATEGORY: 'Tăng trưởng các chỉ tiêu trong cán cân thương mại',
    EXPORT_IMPORT_AND_BALANCE: 'Xuất nhập khẩu và cán cân thương mại',
    OUTSTANDING_TARGET: 'Chỉ tiêu nổi bật',
    COMMODITIES_TOP_10: 'Top 10 mặt hàng xuất, nhập khẩu chính',
    PARTNERS_TOP_10: 'Top 10 thị trường xuất nhập khẩu hàng hóa chủ yếu',
    EXCHANGE_RESERVE: 'Dự trữ ngoại hối của Việt Nam',

    NAME: 'Chỉ tiêu',
    VALUE: 'Giá trị',

    FOOTER_BALANCE_OF_TRADE: 'Cán cân TM',
    FOOTER_EXPORT: 'Xuất khẩu',
    FOOTER_IMPORT: 'Nhập khẩu',
    FOOTER_VALUE_BILLION_USD: 'Giá trị (Tỷ USD)',
    FOOTER_FOREIGN_RESERVE: 'Dự trữ ngoại hối',
    FOOTER_WEEK_IMPORT: 'Tuần nhập khẩu',

    FILTER_COMMODITIES: 'Mặt hàng',
    FILTER_PARTNERS: 'Đối tác',
    FILTER_FOREIGN_RESERVE: 'Dự trữ ngoại hối',

    RADIO_BUTTON_GROWTH: 'Tăng trưởng YoY',
    RADIO_BUTTON_VALUE: 'Giá trị',
  },

  exportImportByCommodities: {
    IMPORT_TURNOVER_TABLE: 'Nhập khẩu theo mặt hàng',
    EXPORT_TURNOVER_TABLE: 'Xuất khẩu theo mặt hàng',

    EXPORT_COMMODITIES_STRUCTURE: 'Giá trị xuất khẩu theo hàng hóa',
    IMPORT_COMMODITIES_STRUCTURE: 'Giá trị nhập khẩu theo hàng hóa',

    EXPORT_TURN_OVER: 'Tăng trưởng xuất khẩu các loại hàng hóa',
    IMPORT_TURN_OVER: 'Tăng trưởng nhập khẩu các loại hàng hóa',

    STRUCTURE: 'Cơ cấu xuất khẩu',
    STRUCTURE_IMPORT: 'Cơ cấu nhập khẩu',
    BY_PARTNERS: 'theo quốc gia',
    VALUE_YEARLY: 'Giá trị xuất khẩu',
    VALUE_YEARLY_IMPORT: 'Giá trị nhập khẩu',
    YEARLY: 'qua các năm',

    FILTER_STRUCTURE: 'Cơ cấu',
    FILTER_GROWTH: 'Giá trị',
    ACC_VALUE: 'Giá trị luỹ kế',
  },

  exportImportByLocation: {
    IMPORT_TURNOVER_TABLE: 'Nhập khẩu theo quốc gia',
    EXPORT_TURNOVER_TABLE: 'Xuất khẩu theo quốc gia',

    CHANGE_TURN_OVER_PARTNERS_EXPORT: 'Tăng trưởng xuất khẩu theo đối tác',
    CHANGE_TURN_OVER_PARTNERS_IMPORT: 'Tăng trưởng nhập khẩu theo đối tác',

    GROWTH_PARTNERS_EXPORT: 'Tăng trưởng giá trị xuất khẩu với đối tác',
    GROWTH_PARTNERS_IMPORT: 'Tăng trưởng giá trị nhập khẩu với đối tác',

    STRUCTURE_EXPORT: 'Cơ cấu xuất khẩu hàng hóa tới',
    STRUCTURE_IMPORT: 'Cơ cấu nhập khẩu hàng hóa từ',

    FILTER_CONTINENT: 'Châu lục',
    FILTER_TRADE_AGREEMENTS: 'Hiệp định thương mại',
  },
}
