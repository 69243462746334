import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { maxCondition } from '../../constant'
import style from '../../index.module.css'
import {
  selectAddMoreConditionByIds,
  selectAddMoreConditionIds,
  selectConditions,
} from '../../store/slice'

const Count = () => {
  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)
  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)
  const conditions = useSelector(selectConditions)

  const lengthConditions = Object.keys(conditions).reduce((result, key) => {
    return (result += conditions[key].length)
  }, 0)
  const lengthAddMoreCondition = addMoreConditionIds.filter(
    (indicatorId) => addMoreConditionByIds[indicatorId].checked,
  ).length
  const totalCondition = lengthConditions + lengthAddMoreCondition

  return (
    <div className="note mt-8">
      <Translate value="tool.smartScreening.SELECTED" />: {totalCondition}/
      {maxCondition}
      <Translate value="tool.smartScreening.OF_TOTAL" />
      {totalCondition > maxCondition && (
        <span className={style.warning}>
          <i className="icon-warning" />
          <Translate value="tool.smartScreening.MAX_MORE_CONDITION" />
        </span>
      )}
    </div>
  )
}

export default Count
