import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { INDICATOR_TYPE, MAX_TICKER, PLOT_TYPE } from '../constant'
import style from '../index.module.css'
import {
  changePlotType,
  selectFilteredOrganizationIds,
  selectPlotType,
} from '../store/slice'
import BubbleColor from './BubbleColor'
import BubbleSize from './BubbleSize'
import TimePoint from './timePoint'
import TimeRange from './TimeRange'
import { TooltipDisabled } from './TooltipDisabled'
import XAxis from './XAxis'
import YAxis from './YAxis'

const ItemRadio = ({ item, value, setValue, disabled }) => {
  const changeValue = () => {
    if (disabled) return
    setValue(item.value)
  }

  const isActive = value === item.value

  return (
    <div className="d-flex mt-8 align-center">
      <span
        onClick={changeValue}
        className="d-flex ali-center cursor-pointer"
        style={{ opacity: disabled ? 0.4 : 1 }}
      >
        <input
          type="radio"
          className="radiobox radiobox2"
          checked={isActive}
          readOnly
          disabled={disabled}
        />
        <span className={isActive ? style.activeCheck : ''}>
          <Translate value={item.name} />
        </span>
      </span>
      {disabled && <TooltipDisabled />}
    </div>
  )
}

const PlotType = () => {
  const dispatch = useDispatch()

  const type = useSelector(selectPlotType)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)

  const disableTimeSeries = filteredOrgIds.length > MAX_TICKER

  const handleChange = (val) => {
    dispatch(changePlotType(val))
  }

  return (
    <>
      <div className={style.mt24}>
        <Span className={style.disabled} style={{ fontSize: 11 }}>
          <Translate value="sector.sectorConstituents.segmentation.SCATTER_PLOT_TYPE" />
        </Span>
        {Object.keys(PLOT_TYPE).map((key) => (
          <ItemRadio
            key={key}
            item={{
              value: PLOT_TYPE[key],
              name:
                'sector.sectorConstituents.segmentation.scatterPlotType.' + key,
            }}
            value={type}
            setValue={handleChange}
            disabled={
              PLOT_TYPE[key] === PLOT_TYPE.TIME_SERIES && disableTimeSeries
            }
          />
        ))}
      </div>
      {type === PLOT_TYPE.POINT_IN_TIME ? (
        <>
          <XAxis />
          <TimePoint indicatorType={INDICATOR_TYPE.X} />
          <YAxis />
          <TimePoint indicatorType={INDICATOR_TYPE.Y} />
        </>
      ) : (
        <>
          <XAxis />
          <YAxis />
          <TimeRange />
        </>
      )}
      <BubbleSize />
      <BubbleColor />
    </>
  )
}

export default PlotType
