import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import Dropdown from '../../../../common/dropdown'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import { Icon } from '../../../../common/html/Icon'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import InputFormatCurrency from '../../../../common/input/InputFormatCurrency'
import { Loading } from '../../../../common/loading'
import useGetStock from '../../../../common/masterData/useGetStock'
import Popup from '../../../../common/popup'
import SelectCustom from '../../../../common/selectCustom'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import {
  BOND_DETAIL_OPTIONS,
  BOND_DETAIL_TYPES,
  COUPON_OPTIONS,
  COUPON_TYPES,
  INTEREST_PAYMENT_PERIOD_OPTIONS,
  INTEREST_PAYMENT_PERIOD_TYPES,
} from './constants'
import style from './index.module.css'
import {
  selectDataSearchIssuer,
  selectFilterSearchIssuer,
  selectLoadingSearchIssuer,
  setIssuerData,
  setLoadingSearchIssuer,
} from './store/slice'

const defaultData = {
  ticker: '',
  bondTypeId: BOND_DETAIL_TYPES.CORPORATE_BOND,
  organizationId: null,
  issueDateId: null,
  maturityDateId: null,
  parValue: '',
  durationDay: '',
  paymentCalendarId: INTEREST_PAYMENT_PERIOD_TYPES.ONE_MONTH,
  couponTypeId: COUPON_TYPES.FIXED,
  interestRate: '',
}

const defaultError = {
  ticker: '',
  organizationId: '',
  issueDateId: '',
  maturityDateId: '',
  parValue: '',
  interestRate: '',
}

const messageRequired = 'bond.bondScreening.createBond.MESSAGE_REQUIRED'

const PopupCreateBond = ({ handleClose, handleSubmit }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()
  const inputRef = useRef()

  const timeZone = UseTimeZone()
  const { stocks } = useGetStock()

  const loading = useSelector(selectLoadingSearchIssuer)
  const { page, pageSize, enableInfinity } = useSelector(
    selectFilterSearchIssuer,
  )
  const issuers = useSelector(selectDataSearchIssuer)
  const locale = useSelector((state) => state.i18n.locale)

  const [data, setData] = useState(defaultData)
  const [error, setError] = useState(defaultError)
  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const customHeader = HeaderOneCalendarRange({
    startYear: 2010,
    endYear: new Date(
      new Date().setFullYear(new Date().getFullYear() + 30),
    ).getFullYear(),
  })

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const getDateWithTimezone = (date) =>
    new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))

  const handleSearchMore = () => {
    if (!enableInfinity) {
      return
    }

    if (!!keySearch.length) {
      const newStocks = stocks.filter(
        (item) =>
          item?.ticker?.toLowerCase()?.includes(keySearch?.toLowerCase()) ||
          item?.organizationShortName
            ?.toLowerCase()
            ?.includes(keySearch?.toLowerCase()) ||
          item?.taxCode?.toLowerCase()?.includes(keySearch?.toLowerCase()),
      )

      dispatch(
        setIssuerData({
          data: newStocks.slice(0, pageSize),
          page,
          enableInfinity: newStocks.slice(0, 20)?.length !== newStocks?.length,
          pageSize: 20,
        }),
      )

      return
    }

    dispatch(
      setIssuerData({
        data: stocks.slice(0, pageSize),
        page,
        enableInfinity: stocks.slice(0, 20)?.length !== stocks?.length,
        pageSize: 20,
      }),
    )
  }

  const handleChangeTicker = (value) => {
    setError((prev) => ({
      ...prev,
      ticker: '',
    }))

    setData((prev) => ({ ...prev, ticker: value.toUpperCase() }))
  }

  const validateTicker = () => {
    if (!data.ticker.length) {
      setError((prev) => ({
        ...prev,
        ticker: messageRequired,
      }))

      return false
    }

    const regex = /^[a-zA-Z0-9._-]+$/g

    if (!regex.test(data.ticker)) {
      setError((prev) => ({
        ...prev,
        ticker: 'bond.bondScreening.createBond.TICKER_REQUIRED',
      }))

      return false
    }

    if (data.ticker.length > 30) {
      setError((prev) => ({
        ...prev,
        ticker: 'bond.bondScreening.createBond.TICKER_MAX_LENGTH',
      }))

      return false
    }

    return true
  }

  const validateOrganization = (organizationId) => {
    setError((prev) => ({
      ...prev,
      organizationId: '',
    }))

    if (isShowDropdown) {
      handleHide()
    }

    setData((prev) => ({ ...prev, organizationId }))

    if (!organizationId) {
      setError((prev) => ({
        ...prev,
        organizationId: messageRequired,
      }))

      return false
    }

    return true
  }

  const handleChangeBondType = (value) => {
    setData((prev) => ({ ...prev, bondTypeId: value }))
    setError((prev) => ({
      ...prev,
      issueDateId: '',
    }))
  }

  const validateIssueDate = () => {
    if (!data.issueDateId) {
      setError((prev) => ({
        ...prev,
        issueDateId: messageRequired,
      }))

      return false
    }

    if (data.maturityDateId) {
      const years = moment(data.maturityDateId).diff(
        data.issueDateId,
        'years',
        true,
      )

      if (years > 30) {
        setError((prev) => ({
          ...prev,
          issueDateId: 'bond.bondScreening.createBond.DATE_MAX',
        }))

        return false
      }
    }

    return true
  }

  const handleChangeIssueDate = (value) => {
    setData((prev) => ({ ...prev, issueDateId: value }))
    setError((prev) => ({
      ...prev,
      issueDateId: '',
    }))
  }

  const validateMaturityDate = () => {
    if (!data.maturityDateId) {
      setError((prev) => ({
        ...prev,
        maturityDateId: messageRequired,
      }))

      return false
    }

    if (data.issueDateId) {
      const years = moment(data.maturityDateId).diff(
        data.issueDateId,
        'years',
        true,
      )

      if (years > 30) {
        setError((prev) => ({
          ...prev,
          maturityDateId: 'bond.bondScreening.createBond.DATE_MAX',
        }))

        return false
      }
    }

    return true
  }

  const handleChangeMaturityDate = (value) => {
    setData((prev) => ({ ...prev, maturityDateId: value }))
    setError((prev) => ({
      ...prev,
      maturityDateId: '',
    }))
  }

  const handleChangeParValue = () => {
    setError((prev) => ({
      ...prev,
      parValue: '',
    }))
  }

  const validateParValue = (value) => {
    setData((prev) => ({ ...prev, parValue: value }))

    if (!value.length) {
      setError((prev) => ({
        ...prev,
        parValue: messageRequired,
      }))

      return false
    }

    if (parseInt(value) > 10000000000) {
      setError((prev) => ({
        ...prev,
        parValue: 'bond.bondScreening.createBond.PAR_VALUE_MAX',
      }))

      return false
    }

    return true
  }

  const handleChangeInterestPayment = (value) => {
    setData((prev) => ({ ...prev, paymentCalendarId: value }))
  }

  const handleChangeCouponType = (value) => {
    setData((prev) => ({ ...prev, couponTypeId: value }))
  }

  const handleChangeInterestRate = () => {
    setError((prev) => ({
      ...prev,
      interestRate: '',
    }))
  }

  const validateInterestRate = (value) => {
    setData((prev) => ({ ...prev, interestRate: value }))

    if (!value.length) {
      setError((prev) => ({
        ...prev,
        interestRate: messageRequired,
      }))

      return false
    }

    if (parseInt(value) > 100) {
      setError((prev) => ({
        ...prev,
        interestRate: 'bond.bondScreening.createBond.INTEREST_RATE_MAX',
      }))

      return false
    }

    return true
  }

  const handleSave = () => {
    setError(defaultError)

    const checkTicker = validateTicker()
    const checkOrganization = validateOrganization(data.organizationId)
    const checkParValue = validateParValue(data.parValue)
    const checkIssueDate = validateIssueDate()
    const checkMaturityDate = validateMaturityDate()
    const checkInterestRate = validateInterestRate(data.interestRate)

    if (
      !checkTicker ||
      !checkOrganization ||
      !checkParValue ||
      !checkIssueDate ||
      !checkMaturityDate ||
      !checkInterestRate
    )
      return

    const { interestRate, parValue, ...restData } = data

    const months = moment(data.maturityDateId).diff(
      data.issueDateId,
      'months',
      true,
    )

    const params = {
      ...restData,
      durationDay: parseInt(months),
      fixedInterestRate:
        restData.couponTypeId === COUPON_TYPES.FIXED
          ? parseFloat(formatVal(parseFloat(interestRate) / 100, 4))
          : null,
      floatInterestSpread:
        restData.couponTypeId === COUPON_TYPES.FLOAT
          ? parseFloat(formatVal(parseFloat(interestRate) / 100, 4))
          : null,
      parValue: parseInt(parValue),
    }

    handleSubmit(params)
  }

  const handleCancel = () => {
    setData(defaultData)
    setError(defaultError)
    handleClose()
  }

  const duration = useMemo(() => {
    if (!data.issueDateId || !data.maturityDateId) return ''

    const months = moment(data.maturityDateId).diff(
      data.issueDateId,
      'months',
      true,
    )

    if (months > 36)
      return I18n.t('bond.bondScreening.createBond.YEAR', {
        number: formatVal(months / 12, 2),
      })

    return I18n.t('bond.bondScreening.createBond.MONTH', {
      number: formatVal(months, 0),
    })
  }, [data.issueDateId, data.maturityDateId])

  const expected = useMemo(() => {
    if (!data.issueDateId || !data.maturityDateId) return ''

    const months = moment(data.maturityDateId).diff(data.issueDateId, 'months')

    switch (data.paymentCalendarId) {
      case INTEREST_PAYMENT_PERIOD_TYPES.THREE_MONTHS:
        return formatVal(months / 3, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.SIX_MONTHS:
        return formatVal(months / 6, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.TWELVE_MONTHS:
        return formatVal(months / 12, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.MATURITY_DATE:
        return '1'
      default:
        return formatVal(months, 0)
    }
  }, [data.issueDateId, data.maturityDateId, data.paymentCalendarId])

  const organizationName = useMemo(() => {
    if (!data.organizationId) return I18n.t('common.SEARCH')

    const organization = issuers.find(
      (item) => item.organizationId === data.organizationId,
    )

    return organization && organization?.organizationShortName
      ? organization?.organizationShortName
      : I18n.t('common.SEARCH')
  }, [data.organizationId, locale])

  const placeholderInput = useMemo(() => I18n.t('common.SEARCH'), [locale])

  const renderError = (message) => (
    <div style={{ margin: '-10px 0' }}>
      <Span
        style={{
          fontStyle: 'italic',
          color: '#ff2b66',
          fontSize: 11,
        }}
      >
        <Translate value={message} />
      </Span>
    </div>
  )

  useEffect(() => {
    if (isShowDropdown) {
      dispatch(setLoadingSearchIssuer(true))

      inputRef?.current?.focus()

      const timeout = setTimeout(() => {
        if (!!keySearch.length) {
          const newStocks = stocks.filter(
            (item) =>
              item?.ticker?.toLowerCase()?.includes(keySearch?.toLowerCase()) ||
              item?.organizationShortName
                ?.toLowerCase()
                ?.includes(keySearch?.toLowerCase()) ||
              item?.taxCode?.toLowerCase()?.includes(keySearch?.toLowerCase()),
          )

          dispatch(
            setIssuerData({
              data: newStocks.slice(0, 20),
              page: 1,
              enableInfinity:
                newStocks.slice(0, 20)?.length !== newStocks?.length,
              pageSize: 20,
            }),
          )

          return
        }

        dispatch(
          setIssuerData({
            data: stocks.slice(0, 20),
            page: 1,
            enableInfinity: stocks.slice(0, 20)?.length !== stocks?.length,
            pageSize: 20,
          }),
        )
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [isShowDropdown, keySearch.length, stocks])

  return (
    <Popup>
      <div className="modal modal-small" style={{ width: 502 }}>
        <div className="modal-title">
          <h3>
            <Span style={{ textTransform: 'uppercase' }}>
              <Translate value="bond.bondScreening.createBond.CREATE_TITLE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ padding: 0 }}>
          <div className="content" style={{ padding: 16 }}>
            <div className="flex-column" style={{ gap: 16 }}>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.BOND_TICKER" />
                </Span>
                <div style={{ width: 240 }}>
                  <Input
                    className={`${style.input} ${style.inputBackground}`}
                    value={data.ticker}
                    onChange={(e) => handleChangeTicker(e?.target?.value ?? '')}
                    onBlur={validateTicker}
                  />
                </div>
              </div>
              {!!error.ticker.length && renderError(error.ticker)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.BOND_TYPE" />
                </Span>
                <div style={{ width: 240 }}>
                  <SelectCustom
                    value={data.bondTypeId}
                    selectData={BOND_DETAIL_OPTIONS}
                    handleChange={handleChangeBondType}
                    appendStyle={{ fontWeight: 340 }}
                    isI18n
                  />
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUER" />
                </Span>
                <div style={{ width: 240 }}>
                  <div
                    ref={dropdownRef}
                    className="form-search-inbox"
                    style={{ width: '100%', margin: 0 }}
                  >
                    {!isShowDropdown ? (
                      <Span
                        className={`d-flex ali-center ${style.input} ${style.inputBackground}`}
                        style={{
                          paddingRight: 24,
                          fontStyle: data.organizationId ? 'normal' : 'italic',
                          color: data.organizationId
                            ? '#ECECEC'
                            : 'rgba(255, 255, 255, 0.4)',
                          fontWeight: data.organizationId ? 340 : 400,
                        }}
                        onClick={handleShow}
                      >
                        <TextEllipsis
                          text={organizationName ?? ''}
                          appendStyle={{ width: '100%' }}
                        />
                      </Span>
                    ) : (
                      <Input
                        style={{
                          fontSize: 12,
                          backgroundColor: '#50545f ',
                          padding: '0 20px 0px 8px',
                        }}
                        type="text"
                        className="search-inbox"
                        placeholder={placeholderInput}
                        onChange={(e) => setKeySearch(e.target.value)}
                        value={keySearch}
                        ref={inputRef}
                      />
                    )}

                    <button type="button">
                      <i className={`icon-search-2 ${style.colorIcon}`} />
                    </button>
                    <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
                      <HandleClickOutside
                        handleClickOutside={handleHide}
                        exceptRef={dropdownRef}
                      >
                        <div
                          className="input-dropdown"
                          style={{ marginTop: 2 }}
                        >
                          <div
                            className={`dropdown-container ${style.dropdownContainerSearch}`}
                            style={{
                              width:
                                dropdownRef?.current?.getBoundingClientRect()
                                  .width,
                            }}
                          >
                            {loading ? (
                              <div style={{ height: 29 }}>
                                <Loading />
                              </div>
                            ) : !!issuers?.length ? (
                              <ScrollComponent
                                autoHeight={true}
                                autoHeightMax={208}
                                scrollToBottomCallback={handleSearchMore}
                              >
                                <ul className="list-check">
                                  {issuers.map((item, index) => {
                                    return (
                                      <li key={`key-${index}`}>
                                        <a
                                          style={{
                                            cursor: 'pointer',
                                          }}
                                          onClick={() =>
                                            validateOrganization(
                                              item?.organizationId ?? null,
                                            )
                                          }
                                        >
                                          <label
                                            className="d-flex ali-center"
                                            style={{ gap: 8 }}
                                          >
                                            <div
                                              style={{
                                                width: 77,
                                                flexShrink: 0,
                                              }}
                                            >
                                              <TextEllipsis
                                                text={
                                                  item?.ticker ??
                                                  item?.taxCode ??
                                                  item?.tickerOrTaxCode ??
                                                  ''
                                                }
                                                zIndexTooltip={999}
                                                isI18n={false}
                                              />
                                            </div>
                                            <div
                                              style={{
                                                width: 'calc(100% - 85px)',
                                              }}
                                            >
                                              <TextEllipsis
                                                text={
                                                  item?.organizationShortName ??
                                                  item?.organizationName ??
                                                  ''
                                                }
                                                zIndexTooltip={999}
                                                isI18n={false}
                                              />
                                            </div>
                                          </label>
                                        </a>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </ScrollComponent>
                            ) : (
                              <div className={style.noData}>
                                <Span style={{ fontSize: 10 }}>
                                  <Translate value="common.NO_DATA" />
                                </Span>
                              </div>
                            )}
                          </div>
                        </div>
                      </HandleClickOutside>
                    </Dropdown>
                  </div>
                </div>
              </div>
              {!!error.organizationId.length &&
                renderError(error.organizationId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUE_DATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <div className="form-control calendar mb-0">
                      <div className={`${style.bgGrey} w-100`}>
                        <CalendarPopper
                          date={
                            data.issueDateId
                              ? getDateWithTimezone(data.issueDateId)
                              : null
                          }
                          handleChange={(value) =>
                            handleChangeIssueDate(
                              !!value?.length
                                ? getISOStartOrEndOfDay(value, timeZone, true)
                                : null,
                            )
                          }
                          customHeader={customHeader}
                          CustomInput={InputDateCalendar}
                          popperClassName={style.popperClassNameLeft}
                          onClose={validateIssueDate}
                          excludeDateIntervals={
                            data.maturityDateId
                              ? [
                                  {
                                    start: new Date(
                                      moment(data.maturityDateId)
                                        .subtract(1, 'days')
                                        .format('YYYY-MM-DD'),
                                    ),
                                    end: new Date(
                                      `${new Date().getFullYear() + 1}-12-31`,
                                    ),
                                  },
                                ]
                              : undefined
                          }
                          placeholder="dd/mm /yyyy"
                        />
                      </div>
                      <Icon className="icon-date" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                </div>
              </div>
              {!!error.issueDateId.length && renderError(error.issueDateId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.MATURITY_DATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <div className="form-control calendar mb-0">
                      <div className={`${style.bgGrey} w-100`}>
                        <CalendarPopper
                          date={
                            data.maturityDateId
                              ? getDateWithTimezone(data.maturityDateId)
                              : null
                          }
                          handleChange={(value) =>
                            handleChangeMaturityDate(
                              !!value?.length
                                ? getISOStartOrEndOfDay(value, timeZone, true)
                                : null,
                            )
                          }
                          customHeader={customHeader}
                          CustomInput={InputDateCalendar}
                          popperClassName={style.popperClassNameLeft}
                          onClose={validateMaturityDate}
                          excludeDateIntervals={
                            data.issueDateId
                              ? [
                                  {
                                    start: new Date('1999-12-31'),
                                    end: new Date(
                                      moment(data.issueDateId).format(
                                        'YYYY-MM-DD',
                                      ),
                                    ),
                                  },
                                ]
                              : undefined
                          }
                          placeholder="dd/mm /yyyy"
                        />
                      </div>
                      <Icon className="icon-date" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                </div>
              </div>
              {!!error.maturityDateId.length &&
                renderError(error.maturityDateId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.PAR_VALUE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <InputFormatCurrency
                      className={`${style.input} ${style.inputBackground}`}
                      handleBlur={(value) => validateParValue(value)}
                      defaultValue={data.parValue}
                      fractionDigits={0}
                      style={{ width: '100%' }}
                      decimalRender={0}
                      regexValueCustom={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                      handleChange={handleChangeParValue}
                    />
                  </div>
                </div>
              </div>
              {!!error.parValue.length && renderError(error.parValue)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate
                    value="bond.bondScreening.createBond.INTEREST_PAYMENT_PERIOD"
                    unit={
                      data.paymentCalendarId !==
                      INTEREST_PAYMENT_PERIOD_TYPES.MATURITY_DATE
                        ? I18n.t(
                            'bond.bondScreening.createBond.INTEREST_PAYMENT_PERIOD_UNIT',
                          )
                        : ''
                    }
                  />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <SelectCustom
                      value={data.paymentCalendarId}
                      selectData={INTEREST_PAYMENT_PERIOD_OPTIONS}
                      handleChange={handleChangeInterestPayment}
                      appendStyle={{ fontWeight: 340 }}
                      isI18n
                    />
                  </div>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.EXPECTED" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>{expected}</Span>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUE_PERIOD" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>{duration}</Span>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.COUPON_TYPE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <SelectCustom
                      value={data.couponTypeId}
                      selectData={COUPON_OPTIONS}
                      handleChange={handleChangeCouponType}
                      appendStyle={{ fontWeight: 340 }}
                      isI18n
                    />
                  </div>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.COUPON_INTEREST_RATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <InputFormatCurrency
                      className={`${style.input} ${style.inputBackground}`}
                      handleBlur={(value) => validateInterestRate(value)}
                      handleChange={handleChangeInterestRate}
                      defaultValue={data.interestRate}
                      fractionDigits={2}
                      style={{ width: '100%' }}
                      decimalRender={2}
                      regexValueCustom={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                      suffix="%"
                    />
                  </div>
                </div>
              </div>
              {!!error.interestRate.length && renderError(error.interestRate)}
              <div
                className={style.groupBtn}
                style={{ minHeight: 20, padding: 0 }}
              >
                <div className="d-flex ali-center" style={{ gap: 8 }}>
                  <Button
                    onClick={handleCancel}
                    className={`btn normal w-80 h-20`}
                    style={{ fontWeight: 600, flex: 1 }}
                  >
                    <Translate value="common.button.BUTTON_CANCEL" />
                  </Button>
                  <Button
                    onClick={handleSave}
                    className={`btn btn-blue w-80 h-20 `}
                    style={{ fontWeight: 600, flex: 1 }}
                  >
                    <Translate value="common.button.BUTTON_SAVE" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupCreateBond
