import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDataTree } from '../../common/tree/helper'
import {
  key,
  selectLoading,
  selectLocale,
  selectRatioIndicators,
} from './store/slice'
import { getRatioIndicatorsThunk } from './store/thunk'

const useGetRatioIndicators = (disabledCallApi = false) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const ratioIndicators = useSelector(selectRatioIndicators)
  const loadingRatio = useSelector(selectLoading(key.RATIO_INDICATORS))

  const [ratioIndicatorsTree, setRatioIndicatorsTree] = useState({})

  useEffect(() => {
    if (!disabledCallApi) {
      dispatch(getRatioIndicatorsThunk())
    }
  }, [locale])

  useEffect(() => {
    setRatioIndicatorsTree(
      convertDataTree(ratioIndicators, 'indicatorId', 'parentIndicatorId'),
    )
  }, [ratioIndicators])

  return {
    ratioIndicators,
    ratioIndicatorsTree,
    loadingRatio,
  }
}

export default useGetRatioIndicators
