import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { FILTER_LIST } from './constants'
import style from './index.module.css'
import { changeIsNewsOrReports, selectIsNews } from './store/slice'

export const RadioGroup = () => {
  const dispatch = useDispatch()

  const isNews = useSelector(selectIsNews)

  const handleChangeRadio = (val) => {
    dispatch(changeIsNewsOrReports(val))
  }

  return (
    <div className="d-flex">
      <ul className={`list-check d-flex mt-0`}>
        {FILTER_LIST.map((item) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={item.value === isNews}
                onChange={() => handleChangeRadio(item.value)}
              />
              <Span
                style={{ fontSize: 12 }}
                className={item.value === isNews ? style.active : ''}
              >
                <Translate value={item.label} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}
