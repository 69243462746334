import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'

export const getNetTradingValueChartOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/getNetTradingValueChartOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getNetTradingValueChartOverview(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getNetTradingValueChartTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/getNetTradingValueChartTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getNetTradingValueChartTicker(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
