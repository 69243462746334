import { CellWithColor } from '../../../../../common/table/CellWithColor'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { getThreeColorByValue } from '../../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import {
  HOLDING_OPTIONS,
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_STOCK_SECURITY_HOLDING_HISTORY_KEYS,
} from '../constants'

export const stockSecurityHoldingHistorySchema = ({
  assetColumns,
  holdingOption,
  locale,
  dataById,
}) => {
  return [
    ...TABLE_STOCK_SECURITY_HOLDING_HISTORY_KEYS,
    ...[...assetColumns].sort(
      (a, b) =>
        b[`${holdingOption}${b.assetId}`] - a[`${holdingOption}${a.assetId}`],
    ),
  ].map((item) => {
    if (item.colId === TABLE_COL_KEYS.COL_STOCK_PUBLIC_DATE) {
      return {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    return {
      ...item,
      colId: holdingOption + item.colId,
      isI18n: false,
      disableSort: true,
      thStyle: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 600,
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        const prevVal = dataById[rowId + 1]
          ? dataById[rowId + 1][holdingOption + item.colId]
          : null
        const compareVal =
          typeof prevVal !== 'number' || typeof val !== 'number'
            ? val
            : val === 0
            ? EMPTY_VALUE
            : val - prevVal
        const color = getThreeColorByValue(compareVal)

        return (
          <CellWithColor
            val={val}
            format={(val) =>
              holdingOption === HOLDING_OPTIONS.VOLUME
                ? formatVal(val / TABLE_COL_FORMATS.COL_STOCK_VOLUME_ICB, 2)
                : valToPercent(val)
            }
            color={color}
            isPercent={holdingOption === HOLDING_OPTIONS.CONTRIBUTION}
          />
        )
      },
    }
  })
}

export const formatStockSecurityHoldingHistoryData = (rawData) => {
  const dataByEndDateId = {}
  const data = []
  const assetById = {}
  const assetColumns = []
  const latestDate = rawData.length ? rawData[0].endDateId : ''

  rawData.forEach((item, index) => {
    if (typeof dataByEndDateId[item.endDateId] === 'number') {
      data[dataByEndDateId[item.endDateId]] = {
        ...data[dataByEndDateId[item.endDateId]],
        [`${HOLDING_OPTIONS.CONTRIBUTION}${item.assetId}`]:
          item.portfolioWeight,
        [`${HOLDING_OPTIONS.VOLUME}${item.assetId}`]: item.quantity,
      }
    } else {
      dataByEndDateId[item.endDateId] = data.length
      data.push({
        endDateId: item.endDateId,
        [`${HOLDING_OPTIONS.CONTRIBUTION}${item.assetId}`]:
          item.portfolioWeight,
        [`${HOLDING_OPTIONS.VOLUME}${item.assetId}`]: item.quantity,
      })
    }

    if (!assetById[item.assetId]) {
      assetById[item.assetId] = true
      assetColumns.push({
        title: item.ticker,
        colId: item.assetId,
        assetId: item.assetId,
        ticker: item.ticker,
        organizationShortName: item.organizationShortName,
        endDateId: item.endDateId,
        [`${HOLDING_OPTIONS.CONTRIBUTION}${item.assetId}`]:
          item.endDateId === latestDate ? item.portfolioWeight : null,
        [`${HOLDING_OPTIONS.VOLUME}${item.assetId}`]:
          item.endDateId === latestDate ? item.quantity : null,
      })
    }
  })

  return {
    data,
    assetColumns,
  }
}
