import { Button } from '../../../../common/html/Button'
import TextEllipsis from '../../../../common/textEllipsis'
import { VIEW_TYPE } from '../../../chart/constants'
import { TIME_FREQUENCY } from '../../constants'
import { equationIndicatorColWidth } from '../constants'
import style from '../style.module.css'
import { IndicatorSelectDate } from './IndicatorSelectDate'
import { IndicatorSelectInput } from './IndicatorSelectInput'
import { IndicatorSelectTime } from './IndicatorSelectTime'

export const IndicatorSelectItem = ({
  equationIndicator,
  viewType,
  isEquationTypeCount,
  indicatorNameColWidth,
  indicatorsLatestPeriod,
  onChangeIndicator,
  onChangeIndicatorDate,
  onDeleteIndicator,
  onAddIndicatorData,
}) => {
  return (
    <div className="ml-20">
      {Object.keys(equationIndicator.data).map((key, index) => (
        <div key={index} className={style.equationListItem}>
          <div style={{ width: equationIndicatorColWidth.alias }}>
            <div className={style.equationListIndicatorItemAlias}>
              {equationIndicator.data[key].alias}
            </div>
          </div>
          <div style={{ width: indicatorNameColWidth }}>
            <div className={style.equationListItemColContent}>
              {!index && (
                <TextEllipsis
                  text={equationIndicator.nameDisplay}
                  isI18n={false}
                />
              )}
            </div>
          </div>
          {isEquationTypeCount && (
            <>
              <div style={{ width: equationIndicatorColWidth.valueFrom }}>
                <div className={style.equationListItemColContent}>
                  <IndicatorSelectInput
                    value={equationIndicator.data[key].valueFrom}
                    unit={equationIndicator.unit}
                    handleChange={(val) =>
                      onChangeIndicator(
                        val,
                        key,
                        equationIndicator,
                        'valueFrom',
                      )
                    }
                  />
                </div>
              </div>
              <div style={{ width: equationIndicatorColWidth.divider }}>
                <div className={style.equationListItemColContent}>
                  <i className="icon-minus" style={{ fontSize: 12 }} />
                </div>
              </div>
              <div style={{ width: equationIndicatorColWidth.valueTo }}>
                <div className={style.equationListItemColContent}>
                  <IndicatorSelectInput
                    value={equationIndicator.data[key].valueTo}
                    unit={equationIndicator.unit}
                    handleChange={(val) =>
                      onChangeIndicator(val, key, equationIndicator, 'valueTo')
                    }
                  />
                </div>
              </div>
              <div style={{ width: equationIndicatorColWidth.unit }}>
                <div className={style.equationListItemColContentUnit}>
                  <TextEllipsis text={equationIndicator.unit} isI18n={false} />
                </div>
              </div>
            </>
          )}
          {viewType === VIEW_TYPE.BY_SECURITIES && (
            <div style={{ width: equationIndicatorColWidth.timeGroup }}>
              {equationIndicator.timeFrequency.toLowerCase() ===
              TIME_FREQUENCY.DAILY.toLowerCase() ? (
                <IndicatorSelectDate
                  dataKey={key}
                  data={equationIndicator}
                  indicatorsLatestPeriod={indicatorsLatestPeriod}
                  onChangeIndicator={onChangeIndicatorDate}
                />
              ) : (
                <IndicatorSelectTime
                  dataKey={key}
                  data={equationIndicator}
                  indicatorsLatestPeriod={indicatorsLatestPeriod}
                  onChangeIndicator={onChangeIndicatorDate}
                />
              )}
            </div>
          )}
          <div style={{ width: equationIndicatorColWidth.iconDelete }}>
            <div
              className={style.equationListIndicatorItemDelete}
              onClick={() =>
                onDeleteIndicator(equationIndicator.indicatorTypeId, key)
              }
            >
              <i
                style={{ fontSize: 10, color: '#8B8B8B' }}
                className="icon-delete-circle"
              />
            </div>
          </div>
        </div>
      ))}
      {viewType === VIEW_TYPE.BY_SECURITIES && (
        <div style={{ marginTop: 8, marginBottom: 8 }} className="btn-add">
          <Button
            className="btn h-20"
            onClick={() => onAddIndicatorData(equationIndicator)}
            style={{ width: 21 }}
          >
            <i className="icon-plus" />
          </Button>
        </div>
      )}
    </div>
  )
}
