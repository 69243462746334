import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Table as TableComponent } from '../../../../common/table'
import { FEATURES } from '../../../../constants/routes'
import {
  convertSortKeyToNumber,
  convertSortKeyToString,
} from '../../../../utils/Common'
import { TABLE_COL_KEYS, TABLE_TABS, VALUE_TYPES } from '../constants'
import {
  getSortFieldDefaultByTableTab,
  getTableSchema,
  getTableThCellTopById,
  getTableThSchema,
} from '../helpers'
import {
  changeFilter,
  selectDataById,
  selectDataCellById,
  selectFilters,
  selectIds,
  selectLoading,
  selectMaxMinByColId,
  selectReCalcWidth,
  selectSectorAllocationColumns,
  selectTableTab,
  selectValueType,
  sortTableData,
} from '../store/slice'
import { withGetData } from './withGetData'

const Table = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  // Selector
  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectIds)
  const loading = useSelector(selectLoading)
  const tableTab = useSelector(selectTableTab)
  const valueType = useSelector(selectValueType)
  const filters = useSelector(selectFilters)
  const reCalcWidth = useSelector(selectReCalcWidth)
  const maxMinByColId = useSelector(selectMaxMinByColId)
  const sectorAllocationColumns = useSelector(selectSectorAllocationColumns)
  const dataById = useSelector(selectDataById)

  const [valueSort, setValueSort] = useState('')

  const getConditionSetSortFieldDefault = () =>
    !filters.sortField ||
    (filters.sortField &&
      Object.values(VALUE_TYPES).some((val) =>
        filters.sortField.includes(val),
      ) &&
      !filters.sortField.includes(valueType))

  // Actions
  const handleChangePage = (page) => {
    if (page) {
      dispatch(changeFilter({ page }))
    }
  }

  const handleChangeSortField = (data) => {
    if (data && Object.values(data)[0]) {
      dispatch(
        changeFilter({
          sortField: Object.keys(data)[0],
          sortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(
        changeFilter({
          sortField: getSortFieldDefaultByTableTab(tableTab, valueType),
          sortOrder: 1,
        }),
      )
    }
  }

  const handleClick = (_, rowId) => {
    const organizationId = dataById[rowId]?.organizationId || ''
    navigate(
      FEATURES.fund.components['fund-profile'].components.navData.route,
      { organizationId },
    )
  }

  // Use effect
  useEffect(() => {
    if (!filters.sortField) {
      const sortField = getSortFieldDefaultByTableTab(tableTab, valueType)
      dispatch(changeFilter({ sortField }))
    }
  }, [tableTab])

  useEffect(() => {
    if (
      [TABLE_TABS.ASSET_ALLOCATION, TABLE_TABS.SECTOR_ALLOCATION].includes(
        tableTab,
      ) &&
      getConditionSetSortFieldDefault()
    ) {
      const sortField = getSortFieldDefaultByTableTab(tableTab, valueType)
      dispatch(changeFilter({ sortField }))
    }
  }, [valueType, tableTab])

  useEffect(() => {
    if (filters.sortField) {
      setValueSort({
        [filters.sortField]: convertSortKeyToString(filters.sortOrder),
      })
    }
  }, [filters.sortField, filters.sortOrder])

  useEffect(() => {
    if (tableTab === TABLE_TABS.SECTOR_ALLOCATION) {
      let sortField = filters.sortField

      if (getConditionSetSortFieldDefault()) {
        sortField = getSortFieldDefaultByTableTab(tableTab, valueType)
      }

      dispatch(
        sortTableData({
          [sortField]: convertSortKeyToString(filters.sortOrder),
        }),
      )
    }
  }, [JSON.stringify(dataById), valueType])

  return (
    <TableComponent
      ids={ids}
      schema={getTableSchema({
        tableTab,
        valueType,
        locale,
        maxMinByColId,
        sectorAllocationColumns,
        onClick: handleClick,
      })}
      rowSpanSchema={getTableThSchema(tableTab, valueType)}
      tableHeadRowTop={getTableThCellTopById({ tableTab, valueType })}
      getDataFromRedux={selectDataCellById}
      // renderContentPagination={
      //   tableTab === TABLE_TABS.SECTOR_ALLOCATION
      //     ? () => (
      //         <Span style={{ fontSize: 11 }} className="t-italic opacity-5">
      //           <Translate
      //             value={'fund.fundCenter.statistic.NOTE_UNIT_BILLION_VND'}
      //           />
      //         </Span>
      //       )
      //     : undefined
      // }
      isLoading={loading}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      hasTooltip={false}
      hasPagination={false}
      currentPage={filters.page}
      totalPage={filters.totalPage}
      reCalcWidths={reCalcWidth}
      stickies={{
        [TABLE_COL_KEYS.COL_INDEX]: true,
        [TABLE_COL_KEYS.COL_FUND_TICKER]: true,
        [TABLE_COL_KEYS.COL_FUND_NAME]: true,
      }}
      valueSort={valueSort}
      sort={tableTab === TABLE_TABS.SECTOR_ALLOCATION ? sortTableData : true}
      handleChangePage={handleChangePage}
      handleSortField={
        tableTab !== TABLE_TABS.SECTOR_ALLOCATION
          ? handleChangeSortField
          : undefined
      }
    />
  )
}

export default withGetData(Table)
