import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class SectorConstituentsOverviewProxy extends ProxyBase {
  getTopRevenueChartData(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'TopRevenueChartData',
          paramsArr: params,
        })
      : this.get('TopRevenueChartData', params)
  }

  getSectorEarningGrowthChartData(params) {
    return this.get('SectorEarningGrowthChartData', params)
  }

  getSegmentationChartData(params) {
    return this.get('SegmentationChartData', params)
  }

  getTopTotalOperatingIncomeChartData(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'TopTotalOperatingIncomeChartData',
          paramsArr: params,
        })
      : this.get('TopTotalOperatingIncomeChartData', params)
  }

  getCreditAndDepositChartData(params) {
    return this.get('CreditAndDepositChartData', params)
  }

  getMarginLendingChartData(params) {
    return this.get('MarginLendingChartData', params)
  }

  getKeyRatios(params) {
    return this.get('KeyRatios', params)
  }

  getTopPerformance(params) {
    return this.get('TopPerformance', params)
  }

  downloadKeyRatios(params) {
    return this.get('DownloadKeyRatios', params, 'download')
  }

  downloadTopPerformance(params) {
    return this.get('DownloadTopPerformance', params, 'download')
  }

  getListNews(params) {
    return this.get('GetListNews', params)
  }

  getListReport(params) {
    return this.get('GetListReport', params)
  }
}

export default new SectorConstituentsOverviewProxy(
  ServiceProxyConfig.Sector.SectorConstituents.Overview.ServiceUrl,
)
