import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valDivMillion } from '../../../../utils/Value'
import {
  getQuarterAndYearInDataRange,
  getQuarterAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../common/helper'
import { BALANCE_PAYMENT_FILTER } from '../../constants'
import { COMPONENTS_FILTER } from '../constants'
import { getChangeByTimeRange } from './thunk'

const slice = createSlice({
  name: 'economy/paymentBalance/getChangeByTimeRange',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    ids: [],
    idLineChart: [],
    isYearly: false,
    currentComponent: COMPONENTS_FILTER[0].value,
    currentTypeStatistics: BALANCE_PAYMENT_FILTER[1].value,
    isKeepListCheckbox: null,
  },
  reducers: {
    changeIsYearlyComponentsChart: (state, action) => {
      state.isYearly = action.payload
    },
    changeComponent: (state, action) => {
      state.currentComponent = action.payload
    },
    changeTypeStatisticsComponentsChart: (state, action) => {
      state.currentTypeStatistics = action.payload
    },
    keepListCheckbox: (state, action) => {
      state.isKeepListCheckbox = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChangeByTimeRange.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getChangeByTimeRange.fulfilled, (state, action) => {
      state.isLoading = action.payload.loading

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          quarter: item.realQuarter,
          year: item.realYear,
          id: item.boPVNTypeId,
          name: item.boPVNTypeName,
          level: item.boPVNTypeLevel,
          value: valDivMillion(item.value),
        })) || []
      state.origData = payloadData
      state.data = convertData(payloadData, state.isYearly).reverse()
      state.ids = [
        ...new Set(
          payloadData
            ?.filter((item) => item.level === 2)
            .map((item) => `${item.id}`),
        ),
      ]
      state.idLineChart = payloadData?.find((item) => item.level === 1)
        ? [
            ...new Set(
              payloadData
                ?.filter((item) => item.level === 1)
                .map((item) => `${item.id}`),
            ),
          ]
        : []
    })
    builder.addCase(getChangeByTimeRange.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectActiveCategory = (state) => state[slice.name].activeCategory
export const selectIds = (state) => state[slice.name].ids
export const selectIdLineChart = (state) => state[slice.name].idLineChart
export const selectCurrentComponent = (state) =>
  state[slice.name].currentComponent
export const selectCurrentTypeStatistics = (state) =>
  state[slice.name].currentTypeStatistics
export const selectKeepListCheckbox = (state) =>
  state[slice.name].isKeepListCheckbox

export const {
  changeActiveCategory,
  changeIsYearlyComponentsChart,
  changeComponent,
  changeTypeStatisticsComponentsChart,
  keepListCheckbox,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, isYearly) => {
  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(data).map((item) => getYearKey(item.year))
    : getQuarterAndYearInDataRange(data).map((item) =>
        getQuarterAndYearKey(item.quarter, item.year),
      )

  //get all cpiVNId and corresponding value from specified data range
  const getDataByQuarterAndYear = (quarter, year) => {
    return data
      .filter((item) => item.year === year && item.quarter === quarter)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? 0,
      }))
  }

  const getDataByYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? getYearInDataRange(data).map((item) => [...getDataByYear(item.year)])
    : getQuarterAndYearInDataRange(data).map((item) => [
        ...getDataByQuarterAndYear(item.quarter, item.year),
      ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [`${e.id}`]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
