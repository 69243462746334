import { isEmpty, maxBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import PopupAlertSuccess from '../../../common/popup/PopupAlertSuccess'
import { PopupHeaderCommon } from '../../../common/popup/PopupHeaderCommon'
import { invertKeyBy, keyBy } from '../../../utils/Common'
import {
  FORMAT,
  getCurrentDateTime,
  getDateWithTimezone,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { valueTab as valueTabType } from '../infoSec/constants'
import {
  selecBondIds,
  selecBondInfo,
  selectActiveTab as selectActiveTabType,
  selectCreateBondId,
} from '../infoSec/store/slice'
import InputDate from './InputDate'
import { typePopup, valueCheckBox, valueTab } from './constants'
import { areArraysEqual } from './helper'
import style from './index.module.css'
import {
  addMoreTab,
  changeSaveStatus,
  openPopup,
  selectActiveTab,
  selectDataTab,
  selectDataTabOld,
  selectListTab,
  selectSaveStatus,
  selectStateSameIndustry,
  updateTab,
} from './store/slice'
import { addNewApi, getPriceYtmApi, updateApi } from './store/thunk'

const defaultRowAdd = {
  name: '',
  date: getCurrentDateTime(FORMAT.DATE),
}

function sortByTradingDate(a, b) {
  const dateA = new Date(a.tradingDateId)
  const dateB = new Date(b.tradingDateId)

  if (dateA < dateB) {
    return -1
  }
  if (dateA > dateB) {
    return 1
  }
  return 0
}

const filterArrayDate = (array, tradingDate, locale) => {
  if (isEmpty(array)) {
    return null
  }
  const currentDate = getISOStartOrEndOfDay(tradingDate, locale, true)
  const closestElement = array.reduce((prev, curr) => {
    const prevSettlementDate = new Date(
      getISOStartOrEndOfDay(prev.tradingDateId, locale, true),
    )
    const currSettlementDate = new Date(
      getISOStartOrEndOfDay(curr.tradingDateId, locale, true),
    )
    const prevDiff = Math.abs(prevSettlementDate - new Date(currentDate))

    const currDiff = Math.abs(currSettlementDate - new Date(currentDate))

    return prevDiff < currDiff ? prev : curr
  })
  return closestElement
}

const Bottom = () => {
  const timeZone = UseTimeZone()
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const dataTab = useSelector(selectDataTab)
  const dataOld = useSelector(selectDataTabOld)
  const active = useSelector(selectActiveTab)
  const listTab = useSelector(selectListTab)
  const bondId = useSelector(selecBondIds)
  const form = useSelector(selectStateSameIndustry)
  const bondInfo = useSelector(selecBondInfo)
  const activeTab = useSelector(selectActiveTabType)
  const createBondId = useSelector(selectCreateBondId)
  const saveStatus = useSelector(selectSaveStatus)

  const [rowAdd, setrowAdd] = useState(defaultRowAdd)
  const disable =
    active === valueTab.BOND_CREATED || active === valueTab.BOND_DEFAULT
  const [show, setshow] = useState(false)
  const [showSave, setShowSave] = useState(false)

  const onSave = () => {
    if (disable) {
      return
    }
    const dataInvert = invertKeyBy(dataTab?.[active]?.data)
    const tabActive = listTab.find((e) => e.value === active)
    if (dataInvert?.[0]?.bondCashFlowId) {
      const params = {
        parameter: {
          lstBondCashFlow: dataInvert.map((e) => ({
            ...e,
            bondCashFlowDetailId: e?.bondCashFlowDetailId,
            bondCashFlowDetailName: e?.bondCashFlowDetailName || '',
            cashFlowTypeId: e?.cashFlowTypeId,
            bondPaymentTypeId: e?.bondPaymentTypeId,
            tradingDateId: e?.tradingDateId,
            expectedInterestPaymentPeriod: 0,
            couponTypeId: e?.couponTypeId,
            paymentCalendarId: e?.paymentCalendarId,
            paymentCalendarMonth: e?.paymentCalendarMonth,
            couponValue:
              e?.bondPaymentTypeId === 1 ? e.estimatedValue : e?.parValue,
            principalValue: e?.parValue,
            pv: 0,
            floatInterestBenchmarkId: e?.floatInterestBenchmarkId,
            bondInterestId: e?.bondInterestId,
            estimateValue: parseInt(e?.estimateValue),
          })),
          userBondCashFlowName: tabActive?.title,
          bondId:
            activeTab === valueTabType.BOND_DEFAULT ? bondId : createBondId,
          parValue: dataInvert?.[0]?.parValue,
          isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
          bondCashFlowId: dataInvert?.[0]?.bondCashFlowId,
        },
      }
      dispatch(updateApi(params))
    } else {
      const params = {
        parameter: {
          lstBondCashFlow: dataInvert.map((e) => ({
            bondCashFlowDetailName: e?.bondCashFlowDetailName || '',
            cashFlowTypeId: e?.cashFlowTypeId,
            bondPaymentTypeId: e?.bondPaymentTypeId,
            tradingDateId: e?.tradingDateId,
            expectedInterestPaymentPeriod: 0,
            couponTypeId: e?.couponTypeId,
            paymentCalendarId: e?.paymentCalendarId,
            paymentCalendarMonth: e?.paymentCalendarMonth,
            couponValue:
              e?.bondPaymentTypeId === 1 ? e.estimatedValue : e?.parValue,
            principalValue: e?.parValue,
            pv: 0,
            floatInterestBenchmarkId: e?.floatInterestBenchmarkId,
            bondInterestId: e?.bondInterestId,
            estimateValue: e?.estimateValue,
          })),

          userBondCashFlowName: tabActive?.title,
          bondId:
            activeTab === valueTabType.BOND_DEFAULT ? bondId : createBondId,
          parValue: dataInvert?.[0]?.parValue,
          isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
        },
      }
      return dispatch(
        addNewApi({
          params,
          isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
        }),
      )
    }
  }

  const onChangeAddForm = (value) => {
    setrowAdd({
      ...rowAdd,
      date: value,
    })
  }

  const onChangeNameAddForm = (value) => {
    if (value.length >= 50) {
      dispatch(
        openPopup({
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M004'),
          isShow: true,
          type: typePopup.WARNING,
        }),
      )
    }
    setrowAdd({
      ...rowAdd,
      name: value,
    })
  }

  const onClosePopup = () => {
    setrowAdd(defaultRowAdd)
    setshow(false)
  }

  const onChanPrice = (listBondCashFlow) => {
    const price =
      form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.dirtyprice || 0
        : form.checkBox === valueCheckBox.CLEAN_PRICE
        ? parseFloat(form.cleanPrice) + parseFloat(form.accInterest) || 0
        : 0

    if (price > 0 || parseFloat(form?.ytm) > 0) {
      const listBondCreated = invertKeyBy(dataTab?.[valueTab.BOND_DEFAULT].data)
      const checkWeigh = areArraysEqual(listBondCashFlow, listBondCreated)
      const listBond = listBondCashFlow
        .filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) >= new Date(form.paymentDate),
        )
        .map((e) => {
          const result = {
            settlementdate: e.tradingDateId,
            frequency: bondInfo.paymentCalendarMonth,
            paymentStatusId: e.paymentStatusId,
            coupon:
              e.bondPaymentTypeId === 1
                ? parseFloat(e?.couponInterestRate || 0) / 100
                : 0,
          }

          return result
        })
      const pastDay = filterArrayDate(
        listBondCashFlow.filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )
      const paramsLstBondCashFlow = [...listBond]
      if (pastDay) {
        paramsLstBondCashFlow.unshift({
          settlementdate: pastDay?.tradingDateId,
          frequency: bondInfo?.paymentCalendarMonth,
          paymentStatusId: pastDay?.paymentStatusId,
          coupon: parseFloat(pastDay?.couponInterestRate) / 100,
        })
      }

      const originalDebt = listBondCashFlow.find(
        (e) =>
          e.bondPaymentTypeId === 4 &&
          new Date(e.tradingDateId) > new Date(form.paymentDate),
      )

      const params = {
        lstBondCashFlow: paramsLstBondCashFlow,
        issueDate: bondInfo.issueDateId,
        weigh: checkWeigh ? 0 : 1,
        paydate: new Date(getDateWithTimezone(form.paymentDate, locale)),
        interestBasisTypeId: form.interestBasis,
        price:
          form.checkBox === valueCheckBox.DIRTY_PRICE ? form.dirtyprice : 0,
        ytm:
          form.checkBox === valueCheckBox.YTM ? parseFloat(form?.ytm) / 100 : 0,
        parValue: parseInt(originalDebt?.estimatedValue ?? 0),
        cleanPrice:
          form.checkBox === valueCheckBox.DIRTY_PRICE
            ? parseFloat(form.cleanPrice)
            : 0,
        exPayDate: form.exDate
          ? new Date(getDateWithTimezone(form.exDate, locale))
          : null,
      }
      dispatch(getPriceYtmApi(params))
    }
  }

  const clickAddRow = () => {
    if (rowAdd.name.length <= 0) {
      dispatch(
        openPopup({
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M006'),
          isShow: true,
          type: typePopup.WARNING,
        }),
      )
      return
    }
    const dataInvert = invertKeyBy(dataTab?.[active]?.data)

    const dataMaxById = maxBy(dataInvert, (item) => item.id)

    const newElement = {
      ...dataInvert[0],
      id: (dataMaxById?.id ?? 0) + 1,
      bondCashFlowDetailName: rowAdd.name,
      tradingDateId: getISOStartOrEndOfDay(rowAdd.date, timeZone, true),
      couponInterestRate: 0,
      estimatedValue: 0,
    }

    const dataSortNotFormatted = [...dataInvert, newElement].sort(
      sortByTradingDate,
    )

    const dataSort = dataSortNotFormatted
      .map((e, index) => {
        if (index === 0) {
          if (new Date(e.tradingDateId) < new Date(form.paymentDate)) {
            return e
          }

          const days = moment(e?.tradingDateId).diff(e?.issueDateId, 'days')

          return {
            ...e,
            couponInterestRate: e?.couponInterestRate,
            estimatedValue:
              ((e?.couponInterestRate / 100) * e.parValue) /
              ((days ? (days === 366 ? 365 : days) : 0) / 365),
          }
        }

        if (index !== 0 && e?.bondPaymentTypeId === 4) {
          return e
        }

        const days = moment(e?.tradingDateId).diff(
          dataSortNotFormatted[index - 1]?.tradingDateId,
          'days',
        )

        return {
          ...e,
          couponInterestRate: e?.couponInterestRate,
          estimatedValue:
            ((e?.couponInterestRate / 100) * e.parValue) /
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
        }
      })
      .map((e, index) => ({ ...e, id: index }))

    dispatch(
      updateTab({
        data: keyBy(dataSort, 'id'),
        ids: dataSort.map((e) => e.id),
      }),
    )
    onChanPrice(dataSort)
    onClosePopup()
  }

  const onReset = () => {
    if (disable) {
      return
    }
    onChanPrice(invertKeyBy(dataOld?.[active]?.data))
    dispatch(updateTab(dataOld[active]))
  }

  const checkAddRow = () => {
    if (active !== valueTab.BOND_CREATED && active !== valueTab.BOND_DEFAULT) {
      const data = invertKeyBy(dataTab?.[active]?.data)
      const dataCreated = invertKeyBy(dataTab?.[valueTab.BOND_CREATED]?.data)

      if (data.length - dataCreated.length >= 10) {
        dispatch(
          openPopup({
            message: I18n.t('bond.valuation.sameIndusTryBond.SC07_MS0011'),
            isShow: true,
            type: typePopup.WARNING,
          }),
        )
        return
      }
      setshow(true)
    } else {
      if (listTab.length >= 7) {
        return dispatch(
          openPopup({
            isShow: true,
            message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M005'),
            typePopup: typePopup.ADD_ROW,
          }),
        )
      } else {
        const nameTab = I18n.t('bond.valuation.common.nameTab', {
          number: listTab.length - 1,
        })
        dispatch(addMoreTab({ nameTab }))
        setshow(true)
      }
    }
  }

  useEffect(() => {
    if (!!saveStatus?.length) {
      setShowSave(true)
    }
  }, [saveStatus])

  return (
    <div className="d-flex justify-content-space-between ali-center">
      <div>
        <Button
          className={`btn h-20 ${style.btnAdd}`}
          onClick={() => checkAddRow()}
        >
          <Span
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: '#FFF',
            }}
            className="mr-8 ml-8"
          >
            +
          </Span>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 340,
              color: '#FFF',
            }}
            className="mr-8"
          >
            {I18n.t('bond.valuation.sameIndusTryBond.bottom.btnAdd')}
          </Span>
        </Button>
      </div>
      <div className="d-flex justify-content-space-between ali-center">
        <Button
          className={`btn h-20 ${style.btnSave} mr-8 ${
            disable && style.disabled
          }`}
          onClick={() => onSave()}
        >
          <Span
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: '#FFF',
            }}
            className="ml-32 mr-32"
          >
            {I18n.t('bond.valuation.sameIndusTryBond.bottom.btnSave')}
          </Span>
        </Button>
        <Button
          className={`btn w-80 h-20 ${style.btnReset} d-flex ali-center ${
            disable && style.disabled
          }`}
          onClick={() => onReset()}
          style={{
            padding: '0px 10px',
          }}
        >
          <span className="icon-refresh mr-8 ml-8"></span>
          <Span
            style={{
              fontSize: 12,
              fontWeight: 600,
              color: '#FFF',
            }}
            className="mr-8"
          >
            {I18n.t('bond.valuation.sameIndusTryBond.bottom.btnReset')}
          </Span>
        </Button>
      </div>
      {show && (
        <PopupHeaderCommon
          setIsShow={setshow}
          title="bond.valuation.sameIndusTryBond.popupAddRow.title"
        >
          <div className={style.nameRow}>
            <div className="mr-10">
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <Translate value="bond.valuation.sameIndusTryBond.popupAddRow.labelName" />
              </Span>
            </div>
            <Input
              className={style.nameInput}
              style={{ fontSize: 12, fontWeight: 340 }}
              value={rowAdd.name}
              onChange={(e) => onChangeNameAddForm(e.target.value)}
            />
          </div>
          <div className={style.nameRow}>
            <div className="mr-10">
              <Span style={{ fontSize: 11, fontWeight: 600 }}>
                <Translate value="bond.valuation.sameIndusTryBond.popupAddRow.LabelDate" />
              </Span>
            </div>
            <InputDate
              date={rowAdd.date}
              onChangeDate={(e) => onChangeAddForm(e)}
            />
          </div>
          <div className={style.footerContainer}>
            <Button
              className={`btn btn-blue w-80 h-20 ${style.createBtn} `}
              style={{ fontSize: 11, fontWeight: 500 }}
              onClick={() => clickAddRow()}
            >
              <Translate value="bond.valuation.sameIndusTryBond.popupAddRow.btnSave" />
            </Button>
            <Button
              className={`btn normal w-80 h-20 ${style.cancelBtn} `}
              style={{ fontSize: 11, fontWeight: 500 }}
              onClick={() => onClosePopup()}
            >
              <Translate value="bond.valuation.sameIndusTryBond.popupAddRow.btnCancel" />
            </Button>
          </div>
        </PopupHeaderCommon>
      )}
      <PopupAlertSuccess
        message={
          <Translate
            value="bond.valuation.sameIndusTryBond.popupAddRow.SUCCESS"
            name={saveStatus}
          />
        }
        isShow={showSave}
        handleClose={() => {
          setShowSave(false)
          dispatch(changeSaveStatus(''))
        }}
      />
    </div>
  )
}

export default Bottom
