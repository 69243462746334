import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import YearFromTo from './common/YearFromTo'
import { deepCopy } from './helper'
import style from './index.module.css'
import { selectValueDetailPopupIndicator } from './store/slice'

const CashDividendPlan = ({
  handleSubmit,
  handleCancel,
  title,
  minDate,
  maxDate,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const from = useSelector(selectValueDetailPopupIndicator('from'))
  const to = useSelector(selectValueDetailPopupIndicator('to'))

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    detail.dividendYears = range(detail.from, detail.to)
    delete detail.from
    delete detail.to
    handleSubmit({ ...data, detail })
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`d-flex ali-center ${style.cdpContent}`}>
            <Translate value="common.popup.DIVIDEND_YEAR" />
            <div>
              <YearFromTo
                keyFrom="from"
                keyTo="to"
                minYear={Math.max(new Date(minDate).getFullYear(), 2000)}
                maxYear={new Date(maxDate).getFullYear()}
                isSmallInput={true}
              />
            </div>
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
            ratio={to - from + 1}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              ratio={to - from + 1}
              submit={convertData}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

CashDividendPlan.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(CashDividendPlan)
