import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleClickOutside } from '../../common/HandleClickOutside'
import { Loading } from '../../common/loading'
import { updateCharts } from '../chart/store/thunk'
import { PanelContainer } from '../common/panel/PanelContainer'
import {
  changePositionChart,
  enableLinkChart,
  minimizeButtonLinkChart,
  selectAddedLinkChart,
  selectDashboardChartData,
  selectDashboardLoading,
  selectEnableLinkChart,
  selectLinkedSecurity,
} from '../store/slice'
import ChartPanel from './ChartPanel'

const DashboardTab = () => {
  const dispatch = useDispatch()
  const dashboardChartLoading = useSelector(selectDashboardLoading)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const linkedSecurity = useSelector(selectLinkedSecurity)
  const linkedChartIds = useSelector(selectAddedLinkChart)
  const isLinkChart = useSelector(selectEnableLinkChart)

  const dashboardChartDataSort = [...dashboardChartData].sort(
    (a, b) => a.order - b.order,
  )

  const changeChartSecurity = (linkedSecurity) => {
    let clonedChartData = []
    dashboardChartDataSort.forEach((item) => {
      const obj = {}
      Object.assign(obj, item)
      if (linkedChartIds.includes(item.id)) {
        const companyId = JSON.parse(obj.detail).securities.companies
        obj.linked = true
        obj.detail = obj.detail.replaceAll(companyId[0], linkedSecurity)
        dispatch(updateCharts(obj))
      }
      clonedChartData.push(obj)
    })
    dispatch(changePositionChart(clonedChartData))
  }

  useEffect(() => {
    dispatch(enableLinkChart(true))
    dispatch(minimizeButtonLinkChart(false))
  }, [])

  useEffect(() => {
    if (linkedSecurity) {
      changeChartSecurity(linkedSecurity)
    }
  }, [linkedSecurity])

  const onClickOutside = () => {
    if (isLinkChart) {
      dispatch(minimizeButtonLinkChart(true))
    }
  }

  return (
    <>
      {dashboardChartLoading && <Loading isBlack />}
      {!dashboardChartLoading && (
        <HandleClickOutside
          className="w-100 h-100"
          handleClickOutside={onClickOutside}
        >
          <PanelContainer
            appendStyle={{ padding: '0px 16px', height: '100%' }}
            hasAddNewChart={false}
          >
            {dashboardChartDataSort.map((item, index) => (
              <ChartPanel key={item.id} item={item} index={index} />
            ))}
          </PanelContainer>
        </HandleClickOutside>
      )}
    </>
  )
}

DashboardTab.propTypes = {}

export default DashboardTab
