import { withWrapper } from '../../../common/withWrapper'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { PanelBasicInformation } from './basicInformation/PanelBasicInformation'
import { PanelDistributor } from './distributors/PanelDistributor'
import { PanelFundManager } from './fundManagers/PanelFundManager'
import { PanelInvestmentFee } from './investmentFees/PanelInvestmentFee'
import { PanelInvestmentStrategy } from './investmentStrategy/PanelInvestmentStrategy'

const mapKeys = [
  { key: 'BASIC_INFORMATION', component: PanelBasicInformation },
  { key: 'INVESTMENT_FEE', component: PanelInvestmentFee },
  { key: 'DISTRIBUTOR', component: PanelDistributor },
  { key: 'INVESTMENT_STRATEGY', component: PanelInvestmentStrategy },
  { key: 'FUND_MANAGER', component: PanelFundManager },
]

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return mapKeys.map((item) => {
    const Component = item.component
    return (
      <Component
        key={item.key}
        panelRefs={panelRefs}
        panelKey={item.key}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  })
}

export default withWrapper(PANEL_LAYOUTS[22233](mapKeys), panelRefs)(Content)
