import { valDivBillion } from '../../../../../utils/Value'

export const formatDataChart = (data, isPercentFormat) => {
  return data.map((item) => {
    return {
      ...item,
      value1: isPercentFormat ? item.value1 * 100 : valDivBillion(item.value1),
      value2: item.value2 * 100,
    }
  })
}
