import { TIME_FREQUENCY } from '../../../../constants/Datetime'

export const TABLE_TIME_RANGES = [
  {
    title: 'common.FREQUENCY.DAILY',
    value: TIME_FREQUENCY.DAILY,
  },
  {
    title: 'common.FREQUENCY.WEEKLY',
    value: TIME_FREQUENCY.WEEKLY,
  },
  {
    title: 'common.FREQUENCY.MONTHLY',
    value: TIME_FREQUENCY.MONTHLY,
  },
  {
    title: 'common.FREQUENCY.QUARTERLY',
    value: TIME_FREQUENCY.QUARTERLY,
  },
  {
    title: 'common.FREQUENCY.YEARLY',
    value: TIME_FREQUENCY.YEARLY,
  },
]

export const TABLE_COL_KEYS = {
  COL_DATE: 'realDate',
  COL_WEEK: 'realWeek',
  COL_MONTH: 'realMonth',
  COL_QUARTER: 'realQuarter',
  COL_YEAR: 'realYear',
  COL_FROM_DATE: 'startDateId',
  COL_TO_DATE: 'endDateId',
  COL_OUTSTANDING_FUND_CERT: 'shareOutstanding',
  COL_NAV_CERT: 'naVperShareAdjusted',
  COL_CHANGE_NAV: 'percentNAVPerShareChange',
  COL_NAV: 'totalNAV',
  COL_FUND_FLOW: 'fundFlow',
  COL_FOREIGN_VOLUME: 'foreignVolume',
  COL_FOREIGN_RATE: 'foreignPercentage',
  COL_CLOSE_PRICE: 'closePriceAdjusted',
  COL_DISCOUNT_VND: 'discountPremium',
  COL_DISCOUNT_PERCENT: 'percentDiscountPremium',
}

export const TABLE_COL_FORMATS = {
  COL_OUTSTANDING_FUND_CERT: 1,
  COL_NAV_CERT: 1,
  COL_CHANGE_NAV: 1,
  COL_NAV: 10 ** 6,
  COL_FUND_FLOW: 10 ** 6,
  COL_FOREIGN_VOLUME: 1,
  COL_FOREIGN_RATE: 1,
  COL_CLOSE_PRICE: 1,
  COL_DISCOUNT_VND: 1,
  COL_DISCOUNT_PERCENT: 1,
}

export const TABLE_NAV_DATA_FREQUENCY_KEYS = [
  'COL_YEAR',
  'COL_DATE',
  'COL_WEEK',
  'COL_MONTH',
  'COL_QUARTER',
  // 'COL_FROM_DATE',
  // 'COL_TO_DATE',
].map((key) => ({
  title: 'fund.fundProfile.navData.navDataTable.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_NAV_DATA_KEYS = [
  'COL_OUTSTANDING_FUND_CERT',
  'COL_NAV_CERT',
  'COL_CHANGE_NAV',
  'COL_NAV',
  'COL_FUND_FLOW',
  // 'COL_FOREIGN_VOLUME',
  // 'COL_FOREIGN_RATE',
  'COL_CLOSE_PRICE',
  'COL_DISCOUNT_VND',
  'COL_DISCOUNT_PERCENT',
].map((key) => ({
  title: 'fund.fundProfile.navData.navDataTable.' + key,
  colId: TABLE_COL_KEYS[key],
}))
