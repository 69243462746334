import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import EventEmitter, { RESET_TABLE_SORT } from '../../../../utils/EventEmitter'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { TYPE_FORMAT } from '../../common/constants'
import { TABLE_SECTOR_ID } from '../constant'
import Filter from './Filter'
import {
  changeIndicator,
  resetDataTable,
  selectFilterStatus,
  selectGroupColumns,
  selectIds,
  selectIndicator,
  selectIndicatorDefault,
  selectLoading,
  selectPayloadCallBack,
  selectTimeFrequency,
  selectTypeFormatData,
  selectValueById,
  selectViewBy,
  sort,
} from './store/slice'
import { getTableData } from './store/thunk'
import style from './style.module.css'

const TotalRegisterFDI = ({ width, height }) => {
  const dispatch = useDispatch()
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [sortField, setSortField] = useState({})

  const locale = useSelector((state) => state.i18n.locale)
  const FDITypeId = useSelector(selectFilterStatus)
  const isLoading = useSelector(selectLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const ids = useSelector(selectIds)
  const timeFrequency = useSelector(selectTimeFrequency)
  const viewBy = useSelector(selectViewBy)
  const indicator = useSelector(selectIndicator)
  const payloadCallBack = useSelector(selectPayloadCallBack)
  const indicatorDefault = useSelector(selectIndicatorDefault)
  const typeFormatData = useSelector(selectTypeFormatData)

  const scrollToLeftCallback = () => {
    if (payloadCallBack.year && payloadCallBack.month) {
      setTimeout(() => {
        dispatch(
          getTableData({
            FDITypeId: FDITypeId,
            TimeFrequency: timeFrequency,
            Year: payloadCallBack.year,
            Month: payloadCallBack.month,
            NumberOfPeriod: 5,
          }),
        )
      })
      setIsFirstTime(false)
    }
  }

  useEffect(() => {
    setSortField({})
    EventEmitter.dispatch(RESET_TABLE_SORT)
  }, [viewBy, FDITypeId, timeFrequency])

  useEffect(() => {
    dispatch(sort(sortField))
    setIsFirstTime(true)
  }, [sortField])

  useEffect(() => {
    if (FDITypeId)
      dispatch(
        getTableData({
          FDITypeId: FDITypeId,
          TimeFrequency: timeFrequency,
          NumberOfPeriod: 20,
        }),
      )
    setIsFirstTime(true)
  }, [locale, FDITypeId, timeFrequency])

  useEffect(() => {
    dispatch(resetDataTable())
  }, [])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <div
                key={timeFrequency}
                style={{ height: `calc(100% - ${size.height}px)` }}
                id={TABLE_SECTOR_ID}
              >
                {isLoading && isFirstTime ? (
                  <Loading />
                ) : (
                  <Table
                    ids={ids}
                    columnDraggable={false}
                    getDataFromRedux={selectValueById}
                    isLoading={isLoading}
                    rowDraggable={false}
                    sort={sort}
                    schema={groupColumns.map((item, index) => {
                      const title = I18n.t(item.title)
                      const colId = item.key
                      let result = {
                        colId,
                        title,
                        thStyle: {
                          textAlign: 'right',
                        },
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (val, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                ...props.style,
                                textAlign: 'right',
                                color:
                                  (val === EMPTY_VALUE || parseInt(val)) >= 0
                                    ? undefined
                                    : '#ff4752',
                              }}
                            >
                              {typeFormatData === TYPE_FORMAT.CAPITAL
                                ? formatVal(valDivMillion(val))
                                : val}
                            </td>
                          )
                        },
                      }
                      if (index === 0) {
                        result = {
                          ...result,
                          isI18n: true,
                          thStyle: {
                            textAlign: 'left',
                            textTransform: 'uppercase',
                          },
                          tdStyle: {
                            textAlign: 'left',
                          },
                          canCustomTd: true,
                          render: (val, rowId, { style, className }) => (
                            <td
                              className={className}
                              style={{
                                ...style,
                                width: '100%',
                                maxWidth: width / 3,
                              }}
                            >
                              <TextEllipsis isI18n={false} text={val} />
                            </td>
                          ),
                        }
                      }
                      return result
                    })}
                    stickyFirstColumn
                    isPagination={false}
                    hasFooter={false}
                    resizable={false}
                    changeActiveRow={changeIndicator}
                    defaultActiveRowId={indicator?.key || indicatorDefault?.key}
                    scrollToLeftCallback={scrollToLeftCallback}
                    isLazyLoadLeft={true}
                    defaultScrollToRight={isFirstTime}
                    handleSortField={setSortField}
                  />
                )}
              </div>
            )}
            {viewBy && (
              <Span className={style.labelTable} style={{ fontSize: 11 }}>
                <Translate value="economy.fdi.overview.LABEL_TABLE" />
              </Span>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TotalRegisterFDI
