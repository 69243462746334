import { createSlice, current } from '@reduxjs/toolkit'
import { handleDataRealtime } from '../../../../../common/chart/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  timeSaleAreaFormatValue,
  timeSaleBarFormatValue,
  timeSaleDateFilter,
  timeSaleDateFormatAPI,
} from '../constants'
import {
  getDeepTransactionTimeSaleAreaChart,
  getDeepTransactionTimeSaleBarChart,
} from './thunk'

const INCREMENT_KEY = {
  0: 'unidentifiedVolume',
  1: 'buyUpVolume',
  2: 'sellDownVolume',
}

const initialState = {
  areaData: [],
  barData: [],
  timeRange: timeSaleDateFormatAPI[timeSaleDateFilter.ONE_DAY],
  loading: true,
}

export const slice = createSlice({
  name: 'market/equityTrading/deepTransaction/timeSale',
  initialState,
  reducers: {
    changeTimeRange: (state, action) => {
      state.timeRange = timeSaleDateFormatAPI[action.payload]
    },
    changeLoading: (state) => {
      state.loading = !state.loading
    },
    subscribeTimeSaleBarChart: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        let newDataRealtime = []
        const newData = { ...action.payload[0] }
        const lastData = [...current(state.barData)]
        const isExist = lastData.some(
          (item) =>
            item.priceFrom ===
            Number(newData.matchPrice) / timeSaleBarFormatValue.priceFrom,
        )
        if (isExist) {
          newDataRealtime = lastData.map((item) => {
            if (
              item.priceFrom ===
              Number(newData.matchPrice) / timeSaleBarFormatValue.priceFrom
            ) {
              return {
                ...item,
                [INCREMENT_KEY[newData.matchType]]:
                  item[INCREMENT_KEY[newData.matchType]] +
                  Number(newData.matchVolume) /
                    timeSaleBarFormatValue[INCREMENT_KEY[newData.matchType]],
              }
            } else {
              return item
            }
          })
        } else {
          newDataRealtime = [
            ...lastData,
            {
              priceFrom:
                Number(newData.matchPrice) / timeSaleBarFormatValue.priceFrom,
              priceTo:
                Number(newData.matchPrice) / timeSaleBarFormatValue.priceTo,
              buyUpVolume:
                Number(newData.matchType) === 1
                  ? Number(newData.matchVolume) /
                    timeSaleBarFormatValue.buyUpVolume
                  : 0,
              sellDownVolume:
                Number(newData.matchType) === 2
                  ? Number(newData.matchVolume) /
                    timeSaleBarFormatValue.sellDownVolume
                  : 0,
              unidentifiedVolume:
                Number(newData.matchType) === 0
                  ? Number(newData.matchVolume) /
                    timeSaleBarFormatValue.unidentifiedVolume
                  : 0,
              closePrice:
                Number(newData.referencePrice) /
                timeSaleBarFormatValue.closePrice,
            },
          ]
        }
        if (state.timeRange === timeSaleDateFormatAPI['1D']) {
          state.barData = newDataRealtime
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDeepTransactionTimeSaleAreaChart.fulfilled,
      (state, action) => {
        state.loading = false
        const timeRange = action.meta.arg.TimeRange
        const data = [...action.payload.data]
          .sort(
            (a, b) =>
              new Date(a.tradingDate).getTime() -
              new Date(b.tradingDate).getTime(),
          )
          .map((item) => {
            const objData = {}
            Object.keys(item).forEach((key) => {
              objData[key] = timeSaleAreaFormatValue[key]
                ? item[key] / timeSaleAreaFormatValue[key]
                : item[key]
            })
            return objData
          })
        if (timeRange === 'OneDay') {
          const dataRealtime = handleDataRealtime(
            15 * 60 * 1000,
            data,
            'tradingDate',
          )
          state.areaData = dataRealtime
          state.timeRange = timeRange
        } else {
          state.areaData = data
        }
      },
    )
    builder.addCase(
      getDeepTransactionTimeSaleAreaChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(getDeepTransactionTimeSaleBarChart.pending, (state) => {
      state.loading = true
      state.barData = []
    })
    builder.addCase(
      getDeepTransactionTimeSaleBarChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.barData = [...action.payload.data]
          .sort(
            (a, b) =>
              new Date(b.tradingDate).getTime() -
              new Date(a.tradingDate).getTime(),
          )
          .map((item) => {
            const objData = {}
            Object.keys(item).forEach((key) => {
              objData[key] = timeSaleBarFormatValue[key]
                ? item[key] / timeSaleBarFormatValue[key]
                : item[key]
            })
            return objData
          })
        state.timeRange = action.meta.arg.TimeRange
      },
    )
    builder.addCase(
      getDeepTransactionTimeSaleBarChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeTimeRange, subscribeTimeSaleBarChart, changeLoading } =
  slice.actions
export const selectAreaData = (state) => state[slice.name].areaData
export const selectBarData = (state) => state[slice.name].barData
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
