import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { listOption, listTab } from './constants'
import style from './index.module.css'
import {
  selecTradingType,
  selectTabActive,
  updateTab,
  updateTradingType,
} from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const activeTab = useSelector(selectTabActive)
  const valueTradingType = useSelector(selecTradingType)

  const onChangeTab = (item) => {
    dispatch(updateTab(item.value))
  }

  const onChangeFilter = (value) => {
    dispatch(updateTradingType(value))
  }

  return (
    <div className="d-flex ali-center mb-8 group-btn">
      <div>
        <DispatchActionTab
          onChangeTab={(e) => onChangeTab(e)}
          data={listTab}
          activeTab={activeTab}
          textSize={11}
        />
      </div>
      <div className={style.select}>
        <SelectCustom
          value={valueTradingType}
          selectData={listOption}
          handleChange={(e) => onChangeFilter(e)}
          isI18n={true}
        />
      </div>
    </div>
  )
}

export default Filter
