import { getLatestYearData } from '../table/helpers'

export const xAxisKey = 'label'
export const leftUnit =
  'economy.productionAndConsumption.consumption.BILLION_VND'

export const leftLabel =
  'economy.productionAndConsumption.consumption.CHART_BILLION'

export const mappingDisplayName = (maxYear) => {
  return getLatestYearData(maxYear, 5).reduce(
    (a, v) => ({ ...a, [v]: v.toString() }),
    {},
  )
}

export const fillColor = ['#ecffdf', '#40abff', '#facc5c', '#45b29d', '#e61a00']
export const ACC_VALUE = {
  VALUE: 'AccValue',
  NOT_VALUE: 'NotAccValue',
}

export const MONTHS_EN_LIST = [
  { title: 'Jan.', value: 'Th01' },
  { title: 'Feb.', value: 'Th02' },
  { title: 'Mar.', value: 'Th03' },
  { title: 'Apr.', value: 'Th04' },
  { title: 'May', value: 'Th05' },
  { title: 'Jun.', value: 'Th06' },
  { title: 'Jul.', value: 'Th07' },
  { title: 'Aug.', value: 'Th08' },
  { title: 'Sep.', value: 'Th09' },
  { title: 'Oct.', value: 'Th10' },
  { title: 'Nov.', value: 'Th11' },
  { title: 'Dec.', value: 'Th12' },
]
