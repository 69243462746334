import moment from 'moment'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'

export const handleTableData = (tableData, timeZone, locale) => {
  if (!tableData.length) {
    return []
  }

  return tableData
    .map((item, index) => ({
      ...item,
      id: `${item.auctionDateId}-${index}`,
      title: formatDateTime(item.auctionDateId, FORMAT.DATE, locale, timeZone),
    }))
    .sort((a, b) =>
      moment(a.title, FORMAT.DATE[locale]).diff(
        moment(b.title, FORMAT.DATE[locale]),
      ),
    )
    .reverse()
}

export const keyByDate = (payload) => {
  if (!payload.length) {
    return {}
  }

  const result = {}
  payload.forEach((item) => {
    result[item.id] = {
      ...item,
      participantWinner: `${item.participant}/${item.winner}`,
    }
  })

  return result
}

export const handleLimitDate = (payload, timeZone) => {
  if (!payload.length) {
    return {
      minDate: '',
      maxDate: '',
      minYear: '',
      maxYear: '',
    }
  }

  const limit = {}
  payload.forEach(({ realDay, realMonth, realYear }, index) => {
    const limitDate = getISOStartOrEndOfDay(
      new Date(realYear, realMonth - 1, realDay),
      timeZone,
      true,
    )
    if (index === 0) {
      limit.minDate = limitDate
      limit.minYear = realYear
    } else {
      limit.maxDate = limitDate
      limit.maxYear = realYear
    }
  })

  return limit
}
