import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class SimpleSearchProxy extends ProxyBase {
  searchCompany(params) {
    return this.get('SearchCompany', params)
  }

  searchIndies(params) {
    return this.get('SearchIndies', params, '', false, JSON.stringify(params))
  }

  searchCompanyOrIndex(params) {
    return this.get('SearchCompanyOrIndex', params)
  }

  searchHOHAUPCompany(params) {
    return this.get('SearchHOHAUPCompany', params)
  }

  searchBondAndUserBond(params) {
    return this.get('SearchBondAndUserBond', params)
  }
  searchBond(params) {
    return this.get('SearchBond', params)
  }
}

export default new SimpleSearchProxy(
  ServiceProxyConfig.Common.SimpleSearch.ServiceUrl,
)
