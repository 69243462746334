import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { TooltipWarning } from '../../../../../common/tooltipWarning'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import style from './index.module.css'
import { selectDataBondDetail } from './store/slice'

const widthPopup = 800
const BodyPopup = ({ setIsShow }) => {
  const data = useSelector(selectDataBondDetail)
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <div style={{ width: widthPopup }}>
      <div className={style.wrapPopup}>
        <div className={style.column}>
          <div style={{ width: widthPopup / 2 }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
                textDecorationLine: 'underline',
              }}
            >
              <TextEllipsis
                text={'bond.bondSecurity.generalSetting.popup.infoBasic.title'}
              />
            </Span>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.infoBasic.bondId'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.ticker} />
              </Span>
            </div>
          </div>

          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.infoBasic.codeISIN'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data?.bondIsinCode || ''} />
              </Span>
            </div>
          </div>

          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.infoBasic.issues'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={data.organizationShortName}
                />
              </Span>
            </div>
          </div>

          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.infoBasic.sector'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.icbName} />
              </Span>
            </div>
          </div>

          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.infoBasic.typeBond'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.bondType} />
              </Span>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <div style={{ width: widthPopup / 2 }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
                textDecorationLine: 'underline',
              }}
            >
              <TextEllipsis
                text={
                  'bond.bondSecurity.generalSetting.popup.interestRate.title'
                }
              />
            </Span>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.typeCouppon'
                  }
                />
              </Span>
            </div>
            <div
              style={{ width: widthPopup / 4 }}
              className="d-flex ali-center"
            >
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 340,
                  marginRight: 5,
                  color:
                    data?.couponTypeId === 1
                      ? '#FECF2F'
                      : data?.couponTypeId === 3
                      ? '#00E5E4'
                      : '#FFF',
                }}
              >
                <TextEllipsis isI18n={false} text={data.couponTypeName} />
              </Span>
              {(data?.couponTypeId === 1 || data?.couponTypeId === 3) && (
                <TooltipWarning
                  tooltip={data?.interestDescription ?? ''}
                  isI18n={false}
                  isHtml
                  maxWidthTooltip={375}
                  alignItems={'center'}
                />
              )}
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.method'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.issueMethodName} />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.interestPaymentPeriod'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={`${data.paymentCalendarMonth} ${I18n.t(
                    'common.calendar.MONTH',
                  )}`}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.NumberOfExpectedPayable'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  // text={`${data.expectedInterestPaymentPeriod} ${I18n.t(
                  //   'common.calendar.MONTH',
                  // )}`}
                  text={`${data.expectedInterestPaymentPeriod}`}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.FixedCoupon'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={`${formatVal(data.fixedInterestRate * 100)}%`}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.NumberOfFixedCoupon'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={data.fixedInterestPaymentPeriod}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.FloatInterestSpread'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={`${formatVal(data.floatInterestSpread * 100)}%`}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.ReferenceInterestRate'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={
                    data.floatBenchmark
                      ? formatVal(data.floatBenchmark * 100)
                      : ''
                  }
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.interestRate.NumberofFloatcoupon'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={data.expectedInterestPaymentPeriod}
                />
              </Span>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <div style={{ width: widthPopup / 2 }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
                textDecorationLine: 'underline',
              }}
            >
              <TextEllipsis
                text={
                  'bond.bondSecurity.generalSetting.popup.IssueInformation.title'
                }
              />
            </Span>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.IssueMarket'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.locationName} />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.IssueForm'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 340,
                  color:
                    data?.issueMethodId === 6
                      ? '#FECF2F'
                      : data?.issueMethodId === 5
                      ? '#ECECEC'
                      : '#FFF',
                }}
              >
                <TextEllipsis
                  isI18n={false}
                  text={data.issueMethodName}
                  appendStyle={{
                    color:
                      data?.issueMethodId === 6
                        ? '#FECF2F'
                        : data?.issueMethodId === 5
                        ? '#ECECEC'
                        : '#FFF',
                  }}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.Currency'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.currencyCode} />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.IssueDate'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatDateTime(data.issueDateId, FORMAT.DATE, locale)}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.MaturityDate'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatDateTime(
                    data.maturityDateId,
                    FORMAT.DATE,
                    locale,
                  )}
                />
              </Span>
            </div>
          </div>

          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.Duration'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis isI18n={false} text={data.durationName} />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.ParValue'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatVal(data.parValue, 0)}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.IssueValue'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatVal(valDivBillion(data.issueValue))}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.OutstandingValue'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatVal(valDivBillion(data.outstandingValue))}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.IssueInformation.RemainingDuration'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={formatVal(data.remainingDuration)}
                />
              </Span>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <div style={{ width: widthPopup / 2 }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
                textDecorationLine: 'underline',
              }}
            >
              <TextEllipsis
                text={
                  'bond.bondSecurity.generalSetting.popup.ConditionsAttached.title'
                }
              />
            </Span>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Redemption'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#FECF2F' }}>
                <TextEllipsis
                  isI18n={true}
                  text={
                    data.isRedemption === 1
                      ? 'bond.bondSecurity.common.yes'
                      : 'bond.bondSecurity.common.no'
                  }
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Convertible'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#FECF2F' }}>
                <TextEllipsis
                  isI18n={true}
                  text={
                    data.isConvertable === 1
                      ? 'bond.bondSecurity.common.yes'
                      : 'bond.bondSecurity.common.no'
                  }
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Coveredwarrant'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#FECF2F' }}>
                <TextEllipsis
                  isI18n={true}
                  text={
                    data.isCoveredWarrant === 1
                      ? 'bond.bondSecurity.common.yes'
                      : 'bond.bondSecurity.common.no'
                  }
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Issueguarantee'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#3DA967' }}>
                <TextEllipsis
                  isI18n={false}
                  text={data?.issueShortName || ''}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Paymentguarantee'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#FECF2F' }}>
                <TextEllipsis
                  isI18n={false}
                  text={data?.paymentShortName || ''}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.CollateralType'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis
                  isI18n={false}
                  text={data?.collateralTypeName || ''}
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.GreenBond'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 340, color: '#FECF2F' }}>
                <TextEllipsis
                  text={
                    data.isGreenBond === 1
                      ? 'bond.bondSecurity.common.yes'
                      : 'bond.bondSecurity.common.no'
                  }
                />
              </Span>
            </div>
          </div>
          <div className="d-flex ali-center">
            <div style={{ width: widthPopup / 4 }}>
              <Span style={{ fontSize: 12, fontWeight: 600 }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.generalSetting.popup.ConditionsAttached.Status'
                  }
                />
              </Span>
            </div>
            <div style={{ width: widthPopup / 4 }}>
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 340,
                  color:
                    data?.activestatusId === 15
                      ? '#3DA967'
                      : data?.activestatusId === 17
                      ? '#FECF2F'
                      : '#ECECEC',
                }}
              >
                <TextEllipsis
                  isI18n={false}
                  text={data?.activeStatusName || ''}
                />
              </Span>
            </div>
          </div>
        </div>
      </div>

      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          className={`btn btn-blue h-20 w-80`}
          onClick={() => setIsShow(false)}
        >
          <Span style={{ fontSize: 12, fontWeight: '600' }}>
            {I18n.t('bond.bondSecurity.generalSetting.popup.btnOk')}
          </Span>
        </Button>
      </div>
    </div>
  )
}

export default BodyPopup
