import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class SecondaryMarketStatisticsProxy extends ProxyBase {
  getSectorStatistics(params) {
    return this.get('Overview', params)
  }
  getCorrelationOfMarketBySector(params) {
    return this.get('CorrelationMarketInterestRemainDurationBySector', params)
  }
  getCorrelationOfMarketByOrganization(params) {
    return this.get(
      'CorrelationMarketInterestRemainDurationByOrganization',
      params,
    )
  }
  getMarketTradingValueByIssueMethod(params) {
    return this.post('MarketLiquidityByIssueMethod', params)
  }
  getMarketTradingValueByTradingMethod(params) {
    return this.post('MarketLiquidityByTradingMethod', params)
  }
  getMarketTradingValueBySector(params) {
    return this.post('MarketTradingValueSector', params)
  }

  downloadIssuanceValue(params) {
    return this.get('OverviewExport', params, 'download')
  }
}

export default new SecondaryMarketStatisticsProxy(
  ServiceProxyConfig.Bond.BenchmarkingSecondary.ServiceUrl,
)
