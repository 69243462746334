import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import style from '../index.module.css'
import AllCheckbox from './AllCheckbox'
import { getTickerIds } from './store/slice'
import TickerItem from './TickerItem'

const ListStockCheckbox = () => {
  const tickerIds = useSelector(getTickerIds)

  return (
    <div className={style.listStockCheckbox}>
      <AllCheckbox />
      <div style={{ flex: 1 }}>
        <ScrollComponent>
          {tickerIds.map((tickerId) => (
            <TickerItem key={tickerId} tickerId={tickerId} />
          ))}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default ListStockCheckbox
