import { Translate } from 'react-redux-i18n'
import useHeightTdTable from '../../../../common/hooks/useHeightTdTable'
import { Span } from '../../../../common/html/Span'
import { Z_INDEX } from '../../../../constants/Common'
import { BUY_CK, BUY_TM } from './constants'
import style from './index.module.css'

const TOTAL_PADDING_TOP_BOTTOM = 10

const TableHeader = ({ tableHeaderColAttr }) => {
  const heightTd = useHeightTdTable()
  const heightTh = heightTd + TOTAL_PADDING_TOP_BOTTOM

  const renderTitle = (text) => {
    return (
      <Span
        className={style.theadContent}
        style={{ fontSize: 10, fontWeight: 600 }}
      >
        {text ? <Translate value={text} /> : ''}
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      {tableHeaderColAttr.map((row, index) => {
        return (
          <tr key={index}>
            {row.map((col, i) => {
              return (
                <th
                  key={col.name}
                  {...col?.attrs}
                  style={{
                    ...col?.style,
                    height: heightTh,
                    position: 'relative',
                  }}
                >
                  {col?.icon}
                  {renderTitle(col.value)}
                  <div
                    className={style.fixedBorderBottom}
                    style={{
                      backgroundColor:
                        index === 0 && i === 0 ? '#273040' : '#212832',
                    }}
                  />
                  {(col.value === BUY_TM || col.value === BUY_CK) && (
                    <div className={style.fixedBorderRight} />
                  )}
                  {col.name.includes('firstCell') && (
                    <div className={style.fixedBorderLeft} />
                  )}
                  {col.name.includes('lastCell') && (
                    <div className={style.fixedBorderLastCell} />
                  )}
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default TableHeader
