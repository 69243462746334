import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Loading } from '../../../common/loading'
import { Image } from '../../../common/news/image'
import { Item } from '../../../common/news/item'
import { NoData } from '../../../common/noData'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../constants/routes'

export const HeaderCommon = ({ data, isLoading }) => {
  const navigate = useCustomNavigate()
  const basicInfo = useSelector(selectBasicInfo)

  const renderContent = () => {
    if (isLoading) {
      return <Loading />
    }

    if (Object.keys(data).length === 0) {
      return (
        <div className="w-100 d-flex ali-center justify-content-center">
          <NoData />
        </div>
      )
    }

    const onAction = (newsId) => () => {
      navigate(
        FEATURES.corporate.components['news-events'].components['news-detail']
          .route,
        basicInfo,
        '?newsId=' + newsId,
      )
    }

    return (
      <>
        <div className="h-100">
          <Image
            url={data.imageUrl}
            isCrop
            width={366}
            height={214}
            className="h-100 b-radius-4"
            isDefaultSquareImg={false}
          />
        </div>
        <div className="w-100 h-100 ml-10">
          <Item
            item={data}
            isShowBtnShare
            distanceBetweenItem={30}
            titleFontSize={26}
            titleLineClamp={2}
            onAction={onAction(data.newsId)}
          />
        </div>
      </>
    )
  }

  return (
    <div className="w-100 h-100 d-flex position-relative">
      {renderContent()}
    </div>
  )
}

HeaderCommon.propTypes = {
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}
