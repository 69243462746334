import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Bar, Line, ReferenceLine } from 'recharts'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import ReferencePrice from '../../../../common/chart/rechart/ReferencePrice'
import { selectPriceData } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FORMAT } from '../../../../utils/Datetime'
import {
  barKeys,
  fillColor,
  keyXAxis,
  lineKeys,
  pricePerformanceFormatValue,
} from './constants'

export const ChartComponent = ({ data, ticker, width, height, dateType }) => {
  // Use selector
  const priceData = useSelector(selectPriceData)

  // Get data
  const openIndex =
    (data.length && data[0].referencePrice) ||
    priceData.priceInfo?.referencePrice /
      pricePerformanceFormatValue.referencePrice

  const mappingDisplayName = {
    closePrice: ticker,
    matchVolume: 'market.equityTrading.deepTransaction.pricePerformance.VOLUME',
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: barKeys,
        yAxisId: 'barChart',
        unit: 'market.equityTrading.deepTransaction.pricePerformance.THOUSAND',
        decimalLength: 2,
      },
      {
        keys: lineKeys,
        yAxisId: 'lineChart',
        decimalLength: 2,
      },
    ]
  }

  // Render
  const renderBarChart = () => {
    return (
      <Bar
        isAnimationActive={false}
        yAxisId="barChart"
        dataKey={barKeys[0]}
        fill={fillColor[barKeys[0]]}
      />
    )
  }

  const renderLineChart = () => {
    return (
      <Line
        yAxisId="lineChart"
        dataKey={lineKeys[0]}
        type="linear"
        stroke={fillColor[lineKeys[0]]}
        fill={fillColor[lineKeys[0]]}
        strokeWidth={2}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    )
  }

  const renderReferenceLineChart = () => {
    return (
      <>
        <ReferenceLine yAxisId="barChart" y={0} stroke="#555555" label="" />
        <ReferenceLine
          yAxisId="lineChart"
          y={openIndex}
          stroke="#ffcc24"
          label={(ctx) => (
            <ReferencePrice ctx={ctx} value={openIndex} yAxisWidth={50} />
          )}
        />
      </>
    )
  }

  return (
    width &&
    height && (
      <ChartContainer
        data={data}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        yAxis={[
          {
            id: 'lineChart',
            keys: lineKeys,
            isLineChart: true,
            orientation: 'left',
            tickFormatter: () => '',
            customDomain: ([min, max]) => [
              Math.min(openIndex, min) / 1.02,
              Math.max(openIndex * 1.01, max),
            ],
          },
          {
            id: 'barChart',
            keys: barKeys,
            isBarChart: true,
            orientation: 'right',
            tickFormatter: () => '',
          },
        ]}
        timeFrame={dateType}
        isHasCartesianGrid={false}
        tooltipSchema={{
          title: { formatDate: FORMAT.DATE_TIME },
          content: getContent(),
          mappingDisplayName: mappingDisplayName,
        }}
        isShowOriginalXAxis={false}
      >
        {renderBarChart()}
        {renderLineChart()}
        {renderReferenceLineChart()}
      </ChartContainer>
    )
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  ticker: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dateType: PropTypes.string.isRequired,
}
