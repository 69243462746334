import watchlistTable from './watchlistTable'
import watchlistDetail from './watchlistDetail'

const common = {
  TITLE: 'My Watchlist',
}

const watchlist = {
  watchlistTable,
  watchlistDetail,
  common,
}

export default watchlist
