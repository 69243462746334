import { useSelector } from 'react-redux'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import Ownership from './ownership'
import { TIME_FRAME_OWNERSHIP } from './ownership/constants'
import { selectTimeFrame } from './ownership/store/slice'

const PanelOwnership = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  width,
  height,
}) => {
  const nameScreen = UseI18n('market.marketInDepth.foreign.NAME_SCREEN')
  const chartName = UseI18n('market.marketInDepth.foreign.OWNERSHIP_CHART')
  const timeFrame = useSelector(selectTimeFrame)
  const indexInfo = useSelector(selectIndexInfo)
  const allTabName = UseI18n('market.marketInDepth.foreign.All')

  return (
    <Panel
      title={'market.marketInDepth.foreign.OWNERSHIP'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={UseI18n('market.marketInDepth.foreign.OWNERSHIP')}
      downloadJpgParams={{
        domId: 'market-foreign-ownership',
        nameScreen,
        chartName,
        tickerName: indexInfo?.groupCode || '',
        tabName:
          timeFrame === TIME_FRAME_OWNERSHIP.ALL ? allTabName : timeFrame,
      }}
    >
      <Ownership width={width} height={height} />
    </Panel>
  )
}

export default PanelOwnership
