import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../../utils/Value'
import style from '../../index.module.css'
import { FOUR_LINES, LINE_FOUR_COLORS, LINE_THREE_COLORS } from './constants'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 45 }
const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  titleLineKeys,
  isYearly,
}) => {
  const chartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {lineKeys.map((item) => (
              <tr className={style.trTable} key={item}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {I18n.t(titleLineKeys[lineKeys.indexOf(item)])}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{ fontSize: 11 }}
                    className={
                      valueTooltip[item] < 0 ? style.negativeValColor : ''
                    }
                  >
                    {`${formatVal(valueTooltip[item])}${
                      valueTooltip[item]
                        ? I18n.t('sector.sectorSpecific.steel.PERCENT')
                        : ''
                    }`}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      margin={MARGIN}
      yAxis={[
        {
          id: 'left',
          orientation: 'left',
          keys: lineKeys,
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: '%',
        },
      ]}
      timeFrame={!isYearly && TIME_RANGES.CUSTOM}
      isNotFormatXAxis={!isYearly}
      renderCustomTooltip={(payload) => chartTooltip(payload)}
    >
      <>
        {lineKeys.map((item) =>
          lineKeys.length === FOUR_LINES ? (
            <Line
              key={item}
              yAxisId={'left'}
              dataKey={item}
              type="linear"
              stroke={LINE_FOUR_COLORS[lineKeys.indexOf(item)]}
              fill={LINE_FOUR_COLORS[lineKeys.indexOf(item)]}
              strokeWidth={1.5}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          ) : (
            <Line
              key={item}
              yAxisId={'left'}
              dataKey={item}
              type="linear"
              stroke={LINE_THREE_COLORS[lineKeys.indexOf(item)]}
              fill={LINE_THREE_COLORS[lineKeys.indexOf(item)]}
              strokeWidth={1.5}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
            />
          ),
        )}
      </>
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export default ChartComponent
