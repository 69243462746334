import { keyBy } from '../../../utils/Common'
const MAX_QUARTER = 4

const separateDeepestData = (data, result = {}) => {
  Object.values(data).forEach((e) => {
    if (Object.values(e.children).length) {
      separateDeepestData(e.children, result)
    } else {
      result[e.parentIndicatorId] = {
        ...result[e.parentIndicatorId],
        [e.indicatorId]: e,
      }
      delete data[e.indicatorId]
    }
  })

  return result
}

export const handleTreeIndicators = (indicators) => {
  const data = keyBy(
    indicators.map((e) => ({ ...e, children: {} })),
    'indicatorId',
  )

  indicators
    .slice()
    .reverse()
    .forEach((e) => {
      if (data[e.parentIndicatorId]) {
        data[e.parentIndicatorId].children[e.indicatorId] = data[e.indicatorId]
        delete data[e.indicatorId]
      }
    })

  const deepestIndicators = separateDeepestData(data)

  return { data, deepestIndicators }
}

export const formatDateByPeriodType = (dateTime, periodType) => {
  if (periodType === 'Quarterly') {
    return {
      quarter: dateTime.quarter === 5 ? MAX_QUARTER : dateTime.quarter,
      year: dateTime.year,
    }
  }

  return periodType === 'Yearly' ? dateTime.year : dateTime.date
}
