const indices = {
  NAME: 'TÊN DANH MỤC',
  DESCRIPTION: 'MÔ TẢ',
  DATE: 'NGÀY THAY ĐỔI',
  FAVORITE: 'YÊU THÍCH',
  REFRESH: 'LÀM MỚI',
  MORE: 'Khác',
}

const btnMore = {
  BTN_DUPLICATE: 'Sao chép',
  BTN_RENAME: 'Đổi tên',
  BTN_EDIT: 'Đổi mô tả',
  BTN_DELETE: 'Xóa',
}

const validate = {
  NAME_MAX: 'Tên danh mục quá 100 kí tự.',
  NAME_DUPLICATE: 'Tên danh mục đã tồn tại.',
}

const watchlistTable = {
  indices,
  btnMore,
  validate,
  BTN_CREATE: 'Tạo',
  BTN_DUPLICATE: 'Sao chép',
  BTN_DELETE: 'Xóa',
  EXCEEDED_WL:
    'Đã tạo đủ số lượng danh mục cho phép. Không thể tạo thêm danh mục mới.',
  TITLE_DELETE_WL: 'Xóa danh mục',
  ASK_DELETE: 'Bạn có muốn xóa',
  ITEMS: 'danh mục',
  BTN_CANCEL: 'Hủy bỏ',
  BTN_DELETE_2: 'Xóa',
}

export default watchlistTable
