import { FORMAT, formatDateTimeLocal } from '../../../utils/Datetime'

export const getDateListFrom = ({ from, dateCount, isGetNext = true }) => {
  let result = []

  let count = 0
  while (result.length < dateCount) {
    const dt = new Date(from)
    dt.setDate(dt.getDate() + (isGetNext ? count : -count))
    count++
    if (![6, 0].includes(dt.getDay())) {
      const date = formatDateTimeLocal(new Date(dt), FORMAT.DATE)
      isGetNext ? result.push(date) : result.unshift(date)
    }
  }

  return result
}

export const getCurrentDate = (data, dates) => {
  return dates.reverse().find((date) => data.some((item) => item.date === date))
}
