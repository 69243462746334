import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { TABLE_FILTER } from '../exportImportTable/constants'
import {
  removeCategoryFromList,
  selectImportExportDataType,
  selectListCategory,
  selectLoadingTable,
  selectTypeFilter,
} from '../exportImportTable/store/slice'
import { ChartComponent } from './ChartComponent'
import {
  COLORS_LINE_CHART,
  TURN_OVER_MONTHLY_DATE_FILTER,
  TURN_OVER_YEARLY_DATE_FILTER,
} from './constants'
import {
  changeActiveCategory,
  handleCurrentDateType,
  handleCurrentDateTypeYearly,
  selectCurrentDateType,
  selectCurrentDateTypeYearly,
  selectDataChart,
  selectDisableLoading,
  selectLoading,
  selectMaxYear,
} from './store/slice'
import {
  getExportChangeTurnoverByProduct,
  getImportChangeTurnoverByProduct,
  getMaxDateDetail,
} from './store/thunk'

export const ExportTurnOver = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const headerRef = useRef()
  const legendPanel = useRef()

  // Use selector
  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const isLoadingTable = useSelector(selectLoadingTable)
  const listCategory = useSelector(selectListCategory)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const importExportDataType = useSelector(selectImportExportDataType)
  const currentDateType = useSelector(selectCurrentDateType)
  const currentDateTypeYearly = useSelector(selectCurrentDateTypeYearly)
  const maxDate = useSelector(selectMaxYear)
  const disableLoading = useSelector(selectDisableLoading)

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListCategory, setHeightListCategory] = useState(0)

  const isYearly = currentTypeFilter === TABLE_FILTER[0].value
  const getIdsInListCategory = listCategory?.map((item) => item.id)

  // Actions
  const onChangeTab = (item) => {
    if (isYearly) {
      dispatch(handleCurrentDateTypeYearly(item.value))
    } else {
      dispatch(handleCurrentDateType(item.value))
    }
  }

  const handleDelete = (item) => {
    dispatch(removeCategoryFromList(item.id))
    dispatch(changeActiveCategory(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveCategory(item.vnTypeId))
  }

  const handleUnHover = () => {
    dispatch(changeActiveCategory(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListCategory)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListCategory,
      )
  }, [height, width, listCategory, heightListCategory])

  useEffect(() => {
    dispatch(
      getMaxDateDetail({
        ExImConstant: importExportDataType,
      }),
    )
  }, [importExportDataType])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      getIdsInListCategory.length &&
      maxDate &&
      !disableLoading
    ) {
      dispatch(
        getImportChangeTurnoverByProduct({
          DataType: currentTypeFilter,
          TimeRange: isYearly ? currentDateTypeYearly : currentDateType,
          VNTypeIds: getIdsInListCategory.toString(),
          Year: maxDate?.realYear,
        }),
      )
    }
  }, [listCategory, currentDateType, currentDateTypeYearly, maxDate])

  useEffect(() => {
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      getIdsInListCategory.length &&
      maxDate &&
      !disableLoading
    ) {
      dispatch(
        getExportChangeTurnoverByProduct({
          DataType: currentTypeFilter,
          TimeRange: isYearly ? currentDateTypeYearly : currentDateType,
          VNTypeIds: getIdsInListCategory.toString(),
          Year: maxDate?.realYear,
        }),
      )
    }
  }, [listCategory, currentDateType, currentDateTypeYearly, maxDate])

  return width && height && (isLoading || isLoadingTable) ? (
    <Loading />
  ) : (
    <>
      <div className="h-100">
        <div className="d-flex justify-content-end" ref={headerRef}>
          <DispatchActionTab
            data={
              isYearly
                ? TURN_OVER_YEARLY_DATE_FILTER
                : TURN_OVER_MONTHLY_DATE_FILTER
            }
            activeTab={isYearly ? currentDateTypeYearly : currentDateType}
            onChangeTab={onChangeTab}
          />
        </div>
        {!dataChart.length ? (
          <NoData />
        ) : (
          <div id="exportTurnOverChart">
            <ChartComponent
              data={dataChart}
              width={width}
              height={heightSizeTracker}
              keyXAxis={'time'}
              lineKeys={getIdsInListCategory}
            />
            <FooterWithScroll
              width={width}
              data={listCategory}
              idKey="id"
              nameKey="name"
              color={(item) => COLORS_LINE_CHART[listCategory.indexOf(item)]}
              legendPanel={legendPanel}
              isFullScreen={isFullScreen}
              setHeightFooter={setHeightListCategory}
              onDelete={handleDelete}
              onHover={handleHover}
              onUnHover={handleUnHover}
            />
          </div>
        )}
      </div>
    </>
  )
}
