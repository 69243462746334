import { useDispatch, useSelector } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from '../index.module.css'
import {
  getDataByTickerId,
  getDisplayById,
  handleTickById,
} from './store/slice'

const TickerItem = ({ tickerId }) => {
  const dispatch = useDispatch()

  const dataByTickerId = useSelector(getDataByTickerId(tickerId))
  const display = useSelector(getDisplayById(tickerId))

  const handleCheckbox = () => {
    dispatch(handleTickById(tickerId))
  }

  return display.isDisplay ? (
    <div className={style.tickerItem} onClick={handleCheckbox}>
      <div style={{ width: '15%' }}>
        <TextEllipsis isI18n={false} text={dataByTickerId.ticker} />
      </div>
      <div style={{ width: '75%' }}>
        <TextEllipsis
          isI18n={false}
          text={dataByTickerId.organizationShortName}
          appendStyle={{ opacity: 0.4 }}
        />
      </div>
      <div className={style.checkboxTicker}>
        <input
          type="checkbox"
          checked={display.isChecked}
          className={`checkbox`}
          style={{ cursor: 'pointer', minWidth: 12 }}
          readOnly
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TickerItem
