import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../utils/Common'
import { register } from '../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../utils/Value'
import { SEC_GROUP_INDICATOR } from '../common/constants'
import {
  formatDashboardNoOrder,
  formatMenuData,
} from '../common/menuDashboard/helpers'
import { NAME_POPUP } from '../popup/constants'
import {
  downloadDataExport,
  getChartByDashboardId,
  getCharts,
  getChartUserSettings,
  getDashboard,
  getIndicators,
  getListIndicatorLatestPeriod,
  getMyLibraryData,
  postDashboard,
  updateAndReloadDashboard,
} from './thunk'

const initialState = {
  chartOfTheDay: {},
  dashboardTemplate: {},
  myDashboard: {},
  myChartLibrary: {},
  dashboard: {},
  linkChart: {
    linkedSecurity: '',
    addedChart: [],
    enableLinkChart: true,
    minimizeButton: true,
  },
  isPopup: {
    [NAME_POPUP.DUPLICATE_DB]: false,
    [NAME_POPUP.DELETE_DB]: false,
    [NAME_POPUP.COPY_CHART_TO_NEW_DB]: false,
    [NAME_POPUP.WARNING_SAVE]: false,
  },
  dashboardSelected: undefined,
  dashboardLoading: true,
  dashboardChartData: [],
  dashboardChartLoading: true,
  isNewDashboard: false,
  indicatorsBySecurities: {
    [SEC_GROUP_INDICATOR.COMPANY]: [],
    [SEC_GROUP_INDICATOR.INDEX]: [],
    [SEC_GROUP_INDICATOR.SECTOR]: [],
    [SEC_GROUP_INDICATOR.ECONOMY]: [],
  },
  indicators: [],
  indicatorsLatestPeriod: {},
  isLoadingIndicators: true,
  isLoadingIndicatorsLatestPeriod: true,
  listDashboard: [],
  isLoadingChartByDashboardId: true,
  chartByDashboardId: {},
  isLoadingCreateDashboard: false,
  chartsSelected: [],
  chartLibrary: [],
  isLoadingChartLibrary: true,
  editDashboardLoading: false,
  listChartDelete: [],
  userSettings: {},
  isLoadingUserSettings: false,
  isFirstLoadDashboard: false,
  isLoadingDownloadExcel: false,
}

export const slice = createSlice({
  name: 'financialChart',
  initialState,
  reducers: {
    changeShowPopup: (state, action) => {
      state.isPopup[action.payload.keyUpdate] = action.payload.value
    },
    changeDashboardSelected: (state, action) => {
      state.dashboardSelected = action.payload
      state.dashboardChartData = []
      state.dashboardChartLoading = true
    },
    changeIsNewDashboard: (state, action) => {
      state.isNewDashboard = action.payload
    },
    changeDashboard: (state, action) => {
      state.dashboard = {
        ...state.dashboard,
        ...action.payload,
      }
    },
    deleteChart: (state, action) => {
      state.dashboardChartData = [...state.dashboardChartData].filter(
        (item) => item.id !== action.payload,
      )
      if (typeof action.payload === 'number') {
        state.listChartDelete = [...state.listChartDelete, action.payload]
      }
    },
    updateChart: (state, action) => {
      if (action.payload.id) {
        const foundIndex = state.dashboardChartData.findIndex(
          (i) => i.id === action.payload.id,
        )
        state.dashboardChartData[foundIndex].detail = action.payload.value
        if (action.payload.order) {
          state.dashboardChartData[foundIndex].order = action.payload.order
        }
        if (action.payload.name) {
          state.dashboardChartData[foundIndex].name = action.payload.name
        }
      }
    },
    addChart: (state, action) => {
      state.dashboardChartData = [...state.dashboardChartData, action.payload]
    },
    replaceChart: (state, action) => {
      const { itemReplace, valueReplace } = action.payload
      state.dashboardChartData = [...state.dashboardChartData].map((item) =>
        item.id === itemReplace.id ? valueReplace : item,
      )
    },

    changePositionChart: (state, action) => {
      state.dashboardChartData = action.payload
    },
    changeIndicatorsLatestPeriodLoading: (state, action) => {
      state.isLoadingIndicatorsLatestPeriod = action.payload
    },
    enableLinkChart: (state, action) => {
      state.linkChart.enableLinkChart = action.payload
    },
    addLinkChart: (state, action) => {
      state.linkChart.addedChart = Array.from(
        new Set([...state.linkChart.addedChart, action.payload]),
      )
    },
    removeLinkChart: (state, action) => {
      state.linkChart.addedChart = state.linkChart.addedChart.filter(
        (e) => e !== action.payload,
      )
      const index = state.dashboardChartData.findIndex(
        (item) => item.id === action.payload,
      )
      state.dashboardChartData[index].linked = false
    },
    linkedSecurity: (state, action) => {
      state.linkChart.linkedSecurity = action.payload
    },
    minimizeButtonLinkChart: (state, action) => {
      state.linkChart.minimizeButton = action.payload
    },
    changeChartsSelected: (state, action) => {
      state.chartsSelected = action.payload
    },
    changeUserSettings: (state, action) => {
      state.userSettings = action.payload
    },
    changeIsFirstLoadDashboard: (state, action) => {
      state.isFirstLoadDashboard = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDashboard.pending, (state) => {
      state.dashboardLoading = true
    })
    builder.addCase(getDashboard.fulfilled, (state, action) => {
      const [chartOfTheDay, dashboardTemplate, myDashboard, myChartLibrary] =
        formatMenuData(action.payload)
      state.chartOfTheDay = chartOfTheDay
      state.dashboardTemplate = dashboardTemplate
      state.myChartLibrary = myChartLibrary
      state.myDashboard = {
        ...myDashboard,
        children: [
          ...myDashboard.children,
          ...formatDashboardNoOrder(action.payload),
        ],
      }
      state.listDashboard = [
        ...myDashboard.children.map((item) => item.children).flat(),
        ...formatDashboardNoOrder(action.payload),
      ]

      state.dashboardLoading = false
    })
    builder.addCase(getDashboard.rejected, (state) => {
      state.dashboardLoading = false
    })

    builder.addCase(getCharts.pending, (state) => {
      state.dashboardChartLoading = true
    })
    builder.addCase(getCharts.fulfilled, (state, action) => {
      state.dashboardChartData = action.payload
      state.dashboardChartLoading = false
    })
    builder.addCase(getCharts.rejected, (state) => {
      state.dashboardChartLoading = false
    })
    //indicators
    builder.addCase(getIndicators.pending, (state) => {
      state.isLoadingIndicators = true
    })
    builder.addCase(getIndicators.fulfilled, (state, action) => {
      state.indicators = action.payload
      const indicatorsBySecurities = {
        [SEC_GROUP_INDICATOR.COMPANY]: [],
        [SEC_GROUP_INDICATOR.INDEX]: [],
        [SEC_GROUP_INDICATOR.SECTOR]: [],
        [SEC_GROUP_INDICATOR.ECONOMY]: [],
      }
      action.payload.forEach((i) => {
        i.secGroup === SEC_GROUP_INDICATOR.COMPANY
          ? indicatorsBySecurities[SEC_GROUP_INDICATOR.COMPANY].push(i)
          : i.secGroup === SEC_GROUP_INDICATOR.SECTOR
          ? indicatorsBySecurities[SEC_GROUP_INDICATOR.SECTOR].push(i)
          : i.secGroup === SEC_GROUP_INDICATOR.ECONOMY
          ? indicatorsBySecurities[SEC_GROUP_INDICATOR.ECONOMY].push(i)
          : indicatorsBySecurities[SEC_GROUP_INDICATOR.INDEX].push(i)
      })
      state.securitiesEconomyById = keyBy(
        indicatorsBySecurities[SEC_GROUP_INDICATOR.ECONOMY],
        'id',
      )
      state.securitiesEconomyChildren = indicatorsBySecurities[
        SEC_GROUP_INDICATOR.ECONOMY
      ].filter((item) => item.parentId)
      state.indicatorsBySecurities = indicatorsBySecurities

      state.isLoadingIndicators = false
    })
    builder.addCase(getIndicators.rejected, (state) => {
      state.isLoadingIndicators = false
    })
    // Get indicators latest period
    builder.addCase(getListIndicatorLatestPeriod.fulfilled, (state, action) => {
      state.indicatorsLatestPeriod = {
        ...state.indicatorsLatestPeriod,
        ...action.payload,
      }
    })
    // Get chart by dashboard id
    builder.addCase(getChartByDashboardId.pending, (state) => {
      state.isLoadingChartByDashboardId = true
    })
    builder.addCase(getChartByDashboardId.fulfilled, (state, action) => {
      state.chartByDashboardId = action.payload
      state.isLoadingChartByDashboardId = false
    })
    builder.addCase(getChartByDashboardId.rejected, (state) => {
      state.isLoadingChartByDashboardId = false
    })
    // Post dashboard
    builder.addCase(postDashboard.pending, (state) => {
      state.isLoadingCreateDashboard = true
    })
    builder.addCase(postDashboard.fulfilled, (state, action) => {
      state.isLoadingCreateDashboard = false
    })
    builder.addCase(postDashboard.rejected, (state) => {
      state.isLoadingCreateDashboard = false
    })
    // Get chart library
    builder.addCase(getMyLibraryData.pending, (state) => {
      state.isLoadingChartLibrary = true
    })
    builder.addCase(getMyLibraryData.fulfilled, (state, action) => {
      state.chartLibrary = action.payload
      state.isLoadingChartLibrary = false
    })
    builder.addCase(getMyLibraryData.rejected, (state) => {
      state.isLoadingChartLibrary = false
    })
    // Update dashboard
    builder.addCase(updateAndReloadDashboard.pending, (state) => {
      state.editDashboardLoading = true
    })
    builder.addCase(updateAndReloadDashboard.fulfilled, (state) => {
      state.editDashboardLoading = false
    })
    builder.addCase(updateAndReloadDashboard.rejected, (state) => {
      state.editDashboardLoading = false
    })
    // Get user settings
    builder.addCase(getChartUserSettings.pending, (state) => {
      state.isLoadingUserSettings = true
    })
    builder.addCase(getChartUserSettings.fulfilled, (state, action) => {
      if (action.payload) {
        try {
          const obj = JSON.parse(action.payload)
          if (typeof obj === 'object' && !Array.isArray(obj)) {
            state.userSettings = obj
          }
        } catch (error) {}
      }
      state.isLoadingUserSettings = false
    })
    builder.addCase(getChartUserSettings.rejected, (state) => {
      state.isLoadingUserSettings = false
    })
    // Download excel
    builder.addCase(downloadDataExport.pending, (state) => {
      state.isLoadingDownloadExcel = true
    })
    builder.addCase(downloadDataExport.fulfilled, (state) => {
      state.isLoadingDownloadExcel = false
    })
    builder.addCase(downloadDataExport.rejected, (state) => {
      state.isLoadingDownloadExcel = false
    })
  },
})

export const selectChartOfTheDay = (state) => state[slice.name].chartOfTheDay
export const selectDashboardTemplate = (state) =>
  state[slice.name].dashboardTemplate
export const selectMyDashboard = (state) => state[slice.name].myDashboard
export const selectMyChartLibrary = (state) => state[slice.name].myChartLibrary
export const selectDashboard = (state) => state[slice.name].dashboard
export const selectScreenType = (state) =>
  state[slice.name].dashboard.screenType
export const selectIsShowPopup = (state) => state[slice.name].isPopup
export const selectDashboardSelected = (state) =>
  state[slice.name].dashboardSelected
export const selectDashboardLoading = (state) =>
  state[slice.name].dashboardLoading
export const selectDashboardChartData = (state) =>
  state[slice.name].dashboardChartData
export const selectDashboardChartLoading = (state) =>
  state[slice.name].dashboardChartLoading
export const selectIsNewDashboard = (state) => state[slice.name].isNewDashboard
export const selectIndicatorBySecurities = (state) =>
  state[slice.name].indicatorsBySecurities
export const selectIndicatorByTypeSecurities = (key) => (state) =>
  valByKeyWithDot(state[slice.name].indicatorsBySecurities, key)
export const selectIndicators = (state) => state[slice.name].indicators
export const selectLoadingIndicators = (state) =>
  state[slice.name].isLoadingIndicators
export const selectIndicatorsLatestPeriod = (state) =>
  state[slice.name].indicatorsLatestPeriod
export const selectIndicatorLatestPeriodLoading = (state) =>
  state[slice.name].isLoadingIndicatorsLatestPeriod
export const selectEnableLinkChart = (state) =>
  state[slice.name].linkChart.enableLinkChart
export const selectAddedLinkChart = (state) =>
  state[slice.name].linkChart.addedChart
export const selectLinkedSecurity = (state) =>
  state[slice.name].linkChart.linkedSecurity
export const selectListDashboard = (state) => state[slice.name].listDashboard
export const selectMinimizeButtonLinkChart = (state) =>
  state[slice.name].linkChart.minimizeButton
export const selectChartByDashboardId = (state) =>
  state[slice.name].chartByDashboardId
export const selectIsLoadingCreateDashboard = (state) =>
  state[slice.name].isLoadingCreateDashboard
export const selectChartsSelected = (state) => state[slice.name].chartsSelected
export const selectChartLibrary = (state) => state[slice.name].chartLibrary
export const selectLoadingChartLibrary = (state) =>
  state[slice.name].isLoadingChartLibrary
export const selectEditDashboardLoading = (state) =>
  state[slice.name].editDashboardLoading
export const selectListChartDelete = (state) =>
  state[slice.name].listChartDelete
export const selectUserSettings = (state) => state[slice.name].userSettings
export const selectUserSettingsLoading = (state) =>
  state[slice.name].isLoadingUserSettings
export const selectIsFirstLoadDashboard = (state) =>
  state[slice.name].isFirstLoadDashboard
export const selectIsLoadingDownloadExcel = (state) =>
  state[slice.name].isLoadingDownloadExcel

export const {
  changeShowPopup,
  changeDashboardSelected,
  changeIsNewDashboard,
  changeDashboard,
  deleteChart,
  updateChart,
  addChart,
  changePositionChart,
  changeIndicatorsLatestPeriodLoading,
  enableLinkChart,
  addLinkChart,
  removeLinkChart,
  linkedSecurity,
  minimizeButtonLinkChart,
  changeChartsSelected,
  changeUserSettings,
  changeIsFirstLoadDashboard,
  replaceChart,
} = slice.actions

register(slice.name, slice.reducer)
