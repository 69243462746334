import { useSelector } from 'react-redux'
import { MARGIN } from '../../../../../common/chart/constants'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../../common/loading'
import { ChartContainer } from '../../common/ChartContainer'
import { selectViewType } from '../store/slice'
import { getChartDataFormat, getDataTitle } from './helper'
import { selectChartData, selectChartLoading } from './store/slice'

const PERCENT_FORMAT = '(%)'

export const ChartComponent = ({ chartOption, width, height, margin }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()

  // Use selector
  const data = useSelector(selectChartData)
  const loading = useSelector(selectChartLoading)
  const viewType = useSelector(selectViewType)

  // Get data
  const getData = () => {
    return data.map((item) => ({
      ...item,
      label: getDataTitle(viewType, item, locale, timezone),
    }))
  }

  return (
    <div style={{ height: height }} className="position-relative">
      {chartOption && chartOption.component ? (
        <ChartContainer
          id={chartOption.thunkFuncName}
          data={getData() || []}
          height={height}
          width={width}
          topLabel={chartOption.chartName}
          isLoading={typeof loading === 'undefined' || loading}
        >
          {(resizeSize) => {
            const Component = chartOption.component
            const size = chartOption.size(resizeSize)
            const props = {
              data: getChartDataFormat(getData(), chartOption),
              ...size,
              ...chartOption.props,
              margin: { ...MARGIN, ...margin },
              leftLabel:
                chartOption.leftLabel === PERCENT_FORMAT
                  ? ''
                  : chartOption.leftLabel,
              rightLabel:
                chartOption.rightLabel === PERCENT_FORMAT
                  ? ''
                  : chartOption.rightLabel,
              isYAxisPercentLeft: chartOption.leftLabel === PERCENT_FORMAT,
              isYAxisPercentRight: chartOption.rightLabel === PERCENT_FORMAT,
            }
            return <Component {...props} />
          }}
        </ChartContainer>
      ) : (
        <Loading />
      )}
    </div>
  )
}
