import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import style from '../index.module.css'
import { selectListCategory } from './store/slice'

export const TdSelect = ({
  val,
  rowId,
  onRowCollapse,
  data,
  isRowCollapse,
  width,
}) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const listCategory = useSelector(selectListCategory)

  const [isCollapse, setIsCollapse] = useState(false)

  const categoryDataTableItem = data?.find((item) => item.id === rowId)

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listCategory?.some((category) => category.id === rowId)
  }

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <div
      className="d-flex align-center"
      style={{ gap: GAP_ITEM, width: width }}
    >
      <div>
        {categoryDataTableItem?.isCollapse ? (
          <input
            type="checkbox"
            className={`checkbox ${style.checkbox}`}
            checked={handleChecked()}
            onChange={handleChangeListCategory}
          />
        ) : (
          <div style={{ width: '30px' }}></div>
        )}
      </div>
      <div
        className="d-flex"
        style={{
          marginLeft: !categoryDataTableItem?.isCollapse ? MARGIN_LEFT : 0,
        }}
      >
        {categoryDataTableItem?.isCollapse ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <></>
        )}
        <div className={categoryDataTableItem?.isCollapse ? style.pointer : ''}>
          <TextEllipsis
            isI18n={false}
            text={val}
            appendStyle={{ width: 270 }}
          />
        </div>
      </div>
    </div>
  )
}
