import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
// import { NoData } from '../../../../../common/noData'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../../common/popup/PopupAlert'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'
import {
  updateBondIds,
  updateStatusGetDefaultId,
} from '../../../../valuation/infoSec/store/slice'
import { selectBasicInfo } from '../Filter/store/slice'
import style from './index.module.css'
import PopupHistory from './popupHistory'
import {
  selectDataBondDetail,
  selectLoading,
  selectPopupAlert,
  updatePopupAlert,
} from './store/slice.js'
import { getDataThunk, getDataWatchList } from './store/thunk'

const Content = ({ width }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const bondInfo = useSelector(selectBasicInfo)
  const loading = useSelector(selectLoading)
  const data = useSelector(selectDataBondDetail)
  const locale = useSelector((state) => state.i18n.locale)
  const [showPopup, setshowPopup] = useState(false)
  const popup = useSelector(selectPopupAlert)

  useEffect(() => {
    dispatch(getDataWatchList(locale))
  }, [locale])

  const getData = () => {
    dispatch(
      getDataThunk({
        BondId: bondInfo?.bondId,
      }),
    )
  }
  useEffect(() => {
    if (bondInfo?.bondId) {
      getData()
    }
  }, [bondInfo?.bondId, locale])

  if (loading) {
    return <Loading />
  }

  const onChangPopup = (value) => {
    setshowPopup(value)
  }
  // if (Object.keys(data).length === 0) {
  //   return <NoData />
  // }

  const changeRouter = () => {
    dispatch(updateBondIds(data.bondId))
    dispatch(updateStatusGetDefaultId(true))
    navigate('/bond/valuation')
  }

  const closePopupAlert = () => {
    dispatch(
      updatePopupAlert({
        show: false,
        message: '',
      }),
    )
  }

  const clickRouter = () => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: data.organizationId,
    })
  }

  return (
    <>
      <div className={style.wrapContent}>
        <div className={style.column}>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.BondIsinCode'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.OrganizationShortName'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.IssueDateId'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.MaturityDateId'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.ParValue'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.DurationId'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.remainingDuration'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.CouponTypeId'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.CouponValue'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.PaymentCalendarId'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.IsCollateral'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.IsPaymentGuarantee'}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 600 }}>
              <TextEllipsis
                isI18n={true}
                text={'bond.bondSecurity.generalSetting.report'}
              />
            </Span>
          </div>
        </div>

        <div className={style.column}>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.bondIsinCode || ''} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            {/* <Link to={'/bond/valuation'} style={{ textDecoration: 'none' }}> */}
            <Span
              style={{ fontSize: 12, fontWeight: 340 }}
              className={style.link}
              onClick={() => clickRouter()}
            >
              <TextEllipsis isI18n={false} text={data.organizationShortName} />
            </Span>
            {/* </Link> */}
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={
                  data.issueDateId
                    ? formatDateTime(data.issueDateId, FORMAT.DATE, locale)
                    : ''
                }
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={
                  data.maturityDateId
                    ? formatDateTime(data.maturityDateId, FORMAT.DATE, locale)
                    : ''
                }
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={formatVal(data.parValue, 0)} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data.durationName} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={formatVal(data.remainingDuration)}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.couponTypeName || ''} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={formatVal(data.couponInterestRate * 100)}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={`${data.paymentCalendarMonth} ${I18n.t(
                  'common.calendar.MONTH',
                )}`}
              />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.guarantee || ''} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.formOfGuarantee || ''} />
            </Span>
          </div>
          <div style={{ width: width / 3 }} className={style.row}>
            {data?.sourceUrl ? (
              <a
                className={style.link}
                onClick={() => {
                  window.open(data?.sourceUrl, '_blank')
                }}
              >
                <Translate value="bond.bondSecurity.generalSetting.view" />
              </a>
            ) : (
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <TextEllipsis text="bond.bondSecurity.generalSetting.updating" />
              </Span>
            )}
          </div>
        </div>

        <div className={style.groupBTn} style={{ width: width / 3 }}>
          <Button
            className={`btn btn-blue h-20`}
            style={{ width: '30px' }}
            onClick={() => setshowPopup(true)}
          >
            <Span style={{ fixedFontSize: '16px', fontWeight: '600' }}>+</Span>
          </Button>
          <Button
            className={`btn btn-blue w-80 h-20 `}
            onClick={() => changeRouter()}
          >
            <TextEllipsis
              isI18n={true}
              text={'bond.bondSecurity.generalSetting.btnPricing'}
            />
          </Button>
        </div>
      </div>
      {showPopup && <PopupHistory onChangPopup={onChangPopup} />}
      <PopupAlert
        message={popup?.message || ''}
        isShow={popup?.show || false}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => closePopupAlert()}
      />
    </>
  )
}

export default Content
