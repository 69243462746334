import MasterDataProxy from '../../../proxies/bond/overview/MasterDataProxy'
import { ServiceBase } from '../../ServiceBase'

export class MasterData extends ServiceBase {
  getICBsApi(params) {
    return this.getData(() => MasterDataProxy.getICBsProxy(params))
  }
}

export default new MasterData()
