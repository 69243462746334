import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import style from '../index.module.css'
import { selectLocale } from '../store/slice'
import { getTypeInterimThunk } from '../store/thunk'
import useGetFinancialStatementIndicators from '../useGetFinancialStatementIndicators'
import useGetRatioIndicators from '../useGetRatioIndicators'
import Indicator from './Indicator'
import Template from './Template'

const MenuTab = () => {
  const dispatch = useDispatch()

  const { ratioIndicatorsTree, loadingRatio } = useGetRatioIndicators()
  const { financialStatementIndicatorsTree, loadingFinancialStatement } =
    useGetFinancialStatementIndicators()

  const [isIndicator, setIsIndicator] = useState(true)

  const locale = useSelector(selectLocale)

  useEffect(() => {
    dispatch(getTypeInterimThunk())
  }, [locale])

  const openIndicator = () => setIsIndicator(true)

  const openTemplate = () => setIsIndicator(false)

  return (
    <div className="nav-box-tab mt-0 h-100">
      <SizeTracker>
        {(size) => {
          return (
            <>
              <nav className="tab-in-panel mb-8">
                <a
                  onClick={openIndicator}
                  className={
                    isIndicator ? `active ${style.pointer}` : style.pointer
                  }
                >
                  <Span
                    style={{ fontSize: 11 }}
                    className={style.fontWeight600}
                  >
                    <Translate value="tool.smartScreening.INDICATOR" />
                  </Span>
                </a>
                <a
                  onClick={openTemplate}
                  className={
                    !isIndicator ? `active ${style.pointer}` : style.pointer
                  }
                >
                  <Span
                    style={{ fontSize: 11 }}
                    className={style.fontWeight600}
                  >
                    <Translate value="tool.smartScreening.TEMPLATE" />
                  </Span>
                </a>
              </nav>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {isIndicator ? (
                    <Indicator
                      ratioIndicatorsTree={ratioIndicatorsTree}
                      loadingRatio={loadingRatio}
                      financialStatementIndicatorsTree={
                        financialStatementIndicatorsTree
                      }
                      loadingFinancialStatement={loadingFinancialStatement}
                    />
                  ) : (
                    <Template
                      loadingRatio={loadingRatio}
                      loadingFinancialStatement={loadingFinancialStatement}
                    />
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default MenuTab
