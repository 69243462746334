import { I18n, Translate } from 'react-redux-i18n'
import {
  CONTAINER_CHART_TYPES,
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, isInValidValue } from '../../../../../utils/Value'

export const formatStockDataApi = (data) => {
  return data
    .map((item) => ({
      ...item,
      [DEFAULT_XAXIS_DATA_KEY]: item.ticker,
      [DEFAULT_PRE_VALUE_KEY + 1]: item.marketValue / 10 ** 9,
    }))
    .sort((a, b) => b.marketValue - a.marketValue)
    .slice(0, 10)
}

const getSchema = () => {
  return [
    {
      unit: 'common.UNIT.BILLION_VND',
      isI18n: true,
      color: '#40ABFF',
      footer: {
        before: {
          bgColor: '#40ABFF',
        },
      },
      tooltip: {
        renderTitle: () => (
          <Translate value="fund.fundProfile.portfolio.assetAllocation.HOLDING_VALUE" />
        ),
        formatValue: (value) => formatVal(value),
      },
    },
  ]
}

const getRenderTooltipValue = (data, schema) => (
  <>
    <div className="d-flex j-b">
      <div>
        <Span style={{ fontSize: 11, fontWeight: 400 }}>{data.ticker}</Span>
      </div>
      <div className="ml-8">
        <Span
          style={{
            fontSize: 11,
            fontWeight: 500,
          }}
        >
          {data.organizationName}
        </Span>
      </div>
    </div>
    {schema.map((item) =>
      !isInValidValue(data[item.dataKey]) && !isNaN(data[item.dataKey]) ? (
        <div key={item.dataKey} className="d-flex j-b">
          <div>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {item.tooltip?.renderTitle
                ? item.tooltip.renderTitle(data)
                : item.text}
              :
            </Span>
          </div>
          <div className="ml-8">
            {item.tooltip?.renderValue ? (
              item.tooltip.renderValue(data, item)
            ) : (
              <Span
                style={{
                  fontSize: 11,
                  fontWeight: 500,
                }}
              >
                {item.tooltip?.formatValue
                  ? item.tooltip.formatValue(data[item.dataKey])
                  : data[item.dataKey]}
              </Span>
            )}
            {item.unit && (
              <Span style={{ fontSize: 11, fontWeight: 500, marginLeft: 4 }}>
                {item.isI18n ? <Translate value={item.unit} /> : item.unit}
              </Span>
            )}
          </div>
        </div>
      ) : null,
    )}
  </>
)

export const getStockProps = () => {
  const schema = getSchema()
  return {
    schema,
    chartProps: {
      chartType: CONTAINER_CHART_TYPES.COMPOSED,
      yAxis: [
        {
          dataKeys: [DEFAULT_PRE_VALUE_KEY + 1],
          labelText: I18n.t('common.UNIT.BILLION_VND'),
          isBarChart: true,
        },
      ],
      isUseXAxisDiv: true,
      showAllTick: true,
    },
    footerProps: {
      isHide: true,
    },
    tooltipProps: {
      title: (data) => (
        <div className="t-italic d-flex justify-content-space-between ali-center">
          <Span style={{ fontSize: 10, marginRight: 16 }}>
            {formatDateTime(
              new Date(data.reportYear, data.reportMonth - 1).toString(),
              FORMAT.MONTH_YEAR_NUMBER,
            )}
          </Span>
        </div>
      ),
      render: getRenderTooltipValue,
    },
  }
}
