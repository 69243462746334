import css from 'dom-css'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFontSize } from '../../../../utils/FontSize'
import { ScrollComponent } from '../../../ScrollComponent'
import ScrollComponentWithNextBack from '../../../scrollComponentWithNextBack/ScrollComponentWithNextBack'
import { getTextWidth } from '../../helper'
import { chunkArray } from '../helper'
import Item from './Item'

const MARGIN_LEFT_RIGHT_CONTAINER = 24
const MARGIN_RIGHT_ITEM = 16
const BORDER_ITEM = 2
const DIV_COLOR_ITEM = 28
const DIV_CLOSE_ITEM = 21
const wrapperText =
  MARGIN_RIGHT_ITEM + BORDER_ITEM + DIV_COLOR_ITEM + DIV_CLOSE_ITEM

const FooterWithScroll = ({
  width,
  data,
  idKey,
  nameKey,
  formatName,
  color,
  legendPanel,
  isDashed,
  isFullScreen,
  setHeightFooter,
  onDelete,
  onHover,
  onUnHover,
}) => {
  const scrollbarsRef = useRef()
  const iconLeftScrollbarsRef = useRef()
  const iconRightScrollbarsRef = useRef()
  const fontSize12 = getFontSize(12)

  const locale = useSelector((state) => state.i18n.locale)

  const [listItemDisplay, setListItemDisplay] = useState({
    rowOne: [],
    rowTwo: [],
  })

  const setValueAfterUpdate = (values) => {
    const { scrollLeft, scrollWidth, clientWidth } = values
    iconLeftScrollbarsRef?.current &&
      css(iconLeftScrollbarsRef.current, { opacity: scrollLeft ? 1 : 0.4 })
    iconRightScrollbarsRef?.current &&
      css(iconRightScrollbarsRef.current, {
        opacity: scrollWidth - clientWidth !== scrollLeft ? 1 : 0.4,
      })
  }

  const calcWidthComponent = (listData) => {
    const itemsTextWidth = listData
      .map((item) => (formatName ? formatName(item) : item[nameKey]))
      .map((text) => getTextWidth(text, fontSize12))
    return itemsTextWidth?.length
      ? itemsTextWidth
          .map(
            (item) => Math.min(item, isFullScreen ? item : 100) + wrapperText,
          )
          .reduce((prev, cur) => prev + cur, MARGIN_LEFT_RIGHT_CONTAINER)
      : 0
  }

  useEffect(() => {
    if (data?.length) {
      const totalWidth = calcWidthComponent(data)
      if (width < totalWidth) {
        let newArray = chunkArray([...data], Math.ceil(data.length / 2))
        while (calcWidthComponent(newArray[0]) <= width) {
          const newRowOne = [...newArray[0]]
          const newRowTwo = [...newArray[1]]
          newRowOne.push(newRowTwo.shift())
          if (calcWidthComponent(newRowOne) <= width) {
            newArray = [newRowOne, newRowTwo]
          } else {
            break
          }
        }
        while (
          calcWidthComponent(newArray[0]) > width &&
          calcWidthComponent(newArray[1]) < width
        ) {
          const newRowOne = [...newArray[0]]
          const newRowTwo = [...newArray[1]]
          newRowTwo.unshift(newRowOne.pop())
          if (
            calcWidthComponent(newRowOne) <= width &&
            calcWidthComponent(newRowTwo) <= width
          ) {
            newArray = [newRowOne, newRowTwo]
          } else {
            break
          }
        }
        setListItemDisplay({
          rowOne: newArray[0],
          rowTwo: newArray[1],
        })
      } else {
        setListItemDisplay({
          rowOne: data,
          rowTwo: [],
        })
      }
    } else {
      setListItemDisplay({
        rowOne: [],
        rowTwo: [],
      })
    }
  }, [data, width, locale])

  useEffect(() => {
    setHeightFooter(legendPanel?.current?.offsetHeight)
  }, [legendPanel?.current, listItemDisplay])

  if (data.length)
    return (
      <div ref={legendPanel} style={{ margin: '0px 12px' }}>
        <ScrollComponentWithNextBack
          scrollbarsRef={scrollbarsRef}
          iconLeftScrollbarsRef={iconLeftScrollbarsRef}
          iconRightScrollbarsRef={iconRightScrollbarsRef}
        >
          <ScrollComponent
            autoHeight={true}
            autoHeightMax={80}
            scrollbarsRef={scrollbarsRef}
            setValueAfterUpdate={setValueAfterUpdate}
          >
            <div className="list-stocks d-flex justify-content-start">
              {listItemDisplay.rowOne.map((item) => {
                return (
                  <Item
                    key={item[idKey]}
                    item={item}
                    nameKey={nameKey}
                    formatName={formatName}
                    color={color}
                    appendStyle={{
                      marginBottom: listItemDisplay.rowTwo.length ? 8 : 0,
                    }}
                    showBtnDelete={data.length}
                    isDashed={isDashed}
                    isFullScreen={isFullScreen}
                    onDelete={onDelete}
                    onHover={onHover}
                    onUnHover={onUnHover}
                  />
                )
              })}
            </div>
            <div className="list-stocks d-flex justify-content-start">
              {listItemDisplay.rowTwo.map((item) => {
                return (
                  <Item
                    key={item[idKey]}
                    item={item}
                    nameKey={nameKey}
                    formatName={formatName}
                    color={color}
                    appendStyle={{
                      marginBottom: listItemDisplay.rowTwo.length ? 8 : 0,
                    }}
                    showBtnDelete={data.length}
                    isDashed={isDashed}
                    isFullScreen={isFullScreen}
                    onDelete={onDelete}
                    onHover={onHover}
                    onUnHover={onUnHover}
                  />
                )
              })}
            </div>
          </ScrollComponent>
        </ScrollComponentWithNextBack>
      </div>
    )
  return null
}

export default FooterWithScroll

FooterWithScroll.propTypes = {
  width: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  idKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
  formatName: PropTypes.func,
  color: PropTypes.func.isRequired,
  legendPanel: PropTypes.any.isRequired,
  setHeightFooter: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
  onUnHover: PropTypes.func.isRequired,
}
