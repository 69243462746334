import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import PanelFilter from '../../../../common/panelFilter'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelChart } from './chart/PanelChart'
import { Filter } from './filter'
import { PanelTable } from './table/PanelTable'

const MAP_KEY = {
  FILTER: 'FILTER',
  TABLE: 'TABLE',
  CHART: 'CHART',
}

const FILTER_WIDTH = 250
const MIN_WIDTH = 300

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: '100%',
    top: 0,
    left: 0,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    filterResize: [
      {
        key: MAP_KEY.TABLE,
        ratioWidth: 0,
        ratioLeft: -1,
      },
      {
        key: MAP_KEY.CHART,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TABLE}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.65)`,
    height: '100%',
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minLeft: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH * 3 * 0.65 - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    verticalResize: [
      {
        key: MAP_KEY.CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHART}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.35)`,
    height: '100%',
    top: 0,
    left: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.65 + ${
      FILTER_WIDTH + 4 * COMPONENT.MARGIN
    }px)`,
    minLeft: FILTER_WIDTH + MIN_WIDTH * 3 * 0.65 + 6 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH * 3 * 0.35,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelFilter
        title={'sector.financialAnalysis.corporate.FILTER'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>

      <PanelTable
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TABLE}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHART}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  dataType: PropTypes.string,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
