import moment from 'moment'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { FORMAT } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import { renderTimeType } from '../helper'
import style from '../index.module.css'
import {
  selectDataInterestAndPrincipalPaid,
  selectFilterInterestAndPrincipalPaid,
} from '../store/slice'

const ChartTooltip = ({ dataChart }) => {
  const { timeType } = useSelector(selectFilterInterestAndPrincipalPaid)
  const { data } = useSelector(selectDataInterestAndPrincipalPaid)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDate = useMemo(() => {
    if (timeType === TIME_FREQUENCY.MONTHLY) {
      const newDate = moment(
        dataChart.date,
        FORMAT.MONTH_YEAR_NUMBER[locale],
      ).format(FORMAT.MONTH[locale])
      return dataChart.date.includes(data[0].date)
        ? newDate + renderTimeType(timeType)
        : newDate
    }

    return dataChart.date
  }, [dataChart.date, locale])

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {formatDate}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.interestAndPrincipalPaid.PRINCIPAL_PAID" />
          :
        </Span>

        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.principalPaid)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.interestAndPrincipalPaid.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.interestAndPrincipalPaid.COUPON_PAID" />
          :
        </Span>

        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.couponPaid)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.interestAndPrincipalPaid.UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    date: PropTypes.string.isRequired,
    principalPaid: PropTypes.number.isRequired,
    couponPaid: PropTypes.number.isRequired,
  }).isRequired,
}

export default ChartTooltip
