import { useRef } from 'react'
import AddTicker from './AddTicker'
import DeleteAllTicker from './DeleteAllTicker'
import TableTab from './TableTab'
import ToggleChart from './ToggleChart'

const Header = ({ width }) => {
  const headerFirstComponentRef = useRef()
  const headerLastComponentRef = useRef()
  return (
    <div className="d-flex mb-8">
      <div className="d-flex" ref={headerFirstComponentRef}>
        <DeleteAllTicker />
        <AddTicker />
      </div>
      <TableTab
        width={
          width -
          headerFirstComponentRef.current?.getBoundingClientRect().width -
          headerLastComponentRef.current?.getBoundingClientRect().width
        }
      />
      <div ref={headerLastComponentRef}>
        <ToggleChart />
      </div>
    </div>
  )
}

export default Header
