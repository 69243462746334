import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { TextBlink } from '../../../common/table/TextBlink'
import { getThreeColorByValue, getTwoColorByValue } from '../../../utils/Color'
import styles from '../index.module.css'

const TdColor = ({
  value,
  colId,
  keyColor,
  selectCell,
  isLastPrice,
  tableRef,
  style,
  className,
  isTwoColor,
}) => {
  const colorValue = useSelector(selectCell(colId, keyColor))

  useEffect(() => {
    if (isLastPrice && Number(value) === 0) {
      const thisRow = tableRef.current?.querySelector(`#${colId}`)
      if (thisRow) {
        thisRow.classList.add(styles.displayNone)
      }
    }
  }, [value])

  return (
    <td
      style={{
        ...style,
        color: isTwoColor
          ? getTwoColorByValue(Number(colorValue))
          : getThreeColorByValue(Number(colorValue)),
      }}
      className={className}
    >
      <TextBlink value={Number(String(value).replace('%', ''))}>
        {value}
      </TextBlink>
    </td>
  )
}

TdColor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keyColor: PropTypes.string.isRequired,
  selectCell: PropTypes.func.isRequired,
  isLastPrice: PropTypes.bool,
  tableRef: PropTypes.object,
  isTwoColor: PropTypes.bool,
}

export default TdColor
