import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { SizeTracker } from '../../../../common/sizeTracker'
import { LIST_TYPE } from '../../../common/constants'
import {
  changeEquationSecurities,
  selectEquationLoading,
  selectEquationSecurities,
  selectTickersByExchangeIcbs,
} from '../store/slice'
import { CompanyItem } from './CompanyItem'
import { SelectExchangeSector } from './SelectExchangeSector'

const MAX_EQUATION_SECURITIES_ITEM = 3000

const CompanyList = ({ valueSearch, selectSectorRef, selectExchangeRef }) => {
  const dispatch = useDispatch()

  const tickersByExchangeIcbs = useSelector(selectTickersByExchangeIcbs)
  const equationSecurities = useSelector(selectEquationSecurities)
  const loading = useSelector(selectEquationLoading)

  const truncateArray = (array) => {
    return array
      .filter((item) =>
        valueSearch
          ? item.tickerOrTaxCode
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.organizationShortName
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase())
          : true,
      )
      .slice(0, MAX_EQUATION_SECURITIES_ITEM)
  }

  const isCheckAll = useMemo(() => {
    return !truncateArray(tickersByExchangeIcbs).some((item) =>
      equationSecurities.companies
        ? !equationSecurities.companies.includes(item.organizationId)
        : true,
    )
  }, [tickersByExchangeIcbs, equationSecurities])

  const handleCheckboxChange = (item) => {
    let newCompanies = equationSecurities.companies || []
    const orgId = +item.organizationId

    if (!newCompanies.find((val) => val === orgId)) {
      newCompanies = [...newCompanies, orgId]
    } else {
      newCompanies = newCompanies.filter((val) => val !== orgId)
    }

    dispatch(
      changeEquationSecurities({
        value: newCompanies,
        type: LIST_TYPE.COMPANIES,
      }),
    )
  }

  const handleCheckboxAll = () => {
    let newCompanies = []

    if (!isCheckAll) {
      newCompanies = truncateArray(tickersByExchangeIcbs).map(
        (item) => item.organizationId,
      )
    }

    dispatch(
      changeEquationSecurities({
        value: newCompanies,
        type: LIST_TYPE.COMPANIES,
      }),
    )
  }

  return (
    <div className="h-100">
      <SizeTracker>
        {(size) => (
          <>
            <SelectExchangeSector
              selectSectorRef={selectSectorRef}
              selectExchangeRef={selectExchangeRef}
            />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {loading ? (
                  <Loading isBlack />
                ) : (
                  <>
                    <ScrollComponent bgColor="#D9D9D9">
                      {truncateArray(tickersByExchangeIcbs).length ? (
                        <>
                          <CompanyItem
                            isItemAll
                            isCheckAll={isCheckAll}
                            onClick={handleCheckboxAll}
                          />
                          {truncateArray(tickersByExchangeIcbs).map(
                            (ticker, index) => (
                              <CompanyItem
                                key={index}
                                ticker={ticker}
                                equationSecurities={equationSecurities}
                                onClick={handleCheckboxChange}
                              />
                            ),
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </ScrollComponent>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}

CompanyList.propTypes = {}

export default CompanyList
