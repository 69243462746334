import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'

export const NAV_DATA_FUND_FLOW_TIME_RANGES = [
  {
    title: '3M',
    value: TIME_RANGES.ThreeMonths,
  },
  {
    title: '6M',
    value: TIME_RANGES.SixMonths,
  },
  {
    title: 'YTD',
    value: TIME_RANGES.YearToDate,
  },
  {
    title: '1Y',
    value: TIME_RANGES.OneYear,
  },
]
