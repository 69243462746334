import { useDispatch } from 'react-redux'
import { SEC_GROUP_INDICATOR } from '../../../common/constants'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { changeSecuritiesEconomyById } from '../../../popup/common/store/slice'
import { TIME_FREQUENCY } from '../../../popup/constants'
import { editIndicator } from '../../../popup/popupAddIndicator/store/slice'
import DailySecurities from './DailySecurities'
import MonthlySecurities from './MonthlySecurities'
import QuarterlySecurities from './QuarterlySecurities'
import WeeklySecurities from './WeeklySecurities'
import YearlySecurities from './YearlySecurities'

const TimeSecurities = ({
  typeIndicator,
  indicatorItem,
  indicatorLatestPeriod,
  isViewByLatestPeriod,
  onChange,
}) => {
  const dispatch = useDispatch()

  const handleChangeTime = (value) => {
    const objClone = { ...indicatorItem }
    objClone.dataLatestPeriod = value

    if (typeIndicator === SEC_GROUP_INDICATOR.ECONOMY) {
      dispatch(changeSecuritiesEconomyById(objClone))
    } else {
      dispatch(editIndicator({ typeIndicator: typeIndicator, value: objClone }))
    }

    onChange()
  }

  const renderTime = () => {
    const indicatorKey = indicatorItem
      ? `${indicatorItem.id}_${getTimeFrequencyByIndicator(indicatorItem)}`
      : ''
    const dataLatestPeriod =
      isViewByLatestPeriod || !indicatorItem
        ? indicatorLatestPeriod[indicatorKey]
        : indicatorItem?.dataLatestPeriod

    switch (indicatorItem?.timeFrequency) {
      case TIME_FREQUENCY.DAILY:
        return (
          <DailySecurities
            value={dataLatestPeriod}
            onChange={handleChangeTime}
          />
        )
      case TIME_FREQUENCY.WEEKLY:
        return (
          <WeeklySecurities
            value={dataLatestPeriod}
            onChange={handleChangeTime}
          />
        )
      case TIME_FREQUENCY.MONTHLY:
        return (
          <MonthlySecurities
            value={dataLatestPeriod}
            onChange={handleChangeTime}
          />
        )
      case TIME_FREQUENCY.QUARTERLY:
      case TIME_FREQUENCY.TTM:
        return (
          <QuarterlySecurities
            value={dataLatestPeriod}
            onChange={handleChangeTime}
          />
        )
      case TIME_FREQUENCY.YEARLY:
        return (
          <YearlySecurities
            value={dataLatestPeriod}
            onChange={handleChangeTime}
          />
        )
      default:
        return <DailySecurities disabled />
    }
  }

  return <div className="mt-8">{renderTime()}</div>
}

export default TimeSecurities
