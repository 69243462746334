import { valByKeyWithDot } from './Value'

export const getUnique = () => {
  return new Date().getTime()
}

export const keyBy = (array, key, formatKey) => {
  const result = {}

  array.forEach((item) => {
    result[
      formatKey
        ? formatKey(valByKeyWithDot(item, key))
        : valByKeyWithDot(item, key)
    ] = item
  })
  return result
}

export const invertKeyBy = (object) => Object.values(object)

export const keyByArray = (array, key, formatKey) => {
  const result = {}
  array.forEach((item) => {
    const keyObj = valByKeyWithDot(item, key)
    if (!result[keyObj]) {
      result[formatKey ? formatKey(keyObj) : keyObj] = [item]
    } else {
      result[formatKey ? formatKey(keyObj) : keyObj].push(item)
    }
  })
  return result
}

export const getNestedRoute = (route) => {
  const array =
    route && route.length > 0 && route[0] === '/'
      ? route.substr(1, route.length).split('/')
      : route.split('/')

  switch (array.length) {
    case 1:
      return {
        route: '/' + array[0],
        key: array[0],
      }
    case 2:
      return {
        route: '/' + array[0] + '/' + array[1],
        parentRoute: '/' + array[0],
        key: array[1],
        parentKey: array[0],
      }
    case 3:
      return {
        route: '/' + array[0] + '/' + array[1] + '/' + array[2],
        parentRoute: '/' + array[0] + '/' + array[1],
        grandParentRoute: '/' + array[0],
        key: array[2],
        parentKey: array[1],
        grandParentKey: array[0],
      }
    default:
      throw Error('[getNestedRoute] Pls set route correctly')
  }
}

export const debounce = (func, timeout = 300) => {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const throttle = (func, timeout = 300) => {
  let isRunFunc = true
  return (...args) => {
    if (isRunFunc) {
      isRunFunc = false
      func.apply(this, args)
    }
    setTimeout(() => {
      isRunFunc = true
    }, timeout)
  }
}

export const range = (start, end, isDesc = false) => {
  if (end < start || isNaN(end) || isNaN(start)) {
    return []
  }
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => (isDesc ? end - idx : start + idx))
}

export const joinWithoutEmpty = (list, delimiter = ',') => {
  return list.filter((item) => !!item).join(delimiter)
}

export const sumArray = (arr) => {
  return arr.reduce((pV, cV) => pV + cV, 0)
}

export const handleDuplicateArray = (arr) => {
  const seen = {}
  return arr.filter((item) => {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true)
  })
}

export const handleDuplicateObjectArray = (arr, keyId) => {
  const seen = {}
  return arr.filter((obj) => {
    return seen.hasOwnProperty(obj[keyId]) ? false : (seen[obj[keyId]] = true)
  })
}

export const formatNumber = (val) => {
  return typeof val === 'number' ? val : 0
}

export function uuid() {
  let index = 0
  let indexN = 0
  const time = new Date().getTime()
  const characters = 'abcdef0123456789'

  return 'xxxxxxxx-xxxx-Mxxx-Nxxx-xxxxxxxxxxxx'.replace(/[xMN]/g, (c) => {
    let char = 0

    if (c === 'M') {
      char = Math.ceil(Math.random() * 5)
    } else if (c === 'N') {
      const values = [8, 9, 'a', 'b']
      char = values[Math.floor(Math.random() * 4)]
      indexN = index
    } else if (indexN !== 0 && index > indexN + 2) {
      char = time.toString()[index - indexN - 3]
    } else {
      char = characters[Math.floor(Math.random() * characters.length)]
    }

    index++
    return char.toString(16)
  })
}

export const convertSortKeyToNumber = (keySort) => (keySort === 'asc' ? 0 : 1)
export const convertSortKeyToString = (keySort) =>
  keySort === 0 ? 'asc' : 'desc'

export const emptyInList = (list, keys) => {
  return keys.every((key) => {
    return list.filter((item) => !!item[key] || item[key] === 0).length === 0
  })
}

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value)
}

export const convertKeyNavigate = (
  data,
  keepSearchParams,
  searchParams,
  createSearchParams,
) => {
  const params = {}

  keepSearchParams.forEach((key) => {
    if (searchParams.has(key)) {
      params[key] = searchParams.get(key)
    }
  })
  if (data?.bondId) {
    params.bondId = data.bondId
  } else if (data?.coveredWarrantId) {
    params.coveredWarrantId = data.coveredWarrantId
  } else if (data?.groupId) {
    params.groupId = data.groupId
  } else if (data?.icbId && !data?.organizationId) {
    params.icbId = data.icbId
  } else if (data?.organizationId) {
    params.organizationId = data.organizationId
    if (data?.newsId) {
      params.newsId = data.newsId
    }
  }

  return Object.keys(params).length
    ? '?' + createSearchParams(params).toString()
    : ''
}
