import { Criteria } from './Criteria'
import { Exchange } from './Exchange'
import { ResetButton } from './ResetButton'
import { SizeBy } from './SizeBy'

export const Filter = () => {
  return (
    <div className="h-100 position-relative">
      <Exchange />
      <SizeBy />
      <Criteria />
      <ResetButton />
    </div>
  )
}
