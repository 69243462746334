import { sortBy } from 'lodash'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { BAR_COLOR_RELATIVE_TO_EQUITY } from '../constants'
import {
  keys,
  selectDataRelativeToEquity,
  selectFilterRelativeToEquity,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const RelativeToEquity = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.RELATIVE_TO_EQUITY))
  const { data, list } = useSelector(selectDataRelativeToEquity)
  const { timeType } = useSelector(selectFilterRelativeToEquity)

  const [listCheckbox, setListCheckbox] = useState([
    'outstandingBond',
    'issueValue',
    'valueOfCancelBond',
    'valueOfRedemption',
    'equity',
  ])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(() => {
    const newData = sortBy(data, (item) => {
      const dateArray = item.date.split('-')

      if (!dateArray.length || dateArray.length <= 1) {
        return item.date
      }

      const newDate = moment(dateArray[1]).quarter(
        parseInt(dateArray[0].slice(1)),
      )

      return newDate
    })

    return newData.map((item, id) => ({
      ...item,
      date:
        id === newData.length - 1
          ? `${item.date}${timeType === 'Yearly' ? `(YTD)` : `(QTD)`}`
          : item.date,
      outstandingBond: valDivBillion(item.outstandingBond),
      outstandingBond2: valDivBillion(item.outstandingBond),
      issueValue: valDivBillion(item.issueValue),
      issueValue2: valDivBillion(
        item.outstandingBond +
          item.issueValue -
          (item.valueOfCancelBond >= 0 ? item.valueOfCancelBond : 0) -
          item.valueOfRedemption,
      ),
      valueOfCancelBond: valDivBillion(item.valueOfCancelBond),
      valueOfRedemption: valDivBillion(item.valueOfRedemption),
      equity: item.equity,
    }))
  }, [data])

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  return (
    <Panel
      title="bond.corporateBond.relativeToEquity.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.relativeToEquity.TITLE')}
      downloadJpgParams={{
        domId: 'relativeToEquityChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: I18n.t('bond.corporateBond.relativeToEquity.TITLE'),
        tickerName: '',
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="relativeToEquityChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : !!formatDataBarChart.length ? (
                    <>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - size.height - 34}
                        keyXAxis="date"
                        isUseXAxisDiv
                        showAllTick
                        yAxis={[
                          {
                            id: 'left',
                            keys: listCheckbox.filter((e) => e !== 'equity'),
                            orientation: 'left',
                            isStackedBar: true,
                            label: I18n.t(
                              'bond.corporateBond.relativeToEquity.VALUE',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 5,
                            isBarChart: true,
                            // min: rangeValue.minValue,
                            // max: rangeValue.maxValue,
                          },
                          {
                            id: 'right',
                            keys: [list[4]],
                            orientation: 'right',
                            isStackedBar: true,
                            // label: I18n.t(
                            //   'bond.corporateBond.relativeToEquity.VALUE',
                            // ),
                            labelPosition: AXIS_LABEL_POSITION.RIGHT,
                            tickNum: 5,
                            // min: 100,
                            // max: 1,
                            // unitYAxis: '%',
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            {listCheckbox.includes(list[1]) && (
                              <Bar
                                stackId="stackId1"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[1]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[0]}
                                barSize={10}
                              />
                            )}
                            {listCheckbox.includes(list[2]) && (
                              <Bar
                                stackId="stackId2"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[2]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[1]}
                                barSize={10}
                              />
                            )}
                            {listCheckbox.includes(list[3]) && (
                              <Bar
                                stackId="stackId2"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[3]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[2]}
                                barSize={10}
                              />
                            )}
                            {listCheckbox.includes(list[0]) && (
                              <Bar
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[0]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[3]}
                                barSize={10}
                              />
                            )}
                            {listCheckbox.includes(list[4]) && (
                              <Scatter
                                yAxisId="right"
                                dataKey={list[4]}
                                isAnimationActive={false}
                                line
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[4]}
                                shape={
                                  'circle'
                                  // <ScatterDiamondShape
                                  //   dataKey={list[4]}
                                  //   color={BAR_COLOR_RELATIVE_TO_EQUITY[4]}
                                  // />
                                }
                              />
                            )}
                            {/* {listCheckbox2.includes(list[5]) && (
                              <Bar
                                stackId="stackId2"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[5]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[2]}
                                barSize={30}
                              />
                            )}
                            {listCheckbox2.includes(list[6]) && (
                              <Bar
                                stackId="stackId2"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[6]}
                                fill={BAR_COLOR_RELATIVE_TO_EQUITY[3]}
                                barSize={30}
                              />
                            )} */}
                          </>
                        )}
                      </ChartContainer>
                      <Footer
                        key={width}
                        list={[
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_ISSUE_BOND',
                            dataKey: list[1],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[0],
                            },
                          },
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_MATURITY_BOND',
                            dataKey: list[2],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[1],
                            },
                          },
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_REDEEMED_BOND',
                            dataKey: list[3],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[2],
                            },
                          },
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_OUTSTANDING_BOND',
                            dataKey: list[0],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[3],
                            },
                          },
                          {
                            text: 'bond.corporateBond.relativeToEquity.OUTSTANDING_BOND',
                            dataKey: list[4],
                            type: TYPE.CIRCLE_MARKER_LINE,
                            before: {
                              bgColor: '#DF5A49',
                            },
                            after: {
                              bgColor: '#DF5A49',
                            },
                          },
                        ]}
                        appendStyle={{
                          height: 16,
                          marginBottom: 4,
                          marginTop: 8,
                        }}
                        listCheckbox={listCheckbox}
                        setListCheckbox={setListCheckbox}
                      />
                      {/* <Footer
                        key={width + 1}
                        list={[
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_MATURITY_BOND',
                            dataKey: list[5],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[2],
                            },
                          },
                          {
                            text: 'bond.corporateBond.relativeToEquity.VALUE_OF_REDEEMED_BOND',
                            dataKey: list[6],
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_RELATIVE_TO_EQUITY[3],
                            },
                          },
                        ]}
                        appendStyle={{ height: 16 }}
                        listCheckbox={listCheckbox2}
                        setListCheckbox={setListCheckbox2}
                      /> */}
                    </>
                  ) : (
                    <NoData />
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default RelativeToEquity
