import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import {
  changeCheckedTableConstants,
  selectCheckedIndicators,
  selectData,
  selectGroupColumns,
  selectIds,
  selectLevelCollapse,
  selectLoadingTable,
  selectPeriod,
  selectTableConstants,
  selectValueById,
  selectViewType,
} from '../store/slice'
import { getTableData } from '../store/thunk'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnCell } from './OtherColumnCell'

export const CorporateTable = ({ dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const ids = useSelector(selectIds)
  const levelCollapse = useSelector(selectLevelCollapse)
  const groupColumns = useSelector(selectGroupColumns)
  const loading = useSelector(selectLoadingTable)
  const viewType = useSelector(selectViewType)
  const period = useSelector(selectPeriod)
  const checkedIndicator = useSelector(selectCheckedIndicators)
  const tableConstants = useSelector(selectTableConstants)
  const data = useSelector(selectData)

  // Use state
  const [rowsCollapse, setRowsCollapse] = useState([])

  // Get data
  const getCheckedTableConstants = useMemo(() => {
    return tableConstants.filter(
      (item) =>
        checkedIndicator.includes(item.indicatorId) ||
        item.childrenId.some((id) => checkedIndicator.includes(id)),
    )
  }, [tableConstants, checkedIndicator])

  const getIds = useMemo(() => {
    return getCheckedTableConstants.map((item) => item.indicatorId)
  }, [getCheckedTableConstants])

  // Actions
  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  // Use effect
  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId) return
    dispatch(
      getTableData({
        TimeFrequency: viewType,
        NumberOfPeriod: period,
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
      }),
    )
  }, [viewType, period, dataType, sectorInfo])

  useEffect(() => {
    dispatch(changeCheckedTableConstants(getCheckedTableConstants))
  }, [getCheckedTableConstants])

  if (loading) {
    return <Loading />
  }

  if (!loading && (!groupColumns.length || !ids.length || !data.length)) {
    return <NoData />
  }

  return (
    <Table
      ids={getIds}
      getDataFromRedux={selectValueById}
      isLoading={loading}
      schema={groupColumns.map((item, index) => {
        const title = item.title
        const colId = item.key
        const result = {
          colId,
          title,
        }

        if (index === 0) {
          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'left',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, { style, className }) => {
              return (
                <FirstColumnCell
                  val={val}
                  rowId={rowId}
                  style={{ ...style, minWidth: 300 }}
                  className={className}
                  onRowCollapse={onRowCollapse}
                />
              )
            },
          }
        }

        return {
          ...result,
          isI18n: true,
          thStyle: {
            textAlign: 'right',
            fontSize: 10,
          },
          canCustomTd: true,
          render: (val, rowId, { className }) => {
            return (
              <OtherColumnCell val={val} rowId={rowId} className={className} />
            )
          },
        }
      })}
      stickyFirstColumn={true}
      hasFooter={false}
      isCollapse={true}
      rowsCollapse={rowsCollapse}
      levelCollapse={levelCollapse}
      defaultScrollToRight
    />
  )
}
