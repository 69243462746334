export const STATEMENT_CONSTANTS = {
  BALANCE_SHEET: 'BalanceSheet',
  INCOME_STATEMENT: 'IncomeStatement',
  CASH_FLOW: 'CashFlow',
  NOTE: 'Note',
}

export const AUDIT_CONSTANTS = {
  AUDIT_STATUS: 'auditStatus',
  AUDIT_FIRM: 'auditFirm',
  AUDIT_OPINION_TYPE_NAME: 'auditOpinionTypeName',
}

export const AUDIT_DATA = Object.keys(AUDIT_CONSTANTS).map((key) => ({
  index: AUDIT_CONSTANTS[key],
  name: 'corporate.financialData.financialStatement.' + key,
  key: AUDIT_CONSTANTS[key],
  en_key: 'en_' + AUDIT_CONSTANTS[key],
  level: 0,
  style: '',
}))
