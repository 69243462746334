import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { changeRetryFlag, changeYearType } from './slice'

export const getLoanStructureChartData = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/bank/assetQuality/loanStructure/GET_LOAN_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FinancialAnalysisService.getAssetQualityLoanStructure(data)

      if (response.requestParams) {
        dispatch(changeYearType(response.requestParams.Year))
      }
      dispatch(changeRetryFlag(false))

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
