import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Z_INDEX } from '../../constants/Common'
import { getUnique } from '../../utils/Common'
import DropDown, { positionDropdown } from '../dropdown'
import { HandleClickOutside } from '../HandleClickOutside'
import { ScrollComponent } from '../ScrollComponent'
import style from './index.module.css'

const SelectMonthYear = ({
  handleChange,
  selectRef,
  options,
  renderOption,
  currentValue,
  marginTop,
  zIndex,
  contentDropdownRef,
  isOptionSelectedDefaultColor,
  position,
  maxHeightDropdown,
  themeWhite,
}) => {
  const containerRef = useRef()
  const currentOptionRef = useRef()
  const isDisableOpen = useRef(false)
  const idDropDown = useRef('id' + getUnique())

  const [isShowOption, setIsShowOption] = useState(false)

  useEffect(() => {
    if (isShowOption) {
      const timer = setTimeout(() => {
        document
          .querySelector(`#${idDropDown.current} .scrollbars div:first-child`)
          .scroll({
            top: currentOptionRef.current?.offsetTop || 0,
          })
      })
      return () => clearTimeout(timer)
    }
  }, [isShowOption])

  const handleCloseOption = () => setIsShowOption(false)

  const handleOpenOption = () => {
    if (!isDisableOpen.current) setIsShowOption(true)
  }

  const handleClickOption = (value) => {
    handleChange(value)
    handleCloseOption()
  }

  const handleClickOutside = () => {
    handleCloseOption()
    isDisableOpen.current = true
    setTimeout(() => (isDisableOpen.current = false), 200)
  }

  const classOptionSelecte = themeWhite
    ? style.optionSelectThemeWhite
    : isOptionSelectedDefaultColor
    ? style.optionSelect
    : style.optionSelect2

  const classOptionSelected = themeWhite
    ? style.optionSelectedThemeWhite
    : isOptionSelectedDefaultColor
    ? style.optionSelected
    : style.optionSelected2

  return (
    <>
      <div onClick={handleOpenOption} ref={containerRef}>
        {selectRef}
      </div>
      <DropDown
        isShow={isShowOption}
        parentRef={containerRef}
        position={position ? position : positionDropdown.BOTTOM_CENTER}
        zIndex={zIndex || Z_INDEX.DATE_PICKER + 1}
        appendStyle={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}
      >
        <HandleClickOutside handleClickOutside={handleClickOutside}>
          <div
            ref={contentDropdownRef}
            id={idDropDown.current}
            className="react-datepicker-ignore-onclickoutside"
            style={{ marginTop: `${marginTop || 8}px` }}
          >
            <ScrollComponent
              autoHeight={true}
              autoHeightMax={maxHeightDropdown}
              verticalTrackWidth={5}
              horizontalTrackWidth={5}
              bgColor={themeWhite ? '#D9D9D9' : '#2a3443'}
            >
              {options.map((value) => (
                <div
                  key={value}
                  onClick={() => handleClickOption(value)}
                  ref={value === currentValue ? currentOptionRef : undefined}
                >
                  {renderOption ? (
                    renderOption(value)
                  ) : (
                    <div
                      className={`${classOptionSelecte} ${
                        value === currentValue ? classOptionSelected : ''
                      }`}
                    >
                      {value}
                    </div>
                  )}
                </div>
              ))}
            </ScrollComponent>
          </div>
        </HandleClickOutside>
      </DropDown>
    </>
  )
}

SelectMonthYear.propTypes = {
  handleChange: PropTypes.func.isRequired,
  selectRef: PropTypes.element.isRequired,
  options: PropTypes.array.isRequired,
  renderOption: PropTypes.func,
  currentValue: PropTypes.any.isRequired,
  marginTop: PropTypes.number,
  zIndex: PropTypes.number,
  contentDropdownRef: PropTypes.object,
  isOptionSelectedDefaultColor: PropTypes.bool,
  maxHeightDropdown: PropTypes.number,
  themeWhite: PropTypes.bool,
}

SelectMonthYear.defaultProps = {
  isOptionSelectedDefaultColor: true,
  maxHeightDropdown: 250,
  themeWhite: false,
}

export default SelectMonthYear
