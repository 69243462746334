import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { convertSortKeyToNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { FilterList } from './FilterList'
import { TABLE_TYPE } from './constants'
import { getGroupColumnsByTableType } from './helper'
import {
  changeGroupColumns,
  changePage,
  changeSortField,
  changeSortOrder,
  changeTableType,
  selectDataTable,
  selectDataTableById,
  selectFilter,
  selectGroupColumns,
  selectIds,
  selectIsChangeFilter,
  selectLoadingTable,
  selectRecalcWidths,
  sort,
} from './store/slice'
import {
  getOrderStatisticsDetail,
  getOrderStatisticsOverview,
  getOrderStatisticsTicker,
} from './store/thunk'

const tableId = 'overviewStatistics'
const PAGE_SIZE = 22
const PAGE_NAVIGATION_HEIGHT = 20
const DEFAULT_FILTER_HEIGHT_PERCENT = 0.4

export const OverviewStatistics = ({ width, height }) => {
  const dispatch = useDispatch()

  const timeZone = UseTimeZone()

  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoadingTable)
  const filterData = useSelector(selectFilter)
  const groupColumns = useSelector(selectGroupColumns)
  const reCalcWidths = useSelector(selectRecalcWidths)
  const isChangeFilter = useSelector(selectIsChangeFilter)
  const dataTable = useSelector(selectDataTable)
  const locale = useSelector((state) => state.i18n.locale)

  const [tableHeight, setTableHeight] = useState(0)

  const renderContentPagination = () => {
    return <></>
  }

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  const filterListHeight =
    document.getElementById('filterList')?.getBoundingClientRect().height ??
    height * DEFAULT_FILTER_HEIGHT_PERCENT

  const setPage = (value) => {
    dispatch(changePage(value))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(changeSortField(Object.keys(data)[0]))
      dispatch(changeSortOrder(convertSortKeyToNumber(Object.values(data)[0])))
    } else {
      dispatch(changeSortField(null))
      dispatch(changeSortOrder(null))
    }
  }
  useEffect(() => {
    dispatch(
      changeGroupColumns(
        getGroupColumnsByTableType(filterData, locale, timeZone, dataTable),
      ),
    )
  }, [filterData?.tableType, dataTable])

  useEffect(() => {
    if (!filterData?.isTotal) {
      dispatch(changeTableType(TABLE_TYPE.TICKER))
    } else if (
      filterData?.tableType === TABLE_TYPE.OVERVIEW ||
      !filterData?.tableType
    ) {
      dispatch(changeTableType(TABLE_TYPE.OVERVIEW))
    } else {
      dispatch(changeTableType(TABLE_TYPE.DETAIL))
    }
  }, [filterData?.isTotal])

  const params = {
    From: filterData?.dateFrom
      ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
      : firstDateOfCurrentYear,
    To: filterData?.dateTo
      ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
      : new Date(),
    Page: filterData?.page,
    PageSize: PAGE_SIZE,
    SortField: filterData?.sortField ?? '',
    SortOrder: filterData?.sortOrder ?? '',
  }

  useEffect(() => {
    if (filterData?.derivativeId && filterData?.derivativeId > 0) {
      params.FutureId = filterData?.derivativeId
      dispatch(getOrderStatisticsTicker(params))
    } else if (filterData?.tableType === TABLE_TYPE.OVERVIEW) {
      params.GroupId = filterData?.derivativeId
      dispatch(getOrderStatisticsOverview(params))
    } else {
      params.GroupId = filterData?.derivativeId
      dispatch(getOrderStatisticsDetail(params))
    }
  }, [locale, filterData])

  useEffect(() => {
    if (height && filterListHeight) {
      setTableHeight(height - filterListHeight - PAGE_NAVIGATION_HEIGHT)
    }
  }, [height - filterListHeight])

  return (
    width &&
    height && (
      <>
        <div id="filterList">
          <FilterList filterData={filterData} width={width} />
        </div>
        {isChangeFilter && <Loading />}
        {!isChangeFilter &&
          (!dataTable.length ? (
            <div
              style={{
                height: tableHeight,
              }}
            >
              <NoData />
            </div>
          ) : (
            groupColumns?.schema.length && (
              <div
                style={{
                  height: tableHeight,
                }}
                id={tableId}
              >
                <Table
                  ids={ids}
                  getDataFromRedux={selectDataTableById}
                  isLoading={isLoading}
                  columnDraggable={false}
                  stickies={
                    filterData?.tableType === TABLE_TYPE.DETAIL
                      ? { tradingDateId: true, ticker: true }
                      : { tradingDateId: true }
                  }
                  hasTooltip={false}
                  hasFooter={false}
                  resizable={false}
                  schema={groupColumns?.schema}
                  hasPagination={true}
                  currentPage={filterData?.page}
                  totalPage={filterData?.totalPage}
                  handleChangePage={setPage}
                  renderContentPagination={renderContentPagination}
                  reCalcWidths={reCalcWidths}
                  sort={sort}
                  handleSortField={onChangeSortField}
                />
              </div>
            )
          ))}
      </>
    )
  )
}
