import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import PopupAlert from '../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../common/sizeTracker'
import { Table } from '../../../common/table'
import { keyBy } from '../../../utils/Common'
import { formatVal, valDivMillion } from '../../../utils/Value'
import { BALANCE_PAYMENT_FILTER } from '../constants'
import {
  addCategoryToList,
  keepCurrentSelectedIndexes,
  removeCategoryFromList,
  selectCategoryDataTableById,
  selectCategoryIds,
  selectCurrentTypeStatistics,
  selectDataTable,
  selectGroupColumns,
  selectIsChangeFilter,
  selectListCategory,
  selectLoadingTableContent,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectRowsCollapse,
  setRowsCollapse,
} from './store/slice'
import {
  balanceOfPaymentTableContent,
  balanceOfPaymentTableContentAfterScroll,
} from './store/thunk'
import { TdSelect } from './TdSelect'

const LIMIT_SELECTION = 20
const NUMBER_DATA_RENDER_FIRST_FETCH = 20
const HORIZONTAL_TRACK_LEFT = 28
const NUMBER_DATA_RENDER_AFTER_SCROLL = 5

export const BalanceOfPayment = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataTable = useSelector(selectDataTable)
  const groupColumns = useSelector(selectGroupColumns)
  const categoryIds = useSelector(selectCategoryIds)
  const isContentLoading = useSelector(selectLoadingTableContent)
  const listCategory = useSelector(selectListCategory)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const isChangeFilter = useSelector(selectIsChangeFilter)
  const currentType = useSelector(selectCurrentTypeStatistics)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const locale = useSelector((state) => state.i18n.locale)

  const [isShowWarning, setIsShowWarning] = useState(false)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)
  const [newMaxYear, setNewMaxYear] = useState(minCurrentYear)

  const isYearly = currentType === BALANCE_PAYMENT_FILTER[0].value

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
    }
  }

  const categoryDataTableItemWithCollapse = dataTable?.map((item) => ({
    ...item,
    isCollapsible: item.level === 1 ? true : false,
  }))

  const levelCollapse = keyBy(
    categoryDataTableItemWithCollapse?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.isCollapsible,
      name: item.name,
    })),
    'id',
  )

  const currentSelectedIndexes = listCategory?.map((item) => item.index)

  const onRowClick = (rowId) => {
    if (!listCategory?.some((item) => item.id === rowId)) {
      if (listCategory.length < LIMIT_SELECTION) {
        const category = {
          id: rowId,
          name: dataTable?.find((item) => item.id === rowId).name,
          index: dataTable?.find((item) => item.id === rowId).index,
        }
        dispatch(addCategoryToList(category))
      } else {
        setIsShowWarning(true)
      }
    } else {
      dispatch(removeCategoryFromList(rowId))
    }
  }

  const scrollToLeftCallback = () => {
    if (isYearly) {
      setNewMaxYear(minCurrentYear)
    } else {
      setNewMaxMonth(minCurrentMonth)
    }
    setIsFirstRender(false)
  }

  useEffect(() => {
    setIsFirstRender(true)
    setNewMaxMonth(null)
    setNewMaxYear(null)
  }, [currentType])

  useEffect(() => {
    setIsFirstRender(true)
    dispatch(
      balanceOfPaymentTableContent({
        DataType: currentType,
        Limit: NUMBER_DATA_RENDER_FIRST_FETCH,
      }),
    )
  }, [currentType, locale])

  useEffect(() => {
    setNewMaxMonth(null)
    setNewMaxYear(null)
    dispatch(keepCurrentSelectedIndexes(currentSelectedIndexes))
  }, [locale])

  useEffect(() => {
    if (newMaxMonth && !isYearly) {
      dispatch(
        balanceOfPaymentTableContentAfterScroll({
          DataType: currentType,
          Limit: NUMBER_DATA_RENDER_AFTER_SCROLL,
          Quarter: minCurrentMonth,
          Year: minCurrentYear,
        }),
      )
    }
  }, [newMaxMonth, currentType, isYearly])

  useEffect(() => {
    if (newMaxYear && isYearly) {
      dispatch(
        balanceOfPaymentTableContentAfterScroll({
          DataType: currentType,
          Limit: NUMBER_DATA_RENDER_AFTER_SCROLL,
          Quarter: null,
          Year: minCurrentYear,
        }),
      )
    }
  }, [newMaxYear, currentType, isYearly])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
              {`${I18n.t(
                'economy.paymentBalance.paymentBalance.UNIT',
              )}: ${I18n.t(
                'economy.paymentBalance.paymentBalance.MILLION_USD',
              )}`}
            </Span>
            {isChangeFilter && <Loading />}
            {size.height && !isChangeFilter && (
              <div
                id="balanceOfPaymentTable"
                style={{ height: height - size.height }}
              >
                <Table
                  ids={categoryIds}
                  getDataFromRedux={selectCategoryDataTableById}
                  isLoading={isContentLoading}
                  columnDraggable={false}
                  horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                  hasTooltip={false}
                  hasFooter={false}
                  onRowClick={onRowClick}
                  stickyFirstColumn
                  schema={groupColumns.map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    let result = {
                      colId,
                      title,
                      isI18n: false,
                      thStyle: {
                        textAlign: 'right',
                      },
                      tdStyle: {
                        textAlign: 'right',
                      },
                    }
                    if (index === 0) {
                      result = {
                        ...result,
                        isI18n: false,
                        thStyle: {
                          textAlign: 'left',
                        },
                        tdStyle: {
                          textAlign: 'left',
                        },
                        render: (val, rowId) => {
                          return (
                            <TdSelect
                              val={val}
                              rowId={rowId}
                              onRowCollapse={onRowCollapse}
                              data={dataTable}
                              isRowCollapse={rowsCollapse.includes(rowId)}
                            />
                          )
                        },
                      }
                    }
                    if (index !== 0) {
                      result = {
                        ...result,
                        render: (val) => {
                          return (
                            <span
                              style={{
                                color: val < 0 ? '#ff4752' : '',
                              }}
                            >
                              {formatVal(valDivMillion(val), 0)}{' '}
                            </span>
                          )
                        },
                      }
                    }
                    return result
                  })}
                  isCollapse={true}
                  rowsCollapse={rowsCollapse}
                  levelCollapse={levelCollapse}
                  scrollToLeftCallback={scrollToLeftCallback}
                  defaultScrollToRight={isFirstRender}
                  isLazyLoadLeft={true}
                />
              </div>
            )}
            <PopupAlert
              message={I18n.t(
                'economy.paymentBalance.paymentBalance.WARNING_MESS',
              )}
              isShow={isShowWarning}
              handleClose={() => setIsShowWarning(false)}
              zIndexOverlay={12}
            />
          </>
        )}
      </SizeTracker>
    )
  )
}
