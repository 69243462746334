import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import CorrelationOfSecondaryMarket from './correlationOfSecondaryMarket'
import MarketTradingValue from './marketTradingValue'
import MarketTradingValueBySector from './marketTradingValueBySector'
import SectorStatistics from './sectorStatistics'

const MAP_KEY = {
  SECTOR_STATISTICS: 'SECTOR_STATISTICS',
  CORRELATION_OF_SECONDARY_MARKET: 'CORRELATION_OF_SECONDARY_MARKET',
  MARKET_TRADING_VALUE: 'MARKET_TRADING_VALUE',
  MARKET_TRADING_VALUE_BY_SECTOR: 'MARKET_TRADING_VALUE_BY_SECTOR',
}

const MAP_SIZE = {
  [`${MAP_KEY.SECTOR_STATISTICS}`]: {
    width: `calc(50%)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.CORRELATION_OF_SECONDARY_MARKET,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.CORRELATION_OF_SECONDARY_MARKET,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.MARKET_TRADING_VALUE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.MARKET_TRADING_VALUE_BY_SECTOR,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CORRELATION_OF_SECONDARY_MARKET}`]: {
    width: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${3.5 * COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARKET_TRADING_VALUE}`]: {
    width: `calc(50%)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_TRADING_VALUE_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARKET_TRADING_VALUE_BY_SECTOR}`]: {
    width: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 'calc(50%)',
    left: `calc(50% + ${3.5 * COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_TRADING_VALUE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <SectorStatistics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SECTOR_STATISTICS}
        sizes={sizes}
        setSizes={setSizes}
      />
      <CorrelationOfSecondaryMarket
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CORRELATION_OF_SECONDARY_MARKET}
        sizes={sizes}
        setSizes={setSizes}
      />
      <MarketTradingValue
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_TRADING_VALUE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <MarketTradingValueBySector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_TRADING_VALUE_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      {/* <AverageDurationBySector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.AVERAGE_DURATION_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      /> */}
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
