import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import style from './index.module.css'

export const MoreButton = ({ onAction }) => {
  return (
    <a className={style.btn} onClick={onAction}>
      <Translate value="common.news.VIEW_ALL" />
      <i className="icon-arrow-right-two" />
    </a>
  )
}

MoreButton.propTypes = {
  onAction: PropTypes.func,
}

MoreButton.defaultProps = {
  onAction: () => {},
}
