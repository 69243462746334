import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import useStockBasicInfo from '../../../common/hooks/useStockBasicInfo'
import useGetIndices from '../../../common/masterData/useGetIndices'
import Tabs from '../../../common/tabs'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../constants/routes'
import Associate from '../associate'
import Director from '../director'
import MajorShareHolder from '../majorSharehoders'
import Subsidiaries from '../subsidiaries'
import { changeIdTab } from './store/slice'

export const tabData = [
  {
    title: 'corporate.ownership.ownership.MAJOR_SHAREHOLDER',
    content: <MajorShareHolder />,
  },
  {
    title: 'corporate.ownership.ownership.MANAGEMENT_AND_DIRECTOR',
    content: <Director />,
  },
  {
    title: 'corporate.ownership.ownership.SUBSIDIARIES',
    content: <Subsidiaries />,
  },
  {
    title: 'corporate.ownership.ownership.ASSOCIATE',
    content: <Associate />,
  },
  {
    title: 'corporate.ownership.ownership.INTERNAL_TRANSACTIONS',
    content: <></>,
  },
]

export const TabContent = ({ width, height }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const [stateIdTab, setStateIdTab] = useState(0)
  const basicInfo = useSelector(selectBasicInfo)
  const { indices } = useGetIndices()
  const stockBasicInfo = useStockBasicInfo(basicInfo.organizationId)

  const getDataIndexRedirect = () => {
    const dataIndexRedirect = indices.find(
      (item) => item.groupId === stockBasicInfo.indexGroupId,
    )
    return dataIndexRedirect || {}
  }

  const redirectDirectorDeal = () => {
    navigate(
      FEATURES.market.components['market-in-depth'].components['director-deal']
        .route,
      getDataIndexRedirect(),
    )

    const dataTickerRedirect = {
      organizationId: basicInfo.organizationId,
      organizationShortName: basicInfo.companyName,
      tickerOrTaxCode: basicInfo.ticker,
    }
    sessionStorage.setItem('tickerRedirect', JSON.stringify(dataTickerRedirect))
  }

  useEffect(() => {
    if (stateIdTab === 4) {
      redirectDirectorDeal()
    } else {
      dispatch(changeIdTab(stateIdTab))
    }
  }, [stateIdTab])

  return (
    <Tabs
      active={0}
      itemStyle={{
        textTransform: 'uppercase',
        paddingLeft: 16,
        paddingRight: 16,
        fontWeight: 500,
      }}
      saveDataActionTab={setStateIdTab}
      widthComponent={width}
    >
      {tabData.map((tab, index) => (
        <div key={index} title={tab.title} width={width} height={height}>
          {tab.content}
        </div>
      ))}
    </Tabs>
  )
}

TabContent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
