export const overview = {
  OVERVIEW: 'Tổng quan',
  YEARLY: 'Hàng năm',
  MONTHLY: 'Lũy kế tháng',
  REGISTERED_CAPITAL: 'Vốn đăng ký',
  DISBURSED_CAPITAL: 'Vốn thực hiện',
  NEW_REGISTRATION: 'Vốn đăng ký cấp mới',
  ADDITIONAL_REGISTRATION: 'Vốn đăng ký tăng thêm  ',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE: 'Giá trị góp vốn, mua cổ phần',
  NEW_APPROVAL_PROJECT: 'Số dự án cấp mới',
  ADDITIONAL_CAPITAL: 'Số lượt dự án điều chỉnh',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE_1: 'Số lượt góp vốn mua cổ phần',
  NEW_AND_ADDITIONAL_REGISTRATION: 'Vốn đăng ký',
  NO_OF_NEW_APPROVAL_PROJECT: 'Số dự án cấp mới',
  TITLE_PANEL_TABLE: 'Tổng vốn đầu tư nước ngoài đăng ký/thực hiện',
  TITLE_PANEL_CHART: 'Biểu đồ vốn đầu tư nước ngoài đăng ký/thực hiện',
  TITLE_PANEL_KEY_INDICATOR: 'Các chỉ tiêu nổi bật',
  TITLE_PANEL_1: 'Top 10 quốc gia đầu tư vào Việt Nam',
  TITLE_PANEL_2: 'Top 10 tỉnh thành thu hút vốn đầu tư nước ngoài',
  TITLE_PANEL_3: 'Cơ cấu tổng vốn Đầu tư nước ngoài',
  TITLE_PANEL_4: 'Top 5 lĩnh vực được đầu tư nhiều nhất',
  LABEL_TABLE: 'Đơn vị: triệu USD',
  MILLION_USD: 'Triệu USD',
  PROJECTS: 'Dự án',
  PROJECTS_NUMBER: 'Số dự án',
  LABEL_PROJECTS: 'Dự án mới',
  LABEL_MILLION_USD: '(Triệu USD)',
  BILLION_USD: 'Tỷ USD',
  OTHERS: 'Các ngành khác',
  NAME_SCREEN: 'Kinh tế_Tổng quan',
  LABEL_PROJECTS_CHART: '(Dự án)',
  REGISTRATION_TOTAL: 'Vốn FDI đăng ký',
}

export const fdiByCountries = {
  TITLE_SCREEN: 'FDI theo đối tác',
  FDI_BY_ORIGIN_COUNTRY: 'Vốn đầu tư nước ngoài theo đối tác',
  CAPITAL: 'Vốn đăng ký',
  NUMBER_OF_PROJECT: 'Số dự án',
  YEARLY: 'Hàng năm',
  ACC_MONTHLY: 'Luỹ kế tháng',
  SEARCH: 'Quốc gia',
  LABEL_TABLE: 'Đơn vị: triệu USD',
  FDI_STRUCTURE_BY_ORIGIN_COUNTRY: 'Cơ cấu vốn FDI theo đối tác',
  LABEL_CHART: 'Cơ cấu vốn/dự án của',
  REGISTERED_CAPITAL: 'Tổng vốn đăng ký',
  NEW_REGISTRATION: 'Vốn đăng ký cấp mới',
  ADDITIONAL_REGISTRATION: 'Vốn đăng ký điều chỉnh',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE: 'Giá trị góp vốn mua cổ phần',
  NUMBER_OF_NEW_REGISTRATION_PROJECT: 'Số dự án cấp mới',
  MILLION_USD: 'Triệu USD',
  PROJECTS: 'Dự án',
  OTHERS: 'Các đối tác khác',
  COMPOSITION_OF_FDI_INFLOWS_BY_ORIGIN_COUNTRY:
    'Cơ cấu đối tác trong tổng vốn đầu tư cả nước',
  FDI_STRUCTURE_BY_COUNTRY: 'Cơ cấu vốn đầu tư nước ngoài theo đối tác',
  COUNTRY_CONTINENT: 'QUỐC GIA/LỤC ĐỊA',
  LABEL_MILLION_USD: '(Triệu USD)',
  LABEL_PROJECTS: '(Dự án)',
}

export const fdiByIndustry = {
  TITLE_SCREEN: 'FDI theo ngành',
  CAPITAL: 'Tổng vốn',
  NUMBER_OF_PROJECT: 'Số dự án',
  FDI_NO_OF_NEW_PROJECTS: 'Số dự án cấp mới',
  FDI_NEW_REGISTERED_CAPITAL: 'Vốn đăng ký cấp mới',
  FDI_NO_OF_PROJECT_REGISTERED_FOR_ADDITIONAL_CAPITAL: 'Số lượt dự án tăng vốn',
  FDI_REGISTERED_ADDITIONAL_CAPITAL: 'Vốn đăng ký điều chỉnh',
  FDI_PROJECTS_REGISTERED_FOR_SHARE_PURCHASE: 'Số lượt góp vốn mua cổ phần',
  FDI_VALUE_REGISTERED_FOR_SHARE_PURCHASE: 'Giá trị góp vốn mua cổ phần',
  FDI_NEW_REGISTERED_AND_ADDITIONAL_CAPITAL: 'Tổng vốn đăng ký',
  FDI_STRUCTURE_BY_INDUSTRY: 'Cơ cấu vốn/dự án theo ngành',
  COMPOSITION_OF_FDI_INFLOWS_BY_INDUSTRY:
    'Cơ cấu ngành trong tổng vốn đầu tư cả nước',
  MILLION_USD: '(Triệu USD)',
  PROJECTS: '(Dự án)',
  FDI_BY_INDUSTRY: 'Vốn đầu tư nước ngoài theo ngành',
  INDUSTRY: 'Ngành',
  LABEL_TABLE: 'Đơn vị: triệu USD',
  YEARLY: 'Hàng năm',
  ACC_MONTHLY: 'Luỹ kế tháng',
  OTHERS: 'Các ngành khác',
  NAME_SCREEN: 'Kinh tế_FDI theo ngành',
  MILLION_USD_UNIT: 'Triệu USD',
  PROJECTS_UNIT: 'Dự án',
  LABEL_MILLION_USD: '(Triệu USD)',
  LABEL_PROJECTS: '(Dự án)',
}

export const fdiByProvinces = {
  TITLE_SCREEN: 'FDI theo tỉnh thành',
  CAPITAL: 'Vốn đăng ký',
  NUMBER_OF_PROJECT: 'Số dự án',
  YEARLY: 'Hàng năm',
  ACC_MONTHLY: 'Lũy kế tháng',
  FDI_BY_PROVINCE: 'Vốn đầu tư nước ngoài theo tỉnh thành',
  FDI_STRUCTURE_BY_PROVINCE: 'Cơ cấu vốn đầu tư nước ngoài theo tỉnh thành',
  COMPOSITION_OF_FDI_INFLOWS_BY_PROVINCE:
    'Cơ cấu các tỉnh thành trong tổng vốn đầu tư cả nước',
  MILLION_USD: '(Triệu USD)',
  PROJECTS: '(Dự án)',
  REGISTERED_CAPITAL: 'Vốn đăng ký',
  NEW_REGISTRATION: 'Vốn đăng ký cấp mới',
  ADDITIONAL_REGISTRATION: 'Vốn đăng ký điều chỉnh',
  CAPITAL_CONTRIBUTION_SHARE_PURCHASE: 'Góp vốn, mua cổ phần',
  NUMBER_OF_NEW_REGISTRATION_PROJECT: 'Số dự án mới',
  PERIOD: 'Kỳ dữ liệu',
  PROVINCE: 'Tỉnh thành phố',
  VALUE: 'Giá trị đóng góp (%)',
  OTHERS: 'Các tỉnh thành khác',
  LABEL_CHART: 'Cơ cấu vốn/dự án của',
  SEARCH: 'Tỉnh, thành phố',
  NAME_SCREEN: 'Kinh tế_FDI theo tỉnh thành',
  PROVINCE1: 'Tỉnh thành',
  MILLION_USD_UNIT: 'Triệu USD',
  PROJECTS_UNIT: 'Dự án',
  LABEL_MILLION_USD: '(Triệu USD)',
  LABEL_PROJECTS: '(Dự án)',
}
