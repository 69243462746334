import { forwardRef } from 'react'
import { useDispatch } from 'react-redux'
import UseI18n from '../../../common/hooks/useI18n'
import { Input } from '../../../common/html/Input'
import style from '../index.module.css'
import { search } from './store/slice'

export const DropdownInput = forwardRef((props, ref) => {
  const dispatch = useDispatch()

  const handleSearchItem = (e) => {
    const searchString = e.target.value.toLowerCase()
    dispatch(search(searchString))
  }

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <form className="form-search-inbox" style={{ marginTop: 2 }}>
      <Input
        style={{ fontSize: 11 }}
        type="text"
        className={`search-inbox ${style.inputSearch}`}
        placeholder={placeHolder}
        onChange={(e) => handleSearchItem(e)}
        ref={ref}
        onKeyDown={(e) => handleKeyEnter(e)}
      />
      <button type="button">
        <i className="icon-search-2" />
      </button>
    </form>
  )
})

export default DropdownInput
