export const LIMIT_FIRST_FETCH = 20
export const HORIZONTAL_TRACK_LEFT = 28
export const LIMIT_FETCH = 5

export const TIME_FREQ = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
}

export const TIME_FREQUENCY_FILTER = [
  {
    title: 'sector.sectorSpecific.fishery.FILTER_MONTHLY',
    value: TIME_FREQ.MONTHLY,
    isYearly: false,
  },
  {
    title: 'sector.sectorSpecific.fishery.FILTER_ACC_MONTHLY',
    value: TIME_FREQ.ACC_MONTHLY,
    isYearly: false,
  },
  {
    title: 'sector.sectorSpecific.fishery.FILTER_YEARLY',
    value: TIME_FREQ.YEARLY,
    isYearly: true,
  },
]

export const TYPE_VAL_GROWTH = {
  VAL: 'Value',
  GROWTH: 'Growth',
}

export const VAL_GROWTH_FILTER = [
  {
    title: 'sector.sectorSpecific.fishery.VALUE',
    value: TYPE_VAL_GROWTH.VAL,
  },
  {
    title: 'sector.sectorSpecific.fishery.GROWTH',
    value: TYPE_VAL_GROWTH.GROWTH,
  },
]

export const ID_TABLE = 'corporateExportTable'
