import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { MAP_TIME_FILTER } from '../constants'
import { selectTimeFilter } from '../financialMetric/store/slice'
import { getLabelQuarterly } from '../helper'
import { selectLastItem } from './store/slice'

export const TopLabel = () => {
  const lastItem = useSelector(selectLastItem)
  const timeFilter = useSelector(selectTimeFilter)

  return (
    <>
      <Translate value="corporate.overview.financial.PEERS_COMPARISON" />
      &nbsp;
      {timeFilter.value === MAP_TIME_FILTER.YEARLY
        ? lastItem.year
        : getLabelQuarterly(lastItem)}
    </>
  )
}
