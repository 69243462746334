import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../../common/textEllipsis'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine'
import { ItemSearch } from '../ItemSearch'
import {
  changeIsAccMonthlyGrowthChart,
  changeIsValOrGrowthChart,
  changeIsYearlyGrowthChart,
} from '../exportGrowth/store/slice'
import style from '../index.module.css'
import {
  TIME_FREQ,
  TIME_FREQUENCY_FILTER,
  VAL_GROWTH_FILTER,
} from './constants'
import {
  changeCurrentMinMonth,
  changeFrequencyType,
  changeIsValOrGrowth,
  selectIsValOrGrowth,
} from './store/slice'

export const FilterList = ({ width, currentTimeFrequency }) => {
  const dispatch = useDispatch()

  const leftHeaderRef = useRef()
  const radioBoxRef = useRef()

  const isValOrGrowth = useSelector(selectIsValOrGrowth)

  const handleChangeValOrGrowth = (item) => {
    dispatch(changeIsValOrGrowth(item.value))
    dispatch(changeIsValOrGrowthChart(item.value))
  }

  const handleChangeTimeFreq = (item) => {
    dispatch(changeFrequencyType(item.value))
    dispatch(changeCurrentMinMonth(null))
    dispatch(changeIsYearlyGrowthChart(item.value === TIME_FREQ.YEARLY))
    dispatch(
      changeIsAccMonthlyGrowthChart(item.value === TIME_FREQ.ACC_MONTHLY),
    )
  }

  return (
    <div style={{ width: width }} className="d-flex j-b">
      <div style={{ marginTop: '3px', width: '200px' }} ref={leftHeaderRef}>
        <ItemSearch />
      </div>
      <div className="d-flex">
        <ul className={`list-check d-flex mt-0`} ref={radioBoxRef}>
          {VAL_GROWTH_FILTER.map((item) => (
            <li key={item.value} className="mb-0">
              <label>
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={item.value === isValOrGrowth}
                  onChange={() => handleChangeValOrGrowth(item)}
                />
                <Span
                  style={{ fontSize: 12 }}
                  className={item.value === isValOrGrowth ? style.active : ''}
                >
                  <TextEllipsis text={item.title} />
                </Span>
              </label>
            </li>
          ))}
        </ul>
        <VerticalLine mr={12} ml={12} />
        <div style={{ marginTop: '3px' }}>
          <DispatchActionTab
            data={TIME_FREQUENCY_FILTER}
            activeTab={currentTimeFrequency}
            onChangeTab={handleChangeTimeFreq}
            itemStyle={{
              fontWeight: 500,
              fontSize: 11,
              paddingLeft: 10,
              paddingRight: 10,
            }}
            widthComponent={
              width -
              leftHeaderRef.current?.getBoundingClientRect().width -
              radioBoxRef.current?.getBoundingClientRect().width -
              50
            }
          />
        </div>
      </div>
    </div>
  )
}
