export const valueTimefrequency = {
  ISSUEMETHOD: 'ISSUEMETHOD',
  COLLATERAL: 'COLLATERAL',
}

export const Timefrequency = [
  {
    label: 'bond.overview.ValueOfBonds.fiter.method.issueMethod',
    value: valueTimefrequency.ISSUEMETHOD,
  },
  {
    label: 'bond.overview.ValueOfBonds.fiter.method.collateral',
    value: valueTimefrequency.COLLATERAL,
  },
]
