import { useRef } from 'react'
import { ChartTooltipContent } from './ChartTooltipContent'

export const CustomTooltip = ({
  active,
  payload,
  keyXAxis,
  xAxisDecimalLength,
  decimalLengths,
  tooltipSchema,
  renderCustomTooltip,
}) => {
  const ref = useRef()

  if (!active) return null

  const data = payload && payload.length > 0 ? payload[0].payload : {}

  const renderTooltip = () => {
    return (
      <ChartTooltipContent
        tooltipData={data}
        xAxisKey={keyXAxis}
        decimalLengths={decimalLengths}
        tooltipSchema={tooltipSchema}
      />
    )
  }

  return (
    <div
      ref={ref}
      style={{
        background: '#344053cc',
        padding: 10,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        fontWeight: 500,
        position: 'relative',
      }}
    >
      {renderCustomTooltip
        ? renderCustomTooltip(data, decimalLengths, xAxisDecimalLength)
        : renderTooltip()}
    </div>
  )
}
