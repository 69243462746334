import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getMaxDate } from '../../averageTradingValue/store/thunk'
import { convertData } from '../../common/helper'
import { getOrganizationsInfo } from '../../tableMarginGrowth/store/thunk'
import { DEFAULT_ALL_VALUE } from '../constants'
import { getMarginLoans } from './thunk'

const slice = createSlice({
  name: 'market/marginLoans/marginLoansChart',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    filter: {
      currentYear: null,
      currentQuarter: null,
    },
    currentOrganizationId: null,
    idsBarkey: [],
    maxDate: {
      year: null,
      quarter: null,
    },
    listOrganizations: [],
    keySearch: '',
    listCheckbox: [],
  },
  reducers: {
    changeCurrentYear: (state, action) => {
      state.filter.currentYear = action.payload
    },
    changeCurrentQuarter: (state, action) => {
      state.filter.currentQuarter = action.payload
    },
    changeCurrentOrganizationId: (state, action) => {
      state.currentOrganizationId = action.payload
    },
    search: (state, action) => {
      state.keySearch = action.payload
    },
    changeListCheckbox: (state, action) => {
      state.listCheckbox = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarginLoans.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getMarginLoans.fulfilled, (state, action) => {
      state.isLoading = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          quarter: item.realQuarter,
          year: item.realYear,
          value: item.nS239 / 1000000000,
        })) || []
      state.idsBarkey = [
        ...new Set(payloadData.map((item) => `${item.organizationId}`)),
      ]
      state.origData = payloadData

      const filterNullObj = (obj) => {
        for (var key in obj) {
          if (obj[key] === 0 && key !== 'time') return false
        }
        return true
      }
      state.data = convertData(payloadData, true).filter((item) =>
        filterNullObj(item),
      )
    })
    builder.addCase(getMarginLoans.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getOrganizationsInfo.pending, (state) => {
      state.listOrganizations = []
      state.isLoading = true
    })
    builder.addCase(getOrganizationsInfo.fulfilled, (state, action) => {
      const defaultAllObj = {
        name: 'market.marginLoans.ALL',
        value: DEFAULT_ALL_VALUE,
      }
      state.listOrganizations = [
        { ...defaultAllObj },
        ...(action.payload?.map((item) => ({
          name: item.organizationShortName,
          value: item.organizationId,
          ticker: item.tickerOrTaxCode,
        })) || []),
      ]
    })
    builder.addCase(getOrganizationsInfo.rejected, (state) => {
      state.listOrganizations = []
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.maxDate = {}
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.maxDate.quarter = action.payload?.realQuarter
      state.maxDate.year = action.payload?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.maxDate = {}
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectFilter = (state) => state[slice.name].filter
export const selectIdsBarkey = (state) => state[slice.name].idsBarkey
export const selectMaxDate = (state) => state[slice.name].maxDate
export const selectListOrganizations = (state) =>
  state[slice.name].listOrganizations
export const selectKeySearch = (state) => state[slice.name].keySearch
export const selectListCheckbox = (state) => state[slice.name].listCheckbox
export const selectCurrentOrganizationId = (state) =>
  state[slice.name].currentOrganizationId

export const {
  changeCurrentQuarter,
  changeCurrentYear,
  changeCurrentOrganizationId,
  search,
  changeListCheckbox,
} = slice.actions

register(slice.name, slice.reducer)
