import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import {
  HOLDING_OPTIONS,
  SECURITY_HOLDING_TABS,
  TABLE_COL_KEYS,
  TABLE_TABS,
} from '../constants'
import {
  stockCurrentSecurityHoldingSchema,
  stockCurrentSecurityHoldingTableThSchema,
  stockCurrentSecurityHoldingThCellTopById,
} from './stockCurrentSecurityHoldingHelpers'
import { stockSecurityHoldingHistorySchema } from './stockSecurityHoldingHistoryHelpers'

export const getTableSchema = ({
  tableTab,
  filters,
  locale,
  maxMinByColId,
  assetColumns,
  dataById,
  onRowCollapse,
}) => {
  switch (true) {
    case tableTab === TABLE_TABS.STOCK &&
      filters.securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT:
      return stockCurrentSecurityHoldingSchema({
        maxMinByColId,
        dataById,
        isGroupByIndustry: filters.isGroupByIndustry,
        onRowCollapse,
      })
    case tableTab === TABLE_TABS.STOCK &&
      filters.securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY:
      return stockSecurityHoldingHistorySchema({
        assetColumns,
        holdingOption: filters.holdingOption,
        locale,
        dataById,
      })
    default:
      return []
  }
}

export const getTableThSchema = ({ tableTab, securityHoldingTab }) => {
  if (securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT) {
    switch (tableTab) {
      case TABLE_TABS.STOCK:
        return stockCurrentSecurityHoldingTableThSchema()
      default:
        return
    }
  }
}

export const getTableThCellTopById = ({ tableTab, securityHoldingTab }) => {
  if (securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT) {
    switch (tableTab) {
      case TABLE_TABS.STOCK:
        return stockCurrentSecurityHoldingThCellTopById
      default:
        return
    }
  }
}

export const getMaxMinDataByColId = (data, keys) => {
  const result = {}
  keys.forEach((id) => (result[id] = { max: 0, min: 0 }))
  data.forEach((item) => {
    Object.keys(result).forEach((key) => {
      result[key].max = Math.max(result[key].max, item[key])
      result[key].min = Math.min(result[key].min, item[key])
    })
  })
  return result
}

export const getParams = ({
  organizationId,
  filters,
  tableTab,
  funds,
  isDownload = false,
}) => {
  let params = {
    OrganizationId: organizationId,
    SortField:
      filters.sortField ||
      getSortFieldDefaultByTableTab({
        tableTab,
        securityHoldingTab: filters.securityHoldingTab,
      }),
    SortOrder: filters.sortOrder,
  }

  if (filters.securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT) {
    params = {
      ...params,
      Month: filters.month,
      Year: filters.year,
      IsGroup: filters.isGroupByIndustry,
    }
  } else {
    params = {
      ...params,
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From:
        filters.timeRange === calendarTabKey ? filters.startDate : undefined,
      To: filters.timeRange === calendarTabKey ? filters.endDate : undefined,
      AssetIds:
        filters.assetIds.length === funds.length
          ? ''
          : filters.assetIds.join(','),
    }
  }

  if (!isDownload) {
    params = {
      ...params,
      Page: filters.page,
      PageSize: 100,
    }
  } else {
    if (filters.securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY) {
      params = {
        ...params,
        HoldingOption: Boolean(
          filters.holdingOption === HOLDING_OPTIONS.CONTRIBUTION,
        ),
      }
    }
  }

  return params
}

export const getSortFieldDefaultByTableTab = ({
  tableTab,
  securityHoldingTab,
}) => {
  switch (true) {
    case tableTab === TABLE_TABS.STOCK &&
      securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT:
      return TABLE_COL_KEYS.COL_STOCK_FUND_NAV
    case tableTab === TABLE_TABS.STOCK &&
      securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY:
      return TABLE_COL_KEYS.COL_STOCK_PUBLIC_DATE
    default:
      return ''
  }
}
