import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FDIByIndustriesService from '../../../../../../core/services/economy/fdi/FDIByIndustriesService'
import { handleExport } from '../../../../../utils/Export'

export const getTableData = createAsyncThunk(
  'economy/fdi/fdiByIndustries/GET_DATA_TABLE',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await FDIByIndustriesService.getTotalRegisterCapitalRequest(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFDITypeName = createAsyncThunk(
  'economy/fdi/fdiByIndustries/GET_FDI_BY_INDUSTRIES',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByIndustriesService.getFDITypeName(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDataTable = createAsyncThunk(
  'economy/fdi/fdiByIndustries/DOWNLOAD_DATA_TABLE',
  async (data) => {
    const response = await FDIByIndustriesService.downloadTotalRegisterCapital(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
