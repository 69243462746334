import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  addIndicator,
  keys,
  openPopupTemplate,
  selectActiveSheet,
  selectColumnById,
  selectFiinXTemplates,
  selectListSheet,
  selectLoading,
  selectTemplates,
} from '../../../../common/dataExplorer/corporate/store/slice'
import {
  deleteTemplateThunk,
  getFiinXTemplatesThunk,
  getTemplatesThunk,
  getTemplateThunk,
  renameTemplateThunk,
} from '../../../../common/dataExplorer/corporate/store/thunk'
import useGetIndicator from '../../../../common/dataExplorer/corporate/useGetIndicator'
import MenuTab from '../../../../common/dataExplorer/filter/MenuTab'
import { Loading } from '../../../../common/loading'
import PopupEvent from '../../../../common/popup/PopupEvent'
import style from '../index.module.css'
import TickerFilter from './TickerFilter'

const RatioFilter = () => {
  const dispatch = useDispatch()

  const { indicatorsTree, loading } = useGetIndicator(false)

  const currentColumnById = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const activeSheet = useSelector(selectActiveSheet)
  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const columnById = useSelector(selectColumnById(activeSheet))
  const templates = useSelector(selectTemplates)
  const fiinXTemplates = useSelector(selectFiinXTemplates)
  const listSheet = useSelector(selectListSheet)

  const [isShowPopup, setIsShowPopup] = useState(false)
  const [templateId, setTemplateId] = useState(null)

  useEffect(() => {
    dispatch(getTemplatesThunk())
  }, [])

  useEffect(() => {
    dispatch(getFiinXTemplatesThunk())
  }, [locale])

  useEffect(() => {
    currentColumnById.current = columnById
  }, [columnById])

  const handleAddIndicator = (indicator) => {
    if (!Object.keys(indicator.children).length) {
      dispatch(addIndicator(indicator))
    }
  }

  const renameTemplate = ({ newName, item }) => {
    dispatch(
      renameTemplateThunk({
        templateId: item.templateId,
        oldTemplateName: item.templateName,
        newTemplateName: newName,
      }),
    )
  }

  const deleteTemplate = (item) => {
    dispatch(deleteTemplateThunk({ templateId: item.templateId }))
  }

  const getDetailTemplate = () => {
    dispatch(getTemplateThunk({ TemplateId: templateId }))
    hidePopup()
  }

  const getDetailFiinXTemplate = (template) => {
    dispatch(openPopupTemplate(template))
  }

  const showPopup = (item) => {
    setIsShowPopup(true)
    setTemplateId(item.templateId)
  }

  const hidePopup = () => {
    setIsShowPopup(false)
    setTemplateId(null)
  }

  const isFiinXTemplate = !!listSheet.find(
    (sheet) => sheet.value === activeSheet,
  )?.templateGroup

  return (
    <div
      className={`h-100 position-relative ${
        loadingTable ? style.containerLoadingBlur : ''
      }`}
    >
      {isShowPopup && (
        <PopupEvent
          isShow={isShowPopup}
          title={<Translate value="tool.dataExplorer.NOTICE" />}
          message={<Translate value="tool.dataExplorer.NOTE_DETAIL_TEMPLATE" />}
          handleClose={hidePopup}
          btnSubmit={{
            btnName: <Translate value="tool.dataExplorer.OK" />,
            event: getDetailTemplate,
          }}
          btnCancel={{
            btnName: <Translate value="common.button.BUTTON_CANCEL" />,
            event: hidePopup,
          }}
        />
      )}
      {loadingTable && <Loading />}
      <div className={style.h55}>
        <TickerFilter />
      </div>
      <div className={style.h45}>
        <MenuTab
          handleAddIndicator={handleAddIndicator}
          templates={templates}
          handleRenameTemplate={renameTemplate}
          handleDeleteTemplate={deleteTemplate}
          handleGetTemplate={showPopup}
          indicatorTree={indicatorsTree}
          loadingIndicator={loading}
          isHasFiinXTemplate={true}
          isFiinXTemplate={isFiinXTemplate}
          fiinXTemplates={
            process.env.REACT_APP_ENV === 'production' ? [] : fiinXTemplates
          }
          handleGetFiinXTemplate={getDetailFiinXTemplate}
        />
      </div>
    </div>
  )
}

export default RatioFilter
