import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { convertSortKeyToNumber } from '../../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import { SummaryFilter } from '../SummaryFilter'
import { TAB_TYPE } from '../constants'
import { priceDataTableCol } from './constants'
import { getTableSchema } from './helps'
import {
  changeFilter,
  resetData,
  selectDataDateRange,
  selectDataOrderStatistic,
  selectFilter,
  selectIds,
  selectLoading,
  selectReCalcWidths,
  selectTotalPage,
  sort,
} from './store/slice'
import { getOrderStatistic } from './store/thunk'

export const OrderStatistic = ({ width, tabType, onFilterChange }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const basicInfo = useSelector(selectBasicInfo)
  const ids = useSelector(selectIds)
  const filter = useSelector(selectFilter)
  const isLoading = useSelector(selectLoading)
  const totalPage = useSelector(selectTotalPage)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const dataDateRange = useSelector(selectDataDateRange)

  // Use state
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false)
  const [isOpenDateTo, setIsOpenDateTo] = useState(false)

  // Actions
  const onChangeTimeFilter = (time) => {
    dispatch(resetData())
    dispatch(changeFilter({ TimeFilter: time.value, Page: 1 }))
  }

  const onChangePage = (page) => {
    dispatch(changeFilter({ Page: page }))
  }

  const onChangeDateFrom = (date) => {
    dispatch(changeFilter({ StartDate: date }))
  }

  const onChangeDateTo = (date) => {
    dispatch(changeFilter({ EndDate: date }))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeFilter({
          SortField: Object.keys(data)[0],
          SortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(changeFilter({ SortField: null, SortOrder: null }))
    }
  }

  // Use effect
  useEffect(() => {
    onFilterChange(filter)
  }, [filter])

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        changeFilter({
          OrganizationId: basicInfo.organizationId,
          StartDate: undefined,
          EndDate: undefined,
        }),
      )
      dispatch(resetData())
    }
  }, [basicInfo.organizationId])

  useEffect(() => {
    if (tabType === TAB_TYPE.ORDER_STATISTIC && filter.OrganizationId) {
      dispatch(
        getOrderStatistic({
          ...filter,
          StartDate: filter.StartDate
            ? getISOStartOrEndOfDay(filter.StartDate, timeZone, true)
            : undefined,
          EndDate: filter.EndDate
            ? getISOStartOrEndOfDay(filter.EndDate, timeZone, false)
            : undefined,
        }),
      )
    }
  }, [tabType, filter])

  return (
    <>
      <SummaryFilter
        width={width}
        page={filter.Page}
        totalPage={totalPage}
        activeTab={filter.TimeFilter}
        dateFrom={
          isOpenDateFrom
            ? filter.StartDate
            : filter.StartDate || dataDateRange.startDate
        }
        dateTo={
          isOpenDateTo
            ? filter.EndDate
            : filter.EndDate || dataDateRange.endDate
        }
        limitDatepicker={dataDateRange}
        onChangeTab={onChangeTimeFilter}
        onChangePage={onChangePage}
        onDateFromChange={onChangeDateFrom}
        onDateToChange={onChangeDateTo}
        onOpenDateFrom={setIsOpenDateFrom}
        onOpenDateTo={setIsOpenDateTo}
      />
      <Table
        ids={ids}
        isLoading={isLoading}
        getDataFromRedux={selectDataOrderStatistic}
        schema={getTableSchema(filter.TimeFilter, locale)}
        stickies={{
          [priceDataTableCol.DATE]: true,
        }}
        sort={sort}
        defaultSort={{ tradingDateId: 'desc' }}
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
        handleSortField={onChangeSortField}
        reCalcWidths={reCalcWidths}
      />
    </>
  )
}

OrderStatistic.propTypes = {
  width: PropTypes.number.isRequired,
  tabType: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}
