import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class DefaultBondIdProxy extends ProxyBase {
  getDefaultBondIdProxy(params) {
    return this.get('GetDefaultBondId', params)
  }
}

export default new DefaultBondIdProxy(
  ServiceProxyConfig.Common.Master.ServiceUrl,
)
