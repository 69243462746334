import { withWrapper } from '../../../common/withWrapper'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { PanelAssetAllocation } from './assetAllocation/PanelAssetAllocation'
import { PanelAssetAllocationOverTime } from './assetAllocationOverTime/PanelAssetAllocationOverTime'
import { PanelPortfolioTable } from './portfolioTable/PanelPortfolioTable'

const mapKeys = [
  { key: 'ASSET_ALLOCATION', component: PanelAssetAllocation },
  { key: 'PORTFOLIO_TABLE', component: PanelPortfolioTable },
  {
    key: 'ASSET_ALLOCATION_OVER_TIME',
    component: PanelAssetAllocationOverTime,
  },
]

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return mapKeys.map((item) => {
    const Component = item.component
    return (
      <Component
        key={item.key}
        panelRefs={panelRefs}
        panelKey={item.key}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  })
}

export default withWrapper(PANEL_LAYOUTS[242](mapKeys), panelRefs)(Content)
