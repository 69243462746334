import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getValueAndGrowthChartData } from './thunk'

const initialState = {
  loading: true,
  data: [],
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/securities/panelBrokerageRevenueGrowth',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getValueAndGrowthChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValueAndGrowthChartData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload || []
    })
    builder.addCase(getValueAndGrowthChartData.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectValueAndGrowthChartData = (state) => state[slice.name].data

export const { resetStore } = slice.actions

register(slice.name, slice.reducer)
