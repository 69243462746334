import PropTypes from 'prop-types'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { VALUE_TIME_FRAME_OPTIONS } from '../constants'

const TimeFrame = ({ timeRange, onChangeTab, timeFrameOption, styleProps }) => {
  return (
    <div style={{ marginLeft: 8, ...styleProps }} className="d-flex">
      <DispatchActionTab
        data={timeFrameOption}
        activeTab={timeRange}
        onChangeTab={onChangeTab}
      />
    </div>
  )
}

TimeFrame.propTypes = {
  timeRange: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeTab: PropTypes.func,
  timeFrameOption: PropTypes.array,
  styleProps: PropTypes.object,
}

TimeFrame.defaultProps = {
  onChangeTab: () => {},
  timeFrameOption: VALUE_TIME_FRAME_OPTIONS,
  styleProps: {},
}

export default TimeFrame
