import moment from 'moment'
import { useSelector } from 'react-redux'
import { languageObjs } from '../../../configs/Language'
import { FORMAT } from './../../utils/Datetime'

export const placeholderDateCalendar = (locale) =>
  locale === 'en' ? 'mm/dd/yyyy' : 'dd/mm/yyyy'

export const useGetDayTitle = () => {
  const lang = useSelector((state) => state.i18n.locale)

  const isEn = lang === languageObjs.en

  return (day) => {
    return isEn ? enDays[day] : viDays[day]
  }
}

export const enDays = {
  Sunday: 'Sun',
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
}

export const viDays = {
  Sunday: 'CN',
  Monday: 'T2',
  Tuesday: 'T3',
  Wednesday: 'T4',
  Thursday: 'T5',
  Friday: 'T6',
  Saturday: 'T7',
}

export const useGetMonths = (isLowerCase, isThreeCharacter) => {
  const lang = useSelector((state) => state.i18n.locale)

  const isEn = lang === languageObjs.en

  if (isThreeCharacter) return isEn ? enMonthsThreeCharacter : viMonths
  if (isLowerCase) return isEn ? enMonthsLowerCase : viMonthsLowerCase
  else return isEn ? enMonths : viMonths
}

export const enMonthsThreeCharacter = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
]

export const enMonths = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
]

export const enMonthsLowerCase = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const viMonths = [
  'THÁNG 1',
  'THÁNG 2',
  'THÁNG 3',
  'THÁNG 4',
  'THÁNG 5',
  'THÁNG 6',
  'THÁNG 7',
  'THÁNG 8',
  'THÁNG 9',
  'THÁNG 10',
  'THÁNG 11',
  'THÁNG 12',
]

export const viMonthsLowerCase = [
  'Tháng 1',
  'Tháng 2',
  'Tháng 3',
  'Tháng 4',
  'Tháng 5',
  'Tháng 6',
  'Tháng 7',
  'Tháng 8',
  'Tháng 9',
  'Tháng 10',
  'Tháng 11',
  'Tháng 12',
]

export const getDateByLocale = (str, locale) => {
  let [month, day, year] = str.split('/').map(Number)
  if (locale === 'vi') [month, day] = [day, month]
  return new Date(year, month - 1, day)
}

export const convertDateFromDatePicker = (dates, format) => {
  const convertFunc = (date) => {
    if (!date) return date
    return moment(date)
      .format(format ?? FORMAT.DATE.en)
      .toString()
  }

  if (Array.isArray(dates)) {
    return dates.map(convertFunc)
  } else {
    return convertFunc(dates)
  }
}
