import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { formatChartData } from './helper'

const MAX_HEIGHT_CHART = 500

const PieChartComponent = ({
  data,
  height,
  datakey,
  dataName = 'ticker',
  top = 5,
  currentTicker,
  width,
}) => {
  const chartData = formatChartData({
    data,
    datakey,
    dataName,
    currentTicker,
    top,
  })

  const chartHeight = Math.min(height, MAX_HEIGHT_CHART)

  return (
    <PieChartWithFooter
      data={chartData}
      height={chartHeight}
      width={width}
      footerRight
      footerProps={{
        isOneColumn: true,
        appendStyle: { gridGap: 8 },
        itemStyle: { paddingRight: 8 },
      }}
    />
  )
}

export default PieChartComponent
