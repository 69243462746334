import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DangerouslySetInnerHTML } from '../../../../../common/html/DangerouslySetInnerHTML'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import { TITLE_FIRST, TITLE_SECOND, TITLE_THIRD } from './constants'
import style from './index.module.css'
import { selectData, selectLoading } from './store/slice'
import { getFisheryProfile } from './store/thunk'

export const Overview = () => {
  const dispatch = useDispatch()

  const data = useSelector(selectData)
  const isLoading = useSelector(selectLoading)
  const locale = useSelector((state) => state.i18n.locale)

  const OVERVIEW_CONTENT = [
    { title: TITLE_FIRST, content: data.history },
    { title: TITLE_SECOND, content: data.primaryProduct },
    { title: TITLE_THIRD, content: data.role },
  ]

  useEffect(() => {
    dispatch(getFisheryProfile({}))
  }, [locale])

  return isLoading ? (
    <Loading />
  ) : !data ? (
    <NoData />
  ) : (
    <ScrollComponent>
      {OVERVIEW_CONTENT.map((item) => (
        <div className={style.paragraph} key={OVERVIEW_CONTENT.indexOf(item)}>
          <TextEllipsis text={item.title} appendStyle={{ fontWeight: '600' }} />
          <DangerouslySetInnerHTML html={item.content} />
        </div>
      ))}
    </ScrollComponent>
  )
}
