import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'

const initialState = {
  dashBoardSelected: null,
  chartsSelected: [],
  chartReplaceChecked: {},
  dashboardSelectedDetail: null,
}

export const slice = createSlice({
  name: 'financialChart/dropdownMoreDashboard',
  initialState,
  reducers: {
    setDashBoardSelected: (state, action) => {
      state.dashBoardSelected = action.payload
    },
    setDashBoardSelectedDetail: (state, action) => {
      state.dashboardSelectedDetail = action.payload
    },
    setChartSelected: (state, action) => {
      state.chartsSelected = action.payload
    },
    setChartReplaceChecked: (state, action) => {
      state.chartReplaceChecked = action.payload
    },
  },
})

export const {
  setDashBoardSelected,
  setChartSelected,
  setChartReplaceChecked,
  setDashBoardSelectedDetail,
} = slice.actions

export const selectDashboardSelectedPopup = (state) =>
  state[slice.name].dashBoardSelected
export const selectChartSelected = (state) => state[slice.name].chartsSelected
export const selectChartReplaceChecked = (state) =>
  state[slice.name].chartReplaceChecked
export const selectDashboardSelectedDetailPopup = (state) =>
  state[slice.name].dashboardSelectedDetail

register(slice.name, slice.reducer)
