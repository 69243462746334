import { createAsyncThunk } from '@reduxjs/toolkit'
import SectorStatisticsOverviewService from '../../../../../../../core/services/sector/sectorStatistics/OverviewService'

export const pricePerformance = createAsyncThunk(
  'sector/sectorStatistics/overview/pricePerformance/tableSector/PRICE_PERFORMANCE',
  async (data, { rejectWithValue }) => {
    const response = await SectorStatisticsOverviewService.pricePerformance(
      data,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
