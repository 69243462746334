import moment from 'moment'
import { keyBy } from '../../../../../../utils/Common'
import { tableConstants, tableFormat } from './constants'

export const getDataTitle = (item) => {
  return item.realYear
}

export const formatTableData = (action) => {
  const groupColumns = [{ key: 'name', title: '' }]
  const valueArr = tableConstants.map((item) => ({ ...item }))
  const tempObj = {}

  action.payload.forEach((item) =>
    tempObj[`${item.realQuarter}-${item.realYear}`]
      ? (tempObj[`${item.realQuarter}-${item.realYear}`][item.indicator] =
          item.value)
      : (tempObj[`${item.realQuarter}-${item.realYear}`] = {
          realYear: item.realYear,
          realQuarter: item.realQuarter,
          [item.indicator]: item.value,
        }),
  )

  Object.values(tempObj)
    .sort((a, b) => {
      if (a.realQuarter && b.realQuarter) {
        return (
          moment().set('year', a.realYear).quarter(a.realQuarter).unix() -
          moment().set('year', b.realYear).quarter(b.realQuarter).unix()
        )
      } else {
        return (
          moment().set('year', a.realYear).unix() -
          moment().set('year', b.realYear).unix()
        )
      }
    })
    .forEach((item) => {
      const key = `column${item.realQuarter ? item.realQuarter + '/' : ''}${
        item.realYear
      }`

      groupColumns.push({
        key: key,
        title: getDataTitle(item),
      })

      valueArr.forEach((element) => {
        element[key] = item[element.id] / tableFormat[element.id]
      })
    })

  return { groupColumns, valueByIds: keyBy(valueArr, 'id') }
}
