import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import { getMinMonthYear, getValueMOM } from './store/slice'
import { downloadMoneySupplyOutstandingLoan } from './store/thunk'

const PanelMoneySupplyCredit = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const valueMOM = useSelector(getValueMOM)
  const { minYear, minMonth } = useSelector(getMinMonthYear)

  const downloadCsv = () => {
    return downloadMoneySupplyOutstandingLoan({
      SBVType: valueMOM,
      Year: minYear,
      Month: minMonth,
    })
  }

  return (
    <Panel
      title={'economy.monetary.monetary.MONEY_SUPPLY_CREDIT'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelMoneySupplyCredit
