import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Span } from '../../../../../common/html/Span'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { ChartColorComponent } from '../../../../common/menuChartSettings/components/chartColorComponent'
import {
  keyToolHeaderSettings,
  SET_AS_DEFAULT_SETTING_KEY,
} from '../../../constants'
import style from './style.module.css'
import Switch from './Switch'
import TitleChart from './TitleChart'
import XAxis from './XAxis'
import XAxisLegend from './XAxisLegend'
import YAxisLabel from './YAxisLabel'
import YAxisLegend from './YAxisLegend'
import YAxisPosition from './YAxisPosition'

export const SettingStyleChart = ({
  settings,
  isBySecurities,
  isSetAsDefault,
  isShowChart,
  changeSettings,
  changeIsSetAsDefault,
}) => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(!isShow)
  }

  const clickOutside = () => {
    setIsShow(false)
  }

  const handleChangeSetting = (settingKey, value) => {
    changeSettings({
      [settingKey]:
        settingKey === keyToolHeaderSettings.X_AXIS_ANGLE
          ? value
          : value || !settings[settingKey],
    })
  }

  const handleChangeSetAsDefault = () => {
    changeIsSetAsDefault && changeIsSetAsDefault()
  }

  const getMaxHeight = useMemo(() => {
    return (document.body.offsetHeight * 2) / 3
  }, [document.body.offsetHeight])

  return (
    <HandleClickOutside handleClickOutside={clickOutside}>
      <div className="hover-dropdown top-filter-search" style={{ width: 34 }}>
        <span
          onClick={onClick}
          className={[
            'cursor-pointer w-100',
            !isShowChart && style.pointerEventNone,
          ].join(' ')}
        >
          <i
            title={I18n.t('financialChart.GENERAL_SETTING')}
            className="icon-setting-bold"
            style={{ fontSize: 16 }}
          />
        </span>
        {isShow && (
          <div className={`dropdown-container ${style.dropdownContainer}`}>
            <ScrollComponent
              autoHeight
              autoHeightMax={getMaxHeight}
              bgColor="#D9D9D9"
            >
              <div
                style={{ paddingLeft: 16, paddingRight: 16, margin: '4px 0' }}
              >
                <TitleChart />
                <div className={style.lineVertical} />
                <XAxis
                  settings={settings}
                  isBySecurities={isBySecurities}
                  onChange={handleChangeSetting}
                />
                <div className={style.lineVertical} />
                <XAxisLegend
                  checked={settings[keyToolHeaderSettings.CHART_LEGEND_FOOTER]}
                  onChange={() =>
                    handleChangeSetting(
                      keyToolHeaderSettings.CHART_LEGEND_FOOTER,
                    )
                  }
                />
                <div className={style.lineVertical} />
                <YAxisPosition
                  checked={settings[keyToolHeaderSettings.GROUP_Y_AXIS]}
                  onChange={() =>
                    handleChangeSetting(keyToolHeaderSettings.GROUP_Y_AXIS)
                  }
                  positionGroupYAxis={
                    settings[keyToolHeaderSettings.POSITION_GROUP_Y_AXIS]
                  }
                  changePositionGroupYAxis={(val) =>
                    handleChangeSetting(
                      keyToolHeaderSettings.POSITION_GROUP_Y_AXIS,
                      val,
                    )
                  }
                />
                <div className={style.lineVertical} />
                <YAxisLabel
                  positionYAxisLabel={
                    settings[keyToolHeaderSettings.POSITION_Y_AXIS_LABEL]
                  }
                  changePositionYAxisLabel={(val) =>
                    handleChangeSetting(
                      keyToolHeaderSettings.POSITION_Y_AXIS_LABEL,
                      val,
                    )
                  }
                />
                <div className={style.lineVertical} />
                <YAxisLegend
                  checked={settings[keyToolHeaderSettings.CHART_LEGEND]}
                  onChange={() =>
                    handleChangeSetting(keyToolHeaderSettings.CHART_LEGEND)
                  }
                  legendXAxis={settings[keyToolHeaderSettings.Y_AXIS_LEGEND]}
                  changeLegendXAxis={(val) =>
                    handleChangeSetting(
                      keyToolHeaderSettings.Y_AXIS_LEGEND,
                      val,
                    )
                  }
                />
                <div className={style.lineVertical} />
                <div style={{ paddingTop: 5 }}>
                  <div style={{ marginBottom: 6 }}>
                    <Translate
                      style={{ fontWeight: 500 }}
                      value="financialChart.SET_DEFAULT_COLOR"
                    />
                  </div>
                  <ChartColorComponent
                    isHideTitle
                    isHideGradient
                    value="#ffffff"
                  />
                </div>
                <div className={style.lineVertical} />
                <div
                  className="d-flex ali-center justify-content-space-between"
                  style={{ margin: '10px 0px' }}
                >
                  <label className="cus--chec">
                    <Span style={{ fontSize: 12 }}>
                      <Translate value="financialChart.setting.GRID_DISPLAY" />
                    </Span>
                  </label>
                  <Switch
                    checked={settings[keyToolHeaderSettings.GRID_DISPLAY]}
                    onChange={() =>
                      handleChangeSetting(keyToolHeaderSettings.GRID_DISPLAY)
                    }
                  />
                </div>
                <div className={style.lineVertical} />
                <div
                  className="d-flex ali-center justify-content-space-between"
                  style={{ margin: '10px 0px' }}
                >
                  <label
                    htmlFor={SET_AS_DEFAULT_SETTING_KEY}
                    className="cus--chec"
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value="financialChart.setting.SET_AS_DEFAULT_SETTING" />
                    </Span>
                  </label>
                  <Switch
                    checked={isSetAsDefault}
                    onChange={handleChangeSetAsDefault}
                  />
                </div>
              </div>
            </ScrollComponent>
          </div>
        )}
      </div>
    </HandleClickOutside>
  )
}

SettingStyleChart.propTypes = {
  settings: PropTypes.object,
  isBySecurities: PropTypes.bool,
  isSetAsDefault: PropTypes.bool,
  changeSettings: PropTypes.func,
  changeIsSetAsDefault: PropTypes.func,
  isShowChart: PropTypes.bool,
}

SettingStyleChart.defaultProps = {
  settings: {},
  isBySecurities: false,
  isSetAsDefault: false,
  changeSettings: () => {},
  changeIsSetAsDefault: () => {},
}
