export const keyTableResult = {
  NO: '0',
  TICKER: '1',
  COMPANY_NAME: '2',
  EXCHANGE: '3',
  SECTOR_L2: '4',
}

export const initKeyTable = {
  0: '0',
  1: '1',
  2: '2',
  3: '3',
  4: '4',
}

export const maxTemplate = 100

export const maxCondition = 100

export const maxResult = 300

export const typeData = {
  DAILY: 'Daily',
  TTM: 'TTM',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}

export const selectionType = {
  FROM_TO: 'FromTo',
  RADIO_BUTTON: 'RadioButton',
}

export const unitType = {
  PERCENT: '%',
}

export const blankValue = ''

export const exceptGroupIdCheckHOSE = [4, 5, 6]
