import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { removeAccents } from '../../../../utils/Search'
import DropdownLocaleName from './DropdownLocaleName'
import style from './index.module.css'
import { getIdWithTitles, getKeySearch, handleKeySearch } from './store/slice'

const SearchBank = () => {
  const dispatch = useDispatch()

  const inputRef = useRef()
  const search = UseI18n('economy.monetary.monetary.BANK')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()
  const exceptOutsideRef = useRef()

  const keySearch = useSelector(getKeySearch)
  const idWithTitle = useSelector(getIdWithTitles)

  const titleWithoutAccents = Object.keys(idWithTitle).map((item) => ({
    id: item,
    bankNameWithoutAccent: removeAccents(idWithTitle[item]),
    title: idWithTitle[item],
  }))

  const handleChangeSearchInput = (e) => {
    dispatch(handleKeySearch(e.target.value))
  }

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const filterListBank = (list) => {
    const keySearchWithoutAccent = removeAccents(keySearch)
    return list.filter((item) =>
      item.bankNameWithoutAccent
        ?.toLowerCase()
        .includes(keySearchWithoutAccent.toLowerCase()),
    )
  }

  return (
    <div className={`form-search-inbox m-0 ${style.searchInput} `}>
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        ref={inputRef}
        value={keySearch}
        onChange={handleChangeSearchInput}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        type="text"
        placeholder={search}
        className={`search-inbox `}
      />
      <button type="button" style={{ color: '#ffffff66' }}>
        <i className="icon-search-2" />
      </button>
      <DropdownLocaleName
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listBank={filterListBank(titleWithoutAccents)}
        eventPressKey={eventPressKey}
      />
    </div>
  )
}

export default SearchBank
