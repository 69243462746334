import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import { Icon } from '../../../../common/html/Icon'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../common/popup/PopupAlert'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  FORMAT,
  formatDateTime,
  getDateTimeFrom,
} from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { renderCouponName } from '../../primaryMarket/marketStatistics/helper'
import { RADIO_TYPES } from './constants'
import style from './index.module.css'
import {
  changeBondDetail,
  changeItemSelected,
  changeModalData,
  resetModalData,
  selectBondDetailSelected,
  selectBondInfo,
  selectFilterPriceBoard,
  selectItemSelected,
  selectModalData,
} from './store/slice'
import { getBondCashFlowScenarioData, getPriceYtmData } from './store/thunk'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/

const PopupDetail = () => {
  const dispatch = useDispatch()

  const item = useSelector(selectItemSelected)
  const data = useSelector(selectBondDetailSelected)
  const {
    tradingDate,
    paymentDate,
    totalParValue,
    volume,
    ytm,
    dirtyPrice,
    cleanPrice,
  } = useSelector(selectModalData)
  const locale = useSelector((state) => state.i18n.locale)
  const { isCleanPrice } = useSelector(selectFilterPriceBoard)
  const bondInfo = useSelector(selectBondInfo)

  const timeZone = UseTimeZone()

  const [value, setValue] = useState('')
  const [isShow, setIsShow] = useState('')
  const [check, setCheck] = useState(1)

  const handleCancel = () => {
    dispatch(changeItemSelected(null))
    dispatch(changeBondDetail(null))
    dispatch(resetModalData())
  }

  const handleChangeCoupon = (coupon) => {
    setValue(!!coupon.length ? (!isNaN(coupon) ? coupon : value) : '')
  }

  const handleChangeVolume = (value) => {
    const newVolume = !!value.length ? (!isNaN(value) ? value : volume) : ''

    dispatch(
      changeModalData({
        key: 'volume',
        value:
          parseFloat(newVolume) < 1
            ? '1'
            : parseFloat(newVolume) > 1000000
            ? '1000000'
            : newVolume,
      }),
    )
  }

  const handleChangeYtm = (value) => {
    if (!value?.length || value.match(regexValue)) {
      dispatch(
        changeModalData({
          key: 'ytm',
          value: value,
        }),
      )
    }
  }

  const handleChangeDirtyPrice = (value) => {
    if (!value?.length || value.match(regexValue)) {
      dispatch(
        changeModalData({
          key: 'dirtyPrice',
          value: value,
        }),
      )
    }
  }

  const handleChangeCleanPrice = (value) => {
    if (!value?.length || value.match(regexValue)) {
      dispatch(
        changeModalData({
          key: 'cleanPrice',
          value: value,
        }),
      )
    }
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE, undefined, timeZone))
  }

  const handleGetBondCashFlow = (coupon, date) => {
    const payload = {
      BondIds: item.id,
      TradingDateId: date,
      InterestBasisTypeId: 1,
      InterestRate: coupon,
    }

    dispatch(getBondCashFlowScenarioData(payload))
  }

  const handleBlurVolume = () => {
    dispatch(
      changeModalData({
        key: 'volume',
        value: !isNaN(volume) ? formatVal(volume, 10, false, '', 0) : '',
      }),
    )

    dispatch(
      changeModalData({
        key: 'totalParValue',
        value: !!volume.length
          ? (item.parValue * parseFloat(volume.replace(/,/g, ''))).toString()
          : '',
      }),
    )
  }

  const handleFocusYtm = () => {
    dispatch(
      changeModalData({
        key: 'ytm',
        value: ytm.replace(/,/g, ''),
      }),
    )
  }

  const handleFocusDirtyPrice = () => {
    dispatch(
      changeModalData({
        key: 'dirtyPrice',
        value: dirtyPrice.replace(/,/g, ''),
      }),
    )
  }
  const handleFocusCleanPrice = () => {
    dispatch(
      changeModalData({
        key: 'cleanPrice',
        value: cleanPrice.replace(/,/g, ''),
      }),
    )
  }

  const handleBlurPrice = () => {
    if (
      (check === RADIO_TYPES.YTM && !ytm.length) ||
      (check === RADIO_TYPES.DIRTY_PRICE && !dirtyPrice.length) ||
      (check === RADIO_TYPES.CLEAN_PRICE && !cleanPrice.length)
    ) {
      return
    }

    const payload = {
      lstBondCashFlow: bondInfo.map((item) => ({
        settlementdate: item.tradingDateId,
        coupon:
          item.bondPaymentTypeId === 1
            ? parseFloat(item?.couponInterestRate || 0) / 100
            : 0,
        frequency: data?.paymentCalendarMonth ?? 0,
        paymentStatusId: item.paymentStatusId,
      })),
      issueDate: tradingDate,
      weigh: 0,
      paydate: paymentDate,
      interestBasisTypeId: 1,
      price:
        check !== RADIO_TYPES.YTM
          ? parseFloat(
              check === RADIO_TYPES.CLEAN_PRICE ? cleanPrice : dirtyPrice,
            )
          : 0,
      ytm:
        check === RADIO_TYPES.YTM
          ? parseFloat(parseFloat(ytm).toFixed(2)) / 100
          : 0,
      parValue: item.parValue,
      type: check,
    }

    dispatch(getPriceYtmData(payload))
  }

  const handleChangeTradingDate = (date) => {
    const paymentDate = moment(
      getDateTimeFrom(date, +1, 'day', FORMAT.DATE),
      'MM/DD/YYYY',
    ).unix()

    const issueDate = moment(
      moment(data?.issueDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()
    const maturityDate = moment(
      moment(data?.maturityDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()

    if (paymentDate >= issueDate && paymentDate <= maturityDate) {
      dispatch(
        changeModalData({
          key: 'tradingDate',
          value: date,
        }),
      )

      dispatch(
        changeModalData({
          key: 'paymentDate',
          value: getDateTimeFrom(date, +1, 'day', FORMAT.DATE),
        }),
      )

      handleGetBondCashFlow(
        parseFloat(value),
        moment(date).format(FORMAT.YEAR_MONTH_DAY[locale]),
      )

      return
    }

    setIsShow('bond.valuation.sameIndusTryBond.contentCheckPaymentDate')
  }

  const handleChangePaymentDate = (date) => {
    const paymentDate = moment(date, 'MM/DD/YYYY').unix()

    const issueDate = moment(
      moment(data?.issueDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()
    const maturityDate = moment(
      moment(data?.maturityDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()

    if (paymentDate >= issueDate && paymentDate <= maturityDate) {
      dispatch(
        changeModalData({
          key: 'tradingDate',
          value: getDateTimeFrom(date, -1, 'day', FORMAT.DATE),
        }),
      )

      dispatch(
        changeModalData({
          key: 'paymentDate',
          value: date,
        }),
      )

      handleGetBondCashFlow(
        parseFloat(value),
        moment(getDateTimeFrom(date, -1, 'day', FORMAT.DATE)).format(
          FORMAT.YEAR_MONTH_DAY[locale],
        ),
      )

      return
    }

    setIsShow('bond.valuation.sameIndusTryBond.contentCheckPaymentDate')
  }

  useEffect(() => {
    if (data?.couponInterestRate && item) {
      setValue(formatVal(data.couponInterestRate))
      handleGetBondCashFlow(
        data.couponInterestRate,
        moment(tradingDate).format(FORMAT.YEAR_MONTH_DAY[locale]),
      )
    }
  }, [locale, data?.couponInterestRate, item])

  useEffect(() => {
    if (item) {
      dispatch(
        changeModalData({
          key: isCleanPrice ? 'cleanPrice' : 'dirtyPrice',
          value: item.price,
        }),
      )
      setCheck(isCleanPrice ? 3 : 2)
    }
  }, [item, isCleanPrice])

  return (
    <>
      <Popup>
        <div className={`modal ${style.fitContent}`}>
          <div className="modal-title">
            <h3>
              <Span>{item?.ticker ?? ''}</Span>
            </h3>
            <a className="close-modal" onClick={handleCancel}>
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div
            className={`modal-content ${style.modalBody}`}
            style={{
              paddingBottom: 0,
              overflow: 'auto',
            }}
          >
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.ISSUER" />
              </Span>
              <div className={style.modalContent}>
                {data?.organizationShortName ? (
                  <a
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsShow('common.FUNCTION_COMING_SOON')
                    }}
                  >
                    <TextEllipsis
                      text={data?.organizationShortName}
                      isI18n={false}
                    />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.INDUSTRY" />
              </Span>
              <div className={style.modalContent}>
                {data?.icbName ? (
                  <a
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsShow('common.FUNCTION_COMING_SOON')
                    }}
                  >
                    <TextEllipsis text={data?.icbName} isI18n={false} />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.ISSUE_DATE" />
              </Span>
              <div className={style.modalContent}>
                {data?.issueDateId ? (
                  <DateTimeSpan date={data?.issueDateId} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.DUE_DATE" />
              </Span>
              <div className={style.modalContent}>
                {data?.maturityDateId ? (
                  <DateTimeSpan date={data?.maturityDateId} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.INTEREST_PAYMENT_PERIOD" />
              </Span>
              <div className={style.modalContent}>
                {data?.paymentCalendarMonth ?? ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.CREDIT_EVENT" />
              </Span>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.DURATION" />
              </Span>
              <div className={style.modalContent}>
                {data?.durationName ?? ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.COUPON_TYPE" />
              </Span>
              <div className={style.modalContent} style={{ color: '#FF4752' }}>
                {data?.bondEventTypeName ?? ''}
              </div>
              <div
                className={style.modalContent}
                style={{
                  color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
                }}
              >
                {renderCouponName(
                  data?.couponTypeId ?? data?.couponTypeName ?? '',
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 10 }}>
              <Span style={{ fontStyle: 'italic', marginLeft: 16, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.EXPECTED_COUPON" />
              </Span>
              <div className={style.modalContent}>
                <div className="d-flex ali-center">
                  {data?.couponTypeId === 1 ? (
                    <div style={{ width: 42 }}>
                      <Input
                        onChange={(e) =>
                          handleChangeCoupon(e?.target?.value ?? '')
                        }
                        onBlur={() => {
                          handleGetBondCashFlow(
                            parseFloat(value),
                            moment(tradingDate).format(
                              FORMAT.YEAR_MONTH_DAY[locale],
                            ),
                          )

                          setValue(
                            !isNaN(value)
                              ? formatVal(value, 10, false, '', 0)
                              : '',
                          )

                          // dispatch(
                          //   changeCouponByBondId({
                          //     id: item.id,
                          //     couponValue: !isNaN(value)
                          //       ? parseFloat(value)
                          //       : '',
                          //   }),
                          // )
                        }}
                        onFocus={() => {
                          setValue(
                            !!value?.length ? value.replace(/,/g, '') : '',
                          )
                        }}
                        type="text"
                        className={style.input}
                        value={value}
                        style={{ textAlign: 'right' }}
                      />
                    </div>
                  ) : (
                    <Span>
                      {valToPercent(
                        data?.couponInterestRate,
                        true,
                        undefined,
                        2,
                      )}
                    </Span>
                  )}
                  {data?.floatInterestSpread > 0 && (
                    <Span style={{ marginLeft: 8 }}>
                      +{valToPercent(data?.floatInterestSpread)}
                    </Span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontStyle: 'italic', marginLeft: 16, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.COUPON_BENCHMARK" />
              </Span>
              {data?.floatInterestBenchmarkDescription && (
                <div
                  className={style.modalContent}
                  style={{
                    color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
                  }}
                >
                  <TextEllipsis
                    text={data?.floatInterestBenchmarkDescription}
                  />
                </div>
              )}
            </div>
            <div
              className="d-flex ali-center"
              style={{
                marginBottom: 16,
                color: '#FF4752',
                fontStyle: 'italic',
              }}
            >
              <Span>
                <Translate value="bond.corporateBond.priceBoard.CAUTION" />
              </Span>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.TRADING_DATE" />
              </Span>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        tradingDate ? getDateWithTimeZone(tradingDate) : null
                      }
                      handleChange={handleChangeTradingDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      popperClassName={style.popperClassName}
                    />
                  </div>
                  <Icon className={`icon-date`} style={{ fontSize: 12 }} />
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.PAYMENT_DATE" />
              </Span>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        paymentDate ? getDateWithTimeZone(paymentDate) : null
                      }
                      handleChange={handleChangePaymentDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      popperClassName={style.popperClassName}
                    />
                  </div>
                  <Icon
                    className={`icon-date ${style.eventNone}`}
                    style={{ fontSize: 12 }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.EXRIGHT_DATE" />
              </Span>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        data?.exPaymentDateId
                          ? getDateWithTimeZone(data?.exPaymentDateId)
                          : null
                      }
                      handleChange={handleChangeTradingDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      disabled
                    />
                  </div>
                  <Icon
                    className={`icon-date ${style.eventNone}`}
                    style={{ fontSize: 12 }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.VOLUME" />
              </Span>
              <div className={style.modalContent}>
                <Input
                  onChange={(e) => handleChangeVolume(e?.target?.value ?? '')}
                  onBlur={handleBlurVolume}
                  onFocus={() => {
                    dispatch(
                      changeModalData({
                        key: 'volume',
                        value: !!volume?.length ? volume.replace(/,/g, '') : '',
                      }),
                    )
                  }}
                  type="text"
                  className={style.input}
                  value={volume}
                  style={{ textAlign: 'right' }}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER_2',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.TOTAL_PAR_VALUE" />
              </Span>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                {!!totalParValue.length
                  ? formatVal(totalParValue, 10, false, '', 0)
                  : ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <div
                className={`d-flex align-center ${style.wrapRadio}
                  `}
                style={{ flex: 1 }}
                onClick={() => setCheck(1)}
              >
                <input
                  style={{ marginTop: 1.5 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.YTM}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.YIELD_TO_MATURITY" />
                </Span>
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) => handleChangeYtm(e?.target?.value ?? '')}
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusYtm}
                  type="text"
                  className={style.input}
                  value={ytm}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.YTM}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <div
                className={`d-flex align-center ${style.wrapRadio}
                  `}
                style={{ flex: 1 }}
                onClick={() => setCheck(2)}
              >
                <input
                  style={{ marginTop: 1.5 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.DIRTY_PRICE}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.DIRTY" />
                </Span>
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) =>
                    handleChangeDirtyPrice(e?.target?.value ?? '')
                  }
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusDirtyPrice}
                  type="text"
                  className={style.input}
                  value={dirtyPrice}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.DIRTY_PRICE}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center">
              <div
                className={`d-flex align-center ${style.wrapRadio}
                  `}
                style={{ flex: 1 }}
                onClick={() => setCheck(3)}
              >
                <input
                  style={{ marginTop: 1.5 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.CLEAN_PRICE}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.CLEAN" />
                </Span>
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) =>
                    handleChangeCleanPrice(e?.target?.value ?? '')
                  }
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusCleanPrice}
                  type="text"
                  className={style.input}
                  value={cleanPrice}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.CLEAN_PRICE}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ padding: 16 }}>
            <Button
              className={`btn btn-blue w-80`}
              onClick={handleCancel}
              style={{ height: 24 }}
            >
              <Translate value="common.button.BUTTON_CLOSE" />
            </Button>
          </div>
        </div>
      </Popup>
      <PopupAlert
        message={I18n.t(isShow)}
        isShow={!!isShow.length}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShow('')}
      />
    </>
  )
}

export default PopupDetail
