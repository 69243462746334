export const TIME_FRAME_NET_VALUE = {
  ONE_MONTH: {
    title: '1M',
    value: 'OneMonth',
  },
  THREE_MONTH: {
    title: '3M',
    value: 'ThreeMonths',
  },
  SIX_MONTH: {
    title: '6M',
    value: 'SixMonths',
  },
  ONE_YEAR: {
    title: '1Y',
    value: 'OneYear',
  },
  THREE_YEAR: {
    title: '3Y',
    value: 'ThreeYears',
  },
  FIVE_YEAR: {
    title: '5Y',
    value: 'FiveYears',
  },
}
