import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CalendarPopper from '../../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../../common/calendar/headers/HeaderOneCalendarRange'
import { placeholderDateCalendar } from '../../../../../common/calendar/helper'
import InputDateCalendar from '../../../../../common/calendar/inputs/InputDateCalendar'
import { positionDropdown } from '../../../../../common/dropdown'
import style from '../../index.module.css'
import { FILTER_SCROLL_ID } from '../index'

const DailyPoint = ({ disabled, value, onChange }) => {
  const locale = useSelector((state) => state.i18n.locale)

  const handleChange = (value) => onChange(value || null)

  return (
    <div className="mt-8">
      <div
        className={`h-20 d-flex ali-center w-100 ${style.calendar}`}
        style={{ pointerEvents: disabled ? 'none' : '' }}
      >
        <CalendarPopper
          date={value ? new Date(value) : ''}
          handleChange={handleChange}
          customHeader={HeaderOneCalendarRange({
            startYear: 2000,
            endYear: new Date().getFullYear(),
            position: positionDropdown.TOP_CENTER,
            marginTop: 1,
            maxHeightDropdown: 160,
          })}
          CustomInput={InputDateCalendar}
          placeholder={placeholderDateCalendar(locale)}
          customInputStyle={{ fontSize: 10 }}
          scrollIds={[FILTER_SCROLL_ID]}
        />
        <i className="icon-date" style={{ pointerEvents: 'none' }} />
      </div>
    </div>
  )
}

CalendarPopper.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  handleChange: PropTypes.func,
}

CalendarPopper.defaultProps = {
  disabled: false,
  handleChange: () => {},
}

export default DailyPoint
