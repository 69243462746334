import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import InputFormatCurrency from '../../../../common/input/InputFormatCurrency'
import style from './index.module.css'

const InputTd = ({
  initialValue,
  handleSubmit,
  fractionDigits,
  decimalRender,
  regex,
  rowId,
  suffix,
}) => {
  const inputRef = useRef()

  const [value, setValue] = useState('')

  useEffect(() => {
    if (!isNaN(initialValue)) {
      setValue(initialValue)
    }
  }, [initialValue])

  return (
    <div ref={inputRef}>
      <HandleClickOutside
        handleClickOutside={() => handleSubmit(value, rowId)}
        exceptRef={inputRef}
      >
        <div className="form-control mb-0">
          <div className={`${style.bgGrey} w-100`}>
            <InputFormatCurrency
              handleBlur={(value) => handleSubmit(value, rowId)}
              defaultValue={initialValue ?? null}
              fractionDigits={fractionDigits}
              style={{ textAlign: 'right' }}
              decimalRender={decimalRender}
              regexValueCustom={regex}
              handleChange={(value) => setValue(value)}
              suffix={suffix}
              isEnter
            />
          </div>
        </div>
      </HandleClickOutside>
    </div>
  )
}

InputFormatCurrency.propTypes = {
  initialValue: PropTypes.number,
  handleSubmit: PropTypes.func,
  fractionDigits: PropTypes.number,
  suffix: PropTypes.string,
}

InputFormatCurrency.defaultProps = {
  fractionDigits: 2,
  decimalRender: 2,
}

export default InputTd
