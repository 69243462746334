import { createAsyncThunk } from '@reduxjs/toolkit'
import PriceDataService from '../../../../../core/services/common/PriceDataService'

export const getLatestPrice = createAsyncThunk(
  'common/fundTopInfo/GET_LATEST_PRICE',
  async (params, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
