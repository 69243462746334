export const dailyReport = {
  TEMPLATE: 'Template',
  SETTING: 'Template setting ',
  SAVE: 'Save',
  SAVE_AS: 'Save as',
  EXPORT: 'Export',
  COMPANY_SELECTED: 'Company selected',
  COMPANY: 'Company',
  MARKET_NEWS: 'Market news',
  CORPORATE_NEWS: 'Corporate news',
  TICKER: 'Ticker',
  GROUP_STOCK: 'Group stock',
  RESET: 'Reset',
  SAVE_AS_TEMPLATE: 'Save as',
}
