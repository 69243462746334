import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { handleLimitDate } from '../panelDepositRateVietNam/helper'
import style from './index.module.css'
import { getDateHasData, getLimitTime, handleDate } from './store/slice'

const SelectDate = () => {
  const dispatch = useDispatch()
  const [isOpenDate, setIsOpenDate] = useState(false)
  const currentDate = [new Date()]
  const currentYear = new Date().getFullYear()

  const timeZone = UseTimeZone()
  const dateHasData = useSelector(getDateHasData)
  const limitTime = useSelector(getLimitTime)
  const limitDatepicker = handleLimitDate(limitTime, timeZone)

  const onDateChange = (date) => {
    dispatch(handleDate(date))
  }

  const onDateFromClose = () => {
    setIsOpenDate(false)
  }

  const onClickDateFrom = () => {
    setIsOpenDate(true)
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  return (
    <div className="form-date-select">
      <Span style={{ fontSize: 12 }}>
        <Translate value="economy.monetary.monetary.DATE" />
      </Span>
      <div className="date-select">
        <CalendarPopper
          date={dateHasData ? getDateWithTimeZone(dateHasData) : ''}
          minDate={getDateWithTimeZone(limitDatepicker.minDate)}
          maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
          handleChange={onDateChange}
          CustomInput={InputDateCalendar}
          hightlightDates={currentDate}
          customHeader={HeaderCalendarPopper({
            startYear: limitDatepicker.minYear || currentYear - 10,
            endYear: limitDatepicker.maxYear || currentYear,
            zIndex: 11,
          })}
          isOpen={isOpenDate}
          onClose={onDateFromClose}
        />
        <span
          className={`btn-select-date ${style.iconCaret}`}
          onClick={onClickDateFrom}
        >
          <i className="icon-caret-down" />
        </span>
      </div>
    </div>
  )
}

export default SelectDate
