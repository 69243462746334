import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import TopLabel from '../../common/TopLabel'
import useChartAPI from '../../common/useChartAPI'
import { CHART_ORDER } from '../../constants'
import { BOTTOM_CHART_ID } from '../../panelChartBottom/PanelChartBottom'
import { TOP_CHART_ID } from '../../panelChartTop/PanelChartTop'
import {
  selectIncomeStructure,
  selectIncomeStructureLoading,
} from '../../store/slice'
import ChartComponent from './ChartComponent'

const BarPointChart = ({ width, height, chartType }) => {
  const { CHART_1, CHART_2 } = CHART_ORDER

  const incomeStructureData = useSelector(selectIncomeStructure)
  const incomeStructureLoading = useSelector(selectIncomeStructureLoading)

  const [data, loading] = useChartAPI(chartType)

  return (
    <div
      className="h-100"
      id={chartType === CHART_1 ? TOP_CHART_ID : BOTTOM_CHART_ID}
    >
      {(loading || incomeStructureLoading) && <Loading />}
      {!loading && !incomeStructureLoading && (
        <>
          {(data?.length === 0 || incomeStructureData.length === 0) && (
            <NoData />
          )}
          {data?.length > 0 && incomeStructureData.length > 0 && (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <TopLabel
                      isChart1={chartType === CHART_1}
                      isBarPointChart
                    />
                    {(size.height || size.height === 0) && (
                      <ChartComponent
                        width={width}
                        height={height - size.height}
                        data={data}
                        keyXAxis={chartType === CHART_1 ? 'year' : 'ticker'}
                        lineKey={chartType === CHART_1 ? 'value2' : ''}
                        pointKey={chartType === CHART_2 ? 'value2' : ''}
                        barKey={'value1'}
                        chartType={chartType}
                      />
                    )}
                  </>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

BarPointChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  loading: PropTypes.bool,
  data: PropTypes.array,
}

export default BarPointChart
