import UseI18n from '../../../../../common/hooks/useI18n'
import { MONTH_LIST_FILTER_EN } from '../tableStatistics/constants'
import { getMonthKey } from '../tableStatistics/helper'

export const getChartNameByLocale = (
  locale,
  isYearly,
  isAcc,
  currentCategoryName,
  currentMonth,
  currentYear,
) => {
  let chartName = ''

  const currentMonthEN = MONTH_LIST_FILTER_EN.find(
    (item) => item.value === currentMonth,
  )?.name

  if (locale === 'en') {
    chartName = isYearly
      ? `${UseI18n(
          'sector.sectorSpecific.steel.PEERS_COMPARISON',
        )} of ${UseI18n(currentCategoryName)} ${currentYear}`
      : isAcc
      ? `${UseI18n(
          'sector.sectorSpecific.steel.PEERS_COMPARISON',
        )} of ${UseI18n(currentCategoryName)} ${currentMonth}M-${currentYear}`
      : `${UseI18n(
          'sector.sectorSpecific.steel.PEERS_COMPARISON',
        )} of ${UseI18n(currentCategoryName)} ${currentMonthEN}-${currentYear}`
  } else {
    chartName = isYearly
      ? `${UseI18n('sector.sectorSpecific.steel.PEERS_COMPARISON')} ${UseI18n(
          currentCategoryName,
        )} ${currentYear}`
      : isAcc
      ? `${UseI18n('sector.sectorSpecific.steel.PEERS_COMPARISON')} ${UseI18n(
          currentCategoryName,
        )} ${currentMonth}Th-${currentYear}`
      : `${UseI18n('sector.sectorSpecific.steel.PEERS_COMPARISON')} ${UseI18n(
          currentCategoryName,
        )} Th${getMonthKey(currentMonth)}-${currentYear}`
  }
  return chartName
}
