import moment from 'moment'
import { MARGIN } from '../../../common/chart/constants'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../common/chart/helper'
import { keyBy } from '../../../utils/Common'
import { valToPercent } from '../../../utils/Value'
import { AUDIT_CONSTANTS, AUDIT_DATA } from './constants'
import { TIME_FILTER_VALUE, VIEW_BY } from './filter/constants'

const formatFieldName = (fieldName) => {
  const isRtd = fieldName.indexOf('RTD') !== -1
  return fieldName
    .split('')
    .map((char, index) => {
      if (index === 0 || (index === 1 && isRtd)) {
        return char.toLowerCase()
      } else {
        return char
      }
    })
    .join('')
}

export const getDataTitle = (timeFilter, item, separator = '/') => {
  switch (timeFilter) {
    case TIME_FILTER_VALUE.YEARLY:
      return item.year
    case TIME_FILTER_VALUE.QUARTERLY:
      return `Q${item.quarter < 5 ? item.quarter : 4}${separator}${item.year}`
    case TIME_FILTER_VALUE.NINE_MONTHS:
      return `9M${separator}${item.year}`
    case TIME_FILTER_VALUE.SIX_MONTHS:
      return `6M${separator}${item.year}`
    default:
      return item.year
  }
}

export const formatFinancialData = (state, action) => {
  state.loading = false
  const groupColumns = []
  const financialArr = Object.values(state.financialById)
  const timeFilter = action.meta.arg.TimeFilter
  const commonSize = action.meta.arg.CommonSize === 'true'
  const quarterly = VIEW_BY[1].value
  action.payload
    .sort((a, b) => {
      if (a.quarter && b.quarter) {
        return (
          moment().set('year', b.year).quarter(b.quarter).unix() -
          moment().set('year', a.year).quarter(a.quarter).unix()
        )
      } else {
        return (
          moment().set('year', b.year).unix() -
          moment().set('year', a.year).unix()
        )
      }
    })
    .filter((_, index) => index < action.meta.arg.NumberOfPeriod)
    .reverse()
    .forEach((item) => {
      groupColumns.push({
        key:
          timeFilter === quarterly
            ? `columnQ${item.quarter}/${item.year}`
            : `column${item.year}`,
        title: getDataTitle(timeFilter, item),
        year: item.year,
        quarter: item.quarter,
      })
      financialArr.forEach((element) => {
        const key =
          timeFilter === quarterly
            ? `columnQ${item.quarter}/${item.year}`
            : `column${item.year}`
        if (Object.values(AUDIT_CONSTANTS).includes(element.index)) {
          element[key] = item[element.key] || item[element.en_key]
        } else if (element.fixUnit) {
          element[key] = item[element.key]
        } else {
          if (commonSize) {
            element[key] =
              element.isFormatCommonValToPercent && commonSize
                ? valToPercent(
                    item[element.key] / element.isFormatValue.formatCommonValue,
                    true,
                  )
                : item[element.key]
          } else {
            element[key] = element.isFormatValToPercent
              ? valToPercent(
                  item[element.key] / element.isFormatValue.formatCommonValue,
                  true,
                )
              : item[element.key]
          }
        }
      })
    })
  state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
}

const formatIndicatorItem = (indicators, indicator, parent = { level: 0 }) => {
  const key = formatFieldName(indicator.fieldName)
  const isFormatValToPercent = indicator.formating.includes('%')
  const isFormatCommonValToPercent = indicator.formatingCommonSize.includes('%')
  const childrenId = indicators
    .filter((v) => v.parentField === indicator.fieldName)
    .map((v) => v.indicatorMappingId)
  const hasChild = childrenId.length !== 0
  const childTable = {
    index: indicator.indicatorMappingId,
    parentIndex: parent.index,
    parentId: parent.index,
    childrenId: childrenId,
    name: indicator.indicator,
    key: key,
    isFormatValue: {
      formatValue: 1 / indicator.multiplier,
      formatCommonValue: indicator.multiplierCommonSize * 0.01,
    },
    level: parent.level + 1,
    isFormatValToPercent: isFormatValToPercent,
    isFormatCommonValToPercent: isFormatCommonValToPercent,
    isCollapse: hasChild,
    style: indicator.style || '',
    fixUnit: indicator.fixUnit || false,
  }
  const childItem = hasChild
    ? getChild(indicators, childTable, indicator.fieldName)
    : []
  return [childTable, ...childItem]
}

const getChild = (indicators, parent, parentField) => {
  let childTableArr = []
  indicators.forEach((indicator) => {
    if (indicator.parentField === parentField) {
      const child = formatIndicatorItem(indicators, indicator, parent)
      childTableArr = [...childTableArr, ...child]
    }
  })
  return childTableArr
}

const formatIndicatorMapping = (indicators) => {
  let ids = []
  let tableConstants = []
  indicators
    .filter((indicator) => !indicator.parentField)
    .forEach((indicator) => {
      const child = formatIndicatorItem(indicators, indicator)
      ids = [...ids, ...child.map((v) => v.index)]
      tableConstants = [...tableConstants, ...child]
    })
  return { ids, tableConstants }
}

export const setFormatIndicatorMapping = (state) => {
  const { ids, tableConstants } = formatIndicatorMapping(
    state.indicatorMappingData,
  )
  // const auditStatus = {
  //   index: 'audit_status',
  //   name: 'corporate.financialData.financialStatement.AUDIT_STATUS',
  //   key: 'auditStatus',
  //   en_key: 'en_AuditStatus',
  //   level: 0,
  //   style: '',
  // }
  state.tableConstants = [...AUDIT_DATA, ...tableConstants]
  state.ids = [...Object.values(AUDIT_CONSTANTS), ...ids]
  state.financialById = keyBy([...AUDIT_DATA, ...tableConstants], 'index')
  state.mappingLoading = false
}

// Get format data for chart
export const getChartDataFormat = (data, options) => {
  if (!data[options.thunkFuncName]) return []
  return data[options.thunkFuncName]
    .map((item) => {
      const obj = {}
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          if (options.dataFormat[key]) {
            obj[key] = item[key] / options.dataFormat[key]
          } else {
            obj[key] = item[key]
          }
        } else {
          obj[key] = 0
        }
      })
      return obj
    })
    .sort((a, b) => {
      if (a.quarter && b.quarter) {
        return (
          moment().set('year', b.year).quarter(b.quarter).unix() -
          moment().set('year', a.year).quarter(a.quarter).unix()
        )
      } else {
        return (
          moment().set('year', b.year).unix() -
          moment().set('year', a.year).unix()
        )
      }
    })
    .filter((_, index) => index < 5)
    .reverse()
}

const getStackedBarChartDataItem = (data) => {
  const stackDataItem = { dummy: 0 }
  Object.keys(data[0]).forEach((key) => {
    if (typeof data[0][key] === 'number') {
      stackDataItem[key] = 0
    }
    if (typeof data[0][key] === 'string') {
      stackDataItem[key] = ''
    }
    if (typeof data[0][key] === 'boolean') {
      stackDataItem[key] = false
    }
  })
  return stackDataItem
}

// Get data for group stack bar chart after formatting
const getStackedBarChartData = (data, barKeys) => {
  const stackData = []
  data.forEach((item) => {
    barKeys.forEach((stack) => {
      const obj = {
        ...getStackedBarChartDataItem(data),
        label: item.label + stack.join(''),
      }
      Object.keys(item).forEach((key) => {
        if (stack.includes(key)) {
          obj[key] = item[key]
        }
      })
      stackData.push(obj)
    })
  })
  return stackData
}

const getEachChartMargin = (chartOptions, data, fontSize) => {
  const chartOptionsProps = chartOptions.props
  const isShowRightTick =
    typeof chartOptionsProps.isShowRightTick === 'boolean' &&
    !chartOptionsProps.isShowRightTick
      ? false
      : true

  // Check if the chart is bar chart and line chart without horizontal
  if (chartOptions.leftLabel && chartOptionsProps.xAxisKey) {
    let barTickValues = []
    let barDecimalLength = 0
    let lineTickValues = []
    let lineDecimalLength = 0

    // Get tick values and decimal length for bar chart
    if (chartOptionsProps.barKeys && data.length) {
      const isGroupStack = typeof chartOptionsProps.barKeys[0] === 'object'
      const [barMin, barMax] = yExtentsCharts(
        isGroupStack
          ? getStackedBarChartData(data, chartOptionsProps.barKeys)
          : data || [],
        chartOptionsProps.barKeys.join(',').split(','),
        chartOptionsProps.barKeys.length > 1,
      )
      barTickValues = getRoundedTickValues([barMin, barMax], 6)
      barDecimalLength = getDecimalLengthYAxis(barTickValues)
    }

    // Get tick values and decimal length for line chart
    if (chartOptionsProps.lineKeys && data.length) {
      const [lineMin, lineMax] = yExtentsCharts(
        data || [],
        chartOptionsProps.lineKeys,
      )
      lineTickValues = getRoundedTickValues([lineMin, lineMax], 6)
      lineDecimalLength = getDecimalLengthYAxis(lineTickValues)
    }

    // Calculate left and right tick value, decimal length
    const leftTickValues = !isShowRightTick
      ? [...barTickValues, ...lineTickValues]
      : barTickValues
    const rightTickValues = !isShowRightTick ? [] : lineTickValues
    const leftDecimalLength = !isShowRightTick
      ? Math.min(barDecimalLength, lineDecimalLength)
      : barDecimalLength
    const rightDecimalLength = lineDecimalLength

    // Calculate margin for chart
    const margin = getChartMargin(
      leftTickValues,
      rightTickValues,
      leftDecimalLength,
      rightDecimalLength,
      fontSize,
    )

    return margin
  }

  return MARGIN
}

export const getCompareChartMargin = (
  data,
  chartOptions,
  otherChartOptions,
  fontSize,
) => {
  const margin = getEachChartMargin(
    chartOptions,
    getChartDataFormat(data, chartOptions),
    fontSize,
  )
  const otherMargin = getEachChartMargin(
    otherChartOptions,
    getChartDataFormat(data, otherChartOptions),
    fontSize,
  )
  // Compare two chart margin
  return {
    left: Math.max(margin?.left || 0, otherMargin?.left || 0),
    right: Math.max(margin?.right || 0, otherMargin?.right || 0),
  }
}
