export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const columns = [
  {
    key: 'paymentDate',
    title: 'bond.valuation.history.table.paymentDate',
    attrs: {
      style: {
        textAlign: 'right',
        width: '25%',
        minWidth: '100px',
      },
    },
  },
  {
    key: 'couponInterestRate',
    title: 'bond.valuation.history.table.couponInterestRate',
    additionText: 'bond.valuation.history.table.unitYear',
    attrs: {
      style: {
        textAlign: 'right',
        width: '25%',
      },
    },
  },
  {
    key: 'interestAndPrincipal',
    title: 'bond.valuation.history.table.interestAndPrincipal',
    additionText: 'bond.valuation.history.table.unitMoney',
    attrs: {
      style: {
        textAlign: 'right',
        width: '25%',
      },
    },
  },
  {
    key: 'paymentStatusName',
    title: 'bond.valuation.history.table.paymentStatusName',
    attrs: {
      style: {
        textAlign: 'right',
        width: '25%',
      },
    },
  },
]

export const STATUE_COLOR = {
  paid: '#3DA967',
  unpaid: '#DF5A49',
  postpone: '#FECF2F',
  cancel: '#FF4752',
  payment: '#A6CF98',
  paid_unconfirmed: '#3DA967',
  late_payment: '#ECECEC',
}
