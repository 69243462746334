import PropTypes from 'prop-types'
import { EMPTY_VALUE } from '../../../constants/Common'
import { valToPercent } from '../../../utils/Value'
import style from '../index.module.css'

const TdPercentChange = ({ value, colId, maxValue }) => {
  const textValue = valToPercent(value)
  const percentChange = (Math.abs(value) / maxValue) * 100
  const isEmpty = textValue === EMPTY_VALUE

  return (
    <>
      <div className="d-flex w-100 h-20">
        {value === 0 || isEmpty ? (
          <div className={style.textDraw}>{textValue}</div>
        ) : (
          <>
            {value < 0 ? (
              <div
                className={`${style.w50} ${style.textLost} position-relative`}
              >
                <div
                  className={style.backgroundLost}
                  style={{
                    width: `${percentChange}%`,
                  }}
                />
                <span>{textValue}</span>
                &nbsp;
              </div>
            ) : (
              <div className={style.w50} />
            )}

            {value > 0 ? (
              <div
                className={`${style.w50} ${style.textGain} position-relative`}
              >
                <div
                  className={style.backgroundGain}
                  style={{
                    width: `${percentChange}%`,
                  }}
                />
                <span>{textValue}</span>
                &nbsp;
              </div>
            ) : (
              <div className={style.w50} />
            )}
          </>
        )}
      </div>
    </>
  )
}

TdPercentChange.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxValue: PropTypes.number.isRequired,
}

export default TdPercentChange
