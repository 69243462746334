import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class ChartingProxy extends ProxyBase {
  getTradingViewData(params) {
    return this.get('GetStockChartData', params, null, true)
  }

  getStockEvents(params) {
    return this.get('GetStockEvents', params, null, true)
  }

  getIndicators(params) {
    return this.get('GetIndicators', params)
  }

  getAllMacroEconomicIndicator(params) {
    return this.get('GetAllMacroEconomicIndicator', params)
  }

  getAllMoreIndicator(params) {
    return this.get('GetAllMoreIndicator', params)
  }

  getMoreIndicatorData(params) {
    return this.get('GetMoreIndicatorData', params, null, true)
  }
}

export default new ChartingProxy(ServiceProxyConfig.Common.Charting.ServiceUrl)
