import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import { handleColor } from './helper'
import style from './index.module.css'
import { getMinMaxValueByColId } from './store/slice'

const OtherColumnCell = ({ val, props, isFixedRow, rowId, colId }) => {
  const minMaxValue = useSelector(getMinMaxValueByColId(colId))

  const getStyle = (val, minMaxValue) => {
    const style = { position: 'relative', fontWeight: 600, height: '100%' }

    if (isFixedRow) {
      return {
        ...style,
        fontStyle: 'italic',
      }
    }

    if (typeof val === 'number' && minMaxValue.length) {
      const { bg, color } = handleColor(val, minMaxValue)
      return {
        ...style,
        backgroundColor: bg,
        color: color,
      }
    }

    return style
  }

  return (
    <td
      {...props}
      style={{
        ...props.style,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div style={getStyle(val, minMaxValue)} className={style.customTd}>
        <Span style={{ paddingRight: 8 }}>
          {formatVal(val)}
          {typeof val === 'number' && '%'}
        </Span>
        {rowId === '0' && <div className={style.borderTop} />}
      </div>
    </td>
  )
}

OtherColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  props: PropTypes.object,
  rowId: PropTypes.string.isRequired,
  isFixedRow: PropTypes.bool,
  colId: PropTypes.string,
}

export default OtherColumnCell
