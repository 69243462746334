import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valDivBillion,
  valToPercent,
} from '../../../../../../utils/Value'
import { convertData, getUniqueListBy } from '../helper'
import { getDetailMetrics } from './thunk'

const initialState = {
  loading: true,
  detailMetricsById: {},
  ids: [],
  groupColumns: [],
  levelCollapse: {},
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/securities/panelDetailMetrics',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    setDetailMetricsById: (state, action) => {
      state.detailMetricsById = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDetailMetrics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDetailMetrics.fulfilled, (state, action) => {
      state.loading = false
      const dataConvert = getUniqueListBy(
        convertData(action.payload || []),
        'organizationId',
      )
      const groupColumns = []
      const detailMetricsArr = Object.values(state.detailMetricsById)

      dataConvert.forEach((i) => {
        groupColumns.push({
          key: i.ti,
          title: i.ti,
        })
        detailMetricsArr.forEach((e) => {
          const value = i[e.key]
          e[i.ti] = e.isFormatValToPercent
            ? valToPercent(value)
            : formatVal(valDivBillion(value))
        })
      })
      state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
    })
    builder.addCase(getDetailMetrics.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectDetailMetricsIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectDetailMetricsValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].detailMetricsById[id], attr)
}
export const selectItemData = (id) => (state) =>
  state[slice.name].detailMetricsById[id]

export const selectLoading = (state) => state[slice.name].loading

export const { resetStore, changeIds, setDetailMetricsById } = slice.actions

register(slice.name, slice.reducer)
