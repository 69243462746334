const currentYearByDateJS = new Date().getFullYear()
const DEFAULT_OLDEST_YEAR = 2012

const YEARS_LIST = [
  ...Array(currentYearByDateJS - DEFAULT_OLDEST_YEAR + 1),
].map((_, index) => currentYearByDateJS - index)

export const YEARS_FILTER = YEARS_LIST.map((item) => ({
  name: item,
  value: item,
}))
