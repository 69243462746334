import { useDispatch, useSelector } from 'react-redux'
import HeaderOneCalendarRange from '../../../common/calendar/headers/HeaderOneCalendarRange'
import OneCalendarRange from '../../../common/calendar/OneCalendarRange'
import { FORMAT, getCurrentDateTime } from '../../../utils/Datetime'
import {
  changeSelectedDate,
  selectDateHasEvents,
  selectEndDate,
  selectStartDate,
} from '../store/slice'
import style from '../style.module.css'

const DatePicker = () => {
  const dispatch = useDispatch()

  const selectedStartDate = useSelector(selectStartDate)
  const selectedEndDate = useSelector(selectEndDate)
  const dateHasEvent = useSelector(selectDateHasEvents)

  const highlightWithRanges = [
    {
      'react-datepicker__day--highlighted_event': dateHasEvent,
    },
  ]

  const handleChange = (dates) => {
    const [startDate, endDate] = dates
    dispatch(
      changeSelectedDate({
        startDate,
        endDate,
      }),
    )
  }

  return (
    <div className={style.dateRange}>
      <OneCalendarRange
        startDate={selectedStartDate ? new Date(selectedStartDate) : null}
        endDate={selectedEndDate ? new Date(selectedEndDate) : null}
        customHeader={HeaderOneCalendarRange({
          startYear: 2004,
          endYear: +getCurrentDateTime(FORMAT.YEAR),
        })}
        minDate={new Date(2004, 0)}
        maxDate={new Date(new Date().getFullYear(), 11, 31)}
        handleChange={handleChange}
        hightlightDates={highlightWithRanges}
        btnDeleteDate
      />
    </div>
  )
}

export default DatePicker
