import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  ORIGINAL_NUMBER_FIRST,
  ORIGINAL_NUMBER_FIRST_NOT_YEARLY,
} from '../../../constants'
import { TAB_TYPE } from '../../constants'
import {
  addColorIdToData,
  addColorIdToDataDefault,
  convertDataTable,
  convertGroupColumn,
  getTimeMaxCallApi,
  getTimeMinCallApi,
} from '../../helper'
import { DEFAULT_MIN_TIME } from '../../iip/store/slice'
import { getDataTableIIC } from './thunk'

const slice = createSlice({
  name: 'economy/productionAndConsumption/production/table/iic',
  initialState: {
    dataTable: [],
    dataById: {},
    ids: [],
    groupColumns: [],
    loading: false,
    listSelectRowIIC: [],
    dataWithColorId: [],
    minTimeCallApi: DEFAULT_MIN_TIME,
    maxTimeCallApi: DEFAULT_MIN_TIME,
    prevData: [],
    initIds: [],
    prevScrollWidth: 0,
    isShowAlert: false,
    dataSortLevel: [],
  },
  reducers: {
    addSelectListTableIIC: (state, action) => {
      state.listSelectRowIIC = [...state.listSelectRowIIC, action.payload]
    },
    removeSelectListTableIIC: (state, action) => {
      state.listSelectRowIIC = state.listSelectRowIIC.filter(
        (item) => item.iivnTypeId !== action.payload,
      )
    },
    changeMinTimeCallApi: (state, action) => {
      state.minTimeCallApi = { ...action.payload }
    },
    resetDataIIC: (state) => {
      state.dataById = {}
      state.groupColumns = []
      state.prevData = []
      state.minTimeCallApi = DEFAULT_MIN_TIME
      state.listSelectRowIIC = []
    },
    sortTableIIC: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initIds,
        0,
        state.dataSortLevel,
      )
    },
    handlePrevScrollWidth: (state, action) => {
      state.prevScrollWidth = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataTableIIC.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataTableIIC.fulfilled, (state, action) => {
      state.prevData = [...action.payload.dataIIC, ...state.prevData]
      state.data = action.payload.titleIIC
      const dataTitle = action.payload.titleIIC
      const data = convertDataTable(state.prevData, dataTitle, TAB_TYPE.IIC)
      const groupColumns = [{ key: 'iivnTypeName', title: '' }]
      const filterGroupColumns = convertGroupColumn(data)
      filterGroupColumns?.forEach((item) => {
        groupColumns.push({
          key: item,
          title: item,
        })
      })
      state.dataTable = data
      state.groupColumns = groupColumns
      state.dataById = keyBy(data, 'iivnTypeId')
      state.dataWithColorId = addColorIdToData(data)
      state.listSelectRowIIC = addColorIdToDataDefault(
        data,
        action?.meta?.arg,
        state.listSelectRowIIC,
      )
      state.minTimeCallApi = getTimeMinCallApi(action.payload.dataIIC)
      if (
        [ORIGINAL_NUMBER_FIRST, ORIGINAL_NUMBER_FIRST_NOT_YEARLY].includes(
          action.meta.arg.Limit,
        )
      ) {
        state.maxTimeCallApi = getTimeMaxCallApi(action.payload.dataIIC)
      }
      state.ids = state.data?.map((item) => item.iivnTypeId)
      state.initIds = state.ids
      state.dataSortLevel = action.payload.titleIIC?.map((item) => {
        return {
          id: item.iivnTypeId,
          level: item.iivnTypeLevel,
          parentId: item.parentIIVNTypeId,
        }
      })
      state.loading = false
    })
    builder.addCase(getDataTableIIC.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectCellValueDataTableIIC = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}

export const selectIdIIC = (state) => state[slice.name].ids
export const selectDataTableIIC = (state) => state[slice.name].dataTable
export const selectGroupColumnTableIIC = (state) =>
  state[slice.name].groupColumns
export const selectLoadingTableIIC = (state) => state[slice.name].loading
export const selectDataColorIdIIC = (state) => state[slice.name].dataWithColorId
export const selectMinTimeCallApi = (state) => state[slice.name].minTimeCallApi
export const selectMaxTimeCallApi = (state) => state[slice.name].maxTimeCallApi
export const selectListSelectRowIIC = (state) =>
  state[slice.name].listSelectRowIIC
export const getPrevScrollWidth = (state) => state[slice.name].prevScrollWidth
export const getIsShowAlert = (state) => state[slice.name].isShowAlert

export const {
  addSelectListTableIIC,
  removeSelectListTableIIC,
  changeMinTimeCallApi,
  resetDataIIC,
  sortTableIIC,
  handlePrevScrollWidth,
  handleIsShowAlert,
} = slice.actions
register(slice.name, slice.reducer)
