export const VIEW_TYPE = {
  YEAR: 'Yearly',
  QUARTER: 'Quarterly',
}

export const NUMBER_OF_PERIOD = [...new Array(11)].map((_, index) =>
  !index
    ? { name: 'sector.financialAnalysis.common.ALL', value: String(1000) }
    : {
        name: String(index),
        value: String(index),
      },
)

export const VIEW_TYPE_RADIO = [
  {
    title: 'sector.financialAnalysis.common.YEARLY',
    value: VIEW_TYPE.YEAR,
  },
  {
    title: 'sector.financialAnalysis.common.QUARTERLY',
    value: VIEW_TYPE.QUARTER,
  },
]

export const allIndicators = {
  1: {
    indicatorId: 1,
    indicatorGroupId: 3,
    orderNumber: 1,
    indicatorName: 'sector.financialAnalysis.corporate.table.ACTIVITY_RATIO',
    children: {
      2: {
        indicatorId: 2,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 2,
        indicatorName:
          'sector.financialAnalysis.corporate.table.TOTAL_ASSET_TURNOVER',
        tableFieldCode: 'RT0003',
        multiplier: 1,
        unit: '',
        children: {},
      },
      3: {
        indicatorId: 3,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 3,
        indicatorName:
          'sector.financialAnalysis.corporate.table.EQUITY_TURNOVER',
        tableFieldCode: 'RT0004',
        multiplier: 1,
        unit: '',
        children: {},
      },
      4: {
        indicatorId: 4,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 4,
        indicatorName:
          'sector.financialAnalysis.corporate.table.RECEIVABLES_TURNOVER',
        tableFieldCode: 'RT0008',
        multiplier: 1,
        unit: '',
        children: {},
      },
      5: {
        indicatorId: 5,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 5,
        indicatorName:
          'sector.financialAnalysis.corporate.table.DAY_SALE_OUTSTANDING',
        tableFieldCode: 'RT0009',
        multiplier: 1,
        unit: 'Days',
        children: {},
      },
      6: {
        indicatorId: 6,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 6,
        indicatorName:
          'sector.financialAnalysis.corporate.table.INVENTORY_TURNOVER',
        tableFieldCode: 'RT0010',
        multiplier: 1,
        unit: '',
        children: {},
      },
      7: {
        indicatorId: 7,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 7,
        indicatorName:
          'sector.financialAnalysis.corporate.table.DAY_INVENTORY_HAND',
        tableFieldCode: 'RT0011',
        multiplier: 1,
        unit: 'Days',
        children: {},
      },
      8: {
        indicatorId: 8,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 8,
        indicatorName:
          'sector.financialAnalysis.corporate.table.PAYABLE_TURNOVER',
        tableFieldCode: 'RT0012',
        multiplier: 1,
        unit: '',
        children: {},
      },
      9: {
        indicatorId: 9,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 9,
        indicatorName:
          'sector.financialAnalysis.corporate.table.NUMBER_DAY_PAYABLE',
        tableFieldCode: 'RT0013',
        multiplier: 1,
        unit: 'Days',
        children: {},
      },
      10: {
        indicatorId: 10,
        parentIndicatorId: 1,
        indicatorGroupId: 3,
        orderNumber: 10,
        indicatorName:
          'sector.financialAnalysis.corporate.table.CASH_CONVERSION_CYCLE',
        tableFieldCode: 'RT0015',
        multiplier: 1,
        unit: 'Days',
        children: {},
      },
    },
  },
  11: {
    indicatorId: 11,
    indicatorGroupId: 3,
    orderNumber: 11,
    indicatorName: 'sector.financialAnalysis.corporate.table.LIQUIDITY_RATIO',
    children: {
      12: {
        indicatorId: 12,
        parentIndicatorId: 11,
        indicatorGroupId: 3,
        orderNumber: 12,
        indicatorName: 'sector.financialAnalysis.corporate.table.CASH_RATIO',
        tableFieldCode: 'RT0018',
        multiplier: 1,
        unit: '',
        children: {},
      },
      13: {
        indicatorId: 13,
        parentIndicatorId: 11,
        indicatorGroupId: 3,
        orderNumber: 13,
        indicatorName: 'sector.financialAnalysis.corporate.table.QUICK_RATIO',
        tableFieldCode: 'RT0019',
        multiplier: 1,
        unit: '',
        children: {},
      },
      14: {
        indicatorId: 14,
        parentIndicatorId: 11,
        indicatorGroupId: 3,
        orderNumber: 14,
        indicatorName: 'sector.financialAnalysis.corporate.table.CURRENT_RATIO',
        tableFieldCode: 'RT0020',
        multiplier: 1,
        unit: '',
        children: {},
      },
    },
  },
  15: {
    indicatorId: 15,
    indicatorGroupId: 3,
    orderNumber: 15,
    indicatorName: 'sector.financialAnalysis.corporate.table.SOLVENCY_RATIO',
    children: {
      16: {
        indicatorId: 16,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 16,
        indicatorName:
          'sector.financialAnalysis.corporate.table.ST_DEPT_ASSET_RATIO',
        tableFieldCode: 'RT0022',
        multiplier: 1,
        unit: '',
        children: {},
      },
      17: {
        indicatorId: 17,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 17,
        indicatorName:
          'sector.financialAnalysis.corporate.table.ST_DEPT_EQUITY_RATIO',
        tableFieldCode: 'RT0023',
        multiplier: 1,
        unit: '',
        children: {},
      },
      18: {
        indicatorId: 18,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 18,
        indicatorName:
          'sector.financialAnalysis.corporate.table.LT_DEPT_ASSET_RATIO',
        tableFieldCode: 'RT0024',
        multiplier: 1,
        unit: '',
        children: {},
      },
      19: {
        indicatorId: 19,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 19,
        indicatorName:
          'sector.financialAnalysis.corporate.table.LT_DEPT_EQUITY_RATIO',
        tableFieldCode: 'RT0025',
        multiplier: 1,
        unit: '',
        children: {},
      },
      20: {
        indicatorId: 20,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 20,
        indicatorName:
          'sector.financialAnalysis.corporate.table.DEPT_ASSET_RATIO',
        tableFieldCode: 'RT0026',
        multiplier: 1,
        unit: '',
        children: {},
      },
      21: {
        indicatorId: 21,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 21,
        indicatorName:
          'sector.financialAnalysis.corporate.table.DEPT_EQUITY_RATIO',
        tableFieldCode: 'RT0027',
        multiplier: 1,
        unit: '',
        children: {},
      },
      22: {
        indicatorId: 22,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 22,
        indicatorName:
          'sector.financialAnalysis.corporate.table.LIABILITIES_DEPT_ASSET_RATIO',
        tableFieldCode: 'RT0033',
        multiplier: 1,
        unit: '',
        children: {},
      },
      23: {
        indicatorId: 23,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 23,
        indicatorName:
          'sector.financialAnalysis.corporate.table.LIABILITIES_DEPT_EQUITY_RATIO',
        tableFieldCode: 'RT0034',
        multiplier: 1,
        unit: '',
        children: {},
      },
      24: {
        indicatorId: 24,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 24,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FINANCIAL_LEVERAGE_RATIO',
        tableFieldCode: 'RT0035',
        multiplier: 1,
        unit: '',
        children: {},
      },
      25: {
        indicatorId: 25,
        parentIndicatorId: 15,
        indicatorGroupId: 3,
        orderNumber: 25,
        indicatorName:
          'sector.financialAnalysis.corporate.table.INTEREST_COVERAGE',
        tableFieldCode: 'RT0044',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
    },
  },
  26: {
    indicatorId: 26,
    indicatorGroupId: 3,
    orderNumber: 26,
    indicatorName:
      'sector.financialAnalysis.corporate.table.PROFITABILITY_RATIO',
    children: {
      27: {
        indicatorId: 27,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 27,
        indicatorName:
          'sector.financialAnalysis.corporate.table.GROSS_PROFIT_MARGIN',
        tableFieldCode: 'RT0050',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      28: {
        indicatorId: 28,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 28,
        indicatorName: 'sector.financialAnalysis.corporate.table.EBITDA_MARGIN',
        tableFieldCode: 'RT0051',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      29: {
        indicatorId: 29,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 29,
        indicatorName: 'sector.financialAnalysis.corporate.table.EBIT_MARGIN',
        tableFieldCode: 'RT0052',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      30: {
        indicatorId: 30,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 30,
        indicatorName: 'sector.financialAnalysis.corporate.table.PRETAX_MARGIN',
        tableFieldCode: 'RT0053',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      31: {
        indicatorId: 31,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 31,
        indicatorName:
          'sector.financialAnalysis.corporate.table.NET_PROFIT_MARGIN',
        tableFieldCode: 'RT0054',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      32: {
        indicatorId: 32,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 32,
        indicatorName: 'sector.financialAnalysis.corporate.table.ROA',
        tableFieldCode: 'RT0059',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      33: {
        indicatorId: 33,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 33,
        indicatorName: 'sector.financialAnalysis.corporate.table.ROE',
        tableFieldCode: 'RT0060',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      34: {
        indicatorId: 34,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 34,
        indicatorName: 'sector.financialAnalysis.corporate.table.ROIC',
        tableFieldCode: 'RT0064',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      35: {
        indicatorId: 35,
        parentIndicatorId: 26,
        indicatorGroupId: 3,
        orderNumber: 35,
        indicatorName:
          'sector.financialAnalysis.corporate.table.EFFECTIVE_TAX_RATE',
        tableFieldCode: 'RT0067',
        multiplier: 100,
        unit: '%',
        children: {},
      },
    },
  },
  36: {
    indicatorId: 36,
    indicatorGroupId: 3,
    orderNumber: 36,
    indicatorName: 'sector.financialAnalysis.corporate.table.CASHFLOW_RATIO',
    children: {
      37: {
        indicatorId: 37,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 37,
        indicatorName:
          'sector.financialAnalysis.corporate.table.CFO_LIABILITIES',
        tableFieldCode: 'RT0075',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
      38: {
        indicatorId: 38,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 38,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FREE_CASHFLOW_REVENUE',
        tableFieldCode: 'RT0083',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
      39: {
        indicatorId: 39,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 39,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FREE_CASHFLOW_OPERATING',
        tableFieldCode: 'RT0084',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
      40: {
        indicatorId: 40,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 40,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FREE_CASHFLOW_ASSET',
        tableFieldCode: 'RT0085',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
      41: {
        indicatorId: 41,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 41,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FREE_CASHFLOW_EQUITY',
        tableFieldCode: 'RT0088',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
      42: {
        indicatorId: 42,
        parentIndicatorId: 36,
        indicatorGroupId: 3,
        orderNumber: 42,
        indicatorName:
          'sector.financialAnalysis.corporate.table.REINVESTMENT_FCF',
        tableFieldCode: 'RT0089',
        multiplier: 1,
        unit: 'Times',
        children: {},
      },
    },
  },
  43: {
    indicatorId: 43,
    indicatorGroupId: 3,
    orderNumber: 43,
    indicatorName: 'sector.financialAnalysis.corporate.table.GROWTH',
    children: {
      44: {
        indicatorId: 44,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 44,
        indicatorName:
          'sector.financialAnalysis.corporate.table.NET_REVENUE_GROWTH',
        tableFieldCode: 'RT0156',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      45: {
        indicatorId: 45,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 45,
        indicatorName:
          'sector.financialAnalysis.corporate.table.GROSS_PROFIT_GROWTH',
        tableFieldCode: 'RT0157',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      46: {
        indicatorId: 46,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 46,
        indicatorName: 'sector.financialAnalysis.corporate.table.EBITDA_GROWTH',
        tableFieldCode: 'RT0158',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      47: {
        indicatorId: 47,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 47,
        indicatorName: 'sector.financialAnalysis.corporate.table.EBIT_GROWTH',
        tableFieldCode: 'RT0159',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      48: {
        indicatorId: 48,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 48,
        indicatorName: 'sector.financialAnalysis.corporate.table.EBT_GROWTH',
        tableFieldCode: 'RT0160',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      49: {
        indicatorId: 49,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 49,
        indicatorName:
          'sector.financialAnalysis.corporate.table.RECEIVABLE_GROWTH',
        tableFieldCode: 'RT0163',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      50: {
        indicatorId: 50,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 50,
        indicatorName:
          'sector.financialAnalysis.corporate.table.NET_INVENTORY_GROWTH',
        tableFieldCode: 'RT0164',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      51: {
        indicatorId: 51,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 51,
        indicatorName:
          'sector.financialAnalysis.corporate.table.SHORT_TERM_GROWTH',
        tableFieldCode: 'RT0165',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      52: {
        indicatorId: 52,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 52,
        indicatorName:
          'sector.financialAnalysis.corporate.table.LONG_TERM_GROWTH',
        tableFieldCode: 'RT0166',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      53: {
        indicatorId: 53,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 53,
        indicatorName: 'sector.financialAnalysis.corporate.table.SGA_GROWTH',
        tableFieldCode: 'RT0167',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      54: {
        indicatorId: 54,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 54,
        indicatorName: 'sector.financialAnalysis.corporate.table.ASSET_GROWTH',
        tableFieldCode: 'RT0172',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      55: {
        indicatorId: 55,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 55,
        indicatorName:
          'sector.financialAnalysis.corporate.table.OWNER_EQUITY_GROWTH',
        tableFieldCode: 'RT0174',
        multiplier: 100,
        unit: '%',
        children: {},
      },
      56: {
        indicatorId: 56,
        parentIndicatorId: 43,
        indicatorGroupId: 3,
        orderNumber: 56,
        indicatorName: 'sector.financialAnalysis.corporate.table.CFO_GROWTH',
        tableFieldCode: 'RT0170',
        multiplier: 100,
        unit: '%',
        children: {},
      },
    },
  },
  57: {
    indicatorId: 57,
    indicatorGroupId: 3,
    orderNumber: 57,
    indicatorName: 'sector.financialAnalysis.corporate.table.VALUATION_RATIO',
    children: {
      58: {
        indicatorId: 58,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 58,
        indicatorName: 'sector.financialAnalysis.corporate.table.MARKET_CAP',
        tableFieldCode: 'RTD0005',
        multiplier: 1 / 10 ** 9,
        unit: 'Billion VND',
        children: {},
      },
      59: {
        indicatorId: 59,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 59,
        indicatorName: 'sector.financialAnalysis.corporate.table.EV',
        tableFieldCode: 'RTD0006',
        multiplier: 1 / 10 ** 9,
        unit: 'Billion VND',
        children: {},
      },
      60: {
        indicatorId: 60,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 60,
        indicatorName:
          'sector.financialAnalysis.corporate.table.BOOK_VALUE_PER_SHARE',
        tableFieldCode: 'RTD0007',
        multiplier: 1,
        unit: 'VND',
        children: {},
      },
      61: {
        indicatorId: 61,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 61,
        indicatorName:
          'sector.financialAnalysis.corporate.table.SALE_PER_SHARE',
        tableFieldCode: 'RTD0008',
        multiplier: 1,
        unit: 'VND',
        children: {},
      },
      62: {
        indicatorId: 62,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 62,
        indicatorName: 'sector.financialAnalysis.corporate.table.BASIC_EPS',
        tableFieldCode: 'RTD0011',
        multiplier: 1,
        unit: 'VND',
        children: {},
      },
      63: {
        indicatorId: 63,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 63,
        indicatorName: 'sector.financialAnalysis.corporate.table.DILUTED_EPS',
        tableFieldCode: 'RTD0012',
        multiplier: 1,
        unit: 'VND',
        children: {},
      },
      64: {
        indicatorId: 64,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 64,
        indicatorName:
          'sector.financialAnalysis.corporate.table.FREE_CASHFLOW_PER_SHARE',
        tableFieldCode: 'RTD0013',
        multiplier: 1,
        unit: 'VND',
        children: {},
      },
      65: {
        indicatorId: 65,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 65,
        indicatorName: 'sector.financialAnalysis.corporate.table.PB',
        tableFieldCode: 'RTD0019',
        multiplier: 1,
        unit: '',
        children: {},
      },
      66: {
        indicatorId: 66,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 66,
        indicatorName: 'sector.financialAnalysis.corporate.table.PE',
        tableFieldCode: 'RTD0022',
        multiplier: 1,
        unit: '',
        children: {},
      },
      67: {
        indicatorId: 67,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 67,
        indicatorName: 'sector.financialAnalysis.corporate.table.PS',
        tableFieldCode: 'RTD0025',
        multiplier: 1,
        unit: '',
        children: {},
      },
      68: {
        indicatorId: 68,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 68,
        indicatorName: 'sector.financialAnalysis.corporate.table.EV_REVENUE',
        tableFieldCode: 'RTD0026',
        multiplier: 1,
        unit: '',
        children: {},
      },
      69: {
        indicatorId: 69,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 69,
        indicatorName: 'sector.financialAnalysis.corporate.table.EV_EBITDA',
        tableFieldCode: 'RTD0027',
        multiplier: 1,
        unit: '',
        children: {},
      },
      70: {
        indicatorId: 70,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 70,
        indicatorName: 'sector.financialAnalysis.corporate.table.EV_EBIT',
        tableFieldCode: 'RTD0028',
        multiplier: 1,
        unit: '',
        children: {},
      },
      71: {
        indicatorId: 71,
        parentIndicatorId: 57,
        indicatorGroupId: 3,
        orderNumber: 71,
        indicatorName:
          'sector.financialAnalysis.corporate.table.DIVIDEND_YIELD',
        tableFieldCode: 'RTD0045',
        multiplier: 100,
        unit: '%',
        children: {},
      },
    },
  },
}
