import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { defineGroupColumns, findMinYear, keyBy } from '../helper'
import { getGDPBySpendingMethodStructure, getGDPTypeName } from './thunk'

const initialState = {
  tableMapping: [],
  tableMappingLoading: true,
  tableDataLoading: true,
  GDPStructureById: {},
  isFirstTime: true,
  isAllowCallApi: true,
  minYear: 0,
  prevMinYear: 0,
  groupColumns: [],
}

const slice = createSlice({
  name: 'economy/gdp/gdpBySpendingMethod/panelGDPStructure',
  initialState,
  reducers: {
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPBySpendingMethodStructure.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(
      getGDPBySpendingMethodStructure.fulfilled,
      (state, action) => {
        const data = action.payload || []
        state.tableDataLoading = false
        state.groupColumns = defineGroupColumns(
          data,
          state.groupColumns,
          action.meta.arg.NumberOfPeriod,
        )
        state.GDPStructureById = keyBy(
          data,
          state.GDPStructureById,
          action.meta.arg.NumberOfPeriod,
        )
        state.prevMinYear = state.minYear
        state.minYear = findMinYear(data)
        if (!data?.length) {
          state.isAllowCallApi = false
        }
      },
    )
    builder.addCase(
      getGDPBySpendingMethodStructure.rejected,
      (state, action) => {
        state.tableDataLoading = action.payload.isLoading
      },
    )
    builder.addCase(getGDPTypeName.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getGDPTypeName.fulfilled, (state, action) => {
      state.tableMappingLoading = false
      state.tableMapping = action.payload || []
    })
    builder.addCase(getGDPTypeName.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
  },
})

export const { handleIsFirstTime, handleIsAllowCallApi } = slice.actions

export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getTableMappingData = (state) => state[slice.name].tableMapping
export const getTableData = (state) => state[slice.name].tableData
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIsFirstTime = (state) => state[slice.name].isFirstTime
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getMinYear = (state) => state[slice.name].minYear
export const getPrevMinYear = (state) => state[slice.name].prevMinYear
export const getGDPStructureById = (state) => state[slice.name].GDPStructureById
export const getGDPStructureValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].GDPStructureById[id], attr)

register(slice.name, slice.reducer)
