import CompanySnapshotProxy from '../../proxies/corporate/CompanySnapshotProxy'
import CompanyProxy from '../../proxies/corporate/CompanySnapshotTemplatesProxy'
import { ServiceBase } from '../ServiceBase'

export class CompanySnapshotService extends ServiceBase {
  getOverview(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getOverview',
      params,
    )(() => CompanySnapshotProxy.getOverview(params))
  }

  getRevenueBreakdown(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getRevenueBreakdown',
      params,
    )(() => CompanySnapshotProxy.getRevenueBreakdown(params))
  }

  getCompareWithSector(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getCompareWithSector',
      params,
    )(() => CompanySnapshotProxy.getCompareWithSector(params))
  }

  getStockData(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getStockData',
      params,
    )(() => CompanySnapshotProxy.getStockData(params))
  }

  getStockData1Yrs(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getStockData1Yrs',
      params,
    )(() => CompanySnapshotProxy.getStockData1Yrs(params))
  }

  getFinancialRatio(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getFinancialRatio',
      params,
    )(() => CompanySnapshotProxy.getFinancialRatio(params))
  }

  getValuationHistory(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getValuationHistory',
      params,
    )(() => CompanySnapshotProxy.getValuationHistory(params))
  }

  getPricePerformanceChart(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getPricePerformanceChart',
      params,
    )(() => CompanySnapshotProxy.getPricePerformanceChart(params))
  }

  getPeerAnalysis(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getPeerAnalysis',
      params,
    )(() => CompanySnapshotProxy.getPeerAnalysis(params))
  }

  getKeyFinancialData(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getKeyFinancialData',
      params,
    )(() => CompanySnapshotProxy.getKeyFinancialData(params))
  }

  getFinancialData(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getFinancialData',
      params,
    )(() => CompanySnapshotProxy.getFinancialData(params))
  }

  getOwnershipAnalysis(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getOwnershipAnalysis',
      params,
    )(() => CompanySnapshotProxy.getOwnershipAnalysis(params))
  }

  getTop5MajorShareHolder(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getTop5MajorShareHolder',
      params,
    )(() => CompanySnapshotProxy.getTop5MajorShareHolder(params))
  }

  getPriceData(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getPriceData',
      params,
    )(() => CompanySnapshotProxy.getPriceData(params))
  }

  getStockPerformanceAndIndex(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getStockPerformanceAndIndex',
      params,
    )(() => CompanySnapshotProxy.getStockPerformanceAndIndex(params))
  }

  getPricePerformance(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getPricePerformance',
      params,
    )(() => CompanySnapshotProxy.getPricePerformance(params))
  }

  getForeignNetTrading(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getForeignNetTrading',
      params,
    )(() => CompanySnapshotProxy.getForeignNetTrading(params))
  }

  getIncomeStatement(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getIncomeStatement',
      params,
    )(() => CompanySnapshotProxy.getIncomeStatement(params))
  }

  getBalanceStatement(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getBalanceStatement',
      params,
    )(() => CompanySnapshotProxy.getBalanceStatement(params))
  }

  getReportDate(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getReportDate',
      params,
    )(() => CompanySnapshotProxy.getReportDate(params))
  }

  getSummary(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getSummary',
      params,
    )(() => CompanySnapshotProxy.getSummary(params))
  }

  getMajorShareHolder(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getMajorShareHolder',
      params,
    )(() => CompanySnapshotProxy.getMajorShareHolder(params))
  }

  getOwnershipAnalysisChart(params) {
    return this.applyMemoryCache(
      'CompanySnapshotService.getOwnershipAnalysisChart',
      params,
    )(() => CompanySnapshotProxy.getOwnershipAnalysisChart(params))
  }

  getTemplate(params) {
    return this.getData(() => CompanyProxy.getTemplate(params))
  }

  getMapping_GetAll(params) {
    return this.getData(() => CompanyProxy.getMapping_GetAll(params))
  }

  updateTemplate(params) {
    return this.getData(() => CompanyProxy.updateTemplate(params))
  }

  postTemplates(params) {
    return this.getData(() => CompanyProxy.postTemplates(params))
  }

  deleteTemplate(params) {
    return this.getData(() => CompanyProxy.deleteTemplate(params))
  }

  getMaxDateStatement(params) {
    return this.getData(() => CompanySnapshotProxy.getMaxDateStatement(params))
  }
}

export default new CompanySnapshotService()
