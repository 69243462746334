import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../../../core/services/economy/importExport/OverviewService'

export const getTopTradeGood = createAsyncThunk(
  'economy/importExport/outstandingTarget/topTradeGood',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getTopTradeGood(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
