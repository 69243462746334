import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { FundTopInfo } from '../../../common/topInfo/fundTopInfo'
import Content from './Content'

export const FundProfilePeriodicalReport = () => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FundTopInfo title="fund.fundProfile.PERIODICAL_REPORT" />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <ScrollGroupComponent>
                  <Content />
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
