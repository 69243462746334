import { GDP_PROVINCE_VALUE } from '../constants'

const { PER_CAPITA, GROWTH_YOY } = GDP_PROVINCE_VALUE

export const handleBarChartData = (payload, args) => {
  if (!payload.length) {
    return []
  }

  const { IsNominal: isNominal, GDPTypeCode: valueGrowthOptions } = args
  return payload
    .map((item) => {
      return {
        ...item,
        value: formatVal(item.value, isNominal, valueGrowthOptions),
      }
    })
    .sort((a, b) => b.value - a.value)
}

const formatVal = (val, isNominal, valueGrowthOptions) => {
  switch (valueGrowthOptions) {
    case PER_CAPITA: {
      if (isNominal) {
        return val / 1000000
      } else {
        return val * 100
      }
    }
    case GROWTH_YOY: {
      return val * 100
    }
    default:
      return val / 1000000000
  }
}
