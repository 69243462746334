import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { DispatchActionTabWithCalendar } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { TabLink } from '../../../../../common/tabs/TabLink'
import {
  SECURITY_HOLDING_TABS,
  TABLE_TABS,
  TABLE_TIME_RANGES,
} from '../constants'
import {
  changeFilter,
  changeIsDisableCallApi,
  changeTableTab,
  selectFilters,
  selectLoading,
  selectTableTab,
} from '../store/slice'
import { FundHoldingOption } from './FundHoldingOption'
import { FundTicker } from './FundTicker'
import { TimeOption } from './TimeOption'

export const Filters = () => {
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const loading = useSelector(selectLoading)

  // Actions
  const handleChangeFilter = (value) => {
    dispatch(changeIsDisableCallApi(false))
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  const handleChangeTableTab = (tab) => {
    dispatch(changeTableTab(tab.value))
    handleChangeFilter({ page: 1 })
  }

  const handleChangeTimeRange = (item) => {
    handleChangeFilter({ timeRange: item.value })
  }

  const handleChangeCalendar = (payload) => {
    handleChangeFilter({
      timeRange: payload.activeTab,
      startDate: payload.startDate,
      endDate: payload.endDate,
    })
  }

  return (
    <div className="mb-8">
      <div className="mb-8">
        <TabLink
          defaultTab={TABLE_TABS.STOCK}
          value={tableTab}
          tabs={Object.keys(TABLE_TABS).map((key) => ({
            title: `fund.fundProfile.portfolio.${key}`,
            value: TABLE_TABS[key],
          }))}
          is18n
          disabled={loading}
          onClick={handleChangeTableTab}
        />
      </div>
      <div className="mb-8">
        <DispatchActionTab
          data={Object.keys(SECURITY_HOLDING_TABS).map((key) => ({
            title: 'fund.fundProfile.portfolio.' + key,
            value: SECURITY_HOLDING_TABS[key],
          }))}
          activeTab={filters.securityHoldingTab}
          textSize={11}
          onChangeTab={({ value }) =>
            handleChangeFilter({ securityHoldingTab: value })
          }
        />
      </div>
      {filters.securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT ? (
        <TimeOption
          month={filters.month}
          year={filters.year}
          isGroup={filters.isGroupByIndustry}
          onChange={handleChangeFilter}
        />
      ) : (
        <div className="d-flex ali-center">
          <FundTicker value={filters.assetIds} onChange={handleChangeFilter} />
          <FundHoldingOption
            value={filters.holdingOption}
            onChange={handleChangeFilter}
          />
          <DispatchActionTabWithCalendar
            id="fundPortfolioTable"
            tabs={TABLE_TIME_RANGES}
            activeTab={filters.timeRange}
            startDate={filters.startDate}
            endDate={filters.endDate}
            datePreset={TABLE_TIME_RANGES}
            onChangeTab={handleChangeTimeRange}
            onChangeCalendar={handleChangeCalendar}
          />
        </div>
      )}
    </div>
  )
}
