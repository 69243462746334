import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { selectListCompareChecked, selectListTicker } from './store/slice'

const DropdownTitle = () => {
  const listTicker = useSelector(selectListTicker)
  const listCompareChecked = useSelector(selectListCompareChecked)

  return (
    <div>
      {listCompareChecked.length === listTicker.length ? (
        <Translate value="market.marketInDepth.directorDeal.ALL_TICKERS" />
      ) : listCompareChecked.length === 0 ? (
        <Translate value="market.marketInDepth.directorDeal.NO_TICKER" />
      ) : listCompareChecked.length === 1 ? (
        <>{listCompareChecked[0].tickerOrTaxCode}</>
      ) : (
        <Translate value="market.marketInDepth.directorDeal.LB_TICKERS" />
      )}
    </div>
  )
}

export default DropdownTitle
