import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelInvestmentStructure from './investmentStructure/PanelInvestmentStructure'
import PanelRevenueStructure from './revenueStructure/PanelRevenueStructure'
import PanelSocialInvestment from './socialInvestment/PanelSocialInvestment'
import PanelStateBudgetRevenue from './stateBudgetRevenue/PanelStateBudgetRevenue'

const MAP_KEY = {
  STATE_BUDGET_REVENUE: 'STATE_BUDGET_REVENUE',
  REVENUE_STRUCTURE: 'REVENUE_STRUCTURE',
  SOCIAL_INVESTMENT: 'SOCIAL_INVESTMENT',
  INVESTMENT_STRUCTURE: 'INVESTMENT_STRUCTURE',
}

const MIN_SIZE = {
  [`${MAP_KEY.STATE_BUDGET_REVENUE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.REVENUE_STRUCTURE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.SOCIAL_INVESTMENT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.INVESTMENT_STRUCTURE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.STATE_BUDGET_REVENUE}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(45% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.STATE_BUDGET_REVENUE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.STATE_BUDGET_REVENUE}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.REVENUE_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.REVENUE_STRUCTURE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.SOCIAL_INVESTMENT,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.INVESTMENT_STRUCTURE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.REVENUE_STRUCTURE}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(45% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.REVENUE_STRUCTURE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.REVENUE_STRUCTURE}`].minHeight,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.SOCIAL_INVESTMENT}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(55% - ${COMPONENT.MARGIN}px)`,
    top: `calc(45% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.SOCIAL_INVESTMENT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.SOCIAL_INVESTMENT}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.INVESTMENT_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INVESTMENT_STRUCTURE}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(55% - ${COMPONENT.MARGIN}px)`,
    top: `calc(45% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.INVESTMENT_STRUCTURE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.INVESTMENT_STRUCTURE}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelStateBudgetRevenue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.STATE_BUDGET_REVENUE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelRevenueStructure
          panelRefs={panelRefs}
          panelKey={MAP_KEY.REVENUE_STRUCTURE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelSocialInvestment
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SOCIAL_INVESTMENT}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelInvestmentStructure
          panelRefs={panelRefs}
          panelKey={MAP_KEY.INVESTMENT_STRUCTURE}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
