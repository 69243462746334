import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { getCorpMarketShare, getCorpRevenueType } from './thunk'

const initialState = {
  filter: {
    revenueTypeId: null,
    year: null,
  },
  revenueTypes: [],
  revenueTypeById: {},
  data: [],
  loading: true,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/corp/marketShare',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
      state.data = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorpRevenueType.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCorpRevenueType.fulfilled, (state, action) => {
      const data = [...(action.payload || [])]
      data.sort((a, b) => b?.rB1 - a?.rB1)
      state.revenueTypes = data
      state.revenueTypeById = keyBy(data, 'revenueTypeId')
      if (data.length === 0) state.loading = false
    })
    builder.addCase(getCorpRevenueType.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getCorpMarketShare.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCorpMarketShare.fulfilled, (state, action) => {
      state.data = action.payload || []
      state.loading = false
    })
    builder.addCase(getCorpMarketShare.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter
export const selectData = (state) => state[slice.name].data
export const selectRevenueTypes = (state) => state[slice.name].revenueTypes
export const selectRevenueTypeDataById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].revenueTypeById[id], attr)
export const selectLoading = (state) => state[slice.name].loading

export const { resetStore, changeFilter } = slice.actions

register(slice.name, slice.reducer)
