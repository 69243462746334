export const tableConstants = [
  {
    id: 'NIIV',
    name: 'sector.financialAnalysis.bank.profitability.NET_AND_SIMILAR',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'NIIYG',
    name: 'sector.financialAnalysis.bank.profitability.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NFIV',
    name: 'sector.financialAnalysis.bank.profitability.NET_AND_COMMISSION',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'NFIYG',
    name: 'sector.financialAnalysis.bank.profitability.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NIRAV',
    name: 'sector.financialAnalysis.bank.profitability.NET_FROM_ACTIVITIES',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'NIRYG',
    name: 'sector.financialAnalysis.bank.profitability.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'TOI',
    name: 'sector.financialAnalysis.bank.profitability.TOTAL_OPERATING_INCOME',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'TOIG',
    name: 'sector.financialAnalysis.bank.profitability.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'RT0252',
    name: 'sector.financialAnalysis.bank.profitability.COST_INCOME_RATIO',
    isYoyGrowth: false,
    isPercent: true,
  },
  {
    id: 'RT0285',
    name: 'sector.financialAnalysis.bank.profitability.NIM',
    isYoyGrowth: false,
    isPercent: true,
  },
  {
    id: 'RT0286',
    name: 'sector.financialAnalysis.bank.profitability.YOEA',
    isYoyGrowth: false,
    isPercent: true,
  },
  {
    id: 'RT0287',
    name: 'sector.financialAnalysis.bank.profitability.COF',
    isYoyGrowth: false,
    isPercent: true,
  },
]

export const tableFormat = {
  NIIV: 10 ** 9,
  NIIYG: 1,
  NFIV: 10 ** 9,
  NFIYG: 1,
  NIRAV: 10 ** 9,
  NIRYG: 1,
  TOI: 10 ** 9,
  TOIG: 1,
  RT0252: 1,
  RT0285: 1,
  RT0286: 1,
  RT0287: 1,
}
