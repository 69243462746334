import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelDirectorDeal from './PanelDirectorDeal'
import PanelNetValueBreakdown from './PanelNetValueBreakdown'
import PanelNetValueOfInternalTrading from './PanelNetValueOfInternalTrading'

const MAP_KEY = {
  NET_VALUE_OF_INTERNAL_TRADING: 'NET_VALUE_OF_INTERNAL_TRADING',
  DEAL: 'DEAL',
  NET_VALUE_BREAKDOWN: 'NET_VALUE_BREAKDOWN',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING]: {
    minWidth: 500,
    minHeight: 300,
  },
  [MAP_KEY.NET_VALUE_BREAKDOWN]: {
    minWidth: 500,
    minHeight: 300,
  },
  [MAP_KEY.DEAL]: {
    minWidth: 1000 + 2 * COMPONENT.MARGIN,
    minHeight: 600,
  },
}

const minWidthResponsive =
  MIN_SIZE_SETTING[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING].minWidth +
  MIN_SIZE_SETTING[MAP_KEY.DEAL].minWidth +
  COMPONENT.MARGIN * 2

const MAP_SIZE = {
  [`${MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NET_VALUE_BREAKDOWN,
        isResizeWithParent: true,
        ratioWidth: -1,
        ratioLeft: 0,
      },
      {
        key: MAP_KEY.DEAL,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.NET_VALUE_BREAKDOWN,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [`${MAP_KEY.DEAL}`]: {
    width: `calc(200% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: `calc(100% /3 + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.DEAL],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [`${MAP_KEY.NET_VALUE_BREAKDOWN}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.NET_VALUE_BREAKDOWN],
    minLeft: 0,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING].minHeight +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const MIN_SIZE_SETTING_SCREEN_SMALL = {
  [MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING]: {
    minWidth: 540,
    minHeight: 420,
  },
  [MAP_KEY.NET_VALUE_BREAKDOWN]: {
    minWidth: 540,
    minHeight: 420,
  },
  [MAP_KEY.DEAL]: {
    minWidth: 1080 + 2 * COMPONENT.MARGIN,
    minHeight: 420,
  },
}

export const MAP_SIZE_SCREEN_SMALL = {
  [`${MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NET_VALUE_BREAKDOWN,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.DEAL,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.NET_VALUE_BREAKDOWN,
        ratioHeight: -1,
        ratioTop: 0,
      },
    ],
  },
  [`${MAP_KEY.DEAL}`]: {
    width: `100%`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.DEAL],
    minLeft: 0,
    minTop:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING]
        .minHeight + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NET_VALUE_BREAKDOWN}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: `calc(100% /2 + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.NET_VALUE_BREAKDOWN],
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.NET_VALUE_BREAKDOWN].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelNetValueOfInternalTrading
        panelRefs={panelRefs}
        panelKey={MAP_KEY.NET_VALUE_OF_INTERNAL_TRADING}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelDirectorDeal
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DEAL}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelNetValueBreakdown
        panelRefs={panelRefs}
        panelKey={MAP_KEY.NET_VALUE_BREAKDOWN}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SCREEN_SMALL,
  minWidthResponsive,
)(Content)
