import { useSelector } from 'react-redux'
import UseTimeZone from '../../common/hooks/useTimeZone'
import { Span } from '../../common/html/Span'
import TextEllipsis from '../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import { ChartSetting } from '../common/setting/ChartSetting'
import style from './PanelFooter.module.css'

export const PanelFooter = ({ item, index, renameChart, duplicateChart }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()
  const isPositionCopyRight = (index + 1) % 4

  return (
    <div className={style.container}>
      <div className={style.content}>
        <Span style={{ fontSize: 14, fontWeight: 500, width: '100%' }}>
          <TextEllipsis text={item.name} />
        </Span>
        <div className="align-center">
          <Span style={{ fontSize: 11 }}>
            {formatDateTime(
              item.updateDate,
              FORMAT.DATE_TIME,
              locale,
              timezone,
            )}
          </Span>
        </div>
      </div>
      <ChartSetting
        item={item}
        renameChart={renameChart}
        positionCopyToLeft={!isPositionCopyRight}
        duplicateChart={duplicateChart}
      />
    </div>
  )
}
