import { randomOneColor } from '../../../../../utils/Color'
import { COLORS } from '../../constant'

export const getValueInTrillion = (val) => val * 10 ** 9

export const getExistColors = (organizations) => {
  let result = []
  Object.values(organizations).forEach((org) => {
    if (!result.includes(org.color)) {
      result.push(org.color)
    }
    if (org.prevGroups && org.prevGroups.length) {
      org.prevGroups.forEach((group) => {
        if (group.color && !result.includes(group.color)) {
          result.push(group.color)
        }
      })
    }
  })
  return result
}

export const randomColor = (organizations = {}) => {
  let result

  const existColors = getExistColors(organizations)

  for (const color of COLORS) {
    if (!existColors.includes(color)) {
      result = color
      break
    }
  }

  return result || randomOneColor({ ignoreColors: existColors, threshold: 12 })
}

export const randomCompanyColor = (organizations = {}) => {
  let result

  const existColors = Object.values(organizations)
    .map((org) => org.companyColor)
    .filter(Boolean)

  for (const color of COLORS) {
    if (!existColors.includes(color)) {
      result = color
      break
    }
  }

  return result || randomOneColor({ ignoreColors: existColors, threshold: 12 })
}
