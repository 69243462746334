import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectActiveMethodType } from '../store/slice'
import { ChartComponent } from './ChartComponent'
// import { valueTimefrequency } from './constants'
import { I18n } from 'react-redux-i18n'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import { selectMethodType } from '../store/slice'
import { ChartCashAll } from './ChartCashAll'
import { jobType, latePaymentTypeValue } from './constants'
import FilterCountry from './dropdown'
import {
  selectActiveTab,
  selectCouponTypeTab,
  selectDataAll,
  selectDataChart,
  selectIds,
  selectLoading,
} from './store/slice'
import { getDataCashfollowAll, getDataValueOfBonds } from './store/thunk'

const arrayColor = ['#185999', '#0096EB', '#FACC5C', '#A6CF98', '#FA9AA7']

const textContentTooltip = (timeType) => {
  switch (timeType) {
    case latePaymentTypeValue.ALL:
      return I18n.t('bond.overview.expectedCash.chart.valueCashAll')
    case latePaymentTypeValue.Origin:
      return I18n.t('bond.overview.expectedCash.chart.valueCashCoupon')
    default:
      return I18n.t('bond.overview.expectedCash.chart.valueCashPrincipal')
  }
}

const textContentTooltip2 = (timeType) => {
  switch (timeType) {
    case latePaymentTypeValue.ALL:
      return I18n.t('bond.overview.expectedCash.chart.debtDueAll')
    case latePaymentTypeValue.Origin:
      return I18n.t('bond.overview.expectedCash.chart.debtDueCoupon')
    default:
      return I18n.t('bond.overview.expectedCash.chart.debtDuePrincipal')
  }
}

const Content = ({ width, height }) => {
  const activeMethod = useSelector(selectMethodType)
  const methodJob = useSelector(selectActiveTab)
  const dataCashAll = useSelector(selectDataAll)
  const dispatch = useDispatch()
  const TimeRangeActive = useSelector(selectActiveMethodType)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const methodType = useSelector(selectCouponTypeTab)
  const ids = useSelector(selectIds)

  const [listCheckbox, setListCheckbox] = useState([])
  const [listCheckboxAll, setListCheckboxAll] = useState(['value', 'valueB'])

  useEffect(() => {
    if (methodJob === jobType.ALL) {
      dispatch(
        getDataCashfollowAll({
          TimeRange: TimeRangeActive,
          ReleaseMethodType: activeMethod,
          CouponType: methodType,
        }),
      )
    } else {
      dispatch(
        getDataValueOfBonds({
          TimeRange: TimeRangeActive,
          MethodType: activeMethod,
          CouponType: methodType,
        }),
      )
    }
  }, [TimeRangeActive, activeMethod, methodType, methodJob])

  const formattedArrayBar = ids
    .map((number) => `${number}-value`)
    .sort((a, b) => (a.includes('0') ? 1 : b.includes('0') ? -1 : 0))

  useEffect(() => {
    setListCheckbox([...formattedArrayBar])
  }, [ids])

  const checkFooter = () => {
    const data = ids.map((id, index) => {
      const foundItem = dataChart.find((item) => item[`${id}-name`])
      return {
        text: foundItem[`${id}-name`],
        dataKey: `${id}-value`,
        type: TYPE.SQUARE,
        before: {
          bgColor: arrayColor[index],
        },
      }
    })
    return data
  }

  const checkFooterAll = () => {
    return [
      {
        text: textContentTooltip(methodType).replace(/:/g, ''),
        // dataKey: 'value',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#0096EB',
        },
      },
      {
        text: textContentTooltip2(methodType).replace(/:/g, ''),
        // dataKey: 'value',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#FACC5C',
        },
      },
    ]
  }
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterCountry />
            {methodJob === jobType.ALL && size.height && (
              <div
                style={{ height: height - size.height, position: 'relative' }}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataCashAll.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div
                              className="position-relative"
                              id="FDIExpectedCash"
                            >
                              {size.height && (
                                <ChartCashAll
                                  data={dataCashAll}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'date'}
                                  displayData={listCheckboxAll}
                                  num1={'value'}
                                  num2={'valueB'}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={checkFooterAll()}
                                listCheckbox={listCheckboxAll}
                                setListCheckbox={(e) => setListCheckboxAll(e)}
                                // numItemPerRow={2}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
            {methodJob === jobType.JOB && size.height && (
              <div
                style={{ height: height - size.height, position: 'relative' }}
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChart.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div
                              className="position-relative"
                              id="FDIExpectedCash"
                            >
                              {size.height && (
                                <ChartComponent
                                  data={dataChart}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'XAxis'}
                                  displayData={listCheckbox}
                                  ids={formattedArrayBar}
                                  listfooter={checkFooter()}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={checkFooter()}
                                listCheckbox={listCheckbox}
                                setListCheckbox={(e) => setListCheckbox(e)}
                                numItemPerRow={3}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Content
