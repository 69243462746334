import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { Table } from '../../../../common/table'
import { TABLE_ID } from '../CommoditiesSearch'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { DATA_TYPE_LIST, LIMIT_FETCH, LIMIT_FIRST_FETCH } from './constants'
import OtherCellColumn from './OtherCellColumn'
import {
  addCategoryToList,
  changeCommodity,
  handleIsFirstTime,
  removeCategoryFromList,
  selectActiveItem,
  selectCategoryDataTableById,
  selectCategoryIds,
  selectDataTable,
  selectGroupColumns,
  selectImportExportDataType,
  selectIsFirstTime,
  selectLevelCollapse,
  selectLimitQuarter,
  selectListCategory,
  selectLoadingTable,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectOrigParentCategory,
  selectRowsCollapse,
  selectTypeFilter,
  selectUnCollapseIds,
  setLimitQuarter,
  setRowsCollapse,
  setUnCollapseIds,
  sort,
} from './store/slice'
import {
  getExportByProductAfterScroll,
  getExportByProductChildren,
  getExportByProductChildrenAfterScroll,
  getImportByProductAfterScroll,
  getImportByProductChildren,
  getImportByProductChildrenAfterScroll,
} from './store/thunk'
import { TdSelect } from './TdSelect'

const HORIZONTAL_TRACK_LEFT = 28
const LIMIT_SELECTION = 20
const WIDTH_FIRST_COL = 320

const TableComponent = ({ height, currentTypeData }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoadingTable)
  const ids = useSelector(selectCategoryIds)
  const dataTable = useSelector(selectDataTable)
  const listOrigParentCategory = useSelector(selectOrigParentCategory)
  const listCategory = useSelector(selectListCategory)
  const levelCollapse = useSelector(selectLevelCollapse)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const importExportDataType = useSelector(selectImportExportDataType)
  const activeCommodity = useSelector(selectActiveItem)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const groupColumns = useSelector(selectGroupColumns)
  const isFirstTime = useSelector(selectIsFirstTime)
  const limitQuarter = useSelector(selectLimitQuarter)
  const unCollapseIds = useSelector(selectUnCollapseIds)

  const [isShowWarning, setIsShowWarning] = useState(false)

  const getItemById = (id) => {
    return listOrigParentCategory?.find((item) => item.id === id)
  }

  const isListCategoryIncludeItem = (id) => {
    return listCategory?.some((item) => item.id === id)
  }

  const onRowClick = (rowId) => {
    if (getItemById(rowId)?.isCollapse) {
      if (!isListCategoryIncludeItem(rowId)) {
        if (listCategory.length < LIMIT_SELECTION) {
          const category = {
            id: rowId,
            name: getItemById(rowId)?.name,
            vnTypeId: getItemById(rowId)?.vnTypeId,
          }
          dispatch(addCategoryToList(category))
        }
      } else {
        dispatch(removeCategoryFromList(rowId))
      }
    }
  }

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
      if (!unCollapseIds.includes(rowId)) {
        dispatch(setUnCollapseIds([rowId, ...unCollapseIds]))
        if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
          dispatch(
            getImportByProductChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              vnTypeIds: rowId,
            }),
          )
        } else {
          dispatch(
            getExportByProductChildren({
              DataType: currentTypeFilter,
              Limit: limitQuarter
                ? limitQuarter + LIMIT_FIRST_FETCH
                : LIMIT_FIRST_FETCH,
              vnTypeIds: rowId,
            }),
          )
        }
      }
    }
  }

  const scrollToLeftCallback = () => {
    dispatch(handleIsFirstTime(false))
    dispatch(setLimitQuarter(limitQuarter + LIMIT_FETCH))
    if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
      dispatch(
        getImportByProductAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseIds.length) {
        dispatch(
          getImportByProductChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            vnTypeIds: unCollapseIds.toString(),
          }),
        )
      }
    } else {
      dispatch(
        getExportByProductAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseIds.length) {
        dispatch(
          getExportByProductChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            vnTypeIds: unCollapseIds.toString(),
          }),
        )
      }
    }
  }

  return (
    <div id={TABLE_ID} style={{ height }}>
      <Table
        ids={ids}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        sort={sort}
        resizable={false}
        getDataFromRedux={selectCategoryDataTableById}
        isLoading={isLoading}
        columnDraggable={false}
        hasTooltip={false}
        hasFooter={false}
        onRowClick={onRowClick}
        stickyFirstColumn
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          let result = {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
            },
          }
          if (index === 0) {
            result = {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                width: WIDTH_FIRST_COL,
              },
              tdStyle: {
                textAlign: 'left',
                width: WIDTH_FIRST_COL,
              },
              render: (val, rowId) => {
                return (
                  <TdSelect
                    val={val}
                    rowId={rowId}
                    onRowCollapse={onRowCollapse}
                    data={dataTable}
                    isRowCollapse={rowsCollapse.includes(rowId)}
                    width={WIDTH_FIRST_COL}
                  />
                )
              },
            }
          }
          if (index !== 0) {
            result = {
              ...result,
              thStyle: {
                minWidth: 150,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                return (
                  <OtherCellColumn
                    val={val}
                    props={props}
                    isGrowth={
                      dataTable.length
                        ? dataTable[0].type === DATA_TYPE_LIST.GROWTH
                        : ''
                    }
                  />
                )
              },
            }
          }
          return result
        })}
        isCollapse={true}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        changeActiveRow={changeCommodity}
        defaultActiveRowId={activeCommodity?.id ?? listCategory[0]?.id}
        scrollToLeftCallback={scrollToLeftCallback}
        defaultScrollToRight={isFirstTime}
        isLazyLoadLeft={true}
      />
      <PopupAlert
        message={I18n.t('economy.importExport.importExport.WARNING_MESS')}
        isShow={isShowWarning}
        handleClose={() => setIsShowWarning(false)}
        zIndexOverlay={12}
      />
    </div>
  )
}

export default TableComponent
