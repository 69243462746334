import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab.js'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice.js'
import { REMAINING_MATURITIES_TABS } from '../constants.js'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectFilterRemainingMaturities,
} from '../store/slice.js'
import {
  getCouponInterestRateData,
  getRemainingMaturitiesData,
} from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const { activeTab } = useSelector(selectFilterRemainingMaturities)
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)

  const handleChangeActiveTab = (item) => {
    dispatch(
      changeFilter({
        label: keys.REMAINING_MATURITIES,
        key: 'activeTab',
        value: item.value,
      }),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId && activeTab) {
      const payload = {
        OrganizationId: basicInfo.organizationId,
      }

      dispatch(
        activeTab === 1
          ? getRemainingMaturitiesData(payload)
          : getCouponInterestRateData(payload),
      )
    }
  }, [locale, activeTab, basicInfo.organizationId])

  return (
    <div className="d-flex mb-8">
      <div className={`${style.menuContainer}`}>
        <DispatchActionTab
          data={REMAINING_MATURITIES_TABS}
          activeTab={activeTab}
          onChangeTab={handleChangeActiveTab}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
      </div>
    </div>
  )
}

export default Filter
