import { STATEMENT_CONSTANTS } from '../constants'

export const TIME_FILTER_VALUE = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  SIX_MONTHS: '6 Months',
  NINE_MONTHS: '9 Months',
}

export const STATEMENT = [
  {
    name: 'corporate.financialData.financialStatement.BALANCE_SHEET',
    value: STATEMENT_CONSTANTS.BALANCE_SHEET,
  },
  {
    name: 'corporate.financialData.financialStatement.INCOME_STATEMENT',
    value: STATEMENT_CONSTANTS.INCOME_STATEMENT,
  },
  {
    name: 'corporate.financialData.financialStatement.CASH_FLOW',
    value: STATEMENT_CONSTANTS.CASH_FLOW,
  },
  {
    name: 'corporate.financialData.financialStatement.NOTE',
    value: STATEMENT_CONSTANTS.NOTE,
  },
]

export const VIEW_BY = [
  {
    name: 'corporate.financialData.common.YEARLY',
    value: TIME_FILTER_VALUE.YEARLY,
  },
  {
    name: 'corporate.financialData.common.QUARTERLY',
    value: TIME_FILTER_VALUE.QUARTERLY,
  },
  {
    name: 'corporate.financialData.common.SIX_MONTHS',
    value: TIME_FILTER_VALUE.SIX_MONTHS,
  },
  {
    name: 'corporate.financialData.common.NINE_MONTHS',
    value: TIME_FILTER_VALUE.NINE_MONTHS,
  },
]

export const NUMBER_OF_PERIOD = [...new Array(11)].map((_, index) =>
  !index
    ? { name: 'corporate.financialData.common.ALL', value: 1000 }
    : {
        name: String(index),
        value: String(index),
      },
)

export const TYPE = [
  {
    name: 'corporate.financialData.common.CONSOLIDATED',
    value: 'true',
  },
  {
    name: 'corporate.financialData.common.SEPARATED',
    value: 'false',
  },
]

export const LASTEST_YEAR = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: String(year - v),
  }
})

export const COMMON_SIZE = [
  {
    name: 'corporate.financialData.financialStatement.YES',
    value: 'true',
  },
  {
    name: 'corporate.financialData.financialStatement.NO',
    value: 'false',
  },
]

export const UNIT_VALUE_CONSTANTS = {
  VND: 'VND',
  MILLION_VND: 'millionVND',
  BILLION_VND: 'billionVND',
  USD: 'USD',
  THOUSAND_USD: 'thousandUSD',
  MILLION_USD: 'millionUSD',
}

export const CURRENCY = [
  {
    name: 'corporate.financialData.financialStatement.VND',
    value: UNIT_VALUE_CONSTANTS.VND,
  },
  {
    name: 'corporate.financialData.financialStatement.USD',
    value: UNIT_VALUE_CONSTANTS.USD,
  },
]

export const UNIT = {
  VND: [
    {
      name: 'corporate.financialData.financialStatement.VND',
      value: UNIT_VALUE_CONSTANTS.VND,
    },
    {
      name: 'corporate.financialData.financialStatement.MILLION_VND',
      value: UNIT_VALUE_CONSTANTS.MILLION_VND,
    },
    {
      name: 'corporate.financialData.financialStatement.BILLION_VND',
      value: UNIT_VALUE_CONSTANTS.BILLION_VND,
    },
  ],
  USD: [
    {
      name: 'corporate.financialData.financialStatement.USD',
      value: UNIT_VALUE_CONSTANTS.USD,
    },
    {
      name: 'corporate.financialData.financialStatement.THOUSAND_USD',
      value: UNIT_VALUE_CONSTANTS.THOUSAND_USD,
    },
    {
      name: 'corporate.financialData.financialStatement.MILLION_USD',
      value: UNIT_VALUE_CONSTANTS.MILLION_USD,
    },
  ],
}

export const FILTER_OPTIONS = [
  {
    title: 'corporate.financialData.common.STATEMENT',
    options: STATEMENT,
    field: 'statement',
    type: 'ratio',
  },
  {
    title: 'corporate.financialData.common.VIEW_BY',
    options: VIEW_BY,
    field: 'viewBy',
    type: 'select',
  },
  {
    title: 'corporate.financialData.common.NUMBER_OF_PERIOD',
    options: NUMBER_OF_PERIOD,
    field: 'numberOfPeriod',
    type: 'select',
  },
  {
    title: 'corporate.financialData.common.LASTEST_YEAR',
    options: LASTEST_YEAR,
    field: 'lastestYear',
    type: 'select',
  },
  {
    title: 'corporate.financialData.common.TYPE',
    options: TYPE,
    field: 'type',
    type: 'ratio',
  },
  {
    title: 'corporate.financialData.common.COMMON_SIZE',
    options: COMMON_SIZE,
    field: 'commonSize',
    type: 'ratio',
    layout: 'vertical',
  },
  {
    title: 'corporate.financialData.common.CURRENCY',
    options: CURRENCY,
    field: 'currency',
    type: 'ratio',
    layout: 'vertical',
  },
  {
    title: 'corporate.financialData.common.UNIT',
    options: UNIT,
    field: 'unit',
    type: 'select',
  },
]
