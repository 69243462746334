import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { formatVal } from '../../../../utils/Value'

const BAR_COLORS = ['#176491', '#d2e5ff']
const LINE_COLOR = '#f7b80c'
const YAXIS_LEFT = 'YAXIS_LEFT'
const YAXIS_RIGHT = 'YAXIS_RIGHT'

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKeys,
  lineKey,
}) => {
  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.costOfOperation.COST_OF_OPERATION',
        ),
        value: formatVal(
          tooltipData[barKeys[0]],
          decimalLengths[YAXIS_LEFT] || 2,
        ),
        unit: I18n.t(
          'corporate.businessModel.costOfOperation.CHART_BILLION_VND',
        ),
      },
      {
        label: I18n.t('corporate.businessModel.costOfOperation.REVENUE'),
        value: formatVal(
          tooltipData[barKeys[1]],
          decimalLengths[YAXIS_LEFT] || 2,
        ),
        unit: I18n.t(
          'corporate.businessModel.costOfOperation.CHART_BILLION_VND',
        ),
      },
      {
        label: I18n.t(
          'corporate.businessModel.costOfOperation.COST_PER_REVENUE',
        ),
        value: formatVal(
          tooltipData[lineKey],
          decimalLengths[YAXIS_RIGHT] || 2,
        ),
        unit: '%',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {el.value} {el.unit}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                margin={{ ...MARGIN_RECHARTS, right: 12 }}
                yAxis={[
                  {
                    id: YAXIS_LEFT,
                    keys: barKeys,
                    orientation: 'left',
                    isBarChart: true,
                    label: I18n.t(
                      'corporate.businessModel.penetrationAnalysis.BILLION',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                  },
                  {
                    id: YAXIS_RIGHT,
                    keys: [lineKey],
                    isLineChart: true,
                    orientation: 'right',
                    labelPosition: AXIS_LABEL_POSITION.RIGHT,
                    unitYAxis: '%',
                  },
                ]}
                renderCustomTooltip={renderTooltip}
              >
                {({ chartContentWidth }) => (
                  <>
                    <Bar
                      yAxisId={YAXIS_LEFT}
                      dataKey={barKeys[0]}
                      barSize={getColumnSizeInBarChart(
                        chartContentWidth,
                        data.length,
                      )}
                      fill={BAR_COLORS[0]}
                      isAnimationActive={false}
                    />
                    <Bar
                      yAxisId={YAXIS_LEFT}
                      dataKey={barKeys[1]}
                      barSize={getColumnSizeInBarChart(
                        chartContentWidth,
                        data.length,
                      )}
                      fill={BAR_COLORS[1]}
                      isAnimationActive={false}
                    />
                    <Line
                      yAxisId={YAXIS_RIGHT}
                      dataKey={lineKey}
                      type="linear"
                      stroke={LINE_COLOR}
                      fill={LINE_COLOR}
                      strokeWidth={2}
                      dot={{
                        strokeWidth: getRadiusOfScatter(
                          chartContentWidth,
                          data.length,
                        ),
                      }}
                      activeDot={false}
                      isAnimationActive={false}
                    />
                  </>
                )}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'corporate.businessModel.costOfOperation.COST_OF_OPERATION',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[0],
                  },
                },
                {
                  text: 'corporate.businessModel.costOfOperation.REVENUE',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[1],
                  },
                },
                {
                  text: 'corporate.businessModel.costOfOperation.COST_PER_REVENUE',
                  type: TYPE.CIRCLE_MARKER_LINE,
                  before: {
                    bgColor: LINE_COLOR,
                  },
                  after: {
                    bgColor: LINE_COLOR,
                  },
                },
              ]}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
