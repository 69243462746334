import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { quarters } from '../../constants'
import { changeIsGetData } from '../tableSector/store/slice'
import { changeQuarter, selectQuarter } from './store/slice'

export const QuarterSelectOption = () => {
  const dispatch = useDispatch()

  const quarter = useSelector(selectQuarter)

  const onChange = (val) => {
    dispatch(changeQuarter(val.value))
    dispatch(changeIsGetData(true))
  }

  return (
    <div className="ml-8">
      <DispatchActionTab
        data={Object.keys(quarters).map((key) => {
          return {
            title: I18n.t(`sector.sectorStatistics.overview.${key}`),
            value: quarters[key],
          }
        })}
        activeTab={quarter}
        onChangeTab={onChange}
      />
    </div>
  )
}
