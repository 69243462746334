import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'

const POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
}

export const BlockBarItem = ({
  leftValue,
  rightValue,
  color,
  widthColor,
  positionColor = POSITION.LEFT,
}) => {
  const getPositionColor = () => {
    switch (positionColor) {
      case POSITION.RIGHT:
        return 'flex-end'
      default:
        return POSITION.LEFT
    }
  }

  return (
    <div
      className="d-flex position-relative"
      style={{
        height: 30,
        justifyContent: getPositionColor(),
      }}
    >
      <Span
        style={{ left: 8, position: 'absolute' }}
        className="align-center h-100"
      >
        <Span className="canvas-chart-title">{leftValue}</Span>
      </Span>
      <Span
        style={{ right: 8, position: 'absolute' }}
        className="align-center h-100"
      >
        <Span className="canvas-chart-title">{rightValue}</Span>
      </Span>
      <div
        style={{ width: widthColor, backgroundColor: color }}
        className="h-100"
      ></div>
    </div>
  )
}

BlockBarItem.propTypes = {
  leftValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  color: PropTypes.string,
  widthColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  positionColor: PropTypes.string,
}

BlockBarItem.defaultProps = {
  positionColor: POSITION.left,
}
