import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { MARGIN_RECHARTS } from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { titleData } from '../../common/helper'
import { customYAxisTick, getItemTextWidth, xAxisLabelCustom } from '../helper'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}
const margin = { ...MARGIN_RECHARTS, left: 10, top: 10 }

const ChartComponent = ({ data, width, height, keyYAxis, barKeys }) => {
  const fontSize = getFontSize(11)

  const renderTooltip = (tooltipData) => {
    const tooltipContentData = [
      {
        label: titleData(tooltipData.month, tooltipData.year),
        value: barKeys[1],
        unit: 'economy.fdi.overview.MILLION_USD',
      },
      {
        label: titleData(tooltipData.month, tooltipData.year - 1),
        value: barKeys[0],
        unit: 'economy.fdi.overview.MILLION_USD',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyYAxis]}</Span>
        </div>
        {tooltipContentData.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(el.label)}:
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(tooltipData[el.value]) + ' ' + I18n.t(el.unit)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      xAxisType="number"
      keyXAxis={barKeys}
      yAxis={[
        {
          id: 'left',
          orientation: 'left',
          keys: [keyYAxis],
          tickNum: SETTINGS.yTickNum,
          type: 'category',
          tickFormatter: (val) => val,
          tick: customYAxisTick(fontSize),
          yAxisWidth: Math.min(getItemTextWidth(data, fontSize), width / 4),
          interval: 0,
        },
      ]}
      layout="vertical"
      renderCustomTooltip={renderTooltip}
      margin={margin}
      xAxisLabelCustom={xAxisLabelCustom}
      isShowOriginalXAxis={false}
    >
      {barKeys.map((barKey, index) => (
        <React.Fragment key={index}>
          <Bar
            yAxisId="left"
            isAnimationActive={false}
            dataKey={barKey}
            fill={index === 0 ? '#185999' : '#facc5c'}
          />
        </React.Fragment>
      ))}
    </ChartContainer>
  )
}

export default ChartComponent

ChartComponent.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  keyYAxis: PropTypes.string.isRequired,
  barKeys: PropTypes.array.isRequired,
}
