import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import { Button } from '../html/Button'
import Popup from './index'
import style from './index.module.css'

const PopupAlertSuccess = ({
  message,
  isShow,
  handleClose,
  zIndexOverlay,
  zIndex,
  buttonText,
  isShowButton,
  modalStyle,
}) => {
  return (
    <>
      {isShow && (
        <Popup zIndex={zIndex} zIndexOverlay={zIndexOverlay}>
          <div style={modalStyle} className="modal alert">
            <div
              style={{ height: 40, padding: '0 8px' }}
              className="d-flex ali-center justify-content-end"
            >
              <a onClick={handleClose} className={`${style.closeIcon}`}>
                <i
                  className="icon-delete-stroke"
                  style={{ color: '#E5E5E5', fontSize: 10 }}
                />
              </a>
            </div>
            <div
              className="modal-content"
              style={{ paddingTop: 0, paddingBottom: 16 }}
            >
              <div
                className="flex-column ali-center justify-content-center"
                style={{ gap: 16, minHeight: 140 }}
              >
                <img src="/iconLike.svg" alt="Like" width={46} height={46} />
                <div style={{ flex: 1 }}>{message}</div>

                {isShowButton && (
                  <div
                    className="d-flex ali-center"
                    style={{ alignSelf: 'flex-end' }}
                  >
                    <Button
                      onClick={handleClose}
                      className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value={buttonText} />
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

PopupAlertSuccess.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  zIndexOverlay: PropTypes.number,
  buttonText: PropTypes.string,
  isShowButton: PropTypes.bool,
  modalStyle: PropTypes.object,
}

PopupAlertSuccess.defaultProps = {
  zIndex: Z_INDEX.MODAL,
  zIndexOverlay: Z_INDEX.MODAL + 1,
  buttonText: 'common.button.BUTTON_CLOSE',
  isShowButton: true,
  modalStyle: {},
}

export default PopupAlertSuccess
