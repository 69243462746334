import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getCoveredWarrantSummary } from './thunk'

const initialState = {
  data: {},
  loading: true,
}

export const slice = createSlice({
  name: 'market/coveredWarrant/summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCoveredWarrantSummary.pending, (state) => {
      state.loading = true
      state.data = {}
    })
    builder.addCase(getCoveredWarrantSummary.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getCoveredWarrantSummary.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectDataById = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
