import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchSector } from '../store/slice'
import { getSearchSectorData } from '../store/thunk'
import { SecOverviewItem } from './SecOverviewItem'

export const SecOverview = (props) => {
  const { searchStr, open, onClickCollapse, getBasicInfo } = props
  const dispatch = useDispatch()
  const sector = useSelector(selectSearchSector)

  const onClickPrevPage = () => {
    if (sector.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchSectorData({
          Term: searchStr,
          Page: sector.data.page - 1,
          PageSize: sector.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (sector.data.page === sector.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchSectorData({
          Term: searchStr,
          Page: sector.data.page + 1,
          PageSize: sector.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="sec-over"
      title={`${UseI18n('search.fullSearch.SEC_OVERVIEW')} (${
        sector.data.totalRecords
      })`}
      blockData={sector}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!sector.data.items.length &&
        sector.data.items.map((item) => (
          <SecOverviewItem
            key={item.icbId}
            searchStr={searchStr}
            data={item}
            getBasicInfo={getBasicInfo}
          />
        ))}
    </SearchCollapseBlock>
  )
}

SecOverview.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
