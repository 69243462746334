import { createSlice } from '@reduxjs/toolkit'
import { FORMAT, formatDateTime } from '../../../../../../utils/Datetime'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getNetValueByInvestorChartOverview,
  getNetValueByInvestorChartTicker,
} from './thunk'

const slice = createSlice({
  name: 'market/coveredWarrant/statistics/orderedStatistics/valueInvestor',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    listCheckbox: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNetValueByInvestorChartOverview.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getNetValueByInvestorChartOverview.fulfilled,
      (state, action) => {
        state.isLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            localIndividual: item.localIndividual / 1000000000,
            foreign: item.foreign / 1000000000,
            localInstitution: item.localInstitution / 1000000000,
            prorprietary: item.prorprietary / 1000000000,
            time: formatDateTime(item.tradingDateId, FORMAT.DATE),
          })) || []
        state.origData = payloadData
        state.data = payloadData
      },
    )
    builder.addCase(
      getNetValueByInvestorChartOverview.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )

    builder.addCase(getNetValueByInvestorChartTicker.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getNetValueByInvestorChartTicker.fulfilled,
      (state, action) => {
        state.isLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            localIndividual: item.localIndividual / 1000000000,
            foreign: item.foreign / 1000000000,
            localInstitution: item.localInstitution / 1000000000,
            prorprietary: item.prorprietary / 1000000000,
            closePriceAdjusted: item.closePriceAdjusted / 1000,
            time: formatDateTime(item.tradingDateId, FORMAT.DATE),
          })) || []
        state.origData = payloadData
        state.data = payloadData
      },
    )
    builder.addCase(
      getNetValueByInvestorChartTicker.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData

register(slice.name, slice.reducer)
