import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { TAB_TYPE } from '../summary/constants'

const slice = createSlice({
  name: 'market/equityTrading/priceStatistics',
  initialState: {
    tabType: TAB_TYPE.OVERVIEW,
  },
  reducers: {
    changeTabType: (state, action) => {
      state.tabType = action.payload
    },
  },
})

export const { changeTabType } = slice.actions
export const selectTabType = (state) => state[slice.name].tabType

register(slice.name, slice.reducer)
