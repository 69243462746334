import { useSelector } from 'react-redux'
import TopCompanies from '.'
import { Panel } from '../../../../common/panel'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectExchangeCode } from '../slice'
import { downloadTopPerformance } from './store/thunk'

const PanelTopCompanies = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)

  return (
    <Panel
      title={'sector.sectorConstituents.overview.TOP_COMPANIES_PERFORMANCE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadTopPerformance({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
        })
      }
    >
      <TopCompanies
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
      />
    </Panel>
  )
}

export default PanelTopCompanies
