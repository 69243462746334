import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import {
  getColumnSizeInBarChart,
  getFormat,
  getRadiusOfScatter,
} from '../../../common/chart/helper'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import ContentTooltip from '../../../common/chart/rechart/ContentTooltip'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import withLoading from '../../../common/withLoading'
import { formatDateTime } from '../../../utils/Datetime'
import { formatVal, valDivBillion, valToPercent } from '../../../utils/Value'
import style from '../index.module.css'
import {
  selectActiveSector,
  selectTimeRange,
  selectTotalValueBySector,
} from '../store/slice'

const ChartComponent = ({ widthChart, heightChart }) => {
  const timezone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const activeSector = useSelector(selectActiveSector)
  const totalValueBySector = useSelector(selectTotalValueBySector)
  const timeRange = useSelector(selectTimeRange)
  const indexInfo = useSelector(selectIndexInfo)

  const [dataChart, setDataChart] = useState([])

  useEffect(() => {
    setDataChart(
      totalValueBySector.map((item) => {
        return {
          ...item,
          sectorTotalValue: valDivBillion(item.sectorTotalValue),
          sectorPercentage: valToPercent(item.sectorPercentage, false, true),
          indexPercentage: valToPercent(item.indexPercentage, false, true),
          tradingDate: new Date(item.tradingDateId).getTime(),
        }
      }),
    )
  }, [totalValueBySector])

  const newTimeRange =
    timeRange === TIME_RANGES['1D'] ? TIME_RANGES['1W'] : timeRange

  const format = getFormat(newTimeRange)

  return (
    <div className="h-100">
      <ChartContainer
        data={dataChart}
        width={widthChart}
        height={heightChart}
        keyXAxis="tradingDate"
        margin={{ ...MARGIN_RECHARTS, left: 12 }}
        yAxis={[
          {
            id: 'left',
            keys: ['sectorPercentage', 'indexPercentage'],
            isLineChart: true,
            orientation: 'left',
            labelPosition: AXIS_LABEL_POSITION.LEFT,
            unitYAxis: '%',
          },
          {
            id: 'right',
            keys: ['sectorTotalValue'],
            isBarChart: true,
            orientation: 'right',
            label: I18n.t('market.sectorInDepth.RELATIVE_VALUE'),
            labelPosition: AXIS_LABEL_POSITION.RIGHT,
          },
        ]}
        timeFrame={newTimeRange}
        renderCustomTooltip={(data) => {
          const contentTooltip = [
            {
              label: formatDateTime(data.tradingDate, format, locale, timezone),
              value: '',
              styleLabel: { fontStyle: 'italic', fontSize: 10 },
              styleValue: { fontStyle: 'italic', fontSize: 10 },
            },
            {
              label: I18n.t('market.sectorInDepth.TOTAL_VALUE') + ':',
              value:
                formatVal(data.sectorTotalValue) +
                ' ' +
                I18n.t('market.sectorInDepth.VALUE_BILLION'),
              styleLabel: { fontSize: 11, fontWeight: 400 },
              styleValue: { fontSize: 11, fontWeight: 500 },
            },
            {
              label: activeSector + ':',
              value: formatVal(data.sectorPercentage) + '%',
              styleLabel: { fontSize: 11, fontWeight: 400 },
              styleValue: { fontSize: 11, fontWeight: 500 },
            },
            {
              label: (indexInfo.groupName || indexInfo.groupCode) + ':',
              value: formatVal(data.indexPercentage) + '%',
              styleLabel: { fontSize: 11, fontWeight: 400 },
              styleValue: { fontSize: 11, fontWeight: 500 },
            },
          ]
          return <ContentTooltip contentTooltip={contentTooltip} />
        }}
      >
        {({ chartContentWidth }) => (
          <>
            <Bar
              isAnimationActive={false}
              yAxisId="right"
              dataKey="sectorTotalValue"
              fill="#2695e7"
              barSize={getColumnSizeInBarChart(
                chartContentWidth,
                dataChart.length,
              )}
            />
            <Line
              yAxisId="left"
              dataKey="indexPercentage"
              stroke="#29ecff"
              strokeWidth={2}
              dot={{
                stroke: '#29ecff',
                strokeWidth: getRadiusOfScatter(
                  chartContentWidth,
                  dataChart.length,
                ),
                fill: '#29ecff',
              }}
              isAnimationActive={false}
              activeDot={false}
            />
            <Line
              yAxisId="left"
              dataKey="sectorPercentage"
              stroke="#f7b80c"
              strokeWidth={2}
              dot={{
                stroke: '#f7b80c',
                strokeWidth: getRadiusOfScatter(
                  chartContentWidth,
                  dataChart.length,
                ),
                fill: '#f7b80c',
              }}
              isAnimationActive={false}
              activeDot={false}
            />
          </>
        )}
      </ChartContainer>
    </div>
  )
}

ChartComponent.propTypes = {
  widthChart: PropTypes.number.isRequired,
  heightChart: PropTypes.number.isRequired,
}

const ChartValueRelative = ({ widthChart, heightChart }) => {
  const activeSector = useSelector(selectActiveSector)
  const indexInfo = useSelector(selectIndexInfo)

  const listIconChart = [
    {
      text: 'market.sectorInDepth.TOTAL_VALUE',
      type: TYPE.SQUARE,
      before: {
        bgColor: '#2695e7',
      },
    },
    {
      text: activeSector,
      type: TYPE.CIRCLE_MARKER_LINE,
      before: {
        bgColor: '#facc5c',
      },
      after: {
        bgColor: '#facc5c',
      },
    },
    {
      text: indexInfo.groupName || indexInfo.groupCode,
      type: TYPE.CIRCLE_MARKER_LINE,
      before: {
        bgColor: '#29ecff',
      },
      after: {
        bgColor: '#29ecff',
      },
    },
  ]

  return (
    <>
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <ChartComponent
                  widthChart={widthChart}
                  heightChart={heightChart - size.height}
                />
              </div>
            )}
            <div
              className={`${style.pt10px} ${
                !size.height ? style.opacity0 : ''
              }`}
            >
              <Footer key={widthChart} list={listIconChart} />
            </div>
          </>
        )}
      </SizeTracker>
    </>
  )
}

ChartValueRelative.propTypes = {
  widthChart: PropTypes.number.isRequired,
  heightChart: PropTypes.number.isRequired,
}

export default withLoading(ChartValueRelative)
