import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'
import { handleExport } from '../../../../utils/Export'

export const getManagementAndDirectors = createAsyncThunk(
  'corporate/ownership/director/GET_MANAGEMENT_AND_DIRECTORS',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getManagementAndDirectors(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadManagementAndDirectors = createAsyncThunk(
  'corporate/ownership/director/DOWNLOAD_MANAGEMENT_AND_DIRECTORS',
  async (data) => {
    const response = await OwnershipService.downloadManagementAndDirectors(data)
    handleExport(response.data, response.filename)
  },
)
