export const SESSION_TIME_OUT = 'SESSION_TIME_OUT'
export const API_TIMEOUT = 'ECONNABORTED '
export const COMPONENT_RESIZE_START = 'COMPONENT_RESIZE_START'
export const COMPONENT_RESIZE = 'COMPONENT_RESIZE'
export const DROPDOWN_RESET_POSITION = 'DROPDOWN_RESET_POSITION'
export const CALENDAR_RESET_POSITION = 'CALENDAR_RESET_POSITION'
export const FULL_COMPONENT = 'FULL_COMPONENT'
export const CLOSE_FULL_COMPONENT = 'CLOSE_FULL_COMPONENT'
export const SHOW_POPUP_ERROR = 'SHOW_POPUP_ERROR'
export const CALLBACK_SHOW_POPUP_ERROR = 'CALLBACK_SHOW_POPUP_ERROR'
export const CALLBACK_CLOSE_SHOW_POPUP_ERROR = 'CALLBACK_CLOSE_SHOW_POPUP_ERROR'
export const LOADING_TABLE_DE = 'LOADING_TABLE_DE'
export const CHANGE_LOCALE = 'CHANGE_LOCALE'
export const ON_MOUSE_OVER = 'ON_MOUSE_OVER'
export const ON_MOUSE_LEAVE = 'ON_MOUSE_LEAVE'
export const UPDATE_CURRENT_STATE = 'UPDATE_CURRENT_STATE'
export const BUTTON_TEXT_POPUP_ERROR = 'BUTTON_TEXT_POPUP_ERROR'
export const RESET_TABLE_SORT = 'RESET_TABLE_SORT'
export const ICON_STATUS_POPUP_ERROR = 'ICON_STATUS_POPUP_ERROR'
export const IS_SHOW_BUTTON_ERROR = 'IS_SHOW_BUTTON_ERROR'
export const SCROLL_COMPONENT = 'SCROLL_COMPONENT'
export const FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE =
  'FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE'
export const MODAL_STYLE_POPUP_ERROR = 'MODAL_STYLE_POPUP_ERROR'
export const MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR =
  'MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR'
export const MODAL_BUTTON_STYLE_POPUP_ERROR = 'MODAL_BUTTON_STYLE_POPUP_ERROR'
export const IS_API_ERROR_403 = 'IS_API_ERROR_403'
export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN'
export const UPDATE_AUTH_TIME_AFTER_REFRESH_TOKEN =
  'UPDATE_AUTH_TIME_AFTER_REFRESH_TOKEN'

class EventEmitter {
  events = {}

  dispatch = (event, data = null) => {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].forEach((callback) => {
      callback(data)
    })
  }

  subscribe = (event, callback) => {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event].push(callback)
  }

  unsubscribe = (event, callback) => {
    if (!this.events[event]) {
      this.events[event] = []
    }
    this.events[event] = this.events[event].filter((func) => func !== callback)
  }
}

export default new EventEmitter()
