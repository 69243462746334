export const tradingData = {
  TRADING_DATA: 'Dữ liệu giao dịch',
  FILTER: 'Lọc',
  SELECTED_ITEMS: 'Các mục đã chọn',
  EXPORT_SETTING: 'Cài đặt',
  PREVIEW: 'Bản xem trước',
  COMPARE: 'Công ty',
  INDEX_AND_SECTOR: 'Chỉ số & Ngành',
  LB_COMPANIES: 'Công ty:',
  LB_SECTORS: 'Ngành:',
  LB_INDICATORS: 'Chỉ số:',
  LB_INDICES_AND_SECTORS: 'Chỉ số & Ngành:',
  LB_TIME: 'Thời gian:',
  EXPORT_FILE: 'Kiểu xuất dữ liệu:',
  A_FILE: 'Gộp mã trong 1 file',
  MULTIPLE_FILES: 'Tách từng file theo mã',
  GROUP_BY: 'Nhóm cột dữ liệu theo:',
  DATE: 'Ngày',
  COMPANY: 'Công ty',
  SECTOR: 'Ngành',
  INDICATOR: 'Chỉ số',
  BUTTON_LOAD: 'Tải dữ liệu',
  BUTTON_SAVE: 'Lưu',
  BUTTON_EXPORT_EXCEL: 'Xuất dữ liệu',
  LB_TABLE:
    'Bản xem trước chỉ hiển thị [numIndicator] chỉ số của [numCompany] [viewType]. Xuất Excel để xem toàn bộ kết quả.',
  MULTI_FILE_NOTE_TABLE:
    'Bản xem trước chỉ hiển thị 1 file tương ứng với 1 [viewType]. Xuất Excel để xem toàn bộ kết quả.',
  VIEW_TYPE_COMPANY: 'công ty',
  VIEW_TYPE_COMPANIES: 'công ty',
  VIEW_TYPE_SECTOR: 'ngành',
  VIEW_TYPE_SECTORS: 'ngành',
  THREE_MONTH: '3 Tháng',
  SIX_MONTH: '6 Tháng',
  NINE_MONTH: '9 Tháng',
  ONE_YEAR: '1 Năm',
  YTB: 'YTB',
  LB_FROM: 'Từ',
  LB_TO: 'Đến',
  WARNING_MAX_TICKERS: 'Số lượng chỉ tiêu cài đặt thêm không vượt quá 100.',
  WARNING_MAX_INDICATORS: 'Số lượng chỉ tiêu không vượt quá 100.',
  TICKER: 'Mã',
  COMPANY_NAME: 'Tên công ty',
  NAME: 'Tên',
  UNIT: 'Đơn vị: ',
  SELECTED: 'Đã chọn: ',
  MAX_TICKER_WARNING: 'Tối đa 100 [viewType] được chọn.',
  MAX_INDICATOR_WARNING: 'Tối đa 100 chỉ số được chọn.',
  RESET: 'Reset',
  NO_DATA_TEXT_CORPORATE:
    'Tick chọn ít nhất 1 công ty và 1 chỉ tiêu để xem bản xem trước và xuất file excel.',
  NO_DATA_TEXT_INDEX_SECTOR:
    'Tick chọn ít nhất 1 chỉ số/ngành và 1 chỉ tiêu để xem bản xem trước và xuất file excel.',
  EXIST_INDEX_SECTOR: 'Chỉ số/ngành đã tồn tại.',
  EXIST_TICKER_SECTOR: 'Cổ phiếu đã tồn tại.',
}
