import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { SEARCH_TIME_FRAME } from '../constants'
import style from '../style.module.css'
import { DatePicker } from './DatePicker'
import DropdownSearchIndicator from './DropdownSearchIndicator'
import {
  changeDateFrom,
  changeDateTo,
  changeTimeFrameChart,
  selectFilter,
  setIsShow,
} from './store/slice'

const FilterDataChart = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const handleShowPopupCompare = () => {
    dispatch(setIsShow(true))
  }

  const changeTimeFrameTab = (data) => {
    dispatch(changeTimeFrameChart(data.value))
    dispatch(changeDateFrom(''))
    dispatch(changeDateTo(''))
  }

  return (
    <div className="d-flex j-b">
      <div className="d-flex justify-content-start">
        <DropdownSearchIndicator />
        <button
          className={`btn btn-add-more ${style.buttonAddCompare}`}
          onClick={() => handleShowPopupCompare()}
        >
          <i className="icon-plus"></i>
          <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
            <Translate value="market.marketInDepth.valuation.LB_COMPARE" />
          </Span>
        </button>
      </div>
      <div
        className="d-flex justify-content-end align-center"
        style={{ width: 'auto' }}
      >
        <DatePicker />
        <DispatchActionTab
          data={Object.keys(SEARCH_TIME_FRAME).map((key) => {
            return {
              title: I18n.t(`market.marketInDepth.valuation.${key}`),
              value: SEARCH_TIME_FRAME[key],
            }
          })}
          activeTab={filter.timeFrameTab}
          onChangeTab={changeTimeFrameTab}
        />
      </div>
    </div>
  )
}

export default FilterDataChart
