import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import { LIST_TYPE, MAX_TICKER, PLOT_TYPE } from '../../constant'
import {
  LOADING_TYPE,
  selectCompanies,
  selectLoading,
  selectPlotType,
} from '../../store/slice'
import { getSearchCompanies } from '../../store/thunk'
import style from './company.module.css'
import { randomColor, randomCompanyColor } from './helper'

const SEARCH_PARAMS = {
  page: 1,
  pageSize: 100,
}

const CompanyList = ({
  organizations,
  setOrganizations,
  filteredOrgIds,
  setFilteredOrgIds,
  groups,
  setGroups,
  exchanges,
  marketCap,
  keyword,
  handleOpenAlert,
}) => {
  const dispatch = useDispatch()
  const companies = useSelector(selectCompanies)
  const loading = useSelector(selectLoading(LOADING_TYPE.CHOOSE_ITEM))
  const plotType = useSelector(selectPlotType)

  useEffect(() => {
    dispatch(
      getSearchCompanies({
        Term: keyword || undefined,
        Page: SEARCH_PARAMS.page,
        PageSize: SEARCH_PARAMS.pageSize,
      }),
    )
  }, [keyword])

  const onCheckboxChange = (e, ticker) => {
    const checked = e.target.checked

    if (checked) {
      if (
        !exchanges.includes(ticker.exchangeCode.toUpperCase()) ||
        !(
          (marketCap.from || 0) <= (ticker.exchangeMarketCap || 0) &&
          (marketCap.to || Infinity) >= (ticker.exchangeMarketCap || 0)
        )
      ) {
        handleOpenAlert()
        return
      }

      const newOrganizations = JSON.parse(JSON.stringify(organizations))

      let prevGroups = []
      const oldOrg = newOrganizations[ticker.organizationId]
      if (oldOrg) {
        prevGroups = [
          ...oldOrg.prevGroups,
          {
            type: oldOrg.type,
            id: oldOrg.typeId,
            color: oldOrg.color,
          },
        ]
      }

      newOrganizations[ticker.organizationId] = {
        type: LIST_TYPE.COMPANY,
        typeId: ticker.organizationId,
        organizationId: ticker.organizationId,
        color: randomColor(organizations),
        companyColor: randomCompanyColor(organizations),
        prevGroups,
        marketCap: ticker.exchangeMarketCap || 0,
        exchange: ticker.exchangeCode,
      }

      setOrganizations(newOrganizations)
      setFilteredOrgIds([...filteredOrgIds, ticker.organizationId])
    } else {
      const { [ticker.organizationId]: deletedOrg, ...newOrganizations } =
        organizations
      let newGroups = JSON.parse(JSON.stringify(groups))

      if (
        deletedOrg.type !== LIST_TYPE.COMPANY &&
        Object.values(newOrganizations).every(
          (org) =>
            !(org.type === deletedOrg.type && org.typeId === deletedOrg.typeId),
        )
      ) {
        newGroups = newGroups.filter(
          (group) =>
            !(group.type === deletedOrg.type && group.id === deletedOrg.typeId),
        )

        Object.keys(newOrganizations).forEach((orgId) => {
          const org = newOrganizations[orgId]
          if (org.prevGroups?.length) {
            const groupIndex = org.prevGroups.findIndex(
              (group) =>
                group.type === deletedOrg.type &&
                group.id === deletedOrg.typeId,
            )
            if (groupIndex !== -1) {
              org.prevGroups.splice(groupIndex, 1)
            }
          }
        })
      }

      newGroups = newGroups.filter((group) =>
        Object.values(newOrganizations).some(
          (org) =>
            (org.type === group.type && org.typeId === group.id) ||
            org?.prevGroups?.some(
              (g) => g.type === group.type && g.id === group.id,
            ),
        ),
      )

      setGroups(newGroups)
      setOrganizations(newOrganizations)
      setFilteredOrgIds(
        filteredOrgIds.filter((id) => id !== ticker.organizationId),
      )
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : companies.length === 0 ? (
        <NoData />
      ) : (
        <ScrollComponent>
          <ul>
            {companies.map((ticker) => {
              const isCheck = filteredOrgIds.includes(ticker.organizationId)
              const disabled =
                !isCheck &&
                plotType === PLOT_TYPE.TIME_SERIES &&
                filteredOrgIds.length >= MAX_TICKER

              return (
                <li
                  key={ticker.organizationId}
                  className={`${style.tickerItem} ${
                    disabled ? style.disabled : ''
                  }`}
                >
                  <label>
                    <div className={style.checkBox}>
                      <input
                        type="checkbox"
                        className="checkbox line"
                        checked={isCheck}
                        disabled={disabled}
                        onChange={(e) => onCheckboxChange(e, ticker)}
                      />
                    </div>
                    <div className={style.tickerName}>
                      <TextEllipsis
                        isI18n={false}
                        text={
                          ticker.ticker ||
                          ticker.taxCode ||
                          ticker.organizationShortName
                        }
                      />
                    </div>
                    <div className={style.companyName}>
                      <TextEllipsis
                        isI18n={false}
                        text={
                          ticker.organizationShortName ||
                          ticker.organizationName
                        }
                      />
                    </div>
                  </label>
                </li>
              )
            })}
          </ul>
        </ScrollComponent>
      )}
    </>
  )
}

export default CompanyList
