import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import useGetICB from '../../../../../common/masterData/useGetICB'
import { NoData } from '../../../../../common/noData'
import { Search } from '../../../../../common/search'
import SelectCustom from '../../../../../common/selectCustom'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { Z_INDEX } from '../../../../../constants/Common'
import { keyBy } from '../../../../../utils/Common'
import { getColor } from '../../helpers'
import { EXCHANGE_CODE, ICB_LEVEL, MAX_ITEM } from '../constant'
import { selectLoading, selectTickerList } from '../store/slice'
import { getAllOrganizationByIcbIdAndGroupId } from '../store/thunk'
import style from './index.module.css'

const SCROLL_SECTOR = 'SCROLL_SECTOR'
const SCROLL_TICKER = 'SCROLL_TICKER'

const Sector = ({ items, setItems }) => {
  const dispatch = useDispatch()

  const currentSector = useSelector(selectSectorInfo)
  const { loading, ICBs } = useGetICB()
  const icbById = keyBy(ICBs || {}, 'icbId')
  const icbByCode = keyBy(ICBs || {}, 'icbCode')
  const loadingTicker = useSelector(selectLoading)
  let tickerList = useSelector(selectTickerList)

  const [keyword, setKeyword] = useState('')
  const [icbLevel, setIcbLevel] = useState(ICB_LEVEL[1].value)
  const [filteredSectors, setFilteredSectors] = useState([])
  const [activeIcbId, setActiveIcbId] = useState()
  const [sortedTickerList, setSortedTickerList] = useState([])

  useEffect(() => {
    const selectedOrgIds = items
      .filter((item) => !!item.organizationId)
      .map((item) => item.organizationId)

    const newSortedTickerList = [...tickerList]
    if (keyword) {
      newSortedTickerList.sort(
        (a, b) =>
          b?.ticker.toLowerCase().indexOf(keyword.toLowerCase()) -
          a?.ticker.toLowerCase().indexOf(keyword.toLowerCase()),
      )
    } else {
      newSortedTickerList.sort((a, b) => {
        return selectedOrgIds.includes(a.organizationId) &&
          !selectedOrgIds.includes(b.organizationId)
          ? -1
          : 1
      })
    }
    setSortedTickerList(newSortedTickerList)
  }, [tickerList, keyword])

  const allOrgIds = items
    .filter((item) => item.organizationId)
    .map((item) => item.organizationId)
  const isCheckSomeTicker = tickerList
    .map((item) => item.organizationId)
    .some((id) => allOrgIds.includes(id))

  const handleChangeIcb = ({ icbId, icbCode }) => {
    setActiveIcbId(icbId)

    if (items.length >= MAX_ITEM) return

    const newItems = JSON.parse(JSON.stringify(items))
    const sectorIndex = items.findIndex((item) => item.icbId === icbId)
    if (sectorIndex === -1) {
      const parentIcbCodes = icbByCode[icbCode]?.icbCodePath
        ?.split('/')
        ?.slice(0, -1)

      const icbs = newItems
        .map((item, index) => ({ ...item, index }))
        .filter((item) => !!item.icbId)

      for (let i = 0; i < icbs.length; i++) {
        const currentIcb = icbs[i]
        const currentIcbCode = icbById[currentIcb.icbId]?.icbCode
        const parentCurrentIcbCodes = icbByCode[currentIcbCode]?.icbCodePath
          ?.split('/')
          ?.slice(0, -1)

        if (parentCurrentIcbCodes.includes(icbCode)) {
          newItems.splice(currentIcb.index, 0, {
            icbId,
            color: getColor(newItems),
          })
          setItems(newItems)
          return
        }
      }

      for (let i = icbs.length - 1; i >= 0; i--) {
        const currentIcb = icbs[i]
        const currentIcbCode = icbById[currentIcb.icbId]?.icbCode
        if (parentIcbCodes.includes(currentIcbCode)) {
          let nextSectorIndex = newItems.findIndex(
            (item, index) => index > currentIcb.index && !!item.icbId,
          )
          nextSectorIndex =
            nextSectorIndex === -1 ? items.length : nextSectorIndex
          newItems.splice(nextSectorIndex, 0, {
            icbId,
            color: getColor(newItems),
          })
          setItems(newItems)
          return
        }
      }
      newItems.push({ icbId, color: getColor(newItems) })
    } else {
      newItems[sectorIndex].isDeleted = false
    }
    setItems(newItems)
  }

  const onCheckboxChange = (e) => {
    const organizationId = +e.target.value
    if (e.target.checked) {
      const sectorIndex = items.findIndex((item) => item.icbId === activeIcbId)
      let nextSectorIndex = items.findIndex(
        (item, index) => index > sectorIndex && !!item.icbId,
      )
      nextSectorIndex = nextSectorIndex === -1 ? items.length : nextSectorIndex

      const newItems = JSON.parse(JSON.stringify(items))
      newItems.splice(nextSectorIndex, 0, {
        organizationId,
        color: getColor(items),
      })
      setItems(newItems)
    } else {
      setItems(
        items.filter(
          (item) =>
            !(!!item.organizationId && item.organizationId === organizationId),
        ),
      )
    }
  }

  const onCheckAllChange = (checked) => {
    const sectorIndex = items.findIndex((item) => item.icbId === activeIcbId)
    let nextSectorIndex = items.findIndex(
      (item, index) => index > sectorIndex && !!item.icbId,
    )
    nextSectorIndex = nextSectorIndex === -1 ? items.length : nextSectorIndex

    const newItems = JSON.parse(JSON.stringify(items))
    newItems.splice(sectorIndex + 1, nextSectorIndex - sectorIndex - 1)

    if (checked) {
      sortedTickerList
        .slice(0, MAX_ITEM - newItems.length)
        .forEach((ticker, index) => {
          newItems.splice(sectorIndex + 1 + index, 0, {
            organizationId: ticker.organizationId,
            color: getColor(newItems),
          })
        })
    }
    setItems(newItems)
  }

  const handleSortSector = (icbLevel, searchText) => {
    if (!ICBs) return

    const data = ICBs.filter((item) => item.icbLevel === icbLevel)
    const searchKeyword = searchText || keyword
    if (searchKeyword) {
      data.sort(
        (a, b) =>
          b.icbName.toLowerCase().indexOf(searchKeyword.toLowerCase()) -
          a.icbName.toLowerCase().indexOf(searchKeyword.toLowerCase()),
      )
    }
    setFilteredSectors(data)
  }

  const handleChangeIcbLevel = (icbLevel) => {
    setIcbLevel(icbLevel)
    handleSortSector(icbLevel)
  }

  const handleChangeKeyword = (keyword) => {
    setKeyword(keyword.trim())
    handleSortSector(icbLevel, keyword.trim())
  }

  useEffect(() => {
    if (currentSector && ICBs) {
      const icb = ICBs.find((icb) => icb.icbId === currentSector.icbId)
      if (icb) {
        setIcbLevel(icb.icbLevel)
        setActiveIcbId(icb.icbId)
        handleSortSector(icb.icbLevel)
      } else {
        setIcbLevel(ICB_LEVEL[1].value)
        handleSortSector(ICB_LEVEL[1].value)
      }
    }
  }, [ICBs, currentSector])

  useEffect(() => {
    if (activeIcbId) {
      dispatch(
        getAllOrganizationByIcbIdAndGroupId({
          ICBId: activeIcbId,
          ExchangeCode: EXCHANGE_CODE.ALL_EXCHANGE,
        }),
      )
    }
  }, [activeIcbId])

  useEffect(() => {
    if (activeIcbId) {
      const scrollSector = document.querySelector(
        `#${SCROLL_SECTOR} .scrollbars div:first-child`,
      )
      const activeSector = scrollSector?.querySelector(`[id="${activeIcbId}"]`)
      if (
        scrollSector &&
        activeSector &&
        activeSector.offsetTop + activeSector.offsetHeight >
          scrollSector.offsetHeight
      ) {
        scrollSector.scrollTo({
          top: activeSector.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }
  }, [activeIcbId])

  useEffect(() => {
    const scrollSector = document.querySelector(
      `#${SCROLL_SECTOR} .scrollbars div:first-child`,
    )
    const scrollTicker = document.querySelector(
      `#${SCROLL_TICKER} .scrollbars div:first-child`,
    )

    if (scrollSector) {
      scrollSector.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
    if (scrollTicker) {
      scrollTicker.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }, [keyword])

  return (
    <div className={style.sector}>
      <div className={style.title}>
        <Span style={{ opacity: 0.4, display: 'block' }}>
          <Translate value="sector.sectorConstituents.valuation.SECTOR" />
        </Span>
        <div className={style.selectSector}>
          <SelectCustom
            value={icbLevel}
            isI18n={true}
            selectData={ICB_LEVEL}
            handleChange={handleChangeIcbLevel}
            dropdownSpace={4}
            renderSelect={() => {
              return (
                <div className="form-date-select">
                  <div className="date-select w-100px">
                    <TextEllipsis
                      isI18n
                      text={ICB_LEVEL.find((e) => e.value === icbLevel)?.name}
                      zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                    />
                    <span
                      className="btn-select-date"
                      style={{ top: 6, right: -4 }}
                    >
                      <i className={`icon-caret-down`} />
                    </span>
                  </div>
                </div>
              )
            }}
          />
        </div>
        <div className="flex-1" />
        <Search
          onChange={handleChangeKeyword}
          appendStyle={{
            margin: 0,
            width: 212,
          }}
        />
      </div>
      <div className={style.sectorContent}>
        {loading || !ICBs || ICBs.length === 0 ? (
          <Loading />
        ) : (
          <>
            <div className={style.sectorList} id={SCROLL_SECTOR}>
              {filteredSectors.length === 0 ? (
                <NoData />
              ) : (
                <ScrollComponent>
                  <ul>
                    {filteredSectors.map((icb) => (
                      <li
                        key={icb.icbId}
                        id={icb.icbId}
                        className={`${
                          icb.icbId === activeIcbId ? style.active : ''
                        }`}
                        onClick={() => handleChangeIcb(icb)}
                      >
                        {icb?.icbName + ' L' + icb?.icbLevel}
                      </li>
                    ))}
                  </ul>
                </ScrollComponent>
              )}
            </div>
            <div className={style.tickerList} id={SCROLL_TICKER}>
              {loadingTicker ? (
                <Loading />
              ) : sortedTickerList.length === 0 ? (
                <NoData />
              ) : (
                <ScrollComponent>
                  <>
                    <div
                      className={`${style.checkAllGroup} ${
                        items.length >= MAX_ITEM && !isCheckSomeTicker
                          ? style.disabled
                          : ''
                      }`}
                    >
                      <div
                        onClick={() =>
                          onCheckAllChange(isCheckSomeTicker ? false : true)
                        }
                      >
                        <i
                          className={
                            isCheckSomeTicker
                              ? 'icon-minus-stroke'
                              : 'icon-check-box-empty'
                          }
                        />
                      </div>
                    </div>
                    <ul>
                      {sortedTickerList.map((ticker) => {
                        const isCheck = items
                          .filter((item) => !!item.organizationId)
                          .map((item) => item.organizationId)
                          .includes(ticker.organizationId)
                        const disabled = !isCheck && items.length === MAX_ITEM
                        return (
                          <li
                            key={ticker.organizationId}
                            className={`${style.tickerItem} ${
                              disabled ? style.disabled : ''
                            }`}
                          >
                            <label>
                              <div className={style.checkBox}>
                                <input
                                  type="checkbox"
                                  className="checkbox line"
                                  checked={isCheck}
                                  disabled={disabled}
                                  value={ticker.organizationId}
                                  onChange={onCheckboxChange}
                                />
                              </div>
                              <div className={style.tickerName}>
                                <TextEllipsis
                                  isI18n={false}
                                  text={ticker.ticker}
                                />
                              </div>
                              <div className={style.companyName}>
                                <TextEllipsis
                                  isI18n={false}
                                  text={ticker.organizationShortName}
                                />
                              </div>
                            </label>
                          </li>
                        )
                      })}
                    </ul>
                  </>
                </ScrollComponent>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Sector
