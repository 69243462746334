import { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { selectDateHasEvents } from '../../economy/event/store/slice'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
} from '../../utils/Datetime'
import Dropdown from '../dropdown'
import { Button } from '../html/Button'
import HeaderOneCalendarRange from './headers/HeaderOneCalendarRangeCustorm'
import { useGetDayTitle } from './helper'
import style from './index.module.css'

const TwoCalendarRangeCustom = ({
  id,
  isShowDropdown,
  dropdownRef,
  handleHide,
  handleChangeTimeRange,
  handleChangeDates,
  setSelectedStartDate,
  setSelectedEndDate,
  active,
  selectedStartDate,
  selectedEndDate,
  position,
  datePreset,
  excludeDateIntervals,
  customInput,
  startYear,
  endYear,
  startMonth,
  endMonth,
}) => {
  const thisCalendarStart = useRef()
  const thisCalendarEnd = useRef()
  const headerRef = useRef()
  const getDayTitle = useGetDayTitle()

  const dateHasEvent = useSelector(selectDateHasEvents)
  const locale = useSelector((state) => state.i18n.locale)

  const [dateHeaderCalender, setdateHeaderCalender] = useState(
    new Date(
      new Date().getFullYear(),
      endMonth ? endMonth - 2 : new Date().getMonth() - 1,
      1,
    ),
  )
  const [dateHeaderCalenderEnd, setdateHeaderCalenderEnd] = useState(
    new Date(
      new Date().getFullYear(),
      endMonth ? endMonth - 1 : new Date().getMonth(),
      1,
    ),
  )

  // Actions
  const handleOpenCalendarStart = () => thisCalendarStart.current.setOpen(true)
  const handleCloseCalendarStart = () =>
    thisCalendarStart.current.setOpen(false)

  const handleOpenCalendarEnd = () => thisCalendarEnd.current.setOpen(true)
  const handleCloseCalendarEnd = () => thisCalendarEnd.current.setOpen(false)

  const highlightWithRanges = [
    {
      'react-datepicker__day--highlighted_event': dateHasEvent,
    },
  ]

  const handleChangeStartDate = (dates) => {
    const [startDate, endDate] = dates
    let startDateCheck = startDate
    let endDateCheck = endDate
    if (!endDate) {
      if (
        new Date(selectedStartDate).getTime() ===
        new Date(selectedEndDate).getTime()
      ) {
        if (
          new Date(startDate).getTime() >= new Date(selectedStartDate).getTime()
        ) {
          startDateCheck = selectedStartDate
          endDateCheck = startDate
        } else {
          startDateCheck = startDate
          endDateCheck = selectedStartDate
        }
      }
      if (
        new Date(selectedStartDate).getTime() !==
        new Date(selectedEndDate).getTime()
      ) {
        startDateCheck = startDate
        endDateCheck = startDate
      }
    }

    setSelectedStartDate(startDateCheck)
    setSelectedEndDate(endDateCheck)
  }

  const handleChangeEndDate = (dates) => {
    const [startDate, endDate] = dates
    let startDateCheck = startDate
    let endDateCheck = endDate
    if (!endDate) {
      if (
        new Date(selectedStartDate).getTime() ===
        new Date(selectedEndDate).getTime()
      ) {
        if (
          new Date(startDate).getTime() >= new Date(selectedStartDate).getTime()
        ) {
          startDateCheck = selectedStartDate
          endDateCheck = startDate
        } else {
          startDateCheck = startDate
          endDateCheck = selectedStartDate
        }
      }
      if (
        new Date(selectedStartDate).getTime() !==
        new Date(selectedEndDate).getTime()
      ) {
        startDateCheck = startDate
        endDateCheck = startDate
      }
    }
    setSelectedStartDate(startDateCheck)
    setSelectedEndDate(endDateCheck)
  }

  return (
    <Dropdown
      id={id}
      isShow={isShowDropdown}
      parentRef={dropdownRef}
      position={position}
    >
      <div className={`d-flex ${style.customCalendar}`}>
        <div>
          <div className={`${style.leftContainer}`}>
            <div className={style.container}>
              <div
                className={`${style.oneCalendarRange} oneCalendarRangeCustorm`}
              >
                <DatePicker
                  selected={dateHeaderCalender}
                  ref={thisCalendarStart}
                  onCalendarClose={handleCloseCalendarStart}
                  onCalendarOpen={handleOpenCalendarStart}
                  shouldCloseOnSelect={false}
                  startDate={
                    selectedStartDate ? new Date(selectedStartDate) : null
                  }
                  endDate={selectedEndDate ? new Date(selectedEndDate) : null}
                  onChange={handleChangeStartDate}
                  highlightDates={highlightWithRanges}
                  formatWeekDay={getDayTitle}
                  customInput={customInput}
                  renderCustomHeader={HeaderOneCalendarRange({
                    startMonth: startMonth,
                    endMonth: endMonth,
                    startYear: startYear || 2004,
                    endYear: endYear || +getCurrentDateTime(FORMAT.YEAR),
                    zIndex: 100,
                    headerRef: headerRef,
                    dateCustorm: dateHeaderCalender,
                    changeDateCustorm: (date) => {
                      if (date.getTime() > dateHeaderCalenderEnd.getTime()) {
                        const DateNext = new Date(
                          date.getFullYear(),
                          date.getMonth() + 1,
                          1,
                        )
                        setdateHeaderCalenderEnd(DateNext)
                      }
                      setdateHeaderCalender(date)
                    },
                  })}
                  minDate={new Date(startYear || 2004, 0)}
                  maxDate={
                    new Date(
                      endYear || new Date().getFullYear(),
                      endMonth ? endMonth : 12,
                      0,
                    )
                  }
                  inline
                  selectsRange
                  excludeDateIntervals={excludeDateIntervals}
                />
              </div>
            </div>
            <div className={style.container}>
              <div
                className={`${style.oneCalendarRange} oneCalendarRangeCustorm`}
              >
                <DatePicker
                  selected={dateHeaderCalenderEnd}
                  ref={thisCalendarEnd}
                  onCalendarClose={handleCloseCalendarEnd}
                  onCalendarOpen={handleOpenCalendarEnd}
                  shouldCloseOnSelect={false}
                  startDate={
                    selectedStartDate ? new Date(selectedStartDate) : null
                  }
                  endDate={selectedEndDate ? new Date(selectedEndDate) : null}
                  onChange={handleChangeEndDate}
                  highlightDates={highlightWithRanges}
                  formatWeekDay={getDayTitle}
                  customInput={customInput}
                  renderCustomHeader={HeaderOneCalendarRange({
                    startMonth: startMonth,
                    endMonth: endMonth,
                    startYear: startYear || 2004,
                    endYear: endYear || +getCurrentDateTime(FORMAT.YEAR),
                    zIndex: 100,
                    dateCustorm: dateHeaderCalenderEnd,
                    changeDateCustorm: (date) => {
                      const DatePre = new Date(
                        date.getFullYear(),
                        date.getMonth() - 1,
                        1,
                      )
                      setdateHeaderCalenderEnd(date)
                      setdateHeaderCalender(DatePre)
                    },
                  })}
                  minDate={new Date(startYear || 2004, 0)}
                  maxDate={
                    new Date(
                      endYear || new Date().getFullYear(),
                      endMonth ? endMonth : 12,
                      0,
                    )
                  }
                  inline
                  selectsRange
                  excludeDateIntervals={excludeDateIntervals}
                />
              </div>
            </div>
          </div>
          <div
            className={`d-flex justify-content-end ali-center ${style.buttonContainer}`}
          >
            <div>
              {formatDateTime(selectedStartDate, FORMAT.DATE, locale)} -{' '}
              {formatDateTime(
                selectedEndDate ?? new Date(),
                FORMAT.DATE,
                locale,
              )}
            </div>
            <div className="w-80 d-flex ml-8">
              <Button
                className={`btn normal h-20 mr-8 ${style.buttonReset}`}
                onClick={handleHide}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                className={`btn btn-blue h-20 ${style.buttonReset}`}
                onClick={handleChangeDates}
              >
                <Translate value="common.button.OK" />
              </Button>
            </div>
          </div>
        </div>

        <div className={style.buttonCustom}>
          {datePreset.map(({ value, title }) => (
            <div
              key={title}
              className={active === value ? style.backgroundSelected : ''}
              onClick={() => handleChangeTimeRange(value)}
            >
              <Translate value={title} />
            </div>
          ))}
        </div>
      </div>
    </Dropdown>
  )
}

export default TwoCalendarRangeCustom
