import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundNavDataService from '../../../../../../core/services/fund/FundNavDataService'
import { handleExport } from '../../../../../utils/Export'

export const getNavDataThunk = createAsyncThunk(
  'fund/fundProfile/navData/GET_NAV_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FundNavDataService.getNAVData(data)
      if (response.success) {
        return {
          data: response.data,
          totalPage:
            response.data.length && response.data[0].totalRow
              ? Math.ceil(response.data[0].totalRow / data.PageSize)
              : 1,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadNAVDataExport = createAsyncThunk(
  'fund/fundProfile/navData/DOWNLOAD_NAV_DATA_EXPORT',
  async (data) => {
    const response = await FundNavDataService.downloadNAVDataExport(data)
    handleExport(response.data, response.filename)
  },
)
