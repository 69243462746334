import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import FundNavDataService from '../../../../../core/services/fund/FundNavDataService'
import BasicChart from '../../../../common/chart/basicChart'
import { DEFAULT_PRE_VALUE_KEY } from '../../../../common/chart/basicChart/constants'
// import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import moment from 'moment'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import {
  useCheckTimeRangeValid,
  useFormatDateTimeByTimeRange,
} from '../../../../common/hooks/useDateTime'
import UseI18n from '../../../../common/hooks/useI18n'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { Filter } from './Filter'
import { formatDataApi, getSchema, renderTooltipContent } from './helpers'
import { selectFilters } from './store/slice'

export const FundFlow = ({ id, width, height }) => {
  const { getTimeRangeCheckValid } = useCheckTimeRangeValid()
  const { formatDateTimeByTimeRange } = useFormatDateTimeByTimeRange()

  const yAxisText = UseI18n(
    'fund.fundProfile.navData.fundFlow.FUND_FLOW_Y_AXIS',
  )
  const yAxisAccText = UseI18n(
    'fund.fundProfile.navData.fundFlow.FUND_FLOW_ACC_Y_AXIS',
  )

  const locale = useSelector((state) => state.i18n.locale)
  const fundInfo = useSelector(selectFundInfo)
  const filters = useSelector(selectFilters)

  const getParams = useMemo(() => {
    return {
      OrganizationId: fundInfo.organizationId,
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From: filters.timeRange === calendarTabKey ? filters.startDate : '',
      To: filters.timeRange === calendarTabKey ? filters.endDate : '',
    }
  }, [filters, fundInfo.organizationId])

  const getTimeRangeValid = useMemo(
    () =>
      getTimeRangeCheckValid({
        timeRange: filters.timeRange,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }),
    [filters.timeRange, filters.startDate, filters.endDate],
  )

  const renderTooltipTitle = (data) => {
    const startOfWeek = moment(data.endDateId).startOf('week').toString()
    const endOfWeek = moment(data.endDateId)
      .endOf('week')
      .subtract(2, 'day')
      .toString()
    return (
      formatDateTime(startOfWeek, FORMAT.DATE, locale) +
      ' - ' +
      formatDateTime(endOfWeek, FORMAT.DATE, locale)
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <BasicChart
                id={id}
                width={width}
                height={height - size.height}
                params={getParams}
                serviceApi={(params) =>
                  FundNavDataService.getListFundFlow(params)
                }
                formatDataApi={(data) =>
                  formatDataApi({
                    data,
                    filters,
                    getTimeRangeCheckValid,
                    formatDateTimeByTimeRange,
                  })
                }
                schema={getSchema()}
                chartProps={{
                  chartType: 'ComposedChart',
                  yAxis: [
                    {
                      yAxisId: 'fundFlow',
                      dataKeys: [`${DEFAULT_PRE_VALUE_KEY}1`],
                      labelText: yAxisText,
                    },
                    {
                      yAxisId: 'accFundFlow',
                      dataKeys: [`${DEFAULT_PRE_VALUE_KEY}2`],
                      orientation: 'right',
                      labelText: yAxisAccText,
                      labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      isLineChart: true,
                    },
                  ],
                }}
                tooltipProps={{
                  title: getTimeRangeValid.isLessThanSixMonth
                    ? renderTooltipTitle
                    : undefined,
                  render: renderTooltipContent,
                }}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
