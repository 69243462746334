import { useSelector } from 'react-redux'
import { selectDisplayColumn } from './store/slice'

const withWrapperCell = (ThCell) => (props) => {
  const isDisplay = useSelector(selectDisplayColumn(props.index))

  if (!isDisplay) return <></>

  return <ThCell {...props} />
}

export default withWrapperCell
