import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { YEAR_OPTIONS } from '../constant'
import {
  getCreditRatingCoefficient,
  getFundsFromOperationsToDebt,
  getLiabilitiesToEquityRatio,
  getgetDebtToEBITDA,
} from './thunk'

const initialState = {
  dataChart1: [],
  filterChart1: YEAR_OPTIONS[0].value,
  loadingChart1: true,
  dataChart2: [],
  loadingChart2: true,
  dataChart3: [],
  loadingChart3: true,
  dataChart4: [],
  loadingChart4: true,
}

const slice = createSlice({
  name: 'bond/comparable/chart',
  initialState,
  reducers: {
    updateFilterChart1: (state, action) => {
      state.filterChart1 = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCreditRatingCoefficient.pending, (state) => {
      state.loadingChart1 = true
    })
    builder.addCase(getCreditRatingCoefficient.fulfilled, (state, action) => {
      state.loadingChart1 = false
      state.dataChart1 = action.payload
    })
    builder.addCase(getCreditRatingCoefficient.rejected, (state, action) => {
      state.dataChart1 = []
      state.loadingChart1 = action.payload
    })

    builder.addCase(getLiabilitiesToEquityRatio.pending, (state) => {
      state.loadingChart2 = true
    })
    builder.addCase(getLiabilitiesToEquityRatio.fulfilled, (state, action) => {
      state.loadingChart2 = false
      state.dataChart2 = action.payload
    })
    builder.addCase(getLiabilitiesToEquityRatio.rejected, (state, action) => {
      state.loadingChart2 = action.payload
    })

    builder.addCase(getFundsFromOperationsToDebt.pending, (state) => {
      state.loadingChart3 = true
    })
    builder.addCase(getFundsFromOperationsToDebt.fulfilled, (state, action) => {
      state.loadingChart3 = false
      state.dataChart3 = action.payload
    })
    builder.addCase(getFundsFromOperationsToDebt.rejected, (state, action) => {
      state.loadingChart3 = action.payload
    })

    builder.addCase(getgetDebtToEBITDA.pending, (state) => {
      state.loadingChart4 = true
    })
    builder.addCase(getgetDebtToEBITDA.fulfilled, (state, action) => {
      state.loadingChart4 = false
      state.dataChart4 = action.payload
    })
    builder.addCase(getgetDebtToEBITDA.rejected, (state, action) => {
      state.loadingChart4 = action.payload
    })
  },
})

export const selectLoadingChart1 = (state) => state[slice.name].loadingChart1
export const selectDataChart1 = (state) => state[slice.name].dataChart1
export const selectFilterChart1 = (state) => state[slice.name].filterChart1

export const selectLoadingChart2 = (state) => state[slice.name].loadingChart2
export const selectDataChart2 = (state) => state[slice.name].dataChart2

export const selectLoadingChart3 = (state) => state[slice.name].loadingChart3
export const selectDataChart3 = (state) => state[slice.name].dataChart3

export const selectLoadingChart4 = (state) => state[slice.name].loadingChart4
export const selectDataChart4 = (state) => state[slice.name].dataChart4

export const { updateFilterChart1 } = slice.actions

register(slice.name, slice.reducer)
