import { I18n } from 'react-redux-i18n'
import { lengthChartInLayout } from '../../chart/common/useGetLayoutDashboard'
import { getNewDashboardName } from '../helpers'

export const formatMenuData = (items, id = undefined, link = 'parentId') =>
  items
    .filter((item) => item[link] === id)
    .map((item) => ({
      ...item,
      children: formatMenuData(items, item.id).sort(compare),
    }))
    .sort(compare)

const compare = (a, b) => {
  if (a.order < b.order) {
    return -1
  }
  if (a.order > b.order) {
    return 1
  }
  return 0
}

export const formatDashboardNoOrder = (items) =>
  items.filter(
    (item) =>
      !item.isGroup &&
      !item.isFixed &&
      !item.isTemplate &&
      !item.parentId &&
      !item.order,
  )

export const getNewDashboardData = (
  dashboardData,
  isGroup = false,
  isGroupChildren = false,
) => {
  if (isGroup) {
    return {
      name: getNewDashboardName(
        dashboardData,
        I18n.t('financialChart.NEW_GROUP_DASHBOARD_NAME'),
        true,
      ),
      isGroup: true,
      layout: 'layout_1',
      maxChartCount: lengthChartInLayout.layout_1,
      parentId: dashboardData.id,
      order: getNewDashboardOrder(dashboardData),
    }
  } else if (isGroupChildren) {
    return {
      name: getNewDashboardName(
        dashboardData,
        I18n.t('financialChart.NEW_DASHBOARD_NAME'),
      ),
      isGroup: false,
      layout: 'layout_1',
      maxChartCount: lengthChartInLayout.layout_1,
      parentId: dashboardData.id,
      order: getNewDashboardOrder(dashboardData),
    }
  } else {
    return {
      name: getNewDashboardName(
        dashboardData,
        I18n.t('financialChart.NEW_DASHBOARD_NAME'),
      ),
      isGroup: false,
      layout: 'layout_1',
      maxChartCount: lengthChartInLayout.layout_1,
    }
  }
}

const getNewDashboardOrder = (dashboardData) => {
  const dashboardOrders = dashboardData.children
    .filter((item) => item.isGroup)
    .map((item) => item.order)
    .filter((val) => !!val)
    .sort((a, b) => a - b)

  if (!dashboardOrders.length) {
    return 1
  } else {
    return dashboardOrders[dashboardOrders.length - 1] + 1
  }
}
