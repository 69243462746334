import PropTypes from 'prop-types'
import { useRef } from 'react'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { calculateTotalWidth } from './helper'

export const ThResize = ({
  resizable,
  col,
  widths,
  setWidths,
  setIsResizing,
  schema,
  resizeRef,
  initWidths,
  resizeState,
  setResizeState,
  setIsWidth100,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const ref = useRef({})
  const isAlMostDisableResize = () => {
    const disableResizeSchema = schema.filter((col) => col.disableResize)
    return schema.length === disableResizeSchema.length + 1
  }

  const containerWidth = calculateTotalWidth(Object.values(initWidths))

  const canResize = (e) => {
    const isLessThanMinWidth =
      widths[col.colId] +
        (formatWithZoom(e.clientX, true) - ref.current.clientX) <
      0

    return !isLessThanMinWidth
  }

  const onResizeColumn = (e) => {
    if (!canResize(e)) {
      return
    }

    const newColWidth =
      widths[col.colId] +
      (formatWithZoom(e.clientX, true) - ref.current.clientX)
    const obj = {}

    const newWidths = {
      ...widths,
      [col.colId]: newColWidth,
      ...obj,
    }

    const totalNewWidth = calculateTotalWidth(Object.values(newWidths))

    if (totalNewWidth < containerWidth) {
      setIsWidth100(false)
    } else {
      setIsWidth100(true)
    }

    setWidths(newWidths)

    resizeState = {
      ...resizeState,
      [col.colId]: true,
    }
    setResizeState(resizeState)
  }

  const onStopResizeColumn = () => {
    setIsResizing(false)
    window.removeEventListener('mousemove', onResizeColumn)
    window.removeEventListener('mouseup', onStopResizeColumn)
  }

  const onResizeStart = (e) => {
    ref.current.clientX = formatWithZoom(e.clientX, true)
    setIsResizing(true)
    window.addEventListener('mousemove', onResizeColumn)
    window.addEventListener('mouseup', onStopResizeColumn)
  }

  return (
    resizable &&
    !col.disableResize &&
    !isAlMostDisableResize() && (
      <div
        ref={resizeRef}
        onMouseDown={onResizeStart}
        style={{
          height: '100%',
          display: 'inline-block',
          width: 8,
          cursor: 'col-resize',
          position: 'absolute',
          top: 0,
          right: 0,
        }}
      />
    )
  )
}

ThResize.propTypes = {
  resizable: PropTypes.bool.isRequired,
  col: PropTypes.object.isRequired,
  widths: PropTypes.object.isRequired,
  setWidths: PropTypes.func.isRequired,
  setIsResizing: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  resizeRef: PropTypes.object.isRequired,
  initWidths: PropTypes.object.isRequired,
  resizeState: PropTypes.object.isRequired,
  setResizeState: PropTypes.func.isRequired,
}
