import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Input } from '../../../../common/html/Input'
import Popup from '../../../../common/popup'
import { getActiveTemplate, getTemplateRaw } from '../../template/store/slice'
import style from './index.module.css'
import { postTemplates, putTemplates } from './store/thunk'

const MAP_STATE_RADIO = {
  SAVE: 1,
  UPDATE: 2,
}

const SavePopup = ({ isShow, handleClose, templateParameters, date }) => {
  const dispatch = useDispatch()

  const [radioState, setRadioState] = useState(MAP_STATE_RADIO.SAVE)
  const [nameTemplate, setNameTemplate] = useState('')
  const [error, setError] = useState('')
  const templateRaw = useSelector(getTemplateRaw)
  const activeTemplate = useSelector(getActiveTemplate)

  const handleInputName = (e) => {
    setNameTemplate(e.target.value)
    if (!e.target.value) {
      setError('corporate.companySnapshot.MESS_NAME_ENTER')
    } else if (
      templateRaw.some((item) => item.templateName === e.target.value.trim())
    ) {
      setError('corporate.companySnapshot.MESS_NAME_EXISTED')
    } else setError('')
  }

  const onCancel = () => {
    handleClose()
    setNameTemplate('')
  }

  const onSubmit = () => {
    if (radioState === MAP_STATE_RADIO.UPDATE) {
      dispatch(
        putTemplates({
          templateId: activeTemplate,
          templateName: nameTemplate,
          templateParameters,
          date,
        }),
      )
    } else {
      dispatch(
        postTemplates({
          templateName: nameTemplate,
          templateParameters,
          date,
        }),
      )
    }
    onCancel()
  }

  return (
    <>
      {isShow && (
        <Popup>
          <div className="modal modal-small">
            <div className="modal-title">
              <h3>
                <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
              </h3>
              <a onClick={handleClose} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              {activeTemplate ? (
                <ul className={`list-check mt-0`}>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2 ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.UPDATE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.UPDATE)}
                        />
                        <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
                      </label>
                    </div>
                  </li>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2  ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.SAVE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.SAVE)}
                        />
                        <Translate value="newsReport.dailyReport.dailyReport.SAVE_AS_TEMPLATE" />
                      </label>
                      {radioState === MAP_STATE_RADIO.SAVE && (
                        <>
                          <Input
                            type="text"
                            value={nameTemplate}
                            className={`${style.borderButton} bg-grey-2 h-20 mt-8 w-100 ml-10`}
                            onChange={(e) => handleInputName(e)}
                          />
                          {error && (
                            <div className={style.error}>
                              <Translate value={error} />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              ) : (
                <ul className={`list-check mt-0`}>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2  ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.SAVE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.SAVE)}
                        />
                        <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
                      </label>

                      <Input
                        type="text"
                        value={nameTemplate}
                        className={`${style.borderButton} bg-grey-2 h-20 mt-8 w-100 ml-10`}
                        onChange={(e) => handleInputName(e)}
                      />
                      {error && (
                        <div className={style.error}>
                          <Translate value={error} />
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              )}
              <div
                className="group-btn justify-content-end"
                style={{ marginTop: 32 }}
              >
                <Button
                  onClick={onCancel}
                  className={`btn normal w-80 h-20 `}
                  style={{ fontSize: 11 }}
                >
                  <Translate value="corporate.companySnapshot.CANCEL" />
                </Button>
                <Button
                  onClick={onSubmit}
                  className={`btn btn-blue w-80 h-20 ${
                    error ? style.disabled : ''
                  }`}
                  style={{ fontSize: 11 }}
                  disabled={error}
                >
                  <Translate value="newsReport.dailyReport.dailyReport.SAVE" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

SavePopup.propTypes = {
  templateParameters: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}

export default SavePopup
