import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import Popup from '../../../../../common/popup'
import { MAX_TICKER, PLOT_TYPE } from '../../constant'
import {
  changeFilteredOrganizationIds,
  changeFilterExchanges,
  changeFilterMarketCap,
  changeSelectedOrganizationGroups,
  changeSelectedOrganizations,
  selectFilteredOrganizationIds,
  selectFilterExchanges,
  selectFilterMarketCap,
  selectPlotType,
  selectSelectedOrganizationGroups,
  selectSelectedOrganizations,
} from '../../store/slice'
import useDidUpdateEffect from './../../../../../common/hooks/useDidUpdateEffect'
import ChooseTicker from './ChooseTicker'
import style from './company.module.css'
import { getValueInTrillion } from './helper'
import SelectedTicker from './SelectedTicker'

const CompanyPopup = ({ setIsShow }) => {
  const dispatch = useDispatch()

  const selectedOrganizationGroups = useSelector(
    selectSelectedOrganizationGroups,
  )
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrganizationIds = useSelector(selectFilteredOrganizationIds)
  const filterExchanges = useSelector(selectFilterExchanges)
  const filterMarketCap = useSelector(selectFilterMarketCap)
  const plotType = useSelector(selectPlotType)

  const [groups, setGroups] = useState(selectedOrganizationGroups)
  const [organizations, setOrganizations] = useState(selectedOrganizations)
  const [filteredOrgIds, setFilteredOrgIds] = useState(filteredOrganizationIds)
  const [exchanges, setExchanges] = useState(filterExchanges)
  const [marketCap, setMarketCap] = useState(filterMarketCap)
  const [checkedOrgIds, setCheckedOrgIds] = useState({})

  const isLimitTicker = plotType === PLOT_TYPE.TIME_SERIES

  // Default open popup
  useEffect(() => {
    setGroups(selectedOrganizationGroups)
  }, [selectedOrganizationGroups])

  useEffect(() => {
    setOrganizations(selectedOrganizations)
  }, [selectedOrganizations])

  useEffect(() => {
    setFilteredOrgIds(filteredOrganizationIds)
  }, [filteredOrganizationIds])

  useEffect(() => {
    setExchanges(filterExchanges)
  }, [filterExchanges])

  useEffect(() => {
    setMarketCap(filterMarketCap)
  }, [filterMarketCap])

  // Handle filter change
  const handleFilterChange = () => {
    setFilteredOrgIds(
      Object.values(organizations)
        .filter(
          (org) =>
            exchanges.includes(org.exchange) &&
            (getValueInTrillion(marketCap.from) || 0) <= (org.marketCap || 0) &&
            (getValueInTrillion(marketCap.to) || Infinity) >=
              (org.marketCap || 0),
        )
        .map((org) => org.organizationId),
    )
  }

  useDidUpdateEffect(() => {
    handleFilterChange()
  }, [exchanges, marketCap])

  const handleCancel = () => {
    setIsShow(false)
  }

  const handleApply = () => {
    setIsShow(false)
    dispatch(changeFilteredOrganizationIds(filteredOrgIds))
    dispatch(changeSelectedOrganizations(organizations))
    dispatch(changeSelectedOrganizationGroups(groups))
    dispatch(changeFilterExchanges(exchanges))
    dispatch(changeFilterMarketCap(marketCap))
  }

  return (
    <Popup>
      <div className="modal modal-medium" style={{ minWidth: 900 }}>
        <div className="modal-title">
          <h3 className="text-up">
            <Translate value="sector.sectorConstituents.segmentation.CHOOSE_COMPANY" />
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className={style.modalContent}>
            <ChooseTicker
              organizations={organizations}
              setOrganizations={setOrganizations}
              filteredOrgIds={filteredOrgIds}
              setFilteredOrgIds={setFilteredOrgIds}
              groups={groups}
              setGroups={setGroups}
              exchanges={exchanges}
              marketCap={marketCap}
              checkedOrgIds={checkedOrgIds}
              setCheckedOrgIds={setCheckedOrgIds}
            />
            <SelectedTicker
              organizations={organizations}
              setOrganizations={setOrganizations}
              filteredOrgIds={filteredOrgIds}
              setFilteredOrgIds={setFilteredOrgIds}
              groups={groups}
              setGroups={setGroups}
              exchanges={exchanges}
              setExchanges={setExchanges}
              marketCap={marketCap}
              setMarketCap={setMarketCap}
              checkedOrgIds={checkedOrgIds}
              setCheckedOrgIds={setCheckedOrgIds}
            />
          </div>

          {isLimitTicker && (
            <>
              <div className={style.selectedCount}>
                <Translate
                  dangerousHTML
                  value="sector.sectorConstituents.segmentation.SELECTED_COUNT"
                />
                {` ${filteredOrgIds.length}/${MAX_TICKER}`}
              </div>
              {filteredOrgIds.length >= MAX_TICKER && (
                <div className={style.maxItemText}>
                  <Translate
                    dangerousHTML
                    value="sector.sectorConstituents.segmentation.MAX_ITEMS_TEXT"
                  />
                </div>
              )}
            </>
          )}

          <div className={style.groupButtonModal}>
            <Button className="btn bg-grey-1 w-80 h-20" onClick={handleCancel}>
              <Translate value={'common.button.BUTTON_CANCEL'} />
            </Button>
            <Button
              onClick={handleApply}
              disabled={isLimitTicker && filteredOrgIds.length > MAX_TICKER}
              className={`btn btn-blue w-80 h-20 ${
                isLimitTicker && filteredOrgIds.length > MAX_TICKER
                  ? style.disableApply
                  : ''
              }`}
            >
              <Translate value={'common.button.BUTTON_APPLY'} />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default CompanyPopup
