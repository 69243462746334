import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../common/chart/constants'
import { FooterContainer } from '../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../common/chart/footer/Item'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const LineChart = ({
  width,
  height,
  data,
  xAxisKey,
  leftKeys,
  rightKeys,
  margin,
  fillColor,
  mappingDisplayName,
  leftLabel,
  rightLabel,
  reversed,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const footerData = () => {
    let keys = []
    if (leftKeys && leftKeys.length) {
      keys = [...leftKeys]
    }
    if (rightKeys && rightKeys.length) {
      keys = [...keys, ...rightKeys]
    }
    return keys.map((i) => ({
      text: mappingDisplayName[i] ?? i,
      type: FOOTER_TYPE.LINE,
      color: fillColor[i],
    }))
  }

  const getData = () => {
    return data.map((item) => ({
      ...item,
      tradingDateId: formatDateTime(item.tradingDateId, FORMAT.DATE),
    }))
  }

  const renderLineChart = (keys, chartContentWidth, yAxisId) => {
    return keys.map((key) => (
      <Line
        key={key}
        yAxisId={yAxisId}
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={1}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={getData()}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              xTickNum={SETTINGS.xTickNum}
              yAxis={[
                {
                  id: 'leftChart',
                  keys: leftKeys,
                  orientation: 'left',
                  isLineChart: true,
                  yAxisWidth: margin?.left,
                  label: leftLabel ? I18n.t(leftLabel) : leftLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  themeDownloadPDF: true,
                  tickNum: SETTINGS.yTickNum,
                },
                {
                  id: 'rightChart',
                  keys: rightKeys,
                  orientation: 'right',
                  isLineChart: true,
                  yAxisWidth: margin?.right,
                  label: rightLabel ? I18n.t(rightLabel) : rightLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  themeDownloadPDF: true,
                  tickNum: SETTINGS.yTickNum,
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              tickFormatter={(val) => formatDateTime(val, FORMAT.DATE, locale)}
              hideToolTipChart
              styleTickXAxis={{ fill: '#000000', fontSize: 8, fontWeight: 500 }}
              styleTickYAxis={{
                fill: '#000000',
                fontSize: 10,
                fontWeight: 500,
                labelFontSize: 8,
              }}
              interval={0}
              reversed={reversed}
            >
              {({ chartContentWidth }) => (
                <>
                  {renderLineChart(leftKeys, chartContentWidth, 'leftChart')}
                  {renderLineChart(rightKeys, chartContentWidth, 'rightChart')}
                </>
              )}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            numItemPerRow={4}
            itemStyle={{ fixedFontSize: 10 }}
            isDownTheLine
          />
        </>
      )}
    </SizeTracker>
  )
}

LineChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  leftKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  rightKeys: PropTypes.arrayOf(PropTypes.string),
  fillColor: PropTypes.object,
  leftUnit: PropTypes.string,
  rightUnit: PropTypes.string,
  mappingDisplayName: PropTypes.objectOf(PropTypes.string),
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  margin: PropTypes.object,
  isDisplayDot: PropTypes.bool,
  reversed: PropTypes.bool,
}

LineChart.defaultProps = {
  fillColor: {},
  leftUnit: '',
  rightUnit: '',
  mappingDisplayName: {},
  leftKeys: [],
  rightKeys: [],
  isDisplayDot: true,
  reversed: false,
}
