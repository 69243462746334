import { createSlice } from '@reduxjs/toolkit'
import { getLatestPrice } from '../../../../../common/topInfo/warrantTopInfo/store/thunk'
import { register } from '../../../../../utils/ReducerRegistry'
import { getPriceDepthTable } from './thunk'

const initialState = {
  data: {},
  ids: [],
  loading: true,
  priceData: {},
}

export const slice = createSlice({
  name: 'market/coveredWarrant/priceDepth/leftTable',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPriceDepthTable.pending, (state) => {
      state.loading = true
      state.data = {}
      state.ids = []
    })
    builder.addCase(getPriceDepthTable.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
    })
    builder.addCase(getPriceDepthTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getLatestPrice.pending, (state) => {
      state.loading = true
      state.priceData = {}
    })
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      state.loading = false
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      }
    })
    builder.addCase(getLatestPrice.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading
export const selectPriceData = (state) => state[slice.name].priceData

register(slice.name, slice.reducer)
