import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemSelect from './common/ItemSelect'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import { deepCopy } from './helper'
import style from './index.module.css'

const ShareIssue = ({
  handleSubmit,
  handleCancel,
  title,
  minYear,
  maxYear,
  maxIndicator,
  lengthIndicator,
  shareIssueMethod,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    delete detail.stage
    handleSubmit({ ...data, detail })
  }

  const selectYear = range(minYear, maxYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`${style.siContent} d-flex j-b`}>
            <div className="d-flex ali-center">
              <Translate value="common.popup.YEAR_OF_REVIEW" />
              <div>
                <ItemSelect
                  selectData={selectYear}
                  keySelect="issueYear"
                  defaultValue={selectYear[0].value}
                />
              </div>
            </div>
            <div className="d-flex ali-center">
              <Translate value="common.popup.ISSUE_METHOD" />
              <div>
                <ItemSelect
                  selectData={shareIssueMethod}
                  keySelect="shareIssueMethodId"
                  defaultValue={9}
                />
              </div>
            </div>
            <div className="d-flex ali-center">
              <Translate value="common.popup.STAGE" />
              <div>
                <ItemSelect
                  selectData={[
                    { value: 1, name: I18n.t('common.popup.MOST_RECENT') },
                  ]}
                  keySelect="stage"
                  defaultValue={1}
                />
              </div>
            </div>
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              submit={convertData}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

ShareIssue.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  mostRecentDate: PropTypes.string,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
  shareIssueMethod: PropTypes.array.isRequired,
}

export default withWrapperPopupIndicator(ShareIssue)
