import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, ReferenceLine, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { NoData } from '../../../../common/noData'
import { BOND_TYPES } from '../../constants'
import { selectDataPortfolioIssuer } from '../../store/slice'
import ChartTooltip from './ChartTooltip'
import ReferenceDuration from './ReferenceDuration'

const CorporateChart = ({ data, width, height }) => {
  const { list } = useSelector(selectDataPortfolioIssuer)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataChart = useMemo(() => {
    if (!data?.length) return []

    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    if (!newDataIsHaveBuyValue?.length) return []

    const dataByGovernment = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.CORPORATE_BOND.includes(item.bondTypeId),
    )

    if (!dataByGovernment?.length) return []

    const dataUnderOneYear = dataByGovernment.filter(
      (item) => item?.remainDuration < 6,
    )

    const dataOneToThreeYears = dataByGovernment.filter(
      (item) => item?.remainDuration >= 6 && item?.remainDuration < 12,
    )

    const dataThreeToFiveYears = dataByGovernment.filter(
      (item) => item?.remainDuration >= 12 && item?.remainDuration < 18,
    )

    const dataFiveToSevenYears = dataByGovernment.filter(
      (item) => item?.remainDuration >= 18 && item?.remainDuration < 24,
    )

    const dataSevenToNineYears = dataByGovernment.filter(
      (item) => item?.remainDuration >= 24 && item?.remainDuration < 36,
    )

    const dataOverNineYears = dataByGovernment.filter(
      (item) => item?.remainDuration >= 36,
    )

    const buyValueUnderOneYear = dataUnderOneYear.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponUnderOneYear = dataUnderOneYear.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const buyValueOneToThreeYears = dataOneToThreeYears.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponOneToThreeYears = dataOneToThreeYears.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const buyValueThreeToFiveYears = dataThreeToFiveYears.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponThreeToFiveYears = dataThreeToFiveYears.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const buyValueFiveToSevenYears = dataFiveToSevenYears.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponFiveToSevenYears = dataFiveToSevenYears.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const buyValueSevenToNineYears = dataSevenToNineYears.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponSevenToNineYears = dataSevenToNineYears.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const buyValueOverNineYears = dataOverNineYears.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const couponOverNineYears = dataOverNineYears.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    return [
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', { month: '<6' }),
        buyValue: !!dataUnderOneYear?.length
          ? buyValueUnderOneYear / 1000000
          : 0,
        coupon:
          !!dataUnderOneYear?.length && buyValueUnderOneYear !== 0
            ? (couponUnderOneYear / buyValueUnderOneYear) * 100
            : 0,
      },
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', {
          month: '6-12',
        }),
        buyValue: !!dataOneToThreeYears?.length
          ? buyValueOneToThreeYears / 1000000
          : 0,
        coupon:
          !!dataOneToThreeYears?.length && buyValueOneToThreeYears !== 0
            ? (couponOneToThreeYears / buyValueOneToThreeYears) * 100
            : 0,
      },
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', {
          month: '12-18',
        }),
        buyValue: !!dataThreeToFiveYears?.length
          ? buyValueThreeToFiveYears / 1000000
          : 0,
        coupon:
          !!dataThreeToFiveYears?.length && buyValueThreeToFiveYears !== 0
            ? (couponThreeToFiveYears / buyValueThreeToFiveYears) * 100
            : 0,
      },
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', {
          month: '18-24',
        }),
        buyValue: !!dataFiveToSevenYears?.length
          ? buyValueFiveToSevenYears / 1000000
          : 0,
        coupon:
          !!dataFiveToSevenYears?.length && buyValueFiveToSevenYears !== 0
            ? (couponFiveToSevenYears / buyValueFiveToSevenYears) * 100
            : 0,
      },
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', {
          month: '24-36',
        }),
        buyValue: !!dataSevenToNineYears?.length
          ? buyValueSevenToNineYears / 1000000
          : 0,
        coupon:
          !!dataSevenToNineYears?.length && buyValueSevenToNineYears !== 0
            ? (couponSevenToNineYears / buyValueSevenToNineYears) * 100
            : 0,
      },
      {
        name: I18n.t('bond.portfolio.portfolioByIssuer.MONTH', {
          month: '>36',
        }),
        buyValue: !!dataOverNineYears?.length
          ? buyValueOverNineYears / 1000000
          : 0,
        coupon:
          !!dataOverNineYears?.length && buyValueOverNineYears !== 0
            ? (couponOverNineYears / buyValueOverNineYears) * 100
            : 0,
      },
    ]
  }, [data, locale])

  const duration = useMemo(() => {
    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    if (!newDataIsHaveBuyValue?.length) return 0

    const dataByGovernment = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.CORPORATE_BOND.includes(item.bondTypeId),
    )

    const totalDuration = dataByGovernment.reduce(
      (total, item) =>
        (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
      0,
    )

    const totalBuyValue = dataByGovernment.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    return totalBuyValue !== 0 ? totalDuration / totalBuyValue : 0
  }, [data])

  const renderTooltip = (dataChart) => (
    <ChartTooltip data={{ ...dataChart, duration }} />
  )

  const bondSizeLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_LABEL'),
    [locale],
  )

  const couponLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.COUPON_LABEL'),
    [locale],
  )

  const durationLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.DURATION_LABEL'),
    [locale],
  )

  return (
    <>
      {!!formatDataChart.length && !!list?.length ? (
        <>
          <div style={{ height: height - 26 }}>
            <ChartContainer
              data={formatDataChart}
              width={width}
              height={height - 24}
              keyXAxis="name"
              isUseXAxisDiv
              showAllTick
              yAxis={[
                {
                  id: 'left',
                  keys: [list[0]],
                  orientation: 'left',
                  label: bondSizeLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  isBarChart: true,
                  tickNum: 5,
                  min: 0,
                },
                {
                  id: 'right',
                  keys: [list[1]],
                  orientation: 'right',
                  label: couponLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  tickNum: 5,
                  min: 0,
                },
                {
                  id: 'right2',
                  keys: [list[2]],
                  isLineChart: true,
                  label: durationLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  tickNum: 5,
                  min: 0,
                  max: duration * 2,
                  hide: true,
                },
              ]}
              margin={{ left: 0, right: 16, top: 16, bottom: 16 }}
              renderCustomTooltip={renderTooltip}
            >
              {({ chartContentWidth }) => (
                <>
                  <Bar
                    isAnimationActive={false}
                    yAxisId="left"
                    dataKey={list[0]}
                    fill="#40ABFF"
                    barSize={27}
                  />
                  <Scatter
                    yAxisId="right"
                    dataKey={list[1]}
                    isAnimationActive={false}
                    shape={
                      <ScatterDiamondShape dataKey={list[1]} color="#FFFFFF" />
                    }
                  />
                  {duration > 0 && (
                    <ReferenceLine
                      yAxisId="right2"
                      y={duration}
                      stroke="#FACC5C"
                      strokeWidth={2}
                      strokeDasharray="5 5"
                      label={(ctx) => (
                        <ReferenceDuration ctx={ctx} value={duration} />
                      )}
                    />
                  )}
                </>
              )}
            </ChartContainer>
          </div>
          <Footer
            key={width}
            list={[
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_2',
                type: TYPE.SQUARE,
                before: {
                  bgColor: '#40ABFF',
                },
              },
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.AVG_COUPON_2',
                renderIcon: (
                  <ScatterDiamondShape
                    style={{ marginRight: 5 }}
                    color="#FFFFFF"
                  />
                ),
              },
              {
                text: 'bond.portfolio.portfolioByIssuer.AVG_DURATION_3',
                type: TYPE.DASHED_LINE,
                dashedLine: {
                  bgColor: '#FACC5C',
                },
              },
            ]}
          />
        </>
      ) : (
        <NoData />
      )}
    </>
  )
}

export default CorporateChart
