export const WIDTH_BAR_CHART = 50

export const TIME_SELECT = {
  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',
}

export const NUMBER_COLUMN_CHART = {
  '1W': 5,
  '1M': 22,
  '3M': 100,
  '6M': 26,
  '1Y': 12,
  '3Y': 36,
  '5Y': 20,
}

export const PRICE_DATA_TIME_SELECT = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}

export const VALUE_TYPE = {
  NET_VALUE: 'NET_VALUE',
  PERCENT_VALUE: 'PERCENT_VALUE',
}

export const TIME_SELECT_VALUE = {
  '1W': 'OneWeek',
  '1M': 'OneMonth',
  '3M': 'ThreeMonths',
  '6M': 'SixMonths',
  '1Y': 'OneYear',
  '3Y': 'ThreeYears',
  '5Y': 'FiveYears',
}

export const MAPPING_UNIT = {
  NET_VALUE: 'Bn.',
  PERCENT_VALUE: '%',
}

export const NET_VALUE_CHART_FORMAT_VALUE = {
  localIndividualBuyValue: 10 ** 9,
  localIndividualSellValue: 10 ** 9,
  localIndividualNetValue: 10 ** 9,
  foreignBuyValueTotal: 10 ** 9,
  foreignSellValueTotal: 10 ** 9,
  localForeignNetValue: 10 ** 9,
  localInstitutionalBuyValue: 10 ** 9,
  localInstitutionalSellValue: 10 ** 9,
  localInstitutionalNetValue: 10 ** 9,
  totalBuyValue: 10 ** 9,
  totalSellValue: 10 ** 9,
  proprietaryNetValueTotal: 10 ** 9,
}

export const NET_VALUE_CHART_KEY = {
  foreign: {
    buy: 'foreignBuyValueTotal',
    sell: 'foreignSellValueTotal',
    net: 'localForeignNetValue',
  },
  localInstitution: {
    buy: 'localInstitutionalBuyValue',
    sell: 'localInstitutionalSellValue',
    net: 'localInstitutionalNetValue',
  },
  localIndividual: {
    buy: 'localIndividualBuyValue',
    sell: 'localIndividualSellValue',
    net: 'localIndividualNetValue',
  },
  proprietary: {
    buy: 'totalBuyValue',
    sell: 'totalSellValue',
    net: 'proprietaryNetValueTotal',
  },
}

export const VALUE_INVESTOR_CHART_FORMAT_VALUE = {
  closeIndex: 10 ** 3,
  foreignNetValueTotal: 10 ** 9,
  localIndividualNetValue: 10 ** 9,
  localInstitutionalNetValue: 10 ** 9,
  proprietaryNetValue: 10 ** 9,
  foreignPercentage: 0.01,
  localIndividualPercentage: 0.01,
  localInstitutionalPercentage: 0.01,
  proprietaryPercentage: 0.01,
}

export const VALUE_INVESTOR_NET_CHART_X_KEY = {
  '1W': 'tradingDateId',
  '1M': 'tradingDateId',
  '3M': 'week',
  '6M': 'week',
  '1Y': 'month',
  '3Y': 'month',
  '5Y': 'quarter',
}

export const NET_VALUE_CHART_DATA_TYPE = {
  FOREIGN: 'foreign',
  LOCAL_INSTITUTION: 'localInstitution',
  LOCAL_INDIVIDUAL: 'localIndividual',
  PROPRIETARY: 'proprietary',
}

export const LIST_TYPE = {
  foreign: {
    display: 'market.equityTrading.priceStatistics.listType.FOREIGN',
    value: NET_VALUE_CHART_DATA_TYPE.FOREIGN,
  },
  localInstitution: {
    display: 'market.equityTrading.priceStatistics.listType.LOCAL_INSTITUTION',
    value: NET_VALUE_CHART_DATA_TYPE.LOCAL_INSTITUTION,
  },
  localIndividual: {
    display: 'market.equityTrading.priceStatistics.listType.LOCAL_INDIVIDUAL',
    value: NET_VALUE_CHART_DATA_TYPE.LOCAL_INDIVIDUAL,
  },
  proprietary: {
    display: 'market.equityTrading.priceStatistics.listType.PROPRIETARY',
    value: NET_VALUE_CHART_DATA_TYPE.PROPRIETARY,
  },
}

export const TRANSACTION_TYPES = {
  ALL: 'all',
  MATCH: 'match',
  DEAL: 'deal',
}

export const TRANSACTION_TYPE_OPTIONS = [
  {
    name: 'market.equityTrading.priceStatistics.transactionType.ALL',
    value: TRANSACTION_TYPES.ALL,
  },
  {
    name: 'market.equityTrading.priceStatistics.transactionType.MATCH',
    value: TRANSACTION_TYPES.MATCH,
  },
  {
    name: 'market.equityTrading.priceStatistics.transactionType.DEAL',
    value: TRANSACTION_TYPES.DEAL,
  },
]
