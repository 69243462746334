import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getValueFromRange } from '../../table/helpers'
import { dataTypeConstants, timeTypeConstants } from '../constants'
import { getConsumptionGrowthByTimeRange } from './thunk'

const initialState = {
  data: [],
  loading: true,
  keys: {},
  timeType: timeTypeConstants.SIX_MONTHS,
  timeTypeYear: timeTypeConstants.SEVEN_YEARS,
  dataType: dataTypeConstants.VALUE,
  listCheckbox: [],
  isKeepListCheckbox: null,
}

export const slice = createSlice({
  name: 'economy/productionAndConsumption/consumption/growthIndex',
  initialState,
  reducers: {
    changeTimeType: (state, action) => {
      state.timeType = action.payload
    },
    changeTimeTypeYear: (state, action) => {
      state.timeTypeYear = action.payload
    },
    changeDataType: (state, action) => {
      state.dataType = action.payload
    },
    keepListCheckbox: (state, action) => {
      state.isKeepListCheckbox = action.payload
    },
    changeListCheckbox: (state, action) => {
      state.listCheckbox = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConsumptionGrowthByTimeRange.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getConsumptionGrowthByTimeRange.fulfilled,
      (state, action) => {
        const data = getValueFromRange(action.payload || [])

        const maxFieldNumber = Math.max(
          ...data.map((item) => Object.keys(item).length),
        )
        const dataPointWithMaxFieldNumber = data.findIndex(
          (dataPoint) => Object.keys(dataPoint).length === maxFieldNumber,
        )
        const leftKeysGrowth = Object.keys(
          data[dataPointWithMaxFieldNumber],
        ).filter((item) => item.includes('growth'))
        const leftKeysValue = Object.keys(
          data[dataPointWithMaxFieldNumber],
        ).filter((item) => item.includes('value'))

        const mappingDisplayName = {}
        const mappingDisplayNameGrowth = {}
        leftKeysValue.forEach((item, key) => {
          mappingDisplayName[item] = data[dataPointWithMaxFieldNumber][key + 1]
        })
        leftKeysGrowth.forEach((item, key) => {
          mappingDisplayNameGrowth[item] =
            data[dataPointWithMaxFieldNumber][key + 1]
        })
        state.keys = {
          leftKeysGrowth: leftKeysGrowth,
          leftKeysValue: leftKeysValue,
          mappingDisplayName: mappingDisplayName,
          mappingDisplayNameGrowth: mappingDisplayNameGrowth,
        }
        state.data = data
        state.loading = false
      },
    )
    builder.addCase(getConsumptionGrowthByTimeRange.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading
export const selectTimeRange = (state) => state[slice.name].timeType
export const selectTimeRangeYear = (state) => state[slice.name].timeTypeYear
export const selectDataType = (state) => state[slice.name].dataType
export const selectKeysValue = (state) => state[slice.name].keys
export const selectListCheckbox = (state) => state[slice.name].listCheckbox
export const selectIsKeepListCheckbox = (state) =>
  state[slice.name].isKeepListCheckbox

export const {
  changeTimeType,
  changeDataType,
  changeTimeTypeYear,
  keepListCheckbox,
  changeListCheckbox,
} = slice.actions

register(slice.name, slice.reducer)
