import { Translate } from 'react-redux-i18n'
import Radio from '../../../../common/html/Radio'
import { Span } from '../../../../common/html/Span'
import styles from '../style.module.css'

export const RadioComponent = ({
  title,
  options,
  isParent,
  value,
  onChange,
  itemClass,
  radioClass = '',
}) => {
  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value={title} />:
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <Radio
          data={options}
          value={value}
          onChange={onChange}
          containerClass={['d-flex', radioClass].join(' ')}
          itemClass={[styles.settingBlockItemRadio, itemClass].join(' ')}
        />
      </div>
    </div>
  )
}
