import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../../common/sizeTracker'
import SelectYear from '../../common/SelectYear'
import ChartComponent from './ChartComponent'
import { TOP_10_GDP } from './PanelTop10Provinces'
import { getRangeYear, getYear, handleYear } from './store/slice'

const Top10Provinces = ({ height, width }) => {
  const dispatch = useDispatch()

  const year = useSelector(getYear)
  const rangeYear = useSelector(getRangeYear)

  const handleChangeYear = (year) => {
    dispatch(handleYear(year))
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <SelectYear
              year={year}
              changeYear={handleChangeYear}
              isFlexEnd={false}
              yearOptions={rangeYear}
            />
            {(sizes.height || sizes.height === 0) && (
              <div id={TOP_10_GDP} style={{ height: height - sizes.height }}>
                <ChartComponent height={height - sizes.height} width={width} />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Top10Provinces
