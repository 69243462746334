import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { screenTypes } from '../../constants'
import { ReactComponent as IconDownloadBlack } from '../../icon/icon-download-black.svg'
import { selectDashboard } from '../../store/slice'
import { downloadAllChart, downloadChart } from '../chartInPanel/store/slice'
import style from './index.module.css'

const DownloadBtn = ({ dashboardID }) => {
  const dispatch = useDispatch()
  const { screenType } = useSelector(selectDashboard)

  const downloadAllCharts = (event) => {
    event.preventDefault()
    dispatch(downloadChart({ id: -1 }))
    dispatch(downloadAllChart({ id: dashboardID }))
  }

  return (
    <>
      {screenType === screenTypes.EDIT_CHART ? (
        <IconDownloadBlack
          className={style.disable}
          title={I18n.t(
            'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_DASHBOARD',
          )}
        />
      ) : (
        <>
          <IconDownloadBlack
            className="cursor-pointer"
            title={I18n.t('financialChart.DOWNLOAD_DASHBOARD')}
            onClick={downloadAllCharts}
          />
        </>
      )}
    </>
  )
}

export default DownloadBtn
