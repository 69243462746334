import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { CommoditiesSearch } from '../CommoditiesSearch'
import { TIME_FILTER_TYPE } from '../exportImportTable/constants'
import { selectTypeFilter } from '../exportImportTable/store/slice'
import style from '../index.module.css'
import {
  ACC_MONTH_FILTER_EN,
  ACC_MONTH_FILTER_VI,
  EXPORT_STRUCTURE_FILTER,
  MONTH_LIST_FILTER_EN,
  MONTH_LIST_FILTER_VI,
  TYPE_CHART_STRUCTURE,
  YEAR_LIST_FILTER,
} from './constants'
import { GrowthChart } from './growthChart'
import { changeIsAccGrowthChart, selectIsAcc } from './growthChart/store/slice'
import { StructureChart } from './structureChart'
import {
  changeCurrentChartType,
  changeCurrentMonth,
  changeCurrentYear,
  selectCurrentChartType,
  selectCurrentMonth,
  selectCurrentYear,
  selectMaxDate,
} from './structureChart/store/slice'

export const ExportStructure = ({ width, height }) => {
  const rightHeaderRef = useRef()
  const searchBoxRef = useRef()

  const dispatch = useDispatch()

  const currentTypeFilter = useSelector(selectTypeFilter)
  const locale = useSelector((state) => state.i18n.locale)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const isAcc = useSelector(selectIsAcc)
  const currentChartType = useSelector(selectCurrentChartType)
  const maxDate = useSelector(selectMaxDate)

  const getMonthFilterByLocale = (locale) => {
    if (currentTypeFilter === TIME_FILTER_TYPE.ACC_MONTHLY) {
      return locale === 'en' ? ACC_MONTH_FILTER_EN : ACC_MONTH_FILTER_VI
    } else {
      return locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI
    }
  }

  const handleOnChange = (item) => {
    dispatch(changeCurrentChartType(item.value))
  }

  const handleMonthChange = (item) => {
    dispatch(changeCurrentMonth(item))
  }

  const handleYearChange = (item) => {
    dispatch(changeCurrentYear(item))
  }

  const handleChangeChecked = (e) => {
    dispatch(changeIsAccGrowthChart(e.target.checked))
  }

  const handleClick = () => {
    dispatch(changeIsAccGrowthChart(!isAcc))
  }

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex j-b">
              <div className="row">
                <div ref={searchBoxRef}>
                  <CommoditiesSearch />
                </div>
                <DispatchActionTab
                  data={EXPORT_STRUCTURE_FILTER}
                  activeTab={currentChartType}
                  onChangeTab={handleOnChange}
                  itemStyle={{
                    fontWeight: 500,
                    fontSize: 11,
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                  widthComponent={
                    width -
                    rightHeaderRef.current?.getBoundingClientRect().width -
                    searchBoxRef.current?.getBoundingClientRect().width
                  }
                />
                <div
                  style={
                    currentTypeFilter === TIME_FILTER_TYPE.YEARLY &&
                    currentChartType === TYPE_CHART_STRUCTURE.GROWTH
                      ? { marginLeft: '15px' }
                      : { display: 'none' }
                  }
                  className="align-center"
                >
                  <input
                    type="checkbox"
                    className={`checkbox ${style.checkbox}`}
                    checked={isAcc}
                    onChange={handleChangeChecked}
                  />
                  <Span onClick={handleClick} style={{ marginLeft: 10 }}>
                    <Translate
                      value={
                        'economy.importExport.importExport.exportImportByCommodities.ACC_VALUE'
                      }
                    />
                  </Span>
                </div>
              </div>
              <div className="row" ref={rightHeaderRef}>
                <div
                  className={`${style.inputDropdown}`}
                  style={
                    currentChartType === TYPE_CHART_STRUCTURE.GROWTH ||
                    currentTypeFilter === TIME_FILTER_TYPE.YEARLY
                      ? { display: 'none' }
                      : { width: '84px', marginRight: '4px' }
                  }
                >
                  <SelectCustom
                    value={currentMonth ?? maxDate?.realMonth}
                    isI18n={true}
                    selectData={getMonthFilterByLocale(locale)}
                    handleChange={handleMonthChange}
                  />
                </div>
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '84px' }}
                >
                  <SelectCustom
                    value={currentYear ?? maxDate?.realYear}
                    isI18n={true}
                    selectData={YEAR_LIST_FILTER}
                    handleChange={handleYearChange}
                  />
                </div>
              </div>
            </div>
            {size.height && (
              <div style={{ height: height - size.height }} id="structureChart">
                {currentChartType === TYPE_CHART_STRUCTURE.STRUCTURE ? (
                  <StructureChart width={width} height={height} />
                ) : (
                  <GrowthChart width={width} height={height} />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
