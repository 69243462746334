import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { COMPONENT_FILTER } from '../../../configs/Layout'
import { debounce } from '../../utils/Common'
import { Span } from '../html/Span'
import { Panel } from '../panel'
import { PanelHeader } from '../panelHeader'
import { Resize } from './Resize'
import style from './index.module.css'

const PanelFilter = ({
  children,
  title,
  isFilterRight,
  sizes,
  setSizes,
  panelKey,
  panelRefs,
  windowBodyStyle,
}) => {
  const initialSizes = useRef()

  const [isShowFilter, setIsShowFilter] = useState(true)

  useEffect(() => {
    const resetFilter = debounce(() => setIsShowFilter(true), 400)
    window.addEventListener('resize', resetFilter)
    return () => window.removeEventListener('resize', resetFilter)
  }, [])

  const handleShowFilter = () => {
    setIsShowFilter(true)

    const widthChange =
      COMPONENT_FILTER.WIDTH_IF_HIDE - initialSizes.current[panelKey].width
    const newSizes = {
      ...sizes,
      [panelKey]: {
        ...sizes[panelKey],
        width: initialSizes.current[panelKey].width,
      },
    }
    newSizes[panelKey].filterResize.forEach((item) => {
      if (item.ratioWidth)
        newSizes[item.key].width += item.ratioWidth * widthChange
      if (item.ratioLeft)
        newSizes[item.key].left += item.ratioLeft * widthChange
    })
    setSizes(newSizes)
  }

  const handleHideFilter = () => {
    initialSizes.current = { ...sizes }
    setIsShowFilter(false)

    const widthChange =
      panelRefs[panelKey].getBoundingClientRect().width -
      COMPONENT_FILTER.WIDTH_IF_HIDE
    const newSizes = {
      ...sizes,
      [panelKey]: {
        ...sizes[panelKey],
        width: COMPONENT_FILTER.WIDTH_IF_HIDE,
      },
    }
    newSizes[panelKey].filterResize.forEach((item) => {
      newSizes[item.key].width += item.ratioWidth * widthChange
      newSizes[item.key].left += item.ratioLeft * widthChange
    })
    setSizes(newSizes)
  }

  return (
    <Panel
      title={title}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isOnlyResize={true}
    >
      <>
        <div
          className={style.filter}
          style={{ display: isShowFilter ? 'block' : 'none' }}
        >
          <div className="window h-100">
            <PanelHeader>
              <div className="window-header-title">
                <i className="icon-filter" />
                &nbsp;&nbsp;
                <Span style={{ textTransform: 'capitalize' }}>
                  <Translate value={title || ''} />
                </Span>
              </div>
              <div className="window-header-action">
                <a className={style.pointer} onClick={handleHideFilter}>
                  <i
                    className={
                      isFilterRight
                        ? 'icon-arrow-right-two'
                        : 'icon-arrow-left-two'
                    }
                  />
                </a>
              </div>
            </PanelHeader>
            <div className="window-body h-100" style={windowBodyStyle}>
              {children}
            </div>
          </div>
        </div>
        {!isShowFilter && (
          <div
            className="h-100"
            style={{ width: `${COMPONENT_FILTER.WIDTH_IF_HIDE}px` }}
          >
            <div className="window h-100">
              <div className="window-header">
                <div className="window-header-title">
                  <i className="icon-filter" />
                </div>
              </div>
              <div
                onClick={handleShowFilter}
                className={`window-body h-100 d-flex ali-center ${style.pointer}`}
              >
                <i
                  className={
                    isFilterRight
                      ? 'icon-arrow-left-two'
                      : 'icon-arrow-right-two'
                  }
                />
              </div>
            </div>
          </div>
        )}
        <Resize
          setSizes={setSizes}
          sizes={sizes}
          panelKey={panelKey}
          resizeClassName={style.resizeX}
          size={sizes[panelKey]}
        />
      </>
    </Panel>
  )
}

PanelFilter.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  isFilterRight: PropTypes.bool,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  panelKey: PropTypes.string.isRequired,
  panelRefs: PropTypes.object.isRequired,
  windowBodyStyle: PropTypes.object,
}

PanelFilter.defaultProps = {
  isFilterRight: false,
  windowBodyStyle: {},
}

export default PanelFilter
