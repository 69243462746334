import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { Span } from '../../common/html/Span'
import { SizeTracker } from '../../common/sizeTracker'
import { TopInfo } from '../../common/topInfo/indexTopInfo'
import { redirectLinkIcbPdf } from '../../utils/Link'
import Content from './Content'
import style from './index.module.css'
import { resetStore } from './store/slice'

const listIndex = [
  {
    exchangeCode: 'HOSE',
    groupCode: 'VNINDEX',
    groupId: 11,
    groupLevel: 4,
    groupName: 'VNINDEX',
    groupOrder: 11,
    parentGroupId: 2,
  },
  {
    exchangeCode: 'HNX',
    groupCode: 'HNXIndex',
    groupId: 46,
    groupLevel: 4,
    groupName: 'HNXIndex',
    groupOrder: 46,
    parentGroupId: 44,
  },
  {
    exchangeCode: 'HOHAUP',
    groupCode: 'HOHAUP',
    groupId: 91,
    groupLevel: 4,
    groupName: 'HOHAUP',
    groupOrder: 999,
    parentGroupId: 91,
    isDisableRedirect: true,
  },
]

export const SectorStatistics = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <TopInfo customIndex={listIndex} />
          {size.height && (
            <div
              style={{ height: `calc(100% - ${size.height}px)` }}
              className="position-relative"
            >
              <ScrollGroupComponent>
                <Content />
              </ScrollGroupComponent>
            </div>
          )}
          <Span className={style.noteIcb} style={{ fontSize: 11 }}>
            <div>
              <Translate value="common.noteIcb.NOTE_1" />
              <a onClick={redirectLinkIcbPdf}>
                <Translate value="common.noteIcb.NOTE_2" />
              </a>
            </div>
          </Span>
        </>
      )}
    </SizeTracker>
  )
}
