import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { isInValidValue } from '../../../../utils/Value'
import CalendarPopper from '../../../calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../hooks/useTimeZone'
import style from '../index.module.css'
import {
  changeValueDetailPopupIndicator,
  selectValueDetailPopupIndicator,
} from '../store/slice'

const RadioCalendar = ({
  defaultValue,
  keySelect,
  disabled,
  maxDate,
  minDate,
}) => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const valueStore = useSelector(selectValueDetailPopupIndicator(keySelect))
  const value = valueStore || defaultValue

  useEffect(() => {
    if (isInValidValue(valueStore)) {
      dispatch(
        changeValueDetailPopupIndicator({
          key: keySelect,
          value: defaultValue,
        }),
      )
    }
  }, [keySelect])

  const handleChange = (value) => {
    dispatch(
      changeValueDetailPopupIndicator({
        key: keySelect,
        value: getISOStartOrEndOfDay(value, timezone, true),
      }),
    )
  }

  return (
    <div
      className={`h-20 d-flex ali-center ${style.calendar} ${
        disabled ? style.disabled : ''
      }`}
    >
      <CalendarPopper
        date={new Date(formatDateTime(value, FORMAT.DATE, undefined, timezone))}
        handleChange={handleChange}
        customHeader={HeaderOneCalendarRange({
          startYear: 2000,
          endYear: new Date().getFullYear(),
          isOptionSelectedDefaultColor: false,
        })}
        maxDate={
          new Date(formatDateTime(maxDate, FORMAT.DATE, undefined, timezone))
        }
        minDate={
          new Date(formatDateTime(minDate, FORMAT.DATE, undefined, timezone))
        }
        CustomInput={InputDateCalendar}
      />
      <i className="icon-arrow-down" />
    </div>
  )
}

RadioCalendar.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  keySelect: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

RadioCalendar.defaultProps = {
  defaultValue: new Date(),
  maxDate: new Date(),
  disabled: false,
}

export default RadioCalendar
