export const DATA_TYPE_LIST = {
  GROWTH: 'growth',
  VALUE: 'value',
}

export const LIST_RADIO = [
  {
    title: 'economy.importExport.importExport.RADIO_BUTTON_RELATIVE',
    value: DATA_TYPE_LIST.GROWTH,
  },
  {
    title: 'economy.importExport.importExport.RADIO_BUTTON_ABSOLUTE',
    value: DATA_TYPE_LIST.VALUE,
  },
]

export const TIME_FILTER_TYPE = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
}

export const TABLE_FILTER = [
  {
    title: 'economy.importExport.importExport.FILTER_YEARLY',
    value: TIME_FILTER_TYPE.YEARLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY',
    value: TIME_FILTER_TYPE.MONTHLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY_ACC',
    value: TIME_FILTER_TYPE.ACC_MONTHLY,
  },
]

export const DEFAULT_MAX_MONTH = 6
export const DEFAULT_MAX_YEAR = 2022

export const LIMIT_FIRST_FETCH = 15
export const LIMIT_FETCH = 4
