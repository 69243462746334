import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { Table } from '../../../../common/table'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { FIXED_ROW_ID } from './config'
import FirstCellColumn from './FirstCellColumn'
import OtherColumnCell from './OtherCellColumn'
import {
  getDate,
  getDepositRateCommercialValue,
  getGroupColumns,
  getIdKey,
  getIdWithTitles,
  getIsShowAlert,
  getTab,
  getTableData,
  getTableDataLoading,
  handleIsShowAlert,
  sort,
} from './store/slice'
import { getMaxDate, getStatisticOtherBank } from './store/thunk'

export const TABLE_INTEREST_ID = 'TABLE_INTEREST_ID'
const HORIZONTAL_TRACK_LEFT = 28

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableData = useSelector(getTableData)
  const date = useSelector(getDate)
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const idKey = useSelector(getIdKey)
  const idWithTitle = useSelector(getIdWithTitles)
  const groupColumns = useSelector(getGroupColumns)
  const tab = useSelector(getTab)
  const isShowAlert = useSelector(getIsShowAlert)

  useEffect(() => {
    dispatch(
      getStatisticOtherBank({
        DateTo: date ? getISOStartOrEndOfDay(date, timeZone, true) : undefined,
        IsIndividual: tab,
      }),
    )
  }, [date, locale, tab])

  useEffect(() => {
    dispatch(
      getMaxDate({
        InterestRateBank: 'Other',
      }),
    )
  }, [])

  if (tableDataLoading) {
    return <Loading />
  }

  if (Object.keys(tableData).length === 0) {
    return <NoData />
  }

  return (
    <div style={{ height }} id={TABLE_INTEREST_ID}>
      <Table
        ids={idKey}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        sort={sort}
        columnDraggable={false}
        getDataFromRedux={getDepositRateCommercialValue}
        rowDraggable={false}
        stickyBottomRowCount={3}
        stickies={{ emptyColumn: true }}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              tdStyle: {
                minWidth: 240,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const title = idWithTitle[rowId]
                return (
                  <FirstCellColumn
                    val={title}
                    props={props}
                    rowId={rowId}
                    isFixedRow={FIXED_ROW_ID.includes(rowId)}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
              minWidth: 100,
            },
            thStyle: {
              textAlign: 'right',
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <OtherColumnCell
                  val={val}
                  props={props}
                  rowId={rowId}
                  colId={colId}
                  isFixedRow={FIXED_ROW_ID.includes(rowId)}
                />
              )
            },
          }
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight
      />
      <PopupAlert
        message={<Translate value={'economy.monetary.monetary.MAX_ITEM'} />}
        isShow={isShowAlert}
        handleClose={() => {
          dispatch(handleIsShowAlert(false))
        }}
      />
    </div>
  )
}

export default TableComponent
