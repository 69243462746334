import moment from 'moment'
import { MARGIN } from '../../../common/chart/constants'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../common/chart/helper'
import { formatVal } from '../../../utils/Value'
import { TIME_FILTER_VALUE } from './filter/constants'

export const getDataTitle = (
  timeFilter,
  item,
  separator = '/',
  isChart = false,
) => {
  switch (timeFilter) {
    case TIME_FILTER_VALUE.YEARLY:
      return item.isTTM
        ? `Q${item.quarter < 5 ? item.quarter : 4}${separator}${item.year}${
            isChart ? '\n' : ''
          }(TTM)`
        : item.year
    case TIME_FILTER_VALUE.QUARTERLY:
      return `Q${item.quarter < 5 ? item.quarter : 4}${separator}${item.year}`
    default:
      return item.isTTM ? `${item.year}(TTM)` : `${item.year}`
  }
}

export const getComparisonChartData = (indicator, data, basicInfo) => {
  if (indicator.subValue && indicator.subValue.key) {
    const key = indicator.subValue.key
    const isFormatValToPercent = indicator.subValue.isFormatValToPercent
    const formatValue = indicator.subValue.isFormatValue.formatValue
    return data
      .map((item) => ({
        organizationId: item.organizationId,
        label: item.ticker,
        comparisonValue: !item[key]
          ? item[key]
          : isFormatValToPercent
          ? Number(formatVal(item[key] * 100).replaceAll(',', ''))
          : Number(formatVal(item[key] / formatValue).replaceAll(',', '')),
      }))
      .sort((item) => (item.label === basicInfo.ticker ? -1 : 0))
  }
  return []
}

export const getGrowthTimelineChartData = (indicator, data, filter) => {
  if (indicator.subValue && indicator.subValue.key) {
    const key = indicator.subValue.key
    const isFormatValToPercent = indicator.subValue.isFormatValToPercent
    const formatValue = indicator.subValue.isFormatValue.formatValue
    const formatData = data.map((item) => ({
      organizationId: item.organizationId,
      label: getDataTitle(filter.viewBy.value, item, '-', true),
      growthTimelineValue: !item[key]
        ? item[key]
        : isFormatValToPercent
        ? Number(formatVal(item[key] * 100).replaceAll(',', ''))
        : Number(formatVal(item[key] / formatValue).replaceAll(',', '')),
      year: item.year,
      quarter: item.quarter,
      isTTM: item.isTTM,
    }))
    const ttmData = formatData.filter((item) => item.isTTM)
    const otherData = formatData
      .filter((item) => !item.isTTM)
      .sort((a, b) => {
        if (a.quarter && b.quarter && a.quarter < 5 && b.quarter < 5) {
          return (
            moment().set('year', b.year).set('quarter', b.quarter).unix() -
            moment().set('year', a.year).set('quarter', a.quarter).unix()
          )
        } else {
          return b.year - a.year
        }
      })
      .filter((_, index) => index < parseInt(filter.numberOfPeriod.value))
      .reverse()

    return [...otherData, ...ttmData]
  }
  return []
}

const getEachChartMargin = (data, barKeys, fontSize) => {
  if (data.length && barKeys.length) {
    const [leftMin, leftMax] = yExtentsCharts(data, barKeys)
    const leftTickValues = getRoundedTickValues([leftMin, leftMax], 5, true)
    const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)
    return getChartMargin(leftTickValues, [], leftDecimalLength, 0, fontSize)
  }
  return MARGIN
}

export const getCompareChartMargin = (
  data,
  otherData,
  barKeys,
  otherBarKeys,
  fontSize,
) => {
  const chartMargin = getEachChartMargin(data, barKeys, fontSize)
  const otherChartMargin = getEachChartMargin(otherData, otherBarKeys, fontSize)
  // Compare two chart margin
  return {
    left: Math.max(chartMargin?.left || 0, otherChartMargin?.left || 0),
    right: Math.max(chartMargin?.right || 0, otherChartMargin?.right || 0),
  }
}
