import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import Title from '../../common/Title'
import { getSortedDataColumnChart } from '../../common/helper'
import {
  selectDataChangeInMarketShare,
  selectFilter,
  selectLoadingChangeInMarketShare,
} from '../store/slice'
import { getInsuChangeInMarketShare } from '../store/thunk'
import ChartComponent from './ChartComponent'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataChangeInMarketShare)
  const loading = useSelector(selectLoadingChangeInMarketShare)
  const filter = useSelector(selectFilter)

  useEffect(() => {
    if (basicInfo.organizationId && filter.year && filter.quarter) {
      dispatch(
        getInsuChangeInMarketShare({
          OrganizationId: basicInfo.organizationId,
          Year: filter.year,
          Quarter: filter.quarter,
        }),
      )
    }
  }, [filter, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title text="LABEL_CHART_CHANGE" />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <ChartComponent
                    width={width}
                    height={height - size.height}
                    data={getSortedDataColumnChart(
                      dataChart,
                      basicInfo?.ticker,
                    )}
                    keyXAxis="ticker"
                    barKey="c1"
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
