import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollComponent } from '../../ScrollComponent'
import { Loading } from '../../loading'
import { SizeTracker } from '../../sizeTracker'
import ListenerScrollTable from './ListenerScrollTable'
import Pagination from './Pagination'
import Tbody from './Tbody'
import Thead from './Thead'
import style from './index.module.css'
import { resetStore } from './store/slice'

export const idScroll = 'scroll-table-de'
export const idContainerTable = 'table-de-container'
export const idTableAbsolute = 'table-de-absolute'

const TableDataExplorer = ({
  isLoading,
  selectColumnId,
  selectColumnCell,
  selectTickerCell,
  selectId,
  maxColumn,
  maxRow,
  selectFullId,
  selectFullColumnId,
  changeIdDisplay,
  renderContentPagination,
  stateResetDefaultPage,
  cellInfo,
  sortColumn,
  swapColumn,
  renderTh,
  renderHeaderTable,
  heightHeaderTable,
  selectIdDisplay,
  isSwap,
  isSort,
  tableThRowSpan,
  tableThTop,
  noDataText,
  customClassName,
  scrollbarGray,
  isCustormHeader,
  isHide,
  pageSizePagination,
  changePage,
  paginationComponent,
  widthColumn,
  heightPerView,
  widthPerView,
}) => {
  const dispatch = useDispatch()
  const columnIndex = useRef([...Array(maxColumn).keys()])

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  const getTableClassName = () => {
    const classNames = [style.table, customClassName.table || '']
    return classNames.join(' ')
  }

  const getTableParentClassName = () => {
    const classNames = [style.tableParent]
    if (isLoading) {
      classNames.push(style.tableLoadingBlur)
    }
    return classNames.join(' ')
  }

  return (
    <div className="h-100 position-relative">
      {isLoading && <Loading />}
      <SizeTracker>
        {(size) => (
          <>
            {changeIdDisplay &&
              (paginationComponent ?? (
                <Pagination
                  selectFullId={selectFullId}
                  changeIdDisplay={changeIdDisplay}
                  renderContentPagination={renderContentPagination}
                  stateResetDefaultPage={stateResetDefaultPage}
                  pageSizePagination={pageSizePagination}
                  changePage={changePage}
                  isHide={isHide}
                />
              ))}
            {size.height && renderHeaderTable && renderHeaderTable()}
            {(size.height || size.height === 0) && (
              <div
                id={idScroll}
                style={{
                  height: `calc(100% - ${size.height + heightHeaderTable}px)`,
                }}
              >
                <ScrollComponent bgColor={scrollbarGray && '#D9D9D9'}>
                  <ListenerScrollTable
                    selectFullColumnId={selectFullColumnId}
                    selectIdDisplay={selectIdDisplay}
                    heightPerView={heightPerView}
                    widthPerView={widthPerView}
                  />
                  <div id={idContainerTable} className="position-relative">
                    <div id={idTableAbsolute} className={style.absolute}>
                      <div className={getTableParentClassName()}>
                        <div className="h-100 d-flex">
                          <table className={getTableClassName()}>
                            <Thead
                              columnIndex={columnIndex.current}
                              selectColumnId={selectColumnId}
                              selectColumnCell={selectColumnCell}
                              cellInfo={cellInfo}
                              sortColumn={sortColumn}
                              swapColumn={swapColumn}
                              renderTh={renderTh}
                              isSwap={isSwap}
                              isSort={isSort}
                              tableThRowSpan={tableThRowSpan}
                              tableThTop={tableThTop}
                              customClassName={customClassName}
                              isCustormHeader={isCustormHeader}
                              widthColumn={widthColumn}
                            />
                            <Tbody
                              columnIndex={columnIndex.current}
                              maxRow={maxRow}
                              selectColumnId={selectColumnId}
                              selectTickerCell={selectTickerCell}
                              selectId={selectId}
                              selectFullId={selectFullId}
                              cellInfo={cellInfo}
                              selectColumnCell={selectColumnCell}
                              noDataText={noDataText}
                              customClassName={customClassName}
                            />
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </ScrollComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}

TableDataExplorer.propTypes = {
  isLoading: PropTypes.bool,
  maxRow: PropTypes.number.isRequired,
  maxColumn: PropTypes.number.isRequired,
  selectId: PropTypes.func.isRequired,
  selectColumnId: PropTypes.func.isRequired,
  selectTickerCell: PropTypes.func.isRequired,
  selectColumnCell: PropTypes.func.isRequired,
  selectFullId: PropTypes.func.isRequired,
  selectFullColumnId: PropTypes.func.isRequired,
  changeIdDisplay: PropTypes.func,
  renderContentPagination: PropTypes.func,
  stateResetDefaultPage: PropTypes.any,
  cellInfo: PropTypes.array,
  sortColumn: PropTypes.func,
  swapColumn: PropTypes.func,
  renderTh: PropTypes.func,
  renderHeaderTable: PropTypes.func,
  heightHeaderTable: PropTypes.number,
  isSwap: PropTypes.bool,
  isSort: PropTypes.bool,
  noDataText: PropTypes.string,
  customClassName: PropTypes.object,
  pageSizePagination: PropTypes.number,
  paginationComponent: PropTypes.element,
  widthColumn: PropTypes.string,
}

TableDataExplorer.defaultProps = {
  cellInfo: [],
  heightHeaderTable: 0,
  isSwap: true,
  isSort: true,
  customClassName: {},
  widthColumn: '190px',
}

export default TableDataExplorer
