import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { changeLocaleGrowthChart } from '../importGrowth/store/slice'
import { FilterList } from './FilterList'
import { TableComponent } from './TableComponent'
import {
  LIMIT_FETCH_SCROLL,
  LIMIT_FIRST_FETCH_MONTHLY,
  LIMIT_FIRST_FETCH_YEARLY,
  TIME_FREQUENCY,
} from './constants'
import {
  changeLocale,
  selectDataTable,
  selectIsFirstRender,
  selectIsValueOrGrowth,
  selectMaxYear,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectTimeFrequencyType,
  setIsFirstRenderImportMarketTab,
} from './store/slice'
import { getImportStatisticsByMarket, getMaxDate } from './store/thunk'

const PADDING_TOP_TABLE = 10

export const ImportStatisticTable = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataTable = useSelector(selectDataTable)
  const timeFrequencyType = useSelector(selectTimeFrequencyType)
  const isValueOrGrowth = useSelector(selectIsValueOrGrowth)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const locale = useSelector((state) => state.i18n.locale)
  const isFirstRender = useSelector(selectIsFirstRender)
  const maxYear = useSelector(selectMaxYear)

  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)

  const isYearly = timeFrequencyType === TIME_FREQUENCY.YEARLY

  useEffect(() => {
    dispatch(
      getMaxDate({
        FisheryConst: 'ImportByMarket',
      }),
    )
  }, [])

  useEffect(() => {
    dispatch(setIsFirstRenderImportMarketTab(true))
    dispatch(
      getImportStatisticsByMarket({
        TimeFrequency: timeFrequencyType,
        Year: isYearly ? maxYear : null,
        Limit: isYearly ? LIMIT_FIRST_FETCH_YEARLY : LIMIT_FIRST_FETCH_MONTHLY,
      }),
    )
  }, [timeFrequencyType, isValueOrGrowth, locale])

  useEffect(() => {
    if (!isFirstRender) {
      dispatch(
        getImportStatisticsByMarket({
          Year: minCurrentYear,
          Month: isYearly ? null : minCurrentMonth,
          TimeFrequency: timeFrequencyType,
          Limit: LIMIT_FETCH_SCROLL,
        }),
      )
    }
  }, [isFirstRender, newMaxMonth])

  useEffect(() => {
    dispatch(changeLocale(locale))
    dispatch(changeLocaleGrowthChart(locale))
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterList width={width} isValueOrGrowth={isValueOrGrowth} />
            {size.height && (
              <div
                id="importStatisticTable"
                style={{
                  paddingTop: PADDING_TOP_TABLE,
                  height: height - size.height - PADDING_TOP_TABLE,
                }}
              >
                <TableComponent
                  dataTable={dataTable}
                  minCurrentMonth={minCurrentMonth}
                  setNewMaxMonth={setNewMaxMonth}
                  isFirstRender={isFirstRender}
                  setIsFirstRenderImportMarketTab={
                    setIsFirstRenderImportMarketTab
                  }
                  isValueOrGrowth={isValueOrGrowth}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
