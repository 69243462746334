import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { TooltipInfo } from '../../../../common/tooltipInfo'
import { Z_INDEX } from '../../../../constants/Common'
import Sort from './Sort'
import { sortStateMap } from './constants'
import style from './index.module.css'
import { changeKeySort, resetIds } from './store/slice'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const [isShow, setIsShow] = useState('')
  const dispatch = useDispatch

  const renderTitle = (text, row) => {
    return (
      <Span
        className={`${style.theadContent} ${row.isHover && style.withIcon}`}
      >
        <Translate value={text} />
        {row.isHover && (
          <>
            <TooltipInfo
              fontSize={12}
              tooltip="bond.corporateBond.priceBoard.PRICE_NOTE"
              onClick={(e) => e.stopPropagation()}
            />
          </>
        )}
        {!row?.disableSort && !row?.hideIconSort && (
          <Sort type={stateSort[row.keySort]} />
        )}
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={`key-${index}`}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              } ${index === 0 && style.cellLeft} ${style.firstCellHeader} ${
                row.alignRight && style.textRight
              }`}
              onClick={() =>
                sortColumn({
                  [row.keySort]: sortStateMap[stateSort[row.keySort]],
                })
              }
            >
              <div className={stateSort[row?.keySort] ? 'mr-5' : undefined}>
                {renderTitle(row.title, row)}

                {row.additionText && (
                  <Span className={style.additionText} style={{ fontSize: 12 }}>
                    <Translate value={row.additionText} />
                  </Span>
                )}
              </div>

              <div
                style={{ height: 14 }}
                className={`${style.tableIcon} ${style.tableIconMore}`}
                onMouseEnter={() => setIsShow(row.name)}
                onMouseLeave={() => setIsShow('')}
                onClick={(e) => e.stopPropagation()}
              >
                <i
                  className="icon-menu-dot-hor"
                  style={{ color: '#8B8B8B', fontSize: 14 }}
                />
                {isShow === row.name && (
                  <div className={`${style.dropdown}`}>
                    {row.sortBy &&
                      row.sortBy.map((item) => (
                        <div
                          key={item.name}
                          className={`${style.dropdownItem} ${
                            item.name === row.keySort &&
                            style.dropdownItemActive
                          }`}
                          onClick={() => {
                            dispatch(
                              changeKeySort({
                                key: row.name,
                                value: item.name,
                              }),
                            )

                            dispatch(resetIds())
                            setIsShow('')
                          }}
                        >
                          <Translate value={item.value} />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
