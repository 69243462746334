import { memo, useMemo, useState } from 'react'
import { Loading } from '../../common/loading'
import useGetLayoutDashboard from '../chart/common/useGetLayoutDashboard'
import usePanelChart from '../common/chartInPanel/usePanelChart'
import withWrapperDataChart from '../common/chartInPanel/withWrapperDataChart'
import { Panel } from '../common/panel'
import { KEY_DASHBOARD_TABS } from '../editDashboard/constants'
import { ChartPanelContent } from './ChartPanelContent'

const ChartPanel = ({
  data,
  index,
  item,
  loading,
  handlePreviewChart,
  isShowViews,
  isShowSetting,
  isHaveDataChartPreview,
}) => {
  const { layoutDisplay } = useGetLayoutDashboard(data.layout)

  const [isEdit, setIsEdit] = useState(false)

  const renameChart = () => {
    setIsEdit(true)
  }

  const { editChart, duplicateChart } = usePanelChart(
    item,
    true,
    index + 1 + KEY_DASHBOARD_TABS,
  )

  const renderChart = useMemo(
    () => (
      <ChartPanelContent
        item={item}
        data={data}
        isHaveDataChartPreview={isHaveDataChartPreview}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    ),
    [
      JSON.stringify(item),
      JSON.stringify(data),
      isHaveDataChartPreview,
      isEdit,
      setIsEdit,
    ],
  )

  return (
    <Panel
      index={index}
      item={item}
      dashboard={data}
      setPreviewChart={() => handlePreviewChart(item)}
      editChart={editChart}
      renameChart={renameChart}
      width={layoutDisplay[index]?.width}
      duplicateChart={duplicateChart}
      positionCopyToLeft={layoutDisplay[index]?.positionCopyToLeft}
      isShowViews={isShowViews}
      isShowSetting={isShowSetting}
    >
      <div className="position-relative w-100 h-100">
        {loading ? <Loading isBlack /> : renderChart}
      </div>
    </Panel>
  )
}

export default withWrapperDataChart(memo(ChartPanel))
