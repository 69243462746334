import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TextNote } from '../../../common/textNote'
import Title from '../../../common/topInfo/components/Title'
import PanelStatistic from './PanelStatistic'

export const FundCenterStatistic = () => {
  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="fund.fundCenter.STATISTIC" />
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    <PanelStatistic />
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
            <TextNote
              value="fund.fundCenter.statistic.TABLE_NOTE"
              isI18n
              className="mt-8"
              opacity={1}
            />
          </>
        )}
      </SizeTracker>
    </div>
  )
}
