import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { HeaderCommon } from '../../HeaderCommon'
import { selectLatest, selectLatestLoading } from '../../store/slice'
import { getLatestNews } from '../../store/thunk'

export const LatestNews = ({ height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const data = useSelector(selectLatest)
  const isLoading = useSelector(selectLatestLoading)

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(getLatestNews({ ICBId: sectorInfo.icbId }))
    }
  }, [locale, sectorInfo.icbId])

  return (
    <HeaderCommon
      data={data}
      isLoading={isLoading}
      height={height}
      isShowTitle
    />
  )
}

LatestNews.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
