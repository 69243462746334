import { DEFAULT_ROUTE } from '../../configs/Common'

export const CURRENT_STATE_KEY = 'CURRENT_STATE_KEY'
let MAX_STATE = 10

class CurrentState {
  obj = {}

  addRoute = ({ key, data }) => {
    const state = JSON.parse(localStorage.getItem(CURRENT_STATE_KEY)) || {}

    // Remove overlap key
    const tmpState = {
      ...state,
      [key]: data,
    }

    const { [key]: newData, ...others } = tmpState
    const newState = { [key]: newData, ...others }

    const result = {}
    const numDisableRecentVisited = Object.keys(newState).filter(
      (key) => newState[key].disableRecentVisited,
    ).length
    MAX_STATE = MAX_STATE + numDisableRecentVisited

    Object.keys(newState).forEach((key, index) => {
      if (index < MAX_STATE) {
        result[key] = newState[key]
      }
    })

    localStorage.setItem(CURRENT_STATE_KEY, JSON.stringify(result))
  }

  getCurrentState = () => {
    const state = JSON.parse(localStorage.getItem(CURRENT_STATE_KEY)) || {}

    if (Object.values(state).length === 0) {
      return {
        key: DEFAULT_ROUTE.route,
        data: DEFAULT_ROUTE,
      }
    }

    const [data] = Object.values(state)
    const [key] = Object.keys(state)

    return {
      key,
      data,
    }
  }

  updateCurrentState = (data) => {
    const state = JSON.parse(localStorage.getItem(CURRENT_STATE_KEY)) || {}
    const key = this.getCurrentState().key
    state[key] = {
      ...(state[key] || {}),
      ...data,
    }

    localStorage.setItem(CURRENT_STATE_KEY, JSON.stringify(state))
  }

  getAll = () => {
    return JSON.parse(localStorage.getItem(CURRENT_STATE_KEY)) || {}
  }

  delete = (keyItem) => {
    const state = JSON.parse(localStorage.getItem(CURRENT_STATE_KEY)) || {}
    delete state[keyItem]
    localStorage.setItem(CURRENT_STATE_KEY, JSON.stringify(state))
  }
}

export default new CurrentState()
