import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import FirstColumnCell from './FirstColumnCell'
import OtherColumnCell from './OtherColumnCell'
import {
  getForecastData,
  getForecastLoading,
  getGroupColumns,
  getIds,
  selectForecastValue,
} from './store/slice'
import { getGDPGrowthForecast } from './store/thunk'

export const EMPTY_ROW_ID = 2

const GDPGrowthForecast = () => {
  const dispatch = useDispatch()

  const forecastLoading = useSelector(getForecastLoading)
  const forecastData = useSelector(getForecastData)
  const idsWithTitle = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getGDPGrowthForecast())
  }, [locale])

  if (forecastLoading) {
    return <Loading />
  }

  if (forecastData.length === 0) {
    return <NoData />
  }

  const ids = idsWithTitle.map((item) => item.id)

  return (
    <div className="h-100">
      <Table
        ids={idsWithTitle.map((item) => item.id)}
        columnDraggable={false}
        getDataFromRedux={selectForecastValue}
        rowDraggable={false}
        stickyBottomRowCount={1}
        stickies={{ emptyColumn: true }}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHeadHorizontal = idsWithTitle.find(
                  (item) => item.id === rowId,
                )
                return (
                  <FirstColumnCell
                    title={tableHeadHorizontal?.title}
                    level={tableHeadHorizontal?.level || 1}
                    props={props}
                    isLastRow={rowId === ids[ids.length - 1]}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
              fontWeight: 600,
              fontSize: 12,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <OtherColumnCell
                  val={val}
                  props={props}
                  rowId={rowId}
                  isLastCell={
                    colId === groupColumns[groupColumns.length - 1].key
                  }
                  isLastRow={rowId === ids[ids.length - 1]}
                  colId={colId}
                />
              )
            },
          }
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        hasUpdatedBorder={false}
        defaultScrollToRight={true}
        disableRowHovered={{ [EMPTY_ROW_ID]: true }}
      />
    </div>
  )
}

export default GDPGrowthForecast
