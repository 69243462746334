import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelPriceGrowth } from './priceGrowth/PanelPriceGrowth'
import { PanelPriceStatistics } from './priceStatistics/PanelPriceStatistics'

const MAP_KEY = {
  PRICE_STATISTIC: 'PRICE STATISTIC',
  PRICE_GROWTH: 'PRICE GROWTH',
  BLANK: 'BLANK',
}

const MIN_SIZE = {
  [`${MAP_KEY.PRICE_STATISTIC}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
  },
  [`${MAP_KEY.PRICE_GROWTH}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.BLANK}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.PRICE_STATISTIC}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.PRICE_STATISTIC}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRICE_STATISTIC}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_GROWTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.BLANK,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRICE_GROWTH}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.PRICE_GROWTH}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRICE_GROWTH}`].minHeight,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.BLANK,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.BLANK}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.BLANK}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.BLANK}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelPriceStatistics
          panelRefs={panelRefs}
          panelKey={MAP_KEY.PRICE_STATISTIC}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelPriceGrowth
          panelRefs={panelRefs}
          panelKey={MAP_KEY.PRICE_GROWTH}
          sizes={sizes}
          setSizes={setSizes}
        />

        <Panel
          panelRefs={panelRefs}
          panelKey={MAP_KEY.BLANK}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
