import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class DailyReportProxy extends ProxyBase {
  postTemplates(params) {
    return this.post('Templates', params)
  }

  putTemplates(params) {
    return this.put('Templates', params)
  }

  getTemplates(params) {
    return this.get('Templates', params)
  }

  deleteTemplates(params) {
    return this.delete('Templates', params)
  }

  getTemplatesByDate(params) {
    return this.get('Templates/ByDate', params)
  }

  getNews(params) {
    return this.get('News', params, undefined, true)
  }

  downloadNews(params) {
    return this.post('DownloadNews', params, {}, 'download')
  }
}

export default new DailyReportProxy(
  ServiceProxyConfig.NewsReport.DailyReport.ServiceUrl,
)
