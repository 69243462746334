import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import PieChartComponent from '../../../common/PieChartComponent'
import Title from '../../../common/Title'
import { selectQuarter, selectYear } from '../../store/slice'
import { getLoanSectorKeyByValue } from '../detailMetrics/helper'
import {
  selectDataMarketShareByLoanSector,
  selectLoadingMarketShareByLoanSector,
  selectLoanSector,
} from '../store/slice'
import { getBankLoanMarketShareByLoanSector } from '../store/thunk'

const LoanMarketShareByLoanSector = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataMarketShareByLoanSector)
  const loading = useSelector(selectLoadingMarketShareByLoanSector)
  const loanSector = useSelector(selectLoanSector)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)

  const sectorName = UseI18n(
    `corporate.businessModel.penetrationAnalysis.loanSector.${getLoanSectorKeyByValue(
      loanSector,
    )}`,
  )

  useEffect(() => {
    if (basicInfo.organizationId && year && quarter) {
      dispatch(
        getBankLoanMarketShareByLoanSector({
          OrganizationId: basicInfo.organizationId,
          Field: loanSector,
          Year: year,
          Quarter: quarter,
        }),
      )
    }
  }, [loanSector, year, quarter, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title text="MARKET_SHARE_BY_LOAN_SECTOR" suffix={sectorName} />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <PieChartComponent
                    data={dataChart}
                    height={height - size.height}
                    datakey="msls"
                    currentTicker={basicInfo?.ticker}
                    width={width}
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default LoanMarketShareByLoanSector
