export const valueTimefrequency = {
  ALL: '',
  ALLLV2: 'ALLLV2',
}

export const Timefrequency = [
  {
    name: 'bond.overview.outStandingBons.fiter.method.all',
    value: valueTimefrequency.ALL,
  },
  {
    name: 'bond.overview.outStandingBons.fiter.method.allLv2',
    value: valueTimefrequency.ALLLV2,
  },
]
