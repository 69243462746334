import { createAsyncThunk } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import UserSettingService from '../../core/services/UserSettingService'
import MasterService from '../../core/services/common/MasterService'
import PackageService from '../../core/services/common/PackageService'
import EventEmitter, {
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  MODAL_STYLE_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../utils/EventEmitter'

export const getGeneralInfo = createAsyncThunk(
  'setting/GET_GENERAL_INFO',
  async (data, { rejectWithValue }) => {
    const response = await UserSettingService.getGeneralInfo(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getProfile = createAsyncThunk(
  'setting/GET_PROFILE',
  async (data, { rejectWithValue }) => {
    const response = await UserSettingService.getProfile(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getTimeZone = createAsyncThunk(
  'setting/GET_TIME_ZONE',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getTimeZone(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const updateProfile = createAsyncThunk(
  'setting/UPDATE_PROFILE_PROFILE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserSettingService.updateProfile(data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const updateFafouriteContact = createAsyncThunk(
  'setting/UPDATE_FAFOURITE_CONTACT',
  async (data, { rejectWithValue }) => {
    try {
      const response = await UserSettingService.updateFafouriteContact(data)
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)

export const getRequestRenewUpgradeAccount = createAsyncThunk(
  'setting/GET_REQUEST_RENEW_UPGRADE_ACCOUNT',
  async (data, { rejectWithValue }) => {
    try {
      const response = await PackageService.getRequestRenewUpgradeAccount(data)
      if (response.success) {
        EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
        EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
        EventEmitter.dispatch(MODAL_STYLE_POPUP_ERROR, {
          width: 500,
        })
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <div className="mb-20 mt-16">
            <div>
              <Translate value="setting.settingProfile.MESS_REQUEST_RENEWALS_UPGRADE_ACCOUNT" />
            </div>
          </div>,
        ])
      }
      return response.data
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  },
)
