import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../../configs/Layout'
import { withWrapper } from '../../../../../common/withWrapper'
import { PanelCasaRatio } from './casaRatio/PanelCasaRatio'
import { PanelDataTable } from './dataTable/PanelDataTable'
import { PanelDepositStructure } from './depositStructure/PanelDepositStructure'
import { PanelLdrRatio } from './ldrRatio/PanelLdrRatio'
import { PanelLoanStructure } from './loanStructure/PanelLoanStructure'
import { PanelTierOneCapital } from './tierOneCapital/PanelTierOneCapital'

const MAP_KEY = {
  DATA_TABLE: 'DATA_TABLE',
  LOAN_STRUCTURE: 'LOAN_STRUCTURE',
  DEPOSIT_STRUCTURE: 'DEPOSIT_STRUCTURE',
  LDR_RATIO: 'LDR_RATIO',
  CASA_RATIO: 'CASA_RATIO',
  TIER_ONE_CAPITAL: 'TIER_ONE_CAPITAL',
}

const MAP_SIZE = {
  [`${MAP_KEY.DATA_TABLE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.LOAN_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.LOAN_STRUCTURE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.DEPOSIT_STRUCTURE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.LDR_RATIO,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.CASA_RATIO,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TIER_ONE_CAPITAL,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.LOAN_STRUCTURE}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.DEPOSIT_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DEPOSIT_STRUCTURE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.LDR_RATIO}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.CASA_RATIO,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CASA_RATIO}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.TIER_ONE_CAPITAL,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TIER_ONE_CAPITAL}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const CapitalStructureContent = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelDataTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelLoanStructure
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelDepositStructure
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelLdrRatio
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelCasaRatio
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelTierOneCapital
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

CapitalStructureContent.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(CapitalStructureContent)
