import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getStackKey, handleRangeYear, handleStackedChartData } from '../helper'
import { getGDPStructureBySpendingMethod, getMaxPeriod } from './thunk'

const initialState = {
  year: new Date().getFullYear() - 1,
  stackedChartLoading: true,
  stackedChartData: [],
  stackKey: [],
  yearOption: [],
}

const slice = createSlice({
  name: 'economy/gdp/gdpBySpendingMethod/panelGDPChangeIn',
  initialState,
  reducers: {
    handleYear: (state, action) => {
      state.year = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPStructureBySpendingMethod.pending, (state) => {
      state.stackedChartLoading = true
    })
    builder.addCase(
      getGDPStructureBySpendingMethod.fulfilled,
      (state, action) => {
        state.stackedChartLoading = false
        state.stackedChartData = handleStackedChartData(action.payload || [])
        state.stackKey = getStackKey(action.payload || [])
      },
    )
    builder.addCase(
      getGDPStructureBySpendingMethod.rejected,
      (state, action) => {
        state.stackedChartLoading = action.payload.isLoading
      },
    )
    builder.addCase(getMaxPeriod.fulfilled, (state, action) => {
      state.year = action.payload?.maxYear
      state.yearOption = handleRangeYear(action.payload || {})
    })
  },
})

export const { handleYear } = slice.actions

export const getStackedBarChartLoading = (state) =>
  state[slice.name].stackedChartLoading
export const getStackedBarChartData = (state) =>
  state[slice.name].stackedChartData
export const getYear = (state) => state[slice.name].year
export const selectStackKey = (state) => state[slice.name].stackKey
export const getYearOption = (state) => state[slice.name].yearOption

register(slice.name, slice.reducer)
