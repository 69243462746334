import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { uuid } from '../../../../utils/Common'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
  getDateTimeFrom,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  DATA_TYPE,
  EXPORT_TYPE,
  GROUP_BY,
  TIME_FRAME_TRADING_DATA,
  VIEW_BY,
} from '../constant'
import {
  stickyColumnsConstants,
  stickyColumnsKey,
  stickyColumnsMappingDisplay,
  widthStickyColumn,
} from './constants'
import styles from './style.module.css'

export const getTimeRange = (time) => {
  switch (time) {
    case TIME_FRAME_TRADING_DATA.THREE_MONTH:
      return {
        fromDate: getDateTimeFrom(new Date(), -3, 'months', FORMAT.DATE),
        toDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME_FRAME_TRADING_DATA.SIX_MONTH:
      return {
        fromDate: getDateTimeFrom(new Date(), -6, 'months', FORMAT.DATE),
        toDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME_FRAME_TRADING_DATA.NINE_MONTH:
      return {
        fromDate: getDateTimeFrom(new Date(), -9, 'months', FORMAT.DATE),
        toDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME_FRAME_TRADING_DATA.ONE_YEAR:
      return {
        fromDate: getDateTimeFrom(new Date(), -1, 'years', FORMAT.DATE),
        toDate: getCurrentDateTime(FORMAT.DATE),
      }
    default:
      return {
        fromDate: getCurrentDateTime(FORMAT.DATE),
        toDate: getCurrentDateTime(FORMAT.DATE),
      }
  }
}

const getThColumnLeft = (index, viewBy) => {
  const thPadding = 8
  if (index === 0) return 0
  return [...new Array(index)]
    .map(
      (_, index) =>
        widthStickyColumn[stickyColumnsConstants[viewBy][index]] +
        thPadding * 2,
    )
    .reduce((total, num) => total + num, 0)
}

const getColorVal = (val) => {
  return Number(val) && val < 0 ? '#ff4752' : undefined
}

const getCellRender = ({ val, el, indicator, locale, timezone }) => {
  if (typeof val === 'undefined') return ''

  if (el) {
    if (stickyColumnsKey[el] === stickyColumnsKey.DATE) {
      return formatDateTime(val, FORMAT.DATE, locale, timezone)
    }

    if (stickyColumnsKey[el] === stickyColumnsKey.COMPANY_NAME) {
      return (
        <div>
          <TextEllipsis isI18n={false} text={val} />
        </div>
      )
    }
  }

  if (indicator) {
    if (indicator.typeOf === 'decimal') {
      return (
        <Span style={{ color: getColorVal(val) }}>
          {typeof val === 'undefined'
            ? ''
            : indicator.unit && indicator.unit === '%'
            ? valToPercent(val, true)
            : indicator.unit && indicator.unit === 'VND'
            ? formatVal(val, 0)
            : formatVal(val)}
        </Span>
      )
    }

    if (indicator.typeOf === 'int') {
      return (
        <Span style={{ color: getColorVal(val) }}>{formatVal(val, 0)}</Span>
      )
    }
  }

  return val
}

export const getTableHeaderGroupByDate = (
  viewBy,
  indicators = [],
  locale,
  timezone,
) => {
  const attrs = stickyColumnsConstants[viewBy].map((el, index) => ({
    id: stickyColumnsKey[el],
    fieldId: stickyColumnsKey[el],
    title: stickyColumnsMappingDisplay[el],
    disableSort: true,
    isSticky: true,
    appendStyleTh: {
      textAlign: 'left',
      minWidth: widthStickyColumn[el],
      maxWidth: widthStickyColumn[el],
      left: getThColumnLeft(index, viewBy),
    },
    appendStyle: {
      textAlign: 'left',
      minWidth: widthStickyColumn[el],
      maxWidth: widthStickyColumn[el],
      left: getThColumnLeft(index, viewBy),
    },
    render: (idTicker, id, val) => getCellRender({ val, el, locale, timezone }),
  }))

  for (const [index, indicator] of indicators.entries()) {
    if (index < 10) {
      attrs.push({
        id: String(indicator.indicatorId),
        fieldId: indicator.indicatorId,
        unit: indicator.unit,
        typeOf: indicator.typeOf || 'int',
        indicatorGroup: indicator.indicatorGroup,
        thStyle: {
          minWidth: widthStickyColumn.OTHER,
          textAlign: 'right',
        },
        title: indicator.indicatorName,
        render: (idTicker, id, val) =>
          getCellRender({ val, indicator, locale, timezone }),
      })
    }
  }

  return attrs
}

export const getTableHeaderGroupByCompany = (
  indicators = [],
  tickers = [],
  locale,
  timezone,
) => {
  const twoTickers = tickers.filter((_, index) => index < 2)
  const tenIndicators = indicators.filter((_, index) => index < 10)
  const rowOneAttrs = stickyColumnsConstants[GROUP_BY.COMPANY].map(
    (el, index) => ({
      id: stickyColumnsKey[el],
      fieldId: stickyColumnsKey[el],
      title: stickyColumnsMappingDisplay[el],
      colSpan: 1,
      rowSpan: 2,
      disableSort: true,
      isSticky: true,
      appendStyleTh: {
        textAlign: 'left',
        minWidth: widthStickyColumn[el],
        maxWidth: widthStickyColumn[el],
        left: getThColumnLeft(index, GROUP_BY.COMPANY),
      },
      appendStyle: {
        textAlign: 'left',
        minWidth: widthStickyColumn[el],
        maxWidth: widthStickyColumn[el],
        left: getThColumnLeft(index, GROUP_BY.COMPANY),
      },
      render: (idTicker, id, val) =>
        getCellRender({ val, el, locale, timezone }),
    }),
  )
  const rowTwoAttrs = []

  if (!twoTickers.length || !tenIndicators.length) {
    return [rowOneAttrs, rowTwoAttrs]
  }

  // Temporary fix, will remove after find a solution to fix header
  twoTickers.forEach((item) => {
    tenIndicators.forEach((indicator) => {
      rowOneAttrs.push({
        title: item.tickerOrTaxCode || item.groupName || item.icbName,
        id: `${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }_${indicator.indicatorId}`,
        colSpan: 1,
        rowSpan: 1,
        isNoData: true,
        thStyle: {
          textAlign: 'right',
        },
      })

      rowTwoAttrs.push({
        title: indicator.indicatorName,
        id: `${indicator.indicatorId}_${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }`,
        parentKey: `${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }_${indicator.indicatorId}`,
        fieldId: indicator.indicatorId,
        unit: indicator.unit,
        typeOf: indicator.typeOf || 'int',
        indicatorGroup: indicator.indicatorGroup,
        colSpan: 1,
        rowSpan: 1,
        thStyle: {
          textAlign: 'right',
          minWidth: widthStickyColumn.OTHER,
        },
        render: (idTicker, id, val) =>
          getCellRender({ val, indicator, locale, timezone }),
      })
    })
  })

  // Will using this format after find solution to fix header
  // twoTickers.forEach((item) => {
  //   rowOneAttrs.push({
  //     title: item.tickerOrTaxCode || item.groupName || item.icbName,
  //     id:
  //       item.tickerOrTaxCode ||
  //       item.groupName ||
  //       `${item.icbName}_${item.icbLevel}`,
  //     colSpan: tenIndicators.length,
  //     rowSpan: 1,
  //     isNoData: true,
  //     thStyle: {
  //       textAlign: 'center',
  //     },
  //   })

  //   tenIndicators.forEach((indicator) => {
  //     rowTwoAttrs.push({
  //       title: indicator.indicatorName,
  //       id: `${indicator.indicatorId}_${
  //         item.tickerOrTaxCode ||
  //         item.groupName ||
  //         `${item.icbName}_${item.icbLevel}`
  //       }`,
  //       parentKey:
  //         item.tickerOrTaxCode ||
  //         item.groupName ||
  //         `${item.icbName}_${item.icbLevel}`,
  //       fieldId: indicator.indicatorId,
  //       unit: indicator.unit,
  //       typeOf: indicator.typeOf || 'int',
  //       indicatorGroup: indicator.indicatorGroup,
  //       colSpan: 1,
  //       rowSpan: 1,
  //       thStyle: {
  //         textAlign: 'right',
  //         minWidth: widthStickyColumn.OTHER,
  //       },
  //       render: (idTicker, id, val) =>
  //         getCellRender({ val, indicator, locale, timezone }),
  //     })
  //   })
  // })

  return [rowOneAttrs, rowTwoAttrs]
}

export const getTableHeaderGroupByIndicator = (
  indicators = [],
  tickers = [],
  locale,
  timezone,
) => {
  const twoTickers = tickers.filter((_, index) => index < 2)
  const tenIndicators = indicators.filter((_, index) => index < 10)
  const rowOneAttrs = stickyColumnsConstants[GROUP_BY.COMPANY].map(
    (el, index) => ({
      id: stickyColumnsKey[el],
      fieldId: stickyColumnsKey[el],
      title: stickyColumnsMappingDisplay[el],
      colSpan: 1,
      rowSpan: 2,
      disableSort: true,
      isSticky: true,
      appendStyleTh: {
        textAlign: 'left',
        minWidth: widthStickyColumn[el],
        maxWidth: widthStickyColumn[el],
        left: getThColumnLeft(index, GROUP_BY.COMPANY),
      },
      appendStyle: {
        textAlign: 'left',
        minWidth: widthStickyColumn[el],
        maxWidth: widthStickyColumn[el],
        left: getThColumnLeft(index, GROUP_BY.COMPANY),
      },
      render: (idTicker, id, val) =>
        getCellRender({ val, el, locale, timezone }),
    }),
  )
  const rowTwoAttrs = []

  if (!twoTickers.length || !tenIndicators.length) {
    return [rowOneAttrs, rowTwoAttrs]
  }

  tenIndicators.forEach((indicator) => {
    twoTickers.forEach((item) => {
      rowTwoAttrs.push({
        title: item.tickerOrTaxCode || item.groupName || item.icbName,
        id: `${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }_${indicator.indicatorId}`,
        parentKey: `${indicator.indicatorId}_${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }`,
        colSpan: 1,
        rowSpan: 1,
        isNoData: true,
        thStyle: {
          textAlign: 'right',
        },
        render: (idTicker, id, val) =>
          getCellRender({ val, indicator, locale, timezone }),
      })

      rowOneAttrs.push({
        title: indicator.indicatorName,
        id: `${indicator.indicatorId}_${
          item.tickerOrTaxCode ||
          item.groupName ||
          `${item.icbName}_${item.icbLevel}`
        }`,
        fieldId: indicator.indicatorId,
        unit: indicator.unit,
        typeOf: indicator.typeOf || 'int',
        indicatorGroup: indicator.indicatorGroup,
        colSpan: 1,
        rowSpan: 1,
        thStyle: {
          textAlign: 'right',
          minWidth: widthStickyColumn.OTHER,
        },
      })
    })
  })

  return [rowOneAttrs, rowTwoAttrs]
}

export const getDataGroupByDate = (data, schema, viewBy) => {
  return [...data]
    .sort(
      (a, b) =>
        moment(b[stickyColumnsKey.DATE]).unix() -
        moment(a[stickyColumnsKey.DATE]).unix(),
    )
    .map((item, index) => {
      const obj = { index: index + 1 }
      schema.forEach((col) => {
        if (
          viewBy === VIEW_BY.INDEX_AND_SECTOR &&
          item.dataType === DATA_TYPE.SECTOR
        ) {
          obj[col.fieldId] = item[col.fieldId]
            ? item[col.fieldId] +
              (col.fieldId === stickyColumnsKey.NAME && item.level
                ? ` L${item.level}`
                : '')
            : item.items[col.fieldId]
        } else {
          obj[col.fieldId] = item[col.fieldId] || item.items[col.fieldId]
        }
      })

      return obj
    })
}

export const getDataGroupByCompanyOrIndicator = (data, schema, viewBy) => {
  const newData = []
  ;[...data]
    .sort(
      (a, b) =>
        moment(b[stickyColumnsKey.DATE]).unix() -
        moment(a[stickyColumnsKey.DATE]).unix(),
    )
    .forEach((item) => {
      if (item[stickyColumnsKey.DATE]) {
        const findIndex = newData.findIndex(
          (el) => el[stickyColumnsKey.DATE] === item[stickyColumnsKey.DATE],
        )

        if (findIndex === -1) {
          const obj = {}
          schema.forEach((col) => {
            if (col.isSticky) {
              obj[col.id] = item[col.fieldId]
            }
          })
          Object.keys(item.items).forEach((key) => {
            if (
              viewBy === VIEW_BY.INDEX_AND_SECTOR &&
              item.dataType === DATA_TYPE.SECTOR
            ) {
              obj[
                `${key}_${item.ticker || item.name}${
                  item.level ? ` L${item.level}_${item.level}` : ''
                }`
              ] = item.items[key]
            } else {
              obj[`${key}_${item.ticker || item.name}`] = item.items[key]
            }
          })
          newData.push(obj)
        } else {
          Object.keys(item.items).forEach((key) => {
            if (
              viewBy === VIEW_BY.INDEX_AND_SECTOR &&
              item.dataType === DATA_TYPE.SECTOR
            ) {
              newData[findIndex][
                `${key}_${item.ticker || item.name}${
                  item.level ? ` L${item.level}_${item.level}` : ''
                }`
              ] = item.items[key]
            } else {
              newData[findIndex][`${key}_${item.ticker || item.name}`] =
                item.items[key]
            }
          })
        }
      }
    })
  return newData.map((item, index) => ({ ...item, index: index + 1 }))
}

export const getParamsQuery = (
  tickers,
  selectedIndicators,
  filter,
  viewBy,
  timeZone,
  mostRecentDate,
) => {
  const dataIds = tickers.map((item) => ({
    id: item.organizationId || item.groupId || item.icbId,
    dataType:
      viewBy === VIEW_BY.CORPORATE
        ? 'Corporate'
        : item.groupId
        ? 'Index'
        : 'Sector',
  }))
  const indicators = selectedIndicators.map((item, index) => ({
    order: index + 1,
    indicatorId: item.indicatorId,
    alias: item.indicatorId,
    indicatorType: '',
    condition: {},
  }))
  const timeRange = getTimeRange(filter.time)
  const fromDate =
    filter.time === TIME_FRAME_TRADING_DATA.YTB
      ? filter.dateFrom || mostRecentDate.minDate
      : timeRange.fromDate
  const toDate =
    filter.time === TIME_FRAME_TRADING_DATA.YTB
      ? filter.dateTo || mostRecentDate.maxDate
      : timeRange.toDate
  const conditionGroup = {
    groupId: uuid(),
    order: 1,
    conditionType: viewBy === VIEW_BY.CORPORATE ? 'DE04_SC01' : 'DE04_SC02',
    condition: {
      timeRange: filter.time,
      fromDate: getISOStartOrEndOfDay(fromDate, timeZone, true),
      toDate: getISOStartOrEndOfDay(toDate, timeZone, false),
    },
    indicators,
  }

  return {
    dataIds,
    conditionGroups: [conditionGroup],
  }
}

export const getHeaderExportExcel = (tickersCheck, indicatorsCheck, filter) => {
  if (
    [GROUP_BY.COMPANY, GROUP_BY.INDICATOR].includes(filter.groupBy) &&
    filter.exportType === EXPORT_TYPE.A_FILE
  ) {
    const headers = []

    if (filter.groupBy === GROUP_BY.COMPANY) {
      tickersCheck.forEach((ticker) =>
        indicatorsCheck.forEach((indicator, index) => {
          headers.push({
            order: index + 1,
            alias: `${
              ticker.organizationId ||
              (ticker.groupId && ticker.groupId + '_INDEX') ||
              (ticker.icbId && ticker.icbId + '_SECTOR')
            }_${indicator.indicatorId}`,
            value: `${indicator.indicatorName}\n${I18n.t(
              'tool.dataExplorer.tradingData.UNIT',
            )}${indicator.unit}`,
          })
        }),
      )
    } else {
      indicatorsCheck.forEach((indicator, indicatorIndex) =>
        tickersCheck.forEach((ticker, tickerIndex) => {
          headers.push({
            order: indicatorIndex * tickersCheck.length + tickerIndex + 1,
            alias: `${
              ticker.organizationId ||
              (ticker.groupId && ticker.groupId + '_INDEX') ||
              (ticker.icbId && ticker.icbId + '_SECTOR')
            }_${indicator.indicatorId}`,
            value: `${indicator.indicatorName}\n${I18n.t(
              'tool.dataExplorer.tradingData.UNIT',
            )}${indicator.unit}`,
          })
        }),
      )
    }

    return headers
  } else {
    return indicatorsCheck.map((item, index) => ({
      order: index + 1,
      alias: item.indicatorId,
      value: `${item.indicatorName}\n${I18n.t(
        'tool.dataExplorer.tradingData.UNIT',
      )}${item.unit}`,
    }))
  }
}

export const getRowSpanSchema = (
  tableHeaderColAttr,
  isGroupByDate,
  groupBy,
) => {
  if (
    !isGroupByDate &&
    tableHeaderColAttr.length &&
    tableHeaderColAttr[0].length
  ) {
    return tableHeaderColAttr.map((row, rowIndex) => {
      return row
        .filter((item) => !Object.values(stickyColumnsKey).includes(item.id))
        .map((col) => {
          const result = {
            title: col.title,
            colId: col.id,
            colSpan: col.colSpan,
            rowSpan: col.rowSpan,
            isI18n: false,
            isThCustomData:
              (rowIndex === 0 && groupBy === GROUP_BY.COMPANY) ||
              (rowIndex === 1 && groupBy === GROUP_BY.INDICATOR),
            thStyle: {
              ...col.thStyle,
              fontSize: 10,
              fontWeight: 'bold',
            },
            thClassName: styles.customTableStickyHead,
          }

          if (col.colSpan) {
            result.disableSort = true
          }

          return result
        })
    })
  }
}

export const getTableHeadRowTop = (tableHeaderColAttr, isGroupByDate) => {
  if (
    !isGroupByDate &&
    tableHeaderColAttr.length &&
    tableHeaderColAttr[0].length
  ) {
    const obj = {}
    tableHeaderColAttr[1].forEach((attr) => (obj[attr.id] = [attr.parentKey]))
    return obj
  }
}
