import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'
import {
  checkAllItem,
  getCheckedAll,
  getListChecked,
  handleCheckedAll,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const isCheckedAll = useSelector(getCheckedAll)
  const listChecked = useSelector(getListChecked)

  const handleCheckAll = () => {
    dispatch(handleCheckedAll(true))
    dispatch(checkAllItem(isCheckedAll))
  }

  const handleUncheckAll = () => {
    dispatch(handleCheckedAll(false))
    dispatch(checkAllItem(isCheckedAll))
  }

  useEffect(() => {
    const currentChecked = Object.values(listChecked)

    if (currentChecked.every((item) => item)) {
      dispatch(handleCheckedAll(true))
    } else {
      dispatch(handleCheckedAll(false))
    }
  }, [listChecked])

  return (
    <div className={style.wrapHeader}>
      <div
        className="d-flex align-center"
        style={{ justifyContent: 'space-between' }}
      >
        <Span className={style.yellowColor}>
          <Translate value={'newsReport.dailyReport.dailyReport.MARKET_NEWS'} />
        </Span>
        {isCheckedAll ? (
          <i
            className={`icon-minus-stroke ${style.checkboxAll}`}
            onClick={handleUncheckAll}
          />
        ) : (
          <Span onClick={handleCheckAll}>
            <input
              type="checkbox"
              className={`checkbox line ${style.btnUncheck}`}
              readOnly
            />
          </Span>
        )}
      </div>
      <div className="d-flex justify-content-end">
        <div className={style.smallLine} />
      </div>
    </div>
  )
}

export default Header
