import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import useHeightTdTable from '../../../../common/hooks/useHeightTdTable'
import { TABLE_HEADER_HORIZONTAL_CHOOSE } from '../configs'
import { handleCurrentStackKey } from '../panelTable/store/slice'
import style from '../panelTable/toiTable/toiTable.module.css'
import {
  handleChartName,
  handleChartType,
  handleChooseCol,
  handleFieldName,
} from '../store/slice'
import { PERCENT_FORMAT } from './TableData'

const Col = ({
  children,
  colSpan,
  styleProps,
  isActive,
  className,
  isClick,
  onClick,
  isChoose,
  id,
  isChooseTHHorizontal,
  chart1Type,
  chart2Type,
  fieldName,
  fieldName_1,
  indicator,
  relativeIndicator,
  isHoverOutline,
  alias,
  valueFormat,
}) => {
  const dispatch = useDispatch()
  const heightTd = useHeightTdTable()
  const isPercentFormat = valueFormat === PERCENT_FORMAT

  const handleClick = () => {
    if (id) {
      if (isChooseTHHorizontal) {
        const itemInConfig = TABLE_HEADER_HORIZONTAL_CHOOSE.find(
          (item) => item.indicator === id || item.indicator_vi === id,
        )
        if (itemInConfig && chart1Type && chart2Type) {
          dispatch(handleChooseCol(id))
          dispatch(
            handleChartName({
              fieldName,
              value: indicator,
              relativeValue: relativeIndicator,
              isPercentFormat,
            }),
          )
          dispatch(
            handleFieldName({ fieldName: itemInConfig.fieldName, fieldName_1 }),
          )
          dispatch(handleChartType({ chart1Type, chart2Type }))
          dispatch(handleCurrentStackKey(fieldName))
          onClick()
        } else {
          onClick()
        }
      } else {
        if (chart1Type && chart2Type) {
          dispatch(
            handleChartName({
              fieldName,
              value: indicator,
              relativeValue: relativeIndicator,
              isPercentFormat,
            }),
          )
          dispatch(handleChartType({ chart1Type, chart2Type }))
          dispatch(handleFieldName({ fieldName, fieldName_1 }))
          dispatch(handleChooseCol(id))
          onClick()
        } else {
          onClick()
        }
      }
    }
  }

  return (
    <td
      id={id}
      style={{ ...styleProps, height: heightTd }}
      className={`${style.col} ${isActive && style.tableDataActive}
      ${!isClick && style.notClick}
      ${isChoose && style.choose}
      ${className}
      ${isHoverOutline && alias && style.colOutline}`}
      colSpan={colSpan}
      onClick={handleClick}
    >
      {children}
    </td>
  )
}

Col.propTypes = {
  children: PropTypes.node,
  colSpan: PropTypes.number,
  styleProps: PropTypes.object,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  isClick: PropTypes.bool,
  onClick: PropTypes.func,
  isChoose: PropTypes.bool,
  valueFormat: PropTypes.string,
}

Col.defaultProps = {
  isClick: true,
  onClick: () => {},
  chart1Type: '',
  chart2Type: '',
  fieldName_1: '',
  isHoverOutline: true,
  valueFormat: '',
}

export default Col
