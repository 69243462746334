import WatchListProxy from '../../../proxies/bond/bondSecurityProxy/watchLisstProxy'
import { ServiceBase } from '../../ServiceBase'

export class WatchList extends ServiceBase {
  getDataWatchLisst() {
    return this.getData(() => WatchListProxy.getDataProxy())
  }
  updateMultiWatchlistDetailsSv(params) {
    return this.getData(() =>
      WatchListProxy.updateMultiWatchlistDetailsProxy(params),
    )
  }
}

export default new WatchList()
