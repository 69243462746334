import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { ITEM_TYPE } from '../../constant'
import { getValuationTableData } from './thunk'

const initialState = {
  loading: true,
  valueById: {},
  reCalcWidths: false,
  rowIds: [],
  initRowIds: [],
  levels: [],
}

const slice = createSlice({
  name: 'sector/sectorConstituents/valuation/valuationTable',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sortByLevel: (state, action) => {
      state.rowIds = getIdsFromProps(
        state.rowIds,
        state.valueById,
        action.payload,
        state.initRowIds,
        0,
        state.levels,
      )
    },
    setRowIds: (state, action) => {
      state.rowIds = state.initRowIds = action.payload
    },
    handleLevels: (state, action) => {
      state.levels = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getValuationTableData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValuationTableData.fulfilled, (state, action) => {
      const data = {}
      action.payload.forEach((item) => {
        if (item.icbId && !item.organizationId) {
          data[`${ITEM_TYPE.SECTOR}-${item.icbId}`] = item
        }
        if (item.organizationId) {
          data[`${ITEM_TYPE.TICKER}-${item.organizationId}`] = item
        }
      })
      state.valueById = data
      state.loading = false
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getValuationTableData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectIsHasData = (state) =>
  !!Object.keys(state[slice.name].valueById).length
export const selectValueById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueById[id], attr)
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const getRowIds = (state) => state[slice.name].rowIds

export const { resetStore, sortByLevel, setRowIds, handleLevels } =
  slice.actions

register(slice.name, slice.reducer)
