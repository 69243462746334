import { range } from 'd3-array'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../../common/hooks/useBasicInfo.js'
import { usePackagePermission } from '../../../../../common/hooks/usePackagePermission.js'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable.js'
import SelectCustom from '../../../../../common/selectCustom/index.js'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime.js'
import {
  MIN_DATE_FILTER,
  MONTHS,
  QUARTER,
  TIME_FREQUENCY,
} from '../constants.js'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectActiveTimeType,
  selectFilterIssuanceValueByCouponType,
} from '../store/slice.js'
import { getIssuanceValueByCouponData } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)
  const { maxMinTableById } = useGetMaxMinTable()
  const commonTopIssuer = maxMinTableById['FGFB_COAN_DR_BondIssue']

  // Use selector
  const methodType = useSelector(selectActiveMethodType)
  const timeType = useSelector(selectActiveTimeType)
  const { month, quarter, year } = useSelector(
    selectFilterIssuanceValueByCouponType,
  )
  const locale = useSelector((state) => state.i18n.locale)

  // Get data
  const getYears = useMemo(() => {
    if (isTrial && timeType === TIME_FREQUENCY.MONTHLY) {
      return [commonTopIssuer?.max_YearReport]
    } else {
      return range(
        MIN_DATE_FILTER,
        getCurrentDateTime(FORMAT.YEAR) * 1 + 1,
      ).reverse()
    }
  }, [commonTopIssuer?.max_YearReport, timeType, isTrial])

  const getMonths = useMemo(() => {
    if (isTrial) {
      return MONTHS.filter(
        (item) => item.value === commonTopIssuer?.max_MonthReport,
      )
    } else {
      return MONTHS
    }
  }, [commonTopIssuer?.max_MonthReport, isTrial])

  // Actions
  const handleChangeMonth = (value) => {
    dispatch(
      changeFilter({
        label: keys.ISSUANCE_VALUE_BY_COUPON,
        key: 'month',
        value,
      }),
    )
  }

  const handleChangeQuarter = (value) => {
    dispatch(
      changeFilter({
        label: keys.ISSUANCE_VALUE_BY_COUPON,
        key: 'quarter',
        value,
      }),
    )
  }

  const handleChangeYear = (value) => {
    dispatch(
      changeFilter({
        label: keys.ISSUANCE_VALUE_BY_COUPON,
        key: 'year',
        value,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload = {
      MethodType: methodType,
      TimeRange: timeType,
      Year: year,
    }

    if (timeType === TIME_FREQUENCY.MONTHLY) {
      payload.Month = month
    }

    if (timeType === TIME_FREQUENCY.QUARTER) {
      payload.Quarter = quarter
    }

    dispatch(getIssuanceValueByCouponData(payload))
  }, [locale, month, quarter, year, methodType, timeType])

  useEffect(() => {
    if (timeType === TIME_FREQUENCY.MONTHLY) {
      dispatch(
        changeFilter({
          label: keys.ISSUANCE_VALUE_BY_COUPON,
          key: 'month',
          value: commonTopIssuer?.max_MonthReport || 1,
        }),
      )
      dispatch(
        changeFilter({
          label: keys.ISSUANCE_VALUE_BY_COUPON,
          key: 'year',
          value:
            commonTopIssuer?.max_YearOfMonthReport || new Date().getFullYear(),
        }),
      )
    }
    if (timeType === TIME_FREQUENCY.QUARTER) {
      dispatch(
        changeFilter({
          label: keys.ISSUANCE_VALUE_BY_COUPON,
          key: 'quarter',
          value: commonTopIssuer?.max_LengthReport || 1,
        }),
      )
      dispatch(
        changeFilter({
          label: keys.ISSUANCE_VALUE_BY_COUPON,
          key: 'year',
          value:
            commonTopIssuer?.max_YearOfLengthReport || new Date().getFullYear(),
        }),
      )
    }
    if (![TIME_FREQUENCY.QUARTER, TIME_FREQUENCY.MONTHLY].includes(timeType)) {
      dispatch(
        changeFilter({
          label: keys.ISSUANCE_VALUE_BY_COUPON,
          key: 'year',
          value: commonTopIssuer?.max_YearReport || new Date().getFullYear(),
        }),
      )
    }
  }, [timeType])

  return (
    <div className="d-flex justify-content-end">
      {timeType === TIME_FREQUENCY.MONTHLY && (
        <div className="w-80 mr-8">
          <SelectCustom
            value={month}
            selectData={getMonths}
            handleChange={handleChangeMonth}
            isI18n
          />
        </div>
      )}
      {timeType === TIME_FREQUENCY.QUARTER && (
        <div className="w-80 mr-8">
          <SelectCustom
            value={quarter}
            selectData={QUARTER}
            handleChange={handleChangeQuarter}
            isI18n
          />
        </div>
      )}
      <div className="w-80">
        <SelectCustom
          value={year}
          selectData={getYears.map((item) => ({
            name: item,
            value: item,
          }))}
          handleChange={handleChangeYear}
        />
      </div>
    </div>
  )
}

export default Filter
