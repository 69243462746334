import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PeerValuationService from '../../../../../../../core/services/corporate/PeerValuationService'
import WatchlistService from '../../../../../../../core/services/watchlist/WatchlistService'

export const getOrgIdsByIcb = createAsyncThunk(
  'corporate/peerValuation/overview/GET_ORG_BY_ICB',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PeerValuationService.getOrgIdsByIcb(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getWatchList = createAsyncThunk(
  'corporate/peerValuation/overview/GET_WL',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlist(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTickerByWLId = createAsyncThunk(
  'corporate/peerValuation/overview/GET_TICKER_BY_WL',
  async ({ WatchListId }, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlistDetail({
        WatchListId,
      })
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
