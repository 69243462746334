import { memo, useEffect, useState } from 'react'
import { SizeTracker } from '../../../sizeTracker'
import { Chart } from '../Chart'
import { Footer } from '../Footer'

export const ChartDefault = memo(
  ({
    data,
    width,
    height,
    colors,
    schema,
    chartProps,
    footerProps,
    tooltipProps,
  }) => {
    const [footerCheckbox, setFooterCheckbox] = useState([])

    const handleChangeFooterCheckbox = (values) => {
      setFooterCheckbox(values)
      footerProps.setListCheckbox && footerProps.setListCheckbox(values)
    }

    useEffect(() => {
      if (footerProps.listCheckbox) {
        setFooterCheckbox(footerProps.listCheckbox)
      }
    }, [JSON.stringify(footerProps.listCheckbox)])

    return (
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <Chart
                data={data}
                width={width}
                height={height - size.height}
                colors={colors}
                schema={schema}
                chartProps={chartProps}
                tooltipProps={tooltipProps}
              />
            )}
            {!footerProps.isHide ? (
              <Footer
                colors={colors}
                schema={schema}
                footerProps={{
                  ...footerProps,
                  listCheckbox: footerProps.isCheckbox
                    ? footerCheckbox
                    : undefined,
                  setListCheckbox: footerProps.isCheckbox
                    ? handleChangeFooterCheckbox
                    : undefined,
                }}
              />
            ) : (
              <div style={{ height: 1 }} />
            )}
          </>
        )}
      </SizeTracker>
    )
  },
)
