import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getMonthAndYearInDataRange,
  getValueChartGrowthYear,
} from '../../table/helpers'
import { MONTHS_EN_LIST } from '../constants'
import {
  getDataConsumptionGrowthYear,
  getMaxDate,
  getSelectionList,
} from './thunk'

const initialState = {
  data: [],
  leftKey: [],
  loading: true,
  accValue: true,
  isLoadingList: true,
  isLoadingMaxYear: true,
  selectionList: [],
  dataTypeChart: null,
  isFirstTime: true,
  locale: 'vi',
  maxYear: null,
  currentDataTypeId: 0,
}

export const slice = createSlice({
  name: 'economy/productionAndConsumption/consumption/growthYear',
  initialState,
  reducers: {
    changeAccValue: (state, action) => {
      state.accValue = action.payload
    },
    changeCurrentDataTypeId: (state, action) => {
      state.currentDataTypeId = action.payload
    },
    changeIsFirstTimeRenderChart: (state, action) => {
      state.isFirstTime = action.payload
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataConsumptionGrowthYear.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataConsumptionGrowthYear.fulfilled, (state, action) => {
      const data = getValueChartGrowthYear(action.payload || [])
      state.data = data?.map((item) => ({
        ...item,
        label:
          state.locale === 'vi'
            ? item.label
            : MONTHS_EN_LIST.find((month) => month.value === item.label).title,
      }))
      const leftKey = new Set(
        getMonthAndYearInDataRange(action.payload || [])?.map(
          (item) => item.year,
        ),
      )
      state.leftKey = [...leftKey].map((item) => item.toString())
      state.loading = false
    })
    builder.addCase(getDataConsumptionGrowthYear.rejected, (state, action) => {
      state.loading = action.payload.loading
    })

    builder.addCase(getSelectionList.pending, (state) => {
      state.isLoadingList = true
    })
    builder.addCase(getSelectionList.fulfilled, (state, action) => {
      const selectionList =
        action.payload?.map((item) => ({
          value: item.retailVNTypeId,
          name: item.retailVNTypeName,
        })) || []
      state.selectionList = selectionList
      state.currentDataTypeId = state.isFirstTime ? 0 : state.currentDataTypeId
      state.isLoadingList = false
    })
    builder.addCase(getSelectionList.rejected, (state, action) => {
      state.isLoadingList = action.payload.loading
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.isLoadingMaxYear = true
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.maxYear = action.payload?.realYear
      state.isLoadingMaxYear = false
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.isLoadingMaxYear = false
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading
export const selectLeftKey = (state) => state[slice.name].leftKey
export const selectAccValue = (state) => state[slice.name].accValue
export const selectCurrentDataTypeId = (state) =>
  state[slice.name].currentDataTypeId
export const selectListOptions = (state) => state[slice.name].selectionList
export const selectMaxYear = (state) => state[slice.name].maxYear

export const {
  changeAccValue,
  changeIsFirstTimeRenderChart,
  changeLocale,
  changeCurrentDataTypeId,
} = slice.actions

register(slice.name, slice.reducer)
