const CrossHairCursorY = (props) => {
  const {
    width,
    data,
    activeCoordinate,
    isTooltipActive,
    offset: { left, right },
  } = props
  if (!isTooltipActive || data.length === 0) return <></>

  return (
    <>
      <g
        stroke="#555555"
        strokeDasharray="3 3"
        strokeWidth={1}
        pointerEvents="none"
      >
        <line
          x1={left}
          y1={activeCoordinate.y}
          x2={width - right}
          y2={activeCoordinate.y}
        />
      </g>
    </>
  )
}

export default CrossHairCursorY
