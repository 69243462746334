import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorValuationService from '../../../../../../core/services/sector/sectorConstituents/SectorValuationService'

export const getReportData = createAsyncThunk(
  'sector/sectorConstituents/valuation/report/getReportData',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SectorValuationService.getValuationReport(params)
      if (response.success) return response
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
