export const chartFillColor = {
  rp: '#3cabeb',
  rpyg: '#ecffdf',
}

export const chartBarKeys = ['rp']

export const chartLineKeys = ['rpyg']

export const chartMappingDisplayName = {
  rp: 'sector.financialAnalysis.security.performance.REVENUE_PROPRIETARY',
  rpyg: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
}

export const chartFormat = {
  rp: 10 ** 9,
  rpyg: 0.01,
}
