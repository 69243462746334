export const chartFillColor = {
  gb: '#1f669a',
  cib: '#3cabeb',
  cb: '#f1ffe7',
  bpvg: '#f7b80c',
}

export const chartBarKeys = ['gb', 'cib', 'cb']

export const chartLineKeys = ['bpvg']

export const chartMappingDisplayName = {
  gb: 'sector.financialAnalysis.bank.assetQuality.GOVERNMENT_BOND',
  cib: 'sector.financialAnalysis.bank.assetQuality.CREDIT_INSTITUTION_BOND',
  cb: 'sector.financialAnalysis.bank.assetQuality.CORPORATE_BOND',
  bpvg: 'sector.financialAnalysis.bank.assetQuality.BOND_PORTFOLIOS_GROWTH',
}

export const chartFormat = {
  gb: 10 ** 9,
  cib: 10 ** 9,
  cb: 10 ** 9,
  bpvg: 0.01,
}
