import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { valToPercent } from '../../../../utils/Value'
import { EMPTY_ROW_ID } from './GDPGrowthForecast'
import { handleColor } from './helper'
import style from './index.module.css'
import { getMinMaxValueByColId } from './store/slice'

const OtherColumnCell = ({
  val,
  props,
  rowId,
  isLastCell,
  isLastRow,
  colId,
}) => {
  const minMaxValue = useSelector(getMinMaxValueByColId(colId))

  const getStyle = (val, minMaxValue) => {
    const style = { position: 'relative', fontWeight: 600, height: '100%' }

    if (typeof val === 'number' && minMaxValue.length && !isLastRow) {
      const { bg, color } = handleColor(val, minMaxValue)
      return {
        ...style,
        backgroundColor: bg,
        color: color,
      }
    }

    return style
  }

  return (
    <td
      {...props}
      className={`${style.borderDefault} ${isLastRow && style.lastRow}`}
      style={{
        borderRightColor: rowId === EMPTY_ROW_ID && !isLastCell && '#1e242e',
        textAlign: 'right',
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div style={getStyle(val, minMaxValue)} className={style.customTd}>
        {rowId !== EMPTY_ROW_ID && (
          <Span style={{ paddingRight: 8 }}>{valToPercent(val)}</Span>
        )}
        {isLastRow && <div className={style.borderTop} />}
      </div>
    </td>
  )
}

OtherColumnCell.propTypes = {
  props: PropTypes.object,
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLastRow: PropTypes.bool,
  colId: PropTypes.string,
}

export default OtherColumnCell
