import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { equationType } from './constants'
import { checkFormulaCharacterValid, checkFormulaValid } from './helpers'
import {
  changeEquationFormula,
  selectEquationFormula,
  selectEquationIndicators,
  selectEquationType,
} from './store/slice'
import style from './style.module.css'

const InputFormula = ({ setIsFormulaValid }) => {
  const dispatch = useDispatch()

  const equationTypeState = useSelector(selectEquationType)
  const equationFormula = useSelector(selectEquationFormula)
  const equationIndicators = useSelector(selectEquationIndicators)

  const [isFirstTime, setIsFirstTime] = useState(true)

  const getUsedAlias = useMemo(() => {
    const alias = []
    Object.values(equationIndicators).forEach((indicator) => {
      Object.values(indicator.data).forEach((item) => {
        item.alias && alias.push(item.alias)
      })
    })

    return alias
  }, [JSON.stringify(equationIndicators)])

  // Actions
  const handleChange = (e) => {
    const value = e.target.value.trim().toUpperCase()
    if (checkFormulaCharacterValid(value)) {
      dispatch(changeEquationFormula(value))
    }
  }

  const handleUpdateEquationFormulaByIndicators = () => {
    let formula = getUsedAlias.join('+')

    if (equationTypeState === equationType.AVERAGE && getUsedAlias.length) {
      formula = `(${formula})/${getUsedAlias.length}`
    }

    dispatch(changeEquationFormula(formula))
  }

  // Use effect
  useEffect(() => {
    setIsFormulaValid(checkFormulaValid(equationFormula, getUsedAlias))
  }, [equationFormula])

  useEffect(() => {
    if (!equationFormula && equationTypeState !== equationType.COUNT) {
      handleUpdateEquationFormulaByIndicators()
    }
  }, [JSON.stringify(equationIndicators)])

  useEffect(() => {
    if (
      equationFormula &&
      equationTypeState !== equationType.COUNT &&
      !isFirstTime
    ) {
      handleUpdateEquationFormulaByIndicators()
    }
    setIsFirstTime(false)
  }, [getUsedAlias.length])

  return (
    <>
      <div className="align-center" style={{ marginTop: 12 }}>
        <Span
          className={style.textBold}
          style={{ whiteSpace: 'nowrap', marginRight: 8, width: 150 }}
        >
          <Translate value="financialChart.EQUATION_FORMULA" />
        </Span>
        <Input
          value={equationFormula}
          className={style.inputIndicatorName}
          onChange={handleChange}
        />
      </div>
      <div className={`mt-8 ${style.textNormalItalic}`}>
        <Span style={{ fontSize: 10 }}>
          <TextEllipsis text="financialChart.EQUATION_FORMULA_SUPPORT" />
        </Span>
        <Span style={{ fontSize: 10 }}>
          <TextEllipsis text="financialChart.EQUATION_FORMULA_NOTE" />
        </Span>
      </div>
    </>
  )
}

export default InputFormula
