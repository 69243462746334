import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { ID_DOM_PANEL_PRODUCTION } from '../chartFirst/constants'
import { getTab } from '../store/slice'
import { TAB_TYPE } from '../table/constants'
import IndexBuyChart from './purchaseIndex'
import Contribution from './topGrowthIndustry/contribution'

export const PanelChartSecond = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const onTab = useSelector(getTab)

  const getTitle = useMemo(() => {
    return `economy.productionAndConsumption.production.CHART_SECOND.${onTab}`
  }, [onTab])

  return (
    <Panel
      title={getTitle}
      panelRefs={panelRefs}
      panelKey={mapKey.CHART_SECOND}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(getTitle)}
      downloadJpgParams={{
        domId: ID_DOM_PANEL_PRODUCTION.SECOND_CHART,
        nameScreen: UseI18n(
          'economy.productionAndConsumption.production.PRODUCTION',
        ),
        chartName: UseI18n(getTitle),
      }}
    >
      {onTab === TAB_TYPE.GENERAL_INDEX ? (
        <IndexBuyChart
          width={formatNumber(sizes[mapKey.CHART_SECOND].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[mapKey.CHART_SECOND].height) -
            HEADER_PANEL_HEIGHT
          }
        />
      ) : (
        <Contribution
          width={formatNumber(sizes[mapKey.CHART_SECOND].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[mapKey.CHART_SECOND].height) -
            HEADER_PANEL_HEIGHT
          }
          activeTab={onTab}
        />
      )}
    </Panel>
  )
}

PanelChartSecond.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
