import { useDispatch, useSelector } from 'react-redux'
import iconTable from '../../icons/iconTable.svg'
import iconTornadoChartChoosed from '../../icons/iconTornadoChartChoosed.svg'
import { TABLE_TYPE } from '../constant'
import { selectTableType } from '../store/slice'
import { changeShowChart, selectIsShowChart } from './store/slice'

const ToggleChart = () => {
  const dispatch = useDispatch()
  const isShowChart = useSelector(selectIsShowChart)
  const tableType = useSelector(selectTableType)

  const isTreeTable = [
    TABLE_TYPE.BALANCE_SHEET,
    TABLE_TYPE.INCOME_STATEMENT,
  ].includes(tableType)

  const handleClick = () => {
    dispatch(changeShowChart(!isShowChart))
  }

  if (!isTreeTable) {
    return (
      <div
        style={{ marginLeft: 16 }}
        className="cursor-pointer"
        onClick={handleClick}
      >
        <img
          src={isShowChart ? iconTable : iconTornadoChartChoosed}
          alt="iconTornadoChart"
          height={20}
        />
      </div>
    )
  }

  return <></>
}

export default ToggleChart
