import PortfolioProxy from '../../../proxies/bond/portfolio/PortfolioProxy'
import { ServiceBase } from '../../ServiceBase'

export class PortfolioService extends ServiceBase {
  getPortfolio() {
    return this.getData(() => PortfolioProxy.getPortfolio())
  }
  getPortfolioInvestment(params) {
    return this.getData(() => PortfolioProxy.getPortfolioInvestment(params))
  }
  postPortfolioInvestment(params) {
    return this.getData(() => PortfolioProxy.postPortfolioInvestment(params))
  }
  getExpectedCashflowData(params) {
    return this.getData(() => PortfolioProxy.getExpectedCashflowData(params))
  }
  downloadExpectedCashflowData(params) {
    return this.getFileDownload(() =>
      PortfolioProxy.downloadExpectedCashflowData(params),
    )
  }
  addPortfolio(params) {
    return this.getData(() => PortfolioProxy.addPortfolio(params))
  }
  updatePortfolio(params) {
    return this.getData(() => PortfolioProxy.updatePortfolio(params))
  }
  updatePortfolioDetails(params) {
    return this.getData(() => PortfolioProxy.updatePortfolioDetails(params))
  }
  deletePortfolio(params) {
    return this.getData(() => PortfolioProxy.deletePortfolio(params))
  }
  downloadPortfolioInvestment(params) {
    return this.getFileDownload(() =>
      PortfolioProxy.downloadPortfolioInvestment(params),
    )
  }
}

export default new PortfolioService()
