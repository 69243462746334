import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import { StackedBarLineChart } from '../../../common/chart/customCharts/StackedBarLineChart'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../common/chart/helper'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal } from '../../../utils/Value'
import {
  COLOR_STRUCTURE_CHART,
  NOT_QUARTER_CONST,
  YELLOW_COLOR,
} from '../common/constants'
import style from '../index.module.css'
import { DEFAULT_ALL_ORGAN_ID, DEFAULT_ALL_VALUE } from './constants'
import { isTaxCode, removeSecuritiesFromStr } from './helper'
import { changeListCheckbox, selectListCheckbox } from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, right: 80 }

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

export const ChartComponent = ({
  width,
  height,
  data,
  idsBarkey,
  currentOrganizationId,
  listOrganizations,
  currentQuarter,
}) => {
  const dispatch = useDispatch()

  const listCheckbox = useSelector(selectListCheckbox)

  const getOrganizationInfoById = (id) => {
    return listOrganizations?.find((item) => item.value === Number(id)) || {}
  }

  const fillColor = {}
  const mappingDisplayName = {}

  const reOrderIds = [...idsBarkey]
  reOrderIds?.push(reOrderIds?.shift())

  const getStackedDisplayName = (id) => {
    return getOrganizationInfoById(id)
      ? Number(id) === DEFAULT_ALL_ORGAN_ID
        ? I18n.t('market.marginLoans.OTHER')
        : isTaxCode(getOrganizationInfoById(id)?.ticker)
        ? removeSecuritiesFromStr(getOrganizationInfoById(id)?.name)
        : getOrganizationInfoById(id)?.ticker
      : ''
  }

  const getDisplayName = (currentOrganizationId) => {
    const ticker = getOrganizationInfoById(currentOrganizationId)?.ticker || ''
    const name =
      removeSecuritiesFromStr(
        getOrganizationInfoById(currentOrganizationId)?.name,
      ) || ''

    return ticker && !isTaxCode(ticker) ? ticker : name
  }

  for (const id of reOrderIds) {
    fillColor[id] = COLOR_STRUCTURE_CHART[reOrderIds.indexOf(id)]
    mappingDisplayName[id] = getStackedDisplayName(id)
  }

  const stackedBarTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listCheckbox?.map((id) => (
              <tr className={style.trTable}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {getStackedDisplayName(id)}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span style={{ fontSize: 11 }}>
                    {valueTooltip[id]
                      ? `${formatVal(valueTooltip[id])} ${I18n.t(
                          'market.marginLoans.BILLION',
                        )}`
                      : EMPTY_VALUE}
                  </Span>
                </td>
              </tr>
            ))}
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {I18n.t('market.marginLoans.TOTAL')}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {`${formatVal(
                    listCheckbox
                      ?.map((id) => valueTooltip[id])
                      .reduce((sum, a) => sum + a, 0),
                  )} ${I18n.t('market.marginLoans.BILLION')}`}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const chartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {getDisplayName(currentOrganizationId)}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {valueTooltip[currentOrganizationId]
                    ? `${formatVal(
                        valueTooltip[currentOrganizationId],
                      )} ${I18n.t('market.marginLoans.BILLION')}`
                    : EMPTY_VALUE}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  const setListCheckbox = (val) => {
    dispatch(changeListCheckbox(val))
  }

  useEffect(() => {
    setListCheckbox(reOrderIds)
  }, [])

  return currentOrganizationId !== DEFAULT_ALL_VALUE ? (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={'time'}
              margin={MARGIN}
              xTickNum={SETTINGS.xTickNum}
              hasBarChart
              yAxis={[
                {
                  id: 'left',
                  orientation: 'left',
                  keys: [currentOrganizationId],
                  isBarChart: true,
                  tickNum: SETTINGS.yTickNum,
                  label: I18n.t('market.marginLoans.BILLION_VND'),
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                },
              ]}
              renderCustomTooltip={(payload) => chartTooltip(payload)}
              isNotFormatXAxis={currentQuarter !== NOT_QUARTER_CONST}
              timeFrame={
                currentQuarter !== NOT_QUARTER_CONST && TIME_RANGES.CUSTOM
              }
            >
              <Bar
                yAxisId={'left'}
                dataKey={currentOrganizationId}
                barSize={getColumnSizeInBarChart(width, data.length)}
                isAnimationActive={false}
                fill={YELLOW_COLOR}
              />
            </ChartContainer>
          )}
          <Footer
            key={width}
            list={[
              {
                text: getDisplayName(currentOrganizationId),
                type: TYPE.SQUARE,
                before: {
                  bgColor: YELLOW_COLOR,
                },
              },
            ]}
          />
        </>
      )}
    </SizeTracker>
  ) : (
    <StackedBarLineChart
      data={data}
      width={width}
      height={height}
      keyXAxis={'time'}
      barKeys={reOrderIds}
      lineKeys={[]}
      decimalLength={2}
      leftLabel={I18n.t('market.marginLoans.BILLION_VND')}
      fillColor={fillColor}
      mappingDisplayName={mappingDisplayName}
      margin={MARGIN}
      timeFrame={TIME_RANGES.CUSTOM}
      isFooterResize
      isNotFormatXAxis
      unit={I18n.t('market.marginLoans.BILLION')}
      isFooterCheckbox
      listCheckbox={listCheckbox}
      setListCheckbox={setListCheckbox}
      renderCustomTooltip={(payload) => stackedBarTooltip(payload)}
    />
  )
}
