import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { VALUE_release_methot } from '../../constants'
import { latePaymentTypeValue } from '../constants'
import { getDataValueOfBonds } from './thunk'

const initialState = {
  typeMethod: VALUE_release_methot.ALL,
  isLoading: false,
  dataChart: [],
  activeTab: latePaymentTypeValue.ALL,
  ids: [],
}

const slice = createSlice({
  name: 'bond/overview/latePayment',
  initialState,
  reducers: {
    updateTab: (state, actions) => {
      state.activeTab = actions.payload
    },
    updateMethod: (state, actions) => {
      state.typeMethod = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataValueOfBonds.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataValueOfBonds.fulfilled, (state, action) => {
      state.dataChart = action.payload.data || []
      state.ids = action.payload.ids || []
      state.isLoading = false
    })
    builder.addCase(getDataValueOfBonds.rejected, (state, action) => {
      state.dataChart = []
      state.isLoading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectIdsChart = (state) => state[slice.name].ids
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectActiveMethod = (state) => state[slice.name].typeMethod

export const { updateTab, updateMethod } = slice.actions

register(slice.name, slice.reducer)
