import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { Table } from '../../../../common/table'
import { debounce } from '../../../../utils/Common'
import { formatVal } from '../../../../utils/Value'
import FirstColumnCell from './FirstColumnCell'
import { getDisabledRow, handleCollapseLevel, isPercentFormat } from './helper'
import style from './index.module.css'

import {
  getIsShowAlert,
  getLoadingBlur,
  getOpenMarketValue,
  handleCurrentPage,
  handleIsScrollRight,
  handleIsShowAlert,
  handleLoadingBlur,
  handlePreviousColumns,
} from './store/slice'

const TABLE_OPEN_MARKET = 'TABLE_OPEN_MARKET'
const FIRST_CELL_COLUMN_MIN_WIDTH = 257
const SCREEN_PADDING = 8
const HORIZONTAL_TRACK_LEFT = 28
const FAKE_LOADING = 500

const TableComponent = ({
  height,
  tableMapping,
  groupColumns,
  currentPage,
  pages,
  isScrollRight,
  previousColumns,
  timeRange,
}) => {
  const dispatch = useDispatch()

  const [rowCollapse, setRowCollapse] = useState([])
  const ids = tableMapping.map((item) => item.id)
  const [firstColumn, lastColumn] = pages[currentPage]
  const currentColumns = groupColumns.slice(firstColumn, lastColumn)
  const { prevFirstColumn, prevLastColumn } = previousColumns

  const loadingBlur = useSelector(getLoadingBlur)
  const isShowAlert = useSelector(getIsShowAlert)

  const scrollToLeftCallback = () => {
    if (currentPage > 1) {
      const firstColumn = currentColumns[0].date

      dispatch(handleIsScrollRight(false))
      dispatch(handleCurrentPage(currentPage - 1))
      dispatch(
        handlePreviousColumns({
          prevFirstColumn: firstColumn,
          prevLastColumn: undefined,
        }),
      )

      fakeLoading()
    }
  }

  const scrollToRightCallback = () => {
    const totalPage = Object.keys(pages).length
    if (currentPage < totalPage) {
      const lastColumn = currentColumns[currentColumns.length - 1].date

      dispatch(
        handlePreviousColumns({
          prevFirstColumn: undefined,
          prevLastColumn: lastColumn,
        }),
      )
      dispatch(handleIsScrollRight(false))
      dispatch(handleCurrentPage(currentPage + 1))

      fakeLoading()
    }
  }

  const fakeLoading = () => {
    dispatch(handleLoadingBlur(true))
    setTimeout(() => {
      dispatch(handleLoadingBlur(false))
    }, FAKE_LOADING)
  }

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowCollapse.indexOf(rowId)
      if (index === -1) {
        setRowCollapse((old) => [...old, rowId])
      }
    } else {
      setRowCollapse((old) => old.filter((item) => item !== rowId))
    }
  }

  useEffect(() => {
    const scrollTable = document.querySelector(
      `#${TABLE_OPEN_MARKET} .scrollbars div:first-child`,
    )

    if (prevFirstColumn) {
      const prevFirstColumnCell =
        document.getElementsByClassName(prevFirstColumn)[0]
      if (prevFirstColumnCell) {
        const { left } = prevFirstColumnCell.getBoundingClientRect()
        setTimeout(() => {
          scrollTable.scrollTo({
            left: left - FIRST_CELL_COLUMN_MIN_WIDTH - SCREEN_PADDING,
            behavior: 'smooth',
          })
        })
      }
    }

    if (prevLastColumn) {
      const prevLastColumnCell =
        document.getElementsByClassName(prevLastColumn)[0]
      if (prevLastColumnCell) {
        const { left } = prevLastColumnCell.getBoundingClientRect()
        setTimeout(() => {
          scrollTable.scrollTo({
            left: left - FIRST_CELL_COLUMN_MIN_WIDTH - SCREEN_PADDING,
            behavior: 'smooth',
          })
        })
      }
    }
  }, [previousColumns])

  return (
    <div style={{ height: height }} id={TABLE_OPEN_MARKET}>
      <Table
        ids={ids}
        columnDraggable={false}
        getDataFromRedux={getOpenMarketValue}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        rowDraggable={false}
        isLoading={loadingBlur}
        schema={[
          { date: 'emptyColumn', dateFormat: '' },
          ...currentColumns,
        ].map((item, index) => {
          const title =
            timeRange === 'Monthly' ? item.monthYear : item.dateFormat
          const colId = item.date
          const result = {
            colId,
            title,
          }
          if (index === 0) {
            return {
              ...result,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                minWidth: 240,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHorizontalHead = tableMapping.find(
                  (item) => item.id === rowId,
                )
                return (
                  <FirstColumnCell
                    props={props}
                    tableHorizontalHead={tableHorizontalHead}
                    rowId={rowId}
                    onRowCollapse={onRowCollapse}
                  />
                )
              },
            }
          }

          return {
            ...result,
            tdStyle: {
              textAlign: 'right',
              fontSize: 12,
            },
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
              fontWeight: 600,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              const tableHorizontalHead = tableMapping.find(
                (item) => item.id === rowId,
              )
              return (
                <td {...props} className={`${colId}`}>
                  <Span
                    className={`${
                      typeof val === 'number' && val < 0 && style.redColor
                    }`}
                  >
                    {tableHorizontalHead.level === 1 ? '' : formatVal(val)}
                    {typeof rowId === 'string' &&
                      typeof val === 'number' &&
                      isPercentFormat(rowId) &&
                      '%'}
                  </Span>
                </td>
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight={isScrollRight}
        isCollapse={true}
        levelCollapse={handleCollapseLevel(tableMapping)}
        rowsCollapse={rowCollapse}
        scrollToLeftCallback={scrollToLeftCallback}
        scrollToRightCallback={debounce(scrollToRightCallback)}
        disableRowHovered={getDisabledRow(tableMapping)}
      />
      <PopupAlert
        message={<Translate value={'economy.monetary.monetary.MAX_ITEM'} />}
        isShow={isShowAlert}
        handleClose={() => {
          dispatch(handleIsShowAlert(false))
        }}
      />
    </div>
  )
}

TableComponent.propTypes = {
  height: PropTypes.number,
  tableMapping: PropTypes.array.isRequired,
  groupColumns: PropTypes.array.isRequired,
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.object.isRequired,
  isScrollRight: PropTypes.bool.isRequired,
  previousColumns: PropTypes.object.isRequired,
  timeRange: PropTypes.string,
}

export default TableComponent
