import { useSelector } from 'react-redux'
import { ImportStatisticTable } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  selectCountLimit,
  selectIsValueOrGrowth,
  selectMaxMonth,
  selectMaxYear,
  selectTimeFrequencyType,
} from './store/slice'
import { getImportDownloadStatisticsByMarket } from './store/thunk'

export const PanelImportStatisticTable = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const timeFrequencyType = useSelector(selectTimeFrequencyType)
  const isValueOrGrowth = useSelector(selectIsValueOrGrowth)
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const countLimit = useSelector(selectCountLimit)

  return (
    <Panel
      title="sector.sectorSpecific.fishery.export.EXPORT_STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        getImportDownloadStatisticsByMarket({
          TimeFrequency: timeFrequencyType,
          ValueGrowthType: isValueOrGrowth,
          Month: maxMonth,
          Year: maxYear,
          Limit: countLimit,
        })
      }
    >
      <ImportStatisticTable
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
