import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundProfileProxy extends ProxyBase {
  getBasicInformation(params) {
    return this.get('GetBasicInformation', params)
  }

  getInvestmentFees(params) {
    return this.get('GetInvestmentFees', params)
  }

  getDistributors(params) {
    return this.get('GetDistributors', params)
  }

  getInvestmentStrategy(params) {
    return this.get('GetInvestmentStrategy', params)
  }

  getBoardOfFundManagers(params) {
    return this.get('GetBoardOfFundManagers', params)
  }

  downloadBasicInformation(params) {
    return this.get('DownloadBasicInformation', params, 'download')
  }

  downloadInvestmentFees(params) {
    return this.get('DownloadInvestmentFees', params, 'download')
  }

  downloadDistributors(params) {
    return this.get('DownloadDistributors', params, 'download')
  }

  downloadBoardOfFundManagers(params) {
    return this.get('DownloadBoardOfFundManagers', params, 'download')
  }
}

export default new FundProfileProxy(
  ServiceProxyConfig.Fund.FundProfile.FundProfile.ServiceUrl,
)
