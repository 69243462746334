import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { SizeTracker } from '../../../../common/sizeTracker'
import style from '../index.module.css'
import { keys, selectLoading } from '../store/slice'
import GroupButton from './GroupButton'
import ResultTable from './ResultTable'

const Result = ({ width, setIsLoadingDownloadCsv }) => {
  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))

  return (
    <SizeTracker>
      {(size) => (
        <div
          className={`h-100 ${loadingTable ? style.containerLoadingBlur : ''}`}
        >
          <div className={style.pb16px}>
            <GroupButton setIsLoadingDownloadCsv={setIsLoadingDownloadCsv} />
          </div>
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              <ResultTable width={width} />
            </div>
          )}
        </div>
      )}
    </SizeTracker>
  )
}

Result.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setIsLoadingDownloadCsv: PropTypes.func.isRequired,
}

export default Result
