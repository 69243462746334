import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../configs/Global'
import { BondRealtime } from '../../../common/BondRealtime'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import PopupAlert, { POPUP_ICON_STATUS } from '../../../common/popup/PopupAlert'
import { Table as TableContent } from '../../../common/table'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../bondSecurity/bondSecurity/Filter/store/slice'
import TableHeader from './TableHeader'
import { tableHeadColumns } from './constants'
import { getTableSchema } from './helper'
import {
  changeItemSelected,
  selectActiveTab,
  selectDataTableById,
  selectDataTradingStatistics,
  selectEnableInfinity,
  selectFilterPriceBoard,
  selectIsExchangePrice,
  selectKeySort,
  selectLoadingTable,
  sort,
  subscribeBondBidAskDealId,
  subscribeBondBidAskId,
  subscribeBondId,
} from './store/slice'
import {
  fetchMorePriceBoardData,
  getBondDetailData,
  updateBookmarkData,
} from './store/thunk'

const Table = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoadingTable)
  const { data, ids, markIds, tickers } = useSelector(
    selectDataTradingStatistics,
  )
  const enableInfinity = useSelector(selectEnableInfinity)
  const {
    duration,
    price,
    tradingStatusId,
    issueMethodId,
    bondIds,
    isCleanPrice,
    icbIds,
    page,
    interestFrom,
    interestTo,
    ...restFilter
  } = useSelector(selectFilterPriceBoard)
  const activeTab = useSelector(selectActiveTab)
  const {
    bidPrice,
    bidPrice2,
    bidPrice3,
    askPrice,
    askPrice2,
    askPrice3,
    lowPrice,
    highPrice,
    avgPrice,
    matchedPrice,
  } = useSelector(selectKeySort)
  const isExchangePrice = useSelector(selectIsExchangePrice)

  const [isCollapseBid, setIsCollapseBid] = useState(false)
  const [isCollapseAsk, setIsCollapseAsk] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const handleSaveToBookmark = (value) => {
    const newMarkIds = markIds.includes(value)
      ? markIds.filter((item) => item !== value)
      : [...markIds, value]

    const params = {
      bondIds: newMarkIds,
    }

    dispatch(updateBookmarkData(params))
  }

  const handleClickCollapseBid = () => {
    setIsCollapseBid(!isCollapseBid)
  }

  const handleClickCollapseAsk = () => {
    setIsCollapseAsk(!isCollapseAsk)
  }

  const tableHeader = useMemo(
    () =>
      tableHeadColumns({
        isCollapseBid,
        isCollapseAsk,
        handleClickCollapseBid,
        handleClickCollapseAsk,
        bidPrice,
        bidPrice2,
        bidPrice3,
        askPrice,
        askPrice2,
        askPrice3,
        lowPrice,
        highPrice,
        avgPrice,
        matchedPrice,
        type: activeTab,
      }),
    [
      isCollapseBid,
      isCollapseAsk,
      bidPrice,
      bidPrice2,
      bidPrice3,
      askPrice,
      askPrice2,
      askPrice3,
      lowPrice,
      highPrice,
      avgPrice,
      matchedPrice,
      activeTab,
    ],
  )

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  const scrollToBottomCallback = () => {
    if (!enableInfinity) return

    const payload = {
      ...restFilter,
      bondIds,
      duration,
      price,
      tradingStatusId,
      issueMethodId,
      icbIds,
      isCleanPrice,
      interestFrom: !isNaN(interestFrom) ? parseFloat(interestFrom) : null,
      interestTo: !isNaN(interestTo) ? parseFloat(interestTo) : null,
      page: page + 1,
      pageSize: 100,
      type: activeTab,
    }

    dispatch(fetchMorePriceBoardData(payload))
  }

  const handleClickPrice = ({ id, price, ticker, parValue }) => {
    const payload = {
      BondIds: id,
      isUserBond: false,
    }

    dispatch(getBondDetailData(payload))
    dispatch(changeItemSelected({ price, ticker, parValue, id } ?? null))
  }

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <>
      <TableContent
        ids={ids}
        columnDraggable={false}
        sort={sort}
        getDataFromRedux={selectDataTableById}
        rowDraggable={false}
        isLoading={isLoading}
        scrollToBottomCallback={() => scrollToBottomCallback(enableInfinity)}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(tableHeader, stateSort, sortColumn, activeTab)
        }
        schema={getTableSchema({
          data,
          tableHeader,
          isExchangePrice,
          setIsShow,
          redirectToSecurityMaster,
          redirectToBondIssuer,
          handleSaveToBookmark,
          handleClickPrice,
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        isCollapse={false}
        hasTooltip={false}
      />
      <PopupAlert
        message={I18n.t('common.FUNCTION_COMING_SOON')}
        isShow={isShow}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShow(false)}
      />
      {!!tickers.length && (
        <>
          {tickers.map((ticker) => (
            <BondRealtime
              channel={ChannelConfig.BidAskBondChannel + ticker}
              action={subscribeBondBidAskId}
              key={ticker}
            >
              <></>
            </BondRealtime>
          ))}
          {tickers.map((ticker) => (
            <BondRealtime
              channel={ChannelConfig.BondChannel + ticker}
              action={subscribeBondId}
              key={ticker}
            >
              <></>
            </BondRealtime>
          ))}
          {tickers.map((ticker) => (
            <BondRealtime
              channel={ChannelConfig.BidAskBondDealChannel + ticker}
              action={subscribeBondBidAskDealId}
              key={ticker}
            >
              <></>
            </BondRealtime>
          ))}
        </>
      )}
    </>
  )
}

export default Table
