import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ChannelConfig, groupHOHAUP } from '../../../../../../configs/Global'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { Realtime } from '../../../../../common/Realtime'
import { Table } from '../../../../../common/table'
import { valToPercent } from '../../../../../utils/Value'
import { keyTableSector, LIMIT_SELECT_SECTOR } from '../../constants'
import { changeDataChart } from '../chartPricePerformance/store/slice'
import style from '../style.module.css'
import CellHighLight from './CellHighLight'
import {
  addSectorToList,
  removeSectorToList,
  selectDataTable,
  selectListSector,
  selectLoadingTableSector,
  selectObjectSort,
  selectReCalcWidthsTable,
  selectSectorIds,
  selectSectorValue,
  sort,
  subscribeSector,
} from './store/slice'
import TdSelectSector from './TdSelectSector'
import ThSelectSector from './ThSelectSector'

const TableSector = () => {
  const dispatch = useDispatch()

  const sectorIds = useSelector(selectSectorIds)
  const isLoading = useSelector(selectLoadingTableSector)
  const listSector = useSelector(selectListSector)
  const reCalcWidths = useSelector(selectReCalcWidthsTable)
  const dataTableSector = useSelector(selectDataTable)
  const objectSort = useSelector(selectObjectSort)

  const [isShowWarning, setIsShowWarning] = useState(false)

  const getMissingNumber = () => {
    const listIndexColor = listSector
      .map((item) => item.colorIndex)
      .sort((a, b) => a - b)
    let result = listIndexColor.length
    for (let index = 0; index < listIndexColor.length; index++) {
      if (listIndexColor[index] !== index) {
        result = index
        break
      }
    }
    return result
  }

  const onRowClick = (rowId) => {
    if (
      listSector.length < LIMIT_SELECT_SECTOR ||
      listSector.some((item) => item.icbId === rowId)
    ) {
      const itemClick = dataTableSector.find((item) => item.icbId === rowId)
      const condition = !listSector.some((item) => item.icbId === rowId)
      if (condition) {
        const sector = {
          icbId: rowId,
          icbName: itemClick.icbName,
          colorIndex: getMissingNumber(),
        }
        dispatch(addSectorToList(sector))
      } else {
        dispatch(removeSectorToList(rowId))
        dispatch(changeDataChart(rowId))
      }
    }
  }

  useEffect(() => {
    if (listSector.length >= LIMIT_SELECT_SECTOR) {
      setIsShowWarning(true)
    }
  }, [listSector])

  useEffect(() => {
    if (dataTableSector.length) {
      dispatch(sort(objectSort))
    }
  }, [dataTableSector])

  return (
    <div className={`${style.tableSectorContainer} h-100`}>
      <Table
        ids={sectorIds}
        getDataFromRedux={selectSectorValue}
        isLoading={isLoading}
        columnDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        reCalcWidths={reCalcWidths}
        sort={sort}
        onRowClick={onRowClick}
        schema={Object.keys(keyTableSector).map((key, index) => {
          const title = `sector.sectorStatistics.overview.${key}`
          const colId = keyTableSector[key]
          let result = {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
            },
            tdStyle: {
              textAlign: 'right',
            },
          }
          if (index === 0) {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'left',
              },
              disableSort: true,
              canCustomTd: true,
              renderTh: () => <ThSelectSector />,
              render: (val, rowId) => {
                return <TdSelectSector val={val} rowId={rowId} colId={colId} />
              },
            }
          }
          if (index === 1) {
            result = {
              ...result,
              canCustomTd: true,
              render: (val, rowId, { style, className }) => {
                return (
                  <CellHighLight
                    value={val}
                    rowId={rowId}
                    keyColor={keyTableSector.PERCENT_INDEX_CHANGE}
                    selectCell={selectSectorValue}
                    style={style}
                    className={className}
                  />
                )
              },
            }
          }
          if (index === 2) {
            result = {
              ...result,
              render: (val) => {
                return valToPercent(val)
              },
            }
          }
          return result
        })}
      />
      {sectorIds.map((idSector) => (
        <Realtime
          channel={ChannelConfig.SectorChannel + idSector + '-' + groupHOHAUP}
          action={subscribeSector}
          key={idSector}
        >
          <></>
        </Realtime>
      ))}
      <PopupAlert
        message={
          <Translate value="sector.sectorStatistics.overview.MESS_WARNING_LIMIT_ADD_SECTOR" />
        }
        isShow={isShowWarning}
        handleClose={() => setIsShowWarning(false)}
        zIndexOverlay={12}
      />
    </div>
  )
}

export default TableSector
