import EventEmitter, {
  SHOW_POPUP_ERROR,
} from '../../components/utils/EventEmitter'
import { handleNameFileDownloadApi } from '../../components/utils/Export'
import { getLanguage } from '../../components/utils/Language'
import memoryCacheManager from '../cache/MemoryCacheManager'

export class ServiceBase {
  getData = (promiseAction, isShowGetError) => {
    return new Promise((resolve, reject) => {
      promiseAction()
        .then((res) => {
          if (!res || res.status === 204) {
            return resolve({})
          }
          return resolve(res.data)
        })
        .catch((err) => {
          if (
            (['post', 'delete'].includes(err?.response?.config?.method) &&
              err?.response?.data?.success === false) ||
            (isShowGetError &&
              (err?.response?.config?.method === 'get' ||
                err?.response?.config?.method === 'put'))
          ) {
            EventEmitter.dispatch(SHOW_POPUP_ERROR, err.response.data.errors)
          }

          return reject(err)
        })
    })
  }

  getFileDownload = (promiseAction) => {
    return new Promise((resolve, reject) => {
      promiseAction()
        .then((res) => {
          if (!res || res.status === 204) {
            return resolve({})
          }
          return resolve({
            data: res.data,
            filename: handleNameFileDownloadApi(
              res.headers['content-disposition'],
            ),
          })
        })
        .catch((err) => {
          if (err?.response?.data?.success === false) {
            EventEmitter.dispatch(SHOW_POPUP_ERROR, err.response.data.errors)
          }

          return reject(err)
        })
    })
  }

  applyMemoryCache =
    (
      key,
      params,
      absoluteExpireTime,
      slidingExpireTimeInMinute,
      removeCallback,
    ) =>
    (promiseAction) => {
      const cacheKey = this.getCacheKey(key, params)

      const cacheItem = memoryCacheManager.get(cacheKey)
      if (cacheItem) {
        return new Promise((resolve, reject) => resolve(cacheItem))
      }

      return promiseAction().then((response) => {
        if (!response || response.status === 204) {
          return {}
        }
        const data = response.data
        memoryCacheManager.add(
          cacheKey,
          data,
          absoluteExpireTime,
          slidingExpireTimeInMinute,
          removeCallback,
        )
        return data
      })
    }

  applySessionStorageCache =
    (
      key,
      params,
      absoluteExpireTime,
      slidingExpireTimeInMinute,
      removeCallback,
    ) =>
    (promiseAction) => {}

  applyLocalStorageCache =
    (
      key,
      params,
      absoluteExpireTime,
      slidingExpireTimeInMinute,
      removeCallback,
    ) =>
    (promiseAction) => {}

  getCacheKey(key, params) {
    return (
      key + JSON.stringify(this.formatParams(params)).replace(/[}{'.":]/g, '')
    )
  }

  formatParams = (params) => {
    return { lang: getLanguage(), ...params }
  }
}
