import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SHARE_OUTSTANDING } from '.'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TimeFrame from '../../common/TimeFrame'
import {
  OPEN_MARKET_DAILY_OPTIONS,
  OPEN_MARKET_MONTHLY_OUTSTANDING,
  OPEN_MARKET_WEEKLY_OPTIONS,
} from '../../constants'
import { getTimeRange as getTimeRangeOpenMarket } from '../panelOpenMarket/store/slice'
import ChartComponent from './ChartComponent'
import {
  getChartData,
  getChartLoading,
  getDailyValue,
  getKeyWithTitle,
  getMonthlyValue,
  getWeeklyValue,
  handleDailyValue,
  handleMonthlyValue,
  handleWeeklyValue,
} from './store/slice'
import { getOutstandingVolume } from './store/thunk'

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const chartData = useSelector(getChartData)
  const chartLoading = useSelector(getChartLoading)
  const dailyValue = useSelector(getDailyValue)
  const weeklyValue = useSelector(getWeeklyValue)
  const monthlyValue = useSelector(getMonthlyValue)
  const openMarketTimeRange = useSelector(getTimeRangeOpenMarket)
  const keyWithTitle = useSelector(getKeyWithTitle)
  const locale = useSelector((state) => state.i18n.locale)

  const { timeFrameOption, timeRange, onChangeTab } = useMemo(() => {
    switch (openMarketTimeRange) {
      case 'Weekly':
        return {
          timeFrameOption: OPEN_MARKET_WEEKLY_OPTIONS,
          timeRange: weeklyValue,
          onChangeTab: (tab) => {
            dispatch(handleWeeklyValue(tab.value))
          },
        }
      case 'Monthly':
        return {
          timeFrameOption: OPEN_MARKET_MONTHLY_OUTSTANDING,
          timeRange: monthlyValue,
          onChangeTab: (tab) => {
            dispatch(handleMonthlyValue(tab.value))
          },
        }
      default:
        return {
          timeFrameOption: OPEN_MARKET_DAILY_OPTIONS,
          timeRange: dailyValue,
          onChangeTab: (tab) => {
            dispatch(handleDailyValue(tab.value))
          },
        }
    }
  }, [openMarketTimeRange, dailyValue, weeklyValue, monthlyValue])

  useEffect(() => {
    dispatch(
      getOutstandingVolume({
        TimeFrequency: openMarketTimeRange,
        TimeRange: timeRange,
      }),
    )
  }, [openMarketTimeRange, dailyValue, weeklyValue, monthlyValue, locale])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <TimeFrame
              timeRange={timeRange}
              onChangeTab={onChangeTab}
              styleProps={{ marginBottom: 8 }}
              timeFrameOption={timeFrameOption}
            />
            {chartLoading && <Loading />}
            {!chartLoading && (
              <div
                style={{ height: height - sizes.height }}
                id={SHARE_OUTSTANDING}
              >
                {chartData.length === 0 && <NoData />}
                {chartData.length !== 0 && (
                  <>
                    {typeof sizes.height === 'number' && (
                      <ChartComponent
                        height={height - sizes.height}
                        width={width}
                        data={chartData}
                        keyXAxis={
                          openMarketTimeRange === 'Monthly'
                            ? 'monthYear'
                            : 'day'
                        }
                        barKey={['11', '14']}
                        lineKey={'0'}
                        keyWithTitle={keyWithTitle}
                        locale={locale}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
