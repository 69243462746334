export const chartFillColor = {
  rT0252: '#45b29d',
  oeG_A: '#facc5c',
}

export const chartBarKeys = ['rT0252']

export const chartLineKeys = ['oeG_A']

export const chartMappingDisplayName = {
  rT0252: 'sector.financialAnalysis.bank.profitability.CIR',
  oeG_A: 'sector.financialAnalysis.bank.profitability.OPERATING_EXPENSE_GROWTH',
}

export const chartFormat = {
  rT0252: 0.01,
  oeG_A: 0.01,
}
