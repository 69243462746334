import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DataExplorerService from '../../../../core/services/tool/dataExplorer/DataExplorerService'
import {
  timeoutInterval,
  typeWorkSpace,
} from '../../../common/dataExplorer/constant'
import {
  convertIndexSectorTemplate,
  getAllGroupIds,
} from '../../../common/dataExplorer/helper'
import { selectDataGeneralInfo } from '../../../setting/slice'
import { keyBy } from '../../../utils/Common'
import {
  changeSheetParamsWhenLoadTemplate,
  selectActiveSheet,
  selectColumnById,
  selectFullColumnId,
  selectGroupColumnById,
  selectIndexSectorById,
  selectIndexSectorId,
  selectIndicator,
  selectIsLoadWorkSpace,
  selectListSheet,
  selectSheet,
} from './store/slice'
import { getShareTemplateThunk, getWorkSpaceThunk } from './store/thunk'

let workSpace
let interval

const WorkSpace = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const sheets = useSelector(selectSheet)
  const listSheet = useSelector(selectListSheet)
  const userInfo = useSelector(selectDataGeneralInfo)
  const indicators = useSelector(selectIndicator)
  const activeSheet = useSelector(selectActiveSheet)
  const columnId = useSelector(selectFullColumnId(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const indexSectorId = useSelector(selectIndexSectorId(activeSheet))
  const indexSectorById = useSelector(selectIndexSectorById(activeSheet))
  const isLoadWorkSpace = useSelector(selectIsLoadWorkSpace)

  useEffect(() => {
    if (isLoadWorkSpace) {
      workSpace = {
        templateName: listSheet[0]?.title,
        dataType: typeWorkSpace.INDEX_SECTOR,
        rawParameter: Object.keys(sheets).map((id) => {
          const sheet = sheets[id]

          const newIndexSectorById = { ...sheet.indexSectorById }
          sheet.indexSectorId.forEach(
            (id) =>
              (newIndexSectorById[id] = convertIndexSectorTemplate(
                sheet.indexSectorById[id],
              )),
          )

          return {
            template: listSheet.find((sheet) => +sheet.value === +id),
            data: {
              indexSectorById: newIndexSectorById,
              indexSectorId: sheet.indexSectorId,
              columnId: sheet.columnId,
              columnById: sheet.columnById,
              groupColumnById: sheet.groupColumnById,
              newIndexSectorId: sheet.indexSectorId,
              newGroupId: getAllGroupIds(sheet.groupColumnById),
            },
          }
        }),
      }
    }
  }, [columnId, columnById, groupColumnById, indexSectorId])

  useEffect(() => {
    interval = setInterval(async () => {
      if (workSpace) {
        await DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }, timeoutInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (workSpace) {
        DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }
  }, [])

  useEffect(() => {
    const shareCode = searchParams.get('shareCode')

    if (userInfo.userId) {
      if (!shareCode) {
        dispatch(getWorkSpaceThunk({ UserID: userInfo.userId }))
      } else {
        dispatch(
          getShareTemplateThunk({ ShareCode: shareCode.replace(/ /g, '+') }),
        )
      }
    }
  }, [userInfo.userId])

  useEffect(() => {
    let newColumnId = columnId
    let newIndexSectorId = indexSectorId
    let newColumnById = columnById
    let isChange = false

    const firstColumnById = columnById && Object.values(columnById)[0]

    if (
      (!columnId?.length || !firstColumnById?.indicatorName) &&
      Object.keys(columnById).length
    ) {
      isChange = true
      const indicatorsById = keyBy(indicators, 'indicatorId')
      const columnId = []

      newColumnById = Object.values(columnById).reduce((obj, item) => {
        const indicator = indicatorsById[item.indicatorId]

        if (!columnId?.length && !newColumnId.includes(item.alias)) {
          newColumnId = [...newColumnId, item.alias]
        }

        if (indicator) {
          return {
            ...obj,
            [item.alias]: {
              ...item,
              ...indicator,
            },
          }
        }

        return { ...obj, [item.alias]: item }
      }, {})
    }

    if (!indexSectorId?.length && Object.keys(indexSectorById).length) {
      isChange = true
      newIndexSectorId = Object.keys(indexSectorById)
    }

    if (isChange) {
      dispatch(
        changeSheetParamsWhenLoadTemplate({
          activeSheet,
          indexSectorId: newIndexSectorId,
          columnById: newColumnById,
          columnId: newColumnId,
        }),
      )
    }
  }, [JSON.stringify(indexSectorById), JSON.stringify(columnById)])

  return <></>
}

export default WorkSpace
