import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../common/sizeTracker'
import { postCharts } from '../chart/store/thunk'
import ChartInPanel from '../common/chartInPanel'
import useFeatureChart from '../common/chartInPanel/useFeatureChart'
import withWrapperDataChart from '../common/chartInPanel/withWrapperDataChart'
import { getChartNameBySampleName } from '../common/helpers'
import { Panel } from '../common/panel'
import { selectChartLibrary, selectMyChartLibrary } from '../store/slice'
import { PanelFooter } from './PanelFooter'
import style from './index.module.css'

const MARGIN = 8
const defaultChartPanelHeight = 219
const BORDER = 1

const PanelChartLibrary = ({
  item,
  loading,
  isShowCheckbox = false,
  chartsChecked = [],
  setChartsChecked,
  index,
}) => {
  const dispatch = useDispatch()

  const myChartLibrary = useSelector(selectMyChartLibrary)
  const chartLibrary = useSelector(selectChartLibrary)

  const [isEdit, setIsEdit] = useState(false)

  const renameChart = () => {
    setIsEdit(true)
  }

  const {
    formatData,
    footerData,
    schema,
    loading: chartLoading,
  } = useFeatureChart(item, true)

  const isCheck = chartsChecked.find((i) => i.id === item.id)

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setChartsChecked([...chartsChecked, item])
    } else {
      setChartsChecked([...chartsChecked].filter((i) => item.id !== i.id))
    }
  }

  const duplicateChart = () => {
    const param = {
      dashboardId: myChartLibrary.id,
      linked: false,
      name: getChartNameBySampleName(
        chartLibrary.map((i) => i.name),
        item.name,
      ),
      detail: JSON.stringify({
        ...item.detail,
        nameChart: {
          ...item.detail?.nameChart,
          name: getChartNameBySampleName(
            chartLibrary.map((i) => i.name),
            item.name,
          ),
        },
      }),
    }
    dispatch(postCharts(param))
  }

  return (
    <Panel
      hasHeader={false}
      height={273}
      width={`calc(100%/4 - ${MARGIN * 2 + BORDER * 2}px)`}
      margin={MARGIN}
      appendStyle={{ padding: 0, paddingTop: 10 }}
    >
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div style={{ height: `calc( 100% - ${size.height}px)` }}>
                <div
                  className="d-flex position-relative h-100"
                  style={{ padding: '0px 10px' }}
                >
                  <label className={style.labelCheckbox}>
                    {isShowCheckbox ? (
                      <input
                        checked={isCheck}
                        type="checkbox"
                        className={`checkbox line cursor-pointer`}
                        onChange={handleChangeCheckbox}
                      />
                    ) : null}
                  </label>
                  <ChartInPanel
                    item={item}
                    data={formatData}
                    width="100%"
                    height={defaultChartPanelHeight}
                    schema={schema}
                    footerData={footerData}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    loading={loading || chartLoading}
                  />
                </div>
              </div>
            )}
            <PanelFooter
              index={index}
              item={item}
              duplicateChart={duplicateChart}
              renameChart={renameChart}
            />
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default withWrapperDataChart(PanelChartLibrary)
