import React from 'react'
import { useSelector } from 'react-redux'
import TotalRegisterFDI from '.'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import {
  selectFilterStatus,
  selectPayloadCallBack,
  selectTimeFrequency,
  selectViewBy,
} from './store/slice'
import { downloadDataTable } from './store/thunk'
import { VIEW_BY } from './constant'

const TotalRegisteredFDIPanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeFrequency = useSelector(selectTimeFrequency)
  const FDITypeId = useSelector(selectFilterStatus)
  const payloadCallBack = useSelector(selectPayloadCallBack)
  const viewBy = useSelector(selectViewBy)

  const downloadCsv = () => {
    const params = {
      FDITypeId: FDITypeId,
      TimeFrequency: timeFrequency,
      IsCapital: viewBy === VIEW_BY.CAPITAL,
    }

    if (payloadCallBack.month || payloadCallBack.prevMonth) {
      params.Month = payloadCallBack.month || payloadCallBack.prevMonth
    }

    if (payloadCallBack.year || payloadCallBack.prevYear) {
      params.Year = payloadCallBack.year || payloadCallBack.prevYear

      if (payloadCallBack.year > payloadCallBack.prevYear) {
        params.Month = payloadCallBack.prevMonth
        params.Year = payloadCallBack.prevYear
      }
    }

    return downloadDataTable(params)
  }

  return (
    <Panel
      title={'economy.fdi.fdiByCountries.FDI_BY_ORIGIN_COUNTRY'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <TotalRegisterFDI
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default TotalRegisteredFDIPanel
