import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { COMPONENT } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import PanelFilter from '../../common/panelFilter'
import { withWrapper } from '../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import Filter from './filter'
import { selectTemplateActive } from './filter/store/slice'
import Preview from './preview/Preview'
import ToolPreview from './preview/ToolPreview'
import style from './style.module.css'

const MAP_KEY = {
  FILTER: 'FILTER',
  PREVIEW_PDF: 'PREVIEW_PDF',
  TOOL_PREVIEW: 'TOOL_PREVIEW',
}

const HEIGHT_HEADER_WRAPPER = 28
export const HEIGHT_TOOL_PREVIEW = 40

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: `calc(610px - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 610 - 2 * COMPONENT.MARGIN,
    minHeight: 500,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.PREVIEW_PDF,
        ratioLeft: -1,
        ratioWidth: 1,
      },
      {
        key: MAP_KEY.TOOL_PREVIEW,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PREVIEW_PDF}`]: {
    width: `calc(100% - 610px)`,
    height: `100%`,
    top: 0,
    left: 610,
    minWidth: 700,
    minHeight: 500,
    minTop: 0,
    minLeft: 610,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOOL_PREVIEW}`]: {
    width: `calc(100% - 610px)`,
    minWidth: 700,
    height: HEIGHT_TOOL_PREVIEW,
    minHeight: HEIGHT_TOOL_PREVIEW,
    top: HEIGHT_HEADER_WRAPPER,
    minTop: HEIGHT_HEADER_WRAPPER,
    left: 610,
    minLeft: 610,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const templateSelect = useSelector(selectTemplateActive)
  const scrollbarsRef = useRef(null)

  return (
    <div className="w-100 h-100 position-relative">
      <PanelFilter
        title="corporate.companySnapshot.SETTING1"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
        windowBodyStyle={{ padding: 0 }}
      >
        <Filter height={sizes[MAP_KEY.FILTER].height - HEADER_PANEL_HEIGHT} />
      </PanelFilter>
      <Panel
        title={
          I18n.t('corporate.companySnapshot.PREVIEW') +
          ' ' +
          templateSelect?.templateName
        }
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PREVIEW_PDF}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Preview
          ref={scrollbarsRef}
          width={formatNumber(sizes[MAP_KEY.PREVIEW_PDF].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[MAP_KEY.PREVIEW_PDF].height) -
            HEADER_PANEL_HEIGHT
          }
        />
      </Panel>
      <Panel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOOL_PREVIEW}
        sizes={sizes}
        setSizes={setSizes}
        headerClass={style.panelHeader}
        windowClass={style.windowClass}
      >
        <ToolPreview scrollbarsRef={scrollbarsRef} />
      </Panel>
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
