import { Translate } from 'react-redux-i18n'

import DropdownExchange from './DropdownExchange'

const Exchange = ({
  exchanges,
  exchangesTree,
  listChecked,
  listParentChecked,
  loading,
}) => {
  return (
    <>
      <Translate
        value="market.event.EXCHANGE"
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />
      <div className="input-dropdown mb-8">
        <DropdownExchange
          exchanges={exchanges}
          exchangesTree={exchangesTree}
          listChecked={listChecked}
          listParentChecked={listParentChecked}
          loading={loading}
        />
      </div>
    </>
  )
}

export default Exchange
