import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupExportSheet from '../../../../common/dataExplorer/PopupExportSheet'
import PopupSaveTemplate from '../../../../common/dataExplorer/PopupSaveTemplate'
import {
  DEFAULT_PARAM_TEMPLATE,
  maxTemplate,
  typeData,
} from '../../../../common/dataExplorer/constant'
import {
  convertParamGetResult,
  getAllGroupIds,
  getColumnHeaders,
  getSheetName,
} from '../../../../common/dataExplorer/helper'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { convertDataId, getHeaderIndicator } from '../helper'
import style from '../index.module.css'
import { GET_RESULT_TEMPLATE_FIINX } from '../popupTemplate'
import {
  selectActionType,
  selectActiveSheet,
  selectAllMostRecent,
  selectColumnById,
  selectFullColumnId,
  selectGroupColumnById,
  selectIndicatorById,
  selectIsLoad,
  selectIsLoadFiinXTemplate,
  selectListSheet,
  selectNewGroupId,
  selectNewTickerId,
  selectShareIssueMethod,
  selectSheet,
  selectTemplates,
  selectTickerById,
  selectTickerId,
} from '../store/slice'

import { selectCustomParams } from '../../popupTemplate/store/slice'
import {
  exportExcelThunk,
  getResultThunk,
  saveTemplateThunk,
  updateTemplateThunk,
} from '../store/thunk'

const GroupButton = ({ setIsLoadingDownloadCsv }) => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const sheets = useSelector(selectSheet)
  const activeSheet = useSelector(selectActiveSheet)
  const tickerId = useSelector(selectTickerId(activeSheet))
  const newTickerId = useSelector(selectNewTickerId(activeSheet))
  const newGroupId = useSelector(selectNewGroupId(activeSheet))
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const locale = useSelector((state) => state.i18n.locale)
  const indicatorById = useSelector(selectIndicatorById)
  const columnId = useSelector(selectFullColumnId(activeSheet))
  const issueMethod = useSelector(selectShareIssueMethod)
  const actionType = useSelector(selectActionType)
  const templates = useSelector(selectTemplates)
  const tickerById = useSelector(selectTickerById(activeSheet))
  const listSheet = useSelector(selectListSheet)
  const isLoad = useSelector(selectIsLoad(activeSheet))
  const mostRecent = useSelector(selectAllMostRecent)
  const isLoadFiinXTemplate = useSelector(
    selectIsLoadFiinXTemplate(activeSheet),
  )

  const detailSheet = listSheet.find((sheet) => sheet.value === activeSheet)

  const customParams = useSelector(selectCustomParams)

  const [isShowPopupExport, setIsShowPopupExport] = useState(false)
  const [isShowPopupSaveAs, setIsShowPopupSaveAs] = useState(false)
  const [isCanExport, setIsCanExport] = useState(false)

  useEffect(() => {
    setIsCanExport(
      Object.keys(sheets).some(
        (valueSheet) => sheets[valueSheet]?.tickerId.length,
      ),
    )
  }, [columnId, tickerId])

  useEffect(() => {
    const param = convertParamGetResult(
      tickerId,
      getAllGroupIds(groupColumnById),
      groupColumnById,
      columnById,
      typeData.CORPORATE,
      convertDataId,
    )

    if (param) {
      dispatch(getResultThunk([param]))
    }
  }, [locale])

  useEffect(() => {
    if (isLoad) {
      if (newTickerId.length || newGroupId.length) {
        handleSubmitTable()
      }
    }
  }, [newTickerId, newGroupId])

  const handleSubmitTable = () => {
    if (isFiinXTemplate) {
      EventEmitter.dispatch(GET_RESULT_TEMPLATE_FIINX)
    } else {
      const oldGroupId = Object.keys(groupColumnById).reduce(
        (arrId, groupId) => {
          const oldId = []
          if (!newGroupId.includes(groupId)) {
            oldId.push(groupId)
          }
          return arrId.concat(oldId)
        },
        [],
      )
      const newRowOldColumn = convertParamGetResult(
        newTickerId,
        oldGroupId,
        groupColumnById,
        columnById,
        typeData.CORPORATE,
        convertDataId,
      )

      const oldTickerId = tickerId.filter((id) => !newTickerId.includes(id))
      const oldRowNewColumn = convertParamGetResult(
        oldTickerId,
        newGroupId,
        groupColumnById,
        columnById,
        typeData.CORPORATE,
        convertDataId,
      )

      const newRowNewColumn = convertParamGetResult(
        newTickerId,
        newGroupId,
        groupColumnById,
        columnById,
        typeData.CORPORATE,
        convertDataId,
      )

      dispatch(
        getResultThunk(
          [newRowOldColumn, oldRowNewColumn, newRowNewColumn].filter(
            (params) => !!params,
          ),
        ),
      )
    }
  }

  const handleShowExport = () => setIsShowPopupExport(true)

  const handleHideExport = () => setIsShowPopupExport(false)

  const handleSubmitExport = async (sheetIds) => {
    const param = {
      parameters: [],
    }

    sheetIds.forEach((value) => {
      const stateSheet = sheets[value]
      param.parameters.push({
        sheetName: getSheetName(listSheet, value),
        header: getColumnHeaders(
          stateSheet.columnId,
          stateSheet.columnById,
          stateSheet.groupColumnById,
          indicatorById,
          issueMethod,
          actionType,
          locale,
          timezone,
          getHeaderIndicator,
          mostRecent,
        ),
        paramerters: convertParamGetResult(
          stateSheet.tickerId,
          getAllGroupIds(stateSheet.groupColumnById),
          stateSheet.groupColumnById,
          stateSheet.columnById,
          detailSheet.templateGroup?.includes('FS')
            ? detailSheet.templateGroup?.replace('FS', '')
            : typeData.CORPORATE,
          convertDataId,
          false,
        ),
        customParams,
      })
    })

    const filterParam = {
      parameters: param.parameters.filter((sheet) => sheet.paramerters),
    }
    handleHideExport()
    if (filterParam.parameters.length > 0) {
      setIsLoadingDownloadCsv(true)
      await dispatch(exportExcelThunk(filterParam))
      setIsLoadingDownloadCsv(false)
    }
  }

  const handleShowSaveAs = () => {
    if (templates.length >= maxTemplate) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_1" />,
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_2" />,
      ])
    } else {
      setIsShowPopupSaveAs(true)
    }
  }

  const handleHideSaveAs = () => setIsShowPopupSaveAs(false)

  const handleSubmitSaveAs = (templateName) => {
    const newTickerById = { ...tickerById }
    tickerId.forEach(
      (id) =>
        (newTickerById[id] = {
          check: tickerById[id].check,
          organizationId: tickerById[id].organizationId,
        }),
    )
    const newColumnById = Object.values(columnById).reduce(
      (obj, item) => ({
        ...obj,
        [item.alias]: {
          alias: item.alias,
          check: item.check,
          color: item.color,
          condition: item.condition,
          groupId: item.groupId,
          stringCheckDuplicate: item.stringCheckDuplicate,
          title: item.title,
          indicatorId: item.indicatorId,
          indicatorType: item.indicatorType,
        },
      }),
      {},
    )

    const param = {
      templateName,
      dataType: typeData.CORPORATE,
      parameter: {
        header: [],
        parameter: DEFAULT_PARAM_TEMPLATE,
        rawParameter: {
          tickerById: newTickerById,
          tickerId,
          columnId,
          columnById: newColumnById,
          groupColumnById,
          newTickerId: tickerId,
          newGroupId: getAllGroupIds(groupColumnById),
        },
      },
    }
    dispatch(saveTemplateThunk(param))
    handleHideSaveAs()
  }

  const handleSave = () => {
    if (detailSheet?.templateId) {
      const newTickerById = { ...tickerById }
      tickerId.forEach(
        (id) =>
          (newTickerById[id] = {
            check: tickerById[id].check,
            organizationId: tickerById[id].organizationId,
          }),
      )
      const newColumnById = Object.values(columnById).reduce(
        (obj, item) => ({
          ...obj,
          [item.alias]: {
            alias: item.alias,
            check: item.check,
            color: item.color,
            condition: item.condition,
            groupId: item.groupId,
            stringCheckDuplicate: item.stringCheckDuplicate,
            title: item.title,
            indicatorId: item.indicatorId,
            indicatorType: item.indicatorType,
          },
        }),
        {},
      )

      const param = {
        templateId: detailSheet.templateId,
        templateName: detailSheet.title,
        dataType: typeData.CORPORATE,
        parameter: {
          header: [],
          parameter: DEFAULT_PARAM_TEMPLATE,
          rawParameter: {
            tickerById: newTickerById,
            tickerId,
            columnId,
            columnById: newColumnById,
            groupColumnById,
            newTickerId: tickerId,
            newGroupId: getAllGroupIds(groupColumnById),
          },
        },
      }
      dispatch(updateTemplateThunk(param))
    }
  }

  const isFiinXTemplate = !!detailSheet?.templateGroup
  const isDisableSaveTemplate =
    (!tickerId.length && !columnId.length) || isFiinXTemplate
  const isDisableLoad =
    isLoad ||
    !newTickerId.length ||
    !newGroupId.length ||
    (isFiinXTemplate && isLoadFiinXTemplate)

  return (
    <div className="d-flex j-b">
      <div className="d-flex ali-center">
        <Button
          style={{ fontSize: 11 }}
          className={`btn h-20 ${style.btnLoad} ${
            isDisableLoad ? style.disabled : ''
          }`}
          onClick={handleSubmitTable}
          disabled={isDisableLoad}
        >
          <Translate value="tool.dataExplorer.LOAD" />
        </Button>
        <div>
          <Translate value="tool.dataExplorer.corporate.NUMBER_OF_RESULT" />:{' '}
          {tickerId?.length}
        </div>
      </div>
      <div className="group-btn">
        <Button
          onClick={handleShowSaveAs}
          disabled={isDisableSaveTemplate}
          style={{ fontSize: 11 }}
          className={`btn btn-blue w-100px h-20 ${
            isDisableSaveTemplate ? style.disabled : ''
          }`}
        >
          {detailSheet?.templateId ? (
            <Translate value="common.button.BUTTON_SAVE_AS" />
          ) : (
            <Translate value="common.button.BUTTON_SAVE" />
          )}
        </Button>
        {detailSheet?.templateId && (
          <Button
            onClick={handleSave}
            disabled={isDisableSaveTemplate}
            style={{ fontSize: 11, marginLeft: 16 }}
            className={`btn btn-blue w-80 h-20 ${
              isDisableSaveTemplate ? style.disabled : ''
            }`}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        )}
        <Button
          style={{ fontSize: 10 }}
          className={`btn btn-blue h-20 btn-export ${style.btnExport} ${
            !isCanExport ? style.disabled : ''
          }`}
          onClick={handleShowExport}
          disabled={!isCanExport}
        >
          <i className={`icon-print ${style.iconPrint}`} />
          <Translate value="tool.smartScreening.EXPORT" />
        </Button>
      </div>
      {isShowPopupExport && (
        <PopupExportSheet
          sheet={listSheet}
          activeSheet={activeSheet}
          handleSubmit={handleSubmitExport}
          handleCancel={handleHideExport}
        />
      )}
      {isShowPopupSaveAs && (
        <PopupSaveTemplate
          templates={templates}
          handleHidePopupSaveAs={handleHideSaveAs}
          handleSubmit={handleSubmitSaveAs}
        />
      )}
    </div>
  )
}

export default GroupButton
