import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { selectData, selectLoading } from '../store/slice'
import { getRecommendation } from '../store/thunk'
import { PieChartWithHover } from './PieChartWithHover'

export const Chart = ({ width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const loading = useSelector(selectLoading)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getRecommendation({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo?.organizationId])

  if (loading) {
    return <Loading />
  }

  if (!data.length) {
    return <NoData />
  }

  return <PieChartWithHover data={data} width={width} height={height} />
}
