import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InfoValuation from '../../../../../core/services/bond/valuation/infoValuation'
import UserBondCashFlow from '../../../../../core/services/bond/valuation/userBondCashFlow'
import { keyBy } from '../../../../utils/Common'
import { handleExport } from '../../../../utils/Export'
import { nameStateSecInfo } from '../../infoSec/store/slice'
import {
  SLICE_NAME,
  TYPE_ACTION_TAB,
  deleteCashFlowClient,
  updateActiveTab,
} from './slice'

export const getPriceYtmApi = createAsyncThunk(
  'bond/valuation/sameIndustryBond/getPriceYtmApi',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoValuation.getPriceBond(params)
      if (response.success) {
        return response?.data || []
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getUserBondCashFlowList = createAsyncThunk(
  'bond/valuation/sameIndustryBond/UserBondCashFlowList',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { bondId, name, isUserBond, id } = params
      const allState = getState()
      const state = allState[SLICE_NAME]

      if (!bondId && !name) {
        return {
          data: {},
          listTab: state.listTab.slice(0, 2),
          type: '',
        }
      }

      const response = await UserBondCashFlow.getList({
        BondId: bondId,
        isUserBond,
      })

      if (response.success) {
        const dataList = response?.data || []

        const uniqueIds = [
          ...new Set(dataList.map((item) => item.bondCashFlowId)),
        ]

        const indexOfTabOld = state?.listTab.findIndex(
          (item) => item?.title === name,
        )
        const tabNew = response?.data.find(
          (item) => item?.bondCashFlowId === id,
        )

        const idOfTabOld = state?.listTab?.[indexOfTabOld]?.value
        const dataTabOld = state?.tab?.[idOfTabOld]

        if (
          name &&
          indexOfTabOld !== -1 &&
          tabNew &&
          idOfTabOld &&
          dataTabOld
        ) {
          const newDataAddTab = Object.assign({}, state?.tab ?? {})

          delete newDataAddTab[idOfTabOld]

          const newData = Object.assign({}, dataTabOld?.data ?? {})

          Object.keys(newData).forEach(function (key) {
            newData[key] = {
              ...newData[key],
              bondCashFlowId: tabNew.bondCashFlowId,
            }
          })

          newDataAddTab[tabNew.bondCashFlowId] = {
            data: newData,
            ids: dataTabOld.ids,
          }

          return {
            data: newDataAddTab,
            listTab: [
              ...state?.listTab.slice(0, indexOfTabOld),
              {
                title: tabNew.bondCashFlowName,
                value: tabNew.bondCashFlowId,
                bondCashFlowId: tabNew.bondCashFlowId,
                isDropdownDot: true,
              },
              ...state?.listTab.slice(indexOfTabOld + 1),
            ],
            type: TYPE_ACTION_TAB.ADD,
          }
        }

        const data = uniqueIds.map((e) => {
          const value = dataList.filter((i) => i.bondCashFlowId === e)

          return value.map((item, index) => ({
            ...item,
            id: index,
            couponInterestRate: item.couponInterestRate * 100,
            isDelete: true,
          }))
        })

        const listTabDefault = state.listTab.slice(0, 2)
        const listTab = uniqueIds.map((e, index) => {
          const value = dataList.find((i) => i.bondCashFlowId === e)
          return {
            title: value.bondCashFlowName,
            value: value.bondCashFlowId,
            bondCashFlowId: e,
            isDropdownDot: true,
          }
        })
        const dataAddTab = {
          0: state.tab?.[0],
          1: state.tab?.[1],
        }

        data.forEach((e) => {
          dataAddTab[e?.[0]?.bondCashFlowId] = {
            data: keyBy(e, 'id'),
            ids: e.map((item) => item.id),
          }
        })

        return {
          data: dataAddTab,
          listTab: [...listTabDefault, ...listTab],
          type: '',
        }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const checkNameApi = createAsyncThunk(
  'bond/valuation/sameIndustryBond/checkName',
  async (data, { rejectWithValue, getState }) => {
    try {
      const { item, isUserBond } = data

      const params = {
        BondCashFlowName: item.title,
        isUserBond,
      }

      const response = await UserBondCashFlow.checkName(params)
      if (response.success) {
        const allState = getState()
        const state = allState[SLICE_NAME].listTab
        const listTab = state.map((e) =>
          e.value === item.value ? { ...e, title: item.title } : e,
        )
        return listTab
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const addNewApi = createAsyncThunk(
  'bond/valuation/sameIndustryBond/addNew',
  async (data, { rejectWithValue, dispatch, getState }) => {
    try {
      const { params, isUserBond } = data

      const response = await UserBondCashFlow.addNew(params)
      if (response.success) {
        const allState = getState()
        const stateBond = allState[nameStateSecInfo]
        dispatch(updateActiveTab(response.data))
        dispatch(
          getUserBondCashFlowList({
            name: params?.parameter?.userBondCashFlowName,
            bondId: isUserBond ? stateBond.bondId : stateBond.BondIds,
            isUserBond,
            id: response?.data ?? null,
          }),
        )

        return params?.parameter?.userBondCashFlowName ?? ''
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const deleteCashFlowRowApi = createAsyncThunk(
  'bond/valuation/sameIndustryBond/delete',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await UserBondCashFlow.deleteRow(params)
      if (response.success) {
        dispatch(deleteCashFlowClient(params.bondCashFlowId))
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updateApi = createAsyncThunk(
  'bond/valuation/sameIndustryBond/update',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await UserBondCashFlow.updateList(data)
      if (response.success) {
        return data?.parameter?.userBondCashFlowName ?? ''
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const exportFile = createAsyncThunk(
  'demo/valuation/sameIndustryBond/exportFile',
  async (data) => {
    const response = await InfoValuation.exportFileApi(data)
    handleExport(response.data, response.filename)
  },
)

export const getAccruedInterest = createAsyncThunk(
  'bond/valuation/sameIndustryBond/getAccruedInterest',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InfoValuation.getAccruedInterestSv(params)
      if (response.success) {
        return response?.data || []
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
