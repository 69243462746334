import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { DAY_WEEK_MONTH_RADIO } from '../constants'
import style from './panelOpenMarket/index.module.css'
import {
  getTimeRange,
  handleIsScrollRight,
  handleTimeRange,
} from './panelOpenMarket/store/slice'

const OpenMarketHeader = () => {
  const dispatch = useDispatch()
  const radioValue = useSelector(getTimeRange)

  const handleChangeRadio = (timeRange) => {
    dispatch(handleTimeRange(timeRange))
    dispatch(handleIsScrollRight(true))
  }

  return (
    <div className={`${style.header}`}>
      <Title title={'economy.monetary.monetary.OPEN_MARKET'} />
      <VerticalLine />
      <div className="d-flex">
        {DAY_WEEK_MONTH_RADIO.map(({ value, label }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
              onClick={() => handleChangeRadio(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === radioValue}
                readOnly={true}
              />
              <Span style={{ fontSize: 12, fontWeight: 500 }}>
                <Translate value={label} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default OpenMarketHeader
