import { maxBy } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  selectAddInformatics,
  selectFilterParams,
  setFilterParameters,
} from '../../store/slice'
import { FILTER_FIELD_TYPES, RANGE_TYPES } from '../constants'
import { FilterTypeDate } from './FilterTypeDate'
import { FilterTypeInput } from './FilterTypeInput'
import { FilterTypeMultiSelect } from './FilterTypeMultiSelect'
import { FilterTypeNumber } from './FilterTypeNumber'
import { FilterTypeRatio } from './FilterTypeRadio'
import { FilterTypeSelect } from './FilterTypeSelect'

export const FilterTypes = ({ item, handleChangeParams }) => {
  const dispatch = useDispatch()

  const addInformatics = useSelector(selectAddInformatics)
  const params = useSelector(selectFilterParams)

  const handleChangeInputRange = (value, type, initialId, selectedId) => {
    const information = addInformatics.find(
      (item) => item.indicatorId === selectedId,
    )
    const indexInformation = params.addedInformations.findIndex(
      (item) => item.indicatorId === selectedId,
    )

    if (!initialId) {
      if (!value?.length) return

      const filter = addInformatics.find(
        (item) => item.indicatorId === selectedId,
      )

      if (!filter) return

      const {
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        multiplier,
      } = filter

      const newItem = {
        id: uuidv4(),
        order: params?.conditions?.length ?? 0,
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        conditionValues:
          type === RANGE_TYPES.FROM
            ? [!!value?.length ? value : null, null]
            : [null, !!value?.length ? value : null],
      }

      if (multiplier) {
        newItem.multiplier = multiplier
      }

      dispatch(
        setFilterParameters({
          key: 'conditions',
          value: [...params.conditions, newItem],
        }),
      )

      const index = params.addedInformations.findIndex(
        (item) => item.indicatorId === selectedId,
      )

      if (index === -1) {
        const max = maxBy(params.addedInformations, (e) => e.order)

        dispatch(
          setFilterParameters({
            key: 'addedInformations',
            value: [
              ...params.addedInformations,
              {
                id: uuidv4(),
                order: (max?.order ?? 0) + 1,
                indicatorId,
                tableCode,
                tableFieldCode,
              },
            ],
          }),
        )
      }

      return
    }

    const filter = params?.conditions?.find(
      (item) => item.indicatorId === initialId,
    )

    if (!filter) return

    const {
      id,
      order,
      indicatorId,
      tableCode,
      conditionTableFieldCode,
      tableFieldCode,
      selectionType,
      selectionTypeId,
      multiplier,
      conditionValues,
    } = filter

    const newConditionValues =
      type === RANGE_TYPES.FROM
        ? [!!value?.length ? value : null, conditionValues?.[1] ?? null]
        : [conditionValues?.[0] ?? null, !!value?.length ? value : null]

    const valuesNotEmptyString = newConditionValues.filter(
      (item) => item && item.length,
    )

    if (!valuesNotEmptyString.length) {
      const index = params?.conditions?.findIndex(
        (item) => item.indicatorId === initialId,
      )

      if (index === -1) return

      const newConditions = [
        ...params.conditions.slice(0, index),
        ...params.conditions.slice(index + 1),
      ]

      dispatch(setFilterParameters({ key: 'conditions', value: newConditions }))

      if (indexInformation !== -1 && !information?.isDefault) {
        dispatch(
          setFilterParameters({
            key: 'addedInformations',
            value: [
              ...params.addedInformations.slice(0, indexInformation),
              ...params.addedInformations.slice(indexInformation + 1),
            ],
          }),
        )
      }

      return
    }

    const newItem = {
      id,
      order,
      indicatorId,
      tableCode,
      conditionTableFieldCode,
      tableFieldCode,
      selectionType,
      selectionTypeId,
      conditionValues: newConditionValues,
    }

    if (multiplier) {
      newItem.multiplier = multiplier
    }

    const index = params?.conditions?.findIndex(
      (item) => item.indicatorId === initialId,
    )
    if (index === -1) return

    const newConditions = [
      ...params.conditions.slice(0, index),
      newItem,
      ...params.conditions.slice(index + 1),
    ]

    dispatch(setFilterParameters({ key: 'conditions', value: newConditions }))

    if (index === -1) {
      const max = maxBy(params.addedInformations, (e) => e.order)

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...params.addedInformations,
            {
              id: uuidv4(),
              order: (max?.order ?? 0) + 1,
              indicatorId,
              tableCode,
              tableFieldCode,
            },
          ],
        }),
      )
    }
  }

  return (
    (item.indicatorId !== 1 || item.indicatorId !== 55) &&
    !!item?.children?.length &&
    item?.children.map((child) => {
      switch (child.selectionType) {
        case FILTER_FIELD_TYPES.DATE:
          return (
            <FilterTypeDate
              data={child}
              handleChangeInputRange={handleChangeInputRange}
            />
          )
        case FILTER_FIELD_TYPES.SELECT:
          return (
            <FilterTypeSelect
              data={child}
              handleChangeParams={handleChangeParams}
            />
          )
        case FILTER_FIELD_TYPES.MULTI_SELECT:
          return (
            <FilterTypeMultiSelect
              data={child}
              handleChangeParams={handleChangeParams}
            />
          )
        case FILTER_FIELD_TYPES.RADIO:
          return (
            <FilterTypeRatio
              data={child}
              handleChangeParams={handleChangeParams}
            />
          )
        case FILTER_FIELD_TYPES.NUMBER:
          return (
            <FilterTypeNumber
              data={child}
              handleChangeInputRange={handleChangeInputRange}
            />
          )
        case FILTER_FIELD_TYPES.INPUT:
          return (
            <FilterTypeInput
              data={child}
              handleChangeParams={handleChangeParams}
            />
          )
        default:
          return <></>
      }
    })
  )
}
