export const GROUP_COLUMNS = [
  {
    key: 'delete',
    title: '',
  },
  {
    key: 'ticker',
    title: 'market.marginLoans.TICKER',
  },
  {
    key: 'name',
    title: 'market.marginLoans.NAME',
  },
  {
    key: 'parentMargin',
    title: 'market.marginLoans.MARGIN_LOANS',
  },
  {
    key: 'parentMarEquity',
    title: 'market.marginLoans.MARGIN_LOANS_OWNER_EQUITY',
  },
]

export const TOTAL_ID = 'total'

export const STICKIES_COL_ID = {
  DELETE: 'delete',
  TICKER: 'ticker',
  NAME: 'name',
}

export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'market.marginLoans.ALL_EXCHANGE',
  HOSE: 'HOSE',
  HNX: 'HNX',
  UPCOM: 'UPCOM',
  OTC: 'OTC',
}
