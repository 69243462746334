import { DateTimeSpan } from '../../../../../common/DateTimeSpan'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { formatValNumber, getFormatDateTime } from '../../helps'
import ColorCellType from '../ColorCell'
import {
  priceDataTableCol,
  tableColByTransactionType,
  tableFormatValue,
} from './constants'

const getValue = (val, colId) => {
  return val / tableFormatValue[colId]
}

export const getTableSchema = ({ TimeFilter: timeFilter }, transactionType) =>
  Object.keys(priceDataTableCol)
    .filter((key) => tableColByTransactionType[transactionType].includes(key))
    .map((key, index) => {
      const title = `market.equityTrading.priceStatistics.priceData.proprietary.${key}`
      const colId = priceDataTableCol[key]
      const result = {
        colId,
        title,
      }

      if (![priceDataTableCol.DATE].includes(colId)) {
        const customSchema = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            fontSize: 10,
            fontWeight: 'bold',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }

        if (
          [
            priceDataTableCol.NET_TOTAL_VAL,
            priceDataTableCol.NET_TOTAL_VOL,
            priceDataTableCol.MATCH_NET_VOL,
            priceDataTableCol.MATCH_NET_VAL,
            priceDataTableCol.DEAL_NET_VOL,
            priceDataTableCol.DEAL_NET_VAL,
          ].includes(colId)
        ) {
          return {
            ...customSchema,
            render: (val) => {
              return (
                <ColorCellType
                  val={getValue(val, colId)}
                  format={(val) => formatValNumber(val)}
                />
              )
            },
          }
        }

        return {
          ...customSchema,
          render: (val) => formatValNumber(getValue(val, colId)),
        }
      }

      const customSchema = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 10,
          fontWeight: 'bold',
        },
        tdStyle: {
          textAlign: 'left',
        },
      }

      if (colId === priceDataTableCol.DATE) {
        switch (timeFilter) {
          case PRICE_DATA_TIME_SELECT.DAILY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.DATE'
            break
          case PRICE_DATA_TIME_SELECT.WEEKLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.WEEK'
            break
          case PRICE_DATA_TIME_SELECT.MONTHLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.MONTH'
            break
          case PRICE_DATA_TIME_SELECT.QUARTERLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.QUARTER'
            break
          case PRICE_DATA_TIME_SELECT.YEARLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.YEAR'
            break
          default:
            break
        }
        return {
          ...customSchema,
          render: (val) => (
            <div style={{ width: 'max-content' }}>
              <DateTimeSpan
                date={val}
                formatDate={getFormatDateTime(timeFilter)}
              />
            </div>
          ),
        }
      }

      return customSchema
    })
