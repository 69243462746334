import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { keySettingChart, keyToolHeaderSettings } from '../../../constants'
import { changeStyleSetting } from '../../../store/slice'
import { RotateText } from './RotateText'
import SettingStyle from './SettingStyle'
import Switch from './Switch'
import style from './style.module.css'
import useGetDataStyle from './useGetDataStyle'

function XAxis({ settings, isBySecurities, onChange }) {
  const dispatch = useDispatch()
  const { xAxis, styleSetting } = useGetDataStyle()

  const handleChangeStyleSetting = (data) => {
    dispatch(
      changeStyleSetting({
        ...styleSetting,
        [keySettingChart.X_AXIS]: data,
      }),
    )
  }

  return (
    <div className="mb-8 mt-8">
      <div className={style.label}>
        <Translate value="financialChart.X_AXIS_SETTING" />
      </div>
      <SettingStyle
        style={xAxis}
        setStyle={handleChangeStyleSetting}
        disablePosition
        fontSizeMax={16}
      />
      <div className="mt-8">
        <Switch
          title={'financialChart.GROUP_BY_YEAR'}
          checked={!!settings[keyToolHeaderSettings.X_AXIS_GROUP_BY_YEAR]}
          disabled={isBySecurities}
          onChange={(value) =>
            onChange(keyToolHeaderSettings.X_AXIS_GROUP_BY_YEAR, value)
          }
        />
      </div>
      <div className="mt-8">
        <RotateText
          value={settings[keyToolHeaderSettings.X_AXIS_ANGLE]}
          disabled={!isBySecurities}
          onChange={(value) =>
            onChange(keyToolHeaderSettings.X_AXIS_ANGLE, value)
          }
        />
      </div>
    </div>
  )
}

export default XAxis
