import { common } from './common'
import * as financialAnalysis from './financialAnalysis'
import * as sectorConstituents from './sectorConstituents'
import * as sectorSpecific from './sectorSpecific'
import * as sectorStatistics from './sectorStatistics'

const sector = {
  common,
  sectorStatistics,
  sectorConstituents,
  financialAnalysis,
  sectorSpecific,
}

export default sector
