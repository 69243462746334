import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { TABLE_FILTER, TYPE_CATEGORY } from '../exportImportTable/constants'
import {
  removeCategoryFromList,
  selectCurrentDataCategory,
  selectImportExportDataType,
  selectListCategory,
  selectLoadingTable,
  selectTypeFilter,
} from '../exportImportTable/store/slice'
import { ChartComponent } from './ChartComponent'
import {
  COLORS_LINE_CHART,
  TURN_OVER_MONTHLY_DATE_FILTER,
  TURN_OVER_YEARLY_DATE_FILTER,
} from './constants'
import {
  changeActiveCategory,
  handleCurrentDateTypeMonth,
  handleCurrentDateTypeYear,
  selectCurrentDateTypeMonth,
  selectCurrentDateTypeYear,
  selectDataChart,
  selectDisableLoading,
  selectLoading,
  selectMaxYear,
} from './store/slice'
import {
  getExportChangeTurnoverByLocation,
  getExportTradeAgreementsChangeTurnoverByLocation,
  getImportChangeTurnoverByLocation,
  getImportTradeAgreementsChangeTurnoverByLocation,
  getMaxDateDetail,
} from './store/thunk'

export const ExportTurnOver = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const headerRef = useRef()
  const legendPanel = useRef()

  // Use selector
  const dataChart = useSelector(selectDataChart)
  const listCategory = useSelector(selectListCategory)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const isLoading = useSelector(selectLoading)
  const isLoadingTable = useSelector(selectLoadingTable)
  const currentDateTypeMonth = useSelector(selectCurrentDateTypeMonth)
  const currentDateTypeYear = useSelector(selectCurrentDateTypeYear)
  const importExportDataType = useSelector(selectImportExportDataType)
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)
  const maxDate = useSelector(selectMaxYear)
  const disableLoading = useSelector(selectDisableLoading)

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListCategory, setHeightListCategory] = useState(0)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT
  const isYearly = currentTypeFilter === TABLE_FILTER[0].value
  const getIdsInListCategory = listCategory?.map((item) => item.locationId)

  // Actions
  const onChangeTab = (item) => {
    if (isYearly) {
      dispatch(handleCurrentDateTypeYear(item.value))
    } else {
      dispatch(handleCurrentDateTypeMonth(item.value))
    }
  }

  const handleDelete = (item) => {
    dispatch(removeCategoryFromList(item.locationId))
    dispatch(changeActiveCategory(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveCategory(item.locationId))
  }

  const handleUnHover = () => {
    dispatch(changeActiveCategory(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListCategory)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListCategory,
      )
  }, [height, width, listCategory, heightListCategory])

  useEffect(() => {
    dispatch(
      getMaxDateDetail({
        ExImConstant: importExportDataType,
      }),
    )
  }, [importExportDataType])

  useEffect(() => {
    if (getIdsInListCategory.length && maxDate && !disableLoading) {
      if (currentCategoryFilter === TYPE_CATEGORY.CONTINENT) {
        if (isImportData) {
          dispatch(
            getImportChangeTurnoverByLocation({
              DataType: currentTypeFilter,
              TimeRange: isYearly ? currentDateTypeYear : currentDateTypeMonth,
              LocationIds: getIdsInListCategory.toString(),
              Year: maxDate?.realYear,
            }),
          )
        } else {
          dispatch(
            getExportChangeTurnoverByLocation({
              DataType: currentTypeFilter,
              TimeRange: isYearly ? currentDateTypeYear : currentDateTypeMonth,
              LocationIds: getIdsInListCategory.toString(),
              Year: maxDate?.realYear,
            }),
          )
        }
      } else {
        if (isImportData) {
          dispatch(
            getImportTradeAgreementsChangeTurnoverByLocation({
              DataType: currentTypeFilter,
              TimeRange: isYearly ? currentDateTypeYear : currentDateTypeMonth,
              LocationIds: getIdsInListCategory.toString(),
              Year: maxDate?.realYear,
            }),
          )
        } else {
          dispatch(
            getExportTradeAgreementsChangeTurnoverByLocation({
              DataType: currentTypeFilter,
              TimeRange: isYearly ? currentDateTypeYear : currentDateTypeMonth,
              LocationIds: getIdsInListCategory.toString(),
              Year: maxDate?.realYear,
            }),
          )
        }
      }
    }
  }, [listCategory, currentDateTypeMonth, currentDateTypeYear, maxDate])

  return width && height && (isLoading || isLoadingTable) ? (
    <Loading />
  ) : (
    <>
      <div className="h-100">
        <div className="d-flex justify-content-end" ref={headerRef}>
          <DispatchActionTab
            data={
              isYearly
                ? TURN_OVER_YEARLY_DATE_FILTER
                : TURN_OVER_MONTHLY_DATE_FILTER
            }
            activeTab={isYearly ? currentDateTypeYear : currentDateTypeMonth}
            onChangeTab={onChangeTab}
          />
        </div>
        {!dataChart.length ? (
          <NoData />
        ) : (
          <div id="exportTurnOverChart">
            <ChartComponent
              data={dataChart}
              width={width}
              height={heightSizeTracker || 0}
              keyXAxis={'time'}
              lineKeys={getIdsInListCategory}
            />
            <FooterWithScroll
              width={width}
              data={listCategory}
              idKey="locationId"
              nameKey="name"
              color={(item) => COLORS_LINE_CHART[listCategory.indexOf(item)]}
              legendPanel={legendPanel}
              isFullScreen={isFullScreen}
              setHeightFooter={setHeightListCategory}
              onDelete={handleDelete}
              onHover={handleHover}
              onUnHover={handleUnHover}
            />
          </div>
        )}
      </div>
    </>
  )
}
