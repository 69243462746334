import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TYPE_IMPORT_EXPORT } from '../../constants'
import { getMaxDateDetail } from '../../exportTurnOver/store/thunk'
import { CATEGORY_FILTER, TABLE_FILTER, TYPE_DATA } from '../constants'
import {
  handleImportByLocation,
  handleImportByLocationAfterChangeFilter,
  handleImportByLocationAfterScroll,
  handleImportByLocationChildren,
  handleImportByLocationChildrenAfterScroll,
  handleImportTradeAgreementsByLocation,
  handleImportTradeAgreementsByLocationAfterChangeFilter,
  handleImportTradeAgreementsByLocationAfterScroll,
  handleImportTradeAgreementsByLocationChildren,
  handleImportTradeAgreementsByLocationChildrenAfterScroll,
} from '../helper'
import {
  getExportByLocation,
  getExportByLocationAfterChangeFilter,
  getExportByLocationAfterScroll,
  getExportByLocationChildren,
  getExportByLocationChildrenAfterScroll,
  getExportTradeAgreementsByLocation,
  getExportTradeAgreementsByLocationAfterChangeFilter,
  getExportTradeAgreementsByLocationAfterScroll,
  getExportTradeAgreementsByLocationChildren,
  getExportTradeAgreementsByLocationChildrenAfterScroll,
  getImportByLocation,
  getImportByLocationAfterChangeFilter,
  getImportByLocationAfterScroll,
  getImportByLocationChildren,
  getImportByLocationChildrenAfterScroll,
  getImportTradeAgreementsByLocation,
  getImportTradeAgreementsByLocationAfterChangeFilter,
  getImportTradeAgreementsByLocationAfterScroll,
  getImportTradeAgreementsByLocationChildren,
  getImportTradeAgreementsByLocationChildrenAfterScroll,
} from './thunk'

const THIRD_LEVEL = 3

const slice = createSlice({
  name: 'economy/importExport/importExportByLocation/getImportByProduct',
  initialState: {
    idsCategory: [],
    dataById: {},
    isLoading: false,
    isContentLoading: false,
    listCategory: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    listOrigParentCategory: [],
    activeItem: null,
    typeData: TYPE_DATA.VALUE,
    currentTypeFilter: TABLE_FILTER[1].value,
    isChangeFilter: false,
    locale: null,
    isChangeLocale: false,
    currentMinMonth: null,
    currentMinYear: null,
    levels: [],
    initialIds: [],
    rowsCollapse: [],
    importExportDataType: TYPE_IMPORT_EXPORT.IMPORT,
    idsMapping: [],
    isChangeImExType: false,
    newMaxMonth: null,
    currentDataCategory: CATEGORY_FILTER[0].value,
    isLoadingRowsCollapseAndLevel: false,
    levelCollapse: {},
    isFirstTime: true,
    multipleActiveRowsIds: [],
    maxDate: null,
    unCollapseIds: [],
    payloadData: [],
    payloadNameData: [],
    limitQuarter: 0,
    isKeepRowsCollapse: false,
    keySearch: '',
    sortType: null,
  },
  reducers: {
    addCategoryToList: (state, action) => {
      state.isGetData = true
      state.listCategory = [...state.listCategory, action.payload]
    },
    removeCategoryFromList: (state, action) => {
      state.isGetData = false
      state.listCategory = state.listCategory.filter(
        (category) => category.id !== action.payload,
      )
    },
    changeActiveItemTable: (state, action) => {
      const rowId = action.payload
      state.activeItem =
        state.dataById[rowId]?.level === THIRD_LEVEL
          ? state.activeItem
          : state.dataById[rowId]
    },
    changeTypeData: (state, action) => {
      state.typeData = action.payload
      state.isChangeFilter = true
    },
    changeTypeFilter: (state, action) => {
      state.currentTypeFilter = action.payload
      state.isChangeFilter = true
      state.ids = []
      state.data = []
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
      state.isChangeLocale = true
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
    },
    changeCurrentMinYear: (state, action) => {
      state.currentMinYear = action.payload
    },
    sort: (state, action) => {
      state.idsCategory = getIdsFromProps(
        state.idsCategory,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
      state.sortType = Object.values(action.payload)[0]
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
    changeImportExportDataType: (state, action) => {
      state.importExportDataType = action.payload
      state.isChangeFilter = true
    },
    changeCurrentDataCategory: (state, action) => {
      state.currentDataCategory = action.payload
      state.isChangeFilter = true
      state.isLoadingRowsCollapseAndLevel = true
    },
    setLoadingTable: (state, action) => {
      state.isLoading = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleMultipleActiveRowsIds: (state, action) => {
      state.multipleActiveRowsIds = action.payload
    },
    setUnCollapseIds: (state, action) => {
      state.unCollapseIds = action.payload
    },
    setLimitQuarter: (state, action) => {
      state.limitQuarter = action.payload
    },
    setIsKeepRowsCollapse: (state, action) => {
      state.isKeepRowsCollapse = action.payload
    },
    changeKeySearch: (state, action) => {
      state.keySearch = action.payload
    },
  },
  extraReducers: (builder) => {
    //Import By Location
    builder.addCase(getImportByLocation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getImportByLocation.fulfilled, (state, action) => {
      handleImportByLocation(state, action)
    })
    builder.addCase(getImportByLocation.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportByLocationChildren.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getImportByLocationChildren.fulfilled, (state, action) => {
      handleImportByLocationChildren(state, action)
    })
    builder.addCase(getImportByLocationChildren.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportByLocationChildrenAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getImportByLocationChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportByLocationChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(
      getImportByLocationChildrenAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(getImportByLocationAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getImportByLocationAfterScroll.fulfilled,
      (state, action) => {
        handleImportByLocationAfterScroll(state, action)
      },
    )
    builder.addCase(getImportByLocationAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getImportByLocationAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getImportByLocationAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportByLocationAfterChangeFilter(state, action)
      },
    )
    builder.addCase(getImportByLocationAfterChangeFilter.rejected, (state) => {
      state.isLoading = false
    })

    //Export By Location
    builder.addCase(getExportByLocation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getExportByLocation.fulfilled, (state, action) => {
      handleImportByLocation(state, action)
    })
    builder.addCase(getExportByLocation.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getExportByLocationChildren.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getExportByLocationChildren.fulfilled, (state, action) => {
      handleImportByLocationChildren(state, action)
    })
    builder.addCase(getExportByLocationChildren.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getExportByLocationChildrenAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getExportByLocationChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportByLocationChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(
      getExportByLocationChildrenAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(getExportByLocationAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getExportByLocationAfterScroll.fulfilled,
      (state, action) => {
        handleImportByLocationAfterScroll(state, action)
      },
    )
    builder.addCase(getExportByLocationAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getExportByLocationAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getExportByLocationAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportByLocationAfterChangeFilter(state, action)
      },
    )
    builder.addCase(getExportByLocationAfterChangeFilter.rejected, (state) => {
      state.isLoading = false
    })

    //Import Trade Agreements
    builder.addCase(getImportTradeAgreementsByLocation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getImportTradeAgreementsByLocation.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocation(state, action)
      },
    )
    builder.addCase(getImportTradeAgreementsByLocation.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getImportTradeAgreementsByLocationChildren.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationChildren.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationChildren(state, action)
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationChildren.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getImportTradeAgreementsByLocationChildrenAfterScroll.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationChildrenAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getImportTradeAgreementsByLocationAfterScroll.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationAfterScroll.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationAfterScroll(state, action)
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getImportTradeAgreementsByLocationAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationAfterChangeFilter(state, action)
      },
    )
    builder.addCase(
      getImportTradeAgreementsByLocationAfterChangeFilter.rejected,
      (state) => {
        state.isLoading = false
      },
    )
    //Export Trade Agreements
    builder.addCase(getExportTradeAgreementsByLocation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getExportTradeAgreementsByLocation.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocation(state, action)
      },
    )
    builder.addCase(getExportTradeAgreementsByLocation.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getExportTradeAgreementsByLocationChildren.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationChildren.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationChildren(state, action)
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationChildren.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getExportTradeAgreementsByLocationChildrenAfterScroll.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationChildrenAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getExportTradeAgreementsByLocationAfterScroll.pending,
      (state) => {
        state.isLoading = true
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationAfterScroll.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationAfterScroll(state, action)
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationAfterScroll.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(
      getExportTradeAgreementsByLocationAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportTradeAgreementsByLocationAfterChangeFilter(state, action)
      },
    )
    builder.addCase(
      getExportTradeAgreementsByLocationAfterChangeFilter.rejected,
      (state) => {
        state.isLoading = false
      },
    )

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectLoadingTableContent = (state) =>
  state[slice.name].isContentLoading
export const selectCategoryIds = (state) => state[slice.name].idsCategory
export const selectCategoryDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCategory = (state) => state[slice.name].listCategory
export const selectDataTable = (state) => state[slice.name].data
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectOrigParentCategory = (state) =>
  state[slice.name].listOrigParentCategory
export const selectDataWithColorId = (state) =>
  state[slice.name].dataWithColorId
export const selectActiveItemTable = (state) => state[slice.name].activeItem
export const selectTypeData = (state) => state[slice.name].typeData
export const selectTypeFilter = (state) => state[slice.name].currentTypeFilter
export const selectCurrentDataCategory = (state) =>
  state[slice.name].currentDataCategory
export const selectImportExportDataType = (state) =>
  state[slice.name].importExportDataType
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectIsLoadingRowsCollapseAndLevel = (state) =>
  state[slice.name].isLoadingRowsCollapseAndLevel
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectIsFirstTime = (state) => state[slice.name].isFirstTime
export const selectMultipleActiveRowsIds = (state) =>
  state[slice.name].multipleActiveRowsIds
export const selectUnCollapseIds = (state) => state[slice.name].unCollapseIds
export const selectPayloadNameData = (state) =>
  state[slice.name].payloadNameData
export const selectLimitQuarter = (state) => state[slice.name].limitQuarter
export const selectKeySearch = (state) => state[slice.name].keySearch
export const selectIsKeepRowsCollapse = (state) =>
  state[slice.name].isKeepRowsCollapse
export const selectSortType = (state) => state[slice.name].sortType
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter

export const {
  addCategoryToList,
  removeCategoryFromList,
  changeActiveItemTable,
  changeTypeData,
  changeTypeFilter,
  changeLocale,
  changeCurrentMinMonth,
  changeCurrentMinYear,
  sort,
  setRowsCollapse,
  changeImportExportDataType,
  changeCurrentDataCategory,
  setNewMaxMonth,
  setLoadingTable,
  handleIsFirstTime,
  handleMultipleActiveRowsIds,
  setUnCollapseIds,
  setLimitQuarter,
  setIsKeepRowsCollapse,
  changeKeySearch,
} = slice.actions

register(slice.name, slice.reducer)
