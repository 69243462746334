import { useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import TopNetValue from './topNetValue'
import { TOP_COUNT, VALUE_TYPE } from './topNetValue/constants'
import { selectTimeFrame, selectType } from './topNetValue/store/slice'
import { downloadTopNetValue } from './topNetValue/store/thunk'

const PanelTopNetValue = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const indexInfo = useSelector(selectIndexInfo)
  const timeFrame = useSelector(selectTimeFrame)
  const type = useSelector(selectType)

  return (
    <Panel
      title={'market.marketInDepth.localInstitution.TOP_NET_VALUE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadTopNetValue({
          GroupId: indexInfo?.groupId,
          Period: timeFrame,
          IsBuy: type === VALUE_TYPE.BUY,
          Top: TOP_COUNT,
        })
      }
    >
      <TopNetValue />
    </Panel>
  )
}

export default PanelTopNetValue
