import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class DeepTransactionProxy extends ProxyBase {
  getDeepTransactionSummary(params) {
    return this.get('DeepTransaction/Summary', params)
  }

  getDeepTransactionPricePerformance(params) {
    return this.get('DeepTransaction/PricePerformance', params)
  }

  getDeepTransactionMatchingOrders(params) {
    return this.get('DeepTransaction/MatchingOrders', params)
  }

  getDeepTransactionTimeSaleBarChart(params) {
    return this.get('DeepTransaction/TimeAndSales/BarChartData', params)
  }

  getDeepTransactionTimeSaleAreaChart(params) {
    return this.get('DeepTransaction/TimeAndSales/AreaChartData', params)
  }
}

export default new DeepTransactionProxy(
  ServiceProxyConfig.Market.EquityTrading.ServiceUrl,
)
