import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../html/Span'
import SelectCustom from '../../selectCustom'
import { ALL_TYPE } from '../constants'
import style from './index.module.css'

export const SourceSelect = ({
  value,
  changeFilter,
  sources,
  appendDropdownRef,
}) => {
  return (
    <div className="mt-30">
      <label className={style.titleSelect}>
        <Span style={{ fontSize: 11 }}>
          <Translate value={'common.news.SOURCE'} />
        </Span>
      </label>
      <SelectCustom
        appendDropdownRef={appendDropdownRef}
        value={value}
        selectData={[
          { newsSourceId: ALL_TYPE, newsSourceName: I18n.t('common.ALL') },
          ...sources,
        ].map((item) => {
          return {
            value: item.newsSourceId,
            name: item.newsSourceName,
          }
        })}
        handleChange={(value) => changeFilter({ source: value })}
      />
    </div>
  )
}
