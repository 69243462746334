import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getDataThunk, getDataWatchList } from './thunk'

const name = 'bond/bondSecurity/generalSetting'
const initialState = {
  loading: false,
  data: {},
  dataFl: [],
  listCheckBox: [],
  popupAlert: {
    show: false,
    message: '',
  },
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateListCheckBox: (state, action) => {
      state.listCheckBox = action.payload
    },
    updatePopupAlert: (state, action) => {
      state.popupAlert = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
      state.isDone = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      state.data = action.payload || {}
      state.loading = false
    })
    builder.addCase(getDataThunk.rejected, (state) => {
      state.data = {}
      state.loading = false
      state.isDone = true
    })

    builder.addCase(getDataWatchList.pending, (state) => {})
    builder.addCase(getDataWatchList.fulfilled, (state, action) => {
      state.dataFl = action.payload || []
      // state.listCheckBox = action.payload.map((e) => e.bondWatchListId)
    })
    builder.addCase(getDataWatchList.rejected, (state) => {
      state.dataFl = []
      state.listCheckBox = []
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectDataBondDetail = (state) => state[slice.name].data
export const selectDataFl = (state) => state[slice.name].dataFl
export const selectListCheckBox = (state) => state[slice.name].listCheckBox
export const selectPopupAlert = (state) => state[slice.name].popupAlert

export const {
  resetStore,
  updateTab,
  updateCoupom,
  updateListCheckBox,
  updatePopupAlert,
} = slice.actions

register(slice.name, slice.reducer)
