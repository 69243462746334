import { useState } from 'react'
import TextEllipsis from '../textEllipsis'

export const CellRedirect = ({ val, rowId, style, className, onClick }) => {
  const [isHover, setIsHover] = useState(false)

  const handleClick = () => {
    onClick && onClick(val, rowId)
  }

  return (
    <td
      style={style}
      className={className}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={handleClick}
    >
      <TextEllipsis
        isI18n={false}
        text={val}
        appendStyle={{ color: isHover ? '#2F9AEE' : '', cursor: 'pointer' }}
      />
    </td>
  )
}
