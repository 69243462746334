import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { Span } from '../../common/html/Span'
import { FEATURES } from '../../constants/routes'
import { DuplicateBtn } from '../common/header/DuplicateBtn'
import { useGetUserSettings } from '../common/useGetUserSettings'
import { dashboardTypes, screenTypes } from '../constants'
import { ReactComponent as IconDownloadBlack } from '../icon/icon-download-black.svg'
import {
  changeDashboard,
  selectChartOfTheDay,
  selectDashboard,
} from '../store/slice'
import style from './index.module.css'

export const Header = ({ onDownloadChart, typeDuplicate, chartItem }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const { data = {} } = useSelector(selectDashboard)
  const chartOfTheDay = useSelector(selectChartOfTheDay)
  const { defaultDashboard } = useGetUserSettings()

  const onClick = () => {
    const params = {
      screenType: screenTypes.DASHBOARD,
      dashboardType: dashboardTypes.MY_DASHBOARD,
    }
    if (!data) {
      params.data = defaultDashboard || chartOfTheDay
    }
    dispatch(changeDashboard(params))
    navigate(
      FEATURES['financial-chart'].components['financial-chart'].route,
      {},
    )
  }

  return (
    <div className={style.header}>
      <div className={style.buttonBack}>
        <i
          className={`icon-arrow-left-bold ${style.iconBack}`}
          onClick={onClick}
        />
        <Span style={{ fontSize: 14, fontWeight: 600 }}>
          <Translate value={'financialChart.BACK'} />
        </Span>
      </div>
      <div className={style.groupButton}>
        <DuplicateBtn typeDuplicate={typeDuplicate} chartItem={chartItem} />
        <IconDownloadBlack
          className="cursor-pointer"
          onClick={onDownloadChart}
        />
      </div>
    </div>
  )
}
