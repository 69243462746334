import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Z_INDEX } from '../../../../constants/Common'
import TextEllipsis from '../../../textEllipsis'
import style from './index.module.css'

export const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      <span className={style.w25}>
        <TextEllipsis
          text={value.groupName || value.icbNameCustom || value.ticker}
          zIndexTooltip={Z_INDEX.DROPDOWN}
          isI18n={false}
        />
      </span>
      <span className={style.textLongW60}>
        {value.groupCode ||
          value.organizationShortName ||
          I18n.t('common.INDUSTRY_LEVEL') + value.icbLevel}
      </span>
      <span className={style.w15AlignRight}>
        {value?.exchangeCode || I18n.t('common.LABEL_HOHAUP')}
      </span>
    </div>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
