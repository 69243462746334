import { createAsyncThunk } from '@reduxjs/toolkit'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getChangeInMarketShareChartData = createAsyncThunk(
  'corporate/businessModel/penetration/securities/panelChangeInBrokerageMarketShare/CHANGE_IN_MARKET_SHARE_CHART_DATA',
  async (params, { rejectWithValue }) => {
    const response =
      await PenetrationAnalysisService.getSecChangeInMarketShareChartData(
        params,
      )
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
