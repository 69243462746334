import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BrokerageMarketShare from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectTypeFilter } from '../store/slice'
import { getMarketShareChartName } from './helper'
import { resetStore } from './store/slice'

const PanelBrokerageMarketShare = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const dispatch = useDispatch()

  const stockInfo = useSelector(selectBasicInfo)
  const typeFilter = useSelector(selectTypeFilter)
  const nameScreen = UseI18n(
    'corporate.businessModel.penetrationAnalysis.PENETRATION_ANALYSIS',
  )
  const chartName = UseI18n(getMarketShareChartName(typeFilter))

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <Panel
      title={'corporate.businessModel.penetrationAnalysis.MARKET_SHARE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: 'brokerageMarketShareId',
        nameScreen,
        chartName,
        tickerName: stockInfo.ticker,
      }}
    >
      <BrokerageMarketShare
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelBrokerageMarketShare
