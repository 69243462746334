import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import { selectListCategory } from '../balanceOfTrade/store/slice'
import style from '../index.module.css'
import { COLORS_LINE_CHART, LIST_RADIO } from './constants'
import { selectActiveCategory } from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 40, bottom: 20 }

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  typeCode,
}) => {
  const listCategory = useSelector(selectListCategory)
  const activeCategory = useSelector(selectActiveCategory)

  const typeCodeIsValue = typeCode === LIST_RADIO[0].value
  const labelYAxis = typeCodeIsValue
    ? I18n.t('economy.importExport.importExport.BILLION_USD')
    : I18n.t('economy.importExport.importExport.PERCENT')

  const growthCategoryTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listCategory.map((item) => (
              <tr className={style.trTable} key={item.id}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>{item.name}</Span>:
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{
                      fontSize: 11,
                      color: valueTooltip[item.id] < 0 ? '#ff4752' : '',
                    }}
                  >
                    {`${formatVal(valueTooltip[item.id])} ${labelYAxis}`}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                label: typeCodeIsValue
                  ? `(${I18n.t(
                      'economy.importExport.importExport.BILLION_USD',
                    )})`
                  : '',
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                unitYAxis: typeCodeIsValue ? '' : '%',
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              growthCategoryTooltip(payload, decimalLengths)
            }
            timeFrame={TIME_RANGES.CUSTOM}
            isNotFormatXAxis
          >
            {listCategory.map((item) => (
              <Line
                key={item.id}
                yAxisId="left"
                dataKey={item.id}
                type="linear"
                stroke={COLORS_LINE_CHART[item.colorId]}
                dot={false}
                activeDot={false}
                strokeOpacity={
                  !activeCategory ? 1 : activeCategory === item.id ? 1 : 0.1
                }
                isAnimationActive={false}
                strokeWidth={
                  !activeCategory ? 1.5 : activeCategory === item.id ? 2.5 : 1.5
                }
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}
