export const common = {
  TITLE: 'Danh sách Trái phiếu',
  BOND_BUYBACK_TITLE: 'Giao dịch mua bán lại',
  BOND_AGGREGATOR: 'Tổng hợp trái phiếu',
  BOND_TRANSACTION: 'Giao dịch mua bán lại',
  BOND_CREATING: 'Trái phiếu khởi tạo',
  ALL_ISSUER: 'Tổ chức phát hành',
  ALL: 'Tất cả',
  REMAINING_DERATION: 'Kỳ hạn còn lại',
  YES: 'Có',
  NO: 'Không',
  BOND_TYPE: 'Loại trái phiếu',
  INDUSTRY: 'Ngành',
  RESET: 'Thiết lập lại',
}

export const filter = {
  TITLE: 'Bộ lọc',
  NUMBER_OF_RESULT: 'Số lượng kết quả: %{count}',
  SEARCH_FILTER_CRITERIA: 'Tìm kiếm tiêu chí lọc',
  GENERAL_INFORMATION: 'Thông tin chung',
  TICKER_OR_ISSUERS: 'Mã TP hoặc TCPH',
  BOND_TYPE_1: 'TPCP',
  BOND_TYPE_2: 'TPDN',
  LIST_OF_ISSUERS: 'Danh mục tổ chức phát hành',
  ISSUE_INFORMATION: 'Thông tin phát hành',
  ISSUE_DATE: 'Ngày phát hành',
  MATURITY_DATE: 'Ngày đáo hạn',
  ISSUE_DURATION: 'Kỳ hạn phát hành',
  ISSUE_VALUE_UNIT: 'Giá trị phát hành (Tỷ VND)',
  OUT_VALUE_UNIT: 'Giá trị lưu hành (Tỷ VND)',
  INTEREST_RATE_INFORMATION: 'Thông tin lãi suất',
  YTM: 'Lãi suất chiết khấu',
  COUPON_INTEREST_RATE: 'Lãi suất Coupon',
  INTEREST_PAYMENT_DATE: 'Kỳ trả lãi dự kiến gần nhất',
  CONDITION_INFORMATION: 'Thông tin điều kiện',
  IS_COLLATERAL: 'Có tài sản đảm bảo',
  IS_PAYMENT_GUARANTEE: 'Có bảo lãnh thanh toán',
  GUARANTEE_ORGANIZATION: 'Tổ chức bảo lãnh thanh toán',
  BOND_STATUS: 'Tình trạng trái phiếu',
  DATE_FROM: 'Từ /   /',
  DATE_TO: 'Đến /   /',
  TEMPLATE_NAME: 'Tìm kiếm trái phiếu',
  SAVE_TEMPLATE_TITLE: 'Lưu biểu mẫu',
  SAVE: 'Lưu biểu mẫu mới',
  UPDATE: 'Lưu vào biểu mẫu đã có',
  PLACEHOLDER: 'Nhập tên biểu mẫu',
  PLACEHOLDER_SELECT: 'Chọn biểu mẫu',
  NOTE: 'Biểu mẫu được lưu sẽ hiển thị ở dạng tab(thẻ) ở trên danh sách',
  MESSAGE1: 'Vui lòng nhập tên biểu mẫu',
  MESSAGE2: 'Tên biểu mẫu đã tồn tại',
  MESSAGE3: 'Vui lòng chọn biểu mẫu',
  MESSAGE4: 'Chỉ có thể lưu tối đa 100 biểu mẫu cá nhân. Hãy thử lại!',
  MORE: 'More',
  YTD: 'Đến cuối năm',
  QTD: 'Đến cuối quý',
  MTD: 'Đến cuối tháng',
  DELETE_1: 'Bạn chắc chắn muốn xóa biểu mẫu',
  DELETE_2: '?',
  DELETE_TITLE: 'Xoá biểu mẫu',
  ISSUER_NOTE: 'Dữ liệu được lọc dựa trên Ngày phát hành trái phiếu',
}

export const bondList = {
  TITLE: 'Danh sách',
  ALL_BOND: 'Tất cả trái phiếu',
  ALL_ISSUER: 'Tất cả TCPH',
  FILTER_OTHER: 'Bộ lọc khác',
  ADD_INDICATORS: 'Thêm chỉ tiêu',
  SAVE_TEMPLATE: 'Lưu biểu mẫu',
  CREATE_WATCHLIST: 'Tạo danh mục',
  table: {
    total: 'Tổng số kết quả : %{total} kết quả',
    no: 'STT',
    bondSticker: 'Mã TP',
    ticker: 'Mã',
  },
  WATCHLIST: 'Danh mục TCPH',
  OPTION_PANEL: 'Bảng tuỳ chọn',
  CREATE_BOND: 'Tạo trái phiếu',
}

export const createBond = {
  OVERVIEW: 'Thông tin chung',
  CREATE_TITLE: 'Trái phiếu khởi tạo',
  EDIT_TITLE: 'Chỉnh sửa trái phiếu khởi tạo',
  BOND_TICKER: 'Mã trái phiếu',
  BOND_TYPE: 'Loại trái phiếu',
  ISSUER: 'Tổ chức phát hành',
  SECTOR: 'Ngành',
  ISSUE_DATE: 'Ngày phát hành',
  MATURITY_DATE: 'Ngày đáo hạn',
  PAR_VALUE: 'Mệnh giá (VND)',
  INTEREST_PAYMENT_PERIOD: 'Kỳ trả lãi %{unit}',
  INTEREST_PAYMENT_PERIOD_UNIT: ' (tháng/lần)',
  EXPECTED: 'Số kỳ trả lãi coupon dự kiến',
  ISSUE_PERIOD: 'Kỳ hạn phát hành',
  COUPON_TYPE: 'Loại lãi suất',
  COUPON_INTEREST_RATE: 'Lãi suất Coupon (%/năm)',
  CORPORATE_BOND: 'Trái phiếu doanh nghiệp',
  GOVERNMENT_BOND: 'Trái phiếu chính phủ',
  FIXED: 'Cố định',
  FLOAT: 'Thả nổi',
  ONE_MONTH: '1',
  THREE_MONTHS: '3',
  SIX_MONTHS: '6',
  TWELVE_MONTHS: '12',
  MESSAGE_REQUIRED: 'Đây là trường bắt buộc',
  TICKER_REQUIRED:
    "Các ký tự đặc biệt được phép nhập gồm: '.' ; ' -' ; '_'. Vui lòng nhập mã hợp lệ",
  TICKER_MAX_LENGTH: 'Số lượng ký tự được nhập không vượt quá 30',
  PAR_VALUE_MAX: 'Vui lòng nhập mệnh giá trong khoảng 0 - 10,000,000,000',
  DATE_MAX: 'Kỳ hạn trái phiếu không vượt quá 30 năm',
  YEAR: '%{number} năm',
  MONTH: '%{number} tháng',
  INTEREST_RATE_MAX: 'Vui lòng nhập lãi Coupon trong khoảng 0 - 100%',
  MESSAGE_CREATE: 'Mã trái phiếu được tạo thành công',
  MESSAGE_UPDATE: 'Cập nhật thông tin mã trái phiếu "%{ticker}" thành công',
  MESSAGE_DELETE: 'Đã xoá thành công mã trái phiếu "%{ticker}"',
  SEARCH_PLACEHOLDER: 'Tìm kiếm mã TP',
  DELETE_TITLE: 'Xóa trái phiếu',
  DELETE_CONTENT:
    'Bạn có chắc chắn muốn xóa vĩnh viễn mã trái phiếu "%{ticker}"?',
}

export const allIssuer = {
  TICKER_OR_ISSUERS: 'Mã hoặc tên TCPH',
  SEARCH_PLACEHOLDER: 'Tìm kiếm mã hoặc tên TCPH',
  TEMPLATE_NAME: 'Tìm kiếm TCPH',
}
