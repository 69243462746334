import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useGetStock from '../../../../common/masterData/useGetStock'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import TextEllipsis from '../../../../common/textEllipsis'
import { getValueByKeyInArray } from '../../../../utils/Value'
import { LIST_TYPE } from '../../../common/constants'
import { getDataAfterDeleteCompany } from '../helpers'
import {
  changeEquationSecurities,
  selectEquationSecurities,
} from '../store/slice'
import style from '../style.module.css'

const SecuritiesSelect = () => {
  const dispatch = useDispatch()

  const { stocks } = useGetStock()

  const { companies, sector, indices } = useSelector(selectEquationSecurities)

  const handleDeleteCompany = (id) => {
    // Companies
    const newCompanies = companies.filter((val) => val !== id)
    dispatch(
      changeEquationSecurities({
        type: LIST_TYPE.COMPANIES,
        value: newCompanies,
      }),
    )
    // Indices
    const newIndices = getDataAfterDeleteCompany(indices, newCompanies)
    if (newIndices) {
      dispatch(
        changeEquationSecurities({
          type: LIST_TYPE.INDICES,
          value: newIndices,
        }),
      )
    }
    // Sector
    const newSector = getDataAfterDeleteCompany(sector, newCompanies)
    if (newSector) {
      dispatch(
        changeEquationSecurities({
          type: LIST_TYPE.SECTOR,
          value: newSector,
        }),
      )
    }
  }

  return (
    <>
      <div className={style.listData}>
        <ScrollComponent bgColor="#D9D9D9">
          <div>
            {companies &&
              companies.map((id) => (
                <div key={id} className={style.equationListItem}>
                  <div className={style.equationSecuritiesListItemTicker}>
                    <TextEllipsis
                      text={getValueByKeyInArray(stocks, id, 'organizationId', [
                        'ticker',
                        'taxCode',
                      ])}
                      isI18n={false}
                    />
                  </div>
                  <div className={style.equationListItemName}>
                    <TextEllipsis
                      text={getValueByKeyInArray(
                        stocks,
                        id,
                        'organizationId',
                        'organizationShortName',
                      )}
                      isI18n={false}
                    />
                  </div>
                  <div
                    className={style.equationListItemDelete}
                    onClick={() => handleDeleteCompany(id)}
                  >
                    <i
                      style={{ fontSize: 10, color: '#4b5259' }}
                      className="icon-delete-circle"
                    />
                  </div>
                </div>
              ))}
          </div>
        </ScrollComponent>
      </div>
      <div style={{ color: '#4e4e4e' }} className="d-flex align-center mt-6">
        <Translate value="financialChart.SELECTED" />
        <span className="ml-5">{companies?.length || 0}</span>
      </div>
    </>
  )
}

export default SecuritiesSelect
