import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import TextEllipsis from '../../textEllipsis'

export const Source = ({ isShowBtnShare, item }) => {
  return (
    <>
      {isShowBtnShare ? (
        <>
          <Translate value="common.news.SOURCE" />
          :&nbsp;
          <a href={item.sourceUrl} target="_blank" rel="noopener noreferrer">
            {item.source || ''}
          </a>
        </>
      ) : (
        <a href={item.sourceUrl} target="_blank" rel="noopener noreferrer">
          <TextEllipsis
            isI18n={false}
            text={I18n.t('common.news.SOURCE') + ': ' + (item.source || '')}
          />
        </a>
      )}
    </>
  )
}

Source.propTypes = {
  isShowBtnShare: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
}
