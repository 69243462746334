import PropTypes from 'prop-types'
import { KEY_DASHBOARD_TABS } from '../../editDashboard/constants'
import { ChartSetting } from '../setting/ChartSetting'
import style from './PanelHeader.module.css'

export const PanelHeader = ({
  item,
  dashboard,
  viewerQuantity,
  setPreviewChart,
  editChart,
  renameChart,
  duplicateChart,
  positionCopyToLeft,
  isShowViews,
  index,
  isShowSetting,
}) => {
  return (
    <div className={style.container}>
      <div className={style.left}>
        {/* {isShowViews ? (
          <>
            <SettingIcon className="mr-5" />
            &nbsp;
            {viewerQuantity} <Translate value="financialChart.VIEW" />
          </>
        ) : null} */}
      </div>
      <div className={style.right} data-html2canvas-ignore>
        {isShowSetting ? (
          <>
            <i
              className={'icon-setting-bold cursor-pointer'}
              style={{ marginRight: 12, fontSize: 14 }}
              onClick={() => editChart(item)}
            />
            <i
              className={'icon-window cursor-pointer'}
              style={{ marginRight: 8, fontSize: 14 }}
              onClick={setPreviewChart}
            />
            <ChartSetting
              chartTab={index + 1 + KEY_DASHBOARD_TABS}
              item={item}
              dashboard={dashboard}
              renameChart={renameChart}
              duplicateChart={duplicateChart}
              positionCopyToLeft={positionCopyToLeft}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

PanelHeader.propTypes = {
  item: PropTypes.any.isRequired,
  viewerQuantity: PropTypes.number,
  setPreviewChart: PropTypes.func,
  editChart: PropTypes.func,
  renameChart: PropTypes.func,
  isShowViews: PropTypes.bool,
  isShowSetting: PropTypes.bool,
}

PanelHeader.defaultProps = {
  viewerQuantity: 101,
  setPreviewChart: () => {},
  renameChart: () => {},
  isShowSetting: true,
}
