import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import TopInfo from '../../common/topInfo/stockTopInfo'
import Content from './Content'

export const Ownership = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo />
              {(size.height || size.height === 0) && (
                <div
                  className="row summary-page"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
