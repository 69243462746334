import { I18n, Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { deepCopy } from '../../../common/dataExplorer/popupIndicator/helper'
import { units } from '../../../common/dataExplorer/popupTemplate/FinancialStatement'
import { EMPTY_VALUE } from '../../../constants/Common'
import { uuid } from '../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import EventEmitter, {
  CALLBACK_SHOW_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../utils/EventEmitter'
import { isInValidValue } from '../../../utils/Value'
import { INDICATOR_TYPE, typeData } from '../constant'
import { maxTicker, typePopupIndicator, typePopupTemplate } from './constant'
import ActionName from './popupIndicator/ActionName'
import IndicatorName from './popupIndicator/IndicatorName'
import IssueMethodName from './popupIndicator/IssueMethodName'
import { callBackErrorAddTicker } from './store/slice'

export const checkMaxTicker = (lengthTicker, callback, arrTicker) => {
  if (lengthTicker > maxTicker) {
    if (arrTicker) {
      EventEmitter.dispatch(
        CALLBACK_SHOW_POPUP_ERROR,
        callBackErrorAddTicker(arrTicker),
      )
    }
    EventEmitter.dispatch(SHOW_POPUP_ERROR, [
      <Translate value="tool.dataExplorer.corporate.MAX_TICKER_1" />,
      <Translate value="tool.dataExplorer.corporate.MAX_TICKER_2" />,
    ])
  } else callback()
}

export const convertParamGetMostRecent = (indicator, type) => {
  const indicators = []
  const dataType = type || typeData.CORPORATE
  indicator.forEach(({ indicatorId, conditionType }) => {
    if (
      ![
        typePopupIndicator.DE01_SC18,
        typePopupIndicator.DE01_SC20,
        typePopupIndicator.DE01_SC33,
        typePopupIndicator.DE01_SC34,
        typePopupIndicator.DE01_SC35,
      ].includes(conditionType)
    ) {
      indicators.push({ indicatorId, dataType: typeData.CORPORATE })
    } else {
      indicators.push({
        indicatorId,
        indicatorType: INDICATOR_TYPE.TTM,
        dataType: typeData.CORPORATE,
      })
      indicators.push({
        indicatorId,
        indicatorType: INDICATOR_TYPE.YEARLY,
        dataType: typeData.CORPORATE,
      })
    }
  })
  return {
    indicatorGroup: dataType,
    indicators,
  }
}

export const convertDataColumns = (conditionType, data, allIndicatorById) => {
  const indicators = []
  switch (conditionType) {
    case typePopupIndicator.DE01_SC07:
    case typePopupIndicator.DE01_SC08:
    case typePopupIndicator.DE01_SC10:
    case typePopupIndicator.DE01_SC11:
    case typePopupIndicator.DE01_SC12:
    case typePopupIndicator.DE01_SC13:
    case typePopupIndicator.DE01_SC15:
    case typePopupIndicator.DE01_SC16:
    case typePopupIndicator.DE01_SC17:
    case typePopupIndicator.DE01_SC19:
    case typePopupIndicator.DE01_SC27:
    case typePopupIndicator.DE01_SC28:
    case typePopupIndicator.DE01_SC29:
    case typePopupIndicator.DE01_SC30:
    case typePopupIndicator.DE01_SC31:
    case typePopupIndicator.DE01_SC32:
    case typePopupIndicator.DE01_SC36:
      data.indicator.forEach((id) => {
        const stringCheckDuplicate = JSON.stringify({ id, ...data.detail })
        indicators.push({
          alias: uuid(),
          ...data.indicatorById[id],
          stringCheckDuplicate,
        })
      })
      break
    case typePopupIndicator.DE01_SC09:
    case typePopupIndicator.DE01_SC14:
    case typePopupIndicator.DE01_SC21: {
      const years =
        data.detail.sourceDividendYears ||
        data.detail.dividendYears ||
        data.detail.years
      const {
        sourceDividendYears,
        dividendYears,
        years: _,
        ...conditionCheck
      } = data.detail

      data.indicator.forEach((id) => {
        years.forEach((year) => {
          const stringCheckDuplicate = JSON.stringify({
            id,
            year,
            ...conditionCheck,
          })
          indicators.push({
            alias: uuid(),
            condition: {
              [conditionType === typePopupIndicator.DE01_SC09
                ? 'sourceDividendYear'
                : 'year']: year,
            },
            ...data.indicatorById[id],
            stringCheckDuplicate,
            title: { year },
          })
        })
      })
      break
    }
    case typePopupIndicator.DE01_SC18:
    case typePopupIndicator.DE01_SC33:
    case typePopupIndicator.DE01_SC34:
    case typePopupIndicator.DE01_SC35: {
      const conditionCheck = deepCopy(data.detail)
      delete conditionCheck?.ttmSettings?.years
      delete conditionCheck?.ttmSettings?.quarters
      delete conditionCheck?.yearlySettings?.years

      data.indicator.forEach((id) => {
        const pairIndicatorId = data.indicatorById[id]?.pairIndicator
        const yearlyPairIndicator = allIndicatorById[pairIndicatorId]
        const ttmSettings = data.detail.ttmSettings

        if (ttmSettings) {
          if (!ttmSettings.years) {
            const stringCheckDuplicate = JSON.stringify({
              id,
              ...conditionCheck.ttmSettings,
              isTtm: true,
            })

            indicators.push({
              alias: uuid(),
              indicatorType: INDICATOR_TYPE.TTM,
              ...data.indicatorById[id],
              stringCheckDuplicate,
              title: {
                date: ttmSettings?.date,
                indicatorType: INDICATOR_TYPE.TTM,
              },
            })
          } else {
            ttmSettings.years.forEach((year) => {
              ttmSettings.quarters.forEach((quarter) => {
                const condition = {
                  year,
                  quarter,
                  indicatorType: INDICATOR_TYPE.TTM,
                }
                const stringCheckDuplicate = JSON.stringify({
                  id,
                  ...condition,
                  ...conditionCheck.ttmSettings,
                  isTtm: true,
                })

                indicators.push({
                  alias: uuid(),
                  indicatorType: INDICATOR_TYPE.TTM,
                  condition,
                  ...data.indicatorById[id],
                  stringCheckDuplicate,
                  title: condition,
                })
              })
            })
          }
        }
        const yearlySettings = data.detail.yearlySettings
        if (yearlySettings) {
          if (!yearlySettings.years) {
            const stringCheckDuplicate = JSON.stringify({
              id: pairIndicatorId || id,
              ...conditionCheck.yearlySettings,
              isYearly: true,
            })

            indicators.push({
              alias: uuid(),
              indicatorType: INDICATOR_TYPE.YEARLY,
              ...(yearlyPairIndicator || data.indicatorById[id]),
              stringCheckDuplicate,
              title: { indicatorType: INDICATOR_TYPE.YEARLY },
            })
          } else {
            yearlySettings.years.forEach((year) => {
              const condition = { year, indicatorType: INDICATOR_TYPE.YEARLY }
              const stringCheckDuplicate = JSON.stringify({
                id: pairIndicatorId || id,
                ...condition,
                ...conditionCheck.yearlySettings,
                isYearly: true,
              })

              indicators.push({
                alias: uuid(),
                indicatorType: INDICATOR_TYPE.YEARLY,
                condition,
                ...(yearlyPairIndicator || data.indicatorById[id]),
                stringCheckDuplicate,
                title: condition,
              })
            })
          }
        }
      })
      break
    }
    case typePopupIndicator.DE01_SC20:
    case typePopupIndicator.DE01_SC26: {
      const conditionCheck = deepCopy(data.detail)
      delete conditionCheck?.years
      delete conditionCheck?.quarters

      data.indicator.forEach((id) => {
        if (!data.detail.years) {
          const stringCheckDuplicate = JSON.stringify({
            id,
            ...conditionCheck,
          })

          indicators.push({
            alias: uuid(),
            ...data.indicatorById[id],
            stringCheckDuplicate,
          })
        } else {
          data.detail.years.forEach((year) => {
            data.detail.quarters.forEach((quarter) => {
              const condition = { year, quarter }
              const stringCheckDuplicate = JSON.stringify({
                id,
                ...condition,
                ...conditionCheck,
              })

              indicators.push({
                alias: uuid(),
                condition,
                ...data.indicatorById[id],
                stringCheckDuplicate,
                title: condition,
              })
            })
          })
        }
      })
      break
    }
    default:
      break
  }
  return indicators
}

export const handleNameIndicator = (
  indicator = {},
  detail,
  conditionType,
  condition,
  mostRecent,
) => {
  switch (conditionType) {
    case typePopupIndicator.DE01_SC07:
      return (
        <div>
          <Translate value="tool.dataExplorer.corporate.ICB_LEVEL" />
          {detail.icbLevel}
        </div>
      )
    case typePopupIndicator.DE01_SC08:
    case typePopupIndicator.DE01_SC27:
    case typePopupIndicator.DE01_SC28:
    case typePopupIndicator.DE01_SC29:
    case typePopupIndicator.DE01_SC30:
    case typePopupIndicator.DE01_SC31:
    case typePopupIndicator.DE01_SC32:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC09:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DIVIDEND_YEAR" />:{' '}
            {condition.year}
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.INTERIM" />:{' '}
            <Translate
              value={`tool.dataExplorer.corporate.${detail.dividendStage}`}
            />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC10:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.YEAR_OF_REVIEW" />:{' '}
            {detail.issueYear}
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.ISSUE_METHOD" />:{' '}
            <Translate value="tool.dataExplorer.corporate.STOCK_DIVIDEND" />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.EXRIGHT_DATE" />:{' '}
            <Translate value="tool.dataExplorer.corporate.MOST_RECENT" />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC11:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.YEAR_OF_REVIEW" />:{' '}
            {detail.issueYear}
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.ISSUE_METHOD" />
            :&nbsp;
            <IssueMethodName issueMethodId={detail.shareIssueMethodId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.EXRIGHT_DATE" />:{' '}
            <Translate value="tool.dataExplorer.corporate.MOST_RECENT" />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC12:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.ACTION" />
            :&nbsp;
            <ActionName actionId={detail.actionTypeId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.END_DATE" />:{' '}
            {detail.year}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC13:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.EVENT" />: AGM
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.EXRIGHT_DATE" />:{' '}
            {detail.year}
          </div>
        </div>
      )
    case typePopupIndicator.DE01_SC14:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DIVIDEND_YEAR" />:{' '}
            {condition.year}
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.INTERIM" />:{' '}
            <Translate value="tool.dataExplorer.corporate.ALL" />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC15:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.YEAR_OF_REVIEW" />:{' '}
            {detail.dividendYear}
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.ISSUE_METHOD" />:{' '}
            <Translate value="tool.dataExplorer.corporate.STOCK_DIVIDEND" />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.EXRIGHT_DATE" />:{' '}
            <Translate value="tool.dataExplorer.corporate.MOST_RECENT" />
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC16:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
            {detail.mostRecent ? (
              <DateTimeSpan date={mostRecent.date} />
            ) : (
              <DateTimeSpan date={detail.date} />
            )}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC17:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
            {detail.tradingDate ? (
              <DateTimeSpan date={detail.tradingDate} />
            ) : detail.time === 'MostRecent' ? (
              <DateTimeSpan date={mostRecent.date} />
            ) : (
              <Translate value={`tool.dataExplorer.corporate.${detail.time}`} />
            )}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC18:
    case typePopupIndicator.DE01_SC33:
    case typePopupIndicator.DE01_SC34:
    case typePopupIndicator.DE01_SC35:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            {condition?.indicatorType === INDICATOR_TYPE.YEARLY ? (
              <Translate value="tool.dataExplorer.corporate.YEARLY_RATIO" />
            ) : (
              <Translate value="tool.dataExplorer.corporate.TTM_RATIO" />
            )}
          </div>
          {condition?.year && !condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {condition.year}
            </div>
          )}
          {condition?.year && condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />: Q
              {condition.quarter}.{condition.year}
            </div>
          )}
          {!condition?.year && !condition?.quarter && (
            <div>
              {condition?.indicatorType === INDICATOR_TYPE.YEARLY ? (
                <>
                  <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
                  {mostRecent.year}
                </>
              ) : (
                <>
                  {condition?.date || mostRecent.date ? (
                    <>
                      <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
                      <DateTimeSpan date={condition?.date || mostRecent.date} />
                    </>
                  ) : (
                    <>
                      <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
                      Q{mostRecent.quarter}.{mostRecent.year}
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC19:
    case typePopupIndicator.DE01_SC36:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.DATE" />:{' '}
            {detail.mostRecent ? (
              <DateTimeSpan date={mostRecent.date} />
            ) : (
              <DateTimeSpan date={detail.date} />
            )}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC20:
    case typePopupIndicator.DE01_SC26:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate
              value={`tool.dataExplorer.corporate.${detail.reportType}`}
            />
          </div>
          {detail.audit !== 'Both' && (
            <div>
              <Translate
                value={`tool.dataExplorer.corporate.${detail.audit}`}
              />
            </div>
          )}
          {detail.period === 'MostRecentQuarter' && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
              {mostRecent.quarter}
            </div>
          )}
          {['MostRecentQuarter', 'MostRecentYear'].includes(detail.period) && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {mostRecent.year}
            </div>
          )}
          {condition?.quarter && (
            <div>
              <Translate value="tool.dataExplorer.corporate.QUARTER" />:{' '}
              <Translate
                value={`tool.dataExplorer.corporate.${condition.quarter}`}
              />
            </div>
          )}
          {condition?.year && (
            <div>
              <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
              {typeof condition.year === 'number' ? (
                condition.year
              ) : (
                <Translate
                  value={`tool.dataExplorer.corporate.${condition.year}`}
                />
              )}
            </div>
          )}
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {conditionType === typePopupIndicator.DE01_SC26 ? (
                <Translate
                  value={units.find((item) => item.value === detail.unit).name}
                />
              ) : (
                indicator.unit
              )}
            </div>
          )}
        </div>
      )
    case typePopupIndicator.DE01_SC21:
      return (
        <div>
          <div>
            <IndicatorName indicatorId={indicator.indicatorId} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.corporate.YEAR" />:{' '}
            {condition.year}
          </div>
          {indicator.unit && (
            <div>
              <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
              {indicator.unit}
            </div>
          )}
        </div>
      )
    default:
      return ''
  }
}

const newLine = '\n'
const space = ': '

export const getHeaderIndicator = (
  indicator,
  detail,
  conditionType,
  condition,
  indicatorById,
  issueMethod,
  actionType,
  locale,
  timezone,
  mostRecent,
) => {
  switch (conditionType) {
    case typePopupIndicator.DE01_SC07:
      return I18n.t('tool.dataExplorer.corporate.ICB_LEVEL') + detail.icbLevel
    case typePopupIndicator.DE01_SC08:
    case typePopupIndicator.DE01_SC27:
    case typePopupIndicator.DE01_SC28:
    case typePopupIndicator.DE01_SC29:
    case typePopupIndicator.DE01_SC30:
    case typePopupIndicator.DE01_SC31:
    case typePopupIndicator.DE01_SC32:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        (indicator.unit
          ? newLine +
            I18n.t('tool.dataExplorer.corporate.UNIT') +
            space +
            indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC09:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DIVIDEND_YEAR') +
        space +
        condition.year +
        newLine +
        I18n.t('tool.dataExplorer.corporate.INTERIM') +
        space +
        I18n.t(
          `tool.dataExplorer.corporate.${
            detail.dividendStage === 'All' ? 'ALL' : 'MOST_RECENT'
          }`,
        ) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC10:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.YEAR_OF_REVIEW') +
        space +
        detail.issueYear +
        newLine +
        I18n.t('tool.dataExplorer.corporate.ISSUE_METHOD') +
        space +
        I18n.t('tool.dataExplorer.corporate.STOCK_DIVIDEND') +
        newLine +
        I18n.t('tool.dataExplorer.corporate.EXRIGHT_DATE') +
        space +
        I18n.t('tool.dataExplorer.corporate.MOST_RECENT') +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC11:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.YEAR_OF_REVIEW') +
        space +
        detail.issueYear +
        newLine +
        I18n.t('tool.dataExplorer.corporate.ISSUE_METHOD') +
        space +
        issueMethod.find(
          (item) => item.shareIssueMethodId === detail.shareIssueMethodId,
        )?.shareIssueMethodName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.EXRIGHT_DATE') +
        space +
        I18n.t('tool.dataExplorer.corporate.MOST_RECENT') +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC12:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.ACTION') +
        space +
        actionType.find((item) => item.actionTypeId === detail.actionTypeId)
          ?.actionTypeName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.END_DATE') +
        space +
        detail.year +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC13:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.EVENT') +
        space +
        'AGM' +
        newLine +
        I18n.t('tool.dataExplorer.corporate.EXRIGHT_DATE') +
        space +
        detail.year
      )
    case typePopupIndicator.DE01_SC14:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DIVIDEND_YEAR') +
        space +
        condition.year +
        newLine +
        I18n.t('tool.dataExplorer.corporate.INTERIM') +
        space +
        I18n.t('tool.dataExplorer.corporate.ALL') +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC15:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.YEAR_OF_REVIEW') +
        space +
        detail.dividendYear +
        newLine +
        I18n.t('tool.dataExplorer.corporate.ISSUE_METHOD') +
        space +
        I18n.t('tool.dataExplorer.corporate.STOCK_DIVIDEND') +
        newLine +
        I18n.t('tool.dataExplorer.corporate.EXRIGHT_DATE') +
        space +
        I18n.t('tool.dataExplorer.corporate.MOST_RECENT') +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC16:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DATE') +
        space +
        (detail.mostRecent
          ? formatDateTime(mostRecent.date, FORMAT.DATE, locale, timezone)
          : formatDateTime(detail.date, FORMAT.DATE, locale, timezone)) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC17:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DATE') +
        space +
        (detail.time === 'MostRecent'
          ? formatDateTime(mostRecent.date, FORMAT.DATE, locale, timezone)
          : !detail.tradingDate
          ? I18n.t(`tool.dataExplorer.corporate.${detail.time}`)
          : formatDateTime(detail.tradingDate, FORMAT.DATE, locale, timezone)) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC18:
    case typePopupIndicator.DE01_SC33:
    case typePopupIndicator.DE01_SC34:
    case typePopupIndicator.DE01_SC35:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        (condition?.indicatorType === INDICATOR_TYPE.YEARLY
          ? I18n.t('tool.dataExplorer.corporate.YEARLY_RATIO')
          : I18n.t('tool.dataExplorer.corporate.TTM_RATIO')) +
        newLine +
        (condition?.year && condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            'Q' +
            condition.quarter +
            '.' +
            condition.year +
            newLine
          : '') +
        (condition?.year && !condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            condition.year +
            newLine
          : '') +
        (!condition?.year && !condition?.quarter
          ? condition?.indicatorType === INDICATOR_TYPE.YEARLY
            ? I18n.t('tool.dataExplorer.corporate.YEAR') +
              space +
              mostRecent.year +
              newLine
            : condition?.date || mostRecent.date
            ? I18n.t('tool.dataExplorer.corporate.DATE') +
              space +
              formatDateTime(
                condition?.date || mostRecent.date,
                FORMAT.DATE,
                locale,
                timezone,
              ) +
              newLine
            : I18n.t('tool.dataExplorer.corporate.QUARTER') +
              space +
              'Q' +
              mostRecent.quarter +
              '.' +
              mostRecent.year +
              newLine
          : '') +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC19:
    case typePopupIndicator.DE01_SC36:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.DATE') +
        space +
        (detail.mostRecent
          ? formatDateTime(mostRecent.date, FORMAT.DATE, locale, timezone)
          : formatDateTime(detail.date, FORMAT.DATE, locale, timezone)) +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    case typePopupIndicator.DE01_SC20:
    case typePopupIndicator.DE01_SC26:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t(`tool.dataExplorer.corporate.${detail.reportType}`) +
        newLine +
        (detail.audit !== 'Both'
          ? I18n.t(`tool.dataExplorer.corporate.${detail.audit}`) + newLine
          : '') +
        (detail.period === 'MostRecentQuarter'
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            mostRecent.quarter +
            newLine
          : '') +
        (['MostRecentQuarter', 'MostRecentYear'].includes(detail.period)
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            mostRecent.year +
            newLine
          : '') +
        (condition?.quarter
          ? I18n.t('tool.dataExplorer.corporate.QUARTER') +
            space +
            I18n.t(`tool.dataExplorer.corporate.${condition.quarter}`) +
            newLine
          : '') +
        (condition?.year
          ? I18n.t('tool.dataExplorer.corporate.YEAR') +
            space +
            (typeof condition.year === 'number'
              ? condition.year
              : I18n.t(`tool.dataExplorer.corporate.${condition.year}`)) +
            newLine
          : '') +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') +
            space +
            (conditionType === typePopupIndicator.DE01_SC26
              ? I18n.t(units.find((item) => item.value === detail.unit).name)
              : indicator.unit)
          : '')
      )
    case typePopupIndicator.DE01_SC21:
      return (
        indicatorById[indicator.indicatorId]?.indicatorName +
        newLine +
        I18n.t('tool.dataExplorer.corporate.YEAR') +
        space +
        condition.year +
        newLine +
        (indicator.unit
          ? I18n.t('tool.dataExplorer.corporate.UNIT') + space + indicator.unit
          : '')
      )
    default:
      return ''
  }
}

export const decodeId = (id) => {
  if (typeof id === 'string' && id.includes('_')) {
    return +id.split('_')[0]
  }
  return id
}

export const convertDataId = (id, dataType) => {
  const newId = decodeId(id)
  return { id: newId, dataType }
}

export const sortFiinXTemplateResult = (data, typeTemplate, columnById) => {
  switch (typeTemplate) {
    case typePopupTemplate.CORPORATE_BOND_ISSUANCE:
      return data.sort(sortDataResult(findAliasSort(columnById, 62000)))
    case typePopupTemplate.SHARE_ISSUANCE:
      return data.sort(sortDataResult(findAliasSort(columnById, 57800)))
    case typePopupTemplate.CASH_DIVIDEND_PAYOUT:
      return data.sort(sortDataResult(findAliasSort(columnById, 57900)))
    case typePopupTemplate.INITIAL_PUBLIC_OFFERING:
      return data.sort(sortDataResult(findAliasSort(columnById, 63700)))
    default:
      return data
  }
}

const findAliasSort = (columnById, sortIndicatorId) => {
  return Object.keys(columnById).find(
    (alias) => columnById[alias].indicatorId === sortIndicatorId,
  )
}

const sortDataResult = (alias) => (item1, item2) => {
  const value1 = item1.items[alias]
  const value2 = item2.items[alias]

  if (isInValidValueCompare(value1)) {
    return 1
  }
  if (isInValidValueCompare(value2)) {
    return -1
  }
  return value2 < value1 ? -1 : 1
}

const isInValidValueCompare = (value) => {
  return isInValidValue(value) || value === EMPTY_VALUE
}
