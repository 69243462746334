import FundHoldingProxy from '../../proxies/fund/FundHoldingProxy'
import { ServiceBase } from '../ServiceBase'

export class FundHoldingService extends ServiceBase {
  getListTickersSelect(params) {
    return this.applyMemoryCache(
      'FundHoldingProxy.getListTickersSelect',
      params,
    )(() => FundHoldingProxy.getListTickersSelect(params))
  }

  getAllFundHolding(params) {
    return this.applyMemoryCache(
      'FundHoldingProxy.getAllFundHolding',
      params,
    )(() => FundHoldingProxy.getAllFundHolding(params))
  }

  downloadAllFundHolding(params) {
    return this.getFileDownload(() =>
      FundHoldingProxy.downloadAllFundHolding(params),
    )
  }

  getDetailFundHolding(params) {
    return this.applyMemoryCache(
      'FundHoldingProxy.getDetailFundHolding',
      params,
    )(() => FundHoldingProxy.getDetailFundHolding(params))
  }

  downloadDetailFundHolding(params) {
    return this.getFileDownload(() =>
      FundHoldingProxy.downloadDetailFundHolding(params),
    )
  }
}

export default new FundHoldingService()
