export const steel = {
  STEEL: 'Steel',
  OVERVIEW_TAB: 'Overview',
  CONSTRUCTION_TAB: 'Steel Construction',
  STEEL_PIPE_TAB: 'Steel Pipe',
  STEEL_COAT_TAB: 'Steel Coat',
  STEEL_COIL_TAB: 'Steel Coil',
  PRICE_TAB: 'Price',

  UNIT: 'Unit',

  STATISTICS: 'Steel Statistics',
  PEERS_COMPARISON: 'Peers Comparison',
  COMPANY_GROWTH: `Company's Growth`,

  FILTER_MONTHLY: 'Monthly',
  FILTER_YEARLY: 'Yearly',
  FILTER_ACC_MONTHLY: 'Acc Monthly',

  MILLION_TONS: 'Million tons',
  TONS: 'Tons',
  PERCENT: '%',
  ALL: 'All',

  COMPANY_NAME: 'Company Name',
  TICKER: 'Ticker',
  PRODUCTION: 'Production',
  CONSUMPTION: 'Consumption',
  TOTAL: 'Total',
  NORTH: 'North',
  CENTRAL: 'Central',
  SOUTH: 'South',
  EXPORT: 'Export',
  INVENTORY: 'Inventory',

  WARNING_MESS:
    'The number of selected items is no more than 5. Please untick an item before adding another to the chart.',
}

export const overview = {
  OVERVIEW: 'Overview',
  NEWS_AND_REPORT: 'News & Reports',
  STEEL_PRODUCTION_AND_CONSUMPTION: 'Steel production and consumption',
  PRODUCTION_AND_CONSUMPTION: 'Production and consumption',
  INVENTORY: 'Inventory',
  STEEL_INVENTORY: 'Steel Inventory',
  EXPORT: 'Export',
  STEEL_EXPORT: 'Steel Export',

  HISTORY: 'History of Industry:',
  PRODUCT: 'Main Product:',
  ROLE: 'The Role of Industry:',

  FILTER_RADIO_NEWS: 'News',
  FILTER_RADIO_REPORTS: 'Reports',

  MILLION_USD: 'Million USD',
  MILLION_TONS: 'Million tons',

  FOOTER_PRODUCTION: 'Production',
  FOOTER_CONSUMPTION: 'Consumption',
  TOOLTIP_INVENTORY: 'Inventory',
  TOOLTIP_EXPORT: 'Export',
}

export const construction = {
  TOTAL: 'Total',
  STEEL_BAR: 'Steel bar',
  STEEL_SHAPE: 'Steel shape',
  STEEL_WIRE_ROD: 'Steel wire rod',
}

export const steelPipe = {
  TOTAL: 'Total',
  BLACK_PIPE: 'Steel Black pipe',
  COATED_PIPE: 'Steel Coated pipe',
}

export const steelCoat = {
  TOTAL: 'Total',
  STEEL_GAL: 'Steel Gal',
  STEEL_PAINTED: 'Steel Painted',
  OTHER: 'Steel Other',
}

export const steelCoil = {
  TOTAL: 'Total',
  HOT_ROLL: 'Hot Roll Steel Coil',
  COOL_ROLL: 'Cool Roll Steel Coil',
}

export const price = {
  PRICE_STATISTICS: 'Price Statistics',
  STEEL_PRICE_GROWTH: 'Steel Price Growth',

  FILTER_VALUE: 'Value',
  FILTER_PERCENT_GROWTH: '% Growth',

  PRODUCT: 'Product',
  TICKER: 'Ticker',

  WARNING_MESS: 'The number of selected items is no more than 20.',
  TABLE_INFO:
    'The steel price is the average price publicized by the manufacturing companies',
}

export const fishery = {
  FISHERY: 'Fishery',
  OVERVIEW: 'Overview',
  EXPORT_BY_PRODUCT: 'Export by product',
  EXPORT_BY_MARKET: 'Export by market',
  EXPORTERS: 'Exporters',

  UNIT: 'Unit',
  MILLION_USD: 'Million USD',
  USD: 'USD',
  PERCENT: '%',

  FILTER_MONTHLY: 'Monthly',
  FILTER_YEARLY: 'Yearly',
  FILTER_ACC_MONTHLY: 'Acc Monthly',

  PRODUCT: 'Product',
  MARKET: 'Market',

  OTHER: 'Others',
  ALL: 'All',

  VALUE: 'Value',
  GROWTH: 'Growth YoY',

  overview: {
    OVERVIEW: 'Overview',
    NEWS_AND_REPORT: 'News & Reports',
    EXPORT: 'Fishery Export',
    EXPORT_YEARLY: 'Seafood Export by Year',
    EXPORT_PRODUCT: 'Seafood Export by Product',
    EXPORT_ONLY: 'Export',
    IMPORT: 'Fishery Import',
    IMPORT_ONLY: 'Import',
    TOP_MARKET: 'Top 5 Export Markets of Vietnam',
    TOP_FIVE: 'Top 5',
    MARKET: 'Export Markets of Vietnam',

    TITLE_FIRST: 'History:',
    TITLE_SECOND: 'Primary products:',
    TITLE_THIRD: 'Role:',

    FILTER_RADIO_NEWS: 'News',
    FILTER_RADIO_REPORTS: 'Reports',
  },

  export: {
    EXPORT_STATISTICS: 'Export Statistics',
    GROWTH_PRODUCT: 'Export Growth by Product',
    CONTRIBUTION_PRODUCT: 'Contribution by Product',
    GROWTH_MARKET: 'Export Growth by Market',
    CONTRIBUTION_MARKET: 'Contribution by Market',
    CONTRIBUTION_BY: 'Exporting Contribution to',
    CONTRIBUTION: 'Contribution by',

    PRODUCT: 'Product',
    MARKET: 'Market',
  },

  exporters: {
    EXPORT_STATISTICS: 'Export Statistics',
    GROWTH_EXPORTERS: 'Export Growth by Exporters',
    TOP_EXPORTERS: 'Top 5 Exporters',
    TICKER: 'Ticker',
  },
}
