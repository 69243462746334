import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../common/ScrollComponent'
import { CorNewReport } from './CorNewReport'
import style from './index.module.css'
import { MarNewReport } from './MarNewReport'
import { SecNewReport } from './SecNewReport'

export const SearchNew = (props) => {
  const { searchStr } = props

  return (
    <>
      <div className={`header-1 ${style['header-1']}`}>
        <Translate value="search.fullSearch.NEWS" />
      </div>
      <ScrollComponent
        appendStyle={{ height: 'calc(100% - 28px)' }}
        horizontalTrackWidth={0}
      >
        <CorNewReport searchStr={searchStr} />
        <SecNewReport searchStr={searchStr} />
        <MarNewReport searchStr={searchStr} />
      </ScrollComponent>
    </>
  )
}

SearchNew.propTypes = {
  searchStr: PropTypes.string.isRequired,
}
