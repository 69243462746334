import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import TableHeader from './TableHeader'
import { tableConstants } from './constants'
import style from './index.module.css'
import { selectDataValue, selectIds, selectLoading } from './store/slice'

const TableInformation = ({ width }) => {
  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoading)
  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  if (isLoading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }

  const renderTable = () => {
    return (
      <Table
        ids={ids}
        getDataFromRedux={selectDataValue}
        isLoading={isLoading}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(tableConstants, stateSort, sortColumn)
        }
        schema={tableConstants.map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key
          let result = {
            colId,
            title,
          }
          if (colId === 'ticker') {
            result = {
              ...result,
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td className={rowId === 'total' && style.rowTotal}>
                    <div
                      style={{ ...item?.attrs?.style, width: `${width / 2}px` }}
                      className={rowId === 'total' && style.borderTopLight}
                    >
                      {rowId === 'total' ? (
                        <Span
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            fontStyle: 'italic',
                          }}
                        >
                          <TextEllipsis
                            text={'bond.interconnectionMap.table.table.Total'}
                          />
                        </Span>
                      ) : (
                        <Span style={{ fontSize: 12, fontWeight: 340 }}>
                          <TextEllipsis text={value} isI18n={false} />
                        </Span>
                      )}
                    </div>
                  </td>
                )
              },
            }
          }
          if (colId === 'outstandingValue') {
            result = {
              ...result,
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td className={rowId === 'total' && style.rowTotal}>
                    <div
                      style={{ ...item?.attrs?.style }}
                      className={rowId === 'total' && style.borderTopLight}
                    >
                      {rowId === 'total' ? (
                        <Span
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            fontStyle: 'italic',
                          }}
                        >
                          <TextEllipsis
                            text={formatVal(valDivBillion(value))}
                            isI18n={false}
                          />
                        </Span>
                      ) : (
                        <Span style={{ fontSize: 12, fontWeight: 340 }}>
                          <TextEllipsis
                            text={formatVal(valDivBillion(value))}
                            isI18n={false}
                          />
                        </Span>
                      )}
                    </div>
                  </td>
                )
              },
            }
          }
          return result
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        stickyBottomRowCount={1}
      />
    )
  }

  return renderTable()
}

export default TableInformation
