const financialChart = {
  setting: {
    SET_AS_DEFAULT_SETTING: 'Đặt làm cài đặt mặc định',
    GRID_DISPLAY: 'Nền dạng lưới',
    VIEW_BY_LATEST_PERIOD: 'Xem theo kỳ mới nhất',
    Y_AXIS_LABEL: 'Tiêu đề trục Y:',
    Y_AXIS_POSITION: 'Định dạng trục Y:',
  },
  chartSettings: {
    MEAN: 'Trung bình',
    PLUS_ONE_SD: '+1 Standard Deviation',
    MINUS_ONE_SD: '-1 Standard Deviation',
    PLUS_TWO_SD: '+2 Standard Deviation',
    MINUS_TWO_SD: '-2 Standard Deviation',
    MEDIAN: 'Trung vị',
    HIGHEST: 'Cao nhất',
    LOWEST: 'Thấp nhất',
    GROWTH_PERCENT: 'Tăng trưởng (%):',
    GROWTH_NOT_COUNT: 'Không',
    GROWTH_ORIGINAL_PERIOD: 'Kỳ gốc',
    GROWTH_PREVIOUS_PERIOD: 'Kỳ trước',
    MULTIPLIER: 'Đơn vị',
    DECIMAL_PLACES: 'Số chữ số thập phân:',
    DATA_FREQUENCY: 'Kỳ dữ liệu:',
    CHART_COLOR: 'Màu sắc:',
    FAVORITE_COLOR: 'Màu yêu thích:',
    STATISTICAL_METHODS: 'Số liệu thống kê:',
    DATA_LABEL: 'Hiển thị dữ liệu',
  },
  table: {
    UNIT: 'Đơn vị: ',
  },
  RAW: 'Số gốc',
  THOUSAND: 'Nghìn',
  MILLION: 'Triệu',
  BILLION: 'Tỷ',
  TRILLION: 'N.Tỷ',
  VALUE: 'Giá trị',
  PERCENT: 'Tăng trưởng',
  LINE_STYLE: 'Kiểu nét',
  LINE_WIDTH: 'Độ dày',
  DOTS_ON_LINE: 'Điểm nối',
  SMOOTH_LINE: 'Làm mịn',
  SOLID: 'Liền',
  DASHED: 'Đứt',
  DOTTED: 'Chấm',
  THIN: 'Mỏng',
  NORMAL: 'Vừa',
  THICK: 'Dày',
  YES: 'Có',
  NO: 'Không',
  SUGGEST_COLOR: 'Màu đề xuất',
  FAVORITE_COLOR: 'Màu yêu thích',
  NEW_DASHBOARD_NAME: 'Dashboard mới',
  NEW_GROUP_DASHBOARD_NAME: 'Nhóm dashboard mới',
  FINANCIAL_CHART: 'Biểu đồ phân tích tài chính',
  DOWNLOADING: 'Đang tải biểu đồ, vui lòng đợi...',
  DOWNLOADING_EXCEL: 'Đang tải excel, vui lòng đợi...',
  ADD_EQUATION: 'Thêm công thức',
  SUM_AVG_COUNT: 'Tổng/Trung bình/Đếm',
  ADD_SECURITIES: 'Thêm chủ thể',
  MULTIPLE_CHOICE: 'Chọn nhiều chủ thể',
  LIST_SECURITIES: 'Danh sách chủ thể đã chọn',
  ADD_INDICATORS: 'Thêm chỉ tiêu',
  LIST_INDICATORS: 'Danh sách các chỉ tiêu đã chọn',
  APPLY: 'Áp dụng',
  CANCEL: 'Hủy',
  SUM: 'Tổng',
  AVG: 'Trung bình',
  COUNT: 'Đếm',
  SECURITIES_1: 'Các chủ thể:',
  INDICATORS_1: 'Các chỉ tiêu:',
  SEARCH: 'Tìm kiếm',
  INDICATOR_NAME: 'Tên chỉ tiêu:',
  ALL: 'Tât cả',
  COMPANIES: 'Doanh nghiệp',
  INDICES: 'Chỉ số',
  SECTOR: 'Ngành',
  ECONOMY: 'Vĩ mô',
  BOND: 'Trái phiếu',
  WATCH_LIST: 'Danh mục',
  SEARCH_SECURITIES: 'Tìm kiếm chủ thể',
  ALERT_DELETE_CHART: 'Bạn chắc chắn muốn xóa vĩnh viễn Biểu đồ?',
  BTN_DELETE: 'Xóa',
  BTN_CANCEL: 'Hủy bỏ',
  BTN_RESET: 'Đặt lại',
  SHARE_CHART: 'Chia sẻ biểu đồ',
  COPY_URL: 'Sao chép',
  COPIED_URL: 'Đã sao chép',
  DELETE_CHART: 'Xoá biểu đồ',
  SECURITIES: 'Chủ thể',
  MESS_CHART_SPACE: 'Bắt đầu tạo biểu đồ!',
  MESS_CHART_SPACE_1: 'Thêm mới chủ thể và chỉ tiêu để tạo biểu đồ!',
  HISTORICAL_PRICE: 'Giá lịch sử',
  MORE_INDICATORS: 'Thêm chỉ tiêu',
  NEW_CHART: 'Biểu đồ mới',
  NEW_DASHBOARD: 'Tạo Dashboard',
  X_AXIS: 'Trục X:',
  BY_TIME: 'Thời gian',
  BY_SECURITIES: 'Chủ thể',
  FROM: 'Từ',
  TO: 'Đến',
  CLEAR: 'Xóa',
  DAILY: 'Hàng ngày',
  WEEKLY: 'Hàng tuần',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  SAVE: 'Lưu',
  SELECTED: 'Đã chọn: ',
  WANNING_ADD_SECURITIES: 'Số lượng chỉ tiêu được chọn không vượt quá 30.',
  SELECTED_ITEMS: 'Đã chọn',
  SEARCH_INDICATORS: 'Tìm kiếm chỉ tiêu',
  NEW_DASHBOARD_GROUP: 'Thêm Nhóm Dashboard',
  CHART: 'Biểu đồ',
  ALL_EXCHANGE: 'Tất cả sàn',
  EQUATION: 'Công thức',
  EQUATION_FROM_VALUE: 'Từ giá trị',
  EQUATION_TO_VALUE: 'Đến giá trị',
  EQUATION_UNIT: 'Đơn vị',
  EQUATION_DATE: 'Kỳ',
  EDIT_CHART: 'Chỉnh sửa biểu đồ',
  EQUATION_SELECTED_ITEM_NO_MORE_THAN:
    'Số lượng chỉ tiêu được chọn không vượt quá 30.',
  EQUATION_INDICATORS_NOT_SAME_UNIT:
    'Các chỉ số đã chọn không cùng đơn vị. Hãy thử lại!',
  EQUATION_SECURITIES_INDICATORS_REQUIRED:
    'Hãy chọn chủ thể và chỉ tiêu trước khi áp dụng các công thức.',
  SAVE_AS: 'Lưu thành',
  WARNING_CANCEL_EDIT_DASHBOARD:
    'Bạn có muốn lưu những thay đổi trước khi chuyển đến tính năng khác không?',
  NUMBER_OF_CHART: 'Số lượng biểu đồ: ',
  TEXT_DISABLE_LAYOUT:
    'Số lượng chart hiện tại vượt quá so với layout bạn chọn. Hãy xóa số lượng chart phù hợp trước khi đổi layout này.',
  EDIT_LAYOUT: 'Sửa bố cục',
  CHOOSE_LAYOUT: 'Chọn bố cục:',
  REPOSITION_CHART: 'Đổi vị trí biểu đồ:',
  ICB_LEVEL_1: 'Ngành cấp 1',
  ICB_LEVEL_2: 'Ngành cấp 2',
  ICB_LEVEL_3: 'Ngành cấp 3',
  ICB_LEVEL_4: 'Ngành cấp 4',
  ICB_LEVEL_5: 'Ngành cấp 5',
  COPY_CHART_TO_NEW_DASHBOARD: 'Sao chép đến Dashboard mới',
  CHART_TITLE: 'Tiêu đề Chart',
  NEW_DASHBOARD_1: 'Tên Dashboard mới',
  OK: 'OK',
  COPY_CHART_TO_NEW_DASHBOARD_SUCCESS: 'Biểu đồ đã được lưu vào ',
  COPY_CHART_TO_NEW_DASHBOARD_SUCCESS_1: ' thành công',
  WARNING_DB_IS_OUT_OF_SPACE:
    'Dashboard không còn đủ chỗ trống. Hãy xóa biểu đồ trong Dashboard hoặc sao chép đến Dashboard khác.',
  DUPLICATE_DASHBOARD: 'Nhân đôi Dashboard',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_CHART:
    'Hãy lưu những thay đổi của bạn trước khi tải xuống biểu đồ.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_CHART:
    'Hãy lưu những thay đổi của bạn trước khi chia sẻ biểu đồ.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DUPLICATING_DASHBOARD:
    'Hãy lưu những thay đổi của bạn trước khi nhân đôi Dashboard.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_DASHBOARD:
    'Hãy lưu những thay đổi của bạn trước khi tải xuống Dashboard.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_DASHBOARD:
    'Hãy lưu những thay đổi của bạn trước khi chia sẻ Dashboard.',
  EDIT: 'Chỉnh sửa',
  LINK_CHART: 'Liên kết',
  ADD_CHART: 'Thêm Biểu đồ',
  REPLACE_CHART: 'Thay thế',
  RENAME_CHART: 'Đổi tên',
  DUPLICATE_CHART: 'Nhân đôi',
  COPY_TO: 'Sao chép đến',
  DOWNLOAD_CHART: 'Tải xuống',
  SHARE_CHART1: 'Chia sẻ',
  DELETE_CHART1: 'Xóa',
  CONFIRM_DELETE: 'Bạn chắc chắn muốn xóa vĩnh viễn ',
  UNLINK_CHART: 'Bỏ liên kết',
  LINK_CHART_DESC:
    'Các biểu đồ chứa một chủ thể công ty có thể được liên kết với nhau để đồng bộ',
  SEARCH_COMPANY: 'Tìm kiếm công ty',
  LINKED_SECURITIES: 'Các chủ thể liên kết',
  REPLACE: 'Thay thế',
  ADD: 'Thêm',
  MY_CHART_LIBRARY: 'Thư viện biểu đồ',
  SELECT: 'Chọn',
  SELECT_ALL: 'Chọn tất cả',
  SHARE: 'Chia sẻ',
  QUARTER_1: 'Quý 1',
  QUARTER_2: 'Quý 2',
  QUARTER_3: 'Quý 3',
  QUARTER_4: 'Quý 4',
  MONTH_1: 'Th01',
  MONTH_2: 'Th02',
  MONTH_3: 'Th03',
  MONTH_4: 'Th04',
  MONTH_5: 'Th05',
  MONTH_6: 'Th06',
  MONTH_7: 'Th07',
  MONTH_8: 'Th08',
  MONTH_9: 'Th09',
  MONTH_10: 'Th10',
  MONTH_11: 'Th11',
  MONTH_12: 'Th12',
  Y_AXIS: 'Trục Y:',
  LEGEND_FOOTER: 'Tiêu đề trục X:',
  Y_AXIS_LABEL: 'Tiêu đề:',
  GROUP_BY_YEAR: 'Nhóm theo năm',
  GROUP_AXIS: 'Gộp trục',
  Y_AXIS_POSITION: 'Định dạng:',
  SET_DEFAULT_COLOR: 'Đặt màu mặc định',
  TITLE_CHART: 'Tiêu đề biểu đồ:',
  LEGEND_Y_AXIS: 'Chú thích trục Y',
  RIGHT: 'Căn phải',
  LEFT: 'Căn trái',
  CUSTOM: 'Tùy chỉnh',
  NEXT_TO_Y_AXIS: 'Cạnh trục',
  ABOVE_Y_AXIS: 'Phía trên trục',
  SAVE_AS_DASHBOARD: 'Lưu dưới dạng trang tổng quan',
  CHANGE_POSITION_YAXIS: 'Thay đổi vị trí trục Y',
  NAME_OF_DASHBOARD: 'Tên bảng điều khiển',
  ADD_SECURITIES_AND_INDICATORS: 'Thêm mới Chủ thể và Chỉ tiêu để vẽ biểu đồ!',
  VIEW: ' Lượt xem',
  EXCHANGE: 'Sàn',
  ALL_SECTOR: 'Tất cả ngành',
  SAVE_CHART: 'Lưu biểu đồ',
  DOWNLOAD_DASHBOARD: 'Tải dashboard',
  SHARE_DASHBOARD: 'Chia sẻ dashboard',
  DELETE_DASHBOARD: 'Xóa dashboard',
  RENAME: 'Đổi tên',
  DUPLICATE: 'Tạo bản sao',
  DELETE: 'Xóa',
  UNSET_AS_DEFAULT: 'Bỏ đặt làm mặc định',
  SET_AS_DEFAULT: 'Đặt làm mặc định',
  NONE: 'Trống',
  MOVE_TO: 'Di chuyển tới',
  DB_IS_OUT_OF_SPACE:
    'Dashboard không còn đủ chỗ trống. Hãy xóa biểu đồ trong Dashboard hoặc sao chép đến Dashboard khác.',
  CHART_TYPE: 'Loại biểu đồ:',
  DOT_TYPE: 'Kiểu nối:',
  X_AXIS_SETTING: 'Định dạng trục X:',
  ADD_MORE_SECURITIES: 'Thêm chủ thể',
  SHOW_TABLE: 'Bảng dữ liệu',
  PIN_TOOLTIP: 'Ghim Tooltip',
  GENERAL_SETTING: 'Cài đặt chung',
  DOWNLOAD: 'Tải xuống',
  DOWNLOAD_CSV: 'Tải xuống excel',
  DOWNLOAD_CSV_NOTE: 'Tính năng không hỗ trợ với công thức tùy biến',
  DONT_SAVE: 'Không lưu',
  ROTATE_TEXT: 'Xoay văn bản',
  UNIT_FIRMS: 'Doanh nghiệp',
  EQUATION_FORMULA: '*Công thức:',
  EQUATION_FORMULA_SUPPORT:
    '(*) Công thức hỗ trợ các phép tính: + , - , / , * , ( , ). Ví dụ: (A+B)/C-D hay A+B*C-D*2',
  EQUATION_FORMULA_NOTE:
    'Lưu ý: Từng chỉ số sẽ được CỘNG tổng trước khi thực hiện phép tính trên.',
  EQUATION_FORMULA_INVALID:
    'Công thức chứa chỉ tiêu không hợp lệ, vui lòng nhập lại.',
  MESS_SAVE_CHART: 'Biểu đồ đã được lưu vào ',
  MESS_SAVE_CHART_1: ' thành công!',
  EQUATION_FORMAT_VND: 'VND',
  EQUATION_FORMAT_PERCENT: '%',
  EQUATION_FORMAT_TIMES: 'Lần',
  EQUATION_COUNT_UNIT_FIRMS: 'Doanh nghiệp',
  EQUATION_NAME_REQUIRED: 'Vui lòng điền tên công thức trước khi áp dụng.',
  BACK: 'Quay lại',
  RESET_MESSAGE_CONFIRM: 'Bạn có muốn xóa toàn bộ cấu hình?',
  MESS_INFO_QUARTERLY_MONTHLY:
    'Đơn vị Quý: -4 kỳ tương ứng Quý cùng kỳ năm trước. \nĐơn vị Tháng: -12 kỳ tương ứng Tháng cùng kỳ năm trước.',
  EQUATION_INDICATOR_LIMIT: 'Số lượng chỉ tiêu không vượt quá 26.',

  BOND_TYPE: 'Loại trái phiếu',
  ISSUE_METHOD: 'Hình thức phát hành',
  ACTIVE_STATUS: 'Trạng thái trái phiếu',
  STOCK: 'Cổ phiếu',

  BOND_TICKER: 'Mã TP',
  TICKER: 'Mã TCPH',
  ISSUER: 'TCPH',
  BOND_SECTOR: 'Ngành',
  TRADING_STATUS: 'Trạng thái TP',
}

export default financialChart
