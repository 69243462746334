import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { handleComboChartData, handleKeyWithTitle } from '../helper'
import { getPurchasingManagersIndex } from './thunk'

const initialState = {
  loading: true,
  chartData: [],
  keyWithTitle: {},
  timeRangeYearly: 'SevenYears',
  timeRangeAcc: 'ThreeYears',
}

export const slice = createSlice({
  name: 'economy/productionAndConsumption/product/purchaseIndex',
  initialState,
  reducers: {
    handleTimeRangeYearly: (state, action) => {
      state.timeRangeYearly = action.payload
    },
    handleTimeRangeAcc: (state, action) => {
      state.timeRangeAcc = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchasingManagersIndex.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPurchasingManagersIndex.fulfilled, (state, action) => {
      state.loading = false
      state.chartData = handleComboChartData(action.payload || [])
      state.keyWithTitle = handleKeyWithTitle(action.payload || [])
    })
    builder.addCase(getPurchasingManagersIndex.rejected, (state, action) => {
      state.loading = action.payload.isLoading
    })
  },
})

export const { handleTimeRangeYearly, handleTimeRangeAcc } = slice.actions

export const selectLoading = (state) => state[slice.name].loading
export const selectChartData = (state) => state[slice.name].chartData
export const getKeyWithTitle = (state) => state[slice.name].keyWithTitle
export const getTimeRangeYearly = (state) => state[slice.name].timeRangeYearly
export const getTimeRangeAcc = (state) => state[slice.name].timeRangeAcc

register(slice.name, slice.reducer)
