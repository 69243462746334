import { SizeTracker } from '../../../common/sizeTracker'
import HeaderPanel from './HeaderPanel'
import TableComponent from './TableComponent'

const UpcomingEvents = ({ height }) => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <HeaderPanel />
            {size.height && <TableComponent height={height - size.height} />}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default UpcomingEvents
