import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'

export const getOverviewOwnershipChart = createAsyncThunk(
  'corporate/ownership/chart/GET_OVERVIEW_OWNERSHIP_CHART',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getOverviewOwnershipChart(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getMajorOwnershipChart = createAsyncThunk(
  'corporate/ownership/chart/GET_MAJOR_OWNERSHIP_CHART',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getMajorOwnershipChart(data)

    if (response.success) {
      return response.data
    }

    return rejectWithValue('Error')
  },
)
