import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import {
  getCheckboxChecked,
  getTableData,
  getTableDataLoading,
  getTimeRange as getTimeFrequency,
} from '../panelExchangeRate/store/slice'
import Header from './Header'
import LineChart from './LineChart'
import { formatMonthYear } from './helper'
import {
  getDropdownValue,
  getLineChartData,
  getLineChartLoading,
  getTimeRangeDaily,
  getTimeRangeMonthly,
  getTimeRangeWeekly,
} from './store/slice'
import { getChangeOfExchangeRate } from './store/thunk'

const ChartComponent = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()

  const lineChartData = useSelector(getLineChartData)
  const lineChartLoading = useSelector(getLineChartLoading)
  const timeFrequency = useSelector(getTimeFrequency)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const locale = useSelector((state) => state.i18n.locale)
  const dropdownValue = useSelector(getDropdownValue)
  const timeZone = UseTimeZone()
  const tableLoading = useSelector(getTableDataLoading)
  const tableData = useSelector(getTableData)
  const timeRangeDaily = useSelector(getTimeRangeDaily)
  const timeRangeWeekly = useSelector(getTimeRangeWeekly)
  const timeRangeMonthly = useSelector(getTimeRangeMonthly)

  let timeRange = undefined

  switch (timeFrequency) {
    case 'Daily':
      timeRange = timeRangeDaily
      break
    case 'Weekly':
      timeRange = timeRangeWeekly
      break
    default:
      timeRange = timeRangeMonthly
      break
  }

  const filterCheckboxChecked = checkboxChecked.map((item) => {
    return {
      id: item.baseCurrencyId,
      organizationId: item.sourceOrganizationId,
      isCentralRate: item.isCentralRate,
    }
  })
  const checkboxCheckedString = filterCheckboxChecked.toString()

  const formatLineChartDataWithDate = lineChartData
    .map((item) => {
      return {
        ...item,
        day: formatDateTime(item.day, FORMAT.DATE, locale, timeZone),
        monthYear: formatMonthYear(item.day),
      }
    })
    .sort((a, b) =>
      moment(a.day, FORMAT.DATE[locale]).diff(
        moment(b.day, FORMAT.DATE[locale]),
      ),
    )

  useEffect(() => {
    if (!tableLoading) {
      dispatch(
        getChangeOfExchangeRate({
          timeFrequency,
          baseCurrencyIds: filterCheckboxChecked,
          timeRange,
        }),
      )
    }
  }, [
    timeFrequency,
    timeRangeDaily,
    timeRangeWeekly,
    timeRangeMonthly,
    locale,
    checkboxCheckedString,
  ])

  if (lineChartLoading) {
    return <Loading />
  }

  if (!lineChartData.length || Object.keys(tableData).length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header />
            {typeof sizes.height === 'number' && (
              <LineChart
                width={width}
                height={height - sizes.height}
                data={formatLineChartDataWithDate}
                keyXAxis={timeFrequency === 'Monthly' ? 'monthYear' : 'day'}
                keyYAxisProps={checkboxChecked.map(
                  (item) => `${item.id}-${dropdownValue}`,
                )}
                dropdownValue={dropdownValue}
                isFullScreen={isFullScreen}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
