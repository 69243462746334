import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectTimeRange, selectYear } from '../store/slice'
import { downloadIncomeStructure } from '../store/thunk'
import Container from './Container'

const PanelTable = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const year = useSelector(selectYear)
  const timeRange = useSelector(selectTimeRange)

  const downloadCsv = () => {
    const params = {
      OrganizationId: basicInfo.organizationId,
      Year: year,
      TimeRange: timeRange,
    }

    return downloadIncomeStructure(params)
  }

  return (
    <Panel
      title={'corporate.businessModel.segmentalAnalysis.INCOME_STRUCTURE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelTable
