import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { COM_GROUP } from '../../../../constants/Group'
import {
  formatVal,
  valDivTrillion,
  valToPercent,
} from '../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  barKey,
  typeSector,
}) => {
  const dataFormat = formatDataChart(data, barKey, lineKeys, typeSector)

  const renderTooltip = (tooltipData) => {
    const contentTooltip =
      typeSector === COM_GROUP.BANK
        ? [
            {
              label: I18n.t(`sector.sectorConstituents.overview.LDR`),
              value: valToPercent(tooltipData[barKey], true),
            },
            {
              label: I18n.t(`sector.sectorConstituents.overview.LOAN_GROWTH`),
              value: valToPercent(tooltipData[lineKeys[0]], true),
            },
            {
              label: I18n.t(
                `sector.sectorConstituents.overview.DEPOSIT_GROWTH`,
              ),
              value: valToPercent(tooltipData[lineKeys[1]], true),
            },
          ]
        : typeSector === COM_GROUP.SECURITIES
        ? [
            {
              label: I18n.t(
                `sector.sectorConstituents.overview.MARGIN_LENDING`,
              ),
              value:
                formatVal(tooltipData[barKey]) +
                I18n.t(
                  `sector.sectorConstituents.overview.TRILLION_VND_TOOLTIP`,
                ),
            },
            {
              label: I18n.t(`sector.sectorConstituents.overview.YOY_GROWTH`),
              value: valToPercent(tooltipData[lineKeys[0]], true),
            },
          ]
        : [
            {
              label: I18n.t(`sector.sectorConstituents.overview.REVENUE`),
              value:
                formatVal(tooltipData[barKey]) +
                I18n.t(
                  `sector.sectorConstituents.overview.TRILLION_VND_TOOLTIP`,
                ),
            },
            {
              label: I18n.t(
                `sector.sectorConstituents.overview.REVENUE_GROWTH`,
              ),
              value: valToPercent(tooltipData[lineKeys[0]], true),
            },
          ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.year}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(el.label) + ' :'}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={dataFormat}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      margin={{
        ...MARGIN_RECHARTS,
        left: typeSector === COM_GROUP.BANK ? 12 : 0,
        right: 14,
      }}
      yAxis={[
        {
          id: 'left',
          keys: [barKey],
          orientation: 'left',
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label:
            typeSector === COM_GROUP.BANK
              ? I18n.t('sector.sectorConstituents.overview.PERCENT_LDR')
              : I18n.t('sector.sectorConstituents.overview.TRILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: typeSector === COM_GROUP.BANK ? '%' : '',
        },
        {
          id: 'right',
          keys: lineKeys,
          orientation: 'right',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          label:
            typeSector === COM_GROUP.BANK
              ? I18n.t('sector.sectorConstituents.overview.PERCENT_GROWTH')
              : '',
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          unitYAxis: '%',
        },
      ]}
      renderCustomTooltip={renderTooltip}
    >
      <Bar
        isAnimationActive={false}
        yAxisId="left"
        dataKey={barKey}
        barSize={getColumnSizeInBarChart(width, dataFormat.length, 30)}
        fill="#facc5c"
      />

      {lineKeys.map((lineKey, index) => (
        <React.Fragment key={index}>
          <Line
            yAxisId="right"
            dataKey={lineKey}
            type="linear"
            stroke={index === 0 ? '#45b29d' : '#df5a49'}
            dot={{
              stroke: index === 0 ? '#45b29d' : '#df5a49',
              strokeWidth: 5,
            }}
            strokeWidth={2}
            activeDot={false}
            isAnimationActive={false}
          />
        </React.Fragment>
      ))}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export const formatDataChart = (data, barKey, lineKeys, isDivBillion) => {
  return data.map((item) => ({
    ...item,
    [barKey]:
      item[barKey] && isDivBillion === COM_GROUP.BANK
        ? item[barKey] * 100
        : valDivTrillion(item[barKey]),
    [lineKeys[0]]: item[lineKeys[0]]
      ? item[lineKeys[0]] * 100
      : item[lineKeys[0]],
    [lineKeys[1]]: item[lineKeys[1]]
      ? item[lineKeys[1]] * 100
      : item[lineKeys[1]],
  }))
}
