import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getDeepTransactionMatchingOrders } from './thunk'

const slice = createSlice({
  name: 'market/equityTrading/deepTransaction/matchingOrder',
  initialState: {
    ids: [],
    initialIds: [],
    data: [],
    dataByIds: {},
    loading: true,
    scrollId: null,
    pageSize: 50,
    isFirstTime: true,
    isPushDataRealtime: true,
    referencePrice: 0,
  },
  reducers: {
    changePageSize: (state, action) => {
      state.pageSize = action.payload
    },
    changeStateWhenScrollToTop: (state) => {
      state.scrollId = null
      state.isPushDataRealtime = true
    },
    changeStateWhenScrollToBottom: (state) => {
      state.isPushDataRealtime = false
    },
    changePriceDataReferencePrice: (state, action) => {
      state.referencePrice = action.payload
    },
    resetState: (state) => {
      state.ids = []
      state.initialIds = []
      state.data = []
      state.dataByIds = {}
      state.loading = true
      state.scrollId = null
      state.isPushDataRealtime = true
      state.isFirstTime = true
    },
    subscribeMatchingOrder: (state, action) => {
      if (
        state.isPushDataRealtime &&
        Array.isArray(action.payload) &&
        action.payload.length > 0
      ) {
        const newData = action.payload[0]
        if (newData.matchVolume && newData.matchVolume > 0) {
          const newDataRealTime = {
            tradingDate: newData.tradingDate,
            matchType: Number(newData.matchType),
            matchPrice: Number(newData.matchPrice),
            priceChange: Number(newData.priceChange),
            percentPriceChange: Number(newData.percentPriceChange),
            matchVolume: Number(newData.matchVolume),
            totalMatchVolume: Number(newData.totalMatchVolume),
          }
          const currentData = state.data
          if (state.data.length >= state.pageSize) {
            currentData.pop()
          }
          const data = [newDataRealTime, ...currentData]
            .sort(
              (a, b) =>
                new Date(b.tradingDate).getTime() -
                new Date(a.tradingDate).getTime(),
            )
            .map((item, index) => ({
              ...item,
              id: index,
            }))

          state.data = data
          state.dataByIds = keyBy(data, 'id')
          if (state.data.length < state.pageSize) {
            state.initialIds = data.map((v) => v.id)
            state.ids = data.map((v) => v.id)
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeepTransactionMatchingOrders.pending, (state) => {
      if (state.isFirstTime) {
        state.loading = true
      }
    })
    builder.addCase(
      getDeepTransactionMatchingOrders.fulfilled,
      (state, action) => {
        state.loading = false
        const lastLength = action.meta.arg.ScrollId ? state.data.length : 0
        const newData = action.payload.data
          .map((item, index) => ({
            ...item,
            id: index + lastLength,
          }))
          .sort(
            (a, b) =>
              new Date(b.tradingDate).getTime() -
              new Date(a.tradingDate).getTime(),
          )
        let data = [...newData]
        if (action.meta.arg.ScrollId) {
          data = [...state.data, ...newData]
        }
        state.data = data
        state.initialIds = data.map((v) => v.id)
        state.dataByIds = keyBy(data, 'id')
        state.ids = data.map((v) => v.id)
        state.scrollId = action.payload.scrollId
        if (state.isFirstTime) {
          state.isFirstTime = false
        }
      },
    )
    builder.addCase(
      getDeepTransactionMatchingOrders.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const {
  changePageSize,
  subscribeMatchingOrder,
  resetState,
  changeStateWhenScrollToBottom,
  changeStateWhenScrollToTop,
  changePriceDataReferencePrice,
} = slice.actions
export const selectDataMatchingOrder = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading
export const selectPageSize = (state) => state[slice.name].pageSize
export const selectScrollId = (state) => state[slice.name].scrollId
export const selectDataById = (state) => state[slice.name].dataByIds
export const selectIsPushDataRealtime = (state) =>
  state[slice.name].isPushDataRealtime
export const selectReferencePrice = (state) => state[slice.name].referencePrice

register(slice.name, slice.reducer)
