import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal } from '../../../../../utils/Value'
import { INDICATOR_FIX_UNIT_INT } from '../../../../financialData/financialStatement/table/constants'
import { FORMAT_DATA_BY_UNIT, UNIT_VALUE_CONSTANTS } from '../constant'
import {
  selectExchangeRates,
  selectFilter,
  selectValueById,
} from '../store/slice'

export const OtherColumnsCell = ({ val, rowId, style, className }) => {
  const itemKey = useSelector(selectValueById(rowId, 'key'))
  const itemFixUnit = useSelector(selectValueById(rowId, 'fixUnit'))
  const { unit, quarter, year } = useSelector(selectFilter)
  const exchangeRates = useSelector(selectExchangeRates)

  const formatDataByUnit =
    INDICATOR_FIX_UNIT_INT.includes(itemKey?.toUpperCase()) && itemFixUnit
      ? 1
      : FORMAT_DATA_BY_UNIT[unit]

  const getformatVal = () => {
    if (val === 0) return EMPTY_VALUE
    if (
      [
        UNIT_VALUE_CONSTANTS.THOUSAND_USD,
        UNIT_VALUE_CONSTANTS.MILLION_USD,
      ].includes(unit)
    ) {
      return !isNaN(val)
        ? formatVal(
            val / exchangeRates[`${quarter}-${year}`] / formatDataByUnit,
          )
        : EMPTY_VALUE
    } else {
      return !isNaN(val) ? formatVal(val / formatDataByUnit) : EMPTY_VALUE
    }
  }

  return (
    <td
      className={className}
      style={{ ...style, color: val < 0 ? '#ff5661' : '', textAlign: 'right' }}
    >
      {getformatVal()}
    </td>
  )
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
