import { titleData } from '../../common/helper'

export const convertDataColumnChart = (data, timeFrequency) => {
  let objList = {}
  const result = []
  data.forEach((item) => {
    return (objList[titleData(item.month, item.year, timeFrequency)] = {
      ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
      [item.locationId]: item.fdiPercentage * 100,
    })
  })
  Object.keys(objList).map((key) => {
    return result.push({
      xLabel: key,
      other:
        100 -
        Object.values(objList[key]).reduce(
          (partialSum, a) => partialSum + a,
          0,
        ),
      ...objList[key],
    })
  })
  return result
}
