import { useSelector } from 'react-redux'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { tableSchema } from '../constants'
import {
  selectTableByIds,
  selectTableCellDataById,
  selectTableIds,
  sort,
} from '../store/slice'
import { getTableSchema } from './helper'

export const ConsensusHistoryTable = () => {
  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const dataByIds = useSelector(selectTableByIds)
  const ids = useSelector(selectTableIds)

  const getData = (rowId) => {
    return dataByIds[rowId].recommendationTypeId
  }

  return (
    <Table
      ids={ids}
      getDataFromRedux={selectTableCellDataById}
      schema={getTableSchema(basicInfo, locale, getData)}
      stickies={{
        [tableSchema.TICKER]: true,
        [tableSchema.DATE]: true,
      }}
      isLargeHead={true}
      hasFooter={false}
      isPagination={false}
      sort={sort}
      defaultSort={{ reportDateId: 'desc' }}
    />
  )
}
