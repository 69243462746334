import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import PopupAlert from '../../common/popup/PopupAlert'
import PopupEvent from '../../common/popup/PopupEvent'
import { maxWatchlist, zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'
import { selectChecked, selectWatchlistIds, uncheckedAll } from '../store/slice'
import { copyWatchlistThunk, deleteWatchlistThunk } from '../store/thunk'

const GroupButton = ({ handleClickCreate }) => {
  const dispatch = useDispatch()

  const watchlistsChecked = useSelector(selectChecked)
  const watchlistIds = useSelector(selectWatchlistIds)

  const [isShowPopupCopy, setIsShowPopupCopy] = useState(false)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const handleCopyWatchlist = () => {
    if (watchlistIds.length + watchlistsChecked.length > maxWatchlist)
      setIsShowPopupCopy(true)
    else if (watchlistsChecked.length > 0) {
      dispatch(copyWatchlistThunk({ bondWatchListIds: watchlistsChecked }))
      dispatch(uncheckedAll())
    }
  }

  const handleDeleteWatchlist = () => {
    if (watchlistsChecked.length > 0) {
      dispatch(deleteWatchlistThunk({ bondWatchlistId: watchlistsChecked }))
      dispatch(uncheckedAll())
      closePopupDelete()
    }
  }

  const closePopupCopy = () => setIsShowPopupCopy(false)

  const openPopupDelete = () => {
    if (watchlistsChecked.length > 0) setIsShowPopupDelete(true)
  }

  const closePopupDelete = () => setIsShowPopupDelete(false)

  const checkBtnCreate = () => {
    if (watchlistIds.length >= maxWatchlist) setIsShowPopupCopy(true)
    else handleClickCreate()
  }

  return (
    <>
      <div className={`justify-content-start ${style.groupBtn}`}>
        <Button
          onClick={checkBtnCreate}
          className={`btn btn-blue-w ${style.buttonCus}`}
        >
          <i className={`icon-plus`} />
          <Translate
            className={style.lineHeight12}
            value="watchlistBond.watchlistTable.BTN_CREATE"
          />
        </Button>
        <Button
          onClick={handleCopyWatchlist}
          className={`btn normal mr-10 ml-10 ${style.buttonCus}`}
        >
          <i className={`icon-duplicate`} />
          <Translate
            className={style.lineHeight12}
            value="watchlistBond.watchlistTable.BTN_DUPLICATE"
          />
        </Button>
        <Button
          onClick={openPopupDelete}
          className={`btn normal ml-10 ${style.buttonCus}`}
        >
          <i className={`icon-trash`} />
          <Translate
            className={style.lineHeight12}
            value="watchlistBond.watchlistTable.BTN_DELETE"
          />
        </Button>
      </div>
      <PopupAlert
        message={<Translate value="watchlistBond.watchlistTable.EXCEEDED_WL" />}
        isShow={isShowPopupCopy}
        handleClose={closePopupCopy}
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
      />
      <PopupEvent
        title={
          <Translate value="watchlistBond.watchlistTable.TITLE_DELETE_WL" />
        }
        message={
          <div>
            <Translate value="watchlistBond.watchlistTable.ASK_DELETE" />{' '}
            <span style={{ fontWeight: 'bold' }}>
              {watchlistsChecked.length + ' '}
              <Translate value="watchlistBond.watchlistTable.ITEMS" />
            </span>
            ?
          </div>
        }
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
        isShow={isShowPopupDelete}
        handleClose={closePopupDelete}
        btnSubmit={{
          btnName: (
            <Translate value="watchlistBond.watchlistTable.BTN_CANCEL" />
          ),
          event: closePopupDelete,
        }}
        btnCancel={{
          btnName: (
            <Translate value="watchlistBond.watchlistTable.BTN_DELETE_2" />
          ),
          event: handleDeleteWatchlist,
        }}
      />
    </>
  )
}

GroupButton.propTypes = {
  handleClickCreate: PropTypes.func.isRequired,
}

export default GroupButton
