import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import { formatVal, valToPercent } from '../../../../utils/Value'

const OtherCellColumn = ({ val, props, isGrowth }) => {
  return (
    <td {...props}>
      <Span
        style={{
          color: val < 0 ? '#ff4752' : '',
        }}
      >
        {isGrowth ? valToPercent(val) : formatVal(val)}
      </Span>
    </td>
  )
}

OtherCellColumn.propTypes = {
  props: PropTypes.object,
  isGrowth: PropTypes.bool,
}

export default OtherCellColumn
