import { isEqual, maxBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { v4 as uuidv4 } from 'uuid'
import { Span } from '../../../common/html/Span'
import { FilterAllIssuer } from '../Filter/FilterAllIssuer'
import { FilterBond } from '../Filter/FilterBond'
import { FilterCreateBond } from '../Filter/FilterCreateBond'
import FilterSector from '../Filter/FilterSector'
import { FilterWatchlist } from '../Filter/FilterWatchlist'
import { INDICATOR_GROUPS } from '../constants'
import {
  selectAddInformatics,
  selectConditions,
  selectFilterParams,
  setFilterParameters,
} from '../store/slice'
import { FilterBondType } from './Filter/FilterBondType'
import { FilterTypes } from './Filter/FilterTypes'
import { BOND_OVERVIEW_MATCH_IDS } from './constants'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()

  const conditions = useSelector(selectConditions)
  const addInformatics = useSelector(selectAddInformatics)
  const params = useSelector(selectFilterParams)
  const locale = useSelector((state) => state.i18n.locale)

  const [ids, setIds] = useState([])

  const filterList = useMemo(() => {
    if (!conditions) return []

    const parent = conditions.filter(
      (item) =>
        item.indicatorId === item.parentIndicatorId || !item.parentIndicatorId,
    )

    return parent.map((item) => ({
      ...item,
      children: conditions.filter(
        (child) =>
          item.indicatorId === child.parentIndicatorId &&
          child.indicatorId !== child.parentIndicatorId,
      ),
    }))
  }, [conditions, params.indicatorGroup, locale])

  // Actions
  const handleClick = (id) => {
    setIds(!ids.includes(id) ? [...ids, id] : ids.filter((item) => item !== id))
  }

  const handleChangeParams = (value, id) => {
    const index = params.conditions.findIndex((item) => item.indicatorId === id)
    const information = addInformatics.find((item) => item.indicatorId === id)
    const indexInformation = params.addedInformations.findIndex(
      (item) => item.indicatorId === id,
    )

    if (!information) return

    const {
      indicatorId,
      tableCode,
      conditionTableFieldCode,
      tableFieldCode,
      selectionType,
      selectionTypeId,
      multiplier,
    } = information

    if (index === -1) {
      if (!value?.length) {
        return
      }

      const newItem = {
        id: uuidv4(),
        order: params?.conditions?.length ?? 0,
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        conditionValues: value,
      }

      if (multiplier) {
        newItem.multiplier = multiplier
      }

      dispatch(
        setFilterParameters({
          key: 'conditions',
          value: [...params.conditions, newItem],
        }),
      )

      if (indexInformation === -1) {
        const max = maxBy(params.addedInformations, (e) => e.order)

        dispatch(
          setFilterParameters({
            key: 'addedInformations',
            value: [
              ...params.addedInformations,
              {
                id: uuidv4(),
                order: (max?.order ?? 0) + 1,
                indicatorId,
                tableCode,
                tableFieldCode,
              },
            ],
          }),
        )
      }

      return
    }

    if (!value?.length) {
      dispatch(
        setFilterParameters({
          key: 'conditions',
          value: [
            ...params.conditions.slice(0, index),
            ...params.conditions.slice(index + 1),
          ],
        }),
      )

      if (indexInformation !== -1 && !information?.isDefault) {
        dispatch(
          setFilterParameters({
            key: 'addedInformations',
            value: [
              ...params.addedInformations.slice(0, indexInformation),
              ...params.addedInformations.slice(indexInformation + 1),
            ],
          }),
        )
      }
      return
    }

    const newConditions = [
      ...params.conditions.slice(0, index),
      {
        ...params.conditions[index],
        conditionValues: value,
      },
      ...params.conditions.slice(index + 1),
    ]

    dispatch(setFilterParameters({ key: 'conditions', value: newConditions }))

    if (indexInformation === -1 && !isEqual(value, ['0'])) {
      const max = maxBy(params.addedInformations, (e) => e.order)

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...params.addedInformations,
            {
              id: uuidv4(),
              order: (max?.order ?? 0) + 1,
              indicatorId,
              tableCode,
              tableFieldCode,
            },
          ],
        }),
      )
      return
    }

    if (
      isEqual(value, ['0']) &&
      indexInformation !== -1 &&
      !information?.isDefault
    ) {
      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...params.addedInformations.slice(0, indexInformation),
            ...params.addedInformations.slice(indexInformation + 1),
          ],
        }),
      )
    }
  }

  // Use effect
  useEffect(() => {
    const ids = conditions.map((item) => item.indicatorId)
    setIds(ids)
  }, [conditions, params.indicatorGroup])

  return (
    <>
      {filterList?.map((item) => (
        <div
          key={item.indicatorId}
          className="d-flex"
          style={{ gap: 8, flexDirection: 'column' }}
        >
          <div className="d-flex ali-center">
            <i
              className={`${style.i} ${
                ids.includes(item.indicatorId)
                  ? 'icon-caret-down'
                  : 'icon-caret-right'
              } cursor-pointer`}
              onClick={() => handleClick(item.indicatorId)}
            />
            <Span
              className="cursor-pointer"
              onClick={() => handleClick(item.indicatorId)}
              style={{ fontWeight: 340 }}
            >
              {item?.indicatorName ?? ''}
            </Span>
          </div>
          {ids.includes(item.indicatorId) && (
            <>
              {BOND_OVERVIEW_MATCH_IDS.includes(item.indicatorId) && (
                <>
                  <div className="w-100">
                    <label className={style.labelInput}>
                      <Span style={{ fontSize: 11 }}>
                        <Translate
                          value={`bond.bondScreening.${
                            params.indicatorGroup ===
                            INDICATOR_GROUPS.BOND_CREATING
                              ? 'createBond'
                              : params.indicatorGroup ===
                                INDICATOR_GROUPS.ALL_ISSUER
                              ? 'allIssuer'
                              : 'filter'
                          }.${
                            params.indicatorGroup ===
                            INDICATOR_GROUPS.BOND_CREATING
                              ? 'BOND_TICKER'
                              : 'TICKER_OR_ISSUERS'
                          }`}
                        />
                      </Span>
                    </label>
                    {params.indicatorGroup ===
                    INDICATOR_GROUPS.BOND_CREATING ? (
                      <FilterCreateBond width={258} />
                    ) : params.indicatorGroup ===
                      INDICATOR_GROUPS.ALL_ISSUER ? (
                      <FilterAllIssuer width={258} />
                    ) : (
                      <FilterBond width={258} />
                    )}
                  </div>
                  <FilterBondType handleChangeParams={handleChangeParams} />
                  <div className="w-100">
                    <label className={style.labelInput}>
                      <Span style={{ fontSize: 11 }}>
                        <Translate value="bond.bondScreening.common.INDUSTRY" />
                      </Span>
                    </label>
                    <FilterSector />
                  </div>
                  <div className="w-100">
                    <label className={style.labelInput}>
                      <Span style={{ fontSize: 11 }}>
                        <Translate value="bond.bondScreening.filter.LIST_OF_ISSUERS" />
                      </Span>
                    </label>
                    <FilterWatchlist width={258} />
                  </div>
                </>
              )}
              <FilterTypes
                item={item}
                handleChangeParams={handleChangeParams}
              />
            </>
          )}
        </div>
      ))}
    </>
  )
}

export default Filter
