import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import AdditionalInfoPrice from '../components/AdditionalInfoPrice'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import { selectIndexInfo, selectPriceData, subscribeIndex } from './store/slice'
import { getLatestPrice } from './store/thunk'

export const PriceData = () => {
  const dispatch = useDispatch()
  const indexInfo = useSelector(selectIndexInfo)
  const priceData = useSelector(selectPriceData)

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(
        getLatestPrice({
          Ids: indexInfo.groupId,
          GroupType: GROUP_TYPE.INDEX,
        }),
      )
    }
  }, [indexInfo.groupId])

  return (
    <div className="info-box">
      <Realtime
        channel={ChannelConfig.IndexChannel + indexInfo.groupId}
        action={subscribeIndex}
      >
        <SummaryInfoPrice data={{ ...priceData, isTicker: false }} />
        <AdditionalInfoPrice data={{ ...priceData, isTicker: false }} />
      </Realtime>
    </div>
  )
}
