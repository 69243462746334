import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'

export const getSectorEarningGrowthChartData = createAsyncThunk(
  'sector/sectorConstituents/overview/SECTOR_EARNING_GROWTH_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getSectorEarningGrowthChartData(
          params,
        )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCreditAndDepositChartData = createAsyncThunk(
  'sector/sectorConstituents/overview/CREDIT_AND_DEPOSIT_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getCreditAndDepositChartData(
          params,
        )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMarginLendingChartData = createAsyncThunk(
  'sector/sectorConstituents/overview/MARGIN_LENDING_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getMarginLendingChartData(
          params,
        )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
