import { sortBy } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { DEFAULT_ORGANIZATION_ID } from '../../../../configs/Common'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import CalendarPopper from '../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../common/calendar/inputs/InputDateCalendar'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../common/hooks/useBasicInfo'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { usePackagePermission } from '../../../common/hooks/usePackagePermission'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Button } from '../../../common/html/Button'
import { Icon } from '../../../common/html/Icon'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import PopupAlert, { POPUP_ICON_STATUS } from '../../../common/popup/PopupAlert'
import TextEllipsis from '../../../common/textEllipsis'
import { TooltipInfo } from '../../../common/tooltipInfo'
import { COM_GROUP } from '../../../constants/Group'
import { PopupCanNotAccess } from '../../../layout/PopupCanNotAccess'
import {
  FORMAT,
  compareTwoDateTime,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../utils/Value'
import { renderCouponName } from '../primaryMarket/marketStatistics/helper'
import { CASH_FLOW_TYPES, RADIO_TYPES } from './constants'
import style from './index.module.css'
import {
  changeBondDetail,
  changeItemSelected,
  changeModalData,
  resetBondInfo,
  resetModalData,
  selectBondDetailSelected,
  selectBondInfo,
  selectFilterPriceBoard,
  selectItemSelected,
  selectLoadingBondInfo,
  selectModalData,
} from './store/slice'
import { getBondCashFlowScenarioData, getPriceYtmData } from './store/thunk'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/
const regexPrice = /^[+]?[0-9]*\.?[0-9]*$/

const PopupDetail = ({ handleCancel }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const timeZone = UseTimeZone()

  const item = useSelector(selectItemSelected)
  const data = useSelector(selectBondDetailSelected)
  const {
    tradingDate,
    paymentDate,
    totalParValue,
    volume,
    ytm,
    dirtyPrice,
    cleanPrice,
  } = useSelector(selectModalData)
  const locale = useSelector((state) => state.i18n.locale)
  const { isCleanPrice } = useSelector(selectFilterPriceBoard)
  const bondInfo = useSelector(selectBondInfo)
  const loadingBondInfo = useSelector(selectLoadingBondInfo)

  const [value, setValue] = useState('')
  const [isShow, setIsShow] = useState('')
  const [check, setCheck] = useState(1)

  const getWeight = (list) => {
    if (!list?.length) return 0

    const nominal = sortBy(
      list.filter((item) => item.cashFlowTypeId === CASH_FLOW_TYPES.NOMINAL),
      (item) => moment(item.tradingDateId),
    )
    const real = sortBy(
      list.filter((item) => item.cashFlowTypeId === CASH_FLOW_TYPES.REAL),
      (item) => moment(item.tradingDateId),
    )

    if (!nominal?.length || !real?.length) return 0

    let check = 0

    for (let i = 1; i <= nominal.length; i++) {
      if (!moment(nominal[i]?.tradingDateId).isSame(real[i]?.tradingDateId)) {
        check = 1
        break
      }
    }

    return check
  }

  const handleChangeCoupon = (coupon) => {
    setValue(!!coupon.length ? (!isNaN(coupon) ? coupon : value) : '')
  }

  const handleChangeVolume = (value) => {
    const newVolume = !!value.length ? (!isNaN(value) ? value : volume) : ''

    dispatch(
      changeModalData({
        key: 'volume',
        value:
          parseFloat(newVolume) < 1
            ? '1'
            : parseFloat(newVolume) > 1000000
            ? '1000000'
            : newVolume,
      }),
    )
  }

  const handleChangeYtm = (value) => {
    if (!value?.length || value.match(regexValue)) {
      dispatch(
        changeModalData({
          key: 'ytm',
          value: value,
        }),
      )
    }
  }

  const handleChangeDirtyPrice = (value) => {
    if (!value?.length || value.match(regexPrice)) {
      dispatch(
        changeModalData({
          key: 'dirtyPrice',
          value: value,
        }),
      )
    }
  }

  const handleChangeCleanPrice = (value) => {
    if (!value?.length || value.match(regexPrice)) {
      dispatch(
        changeModalData({
          key: 'cleanPrice',
          value: value,
        }),
      )
    }
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE, undefined, timeZone))
  }

  const handleGetBondCashFlow = (coupon, date, floatInterestBenchmarkId) => {
    const payload = {
      BondIds: item.id,
      TradingDateId: date,
      InterestBasisTypeId: 1,
      InterestRate: coupon,
      isUserBond: false,
    }

    if (floatInterestBenchmarkId) {
      payload.floatInterestBenchmarkId = floatInterestBenchmarkId
    }

    dispatch(getBondCashFlowScenarioData(payload))
  }

  const handleBlurVolume = () => {
    dispatch(
      changeModalData({
        key: 'volume',
        value: !isNaN(volume) ? formatVal(volume, 10, false, '', 0) : '',
      }),
    )

    dispatch(
      changeModalData({
        key: 'totalParValue',
        value: !!volume.length
          ? (item.parValue * parseFloat(volume.replace(/,/g, ''))).toString()
          : '',
      }),
    )
  }

  const handleFocusYtm = () => {
    dispatch(
      changeModalData({
        key: 'ytm',
        value: ytm.replace(/,/g, '').replace(/%/g, ''),
      }),
    )
  }

  const handleFocusDirtyPrice = () => {
    dispatch(
      changeModalData({
        key: 'dirtyPrice',
        value: dirtyPrice.replace(/,/g, ''),
      }),
    )
  }
  const handleFocusCleanPrice = () => {
    dispatch(
      changeModalData({
        key: 'cleanPrice',
        value: cleanPrice.replace(/,/g, ''),
      }),
    )
  }

  const handleBlurPrice = () => {
    if (
      (check === RADIO_TYPES.YTM && !ytm.length) ||
      (check === RADIO_TYPES.DIRTY_PRICE && !dirtyPrice.length) ||
      (check === RADIO_TYPES.CLEAN_PRICE && !cleanPrice.length)
    ) {
      return
    }

    const rawPrice = parseFloat(
      check === RADIO_TYPES.CLEAN_PRICE ? cleanPrice : dirtyPrice,
    )

    const payload = {
      lstBondCashFlow: !!bondInfo?.length
        ? bondInfo
            .filter((item) => item?.cashFlowTypeId === CASH_FLOW_TYPES.REAL)
            .filter(
              (item) =>
                item?.bondPaymentTypeId === 1 || item?.bondPaymentTypeId === 4,
            )
            .map((item) => ({
              settlementdate: item.tradingDateId,
              coupon:
                item.bondPaymentTypeId === 1
                  ? parseFloat(item?.couponInterestRate ?? 0)
                  : 0,
              frequency: data?.paymentCalendarMonth ?? 0,
              paymentStatusId: item.paymentStatusId,
            }))
        : [],
      issueDate: data?.issueDateId,
      weigh: getWeight(bondInfo),
      paydate: getISOStartOrEndOfDay(paymentDate, timeZone, true),
      interestBasisTypeId: 1,
      price:
        check !== RADIO_TYPES.YTM
          ? check === RADIO_TYPES.CLEAN_PRICE
            ? parseInt(rawPrice + (bondInfo?.[0].accInterest ?? 0))
            : parseInt(rawPrice)
          : 0,
      ytm:
        check === RADIO_TYPES.YTM
          ? parseFloat(parseFloat(ytm).toFixed(4)) / 100
          : 0,
      parValue: item.parValue,
    }

    dispatch(getPriceYtmData(payload))
  }

  const handleChangeTradingDate = (date) => {
    const compareDate = compareTwoDateTime(paymentDate, date)
    if (compareDate === -1) {
      return setIsShow(
        'bond.valuation.sameIndusTryBond.contentComparePaymentDateTradingDate',
      )
    }

    const paymentDateUnix = moment(paymentDate, 'MM/DD/YYYY').unix()
    const issueDate = moment(
      moment(data?.issueDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()
    const maturityDate = moment(
      moment(data?.maturityDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()

    if (paymentDateUnix >= issueDate && paymentDateUnix <= maturityDate) {
      dispatch(
        changeModalData({
          key: 'tradingDate',
          value: date,
        }),
      )

      const newPrice = parseFloat(parseFloat(value / 100).toFixed(4))

      handleGetBondCashFlow(
        newPrice,
        tradingDate,
        newPrice !== data?.interestRate
          ? data?.floatInterestBenchmarkId
          : undefined,
      )

      return
    }

    setIsShow('bond.valuation.sameIndusTryBond.contentCheckPaymentDate')
  }

  const handleChangePaymentDate = (date) => {
    const compareDate = compareTwoDateTime(date, tradingDate)
    if (compareDate === -1) {
      return setIsShow(
        'bond.valuation.sameIndusTryBond.contentComparePaymentDateTradingDate',
      )
    }

    const paymentDate = moment(date, 'MM/DD/YYYY').unix()
    const issueDate = moment(
      moment(data?.issueDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()
    const maturityDate = moment(
      moment(data?.maturityDateId).format(FORMAT.DATE[locale]),
      FORMAT.DATE[locale],
    ).unix()

    if (paymentDate >= issueDate && paymentDate <= maturityDate) {
      dispatch(
        changeModalData({
          key: 'paymentDate',
          value: date,
        }),
      )

      const newPrice = parseFloat(parseFloat(value / 100).toFixed(4))

      handleGetBondCashFlow(
        newPrice,
        getISOStartOrEndOfDay(date, timeZone, true),
        newPrice !== data?.interestRate
          ? data?.floatInterestBenchmarkId
          : undefined,
      )

      return
    }

    setIsShow('bond.valuation.sameIndusTryBond.contentCheckPaymentDate')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  useEffect(() => {
    if (!isNaN(data?.interestRate) && item) {
      setValue(formatVal(data.interestRate * 100))
      handleGetBondCashFlow(
        data.interestRate,
        getISOStartOrEndOfDay(paymentDate, timeZone, true),
      )
    }
  }, [locale, data?.interestRate, item])

  useEffect(() => {
    if (
      item &&
      !!item?.price?.length &&
      !!bondInfo.length &&
      data &&
      !loadingBondInfo
    ) {
      const rawPrice = parseFloat(item.price.replace(/,/g, ''))

      const payload = {
        lstBondCashFlow: !!bondInfo?.length
          ? bondInfo
              .filter((item) => item?.cashFlowTypeId === CASH_FLOW_TYPES.REAL)
              .filter(
                (item) =>
                  item?.bondPaymentTypeId === 1 ||
                  item?.bondPaymentTypeId === 4,
              )
              .map((item) => ({
                settlementdate: item.tradingDateId,
                coupon:
                  item.bondPaymentTypeId === 1
                    ? parseFloat(item?.couponInterestRate ?? 0)
                    : 0,
                frequency: data?.paymentCalendarMonth ?? 0,
                paymentStatusId: item.paymentStatusId,
              }))
          : [],
        issueDate: data?.issueDateId,
        weigh: getWeight(bondInfo),
        paydate: getISOStartOrEndOfDay(paymentDate, timeZone, true),
        interestBasisTypeId: 1,
        price: parseInt(
          isCleanPrice ? rawPrice + (bondInfo?.[0].accInterest ?? 0) : rawPrice,
        ),
        ytm: 0,
        parValue: item.parValue,
      }

      dispatch(getPriceYtmData(payload))
    }
  }, [item, bondInfo.length, data, loadingBondInfo, isCleanPrice])

  useEffect(() => {
    if (item) {
      setCheck(isCleanPrice ? RADIO_TYPES.CLEAN_PRICE : RADIO_TYPES.DIRTY_PRICE)
    }
  }, [item, isCleanPrice])

  return (
    <>
      <Popup>
        <div className={`modal ${style.fitContent}`}>
          <div className="modal-title">
            <h3>
              <Span>{item?.ticker ?? ''}</Span>
            </h3>
            <a className="close-modal" onClick={handleCancel}>
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div
            className={`modal-content ${style.modalBody}`}
            style={{
              paddingBottom: 0,
              overflow: 'auto',
            }}
          >
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.ISSUER" />
              </Span>
              <div className={style.modalContent}>
                {data?.organizationShortName ? (
                  <a
                    className="cursor-pointer"
                    onClick={() =>
                      redirectToBondIssuer(
                        data?.organizationId ??
                          DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                      )
                    }
                  >
                    <TextEllipsis
                      text={data?.organizationShortName}
                      isI18n={false}
                    />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.INDUSTRY" />
              </Span>
              <div className={style.modalContent}>
                {data?.icbName ? (
                  <a
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsShow('common.FUNCTION_COMING_SOON')
                    }}
                  >
                    <TextEllipsis text={data?.icbName} isI18n={false} />
                  </a>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.ISSUE_DATE" />
              </Span>
              <div className={style.modalContent}>
                {data?.issueDateId ? (
                  <DateTimeSpan date={data?.issueDateId} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.DUE_DATE" />
              </Span>
              <div className={style.modalContent}>
                {data?.maturityDateId ? (
                  <DateTimeSpan date={data?.maturityDateId} />
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.INTEREST_PAYMENT_PERIOD" />
              </Span>
              <div className={style.modalContent}>
                {data?.paymentCalendarMonth ?? ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.CREDIT_EVENT" />
              </Span>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.DURATION" />
              </Span>
              <div className={style.modalContent}>
                {data?.durationName ?? ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 12 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.COUPON_TYPE" />
              </Span>
              <div className={style.modalContent} style={{ color: '#FF4752' }}>
                {data?.bondEventTypeName ?? ''}
              </div>
              <div
                className={style.modalContent}
                style={{
                  color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
                }}
              >
                {renderCouponName(
                  data?.couponTypeId ?? data?.couponTypeName ?? '',
                )}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 10 }}>
              <Span style={{ fontStyle: 'italic', marginLeft: 16, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.EXPECTED_COUPON" />
              </Span>
              <div className={style.modalContent}>
                <div className="d-flex ali-center">
                  {data?.couponTypeId === 1 ? (
                    <div style={{ width: 50 }}>
                      <Input
                        onChange={(e) =>
                          handleChangeCoupon(e?.target?.value ?? '')
                        }
                        onBlur={() => {
                          handleGetBondCashFlow(
                            parseFloat(parseFloat(value / 100).toFixed(4)),
                            moment(tradingDate).format(
                              FORMAT.YEAR_MONTH_DAY[locale],
                            ),
                            parseFloat(parseFloat(value / 100).toFixed(4)) !==
                              data?.interestRate
                              ? data?.floatInterestBenchmarkId
                              : undefined,
                          )

                          setValue(!isNaN(value) ? formatVal(value) : '')
                        }}
                        onFocus={() => {
                          setValue(
                            !!value?.length ? value.replace(/,/g, '') : '',
                          )
                        }}
                        type="text"
                        className={style.input}
                        value={value}
                        style={{ textAlign: 'right' }}
                      />
                    </div>
                  ) : (
                    <Span>{valToPercent(data?.couponInterestRate)}</Span>
                  )}
                  {data?.floatInterestSpread > 0 && (
                    <Span style={{ marginLeft: 8 }}>
                      +{valToPercent(data?.floatInterestSpread)}
                    </Span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontStyle: 'italic', marginLeft: 16, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.COUPON_BENCHMARK" />
              </Span>
              {data?.floatInterestBenchmarkDescription && (
                <div
                  className={style.modalContent}
                  style={{
                    color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
                  }}
                >
                  <TextEllipsis
                    text={data?.floatInterestBenchmarkDescription}
                  />
                </div>
              )}
            </div>
            {data?.textCautionType && (
              <div
                className="d-flex ali-center"
                style={{
                  marginBottom: 16,
                  color: '#FF4752',
                  fontStyle: 'italic',
                }}
              >
                <Span>
                  {data?.textCaution ?? (
                    <Translate value="bond.corporateBond.priceBoard.CAUTION" />
                  )}
                </Span>
              </div>
            )}
            <div
              className="d-flex ali-center"
              style={{ marginBottom: 8, gap: 4 }}
            >
              <div className="d-flex ali-center" style={{ gap: 4, flex: 1 }}>
                <Span style={{ fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.TRADING_DATE" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Tradingdate"
                />
              </div>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        tradingDate ? getDateWithTimeZone(tradingDate) : null
                      }
                      handleChange={handleChangeTradingDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      popperClassName={style.popperClassName}
                    />
                  </div>
                  <Icon className={`icon-date`} style={{ fontSize: 12 }} />
                </div>
              </div>
            </div>
            <div
              className="d-flex ali-center"
              style={{ marginBottom: 8, gap: 4 }}
            >
              <div className="d-flex ali-center" style={{ gap: 4, flex: 1 }}>
                <Span style={{ fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.PAYMENT_DATE" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Paymentdate"
                />
              </div>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        paymentDate ? getDateWithTimeZone(paymentDate) : null
                      }
                      handleChange={handleChangePaymentDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      popperClassName={style.popperClassName}
                    />
                  </div>
                  <Icon
                    className={`icon-date ${style.eventNone}`}
                    style={{ fontSize: 12 }}
                  />
                </div>
              </div>
            </div>
            <div
              className="d-flex ali-center"
              style={{ marginBottom: 8, gap: 4 }}
            >
              <div className="d-flex ali-center" style={{ gap: 4, flex: 1 }}>
                <Span style={{ fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.EXRIGHT_DATE" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Exrightdate"
                />
              </div>
              <div className={style.modalContent}>
                <div className="form-control calendar mb-0">
                  <div className={`${style.bgGrey} w-100`}>
                    <CalendarPopper
                      date={
                        data?.exPaymentDateId
                          ? getDateWithTimeZone(data?.exPaymentDateId)
                          : null
                      }
                      handleChange={handleChangeTradingDate}
                      customHeader={HeaderOneCalendarRange({
                        startYear: 2000,
                        endYear: new Date().getFullYear(),
                      })}
                      CustomInput={InputDateCalendar}
                      customInputStyle={{
                        textAlign: 'right',
                        paddingRight: 28,
                      }}
                      disabled
                    />
                  </div>
                  <Icon
                    className={`icon-date ${style.eventNone}`}
                    style={{ fontSize: 12 }}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <div className="d-flex ali-center" style={{ gap: 4, flex: 1 }}>
                <Span style={{ fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.VOLUME" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.TradingVolume"
                />
              </div>
              <div className={style.modalContent}>
                <Input
                  onChange={(e) => handleChangeVolume(e?.target?.value ?? '')}
                  onBlur={handleBlurVolume}
                  onFocus={() => {
                    dispatch(
                      changeModalData({
                        key: 'volume',
                        value: !!volume?.length ? volume.replace(/,/g, '') : '',
                      }),
                    )
                  }}
                  type="text"
                  className={style.input}
                  value={volume}
                  style={{ textAlign: 'right' }}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER_2',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <Span style={{ fontWeight: 600, flex: 1 }}>
                <Translate value="bond.corporateBond.priceBoard.TOTAL_PAR_VALUE" />
              </Span>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                {!!totalParValue.length
                  ? formatVal(totalParValue, 10, false, '', 0)
                  : ''}
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <div
                className={`d-flex align-center
                  `}
                style={{ flex: 1, gap: 4 }}
                onClick={() => setCheck(1)}
              >
                <input
                  style={{ marginTop: 1.5, marginRight: 0 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.YTM}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.YIELD_TO_MATURITY" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.YTM"
                />
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) => handleChangeYtm(e?.target?.value ?? '')}
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusYtm}
                  type="text"
                  className={style.input}
                  value={ytm}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.YTM}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center" style={{ marginBottom: 8 }}>
              <div
                className={`d-flex align-center
                  `}
                style={{ flex: 1, gap: 4 }}
                onClick={() => setCheck(RADIO_TYPES.DIRTY_PRICE)}
              >
                <input
                  style={{ marginTop: 1.5, marginRight: 0 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.DIRTY_PRICE}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.DIRTY" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Dirtyprice"
                />
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) =>
                    handleChangeDirtyPrice(e?.target?.value ?? '')
                  }
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusDirtyPrice}
                  type="text"
                  className={style.input}
                  value={dirtyPrice}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.DIRTY_PRICE}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
            <div className="d-flex ali-center">
              <div
                className={`d-flex align-center
                  `}
                style={{ flex: 1, gap: 4 }}
                onClick={() => setCheck(RADIO_TYPES.CLEAN_PRICE)}
              >
                <input
                  style={{ marginTop: 1.5, marginRight: 0 }}
                  type="radio"
                  className="radiobox radiobox2"
                  checked={check === RADIO_TYPES.CLEAN_PRICE}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 600 }}>
                  <Translate value="bond.corporateBond.priceBoard.CLEAN" />
                </Span>
                <TooltipInfo
                  style={{ marginTop: 1.5 }}
                  fontSize={8}
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Cleanprice"
                />
              </div>
              <div
                className={style.modalContent}
                style={{
                  textAlign: 'right',
                }}
              >
                <Input
                  onChange={(e) =>
                    handleChangeCleanPrice(e?.target?.value ?? '')
                  }
                  onBlur={handleBlurPrice}
                  onFocus={handleFocusCleanPrice}
                  type="text"
                  className={style.input}
                  value={cleanPrice}
                  style={{ textAlign: 'right' }}
                  disabled={check !== RADIO_TYPES.CLEAN_PRICE}
                  placeholder={I18n.t(
                    'bond.corporateBond.priceBoard.PLACEHOLDER',
                  )}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end" style={{ padding: 16 }}>
            <Button
              className={`btn btn-blue w-80`}
              onClick={handleCancel}
              style={{ height: 24 }}
            >
              <Translate value="common.button.BUTTON_CLOSE" />
            </Button>
          </div>
        </div>
      </Popup>
      <PopupAlert
        message={I18n.t(isShow)}
        isShow={!!isShow.length}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShow('')}
      />
    </>
  )
}

const withCheckPackagePermission = (Component) => () => {
  const dispatch = useDispatch()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const item = useSelector(selectItemSelected)

  const handleCancel = () => {
    dispatch(changeItemSelected(null))
    dispatch(changeBondDetail(null))
    dispatch(resetModalData())
    dispatch(resetBondInfo())
  }

  if (isTrial) {
    return (
      <PopupCanNotAccess
        title={item?.ticker ?? ''}
        isHideButton
        isPopup
        onCancel={handleCancel}
      />
    )
  }

  return <Component handleCancel={handleCancel} />
}

export default withCheckPackagePermission(PopupDetail)
