import { PRICE_DATA_TIME_SELECT } from '../constants'
import { ACC_MONTHLY_TITLE } from '../table/constants'

const { ACC_MONTHLY } = PRICE_DATA_TIME_SELECT

export const getIdsByCType = (cType, checkboxChecked) => {
  return checkboxChecked
    .filter((item) => item.includes(cType))
    .map((item) => parseInt(item))
    .toString()
}

export const handlePPIData = (payload, args) => {
  if (!payload.length) {
    return []
  }

  const { DataType: yearlyQuarterlyValue } = args
  const reCalculateQuarter = {
    1: 1,
    6: 2,
    9: 3,
    5: 4,
  }

  const quarterYearNotDuplicate = handleDuplicateByKey(payload, [
    'realYear',
    'realQuarter',
  ])
  return quarterYearNotDuplicate
    .map((item) => {
      const year = item.realYear
      const quarter = reCalculateQuarter[item.realQuarter] ?? item.realQuarter
      const dataWithQuarterYear = payload.filter(
        (element) =>
          element.realYear === item.realYear &&
          element.realQuarter === item.realQuarter,
      )
      return dataWithQuarterYear.reduce((previous, current) => {
        const time = getKeyByQuarterYear(yearlyQuarterlyValue, current)
        return {
          ...previous,
          [`${current.vnTypeId}-${current.cType}`]: current.value * 100,
          time,
          year,
          quarter,
        }
      }, {})
    })
    .sort(sortByQuarterYear)
}

const sortByQuarterYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.quarter > b.quarter) {
      return 1
    }

    if (a.quarter < b.quarter) {
      return -1
    }
  }

  return 0
}

const getKeyByQuarterYear = (
  yearlyQuarterlyValue,
  { realQuarter, realYear },
) => {
  if (yearlyQuarterlyValue === 'AccQuarterly') {
    if (realQuarter === 1) {
      return `Q${realQuarter}-${realYear}`
    }

    if (realQuarter === 6) {
      return `2Q-${realYear}`
    }

    if (realQuarter === 9) {
      return `3Q-${realYear}`
    }

    return `4Q-${realYear}`
  }

  if (yearlyQuarterlyValue === 'Yearly') {
    if (realQuarter < 4) {
      return `Q${realQuarter}-${realYear}`
    }

    return `${realYear}`
  }

  return `Q${realQuarter}-${realYear}`
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const getTooltipContentData = (ids, dataTypeName) => {
  return ids.map((id) => {
    return {
      id: id,
      title: dataTypeName[id],
    }
  })
}

export const handleTooltipTwoColumn = (ids, dataTypeName) => {
  const MAX_ITEM_OF_COLUMN = 8
  const tooltipData = getTooltipContentData(ids, dataTypeName)
  if (tooltipData.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: tooltipData, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(tooltipData.length / 2)
  return {
    leftColumn: tooltipData.concat().splice(0, leftColumnLength),
    rightColumn: tooltipData.concat().splice(leftColumnLength),
  }
}

export const handleTooltipTwoColumnWithoutPPI = (listItemSelect) => {
  const MAX_ITEM_OF_COLUMN = 8
  if (listItemSelect.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: listItemSelect, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(listItemSelect.length / 2)
  return {
    leftColumn: listItemSelect.concat().splice(0, leftColumnLength),
    rightColumn: listItemSelect.concat().splice(leftColumnLength),
  }
}

export const handleTitleXAxis = (data, locale, typeDataTime) => {
  return data.map((item) => {
    let time =
      item.month < 10
        ? `0${item.month}-${item.year}`
        : `${item.month}-${item.year}`
    if (typeDataTime === ACC_MONTHLY) {
      time = `${ACC_MONTHLY_TITLE[item.month][locale]}-${item.year}`
    }
    return {
      ...item,
      time,
    }
  })
}
