import { keyBy } from '../../../../utils/Common'
import {
  getAccMonthAndYearKey,
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../common/helper'
import { TABLE_FILTER, TIME_TYPE, TYPE_DATA } from './constants'

const FIRST_LEVEL = 1
const SECOND_LEVEL = 2
const THIRD_LEVEL = 3

const LOWEST_LOCATION_LEVEL = 4
const MIDDLE_LOCATION_LEVEL = 3
const MIDDLE_TRADE_AGREEMENTS_LEVEL = 3

export const reOrderedNameData = (data, reOrderedIds) => {
  const getOverallParentLocation = data.filter(
    (item) => item.level === FIRST_LEVEL,
  )
  const getOverallChildrenLocation = data.filter(
    (item) => item.level === SECOND_LEVEL,
  )
  const getProducts = data.filter(
    (item) => item.level === MIDDLE_LOCATION_LEVEL,
  )

  const overallParentLocationIds = getOverallParentLocation?.map(
    (item) => item.id,
  )
  const overallChildrenLocationIds = getOverallChildrenLocation?.map(
    (item) => item.id,
  )

  const reOrderParentLocation = reOrderedIds
    ? reOrderedIds
        ?.filter((item) => overallParentLocationIds.includes(item))
        .map(
          (item) =>
            getOverallParentLocation?.find((prod) => prod.id === item) ?? 0,
        )
    : getOverallParentLocation

  const reOrderChildrenLocation = reOrderedIds
    ? reOrderedIds
        ?.filter((item) => overallChildrenLocationIds.includes(item))
        .map(
          (item) =>
            getOverallChildrenLocation?.find((prod) => prod.id === item) ?? 0,
        )
    : getOverallChildrenLocation

  const getProductsOfChildrenLocation = (childrenLocationId) => {
    return getProducts.filter((item) => item.parentId === childrenLocationId)
  }

  const getChildrenOfParentLocation = (parentLocationId) => {
    return reOrderChildrenLocation.filter(
      (item) => item.parentId === parentLocationId,
    )
  }

  return getProducts.length
    ? reOrderParentLocation
        .map((item) => ({
          ...item,
          childrenIds: getChildrenOfParentLocation(item.id).map(
            (item) => item.id,
          ),
          childrenLocationIds: getChildrenOfParentLocation(item.id).map(
            (item) => item.locationId,
          ),
        }))
        .map((item) => [
          item,
          ...getChildrenOfParentLocation(item.id)
            .map((item) => [item, ...getProductsOfChildrenLocation(item.id)])
            .flat(1),
        ])
        .flat(1)
    : reOrderParentLocation
        .map((item) => ({
          ...item,
          childrenIds: getChildrenOfParentLocation(item.id).map(
            (item) => item.id,
          ),
          childrenLocationIds: getChildrenOfParentLocation(item.id).map(
            (item) => item.locationId,
          ),
        }))
        .map((item) => [item, ...getChildrenOfParentLocation(item.id)])
        .flat(1)
}

export const reOrderedTradeAgreementsNameData = (data, reOrderedIds) => {
  const getOverallParentLocation = data.filter(
    (item) => item.level === FIRST_LEVEL,
  )
  const getOverallChildrenLocation = data.filter(
    (item) => item.level === SECOND_LEVEL,
  )
  const getProducts = data.filter(
    (item) => item.level === MIDDLE_LOCATION_LEVEL,
  )

  const overallParentLocationIds = getOverallParentLocation?.map(
    (item) => item.id,
  )
  const overallChildrenLocationIds = getOverallChildrenLocation?.map(
    (item) => item.id,
  )

  const reOrderParentLocation = reOrderedIds
    ? reOrderedIds
        ?.filter((item) => overallParentLocationIds.includes(item))
        .map(
          (item) =>
            getOverallParentLocation?.find((prod) => prod.id === item) ?? 0,
        )
    : getOverallParentLocation

  const reOrderChildrenLocation = reOrderedIds
    ? reOrderedIds
        ?.filter((item) => overallChildrenLocationIds.includes(item))
        .map(
          (item) =>
            getOverallChildrenLocation?.find((prod) => prod.id === item) ?? 0,
        )
    : getOverallChildrenLocation

  const getProductsOfChildrenLocation = (childrenLocationId, areaId) => {
    return getProducts.filter(
      (item) => item.parentId === childrenLocationId && item.areaId === areaId,
    )
  }

  const getChildrenOfParentLocation = (parentLocationId) => {
    return reOrderChildrenLocation.filter(
      (item) => item.parentId === parentLocationId,
    )
  }

  return getProducts.length
    ? reOrderParentLocation
        .map((item) => ({
          ...item,
          childrenIds: getChildrenOfParentLocation(item.locationId).map(
            (item) => item.id,
          ),
          childrenLocationIds: getChildrenOfParentLocation(item.locationId).map(
            (item) => item.locationId,
          ),
        }))
        .map((item) => [
          item,
          ...getChildrenOfParentLocation(item.locationId)
            .map((item) => [
              item,
              ...getProductsOfChildrenLocation(item.locationId, item.areaId),
            ])
            .flat(1),
        ])
        .flat(1)
    : reOrderParentLocation
        .map((item) => ({
          ...item,
          childrenIds: getChildrenOfParentLocation(item.locationId).map(
            (item) => item.id,
          ),
          childrenLocationIds: getChildrenOfParentLocation(item.locationId).map(
            (item) => item.locationId,
          ),
        }))
        .map((item) => [item, ...getChildrenOfParentLocation(item.locationId)])
        .flat(1)
}

export const fullDataWithVal = (
  dataContainVal,
  currentTypeFilter,
  dataWithOnlyTitle,
  typeData,
) => {
  const getDataKeyFromRange =
    currentTypeFilter === TABLE_FILTER[0].value
      ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
      : getMonthAndYearInDataRange(dataContainVal).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )

  const getDataValueFromRange =
    currentTypeFilter === TABLE_FILTER[0].value
      ? getYearInDataRange(dataContainVal).map((item) => [
          ...getDataByYear(dataContainVal, item.year),
        ])
      : getMonthAndYearInDataRange(dataContainVal).map((item) => [
          ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
        ])

  const getCorrespondingDataWithKey = (data, item) => {
    return data[data.indexOf(data.find((i) => i.id === item.id))]?.value
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )

    return dataKeyAndValRange
  }

  return dataWithOnlyTitle.map((item, index) => ({
    ...item,
    type: typeData,
    order: index,
    ...getKeyAndValRange(item),
  }))
}

export const handleGeneralState = (state, data) => {
  state.idsCategory = state.initialIds = data?.map((v) => v.id)
  state.data = data
  state.dataById = keyBy(data, 'id')

  state.isChangeLocale = false
  state.isChangeFilter = false

  state.levels = data.map((item) => ({
    id: item.id,
    parentId: item.parentId,
    level: item.level,
  }))

  state.levelCollapse = keyBy(
    data?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.isCollapse,
    })),
    'id',
  )

  const getGroupColumns = data.length
    ? data
        ?.map((item) => Object.keys(item))[0]
        .filter((item) => /\d/.test(item))
    : []

  const monthInColKey = (colKey) => {
    return Number(colKey.slice(0, 2)) < 10
      ? Number(colKey.slice(1, 2))
      : Number(colKey.slice(0, 2))
  }
  const yearInColKey = (colKey) => {
    return colKey.slice(3, 7)
  }

  const getFilterGroupColumns = () => {
    if (state.currentTypeFilter === TIME_TYPE.YEARLY) {
      return getGroupColumns.map((item) =>
        getYearKey(Number(item), state.maxDate, state.locale),
      )
    } else if (state.currentTypeFilter === TIME_TYPE.MONTHLY) {
      return reOrderByMonthAndYear(getGroupColumns)
    } else {
      return reOrderByMonthAndYear(getGroupColumns)?.map((col) =>
        getAccMonthAndYearKey(
          monthInColKey(col),
          yearInColKey(col),
          state.locale,
        ),
      )
    }
  }

  const filterGroupColumns = getFilterGroupColumns()
  const groupColumns = []
  filterGroupColumns?.forEach((item) => {
    groupColumns.push({
      key: getGroupColumns[filterGroupColumns.indexOf(item)],
      title: item,
    })
  })
  state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]

  state.isChangeFilter = false

  state.currentMinMonth = groupColumns[0]?.key.slice(0, 2)
  state.currentMinYear = groupColumns[0]?.key.slice(3, 7)
}

export const handleImportByLocation = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.locationId,
      parentId: item.parentLocationId,
      locationId: item.locationId,
      productName: item.vnTypeName,
      level:
        item.locationLevel === MIDDLE_LOCATION_LEVEL
          ? SECOND_LEVEL
          : item.locationLevel === LOWEST_LOCATION_LEVEL
          ? THIRD_LEVEL
          : item.locationLevel,
      isCollapse: item.locationLevel !== LOWEST_LOCATION_LEVEL,
      name:
        item.locationLevel === LOWEST_LOCATION_LEVEL
          ? item.vnTypeName
          : item.locationName,
    })) || []

  const payloadData =
    action.payload?.data?.map((item) => ({
      id: item.locationId,
      parentId: item.parentLocationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level:
        item.locationLevel === MIDDLE_LOCATION_LEVEL
          ? SECOND_LEVEL
          : item.locationLevel === LOWEST_LOCATION_LEVEL
          ? THIRD_LEVEL
          : item.locationLevel,
      name: getNameById(payloadNameData, item.locationId),
      isCollapse: item.locationLevel !== LOWEST_LOCATION_LEVEL,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  state.payloadData = state.payloadDataParents = payloadData

  const uniqueLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    parentId: item.parentId,
    locationId: item.locationId,
    isCollapse: true,
  }))

  state.payloadNameData = reOrderedNameData(uniqueLocation)

  const data = payloadData.length
    ? fullDataWithVal(
        payloadData,
        state.currentTypeFilter,
        reOrderedNameData(uniqueLocation),
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data?.filter(
    (item) => item.level !== LOWEST_LOCATION_LEVEL,
  )

  state.listCategory = state.listOrigParentCategory.slice(0, 5)

  handleGeneralState(state, data)
  state.rowsCollapse = data
    ?.filter((item) => item.level === SECOND_LEVEL)
    .map((item) => item.id)
}

export const handleImportByLocationChildren = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadData =
    action.payload?.data
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}`,
        productId: item.vnTypeId,
        name: getNameById(payloadNameData, item.vnTypeId),
        parentId: action.meta.arg.ParentIndex,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    locationId: item.locationId,
    name: item.name,
    level: item.level,
    isCollapse: false,
    parentId: action.meta.arg.LocationIds,
  }))

  state.payloadNameData = state.sortType
    ? reOrderedNameData(
        [...state.payloadNameData, ...uniqueChildrenLocation],
        state.idsCategory,
      )
    : reOrderedNameData([...state.payloadNameData, ...uniqueChildrenLocation])

  state.payloadData = [...state.payloadData, ...payloadData]

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      ).filter((item) => !(!item.name && item.level === LOWEST_LOCATION_LEVEL))
    : [...state.data]

  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByLocationChildrenAfterScroll = (state, action) => {
  state.isLoading = false

  const payloadData =
    action.payload
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}`,
        productId: item.vnTypeId,
        parentId: item.locationId,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    locationId: item.locationId,
    isCollapse: false,
  }))

  state.payloadNameData = reOrderedNameData([
    ...state.payloadNameData,
    ...uniqueChildrenLocation,
  ])

  state.payloadData = [...state.payloadData, ...payloadData]

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]

  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByLocationAfterScroll = (state, action) => {
  state.isLoading = false
  const payloadData =
    action.payload?.map((item) => ({
      id: item.locationId,
      parentId: item.parentLocationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level:
        item.locationLevel === MIDDLE_LOCATION_LEVEL
          ? SECOND_LEVEL
          : item.locationLevel === LOWEST_LOCATION_LEVEL
          ? THIRD_LEVEL
          : item.locationLevel,
      isCollapse: item.locationLevel !== LOWEST_LOCATION_LEVEL,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []

  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...state.payloadData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]
  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportByLocationAfterChangeFilter = (state, action) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.locationId,
      parentId: item.parentLocationId,
      locationId: item.locationId,
      productName: item.vnTypeName,
      level:
        item.locationLevel === MIDDLE_LOCATION_LEVEL
          ? SECOND_LEVEL
          : item.locationLevel === LOWEST_LOCATION_LEVEL
          ? THIRD_LEVEL
          : item.locationLevel,
      isCollapse: item.locationLevel !== LOWEST_LOCATION_LEVEL,
      name:
        item.locationLevel === LOWEST_LOCATION_LEVEL
          ? item.vnTypeName
          : item.locationName,
    })) || []

  const payloadChildrenNameData =
    action.payload?.childrenNameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadData =
    action.payload?.data?.map((item) => ({
      id: item.locationId,
      parentId: item.parentLocationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level:
        item.locationLevel === MIDDLE_LOCATION_LEVEL
          ? SECOND_LEVEL
          : item.locationLevel === LOWEST_LOCATION_LEVEL
          ? THIRD_LEVEL
          : item.locationLevel,
      name: getNameById(payloadNameData, item.locationId),
      isCollapse: item.locationLevel !== LOWEST_LOCATION_LEVEL,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  const uniqueLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    parentId: item.parentId,
    locationId: item.locationId,
    isCollapse: true,
  }))

  const payloadChildrenData =
    action.payload?.childrenData
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}`,
        productId: item.vnTypeId,
        name: getNameById(payloadChildrenNameData, item.vnTypeId),
        parentId: item.locationId,
        locationId: item.locationId,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadChildrenData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    locationId: item.locationId,
    name: item.name,
    level: item.level,
    isCollapse: false,
    parentId: item.locationId,
  }))

  state.payloadNameData = reOrderedNameData([
    ...uniqueLocation,
    ...uniqueChildrenLocation,
  ])
  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...payloadChildrenData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data?.filter(
    (item) => item.level !== LOWEST_LOCATION_LEVEL,
  )

  state.listCategory = [...state.listCategory].map((item) => ({
    ...item,
    name: getNameById(uniqueLocation, item.id),
  }))

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportTradeAgreementsByLocation = (state, action) => {
  state.isLoading = false
  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? `${item.parentLocationId}_${item.locationId}`
          : item.locationId,
      name: item.locationName,
      level:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? SECOND_LEVEL
          : FIRST_LEVEL,
      parentId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.parentLocationId
          : item.locationId,
      locationId: item.locationId,
      isCollapse: true,
      areaId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.areaId
          : item.locationId,
    })) || []

  const payloadData =
    action.payload?.data.map((item) => ({
      id:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? `${item.parentLocationId}_${item.locationId}`
          : item.locationId,
      parentId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.parentLocationId
          : item.locationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? SECOND_LEVEL
          : FIRST_LEVEL,
      name: getNameById(payloadNameData, item.locationId),
      isCollapse: true,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  state.payloadData = state.payloadDataParents = payloadData

  const uniqueLocation = Object.values(
    payloadNameData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    parentId: item.parentId,
    locationId: item.locationId,
    isCollapse: true,
    areaId: item.parentId,
  }))

  state.payloadNameData = reOrderedTradeAgreementsNameData(uniqueLocation)

  const data = payloadData.length
    ? fullDataWithVal(
        payloadData,
        state.currentTypeFilter,
        reOrderedTradeAgreementsNameData(uniqueLocation),
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data?.filter(
    (item) => item.level !== THIRD_LEVEL,
  )

  state.listCategory = state.listOrigParentCategory.slice(0, 5)

  handleGeneralState(state, data)

  state.rowsCollapse = data
    ?.filter((item) => item.level === SECOND_LEVEL)
    .map((item) => item.id)
}

export const handleImportTradeAgreementsByLocationChildren = (
  state,
  action,
) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadData =
    action.payload?.data
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}_${item.areaId}`,
        productId: item.vnTypeId,
        locationId: item.locationId,
        name: getNameById(payloadNameData, item.vnTypeId),
        parentId: action.meta.arg.ParentIndex,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    isCollapse: false,
    parentId: action.meta.arg.LocationIds,
    areaId: action.meta.arg.areaIds,
  }))

  state.payloadNameData = state.sortType
    ? reOrderedTradeAgreementsNameData(
        [...state.payloadNameData, ...uniqueChildrenLocation],
        state.idsCategory,
      )
    : reOrderedTradeAgreementsNameData([
        ...state.payloadNameData,
        ...uniqueChildrenLocation,
      ])

  state.payloadData = [...state.payloadData, ...payloadData]

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]
  state.listCategory = [...state.listCategory]
  handleGeneralState(state, data)
}

export const handleImportTradeAgreementsByLocationChildrenAfterScroll = (
  state,
  action,
) => {
  state.isLoading = false

  const payloadData =
    action.payload
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}_${item.areaId}`,
        productId: item.vnTypeId,
        locationId: item.locationId,
        parentId: action.meta.arg.ParentIndex,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    locationId: item.locationId,
    isCollapse: false,
    areaId: item.areaId,
  }))

  state.payloadNameData = reOrderedNameData([
    ...state.payloadNameData,
    ...uniqueChildrenLocation,
  ])

  state.payloadData = [...state.payloadData, ...payloadData]

  const data = state.payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]
  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportTradeAgreementsByLocationAfterScroll = (
  state,
  action,
) => {
  state.isLoading = false
  const payloadData =
    action.payload?.map((item) => ({
      id:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? `${item.parentLocationId}_${item.locationId}`
          : item.locationId,
      parentId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.parentLocationId
          : item.locationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level: item.level,
      isCollapse: true,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []

  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...state.payloadData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.data,
        state.typeData,
      )
    : [...state.data]
  state.listCategory = [...state.listCategory]

  handleGeneralState(state, data)
  state.rowsCollapse = [...state.rowsCollapse]
}

export const handleImportTradeAgreementsByLocationAfterChangeFilter = (
  state,
  action,
) => {
  state.isLoading = false

  const payloadNameData =
    action.payload?.nameData.map((item) => ({
      id:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? `${item.parentLocationId}_${item.locationId}`
          : item.locationId,
      name: item.locationName,
      level:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? SECOND_LEVEL
          : FIRST_LEVEL,
      parentId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.parentLocationId
          : item.locationId,
      locationId: item.locationId,
      isCollapse: true,
      areaId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.areaId
          : item.locationId,
    })) || []

  const payloadChildrenNameData =
    action.payload?.childrenNameData.map((item) => ({
      id: item.vnTypeId,
      name: item.vnTypeName,
    })) || []

  const payloadData =
    action.payload?.data.map((item) => ({
      id:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? `${item.parentLocationId}_${item.locationId}`
          : item.locationId,
      parentId:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? item.parentLocationId
          : item.locationId,
      parentLocationId: item.parentLocationId,
      locationId: item.locationId,
      productId: item.vnTypeId,
      parentLocationName: item.parentLocationName ?? item.locationName,
      locationName: item.locationName,
      productName: item.vnTypeName,
      year: item.realYear,
      month: item.realMonth,
      level:
        item.locationLevel === MIDDLE_TRADE_AGREEMENTS_LEVEL
          ? SECOND_LEVEL
          : FIRST_LEVEL,
      name: getNameById(payloadNameData, item.locationId),
      isCollapse: true,
      value:
        state.typeData === TYPE_DATA.GROWTH
          ? item.growth
          : item.value / 1000000,
    })) || []
  const uniqueLocation = Object.values(
    payloadNameData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    parentId: item.parentId,
    locationId: item.locationId,
    isCollapse: true,
    areaId: item.parentId,
  }))

  const payloadChildrenData =
    action.payload?.childrenData
      ?.filter((item) => item.vnTypeId)
      .map((item) => ({
        id: `${item.vnTypeId}_${item.locationId}_${item.areaId}`,
        productId: item.vnTypeId,
        locationId: item.locationId,
        areaId: item.areaId,
        name: getNameById(payloadChildrenNameData, item.vnTypeId),
        parentId: item.locationId,
        year: item.realYear,
        month: item.realMonth,
        level: THIRD_LEVEL,
        isCollapse: false,
        value:
          state.typeData === TYPE_DATA.GROWTH
            ? item.growth
            : item.value / 1000000,
      })) || []

  const uniqueChildrenLocation = Object.values(
    payloadChildrenData.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  ).map((item) => ({
    id: item.id,
    name: item.name,
    level: item.level,
    isCollapse: false,
    parentId: item.locationId,
    areaId: item.areaId,
  }))

  state.payloadNameData = reOrderedTradeAgreementsNameData([
    ...uniqueLocation,
    ...uniqueChildrenLocation,
  ])
  state.payloadData = state.payloadDataParents = [
    ...payloadData,
    ...payloadChildrenData,
  ]

  const data = payloadData.length
    ? fullDataWithVal(
        state.payloadData,
        state.currentTypeFilter,
        state.payloadNameData,
        state.typeData,
      )
    : [...state.data]
  state.listOrigParentCategory = data?.filter(
    (item) => item.level !== LOWEST_LOCATION_LEVEL,
  )

  state.listCategory = [...state.listCategory].map((item) => ({
    ...item,
    name: getNameById(uniqueLocation, item.id),
  }))

  handleGeneralState(state, data)

  state.rowsCollapse = [...state.rowsCollapse]
}

export const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}

export const getNameById = (data, id) => {
  return data?.find((item) => item.id === id)?.name || ''
}
