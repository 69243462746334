import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleLineChartData } from '../helper'
import { getCompareOtherBank } from './thunk'

const DEFAULT_ID = 186

const initialState = {
  timeRange: 'ThreeMonths',
  lineChartData: [],
  lineChartLoading: true,
  activeLine: undefined,
  dropdownValue: DEFAULT_ID,
}

const slice = createSlice({
  name: 'economy/monetary/interestRate/panelStatisticalInterestRate',
  initialState,
  reducers: {
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
    handleDropdownValue: (state, action) => {
      state.dropdownValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCompareOtherBank.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getCompareOtherBank.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = handleLineChartData(action.payload || [])
    })
    builder.addCase(getCompareOtherBank.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const { handleTimeRange, handleActiveLine, handleDropdownValue } =
  slice.actions

export const getTimeRange = (state) => state[slice.name].timeRange
export const getLineChartData = (state) => state[slice.name].lineChartData
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getActiveLine = (state) => state[slice.name].activeLine
export const getDropdownValue = (state) => state[slice.name].dropdownValue

register(slice.name, slice.reducer)
