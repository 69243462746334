import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import useGetStockHOHAUP from '../../../common/masterData/useGetStockHOHAUP'
import Popup from '../../../common/popup'
import TextEllipsis from '../../../common/textEllipsis'
import {
  applyTemplate,
  changeShowPreview,
  selectTemplateActive,
} from './store/slice'
import { deleteTemplate, updateTemplate } from './store/thunk'
import style from './style.module.css'

const idDivFooterScroll = 'div-footer-scroll'

const ItemReport = ({ item, index }) => {
  const dispatch = useDispatch()
  const templateRef = useRef()
  const navigate = useCustomNavigate()
  const location = useLocation()
  const { stocksHohaup } = useGetStockHOHAUP()

  const [templateEdit, setTemplateEdit] = useState({})
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isDropdownTop, setIsDropdownTop] = useState(false)
  const [isShowPopupDelete, setShowPopupDelete] = useState(false)
  const [template, setTemplate] = useState({})
  const [isShowEdit, setIsShowEdit] = useState(false)

  const templateSelect = useSelector(selectTemplateActive)

  const handleApplyTemplate = (item) => {
    const { organizationIdTemplate } = JSON.parse(item.templateParameters)
    if (organizationIdTemplate) {
      const company = stocksHohaup.find(
        (ticker) => ticker.organizationId === organizationIdTemplate,
      )
      if (company)
        navigate(location.pathname, {
          organizationId: company.organizationId,
          groupId: null,
          ticker: company.tickerOrTaxCode,
          companyName: company.organizationShortName,
          exchange: company.exchangeCode,
          sector: company.sector,
        })
    }
    dispatch(applyTemplate(item))
    dispatch(changeShowPreview(false))
  }

  const handleClose = () => {
    setShowPopupDelete(false)
  }

  const handleEditNameTemplate = (val) => {
    setIsShowEdit(true)
    setTemplateEdit(val)
  }

  const handleDeleteTemplate = (val) => {
    setShowPopupDelete(true)
    setTemplate(val)
  }

  const handleOpenDropdown = () => {
    const heightFromFooter =
      document.querySelector(`#${idDivFooterScroll}`).getBoundingClientRect()
        .top - templateRef.current.getBoundingClientRect().top
    if (heightFromFooter <= 75) setIsDropdownTop(true)
    else setIsDropdownTop(false)

    setIsShowDropdown(true)
  }

  const handleCloseDropdown = () => setIsShowDropdown(false)

  return (
    <>
      <li key={item.templateId} ref={templateRef}>
        {isShowEdit && templateEdit.templateId === item.templateId ? (
          <EditName item={item} setIsShowEdit={setIsShowEdit} />
        ) : (
          <>
            <div
              className={`${style.textTemplate} ${
                templateSelect.templateId === item.templateId
                  ? style.active
                  : undefined
              }`}
              onClick={() => handleApplyTemplate(item)}
            >
              <TextEllipsis
                isI18n={false}
                text={`${index + 1}. ${item.templateName}`}
                isHardWidth={false}
              />
            </div>
            <div
              className={style.btnMore}
              onMouseOver={handleOpenDropdown}
              onMouseOut={handleCloseDropdown}
            >
              <i className="icon-menu-dot-hor" />
              <div
                className={`${style.dropdownMore} ${
                  isDropdownTop ? style.dropdownTop : ''
                }`}
                style={{ display: isShowDropdown ? 'block' : 'none' }}
              >
                <ul>
                  <li>
                    <button
                      className="cursor-pointer"
                      onClick={() => handleEditNameTemplate(item)}
                      style={{ width: '100%', textAlign: 'start' }}
                    >
                      <i
                        className="icon-edit"
                        style={{ display: 'inline-block', width: '8px' }}
                      />
                      <Span>
                        <Translate value="sector.sectorConstituents.segmentation.RENAME" />
                      </Span>
                    </button>
                  </li>
                  <li>
                    <button
                      className="cursor-pointer"
                      onClick={() => handleDeleteTemplate(item)}
                      style={{ width: '100%', textAlign: 'start' }}
                    >
                      <i
                        className="icon-trash"
                        style={{ display: 'inline-block', width: '8px' }}
                      />
                      <Span>
                        <Translate value="sector.sectorConstituents.segmentation.DELETE" />
                      </Span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </li>
      <PopupDeleteTemplate
        isShow={isShowPopupDelete}
        handleClose={handleClose}
        template={template}
      />
    </>
  )
}

export default ItemReport

const EditName = ({ item, setIsShowEdit }) => {
  const dispatch = useDispatch()

  const [valueInput, setValueInput] = useState(item.templateName)

  const handleBlur = () => {
    setIsShowEdit(false)
  }

  const handleSubmit = () => {
    dispatch(
      updateTemplate({
        templateId: item.templateId,
        templateName: valueInput,
        templateParameters: item.templateParameters,
      }),
    )
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    setValueInput(value)
  }

  return (
    <>
      <div className={style.inputForm}>
        <input
          type="text"
          value={valueInput}
          onChange={handleChangeInput}
          className={style.inputTemplate}
          autoFocus
          onBlur={handleBlur}
        />
        <button
          onMouseDown={handleSubmit}
          className={`btn-ok cursor-pointer ${style.btnOk}`}
        >
          <i className="icon-true" />
        </button>
      </div>
    </>
  )
}

const PopupDeleteTemplate = ({ isShow, handleClose, template }) => {
  const dispatch = useDispatch()

  const onCancel = () => {
    handleClose()
  }

  const onSubmit = () => {
    dispatch(deleteTemplate({ TemplateId: template.templateId }))
    handleClose()
  }

  return (
    <>
      {isShow && (
        <Popup>
          <div className="modal modal-small">
            <div className="modal-title">
              <h3>
                <Translate value="corporate.companySnapshot.DELETE_TEMPLATE" />
              </h3>
              <a onClick={handleClose} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <Translate value="corporate.companySnapshot.TEXT_DELETE_TEMPLATE" />
              {template.templateName}
              <Translate value="corporate.companySnapshot.TEXT_DELETE_TEMPLATE1" />
              <div className="group-btn justify-content-end">
                <Button
                  onClick={onSubmit}
                  className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                  style={{ fontSize: 11 }}
                >
                  <Translate value="corporate.companySnapshot.DELETE" />
                </Button>
                <Button
                  onClick={onCancel}
                  className={`btn btn-blue w-80 h-20 ${style.fontWeightNormal}`}
                  style={{ fontSize: 11 }}
                >
                  <Translate value="corporate.companySnapshot.CANCEL" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}
