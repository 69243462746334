import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OPEN_MARKET_OPERATIONS } from '.'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { handleLineColor } from '../../panelOpenMarket/helper'
import {
  getCheckboxChecked,
  getLineChartColor,
  getTableMapping,
  getTimeRange as getTimeRangeOpenMarket,
  handleCheckboxChecked,
  handleLineChartColor,
} from '../../panelOpenMarket/store/slice'
import style from '../index.module.css'
import ChartComponent from './ChartComponent'
import { getColor, handleLineChartHasAuthor } from './helper'
import {
  getChartData,
  getChartLoading,
  getDailyValue,
  getMonthlyValue,
  getWeeklyValue,
  handleActiveLine,
} from './store/slice'
import { getOpenMarketDisplay } from './store/thunk'

const Container = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const chartData = useSelector(getChartData)
  const chartLoading = useSelector(getChartLoading)
  const locale = useSelector((state) => state.i18n.locale)
  const openMarketTimeRange = useSelector(getTimeRangeOpenMarket)
  const dailyValue = useSelector(getDailyValue)
  const weeklyValue = useSelector(getWeeklyValue)
  const monthlyValue = useSelector(getMonthlyValue)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const lineChartColor = useSelector(getLineChartColor)
  const tableMapping = useSelector(getTableMapping)

  const lineChartHasAuthor = handleLineChartHasAuthor(
    lineChartColor,
    tableMapping,
  )
  const typeIds = checkboxChecked.map((item) => item.typeId)
  const ids = checkboxChecked.map((item) => item.id)

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  const getTimeRange = (openMarketTimeRange) => {
    switch (openMarketTimeRange) {
      case 'Weekly':
        return weeklyValue
      case 'Monthly':
        return monthlyValue
      default:
        return dailyValue
    }
  }

  // Actions
  const handleDelete = (item) => {
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => val.id !== item.author,
    )

    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(handleLineColor(item.author, lineChartColor, true)),
    )
    dispatch(handleActiveLine(undefined))
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(item.author))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  // Use effect
  useEffect(() => {
    dispatch(
      getOpenMarketDisplay({
        TimeFrequency: openMarketTimeRange,
        TimeRange: getTimeRange(openMarketTimeRange),
        TypeIds: typeIds.toString(),
      }),
    )
  }, [
    locale,
    openMarketTimeRange,
    dailyValue,
    weeklyValue,
    monthlyValue,
    checkboxChecked,
  ])

  if (chartLoading) {
    return (
      <div className={`${style.container} h-100`}>
        <Loading />
      </div>
    )
  }

  if (!chartData.length) {
    return (
      <div className={`${style.container} h-100`}>
        <NoData />
      </div>
    )
  }

  return (
    <div className={`${style.container} h-100`} id={OPEN_MARKET_OPERATIONS}>
      <ChartComponent
        height={height}
        width={width}
        data={chartData}
        ids={ids}
        keyXAxis={openMarketTimeRange === 'Monthly' ? 'monthYear' : 'day'}
        heightListIndicator={heightListIndicator}
      />
      <FooterWithScroll
        width={width}
        data={lineChartHasAuthor}
        idKey="author"
        nameKey="indicatorName"
        color={(item) => getColor(item.author, item.color)}
        legendPanel={legendPanel}
        isFullScreen={isFullScreen}
        setHeightFooter={setHeightListIndicator}
        onDelete={handleDelete}
        onHover={handleHover}
        onUnHover={handleUnHover}
      />
    </div>
  )
}

export default Container
