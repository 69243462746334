export default class EventBus {
  channels = {}

  subscribe(channel, action) {
    if (!this.channels[channel]) {
      this.channels[channel] = []
    }
    this.channels[channel].push(action)
  }

  unsubscribe(channel, action) {
    if (this.channels[channel]) {
      this.channels[channel] = this.channels[channel].filter(
        (a) => a !== action,
      )
    }
  }

  publish(channel, data) {
    if (this.channels[channel]) {
      this.channels[channel].forEach((action) => {
        action(data)
      })
    }
  }
}
