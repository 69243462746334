import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DerivativeService from '../../../../../../core/services/market/DerivativeService'
import { handleExport } from '../../../../../utils/Export'

export const getForeignOverview = createAsyncThunk(
  'market/derivativeStatistics/foreign/getForeignOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getForeignOverview(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getForeignDetail = createAsyncThunk(
  'market/derivativeStatistics/foreign/getForeignDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getForeignDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getForeignTicker = createAsyncThunk(
  'market/derivativeStatistics/foreign/getForeignTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getForeignTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadForeignOverview = createAsyncThunk(
  'market/derivativeStatistics/foreign/downloadForeignOverview',
  async (data) => {
    const response = await DerivativeService.downloadForeignOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadForeignDetail = createAsyncThunk(
  'market/derivativeStatistics/foreign/downloadForeignDetail',
  async (data) => {
    const response = await DerivativeService.downloadForeignDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadForeignTicker = createAsyncThunk(
  'market/derivativeStatistics/foreign/downloadForeignTicker',
  async (data) => {
    const response = await DerivativeService.downloadForeignTicker(data)
    handleExport(response.data, response.filename)
  },
)
