import { Translate } from 'react-redux-i18n'
import { handleDuplicateArray, uuid } from '../../utils/Common'
import EventEmitter, {
  CALLBACK_SHOW_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../../utils/EventEmitter'
import { formatVal } from '../../utils/Value'
import {
  blankValue,
  maxCondition,
  selectionType,
  typeData,
  unitType,
} from './constant'
import { callbackErrorAddCondition } from './store/slice'

export const checkDaily = (type, valueIf, valueElse) => {
  return type === typeData.DAILY ? valueIf : valueElse
}

export const checkRadioButton = (type, valueIf, valueElse) => {
  return type === selectionType.RADIO_BUTTON ? valueIf : valueElse
}

export const handleDataInitCondition = (dataTypeInterim) => {
  return {
    ...dataTypeInterim,
    additionalCondition: checkDaily(
      dataTypeInterim.dataTypeName,
      {
        operator: blankValue,
        indicatorInterimId: blankValue,
        interimCode: blankValue,
      },
      {
        ...dataTypeInterim.additionalCriterias[0],
        operator: blankValue,
        indicatorInterimId: blankValue,
        interimCode: blankValue,
      },
    ),
    indicatorInterimId: dataTypeInterim.interims[0].indicatorInterimId,
    interimCode: dataTypeInterim.interims[0].interimCode,
    conditionValues: checkRadioButton(
      dataTypeInterim.selectionType,
      [dataTypeInterim.selectionValues?.[0].value],
      ['', ''],
    ),
  }
}

export const checkMaxCondition = (lengthConditions, callback, arrCondition) => {
  if (lengthConditions > maxCondition) {
    if (arrCondition) {
      EventEmitter.dispatch(
        CALLBACK_SHOW_POPUP_ERROR,
        callbackErrorAddCondition(arrCondition),
      )
    }
    EventEmitter.dispatch(SHOW_POPUP_ERROR, [
      <Translate value="tool.smartScreening.MAX_CONDITION" />,
    ])
  } else callback()
}

export const formatValues = (value, unit) => {
  if (value) {
    if (unit === unitType.PERCENT) {
      return formatVal(value, 10, false, '', 0) + '%' || ''
    } else {
      return formatVal(value, 10, false, '', 0) || ''
    }
  }
  return value
}

export const cancelFormatValues = (value, unit) => {
  if (value) {
    if (unit === unitType.PERCENT) {
      return value.replace(/,/g, '').replace('%', '') || ''
    } else {
      return value.replace(/,/g, '') || ''
    }
  }
  return value
}

export const convertDataSubmit = ({
  ICBsById,
  sectors,
  exchangesById,
  exchanges,
  screenerDate,
  conditionIds,
  conditions,
  addMoreConditionIds,
  addMoreConditionByIds,
  addedPeriod,
}) => {
  const data = {
    sectors: [],
    exchanges: [],
    screenerDate,
    conditions: [],
    addedInformations: [],
    addedPeriod: {},
  }

  data.sectors = handleDuplicateArray(
    sectors.reduce(
      (arr, id) => (ICBsById[id] ? [...arr, ICBsById[id].icbCode] : arr),
      [],
    ),
  )
  data.exchanges = handleDuplicateArray(
    exchanges.reduce(
      (arr, id) =>
        exchangesById[id] ? [...arr, exchangesById[id]?.groupCode] : arr,
      [],
    ),
  )

  let order = 1
  conditionIds.forEach((id) => {
    conditions[id].forEach((condition) => {
      data.conditions.push({
        ...condition,
        id: uuid(),
        order,
        conditionValues: checkRadioButton(
          condition.selectionType,
          condition.conditionValues,
          [
            condition.conditionValues[0] === '' ||
            condition.conditionValues[0] === null
              ? null
              : +condition.conditionValues[0],
            condition.conditionValues[1] === '' ||
            condition.conditionValues[1] === null
              ? null
              : +condition.conditionValues[1],
          ],
        ),
        children: undefined,
        interims: undefined,
        additionalCriterias: undefined,
        selectionValues: undefined,
      })
      order += 1
    })
  })

  if (addMoreConditionIds.length > 0) {
    data.addedInformations = addMoreConditionIds.map((id, index) => {
      return {
        indicatorId: addMoreConditionByIds[id].indicatorId,
        tableCode: addMoreConditionByIds[id].tableCode,
        tableFieldCode: addMoreConditionByIds[id].tableFieldCode,
        id: uuid(),
        order: index + 1,
      }
    })
    data.addedPeriod = addedPeriod
  }

  return data
}
