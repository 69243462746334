export const derivative = {
  HEAD_TABLE_TEXT: 'Khối lượng: x 1,000. Giá trị: x 1,000,000.',
  TICKER: 'Mã',
  EXPIRY_DATE: 'Ngày đáo hạn',
  REFERENCE: 'Tham chiếu',
  PRICE: 'Giá',
  CHANGE: 'Thay đổi',
  PERCENT_CHANGE: '% Thay đổi 1N',
  VOLUME: 'Khối lượng',
  BASIC: 'Độ lệch',
  OI: 'Hợp đồng mở',
  SUMMARY: 'Tổng quan',
  TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  TIME_AND_SALES: 'Time & Sales',
  LAST_PRICE: 'Giá',
  BU_VOLUME: 'KL mua chủ động',
  SD_VOLUME: 'KL bán chủ động',
  UNIDENTIFIED: 'Không xác định',
  PRICE_DEPTH: 'Chi tiết bước giá',
  BID: 'Giá Mua',
  ASK: 'Giá Bán',
  BUY_VOL: 'KL MUA',
  SELL_VOL: 'KL BÁN',
  TOTAL_BUY_VOL: 'TỔNG KL MUA',
  TOTAL_MATCHED_VOL: 'TỔNG KL KHỚP',
  TOTAL_SELL_VOL: 'TỔNG KL BÁN',
  TIME: 'Thời gian',
  TYPE: 'Kiểu',
  MATCH_VOL: 'KL khớp',
  TOTAL_MATCH_VOL: 'Tổng KL khớp',
  ONE_DAY: '1D',
  FIVE_DAY: '5D',
  NINE_DAY: '10D',
  TWENTY_DAY: '20D',
  DERIVATIVE: 'Phái sinh',
  OVERVIEW: ' Tổng quan',
  HISTORICAL_PRICE: 'Lịch sử giá',
  FOREIGN: 'Giao dịch nước ngoài',
  ORDER_STATISTICS: 'Thống kê đặt lệnh',
  PROPRIETARY: 'Tự doanh',
  PRICE_DATA: 'Thống kê giá',
  DERIVATIVE_STATISTICS: 'Thống kê phái sinh',
  DATE: 'Ngày',
  UNMATCHED_BUY: 'Dư mua',
  UNMATCHED_SELL: 'Dư bán',
  BUY_ORDERS: 'Số lệnh mua',
  BUY_VOLUME: 'Tổng KL mua',
  AVG_BUY: 'Trung bình lệnh mua (CP)',
  SELL_ORDERS: 'Số lệnh bán',
  SELL_VOLUME: 'Tổng KL bán',
  AVG_SELL: 'Trung bình lệnh bán (CP)',
  NET_BUY_SELL: 'KL ròng',
  BUY_VALUE: 'Tổng GT mua',
  SELL_VALUE: 'Tổng GT bán',
  NET_VOLUME: 'Tổng KL ròng',
  NET_VALUE: 'Tổng GT ròng',
  ORDER_MATCHING_BUY_VOL: 'KL mua khớp lệnh',
  ORDER_MATCHING_BUY_VAL: 'GT mua khớp lệnh',
  ORDER_MATCHING_SELL_VOL: 'KL bán khớp lệnh',
  ORDER_MATCHING_SELL_VAL: 'GT bán khớp lệnh',
  PUT_THROUGH_BUY_VOL: 'KL mua thoả thuận',
  PUT_THROUGH_BUY_VAL: 'GT mua thoả thuận',
  PUT_THROUGH_SELL_VOL: 'KL bán thoả thuận',
  PUT_THROUGH_SELL_VAL: 'GT bán thoả thuận',
  CW_STATISTICS: 'THỐNG KÊ CHỨNG QUYỀN',
  NET_VALUE_INVESTOR: 'GT ròng theo nhà đầu tư',
  NET_TRADING_BREAKDOWN: 'Phân tách giá trị giao dịch',
  ALL: 'Tất cả',
  DETAIL: 'Chi tiết',
  NOTE_TABLE: 'Giá trị: x 1,000,000.',
  FROM: 'Từ',
  TO: 'Đến',
  CLEAR: 'Xóa',
  TICKER_TABLE: 'Mã',
  ALL_MARKET: 'Toàn thị trường',
  TRADING_DATE: 'Ngày giao dịch',
  MILLION_USD: 'Triệu USD',
  VALUE_MILLION: 'Giá trị (Triệu VND)',
  PRICE_THOUSAND: 'Giá (Nghìn VND)',
  MILLION_VND: 'Triệu VND',
  THOUSAND_VND: 'Nghìn VND',
  VAL_BILLION: 'Giá trị ròng (Tỷ VND)',
  BILLION: 'Tỷ VND',
  BUY: 'GT Mua',
  SELL: 'GT Bán',
  NET: 'GT Ròng',
  LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
  LOCAL_INSTITUTION: 'Tổ chức trong nước',
  VALUE: 'Giá trị',
  PUT_THROUGH: 'Thỏa thuận',
  TOTAL_VOLUME: 'Khối lượng giao dịch',
  TOTAL_VALUE: 'Giá trị giao dịch',
  TICKER_TITLE: 'Mã',
  UNDERLYING_ASSET: 'Giá CKCS',
  BREAK_EVEN: 'Giá hoà vốn',
  AVG_FIVE_DAYS: 'Giá TB 5 phiên',
  PERCENT_PREMIUM: '%Premium',
  OPEN: 'Mở cửa',
  HIGHEST: 'Cao nhất',
  LOWEST: 'Thấp nhất',
  CLOSE: 'Đóng cửa',
  ORDER_MATCHING: 'Khớp lệnh',
  SEARCH: 'Tìm kiếm',
  TOTAL_BUY_VAL: 'Tổng GD mua',
  TOTAL_SELL_VAL: 'Tổng GD bán',
  NET_TOTAL_VOL: 'Tổng KL ròng',
  NET_TOTAL_VAL: 'Tổng GT ròng',
  MATCH_BUY_VOL: 'Kl mua khớp lệnh',
  MATCH_SELL_VOL: 'KL bán khớp lệnh',
  MATCH_BUY_VAL: 'GT mua khớp lệnh',
  MATCH_SELL_VAL: 'GT bán khớp lệnh',
  DEAL_BUY_VOL: 'Kl mua thỏa thuận',
  DEAL_SELL_VOL: 'KL bán thỏa thuận',
  DEAL_BUY_VAL: 'GT mua thỏa thuận',
  DEAL_SELL_VAL: 'GT bán thỏa thuận',
  OPEN_INTEREST: 'Hợp đồng mở ',
  PERCENT_OPEN_INTEREST_CHANGE: '%Thay đổi OI',
  OPEN_PRICE: 'Mở cửa',
  HIGH_PRICE: 'Cao nhất',
  LOW_PRICE: 'Thấp nhất',
  CLOSE_PRICE: 'Đóng cửa',
  VIEW_BY: 'Xem dữ liệu theo',
  DAILY: 'Ngày',
  WEEKLY: 'Tuần',
  MONTHLY: 'Tháng',
  QUARTERLY: 'Qúy',
  YEARLY: 'Năm',
}
