import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseFontSize from '../../../common/hooks/useFontSize'
import useHeightTdTable from '../../../common/hooks/useHeightTdTable'
import { Link } from '../../../common/Link'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { debounce } from '../../../utils/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  FULL_COMPONENT,
} from '../../../utils/EventEmitter'
import { formatVal, valDivMillion, valToPercent } from '../../../utils/Value'
import style from './tickerInformation.module.css'
const Td = ({ children, ...props }) => {
  const heightTd = useHeightTdTable()

  return (
    <td {...props} style={{ ...props.style, height: heightTd }}>
      {children}
    </td>
  )
}

const TickerInformation = ({ summaryInfo }) => {
  const renderItem = ({ label, value, format = (value) => value }) => {
    return (
      <>
        <Td className={[style.bRight0, style.fw440].join(' ')}>
          <Translate value={label} />
        </Td>
        <Td className={['t-right', style.bLeft0].join(' ')}>{format(value)}</Td>
      </>
    )
  }

  const renderWebsiteItem = () => {
    return (
      <>
        <Td className={[style.bRight0, style.fw440].join(' ')}>
          <Translate value="corporate.overview.summary.WEBSITE" />
        </Td>
        <Td className={['t-right', style.bLeft0].join(' ')}>
          <Link url={summaryInfo?.website} className={style.website} />
        </Td>
      </>
    )
  }

  const renderFourColumn = (tableStyle, tableRef) => {
    return (
      <table style={tableStyle} ref={tableRef} className="four-column-table">
        <tbody>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.CHAIRMAN',
              value: summaryInfo?.chairman,
            })}
            {renderItem({
              label: 'corporate.overview.summary.OUTSTANDING_SHARES',
              value: summaryInfo?.outstandingShare,
              format: (val) => formatVal(valDivMillion(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.CEO',
              value: summaryInfo?.ceo,
            })}
            {renderItem({
              label: 'corporate.overview.summary.STATE_HOLDING',
              value: summaryInfo?.statePercentage,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.TELEPHONE',
              value: summaryInfo?.telephone,
            })}
            {renderItem({
              label: 'corporate.overview.summary.FOREIGN_HOLDING',
              value: summaryInfo?.foreignCurrentPercent,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderWebsiteItem()}
            {renderItem({
              label: 'corporate.overview.summary.FREE_FLOAT',
              value: summaryInfo?.freeFloatRate,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.MARGIN_STATUS',
              value: summaryInfo?.marginStatus,
            })}
            {renderItem({
              label: 'corporate.overview.summary.AVERAGE_VOLUME',
              value: summaryInfo?.avg10DayTotalMatchVolume,
              format: (val) => formatVal(val, 0),
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const renderTwoColumn = (tableStyle, tableRef) => {
    return (
      <table style={tableStyle} ref={tableRef} className="two-column-table">
        <tbody>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.OUTSTANDING_SHARES',
              value: summaryInfo?.outstandingShare,
              format: (val) => formatVal(valDivMillion(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.STATE_HOLDING',
              value: summaryInfo?.statePercentage,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.FOREIGN_HOLDING',
              value: summaryInfo?.foreignCurrentPercent,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.FREE_FLOAT',
              value: summaryInfo?.freeFloatRate,
              format: (val) => valToPercent(val),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.AVERAGE_VOLUME',
              value: summaryInfo?.avg10DayTotalMatchVolume,
              format: (val) => formatVal(val, 0),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.CHAIRMAN',
              value: summaryInfo?.chairman,
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.CEO',
              value: summaryInfo?.ceo,
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.TELEPHONE',
              value: summaryInfo?.telephone,
            })}
          </tr>
          <tr>{renderWebsiteItem()}</tr>
          <tr>
            {renderItem({
              label: 'corporate.overview.summary.MARGIN_STATUS',
              value: summaryInfo?.marginStatus,
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const [size, setSize] = useState(null)
  const ref = useRef()
  const tableRef = useRef()
  const [isTwoColumn, setIsTwoColumn] = useState(false)
  const locale = useSelector((state) => state.i18n.locale)
  const typeFontSize = UseFontSize()

  useEffect(() => {
    if (tableRef.current) {
      setSize({
        width: tableRef.current.offsetWidth,
        height: tableRef.current.offsetHeight,
      })
      responsiveTable(tableRef.current.offsetWidth)
    }
  }, [summaryInfo, locale, typeFontSize])

  const responsiveTable = (width) => {
    if (ref.current && ref.current.offsetWidth >= width) {
      setIsTwoColumn(false)
    } else {
      setIsTwoColumn(true)
    }
  }

  useEffect(() => {
    const debounceResize = debounce(() => responsiveTable(size.width))
    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [size])

  useEffect(() => {
    const resize = () => responsiveTable(size.width)
    EventEmitter.subscribe(COMPONENT_RESIZE, resize)
    return () => {
      EventEmitter.unsubscribe(COMPONENT_RESIZE, resize)
    }
  }, [size])

  useEffect(() => {
    const resize = () => responsiveTable(size.width)
    EventEmitter.subscribe(FULL_COMPONENT, resize)
    return () => {
      EventEmitter.unsubscribe(FULL_COMPONENT, resize)
    }
  }, [size])

  useEffect(() => {
    const resize = () => responsiveTable(size.width)
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, resize)
    return () => {
      EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, resize)
    }
  }, [size])

  return (
    <div className="row">
      <div
        className={['col-12', style.container].join(' ')}
        style={{ height: size?.height }}
        ref={ref}
      >
        {size && (
          <ScrollComponent>
            {isTwoColumn && renderTwoColumn({ width: '100%' }, tableRef)}
            {!isTwoColumn && renderFourColumn({ width: '100%' }, tableRef)}
          </ScrollComponent>
        )}
        {!size && renderFourColumn({}, tableRef)}
      </div>
    </div>
  )
}

export default TickerInformation

TickerInformation.propTypes = {
  summaryInfo: PropTypes.object,
}
