import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { BAR_COLOR_CORRELATION_OF_MARKET } from '../constants.js'
import {
  keys,
  selectCorrelationOfMarketDataChart,
  selectFilterCorrelationOfMarket,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const CorrelationOfSecondaryMarket = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const isLoading = useSelector(selectLoading(keys.CORRELATION_OF_MARKET))
  const { data } = useSelector(selectCorrelationOfMarketDataChart)
  const { isSector } = useSelector(selectFilterCorrelationOfMarket)

  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        totalValue: valDivBillion(item.totalValue),
        averageRemain: item.averageRemain,
        averageYTM: item.averageYTM * 100,
      })),
    [data],
  )

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  const titleJpg = useMemo(() => {
    const title = I18n.t(
      'bond.corporateBond.correlationOfSecondaryMarket.TITLE',
    )

    return title
  }, [locale])

  return (
    <Panel
      title="bond.corporateBond.correlationOfSecondaryMarket.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      isIconChart
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'correlationOfSecondaryMarketChartId',
        nameScreen: I18n.t('bond.corporateBond.secondaryMarket.TITLE'),
        chartName: I18n.t(
          'bond.corporateBond.correlationOfSecondaryMarket.TITLE',
        ),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="correlationOfSecondaryMarketChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {formatDataBarChart.length ? (
                        <>
                          <div style={{ height: height - size.height - 28 }}>
                            <ChartContainer
                              data={formatDataBarChart}
                              width={width}
                              height={height - size.height - 28}
                              keyXAxis={
                                isSector ? 'icbName' : 'organizationName'
                              }
                              isUseXAxisDiv
                              showAllTick
                              yAxis={[
                                {
                                  id: 'left',
                                  keys: ['averageYTM'],
                                  orientation: 'left',
                                  isStackedBar: true,
                                  label: I18n.t(
                                    'bond.corporateBond.averageCouponBySector.PERCENT',
                                  ),
                                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                                  tickNum: 5,
                                  min: 0,
                                },
                                {
                                  id: 'right',
                                  keys: ['averageRemain'],
                                  orientation: 'right',
                                  isStackedBar: true,
                                  label: I18n.t(
                                    'bond.corporateBond.correlationOfSecondaryMarket.YEAR',
                                  ),
                                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                                  tickNum: 5,
                                  min: 0,
                                },
                              ]}
                              margin={{
                                left: 0,
                                right: 16,
                                top: 20,
                                bottom: 10,
                              }}
                              renderCustomTooltip={renderTooltip}
                            >
                              {({ chartContentWidth }) => (
                                <>
                                  <Bar
                                    isAnimationActive={false}
                                    yAxisId="left"
                                    dataKey="averageYTM"
                                    fill={BAR_COLOR_CORRELATION_OF_MARKET[0]}
                                    barSize={30}
                                  />
                                  <Scatter
                                    yAxisId="right"
                                    dataKey="averageRemain"
                                    isAnimationActive={false}
                                    shape={
                                      <ScatterDiamondShape
                                        dataKey="averageRemain"
                                        color={
                                          BAR_COLOR_CORRELATION_OF_MARKET[1]
                                        }
                                      />
                                    }
                                  />
                                </>
                              )}
                            </ChartContainer>
                          </div>
                        </>
                      ) : (
                        <NoData />
                      )}
                      <Footer
                        key={width}
                        list={[
                          {
                            text: 'bond.corporateBond.correlationOfSecondaryMarket.AVG_YTM',
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: BAR_COLOR_CORRELATION_OF_MARKET[0],
                            },
                          },
                          {
                            text: 'bond.corporateBond.correlationOfSecondaryMarket.REMAINING_DURATION',
                            renderIcon: (
                              <ScatterDiamondShape
                                style={{ marginRight: 5 }}
                                color={BAR_COLOR_CORRELATION_OF_MARKET[1]}
                              />
                            ),
                          },
                        ]}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default CorrelationOfSecondaryMarket
