import PropTypes from 'prop-types'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { FILTER_OPTIONS } from '../constants'
import { FilterInputRatioItem } from './FilterInputRatioItem'
import FilterOptionItem from './FilterOptionItem'
import style from './index.module.css'

export const Filter = ({ height }) => {
  return (
    <ScrollComponent autoHeight={true} autoHeightMax={height || 0}>
      <div className="h-100">
        {FILTER_OPTIONS.map((option) => (
          <div key={option.field} className={style.filterItem}>
            {option.type === 'ratio' ? (
              <FilterInputRatioItem key={option.field} data={option} />
            ) : (
              <FilterOptionItem key={option.field} data={option} />
            )}
          </div>
        ))}
      </div>
    </ScrollComponent>
  )
}

Filter.propTypes = {
  height: PropTypes.number.isRequired,
}
