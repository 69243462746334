import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Span } from '../../../../../../common/html/Span'
import { formatVal, valToPercent } from '../../../../../../utils/Value'
import { selectDataByIds } from './store/slice'

export const OtherColumnCell = ({ val, rowId, className }) => {
  const isYoyGrowth = useSelector(selectDataByIds(rowId, 'isYoyGrowth'))

  return (
    <td className={className} style={{ textAlign: 'right' }}>
      <Span style={isYoyGrowth ? { opacity: 0.4, fontStyle: 'italic' } : {}}>
        {isYoyGrowth ? valToPercent(val) : formatVal(val)}
      </Span>
    </td>
  )
}

OtherColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
