import { useEffect, useMemo, useState } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../common/html/Button'
import PopupDuplicateDashboard from '../../popup/PopupDuplicateDashboard'
import PopupSaveChartToNewDashboard from '../../popup/popupSaveChartToNewDashboard'
import {
  selectDashboard,
  selectDashboardChartData,
  selectListDashboard,
  selectMyChartLibrary,
  selectMyDashboard,
} from '../../store/slice'
import {
  getChartByDashboardId,
  getDashboard,
  getMyLibraryData,
} from '../../store/thunk'
import { getDashboardDuplicateName } from '../helpers'

export const DuplicateBtn = ({ typeDuplicate, chartItem = {} }) => {
  const dispatch = useDispatch()
  const dashboardChartData = useSelector(selectDashboardChartData)
  const dashboard = useSelector(selectDashboard)
  const myDashboard = useSelector(selectMyDashboard)
  const listDashboard = useSelector(selectListDashboard)
  const myChartLibrary = useSelector(selectMyChartLibrary)

  const [isShow, setIsShow] = useState(false)

  const getName = useMemo(() => {
    return getDashboardDuplicateName(myDashboard, dashboard?.data)
  }, [myDashboard, dashboard])

  const onClick = () => {
    setIsShow(true)
  }

  useEffect(() => {
    if (typeDuplicate === 'duplicateChart') {
      dispatch(getDashboard())
    }
  }, [])

  useEffect(() => {
    if (listDashboard.length && typeDuplicate === 'duplicateChart') {
      const payload = listDashboard.map((item) => ({
        id: item.id,
        chartIncluded: true,
      }))
      dispatch(getChartByDashboardId(payload))
    }
  }, [JSON.stringify(listDashboard)])

  useEffect(() => {
    if (
      Object.values(myChartLibrary).length &&
      typeDuplicate === 'duplicateChart'
    ) {
      dispatch(
        getMyLibraryData({ dashboardId: myChartLibrary.id, isTemplate: false }),
      )
    }
  }, [JSON.stringify(myChartLibrary)])

  return (
    <>
      <Button
        className={`btn w-80 h-20`}
        style={{
          fontSize: 11,
          color: '#ffffff',
          backgroundColor: '#2f9aee',
          fontWeight: 500,
        }}
        onClick={onClick}
      >
        <Translate value="financialChart.SAVE" />
      </Button>

      {typeDuplicate === 'duplicateDashboard' ? (
        <PopupDuplicateDashboard
          isShow={isShow}
          name={getName}
          charts={dashboardChartData}
          dashboard={dashboard?.data || {}}
          handleShow={setIsShow}
        />
      ) : (
        <>
          {isShow ? (
            <PopupSaveChartToNewDashboard
              handleShow={setIsShow}
              onChangeNameChart={() => {}}
              chartTabActive={1}
              chartTabs={{ 1: chartItem?.detail }}
            />
          ) : null}
        </>
      )}
    </>
  )
}
