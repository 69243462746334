export const METRIC_CODE = {
  TPIS1: 'TPIS1',
  YoYGrowthIS1: 'YoYGrowthIS1',
  NI153: 'NI153',
  GWP: 'GWP',
  NI154: 'NI154',
  LIG: 'LIG',
  NI155: 'NI155',
  NLIG: 'NLIG',
  IS73: 'IS73',
  RT0338: 'RT0338',
  RT0339: 'RT0339',
}
