import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../configs/Global'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { Span } from '../../../common/html/Span'
import { Realtime } from '../../../common/Realtime'
import { SizeTracker } from '../../../common/sizeTracker'
import { Table } from '../../../common/table'
import { GROUP_TYPE } from '../../../constants/Common'
import { formatVal, valDivThousand, valToPercent } from '../../../utils/Value'
import CellHighLight from './CellHighLight'
import { indicator } from './constants'
import { RealtimeIndexCustom } from './RealtimeIndexCustom'
import {
  changeIndicator,
  selectIds,
  selectIndicator,
  selectLoading,
  selectReCalcWidthsTable,
  selectSummaryValue,
  selectUnderlyingIds,
  subscribeDerivative,
  subscribeIndex,
} from './store/slice'
import { getLatestPrice, getSummary } from './store/thunk'
import style from './style.module.css'

const SummaryPanel = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const summaryIds = useSelector(selectIds)
  const reCalcWidths = useSelector(selectReCalcWidthsTable)
  const derivativeActive = useSelector(selectIndicator)
  const underlyingIds = useSelector(selectUnderlyingIds)

  useEffect(() => {
    dispatch(getSummary())
  }, [locale])

  useEffect(() => {
    if (derivativeActive?.futureId)
      dispatch(
        getLatestPrice({
          Ids: derivativeActive.futureId,
          GroupType: GROUP_TYPE.FUTURE,
        }),
      )
  }, [derivativeActive])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Span className={style.spanTableColor} style={{ fontSize: 11 }}>
                <Translate value={'market.derivative.HEAD_TABLE_TEXT'} />
              </Span>
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                    marginTop: 4,
                  }}
                >
                  <Table
                    ids={summaryIds}
                    getDataFromRedux={selectSummaryValue}
                    isLoading={isLoading}
                    schema={Object.keys(indicator).map((key, index) => {
                      const title = `market.derivative.${key}`
                      const colId = indicator[key]
                      let result = {
                        colId,
                        title,
                        isI18n: false,
                        thStyle: {
                          textAlign: 'right',
                          textTransform: 'uppercase',
                        },
                        tdStyle: {
                          textAlign: 'right',
                        },
                      }
                      if (index === 0) {
                        result = {
                          ...result,
                          isI18n: false,
                          thStyle: {
                            textAlign: 'left',
                            textTransform: 'uppercase',
                          },
                          tdStyle: {
                            textAlign: 'left',
                          },
                        }
                      }
                      if (index === 1) {
                        result = {
                          ...result,
                          isI18n: false,
                          thStyle: {
                            textAlign: 'left',
                            textTransform: 'uppercase',
                          },
                          tdStyle: {
                            textAlign: 'left',
                          },
                          render: (val) => <DateTimeSpan date={val} />,
                        }
                      }
                      if (
                        index === 2 ||
                        index === 3 ||
                        index === 7 ||
                        index === 8
                      ) {
                        result = {
                          ...result,
                          render: (val) => formatVal(val),
                        }
                      }
                      if (index === 4) {
                        result = {
                          ...result,
                          canCustomTd: true,
                          render: (val, rowId, { style, className }) => {
                            return (
                              <CellHighLight
                                value={formatVal(val)}
                                rowId={rowId}
                                keyColor={colId}
                                selectCell={selectSummaryValue}
                                style={style}
                                className={className}
                              />
                            )
                          },
                        }
                      }
                      if (index === 6) {
                        result = {
                          ...result,
                          render: (val) => formatVal(valDivThousand(val)),
                        }
                      }

                      if (index === 5) {
                        result = {
                          ...result,
                          canCustomTd: true,
                          render: (val, rowId, { style, className }) => {
                            return (
                              <CellHighLight
                                value={valToPercent(val)}
                                rowId={rowId}
                                keyColor={colId}
                                selectCell={selectSummaryValue}
                                style={style}
                                className={className}
                              />
                            )
                          },
                        }
                      }
                      return result
                    })}
                    defaultSort={{ publicDateId: 'desc' }}
                    isLargeHead={true}
                    hasFooter={false}
                    hasPagination={false}
                    hasTooltip={false}
                    reCalcWidths={reCalcWidths}
                    changeActiveRow={changeIndicator}
                    defaultActiveRowId={summaryIds[0]}
                  />
                  {summaryIds.map((idDerivative) => (
                    <Realtime
                      channel={ChannelConfig.DerivativeChannel + idDerivative}
                      action={subscribeDerivative}
                      key={idDerivative}
                    >
                      <></>
                    </Realtime>
                  ))}
                  {underlyingIds.map((idUnderlying, index) => (
                    <RealtimeIndexCustom
                      channel={ChannelConfig.IndexChannel + idUnderlying}
                      action={subscribeIndex}
                      derivativeId={summaryIds[index]}
                      key={index}
                    />
                  ))}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default SummaryPanel
