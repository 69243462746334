import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { positionDropdown } from '../../../common/dropdown'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import {
  changeSecurities,
  selectSecuritiesByName,
} from '../../popup/common/store/slice'

import PopupQuickAddSecurities from '../../popup/popupQuickAddSecurities'
import {
  changeTreeSecuritiesIndicator,
  selectTreeSecuritiesIndicator,
  selectViewType,
} from '../store/slice'
import style from '../style.module.css'
import SecuritiesItemIndicator from './SecuritiesItemIndicator'
import { useGetNameSecuritiesIndicator } from './useGetNameSecuritiesIndicator'

const SecuritiesItem = ({ itemId, itemType, dataChildren }) => {
  const dispatch = useDispatch()
  const parentRef = useRef()

  const itemChecked = useSelector(selectSecuritiesByName(itemType))
  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const viewType = useSelector(selectViewType)

  const [collapseOpen, setCollapseOpen] = useState(true)
  const [isShow, setIsShow] = useState(false)

  const { securitiesName } = useGetNameSecuritiesIndicator(itemType, itemId)

  const handleRemoveSecurities = () => {
    const value = itemChecked.filter((i) => i !== itemId)
    dispatch(changeSecurities({ typeSecurities: itemType, value: value }))
    const newObject = JSON.parse(JSON.stringify(treeSecuritiesIndicator))
    delete newObject[itemType][itemId]
    dispatch(changeTreeSecuritiesIndicator(newObject))
  }

  const onEdit = () => {
    setIsShow(true)
  }

  const onClose = () => {
    setIsShow(false)
  }

  return (
    <div className={style.card}>
      <div className={style.cardHeader}>
        <div
          className="cursor-pointer d-flex ali-center"
          style={{ width: 'calc(100% - 60px)' }}
          onClick={() => setCollapseOpen(!collapseOpen)}
        >
          {collapseOpen ? (
            <i className={`icon-caret-down ${style.iconCollapse}`} />
          ) : (
            <i className={`icon-caret-right ${style.iconCollapse}`} />
          )}
          <Span
            className={style.cardTitle}
            style={{ fontSize: 11, width: 'calc(100% - 20px)' }}
          >
            <TextEllipsis isI18n={false} text={securitiesName} />
          </Span>
        </div>
        <div className="align-center">
          <IconRename
            className={`${style.iconRename} ${
              isShow && style.iconRenameActive
            }`}
            onClick={onEdit}
            ref={parentRef}
          />
          <i
            className={[
              'icon-trash',
              style.iconTrack,
              style.iconContainer,
            ].join(' ')}
            onClick={handleRemoveSecurities}
            style={{ fontSize: 12 }}
          />
        </div>
      </div>
      {collapseOpen && (
        <>
          {dataChildren.length
            ? dataChildren.map((item, index) => (
                <SecuritiesItemIndicator
                  key={index}
                  index={index}
                  itemId={itemId}
                  item={item}
                  itemType={itemType}
                />
              ))
            : null}
        </>
      )}
      {isShow && (
        <PopupQuickAddSecurities
          isShow={isShow}
          onClose={onClose}
          parentRef={parentRef}
          position={positionDropdown.BOTTOM_LEFT_RIGHT}
          itemId={itemId}
          typeSelect={itemType}
          viewType={viewType}
          isShowInputSearch
        />
      )}
    </div>
  )
}

export default SecuritiesItem

SecuritiesItem.propTypes = {
  itemId: PropTypes.number.isRequired,
  itemType: PropTypes.string,
  indicatorSelected: PropTypes.array,
}
