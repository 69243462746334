import { debounce } from '@mui/material'
import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScatterChart } from '../../../../../common/chart/scatterChart'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Loading } from '../../../../../common/loading'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TextNote } from '../../../../../common/textNote'
import { getColorByValueChart } from '../../../../../utils/Color'
import { FUND_STRUCTURE_OPTIONS, FUND_TYPE_OPTIONS } from '../../../constants'
import { Filter } from '../filter'
import {
  changeFilter,
  selectData,
  selectFilters,
  selectLoading,
} from './store/slice'
import { getFundsOverview12Months } from './store/thunk'

export const FundOverview = ({ id, width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const fundNavReport = maxMinTableById['FGFB_COFS_DR_FundNAVReport']

  const xName = UseI18n(
    'fund.fundCenter.overview.performance.FUND_OVERVIEW_X_AXIS',
  )
  const yName = UseI18n(
    'fund.fundCenter.overview.performance.FUND_OVERVIEW_Y_AXIS',
  )
  const zName = UseI18n(
    'fund.fundCenter.overview.performance.FUND_OVERVIEW_Z_AXIS',
  )
  const zUnit = UseI18n('common.UNIT.BILLION_VND')

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const loading = useSelector(selectLoading)
  const data = useSelector(selectData)

  // Get data
  const minData = Math.min(...data.map((item) => item.zValue))
  const maxData = Math.max(...data.map((item) => item.zValue))

  const getParams = useMemo(() => {
    return {
      FundStructureIds:
        filters.structure.join(',') ||
        FUND_STRUCTURE_OPTIONS.map((item) => item.value).join(','),
      FundTypeIds:
        filters.type.join(',') ||
        FUND_TYPE_OPTIONS.map((item) => item.value).join(','),
      Year: filters.year,
      Month: filters.month,
    }
  }, [filters])

  const getSourceNumber = useMemo(() => {
    return data.length > 0 && data[0].source ? data[0].source : 0
  }, [JSON.stringify(data)])

  // Actions
  const handleChange = (value) => {
    dispatch(changeFilter(value))
  }

  const handleApi = useCallback(
    debounce((params) => {
      dispatch(getFundsOverview12Months(params))
    }, 300),
    [],
  )

  // Use effect
  useEffect(() => {
    handleApi(getParams)
  }, [getParams, locale])

  useEffect(() => {
    if (
      fundNavReport?.max_MonthReport &&
      fundNavReport?.max_YearOfMonthReport
    ) {
      handleChange({
        month: fundNavReport.max_MonthReport,
        year: fundNavReport.max_YearOfMonthReport,
      })
    }
  }, [fundNavReport])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter
              {...filters}
              isStructure
              isType
              isMonth
              isYear
              className="mb-8"
              onChange={handleChange}
            />
            <TextNote
              value="fund.fundCenter.overview.performance.FUND_OVERVIEW_NOTE"
              className="mb-8"
              isI18n
            />
            {size.height && (
              <>
                {loading && (
                  <div style={{ height: `calc(100% - ${size.height})` }}>
                    <Loading />
                  </div>
                )}
                {!loading && (
                  <ScatterChart
                    id={id}
                    width={width}
                    height={height - size.height}
                    data={data}
                    xName={xName}
                    yName={yName}
                    xTooltip={xName}
                    yTooltip={yName}
                    zTooltip={zName}
                    xTooltipColor={getColorByValueChart}
                    yTooltipColor={getColorByValueChart}
                    xUnit="%"
                    yUnit="%"
                    zUnit={zUnit}
                    zAxisRange={[
                      minData,
                      isFullScreen ? maxData / 2 : maxData / 10,
                    ]}
                    keyLabel="ticker"
                    isShowLabel
                    isShowLabelOutside
                  />
                )}
              </>
            )}
            <TextNote
              value={
                <Translate
                  value="fund.fundCenter.overview.performance.SOURCE_FUND"
                  total={getSourceNumber}
                />
              }
              fontSize={11}
              className="mb-8"
            />
          </>
        )
      }}
    </SizeTracker>
  )
}
