import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectIsAcc, selectIsYearly } from '../balanceOfTrade/store/slice'
import { ChartComponent } from './ChartComponent'
import { DATE_FILTER } from './constants'
import { changeLocale, selectDataChart, selectLoading } from './store/slice'
import { getImportExportAndTradeBalance } from './store/thunk'

const PADDING_BOTTOM_X_AXIS = 25

export const ImportExportChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)
  const locale = useSelector((state) => state.i18n.locale)

  const [currentDateType, setCurrentDateType] = useState(
    DATE_FILTER.MONTHLY[0].value,
  )
  const [currentDateTypeYearly, setCurrentDateTypeYearly] = useState(
    DATE_FILTER.YEARLY[0].value,
  )

  const currentTimeRange = isYearly ? currentDateTypeYearly : currentDateType

  const handleChangeTab = (item) => {
    return isYearly
      ? setCurrentDateTypeYearly(item.value)
      : setCurrentDateType(item.value)
  }

  useEffect(() => {
    dispatch(
      getImportExportAndTradeBalance({
        DataType: isAcc ? 'AccMonthly' : isYearly ? 'Yearly' : 'Monthly',
        TimeRange: currentTimeRange,
      }),
    )
  }, [isYearly, isAcc, currentDateType, currentDateTypeYearly, locale])

  useEffect(() => {
    dispatch(changeLocale(locale))
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <div className="h-100">
            <div
              className="d-flex justify-content-end"
              style={{ paddingBottom: PADDING_BOTTOM_X_AXIS }}
            >
              <DispatchActionTab
                data={isYearly ? DATE_FILTER.YEARLY : DATE_FILTER.MONTHLY}
                activeTab={currentTimeRange}
                onChangeTab={handleChangeTab}
                itemStyle={{ width: '20px', fontWeight: 500, fontSize: 10 }}
              />
            </div>
            <div id="importExportChart">
              {isLoading ? (
                <Loading />
              ) : (
                size.height &&
                (!dataChart.length ? (
                  <div
                    style={{
                      height: height - size.height - PADDING_BOTTOM_X_AXIS,
                    }}
                  >
                    <NoData />
                  </div>
                ) : (
                  <>
                    <ChartComponent
                      data={dataChart}
                      width={width}
                      height={height - size.height - PADDING_BOTTOM_X_AXIS}
                      keyXAxis={'time'}
                      lineKeys={['import', 'export']}
                      barKeys={['balance_of_trade']}
                    />
                    <Footer
                      key={width}
                      list={[
                        {
                          text: I18n.t(
                            'economy.importExport.importExport.overview.FOOTER_BALANCE_OF_TRADE',
                          ),
                          type: TYPE.LINE,
                          before: {
                            bgColor: '#0096eb',
                          },
                        },
                        {
                          text: I18n.t(
                            'economy.importExport.importExport.overview.FOOTER_EXPORT',
                          ),
                          type: TYPE.CIRCLE_MARKER_LINE,
                          before: {
                            bgColor: '#ecffdf',
                          },
                          after: {
                            bgColor: '#ecffdf',
                          },
                        },
                        {
                          text: I18n.t(
                            'economy.importExport.importExport.overview.FOOTER_IMPORT',
                          ),
                          type: TYPE.CIRCLE_MARKER_LINE,
                          before: {
                            bgColor: '#f7b80c',
                          },
                          after: {
                            bgColor: '#f7b80c',
                          },
                        },
                      ]}
                    />
                  </>
                ))
              )}
            </div>
          </div>
        )}
      </SizeTracker>
    )
  )
}
