import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import Dropdown from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import style from './index.module.css'
import { List } from './List'

export const Setting = ({
  data,
  isShowIcon,
  isShow,
  setIsShow,
  setIsActive,
  position,
  scrollId,
}) => {
  const containerRef = useRef()
  const dropdownRef = useRef()
  const popupRef = useRef()

  const onClick = (e) => {
    e.stopPropagation()
    setIsShow(!isShow)
    setIsActive(!isShow)
  }

  const clickOutside = () => {
    setIsShow(false)
  }

  return (
    <HandleClickOutside
      handleClickOutside={clickOutside}
      exceptRef={[dropdownRef, popupRef]}
      className="d-flex align-center"
    >
      <div style={{ display: 'flex', position: 'relative' }}>
        <i
          ref={containerRef}
          onClick={onClick}
          className={['icon-menu-dot-hor', style.settingIcon].join(' ')}
          style={{
            fontSize: 14,
            backgroundColor: isShow ? '#c8d9e1' : '',
            opacity: isShowIcon ? 1 : 0,
          }}
        />
        {isShow && (
          <Dropdown
            isShow={isShow}
            parentRef={containerRef}
            position={position}
            dropdownSpace={8}
            isAutoPosition
            scrollId={scrollId}
            setIsShow={setIsShow}
          >
            {(position) => {
              return (
                <List
                  data={data}
                  dropdownRef={dropdownRef}
                  onSelectedItem={clickOutside}
                  position={position}
                  popupRef={popupRef}
                />
              )
            }}
          </Dropdown>
        )}
      </div>
    </HandleClickOutside>
  )
}

Setting.propTypes = {
  data: PropTypes.array.isRequired,
  isShowIcon: PropTypes.bool,
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func,
  setIsActive: PropTypes.func,
  scrollId: PropTypes.string,
}

Setting.defaultProps = {
  isShowIcon: true,
  isShow: false,
  setIsShow: () => {},
  setIsActive: () => {},
}
