import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDataTree } from '../../../common/tree/helper'
import { keyBy } from '../../../utils/Common'
import { keys, selectIndicator, selectLoading } from './store/slice'
import { getIndicatorThunk } from './store/thunk'

const useGetIndicator = (disabledCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const indicators = useSelector(selectIndicator)
  const loading = useSelector(selectLoading(keys.INDICATOR))

  const [indicatorsTree, setIndicatorsTree] = useState({})

  useEffect(() => {
    if (!disabledCallApi) {
      dispatch(getIndicatorThunk())
    }
  }, [locale])

  useEffect(() => {
    if (indicators.length > 0) {
      setIndicatorsTree(
        convertDataTree(indicators, 'indicatorId', 'parentIndicatorId'),
      )
    }
  }, [indicators])

  const handleTreePopupIndicator = (conditionType, exceptIndicator = []) => {
    let filterIndicator = indicators.filter(
      (indicator) =>
        indicator.conditionType === conditionType &&
        !exceptIndicator.includes(indicator.indicatorId + ''),
    )
    const filterIndicatorById = keyBy(filterIndicator, 'indicatorId')
    filterIndicator = filterIndicator.map((item) => {
      const indicator = { ...item }
      if (!filterIndicatorById[indicator.parentIndicatorId]) {
        delete indicator.parentIndicatorId
      }
      return indicator
    })

    return convertDataTree(filterIndicator, 'indicatorId', 'parentIndicatorId')
  }

  return { indicators, indicatorsTree, loading, handleTreePopupIndicator }
}

export default useGetIndicator
