import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import LocalInstitutionService from '../../../../../../core/services/market/marketInDepth/LocalInstitutionService'
import { handleExport } from '../../../../../utils/Export'

export const getSectorSegmentation = createAsyncThunk(
  'market/marketInDepth/localInstitution/sectorSegmentation/GET_SECTOR_SEGMENTATION',
  async (params, { rejectWithValue }) => {
    try {
      const response = await LocalInstitutionService.getSectorSegmentation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadSectorSegmentation = createAsyncThunk(
  'market/marketInDepth/localInstitution/sectorSegmentation/DOWNLOAD_SECTOR_SEGMENTATION',
  async (data) => {
    const response = await LocalInstitutionService.downloadSectorSegmentation(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
