import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import LinkTicker from '../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../common/html/Span'
import { Panel } from '../../../common/panel'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../common/sizeTracker'
import { Table } from '../../../common/table'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { EMPTY_VALUE } from '../../../constants/Common'
import { getThreeColorByValue } from '../../../utils/Color'
import { formatVal } from '../../../utils/Value'
import { keySchemaTableTopValue, keyTableTopValue } from '../constant'
import { getIcbId } from '../helper'
import style from '../index.module.css'
import {
  keys,
  selectActiveRow,
  selectLoading,
  selectTimeRange,
  selectTopValueById,
  selectTopValueCell,
  selectTopValueId,
} from '../store/slice'
import { downloadTopValueThunk, getTopValueThunk } from '../store/thunk'
import { timeRangeApi } from '../tableSector'
import { resetDefaultActiveRow } from '../tableSector/ContentTableSector'

const TableTopValue = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const activeRow = useSelector(selectActiveRow)
  const timeRange = useSelector(selectTimeRange)
  const loading = useSelector(selectLoading(keys.TOP_VALUE))
  const topValueId = useSelector(selectTopValueId)
  const topValueById = useSelector(selectTopValueById)
  const indexInfo = useSelector(selectIndexInfo)

  const [sortIndex, setSortIndex] = useState(topValueId)

  useEffect(() => {
    if (activeRow && activeRow !== resetDefaultActiveRow && indexInfo.groupId) {
      dispatch(
        getTopValueThunk({
          ICBId: getIcbId(activeRow),
          NumOfTop: 20,
          Period: timeRangeApi[timeRange],
          GroupId: indexInfo.groupId,
        }),
      )
    }
  }, [activeRow, indexInfo.groupId, timeRange])

  useEffect(() => {
    setSortIndex(
      [...topValueId].sort(
        (key1, key2) => topValueById[key1].index - topValueById[key2].index,
      ),
    )
  }, [topValueId])

  return (
    <Panel
      title="market.sectorInDepth.TITLE_TOP_VALUE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadTopValueThunk({
          ICBId: getIcbId(activeRow),
          NumOfTop: 20,
          Period: timeRangeApi[timeRange],
          GroupId: indexInfo.groupId,
        })
      }
    >
      <PreOpenMarket disabled={timeRange !== TIME_RANGES['1D']}>
        <SizeTracker>
          {(size) => (
            <>
              <div className={style.pb11px}>
                <Span style={{ fontSize: 11 }}>
                  <i className={style.noteTable}>
                    <Translate value="common.table.PRICE_VALUE_FOOTER_TEXT" />
                  </i>
                </Span>
              </div>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <Table
                    ids={sortIndex}
                    columnDraggable={false}
                    getDataFromRedux={selectTopValueCell}
                    isLoading={loading}
                    rowDraggable={false}
                    hasFooter={false}
                    hasTooltip={false}
                    resizable={false}
                    schema={Object.keys(keyTableTopValue).map((key) => {
                      const title = `market.sectorInDepth.${key}`

                      const colId = keyTableTopValue[key]
                      const result = {
                        colId,
                        title,
                        disableSort: true,
                      }

                      return {
                        ...result,
                        isI18n: false,
                        thStyle: {
                          height: '35px',
                          textAlign: [
                            keySchemaTableTopValue.NO,
                            keySchemaTableTopValue.TICKER,
                          ].includes(key)
                            ? 'left'
                            : 'right',
                          whiteSpace: [
                            keySchemaTableTopValue.LOCAL_INDIVIDUAL,
                            keySchemaTableTopValue.LOCAL_INSTITUTION,
                            keySchemaTableTopValue.FOREIGN,
                            keySchemaTableTopValue.PROPRIETARY,
                          ].includes(key)
                            ? 'pre'
                            : undefined,
                        },
                        tdStyle: {
                          textAlign: [
                            keySchemaTableTopValue.NO,
                            keySchemaTableTopValue.TICKER,
                          ].includes(key)
                            ? 'left'
                            : 'right',
                        },
                        render: [
                          keySchemaTableTopValue.CHANGE,
                          keySchemaTableTopValue.LOCAL_INDIVIDUAL,
                          keySchemaTableTopValue.LOCAL_INSTITUTION,
                          keySchemaTableTopValue.FOREIGN,
                          keySchemaTableTopValue.PROPRIETARY,
                        ].includes(key)
                          ? (value) => {
                              const isEmpty = value === EMPTY_VALUE
                              return (
                                <div
                                  style={{
                                    color: !isEmpty
                                      ? getThreeColorByValue(Number(value))
                                      : undefined,
                                  }}
                                >
                                  {formatVal(value)}
                                  {!isEmpty &&
                                    key === keySchemaTableTopValue.CHANGE &&
                                    '%'}
                                </div>
                              )
                            }
                          : [
                              keySchemaTableTopValue.PRICE,
                              keySchemaTableTopValue.TRADING_VALUE,
                            ].includes(key)
                          ? (value) => formatVal(value)
                          : key === keySchemaTableTopValue.TICKER
                          ? (value, colId) => (
                              <LinkTicker
                                organizationId={
                                  topValueById[colId]?.organizationId
                                }
                              >
                                {value}
                              </LinkTicker>
                            )
                          : undefined,
                      }
                    })}
                  />
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </PreOpenMarket>
    </Panel>
  )
}

export default TableTopValue
