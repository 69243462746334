import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPProvinceService from '../../../../../../core/services/economy/gdp/GDPProvinceService'

export const getChangesInGRDPStructureBySectorInProvince = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGRDPChanges/getChangesInGRDPStructureBySectorInProvince',
  async (params, { rejectWithValue }) => {
    try {
      const res =
        await GDPProvinceService.getChangesInGRDPStructureBySectorInProvince(
          params,
        )
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
