import { memo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { encodeSizePanel } from '../helper'
import style from '../index.module.css'
import ForeignTradingChart from './ForeignTradingChart'

export const idForeignChart = 'foreignChart'

const ForeignTrading = ({ sizes, panelRefs, panelKey, setSizes }) => {
  const indexInfo = useSelector(selectIndexInfo)

  const listIconChart = [
    {
      text: 'market.marketInDepth.indices.FOREIGN_BUY',
      type: TYPE.RECTANGLE,
      before: {
        bgColor: '#47c3ff',
      },
    },
    {
      text: 'market.marketInDepth.indices.FOREIGN_SELL',
      type: TYPE.RECTANGLE,
      before: {
        bgColor: '#facc5c',
      },
    },
    {
      text: 'market.marketInDepth.indices.NET_FOREIGN',
      type: TYPE.SQUARE_TWO_COLOR,
      before: {
        bgColor: '#e94235',
      },
      after: {
        bgColor: '#1d97ef',
      },
    },
  ]

  return (
    <Panel
      title="market.marketInDepth.indices.FOREIGN_TRADING"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('market.marketInDepth.indices.FOREIGN_TRADING')}
      downloadJpgParams={{
        domId: idForeignChart,
        nameScreen: I18n.t(
          'market.marketInDepth.indices.NAME_SCREEN_LOWERCASE',
        ),
        chartName: I18n.t(
          'market.marketInDepth.indices.CHART_NAME_FOREIGN_TRADING',
        ),
        tickerName: indexInfo.groupName,
      }}
    >
      <PreOpenMarket>
        <div id={idForeignChart} className={style.foreignTradingContainer}>
          <SizeTracker>
            {(size) => (
              <>
                {size.height && (
                  <div style={{ height: `calc(100% - ${size.height}px)` }}>
                    <ForeignTradingChart
                      sizes={encodeSizePanel(
                        sizes[panelKey].width - PANEL_PADDING,
                        sizes[panelKey].height -
                          size.height -
                          HEADER_PANEL_HEIGHT,
                      )}
                    />
                  </div>
                )}
                <div className={style.pt10px}>
                  <Footer key={sizes.width} list={listIconChart} />
                </div>
              </>
            )}
          </SizeTracker>
        </div>
      </PreOpenMarket>
    </Panel>
  )
}

export default memo(ForeignTrading)
