export const valueTab = {
  ALL: 'All',
  VIET_NAM: 'VietNam',
  OTHER: 'Others',
}

export const indicatorUpcomingEvent = {
  DATE: 'sortValue',
  EVENT: 'macroEventTypeName',
  DESCRIPTION: 'description',
  CURRENT_VALUE: 'value1',
  PREVIOUSVALUE: 'value2',
  GROWTH_YOY: 'valueYoY',
}

export const indicatorNews = {
  DATE: 'date',
  EVENT: 'event',
  NEWS: 'news',
}

export const LIST_TAB = [
  {
    title: 'economy.event.event.ALL',
    value: valueTab.ALL,
  },
  {
    title: 'economy.event.event.VIET_NAM',
    value: valueTab.VIET_NAM,
  },
  {
    title: 'economy.event.event.OTHERS',
    value: valueTab.OTHER,
  },
]

export const MONTHLY_TITLE = {
  1: {
    vi: 'Th01',
    en: 'Jan',
  },
  2: {
    vi: 'Th02',
    en: 'Feb',
  },
  3: {
    vi: 'Th03',
    en: 'Mar',
  },
  4: {
    vi: 'Th04',
    en: 'Apr',
  },
  5: {
    vi: 'Th05',
    en: 'May',
  },
  6: {
    vi: 'Th06',
    en: 'Jun',
  },
  7: {
    vi: 'Th07',
    en: 'Jul',
  },
  8: {
    vi: 'Th08',
    en: 'Aug',
  },
  9: {
    vi: 'Th09',
    en: 'Sep',
  },
  10: {
    vi: 'Th10',
    en: 'Oct',
  },
  11: {
    vi: 'Th11',
    en: 'Nov',
  },
  12: {
    vi: 'Th12',
    en: 'Dec',
  },
}

export const MULTIPLIER_TEXT = {
  1: '',
  1000: 'economy.event.event.THOUSAND',
  1000000: 'economy.event.event.MILLION',
  1000000000: 'economy.event.event.BILLION',
  1000000000000: 'economy.event.event.TRILLION',
}
