export const BALANCE_PAYMENT_FILTER = [
  {
    value: 'Yearly',
    title: 'economy.paymentBalance.paymentBalance.FILTER_YEARLY',
  },
  {
    value: 'Quarterly',
    title: 'economy.paymentBalance.paymentBalance.FILTER_QUARTERLY',
  },
]
