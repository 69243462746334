import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleKeyXAxis } from '../helper'
import { getMoneySupplyOutstandingLoanGrowth } from './thunk'

const initialState = {
  timeRange: 'OneYear',
  lineChartData: [],
  lineChartLoading: true,
  activeLine: undefined,
}

const slice = createSlice({
  name: 'economy/monetary/moneySuppliesCredit/panelGrowthSupplyCredit',
  initialState,
  reducers: {
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMoneySupplyOutstandingLoanGrowth.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(
      getMoneySupplyOutstandingLoanGrowth.fulfilled,
      (state, action) => {
        state.lineChartLoading = false
        state.lineChartData = action.payload || []
        state.keyXAxis = handleKeyXAxis(action.payload || [])
      },
    )
    builder.addCase(
      getMoneySupplyOutstandingLoanGrowth.rejected,
      (state, action) => {
        state.lineChartLoading = action.payload.isLoading
      },
    )
  },
})

export const { handleTimeRange, handleActiveLine } = slice.actions

export const getTimeRange = (state) => state[slice.name].timeRange
export const getLineChartData = (state) => state[slice.name].lineChartData
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getActiveLine = (state) => state[slice.name].activeLine

register(slice.name, slice.reducer)
