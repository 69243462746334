import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { renderTimeValueBond } from '../helper'
import { selectActiveMethodType } from '../store/slice'
import { ChartComponent } from './ChartComponent'
import { valueTimefrequency } from './constants'
import FilterCountry from './dropdown'
import { selectActiveTab, selectDataChart, selectLoading } from './store/slice'
import {
  getDataValueOfBonds,
  getDataValueOfBondsCollateral,
} from './store/thunk'

const Content = ({ width, height }) => {
  const dispatch = useDispatch()
  const TimeRangeActive = useSelector(selectActiveMethodType)
  const activeTab = useSelector(selectActiveTab)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)

  const [listCheckbox, setListCheckbox] = useState([
    'privatePlacement',
    'publicPlacement',
    'undefined',
    'rateOfChange',
  ])

  useEffect(() => {
    if (activeTab === valueTimefrequency.ISSUEMETHOD) {
      dispatch(
        getDataValueOfBonds({
          TimeRange: TimeRangeActive,
        }),
      )
    }
    if (activeTab === valueTimefrequency.COLLATERAL) {
      dispatch(
        getDataValueOfBondsCollateral({
          TimeRange: TimeRangeActive,
        }),
      )
    }
  }, [activeTab, TimeRangeActive])

  const dataChartFormat = dataChart.map((e) => ({
    ...e,
    XAxis: e.date,
    privatePlacement:
      activeTab === valueTimefrequency.ISSUEMETHOD
        ? e.privatePlacement / 1000000000
        : e.collateral / 1000000000,
    publicPlacement:
      activeTab === valueTimefrequency.ISSUEMETHOD
        ? e.publicPlacement / 1000000000
        : e.noCollateral / 1000000000,
    undefined: e?.undefined ? e?.undefined / 1000000000 : null,
  }))

  const checkFooter = () => {
    return [
      {
        text:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.privatePlacement'
            : 'bond.overview.ValueOfBonds.chartCollateral.privatePlacement',
        dataKey: 'privatePlacement',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#185999',
        },
      },
      {
        text:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.publicPlacement'
            : 'bond.overview.ValueOfBonds.chartCollateral.publicPlacement',
        dataKey: 'publicPlacement',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#fff6df',
        },
      },
      {
        text:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.undefined'
            : 'bond.overview.ValueOfBonds.chart.undefined',
        dataKey: 'undefined',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#A6CF98',
        },
      },
      {
        text:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? I18n.t('bond.overview.ValueOfBonds.chart.rateOfChange') +
              renderTimeValueBond(TimeRangeActive)
            : I18n.t(
                'bond.overview.ValueOfBonds.chartCollateral.rateOfChange',
              ) + renderTimeValueBond(TimeRangeActive),
        // dataKey: 'rateOfChange',
        type: TYPE.CIRCLE_MARKER_LINE,
        before: {
          bgColor: '#DF5A49',
        },
        after: {
          bgColor: '#DF5A49',
        },
      },
    ]
  }
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterCountry />
            {size.height && (
              <div
                style={{ height: height - size.height }}
                id="valueOfBondsChart"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChart.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div
                              className="position-relative"
                              id="FDIStructureByProvinceId"
                            >
                              {size.height && (
                                <ChartComponent
                                  data={dataChartFormat}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'date'}
                                  displayData={listCheckbox}
                                  name1={'privatePlacement'}
                                  name2={'publicPlacement'}
                                  name3={'undefined'}
                                  lineKey={'rateOfChange'}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={checkFooter()}
                                listCheckbox={listCheckbox}
                                // setListCheckbox={setListCheckbox}
                                setListCheckbox={(e) => setListCheckbox(e)}
                                numItemPerRow={4}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Content
