import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { keyByDate } from '../helper'
import { getBiddingResultsOnTheOpenMarket, getMaxDate } from './thunk'

const previousYear = new Date()
previousYear.setFullYear(previousYear.getFullYear() - 1)

const initialState = {
  reverseSellTab: 'ReverseRepo',
  from: previousYear,
  to: new Date(),
  limitTime: {
    minDate: '',
    maxDate: '',
    minYear: '',
    maxYear: '',
  },
  tableDataLoading: true,
  tableData: {},
}

const slice = createSlice({
  name: 'economy/monetary/openMarket/panelOpenMarketAuction',
  initialState,
  reducers: {
    handleReverseSellTab: (state, action) => {
      state.reverseSellTab = action.payload
    },
    handleFromDate: (state, action) => {
      state.from = action.payload
    },
    handleToDate: (state, action) => {
      state.to = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBiddingResultsOnTheOpenMarket.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(
      getBiddingResultsOnTheOpenMarket.fulfilled,
      (state, action) => {
        state.tableDataLoading = false
        state.tableData = keyByDate(action.payload || [])
      },
    )
    builder.addCase(
      getBiddingResultsOnTheOpenMarket.rejected,
      (state, action) => {
        state.tableDataLoading = action.payload.isLoading
      },
    )
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.limitTime = action.payload
    })
  },
})

export const { handleReverseSellTab, handleFromDate, handleToDate } =
  slice.actions

export const getReverseSellTab = (state) => state[slice.name].reverseSellTab
export const getFromDate = (state) => state[slice.name].from
export const getToDate = (state) => state[slice.name].to
export const getLimitTime = (state) => state[slice.name].limitTime
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableData = (state) => state[slice.name].tableData
export const getOpenMarketAuctionValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)

register(slice.name, slice.reducer)
