export const chartFillColor = ['#45b29d', '#facc5c', '#df5a49']

export const chartPieKeys = ['currentDeposits', 'termDeposits']

export const chartOtherPieKey = 'otherDeposits'

export const chartMappingDisplayName = {
  currentDeposits:
    'sector.financialAnalysis.bank.capitalStructure.CURRENT_DEPOSIT',
  termDeposits: 'sector.financialAnalysis.bank.capitalStructure.TERM_DEPOSIT',
  otherDeposits: 'sector.financialAnalysis.bank.capitalStructure.OTHER',
}

export const chartFormat = {
  currentDeposits: 0.01,
  termDeposits: 0.01,
  otherDeposits: 0.01,
}
