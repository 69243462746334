import { useSelector } from 'react-redux'
import { NoData } from '../../../common/noData'
import { TradingView } from '../../../common/tradingView'
import { selectIndicator } from '../summary/store/slice'

const TechnicalChartPanel = () => {
  const derivativeActive = useSelector(selectIndicator)

  if (
    derivativeActive?.ticker &&
    ![
      'GB05F2209',
      'GB05F2212',
      'GB05F2303',
      'GB10F2209',
      'GB10F2212',
      'GB10F2303',
    ].includes(derivativeActive?.ticker)
  )
    return (
      <TradingView
        defaultSymbol={derivativeActive?.ticker}
        containerId="market/derivative"
        disabledFeatures={['left_toolbar', 'header_widget']}
      />
    )
  else {
    return <NoData />
  }
}

export default TechnicalChartPanel
