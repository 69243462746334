import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { FundTopInfo } from '../../../common/topInfo/fundTopInfo'
import Content from './Content'

export const FundProfilePortfolio = () => {
  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <FundTopInfo title={'fund.fundProfile.PORTFOLIO'} />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    <Content />
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
