import PropTypes from 'prop-types'
import { I18n, Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../../../constants/Common'
import TextEllipsis from '../../../textEllipsis'
import style from './style.module.css'

const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      <span className={style.w70}>
        <TextEllipsis
          text={value.icbNameCustom}
          zIndexTooltip={Z_INDEX.DROPDOWN}
          isI18n={false}
        />
      </span>
      <span className={style.textLongW15}>
        {I18n.t('common.INDUSTRY_LEVEL_COMPACT') + value.icbLevel}
      </span>
      <span className={style.w20AlignRight}>
        <Translate value="common.LABEL_HOHAUP" />
      </span>
    </div>
  )
}

export default DropdownItem

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
