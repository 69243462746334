import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'

export const getImportChangeTurnoverByLocation = createAsyncThunk(
  'economy/importExport/importExportByCountry/getImportChangeTurnoverByLocation',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getImportChangeTurnoverByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExportChangeTurnoverByLocation = createAsyncThunk(
  'economy/importExport/importExportByCountry/getExportChangeTurnoverByLocation',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getExportChangeTurnoverByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getImportTradeAgreementsChangeTurnoverByLocation =
  createAsyncThunk(
    'economy/importExport/importExportByCountry/getImportTradeAgreementsChangeTurnoverByLocation',
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await OverviewService.getImportTradeAgreementsChangeTurnoverByLocation(
            params,
          )
        if (response.success) {
          return response.data
        }
        return rejectWithValue({ loading: false })
      } catch (error) {
        if (axios.isCancel(error)) {
          return rejectWithValue({ loading: true })
        }
        return rejectWithValue({ loading: false })
      }
    },
  )

export const getExportTradeAgreementsChangeTurnoverByLocation =
  createAsyncThunk(
    'economy/importExport/importExportByCountry/getExportTradeAgreementsChangeTurnoverByLocation',
    async (params, { rejectWithValue }) => {
      try {
        const response =
          await OverviewService.getExportTradeAgreementsChangeTurnoverByLocation(
            params,
          )
        if (response.success) {
          return response.data
        }
        return rejectWithValue({ loading: false })
      } catch (error) {
        if (axios.isCancel(error)) {
          return rejectWithValue({ loading: true })
        }
        return rejectWithValue({ loading: false })
      }
    },
  )

export const getMaxDateDetail = createAsyncThunk(
  'economy/importExport/importExportByCountry/exportTurnOver/getMaxDateDetail',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getMaxDateDetail(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
