export const chartFillColor = {
  nii: '#1f669a',
  nfi: '#3cabeb',
  nira: '#f1ffe7',
}

export const chartBarKeys = ['nii', 'nfi', 'nira']

export const chartMappingDisplayName = {
  nii: 'sector.financialAnalysis.bank.profitability.NET_AND_SIMILAR',
  nfi: 'sector.financialAnalysis.bank.profitability.NET_AND_COMMISSION',
  nira: 'sector.financialAnalysis.bank.profitability.NET_FROM_ACTIVITIES',
}

export const chartFormat = {
  nii: 0.01,
  nfi: 0.01,
  nira: 0.01,
}
