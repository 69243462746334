import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import BondListService from '../../../../../../core/services/bond/bondScreening/BondListService'
import { changeSort } from '../../../../../common/dataExplorer/table/store/slice'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { FILTER_FIELD_CODES } from '../../../Screening/constants'
import { INDICATOR_GROUPS } from '../../../constants'
import {
  formatTable,
  getSortFieldByIndicatorGroup,
  getSortOrderByIndicatorGroup,
  renderTableFieldCode,
} from '../../../helper'
import { setFilterParameters } from '../../../store/slice'

export const getDataBondList = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TABLE',
  async (data, { rejectWithValue, getState, dispatch }) => {
    try {
      const { params, redirectToBondIssuer } = data

      if (params.indicatorGroup !== INDICATOR_GROUPS.ALL_ISSUER) {
        delete params.timeRange
        delete params.startDate
        delete params.endDate
      } else if (params.timeRange === calendarTabKey) {
        params.timeRange = TIME_RANGES.All
      }

      const response = await BondListService.getBondList(params)

      const sortBy = getSortFieldByIndicatorGroup(
        params?.sortBy,
        params?.indicatorGroup,
      )
      const order = params?.order?.toLowerCase() ?? 'desc'
      const newOrder = getSortOrderByIndicatorGroup(
        order,
        params?.sortBy,
        params?.indicatorGroup,
      )

      dispatch(
        setFilterParameters({
          key: 'sortBy',
          value: sortBy,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'order',
          value: order,
        }),
      )

      dispatch(
        changeSort({
          [sortBy]: newOrder,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'pageIndex',
          value: params?.pageIndex ?? 1,
        }),
      )

      if (response.success) {
        const allState = getState()
        const locale = allState.i18n.locale

        if (
          response?.data?.body &&
          !response?.data.body[0].bondId &&
          response?.data.body[0].organizationId
        ) {
          response.data.body = response?.data.body.map((item) => ({
            ...item,
            bondId: item.organizationId,
          }))
        }

        const { ids, columnById, tickerById, tickerDisplay, headers } =
          formatTable(
            locale,
            response?.data,
            params.addedInformations,
            redirectToBondIssuer,
          )

        return {
          ids: ids,
          columnById: columnById,
          tickerDisplay,
          tickerById,
          total: response?.data?.totalRecord || 0,
          headers,
          data: response?.data,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataBondListBySort = createAsyncThunk(
  'bond/bondScreening/bondList/GET_TABLE_BY_SORT',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      dispatch(
        setFilterParameters({
          key: 'pageIndex',
          value: params?.pageIndex ?? 1,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'sortBy',
          value: params?.sortBy ?? FILTER_FIELD_CODES.ISSUE_DATE,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'order',
          value: params?.order?.toLowerCase() ?? 'desc',
        }),
      )

      if (params.indicatorGroup !== INDICATOR_GROUPS.ALL_ISSUER) {
        delete params.timeRange
        delete params.startDate
        delete params.endDate
      } else if (params.timeRange === calendarTabKey) {
        params.timeRange = TIME_RANGES.All
      }

      const response = await BondListService.getBondList(params)

      if (response.success) {
        const allState = getState()
        const locale = allState.i18n.locale

        if (
          response?.data?.body &&
          !response?.data.body[0].bondId &&
          response?.data.body[0].organizationId
        ) {
          response.data.body = response?.data.body.map((item) => ({
            ...item,
            bondId: item.organizationId,
          }))
        }

        const { ids, columnById, tickerById, tickerDisplay, headers } =
          formatTable(locale, response?.data, params.addedInformations)

        return {
          ids: ids,
          columnById: columnById,
          tickerDisplay,
          tickerById,
          total: response?.data?.totalRecord || 0,
          headers,
          data: response?.data,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataBondListIsChange = createAsyncThunk(
  'bond/bondScreening/bondList/GET_BOND_LIST_IS_CHANGE',
  async (data, { rejectWithValue, getState }) => {
    try {
      const {
        params,
        dataRemove,
        addInformatics,
        dataSort,
        redirectToBondIssuer,
      } = data

      if (params.indicatorGroup !== INDICATOR_GROUPS.ALL_ISSUER) {
        delete params.timeRange
        delete params.startDate
        delete params.endDate
      } else if (params.timeRange === calendarTabKey) {
        params.timeRange = TIME_RANGES.All
      }

      const response = await BondListService.getBondList(params)

      if (response.success) {
        const allState = getState()
        const locale = allState.i18n.locale
        const bondListState =
          allState['bond/bondScreening/bondAggregator/table']

        const data = bondListState.data

        const headerRemove = dataRemove
          .map((item) => {
            const value = data.header.find(
              (item2) =>
                renderTableFieldCode(item2.indicatorCode) ===
                renderTableFieldCode(item.tableFieldCode),
            )

            if (!value) return null

            return value
          })
          .filter((item) => item)

        const newAddedInformatics = dataSort
          ? params.addedInformations.filter(
              (item) => renderTableFieldCode(item.tableFieldCode) !== dataSort,
            )
          : params.addedInformations

        const headerChange = newAddedInformatics
          .map((item) => {
            const value = response?.data?.header.find(
              (item2) =>
                renderTableFieldCode(item2.indicatorCode) ===
                renderTableFieldCode(item.tableFieldCode),
            )

            if (!value) return null

            return value
          })
          .filter((item) => item)

        const newDataHeader = data?.header.map((item) => ({
          ...item,
          indicatorCode: renderTableFieldCode(item.indicatorCode),
        }))

        const newHeaderRemove = headerRemove.map((header) => ({
          ...header,
          indicatorCode: renderTableFieldCode(header.indicatorCode),
        }))

        const headerNotChange = []

        newDataHeader.forEach((item) => {
          const index1 = newHeaderRemove.findIndex(
            (item1) => item1.indicatorCode === item.indicatorCode,
          )

          if (index1 === -1) {
            headerNotChange.push(item)
          }
        })

        const header = [...headerNotChange, ...headerChange]

        if (
          response?.data?.body &&
          !response?.data.body[0].bondId &&
          response?.data.body[0].organizationId
        ) {
          response.data.body = response?.data.body.map((item) => ({
            ...item,
            bondId: item.organizationId,
          }))
        }

        const body = data.body.map((item) => {
          const bodyRemove = dataRemove
            .map((item2) => {
              const value = item.items.find(
                (item3) =>
                  renderTableFieldCode(item3.indicatorCode) ===
                  renderTableFieldCode(item2.tableFieldCode),
              )

              if (!value) return null

              return value
            })
            .filter((item) => item)

          const newDataBody = item.items.map((item) => ({
            ...item,
            indicatorCode: renderTableFieldCode(item.indicatorCode),
          }))

          const newBodyRemove = bodyRemove.map((header) => ({
            ...header,
            indicatorCode: renderTableFieldCode(header.indicatorCode),
          }))

          const bodyNotChange = []

          newDataBody.forEach((item) => {
            const index1 = newBodyRemove.findIndex(
              (item1) => item1.indicatorCode === item.indicatorCode,
            )

            if (index1 === -1) {
              bodyNotChange.push(item)
            }
          })

          const bodyChange = newAddedInformatics
            .map((item2) => {
              const responseBody = response?.data?.body.find(
                (value) =>
                  (value.bondTicker && value.bondTicker === item.bondTicker) ||
                  (value.bondId && value.bondId === item.bondId),
              )

              if (!responseBody) return null

              const value = responseBody.items.find(
                (item3) =>
                  renderTableFieldCode(item3.indicatorCode) ===
                  renderTableFieldCode(item2.tableFieldCode),
              )

              if (!value) return null

              return value
            })
            .filter((item) => item)

          return { ...item, items: [...bodyNotChange, ...bodyChange] }
        })

        const newData = {
          ...response?.data,
          header,
          body,
        }

        const { ids, columnById, tickerById, tickerDisplay, headers } =
          formatTable(locale, newData, addInformatics, redirectToBondIssuer)

        return {
          ids: ids,
          columnById: columnById,
          tickerDisplay,
          tickerById,
          total: response?.data?.totalRecord || 0,
          headers,
          data: newData,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondTransactionData = createAsyncThunk(
  'bond/bondScreening/bondList/GET_BOND_TRANSACTION_DATA',
  async (data, { rejectWithValue, getState }) => {
    try {
      const { params, addedInformations, redirectToBondIssuer } = data

      if (params.indicatorGroup !== INDICATOR_GROUPS.ALL_ISSUER) {
        delete params.timeRange
        delete params.startDate
        delete params.endDate
      } else if (params.timeRange === calendarTabKey) {
        params.timeRange = TIME_RANGES.All
      }

      const response = await BondListService.getBondList(params)

      if (response.success) {
        const allState = getState()
        const locale = allState.i18n.locale

        if (
          response?.data?.body &&
          !response?.data.body[0].bondId &&
          response?.data.body[0].organizationId
        ) {
          response.data.body = response?.data.body.map((item) => ({
            ...item,
            bondId: item.organizationId,
          }))
        }

        const header = response?.data?.header.filter(
          (item) =>
            addedInformations.find(
              (information) =>
                renderTableFieldCode(information?.tableFieldCode) ===
                renderTableFieldCode(item?.indicatorCode),
            ) ||
            [
              FILTER_FIELD_CODES.BOND_TICKER,
              FILTER_FIELD_CODES.TICKER,
            ].includes(renderTableFieldCode(item?.indicatorCode)),
        )

        const body = response?.data?.body?.map((item) => ({
          ...item,
          items: item?.items?.filter(
            (child) =>
              addedInformations.find(
                (information) =>
                  renderTableFieldCode(information?.tableFieldCode) ===
                  renderTableFieldCode(child?.indicatorCode),
              ) ||
              [
                FILTER_FIELD_CODES.BOND_TICKER,
                FILTER_FIELD_CODES.TICKER,
              ].includes(renderTableFieldCode(child?.indicatorCode)),
          ),
        }))

        const newData = {
          ...response?.data,
          header,
          body,
        }

        const { ids, columnById, tickerById, tickerDisplay, headers } =
          formatTable(locale, newData, addedInformations, redirectToBondIssuer)

        return {
          ids: ids,
          columnById: columnById,
          tickerDisplay,
          tickerById,
          total: response?.data?.totalRecord || 0,
          headers,
          data: newData,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
