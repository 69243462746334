import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'
import { handleExport } from '../../../../../utils/Export'
import { TIME_TYPE } from '../constants'

const NOT_ACC_VALUE = 'NotAccValue'
const ACC_VALUE = 'AccValue'
const IMPORT = 'Import'
const EXPORT = 'Export'

export const getLocationName = createAsyncThunk(
  'economy/importExport/importExportByLocation/getLocationName',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getLocationName(params)

    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByLocation',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByLocation(params)
    const responseName = await OverviewService.getLocationName()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByLocationChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByLocationChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByLocation(params)
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: IMPORT,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByLocationAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByLocationAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByLocation(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByLocationChildrenAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByLocationChildrenAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByLocation(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportByLocationAfterChangeFilter = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportByLocationAfterChangeFilter',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportByLocation({
      DataType: params.DataType,
      Limit: params.Limit,
    })
    const responseName = await OverviewService.getLocationName()

    const responseChildren = await OverviewService.getImportByLocation(params)
    const responseChildrenName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: IMPORT,
    })

    if (
      response.success &&
      responseName.success &&
      responseChildren.success &&
      responseChildrenName.success
    ) {
      return {
        data: response.data,
        nameData: responseName.data,
        childrenData: responseChildren.data,
        childrenNameData: responseChildrenName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByLocationAfterChangeFilter = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByLocationAfterChangeFilter',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByLocation({
      DataType: params.DataType,
      Limit: params.Limit,
    })
    const responseName = await OverviewService.getLocationName()

    const responseChildren = await OverviewService.getExportByLocation({
      DataType: params.DataType,
      Limit: params.Limit,
      LocationIds: params.LocationIds,
    })
    const responseChildrenName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: EXPORT,
    })

    if (
      response.success &&
      responseName.success &&
      responseChildren.success &&
      responseChildrenName.success
    ) {
      return {
        data: response.data,
        nameData: responseName.data,
        childrenData: responseChildren.data,
        childrenNameData: responseChildrenName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByLocation',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByLocation(params)
    const responseName = await OverviewService.getLocationName()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByLocationChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByLocationChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByLocation(params)
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: EXPORT,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByLocationAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByLocationAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByLocation(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportByLocationChildrenAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportByLocationChildrenAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportByLocation(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportTradeAgreementsByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportTradeAgreementsByLocation',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportTradeAgreementsByLocation(
      params,
    )
    const responseTradeAgreementLocationName =
      await OverviewService.getTradeAgreementLocationName()
    if (response.success && responseTradeAgreementLocationName.success) {
      return {
        data: response.data,
        nameData: responseTradeAgreementLocationName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportTradeAgreementsByLocationChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportTradeAgreementsByLocationChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportTradeAgreementsByLocation(
      params,
    )
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: IMPORT,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportTradeAgreementsByLocationAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getImportTradeAgreementsByLocationAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getImportTradeAgreementsByLocation(
      params,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportTradeAgreementsByLocationChildrenAfterScroll =
  createAsyncThunk(
    'economy/importExport/importExportByLocation/getImportTradeAgreementsByLocationChildrenAfterScroll',
    async (params, { rejectWithValue }) => {
      const response = await OverviewService.getImportTradeAgreementsByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    },
  )

export const getExportTradeAgreementsByLocation = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportTradeAgreementsByLocation',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportTradeAgreementsByLocation(
      params,
    )
    const responseTradeAgreementLocationName =
      await OverviewService.getTradeAgreementLocationName()

    if (response.success && responseTradeAgreementLocationName.success) {
      return {
        data: response.data,
        nameData: responseTradeAgreementLocationName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportTradeAgreementsByLocationChildren = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportTradeAgreementsByLocationChildren',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportTradeAgreementsByLocation(
      params,
    )
    const responseName = await OverviewService.getIdsMapping({
      TimeFrequency: params.DataType,
      AccValue:
        params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
      ExImConstant: EXPORT,
    })
    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportTradeAgreementsByLocationAfterScroll = createAsyncThunk(
  'economy/importExport/importExportByLocation/getExportTradeAgreementsByLocationAfterScroll',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getExportTradeAgreementsByLocation(
      params,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getExportTradeAgreementsByLocationChildrenAfterScroll =
  createAsyncThunk(
    'economy/importExport/importExportByLocation/getExportTradeAgreementsByLocationChildrenAfterScroll',
    async (params, { rejectWithValue }) => {
      const response = await OverviewService.getExportTradeAgreementsByLocation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    },
  )

export const getImportTradeAgreementsByLocationAfterChangeFilter =
  createAsyncThunk(
    'economy/importExport/importExportByLocation/getImportTradeAgreementsByLocationAfterChangeFilter',
    async (params, { rejectWithValue }) => {
      const response = await OverviewService.getImportTradeAgreementsByLocation(
        {
          DataType: params.DataType,
          Limit: params.Limit,
        },
      )
      const responseTradeAgreementLocationName =
        await OverviewService.getTradeAgreementLocationName()

      const responseChildren =
        await OverviewService.getImportTradeAgreementsByLocation({
          DataType: params.DataType,
          Limit: params.Limit,
          LocationIds: params.LocationIds,
          areaIds: params.areaIds,
        })
      const responseChildrenName = await OverviewService.getIdsMapping({
        TimeFrequency: params.DataType,
        AccValue:
          params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
        ExImConstant: IMPORT,
      })

      if (
        response.success &&
        responseTradeAgreementLocationName.success & responseChildren.success &&
        responseChildrenName.success
      ) {
        return {
          data: response.data,
          nameData: responseTradeAgreementLocationName.data,
          childrenData: responseChildren.data,
          childrenNameData: responseChildrenName.data,
        }
      }
      return rejectWithValue(response.errors)
    },
  )

export const getExportTradeAgreementsByLocationAfterChangeFilter =
  createAsyncThunk(
    'economy/importExport/importExportByLocation/getExportTradeAgreementsByLocationAfterChangeFilter',
    async (params, { rejectWithValue }) => {
      const response = await OverviewService.getExportTradeAgreementsByLocation(
        {
          DataType: params.DataType,
          Limit: params.Limit,
        },
      )
      const responseTradeAgreementLocationName =
        await OverviewService.getTradeAgreementLocationName()

      const responseChildren =
        await OverviewService.getExportTradeAgreementsByLocation({
          DataType: params.DataType,
          Limit: params.Limit,
          LocationIds: params.LocationIds,
          areaIds: params.areaIds,
        })
      const responseChildrenName = await OverviewService.getIdsMapping({
        TimeFrequency: params.DataType,
        AccValue:
          params.DataType !== TIME_TYPE.MONTHLY ? ACC_VALUE : NOT_ACC_VALUE,
        ExImConstant: EXPORT,
      })

      if (
        response.success &&
        responseTradeAgreementLocationName.success & responseChildren.success &&
        responseChildrenName.success
      ) {
        return {
          data: response.data,
          nameData: responseTradeAgreementLocationName.data,
          childrenData: responseChildren.data,
          childrenNameData: responseChildrenName.data,
        }
      }
      return rejectWithValue(response.errors)
    },
  )

export const getImportDownloadByLocation = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportDownloadByLocation',
  async (data) => {
    const response = await OverviewService.getImportDownloadByLocation(data)
    handleExport(response.data, response.filename)
  },
)

export const getImportTradeAgreementsDownloadByLocation = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportTradeAgreementsDownloadByLocation',
  async (data) => {
    const response =
      await OverviewService.getImportTradeAgreementsDownloadByLocation(data)
    handleExport(response.data, response.filename)
  },
)

export const getExportDownloadByLocation = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportDownloadByLocation',
  async (data) => {
    const response = await OverviewService.getExportDownloadByLocation(data)
    handleExport(response.data, response.filename)
  },
)

export const getExportTradeAgreementsDownloadByLocation = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportTradeAgreementsDownloadByLocation',
  async (data) => {
    const response =
      await OverviewService.getExportTradeAgreementsDownloadByLocation(data)
    handleExport(response.data, response.filename)
  },
)
