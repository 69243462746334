import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styleCommon from '../../../../common/table/index.module.css'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import {
  INDICATOR_FIX_UNIT_INT,
  TABLE_ROW_LEVEL,
  UNIT_CONSTANTS,
} from '../constants'
import { getStyle } from '../helpers'
import {
  selectFilters,
  selectGroupColumns,
  selectIndicator,
} from '../store/slice'
import styles from './index.module.css'

const MARGIN_LEFT = 40

export const FirstColumnCell = ({
  val,
  rowId,
  style,
  className,
  defaultActiveRowId,
  onRowCollapse,
}) => {
  const tdRef = useRef()

  // Use selector
  const item = useSelector(selectIndicator(rowId))
  const groupColumns = useSelector(selectGroupColumns)
  const filters = useSelector(selectFilters)

  // Use state
  const [isCollapse, setIsCollapse] = useState(false)

  // Get data
  const getText = useMemo(() => {
    if (
      INDICATOR_FIX_UNIT_INT.includes(item.key.toUpperCase()) &&
      item.fixUnit
    ) {
      if (
        [
          UNIT_CONSTANTS.USD,
          UNIT_CONSTANTS.THOUSAND_USD,
          UNIT_CONSTANTS.MILLION_USD,
        ].includes(filters.unit.value)
      ) {
        return val.replace('(VND)', '(USD)')
      }
    }

    return val
  }, [filters.unit.value])

  // Use effect
  useEffect(() => {
    if (rowId === defaultActiveRowId) {
      tdRef.current.classList.add(styleCommon.active)
    } else {
      tdRef.current.classList.remove(styleCommon.active)
    }
  }, [groupColumns])

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  if (item.level === TABLE_ROW_LEVEL.LEVEL_0) {
    return (
      <td
        ref={tdRef}
        className={`${className} ${styles.rowLv0}`}
        style={{
          ...style,
          color: '#8b8b8b',
          ...getStyle(item),
        }}
      >
        <div className={styles.firstCell}>
          <TextEllipsisStaticLine val={getText} />
        </div>
      </td>
    )
  } else {
    return (
      <td ref={tdRef} className={className} style={style}>
        <div
          className="align-center"
          style={{
            marginLeft: (item.level - 1) * MARGIN_LEFT,
          }}
        >
          {item.isCollapse ? (
            <button
              style={{ cursor: 'pointer' }}
              className="align-center"
              onClick={onCollapse}
            >
              <i
                className={`${
                  isCollapse ? 'icon-caret-right' : 'icon-caret-down'
                }`}
                style={{ fontSize: '8px', color: '#8c9194' }}
              ></i>
            </button>
          ) : (
            <div style={{ minWidth: 20 }} />
          )}
          <TextEllipsisStaticLine
            val={getText}
            isI18n={false}
            appendStyle={{
              color: item.level === TABLE_ROW_LEVEL.LEVEL_1 ? '#fecf2f' : '',
              lineHeight: 1.5,
              ...getStyle(item),
            }}
          />
        </div>
      </td>
    )
  }
}

FirstColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onRowCollapse: PropTypes.func,
}
