import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import SelectCustom from '../../../../common/selectCustom'
import { REPORT_TYPE } from '../constants'
import {
  changValuePopupFinancialStatement,
  selectPopupFinancialStatement,
} from '../store/slice'

const DropdownReportType = () => {
  const dispatch = useDispatch()

  const listSelect = [
    {
      name: I18n.t('corporate.companySnapshot.CONSOLIDATED'),
      value: REPORT_TYPE.CONSOLIDATED,
    },
    {
      name: I18n.t('corporate.companySnapshot.PARENT'),
      value: REPORT_TYPE.PARENT,
    },
  ]

  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)

  const changeValueSelect = (val) => {
    dispatch(
      changValuePopupFinancialStatement({
        ...popupFinancialStatement,
        reportType: val,
      }),
    )
  }

  return (
    <div className="w-100">
      <SelectCustom
        value={popupFinancialStatement.reportType}
        selectData={listSelect}
        handleChange={changeValueSelect}
      />
    </div>
  )
}

export default DropdownReportType
