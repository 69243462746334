export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#ecffdf',
]

export const viMonthsLowerCase = [
  'Th01',
  'Th02',
  'Th03',
  'Th04',
  'Th05',
  'Th06',
  'Th07',
  'Th08',
  'Th09',
  'Th10',
  'Th11',
  'Th12',
]

export const TIME_FREQUENCY = { ACC_MONTHLY: 'AccMonthly', YEARLY: 'Yearly' }

export const enMonthsTimeFrequency = [
  'Jan',
  '2M',
  '3M',
  '4M',
  '5M',
  '6M',
  '7M',
  '8M',
  '9M',
  '10M',
  '11M',
  '12M',
]

export const viMonthsTimeFrequency = [
  'Th1',
  '2Th',
  '3Th',
  '4Th',
  '5Th',
  '6Th',
  '7Th',
  '8Th',
  '9Th',
  '10Th',
  '11Th',
  '12Th',
]

export const CHART_TYPE = {
  PIE_CHART: 'PieChart',
  BAR_CHART: 'BarChart',
}

export const MIN_DATE_FILTER = 2009

export const TYPE_FORMAT = {
  CAPITAL: 'capital',
  NUMBER_OF_PROJECT: 'numberOfProject',
}
