import { useSelector } from 'react-redux'
import LinkSector from '../../../../common/dropdownLink/LinkSector'
import { selectIcbInfoById } from '../../../../common/masterData/store/slice'
import TextEllipsis from '../../../../common/textEllipsis'
import style from './index.module.css'

const SectorName = ({ icbId }) => {
  const sectorName = useSelector(selectIcbInfoById(icbId, 'icbName'))
  const sectorLevel = useSelector(selectIcbInfoById(icbId, 'icbLevel'))

  return (
    <div className={style.sectorRow}>
      <LinkSector icbId={icbId}>
        <TextEllipsis isI18n={false} text={sectorName + ' L' + sectorLevel} />
      </LinkSector>
    </div>
  )
}

export default SectorName
