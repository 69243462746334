export const HOSE_GROUP = [
  'VNINDEX',
  'VNXALL',
  'VN30',
  'VNMID',
  'VNSML',
  'VNX50',
  'VNX200',
  'VN100',
  'VNALL',
  'VNDIAMOND',
  'VNFINLEAD',
  'VNFINSELECT',
  'VNFIN',
  'VNREAL',
  'VNCOND',
  'VNCONS',
  'VNENE',
  'VNUTI',
  'VNIND',
  'VNMAT',
  'VNHEAL',
  'VNIT',
]

export const HNX_GROUP = ['HNXIndex', 'HNX30', 'HNXLCap', 'HNXMSCap']

export const UPCOM_GROUP = ['UPCOMINDEX']

export const COM_GROUP = {
  BANK: 'Bank',
  SECURITIES: 'Securities',
  CORPORATE: 'Company',
  INSURANCE: 'Insurance',
}

export const COM_GROUP_ID = {
  BANK: 80,
  SECURITIES: 81,
  CORPORATE: 0 || null,
  INSURANCE: 79,
}

export const MAP_INDUSTRY = {
  [COM_GROUP_ID.BANK]: COM_GROUP.BANK,
  [COM_GROUP_ID.INSURANCE]: COM_GROUP.INSURANCE,
  [COM_GROUP_ID.SECURITIES]: COM_GROUP.SECURITIES,
  [null]: COM_GROUP.CORPORATE,
  0: COM_GROUP.CORPORATE,
}

export const ICB_ID = {
  [COM_GROUP.BANK]: [136, 137, 138, 139, 319],
  [COM_GROUP.SECURITIES]: [159, 339],
  [COM_GROUP.CORPORATE]: [],
  [COM_GROUP.INSURANCE]: [140, 141, 142, 143, 144, 145, 146, 147],
}

export const EXCHANGE_CODE_OTHER_HOHAUP = ['OTC', 'PRIVATE']
