import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import useGetICB from '../../common/masterData/useGetICB'
import { SizeTracker } from '../../common/sizeTracker'
import Title from '../../common/topInfo/components/Title'
import { getAllIdTree, getAllParentChecked } from '../../common/tree/helper'
import BondList from '../bondScreening/BondList'
import {
  changeIsFirst,
  resetStore as resetStoreTable,
} from '../bondScreening/BondList/Table/store/slice'
import Screening from '../bondScreening/Screening'
import { INDICATOR_GROUPS } from '../bondScreening/constants'
import style from '../bondScreening/index.module.css'
import {
  changeIsLoadingTable,
  changeIsLoadingTemplates,
  changeTemplateList,
  changeTemplateMoreList,
  resetDataTemplate,
  resetStore,
  selectFilter,
  selectFilterParams,
  selectInitialTemplates,
  selectIsLoadingTable,
  selectIsLoadingTemplates,
  setFilterParameters,
  setInitialParentSector,
  setInitialSectors,
} from '../bondScreening/store/slice'
import {
  getIndicatorData,
  getTableDataByFilter,
  getWatchListData,
} from '../bondScreening/store/thunk'

export const BondBuyback = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const { ICBsTree } = useGetICB(true, true, undefined, undefined, true)

  const { visible } = useSelector(selectFilter)
  const params = useSelector(selectFilterParams)
  const { indicatorGroup } = params
  const locale = useSelector((state) => state.i18n.locale)
  const isLoadingTable = useSelector(selectIsLoadingTable)
  const isLoadingTemplates = useSelector(selectIsLoadingTemplates)
  const initialTemplates = useSelector(selectInitialTemplates)

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  useEffect(() => {
    dispatch(
      setFilterParameters({
        key: 'indicatorGroup',
        value: INDICATOR_GROUPS.BOND_TRANSACTION,
      }),
    )
    dispatch(resetDataTemplate())
    dispatch(changeIsLoadingTable(true))
    dispatch(changeIsLoadingTemplates(true))
    return () => {
      dispatch(resetStore())
      dispatch(resetStoreTable())
    }
  }, [])

  useEffect(() => {
    if (!!Object.keys(ICBsTree)) {
      const list = getAllIdTree(ICBsTree, 'icbCode')
      const parent = getAllParentChecked(ICBsTree, 'icbCode', list)

      dispatch(setInitialSectors(list))
      dispatch(setInitialParentSector(parent))
    }
  }, [Object.keys(ICBsTree)])

  useEffect(() => {
    dispatch(changeIsFirst(true))
    dispatch(getWatchListData())
  }, [locale, indicatorGroup])

  useEffect(() => {
    dispatch(getIndicatorData({ Language: locale }))
  }, [locale])

  useEffect(() => {
    if (isLoadingTable) {
      dispatch(
        getTableDataByFilter({ type: indicatorGroup, redirectToBondIssuer }),
      )
      dispatch(changeIsLoadingTable(false))
    }
  }, [isLoadingTable, indicatorGroup])

  useEffect(() => {
    if (isLoadingTemplates) {
      const data = initialTemplates?.filter(
        (item) => item.templateType === indicatorGroup,
      )

      const newData = [
        {
          templateId: 0,
          templateName: `bond.bondScreening.bondList.${
            indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
              ? 'ALL_ISSUER'
              : 'ALL_BOND'
          }`,
          templateType: params.type,
          hidden: true,
        },
        ...data,
      ]

      dispatch(changeTemplateList(newData))
      dispatch(changeTemplateMoreList(newData))
      dispatch(changeIsLoadingTemplates(false))
    }
  }, [isLoadingTemplates, indicatorGroup])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.bondScreening.common.BOND_BUYBACK_TITLE" />
            </div>
            {size.height && (
              <div
                className={style.relative}
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <div
                  className="d-flex h-100"
                  style={{ gap: 10, position: 'relative' }}
                >
                  {visible && <Screening />}
                  <BondList />
                </div>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
