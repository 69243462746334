import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'

const name = 'bond/issues'
const initialState = {
  currentTab: 'synthetic',
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    // restore to default state
    resetTab(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const selectCurrentTab = (state) => state[slice.name].currentTab
export const selectcurrentComponent = (state) =>
  state[slice.name].currentComponent

export const { resetTab, updateCurrentTab } = slice.actions

register(slice.name, slice.reducer)
