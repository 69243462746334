import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetFund from '../../../../../common/masterData/useGetFund'
import { selectFundInfo } from '../../../../../common/topInfo/fundTopInfo/store/slice'
import { debounce } from '../../../../../utils/Common'
import { getParams } from '../helpers'
import {
  selectFilters,
  selectIsDisableCallApi,
  selectTableTab,
} from '../store/slice'
import { getTableDataThunk } from '../store/thunk'

export const withGetData = (Component) => (props) => {
  const dispatch = useDispatch()
  const { funds } = useGetFund()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const isDisableCallApi = useSelector(selectIsDisableCallApi)
  const fundInfo = useSelector(selectFundInfo)

  const handleGetData = useCallback(
    debounce((params, tab, securityHoldingTab) => {
      dispatch(getTableDataThunk({ params, tableTab: tab, securityHoldingTab }))
    }, 200),
    [],
  )

  useEffect(() => {
    if (!isDisableCallApi) {
      const params = getParams({
        organizationId: fundInfo.organizationId,
        filters,
        tableTab,
        funds,
      })
      handleGetData(params, tableTab, filters.securityHoldingTab)
    }
  }, [
    fundInfo.organizationId,
    JSON.stringify(filters.assetIds),
    filters.securityHoldingTab,
    filters.timeRange,
    filters.startDate,
    filters.endDate,
    filters.month,
    filters.year,
    filters.isGroupByIndustry,
    filters.page,
    filters.sortField,
    filters.sortOrder,
    tableTab,
    locale,
  ])

  return <Component {...props} />
}
