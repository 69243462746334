import style from '../index.module.css'
import ListStockCheckbox from './ListStockCheckbox'
import SearchGroupStock from './SearchGroupStock'

const GroupStock = () => {
  return (
    <div className={style.groupStock}>
      <SearchGroupStock />
      <ListStockCheckbox />
    </div>
  )
}

export default GroupStock
