import React from 'react'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import ChartComponent from './ChartComponent'

export const NEER_BEER = 'NEER_BEER'

const NeerBeer = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title={''}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <ChartComponent
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default NeerBeer
