import PropTypes from 'prop-types'
import useHeightTdTable from '../../../../common/hooks/useHeightTdTable'
import { Icon } from '../../../../common/html/Icon'
import { getFontSize } from '../../../../utils/FontSize'
import style from '../panelTable/toiTable/toiTable.module.css'

const ToiTableHead = ({
  children,
  colSpan,
  styleProps,
  onClick,
  isActive,
  isCollapse,
}) => {
  const heightTd = useHeightTdTable()

  const renderChildren = () => {
    if (isCollapse) {
      return (
        <div className={style.containerCaretIcon}>
          <i
            style={{
              fontSize: 10,
              alignSelf: 'center',
              color: '#8c9194',
              transform: isActive ? '' : 'rotate(-90deg)',
            }}
            className="icon-caret"
          />
          {children}
          <Icon />
        </div>
      )
    }
    return <>{children}</>
  }

  return (
    <th
      style={{ ...styleProps, fontSize: getFontSize(), height: heightTd }}
      colSpan={colSpan}
      className={`${style.toiTableHead} ${isActive && style.tableHeadActive}`}
      onClick={onClick}
    >
      {renderChildren()}
    </th>
  )
}

ToiTableHead.propTypes = {
  children: PropTypes.node.isRequired,
  colSpan: PropTypes.number,
  styleProps: PropTypes.object,
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isCollapse: PropTypes.bool,
}

export default ToiTableHead
