import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { ScrollComponent } from '../../../common/ScrollComponent'
import PopupContentMenu from '../../common/dropdownMoreDashboard/PopupContentMenu'
import {
  selectDashboardLoading,
  selectMyChartLibrary,
  selectMyDashboard,
} from '../../store/slice'
import style from '../style.module.css'
import styles from '../styleTheme.module.css'

export const PopupContent = ({
  buttonSaveRef,
  chartTitle,
  newDashboardName,
  chartTabActive,
  chartTabs,
  isAddNewDashboard,
  setChartTitle,
  setNewDashboardName,
  setIsAddNewDashboard,
  setIsEnterAddNewDashboard,
  onChangeNameChart,
}) => {
  const dashboardLoading = useSelector(selectDashboardLoading)
  const myChartLibrary = useSelector(selectMyChartLibrary)
  const myDashboard = useSelector(selectMyDashboard)

  const handleChangeChartTitle = (e) => {
    if (isAddNewDashboard) return
    setChartTitle(e.target.value)
    onChangeNameChart &&
      onChangeNameChart({
        ...(chartTabs[chartTabActive]?.nameChart || {}),
        name: e.target.value,
      })
  }

  return (
    <>
      <div
        className={style.popupSaveChartTitle}
        style={{ opacity: isAddNewDashboard ? 0.5 : 1 }}
      >
        <Span style={{ color: '#4e4e4e', fontWeight: 500 }}>
          <Translate value="financialChart.CHART_TITLE" />
        </Span>
        <Input
          className={styles.inputStyle}
          value={chartTitle}
          onChange={handleChangeChartTitle}
          disabled={isAddNewDashboard}
        />
      </div>
      <div className={style.popupSaveChartContent}>
        {dashboardLoading && <Loading isBlack />}
        {!dashboardLoading && (
          <ScrollComponent bgColor="#D9D9D9">
            <>
              <PopupContentMenu
                buttonSaveRef={buttonSaveRef}
                treeMenu={myDashboard}
                isAddNewDashboard={isAddNewDashboard}
                newDashboardName={newDashboardName}
                setNewDashboardName={setNewDashboardName}
                setIsAddNewDashboard={setIsAddNewDashboard}
                setIsEnterAddNewDashboard={setIsEnterAddNewDashboard}
              />
              <PopupContentMenu
                buttonSaveRef={buttonSaveRef}
                treeMenu={myChartLibrary}
                isChartLibrary={true}
              />
            </>
          </ScrollComponent>
        )}
      </div>
    </>
  )
}
