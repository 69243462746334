import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import { deepCopy } from '../popupIndicator/helper'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import CustomItemSelect from './common/CustomItemSelect'
import ItemSelect from './common/ItemSelect'
import TableTicker from './common/TableTicker'
import style from './index.module.css'
import { resetPopupTemplate } from './store/slice'

export const units = [
  {
    value: 1 / 1000000000,
    name: 'tool.dataExplorer.BILLION_VND',
  },
  {
    value: 1 / 1000000,
    name: 'tool.dataExplorer.MILLION_VND',
  },
  {
    value: 1 / 1000,
    name: 'tool.dataExplorer.THOUSAND_VND',
  },
  {
    value: 1,
    name: 'VND',
  },
]

export const vndUnit = 1

const FinancialStatement = ({
  handleCancel,
  handleSubmit,
  ttmMaxQuarter,
  ttmMaxYear,
  ttmMinYear,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetPopupTemplate())
  }, [])

  const selectYear = range(ttmMinYear, ttmMaxYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  const submit = (data) => {
    const detail = deepCopy(data.detail)
    detail.years = [detail.year]
    detail.quarters = [detail.quarter]
    delete detail.year
    delete detail.quarter
    detail.period = 'Specific'
    handleSubmit({ ...data, detail })
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.dataExplorer.FS" />
            </Span>
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className="content">
            <div className={`col ${style.colTicker}`}>
              <div className={`col-header pl-12-pr-16 ${style.h37px}`}>
                <Translate value="tool.dataExplorer.INDICATOR_LABEL" />
              </div>
              <div className="col-content">
                <div className={style.fsItem}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="tool.dataExplorer.FS2" />
                  </Span>
                  <div className="mt-0">
                    <div className={style.fsSubItem}>
                      <ItemSelect
                        defaultValue={ttmMaxQuarter}
                        keySelect="quarter"
                        selectData={[
                          { value: 1, name: 'Q1' },
                          { value: 2, name: 'Q2' },
                          { value: 3, name: 'Q3' },
                          { value: 4, name: 'Q4' },
                          { value: 6, name: I18n.t('common.popup.SIX_MONTH') },
                          { value: 9, name: I18n.t('common.popup.NINE_MONTH') },
                          { value: 5, name: I18n.t('common.popup.ANNUAL') },
                        ]}
                      />
                    </div>
                    <div className={style.fsSubItem}>
                      <ItemSelect
                        defaultValue={ttmMaxYear}
                        keySelect="year"
                        selectData={selectYear}
                      />
                    </div>
                  </div>
                </div>
                <div className={style.fsItem}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="tool.dataExplorer.FINANCIAL_TARGET" />
                  </Span>
                  <ItemSelect
                    defaultValue={ttmMaxYear}
                    keySelect="targetYear"
                    selectData={selectYear}
                  />
                </div>
                <div className={style.fsItem}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="tool.dataExplorer.REPORT_TYPE" />
                  </Span>
                  <ItemSelect
                    defaultValue="Consolidated"
                    keySelect="reportType"
                    selectData={[
                      {
                        value: 'Consolidated',
                        name: I18n.t('common.popup.CONSOLIDATED'),
                      },
                      {
                        value: 'Unconsolidated',
                        name: I18n.t('common.popup.UNCONSOLIDATED'),
                      },
                    ]}
                  />
                </div>
                <div className={style.fsItem}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="tool.dataExplorer.REPORT_FORM" />
                  </Span>
                  <ItemSelect
                    defaultValue="FSCompany"
                    keySelect="companyType"
                    selectData={[
                      {
                        value: 'FSCompany',
                        name: I18n.t('tool.dataExplorer.COMPANY'),
                      },
                      {
                        value: 'FSBank',
                        name: I18n.t('tool.dataExplorer.BANK'),
                      },
                      {
                        value: 'FSSecurities',
                        name: I18n.t('tool.dataExplorer.SERCURITY'),
                      },
                      {
                        value: 'FSNonInsurance',
                        name: I18n.t('tool.dataExplorer.NONLIFE_INSURANCE'),
                      },
                      {
                        value: 'FSInsurance',
                        name: I18n.t('tool.dataExplorer.LIFE_INSURANCE'),
                      },
                    ]}
                  />
                </div>
                <div className={style.fsItem}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="tool.dataExplorer.AUDIT_STATUS" />
                  </Span>
                  <ItemSelect
                    defaultValue="Both"
                    keySelect="audit"
                    selectData={[
                      {
                        value: 'Both',
                        name: I18n.t('common.popup.BOTH_AUDIT'),
                      },
                      {
                        value: 'Audited',
                        name: I18n.t('common.popup.AUDITED'),
                      },
                      {
                        value: 'Unaudited',
                        name: I18n.t('common.popup.UNAUDITED'),
                      },
                    ]}
                  />
                </div>
                <div className={style.fsItem}>
                  <div className={style.fsSubItem}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.dataExplorer.CURRENCY" />
                    </Span>
                    <CustomItemSelect
                      defaultValue="VND"
                      keySelect="currency"
                      selectData={[{ value: 'VND', name: 'VND' }]}
                    />
                  </div>
                  <div className={style.fsSubItem}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="tool.dataExplorer.UNIT" />
                    </Span>
                    <CustomItemSelect
                      defaultValue={units[0].value}
                      keySelect="unit"
                      selectData={units}
                      isI18n={true}
                    />
                  </div>
                </div>
              </div>
            </div>
            <TableTicker />
          </div>
          <Count />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit submit={submit} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default FinancialStatement
