import { createAsyncThunk } from '@reduxjs/toolkit'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'
import { handleExport } from '../../../../../../utils/Export'

export const getImportStatisticsByMarket = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportStatisticsByMarket',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getImportStatisticsByMarket(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportDownloadStatisticsByMarket = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByMarket/getImportDownloadStatisticsByMarket',
  async (data) => {
    const response = await FisheryService.getImportDownloadStatisticsByMarket(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const getMaxDate = createAsyncThunk(
  'sector/sectorSpecific/fishery/exporter/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
