import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DailyReportService from '../../../../../core/services/newsReport/DailyReportService'

export const getTemplate = createAsyncThunk(
  'newsReport/dailyReport/template/getTemplate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await DailyReportService.getTemplates(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const deleteTemplates = createAsyncThunk(
  'newsReport/dailyReport/template/deleteTemplates',
  async (params, { dispatch }) => {
    const res = await DailyReportService.deleteTemplates(params)
    if (res.success) {
      dispatch(getTemplate())
    }
  },
)

export const putTemplates = createAsyncThunk(
  'newsReport/dailyReport/template/putTemplates',
  async (params, { dispatch }) => {
    const res = await DailyReportService.putTemplates(params)
    if (res.success) {
      dispatch(getTemplate())
    }
  },
)
