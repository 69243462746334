import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHART_TYPES } from '../../../common/chart/financialChart/constant'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { LIST_TYPE } from '../../common/constants'
import { dataFormatTypes } from '../../common/menuChartSettings/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { useResize } from '../../common/useResize'
import { POSITION } from '../../popup/popupChangeYAxisPosition'
import { selectUserSettings } from '../../store/slice'
import ToolHeader from '../common/toolHeader'
import { keyToolHeaderSettings, positionXAxis, VIEW_TYPE } from '../constants'
import { checkDefaultChartSettings, randomColor } from '../helper'
import {
  changeFullTreeSecuritiesEconomy,
  changeFullTreeSecuritiesIndicatorBySecurities,
  changeListYAxisCustom,
  changeSettings,
  changeShowTable,
  changeTreeSecuritiesIndicator,
  changeTypeChart,
  changeViewType,
  changSyncEquations,
  changSyncTreeSecuritiesIndicator,
  selectChartTabActiveData,
} from '../store/slice'
import ContentChildrenRight from './ContentChildrenRight'

export const Right = ({ isEditChart }) => {
  const dispatch = useDispatch()

  const targetRef = useRef(null)
  const { height, width } = useResize(targetRef)

  const {
    favoriteColors,
    defaultChartSettings,
    addDefaultChartSettings,
    removeDefaultChartSettings,
  } = useGetUserSettings()

  const userSettings = useSelector(selectUserSettings)
  const chartTabActiveData = useSelector(selectChartTabActiveData)
  const {
    typeChart,
    settings,
    styleSetting,
    listYAxisCustom: yAxisCustom,
    isShowTable,
    viewType,
    treeSecuritiesIndicator,
    equations,
  } = chartTabActiveData

  const isDefaultSettings = useMemo(() => {
    return checkDefaultChartSettings(
      settings,
      styleSetting,
      defaultChartSettings,
    )
  }, [settings, styleSetting, defaultChartSettings])

  const isViewTypeSync = useMemo(() => {
    let chartTypes = []
    Object.keys(treeSecuritiesIndicator).forEach((key) => {
      const security = treeSecuritiesIndicator[key]

      if (key === LIST_TYPE.ECONOMY) {
        Object.values(security).forEach((economy) =>
          chartTypes.push(economy.chartType),
        )
      } else {
        Object.values(security).forEach((indicators) =>
          indicators.forEach((indicator) =>
            chartTypes.push(indicator.chartType),
          ),
        )
      }
    })
    Object.values(equations).forEach((equation) =>
      chartTypes.push(equation.chartType),
    )

    chartTypes = chartTypes.filter(
      (value, index, array) =>
        array.findIndex((val) => val === value) === index,
    )

    return chartTypes.length === 1
  }, [JSON.stringify(treeSecuritiesIndicator), JSON.stringify(equations)])

  const setSyncTreeSecuritiesIndicatorsBySecurities = () => {
    Object.keys(treeSecuritiesIndicator).forEach((key) => {
      if (key !== LIST_TYPE.ECONOMY) {
        const haveIndicators =
          Object.keys(treeSecuritiesIndicator[key]).length > 0
        const indicators = haveIndicators
          ? Object.values(treeSecuritiesIndicator[key])[0]
          : []
        indicators.forEach((indicator, index) => {
          dispatch(
            changeFullTreeSecuritiesIndicatorBySecurities({
              typeSecurities: key,
              indexIndicator: index,
              data: indicator,
            }),
          )
        })
      } else {
        Object.keys(treeSecuritiesIndicator[key]).forEach((key) =>
          dispatch(
            changeFullTreeSecuritiesEconomy({
              securitiesId: key,
              data: { dataFormatType: dataFormatTypes.VALUE },
            }),
          ),
        )
      }
    })

    // Sync Equations
    dispatch(
      changSyncEquations({
        keyUpdate: 'dataFormatType',
        value: dataFormatTypes.VALUE,
      }),
    )
    dispatch(
      changSyncEquations({
        keyUpdate: 'chartType',
        value: CHART_TYPES.BAR,
      }),
    )
  }

  const setSyncTreeSecuritiesIndicatorsByTime = () => {
    const usedColors = [
      ...Object.values(treeSecuritiesIndicator.economy).map(
        (item) => item.color,
      ),
      ...Object.values(equations).map((item) => item.color),
    ]

    const newData = Object.keys(treeSecuritiesIndicator).reduce(
      (typeObj, typeKey) => {
        if (typeKey !== LIST_TYPE.ECONOMY) {
          const security = Object.keys(treeSecuritiesIndicator[typeKey]).reduce(
            (securityObj, securityKey) => {
              const indicators = treeSecuritiesIndicator[typeKey][
                securityKey
              ].map((item) => {
                const color = randomColor(usedColors, favoriteColors)
                usedColors.push(color)
                return { ...item, color }
              })
              return { ...securityObj, [securityKey]: indicators }
            },
            {},
          )
          return { ...typeObj, [typeKey]: security }
        } else {
          return { ...typeObj, [typeKey]: treeSecuritiesIndicator[typeKey] }
        }
      },
      {},
    )

    dispatch(changeTreeSecuritiesIndicator(newData))
  }

  const handleChangeSetting = (value) => {
    dispatch(changeSettings(value))
    if (value[keyToolHeaderSettings.POSITION_GROUP_Y_AXIS]) {
      switch (value[keyToolHeaderSettings.POSITION_GROUP_Y_AXIS]) {
        case POSITION.LEFT:
        case POSITION.RIGHT:
          dispatch(
            changeListYAxisCustom(
              Object.keys(yAxisCustom).reduce(
                (obj, key) => ({
                  ...obj,
                  [key]: {
                    ...yAxisCustom[key],
                    orientation:
                      value[keyToolHeaderSettings.POSITION_GROUP_Y_AXIS],
                  },
                }),
                {},
              ),
            ),
          )
          break
        default:
          break
      }
    }
  }

  const handleChangeDefaultSettings = () => {
    if (isDefaultSettings) {
      removeDefaultChartSettings()
    } else {
      addDefaultChartSettings({ settings, styleSetting })
    }
  }

  const handleChangeViewType = (value) => {
    dispatch(changeViewType(value))
    if (value === VIEW_TYPE.BY_SECURITIES) {
      setSyncTreeSecuritiesIndicatorsBySecurities()
      dispatch(changeTypeChart(CHART_TYPES.BAR))
    } else {
      setSyncTreeSecuritiesIndicatorsByTime()
    }
  }

  const handleChangeTypeChart = (value) => {
    dispatch(changeTypeChart(value))
    dispatch(
      changSyncTreeSecuritiesIndicator({
        keyUpdate: 'chartType',
        value: value,
      }),
    )
    dispatch(
      changSyncEquations({
        keyUpdate: 'chartType',
        value: value,
      }),
    )
  }

  const renderContentChildrenRight = (height) => {
    return (
      <ContentChildrenRight
        height={height}
        width={width}
        isEditChart={isEditChart}
      />
    )
  }

  useEffect(() => {
    let position = positionXAxis.CUSTOM
    const initialValue = Object.keys(yAxisCustom).reduce((previous, key) => {
      if (key !== 'dummyRight' && key !== 'dummyLeft') {
        return { ...previous, [key]: yAxisCustom[key] }
      }
      return previous
    }, {})
    if (
      !Object.values(initialValue).length &&
      userSettings?.defaultChartSettings?.settings?.positionGroupXAxis
    ) {
      position =
        userSettings?.defaultChartSettings?.settings?.positionGroupXAxis ===
        POSITION.CUSTOM
          ? POSITION.RIGHT
          : userSettings?.defaultChartSettings?.settings?.positionGroupXAxis
    } else if (
      Object.values(initialValue).every(
        (item) => item.orientation === positionXAxis.RIGHT,
      )
    ) {
      position = positionXAxis.RIGHT
    } else if (
      Object.values(initialValue).every(
        (item) => item.orientation === positionXAxis.LEFT,
      )
    ) {
      position = positionXAxis.LEFT
    } else {
      position = positionXAxis.CUSTOM
    }

    dispatch(
      changeSettings({
        [keyToolHeaderSettings.POSITION_GROUP_Y_AXIS]: position,
      }),
    )
  }, [
    JSON.stringify(yAxisCustom),
    userSettings?.defaultChartSettings?.settings?.positionGroupXAxis,
  ])

  return (
    <div className="w-100 h-100" ref={targetRef}>
      <ScrollComponent>
        <div style={{ width: '100%', height: '100%' }}>
          <SizeTracker>
            {(size) => (
              <>
                <ToolHeader
                  typeChart={typeChart}
                  settings={settings}
                  viewType={viewType}
                  chartSettings={chartTabActiveData}
                  isSetAsDefault={isDefaultSettings}
                  isShowTable={isShowTable}
                  isViewTypeSync={isViewTypeSync}
                  toggleShowTable={changeShowTable}
                  changeTypeChart={handleChangeTypeChart}
                  changeSettings={handleChangeSetting}
                  changeIsSetAsDefault={handleChangeDefaultSettings}
                  handleChangeViewType={handleChangeViewType}
                />
                {size.height && (
                  <div
                    style={{
                      height: height - size.height || 0,
                    }}
                  >
                    {renderContentChildrenRight(height - size.height || 0)}
                  </div>
                )}
              </>
            )}
          </SizeTracker>
        </div>
      </ScrollComponent>
    </div>
  )
}
