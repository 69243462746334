import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { formatVal } from '../../../../../utils/Value'
import {
  handleListCheckboxClaim,
  selectListCheckboxClaim,
} from '../store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const BAR_COLORS = ['#d2e5ff', '#185999']
const YAXIS_ID = 'left'
const MARGIN = { ...MARGIN_RECHARTS, right: 16, left: 12 }

const ChartComponent = ({ data, width, height, keyXAxis, barKeys }) => {
  const dispatch = useDispatch()
  const listCheckbox = useSelector(selectListCheckboxClaim)

  const setListCheckbox = (newListCheckbox) => {
    dispatch(handleListCheckboxClaim(newListCheckbox))
  }

  const renderTooltip = (tooltipData, decimalLengths) => {
    const decimalLength = decimalLengths[YAXIS_ID] || 2
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.INSU_COMBINED_RATIO',
        ),
        value: formatVal(tooltipData[barKeys[0]], decimalLength) + '%',
      },
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.INSU_CLAIM_RATIO',
        ),
        value: formatVal(tooltipData[barKeys[1]], decimalLength) + '%',
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                tickFormatter={(value) => value}
                isUseXAxisDiv
                showAllTick
                yAxis={[
                  {
                    id: YAXIS_ID,
                    keys: listCheckbox.length ? listCheckbox : barKeys,
                    orientation: 'left',
                    isBarChart: true,
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                    unitYAxis: '%',
                  },
                ]}
                margin={MARGIN}
                renderCustomTooltip={renderTooltip}
              >
                {({ chartContentWidth }) => (
                  <>
                    {listCheckbox.includes(barKeys[0]) ? (
                      <Bar
                        isAnimationActive={false}
                        yAxisId={YAXIS_ID}
                        dataKey={barKeys[0]}
                        barSize={getColumnSizeInBarChart(
                          chartContentWidth,
                          data.length,
                        )}
                        fill={BAR_COLORS[0]}
                      />
                    ) : (
                      ''
                    )}
                    {listCheckbox.includes(barKeys[1]) ? (
                      <Bar
                        isAnimationActive={false}
                        yAxisId={YAXIS_ID}
                        dataKey={barKeys[1]}
                        barSize={getColumnSizeInBarChart(
                          chartContentWidth,
                          data.length,
                        )}
                        fill={BAR_COLORS[1]}
                      />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'corporate.businessModel.penetrationAnalysis.INSU_COMBINED_RATIO',
                  dataKey: barKeys[0],
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[0],
                  },
                },
                {
                  text: 'corporate.businessModel.penetrationAnalysis.INSU_CLAIM_RATIO',
                  dataKey: barKeys[1],
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLORS[1],
                  },
                },
              ]}
              listCheckbox={listCheckbox}
              setListCheckbox={setListCheckbox}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
