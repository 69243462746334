import { useDispatch, useSelector } from 'react-redux'
import { dashboardTypes, screenTypes } from '../../constants'
import { ReactComponent as IconDashboardTemplate } from '../../icon/icon-dashboard-template.svg'
import { changeDashboard, selectDashboardTemplate } from '../../store/slice'
import { List } from './common/List'
import { RootButtonWithCollapse } from './common/RootButtonWithCollapse'

const DashboardTemplate = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectDashboardTemplate)

  const onClickItem = (data) => {
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.DASHBOARD_TEMPLATE,
        data,
      }),
    )
  }

  return (
    data && (
      <div>
        <RootButtonWithCollapse
          data={data}
          renderIcon={() => <IconDashboardTemplate style={{ height: 11 }} />}
        >
          <List data={data.children} onClickItem={onClickItem} />
        </RootButtonWithCollapse>
      </div>
    )
  )
}

export default DashboardTemplate
