import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class OwnerShipProxy extends ProxyBase {
  getOverviewOwnershipChart(params) {
    return this.get('GetOwnershipOverviewChartData', params)
  }

  getMajorOwnershipChart(params) {
    return this.get('GetMajorOwnershipChartData', params)
  }

  getMajorOwnership(params) {
    return this.get('GetMajorShareHolder', params)
  }

  getManagementAndDirectors(params) {
    return this.get('GetManagementAndDirectorsOwnership', params)
  }

  getAssociate(params) {
    return this.get('GetAssociatesOwnership', params)
  }

  getSubsidiaries(params) {
    return this.get('GetSubsidiariesOwnership', params)
  }

  getDirectorDeals(params) {
    return this.get('GetDirectorDeals', params, null, false)
  }

  getHistoricalTooltip(params) {
    return this.get('GetShareHolderTooltipHistorical', params)
  }

  getOwnershipTooltip(params) {
    return this.get('GetShareHolderTooltipOwnership', params)
  }

  downloadMajorShareHolders(params) {
    return this.get('DownloadMajorShareHolders', params, 'download')
  }

  downloadManagementAndDirectors(params) {
    return this.get('DownloadManagementAndDirectors', params, 'download')
  }

  downloadSubsidiaries(params) {
    return this.get('DownloadSubsidiaries', params, 'download')
  }

  downloadAssociates(params) {
    return this.get('DownloadAssociates', params, 'download')
  }

  downloadShareHolderTooltip_Historical(params) {
    return this.get('DownloadShareHolderTooltip_Historical', params, 'download')
  }

  downloadShareHolderTooltip_Ownership(params) {
    return this.get('DownloadShareHolderTooltip_Ownership', params, 'download')
  }
}

export default new OwnerShipProxy(ServiceProxyConfig.Corporate.ServiceUrl)
