import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import style from './index.module.css'
import { getDropdownValue } from './store/slice'

const Tooltip = ({ payload, item }) => {
  const fontSize = getFontSize(11)
  const dropdownValue = useSelector(getDropdownValue)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <TextEllipsisStaticLine
        appendStyle={{ fontSize, fontWeight: 400 }}
        lineClamp={2}
        isI18n={false}
        val={`${item.indicatorName}:`}
      />
      <Span
        style={{ marginLeft: 10, fontSize: 11 }}
        className={style.toolTipContentValue}
      >
        {formatVal(payload[`${item.author}-${dropdownValue}`])}
      </Span>
    </div>
  )
}

export default Tooltip
