import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../dropdown'
import { Input } from '../../html/Input'
import { Span } from '../../html/Span'
import style from '../index.module.css'

export const LinkIcon = ({ url }) => {
  const copyLinkRef = useRef()
  const [isShow, setIsShow] = useState(false)
  const ref = useRef({})

  const clearTimeOut = () => {
    if (ref.current.timeOut) {
      ref.current.timeOut = null
      clearTimeout(ref.current.timeOut)
    }
  }

  useEffect(() => {
    return () => clearTimeOut()
  })

  const onClick = () => {
    copy(url)
    setIsShow(true)
    clearTimeOut()
    ref.current.timeOut = setTimeout(() => {
      setIsShow(false)
    }, 1000)
  }

  return (
    <>
      <div className="d-flex">
        <Input
          type="text"
          defaultValue={url}
          className={style.copyLinkInput}
          style={{ fontSize: 11 }}
        />
        <span
          onClick={onClick}
          ref={copyLinkRef}
          className={style.copyLinkSpan}
        >
          <Translate value="common.shareButton.COPY_LINK" />
        </span>
      </div>
      {isShow && (
        <Dropdown
          position={positionDropdown.TOP_CENTER}
          parentRef={copyLinkRef}
          zIndex={9999}
        >
          <div className={style.tooltipContentInner}>
            <Span style={{ fontSize: 9 }}>
              <Translate value="common.shareButton.COPIED_TO_CLIPBOARD" />
            </Span>
            <div className={style.triangle} />
          </div>
        </Dropdown>
      )}
    </>
  )
}

LinkIcon.propTypes = {
  url: PropTypes.string.isRequired,
}
