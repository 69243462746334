import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'

import {
  selectDataFDF,
  selectPopupPeerAnalysis,
  selectSetting,
  selectShowPreview,
  selectYourCommentaryOnEarnings,
} from '../../filter/store/slice'
import style from '../../preview/style.module.css'
import { LineChart } from '../LineChart'
import SizeComponent from '../SizeComponent'
import FinancialDataTable from './FinancialDataTable'
import KeyFinancialDataTable from './KeyFinancialDataTable'
import LatestOwnershipStructureTable from './LatestOwnershipStructureTable'
import Top5MajorShareHolderTable from './Top5MajorShareHolderTable'

const PeerAnalysisAndFinancialData = () => {
  const data = useSelector(selectDataFDF)
  const dataTopInfo = useSelector(selectBasicInfo)
  const popupPeerAnalysis = useSelector(selectPopupPeerAnalysis)
  const setting = useSelector(selectSetting)
  const commentaryOnEarning = useSelector(selectYourCommentaryOnEarnings)
  const showPreview = useSelector(selectShowPreview)

  const [positionTitle, setPositionTitle] = useState(0)

  const objMapPositionTitle = {
    peerAnalysis: 0,
    financialData: 1,
    yourCommentaryOnEarnings: 2,
    ownershipAnalysis: 3,
  }

  const dataOwnershipAnalysisChart = data.ownershipAnalysisChart[0]
    ? [
        {
          text: 'corporate.companySnapshot.STATE_OWNED',
          value: data.ownershipAnalysisChart[0]['statePercentage'] || 0,
          color: '#45b29d',
        },
        {
          text: 'corporate.companySnapshot.FOREIGN_OWNED',
          value: data.ownershipAnalysisChart[0]['foreignCurrentPercent'] || 0,
          color: '#facc5c',
        },
        {
          text: 'corporate.companySnapshot.OTHER',
          value: data.ownershipAnalysisChart[0]['otherPercentage'] || 0,
          color: '#df5a49',
        },
      ]
    : []

  const dataOwnershipAnalysisChart2 = Object.keys(data.majorShareHolder)
    ? [
        {
          text: 'corporate.companySnapshot.MAJOR_SHAREHOLDER_OWNERSHIP1',
          value: data.majorShareHolder.majorPercent,
          color: '#45b29d',
        },
        {
          text: 'corporate.companySnapshot.DIRECTOR_AND_RELATED_PERSON_OWNERSHIP1',
          value: data.majorShareHolder.directorPercent,
          color: '#facc5c',
        },
        {
          text: 'corporate.companySnapshot.OTHER',
          value: data.majorShareHolder.otherPercent,
          color: '#df5a49',
        },
      ]
    : []

  const getFooter = () => {
    let result = { [dataTopInfo.ticker || dataTopInfo.taxCode]: '#97ba53' }
    const color = ['#facc5c', '#df5a49', '#487eb9', '#0096eb']
    popupPeerAnalysis.forEach(
      (element, index) =>
        (result = { ...result, [element.ticker]: color[index] }),
    )
    return result
  }

  useEffect(() => {
    const array = [
      setting.peerAnalysis,
      setting.financialData,
      setting.yourCommentaryOnEarnings,
      setting.ownershipAnalysis,
    ]
    setPositionTitle(array.findIndex((e) => e === true))
  }, [
    setting.peerAnalysis,
    setting.financialData,
    setting.yourCommentaryOnEarnings,
    setting.ownershipAnalysis,
  ])

  return (
    <>
      {setting.peerAnalysis && (
        <div>
          {positionTitle === objMapPositionTitle.peerAnalysis && showPreview && (
            <div className={style.textBackgroundBlue}>
              <Translate value="corporate.companySnapshot.PEER_ANALYSIS_AND_FINANCIAL_DATA" />
            </div>
          )}
          <div
            className={`d-flex ${style.paddingTop8}`}
            style={{ paddingBottom: 24 }}
          >
            <div className={style.wGroup50l}>
              <div className={style.titleGroup}>
                <Translate value="corporate.companySnapshot.PRICE_PERFORMANCE_CHART" />
              </div>
              <div style={{ height: 250 }}>
                {data.pricePerformanceChart.length ? (
                  <LineChart
                    data={data.pricePerformanceChart}
                    width={350}
                    height={250}
                    fillColor={getFooter()}
                    leftKeys={[
                      ...popupPeerAnalysis.map((i) => i.ticker),
                      dataTopInfo.ticker || dataTopInfo.taxCode,
                    ]}
                    xAxisKey="tradingDateId"
                    leftLabel="(%)"
                    margin={{ right: 25 }}
                  />
                ) : null}
              </div>
            </div>
            <div className={style.wGroup50r}>
              {data.pricePerformanceTable.groupColumns.length ? (
                <>
                  <div
                    className={style.titleGroup}
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Translate value="corporate.companySnapshot.KEY_FINANCIAL_DATA" />
                    <div
                      style={{
                        fontSize: 10,
                        fontStyle: 'italic',
                        fontWeight: 300,
                      }}
                    >
                      {typeof data.pricePerformanceTable.titleChart === 'number'
                        ? I18n.t('corporate.companySnapshot.YEAR1') +
                          data.pricePerformanceTable.titleChart
                        : data.pricePerformanceTable.titleChart}
                    </div>
                  </div>
                  <KeyFinancialDataTable />
                </>
              ) : null}
            </div>
          </div>
        </div>
      )}
      {setting.financialData && (
        <>
          {positionTitle === objMapPositionTitle.financialData && showPreview && (
            <div className={style.textBackgroundBlue}>
              <Translate value="corporate.companySnapshot.PEER_ANALYSIS_AND_FINANCIAL_DATA" />
            </div>
          )}

          {data.financialData.groupColumns.length ? (
            <>
              <div className={style.titleGroup}>
                <Translate value="corporate.companySnapshot.FINANCIAL_DATA" />
              </div>
              <FinancialDataTable />
            </>
          ) : null}
        </>
      )}
      {setting.yourCommentaryOnEarnings && commentaryOnEarning.trim() ? (
        <SizeComponent>
          {positionTitle === objMapPositionTitle.yourCommentaryOnEarnings &&
            showPreview && (
              <div className={style.textBackgroundBlue}>
                <Translate value="corporate.companySnapshot.PEER_ANALYSIS_AND_FINANCIAL_DATA" />
              </div>
            )}
          <div className={style.textMemorize}>
            <span style={{ fontSize: 16.5, marginLeft: 8 }}>
              <Translate value="corporate.companySnapshot.YOUR_COMMENTARY_ON_EARNINGS" />
            </span>
            <p>{commentaryOnEarning || ''}</p>
          </div>
          <div className={style.textNote} style={{ marginBottom: 24 }}>
            <Translate value="corporate.companySnapshot.YOUR_COMMENTARY_ON_EARNINGS_NOTE1" />
            {data.userName.email}
            <Translate value="corporate.companySnapshot.YOUR_COMMENTARY_ON_EARNINGS_NOTE2" />
          </div>
        </SizeComponent>
      ) : null}
      {setting.ownershipAnalysis && (
        <>
          {positionTitle === objMapPositionTitle.ownershipAnalysis &&
            showPreview && (
              <div className={style.textBackgroundBlue}>
                <Translate value="corporate.companySnapshot.PEER_ANALYSIS_AND_FINANCIAL_DATA" />
              </div>
            )}
          <div className={`d-flex ${style.paddingTop8}`}>
            <div className={style.wGroup65}>
              {Object.values(data.majorShareHolder).some((i) => i) && (
                <LatestOwnershipStructureTable />
              )}
            </div>
            <div className={style.wGroup35}>
              {dataOwnershipAnalysisChart2
                .map((item) => item.value)
                .some((i) => i) ? (
                <div
                  className="position-relative h-100"
                  style={{ minHeight: 300 }}
                >
                  <div className={style.titleHeaderChart}>
                    <Translate
                      value={'corporate.companySnapshot.OWNERSHIP_ANALYSIS'}
                    />
                  </div>
                  <PieChartWithFooter
                    data={dataOwnershipAnalysisChart2}
                    height={300}
                    themeDownloadPDF
                    footerProps={{
                      isOneColumn: true,
                    }}
                    pieChartProps={{ radius: 35 }}
                    labelStyle={{ fontSize: 4 }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={`d-flex ${style.paddingTop8}`}
            style={{ paddingBottom: 24 }}
          >
            <div className={style.wGroup65}>
              {data.top5MajorShareHolder.ids.length ? (
                <>
                  <div className={style.titleGroup}>
                    <Translate value="corporate.companySnapshot.TABLE_TOP_5" />
                  </div>
                  <Top5MajorShareHolderTable />
                </>
              ) : null}
            </div>
            <div className={style.wGroup35}>
              <div className="position-relative h-100">
                {dataOwnershipAnalysisChart
                  .map((item) => item.value)
                  .some((i) => i) && (
                  <PieChartWithFooter
                    data={dataOwnershipAnalysisChart}
                    height={300}
                    themeDownloadPDF
                    footerProps={{
                      isOneColumn: true,
                    }}
                    pieChartProps={{ radius: 35 }}
                    labelStyle={{ fontSize: 4 }}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default PeerAnalysisAndFinancialData
