import { titleData } from '../../common/helper'

export const convertData = (data, timeFrequency) => {
  let objList = {}
  const result = []
  data.forEach(
    (item) =>
      (objList[titleData(item.month, item.year, timeFrequency)] = {
        ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
        [item.vsicId]: item.value,
      }),
  )

  Object.keys(objList).map((key) =>
    result.push({
      name: key,
      ...objList[key],
    }),
  )
  return result
}

export const getIndicator = (data) => {
  let objList = {}
  const result = []
  data.forEach(
    (item) =>
      (objList[item.vsicName] = {
        ...(objList[item.vsicName] || {}),
        index: item.vsicId,
        key: item.vsicId,
      }),
  )
  Object.keys(objList).map((key) =>
    result.push({
      title: key,
      name: key,
      ...objList[key],
    }),
  )
  return result
}
