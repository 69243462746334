import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { SEARCH_TIME_FRAME } from '../constants'
import { convertTimeFrame, formatDataChart } from '../helper'
import { ChartComponent } from './ChartComponent'
import NetValueBreakdownDropdown from './NetValueBreakdownDropdown'
import {
  changeStateTimeFrame,
  selectFilter,
  selectLoading,
  selectStateTimeFrame,
  selectValueNetBreakDown,
} from './store/slice'
import { getValueNetBreakDown } from './store/thunk'
import style from './style.module.css'

const NetValueBreakdown = ({ width, height }) => {
  const dispatch = useDispatch()
  const timezone = UseTimeZone()

  const changeStateTimeFrameSearch = (data) => {
    dispatch(changeStateTimeFrame(data.value))
  }

  const locale = useSelector((state) => state.i18n.locale)
  const showLoading = useSelector(selectLoading)
  const timeFrame = useSelector(selectStateTimeFrame)
  const isInstitution = useSelector(selectFilter)
  const data = useSelector(selectValueNetBreakDown)
  const basicInfo = useSelector(selectIndexInfo)

  const [dataFormat, setDataFormat] = useState(data)

  useEffect(() => {
    setDataFormat(
      formatDataChart(
        data,
        ['buy', 'sell', 'net'],
        timeFrame,
        locale,
        'net',
        true,
        timezone,
      ),
    )
  }, [data, locale, timezone])

  useEffect(() => {
    if (basicInfo.groupId) {
      dispatch(
        getValueNetBreakDown({
          GroupId: basicInfo.groupId,
          Period: convertTimeFrame(timeFrame),
          IsInstitution: isInstitution,
        }),
      )
    }
  }, [timeFrame, isInstitution, basicInfo.groupId])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex justify-content-space-between">
                <NetValueBreakdownDropdown />
                <div style={{ width: 'auto' }}>
                  <DispatchActionTab
                    data={Object.keys(SEARCH_TIME_FRAME).map((key) => {
                      return {
                        title: I18n.t(
                          `market.marketInDepth.directorDeal.${key}`,
                        ),
                        value: SEARCH_TIME_FRAME[key],
                      }
                    })}
                    activeTab={timeFrame}
                    onChangeTab={changeStateTimeFrameSearch}
                  />
                </div>
              </div>
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {dataFormat.length ? (
                        <SizeTracker>
                          {(size2) => {
                            return (
                              <div
                                id="netValueBreakdown"
                                className="position-relative"
                              >
                                {size.height && dataFormat.length !== 0 && (
                                  <ChartComponent
                                    data={dataFormat}
                                    width={width}
                                    height={height - size2.height - size.height}
                                    keyXAxis={'XAxis'}
                                    timeFrame={timeFrame}
                                    lineKey={'net'}
                                    barKeys={['buy', 'sell']}
                                  />
                                )}
                                <Footer
                                  key={width}
                                  list={[
                                    {
                                      text: 'market.marketInDepth.directorDeal.BUY',
                                      type: TYPE.SQUARE,
                                      before: {
                                        bgColor: '#3da967',
                                      },
                                    },
                                    {
                                      text: 'market.marketInDepth.directorDeal.SELL',
                                      type: TYPE.SQUARE,
                                      before: {
                                        bgColor: '#e0505b',
                                      },
                                    },
                                    {
                                      text: 'market.marketInDepth.directorDeal.NET',
                                      type: TYPE.CIRCLE_MARKER_LINE,
                                      before: {
                                        bgColor: '#f7b80c',
                                      },
                                      after: {
                                        bgColor: '#f7b80c',
                                      },
                                    },
                                  ]}
                                />
                              </div>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}

              <div className={style.labelFooterChart}>
                <Span style={{ fontSize: 11 }}>
                  <TextEllipsis
                    isI18n={true}
                    text={'market.marketInDepth.directorDeal.LB_FOOTER_CHART'}
                  />
                </Span>
              </div>
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default NetValueBreakdown
