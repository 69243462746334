import copy from 'copy-to-clipboard'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../../../common/dropdown'
import { Span } from '../../../../../common/html/Span'
import style from './index.module.css'

const CoppyLink = ({ item }) => {
  const copyLinkRef = useRef()
  const [isShow, setIsShow] = useState(false)
  const ref = useRef({})

  const clearTimeOut = () => {
    if (ref.current.timeOut) {
      ref.current.timeOut = null
      clearTimeout(ref.current.timeOut)
    }
  }

  useEffect(() => {
    return () => clearTimeOut()
  })

  const handleCoppyLink = () => {
    copy(
      window.location.origin +
        window.location.pathname +
        '?shareId=' +
        item.shareId,
    )
    setIsShow(true)
    clearTimeOut()
    ref.current.timeOut = setTimeout(() => {
      setIsShow(false)
    }, 1000)
  }

  return (
    <div onClick={handleCoppyLink}>
      <button ref={copyLinkRef} className="cursor-pointer">
        <i
          className="icon-coppy-link"
          style={{ display: 'inline-block', width: '8px' }}
        />
        <Translate value="sector.sectorConstituents.segmentation.COPPY_LINK" />
      </button>
      {isShow && (
        <Dropdown
          position={positionDropdown.TOP_CENTER}
          parentRef={copyLinkRef}
        >
          <div className={style.tooltipContentInner}>
            <Span style={{ fontSize: 9 }}>
              <Translate value="common.shareButton.COPIED_TO_CLIPBOARD" />
            </Span>
            <div className={style.triangle} />
          </div>
        </Dropdown>
      )}
    </div>
  )
}

export default CoppyLink
