import {
  getThreeColorByValue,
  getTwoColorByValue,
} from '../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { LinkCWDetail } from '../../common/LinkCWDetail'
import {
  GROUP_COLUMNS_DETAIL,
  GROUP_COLUMNS_OVERVIEW,
  TABLE_TYPE,
} from './constants'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
      result.schema = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val) => formatVal(val / format),
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (item.key === 'netValue' || item.key === 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(val / format, count)}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(val, 0)}
              </span>
            ),
          }
        }
        return result
      })
      break
    case TABLE_TYPE.DETAIL:
      let schemaDetail = GROUP_COLUMNS_DETAIL.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format
        const count = item.count
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val) => formatVal(val / format),
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val, rowId) => {
              const organizationId = dataTable[rowId]?.FutureId || 0
              const activeId = dataTable[rowId]?.activeStatusId || 0
              return activeId !== 1 ? (
                val
              ) : (
                <LinkCWDetail organizationId={organizationId} val={val} />
              )
            },
          }
        }

        if (item.key === 'netValue' || item.key === 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(val / format, count)}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(val, 0)}
              </span>
            ),
          }
        }
        return result
      })
      result.schema = schemaDetail
      break
    case TABLE_TYPE.TICKER:
      let schemaTicker = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        const title = item.title
        const colId = item.key
        const format = item.format

        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val) => formatVal(val / format),
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (item.key === 'netValue' || item.key === 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(val, 0)}
              </span>
            ),
          }
        }
        return result
      })
      result.schema = schemaTicker
      break
    default:
  }

  return result
}
