import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { PERIOD_TYPES, TABLE_TABS } from '../constants'
import { getSortFieldDefaultByTableTab } from '../helpers'
import { getListTickersSelectThunk, getTableDataThunk } from './thunk'

const defaultFilter = {
  assetId: null,
  period: PERIOD_TYPES.SELECT_TIME_PERIOD,
  month: '',
  year: '',
  page: 1,
  totalPage: 1,
  sortField: '',
  sortOrder: 1,
}

const initialState = {
  filters: {
    [TABLE_TABS.ALL]: defaultFilter,
    [TABLE_TABS.DETAIL]: defaultFilter,
  },
  tableTab: TABLE_TABS.ALL,
  reCalcWidth: false,
  loading: false,
  assetLoading: false,
  data: [],
  ids: [],
  initialIds: [],
  dataById: {},
  assets: [],
  assetById: {},
}

export const slice = createSlice({
  name: 'fund/fundCenter/holding',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters[state.tableTab] = {
        ...state.filters[state.tableTab],
        ...action.payload,
      }
    },
    changeTableTab: (state, action) => {
      state.tableTab = action.payload
      if (!state.filters[state.tableTab].sortField) {
        state.filters[state.tableTab] = {
          ...state.filters[state.tableTab],
          sortField: getSortFieldDefaultByTableTab(
            action.payload,
            state.valueType,
          ),
        }
      }
    },
    changeReCalcWidth: (state) => {
      state.reCalcWidth = !state.reCalcWidth
    },
    sortTableData: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    // Get asset ticker select
    builder.addCase(getListTickersSelectThunk.pending, (state) => {
      state.assetLoading = true
    })
    builder.addCase(getListTickersSelectThunk.fulfilled, (state, action) => {
      const data = action.payload
        .map((item) => ({
          ticker: item.ticker,
          name: item.organizationShortName,
          value: item.organizationId,
        }))
        .sort((a, b) => a.ticker.localeCompare(b.ticker))
      state.assets = data
      state.assetById = keyBy(data, 'value')
      state.assetLoading = false
    })
    builder.addCase(getListTickersSelectThunk.rejected, (state, action) => {
      state.assetLoading = action.payload.loading
    })
    // Get table data
    builder.addCase(getTableDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTableDataThunk.fulfilled, (state, action) => {
      const data = action.payload.data.map((item, index) => ({
        ...item,
        id: index,
      }))
      state.data = data
      state.ids = state.initialIds = data.map((e) => e.id)
      state.dataById = keyBy(data, 'id')
      state.filters[state.tableTab].totalPage = action.payload.totalPage
      state.loading = false
      state.reCalcWidth = !state.reCalcWidth
    })
    builder.addCase(getTableDataThunk.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectTableTab = (state) => state[slice.name].tableTab
export const selectFilters = (state) => {
  const tableTab = state[slice.name].tableTab
  return state[slice.name].filters[tableTab]
}
export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectDataById = (state) => state[slice.name].dataById
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectReCalcWidth = (state) => state[slice.name].reCalcWidth
export const selectAssets = (state) => state[slice.name].assets
export const selectAssetById = (state) => state[slice.name].assetById

export const { changeFilter, changeTableTab, sortTableData } = slice.actions

register(slice.name, slice.reducer)
