import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { chartBank1, chartBank2 } from './thunk'

const initialState = {
  chart1Loading: true,
  chart2Loading: true,
  chart1Data: [],
  chart2Data: [],
}

const slice = createSlice({
  name: 'corporate/businessModel/segmentalAnalysis/charts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(chartBank1.pending, (state) => {
      state.chart1Loading = true
    })
    builder.addCase(chartBank1.fulfilled, (state, action) => {
      state.chart1Loading = false
      state.chart1Data = action.payload || []
    })
    builder.addCase(chartBank1.rejected, (state, action) => {
      state.chart1Loading = action.payload.isLoading
    })
    builder.addCase(chartBank2.pending, (state) => {
      state.chart2Loading = true
    })
    builder.addCase(chartBank2.fulfilled, (state, action) => {
      state.chart2Loading = false
      state.chart2Data = action.payload || []
    })
    builder.addCase(chartBank2.rejected, (state, action) => {
      state.chart2Loading = action.payload.isLoading
    })
  },
})

export const selectChart1Loading = (state) => state[slice.name].chart1Loading
export const selectChart2Loading = (state) => state[slice.name].chart2Loading
export const selectChart1Data = (state) => state[slice.name].chart1Data
export const selectChart2Data = (state) => state[slice.name].chart2Data

register(slice.name, slice.reducer)
