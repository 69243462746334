import moment from 'moment'
import { getFirstDayOfMonth, getFirstDayOfYear } from './PanelPrice'
import { MARKET_STATISTIC } from './constants'

export const formatDataTable = ({ data, type }) =>
  data.map((item) => ({
    ...item,
    bidPrice:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.best1Bid : undefined,
    bidPrice2: item?.best2Bid,
    bidPrice3: item?.best3Bid,
    bidYtm:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.bid1YTM : undefined,
    bidYtm2: item?.bid2YTM,
    bidYtm3: item?.bid3YTM,
    bidVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.best1BidVolume
        : undefined,
    bidVolume2: item?.best2BidVolume,
    bidVolume3: item?.best3BidVolume,
    matchedPrice:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchPrice
        : item?.dealPrice,
    ytm: item?.ytm && item?.refYTM ? item?.ytm - item?.refYTM : undefined,
    matchedYtm: item?.ytm,
    matchedVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchVolume
        : item?.dealVolume,
    matchedValue:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchValue
        : item?.dealValue,
    askPrice:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.best1Offer : undefined,
    askPrice2: item?.best2Offer,
    askPrice3: item?.best3Offer,
    askYtm:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.ask1YTM : undefined,
    askYtm2: item?.ask2YTM,
    askYtm3: item?.ask3YTM,
    askVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.best1OfferVolume
        : undefined,
    askVolume2: item?.best2OfferVolume,
    askVolume3: item?.best3OfferVolume,
    highPrice: item?.highestPrice,
    highYtm: item?.highYTM,
    lowPrice: item?.lowestPrice,
    lowYtm: item?.lowYTM,
    avgPrice: item?.averagePrice,
    avgYtm: item?.avgYTM,
    totalMatchedValue: item?.totalMatchValue,
    totalDealVolume: item?.totalDealVolume,
    totalDealValue: item?.totalDealValue,
    name: item.organizationShortName,
    couponValue: item?.interestRate,
    referencePrice: item?.referencePrice,
    id: item.bondId,
    level: 1,
    parentId: item.bondId,
    childIds: [],
  }))

export const START_TIME_HOUR = process.env.REACT_APP_PRICE_BOARD_START_TIME_HOUR
export const START_TIME_MINUTE =
  process.env.REACT_APP_PRICE_BOARD_START_TIME_MINUTE
export const END_TIME_HOUR = process.env.REACT_APP_PRICE_BOARD_END_TIME_HOUR
export const END_TIME_MINUTE = process.env.REACT_APP_PRICE_BOARD_END_TIME_MINUTE

export const getTimeToDay = () => {
  const currentDate = new Date()
  const wtd = new Date()
  const mtd = new Date()
  const qtd = new Date()
  const ytd = new Date()
  const quarter = Math.floor((currentDate.getMonth() + 3) / 3)

  wtd.setDate(currentDate.getDate() - moment().weekday())
  mtd.setDate(
    getFirstDayOfMonth(
      currentDate.getFullYear(),
      currentDate.getMonth(),
    ).getDate(),
  )
  qtd.setDate(1)
  if (quarter === 3) {
    qtd.setMonth(6)
  }
  if (quarter === 1) {
    qtd.setMonth(0)
  }
  if (quarter === 2) {
    qtd.setMonth(3)
  }
  if (quarter === 4) {
    qtd.setMonth(9)
  }
  ytd.setDate(getFirstDayOfYear(currentDate.getFullYear()).getDate())
  ytd.setMonth(getFirstDayOfYear(currentDate.getFullYear()).getMonth())

  return [wtd, ytd, qtd, mtd]
}
