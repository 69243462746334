import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import ThePagination from '../../../common/pagination/ThePagination'
import {
  getNewsData,
  getTotalPage,
  handlePage,
  selectLoadingNews,
} from '../store/slice'
import GroupByMonth from './GroupByMonth'

const Default = ({ isLoadingUpcomingEvent, eventTypeLoading, page }) => {
  const dispatch = useDispatch()

  const isLoadingNews = useSelector(selectLoadingNews)
  const totalPage = useSelector(getTotalPage)
  const newsData = useSelector(getNewsData)

  const handleChangePage = (page) => {
    dispatch(handlePage(page))
  }

  if (isLoadingNews || isLoadingUpcomingEvent || eventTypeLoading) {
    return <Loading />
  }

  if (!Object.keys(newsData).length) {
    return <NoData />
  }

  return (
    <div>
      <ThePagination
        currentPage={page}
        totalPages={totalPage}
        changeCurrentPage={handleChangePage}
      />
      {Object.keys(newsData).map((month, index) => (
        <GroupByMonth
          key={month}
          month={month}
          rowsByMonth={newsData[month]}
          isLast={index === Object.keys(newsData).length - 1}
        />
      ))}
    </div>
  )
}

Default.propTypes = {
  isLoadingUpcomingEvent: PropTypes.bool,
  eventTypeLoading: PropTypes.bool,
  page: PropTypes.number,
}

export default Default
