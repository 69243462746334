import SecuritiesExchangeCount from './SecuritiesExchangeCount'
import SecuritiesSearch from './SecuritiesSearch'
import SecuritiesSectorCount from './SecuritiesSectorCount'
import SecuritiesSelect from './SecuritiesSelect'

const ListSecurities = ({ isEquationTypeCount, isCheckAll }) => {
  if (isEquationTypeCount) {
    return (
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ marginTop: 20 }}
      >
        <SecuritiesExchangeCount />
        <SecuritiesSectorCount isCheckAll={isCheckAll} />
      </div>
    )
  }

  return (
    <div style={{ marginTop: 20 }}>
      <SecuritiesSearch />
      <SecuritiesSelect />
    </div>
  )
}

export default ListSecurities
