import PropTypes from 'prop-types'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import style from './index.module.css'

const FirstColumnCell = ({ title, level, props, isLastRow }) => {
  return (
    <td
      {...props}
      className={`${style.borderDefault} ${isLastRow && style.lastRow}`}
    >
      <div
        className={`${level === 2 && style.tableHeadHorizontalLevel2}`}
        style={{ position: 'relative' }}
      >
        <TextEllipsisStaticLine val={title} isI18n={false} />
        {isLastRow && <div className={style.borderTop} style={{ top: -5 }} />}
      </div>
    </td>
  )
}

FirstColumnCell.propTypes = {
  title: PropTypes.string,
  level: PropTypes.number,
  props: PropTypes.object,
  isLastRow: PropTypes.bool,
}

export default FirstColumnCell
