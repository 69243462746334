import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import { formatVal } from '../../../../../utils/Value'
import { GROUP_ROWS } from '../constants'
import { getRowSpanSchema } from '../helper'
import {
  keys,
  selectDataTableById,
  selectLoading,
  selectSectorStatisticData,
  setRowsCollapse,
  sort,
} from '../store/slice'
import FirstCellColumn from './FirstCellColumn'
import TableHeader from './TableHeader'

const TableContent = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading(keys.SECTOR_STATISTIC))
  const { data, ids, rowsCollapse } = useSelector(selectSectorStatisticData)

  const onRowCollapse = (rowId, isCollapse) => {
    const index = rowsCollapse.indexOf(rowId)

    if (isCollapse && index === -1) {
      dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      return
    }

    dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
  }

  const levelCollapse = useMemo(
    () =>
      keyBy(
        data?.map((item) => ({
          id: item.id,
          level: item.type,
          isCollapsible: !item?.childrenIds?.length ?? false,
        })),
        'id',
      ),
    [data],
  )

  return (
    <Table
      ids={ids}
      columnDraggable={false}
      sort={sort}
      getDataFromRedux={selectDataTableById}
      rowDraggable={false}
      isLoading={isLoading}
      rowSpanSchema={getRowSpanSchema()}
      renderHeader={(stateSort, sortColumn) => (
        <TableHeader
          tableHeaderColAttr={GROUP_ROWS}
          stateSort={stateSort}
          sortColumn={sortColumn}
        />
      )}
      defaultSort={{ totalValue: 'desc' }}
      schema={GROUP_ROWS.map((item, index) => {
        const title = I18n.t(item.title)
        const colId = item.key
        const result = {
          colId,
          title,
        }

        if (index === 0) {
          return {
            ...result,
            tdStyle: {
              minWidth: 100,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              const tableHorizontalHead = data.find((item) => item.id === rowId)

              return (
                <FirstCellColumn
                  props={props}
                  val={tableHorizontalHead?.name ?? ''}
                  level={tableHorizontalHead?.level ?? 1}
                  childrenIds={tableHorizontalHead?.childIds?.length}
                  rowId={rowId}
                  onRowCollapse={onRowCollapse}
                  isRowCollapse={rowsCollapse.includes(rowId)}
                />
              )
            },
          }
        }

        switch (index) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            return {
              ...result,
              tdStyle: {
                minWidth: 100,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{(value = formatVal(value, 2))}</Span>
                </td>
              ),
            }
          default:
            return {}
        }
      })}
      stickyFirstColumn={true}
      isPagination={false}
      hasFooter={false}
      resizable={false}
      isCollapse={true}
      levelCollapse={levelCollapse}
      rowsCollapse={rowsCollapse}
      hasTooltip={false}
      isLargeHead={true}
    />
  )
}

export default TableContent
