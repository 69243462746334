import { METRIC_CODE } from './constant'

export const formatDataDetailMetrics = (payload) => {
  const data = {}
  const tickers = []

  Object.values(METRIC_CODE).forEach((key) => (data[key] = {}))
  payload.forEach((item) => {
    if (!tickers.includes(item.ticker)) tickers.push(item.ticker)
    if (data[item.metricCode]) {
      data[item.metricCode][item.ticker] = item.value
    }
  })

  return { tickers, data }
}
