import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import { tableHeadTab } from '../constants'
import { getByInvestor } from './thunk'

const slice = createSlice({
  name: 'market/equityTrading/priceStatistics/summary/statisticInvest',
  initialState: {
    ids: [],
    initialIds: [],
    data: [],
    dataByIds: {},
    filter: {
      Page: 1,
      PageSize: 22,
      TimeFilter: PRICE_DATA_TIME_SELECT.DAILY,
      SortField: 'tradingdateId',
      SortOrder: 1,
    },
    dataDateRange: {
      startDate: '',
      endDate: '',
      minDate: '',
      minYear: '',
      maxDate: '',
      maxYear: '',
    },
    loading: false,
    totalPage: 0,
    reCalcWidths: false,
    thTab: tableHeadTab.LOCAL_INDIVIDUAL,
  },
  reducers: {
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataByIds,
        action.payload,
        state.initialIds,
      )
    },
    changeFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    resetData: (state) => {
      state.ids = []
      state.data = []
      state.dataByIds = {}
      state.initialIds = []
      state.totalPage = 0
      state.isLoading = false
      state.dataDateRange = {
        startDate: '',
        endDate: '',
        minDate: '',
        minYear: '',
        maxDate: '',
        maxYear: '',
      }
    },
    changeThTab: (state, action) => {
      state.thTab = action.payload
    },
    changeReCalcWidth: (state) => {
      state.reCalcWidths = !state.reCalcWidths
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getByInvestor.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getByInvestor.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload.data
      state.ids = action.payload.data.map((item) => item.tradingdateId)
      state.initialIds = action.payload.data.map((item) => item.tradingdateId)
      state.dataByIds = keyBy(action.payload.data, 'tradingdateId')
      state.totalPage = action.payload.totalPage
      state.reCalcWidths = !state.reCalcWidths

      if (action.payload.data.length) {
        state.dataDateRange = {
          ...state.dataDateRange,
          minDate: action.payload.data[0].minDate,
          minYear: new Date(action.payload.data[0].minDate).getFullYear(),
          maxDate: action.payload.data[0].maxDate,
          maxYear: new Date(action.payload.data[0].maxDate).getFullYear(),
        }
      }

      if (
        !state.dataDateRange.startDate &&
        (!state.filter.StartDate || !state.filter.EndDate) &&
        action.payload.data.length
      ) {
        state.dataDateRange = {
          ...state.dataDateRange,
          startDate: action.payload.data[0].startDate || '',
          endDate: action.payload.data[0].endDate || '',
        }
      }
    })
    builder.addCase(getByInvestor.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const { sort, changeFilter, resetData, changeThTab, changeReCalcWidth } =
  slice.actions
export const selectDataStatisticsInvest = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectFilter = (state) => state[slice.name].filter
export const selectLoading = (state) => state[slice.name].loading
export const selectTotalPage = (state) => state[slice.name].totalPage
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectDataDateRange = (state) => state[slice.name].dataDateRange
export const selectThTab = (state) => state[slice.name].thTab

register(slice.name, slice.reducer)
