import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableDataExplorer from '../../../common/dataExplorer/table'
import { pageSize } from '../../../common/dataExplorer/table/constant'
import { TableSettings } from './TableSettings'
import ThTable from './ThTable'
import { getRowSpanSchema, getTableHeadRowTop } from './helps'
import {
  changeDataDisplay,
  selectCellInfos,
  selectColumnByIdCell,
  selectColumnId,
  selectDataDisplay,
  selectFullColumnIds,
  selectFullDataDisplay,
  selectIds,
  selectLoading,
  selectTableHeaderAttr,
  selectValueById,
} from './store/slice'
import styles from './style.module.css'

const FinancialChartTable = ({
  data,
  fullDataByTimeDataKey,
  schema,
  viewType,
  treeSecuritiesIndicator,
  equations,
  listLegendCustom,
}) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const loading = useSelector(selectLoading)
  const tableHeaderAttr = useSelector(selectTableHeaderAttr)
  const cellInfos = useSelector(selectCellInfos)

  // Get data
  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [treeSecuritiesIndicator, equations, data, locale])

  // Actions
  const changeIdDisplay = (ids) => dispatch(changeDataDisplay(ids))

  return (
    <div className="h-100">
      <TableDataExplorer
        key={cellInfos}
        isLoading={Object.values(loading).some((val) => val)}
        maxColumn={250}
        selectColumnId={selectColumnId}
        selectColumnCell={selectColumnByIdCell}
        maxRow={pageSize}
        selectTickerCell={selectValueById}
        selectId={selectDataDisplay}
        selectFullId={selectIds}
        selectFullColumnId={selectFullColumnIds}
        changeIdDisplay={changeIdDisplay}
        selectIdDisplay={selectFullDataDisplay}
        cellInfo={cellInfos}
        renderTh={(_, colId, column) => (
          <ThTable
            colId={colId}
            thData={column}
            listLegendCustom={listLegendCustom}
          />
        )}
        stateResetDefaultPage={getKey}
        tableThRowSpan={getRowSpanSchema(tableHeaderAttr)}
        tableThTop={getTableHeadRowTop(tableHeaderAttr)}
        isSwap={false}
        isSort={false}
        customClassName={{
          table: styles.table,
          thSticky: styles.thSticky,
          columnThSticky: styles.columnThSticky,
          columnSticky: styles.columnSticky,
          td: styles.td,
          tr: styles.tr,
        }}
        scrollbarGray
      />
      <TableSettings
        data={data}
        fullDataByTimeDataKey={fullDataByTimeDataKey}
        schema={schema}
        viewType={viewType}
        treeSecuritiesIndicator={treeSecuritiesIndicator}
        equations={equations}
      />
    </div>
  )
}

export default FinancialChartTable
