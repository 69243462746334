import { createAsyncThunk } from '@reduxjs/toolkit'
import AdvancedSearchService from '../../../core/services/advanceSearch/AdvancedSearchService'

export const getFullSearchData = createAsyncThunk(
  'advancedSearch/GET_FULL_SEARCH',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getFullSearchData(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getSearchCompanyData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_COMPANY',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchCompanyData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchSectorData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_SECTOR',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchSectorData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchIndicesData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_INDICES',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchIndicesData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchBondData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_BOND',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchBondData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchDerivativesData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_DERIVATIVES',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchDerivativesData(
      params,
    )
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchFunctionData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_FUNCTION',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchFunctionData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchCorNewsData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_COR_NEWS',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchCorNewsData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchSecNewsData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_SEC_NEWS',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchSecNewsData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)

export const getSearchMarNewsData = createAsyncThunk(
  'advancedSearch/GET_SEARCH_MAR_NEWS',
  async (params, { rejectWithValue }) => {
    const response = await AdvancedSearchService.getSearchMarNewsData(params)
    if (response.success) {
      return {
        page: response.page,
        pageSize: response.pageSize,
        totalRecords: response.totalRecords,
        totalPage: response.totalPage,
        hasNextPage: response.hasNextPage,
        hasPreviousPage: response.hasPreviousPage,
        items: response.data,
      }
    }
    return rejectWithValue('Error')
  },
)
