import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import SelectCustom from '../../../../../common/selectCustom'
import { CURRENCY, LASTEST_YEAR, QUARTERS, UNIT } from '../constant'
import { changeFilter, selectFilter } from '../store/slice'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)

  const handleChangeQuarter = (quarter) => dispatch(changeFilter({ quarter }))
  const handleChangeYear = (year) => dispatch(changeFilter({ year }))
  const handeChangeCurrency = (currency) =>
    dispatch(changeFilter({ currency, unit: UNIT[currency][0].value }))
  const handleChangeUnit = (unit) => dispatch(changeFilter({ unit }))

  return (
    <div className={style.filter}>
      <div className={style.select}>
        <SelectCustom
          isI18n={true}
          selectData={QUARTERS}
          value={filter.quarter}
          handleChange={handleChangeQuarter}
        />
      </div>

      <div className={style.select}>
        <SelectCustom
          isI18n={true}
          selectData={LASTEST_YEAR}
          value={filter.year}
          handleChange={handleChangeYear}
        />
      </div>

      <div className={style.verticalLine} />

      <div className="mr-8">
        <ul className="list-check d-flex align-center">
          {CURRENCY.map((item, index) => (
            <li key={item.value}>
              <label>
                <div>
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    value={item.value}
                    readOnly
                    checked={item.value === filter.currency}
                    onClick={(e) => handeChangeCurrency(item.value)}
                  />
                </div>
                <span
                  style={{
                    color: item.value === filter.currency ? '#2f9aee' : '',
                  }}
                >
                  <Translate value={item.name} />
                </span>
              </label>
            </li>
          ))}
        </ul>
      </div>

      <div className={`${style.select} ml-8`}>
        <SelectCustom
          isI18n={true}
          selectData={UNIT[filter.currency]}
          value={filter.unit}
          handleChange={handleChangeUnit}
        />
      </div>
    </div>
  )
}

export default Filter
