import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import UseFontSize from '../hooks/useFontSize'
import { Cell } from './Cell'
import { textDragRow } from './constants'
import style from './index.module.css'

let dragIndex = null

export const Row = ({
  schema,
  rowId,
  setRowRefs,
  getDataFromRedux,
  rowDraggable,
  reorder,
  rowIndex,
  changeActiveRow,
  defaultActiveRowId,
  disableClickGroups,
  isCollapse,
  rowLevelCollapse,
  onRowCollapse,
  onRowDoubleClick,
  onRowClick,
  widths,
  stickies,
  stickyFirstColumn,
  isHighlightLine,
  stickyBottom,
  disableRowHovered,
  rowCustomStyle,
  stickyBgColor,
  tableId,
  multipleActiveRowsIds,
  stickyBottomRLeft,
}) => {
  const ref = useRef()
  const fontSize = UseFontSize()

  useEffect(() => {
    if (ref.current && (stickyBottom || stickyBottom === 0)) {
      setRowRefs((prev) => ({
        ...prev,
        [rowId]: { height: ref.current.offsetHeight },
      }))
    }
  }, [ref.current?.offsetHeight, fontSize])

  const onDragStart = (index) => (event) => {
    event.dataTransfer.setData('text', textDragRow + tableId)
    dragIndex = index
  }

  const onDragEnd = () => {
    dragIndex = null
  }

  const onDragOver = (event) => {
    event.preventDefault()
  }

  const onDrop = (index) => (event) => {
    event.preventDefault()
    if (event.dataTransfer.getData('text') === textDragRow + tableId) {
      if (index !== dragIndex) {
        reorder(dragIndex, index)
        dragIndex = null
      }
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (ref.current && defaultActiveRowId === rowId) {
      const tbody = ref.current.closest('tbody')
      tbody.querySelectorAll('td').forEach((tdDom) => {
        tdDom.classList.remove(style.active)
      })
      ref.current.querySelectorAll('td').forEach((tdDom) => {
        tdDom.classList.add(style.active)
      })
    }
  }, [defaultActiveRowId])

  const onClick = (e) => {
    if (onRowClick) {
      onRowClick(rowId)
    }

    if (changeActiveRow && !disableClickGroups.includes(rowId)) {
      const tr = e.target.closest('tr')
      const tbody = e.target.closest('tbody')

      tbody.querySelectorAll('td').forEach((tdDom) => {
        tdDom.classList.remove(style.active)
      })

      if (tr) {
        tr.querySelectorAll('td').forEach((tdDom) => {
          tdDom.classList.add(style.active)
        })

        dispatch(changeActiveRow(rowId))
      }
    }
  }

  const handleDoubleClick = () => {
    if (onRowDoubleClick) {
      onRowDoubleClick(rowId)
    }
  }

  const getClassName = () => {
    let className = [style.rowHover]

    if (isHighlightLine) {
      className.push(style.highlightBorderBottom)
    }

    if (disableRowHovered[rowId]) {
      className.push(style.disableRowHover)
    }

    return className.join(' ')
  }

  if (Array.isArray(multipleActiveRowsIds)) {
    const tr = document.getElementById(rowId)

    if (multipleActiveRowsIds.includes(rowId) && tr) {
      tr.querySelectorAll('td').forEach((tdDom) => {
        tdDom.classList.add(style.active)
      })
    } else {
      tr &&
        tr.querySelectorAll('td').forEach((tdDom) => {
          tdDom.classList.remove(style.active)
        })
    }
  }

  return (
    <tr
      ref={ref}
      id={rowId}
      style={rowCustomStyle}
      className={getClassName()}
      draggable={rowDraggable && !(stickyBottom || stickyBottom === 0)}
      onDragStart={onDragStart(rowIndex)}
      onDragEnd={onDragEnd}
      onDragOver={onDragOver}
      onDrop={onDrop(rowIndex)}
      onClick={onClick}
      onDoubleClick={handleDoubleClick}
    >
      {schema.map(({ colId, render, tdStyle, canCustomTd }, index) => {
        return (
          <Cell
            key={colId}
            getDataFromRedux={getDataFromRedux}
            rowId={rowId}
            colId={colId}
            render={render}
            tdStyle={tdStyle}
            canCustomTd={canCustomTd}
            isActive={rowId === defaultActiveRowId}
            isCollapse={isCollapse}
            isShowCollapseIcon={rowLevelCollapse.isRowCollapse && index === 0}
            onRowCollapse={onRowCollapse}
            widths={widths}
            sticky={!!stickies[colId] || (stickyFirstColumn && index === 0)}
            colIndex={index}
            schema={schema}
            stickyBottom={stickyBottom}
            stickyBgColor={stickyBgColor}
            rowIndex={rowIndex}
            stickyBottomRLeft={stickyBottomRLeft}
          />
        )
      })}
    </tr>
  )
}

Row.propTypes = {
  schema: PropTypes.array.isRequired,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  setRowRefs: PropTypes.func.isRequired,
  getDataFromRedux: PropTypes.func.isRequired,
  rowDraggable: PropTypes.bool,
  reorder: PropTypes.func,
  rowIndex: PropTypes.number.isRequired,
  changeActiveRow: PropTypes.func,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableClickGroups: PropTypes.array.isRequired,
  isCollapse: PropTypes.bool,
  rowLevelCollapse: PropTypes.object,
  onRowCollapse: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowClick: PropTypes.func,
  isHighlightLine: PropTypes.bool,
  stickyBottom: PropTypes.number,
  disableRowHovered: PropTypes.object,
  rowCustomStyle: PropTypes.object,
  stickyBgColor: PropTypes.string.isRequired,
}

Row.defaultProps = {
  rowDraggable: false,
  reorder: () => {},
  isCollapse: false,
  rowLevelCollapse: {},
  isHighlightLine: false,
  rowCustomStyle: {},
}
