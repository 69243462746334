export const production = {
  PRODUCTION: 'Production',
  CHART_FIRST: {
    generalIndex: 'Growth of production indexes',
    IIP: 'Growth of Industrial Production Index',
    IIC: 'Growth of Consumption index of the whole processing industry',
    III: 'Growth of Inventory index of the whole processing industry',
    PRODUCT: 'Growth of Industrial Products output',
  },
  CHART_SECOND: {
    generalIndex: 'Purchasing Managers Index (PMI)',
    IIP: ' Top 10 sectors of the highest/lowest value',
    IIC: ' Top 10 sectors of the highest/lowest value',
    III: 'Top growth in the period',
    PRODUCT: 'Output of typical industrial products',
  },
  generalIndex: 'Production indexes',
  IIP: 'IIP',
  IIC: 'IIC',
  III: 'III',
  PRODUCT_INDUSTRY: 'Industrial products',

  SAME_PERIOD: 'MoM growth',
  QOQ_GROWTH: 'QoQ growth',
  PREVIOUS_PERIOD: 'YoY growth',
  ORIGINAL_PERIOD: ' % ',

  timeRange: {
    MONTHLY: 'Monthly',
    YEARLY: 'Yearly',
    ACC_MONTHLY: 'Acc.Monthly',
    QUARTERLY: 'Quarterly',
  },

  COMPARE_TO: 'Comparison:',
  ALL_TIME: 'ALL',

  TIME_DATA_TYPE: {
    MONTH_1: 'Jan',
    MONTH_2: '2M',
    MONTH_3: '3M',
    MONTH_4: '4M',
    MONTH_5: '5M',
    MONTH_6: '6M',
    MONTH_7: '7M',
    MONTH_8: '8M',
    MONTH_9: '9M',
    MONTH_10: '10M',
    MONTH_11: '11M',
    MONTH_12: '12M',
  },
  MONTHLY_OPTION: {
    MONTH_1: 'Jan',
    MONTH_2: 'Feb',
    MONTH_3: 'Mar',
    MONTH_4: 'Apr',
    MONTH_5: 'May',
    MONTH_6: 'Jun',
    MONTH_7: 'Jul',
    MONTH_8: 'Aug',
    MONTH_9: 'Sep',
    MONTH_10: 'Oct',
    MONTH_11: 'Nov',
    MONTH_12: 'Dec',
  },
  VALUE: 'Value',
  PERIOD: 'Period',
  PRODUCTION_INDEX: 'Production indexes',
  IIP_TITLE: 'Industrial production index (IIP)',
  IIC_TITLE: 'Consumption index of the whole processing industry (IIC)',
  III_TITLE: 'Inventory index of the whole processing industry (III)',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  ACC_QUARTERLY: 'Acc. Quarterly',
  POINT: '(Point)',
  POINT_TOOLTIP: ' point',
  AVERAGE: 'Average',
  MOM: 'MoM Growth (%)',
}

export const consumption = {
  CONSUMPTION: 'Consumption',
  GROWTH_BY_INDEX: 'Change in Total retail sales',
  GROWTH_BY_YEAR: 'Total retail sales growth over 5 years',
  RETAIL_SERVICE: 'Changes in Retail Sales composition',
  TOTAL_RETAIL_SALE: 'Total retail sales of goods',
  MERCHANDISE_RETAIL: 'Merchandise retail',
  ACCOMMODATION_SERVICE: 'Accommodation and food services',
  TRAVELING_SERVICE: 'Traveling service',
  OTHER_SERVICE: 'Other services',
  CONSUMER_TRUST_INDEX: 'Consumer trust index',
  GROWTH_YOY: 'Growth YoY',
  TABLE_NOTE: 'Unit: Billion VND',
  MONTHLY: 'Monthly',
  ACCUMULATED_MONTH: 'Acc. Monthly',
  YEARLY: ' Yearly',
  CHART_PERCENT: '(%)',
  VALUE: 'Value',
  BILLION_VND: 'Bn.',
  CHART_BILLION: '(Billion VND)',
  OVER_THE_YEAR: 'over the years',
  ALL: 'All',
  ACC_VALUE: 'Acc. Value',
}
