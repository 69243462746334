import PropTypes from 'prop-types'
import { SizeTracker } from '../sizeTracker'
import { Image } from './image'
import { Item as CommonItem } from './item'

const SPACE_BETWEEN_ITEMS = 5

export const ItemWithImage = ({ item, onAction, isItemInLastRow }) => {
  return (
    <div className={`d-flex ${!isItemInLastRow ? 'mb-15' : ''}`}>
      <SizeTracker className="d-flex">
        {(size) => {
          return (
            <>
              <Image url={item.imageUrl} />
              {size.width && (
                <div
                  style={{
                    marginLeft: SPACE_BETWEEN_ITEMS,
                    width: `calc(100% - ${size.width + SPACE_BETWEEN_ITEMS}px)`,
                  }}
                >
                  <CommonItem item={item} onAction={onAction} />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

ItemWithImage.propTypes = {
  item: PropTypes.object.isRequired,
  onAction: PropTypes.func,
  isItemInLastRow: PropTypes.bool,
}

ItemWithImage.defaultProps = {
  onAction: () => {},
  isItemInLastRow: false,
}
