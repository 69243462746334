export const valuation = {
  TITLE: 'Định giá',
  infoSec: 'THÔNG TIN TRÁI PHIẾU',
  infoValuation: 'THÔNG TIN ĐỊNH GIÁ',
  sameIndusTryBond:
    'TRÁI PHIẾU CỦA TỔ CHỨC PHÁT HÀNH VÀ DOANH NGHIỆP CÙNG NGÀNH',
  history: 'LỊCH TRẢ LÃI & GỐC THỰC TẾ',
}

export const common = {
  nameTab: 'Điều chỉnh %{number}',
}

export const infoSec = {
  filter: {
    tab: {
      bondDefault: 'Trái phiếu có sẵn',
      bondCreated: 'Trái phiếu tự tạo',
    },
    btnCreatedBond: 'Tạo trái phiếu',
  },
  info: {
    organizationName: 'Tổ chức phát hành:',
    icbName: 'Ngành:',
    issueDateId: 'Ngày phát hành:',
    maturityDateId: 'Ngày đáo hạn:',
    paymentCalendarMonth: 'Kỳ trả lãi (tháng/lần):',
    expectedInterestPaymentPeriod: 'Số kỳ trả coupon dự kiến :',
    durationName: 'Kỳ hạn phát hành',
    couponTypeName: 'Loại lãi suất',
    CouponType: 'Coupon dự kiến (%/năm):',
    floatInterestSpread: 'Lãi suất tham chiếu :',
    info: 'Thông tin thay đổi kỳ và lãi trái phiếu:',
    note: 'Lưu ý: Lãi tham chiếu hiện đang khác với lãi suất công bố của TCPH',
    textView: 'Xem',
  },
  popupView: {
    title: 'Thông tin thay đổi kỳ và lãi trái phiếu',
    content: 'Thông tin thay đổi kỳ và lãi trái phiếu:',
    btnOk: 'OK',
  },
}

export const history = {
  filter: {
    word: 'Lãi cộng dồn (VND):',
    mass: 'Khối lượng (trái phiếu)',
  },
  table: {
    paymentDate: 'NGÀY THANH TOÁN',
    couponInterestRate: 'COUPON ',
    unitMoney: '(VND)',
    unitYear: '(%)',
    interestAndPrincipal: 'LÃI & GỐC',
    paymentStatusName: 'TÌNH TRẠNG',
    total: 'Tổng dòng tiền dự kiến còn lại',
  },
  tooltipStatus: 'Chưa xác nhận',
}

export const sameIndusTryBond = {
  tabDefault: {
    default: 'Danh nghĩa',
    created: 'Thực tế',
  },
  title: 'Thông tin định giá',
  inputPrice: {
    title: 'Nhập giá trị tính giá',
    ProfitBasis: 'Cơ sở tính lãi:',
    tradingDate: 'Ngày giao dịch',
    paymentDate: 'Ngày thanh toán',
    Exrightdate: 'Ngày chốt quyền',
    tradingVolume: 'KL mua bán (TP)',

    YTM: 'Lợi suất (YTM)',
    dirtyPrice: 'Giá bẩn (VND)',
    cleanPrice: 'Giá sạch (VND)',
    accurateInterest: 'Lãi cộng dồn ',

    TotalParValue: 'Tổng giá trị mệnh giá',
    TotalExpectedReceivedCashFlow: 'Tổng dòng tiền nhận dự kiến:',
    TotalInvestmentValue: 'Tổng giá trị đầu tư (người mua):',
    TotalExpectedInvestmentProfit: 'Tổng lãi đầu tư dự kiến:',
    YieldToMaturity: 'YTM:',
    RemainingDuration: 'Kỳ hạn còn lại:',
    cashLineScenels: 'KỊCH BẢN DÒNG TIỀN',
  },
  BondCashFlowScenario: {
    title: 'Kịch BẢN DÒNG TIỀN',
    tab: {
      Titular: 'Danh nghĩa',
      Reality: 'Thực tế',
      tab1: 'Điều chỉnh 1',
      tab2: 'Điều chỉnh 2',
      tab3: 'Điều chỉnh 3',
      tab4: 'Điều chỉnh 4',
      tab5: 'Điều chỉnh 5',
      more: 'More',
    },
    table: {
      CashFlow: 'Dòng tiền',
      dateTrading: 'Ngày thanh toán',
      coupon: 'Coupon ',
      value: 'Giá trị ',
      typeCashFlow: {
        A1: 'Trái tức',
        A4: 'Nợ gốc',
      },
      unitMoney: '(%/Năm)',
      unit: '(VND)',
    },
    btnAddCashFlow: 'Thêm dòng tiền',
    btnSave: 'Lưu',
    btnReset: 'Reset',
  },
  popupAddRow: {
    title: 'Thêm dòng tiền',
    labelName: 'Tên dòng tiền',
    LabelDate: 'Ngày thanh toán',
    btnSave: 'Lưu',
    btnCancel: 'Hủy bỏ',
    SUCCESS: 'Dòng tiền "%{name}" đã được lưu thành công',
  },
  popupDelete: {
    title: 'Xóa biểu mẫu',
    message: 'Bạn có chắc chắn muốn xóa dòng tiền?',
  },
  bottom: {
    btnAdd: 'Thêm dòng tiền',
    btnSave: 'Lưu',
    btnReset: 'Reset',
  },
  tooltipInput: {
    Tradingdate: 'Ngày giao dịch = Ngày thanh toán -1',
    Paymentdate: 'Ngày dùng để định giá',
    Exrightdate: 'Ngày chốt quyền của kỳ trả lãi',
    TradingVolume: 'Số trái phiếu  mua giả định',
    TotalParvalue: 'Tổng giá trị mệnh giá',

    YTM: 'Lãi suất chiết khấu dùng để định giá',
    Dirtyprice: 'Giá giao dịch giả định',
    Cleanprice: '=Giá bẩn - Lãi cộng dồn',
    AccurateInterest:
      'Số tiền lãi từ trái phiếu, nhưng chưa được thu Kỳ hạn còn lại',
  },
  contentCheckPaymentDate:
    'Ngày thanh toán phải lớn hơn ngày phát hành và nhỏ hơn ngày đáo hạn của trái phiếu',
  contentComparePaymentDateTradingDate:
    'Ngày thanh toán phải lớn hơn ngày giao dịch',
  SC07_M007: 'Bạn có chắc muốn xóa dòng tiền %{Namecashflow} ?',
  SC07_M006: 'Phải nhập ít nhất 1 ký tự',
  SC07_M005: 'Chỉ được thêm tối đa 5 dòng tiền điều chỉnh',
  SC07_M004: 'Chỉ được nhập tối đa 50 ký tự',
  SC07_M003:
    'Ngày trả lãi quá khứ phải lớn hơn ngày phát hành và nhỏ hơn ngày thanh toán',
  SC07_M001: 'Tính năng đang phát triển',
  SC07_MS009: 'Bạn có chắc muốn xóa dòng tiền ?',
  SC07_MS0010: 'Ngày trả lãi phải lớn hơn ngày thanh toán',
  SC07_MS0011: 'Chỉ được thêm tối đa 10 dòng tiền',
  addTab: 'Thêm kịch bản dòng tiền',
  more: 'Khác',
}

export const companny = {
  table: {
    issuer: 'TCPH',
    bondTicker: 'Mã TP',
    couponInterestRate: 'Coupon ',
    issueDateId: 'Ngày phát hành',
    marketPrice: 'Giá gộp ',
    remainingDuration: 'KHCL ',
    yieldtoMaturity: 'YTM',
    unitMoney: '(%)',
    unitMonth: '(Tháng)',
    unit: '(VND)',
    unitCoupon: '(%)',
  },
}
