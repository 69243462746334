import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import { TYPE_CHART_SOCIAL_INVESTMENT } from './constants'
import { getTypeChartValue } from './store/slice'

export const INVESTMENT_STRUCTURE = 'INVESTMENT_STRUCTURE'

const PanelInvestmentStructure = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const typeChartValue = useSelector(getTypeChartValue)

  const isTotalSocialChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL
  const isBudgetProvinceChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.BUDGET_PROVINCES
  const nameScreen = UseI18n('economy.fiscal.stateBudget.STATE_BUDGET')
  const chartName = isTotalSocialChart
    ? UseI18n('economy.fiscal.stateBudget.TOTAL_SOCIAL_CHART_NAME')
    : isBudgetProvinceChart
    ? UseI18n('economy.fiscal.stateBudget.BUDGET_PROVINCES_CHART_NAME')
    : UseI18n('economy.fiscal.stateBudget.STATE_SECTOR_CHART_NAME')

  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'economy.fiscal.stateBudget.INVESTMENT_STRUCTURE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      titleJpg={chartName}
      isDownloadJpg
      downloadJpgParams={{
        domId: INVESTMENT_STRUCTURE,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <Container
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelInvestmentStructure
