import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import style from './style.module.css'

const PopupWarning = ({
  tickerOrTaxCode,
  organizationShortName,
  closeWarning,
}) => {
  return (
    <Popup>
      <div className="modal alert" style={{ width: 416 }}>
        <div className="modal-content" style={{ paddingBottom: 16 }}>
          <div className={style.textOrange}>
            <Span>
              {tickerOrTaxCode + ' - ' + organizationShortName}
              <Translate value="corporate.companySnapshot.MESS_WARNING" />
            </Span>
          </div>
          <div
            className={`group-btn d-flex justify-content-end w-100 ${style.mt24}`}
          >
            <Button
              onClick={closeWarning}
              className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
              style={{ fontSize: 11 }}
            >
              OK
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupWarning
