import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SavePopup from './SavePopup'

const SaveButton = ({ templateParameters, date }) => {
  const [isShow, setShowPopup] = useState(false)

  const handleClose = () => setShowPopup(false)

  return (
    <>
      <button onClick={() => setShowPopup(true)}>
        <Span>
          <Translate value={'newsReport.dailyReport.dailyReport.SAVE'} />
        </Span>
      </button>
      <SavePopup
        isShow={isShow}
        handleClose={handleClose}
        templateParameters={templateParameters}
        date={date}
      />
    </>
  )
}

SaveButton.propTypes = {
  templateParameters: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default SaveButton
