import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class NewsProxy extends ProxyBase {
  getListReport(params) {
    return this.get('GetListReport', params)
  }

  getListNews(params) {
    return this.get('GetListNews', params, null, true)
  }

  getNewsCategories(params) {
    return this.get('GetNewsCategories', params)
  }

  getReportTypes(params) {
    return this.get('GetReportTypes', params)
  }

  getNewsSources(params) {
    return this.get('GetNewsSources', params)
  }

  getReportSources(params) {
    return this.get('GetReportSources', params)
  }

  getLatestNews(params) {
    return this.get('GetLatestNews', params)
  }

  getNewsById(params) {
    return this.get('GetNewsById', params)
  }

  getListNewsOverview(params) {
    return this.get('GetListNews', params)
  }
}

export default new NewsProxy(
  ServiceProxyConfig.Sector.SectorConstituents.NewsReports.ServiceUrl,
)
