import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GROWTH_YOY } from '.'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  getDropDownValue,
  getValueMOM,
} from '../panelMoneySupplyCredit/store/slice'
import Header from './Header'
import LineChart from './LineChart'
import { getLineChartData, getYears } from './store/slice'
import { getGrowthOverYears } from './store/thunk'

const ChartComponent = ({ height, width }) => {
  const dispatch = useDispatch()

  const lineChartData = useSelector(getLineChartData)
  const dropdownValue = useSelector(getDropDownValue)
  const years = useSelector(getYears)
  const valueMoM = useSelector(getValueMOM)
  const isValue = valueMoM === 'Value'

  useEffect(() => {
    if (dropdownValue) {
      dispatch(
        getGrowthOverYears({
          'TypeId.Id': parseInt(dropdownValue),
          'TypeId.IsMoneySupply': dropdownValue.includes('true'),
          SBVType: valueMoM,
        }),
      )
    }
  }, [dropdownValue, valueMoM])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header />
            {typeof sizes.height === 'number' && (
              <div id={GROWTH_YOY}>
                <LineChart
                  data={lineChartData}
                  width={width}
                  height={height - sizes.height}
                  keyXAxis={'month'}
                  keyYAxisProps={years}
                  isValue={isValue}
                />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

ChartComponent.propsType = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default ChartComponent
