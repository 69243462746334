import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InterestRateService from '../../../../../../core/services/economy/InterestRateService'
import { handleExport } from '../../../../../utils/Export'

export const getStatisticStateBank = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateVietNam/getStatisticStateBank',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getStatisticStateBank(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getStatisticStateBankName = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateVietNam/getStatisticStateBankName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getStatisticStateBankName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxDate = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateVietNam/getMaxDate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getMaxDate(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadStatisticStateBank = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateVietNam/downloadStatisticStateBank',
  async (data) => {
    const res = await InterestRateService.downloadStatisticStateBank(data)
    handleExport(res.data, res.filename)
  },
)
