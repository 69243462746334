import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { LIMIT_FIRST_RENDER, LIMIT_RENDER, tabConstants } from './constants'
import { getTableSchema } from './helpers'
import {
  resetData,
  selectData,
  selectDataByIdValue,
  selectDataIds,
  selectGroupColumns,
  selectLoading,
  selectLoadingScroll,
  selectMinTimeCallApi,
  selectTimeType,
} from './store/slice'
import {
  getConsumptionDataTable,
  getConsumptionDataTableNew,
} from './store/thunk'

export const ConsumptionTable = () => {
  const dispatch = useDispatch()

  const data = useSelector(selectData)
  const dataIds = useSelector(selectDataIds)
  const isLoading = useSelector(selectLoading)
  const isLoadingScroll = useSelector(selectLoadingScroll)

  const groupColumns = useSelector(selectGroupColumns)
  const timeType = useSelector(selectTimeType)
  const minTimeCallApi = useSelector(selectMinTimeCallApi)
  const locale = useSelector((state) => state.i18n.locale)

  const [isFirstRender, setIsFirstRender] = useState(true)

  const scrollToLeftCallback = () => {
    if (minTimeCallApi?.year || minTimeCallApi?.month) {
      setIsFirstRender(false)
      if (timeType !== tabConstants.YEARLY) {
        if (minTimeCallApi?.month === 1 && minTimeCallApi?.year === 2005)
          return null
        dispatch(
          getConsumptionDataTableNew({
            DataType: timeType,
            Year: minTimeCallApi?.year,
            Month: minTimeCallApi?.month,
            Limit: LIMIT_RENDER + 1,
          }),
        )
      }
      if (minTimeCallApi?.year !== 2004 && timeType === tabConstants.YEARLY) {
        dispatch(
          getConsumptionDataTableNew({
            DataType: timeType,
            Year: minTimeCallApi?.year,
            Limit: LIMIT_RENDER + 1,
          }),
        )
      }
    }
  }

  useEffect(() => {
    setIsFirstRender(true)
    dispatch(
      getConsumptionDataTable({
        DataType: timeType,
        Limit: LIMIT_FIRST_RENDER,
      }),
    )
    return () => {
      dispatch(resetData())
    }
  }, [timeType, locale])

  return (
    <div id="tableData" className="h-100">
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <Table
                  ids={dataIds}
                  columnDraggable={false}
                  getDataFromRedux={selectDataByIdValue}
                  isLoading={isLoading || isLoadingScroll}
                  rowDraggable={false}
                  schema={getTableSchema(groupColumns, data, timeType, locale)}
                  stickyFirstColumn={true}
                  isPagination={false}
                  hasFooter={false}
                  resizable={false}
                  rowHighlightLines={[dataIds.length]}
                  scrollToLeftCallback={scrollToLeftCallback}
                  defaultScrollToRight={isFirstRender}
                  isLazyLoadLeft={true}
                />
              </div>
            )}
            <div className="mt-8">
              <Span style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}>
                <Translate value="economy.productionAndConsumption.consumption.TABLE_NOTE" />
              </Span>
            </div>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
