export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

export const convertData = (arr) => {
  let result = []
  arr.forEach((element) => {
    const object = arr
      .filter((i) => i.ti === element.ti)
      .reduce(
        (obj, item) =>
          Object.assign(obj, {
            organizationId: item.organizationId,
            ti: item.ti,
            [item.metricCode]: item.value,
          }),
        {},
      )
    result.push(object)
  })
  return result
}
