import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { FirstChart } from './FirstChart'
import { FINANCIAL_STATEMENT_CHARTS_OPTIONS } from './constants'

export const PanelFirstChart = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  statement,
  statementItem,
}) => {
  const basicInfo = useSelector(selectBasicInfo)

  const getTitle = useMemo(() => {
    if (!statement.value || !statementItem) {
      return ''
    } else {
      return FINANCIAL_STATEMENT_CHARTS_OPTIONS[statement.value][statementItem]
        .chart1.topLabel
    }
  }, [statement, statementItem])

  return (
    <Panel
      title={getTitle}
      panelRefs={panelRefs}
      panelKey={mapKey.FINANCIAL_CHART_1}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadJpg
      windowZoom
      downloadJpgParams={{
        domId: 'financialStatementFirstChart',
        nameScreen: UseI18n('corporate.financialData.common.FINANCIAL_DATA'),
        chartName: UseI18n(
          'corporate.financialData.financialStatement.FINANCIAL_STATEMENT',
        ),
        tabName: UseI18n(getTitle),
        tickerName: basicInfo.ticker,
      }}
      titleJpg={UseI18n(getTitle)}
    >
      <FirstChart
        width={formatNumber(
          sizes[mapKey.FINANCIAL_CHART_1].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.FINANCIAL_CHART_1].height - HEADER_PANEL_HEIGHT,
        )}
        statementItem={statementItem}
      />
    </Panel>
  )
}

PanelFirstChart.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  statement: PropTypes.object.isRequired,
  statementItem: PropTypes.string,
}
