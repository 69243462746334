import * as bondScreening from './bondScreening'
import * as bondSecurity from './bondSecurity'
import * as comparable from './comparable'
import * as corporateBond from './corporateBond'
import * as interconnectionMap from './interconnectionMap'
import * as overview from './overview'
import * as portfolio from './portfolio'
import * as valuation from './valuation'

const bond = {
  corporateBond,
  overview,
  valuation,
  bondScreening,
  portfolio,
  bondSecurity,
  comparable,
  interconnectionMap,
}

export default bond
