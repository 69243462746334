import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LIST_TYPE } from '../../../common/constants'
import { getDataQueryPayloadParams } from '../../../common/helpers/dashboardApiParamsAndResponseHelpers'
import { selectSecurities } from '../../../popup/common/store/slice'
import { selectIndicatorSelected } from '../../../popup/popupAddIndicator/store/slice'
import {
  selectIndicatorLatestPeriodLoading,
  selectIndicatorsLatestPeriod,
} from '../../../store/slice'
import {
  EXCLUDE_DATA_KEYS,
  VIEW_TYPE,
  keyToolHeaderSettings,
} from '../../constants'
import {
  changeDataChartByTime,
  selectDataChartByTime,
  selectSettings,
  selectViewType,
} from '../../store/slice'
import { getDataQuery } from '../../store/thunk'

export const DataBySecurities = ({
  isFirstTimeEditChart,
  setIsFirstTimeEditChart,
}) => {
  const dispatch = useDispatch()

  const callDataBySecurities = useRef()
  const callDataByIndicators = useRef()

  const [firstSetData, setFirstSetData] = useState(true)

  const securities = useSelector(selectSecurities)
  const { companies, indices, sector, economy } = securities

  const indicatorSelected = useSelector(selectIndicatorSelected)
  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected

  const viewType = useSelector(selectViewType)
  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)
  const loadingIndicatorLatestPeriod = useSelector(
    selectIndicatorLatestPeriodLoading,
  )
  const dataChartByTime = useSelector(selectDataChartByTime)
  const settings = useSelector(selectSettings)

  const checkCallData = (oldList, newList, oldArrObj, newArrObj) => {
    return (
      !(
        newList?.length < oldList?.length &&
        oldList?.some((r) => newList?.indexOf(r) >= 0)
      ) &&
      !(
        newArrObj?.length < oldArrObj?.length &&
        oldArrObj
          .map((item) => [item?.id, item?.uniqueID].join('_'))
          .some(
            (item1) =>
              newArrObj
                ?.map((el) => [el?.id, el?.uniqueID].join('_'))
                .indexOf(item1) >= 0,
          )
      )
    )
  }

  useEffect(() => {
    if (
      firstSetData &&
      (Object.values(securities).flat().length ||
        Object.values(indicatorSelected).flat().length)
    ) {
      callDataBySecurities.current = securities
      callDataByIndicators.current = indicatorSelected
      setFirstSetData(false)
    }
  }, [
    JSON.stringify(securities),
    JSON.stringify(indicatorSelected),
    firstSetData,
  ])

  useEffect(() => {
    if (
      !isFirstTimeEditChart &&
      !firstSetData &&
      checkCallData(
        callDataBySecurities.current.companies,
        companies,
        callDataByIndicators.current.companyIndicator,
        companyIndicator,
      )
    ) {
      if (!loadingIndicatorLatestPeriod) {
        const securitiesPayload = []

        if (companyIndicator.length && companies.length) {
          securitiesPayload.push({
            securitiesType: 'Companies',
            dataIds: companies,
            indicators: companyIndicator,
          })
        }

        if (securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          dispatch(getDataQuery(payload))
        }
      }
    }
    callDataBySecurities.current = {
      ...callDataBySecurities.current,
      companies,
    }
    callDataByIndicators.current = {
      ...callDataByIndicators.current,
      companyIndicator,
    }
  }, [
    JSON.stringify(companies),
    JSON.stringify(companyIndicator),
    loadingIndicatorLatestPeriod,
    firstSetData,
  ])

  useEffect(() => {
    if (
      !isFirstTimeEditChart &&
      !firstSetData &&
      checkCallData(
        callDataBySecurities.current.indices,
        indices,
        callDataByIndicators.current.indexIndicator,
        indexIndicator,
      )
    ) {
      if (!loadingIndicatorLatestPeriod) {
        const securitiesPayload = []

        if (indexIndicator.length && indices.length) {
          securitiesPayload.push({
            securitiesType: 'Indices',
            dataIds: indices,
            indicators: indexIndicator,
          })
        }

        if (securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          dispatch(getDataQuery(payload))
        }
      }
    }
    callDataBySecurities.current = { ...callDataBySecurities.current, indices }
    callDataByIndicators.current = {
      ...callDataByIndicators.current,
      indexIndicator,
    }
  }, [
    JSON.stringify(indices),
    JSON.stringify(indexIndicator),
    loadingIndicatorLatestPeriod,
    firstSetData,
  ])

  useEffect(() => {
    if (
      !isFirstTimeEditChart &&
      !firstSetData &&
      checkCallData(
        callDataBySecurities.current.sector,
        sector,
        callDataByIndicators.current.sectorIndicator,
        sectorIndicator,
      )
    ) {
      if (!loadingIndicatorLatestPeriod) {
        const securitiesPayload = []

        if (sectorIndicator.length && sector.length) {
          securitiesPayload.push({
            securitiesType: 'Sector',
            dataIds: sector,
            indicators: sectorIndicator,
          })
        }

        if (securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          dispatch(getDataQuery(payload))
        }
      }
    }
    callDataBySecurities.current = { ...callDataBySecurities.current, sector }
    callDataByIndicators.current = {
      ...callDataByIndicators.current,
      sectorIndicator,
    }
  }, [
    JSON.stringify(sector),
    JSON.stringify(sectorIndicator),
    loadingIndicatorLatestPeriod,
    firstSetData,
  ])

  useEffect(() => {
    if (
      !isFirstTimeEditChart &&
      !(
        economy.length < callDataBySecurities.current.economy?.length &&
        callDataBySecurities.current.economy?.some(
          (r) => economy.indexOf(r) >= 0,
        )
      )
    ) {
      if (!loadingIndicatorLatestPeriod) {
        const securitiesPayload = []

        if (economy.length) {
          securitiesPayload.push({
            securitiesType: 'Economy',
            dataIds: [],
            indicators: economy,
          })
        }

        if (securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          dispatch(getDataQuery(payload))
        }
      }
    }
    callDataBySecurities.current = { ...callDataBySecurities.current, economy }
  }, [JSON.stringify(economy), loadingIndicatorLatestPeriod])

  useEffect(() => {
    if (isFirstTimeEditChart && !firstSetData) {
      if (!loadingIndicatorLatestPeriod) {
        const securitiesPayload = []

        if (companyIndicator.length && companies.length) {
          securitiesPayload.push({
            securitiesType: 'Companies',
            dataIds: companies,
            indicators: companyIndicator,
          })
        }

        if (indexIndicator.length && indices.length) {
          securitiesPayload.push({
            securitiesType: 'Indices',
            dataIds: indices,
            indicators: indexIndicator,
          })
        }

        if (sectorIndicator.length && sector.length) {
          securitiesPayload.push({
            securitiesType: 'Sector',
            dataIds: sector,
            indicators: sectorIndicator,
          })
        }

        if (economy.length) {
          securitiesPayload.push({
            securitiesType: 'Economy',
            dataIds: [],
            indicators: economy,
          })
        }

        if (securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          dispatch(getDataQuery(payload))
        }

        setIsFirstTimeEditChart(false)
      }
    }
  }, [
    isFirstTimeEditChart,
    loadingIndicatorLatestPeriod,
    viewType,
    settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
    firstSetData,
  ])

  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_TIME && dataChartByTime.length) {
      const dataKeys = []
      companies.forEach((securityId) =>
        companyIndicator.forEach((indicator) =>
          dataKeys.push(
            `${LIST_TYPE.COMPANIES}_${securityId}_${indicator.id}_${indicator.timeFrequency}`,
          ),
        ),
      )
      indices.forEach((securityId) =>
        indexIndicator.forEach((indicator) =>
          dataKeys.push(
            `${LIST_TYPE.INDICES}_${securityId}_${indicator.id}_${indicator.timeFrequency}`,
          ),
        ),
      )
      sector.forEach((securityId) =>
        sectorIndicator.forEach((indicator) =>
          dataKeys.push(
            `${LIST_TYPE.SECTOR}_${securityId}_${indicator.id}_${indicator.timeFrequency}`,
          ),
        ),
      )
      economy.forEach((indicator) =>
        dataKeys.push(
          `${LIST_TYPE.ECONOMY}_${indicator.id}_${indicator.timeFrequency}`,
        ),
      )

      const newData = []
      dataChartByTime.forEach((item) => {
        const newItem = Object.keys(item).reduce((obj, key) => {
          if (
            Object.values(LIST_TYPE).some((securityKey) =>
              key.includes(securityKey),
            )
          ) {
            return dataKeys.some((val) => key.includes(val))
              ? { ...obj, [key]: item[key] }
              : obj
          } else {
            return { ...obj, [key]: item[key] }
          }
        }, {})

        if (
          Object.keys(newItem).some((key) => !EXCLUDE_DATA_KEYS.includes(key))
        ) {
          newData.push(newItem)
        }
      })

      dispatch(changeDataChartByTime(newData))
    }
  }, [JSON.stringify(securities), JSON.stringify(indicatorSelected)])

  return <></>
}
