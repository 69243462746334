import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LIST_TYPE } from '../../../common/constants'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { selectSecurities } from '../../../popup/common/store/slice'
import { selectIndicatorSelected } from '../../../popup/popupAddIndicator/store/slice'
import {
  changeIndicatorsLatestPeriodLoading,
  selectIndicatorsLatestPeriod,
} from '../../../store/slice'
import { getListIndicatorLatestPeriod } from '../../../store/thunk'
import { VIEW_TYPE } from '../../constants'
import {
  getEconomyWithLatestPeriod,
  getEquationsWithLatestPeriod,
  getSecuritiesIndicatorsWithLatestPeriod,
} from '../../helper'
import {
  changeChartTabIndicatorSelected,
  changeChartTabSecurities,
  changeFullEquations,
  selectChartTabIndicatorSelected,
  selectChartTabSecurities,
  selectEquations,
  selectViewType,
} from '../../store/slice'

export const DataByLatestPeriod = () => {
  const dispatch = useDispatch()

  const securities = useSelector(selectSecurities)
  const { economy } = securities
  const indicatorSelected = useSelector(selectIndicatorSelected)
  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected

  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)
  const viewType = useSelector(selectViewType)
  const equations = useSelector(selectEquations)
  const chartTabIndicatorSelected = useSelector(
    selectChartTabIndicatorSelected(),
  )
  const { economy: chartTabEconomy } = useSelector(selectChartTabSecurities)

  const equationIndicators = useMemo(() => {
    return Object.values(equations).reduce(
      (arr, equation) => [
        ...arr,
        ...Object.values(equation.equationIndicators),
      ],
      [],
    )
  }, [equations])

  useEffect(() => {
    const asyncFn = async () => {
      if (viewType === VIEW_TYPE.BY_SECURITIES) {
        const indicators = []

        if (companyIndicator.length) {
          companyIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: getTimeFrequencyByIndicator(item),
              })
          })
        }

        if (indexIndicator.length) {
          indexIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: getTimeFrequencyByIndicator(item),
              })
          })
        }

        if (sectorIndicator.length) {
          sectorIndicator.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: getTimeFrequencyByIndicator(item),
              })
          })
        }

        if (economy.length) {
          economy.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.id,
                timeFrequency: getTimeFrequencyByIndicator(item),
              })
          })
        }

        if (equationIndicators.length) {
          equationIndicators.forEach((item) => {
            const indicatorLatestPeriod =
              indicatorsLatestPeriod[
                `${item.indicatorId}_${getTimeFrequencyByIndicator(item)}`
              ]

            ;(!indicatorLatestPeriod ||
              !Object.keys(indicatorLatestPeriod).length) &&
              indicators.push({
                indicatorId: item.indicatorId,
                timeFrequency: getTimeFrequencyByIndicator(item),
              })
          })
        }

        if (indicators.length) {
          dispatch(changeIndicatorsLatestPeriodLoading(true))
          await dispatch(getListIndicatorLatestPeriod(indicators))
        }
      }

      dispatch(changeIndicatorsLatestPeriodLoading(false))
    }
    asyncFn()
  }, [
    viewType,
    JSON.stringify(companyIndicator),
    JSON.stringify(indexIndicator),
    JSON.stringify(sectorIndicator),
    JSON.stringify(economy),
    JSON.stringify(equationIndicators),
  ])

  useEffect(() => {
    const newIndicatorSelected = getSecuritiesIndicatorsWithLatestPeriod(
      chartTabIndicatorSelected,
      indicatorsLatestPeriod,
    )
    const newEconomy = getEconomyWithLatestPeriod(
      chartTabEconomy,
      indicatorsLatestPeriod,
    )
    const newEquations = getEquationsWithLatestPeriod(
      equations,
      indicatorsLatestPeriod,
    )

    dispatch(changeChartTabIndicatorSelected(newIndicatorSelected))
    dispatch(changeChartTabSecurities({ [LIST_TYPE.ECONOMY]: newEconomy }))
    dispatch(changeFullEquations(newEquations))
  }, [
    indicatorsLatestPeriod,
    JSON.stringify(chartTabEconomy),
    JSON.stringify(chartTabIndicatorSelected),
    JSON.stringify(equations),
  ])

  return <></>
}
