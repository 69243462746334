import axios from 'axios'
import { getTimeZoneLocal } from '../../components/utils/Datetime'
import { getAuthTime, getDeviceKey, getOs } from '../../components/utils/Device'
import EventEmitter, {
  API_TIMEOUT,
  IS_API_ERROR_403,
} from '../../components/utils/EventEmitter'
import { getLanguage } from '../../components/utils/Language'
import { IS_PUBLIC_SERVICE } from '../../configs/Common'
import authProvider from '../AuthenticationProvider'
import registerCancelTokenAxios from './RegisterCancelTokenAxios'

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.code === 'ECONNABORTED') {
      EventEmitter.dispatch(API_TIMEOUT, '')
    }

    if (error.response && error.response.status === 401) {
      console.log('unauthorized!')
      // window.location.href = '/'
    }

    if (
      error.response &&
      error.response.status === 403 &&
      !error.response.config.url.includes('Package/functions')
    ) {
      EventEmitter.dispatch(IS_API_ERROR_403)
      return
    }

    // setTimeout(() => {
    //   console.clear()
    // }, 0)

    return Promise.reject(error)
  },
)

axios.interceptors.request.use(async function (config) {
  const user = await authProvider.getUser()
  const username = user.profile.user_name
  const deviceKey = getDeviceKey(username)
  const authTime = getAuthTime(username)

  config.headers.language = getLanguage()
  config.headers.timeZoneId = getTimeZoneLocal().timeZoneName
  config.headers.os = getOs()
  config.headers.locationUser = null
  if (deviceKey) {
    config.headers.deviceKey = deviceKey
  }
  if (authTime) {
    config.headers['auth-time'] = Number(authTime)
  }
  return config
})

export default class ProxyBase {
  baseServiceUrl = ''

  constructor(url) {
    this.baseServiceUrl = url
  }

  get(path, data, type, disableCancelToken = false, cancelTokenParams = '') {
    const url = this.baseServiceUrl + path
    const cancelTokenName = disableCancelToken ? url : url + cancelTokenParams
    const params = { ...data }

    let source = null
    if (!disableCancelToken) {
      registerCancelTokenAxios.delete(cancelTokenName)
      source = registerCancelTokenAxios.register(cancelTokenName)
    }

    const config = {
      params,
      timeout: 35000,
    }

    if (source && !disableCancelToken) {
      config.cancelToken = source.token
    }

    if (type === 'download') {
      config.responseType = 'blob'
    }

    if (IS_PUBLIC_SERVICE) {
      return axios.get(url, config).then((data) => {
        if (!disableCancelToken) {
          registerCancelTokenAxios.delete(cancelTokenName)
        }
        return data
      })
    }

    return authProvider.getUser().then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${
        user ? user.access_token : ''
      }`

      return axios.get(url, config).then((data) => {
        if (!disableCancelToken) {
          registerCancelTokenAxios.delete(cancelTokenName)
        }
        return data
      })
    })
  }

  retryWhileResEmpty({
    path,
    paramsArr,
    breakCondition,
    isPostMethod = false,
  }) {
    if (!breakCondition) {
      breakCondition = (response) =>
        !!response.data?.data?.length || !response.data?.success
    }

    let paramsIndex = 0

    const promiseArr = paramsArr.map((params, index) => (response) => {
      if (response && breakCondition(response)) {
        return response
      } else {
        paramsIndex = index
        return isPostMethod ? this.post(path, params) : this.get(path, params)
      }
    })

    return promiseArr
      .reduce((prev, curr) => prev.then(curr), Promise.resolve())
      .then((response) => {
        response.data.requestParams = paramsArr[paramsIndex]
        return response
      })
      .catch((error) => error)
  }

  post(
    path,
    data,
    config = {},
    type,
    disableCancelToken = false,
    cancelTokenParams = '',
  ) {
    const url = this.baseServiceUrl + path
    const cancelTokenName = disableCancelToken ? url : url + cancelTokenParams
    let source = null

    if (!disableCancelToken) {
      registerCancelTokenAxios.delete(cancelTokenName)
      source = registerCancelTokenAxios.register(cancelTokenName)
    }

    if (source && !disableCancelToken) {
      config.cancelToken = source.token
    }

    if (type === 'download') {
      config.responseType = 'blob'
    }

    if (IS_PUBLIC_SERVICE) {
      return axios
        .post(`${this.baseServiceUrl}${path}`, data, config)
        .then((data) => {
          if (!disableCancelToken) {
            registerCancelTokenAxios.delete(cancelTokenName)
          }
          return data
        })
    }

    return authProvider.getUser().then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${
        user ? user.access_token : ''
      }`

      return axios
        .post(`${this.baseServiceUrl}${path}`, data, config)
        .then((data) => {
          if (!disableCancelToken) {
            registerCancelTokenAxios.delete(cancelTokenName)
          }
          return data
        })
    })
  }

  put(
    path,
    data,
    config = {},
    disableCancelToken = false,
    cancelTokenParams = '',
  ) {
    const url = this.baseServiceUrl + path
    const cancelTokenName = disableCancelToken ? url : url + cancelTokenParams
    let source = null

    if (!disableCancelToken) {
      registerCancelTokenAxios.delete(cancelTokenName)
      source = registerCancelTokenAxios.register(cancelTokenName)
    }

    if (source && !disableCancelToken) {
      config.cancelToken = source.token
    }

    if (IS_PUBLIC_SERVICE) {
      return axios
        .put(`${this.baseServiceUrl}${path}`, data, config)
        .then((data) => {
          if (!disableCancelToken) {
            registerCancelTokenAxios.delete(cancelTokenName)
          }
          return data
        })
    }

    return authProvider.getUser().then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${
        user ? user.access_token : ''
      }`

      return axios
        .put(`${this.baseServiceUrl}${path}`, data, config)
        .then((data) => {
          if (!disableCancelToken) {
            registerCancelTokenAxios.delete(cancelTokenName)
          }
          return data
        })
    })
  }

  delete(path, params, config = {}) {
    config.data = { ...params }

    if (IS_PUBLIC_SERVICE) {
      return axios.delete(`${this.baseServiceUrl}${path}`, config)
    }

    return authProvider.getUser().then((user) => {
      axios.defaults.headers.common.Authorization = `Bearer ${
        user ? user.access_token : ''
      }`

      return axios.delete(`${this.baseServiceUrl}${path}`, config)
    })
  }
}
