import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  addChecked,
  addCheckedAll,
  selectChecked,
  selectWatchlistIds,
  unchecked,
  uncheckedAll,
} from '../store/slice'

const WatchlistCheckbox = ({ id }) => {
  const dispatch = useDispatch()

  const checkedList = useSelector(selectChecked)
  const watchlistIds = useSelector(selectWatchlistIds)

  const handleChangeCheckbox = (e) => {
    if (id) {
      if (e.target.checked) dispatch(addChecked(id))
      else dispatch(unchecked(id))
    } else {
      if (e.target.checked) dispatch(addCheckedAll(watchlistIds))
      else dispatch(uncheckedAll())
    }
  }

  const handleUncheckAll = () => dispatch(uncheckedAll())

  const handleCaptureClick = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      {id || checkedList.length === 0 ? (
        <label
          onClick={handleCaptureClick}
          className="d-flex justify-content-center"
        >
          <input
            type="checkbox"
            className={`checkbox line ${
              id ? style.checkCus : style.checkboxAll
            }`}
            onDoubleClick={handleCaptureClick}
            onChange={handleChangeCheckbox}
            checked={
              id
                ? checkedList.includes(id)
                : watchlistIds.length === checkedList.length &&
                  watchlistIds.length > 0
            }
          />
        </label>
      ) : (
        <button
          className={`${style.padding0} ${style.uncheckAll}`}
          onClick={handleUncheckAll}
        >
          <i className="icon-minus-stroke" />
        </button>
      )}
    </>
  )
}

WatchlistCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default WatchlistCheckbox
