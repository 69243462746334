import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import { ThCellWithIconInfo } from '../../../../../../common/table/ThCellWithIConInfo'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { CouponType, latePaymentType } from '../constants'
import {
  selectActiveTab,
  selectCouponTypeTab,
  updateCoupom,
  updateTab,
} from '../store/slice'
import style from './style.module.css'

const FilterCountry = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActiveTab)
  const couponTypeTab = useSelector(selectCouponTypeTab)
  return (
    <div className="d-flex ali-center group-btn">
      <div className={`d-flex ali-center`}>
        <DispatchActionTab
          onChangeTab={(e) => dispatch(updateTab(e.value))}
          data={latePaymentType.map((item) => ({
            title: item.label,
            value: item.value,
          }))}
          activeTab={activeTab}
          textSize={12}
        />
        <div className={`d-flex ali-center ${style.pl24px}`}>
          {CouponType.map(({ value, label }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center ${style.wrapRadio}
                  ${value === couponTypeTab && style.radioActive}
                  `}
                onClick={() => dispatch(updateCoupom(value))}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === couponTypeTab}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={label} />
                </Span>
              </div>
            )
          })}
        </div>
      </div>
      <ThCellWithIconInfo
        isI18n={true}
        tooltipCustom={'bond.overview.expectedCash.tooltip'}
        styleIcon={{
          fontSize: '15px',
        }}
        styleTextTooltip={{
          fontSize: 10,
          fontWeight: 400,
          fontStyle: 'italic',
          color: '#ECECEC',
          letterSpacing: '0.25px',
        }}
      >
        <></>
      </ThCellWithIconInfo>
    </div>
  )
}
export default FilterCountry
