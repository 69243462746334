import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../../utils/Export'

export const getOrderedStatisticsOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/getOrderedStatisticsOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getOrderedStatisticsOverview(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrderedStatisticsDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/getOrderedStatisticsDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getOrderedStatisticsDetail(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrderedStatisticsTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/getOrderedStatisticsTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getOrderedStatisticsTicker(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCoverWarrants = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getCoverWarrants',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getCoverWarrants(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadOrderedStatisticsOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/downloadOrderedStatisticsOverview',
  async (data) => {
    const response =
      await CoveredWarrantService.downloadOrderedStatisticsOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadOrderedStatisticsDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/downloadOrderedStatisticsDetail',
  async (data) => {
    const response =
      await CoveredWarrantService.downloadOrderedStatisticsDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadOrderedStatisticsTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/orderedStatistics/downloadOrderedStatisticsTicker',
  async (data) => {
    const response =
      await CoveredWarrantService.downloadOrderedStatisticsTicker(data)
    handleExport(response.data, response.filename)
  },
)
