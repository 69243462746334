import { useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { convertSortKeyToNumber } from '../../../../utils/Common'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import style from '../style.module.css'
import { dateTypes, indicator } from './constants'
import {
  selectListCompareChecked,
  selectListTicker,
} from './dropdownSearchTickers/store/slice'
import { getTickerByExchange } from './dropdownSearchTickers/store/thunk'
import { Filter } from './filter'
import * as schemas from './schemas'
import {
  changePage,
  changeSortField,
  changeSortOrder,
  selectData,
  selectDealIds,
  selectDealValue,
  selectFilter,
  selectLoadingDataTable,
  selectReCalcWidthsDealTable,
  sort,
} from './store/slice'
import { getDirectorDeals } from './store/thunk'

export const Deal = ({ width }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const timeout = useRef({ data: null })

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const dealIds = useSelector(selectDealIds)
  const basicInfo = useSelector(selectIndexInfo)
  const filterData = useSelector(selectFilter)
  const isLoading = useSelector(selectLoadingDataTable)
  const listCompareChecked = useSelector(selectListCompareChecked)
  const listTicker = useSelector(selectListTicker)
  const data = useSelector(selectData)
  const reCalcWidths = useSelector(selectReCalcWidthsDealTable)

  // Get data
  const dataTickerRedirect = sessionStorage.getItem('tickerRedirect')

  const getPayload = useMemo(() => {
    let initPayload = {
      GroupId: basicInfo.groupId,
      OrganizationIds: dataTickerRedirect
        ? dataTickerRedirect?.organizationId
        : listCompareChecked.length !== listTicker.length
        ? listCompareChecked.map((item) => item.organizationId).toString()
        : null,
      Type: filterData.stateTypes || null,
      Status: filterData.stateStatus || null,
      IsDownload: false,
      StartDate: null,
      EndDate: null,
      SortField: filterData.sortField,
      SortOrder: filterData.sortOrder,
      Page: filterData.page,
      PageSize: 25,
      Term: filterData.owner,
      TransactionType: filterData.transactionType,
      DateType: filterData.dateType,
    }

    if (filterData.timeRange === calendarTabKey) {
      initPayload = {
        ...initPayload,
        StartDate: filterData.dateFrom
          ? getISOStartOrEndOfDay(filterData.dateFrom, timeZone, true)
          : null,
        EndDate: filterData.dateTo
          ? getISOStartOrEndOfDay(filterData.dateTo, timeZone, false)
          : null,
      }
    } else {
      initPayload = {
        ...initPayload,
        TimeRange: filterData.timeRange,
        StartDate: null,
        EndDate: null,
      }
    }

    return initPayload
  }, [
    basicInfo.groupId,
    dataTickerRedirect?.organizationId,
    JSON.stringify(listCompareChecked),
    filterData.stateTypes,
    filterData.stateStatus,
    filterData.dateFrom,
    filterData.dateTo,
    filterData.sortField,
    filterData.sortOrder,
    filterData.page,
    filterData.owner,
    filterData.transactionType,
    filterData.dateType,
    filterData.timeRange,
  ])

  const getRowHighlights = () => {
    const itemIndex = {}
    data.forEach((item, index) =>
      filterData.dateType === dateTypes.TYPE_PUBLIC_DATE
        ? (itemIndex[item.publicDateId] = index)
        : (itemIndex[item.endDateId] = index),
    )
    return Object.keys(itemIndex).map((key) => itemIndex[key])
  }

  // Actions
  const handleChangePage = (page) => {
    dispatch(changePage(page))
  }

  const handleChangeSort = (data) => {
    if (Object.values(data)[0]) {
      dispatch(changeSortField(Object.keys(data)[0]))
      dispatch(changeSortOrder(convertSortKeyToNumber(Object.values(data)[0])))
    } else {
      dispatch(changeSortField(null))
      dispatch(changeSortOrder(null))
    }
  }

  // Use effect
  useEffect(() => {
    clearTimeout(timeout.current.data)
    timeout.current.data = setTimeout(() => {
      dispatch(getDirectorDeals(getPayload))
      clearTimeout(timeout.current.data)
    }, 100)

    return () => {
      clearTimeout(timeout.current.data)
    }
  }, [JSON.stringify(getPayload), locale])

  useEffect(() => {
    if (basicInfo.groupId) {
      const params = {
        GroupId: basicInfo.groupId,
        tickerRedirect: JSON.parse(dataTickerRedirect) || undefined,
      }
      dispatch(getTickerByExchange(params))
      sessionStorage.removeItem('tickerRedirect')
    }
  }, [basicInfo.groupId])

  // Render
  const renderContentPagination = () => {
    return (
      <span className={style.spanTableColor}>
        {filterData.timeRange === calendarTabKey ? (
          I18n.t(
            'market.marketInDepth.directorDeal.TABLE_HEAD_TEXT_DATA_CALCULATE_FROM',
            {
              startDate: formatDateTime(
                filterData.dateFrom,
                FORMAT.DATE,
                locale,
                timeZone,
              ),
              endDate: formatDateTime(
                filterData.dateTo,
                FORMAT.DATE,
                locale,
                timeZone,
              ),
            },
          )
        ) : (
          <Translate
            value={'market.marketInDepth.directorDeal.TABLE_HEAD_TEXT'}
          />
        )}
      </span>
    )
  }

  return (
    <div
      style={{
        height: '100%',
        backgroundColor: '#1e242e',
        borderRadius: '4px',
        overflow: 'hidden',
        boxSizing: 'border-box',
      }}
      id={schemas.DIRECTOR_DEAL_TABLE_ID}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Filter width={width} />
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <Table
                    ids={dealIds}
                    getDataFromRedux={selectDealValue}
                    isLoading={isLoading}
                    schema={schemas.directorDealSchema()}
                    sort={sort}
                    defaultSort={{ publicDateId: 'desc' }}
                    isLargeHead={true}
                    hasFooter={false}
                    hasPagination={true}
                    currentPage={filterData.page}
                    totalPage={filterData.totalPage}
                    handleChangePage={handleChangePage}
                    handleSortField={handleChangeSort}
                    hasTooltip={false}
                    renderContentPagination={renderContentPagination}
                    stickies={{
                      [indicator.TICKER]: true,
                    }}
                    rowHighlightLines={getRowHighlights()}
                    reCalcWidths={reCalcWidths}
                  />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default Deal
