import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import { SEC_GROUP_INDICATOR } from '../../../common/constants'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import {
  selectIndicatorBySecurities,
  selectIndicatorsLatestPeriod,
} from '../../../store/slice'
import { getListIndicatorLatestPeriod } from '../../../store/thunk'
import { handleTreeIndicators } from '../../helper'
import Category from './Category'
import Metric from './Metric'
import style from './style.module.css'

const categoryDefault = 30

const PopupIndicators = ({ isShow, onClose, parentRef, position, keyword }) => {
  const dispatch = useDispatch()

  const indicators = useSelector(selectIndicatorBySecurities)
  const indicatorLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const [category, setCategory] = useState(categoryDefault)
  const [treeData, setTreeData] = useState({
    category: null,
    metric: null,
  })

  const handleGetIndicatorLatestPeriod = (id, timeFrequency) => {
    if (
      !indicatorLatestPeriod[
        `${id}_${getTimeFrequencyByIndicator({ timeFrequency })}`
      ]
    ) {
      dispatch(
        getListIndicatorLatestPeriod([{ indicatorId: id, timeFrequency }]),
      )
    }
  }

  const handleSetCategory = (value) => {
    setCategory(value)
  }

  useEffect(() => {
    const { data, deepestIndicators } = handleTreeIndicators(
      indicators[SEC_GROUP_INDICATOR.COMPANY],
    )
    setTreeData({
      category: data,
      metric: deepestIndicators,
    })
  }, [indicators])

  useEffect(() => {
    if (keyword) {
      setCategory(null)
    } else {
      setCategory(categoryDefault)
    }
  }, [keyword])

  return (
    <Dropdown
      parentRef={parentRef}
      isShow={isShow}
      dropdownSpace={20}
      position={position || positionDropdown.RIGHT_TOP}
    >
      <HandleClickOutside handleClickOutside={onClose} exceptRef={parentRef}>
        <div className={style.indicatorContainer}>
          <div className={style.modalContent}>
            <Category
              treeData={treeData.category}
              category={category}
              setCategory={handleSetCategory}
              keyword={keyword}
            />
            <Metric
              key={category}
              treeData={treeData.metric}
              category={category}
              keyword={keyword}
              onGetIndicatorLatestPeriod={handleGetIndicatorLatestPeriod}
            />
          </div>
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

PopupIndicators.propTypes = {
  isShow: PropTypes.bool,
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
}

export default PopupIndicators
