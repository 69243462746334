import PropTypes from 'prop-types'
import { memo, useEffect, useState } from 'react'
import { ScrollComponent } from '../ScrollComponent'
import ItemCheckAll from './ItemCheckAll'
import TreeItem from './TreeItem'
import { filterTreeData, getAllIdTree, getAllParentChecked } from './helper'
import style from './index.module.css'
import withWrapperTree from './withWrapperTree'

const Tree = ({
  valueSearch,
  treeData,
  idKey,
  nameKey,
  levelKey,
  doubleClickItem,
  isSearchAbsolute,
  isHaveCheckbox,
  isDefaultCheckAll,
  listChecked,
  setListChecked,
  setListParentChecked,
  isHaveCheckboxAll,
  titleCheckboxAll,
  isActiveSelected,
  isSortKey,
  listHightlight,
  isCheckedWhenClickText,
  listDisabled,
  sortKey,
  defaultOpenLevel1,
  autoHeight,
  autoHeightMin,
  autoHeightMax,
  itemDefaultOpen,
  isTextEllipsis,
  disabledCheckDbClick,
  onClickItem,
  appendStyle,
  isInDropdown,
  zIndexTextEllipsis,
  scrollDivId,
  classItemStyle,
  classCheckboxStyle,
  bgColorScroll,
  isCheckAllByValueSearch,
  customStyle,
}) => {
  const [allId, setAllId] = useState([])
  const [keys, setKeys] = useState([])
  const [filterTree, setFilterTree] = useState({})
  const [isReopen, setIsReopen] = useState(false)

  useEffect(() => {
    if (!isCheckAllByValueSearch) {
      if (isHaveCheckbox && allId.length === 0) {
        setAllId(getAllIdTree(treeData, idKey))
      }
    } else {
      if (isHaveCheckbox) {
        setAllId(getAllIdTree(filterTree, idKey, !!valueSearch))
      }
    }
  }, [
    JSON.stringify(treeData),
    isCheckAllByValueSearch,
    JSON.stringify(filterTree),
  ])

  useEffect(() => {
    if (isHaveCheckbox && isDefaultCheckAll) {
      setListChecked(allId)
    }
  }, [JSON.stringify(allId)])

  useEffect(() => {
    if (isHaveCheckbox && setListParentChecked) {
      setListParentChecked(getAllParentChecked(treeData, idKey, listChecked))
    }
  }, [JSON.stringify(listChecked), JSON.stringify(treeData)])

  useEffect(() => {
    setKeys(
      isSortKey
        ? Object.keys(treeData).sort()
        : sortKey
        ? Object.keys(treeData).sort((id1, id2) => {
            return treeData[id1][sortKey] - treeData[id2][sortKey]
          })
        : Object.keys(treeData),
    )
  }, [treeData])

  useEffect(() => {
    setFilterTree(
      filterTreeData(
        treeData,
        nameKey,
        levelKey,
        valueSearch,
        isSearchAbsolute,
        defaultOpenLevel1,
      ),
    )
    setIsReopen(!isReopen)
  }, [valueSearch, treeData])

  return (
    <div className="h-100" id={scrollDivId}>
      <ScrollComponent
        autoHeight={autoHeight}
        autoHeightMin={autoHeightMin}
        autoHeightMax={autoHeightMax}
        bgColor={bgColorScroll}
      >
        {keys.length > 0 && (
          <ul
            className={`${style.ulFirst} ${
              isHaveCheckbox ? style.ulFirstCheckbox : ''
            } ${customStyle}`}
          >
            {isHaveCheckbox && isHaveCheckboxAll && (
              <ItemCheckAll
                titleCheckboxAll={titleCheckboxAll}
                allId={allId}
                listChecked={listChecked}
                setListChecked={setListChecked}
                classItemStyle={classItemStyle}
                classCheckboxStyle={classCheckboxStyle}
              />
            )}
            {keys.map(
              (key) =>
                treeData[key] && (
                  <TreeItem
                    key={key}
                    item={treeData[key]}
                    itemDetail={filterTree[key]}
                    idKey={idKey}
                    nameKey={nameKey}
                    levelKey={levelKey}
                    isParentOpen={true}
                    doubleClickItem={doubleClickItem}
                    isSearchAbsolute={isSearchAbsolute}
                    listChecked={listChecked}
                    setListChecked={setListChecked}
                    isActiveSelected={isActiveSelected}
                    isHaveCheckbox={isHaveCheckbox}
                    listHightlight={listHightlight}
                    isCheckedWhenClickText={isCheckedWhenClickText}
                    listDisabled={listDisabled}
                    isSortKey={isSortKey}
                    sortKey={sortKey}
                    isReopen={isReopen}
                    itemDefaultOpen={itemDefaultOpen}
                    isTextEllipsis={isTextEllipsis}
                    disabledCheckDbClick={disabledCheckDbClick}
                    onClickItem={onClickItem}
                    appendStyle={appendStyle}
                    isInDropdown={isInDropdown}
                    zIndexTextEllipsis={zIndexTextEllipsis}
                    classItemStyle={classItemStyle}
                    classCheckboxStyle={classCheckboxStyle}
                  />
                ),
            )}
          </ul>
        )}
      </ScrollComponent>
    </div>
  )
}

Tree.propTypes = {
  valueSearch: PropTypes.string,
  idKey: PropTypes.string,
  nameKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  levelKey: PropTypes.string,
  doubleClickItem: PropTypes.func,
  isSearchAbsolute: PropTypes.bool,
  treeData: PropTypes.object.isRequired,
  isHaveCheckbox: PropTypes.bool,
  isDefaultCheckAll: PropTypes.bool,
  listChecked: PropTypes.array,
  setListChecked: PropTypes.func,
  setListParentChecked: PropTypes.func,
  isHaveCheckboxAll: PropTypes.bool,
  titleCheckboxAll: PropTypes.string,
  isActiveSelected: PropTypes.bool,
  isSortKey: PropTypes.bool,
  listHightlight: PropTypes.array,
  isCheckedWhenClickText: PropTypes.bool,
  listDisabled: PropTypes.object,
  sortKey: PropTypes.string,
  defaultOpenLevel1: PropTypes.bool,
  autoHeight: PropTypes.bool,
  autoHeightMax: PropTypes.number,
  autoHeightMin: PropTypes.number,
  itemDefaultOpen: PropTypes.array,
  isTextEllipsis: PropTypes.bool,
  disabledCheckDbClick: PropTypes.bool,
  zIndexTextEllipsis: PropTypes.number,
  scrollDivId: PropTypes.string,
  isCheckAllByValueSearch: PropTypes.bool,
  customStyle: PropTypes.string,
}

Tree.defaultProps = {
  valueSearch: '',
  isSearchAbsolute: false,
  isDefaultCheckAll: false,
  isHaveCheckboxAll: false,
  titleCheckboxAll: '',
  isActiveSelected: false,
  isHaveCheckbox: false,
  isSortKey: false,
  listHightlight: [],
  isCheckedWhenClickText: false,
  listDisabled: {},
  defaultOpenLevel1: false,
  autoHeight: false,
  autoHeightMax: 0,
  autoHeightMin: 0,
  isTextEllipsis: true,
  disabledCheckDbClick: false,
  isCheckAllByValueSearch: false,
  customStyle: '',
}

export default withWrapperTree(memo(Tree))
