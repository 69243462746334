import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { getFontSize } from '../../../../../../utils/FontSize'
import { ChartContainer } from '../../../common/ChartContainer'
import { PieChart } from '../../../common/PieChart'
import { chartFillColor } from './constants'
import { selectChartData, selectChartLoading } from './store/slice'

export const ChartComponent = ({ width, height }) => {
  const fontSize = getFontSize(12)

  // Use selector
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)

  // Use state
  const [chartPieKeys, setChartPieKeys] = useState([])
  const [chartMappingDisplayName, setChartMappingDisplayName] = useState({})

  // Get data
  const getData = useMemo(() => {
    if (!data.length) {
      return
    }

    const objData = {}
    const objName = {}
    ;[...data]
      .sort((a, b) => b.fairValuePercentage - a.fairValuePercentage)
      .forEach((item, index) => {
        if (index < 5) {
          objName[item.ticker.toLowerCase()] = item.ticker
        }
        objData[item.ticker.toLowerCase()] = item.fairValuePercentage
      })

    setChartPieKeys([...Object.keys(objData)])
    setChartMappingDisplayName(objName)

    return objData
  }, [data])

  return (
    <div id="fvtplDecompositionChart" style={{ height: height || 0 }}>
      <ChartContainer
        id="fvtplDecompositionChartContainer"
        data={getData ? [getData] : []}
        height={height}
        width={width}
        topLabel="sector.financialAnalysis.security.assetStructure.PORTFOLIO_STRUCTURE"
        isLoading={loading}
      >
        {(resizeSize) => (
          <PieChart
            data={getData}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            pieKeys={chartPieKeys}
            fontSize={fontSize}
            mappingDisplayName={chartMappingDisplayName}
            isFooterColumn
          />
        )}
      </ChartContainer>
    </div>
  )
}
