import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import styles from './index.module.css'
import Content from './outstandingBond/index'

const OutStandingBons = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title="bond.overview.outStandingBons.TITLE"
      windowClass={styles.container}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      isDownloadJpg
      downloadJpgParams={{
        domId: 'FDIoutStandingBons',
        nameScreen: I18n.t('bond.overview.primaryMarket.TITLE'),
        chartName: I18n.t('bond.overview.outStandingBons.TITLE'),
        tickerName: I18n.t('ABC'),
      }}
    >
      <Content
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default OutStandingBons
