import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PanelExportImportTable } from './exportImportTable/PanelExportImportTable'
import { PanelExportStructure } from './exportStructure/PanelExportStructure'
import { PanelExportTurnOver } from './exportTurnOver/PanelExportTurnOver'

const MAP_KEY = {
  EXPORT_IMPORT_COUNTRY: 'EXPORT IMPORT COUNTRY',
  EXPORT_TURN_OVER: 'EXPORT TURN OVER',
  EXPORT_STRUCTURE: 'EXPORT STRUCTURE',
}

const MIN_SIZE = {
  [`${MAP_KEY.EXPORT_IMPORT_COUNTRY}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.EXPORT_TURN_OVER}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.EXPORT_STRUCTURE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.EXPORT_IMPORT_COUNTRY}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_IMPORT_COUNTRY}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_IMPORT_COUNTRY}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.EXPORT_TURN_OVER,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.EXPORT_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXPORT_TURN_OVER}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_TURN_OVER}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_TURN_OVER}`].minHeight,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.EXPORT_STRUCTURE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.EXPORT_STRUCTURE}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_STRUCTURE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_STRUCTURE}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelExportImportTable
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_IMPORT_COUNTRY}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelExportTurnOver
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_TURN_OVER}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelExportStructure
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_STRUCTURE}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
