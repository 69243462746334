import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { AnalyticNews } from '../analyticNews'
import { CorporateAnnouncement } from '../corporateAnnouncement'
import { Latest } from '../latest'

const MAP_KEY = {
  LATEST: 'LATEST',
  ANALYTIC_NEWS: 'ANALYTIC_NEWS',
  CORPORATE_ANNOUNCEMENT: 'CORPORATE_ANNOUNCEMENT',
}

const MAP_SIZE = {
  [MAP_KEY.LATEST]: {
    width: `calc(300% / 4 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: 750,
    minHeight: 200,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.ANALYTIC_NEWS]: {
    width: `calc(100% / 4 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(300% / 4 + ${COMPONENT.MARGIN}px)`,
    minWidth: 750 / 3,
    minHeight: 600 + COMPONENT.MARGIN * 2,
    minTop: 0,
    minLeft: 750 + COMPONENT.MARGIN * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.CORPORATE_ANNOUNCEMENT]: {
    width: `calc(300% / 4 - ${COMPONENT.MARGIN}px)`,
    height: `calc(200% / 3 - ${COMPONENT.MARGIN}px)`,
    top: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: 750,
    minHeight: 400,
    minTop: 200 + COMPONENT.MARGIN * 2,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const List = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title={'corporate.news.LATEST_NEWS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.LATEST}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Latest />
      </Panel>
      <Panel
        title={'corporate.news.ANALYTIC_NEWS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ANALYTIC_NEWS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <AnalyticNews />
      </Panel>
      <Panel
        title={'corporate.news.CORPORATE_ANNOUNCEMENT'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CORPORATE_ANNOUNCEMENT}
        sizes={sizes}
        setSizes={setSizes}
      >
        <CorporateAnnouncement
          width={
            formatNumber(sizes[MAP_KEY.CORPORATE_ANNOUNCEMENT].width) -
            PANEL_PADDING
          }
        />
      </Panel>
    </>
  )
}

List.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(List)
