import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import {
  get6MonthByEndDate,
  get6MonthByStartDate,
  getFirstDayOfMonth,
  getLastDayNext6Month,
  RANGE_MONTH,
} from '../helper'
import {
  changeSelectedDate,
  getUpcomingStartMonth,
  handleUpcomingStartMonth,
  selectEndDate,
  selectStartDate,
} from '../store/slice'
import style from '../style.module.css'

const HeaderPanel = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const upcomingStartMonth = useSelector(getUpcomingStartMonth)
  const startDate = useSelector(selectStartDate)
  const endDate = useSelector(selectEndDate)

  const initStartDate = formatDateTime(
    getFirstDayOfMonth(upcomingStartMonth),
    FORMAT.DATE,
    locale,
    timeZone,
  )

  const initEndDate = formatDateTime(
    getLastDayNext6Month(upcomingStartMonth),
    FORMAT.DATE,
    locale,
    timeZone,
  )

  const decreasePage = () => {
    if (startDate && endDate) {
      const sixMonths = get6MonthByStartDate(startDate)
      dispatch(changeSelectedDate(sixMonths))
    } else {
      dispatch(handleUpcomingStartMonth(upcomingStartMonth - RANGE_MONTH))
    }
  }

  const increasePage = () => {
    if (startDate && endDate) {
      const sixMonths = get6MonthByEndDate(endDate)
      dispatch(changeSelectedDate(sixMonths))
    } else {
      dispatch(handleUpcomingStartMonth(upcomingStartMonth + RANGE_MONTH))
    }
  }

  const getHeaderTitle = () => {
    if (startDate && endDate) {
      return `${formatDateTime(
        startDate,
        FORMAT.DATE,
        locale,
        timeZone,
      )} - ${formatDateTime(endDate, FORMAT.DATE, locale, timeZone)}`
    }

    return `${initStartDate} - ${initEndDate}`
  }

  return (
    <div className={style.topUpcomingEvent}>
      <div className={style.btnBack} onClick={decreasePage}>
        <i className={`icon-arrow-left`} />
      </div>
      <div>
        <Span style={{ fontSize: 21 }}>{getHeaderTitle()}</Span>
      </div>
      <div className={style.btnNext} onClick={increasePage}>
        <i className={`icon-arrow-right`} />
      </div>
    </div>
  )
}

export default HeaderPanel
