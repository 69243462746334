import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { getWatchListThunk } from './thunk'

export const keys = {
  WATCHLIST: 'watchlist',
}

const initialState = {
  loading: {
    [keys.WATCHLIST]: true,
  },
  data: {
    watchlist: [],
  },
}

export const slice = createSlice({
  name: 'dataExplorer/common',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    // get exchange
    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loading[keys.WATCHLIST] = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.data.watchlist = action.payload || []
      state.loading[keys.WATCHLIST] = false
    })
    builder.addCase(getWatchListThunk.rejected, (state) => {
      state.loading[keys.WATCHLIST] = false
    })
  },
})

export const { resetStore } = slice.actions

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectWatchlist = (state) => state[slice.name].data.watchlist

register(slice.name, slice.reducer)
