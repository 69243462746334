import { SizeTracker } from '../../../../common/sizeTracker'
import { Filters } from './filters'
import Table from './table'

export const PortfolioTable = ({ width, height }) => {
  return (
    <SizeTracker>
      {(size) => (
        <>
          <Filters />
          {size.height && (
            <div style={{ height: height - size.height }}>
              <Table />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
