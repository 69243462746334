import { useSelector } from 'react-redux'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import style from '../index.module.css'
import GDPCompositionRightChild from './GDPCompositionRightChild'

export const GDP_COMPOSITION_RIGHT_CHILD = 'GDPCompositionRightChild'

const PanelGDPCompositionRightChild = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const basicInfo = useSelector(selectBasicInfo)
  const nameScreen = UseI18n('constants.route.economy.gdp.totalGDP.LABEL')
  const chartName = UseI18n('economy.gdp.gdp.GDP_COMPOSITION_BY_SECTOR')
  const tickerName = basicInfo.ticker

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: GDP_COMPOSITION_RIGHT_CHILD,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <GDPCompositionRightChild
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelGDPCompositionRightChild
