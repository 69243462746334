import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import style from '../../index.module.css'
import {
  checkAllAddMoreCondition,
  removeAddMoreCondition,
  selectAddMoreConditionByIds,
  uncheckAllAddMoreCondition,
} from '../../store/slice'

const HeaderTable = () => {
  const dispatch = useDispatch()

  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)

  const handleCheckAll = () => dispatch(checkAllAddMoreCondition())

  const handleUncheckAll = () => dispatch(uncheckAllAddMoreCondition())

  const handleRemove = () => dispatch(removeAddMoreCondition())

  const isCheckAll =
    Object.keys(addMoreConditionByIds).length > 0 &&
    Object.keys(addMoreConditionByIds).every(
      (id) => addMoreConditionByIds[id].checked,
    )
  const isHaveChecked = Object.keys(addMoreConditionByIds).some(
    (id) => addMoreConditionByIds[id].checked,
  )

  return (
    <div className={`col-header ${style.h37px}`}>
      <h6>
        <Translate value="tool.smartScreening.SELECTED_ITEM" />
      </h6>
      <div className="group-action">
        {!isCheckAll ? (
          <label
            className={`d-flex justify-content-center ${style.checkboxAll}`}
          >
            <input
              type="checkbox"
              className={`checkbox line ${style.inputCheckAll}`}
              checked={isCheckAll}
              onClick={handleCheckAll}
              readOnly
            />
          </label>
        ) : (
          <div className={`${style.btnMinus}`}>
            <button onClick={handleUncheckAll} className={`${style.pr6px}`}>
              <i className={`icon-minus ${style.fontSize12}`} />
            </button>
          </div>
        )}
        <button
          className={isHaveChecked ? '' : style.disabled}
          onClick={handleRemove}
        >
          <i className="icon-trash" />
        </button>
      </div>
    </div>
  )
}

export default HeaderTable
