export const TIME_RANGE = {
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
  SIX_MONTHS: 'SixMonths',
  NINE_MONTHS: 'NineMonths',
  ONE_YEAR: 'OneYear',
  THREE_YEARS: 'ThreeYears',
  FIVE_YEARS: 'FiveYears',
  TEN_YEARS: 'TenYears',
  ALL_TIME: 'AllTime',
  MONTH_TO_DATE: 'MonthToDate',
  QUARTER_TO_DATE: 'QuarterToDate',
  YEAR_TO_DATE: 'YearToDate',
}

export const listTimeRangeMapKey = {
  [TIME_RANGE.ONE_MONTH]: '1M',
  [TIME_RANGE.THREE_MONTHS]: '3M',
  [TIME_RANGE.SIX_MONTHS]: '6M',
  [TIME_RANGE.NINE_MONTHS]: '9M',
  [TIME_RANGE.ONE_YEAR]: '1Y',
  [TIME_RANGE.THREE_YEARS]: '3Y',
  [TIME_RANGE.FIVE_YEARS]: '5Y',
  [TIME_RANGE.TEN_YEARS]: '10Y',
  [TIME_RANGE.ALL_TIME]: 'All',
  [TIME_RANGE.MONTH_TO_DATE]: 'MTD',
  [TIME_RANGE.QUARTER_TO_DATE]: 'QTD',
  [TIME_RANGE.YEAR_TO_DATE]: 'YTD',
}

export const listTimeRange = [
  { name: '3M', value: TIME_RANGE.THREE_MONTHS },
  { name: '6M', value: TIME_RANGE.SIX_MONTHS },
  { name: '1Y', value: TIME_RANGE.ONE_YEAR },
  { name: '3Y', value: TIME_RANGE.THREE_YEARS },
  { name: '5Y', value: TIME_RANGE.FIVE_YEARS },
  { name: 'financialChart.ALL', value: TIME_RANGE.ALL_TIME },
  { name: 'MTD', value: TIME_RANGE.MONTH_TO_DATE },
  { name: 'QTD', value: TIME_RANGE.QUARTER_TO_DATE },
  { name: 'YTD', value: TIME_RANGE.YEAR_TO_DATE },
]
