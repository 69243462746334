import { createSlice } from '@reduxjs/toolkit'
import { orderBy, sortBy, uniq } from 'lodash'
import moment from 'moment'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import { formatDataTable } from '../helper'
import {
  getAverageCouponBySectorData,
  getAverageDurationBySectorData,
  getIssuanceValueByCouponData,
  getIssuanceValueBySectorData,
  getIssuanceValueData,
} from './thunk'

export const keys = {
  ISSUANCE_VALUE: 'issuanceValue',
  AVERAGE_COUPON_BY_SECTOR: 'averageCouponBySector',
  ISSUANCE_VALUE_BY_SECTOR: 'issuanceValueBySector',
  ISSUANCE_VALUE_BY_COUPON: 'issuanceValueByCouponType',
  AVERAGE_DURATION_BY_SECTOR: 'averageDurationBySector',
}

const initialState = {
  loading: {
    [keys.ISSUANCE_VALUE]: true,
    [keys.AVERAGE_COUPON_BY_SECTOR]: true,
    [keys.ISSUANCE_VALUE_BY_SECTOR]: true,
    [keys.ISSUANCE_VALUE_BY_COUPON]: true,
    [keys.AVERAGE_DURATION_BY_SECTOR]: true,
  },
  activeTab: {
    methodType: 'All',
    timeType: TIME_FREQUENCY.MONTHLY,
  },
  data: {
    [keys.ISSUANCE_VALUE]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
    },
    [keys.AVERAGE_COUPON_BY_SECTOR]: {
      averageCoupon: [],
      averageCouponBySector: [],
    },
    [keys.ISSUANCE_VALUE_BY_SECTOR]: [],
    [keys.ISSUANCE_VALUE_BY_COUPON]: [],
    [keys.AVERAGE_DURATION_BY_SECTOR]: {
      industryAverageByIndustry: [],
      industryAverageIndustry: [],
    },
  },
  filter: {
    [keys.ISSUANCE_VALUE]: {
      month: new Date().getMonth() + 1,
      quarter: Math.floor((new Date().getMonth() + 3) / 3),
      year: +getCurrentDateTime(FORMAT.YEAR),
    },
    [keys.AVERAGE_COUPON_BY_SECTOR]: {
      month: new Date().getMonth() + 1,
      quarter: Math.floor((new Date().getMonth() + 3) / 3),
      year: +getCurrentDateTime(FORMAT.YEAR),
      duration: 'All',
      list: [],
      averageList: [],
    },
    [keys.ISSUANCE_VALUE_BY_SECTOR]: {
      duration: 'All',
      list: [],
    },
    [keys.ISSUANCE_VALUE_BY_COUPON]: {
      month: new Date().getMonth() + 1,
      quarter: Math.floor((new Date().getMonth() + 3) / 3),
      year: +getCurrentDateTime(FORMAT.YEAR),
    },
    [keys.AVERAGE_DURATION_BY_SECTOR]: {
      month: new Date().getMonth() + 1,
      quarter: Math.floor((new Date().getMonth() + 3) / 3),
      year: +getCurrentDateTime(FORMAT.YEAR),
      list: [],
      averageList: [],
    },
  },
}

export const slice = createSlice({
  name: 'bond/corporateBond/primaryMarket',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeActiveMethodType(state, actions) {
      state.activeTab = {
        ...state.activeTab,
        methodType: actions.payload,
      }
    },
    changeActiveTimeType(state, actions) {
      state.activeTab = {
        ...state.activeTab,
        timeType: actions.payload,
      }
    },
    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } =
        state.data[keys.ISSUANCE_VALUE]

      const idsFromProps = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      ).filter((item) => typeof item === 'number')

      const newIds = []

      idsFromProps.forEach((item) => {
        newIds.push(item)

        const idsByParent = initialIds.filter(
          (id) =>
            typeof id === 'string' && parseInt(id.split('-')?.[1]) === item,
        )

        idsByParent.forEach((id) => newIds.push(id))
      })

      state.data[keys.ISSUANCE_VALUE] = {
        ...state.data[keys.ISSUANCE_VALUE],
        ids: newIds,
      }
    },
    setRowsCollapse: (state, action) => {
      state.data[keys.ISSUANCE_VALUE] = {
        ...state.data[keys.ISSUANCE_VALUE],
        rowsCollapse: action.payload,
      }
    },
    changeFilter: (state, action) => {
      state.filter[action.payload.label] = {
        ...state.filter[action.payload.label],
        [action.payload.key]: action.payload.value,
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getIssuanceValueData.pending, (state) => {
      state.loading[keys.ISSUANCE_VALUE] = true
    })
    builder.addCase(getIssuanceValueData.fulfilled, (state, action) => {
      const data = formatDataTable(action.payload.sectors)

      state.data[keys.ISSUANCE_VALUE] = {
        ...state.data[keys.ISSUANCE_VALUE],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
      }
      state.loading[keys.ISSUANCE_VALUE] = false
    })
    builder.addCase(getIssuanceValueData.rejected, (state, action) => {
      state.loading[keys.ISSUANCE_VALUE] = action.payload
    })
    builder.addCase(getAverageCouponBySectorData.pending, (state) => {
      state.loading[keys.AVERAGE_COUPON_BY_SECTOR] = true
    })
    builder.addCase(getAverageCouponBySectorData.fulfilled, (state, action) => {
      const { data, timeType } = action.payload

      const newAverageCouponBySector =
        timeType !== TIME_FREQUENCY.YEARLY
          ? orderBy(
              data.averageCouponBySector,
              (item) => {
                const dates = item.date.split('-')

                return !!dates?.length
                  ? timeType === TIME_FREQUENCY.QUARTER
                    ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                    : moment(item.date, 'MM-YYYY')
                  : item.date
              },
              'asc',
            )
          : sortBy(data.averageCouponBySector, 'date')

      const newAverageCoupon =
        timeType !== TIME_FREQUENCY.YEARLY
          ? orderBy(
              data.averageCoupon,
              (item) => {
                const dates = item.date.split('-')

                return !!dates?.length
                  ? timeType === TIME_FREQUENCY.QUARTER
                    ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                    : moment(item.date, 'MM-YYYY')
                  : item.date
              },
              'asc',
            )
          : sortBy(data.averageCoupon, 'date')

      const averageCouponBySector = newAverageCouponBySector.map((item) => ({
        ...item,
        averageCouponRate: item.averageCouponRate * 100,
      }))
      const averageCoupon = newAverageCoupon.map((item) => ({
        ...item,
        averageCouponRate: item.averageCouponRate * 100,
      }))
      state.data[keys.AVERAGE_COUPON_BY_SECTOR] = {
        averageCouponBySector,
        averageCoupon,
      }
      state.filter[keys.AVERAGE_COUPON_BY_SECTOR] = {
        ...state.filter[keys.AVERAGE_COUPON_BY_SECTOR],
        list: uniq(averageCouponBySector.map((item) => item.date)),
        averageList: uniq(averageCoupon.map((item) => item.date)),
      }
      state.loading[keys.AVERAGE_COUPON_BY_SECTOR] = false
    })
    builder.addCase(getAverageCouponBySectorData.rejected, (state, action) => {
      state.loading[keys.AVERAGE_COUPON_BY_SECTOR] = action.payload
    })
    builder.addCase(getIssuanceValueBySectorData.pending, (state) => {
      state.loading[keys.ISSUANCE_VALUE_BY_SECTOR] = true
    })
    builder.addCase(getIssuanceValueBySectorData.fulfilled, (state, action) => {
      const { data, timeType } = action.payload

      const newData =
        timeType === TIME_FREQUENCY.QUARTER
          ? orderBy(
              data,
              (item) => {
                const dates = item.dateCheck.split('-')

                return !!dates?.length
                  ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                  : item.dateCheck
              },
              'asc',
            )
          : sortBy(data, 'dateCheck')
      state.data[keys.ISSUANCE_VALUE_BY_SECTOR] = newData
      state.filter[keys.ISSUANCE_VALUE_BY_SECTOR] = {
        ...state.filter[keys.ISSUANCE_VALUE_BY_SECTOR],
        list: uniq(newData.map((item) => item.dateCheck)),
      }
      state.loading[keys.ISSUANCE_VALUE_BY_SECTOR] = false
    })
    builder.addCase(getIssuanceValueBySectorData.rejected, (state, action) => {
      state.loading[keys.ISSUANCE_VALUE_BY_SECTOR] = action.payload
    })
    builder.addCase(getIssuanceValueByCouponData.pending, (state) => {
      state.loading[keys.ISSUANCE_VALUE_BY_COUPON] = true
    })
    builder.addCase(getIssuanceValueByCouponData.fulfilled, (state, action) => {
      state.data[keys.ISSUANCE_VALUE_BY_COUPON] = orderBy(
        action.payload,
        'rate',
        'desc',
      )
      state.loading[keys.ISSUANCE_VALUE_BY_COUPON] = false
    })
    builder.addCase(getIssuanceValueByCouponData.rejected, (state, action) => {
      state.loading[keys.ISSUANCE_VALUE_BY_COUPON] = action.payload
    })
    builder.addCase(getAverageDurationBySectorData.pending, (state) => {
      state.loading[keys.AVERAGE_DURATION_BY_SECTOR] = true
    })
    builder.addCase(
      getAverageDurationBySectorData.fulfilled,
      (state, action) => {
        const { data, timeType } = action.payload

        const newIndustryAverageByIndustry =
          timeType !== TIME_FREQUENCY.YEARLY
            ? orderBy(
                data.industryAverageByIndustry,
                (item) => {
                  const dates = item.dateCheck.split('-')

                  return !!dates?.length
                    ? timeType === TIME_FREQUENCY.QUARTER
                      ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                      : moment(item.dateCheck, 'MM-YYYY')
                    : item.dateCheck
                },
                'asc',
              )
            : sortBy(data.industryAverageByIndustry, 'dateCheck')

        const newIndustryAverageIndustry =
          timeType !== TIME_FREQUENCY.YEARLY
            ? orderBy(
                data.industryAverageIndustry,
                (item) => {
                  const dates = item.date.split('-')

                  return !!dates?.length
                    ? timeType === TIME_FREQUENCY.QUARTER
                      ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                      : moment(item.date, 'MM-YYYY')
                    : item.date
                },
                'asc',
              )
            : sortBy(data.industryAverageIndustry, 'date')

        state.data[keys.AVERAGE_DURATION_BY_SECTOR] = {
          industryAverageByIndustry: newIndustryAverageByIndustry,
          industryAverageIndustry: newIndustryAverageIndustry,
        }
        state.filter[keys.AVERAGE_DURATION_BY_SECTOR] = {
          ...state.filter[keys.AVERAGE_DURATION_BY_SECTOR],
          list: uniq(
            newIndustryAverageByIndustry.map((item) => item.dateCheck),
          ),
          averageList: uniq(
            newIndustryAverageIndustry.map((item) => item.date),
          ),
        }
        state.loading[keys.AVERAGE_DURATION_BY_SECTOR] = false
      },
    )
    builder.addCase(
      getAverageDurationBySectorData.rejected,
      (state, action) => {
        state.loading[keys.AVERAGE_DURATION_BY_SECTOR] = action.payload
      },
    )
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]
export const selectActiveMethodType = (state) =>
  state[slice.name].activeTab.methodType
export const selectActiveTimeType = (state) =>
  state[slice.name].activeTab.timeType

// Issuance Value
export const selectFilterIssuanceValueType = (state) =>
  state[slice.name].filter[keys.ISSUANCE_VALUE]
export const selectIssuanceValueData = (state) =>
  state[slice.name].data[keys.ISSUANCE_VALUE]
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.ISSUANCE_VALUE].dataById[id],
    attr,
  )

// Average Coupon By Sector
export const selectFilterAverageCouponBySectorType = (state) =>
  state[slice.name].filter[keys.AVERAGE_COUPON_BY_SECTOR]
export const selectAverageCouponBySectorData = (state) =>
  state[slice.name].data[keys.AVERAGE_COUPON_BY_SECTOR]

// Issuance Value By Sector
export const selectFilterIssuanceValueBySectorType = (state) =>
  state[slice.name].filter[keys.ISSUANCE_VALUE_BY_SECTOR]
export const selectIssuanceValueBySectorData = (state) =>
  state[slice.name].data[keys.ISSUANCE_VALUE_BY_SECTOR]

// Issuance Value By Coupon Type
export const selectFilterIssuanceValueByCouponType = (state) =>
  state[slice.name].filter[keys.ISSUANCE_VALUE_BY_COUPON]
export const selectIssuanceValueByCouponData = (state) =>
  state[slice.name].data[keys.ISSUANCE_VALUE_BY_COUPON]

// Average Duration By Sector
export const selectFilterAverageDurationBySector = (state) =>
  state[slice.name].filter[keys.AVERAGE_DURATION_BY_SECTOR]
export const selectAverageDurationBySectorData = (state) =>
  state[slice.name].data[keys.AVERAGE_DURATION_BY_SECTOR]

export const {
  resetStore,
  changeActiveMethodType,
  changeActiveTimeType,
  changeFilter,
  setRowsCollapse,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
