import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { changeIsFullNode, selectIsFullNode } from '../store/slice'
import { getClassName, isTooTiny } from './helper'
import style from './sectorHeader.module.css'

export const SectorHeader = ({
  parentRate,
  isHover,
  title,
  width,
  height,
  changeFullNode,
  hiddenHover,
}) => {
  const dispatch = useDispatch()
  const isFullNode = useSelector(selectIsFullNode)
  const getSectorTitleBgColor = (value) => {
    if (value < 0) {
      return '#b6434a'
    }

    if (value > 0) {
      return '#187a64'
    }

    return '#f3b662'
  }

  if (parentRate === null || isTooTiny(width, height)) {
    return null
  }

  return (
    <div
      className={getClassName(
        style.sectorTitle,
        style.sectorHoverTitle,
        isHover,
      )}
      style={{ backgroundColor: getSectorTitleBgColor(parentRate) }}
    >
      <div className={style.container}>
        <span className={style.text}>{title}</span>
        {isHover && (
          <a
            className={['cursor-pointer', style.icon].join(' ')}
            onClick={(e) => {
              e.stopPropagation()
              if (isFullNode) {
                dispatch(changeIsFullNode(false))
                changeFullNode(false)
                hiddenHover()
              } else {
                dispatch(changeIsFullNode(true))
                changeFullNode(true)
              }
            }}
          >
            {!isFullNode && <i className="icon-window" />}
            {isFullNode && <i className="icon-minus" />}
          </a>
        )}
      </div>

      <div className={style.arrowDown}>
        <div
          className={getClassName(
            style.triangleWrapper,
            style.sectorHoverTriangleWrapper,
            isHover,
          )}
        >
          <div
            className={getClassName(
              style.triangle,
              style.sectorHoverTriangle,
              isHover,
            )}
            style={{ borderTopColor: getSectorTitleBgColor(parentRate) }}
          />
        </div>
      </div>
    </div>
  )
}

SectorHeader.propTypes = {
  parentRate: PropTypes.number.isRequired,
  isHover: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  changeFullNode: PropTypes.func.isRequired,
  hiddenHover: PropTypes.func.isRequired,
}
