import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION, MARGIN } from '../../../common/chart/constants'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../common/chart/helper'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal } from '../../../utils/Value'
import {
  BLUE_COLOR,
  NOT_QUARTER_CONST,
  YELLOW_COLOR,
} from '../common/constants'
import style from '../index.module.css'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

export const ChartComponent = ({
  height,
  width,
  data,
  keyXAxis,
  barKey,
  lineKey,
  currentQuarter,
}) => {
  const chartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {I18n.t('market.marginLoans.LEVERAGE_RATIO')}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {valueTooltip[lineKey]
                    ? `${formatVal(valueTooltip[lineKey])}${
                        valueTooltip[lineKey] ? '%' : ''
                      }`
                    : EMPTY_VALUE}
                </Span>
              </td>
            </tr>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {I18n.t('market.marginLoans.MARGIN')}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {valueTooltip[barKey]
                    ? `${formatVal(valueTooltip[barKey])} ${I18n.t(
                        'market.marginLoans.BILLION',
                      )}`
                    : EMPTY_VALUE}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                margin={MARGIN}
                xTickNum={SETTINGS.xTickNum}
                hasBarChart
                yAxis={[
                  {
                    id: 'left',
                    orientation: 'left',
                    keys: [lineKey],
                    isBarChart: false,
                    tickNum: SETTINGS.yTickNum,
                    label: I18n.t('market.marginLoans.LEVERAGE_RATIO_ADJ'),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                  },
                  {
                    id: 'right',
                    orientation: 'right',
                    keys: [barKey],
                    isBarChart: true,
                    tickNum: SETTINGS.yTickNum,
                    label: I18n.t('market.marginLoans.MARGIN_BILLION'),
                    labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  },
                ]}
                renderCustomTooltip={(payload) => chartTooltip(payload)}
                isNotFormatXAxis={currentQuarter !== NOT_QUARTER_CONST}
                timeFrame={
                  currentQuarter !== NOT_QUARTER_CONST && TIME_RANGES.CUSTOM
                }
              >
                <Bar
                  yAxisId="right"
                  dataKey={barKey}
                  barSize={getColumnSizeInBarChart(width, data.length, 25)}
                  isAnimationActive={false}
                  fill={BLUE_COLOR}
                />
                <Line
                  yAxisId="left"
                  dataKey={lineKey}
                  type="linear"
                  strokeWidth={2}
                  fill={YELLOW_COLOR}
                  stroke={YELLOW_COLOR}
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'market.marginLoans.LEVERAGE_RATIO',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: YELLOW_COLOR,
                  },
                },
                {
                  text: 'market.marginLoans.MARGIN',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BLUE_COLOR,
                  },
                },
              ]}
            />
          </>
        )}
      </SizeTracker>
    )
  )
}
