import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'
import { handleExport } from '../../../../../../utils/Export'

export const getSteelCoilStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/steelCoil/steelCoilStatistics',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getSteelCoilStatistics(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadSteelCoilStatistic = createAsyncThunk(
  'sector/sectorSpecific/steel/steelCoil/downloadSteelCoilStatistic',
  async (data) => {
    const response = await SteelService.getDownloadSteelCoilStatistic(data)
    handleExport(response.data, response.filename)
  },
)
