import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../core/services/corporate/PenetrationAnalysisService'
import { handleExport } from '../../../../../utils/Export'

export const getInsuMaxPeriod = createAsyncThunk(
  'corporate/businessModel/penetration/insurance/getInsuMaxPeriod',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getInsuMaxPeriod(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsuRevenue = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/getInsuRevenue',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PenetrationAnalysisService.getInsuRevenue(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsuMarketShare = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/getInsuMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getInsuMarketShare(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsuChangeInMarketShare = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/getInsuChangeInMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getInsuChangeInMarketShare(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsuClaimRatio = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/getInsuClaimRatio',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getInsuClaimRatio(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getInsuDetailMetrics = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/getInsuDetailMetrics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getInsuDetailMetrics(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadInsuDetailMetrics = createAsyncThunk(
  'corporate//businessModel/penetration/insurance/downloadInsuDetailMetrics',
  async (data) => {
    const response = await PenetrationAnalysisService.downloadInsuDetailMetrics(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
