import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import PanelFilter from '../../../../common/panelFilter'
import { withWrapper } from '../../../../common/withWrapper'
import Filter from './Filter'
import PanelBrokerageMarketShare from './panelBrokerageMarketShare/PanelBrokerageMarketShare'
import PanelBrokerageRevenueGrowth from './panelBrokerageRevenueGrowth/PanelBrokerageRevenueGrowth'
import PanelChangeInBrokerageMarketShare from './panelChangeInBrokerageMarketShare/PanelChangeInBrokerageMarketShare'
import PanelDetailMetrics from './panelDetailMetrics/PanelDetailMetrics'

const MAP_KEY = {
  FILTER: 'FILTER',
  BROKERAGE_MARKET_SHARE: 'BROKERAGE_MARKET_SHARE',
  BROKERAGE_REVENUE_GROWTH: 'BROKERAGE_REVENUE_GROWTH',
  CHANGE_IN_BROKERAGE_MARKET_SHARE: 'CHANGE_IN_BROKERAGE_MARKET_SHARE',
  DETAIL_METRICS: 'DETAIL_METRICS',
}

const MIN_WIDTH_FILTER = 210

const MIN_SIZE_SETTING = {
  [MAP_KEY.FILTER]: {
    minWidth: MIN_WIDTH_FILTER,
    minHeight: 600,
  },
  [MAP_KEY.BROKERAGE_MARKET_SHARE]: {
    minWidth: 320,
    minHeight: 300,
  },
  [MAP_KEY.BROKERAGE_REVENUE_GROWTH]: {
    minWidth: 480,
    minHeight: 300,
  },
  [MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE]: {
    minWidth: 320,
    minHeight: 300,
  },
  [MAP_KEY.DETAIL_METRICS]: {
    minWidth: 480,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [MAP_KEY.FILTER]: {
    width: MIN_WIDTH_FILTER - COMPONENT.MARGIN,
    height: `100%`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.FILTER],
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.BROKERAGE_MARKET_SHARE,
        ratioLeft: -1,
        ratioWidth: 1,
      },
      {
        key: MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.BROKERAGE_MARKET_SHARE]: {
    width: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.4)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: MIN_WIDTH_FILTER + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_MARKET_SHARE],
    minTop: 0,
    minLeft: MIN_WIDTH_FILTER + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.BROKERAGE_REVENUE_GROWTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.BROKERAGE_REVENUE_GROWTH,
        isResizeWithParent: true,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [
      MAP_KEY.BROKERAGE_MARKET_SHARE,
      MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE,
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [MAP_KEY.BROKERAGE_REVENUE_GROWTH]: {
    width: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.6)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.4 + ${
      MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN
    }px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_REVENUE_GROWTH],
    minTop: 0,
    minLeft:
      MIN_WIDTH_FILTER +
      MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_MARKET_SHARE].minWidth +
      4 * COMPONENT.MARGIN,
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [
      MAP_KEY.BROKERAGE_MARKET_SHARE,
      MAP_KEY.BROKERAGE_REVENUE_GROWTH,
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE]: {
    width: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.4)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: MIN_WIDTH_FILTER + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_MARKET_SHARE].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft: MIN_WIDTH_FILTER + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [
      MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE,
      MAP_KEY.BROKERAGE_MARKET_SHARE,
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [MAP_KEY.DETAIL_METRICS]: {
    width: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.6)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc((100% - ${MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN}px) * 0.4 + ${
      MIN_WIDTH_FILTER + 4 * COMPONENT.MARGIN
    }px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.DETAIL_METRICS],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_REVENUE_GROWTH].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_WIDTH_FILTER +
      MIN_SIZE_SETTING[MAP_KEY.BROKERAGE_MARKET_SHARE].minWidth +
      4 * COMPONENT.MARGIN,
    isOpenCollapse: true,
    isMaxSizeCollapse: false,
    groupCollapse: [
      MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE,
      MAP_KEY.DETAIL_METRICS,
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="corporate.businessModel.penetrationAnalysis.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>
      <PanelBrokerageMarketShare
        panelRefs={panelRefs}
        panelKey={MAP_KEY.BROKERAGE_MARKET_SHARE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelBrokerageRevenueGrowth
        panelRefs={panelRefs}
        panelKey={MAP_KEY.BROKERAGE_REVENUE_GROWTH}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelChangeInBrokerageMarketShare
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHANGE_IN_BROKERAGE_MARKET_SHARE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelDetailMetrics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DETAIL_METRICS}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
