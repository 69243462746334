import { useState } from 'react'
import { useSelector } from 'react-redux'
import PieChart from '../../../../../common/chart/pieChart'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { selectDisplayChartType } from '../store/slice'
import ChartTooltip from './ChartTooltip'
import styles from './index.module.css'

const MIN_HEIGHT = 130

const ChartCell = ({
  setActiveRowId,
  style,
  className,
  data,
  colSpan,
  height,
}) => {
  const tdHeight =
    height / 3.8 < MIN_HEIGHT ? MIN_HEIGHT : Math.round(height / 3.8)
  const chartType = useSelector(selectDisplayChartType)
  const chartData = data?.[chartType]

  const [hovered, setHovered] = useState(null)
  const [zIndex, setZIndex] = useState(2)

  const onMouseOut = () => {
    setActiveRowId(null)
    setZIndex(2)
  }
  const onMouseOver = (data) => {
    setActiveRowId(data?.icbId || null)
    setZIndex(3)
  }

  const renderPieChart = () => {
    return (
      <PieChart
        data={chartData}
        style={{
          fontSize: 12,
          height: tdHeight,
          fill: '#fff',
        }}
        activeSegmentIndex={hovered}
        labelPercent={({ dataEntry }) => Math.round(dataEntry?.percent || 0)}
        renderTooltip={(data) => <ChartTooltip data={data} />}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        labelPosition={110}
        lineWidth={45}
        radius={35}
        animate={false}
        onHoveredChange={setHovered}
      />
    )
  }

  return (
    <td
      colSpan={colSpan}
      style={{
        ...style,
        height: tdHeight + 'px',
        zIndex,
      }}
      className={`${className} ${styles.chartCell}`}
    >
      <div className={styles.divChart}>
        {data && data !== EMPTY_VALUE && renderPieChart()}
      </div>
    </td>
  )
}

export default ChartCell
