import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { selectBasicInfoOverview } from '../store/slice'

export const TickerTh = ({ title }) => {
  const basicInfo = useSelector(selectBasicInfoOverview)
  return (
    <Span style={{ fontSize: 10 }}>
      <Translate value={basicInfo.ticker || title} />
    </Span>
  )
}

TickerTh.propTypes = {
  title: PropTypes.string,
}

TickerTh.defaultProps = {
  title: '',
}
