import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import style from '../index.module.css'
import {
  selectFilterDebtAndLoan,
  selectFilterDebtStatistic,
} from '../store/slice'

const ChartTooltip = ({ data, date }) => {
  const { year, quarter } = useSelector(selectFilterDebtStatistic)
  const activeTab = useSelector(selectFilterDebtAndLoan)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {activeTab === TIME_FREQUENCY.YEARLY ? year : `Q${quarter}-${year}`}
      </Span>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="d-flex ali-center justify-content-space-between"
          >
            <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
              {item.isI18n ? I18n.t(item.text) : item.text}
            </Span>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {`${formatVal(valDivBillion(item.totalValue), 2)} ${I18n.t(
                'bond.corporateBond.issuanceValueByCouponType.BILLION',
              )}, ${formatVal(item.value)}%
              `}
            </Span>
          </div>
        )
      })}
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      totalValue: PropTypes.number,
      value: PropTypes.number,
    }),
  ).isRequired,
}

export default ChartTooltip
