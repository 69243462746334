import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectExchange from '../../common/selectExchange'
import {
  changeExchangeChecked,
  changeFirstMountPopupExchange,
  changeParentExchangeChecked,
  selectExchangeChecked,
  selectFirstMountPopupExchange,
} from '../store/slice'

const SecuritiesExchangeCount = () => {
  const dispatch = useDispatch()

  const equationExchange = useSelector(selectExchangeChecked)
  const isFirstMount = useSelector(selectFirstMountPopupExchange)

  const [keepData, setKeepData] = useState(true)

  const handleChangeFirstMountPopup = (value) => {
    dispatch(changeFirstMountPopupExchange(value))
  }

  const handleChangeParentExchangeChecked = (data) => {
    dispatch(changeParentExchangeChecked(data))
  }

  const handleChangeExchangeChecked = (data) => {
    dispatch(changeExchangeChecked(data))
  }

  useEffect(() => {
    if (!equationExchange) {
      setKeepData(false)
    }
  }, [equationExchange])

  return (
    <SelectExchange
      listChecked={equationExchange?.childrenExchange || []}
      listParentChecked={equationExchange?.parentExchange || []}
      isFirstMount={isFirstMount}
      changeFirstMountPopup={handleChangeFirstMountPopup}
      changeListParentChecked={handleChangeParentExchangeChecked}
      changeExchangeChecked={handleChangeExchangeChecked}
      keepData={keepData}
    />
  )
}

export default SecuritiesExchangeCount
