export const VN_ID = 1

export const GLOBAL_ID = 46

export const NORMAL_FORMAT_ID = [12]

export const TRILLION_FORMAT_ID = [74]

export const PERCENT_FORMAT_ID = [73, 75, 76, 77, 78, 79]

export const EMPTY_CELL_BY_PARENT_ID = [37, 42, 47, 53, 59, 70]

export const EMPTY_CELL_BY_MACRO_ID = [2, 3, 4, 5, 6, 11]
