import { useEffect } from 'react'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { indicatorKeyByTimeFrequency } from '../constants'

export const IndicatorSelectUpdateData = ({
  equationIndicator,
  indicatorLatestPeriod,
  onChangeEquationIndicator,
}) => {
  useEffect(() => {
    const indicatorKey = `${
      equationIndicator.indicatorId
    }_${getTimeFrequencyByIndicator(equationIndicator)}`

    if (indicatorLatestPeriod[indicatorKey]) {
      let isUpdateLatestPeriod = false
      const newEquationIndicator = {
        ...equationIndicator,
        data: Object.keys(equationIndicator.data).reduce((obj, key) => {
          const item = equationIndicator.data[key]
          const isItemValid = Object.values(indicatorKeyByTimeFrequency).some(
            (key) => item[key],
          )

          if (!isItemValid) {
            isUpdateLatestPeriod = true

            return {
              ...obj,
              [key]: {
                ...item,
                ...indicatorLatestPeriod[indicatorKey],
              },
            }
          } else {
            return obj
          }
        }, {}),
      }

      if (isUpdateLatestPeriod) {
        onChangeEquationIndicator({
          id: equationIndicator.indicatorTypeId,
          value: newEquationIndicator,
        })
      }
    }
  }, [indicatorLatestPeriod])

  return <></>
}
