import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table as TableContent } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectReCalcWidths } from '../../../../../market/equityTrading/priceStatistics/summary/overview/store/slice'
import { getColorOverview } from '../../../../../utils/Color'
import { invertKeyBy } from '../../../../../utils/Common'
import { FORMAT } from '../../../../../utils/Datetime'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../utils/Value'
import { TIME_TYPES } from '../constants'
import { calculateSegment, getMinMaxdataAverage } from '../helper'
import {
  keys,
  selectAverageYtmData,
  selectDataTableAverageYtm,
  selectDataTableByIdAverageYtm,
  selectFilterAverageYtm,
  selectLoading,
  sortAverageYtm,
} from '../store/slice'
import TableHeader from './TableHeader'
import {
  GROUP_COLUMNS,
  GROUP_COLUMNS_NOT_REAL,
  keyColumns,
  keyColumnsNoReal,
} from './constants'
import style from './index.module.css'

const Table = () => {
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoading(keys.AVERAGE_YTM))
  const { data, ids } = useSelector(selectAverageYtmData)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const { interest, TimeRange, StartDate, EndDate, ytmChange } = useSelector(
    selectFilterAverageYtm,
  )
  const dataTable = useSelector(selectDataTableAverageYtm)
  const dataMinMax = useMemo(() => {
    const list = invertKeyBy(dataTable)
    const listkey = interest === 'real' ? keyColumns : keyColumnsNoReal
    return getMinMaxdataAverage(list, listkey)
  }, [dataTable, interest])

  const locale = useSelector((state) => state.i18n.locale)

  const columns = useMemo(
    () => (interest === 'real' ? GROUP_COLUMNS : GROUP_COLUMNS_NOT_REAL),
    [interest],
  )

  // Get value
  const getRowHighlights = () => {
    const itemIndex = {}
    data.forEach((item, index) => (itemIndex[item.date] = index))
    return Object.keys(itemIndex).map((key) => itemIndex[key])
  }

  const note = useMemo(() => {
    if ((!ytmChange && interest === 'real') || interest !== 'real') {
      const toDate = data?.[0]?.toDate ?? moment().toDate()

      return I18n.t('bond.corporateBond.topLiquidity.NOTE', {
        now: moment(toDate).format(FORMAT.DATE[locale]),
      })
    }

    switch (TimeRange) {
      case TIME_TYPES.ONE_DAY: {
        const toDate = data?.[0]?.toDate ?? moment().toDate()

        return I18n.t('bond.corporateBond.topLiquidity.NOTE', {
          now: moment(toDate).format(FORMAT.DATE[locale]),
        })
      }
      case TIME_TYPES.ONE_WEEK:
      case TIME_TYPES.ONE_MONTH:
      case TIME_TYPES.THREE_MONTHS: {
        const fromDate = data?.[0]?.fromDate ?? moment().toDate()
        const toDate = data?.[0]?.toDate ?? moment().toDate()

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: moment(fromDate).format(FORMAT.DATE[locale]),
          to: moment(toDate).format(FORMAT.DATE[locale]),
        })
      }
      case 'Calendar': {
        const fromDate = moment(StartDate).format(FORMAT.DATE[locale])
        const toDate = moment(EndDate).format(FORMAT.DATE[locale])

        return I18n.t('bond.corporateBond.topLiquidity.NOTE_2', {
          from: fromDate,
          to: toDate,
        })
      }
      default:
        return ''
    }
  }, [TimeRange, locale, data, StartDate, EndDate, ytmChange, interest])

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  const getbgColorPrice = (key) => {
    switch (key) {
      case 0:
        return {
          bg: '#EBFFD1',
          color: '#191D25',
        }
      case 1:
        return {
          bg: '#B3D69A',
          color: '#191D25',
        }
      case 2:
        return {
          bg: '#A8CE8F',
          color: '#191D25',
        }
      case 3:
        return {
          bg: '#85B76F',
          color: '#191D25',
        }
      case 4:
        return {
          bg: '#80B46B',
          color: '#191D25',
        }
      case 5:
        return {
          bg: '#6EA759',
          color: '#FFFFFF',
        }
      case 6:
        return {
          bg: '#418732',
          color: '#FFFFFF',
        }
      case 7:
        return {
          bg: '#235826',
          color: '#FFFFFF',
        }
      default:
        return {
          bg: '',
          color: '',
        }
    }
  }

  return (
    <>
      <SizeTracker>
        {(size) => (
          <>
            <div
              style={{
                height: `calc(100% - ${size.height}px)`,
              }}
            >
              <TableContent
                isLoading={isLoading}
                ids={ids}
                getDataFromRedux={selectDataTableByIdAverageYtm}
                renderHeader={(stateSort, sortColumn) => (
                  <TableHeader
                    tableHeaderColAttr={columns}
                    stateSort={stateSort}
                    sortColumn={sortColumn}
                  />
                )}
                defaultSort={{ outstandingValue: 'desc' }}
                schema={columns.map((item, index) => {
                  const title = item.title
                  const colId = item.key
                  const result = {
                    colId,
                    title,
                  }

                  switch (index) {
                    case 0:
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td
                            {...props}
                            className={style.firstCellColumn}
                            style={{
                              padding: 0,
                              height: 'auto',
                              position: 'sticky',
                              left: 0,
                              minWidth: 100,
                              maxWidth: 120,
                              backgroundColor: '#1e242e',
                            }}
                          >
                            <div
                              style={{
                                height: 28,
                                padding: '4px 8px',
                                boxSizing: 'border-box',
                                minWidth: '100%',
                                maxWidth: '100%',
                              }}
                              className="d-flex ali-center"
                            >
                              <Span style={{ overflow: 'hidden' }}>
                                <a
                                  className="cursor-pointer"
                                  onClick={() => redirectToBondIssuer(rowId)}
                                >
                                  <TextEllipsis text={value} isI18n={false} />
                                </a>
                              </Span>
                            </div>
                          </td>
                        ),
                      }

                    case 1:
                      return {
                        ...result,
                        tdStyle: {
                          minWidth: 65,
                          textAlign: 'right',
                          background: 'rgba(24, 89, 153, 0.15)',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <Span>{formatVal(valDivBillion(value), 2)}</Span>
                          </td>
                        ),
                      }

                    case 2:
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                          background: 'rgba(24, 89, 153, 0.15)',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <Span>{valToPercent(value)}</Span>
                          </td>
                        ),
                      }

                    case 3:
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                          background: 'rgba(24, 89, 153, 0.15)',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => (
                          <td {...props}>
                            <Span>{formatVal(value, 2)}</Span>
                          </td>
                        ),
                      }

                    default:
                      return {
                        ...result,
                        tdStyle: {
                          textAlign: 'right',
                        },
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const { bg, color } = getbgColorPrice(
                            calculateSegment(
                              value,
                              dataMinMax?.[colId]?.min,
                              dataMinMax?.[colId]?.max,
                            ),
                          )
                          const styleYtm = ((interest === 'real' &&
                            !ytmChange) ||
                            interest !== 'real') && {
                            background: bg,
                            position: 'relative',
                            height: '100%',
                            display: 'flex',
                          }
                          const paddingYtm = ((interest === 'real' &&
                            !ytmChange) ||
                            interest !== 'real') && {
                            padding: '0px 8px',
                          }
                          return (
                            <td
                              {...props}
                              style={{ ...props.style, ...paddingYtm }}
                            >
                              <div style={{ ...styleYtm }}>
                                <Span
                                  style={{
                                    margin: 'auto',
                                    color:
                                      interest === 'real' && ytmChange
                                        ? getColorOverview(value, 0)
                                        : color,
                                  }}
                                >
                                  {interest === 'real' &&
                                    ytmChange &&
                                    !isNaN(value) &&
                                    value < 0 && (
                                      <i
                                        className="icon-caret-down"
                                        style={{
                                          paddingRight: 4,
                                          fontSize: 10,
                                        }}
                                      />
                                    )}
                                  {valToPercent(value)}
                                  {interest === 'real' &&
                                    ytmChange &&
                                    !isNaN(value) &&
                                    value > 0 && (
                                      <i
                                        className="icon-caret-top"
                                        style={{ paddingLeft: 4, fontSize: 10 }}
                                      />
                                    )}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                  }
                })}
                stickies={{
                  name: true,
                }}
                sort={sortAverageYtm}
                stickyFirstColumn
                isLargeHead={true}
                hasFooter={false}
                resizable={false}
                isHighlightLine={true}
                rowHighlights={getRowHighlights()}
                reCalcWidths={reCalcWidths}
              />
            </div>
            {!!note?.length && (
              <div style={{ marginTop: 8 }}>
                <Span style={{ fontStyle: 'italic', color: '#85939C' }}>
                  {note}
                </Span>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </>
  )
}

export default Table
