import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import style from '../../index.module.css'
import { getRandColor, getUniqueCompanies } from '../helper'
import { SearchBoxPriceTab } from '../SearchBoxPriceTab'
import {
  LIMIT_SELECTION,
  NUM_OF_PERIOD,
  NUM_OF_PERIOD_FIRST_FETCH,
  TYPE_DATA,
  TYPE_STATISTIC,
} from './constants'
import { FilterList } from './FilterList'
import {
  addItemToList,
  changeActiveItem,
  changeLocale,
  changePeriodNum,
  removeItemFromList,
  selectActiveItem,
  selectAllIds,
  selectCurrentTypeStatistic,
  selectData,
  selectDataTable,
  selectGroupColumns,
  selectIsFirstRender,
  selectListCheckedItems,
  selectListSteelStypes,
  selectLoading,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectReCalcWidths,
  selectRowsCollapse,
  selectTypeData,
  setIsFirstRender,
  setRowsCollapse,
  sort,
} from './store/slice'
import { getPriceStatistics } from './store/thunk'
import { TdSelect } from './TdSelect'

const HORIZONTAL_TRACK_LEFT = 28
const tableId = 'priceStatistics'

export const PriceStatistics = ({ width, height }) => {
  const dispatch = useDispatch()

  const ids = useSelector(selectAllIds)
  const isLoading = useSelector(selectLoading)
  const listCheckedItems = useSelector(selectListCheckedItems)
  const groupColumns = useSelector(selectGroupColumns)
  const data = useSelector(selectData)
  const typeData = useSelector(selectTypeData)
  const currentTypeStatistic = useSelector(selectCurrentTypeStatistic)
  const listSteelStypes = useSelector(selectListSteelStypes)
  const activeItem = useSelector(selectActiveItem)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const isFirstRender = useSelector(selectIsFirstRender)
  const locale = useSelector((state) => state.i18n.locale)

  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)
  const [newMaxYear, setNewMaxYear] = useState(minCurrentYear)
  const [isShowWarning, setIsShowWarning] = useState(false)

  const activeItemId =
    activeItem?.organizationId ?? activeItem?.id ?? listSteelStypes[0]?.id
  const isYearly = currentTypeStatistic === TYPE_STATISTIC.YEARLY

  const dropdownListItems = (data) => {
    const uniqueCompanies = getUniqueCompanies(
      data.filter((item) => !item.isGroupRow),
    )
    const uniqueSteelTypes = data.filter((item) => item.isGroupRow)

    return [...uniqueSteelTypes, ...uniqueCompanies]
  }

  const onRowClick = (rowId) => {
    if (!listCheckedItems?.some((item) => item.id === rowId)) {
      if (listCheckedItems.length < LIMIT_SELECTION) {
        const getItemByRowId = data?.find((item) => item.id === rowId)
        const item = {
          id: rowId,
          name: getItemByRowId.name,
          colorId: getRandColor(listCheckedItems),
          steelProductTypeId: getItemByRowId.steelProductTypeId,
          steelProductTypeName: getItemByRowId.steelProductTypeName,
          organizationId: getItemByRowId.organizationId,
        }
        dispatch(addItemToList(item))
      } else {
        setIsShowWarning(true)
      }
    } else {
      dispatch(removeItemFromList(rowId))
    }
  }

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
    }
  }

  const itemWithCollapse = data?.map((item) => ({
    ...item,
    isCollapsible: item.isGroupRow,
  }))

  const levelCollapse = keyBy(
    itemWithCollapse?.map((item) => ({
      id: item.id,
      level: item.isCollapsible ? 1 : 2,
      isCollapsible: item.isCollapsible,
    })),
    'id',
  )

  const multipleActiveRowsIds =
    activeItem &&
    activeItem.organizationId &&
    data
      .filter(
        (item) =>
          !item.isGroupRow && item.organizationId === activeItem.organizationId,
      )
      .map((item) => item.id)

  const schema = groupColumns.map((item, index) => {
    const title = item.title
    const colId = item.key
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val, rowId) => {
          return (
            <TdSelect
              val={val}
              rowId={rowId}
              onRowCollapse={onRowCollapse}
              data={data}
              listCheckedItems={listCheckedItems}
              isRowCollapse={rowsCollapse.includes(rowId)}
            />
          )
        },
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => {
          return val
        },
      }
    }
    if (index !== 0 && index !== 1) {
      result = {
        ...result,
        render: (val) => {
          const isGrowth = data.length && data[0]?.typeData === TYPE_DATA.GROWTH
          return (
            <span className={val < 0 ? style.negativeValColor : ''}>
              {isGrowth ? valToPercent(val) : formatVal(val, 0)}
            </span>
          )
        },
      }
    }
    return result
  })

  const scrollToLeftCallback = () => {
    dispatch(changePeriodNum(NUM_OF_PERIOD))
    if (isYearly) {
      setNewMaxYear(minCurrentYear)
    } else {
      setNewMaxMonth(minCurrentMonth)
    }
    dispatch(setIsFirstRender(false))
  }

  //called only first time, fetch 20 periods
  useEffect(() => {
    dispatch(setIsFirstRender(true))
    dispatch(
      getPriceStatistics({
        NumOfPeriod: NUM_OF_PERIOD_FIRST_FETCH,
        TimeFrequency: isYearly ? 'Yearly' : 'Monthly',
        Language: locale,
      }),
    )
  }, [typeData, currentTypeStatistic, locale])

  useEffect(() => {
    setNewMaxMonth(null)
    setNewMaxYear(null)
    dispatch(changeLocale())
  }, [locale])

  //called when is Monthly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxMonth && !isFirstRender && !isYearly) {
      dispatch(
        getPriceStatistics({
          TimeFrequency: 'Monthly',
          Year: minCurrentYear,
          Month: minCurrentMonth,
          NumOfPeriod: NUM_OF_PERIOD,
        }),
      )
    }
  }, [newMaxMonth, isFirstRender])

  //called when is Yearly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxYear && !isFirstRender && isYearly) {
      dispatch(
        getPriceStatistics({
          TimeFrequency: 'Yearly',
          Year: minCurrentYear,
          Month: null,
          NumOfPeriod: NUM_OF_PERIOD,
        }),
      )
    }
  }, [newMaxYear, isFirstRender])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex j-b">
                <div style={{ width: '200px', marginTop: '3px' }}>
                  <SearchBoxPriceTab
                    listCompanies={dropdownListItems(data)}
                    activeItem={activeItem}
                    changeActiveItem={changeActiveItem}
                    tableId={tableId}
                    rowsCollapse={rowsCollapse}
                    activeItemId={activeItemId}
                  />
                </div>
                <FilterList
                  typeData={typeData}
                  listCheckedItems={listCheckedItems}
                  data={data}
                  currentTypeStatistics={currentTypeStatistic}
                />
              </div>
              <div className="table-info">
                <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
                  {I18n.t('sector.sectorSpecific.price.TABLE_INFO')}
                </Span>
                <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
                  {`${I18n.t('sector.sectorSpecific.steel.UNIT')}: ${
                    typeData === TYPE_DATA.PRICE
                      ? I18n.t('sector.sectorSpecific.steel.VND/KG')
                      : I18n.t('sector.sectorSpecific.steel.PERCENT')
                  }`}
                </Span>
              </div>
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                    paddingTop: 2,
                  }}
                  id={tableId}
                >
                  <Table
                    ids={ids}
                    getDataFromRedux={selectDataTable}
                    resizable={false}
                    horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                    isLoading={isLoading}
                    columnDraggable={false}
                    hasTooltip={false}
                    hasFooter={false}
                    onRowClick={onRowClick}
                    stickies={{
                      name: true,
                      ticker: true,
                    }}
                    schema={schema}
                    isCollapse={true}
                    rowsCollapse={rowsCollapse}
                    levelCollapse={levelCollapse}
                    scrollToLeftCallback={scrollToLeftCallback}
                    defaultScrollToRight={isFirstRender}
                    changeActiveRow={changeActiveItem}
                    defaultActiveRowId={activeItemId}
                    multipleActiveRowsIds={multipleActiveRowsIds}
                    isLazyLoadLeft={true}
                    reCalcWidths={reCalcWidths}
                    sort={sort}
                  />
                </div>
              )}
              <PopupAlert
                message={I18n.t('sector.sectorSpecific.price.WARNING_MESS')}
                isShow={isShowWarning}
                handleClose={() => setIsShowWarning(false)}
                zIndexOverlay={12}
              />
            </>
          )
        }}
      </SizeTracker>
    )
  )
}
