import React, { useRef, useState } from 'react'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { INTEREST_BASIS_OPTIONS } from '../../constants'
import style from './index.module.css'

const InterestBasisTd = ({
  props,
  item,
  interestBasis,
  rowId,
  handleChange,
}) => {
  const dropdownRef = useRef()

  const [dropdown, setDropdown] = useState(-1)

  return (
    <td
      {...props}
      style={{
        padding: 0,
        height: 28,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          ...item.attrs.style,
          padding: '4px 8px',
          boxSizing: 'border-box',
          gap: 8,
        }}
        className={`d-flex ali-center ${
          item?.justifyContent ?? 'justify-content-start'
        }`}
        ref={dropdownRef}
      >
        <TextEllipsis text={interestBasis?.name ?? ''} isI18n={false} />
        {rowId !== 'total' && (
          <>
            <Icon
              className={`${
                dropdown === rowId - 1 ? 'icon-caret-top' : 'icon-caret-down'
              } cursor-pointer`}
              style={{ color: '#8C9194', fontSize: 8 }}
              onClick={() => setDropdown(dropdown === -1 ? rowId - 1 : -1)}
            />
            <Dropdown
              position={positionDropdown.BOTTOM_RIGHT_LEFT}
              appendStyle={{
                borderRadius: 4,
                background: '#3a3f49',
                width: 74,
                zIndex: 9,
                cursor: 'default',
                overflow: 'hidden',
              }}
              isShow={dropdown === rowId - 1}
              parentRef={dropdownRef}
            >
              <HandleClickOutside
                handleClickOutside={() => setDropdown(-1)}
                exceptRef={dropdownRef}
              >
                <div>
                  {INTEREST_BASIS_OPTIONS.map((item, index) => (
                    <div
                      key={index}
                      className={`d-flex ali-center ${style.dropdownItem}`}
                      style={{ padding: '0 8px', height: 20 }}
                      onClick={() => {
                        setDropdown(-1)
                        handleChange(item.value, rowId)
                      }}
                    >
                      <Span style={{ fontWeight: 400 }}>{item.name}</Span>
                    </div>
                  ))}
                </div>
              </HandleClickOutside>
            </Dropdown>
          </>
        )}
      </div>
    </td>
  )
}

export default InterestBasisTd
