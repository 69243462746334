import { useDispatch, useSelector } from 'react-redux'
import Radio from '../../../../common/html/Radio'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TooltipInfo } from '../../../../common/tooltipInfo'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import { TABLE_TABS, VALUE_TYPES } from '../constants'
import {
  changeFilter,
  changeTableTab,
  changeValueType,
  selectFilters,
  selectTableTab,
  selectValueType,
} from '../store/slice'
import { Frequency } from './Frequency'
import { FundCode } from './FundCode'
import { FundManagement } from './FundManagement'
import { FundStatus } from './FundStatus'
import { FundStructure } from './FundStructure'
import { FundType } from './FundType'
import { TimeRange } from './TimeRange'

export const Filters = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const valueType = useSelector(selectValueType)

  // Actions
  const handleChangeFilter = (value) => {
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  const handleChangeTableTab = (value) => {
    dispatch(changeTableTab(value))
    handleChangeFilter({ page: 1 })
  }

  const handleChangeValueType = (value) => {
    dispatch(changeValueType(value))
  }

  return (
    <div className="mb-8">
      <div className="d-flex ali-center mb-8">
        <FundCode value={filter.fund} onChange={handleChangeFilter} />
        <FundStructure value={filter.structure} onChange={handleChangeFilter} />
        <FundType value={filter.type} onChange={handleChangeFilter} />
        <FundManagement
          value={filter.management}
          onChange={handleChangeFilter}
        />
        <FundStatus value={filter.status} onChange={handleChangeFilter} />
        <Frequency value={filter.frequency} onChange={handleChangeFilter} />
        <VerticalLine h={20} ml={16} mr={16} />
        <TimeRange
          timeRange={filter.timeRange}
          month={filter.month}
          year={filter.year}
          onChange={handleChangeFilter}
        />
      </div>
      <div className="d-flex ali-center">
        <DispatchActionTab
          onChangeTab={({ value }) => handleChangeTableTab(value)}
          data={Object.keys(TABLE_TABS).map((key) => ({
            title: 'fund.fundCenter.statistic.' + key,
            value: TABLE_TABS[key],
          }))}
          activeTab={tableTab}
          textSize={11}
        />

        {[TABLE_TABS.ASSET_ALLOCATION, TABLE_TABS.SECTOR_ALLOCATION].includes(
          tableTab,
        ) && (
          <div className="d-flex ali-center">
            <VerticalLine h={20} ml={8} mr={8} />
            <Radio
              data={Object.keys(VALUE_TYPES).map((key) => ({
                title: 'fund.fundCenter.statistic.' + key,
                value: VALUE_TYPES[key],
              }))}
              value={valueType}
              onChange={handleChangeValueType}
              containerClass="w-auto d-flex"
            />
          </div>
        )}

        {[TABLE_TABS.FUND_FLOW].includes(tableTab) && (
          <TooltipInfo
            tooltip="fund.fundCenter.FUND_FLOW_NOTE"
            style={{ marginLeft: 16 }}
          />
        )}
      </div>
    </div>
  )
}
