import OrderProxy from '../../../proxies/bond/bondSecurityProxy/orderProxy'
import { ServiceBase } from '../../ServiceBase'

export class Order extends ServiceBase {
  getDataSecurity(params) {
    return this.getData(() => OrderProxy.getDataProxy(params))
  }

  exportFile(params) {
    return this.getFileDownload(() => OrderProxy.exportFileProxy(params))
  }
}

export default new Order()
