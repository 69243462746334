export const common = {
  COMPANY_OVERVIEW: 'Tổng quan doanh nghiệp',
  COMPANY_NEW: 'Tin tức doanh nghiệp',
  FINANCIAL_DATA: 'Dữ liệu tài chính',
  BUSINESS_MODEL: 'Mô hình kinh doanh',
  SECTOR_OVERVIEW: 'Tổng quan ngành',
  TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  FINANCIAL_ANALYSIS: 'Phân tích tài chính',
  SEGMENTATION: 'Thị phần',
  RANKING: 'Xếp hạng',
  RATIO: 'Chỉ số ngành',
  VALUATION: 'Định giá',
}
