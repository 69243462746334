import { createAsyncThunk } from '@reduxjs/toolkit'
import StateBudgetService from '../../../../../../core/services/economy/fiscal/StateBudgetService'
import { handleExport } from '../../../../../utils/Export'

export const getTotalSocialInvesmentCapital = createAsyncThunk(
  'economy/fiscal/stateBudget/getTotalSocialInvesmentCapital',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getTotalSocialInvesmentCapital(
      params,
    )
    const responseName =
      await StateBudgetService.getTotalSocialInvesmentCapitalTypeName()

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getDownloadTotalSocialInvesmentCapital = createAsyncThunk(
  'economy/fiscal/stateBudget/getDownloadTotalSocialInvesmentCapital',
  async (data) => {
    const response =
      await StateBudgetService.getDownloadTotalSocialInvesmentCapital(data)
    handleExport(response.data, response.filename)
  },
)
