import { TYPE } from '../../../../common/chart/footer/Item'
import { chartAttr } from './constants'

export const getColorPieChart = (data) => {
  if (!data) return []

  return Object.keys(data)
    .filter((key) => Object.keys(chartAttr).includes(key))
    .sort((firstKey, secondKey) => {
      return data[secondKey] - data[firstKey]
    })
    .map((key) => ({
      text: chartAttr[key].name,
      latest: chartAttr[key].latest,
      value: data[key],
      color: chartAttr[key].color,
    }))
}

export const getFooterData = (chartData) => {
  if (chartData.length > 0) {
    return chartData.map((item) => {
      return {
        text: item.text,
        type: TYPE.CIRCLE,
        color: item.color,
      }
    })
  }

  return []
}
