import React from 'react'
import SelectCustom from '../../../../common/selectCustom'
import { range } from '../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'

const DropdownYear = ({ yearSelect = 2022, setYear }) => {
  const YEARS = range(2007, getCurrentDateTime(FORMAT.YEAR) * 1).reverse()
  return (
    <div className="w-80">
      <SelectCustom
        value={yearSelect}
        selectData={YEARS.map((year) => ({
          name: year,
          value: year,
        }))}
        handleChange={setYear}
      />
    </div>
  )
}

export default DropdownYear
