import { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from '../../../../common/dropdown'

import PropTypes from 'prop-types'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import useGetExchangeV2 from '../../../../common/masterData/useGetExchangeV2'
import Tree from '../../../../common/tree'
import { getAllParentChecked } from '../../../../common/tree/helper'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import styles from '../../styleTheme.module.css'
import NameChecked from './NameChecked'
import style from './index.module.css'

const OTC_ID = 65
const COMPANY_PRIVATE = 68

const SelectExchange = ({
  selectExchangeRef,
  listChecked,
  listParentChecked,
  changeListParentChecked,
  changeExchangeChecked,
  keepData = false,
}) => {
  const parentRef = useRef()

  const { exchangesTree, exchanges, loading } = useGetExchangeV2(true, true)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')

  const placeHolder = UseI18n('common.SEARCH')

  const exchangesTreeMemo = useMemo(() => {
    return exchangesTree
  }, [JSON.stringify(exchangesTree)])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  const handleChangeExchangeChecked = (values) => {
    const parentExchanges = Object.values(exchangesTree).map(
      (item) => item.groupId,
    )
    const parentChecked = getAllParentChecked(exchangesTree, 'groupId', values)
    const listChildChecked = values.filter(
      (id) => !(parentExchanges.includes(id) && !parentChecked.includes(id)),
    )
    changeExchangeChecked(listChildChecked)
  }

  const handleChangeListParentChecked = (values) => {
    changeListParentChecked(values)
  }

  useEffect(() => {
    if (!keepData) {
      const exchangeChecked = []
      const parentExchangeChecked = []

      Object.values(exchangesTree).forEach((item) => {
        if (item.groupId !== OTC_ID && item.groupId !== COMPANY_PRIVATE) {
          parentExchangeChecked.push(+item.groupId)

          if (item.children && Object.keys(item.children).length) {
            Object.keys(item.children).forEach((groupId) =>
              exchangeChecked.push(+groupId),
            )
          }
        }
      })
      changeExchangeChecked(exchangeChecked.sort((a, b) => a - b))
      changeListParentChecked(parentExchangeChecked.sort((a, b) => a - b))
    }
  }, [keepData])

  return (
    <div className={`input-dropdown ${style.selectSector}`}>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className="span-input w-100 cursor-pointer h-100"
        style={{
          padding: 0,
          borderBottomLeftRadius: isShowDropdown ? 0 : 4,
          borderBottomRightRadius: isShowDropdown ? 0 : 4,
          color: '#4e4e4e',
          background: '#F2F2F2',
        }}
      >
        <NameChecked
          exchanges={exchanges}
          exchangeTreeLength={Object.keys(exchangesTreeMemo).length}
          listParentChecked={listParentChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            ref={selectExchangeRef}
            className={`wrapper-tool`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${style.search} ${styles.inputStyle}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
                  </button>
                </div>
                <div className={style.treeContainer}>
                  {loading ? (
                    <Loading isBlack />
                  ) : (
                    <Tree
                      valueSearch={valueSearch}
                      treeData={exchangesTree}
                      idKey="groupId"
                      nameKey="groupName"
                      listChecked={listChecked}
                      setListChecked={handleChangeExchangeChecked}
                      setListParentChecked={handleChangeListParentChecked}
                      isHaveCheckbox={true}
                      isHaveCheckboxAll={true}
                      titleCheckboxAll="financialChart.ALL_EXCHANGE"
                      isActiveSelected={false}
                      classItemStyle={style.itemStyle}
                      classCheckboxStyle={style.checkboxStyle}
                      bgColorScroll="#D9D9D9"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

SelectExchange.propTypes = {
  listChecked: PropTypes.array,
  listParentChecked: PropTypes.array,
  changeListParentChecked: PropTypes.func,
  changeExchangeChecked: PropTypes.func,
  keepData: PropTypes.bool,
}

export default SelectExchange
