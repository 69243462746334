import AdvancedSearchProxy from '../../proxies/common/AdvancedSearchProxy'
import { ServiceBase } from '../ServiceBase'

class AdvancedSearchService extends ServiceBase {
  getLiveSearchData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getLiveSearchData',
      params,
    )(() => AdvancedSearchProxy.getLiveSearchData(params))
  }

  getFullSearchData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getFullSearchData',
      params,
    )(() => AdvancedSearchProxy.getFullSearchData(params))
  }

  getSearchCompanyData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchCompanyData',
      params,
    )(() => AdvancedSearchProxy.getSearchCompanyData(params))
  }

  getSearchSectorData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchSectorData',
      params,
    )(() => AdvancedSearchProxy.getSearchSectorData(params))
  }

  getSearchIndicesData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchIndicesData',
      params,
    )(() => AdvancedSearchProxy.getSearchIndicesData(params))
  }

  getSearchBondData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchBondData',
      params,
    )(() => AdvancedSearchProxy.getSearchBondData(params))
  }

  getSearchDerivativesData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchDerivativesData',
      params,
    )(() => AdvancedSearchProxy.getSearchDerivativesData(params))
  }

  getSearchFunctionData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchFunctionData',
      params,
    )(() => AdvancedSearchProxy.getSearchFunctionData(params))
  }

  getSearchCorNewsData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchCorNewsData',
      params,
    )(() => AdvancedSearchProxy.getSearchCorNewsData(params))
  }

  getSearchSecNewsData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchSecNewsData',
      params,
    )(() => AdvancedSearchProxy.getSearchSecNewsData(params))
  }

  getSearchMarNewsData(params) {
    return this.applyMemoryCache(
      'AdvancedSearchService.getSearchMarNewsData',
      params,
    )(() => AdvancedSearchProxy.getSearchMarNewsData(params))
  }

  getLogSearch(params) {
    return this.getData(() => AdvancedSearchProxy.getLogSearch(params))
  }

  postLogSearch(params) {
    return this.getData(() => AdvancedSearchProxy.postLogSearch(params))
  }

  deleteLogSearch(params) {
    return this.getData(() => AdvancedSearchProxy.deleteLogSearch(params))
  }
}

export default new AdvancedSearchService()
