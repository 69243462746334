import { getTimeFrequencyByIndicator } from '.'
import { LIST_TYPE, TIME_RANGE } from '../constants'
import { getSecuritiesIndicatorLatestPeriodParams } from './chartApiParamsAndResponseHelpers'

export const getDataChartByDashboard = (response) => {
  const result = {}
  response.forEach((res) => {
    if (res.status === 'fulfilled' && res.value.success && res.value.data) {
      result[res.value.data.id] = res.value.data.charts
    }
  })
  return result
}

const toCapitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
}

export const getDataQueryPayloadParams = (
  viewType,
  securitiesPayload,
  indicatorsLatestPeriod,
  isViewLatestPeriod,
) => {
  return {
    viewType: viewType,
    timeRange: TIME_RANGE.ALL_TIME,
    securities: {
      conditionGroup: securitiesPayload.map((item) => ({
        securitiesType: item.securitiesType,
        dataIds: item.dataIds,
        indicators: getSecuritiesIndicatorLatestPeriodParams(
          item.indicators,
          indicatorsLatestPeriod,
          isViewLatestPeriod,
          viewType,
        ).map((indicator) => ({
          id: indicator.id,
          timeFrequency: toCapitalize(getTimeFrequencyByIndicator(indicator)),
          realTimeFrequency: indicator.timeFrequency,
          uniqueId: indicator.uniqueID,
          ...indicator.dataLatestPeriod,
        })),
      })),
    },
  }
}

export const getFormatDataQueryBySecurities = (data) => {
  return data.reduce((obj, item) => {
    if (item.securitiesType.toLowerCase() === LIST_TYPE.ECONOMY) {
      return { ...obj, ...item.items }
    } else {
      const key = `${item.securitiesType.toLowerCase()}_${item.id}`
      const values = Object.keys(item.items).reduce((childObj, childKey) => {
        const arrKey = childKey.split('_').filter((val) => !!val)
        let newKey = [arrKey[0]]

        if (item.securitiesType.toLowerCase() !== LIST_TYPE.ECONOMY) {
          newKey.push('indicator')
        }

        newKey = [...newKey, ...arrKey.slice(1)].join('_')

        return item.items[childKey]
          ? {
              ...childObj,
              [newKey]: {
                ...item.items[childKey],
                uniqueID: item.items[childKey].uniqueId,
              },
            }
          : childObj
      }, {})

      return obj[key]
        ? { ...obj, [key]: { ...obj[key], ...values } }
        : { ...obj, [key]: values }
    }
  }, {})
}

export const getFormatDataQueryByTime = (data) => {
  return data
    .map((item) => ({
      date: item.tradingDate,
      ...item.items,
    }))
    .sort((a, b) => a.date.localeCompare(b.date))
}
