import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PanelChartFirst } from './chartFirst/PanelChartFirst'
import { PanelChartSecond } from './chartSecond/PanelChartSecond'
import { PanelTable } from './table/PanelTable'

const MAP_KEY = {
  TABLE: 'TABLE',
  CHART_FIRST: 'CHART_FIRST',
  CHART_SECOND: 'CHART_SECOND',
}

const MIN_WIDTH = 1000
const MIN_HEIGHT = 600

const MAP_SIZE = {
  [`${MAP_KEY.TABLE}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: 0,
    minWidth: MIN_WIDTH * 0.6 - COMPONENT.MARGIN,
    minHeight: MIN_HEIGHT,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.CHART_FIRST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.CHART_SECOND,
        ratioLeft: 1,
        ratioWidth: 1,
      },
    ],
    horizontalResize: [],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHART_FIRST}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_WIDTH * 0.4,
    minHeight: MIN_HEIGHT / 2 - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: MIN_WIDTH * 0.6 + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.CHART_SECOND,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CHART_SECOND}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_WIDTH * 0.4,
    minHeight: MIN_HEIGHT / 2 - COMPONENT.MARGIN,
    minTop: MIN_HEIGHT / 2 + COMPONENT.MARGIN,
    minLeft: MIN_WIDTH * 0.6 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}
const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelTable
          panelRefs={panelRefs}
          mapKey={MAP_KEY}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelChartFirst
          panelRefs={panelRefs}
          mapKey={MAP_KEY}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelChartSecond
          panelRefs={panelRefs}
          mapKey={MAP_KEY}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
