export const gdp = {
  INDICATOR: 'Chỉ tiêu',
  TOTAL_GDP: 'Tổng GDP',
  GDP_BY_SECTOR: 'GDP theo ngành',
  GDP_GROWTH_BY_SECTOR: 'Tăng trưởng GDP theo ngành',
  GDP_BY_PROVINCE: 'GDP theo tỉnh thành',
  GDP_GROWTH_FORECAST: 'Dự báo tăng trưởng GDP',
  GDP_COMPOSITION_BY_SECTOR: 'Cơ cấu GDP theo ngành',
  GDP_BY_SPENDING_METHOD: 'GDP theo phương pháp chi tiêu',
  GROWTH_GDP_BY_SPENDING_METHOD: 'Tăng trưởng GDP theo phương pháp chỉ tiêu',
  TOP_10_GDP: 'TOP 10 GDP theo tỉnh thành',
  GDP_STRUCTURE_PROVINCES: 'Cơ cấu GDP của tỉnh thành',
  DOWNLOAD_STRUCTURE_PROVINCES: 'Cơ cấu GDP của ',
  GDP_STRUCTURE_SPENDING_METHOD: 'Cơ cấu GDP theo phương pháp chỉ tiêu',
  CHANGES_IN_GDP: 'Dịch chuyển cơ cấu GDP theo ngành tại ',
  CHANGES_IN_SPENDING_METHOD: 'Thay đổi cơ cấu GDP theo phương pháp chỉ tiêu',
  NOMINAL: 'Danh nghĩa',
  REAL: 'Thực tế',
  ANNUAL: 'Hàng năm',
  ALL: 'Tất cả',
  VALUE_PERCENT: '(Giá trị)',
  VALUE: 'Giá trị',
  ACC_VALUE: 'Giá trị lũy kế',
  GROWTH_YOY: 'Tăng trưởng YoY',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  ACC_QUARTERLY: 'Luỹ kế quý',
  TRILLION_VND: 'Giá trị: nghìn tỷ VND',
  TRILLION: '(Nghìn tỷ VND)',
  GROWTH_YOY_FOOTER: 'Đơn vị: %',
  SEARCH_PROVINCE: 'Tìm tỉnh, thành phố',
  PER_CAPITA: 'Bình quân đầu người',
  PROVINCE_VALUE: 'Giá trị (triệu VND)',
  PROVINCE_GROWTH_YOY: 'Tăng trưởng YoY (%)',
  PROVINCE_PER_HEAD: 'Per head (triệu VND)',
  UNIT_BILLION_VND: 'Đơn vị: tỷ VND',
  UNIT_PERCENT: 'Đơn vị: %',
  UNIT_MILLION_VND: 'Đơn vị: triệu VND',
  UNIT_TRILLION_VND: 'Đơn vị: nghìn tỷ VND',
  BILLION_TOOLTIP: ' Tỷ VND',
  BILLION_VND: '(Tỷ VND)',
  MILLION_VND: '(Triệu VND)',
  MILLION_TOOLTIP: ' Triệu VND',
  MAX_ITEM: 'Số lượng chỉ tiêu lựa chọn không vượt quá 20.',
  PER_CAPITA_REAL: 'Chỉ số phát triển BQ đầu người',
}
