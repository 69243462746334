import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { convertData } from '../helper'
import { getFDIStructure } from './thunk'

const initialState = {
  isLoading: true,
  dataFDIStructure: [],
}

const slice = createSlice({
  name: 'economy/fdi/overview/fdiStructure',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFDIStructure.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFDIStructure.fulfilled, (state, action) => {
      state.dataFDIStructure = convertData(action.payload)
      state.isLoading = false
    })
    builder.addCase(getFDIStructure.rejected, (state, action) => {
      state.isLoading = action.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectFDIStructureData = (state) =>
  state[slice.name].dataFDIStructure

register(slice.name, slice.reducer)
