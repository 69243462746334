import BondListProxy from '../../../proxies/bond/bondScreening/BondListProxy'
import { ServiceBase } from '../../ServiceBase'

export class BondListService extends ServiceBase {
  getTemplates() {
    return this.getData(() => BondListProxy.getTemplates())
  }
  getTemplateById(params) {
    return this.getData(() => BondListProxy.getTemplateById(params))
  }
  saveTemplate(params) {
    return this.getData(() => BondListProxy.saveTemplate(params))
  }
  renameTemplateById(params) {
    return this.getData(() => BondListProxy.renameTemplateById(params))
  }
  updateTemplateById(params) {
    return this.getData(() => BondListProxy.updateTemplateById(params))
  }
  deleteTemplateById(params) {
    return this.getData(() => BondListProxy.deleteTemplateById(params))
  }
  getFilterIndicator(params) {
    return this.getData(() => BondListProxy.getFilterIndicator(params))
  }
  getAllIndicator(params) {
    return this.getData(() => BondListProxy.getAllIndicator(params))
  }
  getBondList(params) {
    return this.getData(() => BondListProxy.getBondListProxy(params))
  }
  getBondListCount(params) {
    return this.applyMemoryCache(
      'BondListService.getBondListCount',
      params,
    )(() => BondListProxy.getBondListCount(params))
  }
  exportBondList(params) {
    return this.getFileDownload(() => BondListProxy.exportBondList(params))
  }
  getWatchlistByUser(params) {
    return this.getData(() => BondListProxy.getWatchlistByUser(params))
  }
}

export default new BondListService()
