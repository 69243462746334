import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { NAVIGATION_CW_STATISTICS } from './constants'
import style from './index.module.css'
import { changePage } from './overviewStatistics/tableOverviewStatistics/store/slice'

export const CoveredWarrantStatistics = () => {
  const dispatch = useDispatch()

  const [currentTab, setCurrentTab] = useState(
    NAVIGATION_CW_STATISTICS[0].value,
  )
  const [currentComponent, setCurrentComponent] = useState(
    NAVIGATION_CW_STATISTICS[0].component,
  )

  const handleClickTab = (item) => () => {
    setCurrentTab(item.value)
    setCurrentComponent(item.component)
    dispatch(changePage(1))
  }

  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="market.coveredWarrant.cwStatistics.CW_STATISTICS" />
              <VerticalLine mr={12} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_CW_STATISTICS.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      currentTab === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {currentTab === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    {currentComponent}
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
