import { scaleLinear } from 'd3-scale'
import { valByKeyWithDot } from '../../../../utils/Value'

const RED_CONFIG = 10
const GREEN_CONFIG = -2
const BLUE_CONFIG = 25
const WHITE_TEXT = [236, 236, 236]
const BLACK_TEXT = [47, 48, 50]

export const getColorByVal = (val, minMaxValue) => {
  const color = scaleLinear(minMaxValue, ['#f9fbe7', '#33691e'])
  return color(val)
}

const getTextColor = (arrRGBValue) => {
  const [r, g, b] = arrRGBValue
  const [wtRed, wtGreen, wtBlue] = WHITE_TEXT
  const [btRed, btGreen, btBlue] = BLACK_TEXT
  const diff =
    (r - wtRed) ** 2 +
    (g - wtGreen) ** 2 +
    (b - wtBlue) ** 2 -
    ((r - btRed) ** 2 + (g - btGreen) ** 2 + (b - btBlue) ** 2)
  return diff < 0 ? '#2f3032' : '#ececec'
}

// Parse rgb string to [r,g,b]
const sliceRGBString = (rgbString) => {
  return rgbString.slice(4, rgbString.length - 1)
}

const getArrayRGBValue = (str) => {
  return sliceRGBString(str)
    .split(', ')
    .map((val) => +val)
}

export const handleColor = (val, minMaxValue) => {
  const color = getColorByVal(val, minMaxValue)
  const arrRGBValue = getArrayRGBValue(color)
  const textColor = getTextColor(arrRGBValue)
  const redValue =
    arrRGBValue[0] > RED_CONFIG ? arrRGBValue[0] - RED_CONFIG : arrRGBValue[0]
  const greenValue =
    arrRGBValue[1] > GREEN_CONFIG
      ? arrRGBValue[1] - GREEN_CONFIG
      : arrRGBValue[1]
  const blueValue =
    arrRGBValue[2] > BLUE_CONFIG ? arrRGBValue[2] - BLUE_CONFIG : arrRGBValue[2]
  return {
    bg: `rgb(${redValue},${greenValue},${blueValue} )`,
    color: textColor,
  }
}

export const handleMaxMinValueByColId = (payload) => {
  if (!payload.length) {
    return {}
  }

  const colIdWithVal = {}
  const result = {}
  payload.forEach((item) => {
    const colId = item.year

    if (!colIdWithVal[colId]) {
      colIdWithVal[colId] = [item.value]
    } else {
      colIdWithVal[colId].push(item.value)
    }
  })

  for (const prop in colIdWithVal) {
    result[prop] = handleMinMaxValue(colIdWithVal[prop])
  }

  return result
}

export const handleMinMaxValue = (payload) => {
  return [Math.min.apply(Math, payload), Math.max.apply(Math, payload)]
}

export const keyByArray = (array, key) => {
  const result = {}
  array.forEach((item) => {
    const keyObj = valByKeyWithDot(item, key)

    if (!result[keyObj]) {
      result[keyObj] = { [item.year]: item.value }
    } else {
      result[keyObj][item.year] = item.value
    }
  })
  return result
}

export const defineIds = (payload) => {
  const dataWithIdNotDuplicate = handleDuplicateByKey(
    payload,
    'nationalAccountVNTypeId',
  )

  return dataWithIdNotDuplicate.map((item) => ({
    id: item.nationalAccountVNTypeId,
    title: item.organizationShortName,
    level: item.nationalAccountVNTypeLevel,
  }))
}

export const defineGroupColumns = (payload) => {
  const dataWithYearNotDuplicate = handleDuplicateByKey(payload, 'year')
  const dataWithYearNotNull = dataWithYearNotDuplicate
    .filter((item) => item.year !== null)
    .sort((a, b) => a.year - b.year)

  return [
    { title: '', key: 'emptyColumn' },
    ...dataWithYearNotNull.map((item) => ({
      title: `${item.year}`,
      key: `${item.year}`,
    })),
  ]
}

const handleDuplicateByKey = (payload, key) => {
  return payload.filter((item, index, arr) => {
    return index === arr.findIndex((element) => element[key] === item[key])
  })
}
