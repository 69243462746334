import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { formatVal } from '../../../../utils/Value'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import { NUMBER_OF_PERIOD, NUMBER_OF_PERIOD_FIRST_TIME } from './config'
import FirstCellColumn from './FirstCellColumn'
import style from './index.module.css'
import {
  getGDPStructureById,
  getGDPStructureValue,
  getGroupColumns,
  getIsAllowCallApi,
  getIsFirstTime,
  getMinYear,
  getTableDataLoading,
  getTableMappingData,
  getTableMappingLoading,
  handleIsFirstTime,
} from './store/slice'
import { getGDPBySpendingMethodStructure, getGDPTypeName } from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const Container = ({ height }) => {
  const dispatch = useDispatch()

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableMapping = useSelector(getTableMappingData)
  const tableMappingLoading = useSelector(getTableMappingLoading)
  const GDPStructureById = useSelector(getGDPStructureById)
  const locale = useSelector((state) => state.i18n.locale)
  const nominalRealValue = useSelector(getNominalRealValue)
  const minYear = useSelector(getMinYear)
  const groupColumns = useSelector(getGroupColumns)
  const isFirstTime = useSelector(getIsFirstTime)
  const isAllowCallApi = useSelector(getIsAllowCallApi)

  const scrollToLeft = () => {
    dispatch(handleIsFirstTime(false))
    if (isAllowCallApi && minYear) {
      dispatch(
        getGDPBySpendingMethodStructure({
          IsNominal: nominalRealValue === NOMINAL,
          NumberOfPeriod: NUMBER_OF_PERIOD,
          Year: minYear - 1,
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(
      getGDPBySpendingMethodStructure({
        IsNominal: nominalRealValue === NOMINAL,
        NumberOfPeriod: NUMBER_OF_PERIOD_FIRST_TIME,
      }),
    )
    dispatch(handleIsFirstTime(true))
  }, [locale, nominalRealValue])

  useEffect(() => {
    dispatch(
      getGDPTypeName({
        IsNominal: nominalRealValue === NOMINAL,
        IsValue: false,
      }),
    )
  }, [nominalRealValue, locale])

  if ((tableDataLoading || tableMappingLoading) && isFirstTime) {
    return <Loading />
  }

  if (Object.keys(GDPStructureById).length === 0 || tableMapping.length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            {typeof sizes.height === 'number' && (
              <div style={{ height: height - sizes.height }}>
                <Table
                  ids={tableMapping.map((item) => item.nationalAccountVNTypeId)}
                  columnDraggable={false}
                  getDataFromRedux={getGDPStructureValue}
                  rowDraggable={false}
                  isLoading={tableDataLoading || tableMappingLoading}
                  schema={[
                    {
                      key: 'indicator',
                      title: 'economy.gdp.gdp.INDICATOR',
                    },
                    ...groupColumns,
                  ].map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    const result = {
                      colId,
                      title,
                    }
                    if (index === 0) {
                      return {
                        ...result,
                        thStyle: {
                          textAlign: 'left',
                        },
                        tdStyle: {
                          minWidth: 240,
                        },
                        canCustomTd: true,
                        render: (val, rowId, props) => {
                          const tableHorizontalHead = tableMapping.find(
                            (item) => item.nationalAccountVNTypeId === rowId,
                          )
                          return (
                            <FirstCellColumn
                              val={
                                tableHorizontalHead?.nationalAccountVNTypeName
                              }
                              props={props}
                              rowId={rowId}
                              level={
                                tableHorizontalHead.nationalAccountVNTypeLevel
                              }
                            />
                          )
                        },
                      }
                    }

                    return {
                      ...result,
                      isI18n: false,
                      tdStyle: {
                        textAlign: 'right',
                        minWidth: 80,
                      },
                      thStyle: {
                        textAlign: 'right',
                      },
                      canCustomTd: true,
                      render: (val, rowId, props) => {
                        return (
                          <td
                            {...props}
                            className={`${
                              typeof val === 'number' &&
                              val < 0 &&
                              style.redColor
                            }`}
                          >
                            <Span>{formatVal(val)}</Span>
                            {typeof val === 'number' && '%'}
                          </td>
                        )
                      },
                    }
                  })}
                  stickyFirstColumn={true}
                  isPagination={false}
                  hasFooter={false}
                  resizable={false}
                  scrollToLeftCallback={scrollToLeft}
                  defaultScrollToRight={isFirstTime}
                  isLazyLoadLeft={true}
                />
              </div>
            )}
            <div className={style.wapFooterGDPStructure}>
              <Span
                style={{ fontSize: 11 }}
                className={style.footerGDPStructure}
              >
                <Translate value="economy.gdp.gdp.UNIT_PERCENT" />
              </Span>
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
