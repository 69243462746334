import { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ExportTurnOver } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { selectImportExportDataType } from '../exportImportTable/store/slice'

export const PanelExportTurnOver = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const importExportDataType = useSelector(selectImportExportDataType)
  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT

  const nameScreen = UseI18n('economy.importExport.importExport.IMPORT_EXPORT')
  const tabName = UseI18n(
    'economy.importExport.importExport.EXPORT_IMPORT_BY_COUNTRIES',
  )
  const title = isImportData
    ? 'economy.importExport.importExport.exportImportByLocation.CHANGE_TURN_OVER_PARTNERS_IMPORT'
    : 'economy.importExport.importExport.exportImportByLocation.CHANGE_TURN_OVER_PARTNERS_EXPORT'

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title={title}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={I18n.t(title)}
      downloadJpgParams={{
        domId: 'exportTurnOverChart',
        nameScreen: nameScreen,
        chartName: I18n.t(title),
        tabName: tabName,
      }}
      handleCustom={setIsFullScreen}
    >
      <ExportTurnOver
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
