export const HEADER_VALUE = {
  NOMINAL: 'NOMINAL',
  REAL: 'REAL',
}

export const COMPOSITION_SECTOR_VALUE = {
  VALUE: 'VALUE',
  ACC_VALUE: 'ACC_VALUE',
}

export const YEAR_OPTIONS = (function () {
  const MIN_YEAR_OPTION = 2000
  const year = new Date().getFullYear()
  const range = year - MIN_YEAR_OPTION + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(year - item),
      value: String(year - item),
    }
  })
})()

export const VALUE_TIME_FRAME_OPTIONS = [
  {
    title: 'economy.gdp.gdp.ANNUAL',
    value: '5',
  },
  { title: 'Q1', value: '1' },
  { title: 'Q2', value: '2' },
  { title: 'Q3', value: '3' },
  { title: 'Q4', value: '4' },
]

export const ACC_TIME_FRAME_OPTIONS = [
  { title: 'Q1', value: '1' },
  { title: '2Q', value: '2' },
  { title: '3Q', value: '3' },
  { title: '4Q', value: '4' },
]

export const LINE_CHART_COLORS = [
  '#ecffdf',
  '#9c54e4',
  '#c7ff58',
  '#f57f07',
  '#185999',
  '#ff2500',
  '#744d23',
  '#f9c105',
  '#85d4ff',
  '#56c10a',
  '#009fe6',
  '#9d9e9e',
  '#f4327b',
  '#930037',
  '#57426d',
  '#4bd9be',
  '#c52e14',
  '#00763e',
  '#975000',
  '#1a36a9',
]

export const PIE_CHART_COLORS = {
  color: ['#45b29d', '#facc5c', '#df5a49', '#185999', '#0096eb', '#ecffdf'],
  other: '#ecffdf',
}

export const STACK_COLORS = {
  2: ['#185999', '#fff6df'],
  3: ['#185999', '#fff6df', '#fe7e6d'],
  4: ['#185999', '#3cabeb', '#fff6df', '#fe7e6d'],
  5: ['#185999', '#40abff', '#f6ad5c', '#a6cf98', '#fff6df'],
  6: ['#45b29d', '#facc5c', '#df5a49', '#185999', '#0096eb', '#ecffdf'],
}
