import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  COMMON_SIZE,
  CURRENCY,
  LASTEST_YEAR,
  NUMBER_OF_PERIOD,
  STATEMENT,
  TYPE,
  UNIT,
  UNIT_VALUE_CONSTANTS,
  VIEW_BY,
} from '../filter/constants'
import { formatFinancialData, setFormatIndicatorMapping } from '../helps'
import {
  getExchangeRates,
  getFinancialDataBalanceSheet,
  getFinancialDataCashFlow,
  getFinancialDataIncomeStatement,
  getFinancialDataNote,
  getIndicatorMapping,
} from './thunk'

const initialState = {
  financialById: {},
  ids: [],
  groupColumns: [],
  tableConstants: [],
  loading: true,
  mappingLoading: false,
  statement: STATEMENT[0],
  financialFilter: {
    viewBy: VIEW_BY[0],
    numberOfPeriod: NUMBER_OF_PERIOD[5],
    type: TYPE[0],
    lastestYear: LASTEST_YEAR[0],
    commonSize: COMMON_SIZE[1],
  },
  filterLocal: {
    currency: CURRENCY[0],
    unit: UNIT.VND[2],
  },
  indicator: {
    value: null,
    subValue: null,
  },
  indicatorMappingData: [],
  stockBasicInfo: {},
  exchangeRates: {},
}

export const slice = createSlice({
  name: 'corporate/financialData/financialStatement',
  initialState,
  reducers: {
    setFinancialById: (state, action) => {
      state.financialById = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeFinancialFilter: (state, action) => {
      state.financialFilter = {
        ...state.financialFilter,
        [action.payload.field]: action.payload.option,
      }
    },
    changeFilterLocal: (state, action) => {
      let filterLocal = {}
      if (action.payload.field === 'currency') {
        if (action.payload.option.value === UNIT_VALUE_CONSTANTS.VND) {
          filterLocal = { unit: UNIT.VND[2] }
        } else {
          filterLocal = { unit: UNIT.USD[2] }
        }
      }
      state.filterLocal = {
        ...state.filterLocal,
        [action.payload.field]: action.payload.option,
        ...filterLocal,
      }
    },
    changeStatement: (state, action) => {
      state.statement = action.payload
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      const rowData = state.financialById[rowId]

      if (rowData.childrenId) {
        const childId = rowData.childrenId[0]
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = state.financialById[childId]
      } else if (rowData.parentId) {
        const parentId = rowData.parentId
        state.indicator.value = state.financialById[parentId]
        state.indicator.subValue = state.financialById[rowId]
      } else {
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = {}
      }
    },
    resetIndicator: (state) => {
      state.indicator = {
        value: null,
        subValue: null,
      }
    },
    resetIndicatorMapping: (state) => {
      state.tableConstants = []
      state.ids = []
      state.financialById = {}
      state.loading = true
    },
    changeLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialDataBalanceSheet.pending, (state) => {
      state.loading = true
      state.groupColumns = []
    })
    builder.addCase(getFinancialDataBalanceSheet.fulfilled, (state, action) => {
      formatFinancialData(state, action)
    })
    builder.addCase(getFinancialDataBalanceSheet.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getFinancialDataCashFlow.pending, (state) => {
      state.loading = true
      state.groupColumns = []
    })
    builder.addCase(getFinancialDataCashFlow.fulfilled, (state, action) =>
      formatFinancialData(state, action),
    )
    builder.addCase(getFinancialDataCashFlow.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getFinancialDataIncomeStatement.pending, (state) => {
      state.loading = true
      state.groupColumns = []
    })
    builder.addCase(
      getFinancialDataIncomeStatement.fulfilled,
      (state, action) => formatFinancialData(state, action),
    )
    builder.addCase(
      getFinancialDataIncomeStatement.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(getFinancialDataNote.pending, (state) => {
      state.loading = true
      state.groupColumns = []
    })
    builder.addCase(getFinancialDataNote.fulfilled, (state, action) =>
      formatFinancialData(state, action),
    )
    builder.addCase(getFinancialDataNote.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getIndicatorMapping.pending, (state) => {
      state.mappingLoading = true
    })
    builder.addCase(getIndicatorMapping.fulfilled, (state, action) => {
      state.indicatorMappingData = action.payload || []
      setFormatIndicatorMapping(state)
    })
    builder.addCase(getIndicatorMapping.rejected, (state, action) => {
      state.mappingLoading = action.payload.loading
    })
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      const exchangeRates = {}
      action.payload?.forEach(
        (item) =>
          (exchangeRates[`${item.quarter}-${item.year}`] = item.exchangeRate),
      )
      state.exchangeRates = exchangeRates
    })
  },
})

export const selectFinancialIds = (state) => state[slice.name].ids
export const selectTableConstants = (state) => state[slice.name].tableConstants
export const selectFinancialValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].financialById[id], attr)
}
export const selectLoading = (state) => state[slice.name].loading
export const selectMappingLoading = (state) => state[slice.name].mappingLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectFinancialFilter = (state) =>
  state[slice.name].financialFilter
export const selectStatement = (state) => state[slice.name].statement
export const selectItemData = (id) => (state) =>
  state[slice.name].financialById[id]
export const selectIndicator = (state) => state[slice.name].indicator
export const selectFinancialById = (state) => state[slice.name].financialById
export const selectFilterLocal = (state) => state[slice.name].filterLocal
export const selectExchangeRates = (state) => state[slice.name].exchangeRates

export const {
  changeFinancialFilter,
  changeStatement,
  setFinancialById,
  changeIds,
  changeIndicator,
  resetIndicator,
  resetIndicatorMapping,
  changeLoading,
  changeFilterLocal,
} = slice.actions

register(slice.name, slice.reducer)
