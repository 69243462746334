import foreignProxy from '../../../proxies/market/marketInDepth/ForeignProxy'
import { ServiceBase } from '../../ServiceBase'

export class ForeignService extends ServiceBase {
  getForeignNetValue(params) {
    return this.getData(() => foreignProxy.getForeignNetValue(params))
  }

  getForeignTopNetValue(params) {
    return this.getData(() => foreignProxy.getForeignTopNetValue(params))
  }

  getForeignOwnerShip(params) {
    return this.getData(() => foreignProxy.getForeignOwnerShip(params))
  }

  getForeignSectorSegmentation(params) {
    return this.applyMemoryCache(
      'ForeignService.getForeignSectorSegmentation',
      params,
    )(() => foreignProxy.getForeignSectorSegmentation(params))
  }

  downloadForeignSectorSegmentation(params) {
    return this.getFileDownload(() =>
      foreignProxy.downloadForeignSectorSegmentation(params),
    )
  }

  downloadForeignTopNetValue(params) {
    return this.getFileDownload(() =>
      foreignProxy.downloadForeignTopNetValue(params),
    )
  }
}

export default new ForeignService()
