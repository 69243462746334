import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { useLocation } from 'react-router-dom'
import { FEATURES } from '../../constants/routes'
import CurrentState from '../../utils/CurrentState'
import Dropdown, { positionDropdown } from '../dropdown'
import { HandleClickOutside } from '../HandleClickOutside'
import useBasicInfo, { TYPE_STOCK_INDICES_SECTOR } from '../hooks/useBasicInfo'
import { Span } from '../html/Span'
import { HEIGHT_DROPDOWN, WIDTH_DROPDOWN } from './constant'
import style from './index.module.css'

const LinkTicker = ({ children, organizationId }) => {
  const location = useLocation()
  const { getBasicInfo } = useBasicInfo()

  const parentRef = useRef()

  const [isOpenDropdown, setIsOpenDropdown] = useState(false)
  const [isHoverText, setIsHoverText] = useState(false)
  const [position, setPosition] = useState()

  useEffect(() => {
    if (isOpenDropdown) {
      const table = parentRef.current.closest('.table-wrapper')
      if (table) {
        table
          .querySelector(`.scrollbars > div:first-child`)
          .addEventListener('scroll', handleCloseDropdown)
      }
      const bottom =
        window.innerHeight - parentRef.current?.getBoundingClientRect().bottom
      const right =
        window.innerWidth - parentRef.current?.getBoundingClientRect().right
      if (bottom > HEIGHT_DROPDOWN) {
        if (right > WIDTH_DROPDOWN) {
          setPosition(positionDropdown.BOTTOM_LEFT_RIGHT)
        } else {
          setPosition(positionDropdown.BOTTOM_RIGHT_LEFT)
        }
      } else {
        if (right > WIDTH_DROPDOWN) {
          setPosition(positionDropdown.TOP_LEFT_RIGHT)
        } else {
          setPosition(positionDropdown.TOP_RIGHT_LEFT)
        }
      }
    }
  }, [isOpenDropdown])

  const handleDropdown = (e) => {
    e.stopPropagation()
    setIsOpenDropdown(!isOpenDropdown)
  }

  const handleCloseDropdown = useCallback(() => {
    setIsOpenDropdown(false)
    const table = parentRef.current.closest('.table-wrapper')
    if (table) {
      table
        .querySelector(`.scrollbars > div:first-child`)
        .removeEventListener('scroll', handleCloseDropdown)
    }
  }, [])

  const overText = () => setIsHoverText(true)

  const outText = () => setIsHoverText(false)

  const handleOpenNewTab = (route, headerSearch) => {
    CurrentState.addRoute({
      key: location.pathname,
      data: {
        route: route,
        disableRecentVisited: true,
        headerSearch,
      },
    })
    window.open(route, '_blank')
    handleCloseDropdown()
  }

  const listAction = [
    {
      icon: <i className="icon-overview" />,
      title: 'common.linkSectorTicker.COMPANY_OVERVIEW',
      action: () => {
        handleOpenNewTab(
          FEATURES.corporate.components.overview.route,
          getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK),
        )
      },
    },
    {
      icon: <i className="icon-radar" />,
      title: 'common.linkSectorTicker.COMPANY_NEWS',
      action: () => {
        handleOpenNewTab(
          FEATURES.corporate.components['news-events'].components.news.route,
          getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK),
        )
      },
    },
    {
      icon: <i className="icon-search-data" />,
      title: 'common.linkSectorTicker.FINANCIAL_DATA',
      action: () => {
        handleOpenNewTab(
          FEATURES.corporate.components['financial-data'].components[
            'financial-statement'
          ].route,
          getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK),
        )
      },
    },
    {
      icon: <i className="icon-book-mark" />,
      title: 'common.linkSectorTicker.OWNERSHIP',
      action: () => {
        handleOpenNewTab(
          FEATURES.corporate.components['ownership'].route,
          getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK),
        )
      },
    },
    {
      icon: <i className="icon-nen" />,
      title: 'common.linkSectorTicker.TECHNICAL_CHART',
      action: () => {
        handleOpenNewTab(
          FEATURES['technical-chart'].route,
          getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK),
        )
      },
    },
  ]

  return (
    <>
      <span
        ref={parentRef}
        className={`cursor-pointer canvas-chart-title ${
          isHoverText ? style.active : ''
        }`}
        onClick={handleDropdown}
        onMouseOver={overText}
        onMouseOut={outText}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
      >
        {children}
      </span>
      {isOpenDropdown && (
        <Dropdown
          parentRef={parentRef}
          isShow={isOpenDropdown}
          position={position}
        >
          <div
            className={`box-notice ${
              position === positionDropdown.BOTTOM_LEFT_RIGHT
                ? style.dropdownBottom
                : position === positionDropdown.TOP_LEFT_RIGHT
                ? style.dropdownTop
                : position === positionDropdown.BOTTOM_RIGHT_LEFT
                ? style.dropdownBottomRight
                : style.dropdownTopRight
            }`}
          >
            <HandleClickOutside
              exceptRef={parentRef}
              handleClickOutside={handleCloseDropdown}
            >
              <>
                {listAction.map((item, index) => (
                  <div
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation()
                      item.action()
                    }}
                    className={`${style.dropdownItem} cursor-pointer d-flex ali-center`}
                  >
                    <div className={style.itemIcon}>{item.icon}</div>
                    <Span>
                      <p className={style.itemTitle}>
                        <Translate value={item.title} />
                      </p>
                    </Span>
                  </div>
                ))}
              </>
            </HandleClickOutside>
          </div>
        </Dropdown>
      )}
    </>
  )
}

LinkTicker.propTypes = {
  children: PropTypes.node.isRequired,
  organizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default LinkTicker
