import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../../common/hooks/usePackagePermission'
import { Loading } from '../../../../../common/loading'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { TIME_FREQUENCY } from '../constants'
import {
  keys,
  selectActiveMethodType,
  selectActiveTimeType,
  selectFilterIssuanceValueType,
  selectLoading,
} from '../store/slice'
import { downloadIssuanceValueData } from '../store/thunk'
import Filter from './Filter'
import TableContent from './Table'

const IssuanceValue = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const methodType = useSelector(selectActiveMethodType)
  const timeType = useSelector(selectActiveTimeType)
  const { month, quarter, year } = useSelector(selectFilterIssuanceValueType)
  const isLoading = useSelector(selectLoading(keys.ISSUANCE_VALUE))

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    const params = {
      MethodType: methodType,
      TimeRange: timeType,
      Year: year,
    }

    if (timeType === TIME_FREQUENCY.MONTHLY) {
      params.Month = month
    }

    if (timeType === TIME_FREQUENCY.QUARTER) {
      params.Quarter = quarter
    }

    return downloadIssuanceValueData(params)
  }

  return (
    <Panel
      title="bond.corporateBond.issuanceValue.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={!isTrial}
      downloadCsv={() => !isTrial && handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                {isLoading ? <Loading /> : <TableContent />}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default IssuanceValue
