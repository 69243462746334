import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { ANNOUNCEMENT_TYPE } from '../constants'
import { Content } from './Content'

const MAP_KEY = {
  MORE: 'MORE',
}

const MAP_SIZE = {
  [MAP_KEY.MORE]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 1000,
    minHeight: 200,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}
const MAP_TITLE = {
  [ANNOUNCEMENT_TYPE.EARNING]: 'sector.sectorConstituents.newsReport.EARNING',
  [ANNOUNCEMENT_TYPE.TRANSACTION]:
    'sector.sectorConstituents.newsReport.TRANSACTIONS',
  [ANNOUNCEMENT_TYPE.BUSINESS]:
    'sector.sectorConstituents.newsReport.OTHER_BUSINESS_ACTIVITIES',
}

const More = ({ sizes, setSizes }) => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const announcementType =
    params.get('announcementType') || ANNOUNCEMENT_TYPE.EARNING

  return (
    <>
      <Panel
        title={MAP_TITLE[announcementType]}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MORE}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Content
          width={formatNumber(sizes[MAP_KEY.MORE].width) - PANEL_PADDING}
        />
      </Panel>
    </>
  )
}

More.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(More)
