import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import Unit from '../../common/Unit'
import style from './index.module.css'
import {
  getFromDate,
  getLimitTime,
  handleFromDate,
  handleFromISODateView,
  handleIsRecalculateSliceValue,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const currentDate = [new Date()]
  const currentYear = new Date().getFullYear()
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false)

  const dateFrom = useSelector(getFromDate)
  const limitDatepicker = useSelector(getLimitTime)
  const locale = useSelector((state) => state.i18n.locale)

  const onDateFromClose = () => {
    setIsOpenDateFrom(false)
  }

  const clearInputDate = () => {
    onDateFromChange('')
  }

  const onClickDateFrom = () => {
    setIsOpenDateFrom(true)
  }

  const onDateFromChange = (fromDate) => {
    dispatch(handleFromDate(fromDate))
    dispatch(
      handleFromISODateView(
        formatDateTime(fromDate, FORMAT.DATE, locale, timeZone),
      ),
    )
    dispatch(handleIsRecalculateSliceValue(true))
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  return (
    <div className="d-flex align-center j-b" style={{ marginBottom: 12 }}>
      <Unit title={'economy.monetary.monetary.UNIT_VND'} />
      <div className="d-flex align-center">
        <div className="form-date-select">
          <Span style={{ fontSize: 12 }}>
            <Translate value="market.marketInDepth.indexStatistics.priceData.FROM_DATE" />
          </Span>
          <div className="date-select">
            <CalendarPopper
              date={dateFrom ? getDateWithTimeZone(dateFrom) : ''}
              minDate={getDateWithTimeZone(limitDatepicker.minDate)}
              maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
              handleChange={onDateFromChange}
              CustomInput={InputDateCalendar}
              hightlightDates={currentDate}
              customHeader={HeaderCalendarPopper({
                startYear: limitDatepicker.minDate
                  ? +formatDateTime(
                      limitDatepicker.minDate,
                      FORMAT.YEAR,
                      undefined,
                      timeZone,
                    )
                  : currentYear - 10,
                endYear: limitDatepicker.maxDate
                  ? +formatDateTime(
                      limitDatepicker.maxDate,
                      FORMAT.YEAR,
                      undefined,
                      timeZone,
                    )
                  : currentYear,
                zIndex: 11,
              })}
              isOpen={isOpenDateFrom}
              onClose={onDateFromClose}
            />
            <span
              className={`btn-select-date ${style.iconCaret}`}
              onClick={onClickDateFrom}
            >
              <i className="icon-caret-down" />
            </span>
          </div>
          <button
            className={style.buttonClear}
            type="button"
            onClick={() => clearInputDate()}
          >
            <Span style={{ fontSize: 10 }}>
              <Translate value="market.marketInDepth.indexStatistics.priceData.CLEAR_DATE" />
            </Span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default Header
