import { Fragment } from 'react'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { selectMyDashboard } from '../../store/slice'
import style from './index.module.css'
import { Translate } from 'react-redux-i18n'

export const PopupMoveTo = ({
  parentRef,
  isShow,
  moveToDB,
  popupRef,
  dashboardSetting,
  moveToNewDB,
}) => {
  const listGroupDashboard = useSelector(selectMyDashboard)

  const getGroupDashboard = () => {
    return Object.values(listGroupDashboard.children).filter(
      (item) => item.isGroup && item.parentId,
    )
  }

  return (
    <>
      <Dropdown
        parentRef={parentRef}
        isShow={isShow}
        position={positionDropdown.RIGHT_BOTTOM}
      >
        <div ref={popupRef} className={style.dropdownContainer}>
          <ScrollComponent autoHeight autoHeightMax={279} bgColor="#D9D9D9">
            <div className={style.dropdownItem} onClick={moveToNewDB}>
              <Translate value="financialChart.NEW_DASHBOARD_GROUP" />
            </div>
            {getGroupDashboard().map((item) => (
              <Fragment key={item.id}>
                {item.id === dashboardSetting.parentId && style.disable ? (
                  <div className={`${style.dropdownItem} ${style.disable}`}>
                    <TextEllipsis
                      zIndexTooltip={Z_INDEX.DROPDOWN}
                      text={item.name}
                    />
                  </div>
                ) : (
                  <div
                    className={style.dropdownItem}
                    onClick={() => moveToDB(item)}
                  >
                    <TextEllipsis
                      zIndexTooltip={Z_INDEX.DROPDOWN}
                      text={item.name}
                    />
                  </div>
                )}
              </Fragment>
            ))}
          </ScrollComponent>
        </div>
      </Dropdown>
    </>
  )
}
