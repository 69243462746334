import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { TIME_FRAME_OPTIONS } from '../../constants'
import style from '../../index.module.css'
import { handleChangeTimeRange, selectTimeRange } from '../../store/slice'

const TimeFrame = () => {
  const dispatch = useDispatch()
  const timeRange = useSelector(selectTimeRange)

  const onChangeTab = (tab) => {
    dispatch(handleChangeTimeRange(tab.value))
  }
  return (
    <div className={style.timeFrame}>
      <DispatchActionTab
        data={TIME_FRAME_OPTIONS}
        activeTab={timeRange}
        onChangeTab={onChangeTab}
      />
    </div>
  )
}

export default TimeFrame
