import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../html/Button'
import { maxIndicator } from '../constant'
import { deepCopy } from '../helper'
import style from '../index.module.css'
import {
  selectIndicatorPopupIndicator,
  selectPopupIndicator,
} from '../store/slice'

const ButtonSubmit = ({
  submit,
  maxIndicator,
  lengthIndicator,
  isError,
  ratio,
  isCancelDisable,
}) => {
  const dataSubmit = useSelector(selectPopupIndicator)
  const indicator = useSelector(selectIndicatorPopupIndicator)

  const handleSubmit = () => {
    submit({ ...dataSubmit, valuePopupIndicator: deepCopy(dataSubmit.detail) })
  }

  const totalIndicator = indicator.length * ratio + lengthIndicator
  const isDisabledSubmit =
    (totalIndicator > maxIndicator ||
      isError ||
      indicator.length * ratio === 0) &&
    !isCancelDisable

  return (
    <Button
      onClick={handleSubmit}
      style={{ fontSize: 11 }}
      className={`btn btn-blue w-80 h-20 ${
        isDisabledSubmit ? style.disabled : ''
      }`}
      disabled={isDisabledSubmit}
    >
      <Translate value="tool.smartScreening.APPLY" />
    </Button>
  )
}

ButtonSubmit.propTypes = {
  submit: PropTypes.func.isRequired,
  isError: PropTypes.bool,
  ratio: PropTypes.number,
  maxIndicator: PropTypes.number,
  isCancelDisable: PropTypes.bool,
}

ButtonSubmit.defaultProps = {
  isError: false,
  ratio: 1,
  maxIndicator: maxIndicator,
  isCancelDisable: false,
}

export default ButtonSubmit
