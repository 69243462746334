import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valDivMillion,
} from '../../../../../utils/Value'
import { getMinData } from '../../../common/helper'
import { VIEW_BY } from '../../constants'
import { convertData } from '../helper'
import { getTableData } from './thunk'

const initialState = {
  isLoading: true,
  dataTable: [],
  timeFilter: VIEW_BY.YEARLY,
  valueById: {},
  ids: [],
  groupColumns: [],
  payloadCallBack: {
    month: undefined,
    year: undefined,
  },
  maxMonthData: null,
  maxYearData: null,
}

export const slice = createSlice({
  name: 'economy/fdi/overview/tableData',
  initialState,
  reducers: {
    setDataKeyById: (state, action) => {
      state.valueById = action.payload
    },
    changeTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    resetDataTable: (state) => {
      state.dataTable = []
      state.payloadCallBack = {
        month: undefined,
        year: undefined,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTableData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTableData.fulfilled, (state, action) => {
      const tableData = action.payload || []
      state.payloadCallBack = {
        month: getMinData(tableData).minMonth,
        year: getMinData(tableData).minYear,
      }

      state.dataTable = [...tableData, ...state.dataTable]

      const data = convertData(state.dataTable, state.timeFilter)
      const groupColumns = []
      const dataKeyArr = Object.values(state.valueById)
      data.forEach((item) => {
        groupColumns.push({
          key: item.year,
          title: item.year,
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? item[e.key] : ''
          e[item.year] = e.isFormatMillion
            ? formatVal(valDivMillion(value))
            : value
        })
      })
      state.groupColumns = data.length
        ? [{ key: 'name', title: '' }, ...groupColumns]
        : []
      state.isLoading = false
      state.maxMonthData = tableData[0]?.maxMonth
      state.maxYearData = tableData[0]?.maxYear
    })
    builder.addCase(getTableData.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectTimeFilter = (state) => state[slice.name].timeFilter
export const selectStructureIds = (state) => state[slice.name].ids
export const selectStructureValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueById[id], attr)
}
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectItemData = (id) => (state) => state[slice.name].valueById[id]
export const selectPayloadCallBack = (state) =>
  state[slice.name].payloadCallBack
export const selectMaxMonthData = (state) => state[slice.name].maxMonthData
export const selectMaxYearData = (state) => state[slice.name].maxYearData

export const { changeTimeFilter, changeIds, setDataKeyById, resetDataTable } =
  slice.actions

register(slice.name, slice.reducer)
