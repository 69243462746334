import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceStatisticService from '../../../../../../core/services/market/equityTrading/PriceStatisticService'

export const getNetValueIndividualChart = createAsyncThunk(
  'market/equityTrading/priceStatistics/netValue/GET_NET_VALUE_INDIVIDUAL_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getNetValueIndividualChart(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getNetValueForeignChart = createAsyncThunk(
  'market/equityTrading/priceStatistics/netValue/GET_NET_VALUE_FOREIGN_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getNetValueForeignChart(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getNetValueInstitutionChart = createAsyncThunk(
  'market/equityTrading/priceStatistics/netValue/GET_NET_VALUE_INSTITUTION_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getNetValueInstitutionChart(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getNetValueProprietaryChart = createAsyncThunk(
  'market/equityTrading/priceStatistics/netValue/GET_NET_VALUE_PROPRIETARY_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getNetValueProprietaryChart(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
