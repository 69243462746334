import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import { positionDropdown } from '../dropdown'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { Span } from '../html/Span'
import style from './index.module.css'

const useClientRect = () => {
  const [rect, setRect] = useState(null)
  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node)
    }
  }, [])
  return [rect, ref]
}

const DropdownTooltipCustom = ({
  containerRef,
  zIndexTooltip,
  isI18n,
  text,
  isHtml,
  styleTextTooltip,
  dropdownSpace,
  maxWidthTooltip,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [positionTooltip, setPositionTooltip] = useState()
  const [rect, ref] = useClientRect()

  const isDropdownTop =
    containerRef.current?.getBoundingClientRect()?.top >
    rect?.getBoundingClientRect()?.height

  useEffect(() => {
    const { left, right } = containerRef.current.getBoundingClientRect()

    let position

    if (isDropdownTop) {
      if (left < maxWidthTooltip) {
        position = positionDropdown.TOP_LEFT_RIGHT
      } else if (window.innerWidth - right < maxWidthTooltip) {
        position = positionDropdown.TOP_RIGHT_LEFT
      } else {
        position = positionDropdown.TOP_CENTER
      }
    } else {
      if (left < maxWidthTooltip) {
        position = positionDropdown.BOTTOM_LEFT_RIGHT
      } else if (window.innerWidth - right < maxWidthTooltip) {
        position = positionDropdown.BOTTOM_RIGHT_LEFT
      } else {
        position = positionDropdown.BOTTOM_CENTER
      }
    }

    setPositionTooltip(position)
  }, [rect, isDropdownTop])

  const getStyles = useMemo(() => {
    if (!rect || !containerRef?.current) return {}

    let nodePosition = {}

    const { top, left, bottom, right } =
      containerRef.current.getBoundingClientRect()

    switch (positionTooltip) {
      case positionDropdown.TOP_CENTER:
        nodePosition.top = top - rect?.offsetHeight - dropdownSpace
        nodePosition.left =
          left - (rect?.offsetWidth - containerRef.current?.offsetWidth) / 2
        break
      case positionDropdown.TOP_LEFT_RIGHT:
        nodePosition.top = top - rect?.offsetHeight - dropdownSpace
        nodePosition.left = left
        break
      case positionDropdown.TOP_RIGHT_LEFT:
        nodePosition.top = top - rect?.offsetHeight - dropdownSpace
        nodePosition.left = right - rect?.offsetWidth
        break
      case positionDropdown.BOTTOM_CENTER:
        nodePosition.top = bottom + dropdownSpace
        nodePosition.left =
          left - (rect?.offsetWidth - containerRef.current?.offsetWidth) / 2
        break
      case positionDropdown.BOTTOM_LEFT_RIGHT:
        nodePosition.top = bottom + dropdownSpace
        nodePosition.left = left
        break
      case positionDropdown.BOTTOM_RIGHT_LEFT:
        nodePosition.top = bottom + dropdownSpace
        nodePosition.left = right - rect?.offsetWidth
        break
      default:
        nodePosition.top = top - rect?.offsetHeight
        nodePosition.left = left
    }

    return Object.keys(nodePosition).reduce(
      (obj, key) => ({ ...obj, [key]: formatWithZoom(nodePosition[key]) }),
      {},
    )
  }, [rect, containerRef?.current, positionTooltip])

  const getStylesArrow = useMemo(() => {
    switch (positionTooltip) {
      case positionDropdown.TOP_LEFT_RIGHT:
      case positionDropdown.BOTTOM_LEFT_RIGHT:
        return style.tooltipLeft
      case positionDropdown.TOP_RIGHT_LEFT:
      case positionDropdown.BOTTOM_RIGHT_LEFT:
        return style.tooltipRight
      default:
        return ''
    }
  }, [positionTooltip])

  const getStylesArrowPosition = useMemo(
    () => (isDropdownTop ? style.tooltipTop : style.tooltipBottom),
    [isDropdownTop],
  )

  return createPortal(
    <div
      ref={ref}
      className={`box-notice ${getStylesArrow} ${getStylesArrowPosition}`}
      style={{
        padding: 9,
        maxWidth: maxWidthTooltip,
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: zIndexTooltip,
        width: 'max-content',
        ...getStyles,
      }}
    >
      {isI18n ? (
        <Span style={{ fontSize: 9, ...styleTextTooltip }}>
          <Translate value={text} dangerousHTML className={style.breakWord} />
        </Span>
      ) : isHtml ? (
        <Span style={{ fontSize: 9, ...styleTextTooltip }}>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
            className={style.breakWord}
          ></div>
        </Span>
      ) : (
        <Span style={{ fontSize: 9, ...styleTextTooltip }}>
          <span className={style.breakWord}>{text}</span>
        </Span>
      )}
    </div>,
    document.querySelector('body'),
  )
}

DropdownTooltipCustom.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  isI18n: PropTypes.bool,
  zIndexTooltip: PropTypes.number,
  containerRef: PropTypes.object.isRequired,
  styleTextTooltip: PropTypes.object,
}

DropdownTooltipCustom.defaultProps = {
  isI18n: true,
  zIndexTooltip: Z_INDEX.MODAL + 10,
  isHtml: false,
  styleTextTooltip: {},
  dropdownSpace: 10,
  maxWidthTooltip: 300,
}

export default DropdownTooltipCustom
