export const chartFillColor = {
  nplr: '#1d97ef',
  smlr: '#f7959c',
}

export const chartLeftKeys = ['nplr', 'smlr']

export const chartMappingDisplayName = {
  nplr: 'sector.financialAnalysis.bank.assetQuality.NPL',
  smlr: 'sector.financialAnalysis.bank.assetQuality.SML',
}

export const chartFormat = {
  nplr: 0.01,
  smlr: 0.01,
}
