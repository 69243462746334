import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ImportChart } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  selectCurrentSelection,
  selectListSelection,
  selectMaxYear,
  selectYear,
} from './store/slice'

const PREV_YEAR_GAP = 4

export const PanelImportChart = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const currentSelection = useSelector(selectCurrentSelection)
  const listSelection = useSelector(selectListSelection)
  const currentYear = useSelector(selectYear)
  const maxYear = useSelector(selectMaxYear)
  const locale = useSelector((state) => state.i18n.locale)

  const currentSelectionName =
    listSelection.length &&
    listSelection?.find((item) => item.value === currentSelection).name
  const currentSelectedYear = currentYear ?? maxYear

  const nameScreen = I18n.t('sector.sectorSpecific.fishery.OVERVIEW')
  const getChartNameByLocale = () => {
    if (locale === 'en') {
      return `${currentSelectionName} ${I18n.t(
        'sector.sectorSpecific.fishery.overview.EXPORT_ONLY',
      )} ${currentSelectedYear - PREV_YEAR_GAP} -${currentSelectedYear}`
    } else {
      return `${I18n.t(
        'sector.sectorSpecific.fishery.overview.EXPORT_ONLY',
      )} ${currentSelectionName} ${
        currentSelectedYear - PREV_YEAR_GAP
      } -${currentSelectedYear}`
    }
  }

  return (
    <Panel
      title="sector.sectorSpecific.fishery.overview.EXPORT_YEARLY"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={getChartNameByLocale()}
      downloadJpgParams={{
        domId: 'importChart',
        nameScreen: nameScreen,
        chartName: getChartNameByLocale(),
      }}
    >
      <ImportChart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
