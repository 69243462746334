import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import { placeholderDateCalendar } from '../../../../common/calendar/helper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import { positionDropdown } from '../../../../common/dropdown'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { LASTEST_YEARS, TIME_SERIES_TYPE } from '../constant'
import style from '../index.module.css'
import {
  changeTimeSeries,
  changeTimeSeriesType,
  selectTimeSeries,
  selectTimeSeriesType,
} from '../store/slice'

const ItemRadio = ({ item }) => {
  const dispatch = useDispatch()

  const timeSeriesType = useSelector(selectTimeSeriesType)

  const handleClick = () => {
    dispatch(changeTimeSeriesType(item.value))
  }

  const isActive = timeSeriesType === item.value

  return (
    <div className="d-flex mt-8 ml-8">
      <span onClick={handleClick} className="d-flex ali-center cursor-pointer">
        <input
          type="radio"
          className="radiobox radiobox2"
          checked={isActive}
          readOnly
        />
        <span className={isActive ? style.activeCheck : ''}>
          <Translate value={item.name} />
        </span>
      </span>
    </div>
  )
}

const InputDate = ({ value, onChange }) => {
  const locale = useSelector((state) => state.i18n.locale)

  const handleChange = (value) => onChange(value || null)

  return (
    <div className={`h-20 d-flex ali-center ${style.calendar}`}>
      <CalendarPopper
        date={value ? new Date(value) : ''}
        handleChange={handleChange}
        customHeader={HeaderOneCalendarRange({
          startYear: 2000,
          endYear: new Date().getFullYear(),
          maxHeightDropdown: 160,
        })}
        CustomInput={InputDateCalendar}
        placeholder={placeholderDateCalendar(locale)}
        customInputStyle={{ fontSize: 10 }}
        popperClassName={style.popperCalendar}
        position={positionDropdown.BOTTOM_CENTER}
      />
    </div>
  )
}

const TimeRange = () => {
  const dispatch = useDispatch()

  const timeSeries = useSelector(selectTimeSeries)

  const handleChangeYear = (year) => {
    dispatch(changeTimeSeries({ year }))
  }

  const handleChangeStartDate = (date) => {
    dispatch(changeTimeSeries({ startDate: date }))
  }

  const handleChangeEndDate = (date) => {
    dispatch(changeTimeSeries({ endDate: date }))
  }

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.TIME_RANGE" />
      </Span>
      <ItemRadio
        item={{
          value: TIME_SERIES_TYPE.RANGE,
          name: 'sector.sectorConstituents.segmentation.timeSeriesType.RANGE',
        }}
      />
      <div className="d-flex ali-center justify-content-end mt-8">
        <div className={`${style.w40} ${style.disabled}`}>
          <Translate value="sector.sectorConstituents.segmentation.FROM" />
        </div>
        <InputDate
          value={timeSeries.startDate}
          onChange={handleChangeStartDate}
        />
      </div>
      <div className="d-flex ali-center justify-content-end mt-8">
        <div className={`${style.w40} ${style.disabled}`}>
          <Translate value="sector.sectorConstituents.segmentation.TO" />
        </div>
        <InputDate value={timeSeries.endDate} onChange={handleChangeEndDate} />
      </div>

      <ItemRadio
        item={{
          value: TIME_SERIES_TYPE.YEAR,
          name: 'sector.sectorConstituents.segmentation.timeSeriesType.YEAR',
        }}
      />
      <div className="d-flex ali-center justify-content-end mt-8">
        <div className={`${style.w40} ${style.disabled}`}>
          <Translate value="sector.sectorConstituents.segmentation.YEAR" />
        </div>
        <div className={style.w208}>
          <SelectCustom
            isI18n={false}
            selectData={LASTEST_YEARS}
            value={timeSeries.year}
            handleChange={handleChangeYear}
            fontSizeItem={10}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeRange
