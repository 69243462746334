import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import UseFontSize from '../../hooks/useFontSize'
import { CHART_TYPES } from '../constants'
import { FooterContainer } from '../footer/FooterContainer'
import { TYPE } from '../footer/Item'
import FooterWithScroll from '../footer/footerWithScroll'
import { footerProps } from './props'

export const Footer = ({
  width,
  colors,
  schema,
  footerProps,
  setHeightFooter,
  onDelete,
  onHover,
  onUnHover,
}) => {
  const legendPanel = useRef()
  const fontSize = UseFontSize()

  const locale = useSelector((state) => state.i18n.locale)

  const getSchema = useMemo(() => {
    const footerSchema = schema.map((item, index) => {
      let itemSchema = {
        id: item.dataKey,
        text: item.text,
        isI18n: item.isI18n,
        type: TYPE.SQUARE,
      }

      if (item.chart) {
        switch (item.chart.type) {
          case CHART_TYPES.BAR:
          case CHART_TYPES.STACK_BAR:
            itemSchema.type = TYPE.SQUARE
            break
          case CHART_TYPES.AREA:
          case CHART_TYPES.STACK_AREA:
            itemSchema.type = TYPE.SQUARE
            break
          case CHART_TYPES.LINE:
            itemSchema.type = TYPE.LINE
            break
          case CHART_TYPES.DOT:
            itemSchema.type = TYPE.CIRCLE
            break
          default:
            break
        }
      }

      if (item.footer) {
        if (item.footer.type) {
          itemSchema.type = item.footer.type
        }

        if (item.footer.before) {
          itemSchema.before = item.footer.before
        }

        if (item.footer.after) {
          itemSchema.after = item.footer.after
        }

        if (item.footer.dashedLine) {
          itemSchema.dashedLine = item.footer.dashedLine
        }

        if (item.footer.tooltip) {
          itemSchema.tooltip = item.footer.tooltip
        }

        if (item.footer.renderIcon) {
          itemSchema.renderIcon = item.footer.renderIcon
        }
      }

      if (!itemSchema.before && !itemSchema.after && !itemSchema.dashedLine) {
        itemSchema = {
          ...itemSchema,
          before: { bgColor: colors[index] },
          after: { bgColor: colors[index] },
        }
      }

      if (footerProps.isCheckbox) {
        itemSchema.dataKey = item.dataKey
      }

      return itemSchema
    })

    return footerSchema
  }, [
    JSON.stringify(colors),
    JSON.stringify(schema),
    JSON.stringify(footerProps),
  ])

  const getSchemaIds = getSchema.map((item) => item.id)

  if (footerProps.isFooterScroll) {
    return (
      <FooterWithScroll
        width={width}
        data={getSchema}
        idKey="id"
        nameKey="text"
        formatName={
          footerProps.formatName
            ? (item) => footerProps.formatName(item)
            : undefined
        }
        color={(item) => {
          return colors[getSchemaIds.indexOf(item.id)]
        }}
        legendPanel={legendPanel}
        isFullScreen={footerProps.isFullScreen}
        setHeightFooter={setHeightFooter}
        onDelete={onDelete}
        onHover={onHover}
        onUnHover={onUnHover}
      />
    )
  }

  return (
    <FooterContainer
      key={fontSize + locale + footerProps.isFullScreen}
      data={getSchema}
      {...footerProps}
    />
  )
}

Footer.propTypes = {
  width: PropTypes.number.isRequired,
  colors: PropTypes.array,
  schema: PropTypes.array.isRequired,
  footerProps: footerProps,
  setHeightFooter: PropTypes.func,
  onDelete: PropTypes.func,
  onHover: PropTypes.func,
  onUnHover: PropTypes.func,
}

Footer.defaultProps = {
  setHeightFooter: () => {},
  onDelete: () => {},
  onHover: () => {},
  onUnHover: () => {},
}
