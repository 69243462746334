import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Popup from '../../../common/popup'
import { SEC_GROUP } from '../../common/constants'
import { getDataLatestPeriod } from '../../store/thunk'
import style from '../styleTheme.module.css'
import { PopupContent } from './PopupContent'
import { PopupFooter } from './PopupFooter'

export const categoryDefault = {
  [SEC_GROUP.COMPANY]: 30,
  [SEC_GROUP.INDEX]: 4124,
  [SEC_GROUP.SECTOR]: 4567,
}

const PopupAddIndicator = ({ setIsShow, typeIndicator, indicatorItem }) => {
  const dispatch = useDispatch()

  const [itemChoose, setItemChoose] = useState({})
  const [category, setCategory] = useState(categoryDefault[typeIndicator])
  const [indicator, setIndicator] = useState(null)

  const handleCancel = () => {
    setIsShow(false)
  }

  useEffect(() => {
    if (itemChoose?.value) {
      const { id, timeFrequency } = itemChoose.value
      dispatch(
        getDataLatestPeriod({
          timeFrequency: timeFrequency,
          indicatorId: id,
        }),
      )
    }
  }, [JSON.stringify(itemChoose)])

  useEffect(() => {
    if (indicatorItem) {
      setCategory(indicatorItem.parentId)
      setIndicator([indicatorItem.id, indicatorItem.timeFrequency].join('_'))
      setItemChoose({
        typeIndicator: typeIndicator,
        value: {
          ...indicatorItem,
        },
      })
    }
  }, [indicatorItem])

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div
        className={`modal modal-medium ${style.modal}`}
        style={{ minWidth: 580 }}
      >
        <div className={`modal-title ${style.titleStyle}`}>
          <h3 className="text-up">
            <Translate value="financialChart.ADD_INDICATORS" />
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentStyle}`}>
          <PopupContent
            typeIndicator={typeIndicator}
            category={category}
            indicator={indicator}
            setCategory={setCategory}
            setIndicator={setIndicator}
            setItemChoose={setItemChoose}
            indicatorItem={indicatorItem}
          />
          <PopupFooter
            typeIndicator={typeIndicator}
            category={category}
            indicator={indicator}
            itemChoose={itemChoose}
            onCancel={handleCancel}
            indicatorItemReplace={indicatorItem}
          />
        </div>
      </div>
    </Popup>
  )
}

export default PopupAddIndicator
