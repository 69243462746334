import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import {
  removeCategoryFromList,
  selectCurrentTypeStatistics,
  selectListCategory,
} from '../balanceOfPaymentTable/store/slice'
import { BALANCE_PAYMENT_FILTER } from '../constants'
import { ChartComponent } from './ChartComponent'
import { COLORS_LINE_CHART, TIME_RANGE } from './constants'
import {
  changeActiveCategory,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { getGrowthByTimeRange } from './store/thunk'

export const ChangeBalance = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const headerRef = useRef()
  const legendPanel = useRef()

  // Use selector
  const dataChart = useSelector(selectDataChart)
  const listCategory = useSelector(selectListCategory)
  const isLoading = useSelector(selectLoading)
  const currentTypeStatistics = useSelector(selectCurrentTypeStatistics)

  const [currentDateType, setCurrentDateType] = useState(
    TIME_RANGE.MONTHLY[0].value,
  )
  const [currentDateTypeYearly, setCurrentDateTypeYearly] = useState(
    TIME_RANGE.YEARLY[0].value,
  )
  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListCategory, setHeightListCategory] = useState(0)

  const isYearly = currentTypeStatistics === BALANCE_PAYMENT_FILTER[0].value
  const getIdsInListCategory = listCategory?.map((item) => item.id)

  // Actions
  const handleDelete = (item) => {
    dispatch(removeCategoryFromList(item.id))
    dispatch(changeActiveCategory(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveCategory(item.id))
  }

  const handleUnHover = () => {
    dispatch(changeActiveCategory(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListCategory)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListCategory,
      )
  }, [height, width, listCategory, heightListCategory])

  useEffect(() => {
    if (!isYearly) {
      dispatch(
        getGrowthByTimeRange({
          DataType: currentTypeStatistics,
          TimeRange: currentDateType,
        }),
      )
    }
  }, [currentDateType, currentTypeStatistics])

  useEffect(() => {
    if (isYearly) {
      dispatch(
        getGrowthByTimeRange({
          DataType: currentTypeStatistics,
          TimeRange: currentDateTypeYearly,
        }),
      )
    }
  }, [currentDateTypeYearly, currentTypeStatistics])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="h-100">
        <div className="d-flex justify-content-end" ref={headerRef}>
          <DispatchActionTab
            data={isYearly ? TIME_RANGE.YEARLY : TIME_RANGE.MONTHLY}
            activeTab={isYearly ? currentDateTypeYearly : currentDateType}
            onChangeTab={(item) =>
              isYearly
                ? setCurrentDateTypeYearly(item.value)
                : setCurrentDateType(item.value)
            }
          />
        </div>
        {!dataChart.length ? (
          <NoData />
        ) : (
          <div id="changeBalance">
            <ChartComponent
              data={dataChart}
              width={width}
              height={heightSizeTracker}
              keyXAxis={'time'}
              lineKeys={getIdsInListCategory}
            />
            <FooterWithScroll
              width={width}
              data={listCategory}
              idKey="id"
              nameKey="name"
              color={(item) =>
                COLORS_LINE_CHART[getIdsInListCategory.indexOf(item.id)]
              }
              legendPanel={legendPanel}
              isFullScreen={isFullScreen}
              setHeightFooter={setHeightListCategory}
              onDelete={handleDelete}
              onHover={handleHover}
              onUnHover={handleUnHover}
            />
          </div>
        )}
      </div>
    </>
  )
}
