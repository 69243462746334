const TdDownload = ({ val }) => {
  return (
    <div className="t-center">
      <a className="cursor-pointer" href={val}>
        <i className="icon-download" />
      </a>
    </div>
  )
}

export default TdDownload
