export const companySnapshot = {
  SETTING: 'Cài đặt',
  COMPANY_OVERVIEW: 'Tổng quan doanh nghiệp',
  COMPANY_PROFILE: 'Hồ sơ Doanh nghiệp',
  COMPANY_POSITION: 'Vị thế Doanh nghiệp',
  BUSINESS_STRATEGIES: 'Chiến lược kinh doanh',
  STOCK_DATA: 'Dữ liệu Cổ phiếu',
  ONE_YEAR_PRICE_AND_VOLUME: '1 Năm; Giá và Khối lượng',
  FINANCIAL_RATIOS: 'Chỉ số tài chính',
  VALUATION_HISTORY: 'Lịch sử định giá',
  PEER_ANALYSIS_AND_FINANCIAL_DATA: 'So sánh Doanh nghiệp & Dữ liệu tài chính',
  PEER_ANALYSIS: 'So sánh Doanh nghiệp',
  FINANCIAL_DATA: 'Dữ liệu tài chính',
  YOUR_COMMENTARY_ON_EARNINGS:
    'Bình luận của quý vị đối với lợi nhuận của công ty',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE:
    'Bình luận không quá 500 ký tự, bao gồm dầu cách và các kí tự đặc biệt',
  OWNERSHIP_ANALYSIS: 'Phân tích sở hữu',
  MARKET_DATA: 'Dữ liệu thị trường',
  PRICE_DATA: 'Dữ liệu giá',
  STOCK_PERFORMANCE_VS_INDEX: 'Biến động cổ phiếu so với Index',
  PRICE_PERFORMANCE: 'Biến động giá',
  FOREIGN_NET_BUYING_SELLING: 'Giao dịch NĐTNN',
  FINANCIAL_STATEMENTS: 'Báo cáo tài chính',
  BALANCE_SHEET: 'Cân đối kế toán',
  INCOME_STATEMENT: 'Kết quả kinh doanh',
  PRICE_VND: 'Giá (VND)',
  HIGHEST_52W: 'Cao nhất 52 tuần (VND)',
  LOWEST_52W: 'Thấp nhất 52 tuần (VND)',
  OUTSTANDING_SHARE: 'SLCP lưu hành (triệu CP)',
  PERCENT_FOREIGN_OWNED: 'Sở hữu nước ngoài',
  FOREIGN_CURRENT_ROOM: 'Room khối ngoại hiện tại',
  MARKET_CAP_BILLION_VND: 'Vốn hóa (Tỷ VND)',
  DIVIDEND_YIELD: 'Tỷ suất cổ tức',
  SECTOR: 'Ngành: ',
  TICKER: 'Mã: ',
  EXCHANGE: 'Sàn: ',
  OUTSTANDING_SHARE_1: 'SLCP lưu hành (triệu CP)',
  FREE_FLOAT_SHARE: 'CP lưu hành tự do (triệu CP):',
  REPORT_DATE: 'Ngày báo cáo: ',
  COMPARE_WITH_SECTOR: 'So sánh với ngành',
  REVENUE_BREAKDOWN: 'Cơ cấu doanh thu',
  PRICE_PERFORMANCE_CHART: 'Biểu đồ diễn biến giá',
  REPORT_TYPE: 'Loại báo cáo',
  REPORT_FORM: 'Chuẩn báo cáo',
  AUDIT_STATUS: 'Trạng thái kiểm toán',
  CURRENCY: 'Tiền tệ',
  SELECT_REPORTING_PERIOD: 'Kỳ báo cáo',
  COMPANY: 'Công ty',
  BANK: 'Ngân hàng',
  SECURITIES: 'Chứng khoán',
  INSURANCE: 'Bảo hiểm',
  CONSOLIDATED: 'Hợp nhất',
  PARENT: 'Công ty mẹ',
  FINANCIAL_STATEMENTS_SETTING: 'Tùy chỉnh báo cáo tài chính',
  AUDITED: 'Kiểm toán',
  UNAUDITED: 'Chưa kiểm toán',
  BOTH_AUDITED_AND_UNAUDITED: 'Cả kiểm toán và chưa kiểm toán',
  YEAR: 'Năm',
  QUARTER: 'Quý',
  BILLION_VND: 'Tỷ VND',
  MILLION_VND: 'Triệu VND',
  PE: 'P/E',
  PB: 'P/B',
  EP_VND: 'EPS (VND)',
  BVPS_VND: 'BVPS (VND)',
  GROSS_MARGIN: 'Tỷ suất LN gộp',
  EBIT_MARGIN: 'Tỷ suất EBIT',
  EBITDA_MARGIN: 'Tỷ suất EBITDA',
  NET_MARGIN: 'Tỷ suất LN ròng',
  ROE: 'ROE',
  ROA: 'ROA',
  CURRENT_RATIO: 'Tỷ suất thanh toán hiện thời',
  QUICK_RATIO: 'Tỷ suất thanh toán nhanh',
  TOTAL_LIABILITIES_TOTAL_ASSETS: 'Tổng nợ phải trả/ Tổng tài sản',
  TOTAL_DEBTS_EQUITY: 'Tổng nợ/ VCSH',
  DISCLAIMER: 'Điều khoản miễn trách',
  TEXT_DISCLAIMER:
    'Bản quyền thuộc về CTCP FiinGroup Việt Nam 2015 - 2022. Báo cáo này được trích xuất tự động từ FiinProX® Platform dựa trên các thông tin công bố công khai, dữ liệu phát triển nội bộ và các nguồn khác được cho là đáng tin cậy, nhưng chưa được kiểm chứng độc lập bởi FiinGroup. FiinGroup sẽ không đại diện hoặc đảm bảo đối với tính chính xác, đúng đắn và đầy đủ của những thông tin trong báo cáo này. Báo cáo này chỉ nhằm mục đích cung cấp thông tin cho các khách hàng sử dụng FiinProX, và không phải là một lời đề nghị hoặc mời chào mua hoặc bán bất kỳ mã chứng khoán nào được đề cập ở đây. Các diễn biến trong quá khứ, nếu có, không hàm ý cho những kết quả tương lai. Nhà đầu tư phải có quyết định đầu tư dựa trên những ý kiến độc lập tùy theo tình trạng tài chính hay mục tiêu đầu tư cụ thể. Báo cáo này không được phép sao chép, tái xuất bản, phát hành cũng như tái phân phối cho bất kỳ mục đích nào nếu không có văn bản chấp thuận của người đại diện được ủy quyền của FiinGroup. Xin vui lòng ghi rõ nguồn FiinProX khi trích dẫn.',
  CONTACT: 'Liên hệ',
  TEXT_CONTACT:
    'FIINGROUP JSC <br/> Trụ sở: Tầng 10, Tòa nhà PeakView, 36 Hoàng Cầu, Đống Đa, Hà Nội, Việt Nam <br/> Chi nhánh: Tầng 16, Tòa nhà Bitexco Financial Tower, số 2 Hải Triều , Phường Bến Nghé, Quận 1, TP. Hồ Chí Minh, Việt Nam <br/> Email: fiinpro@fiingroup.vn',
  TEXT_CONTACT1:
    'TRUNG TÂM CHĂM SÓC KHÁCH HÀNG <br/> T: (84-24) - 3562 6962 - Ext: 103 <br/> Email: fiinpro@fiingroup.vn',
  TABLE_TOP_5: 'Top 5 Cổ đông lớn & HĐQT',
  KEY_FINANCIAL_DATA: 'Dữ liệu tài chính cơ bản',
  REPORT_DATE_1: 'Ngày báo cáo',
  TODAY_CHANGE: 'Thay đổi giá hôm nay',
  YTD_CHANGE: 'Thay đổi giá từ đầu năm đến bây giờ',
  AVG_DAILY_VOL_3M: 'KLGD trung bình 3 tháng (CP)',
  AVG_DAILY_VAL_3M: 'GTGD trung bình 3 tháng (triệu đồng)',
  W_RANGE: 'Dao động giá 52W',
  HIGHEST: 'Cao nhất *',
  LOWEST: 'Thấp nhất *',
  INCOME_STATEMENT_BILLION_VND: 'Kết quả kinh doanh - Tỷ VND',
  INCOME_STATEMENT_MILLION_VND: 'Kết quả kinh doanh - triệu VND',
  INCOME_STATEMENT_VND: 'Kết quả kinh doanh - VND',
  SALES: 'Doanh số',
  NET_SALES: 'Doanh số thuần',
  GROSS_PROFIT: 'Lãi gộp',
  FINANCIAL_INCOME: 'Thu nhập tài chính',
  FINANCIAL_EXPENSES: 'Chi phí tài chính',
  OF_WHICH_INTEREST_EXPENSES: 'Chi phí tiền lãi',
  GAIN_FORM_JOINT_VENTURE: 'Lãi/(lỗ) từ công ty liên doanh',
  SELLING_EXPENSES: 'Chi phí bán hàng',
  GENERAL_AND_ADMIN_EXPENSES: 'Chi phí quản lí doanh nghiệp',
  OPERARING_PROFIT: 'Lãi/(lỗ) từ hoạt động kinh doanh',
  NET_OTHER_INCOME: 'Thu nhập khác, ròng',
  NET_ACCOUNTING_PROFIT: 'Lãi/(lỗ) ròng trước thuế',
  BUSINESS_INCOME_TAX_ENPENSES: 'Chi phí thuế thu nhập doanh nghiệp',
  NET_PROFIT_AFTER_TAX: 'Lãi/(lỗ) thuần sau thuế',
  MINORITY_INTERESTS: 'Lợi ích của cổ đông thiểu số',
  ATTRIBUTE_TO_PARENT_COMPANY: 'Lợi nhuận Cổ đông của Công ty mẹ',
  CURRENT_ASSETS: 'Tài sản ngắn hạn',
  CASH_AND_CASCH_EQUIVALENTS: 'Tiền và tương đương tiền',
  SHORT_TERM_INVESTMENTS: 'Đầu tư tài chính ngắn hạn',
  ACCOUNT_RECEIVABLE: 'Các khoản phải thu',
  INVENTORIES: 'Hàng tồn kho',
  OTHER_CURRENT_ASSETS: 'Tài sản lưu động khác',
  LONG_TERM_ASSETS: 'Tài sản dài hạn',
  LONG_TERM_TRADE_RECEIVABLES: 'Phải thu dài hạn',
  FIXED_ASSETS: 'Tài sản cố định',
  INVESTMENT_PROPERTIES: 'Giá trị ròng tài sản đầu tư',
  LONG_TERM_INCOMPLETE_ASSETS: 'Tài sản dở dang dài dạn',
  LONG_TERM_INVESTMENTS: 'Đầu tư dài hạn',
  OTHER_LONG_TERM_ASSETS: 'Tài sản dài hạn khác',
  GOODWILL: 'Lợi thế thương mại',
  TOTAL_ASSETS: 'TỔNG CỘNG TÀI SẢN',
  LIABILITIES: 'NỢ PHẢI TRẢ',
  CURRENT_LIABILITES: 'Nợ ngắn hạn',
  TRADE_ACCOUNTS_PAYABLE: 'Phải trả người bán',
  SHORT_TERM_UNREALIZED_REVENUE: 'Doanh thu chưa được thực hiện ngắn hạn',
  SHORT_TERM_BORROWINGS: 'Vay và nợ thuê tài chính ngắn hạn',
  LONG_TERM_LIABILITES: 'Nợ dài hạn',
  LONG_TERM_TRADE_PAYABLES: 'Phải trả nhà cung cấp dài hạn',
  UNREALIZED_REVENUE: 'Doanh thu chưa thưc hiện',
  LONG_TERM_BORROWINGS: 'Vay và nợ thuê tài chính dài hạn',
  OWNERS_EQUITY: 'VỐN CHỦ SỞ HỮU',
  CAPITAL_AND_RESEARVES: 'Vốn và các quỹ',
  PAID_IN_CAPITAL: 'Vốn góp',
  SHARE_PREMIUM: 'Thặng dư vốn cổ phần',
  OWNERS_OTHER_CAPITAL: 'Vốn khác',
  UNDISTRIBUTED_EARNINGS: 'Lãi chưa phân phối',
  MINIORITY_INTERESTS: 'Lợi ích cổ đông không kiểm soát',
  BUDGET_SOURCES_AND_OTHER_FUNDS: 'Vốn Ngân sách nhà nước và quỹ khác',
  TOTAL_RESOURCES: 'TỔNG CỘNG NGUỒN VỐN',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE1: '* Bình luận này là của người dùng (',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE2:
    '). FiinGroup sẽ không đại diện hoặc đảm bảo đối với tính chính xác, đúng đắn và đầy đủ của những thông tin trong bình luận này.',
  PRICE: 'Giá',
  VOLUME: 'Khối lượng',
  PRICE1: '(Giá)',
  PE1: 'P/E(Lần)',
  NET_BUYING_VALUE: 'Giá trị mua ròng',
  NET_SELLING_VALUE: 'Giá trị bán ròng',
  BALANCE_SHEET1_BILLION_VND: 'Cân đối kế toán - Tỷ VND',
  BALANCE_SHEET1_MILLION_VND: 'Cân đối kế toán - triệu VND',
  BALANCE_SHEET1_VND: 'Cân đối kế toán - VND',
  LASTEST_OWNERSHIP: 'Cơ cấu sở hữu mới nhất',
  LASTEST_OWNERSHIP2: '(Cập nhật đến ',
  CURRENT_POSITION: 'Chức vụ',
  SHARES: 'Khối lượng',
  SHARES_1: 'Cổ phiếu (nghìn CP)',
  PERCENTAGE: 'Sở hữu',
  LAST_UPDATE: 'Ngày cập nhật',
  REVENUE_FROM_INSURANCE_PREMIUM: 'Doanh thu phí bảo hiểm',
  GROSS_WRITTEN_PREMIUM: 'Thu phí bảo hiểm',
  REINSURANCE_PREMIUM_ASSUMED: 'Thu phí nhận tái bảo hiểm',
  INCREASE1: 'Tăng/giảm dự phòng phí bảo hiểm gốc và nhận tái bảo hiểm',
  DEDUCTIONS: 'Các khoản giảm trừ doanh thu',
  INCREASE_IN_UNEARNED_PREMIUM:
    'Tăng do dự phong phí chưa được hưởng và dự phòng toán học',
  NET_REVENUE_OF_INSURANCE_PREMIUM: 'Doanh thu phí bảo hiểm thuần',
  COMMISSION_INCOME_FROM_OUTWARD:
    'Hoa hồng nhượng tái bảo hiểm và doanh thu khác HĐKD bảo hiểm',
  COMMISSION_ON_REINSURANE_CEDED: 'Thu hoa hồng nhượng tái bảo hiểm',
  OTHER_INCOME: 'Thu nhập khác hoạt động kinh doanh bảo hiểm',
  NET_SALES_FROM_INSURANCE_BUSINESS:
    'Doanh thu thuần từ hoạt động kinh doanh bảo hiểm',
  CLAIM_AND_MATURITY_PAYMENT_EXPENSES:
    'Chi bồi thường bảo hiểm gốc và chi trả đáo hạn',
  CLAIM_EXPENSE_FOR_REINSURANCE_ASSUMED: 'Chi bồi thường nhận tái bảo hiểm',
  CLAIM_RECOVERIES_FROM_OUTWARD_REINSURANCE:
    'Thu bồi thường nhượng tái bảo hiểm',
  CLAIM_EXPENSE_ON_RETAINED_RISKS: 'Bồi thường thuộc trách nhiệm giữ lại',
  INCREASE_MATHEMATIC_RESERVES: 'Tăng/giảm dự phòng toán học',
  INCREASE_CLAIM_RESERVE: 'Tăng/giảm dự phòng bồi thường',
  INCREASE_CLAIM_RESERVE_FOR_DIRECT_INSURANCE_AND_INWARD_INSURANCE:
    'Tăng/giảm dự phòng bồi thường bảo hiểm gốc và nhận tái bảo hiểm',
  INCREASE_IN_CLAIM_RESERVE_FOR_OUTWARD_INSURANCE:
    'Tăng/giảm dự phòng bồi thường nhượng tái bảo hiểm',
  TOTAL_INSURANCE_CLAIM_SETTLEMENT_EXPENSES: 'Tổng chi bồi thường bảo hiểm',
  CLAIM_EXPENSES_USING_CATASTROPHE_RESERVE:
    'Chi bồi thường tư quỹ dao động lớn',
  PROVISION_FOR_CATASTROPHE_RESERVE: 'Trích dự phòng dao động lớn',
  OTHER_INSURANCE_OPRERATING_EXPENSES: 'Chi khác hoạt động kinh doanh bảo hiểm',
  OTHER_UNDERWRITING_EXPENSES: 'Chi khác hoạt động bảo hiểm gốc',
  OTHER_REINSURANCE_ASSUMED_EXPENSES: 'Chi khác nhận tái bảo hiểm khác',
  OTHER_REINSURANCE_CEDED_EXPENSES: 'Chi nhượng tái bảo hiểm',
  OTHER_DIRECT_INSURANCE_OPERATING_EXPENSES:
    'Chi phí trực tiếp kinh doanh hoạt động khác',
  TOTAL_DIRECT_INSURANCE_OPERATING_EXPENSES:
    'Tổng chi trực tiếp hoạt động kinh doanh bảo hiểm',
  GROSS_INSURANCE_OPERATING_PROFIT:
    'Lợi nhuận gộp hoạt động kinh doanh bảo hiểm',
  NET_OPERATING_INCOME_FORM_BANKING_ACTIVITIES:
    'Thu nhập thuần từ hoạt động ngân hàng',
  NET_OPERATING_INCOME_FORM_OTHER_ACTIVITIES:
    'Thu nhập thuần từ các hoạt động khác',
  SELLING_EXPENSE: 'Chi phí bán hàng',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES: 'Chi phí quản lí doanh nghiệp',
  NET_OPERATING_PROFIT_FROM_INSURANCE_OPERATION:
    'Lợi nhuận thuần hoạt động kinh doanh bảo hiểm',
  NET_PROFIT_FORM_BANK_OPERATION: 'Lợi nhuận thuần hoạt động ngân hàng',
  NET_PROFIT_FROM_OTHERS_OPERATION: 'Lợi nhuận thuần từ các hoạt động khác',
  PROFIT_FROM_FINANCIAL_ACTIVITIES: 'Lợi nhuận hoạt động tài chính',
  NET_OPERATING_INCOME_FROM_OTHER_ACTIVITIES: 'Lợi nhuận hoạt động khác',
  PROFIT_BEFORE_TAX: 'Tổng lơi nhuận kế toán trước thuế',
  ENTERPRISE_INCOME_TAX_FOR_THE_YEAR: 'Chi phí thuế thu nhập doanh nghiệp',
  PROFIT_AFTER_TAX: 'Tổng lơi nhuận kế toán sau thuế',
  NET_PROFIT_ATTRIBUTABLE_TO_SHAREHOLDERS_OF_THE_GROUP:
    'Lợi nhuận Cổ đông của Công ty mẹ',
  REINSURANCE_ASSETS: 'Tài sản tái bảo hiểm',
  LOANS_AND_ADVANCES_TO_CUSTOMERS: 'CHO VAY VÀ ỨNG TRƯỚC CHO KHÁCH HÀNG',
  CONSTRUCTION_IN_PROGRESS: 'Chi phí xây dựng cơ bản dở dang',
  SHORT_TERM_DEBTS_AND_BORROWINGS: 'Vay và nợ ngắn hạn',
  ADVANCES_FROM_CUSTOMERS: 'Người mua trả tiền trước',
  UNEARED_COMMISSON_INCOME: 'Doanh thu hoa hồng chưa được hưởng',
  AMOUNT_DUE_TO_CUSTOMERS:
    'Tiền gửi của khách hàng và các tổ chức tín dụng khác',
  INSURANCE_RESERVE: 'Dự phòng nghiệp vu bảo hiểm',
  LONG_TERM_BORROWINGS_AND_LIABILITIES: 'Nợ dài hạn',
  LONG_TERM_ADVANCES_FROM_CUSTOMERS: 'Người mua trả tiền trước dài hạn',
  DEFFERED_REVENUE: 'Doanh thu chưa được thực hiện.',
  EQUITY: 'Vốn đầu tư của chủ sở hữu',
  SHARE_CAPITAL: 'Thặng dư vốn cổ phần',
  ADDITIONAL_PAID_IN_CAPITAL: 'Vốn góp',
  OTHER_RESERVES: 'Vốn khác',
  OTHER_CAPITAL_FUNDS: 'Nguồn kinh phí và quỹ khác',
  LIABILITIES_AND_SHAREHOLDERS_EQUITIES: 'TỔNG CỘNG NGUỒN VỐN',
  INTEREST_AND_SIMILAR_INCOME: 'Thu nhập lãi và các khoản thu nhập tương tự',
  INTEREST_AND_SIMILAR_EXPENSES: 'Chi phí lãi và các chi phí tương tự',
  NET_INTEREST_INCOME: 'Thu nhập lãi thuần',
  NET_FEE_AND_COMMISION_INCOME: 'Lãi thuần từ hoạt động dịch vụ',
  NET_GAIN_FROM_FOREIGN_CURRENCY_AND_GOLD_DEALINGS:
    'Lãi/lỗ thuần từ hoạt động kinh doanh ngoại hối và vàng',
  NET_GAIN_FROM_TRADING_OF_TRADING_SECURITIES:
    'Lãi/lỗ thuần từ mua bán chứng khoán kinh doanh',
  NET_GAIN_FROM_TRADING_OF_INVESTMENT_SECURITIES:
    'Lãi/lỗ thuần từ mua bán chứng khoán đầu tư',
  DIVIDENDS_INCOME: 'Thu nhập từ góp vốn, mua cổ phần',
  TOTAL_OPERAING_INCOME: 'Tổng thu nhập hoạt động',
  OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES:
    'LN thuần từ hoạt động kinh doanh trước CF dự phòng rủi ro tín dụng',
  PROVISION_FOR_CREDIT_LOSSES: 'Chi phí dự phòng rủi ro tín dụng',
  BUSINESS_INCOME_TAX_: 'Chi phí thuế thu nhập doanh nghiệp',
  CASH_AND_PRECIOUS_METALS: 'Tiền mặt, vàng bạc, đá quý',
  BALANCES_WITH_THE_SBV: 'Tiền gửi tại ngân hàng nhà nước Việt Nam',
  PLACEMENTS_WITH_AND_LOANS_TO_OTHER_CREDIT_INSTITUTIONS:
    'Tiền gửi tại các TCTD khác và cho vay các TCTD khác',
  TRADING_SECURITIES_NET: 'Chứng khoán kinh doanh',
  DERIVATIVES_AND_OTHER_FINANCIAL_ASSETS:
    'Các công cụ tài chính phái sinh và các tài sản chính khác',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET: 'Cho vay khách hàng',
  INVESTMENT_SECURITIES: 'Chứng khoán đầu tư',
  INVESTMENT_IN_OTHER_ENTITIES_AND_LONG_TERM_INVESTMENTS:
    'Góp vốn, đầu tư dài hạn',
  OTHER_ASSETS: 'Tài sản khác',
  LIABILITITES_AND_SHAREHOLDERS_EQUITY: 'Nợ phải trả và vốn chủ sở hữu',
  TOTAL_LIABILITIES: 'Tổng nợ phải trả',
  DUE_TO_GOV_AND_BORROWING_FROM_SBV: 'Các khoản nợ chính phủ và NHNN Việt Nam',
  DEPOSITS_AND_BORROWINGS_FROM_OTHER_CREDIT_INSTITUTIONS_:
    'Tiền gửi và vay các tổ chức tín dụng',
  DEPOSITS_FROM_CUSTOMERS: 'Tiền gửi của khách hàng',
  DERIVATIVES_AND_OTHER_FINANCIAL_LIABILITIES:
    'Các công cụ tài chính phái sinh và các khoản nợ tài chính khác',
  FUNDS_RECEVIED_FROM_GOV_INTERNATIONAL_AND_OTHER_INSTITUTIONS:
    'Vốn tài trợ, ủy thác đầu tư của Chính phủ và các tổ chức tín dụng khác',
  CONVERTIBLE_BONDS_CDS_AND_OTHER_VALUABLE_PAPERS_ISSUED:
    'Phát hành giấy tờ có giá',
  OTHER_LIABILITIES: 'Các khoản nợ khác',
  SHAREHOLDERS_EQUITY: 'Vốn chủ sở hữu',
  CAPITAL: 'Vốn của tổ chức tín dụng',
  FUND_FOR_BASIC_CONSTRUCTION: 'Vốn đầu tư XDCB',
  CAPITAL_SURPLUS: 'Thặng dư vốn cổ phần',
  TREASURY_SHARES: 'Cổ phiếu quỹ',
  PREFERRED_SHARES: 'Cổ phiếu ưu đãi',
  RETAINED_EARNINGS: 'Lợi nhuân chưa phân phối',
  REVENUE: 'Doanh thu',
  REVENUE_IN_BROKERAGE_SERVICES: 'Doanh thu hoạt động môi giới chứng khoán',
  REVENUE_IN_SECURITIES_INVESTMENT_AND_OTHER_INVESTMENT_ACTIVITIES:
    'Doanh thu hoạt động đầu tư chứng khoán, góp vốn',
  REVENUE_IN_UNDERWRITING_SERVICES: 'Doanh thu bảo lãnh phát hành chứng khoán',
  REVENUE_IN_ISSUANCE_AGENCY_SERVICES: 'Doanh thu đại lí phát hành chứng khoán',
  REVENUE_IN_ADVISORY_SERVICES: 'Doanh thu hoạt động tư vấn',
  REVENUE_IN_SECURITIES_CUSTODY_SERVICES: 'Doanh thu lưu ký chứng khoán',
  REVENUE_IN_AUCTION_TRUST_SERVICES: 'Doanh thu hoạt động ủy thác đấu giá',
  RENTAL_INCOME_FROM_INVESTMENT_PROPERTY: 'Thu cho thuê sử dụng tài sản',
  OTHERS_REVENUE: 'Doanh thu khác',
  NET_SALES_AND_SERVICES: 'Doanh thu thuần về họat động kinh doanh',
  COST_OF_SALES: 'Chi phí hoạt động kinh doanh',
  GROSS_PROFIT_FROM_OPERATING_ACTIVITIES:
    'Lợi nhuận gộp của hoạt động kinh doanh',
  GAIN_FROM_JOINT_VENTURES: 'Lãi/lỗ từ công ty liên doanh/liên kết',
  NET_PROFIT_FROM_OPERATING_ACTIVITIES:
    'Lợi nhuận thuần từ hoạt động kinh doanh',
  OTHER_PROFIT: 'Lợi nhuận khác',
  SHARES_OF_PROFIT_IN_ASSOCIATE: 'Lãi/lỗ từ công ty liên doanh/liên kết',
  BUSINESS_INCOME_TAX: 'Chi phí thuế thu nhập doanh nghiệp',
  EQUITY_HOLDERS_OF_THE_PARENT: 'Lợi nhuận cổ đông công ty mẹ',
  SHORT_TERM_TRADE_PAYABLES: 'Phải trả nhà cung cấp ngắn hạn',
  OWNERS_EQUITY_: 'VỐN CHỦ SỞ HỮU',
  MARKET_CAP: 'Vốn hóa (Tỷ VND)',
  TOTAL_ASSETS1: 'Tổng tài sản (Tỷ VND)',
  NET_PROFIT_GROWTH: 'Tăng trưởng LN ròng',
  OWNERS_EQUITY1: 'Vốn CSH (Tỷ VND)',
  TOTAL_ASSETS2: 'Tổng tài sản',
  NET_PROFIT: 'Lợi nhuận ròng',
  EPS: 'EPS cơ bản (VND)',
  DIVIDEND: 'Cổ tức (VND)',
  REPORT_DATE1: 'Ngày báo cáo',
  NAME: 'Tên',
  MY_TEMPLATES: 'Báo cáo đã lưu',
  SAVE_TEMPLATE: 'Lưu báo cáo',
  UPDATE_CURRENT_TEMPLATE: 'Cập nhật báo cáo',
  CANCEL: 'Hủy bỏ',
  APPLY: 'Áp dụng',
  DELETE: 'Xóa',
  TEXT_TOOLTIP:
    'Tùy thuộc vào dữ liệu của mỗi công ty, <i>Cơ cấu doanh thu</i> có thể có hoặc không. Hãy chọn <i>So sánh với ngành</i> để thay thế.',
  TOTAL_SHARES: 'Số lượng CP lưu hành',
  PERIOD: 'Kỳ',
  RANGE: 'Khoảng',
  ONE_WEEK: '1 Tuần',
  ONE_MONTH: '1 Tháng',
  THREE_MONTHS: '3 Tháng',
  SIX_MONTHS: '6 Tháng',
  ONE_YEAR: '1 Năm',
  YTD: 'Từ đầu năm',
  ALL: 'Tất cả',
  DELETE_TEMPLATE: 'Xóa báo cáo',
  TEXT_DELETE_TEMPLATE: 'Bạn có chắc chắn muốn xóa biểu mẫu ',
  TEXT_DELETE_TEMPLATE1: ' không ?',
  PREVIEW: 'Xem trước',
  SELECT_TIME_RANGE: 'Chọn khoảng thời gian',
  COMPANY_SNAPSHOT: 'Báo cáo doanh nghiệp',
  SETTING1: 'CÀI ĐẶT',
  PEER_ANALYSIS_SETTING: 'Cài đặt so sánh Doanh nghiệp',
  TEXT_WARNING_ADD_ITEM: 'Bạn chỉ có thể chọn tối đa 3 mã.',
  ALL_EXCHANGE: 'Tất cả sàn',
  MESS_NAME_EXISTED: 'Tên báo cáo đã tồn tại.',
  MESS_NAME_ENTER: 'Nhập tên báo cáo.',
  ADJUSTED_PRICE: '* Giá điều chỉnh',
  SAVE: 'Lưu cài đặt',
  RESET: 'Tạo mới',
  PERIOD_LABEL: 'Theo kỳ: ',
  RANGE_LABEL: 'Theo khoảng thời gian tuỳ chỉnh:',
  STATE_OWNED: 'Sở hữu nhà nước',
  FOREIGN_OWNED: 'Sở hữu ngước ngoài',
  OTHER: 'Khác',
  VND_MILLION: 'Triệu đồng',
  EXPORT: 'Xuất báo cáo',
  NAME_FILE_DOWNLOAD: 'FiinProX_Bao_Cao_Doanh_Nghiep_',
  SAVE_AS: 'Lưu báo cáo mới',
  MAJOR_SHAREHOLDER_OWNERSHIP: 'Nắm giữ của cổ đông lớn',
  DIRECTOR_AND_RELATED_PERSON_OWNERSHIP:
    'Nắm giữ của Ban lãnh đạo và những người liên quan',
  MAJOR_SHAREHOLDER_OWNERSHIP1: 'Cổ đông lớn',
  DIRECTOR_AND_RELATED_PERSON_OWNERSHIP1: 'Ban lãnh đạo',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1: 'Cho vay khách hàng (Tỷ VND)',
  NET_INTEREST_INCOME_1: 'Thu nhập lãi thuần',
  NET_INTEREST_MARGIN: 'NIM (%)',
  NON_INTEREST_INCOME: 'Thu nhập ngoài lãi/ Tổng thu nhập (%)',
  ROA_PRE_PROVISION: 'ROA trước dự phòng',
  LOAN_LOS_REVERVES_LOANS: 'Dự phòng rủi ro tín dụng/ Tổng cho vay (%)',
  LOANS_DEPOSIT_RATIO: 'Cho vay/ Tổng tiền gửi(%)',
  TOTAL_DEBTS: 'Tổng nợ / VCSH (%)',
  CIR: 'CIR (%)',
  NPL: 'NPL (%)',
  NOTE_FINANCIAL_DATA_CHART:
    '*Các dự báo Consensus của FiinGroup được tính toán từ góc nhìn của các nhà môi giới và đội ngũ nghiên cứu viên của FiinGroup',
  VNDBN: 'Tỷ VND',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_2:
    'Cho vay khách hàng/ Tổng tiền gửi khách hàng (%)',
  SAVE_TEMPLATE_SUCCESS: 'Báo cáo của bạn đã được lưu thành công',
  YEAR1: '* Năm: ',
  DEDUCTIONS1: 'Các khoản giảm trừ chi phí',
  MESS_WARNING:
    ' là công ty đại chúng chưa niêm yết. Do đó, dữ liệu giá không có sẵn để so sánh với các công ty niêm yết trong cùng ngành.',
}
