import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import Contribution from './contribution'
import ForeignTrading from './foreignTrading'
import Index from './indexPanel'
import MarketWatch from './marketWatch'
import Prospect from './prospect'

const MAP_KEY = {
  INDEX: 'INDEX',
  PROSPECT: 'PROSPECT',
  MARKET_WATCH: 'MARKET_WATCH',
  FOREIGN_TRADING: 'FOREIGN_TRADING',
  CONTRIBUTION: 'CONTRIBUTION',
}

const MAP_SIZE = {
  [`${MAP_KEY.INDEX}`]: {
    width: `calc(25% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: 250 - 2 * COMPONENT.MARGIN,
    minHeight: 330 - 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_WATCH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.PROSPECT,
        ratioWidth: -1,
        ratioLeft: 0,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.PROSPECT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.PROSPECT}`]: {
    width: `calc(25% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: 250 - 2 * COMPONENT.MARGIN,
    minHeight: 330 - 2 * COMPONENT.MARGIN,
    minTop: 330,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARKET_WATCH}`]: {
    width: `calc(37.5% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(100%)`,
    top: 0,
    left: `calc(25%)`,
    minWidth: 375 - 2 * COMPONENT.MARGIN,
    minHeight: 660 - 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 250,
    verticalResize: [
      {
        key: MAP_KEY.FOREIGN_TRADING,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.CONTRIBUTION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FOREIGN_TRADING}`]: {
    width: `calc(37.5%)`,
    height: `calc(37% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(62.5%)`,
    minWidth: 375 - 2 * COMPONENT.MARGIN,
    minHeight: 244.2 - 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 625,
    horizontalResize: [
      {
        key: MAP_KEY.CONTRIBUTION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CONTRIBUTION}`]: {
    width: `calc(37.5%)`,
    height: `calc(63%)`,
    top: `calc(37%)`,
    left: `calc(62.5%)`,
    minWidth: 375 - 2 * COMPONENT.MARGIN,
    minHeight: 415.8 - 2 * COMPONENT.MARGIN,
    minTop: 244.2,
    minLeft: 625,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <Index
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INDEX}
        sizes={sizes}
        setSizes={setSizes}
      />
      <Prospect
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PROSPECT}
        sizes={sizes}
        setSizes={setSizes}
      />
      <MarketWatch
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_WATCH}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ForeignTrading
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FOREIGN_TRADING}
        sizes={sizes}
        setSizes={setSizes}
      />
      <Contribution
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CONTRIBUTION}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
