import moment from 'moment'
import { SEARCH_ITEM_TYPE } from './constants'

export const convertCompareData = (data) => {
  switch (data.type) {
    case SEARCH_ITEM_TYPE.INDICES:
      return {
        id: data.groupId,
        groupType: SEARCH_ITEM_TYPE.INDICES,
        nameCompare: data.groupName,
        idCompare: SEARCH_ITEM_TYPE.INDICES + data.groupId,
      }
    case SEARCH_ITEM_TYPE.SECTOR:
      return {
        id: data.icbId,
        groupType: SEARCH_ITEM_TYPE.SECTOR,
        nameCompare: convertNameSectorsByLevel(data),
        idCompare: SEARCH_ITEM_TYPE.SECTOR + data.icbId,
      }
    case SEARCH_ITEM_TYPE.TICKER:
      return {
        id: data.organizationId,
        groupType: SEARCH_ITEM_TYPE.TICKER,
        nameCompare: data.ticker,
        idCompare: SEARCH_ITEM_TYPE.TICKER + data.organizationId,
      }
    default:
      return
  }
}

export const getDayPass = (numYear, numMonth) => {
  const toDay = new Date()
  const day = toDay.getDate()
  const year = toDay.getFullYear()
  const month = toDay.getMonth()
  return new Date(year - numYear, month - numMonth, day)
}

export const getColor = (colors, compareParams, exceptColors) => {
  const compareColors = compareParams.map((item) => item.color)
  const filterColors = colors
    .filter((color) => !compareColors.includes(color))
    .filter((color) => !exceptColors.includes(color))
  return filterColors.length > 0 ? filterColors[0] : '#6b6b6b'
}

export const convertNameSectorsByLevel = (sector) => {
  return sector.icbName + ' L' + sector.icbLevel
}

export const findIndexIsSameOrAfter = (array, dateCompare) => {
  return array.findIndex((x) =>
    moment(x.tradingDateId).isSameOrAfter(dateCompare),
  )
}

export const findIndexBefore = (array, dateCompare) => {
  return array.findIndex((x) => moment(dateCompare).isBefore(x.tradingDateId))
}
