export const marginLoans = {
  MARGIN_LOANS: 'Margin Loans',

  MARGIN_AVG_TRADING_VALUE: 'Margin/ Average Trading Value',
  MARGIN_AVG_MATCHED: 'Margin/ Average Matched Value',
  MARGIN_AVG_MATCHED_TIMES: 'Margin/ Average Matched Value (times)',
  TIMES: 'times',
  POINT: 'points',
  RATIO_TIMES: 'Ratio (times)',
  VNINDEX_POINTS: 'VNINDEX (point)',
  NOTE_MARGIN_AVG_TRADING:
    'Note: Average matched value is calculated from matched value on HOSE and HNX for 15 days before and after the end of each quarter.',

  MARGIN_LEVERAGE_RATIO: 'Margin Leverage ratio',
  LEVERAGE_RATIO: 'Leverage ratio',
  LEVERAGE_RATIO_ADJ: 'Leverage ratio (%)',
  ADJUST: 'Adjusted',
  MARGIN: 'Margin',
  MARGIN_BILLION: 'Margin (Billion VND)',
  BILLION: 'Bn.',
  NOTE_MARGIN_LEVERAGE:
    'Note: Leverage ratio (adjusted) is margin loans to total market capitalization adjusted value by free-float on HOSE and HNX',

  MARGIN_GROWTH: `Growth of Margin Loans`,
  MARGIN_LOANS_OWNER_EQUITY: `Margin Loans/Owner's Equity`,
  ADD_TICKER: 'Add Ticker',
  TICKER: 'Ticker',
  NAME: 'Name',
  OTHER: 'Other',
  COMPARE_TO: 'Compared to<br>',
  SUM: 'Sum',
  YEARLY: 'Yearly',
  ALL_EXCHANGE: 'All Exchanges',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  DELETE_ALL_TICKER: 'Delete All Ticker',
  DELETE_MESSAGE: 'Do you want to delete <b><i>All Tickers</i></b>?',
  RESET: 'Reset',

  ALL: 'All Securities',
  BILLION_VND: '(Billion VND)',
  THOUSAND_BILLION_VND: '(Thousand billion VND)',
  MARGIN_LOANS_OF: 'Margin Loans of',
  SECURITIES: 'Securities',
  TOTAL: 'Total',
}
