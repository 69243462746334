import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../bond/corporateBond/bondSecurity/bondSecurity/Filter/store/slice'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FEATURES } from '../../constants/routes'
import { getSearchResultInfo, highlightString } from '../../utils/Search'
import style from './index.module.css'

export const BondOverviewItem = (props) => {
  const { searchStr, data, getBasicInfo } = props
  const navigate = useCustomNavigate()
  const dispatch = useDispatch()

  const [isActive, setIsActive] = useState(false)

  const getTicker = () => {
    return highlightString(
      data.bondTicker,
      data.highlight.bondTicker,
      searchStr,
    )
  }

  const getIsinCode = () => {
    return highlightString(
      data.bondIsinCode,
      data.highlight.bondIsinCode,
      searchStr,
    )
  }

  const getBondName = () => {
    return highlightString(data.bondName, data.highlight.bondTicker, searchStr)
  }

  const onToggleMoreOption = (value) => {
    setIsActive(value)
  }

  const onRedirect = () => {
    const bond = getSearchResultInfo(data, getBasicInfo)
    dispatch(changeBondTicker(bond.bondTicker))
    dispatch(changeBasicInfo(null))
    navigate(FEATURES.bond.components['bond-security'].route, bond)
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div
        className={`d-flex align-center j-b search-content ${
          style['search-content']
        } ${isActive ? style['search-content-active'] : ''}`}
      >
        <div>
          <div className={style['item-title']}>{getTicker()}</div>
          <div className="date">
            {data.exchangeCode && <span>{data.exchangeCode}/ </span>}
            <span>{getBondName()}</span>
            {data.bondIsinCode && <span> (ISIN: {getIsinCode()}) </span>}
          </div>
        </div>
        {/* <SearchItemDropdown
          data={data}
          options={getSearchItemMoreOptions(
            SEARCH_TYPE.BOND,
            data.groupCodes,
            FEATURES,
          )}
          onToggle={onToggleMoreOption}
          getBasicInfo={getBasicInfo}
        /> */}
      </div>
    </a>
  )
}

BondOverviewItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
