import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { dataFormatTypes } from '../constants'
import styles from '../style.module.css'

export const DataFormatComponent = ({
  isParent,
  isEquation,
  isBySecurities,
  value,
  periodValue,
  onChange,
  onChangePeriod,
}) => {
  const handleChange = (e) => {
    if (value !== dataFormatTypes.PREV_PERCENT) return
    let number = e.target.value
    if (typeof number !== 'number' && !number) {
      return onChangePeriod(number)
    }
    if (+number < 0) number = 0
    onChangePeriod(+number)
  }

  const handleIncrease = () => {
    if (value !== dataFormatTypes.PREV_PERCENT) return
    let number = periodValue + 1
    onChangePeriod(number)
  }

  const handleDecrease = () => {
    if (value !== dataFormatTypes.PREV_PERCENT) return
    let number = periodValue - 1
    if (number < 0) number = 0
    onChangePeriod(number)
  }

  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value="financialChart.chartSettings.GROWTH_PERCENT" />
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <ul className="list-check d-flex">
          <li className={styles.settingBlockItemRadio}>
            <label>
              <input
                type="checkbox"
                className="radiobox radiobox2"
                value={dataFormatTypes.VALUE}
                checked={value === dataFormatTypes.VALUE}
                onChange={() => onChange(dataFormatTypes.VALUE)}
              />
              <Translate value="financialChart.chartSettings.GROWTH_NOT_COUNT" />
            </label>
          </li>
          {!isBySecurities && (
            <>
              <li className={styles.settingBlockItemRadio}>
                <label>
                  <input
                    type="checkbox"
                    className="radiobox radiobox2"
                    value={dataFormatTypes.PERCENT}
                    checked={value === dataFormatTypes.PERCENT}
                    onChange={() => onChange(dataFormatTypes.PERCENT)}
                  />
                  <Translate value="financialChart.chartSettings.GROWTH_ORIGINAL_PERIOD" />
                </label>
              </li>
              <li
                className={styles.settingBlockItemRadio}
                title={I18n.t('financialChart.MESS_INFO_QUARTERLY_MONTHLY')}
              >
                <label>
                  <input
                    type="checkbox"
                    className="radiobox radiobox2"
                    value={dataFormatTypes.PREV_PERCENT}
                    checked={value === dataFormatTypes.PREV_PERCENT}
                    onChange={() => onChange(dataFormatTypes.PREV_PERCENT)}
                  />
                  <div
                    className="position-relative mr-8"
                    style={{
                      width: 58,
                    }}
                  >
                    <Input
                      value={periodValue}
                      type="number"
                      className={styles.settingDataFormatTypePrevPeriod}
                      onChange={handleChange}
                      disabled={value !== dataFormatTypes.PREV_PERCENT}
                    />
                    <div
                      className={[
                        'flex-column',
                        styles.settingDataFormatTypePrevPeriodIconContainer,
                      ].join(' ')}
                    >
                      <i
                        className={[
                          'icon-caret-top',
                          styles.settingDataFormatTypePrevPeriodIcon,
                        ].join(' ')}
                        style={{ paddingTop: 1 }}
                        onClick={handleIncrease}
                      />
                      <i
                        className={[
                          'icon-caret-down',
                          styles.settingDataFormatTypePrevPeriodIcon,
                        ].join(' ')}
                        style={{ paddingTop: 2 }}
                        onClick={handleDecrease}
                      />
                    </div>
                  </div>
                  <Translate value="financialChart.chartSettings.GROWTH_PREVIOUS_PERIOD" />
                </label>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  )
}
