import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { BOND_TABS } from '../constants'
import {
  changeFilterPortfolioByIssuer,
  selectFilterPortfolioIssuer,
} from '../store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const { activeTab } = useSelector(selectFilterPortfolioIssuer)
  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeTab = (item) => {
    dispatch(
      changeFilterPortfolioByIssuer({
        key: 'activeTab',
        value: item.value,
      }),
    )
  }

  return (
    <div>
      <DispatchActionTab
        data={BOND_TABS}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        itemStyle={{
          width: '50%',
          fontWeight: 600,
        }}
        containerStyle={{
          width: locale === 'vi' ? 180 : 250,
        }}
      />
    </div>
  )
}

export default Filter
