import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getKeyByValue } from '../../../../utils/Common'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  ALL_METRIC_CODE,
  MAIN_METRIC_CODE,
  PREFIX_METRIC_CODE,
} from '../constants'
import {
  selectDetailMetricsDataById,
  selectDetailMetricsTimes,
  selectLoadingDetailMetrics,
  selectReCalcWiths,
} from '../store/slice'
import { getDetailMetrics } from '../store/thunk'
import { SizeTracker } from './../../../../common/sizeTracker/index'
import style from './index.module.css'

const TableDetailMetrics = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const loading = useSelector(selectLoadingDetailMetrics)
  const detailMetricsTimes = useSelector(selectDetailMetricsTimes)
  const reCalcWidths = useSelector(selectReCalcWiths)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getDetailMetrics({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
            <Span
              style={{
                fontSize: 11,
                textTransform: 'none',
                fontStyle: 'italic',
                opacity: 0.4,
                fontWeight: 'normal',
              }}
            >
              <Translate
                value={
                  'corporate.businessModel.costOfOperation.DETAIL_METRIC_UNIT'
                }
              />
            </Span>
          </div>
          {size.height && (
            <div
              style={{
                height: `calc(100% - ${size.height + 8}px)`,
                marginTop: 8,
              }}
            >
              {loading ? (
                <Loading />
              ) : detailMetricsTimes.length ? (
                <Table
                  ids={ALL_METRIC_CODE}
                  getDataFromRedux={selectDetailMetricsDataById}
                  isLoading={loading}
                  schema={['indicator', ...detailMetricsTimes].map(
                    (time, index) => {
                      const colId = String(time)
                      return {
                        colId,
                        title: index === 0 ? '' : colId,
                        isI18n: false,
                        thStyle: {
                          textAlign: index === 0 ? 'left' : 'right',
                        },
                        tdStyle: {
                          textAlign: index === 0 ? 'left' : 'right',
                        },
                        render: (val, rowId) => {
                          const isMainMetricCode =
                            Object.values(MAIN_METRIC_CODE).includes(rowId)

                          if (index === 0) {
                            const text = isMainMetricCode
                              ? `indicators.${getKeyByValue(
                                  MAIN_METRIC_CODE,
                                  rowId,
                                )}`
                              : rowId.startsWith(PREFIX_METRIC_CODE.GROWTH)
                              ? 'GROWTH_PERCENT'
                              : 'PORTION_PERCENT'

                            return (
                              <div
                                className={
                                  isMainMetricCode
                                    ? style.indicatorLevel1
                                    : style.indicatorLevel2
                                }
                              >
                                <Translate
                                  value={`corporate.businessModel.costOfOperation.${text}`}
                                />
                              </div>
                            )
                          }

                          if (isMainMetricCode) {
                            return (
                              <span className={style.cellValLevel1}>
                                {formatVal(val)}
                              </span>
                            )
                          }

                          return (
                            <span className={style.cellValLevel2}>
                              {valToPercent(val, true)}
                            </span>
                          )
                        },
                      }
                    },
                  )}
                  isLargeHead
                  reCalcWidths={reCalcWidths}
                  stickyFirstColumn
                  hasFooter={false}
                  hasTooltip={false}
                  defaultScrollToRight
                />
              ) : (
                <NoData />
              )}
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

export default TableDetailMetrics
