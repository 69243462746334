import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'

export const getNetValueByInvestorChartOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getNetValueByInvestorChartOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getNetValueByInvestorChartOverview(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getNetValueByInvestorChartTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getNetValueByInvestorChartTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getNetValueByInvestorChartTicker(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
