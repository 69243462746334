import PropTypes from 'prop-types'
import SelectCustom from '../../../common/selectCustom'
import { YEAR_OPTIONS } from '../constants'

const SelectYear = ({ year, changeYear, yearOptions, isFlexEnd }) => {
  return (
    <div className={`d-flex ${isFlexEnd && 'justify-content-end'}`}>
      <div style={{ width: 84 }}>
        <SelectCustom
          isI18n={true}
          selectData={yearOptions}
          value={year}
          handleChange={changeYear}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
    </div>
  )
}

SelectYear.propTypes = {
  year: PropTypes.any,
  changeYear: PropTypes.func,
  yearOptions: PropTypes.array,
  isFlexEnd: PropTypes.bool,
}

SelectYear.defaultProps = {
  changeYear: () => {},
  yearOptions: YEAR_OPTIONS,
  isFlexEnd: true,
}

export default SelectYear
