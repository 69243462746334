// https://www.npmjs.com/package/react-redux-i18n
export const defaultLanguage = 'en'

export const languageObjs = {
  en: 'en',
  vi: 'vi',
}

export const listLanguage = {
  vi: {
    key: 'vi',
    value: 1,
  },
  en: {
    key: 'en',
    value: 2,
  },
}
