import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import Popup from '../../../common/popup'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import {
  selectStore,
  selectTemplate,
  selectTemplateActive,
} from './store/slice'
import { postTemplates, updateTemplate } from './store/thunk'
import style from './style.module.css'

const ButtonSaveTemplate = ({
  stateSetting,
  commentaryOnEarnings,
  dropdownCompanyOverview,
}) => {
  const [isShow, setShowPopup] = useState(false)

  const handleClose = () => setShowPopup(false)

  return (
    <>
      <Button
        style={{
          fontSize: 11,
          paddingLeft: 10,
          paddingRight: 10,
          width: 'auto',
          minWidth: 60,
        }}
        className={`btn btn-blue`}
        onClick={() => setShowPopup(true)}
      >
        <Translate value="corporate.companySnapshot.SAVE" />
      </Button>
      <PopupEvent
        isShow={isShow}
        handleClose={handleClose}
        stateSetting={stateSetting}
        commentaryOnEarnings={commentaryOnEarnings}
        dropdownCompanyOverview={dropdownCompanyOverview}
      />
    </>
  )
}

export default ButtonSaveTemplate

const MAP_STATE_RADIO = {
  SAVE: 1,
  UPDATE: 2,
}

const PopupEvent = ({
  isShow,
  handleClose,
  stateSetting,
  commentaryOnEarnings,
  dropdownCompanyOverview,
}) => {
  const dispatch = useDispatch()

  const data = useSelector(selectStore)
  const templateSelect = useSelector(selectTemplateActive)
  const template = useSelector(selectTemplate)
  const dataTopInfo = useSelector(selectBasicInfo)

  const [nameTemplate, setNameTemplate] = useState('')
  const [error, setError] = useState('')
  const [radioState, setRadioState] = useState(MAP_STATE_RADIO.SAVE)

  const handleInputName = (e) => {
    setNameTemplate(e.target.value)
    if (!e.target.value) {
      setError('corporate.companySnapshot.MESS_NAME_ENTER')
    } else if (
      template.some((item) => item.templateName === e.target.value.trim())
    ) {
      setError('corporate.companySnapshot.MESS_NAME_EXISTED')
    } else setError('')
  }

  const onSubmit = () => {
    const {
      popupTimeRangeStockPerformanceVsIndex,
      popupTimeRangeForeignNetBuyingSelling,
      popupPeerAnalysis,
    } = data
    const templateParameters = {
      setting: stateSetting,
      popupTimeRangeStockPerformanceVsIndex:
        popupTimeRangeStockPerformanceVsIndex,
      popupTimeRangeForeignNetBuyingSelling:
        popupTimeRangeForeignNetBuyingSelling,
      popupPeerAnalysis: popupPeerAnalysis,
      dropdownCompanyOverviewValue: dropdownCompanyOverview,
      yourCommentaryOnEarnings: commentaryOnEarnings,
      organizationIdTemplate: dataTopInfo.organizationId,
    }

    if (templateSelect.templateId) {
      if (radioState === MAP_STATE_RADIO.UPDATE) {
        dispatch(
          updateTemplate({
            templateId: templateSelect.templateId,
            templateName: templateSelect.templateName,
            templateParameters: JSON.stringify(templateParameters),
          }),
        )
      } else {
        dispatch(
          postTemplates({
            templateName: nameTemplate,
            templateParameters: JSON.stringify(templateParameters),
          }),
        )
      }
    } else {
      dispatch(
        postTemplates({
          templateName: nameTemplate,
          templateParameters: JSON.stringify(templateParameters),
        }),
      )
    }
    handleClose()
    setNameTemplate('')
  }

  const onCancel = () => {
    handleClose()
    setNameTemplate('')
  }

  const getDisableSubmit = useCallback(() => {
    if (error) {
      return true
    } else if (templateSelect.templateId) {
      if (radioState === MAP_STATE_RADIO.UPDATE) {
        return false
      } else {
        if (!nameTemplate.length) {
          return true
        }
        return false
      }
    } else {
      if (!nameTemplate.length) {
        return true
      }
      return false
    }
  }, [error, nameTemplate, radioState, templateSelect])

  useEffect(() => {
    if (templateSelect.templateId) {
      setRadioState(MAP_STATE_RADIO.UPDATE)
    } else {
      setRadioState(MAP_STATE_RADIO.SAVE)
    }
  }, [isShow])

  return (
    <>
      {isShow && (
        <Popup>
          <div className="modal modal-small">
            <div className="modal-title">
              <h3>
                <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
              </h3>
              <a onClick={handleClose} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              {templateSelect.templateId ? (
                <ul className={`list-check mt-0`}>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2 ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.UPDATE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.UPDATE)}
                        />
                        <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
                      </label>
                    </div>
                  </li>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2  ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.SAVE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.SAVE)}
                        />
                        <Translate value="corporate.companySnapshot.SAVE_AS" />
                      </label>
                      {radioState === MAP_STATE_RADIO.SAVE && (
                        <>
                          <Input
                            type="text"
                            value={nameTemplate}
                            className={`${style.borderButton} bg-grey-2 h-20 mt-8 w-100 ml-10`}
                            onChange={(e) => handleInputName(e)}
                          />
                          {error && (
                            <div className={style.error}>
                              <Translate value={error} />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              ) : (
                <ul className={`list-check mt-0`}>
                  <li className={style.liOrange}>
                    <div style={{ width: '95%' }}>
                      <label>
                        <input
                          type="radio"
                          className={`radiobox radiobox2  ${style.sizeRadio}`}
                          value={radioState}
                          checked={radioState === MAP_STATE_RADIO.SAVE}
                          onChange={() => setRadioState(MAP_STATE_RADIO.SAVE)}
                        />
                        <Translate value="corporate.companySnapshot.SAVE_TEMPLATE" />
                      </label>

                      <Input
                        type="text"
                        value={nameTemplate}
                        className={`${style.borderButton} bg-grey-2 h-20 mt-8 w-100 ml-10`}
                        onChange={(e) => handleInputName(e)}
                      />
                      {error && (
                        <div className={style.error}>
                          <Translate value={error} />
                        </div>
                      )}
                    </div>
                  </li>
                </ul>
              )}
              <div
                className="group-btn justify-content-end"
                style={{ marginTop: 32 }}
              >
                <Button
                  onClick={onCancel}
                  className={`btn normal h-20 ${style.fontWeightNormal}`}
                  style={{ fontSize: 11, marginRight: 24 }}
                >
                  <Translate value="corporate.companySnapshot.CANCEL" />
                </Button>
                <Button
                  onClick={onSubmit}
                  className={`btn btn-blue h-20 ${style.fontWeightNormal} ${
                    getDisableSubmit() ? style.disabled : ''
                  }`}
                  style={{
                    fontSize: 11,
                    whiteSpace: 'nowrap',
                    padding: '0px 6px',
                  }}
                  disabled={error}
                >
                  <Translate value="corporate.companySnapshot.SAVE" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}
