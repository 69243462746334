import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import DropdownTooltip from '../../../../common/textEllipsis/DropdownTooltip'
import { Z_INDEX } from '../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import HeaderCalendar from '../common/HeaderCalendar'
import style from '../index.module.css'
import { PRICE_DATA_TIME_SELECT } from './constants'

const CalendarFilter = ({
  changeDateFrom,
  changeDateTo,
  filterData,
  isShowTimeFrequency,
  changeTimeFrequency,
}) => {
  const dispatch = useDispatch()
  const currentDate = [new Date()]
  const timeZone = UseTimeZone()
  const currentYear = new Date().getFullYear()

  const containerFromRef = useRef()
  const containerToRef = useRef()

  const [isOpenTooltipFrom, setIsOpenTooltipFrom] = useState(false)
  const [isOpenTooltipTo, setIsOpenTooltipTo] = useState(false)

  const setDateFrom = (value) => {
    handleCloseTooltipFrom()
    dispatch(changeDateFrom(value))
  }

  const setDateTo = (value) => {
    handleCloseTooltipTo()
    dispatch(changeDateTo(value))
  }

  const setTimeFrequency = (value) => {
    dispatch(changeTimeFrequency(value))
  }

  const clearInputDate = () => {
    dispatch(changeDateFrom(''))
    dispatch(changeDateTo(''))
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  const handleOpenTooltipFrom = () => setIsOpenTooltipFrom(true)
  const handleCloseTooltipFrom = () => setIsOpenTooltipFrom(false)

  const handleOpenTooltipTo = () => setIsOpenTooltipTo(true)
  const handleCloseTooltipTo = () => setIsOpenTooltipTo(false)

  return (
    <>
      <div className="form-date-select">
        {isShowTimeFrequency && (
          <>
            <Span style={{ fontSize: 12, whiteSpace: 'nowrap' }}>
              <Translate value="market.coveredWarrant.cwStatistics.VIEW_BY" />
            </Span>
            <SelectCustom
              value={filterData?.timeFrequency || PRICE_DATA_TIME_SELECT.DAILY}
              isI18n={true}
              selectData={Object.keys(PRICE_DATA_TIME_SELECT).map((key) => ({
                name: 'market.coveredWarrant.cwStatistics.' + key,
                value: PRICE_DATA_TIME_SELECT[key],
              }))}
              styleContainer={{ width: 100 }}
              handleChange={setTimeFrequency}
            />
          </>
        )}
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.coveredWarrant.cwStatistics.FROM" />
        </Span>
        <div
          className="date-select"
          onMouseOver={handleOpenTooltipFrom}
          onMouseOut={handleCloseTooltipFrom}
          ref={containerFromRef}
        >
          <CalendarPopper
            date={
              filterData?.dateFrom
                ? getDateWithTimeZone(filterData?.dateFrom)
                : new Date(new Date().getFullYear(), 0, 1)
            }
            minDate={getDateWithTimeZone(filterData?.minDate)}
            maxDate={
              filterData?.maxDate
                ? getDateWithTimeZone(filterData?.maxDate)
                : new Date()
            }
            handleChange={setDateFrom}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendar({
              startYear:
                getDateWithTimeZone(filterData?.minDate).getUTCFullYear() ||
                currentYear - 10,
              endYear:
                getDateWithTimeZone(filterData?.maxDate).getUTCFullYear() ||
                currentYear,
            })}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.coveredWarrant.cwStatistics.TO" />
        </Span>
        <div
          className="date-select"
          onMouseOver={handleOpenTooltipTo}
          onMouseOut={handleCloseTooltipTo}
          ref={containerToRef}
        >
          <CalendarPopper
            date={
              filterData?.dateTo
                ? getDateWithTimeZone(filterData?.dateTo)
                : filterData?.maxDate
                ? getDateWithTimeZone(filterData?.maxDate)
                : new Date()
            }
            minDate={getDateWithTimeZone(filterData?.minDate)}
            maxDate={
              filterData?.maxDate
                ? getDateWithTimeZone(filterData?.maxDate)
                : new Date()
            }
            handleChange={setDateTo}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendar({
              startYear:
                getDateWithTimeZone(filterData?.minDate).getUTCFullYear() ||
                currentYear - 10,
              endYear:
                getDateWithTimeZone(filterData?.maxDate).getUTCFullYear() ||
                currentYear,
            })}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <button
          className={style.buttonClear}
          type="button"
          onClick={() => clearInputDate()}
        >
          <Span style={{ fontSize: 10 }}>
            <Translate value="market.coveredWarrant.cwStatistics.CLEAR" />
          </Span>
        </button>
      </div>
      {isOpenTooltipFrom && (
        <DropdownTooltip
          containerRef={containerFromRef}
          isOpenTooltip={isOpenTooltipFrom}
          zIndexTooltip={Z_INDEX.MODAL + 1}
          isI18n={true}
          text="market.coveredWarrant.cwStatistics.TRADING_DATE"
        />
      )}
      {isOpenTooltipTo && (
        <DropdownTooltip
          containerRef={containerToRef}
          isOpenTooltip={isOpenTooltipTo}
          zIndexTooltip={Z_INDEX.MODAL + 1}
          isI18n={true}
          text="market.coveredWarrant.cwStatistics.TRADING_DATE"
        />
      )}
    </>
  )
}

CalendarFilter.propTypes = {}

export default CalendarFilter
