import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_ORGANIZATION } from '../../../../../configs/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { getLatestPrice, getSearchCompanyData } from './thunk'

export const defaultState = {
  loadingSearch: false,
  basicInfo: {
    en: {
      organizationId: DEFAULT_ORGANIZATION.organizationId,
      groupId: DEFAULT_ORGANIZATION.groupId,
      ticker: DEFAULT_ORGANIZATION.ticker,
      companyName: DEFAULT_ORGANIZATION.companyName_EN,
      exchange: DEFAULT_ORGANIZATION.exchange,
      indexGroupId: DEFAULT_ORGANIZATION.indexGroupId,
      icbId: DEFAULT_ORGANIZATION.icbId,
    },
    vi: {
      organizationId: DEFAULT_ORGANIZATION.organizationId,
      groupId: DEFAULT_ORGANIZATION.groupId,
      ticker: DEFAULT_ORGANIZATION.ticker,
      companyName: DEFAULT_ORGANIZATION.companyName,
      exchange: DEFAULT_ORGANIZATION.exchange,
      indexGroupId: DEFAULT_ORGANIZATION.indexGroupId,
      icbId: DEFAULT_ORGANIZATION.icbId,
    },
  },

  listCompany: [],
  priceData: {},
}

const initialState = {
  loadingSearch: false,
  basicInfo: {
    organizationId: '',
    groupId: '',
    ticker: '',
    companyName: '',
    exchange: '',
    indexGroupId: '',
    icbId: '',
  },
  listCompany: [],
  priceData: {},
  isLoading: true,
}

const slice = createSlice({
  name: 'common/stockTopInfo',
  initialState,
  reducers: {
    resetDefault(state, action) {
      Object.keys(defaultState).forEach((key) => {
        if (key === 'basicInfo') {
          state.basicInfo = defaultState.basicInfo[action.payload]
        } else if (key !== 'priceData') {
          state[key] = defaultState[key]
        }
      })
    },
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeBasicInfo(state, action) {
      state.basicInfo = action.payload
    },
    changeLoadingSearch: (state, action) => {
      state.loadingSearch = action.payload
    },
    subscribeStock: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
    subscribeBidAsk: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchCompanyData.pending, (state) => {
      state.loadingSearch = true
    })
    builder.addCase(getSearchCompanyData.fulfilled, (state, action) => {
      state.loadingSearch = false
      state.listCompany = action.payload || []
    })
    builder.addCase(getSearchCompanyData.rejected, (state) => {
      state.loadingSearch = false
    })

    builder.addCase(getLatestPrice.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      state.isLoading = false
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      } else {
        state.priceData = defaultState.priceData
      }
    })
    builder.addCase(getLatestPrice.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectBasicInfo = (state) => state[slice.name].basicInfo
export const selectLoadingSearch = (state) => state[slice.name].loadingSearch
export const selectListCompany = (state) => state[slice.name].listCompany
export const selectPriceData = (state) => state[slice.name].priceData
export const selectPriceDataLoading = (state) => state[slice.name].isLoading

export const {
  subscribeStock,
  subscribeBidAsk,
  resetDefault,
  changeBasicInfo,
  changeLoadingSearch,
  resetStore,
} = slice.actions

register(slice.name, slice.reducer)
