import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../../common/hooks/useTimeZone.js'
import { Span } from '../../../../../common/html/Span.js'
import { ALL_MATCH_DEAL_RADIO, TOTAL_VALUE_TYPE } from '../constants.js'
import { changeFilter, keys, selectFilterTopAsk } from '../store/slice.js'
import {
  getTopInterestRateAsk,
  getTopInterestRateAskByReload,
} from '../store/thunk.js'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()

  const timer = useRef()

  const [reload, setReload] = useState(false)

  const timeZone = UseTimeZone()

  const { TotalValueType } = useSelector(selectFilterTopAsk)

  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeParams = (item) => {
    dispatch(
      changeFilter({
        label: keys.TOP_ASK_INTEREST,
        key: 'TotalValueType',
        value: item,
      }),
    )
  }

  const handleSearch = () => {
    const payload = {
      IsBuy: false,
      TradingType:
        TotalValueType === TOTAL_VALUE_TYPE.TotalMatchValue
          ? 'Match'
          : TotalValueType === TOTAL_VALUE_TYPE.TotalDealValue
          ? 'Deal'
          : '',
    }
    dispatch(getTopInterestRateAskByReload(payload))
  }

  useEffect(() => {
    const payload = {
      IsBuy: false,
      TradingType:
        TotalValueType === TOTAL_VALUE_TYPE.TotalMatchValue
          ? 'Match'
          : TotalValueType === TOTAL_VALUE_TYPE.TotalDealValue
          ? 'Deal'
          : '',
    }
    dispatch(getTopInterestRateAsk(payload))
  }, [locale, TotalValueType])

  useEffect(() => {
    handleSearch()
  }, [locale, TotalValueType])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: 9,
      minute: 0,
      second: 0,
      millisecond: 0,
    })

    const endTime = moment().utcOffset(timeZone)

    endTime.set({
      hour: 15,
      minute: 15,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) - 30000 < 0) {
      if (endTime - moment().utcOffset(timeZone) - 30000) {
        setReload(true)
        return
      }

      setReload(false)

      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        setReload(true)
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: 15,
      minute: 15,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) - 30000 < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        setReload(false)
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    if (reload) {
      timer.current = setInterval(handleSearch, 30000)
    }

    return () => {
      clearInterval(timer.current)
    }
  }, [locale, TotalValueType, reload])

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
    }
  }, [])
  return (
    <>
      <div className="d-flex justify-content-space-between ali-center mb-8">
        <div
          className="d-flex ali-center justify-content-space-between"
          style={{ gap: 8 }}
        >
          {ALL_MATCH_DEAL_RADIO.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center cursor-pointer ${
                  TotalValueType === value && style.radioActive
                }`}
                onClick={() => handleChangeParams(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={TotalValueType === value}
                  readOnly={true}
                />
                <Span
                  style={{
                    fontSize: 12,
                    fontWeight: 340,
                  }}
                >
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Filter
