import { useDispatch, useSelector } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from './index.module.css'
import {
  getCheckedById,
  getDataById,
  getIdWithAllChild,
  handleCollapse,
  handleTickById,
} from './store/slice'

const CorporateItem = ({ id }) => {
  const dispatch = useDispatch()

  const { isOpen, hasCollapse, listDisable, level, title } = useSelector(
    getDataById(id),
  )
  const { listChild, listParent } = useSelector(getIdWithAllChild(id))
  const isChecked = useSelector(getCheckedById(id))

  const handleCheckbox = () => {
    dispatch(
      handleTickById({ listChild, id, isChecked, listParent, hasCollapse }),
    )
  }

  const onCollapse = () => {
    dispatch(handleCollapse({ listChild, id }))
  }

  if (listDisable.length > 0) {
    return <></>
  }

  return (
    <div className={style.corporateItem}>
      <div
        className={`${style.corporateTitle} ${level === 2 && style.pl10} ${
          level === 3 && style.pl56
        }`}
        onClick={hasCollapse ? onCollapse : () => {}}
      >
        {hasCollapse && (
          <>
            {isOpen ? (
              <i className={`icon-caret-down ${style.iconCaret}`} />
            ) : (
              <i className={`icon-caret-right ${style.iconCaret}`} />
            )}
          </>
        )}
        <TextEllipsis isI18n={false} text={title} />
      </div>
      <div className={style.checkboxTicker}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckbox()}
          className={`checkbox`}
          style={{ cursor: 'pointer', minWidth: 12 }}
        />
      </div>
    </div>
  )
}

export default CorporateItem
