import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Filter from '../../../common/dataExplorer/filter'
import {
  addIndexSector,
  addIndicator,
  keys,
  selectLoading,
  selectTemplates,
} from '../../../common/dataExplorer/indexSector/store/slice'
import {
  deleteTemplateThunk,
  getTemplatesThunk,
  getTemplateThunk,
  renameTemplateThunk,
} from '../../../common/dataExplorer/indexSector/store/thunk'
import useGetIndicator from '../../../common/dataExplorer/indexSector/useGetIndicator'

const IndicesSectorFilter = () => {
  const dispatch = useDispatch()

  const { indicatorsTree, loading } = useGetIndicator(false)

  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const templates = useSelector(selectTemplates)

  useEffect(() => {
    dispatch(getTemplatesThunk())
  }, [])

  const handleAddIndexSector = (indexSector) => {
    dispatch(addIndexSector(indexSector))
  }

  const handleAddIndicator = (indicator) => {
    if (!Object.keys(indicator.children).length) {
      dispatch(addIndicator(indicator))
    }
  }

  const renameTemplate = ({ newName, item }) => {
    dispatch(
      renameTemplateThunk({
        templateId: item.templateId,
        oldTemplateName: item.templateName,
        newTemplateName: newName,
      }),
    )
  }

  const deleteTemplate = (item) => {
    dispatch(deleteTemplateThunk({ templateId: item.templateId }))
  }

  const getDetailTemplate = (item) => {
    dispatch(getTemplateThunk({ TemplateId: item.templateId }))
  }

  return (
    <>
      <Filter
        loading={loadingTable}
        handleAddTicker={handleAddIndexSector}
        handleAddIndicator={handleAddIndicator}
        indicatorTree={indicatorsTree}
        loadingIndicator={loading}
        templates={templates}
        handleRenameTemplate={renameTemplate}
        handleDeleteTemplate={deleteTemplate}
        handleGetTemplate={getDetailTemplate}
        isIndicesSectors={true}
        isFilterIcbLevel5={true}
      />
    </>
  )
}

export default IndicesSectorFilter
