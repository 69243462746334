import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import RegisterCancelTokenAxios from '../../../../core/proxies/RegisterCancelTokenAxios'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { SizeTracker } from '../../../common/sizeTracker'
import SectorTopInfo from '../../../common/topInfo/sectorTopInfo'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import Content from './Content'
import {
  INDICATOR_TYPE,
  PERIOD_TYPE,
  PLOT_TYPE,
  TIME_SERIES_TYPE,
} from './constant'
import {
  LOADING_TYPE,
  changeAllowCallData,
  resetStore,
  selectAllowCallData,
  selectFilteredOrganizationIds,
  selectIndicatorInfoById,
  selectLoadShareTemplate,
  selectLoading,
  selectPlotType,
  selectPointInTime,
  selectTimeSeries,
  selectTimeSeriesType,
  selectXIndicator,
  selectYIndicator,
} from './store/slice'
import { getGeneralData, getPointInTime, getTimeSeries } from './store/thunk'

export const Segmentation = ({ noHeaderSearch }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <>
      <ScrollGroupComponent>
        <SizeTracker>
          {(size) => (
            <>
              {!noHeaderSearch && (
                <SectorTopInfo
                  title="constants.route.sector.sectorConstituents.segmentation.LABEL"
                  keepSearchParams={['shareId']}
                />
              )}
              {(size.height || size.height === 0) && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <Content />
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </ScrollGroupComponent>
      <ListenCallData />
      <ListenRemoveShareId />
    </>
  )
}

const ListenCallData = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const xIndicator = useSelector(selectXIndicator)
  const yIndicator = useSelector(selectYIndicator)
  const plotType = useSelector(selectPlotType)
  const pointInTime = useSelector(selectPointInTime)
  const timeSeries = useSelector(selectTimeSeries)
  const timeSeriesType = useSelector(selectTimeSeriesType)
  const allowCallData = useSelector(selectAllowCallData)
  const loadingIndicators = useSelector(selectLoading(LOADING_TYPE.INDICATORS))

  const xPeriodType = useSelector(
    selectIndicatorInfoById(xIndicator, 'periodType'),
  )
  const yPeriodType = useSelector(
    selectIndicatorInfoById(yIndicator, 'periodType'),
  )

  const cancelGetPointInTime = () => {
    RegisterCancelTokenAxios.delete(
      ServiceProxyConfig.Sector.SectorConstituents.Segmentation.ServiceUrl +
        'PointInTime',
    )
  }

  const cancelGetTimeSeries = () => {
    RegisterCancelTokenAxios.delete(
      ServiceProxyConfig.Sector.SectorConstituents.Segmentation.ServiceUrl +
        'TimeSeries',
    )
  }

  const getPointInTimeData = () => {
    const {
      [INDICATOR_TYPE.X]: {
        [PERIOD_TYPE.DAILY]: xTradingDate,
        [PERIOD_TYPE.QUARTERLY]: xQuarter,
        [PERIOD_TYPE.YEARLY]: xYear,
      },
      [INDICATOR_TYPE.Y]: {
        [PERIOD_TYPE.DAILY]: yTradingDate,
        [PERIOD_TYPE.QUARTERLY]: yQuarter,
        [PERIOD_TYPE.YEARLY]: yYear,
      },
    } = pointInTime

    const params = {
      organizationIds: filteredOrgIds,
      xIndicatorId: xIndicator,
      yIndicatorId: yIndicator,
    }

    if (xPeriodType === PERIOD_TYPE.DAILY) {
      if (!xTradingDate) return
      params.xTradingDate = getISOStartOrEndOfDay(xTradingDate, timeZone, true)
    }
    if (xPeriodType === PERIOD_TYPE.QUARTERLY) {
      if (!xQuarter?.quarter && !xQuarter?.year) return
      params.xQuarter = xQuarter.quarter
      params.xYear = xQuarter.year
    }
    if (xPeriodType === PERIOD_TYPE.YEARLY) {
      if (!xYear) return
      params.xYear = xYear
    }

    if (yPeriodType === PERIOD_TYPE.DAILY) {
      if (!yTradingDate) return
      params.yTradingDate = getISOStartOrEndOfDay(yTradingDate, timeZone, true)
    }
    if (yPeriodType === PERIOD_TYPE.QUARTERLY) {
      if (!yQuarter?.quarter && !yQuarter?.year) return
      params.yQuarter = yQuarter.quarter
      params.yYear = yQuarter.year
    }
    if (yPeriodType === PERIOD_TYPE.YEARLY) {
      if (!yYear) return
      params.yYear = yYear
    }

    dispatch(getPointInTime(params))
  }

  const getTimeSeriesData = () => {
    const params = {
      organizationIds: filteredOrgIds,
      xIndicatorId: xIndicator,
      yIndicatorId: yIndicator,
    }

    const { startDate, endDate, year } = timeSeries

    if (timeSeriesType === TIME_SERIES_TYPE.RANGE) {
      if (!startDate || !endDate) return
      params.startDate = getISOStartOrEndOfDay(startDate, timeZone, true)
      params.endDate = getISOStartOrEndOfDay(endDate, timeZone, false)
    }
    if (timeSeriesType === TIME_SERIES_TYPE.YEAR) {
      if (!year) return
      params.year = year
    }

    dispatch(getGeneralData({ organizationIds: filteredOrgIds }))
    dispatch(getTimeSeries(params))
  }

  const handleGetData = () => {
    if (!allowCallData) {
      dispatch(changeAllowCallData(true))
      return
    }

    if (loadingIndicators) return
    if (!(filteredOrgIds.length && xIndicator && yIndicator)) return

    if (plotType === PLOT_TYPE.POINT_IN_TIME) {
      cancelGetTimeSeries()
      getPointInTimeData()
    } else {
      cancelGetPointInTime()
      getTimeSeriesData()
    }
  }

  const timeSeriesData =
    timeSeriesType === TIME_SERIES_TYPE.RANGE
      ? `${timeSeries.startDate}-${timeSeries.endDate}`
      : timeSeries.year

  useEffect(() => {
    handleGetData()
  }, [
    JSON.stringify([...filteredOrgIds].sort()),
    xIndicator,
    xPeriodType,
    yIndicator,
    yPeriodType,
    plotType,
    timeSeriesType,
    pointInTime,
    timeSeriesData,
  ])

  return <></>
}

const ListenRemoveShareId = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const loadShareTemplate = useSelector(selectLoadShareTemplate)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const xIndicator = useSelector(selectXIndicator)
  const yIndicator = useSelector(selectYIndicator)
  const plotType = useSelector(selectPlotType)
  const pointInTime = useSelector(selectPointInTime)
  const timeSeries = useSelector(selectTimeSeries)
  const timeSeriesType = useSelector(selectTimeSeriesType)

  const [loaded, setLoaded] = useState(loadShareTemplate)

  const handleRemove = () => {
    if (!loaded || !searchParams.has('shareId')) return

    searchParams.delete('shareId')
    navigate(
      {
        search: searchParams.toString() ? '?' + searchParams.toString() : '',
      },
      {
        replace: false,
      },
    )
  }

  useEffect(() => {
    setLoaded(loadShareTemplate)
  }, [loadShareTemplate])

  useEffect(() => {
    handleRemove()
  }, [
    JSON.stringify([...filteredOrgIds].sort()),
    xIndicator,
    yIndicator,
    plotType,
    timeSeriesType,
    pointInTime,
    timeSeries,
  ])

  return <></>
}
