import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../../configs/Layout'
import { withWrapper } from '../../../../../common/withWrapper'
import { PanelAfsDecomposition } from './afsDecomposition/PanelAfsDecomposition'
import { PanelDataTable } from './dataTable/PanelDataTable'
import { PanelFvtplDecomposition } from './fvtplDecomposition/PanelFvtplDecomposition'
import { PanelFvtplSegmental } from './fvtplSegmental/PanelFvtplSegmental'
import { PanelMarginLending } from './marginLending/PanelMarginLending'

const MAP_KEY = {
  DATA_TABLE: 'DATA_TABLE',
  FVTPL_DECOMPOSITION: 'FVTPL_DECOMPOSITION',
  MARGIN_LENDING: 'MARGIN_LENDING',
  FVTPL_SEGMENTAL: 'FVTPL_SEGMENTAL',
  AFS_DECOMPOSITION: 'AFS_DECOMPOSITION',
}

const MAP_SIZE = {
  [`${MAP_KEY.DATA_TABLE}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 6) / 5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.FVTPL_DECOMPOSITION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.FVTPL_DECOMPOSITION,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.MARGIN_LENDING,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.FVTPL_SEGMENTAL,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.AFS_DECOMPOSITION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.FVTPL_DECOMPOSITION}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(40% + ${COMPONENT.MARGIN}px)`,
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 9) / 5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 6) / 5 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARGIN_LENDING}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 6) / 5 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.FVTPL_SEGMENTAL,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FVTPL_SEGMENTAL}`]: {
    width: `calc(30% - ${COMPONENT.MARGIN * 2}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(40% + ${COMPONENT.MARGIN}px)`,
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 9) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 6) / 10 + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.AFS_DECOMPOSITION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.AFS_DECOMPOSITION}`]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 9) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 3 * 0.7 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const AssetStructureContent = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelDataTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelFvtplDecomposition
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelMarginLending
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelFvtplSegmental
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelAfsDecomposition
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

AssetStructureContent.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  dataType: PropTypes.string,
}

export default withWrapper(MAP_SIZE, panelRefs)(AssetStructureContent)
