import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DirectorDealService from '../../../../../../core/services/market/marketInDepth/DirectorDealService'
import { handleExport } from '../../../../../utils/Export'

export const getDirectorDeals = createAsyncThunk(
  'market/marketInDepth/deal/GET_DIRECTOR_DEALS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DirectorDealService.getDirectorDeals(params)
      if (response.data.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getActionTypes = createAsyncThunk(
  'market/marketInDepth/deal/GET_ACTION_TYPES',
  async (params, { rejectWithValue }) => {
    const response = await DirectorDealService.getActionTypes(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getActionStatus = createAsyncThunk(
  'market/marketInDepth/deal/GET_ACTION_STATUS',
  async (params, { rejectWithValue }) => {
    const response = await DirectorDealService.getActionStatus(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadInfoDirectorDeals = createAsyncThunk(
  'market/marketInDepth/deal/DOWNLOAD_INFO_DIRECTOR_DEALS',
  async (data) => {
    const response = await DirectorDealService.downloadInfoDirectorDeals(data)
    handleExport(response.data, response.filename)
  },
)
