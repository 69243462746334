import { EMPTY_VALUE } from '../../../../../constants/Common'
import {
  getThreeColorByValue,
  getTwoColorByValue,
} from '../../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
} from '../../../../../utils/Value'
import { LinkCWDetail } from '../../common/LinkCWDetail'
import { PRICE_DATA_TIME_SELECT } from '../../common/constants'
import {
  GROUP_COLUMNS_DETAIL,
  GROUP_COLUMNS_OVERVIEW,
  TABLE_TYPE,
} from './constants'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
      result.schema = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        let title = item.title
        let dateFormat = FORMAT.DATE

        if (item.key === 'tradingDateId') {
          switch (filterData.timeFrequency) {
            case PRICE_DATA_TIME_SELECT.WEEKLY:
              title = 'market.coveredWarrant.cwStatistics.WEEK'
              dateFormat = FORMAT.DATE
              break
            case PRICE_DATA_TIME_SELECT.MONTHLY:
              title = 'market.coveredWarrant.cwStatistics.MONTH'
              dateFormat = FORMAT.MONTH_YEAR_NUMBER
              break
            case PRICE_DATA_TIME_SELECT.QUARTERLY:
              title = 'market.coveredWarrant.cwStatistics.QUARTER'
              dateFormat = FORMAT.QUARTER_YEAR
              break
            case PRICE_DATA_TIME_SELECT.YEARLY:
              title = 'market.coveredWarrant.cwStatistics.YEAR'
              dateFormat = FORMAT.YEAR
              break
            default:
              break
          }
        }

        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => {
              const date = formatDateTime(val, dateFormat, locale, timeZone)
              return (
                <div style={{ width: 'max-content' }}>
                  {date === EMPTY_VALUE ? val : date}
                </div>
              )
            },
          }
        }
        if (item.key === 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key === 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        return result
      })
      break
    case TABLE_TYPE.DETAIL:
      let schemaDetail = GROUP_COLUMNS_DETAIL.map((item, index) => {
        let title = item.title
        let dateFormat = FORMAT.DATE

        if (item.key === 'tradingDateId') {
          switch (filterData.timeFrequency) {
            case PRICE_DATA_TIME_SELECT.WEEKLY:
              title = 'market.coveredWarrant.cwStatistics.WEEK'
              dateFormat = FORMAT.DATE
              break
            case PRICE_DATA_TIME_SELECT.MONTHLY:
              title = 'market.coveredWarrant.cwStatistics.MONTH'
              dateFormat = FORMAT.MONTH_YEAR_NUMBER
              break
            case PRICE_DATA_TIME_SELECT.QUARTERLY:
              title = 'market.coveredWarrant.cwStatistics.QUARTER'
              dateFormat = FORMAT.QUARTER_YEAR
              break
            case PRICE_DATA_TIME_SELECT.YEARLY:
              title = 'market.coveredWarrant.cwStatistics.YEAR'
              dateFormat = FORMAT.YEAR
              break
            default:
              break
          }
        }

        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => {
              const date = formatDateTime(val, dateFormat, locale, timeZone)
              return (
                <div style={{ width: 'max-content' }}>
                  {date === EMPTY_VALUE ? val : date}
                </div>
              )
            },
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val, rowId) => {
              const organizationId = dataTable[rowId]?.coveredWarrantId || 0
              return <LinkCWDetail organizationId={organizationId} val={val} />
            },
          }
        }
        if (item.key === 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key === 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        return result
      })
      result.schema = schemaDetail
      break
    case TABLE_TYPE.TICKER:
      let schemaTicker = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        let title = item.title
        let dateFormat = FORMAT.DATE

        if (item.key === 'tradingDateId') {
          switch (filterData.timeFrequency) {
            case PRICE_DATA_TIME_SELECT.WEEKLY:
              title = 'market.coveredWarrant.cwStatistics.WEEK'
              dateFormat = FORMAT.DATE
              break
            case PRICE_DATA_TIME_SELECT.MONTHLY:
              title = 'market.coveredWarrant.cwStatistics.MONTH'
              dateFormat = FORMAT.MONTH_YEAR_NUMBER
              break
            case PRICE_DATA_TIME_SELECT.QUARTERLY:
              title = 'market.coveredWarrant.cwStatistics.QUARTER'
              dateFormat = FORMAT.QUARTER_YEAR
              break
            case PRICE_DATA_TIME_SELECT.YEARLY:
              title = 'market.coveredWarrant.cwStatistics.YEAR'
              dateFormat = FORMAT.YEAR
              break
            default:
              break
          }
        }

        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => {
              const date = formatDateTime(val, dateFormat, locale, timeZone)
              return (
                <div style={{ width: 'max-content' }}>
                  {date === EMPTY_VALUE ? val : date}
                </div>
              )
            },
          }
        }
        if (item.key === 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key === 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Value') && item.key !== 'netTotalValue') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivMillion(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Volume') && item.key !== 'netTotalVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        return result
      })
      result.schema = schemaTicker
      break
    default:
  }

  return result
}
