import { languageObjs } from '../../../../configs/Language'
import { FORMAT, getCurrentDateTime } from '../../../utils/Datetime'
import { getLanguage } from '../../../utils/Language'
import {
  colorArray,
  enMonthsTimeFrequency,
  TIME_FREQUENCY,
  viMonthsTimeFrequency,
} from './constants'

export const formatChartData = (
  data,
  dataKey,
  dataName,
  top = 5,
  labelChart,
) => {
  const sortedData = [...data]
  const topData = sortedData.slice(0, top)
  const result = topData.map((item, index) => ({
    text: item[dataName],
    value: item[dataKey],
    color: colorArray[index],
    locationId: item?.locationId,
  }))

  if (topData.length === data.length) return result

  const otherRatio =
    1 - topData.reduce((total, item) => total + item[dataKey], 0)

  return [
    ...result,
    {
      text: labelChart || '',
      value: otherRatio,
      color: '#ecffdf',
      isI18n: true,
      locationId: 'other',
    },
  ]
}

export const titleData = (
  month,
  year,
  timeFrequency = TIME_FREQUENCY.YEARLY,
) => {
  const locale = getLanguage()
  if (timeFrequency === TIME_FREQUENCY.YEARLY) {
    if (month < 12) {
      if (locale === languageObjs.vi) {
        return viMonthsTimeFrequency[month - 1] + '-' + year
      } else {
        return enMonthsTimeFrequency[month - 1] + '-' + year
      }
    } else {
      return year.toString()
    }
  } else {
    if (locale === languageObjs.vi) {
      return viMonthsTimeFrequency[month - 1] + '-' + year
    } else {
      return enMonthsTimeFrequency[month - 1] + '-' + year
    }
  }
}

export const getMinData = (data) => {
  if (data.length) {
    const yearMinInData = data.reduce((prev, cur) =>
      cur?.year < prev?.year ? cur : prev,
    )
    const listDataYearMin = data.filter(
      (item) => item.year === yearMinInData.year,
    )
    const monthMinInData = listDataYearMin.reduce((prev, cur) =>
      cur?.month < prev?.month ? cur : prev,
    )
    return {
      minYear: yearMinInData.year,
      minMonth: monthMinInData.month,
    }
  }
  return {
    minYear: undefined,
    minMonth: undefined,
  }
}

export const convertTextUnicode = (string) => {
  return string
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/đ/g, 'd')
    .replace(/Đ/g, 'D')
}

export const getLatestTime = (count) => {
  let year = +getCurrentDateTime(FORMAT.YEAR)
  let month = +getCurrentDateTime(FORMAT.MONTH_NUMBER)
  return Array.from(Array(count)).map((_, index) => {
    const result = { year, month }
    month--
    if (!month) {
      year--
      month = 12
    }
    return result
  })
}

export const getKeyScale = (
  displayData,
  barKey1,
  barKey2,
  barKey3,
  barKey4,
  lineKey,
) => {
  if (displayData.some((key) => key === barKey4)) {
    return [barKey1, barKey2, barKey3]
  } else {
    return displayData.filter((key) => key !== lineKey && key !== barKey4)
  }
}
