import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useComGroup from '../../../common/hooks/useComGroup'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { COM_GROUP } from '../../../constants/Group'
import { keyBy } from '../../../utils/Common'
import {
  DROPDOWN_COMPANY_OVERVIEW,
  TYPE_TIME_RANGE,
  indicatorBalanceSheetBank,
  indicatorBalanceSheetCorp,
  indicatorBalanceSheetIns,
  indicatorBalanceSheetIns1,
  indicatorBalanceSheetSec,
  indicatorFinancialDataBank,
  indicatorFinancialDataCorp,
  indicatorFinancialDataSec,
  indicatorFinancialRatiosBank,
  indicatorFinancialRatiosCorp,
  indicatorIncomeStatementBank,
  indicatorIncomeStatementCorp,
  indicatorIncomeStatementIns,
  indicatorIncomeStatementIns1,
  indicatorIncomeStatementSec,
  indicatorKeyFinancialBank,
  indicatorKeyFinancialCorp,
  indicatorPricePerFormance,
} from './constants'
import {
  changeBalanceSheetIds,
  changeIncomeStatementIds,
  changeIndicatorFinancialDataCorp,
  changeIndicatorFinancialRatiosCorp,
  changeIndicatorKeyFinancial,
  changeLoadingPDF,
  changeShowPreview,
  changeValueReloadData,
  selectDropdownCompanyOverviewValue,
  selectPopupFinancialStatement,
  selectPopupPeerAnalysis,
  selectReportDate,
  selectSetting,
  selectTimeRangeForeignNetBuyingSelling,
  selectTimeRangeStockPerformanceVsIndex,
  setBalanceSheetById,
  setFinancialDataById,
  setFinancialRatiosById,
  setInComeStatementById,
  setKeyFinancialById,
  setPricePerformanceById,
} from './store/slice'
import {
  getBalanceStatement,
  getCompareWithSector,
  getFinancialData,
  getFinancialRatio,
  getForeignNetTrading,
  getIncomeStatement,
  getKeyFinancialData,
  getMajorShareHolder,
  getOverview,
  getOwnershipAnalysis,
  getOwnershipAnalysisChart,
  getPriceData,
  getPricePerformance,
  getPricePerformanceChart,
  getProfile,
  getRevenueBreakdown,
  getStockData,
  getStockData1Yrs,
  getStockPerformanceAndIndex,
  getSummary,
  getTop5MajorShareHolder,
  getValuationHistory,
} from './store/thunk'

const UseApplyPDF = () => {
  const dispatch = useDispatch()

  const setting = useSelector(selectSetting)
  const dataTopInfo = useSelector(selectBasicInfo)
  const dropdownCompanyOverviewValue = useSelector(
    selectDropdownCompanyOverviewValue,
  )
  const reportDate = useSelector(selectReportDate)
  const listPeerAnalysisChecked = useSelector(selectPopupPeerAnalysis)
  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)
  const timeRangeStockPerformanceVsIndex = useSelector(
    selectTimeRangeStockPerformanceVsIndex,
  )
  const timeRangeForeignNetBuyingSelling = useSelector(
    selectTimeRangeForeignNetBuyingSelling,
  )

  const comGroup = useComGroup(dataTopInfo.organizationId)

  const [isHandle, setIsHandle] = useState(false)

  const payloadGetStockPerformanceAndIndex =
    timeRangeStockPerformanceVsIndex.type === TYPE_TIME_RANGE.PERIOD
      ? {
          OrganizationId: dataTopInfo.organizationId,
          TimeRange: timeRangeStockPerformanceVsIndex.period,
          ReportDate: reportDate,
        }
      : {
          OrganizationId: dataTopInfo.organizationId,
          StartDate: timeRangeStockPerformanceVsIndex.rangeForm,
          EndDate: timeRangeStockPerformanceVsIndex.rangeTo,
          ReportDate: reportDate,
        }

  const payloadGetForeignNetTrading =
    timeRangeForeignNetBuyingSelling.type === TYPE_TIME_RANGE.PERIOD
      ? {
          OrganizationId: dataTopInfo.organizationId,
          TimeRange: timeRangeForeignNetBuyingSelling.period,
          ReportDate: reportDate,
        }
      : {
          OrganizationId: dataTopInfo.organizationId,
          StartDate: timeRangeForeignNetBuyingSelling.rangeForm,
          EndDate: timeRangeForeignNetBuyingSelling.rangeTo,
          ReportDate: reportDate,
        }

  const {
    auditStatus,
    q_quarter1,
    q_quarter2,
    q_quarter3,
    q_quarter4,
    q_quarter5,
    q_year1,
    q_year2,
    q_year3,
    q_year4,
    q_year5,
    reportType,
    year1,
    year2,
    year3,
  } = popupFinancialStatement

  const getIndicatorBalanceSheet = () => {
    const mapIndicator = {
      [COM_GROUP.BANK]: indicatorBalanceSheetBank,
      [COM_GROUP.CORPORATE]: indicatorBalanceSheetCorp,
      [COM_GROUP.INSURANCE]: indicatorBalanceSheetIns.concat(
        indicatorBalanceSheetIns1,
      ),
      [COM_GROUP.SECURITIES]: indicatorBalanceSheetSec,
    }
    return mapIndicator[comGroup] || []
  }

  const getIndicatorIncomeStatement = () => {
    const mapIndicator = {
      [COM_GROUP.BANK]: indicatorIncomeStatementBank,
      [COM_GROUP.CORPORATE]: indicatorIncomeStatementCorp,
      [COM_GROUP.INSURANCE]: indicatorIncomeStatementIns.concat(
        indicatorIncomeStatementIns1,
      ),
      [COM_GROUP.SECURITIES]: indicatorIncomeStatementSec,
    }
    return mapIndicator[comGroup] || []
  }

  const getIndicatorFinancialData = () => {
    const mapIndicator = {
      [COM_GROUP.BANK]: indicatorFinancialDataBank,
      [COM_GROUP.CORPORATE]: indicatorFinancialDataCorp,
      [COM_GROUP.INSURANCE]: indicatorFinancialDataCorp,
      [COM_GROUP.SECURITIES]: indicatorFinancialDataSec,
    }
    return mapIndicator[comGroup] || []
  }

  const getIndicatorFinancialRatios = () => {
    const mapIndicator = {
      [COM_GROUP.BANK]: indicatorFinancialRatiosBank,
      [COM_GROUP.CORPORATE]: indicatorFinancialRatiosCorp,
      [COM_GROUP.INSURANCE]: indicatorFinancialRatiosCorp,
      [COM_GROUP.SECURITIES]: indicatorFinancialRatiosCorp,
    }
    return mapIndicator[comGroup] || []
  }

  const getIndicatorKeyFinancial = () => {
    const mapIndicator = {
      [COM_GROUP.BANK]: indicatorKeyFinancialBank,
      [COM_GROUP.CORPORATE]: indicatorKeyFinancialCorp,
      [COM_GROUP.INSURANCE]: indicatorKeyFinancialCorp,
      [COM_GROUP.SECURITIES]: indicatorKeyFinancialCorp,
    }
    return mapIndicator[comGroup] || []
  }

  const mapApplyPDF = {
    overview: getOverview({
      OrganizationId: dataTopInfo.organizationId,
    }),
    dropdownCompanyOverview:
      dropdownCompanyOverviewValue ===
      DROPDOWN_COMPANY_OVERVIEW.COMPARE_WITH_SECTOR
        ? getCompareWithSector({
            OrganizationId: dataTopInfo.organizationId,
          })
        : getRevenueBreakdown({
            OrganizationId: dataTopInfo.organizationId,
          }),
    stockData: getStockData({
      OrganizationId: dataTopInfo.organizationId,
      ReportDate: reportDate,
    }),
    oneYear_PriceAndVolume: getStockData1Yrs({
      OrganizationId: dataTopInfo.organizationId,
    }),
    financialRatios: [
      getFinancialRatio({
        OrganizationId: dataTopInfo.organizationId,
      }),
      setFinancialRatiosById(keyBy(getIndicatorFinancialRatios(), 'index')),
    ],
    valuationHistory: getValuationHistory({
      OrganizationId: dataTopInfo.organizationId,
      ReportDate: reportDate,
    }),
    peerAnalysis: [
      getKeyFinancialData({
        OrganizationIds: [
          dataTopInfo.organizationId,
          ...listPeerAnalysisChecked.map((i) => i.organizationId),
        ].toString(),
        ReportDate: reportDate,
      }),
      setKeyFinancialById(keyBy(getIndicatorKeyFinancial(), 'index')),
      getPricePerformanceChart({
        OrganizationId: [
          dataTopInfo.organizationId,
          ...listPeerAnalysisChecked.map((i) => i.organizationId),
        ].toString(),
        ReportDate: reportDate,
      }),
    ],
    financialData: [
      getFinancialData({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
      setFinancialDataById(keyBy(getIndicatorFinancialData(), 'index')),
    ],
    ownershipAnalysis: [
      getOwnershipAnalysis({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
      getTop5MajorShareHolder({
        OrganizationId: dataTopInfo.organizationId,
      }),
      getMajorShareHolder({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
      getOwnershipAnalysisChart({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
    ],
    priceData: getPriceData({
      OrganizationId: dataTopInfo.organizationId,
      ReportDate: reportDate,
    }),
    stockPerformanceVsIndex: getStockPerformanceAndIndex(
      payloadGetStockPerformanceAndIndex,
    ),
    pricePerformance: [
      getPricePerformance({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
      setPricePerformanceById(keyBy(indicatorPricePerFormance, 'index')),
    ],
    foreignNetBuyingSelling: getForeignNetTrading(payloadGetForeignNetTrading),
    balanceSheet: [
      getBalanceStatement({
        OrganizationId: dataTopInfo.organizationId,
        ReportType: reportType,
        Audit: auditStatus.toString(),
        RealYears: [year1, year2, year3].toString(),
        RealQuaters: [
          q_quarter1,
          q_quarter2,
          q_quarter3,
          q_quarter4,
          q_quarter5,
        ].toString(),
        RealQuaterYears: [
          q_year1,
          q_year2,
          q_year3,
          q_year4,
          q_year5,
        ].toString(),
      }),
      setBalanceSheetById(keyBy(getIndicatorBalanceSheet(), 'index')),
    ],
    inComeStatement: [
      getIncomeStatement({
        OrganizationId: dataTopInfo.organizationId,
        ReportType: reportType,
        Audit: auditStatus.toString(),
        RealYears: [year1, year2, year3].toString(),
        RealQuaters: [
          q_quarter1,
          q_quarter2,
          q_quarter3,
          q_quarter4,
          q_quarter5,
        ].toString(),
        RealQuaterYears: [
          q_year1,
          q_year2,
          q_year3,
          q_year4,
          q_year5,
        ].toString(),
      }),
      setInComeStatementById(keyBy(getIndicatorIncomeStatement(), 'index')),
    ],
  }

  const applyPDF = async () => {
    dispatch(getProfile())
    dispatch(
      getSummary({
        OrganizationId: dataTopInfo.organizationId,
        ReportDate: reportDate,
      }),
    )
    if (
      setting.companyProfile ||
      setting.companyPosition ||
      setting.businessStrategies
    ) {
      dispatch(getOverview({ OrganizationId: dataTopInfo.organizationId }))
    }

    dispatch(
      changeBalanceSheetIds(
        getIndicatorBalanceSheet().map((item) => item.index),
      ),
    )
    dispatch(
      changeIncomeStatementIds(
        getIndicatorIncomeStatement().map((item) => item.index),
      ),
    )
    dispatch(
      changeIndicatorFinancialDataCorp(
        getIndicatorFinancialData().map((item) => item.index),
      ),
    )
    dispatch(
      changeIndicatorFinancialRatiosCorp(
        getIndicatorFinancialRatios().map((item) => item.index),
      ),
    )
    dispatch(
      changeIndicatorKeyFinancial(
        getIndicatorKeyFinancial().map((item) => item.index),
      ),
    )

    const settingChecked = Object.keys(setting).filter((key) => setting[key])
    const arrPromise = []
    settingChecked.map((item) => {
      if (mapApplyPDF[item]) {
        if (typeof mapApplyPDF[item] === 'object') {
          return mapApplyPDF[item].map((el) => arrPromise.push(el))
        }
        return arrPromise.push(mapApplyPDF[item])
      }
      return {}
    })
    const awaitFunc = async (n) => {
      return await dispatch(n)
    }
    const unresolvedPromises = arrPromise.map(awaitFunc)
    Promise.all(unresolvedPromises)
      .then(() => {
        dispatch(changeShowPreview(true))
        dispatch(changeLoadingPDF(false))
        setIsHandle(false)
        dispatch(changeValueReloadData(true))
      })
      .catch((error) => error.log(error))
  }

  return {
    applyPDF,
    setIsHandle,
    isHandle,
  }
}

export default UseApplyPDF
