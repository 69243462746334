import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'
import { getListDisplay, handleRemoveAllTicker } from './store/slice'

const RemoveAll = () => {
  const dispatch = useDispatch()

  const listDisplay = useSelector(getListDisplay)

  const displayedValues = Object.values(listDisplay).filter(
    (value) => value.isDisplay && value.isChecked,
  )
  const displayedItemIds = Object.keys(listDisplay).filter(
    (id) => listDisplay[id].isDisplay && listDisplay[id].isChecked,
  )
  const hasTickerChecked = displayedValues.length > 0

  const removeAllTicker = () => {
    dispatch(handleRemoveAllTicker(displayedItemIds))
  }

  return (
    <div className={style.wrapRemoveAll}>
      <div className={style.removeAll}>
        <Span style={{ color: '#ececec', opacity: 0.5 }}>
          <Translate
            value={'newsReport.dailyReport.dailyReport.COMPANY_SELECTED'}
          />
        </Span>
        {hasTickerChecked && (
          <i
            className={`icon-trash ${style.iconTrash}`}
            onClick={removeAllTicker}
          />
        )}
      </div>
      {hasTickerChecked && (
        <div className="d-flex justify-content-end">
          <div className={style.smallLine} />
        </div>
      )}
    </div>
  )
}

export default RemoveAll
