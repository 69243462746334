import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { FORMAT } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

export const ShareIssueForm = ({ data }) => {
  return (
    <>
      <Span className={style.title}>
        <Translate
          value="market.event.shareIssueForm.TITLE"
          ticker={data.ticker}
          planVolumn={formatVal(data.planVolumn, 0)}
        />
      </Span>
      <Span style={{ fontSize: 12, marginBottom: 12 }} className={style.time}>
        {data?.date && (
          <DateTimeSpan
            date={data.date}
            formatDate={FORMAT.NEWS_WITHOUT_TIME}
          />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.HEADER"
          organizationName={data.organizationName}
        />
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.ISSUE_METHOD"
          className="mr-4"
        />
        {data.method}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.NUMBER_SHARES_PLANNED"
          className="mr-4"
        />
        {formatVal(data.planVolumn, 0)}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.ISSUE_PRICE"
          issuePrice={formatVal(data.issuePrice, 0)}
        />
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.EXERCISE_RATIO"
          className="mr-4"
        />
        {valToPercent(data.exerciseRatio)}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.ISSUE_VOLUME"
          className="mr-4"
        />
        {formatVal(data.issueVolume, 0)}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.TOTAL_VALUE"
          className="mr-4"
        />
        {formatVal(data.totalValue / 10 ** 6)}
        <Translate value="market.event.MILLION_VND" className="ml-4" />
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.EXRIGHT_DATE"
          className="mr-4"
        />
        {data?.exrightDate && (
          <DateTimeSpan date={data.exrightDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.ISSUE_DATE"
          className="mr-4"
        />
        {data?.exerciseDate && (
          <DateTimeSpan date={data.exerciseDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.shareIssueForm.LISTING_DATE"
          className="mr-4"
        />
        {data?.listingDate && (
          <DateTimeSpan date={data.listingDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
    </>
  )
}
