import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import style from '../../index.module.css'
import { YEARS_FILTER } from '../common/constants'
import { getXAxisKey } from '../helper'
import ChartComponent from './ChartComponent'
import {
  changeYear,
  selectDataChart,
  selectListSelection,
  selectLoading,
  selectMaxYear,
  selectYear,
} from './store/slice'
import { getExportYear, getMaxDate, getOverviewIds } from './store/thunk'

export const ExportChart = ({ width, height }) => {
  const dispatch = useDispatch()
  const timeout = useRef({ exportYear: null })

  const dataChart = useSelector(selectDataChart)
  const listSelection = useSelector(selectListSelection)
  const currentYear = useSelector(selectYear)
  const isLoading = useSelector(selectLoading)
  const maxYear = useSelector(selectMaxYear)
  const locale = useSelector((state) => state.i18n.locale)

  const handleYearChange = (val) => {
    dispatch(changeYear(val))
  }

  const convertedDataChart = dataChart?.map((item) => ({
    ...item,
    time: getXAxisKey(item.month, item.year, locale),
  }))

  useEffect(() => {
    dispatch(getMaxDate({ FisheryConst: 'ExportByMarket' }))
  }, [])

  useEffect(() => {
    dispatch(getOverviewIds())
  }, [locale])

  useEffect(() => {
    if (maxYear && listSelection) {
      clearTimeout(timeout.current.exportYear)
      timeout.current.exportYear = setTimeout(() => {
        dispatch(
          getExportYear({
            Year: currentYear ?? maxYear,
          }),
        )
        clearTimeout(timeout.current.exportYear)
      }, 100)
    }

    return () => {
      clearTimeout(timeout.current.exportYear)
    }
  }, [currentYear, maxYear, listSelection, locale])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <SizeTracker>
      {(size) => {
        return (
          <div className="h-100">
            <div className="d-flex justify-content-end">
              <div
                className={`${style.inputDropdown}`}
                style={{ width: '100px' }}
              >
                <SelectCustom
                  value={currentYear ?? maxYear}
                  isI18n={false}
                  selectData={YEARS_FILTER}
                  handleChange={handleYearChange}
                />
              </div>
            </div>
            {size.height && (
              <div
                id="exportChart"
                className="position-relative"
                style={{ height: height - size.height }}
              >
                {dataChart.length ? (
                  <ChartComponent
                    data={convertedDataChart}
                    width={width}
                    height={height - size.height}
                    keyXAxis="name"
                    barKey="value"
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}
