import InterestRateProxy from '../../proxies/economy/InterestRateProxy'
import { ServiceBase } from '../ServiceBase'

class InterestRateService extends ServiceBase {
  getMaxDate(params) {
    return this.applyMemoryCache(
      'InterestRateService.getMaxDate',
      params,
    )(() => InterestRateProxy.getMaxDate(params))
  }

  getStatisticStateBank(params) {
    return this.applyMemoryCache(
      'InterestRateService.getStatisticStateBank',
      params,
    )(() => InterestRateProxy.getStatisticStateBank(params))
  }

  getStatisticStateBankName(params) {
    return this.applyMemoryCache(
      'InterestRateService.getStatisticStateBankName',
      params,
    )(() => InterestRateProxy.getStatisticStateBankName(params))
  }

  downloadStatisticStateBank(params) {
    return this.getFileDownload(() =>
      InterestRateProxy.downloadStatisticStateBank(params),
    )
  }

  getMovementDepositAndLending(params) {
    return this.applyMemoryCache(
      'InterestRateService.getMovementDepositAndLending',
      params,
    )(() => InterestRateProxy.getMovementDepositAndLending(params))
  }

  getStatisticOtherBank(data) {
    return this.applyMemoryCache(
      'InterestRateService.getStatisticOtherBank',
      data,
    )(() => InterestRateProxy.getStatisticOtherBank(data))
  }

  downloadStatisticOtherBank(params) {
    return this.getFileDownload(() =>
      InterestRateProxy.downloadStatisticOtherBank(params),
    )
  }

  getCompareOtherBank(params) {
    return this.applyMemoryCache(
      'InterestRateService.getCompareOtherBank',
      params,
    )(() => InterestRateProxy.getCompareOtherBank(params))
  }
}

const myInstance = new InterestRateService()
export default myInstance
