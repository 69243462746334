import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../utils/Export'

export const getCoveredWarrantTimeAndSales = createAsyncThunk(
  'market/coveredWarrant/timeAndSales/GET_COVERED_WARRANT_TIME_AND_SALES',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getCoveredWarrantTimeAndSales(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
export const downloadTimeAndSales = createAsyncThunk(
  'market/coveredWarrant/timeAndSales/DOWNLOAD_TIME_AND_SALES',
  async (data) => {
    const response = await CoveredWarrantService.downloadTimeAndSales(data)
    handleExport(response.data, response.filename)
  },
)

export const getCoveredWarrantTimeAndSalesChart = createAsyncThunk(
  'market/coveredWarrant/timeAndSalesChart/GET_COVERED_WARRANT_TIME_AND_SALES_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getCoveredWarrantTimeAndSalesChart(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
