export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'orderMatchingParent',
    title: 'market.derivative.ORDER_MATCHING',
  },
  {
    key: 'totalMatchVolume',
    title: 'market.derivative.VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchValue',
    title: 'market.derivative.VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'dealParent',
    title: 'market.derivative.PUT_THROUGH',
  },
  {
    key: 'totalDealVolume',
    title: 'market.derivative.VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealValue',
    title: 'market.derivative.VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalVolume',
    title: 'market.derivative.TOTAL_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalValue',
    title: 'market.derivative.TOTAL_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'openInterest',
    title: 'market.derivative.OPEN_INTEREST',
    format: null,
    count: 0,
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'ticker',
    title: 'market.derivative.TICKER_TITLE',
  },
  {
    key: 'percentPriceChange',
    title: 'market.derivative.PERCENT_CHANGE',
  },
  {
    key: 'openPrice',
    title: 'market.derivative.OPEN_PRICE',
    format: 10 ** 0,
    count: 2,
  },
  {
    key: 'highPrice',
    title: 'market.derivative.HIGH_PRICE',
    format: 10 ** 0,
    count: 2,
  },
  {
    key: 'lowPrice',
    title: 'market.derivative.LOW_PRICE',
    format: 10 ** 0,
    count: 2,
  },
  {
    key: 'closePrice',
    title: 'market.derivative.CLOSE_PRICE',
    format: 10 ** 0,
    count: 2,
  },
  {
    key: 'totalMatchParent',
    title: 'market.derivative.ORDER_MATCHING',
  },
  {
    key: 'totalMatchVolume',
    title: 'market.derivative.VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchValue',
    title: 'market.derivative.VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalDealParent',
    title: 'market.derivative.PUT_THROUGH',
  },
  {
    key: 'totalDealVolume',
    title: 'market.derivative.VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealValue',
    title: 'market.derivative.VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalVolume',
    title: 'market.derivative.TOTAL_VOLUME',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalValue',
    title: 'market.derivative.TOTAL_VALUE',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'openInterest',
    title: 'market.derivative.OPEN_INTEREST',
  },
  // {
  //   key: 'percentOpenInterestChange',
  //   title: 'market.derivative.PERCENT_OPEN_INTEREST_CHANGE',
  // },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}
