import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MacroService from '../../../../../../core/services/common/MacroService'
import FinancialDataService from '../../../../../../core/services/corporate/FinancialDataService'
import PeerValuationService from '../../../../../../core/services/corporate/PeerValuationService'
import { handleExport } from '../../../../../utils/Export'
import { TABLE_TYPE } from '../constant'
import { addOrgIds } from '../header/store/slice'
import {
  getDownloadTableMethod,
  getPeerComparisonServiceMethod,
} from '../helper'

export const getPeerComparisonPerformance = createAsyncThunk(
  'corporate/peerValuation/overview/peersComparison/GET_PEER_COMPARISON_PERFORMANCE',
  async (data, { rejectWithValue, dispatch }) => {
    const getDataMethod = getPeerComparisonServiceMethod(
      TABLE_TYPE.PERFORMANCE,
      data.comGroup,
    )

    try {
      const response = await getDataMethod(data.params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonValuation = createAsyncThunk(
  'corporate/peerValuation/overview/peersComparison/GET_PEER_COMPARISON_VALUATION',
  async (data, { rejectWithValue, dispatch }) => {
    const getDataMethod = getPeerComparisonServiceMethod(
      TABLE_TYPE.VALUATION,
      data.comGroup,
    )
    try {
      const response = await getDataMethod(data.params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonOwnership = createAsyncThunk(
  'corporate/peerValuation/overview/GET_PEER_COMPARISON_OWNERSHIP',
  async (data, { rejectWithValue, dispatch }) => {
    const getDataMethod = getPeerComparisonServiceMethod(
      TABLE_TYPE.OWNERSHIP,
      data.comGroup,
    )
    try {
      const response = await getDataMethod(data.params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonCredit = createAsyncThunk(
  'corporate/peerValuation/overview/GET_PEER_COMPARISON_CREDIT',
  async (data, { rejectWithValue, dispatch }) => {
    const getDataMethod = getPeerComparisonServiceMethod(
      TABLE_TYPE.CREDIT,
      data.comGroup,
    )
    try {
      const response = await getDataMethod(data.params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonProfitability = createAsyncThunk(
  'corporate/peerValuation/overview/GET_PEER_COMPARISON_PROFITABILITY',
  async (data, { rejectWithValue, dispatch }) => {
    const getDataMethod = getPeerComparisonServiceMethod(
      TABLE_TYPE.PROFITABILITY,
      data.comGroup,
    )
    try {
      const response = await getDataMethod(data.params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonBalanceSheet = createAsyncThunk(
  'corporate/peerValuation/overview/GET_PEER_COMPARISON_BALANCESHEET',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PeerValuationService.getPeerComparisonBalanceSheet(
        params,
      )
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeerComparisonIncomeStatement = createAsyncThunk(
  'corporate/peerValuation/overview/GET_PEER_COMPARISON_INCOMESTATEMENT',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await PeerValuationService.getPeerComparisonIncomeStatement(params)
      if (response.success) {
        dispatch(
          addOrgIds(response.data.map((ticker) => ticker.organizationId)),
        )
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxPeriod = createAsyncThunk(
  'corporate/peerValuation/overview/getMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PeerValuationService.getMaxPeriod(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicatorMapping = createAsyncThunk(
  'corporate/peerValuation/overview/GET_INDICATOR_MAPPING',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getIndicatorMapping(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExchangeRates = createAsyncThunk(
  'corporate/peerValuation/overview/GET_EXCHANGE_RATES',
  async (params, { rejectWithValue }) => {
    const response = await MacroService.getExchangeRates(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadPeerComparisonPerformance = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonPerformance',
  async (data) => {
    const downloadMethod = getDownloadTableMethod(
      TABLE_TYPE.PERFORMANCE,
      data.comGroup,
    )

    const response = await downloadMethod(data.params)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonValuation = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonValuation',
  async (data) => {
    const downloadMethod = getDownloadTableMethod(
      TABLE_TYPE.VALUATION,
      data.comGroup,
    )

    const response = await downloadMethod(data.params)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonOwnership = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonOwnership',
  async (data) => {
    const downloadMethod = getDownloadTableMethod(
      TABLE_TYPE.OWNERSHIP,
      data.comGroup,
    )

    const response = await downloadMethod(data.params)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonCredit = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonCredit',
  async (data) => {
    const downloadMethod = getDownloadTableMethod(
      TABLE_TYPE.CREDIT,
      data.comGroup,
    )

    const response = await downloadMethod(data.params)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonProfitability = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonProfitability',
  async (data) => {
    const downloadMethod = getDownloadTableMethod(
      TABLE_TYPE.PROFITABILITY,
      data.comGroup,
    )

    const response = await downloadMethod(data.params)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonBalanceSheet = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonBalanceSheet',
  async (data) => {
    const response =
      await PeerValuationService.downloadPeerComparisonBalanceSheet(data)
    handleExport(response.data, response.filename)
  },
)
export const downloadPeerComparisonIncomeStatement = createAsyncThunk(
  'corporate/peerValuation/overview/downloadPeerComparisonIncomeStatement',
  async (data) => {
    const response =
      await PeerValuationService.downloadPeerComparisonIncomeStatement(data)
    handleExport(response.data, response.filename)
  },
)
