import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../../../common/dropdown'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { rankColorConstants, scoreTypes } from '../../constants'
import {
  selectTooltipScoreData,
  selectTooltipScoreLoading,
} from '../../store/slice'
import { getTooltipScoreResult } from '../../store/thunk'
import style from './index.module.css'

export const TooltipComponent = ({
  parentRef,
  data,
  isOpen,
  scoreType,
  totalRecords,
}) => {
  const dropdownRef = useRef()
  const dispatch = useDispatch()

  // Use selector
  const loading = useSelector(selectTooltipScoreLoading)
  const tooltipScoreData = useSelector(selectTooltipScoreData)

  // Use state
  const [isPositionTop, setIsPositionTop] = useState(false)
  const [dropdownHeight, setDropdownHeight] = useState()

  // Get data
  const getScoreTitle = useMemo(() => {
    const key = Object.keys(scoreTypes).find(
      (key) => scoreTypes[key] === scoreType,
    )
    return key ? `sector.sectorConstituents.ranking.${key}` : 'Score'
  }, [scoreType])

  const getRankTextColor = useMemo(() => {
    return (
      <div
        style={{ background: rankColorConstants[data[scoreType]] }}
        className={style.rankTooltipLabel}
      >
        <Span style={{ fontSize: 14 }}>{data[scoreType] || EMPTY_VALUE}</Span>
      </div>
    )
  }, [data[scoreType]])

  // Use effect
  useEffect(() => {
    if (isOpen) {
      dispatch(
        getTooltipScoreResult({
          OrganizationId: data.organizationId,
          RateResultId: data.rateResultId,
        }),
      )
    }
  }, [isOpen, data])

  useEffect(() => {
    if (dropdownRef.current) {
      const { y, height } = dropdownRef.current.getBoundingClientRect()
      if (y + height + 100 > window.innerHeight) {
        setIsPositionTop(true)
        setDropdownHeight(y + height)
      }
    }
  }, [loading])

  useEffect(() => {
    if (!isOpen) {
      setDropdownHeight()
      setIsPositionTop(false)
    }
  }, [isOpen])

  return (
    <Dropdown
      key={dropdownHeight}
      parentRef={parentRef}
      isShow={isOpen}
      position={
        isPositionTop
          ? positionDropdown.TOP_LEFT_LEFT
          : positionDropdown.BOTTOM_LEFT_LEFT
      }
    >
      <div ref={dropdownRef} className={`box-notice ${style.tooltipContainer}`}>
        <div
          className={[
            style.tooltipContainerChild,
            style.firstTooltipContainerChild,
          ].join(' ')}
        >
          <div>
            <Span style={{ fontSize: 24, fontWeight: 600 }}>{data.ticker}</Span>
            <div>
              <Span style={{ fontSize: 10, opacity: 0.5 }}>
                {data.organizationShortName}
              </Span>
            </div>
          </div>
          <div className="t-right">
            <div className="align-center mb-10">
              <Span className="mr-8">
                <Translate value={getScoreTitle} />:
              </Span>
              {getRankTextColor}
            </div>
            <Span style={{ fontWeight: 600 }}>
              {data.rankValue}/{totalRecords}
            </Span>
          </div>
        </div>
        <div className={[style.tooltipContainerChild, 'row'].join(' ')}>
          {loading ? (
            <Loading />
          ) : (
            <>
              {tooltipScoreData
                .filter((item) => item.scoreType.toLowerCase() === scoreType)
                .map((item) => (
                  <div
                    key={item.rateIndicatorName}
                    className="col-6 align-center mb-10"
                  >
                    {item.rateValue ? (
                      <i
                        className={[style.iconTrue, 'icon-tick-round'].join(
                          ' ',
                        )}
                      />
                    ) : (
                      <i
                        className={['icon-delete-round', style.iconFalse].join(
                          ' ',
                        )}
                      />
                    )}
                    <Span style={{ marginLeft: 8 }}>
                      {item.rateIndicatorName}
                    </Span>
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </Dropdown>
  )
}

TooltipComponent.propTypes = {
  parentRef: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  scoreType: PropTypes.string.isRequired,
  totalRecords: PropTypes.number,
}
