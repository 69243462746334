import PropTypes from 'prop-types'
import React, { memo, useEffect, useRef, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { uuid } from '../../../utils/Common'
import { getFontSize } from '../../../utils/FontSize'
import { Span } from '../../html/Span'
import { getTextWidth } from '../helper'
import { TooltipInfo } from './../../tooltipInfo'
import { Item, TYPE } from './Item'
import style from './index.module.css'

const CHECKBOX_WIDTH = 13
const ICON_MAX_WIDTH = 40
const MARGIN_RIGHT = 25
const TEXT_MIN_WIDTH = 20

export const Footer = memo(
  ({
    listRefs,
    list,
    isOneRow,
    isOneColumn,
    isDownTheLine,
    listCheckbox,
    appendStyle,
    itemStyle,
    setListCheckbox,
    onMouseOverItem,
    onMouseLeaveItem,
  }) => {
    const ref = useRef()
    const [itemsTextWidth, setItemsTextWidth] = useState([])

    const MAP = {
      [TYPE.SQUARE]: style.square,
      [TYPE.SQUARE_TWO_COLOR]: style.squareTwoColor,
      [TYPE.SQUARE_MARKER_LINE]: style.squareMarkerLine,
      [TYPE.LINE]: style.line,
      [TYPE.CIRCLE_MARKER_LINE]: style.circleMarkerLine,
      [TYPE.TRIANGLE_MARKER_LINE]: style.triangleMarkerLine,
      [TYPE.TRIANGLE]: style.triangle,
      [TYPE.CIRCLE]: style.circle,
      [TYPE.LINE_STROKE_DOT]: style.lineStrokeDot,
      [TYPE.RECTANGLE]: style.rectangle,
      [TYPE.DASHED_LINE]: style.dashedLine,
    }

    const fontSize11 = getFontSize(11)

    const getStrStyle = (item, strIdKey) => {
      const styles = []
      if (item.before) {
        styles.push(strIdKey + ':before {')
        if (item.before.bgColor) {
          styles.push('background: ' + item.before.bgColor + ';')
        }
        if (item.before.borderBottomColor) {
          styles.push('border-bottom: ' + item.before.borderBottomColor + ';')
        }
        styles.push('}')
      }

      if (item.after) {
        styles.push(strIdKey + ':after {')
        if (item.after.bgColor) {
          styles.push('background: ' + item.after.bgColor)
        }
        if (item.after.borderBottomColor) {
          styles.push('border-bottom: ' + item.after.borderBottomColor)
        }
        styles.push('}')
      }

      if (item.dashedLine) {
        styles.push(strIdKey + ':after {')
        if (item.dashedLine.bgColor) {
          styles.push(
            `background: linear-gradient(to right,${item.dashedLine.bgColor} 0px,${item.dashedLine.bgColor} 7px,transparent 7px,transparent 9px,${item.dashedLine.bgColor} 9px,${item.dashedLine.bgColor} 16px,transparent 16px,transparent 18px,${item.dashedLine.bgColor} 18px,${item.dashedLine.bgColor} 25px);`,
          )
        }
        styles.push('}')
      }

      return styles.join('\n')
    }

    const getClassNameContainer = () => {
      if (isOneColumn) {
        return style.verticalContainer
      }

      if (isOneRow) {
        return style.ellipsisHorizontalContainer
      }

      return style.horizontalContainer
    }

    const handleCheckboxChart = (e) => {
      const { value, checked } = e.target
      if (!checked) {
        setListCheckbox(listCheckbox.filter((item) => item !== value))
      } else {
        setListCheckbox([...listCheckbox, value])
      }
    }

    const onClickCheckbox = (e, id) => {
      e.stopPropagation()
      const el = document.getElementById(id)
      if (el) {
        el.click()
      }
    }

    const handleMouseOverItem = (data, index) => {
      onMouseOverItem && onMouseOverItem(data, index)
    }

    const handleMouseLeaveItem = (data, index) => {
      onMouseLeaveItem && onMouseLeaveItem(data, index)
    }

    const getClassNameItem = () => {
      return `${isOneColumn ? style.verticalItem : style.horizontalItemFooter}
      ${
        isDownTheLine
          ? style.alignItemsFlexStart
          : isOneRow
          ? style.textEllipsis
          : ''
      } d-flex`
    }

    const getItemTextWidth = () => {
      if (!ref.current || !list.length || isOneColumn || !isOneRow) {
        return []
      }

      const mainWidth = ref.current.offsetWidth
      const itemsTextWidth = list
        .map((item) => (item.isI18n === false ? item.text : I18n.t(item.text)))
        .map((text) => getTextWidth(text, fontSize11, undefined, 12))
      const totalWidth = itemsTextWidth
        .map(
          (num, index) =>
            num +
            (list[index].dataKey ? CHECKBOX_WIDTH : 0) +
            ICON_MAX_WIDTH +
            MARGIN_RIGHT,
        )
        .reduce((prev, cur) => prev + cur)

      if (mainWidth < totalWidth) {
        let widthNeedSubtract = Math.round(totalWidth - mainWidth)

        do {
          const maxTextWidth = Math.max(...itemsTextWidth)
          const indexMaxTextWidth = itemsTextWidth.findIndex(
            (num) => num === maxTextWidth,
          )
          itemsTextWidth[indexMaxTextWidth]--
          widthNeedSubtract--
        } while (widthNeedSubtract > 0)

        return itemsTextWidth.map((num) =>
          num < TEXT_MIN_WIDTH ? TEXT_MIN_WIDTH : num,
        )
      }

      return itemsTextWidth
    }

    useEffect(() => {
      const widths = getItemTextWidth()
      setItemsTextWidth(widths)
    }, [ref.current?.offsetWidth])

    return (
      <>
        <div
          ref={ref}
          className={[style.main, isOneColumn ? '' : 'w-100'].join(' ')}
        >
          <div className={getClassNameContainer()} style={appendStyle}>
            {list.length === 0 && (
              <div className={getClassNameItem()}>&nbsp;</div>
            )}
            {list.length > 0 &&
              list.map((item, index) => {
                const idKey = item.type + uuid()
                const strIdKey = '#' + idKey
                return (
                  <React.Fragment key={idKey}>
                    <style
                      dangerouslySetInnerHTML={{
                        __html: getStrStyle(item, strIdKey),
                      }}
                    />
                    <div
                      className={getClassNameItem()}
                      style={itemStyle}
                      ref={(el) => (listRefs[index] = el)}
                      data-html2canvas-ignore={
                        item.dataKey && !listCheckbox.includes(item.dataKey)
                          ? true
                          : undefined
                      }
                    >
                      {item.dataKey && (
                        <label data-html2canvas-ignore="true">
                          <input
                            id={`checkbox${idKey}`}
                            type="checkbox"
                            className={`checkbox line ${style.borderCheckbox}`}
                            checked={
                              listCheckbox.includes(item.dataKey) ? true : false
                            }
                            value={item.dataKey}
                            onChange={(e) => {
                              handleCheckboxChart(e)
                            }}
                          />
                        </label>
                      )}
                      <div
                        id={idKey}
                        className={
                          item.renderIcon ? 'align-center' : MAP[item.type]
                        }
                      >
                        {item.renderIcon}
                      </div>
                      <Span
                        style={{
                          fontSize: 11,
                          overflow: 'hidden',
                          cursor: item.dataKey ? 'pointer' : 'auto',
                          width: isDownTheLine ? 'auto' : itemsTextWidth[index],
                          whiteSpace: isDownTheLine ? 'nowrap' : 'initial',
                        }}
                        onClick={(e) => onClickCheckbox(e, `checkbox${idKey}`)}
                        onMouseOver={() => handleMouseOverItem(item, index)}
                        onMouseLeave={() => handleMouseLeaveItem(item, index)}
                      >
                        <Item
                          text={item.text}
                          idKey={idKey}
                          isI18n={item.isI18n}
                          isOneRow={isOneRow}
                          fixedFontSize={itemStyle.fixedFontSize}
                          isDownTheLine={isDownTheLine}
                        />
                      </Span>
                      {item.tooltip && (
                        <TooltipInfo
                          tooltip={item.tooltip}
                          style={{ marginLeft: 4 }}
                        />
                      )}
                    </div>
                  </React.Fragment>
                )
              })}
          </div>
        </div>
      </>
    )
  },
)

Footer.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      type: PropTypes.string,
      isI18n: PropTypes.bool,
      tooltip: PropTypes.string,
      before: PropTypes.shape({
        bgColor: PropTypes.string,
        borderBottomColor: PropTypes.string,
      }),
      after: PropTypes.shape({
        bgColor: PropTypes.string,
        borderBottomColor: PropTypes.string,
      }),
    }),
  ).isRequired,
  isOneColumn: PropTypes.bool,
  listCheckbox: PropTypes.array,
  isOneRow: PropTypes.bool,
  appendStyle: PropTypes.object,
  itemStyle: PropTypes.object,
  listRefs: PropTypes.object,
  isDownTheLine: PropTypes.bool,
}

Footer.defaultProps = {
  isOneColumn: false,
  isOneRow: true,
  appendStyle: {},
  itemStyle: {},
  listRefs: {},
  isDownTheLine: false,
}
