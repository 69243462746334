export const fundProfile = {
  PORTFOLIO: 'PORTFOLIO',
  NAV_DATA: 'NAV DATA',
  FINANCIAL_REPORT: 'FINANCIAL REPORTS',
  PERIODICAL_REPORT: 'PERIODICAL REPORTS',
  PROFILE: 'PROFILE',

  FUND_FLOW_NOTE: 'FiinGroup provides estimates for the Fund Flow statistics',

  portfolio: {
    ASSET_ALLOCATION: 'ASSET ALLOCATION',
    ASSET_ALLOCATION_OVER_TIME: 'ASSET ALLOCATION OVER TIME',
    PORTFOLIO_TABLE: 'PORTFOLIO',

    ALL_TICKER: 'All Tickers',
    ASSETS: 'Assets',
    SECTORS: 'Sectors',
    STOCKS: 'Stocks',
    STOCK: 'Stock',
    BOND: 'Bond',
    CASH_AND_OTHER: 'Cash and Others',
    OTHERS: 'Others',
    RELATIVE: 'Relative',
    ABSOLUTE: 'Absolute',
    CURRENT: 'Current Security Holdings',
    HISTORY: 'Security Holdings History',

    assetAllocation: {
      ASSET_ALLOCATION: 'Asset Allocation',
      CHART_NAME_ASSET_ALLOCATION_ASSETS:
        'ASSET ALLOCATION BY ASSETS %{month} - %{year}',
      CHART_NAME_ASSET_ALLOCATION_SECTORS:
        'ASSET ALLOCATION BY SECTORS %{month} - %{year}',
      CHART_NAME_ASSET_ALLOCATION_STOCKS:
        'ASSET ALLOCATION BY STOCKS %{month} - %{year}',
      HOLDING_VALUE: 'Holding value',
    },

    assetAllocationOverTime: {
      ASSET_ALLOCATION_OVER_TIME: 'Asset Allocation Over Time',
      CHART_NAME_ASSET_ALLOCATION_OVER_TIME_ASSETS:
        'ASSET ALLOCATION OVER TIME BY ASSETS',
      CHART_NAME_ASSET_ALLOCATION_OVER_TIME_SECTORS:
        'ASSET ALLOCATION OVER TIME BY SECTORS',
    },

    portfolioTable: {
      CONTRIBUTION: 'Contribution',
      VOLUME: 'Volume',
      GROUP_BY_INDUSTRY: 'Group by Industry',
      NOTE_UNIT_THOUSAND_VND: 'Unit: Thousand shares',
      COL_STOCK_PUBLIC_DATE: 'Public Date',
      COL_STOCK_TICKER: 'Ticker',
      COL_STOCK_NAME: 'Name',
      COL_STOCK_CLOSE_PRICE: 'Close Price (VND)',
      COL_STOCK_PERCENT_CHANGE: '% Price Change 1M',
      COL_STOCK_FUND_HOLDING: 'Fund Holding',
      COL_STOCK_VOLUME: 'Volume',
      COL_STOCK_VOLUME_CHANGE: 'Volume Change 1M',
      COL_STOCK_OUTSTANDING: '% Outstanding Share',
      COL_STOCK_VALUE: 'Value',
      COL_STOCK_VALUE_NOTE: 'Market value of shares',
      COL_STOCK_FUND_OWNER_CHANGE: "Fund's Ownership change 1M",
      COL_STOCK_VALUE_ICB: 'Value',
      COL_STOCK_FUND_OWNER_CHANGE_ICB: "Fund's Ownership change 1M",
      COL_STOCK_FUND_NAV: '% Portfolio weight',
    },
  },

  navData: {
    NAV_CERTIFICATE: 'NAV/CERTIFICATE',
    FUND_FLOW: 'FUND FLOW',
    NAV_DATA_TABLE: 'NAV DATA',

    RELATIVE: 'Relative',
    ABSOLUTE: 'Absolute',

    navCertificate: {
      NAV_CERTIFICATE: 'Nav/Certificate',
      CHART_NAME_NAV_CERTIFICATE: 'Nav Certificate with %{ticker}',
      NAV_CERT_Y_AXIS_NAV_CCQ_VND: 'NAV/CCQ (VND)',
      NAV_CERT_Y_AXIS_NAV_CCQ: 'NAV/CCQ',
      NAV_CERT_Y_AXIS_INDEX: 'Index (Point)',
      UNIT_NAV_CCQ: 'NAV/CCQ',
      UNIT_POINT: 'Point',
      INDEX_VN: 'VNINDEX',
      INDEX_VN30: 'VN30',
      FUND_AVERAGE: 'Fund Average',
      NO_COMPARISON: 'No Comparison',
    },

    fundFlow: {
      FUND_FLOW: 'Fund Flow',
      CHART_NAME_FUND_FLOW: 'Fund Flow',
      FUND_FLOW_Y_AXIS: 'Fund flow (Mil. VND)',
      FUND_FLOW_ACC_Y_AXIS: 'Acc. fund flow (Mil. VND)',
      FUND_FLOW_FOOTER: 'Fund flow',
      FUND_FLOW_ACC_FOOTER: 'Acc. fund flow 12 months',
      FUND_FLOW_IN_TOOLTIP: 'Fund in flow',
      FUND_FLOW_OUT_TOOLTIP: 'Fund out flow',
      FUND_FLOW_NET_TOOLTIP: 'Net fund flow',
      FUND_FLOW_ACC_TOOLTIP: 'Acc. fund flow 12M',
    },

    navDataTable: {
      COL_DATE: 'Date',
      COL_WEEK: 'Week',
      COL_MONTH: 'Month',
      COL_QUARTER: 'Quarter',
      COL_YEAR: 'Year',
      COL_FROM_DATE: 'From Date',
      COL_TO_DATE: 'To Date',
      COL_OUTSTANDING_FUND_CERT: 'Outstanding fund certificate',
      COL_NAV_CERT: 'NAV/Certificate',
      COL_CHANGE_NAV: '% Change NAV/CCQ',
      COL_NAV: 'Total NAV',
      COL_FUND_FLOW: 'Fund flow',
      COL_FOREIGN_VOLUME: 'Foreign ownership volume',
      COL_FOREIGN_RATE: 'Foreign ownership rate',
      COL_CLOSE_PRICE: 'Close Price',
      COL_DISCOUNT_VND: 'Discount/Premium',
      COL_DISCOUNT_PERCENT: 'Discount/Premium',
      NO_DATA_TEXT_TABLE: '%{ticker} does not public %{frequency} trading date',
    },
  },

  report: {
    FINANCIAL_STATEMENT: 'FINANCIAL STATEMENT',
    PERIODICAL_REPORT: 'PERIODICAL REPORT',

    STATEMENT: 'Statement',
    VIEW_BY: 'View by',
    NUMBER_OF_PERIOD: 'Number of period',
    LATEST_YEAR: 'Latest year',
    CURRENCY: 'Currency',
    UNIT: 'Unit',

    BALANCE_SHEET: 'Balance Sheet',
    INCOME_STATEMENT: 'Income Statement',
    CASH_FLOW: 'Cash Flow Statement',
    NOTE: 'Note to Account',
    ASSET_REPORT: 'Asset Report',
    PROFIT_AND_LOSS_REPORT: 'Profit and Loss Report',
    PORTFOLIO_REPORT: 'Portfolio Report',
    OTHER_REPORT: 'Other Report',

    YEARLY: 'Yearly',
    QUARTERLY: 'Quarterly',
    SIX_MONTHS: '6 Months',

    VND: 'VND',
    MILLION_VND: 'Million VND',
    BILLION_VND: 'Billion VND',
    USD: 'USD',
    THOUSAND_USD: 'Thousand USD',
    MILLION_USD: 'Million USD',

    AUDIT_STATUS: 'Audit Status',
    AUDIT_FIRM: 'Audit Firm',
    AUDIT_OPINION_TYPE_NAME: 'Audit Opinion Type',
  },

  profile: {
    BASIC_INFORMATION: 'BASIC INFORMATION',
    INVESTMENT_FEE: 'INVESTMENT FEES',
    DISTRIBUTOR: 'DISTRIBUTORS',
    INVESTMENT_STRATEGY: 'INVESTMENT STRATEGY',
    FUND_MANAGER: 'BOARD OF FUND MANAGERS',

    basicInfo: {
      FUND_NAME: 'Fund Name',
      TOTAL_NAV: 'NAV',
      NAV_CCQ: 'NAV/CCQ',
      FUND_TYPE_NAME: 'Fund Type',
      FUND_STRUCTURE_NAME: 'Fund Structure',
      REGISTER_DATE: 'Listing Date',
      FUND_MANAGEMENT: 'Fund Managerment',
      SUPERVISOR_BANK: 'Supervisor Bank',
      SCHEDULE_NAME: 'Trading Time',
      FOREIGN_OWNERSHIP: 'Foreign Ownership',
      MIN_INVEST: 'Minimum Buy in',
      ADDRESS: 'Address',
      WEBSITE: 'Website',
    },

    investmentStrategy: {
      DESCRIPTION_FUND: 'Description of Fund',
      INVESTMENT_STRATEGY: 'Investment Strategy',
      INVESTMENT_OBJ: 'Investment Objectives',
      INVESTOR: 'Investor',
    },

    fundManagers: {
      FULLNAME: 'Full Name',
      POSITION: 'Position',
      QUANTITY: 'Quantity',
      UPDATE_DATE: 'Update Date',
    },
  },
}
