import overviewProxy from '../../proxies/corporate/OverviewProxy'
import { ServiceBase } from '../ServiceBase'

class OverviewService extends ServiceBase {
  getBasicInfo(params) {
    return this.applyMemoryCache(
      'OverviewService.getBasicInfo',
      params,
    )(() => overviewProxy.getBasicInfo(params))
  }

  getSummaryInfo(params) {
    return this.applyMemoryCache(
      'OverviewService.getSummaryInfo',
      params,
    )(() => overviewProxy.getSummaryInfo(params))
  }

  getNewsInfo(params) {
    return this.applyMemoryCache(
      'OverviewService.getNewsInfo',
      params,
    )(() => overviewProxy.getNewsInfo(params))
  }

  getFinancialMetricsDataArea(params) {
    return this.getData(() => overviewProxy.getFinancialMetricsDataArea(params))
  }

  getKeyIndicators(params) {
    return this.applyMemoryCache(
      'OverviewService.getKeyIndicators',
      params,
    )(() => overviewProxy.getKeyIndicators(params))
  }

  getFinancialMetricsChartData(params) {
    return this.getData(() =>
      overviewProxy.getFinancialMetricsChartData(params),
    )
  }

  getFinancialMetricsComparisonChartData(params) {
    return this.getData(() =>
      overviewProxy.getFinancialMetricsComparisonChartData(params),
    )
  }

  downloadSummaryInfo(params) {
    return this.getFileDownload(() => overviewProxy.downloadSummaryInfo(params))
  }

  downloadKeyIndicators(params) {
    return this.getFileDownload(() =>
      overviewProxy.downloadKeyIndicators(params),
    )
  }

  downloadFinancialMetrics(params) {
    return this.getFileDownload(() =>
      overviewProxy.downloadFinancialMetrics(params),
    )
  }
}

export default new OverviewService()
