import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { handleComboChartData, handleTitle } from '../helper'
import { getNetPumpVolume } from './thunk'

const initialState = {
  chartData: [],
  chartLoading: true,
  title: [],
}

const slice = createSlice({
  name: 'economy/monetary/openMarket/panelOpenMarketOperations/chartBottom',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNetPumpVolume.pending, (state) => {
      state.chartLoading = true
    })
    builder.addCase(getNetPumpVolume.fulfilled, (state, action) => {
      state.chartLoading = false
      state.chartData = handleComboChartData(action.payload || [])
      state.title = handleTitle(action.payload || [])
    })
    builder.addCase(getNetPumpVolume.rejected, (state, action) => {
      state.chartLoading = action.payload.isLoading
    })
  },
})

export const getChartData = (state) => state[slice.name].chartData
export const getChartLoading = (state) => state[slice.name].chartLoading
export const getTitle = (state) => state[slice.name].title

register(slice.name, slice.reducer)
