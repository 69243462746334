import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import {
  recommendationTypeById,
  recommendationTypeColor,
} from '../consensusHistory/constants'
import { selectTableData } from '../consensusHistory/store/slice'

export const Description = ({ height }) => {
  const dataTable = useSelector(selectTableData)

  const getLatest = useMemo(() => {
    if (dataTable.length) {
      return [...dataTable].sort(
        (a, b) =>
          new Date(b.reportDateId).getTime() -
          new Date(a.reportDateId).getTime(),
      )[0]
    }
  }, [dataTable])

  return (
    <div style={{ paddingTop: 20 }}>
      <ScrollComponent autoHeight autoHeightMax={height / 3 || 0}>
        <Span style={{ fontSize: 16, fontWeight: 200 }}>
          <Translate value="corporate.peerValuation.consensus.LATEST_RECOMMEND" />
          <span
            style={{
              color:
                getLatest &&
                recommendationTypeColor[
                  recommendationTypeById[getLatest.recommendationTypeId]
                ],
              marginLeft: 4,
            }}
          >
            {getLatest ? getLatest.recommendationTypeName + '*' : ''}
          </span>
        </Span>
        <div className="mt-16 t-italic" style={{ fontWeight: 300 }}>
          <Translate value="corporate.peerValuation.consensus.DISCLAIMER" />
        </div>
      </ScrollComponent>
    </div>
  )
}
