import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { keySettingChart } from '../../../constants'
import { changeStyleSetting } from '../../../store/slice'
import SettingStyle from './SettingStyle'
import Switch from './Switch.js'
import useGetDataStyle from './useGetDataStyle'

const XAxisLegend = ({ checked, onChange }) => {
  const dispatch = useDispatch()
  const { xAxisLegend, styleSetting } = useGetDataStyle()

  const handleChangeStyleSetting = (data) => {
    dispatch(
      changeStyleSetting({
        ...styleSetting,
        [keySettingChart.X_AXIS_LEGEND]: data,
      }),
    )
  }

  return (
    <div className="mb-8 mt-8">
      <div
        className="d-flex align-center justify-content-space-between"
        style={{ marginBottom: 4 }}
      >
        <Translate
          style={{ fontWeight: 500 }}
          value="financialChart.LEGEND_FOOTER"
        />
        <Switch checked={checked} onChange={onChange} />
      </div>
      <SettingStyle
        style={xAxisLegend}
        setStyle={handleChangeStyleSetting}
        disableSetting={!checked}
        disablePosition
        fontSizeMax={16}
      />
    </div>
  )
}

export default XAxisLegend
