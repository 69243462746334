import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { changeIsYTD } from '../contribution/store/slice'
import { changeTypeCodeForYearlyChart } from '../growthYearly/store/slice'
import { LIST_RADIO, NUM_OF_PERIOD_FIRST_FETCH } from './constants'
import {
  changeIsNotGetMaxMonth,
  changePeriodNum,
  changeTypeCode,
  keepCurrentItems,
  selectLoadingTableContentCPI,
  selectTypeCode,
} from './store/slice'
import style from './style.module.css'

export const RadioGroup = ({ listCPISector, dataTable }) => {
  const dispatch = useDispatch()

  const typeCode = useSelector(selectTypeCode)
  const isContentLoading = useSelector(selectLoadingTableContentCPI)

  const addedItemsIndexes = listCPISector?.map((item) => item.colorId)

  const handleChangeRadio = (val) => {
    dispatch(changeTypeCode(val))
    dispatch(changeTypeCodeForYearlyChart(val))
    dispatch(changePeriodNum(NUM_OF_PERIOD_FIRST_FETCH))
    dispatch(changeIsYTD(false))
    if (listCPISector.length && dataTable.length) {
      dispatch(keepCurrentItems(addedItemsIndexes))
      dispatch(changeIsNotGetMaxMonth(false))
    }
  }

  return (
    <div className="d-flex">
      <Span style={{ width: '100px', color: '#6f737c', paddingRight: '10px' }}>
        <TextEllipsis text="economy.cpiAndInflation.cpiAndInflationTable.COMPARE" />
      </Span>
      <ul className={`list-check d-flex mt-0`}>
        {LIST_RADIO.map((item) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={item.value === typeCode}
                onChange={() => handleChangeRadio(item.value)}
                disabled={item.value !== typeCode && isContentLoading}
              />
              <Span
                style={{ fontSize: 12 }}
                className={
                  item.value === typeCode
                    ? style.active
                    : isContentLoading
                    ? style.disabled
                    : ''
                }
              >
                <Translate value={item.label} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}
