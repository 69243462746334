import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DeepTransactionService from '../../../../../../core/services/market/equityTrading/DeepTransactionService'

export const getDeepTransactionSummary = createAsyncThunk(
  'market/equityTrading/deepTransaction/summary/GET_DEEP_TRANSACTION_SUMMARY',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DeepTransactionService.getDeepTransactionSummary(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
