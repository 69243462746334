import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import { dashboardTypes, screenTypes, userSettingKeys } from '../constants'
import {
  changeDashboard,
  changeIsFirstLoadDashboard,
  selectChartOfTheDay,
  selectDashboardLoading,
  selectIsFirstLoadDashboard,
  selectListDashboard,
  selectUserSettings,
  selectUserSettingsLoading,
} from '../store/slice'
import { getChartUserSettings, postChartUserSettings } from '../store/thunk'

export const useGetUserSettings = () => {
  const dispatch = useDispatch()

  const userSettings = useSelector(selectUserSettings)
  const listDashboard = useSelector(selectListDashboard)
  const chartOfTheDay = useSelector(selectChartOfTheDay)
  const userSettingsLoading = useSelector(selectUserSettingsLoading)
  const dashboardLoading = useSelector(selectDashboardLoading)
  const isFirstLoadDashboard = useSelector(selectIsFirstLoadDashboard)

  // Data
  const favoriteColors = [
    ...(userSettings[userSettingKeys.FAVORITE_COLORS] || []),
  ]
  const listDashboardById = keyBy(listDashboard, 'id')
  const defaultDashboard =
    listDashboardById[userSettings[userSettingKeys.DEFAULT_DASHBOARD]] || {}
  const defaultChartSettings =
    userSettings[userSettingKeys.DEFAULT_CHART_SETTINGS] || null

  // Actions
  const getUserSettings = () => {
    dispatch(getChartUserSettings())
  }

  const updateUserSettings = (params) => {
    if (typeof params === 'object' && !Array.isArray(params)) {
      dispatch(postChartUserSettings({ Settings: JSON.stringify(params) }))
    }
  }

  const addFavoriteColor = (color) => {
    if (!favoriteColors.includes(color)) {
      updateUserSettings({
        ...userSettings,
        [userSettingKeys.FAVORITE_COLORS]: [...favoriteColors, color],
      })
    }
  }

  const removeFavoriteColor = (color) => {
    if (favoriteColors.includes(color)) {
      updateUserSettings({
        ...userSettings,
        [userSettingKeys.FAVORITE_COLORS]: favoriteColors.filter(
          (val) => val !== color,
        ),
      })
    }
  }

  const addDefaultDashboard = (id) => {
    updateUserSettings({
      ...userSettings,
      [userSettingKeys.DEFAULT_DASHBOARD]: id,
    })
  }

  const removeDefaultDashboard = () => {
    const newParams = { ...userSettings }
    delete newParams[userSettingKeys.DEFAULT_DASHBOARD]
    updateUserSettings(newParams)
  }

  const addDefaultChartSettings = (settings) => {
    updateUserSettings({
      ...userSettings,
      [userSettingKeys.DEFAULT_CHART_SETTINGS]: settings,
    })
  }

  const removeDefaultChartSettings = () => {
    const newParams = { ...userSettings }
    delete newParams[userSettingKeys.DEFAULT_CHART_SETTINGS]
    updateUserSettings(newParams)
  }

  useEffect(() => {
    if (
      !userSettingsLoading &&
      !dashboardLoading &&
      Object.keys(chartOfTheDay)
    ) {
      if (!isFirstLoadDashboard) {
        const dashboard = Object.keys(defaultDashboard).length
          ? {
              screenType: screenTypes.DASHBOARD,
              dashboardType: dashboardTypes.MY_DASHBOARD,
              data: defaultDashboard,
            }
          : {
              screenType: screenTypes.DASHBOARD,
              dashboardType: dashboardTypes.CHART_OF_THE_DAY,
              data: chartOfTheDay,
            }

        dispatch(changeIsFirstLoadDashboard(true))
        dispatch(changeDashboard(dashboard))
      }
    }
  }, [userSettingsLoading, dashboardLoading, defaultDashboard])

  return {
    defaultDashboard,
    favoriteColors,
    defaultChartSettings,
    userSettingsLoading,
    getUserSettings,
    updateUserSettings,
    addFavoriteColor,
    removeFavoriteColor,
    addDefaultDashboard,
    removeDefaultDashboard,
    addDefaultChartSettings,
    removeDefaultChartSettings,
  }
}
