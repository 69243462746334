import { ImportExportChart } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelImportExportChart = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const nameScreen = UseI18n('economy.importExport.importExport.IMPORT_EXPORT')
  const chartName = UseI18n(
    'economy.importExport.importExport.overview.EXPORT_IMPORT_AND_BALANCE',
  )
  const tabName = UseI18n('economy.importExport.importExport.OVERVIEW')

  return (
    <Panel
      title="economy.importExport.importExport.overview.EXPORT_IMPORT_AND_BALANCE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(
        'economy.importExport.importExport.overview.EXPORT_IMPORT_AND_BALANCE',
      )}
      downloadJpgParams={{
        domId: 'importExportChart',
        nameScreen: nameScreen,
        chartName: chartName,
        tabName: tabName,
      }}
    >
      <ImportExportChart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
