import priceStatisticProxy from '../../../proxies/market/equityTrading/PriceStatisticProxy'
import { ServiceBase } from '../../ServiceBase'

class PriceStatisticService extends ServiceBase {
  getOverview(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getOverview',
      params,
    )(() => priceStatisticProxy.getOverview(params))
  }

  downloadOverview(params) {
    return this.getFileDownload(() =>
      priceStatisticProxy.downloadOverview(params),
    )
  }

  getOrderStatistic(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getOrderStatistic',
      params,
    )(() => priceStatisticProxy.getOrderStatistic(params))
  }

  downloadOrderStatistic(params) {
    return this.getFileDownload(() =>
      priceStatisticProxy.downloadOrderStatistic(params),
    )
  }

  getForeign(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getForeign',
      params,
    )(() => priceStatisticProxy.getForeign(params))
  }

  downloadForeign(params) {
    return this.getFileDownload(() =>
      priceStatisticProxy.downloadForeign(params),
    )
  }

  getByInvestor(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getByInvestor',
      params,
    )(() => priceStatisticProxy.getByInvestor(params))
  }

  downloadByInvestor(params) {
    return this.getFileDownload(() =>
      priceStatisticProxy.downloadByInvestor(params),
    )
  }

  getProprietary(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getProprietary',
      params,
    )(() => priceStatisticProxy.getProprietary(params))
  }

  downloadProprietary(params) {
    return this.getFileDownload(() =>
      priceStatisticProxy.downloadProprietary(params),
    )
  }

  getValueByInvestorChart(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getValueByInvestorChart',
      params,
    )(() => priceStatisticProxy.getValueByInvestorChart(params))
  }

  getNetValueIndividualChart(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getNetValueIndividualChart',
      params,
    )(() => priceStatisticProxy.getNetValueIndividualChart(params))
  }

  getNetValueForeignChart(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getNetValueForeignChart',
      params,
    )(() => priceStatisticProxy.getNetValueForeignChart(params))
  }

  getNetValueInstitutionChart(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getNetValueInstitutionChart',
      params,
    )(() => priceStatisticProxy.getNetValueInstitutionChart(params))
  }

  getNetValueProprietaryChart(params) {
    return this.applyMemoryCache(
      'PriceStatisticService.getNetValueProprietaryChart',
      params,
    )(() => priceStatisticProxy.getNetValueProprietaryChart(params))
  }
}

export default new PriceStatisticService()
