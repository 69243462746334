import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { languageObjs } from '../../../../configs/Language'
import ChartingService from '../../../../core/services/common/ChartingService'
import OwnershipService from '../../../../core/services/corporate/OwnershipService'
import { getLanguage } from '../../../utils/Language'
import { formatVal, valDivBillion, valDivThousand } from '../../../utils/Value'
import { STOCK_EVENT_TYPE } from '../constants'
import { SYMBOL_TYPES } from './constants'
import { getSymbolType } from './helper'

const mapResolution = {
  1: 'EachMinute',
  5: 'EachFiveMinutes',
  15: 'EachFifteenMinutes',
  30: 'EachThirteenMinutes',
  60: 'EachOneHour',
  D: 'Daily',
  W: 'Weekly',
  M: 'Monthly',
  '3M': 'Quarterly',
  '12M': 'Yearly',
  '1D': 'Daily',
  '1W': 'Weekly',
  '1M': 'Monthly',
}

const getLang = () => getLanguage()

const getIssueMethodKey = () => {
  const mapLang = {
    en: 'en_ShareIssueMethodName',
    vi: 'shareIssueMethodName',
  }
  return mapLang[getLang()]
}

const formatTime = (time) => {
  const date = new Date(time)
  const day = date.getDay()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

const formatPercent = (percent) => {
  return percent % 1 === 0 ? percent : formatVal(percent)
}

const timeScaleMarkTypes = {
  Earning: {
    color: '#54e52b',
    label: 'E',
    tooltips: [
      {
        i18nKey: 'REVENUE',
        getValue: (item) => formatVal(valDivBillion(item.revenue)),
      },
      {
        i18nKey: 'PROFIT',
        getValue: (item) => formatVal(valDivBillion(item.profit)),
      },
      {
        i18nKey: 'PERIOD_ENDING',
        getValue: (item) => `Q${item.lengthReport} - ${item.yearReport}`,
      },
    ],
  },
  Dividend: {
    color: 'yellow',
    label: 'D',
    tooltips: [
      {
        i18nKey: 'DIVIDENDS',
        getValue: (item) => formatVal(item.valuePerShare, 0),
      },
      {
        i18nKey: 'EX_RATIO',
        getValue: (item) => formatPercent(item.exerciseRate * 100),
      },
      {
        i18nKey: 'EXRIGHT_DATE',
        getValue: (item) => formatTime(item.exRightDateId),
      },
    ],
  },
  ShareIssuance: {
    color: '#c727ff',
    label: 'I',
    tooltips: [
      {
        i18nKey: 'ISSUE_METHOD',
        getValue: (item) => item[getIssueMethodKey()],
      },
      {
        i18nKey: 'VOLUME',
        getValue: (item) => formatVal(item.issueVolumn, 0),
      },
      {
        i18nKey: 'EX_RATIO',
        getValue: (item) => formatPercent(item.exerciseRatio * 100),
      },
      {
        i18nKey: 'EXRIGHT_DATE',
        getValue: (item) => formatTime(item.exRightDateId),
      },
    ],
  },
}

const markTypes = {
  InternalTransaction: {
    S: {
      color: '#EF5351',
      label: 'S',
      getValue: (item) => formatVal(Math.abs(item.shareAcquire), 0),
    },
    B: {
      color: '#27A69A',
      label: 'B',
      getValue: (item) => formatVal(Math.abs(item.shareAcquire), 0),
    },
  },
}

const getTimescaleTooltip = (eventItem, item) =>
  eventItem.tooltips.map(
    (tooltip) =>
      I18n.t(`common.tradingView.${tooltip.i18nKey}`) +
      ': ' +
      tooltip.getValue(item),
  )

const getUnixTime = (publicDate) => {
  return Math.round(new Date(publicDate).getTime() / 1000)
}

const formatTimescaleMarks = (result, item) => {
  const obj = {
    time: getUnixTime(item.publicDate),
    id: item.id,
    labelFontColor: '#ffffff',
    minSize: 10,
  }
  if (!timeScaleMarkTypes[item.type]) {
    return result
  }

  return result.concat({
    ...obj,
    color: timeScaleMarkTypes[item.type].color,
    label: timeScaleMarkTypes[item.type].label,
    tooltip: [''].concat(
      getTimescaleTooltip(timeScaleMarkTypes[item.type], item),
    ),
  })
}

const getMarkTooltip = (item, mark, keyName, status) => {
  return `
    <div style="margin-bottom: 5px">
      <strong>${item[keyName]}</strong>
    </div>
    <div>
      <strong>
        ${status}: ${mark.getValue(item)}
      </strong>
    </div>
  `
}

const formatMarks = (result, item) => {
  const obj = {
    time: getUnixTime(item.publicDate),
    id: item.id,
    minSize: 20,
  }
  if (!markTypes[item.type] || !markTypes[item.type][item.actionTypeCode]) {
    return result
  }
  const mark = markTypes[item.type][item.actionTypeCode]
  const MAP_KEY_NAME = {
    Corporate: {
      [languageObjs.en]: 'en_OrganizationShortName',
      [languageObjs.vi]: 'organizationShortName',
    },
    Individual: {
      [languageObjs.en]: 'en_FullName',
      [languageObjs.vi]: 'fullName',
    },
  }
  return result.concat({
    ...obj,
    labelFontColor: mark.color,
    color: {
      border: mark.color,
      background: 'transparent',
    },
    label: mark.label,
    text: getMarkTooltip(
      item,
      mark,
      MAP_KEY_NAME[item.transactionType][getLang()],
      getLang() === languageObjs.en
        ? item.en_ActiveStatusName
        : item.activeStatusName,
    ),
  })
}

const formatMarksForDirectorDeal = (result, item, currentIndex) => {
  const obj = {
    time: getUnixTime(item.endDateId),
    id: item.endDateId + currentIndex,
    minSize: 20,
  }
  if (!markTypes.InternalTransaction[item.actionTypeCode]) {
    return result
  }

  const mark = markTypes.InternalTransaction[item.actionTypeCode]
  return result.concat({
    ...obj,
    labelFontColor: mark.color,
    color: {
      border: mark.color,
      background: 'transparent',
    },
    label: mark.label,
    text: getMarkTooltip(item, mark, 'fullName', item.activeStatusName),
  })
}

const getEventParam = (symbolInfo, from, to) => ({
  Id: symbolInfo.id,
  From: new Date(from * 1000).toISOString(),
  To: new Date(to * 1000).toISOString(),
})

export const getPrice = (symbolInfo, value) => {
  if (symbolInfo.type === SYMBOL_TYPES.STOCK) {
    return valDivThousand(value)
  }

  return value
}

const getBarTime = (time, resolution) => {
  if (resolution.includes('D')) {
    const date = new Date(time)

    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    )
  }

  return new Date(time).getTime()
}

let prevFrom = null

const getBarParams = (
  symbolInfo,
  resolution,
  from,
  to,
  PriceType,
  Type,
  Id,
) => {
  const mapTypeToParam = {
    [SYMBOL_TYPES.STOCK]: 'Stock',
    [SYMBOL_TYPES.INDICES]: 'Index',
    [SYMBOL_TYPES.FUTURES]: 'Derivative',
    [SYMBOL_TYPES.ECONOMY]: 'Economy',
    [SYMBOL_TYPES.COVERED_WARRANT]: 'CoveredWarrant',
    [SYMBOL_TYPES.COMMODITY]: 'Commodity',
  }

  const isByMinute = [
    mapResolution[1],
    mapResolution[5],
    mapResolution[15],
    mapResolution[30],
  ].includes(mapResolution[resolution])
  const threeMonthsFromNow = moment().subtract(3, 'month').toDate()
  if (isByMinute && threeMonthsFromNow.getTime() > from * 1000) {
    return null
  }

  const isByHour = [mapResolution[60]].includes(mapResolution[resolution])
  const oneYearFromNow = moment().subtract(1, 'year').toDate()
  if (isByHour && oneYearFromNow.getTime() > from * 1000) {
    return null
  }

  const oldestDate = moment().set('year', 2000).startOf('year').toDate()
  if (oldestDate.getTime() > from * 1000) {
    return null
  }

  let From = isByMinute
    ? moment(new Date(to * 1000))
        .subtract(1, 'day')
        .toDate()
    : isByHour
    ? moment(new Date(to * 1000))
        .subtract(1, 'year')
        .toDate()
    : new Date(from * 1000)
  let To = new Date(to * 1000)

  if (isByMinute && prevFrom && prevFrom <= To.getTime()) {
    From = moment(new Date(prevFrom)).subtract(1, 'day').toDate()
    To = new Date(prevFrom)
    prevFrom = From.getTime()
  }

  if (!isByMinute) {
    prevFrom = null
  } else {
    prevFrom = From.getTime()
  }

  return {
    Id: Id ? Id : symbolInfo.id,
    Frequency: mapResolution[resolution],
    From: From.toISOString(),
    To: To.toISOString(),
    Type: Type ? Type : mapTypeToParam[symbolInfo.type],
    PriceType: PriceType,
  }
}

const getIndicatorParams = async (symbolInfo, resolution, from, to) => {
  const [indicatorId, symbolName] = symbolInfo.name.split(':')
  const { id } = await getSymbolType(symbolName)

  return {
    Id: id,
    IndicatorId: indicatorId,
    Type: SYMBOL_TYPES.MORE_INDICATOR,
    Frequency: mapResolution[resolution],
    From: new Date(from * 1000).toISOString(),
    To: new Date(to * 1000).toISOString(),
  }
}

export const isIndicator = (symbolInfo) => {
  return symbolInfo.name.includes('INDICATOR')
}

const formatBar =
  (resolution, isDivThousand = false) =>
  (item) => {
    const lowestPrice = +item.lowestPrice
    const highestPrice = +item.highestPrice
    const openPrice = +item.openPrice
    const closePrice = +item.closePrice
    const totalMatchVolume = +item.totalMatchVolume
    return {
      time: getBarTime(item.tradingDate, resolution),
      low: isDivThousand ? valDivThousand(lowestPrice) : lowestPrice,
      high: isDivThousand ? valDivThousand(highestPrice) : highestPrice,
      open: isDivThousand ? valDivThousand(openPrice) : openPrice,
      close: isDivThousand ? valDivThousand(closePrice) : closePrice,
      volume: totalMatchVolume,
    }
  }

const isValidIndicatorInterval = (resolution) => {
  return ['D', '1D'].includes(resolution)
}

const HistoryProvider = {
  getBars: async (symbolInfo, resolution, from, to, _, PriceType, Type, Id) => {
    if (from < 0 && to < 0) {
      return null
    }

    if (isIndicator(symbolInfo)) {
      if (!isValidIndicatorInterval(resolution)) {
        return null
      }
      const data = await ChartingService.getMoreIndicatorData(
        await getIndicatorParams(symbolInfo, resolution, from, to),
      ).then(({ data }) => data)

      if (!data) {
        return null
      }

      return data.map(formatBar(resolution))
    }

    const barParams = getBarParams(
      symbolInfo,
      resolution,
      from,
      to,
      PriceType,
      Type,
      Id,
    )

    if (!barParams) {
      return null
    }

    const data = await ChartingService.getTradingViewData(barParams).then(
      ({ data }) => data,
    )

    if (!data) {
      return null
    }

    return data.map(
      formatBar(
        resolution,
        [SYMBOL_TYPES.STOCK, SYMBOL_TYPES.COVERED_WARRANT].includes(
          symbolInfo.type,
        ),
      ),
    )
  },

  getTimescaleMarks: async (symbolInfo, from, to, stockEventType) => {
    if (stockEventType === STOCK_EVENT_TYPE.DIRECTOR_DEAL) {
      return []
    }
    const data = await ChartingService.getStockEvents(
      getEventParam(symbolInfo, from, to),
    ).then(({ data }) => data)

    if (!data) {
      return []
    }

    return data.reduce(formatTimescaleMarks, [])
  },

  getMarks: async (symbolInfo, from, to, stockEventType) => {
    const {
      Id: id,
      From: startDate,
      To: endDate,
    } = getEventParam(symbolInfo, from, to, stockEventType)
    const { data } =
      stockEventType === STOCK_EVENT_TYPE.ALL
        ? await ChartingService.getStockEvents({
            Id: id,
            From: startDate,
            To: endDate,
          })
        : await OwnershipService.getDirectorDeals({
            OrganizationId: id,
            StartDate: startDate,
            EndDate: endDate,
          })

    if (!data) {
      return []
    }

    return data.reduce(
      stockEventType === STOCK_EVENT_TYPE.ALL
        ? formatMarks
        : formatMarksForDirectorDeal,
      [],
    )
  },
}

export default HistoryProvider
