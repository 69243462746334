import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OpenMarketService from '../../../../../../core/services/economy/OpenMarketService'
import { handleExport } from '../../../../../utils/Export'
import { handleLimitDate, handleTableData } from '../helper'

export const getBiddingResultsOnTheOpenMarket = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarketAuction/getBiddingResultsOnTheOpenMarket',
  async (params, { rejectWithValue, getState }) => {
    try {
      const res = await OpenMarketService.getBiddingResultsOnTheOpenMarket(
        params,
      )
      const states = getState()
      const timeZone = states.setting.offSetTimeZone
      const locale = states.i18n.locale

      return handleTableData(res.data, timeZone, locale)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxDate = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarketAuction/getMaxDate',
  async (params, { rejectWithValue, getState }) => {
    try {
      const res = await OpenMarketService.getMaxDate(params)
      const states = getState()
      const timeZone = states.setting.offSetTimeZone

      return handleLimitDate(res.data, timeZone)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadBiddingResults = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarketAuction/downloadBiddingResults',
  async (data) => {
    const res = await OpenMarketService.downloadBiddingResults(data)
    handleExport(res.data, res.filename)
  },
)
