export const route = {
  market: {
    LABEL: 'Thị trường',
    marketInDepth: {
      LABEL: 'Thị trường chuyên sâu',
      indices: {
        LABEL: 'Chỉ số',
      },
      foreign: {
        LABEL: 'Giao dịch khối ngoại',
      },
      proprietary: {
        LABEL: 'Giao dịch tự doanh',
      },
      directorDeal: {
        LABEL: 'Giao dịch nội bộ',
      },
      valuation: {
        LABEL: 'Định giá',
      },
      indexStatistics: {
        LABEL: 'Thống kê thị trường',
      },
    },
    sectorStatistics: {
      LABEL: 'Ngành chuyên sâu',
    },
    newsReport: {
      LABEL: 'Tin tức & Báo cáo',
    },
    events: {
      LABEL: 'Sự kiện',
    },
    heatmap: {
      LABEL: 'Bản đồ nhiệt',
    },
    topMover: {
      LABEL: 'Top cổ phiếu',
    },
    equityTrading: {
      LABEL: 'Giao dịch cổ phiếu',
      deepTransaction: {
        LABEL: 'Cổ phiếu trực tuyến',
      },
      priceStatistics: {
        LABEL: 'Thống kê giá',
      },
    },
    derivative: {
      LABEL: 'Phái sinh',
      OVERVIEW: 'Tổng quan',
      DERIVATIVE_STATISTICS: 'Thống kê phái sinh',
    },
    coveredWarrant: {
      LABEL: 'Chứng quyền',
      overview: {
        LABEL: 'Chi tiết chứng quyền',
      },
      cwStatistics: {
        LABEL: 'Thống kê chứng quyền',
      },
    },
    marginLoans: {
      LABEL: 'Nợ vay ký quỹ',
    },
    charting: {
      LABEL: 'Biểu đồ PT kĩ thuật',
    },
  },
  corporate: {
    LABEL: 'Doanh nghiệp',
    overview: {
      LABEL: 'Tổng quan',
    },
    newsEvents: {
      LABEL: 'Tin tức & Sự kiện',
      news: {
        LABEL: 'Tin tức',
      },
      events: {
        LABEL: 'Sự kiện',
      },
      filing: {
        LABEL: 'Tài liệu cổ đông',
      },
    },
    tradingData: {
      LABEL: 'Thông tin giao dịch',
      deepTransaction: {
        LABEL: 'Cổ phiếu trực tuyến',
      },
      priceStatistics: {
        LABEL: 'Thống kê giá',
      },
    },
    ownership: {
      LABEL: 'Cơ cấu sở hữu',
    },
    businessModel: {
      LABEL: 'Mô hình kinh doanh',
      subsidiaryAssociates: {
        LABEL: 'Công ty con & Công ty liên kết',
      },
      segmentalAnalysis: {
        LABEL: 'Phân tích kinh doanh',
      },
      penetrationAnalysis: {
        LABEL: 'Phân tích thị phần',
      },
      costOfOperation: {
        LABEL: 'Chi phí hoạt động',
      },
    },
    financialAnalysis: {
      LABEL: 'Phân tích tài chính',
      debtCreditRisk: {
        LABEL: 'Nợ & Rủi ro tín dụng',
      },
      earningQuality: {
        LABEL: 'Đánh giá lợi nhuận',
      },
    },
    financialData: {
      LABEL: 'Dữ liệu tài chính',
      financialRatios: {
        LABEL: 'Chỉ số tài chính',
      },
      financialStatement: {
        LABEL: 'Báo cáo tài chính',
      },
      dividend: {
        LABEL: 'Cổ tức',
      },
    },
    peerValuation: {
      LABEL: 'So sánh & Định giá',
      overview: {
        LABEL: 'Tổng Quan',
      },
      consensus: {
        LABEL: 'Khuyến nghị từ CTCK',
      },
    },
    companySnapshot: {
      LABEL: 'Báo cáo doanh nghiệp',
    },
  },
  sector: {
    LABEL: 'Ngành',
    sectorStatistics: {
      LABEL: 'Thống kê ngành',
      overview: {
        LABEL: 'Tổng quan ngành',
      },
      ratios: {
        LABEL: 'Chi tiết',
      },
    },
    sectorConstituents: {
      LABEL: 'Ngành chi tiết',
      overview: {
        LABEL: 'Tổng quan ngành',
      },
      newsReport: {
        LABEL: 'Tin tức & Báo cáo',
      },
      events: {
        LABEL: 'Sự kiện',
      },
      financialAnalysis: {
        LABEL: 'Phân tích tài chính',
      },
      segmentation: {
        LABEL: 'Biểu đồ phân tán ngành',
      },
      ranking: {
        LABEL: 'Xếp hạng cổ phiếu',
      },
      ratio: {
        LABEL: 'Chỉ số ngành',
      },
      valuation: {
        LABEL: 'Định giá',
      },
    },
    sectorSpecific: {
      LABEL: 'Ngành chuyên sâu',
      fishery: {
        LABEL: 'Thủy sản',
      },
      steel: {
        LABEL: 'Thép',
      },
    },
  },
  economy: {
    LABEL: 'Kinh tế vĩ mô',
    newsAndReport: {
      LABEL: 'Tin tức & Báo cáo',
    },
    event: {
      LABEL: 'Sự kiện',
    },
    gdp: {
      LABEL: 'GDP',
      totalGDP: {
        LABEL: 'Tổng GDP',
      },
      gdpByProvince: {
        LABEL: 'GDP theo tỉnh thành',
      },
      gdpBySpendingMethod: {
        LABEL: 'GDP theo phương pháp chi tiêu',
      },
    },
    cpiAndInflation: {
      LABEL: 'CPI & Lạm phát',
    },
    importExport: {
      LABEL: 'Xuất nhập khẩu',
      overview: {
        LABEL: 'Tổng quan',
      },
      exportImportByCommodities: {
        LABEL: 'Xuất nhập khẩu theo mặt hàng',
      },
      exportImportByCountries: {
        LABEL: 'Xuất nhập khẩu theo quốc gia',
      },
    },
    fdi: {
      LABEL: 'FDI',
      overview: {
        LABEL: 'Tổng quan',
      },
      fdiByCountries: {
        LABEL: 'FDI theo đối tác',
      },
      fdiByIndustries: {
        LABEL: 'FDI theo ngành',
      },
      fdiByProvinces: {
        LABEL: 'FDI theo tỉnh thành',
      },
    },
    paymentBalance: {
      LABEL: 'Cán cân thanh toán',
    },
    monetary: {
      LABEL: 'Chính sách tiền tệ',
      openMarket: {
        LABEL: 'Thị trường mở',
      },
      'moneySupplies-Credit': {
        LABEL: 'Cung tiền - Tín dụng',
      },
      exchangeRate: {
        LABEL: 'Tỷ giá',
      },
      interestRate: {
        LABEL: 'Lãi suất',
        borrowingInterest: {
          LABEL: 'Borrowing Interest',
        },
        lendingRate: {
          LABEL: 'Lending Rate',
        },
      },
    },
    fiscal: {
      LABEL: 'Tài khóa',
      stateBudget: {
        LABEL: 'Ngân sách Nhà nước',
      },
      publicDebt: {
        LABEL: 'Nợ công',
      },
    },
    productionAndConsumption: {
      LABEL: 'Sản xuất & Tiêu thụ',
      production: {
        LABEL: 'Sản xuất',
      },
      consumption: {
        LABEL: 'Tiêu thụ',
      },
    },
  },
  tool: {
    LABEL: 'Công cụ',
    smartScreening: {
      LABEL: 'Sàng lọc thông minh',
    },
    technicalChart: {
      LABEL: 'Biểu đồ PT kĩ thuật',
    },
    dataExplorer: {
      LABEL: 'Tìm kiếm dữ liệu',
      corporate: {
        LABEL: 'Dữ liệu doanh nghiệp',
      },
      indicesSector: {
        LABEL: 'Dữ liệu chỉ số & ngành',
      },
      tradingData: {
        LABEL: 'Dữ liệu giao dịch',
      },
      economy: {
        LABEL: 'Dữ liệu vĩ mô',
      },
    },
    scatterPlot: {
      LABEL: 'Biểu đồ phân tán',
    },
  },
  search: {
    LABEL: 'Tìm kiếm nâng cao',
  },
  bond: {
    LABEL: 'Trái phiếu',
    governmentBond: {
      LABEL: 'TP Chính Phủ',
    },
    corporateBond: {
      LABEL: 'TP Doanh nghiệp',
      primaryMarket: {
        LABEL: 'Thị trường sơ cấp',
      },
      secondaryMarket: {
        LABEL: 'Thị trường thứ cấp',
      },
      issuers: {
        LABEL: 'Tổ chức phát hành',
      },
      valuation: {
        LABEL: 'Định giá',
      },
      bondSecurity: {
        LABEL: 'Thông tin trái phiếu',
      },
    },
    bondBuyback: {
      LABEL: 'Giao dịch mua bán lại',
    },
    bondScreening: {
      LABEL: 'Bộ lọc trái phiếu',
    },
    portfolio: {
      LABEL: 'Danh mục',
    },
    priceBoard: {
      LABEL: 'Bảng giá',
    },
    interconnectionMap: {
      LABEL: 'Sơ đồ quan hệ',
    },
  },
  newsReport: {
    LABEL: 'Bản tin ngày',
    dailyReport: {
      LABEL: 'Báo cáo hàng ngày',
    },
  },
  fund: {
    LABEL: 'Quỹ',
    fundCenter: {
      LABEL: 'Toàn cảnh quỹ',
      overview: {
        LABEL: 'Tổng quan',
      },
      statistic: {
        LABEL: 'Thống kê',
      },
      holding: {
        LABEL: 'Sở hữu',
      },
    },
    fundProfile: {
      LABEL: 'Hồ sơ quỹ',
      profile: {
        LABEL: 'Hồ sơ quỹ',
      },
      portfolio: {
        LABEL: 'Danh mục',
      },
      navData: {
        LABEL: 'Dữ liệu NAV',
      },
      financialReport: {
        LABEL: 'Báo cáo tài chính',
      },
      periodicalReport: {
        LABEL: 'Báo cáo hoạt động',
      },
    },
  },
  financialChart: {
    LABEL: 'Biểu đồ PT tài chính',
    financialChart: {
      LABEL: 'Biểu đồ PT tài chính',
    },
    scatterPlot: {
      LABEL: 'Biểu đồ phân tán',
    },
  },
}
