const FONT_SIZE_RATIO = 4
const MIN_FONT_SIZE = 8

const Label = ({ x, y, width, value, isShowLabelOutside }) => {
  const isFontSizeMin = width / FONT_SIZE_RATIO <= MIN_FONT_SIZE
  const fontSize = isFontSizeMin ? MIN_FONT_SIZE : width / FONT_SIZE_RATIO
  const containerWidth = isFontSizeMin ? MIN_FONT_SIZE * FONT_SIZE_RATIO : width
  const xPosition = x + width / 2 - 100
  const yPosition = isFontSizeMin ? y - 12 : y

  const getTextStyle = () => {
    let styles = {
      width: 200,
      height: containerWidth,
      textAlign: 'center',
      fontSize,
    }

    if (!isFontSizeMin) {
      styles = {
        ...styles,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
      }
    }

    return styles
  }

  if (!isShowLabelOutside && isFontSizeMin) {
    return null
  }

  return (
    <g style={{ pointerEvents: 'none' }}>
      <foreignObject
        x={xPosition}
        y={yPosition}
        width={200}
        height={containerWidth}
      >
        <div style={getTextStyle()}>
          <span>{value}</span>
        </div>
      </foreignObject>
    </g>
  )
}

export default Label
