import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import FinancialStatementTable from '.'
import { Panel } from '../../../../common/panel'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { FORMAT_DATA_BY_UNIT } from '../constants'
import { selectFilters } from '../store/slice'
import { downloadFinancialReportExcel } from '../store/thunk'

export const PanelTable = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const downloadCsv = () => {
    const params = {
      OrganizationId: fundInfo.organizationId,
      TimeFilter: filters.viewBy,
      NumberOfPeriod: filters.numberOfPeriod,
      LatestYear: filters.latestYear,
      CurrencyCode: filters.currency,
      Divisor: FORMAT_DATA_BY_UNIT[filters.unit],
    }

    return downloadFinancialReportExcel(params)
  }

  return (
    <Panel
      title="fund.fundProfile.report.FINANCIAL_STATEMENT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <FinancialStatementTable />
    </Panel>
  )
}

PanelTable.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
