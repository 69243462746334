import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { Layout } from '../../common/layout'
import { selectEditDashboardLoading } from '../../store/slice'
import Menu from '../common/Menu'
import { resetDefault, selectAddEditChartLoading } from '../store/slice'
import withWrapper from '../withWrapper'
import { Right } from './Right'
import { SettingCreateChartData } from './settingCreateChartData'

const SettingCreateChart = ({ isEditChart }) => {
  const dispatch = useDispatch()

  const addEditChartLoading = useSelector(selectAddEditChartLoading)
  const editDashboardLoading = useSelector(selectEditDashboardLoading)

  useEffect(() => {
    return () => dispatch(resetDefault())
  }, [])

  return (
    <Layout>
      <div className="d-flex h-100 position-relative">
        <Menu isEditChart={isEditChart} />
        <div className="flex-1">
          <Right isEditChart={isEditChart} />
        </div>
        <SettingCreateChartData isEditChart={isEditChart} />
        {(addEditChartLoading || editDashboardLoading) && (
          <Loading
            style={{
              pointerEvents: 'auto',
              opacity: 0.5,
              backgroundColor: '#000000',
            }}
          />
        )}
      </div>
    </Layout>
  )
}

SettingCreateChart.propTypes = {
  isEditChart: PropTypes.bool,
}

SettingCreateChart.defaultProps = {
  isEditChart: false,
}

export default withWrapper(SettingCreateChart)
