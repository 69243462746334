import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import { EMPTY_ROW_ID } from './config'
import style from './index.module.css'

const OtherCellColumn = ({ val, rowId, props, isBorderLeft }) => {
  const isEmptyRow = EMPTY_ROW_ID.includes(rowId)

  return (
    <td
      {...props}
      className={style.borderDefault}
      style={{
        borderRight: isBorderLeft && '1px solid #546373',
        textAlign: 'right',
      }}
    >
      <Span>{isEmptyRow ? '' : formatVal(val)}</Span>
    </td>
  )
}

OtherCellColumn.propTypes = {
  props: PropTypes.object,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isBorderLeft: PropTypes.bool,
}

export default OtherCellColumn
