import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectStockById } from '../store/slice'

const getValue = (stockById, idTicker, colId) => {
  if (typeof colId !== 'object') {
    let value = stockById[idTicker]?.[colId]
    if (value === undefined) {
      value = stockById[String(idTicker)?.split('_')[0]]?.[colId]
    }
    return value
  } else {
    let value
    const mapColId = (idTicker, colId) => {
      for (let i = 0; i < colId.length; i++) {
        if (stockById[idTicker]?.[colId[i]] !== undefined) {
          value = stockById[idTicker]?.[colId[i]]
          break
        }
      }
    }
    mapColId(idTicker, colId)
    if (value === undefined) {
      const newIdTicker = String(idTicker)?.split('_')[0]
      mapColId(newIdTicker, colId)
    }
    return value
  }
}

const CellInfo = ({ idTicker, colId }) => {
  const stockById = useSelector(selectStockById)

  return (
    <div>
      <TextEllipsis
        isI18n={false}
        text={getValue(stockById, idTicker, colId)}
      />
    </div>
  )
}

export default CellInfo
