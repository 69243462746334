import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getMajorShareholder } from './thunk'

const initialState = {
  holderById: {},
  ids: [],
  loading: false,
  showTable: true,
  initialIds: [],
}

export const slice = createSlice({
  name: 'corporate/ownership/majorShareholder',
  initialState,
  reducers: {
    setHolderById: (state, action) => {
      state.holderById = action.payload
    },
    setIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeIds: (state, action) => {
      state.ids = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.holderById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMajorShareholder.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getMajorShareholder.fulfilled, (state, action) => {
      state.loading = false
      state.showTable = true

      // Because ownerPersonId from api can be null => need to format to unique item
      const formatData =
        action.payload?.map((item, index) => {
          return { ...item, uid: item.ownerPersonId || index }
        }) || []
      state.initialIds = state.ids = formatData.map((item) => item.uid)
      state.holderById = keyBy(formatData, 'uid')
    })
    builder.addCase(getMajorShareholder.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectHolderIds = (state) => state[slice.name].ids
export const selectHolderById = (state) => state[slice.name].holderById
export const selectLoading = (state) => state[slice.name].loading
export const selectHolderValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].holderById[id], attr)
}
export const { setHolderById, setIds, changeIds, sort } = slice.actions

register(slice.name, slice.reducer)
