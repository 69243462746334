import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize } from '../../utils/FontSize'

export const TextArea = forwardRef(({ style, ...others }, ref) => {
  return (
    <textarea
      ref={ref}
      style={{ ...style, fontSize: getFontSize(style.fontSize) }}
      {...others}
    />
  )
})

TextArea.propTypes = {
  style: PropTypes.object,
}

TextArea.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
