import { createSlice } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import { maxSheet } from '../../../../common/dataExplorer/Sheet'
import { typeWorkSpace } from '../../../../common/dataExplorer/constant'
import {
  funcSortAsc,
  funcSortDesc,
} from '../../../../common/dataExplorer/helper'
import { SORT_TYPES } from '../../../../common/dataExplorer/table/constant'
import { keyBy } from '../../../../utils/Common'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { register } from '../../../../utils/ReducerRegistry'
import { changeValueWithDot } from '../../../../utils/Value'
import { maxIndicator } from '../constant'
import {
  getIndicatorThunk,
  getResultThunk,
  getShareTemplateThunk,
  getTemplateThunk,
  getTemplatesThunk,
  getWorkSpaceThunk,
} from './thunk'

const valByKeyWithDot = (data, keyWithDot) => {
  return keyWithDot.split('.').reduce((result, key) => {
    return result?.[key]
  }, data)
}

const initialSheet = {
  economyById: {},
  economyId: [],
  columnId: [],
  columnById: {},
  economyDisplay: [],
  defaultEconomyId: [],
  condition: null,
  isLoad: false,
}

export const keys = {
  INDICATOR: 'indicator',
  TABLE_RESULT: 'tableResult',
}

const initialState = {
  loading: { [keys.INDICATOR]: false, [keys.TABLE_RESULT]: true },
  sheets: {
    1: initialSheet,
  },
  listSheet: [{ title: 'SHEET 1', value: 1 }],
  activeSheet: 1,
  indicator: [],
  indicatorById: {},
  isOpenSetting: false,
  conditionTypeEconomy: null,
  templates: [],
  isLoadWorkSpace: false,
}

export const slice = createSlice({
  name: 'dataExplorer/economy',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    resetEconomy(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.economyById = initialSheet.economyById
      activeSheet.economyId = initialSheet.economyId
      activeSheet.economyDisplay = initialSheet.economyDisplay
      activeSheet.defaultEconomyId = initialSheet.defaultEconomyId
    },
    cancelSetting(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.columnId = initialSheet.columnId
      activeSheet.columnById = initialSheet.columnById
    },
    // sheet
    changeActiveSheet(state, action) {
      state.activeSheet = action.payload
    },
    addSheet(state, action) {
      state.sheets[action.payload] = initialSheet
    },
    removeSheet(state, action) {
      delete state.sheets[action.payload.value]
      if (action.payload.templateId) {
        let countTemplate = 1
        state.listSheet.forEach((sheet, index) => {
          if (sheet.templateId === action.payload.templateId) {
            state.listSheet[index].title =
              action.payload.templateName +
              (countTemplate !== 1 ? ` (${countTemplate})` : '')
            countTemplate += 1
          }
        })
      }
    },
    changeListSheet(state, action) {
      state.listSheet = action.payload
    },
    // indicator
    addConditionTypeEconomy(state, action) {
      state.conditionTypeEconomy = action.payload
    },
    handlePopupSetting(state) {
      state.isOpenSetting = !state.isOpenSetting
    },
    changeEconomyDisplay(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      if (activeSheet) {
        activeSheet.economyDisplay = action.payload
      }
    },
    changeCondition(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.condition = action.payload
    },
    addColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const indicator = action.payload
      if (activeSheet.columnId.includes(indicator.indicatorId)) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.economy.INDICATOR_EXIST" />,
        ])
      } else if (activeSheet.columnId.length >= maxIndicator) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.economy.MAX_INDICATOR" />,
        ])
      } else {
        if (!activeSheet.condition) {
          state.conditionTypeEconomy = indicator.conditionType?.toLowerCase()
          state.isOpenSetting = true
        }
        activeSheet.columnId.push(indicator.indicatorId)
        activeSheet.columnById[indicator.indicatorId] = indicator
      }
    },
    removeColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const arrColumnDelete = action.payload

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnDelete.includes(id),
      )
      arrColumnDelete.forEach((columnId) => {
        delete activeSheet.columnById[columnId]
        activeSheet.economyId.forEach(
          (id) => delete activeSheet.economyById[id]?.[columnId],
        )
      })
    },
    swapTwoColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { column1, column2 } = action.payload
      const idColumn1 = activeSheet.columnId[column1]
      const idColumn2 = activeSheet.columnId[column2]
      activeSheet.columnId.splice(column1, 1)
      activeSheet.columnId.splice(
        activeSheet.columnId.indexOf(idColumn2),
        0,
        idColumn1,
      )
    },
    sort(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      let { idColumn, typeSort } = action.payload
      idColumn = idColumn === 'period' ? 'date' : idColumn

      switch (typeSort) {
        case SORT_TYPES.DEFAULT:
          activeSheet.economyId = activeSheet.defaultEconomyId
          break
        case SORT_TYPES.ASC:
          if (
            activeSheet.defaultEconomyId.length !== activeSheet.economyId.length
          ) {
            activeSheet.defaultEconomyId = [...activeSheet.economyId]
          }
          activeSheet.economyId = activeSheet.economyId.sort(
            funcSortAsc(activeSheet.economyById, idColumn),
          )
          break
        case SORT_TYPES.DESC:
          activeSheet.economyId = activeSheet.economyId.sort(
            funcSortDesc(activeSheet.economyById, idColumn),
          )
          break
        default:
          break
      }
    },
    swapColumns(state, action) {
      state.sheets[state.activeSheet].columnId = action.payload
    },
    resetColumn(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.economyId = []
      activeSheet.defaultEconomyId = []
      activeSheet.economyById = {}
      activeSheet.isLoad = false
      activeSheet.condition = null
    },
    changeDetailSetting(state, action) {
      const activeSheet = state.sheets[state.activeSheet]

      const { key, value } = action.payload

      activeSheet.condition = changeValueWithDot(
        activeSheet.condition,
        key,
        value,
      )
    },
    openTemplate(state, action) {
      if (state.listSheet.length >= maxSheet) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_1" />,
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_2" />,
        ])
      } else {
        const countSheetOfTemplate = state.listSheet.filter(
          (sheet) => sheet?.templateId === action.payload.templateId,
        ).length
        const value = state.listSheet[state.listSheet.length - 1].value + 1
        const title =
          action.payload.templateName +
          (countSheetOfTemplate ? ` (${countSheetOfTemplate + 1})` : '')
        state.listSheet.push({
          title,
          value,
          templateId: action.payload.templateId,
          templateName: action.payload.templateName,
        })
        state.sheets[value] = {
          ...initialSheet,
          ...action.payload.parameters?.rawParameter,
          isLoad: false,
        }
        state.activeSheet = value
      }
      state.loading[keys.TABLE_RESULT] = false
    },
    changeSheetParamsWhenLoadTemplate: (state, action) => {
      const { activeSheet, columnId, columnById } = action.payload

      state.sheets[activeSheet] = {
        ...state.sheets[activeSheet],
        columnId,
        columnById,
      }
    },
  },
  extraReducers: (builder) => {
    // get indicator
    builder.addCase(getIndicatorThunk.pending, (state) => {
      state.loading[keys.INDICATOR] = true
    })
    builder.addCase(getIndicatorThunk.fulfilled, (state, action) => {
      state.indicator = action.payload
      state.indicatorById = keyBy(action.payload, 'indicatorId')
      state.loading[keys.INDICATOR] = false
    })
    builder.addCase(getIndicatorThunk.rejected, (state, action) => {
      state.loading[keys.INDICATOR] = action.payload
    })
    // result table
    builder.addCase(getResultThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getResultThunk.fulfilled, (state, action) => {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.economyId = []
      activeSheet.economyById = {}
      const defaultValues = activeSheet.columnId.reduce((items, key) => {
        items[key] = null
        return items
      }, {})
      action.payload.forEach((item) => {
        activeSheet.economyId.push(item.date)

        activeSheet.economyById[item.date] = {
          id: item.date,
          period: item.period,
          date: item.date,
          ...defaultValues,
          ...item.items,
        }
      })
      activeSheet.economyId = activeSheet.economyId.sort((date1, date2) => {
        if (date1 > date2) {
          return -1
        }
        if (date1 < date2) {
          return 1
        }
        return 0
      })
      activeSheet.defaultEconomyId = [...activeSheet.economyId]
      activeSheet.isLoad = true
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getResultThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // template
    builder.addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.templates = action.payload
    })
    builder.addCase(getTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getShareTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getShareTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // get work space
    builder.addCase(getWorkSpaceThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getWorkSpaceThunk.fulfilled, (state, action) => {
      state.loading[keys.TABLE_RESULT] = false

      const workSpaces = action.payload.filter(
        (item) => item.dataType === typeWorkSpace.ECONOMY,
      )

      state.activeSheet = 1

      let countSheet = 1

      workSpaces.forEach((workSpace, index) => {
        const { data, template } = workSpace.parameters.rawParameter

        if (template) {
          state.sheets[countSheet] = {
            ...initialSheet,
            ...data,
            isLoad: false,
          }

          if (index === 0) {
            state.listSheet = []
          }

          state.listSheet.push({
            ...template,
            title:
              template?.title?.slice(0, 5) === 'SHEET'
                ? 'SHEET ' + countSheet
                : template?.title,
            value: countSheet,
          })

          countSheet += 1
        }
      })

      state.isLoadWorkSpace = true
    })
    builder.addCase(getWorkSpaceThunk.rejected, (state) => {
      state.isLoadWorkSpace = true

      state.loading[keys.TABLE_RESULT] = false
    })
  },
})

export const {
  resetStore,
  changeActiveSheet,
  addSheet,
  removeSheet,
  handlePopupSetting,
  addConditionTypeEconomy,
  changeEconomyDisplay,
  addColumn,
  swapTwoColumn,
  sort,
  swapColumns,
  removeColumn,
  changeListSheet,
  changeCondition,
  resetEconomy,
  resetColumn,
  cancelSetting,
  changeDetailSetting,
  openTemplate,
  changeSheetParamsWhenLoadTemplate,
} = slice.actions

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectActiveSheet = (state) => state[slice.name].activeSheet
export const selectSheet = (state) => state[slice.name].sheets

export const selectEconomyById = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.economyById
export const selectEconomyId = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.economyId
export const selectEconomyCell = (sheet) => (id, attr) => (state) =>
  state[slice.name].sheets[sheet]?.economyById[id]?.[attr]
export const selectColumnId = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].columnId?.[index]
export const selectColumnCell = (sheet) => (id) => (state) =>
  state[slice.name].sheets[sheet].columnById?.[id]
export const selectColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnById
export const selectFullColumnId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnId
export const selectEconomyDisplay = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].economyDisplay?.[index]
export const selectFullEconomyDisplay = (sheet) => (state) =>
  state[slice.name].sheets[sheet].economyDisplay

export const selectIndicator = (state) => state[slice.name].indicator
export const selectIndicatorById = (state) => state[slice.name].indicatorById

export const selectIsOpenSetting = (state) => state[slice.name].isOpenSetting
export const selectConditionTypeEconomy = (state) =>
  state[slice.name].conditionTypeEconomy

export const selectTemplates = (state) => state[slice.name].templates

export const selectListSheet = (state) => state[slice.name].listSheet

export const selectIsLoad = (sheet) => (state) =>
  state[slice.name].sheets[sheet].isLoad

export const selectCondition = (sheet) => (state) =>
  state[slice.name].sheets[sheet].condition

export const selectDetailSetting = (sheet) => (key) => (state) =>
  valByKeyWithDot(state[slice.name].sheets[sheet].condition, key)

export const selectIsLoadWorkSpace = (state) =>
  state[slice.name].isLoadWorkSpace

register(slice.name, slice.reducer)
