import { hierarchy, treemap } from 'd3-hierarchy'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import useWindowDevicePixelRatio from '../../../common/hooks/useWindowDevicePixelRatio'
import { SizeTracker } from '../../../common/sizeTracker'
import { getClassName, isTooTiny } from './helper'
import { ListTicker } from './ListTicker'
import { SectorHeader } from './SectorHeader'
import { Ticker } from './Ticker'
import style from './tickerTreeMap.module.css'

const TITLE_HEIGHT = 23
const PADDING = 15

export const TickerTreeMap = ({
  width,
  height,
  title,
  bgColor,
  data,
  parentRate,
  sectorName,
  rootRef,
  changeFullNode,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const getRoot = (width, height, data) => {
    const paddingTop = isTooTiny(width, height) ? 0 : TITLE_HEIGHT
    const root = hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => {
        if (!a.data.organizationId) {
          return 1
        }
        return b.value - a.value
      })
    treemap().size([width, height]).paddingTop(paddingTop).paddingInner(2)(root)

    return root
  }

  const calcPosition = (e) => {
    const rootRect = rootRef.current.getBoundingClientRect()
    const tickerHeight = listTickerRef.current.scrollHeight
    const tickerWidth = listTickerRef.current.scrollWidth
    const pageX = formatWithZoom(e.pageX)
    const pageY = formatWithZoom(e.pageY)

    const isShowTop = pageY + tickerHeight > rootRect.bottom
    const isShowLeft = pageX + tickerWidth > rootRect.right

    return {
      top: isShowTop ? pageY - tickerHeight - PADDING : pageY + PADDING,
      left: isShowLeft ? pageX - tickerWidth - PADDING : pageX + PADDING,
    }
  }

  const onMouseMove = (e, code, bgColor) => {
    const { top, left } = calcPosition(e)
    setHoverData({ code, bgColor })
    setPosition({ top, left })
  }

  const hiddenHover = () => {
    if (isHover) {
      setIsHover(false)
    }
  }

  const enableHover = () => {
    if (!isHover) {
      setIsHover(true)
    }
  }

  const root = getRoot(width, height, data)
  const [isHover, setIsHover] = useState(false)
  const [isShowListTicker, setIsShowListTicker] = useState(false)
  const [position, setPosition] = useState({ top: -1000, left: -1000 })
  const [hoverData, setHoverData] = useState({})
  const listTickerRef = useRef()

  return (
    <div
      style={{ width, height }}
      onMouseLeave={hiddenHover}
      onMouseOver={enableHover}
      className={getClassName('', style.sectorHover, isHover)}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              <SectorHeader
                parentRate={parentRate}
                isHover={isHover}
                hiddenHover={hiddenHover}
                title={title}
                width={width}
                height={height}
                changeFullNode={changeFullNode}
              />
              {(size.height || size.height === 0) && (
                <div
                  className={getClassName(
                    style.tickerTreeMap,
                    style.sectorHoverTickerTreemap,
                    isHover,
                  )}
                  style={{
                    width,
                    height: isTooTiny() ? height : height - size.height,
                  }}
                  onMouseEnter={() => {
                    setIsShowListTicker(true)
                  }}
                  onMouseLeave={() => {
                    setIsShowListTicker(false)
                  }}
                >
                  {root.leaves().map((node, index) => {
                    return (
                      <Ticker
                        key={index}
                        bgColor={bgColor}
                        node={node}
                        onMouseMove={onMouseMove}
                      />
                    )
                  })}
                  <div onMouseOver={onMouseMove}>
                    <ListTicker
                      data={data.children}
                      sectorName={sectorName}
                      appendStyle={{
                        top: position.top,
                        left: position.left,
                        position: 'fixed',
                        maxHeight: isShowListTicker ? 1000 : 0,
                      }}
                      hoverData={hoverData}
                      ref={listTickerRef}
                    />
                  </div>
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

TickerTreeMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  parentRate: PropTypes.number.isRequired,
  sectorName: PropTypes.string.isRequired,
  rootRef: PropTypes.object.isRequired,
  changeFullNode: PropTypes.func.isRequired,
}
