import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Table } from '../../../../common/table'
import { indicatorIncomeStatementIns } from '../../filter/constants'
import {
  selectDataFDF,
  selectInComeStatementValue,
  selectItemInComeStatementData,
} from '../../filter/store/slice'
import style from '../../preview/style.module.css'
import { FirstColumnCell } from '../FirstColumnCell'
import { OtherColumnsCell } from '../OtherColumnsCell'

const IncomeStatementTable = ({ isIns }) => {
  const data = useSelector(selectDataFDF)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div>
      <div className={style.titleGroup}>
        <Translate value="corporate.companySnapshot.FINANCIAL_STATEMENTS" />
      </div>
      <div style={{ height: dimensions.height }}>
        <Table
          ids={
            isIns
              ? indicatorIncomeStatementIns.map((item) => item.index)
              : data.inComeStatement.ids
          }
          columnDraggable={false}
          getDataFromRedux={selectInComeStatementValue}
          isLoading={false}
          rowDraggable={false}
          schema={data.inComeStatement.groupColumns.map((item, index) => {
            const title = I18n.t(item.title.toString())
            const colId = item.key
            const result = {
              colId,
              title,
            }
            if (index === 0) {
              return {
                ...result,
                isI18n: true,
                thStyle: {
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: 9,
                },
                canCustomTd: true,
                render: (val, rowId, { className, style }) => {
                  return (
                    <FirstColumnCell
                      val={val}
                      rowId={rowId}
                      className={className}
                      selectItem={selectItemInComeStatementData}
                      style={{
                        ...style,
                        width: '33%',
                      }}
                    />
                  )
                },
              }
            }
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'right',
                fontWeight: 'bold',
                fontSize: 9,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                return (
                  <OtherColumnsCell
                    val={val}
                    rowId={rowId}
                    colIndex={index}
                    numCol={data.inComeStatement.groupColumns.length}
                    props={props}
                  />
                )
              },
            }
          })}
          isPagination={false}
          hasFooter={false}
          resizable={false}
          dimensionsTable={setDimensions}
          hasTooltip={false}
        />
      </div>
    </div>
  )
}

export default IncomeStatementTable
