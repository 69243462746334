import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import { changeDashboardSelected } from '../../store/slice'
import style from '../styleTheme.module.css'
import { PopupContent } from './PopupContent'
import { PopupGroupButton } from './PopupGroupButton'

const PopupSaveChartToNewDashboard = ({
  chartTabs,
  chartTabActive,
  handleShow,
  onChangeNameChart,
  isSaveAsChart,
}) => {
  const dispatch = useDispatch()
  const buttonSaveRef = useRef()

  const [chartTitle, setChartTitle] = useState('')
  const [newDashboardName, setNewDashboardName] = useState(
    I18n.t('financialChart.NEW_DASHBOARD_NAME'),
  )
  const [isAddNewDashboard, setIsAddNewDashboard] = useState(false)
  const [isEnterAddNewDashboard, setIsEnterAddNewDashboard] = useState(false)

  const onClose = () => {
    handleShow(false)
  }

  useEffect(() => {
    return () => {
      dispatch(changeDashboardSelected())
      setIsAddNewDashboard(false)
      setIsEnterAddNewDashboard(false)
      setNewDashboardName('')
    }
  }, [])

  useEffect(() => {
    if (chartTabActive && chartTabs)
      setChartTitle(chartTabs[chartTabActive]?.nameChart.name || '')
  }, [chartTabActive, chartTabs])

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div
        className={`modal modal-small ${style.modal}`}
        style={{ width: 600 }}
      >
        <div className={`modal-title ${style.titleStyle}`}>
          <h3>
            <Span>
              <Translate value="financialChart.SAVE_CHART" />
            </Span>
          </h3>
          <a onClick={onClose} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentStyle}`}>
          <PopupContent
            buttonSaveRef={buttonSaveRef}
            chartTitle={chartTitle}
            newDashboardName={newDashboardName}
            chartTabActive={chartTabActive}
            chartTabs={chartTabs}
            isAddNewDashboard={isAddNewDashboard}
            setChartTitle={setChartTitle}
            setNewDashboardName={setNewDashboardName}
            setIsAddNewDashboard={setIsAddNewDashboard}
            setIsEnterAddNewDashboard={setIsEnterAddNewDashboard}
            onChangeNameChart={onChangeNameChart}
          />
          <PopupGroupButton
            buttonSaveRef={buttonSaveRef}
            chartTitle={chartTitle}
            newDashboardName={newDashboardName}
            isAddNewDashboard={isAddNewDashboard}
            setIsAddNewDashboard={setIsAddNewDashboard}
            chartTabActive={chartTabActive}
            chartTabs={chartTabs}
            handleShow={handleShow}
            isSaveAsChart={isSaveAsChart}
            isEnterAddNewDashboard={isEnterAddNewDashboard}
          />
        </div>
      </div>
    </Popup>
  )
}

export default PopupSaveChartToNewDashboard
