import PropTypes from 'prop-types'
import style from './index.module.css'

export const Image = ({ imgSrc, width, height, isActive, onClick }) => {
  return (
    <img
      onClick={onClick}
      src={imgSrc}
      alt={'thumbnails'}
      style={{ width, height }}
      className={[style.img, isActive ? style.imgActive : ''].join(' ')}
    />
  )
}

Image.propTypes = {
  imgSrc: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

Image.defaultProps = {
  imgSrc: '',
  isActive: false,
  onClick: () => {},
}
