import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FDIByIndustriesService from '../../../../../../core/services/economy/fdi/FDIByIndustriesService'
import { TIME_FREQUENCY } from '../../../common/constants'
import {
  changeFilterMonth,
  changeFilterYear,
  changeFilterYearByYearly,
  changeRetryFlag,
} from './slice'

export const getDataPieChart = createAsyncThunk(
  'economy/fdi/fdiByIndustries/GET_DATA_PIE_CHART',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FDIByIndustriesService.getCompositionOfFDIInflowsByIndustryPieChart(
          params,
        )
      if (response.requestParams) {
        if (
          response.requestParams.TimeFrequency === TIME_FREQUENCY.ACC_MONTHLY
        ) {
          dispatch(changeFilterYear(response.requestParams.Year))
          dispatch(changeFilterMonth(response.requestParams.Month))
        } else {
          dispatch(changeFilterYearByYearly(response.requestParams.Year))
        }
      }
      dispatch(changeRetryFlag(false))
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getDataColumnChart = createAsyncThunk(
  'economy/fdi/fdiByIndustries/GET_DATA_COLUMN_CHART',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FDIByIndustriesService.getCompositionOfFDIInflowsByIndustryColumnChart(
          params,
        )
      if (response.requestParams) {
        if (
          response.requestParams.TimeFrequency === TIME_FREQUENCY.ACC_MONTHLY
        ) {
          dispatch(changeFilterYear(response.requestParams.Year))
          dispatch(changeFilterMonth(response.requestParams.Month))
        } else {
          dispatch(changeFilterYearByYearly(response.requestParams.Year))
        }
      }
      dispatch(changeRetryFlag(false))
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
