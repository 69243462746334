import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  LEFT_FRAME_OPTIONS,
  LIST_RADIO,
  RIGHT_FRAME_OPTIONS,
} from './constants'
import style from './index.module.css'
import {
  getLeftFrameValue,
  getRightFrameValue,
  handleLeftFrameValue,
  handleRadioValue,
  handleRightFrameValue,
  selectRadioValue,
} from './store/slice'

const Header = ({ isBalanceChart, width }) => {
  const dispatch = useDispatch()

  const leftFrameValue = useSelector(getLeftFrameValue)
  const rightFrameValue = useSelector(getRightFrameValue)
  const radioValue = useSelector(selectRadioValue)

  const changeLeftFrameValue = (val) => {
    dispatch(handleLeftFrameValue(val))
  }

  const changeRightFrameValue = (tab) => {
    dispatch(handleRightFrameValue(tab.value))
  }

  const handleChangeRadio = (val) => {
    dispatch(handleRadioValue(val))
  }

  return (
    <>
      <div className="d-flex j-b mb-8">
        <div className="d-flex">
          <div
            className={`${style.inputDropdown}`}
            style={{ maxWidth: width * 0.3, marginRight: 10 }}
          >
            <SelectCustom
              value={leftFrameValue}
              isI18n={true}
              selectData={LEFT_FRAME_OPTIONS}
              handleChange={changeLeftFrameValue}
            />
          </div>
          {!isBalanceChart ? (
            <div className="d-flex align-center">
              <ul className={`list-check d-flex mt-0`}>
                {LIST_RADIO.map((item) => (
                  <li key={item.value} className="mb-0">
                    <label>
                      <input
                        type="radio"
                        className="radiobox radiobox2"
                        checked={item.value === radioValue}
                        onChange={() => handleChangeRadio(item.value)}
                      />
                      <Span
                        style={{ fontSize: 12 }}
                        className={
                          item.value === radioValue ? style.active : ''
                        }
                      >
                        <TextEllipsis text={item.label} />
                      </Span>
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </div>
        <DispatchActionTab
          data={RIGHT_FRAME_OPTIONS}
          activeTab={rightFrameValue}
          onChangeTab={changeRightFrameValue}
          itemStyle={{
            fontWeight: 500,
            fontSize: 11,
            paddingLeft: 10,
            paddingRight: 10,
          }}
          widthComponent={0.4 * width}
        />
      </div>
    </>
  )
}

export default Header
