export const keyByYear = (payload) => {
  if (!payload.length) {
    return []
  }

  const years = [...new Set(payload.map((item) => item.year))]
  return years.map((year) => {
    const dataWithYear = payload.filter((item) => item.year === year)

    return dataWithYear.reduce((previous, current) => {
      return {
        ...previous,
        [current.nationalAccountVNTypeId]: current.value / 1e12,
        year,
      }
    }, {})
  })
}

export const getIndicatorName = (data, id) => {
  return data.find((item) => item.nationalAccountVNTypeId === id)
    ?.nationalAccountVNTypeName
}

export const getTooltipContentData = (ids, dataTypeName) => {
  return ids.map((id) => {
    const currentItem = dataTypeName.find(
      (item) => item.nationalAccountVNTypeId === id,
    )
    return {
      id: currentItem.nationalAccountVNTypeId,
      title: currentItem.nationalAccountVNTypeName,
    }
  })
}

export const handleTooltipTwoColumn = (ids, dataTypeName) => {
  const MAX_ITEM_OF_COLUMN = 8
  const tooltipData = getTooltipContentData(ids, dataTypeName)
  if (tooltipData.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: tooltipData, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(tooltipData.length / 2)
  return {
    leftColumn: tooltipData.concat().splice(0, leftColumnLength),
    rightColumn: tooltipData.concat().splice(leftColumnLength),
  }
}
