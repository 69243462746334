import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { TopMarketChart } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  selectCurrentSelection,
  selectListSelection,
  selectMaxYear,
  selectYear,
} from './store/slice'

export const PanelTopMarketChart = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const currentSelection = useSelector(selectCurrentSelection)
  const listSelection = useSelector(selectListSelection)
  const currentYear = useSelector(selectYear)
  const maxYear = useSelector(selectMaxYear)

  const currentSelectionName =
    listSelection.length &&
    listSelection?.find((item) => item.value === currentSelection).name
  const currentSelectedYear = currentYear ?? maxYear

  const nameScreen = I18n.t('sector.sectorSpecific.fishery.OVERVIEW')
  const getChartName = () => {
    return `${I18n.t(
      'sector.sectorSpecific.fishery.overview.TOP_FIVE',
    )} ${currentSelectionName} ${I18n.t(
      'sector.sectorSpecific.fishery.overview.MARKET',
    )} ${currentSelectedYear}`
  }

  return (
    <Panel
      title="sector.sectorSpecific.fishery.overview.TOP_MARKET"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={getChartName()}
      downloadJpgParams={{
        domId: 'topMarketChart',
        nameScreen: nameScreen,
        chartName: getChartName(),
      }}
    >
      <TopMarketChart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
