import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class SecondaryOverviewProxy extends ProxyBase {
  getTopLiquidityDataByIssuer(params) {
    return this.get('GetLiquidityChangebyIssuer', params)
  }
  getTopLiquidityDataByBond(params) {
    return this.get('GetLiquidityChangebyBond', params)
  }
  getAverageYTMofIssuer(params) {
    return this.get('GetAverageYTMofIssuer', params)
  }
  getTopInterestRate(params) {
    return this.get('TopInterestRate', params)
  }
  getTopInterestRateAsk(params) {
    return this.get('TopInterestRate', params, {}, true)
  }
  getMarketLiquidityChart(params) {
    return this.get('MarketLiquidity', params)
  }

  downloadTopLiquidityByIssuer(params) {
    return this.get('DownloadLiquidityChangeByIssuer', params, 'download')
  }
  downloadTopLiquidityByBond(params) {
    return this.get('DownloadLiquidityChangeByBond', params, 'download')
  }
  downloadAverageYTMofIssuer(params) {
    return this.get('DownloadAverageYTMofIssuer', params, 'download')
  }
  downloadTopInterestRate(params) {
    return this.get('DownloadTopInterestRate', params, 'download')
  }
}

export default new SecondaryOverviewProxy(
  ServiceProxyConfig.Bond.SecondaryMarketOverview.ServiceUrl,
)
