import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { DiamondIcon } from '../../../../../common/chart/scatterDiamond/DiamondIcon'
import { Span } from '../../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../../utils/Color'
import { formatVal } from '../../../../../utils/Value'
import { latePaymentType } from './constants'
import { selectActiveTab } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 10,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  listBar,
  listScater,
  listfooter,
  ids,
}) => {
  const activeTab = useSelector(selectActiveTab)
  const tab = latePaymentType.find((e) => e.value === activeTab)

  const renderTooltip = (tooltipData) => {
    const keySector = listBar.filter(
      (e) => e !== 'number' && displayData.includes(e),
    )
    const tooltipContentData = (key) => {
      const parts = key.split('-')
      const id = parseInt(parts[0])
      return {
        label: tooltipData?.[`${id}-name`],
        value: formatVal(tooltipData?.[`${id}-latePaymentValue`], 0),
        unit: 'bond.overview.latePayment.chart.unitMoney',
      }
    }

    const tooltipContentBottomData = (key) => {
      const parts = key.split('-')
      const id = parseInt(parts[0])
      return {
        label: tooltipData?.[`${id}-name`],
        value: formatVal(tooltipData?.[`${id}-number`], 0),
        unit: 'bond.overview.latePayment.chart.unitMoney',
      }
    }

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10, fontWeight: 400 }}>
            {tooltipData.XAxis} {I18n.t(tab?.label || '')}
          </Span>
        </div>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {I18n.t('bond.overview.latePayment.chart.valueDelay')}
          </Span>
        </div>
        {keySector.map((key, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 340 }}>
                {tooltipContentData(key).label}
              </Span>
            </div>
            <div className="ml-8">
              <Span
                style={{
                  fontSize: 11,
                  fontWeight: 340,
                  color: getTwoColorByValue(tooltipData[key] || 0),
                }}
              >
                {tooltipContentData(key).value}
              </Span>
              <Span style={{ fontSize: 11, fontWeight: 340 }}>
                {' ' + I18n.t(tooltipContentData(key).unit)}
              </Span>
            </div>
          </div>
        ))}
        {displayData.includes('number') && (
          <>
            <div style={{ fontStyle: 'italic' }}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t('bond.overview.latePayment.chart.numberJobDelay')}
              </Span>
            </div>
            {keySector.map((key, index) => (
              <div key={index} className="d-flex j-b">
                <div>
                  <Span style={{ fontSize: 11, fontWeight: 340 }}>
                    {tooltipContentBottomData(key).label}
                  </Span>
                </div>
                <div className="ml-8">
                  <Span
                    style={{
                      fontSize: 11,
                      fontWeight: 340,
                      color: getTwoColorByValue(tooltipData[key] || 0),
                    }}
                  >
                    {tooltipContentBottomData(key).value}
                  </Span>
                </div>
              </div>
            ))}
          </>
        )}
      </>
    )
  }

  const yAxisLeft = [
    {
      id: 'left',
      keys: listBar.filter((e) => {
        return displayData.includes(e)
      }),
      orientation: 'left',
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.latePayment.chart.labelLeft'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
    },
  ]

  const getColor = (key) => {
    const item = listfooter.find((e) => e.dataKey === key)
    return item?.before?.bgColor || ''
  }

  const yAxisRight = [
    {
      id: 'right',
      keys: listScater,
      orientation: 'right',
      tickNum: SETTINGS.yTickNum,
      labelPosition: AXIS_LABEL_POSITION.RIGHT,
    },
  ]

  const CustomShape = useCallback(
    (props) => {
      const { x, y, numColumn, chartContentWidth, color, keyScarterfig } = props
      const dataBar = displayData.filter((e) => e !== 'number')
      const demo = (chartContentWidth * dataBar.length) / 2
      const cx =
        x - demo + numColumn * chartContentWidth - chartContentWidth / 2
      return (
        <>
          {props?.[keyScarterfig] && (
            <DiamondIcon
              x={cx}
              y={y}
              color={color}
              style={{ width: '5px !important', height: '5px !important' }}
            />
          )}
        </>
      )
    },
    [width, displayData],
  )

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      // timeFrame="1M"
      timeFrame="CUSTOM"
      tickFormatter={(d) => d}
      margin={{
        top: 20,
        bottom: 0,
        left: 0,
        right: 20,
      }}
      hasBarChart={displayData.length ? true : false}
      yAxis={
        displayData.includes('number')
          ? yAxisLeft.concat(yAxisRight)
          : yAxisLeft
      }
      renderCustomTooltip={(dataFormat, decimalLengths, xAxisDecimalLength) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => {
        const barSize = getColumnSizeInBarChart(
          chartContentWidth,
          data.length,
          null,
          listBar.length,
        )

        return (
          <>
            {listBar
              .filter((e) => {
                return displayData.includes(e)
              })
              .map(
                (e, index) =>
                  displayData.includes(e) && (
                    <Bar
                      isAnimationActive={false}
                      dataKey={e}
                      yAxisId="left"
                      fill={getColor(e)}
                      barSize={barSize}
                      key={index}
                    />
                  ),
              )}
            {listScater
              .filter((e, index) => {
                const parts = e.split('-')
                const number = parseInt(parts[0])
                return displayData.includes(`${number}-latePaymentValue`)
              })
              .map((e, index) => {
                if (displayData.includes('number')) {
                  return (
                    <Scatter
                      isAnimationActive={false}
                      dataKey={e}
                      yAxisId="right"
                      shape={
                        <CustomShape
                          color={'#ECECEC'}
                          chartContentWidth={barSize}
                          numColumn={index + 1}
                          keyScarterfig={e}
                        />
                      }
                    />
                  )
                } else {
                  return <></>
                }
              })}
          </>
        )
      }}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
