import { useMemo } from 'react'
import { COMPONENT } from '../../../../../../configs/Layout'
import DropdownSearchTickers from '../dropdownSearchTickers'
import CalendarSearch from './CalendarSearch'
import DropdownSearchDateType from './DropdownSearchDateType'
import DropdownSearchStatus from './DropdownSearchStatus'
import DropdownSearchTransaction from './DropdownSearchTransaction'
import DropdownSearchTypes from './DropdownSearchTypes'
import OwnerSearch from './OwnerSearch'

export const Filter = ({ width }) => {
  const checkIpadScreen = (width, compareWidth = COMPONENT.MIN_SIZE.WIDTH) => {
    return width < compareWidth
  }

  const isIpadScreen = useMemo(() => {
    return checkIpadScreen(width, 1150)
  }, [width])

  return (
    <div className="mb-20">
      {isIpadScreen ? (
        <>
          <div className="d-flex mb-15">
            <DropdownSearchTransaction />
            <DropdownSearchTypes />
            <DropdownSearchTickers />
            <DropdownSearchStatus />
            <OwnerSearch isIpadScreen={isIpadScreen} />
          </div>
          <div className="d-flex justify-content-end">
            <DropdownSearchDateType />
            <CalendarSearch />
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-space-between">
          <div className="d-flex">
            <DropdownSearchTransaction />
            <DropdownSearchTypes />
            <DropdownSearchTickers />
            <DropdownSearchStatus />
            <OwnerSearch isIpadScreen={isIpadScreen} />
          </div>
          <div className="d-flex">
            <DropdownSearchDateType />
            <CalendarSearch />
          </div>
        </div>
      )}
    </div>
  )
}
