import { Span } from '../../../../common/html/Span'

import UseI18n from '../../../../common/hooks/useI18n'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'

const Title = ({ text, suffix, appendStyle }) => {
  const translateText = UseI18n(`sector.sectorConstituents.valuation.${text}`)
  const displayText = suffix ? translateText + ' ' + suffix : translateText

  return (
    <Span
      className="text-up t-center d-block"
      style={{ fontWeight: 600, paddingBottom: 10 }}
    >
      <TextEllipsisStaticLine
        isI18n={false}
        val={displayText}
        appendStyle={appendStyle}
      />
    </Span>
  )
}

export default Title
