import { useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import style from '../index.module.css'
import { key, selectLoading } from '../store/slice'

const LoadingCondition = () => {
  const loading = useSelector(selectLoading(key.CONDITIONS))

  return (
    <>
      {loading && (
        <div className={`h-100 w-100 ${loading ? style.loadingBlur : ''}`}>
          <Loading />
        </div>
      )}
    </>
  )
}

export default LoadingCondition
