import FDIByProvincesProxy from '../../../proxies/economy/fdi/FDIByProvincesProxy'
import { ServiceBase } from '../../ServiceBase'

export class FDIByProvincesService extends ServiceBase {
  getTotalRegisterFDICapital(params) {
    return this.getData(() =>
      FDIByProvincesProxy.getTotalRegisterFDICapital(params),
    )
  }

  downloadTotalRegisterFDICapital(params) {
    return this.getFileDownload(() =>
      FDIByProvincesProxy.downloadTotalRegisterFDICapital(params),
    )
  }

  getLocationName(params) {
    return this.getData(() => FDIByProvincesProxy.getLocationName(params))
  }

  getFDIStructureByProvince(params) {
    return this.getData(() =>
      FDIByProvincesProxy.getFDIStructureByProvince(params),
    )
  }

  getCompositionInTotalFDIPieChart(params) {
    return this.getData(() =>
      FDIByProvincesProxy.getCompositionInTotalFDIPieChart(params),
    )
  }

  getCompositionInTotalFDIColumnChart(params) {
    return this.getData(() =>
      FDIByProvincesProxy.getCompositionInTotalFDIColumnChart(params),
    )
  }
}

export default new FDIByProvincesService()
