export const DEFAULT_MONTH = 1
export const DEFAULT_YEAR = 2022

export const MONTH_LIST_FILTER_VI = [
  {
    name: 'Th01',
    value: 1,
  },
  {
    name: 'Th02',
    value: 2,
  },
  {
    name: 'Th03',
    value: 3,
  },
  {
    name: 'Th04',
    value: 4,
  },
  {
    name: 'Th05',
    value: 5,
  },
  {
    name: 'Th06',
    value: 6,
  },
  {
    name: 'Th07',
    value: 7,
  },
  {
    name: 'Th08',
    value: 8,
  },
  {
    name: 'Th09',
    value: 9,
  },
  {
    name: 'Th10',
    value: 10,
  },
  {
    name: 'Th11',
    value: 11,
  },
  {
    name: 'Th12',
    value: 12,
  },
]

export const MONTH_LIST_FILTER_EN = [
  {
    name: 'Jan',
    value: 1,
  },
  {
    name: 'Feb',
    value: 2,
  },
  {
    name: 'Mar',
    value: 3,
  },
  {
    name: 'Apr',
    value: 4,
  },
  {
    name: 'May',
    value: 5,
  },
  {
    name: 'Jun',
    value: 6,
  },
  {
    name: 'Jul',
    value: 7,
  },
  {
    name: 'Aug',
    value: 8,
  },
  {
    name: 'Sep',
    value: 9,
  },
  {
    name: 'Oct',
    value: 10,
  },
  {
    name: 'Nov',
    value: 11,
  },
  {
    name: 'Dec',
    value: 12,
  },
]

export const ACC_MONTH_FILTER_VI = Array.from(
  { length: 12 },
  (v, k) => k + 1,
).map((item) => ({
  name: item === 1 ? `Th0${item}` : `${item}Th`,
  value: item,
}))

export const ACC_MONTH_FILTER_EN = Array.from(
  { length: 12 },
  (v, k) => k + 1,
).map((item) => ({
  name: item === 1 ? 'Jan' : `${item}M`,
  value: item,
}))

const currentYearByDateJS = new Date().getFullYear()
const MIN_YEAR = 2013

export const YEAR_LIST = [...Array(currentYearByDateJS - MIN_YEAR + 1)].map(
  (_, index) => currentYearByDateJS - index,
)

export const YEAR_LIST_FILTER = YEAR_LIST.map((item) => ({
  value: item,
  name: `${item}`,
}))

export const TYPE_CHART_STRUCTURE = {
  STRUCTURE: 'Structure',
  GROWTH: 'Growth',
}

export const EXPORT_STRUCTURE_FILTER = [
  {
    title:
      'economy.importExport.importExport.exportImportByCommodities.FILTER_STRUCTURE',
    value: TYPE_CHART_STRUCTURE.STRUCTURE,
  },
  {
    title:
      'economy.importExport.importExport.exportImportByCommodities.FILTER_GROWTH',
    value: TYPE_CHART_STRUCTURE.GROWTH,
  },
]

export const COLOR_STRUCTURE_CHART = [
  '#185999',
  '#40abff',
  '#fff6df',
  '#fe7e6d',
  '#facc5c',
  '#45b29d',
]

export const COLOR_GROWTH_CHART = [
  '#e61a00',
  '#45b29d',
  '#facc5c',
  '#fff6df',
  '#40abff',
  '#03d7fc',
  '#fc03f4',
  '#90fc03',
  '#628200',
  '#3452eb',
  '#eb3462',
  '#9b8e9e',
]
