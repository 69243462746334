import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import SelectCustom from '../../../../../common/selectCustom'
import BusinessInformation from '../../corporateInfo'
import { selectVisible, updateVisible } from '../../corporateInfo/store/slice'
import {
  selectfilterLevel,
  updatefilterLevel,
} from '../../filter/FilterOption/store/slice'
import { listLevel } from './constants'
import style from './index.module.css'
import RangeFilter from './rangeFilter'

const Filter = () => {
  const dispatch = useDispatch()
  const level = useSelector(selectfilterLevel)
  const visible = useSelector(selectVisible)

  const openPopupFilter = () => {
    dispatch(updateVisible(true))
  }

  return (
    <>
      <div className="d-flex j-b">
        <div className={style.select}>
          <div style={{ width: 100 }}>
            <SelectCustom
              value={level}
              selectData={listLevel}
              handleChange={(e) => dispatch(updatefilterLevel(e))}
              isI18n={true}
            />
          </div>
          <RangeFilter />
        </div>
        <div>
          <Button
            className={`btn btn-blue w-148 h-20`}
            style={{ fontSize: 11, fontWeight: 500, color: '#FFF' }}
            onClick={() => openPopupFilter()}
          >
            <Translate value="bond.interconnectionMap.businessInformation.btnOpenPopup" />
          </Button>
        </div>
      </div>
      {visible && <BusinessInformation />}
    </>
  )
}

export default Filter
