import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../html/Span'
import { Loading } from '../../../loading'
import { ScrollComponent } from '../../../ScrollComponent'
import { SizeTracker } from '../../../sizeTracker'
import { SizeTrackerContainer } from '../../../sizeTracker/SizeTrackerContainer'
import { Item } from '../../item'
import style from './index.module.css'
import { Tag } from './Tag'

export const Right = ({ isLoading, onAction, data, title, hasTag }) => {
  return (
    <div
      className="position-relative h-100"
      style={{ width: '30%', marginLeft: 10 }}
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <SizeTrackerContainer>
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <div className={style.title}>
                    <Span style={{ fontSize: 18 }}>
                      <Translate value={title} />
                    </Span>
                  </div>
                  {size.height && (
                    <div style={{ height: `calc(100% - ${size.height}px)` }}>
                      <ScrollComponent>
                        {hasTag &&
                          Object.keys(data).map((key) => {
                            return (
                              <div style={{ marginBottom: 26 }} key={key}>
                                <Tag text={key} />
                                {data[key].map((item, index) => {
                                  return (
                                    <div
                                      className={style.itemContainer}
                                      style={{
                                        marginTop: index === 0 ? 5 : 16,
                                      }}
                                      key={index}
                                    >
                                      <Item
                                        item={item}
                                        lineClamp={2}
                                        onAction={onAction(item.newsId)}
                                        isShowDesc={false}
                                        titleLineClamp={10}
                                        titleFontSize={12}
                                        titleFontWeight={400}
                                      />
                                    </div>
                                  )
                                })}
                              </div>
                            )
                          })}
                        {!hasTag &&
                          data.map((item, index) => {
                            return (
                              <div
                                className={style.itemContainer}
                                style={{ marginBottom: 16 }}
                                key={index}
                              >
                                <Item
                                  item={item}
                                  lineClamp={2}
                                  onAction={onAction(item.newsId)}
                                  isShowDesc={false}
                                  titleLineClamp={10}
                                  titleFontSize={12}
                                  titleFontWeight={400}
                                />
                              </div>
                            )
                          })}
                      </ScrollComponent>
                    </div>
                  )}
                </>
              )
            }}
          </SizeTracker>
        </SizeTrackerContainer>
      )}
    </div>
  )
}

Right.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
  hasTag: PropTypes.bool,
}

Right.defaultProps = {
  hasTag: false,
}
