import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { IconDoubleArrowLeft } from '../../../common/icons/iconDoubleArrowLeft'
import { IconDoubleArrowRight } from '../../../common/icons/iconDoubleArrowRight'
import { TooltipInfo } from '../../../common/tooltipInfo'
import { Z_INDEX } from '../../../constants/Common'
import Sort from './Sort'
import { sortStateMap } from './constants'
import style from './index.module.css'
import { changeKeySort, resetIds } from './store/slice'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const theadRef = useRef()

  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState('')

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
      ref={theadRef}
    >
      {tableHeaderColAttr.map((row, index) => {
        return (
          <tr key={index}>
            {row.map((col, index2) => {
              return (
                <th
                  onClick={
                    col.disableSort
                      ? undefined
                      : () =>
                          sortColumn({
                            [col.keySort]: sortStateMap[stateSort[col.keySort]],
                          })
                  }
                  key={index2}
                  {...col?.attrs}
                  className={`${
                    !col.additionText && style.verticalAlignInitial
                  } ${!col.isFirstRow && style.secondRow} ${
                    index2 === 0 && style.left
                  } ${col.isHover && style.onRowHover}`}
                >
                  <div
                    className={`d-flex ${
                      col.justifyContent || 'justify-content-end'
                    }`}
                    style={{ position: 'relative' }}
                  >
                    {col.isCollapseLeft &&
                      (col.isCollapse ? (
                        <IconDoubleArrowRight
                          className={`${style.tableIconArrow} ${style.tableIconLeft}`}
                          handleClick={col.onClick}
                        />
                      ) : (
                        <IconDoubleArrowLeft
                          className={`${style.tableIconArrow} ${style.tableIconLeft}`}
                          handleClick={col.onClick}
                        />
                      ))}
                    <div className="d-flex">
                      <div className="d-flex" style={{ gap: 4 }}>
                        <div>
                          {col.value.map((text, i) => (
                            <div
                              className={`d-flex ali-center ${col.justifyContentChild}`}
                              key={i}
                            >
                              <Span
                                className={style.theadContent}
                                style={{
                                  fontSize: 12,
                                  fontWeight: 600,
                                }}
                              >
                                <Translate value={text} />
                              </Span>
                            </div>
                          ))}
                          {col.additionText && (
                            <Span
                              className={style.additionText}
                              style={{ fontSize: 10 }}
                            >
                              <Translate value={col.additionText} />
                            </Span>
                          )}
                        </div>
                        {col.isHover && (
                          <div
                            className={style.tableIcon}
                            style={{ width: 15, flexShrink: 0 }}
                          >
                            <TooltipInfo
                              fontSize={12}
                              tooltip="bond.corporateBond.priceBoard.PRICE_NOTE"
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div>
                        )}

                        <Sort type={stateSort[col.keySort]} />
                      </div>
                      <div
                        style={{ height: 14 }}
                        className={`${style.tableIcon} ${style.tableIconMore}`}
                        onMouseEnter={() => setIsShow(col.name)}
                        onMouseLeave={() => setIsShow('')}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <i
                          className="icon-menu-dot-hor"
                          style={{ color: '#8B8B8B', fontSize: 14 }}
                        />
                        {isShow === col.name && (
                          <div className={`${style.dropdown}`}>
                            {col.sortBy &&
                              col.sortBy.map((item) => (
                                <div
                                  key={item.name}
                                  className={`${style.dropdownItem} ${
                                    item.name === col.keySort &&
                                    style.dropdownItemActive
                                  }`}
                                  onClick={() => {
                                    dispatch(
                                      changeKeySort({
                                        key: col.name,
                                        value: item.name,
                                      }),
                                    )

                                    dispatch(resetIds())
                                    setIsShow('')
                                  }}
                                >
                                  <Translate value={item.value} />
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {col.isCollapseRight &&
                      (col.isCollapse ? (
                        <IconDoubleArrowLeft
                          className={`${style.tableIconArrow} ${style.tableIconRight}`}
                          handleClick={col.onClick}
                        />
                      ) : (
                        <IconDoubleArrowRight
                          className={`${style.tableIconArrow} ${style.tableIconRight}`}
                          handleClick={col.onClick}
                        />
                      ))}
                  </div>
                  <div className={style.borderRight}></div>
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default TableHeader
