import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import CustomScatterLine from '../../../../common/chart/scatterLine'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import { CHARTS } from './constant'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const YAXIS_ID = 'left'

const ChartColumn = ({ data, width, height, type, keyXAxis, keyYAxis }) => {
  const formatData = data.map((item) => ({
    ...item,
    barKey: [item[keyYAxis.minBarKey], item[keyYAxis.maxBarKey]],
  }))

  const tickFormatter = (val, index) => {
    return data[index]?.isTTM ? 'YTD' : val
  }

  const renderTooltip = (tooltipData, decimalLengths) => {
    const isYTD = tooltipData?.isTTM
    const decimalLength = decimalLengths[YAXIS_ID] || 0
    const contentTooltip = [
      {
        label: I18n.t(
          `corporate.peerValuation.overview.chart.${CHARTS[type]}.max`,
        ),
        value: tooltipData[keyYAxis.maxBarKey],
      },
      {
        label: I18n.t(
          `corporate.peerValuation.overview.chart.${CHARTS[type]}.min`,
        ),
        value: tooltipData[keyYAxis.minBarKey],
      },
      {
        label: I18n.t(
          `corporate.peerValuation.overview.chart.${CHARTS[type]}.${
            isYTD ? 'current' : 'endYear'
          }`,
        ),
        value: tooltipData[keyYAxis.lineKey],
      },
      {
        label: I18n.t(
          `corporate.peerValuation.overview.chart.${CHARTS[type]}.${
            isYTD ? 'currentPeer' : 'endYearPeer'
          }`,
        ),
        value: tooltipData[keyYAxis.diamondKey],
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            {isYTD ? 'YTD' : tooltipData[keyXAxis]}
          </Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(el.value, decimalLength || 2)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={formatData}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      tickFormatter={tickFormatter}
      yAxis={[
        {
          id: YAXIS_ID,
          keys: Object.values(keyYAxis),
          isLineChart: true,
          orientation: 'left',
          tickNum: SETTINGS.yTickNum,
        },
      ]}
      renderCustomTooltip={renderTooltip}
    >
      <Bar
        isAnimationActive={false}
        yAxisId={YAXIS_ID}
        dataKey={'barKey'}
        barSize={getColumnSizeInBarChart(width, data.length, 25)}
        fill="#185999"
      />
      <Scatter
        yAxisId={YAXIS_ID}
        dataKey={keyYAxis.lineKey}
        fill="#facc5c"
        isAnimationActive={false}
        shape={
          <CustomScatterLine
            widthBar={getColumnSizeInBarChart(width, data.length, 33)}
          />
        }
      />
      <Scatter
        yAxisId={YAXIS_ID}
        dataKey={keyYAxis.diamondKey}
        fill="#228a9f"
        isAnimationActive={false}
        shape={<ScatterDiamondShape dataKey={keyYAxis.diamondKey} />}
      />
    </ChartContainer>
  )
}

export default ChartColumn
