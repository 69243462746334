import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TopInfo } from '../../../common/topInfo/indexTopInfo'
import Content from './Content'

export const DirectorDeal = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo
                title={'market.marketInDepth.directorDeal.LB_DIRECTOR_DEAL'}
              />
              {size.height && (
                <div
                  className={`row director-deal-page`}
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
