import { useEffect, useRef, useState } from 'react'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../utils/EventEmitter'
import { formatVal } from '../../../utils/Value'
import { positionDropdown } from '../../dropdown'
import useWindowDevicePixelRatio from '../../hooks/useWindowDevicePixelRatio'
import { SIZE_ZOOM_CHART, TICK_MARGIN_XAXIS } from '../constants'
import { getDecimalLengthYAxis, getRoundedTickValues } from '../helper'
import style from './index.module.css'
import TooltipZoomChart from './TooltipZoomChart'
import { ReactComponent as ZoomChartIcon } from './ZoomChartIcon.svg'

const widthResize = 0
const rangeMinMax = 50
let mouseStart = 0
let positionStart = 0
let keyPosition = ''
let minResize = 0
let maxResize = 0
let positionVertical = {}
let positionTop = 0
let positionBottom = 0

const ZoomY = ({
  margin,
  y,
  extend,
  setExtend,
  disabled,
  isLeft,
  height,
  heightXAxis,
  position,
  listTitleTooltipZoom,
  yTickNum,
}) => {
  const verticalBarRef = useRef()
  const minMaxResize = useRef()
  const topVerticalRef = useRef()
  const bottomVerticalRef = useRef()

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [positionResize, setPositionResize] = useState({
    topVertical: 0,
    bottomVertical: 0,
  })
  const [showTooltip, setShowTooltip] = useState(false)
  const [valueResize, setValueResize] = useState({
    firstPoint: '',
    finalPoint: '',
  })
  const [isFirstTime, setIsFirstTime] = useState(true)

  const changeDataTooltip = () => {
    const data = {
      ...extend,
      [isLeft ? 'leftY' : 'rightY']: y.map(([min, max]) => {
        const minVal =
          max -
          (positionVertical.bottomVertical / minMaxResize.current.maxVertical) *
            (max - min)
        const maxVal =
          max -
          (positionVertical.topVertical / minMaxResize.current.maxVertical) *
            (max - min)

        return [minVal, maxVal]
      }),
    }
    const result = { firstPoint: [], finalPoint: [] }
    if (isLeft) {
      data.leftY.forEach((element) => {
        const tickValues = getRoundedTickValues(
          [element[0], element[1]],
          yTickNum,
        )
        const decimalLength = getDecimalLengthYAxis(tickValues)
        result.finalPoint.push(formatVal(tickValues[0], decimalLength))
        result.firstPoint.push(
          formatVal(tickValues[tickValues.length - 1], decimalLength),
        )
      })
      setValueResize(result)
    } else {
      data.rightY.forEach((element) => {
        const tickValues = getRoundedTickValues(
          [element[0], element[1]],
          yTickNum,
        )
        const decimalLength = getDecimalLengthYAxis(tickValues)
        result.finalPoint.push(formatVal(tickValues[0], decimalLength))
        result.firstPoint.push(
          formatVal(tickValues[tickValues.length - 1], decimalLength),
        )
      })
      setValueResize(result)
    }
  }

  const zoomStart = (e, keyPositionParam, minResizeParam, maxResizeParam) => {
    mouseStart = formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true)
    positionStart = positionResize[keyPositionParam]
    keyPosition = keyPositionParam
    minResize = minResizeParam
    maxResize = maxResizeParam
    positionVertical = positionResize
    window.addEventListener('mousemove', zoomOpen)
    window.addEventListener('mouseup', zoomStop)
    window.addEventListener('touchmove', zoomOpen)
    window.addEventListener('touchend', zoomStop)
  }

  const zoomOpen = (e) => {
    const position =
      formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true) -
      mouseStart +
      positionStart
    if (position >= minResize && position <= maxResize) {
      positionVertical = { ...positionResize, [keyPosition]: position }
    } else if (position < minResize) {
      positionVertical = { ...positionResize, [keyPosition]: minResize }
    } else {
      positionVertical = {
        ...positionResize,
        [keyPosition]: maxResize,
      }
    }
    setPositionResize(positionVertical)
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setShowTooltip(true)
    changeDataTooltip()
  }

  const zoomStop = () => {
    setExtend({
      ...extend,
      [isLeft ? 'leftY' : 'rightY']: y.map(([min, max]) => {
        const minVal =
          max -
          (positionVertical.bottomVertical / minMaxResize.current.maxVertical) *
            (max - min)
        const maxVal =
          max -
          (positionVertical.topVertical / minMaxResize.current.maxVertical) *
            (max - min)

        return [minVal, maxVal]
      }),
    })
    setShowTooltip(false)
    window.removeEventListener('mousemove', zoomOpen)
    window.removeEventListener('mousemove', zoomBarMove)
    window.removeEventListener('mouseup', zoomStop)
    window.removeEventListener('touchmove', zoomOpen)
    window.removeEventListener('touchmove', zoomBarMove)
    window.removeEventListener('touchend', zoomStop)
  }

  const zoomBarMoveStart = (e) => {
    mouseStart = formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true)
    positionTop = positionResize.topVertical
    positionBottom = positionResize.bottomVertical
    minResize = minMaxResize.current.minVertical
    maxResize = minMaxResize.current.maxVertical
    positionVertical = positionResize
    window.addEventListener('mousemove', zoomBarMove)
    window.addEventListener('mouseup', zoomStop)
    window.addEventListener('touchmove', zoomBarMove)
    window.addEventListener('touchend', zoomStop)
  }

  const zoomBarMove = (e) => {
    const positionTopVertical =
      formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true) -
      mouseStart +
      positionTop
    const positionBottomVertical =
      formatWithZoom(e.clientY || e.touches?.[0]?.clientY || 0, true) -
      mouseStart +
      positionBottom
    const rangeTopBottom = positionBottom - positionTop
    if (
      positionTopVertical >= minResize &&
      positionBottomVertical <= maxResize
    ) {
      positionVertical = {
        topVertical: positionTopVertical,
        bottomVertical: positionBottomVertical,
      }
    } else if (positionTopVertical < minResize) {
      positionVertical = {
        topVertical: minResize,
        bottomVertical: minResize + rangeTopBottom,
      }
    } else {
      positionVertical = {
        topVertical: maxResize - rangeTopBottom,
        bottomVertical: maxResize,
      }
    }
    setPositionResize(positionVertical)
    setShowTooltip(true)
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    changeDataTooltip()
  }

  const resize = () => {
    const oldMinMaxResize = { ...minMaxResize.current }
    minMaxResize.current = {
      minVertical: 0,
      maxVertical: formatWithZoom(
        verticalBarRef.current.getBoundingClientRect().height - widthResize,
      ),
    }
    setPositionResize({
      topVertical:
        (positionResize.topVertical / oldMinMaxResize.maxVertical) *
          minMaxResize.current.maxVertical || 0,
      bottomVertical:
        (positionResize.bottomVertical / oldMinMaxResize.maxVertical) *
          minMaxResize.current.maxVertical || 0,
    })
  }

  const handleYChange = () => {
    if (positionResize.bottomVertical)
      setExtend({
        ...extend,
        [isLeft ? 'leftY' : 'rightY']: y.map(([min, max]) => {
          const minVal =
            max -
            (positionResize.bottomVertical / minMaxResize.current.maxVertical) *
              (max - min)

          const maxVal =
            max -
            (positionResize.topVertical / minMaxResize.current.maxVertical) *
              (max - min)

          return [minVal, maxVal]
        }),
      })
  }

  useEffect(() => {
    if (height > 0) {
      resize()
    }
  }, [height])

  useEffect(() => {
    handleYChange()
  }, [JSON.stringify(y)])

  useEffect(() => {
    if (
      verticalBarRef.current &&
      verticalBarRef.current.getBoundingClientRect().height &&
      isFirstTime
    ) {
      minMaxResize.current = {
        minVertical: 0,
        maxVertical: formatWithZoom(
          verticalBarRef.current.getBoundingClientRect().height - widthResize,
        ),
      }
      setPositionResize({
        topVertical: 0,
        bottomVertical: formatWithZoom(
          verticalBarRef.current.getBoundingClientRect().height - widthResize,
        ),
      })
      setIsFirstTime(false)
    }
  }, [verticalBarRef?.current?.getBoundingClientRect().height])

  return (
    <div
      className={`position-relative ${
        position === 'left' ? style.verticalBarLeft : style.verticalBarRight
      }`}
      ref={verticalBarRef}
      style={{
        marginTop: margin.top + 'px',
        height: `calc(${
          height - margin.top - margin.bottom - heightXAxis - TICK_MARGIN_XAXIS
        }px)`,
        display: disabled ? 'none' : 'block',
        left: margin.left,
      }}
      data-html2canvas-ignore="true"
    >
      <div
        style={{
          top: positionResize.topVertical,
          height:
            positionResize.bottomVertical - positionResize.topVertical + 'px',
        }}
        className={style.verticalResizeBar}
      />
      <div
        style={{
          top: positionResize.topVertical,
          height:
            positionResize.bottomVertical - positionResize.topVertical + 'px',
        }}
        className={style.verticalResizeBarCover}
        onTouchStart={zoomBarMoveStart}
        onMouseDown={zoomBarMoveStart}
      />
      <ZoomChartIcon
        className={style.verticalIconZoomChart}
        style={{
          top:
            (positionResize.topVertical + positionResize.bottomVertical) / 2 -
            SIZE_ZOOM_CHART / 2,
        }}
      />
      <div
        className={style.verticalResize}
        style={{ top: positionResize.topVertical }}
      >
        <TooltipZoomChart
          containerRef={topVerticalRef}
          isOpenTooltip={showTooltip}
          valueTooltip={valueResize.firstPoint}
          listTitleTooltipZoom={listTitleTooltipZoom}
          position={
            isLeft ? positionDropdown.LEFT_TOP : positionDropdown.RIGHT_TOP
          }
          isLeft={isLeft}
        />
      </div>
      <div
        ref={topVerticalRef}
        className={style.verticalResizeCoverTop}
        style={{ top: positionResize.topVertical - 10 }} //subtract cover div height
        onTouchStart={(e) =>
          zoomStart(
            e,
            'topVertical',
            minMaxResize.current.minVertical,
            positionResize.bottomVertical - rangeMinMax,
          )
        }
        onMouseDown={(e) =>
          zoomStart(
            e,
            'topVertical',
            minMaxResize.current.minVertical,
            positionResize.bottomVertical - rangeMinMax,
          )
        }
      />
      <div
        className={style.verticalResize}
        style={{ top: positionResize.bottomVertical }}
      >
        <TooltipZoomChart
          containerRef={bottomVerticalRef}
          isOpenTooltip={showTooltip}
          valueTooltip={valueResize.finalPoint}
          listTitleTooltipZoom={listTitleTooltipZoom}
          position={
            isLeft ? positionDropdown.LEFT_TOP : positionDropdown.RIGHT_TOP
          }
          isLeft={isLeft}
        />
      </div>
      <div
        ref={bottomVerticalRef}
        className={style.verticalResizeCoverBottom}
        style={{ top: positionResize.bottomVertical - 10 }} //subtract cover div height
        onTouchStart={(e) =>
          zoomStart(
            e,
            'bottomVertical',
            positionResize.topVertical + rangeMinMax,
            minMaxResize.current.maxVertical,
          )
        }
        onMouseDown={(e) =>
          zoomStart(
            e,
            'bottomVertical',
            positionResize.topVertical + rangeMinMax,
            minMaxResize.current.maxVertical,
          )
        }
      />
      <div />
    </div>
  )
}

export default ZoomY
