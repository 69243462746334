import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { HorizontalStackedBarSeries } from '../../../../common/chart/customCharts/horizontalStackedBarSeries'
import { Footer } from '../../../../common/chart/footer'
import { getTextWidth } from '../../../../common/chart/helper'
import { SizeTracker } from '../../../../common/sizeTracker'
import { PANEL_PADDING } from '../../../../constants/Common'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { listIconBarChart } from '../constants'
import style from '../index.module.css'

const SETTINGS = {
  padding: 30,
  marginBottom: 40,
  tickNumber: 5,
  fontSize: 12,
}

const fillColor = {
  buyUpVolume: '#3da967',
  sellDownVolume: '#e0505b',
  unidentifiedVolume: '#32a6e8',
}

export const HorizontalStackBarChartComponent = ({ data, width, height }) => {
  const fontSize = getFontSize(SETTINGS.fontSize)
  const maxLeftLabelWidth =
    Math.max(
      ...data.map((item) =>
        item.priceFrom !== item.priceTo
          ? getTextWidth(
              `${formatVal(item.priceFrom)} - ${formatVal(item.priceTo)}`,
              fontSize,
            )
          : getTextWidth(formatVal(item.priceFrom), fontSize),
      ),
    ) + SETTINGS.padding

  const getTooltipContentData = () => [
    {
      label: 'market.equityTrading.deepTransaction.timeSale.BU_VOLUME',
      keyValue: 'buyUpVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
    },
    {
      label: 'market.equityTrading.deepTransaction.timeSale.SD_VOLUME',
      keyValue: 'sellDownVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
    },
    {
      label: 'market.equityTrading.deepTransaction.timeSale.UNIDENTIFIED',
      keyValue: 'unidentifiedVolume',
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('market.equityTrading.deepTransaction.timeSale.THOUSAND')}`,
    },
  ]

  return (
    <div className={style.chartContainer}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <HorizontalStackedBarSeries
                    data={data}
                    width={width - PANEL_PADDING * 2}
                    height={height - size.height}
                    yAxisCompareKey="closePrice"
                    yAxisMaxKey="priceTo"
                    yAxisMinKey="priceFrom"
                    barKeys={[
                      'buyUpVolume',
                      'sellDownVolume',
                      'unidentifiedVolume',
                    ]}
                    margin={{
                      left: maxLeftLabelWidth,
                      bottom: SETTINGS.marginBottom,
                      right: 0,
                    }}
                    isShowTotal={true}
                    isFillColorYAxis={true}
                    tooltipContentData={getTooltipContentData()}
                    fillColor={fillColor}
                    tickFormat={(val) => formatVal(val, 0)}
                    formatYAxisLabel={(val) => formatVal(val)}
                  />
                </div>
              )}
              <div style={{ paddingTop: 10 }}>
                <Footer key={width} list={listIconBarChart} />
              </div>
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

HorizontalStackBarChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
