import { I18n } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../../common/DateTimeSpan'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import TextEllipsis from '../../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import {
  recommendationTypeById,
  recommendationTypeColor,
  tableFormatData,
  tableSchema,
} from '../constants'
import styles from '../index.module.css'

const getColorString = (value) => {
  return recommendationTypeColor[recommendationTypeById[value]]
}

const getColorNumber = (value) => {
  return value > 0 ? '#59c761' : value < 0 ? '#ff4752' : '#e59e2e'
}

const ColorCellType = ({ val, colorFunc, format }) => {
  return (
    <div style={{ color: colorFunc(val), whiteSpace: 'nowrap' }}>
      {format ? format(val) : val}
    </div>
  )
}

export const getTableSchema = (basicInfo, locale, getData) => {
  return Object.keys(tableSchema).map((key) => {
    const title = `corporate.peerValuation.consensus.${key}`
    const colId = tableSchema[key]
    const result = {
      colId,
      title,
    }

    const rightSchema = {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      disableSort: false,
    }

    if (tableSchema.RECOMMENDATION_TYPE_NAME === colId) {
      return {
        ...rightSchema,
        render: (val, rowId) => {
          return (
            <ColorCellType
              val={val}
              colorFunc={(_) => getColorString(getData(rowId))}
            />
          )
        },
      }
    }

    if (tableSchema.DFCP === colId) {
      return {
        ...rightSchema,
        thStyle: { ...rightSchema.thStyle },
        render: (val, rowId) => {
          return (
            <ColorCellType
              val={val}
              colorFunc={(_) => getColorNumber(val)}
              format={(val) => valToPercent(val || 0)}
            />
          )
        },
        renderTh: () => (
          <span style={{ whiteSpace: 'pre' }}>{I18n.t(title)}</span>
        ),
      }
    }

    const leftSchema = {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'left',
      },
      tdStyle: {
        textAlign: 'left',
      },
      disableSort: true,
    }

    if (tableSchema.TICKER === colId) {
      return {
        ...leftSchema,
        render: (_) => (
          <LinkTicker organizationId={basicInfo.organizationId}>
            {basicInfo.ticker}
          </LinkTicker>
        ),
      }
    }

    if (tableSchema.DATE === colId) {
      return {
        ...leftSchema,
        disableSort: false,
        render: (val) => <DateTimeSpan date={val} />,
      }
    }

    if (tableSchema.PRICE_TARGET === colId) {
      return {
        ...leftSchema,
        disableSort: false,
        render: (val) => formatVal((val || 0) / tableFormatData[colId]),
      }
    }

    if (tableSchema.REPORT === colId) {
      return {
        ...leftSchema,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td className={[className, styles.customTd].join(' ')} style={style}>
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    if (tableSchema.DOWNLOAD_REPORT === colId) {
      return {
        ...rightSchema,
        disableSort: true,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td className={className} style={style}>
            <div className="centerItems">
              <a
                href={val !== EMPTY_VALUE ? val : undefined}
                target="_blank"
                rel="noopener noreferrer"
                style={{ cursor: 'pointer' }}
              >
                <i className="icon-download" />
              </a>
            </div>
          </td>
        ),
      }
    }

    return leftSchema
  })
}
