export const overview = {
  OVERVIEW: 'Overview',
  LB_LISTED: 'Listed',
  LB_PRIVATE: 'Private',
  LB_ALL: 'All',
  NEWS_AND_EVENTS: 'News & Research',
  TOP_REVENUE: 'Top Revenue',
  SECTOR_EARNING_GROWTH: 'Sector’s Earning Growth',
  KEY_RATIOS: 'Key Ratios',
  TOP_COMPANIES_PERFORMANCE: 'Companies’ Performance',
  SEGMENTATION: 'Segmentation',
  NEWS: 'News',
  RESEARCH: 'Research',
  EVENT: 'Event',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  REVENUE: 'Revenue',
  PROFIT: 'Profit',
  PROFITABILITY: 'Profitability',
  FINANCIAL_STRENGTH: 'Financial Strength',
  MANAGEMENT_EFFICIENCY: 'Management Efficiency',
  ASSETS_QUALITY: 'Assets Quality',
  GROWTH: 'Growth',
  VALUATION: 'Valuation',
  NET_REVENUE: 'Net Revenue',
  NET_MARGIN: 'Net Profit Margin',
  PEER_NET_MARGIN: 'Peer’s Net Profit Margin',
  REVENUE_GROWTH: 'Revenue Growth',
  UNIT_PRICE: '(Billion VND)',
  LDR: 'LDR',
  LOAN_GROWTH: 'Loan Growth',
  DEPOSIT_GROWTH: 'Deposit Growth',
  LB_CHART_BANK: 'Bank’s Credit & Deposit',
  CREDIT_DEPOSIT: 'Credit & Deposit',
  MARGIN_LENDING: 'Margin Lending',
  YOY_GROWTH: 'YoY Growth',
  BILLION: ' Bn.',
  TOP_TOTAL_OPERATING_INCOME: 'Top Total Operating Income',
  ASSET_QUALITY: 'Asset Quality',
  NPL_RATIO: 'NPL Ratio',
  COVERAGE_RATIO: 'Loan-loss reserves/NPLs',
  LOAN_LOSS_PROVISIONS: 'Provisions/ Loans to customer',
  LDR_RATIO: 'LDR Ratio',
  CASA_RATIO: 'CASA Ratio',
  ST_FUNDING_TO_MLT_LOANS: 'Current Deposits for MLT Loan (%)',
  EFFICIENCY: 'Management Efficiency',
  INTEREST_AND_SIMILAR_INCOME: 'Interest and Similar Income (%)',
  FEES_AND_COMMISSION_INCOME: 'Fees and Commission Income (%)',
  COST_TO_INCOME_RATIO: 'Cost-to-Income Ratio',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES: 'General & Administrative Expenses (%)',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES_TABLE:
    'General & Administrative <br/>Expenses <i>(%)</i>',
  YIELD_ON_EARNING_ASSETS: 'Average Yield on Earning Assets (YOEA)',
  COST_OF_FUNDS: 'Average Cost of financing (COF)',
  NIM: 'NIM',
  AVERAGE_LOANS_GROWTH: 'Average Loans Growth (%YoY)',
  AVERAGE_DEPOSIT_GROWTH: 'Average Deposit Growth (%YoY)',
  INTEREST_INCOME_GROWTH: 'Interest Income Growth (%YoY)',
  NON_INTEREST_INCOME_GROWTH: 'Non-Interest Income Growth (%YoY)',
  PREPROVISION_INCOME_GROWTH: 'Preprovision Income Growth (%YoY)',
  AVERAGE_LOANS_GROWTH_TABLE: 'Average Loans <br/>Growth <i>(%YoY)</i>',
  AVERAGE_DEPOSIT_GROWTH_TABLE: 'Average Deposit <br/>Growth <i>(%YoY)</i>',
  INTEREST_INCOME_GROWTH_TABLE: 'Interest income <br/>Growth <i>(%YoY)</i>',
  NON_INTEREST_INCOME_GROWTH_TABLE:
    'Non-Interest income <br/>Growth <i>(%YoY)</i>',
  PREPROVISION_INCOME_GROWTH_TABLE:
    'Preprovision Income <br/>Growth <i>(%YoY)</i>',
  PE: 'P/E',
  PB: 'P/B',
  DIVIDEND_YIELD: 'Dividend Yield',
  GROSS_PROFIT_MARGIN: 'Gross Profit Margin',
  MARKET_CAP: 'Market Cap (Bn. VND)',
  YOEA: 'YOEA',
  COF: 'COF',
  ROA: 'ROA',
  ROE: 'ROE',
  NII_TOI: 'NII/TOI',
  NFI_TOI: 'NFI/TOI',
  CIR: 'CIR',
  COMPANY: 'Ticker',
  EV: 'EV (Billion VND)',
  EV_TABLE: 'EV <i>(Billion VND)</i>',
  PS: 'P/S',
  EBITDA_MARGIN: 'EBITDA Margin',
  NET_PROFIT_MARGIN: 'Net Profit Margin',
  CURRENT_RATIO: 'Current Ratio',
  CURRENT_RATIO_TABLE: 'Current Ratio',
  TOTAL_DEBT_OR_TOTAL_ASSETS: 'Total Debt/Total Assets',
  TOTAL_DEBT_OR_EQUITY: 'Total Debt Equity',
  TOTAL_DEBT_OR_EQUITY_TABLE: 'Total Debt/<br/>Equity',
  INTEREST_COVERAGE: 'Interest Coverage',
  INTEREST_COVERAGE_TABLE: 'Interest Coverage',
  ROIC: 'ROIC',
  CAPEX_OR_SALES: 'Capex/Sales',
  ASSET_TURNOVER: 'Asset turnover',
  EV_EBITDA: 'EV/EBITDA',
  NET_REVENUE_GROWTH_1_YEAR: 'Net Revenue Growth (1 year)',
  TOTAL_ASSETS_GROWTH_1_YEAR: 'Total Assets Growth (1 year)',
  OWNERS_EQUITY_GROWTH_1_YEAR: 'Owner’s Equity Growth (1 year)',
  NET_REVENUE_GROWTH_3_YEAR: 'Net Revenue Growth (3-year CAGR)',
  TOTAL_ASSETS_GROWTH_3_YEAR: 'Total Assets Growth (3-year CAGR)',
  OWNERS_EQUITY_GROWTH_3_YEAR: 'Owner’s Equity Growth (3-year CAGR)',
  NET_REVENUE_GROWTH_1_YEAR_TABLE: 'Net Revenue <br/>Growth ',
  TOTAL_ASSETS_GROWTH_1_YEAR_TABLE: 'Total Assets <br/>Growth',
  MARGIN_LENDING_OR_OWNERS_EQUITY: 'Margin Lending/Owner’s Equity',
  MARGIN_LENDING_OR_OWNERS_EQUITY_TABLE: 'Margin Lending/<br/>Owner’s Equity',
  MARGIN_LENDING_OR_TOTAL_ASSETS: 'Margin Lending/Total Assets',
  REVENUE_PER_EMPLOYEE: 'Equity Turnover',
  NET_INCOME_PER_EMPLOYEE: 'Asset Turnover',
  LOAN: 'Loan',
  DEPOSIT: 'Deposit',
  TOTAL_ASSET: 'Total Assets',
  BROKERAGE_REVENUE: 'Brokerage Revenue',
  EBIT_MARGIN: 'EBIT Margin',
  PRETAX_MARGIN: 'Pretax Margin',
  TOTAL_DEBT_EQUITY: 'Total Debt/Equity',
  MARGIN_LENDING_OWNER: 'Margin Lending/ Owner’s Equity',
  REVENUE_PER_EMPLOYEE_M: 'Equity Turnover',
  NET_INCOME_PER_EMPLOYEE_M: 'Asset Turnover',
  NET_REVENUE_GROWTH_YOY: 'Net Revenue Growth YoY',
  EBITDA_GROWTH_YOY: 'EBITDA Growth YoY',
  EBIT_GROWTH_YOY: 'EBIT Growth YoY',
  TOTAL_ASSETS_GROWTH_YOY: 'Total Assets Growth YoY',
  OWNERS_EQUITY_GROWTH_YOY: 'Owner’s Equity Growth YoY',
  EV_EBIT: 'EV/EBIT',
  CASH_CONVERSION_CYCLEL: 'Cash Conversion Cycle',
  TOTAL_ASSET_TURNOVER: 'Total Asset Turnover',
  ACCOUNT_RECEIVABLE_TURNOVER: 'Account Receivable Turnover',
  ACCOUNT_RECEIVABLE_TURNOVER_TABLE: 'Account Receivable <br/> Turnover ',
  INVENTORY_TURNOVER: 'Inventory Turnover',
  ACCOUNT_PAYABLE_TURNOVER: 'Account Payable Turnover',
  ACCOUNT_PAYABLE_TURNOVER_TABLE: 'Account Payable <br/> Turnover',
  CASH_CONVERSION_CYCLE: 'Cash Conversion Cycle',
  CASH_CONVERSION_CYCLE_TABLE: 'Cash Conversion <br/> Cycle <i>(D)</i>',
  MEDIAN: 'Median',
  MEAN: 'Mean',
  SECTOR: 'Sector',
  OTC: 'OTC',
  ANNUAL: 'Annual',
  ST_FUNDING_TABLE: 'Current Deposits <br>for MLT Loan (%)',
  CASH_RATIO: 'Cash Ratio',
  QUICK_RATIO: 'Quick Ratio',
  EBIT_GROWTH: 'EBIT Growth',
  OWNERS_EQUITY_GROWTH: 'Owner’s Equity Growth',
  TOTAL_ASSETS_GROWTH: 'Total Assets Growth',
  NET_REVENUE_GROWTH: 'Net Revenue Growth',
  EBITDA_GROWTH: 'EBITDA Growth',
  TOTAL_OPERATING_INCOME: 'Total Operating Income',
  TRILLION_VND: '(Trillion VND)',
  SEGMENTATION_REVENUE: 'Revenue Segmentation',
  COVERAGE_RATIO_TABLE: 'Loan-loss reserves/<br/>NPLs',
  LOAN_LOSS_PROVISIONS_TABLE: 'Provisions/<br/> Loans to customer',
  CAPEX_OR_SALES_TABLE: 'Capex/<br/>Sales',
  CASH_RATIO_TABLE: 'Cash Ratio',
  QUICK_RATIO_TABLE: 'Quick Ratio',
  COMPANY_NAME: 'Company Name',
  PE_FORWARD: 'P/E Forward',
  BVPS: 'BVPS',
  TRILLION_VND_TOOLTIP: ' Trl.',
  PERCENT_LDR: 'LDR',
  PERCENT_GROWTH: 'Growth',
}

export const newsReport = {
  SECTOR_NEWS: 'Sector News',
  SECTOR_REPORTS: 'Sector Reports',
  LATEST_NEWS: 'Latest News',
  DATE: 'DATE',
  ISSUER: 'ISSUER',
  TITLE: 'TITLE',
  ACTION: ' ',
  SAME_CATEGORY: 'Same Category',
  DETAIL: 'Detail',
  EARNING: 'Earning',
  TRANSACTIONS: 'Transactions',
  OTHER_BUSINESS_ACTIVITIES: 'Other Business Activities',
  ISSUER_TOOLTIP: 'ISSUER',
}

export const ranking = {
  RANKING: 'Ranking',
  RANK_VALUE: 'Rank',
  TICKER: 'Ticker',
  COMPANY_NAME: 'Company Name',
  EXCHANGE: 'Exchange',
  SECTOR: 'Sector',
  MARKET_CAP: 'Market Cap (Billion VND)',
  VALUE: 'Value',
  GROWTH: 'Growth',
  MOMENTUM: 'Momentum',
  VGM: 'VGM',
  RANKING_STATUS: 'Ranking History',
  ALL_EXCHANGE: 'All Exchange',
  VALUE_SCORE: 'Value Score',
  GROWTH_SCORE: 'Growth Score',
  MOMENTUM_SCORE: 'Momentum Score',
  VGM_SCORE: 'VGM Score',
  FIINX_RANKING: 'FiinProX Rank',
  BANK: 'Bank',
  VN30: 'VN30',
  NOTE_CHART_RANKING:
    'Ranking value of 5 consecutive ranking periods (including current period)',
  NOTE_TABLE_RANKING:
    'For more details on how to utilize FiinProX Stock Ranking, ',
  CLICK_HERE: 'click here',
}

export const valuation = {
  NAME: 'Valuation',
  FILTER: 'Filter',
  EXCHANGE: 'Exchange',
  ALL_EXCHANGE: 'All Exchange',
  ALL: 'All',
  HOSE: 'HOSE',
  HNX: 'HNX',
  UPCOM: 'UPCoM',
  INDICATOR: 'Indicator',
  indicators: {
    basicPE: 'Basic P/E',
    marketCap: 'Market Cap',
    ev: 'EV',
    pS: 'P/S',
    pB: 'P/B',
    pCfo: 'P/CFO',
    evRevenue: 'EV/Revenue',
    evEbitda: 'EV/EBITDA',
    evEbit: 'EV/EBIT',
    evFcff: 'EV/FCFF',
    evFcfe: 'EV/FCFE',
  },
  COMPARISION: 'Comparison',
  ADD_COMPARISION: 'Add Sector, Company',
  COMPARE: 'COMPARE',
  SECTOR: 'Sector',
  SECTOR_LEVEL_1: 'Level 1',
  SECTOR_LEVEL_2: 'Level 2',
  SECTOR_LEVEL_3: 'Level 3',
  SECTOR_LEVEL_4: 'Level 4',
  SECTOR_LEVEL_5: 'Level 5',
  SELECTED_ITEMS: 'Selected items',
  SELECTED_COUNT: 'Selected:',
  MAX_ITEMS_TEXT: 'The number of selected items is no more than 20 items.',
  RESET: 'Reset',
  VALUATION_CHART: 'Valuation Chart',
  VALUATION_CHART_TITLE: ' ',
  PRICE_CHART: 'Price Chart',
  PRICE_CHART_TITLE: 'Sectors Index & Stocks Price Comparison',
  RELATIVE: 'Relative',
  ABSOLUTE: 'Absolute',
  THOUSAND_VND: '(Thousand VND)',
  THOUSAND_VND_TOOLTIP: 'Thousand',
  TRILLION_VND: '(Trillion VND)',
  TRILLION_VND_TOOLTIP: 'Trl.',
  POINT: '(Point)',
  POINT_TOOLTIP: 'Point',
  DATA_TABLE: 'Data Table',
  dataTable: {
    name: 'Sector/Ticker',
    marketCap: 'Market Cap <p>(Billion VND)</p>',
    pE: 'P/E',
    pB: 'P/B',
    bvps: 'BVPS',
    peForward: 'P/E Forward',
  },
  VALUATION_REPORT: 'Valuation Report',
  valuationReport: {
    date: 'Date',
    name: 'Sector/Ticker',
    issuer: 'Issuer',
    title: 'Report Name',
    download: ' ',
  },
}

export const ratio = {
  FILTER: 'Filter',
  EXCHANGE: 'Exchange',
  MARKET_CAP: 'Market Cap (Million VND)',
  COMPANY: 'Company',
  SEARCH: 'Search',
  FROM: 'From',
  TO: 'To',
  ALL_EXCHANGE: 'All Exchange',
  RATIO: 'RATIO',
}

export const segmentation = {
  FILTER: 'Filter',
  SCATTER_PLOT: 'Scatter Plot',
  MAX_TEMPLATE_1: 'The number of personal templates is no more than 100.',
  MAX_TEMPLATE_2: 'Please try again!',
  TEMPLATE_NAME_TOO_SHORT: 'Template name is too short.',
  TEMPLATE_EXIST: 'Template name is already existed. Please try again!',
  TEMPLATE_REQUIRE: 'Please enter the template name.',
  BTN_SAVE_AS: 'Save as',
  LABEL_NAME_TEMPLATE: 'Name of template:',
  SEARCH: 'Search',
  CHOOSE_COMPANY: 'Companies & Group of companies',
  SELECTED_COUNT: 'Selected:',
  MAX_ITEMS_TEXT:
    'The number of selected companies in <i>Time Series</i> chart is no more than 500.',
  listType: {
    COMPANY: 'Companies',
    WATCHLIST: 'Watchlist',
    INDEX: 'Indices',
    SECTOR: 'Sectors',
  },
  SECTOR_LEVEL_1: 'Level 1',
  SECTOR_LEVEL_2: 'Level 2',
  SECTOR_LEVEL_3: 'Level 3',
  SECTOR_LEVEL_4: 'Level 4',
  SECTOR_LEVEL_5: 'Level 5',
  QUARTER_1: 'Quarter 1',
  QUARTER_2: 'Quarter 2',
  QUARTER_3: 'Quarter 3',
  QUARTER_4: 'Quarter 4',
  BUBBLE_SIZE: 'Bubble size',
  bubbleSize: {
    TOTAL_ASSETS: 'Total Assets',
    MARKET_CAP: 'Market Cap',
    VOLUMN_1W: 'Avg. Volume 1W',
    VOLUMN_1M: 'Avg. Volume 1M',
    VOLUMN_3M: 'Avg. Volume 3M',
    VOLUMN_6M: 'Avg. Volume 5M',
    VOLUMN_1Y: 'Avg. Volume 1Y',
    CONSTRAINT: 'Constraint',
  },
  BUBBLE_COLOR: 'Bubble color',
  bubbleColor: {
    GROUP: 'By Group',
    COMPANY: 'By company',
    CONSTRAINT: 'Constraint',
  },
  NOT_VALID_TICKER:
    'There are companies not in the Exchange you chose or in the range of Market Cap. Please choose another companies or filter again.',
  SELECTED_ITEMS: 'Selected items',
  EXCHANGES: 'Exchanges',
  exchanges: {
    ALL_EXCHANGE: 'All Exchanges',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
    OTC: 'OTC',
    PRIVATE: 'Private',
  },
  NO_EXCHANGE: 'Select Exchange',
  MARKET_CAP_LABEL: 'Market Cap (Trillion VND)',
  TEMPLATE_LABEL: 'Template',
  CHOOSE_TEMPLATE: 'Choose Template',
  RENAME: 'Rename',
  COPPY_LINK: 'Coppy Link',
  DELETE: 'Delete',
  TITLE_DELETE_TEMPLATE: 'Delete template',
  ASK_DELETE_TEMPLATE: 'Are you sure to delete ',
  SCATTER_PLOT_TYPE: 'Scatter Plot Type',
  scatterPlotType: {
    POINT_IN_TIME: 'Point in Time',
    TIME_SERIES: 'Time Series',
  },
  timeSeriesType: {
    RANGE: 'Date Range',
    YEAR: 'Full Year',
  },
  TIME_RANGE: 'Time Range',
  X_ASIS: 'X-asis',
  Y_ASIS: 'Y-asis',
  CHOOSE_INDICATOR: 'Choose Indicator',
  FROM: 'From',
  TO: 'To',
  YEAR: 'Year',
  CATEGORY: 'Category',
  METRIC: 'Metric',
  NO_TEMPLATE: 'No Templates.',
  INVALID_TEMPLATE:
    'Error: Template does not exist or has been deleted. Please choose another template.',
  SHOW_TICKER: 'Show Ticker/Taxcode',
  ALL: 'All',
}
