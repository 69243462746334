export const dashboardTypes = {
  CHART_OF_THE_DAY: 'chart_of_the_day',
  MY_DASHBOARD: 'my_dashboard',
  DASHBOARD_TEMPLATE: 'dashboard_template',
  MY_CHART_LIBRARY: 'my_chart_library',
  NEW_DASHBOARD: 'new_dashboard',
}

export const screenTypes = {
  DASHBOARD: 'dashboard',
  NEW_CHART: 'new_chart',
  EDIT_CHART: 'edit_chart',
  SETTING_CREATE_CHART: 'setting_create_chart',
}

export const userSettingKeys = {
  DEFAULT_DASHBOARD: 'defaultDashboard',
  FAVORITE_COLORS: 'favoriteColors',
  DEFAULT_CHART_SETTINGS: 'defaultChartSettings',
}
