import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class FisheryProxy extends ProxyBase {
  getFisheryProfile(params) {
    return this.get('Profile', params)
  }

  getReports(params) {
    return this.get('Reports', params)
  }

  getNews(params) {
    return this.get('News', params)
  }

  getOverviewIds(params) {
    return this.get('Ids', params, {}, true)
  }

  getExportYear(params) {
    return this.get('ExportYear', params)
  }

  getImportYear(params) {
    return this.get('ImportYear', params)
  }

  getTopExportMarketsOfVN(params) {
    return this.get('TopExportMarketsOfVN', params)
  }

  getExportStatisticsByMarket(params) {
    return this.get('ExportStatisticsByMarket', params)
  }

  getExportStatisticsByProduct(params) {
    return this.get('ExportStatisticsByProduct', params)
  }

  getExportGrowthByProduct(params) {
    return this.get('ExportGrowthByProduct', params)
  }

  getExportGrowthByMarket(params) {
    return this.get('ExportGrowthByMarket', params)
  }

  getExportContributionByProduct(params) {
    return this.get('ExportContributionByProduct', params)
  }

  getExportContributionByMarket(params) {
    return this.get('ExportContributionByMarket', params)
  }

  getImportStatisticsByProduct(params) {
    return this.get('ImportStatisticsByProduct', params)
  }

  getImportStatisticsByMarket(params) {
    return this.get('ImportStatisticsByMarket', params)
  }

  getImportGrowthByProduct(params) {
    return this.get('ImportGrowthByProduct', params)
  }

  getImportGrowthByMarket(params) {
    return this.get('ImportGrowthByMarket', params)
  }

  getImportContributionByProduct(params) {
    return this.get('ImportContributionByProduct', params)
  }

  getImportContributionByMarket(params) {
    return this.get('ImportContributionByMarket', params)
  }

  getExportDownloadStatisticsByMarket(params) {
    return this.get('ExportDownloadStatisticsByMarket', params, 'download')
  }

  getExportDownloadStatisticsByProduct(params) {
    return this.get('ExportDownloadStatisticsByProduct', params, 'download')
  }

  getImportDownloadStatisticsByMarket(params) {
    return this.get('ImportDownloadStatisticsByMarket', params, 'download')
  }

  getImportDownloadStatisticsByProduct(params) {
    return this.get('ImportDownloadStatisticsByProduct', params, 'download')
  }

  getExporterStatistics(params) {
    return this.get('ExporterStatistics', params)
  }

  getExporterGrowth(params) {
    return this.get('ExporterGrowth', params)
  }

  getTopExporters(params) {
    return this.get('TopExporters', params)
  }

  getMaxDate(params) {
    return this.get('MaxDate', params, null, true)
  }

  getExporterDownloadStatistics(params) {
    return this.get('ExporterDownloadeStatistics', params, 'download')
  }
}

export default new FisheryProxy(
  ServiceProxyConfig.Sector.SectorSpecific.Fishery.ServiceUrl,
)
