import React from 'react'

const pathShowErrors = ['/fund']

export class ErrorBoundary extends React.Component {
  componentDidCatch(e) {
    if (
      process.env.NODE_ENV === 'production' &&
      process.env.REACT_APP_ENV === 'production' &&
      !pathShowErrors.some((path) => window.location.pathname.includes(path))
    ) {
      window.location.href = '/error'
    }
  }

  render() {
    return this.props.children
  }
}
