export const ALL_PUBLIC_PRIVATE_TABS = [
  {
    title: 'bond.corporateBond.primaryMarket.ALL',
    value: 'All',
  },
  {
    title: 'bond.corporateBond.primaryMarket.PUBLIC',
    value: 'Public',
  },
  {
    title: 'bond.corporateBond.primaryMarket.PRIVATE',
    value: 'Private',
  },
]

export const MIN_DATE_FILTER = 2009

//Secondary market
export const TIME_FREQUENCY = {
  D: 'OneDay',
  WTD: 'WeekToDate',
  MTD: 'MonthToDate',
  QTD: 'QuarterToDate',
  YTD: 'YearToDate',
}
export const TIME_FREQUENCY_TRADING_VALUE = {
  D: 'Daily',
  W: 'Weekly',
  M: 'Monthly',
  Q: 'Quarterly',
  Y: 'Yearly',
}

export const TIME_FREQUENCY_TABS = [
  {
    title: 'D',
    value: TIME_FREQUENCY.D,
  },
  {
    title: 'WTD',
    value: TIME_FREQUENCY.WTD,
  },
  {
    title: 'MTD',
    value: TIME_FREQUENCY.MTD,
  },
  {
    title: 'QTD',
    value: TIME_FREQUENCY.QTD,
  },
  {
    title: 'YTD',
    value: TIME_FREQUENCY.YTD,
  },
]
export const TIME_FREQUENCY_TABS_TRADING_VALUE = [
  {
    title: 'bond.corporateBond.benchmarkingSecondary.D',
    value: TIME_FREQUENCY_TRADING_VALUE.D,
  },
  {
    title: 'bond.corporateBond.benchmarkingSecondary.WTD',
    value: TIME_FREQUENCY_TRADING_VALUE.W,
  },
  {
    title: 'bond.corporateBond.benchmarkingSecondary.MTD',
    value: TIME_FREQUENCY_TRADING_VALUE.M,
  },
  {
    title: 'bond.corporateBond.benchmarkingSecondary.QTD',
    value: TIME_FREQUENCY_TRADING_VALUE.Q,
  },
  {
    title: 'bond.corporateBond.benchmarkingSecondary.YTD',
    value: TIME_FREQUENCY_TRADING_VALUE.Y,
  },
]
export const TIME_FREQUENCY_TABS_TRADING = [
  {
    title: 'D',
    value: TIME_FREQUENCY_TRADING_VALUE.D,
  },
  {
    title: 'W',
    value: TIME_FREQUENCY_TRADING_VALUE.W,
  },
  {
    title: 'M',
    value: TIME_FREQUENCY_TRADING_VALUE.M,
  },
  {
    title: 'Q',
    value: TIME_FREQUENCY_TRADING_VALUE.Q,
  },
  {
    title: 'Y',
    value: TIME_FREQUENCY_TRADING_VALUE.Y,
  },
]

export const listRemainingDurationType = [
  { name: 'bond.corporateBond.marketTradingValueBySector.ALL', value: 'All' },
  {
    name: 'bond.corporateBond.marketTradingValueBySector.LessThan1Year',
    value: 'LessThan1Year',
  },
  {
    name: 'bond.corporateBond.marketTradingValueBySector.From1To3Years',
    value: 'From1To3Years',
  },
  {
    name: 'bond.corporateBond.marketTradingValueBySector.From3To5Years',
    value: 'From3To5Years',
  },
  {
    name: 'bond.corporateBond.marketTradingValueBySector.From5To7Years',
    value: 'From5To7Years',
  },
  {
    name: 'bond.corporateBond.marketTradingValueBySector.AboveThan7Years',
    value: 'AboveThan7Years',
  },
]

export const CORRELATION_TYPE_RADIOS = [
  {
    name: 'bond.corporateBond.correlationOfSecondaryMarket.SECTOR',
    value: true,
  },
  {
    name: 'bond.corporateBond.correlationOfSecondaryMarket.ORGANIZATION',
    value: false,
  },
]
export const MARKET_TRADING_VALUE = [
  {
    name: 'bond.corporateBond.marketTradingValue.ISSUE_METHOD',
    value: false,
  },
  {
    name: 'bond.corporateBond.marketTradingValue.TRADING_METHOD',
    value: true,
  },
]

export const MONTHS = [
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_1',
    value: 1,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_2',
    value: 2,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_3',
    value: 3,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_4',
    value: 4,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_5',
    value: 5,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_6',
    value: 6,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_7',
    value: 7,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_8',
    value: 8,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_9',
    value: 9,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_10',
    value: 10,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_11',
    value: 11,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_12',
    value: 12,
  },
]

export const QUARTER = [
  {
    name: 'Q1',
    value: 1,
  },
  {
    name: 'Q2',
    value: 2,
  },
  {
    name: 'Q3',
    value: 3,
  },
  {
    name: 'Q4',
    value: 4,
  },
]

export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#185999',
  '#ECFFDF',
  '#A6CF98',
]

export const RELEASE_TERM = [
  {
    name: 'bond.corporateBond.primaryMarket.ALL',
    value: 'All',
  },
  {
    name: 'bond.corporateBond.primaryMarket.ONE_TWO_THREE_YEARS',
    value: 'OneToThreeYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.THREE_TWO_FIVE_YEARS',
    value: 'ThreeToFiveYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.FIVE_TO_SEVEN_YEARS',
    value: 'FiveToSevenYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.OVER_SEVEN_YEAR',
    value: 'OverSevenYears',
  },
]

export const BAR_COLOR_CORRELATION_OF_MARKET = ['#0096EB', '#FFFFFF']

export const BAR_COLOR_MARKET_TRADING_VALUE = [
  '#40ABFF',
  '#FACC5C',
  '#FACC5C',
  '#FFF6DF',
  '#FA9AA7',
]

export const GROUP_ROWS = [
  {
    key: 'name',
    title: 'bond.corporateBond.issuanceValue.INDUSTRY',
    keySort: 'name',
  },
  {
    key: 'totalValue',
    title: 'bond.corporateBond.sectorStatistic.TOTAL_VALUE',
    additionText: 'bond.corporateBond.sectorStatistic.UNIT',
    keySort: 'totalValue',
    alignRight: true,
  },
  {
    key: 'totalValuePublic',
    title: 'bond.corporateBond.sectorStatistic.TOTAL_VALUE_OF_PUBLIC_BOND',
    additionText: 'bond.corporateBond.sectorStatistic.UNIT',
    keySort: 'totalValuePublic',
    alignRight: true,
  },
  {
    key: 'totalValuePrivate',
    title: 'bond.corporateBond.sectorStatistic.TOTAL_VALUE_OF_PRIVATE_BOND',
    additionText: 'bond.corporateBond.sectorStatistic.UNIT',
    keySort: 'totalValuePrivate',
    alignRight: true,
  },
  {
    key: 'averageRemainDuration',
    title: 'bond.corporateBond.sectorStatistic.AVG_REMAIN_DURATION',
    additionText: 'bond.corporateBond.sectorStatistic.YEAR',
    keySort: 'averageRemainDuration',
    alignRight: true,
  },
  {
    key: 'averageYTM',
    title: 'bond.corporateBond.sectorStatistic.AVG_YTM',
    additionText: 'bond.corporateBond.sectorStatistic.PERCENT',
    keySort: 'averageYTM',
    alignRight: true,
  },
]

export const GROUP_COLUMNS = [
  {
    key: 'id',
    title: '',
  },
  {
    key: 'summary',
    title: 'bond.corporateBond.issuanceValue.SUMMARY',
  },
  {
    key: 'fixed',
    title: 'bond.corporateBond.issuanceValue.FIXED',
  },
  {
    key: 'float',
    title: 'bond.corporateBond.issuanceValue.FLOATING_AND_COMBINE',
  },
  {
    key: 'other',
    title: 'bond.corporateBond.issuanceValue.OTHER',
  },
]

export const COUPON_TYPES = {
  OTHERS: 0,
  FLOAT: 1,
  ZERO: 2,
  MIXED: 3,
  FIXED: 4,
}
