import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import {
  getSchema,
  GROUP_COLUMNS,
  rowSpanSchema,
} from '../../common/tableStatistics/constants'
import { FilterList } from '../../common/tableStatistics/FilterList'
import { getLatestPeriods } from '../../construction/steelStatistics/store/thunk'
import {
  changeCurrentCompany,
  changeIsYearlyChart,
  selectCurrentCompany,
} from '../companyGrowth/store/slice'

import {
  addItemToList,
  changeActiveCompany,
  changeCurrentTypeStatistics,
  changeIsAcc,
  changeIsYearly,
  changeLocale,
  changeMonthCoilTab,
  changeYearCoilTab,
  keepRowsCollapse,
  removeItemFromList,
  selectActiveCompany,
  selectCurrentMonth,
  selectCurrentTypeStatistic,
  selectCurrentYear,
  selectData,
  selectDataTable,
  selectDefaultMaxMonthCoilTab,
  selectDefaultMaxYearCoilTab,
  selectIds,
  selectIdsCompanyOnly,
  selectIsAcc,
  selectIsChangeFilter,
  selectIsYearly,
  selectListAllCompanies,
  selectListCompany,
  selectLoading,
  selectRowsCollapse,
  setRowsCollapse,
  sort,
} from './store/slice'
import { getSteelCoilStatistics } from './store/thunk'

const LIMIT_SELECTION = 5
const HORIZONTAL_TRACK_LEFT = 28
const tableId = 'steelCoilStatistics'

export const SteelStatistics = ({ width, height }) => {
  const dispatch = useDispatch()

  const data = useSelector(selectData)
  const ids = useSelector(selectIds)
  const listCompany = useSelector(selectListCompany)
  const isLoading = useSelector(selectLoading)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const isAcc = useSelector(selectIsAcc)
  const isYearly = useSelector(selectIsYearly)
  const isChangeFilter = useSelector(selectIsChangeFilter)
  const activeCompany = useSelector(selectActiveCompany)
  const idsCompanyOnly = useSelector(selectIdsCompanyOnly)
  const listCompanies = useSelector(selectListAllCompanies)
  const currentCompanyInChart = useSelector(selectCurrentCompany)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const currentTypeStatistics = useSelector(selectCurrentTypeStatistic)
  const defaultMaxMonth = useSelector(selectDefaultMaxMonthCoilTab)
  const defaultMaxYear = useSelector(selectDefaultMaxYearCoilTab)
  const locale = useSelector((state) => state.i18n.locale)

  const [isShowWarning, setIsShowWarning] = useState(false)

  const activeCompanyId = activeCompany?.id ?? listCompany[0]?.id

  const onRowClick = (rowId) => {
    if (idsCompanyOnly?.some((item) => item === rowId)) {
      if (!listCompany?.some((item) => item.organizationId === rowId)) {
        if (listCompany.length < LIMIT_SELECTION) {
          const item = {
            organizationId: rowId,
            name: data?.find((item) => item.organizationId === rowId)?.name,
          }
          dispatch(addItemToList(item))
        } else {
          setIsShowWarning(true)
        }
      } else {
        dispatch(removeItemFromList(rowId))
      }
    }
  }

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
    }
  }

  const itemWithCollapse = data?.map((item) => ({
    ...item,
    isCollapsible: item.isGroupRow,
  }))

  const levelCollapse = keyBy(
    itemWithCollapse?.map((item) => ({
      id: item.id,
      level: item.isCollapsible ? 1 : 2,
      isCollapsible: item.isCollapsible,
    })),
    'id',
  )

  const schema = getSchema(onRowCollapse, rowsCollapse, data, listCompany)

  const getTableHeadRowTop = () => {
    const tableHeadRowTop = {}
    GROUP_COLUMNS.slice(3, 8).forEach((element) => {
      tableHeadRowTop[element.key] = ['consumptionTotal']
    })
    return tableHeadRowTop
  }

  useEffect(() => {
    dispatch(getLatestPeriods())
  }, [])

  //choose monthly and acc_monthly on filter tab
  useEffect(() => {
    if (!isYearly && currentMonth && currentYear) {
      dispatch(
        getSteelCoilStatistics({
          Year: currentYear,
          Month: currentMonth,
          Accumulated: isAcc,
        }),
      )
    }
  }, [currentMonth, currentYear, isAcc, isYearly, locale])

  //choose yearly on filter tab
  useEffect(() => {
    if (isYearly && currentYear) {
      dispatch(
        getSteelCoilStatistics({
          Year: currentYear,
          Month: null,
          Accumulated: false,
        }),
      )
    }
  }, [currentYear, isYearly, locale])

  useEffect(() => {
    dispatch(changeLocale())
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterList
              width={width}
              currentMonthSelected={currentMonth ?? defaultMaxMonth}
              currentYearSelected={currentYear ?? defaultMaxYear}
              listCompanies={listCompanies}
              changeMonth={changeMonthCoilTab}
              changeYear={changeYearCoilTab}
              changeIsAcc={changeIsAcc}
              changeIsYearly={changeIsYearly}
              changeIsYearlyChart={changeIsYearlyChart}
              currentItemInChart={currentCompanyInChart}
              activeItem={activeCompany}
              changeActiveItem={changeActiveCompany}
              changeCurrentItem={changeCurrentCompany}
              currentTypeStatistics={currentTypeStatistics}
              changeCurrentTypeStatistics={changeCurrentTypeStatistics}
              tableId={tableId}
              setRowsCollapse={setRowsCollapse}
              rowsCollapse={rowsCollapse}
              sort={sort}
              keepRowsCollapse={keepRowsCollapse}
            />
            <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
              {`${I18n.t('sector.sectorSpecific.steel.UNIT')}: ${I18n.t(
                'sector.sectorSpecific.steel.TONS',
              )}`}
            </Span>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                  paddingTop: '5px',
                }}
                id={tableId}
              >
                {isChangeFilter && <Loading />}
                {!isChangeFilter && (
                  <Table
                    ids={ids}
                    horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                    resizable={false}
                    getDataFromRedux={selectDataTable}
                    isLoading={isLoading}
                    columnDraggable={false}
                    hasTooltip={false}
                    hasFooter={false}
                    onRowClick={onRowClick}
                    stickyFirstColumn
                    schema={schema}
                    rowSpanSchema={rowSpanSchema}
                    isCollapse={true}
                    rowsCollapse={rowsCollapse}
                    levelCollapse={levelCollapse}
                    changeActiveRow={changeActiveCompany}
                    defaultActiveRowId={activeCompanyId}
                    tableHeadRowTop={getTableHeadRowTop()}
                    sort={sort}
                  />
                )}
              </div>
            )}
            <PopupAlert
              message={I18n.t('sector.sectorSpecific.steel.WARNING_MESS')}
              isShow={isShowWarning}
              handleClose={() => setIsShowWarning(false)}
              zIndexOverlay={12}
            />
          </>
        )}
      </SizeTracker>
    )
  )
}
