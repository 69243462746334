import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getImportContributionByMarket,
  getImportContributionByProduct,
} from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/importByProduct/importProportion',
  initialState: {
    isLoading: true,
    data: [],
    activeItem: null,
    currentYear: null,
  },
  reducers: {
    changeActiveItemProportionChart: (state, action) => {
      state.activeItem = action.payload
    },
    changeCurrentYear: (state, action) => {
      state.currentYear = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportContributionByMarket.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getImportContributionByMarket.fulfilled,
      (state, action) => {
        const payloadData = action.payload || []
        state.data = payloadData.map((item) => ({
          id: item.seafoodProductId,
          name: item.seafoodProductName,
          value: item.percentage,
        }))
        state.isLoading = false
      },
    )
    builder.addCase(getImportContributionByMarket.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportContributionByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getImportContributionByProduct.fulfilled,
      (state, action) => {
        const payloadData = action.payload || []
        state.data = payloadData.map((item) => ({
          id: item.locationId,
          name: item.locationName,
          value: item.percentage,
        }))
        state.isLoading = false
      },
    )
    builder.addCase(getImportContributionByProduct.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveItemProportionChart = (state) =>
  state[slice.name].activeItem
export const selectCurrentYear = (state) => state[slice.name].currentYear

export const { changeActiveItemProportionChart, changeCurrentYear } =
  slice.actions

register(slice.name, slice.reducer)
