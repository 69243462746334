import { LinkCWDetail } from '../../common/LinkCWDetail'
import styles from './index.module.css'

export const LightBorderColumnCell = ({
  val,
  isAlignTextLeft,
  styleProps,
  className,
  isCWDetail,
  organizationId,
}) => {
  return (
    <td
      className={`${styles.borderRightColumnCellTd} ${
        isAlignTextLeft ? styles.alignTextLeft : styles.alignTextRight
      } ${className}`}
      style={{ ...styleProps }}
    >
      {isCWDetail ? (
        <LinkCWDetail organizationId={organizationId} val={val} />
      ) : (
        val
      )}
    </td>
  )
}
