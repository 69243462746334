import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class WatchListProxy extends ProxyBase {
  getDataProxy() {
    return this.get('MyWatchlist')
  }
  updateMultiWatchlistDetailsProxy(params) {
    return this.post('UpdateMultiWatchlistDetails', params)
  }
}

export default new WatchListProxy(
  ServiceProxyConfig.Bond.MyWatchlist.ServiceUrl,
)
