import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { selectFilterParams } from '../../store/slice'
import style from '../index.module.css'

export const FilterTypeSelect = ({ data, handleChangeParams }) => {
  const params = useSelector(selectFilterParams)

  const initialParams = params.conditions.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  return (
    <div className="w-100" key={data.indicatorId}>
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          {`${data?.indicatorName ?? ''} ${data.unit ?? ''}`}
        </Span>
      </label>
      <SelectCustom
        value={initialParams?.conditionValues?.[0] ?? ''}
        selectData={
          data.selectionValues?.map((item) => ({
            value: item.value,
            name: item.text,
          })) ?? []
        }
        handleChange={(value) => handleChangeParams([value], data.indicatorId)}
        appendStyle={{ fontWeight: 340 }}
        isDisable={!data.selectionValues?.length}
        isI18n
      />
    </div>
  )
}
