import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getInvestmentStructure, getLocationName } from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
  listLocationName: [],
  filter: undefined,
}

const slice = createSlice({
  name: 'economy/fdi/fdiByProvinces/barChartData',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInvestmentStructure.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInvestmentStructure.fulfilled, (state, action) => {
      state.dataChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getInvestmentStructure.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getLocationName.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getLocationName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.listLocationName = data
      state.filter = state.filter
        ? data.find((i) => i.locationId === state.filter?.locationId)
        : data[0]
      state.isLoading = false
    })
    builder.addCase(getLocationName.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectListLocationName = (state) =>
  state[slice.name].listLocationName
export const selectFilter = (state) => state[slice.name].filter

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
