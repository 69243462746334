import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import styles from '../../styleTheme.module.css'
import style from '../style.module.css'
import ListCompaniesSelected from './ListCompaniesSelected'
import ListEconomySelected from './ListEconomySelected'
import ListIndicesSelected from './ListIndicesSelected'
import ListSectorSelected from './ListSectorSelected'

const SecuritiesSelected = ({ securitiesState, setSecuritiesState }) => {
  const { companies, indices, sector, economy } = securitiesState

  const handleResetSecurities = () => {
    setSecuritiesState({
      companies: [],
      indices: [],
      sector: [],
      economy: [],
      watchList: [],
    })
  }

  const getIndexStart = (typeSecurities) => {
    const arrNew = {
      companies: [],
      indices: [companies],
      sector: [companies, indices],
      economy: [companies, indices, sector],
      watchList: [companies, indices, sector, economy],
    }
    return [].concat.apply([], arrNew[typeSecurities]).length
  }

  const renderList = () => {
    const props = {
      securitiesState,
      setSecuritiesState,
    }

    return (
      <ScrollComponent bgColor="#D9D9D9">
        <ul className={style.selectedTickerList}>
          <ListCompaniesSelected
            {...props}
            indexStart={getIndexStart('companies')}
            companiesChecked={companies}
          />
          <ListIndicesSelected
            {...props}
            indexStart={getIndexStart('indices')}
            indicesChecked={indices}
          />
          <ListSectorSelected
            {...props}
            indexStart={getIndexStart('sector')}
            sectorChecked={sector}
          />
          <ListEconomySelected
            {...props}
            indexStart={getIndexStart('economy')}
            economyChecked={economy}
          />
        </ul>
      </ScrollComponent>
    )
  }

  return (
    <div className={style.chooseSecurities}>
      <div className={style.header}>
        <Span className={styles.colorText}>
          <Translate value="financialChart.SELECTED_ITEMS" />
        </Span>
        <i
          onClick={handleResetSecurities}
          className="icon-trash cursor-pointer"
          style={{ color: '#8B8B8B', marginRight: 12 }}
        />
      </div>
      <div className={style.itemList}> {renderList()}</div>
    </div>
  )
}

export default SecuritiesSelected
