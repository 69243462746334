import React from 'react'
import { Panel } from '../../../../common/panel'
import FilterMonthYear from './FilterMonthYear'

const KeyIndicatorPanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title={'economy.fdi.overview.TITLE_PANEL_KEY_INDICATOR'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <FilterMonthYear />
    </Panel>
  )
}

export default KeyIndicatorPanel
