import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  ORIGINAL_NUMBER_FIRST,
  ORIGINAL_NUMBER_FIRST_NOT_YEARLY,
} from '../../../constants'
import { TAB_TYPE } from '../../constants'
import {
  addColorIdToData,
  addColorIdToDataDefault,
  convertDataTable,
  convertGroupColumn,
  getTimeMaxCallApi,
  getTimeMinCallApi,
} from '../../helper'
import { getDataTableIIPCompareByTimeRange } from './thunk'

export const DEFAULT_MIN_TIME = { month: 0, year: 0 }

const slice = createSlice({
  name: 'economy/productionAndConsumption/production/table/iip',
  initialState: {
    dataTable: [],
    dataById: {},
    ids: [],
    groupColumns: [],
    loading: false,
    listSelectRowIIP: [],
    dataWithColorId: [],
    minTimeCallApi: DEFAULT_MIN_TIME,
    maxTimeCallApi: DEFAULT_MIN_TIME,
    prevData: [],
    initIds: [],
    prevScrollWidth: 0,
    isShowAlert: false,
    dataSortLevel: [],
  },
  reducers: {
    addSelectListTableIIP: (state, action) => {
      state.listSelectRowIIP = [...state.listSelectRowIIP, action.payload]
    },
    removeSelectListTableIIP: (state, action) => {
      state.listSelectRowIIP = state.listSelectRowIIP.filter(
        (item) => item.iivnTypeId !== action.payload,
      )
    },
    changeMinTimeCallApi: (state, action) => {
      state.minTimeCallApi = { ...action.payload }
    },
    resetDataIIP: (state, action) => {
      state.dataById = {}
      state.groupColumns = []
      state.prevData = []
      state.minTimeCallApi = DEFAULT_MIN_TIME
      state.listSelectRowIIP = []
    },
    sortTableIIP: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initIds,
        0,
        state.dataSortLevel,
      )
    },
    handlePrevScrollWidth: (state, action) => {
      state.prevScrollWidth = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataTableIIPCompareByTimeRange.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getDataTableIIPCompareByTimeRange.fulfilled,
      (state, action) => {
        state.prevData = [...action.payload.dataIIP, ...state.prevData]
        const data = convertDataTable(
          state.prevData,
          action.payload.titleIIP,
          TAB_TYPE.IIP,
        )
        const groupColumns = [{ key: 'iivnTypeName', title: '' }]
        const filterGroupColumns = convertGroupColumn(data)
        filterGroupColumns?.forEach((item) => {
          groupColumns.push({
            key: item,
            title: item,
          })
        })
        state.dataTable = data
        state.groupColumns = groupColumns
        state.dataById = keyBy(data, 'iivnTypeId')
        state.dataWithColorId = addColorIdToData(data)
        state.listSelectRowIIP = addColorIdToDataDefault(
          data,
          action?.meta?.arg,
          state.listSelectRowIIP,
        )
        state.minTimeCallApi = getTimeMinCallApi(action.payload.dataIIP)
        if (
          [ORIGINAL_NUMBER_FIRST, ORIGINAL_NUMBER_FIRST_NOT_YEARLY].includes(
            action.meta.arg.Limit,
          )
        ) {
          state.maxTimeCallApi = getTimeMaxCallApi(action.payload.dataIIP)
        }
        state.ids = action.payload.titleIIP?.map((item) => item.iivnTypeId)
        state.initIds = state.ids
        state.dataSortLevel = action.payload.titleIIP?.map((item) => {
          return {
            id: item.iivnTypeId,
            level: item.iivnTypeLevel,
            parentId: item.parentIIVNTypeId,
          }
        })
        state.loading = false
      },
    )
    builder.addCase(getDataTableIIPCompareByTimeRange.rejected, (state) => {
      state.dataTable = []
      state.loading = false
    })
  },
})

export const selectCellValueDataTableIIP = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}

export const selectIdIIP = (state) => state[slice.name].ids
export const selectDataTableIIP = (state) => state[slice.name].dataTable
export const selectGroupColumnTableIIP = (state) =>
  state[slice.name].groupColumns

export const selectLoadingTableIIP = (state) => state[slice.name].loading
export const selectDataColorIdIIP = (state) => state[slice.name].dataWithColorId
export const selectMinTimeCallApi = (state) => state[slice.name].minTimeCallApi
export const selectMaxTimeCallApi = (state) => state[slice.name].maxTimeCallApi
export const selectListSelectRowIIP = (state) =>
  state[slice.name].listSelectRowIIP
export const getPrevScrollWidth = (state) => state[slice.name].prevScrollWidth
export const getIsShowAlert = (state) => state[slice.name].isShowAlert

export const {
  addSelectListTableIIP,
  removeSelectListTableIIP,
  changeMinTimeCallApi,
  resetDataIIP,
  sortTableIIP,
  handlePrevScrollWidth,
  handleIsShowAlert,
} = slice.actions

register(slice.name, slice.reducer)
