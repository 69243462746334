import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  defaultTickItems,
  TYPE_STATISTICS_FILTER,
} from '../../../common/tableStatistics/constants'
import { getLatestPeriods, getSteelStatistics } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/construction/steelStatistics',
  initialState: {
    idsAll: [],
    idsCompanyOnly: [],
    dataById: {},
    data: [],
    isLoading: false,
    listCompany: [],
    listAllCompanies: [],
    isChangeType: false,
    groupColumns: [],
    currentMonth: null,
    currentYear: null,
    isAcc: false,
    isYearly: false,
    isChangeFilter: false,
    activeCompany: null,
    rowsCollapse: [],
    currentTypeStatistics: TYPE_STATISTICS_FILTER[0],
    defaultMaxMonth: null,
    defaultMaxYear: null,
    isLoadingLatestPeriods: false,
    initialIds: [],
    levels: [],
    isKeepRowsCollapse: false,
  },
  reducers: {
    addItemToList: (state, action) => {
      state.listCompany = [...state.listCompany, action.payload]
    },
    removeItemFromList: (state, action) => {
      state.listCompany = state.listCompany.filter(
        (item) => item.organizationId !== action.payload,
      )
    },
    changeMonthConstructionTab: (state, action) => {
      if (state.currentMonth !== action.payload) {
        state.isChangeFilter = true
      }
      state.currentMonth = action.payload
    },
    changeYearConstructionTab: (state, action) => {
      if (state.currentYear !== action.payload) {
        state.isChangeFilter = true
      }
      state.currentYear = action.payload
    },
    changeIsAcc: (state, action) => {
      if (state.isAcc !== action.payload) {
        state.isChangeFilter = true
      }
      state.isAcc = action.payload
    },
    changeIsYearly: (state, action) => {
      if (state.isYearly !== action.payload) {
        state.isChangeFilter = true
      }
      state.isYearly = action.payload
    },
    changeActiveCompany: (state, action) => {
      const rowId = action.payload
      if (rowId !== null) {
        state.activeCompany =
          state.dataById[
            state.idsCompanyOnly.some((item) => item === rowId)
              ? state.idsCompanyOnly.filter((item) => item === rowId)
              : state.data.find((item) => item.id === rowId).organizationId
          ]
      } else {
        state.activeCompany = null
      }
    },
    changeLocale: (state) => {
      state.isChangeFilter = true
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
    changeCurrentTypeStatistics: (state, action) => {
      state.currentTypeStatistics = action.payload
    },
    sort: (state, action) => {
      state.idsAll = getIdsFromProps(
        state.idsAll,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    keepRowsCollapse: (state, action) => {
      state.isKeepRowsCollapse = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSteelStatistics.pending, (state) => {
      state.isLoading = true
      state.data = []
      state.dataById = {}
      state.listCompany = []
    })
    builder.addCase(getSteelStatistics.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false
      const data = action.payload || []
      state.data = data?.map((item) => ({
        ...item,
        id: item.isGroupRow
          ? item.organizationId
          : `${item.organizationId}_${item.priority}`,
        ticker: item.isGroupRow && item.organizationId !== 0 ? item.ticker : '',
      }))
      state.dataById = keyBy(state.data, 'id')
      state.idsAll = state.initialIds = state.data?.map((v) => v.id)
      state.idsCompanyOnly = state.data
        .filter((item) => item.isGroupRow)
        .map((v) => v.id)

      state.levels = state.data.map((item) => ({
        id: item.id,
        parentId: item.organizationId,
        level: item.isGroupRow ? 1 : 2,
      }))

      state.listCompany = defaultTickItems(state.data)
      state.listAllCompanies = state.data.filter((item) => item.isGroupRow)
      state.rowsCollapse = !state.isKeepRowsCollapse
        ? state.idsAll.slice(1, state.idsAll.length)
        : [...state.rowsCollapse]
    })
    builder.addCase(getSteelStatistics.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getLatestPeriods.pending, (state) => {
      state.isLoadingLatestPeriods = true
      state.defaultMaxMonth = null
      state.defaultMaxYear = null
    })
    builder.addCase(getLatestPeriods.fulfilled, (state, action) => {
      state.isLoadingLatestPeriods = false
      state.defaultMaxMonth = action.payload?.find(
        (item) => item.type === 'SteelConstruction',
      )?.month
      state.defaultMaxYear = action.payload?.find(
        (item) => item.type === 'SteelConstruction',
      )?.year
    })
    builder.addCase(getLatestPeriods.rejected, (state) => {
      state.isLoadingLatestPeriods = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].idsAll
export const selectIdsCompanyOnly = (state) => state[slice.name].idsCompanyOnly
export const selectDataTable = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCompany = (state) => state[slice.name].listCompany
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectCurrentMonth = (state) => state[slice.name].currentMonth
export const selectCurrentYear = (state) => state[slice.name].currentYear
export const selectIsAcc = (state) => state[slice.name].isAcc
export const selectIsYearly = (state) => state[slice.name].isYearly
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter
export const selectActiveCompany = (state) => state[slice.name].activeCompany
export const selectListAllCompanies = (state) =>
  state[slice.name].listAllCompanies
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectCurrentTypeStatistic = (state) =>
  state[slice.name].currentTypeStatistics
export const selectDefaultMaxMonthConstructionTab = (state) =>
  state[slice.name].defaultMaxMonth
export const selectDefaultMaxYearConstructionTab = (state) =>
  state[slice.name].defaultMaxYear

export const {
  changeMonthConstructionTab,
  changeYearConstructionTab,
  addItemToList,
  removeItemFromList,
  changeIsAcc,
  changeIsYearly,
  changeActiveCompany,
  changeLocale,
  setRowsCollapse,
  changeCurrentTypeStatistics,
  keepRowsCollapse,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
