import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../common/textEllipsis'
import { keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'

const NameChecked = ({ watchlist, listAllChecked, listChecked }) => {
  const [watchlistById, setWatchlistById] = useState({})
  useEffect(() => {
    if (watchlist.length > 0) setWatchlistById(keyBy(watchlist, 'watchListId'))
  }, [watchlist])

  return (
    <div className={style.w85}>
      {listAllChecked?.length > 0 &&
      listAllChecked?.length === listChecked?.length ? (
        <Translate value="market.event.ALL_COMPANY" />
      ) : listChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listChecked
            .map((key) => watchlistById[key]?.watchListName)
            .join('; ')}
        />
      ) : (
        <span className={style.opacity0}>
          <Translate value="tool.smartScreening.ALL_COMPANY" />
        </span>
      )}
    </div>
  )
}

NameChecked.propTypes = {
  watchlist: PropTypes.array.isRequired,
  listAllChecked: PropTypes.array.isRequired,
  listChecked: PropTypes.array.isRequired,
}

export default NameChecked
