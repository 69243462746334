import { createAsyncThunk } from '@reduxjs/toolkit'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'

export const getTopExportMarketsOfVN = createAsyncThunk(
  'sector/sectorSpecific/fishery/overviewFishery/topExportMarkets',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getTopExportMarketsOfVN(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getMaxDate = createAsyncThunk(
  'sector/sectorSpecific/fishery/overviewFishery/topExporterChart/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
