import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { ValueInvestor } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { selectDateTime } from './store/slice'

export const PanelValueInvestor = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  isResizeResponsive,
}) => {
  const basicInfo = useSelector(selectIndexInfo)
  const dateType = useSelector(selectDateTime)

  return (
    <Panel
      title="market.marketInDepth.indexStatistics.VALUE_INVESTOR"
      panelRefs={panelRefs}
      panelKey={mapKey.VALUE_INVESTOR}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n('market.marketInDepth.indexStatistics.VALUE_INVESTOR')}
      downloadJpgParams={{
        domId: 'valueInvestor',
        nameScreen: UseI18n(
          'market.marketInDepth.indexStatistics.SCREEN_LABEL',
        ),
        chartName: UseI18n(
          'market.marketInDepth.indexStatistics.VALUE_INVESTOR',
        ),
        tabName: UseI18n(`market.marketInDepth.indexStatistics.${dateType}`),
        tickerName: basicInfo.groupCode,
      }}
    >
      <ValueInvestor
        width={sizes[mapKey.VALUE_INVESTOR].width - PANEL_PADDING}
        height={sizes[mapKey.VALUE_INVESTOR].height - HEADER_PANEL_HEIGHT}
        isResizeResponsive={isResizeResponsive}
      />
    </Panel>
  )
}

PanelValueInvestor.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  isResizeResponsive: PropTypes.bool,
}
