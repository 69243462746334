import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class StateBudgetProxy extends ProxyBase {
  getBudgetRevenueTypeName(params) {
    return this.get('BudgetRevenueTypeName', params)
  }

  getStateBudgetBalance(params) {
    return this.get('StateBudgetBalance', params)
  }

  getDownloadStateBudgetBalance(params) {
    return this.get('DownloadStateBudgetBalance', params, 'download')
  }

  getStateBudgetBalanceChart(params) {
    return this.get('StateBudgetBalanceChart', params)
  }

  getStateBudgetExpenditureChart(params) {
    return this.get('StateBudgetExpenditureChart', params)
  }

  getStateBudgetRevenueChart(params) {
    return this.get('StateBudgetRevenueChart', params)
  }

  getTotalSocialInvesmentCapitalChart(params) {
    return this.get('TotalSocialInvesmentCapitalChart', params)
  }

  getTotalInvesmentCapitalUnderTheStateBudgetChart(params) {
    return this.get('TotalInvesmentCapitalUnderTheStateBudgetChart', params)
  }

  getTotalInvesmentCapitalOfTheStateSectorChart(params) {
    return this.get('TotalInvesmentCapitalOfTheStateSectorChart', params)
  }

  getTotalSocialInvesmentCapital(params) {
    return this.get('TotalSocialInvesmentCapital', params)
  }

  getTotalSocialInvesmentCapitalTypeName(params) {
    return this.get('TotalSocialInvesmentCapitalTypeName', params)
  }

  getDownloadTotalSocialInvesmentCapital(params) {
    return this.get('DownloadTotalSocialInvesmentCapital', params, 'download')
  }

  getMaxPeriod(params) {
    return this.get('GetMaxPeriod', params)
  }

  getDataStatusName(params) {
    return this.get('GetDataStatusName', params)
  }
}

export default new StateBudgetProxy(
  ServiceProxyConfig.Economy.Fiscal.StateBudget.ServiceUrl,
)
