import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { selectSectorInfo } from '../../../common/topInfo/sectorTopInfo/store/slice'
import { withWrapper } from '../../../common/withWrapper'
import { RankingComponent } from './component/RankingComponent'
import { selectExchangeCode } from './store/slice'
import { downloadDataTable } from './store/thunk'

const MAP_KEY = {
  RANKING: 'RANKING',
}

const MAP_SIZE = {
  [`${MAP_KEY.RANKING}`]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)

  return (
    <Panel
      title="sector.sectorConstituents.ranking.RANKING"
      panelRefs={panelRefs}
      panelKey={MAP_KEY.RANKING}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadCsv
      downloadCsv={() =>
        downloadDataTable({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
        })
      }
    >
      <RankingComponent />
    </Panel>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
