import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize, getLineHeightP } from '../../utils/FontSize'

export const P = ({ children, style, ...others }) => {
  return (
    <p
      style={{
        ...style,
        fontSize: getFontSize(style?.fontSize),
        lineHeight: getLineHeightP(),
      }}
      {...others}
    >
      {children}
    </p>
  )
}

P.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

P.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
