import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import SelectYearCustom from '../../common/SelectYearCustom'
import TimeFrame from './TimeFrame'

const FilterGroup = () => {
  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <Span style={{ color: '#ececec', fontStyle: 'italic', opacity: 0.4 }}>
        <Translate value="corporate.businessModel.segmentalAnalysis.UNIT_BILLION_VND" />
      </Span>
      <div className="d-flex">
        <SelectYearCustom />
        <TimeFrame />
      </div>
    </div>
  )
}

export default FilterGroup
