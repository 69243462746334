import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { quartersMonthly } from '../constans'
import { convertDataChartTopChange } from '../helper'
import { getDataChartTopChange } from './thunk'

export const INIT_CONTRIBUTION = {
  topGainers: [],
  topLosers: [],
}
export const INIT_QUARTER = 3

const initialState = {
  loading: false,
  rawData: [],
  contribution: INIT_CONTRIBUTION,
  activeQuarter: INIT_QUARTER,
  activeMonth: new Date().getMonth() + 1,
  listTimeRanger: quartersMonthly,
  activeYear: new Date().getFullYear,
  timeRequest: {},
}

export const slice = createSlice({
  name: 'economy/productionAndConsumption/production/topGrowthIndustry',
  initialState,
  reducers: {
    getQuarterActive: (state, action) => {
      state.activeQuarter = action.payload
    },
    changeYearActive: (state, action) => {
      state.activeYear = action.payload
    },
    changeMonthActive: (state, action) => {
      state.activeMonth = action.payload
    },
    changeListTimeRanger: (state, action) => {
      state.listTimeRanger = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getDataChartTopChange.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataChartTopChange.fulfilled, (state, action) => {
      state.rawData = action.payload || []
      state.contribution = convertDataChartTopChange(action.payload || [])
      state.timeRequest = {
        year: action?.meta?.arg?.Year,
        month: action?.meta?.arg?.Month,
        dataType: action?.meta?.arg?.DataType,
      }

      state.loading = false
    })
    builder.addCase(getDataChartTopChange.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectContribution = (state) => state[slice.name].contribution
export const selectQuarterActive = (state) => state[slice.name].activeQuarter
export const selectListTimeQuarter = (state) => state[slice.name].listTimeRanger
export const selectYearActive = (state) => state[slice.name].activeYear
export const selectMonthActive = (state) => state[slice.name].activeMonth
export const selectTimeRequest = (state) => state[slice.name].timeRequest
export const getRawData = (state) => state[slice.name].rawData

export const {
  getQuarterActive,
  changeListTimeRanger,
  changeYearActive,
  changeMonthActive,
} = slice.actions

register(slice.name, slice.reducer)
