import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { usePrevious } from '../../../hooks/usePrevious'
import style from './index.module.css'

export const TextBlink = ({ value, otherBlinkValue, tradingDate }) => {
  const ref = useRef()

  const preValue = usePrevious(value)
  const preOtherBlinkValue = usePrevious(otherBlinkValue)
  const prevTradingDate = usePrevious(tradingDate)

  useEffect(() => {
    if (
      tradingDate !== EMPTY_VALUE &&
      prevTradingDate !== tradingDate &&
      (preValue !== value || preOtherBlinkValue !== otherBlinkValue)
    ) {
      let timeOut = null
      if (ref.current) {
        ref.current.classList.add(style.blink)
        timeOut = setTimeout(() => {
          ref.current.classList.remove(style.blink)
        }, 1000)
      }
      return () => clearTimeout(timeOut)
    }
  }, [value, otherBlinkValue, tradingDate])

  return <span ref={ref}>{value}</span>
}

TextBlink.propTypes = {
  value: PropTypes.any.isRequired,
  otherBlinkValue: PropTypes.any,
}
