import { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { Input } from '../../html/Input'
import { Loading } from '../../loading'
import { SizeTracker } from '../../sizeTracker'
import Tree from '../../tree'
import style from './index.module.css'

const Indicator = ({
  indicatorTree,
  loadingIndicator,
  handleAddIndicator,
  isEconomy,
  isFiinXTemplate,
}) => {
  const [valueInput, setValueInput] = useState('')

  const handleChangeInput = (e) => setValueInput(e.target.value)

  const handleDoubleClickItem = (item) => {
    handleAddIndicator(item)
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div
            className={`h-100 ${
              isFiinXTemplate ? style.containerLoadingBlur : ''
            }`}
          >
            <div
              className={`form-search-inbox mb-10 ${
                isEconomy ? style.searchInputEconomy : ''
              }`}
            >
              <Input
                type="text"
                className={`search-inbox bg-grey h-20 ${style.searchInput}`}
                placeholder={I18n.t('tool.smartScreening.SEARCH')}
                value={valueInput}
                onChange={handleChangeInput}
              />
              <button type="button" className={style.searchButton}>
                <i className="icon-search-2" />
              </button>
            </div>
            {size.height && (
              <div
                className="box_indicator"
                style={{ height: `calc(100% - ${size.height}px` }}
              >
                {loadingIndicator ? (
                  <div className="position-relative h-100">
                    <Loading />
                  </div>
                ) : (
                  <Tree
                    valueSearch={valueInput}
                    treeData={indicatorTree}
                    nameKey="indicatorName"
                    doubleClickItem={handleDoubleClickItem}
                    sortKey="orderNumber"
                    disabledCheckDbClick={true}
                  />
                )}
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}

export default Indicator
