import html2canvas from 'html2canvas'
import { formatWithDevicePixelRatio } from '../../utils/Device'
import watermarkPng from './watermark.svg'

const WIDTH_IMAGE_LOGO = 200
const HEIGHT_IMAGE_LOGO = 42
const MARGIN_TOP = 4
const bgColor = '#1e242e'

const image = new Image()
image.src = watermarkPng
image.crossOrigin = 'anonymous'

export const exportToImg = ({
  domId,
  exportName,
  titleJpg,
  fontSize,
  ratio = 5,
  formatWithZoom,
  reverseFormatWithZoom,
  isUseOriginHtml2Canvas,
}) => {
  const formExport = document.getElementById(domId)

  if (formExport) {
    const rectBound = formExport.getBoundingClientRect()
    const widthCut =
      window.devicePixelRatio < 1
        ? rectBound.width
        : formatWithZoom(rectBound.width) ||
          formatWithDevicePixelRatio(rectBound.width)
    const heightCut =
      window.devicePixelRatio < 1
        ? rectBound.height
        : formatWithZoom(rectBound.height) ||
          formatWithDevicePixelRatio(rectBound.height)

    const configCanvas = {
      allowTaint: true,
      useCORS: true,
      removeContainer: true,
      scale: formatWithZoom(ratio),
      width: widthCut,
      height: heightCut,
      backgroundColor: bgColor,
    }

    html2canvas(formExport, {
      ...configCanvas,
      onclone: (document) => {
        Array.from(document.children[0].querySelectorAll('*')).forEach((e) => {
          e.setAttribute(
            'style',
            [
              'font-family: sans-serif !important',
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })

        Array.from(
          document.children[0].querySelectorAll(
            `[id^='${domId}'] .canvas-chart-footer`,
          ),
        ).forEach((e) => {
          const { height, width } = e.getBoundingClientRect()
          e.setAttribute(
            'style',
            [
              `letter-spacing: ${
                window.devicePixelRatio > 1 ? formatWithZoom(1.5) : 1
              }px !important`,
              `font-size: ${reverseFormatWithZoom(fontSize)}px !important`,
              `line-height: ${fontSize}px !important`,
              'display: block',
              `margin-top: ${window.devicePixelRatio > 1 ? -MARGIN_TOP : 0}px`,
              `height: ${formatWithZoom(height + MARGIN_TOP)}px`,
              `width: ${formatWithZoom(width)}px`,
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })

        Array.from(
          document.children[0].querySelectorAll(
            `[id^='${domId}'] .canvas-chart-title`,
          ),
        ).forEach((e) => {
          const { height, width } = e.getBoundingClientRect()
          e.setAttribute(
            'style',
            [
              `letter-spacing: ${formatWithZoom(1.5)}px !important`,
              `font-size: ${reverseFormatWithZoom(fontSize)}px !important`,
              `line-height: ${fontSize}px !important`,
              'display: block',
              'text-align: center',
              `margin-top: ${window.devicePixelRatio > 1 ? -MARGIN_TOP : 0}px`,
              `height: ${formatWithZoom(height + MARGIN_TOP)}px`,
              `width: ${formatWithZoom(width)}px`,
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })
      },
    }).then((canvas) => {
      let canvasExport

      if (isUseOriginHtml2Canvas) {
        canvasExport = canvas
      } else {
        canvasExport = document.createElement('canvas')

        const contextExport = canvasExport.getContext('2d')
        const PADDING = 50 * ratio
        canvasExport.width = widthCut * ratio + 2 * PADDING
        canvasExport.height = heightCut * ratio + 2 * PADDING

        contextExport.fillStyle = bgColor
        contextExport.fillRect(0, 0, canvasExport.width, canvasExport.height)

        contextExport.drawImage(canvas, PADDING, PADDING)

        contextExport.globalAlpha = 0.04
        contextExport.drawImage(
          image,
          canvasExport.width / 2 - (WIDTH_IMAGE_LOGO * ratio) / 2,
          canvasExport.height / 2 - (HEIGHT_IMAGE_LOGO * ratio) / 2,
          WIDTH_IMAGE_LOGO * ratio,
          HEIGHT_IMAGE_LOGO * ratio,
        )

        if (titleJpg) {
          contextExport.fillRect(0, 0, canvasExport.width, canvasExport.height)
          contextExport.globalAlpha = 1
          contextExport.fillStyle = '#ececec'
          contextExport.font = `bold ${fontSize * ratio}px Poppins`
          contextExport.textBaseline = 'top'
          contextExport.textAlign = 'center'
          contextExport.fillText(
            titleJpg.toUpperCase(),
            (widthCut * ratio + 2 * PADDING) / 2,
            PADDING / 2,
          )
        }
      }

      document.body.appendChild(canvasExport)
      const downloadLink = document.createElement('a')
      const data = canvasExport.toDataURL('image/jpeg')

      formExport.appendChild(downloadLink)
      downloadLink.setAttribute('download', exportName)
      downloadLink.setAttribute('href', data)
      downloadLink.click()
      formExport.removeChild(downloadLink)
      document.body.removeChild(canvasExport)
    })
  }
}

export const exportToImgURL = ({
  domId,
  titleJpg,
  fontSize,
  ratio = 5,
  formatWithZoom,
  reverseFormatWithZoom,
}) => {
  const formExport = document.getElementById(domId)

  if (formExport) {
    const rectBound = formExport.getBoundingClientRect()
    const widthCut = rectBound.width
    const heightCut = rectBound.height
    const configCanvas = {
      allowTaint: true,
      useCORS: true,
      removeContainer: true,
      scale: ratio,
      width: widthCut,
      height: heightCut,
      backgroundColor: bgColor,
    }

    return html2canvas(formExport, {
      ...configCanvas,
      onclone: (document, element) => {
        Array.from(document.children[0].querySelectorAll('*')).forEach((e) => {
          e.setAttribute(
            'style',
            [
              'font-family: sans-serif !important',
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })

        Array.from(
          document.children[0].querySelectorAll(
            `[id^='${domId}'] .canvas-chart-footer`,
          ),
        ).forEach((e) => {
          const { height, width } = e.getBoundingClientRect()
          e.setAttribute(
            'style',
            [
              `letter-spacing: ${formatWithZoom(1.5)}px !important`,
              `font-size: ${reverseFormatWithZoom(fontSize)}px !important`,
              `line-height: ${fontSize}px !important`,
              'display: block',
              `margin-top: ${window.devicePixelRatio > 1 ? -MARGIN_TOP : 0}px`,
              `height: ${formatWithZoom(height + MARGIN_TOP)}px`,
              `width: ${formatWithZoom(width)}px`,
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })

        Array.from(
          document.children[0].querySelectorAll(
            `[id^='${domId}'] .canvas-chart-title`,
          ),
        ).forEach((e) => {
          const { height, width } = e.getBoundingClientRect()
          e.setAttribute(
            'style',
            [
              `letter-spacing: ${formatWithZoom(1.5)}px !important`,
              `font-size: ${reverseFormatWithZoom(fontSize)}px !important`,
              `line-height: ${fontSize}px !important`,
              'display: block',
              'text-align: center',
              `margin-top: ${window.devicePixelRatio > 1 ? -MARGIN_TOP : 0}px`,
              `height: ${formatWithZoom(height + MARGIN_TOP)}px`,
              `width: ${formatWithZoom(width)}px`,
              e.getAttribute('style') || '',
            ].join(';'),
          )
        })
      },
    }).then((canvas) => {
      const canvasExport = document.createElement('canvas')
      const contextExport = canvasExport.getContext('2d')
      const PADDING = 50 * ratio
      canvasExport.width = widthCut * ratio + 2 * PADDING
      canvasExport.height = heightCut * ratio + 2 * PADDING

      contextExport.fillStyle = bgColor
      contextExport.fillRect(0, 0, canvasExport.width, canvasExport.height)

      contextExport.drawImage(canvas, PADDING, PADDING)

      contextExport.globalAlpha = 0.04
      contextExport.drawImage(
        image,
        canvasExport.width / 2 - (WIDTH_IMAGE_LOGO * ratio) / 2,
        canvasExport.height / 2 - (HEIGHT_IMAGE_LOGO * ratio) / 2,
        WIDTH_IMAGE_LOGO * ratio,
        HEIGHT_IMAGE_LOGO * ratio,
      )

      if (titleJpg) {
        contextExport.fillRect(0, 0, canvasExport.width, canvasExport.height)
        contextExport.globalAlpha = 1
        contextExport.fillStyle = '#ececec'
        contextExport.font = `${fontSize * ratio}px Poppins`
        contextExport.textBaseline = 'top'
        contextExport.textAlign = 'center'
        contextExport.fillText(
          titleJpg.toUpperCase(),
          (widthCut * ratio + 2 * PADDING) / 2,
          PADDING / 2,
        )
      }

      return canvasExport.toDataURL('image/jpeg')
    })
  }
}
