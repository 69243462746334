import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import Title from '../../common/Title'
import { selectFilter, selectRevenueTypes } from '../marketShare/store/slice'
import ChartComponent from './ChartComponent'
import { changeData, selectData, selectLoading } from './store/slice'
import { getCorpRevenueChart } from './store/thunk'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const revenueTypes = useSelector(selectRevenueTypes)
  const dataChart = useSelector(selectData)
  const loading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)

  const revenueName =
    revenueTypes.find((item) => item.revenueTypeId === filter.revenueTypeId)
      ?.revenueTypeName || undefined

  useEffect(() => {
    if (filter.revenueTypeId) {
      dispatch(
        getCorpRevenueChart({
          RevenueTypeId: filter.revenueTypeId,
          OrganizationId: basicInfo.organizationId,
        }),
      )
    } else {
      dispatch(changeData([]))
    }
  }, [filter.revenueTypeId, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title
              text="REVENUE_SECTOR"
              suffix={revenueName}
              appendStyle={{ paddingTop: 6 }}
            />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <ChartComponent
                    width={width}
                    height={height - size.height}
                    data={dataChart}
                    keyXAxis="realYear"
                    barKey="rB1"
                    lineKey="growth"
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
