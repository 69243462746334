import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../configs/Global'
import { Realtime } from '../../../common/Realtime'
import SelectCustom from '../../../common/selectCustom'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import EventEmitter, { RESET_TABLE_SORT } from '../../../utils/EventEmitter'
import { typeRowGroup } from '../constant'
import { getIcbId, getRowGroup } from '../helper'
import style from '../index.module.css'
import { subscribeIndex, subscribeSector } from '../store/slice'
import { listLevel } from './index'

const getIdDisplay = (tableEl, isIndex) => {
  return Array.from(tableEl?.querySelectorAll('tbody tr') || [])
    .filter(
      (el) =>
        el.style.display !== 'none' &&
        getRowGroup(el.getAttribute('id')) ===
          (isIndex ? typeRowGroup.INDEX : typeRowGroup.ICB),
    )
    .map((el) => getIcbId(el.getAttribute('id')))
}

const ThSector = ({ level, setLevel, tableRef }) => {
  const index = useSelector(selectIndexInfo)

  const handleChangeLevel = (value) => {
    EventEmitter.dispatch(RESET_TABLE_SORT)
    setLevel(value)
  }
  const renderSelect = ({ dataById, value, isShowDropdown }) => {
    return (
      <div className={`${style.h30px} d-flex ali-center`}>
        <div className={`${style.selectLevel}`}>
          {dataById[value]?.name || ''}
          <i
            className={
              (!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up') +
              ' ' +
              style.ml20px
            }
          />
        </div>
      </div>
    )
  }

  let icbIdDisplay = []
  let groupIdDisplay = []
  if (tableRef?.current) {
    icbIdDisplay = getIdDisplay(tableRef.current, false)
    groupIdDisplay = getIdDisplay(tableRef.current, true)
  }

  return (
    <>
      <div className="d-flex j-b w-100 ali-center h-100">
        <div>
          <span style={{ fontWeight: '500' }}>
            <Translate value="market.sectorInDepth.SECTOR" />
          </span>
        </div>
        <div className={style.selectLevelContainer}>
          <SelectCustom
            selectData={listLevel}
            value={level}
            handleChange={handleChangeLevel}
            renderSelect={renderSelect}
            isDropdownFitContent={true}
          />
        </div>
      </div>
      {icbIdDisplay.map((id) => (
        <Realtime
          channel={ChannelConfig.SectorChannel + id + '-' + index.groupId}
          action={subscribeSector}
          key={id}
          stackName="sector"
        >
          <></>
        </Realtime>
      ))}
      {groupIdDisplay.map((id) => (
        <Realtime
          channel={ChannelConfig.IndexChannel + id}
          action={subscribeIndex}
          key={id}
        >
          <></>
        </Realtime>
      ))}
    </>
  )
}

ThSector.propTypes = {
  level: PropTypes.number.isRequired,
  setLevel: PropTypes.func.isRequired,
  tableRef: PropTypes.object,
}

export default ThSector
