import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import BondInformation from './bondInformation'
import Expected from './expected'
import OverviewOfPortfolioSize from './overviewOfPortfolioSize'
import PortfolioSizeByIssuer from './portfolioSizeByIssuer'

const MAP_KEY = {
  BOND_INFORMATION: 'BOND_INFORMATION',
  OVERVIEW_OF_PORTFOLIO_SIZE: 'OVERVIEW_OF_PORTFOLIO_SIZE',
  PORTFOLIO_SIZE_BY_ISSUER: 'PORTFOLIO_SIZE_BY_ISSUER',
  EXPECTED: 'EXPECTED',
}

const MAP_SIZE = {
  [`${MAP_KEY.BOND_INFORMATION}`]: {
    width: `calc(100%)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    horizontalResize: [
      {
        key: MAP_KEY.OVERVIEW_OF_PORTFOLIO_SIZE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.PORTFOLIO_SIZE_BY_ISSUER,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.EXPECTED,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.OVERVIEW_OF_PORTFOLIO_SIZE}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.PORTFOLIO_SIZE_BY_ISSUER,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PORTFOLIO_SIZE_BY_ISSUER}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.EXPECTED,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXPECTED}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <BondInformation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.BOND_INFORMATION}
        sizes={sizes}
        setSizes={setSizes}
      />
      <OverviewOfPortfolioSize
        panelRefs={panelRefs}
        panelKey={MAP_KEY.OVERVIEW_OF_PORTFOLIO_SIZE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PortfolioSizeByIssuer
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PORTFOLIO_SIZE_BY_ISSUER}
        sizes={sizes}
        setSizes={setSizes}
      />
      <Expected
        panelRefs={panelRefs}
        panelKey={MAP_KEY.EXPECTED}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
