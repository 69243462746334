import { uniq } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown from '../../../common/dropdown'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import TextEllipsis from '../../../common/textEllipsis'
import { getDataBondList } from '../BondList/Table/store/thunk'
import { TAB_TYPES } from '../constants'
import {
  changeLoadingSearchBond,
  changeTemplate,
  changeTemplateList,
  changeTemplateMoreList,
  selectDataRaw,
  selectFilterParams,
  selectFilterSearchBond,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  selectTemplate,
  setFilterParameters,
} from '../store/slice'
import { fetchMoreBondListData, getBondListData } from '../store/thunk'
import style from './index.module.css'

export const FilterBondSelect = ({ width, redirectToBondIssuer }) => {
  const dispatch = useDispatch()
  const dropdownRef = useRef()

  const params = useSelector(selectFilterParams)
  const locale = useSelector((state) => state.i18n.locale)
  const { data, page, pageSize, enableInfinity, loading } = useSelector(
    selectFilterSearchBond,
  )
  const templates = useSelector(selectTemplate)
  const rawData = useSelector(selectDataRaw)
  const listParent = useSelector(selectParentSectors)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handleClickItem = (id) => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)
    const newSectors =
      uniq([...listParent, ...params.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...params.sectors])

    setKeySearch('')
    if (raw) {
      dispatch(
        changeTemplate({
          ...rawData,
          parameter: {
            ...rawData.parameter,
            ticker: [id],
          },
        }),
      )
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName: 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: params.indicatorGroup,
      }

      const newTemplateIds = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplateIds))
      dispatch(changeTemplateMoreList(newTemplateIds))

      dispatch(
        changeTemplate({
          ...data,
          parameter: {
            ...params,
            ticker: [id],
          },
        }),
      )
    }

    dispatch(setFilterParameters({ key: 'ticker', value: [id] }))

    const newParams = {
      ...params,
      ticker: [id],
      sectors: newSectors,
      conditions: params.conditions,
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

    handleHide()
  }

  const handleSearchMore = () => {
    if (!enableInfinity) {
      return
    }

    const payload = {
      Language: locale,
      Page: page,
      PageSize: pageSize,
    }

    if (!!keySearch.length) {
      payload.Term = keySearch
    }

    dispatch(fetchMoreBondListData(payload))
  }

  useEffect(() => {
    if (isShowDropdown) {
      dispatch(changeLoadingSearchBond(true))

      const timeout = setTimeout(() => {
        const payload = {
          Language: locale,
          Page: 1,
          PageSize: pageSize,
        }

        if (!!keySearch.length) {
          payload.Term = keySearch
        }

        dispatch(getBondListData(payload))
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [keySearch.length, isShowDropdown])

  const placeholderInput = useMemo(
    () => I18n.t('bond.corporateBond.priceBoard.SEARCH_PLACEHOLDER'),
    [locale],
  )

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {loading ? (
                <div style={{ height: 29 }}>
                  <Loading />
                </div>
              ) : !!data?.length ? (
                <ScrollComponent
                  autoHeight={true}
                  autoHeightMax={200}
                  scrollToBottomCallback={handleSearchMore}
                >
                  <ul className="list-check">
                    {data.map((item, index) => {
                      return (
                        (item.ticker || item.bondTicker) && (
                          <li key={`key-${index}`}>
                            <a
                              style={{
                                cursor: 'pointer',
                                paddingLeft: item.bondTicker ? 28 : 10,
                              }}
                              onClick={() =>
                                handleClickItem(item.ticker || item.bondTicker)
                              }
                            >
                              <label className="d-flex ali-center">
                                <TextEllipsis
                                  text={`${
                                    item?.ticker ?? item?.bondTicker ?? ''
                                  } ${
                                    !item.bondId
                                      ? `- ${item.organizationShortName ?? ''}`
                                      : ''
                                  }`}
                                  zIndexTooltip={999}
                                  isI18n={false}
                                />
                              </label>
                            </a>
                          </li>
                        )
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
