export const LIMIT_FIRST_FETCH_MONTHLY = 22
export const LIMIT_FIRST_FETCH_YEARLY = 20
export const LIMIT_FETCH_SCROLL = 8

export const TYPE_STATISTIC = {
  PRODUCT: 0,
  MARKET: 1,
}

export const TIME_FREQUENCY = {
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
  YEARLY: 'Yearly',
}

export const TIME_FREQUENCY_FILTER = [
  {
    title: 'sector.sectorSpecific.fishery.FILTER_MONTHLY',
    value: TIME_FREQUENCY.MONTHLY,
    isYearly: false,
  },
  {
    title: 'sector.sectorSpecific.fishery.FILTER_ACC_MONTHLY',
    value: TIME_FREQUENCY.ACC_MONTHLY,
    isYearly: false,
  },
  {
    title: 'sector.sectorSpecific.fishery.FILTER_YEARLY',
    value: TIME_FREQUENCY.YEARLY,
    isYearly: true,
  },
]

export const VAL_GROWTH_TYPE = {
  VALUE: 'value',
  GROWTH: 'growth',
}

export const VAL_GROWTH_FILTER = [
  {
    title: 'sector.sectorSpecific.fishery.VALUE',
    value: VAL_GROWTH_TYPE.VALUE,
  },
  {
    title: 'sector.sectorSpecific.fishery.GROWTH',
    value: VAL_GROWTH_TYPE.GROWTH,
  },
]
