import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../common/html/Span'
import { valToPercent } from '../../../utils/Value'
import style from './index.module.css'

export const PieLineLabelChart = ({
  data,
  width,
  height,
  fillColor,
  pieKeys,
  mappingDisplayName,
}) => {
  const chartData = data[data.length - 1]

  // Get data
  const getFormatData = useMemo(() => {
    const dataArr = pieKeys
      .filter((key) => chartData[key])
      .map((key) => ({
        text: mappingDisplayName[key],
        value: chartData[key],
        isI18n: true,
        key,
      }))

    const formatData = dataArr
      .sort((a, b) => b.value - a.value)
      .filter((_, index) => index < 5)
      .map((item, index) => ({ ...item, color: fillColor[index] }))

    const totalMaxData = formatData
      .map((item) => item.value)
      .reduce((prev, cur) => prev + cur, 0)

    formatData.push({
      text: 'corporate.financialData.financialStatement.OTHER',
      value: 1 - totalMaxData,
      color: fillColor[fillColor.length - 1],
      isI18n: true,
    })

    return formatData
  }, [chartData])

  // Render tooltip
  const getTooltipContent = () => {
    return (
      <div className={style.chartTooltip}>
        <Span
          style={{ fontSize: 10, fontWeight: 400 }}
          className={style.chartTooltipDate}
        >
          {chartData.label}
        </Span>
        <ul>
          {getFormatData.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  <Translate value={item.text} />:
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className={style.chartWrapper}>
      <PieChartWithFooter
        data={getFormatData}
        width={width}
        height={height}
        pieChartProps={{ radius: 35 }}
        renderTooltip={getTooltipContent}
        isUseContainerFooter
      />
    </div>
  )
}

PieLineLabelChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.array.isRequired,
  fontSize: PropTypes.number,
  pieKeys: PropTypes.array.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
}
