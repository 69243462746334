import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../../core/services/market/marketInDepth/IndexStatisticService'
import { handleExport } from '../../../../../../utils/Export'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'

export const getIndexStatisticsForeignOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/foreign/GET_INDEX_STATISTICS_FOREIGN_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsForeignOverview(params)
      if (response.success) {
        if (params.TimeFrequency === PRICE_DATA_TIME_SELECT.CUSTOM) {
          dispatch(getIndexStatisticsForeignOverviewTotal(params))
        }
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexStatisticsForeignDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/foreign/GET_INDEX_STATISTICS_FOREIGN_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsForeignDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadStatisticsForeignOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/foreign/DOWNLOAD_STATISTICS_FOREIGN_OVERVIEW',
  async (data) => {
    const response =
      await IndexStatisticService.downloadStatisticsForeignOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadStatisticsForeignDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/foreign/DOWNLOAD_STATISTICS_FOREIGN_DETAIL',
  async (data) => {
    const response =
      await IndexStatisticService.downloadStatisticsForeignDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexStatisticsForeignOverviewTotal = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/foreign/GET_INDEX_STATISTICS_FOREIGN_OVERVIEW_TOTAL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsForeignOverviewTotal(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
