import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addIndicator } from '../store/slice'

const useAddDefaultIndicator = (defaultIndicators) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addIndicator(defaultIndicators))
  }, [defaultIndicators])
}

export default useAddDefaultIndicator
