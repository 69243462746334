import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { TABS } from '../constants'

const initialState = {
  filters: {
    type: TABS.SECTORS,
    month: 0,
    year: 0,
  },
  isDisableCallApi: false,
}

export const slice = createSlice({
  name: 'fund/fundProfile/portfolio/assetAllocation',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    changeIsDisableCallApi: (state, action) => {
      state.isDisableCallApi = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const selectFilters = (state) => state[slice.name].filters
export const selectIsDisableCallApi = (state) =>
  state[slice.name].isDisableCallApi

export const { changeFilter, changeIsDisableCallApi } = slice.actions

register(slice.name, slice.reducer)
