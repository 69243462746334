import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../ScrollComponent'
import {
  changeCurrentChartLayout,
  selectCurrentChartLayout,
} from '../../store/slice'
import { ImageWithLabel } from '../image/ImageWithLabel'
import style from './index.module.css'

export const LeftContent = ({ data }) => {
  const dispatch = useDispatch()
  const currentChartLayout = useSelector(selectCurrentChartLayout)

  const onClick = (currentChartLayout) => () => {
    dispatch(changeCurrentChartLayout(currentChartLayout))
  }

  return (
    <div className={style.container}>
      <div style={{ width: 300, height: 430, marginTop: 12 }}>
        <ScrollComponent>
          <div className={style.column}>
            {data.map((item, index) => {
              return (
                <ImageWithLabel
                  key={index}
                  width={124}
                  height={89}
                  label={item.name}
                  imgSrc={item.thumbnails}
                  appendStyle={{
                    marginBottom: index + 1 !== data.length ? 30 : 0,
                  }}
                  onClick={onClick(item)}
                  isActive={
                    currentChartLayout.chartLayoutId === item.chartLayoutId
                  }
                />
              )
            })}
          </div>
        </ScrollComponent>
      </div>
    </div>
  )
}
