import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../common/html/Span'
import { getUnique } from './../../utils/Common'
import style from './tableFooter.module.css'

export const TableFooter = ({ listFooterText, appendStyle }) => {
  return (
    <div className={style.unitTitleNote} style={appendStyle}>
      {listFooterText.map((item, index) => {
        return (
          <Span key={index} style={{ fontSize: 11 }}>
            <Translate
              key={getUnique() + index}
              value={item.text}
              style={item.style}
            />
          </Span>
        )
      })}
    </div>
  )
}

TableFooter.propTypes = {
  listFooterText: PropTypes.array,
  appendStyle: PropTypes.object,
}

TableFooter.defaultProps = {
  listFooterText: [
    {
      text: 'common.table.TABLE_FOOTER_TEXT',
      style: {},
    },
  ],
  appendStyle: {
    marginTop: 10,
  },
}
