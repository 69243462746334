import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PanelBalanceOfTrade } from './balanceOfTrade/PanelBalanceOfTrade'
import { PanelGrowthCategory } from './growthCategory/PanelGrowthCategory'
import { PanelImportExportChart } from './importExportChart/PanelImportExportChart'
import { PanelOutstandingTarget } from './outstandingTarget/PanelOutstandingTarget'

const MAP_KEY = {
  BALANCE_OF_TRADE: 'BALANCE OF TRADE',
  GROWTH_TARGET: 'GROWTH TARGET',
  IMPORT_EXPORT: 'IMPORT EXPORT',
  OUTSTANDING_TARGET: 'OUTSTANDING TARGET',
}

const MIN_SIZE = {
  [`${MAP_KEY.BALANCE_OF_TRADE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.GROWTH_TARGET}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.IMPORT_EXPORT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.OUTSTANDING_TARGET}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.BALANCE_OF_TRADE}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(45% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.BALANCE_OF_TRADE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.BALANCE_OF_TRADE}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GROWTH_TARGET,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GROWTH_TARGET,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.IMPORT_EXPORT,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.OUTSTANDING_TARGET,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GROWTH_TARGET}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(45% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.GROWTH_TARGET}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.GROWTH_TARGET}`].minHeight,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.IMPORT_EXPORT}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(55% - ${COMPONENT.MARGIN}px)`,
    top: `calc(45% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.IMPORT_EXPORT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.IMPORT_EXPORT}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.OUTSTANDING_TARGET,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.OUTSTANDING_TARGET}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(55% - ${COMPONENT.MARGIN}px)`,
    top: `calc(45% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.OUTSTANDING_TARGET}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.OUTSTANDING_TARGET}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelBalanceOfTrade
          panelRefs={panelRefs}
          panelKey={MAP_KEY.BALANCE_OF_TRADE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelGrowthCategory
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GROWTH_TARGET}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelImportExportChart
          panelRefs={panelRefs}
          panelKey={MAP_KEY.IMPORT_EXPORT}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelOutstandingTarget
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OUTSTANDING_TARGET}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
