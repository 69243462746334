import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../utils/ReducerRegistry'

const slice = createSlice({
  name: 'screens/dashboard',
  initialState: {
    isShow: false,
  },
  reducers: {
    changeIsShow: (state, action) => {
      state.isShow = action.payload
    },
  },
})

export const selectIsShow = (state) => state[slice.name].isShow
export const { changeIsShow } = slice.actions

register(slice.name, slice.reducer)
