import { TYPE } from '../../../../common/chart/footer/Item'
import { MONTH_KEYS } from '../../constants'
import { CASH_TOTAL_ROW_ID } from '../panelMoneySupplyCredit/OtherCellColumn'
import { LINE_CHART_COLORS } from './constants'

export const renderListFooter = (lineKey) => {
  return lineKey.map((item, index) => {
    return {
      text: `${item}`,
      type: TYPE.SQUARE,
      before: {
        bgColor: LINE_CHART_COLORS[index],
      },
      isI18n: false,
    }
  })
}

export const handleLineChartData = (
  lineChartData,
  isValue,
  locale,
  dropdownValue,
) => {
  const months = getDataNotDuplicates(lineChartData, 'month').sort(
    (a, b) => a - b,
  )

  return months.map((month) => {
    const dataWithMonth = lineChartData.filter(
      (element) => element.month === month,
    )
    const valuesWithYearKey = dataWithMonth.reduce((prev, cur) => {
      return {
        ...prev,
        [cur.year]:
          isValue && dropdownValue !== CASH_TOTAL_ROW_ID
            ? cur.value / 1000000000000
            : cur.value * 100,
      }
    }, {})
    return {
      ...valuesWithYearKey,
      month: MONTH_KEYS[month][locale],
    }
  })
}

export const getDataNotDuplicates = (data, key) => {
  return data
    .filter((item, index, arr) => {
      return index === arr.findIndex((element) => element[key] === item[key])
    })
    .map((item) => item[key])
}

export const getTooltipContentData = (data, key) => {
  return key
    ?.map((item) => {
      return {
        label: item,
        value: data[item],
      }
    })
    .reverse()
}
