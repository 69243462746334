import { FundCenterHolding } from '../../fund/fundCenter/holding'
import { FundCenterOverview } from '../../fund/fundCenter/overview'
import { FundCenterStatistic } from '../../fund/fundCenter/statistic'
import { FundProfileFinancialReport } from '../../fund/fundProfile/financialReport'
import { FundProfileNavData } from '../../fund/fundProfile/navData'
import { FundProfilePeriodicalReport } from '../../fund/fundProfile/periodicalReport'
import { FundProfilePortfolio } from '../../fund/fundProfile/portfolio'
import { FundProfile } from '../../fund/fundProfile/profile'

export const FUND_ROUTE = {
  name: 'constants.route.fund.LABEL',
  routeDefault: '/fund/fund-center/overview',
  components: {
    'fund-center': {
      name: 'constants.route.fund.fundCenter.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/fund/fund-center/overview',
          name: 'constants.route.fund.fundCenter.overview.LABEL',
          renderMain: () => <FundCenterOverview />,
        },
        statistic: {
          route: '/fund/fund-center/statistic',
          name: 'constants.route.fund.fundCenter.statistic.LABEL',
          renderMain: () => <FundCenterStatistic />,
        },
        holding: {
          route: '/fund/fund-center/holding',
          name: 'constants.route.fund.fundCenter.holding.LABEL',
          renderMain: () => <FundCenterHolding />,
        },
      },
    },
    'fund-profile': {
      name: 'constants.route.fund.fundProfile.LABEL',
      hasChildren: true,
      components: {
        profile: {
          route: '/fund/fund-profile/profile',
          name: 'constants.route.fund.fundProfile.profile.LABEL',
          renderMain: () => <FundProfile />,
        },
        portfolio: {
          route: '/fund/fund-profile/portfolio',
          name: 'constants.route.fund.fundProfile.portfolio.LABEL',
          renderMain: () => <FundProfilePortfolio />,
        },
        navData: {
          route: '/fund/fund-profile/nav-data',
          name: 'constants.route.fund.fundProfile.navData.LABEL',
          renderMain: () => <FundProfileNavData />,
        },
        financialReport: {
          route: '/fund/fund-profile/financial-report',
          name: 'constants.route.fund.fundProfile.financialReport.LABEL',
          renderMain: () => <FundProfileFinancialReport />,
        },
        periodicalReport: {
          route: '/fund/fund-profile/periodical-report',
          name: 'constants.route.fund.fundProfile.periodicalReport.LABEL',
          renderMain: () => <FundProfilePeriodicalReport />,
        },
      },
    },
  },
}
