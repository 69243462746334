import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { keySettingChart } from '../../../constants.js'
import { changeStyleSetting } from '../../../store/slice.js'
import { listPosition } from './constants.js'
import SettingStyle from './SettingStyle'
import style from './style.module.css'
import Switch from './Switch'
import useGetDataStyle from './useGetDataStyle.js'

const YAxisPosition = ({
  checked,
  onChange,
  positionGroupYAxis,
  changePositionGroupYAxis,
}) => {
  const dispatch = useDispatch()
  const { yAxisPosition, styleSetting } = useGetDataStyle()

  const handleChangeStyleSetting = (data) => {
    dispatch(
      changeStyleSetting({
        ...styleSetting,
        [keySettingChart.Y_AXIS_POSITION]: data,
      }),
    )
  }

  return (
    <div className="mb-8 mt-8">
      <div className={style.label}>
        <Translate value="financialChart.setting.Y_AXIS_POSITION" />
      </div>
      <SettingStyle
        style={yAxisPosition}
        setStyle={handleChangeStyleSetting}
        disablePosition
        fontSizeMax={16}
      />
      <div className="mt-8">
        <Switch
          title={'financialChart.GROUP_AXIS'}
          checked={checked}
          onChange={onChange}
        />
      </div>
      <div className="mt-8 d-flex ali-center">
        <ul className={`list-check ${style.radioCheckbox}`}>
          {listPosition.map((item, index) => (
            <li key={index} style={{ display: !item.showOption && 'none' }}>
              <label>
                <input
                  name="position y axis"
                  type="radio"
                  className={`radiobox ${style.radioBox}`}
                  value={positionGroupYAxis}
                  checked={positionGroupYAxis === item.value ? true : false}
                  onChange={() => changePositionGroupYAxis(item.value)}
                />
                <Translate value={item.name} />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default YAxisPosition
