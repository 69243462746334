import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../common/hooks/usePackagePermission'
import { Span } from '../../../../common/html/Span'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import { PopupCanNotAccess } from '../../../../layout/PopupCanNotAccess'
import Content from './Content'
import {
  ALL_PUBLIC_PRIVATE_TABS,
  MONTH_QUARTER_YEAR_RADIO,
  TIME_FREQUENCY,
} from './constants'
import style from './index.module.css'
import {
  changeActiveMethodType,
  changeActiveTimeType,
  selectActiveMethodType,
  selectActiveTimeType,
} from './store/slice'

export const MarketStatistics = () => {
  const dispatch = useDispatch()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const isActive = useSelector(selectActiveMethodType)
  const radioValue = useSelector(selectActiveTimeType)

  const handleChangeMethodType = (item) => {
    dispatch(changeActiveMethodType(item.value))
  }

  const handleChangeRadio = (value) => {
    dispatch(changeActiveTimeType(value))
  }

  return (
    <>
      <div className="d-flex ali-center mb-8">
        <div className={`${style.menuContainer}`}>
          <DispatchActionTab
            data={ALL_PUBLIC_PRIVATE_TABS}
            activeTab={isActive}
            onChangeTab={handleChangeMethodType}
            containerStyle={{ width: '100%' }}
            itemStyle={{
              width: 'calc(100% / 3)',
              fontWeight: 600,
            }}
          />
        </div>
        <VerticalLine ml={16} mr={16} h={20} />
        <div className="d-flex ali-center">
          {MONTH_QUARTER_YEAR_RADIO.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
                onClick={() => handleChangeRadio(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === radioValue}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 28px)`,
        }}
      >
        {!isTrial || (isTrial && radioValue === TIME_FREQUENCY.MONTHLY) ? (
          <Content />
        ) : (
          <PopupCanNotAccess />
        )}
      </div>
    </>
  )
}
