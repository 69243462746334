import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize } from '../../utils/FontSize'

export const Label = ({ children, style, ...others }) => {
  return (
    <label
      style={{ ...style, fontSize: getFontSize(style?.fontSize) }}
      {...others}
    >
      {children}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

Label.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
