import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Col from '../../common/Col'
import Row from '../../common/Row'
import TableData from '../../common/TableData'
import ToiTableHead from '../../common/ToiTableHead'
import { FIELD_NAME, TYPE_CONFIG } from '../../configs'
import { mappingData } from '../../helper'
import { selectChoose, selectIncomeStructure } from '../../store/slice'
import NonNii from '../childrenTable/NonNii'
import Separator from '../Separator'
import {
  handleToiTableData,
  selectMountChildrenTable,
  selectToiTableData,
  toggleChildrenTable,
} from '../store/slice'
import style from './toiTable.module.css'

export const TOI_FORMAT = '% TOI'

const ToiTable = () => {
  const dispatch = useDispatch()
  const [mappingIncomeStructure, setMappingIncomeStructure] = useState([])
  const { IND, GR } = TYPE_CONFIG
  const { PFIBA_G, NNII_G } = FIELD_NAME

  const choose = useSelector(selectChoose)
  const toiTableData = useSelector(selectToiTableData)
  const mountChildrenTable = useSelector(selectMountChildrenTable)
  const incomeStructureData = useSelector(selectIncomeStructure)

  useEffect(() => {
    if (toiTableData.length && incomeStructureData.length) {
      setMappingIncomeStructure(mappingData(toiTableData, incomeStructureData))
      dispatch(
        handleToiTableData(mappingData(toiTableData, incomeStructureData)),
      )
    }
  }, [toiTableData, incomeStructureData])

  const handleToggleChildrenTable = (fieldName, isCollapse) => {
    if (isCollapse) {
      if (fieldName === mountChildrenTable) {
        dispatch(toggleChildrenTable(''))
      } else {
        dispatch(toggleChildrenTable(fieldName))
      }
    }
  }

  const addRightBorder = (colSpan, colOrder, fieldName, type) => {
    if (colSpan === 1) {
      if (type === IND && (colOrder === 1 || colOrder === 4)) {
        return {
          borderBottom: '1px solid #3e485a',
          borderRight: '1px solid #3e485a',
        }
      }
      if (
        type === IND &&
        (colOrder === 0 || colOrder === 2 || colOrder === 3)
      ) {
        return {
          borderBottom: '1px solid #3e485a',
          borderRight: '1px solid #1b2029',
        }
      }
      if (colOrder === 0 || colOrder === 2 || colOrder === 3) {
        return { borderRight: '1px solid #1b2029' }
      }
    }
    if (fieldName === PFIBA_G || fieldName === NNII_G) {
      return { borderRight: '1px solid #3e485a' }
    }
  }

  return (
    <>
      {mappingIncomeStructure.length > 0 && (
        <table className={style.toiTable}>
          <tbody>
            {mappingIncomeStructure.map((row, index) => {
              const cols = row.map((col) => {
                if (col?.type === GR) {
                  return (
                    <ToiTableHead
                      key={col?.indicatorMappingId}
                      colSpan={col.colSpan}
                      isCollapse={col?.expandable}
                      isActive={mountChildrenTable === col?.fieldName}
                      onClick={() =>
                        handleToggleChildrenTable(
                          col?.fieldName,
                          col?.expandable,
                        )
                      }
                      styleProps={{
                        cursor: col?.expandable ? 'pointer' : 'default',
                        ...addRightBorder(
                          col?.colSpan,
                          col?.colOrder,
                          col?.fieldName,
                          col?.type,
                        ),
                      }}
                    >
                      {col?.indicator}
                    </ToiTableHead>
                  )
                } else {
                  return (
                    <Col
                      key={col?.indicatorMappingId}
                      colSpan={col.colSpan}
                      id={col?.alias}
                      isChoose={choose === col?.fieldName}
                      isActive={mountChildrenTable === col?.parentField}
                      styleProps={{
                        ...addRightBorder(
                          col?.colSpan,
                          col?.colOrder,
                          col?.fieldName,
                          col?.type,
                        ),
                      }}
                      chart1Type={col?.chart1Type}
                      chart2Type={col?.chart2Type}
                      fieldName={col?.fieldName}
                      fieldName_1={col?.fieldName_1}
                      indicator={col?.indicator}
                      relativeIndicator={col?.relativeIndicator}
                      alias={col?.alias}
                      valueFormat={col?.format}
                    >
                      <TableData
                        alias={col?.alias}
                        parentData={col?.value}
                        childrenData={col?.relativeValue}
                        afterParentData={
                          col?.indicator === '%TOI' ? TOI_FORMAT : ''
                        }
                        afterChildrenData={
                          col?.relativeIndicator === '%YoY' ? 'YoY' : ''
                        }
                        relativeField={col?.relativeField}
                        valueFormat={col?.format}
                        multiplier={col?.multiplier}
                      />
                    </Col>
                  )
                }
              })
              return <Row key={index}>{cols.map((col) => col)}</Row>
            })}
            {!!mountChildrenTable && <Separator />}
            {mountChildrenTable === NNII_G && <NonNii />}
          </tbody>
        </table>
      )}
    </>
  )
}

export default ToiTable
