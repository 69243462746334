import { SYMBOL_TYPES } from './constants'
import { store } from '../../../../app/store'
import { keyBy } from '../../../utils/Common'

export const getSymbolType = async (symbolName) => {
  const stocksById = keyBy(
    store.getState()['common/masterData'].stocksHohaup,
    'ticker',
  )
  const indicesById = keyBy(
    store.getState()['common/masterData'].indices,
    'groupCode',
  )
  const economiesById = keyBy(
    store.getState()['common/tradingView'].economies,
    'code',
  )
  const futuresById = keyBy(
    store.getState()['common/tradingView'].futures,
    'ticker',
  )
  const coveredWarrantsById = keyBy(
    store.getState()['common/tradingView'].coveredWarrants,
    'ticker',
  )

  if (stocksById[symbolName]) {
    return {
      symbolType: SYMBOL_TYPES.STOCK,
      id: stocksById[symbolName].organizationId,
    }
  }

  if (indicesById[symbolName]) {
    return {
      symbolType: SYMBOL_TYPES.INDICES,
      id: indicesById[symbolName].groupId,
    }
  }

  if (economiesById[symbolName]) {
    return {
      symbolType: economiesById[symbolName].type,
      id: economiesById[symbolName].id,
    }
  }

  if (coveredWarrantsById[symbolName]) {
    return {
      symbolType: SYMBOL_TYPES.COVERED_WARRANT,
      id: coveredWarrantsById[symbolName].id,
    }
  }

  if (futuresById[symbolName]) {
    return {
      symbolType: SYMBOL_TYPES.FUTURES,
      id: futuresById[symbolName].id,
    }
  }

  return {
    symbolName: null,
    id: null,
  }
}
