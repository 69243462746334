export const event = {
  COMPANY: 'Doanh nghiệp',
  EXCHANGE: 'Sàn',
  SECTOR: 'Ngành',
  ALL_SECTOR: 'Tất cả ngành',
  All_EXCHANGE: 'Tất cả sàn',
  ALL_COMPANY: 'Tất cả doanh nghiệp',
  ALL_EVENT_TYPE: 'Tất cả loại sự kiện',
  ANNOUNCEMENT_DATE: 'Ngày thông báo',
  RECORD_DATE: 'Ngày ĐKCC',
  EXRIGHT_DATE: 'Ngày GDHKQ',
  EXCERCISE_DATE: 'Ngày thực hiện',
  FILTER: 'BỘ LỌC',
  EVENT_TYPES: 'Loại sự kiện',
  SHOW_ALL: 'Tất cả',
  EVENT: 'SỰ KIỆN',
  WATCHLIST: 'Danh mục',
  NO_WATCHLIST: 'Không có danh mục',
  GO_TO: 'Đi đến',
  CREATE_WATCHLIST: 'Tạo danh mục',
  SELECT_WL_PLH: 'Chọn danh mục',
  SEARCH_PLH: 'Tìm kiếm',
  doubleClickText: '(click để xem chi tiết)',
  TABLE_NOTE: 'Khối lượng: x 1,000',
  TOTAL_RESULT: 'Tổng số kết quả : %{totalRecords} kết quả',
  ATTACHMENT: 'Tài liệu đính kèm: ',
  MILLION_VND: 'Triệu VND',
  indicators: {
    date: 'NGÀY THÔNG BÁO',
    ticker: 'MÃ',
    event: 'SỰ KIỆN',
    description: 'CHI TIẾT',
    exrightDate: 'NGÀY GDKHQ ',
    recordDate: 'NGÀY ĐKCC',
    payoutDate: 'NGÀY THỰC HIỆN',
    issueDate: 'NGÀY PHÁT HÀNH',
    valuePerShare: 'GIÁ TRỊ',
    exerciseRate: 'TỶ LỆ',
    dividendYear: 'NĂM TRẢ',
    duration: 'KỲ TRẢ',
    source: 'NGUỒN',
    period: 'KỲ BÁO CÁO',
    netRevenue: 'Doanh thu thuần (Tỷ VND)',
    revenueGrowth: 'Tăng trưởng DT thuần YoY',
    netProfit: 'LNST (Tỷ VND)',
    profitGrowth: 'Tăng trưởng LNST YoY',
    listingDate: 'NGÀY NIÊM YẾT',
    method: 'HÌNH THỨC',
    issueVolume: 'KHỐI LƯỢNG PHÁT HÀNH',
    totalValue: 'GIÁ TRỊ PHÁT HÀNH',
    year: 'NĂM',
    status: 'TRẠNG THÁI',
    address: 'ĐỊA ĐIỂM',
    meetingDate: 'THỜI GIAN',
    symbol: 'MÃ CK',
    growth: '% Tăng trưởng YoY',
    type: 'LOẠI SỰ KIỆN',
    futureName: 'Tên hợp đồng',
    owner: 'Đối tượng',
    position: 'CHỨC VỤ BAN LÃNH ĐẠO',
    ownedPercentage: 'SỞ HỮU SAU GD',
    exerciseDate: 'NGÀY THỰC HIỆN',
    exerciseRatio: 'TỶ LỆ THỰC HIỆN',
    city: 'THÀNH PHỐ',
    time: 'THỜI GIAN DỰ KIẾN',
    organizationName: 'TÊN TỔ CHỨC',
    roleOwner: 'TÊN BAN LÃNH ĐẠO',
    shareBeforeTrade: 'SLCP TRƯỚC GD',
    shareRegister: 'SLCP ĐĂNG KÝ',
    actionTypeName: 'LOẠI GIAO DỊCH',
    shareAcquire: 'SLCP THỰC HIỆN',
    shareAfterTrade: 'SLCP SAU GD',
    ownershipAfterTrade: 'SỞ HỮU SAU GD',
    relative: 'QUAN HỆ',
    endDateId: 'NGÀY KẾT THÚC',
    oldExchange: 'SÀN CŨ',
    newExchange: 'SÀN MỚI',
    startDateId: 'NGÀY BẮT ĐẦU',
  },
  hyperlink: {
    COMPANY_OVERVIEW: 'Tổng quan doanh nghiệp',
    COMPANY_NEWS: 'Tin tức doanh nghiệp',
    FINANCIAL_DATA: 'Dữ liệu tài chính',
    BUSINESS_MODAL: 'Mô hình kinh doanh',
    SECTOR_OVERVIEW: 'Tổng quan ngành',
    TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  },
  cashDividendForm: {
    TITLE:
      '%{ticker} -Trả cổ tức %{durationName}, %{sourceDividendYear}  bằng tiền %{valuePerShare} VND/CP',
    HEADER:
      '%{organizationName} trả cổ tức %{durationName}, %{sourceDividendYear}  bằng tiền : ',
    AN_DATE: 'Ngày thông báo : ',
    EXRIGHT_DATE: 'Ngày GDKHQ : ',
    RECORD_DATE: ' Ngày ĐKCC : ',
    PAYOUT_DATE: 'Ngày thực hiện : ',
    EXERCISE_RATIO:
      'Tỷ lệ thực hiện : %{exerciseRatio}/ mệnh giá (%{valuePerShare} đồng/cổ phiếu)',
  },
  shareIssueForm: {
    TITLE: '%{ticker} - Niêm yết bổ sung %{planVolumn} cổ phiếu',
    HEADER: '%{organizationName} niêm yết cổ phiếu : ',
    ISSUE_METHOD: 'Hình thức phát hành : ',
    NUMBER_SHARES_PLANNED: 'KLCP dự kiến phát hành : ',
    ISSUE_PRICE: 'Giá thực hiện %{issuePrice} VND/CP',
    EXERCISE_RATIO: 'Tỷ lệ thực hiện : ',
    ISSUE_VOLUME: 'KLCP phát hành : ',
    TOTAL_VALUE: 'Giá trị phát hành : ',
    STATUS: 'Tình trạng : ',
    EXRIGHT_DATE: 'Ngày GDKHQ : ',
    ISSUE_DATE: 'Ngày thực hiện : ',
    LISTING_DATE: 'Ngày niêm yết : ',
  },
  directorDealForm: {
    AN_DATE: 'Ngày thông báo : ',
    START_DATE: 'Ngày bắt đầu : ',
    END_DATE: 'Ngày kết thúc : ',
    VOL_BEFORE: 'SLCP trước GD : ',
    VOL_REGISTER: 'SLCP đăng ký : ',
    ACTION_TYPE: 'Loại giao dịch : ',
    VOL_EXECUTED: 'SLCP thực hiện : ',
    VOL_AFTER: 'SLCP sau GD : ',
    OWNERSHIP_AFTER_TRADING: 'Sở hữu sau GD : ',
    STATUS: 'Tình trạng : ',
    POSITION_NAME: 'Chức vụ ban lãnh đạo : ',
    RELATED_PERSON: 'Người liên quan : ',
    RELATIVE: 'Quan hệ : ',
    EVENT_TYPE: 'Loại sự kiện : ',
  },
}
