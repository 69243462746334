import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { range } from '../../../../utils/Common'
import { Span } from '../../../html/Span'
import style from '../index.module.css'
import { selectValueDetailPopupIndicator } from '../store/slice'
import ItemSelect from './ItemSelect'

const YearFromTo = ({
  keyFrom,
  keyTo,
  disabled,
  minYear,
  maxYear,
  isSmallInput,
  appendStyleInput,
  appendStyleTextTo,
}) => {
  const valueFrom =
    useSelector(selectValueDetailPopupIndicator(keyFrom)) || maxYear
  const valueTo = useSelector(selectValueDetailPopupIndicator(keyTo)) || maxYear

  const selectDataFrom = range(minYear, valueTo)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })
  const selectDataTo = range(valueFrom, maxYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })
  const classInput = isSmallInput
    ? style.smallYearFromTo
    : style.yearFromToSelect

  return (
    <div className="d-flex j-b">
      <div style={appendStyleInput} className={classInput}>
        <ItemSelect
          defaultValue={maxYear - 5}
          keySelect={keyFrom}
          selectData={selectDataFrom}
          disabled={disabled}
        />
      </div>
      <div
        style={appendStyleTextTo}
        className={`${style.textTo} ${disabled ? style.disabled : ''}`}
      >
        <Span style={{ fontSize: appendStyleTextTo?.fontSize }}>
          <Translate value="common.popup.TO" />
        </Span>
      </div>
      <div style={appendStyleInput} className={classInput}>
        <ItemSelect
          defaultValue={maxYear}
          keySelect={keyTo}
          selectData={selectDataTo}
          disabled={disabled}
        />
      </div>
    </div>
  )
}

YearFromTo.propTypes = {
  keyFrom: PropTypes.string.isRequired,
  keyTo: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  mostRecentDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  isSmallInput: PropTypes.bool,
}

YearFromTo.defaultProps = {
  disabled: false,
  maxYear: new Date().getFullYear(),
  minYear: 2000,
  isSmallInput: false,
}

export default YearFromTo
