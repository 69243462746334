import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'

export const getSegmentationChartData = createAsyncThunk(
  'sector/sectorConstituents/SEGMENTATION_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getSegmentationChartData(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
