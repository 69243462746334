import defaultSquareImg from './default-news-square.png'
import defaultLargeImg from './default-news.png'
import style from './index.module.css'
import PropTypes from 'prop-types'

export const Image = ({
  url,
  width,
  height,
  isCrop,
  className,
  isDefaultSquareImg,
}) => {
  const defaultImg = isDefaultSquareImg ? defaultSquareImg : defaultLargeImg
  return isCrop ? (
    <img
      className={[style.img, className].join(' ')}
      src={url || defaultImg}
      alt="item"
      width={width}
      height={height}
      onError={(e) => {
        e.target.onerror = null
        e.target.src = defaultImg
      }}
    />
  ) : (
    <img
      className={[style.img, className].join(' ')}
      src={url || defaultImg}
      alt="item"
      onError={(e) => {
        e.target.onerror = null
        e.target.src = defaultImg
      }}
    />
  )
}

Image.propTypes = {
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  isCrop: PropTypes.bool,
  isDefaultSquareImg: PropTypes.bool,
}

Image.defaultProps = {
  width: 107,
  height: 107,
  isCrop: true,
  isDefaultSquareImg: true,
}
