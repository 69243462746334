import PropTypes from 'prop-types'
import { Span } from '../../../html/Span'
import TextEllipsis from '../../../textEllipsis'
import { Image } from './Image'
import style from './index.module.css'

export const ImageWithLabel = ({
  label,
  width,
  height,
  imgSrc,
  appendStyle,
  isActive,
  onClick,
}) => {
  return (
    <div style={appendStyle}>
      <Image
        width={width}
        height={height}
        imgSrc={imgSrc}
        isActive={isActive}
        onClick={onClick}
      />
      <Span
        style={{ width, fontSize: 14 }}
        className={[style.title, isActive ? style.titleActive : ''].join(' ')}
      >
        <TextEllipsis text={label} />
      </Span>
    </div>
  )
}

ImageWithLabel.propTypes = {
  label: PropTypes.string,
  imgSrc: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  appendStyle: PropTypes.object,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

ImageWithLabel.defaultProps = {
  label: '',
  imgSrc: '',
  appendStyle: {},
  isActive: false,
  onClick: () => {},
}
