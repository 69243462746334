import { createSlice } from '@reduxjs/toolkit'
import { hiddenIndustryListByLevel } from '../../../../../../../configs/HiddenIndustryList'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { listLevel } from '../../../constants'

import { pricePerformance } from './thunk'

const slice = createSlice({
  name: 'sector/sectorStatistics/overview/pricePerformance/tableSector',
  initialState: {
    sectorLever: listLevel[1].value,
    idsSector: [],
    dataById: {},
    isLoading: true,
    listSector: [],
    isGetData: true,
    reCalcWidths: false,
    dataTable: [],
    initialIds: [],
    objectSort: {},
    sectorDefault: true,
  },
  reducers: {
    changeSectorLever: (state, action) => {
      state.sectorLever = action.payload
    },
    addSectorToList: (state, action) => {
      state.isGetData = true
      state.listSector = [...state.listSector, action.payload]
    },
    removeSectorToList: (state, action) => {
      state.isGetData = false
      state.listSector = state.listSector.filter(
        (sector) => sector.icbId !== action.payload,
      )
    },
    subscribeSector: (state, action) => {
      const sector = action.payload[0]
      const id = sector.icbId
      state.dataById[id].percentPriceChange = sector.percentIndexChange
    },
    changeIsGetData: (state, action) => {
      state.isGetData = action.payload
    },
    sort: (state, action) => {
      state.idsSector = getIdsFromProps(
        state.idsSector,
        state.dataById,
        action.payload,
        state.initialIds,
      )
      state.objectSort = action.payload
    },
    changeSectorDefault: (state, action) => {
      state.sectorDefault = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pricePerformance.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(pricePerformance.fulfilled, (state, action) => {
      const listSector = action.payload.filter(
        (sector) =>
          !hiddenIndustryListByLevel[state.sectorLever].includes(sector.icbId),
      )
      const newArr = listSector.map((sector) => ({
        ...sector,
        icbName: sector.icbName + ' L' + state.sectorLever,
      }))
      state.dataTable = newArr
      state.initialIds = state.idsSector = newArr.map((sector) => sector.icbId)
      state.dataById = keyBy(newArr, 'icbId')
      state.isLoading = false
      if (state.sectorDefault) {
        state.listSector = newArr.slice(0, 10).map((item, index) => {
          return { ...item, colorIndex: index }
        })
      } else {
        newArr.forEach((element) => {
          state.listSector.find(
            (item) =>
              item.icbId === element.icbId && (item.icbName = element.icbName),
          )
        })
      }
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(pricePerformance.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoadingTableSector = (state) => state[slice.name].isLoading
export const selectSectorIds = (state) => state[slice.name].idsSector
export const selectSectorValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectSectorLever = (state) => state[slice.name].sectorLever
export const selectListSector = (state) => state[slice.name].listSector
export const selectKeyGetData = (state) => state[slice.name].isGetData
export const selectReCalcWidthsTable = (state) => state[slice.name].reCalcWidths
export const selectDataTable = (state) => state[slice.name].dataTable
export const selectObjectSort = (state) => state[slice.name].objectSort

export const {
  changeSectorLever,
  addSectorToList,
  removeSectorToList,
  subscribeSector,
  changeIsGetData,
  sort,
  changeSectorDefault,
} = slice.actions

register(slice.name, slice.reducer)
