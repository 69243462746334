import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelDependsOnSector from './panelDependsOnSector/PanelDependsOnSector'
import PanelKeyRatios from './panelKeyRatios/PanelKeyRatios'
import PanelNewsAndEvents from './panelNewsAndEvents/PanelNewsAndEvents'
import PanelSegmentation from './panelSegmentation/PanelSegmentation'
import PanelTopCompanies from './panelTopCompanies/PanelTopCompanies'
import PanelEarningGrowth from './panelTopRevenue/PanelTopRevenue'

const MAP_KEY = {
  NEWS_AND_EVENTS: 'NEWS_AND_EVENTS',
  TOP_REVENUE: 'TOP_REVENUE',
  DEPENDS_ON_SECTOR: 'DEPENDS_ON_SECTOR',
  KEY_RATIOS: 'KEY_RATIOS',
  TOP_COMPANIES_PERFORMANCE: 'TOP_COMPANIES_PERFORMANCE',
  SEGMENTATION: 'SEGMENTATION',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.NEWS_AND_EVENTS]: {
    minWidth: 330,
    minHeight: 300,
  },
  [MAP_KEY.TOP_REVENUE]: {
    minWidth: 440,
    minHeight: 300,
  },
  [MAP_KEY.DEPENDS_ON_SECTOR]: {
    minWidth: 330,
    minHeight: 300,
  },
  [MAP_KEY.KEY_RATIOS]: {
    minWidth: 330,
    minHeight: 300,
  },
  [MAP_KEY.TOP_COMPANIES_PERFORMANCE]: {
    minWidth: 440,
    minHeight: 300,
  },
  [MAP_KEY.SEGMENTATION]: {
    minWidth: 330,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [MAP_KEY.NEWS_AND_EVENTS]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.NEWS_AND_EVENTS],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.TOP_REVENUE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.TOP_REVENUE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.DEPENDS_ON_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.KEY_RATIOS,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TOP_COMPANIES_PERFORMANCE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.SEGMENTATION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [MAP_KEY.TOP_REVENUE]: {
    width: `calc(40% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(30% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.TOP_REVENUE],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.NEWS_AND_EVENTS].minWidth + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.DEPENDS_ON_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [MAP_KEY.DEPENDS_ON_SECTOR]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.DEPENDS_ON_SECTOR],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.NEWS_AND_EVENTS].minWidth +
      MIN_SIZE_SETTING[MAP_KEY.TOP_REVENUE].minWidth +
      4 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.KEY_RATIOS]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.KEY_RATIOS],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.NEWS_AND_EVENTS].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.TOP_COMPANIES_PERFORMANCE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [MAP_KEY.TOP_COMPANIES_PERFORMANCE]: {
    width: `calc(40% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(30% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.TOP_COMPANIES_PERFORMANCE],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.TOP_REVENUE].minHeight + 2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.KEY_RATIOS].minWidth + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.SEGMENTATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [MAP_KEY.SEGMENTATION]: {
    width: `calc(30% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(70% + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.SEGMENTATION],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.DEPENDS_ON_SECTOR].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.KEY_RATIOS].minWidth +
      MIN_SIZE_SETTING[MAP_KEY.TOP_COMPANIES_PERFORMANCE].minWidth +
      4 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelNewsAndEvents
        panelRefs={panelRefs}
        panelKey={MAP_KEY.NEWS_AND_EVENTS}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelEarningGrowth
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_REVENUE}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelDependsOnSector
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DEPENDS_ON_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelKeyRatios
        panelRefs={panelRefs}
        panelKey={MAP_KEY.KEY_RATIOS}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelTopCompanies
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_COMPANIES_PERFORMANCE}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelSegmentation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SEGMENTATION}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
