import userSettingProxy from '../proxies/UserSettingProxy'
import { ServiceBase } from './ServiceBase'

class UserSettingService extends ServiceBase {
  getGeneralInfo(params) {
    return this.applyMemoryCache(
      'UserSettingService.getGeneralInfo',
      params,
    )(() => userSettingProxy.getGeneralInfo(params))
  }

  getProfile(params) {
    return this.applyMemoryCache(
      'UserSettingService.getProfile',
      params,
    )(() => userSettingProxy.getProfile(params))
  }

  updateProfile(params) {
    return this.getData(() => userSettingProxy.updateProfile(params))
  }

  updateFafouriteContact(params) {
    return userSettingProxy.updateFafouriteContact(params)
  }
}

export default new UserSettingService()
