import { Foreign } from './foreign'
import { HistoricalStatistics } from './historicalPrice'
import { OrderedStatistics } from './orderedStatistics'
import { OverviewStatistics } from './overviewStatistics'
import { Proprietary } from './proprietary'

export const CW_STATISTICS_TAB = {
  OVERVIEW: 'overview',
  HISTORICAL: 'historical',
  FOREIGN: 'foreign',
  ORDER: 'order',
  PROPRIETARY: 'proprietary',
}

export const NAVIGATION_CW_STATISTICS = [
  {
    title: 'market.coveredWarrant.cwStatistics.overview.OVERVIEW',
    component: <OverviewStatistics />,
    value: CW_STATISTICS_TAB.OVERVIEW,
  },
  {
    title:
      'market.coveredWarrant.cwStatistics.historicalPrice.HISTORICAL_PRICE',
    component: <HistoricalStatistics />,
    value: CW_STATISTICS_TAB.HISTORICAL,
  },
  {
    title: 'market.coveredWarrant.cwStatistics.foreign.FOREIGN',
    component: <Foreign />,
    value: CW_STATISTICS_TAB.FOREIGN,
  },
  {
    title:
      'market.coveredWarrant.cwStatistics.orderStatistics.ORDER_STATISTICS',
    component: <OrderedStatistics />,
    value: CW_STATISTICS_TAB.ORDER,
  },
  {
    title: 'market.coveredWarrant.cwStatistics.proprietary.PROPRIETARY',
    component: <Proprietary />,
    value: CW_STATISTICS_TAB.PROPRIETARY,
  },
]
