import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TABLE_TYPE, UNIT_VALUE_CONSTANTS } from '../constant'
import { getDefaultYearQuarter } from '../helper'
import {
  handleReCalcMaxMeanValue,
  handleResponseTableChart,
} from '../tableChart/helper'
import {
  formatIndicatorMapping,
  handleResponseTableTree,
} from '../tableTree/helper'
import {
  getExchangeRates,
  getIndicatorMapping,
  getMaxPeriod,
  getPeerComparisonBalanceSheet,
  getPeerComparisonCredit,
  getPeerComparisonIncomeStatement,
  getPeerComparisonOwnership,
  getPeerComparisonPerformance,
  getPeerComparisonProfitability,
  getPeerComparisonValuation,
} from './thunk'

const defaultYearQuarter = getDefaultYearQuarter()

const initialState = {
  tableType: TABLE_TYPE.VALUATION,
  valueById: {},
  maxMinValueByColId: {},
  responseOrgIds: [],
  initialResponseOrgIds: [],
  filter: {
    quarter: null,
    year: null,
    currency: UNIT_VALUE_CONSTANTS.VND,
    unit: UNIT_VALUE_CONSTANTS.MILLION_VND,
  },
  rowIds: [],
  exchangeRates: {},
  tableConstants: [],
  loading: true,
  reCalcWidths: false,
}

const slice = createSlice({
  name: 'corporate/peerValuation/overview/peerComparison',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTableType: (state, action) => {
      state.tableType = action.payload
    },
    changeResponseOrgIds: (state, action) => {
      const newResponseOrgIds = action.payload
      state.responseOrgIds = [...newResponseOrgIds]

      if (
        ![TABLE_TYPE.BALANCE_SHEET, TABLE_TYPE.INCOME_STATEMENT].includes(
          state.tableType,
        )
      ) {
        handleReCalcMaxMeanValue(state, newResponseOrgIds)
      }
    },
    sort: (state, action) => {
      state.responseOrgIds = getIdsFromProps(
        state.responseOrgIds,
        state.valueById,
        action.payload,
        state.initialResponseOrgIds,
      )
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    resetIndicatorMapping: (state) => {
      state.tableConstants = []
      state.rowIds = []
      state.valueById = {}
    },
    changeLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    //Get Performance
    builder.addCase(getPeerComparisonPerformance.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonPerformance.fulfilled,
      handleResponseTableChart,
    )
    builder.addCase(getPeerComparisonPerformance.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get Valuation
    builder.addCase(getPeerComparisonValuation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonValuation.fulfilled,
      handleResponseTableChart,
    )
    builder.addCase(getPeerComparisonValuation.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get Ownership
    builder.addCase(getPeerComparisonOwnership.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonOwnership.fulfilled,
      handleResponseTableChart,
    )
    builder.addCase(getPeerComparisonOwnership.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get Credit
    builder.addCase(getPeerComparisonCredit.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPeerComparisonCredit.fulfilled, handleResponseTableChart)
    builder.addCase(getPeerComparisonCredit.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get Profitability
    builder.addCase(getPeerComparisonProfitability.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonProfitability.fulfilled,
      handleResponseTableChart,
    )
    builder.addCase(
      getPeerComparisonProfitability.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    //Get BalanceSheet
    builder.addCase(getPeerComparisonBalanceSheet.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonBalanceSheet.fulfilled,
      handleResponseTableTree,
    )
    builder.addCase(getPeerComparisonBalanceSheet.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get IncomeStatement
    builder.addCase(getPeerComparisonIncomeStatement.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getPeerComparisonIncomeStatement.fulfilled,
      handleResponseTableTree,
    )
    builder.addCase(
      getPeerComparisonIncomeStatement.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    //getMaxPeriod
    builder.addCase(getMaxPeriod.fulfilled, (state, { payload }) => {
      if (payload && payload[0]) {
        state.filter.quarter = payload[0].quarter || defaultYearQuarter.quarter
        state.filter.year = payload[0].year || defaultYearQuarter.year
      }
    })
    builder.addCase(getMaxPeriod.rejected, (state, action) => {
      state.filter.quarter = defaultYearQuarter.quarter
      state.filter.year = defaultYearQuarter.year
    })
    //Get IndicatorMapping
    builder.addCase(getIndicatorMapping.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getIndicatorMapping.fulfilled, (state, action) => {
      const { ids, tableConstants } = formatIndicatorMapping(
        action.payload || [],
      )
      state.tableConstants = tableConstants
      state.rowIds = ids
      state.valueById = keyBy(tableConstants, 'index')
    })
    //ExchangeRate
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      const exchangeRates = {}
      action.payload?.forEach(
        (item) =>
          (exchangeRates[`${item.quarter}-${item.year}`] = item.exchangeRate),
      )
      state.exchangeRates = exchangeRates
    })
  },
})

export const selectResponseOrgIds = (state) => state[slice.name].responseOrgIds
export const selectTableType = (state) => state[slice.name].tableType
export const selectValueById = (id, attr) => (state) => {
  const data = state[slice.name].valueById[id]
  return attr ? valByKeyWithDot(data, attr) : data
}

export const selectItemData = (id) => (state) => state[slice.name].valueById[id]
export const selectLoading = (state) => state[slice.name].loading
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths

export const selectMaxMinValueByColId = (colId) => (state) =>
  state[slice.name].maxMinValueByColId[colId]

export const selectFilter = (state) => state[slice.name].filter
export const selectExchangeRates = (state) => state[slice.name].exchangeRates
export const selectRowIds = (state) => state[slice.name].rowIds
export const selectTableConstants = (state) => state[slice.name].tableConstants

export const {
  resetStore,
  changeTableType,
  changeResponseOrgIds,
  changeFilter,
  changeLoading,
  resetIndicatorMapping,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
