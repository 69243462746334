import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CalendarPopper from '../../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../../common/calendar/inputs/InputDateCalendar'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../../common/hooks/usePackagePermission'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Icon } from '../../../../../common/html/Icon'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable'
import SelectCustom from '../../../../../common/selectCustom'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  MONTH_OPTIONS,
  QUARTER_OPTIONS,
  VALUE_TIME,
  yearOption,
} from '../constants'
import { selecDataL2, selectActiveMethodType } from '../store/slice'
import {
  MONTH_LIST_FILTER_EN,
  MONTH_LIST_FILTER_VI,
  Timefrequency,
  list_career,
  tableCirculateConstants,
  tableConstants,
  valueTimefrequency,
} from './constants'
import style from './index.module.css'
import {
  selectFilterTopRelease,
  selectTimeFilter,
  updateFilter,
  updateGroupColums,
  updateMultiFilter,
  updateTab,
} from './store/slice'
import { getDataTopCirculate, getDataTopRealse } from './store/thunk'

const startYear = 2000
const endYear = new Date().getFullYear()

const TopIssuerTab = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const { maxMinTableById } = useGetMaxMinTable()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const dataL2 = useSelector(selecDataL2)
  const timeFilter = useSelector(selectTimeFilter)
  const radioValue = useSelector(selectActiveMethodType)
  const filter = useSelector(selectFilterTopRelease)
  const commonTopIssuer = maxMinTableById['FGFB_COAN_DR_BondIssue']

  // Get data
  const getMomentToday = useMemo(() => {
    const today = moment()
    if (commonTopIssuer?.max_MonthReport) {
      today.set('month', commonTopIssuer?.max_MonthReport)
    }
    if (commonTopIssuer?.max_YearReport) {
      today.set('year', commonTopIssuer?.max_YearReport)
    }
    return today
  }, [commonTopIssuer])

  const getMonths = useMemo(() => {
    const monthLocale =
      locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI

    if (isTrial) {
      const months = [...Array(3)].map((_, index) =>
        getMomentToday.clone().subtract(index, 'month').month(),
      )
      return monthLocale.filter((item) => months.includes(item.value))
    } else {
      return monthLocale
    }
  }, [getMomentToday, locale, isTrial])

  const getYears = useMemo(() => {
    if (isTrial && radioValue === VALUE_TIME.MONTHLY) {
      const years = {}
      ;[...Array(3)].forEach((_, index) => {
        const year = getMomentToday.clone().subtract(index, 'month').year()
        years[year] = true
      })
      return Object.keys(years).map((year) => ({
        name: year.toString(),
        value: year,
      }))
    } else {
      return yearOption()
    }
  }, [radioValue, getMomentToday, isTrial])

  const listData = useMemo(() => {
    const data = dataL2.map((e) => ({
      name: `${e.icbName} L2`,
      value: e.icbId,
    }))
    return [
      {
        name: 'bond.overview.primaryMarket.ALL_SECTOR',
        value: '',
      },
      ...data,
    ]
  }, [dataL2])

  const getData = () => {
    if (timeFilter === valueTimefrequency.OUTSTANDING) {
      dispatch(updateGroupColums(tableCirculateConstants))
      dispatch(
        getDataTopCirculate({
          Industry: filter.career || null,
          DateCheck: filter.DateCheck,
        }),
      )
    }
    if (timeFilter === valueTimefrequency.ISSUE) {
      dispatch(updateGroupColums(tableConstants))
      dispatch(
        getDataTopRealse({
          TopValue: null,
          Industry: filter.career || null,
          TimeFrequency: radioValue,
          Month:
            radioValue === VALUE_TIME.MONTHLY
              ? filter.month || MONTH_OPTIONS[0].value
              : null,
          Quarter:
            radioValue === VALUE_TIME.QUARTERLY
              ? filter.quarter || QUARTER_OPTIONS[0].value
              : null,
          Year: filter.year || getYears[0].value,
        }),
      )
    }
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  // Actions
  const onChangeTab = (data) => {
    dispatch(updateTab(data.value))
  }

  const onChangeFilter = (value, key) => {
    dispatch(updateFilter({ value, key }))
  }

  const headerOneCalendarRange = HeaderCalendarPopper({
    startYear,
    endYear,
  })

  // Use effect
  useEffect(() => {
    dispatch(
      updateMultiFilter({
        quarter: commonTopIssuer?.max_LengthReport || QUARTER_OPTIONS[0].value,
        month: commonTopIssuer?.max_MonthReport || MONTH_OPTIONS[0].value,
        year: commonTopIssuer?.max_YearReport || getYears[0].value,
      }),
    )
  }, [commonTopIssuer])

  useEffect(() => {
    getData()
  }, [radioValue, filter, timeFilter, locale])

  return (
    <div className={`${style.financialMetricHeader}`}>
      <DispatchActionTab
        onChangeTab={(e) => onChangeTab(e)}
        data={Timefrequency.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        activeTab={timeFilter}
        textSize={12}
      />
      <div className={style.select}>
        <SelectCustom
          value={filter.career ?? list_career[0].value}
          selectData={listData}
          handleChange={(e) => onChangeFilter(e, 'career')}
          isI18n={true}
        />
      </div>
      {timeFilter === valueTimefrequency.ISSUE ? (
        <>
          {radioValue === VALUE_TIME.QUARTERLY && (
            <div className={style.selectYearCustom}>
              <SelectCustom
                isI18n={true}
                selectData={QUARTER_OPTIONS}
                value={filter.quarter || QUARTER_OPTIONS[0].value}
                handleChange={(e) => onChangeFilter(e, 'quarter')}
                appendStyle={{ fontWeight: 'normal' }}
              />
            </div>
          )}
          {radioValue === VALUE_TIME.MONTHLY && (
            <div className={style.selectYearCustom}>
              <SelectCustom
                isI18n={true}
                selectData={getMonths}
                value={filter.month || 1}
                handleChange={(e) => onChangeFilter(e, 'month')}
                appendStyle={{ fontWeight: 'normal' }}
              />
            </div>
          )}

          <div className={style.selectYearCustom}>
            <SelectCustom
              isI18n={true}
              selectData={getYears}
              value={filter.year || getYears[0].value}
              handleChange={(e) => onChangeFilter(e, 'year')}
              appendStyle={{ fontWeight: 'normal' }}
            />
          </div>
        </>
      ) : (
        <div className="form-control calendar mb-0">
          <div className={`${style.bgGrey} w-100 h-20`}>
            <CalendarPopper
              date={
                filter.DateCheck ? getDateWithTimeZone(filter.DateCheck) : ''
              }
              maxDate={new Date()}
              handleChange={(e) => onChangeFilter(e, 'DateCheck')}
              CustomInput={InputDateCalendar}
              customHeader={headerOneCalendarRange}
            />
          </div>
          <Icon
            className={`icon-date ${style.eventNone}`}
            style={{ fontSize: 12 }}
          />
        </div>
      )}
    </div>
  )
}

export default TopIssuerTab
