export const formatDataApi = (data) => {
  return data.reduce((array, item) => {
    const value = {
      ...item,
      xValue: item.rfD12 * 100,
      yValue: item.rfD7 * 100,
      zValue: item.nav / 10 ** 9,
      color:
        item.rfD12 === 0 ? '#FACC5CCC' : item.rfD12 < 0 ? '#DF5A49' : '#3DA967',
    }

    if (isNaN(value.xValue) || isNaN(value.yValue) || isNaN(value.zValue)) {
      return array
    } else {
      return [...array, value]
    }
  }, [])
}
