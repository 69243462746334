import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class ExpectedCashProxy extends ProxyBase {
  getDataChart(params) {
    return this.get('Overview/ExpectedCashFlowsIndustry', params)
  }
  getExpectedCashFlowsAll(params) {
    return this.get('Overview/ExpectedCashFlowsAll', params)
  }
}

export default new ExpectedCashProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
