import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseI18n from '../../../../common/hooks/useI18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import {
  ALL_VALUE,
  changeFilterCategoryId,
  changeFilterSubCategoryId,
  selectCategories,
  selectFilterCategoryId,
  selectFilterSubCategoryId,
  selectSubCategoriesByParentId,
} from '../store/slice'
import style from './index.module.css'

const Category = () => {
  const dispatch = useDispatch()
  const filterCategoryId = useSelector(selectFilterCategoryId)
  const filterSubCategoryId = useSelector(selectFilterSubCategoryId)
  const categories = useSelector(selectCategories)
  const subCategories = useSelector(
    selectSubCategoriesByParentId(filterCategoryId),
  )

  const onCategoryChange = (val) => {
    dispatch(changeFilterCategoryId(val))
  }

  const onSubCategoryChange = (val) => {
    dispatch(changeFilterSubCategoryId(val))
  }

  const allCategoryText = UseI18n('corporate.newsEvents.filing.ALL_CATEGORY')
  const allSourceText = UseI18n('corporate.newsEvents.filing.ALL_SOURCES')

  return (
    <>
      <div>
        <Span className={style.filterTitle} style={{ fontSize: 11 }}>
          <Translate value="corporate.newsEvents.filing.CATEGORY" />
        </Span>
        <SelectCustom
          value={filterCategoryId}
          selectData={[
            { name: allCategoryText, value: ALL_VALUE },
            ...categories.map((cat) => ({
              name: cat.reportTypeName,
              value: cat.reportTypeId,
            })),
          ]}
          handleChange={onCategoryChange}
        />
      </div>
      <div className="mt-30">
        <Span className={style.filterTitle} style={{ fontSize: 11 }}>
          <Translate value="corporate.newsEvents.filing.SUB_CATEGORY" />
        </Span>
        <SelectCustom
          value={filterSubCategoryId}
          selectData={[
            { name: allSourceText, value: ALL_VALUE },
            ...(subCategories
              ? subCategories.map((cat) => ({
                  name: cat.reportTypeName,
                  value: cat.reportTypeId,
                }))
              : []),
          ]}
          handleChange={onSubCategoryChange}
        />
      </div>
    </>
  )
}

export default Category
