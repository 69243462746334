import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { StackedAreaLineChart } from '../../../../common/chart/customCharts/StackedAreaLineChart'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import {
  formatTooltipTitle,
  getCompareChartMargin,
  getNetValueChartData,
  getValueInvestorPercentValueChartData,
} from '../helps'
import {
  selectDateTime,
  selectData as selectNetValueData,
  selectType,
} from '../netValue/store/slice'
import { fillColor, keyXAxis } from './constants'

export const PercentValueChart = ({
  data,
  width,
  height,
  dateType,
  valueType,
  isResizeResponsive,
  listCheckbox,
  setListCheckbox,
  getAreaKeys,
  getLineKeys,
  isHnxGroup,
}) => {
  const calcFontSize = getFontSize(12)
  const timezone = UseTimeZone()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectIndexInfo)
  const netValueData = useSelector(selectNetValueData)
  const netValueDateType = useSelector(selectDateTime)
  const netValueType = useSelector(selectType)

  // Get data
  const getValueInvestorPercentValueData = useMemo(() => {
    return getValueInvestorPercentValueChartData(
      data,
      valueType,
      dateType,
      isHnxGroup,
    )
  }, [data, dateType, valueType, isHnxGroup])

  const getNetValueData = useMemo(() => {
    return getNetValueChartData(netValueData, netValueType, netValueDateType)
  }, [netValueData, netValueType, netValueDateType])

  const mappingDisplayName = {
    localInstitution:
      'market.marketInDepth.indexStatistics.valueInvestor.LOCAL_INSTITUTION',
    proprietary:
      'market.equityTrading.priceStatistics.valueInvestor.PROPRIETARY',
    foreign: 'market.marketInDepth.indexStatistics.valueInvestor.FOREIGN',
    localIndividual:
      'market.marketInDepth.indexStatistics.valueInvestor.LOCAL_INDIVIDUAL',
    closeIndex: basicInfo.groupName,
  }

  const chartMargin = getCompareChartMargin(
    getNetValueData,
    getValueInvestorPercentValueData,
    ['buy', 'sell'],
    getAreaKeys(),
    ['net'],
    !isHnxGroup ? ['closeIndex'] : [],
    calcFontSize,
  )

  return (
    <StackedAreaLineChart
      data={getValueInvestorPercentValueData}
      width={width}
      height={height}
      areaKeys={getAreaKeys()}
      lineKeys={getLineKeys()}
      keyXAxis={keyXAxis}
      decimalLength={2}
      margin={chartMargin}
      timeFrame={
        dateType === TIME_RANGES['1W']
          ? TIME_RANGES['1W_DATE']
          : dateType === TIME_RANGES['3M']
          ? TIME_RANGES['1M']
          : dateType
      }
      fillColor={fillColor}
      mappingDisplayName={mappingDisplayName}
      unit="%"
      leftLabel="market.marketInDepth.indexStatistics.valueInvestor.PERCENT_VALUE_UNIT"
      rightLabel="market.marketInDepth.indexStatistics.valueInvestor.INDEX_POINT"
      formatTooltipTitle={(item) =>
        formatTooltipTitle(
          item,
          dateType,
          ['date', 'minDate'],
          keyXAxis,
          locale,
          timezone,
        )
      }
      isFooterResize={isResizeResponsive}
      isFooterCheckbox
      listCheckbox={listCheckbox}
      setListCheckbox={setListCheckbox}
    />
  )
}

PercentValueChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dateType: PropTypes.string.isRequired,
  valueType: PropTypes.string.isRequired,
  isResizeResponsive: PropTypes.bool,
  listCheckbox: PropTypes.array,
  setListCheckbox: PropTypes.func,
  getBarKeys: PropTypes.func,
  getLineKeys: PropTypes.func,
  isHnxGroup: PropTypes.bool,
}
