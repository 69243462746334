import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import { Z_INDEX } from '../../../../../constants/Common'
import { ICB_LEVELS, SEARCH_ITEM_TYPE } from '../../constants'
import style from '../../style.module.css'
import {
  selectIsShow,
  selectListCompareSearch,
  setIsShow,
} from '../store/slice'
import ButtonApplyCompare from './ButtonApplyCompare'
import ComponentListData from './ComponentListData'
import MessWarningAddCompare from './MessWarningAddCompare'
import SearchCompare from './SearchCompare'
import {
  changeCompareTab,
  changeICBLevel,
  searchData,
  setCompareChecked,
} from './store/slice'

const PopupCompare = () => {
  const dispatch = useDispatch()
  const isShow = useSelector(selectIsShow)
  const compareList = useSelector(selectListCompareSearch)

  const closePopup = () => {
    dispatch(setIsShow(false))
    cancelCompare()
    dispatch(searchData(''))
    dispatch(changeCompareTab(SEARCH_ITEM_TYPE.ALL))
    dispatch(changeICBLevel(ICB_LEVELS.ICB_LEVEL_2))
  }

  const cancelCompare = () => {
    dispatch(setCompareChecked(compareList))
  }

  return (
    isShow && (
      <div className="overlay" style={{ zIndex: Z_INDEX.MODAL + 5 }}>
        <div className="modal-wrapper">
          <div className={`modal ${style.modalCompare}`}>
            <div className="modal-title">
              <h3 className={style.headerPopup}>
                <Span style={{ fontSize: 12 }}>
                  <Translate
                    value={'market.marketInDepth.valuation.LB_COMPARE'}
                  />
                </Span>
              </h3>
              <a className="close-modal" onClick={closePopup}>
                <i className="icon-delete-stroke"></i>
              </a>
            </div>
            <div className="modal-content" style={{ padding: '8px 16px' }}>
              <SearchCompare />

              <div className={`modal-tab mt-8 ${style.componentListData}`}>
                <ComponentListData />
              </div>
              <MessWarningAddCompare />
              <div className={style.groupButtonCompare}>
                <Button
                  className="btn bg-grey-1 w-80 h-20"
                  style={{ marginRight: 24 }}
                  onClick={closePopup}
                >
                  <Translate value={'common.button.BUTTON_CANCEL'} />
                </Button>
                <ButtonApplyCompare />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default PopupCompare
