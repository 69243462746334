import PropTypes from 'prop-types'
import LinkSector from '../../../common/dropdownLink/LinkSector'
import useGetICB from '../../../common/masterData/useGetICB'
import TextEllipsis from '../../../common/textEllipsis'
import { typeRowGroup } from '../constant'
import { getIcbId, getRowGroup } from '../helper'
import style from '../index.module.css'

const MARGIN_LEFT = 16
const widthIconArrow = 20

const TdSector = ({
  value,
  colId,
  onRowCollapse,
  rowsCollapse,
  levelCollapse,
  level,
}) => {
  const { ICBById } = useGetICB(true)

  const itemCollapse = levelCollapse[colId] || {}
  const isCollapse = !itemCollapse.isLastChild

  return (
    <div
      className={
        getRowGroup(colId) !== typeRowGroup.ICB ? style.rowHightLight : ''
      }
    >
      {getRowGroup(colId) === typeRowGroup.ICB ? (
        <div
          className="align-center"
          style={{
            marginLeft: itemCollapse?.level
              ? (itemCollapse?.level - level) * MARGIN_LEFT +
                (!isCollapse ? 20 : 0)
              : 0,
          }}
        >
          {isCollapse && (
            <button
              style={{ cursor: 'pointer' }}
              className="align-center"
              onClick={(e) => {
                e.stopPropagation()
                onRowCollapse(colId)
              }}
            >
              <i
                className={`${
                  rowsCollapse.includes(colId)
                    ? 'icon-caret-right'
                    : 'icon-caret-down'
                } ${style.iconCollapse}`}
              ></i>
            </button>
          )}

          <div
            style={{
              width: `calc(100% - ${isCollapse ? widthIconArrow : 0}px)`,
            }}
          >
            <LinkSector icbId={getIcbId(colId)}>
              <TextEllipsis
                isI18n={false}
                text={
                  ICBById[getIcbId(colId)]?.icbName +
                  ' L' +
                  (itemCollapse?.level || '')
                }
              />
            </LinkSector>
          </div>
        </div>
      ) : (
        <>{value}</>
      )}
    </div>
  )
}

TdSector.propTypes = {
  value: PropTypes.string.isRequired,
  colId: PropTypes.string.isRequired,
  onRowCollapse: PropTypes.func.isRequired,
  rowsCollapse: PropTypes.array.isRequired,
  levelCollapse: PropTypes.object.isRequired,
  level: PropTypes.number.isRequired,
}

export default TdSector
