import PeerValuationService from '../../../../../core/services/corporate/PeerValuationService'
import { COM_GROUP } from '../../../../constants/Group'
import { EXCHANGE_CODE, TABLE_TYPE } from './constant'
import {
  downloadPeerComparisonBalanceSheet,
  downloadPeerComparisonCredit,
  downloadPeerComparisonIncomeStatement,
  downloadPeerComparisonOwnership,
  downloadPeerComparisonPerformance,
  downloadPeerComparisonProfitability,
  downloadPeerComparisonValuation,
  getPeerComparisonBalanceSheet,
  getPeerComparisonCredit,
  getPeerComparisonIncomeStatement,
  getPeerComparisonOwnership,
  getPeerComparisonPerformance,
  getPeerComparisonProfitability,
  getPeerComparisonValuation,
} from './store/thunk'

const QUARTER_TIME = 24 * 60 * 60 * 1000 * 90 // 90 day
export const getDefaultYearQuarter = () => {
  const datetime = new Date(new Date().getTime() - 2 * QUARTER_TIME)
  const quarter = Math.floor((datetime.getMonth() + 3) / 3)
  const year = datetime.getFullYear()
  return { year, quarter }
}

export const getExchangeNameByValue = (value) => {
  const key = Object.keys(EXCHANGE_CODE).find(
    (key) => EXCHANGE_CODE[key].toUpperCase() === value.toUpperCase(),
  )

  if (key) {
    return 'corporate.peerValuation.overview.exchanges.' + key
  } else {
    return ''
  }
}

export const getListTableTab = (comGroup) =>
  Object.keys(TABLE_TYPE).map((key) => {
    const titleKey =
      comGroup === COM_GROUP.BANK && TABLE_TYPE[key] === TABLE_TYPE.CREDIT
        ? 'ASSET_QUALITY'
        : key

    return {
      value: TABLE_TYPE[key],
      title: 'corporate.peerValuation.overview.' + titleKey,
    }
  })

export const getPeerComparisonServiceMethod = (type, comGroup) => {
  switch (type) {
    case TABLE_TYPE.PERFORMANCE:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.getPeerComparisonBankPerformance(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.getPeerComparisonInsuPerformance(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.getPeerComparisonSecuPerformance(params)
        default:
          return (params) =>
            PeerValuationService.getPeerComparisonCorpPerformance(params)
      }
    case TABLE_TYPE.VALUATION:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.getPeerComparisonBankValuation(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.getPeerComparisonInsuValuation(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.getPeerComparisonSecuValuation(params)
        default:
          return (params) =>
            PeerValuationService.getPeerComparisonCorpValuation(params)
      }
    case TABLE_TYPE.OWNERSHIP:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.getPeerComparisonBankOwnership(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.getPeerComparisonInsuOwnership(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.getPeerComparisonSecuOwnership(params)
        default:
          return (params) =>
            PeerValuationService.getPeerComparisonCorpOwnership(params)
      }
    case TABLE_TYPE.CREDIT:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.getPeerComparisonBankAssetQuality(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.getPeerComparisonInsuCredit(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.getPeerComparisonSecuCredit(params)
        default:
          return (params) =>
            PeerValuationService.getPeerComparisonCorpCredit(params)
      }
    default:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.getPeerComparisonBankProfitability(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.getPeerComparisonInsuProfitability(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.getPeerComparisonSecuProfitability(params)
        default:
          return (params) =>
            PeerValuationService.getPeerComparisonCorpProfitability(params)
      }
  }
}

export const dispatchGetDataByTableType = (
  dispatch,
  type,
  params,
  comGroup,
) => {
  switch (type) {
    case TABLE_TYPE.PERFORMANCE:
      return dispatch(getPeerComparisonPerformance({ params, comGroup }))
    case TABLE_TYPE.VALUATION:
      return dispatch(getPeerComparisonValuation({ params, comGroup }))
    case TABLE_TYPE.OWNERSHIP:
      return dispatch(getPeerComparisonOwnership({ params, comGroup }))
    case TABLE_TYPE.CREDIT:
      return dispatch(getPeerComparisonCredit({ params, comGroup }))
    case TABLE_TYPE.PROFITABILITY:
      return dispatch(getPeerComparisonProfitability({ params, comGroup }))
    case TABLE_TYPE.BALANCE_SHEET:
      return dispatch(getPeerComparisonBalanceSheet(params))
    case TABLE_TYPE.INCOME_STATEMENT:
      return dispatch(getPeerComparisonIncomeStatement(params))
    default:
      return null
  }
}

export const getDownloadTableMethod = (type, comGroup) => {
  switch (type) {
    case TABLE_TYPE.PERFORMANCE:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.downloadPeerComparisonBankPerformance(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.downloadPeerComparisonInsuPerformance(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.downloadPeerComparisonSecuPerformance(params)
        default:
          return (params) =>
            PeerValuationService.downloadPeerComparisonCorpPerformance(params)
      }
    case TABLE_TYPE.VALUATION:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.downloadPeerComparisonBankValuation(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.downloadPeerComparisonInsuValuation(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.downloadPeerComparisonSecuValuation(params)
        default:
          return (params) =>
            PeerValuationService.downloadPeerComparisonCorpValuation(params)
      }
    case TABLE_TYPE.OWNERSHIP:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.downloadPeerComparisonBankOwnership(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.downloadPeerComparisonInsuOwnership(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.downloadPeerComparisonSecuOwnership(params)
        default:
          return (params) =>
            PeerValuationService.downloadPeerComparisonCorpOwnership(params)
      }
    case TABLE_TYPE.CREDIT:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.downloadPeerComparisonBankCredit(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.downloadPeerComparisonInsuCredit(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.downloadPeerComparisonSecuCredit(params)
        default:
          return (params) =>
            PeerValuationService.downloadPeerComparisonCorpCredit(params)
      }
    default:
      switch (comGroup) {
        case COM_GROUP.BANK:
          return (params) =>
            PeerValuationService.downloadPeerComparisonBankProfitability(params)
        case COM_GROUP.INSURANCE:
          return (params) =>
            PeerValuationService.downloadPeerComparisonInsuProfitability(params)
        case COM_GROUP.SECURITIES:
          return (params) =>
            PeerValuationService.downloadPeerComparisonSecuProfitability(params)
        default:
          return (params) =>
            PeerValuationService.downloadPeerComparisonCorpProfitability(params)
      }
  }
}

export const downloadTableByType = ({ type, params, comGroup }) => {
  switch (type) {
    case TABLE_TYPE.PERFORMANCE:
      return () => downloadPeerComparisonPerformance({ params, comGroup })
    case TABLE_TYPE.VALUATION:
      return () => downloadPeerComparisonValuation({ params, comGroup })
    case TABLE_TYPE.OWNERSHIP:
      return () => downloadPeerComparisonOwnership({ params, comGroup })
    case TABLE_TYPE.CREDIT:
      return () => downloadPeerComparisonCredit({ params, comGroup })
    case TABLE_TYPE.PROFITABILITY:
      return () => downloadPeerComparisonProfitability({ params, comGroup })
    case TABLE_TYPE.BALANCE_SHEET:
      return () => downloadPeerComparisonBalanceSheet(params)
    case TABLE_TYPE.INCOME_STATEMENT:
      return () => downloadPeerComparisonIncomeStatement(params)
    default:
      return null
  }
}
