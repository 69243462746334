import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import PieChartTooltip from '../../../../common/chart/pieChart/pieChartWithFooter/PieChartTooltip'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { selectExchangeCode } from '../slice'
import { MAP_KEY_CHART, VIEW_BY_BANK, VIEW_BY_SECURITIES } from './constants'
import { formatChartData } from './helper'
import {
  changeViewBy,
  selectDataSegmentationChart,
  selectLoading,
  selectViewBy,
} from './store/slice'
import { getSegmentationChartData } from './store/thunk'
import style from './style.module.css'

const Segmentation = ({ width, height }) => {
  const dispatch = useDispatch()

  const viewBy = useSelector(selectViewBy)
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataSegmentationChart)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const exchangeCode = useSelector(selectExchangeCode)

  const getKeyChart = () => {
    return MAP_KEY_CHART[viewBy[typeSector]]
  }

  const formatDataChart = formatChartData(dataChart, getKeyChart(), 'shortName')

  const viewByTab =
    typeSector === COM_GROUP.BANK ? VIEW_BY_BANK : VIEW_BY_SECURITIES

  const handleChangeViewBy = (val) => {
    dispatch(
      changeViewBy({
        key: typeSector,
        val: val.value,
      }),
    )
  }

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(
        getSegmentationChartData({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
          SegmentationType: viewBy[typeSector],
        }),
      )
    }
  }, [sectorInfo.icbId, locale, exchangeCode])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <div>
              <div>
                <DispatchActionTab
                  data={Object.keys(viewByTab).map((key) => {
                    return {
                      title: I18n.t(
                        `sector.sectorConstituents.overview.${key}`,
                      ),
                      value: viewByTab[key],
                    }
                  })}
                  activeTab={viewBy[typeSector]}
                  onChangeTab={handleChangeViewBy}
                  containerStyle={{
                    opacity: typeSector === COM_GROUP.CORPORATE ? 0 : 1,
                  }}
                />
              </div>
              <div
                className={style.canvasChartContainer}
                id="panelEarningGrowthId"
              >
                {size.height && (
                  <div style={{ height: height - size.height }}>
                    {isLoading ? (
                      <Loading />
                    ) : (
                      <>
                        {formatDataChart.length ? (
                          <>
                            {size.height && (
                              <PieChartWithFooter
                                data={formatDataChart}
                                height={height - size.height}
                                width={width}
                                isUseContainerFooter
                                footerProps={{ numRow: 2 }}
                                pieChartProps={{ radius: 28 }}
                                renderTooltip={(data) => (
                                  <PieChartTooltip data={data} />
                                )}
                              />
                            )}
                          </>
                        ) : (
                          <NoData />
                        )}
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default Segmentation
