import { HorizontalStackedBarSeries } from '../../../../common/chart/customCharts/horizontalStackedBarSeries'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  COST_STRUCTURE_COLOR,
  COST_STRUCTURE_KEY,
  MAIN_METRIC_CODE,
} from '../constants'

const ChartComponent = ({ data, width, height }) => {
  const getTooltipData = () => {
    return Object.keys(COST_STRUCTURE_KEY).map((key) => ({
      keyValue: COST_STRUCTURE_KEY[key],
      label: 'corporate.businessModel.costOfOperation.indicators.' + key,
      formatValue: (val) => val + '%',
      valueFontWeight: 500,
      labelFontWeight: 400,
    }))
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <div style={{ height: height - size.height }}>
                <HorizontalStackedBarSeries
                  data={data}
                  width={width}
                  height={height - size.height}
                  yAxisCompareKey={'date'}
                  barKeys={Object.values(COST_STRUCTURE_KEY)}
                  is100Percent={true}
                  tooltipContentData={getTooltipData()}
                  fillColor={COST_STRUCTURE_COLOR}
                  margin={{
                    left: 45,
                    bottom: 35,
                    right: 20,
                  }}
                />
              </div>
            )}
            <FooterContainer
              key={width}
              data={Object.keys(MAIN_METRIC_CODE).map((key, index) => ({
                text:
                  'corporate.businessModel.costOfOperation.indicators.' + key,
                type: TYPE.SQUARE,
                before: {
                  bgColor: Object.values(COST_STRUCTURE_COLOR)[index],
                },
              }))}
              numRow={2}
              numItemPerRow={3}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
