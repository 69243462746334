import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InterestRateService from '../../../../../../core/services/economy/InterestRateService'

export const getMovementDepositAndLending = createAsyncThunk(
  'economy/monetary/interestRate/panelAverageFundingCost/getMovementDepositAndLending',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getMovementDepositAndLending(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
