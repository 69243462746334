import { createSlice } from '@reduxjs/toolkit'
import { SEARCH_ITEM_TYPE } from '../../../../market/marketInDepth/valuation/constants'
import { register } from '../../../../utils/ReducerRegistry'
import { DEFAULT_SEARCH_INFO } from '../constants'
import { getLatestPrice } from './thunk'

const defaultState = {
  currentInfo: DEFAULT_SEARCH_INFO,
  priceData: {},
  displayInfo: true,
  loading: false,
}

const initialState = {
  currentInfo: {
    type: SEARCH_ITEM_TYPE.INDEX,
    groupId: '',
    groupName: '',
    groupCode: '',
    exchangeCode: '',
  },
  priceData: {},
  displayInfo: true,
  loading: false,
}

const slice = createSlice({
  name: 'common/indexTickerTopInfo',
  initialState,
  reducers: {
    resetDefault(state) {
      Object.keys(defaultState).forEach((key) => {
        if (key !== 'priceData') {
          state[key] = defaultState[key]
        }
      })
    },
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeCurrentInfo: (state, action) => {
      state.currentInfo = action.payload
      state.displayInfo = true
    },
    subscribeStock: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
    subscribeBidAsk: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },
    changeDisplayInfo: (state, action) => {
      state.displayInfo = action.payload
    },
    changeLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestPrice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      state.loading = false
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      } else {
        state.priceData = defaultState.priceData
      }
    })
    builder.addCase(getLatestPrice.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectCurrentInfo = (state) => state[slice.name].currentInfo
export const selectPriceData = (state) => state[slice.name].priceData
export const selectDisplayInfo = (state) => state[slice.name].displayInfo

export const {
  changeCurrentInfo,
  subscribeStock,
  subscribeBidAsk,
  changeDisplayInfo,
  resetDefault,
  resetStore,
  changeLoading,
} = slice.actions

register(slice.name, slice.reducer)
