import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchDerivative } from '../store/slice'
import { getSearchDerivativesData } from '../store/thunk'
import { MarDerivativesItem } from './MarDerivativesItem'

export const MarDerivatives = (props) => {
  const { searchStr, open, onClickCollapse, getBasicInfo } = props
  const dispatch = useDispatch()
  const derivatives = useSelector(selectSearchDerivative)

  const onClickPrevPage = () => {
    if (derivatives.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchDerivativesData({
          Term: searchStr,
          Page: derivatives.data.page - 1,
          PageSize: derivatives.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (derivatives.data.page === derivatives.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchDerivativesData({
          Term: searchStr,
          Page: derivatives.data.page + 1,
          PageSize: derivatives.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="mar-der"
      title={`${UseI18n('search.fullSearch.MAR_DERIVATIVES')} (${
        derivatives.data.totalRecords
      })`}
      blockData={derivatives}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!derivatives.data.items.length &&
        derivatives.data.items.map((item) => (
          <MarDerivativesItem
            key={item.derivativeId}
            searchStr={searchStr}
            data={item}
            getBasicInfo={getBasicInfo}
          />
        ))}
    </SearchCollapseBlock>
  )
}

MarDerivatives.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
