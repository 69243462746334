import { useDispatch, useSelector } from 'react-redux'
import SelectExchange from '../common/selectExchange'
import SelectSector from '../common/selectSector'
import {
  changeExchangeChecked,
  changeExchangeFirstMountPopup,
  changeListParentExchangeChecked,
  changeListParentSectorChecked,
  changeSectorChecked,
  changeSectorFirstMountPopup,
  selectExchangeFirstMountPopup,
  selectListExchangeChecked,
  selectListParentExchangeChecked,
  selectListParentSectorChecked,
  selectListSectorChecked,
  selectSectorFirstMountPopup,
} from '../common/store/slice'
import style from './style.module.css'

const SelectExchangeSector = () => {
  const dispatch = useDispatch()

  const exchangeChecked = useSelector(selectListExchangeChecked)
  const parentExchangeChecked = useSelector(selectListParentExchangeChecked)
  const isExchangeFirstMount = useSelector(selectExchangeFirstMountPopup)
  const sectorChecked = useSelector(selectListSectorChecked)
  const parentSectorChecked = useSelector(selectListParentSectorChecked)
  const isSectorFirstMount = useSelector(selectSectorFirstMountPopup)

  const handleChangeSectorFirstMount = (value) => {
    dispatch(changeSectorFirstMountPopup(value))
  }

  const handleChangeParentSectorChecked = (data) => {
    dispatch(changeListParentSectorChecked(data))
  }

  const handleChangeSectorChecked = (data) => {
    dispatch(changeSectorChecked(data))
  }

  const handleChangeExchangeFirstMount = (value) => {
    dispatch(changeExchangeFirstMountPopup(value))
  }

  const handleChangeParentExchangeChecked = (data) => {
    dispatch(changeListParentExchangeChecked(data))
  }

  const handleChangeExchangeChecked = (data) => {
    dispatch(changeExchangeChecked(data))
  }

  return (
    <div className={style.filterExchange}>
      <SelectExchange
        listChecked={exchangeChecked}
        listParentChecked={parentExchangeChecked}
        isFirstMount={isExchangeFirstMount}
        changeFirstMountPopup={handleChangeExchangeFirstMount}
        changeListParentChecked={handleChangeParentExchangeChecked}
        changeExchangeChecked={handleChangeExchangeChecked}
      />
      <SelectSector
        listChecked={sectorChecked}
        listParentChecked={parentSectorChecked}
        isFirstMount={isSectorFirstMount}
        changeFirstMountPopup={handleChangeSectorFirstMount}
        changeListParentChecked={handleChangeParentSectorChecked}
        changeSectorChecked={handleChangeSectorChecked}
        isSetSectorAll
      />
    </div>
  )
}

export default SelectExchangeSector
