import React, { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import styles from '../styleTheme.module.css'
import style from './style.module.css'

export const POSITION = {
  CUSTOM: 'custom',
  RIGHT: 'right',
  LEFT: 'left',
}

const listPosition = [
  { label: 'financialChart.LEFT', value: POSITION.LEFT },
  { label: 'financialChart.RIGHT', value: POSITION.RIGHT },
]

const PopupChangeYAxisPosition = ({ valuePopup, onShow, onChange }) => {
  const [labelText, setLabelText] = useState('')
  const [orientation, setOrientation] = useState(POSITION.LEFT)

  const onClose = () => {
    onShow(false)
  }

  const onApply = () => {
    onChange({ ...valuePopup, labelText, orientation })
    onShow(false)
  }

  const onClickPosition = (value) => {
    setOrientation(value)
  }

  useEffect(() => {
    setLabelText(valuePopup.labelText)
    setOrientation(valuePopup.orientation)
  }, [])

  return (
    <Popup overlayCustom={styles.overlayCustom}>
      <div className={`modal modal-small ${styles.modal}`}>
        <div
          className={`modal-title ${styles.titleStyle}`}
          style={{ textTransform: 'uppercase' }}
        >
          <h3>
            <Span>
              <Translate value="financialChart.CHANGE_POSITION_YAXIS" />
            </Span>
          </h3>
          <a onClick={onClose} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${styles.modalContentStyle}`}>
          <table className={style.table}>
            <tbody>
              <tr>
                <td>
                  <Translate value="financialChart.Y_AXIS_LABEL" />
                </td>
                <td>
                  <Input
                    className={style.input}
                    value={labelText}
                    onChange={(e) => setLabelText(e.target.value)}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <Translate value="financialChart.Y_AXIS_POSITION" />
                </td>
                <td>
                  <div className="d-flex mt-8">
                    {listPosition.map((item) => (
                      <label
                        key={item.value}
                        onClick={() => onClickPosition(item.value)}
                        className={`cursor-pointer d-flex ali-center ${style.radio}`}
                      >
                        <input
                          type="radio"
                          className={`radiobox radiobox2 cursor-pointer`}
                          checked={orientation === item.value}
                          readOnly
                        />
                        <Translate value={item.label} />
                      </label>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="d-flex justify-content-end" style={{ marginTop: 41 }}>
            <Button
              className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
              style={{ fontSize: 11 }}
              onClick={onClose}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              className={`btn w-80 h-20 ml-20 ${styles.buttonBlueStyle}`}
              style={{ fontSize: 11 }}
              onClick={onApply}
            >
              <Translate value="common.button.BUTTON_APPLY" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

const withWrapper =
  (Component) =>
  ({ isShow, ...props }) =>
    isShow ? <Component {...props} /> : <></>

export default withWrapper(PopupChangeYAxisPosition)
