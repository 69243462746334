import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class WatchListProxy extends ProxyBase {
  getWatchlist(params) {
    return this.get('GetByUser', params, '', true)
  }
  getWatchlistBond(params) {
    return this.get('MyWatchlist', params, '', true)
  }
  updateMasterWatchlist(data) {
    return this.post('UpdateMasterOnly', data)
  }

  copyWatchlist(data) {
    return this.post('Copy', data)
  }

  deleteWatchlist(data) {
    return this.post('Delete', data)
  }

  getWatchlistDetail(params) {
    return this.get('GetById', params)
  }

  getTickerByExchange(params) {
    return this.get('GetOrganizationsByExchange', params)
  }

  getTickerByICB(params) {
    return this.get('GetOrganizationsByIcb', params)
  }

  updateWatchlist(data) {
    return this.post('Update', data)
  }

  createWatchlist(data) {
    return this.post('Insert', data)
  }

  updateFavorite(data) {
    return this.post('UpdateFavourite', data)
  }
}

export default new WatchListProxy(ServiceProxyConfig.WatchList.ServiceUrl)
