import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import useGetExchange from '../../common/masterData/useGetExchange'
import useGetICB from '../../common/masterData/useGetICB'
import { ScrollComponent } from '../../common/ScrollComponent'
import style from '../index.module.css'
import { getTickerByExchangeThunk, getTickerByICBThunk } from '../store/thunk'

const itemNameKey = {
  EXCHANGE: 'groupName',
  ICB: 'icbName',
}

const scrollContainerClass = 'exchange-sector-container'

const paddingTopScroll = 80

const infinity = 9999

const timeoutCheckDoubleClick = 350

const TreeItem = ({
  item,
  nameKey,
  valueSearch,
  isParentOpen,
  handleParentOpen,
  isResearch,
  offsetTopScroll,
}) => {
  const dispatch = useDispatch()

  const pRef = useRef()
  const isDoubleClick = useRef(false)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) handleParentOpen()
  }, [isOpen])

  useEffect(() => {
    if (!isParentOpen) setIsOpen(false)
  }, [isParentOpen])

  useEffect(() => {
    if (
      valueSearch.trim() &&
      item[nameKey].toUpperCase().includes(valueSearch.trim().toUpperCase())
    ) {
      handleParentOpen()
      setTimeout(() => {
        if (pRef.current.offsetTop < offsetTopScroll.current) {
          offsetTopScroll.current = pRef.current.offsetTop
          document
            .querySelector(
              `.${scrollContainerClass} .scrollbars div:first-child`,
            )
            .scroll({
              top: pRef.current.offsetTop - paddingTopScroll,
            })
        }
      }, 50)
    }
  }, [valueSearch, isResearch])

  const handleOpen = () => {
    if (!isOpen) setIsOpen(true)
  }

  const handleClick = () => {
    setTimeout(() => {
      if (!isDoubleClick.current) setIsOpen(!isOpen)
    }, timeoutCheckDoubleClick)
  }

  const handleAddTickers = () => {
    isDoubleClick.current = true
    if (nameKey === itemNameKey.EXCHANGE)
      dispatch(getTickerByExchangeThunk({ GroupId: item.groupId }))
    else dispatch(getTickerByICBThunk({ ICBId: item.icbId }))
    setTimeout(() => {
      isDoubleClick.current = false
    }, timeoutCheckDoubleClick + 50)
  }

  return (
    <li
      className={`${style.clearHover} ${
        !isParentOpen ? style.displayNone : ''
      }`}
    >
      <p
        ref={pRef}
        className={`mb-10 mt-0 ${style.pHover} ${
          valueSearch.trim() &&
          item[nameKey].toUpperCase().includes(valueSearch.trim().toUpperCase())
            ? style.pActive
            : ''
        }`}
        onDoubleClick={handleAddTickers}
        onClick={handleClick}
      >
        <i
          className={
            Object.keys(item.children).length === 0
              ? style.ml13
              : isOpen
              ? 'icon-caret-down'
              : 'icon-caret-right'
          }
        />
        {item[nameKey]}
      </p>
      {Object.keys(item.children).length > 0 && (
        <ul>
          {Object.keys(item.children).map((key) => (
            <div key={item.children[key].icbId || item.children[key].groupId}>
              <TreeItem
                item={item.children[key]}
                nameKey={nameKey}
                valueSearch={valueSearch}
                isParentOpen={isOpen}
                handleParentOpen={handleOpen}
                isResearch={isResearch}
                offsetTopScroll={offsetTopScroll}
              />
            </div>
          ))}
        </ul>
      )}
    </li>
  )
}

TreeItem.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  isResearch: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  nameKey: PropTypes.string.isRequired,
  isParentOpen: PropTypes.bool.isRequired,
  handleParentOpen: PropTypes.func.isRequired,
  offsetTopScroll: PropTypes.object.isRequired,
}

const ExchangeTree = ({ valueSearch, isResearch, offsetTopScroll }) => {
  const { exchangesTree } = useGetExchange(true, true)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!valueSearch.trim()) setIsOpen(false)
  }, [valueSearch])

  const handleClick = () => setIsOpen(!isOpen)

  const handleOpen = () => setIsOpen(true)

  return (
    <li>
      <p className="mb-10 mt-0" onClick={handleClick}>
        <i className={isOpen ? 'icon-caret-down' : 'icon-caret-right'} />
        <Translate value="watchlist.watchlistDetail.EXCHANGE" />
      </p>
      {Object.keys(exchangesTree).length > 0 && (
        <ul>
          {Object.keys(exchangesTree)
            .sort()
            .map((key) => (
              <div key={exchangesTree[key].groupId}>
                <TreeItem
                  item={exchangesTree[key]}
                  nameKey={itemNameKey.EXCHANGE}
                  valueSearch={valueSearch}
                  isParentOpen={isOpen}
                  handleParentOpen={handleOpen}
                  isResearch={isResearch}
                  offsetTopScroll={offsetTopScroll}
                />
              </div>
            ))}
        </ul>
      )}
    </li>
  )
}

ExchangeTree.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  isResearch: PropTypes.bool.isRequired,
  offsetTopScroll: PropTypes.object.isRequired,
}

const SectorTree = ({ valueSearch, isResearch, offsetTopScroll }) => {
  const { ICBsTree } = useGetICB(true, true)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!valueSearch.trim()) setIsOpen(false)
  }, [valueSearch])

  const handleClick = () => setIsOpen(!isOpen)

  const handleOpen = () => setIsOpen(true)

  return (
    <li className="mb-10 mt-0">
      <p className="mb-10 mt-0" onClick={handleClick}>
        <i className={isOpen ? 'icon-caret-down' : 'icon-caret-right'} />
        <Translate value="watchlist.watchlistDetail.SECTOR" />
      </p>
      {Object.keys(ICBsTree).length > 0 && (
        <ul>
          {Object.keys(ICBsTree).map((key) => (
            <div key={ICBsTree[key].icbId}>
              <TreeItem
                item={ICBsTree[key]}
                nameKey={itemNameKey.ICB}
                valueSearch={valueSearch}
                isParentOpen={isOpen}
                handleParentOpen={handleOpen}
                isResearch={isResearch}
                offsetTopScroll={offsetTopScroll}
              />
            </div>
          ))}
        </ul>
      )}
    </li>
  )
}

SectorTree.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  isResearch: PropTypes.bool.isRequired,
  offsetTopScroll: PropTypes.object.isRequired,
}

const ExchangeSectorList = () => {
  const offsetTopScroll = useRef(infinity)

  const [valueSearch, setValueSearch] = useState('')
  const [isResearch, setIsResearch] = useState(false)

  const handleChangeInput = (e) => {
    offsetTopScroll.current = infinity
    setValueSearch(e.target.value)
  }

  const handleResearch = () => setIsResearch(!isResearch)

  return (
    <>
      <div className="filter-box">
        <p className="name">
          <Span style={{ fontSize: 10 }}>
            <Translate value="watchlist.watchlistDetail.TITLE_SEARCH_GROUP" />
          </Span>
        </p>
        <div className="form-search-inbox m-0">
          <Input
            onChange={handleChangeInput}
            type="text"
            className="search-inbox h-20"
            placeholder={I18n.t('watchlist.watchlistDetail.SEARCH_PLH')}
            style={{ fontSize: 10 }}
          />
          <button
            onClick={handleResearch}
            className={style.pointer}
            type="button"
          >
            <i className="icon-search-2" />
          </button>
        </div>
      </div>
      <div
        style={{ paddingBottom: '20px' }}
        className={'content-wrapper ' + scrollContainerClass}
      >
        <ScrollComponent
          autoHeight={true}
          autoHeightMax={200}
          verticalTrackWidth={5}
          horizontalTrackWidth={5}
        >
          <ul>
            <ExchangeTree
              valueSearch={valueSearch}
              isResearch={isResearch}
              offsetTopScroll={offsetTopScroll}
            />
            <SectorTree
              valueSearch={valueSearch}
              isResearch={isResearch}
              offsetTopScroll={offsetTopScroll}
            />
          </ul>
        </ScrollComponent>
      </div>
    </>
  )
}

export default ExchangeSectorList
