export const common = {
  TABLE: 'Bảng',
  FILTER: 'Bộ lọc',
  CHART: 'Biểu đồ',
  NO_DATA_SEARCH: 'Không có dữ liệu tìm kiếm',
  YEARLY: 'Năm',
  QUARTERLY: 'Quý',
  SIX_MONTHS: '6 Tháng',
  NINE_MONTHS: '9 Tháng',
  CONSOLIDATED: 'Hợp nhất',
  SEPARATED: 'Công ty mẹ',
  TYPE_OF_RATIO: 'Loại chỉ số',
  VIEW_BY: 'Xem theo',
  NUMBER_OF_PERIOD: 'Số kỳ',
  TYPE: 'Loại',
  LASTEST_YEAR: 'Năm gần nhất',
  STATEMENT: 'Báo cáo',
  COMMON_SIZE: 'Báo cáo theo tỷ trọng',
  CURRENCY: 'Tiền tệ',
  UNIT: 'Đơn vị',
  FINANCIAL_DATA: 'Dữ liệu tài chính',
  ALL: 'Tất cả',
}

export const financialDividend = {
  DIVIDEND: 'Cổ tức',
  CORPORATE_DIVIDEND: 'Cổ tức công ty',
  DECLARATION_DATE: 'Ngày công bố',
  EX_DEVIDEND_DATE: 'Ngày giao dịch không hưởng quyền',
  RECORD_DATE: 'Ngày đăng ký cuối cùng',
  PAYMENT_DATE: 'Ngày trả cổ tức',
  PAYMENT_TYPE: 'Loại thanh toán',
  EXERCISE_RATE: 'Tỷ lệ trả cổ tức',
  PLAN_VOLUME: 'Khối lượng dự kiến',
  ISSUE_VOLUME: 'Khối lượng chính thức',
  CASH_DIVIDEND: 'Cổ tức tiền mặt',
  STOCK_DIVIDEND: 'Cổ tức cổ phiếu',
  DIVIDEND_YEAR: 'Năm trả',
  DURATION: 'Kỳ trả',
  DPS: 'Cổ tức/Cổ phiếu',
  CURRENCY: 'Đơn vị',
  PE: 'P/E',
  DIV_YIELD: 'Lợi suất cổ tức',
  EPS: 'EPS',
  DIVIDEND_PAYOUT_RATIO: 'Hệ số thanh toán cổ tức',
  PRICE_EARNING_RATIO: 'Tỷ lệ thu nhập giá - Tỷ suất cổ tức',
  EARNING_AGAINST_DIVIDEND: 'Thu nhập so với cổ tức',
  EARNING_AGAINST_DIVIDEND_VND: 'Lợi nhuận và cổ tức (VND)',
  DIVIDEND_PAYOUT_RATIO_PERCENT: 'Tỷ lệ cổ tức',
  PE_TIME: 'P/E (Lần)',
  DIV_YIELD_PERCENT: 'Lợi suất cổ tức',
  DIVIDEND_HISTORY: 'Lịch sử cổ tức',
  FINANCIAL_RATIO_BY_YEAR: 'Chỉ số tài chính theo năm (Quý)',
  SALE_PER_SQUARE_VND: 'Giá trị (Tỷ VND)',
  PEER_COMPARISON: 'So sánh cùng ngành',
  SALE_PER_SQUARE: 'Giá trị',
}

export const financialRatio = {
  FINANCIAL_RATIOS: 'Chỉ số tài chính',
  ACTIVITY_RATIO: 'Hiệu quả hoạt động',
  LIQUIDITY_RATIO: 'Chỉ số thanh toán',
  SOLVENCY_RATIO: 'Sức khỏe tài chính',
  PROFITABILITY_RATIO: 'Khả năng sinh lợi',
  CASH_FLOW_RATIO: 'Chỉ số dòng tiền',
  GROWTH_RATIO: 'Chỉ số tăng trưởng',
  VALUATION_RATIO: 'Chỉ số định giá',
  BANKING_SECTOR_RATIO: 'Tỷ lệ khu vực ngân hàng',
  CORPORATE_FINANCIAL_RATIO: 'Chỉ số tài chính doanh nghiệp',
  GROWTH_TIMELINE: 'Tăng trưởng',
  SALE_PER_SQUARE: 'Doanh số bán hàng',
  SALE_PER_SQUARE_VND: 'Doanh số bán hàng (Tỷ VND)',
  SALES_PER_SQUARE: 'Doanh số bán hàng',
  COMPARISON: 'So sánh',
  PEER_COMPARISON: 'So sánh cùng ngành',
  CAPITAL_ADEQUACY: 'An toàn vốn',
  ASSET_QUALITY: 'Chất lượng tài sản',
  EFFICIENCY: 'Hiệu quả hoạt động',
  LIQUIDITY_ASSET: 'Tài sản & Thanh khoản',
  PROFITABILITY: 'Khả năng sinh lợi',
  GROWTH: 'Chỉ sổ tăng trưởng',
  BY_YEAR: 'theo Năm',
  BY_QUARTER: 'theo Quý',
  BY_SIX_MONTHS: 'theo 6 Tháng',
  BY_NINE_MONTHS: 'theo 9 Tháng',
  CHART_THOUSAND_VND: '(Nghìn VND)',
  CHART_MILLION_VND: '(Triệu VND)',
  CHART_BILLION_VND: '(Tỷ VND)',
}

export const financialStatement = {
  FINANCIAL_STATEMENT: 'Báo cáo tài chính',
  BALANCE_SHEET: 'Bảng cân đối kế toán',
  INCOME_STATEMENT: 'Báo cáo kết quả kinh doanh',
  CASH_FLOW: 'Báo cáo lưu chuyển tiền',
  NOTE: 'Thuyết minh',
  YES: 'Có',
  NO: 'Không',
  VND: 'VND',
  MILLION_VND: 'Triệu VND',
  BILLION_VND: 'Tỷ VND',
  USD: 'USD',
  THOUSAND_USD: 'Nghìn USD',
  MILLION_USD: 'Triệu USD',
  CORPORATE_FINANCIAL_STATEMENT: 'Báo cáo tài chính doanh nghiệp',
  TOI_STRUCTURE: 'Cấu trúc thu nhập hoạt động',
  CHART_VND: '(VND)',
  CHART_MILLION_VND: '(Triệu VND)',
  CHART_BILLION_VND: '(Tỷ VND)',
  CHART_USD: '(USD)',
  CHART_THOUSAND_USD: '(Nghìn USD)',
  CHART_MILLION_USD: '(Triệu USD)',
  NII: 'NII',
  NFI: 'NFI',
  OTHER_INCOME: 'Thu nhập khác',
  PROFIT_LOSS: 'Lợi nhuận',
  TOI: 'Tổng thu nhập hoạt động',
  PREPROVISON_PROFIT: 'Lợi nhuận trước dự phòng',
  PBT: 'Lợi nhuận trước thuế',
  REVENUE_PROFIT: 'Doanh thu & Lợi nhuận',
  NET_REVENUE: 'Doanh thu thuần',
  PROFIT_FINANCIAL_ACTIVITIES: 'LN hoạt động tài chính',
  NET_PROFIT: 'LNST',
  TOTAL_INSURANCE: 'Tổng chi bồi thường bảo hiểm',
  CLAIM_RATIO: 'Tỷ lệ bồi thường',
  OPERATING_SALE: 'Doanh thu hoạt động',
  BROKERAGE: 'Môi giới',
  MARGIN_LENDING: 'Cho vay',
  FINANCIAL_ASSET: 'Tài sản tài chính',
  OTHER: 'Khác',
  OPERATING_EXPENSES_STRUCTURE: 'Cấu trúc Chi phí hoạt động',
  REVENUE: 'Doanh thu',
  EBIT: 'EBIT',
  PROFITABILITY: 'Khả năng sinh lợi',
  GROSS_MARGIN: 'Biên LN gộp',
  EBIT_MARGIN: 'Biên EBIT',
  NET_MARGIN: 'Biên LN ròng',
  BALANCE_SHEET_STRUCTURE: 'Cấu trúc Bảng CĐKT',
  LOAN: 'Cho vay KH',
  G_BOND: 'TPCP',
  C_BOND: 'TPDN',
  OTHER_ASSET: 'Tài sản khác',
  CASA: 'CASA',
  TERM_DEPOSIT: 'Tiền gửi kỳ hạn',
  OTHER_LIABILITIES: 'Nợ khác',
  SHAREHOLDER_EQUITY: 'VCSH',
  DEPOSIT_CREDIT_GROWTH: 'Tăng trưởng tiền gửi & Tăng trưởng tín dụng',
  CHART_PERCENT: '(%)',
  LDR: 'LDR',
  DEPOSIT_GROWTH: 'Tăng trưởng tiền gửi',
  CREDIT_GROWTH: 'Tăng trưởng tín dụng',
  CASH_AND_EQUIVALENTS: 'Tiền & tương đương tiền',
  RECEIVABLES: 'Phải thu',
  INVESTMENT: 'Đầu tư',
  RESERVES: 'Dự phòng NV bảo hiểm',
  OWNER_EQUITY: 'VCSH',
  UNEARNED_PREMIUM_RESERVE: 'Dự phòng phí chưa được hưởng',
  CATASHTROPHE_RESERVE: 'Dự phòng dao động',
  CLAIM_RESERVE: 'Dự phòng bồi thường',
  OTHER_RESERVE: 'Dự phòng khác',
  BORROWING: 'Vay',
  MARGIN_LENDING_GROWTH: 'Cho vay ký quỹ vs Tăng trưởng',
  FVTPL: 'FVTPL',
  HTM: 'HTM',
  INVENTORY: 'HTK',
  FIXED_ASSET: 'TSCĐ',
  TRADE_PAYABLES: 'Phải trả người bán',
  SHORT_LONG_TERM: 'Vay NH & DH',
  ST_BORROWING: 'Vay ngắn hạn',
  LT_BORROWING: 'Vay dài hạn',
  CASH_FLOW_STRUCTURE: 'Cầu trúc dòng tiền',
  CFO: 'CFO',
  CFI: 'CFI',
  CFF: 'CFF',
  OPERATING_CASH_FLOW_BREAKDOWN: 'Phân tách dòng tiền hoạt động',
  PLUS_LOAN: '+/- Cho vay KH',
  PLUS_CUSTOMER_DEPOSIT: '+/- Tiền gửi KH',
  PLUS_TRADING_SECURITIES: '+/- CKKD',
  OTHER_CHANGES: 'Thay đổi khác',
  OPERATING_CASH_FLOW: 'Dòng tiền hoạt động kinh doanh',
  PLUS_FVPL: '+/- FVTPL',
  PLUS_HTM: '+/- HTM',
  PLUS_LENDING: '+/- Cho vay',
  PLUS_RECEIVABLE: '+/- Phải thu',
  PLUS_PAYABLE: '+/- Phải trả',
  PLUS_INVENTORY: '+/- HTK',
  TRADING: 'Thương mại',
  AGRICULTURE_FORESTRY: 'Nông nghiệp và lâm nghiệp',
  MANUFACTURING: 'Sản xuất',
  CONSTRUCTION: 'Xây dựng',
  PERSONAL_PUBLIC_SERVICE: 'Dịch vụ cộng đồng và cá nhân',
  WAREHOUSING_TRANSPORTATION: 'Kho bãi, vận tải và viễn thông',
  TRAINING_EDUCATION: 'Giáo dục và đào tạo',
  ESTATE_AGENT_CONSULTANT: 'Bất động sản và tư vấn',
  HOTEL_RESTAURANT: 'Khách sạn và nhà hàng',
  FINANCIAL_SERVICE: 'Dịch vụ tài chính',
  LOAN_TERM: 'Dư nợ cho vay theo kỳ hạn',
  SHORT_TERM_LOAN: 'Nợ ngắn hạn',
  MEDIUM_TERM_LOAN: 'Nợ trung hạn',
  LONG_TERM_LOAN: 'Nợ dài hạn',
  INSURANCE_PREMIUM_BREAKDOWN: 'Cơ cấu doanh thu phí bảo hiểm',
  LIFE_INSURANCE: 'Bảo hiểm nhân thọ',
  HEALTH_INSURANCE: 'Bảo hiểm sức khỏe',
  PROPERTY_DAMAGE_INSURANCE: 'Bảo hiểm tài sản & thiệt hại',
  CARGO_INSURANCE: 'Bảo hiểm hàng hóa vận chuyển',
  AVIATION_INSURANCE: 'Bảo hiểm hàng không',
  MOTOR_VEHICLE_INSURANCE: 'Bảo hiểm xe cơ giới',
  FIRE_INSURANCE: 'Bảo hiểm cháy, nổ',
  MARINE_INSURANCE: 'Bảo hiểm tàu thủy',
  LIABILITY_INSURANCE: 'Bảo hiểm trách nhiệm',
  CREDIT_FINANCIAL_INSURANCE: 'Bảo hiểm tín dụng và rủi ro tài chính',
  BUSINESS_DAMAGE_INSURANCE: 'Bảo hiểm thiệt hại kinh doanh',
  AGRICULTURAL_INSURANCE: 'Bảo hiểm nông nghiệp',
  OTHER_INSURANCE: 'Bảo hiểm khác',
  FINANCIAL_INCOME: 'Lợi nhuận tài chính',
  INTEREST_DEPOSIT: 'Lãi tiền gửi',
  INTEREST_BOND: 'Lãi đầu tư trái phiếu, kỳ phiếu, tín phiếu',
  OTHER_INTEREST: 'Lãi khác',
  FINANCIAL_ASSET_STRUCTURE: 'Cơ cấu Tài sản tài chính',
  LISTED_SECURITIES: 'CK Niêm Yết',
  NON_LISTED_SECURITIES: 'CK Chưa niêm yết',
  BOND: 'Trái phiếu',
  INVENTORY_STRUCTURE: 'Cơ cấu Hàng tồn kho',
  GOOD_TRANSIT: 'Hàng mua đang đi đường',
  RAW_MATERIAL: 'Nguyên liệu, vật liệu',
  TOOL_SUPPLIES: 'Công cụ, dụng cụ',
  FINISHED_GOOD: 'Thành phẩm',
  MERCHANDISE_INVENTORIES: 'Hàng hóa',
  FINANCIAL_EXPENSES: 'Chi phí tài chính',
  INTEREST_EXPENSES: 'Chi phí lãi vay',
  OTHER_FINANCIAL_EXPENSES: 'Chi phí khác',
  LOAN_INDUSTRY: 'Dư nợ cho vay theo ngành',
  AUDIT_STATUS: 'Trạng thái kiểm toán',
  AUDIT_FIRM: 'Công ty Kiểm toán',
  AUDIT_OPINION_TYPE_NAME: 'Loại Ý kiến Kiểm toán',
  FIINPRO_VERSION: 'Phiên bản FiinPro (cũ)',
  FIINPRO_X_VERSION: 'Phiên bản FiinPro-X',
}
