import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  selectData,
  selectDataSummary,
  selectIds,
  selectLoading,
} from './store/slice'
import { getDeepTransactionSummary } from './store/thunk'

export const Summary = () => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoading)
  const data = useSelector(selectData)

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getDeepTransactionSummary({ OrganizationId: basicInfo.organizationId }),
      )
    }
  }, [basicInfo.organizationId])

  return isLoading ? (
    <Loading />
  ) : data.length > 0 ? (
    <Table
      ids={ids}
      getDataFromRedux={selectDataSummary}
      schema={['title', 'value'].map((key) => {
        if (key === 'title') {
          return {
            colId: key,
            isI18n: false,
            tdStyle: {
              textAlign: 'left',
              whiteSpace: 'nowrap',
              fontWeight: 440,
            },
            render: (data) => <Translate value={data} />,
          }
        }

        return {
          colId: key,
          tdStyle: {
            textAlign: 'right',
            whiteSpace: 'nowrap',
            fontWeight: 340,
          },
        }
      })}
      renderHeader={() => <></>}
      hasFooter={false}
      isPagination={false}
      hasTwoColumnBorder
    />
  ) : (
    <NoData />
  )
}
