import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { isInValidValue } from '../../../../utils/Value'
import CalendarPopper from '../../../calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../calendar/headers/HeaderOneCalendarRange'
import { convertDateFromDatePicker } from '../../../calendar/helper'
import InputDateCalendar from '../../../calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../hooks/useTimeZone'
import style from '../index.module.css'
import {
  changeValueDetailPopupIndicator,
  selectValueDetailPopupIndicator,
} from '../store/slice'

const CalendarSelect = ({
  defaultValue,
  keySelect,
  disabled,
  keyMax,
  keyMin,
  isHaveIcon,
  mostRecentDate,
  funcSelect,
  funcChange,
}) => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const functionSelect = funcSelect || selectValueDetailPopupIndicator
  const functionChange = funcChange || changeValueDetailPopupIndicator

  const valueStore = useSelector(functionSelect(keySelect))
  const max = useSelector(functionSelect(keyMax)) || mostRecentDate || undefined
  const min = useSelector(functionSelect(keyMin)) || undefined

  const value = valueStore || defaultValue

  useEffect(() => {
    if (isInValidValue(valueStore)) {
      dispatch(
        functionChange({
          key: keySelect,
          value: getISOStartOrEndOfDay(defaultValue, timezone, true),
        }),
      )
    }
  }, [keySelect])

  const handleChange = (value) => {
    dispatch(
      functionChange({
        key: keySelect,
        value: getISOStartOrEndOfDay(value, timezone, true),
      }),
    )
  }

  return (
    <div
      className={`h-20 d-flex ali-center ${style.calendarSelect} ${
        disabled ? style.disabled : ''
      }`}
    >
      <CalendarPopper
        date={new Date(convertDateFromDatePicker(value))}
        handleChange={handleChange}
        customHeader={HeaderOneCalendarRange({
          startYear: 2000,
          endYear: new Date().getFullYear(),
          isOptionSelectedDefaultColor: false,
        })}
        CustomInput={InputDateCalendar}
        maxDate={
          max
            ? new Date(formatDateTime(max, FORMAT.DATE, undefined, timezone))
            : undefined
        }
        minDate={
          min
            ? new Date(formatDateTime(min, FORMAT.DATE, undefined, timezone))
            : undefined
        }
      />
      {isHaveIcon && <i className="icon-date" />}
    </div>
  )
}

CalendarSelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  keySelect: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  keyMax: PropTypes.string,
  keyMin: PropTypes.string,
  isHaveIcon: PropTypes.bool,
}

CalendarSelect.defaultProps = {
  defaultValue: new Date(),
  disabled: false,
  keyMax: 'null',
  keyMin: 'null',
  isHaveIcon: true,
}

export default CalendarSelect
