import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Area, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../../utils/Value'
import { MONEY_MARKET_SYNC_ID } from '../../config'
import { LINE_CHART_COLORS } from './constants'
import { getTooltipContentData, handleTime, renderFooter } from './helper'
import style from './index.module.css'

const HEADER_HEIGHT = 25
const YAXIS_LEFT = 'left'
const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 2,
}

const ChartComponent = ({
  data,
  height,
  width,
  keyXAxis,
  areaKey,
  lineKey,
  title,
  locale,
}) => {
  const timeZone = UseTimeZone()
  const dataFormatDate = handleTime(data, timeZone, locale)

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: [areaKey, ...lineKey],
      orientation: YAXIS_LEFT,
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      label: I18n.t('economy.monetary.monetary.BILLION_VND'),
      tickNum: SETTINGS.yTickNum,
    },
  ]

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, title).map((item) => {
          return (
            <div
              key={item.key}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <Span style={{ fontSize: 11 }}>{item.label}:</Span>
              <Span
                style={{ marginLeft: 25, fontSize: 11 }}
                className={`${style.toolTipContentValue} ${
                  typeof item.value === 'number' &&
                  item.value < 0 &&
                  style.redColor
                }`}
              >
                {formatVal(item.value)}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  const gradientOffset = (data) => {
    const dataNotUndefined = data.filter((item) => item[areaKey] !== undefined)
    const dataMax = Math.max.apply(
      Math,
      dataNotUndefined.map((i) => i[areaKey]),
    )
    const dataMin = Math.min.apply(
      Math,
      dataNotUndefined.map((i) => i[areaKey]),
    )
    if (dataMax <= 0) {
      return 0
    }
    if (dataMin >= 0) {
      return 1
    }

    return dataMax / (dataMax - dataMin)
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(sizes) => {
        return (
          <>
            {typeof sizes.height === 'number' && (
              <ChartContainer
                data={dataFormatDate}
                height={height - sizes.height - HEADER_HEIGHT}
                width={width}
                keyXAxis={keyXAxis}
                yAxis={keyYAxis}
                renderCustomTooltip={renderTooltip}
                syncId={MONEY_MARKET_SYNC_ID}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis
                margin={{
                  ...MARGIN_RECHARTS,
                  right: 46,
                }}
              >
                <defs>
                  <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                    <stop offset={'0%'} stopColor="#31e984" stopOpacity={1} />
                    <stop
                      offset={gradientOffset(dataFormatDate)}
                      stopColor="#31e984"
                      stopOpacity={0.2}
                    />
                    <stop
                      offset={gradientOffset(dataFormatDate)}
                      stopColor="#fd3e4d"
                      stopOpacity={0.2}
                    />
                    <stop offset={'100%'} stopColor="#fd3e4d" stopOpacity={1} />
                  </linearGradient>
                </defs>
                <defs>
                  <linearGradient id="strokeColor" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset={gradientOffset(dataFormatDate)}
                      stopColor="#31e984"
                      stopOpacity={1}
                    />
                    <stop
                      offset={gradientOffset(dataFormatDate)}
                      stopColor="#fd3e4d"
                      stopOpacity={1}
                    />
                  </linearGradient>
                </defs>
                <Area
                  isAnimationActive={false}
                  yAxisId={YAXIS_LEFT}
                  dataKey={areaKey}
                  fill="url(#splitColor)"
                  stroke="url(#strokeColor)"
                  activeDot={false}
                />
                {lineKey.map((lineKey, index) => {
                  return (
                    <React.Fragment key={lineKey}>
                      <Line
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        dataKey={lineKey}
                        stroke={LINE_CHART_COLORS[index]}
                        strokeWidth={SETTINGS.strokeWidth}
                        dot={false}
                        activeDot={false}
                        connectNulls={true}
                      />
                    </React.Fragment>
                  )
                })}
              </ChartContainer>
            )}
            <Footer key={width} list={renderFooter(areaKey, lineKey, title)} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
