import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../../core/services/market/marketInDepth/IndexStatisticService'
import { handleExport } from '../../../../../../utils/Export'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'

export const getProprietaryOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/proprietary/GET_PROPRIETARY_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await IndexStatisticService.getProprietaryOverview(
        params,
      )
      if (response.success) {
        if (params.TimeFilter === PRICE_DATA_TIME_SELECT.CUSTOM) {
          dispatch(getIndexStatisticsProprietaryOverviewTotal(params))
        }
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProprietaryDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/proprietary/GET_PROPRIETARY_DETAIL',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await IndexStatisticService.getProprietaryDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadProprietaryOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/proprietary/DOWNLOAD_PROPRIETARY_OVERVIEW',
  async (data) => {
    const response = await IndexStatisticService.downloadProprietaryOverview(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadProprietaryDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/proprietary/DOWNLOAD_PROPRIETARY_DETAIL',
  async (data) => {
    const response = await IndexStatisticService.downloadProprietaryDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexStatisticsProprietaryOverviewTotal = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/proprietary/GET_INDEX_STATISTICS_PROPRIETARY_OVERVIEW_TOTAL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsProprietaryOverviewTotal(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
