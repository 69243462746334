import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainerWithZoom } from '../../../../../common/chart/rechart/ChartContainerWithZoom'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'
import { SEARCH_INDICATOR } from '../../constants'
import style from '../../style.module.css'
import { changeLineActive, selectLineActive } from '../store/slice'
import ChartTooltip from './ChartTooltip'
import { formatDataChart } from './helper'

const SETTINGS = {
  yTickNum: 8,
  xTickNum: 5,
}
const WIDTH_LABEL_TIMES = 76
const WIDTH_LABEL_POINTS = 94
const WIDTH_LABEL_TIMES_COMPARE = 94

export const WIDTH_LABEL = 84
const MARGIN = { ...MARGIN_RECHARTS, top: 30, left: 48 }
export const YAXIS_ID = {
  TIMES: 'TIMES',
  POINTS: 'POINTS',
  TIMES_COMPARE: 'TIMES_COMPARE',
}

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  timeFrame,
  rightLineKeys,
  rightLineKeysSecond,
  rightLineKeysCompare,
  isCompare,
  stateIndicator,
  typeCurrentInfo,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const heightLabel = getFontSize(18)
  const hoveredItem = useSelector(selectLineActive)
  const timeZone = UseTimeZone()

  const formatData = useMemo(() => {
    return formatDataChart(
      data,
      typeCurrentInfo,
      'valuationValue',
      [...rightLineKeys, ...rightLineKeysSecond, ...rightLineKeysCompare],
      stateIndicator,
    )
  }, [data, typeCurrentInfo, stateIndicator])

  const getYAxis = useMemo(() => {
    const referenceLabelOption = {
      isLineChart: true,
      orientation: 'right',
      hasReferenceLabel: true,
      referenceLabelHeight: heightLabel,
      tickNum: SETTINGS.yTickNum,
    }

    if (isCompare) {
      return [
        {
          id: YAXIS_ID.TIMES_COMPARE,
          keys: rightLineKeysCompare.map((item) => item.id),
          referenceLabelWidth: WIDTH_LABEL_TIMES_COMPARE,
          label:
            stateIndicator === SEARCH_INDICATOR.priceToBook
              ? I18n.t('market.marketInDepth.valuation.LABEL_PB')
              : I18n.t('market.marketInDepth.valuation.LABEL_PE'),
          labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
          lineKeys: rightLineKeys,
          lineKey: 'id',
          ...referenceLabelOption,
          renderReferenceLabel: ({ data, key, item }) => {
            return <CustomLabel value={data[key]} item={item} />
          },
        },
      ]
    }

    return [
      {
        id: YAXIS_ID.POINTS,
        keys: rightLineKeys.map((item) => item.id),
        referenceLabelWidth: WIDTH_LABEL_TIMES,
        label: I18n.t('market.marketInDepth.valuation.LB_TIMES'),
        labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
        lineKeys: rightLineKeys,
        lineKey: 'id',
        ...referenceLabelOption,
        renderReferenceLabel: ({ data, key, item }) => {
          const keyHideLabel = [
            'lowerLimitPB',
            'upperLimitPB',
            'lowerLimitPE',
            'upperLimitPE',
          ]
          if (keyHideLabel.includes(key) || !data[key]) {
            return null
          } else {
            return <CustomLabel value={data[key]} item={item} />
          }
        },
      },
      {
        id: YAXIS_ID.TIMES,
        keys: rightLineKeysSecond.map((item) => item.id),
        referenceLabelWidth: WIDTH_LABEL_POINTS,
        label: I18n.t('market.marketInDepth.valuation.LB_POINT'),
        labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
        lineKeys: rightLineKeysSecond,
        lineKey: 'id',
        ...referenceLabelOption,
        renderReferenceLabel: ({ data, key, item }) => {
          return <CustomLabel value={data[key]} item={item} />
        },
      },
    ]
  }, [heightLabel, formatData, timeFrame])

  const renderTooltipTable = (tooltipData) => {
    return (
      <ChartTooltip
        tooltipData={tooltipData}
        isCompare={isCompare}
        rightLineKeys={rightLineKeys}
        rightLineKeysSecond={rightLineKeysSecond}
        rightLineKeysCompare={rightLineKeysCompare}
      />
    )
  }

  return (
    <>
      <ChartContainerWithZoom
        key={timeFrame + stateIndicator}
        data={formatData}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        xTickNum={SETTINGS.xTickNum}
        timeFrame={timeFrame}
        tickFormatter={(value) =>
          formatDateTime(value, FORMAT.DATE, locale, timeZone)
        }
        yAxis={getYAxis}
        margin={MARGIN}
        zoomRightYBarPosition="left"
        marginZoomX={{ left: 10, right: 0, top: 25, bottom: -8 }}
        marginZoomY={{ left: 30, right: 0, top: 25, bottom: 8 }}
        renderCustomTooltip={renderTooltipTable}
        listTitleTooltipZoom={[
          'market.marketInDepth.valuation.LB_TIMES',
          'market.marketInDepth.valuation.LB_POINT',
        ]}
        yTickNum={SETTINGS.yTickNum}
      >
        {!isCompare ? (
          <>
            {rightLineKeys.map((item) => {
              return (
                <Line
                  key={item.id}
                  yAxisId={YAXIS_ID.POINTS}
                  dataKey={item.id}
                  type="linear"
                  stroke={item.bgColor}
                  strokeOpacity={
                    !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2
                  }
                  strokeWidth={item.strokeWidth || 2}
                  connectNulls
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              )
            })}
            {rightLineKeysSecond.map((item) => {
              return (
                <Line
                  key={item.id}
                  yAxisId={YAXIS_ID.TIMES}
                  dataKey={item.id}
                  type="linear"
                  stroke={item.bgColor}
                  strokeOpacity={
                    !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2
                  }
                  strokeWidth={item.strokeWidth || 2}
                  connectNulls
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              )
            })}
          </>
        ) : (
          <>
            {rightLineKeysCompare.map((item) => {
              return (
                <Line
                  key={item.id}
                  yAxisId={YAXIS_ID.TIMES_COMPARE}
                  dataKey={item.id}
                  type="linear"
                  stroke={item.bgColor}
                  strokeOpacity={
                    !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2
                  }
                  strokeWidth={
                    !hoveredItem
                      ? 1.5
                      : hoveredItem === item.id
                      ? 2
                      : item.strokeWidth
                  }
                  connectNulls
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                />
              )
            })}
          </>
        )}
      </ChartContainerWithZoom>
    </>
  )
}

const CustomLabel = ({ value, item }) => {
  const refLabel = useRef()
  const dispatch = useDispatch()
  const COLUMN_GAP = 3

  const [widthTextValue, setWidthTextValue] = useState(0)

  const hoveredItem = useSelector(selectLineActive)

  const onHover = () => {
    dispatch(changeLineActive(item.id))
  }

  const onLeave = () => {
    dispatch(changeLineActive(null))
  }

  useEffect(() => {
    if (refLabel.current) {
      setWidthTextValue(refLabel.current?.offsetWidth)
    }
  }, [refLabel.current])

  return (
    <div
      className={style.textNormal}
      style={{
        color: item.textColor,
        background: item.bgColor,
        opacity: !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2,
      }}
      onMouseOver={onHover}
      onMouseOut={onLeave}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '100%',
          alignItems: 'center',
          padding: '0px 3px',
          gap: COLUMN_GAP,
        }}
      >
        <div
          style={{
            width: `calc(100% - ${widthTextValue + COLUMN_GAP}px)`,
          }}
        >
          <Span>
            <TextEllipsis text={item?.nameCompare} isI18n={false} />
          </Span>
        </div>
        <div ref={refLabel}>
          <Span>{formatVal(value)}</Span>
        </div>
      </div>
    </div>
  )
}

export default ChartComponent
