import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import {
  selectLimitNode,
  updateLimitNode,
} from '../../filter/FilterOption/store/slice'
import style from './index.module.css'

const CustomizedSlider = styled((props) => (
  <Slider slotProps={{ thumb: { className: 'thumb' } }} {...props} />
))`
  color: #fff;
  :hover {
    color: #fff;
  }
  & .thumb {
    color: #fff;
    width: 12px;
    height: 12px;
    z-index: 10;
  }

  & .MuiSlider-root {
    padding: 6px 0 !important;
  }
  .MuiSlider-rail {
    background-color: #535f6f;
    height: 7px;
    border: none;
    border-radius: unset;
  }
  .MuiSlider-track {
    background-color: #57626c;
    height: 7px;
    border: none;
    border-radius: unset;
  }
  .MuiSlider-thumb:hover {
    box-shadow: unset;
  }
  .MuiSlider-thumb.Mui-focusVisible {
    box-shadow: unset;
  }
  .Mui-active {
    box-shadow: unset;
  }
  .Mui-focusVisible {
    box-shadow: unset;
  }
  .MuiSlider-markLabel {
    color: #57626c;
  }
  .MuiSlider-mark {
    background-color: #57626c;
    height: 4px;
    top: 80%;
  }
  .MuiSlider-valueLabelOpen {
    background-color: #57626c;
    color: #fff;
  }
`

const RangeFilter = () => {
  // const radio = window.devicePixelRatio
  const dispatch = useDispatch()
  const limitNode = useSelector(selectLimitNode)

  const changeLimitNode = (event) => {
    dispatch(updateLimitNode(event?.target?.value))
  }
  return (
    <div className="d-flex" style={{ gap: '4px' }}>
      <div>
        <Span>
          <Translate value="bond.interconnectionMap.chart.filter.labelRangeFilter" />
        </Span>
      </div>
      <div
        style={{
          width: '160px',
          position: 'relative',
          top: '-6px',
          // zoom: `${radio * 100}%`,
        }}
      >
        <div className={style.starttrack} />
        <CustomizedSlider
          value={limitNode}
          step={1}
          min={1}
          max={15}
          marks={[
            { value: 1, label: '1' },
            { value: 2, label: '' },
            { value: 3, label: '' },
            { value: 4, label: '' },
            { value: 5, label: '5' },
            { value: 6, label: '' },
            { value: 7, label: '' },
            { value: 8, label: '' },
            { value: 9, label: '' },
            { value: 10, label: '10' },
            { value: 11, label: '' },
            { value: 12, label: '' },
            { value: 13, label: '' },
            { value: 14, label: '' },
            { value: 15, label: '15' },
          ]}
          valueLabelDisplay={'auto'}
          valueLabelFormat={(x) => {
            return x
          }}
          onChange={(e) => {
            changeLimitNode(e)
          }}
        />
        <div className={style.endtrack} />
      </div>
    </div>
  )
}

export default RangeFilter
