import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styles from '../../../common/table/index.module.css'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import { OVERVIEW_GROUP_ID } from '../../../common/topInfo/stockTopInfo/constants'
import { selectBasicInfoOverview } from '../store/slice'
import { TABLE_ROW_LEVEL } from './constants'
import { selectGroupColumns, selectItemData } from './store/slice'

const MARGIN_LEFT = 16

export const FirstColumnCell = ({
  val,
  rowId,
  defaultActiveRowId,
  style,
  className,
}) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))
  const basicInfo = useSelector(selectBasicInfoOverview)
  const groupColumns = useSelector(selectGroupColumns)

  useEffect(() => {
    if (rowId === defaultActiveRowId) {
      tdRef.current.classList.add(styles.active)
    } else {
      tdRef.current.classList.remove(styles.active)
    }
  }, [groupColumns])

  if (
    basicInfo.groupId === OVERVIEW_GROUP_ID.BANK ||
    basicInfo.groupId === OVERVIEW_GROUP_ID.CORPORATE
  ) {
    if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
      return (
        <td ref={tdRef} className={className} style={style}>
          <TextEllipsisStaticLine
            val={val}
            appendStyle={{ color: '#fecf2f' }}
          />
        </td>
      )
    } else if (item.level === TABLE_ROW_LEVEL.LEVEL_3) {
      return (
        <td ref={tdRef} className={className} style={style}>
          <TextEllipsisStaticLine
            val={val}
            appendStyle={{
              marginLeft: 2 * MARGIN_LEFT,
              color: '#ececec66',
              fontStyle: 'italic',
            }}
          />
        </td>
      )
    }

    return (
      <td ref={tdRef} className={className} style={style}>
        <TextEllipsisStaticLine
          val={val}
          appendStyle={{ marginLeft: MARGIN_LEFT }}
        />
      </td>
    )
  } else {
    if (item.level === TABLE_ROW_LEVEL.LEVEL_3) {
      return (
        <td ref={tdRef} className={className} style={style}>
          <TextEllipsisStaticLine
            val={val}
            appendStyle={{
              marginLeft: MARGIN_LEFT,
              color: '#ececec66',
              fontStyle: 'italic',
            }}
          />
        </td>
      )
    }

    return (
      <td ref={tdRef} className={className} style={style}>
        <TextEllipsisStaticLine val={val} />
      </td>
    )
  }
}

FirstColumnCell.propTypes = {
  val: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  defaultActiveRowId: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
}
