import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { Table } from '../../../common/table'
import { keyBy } from '../../../utils/Common'
import { valToPercent } from '../../../utils/Value'
import {
  changeMonth,
  changeYear,
  selectCurrentMonth,
  selectCurrentYear,
} from '../contribution/store/slice'
import { NUM_OF_PERIOD, NUM_OF_PERIOD_FIRST_FETCH } from './constants'
import { getItemParent } from './helper'
import {
  addSectorCPIToList,
  changeIsNotGetMaxMonth,
  changeLocale,
  keepCurrentItems,
  removeSectorCPIFromList,
  selectCPIDataTable,
  selectCPIIds,
  selectDataTable,
  selectDataWithColorId,
  selectGroupColumns,
  selectIsChangeTypeCode,
  selectListCPISector,
  selectLoadingTableContentCPI,
  selectMaxMonth,
  selectMaxYear,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectTypeCode,
  sort,
} from './store/slice'
import {
  cpiAndInflationTableContent,
  cpiAndInflationTableContentFirstRender,
} from './store/thunk'
import { TdSelect } from './TdSelect'

const HORIZONTAL_TRACK_LEFT = 28

export const CpiAndInflationTable = ({ width, height }) => {
  const dispatch = useDispatch()

  const cpiIds = useSelector(selectCPIIds)
  const isContentLoading = useSelector(selectLoadingTableContentCPI)
  const dataTable = useSelector(selectDataTable)
  const listCPISector = useSelector(selectListCPISector)
  const typeCode = useSelector(selectTypeCode)
  const locale = useSelector((state) => state.i18n.locale)
  const groupColumns = useSelector(selectGroupColumns)
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const currentMonthSelectedChart = useSelector(selectCurrentMonth)
  const currentYearSelectedChart = useSelector(selectCurrentYear)
  const dataWithColorId = useSelector(selectDataWithColorId)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const isChangeTypeCode = useSelector(selectIsChangeTypeCode)

  const [rowsCollapse, setRowsCollapse] = useState([])
  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)
  const [isFirstRender, setIsFirstRender] = useState(true)

  const getColorIdByCPIId = (cpiId) => {
    return dataWithColorId?.find((item) => item.cpivnTypeId === cpiId)?.colorId
  }

  const onRowClick = (rowId) => {
    if (!listCPISector?.some((item) => item.cpivnTypeId === rowId)) {
      const cpiSector = {
        cpivnTypeId: rowId,
        cpivnTypeName: dataTable?.find((item) => item.cpivnTypeId === rowId)
          .cpivnTypeName,
        colorId: getColorIdByCPIId(rowId),
      }
      dispatch(addSectorCPIToList(cpiSector))
    } else {
      dispatch(removeSectorCPIFromList(rowId))
    }
  }

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old?.filter((item) => item !== rowId))
    }
  }

  const cpiSectorDataTableItemWithCollapse = dataTable?.map((item) => ({
    ...item,
    isCollapsible:
      getItemParent(dataTable)?.includes(item) || item.cpivnTypeLevel === 1
        ? true
        : false,
  }))

  const levelCollapse = keyBy(
    cpiSectorDataTableItemWithCollapse?.map((item) => ({
      id: item.cpivnTypeId,
      level: item.cpivnTypeLevel,
      isCollapsible: item.isCollapsible,
    })),
    'id',
  )

  const scrollToLeftCallback = () => {
    dispatch(changeIsNotGetMaxMonth(true))
    setNewMaxMonth(minCurrentMonth)
    setIsFirstRender(false)
  }

  //called only when change typeCode, fetch 20 periods
  useEffect(() => {
    setIsFirstRender(true)
    if (typeCode) {
      dispatch(
        cpiAndInflationTableContentFirstRender({
          CPIVNTypeCode: typeCode,
          NumOfPeriod: NUM_OF_PERIOD_FIRST_FETCH,
        }),
      )
    }
    dispatch(changeIsNotGetMaxMonth(false))
  }, [typeCode, locale])

  //called when scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxMonth && !isFirstRender) {
      dispatch(
        cpiAndInflationTableContent({
          CPIVNTypeCode: typeCode,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          NumOfPeriod: NUM_OF_PERIOD,
        }),
      )
    }
  }, [newMaxMonth, isFirstRender])

  useEffect(() => {
    if (!currentMonthSelectedChart) {
      dispatch(changeMonth(maxMonth))
    }
    if (!currentYearSelectedChart) {
      dispatch(changeYear(maxYear))
    }
  }, [typeCode, locale])

  useEffect(() => {
    dispatch(changeLocale())
    dispatch(keepCurrentItems(listCPISector?.map((item) => item.colorId)))
  }, [locale])

  return (
    width &&
    height && (
      <>
        {isChangeTypeCode && <Loading />}
        {!isChangeTypeCode && (
          <Table
            ids={cpiIds}
            horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
            sort={sort}
            resizable={false}
            getDataFromRedux={selectCPIDataTable}
            isLoading={isContentLoading}
            columnDraggable={false}
            hasTooltip={false}
            hasFooter={false}
            onRowClick={onRowClick}
            stickyFirstColumn
            schema={groupColumns.map((item, index) => {
              const title = item.title
              const colId = item.key
              let result = {
                colId,
                title,
                isI18n: false,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
              }
              if (index === 0) {
                result = {
                  ...result,
                  isI18n: false,
                  thStyle: {
                    textAlign: 'left',
                  },
                  tdStyle: {
                    textAlign: 'left',
                  },
                  render: (val, rowId) => {
                    return (
                      <TdSelect
                        val={val}
                        rowId={rowId}
                        onRowCollapse={onRowCollapse}
                        dataTable={dataTable}
                      />
                    )
                  },
                }
              }
              if (index !== 0) {
                result = {
                  ...result,
                  thStyle: {
                    minWidth: 85,
                    textAlign: 'right',
                  },
                  render: (val) => {
                    return (
                      <span
                        style={{
                          color: val < 0 ? '#ff4752' : '',
                        }}
                      >
                        {valToPercent(val)}
                      </span>
                    )
                  },
                }
              }
              return result
            })}
            isCollapse={true}
            rowsCollapse={rowsCollapse}
            levelCollapse={levelCollapse}
            scrollToLeftCallback={scrollToLeftCallback}
            defaultScrollToRight={isFirstRender}
            isLazyLoadLeft={true}
          />
        )}
      </>
    )
  )
}
