import moment from 'moment'
import { I18n, Translate } from 'react-redux-i18n'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { CHART_TYPES } from '../../../../../common/chart/constants'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { VALUE_TYPES } from '../constants'

const colors = [
  '#185999',
  '#3CABEB',
  '#DF5A49',
  '#E8B714',
  '#A6CF98',
  '#FFF6DF',
  '#9C54E4',
  '#00E5E4',
  '#FACC5C',
  '#FA9AA7',
  '#D9D9D9',
]

export const formatSectorDataApi = (rawData) => {
  const dataByDate = {}

  rawData.forEach((item) => {
    const key = `${item.reportMonth}-${item.reportYear}`
    if (!dataByDate[key]) {
      dataByDate[key] = [
        {
          [DEFAULT_XAXIS_DATA_KEY]: formatDateTime(
            new Date(item.reportYear, item.reportMonth - 1).toString(),
            FORMAT.MONTH_YEAR_NUMBER,
          ),
          [`${DEFAULT_PRE_VALUE_KEY}1`]: item.percentICB,
          [`${DEFAULT_PRE_VALUE_KEY}1Raw`]: item.value_ICB / 10 ** 9,
          [`${DEFAULT_PRE_VALUE_KEY}1Title`]: item.icbName,
          totalValue: item.totalValue_ICB / 10 ** 9,
        },
      ]
    } else {
      dataByDate[key].push({
        [DEFAULT_XAXIS_DATA_KEY]: formatDateTime(
          new Date(item.reportYear, item.reportMonth - 1).toString(),
          FORMAT.MONTH_YEAR_NUMBER,
        ),
        [`${DEFAULT_PRE_VALUE_KEY}${dataByDate[key].length + 1}`]:
          item.percentICB,
        [`${DEFAULT_PRE_VALUE_KEY}${dataByDate[key].length + 1}Raw`]:
          item.value_ICB / 10 ** 9,
        [`${DEFAULT_PRE_VALUE_KEY}${dataByDate[key].length + 1}Title`]:
          item.icbName,
        totalValue: item.totalValue_ICB / 10 ** 9,
      })
    }
  })

  const data = Object.values(dataByDate).map((values) => {
    let otherValue = values[0].totalValue
    let otherPercent = 1
    const object = values.reduce((obj, item, index) => {
      otherValue -= item[`${DEFAULT_PRE_VALUE_KEY}${index + 1}Raw`]
      otherPercent -= item[`${DEFAULT_PRE_VALUE_KEY}${index + 1}`]
      return { ...obj, ...item }
    }, {})
    return {
      ...object,
      [`${DEFAULT_PRE_VALUE_KEY}${values.length + 1}`]:
        otherPercent >= 0 ? otherPercent : 0,
      [`${DEFAULT_PRE_VALUE_KEY}${values.length + 1}Raw`]: otherValue,
      [`${DEFAULT_PRE_VALUE_KEY}${values.length + 1}Title`]:
        I18n.t('common.OTHERS'),
    }
  })

  return data.sort(
    (a, b) =>
      moment(a[DEFAULT_XAXIS_DATA_KEY], 'MM-YYYY').unix() -
      moment(b[DEFAULT_XAXIS_DATA_KEY], 'MM-YYYY').unix(),
  )
}

const getRenderTooltipValue = (value, percent, isRelative) => (
  <div className="d-flex ali-center">
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
    >
      {formatVal(value)}
    </Span>
    <Span
      style={{
        fontSize: 11,
        fontWeight: 500,
      }}
      className="ml-4"
    >
      <Translate value="common.UNIT.BILLION_VND" />
    </Span>
    {isRelative && (
      <Span
        style={{
          fontSize: 11,
          fontWeight: 500,
        }}
        className="ml-4"
      >
        ({valToPercent(percent)})
      </Span>
    )}
  </div>
)

const getSchema = (data, valueType) => {
  if (!data.length) return []

  const objData = data.reduce((obj, item) => ({ ...obj, ...item }), {})
  const keys = Object.keys(objData).filter(
    (key) => key.includes(DEFAULT_PRE_VALUE_KEY) && key.includes('Title'),
  )

  return keys.map((_, index) => ({
    text: objData[`${DEFAULT_PRE_VALUE_KEY}${index + 1}Title`],
    isI18n: true,
    dataKey:
      valueType === VALUE_TYPES.RELATIVE
        ? `${DEFAULT_PRE_VALUE_KEY}${index + 1}`
        : `${DEFAULT_PRE_VALUE_KEY}${index + 1}Raw`,
    chart: {
      type: CHART_TYPES.STACK_BAR,
    },
    tooltip: {
      renderValue: (value) =>
        getRenderTooltipValue(
          value[`${DEFAULT_PRE_VALUE_KEY}${index + 1}Raw`],
          value[`${DEFAULT_PRE_VALUE_KEY}${index + 1}`],
          valueType === VALUE_TYPES.RELATIVE,
        ),
    },
  }))
}

export const getSectorProps = ({ data, valueType, isFullScreen }) => {
  const schema = getSchema(data, valueType)
  return {
    colors,
    schema,
    chartProps: {
      chartType: 'ComposedChart',
      yAxis: [
        {
          dataKeys: schema.map(
            (_, index) =>
              `${DEFAULT_PRE_VALUE_KEY}${index + 1}${
                valueType === VALUE_TYPES.RELATIVE ? '' : 'Raw'
              }`,
          ),
          labelText:
            valueType === VALUE_TYPES.RELATIVE
              ? ''
              : I18n.t('common.UNIT.BILLION_VND'),
          tickValues:
            valueType === VALUE_TYPES.RELATIVE
              ? [0, 0.25, 0.5, 0.75, 1]
              : undefined,
          tickFormatter:
            valueType === VALUE_TYPES.RELATIVE
              ? (value) => valToPercent(value, false, false, 0)
              : undefined,
          isStackedBar: true,
        },
      ],
    },
    footerProps: { isFullScreen },
  }
}
