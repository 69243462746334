import { useSelector } from 'react-redux'
import { selectChartTabActiveData } from '../../../store/slice'

const useGetDataStyle = () => {
  const chartTabActiveData = useSelector(selectChartTabActiveData)
  const { styleSetting, nameChart } = chartTabActiveData

  return {
    nameChart,
    styleSetting,
    xAxis: styleSetting?.xAxis,
    xAxisLegend: styleSetting?.xAxisLegend,
    yAxisPosition: styleSetting?.yAxisPosition,
    yAxisLegend: styleSetting?.yAxisLegend,
    yAxisLabel: styleSetting?.yAxisLabel,
  }
}

export default useGetDataStyle
