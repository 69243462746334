import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import { selectExchangeCode } from '../slice'
import { SectorReportDownload } from './SectorReportDownload'
import { FILTER, RADIO_FILTER } from './constants'
import {
  changeTabFilter,
  clearData,
  selectDataNews,
  selectDataResearch,
  selectFilter,
  selectLoadingNews,
  selectLoadingResearch,
  selectTotalPageNews,
  selectTotalPageResearch,
} from './store/slice'
import { getListNews, getListReport } from './store/thunk'
import style from './style.module.css'

const NewsAndEvents = ({ width, height }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const filter = useSelector(selectFilter)
  const isLoadingNews = useSelector(selectLoadingNews)
  const isLoadingResearch = useSelector(selectLoadingResearch)
  const dataNews = useSelector(selectDataNews)
  const dataResearch = useSelector(selectDataResearch)

  const totalPageNews = useSelector(selectTotalPageNews)
  const totalPageResearch = useSelector(selectTotalPageResearch)
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)
  const navigate = useCustomNavigate()

  const [rowTimesNews, setRowTimesNews] = useState(1)
  const [rowTimesResearch, setRowTimesResearch] = useState(1)

  const scrollToBottomCallback = () => {
    if (rowTimesNews < totalPageNews) {
      setRowTimesNews(rowTimesNews + 1)
    }
    if (rowTimesResearch < totalPageResearch) {
      setRowTimesResearch(rowTimesResearch + 1)
    }
  }

  const handleChangeTabFilter = (data) => {
    dispatch(changeTabFilter(data))
  }

  const mapValueFilter = {
    [FILTER.NEWS]: {
      data: dataNews,
      isLoading: isLoadingNews,
      rowTimes: rowTimesNews,
    },
    [FILTER.RESEARCH]: {
      data: dataResearch,
      isLoading: isLoadingResearch,
      rowTimes: rowTimesResearch,
    },
  }

  const onClick = (newsId) => () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-detail'
      ].route,
      sectorInfo,
      '?newsId=' + newsId,
    )
  }

  useEffect(() => {
    setRowTimesNews(1)
    setRowTimesResearch(1)
    dispatch(clearData())
  }, [filter, locale, exchangeCode, sectorInfo.icbId])

  useEffect(() => {
    if (sectorInfo.icbId) {
      const payload = {
        ICBId: sectorInfo.icbId,
        ExchangeCode: exchangeCode,
        Page: mapValueFilter[filter].rowTimes,
        PageSize: 100,
      }
      if (filter === FILTER.NEWS) {
        dispatch(getListNews(payload))
      } else {
        dispatch(getListReport(payload))
      }
    }
  }, [
    sectorInfo.icbId,
    exchangeCode,
    locale,
    filter,
    mapValueFilter[filter].rowTimes,
  ])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <ul className="list-check d-flex mb-8">
                {RADIO_FILTER.map((item) => {
                  return (
                    <li key={item.key} className="mb-0">
                      <label className="fs-12">
                        <input
                          type="radio"
                          className="radiobox radiobox2"
                          name="tableType"
                          value={item.value}
                          checked={item.value === filter}
                          onChange={() => handleChangeTabFilter(item.value)}
                        />
                        <Span
                          style={{
                            color: item.value === filter ? '#2f9aee' : '',
                            fontSize: 12,
                          }}
                        >
                          <Translate
                            value={`sector.sectorConstituents.overview.${item.key}`}
                          />
                        </Span>
                      </label>
                    </li>
                  )
                })}
              </ul>
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {mapValueFilter[filter].isLoading ? (
                    <Loading />
                  ) : (
                    <ScrollComponent
                      scrollToBottomCallback={scrollToBottomCallback}
                    >
                      {mapValueFilter[filter].data.length ? (
                        <table key={width} className="w-100">
                          <tbody>
                            {filter === FILTER.NEWS
                              ? mapValueFilter[filter].data.map(
                                  (item, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        style={{ borderBottom: 'unset' }}
                                        onClick={onClick(item.newsId)}
                                      >
                                        <td className={style.publicDate}>
                                          <DateTimeSpan
                                            date={item.publicDate}
                                          />
                                        </td>
                                        <td className={style.title}>
                                          <TextEllipsis
                                            isI18n={false}
                                            text={item.title}
                                          />
                                        </td>
                                        <td className={style.source}>
                                          <TextEllipsis
                                            isI18n={false}
                                            text={item.source}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  },
                                )
                              : mapValueFilter[filter].data.map(
                                  (item, index) => {
                                    return (
                                      <tr
                                        key={index}
                                        style={{
                                          borderBottom: 'unset',
                                          width: '100%',
                                        }}
                                      >
                                        <td className={style.publicDate}>
                                          <DateTimeSpan
                                            date={item.publicDateId}
                                          />
                                        </td>
                                        <td
                                          className={
                                            style.organizationShortName
                                          }
                                        >
                                          <TextEllipsis
                                            isI18n={false}
                                            text={item.organizationShortName}
                                          />
                                        </td>
                                        <td className={style.titleResearch}>
                                          <TextEllipsis
                                            isI18n={false}
                                            text={item.title}
                                          />
                                        </td>
                                        <td className={style.download}>
                                          <SectorReportDownload
                                            data={item.reportURL}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  },
                                )}
                          </tbody>
                        </table>
                      ) : (
                        <NoData />
                      )}
                    </ScrollComponent>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default NewsAndEvents
