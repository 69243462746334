import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class PriceBoardProxy extends ProxyBase {
  getPriceBoardMatched(params) {
    return this.post('PriceBoardMatchValue', params)
  }
  getPriceBoardDeal(params) {
    return this.post('PriceBoardDealValue', params)
  }
  updateBookmark(params) {
    return this.post('updateBookmark', params)
  }
}

export default new PriceBoardProxy(
  ServiceProxyConfig.Bond.PriceBoard.ServiceUrl,
)
