import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class MarginLoansProxy extends ProxyBase {
  getAverageTradingValue(params) {
    return this.get('MarginAverageTradingValue', params)
  }

  getMarginLeverageRatio(params) {
    return this.get('MarginLeverageRatio', params)
  }

  getMarginLoans(params) {
    return this.get('MarginLoans', params)
  }

  getMaxDate(params) {
    return this.get('MaxDate', params)
  }

  getMarginGrowth(params) {
    return this.post('MarginGrowth', params)
  }

  getTopCompany(params) {
    return this.get('TopCompany', params)
  }

  downloadMarginGrowth(params) {
    return this.post('DownloadMarginGrowth', params, {}, 'download')
  }
}

export default new MarginLoansProxy(
  ServiceProxyConfig.Market.MarginLoans.ServiceUrl,
)
