import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { createBondData } from '../../../store/thunk'
import PopupChangeColumn from '../../Popup/PopupChangeColumn'
import PopupCreateBond from '../../Popup/PopupCreateBond'
import PopupSaveTemplate from '../../Popup/PopupSaveTemplate'
import { POPUP_TYPES } from '../../constants'

export const FilterPopups = ({
  templateList,
  error,
  isShow,
  setError,
  setIsShow,
  handleSubmit,
  handleSubmitPopupChangeColumn,
}) => {
  const dispatch = useDispatch()

  const handleClosePopupChangeColumn = () => {
    setIsShow(null)
  }

  const handleCreateBond = (params) => {
    dispatch(createBondData(params))
  }

  return (
    <>
      {isShow === POPUP_TYPES.CREATE_BOND && (
        <PopupCreateBond
          handleClose={() => setIsShow(null)}
          handleSubmit={handleCreateBond}
        />
      )}
      {isShow === POPUP_TYPES.SAVE_TEMPLATE && (
        <PopupSaveTemplate
          handleClose={() => setIsShow(null)}
          handleSubmit={handleSubmit}
          templates={templateList}
          isShow={!!error.length}
          error={error}
          setError={setError}
        />
      )}
      {isShow === POPUP_TYPES.SETTING_COLUMN && (
        <PopupChangeColumn
          handleClose={handleClosePopupChangeColumn}
          handleSubmit={handleSubmitPopupChangeColumn}
        />
      )}
      <PopupAlert
        message={<Translate value={error} />}
        isShow={false}
        handleClose={() => setError('')}
      />
    </>
  )
}
