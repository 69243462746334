import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../core/services/corporate/OverviewService'

export const getBasicInfoThunk = createAsyncThunk(
  'corporate/overview/GET_BASIC_INFO',
  async (data, { rejectWithValue }) => {
    const response = await OverviewService.getBasicInfo(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue()
  },
)
