import PropTypes from 'prop-types'

const DOT_WIDTH_PLUS = 50

export const Rhombus = ({ color, dotWidth, ...props }) => {
  const { cx, cy, value } = props
  const dotWidthSize = dotWidth * 10 + DOT_WIDTH_PLUS
  const points = `${dotWidthSize / 2},${0} ${dotWidthSize},${
    dotWidthSize / 2
  } ${dotWidthSize / 2},${dotWidthSize} ${0},${dotWidthSize / 2}`

  if (typeof value === 'number' && !isNaN(value))
    return (
      <svg
        width={dotWidthSize}
        height={dotWidthSize}
        x={cx - dotWidthSize / 200 - dotWidth}
        y={cy - dotWidthSize / 200 - dotWidth}
        fill={color}
        viewBox="0 0 1024 1024"
      >
        <polygon points={points} stroke="#000000" strokeWidth={0} />
      </svg>
    )

  return null
}

Rhombus.propTypes = {
  color: PropTypes.string,
  dotWidth: PropTypes.number,
}

Rhombus.defaultProps = {
  color: '#000000',
  dotWidth: 6.5,
}
