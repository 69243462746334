import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Span } from '../../html/Span'
import TextEllipsis from '../../textEllipsis'
import { TextEllipsisStaticLine } from '../../textEllipsis/TextEllipsisStaticLine'

import { getFontSize } from '../../../utils/FontSize'
import { getTextWidth } from '../helper'

export const TickItem = ({
  text,
  maxWidth,
  isHide,
  isBreakNewLine,
  showAllTick,
  showAllTickInTwoLines,
}) => {
  const textRef = useRef()

  const [height, setHeight] = useState(0)

  const fontSize = getFontSize(12)
  const textWidth = getTextWidth(text, fontSize)

  useEffect(() => {
    const textHeight = textRef.current?.clientHeight
    if (textHeight) {
      setHeight(textHeight)
    }
  }, [text, maxWidth, isHide, isBreakNewLine])

  return (
    <div
      style={{
        height,
      }}
      // className={style.item}
    >
      {!isHide ? (
        <div
          ref={textRef}
          style={{
            width: maxWidth,
            padding: showAllTick ? '0 2px' : 'unset',
          }}
          className="d-flex ali-center justify-content-center"
          // className={style.itemText}
        >
          <div style={{ width: textWidth > maxWidth ? maxWidth : textWidth }}>
            {showAllTickInTwoLines ? (
              <TextEllipsisStaticLine isI18n={false} val={text} lineClamp={2} />
            ) : isBreakNewLine ? (
              <div style={{ maxWidth: 'min-content', margin: '0 auto' }}>
                <Span
                  className="canvas-chart-title"
                  style={{
                    fontSize: 12,
                    whiteSpace: 'pre',
                  }}
                >
                  {text}
                </Span>
              </div>
            ) : (
              <TextEllipsis
                isI18n={false}
                text={text}
                className="canvas-chart-title"
              />
            )}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

TickItem.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  isHide: PropTypes.bool.isRequired,
  isBreakNewLine: PropTypes.bool,
  showAllTick: PropTypes.bool,
  showAllTickInTwoLines: PropTypes.bool,
}
