import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
} from '../../utils/Datetime'
import { TIME_RANGES } from '../tabs/DispatchActionTab'
import { calendarTabKey } from '../tabs/DispatchActionTabWithCalendar'
import UseTimeZone from './useTimeZone'

export const useCurrentDateTime = (format) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  return getCurrentDateTime(format, locale, timeZone)
}

export const useFormatDateTime = (str, format) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  return formatDateTime(str, format, locale, timeZone)
}

export const useFormatDateTimeByTimeRange = () => {
  const { getTimeRangeCheckValid } = useCheckTimeRangeValid()

  const locale = useSelector((state) => state.i18n.locale)

  const formatDateTimeByTimeRange = ({
    date,
    timeRange,
    startDate,
    endDate,
  }) => {
    const {
      isLessThanThreeMonth,
      isLessThanSixMonth,
      isLessThanOneYear,
      isLessThanThreeYear,
      isLessThanFiveYear,
      isMoreThanFiveYear,
    } = getTimeRangeCheckValid({ timeRange, startDate, endDate })

    switch (true) {
      case isLessThanThreeMonth:
        return formatDateTime(moment(date), FORMAT.DATE, locale)
      case isLessThanSixMonth:
        return formatDateTime(
          moment(date).endOf('week').subtract(2, 'day').format('MM-DD-YYYY'),
          FORMAT.WEEK_YEAR_W,
          locale,
        )
      case isLessThanOneYear:
      case isLessThanThreeYear:
        return (
          formatDateTime(date, FORMAT.MONTH_YEAR_NUMBER, locale) +
          `${
            moment().format('MM-YYYY') === moment(date).format('MM-YYYY')
              ? ' (MTD)'
              : ''
          }`
        )
      case isLessThanFiveYear:
        return (
          formatDateTime(date, FORMAT.QUARTER_YEAR, locale) +
          `${
            moment().format('Q-YYYY') === moment(date).format('Q-YYYY')
              ? ' (QTD)'
              : ''
          }`
        )
      case isMoreThanFiveYear:
        return (
          formatDateTime(date, FORMAT.YEAR, locale) +
          `${
            moment().format('YYYY') === moment(date).format('YYYY')
              ? ' (YTD)'
              : ''
          }`
        )
      default:
        return formatDateTime(moment(date), FORMAT.DATE, locale)
    }
  }

  return { formatDateTimeByTimeRange }
}

export const useCheckTimeRangeValid = () => {
  const getTimeRangeCheckValid = ({ timeRange, startDate, endDate }) => {
    try {
      const isLessThanThreeMonth =
        timeRange === TIME_RANGES.ThreeMonths ||
        (timeRange === TIME_RANGES.YearToDate &&
          moment().unix() <= moment().startOf('year').add(3, 'month').unix()) ||
        (timeRange === calendarTabKey &&
          moment(startDate).add(3, 'month').unix() >= moment(endDate).unix())
      const isLessThanSixMonth =
        timeRange === TIME_RANGES.SixMonths ||
        (!isLessThanThreeMonth &&
          timeRange === TIME_RANGES.YearToDate &&
          moment().unix() <= moment().startOf('year').add(6, 'month').unix()) ||
        (timeRange === calendarTabKey &&
          !isLessThanThreeMonth &&
          moment(startDate).add(6, 'month').unix() >= moment(endDate).unix())
      const isLessThanOneYear =
        timeRange === TIME_RANGES.OneYear ||
        (!isLessThanSixMonth &&
          timeRange === TIME_RANGES.YearToDate &&
          moment().unix() <= moment().startOf('year').add(1, 'year').unix()) ||
        (timeRange === calendarTabKey &&
          !isLessThanSixMonth &&
          moment(startDate).add(1, 'year').unix() >= moment(endDate).unix())
      const isLessThanThreeYear =
        timeRange === TIME_RANGES.ThreeYears ||
        (timeRange === calendarTabKey &&
          !isLessThanOneYear &&
          moment(startDate).add(3, 'year').unix() >= moment(endDate).unix())
      const isLessThanFiveYear =
        timeRange === TIME_RANGES.FiveYears ||
        (timeRange === calendarTabKey &&
          !isLessThanThreeYear &&
          moment(startDate).add(5, 'year').unix() >= moment(endDate).unix())
      const isMoreThanFiveYear =
        timeRange === calendarTabKey &&
        !isLessThanFiveYear &&
        moment(startDate).add(5, 'year').unix() < moment(endDate).unix()

      return {
        isLessThanThreeMonth,
        isLessThanSixMonth,
        isLessThanOneYear,
        isLessThanThreeYear,
        isLessThanFiveYear,
        isMoreThanFiveYear,
      }
    } catch (error) {
      return {}
    }
  }

  return { getTimeRangeCheckValid }
}
