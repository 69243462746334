import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class WatchListProxy extends ProxyBase {
  getWatchlist(params) {
    return this.get('MyWatchlist', params, '', true)
  }
  updateMasterWatchlist(data) {
    return this.post('UpdateWatchlist', data)
  }

  copyWatchlist(data) {
    return this.post('BondWatchlistCopy', data)
  }

  deleteWatchlist(data) {
    return this.post('DeleteWatchlist', data)
  }

  getWatchlistDetail(params) {
    return this.get('WatchlistDetail', params)
  }

  getTickerByExchange(params) {
    return this.get('BondsByFilterRequest', params)
  }

  getTickerByICB(params) {
    return this.get('GetOrganizationsByIcb', params)
  }

  updateWatchlist(data) {
    return this.post('UpdateWatchlistDetails', data)
  }

  createWatchlist(data) {
    return this.post('InsertWatchlist', data)
  }

  updateFavorite(data) {
    return this.post('UpdateFavourite', data)
  }

  FilerMyWatchListProxy(data) {
    return this.get('FilerMyWatchList', data)
  }
}

export default new WatchListProxy(ServiceProxyConfig.WatchListBond.ServiceUrl)
