export const SIMPLE_SEARCH_PARAMS = {
  page: 1,
  pageSize: 25,
}

export const SEARCH_ITEM_TYPE = {
  INDEX: 'INDEX',
  COMPANY: 'COMPANY',
}

export const DEFAULT_SEARCH_INFO = {
  type: SEARCH_ITEM_TYPE.INDEX,
  groupId: 11,
  groupName: 'VNINDEX',
  groupCode: 'VNINDEX',
  exchangeCode: 'HOSE',
}
