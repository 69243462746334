import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../common/textEllipsis'
import { CountriesSearch } from '../CountriesSearch'
import { changeTypeDataForTurnOverChart } from '../exportTurnOver/store/slice'
import style from '../index.module.css'
import { CATEGORY_FILTER, LIST_RADIO } from './constants'
import {
  changeActiveItemTable,
  changeCurrentDataCategory,
  changeCurrentMinMonth,
  changeCurrentMinYear,
  changeKeySearch,
  changeTypeData,
  handleIsFirstTime,
  selectCurrentDataCategory,
  selectTypeData,
  setIsKeepRowsCollapse,
  setLimitQuarter,
  setUnCollapseIds,
} from './store/slice'

export const FilterList = ({ width, limitQuarter }) => {
  const dispatch = useDispatch()

  const leftHeaderRef = useRef()
  const radioBoxRef = useRef()

  const currentTypeData = useSelector(selectTypeData)
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)

  const handleChangeCategory = (item) => {
    dispatch(changeCurrentDataCategory(item.value))
    dispatch(changeCurrentMinMonth(null))
    dispatch(changeCurrentMinYear(null))
    dispatch(changeActiveItemTable(null))
    dispatch(handleIsFirstTime(true))
    dispatch(setUnCollapseIds([]))
    dispatch(setLimitQuarter(0))
    dispatch(changeKeySearch(''))
    dispatch(setIsKeepRowsCollapse(false))
  }

  const handleChangeTypeData = (item) => {
    dispatch(handleIsFirstTime(true))
    dispatch(changeTypeData(item.value))
    dispatch(changeTypeDataForTurnOverChart(item.value))
    dispatch(setIsKeepRowsCollapse(true))
    dispatch(setLimitQuarter(0))
    dispatch(changeKeySearch(''))
  }

  return (
    <div style={{ width: width }} className="d-flex j-b">
      <div className="d-flex" ref={leftHeaderRef}>
        <div style={{ marginRight: '5px', marginTop: '3px' }}>
          <DispatchActionTab
            data={CATEGORY_FILTER}
            activeTab={currentCategoryFilter}
            onChangeTab={handleChangeCategory}
          />
        </div>
        <div style={{ marginTop: '3px', width: '200px' }}>
          <CountriesSearch isInTable limitQuarter={limitQuarter} />
        </div>
      </div>
      <div className="d-flex">
        <ul className={`list-check d-flex mt-0`} ref={radioBoxRef}>
          {LIST_RADIO.map((item) => (
            <li key={item.value} className="mb-0">
              <label>
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={item.value === currentTypeData}
                  onChange={() => handleChangeTypeData(item)}
                />
                <Span
                  style={{ fontSize: 12 }}
                  className={item.value === currentTypeData ? style.active : ''}
                >
                  <TextEllipsis text={item.title} />
                </Span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
