export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'Listed',
  HOSE: 'VNIndex',
  HNX: 'HNXIndex',
  UPCOM: 'UPCOMIndex',
}

export const ICB_LEVEL = [
  { value: 1, name: 'sector.sectorConstituents.valuation.SECTOR_LEVEL_1' },
  { value: 2, name: 'sector.sectorConstituents.valuation.SECTOR_LEVEL_2' },
  { value: 3, name: 'sector.sectorConstituents.valuation.SECTOR_LEVEL_3' },
  { value: 4, name: 'sector.sectorConstituents.valuation.SECTOR_LEVEL_4' },
  { value: 5, name: 'sector.sectorConstituents.valuation.SECTOR_LEVEL_5' },
]

export const MAX_ITEM = 20
