import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN_RECHARTS } from '../../../../../common/chart/constants'
import { StackedBarLineChart } from '../../../../../common/chart/customCharts/StackedBarLineChart'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import {
  COLOR_STRUCTURE_CHART,
  COMPONENTS_VALUE_BREAKDOWN,
  FOOTER_NAME_BARKEYS,
} from './constants'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 85 }

export const ChartComponent = ({
  width,
  height,
  currentComponentFilter,
  dataChart,
  isLoading,
  filterData,
  getNetTradingValueChartTicker,
  getNetTradingValueChartOverview,
}) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)

  const getBarKeysByCurrentComponent = () => {
    let barKeys = []
    switch (currentComponentFilter) {
      case COMPONENTS_VALUE_BREAKDOWN.LOCAL_INDIVIDUAL:
        barKeys = [
          'localIndividualBuyMatchValue',
          'localIndividualSellMatchValue',
        ]
        break
      case COMPONENTS_VALUE_BREAKDOWN.FOREIGN:
        barKeys = ['foreignIndividualBuyValue', 'foreignIndividualSellValue']
        break
      case COMPONENTS_VALUE_BREAKDOWN.LOCAL_INSTITUTION:
        barKeys = [
          'localInstitutionalBuyMatchValue',
          'localInstitutionalSellMatchValue',
        ]
        break
      case COMPONENTS_VALUE_BREAKDOWN.PROPRIETARY:
        barKeys = ['totalMatchBuyValue', 'totalMatchSellValue']
        break
      default:
        barKeys = [
          'localIndividualBuyMatchValue',
          'localIndividualSellMatchValue',
        ]
        break
    }
    return barKeys
  }

  const getLineKeysByCurrentComponent = () => {
    let lineKeys = []
    switch (currentComponentFilter) {
      case COMPONENTS_VALUE_BREAKDOWN.LOCAL_INDIVIDUAL:
        lineKeys = ['localIndividualNetValue']
        break
      case COMPONENTS_VALUE_BREAKDOWN.FOREIGN:
        lineKeys = ['foreignIndividualNetValue']
        break
      case COMPONENTS_VALUE_BREAKDOWN.LOCAL_INSTITUTION:
        lineKeys = ['localInstitutionNetValue']
        break
      case COMPONENTS_VALUE_BREAKDOWN.PROPRIETARY:
        lineKeys = ['prorprietaryNetValue']
        break
      default:
        lineKeys = ['localIndividualNetValue']
    }
    return lineKeys
  }

  const allKeys = [
    ...getBarKeysByCurrentComponent(),
    ...getLineKeysByCurrentComponent(),
  ]

  const fillColor = {}
  const mappingDisplayName = {}

  for (const key of allKeys) {
    fillColor[key] = COLOR_STRUCTURE_CHART[allKeys?.indexOf(key)]
    mappingDisplayName[key] = I18n.t(FOOTER_NAME_BARKEYS[allKeys?.indexOf(key)])
  }

  useEffect(() => {
    if (filterData?.currentCWId) {
      dispatch(
        getNetTradingValueChartTicker({
          CoveredWarrantId: filterData?.currentCWId,
        }),
      )
    } else {
      dispatch(getNetTradingValueChartOverview({}))
    }
  }, [locale, currentComponentFilter, filterData?.currentCWId])

  return isLoading ? (
    <Loading />
  ) : !dataChart.length ? (
    <NoData />
  ) : (
    <StackedBarLineChart
      data={dataChart}
      width={width}
      height={height}
      keyXAxis={'time'}
      barKeys={getBarKeysByCurrentComponent()}
      lineKeys={getLineKeysByCurrentComponent()}
      decimalLength={2}
      leftLabel={`${I18n.t('market.coveredWarrant.cwStatistics.VAL_BILLION')}`}
      rightLabel={`${I18n.t('market.coveredWarrant.cwStatistics.VAL_BILLION')}`}
      fillColor={fillColor}
      mappingDisplayName={mappingDisplayName}
      margin={MARGIN}
      timeFrame={TIME_RANGES['1W_DATE']}
      isFooterResize
      unit={I18n.t('market.coveredWarrant.cwStatistics.BILLION')}
      lineUnit={I18n.t('market.coveredWarrant.cwStatistics.BILLION')}
      listCheckbox={allKeys}
      valueKeysColor={allKeys}
    />
  )
}
