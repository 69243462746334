import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import { typeIndexSector } from '../constant'
import { decodeId } from '../helper'
import { selectIndexById, selectSectorById } from '../store/slice'

const CellInfo = ({ id }) => {
  const indexById = useSelector(selectIndexById)
  const sectorById = useSelector(selectSectorById)

  const { type, id: indexSectorId } = decodeId(id)
  const name =
    type === typeIndexSector.INDEX
      ? indexById[indexSectorId].groupName
      : sectorById[indexSectorId].icbName +
        ' L' +
        sectorById[indexSectorId].icbLevel

  return (
    <div>
      <TextEllipsis isI18n={false} text={name} />
    </div>
  )
}

export default CellInfo
