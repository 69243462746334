import FDIByCountriesProxy from '../../../proxies/economy/fdi/FDIByCountriesProxy'
import { ServiceBase } from '../../ServiceBase'

export class FDIByCountriesService extends ServiceBase {
  getTotalRegisterFDICapital(params) {
    return this.getData(() =>
      FDIByCountriesProxy.getTotalRegisterFDICapital(params),
    )
  }

  downloadTotalRegisterCapital(params) {
    return this.getFileDownload(() =>
      FDIByCountriesProxy.downloadTotalRegisterCapital(params),
    )
  }

  getLocationName(params) {
    return this.getData(() => FDIByCountriesProxy.getLocationName(params))
  }

  getFDIStructureByCountry(params) {
    return this.getData(() =>
      FDIByCountriesProxy.getFDIStructureByCountry(params),
    )
  }

  getCompositionOfFDIInflowsByCountryPieChart(params) {
    return this.getData(() =>
      FDIByCountriesProxy.getCompositionOfFDIInflowsByCountryPieChart(params),
    )
  }

  getCompositionOfFDIInflowsByCountryColumnChart(params) {
    return this.getData(() =>
      FDIByCountriesProxy.getCompositionOfFDIInflowsByCountryColumnChart(
        params,
      ),
    )
  }
}

export default new FDIByCountriesService()
