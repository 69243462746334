import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import SelectCustom from '../../../common/selectCustom'
import TextEllipsis from '../../../common/textEllipsis'
import { COM_GROUP, ICB_ID } from '../../../constants/Group'
import styles from '../index.module.css'
import { EXCHANGE_CODE } from './constants'
import {
  changeCurrentCompaniesIds,
  changeListOrganizationsPopup,
  selectAllTickers,
  selectFilteredListOrganizationsPopup,
  selectIds,
  selectListOrganizationsLoading,
  selectListOrganizationsPopup,
} from './store/slice'
import { getOrganizationsListPopup } from './store/thunk'

export const OrganizationTickers = ({ handleClosePopup, keyword }) => {
  const modalRef = useRef()
  const topFilterRef = useRef()

  const dispatch = useDispatch()

  const listOrganizations = useSelector(selectListOrganizationsPopup)
  const filteredListOrganizations = useSelector(
    selectFilteredListOrganizationsPopup,
  )
  const loadingList = useSelector(selectListOrganizationsLoading)
  const currentIds = useSelector(selectIds)

  const [exchangeFilter, setExchangeFilter] = useState(
    EXCHANGE_CODE.ALL_EXCHANGE,
  )
  const [selectedIds, setSelectedIds] = useState([])

  const handleChangeExchange = (val) => {
    setExchangeFilter(val)
    if (val === EXCHANGE_CODE.ALL_EXCHANGE) {
      dispatch(changeListOrganizationsPopup(listOrganizations))
    } else {
      dispatch(
        changeListOrganizationsPopup(
          listOrganizations.filter((item) => item.exchangeCode === val),
        ),
      )
    }
  }

  const handleCancel = () => {
    handleClosePopup()
  }

  const handleApply = () => {
    if (
      JSON.stringify([...selectedIds].sort()) !==
      JSON.stringify([...currentIds].sort())
    ) {
      dispatch(changeCurrentCompaniesIds(selectedIds))
    }

    handleClosePopup()
  }

  const listOrganizationsHeight =
    modalRef.current?.getBoundingClientRect().height -
    topFilterRef.current?.getBoundingClientRect().height

  const handleCheckboxChange = (value) => {
    const orgId = +value.organizationId
    const condition = selectedIds.some((item) => item === orgId)
    if (!condition) {
      setSelectedIds((prev) => [...prev, orgId])
    } else {
      setSelectedIds((prev) => prev.filter((ticker) => ticker !== orgId))
    }
  }

  const onCheckAllChange = (e) => {
    const filteredOrgIds = listOrganizations.map(
      (ticker) => ticker.organizationId,
    )
    if (e.target.checked) {
      const selectedIdsAdded = filteredOrgIds.filter(
        (id) => !selectedIds.includes(id),
      )
      setSelectedIds([...selectedIds, ...selectedIdsAdded])
    } else {
      setSelectedIds(selectedIds.filter((id) => !filteredOrgIds.includes(id)))
    }
  }

  const isCheckedAll = listOrganizations.every((ticker) =>
    selectedIds.includes(ticker.organizationId),
  )

  useEffect(() => {
    const selectedIds = [...currentIds]
    setSelectedIds(selectedIds)
  }, [currentIds])

  useEffect(() => {
    if (isCheckedAll) {
      dispatch(selectAllTickers(true))
    } else {
      dispatch(selectAllTickers(false))
    }
  }, [selectedIds])

  useEffect(() => {
    dispatch(
      changeListOrganizationsPopup(
        listOrganizations?.filter((item) =>
          keyword
            ? item.tickerOrTaxCode
                ?.toLowerCase()
                .includes(keyword.toLowerCase()) ||
              item.exchangeCode?.toString().includes(keyword.toLowerCase()) ||
              item.organizationShortName
                ?.toLowerCase()
                .includes(keyword.toLowerCase())
            : true,
        ),
      ),
    )
  }, [keyword])

  useEffect(() => {
    dispatch(
      getOrganizationsListPopup({ ICBId: ICB_ID[COM_GROUP.SECURITIES][0] }),
    )
  }, [])

  return (
    <>
      <div className={styles.modalContent} ref={modalRef}>
        <div className={styles.topFilter} ref={topFilterRef}>
          <div className={styles.filterExchange}>
            <SelectCustom
              value={exchangeFilter}
              isI18n={true}
              selectData={Object.keys(EXCHANGE_CODE).map((key) => ({
                name: EXCHANGE_CODE[key],
                value: EXCHANGE_CODE[key],
              }))}
              handleChange={handleChangeExchange}
            />
          </div>
          <div className={styles.checkBox}>
            <label>
              <input
                type="checkbox"
                className={`checkbox line`}
                checked={isCheckedAll}
                onChange={onCheckAllChange}
              />
            </label>
          </div>
        </div>
        <div
          style={{
            height: listOrganizationsHeight,
          }}
        >
          {listOrganizationsHeight &&
            (loadingList ? (
              <Loading />
            ) : !filteredListOrganizations.length ? (
              <NoData />
            ) : (
              <ScrollComponent>
                {filteredListOrganizations.map((item) => {
                  return (
                    <div
                      key={item.organizationId}
                      className={styles.itemTicker}
                      onClick={() => handleCheckboxChange(item)}
                    >
                      <div className={styles.tickerInfo}>
                        <div className={styles.tickerCode}>
                          <TextEllipsis
                            isI18n={false}
                            text={item.tickerOrTaxCode}
                          />
                        </div>
                        <div className={styles.orgName}>
                          <TextEllipsis
                            isI18n={false}
                            text={item.organizationShortName}
                          />
                        </div>
                        <div className={styles.exchange}>
                          <TextEllipsis
                            isI18n={false}
                            text={item.exchangeCode}
                          />
                        </div>
                      </div>
                      <div className={styles.checkBox}>
                        <label>
                          <input
                            type="checkbox"
                            className="checkbox line"
                            checked={selectedIds.includes(item.organizationId)}
                            value={item.organizationId}
                            readOnly
                          />
                        </label>
                      </div>
                    </div>
                  )
                })}
              </ScrollComponent>
            ))}
        </div>
      </div>
      <div className={styles.groupButtonModal}>
        <Button className="btn bg-grey-1 w-80 h-20" onClick={handleCancel}>
          <Translate value={'common.button.BUTTON_CANCEL'} />
        </Button>
        <Button onClick={handleApply} className={`btn btn-blue w-80 h-20`}>
          <Translate value={'common.button.BUTTON_APPLY'} />
        </Button>
      </div>
    </>
  )
}
