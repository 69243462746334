import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DerivativeService from '../../../../../../core/services/market/DerivativeService'
import { handleExport } from '../../../../../utils/Export'

export const getOrderStatisticsOverview = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/getOrderStatisticsOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getOrderStatisticsOverview(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrderStatisticsDetail = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/getOrderStatisticsDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getOrderStatisticsDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrderStatisticsTicker = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/getOrderStatisticsTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getOrderStatisticsTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadOrderedStatisticsOverview = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/downloadOrderedStatisticsOverview',
  async (data) => {
    const response = await DerivativeService.downloadOrderStatisticsOverview(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadOrderedStatisticsDetail = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/downloadOrderedStatisticsDetail',
  async (data) => {
    const response = await DerivativeService.downloadStatisticsDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadOrderedStatisticsTicker = createAsyncThunk(
  'market/derivativeStatistics/orderedStatistics/downloadOrderedStatisticsTicker',
  async (data) => {
    const response = await DerivativeService.downloadStatisticsTicker(data)
    handleExport(response.data, response.filename)
  },
)
