import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { handleItemClickedData } from '../helper'
import { getNewsData, selectLoadingNews } from '../store/slice'
import ItemClicked from './ItemClicked'

const ListEvent = ({ isLoadingUpcomingEvent, eventTypeLoading }) => {
  const newsData = useSelector(getNewsData)
  const eventClicked = handleItemClickedData(newsData)
  const isLoadingNews = useSelector(selectLoadingNews)

  if (isLoadingNews || isLoadingUpcomingEvent || eventTypeLoading) {
    return <Loading />
  }

  if (!eventClicked.length) {
    return <NoData />
  }

  return (
    <>
      {eventClicked.map((item) => (
        <ItemClicked
          key={`${item.id}-${item.event}`}
          day={item.day}
          event={item.event}
          listTitle={item.listTitle}
          id={item.id}
          newsId={item.newsId}
        />
      ))}
    </>
  )
}

ListEvent.propTypes = {
  isLoadingUpcomingEvent: PropTypes.bool,
  eventTypeLoading: PropTypes.bool,
}

export default ListEvent
