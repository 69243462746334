import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Setting } from '.'
import { positionDropdown } from '../../../common/dropdown'
import { ReactComponent as IconDuplicate } from '../../icon/icon-duplicate-black.svg'
import iconFavorite from '../../icon/icon-favorite.svg'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { ReactComponent as IconReplace } from '../../icon/icon-replace.svg'
import PopupCopyToNewDashboardGroup from '../../popup/PopupCopyToNewDashboardGroup'
import PopupDeleteDashboard from '../../popup/PopupDeleteDashboard'
import PopupDuplicateDashboard from '../../popup/PopupDuplicateDashboard'
import { selectChartByDashboardId, selectMyDashboard } from '../../store/slice'
import { updateAndReloadDashboard } from '../../store/thunk'
import { getDashboardDuplicateName } from '../helpers'
import { SCROLL_ID } from '../menuDashboard'
import { useGetUserSettings } from '../useGetUserSettings'

export const DashboardSetting = ({
  data,
  isHover,
  setIsHover,
  setIsEdit,
  setIsActive,
}) => {
  const dispatch = useDispatch()

  const { defaultDashboard, addDefaultDashboard, removeDefaultDashboard } =
    useGetUserSettings()

  const chartByDashboardId = useSelector(selectChartByDashboardId)
  const myDashboard = useSelector(selectMyDashboard)

  const [isShowSetting, setIsShowSetting] = useState(false)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)
  const [isShowPopupDuplicate, setIsShowPopupDuplicate] = useState(false)
  const [isShowPopupMoveToGroupDB, setShowPopupMoveToGroupDB] = useState(false)

  const isDashboardDefault = data.id === defaultDashboard.id

  const getName = useMemo(() => {
    return getDashboardDuplicateName(myDashboard, data)
  }, [myDashboard, data])

  const showPopupDelete = () => {
    setIsShowSetting(false)
    setIsActive(false)
    setIsHover(false)
    setIsShowPopupDelete(true)
  }

  const renameAction = () => {
    setIsShowSetting(false)
    setIsActive(true)
    setIsHover(false)
    setIsEdit(true)
  }

  const changeDefaultDashboardAction = () => {
    if (isDashboardDefault) {
      removeDefaultDashboard()
    } else {
      addDefaultDashboard(data.id)
    }
  }

  const showPopupDuplicateDashboard = () => {
    setIsShowPopupDuplicate(true)
  }

  const handleMoveToGroupDB = (dashboard) => {
    const params = {
      ...data,
      parentId: dashboard.id,
    }
    dispatch(updateAndReloadDashboard(params))
  }

  const handleMoveToNewGroupDB = () => {
    setShowPopupMoveToGroupDB(true)
  }

  const handleMoveTo = (type, dashboard) => {
    if (type === 'MOVE_TO_GROUP_DB') {
      handleMoveToGroupDB(dashboard)
    } else {
      handleMoveToNewGroupDB()
    }
  }

  return (
    <>
      <Setting
        data={[
          {
            icon: <IconRename />,
            title: 'financialChart.RENAME',
            action: renameAction,
            isHide: data.isTemplate,
          },
          {
            icon: <IconDuplicate />,
            title: 'financialChart.DUPLICATE',
            action: showPopupDuplicateDashboard,
          },
          {
            icon: (
              <img
                alt="icon favorite"
                src={iconFavorite}
                height={12}
                width={12}
                style={{ marginRight: 2, marginLeft: 2 }}
              />
            ),
            title: isDashboardDefault
              ? 'financialChart.UNSET_AS_DEFAULT'
              : 'financialChart.SET_AS_DEFAULT',
            action: changeDefaultDashboardAction,
          },
          {
            icon: <IconReplace />,
            title: 'financialChart.MOVE_TO',
            action: handleMoveTo,
            isActionMoveTo: true,
            dashboardSetting: data,
            isHide: data.isTemplate,
          },
          {
            icon: <i className="icon-trash" />,
            title: 'financialChart.DELETE',
            action: showPopupDelete,
            isHide: data.isTemplate,
          },
        ].filter((item) => !item.isHide)}
        isShowIcon={isHover || isShowSetting}
        isShow={isShowSetting}
        setIsShow={setIsShowSetting}
        setIsActive={setIsActive}
        position={positionDropdown.BOTTOM_RIGHT_LEFT}
        scrollId={SCROLL_ID}
      />

      {isShowPopupDelete && (
        <PopupDeleteDashboard
          handleShow={setIsShowPopupDelete}
          dashboard={data}
        />
      )}
      <PopupDuplicateDashboard
        isShow={isShowPopupDuplicate}
        name={getName}
        charts={chartByDashboardId[data.id]}
        dashboard={data}
        handleShow={setIsShowPopupDuplicate}
      />
      {isShowPopupMoveToGroupDB ? (
        <PopupCopyToNewDashboardGroup
          isShow={isShowPopupMoveToGroupDB}
          handleShow={setShowPopupMoveToGroupDB}
          dashboard={data}
        />
      ) : null}
    </>
  )
}
