export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
}

export const list_career = [
  { name: 'bond.overview.primaryMarket.ALL_SECTOR', value: '' },
]

export const tableConstants = [
  {
    index: 'expectedDate',
    title: 'bond.overview.issuesPlan.table.expectedDate',
    name: 'bond.overview.issuesPlan.table.expectedDate',
    key: 'expectedDate',
    attrs: {
      style: { textAlign: 'left' },
    },
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'bondCode',
    key: 'bondCode',
    title: 'bond.overview.issuesPlan.table.bondCode',
    name: 'bond.overview.issuesPlan.table.bondCode',
    attrs: {
      style: {
        textAlign: 'left',
        minWidth: '89px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  {
    index: 'organizationShortName',
    key: 'organizationShortName',
    title: 'bond.overview.issuesPlan.table.organizationShortName',
    name: 'bond.overview.issuesPlan.table.organizationShortName',
    attrs: {
      style: {
        textAlign: 'left',
        maxWidth: '150px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  {
    index: 'issuePlanValue',
    key: 'issuePlanValue',
    title: 'bond.overview.issuesPlan.table.issuePlanValue',
    name: 'bond.overview.issuesPlan.table.issuePlanValue',
    additionText: 'bond.overview.issuesPlan.table.additionTextIssuePlanValue',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    attrs: {
      style: { textAlign: 'right' },
    },
  },
  {
    index: 'planDuration',
    key: 'planDuration',

    title: 'bond.overview.issuesPlan.table.planDuration',
    name: 'bond.overview.issuesPlan.table.planDuration',
    additionText: 'bond.overview.issuesPlan.table.additionPlanDuration',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    attrs: {
      style: { textAlign: 'right' },
    },
  },
  {
    index: 'couponInterestRate',
    key: 'couponInterestRate',
    title: 'bond.overview.issuesPlan.table.couponInterestRate',
    name: 'bond.overview.issuesPlan.table.couponInterestRate',
    additionText: 'bond.overview.issuesPlan.table.additionCouponInterestRate',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    attrs: {
      style: { textAlign: 'right' },
    },
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}
