import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal, valDivBillion, valToPercent } from '../../../utils/Value'
import styles from '../index.module.css'
import { GROUP_COLUMNS, TOTAL_ID } from './constants'
import { DeleteCol } from './DeleteCol'
import { LightBorderColumnCell } from './LightBorderColumnCell'

const BORDER_DARK = '1px solid #2b303a'
const BORDER_LIGHT = '1px solid #546373'
const DEFAULT_QUARTER_NUMBER = 5

export const getSchema = (groupColumns, listOrganizations) => {
  let result = {}

  const getOrganizationInfoById = (id) => {
    return listOrganizations?.find((item) => item.organizationId === id) || {}
  }

  const childrenGroupColumns = groupColumns
    ?.filter((item) => !GROUP_COLUMNS.includes(item))
    .map((item, index) => ({
      ...item,
      title: [0, 5].includes(index)
        ? item.title
        : `${I18n.t('market.marginLoans.COMPARE_TO')} ${item.title}`,
    }))

  const childrenGroupColumnsLength = childrenGroupColumns.length

  const schemaGroupColumns = groupColumns?.filter(
    (item) => !item.key.includes('parent'),
  )

  if (childrenGroupColumnsLength === DEFAULT_QUARTER_NUMBER * 2) {
    result.schema = schemaGroupColumns?.map((item, index) => {
      const title = item.title
      const colId = item.key
      let result = {
        colId,
        title,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
      }
      if (index === 0) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          tdStyle: {
            textAlign: 'center',
            verticalAlign: 'center',
          },
          render: (val, rowId, { style, className }) => {
            return (
              <td
                style={{
                  textAlign: 'center',
                  width: 30,
                  ...style,
                }}
                className={
                  rowId === TOTAL_ID
                    ? `${className} ${styles.borderTopLight}`
                    : className
                }
              >
                {![TOTAL_ID, 0].includes(rowId) ? (
                  <DeleteCol id={rowId} />
                ) : (
                  <></>
                )}
              </td>
            )
          },
        }
      }
      if (index === 1) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
          },
          tdStyle: {
            textAlign: 'left',
            borderRight: BORDER_LIGHT,
          },
          render: (val, rowId, { style, className }) => {
            return (
              <LightBorderColumnCell
                val={
                  rowId === 0
                    ? I18n.t('market.marginLoans.OTHER')
                    : rowId === TOTAL_ID
                    ? ''
                    : getOrganizationInfoById(rowId).tickerOrTaxCode ||
                      EMPTY_VALUE
                }
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={
                  rowId === TOTAL_ID
                    ? `${className} ${styles.borderTopLight}`
                    : className
                }
              />
            )
          },
        }
      }
      if (index === 2) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
            minWidth: 200,
          },
          tdStyle: {
            textAlign: 'left',
            borderRight: BORDER_LIGHT,
            minWidth: 200,
          },
          render: (val, rowId, { style, className }) => (
            <LightBorderColumnCell
              val={
                rowId === 0
                  ? I18n.t('market.marginLoans.OTHER')
                  : rowId === TOTAL_ID
                  ? I18n.t('market.marginLoans.SUM')
                  : getOrganizationInfoById(rowId).organizationShortName ||
                    EMPTY_VALUE
              }
              rowId={rowId}
              isAlignTextLeft
              styleProps={style}
              className={
                rowId === TOTAL_ID
                  ? `${className} ${styles.borderTopLight} ${styles.totalCell}`
                  : className
              }
              isStickyBorderRight
              isText
            />
          ),
        }
      }
      if (index === 3 || index === 8) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          render: (val, rowId, { style, className }) => {
            return (
              <td style={{ ...style }} className={className}>
                <div
                  className={rowId === TOTAL_ID ? styles.borderTopLight : ''}
                >
                  {index === 3
                    ? formatVal(valDivBillion(val))
                    : valToPercent(val)}
                </div>
              </td>
            )
          },
        }
      }
      if (index === 7) {
        result = {
          ...result,
          isI18n: true,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId, { style, className }) => (
            <LightBorderColumnCell
              val={val}
              rowId={rowId}
              styleProps={style}
              className={
                rowId === TOTAL_ID
                  ? `${className} ${styles.borderTopLight}`
                  : className
              }
              isPercentage
            />
          ),
        }
      }
      if (![0, 1, 2, 3, 7, 8].includes(index)) {
        result = {
          ...result,
          isI18n: true,
          thStyle: {
            width: 50,
          },
          tdStyle: {
            textAlign: 'right',
            width: 50,
          },
          render: (val, rowId) => {
            return (
              <div
                className={rowId === TOTAL_ID ? styles.borderTopLight : ''}
                style={{
                  color: val < 0 ? '#ff4752' : '',
                }}
              >
                {valToPercent(val)}
              </div>
            )
          },
        }
      }
      return result
    })
    result.rowSpanSchema = [
      [
        ...groupColumns
          ?.filter((item) => ['ticker', 'name', 'delete'].includes(item.key))
          .map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
                className={styles.nameCell}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: item.key !== 'delete' && BORDER_LIGHT,
            },
          })),
        ...groupColumns
          ?.filter((item) =>
            ['parentMargin', 'parentMarEquity'].includes(item.key),
          )
          .map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            colSpan: 5,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: item.key === 'parentMargin' && BORDER_LIGHT,
              top: 0,
            },
          })),
      ],
      [
        {
          title: childrenGroupColumns[0]?.title,
          colId: childrenGroupColumns[0]?.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
          thClassName: styles.customTableStickyHead,
        },
        ...childrenGroupColumns?.slice(1, 5).map((item) => ({
          title: item.title,
          colId: item.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
            borderRight:
              childrenGroupColumns.indexOf(item) === 4
                ? BORDER_LIGHT
                : BORDER_DARK,
          },
        })),
        {
          title: childrenGroupColumns[5]?.title,
          colId: childrenGroupColumns[5]?.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
          thClassName: styles.customTableStickyHead,
        },
        ...childrenGroupColumns?.slice(6, 11).map((item) => ({
          title: item.title,
          colId: item.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
        })),
      ],
    ]

    let getTableHeadRowTop = () => {
      const tableHeadRowTop = {}
      childrenGroupColumns.slice(0, 5).forEach((element) => {
        tableHeadRowTop[element.key] = ['parentMargin']
      })
      childrenGroupColumns.slice(5, 10).forEach((element) => {
        tableHeadRowTop[element.key] = ['parentMarEquity']
      })
      return tableHeadRowTop
    }

    result.tableHeadRowTop = getTableHeadRowTop()
  } else {
    result.schema = schemaGroupColumns?.map((item, index) => {
      const title = item.title
      const colId = item.key
      let result = {
        colId,
        title,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
      }
      if (index === 0) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          tdStyle: {
            textAlign: 'center',
            verticalAlign: 'center',
          },
          render: (val, rowId, { style, className }) => (
            <td
              style={{ textAlign: 'center', width: 30, ...style }}
              className={
                rowId === TOTAL_ID
                  ? `${className} ${styles.borderTopLight}`
                  : className
              }
            >
              {rowId !== TOTAL_ID ? <DeleteCol id={rowId} /> : <></>}
            </td>
          ),
        }
      }
      if (index === 1) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
          },
          tdStyle: {
            textAlign: 'left',
            borderRight: BORDER_LIGHT,
          },
          render: (val, rowId, { style, className }) => {
            return (
              <LightBorderColumnCell
                val={
                  rowId === 0
                    ? I18n.t('market.marginLoans.OTHER')
                    : rowId === TOTAL_ID
                    ? ''
                    : getOrganizationInfoById(rowId).tickerOrTaxCode ||
                      EMPTY_VALUE
                }
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={
                  rowId === TOTAL_ID
                    ? `${className} ${styles.borderTopLight}`
                    : className
                }
              />
            )
          },
        }
      }
      if (index === 2) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
            minWidth: 200,
          },
          tdStyle: {
            textAlign: 'left',
            borderRight: BORDER_LIGHT,
            minWidth: 200,
          },
          render: (val, rowId, { style, className }) => (
            <LightBorderColumnCell
              val={
                rowId === 0
                  ? I18n.t('market.marginLoans.OTHER')
                  : rowId === TOTAL_ID
                  ? I18n.t('market.marginLoans.SUM')
                  : getOrganizationInfoById(rowId).organizationShortName ||
                    EMPTY_VALUE
              }
              rowId={rowId}
              isAlignTextLeft
              styleProps={style}
              className={
                rowId === TOTAL_ID
                  ? `${className} ${styles.borderTopLight} ${styles.totalCell}`
                  : className
              }
              isStickyBorderRight
              isText
            />
          ),
        }
      }
      if (index === 3) {
        result = {
          ...result,
          isI18n: false,
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId) => (
            <div className={rowId === TOTAL_ID ? styles.borderTopLight : ''}>
              {formatVal(valDivBillion(val))}
            </div>
          ),
        }
      }
      if (
        index ===
        3 + (DEFAULT_QUARTER_NUMBER - childrenGroupColumnsLength / 2)
      ) {
        result = {
          ...result,
          isI18n: false,
          canCustomTd: true,
          thStyle: {
            borderRight: BORDER_LIGHT,
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId, { style, className }) => (
            <LightBorderColumnCell
              val={valToPercent(val)}
              rowId={rowId}
              styleProps={style}
              className={
                rowId === TOTAL_ID
                  ? `${className} ${styles.borderTopLight}`
                  : className
              }
            />
          ),
        }
      }
      if (
        ![
          0,
          1,
          2,
          3,
          3 + (DEFAULT_QUARTER_NUMBER - childrenGroupColumnsLength / 2),
        ].includes(index)
      ) {
        result = {
          ...result,
          isI18n: false,
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId) => {
            return (
              <div className={rowId === TOTAL_ID ? styles.borderTopLight : ''}>
                {valToPercent(val)}
              </div>
            )
          },
        }
      }
      return result
    })
    result.rowSpanSchema = [
      [
        ...groupColumns
          ?.filter((item) => ['ticker', 'name', 'delete'].includes(item.key))
          .map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div
                style={{
                  textAlign: 'left',
                }}
                className={styles.nameCell}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: item.key !== 'delete' && BORDER_LIGHT,
            },
          })),
        ...groupColumns
          ?.filter((item) =>
            ['parentMargin', 'parentMarEquity'].includes(item.key),
          )
          .map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                <Span style={{ fontSize: 10 }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            colSpan: childrenGroupColumnsLength / 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: item.key === 'parentMargin' && BORDER_LIGHT,
              top: 0,
            },
          })),
      ],
      [
        {
          title: childrenGroupColumns[0]?.title,
          colId: childrenGroupColumns[0]?.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
          thClassName: styles.customTableStickyHead,
        },
        ...childrenGroupColumns
          ?.slice(1, childrenGroupColumnsLength / 2)
          .map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight:
                childrenGroupColumns.indexOf(item) ===
                childrenGroupColumnsLength / 2 - 1
                  ? BORDER_LIGHT
                  : BORDER_DARK,
            },
          })),
        {
          title: childrenGroupColumns[childrenGroupColumnsLength / 2]?.title,
          colId: childrenGroupColumns[childrenGroupColumnsLength / 2]?.key,
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
          thClassName: styles.customTableStickyHead,
        },
        ...childrenGroupColumns
          ?.slice(
            childrenGroupColumnsLength / 2 + 1,
            childrenGroupColumnsLength + 1,
          )
          .map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
          })),
      ],
    ]

    let getTableHeadRowTop = () => {
      const tableHeadRowTop = {}
      childrenGroupColumns
        .slice(0, childrenGroupColumnsLength / 2)
        .forEach((element) => {
          tableHeadRowTop[element.key] = ['parentMargin']
        })
      childrenGroupColumns
        .slice(childrenGroupColumnsLength / 2, childrenGroupColumnsLength)
        .forEach((element) => {
          tableHeadRowTop[element.key] = ['parentMarEquity']
        })
      return tableHeadRowTop
    }

    result.tableHeadRowTop = getTableHeadRowTop()
  }

  return result
}

export const sortObjByKey = (data, key) => {
  const sorted = data?.sort((a, b) => b[key] - a[key])
  return sorted
}
