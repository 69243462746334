import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { CHART_TYPE } from '../constants'
import { changeDisplayChartType, selectDisplayChartType } from '../store/slice'
import style from './index.module.css'

const ChartTypeTab = () => {
  const dispatch = useDispatch()
  const chartType = useSelector(selectDisplayChartType)

  const handleChangeDisplayChartType = ({ value }) => {
    dispatch(changeDisplayChartType(value))
  }

  return (
    <div className={style.chartTypeTab}>
      <DispatchActionTab
        data={Object.keys(CHART_TYPE).map((key) => {
          return {
            title: 'market.marketInDepth.foreign.' + key,
            value: CHART_TYPE[key],
          }
        })}
        activeTab={chartType}
        onChangeTab={handleChangeDisplayChartType}
      />
    </div>
  )
}

export default ChartTypeTab
