import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import style from '../style.module.css'

const ChartHeader = () => {
  return (
    <div className={`row ${style.blockHeader}`}>
      <div className="w-50">
        <div className="container align-center j-b">
          <Span className="canvas-chart-title">
            <Translate value="market.derivative.BUY_VOL" />
          </Span>
          <Span className="canvas-chart-title">
            <Translate value="market.derivative.BID" />
          </Span>
        </div>
      </div>
      <div className="w-50">
        <div className="container align-center j-b">
          <Span className="canvas-chart-title">
            <Translate value="market.derivative.ASK" />
          </Span>
          <Span className="canvas-chart-title">
            <Translate value="market.derivative.SELL_VOL" />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default ChartHeader
