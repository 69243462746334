import PropTypes from 'prop-types'
import { withWrapper } from '../../../common/withWrapper'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { PanelFilterTable } from './filter/PanelFilterTable'
import { PanelTable } from './table/PanelTable'

const mapKeys = [
  { key: 'FINANCIAL_FILTER', component: PanelFilterTable },
  { key: 'FINANCIAL_TABLE', component: PanelTable },
]
const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return mapKeys.map((item) => {
    const Component = item.component
    return (
      <Component
        key={item.key}
        panelRefs={panelRefs}
        panelKey={item.key}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  })
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(PANEL_LAYOUTS[15](mapKeys), panelRefs)(Content)
