import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { useGetUserSettings } from '../common/useGetUserSettings'
import { dashboardTypes, screenTypes } from '../constants'
import { changeDashboard, selectChartOfTheDay } from '../store/slice'
import { deleteDashboard } from '../store/thunk'
import style from './styleTheme.module.css'

const PopupDeleteDashboard = ({ handleShow, dashboard }) => {
  const dispatch = useDispatch()

  const { defaultDashboard, removeDefaultDashboard } = useGetUserSettings()

  const chartOfTheDay = useSelector(selectChartOfTheDay)

  const onClose = () => {
    handleShow(false)
  }

  const onApply = () => {
    handleShow(false)
    dispatch(
      deleteDashboard({
        id: dashboard.id,
      }),
    )

    if (!defaultDashboard?.id || dashboard.id === defaultDashboard?.id) {
      removeDefaultDashboard()
      dispatch(
        changeDashboard({
          screenType: screenTypes.DASHBOARD,
          dashboardType: dashboardTypes.CHART_OF_THE_DAY,
          data: chartOfTheDay,
        }),
      )
    } else {
      dispatch(
        changeDashboard({
          screenType: screenTypes.DASHBOARD,
          dashboardType: dashboardTypes.MY_DASHBOARD,
          data: defaultDashboard,
        }),
      )
    }
  }

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div className={`modal modal-small ${style.modal}`}>
        <div className={`modal-title ${style.titleStyle}`}>
          <h3>
            <Span>
              <Translate value="financialChart.DELETE_DASHBOARD" />
            </Span>
          </h3>
          <a onClick={onClose} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentStyle}`}>
          <Span className={style.colorText}>
            <Translate value="financialChart.CONFIRM_DELETE" />
            {dashboard.name}
            {' ?'}
          </Span>
          <div className="group-btn justify-content-end">
            <Button
              className={`btn w-80 h-20 ${style.buttonGrayStyle}`}
              style={{ fontSize: 11 }}
              onClick={onApply}
            >
              <Translate value="common.button.BUTTON_DELETE" />
            </Button>
            <Button
              className={`btn w-80 h-20 ${style.buttonBlueStyle}`}
              style={{ fontSize: 11 }}
              onClick={onClose}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupDeleteDashboard
