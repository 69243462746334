import style from './index.module.css'

export const IconClose = ({ setIsShow }) => {
  return (
    <i
      className={['icon-delete-stroke', style.iconClose].join(' ')}
      onClick={() => setIsShow(false)}
    />
  )
}
