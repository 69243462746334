import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TIME_SELECT } from '../constants'
import { SelectType } from './SelectType'
import { changeDateTime } from './store/slice'

export const ChartFilter = ({ isIpadScreen, dateType }) => {
  const dispatch = useDispatch()

  // Actions
  const changeStateDateTime = (dateTime) => {
    dispatch(changeDateTime(dateTime.value))
  }

  return (
    <div className={isIpadScreen ? 'mb-8' : 'table-info'}>
      <div className={`table-info-left ${isIpadScreen && 'mb-8'}`}>
        <SelectType />
      </div>
      <div className="table-info-right">
        <DispatchActionTab
          data={Object.keys(TIME_SELECT).map((key) => {
            return {
              title: `market.marketInDepth.indexStatistics.${key}`,
              value: TIME_SELECT[key],
            }
          })}
          itemStyle={{ padding: '0 12px', fontWeight: 600 }}
          activeTab={dateType}
          onChangeTab={changeStateDateTime}
        />
      </div>
    </div>
  )
}

ChartFilter.propTypes = {
  isIpadScreen: PropTypes.bool,
  dateType: PropTypes.string.isRequired,
}
