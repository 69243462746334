export const settingProfile = {
  MY_PROFILE: 'Hồ sơ của tôi',
  NAME: 'Tên',
  MAIL: 'Email',
  PHONE: 'Điện thoại',
  PASSWORD: 'Mật khẩu',
  FAVORITE_CONTACT: 'Liên hệ yêu thích',
  CHANGE_PASSWORD: 'Thay đổi mật khẩu',
  EDIT_PROFILE: 'Chỉnh sửa hồ sơ',
  MY_SUBSCRIPTION: 'Đăng ký của tôi',
  ACCOUNT_TYPE: 'Loại tài khoản',
  EXPIRY_DATE: 'Ngày hết hạn',
  BUTTON_RENEW: 'Gia hạn',
  BUTTON_UPGRADE: 'Nâng cấp',
  REQUEST_TO_CHANGE: 'Yêu cầu thay đổi',
  RECENT_PASSWORD: 'Mật khẩu gần đây',
  ENTER_RECENT_PASSWORD: 'Nhập mật khẩu gần đây',
  NEW_PASSWORD: 'Mật khẩu mới',
  ENTER_NEW_PASSWORD: 'Nhập mật khẩu mới',
  CONFIRM_NEW_PASSWORD: 'Xác nhận mật khẩu mới',
  RE_ENTER_NEW_PASSWORD: 'Nhập lại mật khẩu mới',
  BUTTON_CHANGE: 'Thay đổi',
  MESS_CHANGE_PASSWORD_SUCCEED: 'Mật khẩu của bạn đã được thay đổi thành công.',
  MESS_EDIT_INFO_USER_SUCCEED:
    'Yêu cầu của bạn đã được gửi đến dịch vụ khách hàng của chúng tôi và sẽ sớm được chấp thuận.',
  MESS_REQUEST_RENEWALS_UPGRADE_ACCOUNT:
    'Cảm ơn Quý khách đã yêu cầu nâng cấp/gia hạn tài khoản FiinPro-X. Yêu cầu của Quý khách đã được ghi nhận và chuyển đến bộ phận chăm sóc khách hàng của FiinGroup. Chúng tôi sẽ liên hệ sớm nhất để hỗ trợ Quý khách hoàn tất quá trình nâng cấp/gia hạn tài khoản.',
}
