import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  LEVEL_MARGIN_LEFT,
  PERCENT_MARGIN_LEFT,
  tabConstants,
} from './constants'

export const getTableColumnTitle = (item) => {
  if (item.month < 10) {
    return `Th0${item.month}-${item.year}`
  } else {
    return `Th${item.month}-${item.year}`
  }
}

const getRowConstants = (rowId, data) => {
  return data.find((item) => item.id === rowId)
}

export const convertTitleHeader = (title, typeDataTime, locale) => {
  const year = title.slice(title.lastIndexOf('-') + 1)
  const month = title.slice(2, title.lastIndexOf('-'))
  if (month && year) {
    if (typeDataTime === tabConstants.MONTHLY) {
      return `${month}-${year}`
    }

    if (typeDataTime === tabConstants.ACCUMULATED_MONTH) {
      if (Number(month) === 1) {
        return locale === 'vi' ? title : `Jan-${year}`
      } else {
        return locale === 'vi'
          ? `${Number(month)}Th-${year}`
          : `${Number(month)}M-${year}`
      }
    }

    if (typeDataTime === tabConstants.YEARLY) {
      if (Number(month) === 12) {
        return year
      } else {
        return `${month}Th-${year}`
      }
    }
    return title
  }
  return ''
}

export const getTableSchema = (groupColumns, data, timeType, locale) => {
  return groupColumns.map((item, index) => {
    const title = convertTitleHeader(item.title, timeType, locale)
    const colId = item.key
    const result = {
      colId,
      title,
    }

    if (index === 0) {
      return {
        ...result,
        isI18n: true,
        thStyle: {
          textAlign: 'left',
        },
        render: (val, rowId) => {
          const rowConstants = getRowConstants(rowId, data)
          const marginLeft =
            rowConstants?.level * LEVEL_MARGIN_LEFT +
            (rowConstants?.isPercent ? PERCENT_MARGIN_LEFT : 0)
          if (val !== EMPTY_VALUE) {
            return (
              <Span
                style={{
                  marginLeft,
                  opacity: rowConstants?.isPercent ? 0.4 : 1,
                  fontStyle: rowConstants?.isPercent ? 'italic' : '',
                }}
              >
                <TextEllipsisStaticLine
                  isI18n={false}
                  val={val}
                  appendStyle={{
                    minWidth: '200px',
                  }}
                />
              </Span>
            )
          } else {
            return (
              <Span
                style={{
                  marginLeft,
                  opacity: rowConstants?.isPercent ? 0.4 : 1,
                  fontStyle: rowConstants?.isPercent ? 'italic' : '',
                }}
              >
                <Translate
                  value={
                    'economy.productionAndConsumption.consumption.GROWTH_YOY'
                  }
                />
              </Span>
            )
          }
        },
      }
    }

    return {
      ...result,
      isI18n: true,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        const rowConstants = getRowConstants(rowId, data)
        return (
          <Span
            style={{
              opacity: rowConstants?.isPercent && val > 0 ? 0.4 : 1,
              fontStyle: rowConstants?.isPercent ? 'italic' : '',
              color: val < 0 ? '#ff4752' : '',
            }}
          >
            {rowConstants?.isPercent
              ? valToPercent(val)
              : formatVal(val / 10 ** 9)}
          </Span>
        )
      },
    }
  })
}

export const getMonthAndYearInDataRange = (data) => {
  return data
    ?.map((item) => ({
      month: item.realMonth,
      year: item.realYear,
    }))
    ?.filter((value, index, self) => {
      return (
        index ===
        self.findIndex((t) => t.month === value.month && t.year === value.year)
      )
    })
    .sort((a, b) => {
      if (a.year === b.year) {
        if (a.month < b.month) return -1
      } else {
        if (a.year < b.year) return -1
      }
      return null
    })
}

export const getValueFromRange = (data) => {
  const dataKeyColumn = getMonthAndYearInDataRange(data)?.map((value) => {
    return data
      .filter(
        (item) =>
          item.realMonth === value.month && item.realYear === value.year,
      )
      ?.map((item) => {
        return {
          value: item.value ?? 0,
          growth: item.growth ?? 0,
          id: item.retailVNTypeId ?? 0,
          label: `Th${item.realMonth}-${item.realYear}`,
          name: item.retailVNTypeName,
        }
      })
  })
  return dataKeyColumn.map((item, index) => {
    const obj = {}
    item.forEach((i, key) => {
      obj[`value${key + 1}`] = i.value / 10 ** 9
      obj[`growth${key + 1}`] = i.growth
      obj['time'] = i.label
      obj[key + 1] = i.name
    })
    return obj
  })
}

export const getValueChartDetails = (data, timeType, locale) => {
  const dataKeyColumn = getMonthAndYearInDataRange(data)?.map((value) => {
    return data
      .filter(
        (item) =>
          item.realMonth === value.month && item.realYear === value.year,
      )
      ?.map((item) => {
        return {
          value: item.value * 100 ?? 0,
          growth: item.growth ?? 0,
          id: item.retailVNTypeId ?? 0,
          year: item.realYear,
          label: ConvertLabel(
            `Th${item.realMonth}-${item.realYear}`,
            timeType,
            locale,
          ),
        }
      })
  })
  return dataKeyColumn.map((item, index) => {
    const obj = {}
    item.forEach((i, key) => {
      obj[`${i.id}`] = i.value
      obj[`year`] = i.year
      obj['label'] = i.label
    })
    return obj
  })
}

export const getValueChartGrowthYear = (data) => {
  const dataKeyColumn = getMonthAndYearInDataRange(data)
    ?.map((value) => {
      return data
        .filter((item) => item.realMonth === value.month)
        ?.map((item) => {
          return {
            value: item.value ?? 0,
            year: item.realYear,
            label: `${
              item.realMonth < 10
                ? `Th0${item.realMonth}`
                : `Th${item.realMonth}`
            }`,
          }
        })
    })
    .slice(0, 12)
  return dataKeyColumn?.map((item, index) => {
    const obj = {}
    item.forEach((i, key) => {
      obj[i?.year] = i?.value / 10 ** 9
      obj[`year`] = i?.year
      obj['label'] = i?.label
    })
    return obj
  })
}

export const convertDataTable = (data, dataTitle, listId) => {
  const getDataKeyFromRange = getMonthAndYearInDataRange(data)?.map((item) => {
    return item.month < 10
      ? `Th0${item.month}-${item.year}`
      : `Th${item.month}-${item.year}`
  })

  const getDataValueFromRange = getMonthAndYearInDataRange(data)?.map(
    (value) => {
      return data
        .filter(
          (item) =>
            item.realMonth === value.month && item.realYear === value.year,
        )
        ?.map((item) => [
          {
            value: item.value ?? 0,
            id: item.retailVNTypeId ?? 0,
          },
          {
            value: item.growth ?? 0,
            id: `growth${item.retailVNTypeId}` ?? 0,
          },
        ])
        .flat()
    },
  )

  const getCorrespondingDataWithKey = (core, i) => {
    return (
      core[core.indexOf(core.find((item) => item.id === i))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []
    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return listId?.map((item, index) => {
    return {
      id: item,
      name:
        dataTitle?.find((i) => i.retailVNTypeId === item)?.retailVNTypeName ??
        EMPTY_VALUE,
      level:
        index % 2 === 0
          ? dataTitle?.find((i) => i.retailVNTypeId === item)?.retailVNTypeLevel
          : dataTitle?.find(
              (i) => i.retailVNTypeId === Number(listId[index].slice(6)),
            )?.retailVNTypeLevel + 1,
      parentId:
        index % 2 === 0
          ? dataTitle?.find((i) => i.retailVNTypeId === item)
              ?.parentRetailVNTypeId
          : '',
      isPercent: index % 2 === 0 ? false : true,
      ...getKeyAndValRange(item),
    }
  })
}

export const ConvertLabel = (data, typeTime, locale) => {
  const year = data.slice(data.lastIndexOf('-') + 1)
  const month = data.slice(2, data.lastIndexOf('-'))
  if (month && year) {
    if (typeTime === tabConstants.MONTHLY) {
      return month < 10 ? `0${month}-${year}` : `${month}-${year}`
    }

    if (typeTime === tabConstants.ACCUMULATED_MONTH) {
      if (Number(month) === 1) {
        return locale === 'vi' ? data : `Jan-${year}`
      } else {
        return locale === 'vi'
          ? `${Number(month)}Th-${year}`
          : `${Number(month)}M-${year}`
      }
    }

    if (typeTime === tabConstants.YEARLY) {
      if (Number(month) === 12) {
        return year
      } else {
        return `${month}Th-${year}`
      }
    }
    return data
  }
  return data
}

export const getLatestYearData = (maxYear, yearNum = 5) => {
  const currentYear = maxYear
  const years = []
  const startYear = +currentYear - yearNum + 1
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year)
  }

  return years
}
