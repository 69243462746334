import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../common/chart/constants'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { formatTradingDate } from '../helper'
import {
  keys,
  selectDataExpected,
  selectFilterExpected,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'

const CashflowChart = ({ width, height }) => {
  const { list, data } = useSelector(selectDataExpected)
  const { activeMoment } = useSelector(selectFilterExpected)
  const loading = useSelector(selectLoading(keys.EXPECTED))
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataChart = useMemo(() => {
    if (!data?.length) return []

    return data
      .filter((item) => item?.id !== 'total')
      .map((item) => ({
        ...item,
        tradingDateId: formatTradingDate({
          date: parseFloat(item.tradingDateId) * 1000,
          type: activeMoment,
          locale,
        }),
      }))
  }, [data])

  const renderTooltip = (dataChart) => <ChartTooltip data={dataChart} />

  const label = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_LABEL'),
    [locale],
  )

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {!!formatDataChart.length && !!list?.length ? (
            <>
              <div style={{ height: height - 26 }}>
                <ChartContainer
                  timeFrame="CUSTOM"
                  xTickNum={5}
                  data={formatDataChart}
                  width={width}
                  height={height - 8}
                  keyXAxis="tradingDateId"
                  tickFormatter={(d) => d}
                  yAxis={[
                    {
                      id: 'left',
                      keys: list,
                      orientation: 'left',
                      label,
                      labelPosition: AXIS_LABEL_POSITION.LEFT,
                      isStackedBar: true,
                      tickNum: 5,
                      min: 0,
                    },
                  ]}
                  margin={{ left: 0, right: 16, top: 16, bottom: 16 }}
                  renderCustomTooltip={renderTooltip}
                >
                  {({ chartContentWidth }) => (
                    <>
                      <Bar
                        isAnimationActive={false}
                        yAxisId="left"
                        stackId="left"
                        dataKey={list[0]}
                        fill="#FACC5C"
                        barSize={27}
                      />
                      <Bar
                        isAnimationActive={false}
                        yAxisId="left"
                        stackId="left"
                        dataKey={list[1]}
                        fill="#40ABFF"
                        barSize={27}
                      />
                    </>
                  )}
                </ChartContainer>
              </div>
              <Footer
                key={width}
                list={[
                  {
                    text: 'bond.portfolio.expected.COUPON',
                    type: TYPE.SQUARE,
                    before: {
                      bgColor: '#FACC5C',
                    },
                  },
                  {
                    text: 'bond.portfolio.expected.PRINCIPAL',
                    type: TYPE.SQUARE,
                    before: {
                      bgColor: '#40ABFF',
                    },
                  },
                ]}
              />
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  )
}

export default CashflowChart
