import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import {
  selectChartReplaceChecked,
  selectChartSelected,
  setChartReplaceChecked,
  setChartSelected,
} from './store/slice'
import style from './style.module.css'

const ChartItem = ({
  children,
  childrenClass,
  selectMultipleItems,
  childrenStyle,
}) => {
  const dispatch = useDispatch()

  const chartReplaceChecked = useSelector(selectChartReplaceChecked)
  const chartsSelected = useSelector(selectChartSelected)

  const getNameChart = () => {
    return JSON.parse(children.detail)?.nameChart?.name
  }

  const handleChartSelected = (e) => {
    if (selectMultipleItems) {
      if (e.target.checked) {
        dispatch(setChartSelected([...chartsSelected, children]))
      } else {
        dispatch(
          setChartSelected(
            chartsSelected.filter((item) => item.id !== children.id),
          ),
        )
      }
    } else {
      dispatch(setChartReplaceChecked(children))
    }
  }

  if (selectMultipleItems) {
    return (
      <label>
        <div className={[style.childrenDashBoard, childrenClass].join(' ')}>
          <div style={{ width: `calc(100% - 30px)` }}>
            <TextEllipsis
              isI18n={false}
              text={getNameChart() || children.name}
            />
          </div>
          <div className={`ml-8 ${style.checkBox}`}>
            <input
              className={`checkbox line `}
              onClick={handleChartSelected}
              type="checkbox"
              checked={chartsSelected.includes(children)}
            />
          </div>
        </div>
      </label>
    )
  }

  return (
    <div
      className={[style.childrenDashBoard, childrenClass].join(' ')}
      onClick={handleChartSelected}
      style={childrenStyle(children.id === chartReplaceChecked?.id)}
    >
      <TextEllipsis isI18n={false} text={getNameChart() || children.name} />
    </div>
  )
}

ChartItem.propTypes = {
  children: PropTypes.object,
  childrenClass: PropTypes.string,
  selectMultipleItems: PropTypes.bool,
}

export default ChartItem
