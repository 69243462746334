import masterProxy from '../../proxies/common/MasterProxy'
import { ServiceBase } from '../ServiceBase'

class MasterService extends ServiceBase {
  getExchanges(params) {
    return this.applyMemoryCache(
      'MasterService.getExchanges',
      params,
    )(() => masterProxy.getExchanges(params))
  }

  getIcbs(params) {
    return this.applyMemoryCache(
      'MasterService.getIcbs',
      params,
    )(() => masterProxy.getIcbs(params))
  }

  getTimeZone(params) {
    return this.applyMemoryCache(
      'MasterService.getTimeZone',
      params,
    )(() => masterProxy.getTimeZone(params))
  }

  // Groups param is group of All, HOHAUP, OTC and PRIVATE. Default HOHAUP
  getStocks(params) {
    return this.applyMemoryCache(
      'MasterService.getStocks',
      params,
    )(() => masterProxy.getStocks(params))
  }

  // Get Bonds

  getBonds(params) {
    return this.applyMemoryCache(
      'MasterService.getBonds',
      params,
    )(() => masterProxy.getBonds(params))
  }

  getFutures(params) {
    return this.applyMemoryCache(
      'MasterService.getFutures',
      params,
    )(() => masterProxy.getFutures(params))
  }

  getCoveredWarrants(params) {
    return this.applyMemoryCache(
      'MasterService.getCoveredWarrants',
      params,
    )(() => masterProxy.getCoveredWarrants(params))
  }

  getEconomies(params) {
    return this.applyMemoryCache(
      'MasterService.getEconomies',
      params,
    )(() => masterProxy.getEconomies(params))
  }

  getIndices(params) {
    return this.applyMemoryCache(
      'MasterService.getIndices',
      params,
    )(() => masterProxy.getIndices(params))
  }

  getIndicesV2(params) {
    return this.applyMemoryCache(
      'MasterService.getIndicesV2',
      params,
    )(() => masterProxy.getIndicesV2(params))
  }

  getOrganizationsByIcb(params) {
    return this.applyMemoryCache(
      'MasterService.getOrganizationsByIcb',
      params,
    )(() => masterProxy.getOrganizationsByIcb(params))
  }

  getOrganizationsByExchange(params) {
    return this.applyMemoryCache(
      'MasterService.getOrganizationsByExchange',
      params,
    )(() => masterProxy.getOrganizationsByExchange(params))
  }

  getOrganizationsByExchangeAndICB(params) {
    return this.applyMemoryCache(
      'MasterService.getOrganizationsByExchangeAndICB',
      params,
    )(() => masterProxy.getOrganizationsByExchangeAndICB(params))
  }

  getOrganizationsByExchangeAndICBV2(params) {
    return this.applyMemoryCache(
      'MasterService.getOrganizationsByExchangeAndICBV2',
      params,
    )(() => masterProxy.getOrganizationsByExchangeAndICBV2(params))
  }

  getAllFunctions(params) {
    return this.applyMemoryCache(
      'MasterService.getAllFunctions',
      params,
    )(() => masterProxy.getAllFunctions(params))
  }

  getMaxMinTable(params) {
    return this.applyMemoryCache(
      'MasterService.getMaxMinTable',
      params,
    )(() => masterProxy.getMaxMinTable(params))
  }

  getAllFunds(params) {
    return this.applyMemoryCache(
      'MasterService.getAllFunds',
      params,
    )(() => masterProxy.getAllFunds(params))
  }
}

export default new MasterService()
