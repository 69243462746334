import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import { handleExport } from '../../../../utils/Export'

export const getKeyIndicators = createAsyncThunk(
  'corporate/overview/keyIndicator/GET_KEY_INDICATOR',
  async (data, { rejectWithValue }) => {
    const response = await OverviewService.getKeyIndicators(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadKeyIndicators = createAsyncThunk(
  'corporate/overview/keyIndicator/DOWNLOAD_KEY_INDICATORS',
  async (data) => {
    const response = await OverviewService.downloadKeyIndicators(data)
    handleExport(response.data, response.filename)
  },
)
