import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { DEFAULT_CONFIG } from '../configs'
import { chartTypes } from '../constants'
import { getTimeFrame } from '../helper'
import { getGetLatestPeriodBank, getIncomeStructure } from './thunk'

const {
  DEFAULT_YEAR,
  DEFAULT_TIME_RANGE,
  DEFAULT_CHOOSE,
  DEFAULT_FIELD_NAME_1,
  DEFAULT_ACTIVE_TAB,
} = DEFAULT_CONFIG

const initialState = {
  incomeStructureLoading: true,
  choose: DEFAULT_CHOOSE,
  chartName: {
    fieldName: 'TOI',
    value: '',
    relativeValue: '',
    isPercentFormat: false,
  },
  fieldName: DEFAULT_CHOOSE,
  fieldName_1: DEFAULT_FIELD_NAME_1,
  incomeStructure: [],
  year: DEFAULT_YEAR,
  timeRange: DEFAULT_TIME_RANGE,
  chartType: {
    chart1Type: chartTypes.COLUMN_CHART,
    chart2Type: chartTypes.COLUMN_CHART,
  },
  activeTabStackChart1: DEFAULT_ACTIVE_TAB,
  activeTabStackChart2: DEFAULT_ACTIVE_TAB,
}

const slice = createSlice({
  name: 'corporate/businessModel/segmentalAnalysis',
  initialState,
  reducers: {
    handleChooseCol: (state, action) => {
      if (action.payload) {
        state.choose = action.payload
      }
    },
    handleChartName: (state, action) => {
      state.chartName = action.payload
    },
    handleFieldName: (state, action) => {
      state.fieldName = action.payload?.fieldName
      state.fieldName_1 = action.payload.fieldName_1
    },
    handleChartType: (state, action) => {
      state.chartType = action.payload
    },
    handleChangeYear: (state, action) => {
      state.year = action.payload
    },
    handleChangeTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleActiveValueTab1: (state, action) => {
      state.activeTabStackChart1 = action.payload
    },
    handleActiveValueTab2: (state, action) => {
      state.activeTabStackChart2 = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIncomeStructure.pending, (state) => {
      state.incomeStructureLoading = true
    })
    builder.addCase(getIncomeStructure.fulfilled, (state, action) => {
      state.incomeStructureLoading = false
      state.incomeStructure = action.payload || []
    })
    builder.addCase(getIncomeStructure.rejected, (state, action) => {
      state.incomeStructureLoading = action.payload.isLoading
    })
    builder.addCase(getGetLatestPeriodBank.fulfilled, (state, action) => {
      state.year = action.payload?.year || DEFAULT_YEAR
      state.timeRange = getTimeFrame(action.payload?.periodType)
    })
  },
})

export const {
  handleChooseCol,
  handleChangeYear,
  handleChangeTimeRange,
  handleChartType,
  handleFieldName,
  handleChartName,
  handleActiveValueTab1,
  handleActiveValueTab2,
} = slice.actions
export const selectIncomeStructureLoading = (state) =>
  state[slice.name].incomeStructureLoading
export const selectIncomeStructure = (state) =>
  state[slice.name].incomeStructure
export const selectChoose = (state) => state[slice.name].choose
export const selectFieldName_1 = (state) => state[slice.name].fieldName_1
export const selectFieldName = (state) => state[slice.name].fieldName
export const selectYear = (state) => state[slice.name].year
export const selectTimeRange = (state) => state[slice.name].timeRange
export const selectChartType = (state) => state[slice.name].chartType
export const selectChartName = (state) => state[slice.name].chartName
export const selectActiveTab1 = (state) =>
  state[slice.name].activeTabStackChart1
export const selectActiveTab2 = (state) =>
  state[slice.name].activeTabStackChart2

register(slice.name, slice.reducer)
