import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { COM_GROUP, ICB_ID } from '../../../constants/Group'
import { getMaxDate } from '../averageTradingValue/store/thunk'
import { COLOR_STRUCTURE_CHART } from '../common/constants'
import style from '../index.module.css'
import { selectFilter } from '../tableMarginGrowth/store/slice'
import { getOrganizationsInfo } from '../tableMarginGrowth/store/thunk'
import { ChartComponent } from './ChartComponent'
import { DEFAULT_ALL_ORGAN_ID, DEFAULT_ALL_VALUE } from './constants'
import { FilterList } from './FilterList'
import {
  selectCurrentOrganizationId,
  selectDataChart,
  selectIdsBarkey,
  selectListOrganizations,
  selectLoading,
  selectMaxDate,
} from './store/slice'
import { getMarginLoans } from './store/thunk'

const TITLE_HEIGHT = 25

export const MarginLoansChart = ({ width, height, titleChart }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)
  const idsBarkey = useSelector(selectIdsBarkey)
  const maxDate = useSelector(selectMaxDate)
  const listOrganizations = useSelector(selectListOrganizations)
  const filteredOrganizationId = useSelector(selectCurrentOrganizationId)
  const locale = useSelector((state) => state.i18n.locale)

  const fillColor = {}
  const mappingDisplayName = {}
  for (const id of idsBarkey) {
    fillColor[id] = COLOR_STRUCTURE_CHART[idsBarkey.indexOf(id)]
    mappingDisplayName[id] = id
  }

  const currentQuarter = filter?.currentQuarter || maxDate?.quarter
  const currentYear = filter?.currentYear || maxDate?.year
  const currentOrganizationId =
    filteredOrganizationId ||
    (listOrganizations.length ? listOrganizations[0]?.value : '')

  useEffect(() => {
    dispatch(getMaxDate({}))
  }, [])

  useEffect(() => {
    if (currentOrganizationId) {
      dispatch(
        getMarginLoans({
          RealQuarter: currentQuarter,
          RealYear: currentYear,
          OrganId:
            currentOrganizationId === DEFAULT_ALL_VALUE
              ? DEFAULT_ALL_ORGAN_ID
              : currentOrganizationId,
        }),
      )
    }
  }, [currentQuarter, currentYear, currentOrganizationId, locale])

  useEffect(() => {
    dispatch(getOrganizationsInfo({ ICBId: ICB_ID[COM_GROUP.SECURITIES][0] }))
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterList
              currentYear={currentYear}
              currentQuarter={currentQuarter}
              currentOrganizationId={currentOrganizationId}
              listOrganizations={listOrganizations}
            />
            {isLoading ? (
              <Loading />
            ) : (
              size.height &&
              (!dataChart.length ? (
                <div style={{ height: height - size.height - TITLE_HEIGHT }}>
                  <NoData />
                </div>
              ) : (
                <div
                  style={{ height: height - size.height - TITLE_HEIGHT }}
                  id="marginLoans"
                >
                  <div
                    className={[style.titleHeader, 'canvas-chart-title'].join(
                      ' ',
                    )}
                  >
                    {titleChart}
                  </div>
                  <ChartComponent
                    width={width}
                    height={height - size.height - TITLE_HEIGHT}
                    data={dataChart}
                    idsBarkey={idsBarkey}
                    currentOrganizationId={currentOrganizationId}
                    listOrganizations={listOrganizations}
                    currentQuarter={currentQuarter}
                  />
                </div>
              ))
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
