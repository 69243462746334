import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import { Span } from '../../html/Span'
import { getTextWidth } from '../helper'
import style from './referencePrice.module.css'

const paddingLeftRight = 16

const heightLabel = 16

const ReferencePrice = ({ ctx, value, yAxisWidth }) => {
  const calcFontSize = getFontSize(12)

  const labelWidth = getTextWidth(value, calcFontSize, 600)

  const [integer, decimal] = formatVal(value).split('.')

  return (
    <>
      {value && (
        <g>
          <foreignObject
            x={yAxisWidth - labelWidth - paddingLeftRight}
            y={ctx.viewBox.y - heightLabel / 2}
            width={labelWidth + paddingLeftRight}
            height={heightLabel}
            color="#2a2d34"
          >
            <div
              className={style.labelContainer}
              style={{
                backgroundColor: '#ffcc24',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'center',
                position: 'relative',
                width: 'calc(100% - 4px)',
              }}
            >
              <span className={style.label}>{integer}.</span>
              <Span
                style={{ fontSize: 9 }}
                className={`${style.label} ${
                  calcFontSize < 12 ? style.textSmall : style.textLarge
                }`}
              >
                {decimal}
              </Span>
            </div>
          </foreignObject>
        </g>
      )}
    </>
  )
}

export default ReferencePrice
