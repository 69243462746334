import { useSelector } from 'react-redux'
import { Panel } from '../../common/panel'
import { PreOpenMarket } from '../../common/preOpenMarket/PreOpenMarket'
import {
  FILTER_COUNT_TOP,
  SELECT_TIME_RANGE,
  TYPE_TOP_MOVER,
} from './constants'
import { selectFilter } from './store/slice'
import { downloadTopMover } from './store/thunk'
import TopMoverPanel from './topMoverPanel'
import { formatCurrencyToNumber } from './topMoverPanel/helper'

const PanelTopMover = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const filter = useSelector(selectFilter)

  const params = {
    Type: filter.top,
    ExchangeCode: filter.exchange,
    TimeRange: filter.timeRange,
    ICBId: filter.sector?.icbId,
    WatchListId: filter.watchList?.watchListId,
    FromMarketCap: formatCurrencyToNumber(filter.marketCapFrom),
    ToMarketCap: formatCurrencyToNumber(filter.marketCapTo),
    Top: FILTER_COUNT_TOP,
    SortField: filter.sortField,
    SortOrder: filter.sortOrder,
  }
  if (filter.top === TYPE_TOP_MOVER.BREAKOUT) {
    params.Times = filter.times
  }
  if (
    filter.top === TYPE_TOP_MOVER.CONTRIBUTION ||
    filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE ||
    filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME
  ) {
    params.Order = filter.orderTopMover
  }

  return (
    <Panel
      title={'market.topMover.TOP_MOVER'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadCsv={true}
      downloadCsv={() => downloadTopMover(params)}
    >
      {filter.timeRange === SELECT_TIME_RANGE.ONE_DAY ? (
        <PreOpenMarket>
          <TopMoverPanel />
        </PreOpenMarket>
      ) : (
        <TopMoverPanel />
      )}
    </Panel>
  )
}

export default PanelTopMover
