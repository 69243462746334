import { isEmpty } from 'lodash'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import InputFormatCurrency from '../../../common/input/InputFormatCurrencyCopy'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import TextEllipsis from '../../../common/textEllipsis'
import { invertKeyBy, keyBy } from '../../../utils/Common'
import {
  compareTwoDateTime,
  getDateWithTimezone,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { selecBondInfo } from '../infoSec/store/slice'
import InputDate from './InputDate'
import TableHeader from './TableHeader'
import { columns, typePopup, valueCheckBox, valueTab } from './constants'
import { areArraysEqual } from './helper'
import styles from './index.module.css'
import {
  addMoreTab,
  addMoreTabEdit,
  openPopup,
  selectActiveTab,
  selectDataTab,
  selectDataValue,
  selectListTab,
  selectLoading,
  selectStateSameIndustry,
  updateTab,
} from './store/slice'
import { getAccruedInterest, getPriceYtmApi } from './store/thunk'

function sortByTradingDate(a, b) {
  const dateA = new Date(a.tradingDateId)
  const dateB = new Date(b.tradingDateId)

  if (dateA < dateB) {
    return -1
  }
  if (dateA > dateB) {
    return 1
  }
  return 0
}

const filterArrayDate = (array, tradingDate, locale) => {
  if (isEmpty(array)) {
    return null
  }
  const currentDate = getISOStartOrEndOfDay(tradingDate, locale, true)
  const closestElement = array.reduce((prev, curr) => {
    const prevSettlementDate = new Date(
      getISOStartOrEndOfDay(prev.tradingDateId, locale, true),
    )
    const currSettlementDate = new Date(
      getISOStartOrEndOfDay(curr.tradingDateId, locale, true),
    )
    const prevDiff = Math.abs(prevSettlementDate - new Date(currentDate))

    const currDiff = Math.abs(currSettlementDate - new Date(currentDate))

    return prevDiff < currDiff ? prev : curr
  })
  return closestElement
}

const filterArrayDateFuture = (array, tradingDate, locale) => {
  if (isEmpty(array)) {
    return null
  }
  const currentDate = getISOStartOrEndOfDay(tradingDate, locale, true)
  const closestElement = array.reduce((prev, curr) => {
    const prevSettlementDate = new Date(
      getISOStartOrEndOfDay(prev.tradingDateId, locale, true),
    )
    const currSettlementDate = new Date(
      getISOStartOrEndOfDay(curr.tradingDateId, locale, true),
    )
    const prevDiff = Math.abs(prevSettlementDate - new Date(currentDate))

    const currDiff = Math.abs(currSettlementDate - new Date(currentDate))

    return prevDiff < currDiff ? prev : curr
  })
  return closestElement
}

const Content = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const locale = useSelector((state) => state.i18n.locale)
  const tabActive = useSelector(selectActiveTab)
  const dataTab = useSelector(selectDataTab)
  const isLoading = useSelector(selectLoading)
  const form = useSelector(selectStateSameIndustry)
  const listTab = useSelector(selectListTab)
  const bondInfo = useSelector(selecBondInfo)

  const ReturnIds = () => {
    return dataTab?.[tabActive]?.ids || []
  }
  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  if (isLoading) {
    return <Loading />
  }

  if (ReturnIds() === 0) {
    return <NoData />
  }

  const getItem = (rowId) => {
    return dataTab?.[tabActive]?.data?.[rowId]
  }

  const onChanPrice = (listBondCashFlow) => {
    const price =
      form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.dirtyprice || 0
        : form.checkBox === valueCheckBox.CLEAN_PRICE
        ? parseFloat(form.cleanPrice) + parseFloat(form.accInterest) || 0
        : 0

    if (price > 0 || parseFloat(form?.ytm) > 0) {
      const listBondCreated = invertKeyBy(dataTab?.[valueTab.BOND_DEFAULT].data)
      const checkWeigh = areArraysEqual(listBondCashFlow, listBondCreated)
      const listBond = listBondCashFlow
        .filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) >= new Date(form.paymentDate),
        )
        .map((e) => {
          const result = {
            settlementdate: e.tradingDateId,
            frequency: bondInfo.paymentCalendarMonth,
            paymentStatusId: e.paymentStatusId,
            coupon:
              e.bondPaymentTypeId === 1
                ? parseFloat(e?.couponInterestRate || 0) / 100
                : 0,
          }

          return result
        })
      const pastDay = filterArrayDate(
        listBondCashFlow.filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )
      const paramsLstBondCashFlow = [...listBond]
      if (pastDay) {
        paramsLstBondCashFlow.unshift({
          settlementdate: pastDay?.tradingDateId,
          frequency: bondInfo?.paymentCalendarMonth,
          paymentStatusId: pastDay?.paymentStatusId,
          coupon: parseFloat(pastDay?.couponInterestRate) / 100,
        })
      }

      const originalDebt = listBondCashFlow.find(
        (e) =>
          e.bondPaymentTypeId === 4 &&
          new Date(e.tradingDateId) > new Date(form.paymentDate),
      )

      const params = {
        lstBondCashFlow: paramsLstBondCashFlow,
        issueDate: bondInfo.issueDateId,
        weigh: checkWeigh ? 0 : 1,
        paydate: new Date(getDateWithTimezone(form.paymentDate, locale)),
        interestBasisTypeId: form.interestBasis,
        price:
          form.checkBox === valueCheckBox.DIRTY_PRICE ? form.dirtyprice : 0,
        ytm:
          form.checkBox === valueCheckBox.YTM ? parseFloat(form?.ytm) / 100 : 0,
        parValue: parseInt(originalDebt?.estimatedValue ?? 0),
        exPayDate: form.exDate
          ? new Date(getDateWithTimezone(form.exDate, locale))
          : null,
        cleanPrice:
          form.checkBox === valueCheckBox.CLEAN_PRICE
            ? parseFloat(form.cleanPrice)
            : 0,
      }
      dispatch(getPriceYtmApi(params))
    }
  }

  const deleteRow = (item) => {
    if (
      tabActive !== valueTab.BOND_DEFAULT &&
      tabActive !== valueTab.BOND_CREATED
    ) {
      dispatch(
        openPopup({
          message: I18n.t(
            'bond.valuation.sameIndusTryBond.popupDelete.message',
          ),
          isShow: true,
          type: typePopup.DELETE_ROW,
          itemSelect: item,
        }),
      )
    } else {
      onChangeAddTab()
    }
  }

  const onChangeCellData = (id, item, value, key) => {
    if (
      tabActive !== valueTab.BOND_DEFAULT &&
      tabActive !== valueTab.BOND_CREATED
    ) {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const data = {
        ...dataTab?.[tabActive]?.data,
        [id]: {
          ...elementCurrent,
          [key]: value,
        },
      }

      const dataSortNotFormatted = invertKeyBy(data).sort(sortByTradingDate)

      const dataSorrt = dataSortNotFormatted
        .map((e, index) => {
          if (index === 0) {
            if (new Date(e.tradingDateId) < new Date(form.paymentDate)) {
              return e
            }

            const days = moment(e?.tradingDateId).diff(e?.issueDateId, 'days')

            return {
              ...e,
              couponInterestRate: e?.couponInterestRate,
              estimatedValue:
                (e?.couponInterestRate / 100) *
                e.parValue *
                ((days ? (days === 366 ? 365 : days) : 0) / 365),
            }
          }

          if (index !== 0 && e?.bondPaymentTypeId === 4) {
            return e
          }

          const days = moment(e?.tradingDateId).diff(
            dataSortNotFormatted[index - 1]?.tradingDateId,
            'days',
          )

          return {
            ...e,
            couponInterestRate: e?.couponInterestRate,
            estimatedValue:
              (e?.couponInterestRate / 100) *
              e.parValue *
              ((days ? (days === 366 ? 365 : days) : 0) / 365),
          }
        })
        .map((e, index) => ({ ...e, id: index }))

      onChanPrice(dataSorrt)
      dispatch(
        updateTab({
          data: keyBy(dataSorrt, 'id'),
          ids: dataSorrt.map((e) => e.id),
        }),
      )

      // Accurued
      const pastDay = filterArrayDate(
        dataSorrt.filter(
          (e) =>
            e.bondPaymentTypeId === 1 &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )

      const futureDay = filterArrayDateFuture(
        dataSorrt.filter(
          (e) =>
            e.bondPaymentTypeId === 1 &&
            new Date(e.tradingDateId) > new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )
      const params = {
        ParValue: dataTab?.[tabActive]?.data?.[0]?.parValue,
        CouponInterest: bondInfo.couponInterestRate,
        PayDate: form.paymentDate,
        SettlementDate: pastDay?.tradingDateId || bondInfo.issueDateId,
        SettlementDate1: futureDay?.tradingDateId,
        InterestBasisTypeId: form.interestBasis,
        PaymentCalendarMonth:
          dataTab?.[tabActive]?.data?.[0]?.paymentCalendarMonth,
      }
      dispatch(getAccruedInterest(params))
    } else {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const tabNewCurent = dataTab?.[tabActive]?.data
      const tabNewEdit = {
        ...tabNewCurent,
        [id]: {
          ...elementCurrent,
          [key]: value,
        },
      }
      addTabEdit(tabNewEdit, dataTab?.[tabActive]?.ids)
      // onChangeAddTab()

      const pastDay = filterArrayDate(
        invertKeyBy(tabNewEdit).filter(
          (e) =>
            e.bondPaymentTypeId === 1 &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )

      const futureDay = filterArrayDateFuture(
        invertKeyBy(tabNewEdit).filter(
          (e) =>
            e.bondPaymentTypeId === 1 &&
            new Date(e.tradingDateId) > new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )
      const params = {
        ParValue: dataTab?.[tabActive]?.data?.[0]?.parValue,
        CouponInterest: bondInfo.couponInterestRate,
        PayDate: form.paymentDate,
        SettlementDate: pastDay?.tradingDateId || bondInfo.issueDateId,
        SettlementDate1: futureDay?.tradingDateId,
        InterestBasisTypeId: form.interestBasis,
        PaymentCalendarMonth:
          dataTab?.[tabActive]?.data?.[0]?.paymentCalendarMonth,
      }
      dispatch(getAccruedInterest(params))
    }
  }

  const onChangePriceValue = (id, item, value) => {
    if (item?.estimatedValue === parseInt(value)) return
    if (
      tabActive !== valueTab.BOND_DEFAULT &&
      tabActive !== valueTab.BOND_CREATED
    ) {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const elementPrev = dataTab?.[tabActive]?.data?.[id - 1]
      const days = moment(elementCurrent?.tradingDateId).diff(
        id === 0 ? elementCurrent?.issueDateId : elementPrev?.tradingDateId,
        'days',
      )

      const data = {
        ...dataTab?.[tabActive]?.data,
        [id]: {
          ...elementCurrent,
          estimatedValue: value,
          couponInterestRate:
            ((value / item.parValue) * 100) /
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
        },
      }
      onChanPrice(invertKeyBy(data))
      dispatch(
        updateTab({
          data: data,
          ids: ReturnIds(),
        }),
      )
    } else {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const elementPrev = dataTab?.[tabActive]?.data?.[id - 1]
      const days = moment(elementCurrent?.tradingDateId).diff(
        id === 0 ? elementCurrent?.issueDateId : elementPrev?.tradingDateId,
        'days',
      )

      const data = {
        ...dataTab?.[tabActive]?.data,
        [id]: {
          ...elementCurrent,
          estimatedValue: parseFloat(elementCurrent.estimatedValue).toFixed(1),
        },
      }
      dispatch(
        updateTab({
          data: data,
          ids: ReturnIds(),
        }),
      )
      const tabNewCurent = dataTab?.[tabActive]?.data
      const tabNewEdit = {
        ...tabNewCurent,
        [id]: {
          ...elementCurrent,
          estimatedValue: value,
          couponInterestRate:
            ((value / item.parValue) * 100) /
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
        },
      }
      addTabEdit(tabNewEdit, dataTab?.[tabActive]?.ids)
      // onChangeAddTab()
    }
  }

  const onChangePriceCoupon = (id, item, value) => {
    if (
      parseFloat(item?.couponInterestRate).toFixed(2) ===
      parseFloat(value).toFixed(2)
    ) {
      return
    }

    if (
      tabActive !== valueTab.BOND_DEFAULT &&
      tabActive !== valueTab.BOND_CREATED
    ) {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const elementPrev = dataTab?.[tabActive]?.data?.[id - 1]

      const days = moment(elementCurrent?.tradingDateId).diff(
        id === 0 ? elementCurrent?.issueDateId : elementPrev?.tradingDateId,
        'days',
      )

      const data = {
        ...dataTab?.[tabActive]?.data,
        [id]: {
          ...elementCurrent,
          estimatedValue:
            (value / 100) *
            item.parValue *
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
          couponInterestRate: value,
        },
      }
      onChanPrice(invertKeyBy(data))
      dispatch(
        updateTab({
          data: data,
          ids: ReturnIds(),
        }),
      )
    } else {
      const elementCurrent = dataTab?.[tabActive]?.data?.[id]
      const elementPrev = dataTab?.[tabActive]?.data?.[id - 1]
      const days = moment(elementCurrent?.tradingDateId).diff(
        id === 0 ? elementCurrent?.issueDateId : elementPrev?.tradingDateId,
        'days',
      )
      const data = {
        ...dataTab?.[tabActive]?.data,
        [id]: {
          ...elementCurrent,
          couponInterestRate: parseFloat(
            elementCurrent.couponInterestRate,
          ).toFixed(2),
        },
      }
      dispatch(
        updateTab({
          data: data,
          ids: ReturnIds(),
        }),
      )
      const tabNewCurent = dataTab?.[tabActive]?.data
      const tabNewEdit = {
        ...tabNewCurent,
        [id]: {
          ...elementCurrent,
          estimatedValue:
            (value / 100) *
            item.parValue *
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
          couponInterestRate: value,
        },
      }
      addTabEdit(tabNewEdit, dataTab?.[tabActive]?.ids)
    }
  }

  const onChangeAddTab = () => {
    if (listTab.length >= 7) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M005'),
          typePopup: typePopup.ADD_ROW,
        }),
      )
    }
    const nameTab = I18n.t('bond.valuation.common.nameTab', {
      number: listTab.length - 1,
    })
    dispatch(addMoreTab({ nameTab }))
  }

  const addTabEdit = (data, ids) => {
    if (listTab.length >= 7) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M005'),
          typePopup: typePopup.ADD_ROW,
        }),
      )
    }
    const nameTab = I18n.t('bond.valuation.common.nameTab', {
      number: listTab.length - 1,
    })

    const newData = Object.assign({}, data)
    const dataSortNotFormatted = invertKeyBy(newData).sort(sortByTradingDate)
    const dataSort = dataSortNotFormatted
      .map((e, index) => {
        if (index === 0) {
          if (new Date(e.tradingDateId) < new Date(form.paymentDate)) {
            return e
          }

          const days = moment(e?.tradingDateId).diff(e?.issueDateId, 'days')

          return {
            ...e,
            couponInterestRate: e?.couponInterestRate,
            estimatedValue:
              (e?.couponInterestRate / 100) *
              e.parValue *
              ((days ? (days === 366 ? 365 : days) : 0) / 365),
          }
        }

        if (index !== 0 && e?.bondPaymentTypeId === 4) {
          return e
        }

        const days = moment(e?.tradingDateId).diff(
          dataSortNotFormatted[index - 1]?.tradingDateId,
          'days',
        )

        return {
          ...e,
          couponInterestRate: e?.couponInterestRate,
          estimatedValue:
            (e?.couponInterestRate / 100) *
            e.parValue *
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
        }
      })
      .map((e, index) => ({ ...e, id: index }))

    onChanPrice(dataSort)

    dispatch(addMoreTabEdit({ dataSort, ids, nameTab }))
  }

  return (
    <>
      <Table
        ids={ReturnIds()}
        columnDraggable={false}
        getDataFromRedux={selectDataValue}
        isLoading={isLoading}
        rowDraggable={false}
        // changeIds={changeIds}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(columns, stateSort, sortColumn)
        }
        schema={columns.map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key
          const result = {
            colId,
            title,
          }
          switch (index) {
            case 0:
              return {
                ...result,
                tdStyle: {},
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const currentRow = getItem(rowId)

                  const disabled = compareTwoDateTime(
                    form?.tradingDate,
                    currentRow?.tradingDateId,
                  )
                  return (
                    <td
                      style={{
                        ...props.style,
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ opacity: disabled === 1 ? 0.5 : 1 }}>
                        <TextEllipsis
                          text={
                            currentRow?.bondCashFlowDetailName
                              ? currentRow?.bondCashFlowDetailName
                              : I18n.t(
                                  `bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.typeCashFlow.A${value}`,
                                )
                          }
                          isI18n={
                            currentRow?.bondCashFlowDetailName ? false : true
                          }
                        />
                      </Span>
                    </td>
                  )
                },
              }
            case 1:
              return {
                ...result,
                tdStyle: {},
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const currentRow = getItem(rowId)
                  const disabled = compareTwoDateTime(
                    getISOStartOrEndOfDay(form?.paymentDate, timeZone, true),
                    currentRow?.tradingDateId,
                  )
                  return (
                    <td
                      style={{
                        ...props.style,
                        ...item?.attrs?.style,
                      }}
                    >
                      <div
                        style={{
                          maxWidth: 160,
                          minWidth: 160,
                          margin: 'auto',
                        }}
                      >
                        <InputDate
                          date={value}
                          checkMaxDate={false}
                          disabled={disabled === 1 ? true : false}
                          onChangeDate={(e) =>
                            onChangeCellData(
                              rowId,
                              currentRow,
                              getISOStartOrEndOfDay(e, timeZone, true),
                              'tradingDateId',
                            )
                          }
                          isWrap={false}
                        />
                      </div>
                    </td>
                  )
                },
              }
            case 2:
              return {
                ...result,
                tdStyle: {},
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const currentRow = getItem(rowId)

                  const disabled = compareTwoDateTime(
                    getISOStartOrEndOfDay(form?.paymentDate, timeZone, true),
                    currentRow?.tradingDateId,
                  )
                  return (
                    <td
                      style={{
                        ...props.style,
                        ...item?.attrs?.style,
                      }}
                    >
                      {currentRow?.bondPaymentTypeId === 1 && (
                        <>
                          <div className="d-flex justify-content-center">
                            <div className="d-flex ali-center">
                              <div
                                style={{
                                  width: '120px',
                                  marginRight: '24px',
                                }}
                                className={`mt-0`}
                              >
                                <InputFormatCurrency
                                  className={`${styles.inputFormatCurrency}`}
                                  handleBlur={(value) => {
                                    onChangePriceCoupon(
                                      rowId,
                                      currentRow,
                                      value,
                                    )
                                  }}
                                  defaultValue={value}
                                  fractionDigits={2}
                                  decimalRender={2}
                                  style={{
                                    fontSize: 12,
                                    fontWeight: 340,
                                    color: '#FFF',
                                    opacity: disabled === 1 ? 0.5 : 1,
                                    textAlign: 'right',
                                  }}
                                  disabled={disabled === 1 ? true : false}
                                />
                              </div>
                              <div>
                                <span>%</span>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </td>
                  )
                },
              }
            case 3:
              return {
                ...result,
                tdStyle: {},
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const currentRow = getItem(rowId)
                  const disabled = compareTwoDateTime(
                    getISOStartOrEndOfDay(form?.paymentDate, timeZone, true),
                    currentRow?.tradingDateId,
                  )
                  return (
                    <td
                      style={{
                        ...props.style,
                        ...item?.attrs?.style,
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <div className="d-flex ali-center">
                          <div
                            style={{
                              width: '120px',
                              marginRight:
                                disabled !== 1 &&
                                currentRow?.bondPaymentTypeId !== 4
                                  ? '23px'
                                  : '35px',
                            }}
                            className={`mt-0 `}
                          >
                            <InputFormatCurrency
                              className={`${styles.inputFormatCurrency}`}
                              handleBlur={(value) => {
                                onChangePriceValue(rowId, currentRow, value)
                              }}
                              defaultValue={value}
                              fractionDigits={0}
                              decimalRender={0}
                              style={{
                                fontSize: 12,
                                fontWeight: 340,
                                color: '#FFF',
                                opacity: disabled === 1 ? 0.5 : 1,
                                textAlign: 'right',
                              }}
                              disabled={disabled === 1 ? true : false}
                            />
                          </div>
                          <div>
                            {disabled !== 1 &&
                              currentRow?.bondPaymentTypeId !== 4 && (
                                <i
                                  className="icon-trash"
                                  onClick={() => deleteRow(currentRow)}
                                />
                              )}
                          </div>
                        </div>
                      </div>
                    </td>
                  )
                },
              }
            default:
              return {}
          }
        })}
        stickyFirstColumn={false}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight
      />
    </>
  )
}

export default Content
