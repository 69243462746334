import PropTypes from 'prop-types'
import { ScrollComponent } from '../../../common/ScrollComponent'
import style from './index.module.css'

export const Layout = ({ children, appendStyle }) => {
  return (
    <div className={style.container} style={appendStyle}>
      <ScrollComponent>
        <div className={style.content}>{children}</div>
      </ScrollComponent>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
