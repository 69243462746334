import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { getColorByValueChart } from '../../../../utils/Color'
import { formatVal } from '../../../../utils/Value'
import { SEARCH_TIME_FRAME } from '../constants'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  timeFrame,
  lineKey,
  barNetDirectorDealKey,
  barNetInstitutionKey,
}) => {
  const renderTooltip = (tooltipData) => {
    const arrKeyDisplay = [...displayData, lineKey]
    const tooltipContentData = {
      [barNetDirectorDealKey]: {
        label:
          'market.marketInDepth.directorDeal.DIRECTORS_AND_RELATED_PEOPLE_1',
        format: (val) =>
          formatVal(val) + I18n.t('market.marketInDepth.directorDeal.BILLION'),
        isSetColor: true,
      },
      [barNetInstitutionKey]: {
        label: 'market.marketInDepth.directorDeal.INSTITUTIONAL_SHAREHOLDERS_1',
        format: (val) =>
          formatVal(val) + I18n.t('market.marketInDepth.directorDeal.BILLION'),
        isSetColor: true,
      },
      [lineKey]: {
        label: 'market.marketInDepth.directorDeal.NO_OF_TRANSACTIONS_1',
        format: (val) => val,
      },
    }

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.tooltipHover}</Span>
        </div>
        {arrKeyDisplay.map((key, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(tooltipContentData[key].label)}
              </Span>
            </div>
            <div
              className="ml-8"
              style={{
                color:
                  tooltipContentData[key].isSetColor &&
                  getColorByValueChart(tooltipData[key]),
              }}
            >
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {tooltipContentData[key].format(tooltipData[key])}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      timeFrame={timeFrame}
      xTickNum={SETTINGS.xTickNum}
      tickFormatter={(d) => d}
      margin={{ top: 20, bottom: 0, left: 0, right: 0 }}
      yAxis={[
        {
          id: 'left',
          keys: displayData,
          orientation: 'left',
          isStackedBar: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('market.marketInDepth.directorDeal.NET_VALUE'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
        {
          id: 'right',
          keys: [lineKey],
          orientation: 'right',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('market.marketInDepth.directorDeal.TRANSACTION'),
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
        },
      ]}
      renderCustomTooltip={(dataFormat) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {displayData.includes('netDirectorDeal') && (
            <Bar
              stackId="barRight"
              isAnimationActive={false}
              yAxisId="left"
              dataKey={barNetDirectorDealKey}
              fill={'#0096eb'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes('netInstitution') && (
            <Bar
              stackId="barRight"
              isAnimationActive={false}
              yAxisId="left"
              dataKey={barNetInstitutionKey}
              fill={'#fff6df'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {timeFrame !== SEARCH_TIME_FRAME.ONE_MONTH && (
            <>
              <Line
                yAxisId="right"
                dataKey="lineDash"
                type="linear"
                strokeDasharray="5"
                stroke="#f7b80c"
                fill="#f7b80c"
                strokeWidth={2}
                activeDot={false}
                isAnimationActive={false}
                dot={{
                  strokeWidth: getRadiusOfScatter(
                    chartContentWidth,
                    data.length,
                  ),
                  strokeDasharray: '',
                }}
              />
            </>
          )}
          <Line
            yAxisId="right"
            dataKey={
              timeFrame === SEARCH_TIME_FRAME.ONE_MONTH ? lineKey : 'lineSolid'
            }
            type="linear"
            stroke={'#f7b80c'}
            fill={'#f7b80c'}
            strokeWidth={2}
            dot={{
              strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
              strokeDasharray: '',
            }}
            activeDot={false}
            isAnimationActive={false}
          />
        </>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
