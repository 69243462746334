import PropTypes from 'prop-types'
import { IconWrapper } from '../IconWrapper'
import style from '../index.module.css'
import emailIcon from './icon-email.png'

export const EmailIcon = ({ url }) => {
  const onClick = () => {
    window.location = `mailto:?subject=&amp;body=${url}`
  }
  return (
    <IconWrapper>
      <div className={style.img} onClick={onClick}>
        <img src={emailIcon} alt="Email" width={72} height={72} />
        <span>Email</span>
      </div>
    </IconWrapper>
  )
}

EmailIcon.propTypes = {
  url: PropTypes.string.isRequired,
}
