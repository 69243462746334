import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { EXPORT_TYPE, RADIO_GROUP_BY, VIEW_BY } from '../constant'
import { changeFilterGroupBy, selectFilter, selectViewBy } from '../store/slice'
import style from './style.module.css'

const RadioGroupBy = () => {
  const dispatch = useDispatch()

  // Use selector
  const viewBy = useSelector(selectViewBy)
  const filter = useSelector(selectFilter)

  // Get data
  const getGroupBy = useMemo(() => {
    if (viewBy === VIEW_BY.CORPORATE) {
      return RADIO_GROUP_BY.filter((item) => item.isCompany)
    } else {
      return RADIO_GROUP_BY.filter((item) => item.isSector)
    }
  }, [viewBy])

  // Actions
  const handleChangeFilterGroupBy = (e) => {
    dispatch(changeFilterGroupBy(e.target.value))
  }

  return (
    <div>
      <div className={style.titleListRadio}>
        <Span style={{ fontSize: 12 }}>
          <Translate value="tool.dataExplorer.tradingData.GROUP_BY" />
        </Span>
      </div>
      <ul className={`list-check ml-25 ${style.listCheck}`}>
        {getGroupBy.map((item) => {
          return (
            <li
              key={item.value}
              className={style.pdRadio}
              style={{
                opacity:
                  filter.exportType === EXPORT_TYPE.MULTIPLE_FILES ? 0.4 : 1,
              }}
            >
              <label className="fs-12 align-center">
                <input
                  type="radio"
                  className={`radiobox radiobox2 ${style.radio}`}
                  name={item.name}
                  value={item.value}
                  checked={
                    filter.exportType === EXPORT_TYPE.A_FILE
                      ? item.value === filter.groupBy
                      : false
                  }
                  onChange={handleChangeFilterGroupBy}
                  disabled={filter.exportType === EXPORT_TYPE.MULTIPLE_FILES}
                />
                <Span
                  style={{
                    color:
                      filter.exportType === EXPORT_TYPE.A_FILE &&
                      item.value === filter.groupBy
                        ? '#2f9aee'
                        : '',
                  }}
                >
                  <Translate value={item.title} />
                </Span>
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default RadioGroupBy
