import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'

const CompositionOfFDIInflowsByIndustryColumnChart = ({
  data,
  width,
  height,
  barKeys,
  xAxisKey,
  margin,
}) => {
  const renderTooltip = (tooltipData) => {
    return (
      <div style={{ width: 270 }}>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.xLabel}</Span>
        </div>
        {barKeys.map((el, index) => (
          <div key={index} className="d-flex j-b" style={{ alignItems: 'end' }}>
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(el.text) + ':'}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(tooltipData[el?.vsicId])}%
              </Span>
            </div>
          </div>
        ))}
      </div>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      keyXAxis={xAxisKey}
      timeFrame={TIME_RANGES['1M']}
      tickFormatter={(d) => d}
      yAxis={[
        {
          id: 'barChart',
          keys: barKeys.map((item) => item?.vsicId),
          orientation: 'left',
          isStackedBar: true,
          yAxisWidth: margin.left,
          tickValues: [0, 25, 50, 75, 100],
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: '%',
        },
        {
          id: 'dummy',
          keys: [],
          orientation: 'right',
          yAxisWidth: margin.right,
        },
      ]}
      allowEscapeViewBoxY={true}
      renderCustomTooltip={renderTooltip}
    >
      {({ chartContentWidth }) => (
        <>
          {barKeys.map((item) => (
            <Bar
              key={item?.vsicId}
              stackId="stackBar"
              yAxisId="barChart"
              dataKey={item?.vsicId}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
              fill={item?.color}
              isAnimationActive={false}
            />
          ))}
        </>
      )}
    </ChartContainer>
  )
}

export default CompositionOfFDIInflowsByIndustryColumnChart
