import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { VALUE_TIME, VALUE_release_methot } from '../constants'
import { getICBs } from './thunk'

export const name = 'bond/overview/topRelease'

const initialState = {
  activeTab: VALUE_TIME.MONTHLY,
  typeMethod: VALUE_release_methot.ALL,
  dataL2: [],
}

export const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateTab(state, actions) {
      state.activeTab = actions.payload
    },
    updateMethod(state, actions) {
      state.typeMethod = actions.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getICBs.pending, () => {})
    builder.addCase(getICBs.fulfilled, (state, action) => {
      state.dataL2 = action.payload || []
    })
    builder.addCase(getICBs.rejected, () => {})
  },
})

export const selectActiveMethodType = (state) => state[slice.name].activeTab
export const selectMethodType = (state) => state[slice.name].typeMethod
export const selecDataL2 = (state) => state[slice.name].dataL2

export const { resetStore, updateTab, updateMethod } = slice.actions

register(slice.name, slice.reducer)
