import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Content from './Content'
import style from './index.module.css'
import { resetStore } from './store/slice'
import WorkSpace from './WorkSpace'

export const Economy = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <WorkSpace />
            <h3 className={style.title}>
              <Span style={{ fontSize: 16 }}>
                <Translate value="tool.dataExplorer.economy.TITLE" />
              </Span>
            </h3>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
