import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorValuationService from '../../../../../../core/services/sector/sectorConstituents/SectorValuationService'

export const getLineChart = createAsyncThunk(
  'sector/sectorConstituents/valuation/valuationChart/getLineChart',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SectorValuationService.getLineChart(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
