import WatchlistProxy from '../../proxies/watchlist/WatchlistProxy'
import { ServiceBase } from '../ServiceBase'

class WatchlistService extends ServiceBase {
  getWatchlist(params) {
    return this.getData(() => WatchlistProxy.getWatchlist(params))
  }
  getWatchlistBond(params) {
    return this.getData(() => WatchlistProxy.getWatchlistBond(params))
  }

  updateMasterWatchlist(data) {
    return this.getData(() => WatchlistProxy.updateMasterWatchlist(data))
  }

  copyWatchlist(data) {
    return this.getData(() => WatchlistProxy.copyWatchlist(data))
  }

  deleteWatchlist(data) {
    return this.getData(() => WatchlistProxy.deleteWatchlist(data))
  }

  getWatchlistDetail(params) {
    return this.getData(() => WatchlistProxy.getWatchlistDetail(params))
  }

  getTickerByExchange(params) {
    return this.getData(() => WatchlistProxy.getTickerByExchange(params))
  }

  getTickerByICB(params) {
    return this.getData(() => WatchlistProxy.getTickerByICB(params))
  }

  updateWatchlist(data) {
    return this.getData(() => WatchlistProxy.updateWatchlist(data))
  }

  createWatchlist(data) {
    return this.getData(() => WatchlistProxy.createWatchlist(data))
  }

  updateFavorite(data) {
    return this.getData(() => WatchlistProxy.updateFavorite(data))
  }
}

export default new WatchlistService()
