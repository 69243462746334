import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'

export const PriceRate = () => {
  return (
    <div className={`table-info-left ${style.priceRate}`}>
      <Span style={{ fontSize: 11 }}>
        <Translate
          value={
            'market.equityTrading.priceStatistics.priceData.overview.TABLE_HEAD_TEXT'
          }
        />
      </Span>
    </div>
  )
}
