import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getSummaryInfo } from './thunk'

const initialState = {
  summaryInfo: {},
  loading: true,
}

export const slice = createSlice({
  name: 'corporate/overview/summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSummaryInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSummaryInfo.fulfilled, (state, action) => {
      state.loading = false
      state.summaryInfo = {
        ...(action.payload || {}),
      }
    })
    builder.addCase(getSummaryInfo.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectSummaryInfo = (state) => state[slice.name].summaryInfo
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
