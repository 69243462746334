import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { GDP_PROVINCE_VALUE } from '../../constants'
import {
  defineGroupColumns,
  handleIds,
  keyByArray,
  setMinYear,
} from '../helper'
import { getGDPByProvince, getGDPTypeName, getMaxPeriod } from './thunk'

const { VALUE } = GDP_PROVINCE_VALUE

const initialState = {
  valueGrowthNominal: VALUE,
  valueGrowthReal: VALUE,
  tableDataLoading: true,
  tableMappingLoading: true,
  tableData: {},
  tableMapping: [],
  locationId: null,
  ids: [],
  initialIds: [],
  groupColumns: [],
  isFirstTime: true,
  isAllowCallApi: true,
  minYear: 0,
  keySearch: '',
  isReCallApi: false,
  limitChartLoading: true,
}

const slice = createSlice({
  name: 'economy/gdp/gdpByProvince/panelGDPProvince',
  initialState,
  reducers: {
    handleValueGrowthNominal: (state, action) => {
      state.valueGrowthNominal = action.payload
    },
    handleValueGrowthReal: (state, action) => {
      state.valueGrowthReal = action.payload
    },
    handleLocationId: (state, action) => {
      state.locationId = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleKeySearch: (state, action) => {
      state.keySearch = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.tableData,
        action.payload,
        state.initialIds,
        0,
      )
    },
    handleIsReCallApi: (state, action) => {
      state.isReCallApi = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPByProvince.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getGDPByProvince.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.tableData = keyByArray(
        data,
        'nationalAccountVNTypeId',
        state.tableData,
        action.meta.arg,
      )
      state.groupColumns = defineGroupColumns(
        data,
        action.meta.arg.NumberOfPeriod,
        state.groupColumns,
      )
      state.minYear = setMinYear(data)
      if (!data?.length) {
        state.isAllowCallApi = false
      }
    })
    builder.addCase(getGDPByProvince.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getGDPTypeName.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getGDPTypeName.fulfilled, (state, action) => {
      state.tableMappingLoading = false
      state.tableMapping = action.payload || []
      state.ids = state.initialIds = handleIds(action.payload)
    })
    builder.addCase(getGDPTypeName.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
    builder.addCase(getMaxPeriod.pending, (state) => {
      state.limitChartLoading = true
    })
    builder.addCase(getMaxPeriod.fulfilled, (state, action) => {
      state.limitChartLoading = false
    })
    builder.addCase(getMaxPeriod.rejected, (state, action) => {
      state.limitChartLoading = action.payload.isLoading
    })
  },
})

export const {
  handleValueGrowthNominal,
  handleLocationId,
  handleIsFirstTime,
  handleIsAllowCallApi,
  handleKeySearch,
  sort,
  handleIsReCallApi,
  handleValueGrowthReal,
} = slice.actions

export const getValueGrowthNominal = (state) =>
  state[slice.name].valueGrowthNominal
export const getValueGrowthReal = (state) => state[slice.name].valueGrowthReal
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getTableData = (state) => state[slice.name].tableData
export const getTableMappingData = (state) => state[slice.name].tableMapping
export const getGDPProvinceValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)
export const getLocationId = (state) => state[slice.name].locationId
export const getIds = (state) => state[slice.name].ids
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIsFirstTime = (state) => state[slice.name].isFirstTime
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getMinYear = (state) => state[slice.name].minYear
export const getKeySearch = (state) => state[slice.name].keySearch
export const getIsReCallApi = (state) => state[slice.name].isReCallApi
export const getLimitChartLoading = (state) =>
  state[slice.name].limitChartLoading

register(slice.name, slice.reducer)
