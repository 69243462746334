import { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import TwoCalendarRangeCustom from '../calendar/TowCalendarRangeCustom'
import HeaderOneCalendarRange from '../calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../calendar/inputs/InputDateCalendar'
import { positionDropdown } from '../dropdown'
import DropdownTooltip from '../textEllipsis/DropdownTooltip'
import DispatchActionTab, { TIME_RANGES } from './DispatchActionTab'
import style from './index.module.css'

const defaultTabs = [
  { title: 'WTD', value: TIME_RANGES.WTD },
  { title: 'MTD', value: TIME_RANGES.MTD },
  { title: 'QTD', value: TIME_RANGES.QTD },
  { title: 'YTD', value: TIME_RANGES.YTD },
  { title: 'common.ALL', value: TIME_RANGES.All },
]

export const calendarTabKey = 'Calendar'

export const DispatchActionTabWithCalendar = ({
  id,
  tabs,
  activeTab,
  startDate,
  endDate,
  isShowCalendar,
  datePreset,
  excludeDateIntervals,
  endYear,
  endMonth,
  onChangeTab,
  onChangeCalendar,
}) => {
  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)

  // Use state
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [selectedStartDate, setSelectedStartDate] = useState(new Date())
  const [selectedEndDate, setSelectedEndDate] = useState(new Date())
  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [position, setPosition] = useState(positionDropdown.BOTTOM_RIGHT_LEFT)

  // Get data
  const getCalendarTooltip = useMemo(
    () =>
      `${formatDateTime(startDate, FORMAT.DATE, locale)} - ${formatDateTime(
        endDate,
        FORMAT.DATE,
        locale,
      )}`,
    [startDate, endDate],
  )

  // Actions
  const handleChangeDates = () => {
    onChangeCalendar({
      activeTab: calendarTabKey,
      startDate: selectedStartDate,
      endDate: selectedEndDate,
    })
    setIsShowDropdown(false)
  }

  const handleChangeTimeRange = (item) => {
    onChangeCalendar({
      activeTab: item,
      startDate: '',
      endDate: '',
    })
    setIsShowDropdown(false)
  }

  // Use effect
  useEffect(() => {
    if (isShowDropdown) {
      const timeout = setTimeout(() => {
        const dropdown = document.getElementById(id)
        const { width } = dropdown.getBoundingClientRect()
        const { right: containerRight } =
          dropdownRef.current.getBoundingClientRect()
        if (
          containerRight < width &&
          containerRight + width > window.innerWidth
        )
          setPosition(positionDropdown.BOTTOM_CENTER)
        else if (containerRight < width)
          setPosition(positionDropdown.BOTTOM_RIGHT_RIGHT)
        else setPosition(positionDropdown.BOTTOM_RIGHT_LEFT)
        clearTimeout(timeout)
      }, 100)
    }
  }, [isShowDropdown])

  useEffect(() => {
    setSelectedStartDate(startDate || new Date())
  }, [startDate])

  useEffect(() => {
    setSelectedEndDate(endDate || new Date())
  }, [endDate])

  // Render
  const renderCustomHeader = HeaderOneCalendarRange({
    startYear: 2010,
    endYear: new Date(
      new Date().setFullYear(new Date().getFullYear() + 30),
    ).getFullYear(),
    zIndex: 15,
  })

  return (
    <>
      <div className={style.tabWithCalendar}>
        <DispatchActionTab
          data={tabs}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
        {isShowCalendar && (
          <div
            ref={dropdownRef}
            onClick={() => setIsShowDropdown(true)}
            style={{
              backgroundColor:
                activeTab === calendarTabKey ||
                !tabs.map((item) => item.value).includes(activeTab)
                  ? '#2a8cd9'
                  : '',
            }}
            onMouseEnter={() => setIsShowTooltip(true)}
            onMouseLeave={() => setIsShowTooltip(false)}
          >
            <img
              src={'/iconCalendar.svg'}
              alt="download"
              width={16}
              height={16}
            />
          </div>
        )}

        {isShowTooltip && activeTab === calendarTabKey && (
          <DropdownTooltip
            containerRef={dropdownRef}
            text={getCalendarTooltip}
            isOpenTooltip={true}
            isI18n={false}
            styleTextTooltip={{
              fontSize: 12,
              fontWeight: 400,
              color: '#ECECEC',
              backgroundColor: '#2a303a',
              letterSpacing: '0.25px',
            }}
            zIndexTooltip={1000}
          />
        )}
      </div>
      {isShowDropdown && (
        <Fragment key={position}>
          <TwoCalendarRangeCustom
            id={id}
            position={position}
            datePreset={datePreset}
            dropdownRef={dropdownRef}
            isShowDropdown={true}
            setIsShowDropdown={setIsShowDropdown}
            active={activeTab}
            selectedEndDate={selectedEndDate}
            selectedStartDate={selectedStartDate}
            setSelectedEndDate={setSelectedEndDate}
            setSelectedStartDate={setSelectedStartDate}
            handleHide={() => {
              setIsShowDropdown(false)
            }}
            handleChangeDates={handleChangeDates}
            handleChangeTimeRange={handleChangeTimeRange}
            excludeDateIntervals={excludeDateIntervals}
            customHeader={renderCustomHeader}
            customInput={InputDateCalendar}
            endYear={endYear}
            endMonth={endMonth}
          />
        </Fragment>
      )}
    </>
  )
}

DispatchActionTabWithCalendar.defaultProps = {
  id: 'twoCalendarRangeCustom',
  tabs: defaultTabs,
  activeTab: defaultTabs[4].value,
  isShowCalendar: true,
  datePreset: defaultTabs,
  excludeDateIntervals: [
    {
      start: new Date(new Date().setFullYear(new Date().getFullYear() + 11)),
      end: new Date(new Date().setFullYear(new Date().getFullYear() + 30)),
    },
  ],
  endYear: new Date().getFullYear() + 10,
  endMonth: new Date().getMonth(),
}
