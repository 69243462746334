import { useSelector } from 'react-redux'
import { CostChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectTimeType } from './store/slice'

export const PanelCost = ({ panelRefs, mapKey, sizes, setSizes, dataType }) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const timeType = useSelector(selectTimeType)

  return (
    <Panel
      title={'sector.financialAnalysis.bank.profitability.COST'}
      panelRefs={panelRefs}
      panelKey={mapKey.COST}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'costChart',
        nameScreen: UseI18n('sector.financialAnalysis.bank.PROFITABILITY'),
        chartName: UseI18n('sector.financialAnalysis.bank.profitability.COST'),
        tabName: timeType,
        tickerName: currentInfo.ticker,
      }}
      titleJpg={UseI18n('sector.financialAnalysis.bank.profitability.COST')}
    >
      <CostChart
        width={formatNumber(sizes[mapKey.COST].width - PANEL_PADDING)}
        height={formatNumber(sizes[mapKey.COST].height - HEADER_PANEL_HEIGHT)}
        dataType={dataType}
      />
    </Panel>
  )
}
