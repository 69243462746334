import { DashboardSetting } from '../../../setting/DashboardSetting'
import { GroupDashboardSetting } from '../../../setting/GroupDashboardSetting'

export const ItemSetting = ({
  data,
  isNewDashboard,
  isHideCollapseIcon,
  isHover,
  setIsHover,
  setIsEdit,
  setIsActive,
  onGroupAddNewDashboard,
}) => {
  return (
    <>
      {!isNewDashboard && (
        <>
          {!isHideCollapseIcon ? (
            <GroupDashboardSetting
              data={data}
              isHover={isHover}
              setIsHover={setIsHover}
              setIsEdit={setIsEdit}
              setIsActive={setIsActive}
              onAddNewDashboard={onGroupAddNewDashboard}
            />
          ) : (
            <DashboardSetting
              data={data}
              isHover={isHover}
              setIsHover={setIsHover}
              setIsEdit={setIsEdit}
              setIsActive={setIsActive}
            />
          )}
        </>
      )}
    </>
  )
}
