import chartLayoutProxy from '../../proxies/common/ChartLayoutProxy'
import { ServiceBase } from '../ServiceBase'

class ChartingService extends ServiceBase {
  createChartLayout(params) {
    return this.getData(() => chartLayoutProxy.createChartLayout(params))
  }

  getChartLayout(params) {
    return this.getData(() => chartLayoutProxy.getChartLayout(params))
  }

  deleteChartLayout(params) {
    return chartLayoutProxy.deleteChartLayout(params)
  }

  getChartLayoutShareInfo(params) {
    return this.getData(() => chartLayoutProxy.getChartLayoutShareInfo(params))
  }

  getSharedChartLayout(params) {
    return this.getData(() => chartLayoutProxy.getSharedChartLayout(params))
  }
}

export default new ChartingService()
