import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { ID_TABLE } from './exportStatisticTable/constants'
import {
  changeActiveItem,
  selectActiveItem,
  selectDataTable,
  setIsFirstTime,
} from './exportStatisticTable/store/slice'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

export const ItemSearch = () => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const listItems = useSelector(selectDataTable)
  const activeItem = useSelector(selectActiveItem)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [eventPressKey, setEventPressKey] = useState()

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  return (
    <div
      ref={exceptOutsideRef}
      className={`form-search-inbox ${style.inputSearch}`}
    >
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        type="text"
        className="search-inbox bg-grey"
        placeholder={activeItem?.name ?? listItems[0]?.name}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        ref={inputRef}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <DropdownItems
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listItems={listItems?.filter((item) =>
          item.name?.toLowerCase().includes(keySearch.toLowerCase()),
        )}
        setKeySearch={setKeySearch}
        eventPressKey={eventPressKey}
      />
    </div>
  )
}

const DropdownItems = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listItems,
  exceptOutsideRef,
  setKeySearch,
}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthItems = listItems.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listItems[activeIndex])
      else if (listItems[0]) handleClickItem(listItems[0])
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthItems - 1) setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthItems - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (value) => {
    const scrollTable = document.querySelector(
      `#${ID_TABLE} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${value.id}"]`)
    if (scrollTable && headerTable && activeRow) {
      dispatch(setIsFirstTime(false))
      scrollTable.scrollTo({
        top: activeRow.offsetTop - headerTable.offsetHeight,
        behavior: 'smooth',
      })
    }

    dispatch(changeActiveItem(value.id))
    handleHide()
    setKeySearch(value.name)
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listItems.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listItems.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(item)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>{item.name}</div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
