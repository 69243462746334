export const genFooterData = (chartData, listLegendCustom = {}) => {
  if (chartData.length > 0) {
    return chartData.map((item) => {
      const data = {
        ...item,
        text: listLegendCustom[item.id] || item.text,
        type: item.type,
        isI18n: item.isI18n,
        before: {
          bgColor: item.color,
        },
        after: {
          bgColor: item.color,
        },
      }
      if (item.dataKey) {
        data.dataKey = item.dataKey
      }
      return data
    })
  }

  return []
}
