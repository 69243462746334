import SelectCustom from '../../../../../common/selectCustom'
import { TRANSACTION_TYPE_OPTIONS } from '../../constants'

export const Transaction = ({ value, onChange }) => {
  return (
    <div style={{ width: 150 }}>
      <SelectCustom
        value={value}
        isI18n={true}
        selectData={TRANSACTION_TYPE_OPTIONS}
        handleChange={onChange}
      />
    </div>
  )
}
