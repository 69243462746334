import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { debounce } from '../../../utils/Common'
import { useGetNameSecuritiesIndicator } from '../../chart/common/useGetNameSecuritiesIndicator'
import { VIEW_TYPE } from '../../chart/constants'
import { useSettingChartData } from '../../chart/settingChart/useSettingChartData'
import { selectIndicators } from '../../store/slice'
import {
  formatData as formatChartData,
  formatChartSchemaBySecurities,
  formatChartSchemaByTime,
} from '../helpers'
import { getChartTimeRange } from '../helpers/chartHelpers'
import {
  selectDataChartBySecurities,
  selectDataChartByTime,
  selectDataChartEquation,
} from './store/slice'

const useFeatureChart = (item, isDashboardView = false) => {
  const timeZone = UseTimeZone()
  const ref = useRef({ schema: {} })

  const { getNameSecuritiesIndicator, getIndicator } =
    useGetNameSecuritiesIndicator()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const dataChartBySecurities = useSelector(
    selectDataChartBySecurities(item.id),
  )
  const dataChartByTime = useSelector(selectDataChartByTime(item.id))
  const dataChartEquation = useSelector(selectDataChartEquation(item.id))
  const indicators = useSelector(selectIndicators)

  // Use state
  const [schema, setSchema] = useState()
  const [footerData, setFooterData] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  // Get data
  const { chartData, fullDataByTimeDataKey } = useSettingChartData({
    chartSettings: item?.detail,
    dataChartByTime,
    dataChartBySecurities,
    dataChartEquation,
    isDashboardView,
  })

  const getDataTimeFrequency = () => {
    const { timeFrequency } = item?.detail
    return isDashboardView && item.detail.viewType === VIEW_TYPE.BY_TIME
      ? timeFrequency
      : ''
  }

  // Actions
  const handleChangeSchema = useCallback(
    debounce(
      (
        itemDetail,
        data,
        timeZone,
        locale,
        getNameSecuritiesIndicator,
        getIndicator,
      ) => {
        let newChartSchema = schema

        setLoading(true)

        if (itemDetail.viewType === VIEW_TYPE.BY_SECURITIES) {
          const { chartSchema, chartFooter } = formatChartSchemaBySecurities({
            treeSecuritiesIndicator: itemDetail.treeSecuritiesIndicator,
            equations: itemDetail.equations,
            settings: itemDetail.settings,
            getNameSecuritiesIndicator,
            listLegendCustom: itemDetail.listLegendCustom,
            listYAxisCustom: itemDetail.listYAxisCustom,
            getIndicator,
            locale,
          })

          if (JSON.stringify(chartFooter) !== JSON.stringify(footerData)) {
            setFooterData(chartFooter)
          }

          if (JSON.stringify(schema) !== JSON.stringify(chartSchema)) {
            setSchema(chartSchema)
          }

          newChartSchema = chartSchema
        } else {
          const { chartSchema, chartFooter } = formatChartSchemaByTime({
            treeSecuritiesIndicator: itemDetail.treeSecuritiesIndicator,
            equations: itemDetail.equations,
            settings: itemDetail.settings,
            getNameSecuritiesIndicator,
            listLegendCustom: itemDetail.listLegendCustom,
            listYAxisCustom: itemDetail.listYAxisCustom,
            getIndicator,
            data,
            timeZone,
            timeRange: getChartTimeRange(
              itemDetail.timeRange,
              itemDetail.timeRangeDate,
            ),
            timeFrequency: itemDetail.timeFrequency,
            locale,
          })

          if (JSON.stringify(chartFooter) !== JSON.stringify(footerData)) {
            setFooterData(chartFooter)
          }

          if (JSON.stringify(schema) !== JSON.stringify(chartSchema)) {
            setSchema(chartSchema)
          }

          newChartSchema = chartSchema
        }

        if (
          JSON.stringify(
            schema?.charts?.sort((a, b) =>
              (a.dataKey || '').localeCompare(b.dataKey || ''),
            ),
          ) ===
          JSON.stringify(
            newChartSchema?.charts?.sort((a, b) =>
              (a.dataKey || '').localeCompare(b.dataKey || ''),
            ),
          )
        ) {
          setLoading(false)
        }
      },
      100,
    ),
    [schema],
  )

  // Use effect
  useEffect(() => {
    if (indicators.length) {
      handleChangeSchema(
        item.detail,
        data,
        timeZone,
        locale,
        getNameSecuritiesIndicator,
        getIndicator,
      )
    }
  }, [
    locale,
    timeZone,
    indicators.length,
    JSON.stringify(data),
    JSON.stringify(item?.detail?.securities),
    getNameSecuritiesIndicator,
    getIndicator,
  ])

  useEffect(() => {
    if (
      schema &&
      JSON.stringify(schema?.charts) !==
        JSON.stringify(ref.current.schema.charts)
    ) {
      let data = []

      if (chartData.length && schema && schema.charts?.length) {
        data = formatChartData(
          chartData,
          fullDataByTimeDataKey,
          schema.charts,
          item.detail.viewType,
          getDataTimeFrequency(),
          timeZone,
        )
      }

      setData(data)

      if (!chartData.length) {
        setLoading(false)
      }
    }
  }, [JSON.stringify(schema?.charts), JSON.stringify(chartData), timeZone])

  return {
    formatData: data,
    fullDataByTimeDataKey,
    schema,
    footerData,
    loading,
  }
}

export default useFeatureChart
