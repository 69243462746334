import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../../utils/Export'

export const getForeignOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/getForeignOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getForeignOverview(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getForeignDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/getForeignDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getForeignDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getForeignTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/getForeignTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getForeignTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCoverWarrants = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/getCoverWarrants',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getCoverWarrants(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadForeignOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/downloadForeignOverview',
  async (data) => {
    const response = await CoveredWarrantService.downloadForeignOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadForeignDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/downloadForeignDetail',
  async (data) => {
    const response = await CoveredWarrantService.downloadForeignDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadForeignTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/foreign/downloadForeignTicker',
  async (data) => {
    const response = await CoveredWarrantService.downloadForeignTicker(data)
    handleExport(response.data, response.filename)
  },
)
