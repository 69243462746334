import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { selectIsShowChart } from '../header/store/slice'
import { selectMaxMinValueByColId } from '../store/slice'
import style from './index.module.css'

const TornadoTwoSide = ({ value, percentWidth, displayText }) => {
  return (
    <>
      {value < 0 ? (
        <div className={`${style.w50}`}>
          <div
            className={style.barLost}
            style={{
              width: `${percentWidth}%`,
            }}
          >
            <span className={style.textLost}>{displayText}</span>
          </div>
        </div>
      ) : (
        <div className={style.w50} />
      )}

      {value >= 0 ? (
        <div className={`${style.w50}`}>
          <div
            className={style.barGain}
            style={{
              width: `${percentWidth}%`,
            }}
          >
            <span className={style.textGain}>{displayText}</span>
          </div>
        </div>
      ) : (
        <div className={style.w50} />
      )}
    </>
  )
}

const TornadoOneSide = ({ percentWidth, displayText }) => {
  return (
    <div className={style.barGain} style={{ width: `${percentWidth}%` }}>
      <span className={style.textGain}>{displayText}</span>
    </div>
  )
}

const TdCellTornado = ({
  value,
  colId,
  isPercent,
  transformValue,
  isFormatPositiveValue,
  isTwoSide,
}) => {
  const isShowChart = useSelector(selectIsShowChart)
  const maxMin = useSelector(selectMaxMinValueByColId(colId))

  const [percentWidth, setPercentWidth] = useState(0)

  useEffect(() => {
    if (maxMin && Object.keys(maxMin).length) {
      const { max, min } = maxMin
      setPercentWidth(
        Math.abs((value / Math.max(Math.abs(max), Math.abs(min))) * 100) || 0,
      )
    }
  }, [value, maxMin])

  const displayText = isPercent
    ? valToPercent(value)
    : formatVal(transformValue(value))

  const getTextColor = () =>
    value < 0 ? '#ff4752' : isFormatPositiveValue ? '#2de352' : 'inherit'

  if (value === EMPTY_VALUE) return EMPTY_VALUE

  return (
    <div>
      {isShowChart && Object.keys(maxMin).length ? (
        <div className="d-flex w-100 h-20" style={{ minWidth: 120 }}>
          {isTwoSide ? (
            <TornadoTwoSide
              value={value}
              percentWidth={percentWidth}
              displayText={displayText}
            />
          ) : (
            <TornadoOneSide
              percentWidth={percentWidth}
              displayText={displayText}
            />
          )}
        </div>
      ) : (
        <div style={{ textAlign: 'right', color: getTextColor() }}>
          {displayText}
        </div>
      )}
    </div>
  )
}

TdCellTornado.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isPercent: PropTypes.bool,
  transformValue: PropTypes.func,
  isFormatPositiveValue: PropTypes.bool,
  isTwoSide: PropTypes.bool,
}

TdCellTornado.defaultProps = {
  isPercent: true,
  transformValue: (val) => val,
  isFormatPositiveValue: false,
  isTwoSide: true,
}

export default TdCellTornado
