import PropTypes from 'prop-types'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import BarPointChart from '../charts/barPointChart/BarPointChart'
import PieChart from '../charts/pieChart/PieChart'
import StackedBarChart from '../charts/stackedBarChart/StackedBarChart'
import { chartTypes } from '../constants'

const ChartContainer = ({
  keyChart,
  sizes,
  panelKey,
  chartType,
  activeTab,
}) => {
  switch (keyChart) {
    case chartTypes.COLUMN_CHART:
      return (
        <BarPointChart
          width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
          height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
          chartType={chartType}
        />
      )
    case chartTypes.PIE_CHART:
      return (
        <PieChart
          width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
          height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
          chartType={chartType}
        />
      )
    case chartTypes.STACKED_BAR_CHART:
      return (
        <StackedBarChart
          width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
          height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
          chartType={chartType}
          activeTab={activeTab}
        />
      )
    default:
      return <></>
  }
}

ChartContainer.propTypes = {
  key: PropTypes.string,
  sizes: PropTypes.object,
  panelKey: PropTypes.string,
  chartType: PropTypes.string,
}

export default ChartContainer
