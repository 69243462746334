import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../../../utils/Common'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../../utils/Value'
import { chartYearOptions, quarterTypeOptions } from '../../../../constants'
import { companyOptions } from '../../constants'
import {
  getFVTPLListedShareDecompositionAll,
  getFVTPLListedShareDecompositionDetail,
  getOrganizationPortfolio,
} from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/security/assetStructure/fvtplDecomposition',
  initialState: {
    tableIds: [],
    tableDataByIds: {},
    tableData: [],
    chartData: [],
    tickerData: [],
    tableLoading: true,
    chartLoading: true,
    companyType: companyOptions[0].value,
    yearType: chartYearOptions[0].value,
    quarterType: quarterTypeOptions[0].value,
    retryFlag: true,
  },
  reducers: {
    changeCompanyType: (state, action) => {
      state.companyType = action.payload
    },
    changeYearType: (state, action) => {
      state.yearType = action.payload
    },
    changeQuarterType: (state, action) => {
      state.quarterType = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    // getFVTPLListedShareDecompositionAll
    builder.addCase(getFVTPLListedShareDecompositionAll.pending, (state) => {
      state.chartLoading = true
      state.tableLoading = true
    })
    builder.addCase(
      getFVTPLListedShareDecompositionAll.fulfilled,
      (state, action) => {
        state.chartData = action.payload
        const data = [...action.payload]
          .sort((a, b) => b.fairValue - a.fairValue)
          .filter((_, index) => index < 10)
        state.tableData = data
        state.tableDataByIds = keyBy(data, 'ticker')
        state.tableIds = data.map((v) => v.ticker)
        state.tableLoading = false
        state.chartLoading = false
      },
    )
    builder.addCase(
      getFVTPLListedShareDecompositionAll.rejected,
      (state, action) => {
        state.chartLoading = action.payload.loading
        state.tableLoading = action.payload.loading
      },
    )
    // getFVTPLListedShareDecompositionDetail
    builder.addCase(getFVTPLListedShareDecompositionDetail.pending, (state) => {
      state.chartLoading = true
      state.tableLoading = true
    })
    builder.addCase(
      getFVTPLListedShareDecompositionDetail.fulfilled,
      (state, action) => {
        state.chartData = action.payload
        const data = [...action.payload]
          .sort((a, b) => b.fairValue - a.fairValue)
          .filter((_, index) => index < 10)
        state.tableData = data
        state.tableDataByIds = keyBy(data, 'ticker')
        state.tableIds = data.map((v) => v.ticker)
        state.tableLoading = false
        state.chartLoading = false
      },
    )
    builder.addCase(
      getFVTPLListedShareDecompositionDetail.rejected,
      (state, action) => {
        state.chartLoading = action.payload.loading
      },
    )
    // getOrganizationsByIcb
    builder.addCase(getOrganizationPortfolio.fulfilled, (state, action) => {
      const currentTicker = state.tickerData.find(
        (item) => item.organizationId === state.companyType,
      )
      const isExist = action.payload.some(
        (item) =>
          currentTicker && item.organizationId === currentTicker.organizationId,
      )
      if (!currentTicker || isExist) {
        state.tickerData = action.payload
      } else {
        state.tickerData = [currentTicker, ...action.payload]
      }
    })
  },
})

export const selectRetryFlag = (state) => state[slice.name].retryFlag
export const selectYearType = (state) => state[slice.name].yearType
export const selectQuarterType = (state) => state[slice.name].quarterType
export const selectCompanyType = (state) => state[slice.name].companyType
export const selectChartData = (state) => state[slice.name].chartData
export const selectTableData = (state) => state[slice.name].tableData
export const selectTickerData = (state) => state[slice.name].tickerData
export const selectTableIds = (state) => state[slice.name].tableIds
export const selectListTableDataByIds = (state) =>
  state[slice.name].tableDataByIds
export const selectTableDataByIds = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableDataByIds[colId], attr)
export const selectChartLoading = (state) => state[slice.name].chartLoading
export const selectTableLoading = (state) => state[slice.name].tableLoading
export const {
  changeCompanyType,
  changeQuarterType,
  changeYearType,
  changeRetryFlag,
} = slice.actions

register(slice.name, slice.reducer)
