import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import Header from './Header'
import style from './index.module.css'
import {
  getFromDate,
  getReverseSellTab,
  getTableData,
  getTableDataLoading,
  getToDate,
} from './store/slice'
import { getBiddingResultsOnTheOpenMarket, getMaxDate } from './store/thunk'
import TableComponent from './TableComponent'

const TableOpenMarketAuction = ({ height }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const previousYear = new Date()
  previousYear.setFullYear(previousYear.getFullYear() - 1)

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableData = useSelector(getTableData)
  const reverseSellTab = useSelector(getReverseSellTab)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(
      getBiddingResultsOnTheOpenMarket({
        MoneyMarketOperationsVNTypeId: reverseSellTab,
        DateFrom: fromDate
          ? getISOStartOrEndOfDay(fromDate, timeZone, true)
          : previousYear,
        DateTo: toDate
          ? getISOStartOrEndOfDay(toDate, timeZone, true)
          : getISOStartOrEndOfDay(new Date(), timeZone, true),
      }),
    )
  }, [locale, reverseSellTab, fromDate, toDate])

  useEffect(() => {
    dispatch(getMaxDate())
  }, [])

  return (
    <div className="flex-column h-100">
      <Header />
      <div className={style.wrapTable}>
        {tableDataLoading && <Loading />}
        {!tableDataLoading && (
          <>
            {Object.keys(tableData).length === 0 && <NoData />}
            {Object.keys(tableData).length !== 0 && (
              <TableComponent
                ids={Object.keys(tableData)}
                tableData={tableData}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default TableOpenMarketAuction
