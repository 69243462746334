export const mappingDisplayName = {
  closePrice: 'corporate.peerValuation.consensus.ACTUAL_PRICE',
  highestPrice: 'corporate.peerValuation.consensus.TARGET_PRICE',
  buyPercentage: 'corporate.peerValuation.consensus.BUY_PERCENTAGE',
  sellPercentage: 'corporate.peerValuation.consensus.SELL_PERCENTAGE',
  holdPercentage: 'corporate.peerValuation.consensus.HOLD_PERCENTAGE',
  neutralPercentage: 'corporate.peerValuation.consensus.NEUTRAL_PERCENTAGE',
  noRecommendationPercentage:
    'corporate.peerValuation.consensus.NO_RECOMMENDATION_PERCENTAGE',
}

export const fillColor = {
  closePrice: '#47c3ff',
  highestPrice: '#facc5c',
  buyPercentage: '#3da967',
  sellPercentage: '#e0505b',
  holdPercentage: '#5aea78',
  neutralPercentage: '#fdf0a5',
  noRecommendationPercentage: '#f58d04',
}

export const xAxisKey = 'xLabel'

export const barKeys = [
  'buyPercentage',
  'holdPercentage',
  'neutralPercentage',
  'noRecommendationPercentage',
  'sellPercentage',
]

export const lineKeys = ['closePrice', 'highestPrice']
