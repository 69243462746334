import TextEllipsis from '../../../common/textEllipsis'
import { valToPercent } from '../../../utils/Value'
import styles from '../index.module.css'
import { TOTAL_ID } from './constants'

export const LightBorderColumnCell = ({
  val,
  rowId,
  isAlignTextLeft,
  styleProps,
  className,
  isStickyBorderRight,
  isText,
  isPercentage,
}) => {
  return (
    <td
      className={`${styles.borderRightColumnCellTd} ${
        isAlignTextLeft ? styles.alignTextLeft : styles.alignTextRight
      } ${
        rowId === TOTAL_ID || isStickyBorderRight
          ? styles.stickyBorderRight
          : ''
      } ${className}`}
      style={{ ...styleProps }}
    >
      {isText ? (
        <TextEllipsis text={val} />
      ) : isPercentage ? (
        <div
          style={{
            color: val < 0 ? '#ff4752' : '',
          }}
        >
          {valToPercent(val)}
        </div>
      ) : (
        <>{val}</>
      )}
    </td>
  )
}
