import { TYPE } from '../../../../common/chart/footer/Item'

export const tableChartSwitch = {
  TABLE: 'table',
  HORIZONTAL_STACK_BAR: 'horizontalStackBar',
}

export const timeSalesDateFilter = [
  { title: '1D', value: 'OneDay' },
  { title: '5D', value: 'OneWeek' },
  { title: '10D', value: 'TwoWeeks' },
  { title: '20D', value: 'OneMonth' },
]

export const indicator = {
  TIME: 'tradingDate',
  TYPE: 'matchType',
  LAST_PRICE: 'matchPrice',
  CHANGE: 'priceChange',
  PERCENT_CHANGE: 'percentPriceChange',
  MATCH_VOLUME: 'matchVolume',
  TOTAL_MATCH_VOLUME: 'totalMatchVolume',
}

export const listIconBarChart = [
  {
    text: 'market.coveredWarrant.timeAndSales.BU_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#3da967',
    },
  },
  {
    text: 'market.coveredWarrant.timeAndSales.SD_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#e0505b',
    },
  },
  {
    text: 'market.coveredWarrant.timeAndSales.UNIDENTIFIED',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#32a6e8',
    },
  },
]
