import { titleData } from '../../common/helper'

export const convertData = (data, timeFrequency) => {
  let objList = {}
  const result = []
  data.forEach(
    (item) =>
      (objList[titleData(item.month, item.year, timeFrequency)] = {
        ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
        [item.locationId]: item.value,
      }),
  )

  Object.keys(objList).map((key) =>
    result.push({
      name: key,
      ...objList[key],
    }),
  )
  return result
}
