import PropTypes from 'prop-types'
import { Layout } from '.'
import MenuDashboard from '../menuDashboard'

export const LayoutWithMenu = ({ children }) => {
  return (
    <Layout>
      <div className="d-flex h-100">
        <MenuDashboard />
        <div style={{ width: `calc(100% - 252px)` }}>{children}</div>
      </div>
    </Layout>
  )
}

LayoutWithMenu.propTypes = {
  children: PropTypes.node.isRequired,
}
