import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { formatValNumber, getFormatDateTime } from '../../helps'
import ColorCellType from '../ColorCell'
import { TABLE_TYPE } from '../constants'
import { priceDataTableCol, tableFormatValue } from './constants'

const getValue = (val, colId) => {
  return val / tableFormatValue[colId]
}

export const getTableSchema = (
  activeType,
  timeFilter,
  locale,
  dataByIds,
  timeZone,
) =>
  Object.keys(priceDataTableCol[activeType])
    .filter(
      (key) =>
        !(
          activeType === TABLE_TYPE.DETAIL &&
          priceDataTableCol[activeType][key] ===
            priceDataTableCol.detail.DATE &&
          timeFilter === PRICE_DATA_TIME_SELECT.CUSTOM
        ),
    )
    .map((key, index) => {
      const title = `market.marketInDepth.indexStatistics.priceData.orderStatistic.${key}`
      const colId = priceDataTableCol[activeType][key]
      const result = {
        colId,
        title,
      }

      if (
        ![
          priceDataTableCol[activeType].DATE,
          priceDataTableCol.detail.TICKER,
        ].includes(colId)
      ) {
        const customSchema = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            fontSize: 10,
            fontWeight: 'bold',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }

        if (colId === priceDataTableCol[activeType].NET_VOL) {
          return {
            ...customSchema,
            render: (val) => {
              return (
                <ColorCellType
                  val={getValue(val, colId)}
                  format={(val) => formatValNumber(val)}
                />
              )
            },
          }
        }

        return {
          ...customSchema,
          render: (val) => formatValNumber(getValue(val, colId)),
        }
      }

      const customSchema = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 10,
          fontWeight: 'bold',
        },
        tdStyle: {
          textAlign: 'left',
        },
      }

      if (colId === priceDataTableCol.detail.TICKER) {
        return {
          ...customSchema,
          disableSort: true,
          render: (val, rowId) => (
            <LinkTicker organizationId={dataByIds[rowId]?.organizationId}>
              {val}
            </LinkTicker>
          ),
        }
      }

      if (colId === priceDataTableCol.overview.DATE) {
        switch (timeFilter) {
          case PRICE_DATA_TIME_SELECT.DAILY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.DATE'
            break
          case PRICE_DATA_TIME_SELECT.WEEKLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.WEEK'
            break
          case PRICE_DATA_TIME_SELECT.MONTHLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.MONTH'
            break
          case PRICE_DATA_TIME_SELECT.QUARTERLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.QUARTER'
            break
          case PRICE_DATA_TIME_SELECT.YEARLY:
            customSchema.title =
              'market.marketInDepth.indexStatistics.priceData.YEAR'
            break
          default:
            break
        }
        return {
          ...customSchema,
          render: (val) => {
            const date = getFormatDateTime(val, timeFilter, locale, timeZone)
            return (
              <div style={{ width: 'max-content' }}>
                {date === EMPTY_VALUE ? val : date}
              </div>
            )
          },
        }
      }

      return customSchema
    })
