import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class LayePaymentProxy extends ProxyBase {
  getDataChart(params) {
    return this.get('Overview/LatePayment', params)
  }
}

export default new LayePaymentProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
