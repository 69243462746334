import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../../common/textEllipsis'
import { listTab } from './constants'
import { selectActive, updateTab } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()
  const activeTab = useSelector(selectActive)

  const onChangeTab = (item) => {
    dispatch(updateTab(item.value))
  }
  return (
    <div className="d-flex ali-center group-btn mb-8">
      <div>
        <DispatchActionTab
          onChangeTab={(e) => onChangeTab(e)}
          data={listTab}
          activeTab={activeTab}
          textSize={11}
        />
      </div>
      <div>
        <Span
          style={{
            fontSize: 11,
            fontWeight: 400,
            fontStyle: 'italic',
            color: '#85939C',
          }}
        >
          <TextEllipsis
            text={'bond.bondSecurity.tradingInformation.filter.note'}
          />
        </Span>
      </div>
    </div>
  )
}

export default Filter
