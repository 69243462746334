import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import TextEllipsis from '../../../common/textEllipsis'
import { getMaxDate } from '../averageTradingValue/store/thunk'
import { QuarterAndYearSelection } from '../common/QuarterAndYearSelection'
import {
  changeCurrentQuarter,
  changeCurrentYear,
  selectFilter,
} from '../tableMarginGrowth/store/slice'
import { ChartComponent } from './ChartComponent'
import {
  selectDataChart,
  selectLoading,
  selectMaxDate,
  setLoadingChart,
} from './store/slice'
import { getMarginLeverageRatio } from './store/thunk'

const HEIGHT_NOTE = 20

export const LeverageRatio = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const handleYearChange = (item) => {
    dispatch(changeCurrentYear(item))
    if (currentYear !== item) {
      dispatch(setLoadingChart(true))
    }
  }

  const handleQuarterChange = (item) => {
    dispatch(changeCurrentQuarter(item))
    if (currentQuarter !== item) {
      dispatch(setLoadingChart(true))
    }
  }

  const currentQuarter = filter?.currentQuarter || maxDate?.quarter
  const currentYear = filter?.currentYear || maxDate?.year

  useEffect(() => {
    dispatch(getMaxDate({}))
  }, [])

  useEffect(() => {
    if (currentQuarter && currentYear) {
      dispatch(
        getMarginLeverageRatio({
          RealQuarter: currentQuarter,
          RealYear: currentYear,
        }),
      )
    }
  }, [currentQuarter, currentYear, locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <QuarterAndYearSelection
              handleYearChange={handleYearChange}
              handleQuarterChange={handleQuarterChange}
              currentYear={currentYear}
              currentQuarter={currentQuarter}
            />
            {size.height &&
              (isLoading ? (
                <Loading />
              ) : !dataChart.length ? (
                <NoData />
              ) : (
                <div
                  style={{ height: height - size.height - HEIGHT_NOTE }}
                  id="leverageRatio"
                >
                  <ChartComponent
                    height={height - size.height - HEIGHT_NOTE}
                    width={width}
                    data={dataChart}
                    keyXAxis={'time'}
                    lineKey={'ratio'}
                    barKey={'margin'}
                    currentQuarter={currentQuarter}
                  />
                  <Span style={{ color: '#75797f' }}>
                    <TextEllipsis
                      text="market.marginLoans.NOTE_MARGIN_LEVERAGE"
                      className="canvas-chart-title"
                    />
                  </Span>
                </div>
              ))}
          </>
        )}
      </SizeTracker>
    )
  )
}
