import { ChartContainer } from '../../../common/ChartContainer'
import { StackedBarLineChart } from '../../../common/StackedBarLineChart'
import {
  chartBarKeys,
  chartFillColor,
  chartLineKeys,
  chartMappingDisplayName,
} from './constants'

export const ChartComponent = ({ data, width, height, loading }) => {
  return (
    <div style={{ height: height || 0 }} className="position-relative">
      <ChartContainer
        id="nonInterestChartContainer"
        data={data || []}
        height={height}
        width={width}
        isLoading={loading}
      >
        {(resizeSize) => (
          <StackedBarLineChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            barKeys={chartBarKeys}
            lineKeys={chartLineKeys}
            unit="sector.financialAnalysis.common.BILLION"
            lineUnit="sector.financialAnalysis.common.PERCENT"
            xAxisKey="label"
            fillColor={chartFillColor}
            mappingDisplayName={chartMappingDisplayName}
            leftLabel="sector.financialAnalysis.common.CHART_BILLION_VND"
            isYAxisPercentRight={true}
          />
        )}
      </ChartContainer>
    </div>
  )
}
