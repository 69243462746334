import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'
import { selectFilterValueChart } from '../tradingStatistics/store/slice'

const ChartTooltip = ({ dataChart }) => {
  const { type } = useSelector(selectFilterValueChart)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {dataChart.organizationShortName} &nbsp;
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.valueChart.VALUE" />:
        </Span>

        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          {type === 'duration' ? (
            <Translate value="bond.corporateBond.valueChart.DURATION_AVERAGE" />
          ) : (
            <Translate value="bond.corporateBond.valueChart.AVERAGE_YTM" />
          )}
          :
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(
              type === 'duration'
                ? dataChart.averageDuration
                : dataChart.averageYTM,
            )}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {type === 'duration' ? (
              <Translate value="bond.corporateBond.valueChart.MONTH" />
            ) : (
              '%'
            )}
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    organizationShortName: PropTypes.string.isRequired,
    averageDuration: PropTypes.number.isRequired,
    averageYTM: PropTypes.number.isRequired,
  }).isRequired,
}

export default ChartTooltip
