import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  downloadChart,
  selectDownloadChartLoading,
} from '../common/chartInPanel/store/slice'
import { Layout } from '../common/layout'
import PreviewChartCommon from '../common/previewChart'
import styles from './index.module.css'

export const PreviewChart = ({ children }) => {
  const dispatch = useDispatch()

  const downloadChartLoading = useSelector(selectDownloadChartLoading)

  const [isPreviewChart, setIsPreviewChart] = useState(false)
  const [dataChartPreview, setDataChartPreview] = useState({})

  const isHaveDataChartPreview = useMemo(() => {
    return Object.keys(dataChartPreview).length > 0
  }, [dataChartPreview])

  const handlePreviewChart = (data) => {
    setIsPreviewChart(true)
    setDataChartPreview(data)
  }

  const handleDownloadChart = () => {
    dispatch(downloadChart({ id: dataChartPreview.id }))
  }

  const renderChildren = useMemo(() => {
    return children(handlePreviewChart, isHaveDataChartPreview)
  }, [children, isHaveDataChartPreview])

  return (
    <div className="position-relative h-100 w-100">
      {renderChildren}
      {isPreviewChart && (
        <div className={styles.previewChartLayoutContainer}>
          {downloadChartLoading && (
            <div className={styles.downloadContainer}>
              <Translate value="financialChart.DOWNLOADING" />
            </div>
          )}
          <Layout>
            <PreviewChartCommon
              setPreviewChart={setIsPreviewChart}
              dataChartPreview={dataChartPreview}
              onDownloadChart={handleDownloadChart}
            />
          </Layout>
        </div>
      )}
    </div>
  )
}
