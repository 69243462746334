import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { MONTH_OPTIONS, YEAR_OPTIONS } from './constant'
import style from './index.module.css'
import {
  getFromMonth,
  getFromYear,
  getToMonth,
  getToYear,
  handleFromMonth,
  handleFromYear,
  handleIsAllowCallApi,
  handleIsFirstTime,
  handleToMonth,
  handleToYear,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const fromMonth = useSelector(getFromMonth)
  const fromYear = useSelector(getFromYear)
  const toMonth = useSelector(getToMonth)
  const toYear = useSelector(getToYear)

  const changeFromMonth = (fromMonth) => {
    resetTableData()
    dispatch(handleFromMonth(fromMonth))
  }

  const changeFromYear = (fromYear) => {
    resetTableData()
    dispatch(handleFromYear(fromYear))
  }

  const changeToMonth = (toMonth) => {
    resetTableData()
    dispatch(handleToMonth(toMonth))
  }

  const changeToYear = (toYear) => {
    resetTableData()
    dispatch(handleToYear(toYear))
  }

  const resetTime = () => {
    resetTableData()
    dispatch(handleFromMonth(undefined))
    dispatch(handleFromYear(undefined))
    dispatch(handleToMonth(undefined))
    dispatch(handleToYear(undefined))
  }

  const resetTableData = () => {
    dispatch(handleIsFirstTime(true))
  }

  useEffect(() => {
    if (fromMonth && fromYear && toMonth && toYear) {
      dispatch(handleIsAllowCallApi(false))
    }
  }, [fromMonth, fromYear, toMonth, toYear])

  return (
    <div className="d-flex align-center mb-8">
      <Placeholder value="economy.monetary.monetary.FROM" />
      <div className={style.selectBoxMonth}>
        <SelectCustom
          isI18n={true}
          selectData={MONTH_OPTIONS}
          value={fromMonth}
          handleChange={changeFromMonth}
          appendStyle={{ fontWeight: 'normal' }}
          textPlaceholder={
            <Placeholder value={'economy.monetary.monetary.MONTH'} />
          }
        />
      </div>
      <div className={style.selectBoxYear}>
        <SelectCustom
          isI18n={false}
          selectData={YEAR_OPTIONS}
          value={fromYear}
          handleChange={changeFromYear}
          appendStyle={{ fontWeight: 'normal' }}
          textPlaceholder={
            <Placeholder value={'economy.monetary.monetary.YEAR'} />
          }
        />
      </div>
      <Placeholder value="economy.monetary.monetary.TO" />
      <div className={style.selectBoxMonth}>
        <SelectCustom
          isI18n={true}
          selectData={MONTH_OPTIONS}
          value={toMonth}
          handleChange={changeToMonth}
          appendStyle={{ fontWeight: 'normal' }}
          textPlaceholder={
            <Placeholder value={'economy.monetary.monetary.MONTH'} />
          }
        />
      </div>
      <div className={`${style.selectBoxYear} ${style.selectBoxToYear}`}>
        <SelectCustom
          isI18n={false}
          selectData={YEAR_OPTIONS}
          value={toYear}
          handleChange={changeToYear}
          appendStyle={{ fontWeight: 'normal' }}
          textPlaceholder={
            <Placeholder value={'economy.monetary.monetary.YEAR'} />
          }
        />
      </div>
      <button className={style.buttonTrack} onClick={resetTime}>
        <i className="icon-trash" style={{ fontSize: 12 }} />
      </button>
    </div>
  )
}

export default Header

const Placeholder = ({ value }) => {
  return (
    <Span style={{ opacity: 0.4 }}>
      <Translate value={value} />
    </Span>
  )
}
