import { useSelector } from 'react-redux'
import { selectStockInfoById } from '../../../../../common/masterData/store/slice'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from './index.module.css'

const SelectedTickerName = ({ organizationId }) => {
  const ticker = useSelector(selectStockInfoById(organizationId, 'ticker'))
  const taxCode = useSelector(selectStockInfoById(organizationId, 'taxCode'))
  const companyName = useSelector(
    selectStockInfoById(organizationId, 'organizationName'),
  )

  return (
    <div className={style.tickeNameItem}>
      <div
        style={{
          width: '25%',
          whiteSpace: 'nowrap',
        }}
      >
        <TextEllipsis isI18n={false} text={ticker || taxCode} />
      </div>
      <div className={style.tickerCompanyName}>
        <TextEllipsis isI18n={false} text={companyName} />
      </div>
    </div>
  )
}

export default SelectedTickerName
