import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TopInfo } from '../../../common/topInfo/indexTopInfo'
import Content from './Content'

const Foreign = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo title="constants.route.market.marketInDepth.foreign.LABEL" />
              {size.height && (
                <div
                  className="row"
                  style={{
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                    position: 'relative',
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default Foreign
