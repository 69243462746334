export const chartFillColor = {
  rT0273: '#df5a49',
  rT0274: '#45b29d',
}

export const chartLeftKeys = ['rT0273', 'rT0274']

export const chartMappingDisplayName = {
  rT0273: 'sector.financialAnalysis.bank.assetQuality.PROVISION_NPL',
  rT0274: 'sector.financialAnalysis.bank.assetQuality.PROVISION_NPL_SML',
}

export const chartFormat = {
  rT0273: 0.01,
  rT0274: 0.01,
}
