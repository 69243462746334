import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/sectorTopInfo'
import List from './list'

export const NewsReport = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo title="constants.route.sector.sectorConstituents.newsReport.LABEL" />
              {size.height && (
                <div
                  className="w-100 position-relative"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <List />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
