import React, { useRef, useState } from 'react'
import { SizeTracker } from '../../../common/sizeTracker'
import { useResize } from '../useResize'
import ContentChart from './ContentChart'
import FooterPreviewChart from './FooterPreviewChart'
import Header from './Header'

const PreviewChart = ({
  dataChartPreview,
  setPreviewChart,
  onDownloadChart,
}) => {
  const chartRef = useRef(null)
  const wrapperChartRef = useRef(null)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  const [isShowTable, setIsShowTable] = useState(false)

  const dimensionsWrapperChart = useResize(wrapperChartRef)

  const handleToggleShowTable = () => {
    setIsShowTable(!isShowTable)
  }

  return (
    <div className="h-100" style={{ backgroundColor: '#f0f8ff' }}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Header
                dataChartPreview={dataChartPreview}
                setPreviewChart={setPreviewChart}
                changeShowTable={handleToggleShowTable}
                onDownloadChart={onDownloadChart}
              />
              {size.height && (
                <div
                  ref={wrapperChartRef}
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <ContentChart
                    ref={chartRef}
                    dimensions={dimensions}
                    dimensionsWrapperChart={dimensionsWrapperChart}
                    dataChartPreview={dataChartPreview}
                    isShowTable={isShowTable}
                  />
                </div>
              )}
              <FooterPreviewChart
                width={chartRef.current?.offsetWidth}
                height={chartRef.current?.offsetHeight}
                setDimensions={setDimensions}
                isShowTable={isShowTable}
              />
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default PreviewChart
