import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import { SelectSearchMultiple } from '../../../../../common/selectCustom/SelectSearchMultiple'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectTickers } from '../store/slice'

export const FundTicker = ({ value, onChange }) => {
  const tickers = useSelector(selectTickers)

  const getStocks = useMemo(
    () =>
      tickers.map((item) => ({
        name: item.ticker,
        organizationShortName: item.organizationShortName,
        value: item.assetId,
      })),
    [JSON.stringify(tickers)],
  )

  const getOptionsBySearch = ({ search, options }) => {
    return options.filter(
      (item) =>
        item.name.toLowerCase().includes(search.toLowerCase()) ||
        item.organizationShortName.toLowerCase().includes(search.toLowerCase()),
    )
  }

  const renderOptionName = (item) => {
    return (
      <div className="d-flex ali-center" style={{ width: '90%' }}>
        <div className="mr-16" style={{ minWidth: 30 }}>
          <Span>{item.name}</Span>
        </div>
        <TextEllipsis
          text={item.organizationShortName}
          zIndexTooltip={999}
          isI18n={false}
        />
      </div>
    )
  }

  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={200}
        options={getStocks}
        optionsBySearch={getOptionsBySearch}
        numberItemDisplay={100}
        isSearch
        isOptionAll
        isDisableUnSelect={value.length >= 10}
        textEmpty="fund.fundProfile.portfolio.ALL_TICKER"
        textAll="fund.fundProfile.portfolio.ALL_TICKER"
        renderOptionName={renderOptionName}
        onChange={(value) => onChange({ assetIds: value })}
      />
    </div>
  )
}
