import PropTypes from 'prop-types'
import { Separator } from '../../../../common/separator'
import { Business } from './Business'
import { Earning } from './Earning'
import { Transaction } from './Transaction'

const RESPONSIVE_MIN_WIDTH = 1000

export const CorporateAnnouncement = ({ width }) => {
  return (
    <>
      {width > RESPONSIVE_MIN_WIDTH && (
        <div className="d-flex h-100" style={{ width }}>
          <Earning marginRight={10} />
          <Separator />
          <Transaction marginLeft={5} marginRight={5} offsetWidth={2} />
          <Separator />
          <Business marginLeft={10} />
        </div>
      )}
      {width <= RESPONSIVE_MIN_WIDTH && (
        <>
          <div
            className="d-flex"
            style={{ width, height: 'calc(200%/3 - 10px)', marginBottom: 10 }}
          >
            <Earning numColumn={2} marginRight={10} />
            <Transaction numColumn={2} marginLeft={10} />
          </div>
          <Separator isVertical={false} />
          <div
            className="d-flex"
            style={{ width, height: 'calc(100%/3 - 10px)', marginTop: 10 }}
          >
            <Business numColumn={1} childNumColumn={2} childMargin={10} />
          </div>
        </>
      )}
    </>
  )
}

CorporateAnnouncement.propTypes = {
  width: PropTypes.number.isRequired,
}
