import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { getStyle } from '../../helper'
import { selectGroupColumns, selectItemData } from '../store/slice'
import { TABLE_ROW_LEVEL } from './constants'
import style from './index.module.css'

export const OtherColumnsCell = ({
  val,
  rowId,
  colIndex,
  numCol,
  className,
  defaultActiveRowId,
}) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))
  const groupColumns = useSelector(selectGroupColumns)

  const getColorVal = useMemo(() => {
    return Number(val.replace(/,/g, '').replace('%', '')) < 0 ? '#ff5661' : ''
  }, [val])

  useEffect(() => {
    if (rowId === defaultActiveRowId) {
      if (tdRef.current) {
        tdRef.current.classList.add(style.active)
      }
    } else {
      if (tdRef.current) {
        tdRef.current.classList.remove(style.active)
      }
    }
  }, [groupColumns])

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    if (colIndex === 1) {
      return (
        <td
          ref={tdRef}
          className={`${className} ${style.rowLv1}`}
          style={{ ...style }}
          colSpan={numCol - 1}
        ></td>
      )
    }
    return null
  } else {
    return (
      <td
        ref={tdRef}
        className={className}
        style={{
          ...style,
          ...getStyle(item),
          textAlign: 'right',
          color: getColorVal,
        }}
      >
        {val}
      </td>
    )
  }
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colIndex: PropTypes.number,
  numCol: PropTypes.number,
  className: PropTypes.string,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
