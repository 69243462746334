import { colorArray } from '../constants'

export const convertData = (data) => {
  const arrOne = []
  const arrTwo = []
  const result = []
  if (Array.isArray(data)) {
    data.forEach((element) => {
      if ([3, 4, 5].includes(element.fdiTypeId)) {
        arrOne.push({
          text: element.fdiTypeName,
          value: element.fdiPercentage,
          valueLabelFirst: element.value,
          isI18n: false,
        })
      } else {
        arrTwo.push({
          valueLabelSecond: element.value,
          isI18n: false,
        })
      }
    })
    arrOne.forEach((element, index) =>
      result.push({ ...element, ...arrTwo[index], color: colorArray[index] }),
    )
  }
  return result
}
