export const common = {
  TITLE: 'Portfolio',
  BOND_INFORMATION: 'Bond Information',
  OVERVIEW_OF_PORTFOLIO_SIZE: 'Overview Of Portfolio Size',
  PORTFOLIO_SIZE_BY_ISSUER: 'Portfolio Size By Remaining Duration',
  EXPECTED: 'Expected Cash Flow Of Principal & Interest',
  GOVERNMENT_BOND: 'Government Bond',
  GOVERNMENT_BOND_2: 'Government Bond',
  CORPORATE_BOND: 'Corporate Bond',
  CORPORATE_BOND_2: 'Corporate Bond',
  TOTAL: 'Total',
}

export const bondInformation = {
  ADD_BOND_TICKER: 'Add Bond Ticker',
  CHOOSE_PORTFOLIO: 'Choose Portfolio',
  CHOOSE_WATCHLIST: 'Choose Watchlist',
  CREATE_BOND: 'Create Bond',
  VALUATION_DATE: 'Valuation Date',
  CREATE_PORTFOLIO: 'Create Portfolio',
  ADD_TO_WATCHLIST: 'Add To Watchlist',
  TRADING_INFORMATION: 'Trading Information',
  PORTFOLIO_ANALYSIS: 'Portfolio Analysis',
  SAVE_AS: 'Save As',
  SAVE: 'Save',
  BOND_TICKER: 'Bond Ticker',
  BOND_TYPE: 'Bond Type',
  ISSUER: 'Issuer',
  INDUSTRY: 'Industry',
  ISSUE_DATE: 'Issue Date',
  MATURITY_DATE: 'Maturity Date',
  NEXT_PAYMENT_DATE: 'Next Payment Date',
  PAR_VALUE: 'Par Value',
  PAR_VALUE_UNIT: '(VND)',
  COUPON: 'Coupon',
  COUPON_UNIT: '(%/Year)',
  CREDIT_SPREAD: 'Credit Spread',
  INTEREST_PAYMENT_PERIOD: 'Coupon Period',
  INTEREST_PAYMENT_PERIOD_UNIT: '(month/times)',
  INTEREST_BASIS: 'Interest Basis',
  NO_DATA: 'Search, create bond ticker add to watchlist',
  VALUATION_DATE_TOOLTIP:
    'Valuation Date is applied to all Bond ticker in Portfolio table',
  TRADING_DATE: 'Purchased Date',
  BOND_VOLUME: 'Quantity',
  BUY_PRICE: 'Cost Price',
  BUY_PRICE_UNIT: '(VND)',
  BUY_VALUE: 'Cost Value',
  BUY_VALUE_UNIT: '(VND)',
  EXTRA_DISCOUNTS: 'Premium/Discounts',
  UNALLOCATED_EXTRA: 'Unallocated Premium/Discounts',
  LAST_PAYMENT_DATE: 'Last Payment Date',
  ACCRUED_INTEREST: 'Accrued Interest',
  BOOK_VALUE: 'Book Value',
  YTM: 'Ytm',
  DIRTY_PRICE: 'Dirty Price',
  CLEAN_PRICE: 'Clean Price',
  TOTAL_ESTIMATED_VALUE: 'MTM Value',
  PROFIT_LOSS: 'Total Expected Profit/Loss',
  REMAINING_DURATION: 'Remaining Duration',
  LAST_TRADING_DATE: 'Last Trading Date',
  LAST_YTM: 'Last Ytm',
  LAST_DIRTY_PRICE: 'Last Dirty Price',
  LAST_CLEAN_PRICE: 'Last CLean Price',
  MACAULAY_DURATION: 'Macaulay Duration',
  MODIFIED_DURATION: 'Modified Duration',
  CONVEXITY: 'Convexity',
  CONVEXITY_UNIT: '(25bps)',
  PVBP: 'PVBP',
  CREDIT_Q: 'FiinCredit Q',
  WEIGHTED: 'Weighted',
  CREDIT_EVENT: 'Credit Event',
  DELETE_TITLE: 'Delete Bond Ticker',
  DELETE_ALL_TITLE: 'Delete Data Table',
  DELETE_CONTENT:
    'Are you sure you want to delete %{ticker} from Portfolio table?',
  DELETE_ALL_CONTENT: 'Are you sure you want to delete all Portfolio table?',
  YES: 'Yes',
  NO: 'No',
  VALUATION_DATE_TITLE: 'Apply Valuation Date',
  VALUATION_DATE_CONTENT:
    ' Are you sure to apply Valuation Date to all Bond Ticker in the Portfolio table?',
  MESSAGE_DATE_1: 'The Purchased Date must be smaller than the Valuation Date',
  MESSAGE_DATE_2: 'The Valuation Date must be greater than the Purchased Date',
  MESSAGE_DATE_3:
    'The Purchased Date must be greater than the Issue Date and smaller than Maturity Date',
  MESSAGE_DATE_4:
    'The Valuation Date must be greater than the Issue Date and smaller than Maturity Date',
  MESSAGE_YTM: 'YTM must be greater than -100% and less than 100%',
  MESSAGE_DIRTY_PRICE: 'Dirty Price must be greater than 0',
  MESSAGE_CLEAN_PRICE: 'Clean Price must be greater than 0',
  TOOLTIP_1: 'Cost Price per bond average',
  TOOLTIP_2:
    'The FiinCredit Q ratio is a credit risk-related ratio, where a lower value indicates a higher level of risk and vice versa. The model is constructed based on the Merton Model.',
  SAVE_PORTFOLIO_TITLE: 'Save as new Portfolio',
  SAVE_PORTFOLIO_CONTENT: 'New Portfolio Name:',
  MESSAGE_PORTFOLIO_1: 'This field is required',
  MESSAGE_PORTFOLIO_2: 'Portfolio Name has existed. Please re-try',
  PORTFOLIO: 'Portfolio %{number}',
  MESSAGE_PORTFOLIO_3:
    'Saving as new portfolio named "%{name}" was successfully',
  MESSAGE_PORTFOLIO_4: 'Portfolio named "%{name}" has been saved successfully',
  CREATE_PORTFOLIO_CONTENT:
    'Do you want to Save changes before creating new Portfolio?',
  CREATE_PORTFOLIO_CONTENT_2: 'Do you want to save changes?',
  DELETE_PORTFOLIO_TITLE: 'Delete Portfolio',
  DELETE_PORTFOLIO_CONTENT: ' Are you sure you want to delete Portfolio named ',
  RENAME_PORTFOLIO_CONTENT: 'Portfolio Name has existed. Please re-try',
  MAX_LENGTH: 'The number of Bond Ticker is no more than %{number}',
  RENAME_WATCHLIST_CONTENT: 'Watchlist Name has existed. Please re-try',
  WATCHLIST: 'Watchlist %{number}',
  MESSAGE_WATCHLIST_1:
    'Saving as new portfolio named "%{name}" was successfully',
  MESSAGE_WATCHLIST_2: 'Watchlist named "%{name}" has been saved successfully',
  MESSAGE_CREATE_1: 'Note: Please access',
  MESSAGE_CREATE_2: 'to view detail your new created Bond Ticker',
}

export const overviewOfPortfolioSize = {
  CONTRIBUTION: 'Contribution',
  INTEREST: 'Interest',
  REMAINING_DURATION: 'Remaining Duration',
  BOND_SIZE_LABEL: 'Bond Size (Mil. VND)',
  COUPON_LABEL: 'Avg Coupon (%/year)',
  COUPON_UNIT: '%/year',
  BOND_SIZE: 'Bond Size (Cost Value):',
  BOND_SIZE_UNIT: 'Mil. VND',
  AVG_COUPON: 'Avg Coupon:',
  ALL_AVG_COUPON: 'Avg Coupon (Portfolio):',
  BOND_SIZE_2: 'Bond Size',
  AVG_COUPON_2: 'Avg Coupon',
  ALL_AVG_COUPON_2: 'Avg Coupon (Portfolio)',
  DURATION_LABEL: 'Avg Remaining Duration (Years)',
  DURATION_UNIT: 'years',
  AVG_DURATION: 'Avg Remaining Duration:',
  AVG_DURATION_2: 'Avg Remaining Duration',
  ALL_AVG_DURATION: 'Avg Remaining Duration (Portfolio):',
  ALL_AVG_DURATION_2: 'Avg Remaining Duration (Portfolio)',
}

export const portfolioByIssuer = {
  YEAR: '%{year}Y',
  MONTH: '%{month}M',
  AVG_DURATION: 'Avg Remaining Duration (GB)',
  AVG_DURATION_2: 'Avg Remaining Duration (GB):',
  AVG_DURATION_3: 'Avg Remaining Duration (CB)',
  AVG_DURATION_4: 'Avg Remaining Duration (CB):',
}

export const expected = {
  UNIT: 'Unit: Mil. VND',
  UNIT_2: 'Unit (Mil. VND)',
  DAY: 'Day',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
  TOOLTIP:
    'The expected cash outflows already include the debt restructuring of the issuers based on the latest updated information',
  TRADING_DATE: 'Cash flow schedule',
  COUPON: 'Coupon Value',
  COUPON_2: 'Coupon Value:',
  PRINCIPAL: 'Principal Value',
  PRINCIPAL_2: 'Principal Value:',
  TOTAL: 'Total',
}
