export const TIME_RANGE = {
  YEARLY: [
    {
      value: 5,
      title: '5Y',
    },
    {
      value: 7,
      title: '7Y',
    },
    {
      value: 10,
      title: '10Y',
    },
    {
      value: 20,
      title: '20Y',
    },
    {
      value: 30,
      title: 'sector.sectorSpecific.steel.ALL',
    },
  ],
  MONTHLY: [
    {
      value: 1,
      title: '1Y',
    },
    {
      value: 3,
      title: '3Y',
    },
    {
      value: 5,
      title: '5Y',
    },
    {
      value: 10,
      title: '10Y',
    },
    {
      value: 30,
      title: 'sector.sectorSpecific.steel.ALL',
    },
  ],
}

export const COMPANY_GROWTH_FILTER = [
  {
    name: 'sector.sectorSpecific.steel.PRODUCTION',
    value: 0,
  },
  {
    name: 'sector.sectorSpecific.steel.CONSUMPTION',
    value: 1,
  },
  {
    name: 'sector.sectorSpecific.steel.INVENTORY',
    value: 2,
  },
]

export const FOUR_LINES = 4

export const LINE_FOUR_COLORS = ['#ecffdf', '#9c54e4', '#c7ff58', '#f57f07']

export const LINE_THREE_COLORS = ['#9c54e4', '#c7ff58', '#f57f07']
