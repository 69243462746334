import { useRef } from 'react'
import { Span } from '../../../../../common/html/Span'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import style from '../index.module.css'
import TooltipInfoValue from './tooltipInfoValue'

const SecondCellColumn = ({
  value,
  props,
  rowId,
  onMouseEnter,
  onMouseLeave,
  isShowValue,
  idSelected,
  type,
  width,
  time,
  idColumnSelect,
  columnId,
  index,
  rowIndex,
}) => {
  const parentRef = useRef()

  return (
    <td
      {...props}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={parentRef}
      className={idSelected === rowId && style.rowHover}
    >
      <div
        style={{ minWidth: width, boxSizing: 'border-box', padding: '5px 8px' }}
      >
        <Span style={{ fontWeight: rowId === 444 ? 700 : 340 }}>
          {formatVal(valDivBillion(value), 2)}
        </Span>
        {isShowValue === type &&
          idSelected === rowId &&
          columnId === idColumnSelect &&
          rowIndex === index && (
            <TooltipInfoValue
              rowIdCol={idSelected?.split('-')?.[0] ?? null}
              parentRef={parentRef}
              time={time}
            />
          )}
      </div>
    </td>
  )
}

export default SecondCellColumn
