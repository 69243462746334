export const MARGIN_RECHARTS = { left: 0, right: 0, top: 20, bottom: 10 }
export const MARGIN = { left: 10, right: 10, top: 20, bottom: 25 }
export const MIN_HEIGHT_XAXIS = 1
export const HEIGHT_XAXIS = 30
export const TICK_MARGIN_XAXIS = 15
export const SIZE_ZOOM_CHART = 7
export const FONT_SIZE = 11
export const WHITE_COLOR = '#ececec'
export const DEFAULT_COLOR = '#009cff'

export const SESSION = {
  start: {
    hour: 9,
    minute: 0,
  },
  end: {
    hour: 15,
    minute: 0,
  },
}

export const AXIS_LABEL_POSITION = {
  TOP_RIGHT: 'TOP_RIGHT',
  TOP_LEFT: 'TOP_LEFT',
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  TOP: 'TOP',
}

export const CHART_TYPES = {
  LINE: 'LINE',
  BAR: 'BAR',
  AREA: 'AREA',
  DOT: 'DOT',
  STACK_BAR: 'STACK_BAR',
  STACK_AREA: 'STACK_AREA',
}
