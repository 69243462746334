import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  reOrderByMonthAndYear,
  sortObjByMonthAndYear,
  sortObjByYear,
} from '../../../common/helper'
import { TYPE_VAL_GROWTH } from '../../exportStatisticTable/constants'
import { TIME_RANGE } from '../constants'
import { getExporterGrowth } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/corporateExport/exportGrowth',
  initialState: {
    isLoading: true,
    data: [],
    activeItem: null,
    isValOrGrowth: TYPE_VAL_GROWTH.VAL,
    isYearly: false,
    currentDateType: TIME_RANGE.MONTHLY[0].value,
    currentDateTypeYearly: TIME_RANGE.YEARLY[0].value,
    isAccMonthly: false,
    locale: 'en',
  },
  reducers: {
    changeActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    changeIsValOrGrowthChart: (state, action) => {
      state.isValOrGrowth = action.payload
    },
    changeIsYearlyGrowthChart: (state, action) => {
      state.isYearly = action.payload
    },
    changeIsAccMonthlyGrowthChart: (state, action) => {
      state.isAccMonthly = action.payload
    },
    changeCurrentDateType: (state, action) => {
      state.currentDateType = action.payload
    },
    changeCurrentDateTypeYearly: (state, action) => {
      state.currentDateTypeYearly = action.payload
    },
    changeLocaleGrowthChart: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExporterGrowth.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getExporterGrowth.fulfilled, (state, action) => {
      state.isChangeDateType = false

      const isYearlyChart = state.isYearly
      const isAcc = state.isAccMonthly

      const payloadData = action.payload || []
      const latestMonth = payloadData.length ? payloadData[0].realMonth : ''
      const latestYear = payloadData.length ? payloadData[0].realYear : ''
      const yearOfCurrentDateType = isYearlyChart
        ? latestYear - state.currentDateTypeYearly + 1
        : latestYear - state.currentDateType

      const allYearsInRange = Array.from(
        { length: latestYear - yearOfCurrentDateType + 1 },
        (_, i) => yearOfCurrentDateType + i,
      )

      const filteredData = isYearlyChart
        ? payloadData.filter((item) => allYearsInRange.includes(item.realYear))
        : payloadData.filter(
            (item) =>
              allYearsInRange.slice(1, -1).includes(item.realYear) ||
              (item.realYear === latestYear && item.realMonth <= latestMonth) ||
              (item.realYear === yearOfCurrentDateType &&
                item.realMonth >= latestMonth),
          )

      const data = filteredData.map((item) => ({
        ...item,
        id: item.organizationId,
        name: item.organizationShortName,
        month: item.realMonth,
        year: item.realYear,
        value:
          state.isValOrGrowth === TYPE_VAL_GROWTH.VAL
            ? item.accumulatedValue / 1000000
            : item.accumulatedValueYoY * 100,
      }))

      state.data = convertData(data, isYearlyChart, isAcc, state.locale)
      state.isLoading = false
    })
    builder.addCase(getExporterGrowth.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectCurrentStatisticTypeChart = (state) =>
  state[slice.name].currentStatisticTypeChart
export const selectCurrentDateType = (state) =>
  state[slice.name].currentDateType
export const selectCurrentDateTypeYearly = (state) =>
  state[slice.name].currentDateTypeYearly

export const {
  changeActiveItem,
  changeStatisticTypeChart,
  changeIsValOrGrowthChart,
  changeIsYearlyGrowthChart,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  changeIsAccMonthlyGrowthChart,
  changeLocaleGrowthChart,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, isYearly, isAcc, locale) => {
  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? [...new Set(data?.map((item) => `${item.year}`))].sort()
    : isAcc
    ? sortObjByMonthAndYear(getMonthAndYearInDataRange(data)).map((item) =>
        getAccMonthAndYearKey(item.month, item.year, locale),
      )
    : reOrderByMonthAndYear(
        getMonthAndYearInDataRange(data).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        ),
      )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.year === year && item.month === month)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? sortObjByYear(getYearInDataRange(data)).map((item) => [
        ...dataWithYear(item.year),
      ])
    : sortObjByMonthAndYear(getMonthAndYearInDataRange(data)).map((item) => [
        ...dataWithMonthAndYear(item.month, item.year),
      ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
