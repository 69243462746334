import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import style from '../index.module.css'
import { selectListCategory } from './store/slice'

export const TdSelect = ({
  val,
  rowId,
  onRowCollapse,
  data,
  isRowCollapse,
}) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const listCategory = useSelector(selectListCategory)

  const [isCollapse, setIsCollapse] = useState(false)

  const categoryDataTableItemWithCollapse = data?.map((item) => ({
    ...item,
    isCollapsible: item.level === 1 ? true : false,
  }))
  const categoryDataTableItem = categoryDataTableItemWithCollapse?.find(
    (item) => item.id === rowId,
  )

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listCategory?.some((category) => category.id === rowId)
  }

  const handleDisableCheckbox = () => {}

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <input
        type="checkbox"
        className={`checkbox ${style.checkbox}`}
        onChange={handleChangeListCategory}
        checked={handleChecked()}
        disabled={handleDisableCheckbox()}
      />
      <div
        className="d-flex"
        style={{
          marginLeft: (categoryDataTableItem?.level - 1) * MARGIN_LEFT || 0,
        }}
      >
        {categoryDataTableItem?.childrenIds.length &&
        categoryDataTableItem.isCollapsible ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <div style={{ width: 20 }}></div>
        )}
        <div
          className={categoryDataTableItem?.isCollapsible ? style.pointer : ''}
        >
          <TextEllipsis
            isI18n={false}
            text={val}
            appendStyle={{
              color: categoryDataTableItem?.isCollapsible ? '#fecf2f' : '',
              fontStyle: categoryDataTableItem?.level === 2 ? 'italic' : '',
              fontWeight: categoryDataTableItem?.level === 2 ? '500' : '',
            }}
          />
        </div>
      </div>
    </div>
  )
}
