import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import TimeFrame from '../../common/TimeFrame'
import {
  OPEN_MARKET_DAILY_OPTIONS,
  OPEN_MARKET_MONTHLY_OUTSTANDING,
  OPEN_MARKET_WEEKLY_OPTIONS,
} from '../../constants'
import { getTimeRange as getTimeRangeOpenMarket } from '../panelOpenMarket/store/slice'
import {
  getDailyValue,
  getMonthlyValue,
  getWeeklyValue,
  handleDailyValue,
  handleMonthlyValue,
  handleWeeklyValue,
} from './chartTop/store/slice'

const OpenMarketOperations = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const dailyValue = useSelector(getDailyValue)
  const weeklyValue = useSelector(getWeeklyValue)
  const monthlyValue = useSelector(getMonthlyValue)
  const openMarketTimeRange = useSelector(getTimeRangeOpenMarket)

  const { timeFrameOption, timeRange, onChangeTab } = useMemo(() => {
    switch (openMarketTimeRange) {
      case 'Weekly':
        return {
          timeFrameOption: OPEN_MARKET_WEEKLY_OPTIONS,
          timeRange: weeklyValue,
          onChangeTab: (tab) => {
            dispatch(handleWeeklyValue(tab.value))
          },
        }
      case 'Monthly':
        return {
          timeFrameOption: OPEN_MARKET_MONTHLY_OUTSTANDING,
          timeRange: monthlyValue,
          onChangeTab: (tab) => {
            dispatch(handleMonthlyValue(tab.value))
          },
        }
      default:
        return {
          timeFrameOption: OPEN_MARKET_DAILY_OPTIONS,
          timeRange: dailyValue,
          onChangeTab: (tab) => {
            dispatch(handleDailyValue(tab.value))
          },
        }
    }
  }, [openMarketTimeRange, dailyValue, weeklyValue, monthlyValue])

  return (
    <Panel
      title={'economy.monetary.monetary.MONEY_MARKET_OPERATIONS'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <TimeFrame
        timeRange={timeRange}
        onChangeTab={onChangeTab}
        styleProps={{ justifyContent: 'flex-end' }}
        timeFrameOption={timeFrameOption}
      />
    </Panel>
  )
}

export default OpenMarketOperations
