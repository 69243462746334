import { LINE_CHART_COLORS } from '../../constants'
import { ROW_ID_WITHOUT_CHILDREN } from './config'
import { PAGE_NUMBER } from './TableComponent'

export const handleIds = (payload) => {
  if (!payload.length) {
    return []
  }

  const parentIds = getKeyNotDuplicate(payload, 'sourceOrganizationId')
  const rowIds = []
  parentIds.forEach((item) => {
    rowIds.push({
      id: item.sourceOrganizationId,
      title: item.organizationShortName,
      level: 1,
      sourceOrganizationId: item.sourceOrganizationId,
      baseCurrencyId: item.baseCurrencyId,
      isCentralRate: item.isCentralRate,
    })

    if (isRowWithoutChildren(item.sourceOrganizationId)) {
      return
    }

    const children = getChildren(payload, item.sourceOrganizationId).map(
      (child) => ({
        id: `${item.sourceOrganizationId}-${child.baseCurrencyId}`,
        title: child.currencyCode,
        level: 2,
        sourceOrganizationId: child.sourceOrganizationId,
        baseCurrencyId: child.baseCurrencyId,
        isCentralRate: child.isCentralRate,
      }),
    )
    rowIds.push(...children)
  })

  return rowIds
}

export const handleParentIdWithTitle = (payload) => {
  if (!payload.length) {
    return {}
  }

  const parentIds = getKeyNotDuplicate(payload, 'sourceOrganizationId')
  return parentIds.reduce((previous, current) => {
    return {
      ...previous,
      [current.sourceOrganizationId]: current.organizationShortName,
    }
  }, {})
}

export const handleHeaderDate = (payload) => {
  const dateNotDuplicate = getKeyNotDuplicate(payload, 'tradingDateId')

  return dateNotDuplicate.map((item) => item.tradingDateId)
}

const getChildren = (payload, parentId) => {
  const allChildren = payload.filter(
    (item) => item.sourceOrganizationId === parentId,
  )

  return getKeyNotDuplicate(allChildren, 'baseCurrencyId')
}

const getKeyNotDuplicate = (payload, key) => {
  return payload.filter((item, index, arr) => {
    return index === arr.findIndex((element) => element[key] === item[key])
  })
}

export const handleCollapseLevel = (data) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: { level: cur.level, isRowCollapse: cur.level === 1 },
    }
  }, {})
}

export const keyById = (payload) => {
  const result = {}

  payload.forEach((item) => {
    const rowId = isRowWithoutChildren(item.sourceOrganizationId)
      ? `${item.sourceOrganizationId}`
      : `${item.sourceOrganizationId}-${item.baseCurrencyId}`
    const colId = item.tradingDateId

    if (!result[rowId]) {
      result[rowId] = {
        [`${colId}-askRate`]: item.askRate,
        [`${colId}-bidRateCash`]: item.bidRateCash,
        [`${colId}-bidRateTransfer`]: item.bidRateTransfer,
      }
    } else {
      result[rowId][`${colId}-askRate`] = item.askRate
      result[rowId][`${colId}-bidRateCash`] = item.bidRateCash
      result[rowId][`${colId}-bidRateTransfer`] = item.bidRateTransfer
    }
  })

  return result
}

const isRowWithoutChildren = (id) => ROW_ID_WITHOUT_CHILDREN.includes(id)

export const defineGroupColumns = (days) => {
  const groupColumn = []
  days.forEach((day) => {
    groupColumn.push(
      { key: `${day}-bidRateCash`, title: day },
      { key: `${day}-bidRateTransfer`, title: day },
      { key: `${day}-askRate`, title: day },
    )
  })

  return [{ key: 'emptyColumn', title: '' }, ...groupColumn]
}

export const initialCheckboxChecked = (payload, indexChecked) => {
  if (!payload.length) {
    return []
  }

  return indexChecked
    .map((index) => {
      return payload[index]
    })
    .filter((item) => item)
}

export const initLineColor = (checkboxChecked) => {
  return LINE_CHART_COLORS.map((color, index) => {
    let author = undefined
    const itemChecked = checkboxChecked[index]
    if (itemChecked) {
      author =
        itemChecked.level === 1
          ? `${itemChecked.sourceOrganizationId}`
          : itemChecked.id
    }

    return {
      color,
      author: author,
    }
  })
}

export const findNearestDate = (headerDateWithFormat, fromDate) => {
  const fromDateTimeStamp = +new Date(fromDate)
  const listDiff = headerDateWithFormat.map((item) => {
    return {
      ...item,
      diff: item.timeStampValue - fromDateTimeStamp,
    }
  })
  const minDiff = Math.min.apply(
    Math,
    listDiff.reduce(
      (arr, item) => (item.diff > 0 ? [...arr, item.diff] : arr),
      [],
    ),
  )

  return listDiff.findIndex((item) => item.diff === minDiff) + PAGE_NUMBER
}
