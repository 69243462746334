export const FUND_STRUCTURES = {
  STOCK_FUND: '1',
  BOND_FUND: '2',
  BALANCE_FUND: '3',
}

export const FUND_STRUCTURE_OPTIONS = Object.keys(FUND_STRUCTURES).reduce(
  (arr, key) => [
    ...arr,
    {
      name: `fund.fundCenter.${key}`,
      value: FUND_STRUCTURES[key],
    },
  ],
  [],
)

export const FUND_TYPES = {
  OPEN_END_FUND: '1',
  CLOSED_END_FUND: '2',
  ETF_FUND: '3',
  SPECIALTY_FUND: '4',
  MEMBER_FUND: '5',
  PENSION_FUND: '6',
}

export const FUND_TYPE_OPTIONS = Object.keys(FUND_TYPES).reduce(
  (arr, key) => [
    ...arr,
    {
      name: `fund.fundCenter.${key}`,
      value: FUND_TYPES[key],
    },
  ],
  [],
)
