import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class MasterDataProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('GetICBs', params)
  }
}

export default new MasterDataProxy(ServiceProxyConfig.Common.Master.ServiceUrl)
