export const valueTab = {
  dirtyPrice: 'dirtyPrice',
  cleanPrice: 'cleanPrice',
}

export const listTab = [
  {
    title: 'bond.bondSecurity.tradingInformation.filter.dirtyPrice',
    value: valueTab.dirtyPrice,
  },
  {
    title: 'bond.bondSecurity.tradingInformation.filter.cleanPrice',
    value: valueTab.cleanPrice,
  },
]

export const Column = [
  {
    key: 'id',
    title: 'bond.bondSecurity.tradingInformation.popup.table.No',
    attrs: {
      styleHeader: {
        textAlign: 'center',
      },
      style: {
        textAlign: 'center',
      },
    },
  },
  {
    key: 'organizationShortName',
    title:
      'bond.bondSecurity.tradingInformation.popup.table.CreditRatingAgency',
    attrs: {},
  },
  {
    key: 'ratingDate',
    title: 'bond.bondSecurity.tradingInformation.popup.table.RatingDate',
    attrs: {},
  },
  {
    key: 'ratingScoreValue',
    title: 'bond.bondSecurity.tradingInformation.popup.table.RatingType',
    attrs: {},
  },
  {
    key: 'prospects',
    title: 'bond.bondSecurity.tradingInformation.popup.table.RatingProspect',
    attrs: {},
  },
  {
    key: 'sourceUrl',
    title: '',
    attrs: {},
  },
]
