import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import ProductionService from '../../../../../../core/services/economy/productionAndConsumption/ProductionService'

export const growthIIPChart = createAsyncThunk(
  'economy/productionAndConsumption/productGrowthChart/IIP',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ProductionService.getDataChartGrowthIIP(params)
      if (response.success) {
        return response.data
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const growthIICChart = createAsyncThunk(
  'economy/productionAndConsumption/productGrowthChart/IIC',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ProductionService.getDataChartGrowthIIC(params)
      if (response.success) {
        return response.data
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const growthIIIChart = createAsyncThunk(
  'economy/productionAndConsumption/productGrowthChart/III',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ProductionService.getDataChartGrowthIII(params)
      if (response.success) {
        return response.data
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getGrowthByTimeRange = createAsyncThunk(
  'economy/productionAndConsumption/productGrowthChart/PPI',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ProductionService.getGrowthByTimeRange(params)
      if (response.success) {
        return response.data
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
