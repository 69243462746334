import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OMO_NET_INJECTION } from '.'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { getTimeRange as getTimeRangeOpenMarket } from '../../panelOpenMarket/store/slice'
import {
  getDailyValue,
  getMonthlyValue,
  getWeeklyValue,
} from '../chartTop/store/slice'
import style from '../index.module.css'
import ChartComponent from './ChartComponent'
import { getChartData, getChartLoading, getTitle } from './store/slice'
import { getNetPumpVolume } from './store/thunk'

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const chartData = useSelector(getChartData)
  const chartLoading = useSelector(getChartLoading)
  const locale = useSelector((state) => state.i18n.locale)
  const openMarketTimeRange = useSelector(getTimeRangeOpenMarket)
  const dailyValue = useSelector(getDailyValue)
  const weeklyValue = useSelector(getWeeklyValue)
  const monthlyValue = useSelector(getMonthlyValue)
  const title = useSelector(getTitle)

  const getTimeRange = (openMarketTimeRange) => {
    switch (openMarketTimeRange) {
      case 'Weekly':
        return weeklyValue
      case 'Monthly':
        return monthlyValue
      default:
        return dailyValue
    }
  }

  useEffect(() => {
    dispatch(
      getNetPumpVolume({
        TimeFrequency: openMarketTimeRange,
        TimeRange: getTimeRange(openMarketTimeRange),
      }),
    )
  }, [locale, openMarketTimeRange, dailyValue, weeklyValue, monthlyValue])

  if (chartLoading) {
    return (
      <div className={`${style.container} h-100`}>
        <Loading />
      </div>
    )
  }

  if (!chartData.length) {
    return (
      <div className={`${style.container} h-100`}>
        <NoData />
      </div>
    )
  }

  return (
    <div className={`${style.container} h-100`} id={OMO_NET_INJECTION}>
      <ChartComponent
        height={height}
        width={width}
        data={chartData}
        keyXAxis={openMarketTimeRange === 'Monthly' ? 'monthYear' : 'day'}
        areaKey={'0'}
        lineKey={['2', '5']}
        title={title}
        locale={locale}
      />
    </div>
  )
}

export default Container
