import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import EventEmitter, { RESET_TABLE_SORT } from '../../../../utils/EventEmitter'
import { TABLE_SECTOR_ID } from './constant'
import Filter from './Filter'
import {
  resetDataTable,
  selectFilterStatus,
  selectMappingLoading,
  selectTimeFrequency,
  selectViewBy,
  sort,
} from './store/slice'
import { getLocationName, getTableData } from './store/thunk'
import style from './style.module.css'
import TableComponent from './TableComponent'

const TotalRegisterFDI = ({ width }) => {
  const dispatch = useDispatch()
  const refSizeTracker = useRef()
  const refTextNote = useRef()

  const viewBy = useSelector(selectViewBy)
  const locale = useSelector((state) => state.i18n.locale)
  const filterStatus = useSelector(selectFilterStatus)
  const timeFrequency = useSelector(selectTimeFrequency)
  const isMappingLoading = useSelector(selectMappingLoading)

  const [sizeTracker, setSizeTracker] = useState(0)
  const [isFirstTime, setIsFirstTime] = useState(true)
  const [sortField, setSortField] = useState({})

  useEffect(() => {
    setSortField({})
    EventEmitter.dispatch(RESET_TABLE_SORT)
  }, [viewBy, filterStatus, timeFrequency])

  useEffect(() => {
    dispatch(sort(sortField))
    setIsFirstTime(true)
  }, [sortField])

  useEffect(() => {
    dispatch(getLocationName())
  }, [locale])

  useEffect(() => {
    if (filterStatus && !isMappingLoading)
      dispatch(
        getTableData({
          FDITypeId: filterStatus,
          TimeFrequency: timeFrequency,
          NumberOfPeriod: 20,
        }),
      )
    setIsFirstTime(true)
  }, [locale, filterStatus, timeFrequency, isMappingLoading])

  useEffect(() => {
    if (refSizeTracker?.current?.offsetHeight)
      setSizeTracker(refSizeTracker?.current?.offsetHeight)
  }, [refSizeTracker?.current?.offsetHeight, width])

  useEffect(() => {
    dispatch(resetDataTable())
  }, [])

  return (
    <>
      <Filter ref={refSizeTracker} setIsFirstTime={setIsFirstTime} />
      <div
        key={timeFrequency}
        style={{
          height: `calc(100% - ${
            sizeTracker + refTextNote?.current?.offsetHeight
          }px)`,
        }}
        id={TABLE_SECTOR_ID}
      >
        <TableComponent
          setSortField={setSortField}
          isFirstTime={isFirstTime}
          setIsFirstTime={setIsFirstTime}
        />
      </div>
      <div ref={refTextNote}>
        {viewBy && (
          <Span className={style.labelTable} style={{ fontSize: 11 }}>
            <Translate value="economy.fdi.fdiByCountries.LABEL_TABLE" />
          </Span>
        )}
      </div>
    </>
  )
}

export default TotalRegisterFDI
