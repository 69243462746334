import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { getFontSize } from '../../utils/FontSize'
import { Span } from '../html/Span'
import { SizeTracker } from '../sizeTracker'
import TextEllipsis from '../textEllipsis'
import { getItemTextWidth } from './helper'
import style from './index.module.css'

export const TAB_TYPE = {
  BUTTON: 'BUTTON',
  LINK: 'LINK',
}

const Tabs = ({
  children,
  active,
  itemStyle,
  saveDataActionTab,
  tabType,
  parentStyle,
  widthComponent,
}) => {
  const fontSize12 = getFontSize(12)
  const refNav = useRef()
  const locale = useSelector((state) => state.i18n.locale)

  const [activeTab, setActiveTab] = useState(active)
  const [tabsData, setTabsData] = useState([])
  const [widthTab, setWidthTab] = useState('')

  const onClick = (index) => () => {
    setActiveTab(index)
    saveDataActionTab(index)
  }

  useEffect(() => {
    if (widthComponent) {
      const widths = getItemTextWidth(
        tabsData,
        itemStyle,
        fontSize12,
        widthComponent,
      )
      setWidthTab(widths)
    }
  }, [widthComponent, refNav.current, locale])

  useEffect(() => {
    if (children) {
      const dataFromChildren = []

      React.Children.forEach(children, (el) => {
        if (!React.isValidElement(el)) return

        const { props } = el
        dataFromChildren.push({ ...props })
      })
      setTabsData(dataFromChildren)
    }
  }, [children])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <nav
              ref={refNav}
              className={tabType === TAB_TYPE.BUTTON ? 'tab-in-panel' : ''}
              style={parentStyle}
            >
              {tabsData.map(({ title }, index) => (
                <a
                  key={index}
                  className={[
                    style.item,
                    tabType === TAB_TYPE.LINK ? style.linkItem : '',
                    activeTab === index && tabType === TAB_TYPE.BUTTON
                      ? style.btnActive
                      : '',
                  ].join(' ')}
                  style={itemStyle}
                  onClick={onClick(index)}
                >
                  <Span
                    style={{
                      fontSize: 12,
                      width: widthTab[index],
                    }}
                    className={
                      activeTab === index && tabType === TAB_TYPE.LINK
                        ? style.linkActive
                        : ''
                    }
                  >
                    {widthTab[index] ? (
                      <TextEllipsis
                        key={fontSize12 + index}
                        text={title}
                        isI18n={true}
                      />
                    ) : (
                      <Translate value={title} />
                    )}
                  </Span>
                </a>
              ))}
            </nav>

            {size.height && children && tabsData[activeTab] && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {React.cloneElement(tabsData[activeTab].children, {
                  ...tabsData[activeTab],
                })}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

Tabs.propTypes = {
  children: PropTypes.node,
  active: PropTypes.number,
  saveDataActionTab: PropTypes.func,
  tabType: PropTypes.string,
  parentStyle: PropTypes.object,
  itemStyle: PropTypes.object,
}

Tabs.defaultProps = {
  active: 0,
  saveDataActionTab: () => {},
  tabType: TAB_TYPE.BUTTON,
  parentStyle: {},
  itemStyle: {},
}

export default Tabs
