import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import style from '../../index.module.css'
import {
  changeTickerChecked,
  selectWatchlistItemTicker,
  selectWatchlistTickerIds,
} from '../../store/slice'

const ItemTicker = ({ tickerId, index }) => {
  const dispatch = useDispatch()

  const itemTicker = useSelector(selectWatchlistItemTicker(tickerId))

  const handleChangeChecked = (e) =>
    dispatch(
      changeTickerChecked({
        organizationId: itemTicker.organizationId,
        value: e.target.checked,
      }),
    )

  return (
    <tr>
      <td className={style.w50px}>{index + 1}</td>
      <td>
        <div className={style.ticker}>{itemTicker.ticker}</div>
        <span>{itemTicker.items[1].value}</span>
      </td>
      <td className={style.w70px}>
        <div className="d-flex justify-content-center">
          <p>
            <input
              type="checkbox"
              className={`checkbox ${style.checkbox}`}
              checked={itemTicker.checked}
              onChange={handleChangeChecked}
            />
          </p>
        </div>
      </td>
    </tr>
  )
}

const TableTicker = () => {
  const tickerIds = useSelector(selectWatchlistTickerIds)

  return (
    <ScrollComponent>
      <table className="w-100">
        <tbody>
          {tickerIds.map((tickerId, index) => (
            <ItemTicker key={tickerId} tickerId={tickerId} index={index} />
          ))}
        </tbody>
      </table>
    </ScrollComponent>
  )
}

export default TableTicker
