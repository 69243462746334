export const chartFillColor = {
  ml: '#45b29d',
  osyg: '#facc5c',
}

export const chartBarKeys = ['ml']

export const chartLineKeys = ['osyg']

export const chartMappingDisplayName = {
  ml: 'sector.financialAnalysis.security.performance.OPERATING_SALE',
  osyg: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
}

export const chartFormat = {
  ml: 10 ** 9,
  osyg: 0.01,
}
