import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { List } from '../../../../../common/news/List'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../../../constants/routes'
import { ANNOUNCEMENT_TYPE } from '../../constants'
import {
  selectNewsBusinessLoading,
  selectNewsBusinesses,
} from '../../store/slice'
import { getListNewsBusinessActivities } from '../../store/thunk'

export const BusinessActivities = ({
  numColumn,
  childNumColumn,
  marginLeft,
  marginRight,
  childMargin,
}) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const { data = [] } = useSelector(selectNewsBusinesses)
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const isLoading = useSelector(selectNewsBusinessLoading)

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(
        getListNewsBusinessActivities({
          ICBId: sectorInfo.icbId,
          Page: 1,
          PageSize: 10,
          NewsCategoryIds: 0,
          NewsSourceId: 0,
        }),
      )
    }
  }, [locale, sectorInfo.icbId])

  const onMoreAction = () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-more'
      ].route,
      sectorInfo,
      '?announcementType=' + ANNOUNCEMENT_TYPE.BUSINESS,
    )
  }
  const onDetailAction = (newsId) => () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-detail'
      ].route,
      sectorInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <List
      title={'sector.sectorConstituents.newsReport.OTHER_BUSINESS_ACTIVITIES'}
      data={data}
      isLoading={isLoading}
      isNodata={data.length === 0}
      numColum={numColumn}
      childNumColumn={childNumColumn}
      marginLeft={marginLeft}
      marginRight={marginRight}
      childMargin={childMargin}
      onMoreAction={onMoreAction}
      onDetailAction={onDetailAction}
    />
  )
}

BusinessActivities.propTypes = {
  numColumn: PropTypes.number,
  childNumColumn: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  childMargin: PropTypes.number,
}
