import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { GROUP_BY, VIEW_BY } from '../constant'

export const ContentPagination = ({ groupBy, isMultiFile, viewBy }) => {
  const locale = useSelector((state) => state.i18n.locale)

  const getText = useMemo(() => {
    const viewType = I18n.t(
      viewBy === VIEW_BY.CORPORATE
        ? 'tool.dataExplorer.tradingData.VIEW_TYPE_COMPANY'
        : 'tool.dataExplorer.tradingData.VIEW_TYPE_SECTOR',
    )

    let text = ''

    if (!isMultiFile) {
      let numIndicator = 0
      let numCompany = 0

      switch (groupBy) {
        case GROUP_BY.DATE:
          numIndicator = 10
          numCompany = 10
          break
        case GROUP_BY.COMPANY:
        case GROUP_BY.INDICATOR:
          numIndicator = 10
          numCompany = 2
          break
        default:
          break
      }

      text = I18n.t('tool.dataExplorer.tradingData.LB_TABLE')
        .replace('[numIndicator]', numIndicator)
        .replace('[numCompany]', numCompany)
    } else {
      text = I18n.t('tool.dataExplorer.tradingData.MULTI_FILE_NOTE_TABLE')
    }

    text = text.replace('[viewType]', viewType)

    return text
  }, [isMultiFile, groupBy, viewBy, locale])

  return (
    <div style={{ marginBottom: 15 }}>
      <Span style={{ fontSize: 12 }}>{getText}</Span>
    </div>
  )
}
