import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import SelectCustom from '../../../../common/selectCustom'
import TextEllipsis from '../../../../common/textEllipsis'
import style from './index.module.css'

export const dataTypeConstants = {
  LISTED: 'Listed',
  PRIVATE: 'Private',
  ALL: 'All',
}

export const CustomTopInfo = ({
  options,
  onSelectChange,
  onDataTypeChange,
  isShowDataType = true,
}) => {
  const locale = useSelector((state) => state.i18n.locale)

  // Use state
  const [value, setValue] = useState('')
  const [dataType, setDataType] = useState(dataTypeConstants.LISTED)

  // Get data
  const dataTypeOptions = useMemo(() => {
    return Object.keys(dataTypeConstants).map((key) => ({
      name: I18n.t(`sector.financialAnalysis.common.${key}`),
      value: dataTypeConstants[key],
      disableSelect: dataTypeConstants[key] !== dataTypeConstants.LISTED,
    }))
  }, [locale])

  // Actions
  const handleSelectChange = (val) => {
    setValue(val)
    onSelectChange(val)
  }

  const handleDataTypeChange = (val) => {
    setDataType(val)
    onDataTypeChange && onDataTypeChange(val)
  }

  // Use effect
  useEffect(() => {
    setValue(options[0]?.value || '')
  }, [options])

  // Render
  const renderSelect = ({
    styleComponent,
    isDisable,
    isI18n,
    dataById,
    value,
    isShowDropdown,
  }) => {
    return (
      <span className={`${styleComponent.select} ${style.customSelect}`}>
        <span
          className={`${styleComponent.text} ${style.customSelectText} ${
            isDisable ? styleComponent.disable : ''
          }`}
        >
          <TextEllipsis isI18n={isI18n} text={dataById[value]?.name || ''} />
        </span>
        <i
          className={`${!isShowDropdown ? 'icon-caret' : 'icon-caret-up'} ${
            styleComponent.icon
          } ${style.customSelectIcon}`}
        />
      </span>
    )
  }

  return (
    <div className={isShowDataType ? 'd-flex' : ''}>
      {!!options.length && (
        <div className={style.customContainerSelect}>
          <SelectCustom
            value={value}
            isI18n={true}
            selectData={options.map((item) => ({
              name: item.name,
              value: item.value,
            }))}
            handleChange={handleSelectChange}
            renderSelect={renderSelect}
          />
        </div>
      )}
      {isShowDataType && (
        <div style={{ minWidth: 110, marginLeft: 28 }}>
          <SelectCustom
            value={dataType}
            isI18n={true}
            selectData={dataTypeOptions}
            handleChange={handleDataTypeChange}
            renderSelect={renderSelect}
          />
        </div>
      )}
    </div>
  )
}
