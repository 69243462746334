import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_PERFORMANCE_KEYS,
  TABLE_PERFORMANCE_TH_ROW_KEYS,
} from '../constants'

export const performanceSchema = ({ locale }) => {
  return TABLE_PERFORMANCE_KEYS.map((item) => {
    const result = {
      ...item,
      isI18n: false,
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
      return {
        ...result,
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_TOTAL_NAV) {
      return {
        ...result,
        render: (val) =>
          formatVal(val / TABLE_COL_FORMATS.COL_TOTAL_NAV, 2, true),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_NAV_CCQ) {
      return {
        ...result,
        render: (val) =>
          formatVal(Math.round(val / TABLE_COL_FORMATS.COL_NAV_CCQ), 0, true),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_MOST_RECENT,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_YTD,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_1M,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_6M,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_1Y,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_3Y,
        TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_5Y,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    return result
  })
}

export const performanceTableThSchema = () => {
  return TABLE_PERFORMANCE_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      const result = {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
          minWidth: 100,
        },
      }

      if (col.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
        return {
          ...result,
          rowSpan: 2,
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_TOTAL_NAV) {
        return {
          ...result,
          rowSpan: 2,
          renderTh: () => (
            <div style={{ textAlign: 'right' }} className="flex-column">
              <Span>
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.BILLION_VND" />)
              </Span>
            </div>
          ),
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_NAV_CCQ) {
        return {
          ...result,
          rowSpan: 2,
          renderTh: () => (
            <div style={{ textAlign: 'right' }} className="flex-column">
              <Span>
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.VND" />)
              </Span>
            </div>
          ),
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE) {
        return {
          ...result,
          thStyle: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
          },
          colSpan: 6,
        }
      }

      return result
    }),
  )
}

export const performanceThCellTopById = {
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_MOST_RECENT]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_YTD]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_1M]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_6M]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_1Y]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_3Y]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_5Y]: [
    TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE,
  ],
}
