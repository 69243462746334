import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { formatVal } from '../../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
}

const BAR_COLOR = '#0096eb'
const LINE_COLOR = '#f7b80c'
const YAXIS_LEFT = 'YAXIS_LEFT'
const YAXIS_RIGHT = 'YAXIS_RIGHT'

const ChartComponent = ({ data, width, height, keyXAxis, barKey, lineKey }) => {
  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(`corporate.businessModel.penetrationAnalysis.REVENUE`),
        value: formatVal(tooltipData[barKey], decimalLengths[YAXIS_LEFT] || 2),
      },
      {
        label: I18n.t('corporate.businessModel.penetrationAnalysis.GROWTH'),
        value:
          formatVal(tooltipData[lineKey], decimalLengths[YAXIS_RIGHT] || 2) +
          '%',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                margin={{ ...MARGIN_RECHARTS, right: 14 }}
                yAxis={[
                  {
                    id: YAXIS_LEFT,
                    keys: [barKey],
                    orientation: 'left',
                    isBarChart: true,
                    tickNum: SETTINGS.yTickNum,
                    label: I18n.t(
                      'corporate.businessModel.penetrationAnalysis.BILLION',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                  },
                  {
                    id: YAXIS_RIGHT,
                    keys: [lineKey],
                    isLineChart: true,
                    orientation: 'right',
                    tickNum: SETTINGS.yTickNum,
                    labelPosition: AXIS_LABEL_POSITION.RIGHT,
                    unitYAxis: '%',
                  },
                ]}
                renderCustomTooltip={renderTooltip}
              >
                {({ chartContentWidth }) => (
                  <>
                    <Bar
                      yAxisId={YAXIS_LEFT}
                      dataKey={barKey}
                      barSize={getColumnSizeInBarChart(
                        chartContentWidth,
                        data.length,
                      )}
                      fill={BAR_COLOR}
                      isAnimationActive={false}
                    />
                    <Line
                      yAxisId={YAXIS_RIGHT}
                      dataKey={lineKey}
                      type="linear"
                      stroke={LINE_COLOR}
                      fill={LINE_COLOR}
                      strokeWidth={2}
                      dot={{
                        strokeWidth: getRadiusOfScatter(
                          chartContentWidth,
                          data.length,
                        ),
                      }}
                      activeDot={false}
                      isAnimationActive={false}
                    />
                  </>
                )}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'corporate.businessModel.penetrationAnalysis.REVENUE',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR,
                  },
                },
                {
                  text: 'corporate.businessModel.penetrationAnalysis.GROWTH',
                  type: TYPE.CIRCLE_MARKER_LINE,
                  before: {
                    bgColor: LINE_COLOR,
                  },
                  after: {
                    bgColor: LINE_COLOR,
                  },
                },
              ]}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
