import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { HandleClickOutside } from '../HandleClickOutside'
import style from './index.module.css'

export const DownloadCsv = ({
  downloadCsv,
  setLoadingDownloadCsv,
  isMultiOption = false,
  options,
}) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const onClick = async () => {
    if (isMultiOption) {
      setIsShow(true)
    } else if (downloadCsv) {
      setIsLoading(true)
      setLoadingDownloadCsv(true)
      await dispatch(downloadCsv())
      setIsLoading(false)
      setLoadingDownloadCsv(false)
    }
  }

  const onClickOption = async (params) => {
    if (downloadCsv) {
      setIsLoading(true)
      setLoadingDownloadCsv(true)
      await dispatch(downloadCsv(params))
      setIsLoading(false)
      setLoadingDownloadCsv(false)
      setIsShow(false)
    }
  }

  const downloadOption = () => {
    return (
      <div className={style['download-popup']}>
        {options.map((option, idx) => {
          return (
            <div className={style['download-popup-item']} key={idx}>
              <a
                onClick={() => onClickOption({ ...option.params })}
                style={{
                  pointerEvents: isLoading ? 'none' : 'auto',
                  marginLeft: 16,
                }}
              >
                {I18n.t(option.text)}
              </a>
            </div>
          )
        })}
      </div>
    )
  }

  return isMultiOption ? (
    <HandleClickOutside handleClickOutside={() => setIsShow(false)}>
      <span
        className={`cursor-pointer align-center d-flex ${style['multi-download']}`}
        style={{
          pointerEvents: isLoading ? 'none' : 'auto',
          marginLeft: 16,
        }}
        onClick={onClick}
      >
        <img
          src={'/iconDownloadCsv.svg'}
          alt="download"
          width={10}
          height={10}
        />
        {isShow && downloadOption()}
      </span>
    </HandleClickOutside>
  ) : (
    <a
      className={`cursor-pointer align-center d-flex`}
      onClick={onClick}
      style={{
        pointerEvents: isLoading ? 'none' : 'auto',
        marginLeft: 16,
      }}
    >
      <img src={'/iconDownloadCsv.svg'} alt="download" width={10} height={10} />
    </a>
  )
}

DownloadCsv.propTypes = {
  downloadCsv: PropTypes.func,
}
