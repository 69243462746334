import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import { MAP_TIME_FILTER } from '../../constants'
import { getLabelQuarterly } from '../../helper'

export const getFinancialMetricsChartData = createAsyncThunk(
  'corporate/overview/toi/GET_FINANCIAL_METRICS_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getFinancialMetricsChartData(data)
      if (response.success) {
        return response.data.map((item) => {
          return {
            ...item,
            keyXAxis:
              data.TimeFilter === MAP_TIME_FILTER.YEARLY
                ? item.year
                : getLabelQuarterly(item),
          }
        })
      }
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
