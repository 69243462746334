import TechnicalChartPanel from '.'
import { Panel } from '../../../common/panel'

const PanelTechnicalChart = ({ panelRefs, sizes, setSizes, panelKey }) => {
  return (
    <Panel
      title={'market.derivative.TECHNICAL_CHART'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <TechnicalChartPanel />
    </Panel>
  )
}

export default PanelTechnicalChart
