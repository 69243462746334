import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../core/services/market/CoveredWarrantService'

export const getPriceDepthTable = createAsyncThunk(
  'market/coveredWarrant/priceDepth/GET_COVERED_WARRANT_PRICE_DEPTH',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getCoveredWarrantPriceDepth(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
