import React, { useRef, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { ReactComponent as IconMenuMoreBlack } from '../../icon/icon-menu-more-black.svg'
import PopupAddSecurities from '../../popup/popupAddSecurities'
import PopupQuickAddSecurities from '../../popup/popupQuickAddSecurities'
import style from '../style.module.css'

const AddSecurities = ({ isCreateChart, viewType }) => {
  const [isShow, setIsShow] = useState(false)
  const setShowPopup = () => {
    setIsShow(true)
  }

  return (
    <div className={style.securities}>
      <QuickAddSecurities isCreateChart={isCreateChart} viewType={viewType} />
      <div className={style.addSecurities} onClick={setShowPopup}>
        <IconMenuMoreBlack
          height={24}
          title={I18n.t('financialChart.ADD_MORE_SECURITIES')}
        />
      </div>
      {isShow && <PopupAddSecurities setIsShow={setIsShow} />}
    </div>
  )
}

export default AddSecurities

const QuickAddSecurities = ({ isCreateChart, viewType }) => {
  const securitiesRef = useRef()

  const [isShow, setIsShow] = useState(isCreateChart)
  const [keySearch, setKeySearch] = useState('')

  const onClose = () => {
    setIsShow(false)
    setKeySearch('')
  }

  return (
    <>
      <div
        className="align-center w-100"
        ref={securitiesRef}
        onClick={() => setIsShow(true)}
      >
        <Span
          style={{
            fontSize: 12,
            fontWeight: 600,
            marginRight: 4,
            whiteSpace: 'nowrap',
          }}
        >
          <Translate value="financialChart.SECURITIES" />
        </Span>
        <div className={style.searchSecurities}>
          <Input
            value={keySearch}
            placeholder={I18n.t('financialChart.SEARCH')}
            className={style.inputSearch}
            onChange={(e) => setKeySearch(e.target.value)}
          />
          <i className={`icon-search-2 ${style.iconSearch}`} />
        </div>
      </div>
      <PopupQuickAddSecurities
        parentRef={securitiesRef}
        isShow={isShow}
        onClose={onClose}
        isShowInputSearch={false}
        keySearch={keySearch}
        viewType={viewType}
      />
    </>
  )
}
