import TotalGDPProxy from '../../../proxies/economy/gdp/TotalGDPProxy'
import { ServiceBase } from '../../ServiceBase'

class TotalGDPService extends ServiceBase {
  getGDPBySector(params) {
    return this.applyMemoryCache(
      'TotalGDPService.getGDPBySector',
      params,
    )(() => TotalGDPProxy.getGDPBySector(params))
  }

  getGDPGrowthBySector(data) {
    return this.applyMemoryCache(
      'TotalGDPService.getGDPGrowthBySector',
      data,
    )(() => TotalGDPProxy.getGDPGrowthBySector(data))
  }

  getGDPCompositionBySector(params) {
    return this.applyMemoryCache(
      'TotalGDPService.getGDPCompositionBySector',
      params,
    )(() => TotalGDPProxy.getGDPCompositionBySector(params))
  }

  changesInGDPComposition(data) {
    return this.getData(() => TotalGDPProxy.changesInGDPComposition(data))
  }

  getGDPTypeName(params) {
    return this.applyMemoryCache(
      'TotalGDPService.getGDPTypeName',
      params,
    )(() => TotalGDPProxy.getGDPTypeName(params))
  }

  downloadGDPBySector(params) {
    return this.getFileDownload(() => TotalGDPProxy.downloadGDPBySector(params))
  }

  getGDPGrowthForecast(params) {
    return this.applyMemoryCache(
      'TotalGDPService.getGDPGrowthForecast',
      params,
    )(() => TotalGDPProxy.getGDPGrowthForecast(params))
  }

  downloadGDPGrowthForecast(params) {
    return this.getFileDownload(() =>
      TotalGDPProxy.downloadGDPGrowthForecast(params),
    )
  }

  getMaxPeriod(params) {
    return this.applyMemoryCache(
      'TotalGDPService.getMaxPeriod',
      params,
    )(() => TotalGDPProxy.getMaxPeriod(params))
  }
}

export default new TotalGDPService()
