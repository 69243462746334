import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class PriceStatisticProxy extends ProxyBase {
  getOverview(params) {
    return this.get('PriceStatistic/Overview', params)
  }

  downloadOverview(params) {
    return this.get('PriceStatistic/DownloadOverview', params, 'download')
  }

  getOrderStatistic(params) {
    return this.get('PriceStatistic/OrderStatistic', params)
  }

  downloadOrderStatistic(params) {
    return this.get('PriceStatistic/DownloadOrderStatistic', params, 'download')
  }

  getForeign(params) {
    return this.get('PriceStatistic/Foreign', params)
  }

  downloadForeign(params) {
    return this.get('PriceStatistic/DownloadForeign', params, 'download')
  }

  getByInvestor(params) {
    return this.get('PriceStatistic/ByInvestor', params)
  }

  downloadByInvestor(params) {
    return this.get('PriceStatistic/DownloadByInvestor', params, 'download')
  }

  getProprietary(params) {
    return this.get('PriceStatistic/Proprieatary', params)
  }

  downloadProprietary(params) {
    return this.get('PriceStatistic/DownloadProprieatary', params, 'download')
  }

  getValueByInvestorChart(params) {
    return this.get('PriceStatistic/ValueByInvestorChart', params)
  }

  getNetValueIndividualChart(params) {
    return this.get('PriceStatistics/NetValueIndividualChart', params)
  }

  getNetValueForeignChart(params) {
    return this.get('PriceStatistics/NetValueForeignChart', params)
  }

  getNetValueInstitutionChart(params) {
    return this.get('PriceStatistics/NetValueInstitutionChart', params)
  }

  getNetValueProprietaryChart(params) {
    return this.get('PriceStatistics/NetValueroprietaryChart', params)
  }
}

export default new PriceStatisticProxy(
  ServiceProxyConfig.Market.EquityTrading.ServiceUrl,
)
