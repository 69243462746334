import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeChartTabActive,
  selectChartTabActive,
} from '../chart/store/slice'
import { KEY_DASHBOARD_TABS } from './constants'

const withWrapper = (Component) => () => {
  const dispatch = useDispatch()

  const dashboardActive = useSelector(selectChartTabActive)

  useEffect(() => {
    if (!dashboardActive) {
      dispatch(changeChartTabActive(KEY_DASHBOARD_TABS))
    }
  }, [dashboardActive])

  if (!dashboardActive) return <></>

  return <Component />
}

export default withWrapper
