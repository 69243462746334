import { createSlice } from '@reduxjs/toolkit'
import { ALL_TYPE, TIME } from '../../../common/news/constants'
import { keyBy } from '../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../utils/Datetime'
import { register } from '../../../utils/ReducerRegistry'
import {
  getListNews,
  getListReport,
  getMarketNews,
  getNewsById,
  getNewsCategories,
  getNewsSameCategory,
  getNewsSources,
  getReportTypesAndSources,
  getTrendingNews,
} from './thunk'

const slice = createSlice({
  name: 'market/newsReport',
  initialState: {
    newsById: {},

    marketNews: [],
    listNews: [],
    listNewsLoading: true,
    more: {
      sources: [],
      categories: [],
      moreFilterData: {
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        search: '',
      },
    },

    reportIds: [],
    reportsById: {},
    reCalcWidths: false,
    reportLoading: true,
    report: {
      sources: [],
      types: [],
      filterData: {
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        page: 1,
        totalPage: 1,
      },
    },

    trending: {},
    trendingLoading: true,

    newsSameCategories: [],
    newsSameCategoriesLoading: true,
  },
  reducers: {
    changeReportFilter: (state, action) => {
      state.report.filterData = {
        ...state.report.filterData,
        ...action.payload,
      }
    },
    changeMoreFilter: (state, action) => {
      state.more.moreFilterData = {
        ...state.more.moreFilterData,
        ...action.payload,
      }
    },
    changePage: (state, action) => {
      state.report.filterData.page = action.payload
    },
    resetMore: (state) => {
      state.more.sources = []
      state.more.categories = []
    },
    resetReport: (state) => {
      state.report.sources = []
      state.report.types = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsSameCategory.pending, (state) => {
      state.newsSameCategoriesLoading = true
    })
    builder.addCase(getNewsSameCategory.fulfilled, (state, action) => {
      state.newsSameCategoriesLoading = false
      state.newsSameCategories = action.payload
    })
    builder.addCase(getNewsSameCategory.rejected, (state) => {
      state.newsSameCategoriesLoading = false
    })

    builder.addCase(getTrendingNews.pending, (state) => {
      state.trendingLoading = true
    })
    builder.addCase(getTrendingNews.fulfilled, (state, action) => {
      state.trendingLoading = false
      state.trending = action.payload.length > 0 ? action.payload[0] : {}
    })
    builder.addCase(getTrendingNews.rejected, (state) => {
      state.trendingLoading = false
    })

    builder.addCase(getMarketNews.pending, (state) => {
      state.marketNewsLoading = true
    })
    builder.addCase(getMarketNews.fulfilled, (state, action) => {
      state.marketNewsLoading = false
      state.marketNews = action.payload
    })
    builder.addCase(getMarketNews.rejected, (state) => {
      state.marketNewsLoading = false
    })

    builder.addCase(getListNews.pending, (state) => {
      state.listNewsLoading = true
    })
    builder.addCase(getListNews.fulfilled, (state, action) => {
      state.listNewsLoading = false
      state.listNews = action.payload
    })
    builder.addCase(getListNews.rejected, (state) => {
      state.listNewsLoading = false
    })

    builder.addCase(getNewsById.pending, (state) => {
      state.newsByIdLoading = true
    })
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      state.newsByIdLoading = false
      state.newsById = action.payload || {}
    })
    builder.addCase(getNewsById.rejected, (state) => {
      state.newsByIdLoading = false
    })

    builder.addCase(getNewsSources.pending, (state) => {
      state.newsSourcesLoading = true
    })
    builder.addCase(getNewsSources.fulfilled, (state, action) => {
      state.newsSourcesLoading = false
      state.more.sources = action.payload
    })
    builder.addCase(getNewsSources.rejected, (state) => {
      state.newsSourcesLoading = false
    })

    builder.addCase(getNewsCategories.pending, (state) => {
      state.newsCategoriesLoading = true
    })
    builder.addCase(getNewsCategories.fulfilled, (state, action) => {
      state.newsCategoriesLoading = false
      state.more.categories = action.payload
    })
    builder.addCase(getNewsCategories.rejected, (state) => {
      state.newsCategoriesLoading = false
    })

    builder.addCase(getListReport.pending, (state) => {
      state.reportLoading = true
    })
    builder.addCase(getListReport.fulfilled, (state, action) => {
      state.reportLoading = false
      const { data, totalPage, page } = action.payload

      state.report.filterData.page = page
      state.report.filterData.totalPage = totalPage

      const formatData = data.map((item, index) => ({ ...item, index }))
      state.reportIds = formatData.map((item) => item.index)
      state.reportsById = keyBy(formatData, 'index')
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getListReport.rejected, (state) => {
      state.reportLoading = false
    })

    builder.addCase(getReportTypesAndSources.pending, (state) => {
      state.reportTypesAndSourcesLoading = true
    })
    builder.addCase(getReportTypesAndSources.fulfilled, (state, action) => {
      state.reportTypesAndSourcesLoading = false
      state.report.types = action.payload.types
      state.report.sources = action.payload.sources
    })
    builder.addCase(getReportTypesAndSources.rejected, (state) => {
      state.reportTypesAndSourcesLoading = false
    })
  },
})

export const selectNewsSameCategories = (state) =>
  state[slice.name].newsSameCategories
export const selectNewsSameCategoriesLoading = (state) =>
  state[slice.name].newsSameCategoriesLoading

export const selectNewsById = (state) => state[slice.name].newsById
export const selectNewsByIdLoading = (state) =>
  state[slice.name].newsByIdLoading

export const selectListNews = (state) => state[slice.name].listNews
export const selectListNewsLoading = (state) =>
  state[slice.name].listNewsLoading
export const selectMoreFilterData = (state) =>
  state[slice.name].more.moreFilterData
export const selectMoreSources = (state) => state[slice.name].more.sources
export const selectMoreCategories = (state) => state[slice.name].more.categories
export const selectNewsSourcesLoading = (state) =>
  state[slice.name].newsSourcesLoading
export const selectNewsCategoriesLoading = (state) =>
  state[slice.name].newsCategoriesLoading

export const selectTrending = (state) => state[slice.name].trending
export const selectTrendingLoading = (state) =>
  state[slice.name].trendingLoading

export const selectReportIds = (state) => state[slice.name].reportIds
export const selectReport = (rowId, colId) => (state) =>
  state[slice.name].reportsById[rowId][colId]
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectReportTypesAndSourcesLoading = (state) =>
  state[slice.name].reportTypesAndSourcesLoading
export const selectReportLoading = (state) => state[slice.name].reportLoading
export const selectReportFilterData = (state) =>
  state[slice.name].report.filterData
export const selectReportSources = (state) => state[slice.name].report.sources
export const selectReportTypes = (state) => state[slice.name].report.types

export const selectMarketNews = (state) => state[slice.name].marketNews
export const selectMarketNewsLoading = (state) =>
  state[slice.name].marketNewsLoading

export const {
  changeReportFilter,
  changeMoreFilter,
  changePage,
  resetMore,
  resetReport,
} = slice.actions

register(slice.name, slice.reducer)
