import indexStatisticProxy from '../../../proxies/market/marketInDepth/IndexStatisticProxy'
import { ServiceBase } from '../../ServiceBase'

export class IndexStatisticService extends ServiceBase {
  getIndexStatisticsByInvestorOverview(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsByInvestorOverview',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsByInvestorOverview(params))
  }

  getIndexStatisticsByInvestorDetail(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsByInvestorDetail',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsByInvestorDetail(params))
  }

  downloadIndexStatisticsByInvestorOverview(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadIndexStatisticsByInvestorOverview(params),
    )
  }

  downloadIndexStatisticsByInvestorDetail(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadIndexStatisticsByInvestorDetail(params),
    )
  }
  getIndexOrderStatisticsOverview(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexOrderStatisticsOverview',
      params,
    )(() => indexStatisticProxy.getIndexOrderStatisticsOverview(params))
  }

  getIndexOrderStatisticsDetail(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexOrderStatisticsDetail',
      params,
    )(() => indexStatisticProxy.getIndexOrderStatisticsDetail(params))
  }

  downloadIndexOrderStatisticsOverview(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadIndexOrderStatisticsOverview(params),
    )
  }

  downloadIndexOrderStatisticsDetail(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadIndexOrderStatisticsDetail(params),
    )
  }

  getIndexStatisticsOverviewOverview(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOverviewOverview',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsOverviewOverview(params))
  }

  getIndexStatisticsOverviewDetail(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOverviewDetail',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsOverviewDetail(params))
  }

  downloadStatisticsOverviewOverview(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadStatisticsOverviewOverview(params),
    )
  }

  downloadStatisticsOverviewDetail(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadStatisticsOverviewDetail(params),
    )
  }

  getIndexStatisticsForeignOverview(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsForeignOverview',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsForeignOverview(params))
  }

  getIndexStatisticsForeignDetail(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsForeignDetail',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsForeignDetail(params))
  }

  downloadStatisticsForeignOverview(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadStatisticsForeignOverview(params),
    )
  }

  downloadStatisticsForeignDetail(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadStatisticsForeignDetail(params),
    )
  }

  getProprietaryOverview(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getProprietaryOverview',
      params,
    )(() => indexStatisticProxy.getProprietaryOverview(params))
  }

  getProprietaryDetail(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getProprietaryDetail',
      params,
    )(() => indexStatisticProxy.getProprietaryDetail(params))
  }

  downloadProprietaryOverview(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadProprietaryOverview(params),
    )
  }

  downloadProprietaryDetail(params) {
    return this.getFileDownload(() =>
      indexStatisticProxy.downloadProprietaryDetail(params),
    )
  }

  getStatisticsValueByInvestorChart(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getStatisticsValueByInvestorChart',
      params,
    )(() => indexStatisticProxy.getStatisticsValueByInvestorChart(params))
  }

  getStatisticsNetTradingValueForeignChart(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getStatisticsNetTradingValueForeignChart',
      params,
    )(() =>
      indexStatisticProxy.getStatisticsNetTradingValueForeignChart(params),
    )
  }

  getStatisticsNetTradingValueIndividualChart(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getStatisticsNetTradingValueIndividualChart',
      params,
    )(() =>
      indexStatisticProxy.getStatisticsNetTradingValueIndividualChart(params),
    )
  }

  getStatisticsNetTradingValueInstitutionChart(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getStatisticsNetTradingValueInstitutionChart',
      params,
    )(() =>
      indexStatisticProxy.getStatisticsNetTradingValueInstitutionChart(params),
    )
  }

  getStatisticsNetTradingValueProprietaryChart(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getStatisticsNetTradingValueProprietaryChart',
      params,
    )(() =>
      indexStatisticProxy.getStatisticsNetTradingValueProprietaryChart(params),
    )
  }

  getIndexStatisticsByInvestorOverviewTotalAvg(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsByInvestorOverviewTotalAvg',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsByInvestorOverviewTotalAvg(params),
    )
  }

  getIndexStatisticsByInvestorDetailTotalAvg(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsByInvestorDetailTotalAvg',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsByInvestorDetailTotalAvg(params),
    )
  }

  getIndexStatisticsOverviewTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOverviewTotal',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsOverviewTotal(params))
  }

  getIndexStatisticsOverviewDetailTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOverviewDetailTotal',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsOverviewDetailTotal(params))
  }

  getIndexStatisticsOrderStatisticsOverviewTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOrderStatisticsOverviewTotal',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsOrderStatisticsOverviewTotal(
        params,
      ),
    )
  }

  getIndexStatisticsOrderStatisticsDetailTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsOrderStatisticsDetailTotal',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsOrderStatisticsDetailTotal(params),
    )
  }

  getIndexStatisticsProprietaryOverviewTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsProprietaryOverviewTotal',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsProprietaryOverviewTotal(params),
    )
  }

  getIndexStatisticsProprietaryDetailTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsProprietaryDetailTotal',
      params,
    )(() =>
      indexStatisticProxy.getIndexStatisticsProprietaryDetailTotal(params),
    )
  }

  getIndexStatisticsForeignOverviewTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsForeignOverviewTotal',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsForeignOverviewTotal(params))
  }

  getIndexStatisticsForeignDetailTotal(params) {
    return this.applyMemoryCache(
      'IndexStatisticService.getIndexStatisticsForeignDetailTotal',
      params,
    )(() => indexStatisticProxy.getIndexStatisticsForeignDetailTotal(params))
  }
}

export default new IndexStatisticService()
