import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { timeTypeOptions } from '../../../constants'
import { formatDataOtherChart, getTranslateSourceNote } from '../../../helps'
import { ChartComponent } from './ChartComponent'
import { chartFormat } from './constants'
import {
  changeTimeType,
  selectChartData,
  selectChartLoading,
} from './store/slice'
import { getOperatingIncomeChartData } from './store/thunk'

export const OperatingIncomeChart = ({ width, height, dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)

  // Use state
  const [timeType, setTimeType] = useState(timeTypeOptions[0].value)

  // Get data
  const getData = useMemo(() => {
    return formatDataOtherChart(data, chartFormat, timeType)
  }, [timeType, data])

  const getSourceNote = useMemo(() => {
    return getTranslateSourceNote(getData, true)
  }, [locale, getData])

  // Actions
  const onChangeTimeType = (item) => {
    setTimeType(item.value)
    dispatch(changeTimeType(item.value))
  }

  // Use effect
  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId) return
    dispatch(
      getOperatingIncomeChartData({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        TimeFrequency: timeType,
      }),
    )
  }, [timeType, sectorInfo, dataType])

  return (
    <div id="operatingIncomeChart">
      <SizeTracker>
        {(size) => (
          <>
            <div data-html2canvas-ignore="true">
              <DispatchActionTab
                data={timeTypeOptions}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={timeType}
                onChangeTab={onChangeTimeType}
              />
            </div>
            {size.height && (
              <ChartComponent
                data={getData}
                width={width}
                height={height - size.height}
                loading={loading}
              />
            )}
            <Span
              className="canvas-chart-title t-left"
              style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}
            >
              {getSourceNote}
            </Span>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
