import React, { useState } from 'react'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import { typeTreeItem } from '../constants'
import DashboardItem from './DashboardItem'
import style from './style.module.css'

const GroupDashboard = ({
  groupDashboard,
  childrenStyle,
  iconCollapse,
  iconExpand,
  groupDashboardClass,
  dashboardClass,
  selectMultipleItems,
  isShowChart,
  chartClass,
}) => {
  const [collapseOpen, setCollapseOpen] = useState(true)

  const handleCollapse = () => {
    if (groupDashboard.children) {
      setCollapseOpen(!collapseOpen)
    }
  }

  return (
    <>
      <div
        className={`${style.groupDashBoard}  ${groupDashboardClass}`}
        onClick={handleCollapse}
      >
        <div className="align-center">
          {groupDashboard.children ? (
            <>
              {collapseOpen ? (
                <i
                  className={`${iconExpand || 'icon-caret-down'} ${
                    style.iconCollapse
                  }`}
                  style={{ fontSize: 8, color: '#b0b7bb' }}
                />
              ) : (
                <i
                  className={`${iconCollapse || 'icon-caret-right'} ${
                    style.iconCollapse
                  }`}
                  style={{ fontSize: 8, color: '#b0b7bb' }}
                />
              )}
            </>
          ) : (
            <div style={{ width: 10 }} className={style.iconCollapse} />
          )}
          <TextEllipsisStaticLine
            val={groupDashboard.name}
            lineClamp={1}
            isI18n={false}
          />
        </div>
      </div>
      {collapseOpen &&
        groupDashboard.children &&
        groupDashboard.children.map((dashboard) => (
          <DashboardItem
            key={typeTreeItem.DASHBOARD + dashboard.id}
            dashboard={dashboard}
            dashboardClass={dashboardClass}
            chartClass={chartClass}
            childrenStyle={childrenStyle}
            iconCollapse={iconCollapse}
            iconExpand={iconExpand}
            selectMultipleItems={selectMultipleItems}
            isShowChart={isShowChart}
          />
        ))}
    </>
  )
}

export default GroupDashboard
