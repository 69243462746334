import PropTypes from 'prop-types'
import style from './style.module.css'

const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      <span className={style.w25}>{value.ticker}</span>
      <span className={style.textLongW75}>{value.coveredWarrantName}</span>
    </div>
  )
}

export default DropdownItem

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
