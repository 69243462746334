import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { INDICATORS } from '../constant'
import { getCorpDetailMetrics } from './thunk'

const initialState = {
  valueById: {},
  years: [],
  rowIds: [],
  loading: false,
  reCalcWiths: false,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/corp/detailMetrics',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorpDetailMetrics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCorpDetailMetrics.fulfilled, (state, action) => {
      const data = {}
      const years = []
      action.payload?.forEach((item) => {
        if (!years.includes(item.realYear)) {
          years.push(item.realYear)
        }

        Object.values(INDICATORS).forEach((indicator) => {
          const dataKey = `${item.ticker}-${indicator}`
          if (!data[dataKey]) data[dataKey] = {}
          data[dataKey][item.realYear] = item[indicator]
        })
      })

      state.rowIds = Object.keys(data)
      state.years = years.sort().map(String)
      state.valueById = data
      state.reCalcWiths = !state.reCalcWiths
      state.loading = false
    })
    builder.addCase(getCorpDetailMetrics.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectData = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueById[id], attr)
export const selectYears = (state) => state[slice.name].years
export const selectRowIds = (state) => state[slice.name].rowIds
export const selectLoading = (state) => state[slice.name].loading
export const selectReCalcWiths = (state) => state[slice.name].reCalcWiths

export const { resetStore } = slice.actions

register(slice.name, slice.reducer)
