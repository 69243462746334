import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DownloadCsv } from '../../../common/downloadCsv'
import HistoricalDeals from './HistoricalDeals'
import OwnerShip from './OwnerShip'
import { changeLoadingDownloadCsv } from './store/slice'
import {
  downloadShareHolderTooltip_Historical,
  downloadShareHolderTooltip_Ownership,
  getHistoricalTooltip,
  getOwnershipTooltip,
} from './store/thunk'
import style from './style.module.css'

const tabs = [
  {
    index: 0,
    name: 'corporate.ownership.hoverTooltip.HISTORICAL_DEALS',
    children: <HistoricalDeals />,
  },
  {
    index: 1,
    name: 'corporate.ownership.hoverTooltip.OWNERSHIP',
    children: <OwnerShip />,
  },
]

const TooltipInfo = ({ additionalData, boundingClientRect, isPopoverTop }) => {
  const dispatch = useDispatch()
  const [active, setActive] = useState(0)
  const { organizationId, ownerOrganizationId, ownerPersonId } = additionalData

  const objDownload = {
    OrganizationId: organizationId,
    OwnerOrganizationId: ownerOrganizationId,
    OwnerPersonId: ownerPersonId,
  }

  const mapDetailTooltipTab = {
    SHARE_HOLDER_TOOLTIP_HISTORICAL: 0,
    SHARE_HOLDER_TOOLTIP_OWNERSHIP: 1,
  }

  const mapFucHandleDownload = {
    [mapDetailTooltipTab.SHARE_HOLDER_TOOLTIP_HISTORICAL]:
      downloadShareHolderTooltip_Historical(objDownload),
    [mapDetailTooltipTab.SHARE_HOLDER_TOOLTIP_OWNERSHIP]:
      downloadShareHolderTooltip_Ownership(objDownload),
  }

  const handleLoadingDownloadCsv = (val) => {
    dispatch(changeLoadingDownloadCsv(val))
  }

  useEffect(() => {
    dispatch(
      getHistoricalTooltip({
        OrganizationId: organizationId,
        OwnerOrganizationId: ownerOrganizationId,
        OwnerPersonId: ownerPersonId,
      }),
    )

    dispatch(
      getOwnershipTooltip({
        OrganizationId: organizationId,
        OwnerOrganizationId: ownerOrganizationId,
        OwnerPersonId: ownerPersonId,
      }),
    )
  }, [additionalData])

  return (
    <div
      className={`${style.moreInfoContainer} ${
        isPopoverTop ? style.dropdownTop : ''
      }`}
      style={{ left: boundingClientRect.width / 2 - 50 }}
    >
      <div className={style.moreInfoContent}>
        <div className={style.additionalOptions}>
          <ul className="list-check">
            {tabs.map((item, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    className="radiobox radiobox2"
                    name="info"
                    checked={active === item.index ? true : false}
                    onChange={() => setActive(item.index)}
                  />
                  <Translate
                    value={item.name}
                    className={active === item.index ? style.activeOption : ''}
                  />
                </label>
              </li>
            ))}
          </ul>
          <DownloadCsv
            downloadCsv={() => mapFucHandleDownload[active]}
            setLoadingDownloadCsv={handleLoadingDownloadCsv}
          />
        </div>
        {tabs[active].children}
      </div>
    </div>
  )
}

export default TooltipInfo
