import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { SEARCH_TIME_FRAME } from '../../constants'
import { listFilter } from '../constants'
import { getValueNetBreakDown } from './thunk'

const initialState = {
  loading: true,
  stateTimeFrame: SEARCH_TIME_FRAME.ONE_MONTH,
  isInstitution: listFilter[0].value,
  filterName: listFilter[0].name,
  dataValueNetBreakDown: [],
}

export const slice = createSlice({
  name: 'market/marketInDepth/netValueBreakdown',
  initialState,
  reducers: {
    changeStateTimeFrame: (state, action) => {
      state.stateTimeFrame = action.payload
    },
    changeIsInstitution: (state, action) => {
      state.isInstitution = action.payload
    },
    changeFilterName: (state, action) => {
      state.filterName = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getValueNetBreakDown.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValueNetBreakDown.fulfilled, (state, action) => {
      state.loading = false
      state.dataValueNetBreakDown = action.payload
    })
    builder.addCase(getValueNetBreakDown.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading

export const selectStateTimeFrame = (state) => state[slice.name].stateTimeFrame
export const selectFilter = (state) => state[slice.name].isInstitution
export const selectFilterName = (state) => state[slice.name].filterName

export const selectValueNetBreakDown = (state) =>
  state[slice.name].dataValueNetBreakDown

export const { changeStateTimeFrame, changeIsInstitution, changeFilterName } =
  slice.actions

register(slice.name, slice.reducer)
