import { useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../../configs/Global'
import { Realtime } from '../../../../../common/Realtime'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectIndexInfo } from '../../../../../common/topInfo/indexTopInfo/store/slice'
import { tableDataTypes } from '../../constans'
import { selectTableFilter, subscribeIndexIntraday } from '../../store/slice'
import { Filter } from './Filter'
import { TableEOD } from './TableEOD'
import { TableRealtime } from './TableRealtime'

export const Intraday = ({ sizes }) => {
  const tableFilter = useSelector(selectTableFilter)
  const indexInfo = useSelector(selectIndexInfo)

  return (
    <SizeTracker>
      {(size) => (
        <>
          <Filter sizes={sizes} />
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              {tableFilter.dataType === tableDataTypes.REALTIME ? (
                <>
                  <TableRealtime />
                  <Realtime
                    channel={ChannelConfig.IndexChannel + indexInfo.groupId}
                    action={subscribeIndexIntraday}
                  >
                    <></>
                  </Realtime>
                </>
              ) : (
                <TableEOD />
              )}
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
