import { TIME_RANGES } from '../../common/tabs/DispatchActionTab'

export const getId = (data) => {
  return (
    data.rowGroup +
    (data.icbId || data.groupId ? '-' : '') +
    (data.icbId || data.groupId || '')
  )
}

export const getRowGroup = (id) => {
  return id?.split('-')[0]
}

export const getIcbId = (colId) => {
  const [icbId] = colId.split('-').reverse()
  return +icbId
}

export const is1D = (timeRange) => {
  return timeRange === TIME_RANGES['1D']
}
