import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import style from '../../index.module.css'
import { YEARS_FILTER } from '../common/constants'
import ChartComponent from './ChartComponent'
import {
  changeCurrentSelection,
  changeYear,
  selectCurrentSelection,
  selectDataChart,
  selectListSelection,
  selectLoading,
  selectMaxYear,
  selectYear,
  setIsFirstTimeRenderChart,
} from './store/slice'
import { getMaxDate, getTopExportMarketsOfVN } from './store/thunk'

export const TopMarketChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const listSelection = useSelector(selectListSelection)
  const currentSelection = useSelector(selectCurrentSelection)
  const currentYear = useSelector(selectYear)
  const maxYear = useSelector(selectMaxYear)
  const isLoading = useSelector(selectLoading)
  const locale = useSelector((state) => state.i18n.locale)

  const handleTypeChange = (val) => {
    dispatch(changeCurrentSelection(val))
    dispatch(setIsFirstTimeRenderChart(false))
  }
  const handleYearChange = (val) => {
    dispatch(changeYear(val))
  }

  useEffect(() => {
    dispatch(getMaxDate({ FisheryConst: 'ExportByMarket' }))
  }, [])

  useEffect(() => {
    if (currentSelection && maxYear) {
      dispatch(
        getTopExportMarketsOfVN({
          Year: currentYear ?? maxYear,
          Ids: String(currentSelection),
        }),
      )
    }
  }, [currentYear, maxYear, currentSelection, locale])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <SizeTracker>
      {(size) => {
        return (
          <div className="h-100">
            <div className="row j-b">
              <div
                className={`${style.inputDropdown}`}
                style={{ maxWidth: 0.4 * width }}
              >
                <SelectCustom
                  value={currentSelection}
                  isI18n={false}
                  selectData={listSelection}
                  handleChange={handleTypeChange}
                />
              </div>
              <div
                className={`${style.inputDropdown}`}
                style={{ width: '100px' }}
              >
                <SelectCustom
                  value={currentYear ?? maxYear}
                  isI18n={false}
                  selectData={YEARS_FILTER}
                  handleChange={handleYearChange}
                />
              </div>
            </div>
            {size.height && (
              <div
                id="topMarketChart"
                className="position-relative"
                style={{ height: height - size.height }}
              >
                {dataChart.length ? (
                  <ChartComponent
                    data={dataChart}
                    width={width}
                    height={height - size.height}
                    keyXAxis="name"
                    barKey={'value'}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}
