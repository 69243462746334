import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Panel } from '../../../common/panel'
import { redirectLinkIcbPdf } from '../../../utils/Link'
import style from './topInfo.module.css'

const PanelNoteIcb = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isOnlyResize
    >
      <Span className={style.noteIcb} style={{ fontSize: 11 }}>
        <div>
          <Translate value="common.noteIcb.NOTE_1" />
          <a onClick={redirectLinkIcbPdf}>
            <Translate value="common.noteIcb.NOTE_2" />
          </a>
        </div>
      </Span>
    </Panel>
  )
}

export default PanelNoteIcb
