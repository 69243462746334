import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import { convertDateFromDatePicker } from '../../../../common/calendar/helper'
import ButtonDateCalendar from '../../../../common/calendar/inputs/ButtonDateCalendar'
import { Span } from '../../../../common/html/Span'
import {
  RADIO_TIME_FRAME_TRADING_DATA,
  TIME_FRAME_TRADING_DATA,
} from '../constant'
import {
  changeFilterDateFrom,
  changeFilterDateTo,
  changeFilterTime,
  selectFilter,
} from '../store/slice'
import style from './style.module.css'

const RadioTime = ({ minDate, maxDate }) => {
  const dispatch = useDispatch()
  const currentDate = [new Date()]

  const filter = useSelector(selectFilter)

  const setDateFrom = (value) => {
    dispatch(changeFilterDateFrom(value || ''))
  }

  const setDateTo = (value) => {
    dispatch(changeFilterDateTo(value || ''))
  }

  const handleChangeFilterTime = (e) => {
    dispatch(changeFilterTime(e.target.value))
  }

  return (
    <div>
      <div className={style.titleListRadio}>
        <Span style={{ fontSize: 12 }}>
          <Translate value="tool.dataExplorer.tradingData.LB_TIME" />
        </Span>
      </div>
      <div>
        <ul
          className={`d-flex list-check ${style.listCheck} ${style.filterTime}`}
        >
          {RADIO_TIME_FRAME_TRADING_DATA.map((item) => {
            return (
              <li key={item.value} className={style.pdRadio}>
                <label className="fs-12 align-center">
                  <input
                    type="radio"
                    className={`radiobox radiobox2 ${style.radio}`}
                    name={item.name}
                    value={item.value}
                    checked={item.value === filter.time}
                    onChange={handleChangeFilterTime}
                  />
                  <Span
                    style={{
                      color: item.value === filter.time ? '#2f9aee' : '',
                    }}
                  >
                    <Translate value={item.title} />
                  </Span>
                </label>
              </li>
            )
          })}
        </ul>
        <ul
          style={{ marginLeft: 18 }}
          className={`list-check ${style.listCheck}`}
        >
          <li className={style.pdRadio}>
            <label className="fs-12 align-center">
              <input
                type="radio"
                className={`radiobox radiobox2 ${style.radio}`}
                name="times"
                value={TIME_FRAME_TRADING_DATA.YTB}
                checked={TIME_FRAME_TRADING_DATA.YTB === filter.time}
                onChange={handleChangeFilterTime}
              />
              <div className="align-center">
                <div className={`${style.calendar} ml-25`}>
                  <Span>
                    <Translate
                      value={'tool.dataExplorer.tradingData.LB_FROM'}
                    />
                  </Span>
                  <div className="date-select">
                    <CalendarPopper
                      date={filter?.dateFrom ? new Date(filter?.dateFrom) : ''}
                      minDate={
                        minDate
                          ? new Date(convertDateFromDatePicker(minDate))
                          : undefined
                      }
                      handleChange={setDateFrom}
                      CustomInput={ButtonDateCalendar}
                      hightlightDates={currentDate}
                      customHeader={HeaderCalendarPopper({
                        startYear: minDate
                          ? new Date(minDate).getFullYear()
                          : new Date().getFullYear() - 10,
                        endYear: maxDate
                          ? new Date(maxDate).getFullYear()
                          : new Date().getFullYear(),
                      })}
                    />
                  </div>
                </div>
                <div className={`${style.calendar} ml-25`}>
                  <Span>
                    <Translate value={'tool.dataExplorer.tradingData.LB_TO'} />
                  </Span>
                  <div className="date-select">
                    <CalendarPopper
                      date={filter?.dateTo ? new Date(filter?.dateTo) : ''}
                      maxDate={
                        maxDate
                          ? new Date(convertDateFromDatePicker(maxDate))
                          : undefined
                      }
                      handleChange={setDateTo}
                      CustomInput={ButtonDateCalendar}
                      hightlightDates={currentDate}
                      customHeader={HeaderCalendarPopper({
                        startYear: minDate
                          ? new Date(minDate).getFullYear()
                          : new Date().getFullYear() - 10,
                        endYear: maxDate
                          ? new Date(maxDate).getFullYear()
                          : new Date().getFullYear(),
                      })}
                    />
                  </div>
                </div>
              </div>
            </label>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default RadioTime
