import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelExport } from './export/PanelExport'
import { PanelInventory } from './inventory/PanelInventory'
import { PanelNewsAndReport } from './newsAndReports/PanelNewsReports'
import { PanelOverview } from './overview/PanelOverview'
import { PanelProductionConsumption } from './productionConsumption/PanelProductionConsumption'

const MAP_KEY = {
  OVERVIEW: 'OVERVIEW',
  NEWS_REPORT: 'NEWS AND REPORT',
  PRODUCTION_COMSUMPTION_CHART: 'PRODUCTION COMSUMPTION CHART',
  INVENTORY_CHART: 'INVENTORY CHART',
  EXPORT_CHART: 'EXPORT CHART',
}

const MIN_SIZE = {
  [`${MAP_KEY.OVERVIEW}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.NEWS_REPORT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.PRODUCTION_COMSUMPTION_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.INVENTORY_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 - COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.EXPORT_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.OVERVIEW}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.OVERVIEW}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.OVERVIEW}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NEWS_REPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.NEWS_REPORT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.PRODUCTION_COMSUMPTION_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.INVENTORY_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.EXPORT_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.NEWS_REPORT}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.NEWS_REPORT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.NEWS_REPORT}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRODUCTION_COMSUMPTION_CHART}`]: {
    width: `calc(33% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.PRODUCTION_COMSUMPTION_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRODUCTION_COMSUMPTION_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.INVENTORY_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INVENTORY_CHART}`]: {
    width: `calc(33% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(33% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.INVENTORY_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.INVENTORY_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.EXPORT_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXPORT_CHART}`]: {
    width: `calc(34% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(66% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.6) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelOverview
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OVERVIEW}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelNewsAndReport
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NEWS_REPORT}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelProductionConsumption
          panelRefs={panelRefs}
          panelKey={MAP_KEY.PRODUCTION_COMSUMPTION_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelInventory
          panelRefs={panelRefs}
          panelKey={MAP_KEY.INVENTORY_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelExport
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
