import { OVERVIEW_GROUP_ID } from '../../../common/topInfo/stockTopInfo/constants'

export const listTimeFilter = [
  {
    label: 'corporate.overview.financial.QUARTERLY',
    value: 'Quarterly',
  },
  {
    label: 'corporate.overview.financial.YEARLY',
    value: 'Yearly',
  },
]

export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
  LEVEL_3: 3,
}

export const financialMetricTableConstants = {
  bank: [
    {
      index: 'p_l',
      childrenId: ['toi', 'nii', 'nif', 'operating_expense', 'ppop', 'pbt'],
      name: 'corporate.overview.financial.bank.P & L',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'toi',
      childrenId: ['toi_growth'],
      name: 'corporate.overview.financial.bank.TOI',
      key: {
        Yearly: 'iS38',
        Quarterly: 'iS38',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'toi_growth',
      parentId: 'toi',
      name: 'corporate.overview.financial.bank.TOI Growth (YoY)',
      key: {
        Yearly: 'rT0302',
        Quarterly: 'rT0302',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'nii',
      childrenId: ['nii_growth'],
      name: 'corporate.overview.financial.bank.NII',
      key: {
        Yearly: 'iS27',
        Quarterly: 'iS27',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'nii_growth',
      parentId: 'nii',
      name: 'corporate.overview.financial.bank.NII Growth (YoY)',
      key: {
        Yearly: 'rT0300',
        Quarterly: 'rT0300',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'nfi',
      childrenId: ['nfi_toi'],
      name: 'corporate.overview.financial.bank.NFI',
      key: {
        Yearly: 'iS30',
        Quarterly: 'iS30',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'nfi_toi',
      parentId: 'nfi',
      name: 'corporate.overview.financial.bank.NFI/TOI',
      key: {
        Yearly: 'rT0319',
        Quarterly: 'rT0319',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'operating_expense',
      childrenId: ['cir'],
      name: 'corporate.overview.financial.bank.Operating Expense',
      key: {
        Yearly: 'iS39',
        Quarterly: 'iS39',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'cir',
      parentId: 'operating_expense',
      name: 'corporate.overview.financial.bank.CIR',
      key: {
        Yearly: 'rT0252',
        Quarterly: 'rT0252',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'ppop',
      childrenId: ['ppop_growth'],
      name: 'corporate.overview.financial.bank.PPoP',
      key: {
        Yearly: 'iS40',
        Quarterly: 'iS40',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'ppop_growth',
      parentId: 'ppop',
      name: 'corporate.overview.financial.bank.PPoP Growth (YoY)',
      key: {
        Yearly: 'rT0299',
        Quarterly: 'rT0299',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'pbt',
      childrenId: ['pbt_growth'],
      name: 'corporate.overview.financial.bank.PBT',
      key: {
        Yearly: 'iS16',
        Quarterly: 'iS16',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'pbt_growth',
      parentId: 'pbt',
      name: 'corporate.overview.financial.bank.PBT Growth (YoY)',
      key: {
        Yearly: 'rT0160',
        Quarterly: 'rT0160',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'loans_asset_quality',
      childrenId: [
        'total_assets',
        'earning_assets',
        'nim',
        'credit',
        'loan_to_customers',
        'provisions',
        'deposit_from_customers',
        'owner_equity',
        'loan_to_total_assets',
        'ldr',
      ],
      name: 'corporate.overview.financial.bank.Loans & Asset Quality',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'total_assets',
      childrenId: ['total_assets_growth'],
      name: 'corporate.overview.financial.bank.Total Assets',
      key: {
        Yearly: 'bS64',
        Quarterly: 'bS64',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'total_assets_growth',
      parentId: 'total_assets',
      name: 'corporate.overview.financial.bank.Total Assets Growth',
      key: {
        Yearly: 'rT0172',
        Quarterly: 'rT0172',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'earning_assets',
      childrenId: ['yoea'],
      name: 'corporate.overview.financial.bank.Earning Assets',
      key: {
        Yearly: 'rT0280',
        Quarterly: 'rT0280',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'yoea',
      parentId: 'earning_assets',
      name: 'corporate.overview.financial.bank.YOEA',
      key: {
        Yearly: 'rT0286',
        Quarterly: 'rT0286',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'nim',
      name: 'corporate.overview.financial.bank.NIM',
      key: {
        Yearly: 'rT0285',
        Quarterly: 'rT0285',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'credit',
      childrenId: ['credit_growth'],
      name: 'corporate.overview.financial.bank.Credit',
      key: {
        Yearly: 'rT0320_11',
        Quarterly: 'rT0320_11',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'credit_growth',
      parentId: 'credit',
      name: 'corporate.overview.financial.bank.Credit Growth (YoY)',
      key: {
        Yearly: 'rT0320',
        Quarterly: 'rT0320',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'loan_to_customers',
      childrenId: ['npl'],
      name: 'corporate.overview.financial.bank.Loan to Customers',
      key: {
        Yearly: 'bS159',
        Quarterly: 'bS159',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'npl',
      parentId: 'loan_to_customers',
      name: 'corporate.overview.financial.bank.NPL',
      key: {
        Yearly: 'rT0269',
        Quarterly: 'rT0269',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'provisions',
      childrenId: ['llcrs'],
      name: 'corporate.overview.financial.bank.Provisions',
      key: {
        Yearly: 'bS160',
        Quarterly: 'bS160',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'llcrs',
      parentId: 'provisions',
      name: 'corporate.overview.financial.bank.LLCrs',
      key: {
        Yearly: 'rT0237',
        Quarterly: 'rT0237',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'deposit_from_customers',
      childrenId: ['deposit_from_customers_growth'],
      name: 'corporate.overview.financial.bank.Deposit from Customers',
      key: {
        Yearly: 'rT0281',
        Quarterly: 'rT0281',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'deposit_from_customers_growth',
      parentId: 'deposit_from_customers',
      name: 'corporate.overview.financial.bank.Deposit from Customers Growth',
      key: {
        Yearly: 'rT0297',
        Quarterly: 'rT0297',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'owner_equity',
      childrenId: ['owner_equity_growth'],
      name: "corporate.overview.financial.bank.Owner's Equity",
      key: {
        Yearly: 'bS98',
        Quarterly: 'bS98',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'owner_equity_growth',
      parentId: 'owner_equity',
      name: "corporate.overview.financial.bank.Owner's Equity Growth",
      key: {
        Yearly: 'rT0174',
        Quarterly: 'rT0174',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'loan_to_total_assets',
      name: 'corporate.overview.financial.bank.Loan to Total Assets',
      key: {
        Yearly: 'rT0266',
        Quarterly: 'rT0266',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'ldr',
      name: 'corporate.overview.financial.bank.LDR',
      key: {
        Yearly: 'rT0277',
        Quarterly: 'rT0277',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'profitability',
      childrenId: ['roa', 'roe', 'gross_margin', 'net_profit_margin'],
      name: 'corporate.overview.financial.bank.Profitability',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'roa',
      name: 'corporate.overview.financial.bank.ROA',
      key: {
        Yearly: 'rT0059',
        Quarterly: 'rT0059',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'roe',
      name: 'corporate.overview.financial.bank.ROE',
      key: {
        Yearly: 'rT0060',
        Quarterly: 'rT0060',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'gross_margin',
      name: 'corporate.overview.financial.bank.Gross Margin',
      key: {
        Yearly: 'rT0050',
        Quarterly: 'rT0050',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'net_profit_margin',
      name: 'corporate.overview.financial.bank.Net Profit Margin',
      key: {
        Yearly: 'rT0054',
        Quarterly: 'rT0054',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'capital_liquidity',
      childrenId: ['car', 'cof', 'casa_ratio', 'current_deposits_for_mlt_loan'],
      name: 'corporate.overview.financial.bank.Capital and Liquidity',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'car',
      name: 'corporate.overview.financial.bank.CAR',
      key: {
        Yearly: 'nB219',
        Quarterly: 'nB219',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'cof',
      name: 'corporate.overview.financial.bank.COF',
      key: {
        Yearly: 'rT0287',
        Quarterly: 'rT0287',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'casa',
      childrenId: ['casa_ratio'],
      name: 'corporate.overview.financial.bank.CASA',
      key: {
        Yearly: 'nB121',
        Quarterly: 'nB121',
      },
      isFormatValue: {
        Yearly: 10 ** 12,
        Quarterly: 10 ** 12,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'casa_ratio',
      parentId: 'casa',
      name: 'corporate.overview.financial.bank.CASA Ratio',
      key: {
        Yearly: 'nB121_11',
        Quarterly: 'nB121_11',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'current_deposits_for_mlt_loan',
      name: 'corporate.overview.financial.bank.Current Deposits for MLT Loan (%)',
      key: {
        Yearly: 'rT0318',
        Quarterly: 'rT0318',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
  ],
  securities: [
    {
      index: 'total_assets',
      childrenId: ['total_assets_growth'],
      name: 'corporate.overview.financial.security.Total Assets',
      key: {
        Yearly: 'bS64',
        Quarterly: 'bS64',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'total_assets_growth',
      parentId: 'total_assets',
      name: 'corporate.overview.financial.security.Total Assets Growth (%YoY)',
      key: {
        Yearly: 'rT0172',
        Quarterly: 'rT0172',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'margin_lending',
      childrenId: ['margin_lending_growth'],
      name: 'corporate.overview.financial.security.Margin Lending',
      key: {
        Yearly: 'nS239',
        Quarterly: 'nS239',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'margin_lending_growth',
      parentId: 'margin_lending',
      name: 'corporate.overview.financial.security.Margin Lending Growth (%YoY)',
      key: {
        Yearly: 'rT0323',
        Quarterly: 'rT0323',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'margin_lending_owner_equity',
      name: "corporate.overview.financial.security.Margin Lending/ Owner's Equity",
      key: {
        Yearly: 'nS307_11',
        Quarterly: 'nS307_11',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'total_equity',
      childrenId: ['total_equity_growth'],
      name: 'corporate.overview.financial.security.Total Equity',
      key: {
        Yearly: 'bS95',
        Quarterly: 'bS95',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'total_equity_growth',
      parentId: 'total_equity',
      name: 'corporate.overview.financial.security.Total Equity Growth (%YoY)',
      key: {
        Yearly: 'rT0173',
        Quarterly: 'rT0173',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'chartered_capital',
      childrenId: ['chartered_capital_growth'],
      name: 'corporate.overview.financial.security.Chartered Capital',
      key: {
        Yearly: 'bS97',
        Quarterly: 'bS97',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'chartered_capital_growth',
      parentId: 'chartered_capital',
      name: 'corporate.overview.financial.security.Chartered Capital Growth (%YoY)',
      key: {
        Yearly: 'rT0174',
        Quarterly: 'rT0174',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'net_debt',
      childrenId: ['total_debt_total_equity'],
      name: 'corporate.overview.financial.security.Net Debt',
      key: {
        Yearly: 'rT0040',
        Quarterly: 'rT0040',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'total_debt_total_equity',
      parentId: 'net_debt',
      name: 'corporate.overview.financial.security.Total Debt/Total Equity',
      key: {
        Yearly: 'rT0041',
        Quarterly: 'rT0041',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
    },
    {
      index: 'brokerage_fee',
      childrenId: ['brokerage_margin'],
      name: 'corporate.overview.financial.security.Brokerage Fee',
      key: {
        Yearly: 'iS114',
        Quarterly: 'iS114',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'brokerage_margin',
      parentId: 'brokerage_fee',
      name: 'corporate.overview.financial.security.Brokerage Margin',
      key: {
        Yearly: 'rT0326',
        Quarterly: 'rT0326',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'gross_profit',
      childrenId: ['gross_margin'],
      name: 'corporate.overview.financial.security.Gross Profit',
      key: {
        Yearly: 'iS5',
        Quarterly: 'iS5',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'gross_margin',
      parentId: 'gross_profit',
      name: 'corporate.overview.financial.security.Gross Margin',
      key: {
        Yearly: 'rT0050',
        Quarterly: 'rT0050',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'net_profit',
      childrenId: ['net_margin'],
      name: 'corporate.overview.financial.security.Net Profit',
      key: {
        Yearly: 'iS20',
        Quarterly: 'iS20',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'net_margin',
      parentId: 'net_profit',
      name: 'corporate.overview.financial.security.Net Margin',
      key: {
        Yearly: 'rT0054',
        Quarterly: 'rT0054',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'roa',
      name: 'corporate.overview.financial.security.ROA',
      key: {
        Yearly: 'rT0059',
        Quarterly: 'rT0059',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'roe',
      name: 'corporate.overview.financial.security.ROE',
      key: {
        Yearly: 'rT0060',
        Quarterly: 'rT0060',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
  ],
  corporate: [
    {
      index: 'profitability',
      childrenId: [
        'net_revenue',
        'net_revenue_growth',
        'net_income',
        'net_profit_margin',
        'gross_margin',
        'ebit_margin',
        'sg_a_net_revenue',
        'roe',
        'roa',
        'roic',
      ],
      name: 'corporate.overview.financial.corporate.Profitability',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'net_revenue',
      childrenId: ['net_revenue_growth'],
      name: 'corporate.overview.financial.corporate.Net Revenue',
      key: {
        Yearly: 'iS3',
        Quarterly: 'iS3',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'net_revenue_growth',
      parentId: ['net_revenue'],
      name: 'corporate.overview.financial.corporate.Net Revenue Growth',
      key: {
        Yearly: 'rT0156',
        Quarterly: 'rT0156',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'net_income',
      childrenId: ['net_profit_margin'],
      name: 'corporate.overview.financial.corporate.Net Income',
      key: {
        Yearly: 'iS20',
        Quarterly: 'iS20',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'net_profit_margin',
      parentId: ['net_income'],
      name: 'corporate.overview.financial.corporate.Net Profit Margin',
      key: {
        Yearly: 'rT0054',
        Quarterly: 'rT0054',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'gross_margin',
      name: 'corporate.overview.financial.corporate.Gross Margin',
      key: {
        Yearly: 'rT0050',
        Quarterly: 'rT0050',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'ebit_margin',
      name: 'corporate.overview.financial.corporate.EBIT Margin',
      key: {
        Yearly: 'rT0052',
        Quarterly: 'rT0052',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'sg_a_net_revenue',
      name: 'corporate.overview.financial.corporate.SG&A/Net Revenue',
      key: {
        Yearly: 'rT0327',
        Quarterly: 'rT0327',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'roe',
      name: 'corporate.overview.financial.corporate.ROE',
      key: {
        Yearly: 'rT0060',
        Quarterly: 'rT0060',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'roa',
      name: 'corporate.overview.financial.corporate.ROA',
      key: {
        Yearly: 'rT0059',
        Quarterly: 'rT0059',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'roic',
      name: 'corporate.overview.financial.corporate.ROIC',
      key: {
        Yearly: 'rT0064',
        Quarterly: 'rT0064',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'capitial_structure',
      childrenId: [
        'total_assets',
        'owner_equity',
        'total_liabilities_total_assets',
        'total_liabilities_owner_equity',
      ],
      name: 'corporate.overview.financial.corporate.Capital Structure',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'total_assets',
      childrenId: ['total_assets_growth'],
      name: 'corporate.overview.financial.corporate.Total Assets',
      key: {
        Yearly: 'bS64',
        Quarterly: 'bS64',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'total_assets_growth',
      parentId: 'total_assets',
      name: 'corporate.overview.financial.corporate.Total Assets Growth',
      key: {
        Yearly: 'rT0172',
        Quarterly: 'rT0172',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'owner_equity',
      childrenId: ['owner_equity_growth'],
      name: "corporate.overview.financial.corporate.Owner's Equity",
      key: {
        Yearly: 'bS97',
        Quarterly: 'bS97',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'owner_equity_growth',
      parentId: 'owner_equity',
      name: "corporate.overview.financial.corporate.Owner's Equity Growth",
      key: {
        Yearly: 'rT0174',
        Quarterly: 'rT0174',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'total_liabilities_total_assets',
      name: 'corporate.overview.financial.corporate.Total Liabilities/Total Assets',
      key: {
        Yearly: 'rT0031',
        Quarterly: 'rT0031',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'total_liabilities_owner_equity',
      name: "corporate.overview.financial.corporate.Total Liabilities/Owner's Equity",
      key: {
        Yearly: 'rT0032',
        Quarterly: 'rT0032',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'solvency_efficiency',
      childrenId: ['current_ratio', 'quick_ratio', 'interest_rate_coverage'],
      name: 'corporate.overview.financial.corporate.Solvency Efficiency',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'current_ratio',
      name: 'corporate.overview.financial.corporate.Current Ratio',
      key: {
        Yearly: 'rT0020',
        Quarterly: 'rT0020',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'quick_ratio',
      name: 'corporate.overview.financial.corporate.Quick Ratio',
      key: {
        Yearly: 'rT0019',
        Quarterly: 'rT0019',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'interest_rate_coverage',
      name: 'corporate.overview.financial.corporate.Interest rate Coverage',
      key: {
        Yearly: 'rT0044',
        Quarterly: 'rT0044',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'operation_efficiency',
      childrenId: [
        'asset_turnover',
        'inventory',
        'days_of_inventory_on_hand',
        'receivables',
        'day_sales_outstanding',
        'trade_payables',
        'number_of_days_of_payables',
        'cash_conversion_cycle',
      ],
      name: 'corporate.overview.financial.corporate.Operation Efficiency',
      level: TABLE_ROW_LEVEL.LEVEL_1,
    },
    {
      index: 'asset_turnover',
      name: 'corporate.overview.financial.corporate.Asset Turnover',
      key: {
        Yearly: 'rT0003',
        Quarterly: 'rT0003',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'inventory',
      childrenId: ['days_of_inventory_on_hand'],
      name: 'corporate.overview.financial.corporate.Inventory',
      key: {
        Yearly: 'bS18',
        Quarterly: 'bS18',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'days_of_inventory_on_hand',
      parentId: 'inventory',
      name: 'corporate.overview.financial.corporate.Days of inventory on hand (DOH)',
      key: {
        Yearly: 'rT0011',
        Quarterly: 'rT0011',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
    },
    {
      index: 'receivables',
      childrenId: ['day_sales_outstanding'],
      name: 'corporate.overview.financial.corporate.Receivables',
      key: {
        Yearly: 'bS10_11',
        Quarterly: 'bS10_11',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'day_sales_outstanding',
      parentId: 'receivables',
      name: 'corporate.overview.financial.corporate.Day Sales Outstanding',
      key: {
        Yearly: 'rT0009',
        Quarterly: 'rT0009',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
    },
    {
      index: 'trade_payables',
      childrenId: ['number_of_days_of_payables'],
      name: 'corporate.overview.financial.corporate.Trade Payables',
      key: {
        Yearly: 'bS67_11',
        Quarterly: 'bS67_11',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'number_of_days_of_payables',
      parentId: 'trade_payables',
      name: 'corporate.overview.financial.corporate.Number of days of payables',
      key: {
        Yearly: 'rT0013',
        Quarterly: 'rT0013',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
    },
    {
      index: 'cash_conversion_cycle',
      name: 'corporate.overview.financial.corporate.Cash Conversion Cycle',
      key: {
        Yearly: 'rT0015',
        Quarterly: 'rT0015',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
  ],
  insurance: [
    {
      index: 'gross_written_premium',
      childrenId: ['gross_written_premium_growth'],
      name: 'corporate.overview.financial.insurance.Gross Written Premium',
      key: {
        Yearly: 'nI153',
        Quarterly: 'nI153',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'gross_written_premium_growth',
      parentId: 'gross_written_premium',
      name: 'corporate.overview.financial.insurance.Gross Written PremiumGrowth (%YoY)',
      key: {
        Yearly: 'rT0329',
        Quarterly: 'rT0329',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'life_insurance',
      childrenId: ['life_insurance_growth'],
      name: 'corporate.overview.financial.insurance.Life Insurance',
      key: {
        Yearly: 'nI154',
        Quarterly: 'nI154',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'life_insurance_growth',
      parentId: 'life_insurance',
      name: 'corporate.overview.financial.insurance.Life Insurance Growth (%YoY)',
      key: {
        Yearly: 'rT0332',
        Quarterly: 'rT0332',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'non_life_insurance',
      childrenId: ['non_life_insurance_growth'],
      name: 'corporate.overview.financial.insurance.Non-Life Insurance',
      key: {
        Yearly: 'nI155',
        Quarterly: 'nI155',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'non_life_insurance_growth',
      parentId: 'non_life_insurance',
      name: 'corporate.overview.financial.insurance.Non-Life Insurance Growth (%YoY)',
      key: {
        Yearly: 'rT0335',
        Quarterly: 'rT0335',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'npatmi',
      childrenId: ['npatmi_growth'],
      name: 'corporate.overview.financial.insurance.NPATMI',
      key: {
        Yearly: 'iS21',
        Quarterly: 'iS21',
      },
      isFormatValue: {
        Yearly: 10 ** 9,
        Quarterly: 10 ** 9,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
    },
    {
      index: 'npatmi_growth',
      parentId: 'npatmi',
      name: 'corporate.overview.financial.insurance.NPATMI Growth (%YoY)',
      key: {
        Yearly: 'iS21_11',
        Quarterly: 'iS21_11',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_3,
      isFormatValToPercent: true,
    },
    {
      index: 'claim_ratio',
      name: 'corporate.overview.financial.insurance.Claim Ratio (%)',
      key: {
        Yearly: 'rT0338',
        Quarterly: 'rT0338',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
    {
      index: 'combined_ratio',
      name: 'corporate.overview.financial.insurance.Combined Ratio (%)',
      key: {
        Yearly: 'rT0339',
        Quarterly: 'rT0339',
      },
      isFormatValue: {
        Yearly: 1,
        Quarterly: 1,
      },
      level: TABLE_ROW_LEVEL.LEVEL_2,
      isFormatValToPercent: true,
    },
  ],
}

export const MAP_INDUSTRY_AND_INDICATOR_DATA = {
  [OVERVIEW_GROUP_ID.BANK]: {
    value: financialMetricTableConstants.bank[1],
    subValue: financialMetricTableConstants.bank[2],
  },
  [OVERVIEW_GROUP_ID.INSURANCE]: {
    value: financialMetricTableConstants.insurance[0],
    subValue: financialMetricTableConstants.insurance[1],
  },
  [OVERVIEW_GROUP_ID.SECURITIES]: {
    value: financialMetricTableConstants.securities[0],
    subValue: financialMetricTableConstants.securities[1],
  },
  [null]: {
    value: financialMetricTableConstants.corporate[1],
    subValue: financialMetricTableConstants.corporate[2],
  },
  0: {
    value: financialMetricTableConstants.corporate[1],
    subValue: financialMetricTableConstants.corporate[2],
  },
}
