import PropTypes from 'prop-types'
import React from 'react'
import { ScrollComponent } from '../../../ScrollComponent'
import { ChartContentItem } from './ChartContentItem'

export const ChartContent = ({
  data,
  width,
  height,
  yAxisCompareKey,
  yAxisMaxKey,
  yAxisMinKey,
  barKeys,
  tickValues,
  margin,
  isShowTotal,
  isFillColorYAxis,
  is100Percent,
  tooltipContentData,
  fillColor,
  formatYAxisLabel,
  formatTotal,
}) => {
  const getBarHeight = () => {
    return height / data.length
  }

  const getMaxTickValue = () => {
    if (is100Percent) {
      return 100
    } else {
      return tickValues[1] / 2 + tickValues[tickValues.length - 1]
    }
  }

  const getYAxisLabel = (item) => {
    if (!yAxisMinKey || !yAxisMaxKey) {
      return item[yAxisCompareKey]
    } else if (data.every((el) => el[yAxisMinKey] === el[yAxisMaxKey])) {
      return formatYAxisLabel
        ? formatYAxisLabel(item[yAxisMinKey])
        : item[yAxisMinKey]
    } else {
      return formatYAxisLabel
        ? `${formatYAxisLabel(item[yAxisMinKey])} - ${formatYAxisLabel(
            item[yAxisMaxKey],
          )}`
        : `${item[yAxisMinKey]} - ${item[yAxisMaxKey]}`
    }
  }

  return (
    <div
      style={{
        height: `calc(100% - ${margin.bottom}px)`,
        marginBottom: margin.bottom,
      }}
    >
      <ScrollComponent>
        <div className="w-100">
          {data.map((item, index) => (
            <React.Fragment key={index}>
              <ChartContentItem
                data={item}
                width={width}
                barHeight={getBarHeight()}
                yAxisCompareKey={yAxisCompareKey}
                yAxisMaxKey={yAxisMaxKey}
                yAxisMinKey={yAxisMinKey}
                yAxisLabel={getYAxisLabel(item)}
                barKeys={barKeys}
                maxTickValue={getMaxTickValue()}
                margin={margin}
                isShowTotal={isShowTotal}
                isFillColorYAxis={isFillColorYAxis}
                tooltipContentData={tooltipContentData}
                fillColor={fillColor}
                formatTotal={formatTotal}
              />
            </React.Fragment>
          ))}
        </div>
      </ScrollComponent>
    </div>
  )
}

ChartContent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  yAxisCompareKey: PropTypes.string.isRequired,
  yAxisMaxKey: PropTypes.string,
  yAxisMinKey: PropTypes.string,
  barKeys: PropTypes.array.isRequired,
  tickValues: PropTypes.array,
  margin: PropTypes.object,
  isShowTotal: PropTypes.bool,
  isFillColorYAxis: PropTypes.bool,
  is100Percent: PropTypes.bool,
  tooltipContentData: PropTypes.array,
  fillColor: PropTypes.object,
  formatYAxisLabel: PropTypes.func,
}
