import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import useWrapper from '../../hooks/useWrapper'
import useGetFund from '../../masterData/useGetFund'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import { DropdownItem } from './DropdownItem'
import { PriceData } from './PriceData'
import {
  changeFundInfo,
  changeLoadingSearch,
  selectFundInfo,
  selectLoadingSearch,
} from './store/slice'

export const FundTopInfo = ({
  title,
  height,
  radioContent,
  isHidePriceData,
  style,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate()
  const { width: wrapperWidth } = useWrapper()
  const { funds, fundById } = useGetFund()

  useHeaderSearch(TYPE_HEADER.FUND, false)

  const fundInfo = useSelector(selectFundInfo)

  const loadingSearch = useSelector(selectLoadingSearch)

  const [keySearch, setKeySearch] = useState('')

  // Get data
  const handleSearch = useMemo(() => {
    const data = funds
      .filter(
        (i) =>
          i.organizationShortName?.toLowerCase().includes(keySearch) ||
          i.ticker?.toLowerCase().includes(keySearch),
      )
      .sort((a, b) => a.ticker?.localeCompare(b.ticker))

    dispatch(changeLoadingSearch(false))
    return data
  }, [keySearch, JSON.stringify(funds)])

  const options = useMemo(
    () =>
      !loadingSearch
        ? handleSearch.map((company) => {
            return { id: company.organizationId, value: { ...company } }
          })
        : [],
    [JSON.stringify(handleSearch), loadingSearch],
  )

  // Actions
  const handleFocusInputSearch = () => {
    setKeySearch('')
  }

  const handleSelect = (value) => {
    navigate(location.pathname, {
      organizationId: value.organizationId,
      groupId: null,
      ticker: value.ticker,
      organizationShortName: value.organizationShortName,
      icbId: value.icbId,
    })
  }

  const handleChangeLoading = () => {
    dispatch(changeLoadingSearch(true))
  }

  // Use effect
  useEffect(() => {
    if (fundById[fundInfo.organizationId]) {
      dispatch(changeFundInfo(fundById[fundInfo.organizationId]))
    }
  }, [JSON.stringify(fundById[fundInfo?.organizationId])])

  return (
    <div
      className="top-info"
      style={{
        width: wrapperWidth || '100%',
        height: height ?? '48px',
      }}
    >
      {title && (
        <>
          <Title title={title} />
          <VerticalLine mr={12} />
        </>
      )}
      {radioContent && (
        <>
          {radioContent}
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={fundInfo?.ticker}
        description={fundInfo?.organizationShortName}
        onFocus={handleFocusInputSearch}
        onTextChange={(e) => setKeySearch(e?.toLowerCase())}
        options={options}
        loading={loadingSearch}
        onSelect={handleSelect || handleSelect}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
        classNameInputSearch={style}
        onChangeLoading={handleChangeLoading}
      />
      {!isHidePriceData && <PriceData />}
    </div>
  )
}

FundTopInfo.propTypes = {
  title: PropTypes.string,
  handleSelect: PropTypes.func,
  radioContent: PropTypes.element,
  comTypeIds: PropTypes.array, // COM_TYPE_ID values
  height: PropTypes.string,
  isHidePriceData: PropTypes.bool,
}

FundTopInfo.defaultProps = {
  isIncludeBond: false,
  height: undefined,
  isHidePriceData: true,
}
