import { useEffect, useState } from 'react'
import { ChartColorComponent } from './components/chartColorComponent'
import styles from './style.module.css'

const ChartColorSettings = ({ data, setData, onClose }) => {
  const [color, setColor] = useState('#000000')
  const [gradientColor, setGradientColor] = useState(1)

  useEffect(() => {
    setData({ ...data, color, gradientColor })
  }, [color, gradientColor])

  useEffect(() => {
    data.color && setColor(data.color)
    data.gradientColor && setGradientColor(data.gradientColor)
  }, [])

  return (
    <div className={styles.settingBlockContainer}>
      <ChartColorComponent
        isHideGradient
        isParent
        value={color}
        gradientValue={gradientColor}
        onChange={setColor}
        onChangeGradient={setGradientColor}
        onClose={onClose}
        data={data}
      />
    </div>
  )
}

export default ChartColorSettings
