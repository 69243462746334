import { max, maxBy, min, minBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { renderTimeType } from '../../../primaryMarket/marketStatistics/helper'
import { BAR_COLOR_SCALE_OF_DEBT, TIME_FREQUENCY } from '../constants'
import {
  keys,
  selectFilterDebtAndLoan,
  selectLoading,
  selectScaleOfDebtByObjects,
} from '../store/slice'
import { getScaleOfDebtByObjects } from '../store/thunk'
import ChartTooltip from './ChartTooltip'

const ScaleOfDebtByObjects = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading(keys.SCALE_OF_DEBT_BY_OBJECTS))
  const { data } = useSelector(selectScaleOfDebtByObjects)
  const basicInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectFilterDebtAndLoan)

  const formatDataBarChart = useMemo(() => {
    return data
      .map((item, id) => ({
        ...item,
        date:
          activeTab === TIME_FREQUENCY.QUARTER
            ? `Q${item.realQuarter}-${item.realYear} ${renderTimeType(
                activeTab,
                `Q${item.realQuarter}-${item.realYear}`,
              )}`
            : `${item.realYear} ${renderTimeType(activeTab, item.realYear)}`,
        totalDebt: valDivBillion(item.totalDebt),
        totalBondIssue: valDivBillion(item.bond),
        bankLoan: valDivBillion(item.bankLoan),
        other: valDivBillion(item.other),
      }))
      .reverse()
  }, [data])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  useEffect(() => {
    if (basicInfo.organizationId) {
      const payload = {
        TimeFrequency: activeTab,
        OrganizationId: basicInfo.organizationId,
        Top: 5,
      }

      dispatch(getScaleOfDebtByObjects(payload))
    }
  }, [locale, basicInfo.organizationId, activeTab])

  const rangeValue = useMemo(() => {
    const maxOther = maxBy(formatDataBarChart, (item) => item?.other)
    const maxBankLoan = maxBy(formatDataBarChart, (item) => item?.bankLoan)
    const maxTotalBondIssue = maxBy(
      formatDataBarChart,
      (item) => item?.totalBondIssue,
    )
    const minOther = minBy(formatDataBarChart, (item) => item?.other)
    const minBankLoan = minBy(formatDataBarChart, (item) => item?.bankLoan)
    const minTotalBondIssue = minBy(
      formatDataBarChart,
      (item) => item?.totalBondIssue,
    )

    const maxValue = max([
      maxOther?.other ?? 0,
      maxBankLoan?.bankLoan ?? 0,
      maxTotalBondIssue?.totalBondIssue ?? 0,
    ])
    const minValue = min([
      minOther?.other ?? 0,
      minBankLoan?.bankLoan ?? 0,
      minTotalBondIssue?.totalBondIssue ?? 0,
    ])

    return {
      maxValue: maxValue ?? 0,
      minValue: minValue < 0 ? minValue : 0,
    }
  }, [formatDataBarChart])

  return (
    <Panel
      title="bond.corporateBond.scaleOfDebtByObjects.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.scaleOfDebtByObjects.TITLE')}
      downloadJpgParams={{
        domId: 'scaleOfDebtByObjectsChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: I18n.t('bond.corporateBond.scaleOfDebtByObjects.TITLE'),
        tickerName: '',
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <div id="scaleOfDebtByObjectsChartId">
              <div style={{ height: height }}>
                {isLoading ? (
                  <Loading />
                ) : !!formatDataBarChart.length ? (
                  <>
                    <div style={{ height: height - 34 }}>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - 34}
                        keyXAxis="date"
                        yAxis={[
                          {
                            id: 'left',
                            keys: ['totalBondIssue', 'bankLoan', 'other'],
                            orientation: 'left',
                            isStackedBar: true,
                            label: I18n.t(
                              'bond.corporateBond.relativeToEquity.VALUE',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 5,
                            isBarChart: true,
                            min: rangeValue.minValue,
                            max: rangeValue.maxValue,
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            <Bar
                              stackId="stackId1"
                              isAnimationActive={false}
                              yAxisId="left"
                              dataKey="totalBondIssue"
                              fill={BAR_COLOR_SCALE_OF_DEBT[0]}
                              barSize={10}
                            />

                            <Bar
                              stackId="stackId2"
                              isAnimationActive={false}
                              yAxisId="left"
                              dataKey="bankLoan"
                              fill={BAR_COLOR_SCALE_OF_DEBT[1]}
                              barSize={10}
                            />
                            <Bar
                              stackId="stackId3"
                              isAnimationActive={false}
                              yAxisId="left"
                              dataKey="other"
                              fill={BAR_COLOR_SCALE_OF_DEBT[2]}
                              barSize={10}
                            />
                          </>
                        )}
                      </ChartContainer>
                    </div>
                    <Footer
                      key={width}
                      list={[
                        {
                          text: 'bond.corporateBond.scaleOfDebtByObjects.OWNER',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_SCALE_OF_DEBT[0],
                          },
                        },
                        {
                          text: 'bond.corporateBond.scaleOfDebtByObjects.RATIO',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_SCALE_OF_DEBT[1],
                          },
                        },
                        {
                          text: 'bond.corporateBond.scaleOfDebtByObjects.OTHER',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_SCALE_OF_DEBT[2],
                          },
                        },
                      ]}
                      appendStyle={{
                        height: 16,
                        marginBottom: 4,
                        marginTop: 8,
                      }}
                    />
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default ScaleOfDebtByObjects
