import { useSelector } from 'react-redux'
import { getColorByTableCell } from '../../utils/Color'
import { formatVal } from '../../utils/Value'
import { Span } from '../html/Span'

export const CellWithMaxMinByCol = ({
  val,
  colId,
  format,
  props,
  selectMaxMinRedux,
}) => {
  const maxMinByColId = useSelector(selectMaxMinRedux)
  const maxMinVal = maxMinByColId[colId]

  const getStyle = () => {
    const style = { position: 'relative', fontWeight: 600, height: '100%' }

    if (typeof val === 'number' && maxMinVal) {
      const { bg, color } = getColorByTableCell(val, [
        maxMinVal.min,
        maxMinVal.max,
      ])
      return {
        ...style,
        backgroundColor: bg,
        color: color,
      }
    }

    return style
  }

  return (
    <td
      {...props}
      style={{
        ...props.style,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <div style={getStyle()} className="d-flex ali-center justify-content-end">
        <Span className="pr-8 pl-8">
          {format ? format(val) : formatVal(val)}
        </Span>
      </div>
    </td>
  )
}
