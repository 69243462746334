import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { getTimeFrequencyMax } from '../../../common/chart/financialChart/helper'
import { keyBy } from '../../../utils/Common'
import { FORMAT, getDateWithTimezone } from '../../../utils/Datetime'
import {
  getHighest,
  getLowest,
  getMean,
  getMedian,
  getStandardDeviation,
} from '../../../utils/Value'
import { TIME_FREQUENCY, VIEW_TYPE } from '../../chart/constants'
import { randomColor } from '../../chart/helper'
import { LIST_TYPE, TIME_RANGE, XAXIS_DATA_KEY } from '../constants'
import {
  dataFormatTypes,
  multiplierTypes,
  statisticalMethodTypes,
} from '../menuChartSettings/constants'
import {
  getEconomyData,
  getEconomyPayloadAndPromises,
  getEquationDataAggregateParams,
  getEquationDataCountParams,
  getSecuritiesData,
  getSecuritiesIndicatorLatestPeriodParams,
  getSecuritiesPayloadAndPromises,
} from './chartApiParamsAndResponseHelpers'
import {
  getChartNameBySampleName,
  getChartOrderByListChart,
} from './chartHelpers'
import { formatChartSchemaBySecurities } from './chartSchemaBySecuritiesHelpers'
import { formatChartSchemaByTime } from './chartSchemaByTimeHelpers'
import { getDataChartByDashboard } from './dashboardApiParamsAndResponseHelpers'
import {
  getDashboardDuplicateName,
  getNewDashboardName,
} from './dashboardHelpers'

const getMinData = (data, keyMin) => {
  const sortData = Object.values(data)
    .map((value) => (value[0] ? value[0][keyMin] : undefined))
    .sort()
  return sortData[0]
}

const isLoop = (data) => {
  return !Object.keys(data).every((key) => data[key].length === 0)
}

export const formatListTypeData = (groupData) => {
  const result = []
  while (isLoop(groupData)) {
    const min = getMinData(groupData, 'date')
    let item = {}
    Object.keys(groupData).forEach((key) => {
      const data = groupData[key]
      if (data[0] && data[0].date === min) {
        item.date = data[0].date
        item[key] = data[0].value
        data.shift()
      }
    })
    result.push(item)
  }
  return result
}

// Field of object will be similar to formatListTypeData function's object field
// If change field in formatListTypeData function, need to change in this function too
export const formatEquationData = (data, equationId) => {
  return data.map((item) => ({ [equationId]: item.value, date: item.date }))
}

export const getDayPass = (date, numYear, numMonth) => {
  const toDay = new Date(date)
  const day = toDay.getDate()
  const year = toDay.getFullYear()
  const month = toDay.getMonth()
  return new Date(year - numYear, month - numMonth, day)
}

export const findIndexIsSameOrAfter = (array, dateCompare) => {
  return array.findIndex((x) => moment(x.date).isSameOrAfter(dateCompare))
}

export const findIndexBefore = (array, dateCompare) => {
  return array.findIndex((x) => moment(dateCompare).isBefore(x.date)) >= 0
    ? array.findIndex((x) => moment(dateCompare).isBefore(x.date))
    : undefined
}

export const filterDataChart = (
  data,
  timeRange,
  timeRangeDate = { dateFrom: null, dateTo: null },
) => {
  const dataChart = [
    ...data
      .reduce(
        (a, b) => a.set(b.date, Object.assign(a.get(b.date) || {}, b)),
        new Map(),
      )
      .values(),
  ].sort((a, b) => a.date?.localeCompare(b.date))
  const dataLatestDate = dataChart[dataChart.length - 1]
  const startOfMonth = new Date(moment().startOf('month'))
  const startOfQuarter = new Date(moment().startOf('quarter'))
  const startOfYear = new Date(moment().startOf('year'))

  const mapSetDataChart = {
    [TIME_RANGE.ONE_MONTH]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 0, 1),
    ),
    [TIME_RANGE.THREE_MONTHS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 0, 3),
    ),
    [TIME_RANGE.SIX_MONTHS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 0, 6),
    ),
    [TIME_RANGE.NINE_MONTHS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 0, 9),
    ),
    [TIME_RANGE.ONE_YEAR]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 1, 0),
    ),
    [TIME_RANGE.THREE_YEARS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 3, 0),
    ),
    [TIME_RANGE.FIVE_YEARS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 5, 0),
    ),
    [TIME_RANGE.TEN_YEARS]: findIndexIsSameOrAfter(
      dataChart,
      getDayPass(dataLatestDate?.date, 10, 0),
    ),
  }

  switch (timeRange) {
    case TIME_RANGE.ALL_TIME:
      return dataChart
    case TIME_RANGE.YEAR_TO_DATE:
      return findIndexIsSameOrAfter(dataChart, startOfYear) > 0
        ? dataChart.slice(findIndexIsSameOrAfter(dataChart, startOfYear))
        : []
    case TIME_RANGE.QUARTER_TO_DATE:
      return findIndexIsSameOrAfter(dataChart, startOfQuarter) > 0
        ? dataChart.slice(findIndexIsSameOrAfter(dataChart, startOfQuarter))
        : []
    case TIME_RANGE.MONTH_TO_DATE:
      return findIndexIsSameOrAfter(dataChart, startOfMonth) > 0
        ? dataChart.slice(findIndexIsSameOrAfter(dataChart, startOfMonth))
        : []
    default:
      if (timeRangeDate?.dateFrom || timeRangeDate?.dateTo) {
        return dataChart.slice(
          findIndexIsSameOrAfter(
            dataChart,
            timeRangeDate?.dateFrom
              ? new Date(timeRangeDate?.dateFrom)
              : dataChart[0]?.date,
          ),
          findIndexBefore(
            dataChart,
            timeRangeDate?.dateTo
              ? new Date(timeRangeDate?.dateTo)
              : new Date(),
          ),
        )
      } else {
        return mapSetDataChart[timeRange] >= 0
          ? dataChart.slice(mapSetDataChart[timeRange])
          : []
      }
  }
}

export const getFormatDataByDataKey = (data) => {
  const dataByKey = {}
  data.forEach((item) => {
    const dataKeys = Object.keys(item).filter((key) => key !== 'date')
    dataKeys.forEach((key) => {
      if (dataByKey[key] && Array.isArray(dataByKey[key])) {
        dataByKey[key].push({ date: item.date, [key]: item[key] })
      } else {
        dataByKey[key] = [{ date: item.date, [key]: item[key] }]
      }
    })
  })
  return dataByKey
}

const getFormatItem = (item, dataKeys, timeFrequencyValid) => {
  let isExistData = false
  const formatItem = Object.keys(item).reduce((obj, key) => {
    if (dataKeys.includes(key)) {
      if (timeFrequencyValid.some((val) => key.split('_').includes(val))) {
        isExistData = true
        return { ...obj, [key]: item[key] }
      }
    }

    return { ...obj, [key]: item[key] }
  }, {})

  return { formatItem, isExistData }
}

export const getDataByTimeFrequency = (
  data,
  timeFrequency,
  timeZone,
  dataKeys,
) => {
  let formatData = {}

  switch (timeFrequency) {
    case TIME_FREQUENCY.WEEKLY:
      formatData = [...data].reduce((obj, item) => {
        const weekKey = moment(getDateWithTimezone(item.date, timeZone)).format(
          'GGGG-WW',
        )

        const { formatItem, isExistData } = getFormatItem(item, dataKeys, [
          TIME_FREQUENCY.MONTHLY.toLowerCase(),
          TIME_FREQUENCY.QUARTERLY.toLowerCase(),
          TIME_FREQUENCY.YEARLY.toLowerCase(),
        ])

        return !isExistData
          ? { ...obj, [weekKey]: { ...(obj[weekKey] || {}), ...item } }
          : { ...obj, [weekKey]: formatItem }
      }, {})
      break
    case TIME_FREQUENCY.MONTHLY:
      formatData = [...data].reduce((obj, item) => {
        const monthKey = moment(
          getDateWithTimezone(item.date, timeZone),
        ).format('YYYY-MM')
        const { formatItem, isExistData } = getFormatItem(item, dataKeys, [
          TIME_FREQUENCY.QUARTERLY.toLowerCase(),
          TIME_FREQUENCY.YEARLY.toLowerCase(),
        ])

        return !isExistData
          ? { ...obj, [monthKey]: { ...(obj[monthKey] || {}), ...item } }
          : { ...obj, [monthKey]: formatItem }
      }, {})
      break
    case TIME_FREQUENCY.QUARTERLY:
      formatData = [...data].reduce((obj, item) => {
        const quarterKey = moment(
          getDateWithTimezone(item.date, timeZone),
        ).format('YYYY-Q')
        const { formatItem, isExistData } = getFormatItem(item, dataKeys, [
          TIME_FREQUENCY.YEARLY.toLowerCase(),
        ])

        return !isExistData
          ? { ...obj, [quarterKey]: { ...(obj[quarterKey] || {}), ...item } }
          : { ...obj, [quarterKey]: formatItem }
      }, {})
      break
    case TIME_FREQUENCY.YEARLY:
      formatData = [...data].reduce((obj, item) => {
        const yearKey = moment(getDateWithTimezone(item.date, timeZone)).format(
          'YYYY',
        )
        const { formatItem, isExistData } = getFormatItem(item, dataKeys, [
          TIME_FREQUENCY.YEARLY.toLowerCase(),
        ])

        return !isExistData
          ? { ...obj, [yearKey]: { ...(obj[yearKey] || {}), ...item } }
          : { ...obj, [yearKey]: formatItem }
      }, {})
      break
    default:
      return data
  }

  return Object.keys(formatData)
    .sort((a, b) => a.localeCompare(b))
    .map((key) => formatData[key])
}

const getTableDataBySecurities = (data, chart) => {
  if (chart.dataType === dataFormatTypes.PREV_PERCENT) {
    // Todo
  } else if (chart.dataType === dataFormatTypes.PERCENT) {
    const [securityType, , indicatorId, timeFrequency] =
      chart.dataKey.split('_')
    let originalValue = null
    const newData = {}

    Object.keys(data)
      .sort((a, b) => a.localeCompare(b))
      .forEach((key) => {
        const [dataSecurityType, , dataIndicatorId, dataTimeFrequency] =
          key.split('_')

        if (
          securityType === dataSecurityType &&
          indicatorId === dataIndicatorId &&
          timeFrequency === dataTimeFrequency
        ) {
          if (originalValue === null) {
            originalValue = data[key]
            newData[key] = 0
          } else {
            newData[key] =
              ((data[key] - originalValue) / Math.abs(originalValue || 1)) * 100
          }
        }
      })

    return newData
  } else {
    const [securityType, , indicatorId, timeFrequency] =
      chart.dataKey.split('_')
    const newData = {}

    Object.keys(data).forEach((key) => {
      const [dataSecurityType, , dataIndicatorId, dataTimeFrequency] =
        key.split('_')

      if (
        securityType === dataSecurityType &&
        indicatorId === dataIndicatorId &&
        timeFrequency === dataTimeFrequency
      ) {
        if (chart.dataUnit === '%') {
          newData[key] = data[key] * 100 || null
        } else {
          newData[key] = data[key] / (chart.multiplier || 1) || null
        }
      }
    })

    return newData
  }
}

const getOriginalItemValueByTime = (data, fullDataByTime, chart) => {
  if (data.length && fullDataByTime) {
    const firstItem = data[0]
    let indexFirstItem = fullDataByTime.findIndex(
      (item) => item.date === firstItem.date,
    )

    if (chart.dataType === dataFormatTypes.PREV_PERCENT) {
      indexFirstItem -= chart.numberPeriod - 1 || 0
    }

    if (indexFirstItem > 0) {
      const item = fullDataByTime[indexFirstItem - 1]
      return item[chart.dataKey] || 0
    }
  }
}

const getDataByCharts = ({
  data,
  fullDataByTimeDataKey,
  charts,
  viewType,
  isTable,
}) => {
  const originalValues = {}

  return data.reduce((arr, item) => {
    const newXAxisItem = Object.values(XAXIS_DATA_KEY).reduce(
      (obj, key) => (item[key] ? { ...obj, [key]: item[key] } : obj),
      {},
    )
    const newChartItem = charts.reduce((obj, chart) => {
      if (chart.referenceLineYAxisId && !chart.statisticalMethods) {
        return obj
      }

      let value = 0
      switch (chart.dataType) {
        case dataFormatTypes.PREV_PERCENT: {
          const prevOriginalValue =
            getOriginalItemValueByTime(
              [item],
              fullDataByTimeDataKey[chart.dataKey],
              chart,
            ) || item[chart.dataKey]

          value =
            ((item[chart.dataKey] - prevOriginalValue) /
              Math.abs(prevOriginalValue || 1)) *
            100
          break
        }
        case dataFormatTypes.PERCENT: {
          if (viewType === VIEW_TYPE.BY_SECURITIES && isTable) {
            return { ...obj, ...getTableDataBySecurities(item, chart) }
          } else {
            if (originalValues[chart.dataKey]) {
              value =
                ((item[chart.dataKey] - originalValues[chart.dataKey]) /
                  Math.abs(originalValues[chart.dataKey] || 1)) *
                100
            } else {
              const prevOriginalValue = getOriginalItemValueByTime(
                data,
                fullDataByTimeDataKey[chart.dataKey],
                chart,
              )

              if (typeof prevOriginalValue === 'number') {
                value =
                  ((item[chart.dataKey] - prevOriginalValue) /
                    Math.abs(prevOriginalValue || 1)) *
                  100
                originalValues[chart.dataKey] = prevOriginalValue
              } else {
                value = 0
                originalValues[chart.dataKey] = item[chart.dataKey]
              }
            }
          }
          break
        }
        default: {
          if (viewType === VIEW_TYPE.BY_SECURITIES && isTable) {
            return { ...obj, ...getTableDataBySecurities(item, chart) }
          } else {
            if (chart.dataUnit === '%') {
              value = item[chart.dataKey] * 100
            } else {
              value = item[chart.dataKey] / (chart.multiplier || 1)
            }
          }
          break
        }
      }

      if (typeof value !== 'number' || isNaN(value)) {
        return obj
      }
      return { ...obj, [chart.dataKey]: value }
    }, {})

    if (Object.keys(newChartItem).length) {
      return [
        ...arr,
        {
          ...newXAxisItem,
          ...newChartItem,
        },
      ]
    } else {
      return arr
    }
  }, [])
}

export const formatData = (
  data,
  fullDataByTimeDataKey,
  charts,
  viewType,
  timeFrequency,
  timeZone,
) => {
  const dataByTimeFrequency =
    viewType === VIEW_TYPE.BY_SECURITIES
      ? data
      : getDataByTimeFrequency(
          data,
          timeFrequency,
          timeZone,
          charts.map((chart) => chart.dataKey),
        )
  const newData = getDataByCharts({
    data: dataByTimeFrequency,
    fullDataByTimeDataKey,
    viewType,
    charts,
  })
  return formatDataWithStatisticalMethods(newData, charts)
}

export const formatTableData = (
  data,
  fullDataByTimeDataKey,
  schema,
  viewType,
) => {
  const { charts } = schema
  return getDataByCharts({
    data,
    fullDataByTimeDataKey,
    charts,
    viewType,
    isTable: true,
  })
}

export const formatDataWithStatisticalMethods = (data, charts) => {
  if (data.length) {
    const statisticalMethodDataKey = charts.reduce(
      (obj, chart) =>
        chart.referenceLineYAxisId && chart.statisticalMethods
          ? {
              ...obj,
              [chart.dataKey]: Object.values(chart.statisticalMethods).filter(
                (method) => method.isCheck,
              ),
            }
          : obj,
      {},
    )

    const valuesByDataKey = {}
    data.forEach((item) =>
      Object.keys(item).forEach(
        (key) =>
          typeof item[key] === 'number' &&
          !isNaN(item[key]) &&
          (valuesByDataKey[key] = [...(valuesByDataKey[key] || []), item[key]]),
      ),
    )

    Object.keys(statisticalMethodDataKey).forEach((dataKey) => {
      statisticalMethodDataKey[dataKey].forEach((method) => {
        switch (method.value) {
          case statisticalMethodTypes.MEAN: {
            const mean = getMean(valuesByDataKey[dataKey])
            data[0][`${dataKey}_${method.value}`] = mean
            data[data.length - 1][`${dataKey}_${method.value}`] = mean
            break
          }
          case statisticalMethodTypes.MEDIAN: {
            const median = getMedian(valuesByDataKey[dataKey])
            data[0][`${dataKey}_${method.value}`] = median
            data[data.length - 1][`${dataKey}_${method.value}`] = median
            break
          }
          case statisticalMethodTypes.LOWEST: {
            const lowest = getLowest(valuesByDataKey[dataKey])
            data[0][`${dataKey}_${method.value}`] = lowest
            data[data.length - 1][`${dataKey}_${method.value}`] = lowest
            break
          }
          case statisticalMethodTypes.HIGHEST: {
            const highest = getHighest(valuesByDataKey[dataKey])
            data[0][`${dataKey}_${method.value}`] = highest
            data[data.length - 1][`${dataKey}_${method.value}`] = highest
            break
          }
          default: {
            const mean = getMean(valuesByDataKey[dataKey])
            const standardDeviation = getStandardDeviation(
              valuesByDataKey[dataKey],
            )
            const result =
              mean + standardDeviation * method.upperLowerDifference
            data[0][`${dataKey}_${method.value}`] = result
            data[data.length - 1][`${dataKey}_${method.value}`] = result
            break
          }
        }
      })
    })
  }

  return data
}

export const getListSecuritiesIndicator = ({
  indicators,
  securityType,
  securityId,
  typeChart,
  viewType,
  isOrientationLeft,
  usedColors,
  templateBySecurities,
  favoriteColors,
}) => {
  return indicators.map((item, index) => {
    const dataKey =
      securityType !== LIST_TYPE.ECONOMY
        ? [securityType, securityId, item.id, item.timeFrequency].join('_')
        : [securityType, item.id, item.timeFrequency].join('_')
    const dataKeyDuplicate = [
      securityType,
      securityId,
      item.id,
      item.timeFrequency,
      item.uniqueID,
    ].join('_')

    return getChartSchemaParams({
      item,
      securityType,
      dataKey,
      typeChart,
      viewType,
      isOrientationLeft: isOrientationLeft && index === 0,
      usedColors,
      templateBySecurities,
      favoriteColors,
      dataKeyDuplicate,
    })
  })
}

export const getChartSchemaParams = ({
  item,
  securityType,
  dataKey,
  typeChart,
  viewType,
  isOrientationLeft,
  usedColors,
  itemReplace,
  templateBySecurities,
  favoriteColors,
  dataKeyDuplicate,
}) => {
  const template =
    templateBySecurities &&
    templateBySecurities[securityType] &&
    templateBySecurities[securityType].find(
      (indicator) =>
        indicator.indicatorId === item.id &&
        indicator.indicatorTimeFrequency === item.timeFrequency,
    )
  const dataKeyBySecurity =
    securityType !== LIST_TYPE.ECONOMY
      ? [securityType, 'indicator', item.id, item.timeFrequency].join('_')
      : undefined

  const dataKeyBySecurityDuplicate =
    securityType !== LIST_TYPE.ECONOMY
      ? item.uniqueID
        ? [
            securityType,
            'indicator',
            item.id,
            item.timeFrequency,
            item.uniqueID,
          ].join('_')
        : [securityType, 'indicator', item.id, item.timeFrequency].join('_')
      : undefined

  let color = item.color
  let chartType = typeChart

  if (viewType !== VIEW_TYPE.BY_SECURITIES) {
    chartType = template ? template.chartType : typeChart
    color = randomColor(usedColors, favoriteColors)
    usedColors.push(color)
  }

  return itemReplace
    ? {
        ...itemReplace,
        indicatorId: item.id,
        indicatorTimeFrequency: item.timeFrequency,
        indicatorUnit: item.unit,
        dataKey: dataKey,
        dataKeyBySecurity: dataKeyBySecurity,
        chartParameters: {
          ...itemReplace.chartParameters,
          dataKeys: [dataKey],
          yAxisId: dataKey,
          dataKeysDuplicate: [dataKeyDuplicate],
          yAxisIdDuplicate: dataKeyDuplicate,
        },
        dataKeyDuplicate: dataKeyDuplicate,
        dataKeyBySecurityDuplicate: dataKeyBySecurityDuplicate,
      }
    : {
        indicatorId: item.id,
        indicatorTimeFrequency: item.timeFrequency,
        indicatorUnit: item.unit,
        color: color,
        visibility: true,
        dataKey: dataKey,
        dataKeyBySecurity: dataKeyBySecurity,
        chartType: chartType,
        dataFormatType: template
          ? template.dataFormatType
          : dataFormatTypes.VALUE,
        numberPeriod: template ? template.numberPeriod : 4,
        multiplier: template ? template.multiplier : 1,
        decimalPlace: template ? template.decimalPlace : 2,
        chartParameters: {
          dataKeys: [dataKey],
          yAxisId: dataKey,
          orientation: isOrientationLeft ? 'left' : 'right',
          isLineChart: true,
          labelText: typeChart,
          dataKeysDuplicate: [dataKeyDuplicate],
          yAxisIdDuplicate: dataKeyDuplicate,
        },
        dataKeyDuplicate: dataKeyDuplicate,
        dataKeyBySecurityDuplicate: dataKeyBySecurityDuplicate,
      }
}

const getLatestDateByTimeFrequency = ({
  date,
  week,
  month,
  quarter,
  year,
  timeZone,
}) => {
  if (date) return date

  if (week && year) {
    const newDate = moment().set('week', week).set('year', year).endOf('week')
    return getDateWithTimezone(newDate, timeZone)
  }

  if (month && year) {
    const newDate = moment()
      .set('month', month)
      .set('year', year)
      .endOf('month')
    return getDateWithTimezone(newDate, timeZone)
  }

  if (quarter && year) {
    const newDate = moment()
      .set('quarter', quarter)
      .set('year', year)
      .endOf('quarter')
    return getDateWithTimezone(newDate, timeZone)
  }

  if (year) {
    const newDate = moment().set('year', year).endOf('year')
    return getDateWithTimezone(newDate, timeZone)
  }

  return
}

export const formatDataChartBySecurities = (
  dataChart,
  dataChartEquation,
  equations,
  treeSecuritiesIndicator,
  listLegendCustom,
  getSecuritiesName,
  getIndicatorNameTimeFrequency,
) => {
  const data = []
  const visibilitySecurities = []

  Object.keys(treeSecuritiesIndicator).forEach((securityType) => {
    if (securityType !== LIST_TYPE.ECONOMY) {
      const securities = treeSecuritiesIndicator[securityType]
      Object.keys(securities).forEach((securityId) =>
        securities[securityId].forEach((indicator) => {
          if (indicator.visibility) {
            visibilitySecurities.push(
              indicator.dataKeyDuplicate || indicator.dataKey,
            )
          }
        }),
      )
    }
  })

  Object.keys(dataChart)
    .sort((a, b) => a.localeCompare(b))
    .forEach((key) => {
      if (key.includes(LIST_TYPE.ECONOMY)) {
        const customSecurityName =
          listLegendCustom &&
          listLegendCustom[
            `${LIST_TYPE.ECONOMY}_${dataChart[key].id}_${dataChart[key].timeFrequency}`
          ]
        data.push({
          [key]: dataChart[key].value,
          securityName:
            customSecurityName ||
            getIndicatorNameTimeFrequency(
              dataChart[key].id,
              dataChart[key].timeFrequency,
              LIST_TYPE.ECONOMY,
            ),
        })
      } else {
        const securityKeyDetail = key.split('_')
        const securityName = getSecuritiesName(
          securityKeyDetail[0],
          securityKeyDetail[1],
        )

        const dataBySecurity = Object.keys(dataChart[key]).reduce(
          (obj, indicatorKey) => {
            const indicatorData = dataChart[key][indicatorKey]

            const dataKey = indicatorData?.uniqueID
              ? [
                  key,
                  indicatorData.id,
                  indicatorData.timeFrequency,
                  indicatorData.uniqueID,
                ].join('_')
              : [key, indicatorData.id, indicatorData.timeFrequency].join('_')

            if (!visibilitySecurities.includes(dataKey)) {
              return obj
            }

            return {
              ...obj,
              [indicatorKey]: dataChart[key][indicatorKey].value,
            }
          },
          { securityName },
        )
        data.push(dataBySecurity)
      }
    })

  const equationsById = keyBy(Object.values(equations), 'dataKey')
  Object.keys(dataChartEquation).forEach((key) => {
    if (equationsById[key]) {
      data.push({
        [key]: dataChartEquation[key].value,
        securityName:
          (listLegendCustom && listLegendCustom[key]) ||
          equationsById[key].equationIndicatorName,
      })
    }
  })

  return data
}

export const formatDataTableBySecurities = (
  dataChart,
  dataChartEquation,
  timeZone,
) => {
  const data = {}
  Object.keys(dataChart).forEach((key) => {
    if (key.includes(LIST_TYPE.ECONOMY)) {
      const date = getLatestDateByTimeFrequency({ ...dataChart[key], timeZone })
      data[date] = { ...(data[date] || { date }), [key]: dataChart[key].value }
    } else {
      Object.values(dataChart[key]).forEach((indicator) => {
        const date = getLatestDateByTimeFrequency({ ...indicator, timeZone })
        const securityIndicatorKey = indicator?.uniqueID
          ? [
              key,
              indicator.id,
              indicator.timeFrequency,
              indicator.uniqueID,
            ].join('_')
          : [key, indicator.id, indicator.timeFrequency].join('_')
        data[date] = {
          ...(data[date] || { date }),
          [securityIndicatorKey]: indicator.value,
        }
      })
    }
  })

  Object.keys(dataChartEquation).forEach((key) => {
    const date = getLatestDateByTimeFrequency({
      ...dataChartEquation[key],
      timeZone,
    })
    data[date] = {
      ...(data[date] || { date }),
      [key]: dataChartEquation[key].value,
    }
  })

  return Object.values(data).sort((a, b) =>
    (a.date || '').localeCompare(b.date || ''),
  )
}

export const getMultiplierText = (multiplier) => {
  return Object.keys(multiplierTypes).find(
    (key) => multiplierTypes[key] === multiplier,
  )
}

export const getDataUnitWithMultiplier = (unit, multiplier) => {
  return !multiplier || multiplier === multiplierTypes.RAW
    ? unit
    : `${I18n.t('financialChart.' + getMultiplierText(multiplier))} ${unit}`
}

export const getTimeFrequencyByIndicator = (item) => {
  return item.timeFrequency.toLowerCase() === TIME_FREQUENCY.TTM.toLowerCase()
    ? TIME_FREQUENCY.QUARTERLY.toLowerCase()
    : item.timeFrequency.toLowerCase()
}

export const getTimeRangeTimeFrequencyDashboardView = (
  arrayTimeFrequency,
  timeRange,
) => {
  const timeFrequencyMax = getTimeFrequencyMax(arrayTimeFrequency)
  const checkIncludes = (array) => {
    return array.includes(timeRange)
  }

  if (timeFrequencyMax === TIME_FREQUENCY.YEARLY) {
    if (
      checkIncludes([
        TIME_RANGE.THREE_MONTHS,
        TIME_RANGE.SIX_MONTHS,
        TIME_RANGE.MONTH_TO_DATE,
        TIME_RANGE.QUARTER_TO_DATE,
        TIME_RANGE.YEAR_TO_DATE,
        TIME_RANGE.ONE_YEAR,
        TIME_RANGE.THREE_YEARS,
        TIME_RANGE.FIVE_YEARS,
      ])
    ) {
      return timeRange
    }
    return TIME_RANGE.FIVE_YEARS
  } else if (timeFrequencyMax === TIME_FREQUENCY.QUARTERLY) {
    if (
      checkIncludes([
        TIME_RANGE.THREE_MONTHS,
        TIME_RANGE.SIX_MONTHS,
        TIME_RANGE.MONTH_TO_DATE,
        TIME_RANGE.QUARTER_TO_DATE,
        TIME_RANGE.YEAR_TO_DATE,
        TIME_RANGE.ONE_YEAR,
      ])
    ) {
      return timeRange
    }
    return TIME_RANGE.THREE_YEARS
  } else {
    if (
      checkIncludes([
        TIME_RANGE.THREE_MONTHS,
        TIME_RANGE.SIX_MONTHS,
        TIME_RANGE.MONTH_TO_DATE,
        TIME_RANGE.QUARTER_TO_DATE,
        TIME_RANGE.YEAR_TO_DATE,
      ])
    ) {
      return timeRange
    }
    return TIME_RANGE.ONE_YEAR
  }
}

export const getFormatXAxisDashboardView = (arrayTimeFrequency) => {
  const timeFrequencyMax = getTimeFrequencyMax(arrayTimeFrequency)

  if (timeFrequencyMax === TIME_FREQUENCY.YEARLY) {
    return FORMAT.YEAR
  } else if (timeFrequencyMax === TIME_FREQUENCY.QUARTERLY) {
    return FORMAT.MONTH_YEAR_SLASH
  } else {
    return FORMAT.DATE
  }
}

export {
  formatChartSchemaBySecurities,
  formatChartSchemaByTime,
  getChartNameBySampleName,
  getChartOrderByListChart,
  getDashboardDuplicateName,
  getDataChartByDashboard,
  getEconomyData,
  getEconomyPayloadAndPromises,
  getEquationDataAggregateParams,
  getEquationDataCountParams,
  getNewDashboardName,
  getSecuritiesData,
  getSecuritiesIndicatorLatestPeriodParams,
  getSecuritiesPayloadAndPromises,
}
