import { Translate } from 'react-redux-i18n'

export const quarters = {
  ANNUAL: 5,
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
}

export const indicators = {
  'P/E': 'PE',
  'P/B': 'PB',
  'EV/EBITDA': 'EV_EBITDA',
}

export const keyTableSector = {
  icbName: 'icbName',
  PERCENT_INDEX_CHANGE: 'percentPriceChange',
  PERCENT_MARKET_CAP: 'marketCapPercentage',
}

export const listLevel = [
  {
    value: 1,
    name: (
      <span>
        <Translate value="sector.sectorStatistics.overview.ICB_LEVEL" /> 1
      </span>
    ),
  },
  {
    value: 2,
    name: (
      <span>
        <Translate value="sector.sectorStatistics.overview.ICB_LEVEL" /> 2
      </span>
    ),
  },
  {
    value: 3,
    name: (
      <span>
        <Translate value="sector.sectorStatistics.overview.ICB_LEVEL" /> 3
      </span>
    ),
  },
  {
    value: 4,
    name: (
      <span>
        <Translate value="sector.sectorStatistics.overview.ICB_LEVEL" /> 4
      </span>
    ),
  },
]

export const keyTableSectorsReport = {
  ANNOUNCEMENT_DATE: 'publicDateId',
  COMPANY_NAME: 'ticker',
  SECTOR: 'icbName',
  TITLE: 'title',
  REPORT_URL: 'reportURL',
}

export const TIME_RANGE = {
  '3M': 'ThreeMonths',
  '6M': 'SixMonths',
  '9M': 'NineMonths',
  '1Y': 'OneYear',
  '3Y': 'ThreeYears',
  All: 'AllTime',
}

export const VALUE_ORDER = {
  ABSOLUTE: 0,
  RELATIVE: 1,
}

export const arrColors = [
  { bgColor: '#f57f07', color: '#1e242e' },
  { bgColor: '#185999', color: '#ececec' },
  { bgColor: '#ff2500', color: '#ececec' },
  { bgColor: '#c7ff58', color: '#1e242e' },
  { bgColor: '#744d23', color: '#ececec' },
  { bgColor: '#f9c105', color: '#1e242e' },
  { bgColor: '#85d4ff', color: '#1e242e' },
  { bgColor: '#56c10a', color: '#1e242e' },
  { bgColor: '#009fe6', color: '#1e242e' },
  { bgColor: '#9d9e9e', color: '#1e242e' },
  { bgColor: '#f4327b', color: '#ececec' },
  { bgColor: '#930037', color: '#ececec' },
  { bgColor: '#006d8c', color: '#ececec' },
  { bgColor: '#9c54e4', color: '#ececec' },
  { bgColor: '#4bd9be', color: '#1e242e' },
  { bgColor: '#c52e14', color: '#ececec' },
  { bgColor: '#ecffdf', color: '#1e242e' },
  { bgColor: '#00763e', color: '#ececec' },
  { bgColor: '#975000', color: '#ececec' },
  { bgColor: '#1a36a9', color: '#ececec' },
]

export const LIMIT_SELECT_SECTOR = 20
export const WIDTH_LABEL = 84
