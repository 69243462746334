import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStockBasicInfo from '../../../../common/hooks/useStockBasicInfo'
import { Loading } from '../../../../common/loading'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import { ChartContainer } from '../../common/ChartContainer'
import { getChartDataFormat, getCompareChartMargin } from '../helps'
import { selectFinancialFilter, selectStatement } from '../store/slice'
import { FINANCIAL_STATEMENT_CHARTS_OPTIONS } from './constants'
import { selectChartData, selectChartLoading } from './store/slice'

const CHART_PERCENT = 'CHART_PERCENT'

export const SecondChart = ({ height, width, statementItem }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const stockBasicInfo = useStockBasicInfo(basicInfo?.organizationId)
  const statement = useSelector(selectStatement)
  const financialFilter = useSelector(selectFinancialFilter)
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)

  // Use state
  const [chartOptions, setChartOptions] = useState()
  const [otherChartOptions, setOtherChartOptions] = useState()

  // Get data
  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [chartOptions])

  // Use effect
  useEffect(() => {
    if (
      stockBasicInfo?.organizationId !== basicInfo.organizationId ||
      !statementItem ||
      !statement.value
    ) {
      setChartOptions()
      setOtherChartOptions()
    } else {
      setChartOptions(
        FINANCIAL_STATEMENT_CHARTS_OPTIONS[statement.value][statementItem]
          .chart2,
      )
      setOtherChartOptions(
        FINANCIAL_STATEMENT_CHARTS_OPTIONS[statement.value][statementItem]
          .chart1,
      )
    }
  }, [
    statementItem,
    statement.value,
    stockBasicInfo?.organizationId,
    basicInfo.organizationId,
  ])

  useEffect(() => {
    if (
      chartOptions?.thunkFunc &&
      basicInfo.organizationId &&
      basicInfo.organizationId === stockBasicInfo?.organizationId
    ) {
      dispatch(
        chartOptions.thunkFunc({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: financialFilter.viewBy.value,
          NumberOfPeriod: financialFilter.numberOfPeriod.value,
          LatestYear: financialFilter.lastestYear.value,
          Consolidated: financialFilter.type.value,
        }),
      )
    }
  }, [chartOptions, basicInfo, stockBasicInfo, JSON.stringify(financialFilter)])

  const calcFontSize = getFontSize(chartOptions?.props.fontSize || 12)

  return chartOptions && chartOptions.component ? (
    <ChartContainer
      key={getKey}
      id="financialStatementSecondChart"
      data={data[chartOptions.thunkFuncName] || []}
      height={height}
      width={width}
      isLoading={
        typeof loading[chartOptions.thunkFuncName] === 'undefined' ||
        loading[chartOptions.thunkFuncName]
      }
    >
      {(resizeSize) => {
        const Component = chartOptions.component
        const size = chartOptions.size(resizeSize)
        const isPercentLeft = chartOptions?.leftLabel?.includes(CHART_PERCENT)
        const isPercentRight = chartOptions?.rightLabel?.includes(CHART_PERCENT)
        const props = {
          data: getChartDataFormat(data, chartOptions),
          ...size,
          ...chartOptions.props,
          margin: {
            ...chartOptions.props.margin,
            ...getCompareChartMargin(
              data,
              chartOptions,
              otherChartOptions,
              calcFontSize,
            ),
          },
          leftLabel: isPercentLeft ? '' : chartOptions.leftLabel,
          rightLabel: isPercentRight ? '' : chartOptions.rightLabel,
          isYAxisPercentLeft: isPercentLeft,
          isYAxisPercentRight: isPercentRight,
        }
        return <Component {...props} />
      }}
    </ChartContainer>
  ) : (
    <Loading />
  )
}

SecondChart.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  statementItem: PropTypes.string,
}
