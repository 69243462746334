import { useDispatch, useSelector } from 'react-redux'
import SelectSector from '../../common/selectSector'
import {
  changeFirstMountPopupSector,
  changeParentSectorChecked,
  changeSectorChecked,
  selectFirstMountPopupSector,
  selectSectorChecked,
} from '../store/slice'

const SecuritiesSectorCount = ({ isCheckAll }) => {
  const dispatch = useDispatch()

  const equationSector = useSelector(selectSectorChecked)
  const isFirstMount = useSelector(selectFirstMountPopupSector)

  const handleChangeFirstMountPopup = (value) => {
    dispatch(changeFirstMountPopupSector(value))
  }

  const handleChangeParentSectorChecked = (data) => {
    dispatch(changeParentSectorChecked(data))
  }

  const handleChangeSectorChecked = (data) => {
    dispatch(changeSectorChecked(data))
  }

  return (
    <SelectSector
      listChecked={equationSector?.childrenSector || []}
      listParentChecked={equationSector?.parentSector || []}
      isFirstMount={isFirstMount}
      changeFirstMountPopup={handleChangeFirstMountPopup}
      changeListParentChecked={handleChangeParentSectorChecked}
      changeSectorChecked={handleChangeSectorChecked}
      isSetSectorAll={isCheckAll}
    />
  )
}

export default SecuritiesSectorCount
