import { createSlice } from '@reduxjs/toolkit'
import { COM_GROUP } from '../../../../../constants/Group'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  VIEW_BY_BANK,
  VIEW_BY_CORP0RATE,
  VIEW_BY_SECURITIES,
} from '../constants'
import { getSegmentationChartData } from './thunk'

const initialState = {
  loading: true,
  viewBy: {
    [COM_GROUP.BANK]: VIEW_BY_BANK.LOAN,
    [COM_GROUP.SECURITIES]: VIEW_BY_SECURITIES.TOTAL_ASSET,
    [COM_GROUP.CORPORATE]: VIEW_BY_CORP0RATE.REVENUE,
  },
  dataChart: [],
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/segmentation',
  initialState,
  reducers: {
    changeViewBy: (state, action) => {
      const payload = action.payload
      state.viewBy[payload.key] = payload.val
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSegmentationChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSegmentationChartData.fulfilled, (state, action) => {
      state.loading = false
      state.dataChart = action.payload
    })
    builder.addCase(getSegmentationChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectViewBy = (state) => state[slice.name].viewBy
export const selectLoading = (state) => state[slice.name].loading
export const selectDataSegmentationChart = (state) =>
  state[slice.name].dataChart

export const { changeViewBy } = slice.actions

register(slice.name, slice.reducer)
