import { createAsyncThunk } from '@reduxjs/toolkit'
import FinancialChartDashboardService from '../../../core/services/financialChart/FinancialChartDashboardService'
import FinancialChartDataService from '../../../core/services/financialChart/FinancialChartDataService'
import FinancialChartService from '../../../core/services/financialChart/FinancialChartService'
import { handleExport } from '../../utils/Export'
import {
  getDataChartByDashboard,
  getTimeFrequencyByIndicator,
} from '../common/helpers'
import { dashboardTypes, screenTypes } from '../constants'
import {
  changeDashboard,
  changeIsNewDashboard,
  changeUserSettings,
  deleteChart,
} from './slice'

export const getDashboard = createAsyncThunk(
  'financialChart/dashboard/GET_DASHBOARD',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDashboardService.getDashboard(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getSharedDashboard = createAsyncThunk(
  'financialChart/data/GET_SHARED_CHARTS',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDashboardService.getSharedDashboard(
      data,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getDashboardById = createAsyncThunk(
  'financialChart/dashboard/GET_DASHBOARD_BY_ID',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDashboardService.getDashboardById(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const postDashboard = createAsyncThunk(
  'financialChart/dashboard/POST_DASHBOARD',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await FinancialChartDashboardService.postDashboard(data)
    if (response.success) {
      dispatch(getDashboard())
      dispatch(changeIsNewDashboard(false))
      if (!data.isGroup && !data.cancelBackToDB) {
        dispatch(
          changeDashboard({
            screenType: screenTypes.DASHBOARD,
            dashboardType: dashboardTypes.MY_DASHBOARD,
            data: response.data,
          }),
        )
      }
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const updateDashboard = createAsyncThunk(
  'financialChart/dashboard/UPDATE_DASHBOARD',

  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDashboardService.updateDashboard(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const updateAndReloadDashboard = createAsyncThunk(
  'financialChart/dashboard/UPDATE_AND_RELOAD_DASHBOARD',
  async (data, { dispatch }) => {
    const response = await FinancialChartDashboardService.updateDashboard(data)
    if (response.success) {
      dispatch(getDashboard())
    }
  },
)

export const deleteDashboard = createAsyncThunk(
  'financialChart/dashboard/DELETE_DASHBOARD',
  async (data, { dispatch }) => {
    const response = await FinancialChartDashboardService.deleteDashboard(data)
    if (response.success) {
      dispatch(getDashboard())
    }
  },
)

export const getChartById = createAsyncThunk(
  'financialChart/data/GET_CHART_BY_ID',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getChartsById(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getCharts = createAsyncThunk(
  'financialChart/data/GET_CHARTS',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getCharts(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getChartsInDashboard = createAsyncThunk(
  'financialChart/data/GET_CHARTS_IN_DASHBOARD',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getCharts(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const updateCharts = createAsyncThunk(
  'financialChart/data/UPDATE_CHARTS',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.updateCharts(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getSharedCharts = createAsyncThunk(
  'financialChart/data/GET_SHARED_CHARTS',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getSharedCharts(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const deleteCharts = createAsyncThunk(
  'financialChart/data/DELETE_CHARTS',
  async (data, { dispatch, rejectWithValue }) => {
    const response = await FinancialChartService.deleteCharts(data)
    if (response.success) {
      dispatch(deleteChart(data.id))
    }
    return rejectWithValue('Error')
  },
)

export const getDataLatestPeriod = createAsyncThunk(
  'financialChart/data/LATEST_PERIOD',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDataService.getDataLatestPeriod(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getListIndicatorLatestPeriod = createAsyncThunk(
  'financialChart/data/LIST_INDICATOR_LATEST_PERIOD',
  async (data, { rejectWithValue }) => {
    const promises = []
    data.forEach((item) =>
      promises.push(FinancialChartDataService.getDataLatestPeriod(item)),
    )
    const responses = await Promise.allSettled(promises)

    if (responses) {
      const results = {}
      responses.forEach((res, index) => {
        const item = data[index]
        if (res.status === 'fulfilled' && res.value.success) {
          results[`${item.indicatorId}_${getTimeFrequencyByIndicator(item)}`] =
            res.value.data
        }
      })
      return results
    }
    return rejectWithValue('Error')
  },
)

export const getIndicators = createAsyncThunk(
  'financialChart/dataMenu/GET_INDICATOR_ALL',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDataService.getIndicators(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getChartByDashboardId = createAsyncThunk(
  'financialChart/dashboard/GET_CHART_BY_DASHBOARD_ID',
  async (payloads, { rejectWithValue }) => {
    const promises = payloads.map((payload) =>
      FinancialChartDashboardService.getDashboardById(payload),
    )
    const response = await Promise.allSettled(promises)
    if (response.length) {
      return getDataChartByDashboard(response)
    }
    return rejectWithValue('Error')
  },
)

export const getMyLibraryData = createAsyncThunk(
  'financialChart/data/GET_MY_LIBRARY_DATA',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getCharts(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const postChartUserSettings = createAsyncThunk(
  'financialChart/data/POST_CHART_USER_SETTING',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await FinancialChartService.postChartUserSettings(data)
    if (response.success) {
      dispatch(changeUserSettings(JSON.parse(data.Settings)))
      dispatch(getChartUserSettings())
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getChartUserSettings = createAsyncThunk(
  'financialChart/data/GET_CHART_USER_SETTING',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartService.getChartUserSettings(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadDataExport = createAsyncThunk(
  'financialChart/data/DOWNLOAD_DATA_EXPORT',
  async (data) => {
    const response = await FinancialChartDataService.downloadDataExport(data)
    handleExport(response.data, response.filename)
  },
)
