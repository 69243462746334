export const financial = {
  UNIT_LABEL: 'Unit',
  TRILLION: '(Trillion)',
  UNIT_BILLION: 'Billion VND',
  UNIT_TRILLION: 'Trillion VND',
  TITLE_PANEL: 'Financial Metric',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  PEERS_COMPARISON: 'PEERS COMPARISON',
  GROWTH_TIMELINE: 'GROWTH TIMELINE',
  SHORT_UNIT_TRILLION: 'Tril.VND',
  corporate: {
    Profitability: 'Profitability',
    'Net Revenue': 'Net Revenue',
    'Net Revenue Growth': 'Net Revenue Growth (%YoY)',
    'Net Income': 'Net Income',
    'Net Profit Margin': 'Net Profit Margin',
    'Gross Margin': 'Gross Margin (%)',
    'EBIT Margin': 'EBIT Margin',
    'SG&A/Net Revenue': 'SG&A/Net Revenue (%)',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',

    'Capital Structure': 'Capital Structure',
    'Total Assets': 'Total Assets',
    'Total Assets Growth': 'Total Assets Growth (%YoY)',
    "Owner's Equity": "Owner's Equity",
    "Owner's Equity Growth": "Owner's Equity Growth (%YoY)",
    'Total Liabilities/Total Assets': 'Total Liabilities/Total Assets (%)',
    "Total Liabilities/Owner's Equity": "Total Liabilities/Owner's Equity (%)",

    'Solvency Efficiency': 'Solvency Efficiency',
    'Current Ratio': 'Current Ratio',
    'Quick Ratio': 'Quick Ratio',
    'Interest rate Coverage': 'Interest rate Coverage',

    'Operation Efficiency': 'Operation Efficiency',
    'Asset Turnover': 'Asset Turnover',
    Inventory: 'Inventory',
    'Days of inventory on hand (DOH)': 'Days of inventory on hand (DOH)',
    Receivables: 'Receivables',
    'Day Sales Outstanding': 'Day Sales Outstanding',
    'Trade Payables': 'Trade Payables',
    'Number of days of payables': 'Number of days of payables',
    'Cash Conversion Cycle': 'Cash Conversion Cycle',
  },
  bank: {
    'P & L': 'P & L',
    TOI: 'TOI',
    'TOI Growth (YoY)': 'TOI Growth (%YoY)',
    NII: 'NII',
    'NII Growth (YoY)': 'NII Growth (%YoY)',
    NFI: 'NFI',
    'NFI/TOI': 'NFI/TOI (%)',
    'Operating Expense': 'Operating Expense',
    CIR: 'CIR (%)',
    PPoP: 'PPoP',
    'PPoP Growth (YoY)': 'PPoP Growth (%YoY)',
    PBT: 'PBT',
    'PBT Growth (YoY)': 'PBT Growth (%YoY)',

    'Loans & Asset Quality': 'Loans & Asset Quality',
    'Total Assets': 'Total Assets',
    'Total Assets Growth': 'Total Assets Growth (%YoY)',
    'Earning Assets': 'Earning Assets',
    YOEA: 'YOEA (%)',
    NIM: 'NIM',
    Credit: 'Credit',
    'Credit Growth (YoY)': 'Credit Growth (%YoY)',
    'Loan to Customers': 'Loan to Customers',
    NPL: 'NPL (%)',
    Provisions: 'Provisions',
    LLCrs: 'LLCrs (%)',
    'Deposit from Customers': 'Deposit from Customers',
    'Deposit from Customers Growth': 'Deposit from Customers Growth (%YoY)',
    "Owner's Equity": "Owner's Equity",
    "Owner's Equity Growth": "Owner's Equity Growth (%YoY)",
    'Loan to Total Assets': 'Loan to Total Assets',
    LDR: 'LDR',

    Profitability: 'Profitability',
    ROA: 'ROA',
    ROE: 'ROE',
    'Gross Margin': 'Gross Margin (%)',
    'Net Profit Margin': 'Net Profit Margin',

    'Capital and Liquidity': 'Capital and Liquidity',
    CAR: 'CAR (%)',
    COF: 'COF (%)',
    CASA: 'CASA',
    'CASA Ratio': 'CASA Ratio (%)',
    'Current Deposits for MLT Loan (%)': 'Current Deposits for MLT Loan (%)',
  },
  security: {
    'Total Assets': 'Total Assets',
    'Total Assets Growth (%YoY)': 'Total Assets Growth (%YoY)',
    'Margin Lending': 'Margin Lending',
    'Margin Lending Growth (%YoY)': 'Margin Lending Growth (%YoY)',
    "Margin Lending/ Owner's Equity": "Margin Lending/Owner's Equity (%)",
    'Total Equity': 'Total Equity',
    'Total Equity Growth (%YoY)': 'Total Equity Growth (%YoY)',
    'Chartered Capital': 'Chartered Capital',
    'Chartered Capital Growth (%YoY)': 'Chartered Capital Growth (%YoY)',
    'Net Debt': 'Net Debt',
    'Total Debt/Total Equity': 'Total Debt/Total Equity',
    'Brokerage Fee': 'Brokerage Fee',
    'Brokerage Margin': 'Brokerage Margin (%)',
    'Gross Profit': 'Gross Profit',
    'Gross Margin': 'Gross Margin (%)',
    'Net Profit': 'Net Profit',
    'Net Margin': 'Net Margin (%)',
    ROA: 'ROA',
    ROE: 'ROE',
  },
  insurance: {
    'Gross Written Premium': 'Gross Written Premium',
    'Gross Written PremiumGrowth (%YoY)': 'Gross Written PremiumGrowth (%YoY)',
    'Life Insurance': 'Life Insurance',
    'Life Insurance Growth (%YoY)': 'Growth (%YoY)',
    'Non-Life Insurance': 'Non-Life Insurance',
    'Non-Life Insurance Growth (%YoY)': 'Growth (%YoY)',
    NPATMI: 'NPATMI',
    'NPATMI Growth (%YoY)': 'NPATMI Growth (%YoY)',
    'Claim Ratio (%)': 'Claim Ratio (%)',
    'Combined Ratio (%)': 'Combined Ratio (%)',
  },
}

export const keyIndicators = {
  NAME: ' ',
  MWG: 'Corporate',
  GROWTH: 'Growth YoY',
  INDUSTRY: 'Industry',
  GROWTH_IN: 'Growth YoY',
  TITLE_PANEL: 'Key Indicator',
  corporate: {
    'Market Cap (Billion VND)': 'Market Cap (Billion VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Div Yield',
  },
  bank: {
    'Market Cap (Billion VND)': 'Market Cap (Billion VND)',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    'Preprovision ROA': 'Preprovision ROA',
    EPS: 'EPS',
    BVPS: 'BVPS',
    NIM: 'NIM',
    NPL: 'NPL',
  },
  security: {
    'Market Cap (Billion VND)': 'Market Cap (Billion VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Div Yield',
  },
  insurance: {
    'Market Cap (Billion VND)': 'Market Cap (Billion VND)',
    'EV/EBITDA': 'EV/EBITDA',
    'P/E': 'P/E',
    'P/B': 'P/B',
    ROE: 'ROE',
    ROA: 'ROA',
    ROIC: 'ROIC',
    EPS: 'EPS',
    BVPS: 'BVPS',
    'Div Yield': 'Div Yield',
  },
}

export const summary = {
  TITLE_PANEL: 'Summary',
  CHAIRMAN: 'Chairman',
  CEO: 'CEO',
  WEBSITE: 'Website',
  MARGIN_STATUS: 'Margin status',
  OUTSTANDING_SHARES: 'Outstanding shares (millions)',
  FREE_FLOAT: 'Free float',
  AVERAGE_VOLUME: 'Average volume 10 days',
  TELEPHONE: 'Telephone',
  FOREIGN_HOLDING: 'Foreign Holding',
  STATE_HOLDING: 'State Holding',
}

export const news = {
  TITLE_PANEL: 'NEWS',
}
