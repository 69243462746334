import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom/index.js'
import { RELEASE_TERM } from '../constants.js'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectActiveTimeType,
  selectFilterIssuanceValueBySectorType,
} from '../store/slice.js'
import { getIssuanceValueBySectorData } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const methodType = useSelector(selectActiveMethodType)
  const timeType = useSelector(selectActiveTimeType)
  const { duration } = useSelector(selectFilterIssuanceValueBySectorType)
  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeDuration = (value) => {
    dispatch(
      changeFilter({
        label: keys.ISSUANCE_VALUE_BY_SECTOR,
        key: 'duration',
        value,
      }),
    )
  }

  useEffect(() => {
    const payload = {
      MethodType: methodType,
      TimeRange: timeType,
      Duration: duration,
    }

    dispatch(getIssuanceValueBySectorData(payload))
  }, [locale, methodType, timeType, duration])

  return (
    <div className="d-flex">
      <div style={{ width: 185 }}>
        <SelectCustom
          value={duration}
          selectData={RELEASE_TERM}
          handleChange={handleChangeDuration}
          isI18n
        />
      </div>
    </div>
  )
}

export default Filter
