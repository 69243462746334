import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import style from '../index.module.css'

export const ChartHeader = () => {
  return (
    <div className={`row ${style.blockHeader}`}>
      <div className="w-50">
        <div className="container align-center j-b">
          <Span className="canvas-chart-title">
            <Translate value="market.equityTrading.deepTransaction.priceDepth.BUY_VOL" />
          </Span>
          <Span className="canvas-chart-title">
            <Translate value="market.equityTrading.deepTransaction.priceDepth.BID" />
          </Span>
        </div>
      </div>
      <div className="w-50">
        <div className="container align-center j-b">
          <Span className="canvas-chart-title">
            <Translate value="market.equityTrading.deepTransaction.priceDepth.ASK" />
          </Span>
          <Span className="canvas-chart-title">
            <Translate value="market.equityTrading.deepTransaction.priceDepth.SELL_VOL" />
          </Span>
        </div>
      </div>
    </div>
  )
}
