import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import TextEllipsis from '../../../../common/textEllipsis'
import { MAX_SECURITIES_ITEM } from '../../../../financialChart/common/constants'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import style from '../../index.module.css'
import {
  changeChecked,
  changeCheckedAll,
  changeIsFirstMount,
  key,
  selectChecked,
  selectIsFirstMount,
} from '../../store/slice'
import NameChecked from './NameChecked'

const DropdownCompany = ({ loading, stocks }) => {
  const dispatch = useDispatch()
  const parentRef = useRef()
  const listChecked = useSelector(selectChecked(key.COMPANY))
  const listAllChecked = stocks.map((el) => el.organizationId)
  const isFirstMount = useSelector(selectIsFirstMount(key.COMPANY))
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [filterStocks, setFilterStocks] = useState([])

  useEffect(() => {
    setFilterStocks(
      stocks
        .filter((stock) =>
          valueSearch
            ? stock.ticker?.toLowerCase().includes(valueSearch.toLowerCase()) ||
              stock.taxCode
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase()) ||
              stock.organizationShortName
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [valueSearch, stocks])

  useEffect(() => {
    if (isFirstMount && listChecked.length > 0) {
      dispatch(changeIsFirstMount(key.COMPANY))
    }
  }, [listChecked, isFirstMount])

  const handleChangeInput = (e) => {
    setValueSearch(e.target.value)
  }

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleChangeCheckAll = (e) => {
    dispatch(
      changeCheckedAll({
        isCheckedAll: e.target.checked,
        key: key.COMPANY,
        ids: e.target.checked ? listAllChecked : [],
      }),
    )
  }
  const handleHideDropdown = () => setIsShowDropdown(false)
  const handleChange = (organId, isChecked) => {
    dispatch(changeChecked({ isChecked, key: key.COMPANY, id: organId }))
  }

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className={`cursor-pointer h-20 ${style['span-input']}`}
        style={{ borderRadius: isShowDropdown ? '4px 4px 0px 0px' : undefined }}
      >
        <NameChecked
          stocks={stocks}
          listAllChecked={listAllChecked}
          listChecked={listChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`wrapper-tool ${style.mt3}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox bg-grey h-20 ${style.search}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <div className="h-100">
                    <ScrollComponent
                      autoHeight={true}
                      autoHeightMin={200}
                      autoHeightMax={300}
                    >
                      <ul className={style.ulFirst}>
                        <li>
                          <label className="d-flex ali-center">
                            <input
                              type="checkbox"
                              className={`checkbox line mr-8 ${style.darkCheckbox}`}
                              checked={listChecked.length === stocks.length}
                              onChange={handleChangeCheckAll}
                            />
                            <Translate value="market.event.SHOW_ALL" />
                          </label>
                        </li>

                        {filterStocks.map((stock) => (
                          <li
                            className={style.stock}
                            key={stock.organizationId}
                          >
                            <label className="d-flex">
                              <input
                                type="checkbox"
                                className={`checkbox line mr-8 ${style.darkCheckbox}`}
                                checked={listChecked.includes(
                                  stock.organizationId,
                                )}
                                onChange={(e) =>
                                  handleChange(
                                    stock.organizationId,
                                    e.target.checked,
                                  )
                                }
                              />
                              <TextEllipsis
                                className={style['stock-ticker']}
                                isI18n={false}
                                text={stock.ticker}
                                zIndexTooltip={20}
                              />

                              <TextEllipsis
                                className={style['stock-organName']}
                                isI18n={false}
                                text={stock.organizationShortName}
                                zIndexTooltip={20}
                                appendStyle={{ width: '70%' }}
                              />
                            </label>
                          </li>
                        ))}
                      </ul>
                    </ScrollComponent>
                  </div>
                )}
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </>
  )
}

DropdownCompany.propTypes = {
  stocks: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

DropdownCompany.defaultProps = {
  stocks: [],
}

export default DropdownCompany
