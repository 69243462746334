import PropTypes from 'prop-types'
import { CalcResize } from '../../../../common/CalcResize'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { ChartLabelTop } from './ChartLabelTop'
import style from './index.module.css'

export const ChartContainer = ({
  id,
  children,
  data,
  width,
  height,
  topLabel,
  isLoading,
}) => {
  return (
    <div id={id} className="h-100">
      {isLoading ? (
        <Loading />
      ) : !data || !data.length ? (
        <NoData />
      ) : (
        <SizeTracker>
          {(size) => (
            <>
              <div className={`${style.titleHeader}`}>
                {topLabel && <ChartLabelTop label={topLabel} />}
              </div>
              {height && size.height && (
                <div style={{ position: 'relative' }}>
                  <CalcResize
                    initSize={{
                      width: width,
                      height: height - size.height,
                    }}
                  >
                    {(resizeSize) => (
                      <div
                        style={{ width: resizeSize.width, margin: '0 auto' }}
                      >
                        {children(resizeSize)}
                      </div>
                    )}
                  </CalcResize>
                </div>
              )}
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

ChartContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  topLabel: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}
