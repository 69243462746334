import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import style from '../index.module.css'
import { selectLastDataApply, selectResultTickerIds } from '../store/slice'
import { exportResultThunk } from '../store/thunk'
import PopupWatchlist from './popupWatchlist'

const GroupButton = ({ setIsLoadingDownloadCsv }) => {
  const dispatch = useDispatch()

  const tickerIds = useSelector(selectResultTickerIds)
  const lastDataApply = useSelector(selectLastDataApply)

  const [isShowPopup, setIsShowPopup] = useState(false)

  const handleShowPopup = () => setIsShowPopup(true)

  const handleHidePopup = () => setIsShowPopup(false)

  const isNoResult = tickerIds.length === 0

  const handleExportResult = async () => {
    setIsLoadingDownloadCsv(true)
    await dispatch(exportResultThunk(lastDataApply))
    setIsLoadingDownloadCsv(false)
  }

  return (
    <div className="group-btn">
      <Button
        style={{ fontSize: 10 }}
        onClick={handleExportResult}
        disabled={isNoResult}
        className={`btn btn-blue h-20 btn-export ${style.btnExport} ${
          isNoResult ? style.disabled : ''
        }`}
      >
        <i className={`icon-print ${style.iconPrint}`} />
        <Translate value="tool.smartScreening.EXPORT" />
      </Button>
      {isShowPopup && <PopupWatchlist handleHidePopup={handleHidePopup} />}
      <Button
        style={{ fontSize: 10 }}
        onClick={handleShowPopup}
        disabled={isNoResult}
        className={`btn btn-yellow h-20 btn-watchlist ${style.btnWatchlist} ${
          isNoResult ? style.disabled : ''
        }`}
      >
        <i className={`icon-plus ${style.iconPlus}`} />
        <Translate value="tool.smartScreening.CREATE_WATCHLIST" />
      </Button>
    </div>
  )
}

export default GroupButton
