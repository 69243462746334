import moment from 'moment'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import InputFormatCurrency from '../../../common/input/InputFormatCurrencyCopy'
import PopupAlert, { POPUP_ICON_STATUS } from '../../../common/popup/PopupAlert'
import { PopupHeaderCommon } from '../../../common/popup/PopupHeaderCommon'
import {
  FORMAT,
  formatDateTime,
  getDateWithTimezone,
} from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import { valueTab } from '../infoValuation/constants'
import {
  selectActiveTab,
  selectDataTab,
  selectStateSameIndustry,
  updateTab,
} from '../infoValuation/store/slice'
import { valueTab as valueTabType } from './constants'
import style from './index.module.css'
import {
  selecBondIds,
  selecBondInfo,
  selectActiveTab as selectActiveTabType,
  selectCouponInput,
  selectCreateBondId,
  selectPopupCredit,
  updateCouponInput,
} from './store/slice'
import { getBondCashFlowScenario } from './store/thunk'

const Content = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const navigate = useCustomNavigate()
  const bondId = useSelector(selecBondIds)
  const createBondId = useSelector(selectCreateBondId)
  const activeTab = useSelector(selectActiveTabType)
  const form = useSelector(selectStateSameIndustry)

  const data = useSelector(selecBondInfo)
  const dataPopup = useSelector(selectPopupCredit)
  const tabActive = useSelector(selectActiveTab)
  const dataTab = useSelector(selectDataTab)

  const [show, setshow] = useState(false)
  const [showAlert, setshowAler] = useState(false)

  const couponInput = useSelector(selectCouponInput)

  const onChangeInput = (value) => {
    dispatch(updateCouponInput(value))

    if (
      tabActive !== valueTab.BOND_DEFAULT &&
      tabActive !== valueTab.BOND_CREATED
    ) {
      const elementCurrent = dataTab?.[tabActive]?.data

      let dataUpdate = {}
      for (const key in elementCurrent) {
        const elementPrev = elementCurrent[key - 1]
        const element = elementCurrent[key]
        const days = moment(element?.tradingDateId).diff(
          parseInt(key) === 0 ? data?.issueDateId : elementPrev?.tradingDateId,
          'days',
        )
        const couponInterestRate =
          element?.bondPaymentTypeId === 1
            ? parseFloat(value) + parseFloat(data?.floatInterestSpread * 100)
            : 0
        const estimatedValue =
          element?.bondPaymentTypeId === 1
            ? ((parseFloat(value) / 100 +
                parseFloat(data?.floatInterestSpread)) *
                element.parValue) /
              ((days ? (days === 366 ? 365 : days) : 0) / 365)
            : element?.estimatedValue || null

        dataUpdate[key] = {
          ...element,
          couponInterestRate: couponInterestRate,
          estimatedValue: estimatedValue,
        }
      }
      dispatch(
        updateTab({
          data: dataUpdate,
          ids: dataTab?.[tabActive]?.ids,
        }),
      )
    } else {
      const params = {
        BondIds:
          activeTab === valueTabType.BOND_DEFAULT ? bondId : createBondId,
        TradingDateId: getDateWithTimezone(form.paymentDate, locale),
        InterestBasisTypeId: form.interestBasis,
        InterestRate: value / 100,
        BondInterestId: data.BondInterestId,
        isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
      }

      if (
        parseFloat(value / 100).toFixed(4) !==
        parseFloat(data.interestRate).toFixed(4)
      ) {
        params.floatInterestBenchmarkId = data.floatInterestBenchmarkId
      }

      dispatch(
        getBondCashFlowScenario({
          param: params,
          editDate: true,
        }),
      )
    }
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <>
      <div
        className={`flex-column ${style.gap10px}`}
        style={{ height: `100%`, overflow: 'auto' }}
      >
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.organizationName" />
            </Span>
          </div>
          <div className={`flex-1 `}>
            <a
              className="cursor-pointer"
              onClick={() => redirectToBondIssuer(data?.organizationId)}
            >
              <Span className={`${style.label} ${style.link}`}>
                {data?.organizationShortName}
              </Span>
            </a>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.icbName" />
            </Span>
          </div>
          <div className="flex-1">
            <div
              className={`flex-1 ${style.link}`}
              onClick={() => setshowAler(true)}
            >
              <Span className={style.label}>{data?.icbName}</Span>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.issueDateId" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.value}>
              {data?.issueDateId &&
                formatDateTime(data?.issueDateId, FORMAT.DATE, locale)}
            </Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.maturityDateId" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.value}>
              {data?.maturityDateId &&
                formatDateTime(data?.maturityDateId, FORMAT.DATE, locale)}
            </Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.paymentCalendarMonth" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.value}>{data?.paymentCalendarMonth}</Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.expectedInterestPaymentPeriod" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.value}>
              {data?.expectedInterestPaymentPeriod}
            </Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.durationName" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.value}>{data?.durationName}</Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.couponTypeName" />
            </Span>
          </div>
          <div className="flex-1">
            <Span
              className={style.value}
              style={{
                color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
              }}
            >
              {data?.couponTypeName}
            </Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.labelCoupon}>
              <Translate value="bond.valuation.infoSec.info.CouponType" />
            </Span>
          </div>
          <div className="flex-1">
            <Span className={style.label}>
              {data?.couponTypeId === 1 ? (
                <div className="form-date-select">
                  <InputFormatCurrency
                    className={`${style.input}`}
                    handleBlur={(e) => {
                      onChangeInput(e)
                    }}
                    defaultValue={couponInput}
                    fractionDigits={0}
                    style={{
                      fontSize: 12,
                      fontWeight: 340,
                      color: '#FFF',
                      textAlign: 'center',
                      padding: 0,
                    }}
                  />
                  <Span className={style.value}>
                    {data?.floatInterestSpread
                      ? `+${formatVal(data?.floatInterestSpread * 100)}%`
                      : ''}
                  </Span>
                </div>
              ) : (
                <Span className={style.value}>
                  {`${
                    data?.couponInterestRate
                      ? `${formatVal(data?.couponInterestRate * 100)}%`
                      : ''
                  }`}
                </Span>
              )}
            </Span>
          </div>
        </div>
        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.labelCoupon}>
              <Translate value="bond.valuation.infoSec.info.floatInterestSpread" />
            </Span>
          </div>
          <div className="flex-1">
            <Span
              className={style.value}
              style={{
                color: data?.couponTypeId === 1 ? '#FECF2F' : '#ECECEC',
              }}
            >
              {data?.floatInterestBenchmarkDescription}
            </Span>
          </div>
        </div>

        <div className="d-flex">
          <div className="flex-1">
            <Span className={style.label}>
              <Translate value="bond.valuation.infoSec.info.info" />
            </Span>
          </div>
          <div className={`flex-1 ${style.link}`}>
            <Span
              className={`${style.label} cursor-pointer`}
              onClick={() => setshow(true)}
            >
              <Translate value="bond.valuation.infoSec.info.textView" />
            </Span>
          </div>
        </div>
        {data?.textCautionType === true && (
          <div>
            <Span
              className={style.note}
              style={{
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
                fontStyle: 'Poppin_Fixso',
                color: '#FF4752',
              }}
            >
              <Translate value="bond.valuation.infoSec.info.note" />
            </Span>
          </div>
        )}
      </div>
      {show && (
        <PopupHeaderCommon
          title="bond.valuation.infoSec.popupView.title"
          setIsShow={setshow}
        >
          <div style={{ minWidth: '400px' }}>
            {dataPopup.map((item, index) => (
              <div key={index} className={style.rowPopup}>
                <div>
                  <Span style={{ fontSize: 12, fontWeight: 600 }}>
                    {item?.eventTitle}
                  </Span>
                </div>
                <div className={`flex-1 ${style.link}`}>
                  <Span
                    style={{
                      fontSize: 12,
                      fontWeight: 340,
                      fontStyle: 'normal',
                    }}
                    onClick={() => window.open(item.sourceUrl)}
                  >
                    <Translate value="bond.valuation.infoSec.info.textView" />
                  </Span>
                </div>
              </div>
            ))}
            <div className={style.footerContainer}>
              <Button
                className={`btn btn-blue w-80 h-20 ${style.createBtn}`}
                style={{ fontSize: 11, fontWeight: 500, color: '#FFF' }}
                onClick={() => setshow(false)}
              >
                <Translate value="bond.valuation.infoSec.popupView.btnOk" />
              </Button>
            </div>
          </div>
        </PopupHeaderCommon>
      )}
      <PopupAlert
        message={I18n.t('bond.valuation.sameIndusTryBond.SC07_M001')}
        isShow={showAlert}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setshowAler(false)}
      />
    </>
  )
}

export default Content
