import React from 'react'
import { useSelector } from 'react-redux'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer.js'
import { TYPE } from '../../../../common/chart/footer/Item.js'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter/index.js'
import { Loading } from '../../../../common/loading/index.js'
import { NoData } from '../../../../common/noData/index.js'
import { SizeTracker } from '../../../../common/sizeTracker/index.js'
import { CHART_TYPE, colorArray } from '../../common/constants.js'
import { formatChartData } from '../../common/helper.js'
import { selectListVSICName } from '../investmentStructure/store/slice.js'
import { selectTimeFrequency } from '../totalRegisteredFDI/store/slice.js'
import CompositionOfFDIInflowsByIndustryColumnChart from './CompositionInTotalFDIColumnChart.js'
import Filter from './Filter.js'
import { convertDataColumnChart } from './helper.js'
import {
  selectChartType,
  selectDataColumnChart,
  selectDataPieChart,
  selectLoading,
} from './store/slice.js'

const InvestmentStructureFDI = ({ width, height }) => {
  const isLoading = useSelector(selectLoading)
  const dataPieChart = useSelector(selectDataPieChart)
  const dataColumnChart = useSelector(selectDataColumnChart)
  const chartType = useSelector(selectChartType)
  const listVSICName = useSelector(selectListVSICName)
  const timeFrequency = useSelector(selectTimeFrequency)

  const formatDataChart = formatChartData(
    dataPieChart,
    'fdiPercentage',
    'vsicName',
    5,
    'economy.fdi.fdiByIndustry.OTHERS',
  )

  const formatDataColumnChart = convertDataColumnChart(
    dataColumnChart,
    timeFrequency,
  )

  const getContent = () => {
    if (formatDataColumnChart?.length) {
      const sortedData = Object.keys(formatDataColumnChart[0])
        .filter((item) => item !== 'xLabel')
        .map((item) => ({
          value: formatDataColumnChart[0][item],
          vsicId: item,
        }))
        .sort((a, b) => b['value'] - a['value'])

      const otherRatio = sortedData
        .filter((item) => item.vsicId === 'other')
        .map((item) => ({
          ...item,
          color: colorArray[colorArray.length - 1],
          type: TYPE.SQUARE,
          text: 'economy.fdi.fdiByIndustry.OTHERS',
        }))

      const arrNew = sortedData
        .filter((item) => item.vsicId !== 'other')
        .map((item, index) => ({
          ...item,
          color: colorArray[index],
          type: TYPE.SQUARE,
          text: listVSICName.find(
            (el) => el.vsicId.toString() === item.vsicId.toString(),
          )?.vsicName,
        }))
      return arrNew.concat(otherRatio)
    }
    return []
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div>
            <Filter />
            <div id="CompositionOfFDIInflowsByIndustryChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {chartType === CHART_TYPE.PIE_CHART ? (
                        <>
                          {formatDataChart.length ? (
                            <PieChartWithFooter
                              data={formatDataChart}
                              height={height - size.height}
                              width={width}
                              footerRight
                              footerProps={{
                                isOneColumn: true,
                                appendStyle: { gridGap: 8 },
                                itemStyle: { paddingRight: 8 },
                              }}
                            />
                          ) : (
                            <NoData />
                          )}
                        </>
                      ) : (
                        <>
                          {dataColumnChart.length ? (
                            <SizeTracker>
                              {(size1) => {
                                return (
                                  <>
                                    {size1.height && (
                                      <CompositionOfFDIInflowsByIndustryColumnChart
                                        data={formatDataColumnChart}
                                        width={width}
                                        height={
                                          height - size.height - size1.height
                                        }
                                        barKeys={getContent()}
                                        xAxisKey="xLabel"
                                        margin={{ right: 30 }}
                                      />
                                    )}
                                    <FooterContainer
                                      key={width}
                                      numRow={2}
                                      numItemPerRow={3}
                                      data={getContent()}
                                    />
                                  </>
                                )
                              }}
                            </SizeTracker>
                          ) : (
                            <NoData />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      }}
    </SizeTracker>
  )
}

export default InvestmentStructureFDI
