import { createAsyncThunk } from '@reduxjs/toolkit'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'

export const getFisheryProfile = createAsyncThunk(
  'sector/sectorSpecific/fishery/overview/fisheryProfile',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getFisheryProfile(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
