import { chain, orderBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, ReferenceLine, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { NoData } from '../../../../common/noData'
import { BOND_TYPES } from '../../constants'
import { selectDataOverviewOfPortfolio } from '../../store/slice'
import ChartTooltip from './ChartTooltip'
import ReferenceDuration from './ReferenceDuration'

const RemainingDurationChart = ({ data, width, height }) => {
  const { list2 } = useSelector(selectDataOverviewOfPortfolio)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataChart = useMemo(() => {
    if (!data?.length) return []

    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    if (!newDataIsHaveBuyValue?.length) return []

    const dataByGovernment = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.GOVERNMENT_BOND.includes(item.bondTypeId),
    )

    if (!dataByGovernment.length) {
      const newData = orderBy(
        chain(newDataIsHaveBuyValue)
          .groupBy('organizationId')
          .map((value, key) => ({
            organizationId: key,
            tickers: value,
            buyValue: value.reduce(
              (total, item) => (total += item?.buyValue ?? 0),
              0,
            ),
          }))
          .value(),
        ['buyValue'],
        ['desc'],
      )

      const topData = newData.slice(0, 10).map((item) => {
        const totalDuration = item.tickers.reduce(
          (total, item) =>
            (total +=
              ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
          0,
        )

        return {
          name:
            locale === 'vi'
              ? item.tickers?.[0]?.organizationShortName
              : item.tickers?.[0]?.en_OrganizationShortName ??
                item.tickers?.[0]?.ticker,
          text:
            locale === 'vi'
              ? item.tickers?.[0]?.organizationShortName
              : item.tickers?.[0]?.en_OrganizationShortName ??
                item.tickers?.[0]?.ticker,
          buyValue: item.buyValue / 1000000,
          duration: item.buyValue !== 0 ? totalDuration / item.buyValue : 0,
        }
      })
      if (newData.length <= 10) {
        return topData
      }

      const other = newData
        .slice(10)
        .reduce((array, item) => array.concat(item.tickers), [])

      const totalOtherBuyValue = other.reduce(
        (total, item) => (total += item?.buyValue ?? 0),
        0,
      )

      const totalOtherDuration = other.reduce(
        (total, item) =>
          (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
        0,
      )

      const otherData = {
        name: I18n.t('common.OTHERS'),
        text: I18n.t('common.OTHERS'),
        buyValue: totalOtherBuyValue / 1000000,
        duration:
          totalOtherBuyValue !== 0
            ? totalOtherDuration / totalOtherBuyValue
            : 0,
      }

      return [...topData, otherData]
    }

    const newData = orderBy(
      chain(
        newDataIsHaveBuyValue.filter((item) =>
          BOND_TYPES.CORPORATE_BOND.includes(item.bondTypeId),
        ),
      )
        .groupBy('organizationId')
        .map((value, key) => ({
          organizationId: key,
          tickers: value,
          buyValue: value.reduce(
            (total, item) => (total += item?.buyValue ?? 0),
            0,
          ),
        }))
        .value(),
      ['buyValue'],
      ['desc'],
    )

    const totalGovernmentBuyValue = dataByGovernment.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const totalGovernmentCoupon = dataByGovernment.reduce(
      (total, item) =>
        (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
      0,
    )

    const governmentData = {
      name: I18n.t('bond.portfolio.common.GOVERNMENT_BOND'),
      text: I18n.t('bond.portfolio.common.GOVERNMENT_BOND_2'),
      buyValue: totalGovernmentBuyValue / 1000000,
      duration:
        totalGovernmentBuyValue !== 0
          ? totalGovernmentCoupon / totalGovernmentBuyValue
          : 0,
    }

    const topData = newData.slice(0, 10).map((item) => {
      const totalDuration = item.tickers.reduce(
        (total, item) =>
          (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
        0,
      )

      return {
        name:
          item.tickers?.[0]?.organizationShortName ?? item.tickers?.[0]?.ticker,
        buyValue: item.buyValue / 1000000,
        duration: item.buyValue !== 0 ? totalDuration / item.buyValue : 0,
      }
    })
    if (newData.length <= 9) {
      return [governmentData, ...topData]
    }

    const other = newData
      .slice(9)
      .reduce((array, item) => array.concat(item.tickers), [])

    const totalOtherBuyValue = other.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const totalOtherDuration = other.reduce(
      (total, item) =>
        (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
      0,
    )

    const otherData = {
      name: I18n.t('common.OTHERS'),
      text: I18n.t('common.OTHERS'),
      buyValue: totalOtherBuyValue / 1000000,
      duration:
        totalOtherBuyValue !== 0 ? totalOtherDuration / totalOtherBuyValue : 0,
    }

    return [governmentData, ...topData, otherData]
  }, [data, locale])

  const allDuration = useMemo(() => {
    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    const totalDuration = newDataIsHaveBuyValue.reduce(
      (total, item) =>
        (total += ((item?.remainDuration ?? 0) / 12) * (item?.buyValue ?? 0)),
      0,
    )

    const totalBuyValue = newDataIsHaveBuyValue.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    return totalBuyValue !== 0 ? totalDuration / totalBuyValue : 0
  }, [data])

  const renderTooltip = (dataChart) => (
    <ChartTooltip data={{ ...dataChart, allDuration }} />
  )

  const bondSizeLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_LABEL'),
    [locale],
  )

  const durationLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.DURATION_LABEL'),
    [locale],
  )

  return (
    <>
      {!!formatDataChart.length && !!list2?.length ? (
        <>
          <div style={{ height: height - 26 }}>
            <ChartContainer
              data={formatDataChart}
              width={width}
              height={height - 26}
              keyXAxis="name"
              isUseXAxisDiv
              showAllTick
              yAxis={[
                {
                  id: 'left',
                  keys: list2.slice(0, 1),
                  orientation: 'left',
                  label: bondSizeLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  isBarChart: true,
                  tickNum: 5,
                  min: 0,
                },
                {
                  id: 'right',
                  keys: list2.slice(1),
                  orientation: 'right',
                  isLineChart: true,
                  label: durationLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  tickNum: 5,
                  min: 0,
                  max: allDuration * 2,
                },
              ]}
              margin={{ left: 0, right: 16, top: 16, bottom: 16 }}
              renderCustomTooltip={renderTooltip}
            >
              {({ chartContentWidth }) => (
                <>
                  <Bar
                    isAnimationActive={false}
                    yAxisId="left"
                    dataKey={list2[0]}
                    fill="#45B29D"
                    barSize={27}
                  />
                  <Scatter
                    yAxisId="right"
                    dataKey={list2[1]}
                    isAnimationActive={false}
                    shape={
                      <ScatterDiamondShape dataKey={list2[1]} color="#ECECEC" />
                    }
                  />
                  {allDuration > 0 && (
                    <ReferenceLine
                      yAxisId="right"
                      y={allDuration}
                      stroke="#FACC5C"
                      strokeWidth={2}
                      strokeDasharray="5 5"
                      label={(ctx) => (
                        <ReferenceDuration ctx={ctx} value={allDuration} />
                      )}
                    />
                  )}
                </>
              )}
            </ChartContainer>
          </div>
          <Footer
            key={width}
            list={[
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_2',
                type: TYPE.SQUARE,
                before: {
                  bgColor: '#45B29D',
                },
              },
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.AVG_DURATION_2',
                renderIcon: (
                  <ScatterDiamondShape
                    style={{ marginRight: 5 }}
                    color="#ECECEC"
                  />
                ),
              },
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.ALL_AVG_DURATION_2',
                type: TYPE.DASHED_LINE,
                dashedLine: {
                  bgColor: '#FACC5C',
                },
              },
            ]}
          />
        </>
      ) : (
        <NoData />
      )}
    </>
  )
}

export default RemainingDurationChart
