import watchlistDetail from './watchlistDetail'
import watchlistTable from './watchlistTable'

const common = {
  TITLE: 'Bond Watchlist',
}

const watchlistBond = {
  watchlistTable,
  watchlistDetail,
  common,
}

export default watchlistBond
