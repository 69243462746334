import moment from 'moment'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import { MONTHLY_TITLE } from './constants'
import { ALL_EVENT_ID, INIT_CHECKED } from './store/slice'

const currentYear = new Date().getFullYear()

const MAX_DATE = 4
export const RANGE_MONTH = 6

export const handleChecked = (payload, args, checked) => {
  if (!payload.length) {
    return INIT_CHECKED
  }

  const { EventTypeId: tab } = args
  const currentChecked = checked[tab]

  if (currentChecked.includes(ALL_EVENT_ID)) {
    const ids = payload.map((item) => item.macroEventTypeId)
    return { ...checked, [tab]: [ALL_EVENT_ID, ...ids] }
  }

  return checked
}

export const getFirstDayOfMonth = (month, year = currentYear) =>
  new Date(year, month, 1)

export const getLastDayNext6Month = (month, year = currentYear) =>
  new Date(year, month + RANGE_MONTH, 0)

export const get6MonthByStartDate = (startDate) => {
  const month = new Date(startDate).getMonth()
  const year = new Date(startDate).getFullYear()

  return {
    startDate: new Date(year, month - 5, 1),
    endDate: new Date(year, month + 1, 0),
  }
}

export const get6MonthByEndDate = (endDate) => {
  const month = new Date(endDate).getMonth()
  const year = new Date(endDate).getFullYear()

  return {
    startDate: new Date(year, month, 1),
    endDate: new Date(year, month + RANGE_MONTH, 0),
  }
}

export const getIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => item.macroEventId)
}

export const handleUpcomingData = (payload) => {
  if (!payload.length) {
    return []
  }

  const dataWithMonthYear = payload
    .map((item) => addMonthYear(item))
    .filter((item) => item.publicDate)
  const monthYearNotDuplicate = handleDuplicateByKey(dataWithMonthYear, [
    'month',
    'year',
  ])
    .map((item) => ({ month: item.month, year: item.year }))
    .sort(sortByMonthYear)
  const newData = []

  monthYearNotDuplicate.forEach((item) => {
    const parentId = `collapse-${item.month}-${item.year}`
    newData.push({
      ...item,
      isCollapse: true,
      level: 1,
      macroEventId: parentId,
      parentId,
      sortValue: `${item.year}-${item.month}-${MAX_DATE}`,
    })

    const allChildWithMonthYear = getAllChildByMonthYear(
      dataWithMonthYear,
      item,
      parentId,
    ).sort((a, b) => moment(a.publicDate).diff(moment(b.publicDate)))

    newData.push(...allChildWithMonthYear)
  })

  return newData
}

const getAllChildByMonthYear = (data, monthYear, parentId) => {
  return data
    .filter(
      (element) =>
        element.month === monthYear.month && element.year === monthYear.year,
    )
    .map((element) => {
      const date = new Date(element.publicDate).getDate()
      return {
        ...element,
        sortValue: `${element.year}-${addPrefix(element.month)}-${addPrefix(
          date,
        )}${date}`,
        parentId,
        level: 2,
      }
    })
}

const addPrefix = (time) => {
  return Number(time).toString().length === 1 ? `0${time}` : `${time}`
}

const addMonthYear = (item) => {
  return {
    ...item,
    month: item.publicDate ? new Date(item.publicDate).getMonth() : undefined,
    year: item.publicDate ? new Date(item.publicDate).getFullYear() : undefined,
  }
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }
  }

  return 0
}

export const handleCollapseLevel = (data) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.macroEventId]: {
        level: cur?.level || 2,
        isRowCollapse: cur?.isCollapse,
      },
    }
  }, {})
}

export const disableRowHovered = (payload) => {
  return payload
    .filter(
      (item) =>
        typeof item.macroEventId === 'string' &&
        item.macroEventId.includes('collapse'),
    )
    .reduce((previous, current) => {
      return {
        ...previous,
        [current.macroEventId]: true,
      }
    }, {})
}

export const handleLevels = (data) => {
  return data.map((item) => {
    return {
      id: item.macroEventId,
      parentId: item.parentId,
      level: item.level,
    }
  })
}

export const keyByParentId = (payload) => {
  if (!payload.length) {
    return {}
  }

  return payload.reduce((previous, current) => {
    return {
      ...previous,
      [current.macroEventTypeId]: current.parentMacroEventTypeId,
    }
  }, {})
}

export const getDateHasEvent = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.filter((item) => item).map((item) => new Date(item))
}

export const getEventIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return handleDuplicateByKey(payload, ['macroEventId']).map(
    (item) => item.macroEventId,
  )
}

export const handleNewsData = (payload) => {
  if (!payload.length) {
    return []
  }

  const dataWithMonthYear = payload
    .map((item) => addMonthYear(item))
    .filter((item) => item.publicDate)
  const monthYearNotDuplicate = handleDuplicateByKey(dataWithMonthYear, [
    'month',
    'year',
  ])
    .map((item) => ({ month: item.month, year: item.year }))
    .sort(sortByMonthYear)
    .reverse()

  const result = {}
  monthYearNotDuplicate.forEach((item) => {
    const key = `${item.month + 1}-${item.year}`
    result[key] = dataWithMonthYear
      .filter(
        (element) => element.month === item.month && element.year === item.year,
      )
      .map((element) => ({
        ...element,
        date: new Date(element.publicDate),
        event: element.macroEventTypeName,
        news: element.title,
      }))
  })

  return result
}

export const handleActiveRows = (rowId, activeRows) => {
  return activeRows.includes(rowId) ? [] : [rowId]
}

export const getDisableClick = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload
    .filter((item) => item.isCollapse)
    .map((item) => item.macroEventId)
}

export const handleItemClickedData = (data, locale, timeZone) => {
  if (!Object.values(data).length) {
    return []
  }

  const allEvent = Object.values(data).flat()

  const dataNotDuplicate = handleDuplicateByKey(allEvent, [
    'event',
    'publicDate',
  ])
    .sort(sortByMonthYear)
    .reverse()
  const result = []
  dataNotDuplicate.forEach((item) => {
    const day = formatDateTime(item.publicDate, FORMAT.DATE, locale, timeZone)
    const listTitle = allEvent.filter(
      (element) =>
        element.event === item.event && element.publicDate === item.publicDate,
    )
    result.push({
      day,
      event: item.event,
      listTitle,
      id: item.macroEventId,
      newsId: item.newsId,
    })
  })

  return result
}

export const getGroupMonth = (str, locale) => {
  const month = MONTHLY_TITLE[parseInt(str)][locale]
  const year = str.slice(str.lastIndexOf('-') + 1)

  return `${month}-${year}`
}
