import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import InputFormatCurrency from '../../../common/input/InputFormatCurrency'
import { changeMarketCapFrom, changeMarketCapTo } from '../store/slice'

const FilterMarketCap = () => {
  const dispatch = useDispatch()

  const changeInputMarketCapFrom = (val) => {
    dispatch(changeMarketCapFrom(val))
  }
  const changeInputMarketCapTo = (val) => {
    dispatch(changeMarketCapTo(val))
  }

  return (
    <div className="mb-40">
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.topMover.MARKET_CAP" />
      </Span>
      <div className="d-flex mt-8">
        <div className="form-control mr-8 mb-0">
          <InputFormatCurrency
            handleBlur={changeInputMarketCapFrom}
            suffix={'B'}
            className="bg-blue-1"
          />
        </div>
        <span>-</span>
        <div className="form-control ml-8 mb-0">
          <InputFormatCurrency
            handleBlur={changeInputMarketCapTo}
            suffix={'B'}
            className="bg-blue-1"
          />
        </div>
      </div>
    </div>
  )
}

export default FilterMarketCap
