import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getTopAttractiveInvestment } from './thunk'

const initialState = {
  isLoading: true,
  dataTopAttractiveInvestment: [],
}

const slice = createSlice({
  name: 'economy/fdi/overview/fdiIndustry',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopAttractiveInvestment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopAttractiveInvestment.fulfilled, (state, action) => {
      state.dataTopAttractiveInvestment = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getTopAttractiveInvestment.rejected, (state, action) => {
      state.isLoading = action.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectTopAttractiveInvestment = (state) =>
  state[slice.name].dataTopAttractiveInvestment

register(slice.name, slice.reducer)
