import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Right } from '../../../../../common/news/detail/right'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../../../constants/routes'
import { selectNewsOther, selectNewsOtherLoading } from '../../store/slice'
import { getNewsOther } from '../../store/thunk'

export const RightContent = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectNewsOtherLoading)
  const sectorInfo = useSelector(selectSectorInfo)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const newsId = params.get('newsId')
  const data = useSelector(selectNewsOther)

  useEffect(() => {
    if (sectorInfo.icbId && newsId) {
      dispatch(
        getNewsOther({
          ICBId: sectorInfo.icbId,
          NewsId: newsId,
        }),
      )
    }
  }, [sectorInfo.icbId, newsId])

  const onAction = (newsId) => () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-detail'
      ].route,
      sectorInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <Right
      title={'sector.sectorConstituents.newsReport.SAME_CATEGORY'}
      data={data}
      isLoading={isLoading}
      onAction={onAction}
    />
  )
}
