import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FORMAT, formatDateTime } from '../utils/Datetime'
import UseTimeZone from './hooks/useTimeZone'

export const DateTimeSpan = ({ date, formatDate }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  return formatDateTime(date, formatDate, locale, timeZone)
}

DateTimeSpan.defaultProps = {
  date: PropTypes.string.isRequired,
  formatDate: PropTypes.string,
}

DateTimeSpan.defaultProps = {
  formatDate: FORMAT.DATE,
}
