import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { MONTH_QUARTER_YEAR_TABS, TIME_FREQUENCY } from '../constants'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectFilterRelativeToEquity,
} from '../store/slice.js'
import { getRelativeToEquityData } from '../store/thunk'

const Filter = () => {
  const dispatch = useDispatch()

  const { timeType } = useSelector(selectFilterRelativeToEquity)
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)

  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.RELATIVE_TO_EQUITY,
        key: 'timeType',
        value: item.value,
      }),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId && timeType) {
      const payload = {
        TimeRange: timeType,
        OrganizationId: basicInfo.organizationId,
      }

      dispatch(getRelativeToEquityData(payload))
    }
  }, [locale, basicInfo.organizationId, timeType])

  return (
    <div className="d-flex justify-content-end mb-8">
      <div className={`${style.menuContainer}`}>
        <DispatchActionTab
          data={MONTH_QUARTER_YEAR_TABS.filter(
            (item) => item.value !== TIME_FREQUENCY.MONTHLY,
          )}
          activeTab={timeType}
          onChangeTab={handleChangeTimeType}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
      </div>
    </div>
  )
}

export default Filter
