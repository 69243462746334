import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { convertDataTree } from '../../../common/tree/helper'

import { SEC_GROUP_INDICATOR } from '../../common/constants'
import {
  selectIndicatorByTypeSecurities,
  selectLoadingIndicators,
} from '../../store/slice'
import { MAP_TIME_FREQUENCY, TIME_FREQUENCY } from '../constants'
import { getDeepestIndicatorsWithTimeFrequency } from '../helper'

const useGetIndicator = () => {
  const loadingEconomy = useSelector(selectLoadingIndicators)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )

  const [indicatorsTree, setIndicatorsTree] = useState({})

  const deepestEconomies = useMemo(() => {
    return getDeepestIndicatorsWithTimeFrequency(listSecuritiesEconomy)
  }, [listSecuritiesEconomy])

  useEffect(() => {
    if (listSecuritiesEconomy.length > 0) {
      const lisSecuritiesEconomyWithTimeFrequency = listSecuritiesEconomy.map(
        (economy) => {
          const children = {}
          Object.values(TIME_FREQUENCY).forEach((value, index) => {
            if (economy[value]) {
              children[`${economy.id}_${value}`] = {
                id: `${economy.id}_${value}`,
                parentId: economy.id,
                timeFrequency: value,
                secName: MAP_TIME_FREQUENCY[value],
                name: I18n.t(MAP_TIME_FREQUENCY[value]),
                showLeftTree: economy.showLeftTree,
                order: economy.order + index + 1,
                children: {},
              }
            }
          })
          return Object.keys(children).length > 0
            ? { ...economy, children }
            : economy
        },
      )
      setIndicatorsTree(
        convertDataTree(
          lisSecuritiesEconomyWithTimeFrequency,
          'id',
          'parentId',
        ),
      )
    }
  }, [listSecuritiesEconomy])

  return { indicatorsTree, deepestEconomies, loadingEconomy }
}

export default useGetIndicator
