import directorDealProxy from '../../../proxies/market/marketInDepth/DirectorDealProxy'
import { ServiceBase } from '../../ServiceBase'

export class DirectorDealService extends ServiceBase {
  getNetValueInternalTrading(params) {
    return this.getData(() =>
      directorDealProxy.getNetValueInternalTrading(params),
    )
  }

  getValueNetBreakDown(params) {
    return this.getData(() => directorDealProxy.getValueNetBreakDown(params))
  }

  getDirectorDeals(params) {
    return directorDealProxy.getDirectorDeals(params)
  }

  getActionTypes(params) {
    return this.getData(() => directorDealProxy.getActionTypes(params))
  }

  getActionStatus(params) {
    return this.getData(() => directorDealProxy.getActionStatus(params))
  }

  downloadInfoDirectorDeals(params) {
    return this.getFileDownload(() =>
      directorDealProxy.downloadInfoDirectorDeals(params),
    )
  }
}

export default new DirectorDealService()
