import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class TopMoverProxy extends ProxyBase {
  getTopMover(params) {
    return this.get('GetTopMover', params)
  }

  downloadTopMover(params) {
    return this.get('DownloadTopMover', params, 'download')
  }
}

export default new TopMoverProxy(ServiceProxyConfig.Market.TopMover.ServiceUrl)
