import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'

export const getEfficiencyChart = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/chart/GET_EFFICIENCY_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getEfficiency(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getGrowthChart = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/chart/GET_GROWTH_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getGrowth(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProfitabilityChart = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/chart/GET_PROFITABILITY_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getProfitability(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getStrengthChart = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/chart/GET_STRENGTH_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getStrength(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getValuationChart = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/corporate/chart/GET_VALUATION_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialAnalysisService.getValuation(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
