import FDIByIndustriesProxy from '../../../proxies/economy/fdi/FDIByIndustriesProxy'
import { ServiceBase } from '../../ServiceBase'

export class FDIByIndustriesService extends ServiceBase {
  getTotalRegisterCapitalRequest(params) {
    return this.getData(() =>
      FDIByIndustriesProxy.getTotalRegisterCapitalRequest(params),
    )
  }

  downloadTotalRegisterCapital(params) {
    return this.getFileDownload(() =>
      FDIByIndustriesProxy.downloadTotalRegisterCapital(params),
    )
  }

  getVSICName(params) {
    return this.getData(() => FDIByIndustriesProxy.getVSICName(params))
  }

  getFDIStructureByIndustry(params) {
    return this.getData(() =>
      FDIByIndustriesProxy.getFDIStructureByIndustry(params),
    )
  }

  getCompositionOfFDIInflowsByIndustryPieChart(params) {
    return this.getData(() =>
      FDIByIndustriesProxy.getCompositionOfFDIInflowsByIndustryPieChart(params),
    )
  }

  getCompositionOfFDIInflowsByIndustryColumnChart(params) {
    return this.getData(() =>
      FDIByIndustriesProxy.getCompositionOfFDIInflowsByIndustryColumnChart(
        params,
      ),
    )
  }

  getFDITypeName(params) {
    return this.applyMemoryCache(
      'FDIByIndustriesService.getFDITypeName',
      params,
    )(() => FDIByIndustriesProxy.getFDITypeName(params))
  }
}

export default new FDIByIndustriesService()
