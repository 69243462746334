import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../../utils/Color'
import { formatVal } from '../../../../../utils/Value'
import { renderTimeValueBond } from '../helper'
import { selectActiveMethodType } from '../store/slice'
import { getKeyScale } from './common/helper'
import { valueTimefrequency } from './constants'
import { selectActiveTab } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  lineKey,
  name1,
  name2,
  name3,
}) => {
  const selectTime = useSelector(selectActiveMethodType)
  const activeTab = useSelector(selectActiveTab)
  const renderTooltip = (tooltipData) => {
    const tooltipContentData = {
      [name1]: {
        label: I18n.t(
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.privatePlacement'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.privatePlacement',
        ),
        format: (val) => formatVal(val),
        unit:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.unitMoney'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.unitMoney',
      },
      [name2]: {
        label: I18n.t(
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.publicPlacement'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.publicPlacement',
        ),
        format: (val) => formatVal(val),
        unit:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.unitMoney'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.unitMoney',
      },
      [name3]: {
        label: I18n.t(
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.undefined'
            : 'bond.overview.ValueOfBonds.chart.tooltip.undefined',
        ),
        format: (val) => formatVal(val),
        unit:
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.unitMoney'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.unitMoney',
      },
      [lineKey]: {
        label: I18n.t(
          activeTab === valueTimefrequency.ISSUEMETHOD
            ? 'bond.overview.ValueOfBonds.chart.tooltip.rateOfChange'
            : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.rateOfChange',
        ),
        format: (val) => `${formatVal(val)}%`,
        unit: renderTimeValueBond(selectTime),
      },
    }

    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>
            {tooltipData.XAxis}{' '}
            {I18n.t(
              activeTab === valueTimefrequency.ISSUEMETHOD
                ? 'bond.overview.ValueOfBonds.chart.tooltip.label'
                : 'bond.overview.ValueOfBonds.chartCollateral.tooltip.label',
            )}
          </Span>
        </div>
        {displayData.map((key, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(tooltipContentData[key].label)}
              </Span>
            </div>
            <div className="ml-8">
              <Span
                style={{
                  fontSize: 11,
                  fontWeight: 500,
                  color: getTwoColorByValue(tooltipData[key] || 0),
                }}
              >
                {tooltipContentData[key].format(tooltipData[key])}
              </Span>
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {' ' + I18n.t(tooltipContentData[key].unit)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  const yAxisLeft = [
    {
      id: 'left',
      keys: getKeyScale(displayData, name1, name2, lineKey),
      orientation: 'left',
      isStackedBar: true,
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.ValueOfBonds.chart.labelLeft'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
    },
  ]

  const yAxisRight = [
    {
      id: 'right',
      keys: [lineKey],
      orientation: 'right',
      isLineChart: true,
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.ValueOfBonds.chart.labelRight'),
      labelPosition: AXIS_LABEL_POSITION.RIGHT,
    },
  ]

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      timeFrame="CUSTOM"
      // timeFrame="1M"
      tickFormatter={(d) => d}
      margin={{
        top: 20,
        bottom: 0,
        left: 0,
        right: displayData.includes('rateOfChange') ? 0 : 40,
      }}
      hasBarChart={displayData.length ? true : false}
      yAxis={
        displayData.includes('rateOfChange')
          ? yAxisLeft.concat(yAxisRight)
          : yAxisLeft
      }
      renderCustomTooltip={(dataFormat) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {displayData.includes('privatePlacement') && (
            <Bar
              stackId="barRight"
              isAnimationActive={false}
              yAxisId="left"
              dataKey={name1}
              fill={'#185999'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}
          {displayData.includes('publicPlacement') && (
            <Bar
              stackId="barRight"
              isAnimationActive={false}
              yAxisId="left"
              dataKey={name2}
              fill={'#fff6df'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}

          {displayData.includes('undefined') && (
            <Bar
              stackId="barRight"
              isAnimationActive={false}
              yAxisId="left"
              dataKey={name3}
              fill={'#A6CF98'}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
            />
          )}

          {displayData.includes('rateOfChange') && (
            <Line
              yAxisId="right"
              dataKey={lineKey}
              type="linear"
              stroke={'#DF5A49'}
              fill={'#DF5A49'}
              strokeWidth={2}
              dot={{
                strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
                strokeDasharray: '',
              }}
              activeDot={{ r: 1 }}
              isAnimationActive={false}
            />
          )}
        </>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
