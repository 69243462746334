export const TIME_FRAME_OPTIONS = [
  {
    title: '1Y',
    value: 'OneYear',
  },
  { title: '2Y', value: 'TwoYears' },
  { title: '3Y', value: 'ThreeYears' },
  { title: '5Y', value: 'FiveYears' },
]

export const STACK_KEY = [
  'value1',
  'value2',
  'value3',
  'value4',
  'value5',
  'value6',
]

export const STACK_COLORS = {
  2: ['#185999', '#fff6df'],
  3: ['#185999', '#fff6df', '#fe7e6d'],
  4: ['#185999', '#3cabeb', '#fff6df', '#fe7e6d'],
  5: ['#185999', '#40abff', '#f6ad5c', '#a6cf98', '#fff6df'],
  6: ['#45b29d', '#facc5c', '#df5a49', '#185999', '#0096eb', '#ecffdf'],
}
