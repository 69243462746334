import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import { getFontSize } from '../../../utils/FontSize'
import InputDate from './InputDate'

const InputDateCalendar = forwardRef(
  (
    {
      date,
      onClick,
      onChange,
      onInput,
      minDate,
      maxDate,
      placeholderText,
      style,
      disabled,
    },
    ref,
  ) => {
    const onInputChange = (date, e) => {
      if (!disabled) {
        e.target.value = date || null
        onChange(e)
      }
    }

    return (
      <div onClick={onClick} ref={ref}>
        <InputDate
          date={date}
          onChange={onInputChange}
          onInput={onInput}
          minDate={minDate}
          maxDate={maxDate}
          placeholder={placeholderText}
          style={{
            ...style,
            fontSize: getFontSize(style?.fontSize || 12),
            opacity: disabled ? 0.5 : 1,
          }}
          disabled={disabled}
        />
      </div>
    )
  },
)

InputDateCalendar.propTypes = {
  onChange: PropTypes.func,
  onInput: PropTypes.func,
  onClick: PropTypes.func,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  placeholderText: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
}

InputDateCalendar.defaultProps = {
  minDate: new Date(1950, 0, 1),
  maxDate: new Date(2050, 0, 0),
  onInput: () => {},
}

export default InputDateCalendar
