import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectZoom } from '../../layout/header/fontsize/slice'
import { isWindowsOs } from '../../utils/Device'

function getChromeVersion() {
  var userAgent = navigator.userAgent
  var chromeMatch = userAgent.match(/Chrome\/(\d+)\./) // Match the Chrome version part

  if (chromeMatch) {
    return chromeMatch[1] // Return the version number
  } else {
    return null
  }
}

export const chromeVersion = getChromeVersion()
export const latestChromeVersionAffectToScale = 128

const useWindowDevicePixelRatio = () => {
  const originalDevicePixelRatio = window.devicePixelRatio
  const zoom = useSelector(selectZoom)

  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio,
  )

  const reverseFormatWithZoom = (val) => {
    return val * (zoom / devicePixelRatio)
  }

  const formatWithZoom = (val, isRequired) => {
    if (!isRequired && +chromeVersion < latestChromeVersionAffectToScale)
      return val
    return isWindowsOs() ? (val * devicePixelRatio) / zoom : val / zoom
  }

  useEffect(() => {
    const handleWindowResize = () =>
      setDevicePixelRatio(window.devicePixelRatio)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return {
    zoom,
    originalDevicePixelRatio,
    devicePixelRatio,
    reverseFormatWithZoom,
    formatWithZoom,
  }
}

export default useWindowDevicePixelRatio
