import { useEffect } from 'react'
import { Translate } from 'react-redux-i18n'
import { usePrevious } from '../../../../../common/hooks/usePrevious'
import { Span } from '../../../../../common/html/Span'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { ITEM_TYPE } from '../../constant'
import style from './index.module.css'
import SelectedSectorName from './SelectedSectorName'
import SelectedTickerName from './SelectedTickerName'

const WRAPPER_ID = 'WRAPPER_ID'

const SelectedItems = ({ items, setItems }) => {
  const prevItemLength = usePrevious(items.length)

  const onClickDelete = (item) => {
    if (item.icbId) {
      const icbId = item.icbId
      const newItems = JSON.parse(JSON.stringify(items))
      const sectorIndex = newItems.findIndex((item) => item.icbId === icbId)

      if (
        sectorIndex === newItems.length - 1 ||
        !!newItems[sectorIndex + 1].icbId
      ) {
        newItems.splice(sectorIndex, 1)
      } else {
        newItems[sectorIndex].isDeleted = true
      }
      setItems(newItems)
    } else {
      const orgId = item.organizationId
      const newItems = JSON.parse(JSON.stringify(items))
      const index = newItems.findIndex((item) => item.organizationId === orgId)
      if (index !== -1) {
        if (!!newItems[index - 1]?.icbId && newItems[index - 1]?.isDeleted) {
          newItems.splice(index - 1, 2)
        } else {
          newItems.splice(index, 1)
        }
      }
      setItems(newItems)
    }
  }

  const renderItemList = () => {
    const itemList = items.filter((item) => !item.isDeleted)

    if (itemList.length === 0) return <NoData />

    return (
      <ScrollComponent>
        <div className={style.itemList}>
          <div className={style.indexCol}>
            {itemList.map((item, index) => (
              <div key={index}>{index + 1}</div>
            ))}
          </div>
          <div className={style.tickerNameCol}>
            {itemList.map((item, index) => {
              const id = item.icbId
                ? `${ITEM_TYPE.SECTOR}-${item.icbId}`
                : `${ITEM_TYPE.TICKER}-${item.organizationId}`

              return item.icbId ? (
                <SelectedSectorName key={id} icbId={item.icbId} />
              ) : (
                <SelectedTickerName
                  key={id}
                  organizationId={item.organizationId}
                />
              )
            })}
          </div>
          <div className={style.checkboxCol}>
            {itemList.map((item, index) => (
              <div key={index} className={style.checkboxItem}>
                <i
                  className="icon-delete-circle cursor-pointer"
                  style={{ fontSize: 10, color: '#4b5259' }}
                  onClick={() => onClickDelete(item)}
                />
              </div>
            ))}
          </div>
        </div>
      </ScrollComponent>
    )
  }

  useEffect(() => {
    const scrollDiv = document.querySelector(
      `#${WRAPPER_ID} .scrollbars div:first-child`,
    )

    if (scrollDiv && items && items.length > prevItemLength)
      scrollDiv.scrollTo({
        top: scrollDiv.scrollHeight,
        behavior: 'smooth',
      })
  }, [items, prevItemLength])

  return (
    <div className={style.selectedItems}>
      <div className={style.title}>
        <Span
          style={{
            opacity: 0.4,
            display: 'block',
            height: 20,
            lineHeight: '20px',
          }}
        >
          <Translate value="sector.sectorConstituents.valuation.SELECTED_ITEMS" />
        </Span>
      </div>
      <div className={style.selectedItemsContent} id={WRAPPER_ID}>
        {renderItemList()}
      </div>
    </div>
  )
}

export default SelectedItems
