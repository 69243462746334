import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPProvinceService from '../../../../../../core/services/economy/gdp/GDPProvinceService'
import { handleExport } from '../../../../../utils/Export'
import {
  handleRangeYear,
  handleYear,
} from '../../panelTop10Provinces/store/slice'
import { getRangeYear } from '../helper'

export const getGDPByProvince = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGDPProvince/getGDPByProvince',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPProvinceService.getGDPByProvince(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGDPTypeName = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGDPProvince/getGDPTypeName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPProvinceService.getGDPTypeName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxPeriod = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGDPProvince/getMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const res = await GDPProvinceService.getMaxPeriod(params)
      if (res.data.length === 2) {
        const maxYearTop10 = res.data[0]?.maxYear
          ? res.data[0].maxYear
          : new Date().getFullYear()
        dispatch(handleYear(maxYearTop10))
        dispatch(handleRangeYear(getRangeYear(res.data[0])))
      }
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadGDPByProvince = createAsyncThunk(
  'economy/gdp/gdpByProvince/panelGDPProvince/downloadGDPByProvince',
  async (data) => {
    const response = await GDPProvinceService.downloadGDPByProvince(data)
    handleExport(response.data, response.filename)
  },
)
