import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { selectExchangeCode } from '../slice'
import { ChartComponent } from './ChartComponent'
import { VIEW_BY, listTimeFilter } from './constants'
import {
  changeTimeFilter,
  selectDataChart,
  selectLoading,
  selectTimeFilter,
} from './store/slice'
import {
  getCreditAndDepositChartData,
  getMarginLendingChartData,
  getSectorEarningGrowthChartData,
} from './store/thunk'

const DependsOnSector = ({ width, height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const timeFilter = useSelector(selectTimeFilter)

  const getKeyChart = () => {
    if (typeSector === COM_GROUP.BANK) {
      if (timeFilter?.value === VIEW_BY.YEARLY) {
        return {
          barKey: 'rT0277',
          lineKey: ['rT0296', 'rT0297'],
        }
      } else {
        return {
          barKey: 'rT0277',
          lineKey: ['rT0310', 'rT0311'],
        }
      }
    } else if (typeSector === COM_GROUP.SECURITIES) {
      return {
        barKey: 'nS239',
        lineKey: ['nS239_A'],
      }
    } else {
      return {
        barKey: 'iS3',
        lineKey: ['iS3_A'],
      }
    }
  }

  const onChangeTab = (data) => {
    dispatch(
      changeTimeFilter({
        label: data.title,
        value: data.value,
      }),
    )
  }

  useEffect(() => {
    if (sectorInfo.icbId) {
      const payload = {
        ICBId: sectorInfo.icbId,
        ExchangeCode: exchangeCode,
        TimeFrequency: timeFilter?.value,
      }
      if (typeSector === COM_GROUP.BANK) {
        dispatch(getCreditAndDepositChartData(payload))
      } else if (typeSector === COM_GROUP.SECURITIES) {
        dispatch(getMarginLendingChartData(payload))
      } else {
        dispatch(getSectorEarningGrowthChartData(payload))
      }
    }
  }, [sectorInfo.icbId, exchangeCode, locale, timeFilter?.value])

  const convertData = () => {
    if (timeFilter?.value === VIEW_BY.QUARTERLY && dataChart.length) {
      return dataChart.map((item) => ({
        ...item,
        year: 'Q' + item.quarter + '-' + item.year,
      }))
    } else {
      return dataChart
    }
  }

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div>
                <DispatchActionTab
                  onChangeTab={onChangeTab}
                  data={listTimeFilter.map((item) => ({
                    title: I18n.t(item.label),
                    value: item.value,
                  }))}
                  activeTab={timeFilter.value}
                />
              </div>
              {size.height && (
                <div
                  id="panelDependsOnSectorId"
                  style={{ height: height - size.height }}
                >
                  {isLoading ? (
                    <div className="h-100 position-relative">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {dataChart.length ? (
                        <SizeTracker>
                          {(size2) => {
                            return (
                              <>
                                <div className="d-flex justify-content-center mt-8">
                                  <Span
                                    style={{
                                      fontSize: 12,
                                      textTransform: 'uppercase',
                                      fontWeight: 600,
                                    }}
                                    className="canvas-chart-title"
                                  >
                                    {typeSector === COM_GROUP.BANK ? (
                                      <Translate value="sector.sectorConstituents.overview.LB_CHART_BANK" />
                                    ) : typeSector === COM_GROUP.SECURITIES ? (
                                      <Translate value="sector.sectorConstituents.overview.MARGIN_LENDING" />
                                    ) : (
                                      <Translate value="sector.sectorConstituents.overview.SECTOR_EARNING_GROWTH" />
                                    )}
                                  </Span>
                                </div>
                                <div style={{ position: 'relative' }}>
                                  <ChartComponent
                                    data={convertData()}
                                    width={width}
                                    height={height - size.height - size2.height}
                                    barKey={getKeyChart().barKey}
                                    lineKeys={getKeyChart().lineKey}
                                    keyXAxis={'year'}
                                    typeSector={typeSector}
                                  />
                                </div>
                                <Footer
                                  key={width}
                                  list={
                                    typeSector === COM_GROUP.BANK
                                      ? [
                                          {
                                            text: 'sector.sectorConstituents.overview.LDR',
                                            type: TYPE.SQUARE,
                                            before: {
                                              bgColor: '#facc5c',
                                            },
                                          },
                                          {
                                            text: 'sector.sectorConstituents.overview.LOAN_GROWTH',
                                            type: TYPE.CIRCLE_MARKER_LINE,
                                            before: {
                                              bgColor: '#45b29d',
                                            },
                                            after: {
                                              bgColor: '#45b29d',
                                            },
                                          },
                                          {
                                            text: 'sector.sectorConstituents.overview.DEPOSIT_GROWTH',
                                            type: TYPE.CIRCLE_MARKER_LINE,
                                            before: {
                                              bgColor: '#df5a49',
                                            },
                                            after: {
                                              bgColor: '#df5a49',
                                            },
                                          },
                                        ]
                                      : typeSector === COM_GROUP.SECURITIES
                                      ? [
                                          {
                                            text: 'sector.sectorConstituents.overview.MARGIN_LENDING',
                                            type: TYPE.SQUARE,
                                            before: {
                                              bgColor: '#facc5c',
                                            },
                                          },
                                          {
                                            text: 'sector.sectorConstituents.overview.YOY_GROWTH',
                                            type: TYPE.CIRCLE_MARKER_LINE,
                                            before: {
                                              bgColor: '#45b29d',
                                            },
                                            after: {
                                              bgColor: '#45b29d',
                                            },
                                          },
                                        ]
                                      : [
                                          {
                                            text: 'sector.sectorConstituents.overview.REVENUE',
                                            type: TYPE.SQUARE,
                                            before: {
                                              bgColor: '#facc5c',
                                            },
                                          },
                                          {
                                            text: 'sector.sectorConstituents.overview.REVENUE_GROWTH',
                                            type: TYPE.CIRCLE_MARKER_LINE,
                                            before: {
                                              bgColor: '#45b29d',
                                            },
                                            after: {
                                              bgColor: '#45b29d',
                                            },
                                          },
                                        ]
                                  }
                                />
                              </>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default DependsOnSector
