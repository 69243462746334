import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { screenTypes } from '../../../constants'
import { ReactComponent as IconDownloadBlack } from '../../../icon/icon-download-black.svg'
import { selectDashboard } from '../../../store/slice'
import style from './style.module.css'

const IconDownload = () => {
  const { screenType } = useSelector(selectDashboard)

  if (
    screenType === screenTypes.EDIT_CHART ||
    screenType === screenTypes.SETTING_CREATE_CHART
  ) {
    return (
      <IconDownloadBlack
        className={style.disable}
        title={I18n.t(
          'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_CHART',
        )}
      />
    )
  }

  return <IconDownloadBlack title={I18n.t('financialChart.DOWNLOAD')} />
}

IconDownload.propTypes = {}

export default IconDownload
