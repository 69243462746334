import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { quarterTypeOptions, timeTypes } from './constants'

export const formatDataPieChart = (data, chartFormat) => {
  const newObj = {}
  const totalData = Object.keys(data)
    .filter((key) => chartFormat[key])
    .map((key) => data[key] || 0)
    .reduce((prev, cur) => prev + cur)

  if (totalData) {
    Object.keys(data).forEach((key) => {
      if (chartFormat[key] && data[key]) {
        newObj[key] = data[key] / totalData
      } else {
        newObj[key] = data[key]
      }
    })

    return newObj
  } else {
    return data
  }
}

export const getXAxisTickValue = (item, timeType) => {
  switch (timeType) {
    case timeTypes.QUARTERLY:
      return `Q${item.realQuarter || 1}-${item.realYear}`
    default:
      return item.realYear.toString()
  }
}

export const formatDataOtherChart = (data, chartFormat, timeType) => {
  return [...data]
    .sort((a, b) => {
      if (a.realQuarter && b.realQuarter) {
        return (
          moment().set('year', a.realYear).quarter(a.realQuarter).unix() -
          moment().set('year', b.realYear).quarter(b.realQuarter).unix()
        )
      } else {
        return (
          moment().set('year', a.realYear).unix() -
          moment().set('year', b.realYear).unix()
        )
      }
    })
    .map((item) => {
      const newObj = { ...item, label: getXAxisTickValue(item, timeType) }
      Object.keys(chartFormat).forEach((key) => {
        if (newObj[key]) {
          newObj[key] /= chartFormat[key]
        }
      })
      return newObj
    })
}

export const getQuarterYearParams = (number = 3, isFilterQuarter = false) => {
  const paramsArr = []
  const currentYear = new Date().getFullYear()
  const currentQuarter = moment().quarter()
  ;[...new Array(number)].forEach((_, index) => {
    if (isFilterQuarter) {
      ;[...quarterTypeOptions]
        .sort((a, b) => b.value - a.value)
        .forEach((item) => {
          if (
            !(index === 0 && item.value !== 5 && item.value > currentQuarter)
          ) {
            paramsArr.push({ year: currentYear - index, quarter: item.value })
          }
        })
    } else {
      paramsArr.push({ year: currentYear - index })
    }
  })
  return paramsArr.filter((_, index) => index < number)
}

export const getTranslateSourceNote = (data, isBank) => {
  const text = isBank ? 'SOURCE_BANK_NOTE' : 'SOURCE_COMPANY_NOTE'
  const listTotal = data
    .filter((item) => item && typeof item.totalOrganization === 'number')
    .map((item) => item.totalOrganization || 0)
  const totalOrganization = listTotal.length ? Math.min(...listTotal) : 0

  return I18n.t(`sector.financialAnalysis.common.${text}`).replace(
    '[totalOrganization]',
    totalOrganization,
  )
}
