import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { EXCHANGE_STATUS } from '../constants'
import {
  getIssuerOrganizationIds,
  getOverviewStatistics,
  getUnderlyingIds,
} from './thunk'

const slice = createSlice({
  name: 'market/coveredWarrant/statistics/overviewStatistics/tableOverviewStatistics',
  initialState: {
    ids: [],
    initialIds: [],
    dataById: {},
    isLoading: true,
    isLoadingList: true,
    data: [],
    underlyings: [],
    issuerOrganizations: [],
    filter: {
      page: 1,
      totalPage: 1,
      dateFrom: '',
      dateTo: '',
      status: EXCHANGE_STATUS.TRADING,
      currentUnderlyingId: [],
      currentIssuerId: '',
      minDate: '',
      maxDate: '',
      sortField: '',
      sortOrder: 1,
    },
    reCalcWidths: false,
    isPushDataRealTime: false,
  },
  reducers: {
    changeDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload
      state.filter.page = 1
    },
    changeDateTo: (state, action) => {
      state.filter.dateTo = action.payload
      state.filter.page = 1
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },
    changePageTotal: (state, action) => {
      state.filter.totalPage = action.payload
      state.filter.page = 1
    },
    changeStatus: (state, action) => {
      state.filter.status = action.payload
      state.filter.page = 1
    },
    changeCurrentUnderlyingId: (state, action) => {
      state.ids = []
      state.filter.currentUnderlyingId = action.payload
      state.filter.page = 1
    },
    changeCurrentIssuerId: (state, action) => {
      state.ids = []
      state.filter.currentIssuerId = action.payload
      state.filter.page = 1
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
    setLoadingTable: (state, action) => {
      state.isLoading = action.payload
    },
    changeSortField: (state, action) => {
      state.filter.sortField = action.payload
    },
    changeSortOrder: (state, action) => {
      state.filter.sortOrder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOverviewStatistics.pending, (state, action) => {
      state.isPushDataRealTime = false
      state.isLoading = !action.meta.arg.isGetRealtimeData
    })
    builder.addCase(getOverviewStatistics.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false

      const payloadData =
        action.payload.data?.map((item, index) => ({
          ...item,
          id: index,
          duration: item.duration <= 0 ? 0 : item.duration,
        })) || []

      const data = payloadData

      state.ids = state.initialIds = data?.map((v) => v.id)
      state.data = data
      state.dataById = keyBy(data, 'id')

      state.filter.minDate = payloadData.length ? payloadData[0].minDate : ''
      state.filter.maxDate = payloadData.length ? payloadData[0].maxDate : ''

      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = !state.reCalcWidths
      state.isPushDataRealTime = true
    })
    builder.addCase(getOverviewStatistics.rejected, (state, action) => {
      state.isPushDataRealTime = true
      state.isLoading = action.payload.loading
    })

    builder.addCase(getIssuerOrganizationIds.pending, (state) => {
      state.isLoadingList = true
    })
    builder.addCase(getIssuerOrganizationIds.fulfilled, (state, action) => {
      const defaultAllObj = {
        name: 'market.coveredWarrant.cwStatistics.ALL',
        value: 0,
      }
      state.issuerOrganizations = [
        { ...defaultAllObj },
        ...(action.payload?.map((item) => ({
          ...item,
          value: item.issuerOrganizationId,
          name: item.ticker ?? item.organizationShortName,
        })) || []),
      ]
      state.filter.currentIssuerId = state.issuerOrganizations.map(
        (item) => item.value,
      )
    })
    builder.addCase(getIssuerOrganizationIds.rejected, (state) => {
      state.isLoadingList = false
    })

    builder.addCase(getUnderlyingIds.pending, (state) => {
      state.isLoadingList = true
    })
    builder.addCase(getUnderlyingIds.fulfilled, (state, action) => {
      const defaultAllObj = {
        name: 'market.coveredWarrant.cwStatistics.ALL',
        value: 0,
      }
      state.underlyings = [
        { ...defaultAllObj },
        ...(action.payload?.map((item) => ({
          ...item,
          value: item.underlyingId,
          name: item.ticker ?? item.organizationShortName,
        })) || []),
      ]
      state.filter.currentUnderlyingId = state.underlyings.map(
        (item) => item.value,
      )
    })
    builder.addCase(getUnderlyingIds.rejected, (state) => {
      state.isLoadingList = false
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectIds = (state) => state[slice.name].ids
export const selectDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectDataTable = (state) => state[slice.name].data
export const selectFilter = (state) => state[slice.name].filter
export const selectUnderlyings = (state) => state[slice.name].underlyings
export const selectIssuerOrganizations = (state) =>
  state[slice.name].issuerOrganizations
export const selectRecalcWidths = (state) => state[slice.name].reCalcWidths
export const selectIsPushDataRealTime = (state) =>
  state[slice.name].isPushDataRealTime

export const {
  changeDateFrom,
  changeDateTo,
  changePage,
  changePageTotal,
  changeStatus,
  subscribeCoveredWarrant,
  changeCurrentUnderlyingId,
  changeCurrentIssuerId,
  sort,
  setLoadingTable,
  changeSortField,
  changeSortOrder,
} = slice.actions

register(slice.name, slice.reducer)
