export const error = {
  404: {
    title: '404 - Page not found',
    desc: `The page you are looking for might have been removed, had its name
    changed or is temporarily or unavailable.`,
  },
  500: {
    title: 'We are working hard to resolve it.',
    desc: 'Additional information: Error during route processing.',
  },
  454: {
    desc: 'Your account has been active in another device. Please log out and log in your registered device.',
  },
  403: {
    desc: 'Upgrade your account to perform this action.',
  },
  GO_TO_HOMEPAGE: 'Go to homepage',
  GO_TO_PREVIOUS_PAGE: 'Go to previous page',
  SIGN_OUT: 'Sign out',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_FEATURE:
    'You do not have access to this function.',
  PLEASE_CONTACT_THE_ADMIN: 'Please contact our customer support center.',
  ADMIN_CONTACT: 'T: (84-24) - 3562 6962 - Ext: 103',
  ACCOUNT_HAS_BEEN_LOGIN_FROM_ANOTHER_DEVICE:
    'Your account has been logged out because it is currently logged in on another device.',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_FIRST:
    'Your package functions has expired. Please',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_SECOND:
    'Customer Support Department for an extension.',
  YOUR_PACKAGE_EXPIRED_DESCRIPTION_CONTACT: 'contact',
  YOUR_PACKAGE_EXPIRED_NOTE: 'Note:',
  YOUR_PACKAGE_EXPIRED_SYSTEM_COUNTDOWN:
    'Fiinpro-X system will automatically log out after',
  YOUR_PACKAGE_EXPIRED_SYSTEM_COUNTDOWN_SECOND: 'seconds',
}
