import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { INDICATOR_GROUPS } from '../../constants'
import { selectFilterParams } from '../../store/slice'
import {
  BOND_TYPE_RADIOS,
  CREATE_BOND_TYPE_RADIOS,
  TABLE_FIELD_CODES,
} from '../constants'
import style from '../index.module.css'

export const FilterBondType = ({ handleChangeParams }) => {
  const params = useSelector(selectFilterParams)

  if (params.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER) {
    return null
  }

  return (
    <div className="w-100">
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          <Translate value="bond.bondScreening.common.BOND_TYPE" />
        </Span>
      </label>
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 8 }}
      >
        {(params?.indicatorGroup === INDICATOR_GROUPS.BOND_CREATING
          ? CREATE_BOND_TYPE_RADIOS
          : BOND_TYPE_RADIOS
        ).map(({ value, name }) => {
          const initialParams = params.conditions.find(
            (item) =>
              item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
              item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE,
          )

          return (
            <div
              key={value}
              className={`d-flex align-center cursor-pointer ${
                isEqual(initialParams?.conditionValues, value) &&
                style.radioActive
              }`}
              onClick={() =>
                handleChangeParams(value, initialParams.indicatorId)
              }
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={isEqual(initialParams?.conditionValues, value)}
                readOnly={true}
              />
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 340,
                }}
              >
                <Translate value={name} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
