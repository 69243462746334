import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FundCenterService from '../../../../../../core/services/fund/FundCenterService'
import BasicChart from '../../../../../common/chart/basicChart'
import { DEFAULT_PRE_VALUE_KEY } from '../../../../../common/chart/basicChart/constants'
import { CHART_TYPES } from '../../../../../common/chart/constants'
import UseI18n from '../../../../../common/hooks/useI18n'
import useGetFund from '../../../../../common/masterData/useGetFund'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { keyBy } from '../../../../../utils/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { PERFORMANCE_NAV_CCQ_TIME_RANGES, VALUE_TYPES } from '../../constants'
import { Filter } from '../filter'
import { formatDataApi } from './helpers'
import { changeFilter, selectFilters } from './store/slice'

export const NavCertificateGrowth = ({ id, width, height, isFullScreen }) => {
  const dispatch = useDispatch()
  const { funds } = useGetFund()

  const yAxisText = UseI18n('common.UNIT.VND')

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)

  // Get data
  const getFundOptions = useMemo(() => {
    return [
      // ...FIX_FUND_OPTIONS,
      ...funds.map((item) => ({
        name: item.ticker,
        organizationShortName: item.organizationShortName,
        value: item.organizationId,
      })),
    ]
  }, [JSON.stringify(funds), locale])

  const fundOptionByValue = keyBy(getFundOptions, 'value')

  const getSchema = useMemo(() => {
    return filters.fund.map((value, index) => ({
      text: fundOptionByValue[value]?.name,
      dataKey: `${DEFAULT_PRE_VALUE_KEY}${index + 1}_${filters.valueType}`,
      unit: filters.valueType === VALUE_TYPES.ABSOLUTE ? 'common.UNIT.VND' : '',
      isI18n: true,
      chart: {
        type: CHART_TYPES.LINE,
      },
      tooltip: {
        formatValue: (val) =>
          filters.valueType === VALUE_TYPES.ABSOLUTE
            ? formatVal(val)
            : valToPercent(val),
      },
      fundId: value,
    }))
  }, [filters])

  const getParams = useMemo(() => {
    return {
      OrganizationIds: filters.fund.join(','),
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From: filters.timeRange === calendarTabKey ? filters.startDate : '',
      To: filters.timeRange === calendarTabKey ? filters.endDate : '',
    }
  }, [
    JSON.stringify(filters.fund),
    filters.timeRange,
    filters.startDate,
    filters.endDate,
  ])

  // Actions
  const handleChange = (value) => {
    dispatch(changeFilter(value))
  }

  const handleDelete = (item) => {
    const schemaByDataKey = keyBy(getSchema, 'dataKey')
    handleChange({
      fund: filters.fund.filter((id) => id !== schemaByDataKey[item.id].fundId),
    })
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter
              {...filters}
              isFund
              isValueType
              isTimeRange
              fundOptions={getFundOptions}
              timeRangeOptions={PERFORMANCE_NAV_CCQ_TIME_RANGES}
              className="mb-24"
              onChange={handleChange}
            />
            {size.height && (
              <BasicChart
                id={id}
                width={width}
                height={height - size.height}
                params={getParams}
                serviceApi={(params) =>
                  FundCenterService.getListNAVCertificate(params)
                }
                formatDataApi={(data) =>
                  formatDataApi({ data, ids: filters.fund, locale })
                }
                schema={getSchema}
                chartProps={{
                  chartType: 'ComposedChart',
                  yAxis: [
                    {
                      dataKeys: getSchema.map(
                        (item, index) =>
                          item.dataKey ||
                          `${DEFAULT_PRE_VALUE_KEY}${index + 1}_${
                            filters.valueType
                          }`,
                      ),
                      labelText:
                        filters.valueType === VALUE_TYPES.ABSOLUTE
                          ? yAxisText
                          : '',
                      isLineChart: true,
                      tickFormatter:
                        filters.valueType === VALUE_TYPES.RELATIVE
                          ? (value) => valToPercent(value, false, false, 0)
                          : undefined,
                    },
                  ],
                }}
                footerProps={{
                  isFooterScroll: true,
                  isFullScreen: isFullScreen,
                  onDelete: handleDelete,
                }}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
