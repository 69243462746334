export const COMPANY_GROWTH_LINE_KEYS = [
  'productionGalGrowth',
  'productionPaintedGrowth',
  'productionOtherGrowth',
  'totalProductionGrowth',

  'consumptionGalGrowth',
  'consumptionPaintedGrowth',
  'consumptionOtherGrowth',
  'totalConsumptionGrowth',

  'inventoryGalGrowth',
  'inventoryPaintedGrowth',
  'inventoryOtherGrowth',
  'totalInventoryGrowth',
]

export const TITLE_LINE_KEYS = [
  'sector.sectorSpecific.steelCoat.STEEL_GAL',
  'sector.sectorSpecific.steelCoat.STEEL_PAINTED',
  'sector.sectorSpecific.steelCoat.OTHER',
  'sector.sectorSpecific.steelCoat.TOTAL',
]

export const LINE_COLORS = {
  WHITE_GREEN: '#ecffdf',
  PURPLE: '#9c54e4',
  GREEN: '#c7ff58',
  ORANGE: '#f57f07',
}
