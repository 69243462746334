import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { selectPriceData } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { matchingOrderTableSchema } from './constants'
import { getTableSchema } from './helper'
import {
  changeStateWhenScrollToBottom,
  changeStateWhenScrollToTop,
  selectDataById,
  selectDataMatchingOrder,
  selectIds,
  selectIsPushDataRealtime,
  selectLoading,
  selectReferencePrice,
  selectScrollId,
} from './store/slice'

export const RenderTable = ({ rowTimes, setRowTimes }) => {
  const dispatch = useDispatch()

  // Use selector
  const ids = useSelector(selectIds)
  const referencePrice = useSelector(selectReferencePrice)
  const dataById = useSelector(selectDataById)
  const isLoading = useSelector(selectLoading)
  const isPushDataRealtime = useSelector(selectIsPushDataRealtime)
  const priceData = useSelector(selectPriceData)
  const scrollId = useSelector(selectScrollId)

  // Get data
  const getRowData = (rowId) => {
    return dataById[rowId]
  }

  // Actions
  const scrollToBottomCallback = () => {
    if (!isLoading) {
      if (isPushDataRealtime) {
        dispatch(changeStateWhenScrollToBottom())
      }
      if (scrollId) {
        setRowTimes(rowTimes + 1)
      }
    }
  }

  const scrollToTopCallback = () => {
    if (!isLoading && !isPushDataRealtime) {
      dispatch(changeStateWhenScrollToTop())
      setRowTimes(1)
    }
  }

  return (
    <Table
      ids={ids}
      getDataFromRedux={selectDataMatchingOrder}
      schema={getTableSchema(referencePrice, getRowData, priceData)}
      stickies={{
        [matchingOrderTableSchema.TIME]: true,
        [matchingOrderTableSchema.TYPE]: true,
        [matchingOrderTableSchema.PRICE]: true,
      }}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      isPagination={false}
      scrollToBottomCallback={scrollToBottomCallback}
      scrollToTopCallback={scrollToTopCallback}
    />
  )
}
