import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valToPercent,
} from '../../../../utils/Value'
import {
  LASTEST_YEAR,
  NUMBER_OF_PERIOD,
  TYPE,
  TYPE_OF_RATIO_OTHER,
  VIEW_BY,
} from '../filter/constants'
import { getDataTitle } from '../helps'
import { TABLE_ROW_LEVEL } from '../table/constants'
import { getFinancialDataRatio, getIndicatorMapping } from './thunk'

const formatFieldName = (fieldName) => {
  const isRtd = fieldName.indexOf('RTD') !== -1
  return fieldName
    .split('')
    .map((char, index) => {
      if (index === 0 || (index === 1 && isRtd)) {
        return char.toLowerCase()
      } else {
        return char
      }
    })
    .join('')
}

const initialState = {
  rawData: [],
  financialById: {},
  ids: [],
  groupColumns: [],
  tableConstants: [],
  loading: false,
  mappingLoading: true,
  financialRatioFilter: {
    viewBy: VIEW_BY[0],
    numberOfPeriod: NUMBER_OF_PERIOD[5],
    type: TYPE[0],
    lastestYear: LASTEST_YEAR[0],
  },
  typeOfRatio: TYPE_OF_RATIO_OTHER[0],
  indicator: {
    value: null,
    subValue: null,
  },
}

export const slice = createSlice({
  name: 'corporate/financialData/financialRatio',
  initialState,
  reducers: {
    setFinancialById: (state, action) => {
      state.financialById = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeFinancialRatioFilter: (state, action) => {
      state.financialRatioFilter = {
        ...state.financialRatioFilter,
        [action.payload.field]: action.payload.option,
      }
    },
    changeTypeOfRatio: (state, action) => {
      state.typeOfRatio = action.payload
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      const rowData = state.financialById[rowId]

      if (rowData?.childrenId) {
        const childId = rowData.childrenId[0]
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = state.financialById[childId]
      } else if (rowData?.parentId) {
        const parentId = rowData.parentId
        state.indicator.value = state.financialById[parentId]
        state.indicator.subValue = state.financialById[rowId]
      } else {
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = state.financialById[rowId]
      }
    },
    resetIndicator: (state) => {
      state.indicator = {
        value: null,
        subValue: null,
      }
    },
    resetIndicatorMapping: (state) => {
      state.loading = false
      state.mappingLoading = true
      state.tableConstants = []
      state.ids = []
      state.financialById = {}
      state.indicator = {
        value: null,
        subValue: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialDataRatio.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFinancialDataRatio.fulfilled, (state, action) => {
      const groupColumns = []
      const financialArr = Object.values(state.financialById)
      const timeFilter = action.meta.arg.TimeFilter
      const quarterly = VIEW_BY[1].value
      const sortData = [...(action.payload || [])].sort((a, b) => {
        if (a.quarter && b.quarter && a.quarter < 5 && b.quarter < 5) {
          return (
            moment().set('year', b.year).set('quarter', b.quarter).unix() -
            moment().set('year', a.year).set('quarter', a.quarter).unix()
          )
        } else {
          return b.year - a.year
        }
      })
      const ttmData = sortData.filter((item) => item.isTTM)
      const otherData = sortData
        .filter((item) => !item.isTTM)
        .filter((_, index) => index < action.meta.arg.NumberOfPeriod)
        .reverse()
      const rawData = [...otherData, ...ttmData].forEach((item) => {
        groupColumns.push({
          key: item.isTTM
            ? 'columnTTM'
            : timeFilter === quarterly
            ? `columnQ${item.quarter}/${item.year}`
            : `column${item.year}`,
          title: getDataTitle(timeFilter, item),
        })

        financialArr.forEach((element) => {
          if (element.level !== TABLE_ROW_LEVEL.LEVEL_1) {
            element[
              item.isTTM
                ? 'columnTTM'
                : timeFilter === quarterly
                ? `columnQ${item.quarter}/${item.year}`
                : `column${item.year}`
            ] = element.isFormatValToPercent
              ? valToPercent(item[element.key], false)
              : formatVal(item[element.key] / element.isFormatValue.formatValue)
          }
        })
      })
      state.rawData = rawData
      state.groupColumns = [
        { key: 'name', title: '' },
        ...groupColumns.filter((item) => item.key !== 'columnTTM'),
        ...groupColumns.filter((item) => item.key === 'columnTTM'),
      ]
      state.loading = false
    })
    builder.addCase(getFinancialDataRatio.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getIndicatorMapping.pending, (state) => {
      state.mappingLoading = true
    })
    builder.addCase(getIndicatorMapping.fulfilled, (state, action) => {
      const data = action.payload || []
      const tableConstants = data.map((indicator) => {
        const level = indicator.parentField
          ? TABLE_ROW_LEVEL.LEVEL_2
          : TABLE_ROW_LEVEL.LEVEL_1
        if (level === TABLE_ROW_LEVEL.LEVEL_1) {
          const childrenId = data
            .filter((v) => v.parentField === indicator.fieldName)
            .map((v) => v.indicatorMappingId)
          return {
            index: indicator.indicatorMappingId,
            childrenId: childrenId,
            name: indicator.indicator,
            level: level,
            key: indicator.fieldName,
            style: indicator.style || '',
          }
        } else {
          const key = formatFieldName(indicator.fieldName)
          const isFormatValToPercent = indicator.formating.includes('%')
          return {
            index: indicator.indicatorMappingId,
            parentId: indicator.parentField,
            name: indicator.indicator.replaceAll(/\./gi, ''),
            key: key,
            isFormatValue: {
              formatValue: Math.round(1 / indicator.multiplier),
              formatCommonValue: indicator.multiplierCommonSize * 0.01,
            },
            unit: indicator.unit,
            level: level,
            isFormatValToPercent: isFormatValToPercent,
            style: indicator.style || '',
          }
        }
      })
      state.tableConstants = tableConstants
      state.ids = tableConstants.map((v) => v.index)
      state.financialById = keyBy(tableConstants, 'index')
      state.indicator = { ...state.indicator, subValue: tableConstants[1] }
      state.mappingLoading = false
    })
    builder.addCase(getIndicatorMapping.rejected, (state, action) => {
      state.mappingLoading = action.payload.loading
    })
  },
})

export const selectRawData = (state) => state[slice.name].rawData
export const selectFinancialIds = (state) => state[slice.name].ids
export const selectTableConstants = (state) => state[slice.name].tableConstants
export const selectFinancialValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].financialById[id], attr)
}
export const selectLoading = (state) => state[slice.name].loading
export const selectMappingLoading = (state) => state[slice.name].mappingLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectFinancialRatioFilter = (state) =>
  state[slice.name].financialRatioFilter
export const selectItemData = (id) => (state) =>
  state[slice.name].financialById[id]
export const selectIndicator = (state) => state[slice.name].indicator
export const selectFinancialById = (state) => state[slice.name].financialById
export const selectTypeOfRatio = (state) => state[slice.name].typeOfRatio

export const {
  changeFinancialRatioFilter,
  setFinancialById,
  changeIds,
  changeIndicator,
  resetIndicator,
  resetIndicatorMapping,
  changeTypeOfRatio,
} = slice.actions

register(slice.name, slice.reducer)
