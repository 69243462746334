import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown from '../../../../../common/dropdown'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import { EMPTY_VALUE, Z_INDEX } from '../../../../../constants/Common'
import { TABLE_SECTOR_ID } from '../../constant'
import {
  changeIndicator,
  selectIndicator,
  selectIndicatorDefault,
} from '../../totalRegisteredFDI/store/slice'
import { changeFilter, selectFilter, selectListVSICName } from '../store/slice'
import style from './style.module.css'

const FilterVSIC = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()
  const listVSICName = useSelector(selectListVSICName)
  const filter = useSelector(selectFilter)
  const indicator = useSelector(selectIndicator)
  const indicatorDefault = useSelector(selectIndicatorDefault)

  const [isShow, setIsShow] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const toggleDropSearch = () => {
    setIsShow(!isShow)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    setKeySearch('')
  }

  const onChangeFilterStatus = (value) => {
    const scrollTable = document.querySelector(
      `#${TABLE_SECTOR_ID} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${value.vsicId}"]`)
    if (scrollTable && headerTable && activeRow)
      scrollTable.scrollTo({
        top: activeRow.offsetTop - headerTable.offsetHeight,
        behavior: 'smooth',
      })
    dispatch(changeFilter(value))
    dispatch(changeIndicator(value.vsicId))
    hideDropSearch()
  }

  useEffect(() => {
    if (indicatorDefault && !indicator?.key) {
      dispatch(
        changeFilter({
          vsicId: indicatorDefault?.key,
          vsicName: indicatorDefault?.name,
        }),
      )
    }
    if (indicator?.key) {
      dispatch(
        changeFilter({
          vsicId: indicator?.key,
          vsicName: indicator?.name,
        }),
      )
    }
  }, [indicator?.key, indicatorDefault?.key])

  return (
    <div className="input-dropdown" ref={parentDropdownRef}>
      <span
        className={`span-input w-200 ${style.inputDropdown} `}
        style={{ padding: '2px 20px 2px 8px' }}
        ref={dropdownRef}
        onClick={toggleDropSearch}
      >
        <TextEllipsis text={filter?.vsicName || EMPTY_VALUE} />
        <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside
          handleClickOutside={hideDropSearch}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width: dropdownRef?.current?.clientWidth }}
            >
              <div
                className="form-search-inbox"
                style={{ width: 'auto', margin: '4px 8px 8px 8px' }}
              >
                <Input
                  style={{
                    fontSize: 11,
                  }}
                  type="text"
                  className={`search-inbox ${style.inputSearch}`}
                  placeholder={I18n.t('common.SEARCH')}
                  onChange={(e) => setKeySearch(e.target.value?.toLowerCase())}
                />
                <button type="button">
                  <i className="icon-search-2" />
                </button>
              </div>
              <ScrollComponent autoHeight={true} autoHeightMax={200}>
                <ul className="list-check">
                  {listVSICName.length &&
                    listVSICName
                      .filter((item) =>
                        item.vsicName?.toLowerCase().includes(keySearch),
                      )
                      .map((vsic) => {
                        return (
                          <li
                            key={vsic.vsicId}
                            onClick={() => onChangeFilterStatus(vsic)}
                          >
                            <a>
                              <Span style={{ fontSize: 12 }}>
                                <TextEllipsis
                                  text={vsic.vsicName}
                                  zIndexTooltip={Z_INDEX.DROPDOWN}
                                />
                              </Span>
                            </a>
                          </li>
                        )
                      })}
                </ul>
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default FilterVSIC
