import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Label } from '../../../common/html/Label'
import { Span } from '../../../common/html/Span'
import { SearchCompany } from '../../linkChart/SearchCompany'
import {
  changePositionChart,
  selectAddedLinkChart,
  selectDashboard,
  selectDashboardChartData,
  selectLinkedSecurity,
} from '../../store/slice'
import { updateCharts, updateDashboard } from '../../store/thunk'
import style from './index.module.css'

const InputSearchLinkChart = () => {
  const dispatch = useDispatch()
  const dashboardChartData = useSelector(selectDashboardChartData)
  const { data = {} } = useSelector(selectDashboard)
  const linkedChartIds = useSelector(selectAddedLinkChart)
  const dashboardChartDataSort = [...dashboardChartData].sort(
    (a, b) => a.order - b.order,
  )

  const linkedSecurity = useSelector(selectLinkedSecurity)

  const changeChartSecurity = (linkedSecurity) => {
    let clonedChartData = []
    dashboardChartDataSort.forEach((item) => {
      const obj = {}
      Object.assign(obj, item)
      if (linkedChartIds.includes(item.id)) {
        const companyId = JSON.parse(obj.detail).securities.companies
        obj.linked = true
        obj.detail = obj.detail.replaceAll(companyId[0], linkedSecurity)
        dispatch(updateCharts(obj))
      }
      clonedChartData.push(obj)
    })
    dispatch(changePositionChart(clonedChartData))
    dispatch(
      updateDashboard({
        ...data,
        note: JSON.stringify({ ...data.note, linkedSecurity: linkedSecurity }),
      }),
    )
  }

  useEffect(() => {
    if (linkedSecurity) {
      changeChartSecurity(linkedSecurity)
    }
  }, [linkedSecurity])

  return (
    <>
      <div className="d-flex align-center h-100">
        <Label className={style.linkedSecurities}>
          <Span className={style.text}>
            <Translate value="financialChart.LINKED_SECURITIES" />:
          </Span>
        </Label>
        <SearchCompany />
      </div>
    </>
  )
}

export default InputSearchLinkChart
