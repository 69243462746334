import { createAsyncThunk } from '@reduxjs/toolkit'
import ProductionService from '../../../../../../../core/services/economy/productionAndConsumption/ProductionService'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import { TAB_TYPE } from '../../../table/constants'

export const getDataChartTopChange = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iip/getDataChartTopChangeIIP',
  async (params, { rejectWithValue }) => {
    if (params.DataType === PRICE_DATA_TIME_SELECT.YEARLY) {
      delete params.Month
    }
    if (params.activeTab === TAB_TYPE.IIP) {
      delete params.activeTab
      const response = await ProductionService.getDataChartTopChangeIIP(params)
      if (response.success) {
        return response.data
      }
    }
    if (params.activeTab === TAB_TYPE.IIC) {
      delete params.activeTab
      const response = await ProductionService.getDataChartTopChangeIIC(params)
      if (response.success) {
        return response.data
      }
    }
    if (params.activeTab === TAB_TYPE.III) {
      delete params.activeTab
      params.DataType = PRICE_DATA_TIME_SELECT.MONTHLY
      const response = await ProductionService.getDataChartTopChangeIII(params)
      if (response.success) {
        return response.data
      }
    }

    return rejectWithValue({ isLoading: false })
  },
)
