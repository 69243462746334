import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../common/table'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import { initKeyTable, keyTableResult } from '../constant'
import style from '../index.module.css'
import {
  changeTickerIds,
  key,
  selectLastDataApply,
  selectLoading,
  selectLocale,
  selectResultKeyTable,
  selectResultKeyUnique,
  selectResultTickerCell,
  selectResultTickerIds,
  sortResult,
} from '../store/slice'
import { getResultThunk } from '../store/thunk'
import TdTable from './TdTable'
import ThTable from './ThTable'

const DEFAULT_PAGE = 1

const TableResult = () => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const lastDataApply = useSelector(selectLastDataApply)
  const tickerIds = useSelector(selectResultTickerIds)
  const keyTable = useSelector(selectResultKeyTable)
  const keyUnique = useSelector(selectResultKeyUnique)
  const loading = useSelector(selectLoading(key.RESULT))

  const [displayIds, setDisplayIds] = useState([])
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)

  useEffect(() => {
    if (lastDataApply?.conditions?.length > 0 && tickerIds?.length) {
      dispatch(getResultThunk(lastDataApply))
    }
  }, [locale])

  useEffect(() => {
    setDisplayIds(
      tickerIds.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    )
  }, [tickerIds, currentPage])

  useEffect(() => {
    setCurrentPage(DEFAULT_PAGE)
  }, [keyUnique])

  const isInitKey = Object.keys(keyTable).length === 0

  const pageSize = 300
  const totalPage = Math.max(Math.ceil(tickerIds.length / pageSize), 1)

  return (
    <div className={`table-head-large mb-40 h-100 ${style.tableResult}`}>
      <Table
        key={keyUnique}
        sort={sortResult}
        ids={displayIds}
        columnDraggable={false}
        getDataFromRedux={selectResultTickerCell}
        isLoading={loading}
        changeIds={changeTickerIds}
        rowDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        resizable={true}
        hasPagination={true}
        currentPage={currentPage}
        totalPage={totalPage}
        handleChangePage={setCurrentPage}
        stickies={{
          no: true,
          'items.0.value': true,
          'items.1.value': true,
        }}
        noDataText=""
        schema={Object.keys(isInitKey ? initKeyTable : keyTable).map(
          (key, index) => {
            const title = `tool.smartScreening.${key}`
            const colId = isInitKey ? initKeyTable[key] : keyTable[key]
            const isValueCondition = ![
              keyTableResult.NO,
              keyTableResult.TICKER,
              keyTableResult.COMPANY_NAME,
              keyTableResult.EXCHANGE,
              keyTableResult.SECTOR_L2,
            ].includes(key)
            const result = {
              colId,
              title,
              disableSort: key === keyTableResult.NO,
              thStyle: {
                textAlign: !isValueCondition ? 'left' : 'right',
                textTransform: !isValueCondition ? 'uppercase' : 'unset',
                borderLeft: index !== 0 ? 'solid 1px #1b2029' : 'unset',
              },
              tdStyle: {
                textAlign: !isValueCondition ? 'left' : 'right',
              },
            }
            return {
              ...result,
              isI18n: false,
              renderTh: isInitKey ? undefined : () => <ThTable keyTh={key} />,
              render:
                key === keyTableResult.NO
                  ? (_, __, index) => (currentPage - 1) * pageSize + index + 1
                  : isInitKey || !isValueCondition
                  ? (value) => (
                      <TextEllipsisStaticLine
                        isI18n={false}
                        val={value}
                        appendStyle={{
                          minWidth: [
                            keyTableResult.SECTOR_L2,
                            keyTableResult.COMPANY_NAME,
                          ].includes(key)
                            ? '200px'
                            : undefined,
                        }}
                      />
                    )
                  : (value) => (
                      <TdTable value={value} keyTd={key} keyTh={key} />
                    ),
            }
          },
        )}
      />
    </div>
  )
}

export default TableResult
