import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  getCoverWarrants,
  getForeignDetail,
  getForeignOverview,
  getForeignTicker,
} from './thunk'

const slice = createSlice({
  name: 'market/coveredWarrant/statistics/foreign/tableOverviewStatistics',
  initialState: {
    ids: [],
    initialIds: [],
    dataById: {},
    isLoading: true,
    data: [],
    cwList: [],
    isLoadingCWList: true,
    filter: {
      page: 1,
      totalPage: 1,
      dateFrom: '',
      dateTo: '',
      currentCW: {},
      currentCWId: 0,
      isTotal: true,
      tableType: '',
      minDate: '',
      maxDate: '',
      sortField: '',
      sortOrder: 1,
    },
    groupColumns: {
      schema: [],
    },
    reCalcWidths: false,
    isChangeFilter: true,
  },
  reducers: {
    changeDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload
      state.filter.page = 1
    },
    changeDateTo: (state, action) => {
      state.filter.dateTo = action.payload
      state.filter.page = 1
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },
    changePageTotal: (state, action) => {
      state.filter.totalPage = action.payload
      state.filter.page = 1
    },
    changeCurrentCWId: (state, action) => {
      state.filter.currentCW = action.payload
      state.filter.currentCWId = action.payload?.coveredWarrantId
      if (action.payload?.value !== 0) {
        state.filter.isTotal = false
      } else {
        state.filter.isTotal = true
      }
      state.filter.page = 1
    },
    changeTableType: (state, action) => {
      state.filter.tableType = action.payload
      state.filter.page = 1
    },
    changeGroupColumns: (state, action) => {
      state.groupColumns = action.payload
    },
    resetGroupColumns: (state, action) => {
      if (state.filter.tableType !== action.payload || !action) {
        state.groupColumns = {}
        state.groupColumns.schema = []
      }
    },
    setChangeFilter: (state) => {
      state.isChangeFilter = true
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
    changeSortField: (state, action) => {
      state.filter.sortField = action.payload
    },
    changeSortOrder: (state, action) => {
      state.filter.sortOrder = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getForeignOverview.pending, (state) => {
      state.isLoading = true
      state.reCalcWidths = false
    })
    builder.addCase(getForeignOverview.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false

      const payloadData =
        action.payload.data?.map((item, index) => ({
          ...item,
          id: index,
        })) || []

      const data = payloadData

      state.ids = state.initialIds = data?.map((v) => v.id)
      state.data = data
      state.dataById = keyBy(data, 'id')

      state.filter.minDate = payloadData.length ? payloadData[0].minDate : ''
      state.filter.maxDate = payloadData.length ? payloadData[0].maxDate : ''

      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = true
    })
    builder.addCase(getForeignOverview.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getForeignDetail.pending, (state) => {
      state.isLoading = true
      state.reCalcWidths = false
    })
    builder.addCase(getForeignDetail.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false

      const payloadData =
        action.payload.data?.map((item, index) => ({
          ...item,
          id: index,
        })) || []

      const data = payloadData

      state.ids = state.initialIds = data?.map((v) => v.id)
      state.data = data
      state.dataById = keyBy(data, 'id')

      state.filter.minDate = payloadData.length ? payloadData[0].minDate : ''
      state.filter.maxDate = payloadData.length ? payloadData[0].maxDate : ''

      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = true
    })
    builder.addCase(getForeignDetail.rejected, (state) => {
      state.isLoading = false
      state.loadingFullTable = false
    })

    builder.addCase(getForeignTicker.pending, (state) => {
      state.isLoading = true
      state.reCalcWidths = false
    })
    builder.addCase(getForeignTicker.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeFilter = false

      const payloadData =
        action.payload.data?.map((item, index) => ({
          ...item,
          id: index,
        })) || []

      const data = payloadData

      state.ids = state.initialIds = data?.map((v) => v.id)
      state.data = data
      state.dataById = keyBy(data, 'id')

      state.filter.minDate = payloadData.length ? payloadData[0].minDate : ''
      state.filter.maxDate = payloadData.length ? payloadData[0].maxDate : ''

      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = true
    })
    builder.addCase(getForeignTicker.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getCoverWarrants.pending, (state) => {
      state.isLoadingCWList = true
    })
    builder.addCase(getCoverWarrants.fulfilled, (state, action) => {
      state.isLoadingCWList = false
      const defaultAllObj = {
        name: 'market.coveredWarrant.cwStatistics.ALL_MARKET',
        value: 0,
      }
      state.cwList = [
        { ...defaultAllObj },
        ...(action.payload?.map((item) => ({
          ...item,
          name: item.cwCode,
          value: item.coveredWarrantId,
        })) || []),
      ]
    })
    builder.addCase(getCoverWarrants.rejected, (state) => {
      state.isLoadingCWList = false
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectIds = (state) => state[slice.name].ids
export const selectDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectDataTable = (state) => state[slice.name].data
export const selectFilter = (state) => state[slice.name].filter
export const selectCwList = (state) => state[slice.name].cwList
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectRecalcWidths = (state) => state[slice.name].reCalcWidths
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter

export const {
  changeDateFrom,
  changeDateTo,
  changePage,
  changePageTotal,
  changeCurrentCWId,
  changeTableType,
  changeGroupColumns,
  resetGroupColumns,
  setChangeFilter,
  sort,
  changeSortField,
  changeSortOrder,
} = slice.actions

register(slice.name, slice.reducer)
