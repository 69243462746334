import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button } from '../../../../common/html/Button'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { timeSaleChartType, timeSaleDateFilter } from './constants'
import { changeTimeRange } from './store/slice'

export const ChartFilter = ({
  chartType,
  setChartType,
  dateType,
  setDateType,
}) => {
  const dispatch = useDispatch()

  const onChangeTimeRangeTab = (item) => {
    setDateType(item.value)
    dispatch(changeTimeRange(item.value))
  }

  return (
    <div className="align-center j-b">
      <Button
        className="button-filter-chart"
        onClick={(_) =>
          setChartType(
            chartType === timeSaleChartType.AREA_LINE
              ? timeSaleChartType.HORIZONTAL_STACK_BAR
              : timeSaleChartType.AREA_LINE,
          )
        }
      >
        <i
          className={`${
            chartType === timeSaleChartType.AREA_LINE
              ? 'icon-chart'
              : 'icon-ananytic'
          }`}
        />
      </Button>
      <DispatchActionTab
        data={Object.keys(timeSaleDateFilter).map((key) => ({
          title: timeSaleDateFilter[key],
          value: timeSaleDateFilter[key],
        }))}
        activeTab={dateType}
        onChangeTab={onChangeTimeRangeTab}
        itemStyle={{ fontWeight: 500, fontSize: 10 }}
      />
    </div>
  )
}

ChartFilter.propTypes = {
  chartType: PropTypes.string.isRequired,
  setChartType: PropTypes.func.isRequired,
  dateType: PropTypes.string.isRequired,
  setDateType: PropTypes.func.isRequired,
}
