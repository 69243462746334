import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import { listLevel } from '../../constants'
import style from '../style.module.css'
import {
  changeIsGetData,
  changeSectorLever,
  selectSectorLever,
} from './store/slice'

const ThSelectSector = () => {
  const dispatch = useDispatch()

  const levelSector = useSelector(selectSectorLever)

  const handleChangeLevel = (value) => {
    dispatch(changeSectorLever(value))
    dispatch(changeIsGetData(true))
  }

  const renderSelect = ({ dataById, value }) => {
    return (
      <div className={`${style.h30px} d-flex ali-center`}>
        <div className={style.selectLevel}>
          <span>{dataById[value]?.name || ''}</span>
          <Span style={{ fontSize: 7, color: '#2f9aee' }}>
            <i className="icon-caret-down"></i>
          </Span>
        </div>
      </div>
    )
  }

  return (
    <div className="d-flex ali-center justify-content-space-between">
      <div className="mr-10">
        <Translate value="sector.sectorStatistics.overview.SECTOR" />
      </div>
      <div className={style.selectLevelContainer}>
        <SelectCustom
          selectData={listLevel}
          value={levelSector}
          handleChange={handleChangeLevel}
          renderSelect={renderSelect}
          fontSizeItem={10}
        />
      </div>
    </div>
  )
}

export default ThSelectSector
