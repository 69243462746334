export const corporate = {
  ICB_LEVEL: 'Industrial sector (ICB) L',
  YEAR_OF_REVIEW: 'Year of Review',
  EXRIGHT_DATE: 'Exright Date',
  ISSUE_METHOD: 'Issue Method',
  STOCK_DIVIDEND: 'Stock Dividend',
  MOST_RECENT: 'Most Recent',
  UNIT: 'Unit',
  ACTION: 'Action',
  END_DATE: 'End Date',
  EVENT: 'Event',
  DATE: 'Date',
  SixMonthsAgo: '6 Months Ago',
  OneWeekAgo: '1 Week Ago',
  NineMonthsAgo: '9 Months Ago',
  OneMonthAgo: '1 Month Ago',
  OneYearAgo: '1 Year Ago',
  ThreeMonthsAgo: '3 Months Ago',
  TwoYearsAgo: '2 Years Ago',
  MostRecent: 'Most Recent',
  DIVIDEND_YEAR: 'Dividend Year',
  INTERIM: 'Interim',
  ALL: 'All',
  All: 'All',
  YEAR: 'Year',
  QUARTER: 'Quarter',
  MOST_RECENT_YEAR: 'Most Recent Year',
  MOST_RECENT_QUARTER: 'Most Recent Quarter',
  1: 'Q1',
  2: 'Q2',
  3: 'Q3',
  4: 'Q4',
  6: '6 months',
  9: '9 months',
  5: 'Annual',
  Consolidated: 'Consolidated',
  Unconsolidated: 'Unconsolidated',
  TITLE: 'CORPORATE',
  TICKER: 'Filter',
  RESULTS: 'RESULTS',
  NUMBER_OF_RESULT: 'Number of result',
  TICKER_TABLE: 'TICKER',
  COMPANY: 'COMPANY NAME',
  EXCHANGE: 'EXCHANGE',
  SETTING: 'Setting',
  REMOVE_ONLY: 'Remove only',
  REMOVE_GROUP: 'Remove group',
  EXIST_TICKER: 'Ticker is already existed.',
  MAX_SHEET_1: 'The number of spread sheets is no more than 10.',
  MAX_SHEET_2: 'Please try again!',
  MAX_TICKER_1: 'The number of tickers is no more than 4000.',
  MAX_TICKER_2: 'Please try again!',
  MAX_TEMPLATE_1: 'The number of personal templates is no more than 100.',
  MAX_TEMPLATE_2: 'Please try again!',
  YEARLY_RATIO: 'Yearly Ratio',
  TTM_RATIO: 'TTM Ratio',
  Both: 'Both audited and unaudited',
  Audited: 'Audited',
  Unaudited: 'Unaudited',
}
