import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { LIST_TYPE } from '../../common/constants'
import { selectSecurities } from '../common/store/slice'
import style from './index.module.css'
import ListAll from './ListAll'
import ListCompanies from './ListCompanies'
import ListEconomy from './ListEconomy'
import ListIndices from './ListIndices'
import ListSectors from './ListSectors'
import ListWatchList from './ListWatchList'

const PopupQuickAddSecurities = ({
  parentRef,
  isShow,
  onClose,
  position,
  itemId,
  typeSelect,
  keySearch = '',
  viewType,
  isShowInputSearch,
}) => {
  const securities = useSelector(selectSecurities)

  const [listType, setListType] = useState(LIST_TYPE.ALL)
  const [valueSearch, setValueSearch] = useState('')

  const getListSecurities = () => {
    const props = {
      securities,
      valueSearch,
      onClose,
      itemId,
      viewType,
    }

    switch (listType) {
      case LIST_TYPE.COMPANIES:
        return <ListCompanies {...props} />
      case LIST_TYPE.SECTOR:
        return <ListSectors {...props} />
      case LIST_TYPE.ECONOMY:
        return <ListEconomy {...props} />
      case LIST_TYPE.INDICES:
        return <ListIndices {...props} />
      case LIST_TYPE.WATCH_LIST:
        return <ListWatchList {...props} />
      default:
        return <ListAll {...props} />
    }
  }

  const handleSetListType = (value) => {
    if (!typeSelect) setListType(value)
  }

  useEffect(() => {
    setListType(typeSelect || LIST_TYPE.ALL)
  }, [isShow])

  useEffect(() => {
    setValueSearch(keySearch)
  }, [keySearch])

  return (
    <Dropdown
      parentRef={parentRef}
      isShow={isShow}
      position={position || positionDropdown.BOTTOM_LEFT_RIGHT}
    >
      <HandleClickOutside handleClickOutside={onClose} exceptRef={parentRef}>
        <div className={style.popupContent}>
          <nav className={`tab-in-panel mb-8 ${style.tabInPanel}`}>
            {Object.keys(LIST_TYPE).map((key) => (
              <a
                key={key}
                className={[
                  style.itemTab,
                  listType === LIST_TYPE[key] ? style.btnActive : '',
                ].join(' ')}
                onClick={() => handleSetListType(LIST_TYPE[key])}
                style={{ cursor: typeSelect ? 'no-drop' : 'auto' }}
              >
                <Span>
                  <Translate value={`financialChart.${key}`} />
                </Span>
              </a>
            ))}
          </nav>
          {isShowInputSearch ? (
            <div className="align-center j-b">
              <div className={style.searchSecurities}>
                <Input
                  value={valueSearch}
                  style={{ fontSize: 11 }}
                  placeholder={I18n.t('financialChart.SEARCH')}
                  className={style.inputSearch}
                  onChange={(e) => setValueSearch(e.target.value)}
                />
                <i className={`icon-search-2 ${style.iconSearch}`} />
              </div>
            </div>
          ) : null}
          <div className={style.listSecurities}>{getListSecurities()}</div>
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

PopupQuickAddSecurities.propTypes = {
  isShow: PropTypes.bool,
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  typeSelect: PropTypes.string,
  isShowInputSearch: PropTypes.bool,
}

PopupQuickAddSecurities.defaultProps = {
  isShowInputSearch: false,
}

export default PopupQuickAddSecurities
