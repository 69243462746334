import PropTypes from 'prop-types'
import TextEllipsis from '../../../../common/textEllipsis'

export const ChartLabelTop = ({ label }) => {
  return (
    <div style={{ textTransform: 'uppercase' }} className="text-center w-100">
      <TextEllipsis text={label} />
    </div>
  )
}

ChartLabelTop.propTypes = {
  label: PropTypes.string,
}
