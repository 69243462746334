import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import useGetExchange from '../../../../common/masterData/useGetExchange'
import { getAllIdItem } from '../../../../common/tree/helper'
import { handleDuplicateArray, keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'
import {
  changeCheckedExchange,
  selectExchangesTemplate,
} from '../../store/slice'
import DropdownExchange from './DropdownExchange'

const Exchange = () => {
  const dispatch = useDispatch()

  const exchangesTemplate = useSelector(selectExchangesTemplate)

  const { exchangesTree, exchanges, loading, exchangeTreeNotDeleteChildren } =
    useGetExchange(true, true, undefined, true)

  const [exchangeByIds, setExchangeByIds] = useState({})

  useEffect(() => {
    setExchangeByIds(keyBy(exchanges, 'groupCode'))
  }, [exchanges])

  useEffect(() => {
    if (exchangesTemplate.length > 0) {
      dispatch(
        changeCheckedExchange(
          handleDuplicateArray(
            exchangesTemplate.reduce((arr, item) => {
              const detailItem = exchangeByIds[item]

              if (detailItem) {
                const idsChildren = getAllIdItem(
                  exchangeTreeNotDeleteChildren[detailItem.groupId] ||
                    exchangeTreeNotDeleteChildren[
                      detailItem.groupOrder + ' - ' + detailItem.groupId
                    ],
                  'groupId',
                )
                return arr.concat(idsChildren)
              }
              return arr
            }, []),
          ),
        ),
      )
    }
  }, [exchangesTemplate])

  return (
    <>
      <h5 className={style.labelFilter}>
        <Span style={{ fontSize: 11 }}>
          <Translate value="tool.smartScreening.EXCHANGE_LOW" />
        </Span>
      </h5>
      <div className="input-dropdown mb-8">
        <DropdownExchange
          exchangesTree={exchangesTree}
          exchanges={exchanges}
          loading={loading}
        />
      </div>
    </>
  )
}

export default Exchange
