import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { hiddenIndustryListByLevel } from '../../../../configs/HiddenIndustryList'
import { EMPTY_VALUE } from '../../../constants/Common'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import useWrapper from '../../hooks/useWrapper'
import useGetICB from '../../masterData/useGetICB'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import PriceData from './PriceData'
import { MAP_DEFAULT_SECTOR } from './constants'
import DropdownItem from './dropdownItem'
import {
  changeLoadingSearch,
  changeSector,
  selectLoadingSearch,
  selectSectorInfo,
} from './store/slice'

const SectorTopInfo = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate()

  const { ICBs } = useGetICB(true, false, (item) =>
    props.levelSector && props.levelSector.length
      ? props.levelSector.includes(item.icbLevel) &&
        !Object.values(hiddenIndustryListByLevel).flat().includes(item.icbId)
      : !Object.values(hiddenIndustryListByLevel).flat().includes(item.icbId),
  )
  const { width: wrapperWidth } = useWrapper()
  useHeaderSearch(
    TYPE_HEADER.SECTOR,
    true,
    [],
    undefined,
    undefined,
    props.keepSearchParams,
  )

  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const isLoadingSearch = useSelector(selectLoadingSearch)

  const [keySearch, setKeySearch] = useState('')

  const handleSelect = (data, replace) => {
    if (data) {
      navigate(location.pathname, data, '', replace)
    }
  }

  const handleFocusInputSearch = () => {
    setKeySearch('')
  }

  const handleChangeLoading = () => {
    dispatch(changeLoadingSearch(true))
  }

  const handleSearch = useMemo(() => {
    const newArray = ICBs.map((item) => ({
      icbNameCustom: item.icbName + ' L' + item.icbLevel,
      ...item,
    }))

    const data = newArray
      .filter((i) => i.icbNameCustom?.toLowerCase().includes(keySearch))
      .sort((a, b) => a.icbNameCustom?.localeCompare(b.icbNameCustom))

    dispatch(changeLoadingSearch(false))
    return data
  }, [keySearch, JSON.stringify(ICBs)])

  const options = !isLoadingSearch
    ? handleSearch.map((index) => ({
        id: index.icbId,
        value: { ...index },
      }))
    : []

  useEffect(() => {
    const result = ICBs.find((item) => item.icbId === sectorInfo.icbId)
    if (result?.icbId !== sectorInfo.icbId) {
      handleSelect(result)
    }
  }, [ICBs])

  useEffect(() => {
    if (location.search) {
      const result = ICBs.find(
        (item) => '?icbId=' + item.icbId === location.search,
      )
      if (result) {
        dispatch(changeSector(result))
      } else {
        if (props.levelSector?.length) {
          handleSelect(MAP_DEFAULT_SECTOR[props?.levelSector[0]][locale], true)
        }
      }
    }
  }, [location.search, ICBs])

  return (
    <div className="top-info" style={{ width: wrapperWidth || '100%' }}>
      {props.title && (
        <>
          <Title title={props.title} />
          {props.customTopInfo}
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={sectorInfo.icbName}
        description={
          sectorInfo.icbName
            ? sectorInfo.icbName + ' L' + sectorInfo.icbLevel
            : EMPTY_VALUE
        }
        onFocus={handleFocusInputSearch}
        onTextChange={(e) => setKeySearch(e?.toLowerCase())}
        options={options}
        loading={isLoadingSearch}
        onSelect={props.handleSelect || handleSelect}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
        onChangeLoading={handleChangeLoading}
      />
      <PriceData />
    </div>
  )
}

SectorTopInfo.propTypes = {
  title: PropTypes.string,
  customTopInfo: PropTypes.node,
  levelSector: PropTypes.arrayOf(PropTypes.number),
}

export default SectorTopInfo
