import { Translate } from 'react-redux-i18n'
import { DEFAULT_ORGANIZATION_ID } from '../../../../configs/Common'
import { Icon } from '../../../common/html/Icon'
import { Span } from '../../../common/html/Span'
import { TextBlink } from '../../../common/table/TextBlink'
import TextEllipsis from '../../../common/textEllipsis'
import { TooltipWarning } from '../../../common/tooltipWarning'
import { COM_GROUP } from '../../../constants/Group'
import { getBondPriceColor } from '../../../utils/Color'
import { formatVal, valDivMillion, valToPercent } from '../../../utils/Value'
import {
  COLLATERAL,
  ISSUE_METHOD,
  MARKET_STATISTIC,
  TRADING_STATUS,
} from './constants'
import style from './index.module.css'

export const formatPrice = (
  price,
  rbD0002,
  parValue,
  isCleanPrice,
  isExchangePrice,
) => {
  if (isNaN(price)) return undefined

  if (price === 0) return 0

  const newPrice = price - (isCleanPrice && !isNaN(rbD0002) ? rbD0002 : 0)

  return isExchangePrice ? (newPrice * 100) / (parValue ?? 1) : newPrice
}

export const formatDataTable = ({
  data,
  type,
  isCleanPrice,
  isExchangePrice,
}) =>
  data.map((item) => ({
    ...item,
    bidPrice: formatPrice(
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.best1Bid : undefined,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    bidPrice2: formatPrice(
      item?.best2Bid,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    bidPrice3: formatPrice(
      item?.best3Bid,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    bidYtm:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.bid1YTM : undefined,
    bidYtm2: item?.bid2YTM,
    bidYtm3: item?.bid3YTM,
    bidVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.best1BidVolume
        : undefined,
    bidVolume2: item?.best2BidVolume,
    bidVolume3: item?.best3BidVolume,
    matchedPrice: formatPrice(
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchPrice
        : item?.dealPrice,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    ytm:
      item?.ytm && item?.referenceYTM
        ? item?.ytm - item?.referenceYTM
        : undefined,
    matchedYtm: item?.ytm,
    matchedVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchVolume
        : item?.dealVolume,
    matchedValue:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.matchValue
        : item?.dealValue,
    askPrice: formatPrice(
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.best1Offer : undefined,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    askPrice2: formatPrice(
      item?.best2Offer,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    askPrice3: formatPrice(
      item?.best3Offer,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    askYtm:
      type === MARKET_STATISTIC.ORDER_MATCHING ? item?.ask1YTM : undefined,
    askYtm2: item?.ask2YTM,
    askYtm3: item?.ask3YTM,
    askVolume:
      type === MARKET_STATISTIC.ORDER_MATCHING
        ? item?.best1OfferVolume
        : undefined,
    askVolume2: item?.best2OfferVolume,
    askVolume3: item?.best3OfferVolume,
    highPrice: formatPrice(
      item?.highestPrice,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    highYtm: item?.highYTM,
    lowPrice: formatPrice(
      item?.lowestPrice,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    lowYtm: item?.lowYTM,
    avgPrice: formatPrice(
      item?.averagePrice,
      item?.rbD0002,
      item?.parValue,
      isCleanPrice,
      isExchangePrice,
    ),
    avgYtm: item?.avgYTM,
    totalMatchedValue: item?.totalMatchValue,
    totalDealVolume: item?.totalDealVolume,
    totalDealValue: item?.totalDealValue,
    name: item.organizationShortName,
    couponValue: item?.interestRate,
    referencePrice: item?.referencePrice,
    id: item.bondId,
    level: 1,
    parentId: item.bondId,
    childIds: [],
  }))

export const START_TIME_HOUR = process.env.REACT_APP_PRICE_BOARD_START_TIME_HOUR
export const START_TIME_MINUTE =
  process.env.REACT_APP_PRICE_BOARD_START_TIME_MINUTE
export const END_TIME_HOUR = process.env.REACT_APP_PRICE_BOARD_END_TIME_HOUR
export const END_TIME_MINUTE = process.env.REACT_APP_PRICE_BOARD_END_TIME_MINUTE

export const formatBondList = (data) =>
  data.map((item) => ({
    ...item,
    bondIds: data
      .filter(
        (child) => child.bondId && child.organizationId === item.organizationId,
      )
      .map((item) => item.bondId),
  }))

export const getTableSchema = ({
  data,
  tableHeader,
  isExchangePrice,
  setIsShow,
  redirectToSecurityMaster,
  redirectToBondIssuer,
  handleSaveToBookmark,
  handleClickPrice,
}) => {
  return tableHeader[1].map((item, index) => {
    const result = {
      colId: item.name,
    }

    if (item.name === 'ticker') {
      return {
        ...result,
        tdStyle: {
          minWidth: 97,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props} className={style.tickerTd}>
              <div className="d-flex" style={{ gap: 4 }}>
                <div
                  style={{
                    width: 12,
                    height: 12,
                    flexShrink: 0,
                  }}
                >
                  <img
                    className={`cursor-pointer ${
                      row?.isBookmark ? style.block : ''
                    }`}
                    src={
                      row?.isBookmark ? '/iconMarked.svg' : '/iconUnMarked.svg'
                    }
                    alt="Bookmark Icon"
                    onClick={() => handleSaveToBookmark(rowId)}
                    width="100%"
                  />
                </div>
                <a
                  className="cursor-pointer"
                  onClick={() => redirectToSecurityMaster(rowId)}
                >
                  <TextEllipsis text={value} isI18n={false} />
                </a>

                <div
                  className="d-flex ali-center justify-content-center"
                  style={{
                    width: 16,
                    height: 16,
                    flexShrink: 0,
                  }}
                >
                  <Icon
                    className={`icon-plus-circle-line cursor-pointer`}
                    style={{ fontSize: 12 }}
                    onClick={(e) => {
                      e.stopPropagation()
                      setIsShow(true)
                    }}
                  />
                </div>
              </div>
            </td>
          )
        },
      }
    }

    if (item.name === 'organizationShortName') {
      return {
        ...result,
        tdStyle: {
          minWidth: 150,
          maxWidth: 150,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <a
                className="cursor-pointer"
                onClick={() =>
                  redirectToBondIssuer(
                    row?.organizationId ??
                      DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                  )
                }
              >
                <TextEllipsis text={value} isI18n={false} />
              </a>
            </td>
          )
        },
      }
    }

    if (item.name === 'couponTypeName') {
      return {
        ...result,
        tdStyle: {
          minWidth: 84,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <div className="d-flex ali-center">
                <Span
                  className="mr-8"
                  style={{
                    color:
                      value === 'Thả nổi' || value === 'Float'
                        ? '#FECF2F'
                        : '#ECECEC',
                  }}
                >
                  {value}
                </Span>
                {row?.interestDescription &&
                  [1, 3].includes(row?.couponTypeId) && (
                    <TooltipWarning
                      tooltip={row?.interestDescription ?? ''}
                      isI18n={false}
                      alignItems={'center'}
                      iconPaddingTop={2}
                      maxWidthTooltip={375}
                      isHtml
                    />
                  )}
              </div>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidPrice3') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.bidYtm3, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidVolume3') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidPrice2') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.bidYtm2, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidVolume2') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.bidYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'bidVolume') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'ytm') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(24, 89, 153, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{valToPercent(value, false)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'matchedPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          background: 'rgba(24, 89, 153, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.matchedYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'matchedVolume') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(24, 89, 153, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'matchedValue') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(24, 89, 153, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(valDivMillion(value))}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.askYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askVolume') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askPrice2') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.askYtm2, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askVolume2') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askPrice3') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.askYtm3, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'askVolume3') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'highPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.highYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'totalHighVolumeBid') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'lowPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.lowYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'totalHighVolumeAsk') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'avgPrice') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <TextBlink value={value}>
                <div>
                  <Span
                    style={{
                      color: getBondPriceColor(
                        value,
                        isExchangePrice
                          ? (row?.referencePrice * 100) / (row?.parValue ?? 1)
                          : row?.referencePrice,
                      ),
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      handleClickPrice({
                        id: rowId,
                        price: !isNaN(value)
                          ? formatVal(
                              isExchangePrice
                                ? (value * row?.parValue ?? 1) / 100
                                : value,
                            )
                          : '',
                        parValue: row?.parValue ?? 0,
                        ticker: row?.ticker ?? '',
                      })
                    }
                  >
                    {formatVal(value, isExchangePrice ? 2 : 0)}
                  </Span>
                </div>
                <div>{valToPercent(row?.avgYtm, false)}</div>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'totalDealVolume') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(value, 0)}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'totalMatchedValue') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(valDivMillion(value))}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'totalDealValue') {
      return {
        ...result,
        tdStyle: {
          minWidth: 90,
          textAlign: 'right',
          verticalAlign: 'top',
          background: 'rgba(92, 92, 92, 0.10)',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <TextBlink value={value}>
                <Span>{formatVal(valDivMillion(value))}</Span>
              </TextBlink>
            </td>
          )
        },
      }
    }

    if (item.name === 'isCollateral') {
      return {
        ...result,
        tdStyle: {
          minWidth: 80,
          maxWidth: 80,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <Span
                style={{
                  color: value === COLLATERAL.YES ? '#9747FF' : '#ECECEC',
                }}
              >
                <Translate
                  value={`bond.corporateBond.priceBoard.${
                    value === COLLATERAL.YES ? 'YES' : 'NO'
                  }`}
                />
              </Span>
            </td>
          )
        },
      }
    }

    if (item.name === 'couponValue') {
      return {
        ...result,
        tdStyle: {
          minWidth: 80,
          maxWidth: 80,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          const row = data.find((item) => item.bondId === rowId)

          return (
            <td {...props}>
              <Span>{valToPercent(row?.couponValue ?? null, false)}</Span>
            </td>
          )
        },
      }
    }

    if (item.name === 'remainingDuration') {
      return {
        ...result,
        tdStyle: {
          minWidth: 80,
          maxWidth: 80,
          textAlign: 'right',
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <Span>{formatVal(value)}</Span>
            </td>
          )
        },
      }
    }

    if (item.name === 'tradingStatusId') {
      return {
        ...result,
        tdStyle: {
          minWidth: 80,
          maxWidth: 80,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <Span
                style={{
                  color:
                    value === TRADING_STATUS.LISTED ? '#2DE352' : '#ECECEC',
                }}
              >
                <Translate
                  value={`bond.corporateBond.priceBoard.${
                    value === TRADING_STATUS.LISTED ? 'LISTED' : 'OTC'
                  }`}
                />
              </Span>
            </td>
          )
        },
      }
    }

    if (item.name === 'issueMethodId') {
      return {
        ...result,
        tdStyle: {
          minWidth: 95,
          maxWidth: 95,
          verticalAlign: 'top',
        },
        canCustomTd: true,
        render: (value, rowId, props) => {
          return (
            <td {...props}>
              <Span
                style={{
                  color: value === ISSUE_METHOD.PUBLIC ? '#FECF2F' : '#ECECEC',
                }}
              >
                <Translate
                  value={`bond.corporateBond.priceBoard.${
                    value === ISSUE_METHOD.PUBLIC ? 'PUBLIC' : 'PRIVATE'
                  }`}
                />
              </Span>
            </td>
          )
        },
      }
    }

    return {
      ...result,
      tdStyle: {
        minWidth: 90,
        maxWidth: 90,
        verticalAlign: 'top',
      },
      canCustomTd: true,
      render: (value, rowId, props) => {
        return (
          <td {...props}>
            <Span>{value}</Span>
          </td>
        )
      },
    }
  })
}
