import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getEquationDataAggregateParams,
  getEquationDataCountParams,
} from '../../../common/helpers'
import {
  equationType,
  indicatorDefaultData,
} from '../../../popup/popupAddEquation/constants'
import {
  selectIndicatorLatestPeriodLoading,
  selectIndicatorsLatestPeriod,
} from '../../../store/slice'
import { VIEW_TYPE } from '../../constants'
import {
  changeEquationLoading,
  resetDataChartEquation,
  selectEquationChange,
  selectEquations,
  selectViewType,
} from '../../store/slice'
import { getDataAggregate, getDataCount } from '../../store/thunk'

export const DataByEquations = () => {
  const dispatch = useDispatch()

  const equationsRef = useRef({ equations: {} })

  const viewType = useSelector(selectViewType)
  const equations = useSelector(selectEquations)
  const equationChange = useSelector(selectEquationChange)
  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)
  const loadingIndicatorLatestPeriod = useSelector(
    selectIndicatorLatestPeriodLoading,
  )

  const checkEquationChange = () => {
    if (equationsRef.current.equations[equationChange.order]) {
      const {
        equationSecurities,
        equationIndicators,
        equationFormula,
        equationType,
      } = equationsRef.current.equations[equationChange?.order]
      return (
        equationType === equationChange.equationType &&
        JSON.stringify(equationSecurities) ===
          JSON.stringify(equationChange.equationSecurities) &&
        JSON.stringify(equationIndicators) ===
          JSON.stringify(equationChange.equationIndicators) &&
        equationFormula === equationChange.equationFormula
      )
    } else {
      return false
    }
  }

  const getEquationData = async (equation) => {
    let functionGetEquationDataParams = getEquationDataAggregateParams
    let thunkGetDataApi = getDataAggregate

    if (equation.equationType === equationType.COUNT) {
      functionGetEquationDataParams = getEquationDataCountParams
      thunkGetDataApi = getDataCount
    }

    const params = functionGetEquationDataParams(
      viewType,
      equation,
      indicatorsLatestPeriod,
    )

    if (
      viewType === VIEW_TYPE.BY_SECURITIES &&
      params.indicators.some((indicator) =>
        Object.keys(indicatorDefaultData).every((key) => !indicator[key]),
      )
    ) {
      return
    }

    await dispatch(thunkGetDataApi(params))
  }

  useEffect(() => {
    const asyncFn = async () => {
      if (equationChange && !checkEquationChange()) {
        equationsRef.current.equations = equations
        dispatch(changeEquationLoading(true))
        await getEquationData(equationChange)
        dispatch(changeEquationLoading(false))
      }
    }
    asyncFn()
  }, [equationChange])

  useEffect(() => {
    const asyncFn = async () => {
      if (!loadingIndicatorLatestPeriod) {
        const promises = []
        Object.values(equations).forEach((equation) => {
          promises.push(getEquationData(equation))
        })
        dispatch(resetDataChartEquation())
        dispatch(changeEquationLoading(true))
        await Promise.allSettled(promises)
        dispatch(changeEquationLoading(false))
      }
    }
    asyncFn()
  }, [loadingIndicatorLatestPeriod, viewType])

  useEffect(() => {
    if (
      Object.keys(equationsRef.current.equations).length >
      Object.keys(equations).length
    ) {
      equationsRef.current.equations = equations
    }
  }, [equations])

  return <></>
}
