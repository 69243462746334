export const heatmap = {
  FILTER: 'Filter',
  EXCHANGE: 'Exchange',
  SIZE_BY: 'Size by',
  CRITERIA: 'Criteria',
  exchanges: {
    ALL: 'All',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
  },
  sizeByes: {
    MARKET_CAPITALIZATION: 'Market Capitalization',
    TOTAL_VOLUME: 'Total Volume',
    TOTAL_VALUE: 'Total Value',
    FOREIGN_SELL_VOLUME: 'Foreign Sell Volume',
    FOREIGN_BUY_VOLUME: 'Foreign Buy Volume',
    FOREIGN_SELL_VALUE: 'Foreign Sell Value',
    FOREIGN_BUY_VALUE: 'Foreign Buy Value',
  },
  criterias: {
    '1_DAY_PERFORMANCE': '1 Day Performance',
    '1_WEEK_PERFORMANCE': '1 Week Performance',
    '1_MONTH_PERFORMANCE': '1 Month Performance',
    '3_MONTHS_PERFORMANCE': '3 Months Performance',
    '6_MONTHS_PERFORMANCE': '6 Months Performance',
    '1_YEAR_PERFORMANCE': '1 Year Performance',
    YTD_PERFORMANCE: 'YTD Performance',
    RELATIVE_VOLUME: 'Relative Volume',
    'P/E': 'P/E',
    'P/B': 'P/B',
  },
  listTickerHeaderTable: {
    TICKER: 'TICKER',
    PRICE: 'PRICE',
    MarketCap: 'MARKET CAP (BN.)',
    TotalVolume: 'TOTAL VOL. (Thou.)',
    TotalValue: 'TOTAL VAL. (Mn.)',
    ForeignTotalSellVolume: 'FR. SELL VOL. (Thou.)',
    ForeignTotalBuyVolume: 'FR. BUY VOL. (Thou.)',
    ForeignTotalSellValue: 'FR. SELL VAL. (Mn.)',
    ForeignTotalBuyValue: 'FR. BUY VAL. (Mn.)',
    OneDayPerformance: '%1D CHANGE',
    OneWeekPerformance: '%1W CHANGE',
    OneMonthPerformance: '%1M CHANGE',
    ThreeMonthsPerformance: '%3M CHANGE',
    SixMonthsPerformance: '%6M CHANGE',
    OneYearPerformance: '%1Y CHANGE',
    YTDPerformance: '%YTD CHANGE',
    RelativeVolume: 'Relative Volume',
    PE: 'P/E',
    PB: 'P/B',
  },
}
