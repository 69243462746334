import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import useGetIndicesV2 from '../../../common/masterData/useGetIndicesV2'
import { NoData } from '../../../common/noData'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import {
  changeInListYAxisCustom,
  changeTreeSecuritiesIndicator,
} from '../../chart/store/slice'
import {
  LIST_TYPE,
  MAX_SECURITIES_ADD,
  MAX_SECURITIES_ITEM,
} from '../../common/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { changeSecurities } from '../common/store/slice'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import ItemSecurities from './ItemSecurities'
import withWrapperData from './withWrapperData'

const ListIndices = ({
  valueSearch,
  securities,
  onClose,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  itemId,
  usedColors,
  viewType,
}) => {
  const dispatch = useDispatch()

  const { favoriteColors } = useGetUserSettings()

  const { loading, indices } = useGetIndicesV2()

  const [filteredIndices, setFilteredIndices] = useState([])

  const addSecurities = (id) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const arrNew = [...securities.indices, id]
    const arrValue = itemId ? arrNew.filter((i) => i !== itemId) : arrNew
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [LIST_TYPE.INDICES]: arrValue,
      },
      replaceSecurities: itemId,
      typeSecuritiesReplace: LIST_TYPE.INDICES,
      usedColors,
      viewType,
      favoriteColors,
    })
    dispatch(
      changeInListYAxisCustom({
        replaceId: [LIST_TYPE.INDICES, itemId].join('_'),
        updateId: [LIST_TYPE.INDICES, id].join('_'),
      }),
    )
    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(
      changeSecurities({
        typeSecurities: LIST_TYPE.INDICES,
        value: arrValue,
      }),
    )
    onClose()
  }

  const renderTickers = () => (
    <ScrollComponent bgColor="#D9D9D9">
      {filteredIndices.map((item) => {
        return (
          <ItemSecurities
            key={item.groupId}
            securities={securities.indices}
            id={item.groupId}
            text={item.groupName}
            onClick={() => addSecurities(item.groupId)}
            itemId={itemId}
          />
        )
      })}
    </ScrollComponent>
  )

  useEffect(() => {
    setFilteredIndices(
      indices
        .filter((index) =>
          valueSearch
            ? index.groupName?.toLowerCase().includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [indices, valueSearch])

  return (
    <div className="h-100 w-100">
      {loading ? (
        <Loading isBlack />
      ) : filteredIndices.length ? (
        renderTickers()
      ) : (
        <NoData />
      )}
    </div>
  )
}

ListIndices.propTypes = {}

export default withWrapperData(ListIndices)
