import { formatVal } from '../../../utils/Value'
import { Span } from '../../html/Span'

const ChartTooltip = ({
  data,
  yAxisDecimalLength,
  xAxisDecimalLength,
  xAxisId,
  yAxisId,
  zAxisId,
  xTooltip,
  yTooltip,
  zTooltip,
  xTooltipColor,
  yTooltipColor,
  zTooltipColor,
  xUnit,
  yUnit,
  zUnit,
  keyLabel,
}) => {
  return (
    <div>
      <div style={{ marginBottom: 5 }}>
        <Span
          style={{
            fontSize: 14,
            fontWeight: 600,
            color: '#fdfdfd',
          }}
        >
          {`${keyLabel ? `${data[keyLabel]} - ` : ''}${data.name}`}
        </Span>
      </div>
      {xTooltip && (
        <div className="d-flex j-b">
          <div>
            <Span style={{ fontWeight: 400 }}>{xTooltip}:</Span>
          </div>
          <div
            style={{
              fontWeight: 500,
              color: xTooltipColor
                ? xTooltipColor(data[xAxisId])
                : data[xAxisId] < 0
                ? '#eb505a'
                : 'inherit',
            }}
            className="ml-8 d-flex ali-center"
          >
            <Span>{formatVal(data[xAxisId], xAxisDecimalLength || 2)}</Span>
            {xUnit && <Span className="ml-4">{xUnit}</Span>}
          </div>
        </div>
      )}
      {yTooltip && (
        <div className="d-flex j-b">
          <div>
            <Span style={{ fontWeight: 400 }}>{yTooltip}:</Span>
          </div>
          <div
            style={{
              fontWeight: 500,
              color: yTooltipColor
                ? yTooltipColor(data[yAxisId])
                : data[yAxisId] < 0
                ? '#eb505a'
                : 'inherit',
            }}
            className="ml-8 d-flex ali-center"
          >
            <Span>{formatVal(data[yAxisId], yAxisDecimalLength || 2)}</Span>
            {yUnit && <Span className="ml-4">{yUnit}</Span>}
          </div>
        </div>
      )}
      {zTooltip && (
        <div className="d-flex j-b">
          <div>
            <Span style={{ fontWeight: 400 }}>{zTooltip}:</Span>
          </div>
          <div
            style={{
              fontWeight: 500,
              color: zTooltipColor
                ? zTooltipColor(data[zAxisId])
                : data[zAxisId] < 0
                ? '#eb505a'
                : 'inherit',
            }}
            className="ml-8 d-flex ali-center"
          >
            <Span>{formatVal(data[zAxisId], yAxisDecimalLength || 2)}</Span>
            {zUnit && <Span className="ml-4">{zUnit}</Span>}
          </div>
        </div>
      )}
    </div>
  )
}

export default ChartTooltip
