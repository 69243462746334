export const maxIndicator = 250

export const INDICATOR_TYPE = {
  TTM: 'TTM',
  YEARLY: 'Yearly',
}

export const typeOfIndicator = {
  INT: 'int',
  SMALLINT: 'smallint',
  DECIMAL: 'decimal',
  DATE: 'date',
  STRING: 'string',
}

export const DEFAULT_PARAM_TEMPLATE = {
  dataIds: [],
  conditionGroups: [],
}

export const maxTemplate = 100

export const typeData = {
  CORPORATE: 'Corporate',
  INDEX_SECTOR: 'IndexSector',
  ECONOMY: 'Economy',
  TRADING_DATA: 'TradingData',
}

export const typeWorkSpace = {
  CORPORATE: 'Corporate',
  INDEX_SECTOR: 'IndexSector',
  ECONOMY: 'Economy',
  SCREENING: 'Screening',
  TRADING_DATA: 'TradingData',
}

export const timeoutInterval = 15000
