export const COLOR_CHART = [
  '#fff6df',
  '#40abff',
  '#facc5c',
  '#45b29d',
  '#e61a00',
]

const DEFAULT_CPI_VALUE_MOM = 17
const DEFAULT_CPI_VALUE_YOY = 19
const DEFAULT_CPI_VALUE_RTD = 18

export const getDefaultCPIVal = (typeCode) => {
  return typeCode === 'MoM'
    ? DEFAULT_CPI_VALUE_MOM
    : typeCode === 'YoY'
    ? DEFAULT_CPI_VALUE_YOY
    : DEFAULT_CPI_VALUE_RTD
}

export const MONTHS_LIST = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
]
