import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { handleExport } from '../../../../../../../utils/Export'
import { changeQuarterType, changeRetryFlag, changeYearType } from './slice'

export const getFVTPLListedShareDecompositionAll = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/assetStructure/GET_FVTPL_LISTED_SHARE_DECOMPOSITION_ALL',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FinancialAnalysisService.getAssetsStructureFVTPLListedShareDecompositionAll(
          data,
        )

      if (response.requestParams) {
        dispatch(changeYearType(response.requestParams.Year))
        dispatch(changeQuarterType(response.requestParams.Quarter))
      }
      dispatch(changeRetryFlag(false))

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFVTPLListedShareDecompositionDetail = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/assetStructure/GET_FVTPL_LISTED_SHARE_DECOMPOSITION_DETAIL',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FinancialAnalysisService.getAssetsStructureFVTPLListedShareDecompositionDetail(
          data,
        )

      if (response.requestParams) {
        dispatch(changeYearType(response.requestParams.Year))
        dispatch(changeQuarterType(response.requestParams.Quarter))
      }
      dispatch(changeRetryFlag(false))

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrganizationPortfolio = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/assetStructure/GET_ORGANIZATION_PORTFOLIO',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialAnalysisService.getAssetsStructureOrganizationPortfolio(
          data,
        )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadAssetsStructureFVTPLListedShareDecompositionTableAll =
  createAsyncThunk(
    'sector/sectorConstituents/financialAnalysis/security/assetStructure/DOWNLOAD_FVTPL_LISTED_SHARE_DECOMPOSITION_TABLE_ALL',
    async (data) => {
      const response =
        await FinancialAnalysisService.downloadAssetsStructureFVTPLListedShareDecompositionTableAll(
          data,
        )
      handleExport(response.data, response.filename)
    },
  )

export const downloadAssetsStructureFVTPLListedShareDecompositionTableDetail =
  createAsyncThunk(
    'sector/sectorConstituents/financialAnalysis/security/assetStructure/DOWNLOAD_FVTPL_LISTED_SHARE_DECOMPOSITION_TABLE_DETAIL',
    async (data) => {
      const response =
        await FinancialAnalysisService.downloadAssetsStructureFVTPLListedShareDecompositionTableDetail(
          data,
        )
      handleExport(response.data, response.filename)
    },
  )
