import { createSlice } from '@reduxjs/toolkit'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { register } from '../../../../../utils/ReducerRegistry'
import { TABS, VALUE_TYPES } from '../constants'

const initialState = {
  filters: {
    type: TABS.SECTORS,
    valueType: VALUE_TYPES.RELATIVE,
    timeRange: TIME_RANGES.OneYear,
    startDate: null,
    endDate: null,
  },
}

export const slice = createSlice({
  name: 'fund/fundProfile/portfolio/assetAllocationOverTime',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {},
})

export const selectFilters = (state) => state[slice.name].filters

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
