import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import { selectIndexSectorTickerInfo } from '../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import { COMPARE_MAX_ADDED } from '../../constants'
import { convertCompareData } from '../../helper'
import {
  addCompareChecked,
  removeCompareChecked,
  selectListCompareChecked,
} from './store/slice'
import style from './style.module.css'

function ItemCompare({ item }) {
  const dispatch = useDispatch()
  const listCompareChecked = useSelector(selectListCompareChecked)
  const currentInfo = useSelector(selectIndexSectorTickerInfo)

  const handleChecked = () => {
    return listCompareChecked.some(
      (e) =>
        e.nameCompare === (item.groupName || item.ticker || item.icbNameCustom),
    )
  }

  const checkDisableCompare = () => {
    const compareCoincidentTopInfo =
      (currentInfo.groupName ||
        currentInfo.icbNameCustom ||
        currentInfo.ticker) ===
      (item.groupName || item.ticker || item.icbNameCustom)
    return (
      (listCompareChecked.length === COMPARE_MAX_ADDED && !handleChecked()) ||
      compareCoincidentTopInfo
    )
  }
  const onRowClick = () => {
    if (
      listCompareChecked.length < COMPARE_MAX_ADDED ||
      listCompareChecked.some(
        (e) =>
          e.nameCompare ===
          (item.groupName || item.ticker || item.icbNameCustom),
      )
    ) {
      const compareCheck = convertCompareData(item)
      const condition = !listCompareChecked.some(
        (elm) =>
          elm.nameCompare ===
          (item.groupName || item.ticker || item.icbNameCustom),
      )
      if (condition) {
        dispatch(addCompareChecked(compareCheck))
      } else {
        dispatch(removeCompareChecked(compareCheck))
      }
    }
  }

  return (
    <tr
      className={checkDisableCompare() ? style.disabled : undefined}
      onClick={onRowClick}
    >
      <td>
        <Span style={{ fontSize: 12 }}>
          {item.groupName || item.icbNameCustom || item.ticker}
        </Span>
      </td>
      <td className={`${style['w-20']}`}>{item?.exchangeCode}</td>
      <td className={`${style['w-10']} ${style.lineBorderLeft}`}>
        <input
          type="checkbox"
          className={`checkbox ${style.checkbox}`}
          checked={handleChecked()}
          readOnly
          disabled={checkDisableCompare()}
        />
      </td>
    </tr>
  )
}

export default ItemCompare
