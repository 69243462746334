import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal, valDivBillion, valToPercent } from '../../../../utils/Value'
import { titleData } from '../../common/helper'
import {
  selectFilterMonth,
  selectFilterYear,
} from '../keyIndicator/store/slice'
import { selectFDIStructureData, selectLoading } from './store/slice'
import { getFDIStructure } from './store/thunk'
import style from './style.module.css'

const FDIStructure = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const dataFDIStructure = useSelector(selectFDIStructureData)
  const filterMonth = useSelector(selectFilterMonth)
  const filterYear = useSelector(selectFilterYear)

  useEffect(() => {
    if (filterMonth && filterYear)
      dispatch(
        getFDIStructure({
          Month: filterMonth,
          Year: filterYear,
        }),
      )
  }, [locale, filterMonth, filterYear])

  const getFontSizeLabel = (fontSize) => {
    const defaultFontSize = getFontSize(fontSize)
    const widthHeight = Math.min(
      width || Number.POSITIVE_INFINITY,
      height || Number.POSITIVE_INFINITY,
    )
    return (defaultFontSize * 100) / widthHeight
  }

  const handleCustomLabel = ({ x, y, dx, dy, dataEntry }) => {
    if (dataEntry.value)
      return (
        <text
          key={dataEntry.color}
          x={x}
          y={y}
          dx={dx}
          dy={dy}
          dominantBaseline="middle"
          textAnchor="middle"
        >
          <tspan
            x={x}
            y={y}
            style={{
              fontSize: getFontSizeLabel(13),
              fontWeight: 600,
            }}
          >
            {formatVal(valDivBillion(dataEntry.valueLabelFirst))}&nbsp;
            {I18n.t('economy.fdi.overview.BILLION_USD')}
          </tspan>
          <tspan
            x={x}
            y={y}
            dx={dx}
            dy={dy + 6}
            style={{ fontSize: getFontSizeLabel(10) }}
          >
            {dataEntry.valueLabelSecond}&nbsp;
            {I18n.t('economy.fdi.overview.LABEL_PROJECTS')}
          </tspan>
        </text>
      )

    return null
  }

  const renderTooltip = (tooltipData) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            {titleData(filterMonth, filterYear)}
          </Span>
        </div>
        <table>
          <tbody>
            {tooltipData.map((el) => (
              <tr className={style.trTable} key={el.text}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 10 }}>{el.text}</Span>:
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                    paddingBottom: 6,
                  }}
                  className={`${style.pdLabel} ${style.fontWeight600}`}
                >
                  <div>
                    <Span style={{ fontSize: 10 }}>
                      {formatVal(valDivBillion(el.valueLabelFirst))}&nbsp;
                      {I18n.t('economy.fdi.overview.BILLION_USD')} ~&nbsp;
                      {valToPercent(el.value)}
                    </Span>
                  </div>
                  <div>
                    <Span style={{ fontSize: 10 }}>
                      {el.valueLabelSecond}&nbsp;
                      {I18n.t('economy.fdi.overview.LABEL_PROJECTS')}
                    </Span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <div id="fdiStructure" style={{ height: height }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {dataFDIStructure.length ? (
            <PieChartWithFooter
              data={dataFDIStructure}
              height={height}
              width={width}
              isUseContainerFooter
              customLabel={handleCustomLabel}
              pieChartProps={{
                labelPosition: 160,
                radius: 30,
              }}
              renderTooltip={renderTooltip}
            />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
}

export default FDIStructure
