import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { selectIsShowPopupIndicator } from '../store/slice'
import { getIndicators, getWatchList } from '../store/thunk'
import ButtonReset from './ButtonReset'
import Company from './company'
import IndicatorPopup from './indicatorPopup'
import PlotType from './PlotType'
import SelectedItem from './SelectedItem'
import Template from './template'

export const FILTER_SCROLL_ID = 'FILTER_SCROLL_ID'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isShowPopupIndicator = useSelector(selectIsShowPopupIndicator)

  useEffect(() => {
    dispatch(getWatchList())
  }, [])

  useEffect(() => {
    dispatch(getIndicators())
  }, [locale])

  return (
    <div id={FILTER_SCROLL_ID} className="h-100">
      <ScrollComponent>
        <div style={{ padding: 8 }}>
          <Company />
          <Template />
          <SelectedItem />
          <PlotType />
          <ButtonReset />
          {isShowPopupIndicator && <IndicatorPopup />}
        </div>
      </ScrollComponent>
    </div>
  )
}

export default Filter
