import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { ID_DIFF_YOY_ACCYOY } from '../constants'
import { convertWaterfallData } from '../helper'
import { contributionChart } from './thunk'

const slice = createSlice({
  name: 'economy/cpiAndInflation/contribution',
  initialState: {
    isLoadingContributionChart: true,
    data: [],
    idsCPI: [],
    currentMonth: null,
    currentYear: null,
    isYTD: false,
  },
  reducers: {
    changeMonth: (state, action) => {
      state.currentMonth = action.payload
    },
    changeYear: (state, action) => {
      state.currentYear = action.payload
    },
    changeIsYTD: (state, action) => {
      state.isYTD = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(contributionChart.pending, (state) => {
      state.data = []
      state.idsCPI = []
      state.isLoadingContributionChart = true
    })
    builder.addCase(contributionChart.fulfilled, (state, action) => {
      const data = action.payload || []
      state.data = getIdsByCheckYTD(state.isYTD, data)
      state.idsCPI = data.map((v) => v.cpivnTypeId)
      state.isLoadingContributionChart = false
    })
    builder.addCase(contributionChart.rejected, (state) => {
      state.isLoadingContributionChart = false
    })
  },
})

export const selectLoadingContributionChart = (state) =>
  state[slice.name].isLoadingContributionChart
export const selectDataContributionChart = (state) => state[slice.name].data
export const selectIdsCPI = (state) => state[slice.name].idsCPI
export const selectCurrentMonth = (state) => state[slice.name].currentMonth
export const selectCurrentYear = (state) => state[slice.name].currentYear
export const selectIsYTD = (state) => state[slice.name].isYTD

export const { changeMonth, changeYear, changeIsYTD } = slice.actions

register(slice.name, slice.reducer)

const getIdsByCheckYTD = (isYTD, data) => {
  return data.map((item) => ({
    ...item,
    value: convertWaterfallData(data, item),
    cpivnTypeId: isYTD
      ? item.cpivnTypeId + ID_DIFF_YOY_ACCYOY
      : item.cpivnTypeId,
  }))
}
