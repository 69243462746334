import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import SelectCustom from '../../../common/selectCustom'
import TimeRange from '../../common/timeRange'
import Switch from '../common/toolHeader/settingStyleChart/Switch'
import useIsShowChart from '../common/toolHeader/useIsShowChart'
import {
  VIEW_TYPE,
  keyToolHeaderSettings,
  listTimeFrequency,
} from '../constants'
import {
  changeSettings,
  changeTimeFrequency,
  changeTimeRange,
  changeTimeRangeDate,
  selectSettings,
  selectTimeFrequency,
  selectTimeRange,
  selectTimeRangeDate,
  selectViewType,
} from '../store/slice'
import style from '../style.module.css'

const XAxisAndTimeFrequency = forwardRef(({ width }, ref) => {
  const dispatch = useDispatch()

  const viewType = useSelector(selectViewType)
  const timeRange = useSelector(selectTimeRange)
  const timeFrequency = useSelector(selectTimeFrequency)
  const timeRangeDate = useSelector(selectTimeRangeDate)
  const settings = useSelector(selectSettings)
  const isShowChart = useIsShowChart()

  const handleChangeTimeFrequency = (value) => {
    dispatch(changeTimeFrequency(value))
  }

  const handleChangeTimeRange = (val) => {
    dispatch(changeTimeRange(val))
  }

  const handleChangeTimeRangeDate = (val) => {
    dispatch(changeTimeRangeDate(val))
  }

  const handleChangeSettingViewByLatestPeriod = () => {
    dispatch(
      changeSettings({
        [keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD]:
          !settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
      }),
    )
  }

  return (
    <div
      ref={ref}
      className={[
        'd-flex ali-center',
        !isShowChart && style.pointerEventNone,
        viewType === VIEW_TYPE.BY_TIME && 'justify-content-end',
      ].join(' ')}
      style={{ padding: '15px 16px 12px 16px' }}
    >
      {viewType === VIEW_TYPE.BY_TIME ? (
        <div
          className={[
            style.timeData,
            viewType !== VIEW_TYPE.BY_TIME && style.visibilityHidden,
          ].join(' ')}
        >
          <TimeRange
            timeRange={timeRange}
            changeTimeRange={handleChangeTimeRange}
            timeRangeDate={timeRangeDate}
            changeTimeRangeDate={handleChangeTimeRangeDate}
          />
          <div className={style.spanInputSearch}>
            <SelectCustom
              value={timeFrequency}
              isI18n={true}
              selectData={listTimeFrequency}
              handleChange={handleChangeTimeFrequency}
              themeWhite
            />
          </div>
        </div>
      ) : (
        <div className="d-flex ali-center justify-content-space-between">
          <label className="cus--chec">
            <Span style={{ fontSize: 12 }}>
              <Translate value="financialChart.setting.VIEW_BY_LATEST_PERIOD" />
            </Span>
          </label>
          <Switch
            checked={settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD]}
            onChange={handleChangeSettingViewByLatestPeriod}
          />
        </div>
      )}
    </div>
  )
})

export default XAxisAndTimeFrequency
