import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { getFontSize } from '../../../utils/FontSize'
import { LIST_TAB, valueTab } from '../constants'
import {
  getTab,
  handleCheckboxChecked,
  handleTab,
  INIT_CHECKED,
  resetDate,
} from '../store/slice'
import { getEventsDate, getTargetIds } from '../store/thunk'
import style from '../style.module.css'
import DatePicker from './DatePicker'
import EventTypes from './EventTypes'

const Filter = ({ width }) => {
  const dispatch = useDispatch()
  const fontSize = getFontSize(11)
  const [isRecallApi, setIsRecallApi] = useState(false)

  const tab = useSelector(getTab)
  const locale = useSelector((state) => state.i18n.locale)

  const onChangeTabValue = (tab) => {
    dispatch(handleTab(tab.value))
  }

  const handleReset = () => {
    setIsRecallApi(!isRecallApi)
    dispatch(handleTab(valueTab.ALL))
    dispatch(resetDate())
    dispatch(handleCheckboxChecked(INIT_CHECKED))
  }

  useEffect(() => {
    dispatch(
      getTargetIds({
        EventTypeId: tab,
      }),
    )
  }, [tab, locale, isRecallApi])

  useEffect(() => {
    dispatch(getEventsDate())
  }, [])

  return (
    <div className="h-100 flex-column">
      <div className="w-100 mb-40 mt-8">
        <DispatchActionTab
          data={LIST_TAB}
          activeTab={tab}
          onChangeTab={onChangeTabValue}
          containerStyle={{
            width: '100%',
          }}
          itemStyle={{
            width: '33%',
          }}
        />
      </div>
      <div className="mb-40">
        <DatePicker />
      </div>
      <div className={style.textOpacity}>
        <Translate value="economy.event.event.EVENT_TYPES" />
      </div>
      <EventTypes width={width} locale={locale} tab={tab} />
      <div>
        <button
          className={style.resetButton}
          style={{ fontSize }}
          onClick={handleReset}
        >
          Reset
        </button>
      </div>
    </div>
  )
}

export default Filter
