import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../../style.module.css'
import {
  searchAllTicker,
  selectKeySearchTicker,
  selectListCompareChecked,
  selectListTicker,
} from './store/slice'

const RadioSearchAllTicker = () => {
  const dispatch = useDispatch()
  const listTickerChecked = useSelector(selectListCompareChecked)
  const listTicker = useSelector(selectListTicker)
  const showCheckAllTicker = useSelector(selectKeySearchTicker)

  const handleCheckAllTicker = (e) => {
    if (e.target.checked) {
      dispatch(searchAllTicker(true))
    } else {
      dispatch(searchAllTicker(false))
    }
  }

  const onRowClick = () => {
    const condition = listTickerChecked.length === listTicker.length
    if (!condition) dispatch(searchAllTicker(true))
    else dispatch(searchAllTicker(false))
  }

  return (
    <>
      {!showCheckAllTicker && (
        <li className={style.paddingSearchTicker} onClick={onRowClick}>
          <input
            type="checkbox"
            id="radio-check-all"
            className="checkbox"
            checked={listTickerChecked.length === listTicker.length}
            onChange={(e) => handleCheckAllTicker(e)}
          />
          <label>
            <Span style={{ fontSize: 11 }}>
              <Translate value="market.marketInDepth.directorDeal.ALL_TICKERS" />
            </Span>
          </label>
        </li>
      )}
    </>
  )
}

export default RadioSearchAllTicker
