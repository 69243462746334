import { Translate } from 'react-redux-i18n'
import { DOT_TYPES } from '../../../../common/chart/financialChart/constant'
import { Span } from '../../../../common/html/Span'
import { ReactComponent as IconOvalActive } from '../../../icon/icon-oval-active.svg'
import { ReactComponent as IconOval } from '../../../icon/icon-oval.svg'
import { ReactComponent as IconRectangleActive } from '../../../icon/icon-rectangle-active.svg'
import { ReactComponent as IconRectangle } from '../../../icon/icon-rectangle.svg'
import { ReactComponent as IconRhombusActive } from '../../../icon/icon-rhombus-active.svg'
import { ReactComponent as IconRhombus } from '../../../icon/icon-rhombus.svg'
import styles from '../style.module.css'

export const DotTypeComponent = ({ isParent, value, onChange }) => {
  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value="financialChart.DOT_TYPE" />
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <div className={styles.settingDotTypeTab}>
          <div>
            {value === DOT_TYPES.RHOMBUS ? (
              <IconRhombusActive height={16} width={16} />
            ) : (
              <IconRhombus onClick={() => onChange(DOT_TYPES.RHOMBUS)} />
            )}
          </div>
          <div>
            {value === DOT_TYPES.OVAL ? (
              <IconOvalActive height={16} width={16} />
            ) : (
              <IconOval onClick={() => onChange(DOT_TYPES.OVAL)} />
            )}
          </div>
          <div>
            {value === DOT_TYPES.RECTANGLE ? (
              <IconRectangleActive height={16} width={24} />
            ) : (
              <IconRectangle
                height={16}
                onClick={() => onChange(DOT_TYPES.RECTANGLE)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
