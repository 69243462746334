import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { tableAttr, textColors, textUpDown } from './constants'
import { selectData, selectLoading } from './store/slice'
import { getTargetPrice } from './store/thunk'

export const ConsensusTargetPrice = ({ width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const loading = useSelector(selectLoading)

  // Get data
  const getColor = (value) => {
    return value === 0
      ? '#ececec'
      : value > 0
      ? textColors.GREATER
      : textColors.LESS
  }

  const getTextUpDown = (value) => {
    return value > 0 ? textUpDown.UPSIDE : textUpDown.DOWNSIDE
  }

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getTargetPrice({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo?.organizationId])

  if (loading) {
    return <Loading />
  }

  if (!Object.keys(data).length) {
    return <NoData />
  }

  return (
    <ScrollComponent>
      {tableAttr.map((item) => (
        <div
          key={item.name}
          style={{ marginLeft: 'unset', marginRight: 'unset' }}
          className="row mb-15"
        >
          <Span
            style={{ fontSize: 16, fontWeight: 500, whiteSpace: 'nowrap' }}
            className="col-4"
          >
            <Translate value={item.name} />
          </Span>
          <div style={{ alignSelf: 'center' }} className="col-8">
            <div className="d-flex pl-7" style={{ alignItems: 'baseline' }}>
              <Span
                style={{
                  fontSize: 14,
                  color: getColor(data[item.keyPercentage]),
                  marginRight: 8,
                  fontWeight: 600,
                }}
              >
                {formatVal(data[item.keyValue] / item.keyValueFormat, 2, true)}
              </Span>
              <Span style={{ whiteSpace: 'nowrap', fontWeight: 200 }}>
                {`${valToPercent(Math.abs(data[item.keyPercentage]) || null)} `}
                <Translate value={getTextUpDown(data[item.keyPercentage])} />
              </Span>
            </div>
          </div>
        </div>
      ))}
    </ScrollComponent>
  )
}
