import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import GroupStock from '../../../dataExplorer/filter/GroupStock'
import SearchTicker from '../../../dataExplorer/filter/SearchTicker'
import useGetExchange from '../../../masterData/useGetExchange'
import useGetICB from '../../../masterData/useGetICB'
import { ScrollComponent } from '../../../ScrollComponent'
import style from '../index.module.css'
import {
  addTickerPopupTemplate,
  checkPopupTemplate,
  selectTickerByIdPopupTemplate,
  selectTickerPopupTemplate,
  selectTickerPopupTemplateCell,
  uncheckPopupTemplate,
} from '../store/slice'
import { getVN30Thunk } from '../store/thunk'
import CheckAllTicker from './CheckAllTicker'

const CheckBox = ({ id }) => {
  const dispatch = useDispatch()

  const tickerById = useSelector(selectTickerByIdPopupTemplate)

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(checkPopupTemplate(id))
    } else {
      dispatch(uncheckPopupTemplate(id))
    }
  }

  return (
    <input
      onChange={handleChangeCheckbox}
      checked={tickerById[id].check}
      type="checkbox"
      className="checkbox"
    />
  )
}

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const ItemTicker = ({ id, index }) => {
  const ticker = useSelector(
    selectTickerPopupTemplateCell(id, 'tickerOrTaxCode'),
  )
  const tickerName = useSelector(
    selectTickerPopupTemplateCell(id, 'organizationShortName'),
  )

  return (
    <tr>
      <td>{index + 1}</td>
      <td className="d-flex">
        <div className={style.w65}>{ticker}</div>
        <span>{tickerName}</span>
      </td>
      <td>
        <div className="d-flex justify-content-center">
          <p>
            <CheckBox id={id} />
          </p>
        </div>
      </td>
    </tr>
  )
}

ItemTicker.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
}

const MapTicker = () => {
  const ticker = useSelector(selectTickerPopupTemplate)

  return (
    <>
      {ticker.map((id, index) => (
        <ItemTicker key={id} id={id} index={index} />
      ))}
    </>
  )
}

const TableTicker = () => {
  const dispatch = useDispatch()

  const exchange = useGetExchange(true, true)
  const icb = useGetICB(true, true, undefined, (icb) => {
    return { ...icb, icbName: icb.icbName + ' L' + icb.icbLevel }
  })

  useEffect(() => {
    dispatch(getVN30Thunk())
  }, [])

  const handleAddTicker = (tickers) => {
    dispatch(addTickerPopupTemplate(tickers))
  }

  return (
    <>
      <div className={`col ${style.colTicker}`}>
        <div className={`col-header pl-12-pr-16 ${style.h37px}`}>
          <Translate value="tool.dataExplorer.TICKER" />
        </div>
        <div className="col-content">
          <div className={`content-wrapper ${style.contentTicker}`}>
            <SearchTicker handleAddTicker={handleAddTicker} />
            <GroupStock
              exchange={exchange}
              icb={icb}
              handleAddTicker={handleAddTicker}
            />
          </div>
        </div>
      </div>
      <div className={`col ${style.colTable}`}>
        <div className={`col-header ${style.h37px}`}>
          <Translate value="tool.dataExplorer.SELECTED_ITEM" />
          <CheckAllTicker />
        </div>
        <div
          className={`col-content ${style.colContent} ${style.tableIndicatorContainer}`}
        >
          <ScrollComponent>
            <table className={`w-100 ${style.tableSettingIndicator}`}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <MapTicker />
              </tbody>
            </table>
          </ScrollComponent>
        </div>
      </div>
    </>
  )
}

export default TableTicker
