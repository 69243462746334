import { useSelector } from 'react-redux'
import { TextBlink } from '../../../../common/table/TextBlink'
import { formatVal } from '../../../../utils/Value'
import { TIME_FRAME_TOP_NET_VALUE } from './constants'
import { selectTimeFrame } from './store/slice'

const DataCell = ({ value, format, style, className }) => {
  const timeFrame = useSelector(selectTimeFrame)

  const text = formatVal(format(value))

  return (
    <td style={{ ...style }} className={className}>
      {timeFrame === TIME_FRAME_TOP_NET_VALUE.ONE_DAY.value ? (
        <TextBlink value={Number(value)}>{text}</TextBlink>
      ) : (
        text
      )}
    </td>
  )
}

export default DataCell
