import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { COMPONENT } from '../../../configs/Layout'
import style from './index.module.css'

const heightCollapseClose = 40

const CollapseIcon = ({ sizes, setSizes, panelKey }) => {
  const initialSizes = useRef(sizes)

  const isOpen = sizes[panelKey].isMaxSizeCollapse

  useEffect(() => {
    if (
      typeof sizes[panelKey].width === 'number' &&
      typeof sizes[panelKey].height === 'number'
    ) {
      initialSizes.current = sizes
    }
  }, [sizes])

  const handleOpenCollapse = () => {
    const newSizes = {
      ...sizes,
    }
    newSizes[panelKey].groupCollapse.forEach((key) => {
      newSizes[key] = {
        ...newSizes[key],
        isOpenCollapse: false,
        isMaxSizeCollapse: false,
      }
    })
    newSizes[panelKey] = {
      ...sizes[panelKey],
      isOpenCollapse: true,
      isMaxSizeCollapse: true,
    }

    calcNewSizes(newSizes)
  }

  const handleCloseCollapse = () => {
    const newSizes = {
      ...sizes,
    }
    newSizes[panelKey].groupCollapse.forEach((key) => {
      newSizes[key] = {
        ...newSizes[key],
        isOpenCollapse: true,
        isMaxSizeCollapse: false,
      }
    })

    calcNewSizes(newSizes)
  }

  const calcNewSizes = (newSizes) => {
    let totalClose = 0
    let totalOpen = 0
    newSizes[panelKey].groupCollapse.forEach((key) => {
      if (newSizes[key].isOpenCollapse) totalOpen += 1
      else totalClose += 1
    })

    if (totalClose === 0) {
      setSizes(initialSizes.current)
    } else {
      const heightCollapseOpen = `(100% - ${totalClose}*${
        heightCollapseClose + 2 * COMPONENT.MARGIN
      }px)/${totalOpen}`
      let top = `0px`
      const sizesChange = {}
      newSizes[panelKey].groupCollapse.forEach((key) => {
        if (newSizes[key].isOpenCollapse === false) {
          sizesChange[key] = {
            ...newSizes[key],
            height: `${heightCollapseClose - 2 * COMPONENT.MARGIN}px`,
            minHeight: `${heightCollapseClose - 2 * COMPONENT.MARGIN}px`,
            top: `calc(${top})`,
            disableHorizontalResize: true,
          }
          top += ` + ${heightCollapseClose}px`
        } else {
          sizesChange[key] = {
            ...newSizes[key],
            height: `calc(${heightCollapseOpen} - ${2 * COMPONENT.MARGIN}px)`,
            minHeight: `calc(${heightCollapseOpen} - ${
              2 * COMPONENT.MARGIN
            }px)`,
            top: `calc(${top})`,
            disableHorizontalResize: true,
          }
          top += ` + ${heightCollapseOpen}`
        }
      })

      setSizes({
        ...newSizes,
        ...sizesChange,
      })
    }
  }

  return (
    <a
      className={style.pointer}
      onClick={isOpen ? handleCloseCollapse : handleOpenCollapse}
    >
      <i className={isOpen ? 'icon-minus' : 'icon-window'} />
    </a>
  )
}

CollapseIcon.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
  panelKey: PropTypes.string.isRequired,
}

export default CollapseIcon
