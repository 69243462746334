import { useSelector } from 'react-redux'
import { loadingKey, selectLoading, selectStocksHohaup } from './store/slice'

const useGetStockHOHAUP = () => {
  const loading = useSelector(selectLoading(loadingKey.STOCK_HOHAUP))
  const stocksHohaup = useSelector(selectStocksHohaup)

  return {
    loading,
    stocksHohaup,
  }
}

export default useGetStockHOHAUP
