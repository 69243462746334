import { scaleLinear } from 'd3-scale'
import { EMPTY_VALUE } from '../constants/Common'

const COLOR_LETTERS = '0123456789ABCDEF'
const THRESHOLD = 20

export const getThreeColorByValue = (value, isWhiteColorForZero) => {
  return getColor(value)(() => {
    if (value === 0) {
      return isWhiteColorForZero ? '#ececec' : '#ffcc24'
    } else if (value > 0) {
      return '#2de352'
    }

    return '#ff4752'
  })
}

export const getTwoColorByValue = (value) => {
  return getColor(value)(() => {
    if (value >= 0) {
      return '#ececec'
    }

    return '#ff4752'
  })
}

// Color of Match Price = Change = % change
export const getPriceColor = (
  matchPrice,
  refPrice,
  ceilingPrice = null,
  floorPrice = null,
) => {
  return getColor(matchPrice)(() => {
    if (matchPrice === refPrice) {
      return '#ffcc24'
    }

    if (ceilingPrice && matchPrice === ceilingPrice) {
      return '#d34fff'
    }

    if (floorPrice && matchPrice === floorPrice) {
      return '#00e5e4'
    }

    if (
      floorPrice
        ? matchPrice > floorPrice && matchPrice < refPrice
        : matchPrice < refPrice
    ) {
      return '#ff4752'
    }

    if (
      ceilingPrice
        ? matchPrice > refPrice && matchPrice < ceilingPrice
        : matchPrice > refPrice
    ) {
      return '#2de352'
    }

    return '#ececec'
  })
}

const getColor = (value) => (otherCondition) => {
  if (value === EMPTY_VALUE) {
    return '#ececec'
  }

  return otherCondition()
}

export const getColorByValueChart = (value) => {
  return getColor(value)(() => {
    if (value === 0) {
      return '#ececec'
    } else if (value > 0) {
      return '#59c761'
    }

    return '#ff5661'
  })
}

//Random color
const generateColor = () => {
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += COLOR_LETTERS[Math.floor(Math.random() * 16)]
  }
  return color
}
const getRGB = (hexa) => {
  return {
    r: parseInt(hexa.substring(1, 3), 16),
    g: parseInt(hexa.substring(3, 5), 16),
    b: parseInt(hexa.substring(5, 7), 16),
  }
}
const checkSafeDistance = (color1, color2, threshold = THRESHOLD) => {
  const { r: r1, g: g1, b: b1 } = getRGB(color1)
  const { r: r2, g: g2, b: b2 } = getRGB(color2)
  return (r1 - r2) ** 2 + (g1 - g2) ** 2 + (b1 - b2) ** 2 >= threshold ** 2
}
const checkSafeDistanceMultiple = (color, colorArray, threshold) => {
  return colorArray.every((colorItem) =>
    checkSafeDistance(color, colorItem, threshold),
  )
}

export const randomOneColor = ({
  ignoreColors = [],
  threshold = THRESHOLD,
}) => {
  const color = generateColor()

  if (ignoreColors.length) {
    if (checkSafeDistanceMultiple(color, ignoreColors, threshold)) {
      return color
    } else {
      return randomOneColor({ ignoreColors, threshold })
    }
  }

  return color
}

export const randomMultipleColor = ({
  ignoreColors = [],
  count,
  threshold = THRESHOLD,
}) => {
  const result = []

  for (let i = 0; i < count; i++) {
    const color = randomOneColor({
      ignoreColors: [...ignoreColors, ...result],
      threshold,
    })
    result.push(color)
  }

  return result
}

export const shadeColor = (hexColor, magnitude = 36) => {
  hexColor = hexColor.replace(`#`, ``)
  if (hexColor.length === 6) {
    const decimalColor = parseInt(hexColor, 16)
    let r = (decimalColor >> 16) + magnitude
    r > 255 && (r = 255)
    r < 0 && (r = 0)
    let g = (decimalColor & 0x0000ff) + magnitude
    g > 255 && (g = 255)
    g < 0 && (g = 0)
    let b = ((decimalColor >> 8) & 0x00ff) + magnitude
    b > 255 && (b = 255)
    b < 0 && (b = 0)
    return `#${(g | (b << 8) | (r << 16)).toString(16)}`
  } else {
    return hexColor
  }
}

export const hexToRgba = (hex, alpha) => {
  const { r, g, b, a } = hexToRgbaObject(hex, alpha)

  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + a + ')'
}

export const hexToRgbaObject = (hex, alpha) => {
  let r = 0,
    g = 0,
    b = 0,
    a = typeof alpha === 'number' && alpha >= 0 && alpha <= 1 ? alpha : 1

  if (hex.length === 4) {
    r = parseInt(hex.slice(1, 2), 16)
    g = parseInt(hex.slice(2, 3), 16)
    b = parseInt(hex.slice(3, 4), 16)
  }

  if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
  }

  return { r, g, b, a }
}

export const rgbaToAlpha = (a) => {
  if (a < 0) return 0
  if (a >= 0 && a <= 1) return a
  if (a > 1) {
    return ((a / 255) * 100) / 100
  }
  return 1
}

const percentageToHex = (percent) => {
  if (!typeof percent === 'number' || isNaN(percent)) return 'FF'
  if (percent < 0) percent = 0
  if (percent > 100) percent = 100
  const hex = Math.round((percent / 100) * 255).toString(16)
  return hex.length > 1 ? hex : 0 + hex
}

const hexAlphaToPercentage = (hexAlpha) => {
  if (hexAlpha.length > 2) return 100
  return Math.round((parseInt(hexAlpha, 16) / 255) * 100)
}

export const hexToHexAndAlphaPercentage = (hex) => {
  if (hex.length >= 9) {
    return {
      hex: hex.slice(0, 7),
      alpha: hexAlphaToPercentage(hex.slice(7, 9)),
    }
  } else {
    return {
      hex,
      alpha: 100,
    }
  }
}

export const hexAndAlphaPercentageToHex = (hex, alpha) => {
  if (hex.length >= 4 && hex.length < 7) {
    return hex.slice(0, 4)
  }
  if (hex.length >= 7) {
    return hex.slice(0, 7) + percentageToHex(alpha)
  }
}

// Color of Bond Price
export const getBondPriceColor = (price, refPrice) => {
  return getColor(Number(price))(() => {
    if (isNaN(refPrice)) return '#ececec'

    if (Number(price) === Number(refPrice)) {
      return '#ffcc24'
    }

    if (Number(price) < Number(refPrice)) {
      return '#ff4752'
    }

    if (Number(price) > Number(refPrice)) {
      return '#2de352'
    }

    return '#ececec'
  })
}

// Color of Overview Secondary
export const getColorOverview = (price, refPrice) => {
  return getColor(Number(price))(() => {
    if (isNaN(refPrice)) return '#ececec'

    if (Number(price) === Number(refPrice)) {
      return '#ffc31e'
    }

    if (Number(price) < Number(refPrice)) {
      return '#ff4752'
    }

    if (Number(price) > Number(refPrice)) {
      return '#59c761'
    }

    return '#ececec'
  })
}

// Color of table cell
const RED_CONFIG = 10
const GREEN_CONFIG = -2
const BLUE_CONFIG = 25
const WHITE_TEXT = [236, 236, 236]
const BLACK_TEXT = [47, 48, 50]

const getColorByVal = (val, minMaxValue) => {
  const color = scaleLinear(minMaxValue, ['#f9fbe7', '#33691e'])
  return color(val)
}

const getTextColor = (arrRGBValue) => {
  const [r, g, b] = arrRGBValue
  const [wtRed, wtGreen, wtBlue] = WHITE_TEXT
  const [btRed, btGreen, btBlue] = BLACK_TEXT
  const diff =
    (r - wtRed) ** 2 +
    (g - wtGreen) ** 2 +
    (b - wtBlue) ** 2 -
    ((r - btRed) ** 2 + (g - btGreen) ** 2 + (b - btBlue) ** 2)
  return diff < 0 ? '#2f3032' : '#ececec'
}

// Parse rgb string to [r,g,b]
const sliceRGBString = (rgbString) => {
  return rgbString.slice(4, rgbString.length - 1)
}

const getArrayRGBValue = (str) => {
  return sliceRGBString(str)
    .split(', ')
    .map((val) => +val)
}

export const getColorByTableCell = (val, minMaxValue = [0, 0]) => {
  const color = getColorByVal(val, minMaxValue)
  const arrRGBValue = getArrayRGBValue(color)
  const textColor = getTextColor(arrRGBValue)
  const redValue =
    arrRGBValue[0] > RED_CONFIG ? arrRGBValue[0] - RED_CONFIG : arrRGBValue[0]
  const greenValue =
    arrRGBValue[1] > GREEN_CONFIG
      ? arrRGBValue[1] - GREEN_CONFIG
      : arrRGBValue[1]
  const blueValue =
    arrRGBValue[2] > BLUE_CONFIG ? arrRGBValue[2] - BLUE_CONFIG : arrRGBValue[2]
  return {
    bg: `rgb(${redValue},${greenValue},${blueValue} )`,
    color: textColor,
  }
}
