import { createAsyncThunk } from '@reduxjs/toolkit'
import DailyReportService from '../../../../../../core/services/newsReport/DailyReportService'
import { handleExport } from '../../../../../utils/Export'
import { getTemplate } from '../../../template/store/thunk'

export const downloadNews = createAsyncThunk(
  'newsReport/dailyReport/setting/settingArea/downloadNews',
  async (data) => {
    const response = await DailyReportService.downloadNews(data)
    handleExport(response.data, response.filename)
  },
)

export const postTemplates = createAsyncThunk(
  'newsReport/dailyReport/setting/settingArea/postTemplates',
  async (params, { dispatch }) => {
    const res = await DailyReportService.postTemplates(params)
    if (res.success) {
      dispatch(getTemplate())
    }
  },
)

export const putTemplates = createAsyncThunk(
  'newsReport/dailyReport/setting/settingArea/putTemplates',
  async (params, { rejectWithValue, dispatch }) => {
    const res = await DailyReportService.putTemplates(params)
    if (res.success) {
      dispatch(getTemplate())
    }
  },
)
