import { useSelector } from 'react-redux'
import { PeersComparison } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { getChartNameByLocale } from '../../common/chartPeersComparison/helper'
import {
  selectCurrentMonth,
  selectCurrentYear,
  selectIsAcc,
  selectIsYearly,
} from '../steelStatistics/store/slice'
import { PEERS_COMPARISON_FILTER } from './constants'
import { selectCurrentCategory } from './store/slice'

export const PanelPeersComparison = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentYear = useSelector(selectCurrentYear)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentCategory = useSelector(selectCurrentCategory)
  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)
  const locale = useSelector((state) => state.i18n.locale)

  const nameScreen = UseI18n('sector.sectorSpecific.steel.STEEL')
  const tabName = UseI18n('sector.sectorSpecific.steel.STEEL_COIL_TAB')

  const currentCategoryName =
    currentCategory !== PEERS_COMPARISON_FILTER[0].value
      ? PEERS_COMPARISON_FILTER.find((item) => item.value === currentCategory)
          .name
      : tabName

  return (
    <Panel
      title="sector.sectorSpecific.steel.PEERS_COMPARISON"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={getChartNameByLocale(
        locale,
        isYearly,
        isAcc,
        currentCategoryName,
        currentMonth,
        currentYear,
      )}
      downloadJpgParams={{
        domId: 'coilPeersComparison',
        nameScreen: nameScreen,
        chartName: getChartNameByLocale(
          locale,
          isYearly,
          isAcc,
          currentCategoryName,
          currentMonth,
          currentYear,
        ),
        tabName: tabName,
      }}
    >
      <PeersComparison
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
