import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Z_INDEX } from '../../constants/Common'
import { getInnerHeight } from '../../utils/Device'
import EventEmitter, {
  COMPONENT_RESIZE,
  DROPDOWN_RESET_POSITION,
  SCROLL_COMPONENT,
} from '../../utils/EventEmitter'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import style from './index.module.css'
import withWrapperDropdown from './withWrapperDropdown'

export const positionDropdown = {
  TOP_CENTER: 'top-center',
  TOP_LEFT_LEFT: 'top-left-left',
  TOP_LEFT_RIGHT: 'top-left-right',
  TOP_RIGHT_LEFT: 'top-right-left',
  TOP_RIGHT_RIGHT: 'top-right-right',
  BOTTOM_CENTER: 'bottom-center',
  BOTTOM_LEFT_LEFT: 'bottom-left-left',
  BOTTOM_LEFT_RIGHT: 'bottom-left-right',
  BOTTOM_RIGHT_LEFT: 'bottom-right-left',
  BOTTOM_RIGHT_RIGHT: 'bottom-right-right',
  LEFT_CENTER: 'left-center',
  LEFT_TOP: 'left-top',
  LEFT_BOTTOM: 'left-bottom',
  RIGHT_CENTER: 'right-center',
  RIGHT_TOP: 'right-top',
  RIGHT_BOTTOM: 'right-bottom',
}

const stylePosition = ['top', 'right', 'bottom', 'left']

const Dropdown = ({
  id,
  children,
  parentRef,
  position,
  isShow,
  setIsShow,
  scrollId,
  zIndex,
  appendStyle,
  dropdownSpace,
  customPosition,
  isAutoPosition,
  className,
}) => {
  const dropdownRef = useRef()

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [dropdownNode, setDropdownNode] = useState()
  const [resetPosition, setResetPosition] = useState()
  const [autoPosition, setAutoPosition] = useState(false)

  useEffect(() => {
    const newDropdown = document.createElement('div')
    newDropdown.className = style.dropdownContainer
    newDropdown.style.zIndex = zIndex
    if (appendStyle.pointerEvents) {
      newDropdown.style.pointerEvents = appendStyle.pointerEvents
    }
    document.body.appendChild(newDropdown)
    setDropdownNode(newDropdown)
    return () => document.body.removeChild(newDropdown)
  }, [])

  useEffect(() => {
    if (dropdownNode)
      EventEmitter.subscribe(DROPDOWN_RESET_POSITION, funcResetPosition)
    return () =>
      EventEmitter.unsubscribe(DROPDOWN_RESET_POSITION, funcResetPosition)
  }, [dropdownNode])

  useEffect(() => {
    if (isShow) setResetPosition({})
  }, [isShow])

  useEffect(() => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    if (dropdownNode) dropdownNode.style.opacity = 1
  }, [resetPosition])

  useEffect(() => {
    const scrollComponent = (data) => {
      if (data && scrollId && data === scrollId) {
        setIsShow(false)
      }
    }

    EventEmitter.subscribe(SCROLL_COMPONENT, scrollComponent)

    return () => {
      EventEmitter.unsubscribe(SCROLL_COMPONENT, scrollComponent)
    }
  }, [])

  useEffect(() => {
    const handleResize = () => EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    EventEmitter.subscribe(COMPONENT_RESIZE, handleResize)
    return () => EventEmitter.unsubscribe(COMPONENT_RESIZE, handleResize)
  }, [])

  const funcResetPosition = () => {
    if (dropdownNode && parentRef.current) {
      const { top, left, bottom, right } =
        parentRef.current.getBoundingClientRect()
      const parentTop = formatWithZoom(top),
        parentLeft = formatWithZoom(left),
        parentBottom = formatWithZoom(bottom),
        parentRight = formatWithZoom(right)
      let nodePosition = {}
      switch (position) {
        case positionDropdown.TOP_CENTER:
          nodePosition.top = parentTop - dropdownRef.current?.offsetHeight
          nodePosition.left =
            parentLeft -
            (dropdownRef.current?.offsetWidth -
              parentRef.current?.offsetWidth) /
              2
          break
        case positionDropdown.TOP_LEFT_LEFT:
          nodePosition.top = parentTop - dropdownRef.current?.offsetHeight
          nodePosition.left = parentLeft - dropdownRef.current?.offsetWidth
          break
        case positionDropdown.TOP_LEFT_RIGHT:
          nodePosition.top = parentTop - dropdownRef.current?.offsetHeight
          nodePosition.left = parentLeft
          break
        case positionDropdown.TOP_RIGHT_LEFT:
          nodePosition.top =
            parentTop - dropdownRef.current?.offsetHeight - dropdownSpace
          nodePosition.left = parentRight - dropdownRef.current?.offsetWidth
          if (isAutoPosition) {
            setAutoPosition({ isTop: true })

            if (nodePosition.top < 0) {
              nodePosition.top = parentBottom + dropdownSpace
              setAutoPosition({ isTop: false })
            }
          }
          break
        case positionDropdown.TOP_RIGHT_RIGHT:
          nodePosition.top = parentTop - dropdownRef.current?.offsetHeight
          nodePosition.left = parentRight
          break
        case positionDropdown.BOTTOM_CENTER:
          nodePosition.top = parentBottom + dropdownSpace
          nodePosition.left =
            parentLeft -
            (dropdownRef.current?.offsetWidth -
              parentRef.current?.offsetWidth) /
              2
          break
        case positionDropdown.BOTTOM_LEFT_LEFT:
          nodePosition.top = parentBottom + dropdownSpace
          nodePosition.left = parentLeft - dropdownRef.current?.offsetWidth
          break
        case positionDropdown.BOTTOM_LEFT_RIGHT:
          nodePosition.top = parentBottom + dropdownSpace
          nodePosition.left = parentLeft
          break
        case positionDropdown.BOTTOM_RIGHT_LEFT:
          nodePosition.top = parentBottom + dropdownSpace
          nodePosition.left = parentRight - dropdownRef.current?.offsetWidth

          if (isAutoPosition) {
            setAutoPosition({ isTop: false })

            if (
              nodePosition.top + dropdownRef.current?.offsetHeight >
              getInnerHeight()
            ) {
              nodePosition.top =
                parentTop - dropdownRef.current?.offsetHeight - dropdownSpace
              setAutoPosition({ isTop: true })
            }
          }

          break
        case positionDropdown.BOTTOM_RIGHT_RIGHT:
          nodePosition.top = parentBottom + dropdownSpace
          nodePosition.left = parentRight
          break
        case positionDropdown.LEFT_CENTER:
          nodePosition.top =
            parentTop -
            (dropdownRef.current?.offsetHeight -
              parentRef.current?.offsetHeight) /
              2
          nodePosition.left = parentLeft - dropdownRef.current?.offsetWidth
          break
        case positionDropdown.LEFT_TOP:
          nodePosition.top = parentTop
          nodePosition.left = parentLeft - dropdownRef.current?.offsetWidth
          break
        case positionDropdown.LEFT_BOTTOM:
          nodePosition.top = parentBottom - dropdownRef.current?.offsetHeight
          nodePosition.left = parentLeft - dropdownRef.current?.offsetWidth
          break
        case positionDropdown.RIGHT_CENTER:
          nodePosition.top =
            parentTop -
            (dropdownRef.current?.offsetHeight -
              parentRef.current?.offsetHeight) /
              2
          nodePosition.left = parentRight
          break
        case positionDropdown.RIGHT_TOP:
          nodePosition.top = parentTop
          nodePosition.left = parentRight
          break
        case positionDropdown.RIGHT_BOTTOM:
          nodePosition.top = parentBottom - dropdownRef.current?.offsetHeight
          nodePosition.left = parentRight
          break
        default:
          nodePosition.top = parentTop - dropdownRef.current?.offsetHeight
          nodePosition.left = parentLeft
      }

      if (customPosition) {
        nodePosition = customPosition(nodePosition)
      }

      Object.keys(nodePosition).forEach((key) => {
        if (
          stylePosition.includes(key) &&
          nodePosition[key] &&
          typeof Number(nodePosition[key]) === 'number'
        ) {
          dropdownNode.style[key] = nodePosition[key] + 'px'
        }
      })
    }
  }

  return (
    <>
      {dropdownNode &&
        isShow &&
        createPortal(
          <div
            id={id}
            ref={dropdownRef}
            style={appendStyle}
            className={className}
          >
            {isAutoPosition ? children(autoPosition) : children}
          </div>,
          dropdownNode,
        )}
    </>
  )
}

Dropdown.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  parentRef: PropTypes.object.isRequired,
  position: PropTypes.string,
  isShow: PropTypes.bool,
  setIsShow: PropTypes.func,
  zIndex: PropTypes.number,
  appendStyle: PropTypes.object,
  dropdownSpace: PropTypes.number,
  className: PropTypes.string,
}

Dropdown.defaultProps = {
  position: positionDropdown.BOTTOM_LEFT_RIGHT,
  isShow: true,
  setIsShow: () => {},
  zIndex: Z_INDEX.DROPDOWN,
  appendStyle: {},
  dropdownSpace: 0,
  className: '',
  id: '',
}

export default withWrapperDropdown(Dropdown)
