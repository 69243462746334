import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../common/html/Span'
import Popup from '../common/popup'
import { zIndexOverlayPopup } from './constants'
import style from './index.module.css'
import {
  resetWatchlistStore,
  selectIsShowPopup,
  selectIsShowWatchlistDetail,
  setIsShowPopup,
  setIsShowWatchlistDetail,
} from './store/slice'
import { getWatchListThunk } from './store/thunk'
import WatchlistDetail from './watchlistDetail'
import WatchlistTable from './watchlistTable'

const MyWatchlist = () => {
  const dispatch = useDispatch()

  const isShowDetail = useSelector(selectIsShowWatchlistDetail)
  const [watchlistId, setWatchlistId] = useState(null)

  useEffect(() => {
    dispatch(getWatchListThunk())
  }, [dispatch])

  useEffect(() => {
    return () => dispatch(resetWatchlistStore())
  }, [dispatch])

  const handleHidePopup = () => dispatch(setIsShowPopup(false))

  const handleShowDetail = (watchlistDetailId) => {
    setWatchlistId(watchlistDetailId)
    dispatch(setIsShowWatchlistDetail(true))
  }

  const handleHideDetail = () => {
    dispatch(setIsShowWatchlistDetail(false))
  }

  return (
    <Popup zIndexOverlay={zIndexOverlayPopup.watchlist}>
      <div
        className={`modal modal-large watchlist-page ${style.watchlistContainer}`}
      >
        <div className="modal-title">
          <h3>
            <i className="icon-folder-bold" />
            <Span>
              <Translate value="watchlist.common.TITLE" />
            </Span>
          </h3>
          <a onClick={handleHidePopup} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        {!isShowDetail ? (
          <WatchlistTable handleShowDetail={handleShowDetail} />
        ) : (
          <WatchlistDetail
            watchlistId={watchlistId}
            handleHideDetail={handleHideDetail}
          />
        )}
      </div>
    </Popup>
  )
}

const MyWatchlistPopup = () => {
  const isShowPopup = useSelector(selectIsShowPopup)

  return <>{isShowPopup && <MyWatchlist />}</>
}

export default MyWatchlistPopup
