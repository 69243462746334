import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import { STACK_COLORS } from '../../constants'
import { getTableMappingData } from '../panelGDPStructure/store/slice'
import { getTooltipContentData, renderListFooter } from './helper'
import style from './index.module.css'

const SETTINGS = {
  yTickNum: 5,
}

const ChartComponent = ({ data, width, height, keyXAxis, stackKey }) => {
  const GDPTypeName = useSelector(getTableMappingData)

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, stackKey, GDPTypeName).map(
          (item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <Span
                  className={style.tooltipContentKey}
                  style={{ fontSize: 11 }}
                >
                  {item.label ? <Translate value={item.label} /> : ''}:
                </Span>
                <Span
                  style={{ marginLeft: 25, fontSize: 11 }}
                  className={`${style.toolTipContentValue} ${
                    typeof item.value === 'number' &&
                    item.value < 0 &&
                    style.redColor
                  }`}
                >
                  {formatVal(item.value)}%
                </Span>
              </div>
            )
          },
        )}
      </>
    )
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                margin={{ ...MARGIN_RECHARTS, left: 12 }}
                yAxis={[
                  {
                    id: 'left',
                    keys: stackKey,
                    orientation: 'left',
                    isStackedBar: true,
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                    unitYAxis: '%',
                  },
                ]}
                hasBarChart
                renderCustomTooltip={renderTooltip}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis
              >
                {stackKey.length > 0 &&
                  stackKey.map((item, index) => {
                    return (
                      <Bar
                        key={index}
                        barSize={getColumnSizeInBarChart(width, data.length)}
                        stackId="stackId"
                        dataKey={item}
                        fill={STACK_COLORS[stackKey.length][index]}
                        yAxisId="left"
                        isAnimationActive={false}
                      />
                    )
                  })}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={renderListFooter(stackKey, GDPTypeName)}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
