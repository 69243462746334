import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'

export const renderDate = (activeTab, locale, date, timeZone) => {
  if (activeTab === 'Daily' && locale === 'en') {
    let myArray = date.split('-')
    let temp = myArray[0]
    myArray[0] = myArray[1]?.length === 1 ? `0${myArray[1]}` : myArray[1]
    myArray[1] = temp
    return formatDateTime(date, FORMAT.DATE, 'en', timeZone)
  }

  if (activeTab === 'Weekly') {
    let myArray = date.split('/')
    let temp = myArray[0]
    myArray[0] = myArray[1]?.length === 1 ? `0${myArray[1]}` : myArray[1]
    myArray[1] = temp

    return formatDateTime(date, FORMAT.DATE, locale, timeZone)
  }

  if (activeTab === 'Daily') {
    let myArray = date.split('-')
    myArray[1] = myArray[1]?.length === 1 ? `0${myArray[1]}` : myArray[1]

    return formatDateTime(
      myArray.toString().replaceAll(',', '/'),
      FORMAT.DATE,
      'vi',
    )
  }

  if (activeTab === 'Monthly') {
    let myArray = date.split('-')
    myArray[0] = myArray[0]?.length === 1 ? `0${myArray[0]}` : myArray[0]
    return myArray.toString().replaceAll(',', '/')
  }

  if (activeTab === 'Calendar') {
    return formatDateTime(date, FORMAT.DATE, locale, timeZone)
  }

  return date
}

const LiquidityChartTooltip = ({ dataChart }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {dataChart.date}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.MATCHED_1" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalMatchValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.DEAL_1" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalDealValue, true)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.AVERAGE_1" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.avg1Day, true)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

LiquidityChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    totalMatchValue: PropTypes.number.isRequired,
    totalDealValue: PropTypes.number.isRequired,
    avg1Day: PropTypes.number.isRequired,
  }).isRequired,
}

export default LiquidityChartTooltip
