import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import { formatDataTable, formatDataTableLending } from '../helper'
import {
  getCreditRatio,
  getDebtDetailsData,
  getDebtRelationInBsData,
  getDebtStatisticData,
  getScaleOfDebtByObjects,
  getTooltipLendingCompany,
  getTooltipLoanCompany,
} from './thunk'

export const keys = {
  DEBT_RELATION_IN_BALANCE: 'debtRelationInBalance',
  SCALE_OF_DEBT_BY_OBJECTS: 'scaleOfDebtByObjects',
  DEBT_STATISTIC: 'debtStatistic',
  CREDIT_RATIO: 'creditRatio',
  DEBT_DETAIL: 'debtDetail',
  LENDING_COM: 'lendingCom',
  LOAN_COM: 'loanCom',
}

const initialState = {
  loading: {
    [keys.DEBT_RELATION_IN_BALANCE]: true,
    [keys.SCALE_OF_DEBT_BY_OBJECTS]: true,
    [keys.DEBT_STATISTIC]: true,
    [keys.CREDIT_RATIO]: true,
    [keys.DEBT_DETAIL]: true,
    [keys.LENDING_COM]: true,
    [keys.LOAN_COM]: true,
  },
  filterDebtAndLoan: TIME_FREQUENCY.YEARLY,
  data: {
    [keys.DEBT_RELATION_IN_BALANCE]: {
      data: [],
      list: ['totalDebt', 'ownerEquity', 'ratio', 'tts'],
    },
    [keys.SCALE_OF_DEBT_BY_OBJECTS]: {
      data: [],
    },
    [keys.DEBT_STATISTIC]: {
      data: [],
    },
    [keys.CREDIT_RATIO]: {
      data: [],
    },
    [keys.LENDING_COM]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
    },
    [keys.LOAN_COM]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
    },
    [keys.DEBT_DETAIL]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
      initialData: [],
    },
  },
  filter: {
    [keys.BOND_ISSUE_INFORMATION]: {
      CompanyStatus: [15],
      CompanyCollateral: 'All',
      Related: true,
    },

    [keys.DEBT_STATISTIC]: {
      year: new Date().getFullYear(),
      quarter: 1,
    },

    [keys.DEBT_DETAIL]: {
      isFirst: true,
    },
  },
}

export const slice = createSlice({
  name: 'bond/corporateBond/debtAndLoan',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } = state.data[keys.DEBT_DETAIL]

      const idsSorted = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      )

      const idsFromProps = idsSorted.filter((item) => typeof item === 'number')

      const newIds = []

      idsFromProps.forEach((item) => {
        newIds.push(item)

        const idsByParent = idsSorted.filter(
          (id) =>
            typeof id === 'string' && parseInt(id.split('-')?.[1]) === item,
        )

        idsByParent.forEach((id) => newIds.push(id))
      })

      const newIdsWithTotal = newIds.filter((item) => item !== 444)

      state.data[keys.DEBT_DETAIL] = {
        ...state.data[keys.DEBT_DETAIL],
        ids: !!newIdsWithTotal.length ? [...newIdsWithTotal, 444] : [],
      }
    },
    setRowsCollapse: (state, action) => {
      state.data[keys.DEBT_DETAIL] = {
        ...state.data[keys.DEBT_DETAIL],
        rowsCollapse: action.payload,
      }
    },
    changeFilter: (state, action) => {
      state.filter[action.payload.label] = {
        ...state.filter[action.payload.label],
        [action.payload.key]: action.payload.value,
      }
    },
    changeFilterDebtAndLoan: (state, action) => {
      state.filterDebtAndLoan = action.payload.value
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getDebtRelationInBsData.pending, (state) => {
      state.loading[keys.DEBT_RELATION_IN_BALANCE] = true
    })
    builder.addCase(getDebtRelationInBsData.fulfilled, (state, action) => {
      state.data[keys.DEBT_RELATION_IN_BALANCE].data = action.payload.sort(
        (a, b) =>
          state.filterDebtAndLoan === TIME_FREQUENCY.QUARTER
            ? a?.realYear === b?.realYear
              ? a?.realQuarter - b?.realQuarter
              : a?.realYear - b?.realYear
            : a?.realYear - b?.realYear,
      )
      state.loading[keys.DEBT_RELATION_IN_BALANCE] = false
    })
    builder.addCase(getDebtRelationInBsData.rejected, (state, action) => {
      state.loading[keys.DEBT_RELATION_IN_BALANCE] = action.payload
    })

    builder.addCase(getScaleOfDebtByObjects.pending, (state) => {
      state.loading[keys.SCALE_OF_DEBT_BY_OBJECTS] = true
    })
    builder.addCase(getScaleOfDebtByObjects.fulfilled, (state, action) => {
      state.data[keys.SCALE_OF_DEBT_BY_OBJECTS].data = action.payload
      state.loading[keys.SCALE_OF_DEBT_BY_OBJECTS] = false
    })
    builder.addCase(getScaleOfDebtByObjects.rejected, (state, action) => {
      state.loading[keys.SCALE_OF_DEBT_BY_OBJECTS] = action.payload
    })

    builder.addCase(getDebtStatisticData.pending, (state) => {
      state.loading[keys.DEBT_STATISTIC] = true
    })
    builder.addCase(getDebtStatisticData.fulfilled, (state, action) => {
      state.data[keys.DEBT_STATISTIC].data = action.payload
      state.loading[keys.DEBT_STATISTIC] = false
    })
    builder.addCase(getDebtStatisticData.rejected, (state, action) => {
      state.loading[keys.DEBT_STATISTIC] = action.payload
    })

    builder.addCase(getCreditRatio.pending, (state) => {
      state.loading[keys.CREDIT_RATIO] = true
    })
    builder.addCase(getCreditRatio.fulfilled, (state, action) => {
      const formattedData = action.payload
      state.data[keys.CREDIT_RATIO].data = formattedData
      state.loading[keys.CREDIT_RATIO] = false
    })
    builder.addCase(getCreditRatio.rejected, (state, action) => {
      state.loading[keys.CREDIT_RATIO] = action.payload
    })

    builder.addCase(getTooltipLendingCompany.pending, (state) => {
      state.loading[keys.LENDING_COM] = true
    })
    builder.addCase(getTooltipLendingCompany.fulfilled, (state, action) => {
      const data = formatDataTableLending(action.payload)
      state.data[keys.LENDING_COM] = {
        ...state.data[keys.LENDING_COM],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
      }
      state.loading[keys.LENDING_COM] = false
    })
    builder.addCase(getTooltipLendingCompany.rejected, (state, action) => {
      state.loading[keys.LENDING_COM] = action.payload
    })

    builder.addCase(getTooltipLoanCompany.pending, (state) => {
      state.loading[keys.LOAN_COM] = true
    })
    builder.addCase(getTooltipLoanCompany.fulfilled, (state, action) => {
      const data = formatDataTableLending(action.payload)
      state.data[keys.LOAN_COM] = {
        ...state.data[keys.LOAN_COM],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
      }
      state.loading[keys.LOAN_COM] = false
    })
    builder.addCase(getTooltipLoanCompany.rejected, (state, action) => {
      state.loading[keys.LOAN_COM] = action.payload
    })

    builder.addCase(getDebtDetailsData.pending, (state) => {
      state.loading[keys.DEBT_DETAIL] = true
    })
    builder.addCase(getDebtDetailsData.fulfilled, (state, action) => {
      const dataFormatted = formatDataTable(action.payload)

      const ids = dataFormatted.map((item) => item.id)

      state.data[keys.DEBT_DETAIL] = {
        ...state.data[keys.DEBT_DETAIL],
        data: dataFormatted,
        ids,
        initialIds: ids,
        dataById: keyBy(dataFormatted, 'id'),
        levels: dataFormatted,
        initialData: action.payload.data,
      }
      state.loading[keys.DEBT_DETAIL] = false
    })
    builder.addCase(getDebtDetailsData.rejected, (state, action) => {
      state.loading[keys.DEBT_DETAIL] = action.payload
    })
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

// Bond Issue Information
export const selectFilterBondIssueInformation = (state) =>
  state[slice.name].filter[keys.BOND_ISSUE_INFORMATION]
export const selectDataBondIssueInformation = (state) =>
  state[slice.name].data[keys.BOND_ISSUE_INFORMATION]
export const selectItemDataTable = (state) =>
  state[slice.name].data[keys.BOND_ISSUE_INFORMATION].dataById

// Debt and Loan
export const selectFilterDebtAndLoan = (state) =>
  state[slice.name].filterDebtAndLoan

// Debt Relation In Balance
export const selectDataDebtRelationInBalance = (state) =>
  state[slice.name].data[keys.DEBT_RELATION_IN_BALANCE]

// Scale Of Debt by Objects
export const selectScaleOfDebtByObjects = (state) =>
  state[slice.name].data[keys.SCALE_OF_DEBT_BY_OBJECTS]

// Debt Statistic
export const selectFilterDebtStatistic = (state) =>
  state[slice.name].filter[keys.DEBT_STATISTIC]
export const selectDebtStatistic = (state) =>
  state[slice.name].data[keys.DEBT_STATISTIC]

// Credit Ratio
export const selectCreditRatio = (state) =>
  state[slice.name].data[keys.CREDIT_RATIO]

// Lending Com
export const selectLendingComData = (state) =>
  state[slice.name].data[keys.LENDING_COM]
export const selectDataTableByIdLendingCom = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.LENDING_COM].dataById[id], attr)
// Lending Com
export const selectLoanComData = (state) =>
  state[slice.name].data[keys.LOAN_COM]
export const selectDataTableByIdLoanCom = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.LOAN_COM].dataById[id], attr)

// Debt Details
export const selectDebtDetailsData = (state) =>
  state[slice.name].data[keys.DEBT_DETAIL]
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.DEBT_DETAIL].dataById[id], attr)
export const selectFilterDetail = (state) =>
  state[slice.name].filter[keys.DEBT_DETAIL]

export const {
  resetStore,
  changeFilter,
  sort,
  changeFilterDebtAndLoan,
  setRowsCollapse,
} = slice.actions

register(slice.name, slice.reducer)
