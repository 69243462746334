import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import { VALUE_ORDER } from '../../../constants'
import style from '../../style.module.css'
import { changeIsGetData } from '../../tableSector/store/slice'
import { changeOrderValue, selectOrderValue } from '../store/slice'

export const RadioGroup = () => {
  const dispatch = useDispatch()

  const listRadio = [
    {
      value: VALUE_ORDER.RELATIVE,
      label: 'sector.sectorStatistics.overview.RELATIVE',
    },
    {
      value: VALUE_ORDER.ABSOLUTE,
      label: 'sector.sectorStatistics.overview.ABSOLUTE',
    },
  ]

  const orderValue = useSelector(selectOrderValue)

  const handleChangeRadio = (val) => {
    dispatch(changeOrderValue(val))
    dispatch(changeIsGetData(true))
  }

  return (
    <ul className={`list-check d-flex align-center mt-0 ${style.listRadio}`}>
      {listRadio.map((item) => (
        <li key={item.value} className="mb-0">
          <label>
            <input
              type="radio"
              className="radiobox radiobox2"
              checked={item.value === orderValue}
              onChange={() => handleChangeRadio(item.value)}
            />
            <Span
              style={{ fontSize: 12 }}
              className={item.value === orderValue ? style.active : ''}
            >
              <Translate value={item.label} />
            </Span>
          </label>
        </li>
      ))}
    </ul>
  )
}
