import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import PopupAddEquation from '../../popup/popupAddEquation'
import style from '../style.module.css'
import { AddEquationItem } from './AddEquationItem'

export const AddEquations = ({ viewType, equations, onApply }) => {
  const [isShow, setIsShow] = useState(false)
  const [editEquation, setEditEquation] = useState()

  const handleEdit = (equationKey) => {
    setEditEquation(equations[equationKey])
    setIsShow(true)
  }

  const handleAdd = () => {
    setEditEquation()
    setIsShow(true)
  }

  const handleApply = (data) => {
    onApply(data)
    setEditEquation()
  }

  useEffect(() => {
    if (!isShow) {
      setEditEquation()
    }
  }, [isShow])

  return (
    <div className={style.equationContainer}>
      <SizeTracker>
        {(size) => (
          <>
            <div className={style.calculation} onClick={handleAdd}>
              <i
                className="icon-plus"
                style={{
                  marginRight: 6,
                  marginLeft: 12,
                }}
              />
              <Translate value="financialChart.SUM_AVG_COUNT" />
            </div>
            {size.height && (
              <div
                style={{
                  padding: 16,
                  height: `calc(100% - ${size.height + 16 * 2}px)`,
                }}
              >
                <div className="h-100">
                  <ScrollComponent>
                    {!!Object.keys(equations).length && (
                      <div
                        key={Object.keys(equations).length}
                        style={{
                          padding: '8px 8px 1px',
                          backgroundColor: '#edf0f3',
                          borderRadius: 4,
                        }}
                      >
                        {Object.keys(equations).map((key) => (
                          <AddEquationItem
                            key={key}
                            viewType={viewType}
                            equation={equations[key]}
                            equationKey={key}
                            onEditEquation={handleEdit}
                          />
                        ))}
                      </div>
                    )}
                  </ScrollComponent>
                </div>
              </div>
            )}
          </>
        )}
      </SizeTracker>
      {isShow && (
        <PopupAddEquation
          setIsShow={setIsShow}
          viewType={viewType}
          equations={equations}
          equation={editEquation}
          onApply={handleApply}
        />
      )}
    </div>
  )
}

AddEquations.propTypes = {
  equations: PropTypes.object,
}

AddEquations.defaultProps = {
  equations: {},
}
