import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  checkAllPopupIndicator,
  removeIndicator,
  selectIndicatorByIdPopupIndicator,
  selectIndicatorPopupIndicator,
  selectIsEdit,
  selectIsGroup,
  uncheckAllPopupIndicator,
} from '../store/slice'

const CheckAllIndicator = () => {
  const dispatch = useDispatch()

  const indicator = useSelector(selectIndicatorPopupIndicator)
  const indicatorById = useSelector(selectIndicatorByIdPopupIndicator)
  const isEdit = useSelector(selectIsEdit)
  const isGroup = useSelector(selectIsGroup)

  const handleCheckAll = () => {
    dispatch(checkAllPopupIndicator())
  }

  const handleUncheckAll = () => {
    dispatch(uncheckAllPopupIndicator())
  }

  const removeTicker = () => {
    dispatch(removeIndicator())
  }

  const isHaveCheck = indicator.some((id) => indicatorById[id].check === true)

  return (
    <div className={`group-action ${isEdit && !isGroup ? style.disabled : ''}`}>
      {!isHaveCheck ? (
        <label className={`d-flex justify-content-center ${style.checkboxAll}`}>
          <input
            type="checkbox"
            className={`checkbox line ${style.inputCheckAll}`}
            onClick={handleCheckAll}
            readOnly
          />
        </label>
      ) : (
        <div className={`${style.btnMinus}`}>
          <button onClick={handleUncheckAll} className={style.pr6px}>
            <i className="icon-minus" />
          </button>
        </div>
      )}
      <button onClick={removeTicker}>
        <i className="icon-trash" />
      </button>
    </div>
  )
}

export default CheckAllIndicator
