import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { getTwoColorByValue } from '../../../../utils/Color'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

const importExportTooltip = (valueTooltip) => {
  return (
    <div className={style.chartTooltip}>
      <div style={{ fontStyle: 'italic' }} className="mb-8">
        <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
      </div>
      <table>
        <tbody>
          <tr className={style.trTable}>
            <td className={style.pdLabel}>
              <div className={style.mr12}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t(
                    'economy.importExport.importExport.overview.FOOTER_BALANCE_OF_TRADE',
                  )}
                </Span>
                :
              </div>
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
              className={`${style.pdLabel} ${style.fontWeight500}`}
            >
              <Span
                style={{
                  fontSize: 11,
                  color: getTwoColorByValue(valueTooltip.balance_of_trade),
                }}
              >
                {formatVal(valueTooltip.balance_of_trade)}
                {` ${I18n.t('economy.importExport.importExport.BILLION_USD')}`}
              </Span>
            </td>
          </tr>
          <tr className={style.trTable}>
            <td className={style.pdLabel}>
              <div className={style.mr12}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t(
                    'economy.importExport.importExport.overview.FOOTER_EXPORT',
                  )}
                </Span>
                :
              </div>
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
              className={`${style.pdLabel} ${style.fontWeight500}`}
            >
              <Span
                style={{
                  fontSize: 11,
                  color: getTwoColorByValue(valueTooltip.export),
                }}
              >
                {formatVal(valueTooltip.export)}
                {` ${I18n.t('economy.importExport.importExport.BILLION_USD')}`}
              </Span>
            </td>
          </tr>
          <tr className={style.trTable}>
            <td className={style.pdLabel}>
              <div className={style.mr12}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t(
                    'economy.importExport.importExport.overview.FOOTER_IMPORT',
                  )}
                </Span>
                :
              </div>
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
              className={`${style.pdLabel} ${style.fontWeight500}`}
            >
              <Span
                style={{
                  fontSize: 11,
                  color: getTwoColorByValue(valueTooltip.import),
                }}
              >
                {formatVal(valueTooltip.import)}
                {` ${I18n.t('economy.importExport.importExport.BILLION_USD')}`}
              </Span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  barKeys,
}) => {
  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            hasBarChart
            yAxis={[
              {
                id: 'left',
                keys: barKeys,
                isBarChart: true,
                orientation: 'left',
                label: ` ${I18n.t(
                  'economy.importExport.importExport.overview.FOOTER_BALANCE_OF_TRADE',
                )} (${I18n.t(
                  'economy.importExport.importExport.BILLION_USD',
                )})`,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
              },
              {
                id: 'right',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'right',
                label: ` ${I18n.t(
                  'economy.importExport.importExport.IMPORT_EXPORT',
                )} (${I18n.t(
                  'economy.importExport.importExport.BILLION_USD',
                )})`,
                labelPosition: AXIS_LABEL_POSITION.RIGHT,
              },
            ]}
            renderCustomTooltip={(payload) => importExportTooltip(payload)}
            timeFrame={TIME_RANGES.CUSTOM}
            isNotFormatXAxis
          >
            <>
              <Bar
                yAxisId="left"
                dataKey={'balance_of_trade'}
                barSize={getColumnSizeInBarChart(width, data.length, 25)}
                isAnimationActive={false}
                fill="#0096eb"
              />
              <Line
                yAxisId="right"
                dataKey="export"
                type="linear"
                stroke="#ecffdf"
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={2}
              />
              <Line
                yAxisId="right"
                dataKey="import"
                type="linear"
                stroke="#f7b80c"
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={2}
              />
            </>
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
