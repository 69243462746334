import React from 'react'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import Content from './Content'

const FDIOverview = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title={'economy.fdi.overview.OVERVIEW'} />
            </div>
            {size.height && (
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default FDIOverview
