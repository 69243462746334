import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import Dropdown from '../../../../../common/dropdown'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { BOND_STATUS_OPTIONS } from '../constants'
import {
  changeFilter,
  keys,
  selectFilterBondIssueInformation,
} from '../store/slice'
import style from './index.module.css'

export const FilterCompanyStatus = ({ width }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const { CompanyStatus } = useSelector(selectFilterBondIssueInformation)

  const handleChangeBondStatus = (value, checked) => {
    if (value === 'All' && checked === true) {
      dispatch(
        changeFilter({
          label: keys.BOND_ISSUE_INFORMATION,
          key: 'CompanyStatus',
          value: ['All', 15, 16, 17],
        }),
      )
      return
    }
    if (value === 'All' && checked === false) {
      dispatch(
        changeFilter({
          label: keys.BOND_ISSUE_INFORMATION,
          key: 'CompanyStatus',
          value: [],
        }),
      )
      return
    }
    if (!CompanyStatus.includes(value)) {
      dispatch(
        changeFilter({
          label: keys.BOND_ISSUE_INFORMATION,
          key: 'CompanyStatus',
          value: [...CompanyStatus, value],
        }),
      )
      return
    }
    dispatch(
      changeFilter({
        label: keys.BOND_ISSUE_INFORMATION,
        key: 'CompanyStatus',
        value: CompanyStatus.filter((item) => item !== value),
      }),
    )
  }

  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleToggle = () => {
    setIsShowDropdown((prev) => !prev)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Span
        onClick={handleToggle}
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        className={`search-inbox ${style.displayText}`}
      >
        <TextEllipsisStaticLine
          val={
            CompanyStatus.length > 3
              ? I18n.t('common.ALL')
              : BOND_STATUS_OPTIONS.filter((item) =>
                  CompanyStatus.filter((item) => item !== 'All').includes(
                    item.value,
                  ),
                )
                  .map((item) => I18n.t(item.name))
                  .toString()
          }
        />
      </Span>
      <button type="button" onClick={handleToggle}>
        <i
          className={`${
            !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
          } ${style.iconArrow}`}
        />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearchCompany}`}
              style={{ width }}
            >
              <ul className="list-check">
                {BOND_STATUS_OPTIONS.map((item, index) => {
                  return (
                    <li key={`key-${index}`}>
                      <a>
                        <label
                          className="d-flex ali-center"
                          style={{
                            cursor: item.ticker ? 'pointer' : 'default',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox mr-8"
                            checked={
                              CompanyStatus.includes(item.value) ?? false
                            }
                            onChange={(e) =>
                              handleChangeBondStatus(
                                item.value,
                                e.target.checked,
                              )
                            }
                          />

                          <TextEllipsis
                            text={item.name}
                            zIndexTooltip={999}
                            isI18n={true}
                          />
                        </label>
                      </a>
                    </li>
                  )
                })}
              </ul>
              {/* </ScrollComponent> */}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
