import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import {
  changeFilterLocal,
  changeFinancialFilter,
  changeStatement,
  selectFilterLocal,
  selectFinancialFilter,
  selectStatement,
} from '../store/slice'
import style from './index.module.css'

export const FilterInputRatioItem = ({ data, disabled }) => {
  const dispatch = useDispatch()

  // Use selector
  const statement = useSelector(selectStatement)
  const filterLocal = useSelector(selectFilterLocal)
  const financialFilter = useSelector(selectFinancialFilter)

  // Get data
  const getValue = () => {
    if (data.field === 'statement') {
      return statement.value
    } else if (data.field === 'currency') {
      return filterLocal.currency.value
    } else {
      return financialFilter[data.field].value
    }
  }

  // Actions
  const onRadioClick = (item) => {
    if (data.field === 'statement') {
      dispatch(changeStatement(item))
    } else if (data.field === 'currency') {
      dispatch(changeFilterLocal({ field: data.field, option: item }))
    } else {
      dispatch(changeFinancialFilter({ field: data.field, option: item }))
    }
  }

  return (
    <div className={`input-dropdown ${style['input-dropdown']}`}>
      <Span style={{ fontSize: 10 }} className={style['title-dropdown']}>
        <Translate value={data.title} />
      </Span>
      <div className={style['pl-8']}>
        <ul
          className={`list-check ${
            data.layout === 'vertical' && 'align-center'
          }`}
        >
          {data.options.map((item, index) => (
            <li
              key={item.value}
              className={`mb-0 ${data.layout === 'vertical' && 'mt-0 w-50'}`}
            >
              <label className="fs-12">
                <div>
                  <input
                    id={`${data.field}-${item.value}`}
                    type="radio"
                    className="radiobox radiobox2"
                    name={data.field}
                    value={item.value}
                    defaultChecked={item.value === getValue()}
                    onClick={(_) => onRadioClick(item)}
                    disabled={disabled(item.value)}
                    style={{ opacity: disabled(item.value) ? 0.5 : 1 }}
                  />
                </div>
                <Span
                  style={{
                    fontSize: 12,
                    color: item.value === getValue() ? '#2f9aee' : '',
                  }}
                >
                  <Translate value={item.name} />
                </Span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

FilterInputRatioItem.propTypes = {
  data: PropTypes.object.isRequired,
  disabled: PropTypes.func,
}
