import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { TIME_RANGE } from '../socialInvestment/constants'
import { selectTimeRange } from '../socialInvestment/store/slice'
import {
  LEFT_FRAME_OPTIONS,
  QUARTER_SELECTION_MONTHLY,
  QUARTER_SELECTION_QUARTERLY,
  RADIO_LIST,
  RIGHT_FRAME_OPTIONS,
  RIGHT_FRAME_OPTIONS_YEARLY,
  YEAR_OPTIONS,
} from './constants'
import style from './index.module.css'
import {
  getRadioValue,
  getTimeFrame,
  getTimeFrameYearly,
  getTypeChartValue,
  getYearDropdownValue,
  handleCurrentQuarter,
  handleRadioValue,
  handleTimeFrame,
  handleTimeFrameYearly,
  handleTypeChartValue,
  handleYearDropdownValue,
  selectCurrentQuarter,
  selectMaxDate,
} from './store/slice'

const Header = ({ isTotalSocialChart, width }) => {
  const dispatch = useDispatch()

  const typeChartValue = useSelector(getTypeChartValue)
  const timeFrame = useSelector(getTimeFrame)
  const timeFrameYearly = useSelector(getTimeFrameYearly)
  const radioValue = useSelector(getRadioValue)
  const yearDropdownValue = useSelector(getYearDropdownValue)
  const currentQuarter = useSelector(selectCurrentQuarter)
  const currentTimeRange = useSelector(selectTimeRange)
  const maxDate = useSelector(selectMaxDate)

  const isYearly = currentTimeRange === TIME_RANGE.YEARLY
  const isQuarterly = currentTimeRange === TIME_RANGE.QUARTERLY

  const currentYearDropdownValue = yearDropdownValue ?? maxDate?.year
  const currentSelectedQuarter = currentQuarter ?? maxDate?.quarter

  const changeTypeChartValue = (val) => {
    dispatch(handleTypeChartValue(val))
  }

  const changeTimeFrameValue = (tab) => {
    if (isYearly) {
      dispatch(handleTimeFrameYearly(tab.value))
    } else {
      dispatch(handleTimeFrame(tab.value))
    }
  }

  const changeCurrentQuarter = (tab) => {
    dispatch(handleCurrentQuarter(tab.value))
  }

  const handleChangeRadio = (radioValue) => {
    dispatch(handleRadioValue(radioValue))
  }

  const changeYear = (year) => {
    dispatch(handleYearDropdownValue(year))
  }

  return (
    <div>
      <div className="d-flex j-b align-center mb-8">
        <div className="d-flex">
          <div
            className={`${style.inputDropdown}`}
            style={{ maxWidth: width * 0.3, marginRight: 10 }}
          >
            <SelectCustom
              value={typeChartValue}
              isI18n={true}
              selectData={LEFT_FRAME_OPTIONS}
              handleChange={changeTypeChartValue}
            />
          </div>
          {isTotalSocialChart ? (
            <div className="d-flex align-center ">
              {RADIO_LIST.map(({ value, label }, index) => {
                return (
                  <div
                    key={value}
                    className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  ${index === 0 && style.mr28}
                  `}
                    onClick={() => handleChangeRadio(value)}
                  >
                    <input
                      type="radio"
                      className="radiobox radiobox2"
                      checked={value === radioValue}
                      onChange={() => handleChangeRadio(value)}
                    />
                    <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
                      <Translate value={label} />
                    </Span>
                  </div>
                )
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="d-flex">
          {!isTotalSocialChart && (
            <div style={{ width: 84, marginRight: 10 }}>
              <SelectCustom
                isI18n={true}
                selectData={YEAR_OPTIONS}
                value={currentYearDropdownValue}
                handleChange={changeYear}
                appendStyle={{ fontWeight: 'normal' }}
              />
            </div>
          )}
          {isTotalSocialChart ? (
            <DispatchActionTab
              data={isYearly ? RIGHT_FRAME_OPTIONS_YEARLY : RIGHT_FRAME_OPTIONS}
              activeTab={isYearly ? timeFrameYearly : timeFrame}
              onChangeTab={changeTimeFrameValue}
              itemStyle={{
                fontWeight: 500,
                fontSize: 11,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              widthComponent={0.4 * width}
            />
          ) : !isYearly ? (
            <DispatchActionTab
              data={
                isQuarterly
                  ? QUARTER_SELECTION_MONTHLY
                  : QUARTER_SELECTION_QUARTERLY
              }
              activeTab={currentSelectedQuarter}
              onChangeTab={changeCurrentQuarter}
              itemStyle={{
                fontWeight: 500,
                fontSize: 11,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              widthComponent={0.4 * width}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default Header
