import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'

const initialState = {
  date: new Date(),
  limitTime: {
    startDate: '',
    endDate: '',
    minDate: '',
    minYear: '',
    maxDate: '',
    maxYear: '',
  },
  loading: false,
}

const slice = createSlice({
  name: 'newsReport/dailyReport/setting/settingArea',
  initialState,
  reducers: {
    handleDate: (state, action) => {
      state.date = action.payload
    },
    handleLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { handleDate, handleLoading } = slice.actions

export const getDate = (state) => state[slice.name].date
export const getLimitTime = (state) => state[slice.name].limitTime
export const getLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
