import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { Table } from '../../../../../../common/table'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../../utils/Value'
import { selectQuarter, selectYear } from '../../store/slice'
import {
  selectDataDetailMetrics,
  selectDataDetailMetricsById,
  selectDetailMetricsRowIds,
  selectLoadingDetailMetrics,
  selectLoanSector,
  selectReCalcWiths,
} from '../store/slice'
import { getBankLoanDetailMetrics } from '../store/thunk'
import FirstColumnLevelTwo from './FirstColumnLevelTwo'
import { METRIC_CODE_KEY, MS_KEY, YOY_GROWTH_KEY } from './constants'
import { getLoanSectorKeyByValue } from './helper'

const LoanDetailMetric = () => {
  const dispatch = useDispatch()

  const [tickers, setTickers] = useState([])

  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectDataDetailMetrics)
  const rowIds = useSelector(selectDetailMetricsRowIds)
  const loading = useSelector(selectLoadingDetailMetrics)
  const reCalcWidths = useSelector(selectReCalcWiths)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)
  const loanSector = useSelector(selectLoanSector)

  useEffect(() => {
    if (basicInfo.organizationId && year && quarter) {
      dispatch(
        getBankLoanDetailMetrics({
          OrganizationId: basicInfo.organizationId,
          Year: year,
          Quarter: quarter,
          Indicator: loanSector,
        }),
      )
    }
  }, [year, quarter, loanSector, basicInfo.organizationId, locale])

  useEffect(() => {
    if (Object.keys(data).length && loanSector) {
      const dataByLoanSector = data[loanSector.toUpperCase()]
      const newTickers = Object.keys(dataByLoanSector)
        .sort((a, b) => dataByLoanSector[b] - dataByLoanSector[a])
        .slice(0, 10)
        .filter((ticker) => ticker !== basicInfo.ticker)
      setTickers([
        basicInfo?.ticker || basicInfo?.organizationShortName,
        ...newTickers,
      ])
    }
  }, [loanSector, data, basicInfo?.ticker])

  return (
    <div className="h-100">
      {loading ? (
        <Loading />
      ) : Object.keys(data).length && rowIds.length ? (
        <Table
          ids={rowIds}
          getDataFromRedux={selectDataDetailMetricsById}
          isLoading={loading}
          schema={['indicator', ...tickers].map((ticker, index) => {
            const colId = ticker
            return {
              colId,
              title:
                index === 0
                  ? 'corporate.businessModel.penetrationAnalysis.DETAIL_METRICS'
                  : ticker,
              isI18n: false,
              thStyle: {
                textAlign: index === 0 ? 'left' : 'right',
                minWidth: index === 0 ? 180 : 'unset',
              },
              tdStyle: {
                textAlign: index === 0 ? 'left' : 'right',
              },
              canCustomTd: index === 0,
              render: (val, rowId, { style, className }) => {
                if (rowId.startsWith(METRIC_CODE_KEY)) {
                  return index === 0 ? (
                    <td
                      style={{ ...style, width: '100%', maxWidth: 0 }}
                      className={className}
                    >
                      <TextEllipsis
                        text={`corporate.businessModel.penetrationAnalysis.loanSector.${getLoanSectorKeyByValue(
                          rowId,
                        )}`}
                      />
                    </td>
                  ) : (
                    formatVal(valDivBillion(val))
                  )
                }

                if (rowId.startsWith(YOY_GROWTH_KEY)) {
                  return index === 0 ? (
                    <FirstColumnLevelTwo
                      text="YOY_GROWTH"
                      existStyle={style}
                      className={className}
                    />
                  ) : (
                    valToPercent(val)
                  )
                }

                if (rowId.startsWith(MS_KEY)) {
                  return index === 0 ? (
                    <FirstColumnLevelTwo
                      text="MARKET_SHARE"
                      existStyle={style}
                      className={className}
                    />
                  ) : (
                    valToPercent(val)
                  )
                }

                return val
              },
            }
          })}
          isLargeHead
          reCalcWidths={reCalcWidths}
          stickyFirstColumn
          stickyBgColor="#212833"
          hasFooter={false}
          hasTooltip={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default LoanDetailMetric
