export const chartFillColor = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#1f669a',
  '#297ebd',
  '#f1ffe7',
]

export const indicatorMappingDisplayName = {
  nB38: 'sector.financialAnalysis.bank.assetQuality.TRADING',
  nB39: 'sector.financialAnalysis.bank.assetQuality.AGRICULTURE_FORESTRY',
  nB40: 'sector.financialAnalysis.bank.assetQuality.MANUFACTURING',
  nB45: 'sector.financialAnalysis.bank.assetQuality.CONSTRUCTION',
  nB46: 'sector.financialAnalysis.bank.assetQuality.PERSONAL_PUBLIC_SERVICE',
  nB55: 'sector.financialAnalysis.bank.assetQuality.WAREHOUSING',
  nB58: 'sector.financialAnalysis.bank.assetQuality.TRAINING_EDUCATION',
  nB61: 'sector.financialAnalysis.bank.assetQuality.ESTATE_AGENT',
  nB62: 'sector.financialAnalysis.bank.assetQuality.HOTEL_RESTAURANT',
  nB63: 'sector.financialAnalysis.bank.assetQuality.FINANCIAL_SERVICE',
  nB64: 'sector.financialAnalysis.bank.assetQuality.OTHER',
}

export const indicatorOptions = Object.keys(indicatorMappingDisplayName).map(
  (key) => ({
    name: indicatorMappingDisplayName[key],
    value: key,
  }),
)

export const chartFormat = {
  nB38: 10 ** 9,
  nB39: 10 ** 9,
  nB40: 10 ** 9,
  nB45: 10 ** 9,
  nB46: 10 ** 9,
  nB55: 10 ** 9,
  nB58: 10 ** 9,
  nB61: 10 ** 9,
  nB62: 10 ** 9,
  nB63: 10 ** 9,
  nB64: 10 ** 9,
  msls: 0.01,
}
