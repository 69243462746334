import { CostOfOperation } from '../../corporate/businessModel/costOfOperation'
import { PenetrationAnalysis } from '../../corporate/businessModel/penetrationAnalysis'
import { SegmentalAnalysis } from '../../corporate/businessModel/segmentalAnalysis'
import CompanySnapshot from '../../corporate/companySnapshot'
import { Dividend } from '../../corporate/financialData/dividend'
import { FinancialRatios } from '../../corporate/financialData/financialRatios'
import { FinancialStatement } from '../../corporate/financialData/financialStatement'
import { Filing } from '../../corporate/newsEvents/filing'
import { News } from '../../corporate/newsEvents/news'
import { NewsDetail } from '../../corporate/newsEvents/news/NewsDetail'
import { NewsMore } from '../../corporate/newsEvents/news/NewsMore'
import { Overview } from '../../corporate/overviews'
import { Ownership } from '../../corporate/ownership'
import { Consensus } from '../../corporate/peersValuation/consensus'
import { PeersValuation } from '../../corporate/peersValuation/overview'
import { TradingDeepTransaction } from '../../corporate/trading/deepTransaction'
import { TradingPriceStatistics } from '../../corporate/trading/priceStatistics'
import { Events } from '../../market/events'

export const CORPORATE_ROUTE = {
  name: 'constants.route.corporate.LABEL',
  routeDefault: '/corporate/overview',
  components: {
    overview: {
      route: '/corporate/overview',
      name: 'constants.route.corporate.overview.LABEL',
      renderMain: () => <Overview />,
    },
    'news-events': {
      name: 'constants.route.corporate.newsEvents.LABEL',
      hasChildren: true,
      components: {
        news: {
          route: '/corporate/news-events/news',
          relatedRoutes: [
            '/corporate/news-events/news-detail',
            '/corporate/news-events/news-more',
          ],
          name: 'constants.route.corporate.newsEvents.news.LABEL',
          renderMain: () => <News />,
        },
        'news-detail': {
          route: '/corporate/news-events/news-detail',
          name: 'constants.route.corporate.newsEvents.news.LABEL',
          renderMain: () => <NewsDetail />,
          hiddenOnSideBar: true,
        },
        'news-more': {
          route: '/corporate/news-events/news-more',
          name: 'constants.route.corporate.newsEvents.news.LABEL',
          renderMain: () => <NewsMore />,
          hiddenOnSideBar: true,
        },
        events: {
          route: '/corporate/news-events/events',
          name: 'constants.route.corporate.newsEvents.events.LABEL',
          renderMain: () => <Events />,
        },
        filing: {
          route: '/corporate/news-events/filing',
          name: 'constants.route.corporate.newsEvents.filing.LABEL',
          renderMain: () => <Filing />,
        },
      },
    },
    trading: {
      name: 'constants.route.corporate.tradingData.LABEL',
      hasChildren: true,
      components: {
        'deep-transaction': {
          route: '/corporate/trading/deep-transaction',
          name: 'constants.route.corporate.tradingData.deepTransaction.LABEL',
          renderMain: () => <TradingDeepTransaction />,
        },
        'price-statistics': {
          route: '/corporate/trading/price-statistics',
          name: 'constants.route.corporate.tradingData.priceStatistics.LABEL',
          renderMain: () => <TradingPriceStatistics />,
        },
      },
    },
    ownership: {
      route: '/corporate/ownership',
      name: 'constants.route.corporate.ownership.LABEL',
      renderMain: () => <Ownership />,
    },
    'business-model': {
      name: 'constants.route.corporate.businessModel.LABEL',
      hasChildren: true,
      hiddenOnSideBar: process.env.REACT_APP_ENV === 'production',
      inactive: process.env.REACT_APP_ENV === 'production',
      components: {
        'segmental-analysis': {
          route: '/corporate/business-model/segmental-analysis',
          name: 'constants.route.corporate.businessModel.segmentalAnalysis.LABEL',
          inactive: process.env.REACT_APP_ENV === 'production',
          renderMain: () =>
            process.env.REACT_APP_ENV === 'production' ? (
              <></>
            ) : (
              <SegmentalAnalysis />
            ),
        },
        'penetration-analysis': {
          route: '/corporate/business-model/penetration-analysis',
          name: 'constants.route.corporate.businessModel.penetrationAnalysis.LABEL',
          inactive: process.env.REACT_APP_ENV === 'production',
          renderMain: () =>
            process.env.REACT_APP_ENV === 'production' ? (
              <></>
            ) : (
              <PenetrationAnalysis />
            ),
        },
        'cost-of-operation': {
          route: '/corporate/business-model/cost-of-operation',
          name: 'constants.route.corporate.businessModel.costOfOperation.LABEL',
          inactive: process.env.REACT_APP_ENV === 'production',
          renderMain: () =>
            process.env.REACT_APP_ENV === 'production' ? (
              <></>
            ) : (
              <CostOfOperation />
            ),
        },
      },
    },
    'financial-data': {
      name: 'constants.route.corporate.financialData.LABEL',
      hasChildren: true,
      components: {
        'financial-ratios': {
          route: '/corporate/financial-data/financial-ratios',
          name: 'constants.route.corporate.financialData.financialRatios.LABEL',
          renderMain: () => <FinancialRatios />,
        },
        'financial-statement': {
          route: '/corporate/financial-data/financial-statement',
          name: 'constants.route.corporate.financialData.financialStatement.LABEL',
          renderMain: () => <FinancialStatement />,
        },
        dividend: {
          route: '/corporate/financial-data/dividend',
          name: 'constants.route.corporate.financialData.dividend.LABEL',
          renderMain: () => <Dividend />,
        },
      },
    },
    'peers-valuation': {
      name: 'constants.route.corporate.peerValuation.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/corporate/peers-valuation/overview',
          name: 'constants.route.corporate.peerValuation.overview.LABEL',
          renderMain: () => <PeersValuation />,
        },
        consensus: {
          route: '/corporate/peers-valuation/consensus',
          name: 'constants.route.corporate.peerValuation.consensus.LABEL',
          renderMain: () => <Consensus />,
        },
      },
    },
    'company-snapshot': {
      route: '/corporate/company-snapshot',
      name: 'constants.route.corporate.companySnapshot.LABEL',
      renderMain: () => <CompanySnapshot />,
    },
  },
}
