import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { ChartContainer } from '../../common/ChartContainer'
import { StackedChartWithLine } from '../../common/StackedBarLineChart'
import { chartFillColor, priceEarningMappingDisplay } from './constants'
import {
  selectPriceEarningChartData,
  selectPriceEarningChartLoading,
} from './store/slice'
import { getPriceEarningRatioDividendYieldChartData } from './store/thunk'

export const PriceEarningChart = ({ height, width }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectPriceEarningChartLoading)
  const data = useSelector(selectPriceEarningChartData)

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getPriceEarningRatioDividendYieldChartData({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <div id="priceEarningChart" className="h-100">
      <ChartContainer
        data={data}
        height={height}
        width={width}
        isLoading={isLoading}
      >
        {(resizeSize) => (
          <StackedChartWithLine
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            xAxisKey="label"
            barKeys={['rtD0022']}
            lineKeys={['rtD0045']}
            lineUnit="%"
            mappingDisplayName={priceEarningMappingDisplay}
            leftLabel="corporate.financialData.financialDividend.PE_TIME"
            rightLabel="corporate.financialData.financialDividend.DIV_YIELD_PERCENT"
            isXAxisDiv
            isBreakNewLine
            isYAxisPercentRight={true}
          />
        )}
      </ChartContainer>
    </div>
  )
}

PriceEarningChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
