import LinkSector from '../../../../../../common/dropdownLink/LinkSector'
import LinkTicker from '../../../../../../common/dropdownLink/LinkTicker'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { formatVal } from '../../../../../../utils/Value'
import { tableFormat, tableHeaderSchema } from './constants'

export const getTableSchema = (companyType, getValueById) => {
  return Object.keys(tableHeaderSchema).map((key) => {
    const title = `sector.financialAnalysis.security.assetStructure.${key}`
    const colId = tableHeaderSchema[key]
    const result = {
      colId,
      title,
    }

    if (tableHeaderSchema.TICKER === colId) {
      return {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val, rowId) => (
          <LinkTicker organizationId={getValueById(rowId, 'organizationId')}>
            {val}
          </LinkTicker>
        ),
      }
    }

    if (tableHeaderSchema.SECTOR === colId) {
      return {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        render: (val, rowId) => (
          <td style={{ width: '100%', maxWidth: 0 }}>
            <LinkSector icbId={getValueById(rowId, 'icbId')}>
              <TextEllipsis
                isI18n={false}
                text={val}
                appendStyle={{ padding: '1px 0px' }}
              />
            </LinkSector>
          </td>
        ),
      }
    }

    const customSchema = {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (tableHeaderSchema.COST === colId) {
      return {
        ...customSchema,
        render: (val) => formatVal(val / tableFormat.COST),
      }
    }

    if (tableHeaderSchema.MARKET_VALUE === colId) {
      return {
        ...customSchema,
        render: (val) => formatVal(val / tableFormat.COST),
      }
    }

    return result
  })
}
