import PropTypes from 'prop-types'
import { SizeTracker } from '../../../common/sizeTracker'
import style from './index.module.css'
import { PanelHeader } from './PanelHeader'

const PADDING_PANEL = 8
const PANEL_BORDER = 4

export const Panel = ({
  item,
  dashboard,
  children,
  hasHeader,
  height,
  margin,
  setPreviewChart,
  editChart,
  renameChart,
  width,
  duplicateChart,
  positionCopyToLeft,
  appendStyle,
  isShowViews,
  index,
  isShowSetting,
}) => {
  return (
    <div
      className={style.panel}
      style={{
        width:
          width ||
          `calc(100%/2 - ${2 * margin + 2 * PADDING_PANEL + PANEL_BORDER}px)`,
        height,
        margin,
        ...appendStyle,
      }}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              {hasHeader && (
                <PanelHeader
                  index={index}
                  item={item}
                  dashboard={dashboard}
                  setPreviewChart={setPreviewChart}
                  editChart={editChart}
                  renameChart={renameChart}
                  duplicateChart={duplicateChart}
                  positionCopyToLeft={positionCopyToLeft}
                  isShowViews={isShowViews}
                  isShowSetting={isShowSetting}
                />
              )}
              {(size.height || size.height === 0) && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {children}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

Panel.propTypes = {
  item: PropTypes.any,
  dashboard: PropTypes.any,
  children: PropTypes.node.isRequired,
  hasHeader: PropTypes.bool,
  height: PropTypes.number,
  margin: PropTypes.number,
  setPreviewChart: PropTypes.func,
  editChart: PropTypes.func,
  renameChart: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duplicateChart: PropTypes.func,
  positionCopyToLeft: PropTypes.bool,
  isShowViews: PropTypes.bool,
  isShowSetting: PropTypes.bool,
}

Panel.defaultProps = {
  hasHeader: true,
  height: 404,
  margin: 2,
  editChart: () => {},
  renameChart: () => {},
  setPreviewChart: () => {},
  duplicateChart: () => {},
  positionCopyToLeft: false,
  isShowViews: false,
  isShowSetting: true,
}
