import { maxBy, orderBy } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { DEFAULT_ORGANIZATION_ID } from '../../../configs/Common'
import TextEllipsis from '../../common/textEllipsis'
import { COM_GROUP } from '../../constants/Group'
import { keyBy } from '../../utils/Common'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import { formatVal } from '../../utils/Value'
import {
  ALL_VALUE,
  BOND_TYPES,
  FILTER_FIELD_CODES,
  FILTER_FIELD_TYPES,
  TABLE_FIELD_CODES,
} from './Screening/constants'
import { INDICATOR_GROUPS } from './constants'

export const renderConditionParams = (data, addedInformation) => {
  if (!data) return []

  const children = data.filter(
    (item) => item.indicatorId !== item.parentIndicatorId,
  )

  const filterHaveValue = [
    FILTER_FIELD_TYPES.SELECT,
    FILTER_FIELD_TYPES.MULTI_SELECT,
    FILTER_FIELD_TYPES.RADIO,
  ]

  const childrenHaveValue = children.filter((item) =>
    filterHaveValue.includes(item.selectionType),
  )

  let bondType = null

  if (addedInformation) {
    const bondTypeInformation = addedInformation.find(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE,
    )

    if (bondTypeInformation) {
      const {
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        multiplier,
      } = bondTypeInformation

      const bondTypeItem = {
        id: uuidv4(),
        order: 0,
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        conditionValues: BOND_TYPES.ALL,
      }

      if (multiplier) {
        bondTypeItem.multiplier = multiplier
      }

      bondType = bondTypeItem
    }
  }

  return [
    bondType,
    ...childrenHaveValue.map((item, index) => {
      const {
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        selectionValues,
        multiplier,
      } = item

      if (!selectionValues?.length) return null

      const allValue = selectionValues.find((item) => item.value === ALL_VALUE)

      if (!allValue) return null

      const newItem = {
        id: uuidv4(),
        order: index + 1,
        indicatorId,
        tableCode,
        conditionTableFieldCode,
        tableFieldCode,
        selectionType,
        selectionTypeId,
        conditionValues: [allValue.value],
      }

      if (multiplier) {
        newItem.multiplier = multiplier
      }

      return newItem
    }),
  ].filter((item) => item)
}

export const renderAddedInformaticsParams = (data) => {
  if (!data) return []

  const children = data.filter(
    (item) => item.indicatorId !== item.parentIndicatorId && item.isDefault,
  )

  return children.map((item, index) => ({
    id: uuidv4(),
    order: index + 2,
    indicatorId: item.indicatorId,
    tableCode: item.tableCode,
    tableFieldCode: item.tableFieldCode,
  }))
}

const TYPE = {
  DATE: 'date',
  BILLION: 'int',
  DECIMAL: 'decimal',
  SMALLINT: 'smallint',
  STRING: 'string',
}

const getType = (item, value, locale) => {
  const key = item.typeOf

  switch (key) {
    case TYPE.DATE:
      return formatDateTime(value, FORMAT.DATE, locale)
    case TYPE.BILLION:
      return formatVal(value, 0)
    case TYPE.SMALLINT:
      if (
        item?.indicatorCode === 'DurationName' ||
        item?.indicatorCode === 'en_DurationName'
      ) {
        return value
      }

      return formatVal(value, 0)
    case TYPE.DECIMAL:
      return (
        formatVal(value) +
        (item.typeOf === TYPE.DECIMAL && item.unit.includes('%') ? '%' : '')
      )
    default:
      return value ?? ''
  }
}

export const renderTableFieldCode = (string) =>
  string ? (string.indexOf('en_') === 0 ? string.slice(3) : string) : ''

export const formatDataHeaderTable = (data, addedInformation) => {
  if (!data || !data?.length) return []

  const newData = data.map((item) => {
    const index = addedInformation.findIndex(
      (key) =>
        renderTableFieldCode(key.tableFieldCode) ===
        renderTableFieldCode(item.indicatorCode),
    )

    return {
      ...item,
      order: ![
        FILTER_FIELD_CODES.BOND_TICKER,
        FILTER_FIELD_CODES.TICKER,
      ].includes(item.indicatorCode)
        ? index !== -1
          ? addedInformation[index].order
          : item.order
        : 1,
    }
  })

  const max = maxBy(newData, (e) => e.order)

  const itemsNotDuplicate = newData.filter((item) => {
    const index = addedInformation.findIndex((key) => key.order === item.order)

    return (
      index !== -1 &&
      item.order === addedInformation[index].order &&
      renderTableFieldCode(item.indicatorCode) ===
        renderTableFieldCode(addedInformation[index].tableFieldCode)
    )
  })

  const itemsDuplicate = newData
    .filter((item) => {
      const index = addedInformation.findIndex(
        (key) => key.order === item.order,
      )

      return (
        index !== -1 &&
        item.order === addedInformation[index].order &&
        renderTableFieldCode(item.indicatorCode) !==
          renderTableFieldCode(addedInformation[index].tableFieldCode)
      )
    })
    .map((item, index) => ({
      ...item,
      order: (max?.order ?? 0) + index + 1,
    }))

  return orderBy([...itemsNotDuplicate, ...itemsDuplicate], ['order'], 'asc')
}

export const formatDataBodyTable = (data, addedInformation) => {
  if (!data || !data?.length) return []

  const newData = data.map((item, indexData) => {
    const newItems = item?.items.map((child) => {
      const index = addedInformation.findIndex(
        (key) =>
          renderTableFieldCode(key.tableFieldCode) ===
          renderTableFieldCode(child.indicatorCode),
      )

      return {
        ...child,
        order: ![
          FILTER_FIELD_CODES.BOND_TICKER,
          FILTER_FIELD_CODES.TICKER,
        ].includes(child.indicatorCode)
          ? index !== -1
            ? addedInformation[index].order
            : child.order
          : 1,
      }
    })

    const max = maxBy(newItems, (e) => e.order)

    const itemBondTicker = newItems.find((item) =>
      [FILTER_FIELD_CODES.BOND_TICKER, FILTER_FIELD_CODES.TICKER].includes(
        item.indicatorCode,
      ),
    )

    const itemsNotDuplicate = newItems.filter((item) => {
      const index = addedInformation.findIndex(
        (key) => key.order === item.order,
      )

      return (
        index !== -1 &&
        item.order === addedInformation[index].order &&
        renderTableFieldCode(item.indicatorCode) ===
          renderTableFieldCode(addedInformation[index].tableFieldCode)
      )
    })

    const itemsDuplicate = newItems
      .filter((item) => {
        const index = addedInformation.findIndex(
          (key) => key.order === item.order,
        )

        return (
          index !== -1 &&
          item.order === addedInformation[index].order &&
          renderTableFieldCode(item.indicatorCode) !==
            renderTableFieldCode(addedInformation[index].tableFieldCode)
        )
      })
      .map((item, index) => ({
        ...item,
        order: (max?.order ?? 0) + index + 1,
      }))

    return {
      ...item,
      items: orderBy(
        [
          { order: 0, indicatorCode: 'STT', value: indexData + 1 },
          ...(itemBondTicker ? [itemBondTicker] : []),
          ...itemsNotDuplicate,
          ...itemsDuplicate,
        ],
        ['asc'],
        ['order'],
      ),
    }
  })

  return newData
}

export const formatTable = (
  locale,
  data,
  addedInformation,
  redirectToBondIssuer,
) => {
  const dataHeader = formatDataHeaderTable(data?.header, addedInformation)
  const dataBody = formatDataBodyTable(data?.body, addedInformation)

  const ids = []
  const columnById = {}
  const tickerDisplay = dataBody.map((e) => e.bondId)

  const tickerById = {}

  dataBody.forEach((bond, index) => {
    const bondId = bond.bondId

    bond.items.forEach((item) => {
      const indicatorCode = item?.indicatorCode
      const value = item.value

      if (!tickerById[bondId]) {
        tickerById[bondId] = {}
      }

      tickerById[bondId][indicatorCode] = value
    })
  })

  dataHeader
    .filter(
      (item) =>
        ![FILTER_FIELD_CODES.BOND_TICKER, FILTER_FIELD_CODES.TICKER].includes(
          item.indicatorCode,
        ),
    )
    .forEach((e) => {
      ids.push(e?.indicatorCode)
      columnById[e?.indicatorCode] = {
        ...e,
        alias: e.indicatorCode,
        title: e?.indicatorName,
        additionText: e?.unit,
        style: {
          padding: '2px 4px 2px 4px',
          ...e.style,
        },
        typeOf:
          e.typeOf === TYPE.BILLION ||
          e.typeOf === TYPE.DECIMAL ||
          e.typeOf === TYPE.SMALLINT
            ? 'int'
            : '',
        render: (idTicker, idColumn, convertValue) => {
          const data = getType(e, convertValue, locale)

          const row = dataBody.find((item) => item.bondId === idTicker)

          return (
            <div style={{ maxWidth: 282 }}>
              {renderTableFieldCode(e.indicatorCode) ===
              renderTableFieldCode(TABLE_FIELD_CODES.ISSUERS) ? (
                <a
                  className="cursor-pointer"
                  onClick={() =>
                    redirectToBondIssuer(
                      row?.organizationId ??
                        DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                    )
                  }
                >
                  <TextEllipsis text={data} isI18n={false} />
                </a>
              ) : (
                <TextEllipsis text={data} isI18n={false} />
              )}
            </div>
          )
        },
      }
    })

  return {
    ids,
    columnById,
    tickerDisplay,
    tickerById,
    headers: dataHeader.map((item) => item.indicatorCode),
  }
}

export const getICBs = (ICBs, isDeleteChildren = true) => {
  const ICBsSorted = orderBy(ICBs, ['icbLevel'], ['desc'])

  const ICBsById = keyBy(
    ICBs.map((ICB) => {
      return { ...ICB, children: {} }
    }),
    'icbId',
  )

  ICBsSorted.forEach((ICB) => {
    if (ICBsById[ICB.parentICBId] && ICB.icbLevel !== 1) {
      ICBsById[ICB.parentICBId].children[ICB.icbId] = ICBsById[ICB.icbId]
      if (isDeleteChildren) {
        delete ICBsById[ICB.icbId]
      }
    }
  })

  return ICBsById
}

export const formatAddInformation = (data, list) => {
  if (!data?.length) return []

  const addedInformations = !list.length
    ? data.map((item, index) => ({
        ...item,
        order: index + 2,
      }))
    : data.map((item) => {
        const index = list.findIndex((id) => id === item.tableFieldCode)

        return { ...item, order: index !== -1 ? index + 2 : item.order }
      })

  return orderBy(addedInformations, ['order'], ['asc'])
}

export const getSortFieldByIndicatorGroup = (sortBy, indicatorGroup) => {
  let defaultSortBy = sortBy
  switch (true) {
    case indicatorGroup === INDICATOR_GROUPS.BOND_AGGREGATOR:
      if (!sortBy) defaultSortBy = FILTER_FIELD_CODES.ISSUE_DATE
      break
    case indicatorGroup === INDICATOR_GROUPS.BOND_TRANSACTION:
      if (!sortBy) defaultSortBy = FILTER_FIELD_CODES.REDEMPTION_DATE
      break
    case indicatorGroup === INDICATOR_GROUPS.BOND_CREATING:
      if (!sortBy) defaultSortBy = FILTER_FIELD_CODES.ISSUE_DATE
      break
    case indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER:
      if (!sortBy) defaultSortBy = FILTER_FIELD_CODES.ORGANIZATION
      break
    default:
      if (!sortBy) defaultSortBy = FILTER_FIELD_CODES.ISSUE_DATE
      break
  }
  return defaultSortBy
}

export const getSortOrderByIndicatorGroup = (
  sortOrder,
  sortBy,
  indicatorGroup,
) => {
  let defaultSortOrder = sortOrder
  switch (true) {
    case indicatorGroup === INDICATOR_GROUPS.BOND_AGGREGATOR:
      if (sortBy === FILTER_FIELD_CODES.ISSUE_DATE) defaultSortOrder = 'default'
      break
    case indicatorGroup === INDICATOR_GROUPS.BOND_TRANSACTION:
      if (sortBy === FILTER_FIELD_CODES.REDEMPTION_DATE)
        defaultSortOrder = 'default'
      break
    case indicatorGroup === INDICATOR_GROUPS.BOND_CREATING:
      if (sortBy === FILTER_FIELD_CODES.ISSUE_DATE) defaultSortOrder = 'default'
      break
    case indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER:
      if (sortBy === FILTER_FIELD_CODES.ORGANIZATION)
        defaultSortOrder = 'default'
      break
    default:
      break
  }
  return defaultSortOrder
}
