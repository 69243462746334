import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../common/html/Span'
import style from '../index.module.css'
import { selectIsShowTickerFilter, setIsShowTickerFilter } from '../store/slice'
import ExchangeSectorList from './ExchangeSectorList'
import FilterTaxCode from './FilterTaxcode'

const TickerFilter = () => {
  const dispatch = useDispatch()

  const isShowTickerFilter = useSelector(selectIsShowTickerFilter)

  const hideTickerFilter = () => {
    dispatch(setIsShowTickerFilter(false))
  }

  return (
    <>
      {isShowTickerFilter && (
        <div className="box-filter">
          <div className=" d-flex j-b ali-center col-header">
            <h6>
              <Span>
                <Translate value="watchlist.watchlistDetail.FILTER" />
              </Span>
            </h6>
            <a
              onClick={hideTickerFilter}
              className={`close-modal ${style.pointer}`}
            >
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div className="filter-content">
            <FilterTaxCode />
            <ExchangeSectorList />
          </div>
        </div>
      )}
    </>
  )
}

export default TickerFilter
