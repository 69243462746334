import PropTypes from 'prop-types'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { convertTextUnicode } from '../../common/helper'
import {
  changeFilter,
  selectListLocationName,
} from '../investmentStructure/store/slice'
import { TABLE_SECTOR_ID, listViewBy } from './constant'
import {
  changeFilterStatus,
  changeIndicator,
  changeViewBy,
  resetDataTable,
  selectFilterStatus,
  selectListFDITypeName,
  selectViewBy,
} from './store/slice'
import { getFDITypeName } from './store/thunk'
import style from './style.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const Filter = forwardRef(({ setIsFirstTime }, ref) => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const viewBy = useSelector(selectViewBy)
  const filterStatus = useSelector(selectFilterStatus)
  const listFDITypeName = useSelector(selectListFDITypeName)
  const locale = useSelector((state) => state.i18n.locale)
  const listLocationName = useSelector(selectListLocationName)

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const onChangeViewBy = ({ value }) => {
    dispatch(resetDataTable())
    dispatch(changeViewBy(value))
  }

  const onChangeFilterStatus = (value) => {
    dispatch(resetDataTable())
    dispatch(changeFilterStatus(value))
  }

  useEffect(() => {
    dispatch(getFDITypeName({ IsCapital: viewBy }))
  }, [viewBy, locale])

  return (
    <div ref={ref} className="d-flex justify-content-space-between mb-8">
      <div className={'d-flex'}>
        <DispatchActionTab
          data={listViewBy.map((item) => {
            return {
              title: item.title,
              value: item.value,
            }
          })}
          activeTab={viewBy}
          onChangeTab={onChangeViewBy}
          containerStyle={{ marginRight: '8px' }}
        />
        <div className="d-flex" style={{ paddingTop: 0 }}>
          {listFDITypeName.length ? (
            <div className="w-200">
              <SelectCustom
                value={filterStatus}
                selectData={listFDITypeName}
                handleChange={onChangeFilterStatus}
              />
            </div>
          ) : null}
          <div ref={exceptOutsideRef} className="ml-8 form-search-inbox w-200">
            <Input
              style={{
                fontSize: 11,
                backgroundColor: '#3c424b',
              }}
              type="text"
              className="search-inbox"
              placeholder={I18n.t('economy.fdi.fdiByProvinces.SEARCH')}
              ref={inputRef}
              onChange={(e) => setKeySearch(e.target.value?.toLowerCase())}
              onFocus={handleShow}
              onKeyDown={handlePressKey}
              value={keySearch}
            />
            <button type="button">
              <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
            </button>
            <DropdownLocaleName
              isShowDropdown={isShowDropdown}
              parentRef={inputRef}
              handleHide={handleHide}
              exceptOutsideRef={exceptOutsideRef}
              listLocationName={listLocationName.filter((item) =>
                convertTextUnicode(item.locationName)
                  ?.toLowerCase()
                  .includes(convertTextUnicode(keySearch)),
              )}
              setKeySearch={setKeySearch}
              eventPressKey={eventPressKey}
              setIsFirstTime={setIsFirstTime}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default Filter

const DropdownLocaleName = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listLocationName,
  exceptOutsideRef,
  setKeySearch,
  setIsFirstTime,
}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthCompanies = listLocationName.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listLocationName[activeIndex])
      else if (listLocationName[0]) handleClickItem(listLocationName[0])
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthCompanies > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthCompanies - 1)
        setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCompanies > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthCompanies - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (value) => {
    const scrollTable = document.querySelector(
      `#${TABLE_SECTOR_ID} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${value.locationId}"]`)
    if (scrollTable && headerTable && activeRow) {
      setIsFirstTime(false)
      scrollTable.scrollTo({
        top: activeRow.offsetTop - headerTable.offsetHeight,
        behavior: 'smooth',
      })
    }
    dispatch(changeFilter(value))
    dispatch(changeIndicator(value.locationId))
    handleHide()
    setKeySearch(value.locationName)
  }
  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listLocationName.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listLocationName.map((location, index) => {
                return (
                  <div
                    key={location.locationId}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(location)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>
                        {location.locationName}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

ItemScroll.propTypes = {
  isActive: PropTypes.bool.isRequired,
}
