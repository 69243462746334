import { COM_GROUP } from '../../../../constants/Group'
import { formatVal, valToPercent } from '../../../../utils/Value'
import CellMedian from './CellMedian'
import {
  assetQualityIndicatorBank,
  efficiencyIndicatorBank,
  financialStrengthIndicatorCorporate,
  financialStrengthIndicatorSecurities,
  growthIndicatorBank,
  growthIndicatorCorporate,
  growthIndicatorSecurities,
  managementEfficiencyIndicatorCorporate,
  managementEfficiencyIndicatorSecurities,
  profitabilityIndicatorBank,
  profitabilityIndicatorCorporate,
  profitabilityIndicatorSecurities,
  TOP_COMPANIES_TAB_BANK,
  TOP_COMPANIES_TAB_CORPORATE,
  TOP_COMPANIES_TAB_SECURITIES,
  valuationIndicatorBank,
  valuationIndicatorCorporate,
  valuationIndicatorSecurities,
} from './constants'

export const profitabilityIndicatorBankSchema = () => {
  return Object.keys(profitabilityIndicatorBank).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = profitabilityIndicatorBank[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const assetQualityIndicatorBankSchema = () => {
  return Object.keys(assetQualityIndicatorBank).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = assetQualityIndicatorBank[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const efficiencyIndicatorBankSchema = () => {
  return Object.keys(efficiencyIndicatorBank).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = efficiencyIndicatorBank[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const valuationIndicatorBankSchema = () => {
  return Object.keys(valuationIndicatorBank).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = valuationIndicatorBank[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const growthIndicatorBankSchema = () => {
  return Object.keys(growthIndicatorBank).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = growthIndicatorBank[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}

export const profitabilityIndicatorSecuritiesSchema = () => {
  return Object.keys(profitabilityIndicatorSecurities).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = profitabilityIndicatorSecurities[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const financialStrengthIndicatorSecuritiesSchema = () => {
  return Object.keys(financialStrengthIndicatorSecurities).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = financialStrengthIndicatorSecurities[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    if (index === 3 || index === 4 || index === 5 || index === 6) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
          whiteSpace: 'nowrap',
        },
        render: (val, rowId) => {
          return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const managementEfficiencyIndicatorSecuritiesSchema = () => {
  return Object.keys(managementEfficiencyIndicatorSecurities).map(
    (key, index) => {
      const title = `sector.sectorConstituents.overview.${key}`
      const colId = managementEfficiencyIndicatorSecurities[key]
      let result = {
        colId,
        title,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
        },
      }
      if (index === 0 || index === 1) {
        result = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'left',
          },
          tdStyle: {
            textAlign: 'left',
          },
          disableSort: true,
          render: (val, rowId) => {
            return <CellMedian val={val} rowId={rowId} index={index} />
          },
        }
      }

      if (index === 2) {
        result = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId) => {
            return <CellMedian val={val} rowId={rowId} index={index} />
          },
        }
      }
      return result
    },
  )
}
export const valuationIndicatorSecuritiesSchema = () => {
  return Object.keys(valuationIndicatorSecurities).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = valuationIndicatorSecurities[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const growthIndicatorSecuritiesSchema = () => {
  return Object.keys(growthIndicatorSecurities).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = growthIndicatorSecurities[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}

export const profitabilityIndicatorCorporateSchema = () => {
  return Object.keys(profitabilityIndicatorCorporate).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = profitabilityIndicatorCorporate[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}

export const financialStrengthIndicatorCorporateSchema = () => {
  return Object.keys(financialStrengthIndicatorCorporate).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = financialStrengthIndicatorCorporate[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const managementEfficiencyIndicatorCorporateSchema = () => {
  return Object.keys(managementEfficiencyIndicatorCorporate).map(
    (key, index) => {
      const title = `sector.sectorConstituents.overview.${key}`
      const colId = managementEfficiencyIndicatorCorporate[key]
      let result = {
        colId,
        title,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
        },
      }
      if (index === 0 || index === 1) {
        result = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'left',
          },
          tdStyle: {
            textAlign: 'left',
          },
          disableSort: true,
          render: (val, rowId) => {
            return <CellMedian val={val} rowId={rowId} index={index} />
          },
        }
      }

      if (index === 2) {
        result = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId) => {
            return <CellMedian val={val} rowId={rowId} index={index} />
          },
        }
      }
      if (index === 7) {
        result = {
          ...result,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
          render: (val, rowId) => {
            return (
              <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
            )
          },
        }
      }
      return result
    },
  )
}
export const valuationIndicatorCorporateSchema = () => {
  return Object.keys(valuationIndicatorCorporate).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = valuationIndicatorCorporate[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return <CellMedian val={formatVal(val)} rowId={rowId} index={index} />
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}
export const growthIndicatorCorporateSchema = () => {
  return Object.keys(growthIndicatorCorporate).map((key, index) => {
    const title = `sector.sectorConstituents.overview.${key}`
    const colId = growthIndicatorCorporate[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
      render: (val, rowId) => {
        return (
          <CellMedian val={valToPercent(val)} rowId={rowId} index={index} />
        )
      },
    }
    if (index === 0 || index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }

    if (index === 2) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val, rowId) => {
          return <CellMedian val={val} rowId={rowId} index={index} />
        },
      }
    }
    return result
  })
}

const mapSchemaBank = {
  [TOP_COMPANIES_TAB_BANK.PROFITABILITY]: profitabilityIndicatorBankSchema(),
  [TOP_COMPANIES_TAB_BANK.ASSET_QUALITY]: assetQualityIndicatorBankSchema(),
  [TOP_COMPANIES_TAB_BANK.EFFICIENCY]: efficiencyIndicatorBankSchema(),
  [TOP_COMPANIES_TAB_BANK.VALUATION]: valuationIndicatorBankSchema(),
  [TOP_COMPANIES_TAB_BANK.GROWTH]: growthIndicatorBankSchema(),
}

const mapSchemaSecurities = {
  [TOP_COMPANIES_TAB_SECURITIES.PROFITABILITY]:
    profitabilityIndicatorSecuritiesSchema(),
  [TOP_COMPANIES_TAB_SECURITIES.FINANCIAL_STRENGTH]:
    financialStrengthIndicatorSecuritiesSchema(),
  [TOP_COMPANIES_TAB_SECURITIES.MANAGEMENT_EFFICIENCY]:
    managementEfficiencyIndicatorSecuritiesSchema(),
  [TOP_COMPANIES_TAB_SECURITIES.VALUATION]:
    valuationIndicatorSecuritiesSchema(),
  [TOP_COMPANIES_TAB_SECURITIES.GROWTH]: growthIndicatorSecuritiesSchema(),
}

const mapSchemaCorporate = {
  [TOP_COMPANIES_TAB_CORPORATE.PROFITABILITY]:
    profitabilityIndicatorCorporateSchema(),
  [TOP_COMPANIES_TAB_CORPORATE.FINANCIAL_STRENGTH]:
    financialStrengthIndicatorCorporateSchema(),
  [TOP_COMPANIES_TAB_CORPORATE.MANAGEMENT_EFFICIENCY]:
    managementEfficiencyIndicatorCorporateSchema(),
  [TOP_COMPANIES_TAB_CORPORATE.VALUATION]: valuationIndicatorCorporateSchema(),
  [TOP_COMPANIES_TAB_CORPORATE.GROWTH]: growthIndicatorCorporateSchema(),
}

export const getSchema = (typeSector, tabValue) => {
  if (typeSector === COM_GROUP.BANK) {
    return mapSchemaBank[tabValue]
  } else if (typeSector === COM_GROUP.SECURITIES) {
    return mapSchemaSecurities[tabValue]
  } else {
    return mapSchemaCorporate[tabValue]
  }
}
