import React from 'react'
import { useSelector } from 'react-redux'
import { KEY_ACTION } from '../../../../common/dropdownLink/constant'
import LinkSector from '../../../../common/dropdownLink/LinkSector'
import { selectDataByKey } from '../store/slice'

const SectorTooltip = ({ value, rowId }) => {
  const dataByKeys = useSelector(selectDataByKey)

  return (
    <LinkSector
      icbId={dataByKeys[rowId].icbId}
      disableActivate={[KEY_ACTION.RANKING]}
    >
      {value}
    </LinkSector>
  )
}

export default SectorTooltip
