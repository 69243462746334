import { createAsyncThunk } from '@reduxjs/toolkit'
import StateBudgetService from '../../../../../../core/services/economy/fiscal/StateBudgetService'
import { handleExport } from '../../../../../utils/Export'

export const getStateBudgetBalance = createAsyncThunk(
  'economy/fiscal/stateBudget/getStateBudgetBalance',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getStateBudgetBalance({
      NumberOfPeriod: params.NumberOfPeriod,
    })
    const responseName = await StateBudgetService.getBudgetRevenueTypeName()
    const responseDataStatusName = await StateBudgetService.getDataStatusName({
      TimeRange: params.TimeRange,
    })

    if (
      response.success &&
      responseName.success &&
      responseDataStatusName.success
    ) {
      return {
        data: response.data,
        nameData: responseName.data,
        statusName: responseDataStatusName.data,
      }
    }
    return rejectWithValue(response.errors)
  },
)

export const getDownloadStateBudgetBalance = createAsyncThunk(
  'economy/fiscal/stateBudget/getDownloadStateBudgetBalance',
  async (data) => {
    const response = await StateBudgetService.getDownloadStateBudgetBalance(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
