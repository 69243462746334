import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import style from './index.module.css'
import { PopupCopyTo } from './PopupCopyTo'
import { PopupMoveTo } from './PopupMoveTo'

export const Item = ({
  item,
  isLastItem,
  setIsLastItem,
  isFirstItem,
  setIsFirstItem,
  onSelectedItem,
  popupRef,
}) => {
  const parentRef = useRef()

  const [isShowPopupCopyTo, setShowPopupCopyTo] = useState(false)
  const [isShowPopupMoveTo, setShowPopupMoveTo] = useState(false)

  const copyToNewDB = () => {
    setShowPopupCopyTo(false)
    item.action('COPY_TO_NEW_DB', null)
    onSelectedItem()
  }

  const copyToDB = (dashboard) => {
    setShowPopupCopyTo(false)
    item.action('COPY_TO_DB', dashboard)
    onSelectedItem()
  }

  const onMouseEnter = () => {
    if (isLastItem) {
      setIsLastItem(true)
    } else {
      setIsLastItem(false)
    }

    if (isFirstItem) {
      setIsFirstItem(true)
    } else {
      setIsFirstItem(false)
    }
  }

  const onMouseLeave = () => {
    if (isLastItem) {
      setIsLastItem(false)
    }

    if (isFirstItem) {
      setIsFirstItem(false)
    }
  }

  const moveToDB = (dashboard) => {
    setShowPopupMoveTo(false)
    item.action('MOVE_TO_GROUP_DB', dashboard)
    onSelectedItem()
  }

  const moveToNewDB = () => {
    setShowPopupMoveTo(false)
    item.action('MOVE_TO_NEW_GROUP_DB', null)
    onSelectedItem()
  }

  const onClick = (e) => {
    e.stopPropagation()
    if (item.isActionCopyTo) {
      setShowPopupCopyTo(!isShowPopupCopyTo)
    } else if (item.isActionMoveTo) {
      setShowPopupMoveTo(!isShowPopupMoveTo)
    } else {
      item.action()
      onSelectedItem()
    }
  }

  return (
    <>
      <div
        ref={parentRef}
        className={style.dropdownItem}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="d-flex justify-content-space-between w-100 align-center">
          <div className="d-flex align-center">
            <div className={style.dropdownIcon}>{item.icon}</div>
            <Span>
              <Translate value={item.title} />
            </Span>
          </div>
          {item.isActionCopyTo && (
            <i
              className={
                item.positionCopyToLeft ? 'icon-arrow-left' : 'icon-arrow-right'
              }
              style={{ marginRight: 8, color: ' #8b8b8b', fontSize: 10 }}
            />
          )}
          {item.isActionMoveTo && (
            <i
              className="icon-arrow-right"
              style={{ marginRight: 8, color: ' #8b8b8b', fontSize: 10 }}
            />
          )}
        </div>
      </div>
      {isShowPopupCopyTo && item.isActionCopyTo && (
        <PopupCopyTo
          parentRef={parentRef}
          isShow={isShowPopupCopyTo}
          copyToNewDB={copyToNewDB}
          copyToDB={copyToDB}
          copyToRef={popupRef}
          positionCopyToLeft={item.positionCopyToLeft}
        />
      )}
      {isShowPopupMoveTo && item.isActionMoveTo && (
        <PopupMoveTo
          parentRef={parentRef}
          isShow={isShowPopupMoveTo}
          moveToDB={moveToDB}
          popupRef={popupRef}
          dashboardSetting={item.dashboardSetting}
          moveToNewDB={moveToNewDB}
        />
      )}
    </>
  )
}

Item.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
}
