export const dailyReport = {
  TEMPLATE: 'Báo cáo đã lưu',
  SETTING: 'Cài đặt báo cáo ',
  SAVE: 'Lưu',
  SAVE_AS: 'Lưu mới',
  EXPORT: 'Xuất dữ liệu',
  COMPANY_SELECTED: 'Công ty đã chọn',
  COMPANY: 'Công ty',
  MARKET_NEWS: 'Tin thị trường',
  CORPORATE_NEWS: 'Tin doanh nghiệp',
  TICKER: 'Cổ phiếu',
  GROUP_STOCK: 'Nhóm cổ phiếu',
  RESET: 'Cài đặt lại',
  SAVE_AS_TEMPLATE: 'Lưu báo cáo mới',
}
