import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getAssociate } from './thunk'

const initialState = {
  associateById: {},
  ids: [],
  loading: false,
  showTable: true,
  initialIds: [],
}

export const slice = createSlice({
  name: 'corporate/ownership/associate',
  initialState,
  reducers: {
    setAssociateById: (state, action) => {
      state.associateById = action.payload
    },
    setIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeIds: (state, action) => {
      state.ids = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.associateById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssociate.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getAssociate.fulfilled, (state, action) => {
      state.loading = false
      state.showTable = true
      state.initialIds = state.ids = action.payload.map((item, index) => index)
      const data =
        action.payload?.map((item, index) => {
          return { ...item, index }
        }) || []
      state.associateById = keyBy(data, 'index')
    })
    builder.addCase(getAssociate.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectAssociateIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading
export const selectShowTable = (state) => state[slice.name].showTable
export const selectAssociateValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].associateById[id], attr)
}
export const { setAssociateById, setIds, changeIds, setLoading, sort } =
  slice.actions

register(slice.name, slice.reducer)
