import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valDivMillion } from '../../../../utils/Value'
import {
  getQuarterAndYearInDataRange,
  getQuarterAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../common/helper'
import { getGrowthByTimeRange } from './thunk'

const slice = createSlice({
  name: 'economy/paymentBalance/changeBalance',
  initialState: {
    isLoading: true,
    data: [],
    activeCategory: null,
    isYearly: false,
  },
  reducers: {
    changeActiveCategory: (state, action) => {
      state.activeCategory = action.payload
    },
    setLoadingGrowthChart: (state, action) => {
      state.isLoading = action.payload
    },
    setIsYearlyGrowthChart: (state, action) => {
      state.isYearly = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGrowthByTimeRange.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getGrowthByTimeRange.fulfilled, (state, action) => {
      state.isLoading = action.payload.loading

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          quarter: item.realQuarter,
          year: item.realYear,
          id: item.boPVNTypeId,
          name: item.boPVNTypeName,
          level: item.boPVNTypeLevel,
          value: valDivMillion(item.value),
        })) || []
      state.data = convertData(payloadData, state.isYearly).reverse()
    })
    builder.addCase(getGrowthByTimeRange.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveCategory = (state) => state[slice.name].activeCategory

export const {
  changeActiveCategory,
  setLoadingGrowthChart,
  setIsYearlyGrowthChart,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, isYearly) => {
  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(data).map((item) => getYearKey(item.year))
    : getQuarterAndYearInDataRange(data).map((item) =>
        getQuarterAndYearKey(item.quarter, item.year),
      )

  //get all cpiVNId and corresponding value from specified data range
  const getDataByQuarterAndYear = (quarter, year) => {
    return data
      .filter((item) => item.year === year && item.quarter === quarter)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataByYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? getYearInDataRange(data).map((item) => [...getDataByYear(item.year)])
    : getQuarterAndYearInDataRange(data).map((item) => [
        ...getDataByQuarterAndYear(item.quarter, item.year),
      ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
