import { useSelector } from 'react-redux'
import { selectValueDetailPopupIndicator } from '../store/slice'

const ItemPeriod = ({ typeActive, periodActive, children }) => {
  const type = useSelector(selectValueDetailPopupIndicator('type'))
  const period = useSelector(selectValueDetailPopupIndicator('period'))

  const isDisabled = typeActive !== type || periodActive !== period

  return children(isDisabled)
}

export default ItemPeriod
