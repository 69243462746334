import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../utils/Color'
import { formatVal } from '../../../../utils/Value'
import { TOI_FORMAT } from '../panelTable/toiTable/ToiTable'
import ChildrenData from './ChildrenData'

export const PERCENT_FORMAT = '#,0.00%'

const TableData = ({
  alias,
  parentData,
  afterParentData,
  childrenData,
  afterChildrenData,
  isAssetYieldCOF,
  isFlexEnd,
  relativeField,
  valueFormat,
  multiplier,
  isContributionToNIM,
}) => {
  return (
    <>
      {alias ? (
        <div
          className={`d-flex ali-center ${
            isFlexEnd ? 'justify-content-end' : 'justify-content-center'
          }`}
        >
          <Span style={{ color: getTwoColorByValue(parentData) }}>
            {isAssetYieldCOF || valueFormat === PERCENT_FORMAT
              ? formatVal(parentData * 100)
              : formatVal(parentData * multiplier)}
            {isContributionToNIM ||
            (valueFormat === PERCENT_FORMAT && afterParentData !== TOI_FORMAT)
              ? '%'
              : ''}
          </Span>
          <Span style={{ marginRight: 8 }}>
            {afterParentData}
            {isAssetYieldCOF ? '%' : ''}
          </Span>
          {relativeField && relativeField.trim() && (
            <>
              <Span>{'('}</Span>
              {typeof childrenData === 'number' ? (
                <>
                  {childrenData > 0 ? (
                    <ChildrenData
                      className="icon-caret-up"
                      childrenData={childrenData}
                    />
                  ) : childrenData < 0 ? (
                    <ChildrenData
                      className="icon-caret"
                      childrenData={childrenData}
                      isMinus
                    />
                  ) : (
                    <ChildrenData childrenData={childrenData} />
                  )}
                  <Span>{afterChildrenData}</Span>
                </>
              ) : (
                <Span style={{ whiteSpace: 'nowrap' }}>--</Span>
              )}
              <Span>{')'}</Span>
            </>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

TableData.propTypes = {
  alias: PropTypes.any,
  parentData: PropTypes.any,
  afterParentData: PropTypes.string,
  childrenData: PropTypes.any,
  afterChildrenData: PropTypes.string,
  relativeField: PropTypes.string,
  valueFormat: PropTypes.string,
  multiplier: PropTypes.number,
}

TableData.defaultProps = {
  isAssetYieldCOF: false,
  afterChildrenData: '',
  relativeField: '',
  valueFormat: '',
  multiplier: 1,
}

export default TableData
