import { I18n } from 'react-redux-i18n'
import { getTextHeight, getTextWidth } from '../../../../common/chart/helper'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'

const ReferenceDuration = ({ ctx, value }) => {
  const calcFontSize = getFontSize(10)

  const valueFormatted = `${formatVal(value)} ${I18n.t(
    'bond.portfolio.overviewOfPortfolioSize.DURATION_UNIT',
  )}`

  const labelWidth = getTextWidth(valueFormatted, calcFontSize, 340)
  const labelHeight = getTextHeight(valueFormatted, calcFontSize, 340)

  return (
    <>
      {value && (
        <g>
          <foreignObject
            x={ctx.viewBox.x + ctx.viewBox.width - labelWidth - 10}
            y={ctx.viewBox.y - labelHeight - 8}
            width={labelWidth + 14}
            height={labelHeight + 4}
            color="#FFFFF"
          >
            {valueFormatted}
          </foreignObject>
        </g>
      )}
    </>
  )
}

export default ReferenceDuration
