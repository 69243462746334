import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import EventEmitter, { RESET_TABLE_SORT } from '../../../../utils/EventEmitter'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { changeActiveCategory } from '../exportStructure/growthChart/store/slice'
import { changeLocaleStructureChart } from '../exportStructure/structureChart/store/slice'
import {
  changeLocaleForTurnOverChart,
  setDisableLoading,
} from '../exportTurnOver/store/slice'
import {
  DATA_TYPE_LIST,
  LIMIT_FIRST_FETCH,
  TIME_FILTER_TYPE,
} from './constants'
import { FilterList } from './FilterList'
import {
  changeCommodity,
  changeLocale,
  selectActiveItemOrder,
  selectImportExportDataType,
  selectIsChangeFilter,
  selectIsKeepRowsCollapse,
  selectLimitQuarter,
  selectOrigParentCategory,
  selectSortType,
  selectTypeData,
  selectTypeFilter,
  selectUnCollapseIds,
  setIsKeepRowsCollapse,
  setLimitQuarter,
} from './store/slice'
import {
  getExportByProduct,
  getExportByProductAfterChangeFilter,
  getImportByProduct,
  getImportByProductAfterChangeFilter,
} from './store/thunk'
import TableComponent from './TableComponent'

export const ExportImportTable = ({ width, height }) => {
  const dispatch = useDispatch()

  const currentTypeFilter = useSelector(selectTypeFilter)
  const currentTypeData = useSelector(selectTypeData)
  const importExportDataType = useSelector(selectImportExportDataType)
  const sortType = useSelector(selectSortType)
  const locale = useSelector((state) => state.i18n.locale)
  const unCollapseIds = useSelector(selectUnCollapseIds)
  const limitQuarter = useSelector(selectLimitQuarter)
  const isKeepRowsCollapse = useSelector(selectIsKeepRowsCollapse)
  const activeItemOrder = useSelector(selectActiveItemOrder)
  const listOrigParentCategory = useSelector(selectOrigParentCategory)
  const isChangeFilter = useSelector(selectIsChangeFilter)

  useEffect(() => {
    if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getImportByProduct({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getImportByProductAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            vnTypeIds: unCollapseIds.toString(),
          }),
        )
      }
    }
    EventEmitter.dispatch(RESET_TABLE_SORT)
  }, [currentTypeFilter, currentTypeData, locale, importExportDataType])

  useEffect(() => {
    if (importExportDataType === TYPE_IMPORT_EXPORT.EXPORT) {
      if (!isKeepRowsCollapse) {
        dispatch(
          getExportByProduct({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
          }),
        )
      } else {
        dispatch(
          getExportByProductAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: LIMIT_FIRST_FETCH,
            vnTypeIds: unCollapseIds.toString(),
          }),
        )
      }
    }
  }, [currentTypeFilter, currentTypeData, locale, importExportDataType])

  useEffect(() => {
    if (!sortType && unCollapseIds.length) {
      if (importExportDataType === TYPE_IMPORT_EXPORT.EXPORT) {
        dispatch(
          getExportByProductAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            vnTypeIds: unCollapseIds.toString(),
            isAfterSort: true,
          }),
        )
      } else {
        dispatch(
          getImportByProductAfterChangeFilter({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            vnTypeIds: unCollapseIds.toString(),
            isAfterSort: true,
          }),
        )
      }
      dispatch(setDisableLoading(true))
    }
  }, [sortType])

  useEffect(() => {
    dispatch(changeCommodity(listOrigParentCategory[activeItemOrder]?.vnTypeId))
    if (currentTypeFilter !== TIME_FILTER_TYPE.YEARLY) {
      dispatch(
        changeActiveCategory(listOrigParentCategory[activeItemOrder]?.vnTypeId),
      )
    }
  }, [listOrigParentCategory])

  useEffect(() => {
    dispatch(changeLocaleForTurnOverChart(locale))
    dispatch(changeLocale(locale))
    dispatch(changeLocaleStructureChart(locale))
    dispatch(setLimitQuarter(0))
    dispatch(setIsKeepRowsCollapse(true))
  }, [locale])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <FilterList width={width} />
          <div className="mb-8">
            <Span
              style={{
                color: '#75797f',
                fontStyle: 'italic',
              }}
            >
              {`${I18n.t('economy.importExport.importExport.UNIT')}: ${
                currentTypeData === DATA_TYPE_LIST.VALUE
                  ? I18n.t('economy.importExport.importExport.MILLION_USD')
                  : I18n.t('economy.importExport.importExport.PERCENT')
              }`}
            </Span>
          </div>
          {size.height &&
            (isChangeFilter ? (
              <Loading />
            ) : (
              <TableComponent
                height={height - size.height}
                currentTypeData={currentTypeData}
              />
            ))}
        </>
      )}
    </SizeTracker>
  )
}
