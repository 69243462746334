import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import style from './NewChartOrDashboardBtn.module.css'

export const NewChartOrDashboardBtn = ({ title, redirect, renderIcon }) => {
  return (
    <Button className={style.item} onClick={redirect}>
      <span style={{ marginRight: 8, height: 14 }}>{renderIcon()}</span>
      <span>
        <Translate value={title} />
      </span>
    </Button>
  )
}

NewChartOrDashboardBtn.propTypes = {
  title: PropTypes.string,
  redirect: PropTypes.func,
  renderIcon: PropTypes.func,
}

NewChartOrDashboardBtn.defaultProps = {
  title: '',
  redirect: () => {},
  renderIcon: () => {},
}
