import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span.js'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice.js'
import { COLLATERAL_RADIO } from '../constants.js'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectFilterBondIssueInformation,
} from '../store/slice.js'
import { getBondIssueInformationData } from '../store/thunk.js'
import { FilterCompanyStatus } from './FilterCompanyStatus.js'

const Filter = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const { CompanyStatus, CompanyCollateral, Related } = useSelector(
    selectFilterBondIssueInformation,
  )

  const locale = useSelector((state) => state.i18n.locale)

  const handleChangeCollateral = (value) => {
    dispatch(
      changeFilter({
        label: keys.BOND_ISSUE_INFORMATION,
        key: 'CompanyCollateral',
        value,
      }),
    )
  }

  const handleChangeRelated = (value) => {
    dispatch(
      changeFilter({
        label: keys.BOND_ISSUE_INFORMATION,
        key: 'Related',
        value,
      }),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId && CompanyStatus && CompanyCollateral) {
      const payload = {
        OrganizationId: basicInfo.organizationId,
        CompanyStatus: !!CompanyStatus?.length
          ? CompanyStatus.toString().replace('All,', '')
          : null,
        CompanyCollateral,
        Related,
        locale,
      }

      dispatch(getBondIssueInformationData(payload))
    }
  }, [
    locale,
    basicInfo.organizationId,
    CompanyStatus.length,
    CompanyCollateral,
    Related,
  ])

  return (
    <div className="d-flex ali-center mb-8" style={{ gap: 16 }}>
      <div className="d-flex ali-center" style={{ gap: 8 }}>
        <Span>
          <Translate value="bond.corporateBond.bondIssueInformation.BOND_STATUS" />
        </Span>

        <div className="w-148">
          <FilterCompanyStatus />
        </div>
      </div>
      <div className="d-flex ali-center" style={{ gap: 8 }}>
        <Span>
          <Translate value="bond.corporateBond.bondIssueInformation.COLLATERAL" />
          :
        </Span>
        <div className="d-flex ali-center" style={{ gap: 16 }}>
          {COLLATERAL_RADIO.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center cursor-pointer
                  ${value === CompanyCollateral && style.radioActive}
                  `}
                onClick={() => handleChangeCollateral(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === CompanyCollateral}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div>
      </div>
      <label className="d-flex ali-center" style={{ gap: 8 }}>
        <input
          type="checkbox"
          className="checkbox"
          checked={Related}
          onChange={(e) => handleChangeRelated(e?.target?.checked ?? false)}
        />
        <Translate value="bond.corporateBond.bondIssueInformation.RELATED" />
      </label>
    </div>
  )
}

export default Filter
