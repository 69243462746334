import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import TableInformation from './TableInformation'
import style from './index.module.css'
import { getDataThunk } from './store/thunk'

const Table = () => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo?.organizationId) {
      dispatch(
        getDataThunk({
          OrganizationId: basicInfo?.organizationId,
        }),
      )
    }
  }, [basicInfo, locale])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className={style.header}>
              <Span style={{ textTransform: 'capitalize', fontWeight: 600 }}>
                <Translate value={'bond.interconnectionMap.table.title'} />
              </Span>
            </div>
            <div
              style={{
                height: `calc(100% - ${23}px)`,
              }}
            >
              <div
                style={{
                  height: `${31}px`,
                }}
              />
              <div
                style={{
                  height: `calc(100% - ${15}px)`,
                  // position: 'relative',
                }}
              >
                <TableInformation width={size.width} />
              </div>
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Table
