import { PropTypes } from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { CHART_TYPES } from '../../../common/chart/financialChart/constant'
import { Span } from '../../../common/html/Span'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { uuid } from '../../../utils/Common'
import { getUsedColorsBySecuritiesAndEquations } from '../../common/helpers/colorHelper'
import { dataFormatTypes } from '../../common/menuChartSettings/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { dashboardTypes, screenTypes } from '../../constants'
import PopupSaveChartToNewDashboard from '../../popup/popupSaveChartToNewDashboard'
import PopupWarningSave from '../../popup/PopupWarningSave'
import {
  changeDashboard,
  selectChartOfTheDay,
  selectDashboard,
  selectIndicatorsLatestPeriod,
} from '../../store/slice'
import { keyToolHeaderSettings } from '../constants'
import { randomColor } from '../helper'
import {
  changeEquations,
  changeStateNameChart,
  changSyncEquations,
  changSyncTreeSecuritiesIndicator,
  selectChartTabActive,
  selectChartTabsData,
  selectEquations,
  selectSettings,
  selectTreeSecuritiesIndicator,
  selectTypeChart,
  selectViewType,
} from '../store/slice'
import style from '../style.module.css'
import { AddEquations } from './AddEquations'
import AddSecurities from './AddSecurities'
import ButtonResetChart from './ButtonResetChart'
import ClearIndicator from './ClearIndicator'
import SecuritiesChildrenCompanies from './securitiesChildren/SecuritiesChildrenCompanies'
import SecuritiesChildrenEconomy from './securitiesChildren/SecuritiesChildrenEconomy'
import SecuritiesChildrenIndices from './securitiesChildren/SecuritiesChildrenIndices'
import SecuritiesChildrenSector from './securitiesChildren/SecuritiesChildrenSector'

export const TREE_SECURITIES_INDICATOR_ID = 'TREE_SECURITIES_INDICATOR_ID'

const Menu = ({ isEditChart, isCreateChart }) => {
  const dispatch = useDispatch()

  const { favoriteColors, defaultDashboard } = useGetUserSettings()

  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const typeChart = useSelector(selectTypeChart)
  const viewType = useSelector(selectViewType)
  const equations = useSelector(selectEquations)
  const chartTabs = useSelector(selectChartTabsData)
  const chartTabActive = useSelector(selectChartTabActive)
  const { data = {} } = useSelector(selectDashboard)
  const chartOfTheDay = useSelector(selectChartOfTheDay)
  const settings = useSelector(selectSettings)
  const indicatorLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const [isShowPopupWarningSave, setShowPopupWarningSave] = useState(false)
  const [isShowPopupSaveChart, setIsShowPopupSaveChart] = useState(false)

  const getUsedColors = useMemo(() => {
    return getUsedColorsBySecuritiesAndEquations(
      treeSecuritiesIndicator,
      equations,
    )
  }, [treeSecuritiesIndicator, equations])

  const onClick = () => {
    if (isCreateChart) {
      const params = {
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
      }
      if (!data) {
        params.data = defaultDashboard || chartOfTheDay
      }
      dispatch(changeDashboard(params))
    }
    setShowPopupWarningSave(true)
  }

  const handleChangeNameChart = (value) => {
    dispatch(changeStateNameChart(value))
  }

  const handleEquationApply = (equation) => {
    const dataKey = equation.dataKey || uuid()
    dispatch(
      changeEquations({
        key: equation.dataKey ? equation.order : Object.keys(equations).length,
        value: {
          order: equation.dataKey
            ? equation.order
            : Object.keys(equations).length,
          color: randomColor(getUsedColors, favoriteColors),
          visibility: true,
          dataKey: dataKey,
          chartType: typeChart,
          dataFormatType: dataFormatTypes.VALUE,
          multiplier: 1,
          chartParameters: {
            dataKeys: [dataKey],
            yAxisId: dataKey,
            orientation: 'right',
            isLineChart: typeChart === CHART_TYPES.LINE,
            labelText: equation.equationIndicatorName,
          },
          ...equation,
        },
      }),
    )
  }

  useEffect(() => {
    if (typeChart) {
      dispatch(
        changSyncTreeSecuritiesIndicator({
          keyUpdate: 'chartType',
          value: typeChart,
        }),
      )
      dispatch(
        changSyncEquations({
          keyUpdate: 'chartType',
          value: typeChart,
        }),
      )
    }
  }, [typeChart])

  return (
    <div className={style.container}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className={style.headerMenu}>
                <i
                  className={`icon-arrow-left-bold ${style.iconBack}`}
                  onClick={onClick}
                />
                <Span style={{ fontSize: 14, fontWeight: 600 }}>
                  <Translate
                    value={
                      isEditChart
                        ? 'financialChart.EDIT_CHART'
                        : 'financialChart.NEW_CHART'
                    }
                  />
                </Span>
              </div>
              <AddSecurities
                isCreateChart={isCreateChart}
                viewType={viewType}
              />
              {size.height && treeSecuritiesIndicator && (
                <div
                  id={TREE_SECURITIES_INDICATOR_ID}
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <ScrollComponent bgColor="#D9D9D9">
                    <>
                      {Object.keys(treeSecuritiesIndicator.companies).length ? (
                        <SecuritiesChildrenCompanies
                          companies={treeSecuritiesIndicator.companies}
                          indicatorLatestPeriod={indicatorLatestPeriod}
                          isViewByLatestPeriod={
                            settings[
                              keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD
                            ]
                          }
                        />
                      ) : null}
                      {Object.keys(treeSecuritiesIndicator.indices).length ? (
                        <SecuritiesChildrenIndices
                          indices={treeSecuritiesIndicator.indices}
                          indicatorLatestPeriod={indicatorLatestPeriod}
                          isViewByLatestPeriod={
                            settings[
                              keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD
                            ]
                          }
                        />
                      ) : null}
                      {Object.keys(treeSecuritiesIndicator.sector).length ? (
                        <SecuritiesChildrenSector
                          sectors={treeSecuritiesIndicator.sector}
                          indicatorLatestPeriod={indicatorLatestPeriod}
                          isViewByLatestPeriod={
                            settings[
                              keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD
                            ]
                          }
                        />
                      ) : null}
                      {Object.keys(treeSecuritiesIndicator.economy).length ? (
                        <SecuritiesChildrenEconomy
                          economies={treeSecuritiesIndicator.economy}
                          indicatorLatestPeriod={indicatorLatestPeriod}
                          isViewByLatestPeriod={
                            settings[
                              keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD
                            ]
                          }
                        />
                      ) : null}
                    </>
                  </ScrollComponent>
                </div>
              )}
              <AddEquations
                viewType={viewType}
                onApply={handleEquationApply}
                equations={equations}
              />
              <ButtonResetChart />
            </>
          )
        }}
      </SizeTracker>
      <ClearIndicator treeSecuritiesIndicator={treeSecuritiesIndicator} />
      <PopupWarningSave
        isShow={isShowPopupWarningSave}
        handleShow={setShowPopupWarningSave}
        isScreenCreateChart={!isEditChart}
        handleSaveChart={() => setIsShowPopupSaveChart(true)}
      />
      {isShowPopupSaveChart && (
        <PopupSaveChartToNewDashboard
          handleShow={setIsShowPopupSaveChart}
          chartTabActive={chartTabActive}
          chartTabs={chartTabs}
          onChangeNameChart={handleChangeNameChart}
        />
      )}
    </div>
  )
}

Menu.propTypes = {
  isEditChart: PropTypes.bool,
  isCreateChart: PropTypes.bool,
}

Menu.defaultProps = {
  isEditChart: false,
  isCreateChart: false,
}

export default Menu
