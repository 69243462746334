export const paymentBalance = {
  BALANCE_OF_PAYMENT: 'Cán cân thanh toán quốc tế',
  CHANGE_BALANCE: 'Tăng trưởng các chỉ tiêu trong cán cân thanh toán quốc tế',
  CHANGE_COMPONENTS: 'Thay đổi trong',

  FILTER_YEARLY: 'Hàng năm',
  FILTER_QUARTERLY: 'Hàng quý',

  UNIT: 'Đơn vị',
  MILLION_USD: 'Triệu USD',

  ALL: 'Tất cả',

  CURRENT_ACC: 'Cán cân vãng lai',
  CAPITAL_ACC: 'Cán cân Vốn',
  FINANCIAL_ACC: 'Cán cân tài chính',
  ERROR_OMISSION: 'Lỗi và sai sót',
  OVERALL_BALANCE: 'Cán cân tổng thể',
  RESERVED_RELATED: 'Dự trữ và các hạng mục liên quan',

  WARNING_MESS: 'Số lượng chỉ tiêu được chọn không vượt quá 20',
}
