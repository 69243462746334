import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { withWrapper } from '../../../../common/withWrapper'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import Filter from './Filter'
import PanelChangeInMarketShare from './changeInMarketShare/PanelChangeInMarketShare'
import PanelClaimRatio from './claimRatio/PanelClaimRatio'
import PanelDetailMetrics from './detailMetrics/PanelDetailMetrics'
import PanelMarketShare from './marketShare/PanelMarketShare'
import PanelRevenue from './revenue/PanelRevenue'
import { resetStore } from './store/slice'
import { getInsuMaxPeriod } from './store/thunk'

export const MAP_KEY = {
  WRAPPER: 'WRAPPER',
  REVENUE: 'REVENUE',
  MARKET_SHARE: 'MARKET_SHARE',
  CHANGE_IN_MARKET_SHARE: 'CHANGE_IN_MARKET_SHARE',
  CLAIM_RATIO: 'CLAIM_RATIO',
  DETAIL_METRICS: 'DETAIL_METRICS',
}

const HEIGHT_HEADER_WRAPPER = 60
const MIN_HEIGHT = 270

const MAP_SIZE = {
  [`${MAP_KEY.WRAPPER}`]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    minHeight: HEIGHT_HEADER_WRAPPER + 2 * MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REVENUE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: PANEL_PADDING / 2,
    minLeft: PANEL_PADDING / 2,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.MARKET_SHARE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.MARKET_SHARE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.CHANGE_IN_MARKET_SHARE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CLAIM_RATIO,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.MARKET_SHARE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.CHANGE_IN_MARKET_SHARE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHANGE_IN_MARKET_SHARE}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: HEIGHT_HEADER_WRAPPER,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 * 2 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + 4 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CLAIM_RATIO}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 + ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN
    }px)`,
    left: PANEL_PADDING / 2,
    minLeft: PANEL_PADDING / 2,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DETAIL_METRICS}`]: {
    width: `calc((100% - ${PANEL_PADDING}px) / 3 * 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 - ${COMPONENT.MARGIN}px)`,
    top: `calc((100% - ${HEIGHT_HEADER_WRAPPER}px) / 2 + ${
      HEIGHT_HEADER_WRAPPER + COMPONENT.MARGIN
    }px)`,
    left: `calc((100% - ${PANEL_PADDING}px) / 3 + ${
      COMPONENT.MARGIN + PANEL_PADDING / 2
    }px)`,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    minTop: HEIGHT_HEADER_WRAPPER + MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: 2 * COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const InsuranceContent = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getInsuMaxPeriod({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo.organizationId])

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <>
      <Panel
        title="corporate.businessModel.penetrationAnalysis.MARKET_SHARE"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.WRAPPER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </Panel>

      <PanelRevenue
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REVENUE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.REVENUE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.REVENUE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelMarketShare
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_SHARE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.MARKET_SHARE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.MARKET_SHARE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelChangeInMarketShare
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHANGE_IN_MARKET_SHARE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.CHANGE_IN_MARKET_SHARE].width - PANEL_PADDING}
        height={
          sizes[MAP_KEY.CHANGE_IN_MARKET_SHARE].height - HEADER_PANEL_HEIGHT
        }
      />
      <PanelClaimRatio
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CLAIM_RATIO}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.CLAIM_RATIO].width - PANEL_PADDING}
        height={sizes[MAP_KEY.CLAIM_RATIO].height - HEADER_PANEL_HEIGHT}
      />
      <PanelDetailMetrics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DETAIL_METRICS}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(InsuranceContent)
