import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { debounce } from '../../../../utils/Common'
import {
  resetIndicatorMapping,
  selectFilters,
  selectIndicatorById,
} from '../store/slice'
import {
  getDataFinancialTable,
  getExchangeRates,
  getIndicatorMapping,
} from '../store/thunk'

export const withGetData = (Component) => (props) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)
  const indicatorById = useSelector(selectIndicatorById)

  const handleGetData = useCallback(
    debounce((params, statement) => {
      dispatch(getDataFinancialTable({ ...params, statement }))
    }, 200),
    [],
  )

  // Use effect
  useEffect(() => {
    if (Object.keys(indicatorById).length) {
      const params = {
        OrganizationId: fundInfo.organizationId,
        TimeFilter: filters.viewBy,
        NumberOfPeriod: filters.numberOfPeriod,
        LatestYear: filters.latestYear,
      }
      handleGetData(params, filters.statement)
    }
  }, [
    fundInfo.organizationId,
    filters.viewBy,
    filters.numberOfPeriod,
    filters.latestYear,
    JSON.stringify(indicatorById),
  ])

  useEffect(() => {
    dispatch(resetIndicatorMapping())
    dispatch(getIndicatorMapping())
  }, [locale])

  useEffect(() => {
    if (filters.unit) {
      dispatch(getExchangeRates({ CurrencyCode: 'USD' || filters.unit }))
    }
  }, [filters.unit])

  return <Component {...props} />
}
