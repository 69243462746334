import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import Popup from '../../../../../common/popup'
import { Search } from '../../../../../common/search'
import { DEFAULT_INDICATOR_CATEGORY, INDICATOR_TYPE } from '../../constant'
import { formatDateByPeriodType } from '../../helper'
import {
  changePointInTime,
  changeShowPopupIndicator,
  changeXIndicator,
  changeXIndicatorCategory,
  changeYIndicator,
  changeYIndicatorCategory,
  selectFullIndicatorInfoById,
  selectIndicatorInfoById,
  selectIsShowPopupIndicator,
  selectPopupIndicatorType,
  selectXIndicator,
  selectXIndicatorCategory,
  selectYIndicator,
  selectYIndicatorCategory,
} from '../../store/slice'
import Category from './Category'
import Metric from './Metric'
import style from './index.module.css'

const IndicatorPopup = () => {
  const dispatch = useDispatch()

  const isShow = useSelector(selectIsShowPopupIndicator)
  const popupType = useSelector(selectPopupIndicatorType)
  const fullIndicatorInfoById = useSelector(selectFullIndicatorInfoById)
  const indicatorCategory = useSelector(
    popupType === INDICATOR_TYPE.X
      ? selectXIndicatorCategory
      : selectYIndicatorCategory,
  )
  const activeIndicator = useSelector(
    popupType === INDICATOR_TYPE.X ? selectXIndicator : selectYIndicator,
  )

  const [category, setCategory] = useState(null)
  const [indicator, setIndicator] = useState(null)
  const [dateTime, setDate] = useState({})

  const periodType = useSelector(
    selectIndicatorInfoById(indicator, 'periodType'),
  )
  const [keyword, setKeyword] = useState('')
  const [isReloadSearch, setIsReloadSearch] = useState(false)

  useEffect(() => {
    setCategory(indicatorCategory || DEFAULT_INDICATOR_CATEGORY)
  }, [indicatorCategory])

  useEffect(() => {
    setIndicator(activeIndicator)
  }, [activeIndicator])

  const handleCancel = () => {
    dispatch(changeShowPopupIndicator({ isShow: false }))
  }

  const handleApply = () => {
    dispatch(
      popupType === INDICATOR_TYPE.X
        ? changeXIndicatorCategory(category)
        : changeYIndicatorCategory(category),
    )
    dispatch(
      popupType === INDICATOR_TYPE.X
        ? changeXIndicator(indicator)
        : changeYIndicator(indicator),
    )
    dispatch(
      changePointInTime({
        indicatorType: popupType,
        data: { [periodType]: formatDateByPeriodType(dateTime, periodType) },
      }),
    )

    dispatch(changeShowPopupIndicator({ isShow: false }))
  }

  const handleSetCategory = (value) => {
    setKeyword('')
    setCategory(value)
    setIsReloadSearch(!isReloadSearch)
  }

  const handleSetIndicator = (value) => {
    setIndicator(value)
    if (!category) {
      const parentIndicatorId =
        fullIndicatorInfoById[value]?.parentIndicatorId || null
      setCategory(parentIndicatorId)
    }
  }

  const handleSetKeyword = (value) => {
    setKeyword(value)
    setCategory(null)
  }

  return isShow ? (
    <Popup>
      <div className="modal modal-medium" style={{ minWidth: 900 }}>
        <div className="modal-title">
          <h3 className="text-up">
            <Translate
              value={`sector.sectorConstituents.segmentation.${
                popupType === INDICATOR_TYPE.X ? 'X_ASIS' : 'Y_ASIS'
              }`}
            />
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className="mb-15">
            <Search
              key={isReloadSearch}
              onChange={handleSetKeyword}
              appendStyle={{
                margin: 0,
                width: 212,
              }}
            />
          </div>
          <div className={style.modalContent}>
            <Category
              category={category}
              setCategory={handleSetCategory}
              keyword={keyword}
            />
            <Metric
              category={category}
              indicator={indicator}
              keyword={keyword}
              setIndicator={handleSetIndicator}
              setDate={setDate}
            />
          </div>
          <div className={style.groupButtonModal}>
            <Button className="btn bg-grey-1 w-80 h-20" onClick={handleCancel}>
              <Translate value={'common.button.BUTTON_CANCEL'} />
            </Button>
            <Button onClick={handleApply} className={`btn btn-blue w-80 h-20 `}>
              <Translate value={'common.button.BUTTON_APPLY'} />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  ) : (
    <></>
  )
}

export default IndicatorPopup
