import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Panel } from '../../common/panel'
import Setting from './setting'
import { getActiveTemplate, getSettingById } from './template/store/slice'

const SettingPanel = ({ panelRefs, panelKey, sizes, setSizes, bodyClass }) => {
  const activeTemplateId = useSelector(getActiveTemplate)
  const activeTemplate = useSelector(getSettingById(activeTemplateId))

  return (
    <Panel
      title={
        I18n.t('newsReport.dailyReport.dailyReport.SETTING') +
        (activeTemplate?.name || '')
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      bodyClass={bodyClass}
    >
      <Setting />
    </Panel>
  )
}

export default SettingPanel
