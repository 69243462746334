import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { screenTypes } from '../../constants'
import { ReactComponent as IconShare } from '../../icon/icon-share.svg'
import PopupShareChart from '../../popup/PopupShareChart'
import { selectDashboard } from '../../store/slice'
import style from './index.module.css'

const ShareBtn = ({ dashboardID }) => {
  const { screenType } = useSelector(selectDashboard)
  const [showPopupShare, setShowPopupShare] = useState(false)

  const handleShareChart = () => {
    setShowPopupShare(true)
  }
  return (
    <>
      {screenType === screenTypes.EDIT_CHART ? (
        <IconShare
          className={style.disable}
          title={I18n.t(
            'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_DASHBOARD',
          )}
          style={{ fontSize: 15 }}
        />
      ) : (
        <IconShare
          style={{ fontSize: 15 }}
          className="cursor-pointer"
          title={I18n.t('financialChart.SHARE_DASHBOARD')}
          onClick={handleShareChart}
        />
      )}
      {showPopupShare ? (
        <PopupShareChart
          handleShow={setShowPopupShare}
          id={dashboardID}
          type="dashboard"
        />
      ) : null}
    </>
  )
}

export default ShareBtn
