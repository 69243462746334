import { useSelector } from 'react-redux'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import { selectEventValue } from '../../store/slice'

export const EVENT_TABLE_ID = 'event-table-id'

const TickerCell = ({ val, rowId }) => {
  const organizationId = useSelector(selectEventValue(rowId, 'organizationId'))
  const eventTypeId = useSelector(selectEventValue(rowId, 'fiinXEventTypeId'))

  return (
    <>
      <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
      {/* {eventTypeId ? (
        <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
      ) : (
        val
      )} */}
    </>
  )
}

export default TickerCell
