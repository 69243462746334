import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'

const Radio = ({ data, value, containerClass, itemClass, onChange }) => {
  return (
    <ul className={['list-check', containerClass].join(' ')}>
      {data.map((item, index) => (
        <li key={index} className={itemClass}>
          <label>
            <input
              type="checkbox"
              className="radiobox radiobox2"
              value={item.value}
              checked={value === item.value ? true : false}
              onChange={() => onChange(item.value)}
            />
            <Translate value={item.title} />
          </label>
        </li>
      ))}
    </ul>
  )
}

Radio.propTypes = {
  data: PropTypes.array.isRequired,
  value: PropTypes.any.isRequired,
  containerClass: PropTypes.string,
  itemClass: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default Radio
