import PropTypes from 'prop-types'

export const ReportDownload = ({ data }) => {
  return (
    <a className="cursor-pointer" href={data}>
      <i className="icon-download" />
    </a>
  )
}

ReportDownload.propTypes = {
  data: PropTypes.string,
}
