import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../constants/Common'
import { debounce } from '../../utils/Common'
import { ScrollComponent } from '../ScrollComponent'
import UseI18n from '../hooks/useI18n'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { Button } from '../html/Button'
import { Input } from '../html/Input'
import { Span } from '../html/Span'
import Popup from '../popup'
import style from './popupSortColumn.module.css'

let classItem = 'itemSelect'
let initialW = 0
let initialH = 0
let tableDragClone = null
const newLine = '\n'
const IPAD_RATIO = 2

const enterKey = 13

const doObjectsCollide = (a, b) => {
  const aTop = a.offsetTop
  const aLeft = a.offsetLeft
  const bTop = b.offsetTop
  const bLeft = b.offsetLeft

  return !(
    aTop + a.offsetHeight < bTop ||
    aTop > bTop + b.offsetHeight ||
    aLeft + a.offsetWidth < bLeft ||
    aLeft > bLeft + b.offsetWidth
  )
}

const ItemColumn = ({
  index,
  item,
  idKey,
  selected,
  sortedColumn,
  setSortedColumn,
  setSelected,
  valueSearch,
  selectIndicatorById,
  columnIdCheck,
  setColumnIdCheck,
  getDetailColumn,
}) => {
  const indicatorById = useSelector(selectIndicatorById)

  const inputRef = useRef()
  const lastValue = useRef(+index + 1)

  const [isShowEdit, setIsShowEdit] = useState(false)
  const [valueInput, setValueInput] = useState(+index + 1)

  useEffect(() => {
    setValueInput(+index + 1)
  }, [index])

  const handleBlurInput = () => handleSubmit()

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (valueInput) {
      const thisIndex = +index
      const newSortedColumn = [...sortedColumn]
      newSortedColumn.splice(thisIndex, 1)
      newSortedColumn.splice(+valueInput - 1, 0, item)
      setSortedColumn(newSortedColumn)
    }
    hideEdit()
  }

  const handleChangeInput = (e) => {
    if (
      (e.target.value.match(/^[0-9]+$/) &&
        +e.target.value > 0 &&
        +e.target.value <= sortedColumn.length) ||
      e.target.value === ''
    ) {
      lastValue.current = e.target.value
      setValueInput(e.target.value)
    } else setValueInput(lastValue.current)
  }

  const showEdit = () => {
    setIsShowEdit(true)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const hideEdit = () => setIsShowEdit(false)

  const onDragStart = (e) => {
    e.dataTransfer.setData('text', '')
    const elSelected = Array.from(
      document.querySelectorAll(`.${classItem}`),
    ).filter((el) => {
      return selected[el.getAttribute('idx')]
    })
    tableDragClone = e.target.closest('table').cloneNode(true)
    tableDragClone.classList.remove('w-100')
    tableDragClone.style.width = e.target.offsetWidth + 'px'
    tableDragClone.style.backgroundColor = '#151b25'
    tableDragClone.innerHTML = `
    <tbody>
    ${elSelected.map((el) => `<tr>${el.outerHTML}</tr>`).join('')}
    </tbody>`
    document.body.appendChild(tableDragClone)
    e.dataTransfer.setDragImage(tableDragClone, e.target.offsetWidth / 2, 20)
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragEnd = () => {
    document.body.removeChild(tableDragClone)
    tableDragClone = null
    setSelected({})
  }

  const onDrop = (e) => {
    e.preventDefault()
    if (!selected[index]) {
      const newSortColumn = []
      const arrReOrder = []
      let isArrReOrderBeforeItemDrop = true
      sortedColumn.forEach((column, idx) => {
        if (selected[idx]) {
          if (idx > index) {
            isArrReOrderBeforeItemDrop = false
          }
          arrReOrder.push(column)
        } else {
          newSortColumn.push(column)
        }
      })
      const dropIndex =
        newSortColumn.findIndex(
          (itemColumn) => itemColumn[idKey] === item[idKey],
        ) + (isArrReOrderBeforeItemDrop ? 0 : -1)
      setSortedColumn(
        newSortColumn
          .slice(0, dropIndex + 1)
          .concat(arrReOrder)
          .concat(newSortColumn.slice(dropIndex + 1, newSortColumn.length)),
      )
    }
  }

  const stopOpenSelectItem = (e) => {
    if (selected[index]) {
      e.stopPropagation()
    }
  }

  const mouseUp = () => {
    if (selected[index]) {
      setSelected({})
    }
  }

  const handleChange = (e) => {
    e.stopPropagation()
    if (e.target.checked) {
      setColumnIdCheck(columnIdCheck.concat([item[idKey]]))
    } else {
      const newColumnIdCheck = [...columnIdCheck]
      newColumnIdCheck.splice(newColumnIdCheck.indexOf(item[idKey]), 1)
      setColumnIdCheck(newColumnIdCheck)
    }
  }

  const stopClickBubble = (e) => e.stopPropagation()

  const trStyle = selected[index]
    ? { backgroundColor: '#535f6f', borderBottom: 'unset' }
    : valueSearch.trim() &&
      indicatorById[item.indicatorId]?.indicatorName
        ?.toUpperCase()
        .includes(valueSearch.toUpperCase())
    ? { border: 'solid 2px #2f9aee' }
    : undefined

  return (
    <tr
      style={trStyle}
      onMouseUp={mouseUp}
      onMouseDown={stopOpenSelectItem}
      onTouchStart={stopOpenSelectItem}
      onTouchEnd={mouseUp}
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
      idx={index}
      className={classItem}
      draggable={selected[index] || false}
    >
      <td>
        {!isShowEdit ? (
          <div onDoubleClick={showEdit}>{index + 1}</div>
        ) : (
          <div className={style.inputColumn}>
            <input
              value={valueInput}
              ref={inputRef}
              onBlur={handleBlurInput}
              onKeyDown={handlePressEnter}
              onChange={handleChangeInput}
              type="text"
            />
          </div>
        )}
      </td>
      <td>
        <div>{indicatorById[item.indicatorId]?.indicatorName}</div>
        {getDetailColumn && (
          <div className={style.textDetail}>
            {getDetailColumn(item)
              .replaceAll(indicatorById[item.indicatorId]?.indicatorName, '')
              .trim()
              .replaceAll(newLine, '_')}
          </div>
        )}
      </td>
      <td>
        <div className="d-flex justify-content-center">
          <input
            onMouseDown={stopClickBubble}
            onTouchStart={stopClickBubble}
            onChange={handleChange}
            type="checkbox"
            className="checkbox"
            checked={columnIdCheck.includes(item[idKey])}
          />
        </div>
      </td>
    </tr>
  )
}

const PopupSortColumn = ({
  selectColumnId,
  selectColumnById,
  selectIndicatorById,
  idKey,
  handleClosePopup,
  handleSubmit,
  getDetailColumn,
  isDisableDelete,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const indicatorById = useSelector(selectIndicatorById)
  const columnId = useSelector(selectColumnId)
  const columnById = useSelector(selectColumnById)

  const [sortedColumn, setSortedColumn] = useState([])
  const [selected, setSelected] = useState({})
  const [valueSearch, setValueSearch] = useState('')
  const [columnIdCheck, setColumnIdCheck] = useState([])
  const [columnIdDelete, setColumnIdDelete] = useState([])

  const ghostSelectRef = useRef()
  const tableRef = useRef()

  useEffect(() => {
    setSortedColumn(columnId.map((id) => columnById[id]))
  }, [columnId, columnById])

  useEffect(() => {
    handleSearch(valueSearch.trim())
  }, [valueSearch])

  const apply = () =>
    handleSubmit({
      newColumnId: sortedColumn.map((item) => item[idKey]),
      columnDelete: columnIdDelete,
    })

  const startSelector = (e) => {
    const pageX = formatWithZoom(e.pageX) || e.touches?.[0]?.pageX || 0
    const pageY = formatWithZoom(e.pageY) || e.touches?.[0]?.pageY || 0

    const top =
      pageY -
      tableRef.current.getBoundingClientRect().top +
      tableRef.current.querySelector('.scrollbars > div:first-child').scrollTop
    const left = pageX - tableRef.current.getBoundingClientRect().left
    ghostSelectRef.current.classList.add(style.ghostActive)
    ghostSelectRef.current.style.left = left + 'px'
    ghostSelectRef.current.style.top = top + 'px'

    initialW = left
    initialH = top
    document.addEventListener('mouseup', selectElements)
    tableRef.current.addEventListener('mousemove', openSelector)
    document.addEventListener('touchend', selectElements)
    tableRef.current.addEventListener('touchmove', openSelector)
  }

  const openSelector = (e) => {
    const pageX = formatWithZoom(e.pageX) || e.touches?.[0]?.pageX || 0
    const pageY = formatWithZoom(e.pageY) || e.touches?.[0]?.pageY || 0
    const ratio =
      e.touches?.[0]?.pageY || e.touches?.[0]?.pageY === 0 ? IPAD_RATIO : 1
    const top =
      pageY / ratio -
      tableRef.current.getBoundingClientRect().top +
      tableRef.current.querySelector('.scrollbars > div:first-child').scrollTop
    const left = pageX / ratio - tableRef.current.getBoundingClientRect().left
    const w = Math.abs(initialW - left)
    const h = Math.abs(initialH - top)

    ghostSelectRef.current.style.width = w + 'px'
    ghostSelectRef.current.style.height = h + 'px'

    if (left <= initialW && top >= initialH) {
      ghostSelectRef.current.style.left = left + 'px'
    } else if (top <= initialH && left >= initialW) {
      ghostSelectRef.current.style.top = top + 'px'
    } else if (top < initialH && left < initialW) {
      ghostSelectRef.current.style.left = left + 'px'
      ghostSelectRef.current.style.top = top + 'px'
    }
  }

  const selectElements = () => {
    document.removeEventListener('mouseup', selectElements)
    tableRef.current.removeEventListener('mousemove', openSelector)
    document.removeEventListener('touchend', selectElements)
    tableRef.current.removeEventListener('touchmove', openSelector)
    const elSelected = {}
    Array.from(document.querySelectorAll(`.${classItem}`)).forEach((el) => {
      const result = doObjectsCollide(ghostSelectRef.current, el)

      if (result) {
        elSelected[el.getAttribute('idx')] = true
      } else {
        elSelected[el.getAttribute('idx')] = false
      }
    })
    setSelected(elSelected)

    ghostSelectRef.current.classList.remove(style.ghostActive)
    ghostSelectRef.current.style.width = '0px'
    ghostSelectRef.current.style.height = '0px'
  }

  const handleSearch = useCallback(
    debounce((valueSearch) => {
      if (valueSearch) {
        const indexScroll = sortedColumn.findIndex((item) =>
          indicatorById[item.indicatorId]?.indicatorName
            ?.toUpperCase()
            .includes(valueSearch.toUpperCase()),
        )
        tableRef.current.querySelector('.scrollbars > div:first-child').scroll({
          top: Array.from(document.querySelectorAll(`.${classItem}`))[
            indexScroll
          ]?.offsetTop,
        })
      }
    }, 150),
    [sortedColumn],
  )

  const handleChangeSearch = (e) => setValueSearch(e.target.value)

  const handleCheckAll = () =>
    setColumnIdCheck(sortedColumn.map((column) => column[idKey]))

  const handleUncheckAll = () => setColumnIdCheck([])

  const handleDeleteTicker = () => {
    setColumnIdDelete(columnIdDelete.concat(columnIdCheck))
    setSortedColumn(
      sortedColumn.filter((column) => !columnIdCheck.includes(column[idKey])),
    )
    setColumnIdCheck([])
  }

  const search = UseI18n('common.popup.SEARCH')

  const isHaveCheck = !!columnIdCheck.length

  return (
    <Popup>
      <div className={`modal tool-page ${style.popupSortColumn}`}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="common.popup.SORT_INDICATOR" />
            </Span>
          </h3>
          <a onClick={handleClosePopup} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={style.noteSortColumn}>
            <Span style={{ fontSize: 11 }}>
              <Translate value="common.popup.SORT_COLUMN_NOTE" />
            </Span>
          </div>
          <div className="d-flex justify-content-end">
            <div className={`form-search-inbox m-0 ${style.searchColumn}`}>
              <Input
                onChange={handleChangeSearch}
                type="text"
                className="search-inbox"
                placeholder={search}
                value={valueSearch}
              />
              <button type="button">
                <i className="icon-search-2" />
              </button>
            </div>
          </div>
          <div className="content mt-8">
            <div className="w-100">
              <div
                ref={tableRef}
                onMouseDown={startSelector}
                onTouchStart={startSelector}
                className={`col-content ${style.tableSortColumnContainer}`}
              >
                <ScrollComponent>
                  <div
                    ref={ghostSelectRef}
                    className={style.ghostSelect}
                    style={{ zIndex: Z_INDEX.MODAL + 1 }}
                  >
                    <span></span>
                  </div>
                  <div className={style.tableContainer}>
                    <table className={`w-100 ${style.tableSortColumn}`}>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th>
                            <div
                              className={`d-flex ali-center justify-content-center ${style.checkboxContainer}`}
                            >
                              {!isHaveCheck ? (
                                <label
                                  className={`d-flex justify-content-center ${style.checkboxAll}`}
                                >
                                  <input
                                    type="checkbox"
                                    className={`checkbox line cursor-pointer ${style.inputCheckAll}`}
                                    onClick={handleCheckAll}
                                    checked={false}
                                    readOnly
                                  />
                                </label>
                              ) : (
                                <div className={style.btnMinus}>
                                  <button
                                    onClick={handleUncheckAll}
                                    className="cursor-pointer"
                                  >
                                    <span>-</span>
                                  </button>
                                </div>
                              )}
                              <div className={style.checkAllBorder} />
                              <button
                                disabled={isDisableDelete}
                                onClick={handleDeleteTicker}
                                className={`${style.btnTrash} ${
                                  isDisableDelete ? style.disabled : ''
                                }`}
                              >
                                <i className="icon-trash" />
                              </button>
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {sortedColumn.map((item, index) => (
                          <ItemColumn
                            key={index}
                            index={index}
                            item={item}
                            idKey={idKey}
                            selected={selected}
                            sortedColumn={sortedColumn}
                            setSortedColumn={setSortedColumn}
                            setSelected={setSelected}
                            valueSearch={valueSearch}
                            selectIndicatorById={selectIndicatorById}
                            columnIdCheck={columnIdCheck}
                            setColumnIdCheck={setColumnIdCheck}
                            getDetailColumn={getDetailColumn}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </ScrollComponent>
              </div>
            </div>
          </div>
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleClosePopup}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              onClick={apply}
              style={{ fontSize: 11 }}
              className={`btn btn-blue w-80 h-20`}
            >
              <Translate value="tool.smartScreening.APPLY" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupSortColumn.propTypes = {
  selectColumnId: PropTypes.func.isRequired,
  selectColumnById: PropTypes.func.isRequired,
  selectIndicatorById: PropTypes.func.isRequired,
  idKey: PropTypes.string.isRequired,
  handleClosePopup: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  getDetailColumn: PropTypes.func,
  isDisableDelete: PropTypes.bool,
}

PopupSortColumn.defaultProps = {
  isDisableDelete: false,
}

export default PopupSortColumn
