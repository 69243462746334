export const common = {
  table: {
    TABLE_FOOTER_TEXT: 'Price: x 1,000. Volume: x 1,000. Value: x 1,000,000',
    SIMPLE_TABLE_FOOTER_TEXT: 'Price: x 1,000. Volume: x 1,000',
    PRICE_TABLE_FOOTER_TEXT: 'Price: x 1,000',
    PRICE_VALUE_FOOTER_TEXT: 'Price: x 1,000. Value: x 1,000,000.',
    RESULT: 'Number of result: 2345 results',
    PAGE_RESULT: 'Page',
    PAGE_RESULT_FINALLY: 'of 1024',
    LB_PAGE: 'Page',
    LB_OF: 'of',
  },
  button: {
    BUTTON_CREATE: 'Create',
    BUTTON_SAVE: 'Save',
    BUTTON_SAVE_AS: 'Save as',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_BACK: 'Back',
    BUTTON_OK: 'Ok',
    BUTTON_RESET: 'Reset',
    BUTTON_APPLY: 'Apply',
    BUTTON_DELETE: 'Delete',
    BUTTON_YES: 'Yes',
    BUTTON_NO: 'No',
    BUTTON_WATCH_LIST: 'Watchlist',
    BUTTON_CLOSE: 'Close',
    BUTTON_SEARCH: 'Search',
    BUTTON_EDIT_NAME: 'Rename',
    BUTTON_CHANGE_CORPORATE: 'Corporate',
    BUTTON_CHANGE_BOND: 'Bond',
    BUTTON_NO_SAVE: "Don't Save",
    BUTTON_EDIT: 'Edit',
  },
  calendar: {
    DAY: 'Day',
    WEEK: 'Week',
    MONTH: 'Month',
    QUARTER: 'Quarter',
    YEAR: 'Year',
    FROM: 'From',
    TO: 'To',
  },
  NO_DATA: 'No Data',
  DATA_NOT_AVAILABLE: 'Data is not available',
  SEARCH: 'Search',
  FILTER: 'Filter',
  ALL: 'All',
  ALL_FREQUENCY: 'All Frequencies',
  FREE: 'Free',
  CONFIRM_REDIRECT: 'Changes you made may not be saved.',
  INDUSTRY_LEVEL: 'Industry Level ',
  INDUSTRY_LEVEL_COMPACT: 'Level ',
  LABEL_HOHAUP: 'HOHAUP',
  preOpenMarket: {
    marketPreOpenTitle: 'The market will be open in',
  },
  shareButton: {
    SHARE: 'Share',
    COPIED_TO_CLIPBOARD: 'Copied to clipboard',
    COPY_LINK: 'Copy link',
  },
  tradingView: {
    LOAD_TEMPLATE: 'Load Template',
    SAVE_TEMPLATE: 'Save Template',
    SHARE: 'Share',
    NAME_OF_TEMPLATE: 'Name of template:',
    DESCRIPTION: 'Description:',
    TEMPLATE_LIBRARY: 'Template Library',
    MY_TEMPLATE: 'My Template',
    DELETE_TEMPLATE: 'Delete Template',
    REVENUE: 'Revenue',
    PROFIT: 'Profit',
    PERIOD_ENDING: 'Period',
    DIVIDENDS: 'Dividends',
    EX_RATIO: 'Exercise Rate',
    EXRIGHT_DATE: 'Ex-right Date',
    ISSUE_METHOD: 'Issue Method',
    VOLUME: 'Volume',
    STOCK: 'Stock',
    FUTURES: 'Futures',
    INDICES: 'Indices',
    ECONOMY: 'Economy',
    COVERED_WARRANT: 'Covered Warrant',
    ALL: 'All',
  },
  linkSectorTicker: {
    SECTOR_OVERVIEW: 'Sector Overview',
    FINANCIAL_ANALYSIS: 'Financial Analysis',
    SEGMENTATION: 'Segmentation',
    RANKING: 'Ranking',
    RATIO: 'Ratio',
    VALUATION: 'Valuation',
    COMPANY_OVERVIEW: 'Company Overview',
    COMPANY_NEWS: 'Company News',
    FINANCIAL_DATA: 'Financial Data',
    OWNERSHIP: 'Ownership',
    TECHNICAL_CHART: 'Technical Chart',
  },
  popup: {
    DONT_SHOW_AGAIN: 'Don’t show again',
    ICB_LEVEL: 'ICB Level',
    INDUSTRY_LEVEL: 'Industry Level',
    DIVIDEND_YEAR: 'Dividend Year',
    DIVIDEND_STAGE: 'Dividend Stage',
    MOST_RECENT: 'Most Recent',
    ALL: 'All',
    YEAR: 'Year',
    SELECT: 'Selected:',
    MAX_MORE_CONDITION: 'The number of indicators is no more than 250.',
    MOST_RECENT_2: 'Most Recent',
    DATE: 'Date',
    SIX_MONTH_AGO: '6 Months Ago',
    ONE_WEEK_AGO: '1 Week Ago',
    NINE_MONTH_AGO: '9 Months Ago',
    ONE_MONTH_AGO: '1 Month Ago',
    ONE_YEAR_AGO: '1 Year Ago',
    THREE_MONTH_AGO: '3 Months Ago',
    TWO_YEAR_AGO: '2 Years Ago',
    STOCK_DIVIDEND: 'Stock Dividend',
    YEAR_OF_REVIEW: 'Year of Review',
    ISSUE_METHOD: 'Issue Method',
    STAGE: 'Stage',
    ACTION: 'Action',
    ERROR_QUARTER: 'Please choose a “Quarter” to show TTM ratios.',
    ERROR_TYPE: 'Please choose one of the ratio type: TTM or Yearly ratios.',
    OF_TOTAL_TABLE: ' of total indicators added in the result table.',
    TITLE_TTM: 'Trailing Twelve Months (TTM) Ratios',
    MULTIPLE_REPORTING: 'Multiple Reporting',
    QUARTER: 'Quarter',
    TITLE_YEAR: 'Yearly Ratios',
    TO: 'to',
    INDICATOR: 'Indicator',
    SEARCH: 'Search',
    SELECT_ITEM: 'Selected items',
    PERIOD: 'Period',
    MOST_YEAR_RECENT: 'Most Year Recent',
    MOST_QUARTER_RECENT: 'Most Quarter Recent',
    REPORT_TYPE: 'Report Type',
    AUDIT: 'Audit',
    CONSOLIDATED: 'Consolidated',
    UNCONSOLIDATED: 'Unconsolidated',
    BOTH_AUDIT: 'Both audited and unaudited',
    AUDITED: 'Audited',
    UNAUDITED: 'Unaudited',
    SIX_MONTH: '6 months',
    NINE_MONTH: '9 months',
    ANNUAL: 'Annual',
    SETTING_GROUP_NOTE: 'Synchronized settings of selected indicators.',
    SORT_COLUMN_NOTE:
      'Rearranging the order of tickers by dragging the ticker into a desired position or double clicking into the number and replacing a new ordinal number for the ticker.',
    SORT_INDICATOR: 'SORT INDICATOR',
  },
  news: {
    VIEW_ALL: 'View all',
    NEWS_TYPE: 'News type',
    DETAIL_NEWS_TYPE: 'Detail News type',
    SOURCE: 'Source',
    TIME: 'Time',
    time: {
      All: 'All',
      'Last 7 days': 'Last 7 days',
      'Last month': 'Last month',
      'Last 3 months': 'Last 3 months',
      'Last 6 months': 'Last 6 months',
      'Last year': 'Last year',
      Custom: 'Custom',
    },
  },
  noteIcb: {
    NOTE_1: "* Refer to FiinGroup's Industry Classification Methodology ",
    NOTE_2: 'here.',
  },
  FUNCTION_COMING_SOON: 'Feature under development',
  MONTH: {
    JAN: 'Jan',
    FEB: 'Feb',
    MAR: 'Mar',
    APR: 'Apr',
    MAY: 'May',
    JUN: 'Jun',
    JUL: 'Jul',
    AUG: 'Aug',
    SEP: 'Sep',
    OCT: 'Oct',
    NOV: 'Nov',
    DEC: 'Dec',
  },
  MONTHLY_OPTION: {
    MONTH_1: 'Jan',
    MONTH_2: 'Feb',
    MONTH_3: 'Mar',
    MONTH_4: 'Apr',
    MONTH_5: 'May',
    MONTH_6: 'Jun',
    MONTH_7: 'Jul',
    MONTH_8: 'Aug',
    MONTH_9: 'Sep',
    MONTH_10: 'Oct',
    MONTH_11: 'Nov',
    MONTH_12: 'Dec',
  },
  OTHERS: 'Others',
  MONTHS: 'months',
  YEARS: 'years',
  UNIT: {
    PERCENT: '%',
    VND: 'VND',
    THOUSAND_VND: 'Th. VND',
    MILLION_VND: 'Mil. VND',
    BILLION_VND: 'Bil. VND',
    USD: 'USD',
    THOUSAND_USD: 'Th. USD',
    MILLION_USD: 'Mil. USD',
    BILLION_USD: 'Bil. USD',
  },
  FREQUENCY: {
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',
  },
}
