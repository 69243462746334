import { Translate } from 'react-i18nify'
import style from '../style.module.css'

export const ItemCheckAll = ({ checked, onChange }) => {
  return (
    <div className={style.itemTicker} onClick={onChange}>
      <div className={`ml-8 ${style.checkBox}`}>
        <label>
          <input
            type="checkbox"
            className={`checkbox line `}
            checked={checked}
            readOnly
          />
        </label>
      </div>
      <div className={style.tickerInfo}>
        <div className={style.securitiesName}>
          <Translate value="financialChart.ALL" />
        </div>
      </div>
    </div>
  )
}
