import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import PopupDeleteChart from '../popup/PopupDeleteChart'
import style from './Header.module.css'

export const Header = ({
  chartLibrary,
  isSelect,
  setIsSelect,
  handleCheckAll,
  chartsChecked,
}) => {
  const [isShow, setIsShow] = useState(false)

  const handleSelect = () => {
    if (chartLibrary.length) {
      setIsSelect(true)
    }
  }

  const handleCancel = () => {
    setIsSelect(false)
    handleCheckAll(false)
  }

  return (
    <>
      <div className={`${style.topContainer}`}>
        <div className={style.topTitle}>
          <Span style={{ fontSize: 20 }}>
            <Translate value="financialChart.MY_CHART_LIBRARY" />
          </Span>
        </div>
        {isSelect ? (
          <Button className={`${style.topBtn} `} onClick={handleCancel}>
            <Translate value="financialChart.CANCEL" />
          </Button>
        ) : (
          <Button
            className={`${style.topBtn} ${
              chartLibrary.length ? '' : style.disable
            }`}
            onClick={handleSelect}
          >
            <Translate value="financialChart.SELECT" />
          </Button>
        )}
      </div>
      {isSelect && (
        <div className={style.bottomContainer}>
          <div className={style.bottomLeft}>
            <Button
              className={`${style.btnGray} ${
                !chartsChecked.length && style.disable
              }`}
              onClick={() => setIsShow(true)}
            >
              <i className="icon-trash" />
              <Translate value="financialChart.BTN_DELETE" />
            </Button>
          </div>
          <div className={style.bottomRight}>
            <label htmlFor="checkAll">
              <Span>
                <Translate value="financialChart.SELECT_ALL" />
              </Span>
            </label>
            <Input
              type="checkbox"
              className={`checkbox cursor-pointer ${style.checkbox}`}
              name="checkAll"
              onChange={(e) => handleCheckAll(e.target.checked)}
              checked={chartsChecked.length === chartLibrary.length}
            />
          </div>
        </div>
      )}
      {isShow ? (
        <PopupDeleteChart handleShow={setIsShow} chartId={chartsChecked} />
      ) : null}
    </>
  )
}
