export const DATA_GROUP = {
  DATA_GROUP_1: 1,
  DATA_GROUP_2: 2,
}

export const FIELD_NAME = {
  EA_G: 'EA_G',
  IBL_G: 'IBL_G',
  NNII_G: 'NNII_G',
  NII_G: 'NII_G',
  PFIBA_G: 'PFIBA_G',
  OT_G: 'OT_G',
  LDR: 'LDR',
  LLCR: 'LLCR',
  IE: 'IE',
  RD: 'RD',
}

export const RELATIVE_FIELD_NAME = {
  CTNIM: 'CTNIM',
  CTNIM1: 'CTNIM1',
  CTNIM2: 'CTNIM2',
  CTNIM4: 'CTNIM4',
  CTNIM5: 'CTNIM5',
}

export const ORDER_CONFIG = {
  ORDER_HEADER_CONFIG: [7, 8, 9],
  ORDER_FOOTER_CONFIG: [60, 61, 62, 63],
}

export const TYPE_CONFIG = {
  IND: 'IND',
  GR: 'GR',
}

export const DEFAULT_CONFIG = {
  DEFAULT_YEAR: new Date().getFullYear() - 1,
  DEFAULT_TIME_RANGE: 'OneYear',
  DEFAULT_CHOOSE: 'TOI',
  DEFAULT_FIELD_NAME_1: '',
  DEFAULT_ACTIVE_TAB: 'value',
}

export const TABLE_HEADER_HORIZONTAL_CHOOSE = [
  {
    fieldName_1: '',
    fieldName: 'DBT_G',
    indicator_vi: 'Tiền gửi theo loại hình',
    indicator: 'Deposit by Type',
  },
  {
    fieldName_1: '',
    fieldName: 'DBC_G',
    indicator_vi: 'Tiền gửi theo loại khách hàng',
    indicator: 'Deposit by Customers',
  },
  {
    fieldName_1: '',
    fieldName: 'SSBT_G',
    indicator_vi: 'Theo loại chứng khoán',
    indicator: 'By Securities Type',
  },
  {
    fieldName_1: '',
    fieldName: 'SSBI_G',
    indicator_vi: 'Theo loại hình đầu tư',
    indicator: 'By Investment Type',
  },
  {
    fieldName_1: '',
    fieldName: 'LGBG_G',
    indicator_vi: 'Theo chất lượng nợ vay',
    indicator: 'By Grading',
  },
  {
    fieldName_1: '',
    fieldName: 'LGBM_G',
    indicator_vi: 'Theo kỳ hạn',
    indicator: 'By Maturity',
  },
]
