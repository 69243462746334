import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class PriceDataProxy extends ProxyBase {
  getLatestPrice(params) {
    return this.get('GetLatestPrice', params, null, true)
  }

  getLatestPriceWithCancelToken(params, cancelTokenParams) {
    return this.get('GetLatestPrice', params, null, false, cancelTokenParams)
  }

  getLatestIndices(params) {
    return this.get('GetLatestIndices', params)
  }

  getLatestSectors(params) {
    return this.get('GetLatestSectors', params)
  }
}

export default new PriceDataProxy(
  ServiceProxyConfig.Market.PriceData.ServiceUrl,
)
