import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  reOrderByMonthAndYear,
  sortObjByMonthAndYear,
  sortObjByYear,
} from '../../../common/helper'
import { VAL_GROWTH_TYPE } from '../../importStatisticTable/constants'
import { TIME_RANGE } from '../constants'
import { getImportGrowthByMarket, getImportGrowthByProduct } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/importByMarket/importGrowth',
  initialState: {
    isLoading: true,
    data: [],
    activeItem: null,
    isValueOrGrowthChart: VAL_GROWTH_TYPE.VALUE,
    isYearlyGrowthChart: false,
    isAccMonthlyGrowthChart: false,
    currentDateType: TIME_RANGE.MONTHLY[0].value,
    currentDateTypeYearly: TIME_RANGE.YEARLY[0].value,
    locale: 'en',
  },
  reducers: {
    changeActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    setLoadingGrowthChart: (state, action) => {
      state.isLoading = action.payload
    },
    changeIsValueOrGrowthChart: (state, action) => {
      state.isValueOrGrowthChart = action.payload
    },
    changeIsYearlyGrowthChart: (state, action) => {
      state.isYearlyGrowthChart = action.payload
    },
    changeIsAccMonthlyGrowthChart: (state, action) => {
      state.isAccMonthlyGrowthChart = action.payload
    },
    changeCurrentDateType: (state, action) => {
      state.currentDateType = action.payload
    },
    changeCurrentDateTypeYearly: (state, action) => {
      state.currentDateTypeYearly = action.payload
    },
    changeLocaleGrowthChart: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportGrowthByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getImportGrowthByProduct.fulfilled, (state, action) => {
      const isYearlyChart = state.isYearlyGrowthChart

      const payloadData = action.payload || []
      const latestMonth = payloadData.length ? payloadData[0].realMonth : ''
      const latestYear = payloadData.length ? payloadData[0].realYear : ''
      const yearOfCurrentDateType = isYearlyChart
        ? latestYear - state.currentDateTypeYearly + 1
        : latestYear - state.currentDateType

      const allYearsInRange = Array.from(
        { length: latestYear - yearOfCurrentDateType + 1 },
        (_, i) => yearOfCurrentDateType + i,
      )

      const filteredData = isYearlyChart
        ? payloadData.filter((item) => allYearsInRange.includes(item.realYear))
        : payloadData.filter(
            (item) =>
              allYearsInRange.slice(1, -1).includes(item.realYear) ||
              (item.realYear === latestYear && item.realMonth <= latestMonth) ||
              (item.realYear === yearOfCurrentDateType &&
                item.realMonth >= latestMonth),
          )

      const data = filteredData.map((item) => ({
        ...item,
        productId: item.seafoodProductId,
        productName: item.seafoodProductName,
        year: item.realYear,
        month: item.realMonth,
        value:
          state.isValueOrGrowthChart === VAL_GROWTH_TYPE.VALUE
            ? item.accumulatedValue / 1000000
            : item.accumulatedValueYoY * 100,
      }))

      state.data = convertData(
        data,
        state.isYearlyGrowthChart,
        state.isAccMonthlyGrowthChart,
        state.locale,
      )
      state.isLoading = false
    })
    builder.addCase(getImportGrowthByProduct.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getImportGrowthByMarket.pending, (state, action) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getImportGrowthByMarket.fulfilled, (state, action) => {
      const isYearlyChart = state.isYearlyGrowthChart

      const payloadData = action.payload || []
      const latestMonth = payloadData.length ? payloadData[0].realMonth : ''
      const latestYear = payloadData.length ? payloadData[0].realYear : ''
      const yearOfCurrentDateType = isYearlyChart
        ? latestYear - state.currentDateTypeYearly + 1
        : latestYear - state.currentDateType

      const allYearsInRange = Array.from(
        { length: latestYear - yearOfCurrentDateType + 1 },
        (_, i) => yearOfCurrentDateType + i,
      )

      const filteredData = isYearlyChart
        ? payloadData.filter((item) => allYearsInRange.includes(item.realYear))
        : payloadData.filter(
            (item) =>
              allYearsInRange.slice(1, -1).includes(item.realYear) ||
              (item.realYear === latestYear && item.realMonth <= latestMonth) ||
              (item.realYear === yearOfCurrentDateType &&
                item.realMonth >= latestMonth),
          )

      const data = filteredData.map((item) => ({
        ...item,
        productId: item.seafoodProductId,
        productName: item.seafoodProductName,
        year: item.realYear,
        month: item.realMonth,
        value:
          state.isValueOrGrowthChart === VAL_GROWTH_TYPE.VALUE
            ? item.accumulatedValue / 1000000
            : item.accumulatedValueYoY * 100,
      }))
      state.data = convertData(
        data,
        state.isYearlyGrowthChart,
        state.isAccMonthlyGrowthChart,
        state.locale,
      )

      state.isLoading = false
    })
    builder.addCase(getImportGrowthByMarket.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectIsValueOrGrowthChart = (state) =>
  state[slice.name].isValueOrGrowthChart
export const selectCurrentDateType = (state) =>
  state[slice.name].currentDateType
export const selectCurrentDateTypeYearly = (state) =>
  state[slice.name].currentDateTypeYearly

export const {
  changeActiveItem,
  changeStatisticTypeChart,
  setLoadingGrowthChart,
  changeIsValueOrGrowthChart,
  changeIsYearlyGrowthChart,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  changeIsAccMonthlyGrowthChart,
  changeLocaleGrowthChart,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, isYearly, isAcc, locale) => {
  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? [...new Set(data?.map((item) => `${item.year}`))].sort()
    : isAcc
    ? sortObjByMonthAndYear(getMonthAndYearInDataRange(data)).map((item) =>
        getAccMonthAndYearKey(item.month, item.year, locale),
      )
    : reOrderByMonthAndYear(
        getMonthAndYearInDataRange(data).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        ),
      )

  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.productId}_${item.locationId}` ?? 0,
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.productId}_${item.locationId}` ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? sortObjByYear(getYearInDataRange(data)).map((item) => [
        ...dataWithYear(item.year),
      ])
    : sortObjByMonthAndYear(getMonthAndYearInDataRange(data)).map((item) => [
        ...dataWithMonthAndYear(item.month, item.year),
      ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
