import { useEffect, useRef } from 'react'

const TickerCell = ({ rowId, style, className }) => {
  const tdRef = useRef()

  useEffect(() => {
    const rowDom = tdRef.current?.closest('tr')
    const handleMouseOver = () => {
      rowDom.firstChild.style.setProperty(
        'background-color',
        '#1e242e',
        'important',
      )
    }
    rowDom.addEventListener('mouseover', handleMouseOver)

    return () => rowDom.removeEventListener('mouseover', handleMouseOver)
  }, [])

  return (
    <td ref={tdRef} rowSpan={4} style={style} className={className}>
      {rowId.split('-')[0]}
    </td>
  )
}

export default TickerCell
