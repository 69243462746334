import { EMPTY_VALUE } from '../../constants/Common'
import { randomOneColor } from '../../utils/Color'
import { isInValidValue } from '../../utils/Value'
import { INDICATOR_TYPE } from './constant'
import { typePopupIndicator as typePopupIndicatorCorporate } from './corporate/constant'
import { typePopupIndicator as typePopupIndicatorIndexSector } from './indexSector/constant'

export const encodeWatchlistId = (watchlist, index) => {
  return 'index' + index + '-' + watchlist.watchListId
}

export const getColumnHeaders = (
  columnIds,
  columnById,
  groupColumnById,
  indicatorById,
  issueMethod,
  actionType,
  locale,
  timezone,
  getHeaderIndicator,
  mostRecent = {},
) => {
  return columnIds.map((id, index) => {
    const column = columnById[id]
    const indicatorType = [
      typePopupIndicatorCorporate.DE01_SC20,
      typePopupIndicatorIndexSector.DE02_SC12,
    ].includes(column.conditionType)
      ? groupColumnById[column.groupId]?.condition?.period ===
        'MostRecentQuarter'
        ? INDICATOR_TYPE.TTM
        : INDICATOR_TYPE.YEARLY
      : column.indicatorType

    return {
      order: index + 1,
      alias: id,
      value: getHeaderIndicator(
        indicatorById[column.indicatorId],
        groupColumnById[column.groupId]?.condition,
        column.conditionType,
        column.title,
        indicatorById,
        issueMethod,
        actionType,
        locale,
        timezone,
        mostRecent[column.indicatorId + (indicatorType || '')] || {},
      ),
    }
  })
}

export const convertParamGetResult = (
  ids,
  groupIds,
  groupColumnById,
  columnById,
  dataType,
  convertDataId,
  isDisableWhenEmptyColumn = true,
) => {
  const conditionGroups = []
  let orderGroup = 0
  let orderIndicator = 0

  groupIds.forEach((groupId) => {
    const conditionGroup = groupColumnById[groupId]
    orderGroup++
    conditionGroups.push({
      groupId: conditionGroup.groupId,
      order: orderGroup,
      conditionType: conditionGroup.conditionType,
      condition: conditionGroup.condition,
      indicators: conditionGroup.indicators.map((aliasIndicator) => {
        const indicator = columnById[aliasIndicator]
        orderIndicator++
        return {
          order: orderIndicator,
          indicatorId: indicator.indicatorId,
          indicatorType: indicator.indicatorType,
          alias: indicator.alias,
          condition: indicator.condition,
        }
      }),
    })
  })

  return ids.length && (conditionGroups.length || !isDisableWhenEmptyColumn)
    ? {
        dataIds: ids.map((id) => {
          if (convertDataId) {
            return convertDataId(id, dataType)
          }
          return { id: id, dataType }
        }),
        conditionGroups,
      }
    : null
}

export const checkDeleteGroup = (group) => {
  if (!group.indicators || !group.indicators?.length) {
    return true
  }
  return false
}

export const countIndicator = (group) => {
  return group.indicators?.length || 0
}

export const getListIndicator = (group, columnById, indicatorById) => {
  const existedIndicatorId = {}
  const indicators = []
  ;(group.indicators || []).forEach((columnId) => {
    const indicatorId = columnById[columnId].indicatorId
    if (!existedIndicatorId[indicatorId]) {
      existedIndicatorId[indicatorId] = true
      indicators.push(indicatorById[indicatorId])
    }
  })
  return indicators
}

export const getListColumnId = (group) => {
  return group.indicators
}

export const getArrColumnIdByIndicatorId = (group, columnById, indicatorId) => {
  const results = []
  group.indicators?.forEach((columnId) => {
    if (columnById[columnId]?.indicatorId === indicatorId) {
      results.push(columnId)
    }
  })
  return results
}

export const convertTickerTemplate = (ticker) => {
  return {
    check: false,
    exchangeCode: ticker.exchangeCode,
    organizationId: ticker.organizationId,
    organizationShortName: ticker.organizationShortName,
    tickerOrTaxCode: ticker.tickerOrTaxCode,
  }
}

export const convertIndexSectorTemplate = (indexSector) => {
  return {
    check: false,
    groupId: indexSector.groupId,
    icbId: indexSector.icbId,
  }
}

export const convertSectorTemplate = (sector) => {
  return {
    check: false,
    icbId: sector.icbId,
    icbLevel: sector.icbLevel,
  }
}

export const getAllGroupIds = (groupColumnById) => {
  return Object.keys(groupColumnById)
}

export const getSheetName = (listSheet, valueSheet) => {
  return listSheet.find((sheet) => sheet.value === valueSheet)?.title
}

const isInValidValueCompare = (value) => {
  return isInValidValue(value) || value === EMPTY_VALUE
}

export const funcSortAsc = (byId, idColumn) => (id1, id2) => {
  let value1 = byId[id1]?.[idColumn]
  let value2 = byId[id2]?.[idColumn]

  if (isInValidValueCompare(value1)) {
    return -1
  }
  if (isInValidValueCompare(value2)) {
    return 1
  }
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value1.localeCompare(value2, 'vi')
  }
  return value1 > value2 ? 1 : -1
}

export const funcSortDesc = (byId, idColumn) => (id1, id2) => {
  let value1 = byId[id1]?.[idColumn]
  let value2 = byId[id2]?.[idColumn]

  if (isInValidValueCompare(value1)) {
    return 1
  }
  if (isInValidValueCompare(value2)) {
    return -1
  }
  if (typeof value1 === 'string' && typeof value2 === 'string') {
    return value2.localeCompare(value1, 'vi')
  }
  return value2 < value1 ? -1 : 1
}

export const getObjStringCheckDuplicateCondition = (columnById) => {
  const objCheck = {}
  Object.keys(columnById).forEach((id) => {
    if (columnById[id].stringCheckDuplicate) {
      objCheck[columnById[id].stringCheckDuplicate] = true
    }
  })
  return objCheck
}

export const getIgnoreColor = (columnId, columnById) => {
  const ignoreColors = {}
  columnId.forEach((id) => {
    const colorColumn = columnById[id]?.color
    if (!ignoreColors[colorColumn] && colorColumn) {
      ignoreColors[colorColumn] = true
    }
  })
  return Object.keys(ignoreColors)
}

const backgroundThColor = '#273040'

export const randomColor = (ignoreColors = []) => {
  return randomOneColor({
    ignoreColors: ignoreColors.concat([backgroundThColor]),
  })
}
