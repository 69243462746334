import { useDispatch } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { CHART_ORDER, VALUE_OPTIONS } from '../../constants'
import { handleActiveValueTab1, handleActiveValueTab2 } from '../../store/slice'

const ValueFrame = ({ activeTab, chartType }) => {
  const dispatch = useDispatch()
  const { CHART_1 } = CHART_ORDER

  const handleChangeTab = (tabIndex) => {
    if (chartType === CHART_1) {
      dispatch(handleActiveValueTab1(tabIndex.value))
    } else {
      dispatch(handleActiveValueTab2(tabIndex.value))
    }
  }

  return (
    <div>
      <DispatchActionTab
        data={VALUE_OPTIONS}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        containerStyle={{
          textTransform: 'uppercase',
        }}
      />
    </div>
  )
}

export default ValueFrame
