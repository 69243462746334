import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Span } from '../../../../../common/html/Span'
import { rankColorConstants } from '../../constants'
import { selectDataByKey } from '../../store/slice'
import style from './index.module.css'
import { TooltipComponent } from './TooltipComponent'

export const RankTooltip = ({ value, rowId, scoreType }) => {
  const parentRef = useRef()

  // Use selector
  const dataByKeys = useSelector(selectDataByKey)

  // Use state
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        ref={parentRef}
        className={style.rankCellContentTooltip}
        onClick={(_) => setIsOpen(!isOpen)}
      >
        <Span style={{ color: rankColorConstants[value] }}>{value}</Span>
      </div>
      <HandleClickOutside
        handleClickOutside={() => setIsOpen(false)}
        exceptRef={parentRef}
      >
        <TooltipComponent
          parentRef={parentRef}
          data={dataByKeys[rowId]}
          isOpen={isOpen}
          scoreType={scoreType}
          totalRecords={Object.keys(dataByKeys).length}
        />
      </HandleClickOutside>
    </>
  )
}
