import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useGetHeaderSearch from '../../../../../common/hooks/useGetHeaderSearch'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TradingView } from '../../../../../common/tradingView'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectBasicInfo } from '../Filter/store/slice'
import Filter from './filter'
import { selectTabActive } from './store/slice'

const InterestChart = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const headerSearch = useGetHeaderSearch()
  const bondInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectTabActive)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  return (
    <Panel
      title="bond.bondSecurity.common.chartInterest"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      isDownloadJpg={false}
      downloadCsv={() => {}}
      titleJpg={I18n.t('bond.bondSecurity.common.chartInterest')}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                <TradingView
                  containerId="tools/technicalChart"
                  defaultSymbol={bondInfo?.ticker || headerSearch?.groupCode}
                  PriceType={activeTab}
                  Type={'Bond'}
                  Id={bondInfo?.bondId || ''}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default InterestChart
