import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import useSectorGroup from '../../../common/hooks/useSectorGroup'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { SizeTracker } from '../../../common/sizeTracker'
import SectorTopInfo from '../../../common/topInfo/sectorTopInfo'
import { selectSectorInfo } from '../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../constants/Group'
import { Bank } from './bank'
import { CustomTopInfo, dataTypeConstants } from './common/CustomTopInfo'
import {
  bankOptions,
  bankTypes,
  securityOptions,
  securityTypes,
} from './constants'
import { Corporate } from './corporate'
import style from './index.module.css'
import { Security } from './security'

export const FinancialAnalysis = () => {
  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const sectorGroup = useSectorGroup(sectorInfo.icbId)

  // Use state
  const [type, setType] = useState('')
  const [topInfoOptions, setTopInfoOptions] = useState([])
  const [dataType, setDataType] = useState(dataTypeConstants.LISTED)

  // Get data
  const isSecurity = useMemo(() => {
    return sectorGroup === COM_GROUP.SECURITIES
  }, [sectorGroup])

  // Actions
  const onSelectChange = (val) => {
    setType(val)
  }

  const onDataTypeChange = (val) => {
    setDataType(val)
  }

  // Use effect
  useEffect(() => {
    const loadComGroupType = () => {
      switch (sectorGroup) {
        case COM_GROUP.BANK:
          setTopInfoOptions(bankOptions)
          setType(bankTypes.ASSET_QUALITY)
          break
        case COM_GROUP.SECURITIES:
          setTopInfoOptions(securityOptions)
          setType(securityTypes.ASSET_STRUCTURE)
          break
        default:
          setTopInfoOptions([])
          setType('')
          return ''
      }
    }
    loadComGroupType()
  }, [sectorGroup])

  // Render
  const renderComponent = useMemo(() => {
    switch (sectorGroup) {
      case COM_GROUP.BANK:
        return <Bank type={type} dataType={dataType} />
      case COM_GROUP.SECURITIES:
        return <Security type={type} />
      case COM_GROUP.CORPORATE:
        return <Corporate dataType={dataType} />
      default:
        return ''
    }
  }, [sectorGroup, type, dataType])

  return (
    <div className="h-100">
      <SizeTracker>
        {(size) => (
          <>
            <SectorTopInfo
              title="sector.financialAnalysis.common.FINANCIAL_ANALYSIS"
              levelSector={[1, 2, 3, 4]}
              customTopInfo={
                <CustomTopInfo
                  options={topInfoOptions}
                  onSelectChange={onSelectChange}
                  onDataTypeChange={onDataTypeChange}
                  isShowDataType={!isSecurity}
                />
              }
            />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                {sectorInfo.icbId ? (
                  <ScrollGroupComponent>
                    <div className="position-relative w-100 h-100">
                      {renderComponent}
                    </div>
                  </ScrollGroupComponent>
                ) : (
                  <Loading />
                )}
              </div>
            )}
            <div className={style.screenNote}>
              <Span style={{ fontSize: 11 }}>
                <Translate value="sector.financialAnalysis.common.SCREEN_NOTE" />
              </Span>
            </div>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
