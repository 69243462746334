import { FvtplDecomposition } from '.'
import { Panel } from '../../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { CustomPanelHeaderActions } from './CustomPanelHeaderActions'

export const PanelFvtplDecomposition = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  return (
    <Panel
      title="sector.financialAnalysis.security.assetStructure.FVTPL_DECOMPOSITION"
      panelRefs={panelRefs}
      panelKey={mapKey.FVTPL_DECOMPOSITION}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      renderCustomHeader={() => (
        <CustomPanelHeaderActions dataType={dataType} />
      )}
    >
      <FvtplDecomposition
        width={formatNumber(
          sizes[mapKey.FVTPL_DECOMPOSITION].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.FVTPL_DECOMPOSITION].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
