import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valToPercent,
} from '../../../../utils/Value'
import { getKeyIndicators } from './thunk'

const formatValue = (item, element, key) => {
  return element[key].isFormatValToPercent
    ? valToPercent(item[element[key].key])
    : formatVal(item[element[key].key] / element[key].isFormatValue)
}

const initialState = {
  indicatorById: [],
  ids: [],
  isLoading: true,
  showTable: false,
}

export const slice = createSlice({
  name: 'corporate/overview/keyIndicator',
  initialState,
  reducers: {
    changeIds: (state, action) => {
      state.ids = action.payload
    },
    setIndicatorById: (state, action) => {
      state.indicatorById = action.payload
    },
    setIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getKeyIndicators.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getKeyIndicators.fulfilled, (state, action) => {
      state.isLoading = false
      state.showTable = true
      const indicatorArr = Object.values(state.indicatorById)
      action.payload?.forEach((item) => {
        if (item.organizationId > 0) {
          indicatorArr.forEach((element) => {
            element.MWG.value = formatValue(item, element, 'MWG')
          })
        } else {
          indicatorArr.forEach((element) => {
            element.INDUSTRY.value = formatValue(item, element, 'INDUSTRY')
          })
        }
      })
    })
    builder.addCase(getKeyIndicators.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectIndicatorIds = (state) => state[slice.name].ids
export const selectIndicatorValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].indicatorById[id], attr)
}
export const selectLoading = (state) => state[slice.name].isLoading
export const selectShowTable = (state) => state[slice.name].showTable
export const { changeIds, setIndicatorById, setIds } = slice.actions

register(slice.name, slice.reducer)
