import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { listTabProspect } from '../constans'
import style from '../index.module.css'
import {
  changeActiveTab,
  keys,
  selectActiveTab,
  selectLocale,
} from '../store/slice'
import {
  downloadCommoditiesThunk,
  downloadWorldIndexThunk,
  getCommoditiesThunk,
  getWorldIndexThunk,
} from '../store/thunk'
import TableCommodities from './TableCommodities'
import TableWorldIndex from './TableWorldIndex'

const Prospect = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const activeTab = useSelector(selectActiveTab(keys.PROSPECT))
  const locale = useSelector(selectLocale)

  const handleChangeTab = (item) =>
    dispatch(changeActiveTab({ key: keys.PROSPECT, value: item.value }))

  useEffect(() => {
    dispatch(getCommoditiesThunk())
    dispatch(getWorldIndexThunk())
  }, [locale])

  return (
    <Panel
      title="market.marketInDepth.indices.PROSPECT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={
        activeTab === listTabProspect[0].value
          ? downloadWorldIndexThunk
          : downloadCommoditiesThunk
      }
    >
      <SizeTracker>
        {(size) => (
          <>
            <div className={`w-100 ${style.menuContainer}`}>
              <DispatchActionTab
                data={listTabProspect}
                activeTab={activeTab}
                onChangeTab={handleChangeTab}
                containerStyle={{ width: '100%' }}
                itemStyle={{ width: '50%', fontWeight: 600 }}
              />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <>
                  {activeTab === listTabProspect[1].value ? (
                    <TableCommodities />
                  ) : (
                    <TableWorldIndex />
                  )}
                </>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default Prospect
