import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { Z_INDEX } from '../../../../constants/Common'
import { range } from '../../../../utils/Common'
import { blankValue } from '../../constant'
import style from '../../index.module.css'
import {
  changeAddedPeriod,
  selectAddedPeriod,
  selectAllTypeInterims,
} from '../../store/slice'

const idThreeMostRecentQuarter = '9'

const idFiveMostRecentQuarter = '10'

const getMaxMinYear = (val) => val?.split('-')[1] || new Date().getFullYear()

const getMaxMinQuarter = (val) => val?.split('-')[0]?.[1] || 1

const AddedPeriod = () => {
  const dispatch = useDispatch()

  const addedPeriod = useSelector(selectAddedPeriod)
  const allTypeInterims = useSelector(selectAllTypeInterims)

  const interimsId5 = (
    allTypeInterims.find((item) => item.indicatorGroupId === 5)
      ?.typeInterims?.[0]?.interims || []
  ).filter(
    (item) =>
      ![idThreeMostRecentQuarter, idFiveMostRecentQuarter].includes(
        item.indicatorInterimId,
      ),
  )

  useEffect(() => {
    if (allTypeInterims) {
      handleChangeSelect({ key: 'quarter', value: maxQuarter })
      handleChangeSelect({ key: 'year', value: maxYear })
    }
  }, [allTypeInterims])

  useEffect(() => {
    if (addedPeriod.year === maxYear && addedPeriod.quarter > maxQuarter) {
      handleChangeSelect({ key: 'quarter', value: maxQuarter })
    }
    if (
      addedPeriod.year === minYear &&
      addedPeriod.quarter < minQuarter &&
      addedPeriod.quarter !== blankValue
    ) {
      handleChangeSelect({ key: 'quarter', value: minQuarter })
    }
  }, [addedPeriod])

  const handleChangeSelect = (data) => dispatch(changeAddedPeriod(data))

  const maxYear = +getMaxMinYear(interimsId5[0]?.indicatorInterimId)
  const maxQuarter = +getMaxMinQuarter(interimsId5[0]?.indicatorInterimId)
  const minYear = +getMaxMinYear(
    interimsId5[interimsId5.length - 1]?.indicatorInterimId,
  )
  const minQuarter = +getMaxMinQuarter(
    interimsId5[interimsId5.length - 1]?.indicatorInterimId,
  )

  const periods = [
    { value: blankValue, name: I18n.t('tool.smartScreening.ANNUAL') },
    { value: 1, name: 'Q1' },
    { value: 2, name: 'Q2' },
    { value: 3, name: 'Q3' },
    { value: 4, name: 'Q4' },
  ].filter((item) => {
    if (addedPeriod.year === maxYear) {
      return !(item.value > maxQuarter)
    }
    if (addedPeriod.year === minYear) {
      return !(item.value < minQuarter) || item.value === blankValue
    }
    return true
  })

  const years = range(minYear, maxYear)
    .map((year) => {
      return { value: year, name: year }
    })
    .reverse()

  return (
    <div
      className={`col-header pl-12-pr-16 ${style.headerCondition} ${style.headerPopup} ${style.hFitContent}`}
    >
      <div className={style.w248}>
        <div className="d-flex j-b">
          <div className={style.w94}>
            <Span style={{ fontSize: 10 }}>
              <p className={style.labelSelect}>
                <Translate value="tool.smartScreening.PERIOD" />
              </p>
            </Span>
            <SelectCustom
              zIndexDropdown={Z_INDEX.COMMON_CSS_OVERLAY + 1}
              selectData={periods}
              value={addedPeriod.quarter}
              handleChange={(value) =>
                handleChangeSelect({ key: 'quarter', value })
              }
            />
          </div>
          <div className={style.w94}>
            <Span style={{ fontSize: 10 }}>
              <p className={style.labelSelect}>
                <Translate value="tool.smartScreening.YEAR" />
              </p>
            </Span>
            <SelectCustom
              zIndexDropdown={Z_INDEX.COMMON_CSS_OVERLAY + 1}
              selectData={years}
              value={addedPeriod.year}
              handleChange={(value) =>
                handleChangeSelect({ key: 'year', value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddedPeriod
