import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Col from '../../common/Col'
import Row from '../../common/Row'
import TableData from '../../common/TableData'
import ToiTableHead from '../../common/ToiTableHead'
import { FIELD_NAME, TYPE_CONFIG } from '../../configs'
import { mappingData } from '../../helper'
import { selectChoose, selectIncomeStructure } from '../../store/slice'
import { selectNonNiiData } from '../store/slice'

const NonNii = () => {
  const { GR } = TYPE_CONFIG
  const { OT_G } = FIELD_NAME
  const [mappingIncomeStructure, setMappingIncomeStructure] = useState([])

  const nonNiiData = useSelector(selectNonNiiData)
  const choose = useSelector(selectChoose)
  const incomeStructureData = useSelector(selectIncomeStructure)

  useEffect(() => {
    if (nonNiiData.length && incomeStructureData.length) {
      setMappingIncomeStructure(mappingData(nonNiiData, incomeStructureData))
    }
  }, [nonNiiData, incomeStructureData])

  return (
    <>
      {mappingIncomeStructure.length > 0 &&
        mappingIncomeStructure.map((row, index) => {
          const cols = row.map((col) => {
            if (col?.type === GR) {
              return (
                <ToiTableHead
                  key={col?.indicatorMappingId}
                  colSpan={col.colSpan}
                  isCollapse={col?.expandable}
                  isActive
                  styleProps={{
                    cursor: col?.expandable ? 'pointer' : 'default',
                    borderRight:
                      col?.fieldName !== OT_G
                        ? '1px solid #1b2029'
                        : '1px solid #3e485a',
                  }}
                >
                  {col?.indicator}
                </ToiTableHead>
              )
            } else {
              return (
                <Col
                  key={col?.indicatorMappingId}
                  colSpan={col.colSpan}
                  id={col?.alias}
                  isChoose={choose === col?.fieldName}
                  styleProps={{
                    borderRightColor:
                      col?.parentField !== OT_G ? '#1b2029' : '#3e485a',
                    borderBottom: '1px solid #3e485a',
                  }}
                  chart1Type={col?.chart1Type}
                  chart2Type={col?.chart2Type}
                  fieldName={col?.fieldName}
                  fieldName_1={col?.fieldName_1}
                  indicator={col?.indicator}
                  relativeIndicator={col?.relativeIndicator}
                  alias={col?.alias}
                  valueFormat={col?.format}
                >
                  <TableData
                    alias={col?.alias}
                    parentData={col?.value}
                    childrenData={col?.relativeValue}
                    afterChildrenData={
                      col?.relativeIndicator === '%YoY' ? 'YoY' : ''
                    }
                    relativeField={col?.relativeField}
                    valueFormat={col?.format}
                    multiplier={col?.multiplier}
                  />
                </Col>
              )
            }
          })
          return <Row key={index}>{cols.map((col) => col)}</Row>
        })}
    </>
  )
}

export default NonNii
