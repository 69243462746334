import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'

const ChartTooltip = ({ dataChart, timeType }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {dataChart.tradingDate}
      </Span>
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 40 }}
      >
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate
            value="bond.corporateBond.marketLiquidity.TOTAL_TODAY"
            time={timeType}
          />
          :
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalValueToday)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 40 }}
      >
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.marketLiquidity.TOTAL_YESTERDAY" />
          :
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalValueLastday)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default ChartTooltip
