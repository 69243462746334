import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal } from '../../../utils/Value'
import { TICK_MARGIN_YAXIS } from './constant'

const MouseCoordinateY = (props) => {
  const { activeCoordinate, yAxisMap, isTooltipActive, data } = props

  if (!isTooltipActive || data.length === 0) return <></>

  return (
    <>
      {Object.values(yAxisMap).map((yAxis) => {
        const value = yAxis.scale.invert(activeCoordinate.y)
        const formatDecimalLength =
          typeof yAxis.decimalLength === 'number' ? yAxis.decimalLength : 2
        const valueDisplay = formatVal(value, formatDecimalLength)

        return (
          <text
            key={yAxis.yAxisId}
            x={
              yAxis.orientation === 'left'
                ? yAxis.x + yAxis.width - TICK_MARGIN_YAXIS
                : yAxis.x + TICK_MARGIN_YAXIS
            }
            y={activeCoordinate.y}
            fill="red"
            dominantBaseline="middle"
            textAnchor={yAxis.orientation === 'left' ? 'end' : 'start'}
          >
            {valueDisplay !== EMPTY_VALUE && valueDisplay}
          </text>
        )
      })}
    </>
  )
}

export default MouseCoordinateY
