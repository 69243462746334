import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Z_INDEX } from '../../../constants/Common'
import Dropdown, { positionDropdown } from '../../dropdown'
import style from './index.module.css'

const paddingTooltip = 9
const heightArrowTooltip = 9 //set in CSS class box-notice
const maxWidthTooltip = 300

const TooltipZoomChart = ({
  containerRef,
  isOpenTooltip,
  valueTooltip,
  listTitleTooltipZoom = [],
  position,
}) => {
  const [positionTooltip, setPositionTooltip] = useState(
    positionDropdown.TOP_CENTER,
  )

  useEffect(() => {
    const { top, bottom } = containerRef.current.getBoundingClientRect()
    if (top < maxWidthTooltip)
      setPositionTooltip(positionDropdown.TOP_LEFT_RIGHT)
    else if (window.innerWidth - bottom < maxWidthTooltip)
      setPositionTooltip(positionDropdown.TOP_RIGHT_LEFT)
    else setPositionTooltip(positionDropdown.TOP_CENTER)
  }, [])

  return (
    <Dropdown
      parentRef={containerRef}
      isShow={isOpenTooltip}
      position={position || positionTooltip}
    >
      <div
        className={`box-notice ${style.tooltip} ${
          positionTooltip === positionDropdown.TOP_LEFT_RIGHT
            ? style.tooltipLeft
            : positionTooltip === positionDropdown.TOP_RIGHT_LEFT
            ? style.tooltipRight
            : ''
        } ${style.boxNotice}`}
        style={{
          padding: `${paddingTooltip}px`,
          bottom: `${heightArrowTooltip}px`,
          maxWidth: `${maxWidthTooltip}px`,
        }}
      >
        {typeof valueTooltip === 'string' ? (
          <>{valueTooltip}</>
        ) : (
          <>
            {valueTooltip.length && valueTooltip.length > 1 ? (
              <table>
                <tbody>
                  {valueTooltip.map((item, index) => (
                    <tr className={style.trTable} key={index}>
                      <td className={style.pdLabel}>
                        <div className="mr-8">
                          <Translate value={listTitleTooltipZoom[index]} /> :
                        </div>
                      </td>
                      <td className={`${style.pdLabel} ${style.textEnd}`}>
                        {item}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <>{valueTooltip[0]}</>
            )}
          </>
        )}
      </div>
    </Dropdown>
  )
}

TooltipZoomChart.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  isI18n: PropTypes.bool,
  zIndexTooltip: PropTypes.number,
  containerRef: PropTypes.object.isRequired,
  isOpenTooltip: PropTypes.bool.isRequired,
  dropdownStyle: PropTypes.object,
}

TooltipZoomChart.defaultProps = {
  isI18n: true,
  zIndexTooltip: Z_INDEX.MODAL + 1,
  dropdownStyle: {},
}

export default TooltipZoomChart
