import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SmartScreeningService from '../../../core/services/tool/SmartScreeningService'
import {
  timeoutInterval,
  typeWorkSpace,
} from '../../common/dataExplorer/constant'
import { selectDataGeneralInfo } from '../../setting/slice'
import {
  selectCheckConditionLastApply,
  selectConditionIds,
  selectConditions,
  selectFilters,
  selectFinancialStatementIndicators,
  selectIsLoadWorkSpace,
  selectRatioIndicators,
} from './store/slice'
import { getWorkSpaceThunk } from './store/thunk'

let workSpace
let interval

const WorkSpace = () => {
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)
  const conditionIds = useSelector(selectConditionIds)
  const conditions = useSelector(selectConditions)
  const checkConditionLastApply = useSelector(selectCheckConditionLastApply)
  const ratioIndicators = useSelector(selectRatioIndicators)
  const financialStatementIndicators = useSelector(
    selectFinancialStatementIndicators,
  )
  const userInfo = useSelector(selectDataGeneralInfo)
  const isLoadWorkSpace = useSelector(selectIsLoadWorkSpace)

  useEffect(() => {
    if (isLoadWorkSpace) {
      workSpace = {
        templateName: typeWorkSpace.SCREENING,
        rawParameter: [
          {
            filters,
            data: {
              conditionIds,
              conditions,
              checkConditionLastApply,
            },
          },
        ],
      }
    }
  }, [filters, conditionIds, conditions, checkConditionLastApply])

  useEffect(() => {
    interval = setInterval(async () => {
      if (workSpace) {
        await SmartScreeningService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }, timeoutInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (workSpace) {
        SmartScreeningService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }
  }, [])

  useEffect(() => {
    if (
      userInfo.userId &&
      ratioIndicators.length &&
      financialStatementIndicators.length
    ) {
      dispatch(getWorkSpaceThunk({ UserID: userInfo.userId }))
    }
  }, [
    userInfo.userId,
    ratioIndicators.length,
    financialStatementIndicators.length,
  ])

  return <></>
}

export default WorkSpace
