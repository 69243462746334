export const common = {
  COMPANY_OVERVIEW: 'Company Overview',
  COMPANY_NEW: 'Company News',
  FINANCIAL_DATA: 'Financial Data',
  BUSINESS_MODEL: 'Business Model',
  SECTOR_OVERVIEW: 'Sector Overview',
  TECHNICAL_CHART: 'Technical Chart',
  FINANCIAL_ANALYSIS: 'Financial Analysis',
  SEGMENTATION: 'Segmentation',
  RANKING: 'Ranking',
  RATIO: 'Ratio',
  VALUATION: 'Valuation',
}
