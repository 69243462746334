import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import {
  ACC_MONTHLY_TITLE,
  MONTHLY_TITLE,
  TAB_TYPE,
} from '../../table/constants'
import { INIT_CONTRIBUTION } from './store/slice'

export const MAX_ROW = 10
const { ACC_MONTHLY } = PRICE_DATA_TIME_SELECT

export const encodeSizePanel = (width, height) => {
  return `${width}-${height}`
}

export const getLatestYearData = (yearNum = 5) => {
  const currentYear = getCurrentDateTime(FORMAT.YEAR)
  const years = []
  const startYear = +currentYear - yearNum + 1
  for (let year = startYear; year <= currentYear; year++) {
    years.push(year)
  }

  return years
}

export const convertDataChartTopChange = (data) => {
  if (!data.length) {
    return INIT_CONTRIBUTION
  }

  const topGainers = data.filter((item) => item.value > 0)
  const topLosers = data.filter((item) => item.value < 0)
  return {
    topGainers: topGainers.slice(0, MAX_ROW),
    topLosers: topLosers.slice(0, MAX_ROW),
  }
}

export const getTypeName = (pageActive) => {
  if (pageActive === TAB_TYPE.IIP) {
    return 'iipvnTypeName'
  }

  return pageActive === TAB_TYPE.IIC ? 'iicvnTypeName' : 'iiivnTypeName'
}

export const addTitleAndValue = (data, typeName) => {
  return data.map((item) => {
    return {
      title: item[typeName],
      value: formatVal(item.value * 100),
    }
  })
}

export const handleTooltipTitle = ({ month, year, dataType }, locale) => {
  if (!month) {
    return year
  }

  let time = `${MONTHLY_TITLE[month][locale]}-${year}`

  if (dataType === ACC_MONTHLY) {
    time = `${ACC_MONTHLY_TITLE[month][locale]}-${year}`
  }

  return time
}
