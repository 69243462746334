import { PropTypes } from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelExportGrowth } from './exportGrowth/PanelExportGrowth'
import { PanelExportStatisticTable } from './exportStatisticTable/PanelExportStatistic'
import { PanelTopExport } from './topExport/PanelTopMarketChart'

const MAP_KEY = {
  EXPORT_STATISTICS_CORPORATE: 'EXPORT STATISTICS CORPORATE',
  EXPORT_GROWTH_CORPORATE: 'EXPORT GROWTH CORPORATE',
  TOP_EXPORT: 'TOP EXPORT',
}

const MIN_SIZE = {
  [`${MAP_KEY.EXPORT_STATISTICS_CORPORATE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
  },
  [`${MAP_KEY.EXPORT_GROWTH_CORPORATE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.TOP_EXPORT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.EXPORT_STATISTICS_CORPORATE}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_STATISTICS_CORPORATE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_STATISTICS_CORPORATE}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.EXPORT_GROWTH_CORPORATE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.TOP_EXPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXPORT_GROWTH_CORPORATE}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_GROWTH_CORPORATE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_GROWTH_CORPORATE}`].minHeight,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.TOP_EXPORT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.TOP_EXPORT}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(60% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.TOP_EXPORT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.TOP_EXPORT}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.8 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelExportStatisticTable
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_STATISTICS_CORPORATE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelExportGrowth
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_GROWTH_CORPORATE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelTopExport
          panelRefs={panelRefs}
          panelKey={MAP_KEY.TOP_EXPORT}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
