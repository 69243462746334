import { UserManager } from 'oidc-client'
import { LOCAL_STORAGE_KEY } from '../components/constants/Common'
import EventEmitter, {
  SESSION_TIME_OUT,
  UPDATE_AUTH_TIME_AFTER_REFRESH_TOKEN,
} from '../components/utils/EventEmitter'
import { IdentityServerConfig } from '../configs/IdentityServer'

class AuthenticationProvider {
  _usrMgr
  constructor() {
    this._usrMgr = new UserManager(IdentityServerConfig)
    let me = this
    this._usrMgr.events.addSilentRenewError(function (e) {
      me._usrMgr.getUser().then((user) => {
        if (!user || user.expired) {
          // localStorage.clear()
          EventEmitter.dispatch(SESSION_TIME_OUT, '')
        }
      })
    })

    this._usrMgr.events.addUserSessionChanged(() => {
      console.log('fire')
    })

    this._usrMgr.events.addUserLoaded((user) => {
      EventEmitter.dispatch(UPDATE_AUTH_TIME_AFTER_REFRESH_TOKEN, user)
    })
  }

  getStoreKey() {
    return `user:${IdentityServerConfig.authority}:${IdentityServerConfig.client_id}`
  }

  async getUser() {
    const user = await this._usrMgr.getUser()
    if (user && !user?.profile?.user_id) {
      return this.signout()
    }

    return user
  }

  signin() {
    localStorage.setItem(
      LOCAL_STORAGE_KEY.RETURN_URL,
      window.location.pathname + window.location.search,
    )
    const query = new URLSearchParams(window.location.search)
    this._usrMgr.signinRedirect({
      //Bổ sung thêm 2 tham số truyền sang Identity Server
      extraQueryParams: {
        refCode: query.get('refCode') || '',
        refUserId: query.get('refUserId') || '',
      },
    })
  }

  signinCallback() {
    return this._usrMgr.signinRedirectCallback()
  }

  signout() {
    return this._usrMgr.signoutRedirect()
  }

  signinSilentCallback() {
    return this._usrMgr.signinSilentCallback()
  }

  signinSilent() {
    return this._usrMgr.signinSilent()
  }

  signinPopup() {
    return this._usrMgr.signinPopup()
  }

  clearStaleState() {
    return this._usrMgr.clearStaleState()
  }
}

const authProvider = new AuthenticationProvider()
export default authProvider
