import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Comparable from '../../../../../../../core/services/bond/comparable/comparable'

export const getCreditRatingCoefficient = createAsyncThunk(
  'bond/comparable/chart/chart1',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Comparable.getCreditRatingCoefficientSv(params)
      if (response.success) {
        const data = response?.data || []
        return data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getLiabilitiesToEquityRatio = createAsyncThunk(
  'bond/comparable/chart/chart12',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Comparable.getLiabilitiesToEquityRatioSv(params)
      if (response.success) return response.data
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getFundsFromOperationsToDebt = createAsyncThunk(
  'bond/comparable/chart/chart3',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Comparable.getFundsFromOperationsToDebtSv(params)
      if (response.success) return response.data
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getgetDebtToEBITDA = createAsyncThunk(
  'bond/comparable/chart/chart4',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Comparable.getgetDebtToEBITDASv(params)
      if (response.success) return response.data
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
