import { LIMIT_SELECTION } from './priceStatistics/constants'

export const getMonthAndYearInDataRange = (dataRange) => {
  const allMonthsAndYears = dataRange?.map((item) => ({
    month: item.month,
    year: item.year,
  }))

  const uniqueMonthsAndYears = Object.values(
    allMonthsAndYears.reduce((acc, curr) => {
      acc[curr.month + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  return uniqueMonthsAndYears
}

export const getMonthInDataRange = (data) => {
  const allMonths = data?.map((item) => item.month)
  const uniqueMonths = [...new Set(allMonths)]

  return uniqueMonths.map((item) => ({ month: item }))
}

export const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  const uniqueYears = [...new Set(allYears)]

  return uniqueYears.map((item) => ({ year: item }))
}

export const getMonthAndYearKey = (month, year) => {
  return month < 10 ? `0${month}-${year}` : `${month}-${year}`
}

export const getYearKey = (year) => {
  return `${year}`
}

export const getDataByMonthAndYear = (data, month, year) => {
  return data
    ?.filter((item) => item.month === month && item.year === year)
    ?.map((item) => ({
      ...item,
      value: item.value ?? 0,
    }))
}

export const getDataByYear = (data, year) => {
  return data
    ?.filter((item) => item.year === year)
    ?.map((item) => ({
      ...item,
      value: item.value ?? 0,
    }))
}

export const getUniqueSteelTypes = (data) => {
  const allSteelTypes = data?.map((item) => ({
    id: item.id,
    fullName: item.fullName ?? '',
    isGroupRow: item.isGroupRow,
    name: item.name,
    productTypeOrder: item.productTypeOrder,
    steelProductTypeId: item.steelProductTypeId,
    ticker: !item.organizationId ? '' : item.ticker,
    organizationId: item.organizationId,
  }))

  const uniqueSteelTypes = Object.values(
    allSteelTypes.reduce((acc, curr) => {
      acc[curr.id + '|' + curr.productTypeOrder] = curr
      return acc
    }, {}),
  )

  const parentSteelTypes = uniqueSteelTypes.filter((item) => item.isGroupRow)
  const childrenSteelTypes = uniqueSteelTypes.filter((item) => !item.isGroupRow)

  const getChildrenOfSpecificParent = (parentId, getIdsOnly) => {
    return !getIdsOnly
      ? childrenSteelTypes
          .filter((item) => item.steelProductTypeId === parentId)
          .map((item) => ({
            ...item,
            steelProductTypeName: parentSteelTypes.find(
              (item) => item.id === parentId,
            ).name,
          }))
      : childrenSteelTypes
          .filter((item) => item.steelProductTypeId === parentId)
          .map((item) => item.organizationId)
  }

  const parentSteelTypesWithChildren = parentSteelTypes.map((item) => ({
    ...item,
    childrenIds: getChildrenOfSpecificParent(item.id, true),
  }))

  return parentSteelTypesWithChildren
    .map((item) => [item, ...getChildrenOfSpecificParent(item.id)])
    .flat(1)
}

export const getUniqueCompanies = (data) => {
  const uniqueCompaniesNames = [...new Set(data.map((item) => item.name))]

  return uniqueCompaniesNames.map((uniqueName) =>
    data?.find((item) => item.name === uniqueName),
  )
}

export const addColorIdToData = (data) => {
  return data?.map((item) => ({
    ...item,
    colorId: data?.indexOf(item),
  }))
}

export const keepCurrentListByIndex = (indexArr, data) => {
  return indexArr.map((index) => data[index])
}

export const getRandColor = (listCurrentCheckedItems) => {
  const colorIdsArr = [...Array(LIMIT_SELECTION).keys()]

  const colorIdsInListCategory = listCurrentCheckedItems.map(
    (item) => item.colorId,
  )

  const remainColors = colorIdsArr.filter(
    (item) => !colorIdsInListCategory.includes(item),
  )

  return remainColors[Math.floor(Math.random() * remainColors.length)]
}
