import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { LIST_TYPE, TIME_SELECT } from '../../constants'
import {
  getStatisticsNetTradingValueForeignChart,
  getStatisticsNetTradingValueIndividualChart,
  getStatisticsNetTradingValueInstitutionChart,
  getStatisticsNetTradingValueProprietaryChart,
} from './thunk'

const slice = createSlice({
  name: 'market/marketInDepth/indexStatistics/netValue',
  initialState: {
    data: [],
    dateTime: TIME_SELECT.ONE_WEEK,
    type: LIST_TYPE.foreign.value,
    typeName: LIST_TYPE.foreign.display,
    listType: LIST_TYPE,
    loading: true,
  },
  reducers: {
    changeType: (state, action) => {
      state.type = action.payload
    },
    changeTypeName: (state, action) => {
      state.typeName = action.payload
    },
    changeDateTime: (state, action) => {
      state.dateTime = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getStatisticsNetTradingValueForeignChart.pending,
      (state) => {
        state.loading = true
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueForeignChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = [...action.payload.data].sort(
          (a, b) =>
            new Date(a.tradingDateId).getTime() -
            new Date(b.tradingDateId).getTime(),
        )
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueForeignChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueIndividualChart.pending,
      (state) => {
        state.loading = true
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueIndividualChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = [...action.payload.data].sort(
          (a, b) =>
            new Date(a.tradingDateId).getTime() -
            new Date(b.tradingDateId).getTime(),
        )
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueIndividualChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueInstitutionChart.pending,
      (state) => {
        state.loading = true
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueInstitutionChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = [...action.payload.data].sort(
          (a, b) =>
            new Date(a.tradingDateId).getTime() -
            new Date(b.tradingDateId).getTime(),
        )
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueInstitutionChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueProprietaryChart.pending,
      (state) => {
        state.loading = true
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueProprietaryChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = [...action.payload.data].sort(
          (a, b) =>
            new Date(a.tradingDateId).getTime() -
            new Date(b.tradingDateId).getTime(),
        )
      },
    )
    builder.addCase(
      getStatisticsNetTradingValueProprietaryChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeType, changeDateTime, changeTypeName } = slice.actions
export const selectType = (state) => state[slice.name].type
export const selectTypeName = (state) => state[slice.name].typeName
export const selectDateTime = (state) => state[slice.name].dateTime
export const selectListType = (state) => state[slice.name].listType
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
