import React from 'react'
import { Translate } from 'react-redux-i18n'
import KeyIndicatorCountry from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Span } from '../../../../common/html/Span'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import style from '../style.module.css'

const KeyIndicatorCountryPanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('economy.fdi.overview.TITLE_PANEL_1')}
      headerTitleClass={style.headerTitleClass}
      renderCustomTitle={() => (
        <Span style={{ textTransform: 'uppercase' }}>
          <Translate value="economy.fdi.overview.TITLE_PANEL_1" />
        </Span>
      )}
      downloadJpgParams={{
        domId: 'keyIndicatorCountryId',
        nameScreen: UseI18n('economy.fdi.overview.NAME_SCREEN'),
        chartName: UseI18n('economy.fdi.overview.TITLE_PANEL_1'),
      }}
    >
      <KeyIndicatorCountry
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default KeyIndicatorCountryPanel
