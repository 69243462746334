import { I18n } from 'react-redux-i18n'
import { COMPONENT } from '../../../../configs/Layout'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { CHART_DOM_ID } from './constant'
import Filter from './filter'
import ScatterPlot from './scatterPlot'

const MAP_KEY = {
  FILTER: 'FILTER',
  CHART: 'CHART',
}

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: `calc(300px - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 300 - 2 * COMPONENT.MARGIN,
    minHeight: 500,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.CHART,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHART}`]: {
    width: `calc(100% - 300px)`,
    height: `100%`,
    top: 0,
    left: 300,
    minWidth: 700,
    minHeight: 500,
    minTop: 0,
    minLeft: 300,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const nameScreen = I18n.t(
    'constants.route.sector.sectorConstituents.segmentation.LABEL',
  )
  const chartName = I18n.t(
    'sector.sectorConstituents.segmentation.SCATTER_PLOT',
  )

  return (
    <div className="w-100 h-100 position-relative">
      <PanelFilter
        title="sector.sectorConstituents.segmentation.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
        windowBodyStyle={{ padding: 0 }}
      >
        <Filter />
      </PanelFilter>
      <Panel
        title={'sector.sectorConstituents.segmentation.SCATTER_PLOT'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHART}
        sizes={sizes}
        setSizes={setSizes}
        isDownloadJpg={true}
        downloadJpgParams={{
          domId: CHART_DOM_ID,
          nameScreen,
          chartName,
        }}
        titleJpg={UseI18n(
          'sector.sectorConstituents.segmentation.SCATTER_PLOT',
        )}
      >
        <ScatterPlot
          width={sizes[MAP_KEY.CHART].width - PANEL_PADDING}
          height={sizes[MAP_KEY.CHART].height - HEADER_PANEL_HEIGHT}
        />
      </Panel>
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
