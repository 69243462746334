export const GROUP_COLUMNS = [
  {
    key: 'ticker',
    title: 'bond.corporateBond.topBidAsk.TICKER',
    attrs: {
      style: { minWidth: '20%' },
    },
    keySort: 'ticker',
    alignRight: false,
  },
  {
    key: 'organizationShortName',
    title: 'bond.corporateBond.topBidAsk.ISSUER',
    attrs: {
      style: { minWidth: '20%', maxWidth: 120 },
    },
    keySort: 'organizationShortName',
    alignRight: false,
  },
  {
    key: 'ytm',
    title: 'bond.corporateBond.topBidAsk.YTM',
    additionalText: 'bond.corporateBond.topBidAsk.PERCENT',
    attrs: {
      style: { minWidth: '15%' },
    },
    keySort: 'ytm',
    alignRight: true,
  },
  {
    key: 'price',
    title: 'bond.corporateBond.topBidAsk.BestAsk',
    additionalText: 'bond.corporateBond.topBidAsk.VND',
    attrs: {
      style: { minWidth: '15%' },
    },
    keySort: 'price',
    alignRight: true,
  },
  {
    key: 'remainLength',
    title: 'bond.corporateBond.topBidAsk.KHCL',
    additionalText: 'bond.corporateBond.topBidAsk.MONTH',
    attrs: {
      style: { minWidth: '15%' },
    },
    keySort: 'remainLength',
    alignRight: true,
  },
  {
    key: 'volume',
    title: 'bond.corporateBond.topBidAsk.VOL_ASK',
    attrs: {
      style: { minWidth: '15%' },
    },
    keySort: 'volume',
    alignRight: true,
  },
]
