import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { externalLink } from '../../../../../configs/ExternalLink'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Search } from '../../../../common/search'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { exchangeOptions, rankingTableConstants } from '../constants'
import { getTableSchema } from '../helper'
import style from '../index.module.css'
import {
  changeExchangeCode,
  changeTableData,
  selectDataByIds,
  selectDataByKey,
  selectIds,
  selectLoading,
} from '../store/slice'
import { getDataTable } from '../store/thunk'
import { SelectType } from './SelectType'

export const RankingComponent = () => {
  const dispatch = useDispatch()

  // Use selector
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectIds)
  const dataByKey = useSelector(selectDataByKey)
  const sectorInfo = useSelector(selectSectorInfo)

  // Use state
  const [exchangeType, setExchangeType] = useState(exchangeOptions[0].value)

  // Actions
  const onChangeExchangeCode = (value) => {
    setExchangeType(value)
    dispatch(changeExchangeCode(value))
  }

  const onChangeSearch = (value) => {
    dispatch(changeTableData(value))
  }

  // Use effect
  useEffect(() => {
    if (sectorInfo?.icbId) {
      dispatch(
        getDataTable({
          ExchangeCode: exchangeType,
          ICBId: sectorInfo.icbId,
        }),
      )
    }
  }, [exchangeType, sectorInfo])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="align-center mb-8">
            <SelectType
              activeTab={exchangeType}
              onChangeTab={onChangeExchangeCode}
              style={{ marginRight: 8 }}
            />
            <Search onChange={onChangeSearch} margin={'0px'} width={246} />
          </div>
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              {loading ? (
                <Loading />
              ) : ids.length ? (
                <Table
                  isLoading={loading}
                  ids={ids}
                  getDataFromRedux={selectDataByIds}
                  schema={getTableSchema(dataByKey)}
                  stickies={{
                    [rankingTableConstants.TICKER]: true,
                    [rankingTableConstants.RANK_VALUE]: true,
                  }}
                  isLargeHead={true}
                  hasFooter={false}
                  hasTooltip
                  resizable={false}
                />
              ) : (
                <NoData />
              )}
            </div>
          )}
          <div className="d-flex">
            <div className={style.noteTableColor}>
              <Translate value="sector.sectorConstituents.ranking.NOTE_TABLE_RANKING" />
            </div>
            &nbsp;
            <a
              className={style.textClickHere}
              href={externalLink.rankingSeeMore}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translate value="sector.sectorConstituents.ranking.CLICK_HERE" />
            </a>
          </div>
        </>
      )}
    </SizeTracker>
  )
}
