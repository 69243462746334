export const title = {
  structure: 'Cấu trúc',
  overview: 'Tổng quan',
  detailOwnership: 'Chi tiết cơ cấu sở hữu',
  majorOwnership: 'Cổ đông lớn',
  TOTAL: 'Tổng',
}

export const chart = {
  ForeignOwned: 'Sở hữu nước ngoài',
  StateOwned: 'Sở hữu nhà nước',
  Other: 'Khác',
  Management: 'Management',
  Funds: 'Quỹ',
}

export const ownership = {
  MAJOR_SHAREHOLDER: 'Danh sách cổ đông',
  MANAGEMENT_AND_DIRECTOR: 'Ban lãnh đạo',
  DIRECTOR_DEALS: 'Giao dịch ban lãnh đạo',
  SUBSIDIARIES: 'Công ty con',
  ASSOCIATE: 'Liên doanh',
  INTERNAL_TRANSACTIONS: 'Giao dịch nội bộ',
}

export const majorShareHolder = {
  TICKER: 'Tên cổ đông',
  SHARES: 'Số cổ phần',
  PERCENTAGE: '% sở hữu',
  LAST_UPDATE: 'Ngày cập nhật',
  LATEST_TRANSACTION: 'Giao dịch mới nhất',
  SHARE_VOLUME: 'Khối lượng giao dịch',
  PRICE_RANGE: 'Giá trị dự kiến',
  TRANSACTION_DATES: 'Ngày giao dịch',
  THOUSAND: '(nghìn)',
  BILLION_VND: '(Tỷ VND)',
  EST_VALUE_TOOLTIP:
    'Giá trị dự kiên bằng số lượng cổ phiếu giao dịch nhân với mức giá tại ngày giao dịch cuối',
}

export const hoverTooltip = {
  HISTORICAL_DEALS: 'Lịch sử giao dịch',
  OWNERSHIP: 'Cổ phiếu đang nắm giữ',
  START_DATE: 'Ngày giao dịch',
  TYPE: 'Loại giao dịch',
  REGISTER: 'Đăng ký',
  RESULT: 'Kết quả',
  SHARE: 'Số cổ phiếu ',
  BEFORE: '(trước giao dịch)',
  AFTER: '(sau giao dịch)',
  PERCENTAGE: '% Sở hữu',
  TICKER: 'Mã',
  QUANTITY: 'Số lượng',
  UPDATE_DATE: 'Ngày cập nhật',
  VALUE: 'Giá trị',
  MILLION: '(Triệu VND)',
}

export const director = {
  TICKER: 'Tên cổ đông',
  TITLE: 'Chức vụ',
  SHARES: 'Số cổ phần',
  PERCENTAGE: '% sở hữu',
  LAST_UPDATE: 'Ngày cập nhật',
  SHARE_VOLUME: 'Khối lượng giao dịch',
  LATEST_TRANSACTION: 'Giao dịch mới nhất',
  PRICE_RANGE: 'Giá trị dự kiến',
  TRANSACTION_DATES: 'Ngày giao dịch',
  THOUSAND: '(nghìn)',
  BILLION_VND: '(Tỷ VND)',
  EST_VALUE_TOOLTIP:
    'Giá trị dự kiên bằng số lượng cổ phiếu giao dịch nhân với mức giá tại ngày giao dịch cuối',
}

export const associate = {
  TICKER: '<b>Mã</b>',
  ASSOCIATE: '<b>Công ty con & Công ty liên kết</b>',
  STATUS: '<b>Trạng thái niêm yết</b>',
  CHAPTER_CAPITAL:
    '<b>VỐN ĐIỀU LỆ</b></br><i style="font-weight: 300">(Tỷ VND)</i>',
  CAPITAL_CONTRIBUTION:
    '<b>VỐN GÓP</b></br><i style="font-weight: 300">(Tỷ VND)</i>',
  OWNERSHIP: '<b>CỔ PHIẾU ĐANG NẮM GIỮ</b>',
  STATUS_DELISTED: 'Hủy niêm yết',
  STATUS_LISTING: 'Đang niêm yết',
  STATUS_PLAN: 'Kế hoạch niêm yết',
  STATUS_UNLISTED: 'Chưa niêm yết',
}

export const subsidiaries = {
  TICKER: '<b>Mã</b>',
  SUBSIDIARIES: '<b>Công ty con</b>',
  STATUS: '<b>Trạng thái niêm yết</b>',
  CHAPTER_CAPITAL:
    '<b>Vốn điều lệ</b> </br><i style="font-weight: 300">(Tỷ VND)</i>',
  CAPITAL_CONTRIBUTION:
    '<b>Vốn góp</b> </br><i style="font-weight: 300">(Tỷ VND)</i>',
  OWNERSHIP: '<b>Cổ phiếu đang nắm giữ</b>',
  STATUS_DELISTED: 'Hủy niêm yết',
  STATUS_LISTING: 'Đang niêm yết',
  STATUS_PLAN: 'Kế hoạch niêm yết',
  STATUS_UNLISTED: 'Chưa niêm yết',
}

export const deals = {
  NAME: 'Tên lãnh đạo',
  NUMBER_OF_TRANSACTION_SHARES: 'Số cổ phiếu giao dịch',
  ANNOUNCEMENT_DATE: 'Ngày công bố',
  TRANSACTION_DATES: 'Ngày kết thúc',
}
