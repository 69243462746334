import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { range } from '../../../../utils/Common'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { FILTER_MIN_YEAR, RATIO_QUARTER, VIEW_BY } from './constants'
import {
  changeQuarter,
  changeTypeFilter,
  changeYear,
  resetStore,
  selectQuarter,
  selectTypeFilter,
  selectYear,
} from './store/slice'
import {
  getSecMarginLendingMaxPeriod,
  getSecProprietaryTreasuryMaxPeriod,
} from './store/thunk'
import style from './style.module.css'

const YEARS = range(FILTER_MIN_YEAR, +getCurrentDateTime(FORMAT.YEAR)).reverse()

const Filter = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  useEffect(() => {
    if (typeFilter === VIEW_BY.MARGIN_LENDING) {
      dispatch(
        getSecMarginLendingMaxPeriod({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    } else {
      dispatch(
        getSecProprietaryTreasuryMaxPeriod({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [typeFilter, basicInfo.organizationId])

  const handleChangeTypeFilter = (val) => {
    dispatch(changeTypeFilter(val))
  }
  const handleChangeYear = (val) => {
    dispatch(changeYear(val))
  }
  const handleChangeQuarter = (e) => {
    dispatch(changeQuarter(+e.target.value))
  }

  return (
    <div
      className="wrapper-tool h-100 d-flex"
      style={{ flexDirection: 'column', position: 'relative' }}
    >
      <div className="mt-8">
        {Object.keys(VIEW_BY).map((key) => {
          return (
            <a
              key={key}
              className={`h-20
               ${typeFilter === key ? style.buttonBlue : style.buttonInBox}`}
              onClick={() => handleChangeTypeFilter(key)}
            >
              <Span style={{ fontSize: 11 }} className="w-100 text-center">
                <TextEllipsis
                  text={`corporate.businessModel.penetrationAnalysis.${key}`}
                />
              </Span>
            </a>
          )
        })}
      </div>
      <Span className={style.labelFilter} style={{ fontSize: 11 }}>
        <Translate value="corporate.businessModel.penetrationAnalysis.TIME" />
      </Span>
      <div className="mt-8">
        <SelectCustom
          value={filterYear}
          selectData={YEARS.map((year) => ({
            name: year,
            value: year,
          }))}
          handleChange={handleChangeYear}
        />
      </div>
      <ul className="list-check mt-16">
        {RATIO_QUARTER.map((item) => {
          return (
            <li key={item.key} className={style.liQuarter}>
              <label className="fs-12">
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  name="tableType"
                  value={item.value}
                  checked={item.value === filterQuarter}
                  onClick={handleChangeQuarter}
                  readOnly
                />
                <Span
                  style={{
                    color: item.value === filterQuarter ? '#2f9aee' : '',
                    fontSize: 12,
                  }}
                >
                  <Translate
                    value={`corporate.businessModel.penetrationAnalysis.${item.key}`}
                  />
                </Span>
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default Filter
