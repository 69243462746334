import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorValuationService from '../../../../../../core/services/sector/sectorConstituents/SectorValuationService'

export const getAllOrganizationByIcbIdAndGroupId = createAsyncThunk(
  'sector/sectorConstituents/valuation/filter/getAllOrganizationByIcbIdAndGroupId',
  async ({ ExchangeCode, ICBId }, { rejectWithValue }) => {
    try {
      const response =
        await SectorValuationService.getAllOrganizationByIcbIdAndGroupId({
          ExchangeCode,
          ICBId,
        })
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMarketCapOrganizationByIcbId = createAsyncThunk(
  'sector/sectorConstituents/valuation/filter/getMarketCapOrganizationByIcbId',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorValuationService.getMarketCapOrganizationByIcbId(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
