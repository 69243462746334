import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class GDPProvinceProxy extends ProxyBase {
  getGDPByProvince(params) {
    return this.get('GDPByProvince', params, undefined, true)
  }

  getGDPTypeName(params) {
    return this.get('GDPTypeName', params)
  }

  downloadGDPByProvince(params) {
    return this.get('DownloadGDPByProvince', params, 'download')
  }

  getProvincesByGRDP(params) {
    return this.get('ProvincesByGRDP', params)
  }

  getGRDPStructureInByProvince(params) {
    return this.get('GRDPStructureInByProvince', params)
  }

  getChangesInGRDPStructureBySectorInProvince(params) {
    return this.get('ChangesInGRDPStructureBySectorInProvince', params)
  }

  getLocationName(params) {
    return this.get('LocationName', params)
  }

  getMaxPeriod(params) {
    return this.get('MaxPeriod', params, undefined, true)
  }
}

export default new GDPProvinceProxy(
  ServiceProxyConfig.Economy.GDP.Province.ServiceUrl,
)
