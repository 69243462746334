import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  CURRENCY,
  LATEST_YEAR,
  NUMBER_OF_PERIOD,
  STATEMENT_CONSTANTS,
  UNIT_CONSTANTS,
  VIEW_BY_CONSTANTS,
} from '../constants'
import { formatFinancialData, setFormatIndicatorMapping } from '../helpers'
import {
  getDataFinancialTable,
  getExchangeRates,
  getIndicatorMapping,
} from './thunk'

const initialState = {
  filters: {
    statement: STATEMENT_CONSTANTS.ASSET_REPORT,
    viewBy: VIEW_BY_CONSTANTS.YEARLY,
    numberOfPeriod: NUMBER_OF_PERIOD[10].value,
    latestYear: LATEST_YEAR[0].value,
    currency: CURRENCY.VND,
    unit: UNIT_CONSTANTS.BILLION_VND,
  },
  loading: true,
  indicatorLoading: false,
  indicators: [],
  indicatorById: {},
  ids: [],
  groupColumns: [],
  tableConstants: [],
  exchangeRates: {},
}

export const slice = createSlice({
  name: 'fund/fundProfile/periodicalReport',
  initialState,
  reducers: {
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeFilter: (state, action) => {
      const filter = {}
      Object.keys(action.payload).forEach((key) => {
        if (key === 'currency') {
          if (action.payload[key] === UNIT_CONSTANTS.VND) {
            filter.unit = UNIT_CONSTANTS.BILLION_VND
          } else {
            filter.unit = UNIT_CONSTANTS.MILLION_USD
          }
        }
        if (key === 'statement') {
          state.filters.statement = action.payload[key]
          setFormatIndicatorMapping(state)
        }
      })
      state.filters = {
        ...state.filters,
        ...action.payload,
        ...filter,
      }
    },
    resetIndicatorMapping: (state) => {
      state.tableConstants = []
      state.ids = []
      state.indicatorById = {}
      state.loading = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataFinancialTable.pending, (state) => {
      state.loading = true
      state.groupColumns = []
    })
    builder.addCase(getDataFinancialTable.fulfilled, (state, action) => {
      formatFinancialData(state, action)
    })
    builder.addCase(getDataFinancialTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getIndicatorMapping.pending, (state) => {
      state.indicatorLoading = true
    })
    builder.addCase(getIndicatorMapping.fulfilled, (state, action) => {
      state.indicators = action.payload || []
      setFormatIndicatorMapping(state)
    })
    builder.addCase(getIndicatorMapping.rejected, (state, action) => {
      state.indicatorLoading = action.payload.loading
    })
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      const exchangeRates = {}
      action.payload?.forEach(
        (item) =>
          (exchangeRates[`${item.quarter}-${item.year}`] = item.exchangeRate),
      )
      state.exchangeRates = exchangeRates
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectTableConstants = (state) => state[slice.name].tableConstants
export const selectIndicatorValueByCell = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].indicatorById[id], attr)
}
export const selectLoading = (state) => state[slice.name].loading
export const selectIndicatorLoading = (state) =>
  state[slice.name].indicatorLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectFilters = (state) => state[slice.name].filters
export const selectIndicator = (id) => (state) =>
  state[slice.name].indicatorById[id]
export const selectIndicatorById = (state) => state[slice.name].indicatorById
export const selectExchangeRates = (state) => state[slice.name].exchangeRates

export const { changeFilter, changeIds, resetIndicatorMapping } = slice.actions

register(slice.name, slice.reducer)
