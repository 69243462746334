import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { FORMAT } from '../../../../utils/Datetime'
import style from './index.module.css'

export const SummaryForm = ({ data }) => {
  return (
    <>
      <Span style={{ fontSize: 14 }} className={style.title}>
        {data.description}
      </Span>
      <Span style={{ fontSize: 12 }} className={style.time}>
        {data.date && (
          <DateTimeSpan
            date={data.date}
            formatDate={FORMAT.NEWS_WITHOUT_TIME}
          />
        )}
      </Span>
      {data.eventContent && (
        <div className={style.content}>
          <ScrollComponent
            appendStyle={{
              height: '35vh',
            }}
          >
            {data.eventContent.includes('<br') ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.eventContent,
                }}
              />
            ) : (
              <div style={{ whiteSpace: 'pre-line' }}>{data.eventContent}</div>
            )}
          </ScrollComponent>
        </div>
      )}
      {data?.attachFileUrl && (
        <div>
          <div className={style.divider} />
          <div className={style.attachment}>
            <Translate value="market.event.ATTACHMENT" />
            <a
              href={data.attachFileUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={style.link}
            >
              {data.attachFileUrl}
            </a>
          </div>
        </div>
      )}
    </>
  )
}
