export const overview = {
  SECTOR_SUMMARY: 'Tổng quan ngành',
  SECTOR: 'NGÀNH',
  ICB_LEVEL: 'Cấp',
  PERCENT_MARKET_CAP: 'TỶ TRỌNG <br/> VỐN HÓA (%)',
  PERCENT_INDEX_CHANGE: '% THAY ĐỔI 1D',
  VALUATION: 'ĐỊNH GIÁ',
  ANNUAL: 'Hàng năm',
  MARKET_CAP: 'VỐN HÓA',
  SECTORS_REPORT: 'BÁO CÁO NGÀNH',
  PRICE_PERFORMANCE: 'Biến động ngành',
  ANNOUNCEMENT_DATE: 'Ngày công bố',
  TICKER: 'Mã',
  COMPANY_NAME: 'Công ty phát hành',
  TITLE: 'Tiêu đề báo cáo',
  RELATIVE: 'Tương đối',
  ABSOLUTE: 'Tuyệt đối',
  MESS_WARNING_LIMIT_ADD_SECTOR: 'Số lượng chỉ tiêu lựa chọn không vượt quá 20',
  RANGE_PE: 'Khoảng P/E',
  RANGE_PB: 'P/B Chênh lệch',
  RANGE_EV_EBITDA: 'EV/EBITDA Chênh lệch',
  AVERAGE_PE: 'P/E Trung bình',
  AVERAGE_PB: 'P/B Trung bình',
  AVERAGE_EV_EBITDA: 'EV/EBITDA Trung bình',
  CURRENT_PE: 'P/E Hiện tại',
  CURRENT_PB: 'P/B Hiện tại',
  CURRENT_EV_EBITDA: 'EV/EBITDA Hiện tại',
  MIN_PE: 'P/E Min',
  MIN_PB: 'P/B Min',
  MIN_EV_EBITDA: 'EV/EBITDA Min',
  MAX_PE: 'P/E Max',
  MAX_PB: 'P/B Max',
  MAX_EV_EBITDA: 'EV/EBITDA Max',
  OTHERS: 'Khác',
  All: 'Tất cả',
  MARKET_CAP_CHART: 'TỶ TRỌNG VỐN HÓA CỦA NGÀNH CẤP&nbsp;',
  CHART_POINT: 'Điểm',
}

export const ratio = {
  INCOME_PERFORMANCE: 'HIỆU QUẢ HOẠT ĐỘNG',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  REVENUE: 'TĂNG TRƯỞNG DOANH THU',
  EBIT: 'TĂNG TRƯỞNG EBIT',
  LNST: 'TĂNG TRƯỞNG LNST',
  EPS: 'TĂNG TRƯỞNG EPS',
  DETAIL: 'CHI TIẾT',
  LISTED: 'Niêm yết',
  ALL: 'Tất cả',
  FILTER: 'Bộ lọc',
  SEARCH: 'Tìm kiếm',
  ICB_LEVEL: 'Cấp',
  SECTOR: 'NGÀNH',
  LEVEL: 'CẤP',
  LEVEL_2: 'Cấp',
  PRIVATE: 'Tư nhân',
  SECTOR_EXIST: 'Ngành đã tồn tại.',
  ANNUAL: 'Hàng năm',
}
