import moment from 'moment'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table as TableContent } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { TooltipWarning } from '../../../../common/tooltipWarning'
import {
  FORMAT,
  formatDuration,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import PopupDeleteAllTicker from '../../Popup/PopupDeleteAllTicker'
import PopupDeleteTicker from '../../Popup/PopupDeleteTicker'
import {
  BOND_INFORMATION_TABS,
  BOND_INFORMATION_TAB_TYPES,
  GROUP_BOND_INFORMATION_COLUMNS,
  INTEREST_BASIS_OPTIONS,
} from '../../constants'
import {
  changeBuyPrice,
  changeDataBondInformation,
  changeFilterBondInformation,
  keys,
  resetDataBondInformation,
  selectDataBondInformation,
  selectDataTableById,
  selectFilterBondInformation,
  selectLoading,
  sort,
} from '../../store/slice'
import { postPortfolioInvestmentData } from '../../store/thunk'
import DateTd from './DateTd'
import InputTd from './InputTd'
import InterestBasisTd from './InterestBasisTd'
import TableHeader from './TableHeader'
import style from './index.module.css'

const Table = ({ width }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading(keys.BOND_INFORMATION))
  const { data, ids } = useSelector(selectDataBondInformation)
  const { activeTab } = useSelector(selectFilterBondInformation)
  const locale = useSelector((state) => state.i18n.locale)

  const timeZone = UseTimeZone()

  const scrollbarsRef = useRef()
  const tickerRef = useRef()
  const tradingDateRef = useRef()
  const totalValueRef = useRef()
  const bondEventRef = useRef()

  const [index, setIndex] = useState(-1)
  const [isShow, setIsShow] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [field, setFiled] = useState(undefined)
  const [error, setError] = useState('')

  const handleDeleteTicker = () => {
    const newData = [...data.slice(0, index), ...data.slice(index + 1)]
    dispatch(changeDataBondInformation(newData))
    setIndex(-1)
  }

  const handleDeleteAllTicker = () => {
    dispatch(resetDataBondInformation())
    setIndex(-1)
    setIsShow(false)
  }

  const handleClose = () => {
    setIndex(-1)
    setIsEdit(false)
    setFiled(undefined)
  }

  const handleChangeTradingDate = (date, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      ytm,
      dirtyPrice,
      cleanPrice,
      bondVolume,
      valuationDate,
      interestBasisTypeId,
      issueDateId,
      maturityDateId,
      buyPrice,
    } = item

    const tradingDateUnix = moment(date).unix()
    const valuationDateUnix = moment(valuationDate).unix()
    const issueDateUnix = moment(issueDateId).unix()
    const maturityDateUnix = moment(maturityDateId).unix()

    if (tradingDateUnix - valuationDateUnix > 0) {
      setError('bond.portfolio.bondInformation.MESSAGE_DATE_1')
      return
    }

    if (
      tradingDateUnix - issueDateUnix < 0 ||
      tradingDateUnix - maturityDateUnix > 0
    ) {
      setError('bond.portfolio.bondInformation.MESSAGE_DATE_3')
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: getISOStartOrEndOfDay(date, timeZone, true),
          valuationDate: valuationDate,
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId,
          ytm: !isNaN(ytm) ? parseFloat(formatVal(ytm / 100, 4)) : null,
          dirtyPrice: dirtyPrice ?? null,
          cleanPrice: cleanPrice ?? null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleChangePaymentDate = (date, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      ytm,
      dirtyPrice,
      cleanPrice,
      bondVolume,
      tradingDateId,
      interestBasisTypeId,
      issueDateId,
      maturityDateId,
      buyPrice,
    } = item

    const tradingDateUnix = moment(tradingDateId).unix()
    const valuationDateUnix = moment(date).unix()
    const issueDateUnix = moment(issueDateId).unix()
    const maturityDateUnix = moment(maturityDateId).unix()

    if (tradingDateUnix - valuationDateUnix > 0) {
      setError('bond.portfolio.bondInformation.MESSAGE_DATE_2')
      return
    }

    if (
      valuationDateUnix - issueDateUnix < 0 ||
      valuationDateUnix - maturityDateUnix > 0
    ) {
      setError('bond.portfolio.bondInformation.MESSAGE_DATE_4')
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate: getISOStartOrEndOfDay(date, timeZone, true),
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId,
          ytm: !isNaN(ytm) ? parseFloat(formatVal(ytm / 100, 4)) : null,
          dirtyPrice: dirtyPrice ?? null,
          cleanPrice: cleanPrice ?? null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleBlurInputVolume = (value, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      tradingDateId,
      interestBasisTypeId,
      valuationDate,
      bondVolume,
      ytm,
      buyPrice,
    } = item

    if (
      (typeof value === 'string' && !value?.length) ||
      parseInt(value) === bondVolume
    ) {
      handleClose()
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate,
          bondVolume: parseInt(value),
          interestBasisTypeId,
          ytm: !isNaN(ytm) ? parseFloat(formatVal(ytm / 100, 4)) : null,
          dirtyPrice: null,
          cleanPrice: null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleBlurInputBuyPrice = (value, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const { buyPrice } = item

    if (
      (typeof value === 'string' && !value?.length) ||
      parseFloat(value) === buyPrice
    ) {
      handleClose()
      return
    }

    dispatch(changeBuyPrice({ value: parseFloat(value), id }))

    handleClose()
  }

  const handleBlurInputYtm = (value, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      tradingDateId,
      interestBasisTypeId,
      valuationDate,
      bondVolume,
      ytm,
      buyPrice,
    } = item

    if (
      (typeof value === 'string' && !value?.length) ||
      parseFloat(value) === ytm
    ) {
      handleClose()
      return
    }

    if (parseFloat(value) < -100 || parseFloat(value) > 100) {
      setError('bond.portfolio.bondInformation.MESSAGE_YTM')
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate,
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId,
          ytm: parseFloat(formatVal(value / 100, 4)),
          dirtyPrice: null,
          cleanPrice: null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleBlurInputDirtyPrice = (value, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      tradingDateId,
      interestBasisTypeId,
      valuationDate,
      bondVolume,
      dirtyPrice,
      buyPrice,
    } = item

    if (
      (typeof value === 'string' && !value?.length) ||
      parseFloat(value) === dirtyPrice
    ) {
      handleClose()
      return
    }

    if (parseFloat(value) <= 0) {
      setError('bond.portfolio.bondInformation.MESSAGE_DIRTY_PRICE')
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate,
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId,
          ytm: null,
          dirtyPrice: parseFloat(value),
          cleanPrice: null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleBlurInputCleanPrice = (value, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      tradingDateId,
      interestBasisTypeId,
      valuationDate,
      bondVolume,
      cleanPrice,
      buyPrice,
    } = item

    if (
      (typeof value === 'string' && !value?.length) ||
      parseFloat(value) === cleanPrice
    ) {
      handleClose()
      return
    }

    if (parseFloat(value) <= 0) {
      setError('bond.portfolio.bondInformation.MESSAGE_CLEAN_PRICE')
      return
    }

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate,
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId,
          ytm: null,
          dirtyPrice: null,
          cleanPrice: parseFloat(value),
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))

    handleClose()
  }

  const handleChangeInterestBasis = (type, id) => {
    const item = data.find((item) => item?.id === id)

    if (!item) return

    const {
      isUserBond,
      bondId,
      ytm,
      bondVolume,
      tradingDateId,
      valuationDate,
      buyPrice,
    } = item

    const params = {
      bonds: [
        {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate,
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId: type,
          ytm: !isNaN(ytm) ? parseFloat(formatVal(ytm / 100, 4)) : null,
          dirtyPrice: null,
          cleanPrice: null,
          buyPrice: parseFloat(buyPrice),
        },
      ],
      id,
    }

    dispatch(postPortfolioInvestmentData(params))
  }

  const handleScrollLeft = (value) => {
    dispatch(changeFilterBondInformation({ key: 'activeTab', value }))

    if (activeTab === value) return

    switch (value) {
      case BOND_INFORMATION_TAB_TYPES.BOND_INFORMATION:
        scrollbarsRef?.current?.scrollLeft(0)
        break
      case BOND_INFORMATION_TAB_TYPES.TRADING_INFORMATION:
        scrollbarsRef?.current?.scrollLeft(
          tradingDateRef?.current?.offsetLeft -
            tickerRef?.current?.offsetWidth +
            2,
        )
        break
      case BOND_INFORMATION_TAB_TYPES.PORTFOLIO_ANALYSIS:
        scrollbarsRef?.current?.scrollLeft(
          bondEventRef?.current.offsetLeft -
            tickerRef?.current?.offsetWidth +
            2,
        )
        break
      default:
        break
    }
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className={style.tabLinkContainer}>
            {BOND_INFORMATION_TABS.map((item) => (
              <a
                key={item.value}
                className={`${style.tabLink} ${
                  activeTab === item.value ? style.tabLinkActive : ''
                }`}
                onClick={() => handleScrollLeft(item.value)}
              >
                <Span style={{ fontSize: 12 }}>
                  <Translate value={item.name} />
                </Span>
                {activeTab === item.value && (
                  <div className={style.tabLinkActiveBorderBottom}></div>
                )}
              </a>
            ))}
          </div>
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              <TableContent
                ids={ids}
                columnDraggable={false}
                sort={sort}
                getDataFromRedux={selectDataTableById}
                rowDraggable={false}
                isLoading={isLoading}
                scrollbarsRef={scrollbarsRef}
                idRowCustomStyle={['total']}
                isScrollHorizontal
                rowCustomStyle={{
                  position: 'sticky',
                  bottom: 1,
                  zIndex: 3,
                  background: '#273040',
                  fontWeight: 700,
                  fontStyle: 'italic',
                  textTransform: 'uppercase',
                  border: 'none',
                }}
                renderHeader={(stateSort, sortColumn, scrollLeft) => (
                  <TableHeader
                    tableRow={GROUP_BOND_INFORMATION_COLUMNS}
                    stateSort={stateSort}
                    sortColumn={sortColumn}
                    handleClick={() => setIsShow(true)}
                    scrollLeft={scrollLeft}
                    width={width}
                    tickerRef={tickerRef}
                    tradingDateRef={tradingDateRef}
                    totalValueRef={totalValueRef}
                    bondEventRef={bondEventRef}
                  />
                )}
                schema={GROUP_BOND_INFORMATION_COLUMNS.map((item) => {
                  const result = {
                    colId: item.name,
                  }

                  switch (item.name) {
                    case 'ticker':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                              className={`${style.cellLeft} ${
                                rowId !== 'total'
                                  ? style.firstTd
                                  : style.firstTdTotal
                              }`}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 7.5,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <div style={{ width: 12, paddingLeft: 2.5 }}>
                                  {rowId !== 'total' && (
                                    <Icon
                                      className="icon-delete-stroke cursor-pointer"
                                      style={{ color: '#AEBCD2', fontSize: 7 }}
                                      onClick={() => setIndex(tableIndex)}
                                    />
                                  )}
                                </div>
                                <Span style={{ flex: 1 }}>
                                  {rowId === 'total' ? (
                                    <Translate value={value} />
                                  ) : (
                                    <TextEllipsis
                                      text={value}
                                      appendStyle={{
                                        color:
                                          tableData?.activeStatusId === 16 ||
                                          tableData?.activeStatusId === 17
                                            ? '#FF4752'
                                            : '#ECECEC',
                                      }}
                                      isI18n={false}
                                    />
                                  )}
                                </Span>
                                {tableData?.isUserBond && (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M8 12L3.87163 14.1704C3.63687 14.2938 3.36249 14.0945 3.40733 13.8331L4.19577 9.23607L0.855851 5.98045C0.665926 5.79532 0.770729 5.47277 1.0332 5.43463L5.64886 4.76393L7.71305 0.581435C7.83043 0.343596 8.16958 0.343597 8.28696 0.581435L10.3511 4.76393L14.9668 5.43463C15.2293 5.47277 15.3341 5.79532 15.1441 5.98045L11.8042 9.23607L12.5927 13.8331C12.6375 14.0945 12.3631 14.2938 12.1284 14.1704L8 12Z"
                                      fill="url(#paint0_linear_186_480)"
                                    />
                                    <defs>
                                      <linearGradient
                                        id="paint0_linear_186_480"
                                        x1="-4.37204"
                                        y1="11.7128"
                                        x2="11.7692"
                                        y2="20.5613"
                                        gradientUnits="userSpaceOnUse"
                                      >
                                        <stop stopColor="#23DAE7" />
                                        <stop offset="1" stopColor="#3597FF" />
                                      </linearGradient>
                                    </defs>
                                  </svg>
                                )}
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'bondTypeName':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {locale === 'vi'
                                    ? tableData?.bondTypeName
                                    : tableData?.en_BondTypeName ?? ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'organizationShortName':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <TextEllipsis
                                  text={
                                    locale === 'vi'
                                      ? tableData?.organizationShortName
                                      : tableData?.en_OrganizationShortName ??
                                        ''
                                  }
                                  isI18n={false}
                                />
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'icbName':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  <TextEllipsis
                                    text={
                                      locale === 'vi'
                                        ? tableData?.icbName
                                        : tableData?.en_ICBName ?? ''
                                    }
                                    isI18n={false}
                                  />
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'issueDateId':
                    case 'nextPaymentDate':
                    case 'lastTradingDateId':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const date = moment(value).format(FORMAT.DATE[locale])

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total'
                                    ? moment(value).isValid()
                                      ? date
                                      : '--'
                                    : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'lastPaymentDate':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const date = moment(value).format(FORMAT.DATE[locale])

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total'
                                    ? moment(value).isValid()
                                      ? date
                                      : '--'
                                    : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'maturityDateId':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const date = moment(value).format(FORMAT.DATE[locale])

                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total'
                                    ? moment(value).isValid()
                                      ? date
                                      : '--'
                                    : ''}
                                </Span>
                                {rowId !== 'total' &&
                                  (tableData?.activeStatusId === 16 ||
                                    tableData?.activeStatusId === 17) && (
                                    <TooltipWarning
                                      tooltip={
                                        locale === 'vi'
                                          ? tableData?.activeStatusName ?? ''
                                          : tableData?.en_ActiveStatusName ?? ''
                                      }
                                      isI18n={false}
                                      alignItems="center"
                                      iconColor="#FF4752"
                                      maxWidthTooltip={375}
                                      isHtml
                                    />
                                  )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'tradingDateId':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          const date = moment(value).format(FORMAT.DATE[locale])

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'tradingDateId' ? (
                                  <DateTd
                                    value={value}
                                    handleChange={handleChangeTradingDate}
                                    handleClose={handleClose}
                                    rowId={rowId}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {moment(value).isValid() ? date : '--'}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('tradingDateId')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'valuationDate':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          const date = moment(value).format(FORMAT.DATE[locale])

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'valuationDate' ? (
                                  <DateTd
                                    value={value}
                                    handleChange={handleChangePaymentDate}
                                    handleClose={handleClose}
                                    rowId={rowId}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {moment(value).isValid() ? date : '--'}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('valuationDate')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'parValue':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total' ? formatVal(value, 0) : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'rbD0003':
                    case 'rbD0004':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{formatVal(value, 0)}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'bondVolume':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'bondVolume' ? (
                                  <InputTd
                                    initialValue={
                                      tableData?.bondVolume ?? undefined
                                    }
                                    handleSubmit={handleBlurInputVolume}
                                    fractionDigits={0}
                                    decimalRender={0}
                                    regex={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                                    rowId={rowId}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {formatVal(tableData?.bondVolume, 0)}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('bondVolume')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'buyPrice':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'buyPrice' ? (
                                  <InputTd
                                    initialValue={
                                      tableData?.buyPrice ?? undefined
                                    }
                                    handleSubmit={handleBlurInputBuyPrice}
                                    fractionDigits={0}
                                    rowId={rowId}
                                    regex={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                                    decimalRender={0}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>{formatVal(value, 0)}</Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('buyPrice')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'ytm':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'ytm' ? (
                                  <InputTd
                                    initialValue={tableData?.ytm ?? undefined}
                                    handleSubmit={handleBlurInputYtm}
                                    fractionDigits={2}
                                    decimalRender={2}
                                    rowId={rowId}
                                    suffix={'%'}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {tableData?.ytm !== ''
                                          ? valToPercent(tableData?.ytm, true)
                                          : ''}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('ytm')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'dirtyPrice':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'dirtyPrice' ? (
                                  <InputTd
                                    initialValue={
                                      tableData?.dirtyPrice ?? undefined
                                    }
                                    handleSubmit={handleBlurInputDirtyPrice}
                                    fractionDigits={0}
                                    decimalRender={0}
                                    rowId={rowId}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {formatVal(tableData?.dirtyPrice, 0)}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('dirtyPrice')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'cleanPrice':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          const tableIndex = data.findIndex(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                  gap: 8,
                                  height: 24,
                                }}
                                className={`d-flex ali-center ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                {isEdit &&
                                index === tableIndex &&
                                field === 'cleanPrice' ? (
                                  <InputTd
                                    initialValue={
                                      tableData?.cleanPrice ?? undefined
                                    }
                                    handleSubmit={handleBlurInputCleanPrice}
                                    fractionDigits={0}
                                    decimalRender={0}
                                    rowId={rowId}
                                  />
                                ) : (
                                  rowId !== 'total' && (
                                    <>
                                      <Span>
                                        {formatVal(tableData?.cleanPrice, 0)}
                                      </Span>
                                      <Icon
                                        className="icon-edit cursor-pointer"
                                        style={{ color: '#585858' }}
                                        onClick={() => {
                                          setIndex(tableIndex)
                                          setIsEdit(true)
                                          setFiled('cleanPrice')
                                        }}
                                      />
                                    </>
                                  )
                                )}
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'couponInterestRate':
                    case 'weighted':
                    case 'lastRBD0001':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{valToPercent(value)}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'rbD0008':
                    case 'lastDirtyPrice':
                    case 'lastCleanPrice':
                    case 'rbD0005':
                    case 'rbD0006':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total' ? formatVal(value, 0) : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'accruedInterest':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total' ? formatVal(value, 0) : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'bookValue':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{formatVal(value, 0)}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'totalValue':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{formatVal(value, 0)}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'buyValue':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{formatVal(value, 0)}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'remainDuration':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>
                                  {rowId !== 'total'
                                    ? formatDuration(value)
                                    : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }

                    case 'interestBasisTypeId':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const interestBasis = INTEREST_BASIS_OPTIONS.find(
                            (item) => item.value === value,
                          )

                          return (
                            <InterestBasisTd
                              props={props}
                              interestBasis={interestBasis}
                              item={item}
                              rowId={rowId}
                              handleChange={handleChangeInterestBasis}
                            />
                          )
                        },
                      }

                    case 'extraDiscounts':
                    case 'unallocatedExtra':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span
                                  style={{
                                    color:
                                      value > 0
                                        ? '#2DE352'
                                        : value < 0
                                        ? '#FF4752'
                                        : '#ECECEC',
                                  }}
                                >
                                  {rowId !== 'total' ? formatVal(value, 0) : ''}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'profitLoss':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                                background:
                                  rowId !== 'total'
                                    ? 'rgba(24, 89, 153, 0.15)'
                                    : 'transparent',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span
                                  style={{
                                    color:
                                      value > 0
                                        ? '#2DE352'
                                        : value < 0
                                        ? '#FF4752'
                                        : '#ECECEC',
                                  }}
                                >
                                  {formatVal(value, 0)}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }
                    case 'bondEventTypeName':
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          const tableData = data.find(
                            (item) => item?.id === rowId,
                          )

                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span
                                  style={{
                                    color: !!tableData?.bondEventTypeName
                                      ?.length
                                      ? '#FF4752'
                                      : '#ECECEC',
                                  }}
                                >
                                  {value}
                                </Span>
                              </div>
                            </td>
                          )
                        },
                      }

                    default:
                      return {
                        ...result,
                        canCustomTd: true,
                        render: (value, rowId, props) => {
                          return (
                            <td
                              {...props}
                              style={{
                                padding: 0,
                                height: 28,
                                boxSizing: 'border-box',
                              }}
                            >
                              <div
                                style={{
                                  ...item.attrs.style,
                                  padding: '4px 8px',
                                  boxSizing: 'border-box',
                                }}
                                className={`d-flex ${
                                  item?.justifyContent ??
                                  'justify-content-start'
                                }`}
                              >
                                <Span>{value}</Span>
                              </div>
                            </td>
                          )
                        },
                      }
                  }
                })}
                stickyFirstColumn={true}
                isPagination={false}
                hasFooter={false}
                resizable={false}
                isCollapse={false}
                hasTooltip={false}
              />
            </div>
          )}
          {index !== -1 && !isEdit && (
            <PopupDeleteTicker
              handleClose={() => setIndex(-1)}
              handleSubmit={handleDeleteTicker}
              data={data[index]}
            />
          )}
          {isShow && !isEdit && (
            <PopupDeleteAllTicker
              handleClose={() => setIsShow(false)}
              handleSubmit={handleDeleteAllTicker}
            />
          )}
          <PopupAlert
            message={<Translate value={error} />}
            isShow={!!error.length}
            handleClose={() => setError('')}
          />
        </>
      )}
    </SizeTracker>
  )
}

export default Table
