import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getFinancialMetricsComparisonChartData } from './thunk'

const initialState = {
  data: [],
  lastItem: {},
  loading: true,
}

const slice = createSlice({
  name: 'corporate/overview/peersComparison',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFinancialMetricsComparisonChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getFinancialMetricsComparisonChartData.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = action.payload || []
        state.lastItem =
          action.payload && action.payload.length > 0
            ? action.payload[action.payload.length - 1]
            : {}
      },
    )
    builder.addCase(
      getFinancialMetricsComparisonChartData.rejected,
      (state, action) => {
        state.loading = action.payload.isLoading
      },
    )
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading
export const selectLastItem = (state) => state[slice.name].lastItem

register(slice.name, slice.reducer)
