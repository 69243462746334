import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../../utils/Value'
import style from '../../index.module.css'
import { TYPE_DATA } from '../priceStatistics/constants'
import {
  selectListCheckedItems,
  selectTypeData,
} from '../priceStatistics/store/slice'
import { COLORS_LINE_CHART } from './constants'
import { selectActiveItem } from './store/slice'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20, bottom: 20 }

export const ChartComponent = ({
  width,
  height,
  keyXAxis,
  lineKeys,
  data,
  isYearly,
}) => {
  const listCheckedItems = useSelector(selectListCheckedItems)
  const activeItem = useSelector(selectActiveItem)
  const typeData = useSelector(selectTypeData)

  const growthSectorTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listCheckedItems.map((item) => (
              <tr className={style.trTable} key={item.id}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {item.steelProductTypeName
                        ? `${item.steelProductTypeName} - ${item.name}`
                        : item.name}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{ fontSize: 11 }}
                    className={
                      valueTooltip[item.id] < 0 ? style.negativeValColor : ''
                    }
                  >
                    {typeData === TYPE_DATA.PRICE
                      ? `${formatVal(valueTooltip[item.id], 0)} ${
                          valueTooltip[item.id]
                            ? I18n.t('sector.sectorSpecific.steel.VND/KG')
                            : ''
                        }`
                      : `${formatVal(valueTooltip[item.id])} ${
                          valueTooltip[item.id]
                            ? I18n.t('sector.sectorSpecific.steel.PERCENT')
                            : ''
                        }`}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                label:
                  typeData === TYPE_DATA.PRICE
                    ? `(${I18n.t('sector.sectorSpecific.steel.VND/KG')})`
                    : ``,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                unitYAxis: typeData === TYPE_DATA.PRICE ? '' : '%',
              },
            ]}
            timeFrame={!isYearly && TIME_RANGES.CUSTOM}
            isNotFormatXAxis={!isYearly}
            renderCustomTooltip={(payload, decimalLengths) =>
              growthSectorTooltip(payload, decimalLengths)
            }
          >
            {listCheckedItems.map((item) => (
              <Line
                key={item.id}
                yAxisId="left"
                dataKey={item.id}
                type="linear"
                stroke={COLORS_LINE_CHART[item.colorId]}
                dot={false}
                activeDot={false}
                strokeOpacity={
                  !activeItem ? 1 : activeItem === item.id ? 1 : 0.1
                }
                isAnimationActive={false}
                strokeWidth={
                  !activeItem ? 1.5 : activeItem === item.id ? 2.5 : 1.5
                }
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}
