import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { useGetNameSecuritiesIndicator } from '../../chart/common/useGetNameSecuritiesIndicator'
import {
  selectIndicatorByTypeSecurities,
  selectIndicators,
} from '../../store/slice'
import { SEC_GROUP_INDICATOR } from '../constants'
import { formatTableData } from '../helpers'
import { stickyColumnsKey } from './constants'
import {
  getEconomyInfoById,
  getIndicatorsInfoById,
  getTableHeader,
} from './helps'
import {
  changeCellInfos,
  changeColumnIds,
  changeColumns,
  changeColumnsById,
  changeData,
  changeTableHeaderAttr,
  resetDefault,
  selectTableHeaderAttr,
} from './store/slice'

export const TableSettings = ({
  data,
  fullDataByTimeDataKey,
  schema,
  viewType,
  treeSecuritiesIndicator,
  equations,
}) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const { getSecuritiesName } = useGetNameSecuritiesIndicator()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const tableHeaderAttr = useSelector(selectTableHeaderAttr)
  const indicators = useSelector(selectIndicators)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )

  // Use state
  const [indicatorsInfoById, setIndicatorsInfoById] = useState({})
  const [economyInfoById, setEconomyInfoById] = useState({})

  // Use effect
  useEffect(() => {
    let columns = []
    let cellInfos = []

    ;[...tableHeaderAttr].reverse().forEach((row) => {
      row
        .filter((col) => !col.isNoData)
        .forEach((col) => {
          if (Object.values(stickyColumnsKey).includes(col.id)) {
            cellInfos.push(col)
          } else {
            columns.push(col)
          }
        })
    })

    const columnIds = []
    const columnsById = columns.reduce((obj, item) => {
      columnIds.push(item.id)
      return { ...obj, [item.id]: item }
    }, {})

    dispatch(changeCellInfos(cellInfos))
    dispatch(changeColumns(columns))
    dispatch(changeColumnIds(columnIds))
    dispatch(changeColumnsById(columnsById))
  }, [tableHeaderAttr])

  useEffect(() => {
    const data = getTableHeader({
      treeSecuritiesIndicator,
      equations,
      indicatorsInfoById,
      economyInfoById,
      locale,
      timeZone,
      viewType,
      getSecuritiesName,
    })

    dispatch(changeTableHeaderAttr(data))
  }, [
    locale,
    treeSecuritiesIndicator,
    equations,
    indicatorsInfoById,
    economyInfoById,
    timeZone,
    getSecuritiesName,
  ])

  useEffect(() => {
    if (indicators.length) {
      const indicatorsById = getIndicatorsInfoById(
        indicators,
        treeSecuritiesIndicator,
      )
      setIndicatorsInfoById(indicatorsById)
    }
  }, [treeSecuritiesIndicator, indicators])

  useEffect(() => {
    if (Object.keys(treeSecuritiesIndicator.economy).length) {
      const economyById = getEconomyInfoById(
        listSecuritiesEconomy,
        Object.keys(treeSecuritiesIndicator.economy),
      )
      setEconomyInfoById(economyById)
    }
  }, [listSecuritiesEconomy, treeSecuritiesIndicator.economy])

  useEffect(() => {
    if (data.length) {
      dispatch(
        changeData(
          formatTableData(
            data,
            fullDataByTimeDataKey,
            schema,
            viewType,
          ).reverse(),
        ),
      )
    } else {
      dispatch(resetDefault())
    }
  }, [data, schema])

  return <></>
}
