import { getTimeFrequencyByIndicator } from '.'
import { VIEW_TYPE } from '../../chart/constants'
import { LIST_TYPE, TIME_RANGE } from '../constants'

// Company, Indices, Sector get payload and promises action
export const getSecuritiesPayloadAndPromises = (params, securityKey) => {
  if (params.ViewType === VIEW_TYPE.BY_TIME) {
    return getSecuritiesPayloadAndPromisesByTime(
      params,
      params[securityKey],
      params.IndicatorId,
      securityKey,
    )
  } else {
    return getSecuritiesPayloadAndPromisesBySecurities(
      params,
      params[securityKey],
      params.IndicatorId,
      securityKey,
    )
  }
}

const getSecuritiesPayloadAndPromisesByTime = (
  params,
  securitiesId,
  indicatorsId,
  securityKey,
) => {
  const payloads = []

  securitiesId.forEach((securityId) => {
    indicatorsId.forEach((indicator) => {
      const payload = {
        ...params,
        [securityKey]: securityId,
        IndicatorId: indicator.id,
        timeFrequency: indicator.timeFrequency,
        uniqueID: indicator.uniqueID,
      }

      payloads.push(payload)
    })
  })

  return payloads
}

const getSecuritiesPayloadAndPromisesBySecurities = (
  params,
  securitiesId,
  indicatorsId,
  securityKey,
) => {
  const payloads = []

  securitiesId.forEach((securityId) => {
    indicatorsId.forEach((indicator) => {
      const payload = {
        ...params,
        [securityKey]: securityId,
        IndicatorId: indicator.id,
        timeFrequency: indicator.timeFrequency,
        TimeRange: undefined,
        uniqueID: indicator.uniqueID,
        ...indicator.dataLatestPeriod,
      }

      if (Object.values(indicator.dataLatestPeriod).some((val) => !!val)) {
        payloads.push(payload)
      }
    })
  })

  return payloads
}

// Company, Indices, Sector get data from responses
export const getSecuritiesData = (
  response,
  payloads,
  viewType,
  securityType,
  securityKeyId,
) => {
  const result = {}

  response.forEach((res, index) => {
    const item = payloads[index]

    if (res.status === 'fulfilled' && res.value.success) {
      if (viewType === VIEW_TYPE.BY_TIME) {
        const keyData = item.uniqueID
          ? [
              securityType,
              item[securityKeyId],
              item.IndicatorId,
              item.timeFrequency,
              item.uniqueID,
            ].join('_')
          : [
              securityType,
              item[securityKeyId],
              item.IndicatorId,
              item.timeFrequency,
            ].join('_')

        result[keyData] = res.value.data
      } else {
        const securityKey = [securityType, item[securityKeyId]].join('_')
        const indicatorKey = item?.uniqueID
          ? [
              securityType,
              'indicator',
              item.IndicatorId,
              item.timeFrequency,
              item.uniqueID,
            ].join('_')
          : [
              securityType,
              'indicator',
              item.IndicatorId,
              item.timeFrequency,
            ].join('_')

        result[securityKey] = {
          ...result[securityKey],
          [indicatorKey]: {
            ...(res.value.data[0] || {}),
            id: item.IndicatorId,
            timeFrequency: item.timeFrequency,
            uniqueID: item?.uniqueID,
          },
        }
      }
    }
  })
  return result
}

// Economy get payload and promises action
export const getEconomyPayloadAndPromises = (params) => {
  const payloads = []

  params.IndicatorId.forEach((indicator) => {
    let payload = {
      ...params,
      IndicatorId: indicator.id,
      timeFrequency: indicator.timeFrequency,
    }

    if (params.ViewType === VIEW_TYPE.BY_SECURITIES) {
      payload = { ...payload, ...indicator.dataLatestPeriod }
    }

    if (
      params.ViewType === VIEW_TYPE.BY_SECURITIES &&
      !Object.values(indicator.dataLatestPeriod).some((val) => !!val)
    ) {
      return
    }

    payloads.push(payload)
  })

  return payloads
}

// Economy get data from responses
export const getEconomyData = (response, payloads, viewType) => {
  const result = {}

  response.forEach((res, index) => {
    const item = payloads[index]

    if (res.status === 'fulfilled' && res.value.success) {
      const keyData = [
        LIST_TYPE.ECONOMY,
        item.IndicatorId,
        item.timeFrequency,
      ].join('_')

      if (viewType === VIEW_TYPE.BY_TIME) {
        result[keyData] = res.value.data
      } else {
        result[keyData] = {
          ...(res.value.data[0] || {}),
          id: item.IndicatorId,
          timeFrequency: item.timeFrequency,
        }
      }
    }
  })

  return result
}

// Get equation params
export const getEquationDataAggregateParams = (
  viewType,
  equation,
  indicatorsLatestPeriod,
) => {
  return {
    viewType,
    timeRange: TIME_RANGE.ALL_TIME,
    aggregateFunction: equation.equationType,
    organizationIds: equation.equationSecurities.companies || [],
    formula: equation.equationFormula,
    indicators: Object.values(equation.equationIndicators).reduce(
      (arr, indicator) => [
        ...arr,
        ...Object.values(indicator.data).map((values) => {
          const timeParams =
            viewType === VIEW_TYPE.BY_SECURITIES
              ? {
                  year: getValueByKey(
                    indicator,
                    values,
                    'year',
                    indicatorsLatestPeriod,
                  ),
                  quarter: getValueByKey(
                    indicator,
                    values,
                    'quarter',
                    indicatorsLatestPeriod,
                  ),
                  month: getValueByKey(
                    indicator,
                    values,
                    'month',
                    indicatorsLatestPeriod,
                  ),
                  week: getValueByKey(
                    indicator,
                    values,
                    'week',
                    indicatorsLatestPeriod,
                  ),
                  date: getValueByKey(
                    indicator,
                    values,
                    'date',
                    indicatorsLatestPeriod,
                  ),
                }
              : {}
          return {
            ...indicator,
            ...values,
            ...timeParams,
            data: undefined,
          }
        }),
      ],
      [],
    ),
    order: equation.order,
    equationId: equation.dataKey,
  }
}

export const getEquationDataCountParams = (
  viewType,
  equation,
  indicatorsLatestPeriod,
) => {
  return {
    viewType,
    timeRange: TIME_RANGE.ALL_TIME,
    groupIds: Object.values(equation.equationSecurities.indices || {})
      .reduce((arr, values) => [...arr, ...values], [])
      .filter((id, index, arr) => index === arr.findIndex((val) => val === id)),
    icbIds: Object.values(equation.equationSecurities.sector || {})
      .reduce((arr, values) => [...arr, ...values], [])
      .filter((id, index, arr) => index === arr.findIndex((val) => val === id)),
    indicators: Object.values(equation.equationIndicators).reduce(
      (arr, indicator) => [
        ...arr,
        ...Object.values(indicator.data).map((values) => {
          const timeParams =
            viewType === VIEW_TYPE.BY_SECURITIES
              ? {
                  year: getValueByKey(
                    indicator,
                    values,
                    'year',
                    indicatorsLatestPeriod,
                  ),
                  quarter: getValueByKey(
                    indicator,
                    values,
                    'quarter',
                    indicatorsLatestPeriod,
                  ),
                  month: getValueByKey(
                    indicator,
                    values,
                    'month',
                    indicatorsLatestPeriod,
                  ),
                  week: getValueByKey(
                    indicator,
                    values,
                    'week',
                    indicatorsLatestPeriod,
                  ),
                  date: getValueByKey(
                    indicator,
                    values,
                    'date',
                    indicatorsLatestPeriod,
                  ),
                }
              : {}
          return {
            ...indicator,
            ...values,
            ...timeParams,
            data: undefined,
          }
        }),
      ],
      [],
    ),
    order: equation.order,
    equationId: equation.dataKey,
  }
}

const getValueByKey = (indicator, values, key, indicatorsLatestPeriod) => {
  const latestPeriod =
    indicatorsLatestPeriod[
      `${indicator.indicatorId}_${getTimeFrequencyByIndicator(indicator)}`
    ]
  return values[key] || (latestPeriod && latestPeriod[key]) || null
}

export const getSecuritiesIndicatorLatestPeriodParams = (
  indicators,
  indicatorsLatestPeriod,
  isViewByLatestPeriod,
  viewType,
) => {
  return indicators
    ? indicators
        .map((item) => {
          return {
            ...item,
            dataLatestPeriod: {
              ...(indicatorsLatestPeriod[
                `${item.id}_${getTimeFrequencyByIndicator(item)}`
              ] || {}),
              ...(!isViewByLatestPeriod ? item.dataLatestPeriod : {}),
            },
          }
        })
        .filter(
          (item) =>
            viewType === VIEW_TYPE.BY_TIME ||
            (viewType === VIEW_TYPE.BY_SECURITIES &&
              Object.keys(item.dataLatestPeriod).length > 0),
        )
    : []
}
