import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellRedirect } from '../../../../common/table/CellRedirect'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import TextEllipsis from '../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_DETAIL_KEYS,
  TABLE_DETAIL_TH_ROW_KEYS,
} from '../constants'

export const detailSchema = ({ locale, onClick }) => {
  return TABLE_DETAIL_KEYS.map((item) => {
    let result = {
      ...item,
      tdStyle: {
        textAlign: 'left',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_TICKER) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'left',
          maxWidth: 110,
          whiteSpace: 'nowrap',
        },
        canCustomTd: true,
        render: (val, rowId, { style, className }) => (
          <CellRedirect
            val={val}
            rowId={rowId}
            style={style}
            className={className}
            onClick={onClick}
          />
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_INVESTMENT_STRATEGY) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'left',
          maxWidth: 110,
          whiteSpace: 'nowrap',
        },
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_NAME) {
      return {
        ...result,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: 190,
            }}
            className={className}
          >
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    result = {
      ...result,
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_UPDATE_DATE) {
      return {
        ...result,
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_1M,
        TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y,
        TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y,
        TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET,
        TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_TOTAL_VALUE) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) =>
              formatVal(
                val / TABLE_COL_FORMATS.COL_DETAIL_FUND_HOLDING_TOTAL_VALUE,
              )
            }
          />
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => formatVal(Math.round(val), 0)}
          />
        ),
      }
    }

    return {
      ...result,
      render: (val) => (
        <CellWithColor val={val} format={(val) => formatVal(val, 0)} />
      ),
    }
  })
}

export const detailTableThSchema = () => {
  return TABLE_DETAIL_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      const result = {
        ...col,
        isI18n: false,
        rowSpan: 2,
      }

      if (col.colId === TABLE_COL_KEYS.COL_DETAIL_TICKER) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            minWidth: 110,
          },
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_DETAIL_NAME) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
          },
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_DETAIL_INVESTMENT_STRATEGY) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
          },
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ) {
        return {
          ...result,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
          },
          renderTh: () => (
            <div style={{ textAlign: 'right' }} className="flex-column">
              <Span>
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.VND" />)
              </Span>
            </div>
          ),
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_DETAIL_UPDATE_DATE) {
        return {
          ...result,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
          },
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE,
          TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
        ].includes(col.colId)
      ) {
        return {
          ...col,
          isI18n: false,
          thStyle: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
          },
          colSpan:
            col.colId === TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE ? 3 : 7,
        }
      }

      return {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
        },
        renderTh: () => (
          <div style={{ textAlign: 'right' }}>
            <Span className="mr-4">
              <Translate value={col.title} />
            </Span>
            {col.colId ===
              TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_TOTAL_VALUE && (
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.BILLION_VND" />)
              </Span>
            )}
          </div>
        ),
      }
    }),
  )
}

export const detailThCellTopById = {
  [TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_1M]: [
    TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y]: [
    TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y]: [
    TABLE_COL_KEYS.COL_DETAIL_NAV_CCQ_PERFORMANCE,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_VOLUME]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_TOTAL_VALUE]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE]: [
    TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING,
  ],
}
