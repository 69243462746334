import { useEffect, useMemo, useRef, useState } from 'react'
import Dropdown from '../../../../common/dropdown'

import PropTypes from 'prop-types'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import useGetICB from '../../../../common/masterData/useGetICB'
import Tree from '../../../../common/tree'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import classesTheme from '../../styleTheme.module.css'
import NameChecked from './NameChecked'
import classes from './index.module.css'

const SelectSector = ({
  selectSectorRef,
  listChecked,
  listParentChecked,
  isFirstMount,
  changeFirstMountPopup,
  changeListParentChecked,
  changeSectorChecked,
  isSetSectorAll,
}) => {
  const parentRef = useRef()

  const { ICBsTree, ICBs, loading } = useGetICB(true, true)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')

  const placeHolder = UseI18n('common.SEARCH')

  const ICBsTreeMemo = useMemo(() => {
    return ICBsTree
  }, [JSON.stringify(ICBsTree)])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  useEffect(() => {
    if (isSetSectorAll) {
      const arrayIdSector = ICBs.map((item) => item.icbId)
      if (arrayIdSector.length !== listChecked.length) {
        changeSectorChecked(arrayIdSector)
      }
    }

    return () => changeFirstMountPopup(false)
  }, [])

  return (
    <div className={`input-dropdown ${classes.selectSector}`}>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className="span-input w-100 cursor-pointer h-100"
        style={{
          padding: 0,
          borderBottomLeftRadius: isShowDropdown ? 0 : 4,
          borderBottomRightRadius: isShowDropdown ? 0 : 4,
          color: '#4e4e4e',
          background: '#F2F2F2',
        }}
      >
        <NameChecked
          ICBs={ICBs}
          icbTreeLength={Object.keys(ICBsTreeMemo).length}
          listParentChecked={listParentChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            ref={selectSectorRef}
            className={`wrapper-tool`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${classes.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${classes.search} ${classesTheme.inputStyle}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={classes.btnSearch} type="button">
                    <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
                  </button>
                </div>
                <div className={classes.treeContainer}>
                  {loading ? (
                    <Loading isBlack />
                  ) : (
                    <Tree
                      valueSearch={valueSearch}
                      treeData={ICBsTreeMemo}
                      idKey="icbId"
                      nameKey="icbName"
                      levelKey="icbLevel"
                      zIndexTextEllipsis={12}
                      listChecked={listChecked}
                      setListChecked={changeSectorChecked}
                      setListParentChecked={changeListParentChecked}
                      isHaveCheckbox={true}
                      isHaveCheckboxAll={true}
                      isDefaultCheckAll={isFirstMount}
                      titleCheckboxAll="financialChart.ALL_SECTOR"
                      isActiveSelected={false}
                      classItemStyle={classes.itemStyle}
                      classCheckboxStyle={classes.checkboxStyle}
                      bgColorScroll="#D9D9D9"
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

SelectSector.propTypes = {
  listChecked: PropTypes.array,
  listParentChecked: PropTypes.array,
  isFirstMount: PropTypes.bool,
  changeFirstMountPopup: PropTypes.func,
  changeListParentChecked: PropTypes.func,
  changeSectorChecked: PropTypes.func,
  isSetSectorAll: PropTypes.bool,
}

export default SelectSector
