import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import UseFontSize from '../../../../../common/hooks/useFontSize'
import useHeightTdTable from '../../../../../common/hooks/useHeightTdTable'
import { Span } from '../../../../../common/html/Span'
import { MAP_STOCKS_SYMBOL } from '../../../../../common/topInfo/constant'
import { selectWarrantInfo } from '../../../../../common/topInfo/warrantTopInfo/store/slice'
import { getLatestPrice } from '../../../../../common/topInfo/warrantTopInfo/store/thunk'
import { EMPTY_VALUE, GROUP_TYPE } from '../../../../../constants/Common'
import { getThreeColorByValue } from '../../../../../utils/Color'
import { debounce } from '../../../../../utils/Common'
import EventEmitter, {
  COMPONENT_RESIZE,
} from '../../../../../utils/EventEmitter'
import {
  formatVal,
  valByKeyWithDot,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../../utils/Value'
import style from '../priceDepth.module.css'
import { selectData, selectPriceData } from '../store/slice'
import { getPriceDepthTable } from '../store/thunk'

const Td = ({ children, ...props }) => {
  const heightTd = useHeightTdTable()

  return (
    <td {...props} style={{ ...props.style, height: heightTd }}>
      {children}
    </td>
  )
}

export const TablePriceDepth = ({ height }) => {
  const renderItem = ({ label, value, format = (value) => value }) => {
    return (
      <>
        <Td className={[style.bRight0, style.fw440].join(' ')}>
          <Translate
            value={label}
            className={[style.textAlignLeft, 'canvas-chart-title'].join(' ')}
          />
        </Td>
        <Td className={['t-right', style.bLeft0].join(' ')}>
          <Span
            className={[style.textAlignRight, 'canvas-chart-title'].join(' ')}
          >
            {format(value)}
          </Span>
        </Td>
      </>
    )
  }

  const percentageValCell = (val) => {
    return typeof val === 'number' ? (
      <>
        <span style={{ color: getThreeColorByValue(val) }}>
          {valToPercent(val)}
        </span>
      </>
    ) : (
      <>{EMPTY_VALUE}</>
    )
  }

  const getItemByKey = (key, data) => {
    return data[key] ?? EMPTY_VALUE
  }

  const renderFourColumn = (tableStyle, tableRef) => {
    return (
      <table style={tableStyle} className="four-column-table" ref={tableRef}>
        <tbody>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1D_CHANGE',
              value: priceData
                ? percentageValCell(
                    valByKeyWithDot(
                      priceData,
                      MAP_STOCKS_SYMBOL.percentPriceChange,
                    ),
                  )
                : EMPTY_VALUE,
            })}
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.TOTAL_VOLUME',
              value: priceData
                ? valByKeyWithDot(priceData, 'priceInfo.totalVolume')
                : EMPTY_VALUE,
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1W_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChange1WeekClosePrice', data),
              ),
            })}
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.FOREIGN_BUY_VOLUME',
              value: getItemByKey('foreignBuyVolumeTotal', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1M_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChange1MonthClosePrice', data),
              ),
            })}
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.FOREIGN_SELL_VOLUME',
              value: getItemByKey('foreignSellVolumeTotal', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_FTD_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChangeFTDClosePrice', data),
              ),
            })}
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.AVG_VOLUME_10D',
              value: getItemByKey('avg10DayTotalVolume', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.TOTAL_VALUE_BN_VND',
              value: priceData
                ? valByKeyWithDot(priceData, 'priceInfo.totalValue')
                : EMPTY_VALUE,
              format: (val) => formatVal(valDivMillion(val)),
            })}
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.AVG_VOLUME_1M',
              value: getItemByKey('avg1MonthTotalVolume', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const renderTwoColumn = (tableStyle, tableRef) => {
    return (
      <table style={tableStyle} className="two-column-table" ref={tableRef}>
        <tbody>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1D_CHANGE',
              value: priceData
                ? percentageValCell(
                    valByKeyWithDot(
                      priceData,
                      MAP_STOCKS_SYMBOL.percentPriceChange,
                    ),
                  )
                : EMPTY_VALUE,
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1W_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChange1WeekClosePrice', data),
              ),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_1M_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChange1MonthClosePrice', data),
              ),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.PERCENT_FTD_CHANGE',
              value: percentageValCell(
                getItemByKey('totalPercentChangeFTDClosePrice', data),
              ),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.TOTAL_VALUE_BN_VND',
              value: priceData
                ? valByKeyWithDot(priceData, 'priceInfo.totalValue')
                : EMPTY_VALUE,
              format: (val) => formatVal(valDivMillion(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.TOTAL_VOLUME',
              value: priceData
                ? valByKeyWithDot(priceData, 'priceInfo.totalVolume')
                : EMPTY_VALUE,
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.FOREIGN_BUY_VOLUME',
              value: getItemByKey('foreignBuyVolumeTotal', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.FOREIGN_SELL_VOLUME',
              value: getItemByKey('foreignSellVolumeTotal', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.AVG_VOLUME_10D',
              value: getItemByKey('avg10DayTotalVolume', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
          <tr>
            {renderItem({
              label: 'market.coveredWarrant.priceDepth.AVG_VOLUME_1M',
              value: getItemByKey('avg1MonthTotalVolume', data),
              format: (val) => formatVal(valDivThousand(val)),
            })}
          </tr>
        </tbody>
      </table>
    )
  }

  const dispatch = useDispatch()

  const warrantInfo = useSelector(selectWarrantInfo)
  const data = useSelector(selectData)
  const priceData = useSelector(selectPriceData)
  const [size, setSize] = useState(null)
  const ref = useRef()
  const tableRef = useRef()
  const [isTwoColumn, setIsTwoColumn] = useState(false)
  const locale = useSelector((state) => state.i18n.locale)
  const typeFontSize = UseFontSize()

  useEffect(() => {
    if (warrantInfo.coveredWarrantId) {
      dispatch(
        getPriceDepthTable({
          CoveredWarrantId: warrantInfo.coveredWarrantId,
        }),
        getLatestPrice({
          Ids: warrantInfo.coveredWarrantId,
          GroupType: GROUP_TYPE.COVERED_WARRANT,
        }),
      )
    }
  }, [warrantInfo.coveredWarrantId])

  useEffect(() => {
    if (tableRef.current) {
      setSize({
        width: tableRef.current.offsetWidth,
        height: tableRef.current.offsetHeight,
      })
      responsiveTable(tableRef.current.offsetWidth)
    }
  }, [locale, typeFontSize, data, priceData?.priceInfo])

  const responsiveTable = (width) => {
    if (ref.current && ref.current.offsetWidth >= width) {
      setIsTwoColumn(false)
    } else {
      setIsTwoColumn(true)
    }
  }

  useEffect(() => {
    const debounceResize = debounce(() => responsiveTable(size.width))
    window.addEventListener('resize', debounceResize)
    return () => {
      window.removeEventListener('resize', debounceResize)
    }
  }, [size])

  useEffect(() => {
    const resize = () => responsiveTable(size.width)
    EventEmitter.subscribe(COMPONENT_RESIZE, resize)
    return () => {
      EventEmitter.unsubscribe(COMPONENT_RESIZE, resize)
    }
  }, [size])

  return (
    <>
      <div className={`${style.table} h-100`} ref={ref}>
        {size && (
          <ScrollComponent
            autoHeight
            autoHeightMax={height}
            verticalTrackWidth={5}
            horizontalTrackWidth={5}
          >
            {isTwoColumn && renderTwoColumn({ width: '100%' }, tableRef)}
            {!isTwoColumn && renderFourColumn({ width: '100%' }, tableRef)}
          </ScrollComponent>
        )}
        {!size && renderFourColumn({}, tableRef)}
      </div>
    </>
  )
}
