import moment from 'moment'
import { I18n } from 'react-redux-i18n'
import { typeOfIndicator } from '../../../common/dataExplorer/constant'
import TextEllipsis from '../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../utils/Value'
import { getNameIndicator } from '../../chart/helper'
import { MAP_NAME_BY_TIME_FREQUENCY } from '../../popup/constants'
import {
  equationFormatUnitConstants,
  equationType,
} from '../../popup/popupAddEquation/constants'
import { LIST_TYPE, VIEW_TYPE } from '../constants'
import { dataFormatTypes } from '../menuChartSettings/constants'
import {
  stickyColumnsConstants,
  stickyColumnsKey,
  stickyColumnsMappingDisplay,
  widthStickyColumn,
} from './constants'
import styles from './style.module.css'

const getThColumnLeft = (index) => {
  const thPadding = 8
  if (index === 0) return 0
  return [...new Array(index)]
    .map(
      (_, index) =>
        widthStickyColumn[stickyColumnsConstants[index]] + thPadding * 2,
    )
    .reduce((total, num) => total + num, 0)
}

const getColorVal = (val) => {
  return Number(val) && val < 0 ? '#ff4752' : undefined
}

const getCellRender = ({ val, el, indicator, locale, timezone }) => {
  if (typeof val === 'undefined') return ''

  if (el) {
    if (stickyColumnsKey[el] === stickyColumnsKey.DATE) {
      return formatDateTime(val, FORMAT.DATE, locale, timezone)
    }
  }

  if (indicator) {
    let unit = ''
    let value = val
    if (indicator.indicatorUnit) {
      unit = indicator.indicatorUnit
    }
    if (indicator.equationUnit) {
      unit = indicator.equationUnit
    }
    if (
      indicator.equationFormatUnit &&
      indicator.equationType !== equationType.COUNT
    ) {
      switch (indicator.equationFormatUnit) {
        case equationFormatUnitConstants.EQUATION_FORMAT_PERCENT:
          unit = '%'
          break
        case equationFormatUnitConstants.EQUATION_FORMAT_TIMES:
          unit = I18n.t('financialChart.EQUATION_FORMAT_TIMES')
          break
        default:
          unit = 'VND'
          break
      }
    }
    if (indicator.equationType === equationType.COUNT) {
      unit = I18n.t('financialChart.EQUATION_COUNT_UNIT_FIRMS')
    }

    const textVal =
      typeof val === 'undefined'
        ? ''
        : unit === '%' ||
          indicator.dataFormatType === dataFormatTypes.PERCENT ||
          indicator.dataFormatType === dataFormatTypes.PREV_PERCENT
        ? valToPercent(value, true, false, indicator.decimalPlace)
        : formatVal(value, indicator.decimalPlace)
    return (
      <TextEllipsis
        text={textVal}
        appendStyle={{ color: getColorVal(val) }}
        isI18n={false}
      />
    )
  }

  return val
}

const getRowAttrsBySecurity = ({
  security,
  indicatorsInfoById,
  securityType,
  locale,
  timezone,
  viewType,
  getSecuritiesName,
}) => {
  const rowOneAttrs = []
  const rowTwoAttrs = []

  Object.keys(security)
    .sort((a, b) => +a - +b)
    .forEach((id) => {
      Object.values(security[id]).forEach((indicator) => {
        if (indicator.visibility) {
          const indicatorId = `${indicator.indicatorId}_${indicator.indicatorTimeFrequency}`

          rowOneAttrs.push({
            title: getSecuritiesName(securityType, +id),
            id: `${id}_${indicatorId}`,
            colSpan: 1,
            rowSpan: 1,
            isNoData: true,
            thStyle: {
              textAlign: 'right',
              backgroundColor: '#e7e7e7',
              color: '#4e4e4e',
            },
          })

          const idBySecurities =
            viewType === VIEW_TYPE.BY_SECURITIES
              ? indicator.dataKeyBySecurityDuplicate ||
                indicator.dataKeyBySecurity
              : ''
          rowTwoAttrs.push({
            title:
              indicatorsInfoById[indicatorId]?.name ||
              indicator.dataKeyDuplicate ||
              indicator.dataKey,
            id: indicator.dataKeyDuplicate || indicator.dataKey,
            idBySecurities: idBySecurities,
            parentKey: `${id}_${indicatorId}`,
            fieldId: indicator.dataKeyDuplicate || indicator.dataKey,
            fieldIdBySecurities: idBySecurities,
            unit: indicatorsInfoById[indicatorId]?.unit,
            typeOf: typeOfIndicator.INT,
            colSpan: 1,
            rowSpan: 1,
            thStyle: {
              textAlign: 'right',
              minWidth: widthStickyColumn.OTHER,
              backgroundColor: '#e7e7e7',
              color: '#4e4e4e',
            },
            chartAttributes: indicator,
            render: (idTicker, id, val) =>
              getCellRender({ val, indicator, locale, timezone }),
          })
        }
      })
    })

  return { rowOne: rowOneAttrs, rowTwo: rowTwoAttrs }
}

export const getTableHeader = ({
  treeSecuritiesIndicator,
  equations,
  indicatorsInfoById,
  economyInfoById,
  locale,
  timezone,
  viewType,
  getSecuritiesName,
}) => {
  const { companies, indices, sector, economy } = treeSecuritiesIndicator
  let rowOneAttrs = stickyColumnsConstants.map((el, index) => ({
    id: stickyColumnsKey[el],
    fieldId: stickyColumnsKey[el],
    title: stickyColumnsMappingDisplay[el],
    colSpan: 1,
    rowSpan: 2,
    disableSort: true,
    isSticky: true,
    appendStyleTh: {
      textAlign: 'left',
      minWidth: widthStickyColumn[el],
      maxWidth: widthStickyColumn[el],
      left: getThColumnLeft(index),
      backgroundColor: '#e7e7e7',
      color: '#4e4e4e',
    },
    appendStyle: {
      textAlign: 'left',
      minWidth: widthStickyColumn[el],
      maxWidth: widthStickyColumn[el],
      left: getThColumnLeft(index),
      backgroundColor: '#ffffff',
      color: '#4e4e4e',
    },
    render: (idTicker, id, val) => getCellRender({ val, el, locale, timezone }),
  }))
  let rowTwoAttrs = []

  if (Object.keys(companies).length) {
    const { rowOne, rowTwo } = getRowAttrsBySecurity({
      security: companies,
      indicatorsInfoById,
      securityType: LIST_TYPE.COMPANIES,
      locale,
      timezone,
      viewType,
      getSecuritiesName,
    })
    rowOneAttrs = [...rowOneAttrs, ...rowOne]
    rowTwoAttrs = [...rowTwoAttrs, ...rowTwo]
  }

  if (Object.keys(indices).length) {
    const { rowOne, rowTwo } = getRowAttrsBySecurity({
      security: indices,
      indicatorsInfoById,
      securityType: LIST_TYPE.INDICES,
      locale,
      timezone,
      viewType,
      getSecuritiesName,
    })
    rowOneAttrs = [...rowOneAttrs, ...rowOne]
    rowTwoAttrs = [...rowTwoAttrs, ...rowTwo]
  }

  if (Object.keys(sector).length) {
    const { rowOne, rowTwo } = getRowAttrsBySecurity({
      security: sector,
      indicatorsInfoById,
      securityType: LIST_TYPE.SECTOR,
      locale,
      timezone,
      viewType,
      getSecuritiesName,
    })
    rowOneAttrs = [...rowOneAttrs, ...rowOne]
    rowTwoAttrs = [...rowTwoAttrs, ...rowTwo]
  }

  if (Object.keys(economy).length) {
    Object.keys(economy)
      .sort((a, b) => a.localeCompare(b))
      .forEach((economyId) => {
        if (economy[economyId].visibility) {
          rowOneAttrs.push({
            id: economy[economyId].dataKey,
            fieldId: economy[economyId].dataKey,
            title:
              economyInfoById[economyId]?.name || economy[economyId].dataKey,
            unit: economyInfoById[economyId]?.unit,
            typeOf: typeOfIndicator.INT,
            colSpan: 1,
            rowSpan: 2,
            thStyle: {
              minWidth: widthStickyColumn.OTHER,
              textAlign: 'right',
              backgroundColor: '#e7e7e7',
              color: '#4e4e4e',
            },
            chartAttributes: economy[economyId],
            render: (idTicker, id, val) =>
              getCellRender({
                val,
                indicator: economy[economyId],
                locale,
                timezone,
              }),
          })
        }
      })
  }

  if (Object.keys(equations).length) {
    Object.values(equations).forEach((equation) => {
      if (equation.visibility) {
        let unit = equation.equationUnit
        if (
          equation.equationFormatUnit &&
          equation.equationType !== equationType.COUNT
        ) {
          switch (equation.equationFormatUnit) {
            case equationFormatUnitConstants.EQUATION_FORMAT_PERCENT:
              unit = '%'
              break
            case equationFormatUnitConstants.EQUATION_FORMAT_TIMES:
              unit = I18n.t('financialChart.EQUATION_FORMAT_TIMES')
              break
            default:
              unit = 'VND'
              break
          }
        }
        if (equation.equationType === equationType.COUNT) {
          unit = I18n.t('financialChart.EQUATION_COUNT_UNIT_FIRMS')
        }

        rowOneAttrs.push({
          id: equation.dataKey,
          fieldId: equation.dataKey,
          title: equation.equationIndicatorName,
          unit: unit,
          typeOf: typeOfIndicator.INT,
          colSpan: 1,
          rowSpan: 2,
          thStyle: {
            minWidth: widthStickyColumn.OTHER,
            textAlign: 'right',
            backgroundColor: '#e7e7e7',
            color: '#4e4e4e',
          },
          chartAttributes: equation,
          render: (idTicker, id, val) =>
            getCellRender({ val, indicator: equation, locale, timezone }),
        })
      }
    })
  }

  return [rowOneAttrs, rowTwoAttrs]
}

export const getDataGroupByDate = (data, schema, viewBy) => {
  return [...data]
    .sort(
      (a, b) =>
        moment(b[stickyColumnsKey.DATE]).unix() -
        moment(a[stickyColumnsKey.DATE]).unix(),
    )
    .map((item, index) => {
      const obj = { index: index + 1 }
      schema.forEach((col) => {
        obj[col.fieldId] = item[col.fieldId] || item.items[col.fieldId]
      })

      return obj
    })
}

export const getRowSpanSchema = (tableHeaderColAttr) => {
  if (tableHeaderColAttr.length && tableHeaderColAttr[0].length) {
    return tableHeaderColAttr.map((row, rowIndex) => {
      return row
        .filter((item) => !Object.values(stickyColumnsKey).includes(item.id))
        .map((col) => {
          const result = {
            title: col.title,
            colId: col.id,
            colSpan: col.colSpan,
            rowSpan: col.rowSpan,
            isI18n: false,
            isThCustomData: true,
            thStyle: {
              ...col.thStyle,
              fontSize: 10,
              fontWeight: 'bold',
            },
            thClassName: styles.customTableStickyHead,
            rowIndex,
          }

          if (col.colSpan) {
            result.disableSort = true
          }

          return result
        })
    })
  }
}

export const getTableHeadRowTop = (tableHeaderColAttr) => {
  if (tableHeaderColAttr.length && tableHeaderColAttr[0].length) {
    const obj = {}
    tableHeaderColAttr[1].forEach((attr) => (obj[attr.id] = [attr.parentKey]))
    return obj
  }
}

export const getIndicatorsInfoById = (indicators, treeSecuritiesIndicator) => {
  const indicatorsSelected = {}
  Object.values(treeSecuritiesIndicator).forEach((security) =>
    Object.values(security).forEach(
      (indicators) =>
        Array.isArray(indicators) &&
        indicators.forEach(
          (indicator) =>
            (indicatorsSelected[
              `${indicator.indicatorId}_${indicator.indicatorTimeFrequency}`
            ] = {
              id: indicator.indicatorId,
              timeFrequency: indicator.indicatorTimeFrequency,
            }),
        ),
    ),
  )
  return Object.keys(indicatorsSelected).reduce((obj, indicatorKey) => {
    const findIndicator = indicators.find(
      (i) => i.id === +indicatorsSelected[indicatorKey].id,
    )
    return {
      ...obj,
      [indicatorKey]: {
        name: getNameIndicator(
          findIndicator,
          indicatorsSelected[indicatorKey].timeFrequency,
        ),
        unit: findIndicator.unit,
      },
    }
  }, {})
}

export const getEconomyInfoById = (economies, economyIds) => {
  return economyIds.reduce((obj, id) => {
    const detailId = id.split('_')
    const findEconomy = economies.find((i) => i.id === +detailId[0])
    return {
      ...obj,
      [id]: {
        name: findEconomy?.secName
          ? `${findEconomy?.secName} ${MAP_NAME_BY_TIME_FREQUENCY[detailId[1]]}`
          : id,
        unit: findEconomy?.unit,
      },
    }
  }, {})
}
