import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { CHART_TYPE, chartAttr } from '../constants'
import { getColorPieChart } from '../helper'
import { getMajorOwnershipChart, getOverviewOwnershipChart } from './thunk'

const initialState = {
  overviewChartData: [],
  majorOwnershipChartData: [],
  overviewLoading: true,
  majorOwnershipLoading: true,
}

export const slice = createSlice({
  name: 'corporate/ownership/chart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOverviewOwnershipChart.pending, (state) => {
      state.overviewLoading = true
    })
    builder.addCase(getOverviewOwnershipChart.fulfilled, (state, action) => {
      state.overviewLoading = false

      state.overviewChartData = getColorPieChart(
        action.payload || [],
        chartAttr[CHART_TYPE.OVERVIEW].OtherPercentage.value,
        CHART_TYPE.OVERVIEW,
      )
    })
    builder.addCase(getOverviewOwnershipChart.rejected, (state) => {
      state.overviewLoading = true
    })

    builder.addCase(getMajorOwnershipChart.pending, (state) => {
      state.majorOwnershipLoading = true
    })
    builder.addCase(getMajorOwnershipChart.fulfilled, (state, action) => {
      state.majorOwnershipLoading = false

      state.majorOwnershipChartData = getColorPieChart(
        action.payload || [],
        chartAttr[CHART_TYPE.OWNERSHIP].Others.value,
        CHART_TYPE.OWNERSHIP,
      )
    })
    builder.addCase(getMajorOwnershipChart.rejected, (state) => {
      state.majorOwnershipLoading = true
    })
  },
})

export const selectOverviewLoading = (state) =>
  state[slice.name].overviewLoading
export const selectMajorOwnershipLoading = (state) =>
  state[slice.name].majorOwnershipLoading
export const selectOverviewChartData = (state) =>
  state[slice.name].overviewChartData
export const selectMajorOwnershipChartData = (state) =>
  state[slice.name].majorOwnershipChartData

register(slice.name, slice.reducer)
