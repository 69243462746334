import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import PieChartComponent from '../../common/PieChartComponent'
import Title from '../../common/Title'
import {
  selectData,
  selectFilter,
  selectLoading,
  selectRevenueTypes,
} from './store/slice'
import { getCorpMarketShare } from './store/thunk'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectData)
  const loading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)
  const revenueTypes = useSelector(selectRevenueTypes)

  const revenueName =
    revenueTypes.find((item) => item.revenueTypeId === filter.revenueTypeId)
      ?.revenueTypeName || undefined

  useEffect(() => {
    if (filter.revenueTypeId && filter.year) {
      dispatch(
        getCorpMarketShare({
          RevenueTypeId: filter.revenueTypeId,
          Year: filter.year,
        }),
      )
    }
  }, [filter])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title text="MARKET_SHARE" suffix={revenueName} />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <PieChartComponent
                    data={dataChart}
                    height={height - 2 * size.height}
                    datakey="rB1Percentage"
                    currentTicker={basicInfo?.ticker}
                    width={width}
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
