import { valByKeyWithDot } from '../../../../utils/Value'
import { LINE_CHART_COLORS } from '../../constants'
import { NUMBER_OF_PERIOD_FIRST_TIME } from './TableComponent'

export const EMPTY_ROW = ['0-true', '0-false']

const handleDuplicate = (arr) => {
  return arr.filter(
    (item, index, arr) =>
      index ===
      arr.findIndex((i) => item.year === i.year && item.month === i.month),
  )
}

export const getYearMonthNotDuplicate = (data) => {
  const arrYearMonth = data.map((item) => {
    return {
      year: item.year,
      month: item.month,
    }
  })

  return handleDuplicate(arrYearMonth).sort(sortByMonthYear)
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }
  }

  return 0
}

export const defineGroupColumns = (columns, prevColumns, numberOfPeriod) => {
  const DEFAULT_TITLE_LENGTH = 7
  const columnsNumberOfPeriod =
    numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME ? [] : prevColumns
  const groupColumns = columns.map((column) => {
    const title =
      `${column.month}-${column.year}`.length === DEFAULT_TITLE_LENGTH
        ? `${column.month}-${column.year}`
        : 0 + `${column.month}-${column.year}`
    return {
      key: `column${column.month}${column.year}`,
      title,
    }
  })

  return [...groupColumns, ...columnsNumberOfPeriod]
}

export const keyByArray = (array, key, formatKey, prevData, numberOfPeriod) => {
  const result = {}
  array.forEach((item) => {
    const keyObj = valByKeyWithDot(item, key)
    if (!result[keyObj]) {
      result[formatKey ? formatKey(keyObj) : keyObj] = {
        [`column${item.month}${item.year}`]: item?.value,
      }
    } else {
      result[formatKey ? formatKey(keyObj) : keyObj][
        `column${item.month}${item.year}`
      ] = item?.value
    }
  })

  return mergePrevCurrentData(prevData, result, numberOfPeriod)
}

const mergePrevCurrentData = (prev, current, numberOfPeriod) => {
  if (numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })
  return prevCurrentData
}

export const findMinMonthYear = (payload) => {
  if (!payload.length) {
    return { minYear: 0, minMonth: 0 }
  }

  const minYear = Math.min.apply(
    Math,
    payload.map((item) => item.year),
  )
  const monthWithMinYear = payload
    .filter((item) => item.year === minYear)
    .map((item) => item.month)

  return {
    minYear,
    minMonth: Math.min.apply(Math, monthWithMinYear),
  }
}

export const defineDropDownYoY = (dataTypeName) => {
  const dataTypeNameWithId = handleIds(dataTypeName)

  return dataTypeNameWithId
    .filter((item) => !EMPTY_ROW.includes(item.id))
    .map((item) => ({
      name: item.sbvTotalLiquidityVNTypeName,
      value: item.id,
      customStyle: handleItemDropdownStyle(item.sbvTotalLiquidityVNTypeLevel),
    }))
}

export const handleIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => {
    return {
      ...item,
      id: `${item.sbvTotalLiquidityVNTypeId}-${item.isMoneySupply}`,
    }
  })
}

const handleItemDropdownStyle = (level) => {
  switch (level) {
    case 2:
      return { paddingLeft: 22 }
    case 3:
      return { paddingLeft: 56 }
    default:
      return { paddingLeft: 8 }
  }
}

export const initialCheckboxChecked = (payload, indexChecked) => {
  if (!payload.length) {
    return {}
  }
  const initChecked = {}
  indexChecked.forEach((index) => {
    const { sbvTotalLiquidityVNTypeId, isMoneySupply, id } = payload[index]
    initChecked[id] = {
      id: sbvTotalLiquidityVNTypeId,
      isMoneySupply,
    }
  })

  return initChecked
}

export const initLineColor = (payload, indexChecked) => {
  if (!payload.length) {
    return []
  }

  let init = []
  indexChecked.forEach((index) => {
    init.push(payload[index])
  })

  return LINE_CHART_COLORS.map((color, index) => {
    return {
      color,
      author: init[index]?.id,
      indicatorName: init[index]?.sbvTotalLiquidityVNTypeName,
    }
  })
}

export const handleLineColor = (
  id,
  lineChartColor,
  isChecked,
  indicatorName,
) => {
  if (isChecked) {
    return lineChartColor.map((color) => {
      if (color.author === id) {
        return {
          ...color,
          author: undefined,
        }
      }
      return color
    })
  } else {
    let count = 0
    return lineChartColor.map((color) => {
      if (count === 0 && color.author === undefined) {
        count++
        return {
          ...color,
          author: id,
          indicatorName,
        }
      }
      return color
    })
  }
}
