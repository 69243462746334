import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { ZOOM_STORAGE_KEY } from './constants'

const slice = createSlice({
  name: 'layout/header/fontSize',
  initialState: {
    zoom: +localStorage.getItem(ZOOM_STORAGE_KEY) || 1,
  },
  reducers: {
    changeZoom: (state, { payload = 1 }) => {
      state.zoom = payload
    },
  },
})

register(slice.name, slice.reducer)

export const { changeZoom } = slice.actions
export const selectZoom = (state) => state[slice.name].zoom
