import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { SizeTrackerContainer } from '../../../common/sizeTracker/SizeTrackerContainer'
import { selectBasicInfoOverview } from '../store/slice'
import EnterpriseInformation from './EnterpriseInformation'
import { selectLoading, selectSummaryInfo } from './store/slice'
import { getSummaryInfo } from './store/thunk'
import TickerInformation from './TickerInformation'

const Summary = () => {
  const dispatch = useDispatch()

  const summaryInfo = useSelector(selectSummaryInfo)
  const isLoading = useSelector(selectLoading)
  const basicInfo = useSelector(selectBasicInfoOverview)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getSummaryInfo({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId, locale])

  if (isLoading) {
    return <Loading />
  }

  if (!Object.keys(summaryInfo).length && basicInfo?.organizationId) {
    return <NoData />
  }

  if (Object.keys(summaryInfo).length) {
    return (
      <SizeTrackerContainer>
        <SizeTracker>
          {(size) => {
            return (
              <>
                {size.height && (
                  <ScrollComponent
                    appendStyle={{ height: `calc(100% - ${size.height}px)` }}
                    verticalTrackWidth={5}
                    horizontalTrackWidth={5}
                  >
                    <EnterpriseInformation profile={summaryInfo.profile} />
                  </ScrollComponent>
                )}

                <TickerInformation summaryInfo={summaryInfo} />
              </>
            )
          }}
        </SizeTracker>
      </SizeTrackerContainer>
    )
  }

  return <></>
}

export default Summary
