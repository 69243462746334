import { LINE_CHART_COLORS } from '../../constants'
import { MAX_ITEM, NUMBER_OF_PERIOD_FIRST_TIME } from './config'

export const defineGroupColumns = (payload, prevColumns, numberOfPeriod) => {
  const columnsNumberOfPeriod =
    numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME ? [] : prevColumns

  const groupColumns = handleDuplicate(payload).map((item) => {
    return {
      key: `${item.year}`,
      title: `${item.year}`,
    }
  })

  return [...groupColumns, ...columnsNumberOfPeriod]
}

export const keyBy = (payload, prevData, numberOfPeriod) => {
  const result = {}
  payload.forEach((item) => {
    const objectKey = item.nationalAccountVNTypeId
    const columnKey = `${item.year}`
    const value = item.value / 1e12

    if (!result[objectKey]) {
      result[objectKey] = { [columnKey]: value }
    } else {
      result[objectKey][columnKey] = value
    }
  })

  return mergePrevCurrentData(prevData, result, numberOfPeriod)
}

const mergePrevCurrentData = (prev, current, numberOfPeriod) => {
  if (numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })
  return prevCurrentData
}

const handleDuplicate = (arr) => {
  return arr.filter(
    (item, index, arr) =>
      index ===
      arr.findIndex(
        (i) => item.year === i.year && item.dataStatusName === i.dataStatusName,
      ),
  )
}

export const findMinYear = (payload) => {
  if (!payload.length) {
    return 0
  }

  return Math.min.apply(
    Math,
    payload.map((item) => item.year),
  )
}

export const getIdByLevel = (
  dataTypeName,
  indexChecked,
  isGetIndicatorName = false,
) => {
  if (dataTypeName.length < MAX_ITEM) {
    return []
  }

  const defaultChecked = indexChecked.map((index) => dataTypeName[index])

  if (isGetIndicatorName) {
    return defaultChecked.map((item) => ({
      id: item?.nationalAccountVNTypeId,
      indicatorName: item?.nationalAccountVNTypeName,
    }))
  }
  return defaultChecked.map((item) => item?.nationalAccountVNTypeId)
}

export const initLineColor = (checkboxChecked) => {
  return LINE_CHART_COLORS.map((color, index) => {
    return {
      color,
      author: checkboxChecked[index]?.id,
      indicatorName: checkboxChecked[index]?.indicatorName,
    }
  })
}

export const handleLineColor = (id, lineChartColor, isChecked) => {
  if (isChecked) {
    return lineChartColor.map((color) => {
      if (color.author === id) {
        return {
          ...color,
          author: undefined,
        }
      }
      return color
    })
  } else {
    let count = 0
    return lineChartColor.map((color) => {
      if (count === 0 && color.author === undefined) {
        count++
        return {
          ...color,
          author: id,
        }
      }
      return color
    })
  }
}
