import { COM_GROUP } from '../components/constants/Group'

export const IS_PUBLIC_SERVICE = false

export const DEFAULT_ORGANIZATION = {
  companyName: 'An Phát Bioplastics',
  exchange: 'HOSE',
  exchangeCode: 'HOSE',
  icbId: 12,
  groupId: null,
  organizationId: 2734,
  ticker: 'AAA',
  companyName_EN: 'An Phat Bioplastics',
  organizationName: 'An Phat Bioplastics Joint Stock Company',
  organizationShortName: 'An Phat Bioplastics',
  sector: 'Chemicals',
  taxCode: '0800373586',
  indexGroupId: 11,
}

export const DEFAULT_ORGANIZATION_ID = {
  [COM_GROUP.CORPORATE]: 2734, //AAA
  [COM_GROUP.BANK]: 4143, // VCB
  [COM_GROUP.SECURITIES]: 3908, // SSI
  [COM_GROUP.INSURANCE]: 3702, //PVI
}

export const DEFAULT_ROUTE = {
  route: '/corporate/overview',
}
