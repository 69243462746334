import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellTornado } from '../../../../common/table/CellTornado'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_FUND_FLOW_KEYS,
  TABLE_FUND_FLOW_TH_ROW_KEYS,
} from '../constants'

export const fundFlowSchema = ({ maxMinByColId, locale }) => {
  return TABLE_FUND_FLOW_KEYS.map((item) => {
    if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
      return {
        ...item,
        isI18n: false,
        tdStyle: {
          textAlign: 'right',
        },
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    return {
      ...item,
      isI18n: false,
      tdStyle: {
        textAlign: 'center',
      },
      canCustomTd: true,
      render: (val, _, { style, className }) => (
        <td
          style={{
            ...style,
            minWidth: 200,
          }}
          className={className}
        >
          <CellTornado
            value={val}
            colId={item.colId}
            transformValue={(val) =>
              val / TABLE_COL_FORMATS.COL_TOTAL_FUND_FLOW_MOST_RECENT
            }
            maxMinByColId={maxMinByColId}
            isPercent={false}
          />
        </td>
      ),
    }
  })
}

export const fundFlowTableThSchema = () => {
  return TABLE_FUND_FLOW_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      let result = {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
        },
      }

      if (col.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
        return {
          ...result,
          rowSpan: 2,
        }
      }

      result = {
        ...result,
        thStyle: {
          textAlign: 'center',
          fontSize: 12,
          fontWeight: 600,
        },
      }

      if (col.colId === TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW) {
        return {
          ...result,
          colSpan: 4,
          renderTh: () => (
            <div style={{ textAlign: 'center' }}>
              <Span className="mr-4">
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.BILLION_VND" />)
              </Span>
            </div>
          ),
        }
      }

      return result
    }),
  )
}

export const fundFlowThCellTopById = {
  [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_MOST_RECENT]: [
    TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW,
  ],
  [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_3M]: [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW],
  [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_YTD]: [
    TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW,
  ],
  [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_1Y]: [TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW],
}
