import { isEqual } from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import useGetICB from '../../../common/masterData/useGetICB'
import PopupAlert from '../../../common/popup/PopupAlert'
import SelectCustom from '../../../common/selectCustom'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { formatVal } from '../../../utils/Value'
import { ISSUE_METHOD, MARKET_STATISTIC_TABS, PRICE_OPTIONS } from './constants'
import { FilterBond } from './filter/FilterBond'
import FilterSector from './filter/FilterSector'
import {
  END_TIME_HOUR,
  END_TIME_MINUTE,
  START_TIME_HOUR,
  START_TIME_MINUTE,
} from './helper'
import style from './index.module.css'
import {
  changeActiveTab,
  changeBookmarkStatus,
  changeFilter,
  resetFilter,
  resetSearchBondList,
  selectActiveTab,
  selectBookmarkStatus,
  selectFilterPriceBoard,
  selectIsExchangePrice,
  selectLoadingIcbIds,
  selectSearchFilter,
} from './store/slice'
import { getPriceBoardData, getPriceBoardWithBookmarkData } from './store/thunk'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/
const regexPositiveValue = /^-?\d*\.{0,1}\d+$/
const regexPositiveValue2 = /^\d*\.?\d+$/

const Filter = () => {
  const dispatch = useDispatch()

  const activeTab = useSelector(selectActiveTab)
  const filter = useSelector(selectFilterPriceBoard)
  const {
    durationFrom,
    durationTo,
    price,
    tradingStatusId,
    issueMethodId,
    bondId,
    isCleanPrice,
    icbIds,
    isTrading,
    interestFrom,
    interestTo,
    ...restFilter
  } = filter

  const { initialData } = useSelector(selectSearchFilter)

  const locale = useSelector((state) => state.i18n.locale)
  const { ICBs } = useGetICB()
  const listSector = ICBs.filter((item) => item.icbLevel === 2).map((item) => ({
    icbNameCustom: item.icbName + ' L2',
    ...item,
  }))

  const loadingIcbIds = useSelector(selectLoadingIcbIds)
  const isExchangePrice = useSelector(selectIsExchangePrice)
  const bookmarkStatus = useSelector(selectBookmarkStatus)

  const timeZone = UseTimeZone()

  const [isShowAlert, setIsShowAlert] = useState('')
  const [typeInput, setTypeInput] = useState(1)
  const [isReset, setIsReset] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const durationFromRef = useRef()
  const durationToRef = useRef()
  const interestFromRef = useRef()
  const interestToRef = useRef()

  const handleChangeActiveTab = (value) => {
    dispatch(changeActiveTab(value))
  }

  const handleChangeFilter = ({ key, value }) => {
    dispatch(changeFilter({ key, value }))
  }

  // const handleChangeTradingStatus = (check, value) => {
  //   handleChangeFilter({
  //     key: 'tradingStatusId',
  //     value: check
  //       ? [...tradingStatusId, value]
  //       : tradingStatusId.filter((item) => item !== value),
  //   })
  // }

  const handleChangeIssueMethod = (check, value) => {
    handleChangeFilter({
      key: 'issueMethodId',
      value: check
        ? [...issueMethodId, value]
        : issueMethodId.filter((item) => item !== value),
    })
  }

  const handleResetFilter = () => {
    setIsReset(true)
    dispatch(resetFilter(listSector.map((item) => item.icbId)))
    setKeySearch('')
  }

  const handleClosePopup = () => {
    switch (typeInput) {
      case 1:
        durationFromRef?.current?.focus()
        break
      case 2:
        durationToRef?.current?.focus()
        break
      case 3:
        interestFromRef?.current?.focus()
        break
      case 4:
        interestToRef?.current?.focus()
        break
      default:
        break
    }

    setIsShowAlert('')
  }

  const getKey = (type) => {
    switch (type) {
      case 1:
        return 'durationFrom'
      case 2:
        return 'durationTo'
      case 3:
        return 'interestFrom'
      case 4:
        return 'interestTo'
      default:
        break
    }
  }

  const getValue = (type) => {
    switch (type) {
      case 1:
        return durationFrom
      case 2:
        return durationTo
      case 3:
        return interestFrom
      case 4:
        return interestTo
      default:
        break
    }
  }

  const handleBlurInput = (type) => {
    const check =
      type === 1 || type === 2
        ? validate(durationFrom.replace(/,/g, ''), durationTo.replace(/,/g, ''))
        : validate(interestFrom.replace(/,/g, ''), interestTo.replace(/,/g, ''))
    const value = getValue(type)

    handleChangeFilter({
      key: getKey(type),
      value: !isNaN(value)
        ? type === 1
          ? formatVal(value, 10, false, '', 0)
          : formatVal(value, 2, false, '', 0)
        : '',
    })

    if (check) {
      setIsShowAlert('bond.corporateBond.priceBoard.RANGE_YTM')
      setTypeInput(type)
      return
    }
    handleSearch()
  }

  const handleFocusDurationFrom = () => {
    handleChangeFilter({
      key: 'durationFrom',
      value: durationFrom.replace(/,/g, ''),
    })
  }

  const handleFocusDurationTo = () => {
    handleChangeFilter({
      key: 'durationTo',
      value: durationTo.replace(/,/g, ''),
    })
  }

  const handleFocusInterestFrom = () => {
    handleChangeFilter({
      key: 'interestFrom',
      value: interestFrom.replace(/,/g, ''),
    })
  }

  const handleFocusInterestTo = () => {
    handleChangeFilter({
      key: 'interestTo',
      value: interestTo.replace(/,/g, ''),
    })
  }

  const handleSearch = () => {
    const payload = {
      ...restFilter,
      bondIds:
        isEqual(
          initialData.map((item) => item.bondId).filter((item) => item),
          bondId,
        ) && !keySearch.length
          ? null
          : bondId,
      price,
      tradingStatusId,
      issueMethodId,
      icbIds: icbIds.length === listSector.length ? [] : icbIds,
      isTrading,
      isCleanPrice,
      interestFrom:
        !isNaN(interestFrom) && !!interestFrom?.length
          ? parseFloat(
              parseFloat(interestFrom.replace(/,/g, '') / 100).toFixed(4),
            )
          : null,
      interestTo:
        !isNaN(interestTo) && !!interestTo?.length
          ? parseFloat(
              parseFloat(interestTo.replace(/,/g, '') / 100).toFixed(4),
            )
          : null,
      durationFrom: !isNaN(durationFrom)
        ? parseFloat(durationFrom.replace(/,/g, ''))
        : null,
      durationTo: !isNaN(durationTo)
        ? parseFloat(durationTo.replace(/,/g, ''))
        : null,
      page: 1,
      type: activeTab,
    }

    dispatch(getPriceBoardData(payload))
  }

  const validate = (from, to) => {
    if (!isNaN(from) && !isNaN(to) && parseFloat(from) > parseFloat(to)) {
      return true
    }

    return false
  }

  useEffect(() => {
    if (price && !loadingIcbIds && activeTab && !isShowDropdown) {
      handleSearch()
    }
  }, [
    locale,
    bondId,
    price,
    tradingStatusId,
    issueMethodId,
    icbIds,
    isTrading,
    loadingIcbIds,
    activeTab,
    isCleanPrice,
    isExchangePrice,
    keySearch,
    isShowDropdown,
    initialData,
  ])

  useEffect(() => {
    if (isReset) {
      handleSearch()
      setIsReset(false)
    }
  }, [isReset])

  useEffect(() => {
    dispatch(resetSearchBondList())
  }, [activeTab])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: +START_TIME_HOUR ?? 8,
      minute: +START_TIME_MINUTE ?? 50,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        handleSearch()
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: +END_TIME_HOUR ?? 15,
      minute: +END_TIME_MINUTE ?? 30,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        handleSearch()
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    if (bookmarkStatus) {
      const payload = {
        ...restFilter,
        bondIds:
          isEqual(
            initialData.map((item) => item.bondId).filter((item) => item),
            bondId,
          ) && !keySearch.length
            ? null
            : bondId,
        price,
        tradingStatusId,
        issueMethodId,
        icbIds: icbIds.length === listSector.length ? [] : icbIds,
        isTrading,
        isCleanPrice,
        interestFrom: !isNaN(interestFrom) ? parseFloat(interestFrom) : null,
        interestTo: !isNaN(interestTo) ? parseFloat(interestTo) : null,
        durationFrom: !isNaN(durationFrom)
          ? parseFloat(durationFrom.replace(/,/g, ''))
          : null,
        durationTo: !isNaN(durationTo)
          ? parseFloat(durationTo.replace(/,/g, ''))
          : null,
        page: 1,
        pageSize: restFilter.page * 100,
        type: activeTab,
      }

      dispatch(getPriceBoardWithBookmarkData(payload))
      dispatch(changeBookmarkStatus(false))
    }
  }, [bookmarkStatus])

  return (
    <div>
      <div className="d-flex ali-center mb-8" style={{ gap: 16 }}>
        {MARKET_STATISTIC_TABS.map((item) => (
          <Span
            key={item.title}
            className={`${style.tab} ${
              item.value === activeTab && style.tabActive
            }`}
            onClick={() => handleChangeActiveTab(item.value)}
            style={{ fontSize: 16 }}
          >
            <Translate value={item.title} />
          </Span>
        ))}
      </div>
      <div
        className="d-flex ali-center justify-content-space-between mb-8"
        style={{ flexWrap: 'wrap', gap: 8 }}
      >
        <div className="d-flex ali-center" style={{ flexWrap: 'wrap', gap: 8 }}>
          <div style={{ width: 210 }}>
            <FilterBond
              keySearch={keySearch}
              setKeySearch={setKeySearch}
              isShowDropdown={isShowDropdown}
              setIsShowDropdown={setIsShowDropdown}
            />
          </div>
          <div style={{ width: 150 }}>
            <SelectCustom
              value={1}
              selectData={[
                {
                  name: I18n.t('bond.corporateBond.priceBoard.MY_WATCHLIST'),
                  value: 1,
                },
              ]}
              handleChange={handleChangeFilter}
              appendStyle={{ fontWeight: 340 }}
              isDisable
            />
          </div>
          <FilterSector />
          {/* <VerticalLine mr={0} ml={12} h={15} /> */}
          {/* <div className="d-flex ali-center" style={{ gap: 12 }}>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={
                  tradingStatusId.includes(TRADING_STATUS.LISTED) ?? false
                }
                onChange={(e) =>
                  handleChangeTradingStatus(
                    e?.target?.checked ?? false,
                    TRADING_STATUS.LISTED,
                  )
                }
              />
              <Translate
                value="bond.corporateBond.priceBoard.LISTED"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={tradingStatusId.includes(TRADING_STATUS.OTC) ?? false}
                onChange={(e) =>
                  handleChangeTradingStatus(
                    e?.target?.checked ?? false,
                    TRADING_STATUS.OTC,
                  )
                }
              />
              <Translate
                value="OTC"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
          </div> */}
          <VerticalLine mr={0} ml={0} h={15} />
          <div className="d-flex ali-center" style={{ gap: 12 }}>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={issueMethodId.includes(ISSUE_METHOD.PUBLIC) ?? false}
                onChange={(e) =>
                  handleChangeIssueMethod(
                    e?.target?.checked ?? false,
                    ISSUE_METHOD.PUBLIC,
                  )
                }
              />
              <Translate
                value="bond.corporateBond.priceBoard.PUBLIC"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={issueMethodId.includes(ISSUE_METHOD.PRIVATE) ?? false}
                onChange={(e) =>
                  handleChangeIssueMethod(
                    e?.target?.checked ?? false,
                    ISSUE_METHOD.PRIVATE,
                  )
                }
              />
              <Translate
                value="bond.corporateBond.priceBoard.PRIVATE"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
          </div>
          <VerticalLine mr={0} ml={0} h={15} />
          <label className="d-flex ali-center" style={{ gap: 8 }}>
            <input
              type="checkbox"
              className="checkbox"
              checked={isTrading}
              onChange={(e) =>
                handleChangeFilter({
                  key: 'isTrading',
                  value: e?.target?.checked ?? false,
                })
              }
            />
            <Translate
              value="bond.corporateBond.priceBoard.BOND_WITH_TRADING"
              style={{ flexShrink: 0, fontWeight: 340 }}
            />
          </label>
          <VerticalLine mr={0} ml={0} h={15} />
          <div className="d-flex ali-center" style={{ gap: 8 }}>
            <Span style={{ fontWeight: 340 }}>
              <Translate value="bond.corporateBond.priceBoard.DURATION_TEXT" />
            </Span>
            <div
              className={`d-flex ali-center ${style.inputWrapper}`}
              style={{ gap: 4 }}
            >
              <div style={{ width: 60 }}>
                <Input
                  onChange={(e) => {
                    if (
                      e?.target?.value.match(regexPositiveValue) ||
                      !e?.target?.value?.length
                    ) {
                      handleChangeFilter({
                        key: 'durationFrom',
                        value: e?.target?.value,
                      })
                    }
                  }}
                  onFocus={handleFocusDurationFrom}
                  onBlur={() => handleBlurInput(1)}
                  type="text"
                  className={style.input}
                  value={durationFrom}
                  ref={durationFromRef}
                />
              </div>
            </div>
            <Span style={{ color: '#50545F' }}>-</Span>
            <div
              className={`d-flex ali-center ${style.inputWrapper}`}
              style={{ gap: 4 }}
            >
              <div style={{ width: 60 }}>
                <Input
                  onChange={(e) => {
                    if (
                      e?.target?.value.match(regexPositiveValue2) ||
                      !e?.target?.value?.length
                    ) {
                      handleChangeFilter({
                        key: 'durationTo',
                        value: e?.target?.value,
                      })
                    }
                  }}
                  onFocus={handleFocusDurationTo}
                  onBlur={() => handleBlurInput(2)}
                  type="text"
                  className={style.input}
                  value={durationTo}
                  ref={durationToRef}
                />
              </div>
            </div>
          </div>
          <VerticalLine mr={0} ml={0} h={15} />
          <div style={{ width: 150 }}>
            <SelectCustom
              value={price}
              selectData={PRICE_OPTIONS}
              handleChange={(item) =>
                handleChangeFilter({
                  key: 'price',
                  value: item,
                })
              }
              appendStyle={{ fontWeight: 340 }}
              isI18n
            />
          </div>
          <VerticalLine mr={0} ml={0} h={15} />
          <div className="d-flex ali-center" style={{ gap: 8 }}>
            <Span style={{ fontWeight: 340 }}>
              <Translate value="YTM (%)" />
            </Span>
            <div
              className={`d-flex ali-center ${style.inputWrapper}`}
              style={{ gap: 4 }}
            >
              <div style={{ width: 60 }}>
                <Input
                  onChange={(e) => {
                    if (
                      e?.target?.value.match(regexValue) ||
                      !e?.target?.value?.length
                    ) {
                      handleChangeFilter({
                        key: 'interestFrom',
                        value: e?.target?.value,
                      })
                    }
                  }}
                  onFocus={handleFocusInterestFrom}
                  onBlur={() => handleBlurInput(3)}
                  type="text"
                  className={style.input}
                  value={interestFrom}
                  ref={interestFromRef}
                />
              </div>
            </div>
            <Span style={{ color: '#50545F' }}>-</Span>
            <div
              className={`d-flex ali-center ${style.inputWrapper}`}
              style={{ gap: 4 }}
            >
              <div style={{ width: 60 }}>
                <Input
                  onChange={(e) => {
                    if (
                      e?.target?.value.match(regexValue) ||
                      !e?.target?.value?.length
                    ) {
                      handleChangeFilter({
                        key: 'interestTo',
                        value: e?.target?.value,
                      })
                    }
                  }}
                  onFocus={handleFocusInterestTo}
                  onBlur={() => handleBlurInput(4)}
                  type="text"
                  className={style.input}
                  value={interestTo}
                  ref={interestToRef}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="d-flex ali-center"
          style={{
            gap: 8,
            flexShrink: 0,
            alignSelf: 'flex-end',
          }}
        >
          <div className="w-80">
            <Button
              className={`btn btn-blue h-20 ${style.buttonReset}`}
              onClick={handleResetFilter}
            >
              <Translate value="bond.corporateBond.priceBoard.RESET" />
            </Button>
          </div>
          <div style={{ minWidth: 125 }}>
            <Button
              className={`btn h-20 ${style.buttonSave}`}
              onClick={() => setIsShowAlert('common.FUNCTION_COMING_SOON')}
            >
              <Translate value="bond.corporateBond.priceBoard.SAVE" />
            </Button>
          </div>
        </div>
      </div>
      <PopupAlert
        message={<Translate value={isShowAlert} />}
        isShow={!!isShowAlert.length}
        handleClose={handleClosePopup}
      />
    </div>
  )
}

export default Filter
