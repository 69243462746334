import { useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown, { positionDropdown } from '../../../../common/dropdown'
import { Icon } from '../../../../common/html/Icon'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { changePortfolioList } from '../../store/slice'
import style from './index.module.css'

const Item = ({
  handleClose,
  handleDelete,
  handleEdit,
  handleClick,
  item,
  isShow,
  setIsShow,
  isEdit,
  setIsEdit,
}) => {
  const inputRef = useRef()
  const divRef = useRef()

  const [name, setName] = useState('')

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleEdit(name, item?.portfolioId)
      setIsEdit(null)
    }
  }

  useEffect(() => {
    if (isEdit) {
      inputRef?.current?.focus()
    }
  }, [isEdit])

  return (
    <div
      style={{
        gap: 8,
        height: 24,
        boxSizing: 'border-box',
      }}
      onClick={() => {
        handleClick(item.portfolioId)
      }}
      className={`d-flex ali-center ${style.itemMore}`}
      ref={divRef}
    >
      <div
        style={{ width: 16, height: 16, paddingLeft: 4 }}
        className="d-flex ali-center justify-content-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="12"
          viewBox="0 0 8 12"
          fill="none"
        >
          <path
            d="M3.325 10.05C3.325 10.7925 2.7175 11.4 1.975 11.4C1.2325 11.4 0.625 10.7925 0.625 10.05C0.625 9.30748 1.2325 8.69998 1.975 8.69998C2.7175 8.69998 3.325 9.30748 3.325 10.05ZM1.975 4.64998C1.2325 4.64998 0.625 5.25748 0.625 5.99998C0.625 6.74248 1.2325 7.34998 1.975 7.34998C2.7175 7.34998 3.325 6.74248 3.325 5.99998C3.325 5.25748 2.7175 4.64998 1.975 4.64998ZM1.975 0.599976C1.2325 0.599976 0.625 1.20748 0.625 1.94998C0.625 2.69248 1.2325 3.29998 1.975 3.29998C2.7175 3.29998 3.325 2.69248 3.325 1.94998C3.325 1.20748 2.7175 0.599976 1.975 0.599976ZM6.025 3.29998C6.7675 3.29998 7.375 2.69248 7.375 1.94998C7.375 1.20748 6.7675 0.599976 6.025 0.599976C5.2825 0.599976 4.675 1.20748 4.675 1.94998C4.675 2.69248 5.2825 3.29998 6.025 3.29998ZM6.025 4.64998C5.2825 4.64998 4.675 5.25748 4.675 5.99998C4.675 6.74248 5.2825 7.34998 6.025 7.34998C6.7675 7.34998 7.375 6.74248 7.375 5.99998C7.375 5.25748 6.7675 4.64998 6.025 4.64998ZM6.025 8.69998C5.2825 8.69998 4.675 9.30748 4.675 10.05C4.675 10.7925 5.2825 11.4 6.025 11.4C6.7675 11.4 7.375 10.7925 7.375 10.05C7.375 9.30748 6.7675 8.69998 6.025 8.69998Z"
            fill="#63696F"
          />
        </svg>
      </div>
      {isEdit === item?.portfolioId ? (
        <>
          <div style={{ flex: 1, overflow: 'hidden', gap: 8 }}>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Input
                value={name}
                onChange={(e) => setName(e?.target?.value)}
                style={{
                  color: '#ECECEC',
                  background: '#2C313E',
                  borderRadius: 4,
                  fontWeight: 340,
                  width: '100%',
                }}
                onKeyDown={handleEnterKey}
                maxLength={50}
                ref={inputRef}
                onClick={(e) => e.stopPropagation()}
              />
              <i
                className={`icon-true cursor-pointer cursor-pointer ${style.iconMore}`}
                style={{ fontSize: 12 }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleEdit(name, item?.portfolioId)
                  setIsEdit(null)
                }}
              />
            </div>
          </div>
          <div className="d-flex ali-center h-100" style={{ paddingRight: 4 }}>
            <i
              className={`icon-delete-stroke cursor-pointer ${style.iconMore}`}
              onClick={(e) => {
                e.stopPropagation()
                setIsEdit(null)
              }}
            />
          </div>
        </>
      ) : (
        <>
          <div style={{ flex: 1, overflow: 'hidden' }}>
            <TextEllipsis
              text={item?.portfolioName ?? ''}
              zIndexTooltip={999}
              isI18n={false}
            />
          </div>
          <div className="d-flex ali-center h-100" style={{ paddingRight: 4 }}>
            <i
              className={`icon-menu-dot-hor cursor-pointer ${style.iconMore}`}
              onClick={(e) => {
                e.stopPropagation()
                setIsShow(isShow ? null : item?.portfolioId)
              }}
            />
          </div>
        </>
      )}
      <Dropdown
        className="dropdown-portfolio"
        position={positionDropdown.RIGHT_TOP}
        appendStyle={{
          background: '#3c424b',
          width: 130,
          borderRadius: '0 4px 4px 0',
        }}
        isShow={isShow === item?.portfolioId}
        parentRef={divRef}
      >
        <div
          onMouseEnter={() => {
            divRef?.current?.classList.add(style.itemHover)
          }}
          onMouseLeave={() => {
            divRef?.current?.classList.remove(style.itemHover)
          }}
        >
          <div
            className={`${style.dropdownItem} `}
            onClick={(e) => {
              e.stopPropagation()
              setIsEdit(item.portfolioId)
              setName(item?.portfolioName ?? '')
              setIsShow(null)
            }}
          >
            <Icon
              className="icon-edit"
              style={{ color: '#AEBCD2', fontSize: 10 }}
            />
            <Translate value="common.button.BUTTON_EDIT_NAME" />
          </div>
          <div
            className={`${style.dropdownItem}`}
            onClick={(e) => {
              e.stopPropagation()
              setIsShow(null)
              handleDelete(item)
              handleClose()
            }}
          >
            <Icon
              className="icon-trash"
              style={{ color: '#AEBCD2', fontSize: 10 }}
            />
            <Translate value="common.button.BUTTON_DELETE" />
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

const FilterPortfolio = ({
  handleDelete,
  handleEdit,
  handleClick,
  data,
  value,
  placeholder,
  styleContainer,
}) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const [list, setList] = useState([])
  const [isShow, setIsShow] = useState(null)
  const [isEdit, setIsEdit] = useState(null)
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const name = useMemo(() => {
    const item = data.find((e) => e.portfolioId === value)

    return item ? item?.portfolioName : ''
  }, [data, value])

  const getListStyle = () => ({
    background: 'transparent',
    padding: 0,
  })

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    cursor: 'default',

    // change background colour if dragging
    background: isDragging ? 'rgb(83, 95, 111)' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(list, result.source.index, result.destination.index)

    setList(items)
  }

  const isDropdownTop =
    window.innerHeight - dropdownRef.current?.getBoundingClientRect()?.bottom <
    120

  useEffect(() => {
    if (isShowDropdown) {
      setIsShow(null)
    }
  }, [isShowDropdown])

  useEffect(() => {
    if (isShowDropdown) {
      setList(data.filter((item) => !isNaN(item.portfolioId)))
    }
  }, [isShowDropdown, data])

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      <div
        onClick={() => setIsShowDropdown(!isShowDropdown)}
        style={styleContainer}
      >
        <span className={`${style.select} ${isShowDropdown ? style.show : ''}`}>
          <span className={`${style.text}`}>
            <Span style={{ fontWeight: 340 }}>
              {!value ? (
                <TextEllipsis text={placeholder} />
              ) : (
                <TextEllipsis text={name} isI18n={false} />
              )}
            </Span>
          </span>
          <i
            className={`${
              !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
            } ${style.icon}`}
          />
        </span>
      </div>
      <Dropdown
        position={
          isDropdownTop
            ? positionDropdown.TOP_LEFT_RIGHT
            : positionDropdown.BOTTOM_LEFT_RIGHT
        }
        appendStyle={{
          borderRadius: '0 0 4px 4px',
          background: '#3a3f49',
          width: `${dropdownRef.current?.getBoundingClientRect()?.width}px`,
          zIndex: 9,
          cursor: 'default',
        }}
        isShow={isShowDropdown}
        parentRef={dropdownRef}
      >
        <HandleClickOutside
          handleClickOutside={(elementClicked) => {
            const dropdownChild =
              document.getElementsByClassName('dropdown-portfolio')

            if (
              dropdownChild?.[0] &&
              dropdownChild?.[0]?.contains(elementClicked)
            ) {
              return
            }

            setIsEdit(null)
            setIsShowDropdown(false)
            dispatch(changePortfolioList(list))
          }}
          exceptRef={dropdownRef}
        >
          <div>
            <ScrollComponent autoHeight={true} autoHeightMax={120}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {list.map((item, index) => (
                        <Draggable
                          key={`item2-${item?.portfolioId}`}
                          draggableId={`item2-${item?.portfolioId}`}
                          index={index}
                          isDragDisabled={isEdit}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Item
                                handleClick={handleClick}
                                handleClose={() => setIsShowDropdown(false)}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                item={item}
                                isShow={isShow}
                                setIsShow={setIsShow}
                                isEdit={isEdit}
                                setIsEdit={setIsEdit}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </ScrollComponent>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default FilterPortfolio
