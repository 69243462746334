import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import CalendarSearch from './CalendarSearch'
import { listTimeRange } from './constants'
import style from './style.module.css'

const TimeRange = ({
  timeRange,
  changeTimeRange,
  timeRangeDate,
  changeTimeRangeDate,
}) => {
  const [timeRangeState, setTimeRangeState] = useState(timeRange)
  const [timeRangeDateState, setTimeRangeDateState] = useState(timeRangeDate)

  const handleChangeTimeRange = (value) => {
    setTimeRangeState(value)
    setTimeRangeDateState({
      dateTo: '',
      dateFrom: '',
    })
  }

  useEffect(() => {
    changeTimeRange(timeRangeState)
  }, [timeRangeState])

  useEffect(() => {
    changeTimeRangeDate(timeRangeDateState)
  }, [timeRangeDateState])

  useEffect(() => {
    setTimeRangeState(timeRange)
  }, [timeRange])

  useEffect(() => {
    setTimeRangeDateState(timeRangeDate)
  }, [timeRangeDate])

  return (
    <div className="align-center">
      <CalendarSearch
        timeRangeState={timeRangeState}
        timeRangeDateState={timeRangeDateState}
        setTimeRangeState={setTimeRangeState}
        setTimeRangeDateState={setTimeRangeDateState}
      />
      <nav className={`tab-in-panel ${style.tabInPanel}`}>
        {listTimeRange.map(({ name, value }, index) => (
          <a
            key={index}
            className={[
              style.item,
              timeRangeState === value ? style.btnActive : '',
            ].join(' ')}
            style={{ padding: '0 10px' }}
            onClick={() => handleChangeTimeRange(value)}
          >
            <Span
              style={{
                fontSize: 10,
              }}
            >
              <Translate value={name} />
            </Span>
          </a>
        ))}
      </nav>
    </div>
  )
}

export default TimeRange

TimeRange.propTypes = {
  timeRange: PropTypes.string,
  changeTimeRange: PropTypes.func,
  timeRangeDate: PropTypes.object,
  changeTimeRangeDate: PropTypes.func,
}

TimeRange.defaultProps = {
  changeTimeRange: () => {},
  timeRangeDate: {},
  changeTimeRangeDate: () => {},
}
