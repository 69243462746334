import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import OpenMarket from './panelOpenMarket'
import OpenMarketAuction from './panelOpenMarketAuction'
import OpenMarketOperations from './panelOpenMarketOperations'
import OpenMarketOperationsBottom from './panelOpenMarketOperations/chartBottom'
import OpenMarketOperationsTop from './panelOpenMarketOperations/chartTop'
import ShareOutstanding from './panelShareOutstanding'

const HEADER_PANEL_HEIGHT = 25
const PADDING_PANEL = 8
const TIME_FRAME_HEIGHT = 20
const ROUND_NUMBER = 2

const MAP_KEY = {
  OPEN_MARKET: 'OPEN_MARKET',
  OPEN_MARKET_OPERATIONS: 'OPEN_MARKET_OPERATIONS',
  OPEN_MARKET_OPERATIONS_TOP: 'OPEN_MARKET_OPERATIONS_TOP',
  OPEN_MARKET_OPERATIONS_BOTTOM: 'OPEN_MARKET_OPERATIONS_BOTTOM',
  OPEN_MARKET_AUCTION_RESULTS: 'OPEN_MARKET_AUCTION_RESULTS',
  SHARE_OUTSTANDING: 'SHARE_OUTSTANDING',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.OPEN_MARKET]: {
    minWidth: 796,
    minHeight: 300,
  },
  [MAP_KEY.OPEN_MARKET_OPERATIONS]: {
    minWidth: 396,
    minHeight: 604,
  },
  [MAP_KEY.OPEN_MARKET_OPERATIONS_TOP]: {
    minWidth: 380,
    minHeight: 266,
  },
  [MAP_KEY.OPEN_MARKET_OPERATIONS_BOTTOM]: {
    minWidth: 380,
    minHeight: 266,
  },
  [MAP_KEY.OPEN_MARKET_AUCTION_RESULTS]: {
    minWidth: 432,
    minHeight: 300,
  },
  [MAP_KEY.SHARE_OUTSTANDING]: {
    minWidth: 396,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.OPEN_MARKET}`]: {
    width: `calc(200% / 3  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.OPEN_MARKET_OPERATIONS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.OPEN_MARKET_OPERATIONS_TOP,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.OPEN_MARKET_OPERATIONS_BOTTOM,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.OPEN_MARKET_AUCTION_RESULTS,
        isResizeWithParent: true,
        ratioWidth: -0.5,
      },
      {
        key: MAP_KEY.SHARE_OUTSTANDING,
        ratioWidth: -0.5,
        ratioLeft: 0.5,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.OPEN_MARKET_AUCTION_RESULTS,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.SHARE_OUTSTANDING,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.OPEN_MARKET_OPERATIONS}`]: {
    width: `calc(100% / 3  - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: `calc(200% / 3  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_OPERATIONS],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET].minWidth + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.OPEN_MARKET_OPERATIONS_TOP}`]: {
    width: `calc(100% /3  - ${COMPONENT.MARGIN + 2 * PADDING_PANEL}px)`,
    height: `calc(50%   - ${
      (8 * COMPONENT.MARGIN +
        HEADER_PANEL_HEIGHT +
        PADDING_PANEL +
        TIME_FRAME_HEIGHT +
        ROUND_NUMBER) /
      2
    }px)`,
    top: HEADER_PANEL_HEIGHT + 2 * PADDING_PANEL + TIME_FRAME_HEIGHT,
    left: `calc(200% / 3  + ${COMPONENT.MARGIN + PADDING_PANEL}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_OPERATIONS_TOP],
    minTop: HEADER_PANEL_HEIGHT + 2 * PADDING_PANEL + TIME_FRAME_HEIGHT,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET].minWidth +
      2 * COMPONENT.MARGIN +
      PADDING_PANEL,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.OPEN_MARKET_OPERATIONS_BOTTOM}`]: {
    width: `calc(100% /3  - ${COMPONENT.MARGIN + 2 * PADDING_PANEL}px)`,
    height: `calc(50%   - ${
      (8 * COMPONENT.MARGIN +
        HEADER_PANEL_HEIGHT +
        PADDING_PANEL +
        TIME_FRAME_HEIGHT +
        ROUND_NUMBER) /
      2
    }px)`,
    top: `calc(50%   + ${
      (4 * COMPONENT.MARGIN +
        HEADER_PANEL_HEIGHT +
        PADDING_PANEL +
        TIME_FRAME_HEIGHT) /
      2
    }px)`,
    left: `calc(200% / 3  + ${COMPONENT.MARGIN + PADDING_PANEL}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_OPERATIONS_BOTTOM],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_OPERATIONS_TOP].minHeight +
      HEADER_PANEL_HEIGHT +
      2 * PADDING_PANEL +
      TIME_FRAME_HEIGHT +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET].minWidth +
      2 * COMPONENT.MARGIN +
      PADDING_PANEL,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [`${MAP_KEY.OPEN_MARKET_AUCTION_RESULTS}`]: {
    width: `calc(100% / 3  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_AUCTION_RESULTS],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.SHARE_OUTSTANDING,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.SHARE_OUTSTANDING}`]: {
    width: `calc(100% / 3  - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.SHARE_OUTSTANDING],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET].minHeight + 2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.OPEN_MARKET_AUCTION_RESULTS].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <OpenMarket
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OPEN_MARKET}
          sizes={sizes}
          setSizes={setSizes}
        />
        <OpenMarketOperations
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OPEN_MARKET_OPERATIONS}
          sizes={sizes}
          setSizes={setSizes}
        />
        <OpenMarketOperationsTop
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OPEN_MARKET_OPERATIONS_TOP}
          sizes={sizes}
          setSizes={setSizes}
        />
        <OpenMarketOperationsBottom
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OPEN_MARKET_OPERATIONS_BOTTOM}
          sizes={sizes}
          setSizes={setSizes}
        />
        <OpenMarketAuction
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OPEN_MARKET_AUCTION_RESULTS}
          sizes={sizes}
          setSizes={setSizes}
        />
        <ShareOutstanding
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SHARE_OUTSTANDING}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
