import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundPortfolioService from '../../../../../../core/services/fund/FundPortfolioService'
import { handleExport } from '../../../../../utils/Export'
import { SECURITY_HOLDING_TABS, TABLE_TABS } from '../constants'

export const getTableDataThunk = createAsyncThunk(
  'fund/fundProfile/portfolio/GET_TABLE_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params, tableTab, securityHoldingTab } = data
      let response = {}

      switch (true) {
        case tableTab === TABLE_TABS.STOCK &&
          securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT:
          response = await FundPortfolioService.getStockCurrentSecurityHoldings(
            params,
          )
          break
        case tableTab === TABLE_TABS.STOCK &&
          securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY:
          response = await FundPortfolioService.getStockSecurityHoldingsHistory(
            params,
          )
          break
        default:
          break
      }

      if (response.success) {
        return {
          data: response.data,
          totalPage:
            response.data.length && response.data[0].totalRow
              ? Math.ceil(response.data[0].totalRow / params.PageSize)
              : 1,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadStockCurrentSecurityHoldings = createAsyncThunk(
  'fund/fundProfile/portfolio/DOWNLOAD_STOCK_CURRENT_SECURITY_HOLDING',
  async (data) => {
    const response =
      await FundPortfolioService.downloadStockCurrentSecurityHoldings(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadStockSecurityHoldingsHistory = createAsyncThunk(
  'fund/fundProfile/portfolio/DOWNLOAD_STOCK_SECURITY_HOLDING_HISTORY',
  async (data) => {
    const response =
      await FundPortfolioService.downloadStockSecurityHoldingsHistory(data)
    handleExport(response.data, response.filename)
  },
)
