import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import ItemReport from './ItemReport'
import { selectTemplate } from './store/slice'
import style from './style.module.css'

const idDivFooterScroll = 'div-footer-scroll'

const SavedReport = ({ isHandle }) => {
  const listSaveReport = useSelector(selectTemplate)

  return (
    <div className={`${style.template} ${isHandle ? style.isHandle : ''}`}>
      <div className={style.titleGray}>
        <Translate value="corporate.companySnapshot.MY_TEMPLATES" />
      </div>
      <ScrollComponent
        autoHeight={true}
        autoHeightMax={116}
        autoHeightMin={116}
      >
        <ul>
          {listSaveReport.map((item, index) => (
            <ItemReport key={index} item={item} index={index} />
          ))}
        </ul>
      </ScrollComponent>
      <div id={idDivFooterScroll} />
    </div>
  )
}

export default SavedReport
