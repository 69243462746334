import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { keyBy } from '../../../../utils/Common'
import { STATEMENT_CONSTANTS } from '../constants'
import {
  changeIds,
  changeIndicator,
  changeLoading,
  resetIndicatorMapping,
  selectFilterLocal,
  selectFinancialById,
  selectFinancialFilter,
  selectFinancialIds,
  selectFinancialValue,
  selectGroupColumns,
  selectLoading,
  selectMappingLoading,
  selectStatement,
  selectTableConstants,
} from '../store/slice'
import {
  getExchangeRates,
  getFinancialDataBalanceSheet,
  getFinancialDataCashFlow,
  getFinancialDataIncomeStatement,
  getFinancialDataNote,
  getIndicatorMapping,
} from '../store/thunk'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import { TABLE_ROW_LEVEL } from './constants'

export const FinancialStatementTable = () => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const financialIds = useSelector(selectFinancialIds)
  const tableConstants = useSelector(selectTableConstants)
  const isLoading = useSelector(selectLoading)
  const isMappingLoading = useSelector(selectMappingLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)
  const financialFilter = useSelector(selectFinancialFilter)
  const filterLocal = useSelector(selectFilterLocal)
  const statement = useSelector(selectStatement)
  const financialByIds = useSelector(selectFinancialById)

  // Use state
  const [rowsCollapse, setRowsCollapse] = useState([])

  // Actions
  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  // Use effect
  useEffect(() => {
    const payload = {
      OrganizationId: basicInfo.organizationId,
      TimeFilter: financialFilter.viewBy.value,
      NumberOfPeriod: financialFilter.numberOfPeriod.value,
      LatestYear: financialFilter.lastestYear.value,
      Consolidated: financialFilter.type.value,
      CommonSize: financialFilter.commonSize.value,
    }
    if (comGroup && Object.keys(financialByIds).length) {
      switch (statement.value) {
        case STATEMENT_CONSTANTS.BALANCE_SHEET:
          dispatch(getFinancialDataBalanceSheet(payload))
          break
        case STATEMENT_CONSTANTS.CASH_FLOW:
          dispatch(getFinancialDataCashFlow(payload))
          break
        case STATEMENT_CONSTANTS.INCOME_STATEMENT:
          dispatch(getFinancialDataIncomeStatement(payload))
          break
        case STATEMENT_CONSTANTS.NOTE:
          dispatch(getFinancialDataNote(payload))
          break
        default:
          break
      }
      setRowsCollapse([])
      dispatch(changeLoading(true))
    }
  }, [financialFilter, basicInfo, financialByIds])

  useEffect(() => {
    if (comGroup) {
      dispatch(resetIndicatorMapping())
      dispatch(
        getIndicatorMapping({
          Statement: statement.value,
          ComGroup: comGroup,
          Language: locale,
        }),
      )
    }
  }, [comGroup, locale, statement.value, basicInfo])

  useEffect(() => {
    if (filterLocal.unit?.value) {
      dispatch(
        getExchangeRates({ CurrencyCode: 'USD' || filterLocal.unit?.value }),
      )
    }
  }, [filterLocal.unit.value])

  if (isLoading || isMappingLoading) {
    return <Loading />
  }

  if (!groupColumns.length || !Object.keys(financialByIds).length) {
    return <NoData />
  }

  const renderTable = () => {
    const levelCollapse = keyBy(
      tableConstants.map((v) => ({
        index: v.index,
        level: v.level,
        isRowCollapse: v.isCollapse,
      })),
      'index',
    )

    return (
      <Table
        ids={financialIds}
        columnDraggable={false}
        getDataFromRedux={selectFinancialValue}
        isLoading={isLoading || isMappingLoading}
        rowDraggable={false}
        changeIds={changeIds}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'left',
                fontSize: 10,
              },
              canCustomTd: true,
              render: (val, rowId, { style, className }) => {
                return (
                  <FirstColumnCell
                    val={val}
                    rowId={rowId}
                    style={{ ...style, minWidth: 400 }}
                    className={className}
                    defaultActiveRowId={financialIds[1]}
                    onRowCollapse={onRowCollapse}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, { style, className }) => {
              return (
                <OtherColumnsCell
                  val={val}
                  rowId={rowId}
                  className={className}
                  columnItem={item}
                />
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        changeActiveRow={changeIndicator}
        disableClickGroups={tableConstants
          .filter((item) => item.level === TABLE_ROW_LEVEL.LEVEL_0)
          .map((item) => item.index)}
        defaultActiveRowId={financialIds[1]}
        resizable={false}
        defaultScrollToRight={true}
        isCollapse={true}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
      />
    )
  }

  return renderTable()
}
