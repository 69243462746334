import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import {
  changeStateStatus,
  selectActionStatus,
  selectFilter,
} from '../store/slice'
import { getActionStatus } from '../store/thunk'
import style from '../style.module.css'

export const DropdownSearchStatus = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filterData = useSelector(selectFilter)
  const dataActionStatus = useSelector(selectActionStatus)

  const setStateStatus = (data) => {
    dispatch(changeStateStatus(data))
  }

  useEffect(() => {
    dispatch(getActionStatus())
  }, [locale])

  return (
    <div className={`mr-8 ${style.spanInputSearch}`} style={{ width: 135 }}>
      <SelectCustom
        value={filterData.stateStatus}
        isI18n={true}
        selectData={dataActionStatus.map((item) => ({
          name: item.activeStatusName,
          value: item.activeStatusId,
        }))}
        handleChange={setStateStatus}
      />
    </div>
  )
}

export default DropdownSearchStatus
