import { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Loading } from '../../../common/loading'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { debounce } from '../../../utils/Common'
import PopupContentMenu from '../../common/dropdownMoreDashboard/PopupContentMenu'
import {
  selectChartByDashboardId,
  selectChartLibrary,
  selectDashboardLoading,
  selectMyChartLibrary,
  selectMyDashboard,
} from '../../store/slice'
import style from '../style.module.css'
import styles from '../styleTheme.module.css'

export const PopupContent = ({ buttonSaveRef, selectMultipleItems }) => {
  const dashboardLoading = useSelector(selectDashboardLoading)
  const myChartLibrary = useSelector(selectMyChartLibrary)
  const myDashboard = useSelector(selectMyDashboard)
  const chartByDashboardId = useSelector(selectChartByDashboardId)
  const chartLibrary = useSelector(selectChartLibrary)

  const [searchKey, setSearchKey] = useState('')

  const treeMenuMyDashboard = () => {
    const objClone = JSON.parse(JSON.stringify(myDashboard))
    objClone.children.forEach((element) => {
      if (!element.parentId) {
        element.children = chartByDashboardId[element.id]
      } else {
        element.children.forEach((item) => {
          item.children = chartByDashboardId[item.id] || []
        })
      }
    })
    return objClone
  }

  return (
    <>
      <div className={style.searchInput}>
        <div className="form-search-inbox m-0">
          <Input
            type="text"
            className={`search-inbox ${styles.inputStyle}`}
            placeholder={I18n.t('financialChart.SEARCH')}
            onChange={debounce((e) => setSearchKey(e.target.value))}
          />
          <button type="button">
            <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
          </button>
        </div>
      </div>
      <div className={style.popupSaveChartContent}>
        {dashboardLoading && <Loading isBlack />}
        {!dashboardLoading && (
          <ScrollComponent bgColor="#D9D9D9">
            <>
              <PopupContentMenu
                treeMenu={treeMenuMyDashboard()}
                buttonSaveRef={buttonSaveRef}
                selectMultipleItems={selectMultipleItems}
                searchKey={searchKey}
                isShowChart
              />
              <PopupContentMenu
                treeMenu={{ ...myChartLibrary, children: chartLibrary }}
                selectMultipleItems={selectMultipleItems}
                searchKey={searchKey}
                isShowChart
                isChartLibrary
              />
            </>
          </ScrollComponent>
        )}
      </div>
    </>
  )
}
