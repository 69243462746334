import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import EventEmitter, { RESET_TABLE_SORT } from '../../utils/EventEmitter'
import UseFontSize from '../hooks/useFontSize'
import { Loading } from '../loading'
import ThePagination from '../pagination/ThePagination'
import { SizeTracker } from '../sizeTracker'
import { ScrollComponent } from './../ScrollComponent'
import { NoData } from './NoData'
import { TableBody } from './TableBody'
import { TableFooter } from './TableFooter'
import { TableHead } from './TableHead'
import { reorder } from './helper'
import style from './index.module.css'

export const RENDER_CONTENT_TYPE = {
  FIT_CONTENT: 1,
  SCROLL: 2,
  SCROLL_WITH_SIZE_TRACKER: 3,
}

export const Table = ({
  schema,
  rowSpanSchema,
  ids,
  idsDisplay,
  getDataFromRedux,
  isLoading,
  rowDraggable,
  columnDraggable,
  noDataText,
  defaultSort,
  valueSort,
  sort,
  resizable,
  changeIds,
  isLargeHead,
  hasFooter,
  listFooterText,
  hasPagination,
  hasTooltip,
  changeActiveRow,
  defaultActiveRowId,
  renderContentType,
  disableClickGroups,
  defaultScrollToRight,
  renderHeader,
  renderTBody,
  isCollapse,
  rowsCollapse,
  levelCollapse,
  onRowCollapse,
  onRowDoubleClick,
  onRowClick,
  stickies,
  reCalcWidths,
  stickyFirstColumn,
  currentPage,
  totalPage,
  handleChangePage,
  handleSortField,
  renderContentPagination,
  renderRightContent,
  rowHighlightLines,
  tableHeadRowTop,
  stickyBottomRowCount,
  disableRowHovered,
  scrollToBottomCallback,
  scrollToTopCallback,
  scrollToRightCallback,
  scrollToLeftCallback,
  hasBorderTHead,
  idRowCustomStyle,
  rowCustomStyle,
  stickyBgColor,
  isFullWidth,
  hasTwoColumnBorder,
  hasUpdatedBorder,
  multipleActiveRowsIds,
  isLazyLoadLeft,
  verticalTrackTop,
  dimensionsTable,
  horizontalTrackLeft,
  scrollbarsRef,
  isScrollHorizontal,
  stickyBottomRLeft,
}) => {
  const typeFontSize = UseFontSize()

  const [stateSort, setStateSort] = useState(defaultSort)
  const [stateSchema, setStateSchema] = useState(schema)
  const [widths, setWidths] = useState({})
  const [verticalTrackBottom, setVerticalTrackBottom] = useState(0)
  const [isWidth100, setIsWidth100] = useState(true)
  const [tableScrollWidth, setTableScrollWidth] = useState(0)
  const [isScrollRight, setIsScrollRight] = useState(true)
  const dispatch = useDispatch()
  const tableRef = useRef()
  const prevTableScrollWidth = useRef()
  const [offsetLeft, setOffsetLeft] = useState(0)

  useEffect(() => {
    if (sort && typeof sort === 'function') {
      dispatch(sort(defaultSort))
    }
  }, [])

  useEffect(() => {
    if (valueSort) {
      setStateSort(valueSort)
    }
  }, [valueSort])

  useEffect(() => {
    setStateSchema(schema)
  }, [schema])

  useEffect(() => {
    prevTableScrollWidth.current = tableScrollWidth
  }, [tableScrollWidth])

  useEffect(() => {
    if (tableRef.current && isLazyLoadLeft) {
      setTableScrollWidth((prev) => {
        prevTableScrollWidth.current = prev
        return tableRef.current.scrollWidth
      })
    }
  })

  const reorderColumn = (startIndex, targetIndex) => {
    setStateSchema(reorder(stateSchema, startIndex, targetIndex))
  }

  const reorderRow = (startIndex, targetIndex) => {
    const newIds = reorder(ids, startIndex, targetIndex)
    dispatch(changeIds(newIds))
  }

  const sortColumn = (newStateSort) => {
    if (sort) {
      if (handleSortField) {
        handleSortField(newStateSort)
      } else {
        dispatch(sort(newStateSort))
      }

      setIsScrollRight(false)
      setStateSort(newStateSort)
    }
  }

  const resetTableSort = () => {
    setStateSort({})
  }

  useEffect(() => {
    EventEmitter.subscribe(RESET_TABLE_SORT, resetTableSort)
    return () => EventEmitter.unsubscribe(RESET_TABLE_SORT, resetTableSort)
  }, [])

  useEffect(() => {
    if (dimensionsTable && tableRef?.current) {
      dimensionsTable({
        width: tableRef?.current?.clientWidth,
        height: tableRef?.current?.clientHeight,
      })
    }
  }, [tableRef?.current?.clientHeight])

  const headRef = useRef()
  const renderHead = () => {
    return renderHeader ? (
      renderHeader(stateSort, sortColumn, offsetLeft)
    ) : (
      <TableHead
        schema={stateSchema}
        rowSpanSchema={rowSpanSchema}
        headRef={headRef}
        columnDraggable={columnDraggable}
        reorderColumn={reorderColumn}
        sortColumn={sortColumn}
        stateSort={stateSort}
        resizable={resizable}
        hasTooltip={hasTooltip}
        stickies={stickies}
        widths={widths}
        setWidths={setWidths}
        setIsWidth100={setIsWidth100}
        reCalcWidths={reCalcWidths}
        stickyFirstColumn={stickyFirstColumn}
        tableHeadRowTop={tableHeadRowTop}
        hasBorderTHead={hasBorderTHead}
        typeFontSize={typeFontSize}
      />
    )
  }

  const renderBody = () => {
    if (renderTBody) {
      return renderTBody()
    }

    const isNoData = ids.length === 0
    if (isNoData && !isLoading) {
      return <NoData schema={stateSchema} noDataText={noDataText} />
    }

    return (
      <TableBody
        ids={ids}
        idsDisplay={idsDisplay}
        getDataFromRedux={getDataFromRedux}
        schema={stateSchema}
        rowDraggable={rowDraggable}
        reorder={reorderRow}
        changeActiveRow={changeActiveRow}
        defaultActiveRowId={defaultActiveRowId}
        disableClickGroups={disableClickGroups}
        isCollapse={isCollapse}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        onRowCollapse={onRowCollapse}
        onRowDoubleClick={onRowDoubleClick}
        onRowClick={onRowClick}
        stickies={stickies}
        widths={widths}
        stickyFirstColumn={stickyFirstColumn}
        rowHighlightLines={rowHighlightLines}
        stickyBottomRowCount={stickyBottomRowCount}
        disableRowHovered={disableRowHovered}
        setVerticalTrackBottom={setVerticalTrackBottom}
        rowCustomStyle={rowCustomStyle}
        idRowCustomStyle={idRowCustomStyle}
        stickyBgColor={stickyBgColor}
        multipleActiveRowsIds={multipleActiveRowsIds}
        stickyBottomRLeft={stickyBottomRLeft}
      />
    )
  }

  const getTableClassName = () => {
    const classNames = [style.table]

    if (isFullWidth && isWidth100) {
      classNames.push('w-100')
    }
    if (hasTwoColumnBorder) {
      classNames.push('two-column-table')
    }
    if (hasUpdatedBorder) {
      classNames.push(style.borderTable)
    }
    return classNames.join(' ')
  }

  const getTableParentClassName = () => {
    const classNames = [style.tableParent]

    if (isLoading) {
      classNames.push(style.tableLoadingBlur)
    }

    return classNames.join(' ')
  }

  const renderContentScroll = () => {
    return (
      <div className={getTableParentClassName()}>
        <table className={getTableClassName()} ref={tableRef}>
          {renderHead()}
          {renderBody()}
        </table>
      </div>
    )
  }

  const getVerticalTrackTop = () => {
    if (verticalTrackTop) {
      return verticalTrackTop
    }

    return headRef.current?.clientHeight || 0
  }

  const renderScroll = () => {
    return isScrollHorizontal ? (
      <ScrollComponent
        horizontalTrackLeft={horizontalTrackLeft}
        verticalTrackTop={getVerticalTrackTop()}
        verticalTrackBottom={verticalTrackBottom}
        defaultScrollToRight={defaultScrollToRight}
        scrollToBottomCallback={scrollToBottomCallback}
        scrollToTopCallback={scrollToTopCallback}
        scrollToRightCallback={scrollToRightCallback}
        scrollToLeftCallback={scrollToLeftCallback}
        scrollLeftValue={tableScrollWidth - prevTableScrollWidth.current}
        isScrollRight={isScrollRight}
        isLoading={isLoading}
        scrollCallBack={setOffsetLeft}
        scrollbarsRef={scrollbarsRef}
      >
        {renderContentScroll()}
      </ScrollComponent>
    ) : (
      <ScrollComponent
        horizontalTrackLeft={horizontalTrackLeft}
        verticalTrackTop={getVerticalTrackTop()}
        verticalTrackBottom={verticalTrackBottom}
        defaultScrollToRight={defaultScrollToRight}
        scrollToBottomCallback={scrollToBottomCallback}
        scrollToTopCallback={scrollToTopCallback}
        scrollToRightCallback={scrollToRightCallback}
        scrollToLeftCallback={scrollToLeftCallback}
        scrollLeftValue={tableScrollWidth - prevTableScrollWidth.current}
        isScrollRight={isScrollRight}
        isLoading={isLoading}
      >
        {renderContentScroll()}
      </ScrollComponent>
    )
  }

  const getStyle = () => {
    const classNames = [style.tableWrapper, 'table-wrapper']
    if (isLargeHead) {
      classNames.push('table-head-large')
    }

    return classNames.join(' ')
  }

  return (
    <div className={getStyle()}>
      {isLoading && <Loading />}
      {renderContentType === RENDER_CONTENT_TYPE.FIT_CONTENT && (
        <>
          {hasPagination && (
            <ThePagination
              currentPage={currentPage}
              totalPages={totalPage}
              changeCurrentPage={handleChangePage}
              renderContentPagination={renderContentPagination}
              renderRightContent={renderRightContent}
            />
          )}
          {renderContentScroll()}
          {hasFooter && <TableFooter listFooterText={listFooterText} />}
        </>
      )}
      {renderContentType === RENDER_CONTENT_TYPE.SCROLL && (
        <>
          {hasPagination && (
            <ThePagination
              currentPage={currentPage}
              totalPages={totalPage}
              changeCurrentPage={handleChangePage}
              renderContentPagination={renderContentPagination}
              renderRightContent={renderRightContent}
            />
          )}
          {renderScroll()}
          {hasFooter && <TableFooter listFooterText={listFooterText} />}
        </>
      )}
      {renderContentType === RENDER_CONTENT_TYPE.SCROLL_WITH_SIZE_TRACKER && (
        <SizeTracker>
          {(size) => (
            <>
              {hasPagination && (
                <ThePagination
                  currentPage={currentPage}
                  totalPages={totalPage}
                  changeCurrentPage={handleChangePage}
                  renderContentPagination={renderContentPagination}
                  renderRightContent={renderRightContent}
                />
              )}
              {(size.height || size.height === 0) && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {renderScroll()}
                </div>
              )}
              {hasFooter && <TableFooter listFooterText={listFooterText} />}
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

Table.propTypes = {
  schema: PropTypes.array.isRequired,
  rowSpanSchema: PropTypes.array,
  ids: PropTypes.array.isRequired,
  idsDisplay: PropTypes.array,
  getDataFromRedux: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  rowDraggable: PropTypes.bool,
  columnDraggable: PropTypes.bool,
  noDataText: PropTypes.string,
  defaultSort: PropTypes.object,
  valueSort: PropTypes.object,
  sort: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  resizable: PropTypes.bool,
  isLargeHead: PropTypes.bool,
  hasFooter: PropTypes.bool,
  listFooterText: PropTypes.array,
  hasPagination: PropTypes.bool,
  renderContentType: PropTypes.number,
  hasTooltip: PropTypes.bool,
  changeActiveRow: PropTypes.func,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableClickGroups: PropTypes.array,
  defaultScrollToRight: PropTypes.bool,
  renderHeader: PropTypes.func,
  renderTBody: PropTypes.func,
  isCollapse: PropTypes.bool,
  rowsCollapse: PropTypes.array,
  levelCollapse: PropTypes.object,
  onRowCollapse: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowClick: PropTypes.func,
  stickies: PropTypes.object,
  stickyFirstColumn: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  handleChangePage: PropTypes.func,
  rowHighlightLines: PropTypes.array,
  tableHeadRowTop: PropTypes.object,
  reCalcWidths: PropTypes.any,
  stickyBottomRowCount: PropTypes.number,
  disableRowHovered: PropTypes.object,
  scrollToBottomCallback: PropTypes.func,
  scrollToLeftCallback: PropTypes.func,
  scrollToTopCallback: PropTypes.func,
  scrollToRightCallback: PropTypes.func,
  hasBorderTHead: PropTypes.bool,
  idRowCustomStyle: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  rowCustomStyle: PropTypes.object,
  stickyBgColor: PropTypes.string,
  renderRightContent: PropTypes.func,
  isFullWidth: PropTypes.bool,
  isLazyLoadLeft: PropTypes.bool,
  verticalTrackTop: PropTypes.number,
  dimensionsTable: PropTypes.func,
  horizontalTrackLeft: PropTypes.number,
}

Table.defaultProps = {
  noDataText: '',
  isLoading: false,
  rowDraggable: false,
  defaultSort: {},
  columnDraggable: false,
  resizable: true,
  isLargeHead: false,
  hasFooter: true,
  sort: null,
  hasPagination: false,
  renderContentType: RENDER_CONTENT_TYPE.SCROLL_WITH_SIZE_TRACKER,
  hasTooltip: true,
  disableClickGroups: [],
  defaultScrollToRight: false,
  isCollapse: false,
  rowsCollapse: [],
  levelCollapse: {},
  stickies: {},
  stickyFirstColumn: false,
  rowHighlightLines: [],
  disableRowHovered: {},
  hasBorderTHead: false,
  rowCustomStyle: {},
  stickyBgColor: '#1e242e',
  idRowCustomStyle: [],
  isFullWidth: true,
  hasUpdatedBorder: true,
  isLazyLoadLeft: false,
  verticalTrackTop: 0,
  horizontalTrackLeft: 0,
  isScrollHorizontal: false,
}
