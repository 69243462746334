import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import * as Yup from 'yup'
import { Form } from '../../common/formValidate/Form'
import { encodeMessage } from '../../common/formValidate/helper'
import style from '../index.module.css'
import {
  resetWatchlistDetail,
  selectWatchlistDetail,
  selectWatchlistsById,
} from '../store/slice'
import {
  createWatchlistThunk,
  getWatchlistDetailThunk,
  updateMasterWatchListThunk,
  updateWatchlistThunk,
} from '../store/thunk'
import ButtonPrevious from './ButtonPrevious'
import ButtonShowTicker from './ButtonShowTicker'
import GroupButtonSubmit from './GroupButtonSubmit'
import TickerFilter from './TickerFilter'
import TickerTable from './TickerTable'
import WatchlistInformation from './WatchlistInformation'

const newWatchlist = {
  watchListName: '',
  description: '',
  isSaveAs: false,
  watchListNameSaveAs: '',
  watchListDetails: [],
}

const watchlistSchema = (watchlistsById, watchlistId) => {
  return Yup.object().shape({
    watchListName: Yup.string().when('isSaveAs', (isSaveAs, schema) => {
      if (!isSaveAs)
        return schema
          .required(
            encodeMessage(
              'watchListName',
              'watchlistBond.watchlistDetail.validate.NAME_REQUIRE',
            ),
          )
          .max(
            100,
            encodeMessage(
              'watchListName',
              'watchlistBond.watchlistDetail.validate.NAME_MAX',
            ),
          )
          .test(
            'CHECK_DULICATE_NAME',
            encodeMessage(
              'watchListName',
              'watchlistBond.watchlistDetail.validate.NAME_DUPLICATE',
            ),
            (value) => {
              return !Object.keys(watchlistsById).some(
                (id) =>
                  watchlistsById[id].watchListName === value &&
                  watchlistId + '' !== id + '',
              )
            },
          )
      return schema
    }),
    watchListNameSaveAs: Yup.string().when('isSaveAs', (isSaveAs, schema) => {
      if (isSaveAs)
        return schema
          .required(
            encodeMessage(
              'watchListNameSaveAs',
              'watchlistBond.watchlistDetail.validate.NAME_REQUIRE',
            ),
          )
          .max(
            100,
            encodeMessage(
              'watchListNameSaveAs',
              'watchlistBond.watchlistDetail.validate.NAME_MAX',
            ),
          )
          .test(
            'CHECK_DULICATE_NAME',
            encodeMessage(
              'watchListNameSaveAs',
              'watchlistBond.watchlistDetail.validate.NAME_DUPLICATE',
            ),
            (value) => {
              return !Object.keys(watchlistsById).some(
                (id) => watchlistsById[id].watchListName === value,
              )
            },
          )
      return schema
    }),
  })
}

const WatchlistDetail = ({ watchlistId, handleHideDetail }) => {
  const dispatch = useDispatch()

  const watchlistsById = useSelector(selectWatchlistsById)
  const watchlistDetail = useSelector(selectWatchlistDetail)

  useEffect(() => {
    if (watchlistId)
      dispatch(getWatchlistDetailThunk({ bondWatchlistId: watchlistId }))
  }, [watchlistId, dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetWatchlistDetail())
    }
  }, [])

  const handleSubmit = async ({
    watchListName,
    description,
    isSaveAs,
    watchListNameSaveAs,
    watchListDetails,
  }) => {
    if (isSaveAs)
      dispatch(
        createWatchlistThunk({
          watchlistName: watchListNameSaveAs,
          watchlistDescription: description,
          bondWatchlistDetailsList: watchListDetails,
        }),
      )
    else if (!watchlistId) {
      dispatch(
        createWatchlistThunk({
          watchListName,
          watchlistDescription: description,
          bondWatchlistDetailsList: watchListDetails,
        }),
      )
    } else {
      if (
        watchlistDetail.watchListName !== watchListName ||
        watchlistDetail.description !== description
      ) {
        dispatch(
          updateMasterWatchListThunk({
            bondWatchListId: +watchlistId,
            bondWatchListName: watchListName,
            description: description,
          }),
        )
      }

      dispatch(
        updateWatchlistThunk({
          bondWatchlistId: watchlistId,
          bondWatchlistDetails: watchListDetails,
        }),
      )
    }
    handleHideDetail()
  }

  return (
    <div className="modal-content">
      {!watchlistId && (
        <div className={style.createTitle}>
          <Translate value="watchlistBond.watchlistDetail.TITLE_CREATE" />
        </div>
      )}
      <Form
        initialValues={
          watchlistDetail.watchListName
            ? {
                watchListName: watchlistDetail.watchListName,
                description: watchlistDetail.description,
                isSaveAs: false,
                watchListNameSaveAs: '',
                watchListDetails: watchlistDetail.details,
              }
            : newWatchlist
        }
        schema={watchlistSchema(watchlistsById, watchlistId)}
        handleSubmitForm={handleSubmit}
      >
        {(form) => {
          return (
            <>
              <form className="form-ticker">
                <WatchlistInformation form={form} />
                <ButtonShowTicker />
              </form>
              <div className={`d-flex ${style.tableTickerContainer}`}>
                <TickerFilter form={form} />
                <TickerTable form={form} />
              </div>
              <div className={`d-flex j-b group-btn-stick ${style.mt38}`}>
                <ButtonPrevious
                  handleHideDetail={handleHideDetail}
                  form={form}
                />
                <GroupButtonSubmit watchlistId={watchlistId} form={form} />
              </div>
            </>
          )
        }}
      </Form>
    </div>
  )
}

WatchlistDetail.propTypes = {
  watchlistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleHideDetail: PropTypes.func.isRequired,
}

export default WatchlistDetail
