import PropTypes from 'prop-types'
import { withWrapper } from '../../../common/withWrapper'
import PanelPrice from './PanelPrice'

const MAP_KEY = {
  PRICE_BOARD: 'PRICE_BOARD',
}

const MAP_SIZE = {
  [`${MAP_KEY.PRICE_BOARD}`]: {
    width: `100%`,
    height: `100%`,
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelPrice
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PRICE_BOARD}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
