import { useDispatch } from 'react-redux'
import { resetChartTabData } from '../../chart/store/slice'
import { screenTypes } from '../../constants'
import { changeDashboard } from '../../store/slice'
import { NewChartOrDashboardBtn } from './common/NewChartOrDashboardBtn'

const NewChartBtn = () => {
  const dispatch = useDispatch()

  const redirect = () => {
    dispatch(changeDashboard({ screenType: screenTypes.NEW_CHART, data: null }))
    dispatch(resetChartTabData())
  }

  return (
    <NewChartOrDashboardBtn
      title={'financialChart.NEW_CHART'}
      renderIcon={() => (
        <i className="icon-plus-circle-line" style={{ fontSize: 14 }} />
      )}
      redirect={redirect}
    />
  )
}

export default NewChartBtn
