import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valDivThousand } from '../../../../../utils/Value'
import { CHART_TYPE, ITEM_TYPE, TIME_FRAME } from '../../constant'
import { getPriceChartData } from './thunk'

const initialState = {
  chartType: CHART_TYPE.RELATIVE,
  timeFrame: TIME_FRAME.ONE_YEAR.value,
  data: [],
  loading: true,
  hoveredItem: null,
}

const slice = createSlice({
  name: 'sector/sectorConstituents/valuation/priceChart',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeChartType: (state, action) => {
      state.chartType = action.payload
    },
    changeTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
    changeHoveredItem: (state, action) => {
      state.hoveredItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPriceChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPriceChartData.fulfilled, (state, action) => {
      const data = {}
      action.payload.forEach((item) => {
        if (!data[item.tradingDateId]) {
          data[item.tradingDateId] = { tradingDateId: item.tradingDateId }
        }
        if (item.organizationId) {
          data[item.tradingDateId][
            `${ITEM_TYPE.TICKER}-${item.organizationId}`
          ] = valDivThousand(item.closePrice)
        } else {
          data[item.tradingDateId][`${ITEM_TYPE.SECTOR}-${item.icbId}`] =
            item.closePrice
        }
      })

      state.data = Object.values(data).sort(
        (a, b) => new Date(a.tradingDateId) - new Date(b.tradingDateId),
      )
      state.loading = false
    })
    builder.addCase(getPriceChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartType = (state) => state[slice.name].chartType
export const selectTimeFrame = (state) => state[slice.name].timeFrame
export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data
export const selectHoveredItem = (state) => state[slice.name].hoveredItem

export const {
  resetStore,
  changeChartType,
  changeTimeFrame,
  changeHoveredItem,
} = slice.actions

register(slice.name, slice.reducer)
