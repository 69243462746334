import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  COMPANY_GROWTH_FILTER,
  TIME_RANGE,
} from '../../../common/chartCompanyGrowth/constants'
import { convertData } from '../../../common/chartCompanyGrowth/helpers'
import { getConstructionCompanyGrowth, getOrganizations } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/construction/companyGrowth',
  initialState: {
    isLoading: true,
    data: [],
    isLoadingCompany: true,
    listCompany: [],
    currentCompany: null,
    currentCategoryFilter: COMPANY_GROWTH_FILTER[0].value,
    currentDateType: TIME_RANGE.MONTHLY[0].value,
    currentDateTypeYearly: TIME_RANGE.YEARLY[0].value,
    isYearlyChart: false,
  },
  reducers: {
    changeCurrentCompany: (state, action) => {
      state.currentCompany = action.payload
    },
    changeCurrentCategoryFilter: (state, action) => {
      state.currentCategoryFilter = action.payload
    },
    changeCurrentDateType: (state, action) => {
      state.currentDateType = action.payload
    },
    changeCurrentDateTypeYearly: (state, action) => {
      state.currentDateTypeYearly = action.payload
    },
    changeIsYearlyChart: (state, action) => {
      state.isYearlyChart = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConstructionCompanyGrowth.pending, (state) => {
      state.data = []
      state.isLoading = true
      state.dataById = {}
    })
    builder.addCase(getConstructionCompanyGrowth.fulfilled, (state, action) => {
      const payloadData = action.payload || []
      const payloadDataLength = payloadData.length
      const latestMonth = payloadData[payloadDataLength - 1].month
      const latestYear = payloadData[payloadDataLength - 1].year
      const indexOfPrevData = state.isYearlyChart
        ? payloadData.findIndex(
            (item) =>
              item.year === latestYear - state.currentDateTypeYearly + 1,
          )
        : payloadData.findIndex(
            (item) =>
              item.month === latestMonth &&
              item.year === latestYear - state.currentDateType,
          )

      const slicedData =
        indexOfPrevData !== -1
          ? payloadData.slice(indexOfPrevData, payloadDataLength)
          : payloadData

      state.data = slicedData.map((item) =>
        convertData(item, ['month', 'year', 'organizationId']),
      )
      state.isLoading = false
    })
    builder.addCase(getConstructionCompanyGrowth.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getOrganizations.pending, (state) => {
      state.listCompany = []
      state.isLoadingCompany = true
    })
    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      const payloadData = action.payload || []
      state.listCompany = payloadData.map((item) => ({
        ...item,
        id: item.organizationId,
        name: item.organizationShortName,
      }))
      state.isLoadingCompany = false
    })
    builder.addCase(getOrganizations.rejected, (state) => {
      state.isLoadingCompany = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectCurrentCompany = (state) => state[slice.name].currentCompany
export const selectListCompany = (state) => state[slice.name].listCompany
export const selectCurrentCategoryFilter = (state) =>
  state[slice.name].currentCategoryFilter

export const {
  changeCurrentCompany,
  changeCurrentCategoryFilter,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  changeIsYearlyChart,
} = slice.actions

register(slice.name, slice.reducer)
