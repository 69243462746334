import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { SEARCH_TIME_FRAME } from '../../constants'
import { getNetValueInternalTrading } from './thunk'

const initialState = {
  loading: true,
  stateTimeFrame: SEARCH_TIME_FRAME.ONE_MONTH,
  dataNetValueInternalTrading: [],
}

export const slice = createSlice({
  name: 'market/marketInDepth/netValueOfInternalTrading',
  initialState,
  reducers: {
    changeStateTimeFrame: (state, action) => {
      state.stateTimeFrame = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetValueInternalTrading.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueInternalTrading.fulfilled, (state, action) => {
      state.loading = false
      state.dataNetValueInternalTrading = action.payload
    })
    builder.addCase(getNetValueInternalTrading.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading

export const selectStateTimeFrame = (state) => state[slice.name].stateTimeFrame

export const selectNetValueInternalTrading = (state) =>
  state[slice.name].dataNetValueInternalTrading

export const { changeStateTimeFrame } = slice.actions

register(slice.name, slice.reducer)
