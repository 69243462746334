import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import Radio from '../../../../common/html/Radio'
import useGetMaxMinTable from '../../../../common/masterData/useGetMaxMinTable'
import SelectCustom from '../../../../common/selectCustom'
import { MONTH_OPTIONS } from '../../../../constants/Datetime'
import { TABLE_TABS, TIME_RANGE_TYPES } from '../constants'
import { selectTableTab } from '../store/slice'

export const TimeRange = ({ timeRange, month, year, onChange }) => {
  const { maxMinTableById } = useGetMaxMinTable()

  const tableTab = useSelector(selectTableTab)

  const maxMinTable = useMemo(() => {
    switch (tableTab) {
      case TABLE_TABS.SECTOR_ALLOCATION:
        return maxMinTableById['FGFB_COFS_DR_FundStockHolding']
      case TABLE_TABS.ASSET_ALLOCATION:
        return maxMinTableById['FGFB_COFS_DR_FundAssetReport']
      default:
        return maxMinTableById['FGFB_COFS_DR_FundNAVReport']
    }
  }, [maxMinTableById, tableTab])

  const getYears = useMemo(() => {
    return new Array(new Date().getFullYear() - 2000 + 1)
      .fill(null)
      .map((_, index) => ({
        name: String(new Date().getFullYear() - index),
        value: new Date().getFullYear() - index,
      }))
  }, [])

  const getMonths = useMemo(() => {
    return MONTH_OPTIONS
  }, [])

  const handleChangeTimeRange = (value) => {
    onChange({
      timeRange: value,
      month:
        value === TIME_RANGE_TYPES.SELECT_TIME_PERIOD
          ? month || maxMinTable?.max_MonthReport
          : '',
      year:
        value === TIME_RANGE_TYPES.SELECT_TIME_PERIOD
          ? year || maxMinTable?.max_YearOfMonthReport
          : '',
    })
  }

  return (
    <div className="d-flex ali-center">
      <div className="mr-16">
        <Radio
          data={Object.keys(TIME_RANGE_TYPES).map((key) => ({
            title: 'fund.fundCenter.statistic.' + key,
            value: TIME_RANGE_TYPES[key],
          }))}
          value={timeRange}
          onChange={handleChangeTimeRange}
          containerClass="w-auto d-flex"
        />
      </div>
      {timeRange === TIME_RANGE_TYPES.SELECT_TIME_PERIOD && (
        <div className="d-flex ali-center">
          <div style={{ width: 85 }} className="mr-8">
            <SelectCustom
              value={month}
              selectData={getMonths}
              handleChange={(value) => onChange({ month: value })}
              isI18n
            />
          </div>
          <div style={{ width: 85 }}>
            <SelectCustom
              value={year}
              selectData={getYears}
              handleChange={(value) => onChange({ year: value })}
              isI18n
            />
          </div>
        </div>
      )}
    </div>
  )
}
