import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import styleCommon from '../../../../common/table/index.module.css'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getStyle } from '../../helper'
import { selectGroupColumns, selectItemData } from '../store/slice'
import { TABLE_ROW_LEVEL } from './constants'
import styles from './index.module.css'

const MARGIN_LEFT = 40

export const FirstColumnCell = ({
  val,
  rowId,
  style,
  className,
  defaultActiveRowId,
}) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))
  const groupColumns = useSelector(selectGroupColumns)

  useEffect(() => {
    if (rowId === defaultActiveRowId) {
      tdRef.current.classList.add(styleCommon.active)
    } else {
      tdRef.current.classList.remove(styleCommon.active)
    }
  }, [groupColumns])

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    return (
      <td
        ref={tdRef}
        className={`${className} ${styles.rowLv1}`}
        style={{ ...style }}
      >
        <div
          style={{
            color: '#fecf2f',
            ...getStyle(item),
          }}
        >
          <TextEllipsisStaticLine val={val} />
        </div>
      </td>
    )
  } else {
    return (
      <td ref={tdRef} className={className} style={{ ...style }}>
        <div
          style={{
            marginLeft: MARGIN_LEFT,
            ...getStyle(item),
          }}
        >
          <TextEllipsisStaticLine val={val} />
        </div>
      </td>
    )
  }
}

FirstColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  className: PropTypes.string,
  defaultActiveRowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
