import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { RENDER_CONTENT_TYPE, Table } from '../../common/table'
import EventEmitter, { COMPONENT_RESIZE } from '../../utils/EventEmitter'
import { indices, tickerTableKeys } from '../constants'
import style from '../index.module.css'
import {
  addChecked,
  changeTickerIds,
  changeTickersById,
  selectChecked,
  selectIsShowTickerFilter,
  selectLoading,
  selectTickerIds,
  selectTickersById,
  selectTickersCell,
  sortTicker,
  unchecked,
  uncheckedAll,
} from '../store/slice'
import TBodyFilter from './TbodyFilter'
import TBodyOrganizationOrder from './TBodyOrganizationOrder'
import THeadFilter from './TheadFilter'
import TickerCheckbox from './TickerCheckbox'

export const heightTh = 36

const usePushTickerIntoForm = (form) => {
  const tickerIds = useSelector(selectTickerIds)
  const tickersById = useSelector(selectTickersById)

  useEffect(() => {
    form.changeValues(
      'watchListDetails',
      tickerIds.map((tickerId) => tickersById[tickerId]),
    )
  }, [tickerIds, tickersById])
}

const TickerTable = ({ form }) => {
  const checkedList = useSelector(selectChecked)
  const dispatch = useDispatch()

  usePushTickerIntoForm(form)

  const tickersById = useSelector(selectTickersById)
  const tickerIds = useSelector(selectTickerIds)
  const isLoading = useSelector(selectLoading('tickerTable'))
  const isShowTickerFilter = useSelector(selectIsShowTickerFilter)

  const onRowClick = (id) => {
    const condition = checkedList.some((item) => item === id)
    if (!condition) dispatch(addChecked(id))
    else dispatch(unchecked(id))
  }

  useEffect(() => {
    return () => dispatch(uncheckedAll())
  }, [dispatch])

  useEffect(() => {
    EventEmitter.dispatch(COMPONENT_RESIZE)
  }, [isShowTickerFilter])

  useEffect(() => {
    // add table id number
    const newTickersById = { ...tickersById }
    tickerIds.forEach((tickerId, index) => {
      newTickersById[tickerId] = {
        ...newTickersById[tickerId],
        organizationOrder: index + 1,
      }
    })
    dispatch(changeTickersById(newTickersById))
  }, [tickerIds])

  return (
    <div className={['box-r', 'w-100', style.tableNoneScrollHorizon].join(' ')}>
      <div className="w-100 h-100 watchlist-ticker ticker-table-container">
        <Table
          renderContentType={RENDER_CONTENT_TYPE.SCROLL}
          ids={tickerIds}
          columnDraggable={false}
          getDataFromRedux={selectTickersCell}
          isLoading={isLoading}
          changeIds={changeTickerIds}
          rowDraggable={true}
          hasFooter={false}
          hasTooltip={false}
          noDataText=" "
          resizable={false}
          onRowClick={onRowClick}
          schema={Object.keys(indices.tickers).map((key) => {
            const title = `watchlist.watchlistDetail.indices.${key}`

            const colId = indices.tickers[key]
            const result = {
              colId,
              title,
              disableSort: true,
            }

            return {
              ...result,
              isI18n: false,
              render:
                key === tickerTableKeys.CHECKBOX
                  ? (value, colId) => {
                      return <TickerCheckbox id={colId} />
                    }
                  : key === tickerTableKeys.CODE
                  ? (value, colId) => {
                      return <TBodyFilter value={value} colId={colId} />
                    }
                  : key === tickerTableKeys.ID
                  ? (value, colId) => {
                      return (
                        <TBodyOrganizationOrder value={value} colId={colId} />
                      )
                    }
                  : undefined,
              renderTh:
                key === tickerTableKeys.CHECKBOX
                  ? () => {
                      return <TickerCheckbox form={form} />
                    }
                  : key === tickerTableKeys.CODE
                  ? () => {
                      return <THeadFilter />
                    }
                  : key === tickerTableKeys.ID
                  ? () => <></>
                  : undefined,
              thStyle: {
                textAlign: 'left',
                height: heightTh + 'px',
                width: key === tickerTableKeys.CODE ? '34%' : undefined,
              },
            }
          })}
          sort={sortTicker}
        />
        <div className={`note ${style.mt7}`}>
          <Translate value="watchlist.watchlistDetail.TOTAL" />:{' '}
          {tickerIds.length}
        </div>
      </div>
    </div>
  )
}

TickerTable.propTypes = {
  form: PropTypes.object.isRequired,
}

export default TickerTable
