import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { colorArray } from '../constants.js'
import {
  keys,
  selectActiveTimeType,
  selectFilterMarketTradingValueBySector,
  selectLoading,
  selectMarketTradingValueBySectorData,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const MarketTradingValueBySector = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const isLoading = useSelector(
    selectLoading(keys.MARKET_TRADING_VALUE_BY_SECTOR),
  )
  const data = useSelector(selectMarketTradingValueBySectorData)
  const { month, quarter, year } = useSelector(
    selectFilterMarketTradingValueBySector,
  )
  const timeType = useSelector(selectActiveTimeType)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataPieChart = useMemo(() => {
    const filterData = data.filter((item) => item.percentage !== 0)
    const result = filterData.map((item, index) => ({
      text: item.icbName,
      value: item.percentage * 100,
      color: colorArray[index],
      locationId: item?.locationId,
      totalValue: item.totalValue,
    }))

    return [...result]
  }, [data])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => <ChartTooltip data={dataChart} />

  const titleJpg = useMemo(() => {
    const title = I18n.t('bond.corporateBond.marketTradingValueBySector.TITLE')

    // const time = renderDateTime({
    //   timeType,
    //   month,
    //   quarter,
    //   year,
    // })

    return `${title}`
  }, [timeType, month, quarter, year, locale])

  return (
    <Panel
      title="bond.corporateBond.marketTradingValueBySector.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isIconChart
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'marketTradingValueBySectorChartId',
        nameScreen: I18n.t('bond.corporateBond.secondaryMarket.TITLE'),
        chartName: I18n.t(
          'bond.corporateBond.marketTradingValueBySector.TITLE',
        ),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="marketTradingValueBySectorChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {!!formatDataPieChart.length ? (
                        <PieChartWithFooter
                          data={formatDataPieChart}
                          height={height - size.height}
                          width={width}
                          renderTooltip={renderTooltip}
                          footerRight
                          footerProps={{
                            isOneColumn: true,
                            appendStyle: { gridGap: 8 },
                          }}
                          isI18n
                          customStyleChartDiv={{ minWidth: '50%' }}
                          pieChartProps={{ radius: 30 }}
                        />
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

MarketTradingValueBySector.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default MarketTradingValueBySector
