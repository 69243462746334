import { TIME_FRAME_NET_VALUE } from './constants'

export const getTimeFrameTitle = (value) => {
  const title = Object.values(TIME_FRAME_NET_VALUE).find(
    (time) => time.value === value,
  )?.title
  return title
    ? title === TIME_FRAME_NET_VALUE.THREE_MONTH.title
      ? TIME_FRAME_NET_VALUE.ONE_MONTH.title
      : title
    : ''
}
