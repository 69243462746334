import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

const PieChartTooltip = ({
  data,
  decimalLength,
  title,
  renderTitle,
  renderValue,
}) => {
  const getTitle = () => {
    return typeof title === 'string' ? title : title(data)
  }

  return (
    <>
      {title && (
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{getTitle()}</Span>
        </div>
      )}
      <div className={style.chartTooltip}>
        <ul>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {renderTitle ? (
                    renderTitle(item)
                  ) : (
                    <>
                      {item.isI18n ? (
                        <Translate value={item.text} />
                      ) : (
                        item.text
                      )}
                      {':'}
                    </>
                  )}
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {renderValue
                    ? renderValue(item)
                    : valToPercent(item.value, false, false, decimalLength)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default PieChartTooltip
