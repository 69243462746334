import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { uuid } from '../../utils/Common'
import style from './index.module.css'

const ItemCheckAll = ({
  titleCheckboxAll,
  allId,
  listChecked,
  setListChecked,
  classItemStyle,
  classCheckboxStyle,
}) => {
  const inputId = 'checkAllTreeData_' + uuid()

  const [isChecked, setIsCheck] = useState(false)

  useEffect(() => {
    if (allId.length > 0 && allId.every((id) => listChecked.includes(id))) {
      setIsCheck(true)
      setListChecked(allId, true)
    } else {
      setIsCheck(false)
    }
  }, [JSON.stringify(listChecked), JSON.stringify(allId)])

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) setListChecked(allId, e.target.checked)
    else setListChecked([], e.target.checked)
  }

  return (
    <li className={style.li}>
      <div className={[style.p, classItemStyle].join(' ')}>
        <div className="d-flex ali-center">
          <label className={style.labelCheckbox}>
            <input
              id={inputId}
              checked={isChecked}
              type="checkbox"
              className={`checkbox line cursor-pointer ${style.checkbox} ${classCheckboxStyle}`}
              onChange={handleChangeCheckbox}
            />
          </label>
          <label htmlFor={inputId}>
            <div className={style.name}>
              <Translate value={titleCheckboxAll} />
            </div>
          </label>
        </div>
      </div>
    </li>
  )
}

ItemCheckAll.propTypes = {
  titleCheckboxAll: PropTypes.string.isRequired,
  allId: PropTypes.array.isRequired,
  listChecked: PropTypes.array.isRequired,
  setListChecked: PropTypes.func.isRequired,
}

export default ItemCheckAll
