import { useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import style from '../index.module.css'
import { selectFilter } from '../store/slice'
import { downloadInsuDetailMetrics } from '../store/thunk'
import TableDetailMetrics from './TableDetailMetrics'

const PanelDetailMetrics = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const filter = useSelector(selectFilter)

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadInsuDetailMetrics({
          OrganizationId: basicInfo.organizationId,
          Year: filter.year,
          Quarter: filter.quarter,
        })
      }
    >
      <TableDetailMetrics />
    </Panel>
  )
}

export default PanelDetailMetrics
