import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valDivMillion } from '../../../../../../utils/Value'
import { getOverviewIds } from '../../exportChart/store/thunk'
import { getMaxDate, getTopExportMarketsOfVN } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/overviewFishery/topMarketChart',
  initialState: {
    isLoading: true,
    dataChart: [],
    year: null,
    isLoadingList: true,
    listSelection: [],
    isFirstTimeRenderChart: true,
    currentSelection: null,
    isMaxDateLoading: false,
    maxYear: null,
  },
  reducers: {
    changeCurrentSelection: (state, action) => {
      state.currentSelection = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    setIsFirstTimeRenderChart: (state, action) => {
      state.isFirstTimeRenderChart = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopExportMarketsOfVN.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopExportMarketsOfVN.fulfilled, (state, action) => {
      state.isLoading = false
      const data =
        action.payload
          ?.map((item) => ({
            ...item,
            id: item.locationId,
            name: item.locationName,
            value: valDivMillion(item.totalValue),
          }))
          .reverse() || []
      state.dataChart = data
    })
    builder.addCase(getTopExportMarketsOfVN.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getOverviewIds.pending, (state) => {
      state.isLoadingList = true
      state.listSelection = []
    })
    builder.addCase(getOverviewIds.fulfilled, (state, action) => {
      state.isLoadingList = false
      const listSelection =
        action.payload?.map((item) => ({
          name: item.seafoodProductName,
          value: item.seafoodProductId,
        })) || []
      const defaultSelection = listSelection.length ? listSelection[0].value : 0
      state.currentSelection = state.isFirstTimeRenderChart
        ? defaultSelection
        : state.currentSelection
      state.listSelection = listSelection
    })
    builder.addCase(getOverviewIds.rejected, (state) => {
      state.isLoadingList = false
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.isMaxDateLoading = true
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.isMaxDateLoading = false
      const payloadData = action.payload
      state.maxYear = payloadData?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.isMaxDateLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectYear = (state) => state[slice.name].year
export const selectListSelection = (state) => state[slice.name].listSelection
export const selectCurrentSelection = (state) =>
  state[slice.name].currentSelection
export const selectMaxYear = (state) => state[slice.name].maxYear

export const { changeCurrentSelection, changeYear, setIsFirstTimeRenderChart } =
  slice.actions

register(slice.name, slice.reducer)
