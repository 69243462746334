import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../common/table/helper'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import {
  valByKeyWithDot,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../utils/Value'
import { typeRowGroup } from '../constant'
import { getId, is1D } from '../helper'
import {
  getLastestIndexThunk,
  getLastestPriceThunk,
  getLastestSectorThunk,
  getSectorInDepthThunk,
  getTopValueThunk,
  getTotalValueBySectorThunk,
} from './thunk'

export const keys = {
  SECTOR_IN_DEPTH: 'sectorInDepth',
  TOTAL_VALUE_BY_SECTOR: 'totalValueBySector',
  TOP_VALUE: 'topValue',
}

const initialState = {
  loading: {
    [keys.SECTOR_IN_DEPTH]: true,
    [keys.TOTAL_VALUE_BY_SECTOR]: true,
    [keys.TOP_VALUE]: true,
  },
  data: {
    timeRange: TIME_RANGES['1D'],
    sectorInDepthById: {},
    sectorInDepthId: [],
    activeRow: null,
    activeSector: '',
    totalValueBySector: [],
    topValueById: {},
    topValueId: [],
    lastActiveRow: null, // cache activeRow when change time range
    icbById: {},
    levelSort: [],
    sortSectorInDepthId: [],
    initSectorInDepthId: [],
  },
}

export const slice = createSlice({
  name: 'market/sectorInDepth',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // action table sector filter
    changeSectorInDepthId(state, action) {
      state.data.sectorInDepthId = action.payload
    },
    changeActiveRow(state, action) {
      if (action.payload && state.data.lastActiveRow) {
        state.data.activeRow = state.data.lastActiveRow
        state.data.lastActiveRow = null
      } else {
        state.data.activeRow = action.payload
      }
    },
    changeActiveSector(state, action) {
      state.data.activeSector = action.payload
    },
    // time range
    changeTimeRange(state, action) {
      state.data.timeRange = action.payload
      state.data.lastActiveRow = state.data.activeRow
      state.loading[keys.SECTOR_IN_DEPTH] = true
    },
    //table sector
    changeSectorInDepthById(state, action) {
      state.data.sectorInDepthById = {
        ...state.data.sectorInDepthById,
        ...action.payload,
      }
    },
    subscribeSector(state, action) {
      action.payload?.forEach((sector) => {
        state.data.sectorInDepthById[typeRowGroup.ICB + '-' + sector.icbId] = {
          ...state.data.sectorInDepthById[
            typeRowGroup.ICB + '-' + sector.icbId
          ],
          closePrice: sector.closeIndex,
          priceChange: sector.indexChange,
          percentPriceChange: sector.percentIndexChange,
          foreignNetValue:
            sector.foreignBuyValueTotal - sector.foreignSellValueTotal,
          foreignNetVolumn:
            sector.foreignBuyVolumeTotal - sector.foreignSellVolumeTotal,
          ...sector,
        }
      })
    },
    subscribeIndex(state, action) {
      const index = action.payload[0]
      state.data.sectorInDepthById[typeRowGroup.INDEX + '-' + index.groupId] = {
        ...state.data.sectorInDepthById[
          typeRowGroup.INDEX + '-' + index.groupId
        ],
        closePrice: index.closeIndex,
        totalVolume: index.totalMatchVolume,
        totalValue: index.totalMatchValue,
        foreignNetValue:
          index.foreignBuyValueTotal - index.foreignSellValueTotal,
        foreignNetVolumn:
          index.foreignBuyVolumeTotal - index.foreignSellVolumeTotal,
        ...index,
      }
    },
    changeIcbById(state, action) {
      state.data.icbById = action.payload
    },
    sortByLevel(state, action) {
      state.data.sortSectorInDepthId = getIdsFromProps(
        state.data.sortSectorInDepthId,
        state.data.sectorInDepthById,
        action.payload,
        state.data.initSectorInDepthId,
        3,
        state.data.levelSort,
      )
    },
    handleLevelSort(state, action) {
      state.data.levelSort = action.payload
    },
    handleSortSectorInDepthId(state, action) {
      state.data.sortSectorInDepthId = state.data.initSectorInDepthId =
        action.payload
    },
  },

  extraReducers: (builder) => {
    //get sector in depth
    builder.addCase(getSectorInDepthThunk.pending, (state) => {
      state.loading[keys.SECTOR_IN_DEPTH] = true
    })
    builder.addCase(getSectorInDepthThunk.fulfilled, (state, action) => {
      if (!is1D(state.data.timeRange)) {
        const sectorInDepthById = {}
        action.payload.forEach((item) => {
          if (item.rowGroup !== 'TotalICB') {
            sectorInDepthById[getId(item)] = item
          }
        })

        state.data.sectorInDepthById = sectorInDepthById
        state.data.sectorInDepthId = Object.keys(state.data.sectorInDepthById)
        state.data.lastActiveRow = state.data.activeRow
        state.loading[keys.SECTOR_IN_DEPTH] = false
      }
    })
    builder.addCase(getSectorInDepthThunk.rejected, (state, action) => {
      state.loading[keys.SECTOR_IN_DEPTH] = action.payload

      state.data.sectorInDepthById = initialState.data.sectorInDepthById
      state.data.sectorInDepthId = initialState.data.sectorInDepthId
      state.data.topValueById = initialState.data.topValueById
      state.data.topValueId = initialState.data.topValueId
      state.data.totalValueBySector = initialState.data.totalValueBySector
      state.data.activeSector = initialState.data.activeSector
    })
    //get lastest sector
    builder.addCase(getLastestSectorThunk.pending, (state) => {
      state.loading[keys.SECTOR_IN_DEPTH] = true

      state.data.topValueById = initialState.data.topValueById
      state.data.topValueId = initialState.data.topValueId
      state.data.totalValueBySector = initialState.data.totalValueBySector
      state.data.activeSector = initialState.data.activeSector
    })
    builder.addCase(getLastestSectorThunk.fulfilled, (state, action) => {
      if (is1D(state.data.timeRange)) {
        const sectorInDepthById = {}
        action.payload.forEach((item) => {
          sectorInDepthById[typeRowGroup.ICB + '-' + item.id] = {
            ...item,
            rowGroup: typeRowGroup.ICB,
            foreignNetValue:
              item.foreignBuyValueTotal - item.foreignSellValueTotal,
            foreignNetVolumn:
              item.foreignBuyVolumeTotal - item.foreignSellVolumeTotal,
          }
        })

        state.data.sectorInDepthById = sectorInDepthById
        state.loading[keys.SECTOR_IN_DEPTH] = false
      }
    })
    builder.addCase(getLastestSectorThunk.rejected, (state, action) => {
      state.loading[keys.SECTOR_IN_DEPTH] = action.payload

      state.data.sectorInDepthById = initialState.data.sectorInDepthById
      state.data.sectorInDepthId = initialState.data.sectorInDepthId
    })
    builder.addCase(getLastestPriceThunk.fulfilled, (state, action) => {
      if (is1D(state.data.timeRange)) {
        action.payload.forEach((item) => {
          state.data.sectorInDepthById[typeRowGroup.ICB + '-' + item.id] = {
            ...item,
            ...state.data.sectorInDepthById[typeRowGroup.ICB + '-' + item.id],
            foreignNetValue:
              item.foreignBuyValueTotal - item.foreignSellValueTotal,
            foreignNetVolumn:
              item.foreignBuyVolumeTotal - item.foreignSellVolumeTotal,
          }
        })
      }
    })
    builder.addCase(getLastestIndexThunk.fulfilled, (state, action) => {
      if (is1D(state.data.timeRange)) {
        action.payload.forEach((item) => {
          state.data.sectorInDepthById[typeRowGroup.INDEX + '-' + item.id] = {
            rowGroup: typeRowGroup.INDEX,
            ...item,
            name: item.code,
            foreignNetValue:
              item.foreignBuyValueTotal - item.foreignSellValueTotal,
            foreignNetVolumn:
              item.foreignBuyVolumeTotal - item.foreignSellVolumeTotal,
          }
        })
      }
    })
    //get total value by sector
    builder.addCase(getTotalValueBySectorThunk.pending, (state) => {
      state.data.totalValueBySector = initialState.data.totalValueBySector
      state.loading[keys.TOTAL_VALUE_BY_SECTOR] = true
    })
    builder.addCase(getTotalValueBySectorThunk.fulfilled, (state, action) => {
      state.data.totalValueBySector = action.payload
      state.loading[keys.TOTAL_VALUE_BY_SECTOR] = false
    })
    builder.addCase(getTotalValueBySectorThunk.rejected, (state, action) => {
      if (action.payload) {
        state.loading[keys.TOTAL_VALUE_BY_SECTOR] = false
      }
    })
    //get top value
    builder.addCase(getTopValueThunk.pending, (state) => {
      state.loading[keys.TOP_VALUE] = true
    })
    builder.addCase(getTopValueThunk.fulfilled, (state, action) => {
      state.data.topValueById = keyBy(
        action.payload.map((item, index) => {
          return {
            ...item,
            index: index + 1,
            closePriceAdjusted: valDivThousand(item.closePriceAdjusted),
            percentPriceChange: valToPercent(
              item.percentPriceChange,
              false,
              true,
            ),
            totalValue: valDivMillion(item.totalValue),
            localIndividual: valDivMillion(item.localIndividual),
            localInstitution: valDivMillion(item.localInstitution),
            foreign: valDivMillion(item.foreign),
            proprietary: valDivMillion(item.proprietary),
          }
        }),
        'organizationId',
      )
      state.data.topValueId = Object.keys(state.data.topValueById)
      state.loading[keys.TOP_VALUE] = false
    })
    builder.addCase(getTopValueThunk.rejected, (state, action) => {
      state.data.topValueById = initialState.data.topValueById
      state.data.topValueId = initialState.data.topValueId
      state.loading[keys.TOP_VALUE] = action.payload
    })
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectSectorInDepthId = (state) =>
  state[slice.name].data.sectorInDepthId
export const selectSectorInDepthCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.sectorInDepthById[id], attr)
export const selectSectorInDepthById = (state) =>
  state[slice.name].data.sectorInDepthById
export const selectActiveRow = (state) => state[slice.name].data.activeRow

export const selectActiveSector = (state) => state[slice.name].data.activeSector

export const selectTimeRange = (state) => state[slice.name].data.timeRange

export const selectTotalValueBySector = (state) =>
  state[slice.name].data.totalValueBySector

export const selectTopValueId = (state) => state[slice.name].data.topValueId
export const selectTopValueCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.topValueById[id], attr)
export const selectTopValueById = (state) => state[slice.name].data.topValueById

export const selectLastActiveRow = (state) =>
  state[slice.name].data.lastActiveRow

export const selectIcbById = (state) => state[slice.name].data.icbById
export const selectSortSectorInDepthId = (state) =>
  state[slice.name].data.sortSectorInDepthId

export const {
  resetStore,
  changeSectorInDepthId,
  changeActiveRow,
  changeTimeRange,
  changeActiveSector,
  changeSectorInDepthById,
  subscribeSector,
  subscribeIndex,
  changeIcbById,
  sortByLevel,
  handleLevelSort,
  handleSortSectorInDepthId,
} = slice.actions

register(slice.name, slice.reducer)
