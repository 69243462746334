import { FORMAT, formatDateTime } from '../../../../utils/Datetime'

export const convertArr = (data) => {
  const result = data.map((item) => {
    const modifiedItem = Object.assign({}, item)
    Object.keys(item).forEach((keyName) => {
      if (keyName !== 'tradingDateId') {
        if (keyName.includes('Sell')) {
          modifiedItem[keyName] = modifiedItem[keyName]
            ? (Math.abs(modifiedItem[keyName]) * -1) / 1000000000
            : 0
        } else {
          modifiedItem[keyName] = modifiedItem[keyName]
            ? modifiedItem[keyName] / 1000000000
            : 0
        }
      } else {
        modifiedItem.time = formatDateTime(item.tradingDateId, FORMAT.DATE)
      }
    })
    return modifiedItem
  })
  return result
}
