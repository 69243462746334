import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Right } from '../../../../../common/news/detail/right'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../../../constants/routes'
import { selectNewsOther, selectNewsOtherLoading } from '../../store/slice'
import { getNewsOther } from '../../store/thunk'

export const RightContent = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectNewsOtherLoading)
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectNewsOther)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const newsId = params.get('newsId')

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getNewsOther({
          OrganizationId: basicInfo.organizationId,
          NewsId: newsId,
        }),
      )
    }
  }, [basicInfo.organizationId, newsId])

  const onAction = (newsId) => () => {
    navigate(
      FEATURES.corporate.components['news-events'].components['news-detail']
        .route,
      basicInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <Right
      title={'corporate.news.OTHER_COMPANY_NEWS'}
      data={data}
      isLoading={isLoading}
      onAction={onAction}
      hasTag
    />
  )
}
