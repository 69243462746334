import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import style from '../style.module.css'
import { getTab, handleTab } from './store/slice'
import { TAB_COMPONENT_DATA } from './table/constants'

const FilterGroup = () => {
  const dispatch = useDispatch()

  const tab = useSelector(getTab)

  const onChangeTab = (keyTab) => {
    dispatch(handleTab(keyTab))
  }

  return (
    <div className="top-info">
      <Title title={'economy.productionAndConsumption.production.PRODUCTION'} />
      <VerticalLine mr={15} ml={15} styleProps={{ height: 20 }} />
      <div className={style.tabLinkContainer}>
        {Object.keys(TAB_COMPONENT_DATA).map((keyTab) => (
          <div key={keyTab} onClick={() => onChangeTab(keyTab)}>
            <Span
              className={`${
                tab === keyTab ? style.activeTab : style.normalTab
              } ${style.tab}`}
            >
              <Translate value={TAB_COMPONENT_DATA[keyTab].title} />
              {tab === keyTab && <div className={`${style.footerTab}`} />}
            </Span>
          </div>
        ))}
      </div>
    </div>
  )
}

export default FilterGroup
