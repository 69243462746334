import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class MasterProxy extends ProxyBase {
  getExchanges(params) {
    return this.get('GetExchanges', params)
  }

  getIcbs(params) {
    return this.get('GetICBs', params, null, true)
  }

  getTimeZone(params) {
    return this.get('GetTimeZones', params)
  }

  getStocks(params) {
    return this.get('GetStocks', params, null, true)
  }

  getFutures(params) {
    return this.get('GetFutures', params, null, true)
  }

  getCoveredWarrants(params) {
    return this.get('GetCoveredWarrants', params, null, true)
  }

  getEconomies(params) {
    return this.get('GetEconomies', params, null, true)
  }

  getBonds(params) {
    return this.get('GetAllBond', params, null, true)
  }

  getIndices(params) {
    return this.get('GetIndices', params, null, true)
  }

  getIndicesV2(params) {
    return this.get('GetIndicesV2', params, null, true)
  }

  getOrganizationsByIcb(params) {
    return this.get('GetOrganizationsByIcb', params, null, true)
  }

  getOrganizationsByExchange(params) {
    return this.get('GetOrganizationsByExchange', params)
  }

  getOrganizationsByExchangeAndICB(params) {
    return this.post(
      'GetOrganizationsByExchangeAndICB',
      params,
      {},
      'post',
      true,
    )
  }

  getOrganizationsByExchangeAndICBV2(params) {
    return this.get('GetOrganizationsByExchangeAndICBV2', params)
  }

  getAllFunctions(params) {
    return this.get('GetAllFunctions', params)
  }

  getMaxMinTable() {
    return this.get('GetMaxMinTable')
  }

  getAllFunds() {
    return this.get('GetAllFunds')
  }
}

export default new MasterProxy(ServiceProxyConfig.Common.Master.ServiceUrl)
