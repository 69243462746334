import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import CalendarPopper from '../../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../../common/calendar/headers/HeaderOneCalendarRange'
import { placeholderDateCalendar } from '../../../../../common/calendar/helper'
import InputDateCalendar from '../../../../../common/calendar/inputs/InputDateCalendar'
import { positionDropdown } from '../../../../../common/dropdown'
import style from '../../index.module.css'

export const SelectDate = ({ disabled, value, onChange }) => {
  const locale = useSelector((state) => state.i18n.locale)

  const handleChange = (value) => onChange(value || '')

  return (
    <div className="mr-8">
      <div
        className={`h-20 d-flex ali-center w-100 ${style.calendar}`}
        style={{ pointerEvents: disabled ? 'none' : '' }}
      >
        <CalendarPopper
          date={value ? new Date(value) : ''}
          handleChange={handleChange}
          customHeader={HeaderOneCalendarRange({
            startMonth: moment().subtract(1, 'month').month(),
            endMonth: moment().month() + 1,
            startYear: new Date().getFullYear(),
            endYear: new Date().getFullYear(),
            position: positionDropdown.BOTTOM_CENTER,
            marginTop: 1,
            maxHeightDropdown: 160,
          })}
          CustomInput={InputDateCalendar}
          placeholder={placeholderDateCalendar(locale)}
          startYear={new Date().getFullYear()}
          endYear={new Date().getFullYear()}
          minDate={moment().subtract(1, 'month').toDate()}
          maxDate={new Date()}
          excludeDateIntervals={[
            {
              start: new Date(
                new Date().setFullYear(new Date().getFullYear() - 30),
              ),
              end: moment().subtract(1, 'month').toDate(),
            },
            {
              start: moment().toDate(),
              end: new Date(
                new Date().setFullYear(new Date().getFullYear() + 30),
              ),
            },
          ]}
        />
        <i className="icon-date" style={{ pointerEvents: 'none' }} />
      </div>
    </div>
  )
}

SelectDate.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  handleChange: PropTypes.func,
}

SelectDate.defaultProps = {
  disabled: false,
  handleChange: () => {},
}
