import FundReportProxy from '../../proxies/fund/FundReportProxy'
import { ServiceBase } from '../ServiceBase'

export class FundPortfolioService extends ServiceBase {
  getIndicatorFinancialMapping(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getIndicatorFinancialMapping',
      params,
    )(() => FundReportProxy.getIndicatorFinancialMapping(params))
  }

  getIndicatorPeriodicalMapping(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getIndicatorPeriodicalMapping',
      params,
    )(() => FundReportProxy.getIndicatorPeriodicalMapping(params))
  }

  getDataFinancialBalanceSheet(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataFinancialBalanceSheet',
      params,
    )(() => FundReportProxy.getDataFinancialBalanceSheet(params))
  }

  getDataFinancialCashFlow(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataFinancialCashFlow',
      params,
    )(() => FundReportProxy.getDataFinancialCashFlow(params))
  }

  getDataFinancialIncomeStatement(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataFinancialIncomeStatement',
      params,
    )(() => FundReportProxy.getDataFinancialIncomeStatement(params))
  }

  getDataFinancialNotes(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataFinancialNotes',
      params,
    )(() => FundReportProxy.getDataFinancialNotes(params))
  }

  getDataPeriodicalAssetReport(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataPeriodicalAssetReport',
      params,
    )(() => FundReportProxy.getDataPeriodicalAssetReport(params))
  }

  getDataPeriodicalOtherReport(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataPeriodicalOtherReport',
      params,
    )(() => FundReportProxy.getDataPeriodicalOtherReport(params))
  }

  getDataPeriodicalPortfolioReport(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataPeriodicalPortfolioReport',
      params,
    )(() => FundReportProxy.getDataPeriodicalPortfolioReport(params))
  }

  getDataPeriodicalProfitLossReport(params) {
    return this.applyMemoryCache(
      'FundReportProxy.getDataPeriodicalProfitLossReport',
      params,
    )(() => FundReportProxy.getDataPeriodicalProfitLossReport(params))
  }

  downloadFinancialReportExcel(params) {
    return this.getFileDownload(() =>
      FundReportProxy.downloadFinancialReportExcel(params),
    )
  }

  downloadPeriodicalReportExcel(params) {
    return this.getFileDownload(() =>
      FundReportProxy.downloadPeriodicalReportExcel(params),
    )
  }
}

export default new FundPortfolioService()
