export const chartFillColor = {
  rT0230: '#45b29d',
  rT0249: '#facc5c',
}

export const chartBarKeys = ['rT0230']

export const chartLineKeys = ['rT0249']

export const chartMappingDisplayName = {
  rT0230: 'sector.financialAnalysis.bank.capitalStructure.TIER_ONE_CAPITAL',
  rT0249: 'sector.financialAnalysis.bank.capitalStructure.TIER_TOTAL_ASSET',
}

export const chartFormat = {
  rT0230: 10 ** 9,
  rT0249: 0.01,
}
