import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextNote } from '../../../../common/textNote'
import { TABLE_TABS } from '../constants'
import {
  changeFilter,
  selectAssets,
  selectFilters,
  selectTableTab,
} from '../store/slice'
import { StockCode } from './StockCode'
import { TimeRange } from './TimeRange'

export const Filters = () => {
  const dispatch = useDispatch()

  const tableTab = useSelector(selectTableTab)
  const filter = useSelector(selectFilters)
  const assets = useSelector(selectAssets)

  // Actions
  const handleChangeFilter = (value) => {
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  useEffect(() => {
    if (tableTab === TABLE_TABS.DETAIL && !filter.assetId && assets.length) {
      handleChangeFilter({ assetId: assets[0].value })
    }
  }, [tableTab, assets.length])

  return (
    <div className="d-flex ali-center mb-8">
      {tableTab === TABLE_TABS.DETAIL && (
        <StockCode value={filter.assetId} onChange={handleChangeFilter} />
      )}
      <TimeRange
        timeRange={filter.period}
        month={filter.month}
        year={filter.year}
        onChange={handleChangeFilter}
      />
      <TextNote value="fund.fundCenter.holding.TABLE_NOTE" isI18n />
    </div>
  )
}
