import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import {
  selectBasicInfo,
  selectPriceData,
} from '../../../../common/topInfo/stockTopInfo/store/slice'
import style from '../index.module.css'
import { ChartComponent } from './ChartComponent'
import { listTab, pricePerformanceFormatValue } from './constants'
import {
  changeTimeRange,
  selectData,
  selectLoading,
  subscribePricePerformance,
} from './store/slice'
import { getDeepTransactionPricePerformance } from './store/thunk'

export const PricePerformance = ({ width, height, setDateType }) => {
  const chartContainerRef = useRef()
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const priceData = useSelector(selectPriceData)
  const isLoading = useSelector(selectLoading)

  // Use state
  const [tabActive, setTabActive] = useState(listTab[0])

  // Get data
  const getData = useMemo(() => {
    return data.map((item) => ({
      ...item,
      referencePrice:
        priceData?.priceInfo?.referencePrice /
          pricePerformanceFormatValue.referencePrice || item.referencePrice,
    }))
  }, [data, priceData])

  // Actions
  const onChangeTab = (item) => {
    dispatch(changeTimeRange(item.value))
    setTabActive(item)
    setDateType(item)
  }

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getDeepTransactionPricePerformance({
          OrganizationId: basicInfo.organizationId,
          TimeRange: tabActive.value,
        }),
      )
    }
  }, [tabActive.value, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className={`w-100 ${style.pb15px} d-flex justify-content-end`}>
            <DispatchActionTab
              data={listTab}
              activeTab={tabActive.value}
              onChangeTab={onChangeTab}
              itemStyle={{ fontWeight: 500, fontSize: 10 }}
            />
          </div>
          {size.height && (
            <div
              id="pricePerformance"
              style={{ height: `calc(100% - ${size.height}px)` }}
            >
              {isLoading ? (
                <Loading />
              ) : getData && getData.length > 0 ? (
                <div
                  ref={chartContainerRef}
                  className="h-100 position-relative"
                >
                  <div>
                    <ChartComponent
                      data={getData}
                      ticker={basicInfo.ticker}
                      width={width}
                      height={height - size.height}
                      chartContainerRef={chartContainerRef}
                      dateType={tabActive.title}
                    />
                  </div>
                </div>
              ) : (
                <NoData />
              )}
              <Realtime
                channel={ChannelConfig.StockChannel + basicInfo.organizationId}
                action={subscribePricePerformance}
              >
                <></>
              </Realtime>
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

PricePerformance.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setDateType: PropTypes.func.isRequired,
}
