import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { BANK_TAB } from './constant'
import DepositDetailMetrics from './deposit/detailMetrics'
import { downloadBankDepositDetailMetrics } from './deposit/store/thunk'
import style from './index.module.css'
import LoanDetailMetric from './loan/detailMetrics'
import { selectLoanSector } from './loan/store/slice'
import { downloadBankLoanDetailMetrics } from './loan/store/thunk'
import { selectBankTab, selectQuarter, selectYear } from './store/slice'

const PanelSix = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const bankTab = useSelector(selectBankTab)
  const basicInfo = useSelector(selectBasicInfo)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)
  const loanSector = useSelector(selectLoanSector)

  const downloadCsv = () => {
    const params = {
      OrganizationId: basicInfo.organizationId,
      Year: year,
      Quarter: quarter,
    }

    if (bankTab === BANK_TAB.LOAN) {
      return downloadBankLoanDetailMetrics({
        ...params,
        Indicator: loanSector,
      })
    } else {
      return downloadBankDepositDetailMetrics(params)
    }
  }

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <div className="h-100">
        {bankTab === BANK_TAB.LOAN ? (
          <LoanDetailMetric />
        ) : (
          <DepositDetailMetrics />
        )}
      </div>
    </Panel>
  )
}

export default PanelSix
