import { ConsensusTargetPrice } from '.'
import { Panel } from '../../../../common/panel'

export const PanelConsensusTargetPrice = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  return (
    <Panel
      title="corporate.peerValuation.consensus.CONSENSUS_TARGET_PRICE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <ConsensusTargetPrice width={width} height={height} />
    </Panel>
  )
}
