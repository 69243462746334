import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { List } from '../../../../../common/news/List'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../../../constants/routes'
import { ANNOUNCEMENT_TYPE } from '../../constants'
import { selectNewsEarningLoading, selectNewsEarnings } from '../../store/slice'
import { getListNewsEarning } from '../../store/thunk'

export const Earning = ({ numColumn, marginLeft, marginRight }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const { data = [] } = useSelector(selectNewsEarnings)
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const isLoading = useSelector(selectNewsEarningLoading)

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(
        getListNewsEarning({
          ICBId: sectorInfo.icbId,
          Page: 1,
          PageSize: 10,
          NewsCategoryIds: 0,
          NewsSourceId: 0,
        }),
      )
    }
  }, [locale, sectorInfo.icbId])

  const onMoreAction = () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-more'
      ].route,
      sectorInfo,
      '?announcementType=' + ANNOUNCEMENT_TYPE.EARNING,
    )
  }
  const onDetailAction = (newsId) => () => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report-detail'
      ].route,
      sectorInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <List
      title={'sector.sectorConstituents.newsReport.EARNING'}
      data={data}
      isLoading={isLoading}
      isNodata={data.length === 0}
      numColum={numColumn}
      marginLeft={marginLeft}
      marginRight={marginRight}
      onMoreAction={onMoreAction}
      onDetailAction={onDetailAction}
    />
  )
}

Earning.propTypes = {
  numColumn: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
}
