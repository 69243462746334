import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'

export const getRevenueBrokerageChartData = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/performance/revenueBrokerage/GET_REVENUE_BROKERAGE_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await FinancialAnalysisService.getPerformanceRevenueFromBrokerage(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
