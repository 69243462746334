import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { keyBy } from '../../utils/Common'
import { HandleClickOutside } from '../HandleClickOutside'
import { ScrollComponent } from '../ScrollComponent'
import Dropdown from '../dropdown'
import UseI18n from '../hooks/useI18n'
import { Input } from '../html/Input'
import { Span } from '../html/Span'
import TextEllipsis from '../textEllipsis'
import { TextEllipsisStaticLine } from '../textEllipsis/TextEllipsisStaticLine'
import { compareArrayEqual, getOptionsBySearch } from './helpers'
import style from './index.module.css'

export const SelectSearchMultiple = ({
  width,
  options,
  optionsBySearch,
  values,
  numberItemDisplay,
  isDisableUnSelect,
  isSearch,
  isOptionAll,
  isI18n,
  textEmpty,
  textAll,
  selectedName,
  renderOptionName,
  onSearch,
  onChange,
}) => {
  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)

  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState([])
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  // Get data
  const optionsByValue = keyBy(options, 'value')

  const getOptions = useMemo(() => {
    let data = options
    if (search.trim()) {
      data = optionsBySearch({ search, options })
    }
    if (numberItemDisplay > 0) {
      return data.slice(0, numberItemDisplay)
    } else {
      return data
    }
  }, [JSON.stringify(options), optionsBySearch, search])

  const getSelectedName = useMemo(() => {
    if (!selected.length) {
      return I18n.t(textEmpty)
    }

    const isNotAll =
      !!selected.length &&
      options.some((item) => !selected.includes(item.value))
    if (isOptionAll && !isNotAll) {
      return I18n.t(textAll)
    }

    if (selectedName) return selectedName

    if (!Object.keys(optionsByValue).length) {
      return ''
    }

    return selected
      .map((val) =>
        isI18n ? I18n.t(optionsByValue[val].name) : optionsByValue[val].name,
      )
      .join(', ')
  }, [JSON.stringify(selected), JSON.stringify(optionsByValue), locale])

  // Actions
  const handleSearch = (event) => {
    const value = event.target.value
    setSearch(value)
    onSearch && onSearch(value)
  }

  const handleChange = (value, checked) => {
    if (checked) {
      setSelected((old) => [...old, value])
      onChange([...selected, value])
    } else {
      if (isDisableUnSelect && !selected.includes(value)) return
      setSelected((old) => old.filter((val) => val !== value))
      onChange(selected.filter((val) => val !== value))
    }
  }

  const handleChangeAll = () => {
    if (
      compareArrayEqual(
        selected,
        getOptions.map((item) => item.value),
      )
    ) {
      setSelected([])
      onChange([])
    } else {
      const values = [
        ...selected,
        ...getOptions.map((item) => item.value),
      ].filter(
        (val, index, arr) =>
          arr.findIndex((arrVal) => arrVal === val) === index,
      )
      setSelected(values)
      onChange(values)
    }
  }

  const handleToggle = () => {
    setIsShowDropdown((prev) => !prev)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  // Use effect
  useEffect(() => {
    if (JSON.stringify(values) !== JSON.stringify(selected)) {
      setSelected(values)
    }
  }, [JSON.stringify(values)])

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox`}
      style={{ width, margin: 0 }}
    >
      <Span
        onClick={handleToggle}
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        className="search-inbox d-flex ali-center"
      >
        <TextEllipsisStaticLine
          val={getSelectedName}
          isI18n={false}
          // appendStyle={
          //   !selected.length
          //     ? { fontStyle: 'italic', opacity: 0.5, paddingRight: 4 }
          //     : {}
          // }
        />
      </Span>
      <button type="button" onClick={handleToggle}>
        <i
          className={`${
            !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
          } ${style.iconArrow}`}
        />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearchCompany}`}
              style={{ width }}
            >
              {isSearch && (
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${style.search}`}
                    placeholder={UseI18n('common.SEARCH')}
                    value={search}
                    onChange={handleSearch}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" style={{ fontSize: 8 }} />
                  </button>
                </div>
              )}
              <ScrollComponent
                autoHeight={true}
                autoHeightMax={253}
                autoHeightMin={30}
                verticalTrackWidth={5}
                horizontalTrackWidth={5}
              >
                <ul className="list-check">
                  {isOptionAll && (
                    <li key="key-all">
                      <a>
                        <label
                          className="d-flex ali-center"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox mr-8"
                            checked={
                              selected.length &&
                              compareArrayEqual(
                                selected,
                                getOptions.map((item) => item.value),
                              )
                            }
                            onChange={handleChangeAll}
                          />

                          <TextEllipsis
                            text="common.ALL"
                            zIndexTooltip={999}
                            isI18n={true}
                          />
                        </label>
                      </a>
                    </li>
                  )}
                  {getOptions.map((item, index) => {
                    return (
                      <li key={`key-${index}`}>
                        <a>
                          <label
                            className="d-flex ali-center"
                            style={{
                              cursor: 'pointer',
                              opacity:
                                isDisableUnSelect &&
                                !selected.includes(item.value)
                                  ? 0.5
                                  : 1,
                            }}
                          >
                            <input
                              type="checkbox"
                              className="checkbox mr-8"
                              checked={selected.includes(item.value) ?? false}
                              disabled={
                                isDisableUnSelect &&
                                !selected.includes(item.value)
                              }
                              onChange={(e) =>
                                handleChange(item.value, e.target.checked)
                              }
                            />
                            {renderOptionName ? (
                              renderOptionName(item)
                            ) : (
                              <TextEllipsis
                                text={item.name}
                                zIndexTooltip={999}
                                isI18n={isI18n}
                              />
                            )}
                          </label>
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

SelectSearchMultiple.defaultProps = {
  isI18n: false,
  textEmpty: 'common.ALL',
  textAll: 'common.ALL',
  optionsBySearch: getOptionsBySearch,
}
