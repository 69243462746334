import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { getSecondsToOpenMarket } from '../helper'

const slice = createSlice({
  name: 'common/preOpenMarket',
  initialState: {
    secondsToOpenMarket: getSecondsToOpenMarket(),
  },
  reducers: {
    changeSecondsToOpenMarket: (state, action) => {
      state.secondsToOpenMarket = action.payload
    },
  },
})

export const selectSecondsToOpenMarket = (state) =>
  state[slice.name].secondsToOpenMarket

export const { changeSecondsToOpenMarket } = slice.actions

register(slice.name, slice.reducer)
