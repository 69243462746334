import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import LinkTicker from '../../../common/dropdownLink/LinkTicker'
import { EMPTY_VALUE } from '../../../constants/Common'
import { formatVal, valDivBillion, valToPercent } from '../../../utils/Value'
import { textByStatus } from './constants'
import { selectAssociateValue } from './store/slice'

const TableCell = ({ val, colId }) => {
  const organizationId = useSelector(
    selectAssociateValue(colId, 'organizationId'),
  )

  const renderData = () => {
    let renderItem = <div>{val}</div>

    switch (colId) {
      case 1:
        renderItem =
          organizationId && organizationId !== EMPTY_VALUE ? (
            <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
          ) : (
            <div>{val}</div>
          )
        break
      case 2:
        const text = textByStatus[val] || EMPTY_VALUE
        renderItem = (
          <div>
            <Translate value={text} />
          </div>
        )
        break
      case 3:
        renderItem = <div>{formatVal(valDivBillion(val), 2)}</div>
        break
      case 4:
        renderItem = <div>{formatVal(valDivBillion(val), 2)}</div>
        break
      case 5:
        renderItem = <div>{valToPercent(val)}</div>
        break

      default:
        break
    }

    return renderItem
  }
  return renderData()
}

export default TableCell
