import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { useLocation } from 'react-router'
import { DEFAULT_ORGANIZATION_ID } from '../../../../../../configs/Common'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { COM_GROUP } from '../../../../../constants/Group'
import { FEATURES } from '../../../../../constants/routes'
import CurrentState from '../../../../../utils/CurrentState'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { selectDataBondDetail } from '../generalSetting/store/slice'
import TableHeader from './TableHeader'
import { groupColumn, valueTab } from './constants'
import {
  selectData,
  selectDataValue,
  selectIds,
  selectLoading,
  selectTabActive,
} from './store/slice'

const Content = ({ width }) => {
  const ids = useSelector(selectIds)
  const data = useSelector(selectData)

  const locale = useSelector((state) => state.i18n.locale)
  const loading = useSelector(selectLoading)
  const activeTab = useSelector(selectTabActive)
  const bondDetail = useSelector(selectDataBondDetail)

  const location = useLocation()

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  const handleOpenNewTab = (route, headerSearch) => {
    CurrentState.addRoute({
      key: location.pathname,
      data: {
        route: route,
        disableRecentVisited: true,
        headerSearch,
      },
    })

    window.open(route, '_blank')
  }

  if (loading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }
  return (
    <Table
      ids={ids}
      columnDraggable={false}
      getDataFromRedux={selectDataValue}
      isLoading={loading}
      rowDraggable={false}
      renderHeader={(stateSort, sortColumn) =>
        renderThead(groupColumn(activeTab), stateSort, sortColumn)
      }
      schema={groupColumn(activeTab).map((item, index) => {
        const title = I18n.t(item.title)
        const colId = item.key
        const result = {
          colId,
          title,
        }
        switch (index) {
          case 0:
            return {
              ...result,
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <div style={{ width: `${width * 0.15}px` }}>
                      <Span>
                        <TextEllipsis
                          text={formatDateTime(value, FORMAT.DATE, locale)}
                          isI18n={false}
                        />
                      </Span>
                    </div>
                  </td>
                )
              },
            }
          case 1:
            return {
              ...result,
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td>
                    <div style={{ width: `${width * 0.65}px` }}>
                      <Span>
                        <TextEllipsis text={value} isI18n={false} />
                      </Span>
                    </div>
                  </td>
                )
              },
            }
          case 2:
            return {
              ...result,
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td>
                    <Span
                      style={{
                        color: '#40ABFF',
                        textDecorationLine: 'underline',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        activeTab === valueTab.new
                          ? handleOpenNewTab(
                              `${
                                FEATURES.corporate.components['news-events']
                                  .components['news-detail'].route
                              }?organizationId=${
                                bondDetail?.organizationId ??
                                DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE]
                              }&newsId=${data?.[rowId]?.newsId}`,
                              {
                                organizationId:
                                  bondDetail?.organizationId ??
                                  DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                                newsId: data?.[rowId]?.newsId,
                              },
                            )
                          : window.open(value, 'blank')
                      }}
                    >
                      {I18n.t('bond.bondSecurity.information.table.view')}
                    </Span>
                  </td>
                )
              },
            }
          default:
            return {}
        }
      })}
      stickyFirstColumn={false}
      isPagination={false}
      hasFooter={false}
      resizable={false}
    />
  )
}

export default Content
