import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { Table } from '../../../../../../common/table'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../../utils/Value'
import { selectQuarter, selectYear } from '../../store/slice'
import {
  selectDataDetailMetrics,
  selectDetailMetricsTickers,
  selectLoadingDetailMetrics,
  selectReCalcWiths,
} from '../store/slice'
import { getBankDepositDetailMetrics } from '../store/thunk'
import FirstColumnLevelTwo from './FirstColumnLevelTwo'
import { ROW_IDS } from './constant'
import { getDepositRowKeyByValue } from './helper'

const DepositDetailMetrics = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const topTickers = useSelector(selectDetailMetricsTickers)
  const loading = useSelector(selectLoadingDetailMetrics)
  const reCalcWidths = useSelector(selectReCalcWiths)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getBankDepositDetailMetrics({
          OrganizationId: basicInfo.organizationId,
          Year: year,
          Quarter: quarter,
        }),
      )
    }
  }, [year, quarter, basicInfo.organizationId, locale])

  const tickers = [
    basicInfo.ticker || basicInfo.organizationShortName,
    ...topTickers.filter((ticker) => ticker !== basicInfo.ticker),
  ]

  return (
    <div className="h-100">
      {loading ? (
        <Loading />
      ) : tickers.length > 1 ? (
        <Table
          ids={Object.values(ROW_IDS)}
          getDataFromRedux={selectDataDetailMetrics}
          isLoading={loading}
          schema={['indicator', ...tickers].map((ticker, index) => {
            const colId = ticker
            return {
              colId,
              title:
                index === 0
                  ? 'corporate.businessModel.penetrationAnalysis.DETAIL_METRICS'
                  : ticker,
              isI18n: false,
              thStyle: {
                textAlign: index === 0 ? 'left' : 'right',
                minWidth: index === 0 ? 180 : 'unset',
              },
              tdStyle: {
                textAlign: index === 0 ? 'left' : 'right',
              },
              canCustomTd: index === 0,
              render: (val, rowId, { style, className }) => {
                switch (rowId) {
                  case ROW_IDS.deposit:
                  case ROW_IDS.casa:
                    return index === 0 ? (
                      <td
                        style={{ ...style, width: '100%', maxWidth: 0 }}
                        className={className}
                      >
                        <TextEllipsis
                          text={`corporate.businessModel.penetrationAnalysis.depositRow.${getDepositRowKeyByValue(
                            rowId,
                          )}`}
                        />
                      </td>
                    ) : (
                      formatVal(valDivBillion(val))
                    )

                  default:
                    return index === 0 ? (
                      <FirstColumnLevelTwo
                        text={`depositRow.${getDepositRowKeyByValue(rowId)}`}
                        existStyle={style}
                        className={className}
                      />
                    ) : (
                      valToPercent(val)
                    )
                }
              },
            }
          })}
          isLargeHead
          reCalcWidths={reCalcWidths}
          stickyFirstColumn
          stickyBgColor="#212833"
          hasFooter={false}
          hasTooltip={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default DepositDetailMetrics
