import BalanceOfPaymentProxy from '../../proxies/economy/BalanceOfPaymentProxy'
import { ServiceBase } from '../ServiceBase'

class BalanceOfPaymentService extends ServiceBase {
  getNameOrder(params) {
    return this.getData(() => BalanceOfPaymentProxy.getNameOrder(params))
  }

  getByTimeRange(params) {
    return this.getData(() => BalanceOfPaymentProxy.getByTimeRange(params))
  }

  getGrowthByTimeRange(params) {
    return this.getData(() =>
      BalanceOfPaymentProxy.getGrowthByTimeRange(params),
    )
  }

  getChangeByTimeRange(params) {
    return this.getData(() =>
      BalanceOfPaymentProxy.getChangeByTimeRange(params),
    )
  }

  getDownloadBalanceOfPayment(params) {
    return this.getFileDownload(() =>
      BalanceOfPaymentProxy.getDownloadBalanceOfPayment(params),
    )
  }
}

export default new BalanceOfPaymentService()
