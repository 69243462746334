import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { keySettingChart } from '../../../constants'
import { changeStyleSetting } from '../../../store/slice'
import { listPositionLabel } from './constants'
import SettingStyle from './SettingStyle'
import style from './style.module.css'
import useGetDataStyle from './useGetDataStyle'

const YAxisLabel = ({ positionYAxisLabel, changePositionYAxisLabel }) => {
  const dispatch = useDispatch()
  const { yAxisLabel, styleSetting } = useGetDataStyle()

  const handleChangeStyleSetting = (data) => {
    dispatch(
      changeStyleSetting({
        ...styleSetting,
        [keySettingChart.Y_AXIS_LABEL]: data,
      }),
    )
  }

  return (
    <div className="mb-8 mt-8">
      <div className={style.label}>
        <Translate value="financialChart.setting.Y_AXIS_LABEL" />
      </div>
      <SettingStyle
        style={yAxisLabel}
        setStyle={handleChangeStyleSetting}
        disablePosition
        fontSizeMax={16}
      />
      <div className="mt-8 d-flex ali-center">
        <ul className={`list-check ${style.radioCheckbox}`}>
          {listPositionLabel.map((item, index) => (
            <li key={index}>
              <label>
                <input
                  type="radio"
                  className={`radiobox ${style.radioBox}`}
                  value={positionYAxisLabel}
                  checked={positionYAxisLabel === item.value ? true : false}
                  onChange={() => changePositionYAxisLabel(item.value)}
                />
                <Translate value={item.name} />
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default YAxisLabel
