import { useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Realtime } from '../../../common/Realtime'
import { Table } from '../../../common/table'
import { FORMAT } from '../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../utils/Value'
import { selectIndicator } from '../summary/store/slice'
import CellHighLight from './CellHighLight'
import CellType from './CellType'
import { indicator } from './constants'
import {
  selectData,
  selectDataMatchingOrder,
  selectIds,
  selectLoadingTable,
  selectScrollId,
  subscribeMatchingOrder,
} from './store/slice'

const TableComponent = ({ setRowTimes }) => {
  const ids = useSelector(selectIds)
  const data = useSelector(selectData)
  const scrollId = useSelector(selectScrollId)
  const derivativeActive = useSelector(selectIndicator)
  const isLoading = useSelector(selectLoadingTable)

  const scrollToBottomCallback = () => {
    if (scrollId) {
      setRowTimes((old) => old + 1)
    }
  }

  return (
    <div className="h-100">
      {isLoading ? (
        <Loading />
      ) : data.length > 0 ? (
        <Table
          ids={ids}
          getDataFromRedux={selectDataMatchingOrder}
          schema={Object.keys(indicator).map((key, index) => {
            const title = `market.derivative.${key}`
            const colId = indicator[key]
            let result = {
              colId,
              title,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => formatVal(val),
            }

            if (index === 0) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'left',
                  textTransform: 'uppercase',
                },
                tdStyle: {
                  textAlign: 'left',
                },
                render: (val) => {
                  return <DateTimeSpan date={val} formatDate={FORMAT.TIME} />
                },
              }
            }
            if (index === 1) {
              return {
                ...result,

                render: (val) => <CellType val={val} />,
              }
            }
            if (index === 2) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={formatVal(val)}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataMatchingOrder}
                  />
                ),
              }
            }
            if (index === 3) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={formatVal(val)}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataMatchingOrder}
                  />
                ),
              }
            }
            if (index === 4) {
              result = {
                ...result,
                render: (val, rowId) => (
                  <CellHighLight
                    value={valToPercent(val)}
                    rowId={rowId}
                    keyColor={colId}
                    selectCell={selectDataMatchingOrder}
                  />
                ),
              }
            }

            return result
          })}
          stickies={{
            [indicator.TIME]: true,
            [indicator.TYPE]: true,
            [indicator.LAST_PRICE]: true,
          }}
          isLargeHead={true}
          hasFooter={false}
          isPagination={false}
          scrollToBottomCallback={scrollToBottomCallback}
        />
      ) : (
        <NoData />
      )}
      <Realtime
        channel={ChannelConfig.DerivativeChannel + derivativeActive?.futureId}
        action={subscribeMatchingOrder}
      >
        <></>
      </Realtime>
    </div>
  )
}

export default TableComponent
