import { createAsyncThunk } from '@reduxjs/toolkit'
import ChartingService from '../../../../core/services/common/ChartingService'
import ChartLayoutService from '../../../../core/services/common/ChartLayoutService'
import MasterService from '../../../../core/services/common/MasterService'
import IndicesService from '../../../../core/services/market/marketInDepth/IndicesService'

export const createChartLayout = createAsyncThunk(
  'common/tradingView/createChartLayout',
  async (data, { rejectWithValue }) => {
    const response = await ChartLayoutService.createChartLayout(data)
    if (response.success) {
      return response.data
    }
    rejectWithValue(response.errors)
  },
)

export const getChartLayout = createAsyncThunk(
  'common/tradingView/getChartLayout',
  async (data, { rejectWithValue }) => {
    const response = await ChartLayoutService.getChartLayout(data)
    if (response.success) {
      return response.data
    }
    rejectWithValue(response.errors)
  },
)

export const deleteChartLayout = createAsyncThunk(
  'common/tradingView/deleteChartLayout',
  async (data, { rejectWithValue }) => {
    const response = await ChartLayoutService.deleteChartLayout(data)
    if (response.success) {
      return response.data
    }
    rejectWithValue(response.errors)
  },
)

export const getSearchData = createAsyncThunk(
  'tradingView/getSearchData',
  async () => {
    const response = await Promise.allSettled([
      MasterService.getFutures(),
      ChartingService.getAllMacroEconomicIndicator(),
      MasterService.getCoveredWarrants(),
      IndicesService.getCommodities(),
      ChartingService.getAllMoreIndicator(),
    ])
    const [futures, economies, coveredWarrants, commodities, indicators] =
      response
    localStorage.setItem(
      'LIST_INDICATORS',
      JSON.stringify(
        indicators.status === 'fulfilled' ? indicators.value.data : [],
      ),
    )

    return {
      futures: futures.status === 'fulfilled' ? futures.value.data : [],
      economies: economies.status === 'fulfilled' ? economies.value.data : [],
      coveredWarrants:
        coveredWarrants.status === 'fulfilled'
          ? coveredWarrants.value.data
          : [],
      commodities:
        commodities.status === 'fulfilled' ? commodities.value.data : [],
    }
  },
)
