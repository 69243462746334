import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { FORMAT } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

export const CashDividendForm = ({ data }) => {
  return (
    <>
      <Span style={{ fontSize: 14 }} className={style.title}>
        <Translate
          value="market.event.cashDividendForm.TITLE"
          ticker={data.ticker}
          durationName={data.period}
          sourceDividendYear={data.dividendYear}
          valuePerShare={formatVal(data.valuePerShare, 0)}
        />
      </Span>
      <Span style={{ fontSize: 12, marginBottom: 12 }} className={style.time}>
        {data?.date && (
          <DateTimeSpan
            date={data.date}
            formatDate={FORMAT.NEWS_WITHOUT_TIME}
          />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.HEADER"
          organizationName={data.organizationName}
          durationName={data.period}
          sourceDividendYear={data.dividendYear}
        />
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.AN_DATE"
          className="mr-4"
        />
        {data?.date && (
          <DateTimeSpan date={data.date} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.EXRIGHT_DATE"
          className="mr-4"
        />
        {data?.exrightDate && (
          <DateTimeSpan date={data.exrightDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.RECORD_DATE"
          className="mr-4"
        />
        {data?.recordDate && (
          <DateTimeSpan date={data.recordDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.PAYOUT_DATE"
          className="mr-4"
        />
        {data?.exerciseDate && (
          <DateTimeSpan date={data.exerciseDate} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.cashDividendForm.EXERCISE_RATIO"
          exerciseRatio={valToPercent(data.exerciseRatio)}
          valuePerShare={formatVal(data.valuePerShare, 0)}
        />
      </Span>
    </>
  )
}
