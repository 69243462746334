import React from 'react'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Content from './Content'
import FilterGroup from './FilterGroup'

const Production = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <FilterGroup />
              {size.height && (
                <div
                  className={`summary-page`}
                  style={{
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                    position: 'relative',
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default Production
