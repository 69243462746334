import React from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import useGetICB from '../../../../common/masterData/useGetICB'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  formatVal,
  valDivBillion,
  valDivMillion,
  valToPercent,
} from '../../../../utils/Value'
import { DROPDOWN_COMPANY_OVERVIEW, colorArray } from '../../filter/constants'
import {
  selectDataFDF,
  selectDropdownCompanyOverviewValue,
  selectSetting,
} from '../../filter/store/slice'
import '../../preview/index.css'
import style from '../../preview/style.module.css'
import { LineChart } from '../LineChart'
import FinancialRatiosTable from './FinancialRatiosTable'
import { PriceAndVolumeChart } from './PriceAndVolumeChart'

const CompanyOverview = () => {
  const data = useSelector(selectDataFDF)
  const dropdownCompanyOverviewValue = useSelector(
    selectDropdownCompanyOverviewValue,
  )
  const setting = useSelector(selectSetting)
  const dataTopInfo = useSelector(selectBasicInfo)
  const { ICBs } = useGetICB()

  const getReportForm = () => {
    if (ICBs.length && data.dropdownCompareWithSector[0]) {
      return ICBs.find(
        (i) => i.parentICBId === data.dropdownCompareWithSector[0]?.icbId,
      ).icbName
    }
    return ''
  }

  const arrayCompanyOverviewChecked = [
    setting.companyProfile,
    setting.companyPosition,
    setting.businessStrategies,
    setting.financialRatios,
  ]
  const arrayStockData = [
    setting.stockData,
    setting.oneYear_PriceAndVolume,
    setting.dropdownCompanyOverview,
    setting.valuationHistory,
  ]

  const listStockData = [
    {
      title: 'corporate.companySnapshot.PRICE_VND',
      keyValue: 'closePriceAdjusted',
      format: (val) => formatVal(val, 0),
    },
    {
      title: 'corporate.companySnapshot.HIGHEST_52W',
      keyValue: 'max52WeekHighPriceAdjusted',
      format: (val) => formatVal(val, 0),
    },
    {
      title: 'corporate.companySnapshot.LOWEST_52W',
      keyValue: 'min52WeekLowPriceAdjusted',
      format: (val) => formatVal(val, 0),
    },
    {
      title: 'corporate.companySnapshot.OUTSTANDING_SHARE',
      keyValue: 'exchangeOutstandingShare',
      format: (val) => formatVal(valDivMillion(val)),
    },
    {
      title: 'corporate.companySnapshot.PERCENT_FOREIGN_OWNED',
      keyValue: 'foreignCurrentPercent',
      format: (val) => valToPercent(val),
    },
    {
      title: 'corporate.companySnapshot.FOREIGN_CURRENT_ROOM',
      keyValue: 'foreignRemainRoom',
      format: (val) => formatVal(val, 0),
    },
    {
      title: 'corporate.companySnapshot.MARKET_CAP_BILLION_VND',
      keyValue: 'rtD0005',
      format: (val) => formatVal(valDivBillion(val)),
    },
    {
      title: 'corporate.companySnapshot.DIVIDEND_YIELD',
      keyValue: 'rtD0045',
      format: (val) => valToPercent(val),
    },
  ]

  const changeTextDisplay = (text) => {
    return text.replace(
      `FONT-FAMILY: Arial; FONT-SIZE: 10pt;`,
      `font-weight: 400;`,
    )
  }

  return (
    <>
      <div className={`d-flex ${style.paddingTop8}`}>
        <div className={style.wGroup60}>
          {arrayCompanyOverviewChecked.some((e) => e === true) && (
            <div className={style.titleGroup}>
              <Translate value="corporate.companySnapshot.COMPANY_OVERVIEW" />
            </div>
          )}
          {setting.companyProfile && (
            <>
              <div className={style.titleContent}>
                <Translate value="corporate.companySnapshot.COMPANY_PROFILE" />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: changeTextDisplay(data.companyProfile),
                }}
              />
            </>
          )}
          {setting.companyPosition && (
            <>
              <div className={style.titleContent}>
                <Translate value="corporate.companySnapshot.COMPANY_POSITION" />
              </div>
              <div
                className={style.contentList}
                dangerouslySetInnerHTML={{
                  __html: changeTextDisplay(data.companyPosition),
                }}
              />
            </>
          )}
        </div>
        <div className={style.wGroup40}>
          {arrayStockData.some((e) => e === true) &&
          (Object.keys(data.stockData).length ||
            data.oneYear_PriceAndVolume.length) ? (
            <div className={style.titleGroup}>
              <Translate value="corporate.companySnapshot.STOCK_DATA" />
            </div>
          ) : null}

          {setting.stockData && Object.keys(data.stockData).length ? (
            <div className="mt-8">
              {listStockData.map((item) => (
                <div key={item.keyValue} className={style.spaceBetweenContent}>
                  <div className={style.textBlue}>
                    <Translate value={item.title} />
                  </div>
                  <div className={style.textValueSlender}>
                    {item.format(data.stockData[item.keyValue])}
                  </div>
                </div>
              ))}
            </div>
          ) : null}
          {data.oneYear_PriceAndVolume.length ? (
            <div style={{ height: 250, marginTop: 24 }}>
              <PriceAndVolumeChart
                data={data.oneYear_PriceAndVolume}
                width={290}
                height={250}
                keyXAxis="tradingDateId"
                lineKey="closePriceAdjusted"
                barKey="totalVolume"
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className={`d-flex ${style.paddingTop8}`}>
        <div className={style.wGroup60}>
          {setting.businessStrategies && (
            <>
              <div className={style.titleContent}>
                <Translate value="corporate.companySnapshot.BUSINESS_STRATEGIES" />
              </div>
              <div
                className={style.contentList}
                dangerouslySetInnerHTML={{
                  __html: changeTextDisplay(data.businessStrategies),
                }}
              />
            </>
          )}
        </div>
        <div className={style.wGroup40}>
          {setting.financialRatios && <FinancialRatiosTable />}
        </div>
      </div>
      <div
        className={`d-flex ${style.paddingTop8}`}
        style={{ paddingBottom: 24 }}
      >
        <div className={style.wGroup50l}>
          {setting.dropdownCompanyOverview && (
            <>
              {dropdownCompanyOverviewValue ===
              DROPDOWN_COMPANY_OVERVIEW.COMPARE_WITH_SECTOR ? (
                <>
                  {data.dropdownCompareWithSector.length ? (
                    <>
                      <div className={style.titleGroup}>
                        <Translate value="corporate.companySnapshot.COMPARE_WITH_SECTOR" />
                      </div>
                      <div style={{ height: 250 }}>
                        <LineChart
                          data={data.dropdownCompareWithSector}
                          width={350}
                          height={250}
                          fillColor={{
                            percentSectorChange: '#97ba53',
                            percentPriceChange: '#487eb9',
                          }}
                          leftKeys={[
                            'percentSectorChange',
                            'percentPriceChange',
                          ]}
                          xAxisKey="tradingDateId"
                          leftLabel="(%)"
                          margin={{ right: 25 }}
                          mappingDisplayName={{
                            percentSectorChange: dataTopInfo.ticker,
                            percentPriceChange: getReportForm(),
                          }}
                          reversed={true}
                        />
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {data.dropdownRevenueBreakdown.length ? (
                    <>
                      <div className={style.titleGroup}>
                        <Translate value="corporate.companySnapshot.REVENUE_BREAKDOWN" />
                      </div>
                      <div style={{ height: 250 }}>
                        (
                        <PieChartWithFooter
                          data={data.dropdownRevenueBreakdown.map(
                            (item, index) => ({
                              text: item.revenueTypeName,
                              value: item.percent,
                              color: colorArray[index],
                            }),
                          )}
                          height={250}
                          footerRight
                          themeDownloadPDF
                          pieChartProps={{ radius: 35 }}
                          footerProps={{
                            isOneColumn: true,
                            appendStyle: { gridGap: 8 },
                            itemStyle: {
                              paddingRight: 8,
                              fixedFontSize: 10,
                              paddingLeft: 5,
                            },
                            isDownTheLine: true,
                          }}
                          labelStyle={{ fontSize: 5 }}
                        />
                        )
                      </div>
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
        <div className={style.wGroup50r}>
          {setting.valuationHistory && data.valuationHistory.length ? (
            <>
              <div className={style.titleGroup}>
                <Translate value="corporate.companySnapshot.VALUATION_HISTORY" />
              </div>
              <div style={{ height: 250 }}>
                <LineChart
                  data={data.valuationHistory}
                  width={370}
                  height={250}
                  fillColor={{
                    closePriceAdjusted: '#97ba53',
                    rtD0022: '#487eb9',
                  }}
                  leftKeys={['closePriceAdjusted']}
                  rightKeys={['rtD0022']}
                  xAxisKey="tradingDateId"
                  leftLabel="corporate.companySnapshot.PRICE"
                  rightLabel="corporate.companySnapshot.PE1"
                  mappingDisplayName={{
                    closePriceAdjusted:
                      dataTopInfo.ticker +
                      I18n.t('corporate.companySnapshot.PRICE1'),
                    rtD0022: 'corporate.companySnapshot.PE',
                  }}
                />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

export default CompanyOverview
