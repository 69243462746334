import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { handleLineColor } from './helper'
import style from './index.module.css'
import {
  getCheckboxChecked,
  getIndexChecked,
  getLineChartColor,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from './store/slice'

const MAX_TICK = 20

const FirstColumnCell = ({ val, props, level, rowId, data, ids }) => {
  const dispatch = useDispatch()

  const checkboxChecked = useSelector(getCheckboxChecked)
  const lineChartColor = useSelector(getLineChartColor)
  const indexChecked = useSelector(getIndexChecked)

  const getStyle = (level) => {
    switch (level) {
      case 1:
        return { marginRight: 8 }
      case 2:
        return { marginRight: 32 }
      case 3:
        return { marginRight: 56 }
      default:
        return { marginRight: 8 }
    }
  }

  const handleCheckbox = (id) => {
    const idCheckboxChecked = Object.keys(checkboxChecked)
    const isChecked = idCheckboxChecked.includes(id)
    const newCheckboxChecked = isChecked
      ? idCheckboxChecked
          .filter((item) => item !== id)
          .reduce((prev, cur) => {
            return {
              ...prev,
              [cur]: checkboxChecked[cur],
            }
          }, {})
      : checkboxChecked.length > MAX_TICK
      ? checkboxChecked
      : {
          ...checkboxChecked,
          [id]: {
            id: data.sbvTotalLiquidityVNTypeId,
            isMoneySupply: data.isMoneySupply,
          },
        }

    changeIndexChecked(isChecked)
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(
        handleLineColor(
          id,
          lineChartColor,
          isChecked,
          data.sbvTotalLiquidityVNTypeName,
        ),
      ),
    )
  }

  const changeIndexChecked = (isChecked) => {
    const currentIndex = ids.findIndex((item) => item.id === rowId)
    let newIndexChecked = indexChecked
    if (isChecked) {
      newIndexChecked = indexChecked.filter((item) => item !== currentIndex)
    } else {
      newIndexChecked = [...indexChecked, currentIndex]
    }

    dispatch(handleIndexChecked(newIndexChecked))
  }

  return (
    <td {...props} className={style.firstCellColumn}>
      <div
        className={`d-flex align-center`}
        onClick={() => handleCheckbox(rowId)}
      >
        {level !== -1 && (
          <input
            type="checkbox"
            checked={Object.keys(checkboxChecked).includes(rowId)}
            readOnly
            className={`checkbox ${style.checkboxMinWidth} `}
            style={{ ...getStyle(level), cursor: 'pointer' }}
          />
        )}
        <TextEllipsisStaticLine
          appendStyle={{ color: level === -1 && '#fecf2f' }}
          val={val}
          isI18n={false}
        />
      </div>
      <div className={style.borderLeftMoneySupply} />
    </td>
  )
}

FirstColumnCell.propTypes = {
  data: PropTypes.object.isRequired,
  props: PropTypes.object,
  level: PropTypes.number,
  rowId: PropTypes.string.isRequired,
  val: PropTypes.string,
  ids: PropTypes.array.isRequired,
}

export default FirstColumnCell
