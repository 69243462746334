import { useDispatch } from 'react-redux'
import { dashboardTypes, screenTypes } from '../../constants'
import { changeDashboard } from '../../store/slice'
import { NewChartOrDashboardBtn } from './common/NewChartOrDashboardBtn'

const NewDashboardBtn = () => {
  const dispatch = useDispatch()

  const redirect = () => {
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.NEW_DASHBOARD,
        data: null,
      }),
    )
  }

  return (
    <NewChartOrDashboardBtn
      title={'financialChart.NEW_DASHBOARD'}
      renderIcon={() => (
        <i className="icon-plus-circle-line" style={{ fontSize: 14 }} />
      )}
      redirect={redirect}
    />
  )
}

export default NewDashboardBtn
