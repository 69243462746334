import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class InformationProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('BondNews', params)
  }
  getDatafileProxy(params) {
    return this.get('BondFiles', params)
  }

  // exportFileProxy(params) {
  //   return this.post('DownloadBondDetailCashFlow', params, {}, 'download')
  // }
}

export default new InformationProxy(
  ServiceProxyConfig.Bond.SecurityMasterCb.ServiceUrl,
)
