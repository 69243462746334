import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../loading'
import useGetIndices from '../masterData/useGetIndices'
import useGetStockHOHAUP from '../masterData/useGetStockHOHAUP'
import { Content } from './Content'
import { selectIsSearchDataLoading } from './store/slice'
import { getSearchData } from './store/thunk'

export const TradingView = (props) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsSearchDataLoading)
  const stocks = useGetStockHOHAUP()
  const indices = useGetIndices()
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getSearchData())
  }, [locale, props.PriceType, props.Id])

  if (isLoading || stocks.loading || indices.loading) {
    return <Loading />
  }

  return <>{<Content {...props} />}</>
}
