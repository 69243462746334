import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import { changeTickerIds, selectTickerIds } from '../store/slice'

const enterKey = 13

const TBodyOrganizationOrder = ({ value, colId }) => {
  const dispatch = useDispatch()

  const inputRef = useRef()
  const lastValue = useRef(value)

  const tickerIds = useSelector(selectTickerIds)

  const [isShowEdit, setIsShowEdit] = useState(false)
  const [valueInput, setValueInput] = useState(value)

  useEffect(() => {
    setValueInput(value)
  }, [value])

  const handleBlurInput = () => handleSubmit()

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      handleSubmit()
    }
  }

  const handleSubmit = () => {
    if (valueInput) {
      const thisIndex = +value - 1
      const newTickerIds = [...tickerIds]
      newTickerIds.splice(thisIndex, 1)
      newTickerIds.splice(+valueInput - 1, 0, colId)
      dispatch(changeTickerIds(newTickerIds))
    }
    hideEdit()
  }

  const handleChangeInput = (e) => {
    if (
      (e.target.value.match(/^[0-9]+$/) &&
        +e.target.value > 0 &&
        +e.target.value < tickerIds.length + 1) ||
      e.target.value === ''
    ) {
      lastValue.current = e.target.value
      setValueInput(e.target.value)
    } else setValueInput(lastValue.current)
  }

  const showEdit = () => {
    setIsShowEdit(true)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const hideEdit = () => setIsShowEdit(false)

  return (
    <>
      {isShowEdit ? (
        <div style={{ margin: '0px -8px' }}>
          <input
            ref={inputRef}
            onBlur={handleBlurInput}
            onKeyDown={handlePressEnter}
            onChange={handleChangeInput}
            className={style.input}
            value={valueInput}
            type="text"
          />
        </div>
      ) : (
        <div onDoubleClick={showEdit} className={style.pointer}>
          {value}
        </div>
      )}
    </>
  )
}

TBodyOrganizationOrder.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TBodyOrganizationOrder
