import { Translate } from 'react-redux-i18n'
import styles from './switch.module.css'

const Switch = ({ title, checked, disabled, onChange }) => {
  const onToggle = (e) => {
    if (!disabled) {
      e?.stopPropagation()
      onChange && onChange(!checked)
    }
  }

  const onClickText = (e) => {
    e?.stopPropagation()
    onToggle(e)
  }

  return (
    <div
      className="d-flex align-center ml-8"
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <div
        className={styles.switchDiv}
        style={{
          backgroundColor: checked ? '#43acff' : '#939393',
        }}
        onClick={onToggle}
      >
        <div
          className={styles.switchBtn}
          style={{
            left: checked ? 'calc(100% - 10px)' : 1,
            backgroundColor: '#FFFFFF',
          }}
        />
      </div>
      {title && (
        <span
          style={{ paddingLeft: 8 }}
          className="cursor-pointer"
          onClick={onClickText}
        >
          <Translate value={title} />
        </span>
      )}
    </div>
  )
}

export default Switch
