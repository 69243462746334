export const GROUP_COLUMNS = [
  {
    key: 'id',
    title: 'market.coveredWarrant.cwStatistics.overview.ORDER',
    index: 1,
  },
  {
    key: 'cwCode',
    title: 'market.coveredWarrant.cwStatistics.overview.TICKER',
    index: 2,
  },
  {
    key: 'activeStatusName',
    title: 'market.coveredWarrant.cwStatistics.overview.TRADING_STATUS',
    index: 3,
  },
  {
    key: 'issuer',
    title: 'market.coveredWarrant.cwStatistics.overview.ISSUER',
    index: 4,
  },
  {
    key: 'cwAssuredParent',
    title: 'market.coveredWarrant.cwStatistics.overview.CW_ASSURED',
    index: 5,
  },
  {
    key: 'optionTypeName',
    title: 'market.coveredWarrant.cwStatistics.overview.CW_TYPE',
    index: 6,
  },
  {
    key: 'adjustedExecutionRateCoveredWarrant',
    title: 'market.coveredWarrant.cwStatistics.overview.EXECUTIVE_RATIO',
    index: 7,
  },
  {
    key: 'adjustedExecutionRateUnderlying',
    title: '',
    index: 8,
  },
  {
    key: 'issuePrice',
    title: 'market.coveredWarrant.cwStatistics.overview.ISSUE_PRICE',
    index: 9,
  },
  {
    key: 'matchPrice',
    title: 'market.coveredWarrant.cwStatistics.overview.CW_PRICE',
    index: 10,
  },
  {
    key: 'percentPriceChange',
    title: 'market.coveredWarrant.cwStatistics.overview.PERCENT_CHANGE',
    index: 11,
  },
  {
    key: 'underlyingAssetParent',
    title: 'market.coveredWarrant.cwStatistics.overview.UNDERLYING_ASSET',
    index: 14,
  },
  {
    key: 'ticker',
    title: 'market.coveredWarrant.cwStatistics.overview.UNDERLYING_ASSET_CHILD',
    index: 15,
  },
  {
    key: 'adjustedExercisePrice',
    title: 'market.coveredWarrant.cwStatistics.overview.EXERCISE_PRICE',
    index: 16,
  },
  {
    key: 'avgPrice5Days',
    title: 'market.coveredWarrant.cwStatistics.overview.AVG_PRICE',
    index: 17,
  },
  {
    key: 'breakEvenPrice',
    title: 'market.coveredWarrant.cwStatistics.overview.BREAK_EVEN_PRICE',
    index: 18,
  },
  {
    key: 'currentPrice',
    title: 'market.coveredWarrant.cwStatistics.overview.CURRENT_PRICE',
    index: 19,
  },
  {
    key: 'premiumPercentage',
    title: 'market.coveredWarrant.cwStatistics.overview.PERCENT_PREMIUM',
    index: 21,
  },
  {
    key: 'duration',
    title: 'market.coveredWarrant.cwStatistics.overview.DURATION',
    index: 22,
  },
  {
    key: 'listingDateId',
    title: 'market.coveredWarrant.cwStatistics.overview.FIRST_TRADING_DATE',
    index: 23,
  },
  {
    key: 'lastTradingDateId',
    title: 'market.coveredWarrant.cwStatistics.overview.LAST_TRADING_DATE',
    index: 24,
  },
  {
    key: 'cwStatus',
    title: 'market.coveredWarrant.cwStatistics.overview.CW_STATUS',
    index: 25,
  },
  {
    key: 'closePrice1',
    title: 'market.coveredWarrant.cwStatistics.overview.CLOSE_PRICE',
    index: 12,
  },
  {
    key: 'prePercent',
    title:
      'market.coveredWarrant.cwStatistics.overview.PERCENT_CLOSE_PRICE_CHANGE',
    index: 13,
  },
  {
    key: 'closePrice2',
    title: 'market.coveredWarrant.cwStatistics.overview.CLOSE_PRICE_TSCS',
    index: 20,
  },
]

export const EXCHANGE_STATUS = {
  ALL: 0,
  STOP: 2,
  TRADING: 1,
}

export const DROP_LIST_EXCHANGE_STATUS = [
  {
    name: 'market.coveredWarrant.cwStatistics.ALL',
    value: EXCHANGE_STATUS.ALL,
  },
  {
    name: 'market.coveredWarrant.cwStatistics.overview.STOP',
    value: EXCHANGE_STATUS.STOP,
  },
  {
    name: 'market.coveredWarrant.cwStatistics.overview.TRADING',
    value: EXCHANGE_STATUS.TRADING,
  },
]

export const RECORD_NUM_PER_PAGE = 22

export const GROUP_ID_CONVERT = {
  HOSE: 11,
  HNX: 46,
  UPCOM: 47,
  OTC: 65,
}

export const tableId = 'overviewStatistics'
export const PAGE_NAVIGATION_HEIGHT = 20
export const DEFAULT_FILTER_HEIGHT_PERCENT = 0.4
