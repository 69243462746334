import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import TimeFrame from '../../common/TimeFrame'
import style from '../../common/common.module.css'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import { GDP_SECTOR_VALUE, TIME_FRAME_OPTIONS } from './constants'
import {
  getValueGrowthYoY,
  getYearlyQuarterly,
  handleCheckboxChecked,
  handleIsAllowCallApi,
  handleValueGrowthYoY,
  handleYearlyQuarterly,
} from './store/slice'

const SelectionGroup = () => {
  const dispatch = useDispatch()
  const { VALUE, GROWTH_YOY } = GDP_SECTOR_VALUE
  const { REAL } = HEADER_VALUE
  const RADIO_LIST = [
    {
      value: VALUE,
      label: 'economy.gdp.gdp.VALUE',
    },
    {
      value: GROWTH_YOY,
      label: 'economy.gdp.gdp.GROWTH_YOY',
    },
  ]

  const timeRange = useSelector(getYearlyQuarterly)
  const radioValue = useSelector(getValueGrowthYoY)
  const headerRadioValue = useSelector(getNominalRealValue)

  const onChangeTab = (timeRange) => {
    dispatch(handleYearlyQuarterly(timeRange.value))
    dispatch(handleCheckboxChecked([]))
    dispatch(handleIsAllowCallApi(true))
  }

  const handleChangeRadio = (radioValue) => {
    dispatch(handleValueGrowthYoY(radioValue))
    dispatch(handleCheckboxChecked([]))
    dispatch(handleIsAllowCallApi(true))
  }

  return (
    <div className="d-flex align-center" style={{ marginBottom: 8 }}>
      <TimeFrame
        timeRange={timeRange}
        onChangeTab={onChangeTab}
        timeFrameOption={TIME_FRAME_OPTIONS}
        styleProps={{ margin: 0 }}
      />
      {headerRadioValue === REAL && (
        <>
          <VerticalLine ml={16} mr={16} styleProps={{ height: 20 }} />
          {RADIO_LIST.map(({ value, label }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
                onClick={() => handleChangeRadio(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === radioValue}
                  onChange={() => handleChangeRadio(value)}
                />
                <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
                  <Translate value={label} />
                </Span>
              </div>
            )
          })}
        </>
      )}
    </div>
  )
}

export default SelectionGroup
