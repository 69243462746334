import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { quarters } from './constants'
import { changeQuarter, selectQuarter } from './store/slice'

export const QuarterSelectOption = () => {
  const dispatch = useDispatch()

  const quarter = useSelector(selectQuarter)

  const onChange = (val) => {
    dispatch(changeQuarter(val.value))
  }

  return (
    <DispatchActionTab
      data={Object.keys(quarters).map((key) => {
        return {
          title: I18n.t(`sector.sectorConstituents.overview.${key}`),
          value: quarters[key],
        }
      })}
      activeTab={quarter}
      onChangeTab={onChange}
    />
  )
}
