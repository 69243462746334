import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getMonthInDataRange } from '../../common/helper'
import { TYPE_CODE } from '../../cpiAndInflationTable/constants'
import { getDefaultCPIVal } from '../constants'
import { growthYearlyChart } from './thunk'

const slice = createSlice({
  name: 'economy/cpiAndInflation/growthYearly',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    cpiId: getDefaultCPIVal(TYPE_CODE.YoY),
    typeCodeChart: TYPE_CODE.YoY,
    yearArr: [],
  },
  reducers: {
    changeDataChart: (state, action) => {
      state.cpiId = action.payload
    },
    changeTypeCodeForYearlyChart: (state, action) => {
      state.typeCodeChart = action.payload
      state.cpiId = getDefaultCPIVal(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(growthYearlyChart.pending, (state) => {
      state.data = []
      state.origData = []
      state.yearArr = []
      state.isLoading = true
    })
    builder.addCase(growthYearlyChart.fulfilled, (state, action) => {
      const data = action.payload || []
      state.origData = data
      state.data = convertData(data)
      state.yearArr = [...getYearInDataRange(data)]
      state.isLoading = false
    })
    builder.addCase(growthYearlyChart.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigDataChart = (state) => state[slice.name].origData
export const selectCPIId = (state) => state[slice.name].cpiId
export const selectYearArr = (state) => state[slice.name].yearArr

export const { changeDataChart, changeTypeCodeForYearlyChart } = slice.actions
register(slice.name, slice.reducer)

const convertData = (data) => {
  const getMonthInData = getMonthInDataRange(data)

  const getMonthKeyFromRange = getMonthInData.map((item) => item.month)

  const getDataByMonth = (month) => {
    return data
      .filter((item) => item.month === month)
      .map((item) => ({
        value: item.value ?? 0,
        year: item.year ?? 0,
      }))
  }

  const getMonthValueFromRange = getMonthInData.map((item) => [
    ...getDataByMonth(item.month),
  ])

  const getDataKeyIdValueFromRange = getMonthValueFromRange.length
    ? getMonthValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.year]: e.value }
        }),
      )
    : []

  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getMonthKeyFromRange[i],
  }))
}

const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  return [...new Set(allYears)]
}
