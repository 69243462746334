import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DerivativeService from '../../../../../../core/services/market/DerivativeService'
import { handleExport } from '../../../../../utils/Export'

export const getProprietaryOverview = createAsyncThunk(
  'market/derivativeStatistics/proprietary/getProprietaryOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getProprietaryOverview(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProprietaryDetail = createAsyncThunk(
  'market/derivativeStatistics/proprietary/getProprietaryDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getProprietaryDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getProprietaryTicker = createAsyncThunk(
  'market/derivativeStatistics/proprietary/getProprietaryTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getProprietaryTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadProprietaryOverview = createAsyncThunk(
  'market/derivativeStatistics/proprietary/downloadProprietaryOverview',
  async (data) => {
    const response = await DerivativeService.downloadProprietaryOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadProprietaryDetail = createAsyncThunk(
  'market/derivativeStatistics/proprietary/downloadProprietaryDetail',
  async (data) => {
    const response = await DerivativeService.downloadProprietaryDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadProprietaryTicker = createAsyncThunk(
  'market/derivativeStatistics/proprietary/downloadProprietaryTicker',
  async (data) => {
    const response = await DerivativeService.downloadProprietaryTicker(data)
    handleExport(response.data, response.filename)
  },
)
