import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyToolHeaderSettings } from '../../../chart/constants'
import { getDataQueryPayloadParams } from '../../helpers/dashboardApiParamsAndResponseHelpers'
import { selectIndicatorsLatestPeriod } from '../store/slice'
import { getDataQuery } from '../store/thunk'

export const DataBySecurities = ({
  data,
  isHaveDataChartPreview,
  loading,
  loadingIndicatorLatestPeriod,
  onFormatData,
  setLoading,
}) => {
  const dispatch = useDispatch()

  const indicatorsLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const { viewType, securities, indicatorSelected, settings } = data.detail
  const { companies, economy, indices, sector } = securities
  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected

  useEffect(() => {
    const asyncFn = async () => {
      if (!loadingIndicatorLatestPeriod && !loading) {
        setLoading('Securities', true)

        const securitiesPayload = []

        if (companyIndicator.length && companies.length) {
          securitiesPayload.push({
            securitiesType: 'Companies',
            dataIds: companies,
            indicators: companyIndicator,
          })
        }

        if (indexIndicator.length && indices.length) {
          securitiesPayload.push({
            securitiesType: 'Indices',
            dataIds: indices,
            indicators: indexIndicator,
          })
        }

        if (sectorIndicator.length && sector.length) {
          securitiesPayload.push({
            securitiesType: 'Sector',
            dataIds: sector,
            indicators: sectorIndicator,
          })
        }

        if (economy.length) {
          securitiesPayload.push({
            securitiesType: 'Economy',
            dataIds: [],
            indicators: economy,
          })
        }

        if (!isHaveDataChartPreview && securitiesPayload.length) {
          const payload = getDataQueryPayloadParams(
            viewType,
            securitiesPayload,
            indicatorsLatestPeriod,
            settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
          )

          await dispatch(getDataQuery(payload))
            .unwrap()
            .then((data) => {
              onFormatData(data)
            })
        }

        setLoading('Securities', false)
      }
    }
    asyncFn()
  }, [
    loadingIndicatorLatestPeriod,
    JSON.stringify(indicatorSelected),
    JSON.stringify(securities),
  ])

  return <></>
}
