import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valueTab } from '../constants'
import {
  createBondThunk,
  getCreateBondListData,
  getCreditEvent,
  getDataBondInfo,
  getDataListBond,
  getDefaultBondId,
} from './thunk.js'

export const nameStateSecInfo = 'demo/valuation/secInfo'

const initialState = {
  listBond: [],
  BondIds: '',
  bondInfo: {},
  StatueSuccess: false,
  activeTab: valueTab.BOND_DEFAULT,
  couponInput: '',
  popupCredit: [],
  statusGetDefaultBondID: false,
  editlocale: false,
  data: [],
  bondId: null,
  isCreate: false,
  bondTicker: '',
}

export const slice = createSlice({
  name: nameStateSecInfo,
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateBondIds(state, actions) {
      state.BondIds = actions.payload
    },
    updateCreateBondId(state, actions) {
      state.bondId = actions.payload
    },
    updateBondTicker(state, actions) {
      state.bondTicker = actions.payload
    },
    updateStatusSuccess: (state, actions) => {
      state.StatueSuccess = actions.payload
    },
    updateCouponInput: (state, actions) => {
      state.couponInput = actions.payload
    },
    updateStatusGetDefaultId: (state, actions) => {
      state.statusGetDefaultBondID = actions.payload
    },
    updateStatusEditlocale: (state, actions) => {
      state.editlocale = actions.payload
    },
    changeActiveTab: (state, actions) => {
      state.activeTab = actions.payload
    },
    changeIsCreate: (state, actions) => {
      state.isCreate = actions.payload
    },
    resetBondInformation: (state, actions) => {
      state.bondInfo = {}
      state.StatueSuccess = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataListBond.pending, (state) => {})
    builder.addCase(getDataListBond.fulfilled, (state, action) => {
      state.listBond = action.payload
    })
    builder.addCase(getDataListBond.rejected, (state, action) => {})

    builder.addCase(getDataBondInfo.pending, (state) => {})
    builder.addCase(getDataBondInfo.fulfilled, (state, action) => {
      state.bondInfo = action.payload
      state.StatueSuccess = true
    })
    builder.addCase(getDataBondInfo.rejected, (state, action) => {})

    builder.addCase(getCreditEvent.pending, (state) => {})
    builder.addCase(getCreditEvent.fulfilled, (state, action) => {
      state.popupCredit = action.payload
    })
    builder.addCase(getCreditEvent.rejected, (state, action) => {})

    builder.addCase(getDefaultBondId.pending, (state) => {})
    builder.addCase(getDefaultBondId.fulfilled, (state, action) => {
      state.statusGetDefaultBondID = true
    })
    builder.addCase(getDefaultBondId.rejected, (state, action) => {})
    builder.addCase(getCreateBondListData.pending, (state) => {})
    builder.addCase(getCreateBondListData.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(getCreateBondListData.rejected, (state, action) => {})
    builder.addCase(createBondThunk.pending, (state) => {})
    builder.addCase(createBondThunk.fulfilled, (state, action) => {
      const { status, data, bond } = action.payload

      state.isCreate = status
      state.data = data

      if (bond?.bondId) {
        state.bondId = bond.bondId
        state.activeTab = valueTab.BOND_CREATED
        state.bondTicker = bond.bondTicker
      }
    })
    builder.addCase(createBondThunk.rejected, (state, action) => {})
  },
})

export const selectListBond = (state) => state[slice.name].listBond
export const selecEditlocale = (state) => state[slice.name].editlocale
export const selectStatusSuccess = (state) => state[slice.name].StatueSuccess
export const selecBondIds = (state) => state[slice.name]?.BondIds
export const selecBondInfo = (state) => state[slice.name].bondInfo
export const selectCouponInput = (state) => state[slice.name].couponInput
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectPopupCredit = (state) => state[slice.name].popupCredit
export const selectStatueDefaultId = (state) =>
  state[slice.name].statusGetDefaultBondID
export const selectCreateBondList = (state) => state[slice.name].data
export const selectCreateBondId = (state) => state[slice.name]?.bondId
export const selectCreateBondStatus = (state) => state[slice.name]?.isCreate
export const selectCreateBondTicker = (state) => state[slice.name]?.bondTicker

export const {
  resetStore,
  updateBondIds,
  updateStatusSuccess,
  updateCouponInput,
  updateStatusGetDefaultId,
  updateStatusEditlocale,
  changeActiveTab,
  updateCreateBondId,
  resetBondInformation,
  changeIsCreate,
  updateBondTicker,
} = slice.actions

register(slice.name, slice.reducer)
