import { useSelector } from 'react-redux'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Loading } from '../../../../common/loading'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FilterInputRatioItem } from './FilterInputRatioItem'
import { FilterOptionItem } from './FilterOptionItem'
import { FILTER_OPTIONS } from './constants'

export const FinancialRatioFilter = () => {
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)

  if (!comGroup) return <Loading />

  return FILTER_OPTIONS.map((option) =>
    option.field === 'type' ? (
      <FilterInputRatioItem key={option.field} data={option} />
    ) : (
      <FilterOptionItem key={option.field} data={option} comGroup={comGroup} />
    ),
  )
}
