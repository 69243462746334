import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundPortfolioProxy extends ProxyBase {
  getIndicatorPeriodicalMapping(params) {
    return this.get('GetIndicatorPeriodicalMapping', params)
  }

  getIndicatorFinancialMapping(params) {
    return this.get('GetIndicatorFinancialMapping', params)
  }

  getDataFinancialBalanceSheet(params) {
    return this.get('GetDataFinancialBalanceSheet', params)
  }

  getDataFinancialIncomeStatement(params) {
    return this.get('GetDataFinancialIncomeStatement', params)
  }

  getDataFinancialCashFlow(params) {
    return this.get('GetDataFinancialCashFlow', params)
  }

  getDataFinancialNotes(params) {
    return this.get('GetDataFinancialNotes', params)
  }

  getDataPeriodicalAssetReport(params) {
    return this.get('GetDataPeriodicalAssetReport', params)
  }

  getDataPeriodicalProfitLossReport(params) {
    return this.get('GetDataPeriodicalProfitLossReport', params)
  }

  getDataPeriodicalPortfolioReport(params) {
    return this.get('GetDataPeriodicalPortfolioReport', params)
  }

  getDataPeriodicalOtherReport(params) {
    return this.get('GetDataPeriodicalOtherReport', params)
  }

  downloadFinancialReportExcel(params) {
    return this.get('GetFinancialReportExcel', params, 'download')
  }

  downloadPeriodicalReportExcel(params) {
    return this.get('GetPeriodicalReportExcel', params, 'download')
  }
}
export default new FundPortfolioProxy(
  ServiceProxyConfig.Fund.FundProfile.FundReport.ServiceUrl,
)
