import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { LIST_TYPE, TIME_SELECT } from '../../constants'
import {
  getNetValueForeignChart,
  getNetValueIndividualChart,
  getNetValueInstitutionChart,
  getNetValueProprietaryChart,
} from './thunk'

const slice = createSlice({
  name: 'market/equityTrading/priceStatistics/netValue',
  initialState: {
    data: [],
    dateTime: TIME_SELECT.ONE_WEEK,
    type: LIST_TYPE.foreign.value,
    typeName: LIST_TYPE.foreign.display,
    listType: LIST_TYPE,
    loading: true,
  },
  reducers: {
    changeType: (state, action) => {
      state.type = action.payload
    },
    changeTypeName: (state, action) => {
      state.typeName = action.payload
    },
    changeDateTime: (state, action) => {
      state.dateTime = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetValueForeignChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueForeignChart.fulfilled, (state, action) => {
      state.loading = false
      state.data = [...action.payload.data].sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
    })
    builder.addCase(getNetValueForeignChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getNetValueIndividualChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueIndividualChart.fulfilled, (state, action) => {
      state.loading = false
      state.data = [...action.payload.data].sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
    })
    builder.addCase(getNetValueIndividualChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getNetValueInstitutionChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueInstitutionChart.fulfilled, (state, action) => {
      state.loading = false
      state.data = [...action.payload.data].sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
    })
    builder.addCase(getNetValueInstitutionChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getNetValueProprietaryChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueProprietaryChart.fulfilled, (state, action) => {
      state.loading = false
      state.data = [...action.payload.data].sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
    })
    builder.addCase(getNetValueProprietaryChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const { changeType, changeDateTime, changeTypeName } = slice.actions
export const selectType = (state) => state[slice.name].type
export const selectTypeName = (state) => state[slice.name].typeName
export const selectDateTime = (state) => state[slice.name].dateTime
export const selectListType = (state) => state[slice.name].listType
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
