import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectTickerTableFilter } from '../store/slice'
import { heightTh } from './TickerTable'

const TBodyFilter = ({ value, colId }) => {
  const tickerTableFilter = useSelector(selectTickerTableFilter)

  const tdRef = useRef()
  const trRef = useRef()
  const scrollRef = useRef()
  const isHightlight = useRef(false)

  useEffect(() => {
    scrollRef.current = document.querySelector(
      `.ticker-table-container .scrollbars div:first-child`,
    )
    trRef.current = Array.from(
      document.querySelectorAll(`.ticker-table-container tbody tr`),
    )?.find((element) => {
      return element.contains(tdRef.current)
    })
  }, [colId])

  useEffect(() => {
    if (
      tickerTableFilter.trim() &&
      tickerTableFilter.toLowerCase().trim() === value.toLowerCase().trim()
    ) {
      scrollRef.current.scroll({
        top: trRef.current?.offsetTop - heightTh, // offset top tr + height thead position fixed
      })
      trRef.current?.querySelectorAll('td').forEach((element) => {
        element.style.background = '#435b79' // active color row
      })
      isHightlight.current = true
    } else if (isHightlight.current) {
      trRef.current?.querySelectorAll('td').forEach((element) => {
        element.style.background = '#151b25' // default color table row
      })
      isHightlight.current = false
    }
  }, [tickerTableFilter])

  return <div ref={tdRef}>{value}</div>
}

TBodyFilter.propTypes = {
  value: PropTypes.string.isRequired,
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TBodyFilter
