import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isInValidValue } from '../../../../utils/Value'
import style from '../index.module.css'
import {
  changeValueDetailPopupIndicator,
  selectValueDetailPopupIndicator,
} from '../store/slice'

const ItemCheckbox = ({
  defaultCheck,
  keyCheckbox,
  valueCheckbox,
  textCheckbox,
  disabled,
  isError,
}) => {
  const dispatch = useDispatch()

  const valueStore = useSelector(selectValueDetailPopupIndicator(keyCheckbox))
  const value = valueStore || []

  useEffect(() => {
    if (defaultCheck && isInValidValue(valueStore)) {
      dispatch(
        changeValueDetailPopupIndicator({
          key: keyCheckbox,
          value: [...value, valueCheckbox],
        }),
      )
    }
  }, [keyCheckbox])

  const handleChange = () => {
    if (!disabled) {
      const newValue = [...value]
      if (!value.includes(valueCheckbox)) {
        newValue.push(valueCheckbox)
        dispatch(
          changeValueDetailPopupIndicator({
            key: keyCheckbox,
            value: newValue?.sort(),
          }),
        )
      } else {
        newValue.splice(newValue.indexOf(valueCheckbox), 1)
        dispatch(
          changeValueDetailPopupIndicator({
            key: keyCheckbox,
            value: newValue,
          }),
        )
      }
    }
  }

  const isCheck = value.includes(valueCheckbox)

  return (
    <div className={`d-flex ali-center ${style.radioContainer}`}>
      <span
        className={`d-flex ali-center cursor-pointer ${
          disabled ? style.disabled : ''
        }`}
        onClick={handleChange}
      >
        <input
          type="checkbox"
          className={`checkbox cursor-pointer ${style.checkbox2} ${
            isError ? style.checkboxError : ''
          }`}
          checked={isCheck}
          readOnly
        />
        {textCheckbox && <span>{textCheckbox}</span>}
      </span>
    </div>
  )
}

ItemCheckbox.propTypes = {
  defaultCheck: PropTypes.bool,
  keyCheckbox: PropTypes.string.isRequired,
  valueCheckbox: PropTypes.any.isRequired,
  textCheckbox: PropTypes.string,
  disabled: PropTypes.bool,
  isError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

ItemCheckbox.defaultProps = {
  defaultCheck: false,
  disabled: false,
  isError: false,
}

export default ItemCheckbox
