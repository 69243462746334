import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LIST_TYPE } from '../../../common/constants'
import {
  changeFullSecurities,
  selectSecurities,
} from '../../../popup/common/store/slice'
import {
  changeFullIndicator,
  selectIndicatorSelected,
} from '../../../popup/popupAddIndicator/store/slice'
import { VIEW_TYPE, keyToolHeaderSettings } from '../../constants'
import {
  changeChartTabActiveData,
  changeDataChartBySecurities,
  resetDataChart,
  resetDataChartEquation,
  selectChartTabActive,
  selectChartTabIndicatorSelected,
  selectChartTabSecurities,
  selectDataChartBySecurities,
  selectEquations,
  selectSettings,
  selectViewType,
  selectYAxisCustom,
} from '../../store/slice'
import { DataByEquations } from './DataByEquations'
import { DataByLatestPeriod } from './DataByLatestPeriod'
import { DataBySecurities } from './DataBySecurities'

export const SettingCreateChartData = ({ isEditChart }) => {
  const dispatch = useDispatch()

  const securities = useSelector(selectSecurities)
  const { companies, indices, sector, economy } = securities
  const indicatorSelected = useSelector(selectIndicatorSelected)

  const viewType = useSelector(selectViewType)
  const chartTabActive = useSelector(selectChartTabActive)
  const chartTabSecurities = useSelector(selectChartTabSecurities)
  const chartTabIndicatorSelected = useSelector(
    selectChartTabIndicatorSelected(),
  )
  const equations = useSelector(selectEquations)
  const dataChartBySecurities = useSelector(selectDataChartBySecurities)
  const listYAxisCustom = useSelector(selectYAxisCustom)
  const settings = useSelector(selectSettings)

  const [isFirstTimeInCreateChart, setIsFirstTimeInCreateChart] = useState(true)
  const [isFirstTimeEditChart, setIsFirstTimeEditChart] = useState(true)

  const setListYAxisCustom = () => {
    const dataKeys = []
    // Securities
    Object.keys(securities)
      .filter((key) => Object.values(LIST_TYPE).includes(key))
      .forEach((key) => {
        if (key === LIST_TYPE.ECONOMY) {
          securities[key].forEach((item) =>
            dataKeys.push(`${key}_${item.id}_${item.timeFrequency}`),
          )
        } else {
          securities[key].forEach((id) => dataKeys.push(`${key}_${id}_`))
        }
      })
    // Indicators
    Object.values(indicatorSelected).forEach((indicators) =>
      indicators.forEach((item) =>
        dataKeys.push(`${item.id}_${item.timeFrequency}`),
      ),
    )
    // Equations
    Object.values(equations).map((item) => dataKeys.push(item.dataKey))

    const newListYAxisCustom = Object.keys(listYAxisCustom).reduce(
      (obj, key) =>
        dataKeys.some((val) => key.includes(val))
          ? { ...obj, [key]: listYAxisCustom[key] }
          : obj,
      {},
    )

    dispatch(changeChartTabActiveData({ listYAxisCustom: newListYAxisCustom }))
  }

  useEffect(() => {
    dispatch(resetDataChart())
    dispatch(resetDataChartEquation())
  }, [viewType, chartTabActive])

  useEffect(() => {
    setIsFirstTimeEditChart(true)
  }, [
    settings[keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD],
    viewType,
    chartTabActive,
  ])

  useEffect(() => {
    if (
      JSON.stringify(indicatorSelected) !==
      JSON.stringify(chartTabIndicatorSelected)
    ) {
      dispatch(changeChartTabActiveData({ indicatorSelected }))
    }

    setListYAxisCustom()
  }, [JSON.stringify(indicatorSelected)])

  useEffect(() => {
    if (JSON.stringify(securities) !== JSON.stringify(chartTabSecurities)) {
      const newEconomy = [...securities.economy].map((item) => {
        const findEconomy = chartTabSecurities.economy.find(
          (economyItem) =>
            item.id === economyItem.id &&
            item.timeFrequency === economyItem.timeFrequency,
        )
        if (findEconomy) {
          return { ...findEconomy, ...item }
        } else {
          return item
        }
      })
      dispatch(
        changeChartTabActiveData({
          securities: { ...securities, economy: newEconomy },
        }),
      )
    }

    setListYAxisCustom()
  }, [JSON.stringify(securities)])

  useEffect(() => {
    setListYAxisCustom()
  }, [JSON.stringify(equations)])

  useEffect(() => {
    if (!isEditChart && isFirstTimeInCreateChart) {
      setIsFirstTimeInCreateChart(false)
    }

    if (chartTabActive && (isEditChart || !isFirstTimeInCreateChart)) {
      dispatch(changeFullSecurities(chartTabSecurities))
      dispatch(changeFullIndicator(chartTabIndicatorSelected))
    }
  }, [chartTabActive])

  // Update data on remove securities or equations
  useEffect(() => {
    if (viewType === VIEW_TYPE.BY_SECURITIES) {
      const dataChartKeys = [
        ...companies.map((id) => [LIST_TYPE.COMPANIES, id].join('_')),
        ...indices.map((id) => [LIST_TYPE.INDICES, id].join('_')),
        ...sector.map((id) => [LIST_TYPE.SECTOR, id].join('_')),
        ...economy.map((item) =>
          [LIST_TYPE.ECONOMY, item.id, item.timeFrequency].join('_'),
        ),
        ...Object.values(equations).map((equation) => equation.dataKey),
      ]
      const newDataChart = Object.keys(dataChartBySecurities).reduce(
        (obj, key) =>
          dataChartKeys.includes(key)
            ? { ...obj, [key]: dataChartBySecurities[key] }
            : obj,
        {},
      )
      dispatch(changeDataChartBySecurities(newDataChart))
    }
  }, [companies, indices, sector, economy, equations])

  return (
    <>
      <DataBySecurities
        key={'securities_' + chartTabActive}
        isFirstTimeEditChart={isFirstTimeEditChart}
        setIsFirstTimeEditChart={setIsFirstTimeEditChart}
      />
      <DataByEquations key={'equations_' + chartTabActive} />
      <DataByLatestPeriod />
    </>
  )
}
