import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class ChartingProxy extends ProxyBase {
  createChartLayout(params) {
    return this.post('', params)
  }

  getChartLayout(params) {
    return this.get('', params)
  }

  deleteChartLayout(params) {
    return this.delete('', params)
  }

  getChartLayoutShareInfo(params) {
    return this.post('GetChartLayoutShareInfo', params)
  }

  getSharedChartLayout(params) {
    return this.get('GetSharedChartLayout', params)
  }
}

export default new ChartingProxy(
  ServiceProxyConfig.Common.ChartLayout.ServiceUrl,
)
