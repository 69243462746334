import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { formatVal } from '../../../../utils/Value'
import { Span } from '../../../html/Span'
import { ChartTooltip } from './ChartTooltip'

const SETTINGS = {
  minBarHeight: 36,
  maxBarHeight: 60,
  minBarBlockHeight: 20,
  maxBarBlockHeight: 30,
  colors: {
    default: '#ececec',
    equalTo: '#ffcc24',
    lessThan: '#ff333f',
    greaterThan: '#2de352',
  },
}

export const STACK_HORIZONTAL_BAR_CHART_LABEL_PADDING_RIGHT = 12

export const ChartContentItem = ({
  data,
  width,
  barHeight,
  yAxisCompareKey,
  yAxisMaxKey,
  yAxisMinKey,
  yAxisLabel,
  barKeys,
  maxTickValue,
  margin,
  isShowTotal,
  isFillColorYAxis,
  tooltipContentData,
  fillColor,
  formatTotal,
}) => {
  const parentRef = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const getWidth = (key) => {
    return data[key]
      ? (data[key] / maxTickValue) * (width - margin.left - margin.right) || 0
      : 0
  }

  const getYTickColor = () => {
    if (!isFillColorYAxis || !yAxisMinKey || !yAxisMaxKey) {
      return SETTINGS.colors.default
    }

    if (data[yAxisCompareKey] < data[yAxisMinKey]) {
      return SETTINGS.colors.greaterThan
    } else if (data[yAxisCompareKey] > data[yAxisMaxKey]) {
      return SETTINGS.colors.lessThan
    } else {
      return SETTINGS.colors.equalTo
    }
  }

  const getTotalStackBarValue = () => {
    const totalVal = barKeys
      .map((key) => data[key])
      .reduce((prev, cur) => prev + cur, 0)
    return formatTotal
      ? formatTotal(totalVal)
      : formatVal(totalVal, totalVal > 10 ** 3 ? 0 : 2)
  }

  const openTooltip = () => setIsOpen(true)

  const closeTooltip = () => setIsOpen(false)

  return (
    <div
      className="align-center"
      style={{
        height:
          barHeight > SETTINGS.minBarHeight ? barHeight : SETTINGS.minBarHeight,
      }}
    >
      <div style={{ width: margin.left }}>
        <div
          className="t-right"
          style={{
            paddingRight: STACK_HORIZONTAL_BAR_CHART_LABEL_PADDING_RIGHT,
            color: getYTickColor(),
          }}
        >
          <Span className="canvas-chart-title">{yAxisLabel}</Span>
        </div>
      </div>
      <div
        ref={parentRef}
        className="align-center"
        onMouseOver={openTooltip}
        onMouseOut={closeTooltip}
      >
        {barKeys.map((key) => (
          <div
            key={key}
            style={{
              width: getWidth(key),
              backgroundColor: fillColor[key],
              height:
                barHeight > SETTINGS.maxBarHeight
                  ? SETTINGS.maxBarBlockHeight
                  : SETTINGS.minBarBlockHeight,
            }}
          ></div>
        ))}
        {isShowTotal && (
          <div
            style={{
              position: 'relative',
              height:
                barHeight > SETTINGS.maxBarHeight
                  ? SETTINGS.maxBarBlockHeight
                  : SETTINGS.minBarBlockHeight,
            }}
            className="ml-8"
          >
            <div
              style={{ position: 'absolute' }}
              className="h-100 align-center"
            >
              <Span className="canvas-chart-title">
                {getTotalStackBarValue()}
              </Span>
            </div>
          </div>
        )}
      </div>
      <div style={{ width: margin.right }} />
      <ChartTooltip
        parentRef={parentRef}
        data={data}
        isOpen={isOpen}
        tooltipContentData={tooltipContentData}
      />
    </div>
  )
}

ChartContentItem.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  barHeight: PropTypes.number.isRequired,
  yAxisCompareKey: PropTypes.string.isRequired,
  yAxisMaxKey: PropTypes.string,
  yAxisMinKey: PropTypes.string,
  yAxisLabel: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  barKeys: PropTypes.array.isRequired,
  maxTickValue: PropTypes.number,
  margin: PropTypes.object,
  isShowTotal: PropTypes.bool,
  isFillColorYAxis: PropTypes.bool,
  tooltipContentData: PropTypes.array,
  fillColor: PropTypes.object,
}
