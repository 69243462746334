import { maxBy, uniq } from 'lodash'
import { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { v4 as uuidv4 } from 'uuid'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import Dropdown from '../../../common/dropdown'
import UseI18n from '../../../common/hooks/useI18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import useGetICB from '../../../common/masterData/useGetICB'
import TextEllipsis from '../../../common/textEllipsis'
import Tree from '../../../common/tree'
import { Z_INDEX } from '../../../constants/Common'
import { keyBy } from '../../../utils/Common'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../utils/EventEmitter'
import { TABLE_FIELD_CODES } from '../Screening/constants'
import {
  selectAddInformatics,
  selectFilterParams,
  selectInitialSectors,
  selectParentSectors,
  setFilterParameters,
  setParentSector,
} from '../store/slice'
import style from './index.module.css'

const FilterSector = () => {
  const dispatch = useDispatch()

  const parentRef = useRef()
  const dropdownRef = useRef()

  const { sectors, addedInformations } = useSelector(selectFilterParams)
  const initialSectors = useSelector(selectInitialSectors)
  const parentList = useSelector(selectParentSectors)
  const addInformatics = useSelector(selectAddInformatics)

  const { ICBsTree, ICBs, loading } = useGetICB(
    true,
    true,
    undefined,
    undefined,
    true,
  )

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => {
    setIsShowDropdown(false)
    setValueSearch('')
  }

  const setListChecked = (listChecked) => {
    const newList = listChecked.filter((item) => !parentList.includes(item))

    dispatch(
      setFilterParameters({
        key: 'sectors',
        value: newList,
      }),
    )

    const information = addInformatics.find(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.INDUSTRY ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_INDUSTRY,
    )
    const indexInformation = addedInformations.findIndex(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.INDUSTRY ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_INDUSTRY,
    )

    if (!information) return

    const { indicatorId, tableCode, tableFieldCode } = information

    if (
      (!listChecked?.length ||
        listChecked?.length === initialSectors?.length ||
        newList?.length === initialSectors?.length) &&
      indexInformation !== -1 &&
      !information?.isDefault
    ) {
      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: addedInformations.filter(
            (item) =>
              item.tableFieldCode !== TABLE_FIELD_CODES.INDUSTRY &&
              item.tableFieldCode !== TABLE_FIELD_CODES.EN_INDUSTRY,
          ),
        }),
      )
      return
    }

    if (
      !!listChecked?.length &&
      uniq(listChecked)?.length !== uniq(initialSectors)?.length &&
      indexInformation === -1
    ) {
      const max = maxBy(addedInformations, (e) => e.order)

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: [
            ...addedInformations,
            {
              id: uuidv4(),
              order: (max?.order ?? 0) + 1,
              indicatorId,
              tableCode,
              tableFieldCode,
            },
          ],
        }),
      )
    }
  }

  const setParentChecked = (listChecked) => {
    dispatch(setParentSector(listChecked))
  }

  const text = useMemo(() => {
    const newList = sectors.filter((item) => !parentList.includes(item))

    return !!newList.length && !!initialSectors.length ? (
      newList.length === initialSectors.length ? (
        <Translate value="bond.corporateBond.priceBoard.ALL" />
      ) : (
        <TextEllipsis
          text={parentList
            .map((key) => keyBy(ICBs, 'icbCode')[key]?.icbName)
            .join(', ')}
          zIndexTooltip={999}
        />
      )
    ) : (
      <Span style={{ opacity: 0 }}>
        <Translate value="bond.bondScreening.common.INDUSTRY" />
      </Span>
    )
  }, [sectors, initialSectors, parentList])

  return (
    <div className={`input-dropdown ${style.input}`} ref={parentRef}>
      <span
        className={`span-input ${style.inputDropdown} `}
        style={{
          padding: '2px 0',
          width: '100%',
          gap: 8,
          height: '100%',
          boxSizing: 'border-box',
        }}
        ref={dropdownRef}
        onClick={handleDropdown}
      >
        <div style={{ flex: 1, overflow: 'hidden' }}>{text}</div>
        <i
          className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'}
          style={{ position: 'static' }}
        />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={dropdownRef}
        >
          <div
            className={`wrapper-tool ${style.mt3}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${style.search}`}
                    placeholder={UseI18n('common.SEARCH')}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" style={{ fontSize: 8 }} />
                  </button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <Tree
                    valueSearch={valueSearch}
                    treeData={ICBsTree}
                    idKey="icbCode"
                    nameKey="icbName"
                    levelKey="icbLevel"
                    isDefaultCheckAll={false}
                    listChecked={sectors}
                    setListChecked={setListChecked}
                    setListParentChecked={setParentChecked}
                    isHaveCheckboxAll={true}
                    titleCheckboxAll="common.ALL"
                    isHaveCheckbox={true}
                    isActiveSelected={false}
                    autoHeight={true}
                    autoHeightMin={200}
                    autoHeightMax={350}
                    isCheckedWhenClickText
                    zIndexTextEllipsis={Z_INDEX.DROPDOWN + 1}
                    customStyle={style.customTree}
                    classCheckboxStyle={style.checkbox}
                  />
                )}
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default FilterSector
