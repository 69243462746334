import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TimeFrame from '../../common/TimeFrame'
import { handleLineColor } from '../panelTotalGDP/helper'
import {
  getCheckboxChecked,
  getGDPTypeNameData,
  getIndexChecked,
  getLineChartColor,
  getValueGrowthYoY,
  getYearlyQuarterly,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from '../panelTotalGDP/store/slice'
import ChartComponent from './ChartComponent'
import { GDP_GROWTH_BY_SECTOR } from './PanelGDPGrowthBySector'
import {
  QUARTERLY_TIME_FRAME_OPTIONS,
  YEAR_TIME_FRAME_OPTIONS,
} from './constants'
import { getIndicatorName } from './helper'
import {
  getLineChartData,
  getLineChartLoading,
  getTimeRangeQuarterly,
  getTimeRangeYearly,
  handleActiveLine,
  handleTimeRangeQuarterly,
  handleTimeRangeYearly,
} from './store/slice'
import { getGDPGrowthBySector } from './store/thunk'

const GDPGrowthBySector = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const gdpTypeNameData = useSelector(getGDPTypeNameData)
  const lineChartLoading = useSelector(getLineChartLoading)
  const lineChartData = useSelector(getLineChartData)
  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeQuarterly = useSelector(getTimeRangeQuarterly)
  const yearlyQuarterlyOption = useSelector(getYearlyQuarterly)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const indexChecked = useSelector(getIndexChecked)
  const lineChartColor = useSelector(getLineChartColor)
  const valueGrowthYoY = useSelector(getValueGrowthYoY)
  const locale = useSelector((state) => state.i18n.locale)

  const isYearly = yearlyQuarterlyOption === 'Yearly'
  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  // Actions
  const handleChangeTab = (timeRange) => {
    if (isYearly) {
      dispatch(handleTimeRangeYearly(timeRange?.value))
    } else {
      dispatch(handleTimeRangeQuarterly(timeRange?.value))
    }
  }

  const handleDelete = (item) => {
    const isChecked = checkboxChecked.includes(item.author)
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => val !== item.author,
    )
    const currentIndex = gdpTypeNameData.findIndex(
      (val) => val.nationalAccountVNTypeId === item.author,
    )
    const newIndexChecked = indexChecked.filter((val) => val !== currentIndex)

    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(
        handleLineColor(item.author, lineChartColor, isChecked),
      ),
    )
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(item.author))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  // Use effect
  useEffect(() => {
    if (checkboxChecked.length) {
      dispatch(
        getGDPGrowthBySector({
          nationalAccountVNTypeIds: checkboxChecked,
          timeFrequency: yearlyQuarterlyOption,
        }),
      )
    }
  }, [locale, yearlyQuarterlyOption, valueGrowthYoY, checkboxChecked])

  return (
    <div className="h-100">
      {lineChartLoading && <Loading />}
      {!lineChartLoading && (
        <>
          {lineChartData.length === 0 && <NoData />}
          {lineChartData.length > 0 && (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <TimeFrame
                      styleProps={{ justifyContent: 'end' }}
                      timeFrameOption={
                        isYearly
                          ? YEAR_TIME_FRAME_OPTIONS
                          : QUARTERLY_TIME_FRAME_OPTIONS
                      }
                      timeRange={
                        isYearly ? timeRangeYearly : timeRangeQuarterly
                      }
                      onChangeTab={handleChangeTab}
                    />
                    {(size.height || size.height === 0) && (
                      <div id={GDP_GROWTH_BY_SECTOR}>
                        <ChartComponent
                          data={lineChartData}
                          height={height - size.height}
                          width={width}
                          keyXAxis={isYearly ? 'year' : 'yearQuarter'}
                          checkboxChecked={checkboxChecked}
                          heightListIndicator={heightListIndicator}
                        />
                        <FooterWithScroll
                          width={width}
                          data={lineChartColorHasAuthor}
                          idKey="author"
                          nameKey="indicatorName"
                          formatName={(item) =>
                            getIndicatorName(gdpTypeNameData, item.author)
                          }
                          color={(item) => item.color}
                          legendPanel={legendPanel}
                          isFullScreen={isFullScreen}
                          setHeightFooter={setHeightListIndicator}
                          onDelete={handleDelete}
                          onHover={handleHover}
                          onUnHover={handleUnHover}
                        />
                      </div>
                    )}
                  </>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

export default GDPGrowthBySector
