import { createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '../../../../core/services/common/MasterService'
import PackageService from '../../../../core/services/common/PackageService'

export const getExchangeThunk = createAsyncThunk(
  'common/masterData/GET_EXCHANGE',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getExchanges()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getExchangeThunkV2 = createAsyncThunk(
  'common/masterData/GET_EXCHANGE_V2',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getIndicesV2()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getICBThunk = createAsyncThunk(
  'common/masterData/GET_ICB',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getIcbs()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getStockThunk = createAsyncThunk(
  'common/masterData/GET_STOCK',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getStocks({ Groups: 'All' })
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getIndicesThunk = createAsyncThunk(
  'common/masterData/GET_INDICES',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getIndices()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getIndicesV2Thunk = createAsyncThunk(
  'common/masterData/GET_INDICES_V2',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getIndicesV2()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getCoveredWarrantsThunk = createAsyncThunk(
  'common/masterData/GET_COVERED_WARRANT',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getCoveredWarrants()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

// Get bonds
export const getBondThunk = createAsyncThunk(
  'common/masterData/GET_BOND',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getBonds()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getPackageFunctionsThunk = createAsyncThunk(
  'common/package/GET_PACKAGE_FUNCTIONS',
  async (data, { rejectWithValue }) => {
    const response = await PackageService.getPackageFunctions()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getAllFunctionsThunk = createAsyncThunk(
  'common/package/GET_ALL_FUNCTIONS',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getAllFunctions()
    if (response.success) return response.data
    return rejectWithValue(response.errors)
  },
)

export const getMaxMinTableThunk = createAsyncThunk(
  'common/package/GET_MAX_MIN_TABLE',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getMaxMinTable()
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getAllFundsThunk = createAsyncThunk(
  'common/package/GET_ALL_FUNDS',
  async (data, { rejectWithValue }) => {
    const response = await MasterService.getAllFunds()
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
