import { I18n } from 'react-redux-i18n'
import { TopExportChart } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'

export const PanelTopExport = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const nameScreen = I18n.t('sector.sectorSpecific.fishery.FISHERY')
  const chartName = 'sector.sectorSpecific.fishery.exporters.TOP_EXPORTERS'
  const tabName = I18n.t('sector.sectorSpecific.fishery.EXPORTERS')

  return (
    <Panel
      title={chartName}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={I18n.t(chartName)}
      downloadJpgParams={{
        domId: 'topExportChart',
        nameScreen: nameScreen,
        chartName: I18n.t(chartName),
        tabName: tabName,
      }}
    >
      <TopExportChart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
