import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import TextEllipsis from '../../common/textEllipsis'
import { Z_INDEX } from '../../constants/Common'
import { keyBy } from '../../utils/Common'
import { HandleClickOutside } from '../HandleClickOutside'
import { ScrollComponent } from '../ScrollComponent'
import Dropdown, { positionDropdown } from '../dropdown'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { Span } from '../html/Span'
import Tree from '../tree'
import style from './index.module.css'

const SelectCustom = ({
  isI18n,
  isDisable,
  value,
  selectData,
  handleChange,
  maxItemDropdown,
  renderSelect,
  renderItemSelect,
  heightItem,
  isShowArrow,
  appendDropdownRef,
  fontSizeItem,
  zIndexTextEllipsis,
  timeoutClickOutside,
  zIndexDropdown,
  appendStyle,
  isDropdownFitContent,
  dropdownSpace,
  textPlaceholder,
  dropdownMinHeight,
  noDataContent,
  styleContainer,
  isCollapse,
  selectDataCollapse,
  appendDropdownStyle,
  idKey,
  nameKey,
  onClickItem,
  itemDefaultOpen,
  appendTreeItemStyle,
  appendStyleDivValue,
  themeWhite,
  renderTextSelect,
}) => {
  const selectRef = useRef()
  const dropdownRef = useRef()
  const currentItemRef = useRef()

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [dataById, setDataById] = useState({})
  const [isActiveSelected, setIsActiveSelected] = useState(false)

  const isDropdownTop =
    window.innerHeight - selectRef.current?.getBoundingClientRect()?.bottom <
    maxItemDropdown * heightItem

  useEffect(() => {
    setDataById(keyBy(selectData, 'value'))
  }, [selectData])

  useEffect(() => {
    if (isShowDropdown) {
      const ref = appendDropdownRef || dropdownRef
      const timer = setTimeout(() => {
        ref.current?.querySelector(`.scrollbars div:first-child`).scroll({
          top:
            currentItemRef.current?.offsetTop -
            (maxItemDropdown - 1) * heightItem,
        })
      })
      return () => clearTimeout(timer)
    }
  }, [isShowDropdown])

  const handleDropdown = () => {
    if (!isDisable) {
      setIsShowDropdown(!isShowDropdown)
      setIsActiveSelected(true)
    }
  }

  const closeDropdown = () => setIsShowDropdown(false)

  const handleSelect = (value) => {
    handleChange(value)
    closeDropdown()
  }

  const handleClick = (item) => {
    onClickItem && onClickItem(item)
    closeDropdown()
  }

  const turnOffActiveSelected = () => {
    if (isActiveSelected) setIsActiveSelected(false)
  }

  return (
    <>
      <div onClick={handleDropdown} ref={selectRef} style={styleContainer}>
        {renderSelect ? (
          renderSelect({
            styleComponent: style,
            isDisable,
            isI18n,
            dataById,
            value,
            isShowDropdown,
          })
        ) : (
          <span
            className={`${themeWhite ? style.selectThemeWhite : style.select} ${
              isShowDropdown ? style.show : ''
            }`}
            style={appendStyleDivValue}
          >
            <span className={`${style.text} ${isDisable ? style.disable : ''}`}>
              <Span style={{ fontSize: fontSizeItem, ...appendStyle }}>
                {renderTextSelect && !isShowDropdown ? (
                  <TextEllipsis
                    isI18n={isI18n}
                    text={renderTextSelect}
                    zIndexTooltip={zIndexTextEllipsis}
                  />
                ) : dataById[value]?.name ? (
                  <TextEllipsis
                    isI18n={isI18n}
                    text={dataById[value]?.name}
                    zIndexTooltip={zIndexTextEllipsis}
                  />
                ) : (
                  textPlaceholder
                )}
              </Span>
            </span>
            {isShowArrow && (
              <i
                className={`${
                  !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
                } ${style.icon}`}
              />
            )}
          </span>
        )}
      </div>
      <Dropdown
        parentRef={selectRef}
        isShow={isShowDropdown}
        position={
          isDropdownTop
            ? positionDropdown.TOP_LEFT_RIGHT
            : positionDropdown.BOTTOM_LEFT_RIGHT
        }
        zIndex={zIndexDropdown}
        dropdownSpace={dropdownSpace}
      >
        <HandleClickOutside
          handleClickOutside={
            timeoutClickOutside
              ? () => setTimeout(closeDropdown, timeoutClickOutside)
              : closeDropdown
          }
          exceptRef={selectRef}
        >
          <div
            ref={appendDropdownRef || dropdownRef}
            className={`${style.dropdown} ${
              isDropdownTop ? style.dropdownTop : style.dropdownBottom
            }`}
            style={{
              ...appendDropdownStyle,
              width: isDropdownFitContent
                ? 'fit-content'
                : `${formatWithZoom(
                    selectRef.current?.getBoundingClientRect()?.width,
                  )}px`,
            }}
          >
            {!isCollapse && !selectDataCollapse && (
              <ScrollComponent
                autoHeight={true}
                autoHeightMax={maxItemDropdown * heightItem}
                verticalTrackWidth={5}
                horizontalTrackWidth={5}
                autoHeightMin={dropdownMinHeight}
              >
                {selectData.length === 0 ? noDataContent : null}
                <ul className={themeWhite ? style.ulThemeWhite : style.ul}>
                  <>
                    {renderItemSelect &&
                      selectData.map((item) =>
                        renderItemSelect({
                          styleComponent: style,
                          key: item.value,
                          dataById,
                          item,
                          currentItemRef,
                          isActiveSelected,
                          handleSelect,
                          turnOffActiveSelected,
                          isI18n,
                          heightItem,
                          closeDropdown,
                          value,
                          dropdownRef: appendDropdownRef || dropdownRef,
                        }),
                      )}
                    {!renderItemSelect &&
                      selectData.map((item) => (
                        <li
                          key={item.value}
                          ref={
                            value === item.value ? currentItemRef : undefined
                          }
                          className={`${
                            themeWhite ? style.liThemeWhite : style.li
                          } ${
                            value === item.value && isActiveSelected
                              ? themeWhite
                                ? style.activeThemeWhite
                                : style.active
                              : ''
                          }`}
                          style={{
                            height: `${heightItem}px`,
                            opacity: item.disableSelect ? 0.4 : 1,
                            pointerEvents: item.disableSelect ? 'none' : 'auto',
                          }}
                          onClick={() => handleSelect(item.value)}
                          onMouseOver={turnOffActiveSelected}
                        >
                          <Span
                            className={style.textDropdown}
                            style={{ fontSize: fontSizeItem }}
                          >
                            {isDropdownFitContent ? (
                              item.name
                            ) : (
                              <TextEllipsis
                                isI18n={isI18n}
                                text={item.name}
                                zIndexTooltip={zIndexTextEllipsis}
                              />
                            )}
                          </Span>
                        </li>
                      ))}
                  </>
                </ul>
              </ScrollComponent>
            )}
            {isCollapse && selectDataCollapse && (
              <Tree
                treeData={selectDataCollapse}
                idKey={idKey}
                nameKey={nameKey}
                onClickItem={handleClick}
                disabledCheckDbClick
                itemDefaultOpen={itemDefaultOpen}
                appendStyle={appendTreeItemStyle}
                isInDropdown={true}
              />
            )}
          </div>
        </HandleClickOutside>
      </Dropdown>
    </>
  )
}

SelectCustom.propTypes = {
  isI18n: PropTypes.bool,
  isDisable: PropTypes.bool,
  value: PropTypes.any,
  selectData: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      name: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object,
      ]),
      disableSelect: PropTypes.bool,
      customStyle: PropTypes.object,
    }),
  ),
  handleChange: PropTypes.func,
  maxItemDropdown: PropTypes.number,
  renderSelect: PropTypes.func,
  renderItemSelect: PropTypes.func,
  heightItem: PropTypes.number,
  isShowArrow: PropTypes.bool,
  appendDropdownRef: PropTypes.object,
  fontSizeItem: PropTypes.number,
  zIndexTextEllipsis: PropTypes.number,
  timeoutClickOutside: PropTypes.number,
  zIndexDropdown: PropTypes.number,
  appendStyle: PropTypes.object,
  isDropdownFitContent: PropTypes.bool,
  dropdownSpace: PropTypes.number,
  dropdownMinHeight: PropTypes.number,
  textPlaceholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  styleContainer: PropTypes.object,
  appendStyleDivValue: PropTypes.object,
  renderTextSelect: PropTypes.string,
}

SelectCustom.defaultProps = {
  isI18n: false,
  isDisable: false,
  handleChange: () => {},
  maxItemDropdown: 10,
  heightItem: 23,
  isShowArrow: true,
  fontSizeItem: 12,
  zIndexTextEllipsis: Z_INDEX.DROPDOWN + 1,
  timeoutClickOutside: null,
  appendStyle: {},
  isDropdownFitContent: false,
  dropdownSpace: 0,
  textPlaceholder: '',
  styleContainer: {},
  appendStyleDivValue: {},
}

export default SelectCustom
