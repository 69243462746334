import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { debounce } from '../../../../../utils/Common'
import { selectIcbLv2 } from '../peersComparison/store/slice'
import { selectFilterChart1 } from './store/slice'
import {
  getCreditRatingCoefficient,
  getFundsFromOperationsToDebt,
  getLiabilitiesToEquityRatio,
  getgetDebtToEBITDA,
} from './store/thunk'

const ChartType = ({ width }) => {
  const dispatch = useDispatch()

  // Use select
  const basicInfo = useSelector(selectBasicInfo)
  const icbIdLv2 = useSelector(selectIcbLv2)
  const locale = useSelector((state) => state.i18n.locale)
  const filter = useSelector(selectFilterChart1)

  // Actions
  const handleApi = useCallback(
    debounce((params) => {
      dispatch(getLiabilitiesToEquityRatio(params))
      dispatch(getFundsFromOperationsToDebt(params))
      dispatch(getgetDebtToEBITDA(params))
    }, 200),
    [],
  )

  const handleApiWithYear = useCallback(
    debounce((params) => {
      dispatch(getCreditRatingCoefficient(params))
    }, 200),
    [],
  )

  // Use effect
  useEffect(() => {
    if (basicInfo?.organizationId && icbIdLv2) {
      handleApi({
        OrganizationId: basicInfo?.organizationId,
        icbId: icbIdLv2,
      })
    }
  }, [basicInfo?.organizationId, locale, icbIdLv2])

  useEffect(() => {
    if (basicInfo.organizationId && icbIdLv2) {
      handleApiWithYear({
        OrganizationId: basicInfo?.organizationId,
        icbId: icbIdLv2,
        Year: filter,
      })
    }
  }, [basicInfo?.organizationId, locale, filter, icbIdLv2])

  return (
    <div className="d-flex mb-8">
      <Footer
        key={width}
        appendStyle={{ height: 20, justifyContent: 'flex-start' }}
        list={[
          // {
          //   text: 'corporate.peerValuation.overview.LATEST_COMPANY_RATIO',
          //   type: TYPE.RECTANGLE,
          //   before: {
          //     bgColor: '#facc5c',
          //   },
          //   tooltip: 'corporate.peerValuation.overview.CURRENT_TOOLTIP',
          // },
          {
            text: 'bond.comparable.chart.lengend.enterprise',
            type: TYPE.CIRCLE_MARKER_LINE,
            before: {
              bgColor: '#40ABFF',
            },
            after: {
              bgColor: '#40ABFF',
            },
          },
          {
            text: 'bond.comparable.chart.lengend.industry',
            type: TYPE.CIRCLE_MARKER_LINE,
            before: {
              bgColor: '#FECF2F',
            },
            after: {
              bgColor: '#FECF2F',
            },
          },
        ]}
      />
    </div>
  )
}

export default ChartType
