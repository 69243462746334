import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { fillColor, lineKeys, mappingDisplayName, xAxisKey } from './constants'

export const LineChart = ({ data, width, height, decimalLength, margin }) => {
  const timezone = UseTimeZone()
  const locale = useSelector((state) => state.i18n.locale)

  // Get data
  const getData = () => {
    return data.map((item, index) => ({
      ...item,
      [xAxisKey]: `${item[xAxisKey]}_${index}`,
    }))
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: lineKeys,
        yAxisId: 'lineChart',
        unit: 'corporate.peerValuation.consensus.CHART_THOUSAND_VND',
        decimalLength: decimalLength || 2,
      },
    ]
  }

  return (
    <ChartContainer
      data={getData()}
      width={width}
      height={height}
      keyXAxis={xAxisKey}
      yAxis={[
        {
          id: 'dummyChart',
          keys: [],
          orientation: 'left',
          yAxisWidth: margin.left,
        },
        {
          id: 'lineChart',
          keys: lineKeys,
          orientation: 'right',
          isLineChart: true,
          yAxisWidth: margin.right,
          label: I18n.t(
            'corporate.peerValuation.consensus.CHART_UNIT_THOUSAND',
          ),
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
        },
      ]}
      isShowXAxis={false}
      allowEscapeViewBoxY={true}
      tooltipSchema={{
        title: {
          formatValue: (data) =>
            data[xAxisKey]
              ? formatDateTime(
                  data[xAxisKey].split('_')[0],
                  FORMAT.DATE,
                  locale,
                  timezone,
                )
              : data[xAxisKey],
        },
        content: getContent(),
        mappingDisplayName: mappingDisplayName,
      }}
    >
      {lineKeys.map((key) => (
        <Line
          key={key}
          yAxisId="lineChart"
          dataKey={key}
          type="linear"
          stroke={fillColor[key]}
          fill={fillColor[key]}
          strokeWidth={2}
          dot={false}
          activeDot={false}
          isAnimationActive={false}
        />
      ))}
    </ChartContainer>
  )
}
