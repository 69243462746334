import { useSelector } from 'react-redux'
import { LIST_TYPE, SEC_GROUP } from '../../../common/constants'
import { selectChartTabIndicatorSelected } from '../../store/slice'
import style from '../../style.module.css'
import AddMoreIndicator from '../AddMoreIndicator'
import IndicatorItemCard from '../IndicatorItemCard'
import SecuritiesItem from '../SecuritiesItemCard'

const SecuritiesChildrenIndices = ({
  indices,
  indicatorLatestPeriod,
  isViewByLatestPeriod,
}) => {
  const indicatorSelected = useSelector(
    selectChartTabIndicatorSelected(SEC_GROUP.INDEX),
  )

  return (
    <div className={style.securitiesChildren}>
      {Object.keys(indices)
        .sort((a, b) => +a - +b)
        .map((item) => (
          <SecuritiesItem
            key={item}
            itemId={+item}
            itemType={LIST_TYPE.INDICES}
            dataChildren={indices[item]}
          />
        ))}
      <AddMoreIndicator typeIndicator={SEC_GROUP.INDEX} />
      {indicatorSelected.length
        ? indicatorSelected.map((indicator, index) => (
            <IndicatorItemCard
              key={
                indicator.uniqueID
                  ? [indicator.uniqueID, indicator.id].join('_')
                  : indicator.id
              }
              index={index}
              indicatorItem={indicator}
              typeIndicator={SEC_GROUP.INDEX}
              itemType={LIST_TYPE.INDICES}
              indicatorLatestPeriod={indicatorLatestPeriod}
              isViewByLatestPeriod={isViewByLatestPeriod}
            />
          ))
        : null}
    </div>
  )
}

SecuritiesChildrenIndices.propTypes = {}

export default SecuritiesChildrenIndices
