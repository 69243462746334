import { createSlice } from '@reduxjs/toolkit'
import { keyBy, uuid } from '../../../../utils/Common'
import {
  FORMAT,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../../../utils/Datetime'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  getBondCashFlowScenario,
  getDataBondInfo,
} from '../../infoSec/store/thunk'
import {
  tabOption,
  valueCheckBox,
  valueInterrest,
  valueTab,
} from '../constants'
import {
  addNewApi,
  checkNameApi,
  deleteCashFlowRowApi,
  getAccruedInterest,
  getPriceYtmApi,
  getUserBondCashFlowList,
  updateApi,
} from './thunk'

export const SLICE_NAME = 'bond/valuation/sameIndustrybond'

export const TYPE_ACTION_TAB = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

function sortByTradingDate(a, b) {
  const dateA = `${a.tradingDateId}_${a.bondPaymentTypeId}`
  const dateB = `${b.tradingDateId}_${b.bondPaymentTypeId}`
  return dateA.localeCompare(dateB)
}

const fomatPrice = (ytm, price, accInterest, checkBox, cleanPrice) => {
  if (checkBox === valueCheckBox.YTM) {
    return {
      ytmRender: ytm * 100,
      ytm: ytm * 100,
      dirtyprice: price,
      cleanPrice: cleanPrice,
    }
  }
  if (checkBox === valueCheckBox.DIRTY_PRICE) {
    return {
      ytmRender: ytm * 100,
      ytm: ytm * 100,
      dirtyprice: price,
      cleanPrice: cleanPrice,
    }
  }
  if (checkBox === valueCheckBox.CLEAN_PRICE) {
    return {
      ytmRender: ytm * 100,
      ytm: ytm * 100,
      dirtyprice: price,
      cleanPrice: cleanPrice,
    }
  }
}

const getDefaultPaymentDate = () => {
  if (new Date().getDay() === 5) {
    return getDateTimeFrom(new Date(), 3, 'day', FORMAT.DATE)
  } else {
    return getDateTimeFrom(new Date(), +1, 'day', FORMAT.DATE)
  }
}

const initialState = {
  loading: false,
  form: {
    tradingDate: getCurrentDateTime(FORMAT.DATE),
    paymentDate: getDefaultPaymentDate(),
    TradingVolume: 0,
    interestBasis: valueInterrest.ACT,
    checkBox: valueCheckBox.YTM,
    ytm: '',
    dirtyprice: '',
    cleanPrice: '',
    ytmRender: '',
    accInterest: 0,
    exDate: null,
  },
  info: [],
  tab: {},
  tabOld: {},
  tabActive: valueTab.BOND_DEFAULT,
  listTab: tabOption,
  listTabOld: tabOption,

  addTabSuccess: false,
  statusGetBondCashFlow: false,
  popupAlert: {
    message: '',
    isShow: false,
    type: '',
    itemSelect: {},
  },
  status: '',
}

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetFormWhenChangeTab(state, actions) {
      state.form = {
        tradingDate: getCurrentDateTime(FORMAT.DATE),
        paymentDate: getDefaultPaymentDate(),
        TradingVolume: 0,
        interestBasis: valueInterrest.ACT,
        checkBox: valueCheckBox.YTM,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        accInterest: 0,
        exDate: null,
      }
      state.info = []
      state.tab = {}
      state.tabOld = {}
      state.tabActive = valueTab.BOND_DEFAULT
      state.listTab = tabOption
      state.listTabOld = tabOption
    },
    updateForm(state, actions) {
      state.form = actions.payload
    },
    updateActiveTab(state, actions) {
      state.tabActive = actions.payload
    },

    updateStatusAddMore: (state, action) => {
      state.addTabSuccess = action.payload
    },
    updateStatusGetBondCashFlow: (state, action) => {
      state.statusGetBondCashFlow = action.payload
    },
    openPopup: (state, action) => {
      state.popupAlert = action.payload
    },
    updateTab: (state, action) => {
      state.tab[state.tabActive] = action.payload
    },
    addMoreTab: (state, action) => {
      const idRandom = uuid()
      const listtab = state.tab
      const tabMore = state.tab?.[valueTab.BOND_CREATED]
      const dataTab = tabMore?.data || {}
      for (const key in dataTab) {
        dataTab[key].bondCashFlowId = null
      }
      state.listTab = [
        ...state.listTab,
        {
          title: action.payload.nameTab,
          value: idRandom,
          isDropdownDot: true,
        },
      ]
      state.tabOld = {
        ...listtab,
        [idRandom]: {
          data: dataTab,
          ids: tabMore?.ids,
        },
      }
      state.tab = {
        ...listtab,
        [idRandom]: {
          data: dataTab,
          ids: tabMore?.ids,
        },
      }

      state.tabActive = idRandom
      state.addTabSuccess = true
    },
    addMoreTabEdit: (state, action) => {
      const idRandom = uuid()
      const listtab = state.tab
      const data = {}

      const { dataSort, ids, nameTab } = action.payload

      const newDataTab = keyBy(dataSort, 'id')

      for (const key in dataSort) {
        data[key] = {
          ...newDataTab[key],
          bondCashFlowId: null,
        }
      }
      state.listTab = [
        ...state.listTab,
        {
          title: nameTab,
          value: idRandom,
          isDropdownDot: true,
        },
      ]
      state.tabOld = {
        ...listtab,
        [idRandom]: {
          data,
          ids,
        },
      }
      state.tab = {
        ...listtab,
        [idRandom]: {
          data,
          ids,
        },
      }

      state.tabActive = idRandom
      state.addTabSuccess = true
    },
    deleteCashFlowClient: (state, action) => {
      const data = state.tab
      delete data[action.payload]
      state.tab = data
      state.tabOld = data
      state.listTab = state.listTab.filter((e) => e.value !== action.payload)
      state.tabActive = valueTab.BOND_DEFAULT
    },
    changeSaveStatus: (state, action) => {
      state.status = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBondCashFlowScenario.pending, (state) => {})
    builder.addCase(getBondCashFlowScenario.fulfilled, (state, action) => {
      const req = action.payload.data
      const tabDefault = req.filter((e) => e.cashFlowTypeId === 1)
      const tabDefault2 = req.filter((e) => e.cashFlowTypeId === 2)

      const data1 = tabDefault.sort(sortByTradingDate).map((e, index) => ({
        ...e,
        id: index,
        couponInterestRate: e.couponInterestRate * 100,
      }))
      const data2 = tabDefault2.sort(sortByTradingDate).map((e, index) => ({
        ...e,
        id: index,
        couponInterestRate: e.couponInterestRate * 100,
      }))
      state.tab = {
        ...state.tab,
        0: {
          data: keyBy(data1, 'id'),
          ids: data1.map((e) => e.id),
        },
        1: {
          data: keyBy(data2, 'id'),
          ids: data2.map((e) => e.id),
        },
      }
      state.tabOld = {
        ...state.tabOld,
        0: {
          data: keyBy(data1, 'id'),
          ids: data1.map((e) => e.id),
        },
        1: {
          data: keyBy(data2, 'id'),
          ids: data2.map((e) => e.id),
        },
      }
      state.info = req
      state.form.accInterest = req?.[0]?.accInterest || 0
      if (!action.payload?.editDate) {
        state.statusGetBondCashFlow = true
      }
    })
    builder.addCase(getBondCashFlowScenario.rejected, (state) => {})
    builder.addCase(getPriceYtmApi.pending, () => {})
    builder.addCase(getPriceYtmApi.fulfilled, (state, action) => {
      const valueFormat = fomatPrice(
        action.payload.ytm,
        action.payload.price,
        state.form.accInterest || 0,
        state.form.checkBox,
        action.payload.cleanPrice,
      )
      state.form = {
        ...state.form,
        ...valueFormat,
      }
    })
    builder.addCase(getPriceYtmApi.rejected, () => {})

    builder.addCase(getUserBondCashFlowList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUserBondCashFlowList.fulfilled, (state, action) => {
      state.loading = false
      state.tab = action.payload.data
      state.tabOld = action.payload.data
      state.listTab = action.payload.listTab
      state.listTabOld = action.payload.listTab
      if (action.payload.type === TYPE_ACTION_TAB.ADD) {
        state.addTabSuccess = true
      }
    })
    builder.addCase(getUserBondCashFlowList.rejected, (state, action) => {
      state.loading = action.payload
      state.tabActive = valueTab.BOND_DEFAULT
      state.listTab = state.listTab.slice(0, 2)
      state.listTabOld = state.listTab.slice(0, 2)
      state.tab = {}
      state.tabOld = {}
    })

    builder.addCase(checkNameApi.pending, () => {})
    builder.addCase(checkNameApi.fulfilled, (state, action) => {
      state.listTab = action.payload
    })
    builder.addCase(checkNameApi.rejected, () => {})

    builder.addCase(deleteCashFlowRowApi.pending, (state) => {
      state.loading = true
    })
    builder.addCase(deleteCashFlowRowApi.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(deleteCashFlowRowApi.rejected, (state, action) => {
      state.loading = action.payload
    })

    builder.addCase(getAccruedInterest.pending, () => {})
    builder.addCase(getAccruedInterest.fulfilled, (state, action) => {
      state.form.accInterest = action.payload
    })
    builder.addCase(getAccruedInterest.rejected, () => {})

    builder.addCase(getDataBondInfo.pending, () => {})
    builder.addCase(getDataBondInfo.fulfilled, (state, action) => {
      state.form.exDate = action?.payload?.exrightDateId || null
    })
    builder.addCase(getDataBondInfo.rejected, (state) => {
      state.form.exDate = null
    })
    builder.addCase(addNewApi.pending, () => {})
    builder.addCase(addNewApi.fulfilled, (state, action) => {
      state.status = action.payload
    })
    builder.addCase(addNewApi.rejected, (state, action) => {})

    builder.addCase(updateApi.pending, () => {})
    builder.addCase(updateApi.fulfilled, (state, action) => {
      state.status = action.payload
    })
    builder.addCase(updateApi.rejected, (state, action) => {})
  },
})

export const selectStateSameIndustry = (state) => state[slice.name].form
export const selectInfoSameIndustry = (state) => state[slice.name].info

export const selectDataTab = (state) => state[slice.name].tab
export const selectDataTabOld = (state) => state[slice.name].tabOld

export const selectActiveTab = (state) => state[slice.name].tabActive
export const selectListTab = (state) => state[slice.name].listTab
export const selectListTabOld = (state) => state[slice.name].listTabOld
export const selectSaveStatus = (state) => state[slice.name].status

export const selectPopup = (state) => state[slice.name].popupAlert
export const selectLoading = (state) => state[slice.name].loading

export const selectStatusAddTab = (state) => state[slice.name].addTabSuccess
export const selectStatusGetBondCashFlow = (state) =>
  state[slice.name].statusGetBondCashFlow
export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(
    state[slice.name].tab?.[state[slice.name]?.tabActive]?.data?.[id],
    attr,
  )
}
export const selectDataIds = (key) => (state) => {
  return state.tab?.[key]?.ids || []
}
export const {
  updateForm,
  updateActiveTab,
  updateStatusAddMore,
  updateStatusGetBondCashFlow,
  openPopup,
  updateTab,
  addMoreTab,
  deleteCashFlowClient,
  addMoreTabEdit,
  resetFormWhenChangeTab,
  changeSaveStatus,
} = slice.actions

register(slice.name, slice.reducer)
