export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
}

export const keyRatios = {
  bank: [
    {
      index: 'Profitability',
      childrenId: [
        'Yield_on_Earning_Assets',
        'Cost_of_funds',
        'NIM',
        'ROA',
        'ROE',
      ],
      name: 'sector.sectorConstituents.overview.PROFITABILITY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Yield_on_Earning_Assets',
      name: 'sector.sectorConstituents.overview.YIELD_ON_EARNING_ASSETS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0286',
      isPercent: true,
    },
    {
      index: 'Cost_of_funds',
      name: 'sector.sectorConstituents.overview.COST_OF_FUNDS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0287',
      isPercent: true,
    },
    {
      index: 'NIM',
      name: 'sector.sectorConstituents.overview.NIM',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0285',
      isPercent: true,
    },
    {
      index: 'ROA',
      name: 'sector.sectorConstituents.overview.ROA',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0059',
      isPercent: true,
    },
    {
      index: 'ROE',
      name: 'sector.sectorConstituents.overview.ROE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0060',
      isPercent: true,
    },
    {
      index: 'AssetQuality',
      childrenId: [
        'NPL_Ratio',
        'Coverage_Ratio',
        'Loan_Loss',
        'LDR_Ratio',
        'CASA_Ratio',
        'ST_Funding',
      ],
      name: 'sector.sectorConstituents.overview.ASSET_QUALITY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'NPL_Ratio',
      name: 'sector.sectorConstituents.overview.NPL_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0236',
      isPercent: true,
    },
    {
      index: 'Coverage_Ratio',
      name: 'sector.sectorConstituents.overview.COVERAGE_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0237',
      isPercent: true,
    },
    {
      index: 'Loan_Loss',
      name: 'sector.sectorConstituents.overview.LOAN_LOSS_PROVISIONS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0238',
      isPercent: true,
    },
    {
      index: 'LDR_Ratio',
      name: 'sector.sectorConstituents.overview.LDR_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0277',
      isPercent: true,
    },
    {
      index: 'CASA_Ratio',
      name: 'sector.sectorConstituents.overview.CASA_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0295',
      isPercent: true,
    },
    {
      index: 'ST_Funding',
      name: 'sector.sectorConstituents.overview.ST_FUNDING_TO_MLT_LOANS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0318',
      isPercent: true,
    },
    {
      index: 'Efficiency',
      childrenId: [
        'Interest_And_Similar_Income',
        'Fees_And_Commission_Income',
        'Cost_to_Income_Ratio',
        'General_And_Administrative_Expenses',
      ],
      name: 'sector.sectorConstituents.overview.EFFICIENCY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Interest_And_Similar_Income',
      name: 'sector.sectorConstituents.overview.INTEREST_AND_SIMILAR_INCOME',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0254',
      isPercent: true,
    },
    {
      index: 'Fees_And_Commission_Income',
      name: 'sector.sectorConstituents.overview.FEES_AND_COMMISSION_INCOME',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0255',
      isPercent: true,
    },
    {
      index: 'Cost_to_Income_Ratio',
      name: 'sector.sectorConstituents.overview.COST_TO_INCOME_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0252',
      isPercent: true,
    },
    {
      index: 'General_And_Administrative_Expenses',
      name: 'sector.sectorConstituents.overview.GENERAL_AND_ADMINISTRATIVE_EXPENSES',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0260',
      isPercent: true,
    },
    {
      index: 'Valuation',
      childrenId: ['BVPS', 'Pe', 'Pb', 'P/E_Forward'],
      name: 'sector.sectorConstituents.overview.VALUATION',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'BVPS',
      name: 'sector.sectorConstituents.overview.BVPS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0007',
    },
    {
      index: 'Pe',
      name: 'sector.sectorConstituents.overview.PE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0022',
    },
    {
      index: 'Pb',
      name: 'sector.sectorConstituents.overview.PB',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0019',
    },
    {
      index: 'P/E_Forward',
      name: 'sector.sectorConstituents.overview.PE_FORWARD',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0065',
    },
    {
      index: 'Growth',
      childrenId: [
        'Average_Loans_Growth',
        'Average_Deposit_Growth',
        'Interest_income_Growth',
        'Non-Interest_income_Growth',
        'Preprovision_Income_Growth',
      ],
      name: 'sector.sectorConstituents.overview.GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Average_Loans_Growth',
      name: 'sector.sectorConstituents.overview.AVERAGE_LOANS_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0296',
      isPercent: true,
    },
    {
      index: 'Average_Deposit_Growth',
      name: 'sector.sectorConstituents.overview.AVERAGE_DEPOSIT_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0297',
      isPercent: true,
    },
    {
      index: 'Interest_income_Growth',
      name: 'sector.sectorConstituents.overview.INTEREST_INCOME_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0300',
      isPercent: true,
    },
    {
      index: 'Non-Interest_income_Growth',
      name: 'sector.sectorConstituents.overview.NON_INTEREST_INCOME_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0301',
      isPercent: true,
    },
    {
      index: 'Preprovision_Income_Growth',
      name: 'sector.sectorConstituents.overview.PREPROVISION_INCOME_GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0299',
      isPercent: true,
    },
  ],
  securities: [
    {
      index: 'Profitability',
      childrenId: [
        'Gross_Profit_Margin',
        'EBITDA_Margin',
        'EBIT_Margin',
        'Pretax_Margin',
        'Net_Profit_Margin',
        'ROA',
        'ROE',
        'ROIC',
      ],
      name: 'sector.sectorConstituents.overview.PROFITABILITY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Gross_Profit_Margin',
      name: 'sector.sectorConstituents.overview.GROSS_PROFIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0050',
      isPercent: true,
    },
    {
      index: 'EBITDA_Margin',
      name: 'sector.sectorConstituents.overview.EBITDA_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0051',
      isPercent: true,
    },
    {
      index: 'EBIT_Margin',
      name: 'sector.sectorConstituents.overview.EBIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0052',
      isPercent: true,
    },
    {
      index: 'Pretax_Margin',
      name: 'sector.sectorConstituents.overview.PRETAX_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0053',
      isPercent: true,
    },
    {
      index: 'Net_Profit_Margin',
      name: 'sector.sectorConstituents.overview.NET_PROFIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0054',
      isPercent: true,
    },
    {
      index: 'ROA',
      name: 'sector.sectorConstituents.overview.ROA',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0059',
      isPercent: true,
    },
    {
      index: 'ROE',
      name: 'sector.sectorConstituents.overview.ROE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0060',
      isPercent: true,
    },
    {
      index: 'ROIC',
      name: 'sector.sectorConstituents.overview.ROIC',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0064',
      isPercent: true,
    },
    {
      index: 'Financial_Strength',
      childrenId: [
        'Current_Ratio',
        'Cash_Ratio',
        'Quick_Ratio',
        'Total_Debt/Equity',
        'Margin_Lending',
      ],
      name: 'sector.sectorConstituents.overview.FINANCIAL_STRENGTH',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Current_Ratio',
      name: 'sector.sectorConstituents.overview.CURRENT_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0020',
    },
    {
      index: 'Cash_Ratio',
      name: 'sector.sectorConstituents.overview.CASH_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0018',
    },
    {
      index: 'Quick_Ratio',
      name: 'sector.sectorConstituents.overview.QUICK_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0019',
    },
    {
      index: 'Total_Debt/Equity',
      name: 'sector.sectorConstituents.overview.TOTAL_DEBT_EQUITY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0027',
      isPercent: true,
    },
    {
      index: 'Margin_Lending',
      name: 'sector.sectorConstituents.overview.MARGIN_LENDING_OWNER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'MLOE',
      isPercent: true,
    },
    {
      index: 'Management_Efficiency',
      childrenId: [
        'Account_receivable_turnover',
        'Account_payable_turnover',
        'Revenue_per_employee',
        'Net_Income_per_employee',
      ],
      name: 'sector.sectorConstituents.overview.MANAGEMENT_EFFICIENCY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Account_receivable_turnover',
      name: 'sector.sectorConstituents.overview.ACCOUNT_RECEIVABLE_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0008',
    },
    {
      index: 'Account_payable_turnover',
      name: 'sector.sectorConstituents.overview.ACCOUNT_PAYABLE_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0012',
    },
    {
      index: 'Revenue_per_employee',
      name: 'sector.sectorConstituents.overview.REVENUE_PER_EMPLOYEE_M',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0004',
    },
    {
      index: 'Net_Income_per_employee',
      name: 'sector.sectorConstituents.overview.NET_INCOME_PER_EMPLOYEE_M',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0003',
    },
    {
      index: 'Valuation',
      childrenId: ['Pe', 'Ps', 'Pb', 'P/E_Forward', 'EV/EBITDA', 'EV/EBIT'],
      name: 'sector.sectorConstituents.overview.VALUATION',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Pe',
      name: 'sector.sectorConstituents.overview.PE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0022',
    },
    {
      index: 'Ps',
      name: 'sector.sectorConstituents.overview.PS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0025',
    },
    {
      index: 'Pb',
      name: 'sector.sectorConstituents.overview.PB',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0019',
    },
    {
      index: 'P/E_Forward',
      name: 'sector.sectorConstituents.overview.PE_FORWARD',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0065',
    },
    {
      index: 'EV/EBITDA',
      name: 'sector.sectorConstituents.overview.EV_EBITDA',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0027',
    },
    {
      index: 'EV/EBIT',
      name: 'sector.sectorConstituents.overview.EV_EBIT',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0028',
    },
    {
      index: 'Growth',
      childrenId: [
        'Net_Revenue_Growth_YoY',
        'EBITDA_Growth_YoY',
        'EBIT_Growth_YoY',
        'Total_Assets_Growth_YoY',
        'Owner_Equity_Growth_YoY',
      ],
      name: 'sector.sectorConstituents.overview.GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Net_Revenue_Growth_YoY',
      name: 'sector.sectorConstituents.overview.NET_REVENUE_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0156',
      isPercent: true,
    },
    {
      index: 'EBITDA_Growth_YoY',
      name: 'sector.sectorConstituents.overview.EBITDA_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0158',
      isPercent: true,
    },
    {
      index: 'EBIT_Growth_YoY',
      name: 'sector.sectorConstituents.overview.EBIT_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0159',
      isPercent: true,
    },
    {
      index: 'Total_Assets_Growth_YoY',
      name: 'sector.sectorConstituents.overview.TOTAL_ASSETS_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0172',
      isPercent: true,
    },
    {
      index: 'Owner_Equity_Growth_YoY',
      name: 'sector.sectorConstituents.overview.OWNERS_EQUITY_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0174',
      isPercent: true,
    },
  ],
  corporate: [
    {
      index: 'Profitability',
      childrenId: [
        'Gross_Profit_Margin',
        'EBITDA_Margin',
        'EBIT_Margin',
        'Pretax_Margin',
        'Net_Profit_Margin',
        'ROA',
        'ROE',
        'ROIC',
      ],
      name: 'sector.sectorConstituents.overview.PROFITABILITY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Gross_Profit_Margin',
      name: 'sector.sectorConstituents.overview.GROSS_PROFIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0050',
      isPercent: true,
    },
    {
      index: 'EBITDA_Margin',
      name: 'sector.sectorConstituents.overview.EBITDA_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0051',
      isPercent: true,
    },
    {
      index: 'EBIT_Margin',
      name: 'sector.sectorConstituents.overview.EBIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0052',
      isPercent: true,
    },
    {
      index: 'Pretax_Margin',
      name: 'sector.sectorConstituents.overview.PRETAX_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0053',
      isPercent: true,
    },
    {
      index: 'Net_Profit_Margin',
      name: 'sector.sectorConstituents.overview.NET_PROFIT_MARGIN',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0054',
      isPercent: true,
    },
    {
      index: 'ROA',
      name: 'sector.sectorConstituents.overview.ROA',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0059',
      isPercent: true,
    },
    {
      index: 'ROE',
      name: 'sector.sectorConstituents.overview.ROE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0060',
      isPercent: true,
    },
    {
      index: 'ROIC',
      name: 'sector.sectorConstituents.overview.ROIC',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0064',
      isPercent: true,
    },

    {
      index: 'Financial_Strength',
      childrenId: [
        'Current_Ratio',
        'Cash_Ratio',
        'Quick_Ratio',
        'Total_Debt/Equity',
        'Interest_Coverage',
      ],
      name: 'sector.sectorConstituents.overview.FINANCIAL_STRENGTH',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Current_Ratio',
      name: 'sector.sectorConstituents.overview.CURRENT_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0020',
    },
    {
      index: 'Cash_Ratio',
      name: 'sector.sectorConstituents.overview.CASH_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0018',
    },
    {
      index: 'Quick_Ratio',
      name: 'sector.sectorConstituents.overview.QUICK_RATIO',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0019',
    },
    {
      index: 'Total_Debt/Equity',
      name: 'sector.sectorConstituents.overview.TOTAL_DEBT_EQUITY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0027',
      isPercent: true,
    },
    {
      index: 'Interest_Coverage',
      name: 'sector.sectorConstituents.overview.INTEREST_COVERAGE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0044',
    },
    {
      index: 'Management_Efficiency',
      childrenId: [
        'Account_Receivable_Turnover',
        'Inventory_Turnover',
        'Account_Payable_Turnover',
        'Cash_Conversion_Cycle',
        'Capex_Sales',
        'Total_Asset_Turnover',
      ],
      name: 'sector.sectorConstituents.overview.MANAGEMENT_EFFICIENCY',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Account_Receivable_Turnover',
      name: 'sector.sectorConstituents.overview.ACCOUNT_RECEIVABLE_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0008',
    },
    {
      index: 'Inventory_Turnover',
      name: 'sector.sectorConstituents.overview.INVENTORY_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0010',
    },
    {
      index: 'Account_Payable_Turnover',
      name: 'sector.sectorConstituents.overview.ACCOUNT_PAYABLE_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0012',
    },
    {
      index: 'Cash_Conversion_Cycle',
      name: 'sector.sectorConstituents.overview.CASH_CONVERSION_CYCLE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0015',
    },
    {
      index: 'Capex_Sales',
      name: 'sector.sectorConstituents.overview.CAPEX_OR_SALES',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0007',
      isPercent: true,
    },
    {
      index: 'Total_Asset_Turnover',
      name: 'sector.sectorConstituents.overview.TOTAL_ASSET_TURNOVER',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0003',
    },
    {
      index: 'Valuation',
      childrenId: ['Pe', 'Ps', 'Pb', 'P/E_Forward', 'EV/EBITDA', 'EV/EBIT'],
      name: 'sector.sectorConstituents.overview.VALUATION',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Pe',
      name: 'sector.sectorConstituents.overview.PE',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0022',
    },
    {
      index: 'Ps',
      name: 'sector.sectorConstituents.overview.PS',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0025',
    },
    {
      index: 'Pb',
      name: 'sector.sectorConstituents.overview.PB',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0019',
    },
    {
      index: 'P/E_Forward',
      name: 'sector.sectorConstituents.overview.PE_FORWARD',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0065',
    },
    {
      index: 'EV/EBITDA',
      name: 'sector.sectorConstituents.overview.EV_EBITDA',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0027',
    },
    {
      index: 'EV/EBIT',
      name: 'sector.sectorConstituents.overview.EV_EBIT',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RTD0028',
    },
    {
      index: 'Growth',
      childrenId: [
        'Net_Revenue_Growth_YoY',
        'EBITDA_Growth_YoY',
        'EBIT_Growth_YoY',
        'Total_Assets_Growth_YoY',
        'Owner_Equity_Growth_YoY',
      ],
      name: 'sector.sectorConstituents.overview.GROWTH',
      level: TABLE_ROW_LEVEL.LEVEL_1,
      key: null,
      isCollapse: true,
    },
    {
      index: 'Net_Revenue_Growth_YoY',
      name: 'sector.sectorConstituents.overview.NET_REVENUE_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0156',
      isPercent: true,
    },
    {
      index: 'EBITDA_Growth_YoY',
      name: 'sector.sectorConstituents.overview.EBITDA_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0158',
      isPercent: true,
    },
    {
      index: 'EBIT_Growth_YoY',
      name: 'sector.sectorConstituents.overview.EBIT_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0159',
      isPercent: true,
    },
    {
      index: 'Total_Assets_Growth_YoY',
      name: 'sector.sectorConstituents.overview.TOTAL_ASSETS_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0172',
      isPercent: true,
    },
    {
      index: 'Owner_Equity_Growth_YoY',
      name: 'sector.sectorConstituents.overview.OWNERS_EQUITY_GROWTH_YOY',
      level: TABLE_ROW_LEVEL.LEVEL_2,
      key: 'RT0174',
      isPercent: true,
    },
  ],
}
