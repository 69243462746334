import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import { MONTH_LIST_FILTER_VI } from '../../exportImportByCommodities/exportStructure/constants'
import { CountriesSearch } from '../CountriesSearch'
import { TIME_TYPE } from '../exportImportTable/constants'
import { selectTypeFilter } from '../exportImportTable/store/slice'
import style from '../index.module.css'
import {
  ACC_MONTH_FILTER_EN,
  ACC_MONTH_FILTER_VI,
  MONTH_LIST_FILTER_EN,
  YEAR_LIST_FILTER,
} from './constants'
import {
  changeCurrentMonthStructureChart,
  changeCurrentYearStructureChart,
  selectCurrentMonth,
  selectCurrentYear,
  selectMaxDate,
} from './store/slice'

const FilterGroup = () => {
  const dispatch = useDispatch()

  const currentTypeFilter = useSelector(selectTypeFilter)
  const locale = useSelector((state) => state.i18n.locale)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const maxDate = useSelector(selectMaxDate)

  const getMonthFilterByLocale = (locale) => {
    if (currentTypeFilter === TIME_TYPE.ACC_MONTHLY) {
      return locale === 'en' ? ACC_MONTH_FILTER_EN : ACC_MONTH_FILTER_VI
    } else {
      return locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI
    }
  }

  const handleMonthChange = (item) => {
    dispatch(changeCurrentMonthStructureChart(item))
  }

  const handleYearChange = (item) => {
    dispatch(changeCurrentYearStructureChart(item))
  }

  return (
    <div className="d-flex j-b">
      <div className="row">
        <CountriesSearch />
      </div>
      <div className="row">
        <div
          className={`${style.inputDropdown}`}
          style={
            currentTypeFilter === TIME_TYPE.YEARLY
              ? { display: 'none' }
              : { width: '84px', marginRight: '4px' }
          }
        >
          <SelectCustom
            value={currentMonth ?? maxDate?.realMonth}
            isI18n={false}
            selectData={getMonthFilterByLocale(locale)}
            handleChange={handleMonthChange}
          />
        </div>
        <div className={`${style.inputDropdown}`} style={{ width: '84px' }}>
          <SelectCustom
            value={currentYear ?? maxDate?.realYear}
            isI18n={true}
            selectData={YEAR_LIST_FILTER}
            handleChange={handleYearChange}
          />
        </div>
      </div>
    </div>
  )
}

export default FilterGroup
