import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { sortObjByYear } from '../../../common/helper'
import { getStateBudgetBalance } from './thunk'

const initialState = {
  ids: [],
  dataById: {},
  isLoading: true,
  data: [],
  groupColumns: [],
  payloadData: [],
  initialIds: [],
  levels: [],
  rowsCollapse: [],
  minYear: new Date().getFullYear(),
}

const slice = createSlice({
  name: 'economy/fiscal/stateBudget/stateBudgetRevenue',
  initialState,
  reducers: {
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStateBudgetBalance.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getStateBudgetBalance.fulfilled, (state, action) => {
      state.isLoading = false

      const payloadNameData =
        action.payload?.nameData?.map((item) => ({
          ...item,
          id: item.isRevenue
            ? `${item.budgetRevenueExpenditureVNTypeId}_1`
            : `${item.budgetRevenueExpenditureVNTypeId}_0`,
          name: item.budgetRevenueExpenditureVNTypeName ?? '',
          level: item.budgetRevenueExpenditureVNTypeLevel,
          parentId: item.isRevenue
            ? `${item.parentBudgetRevenueExpenditureVNTypeId}_1`
            : `${item.parentBudgetRevenueExpenditureVNTypeId}_0`,
        })) || []

      let minYear = new Date().getFullYear()
      const payloadData =
        action.payload?.data?.map((item) => {
          if (item.year < minYear) {
            minYear = item.year
          }

          return {
            ...item,
            id: item.isRevenue
              ? `${item.budgetRevenueExpenditureVNTypeId}_1`
              : `${item.budgetRevenueExpenditureVNTypeId}_0`,
            name: item.budgetRevenueExpenditureVNTypeName ?? '',
            level: item.budgetRevenueExpenditureVNTypeLevel,
            parentId: item.isRevenue
              ? `${item.parentBudgetRevenueExpenditureVNTypeId}_1`
              : `${item.parentBudgetRevenueExpenditureVNTypeId}_0`,
            value: item.value / 1000000000,
          }
        }) || []

      const data = fullDataWithVal(payloadData, payloadNameData)
      state.ids = state.initialIds = data?.map((v) => v.id)
      state.payloadData = payloadData
      state.data = data
      state.dataById = keyBy(data, 'id')
      state.levels = payloadNameData
      state.minYear = minYear

      state.rowsCollapse = payloadNameData
        .filter((item) => item.level > 2)
        .map((item) => item.id)

      const getGroupColumns = data?.map((item) => Object.keys(item))[0]
      const filterGroupColumns = getGroupColumns
        ?.filter((item) => item.includes('20'))
        .sort((prevYear, currYear) => currYear - prevYear)

      const statusName = action.payload?.statusName || []

      const getStatusOfYear = (year) => {
        const status = statusName?.find((item) => item.year === Number(year))
        return status ? status.dataStatusName : ''
      }

      const groupColumns = []
      filterGroupColumns?.forEach((item) => {
        groupColumns.unshift({
          key: item,
          title: `${item}\n \t <dfn>${getStatusOfYear(item)}</dfn>`,
        })
      })

      state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
    })
    builder.addCase(getStateBudgetBalance.rejected, (state) => {
      state.isContentLoading = false
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectIds = (state) => state[slice.name].ids
export const selectDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectDataTable = (state) => state[slice.name].data
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectMinYear = (state) => state[slice.name].minYear

export const { sort, setRowsCollapse } = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (dataContainVal, dataWithOnlyTitle) => {
  const reOrderedYears = sortObjByYear(dataContainVal.map((item) => item.year))
  const getDataKeyFromRange = reOrderedYears

  const getDataByYear = (data, year) => {
    return data
      ?.filter((item) => item.year === year)
      ?.map((item) => ({
        value: item.value ?? 0,
        year: item.year ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange = getDataKeyFromRange.map((item) => [
    ...getDataByYear(dataContainVal, item),
  ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return dataWithOnlyTitle.map((item) => ({
    ...item,
    childrenIds: getChildrenIds(dataWithOnlyTitle, item.id),
    ...getKeyAndValRange(item),
  }))
}

const getChildrenIds = (data, id) => {
  return data
    .filter((item) => item.level > 1 && item.parentId === id)
    .map((item) => item.id)
}
