import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { ItemWithImage } from '../../../../common/news/ItemWithImage'
import { ALL_TYPE } from '../../../../common/news/constants'
import { getFilterDate } from '../../../../common/news/helper'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { ANNOUNCEMENT_TYPE } from '../constants'
import {
  resetMore,
  selectAnnouncementCategories,
  selectAnnouncementSources,
  selectMoreFilterData,
  selectNewsBusinessLoading,
  selectNewsBusinesses,
  selectNewsCategoriesLoading,
  selectNewsEarningLoading,
  selectNewsEarnings,
  selectNewsSourcesLoading,
  selectNewsTransactionLoading,
  selectNewsTransactions,
} from '../store/slice'
import {
  getNewsBusinessPaged,
  getNewsEarningPaged,
  getNewsTransactionsPaged,
} from '../store/thunk'

const NUM_COLUMN = 3

export const Grid = () => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const businesses = useSelector(selectNewsBusinesses)
  const earnings = useSelector(selectNewsEarnings)
  const transactions = useSelector(selectNewsTransactions)
  const sources = useSelector(selectAnnouncementSources)
  const categories = useSelector(selectAnnouncementCategories)
  const isBusinessLoading = useSelector(selectNewsBusinessLoading)
  const isTransactionLoading = useSelector(selectNewsTransactionLoading)
  const isEarningLoading = useSelector(selectNewsEarningLoading)
  const isSourcesLoading = useSelector(selectNewsSourcesLoading)
  const isCategoriesLoading = useSelector(selectNewsCategoriesLoading)

  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const filterData = useSelector(selectMoreFilterData)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const announcementType =
    params.get('announcementType') || ANNOUNCEMENT_TYPE.EARNING
  const timeZone = UseTimeZone()

  const fetchAPI = (page = 1) => {
    if (
      sources.length > 0 &&
      categories.length > 0 &&
      basicInfo.organizationId
    ) {
      const dates = getFilterDate(
        filterData.time,
        filterData.startDate,
        filterData.endDate,
      )
      if (dates.startDate && !dates.endDate) return

      const strCategoryParams =
        filterData.category === ALL_TYPE
          ? filterData.newsType === ALL_TYPE
            ? categories.map((item) => item.newsCategoryId).join(',')
            : filterData.newsType
          : filterData.category

      const params = {
        OrganizationId: basicInfo.organizationId,
        Page: page,
        PageSize: 30,
        NewsCategoryIds: strCategoryParams,
        NewsSourceIds:
          filterData.source === ALL_TYPE ? null : filterData.source,
        StartDate: dates.startDate
          ? getISOStartOrEndOfDay(dates.startDate, timeZone, true)
          : null,
        EndDate: dates.endDate
          ? getISOStartOrEndOfDay(dates.endDate, timeZone, false)
          : null,
        Term: filterData.search || null,
      }
      switch (announcementType) {
        case ANNOUNCEMENT_TYPE.BUSINESS:
          dispatch(getNewsBusinessPaged(params))
          break
        case ANNOUNCEMENT_TYPE.EARNING:
          dispatch(getNewsEarningPaged(params))
          break
        case ANNOUNCEMENT_TYPE.TRANSACTION:
          dispatch(getNewsTransactionsPaged(params))
          break
        default:
          throw Error('Pls set announcement type correctly!')
      }
    }
  }

  useEffect(() => {
    fetchAPI()
  }, [
    locale,
    basicInfo.organizationId,
    sources,
    categories,
    filterData,
    timeZone,
  ])

  useEffect(() => {
    return () => {
      dispatch(resetMore())
    }
  }, [])

  const getData = () => {
    switch (announcementType) {
      case ANNOUNCEMENT_TYPE.BUSINESS:
        return {
          data: businesses,
          isLoading: isBusinessLoading,
        }
      case ANNOUNCEMENT_TYPE.EARNING:
        return {
          data: earnings,
          isLoading: isEarningLoading,
        }
      case ANNOUNCEMENT_TYPE.TRANSACTION:
        return {
          data: transactions,
          isLoading: isTransactionLoading,
        }
      default:
        throw Error('Pls set announcement type correctly!')
    }
  }

  const { data: objectData, isLoading } = getData()
  const { data } = objectData

  if (isLoading || isSourcesLoading || isCategoriesLoading) {
    return (
      <div className="w-100 h-100 position-relative">
        <Loading />
      </div>
    )
  }

  if (sources.length === 0 || categories.length === 0 || data.length === 0) {
    return <NoData />
  }

  const onAction = (newsId) => () => {
    navigate(
      FEATURES.corporate.components['news-events'].components['news-detail']
        .route,
      basicInfo,
      '?newsId=' + newsId,
    )
  }

  const dataIgnoreFirstItem = data.filter((_, index) => index !== 0)
  const length = dataIgnoreFirstItem.length

  return (
    <ScrollComponent>
      <div className="d-flex w-100" style={{ flexWrap: 'wrap' }}>
        {dataIgnoreFirstItem.map((item, index) => {
          if (index % NUM_COLUMN === 0) {
            return (
              <div
                style={{
                  width: `calc(100%/${NUM_COLUMN} - 20px)`,
                  marginRight: 20,
                }}
                key={index}
              >
                <ItemWithImage
                  item={item}
                  onAction={onAction(item.newsId)}
                  isItemInLastRow={index > length - NUM_COLUMN}
                />
              </div>
            )
          }

          if (index % NUM_COLUMN === NUM_COLUMN - 1) {
            return (
              <div
                style={{
                  width: `calc(100%/${NUM_COLUMN} - 20px)`,
                  marginLeft: 20,
                }}
                key={index}
              >
                <ItemWithImage
                  item={item}
                  onAction={onAction(item.newsId)}
                  isItemInLastRow={index > length - NUM_COLUMN}
                />
              </div>
            )
          }

          return (
            <div
              style={{
                width: `calc(100%/${NUM_COLUMN} - 20px)`,
                marginLeft: 10,
                marginRight: 10,
              }}
              key={index}
            >
              <ItemWithImage
                item={item}
                onAction={onAction(item.newsId)}
                isItemInLastRow={index > length - NUM_COLUMN}
              />
            </div>
          )
        })}
      </div>
    </ScrollComponent>
  )
}
