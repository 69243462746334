import { createAsyncThunk } from '@reduxjs/toolkit'
import DerivativeService from '../../../../../core/services/market/DerivativeService'

export const getDerivative = createAsyncThunk(
  'market/derivation/GET_DERIVATIVE',
  async (params, { rejectWithValue }) => {
    const response = await DerivativeService.getDerivative(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
