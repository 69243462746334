import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { Timefrequency } from '../constants'
import { selectActiveTab, updateTab } from '../store/slice'
import style from './style.module.css'

const FilterCountry = () => {
  const dispatch = useDispatch()
  const active = useSelector(selectActiveTab)
  const onChangeTab = (e) => {
    dispatch(updateTab(e.value))
  }
  return (
    <div className={`${style.financialMetricHeader}`}>
      <DispatchActionTab
        onChangeTab={(e) => onChangeTab(e)}
        data={Timefrequency.map((item) => ({
          title: item.label,
          value: item.value,
        }))}
        activeTab={active}
        textSize={12}
      />
    </div>
  )
}
export default FilterCountry
