import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AVERAGE_FUNDING_COST } from '.'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TimeFrame from '../../common/TimeFrame'
import { TIME_FRAME_OPTIONS_MONTHLY } from '../../exchangeRate/panelExchangeRateVolatility/constants'
import LineChart from './LineChart'
import {
  getLineChartData,
  getLineChartLoading,
  getTimeRange,
  handleTimeRange,
} from './store/slice'
import { getMovementDepositAndLending } from './store/thunk'

const ChartComponent = ({ height, width }) => {
  const dispatch = useDispatch()

  const timeRange = useSelector(getTimeRange)
  const lineChartData = useSelector(getLineChartData)
  const lineChartLoading = useSelector(getLineChartLoading)
  const locale = useSelector((state) => state.i18n.locale)

  const onChangeTab = (tab) => {
    dispatch(handleTimeRange(tab.value))
  }

  useEffect(() => {
    dispatch(
      getMovementDepositAndLending({
        TimeRange: timeRange,
      }),
    )
  }, [locale, timeRange])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <TimeFrame
              timeRange={timeRange}
              onChangeTab={onChangeTab}
              styleProps={{ justifyContent: 'flex-end' }}
              timeFrameOption={TIME_FRAME_OPTIONS_MONTHLY}
            />
            {lineChartLoading && <Loading />}
            {!lineChartLoading && (
              <>
                {lineChartData.length === 0 && (
                  <div
                    style={{ height: sizes.height ? height - sizes.height : 0 }}
                  >
                    <NoData />
                  </div>
                )}
                {lineChartData.length > 0 && (
                  <>
                    {typeof sizes.height === 'number' && (
                      <div id={AVERAGE_FUNDING_COST}>
                        <LineChart
                          width={width}
                          height={height - sizes.height}
                          data={lineChartData}
                          keyXAxis={'date'}
                          locale={locale}
                          timeRange={timeRange}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
