import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'
import { handleExport } from '../../../../../../utils/Export'

export const getBankDepositFromCustomer = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositFromCustomer',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankDepositFromCustomer(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankDepositMarketShare = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankDepositMarketShare(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankDepositChangeInMarketShare = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositChangeInMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankDepositChangeInMarketShare(
          params,
        )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankDepositCasa = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositCasa',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getBankDepositCasa(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankDepositCasaMarketShare = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositCasaMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankDepositCasaMarketShare(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankDepositDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/getBankDepositDetailMetrics',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankDepositDetailMetrics(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadBankDepositDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/bank/deposit/downloadBankDepositDetailMetrics',
  async (data) => {
    const response =
      await PenetrationAnalysisService.downloadBankDepositDetailMetrics(data)
    handleExport(response.data, response.filename)
  },
)
