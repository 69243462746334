import style from './index.module.css'
import { LeftContent } from './leftContent'
import RightContent from './rightContent'
import { Search } from '../../search'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { useSelector } from 'react-redux'
import { selectIsLoading } from '../store/slice'
import { Loading } from '../../loading'

export const Content = ({ data, changePopUpStatus, hasDeleteTemplate }) => {
  const isLoading = useSelector(selectIsLoading)
  const [searchText, setSearchText] = useState('')
  const onSearch = (value) => {
    setSearchText(value)
  }

  const filterData = data.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase()),
  )

  return (
    <div className={style.popupContent}>
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <Search onChange={onSearch} margin="0" />
          {filterData.length === 0 && (
            <div style={{ marginTop: 12 }}>
              <Translate value="common.NO_DATA" />
            </div>
          )}
          {filterData.length !== 0 && (
            <div className="d-flex">
              <LeftContent data={filterData} />
              <RightContent
                changePopUpStatus={changePopUpStatus}
                hasDeleteTemplate={hasDeleteTemplate}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}
