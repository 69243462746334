import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../../utils/Export'

export const getOverviewStatistics = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getOverviewStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getOverviewStatistics(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIssuerOrganizationIds = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getIssuerOrganizationIds',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getIssuerOrganizationIds(
      params,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getUnderlyingIds = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/getUnderlyingIds',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getUnderlyingIds(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadOverviewStatistics = createAsyncThunk(
  'market/coveredWarrant/statistics/overviewStatistics/downloadOverviewStatistics',
  async (data) => {
    const response = await CoveredWarrantService.downloadOverviewStatistics(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
