import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { TIME_RANGE } from '../../common/chartCompanyGrowth/constants'
import { TYPE_STATISTIC } from '../priceStatistics/constants'
import {
  removeItemFromList,
  selectCurrentTypeStatistic,
  selectListCheckedItems,
} from '../priceStatistics/store/slice'
import { ChartComponent } from './ChartComponent'
import { COLORS_LINE_CHART } from './constants'
import { getItemName } from './helper'
import {
  changeActiveItem,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  changeDateType,
  selectCurrentDateType,
  selectCurrentDateTypeYearly,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { getPriceGrowth } from './store/thunk'

export const PriceGrowth = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const listCheckedItems = useSelector(selectListCheckedItems)
  const typeStatistic = useSelector(selectCurrentTypeStatistic)
  const currentDateType = useSelector(selectCurrentDateType)
  const currentDateTypeYearly = useSelector(selectCurrentDateTypeYearly)
  const isLoading = useSelector(selectLoading)

  const headerChart = useRef()
  const legendPanel = useRef()

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListItems, setHeightListItems] = useState(0)

  const getIdsInListCheckedItems = listCheckedItems?.map((item) => item.id)

  const isYearly = typeStatistic === TYPE_STATISTIC.YEARLY

  const listItemIdsForChart = listCheckedItems?.map((item) => ({
    steelProductTypeId: item.steelProductTypeId,
    organizationId: item.organizationId,
  }))

  // Actions
  const handleChangeDateType = (item) => {
    dispatch(changeDateType())
    return isYearly
      ? dispatch(changeCurrentDateTypeYearly(item.value))
      : dispatch(changeCurrentDateType(item.value))
  }

  const handleDelete = (item) => {
    dispatch(removeItemFromList(item.id))
    dispatch(changeActiveItem(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveItem(item.id))
  }

  const handleUnHover = () => {
    dispatch(changeActiveItem(null))
  }

  // Use effect
  useEffect(() => {
    if (headerChart.current?.offsetHeight && heightListItems)
      setHeightSizeTracker(
        height - headerChart.current?.offsetHeight - heightListItems,
      )
  }, [height, width, listCheckedItems, heightListItems])

  useEffect(() => {
    if (listItemIdsForChart.length) {
      dispatch(
        getPriceGrowth({
          timeFrequency: isYearly ? 'Yearly' : 'Monthly',
          timeRange: 'AllTime',
          steelProductTypeOrganizations: listItemIdsForChart,
        }),
      )
    }
  }, [currentDateType, currentDateTypeYearly, listCheckedItems])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <>
      <div
        ref={headerChart}
        className="d-flex justify-content-end box-price-chart"
      >
        <DispatchActionTab
          data={isYearly ? TIME_RANGE.YEARLY : TIME_RANGE.MONTHLY}
          activeTab={isYearly ? currentDateTypeYearly : currentDateType}
          onChangeTab={handleChangeDateType}
        />
      </div>
      {!dataChart.length ? (
        <NoData />
      ) : (
        <div id="priceGrowth">
          <ChartComponent
            data={dataChart}
            width={width}
            height={heightSizeTracker}
            keyXAxis={'time'}
            lineKeys={getIdsInListCheckedItems}
            isYearly={isYearly}
          />
          <FooterWithScroll
            width={width}
            data={listCheckedItems}
            idKey="id"
            nameKey="name"
            formatName={getItemName}
            color={(item) => COLORS_LINE_CHART[item.colorId]}
            legendPanel={legendPanel}
            isFullScreen={isFullScreen}
            setHeightFooter={setHeightListItems}
            onDelete={handleDelete}
            onHover={handleHover}
            onUnHover={handleUnHover}
          />
        </div>
      )}
    </>
  )
}
