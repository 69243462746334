import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Panel } from '../../../common/panel'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../common/sizeTracker'
import DispatchActionTab, {
  TIME_RANGES,
} from '../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import EventEmitter, { RESET_TABLE_SORT } from '../../../utils/EventEmitter'
import style from '../index.module.css'
import { changeTimeRange, selectTimeRange } from '../store/slice'
import {
  downloadSectorInDepthThunk,
  getLastestSectorThunk,
  getSectorInDepthThunk,
} from '../store/thunk'
import ContentTableSector from './ContentTableSector'
import ContentTableSector1D from './ContentTableSector1D'

const listTab = [
  { title: TIME_RANGES['1D'], value: TIME_RANGES['1D'] },
  { title: TIME_RANGES['1W'], value: TIME_RANGES['1W'] },
  { title: TIME_RANGES['1M'], value: TIME_RANGES['1M'] },
  { title: TIME_RANGES['3M'], value: TIME_RANGES['3M'] },
  { title: TIME_RANGES['6M'], value: TIME_RANGES['6M'] },
  { title: TIME_RANGES['1Y'], value: TIME_RANGES['1Y'] },
]

export const timeRangeApi = {
  [TIME_RANGES['1D']]: 'OneDay',
  [TIME_RANGES['1W']]: 'OneWeek',
  [TIME_RANGES['1M']]: 'OneMonth',
  [TIME_RANGES['3M']]: 'ThreeMonths',
  [TIME_RANGES['6M']]: 'SixMonths',
  [TIME_RANGES['1Y']]: 'OneYear',
}

export const listLevel = [
  {
    value: 1,
    name: (
      <span>
        <Translate value="market.sectorInDepth.ICB_LEVEL" /> 1
      </span>
    ),
  },
  {
    value: 2,
    name: (
      <span>
        <Translate value="market.sectorInDepth.ICB_LEVEL" /> 2
      </span>
    ),
  },
  {
    value: 3,
    name: (
      <span>
        <Translate value="market.sectorInDepth.ICB_LEVEL" /> 3
      </span>
    ),
  },
  {
    value: 4,
    name: (
      <span>
        <Translate value="market.sectorInDepth.ICB_LEVEL" /> 4
      </span>
    ),
  },
]

const TableSector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const indexInfo = useSelector(selectIndexInfo)
  const tabActive = useSelector(selectTimeRange)

  const [level, setLevel] = useState(listLevel[1].value)

  useEffect(() => {
    if (indexInfo.groupId && tabActive !== TIME_RANGES['1D']) {
      dispatch(
        getSectorInDepthThunk({
          GroupId: indexInfo.groupId,
          Period: timeRangeApi[tabActive],
        }),
      )
    }
  }, [tabActive, indexInfo.groupId])

  useEffect(() => {
    if (indexInfo.groupId && tabActive === TIME_RANGES['1D']) {
      dispatch(
        getLastestSectorThunk({
          GroupId: indexInfo.groupId,
          IcbLevel: level,
        }),
      )
    }
  }, [indexInfo.groupId, tabActive, level])

  const handleChangeTab = (item) => {
    EventEmitter.dispatch(RESET_TABLE_SORT)
    dispatch(changeTimeRange(item.value))
  }

  return (
    <Panel
      title="market.sectorInDepth.SECTOR_STATISTIC"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadSectorInDepthThunk({
          GroupId: indexInfo.groupId,
          Period: timeRangeApi[tabActive],
          ICBLevel: level,
        })
      }
    >
      <SizeTracker>
        {(size) => (
          <>
            <div className={`d-flex j-b ali-center ${style.pb11px}`}>
              <Span style={{ fontSize: 11 }}>
                <i className={style.noteTable}>
                  <Translate value="common.table.TABLE_FOOTER_TEXT" />
                </i>
              </Span>
              <div>
                <DispatchActionTab
                  data={listTab}
                  activeTab={tabActive}
                  onChangeTab={handleChangeTab}
                  itemStyle={{ fontWeight: 600, fontSize: 10 }}
                />
              </div>
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {tabActive === TIME_RANGES['1D'] ? (
                  <PreOpenMarket>
                    <ContentTableSector1D level={level} setLevel={setLevel} />
                  </PreOpenMarket>
                ) : (
                  <ContentTableSector level={level} setLevel={setLevel} />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

TableSector.propTypes = {
  ICBs: PropTypes.array.isRequired,
  loadingICB: PropTypes.bool.isRequired,
}

export default TableSector
