import moment from 'moment'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../../../common/chart/helper'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { VIEW_TYPE } from '../constants'

export const getDataTitle = (timeFilter, item, locale, timezone) => {
  switch (timeFilter) {
    case VIEW_TYPE.YEAR: {
      if (item.tradingDateId) {
        return formatDateTime(
          new Date(item.tradingDateId),
          FORMAT.DATE,
          locale,
          timezone,
        )
      } else if (item.realYear) {
        return item.isTTM
          ? `Q${item.realQuarter}/${item.realYear}\n(TTM)`
          : item.realYear
      } else {
        return ''
      }
    }
    case VIEW_TYPE.QUARTER: {
      if (item.tradingDateId) {
        return formatDateTime(
          new Date(item.tradingDateId),
          FORMAT.DATE,
          locale,
          timezone,
        )
      } else if (item.realYear && item.realQuarter) {
        return `Q${item.realQuarter}/${item.realYear}`
      } else {
        return ''
      }
    }
    default:
      return item.realYear || item.tradingDateId
  }
}

export const getMarginLineChart = (chartOption, data, fontSize) => {
  const { leftKeys, rightKeys } = chartOption.props

  // Y axis chart left
  const [leftMin, leftMax] =
    leftKeys && leftKeys.length
      ? yExtentsCharts(data, leftKeys, false, true)
      : [0, 0]
  const leftTickValues =
    leftKeys && leftKeys.length ? getRoundedTickValues([leftMin, leftMax]) : []
  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  // Y axis chart right
  const [rightMin, rightMax] =
    rightKeys && rightKeys.length
      ? yExtentsCharts(data, rightKeys, false, true)
      : [0, 0]
  const rightTickValues =
    rightKeys && rightKeys.length
      ? getRoundedTickValues([rightMin, rightMax])
      : []
  const rightDecimalLength = getDecimalLengthYAxis(rightTickValues)

  return getChartMargin(
    leftTickValues,
    rightTickValues,
    leftDecimalLength,
    rightDecimalLength,
    fontSize,
  )
}

export const getMarginStackChart = (chartOption, data, fontSize) => {
  const { barKeys, lineKeys } = chartOption.props

  // Y axis chart left
  const [leftMin, leftMax] = yExtentsCharts(data, barKeys, true)
  const leftTickValues = getRoundedTickValues([leftMin, leftMax])
  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  // Y axis chart right
  const [rightMin, rightMax] =
    lineKeys && lineKeys.length
      ? yExtentsCharts(data, lineKeys, false, true, true)
      : [0, 0]
  const rightTickValues =
    lineKeys && lineKeys.length
      ? getRoundedTickValues([rightMin, rightMax])
      : []
  const rightDecimalLength = getDecimalLengthYAxis(rightTickValues)

  return getChartMargin(
    leftTickValues,
    rightTickValues,
    leftDecimalLength,
    rightDecimalLength,
    fontSize,
  )
}

export const getMarginGroupChart = (chartOption, data, fontSize) => {
  const { barKeys, lineKeys } = chartOption.props

  // Y axis chart left
  const [leftMin, leftMax] = yExtentsCharts(data, barKeys)
  const leftTickValues = getRoundedTickValues([leftMin, leftMax])
  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  // Y axis chart right
  const [rightMin, rightMax] =
    lineKeys && lineKeys.length
      ? yExtentsCharts(data, lineKeys, false, true, true)
      : [0, 0]
  const rightTickValues =
    lineKeys && lineKeys.length
      ? getRoundedTickValues([rightMin, rightMax])
      : []
  const rightDecimalLength = getDecimalLengthYAxis(rightTickValues)

  return getChartMargin(
    leftTickValues,
    rightTickValues,
    leftDecimalLength,
    rightDecimalLength,
    fontSize,
  )
}

export const getCompareMargin = (margins) => {
  const marginLefts = margins.map((item) => item.left || 0)
  const marginRights = margins.map((item) => item.right || 0)

  return {
    left: Math.max(...marginLefts),
    right: Math.max(...marginRights),
  }
}

export const getChartDataFormat = (data, options) => {
  if (!data) return []
  const formatData = data
    .map((item) => {
      const obj = {}
      Object.keys(item).forEach((key) => {
        if (item[key]) {
          if (options.dataFormat[key]) {
            obj[key] = item[key] / options.dataFormat[key]
          } else {
            obj[key] = item[key]
          }
        } else {
          obj[key] = 0
        }
      })
      return obj
    })
    .sort((a, b) => {
      if (a.tradingDateId && b.tradingDateId) {
        return (
          new Date(b.tradingDateId).getTime() -
          new Date(a.tradingDateId).getTime()
        )
      } else if (a.realQuarter && b.realQuarter) {
        return (
          moment().set('year', b.realYear).quarter(b.realQuarter).unix() -
          moment().set('year', a.realYear).quarter(a.realQuarter).unix()
        )
      } else {
        return (
          moment().set('year', b.realYear).unix() -
          moment().set('year', a.realYear).unix()
        )
      }
    })
    .reverse()
  const ttmData = formatData.filter((item) => item.isTTM)
  const otherData = formatData.filter((item) => !item.isTTM)
  return [...otherData, ...ttmData]
}
