import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import { getAllChildrenItem } from '../../tree/helper'
import style from './index.module.css'

export const valueTypeAdd = {
  MULTIPLE: 'multiple',
  SINGLE: 'single',
}

const typeAdds = [
  { value: valueTypeAdd.SINGLE, label: 'tool.dataExplorer.ADD_ONE' },
  { value: valueTypeAdd.MULTIPLE, label: 'tool.dataExplorer.ADD_ALL' },
]

const PopupNoticeAddSector = ({
  handleClose,
  handleSubmit,
  icb,
  zIndex,
  zIndexOverlay,
}) => {
  const [typeAdd, setTypeAdd] = useState(valueTypeAdd.SINGLE)

  const submit = () => {
    if (typeAdd === valueTypeAdd.SINGLE) {
      handleSubmit([icb])
    } else {
      handleSubmit(getAllChildrenItem(icb, true))
    }
    handleClose()
  }

  return (
    <Popup zIndex={zIndex} zIndexOverlay={zIndexOverlay}>
      <div className="modal modal-small">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.dataExplorer.NOTICE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className="content">
            {typeAdds.map((item, index) => (
              <label
                key={item.value}
                onClick={() => setTypeAdd(item.value)}
                className={`cursor-pointer d-flex ali-center ${
                  typeAdd === item.value ? style.radioActive : ''
                } ${index !== 0 ? style.mt15 : ''}`}
              >
                <input
                  type="radio"
                  className={`radiobox radiobox2 cursor-pointer ${style.radio} ${style.mr9}`}
                  checked={typeAdd === item.value}
                  readOnly
                />
                <Translate value={item.label} />
              </label>
            ))}
          </div>
          <div className={`group-btn justify-content-end ${style.mt45}`}>
            <Button
              onClick={handleClose}
              className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
              style={{ fontSize: 11 }}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              onClick={submit}
              className={`btn btn-blue w-80 h-20 ${style.fontWeightNormal}`}
              style={{ fontSize: 11 }}
            >
              <Translate value="common.button.BUTTON_APPLY" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupNoticeAddSector.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  icb: PropTypes.object.isRequired,
  zIndex: PropTypes.number,
  zIndexOverlay: PropTypes.number,
}

export default PopupNoticeAddSector
