import PropTypes from 'prop-types'
import TextEllipsis from '../../../common/textEllipsis'

export const PADDING_TITLE = 12

const TitleChart = ({ width, nameChart }) => {
  const { bold, fontSize, italic, name, position, underline } = nameChart

  return (
    <div
      className="t-center d-flex"
      style={{
        width: width - PADDING_TITLE * 2,
        paddingTop: 38,
        justifyContent: position,
        margin: 'auto',
        paddingLeft: PADDING_TITLE,
        paddingRight: PADDING_TITLE,
      }}
    >
      <TextEllipsis
        appendStyle={{
          opacity: width ? 1 : 0,
          fontSize: fontSize,
          fontWeight: bold ? 600 : '400',
          transform: italic ? 'skew(-20deg)' : '',
          textDecoration: underline ? 'underline' : '',
        }}
        isI18n={false}
        text={name}
        className="canvas-chart-title"
      />
    </div>
  )
}

export default TitleChart

TitleChart.propTypes = {
  nameChart: PropTypes.object,
}

TitleChart.defaultProps = {
  nameChart: {},
}
