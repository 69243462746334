import { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import {
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import DataCell from './DataCell'
import NetValueTypeTab from './NetValueTypeTab'
import TickerCell, { TOP_NET_VALUE_TABLE_ID } from './TickerCell'
import TimeFrame from './TimeFrame'
import {
  TIME_FRAME_TOP_NET_VALUE,
  TOP_COUNT,
  VALUE_TYPE,
  indicator,
} from './constants'
import style from './index.module.css'
import {
  resetStore,
  selectIds,
  selectLoading,
  selectReCalcWidths,
  selectTimeFrame,
  selectType,
  selectValueById,
  sort,
  subscribeStock,
} from './store/slice'
import { getTopNetValue } from './store/thunk'

const TopNetValue = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const indexInfo = useSelector(selectIndexInfo)
  const topNetValueIds = useSelector(selectIds)
  const timeFrame = useSelector(selectTimeFrame)
  const type = useSelector(selectType)
  const loading = useSelector(selectLoading)
  const reCalcWidths = useSelector(selectReCalcWidths)

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(
        getTopNetValue({
          GroupId: indexInfo.groupId,
          Period: timeFrame,
          IsBuy: type === VALUE_TYPE.BUY,
          Top: TOP_COUNT,
        }),
      )
    }
  }, [timeFrame, indexInfo?.groupId, type, locale])

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  const WrapperTable =
    timeFrame === TIME_FRAME_TOP_NET_VALUE.ONE_DAY.value
      ? PreOpenMarket
      : Fragment

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex justify-content-space-between">
                <NetValueTypeTab />
                <TimeFrame />
              </div>
              <Span style={{ fontSize: 11 }} className={style.spanTableInfo}>
                <Translate value="market.marketInDepth.foreign.NOTE_TOP_NET_TABLE" />
              </Span>
              {size.height && (
                <div
                  id={TOP_NET_VALUE_TABLE_ID}
                  style={{ height: `calc(100% - ${size.height}px)` }}
                  className="position-relative"
                >
                  {loading ? (
                    <Loading />
                  ) : (
                    <WrapperTable>
                      <Table
                        ids={topNetValueIds}
                        getDataFromRedux={selectValueById}
                        isLoading={loading}
                        schema={Object.keys(indicator).map((key, index) => {
                          const title = `market.marketInDepth.foreign.${key}`
                          const colId = indicator[key]
                          const result = {
                            colId,
                            title,
                            isI18n: false,
                            thStyle: {
                              textAlign: 'right',
                              fontSize: 10,
                            },
                            tdStyle: {
                              textAlign: 'right',
                            },
                          }

                          if (index === 0) {
                            return {
                              ...result,
                              thStyle: {
                                textAlign: 'left',
                                fontSize: 10,
                              },
                              tdStyle: {
                                textAlign: 'left',
                              },
                              render: (val, rowId) => (
                                <TickerCell val={val} rowId={rowId} />
                              ),
                            }
                          }

                          if ([1, 2].includes(index)) {
                            return {
                              ...result,
                              canCustomTd: true,
                              render: (val, rowId, { style, className }) => (
                                <DataCell
                                  value={val}
                                  format={valDivThousand}
                                  style={style}
                                  className={className}
                                />
                              ),
                            }
                          }

                          if ([3, 4].includes(index)) {
                            return {
                              ...result,
                              canCustomTd: true,
                              render: (val, rowId, { style, className }) => (
                                <DataCell
                                  value={val}
                                  format={valDivMillion}
                                  style={style}
                                  className={className}
                                />
                              ),
                            }
                          }

                          if (index === 5) {
                            return {
                              ...result,
                              canCustomTd: true,
                              render: (val, rowId, { style, className }) => (
                                <DataCell
                                  value={val}
                                  format={valDivMillion}
                                  style={style}
                                  className={className}
                                />
                              ),
                              tdStyle: {
                                ...result.tdStyle,
                                color:
                                  type === VALUE_TYPE.BUY
                                    ? '#59c761'
                                    : '#ff4752',
                              },
                            }
                          }

                          if (index === 6) {
                            return {
                              ...result,
                              render: (val) => valToPercent(val),
                            }
                          }

                          return result
                        })}
                        sort={sort}
                        reCalcWidths={reCalcWidths}
                        hasFooter={false}
                        stickyFirstColumn
                      />
                    </WrapperTable>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
      {timeFrame === TIME_FRAME_TOP_NET_VALUE.ONE_DAY.value &&
        !!topNetValueIds.length &&
        topNetValueIds.map((id) => (
          <Realtime
            channel={ChannelConfig.StockChannel + id}
            action={subscribeStock}
            key={id}
          >
            <></>
          </Realtime>
        ))}
    </>
  )
}

export default TopNetValue
