import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Area, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FORMAT } from '../../../../utils/Datetime'
import {
  areaKeys,
  fillColor,
  keyXAxis,
  lineKeys,
  listIconAreaChart,
} from './constants'

export const AreaLineChartComponent = ({ data, width, height, dateType }) => {
  // Use selector
  const basicInfo = useSelector(selectBasicInfo)

  // Get data
  const mappingDisplayName = {
    buyUpVolume: 'market.equityTrading.deepTransaction.timeSale.BU_VOLUME',
    sellDownVolume: 'market.equityTrading.deepTransaction.timeSale.SD_VOLUME',
    matchPrice: basicInfo.ticker,
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: areaKeys,
        yAxisId: 'areaChart',
        unit: 'market.equityTrading.deepTransaction.timeSale.THOUSAND',
        decimalLength: 2,
      },
      {
        keys: lineKeys,
        yAxisId: 'lineChart',
        decimalLength: 2,
      },
    ]
  }

  // Render
  const renderLinearGradient = () => {
    return (
      <defs>
        <linearGradient id={areaKeys[0]} x1="0" y1="0" x2="0" y2="1">
          <stop offset="45%" stopColor="#37a77d" stopOpacity={0.5} />
          <stop offset="55%" stopColor="rgb(55, 167, 125)" stopOpacity={0.5} />
        </linearGradient>
        <linearGradient id={areaKeys[1]} x1="0" y1="0" x2="0" y2="1">
          <stop offset="30%" stopColor="#e84855" stopOpacity={0.5} />
          <stop offset="60%" stopColor="rgba(255, 81, 81)" stopOpacity={0.5} />
        </linearGradient>
      </defs>
    )
  }

  const renderAreaChart = () => {
    return areaKeys.map((key) => (
      <Area
        key={key}
        dataKey={key}
        fill={`url(#${key})`}
        yAxisId="areaChart"
        stroke={fillColor[key]}
        fillOpacity={1}
        isAnimationActive={false}
        activeDot={false}
      />
    ))
  }

  const renderLineChart = () => {
    return lineKeys.map((key) => (
      <Line
        key={key}
        yAxisId="lineChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={2}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <div
              style={{ height: `calc(100% - ${size.height}px)` }}
              className="position-relative"
            >
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                yAxis={[
                  {
                    id: 'areaChart',
                    keys: areaKeys,
                    orientation: 'left',
                    label: I18n.t(
                      'market.equityTrading.deepTransaction.timeSale.VOLUME_SHARE',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                  },
                  {
                    id: 'lineChart',
                    keys: lineKeys,
                    orientation: 'right',
                    isLineChart: true,
                    label: I18n.t(
                      'market.equityTrading.deepTransaction.timeSale.PRICE_VND',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  },
                ]}
                timeFrame={dateType}
                tooltipSchema={{
                  title: { formatDate: FORMAT.DATE_TIME },
                  content: getContent(),
                  mappingDisplayName: mappingDisplayName,
                }}
              >
                {renderLinearGradient()}
                {renderAreaChart()}
                {renderLineChart()}
              </ChartContainer>
            </div>
          )}
          <Footer key={width} list={listIconAreaChart(basicInfo.ticker)} />
        </>
      )}
    </SizeTracker>
  )
}

AreaLineChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dateType: PropTypes.string.isRequired,
}
