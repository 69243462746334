import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialDataService from '../../../../../../core/services/corporate/FinancialDataService'

export const getFinancialRatioChartData = createAsyncThunk(
  'corporate/financialData/financialRatio/chart/GET_FINANCIAL_RATIO_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialRatioChartData(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getPeersComparisonChartData = createAsyncThunk(
  'corporate/financialData/financialRatio/GET_PEERS_COMPARISON_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getPeersComparisonChartData(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
