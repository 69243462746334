import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Translate } from 'react-redux-i18n'
import { DynamicInput } from '../../common/formValidate/DynamicInput'
import { Span } from '../../common/html/Span'
import TextEllipsis from '../../common/textEllipsis'
import style from '../index.module.css'

const NameInput = ({ form }) => {
  const nameValue = form.useValueInput('watchListName')

  useEffect(() => {
    if (nameValue?.[0] === ' ')
      form.changeValues('watchListName', nameValue.trimStart())
  }, [nameValue])

  return (
    <DynamicInput
      typeInput="text"
      nameInput="watchListName"
      touchOnBlur={true}
      form={form}
      classInput="bg-grey"
      classMessage={`text-error ml-8 ${style.inline}`}
    />
  )
}

NameInput.propTypes = {
  form: PropTypes.object.isRequired,
}

const WatchlistInformation = ({ form }) => {
  useEffect(() => {
    return () => form.resetForm()
  }, [])

  return (
    <>
      <div
        style={{ marginBottom: '16px' }}
        className="form-control d-flex ali-center"
      >
        <label className="mb-0">
          <Span>
            <Translate value="watchlist.watchlistDetail.NAME" />
          </Span>
        </label>
        <NameInput form={form} />
      </div>
      <div className="form-control d-flex ali-center">
        <label className={`${style.textEllipsis} mb-0`}>
          <div className={style.w70px}>
            <Span>
              <TextEllipsis text="watchlist.watchlistDetail.DESCRIPTION" />
            </Span>
          </div>
        </label>
        <DynamicInput
          typeInput="text"
          nameInput="description"
          touchOnBlur={true}
          form={form}
          classInput="bg-grey"
          classMessage={`text-error ml-8 ${style.inline}`}
        />
      </div>
    </>
  )
}

WatchlistInformation.propTypes = {
  form: PropTypes.object.isRequired,
}

export default WatchlistInformation
