import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import PanelNetValue from './PanelNetValue'
import PanelSectorSegmentation from './PanelSectorSegmentation'
import PanelTopNetValue from './PanelTopNetValue'

const MAP_KEY = {
  NET_VALUE: 'NET_VALUE',
  TOP_NET_VALUE: 'TOP_NET_VALUE',
  SECTOR_SEGMENTATION: 'SECTOR_SEGMENTATION',
}

const MIN_WIDTH = 500
const MIN_HEIGHT = 600

const MAP_SIZE = {
  [`${MAP_KEY.NET_VALUE}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(42% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 42) / 100 - COMPONENT.MARGIN,
    verticalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.TOP_NET_VALUE,
        ratioWidth: -1,
        ratioLeft: 0,
      },
      {
        key: MAP_KEY.SECTOR_SEGMENTATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.TOP_NET_VALUE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.TOP_NET_VALUE}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(58% - ${COMPONENT.MARGIN}px)`,
    top: `calc(42% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minTop: (MIN_HEIGHT * 42) / 100 + COMPONENT.MARGIN,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 58) / 100 - COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.SECTOR_SEGMENTATION}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: `calc(100% / 2 + ${COMPONENT.MARGIN}px)`,
    minTop: 0,
    minLeft: MIN_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelNetValue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NET_VALUE}
          sizes={sizes}
          setSizes={setSizes}
          width={sizes[MAP_KEY.NET_VALUE].width - PANEL_PADDING}
          height={sizes[MAP_KEY.NET_VALUE].height - HEADER_PANEL_HEIGHT}
        />

        <PanelTopNetValue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.TOP_NET_VALUE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelSectorSegmentation
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SECTOR_SEGMENTATION}
          sizes={sizes}
          setSizes={setSizes}
          height={
            sizes[MAP_KEY.SECTOR_SEGMENTATION].height - HEADER_PANEL_HEIGHT
          }
        />
      </>
    )
  }

  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
