export const common = {
  TITLE: 'Bond List',
  BOND_BUYBACK_TITLE: 'Bond Buyback',
  BOND_AGGREGATOR: 'All Bond',
  BOND_TRANSACTION: 'Bond Buyback',
  BOND_CREATING: 'Create Bond',
  ALL_ISSUER: 'Issuer',
  ALL: 'All',
  REMAINING_DERATION: 'Remaining Duration',
  YES: 'Yes',
  NO: 'No',
  BOND_TYPE: 'Bond Type',
  INDUSTRY: 'Industry',
  RESET: 'Reset',
}

export const filter = {
  TITLE: 'Filter',
  NUMBER_OF_RESULT: 'Number of results: %{count}',
  SEARCH_FILTER_CRITERIA: 'Search Filter Criteria',
  GENERAL_INFORMATION: 'General Information',
  TICKER_OR_ISSUERS: 'Bond Code Or Issuers',
  BOND_TYPE_1: 'Government Bond',
  BOND_TYPE_2: 'Corporate Bond',
  LIST_OF_ISSUERS: 'List Of Issuers',
  ISSUE_INFORMATION: 'Issue Information',
  ISSUE_DATE: 'Issue Date',
  MATURITY_DATE: 'Maturity Date',
  ISSUE_DURATION: 'Issue Duration',
  ISSUE_VALUE_UNIT: 'Issue Value (Bil. VND)',
  OUT_VALUE_UNIT: 'Outstanding Value (Bil. VND)',
  INTEREST_RATE_INFORMATION: 'Interest Rate Information',
  YTM: 'Yield To Maturity',
  COUPON_INTEREST_RATE: 'Coupon Interest Rate',
  INTEREST_PAYMENT_DATE: 'Next Expected Interest Payment Date',
  CONDITION_INFORMATION: 'Condition Information',
  IS_COLLATERAL: 'Is Collateral',
  IS_PAYMENT_GUARANTEE: 'Is Payment Guarantee',
  GUARANTEE_ORGANIZATION: 'Guarantee Organization',
  BOND_STATUS: 'Bond Status',
  DATE_FROM: 'From /   /',
  DATE_TO: 'To /   /',
  TEMPLATE_NAME: 'Search Bond',
  SAVE_TEMPLATE_TITLE: 'Save Template',
  SAVE: 'Save as',
  UPDATE: 'Save change',
  PLACEHOLDER: 'Enter template name',
  PLACEHOLDER_SELECT: 'Select template',
  NOTE: 'Saved templates display as tabs above the list',
  MESSAGE1: 'Please enter the template name',
  MESSAGE2: 'Template name is already existed',
  MESSAGE3: 'Please select template',
  MESSAGE4:
    'The number of personal templates is no more than 100. Please try again!',
  MORE: 'More',
  YTD: 'By the end of year',
  QTD: 'By the end of quarter',
  MTD: 'By the end of month',
  DELETE_1: 'Are you sure to delete',
  DELETE_2: 'template?',
  DELETE_TITLE: 'Delete Template',
  ISSUER_NOTE: 'Data is filtered based on Bond Issue Date',
}

export const bondList = {
  TITLE: 'Bond List',
  ALL_BOND: 'All Bond',
  ALL_ISSUER: 'All Issuer',
  FILTER_OTHER: 'Filter',
  ADD_INDICATORS: 'Add indicators',
  SAVE_TEMPLATE: 'Save Template',
  CREATE_WATCHLIST: 'Create Watchlist',
  table: {
    total: 'Total results : %{total} results',
    no: 'NO',
    bondSticker: 'Ticker',
    ticker: 'Ticker',
  },
  WATCHLIST: 'Issue Organization',
  OPTION_PANEL: 'Re-arrange Table',
  CREATE_BOND: 'Create Bond',
}

export const createBond = {
  OVERVIEW: 'Overview',
  CREATE_TITLE: 'Create Bond',
  EDIT_TITLE: 'Edit Create Bond',
  BOND_TICKER: 'Bond Ticker',
  BOND_TYPE: 'Bond Type',
  ISSUER: 'Issuer',
  SECTOR: 'Sector',
  ISSUE_DATE: 'Issue Date',
  MATURITY_DATE: 'Maturity Date',
  PAR_VALUE: 'Bond Face Value (VND)',
  INTEREST_PAYMENT_PERIOD: 'Interest Payment Period %{unit}',
  INTEREST_PAYMENT_PERIOD_UNIT: ' (month/times)',
  EXPECTED: 'Expected Coupon Payment Period',
  ISSUE_PERIOD: 'Issue Period',
  COUPON_TYPE: 'Coupon Rate Type',
  COUPON_INTEREST_RATE: 'Coupon Interest Rate (%/year)',
  CORPORATE_BOND: 'Corporate Bond',
  GOVERNMENT_BOND: 'Government Bond',
  FIXED: 'Fixed',
  FLOAT: 'Float',
  ONE_MONTH: '1',
  THREE_MONTHS: '3',
  SIX_MONTHS: '6',
  TWELVE_MONTHS: '12',
  MESSAGE_REQUIRED: 'This field is required',
  TICKER_REQUIRED:
    "Special characters allowed include: '.' ; ' -' ; '_'. Please enter a valid Ticker",
  TICKER_MAX_LENGTH: 'The number of entered letter is no more than 30',
  PAR_VALUE_MAX: 'Please enter Par Value from 0 to 10,000,000,000',
  DATE_MAX: 'Issue Period is no more than 30 years',
  YEAR: '%{number} years',
  MONTH: '%{number} months',
  INTEREST_RATE_MAX: 'Please enter Coupon interest rate from 0 to 100%',
  MESSAGE_CREATE: 'New Bond ticker has been created successfully',
  MESSAGE_UPDATE: 'Bond Ticker named "%{ticker}" has been updated successfully',
  MESSAGE_DELETE: 'Bond Ticker named "%{ticker}" has been deleted successfully',
  SEARCH_PLACEHOLDER: 'Search Bond Ticker',
  DELETE_TITLE: 'Delete Bond',
  DELETE_CONTENT:
    'Are you sure to delete Bond ticker named "%{ticker}" permanently?',
}

export const allIssuer = {
  TICKER_OR_ISSUERS: 'Ticker Or Issuer Name',
  SEARCH_PLACEHOLDER: 'Search Ticker Or Issuer Name',
  TEMPLATE_NAME: 'Search Issuer',
}
