import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { debounce } from '../../../../utils/Common'
import { getParams } from './helpers'
import { selectFilters } from './store/slice'
import { getNavDataThunk } from './store/thunk'

export const withGetData = (Component) => (props) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const handleGetData = useCallback(
    debounce((params) => {
      dispatch(getNavDataThunk(params))
    }, 200),
    [],
  )

  useEffect(() => {
    const params = getParams({
      organizationId: fundInfo.organizationId,
      filters,
    })
    handleGetData(params)
  }, [
    fundInfo.organizationId,
    filters.frequency,
    filters.startDate,
    filters.endDate,
    filters.page,
    filters.sortField,
    filters.sortOrder,
    locale,
  ])

  return <Component {...props} />
}
