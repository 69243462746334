import { I18n } from 'react-redux-i18n'

export const getNewDashboardName = (
  dashboardData,
  dashboardName,
  isGroup = false,
) => {
  const dashboardNameNumbers =
    dashboardData.children
      ?.filter(
        (item) =>
          (isGroup ? item.isGroup : !item.isGroup) &&
          item.name.includes(dashboardName),
      )
      .map((item) => +item.name.replace(dashboardName + ' ', ''))
      .filter((val) => typeof val === 'number' && !isNaN(val))
      .sort((a, b) => a - b) || []
  const missingNumbers = getMissingNumbers(
    dashboardNameNumbers,
    dashboardNameNumbers[dashboardNameNumbers.length - 1],
  )
  const nextNumber = missingNumbers.length
    ? missingNumbers[0]
    : (dashboardNameNumbers[dashboardNameNumbers.length - 1] || 0) + 1

  return `${dashboardName} ${nextNumber}`
}

const getMissingNumbers = (arrayNumbers, maxNumber) => {
  const countNumber = maxNumber
  const missingNumbers = []

  for (var i = 1; i <= countNumber; i++) {
    if (arrayNumbers.indexOf(i) === -1) {
      missingNumbers.push(i)
    }
  }

  return missingNumbers
}

export const getDashboardDuplicateName = (parentDashboard, dashboard) => {
  const groupDashboard =
    parentDashboard.children?.find((item) => item.id === dashboard?.parentId) ||
    parentDashboard

  return getNewDashboardName(
    groupDashboard,
    dashboard?.name || I18n.t('financialChart.NEW_DASHBOARD_NAME'),
    true,
  )
}
