import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { valueTab } from '../constants'

const name = 'bond/bondSecurity/priceChart'
const initialState = {
  tabActive: valueTab.dirtyPrice,
  loading: false,
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    updateTab: (state, action) => {
      state.tabActive = action.payload
    },
  },
  extraReducers: (builder) => {},
})

export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectTabActive = (state) => state[slice.name].tabActive

export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name]?.data?.[id], attr)
}

export const { updateTab } = slice.actions

register(slice.name, slice.reducer)
