import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Z_INDEX } from '../../../constants/Common'
import Sort from './Sort'
import { sortStateMap } from './constants'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text, attr) => {
    return (
      <Span
        className={style.theadContent}
        style={{
          ...attr.style,
          fontSize: 12,
          fontWeight: 600,
          textTransform: 'uppercase',
          // whiteSpace: 'break-spaces',
        }}
      >
        <Translate value={text} />
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={index}
              {...row?.attrs}
              onClick={() =>
                sortColumn({
                  [row.key]: sortStateMap[stateSort[row.key]],
                })
              }
            >
              {renderTitle(row.title, row?.attrs)}
              {row.additionText && (
                <Span className={style.additionText} style={{ fontSize: 12 }}>
                  <Translate value={row.additionText} />
                </Span>
              )}
              {!row?.disableSort && !row?.hideIconSort && (
                <Sort type={stateSort[row.key]} />
              )}
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
