import { chain, maxBy, minBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR, RELEASE_TERM } from '../constants'
import { renderTimeType } from '../helper'
import {
  keys,
  selectActiveTimeType,
  selectFilterIssuanceValueBySectorType,
  selectIssuanceValueBySectorData,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'
const IssuanceValueBySector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.ISSUANCE_VALUE_BY_SECTOR))
  const data = useSelector(selectIssuanceValueBySectorData)
  const timeType = useSelector(selectActiveTimeType)
  const { list } = useSelector(selectFilterIssuanceValueBySectorType)
  const [listCheckbox, setListCheckbox] = useState([])
  const { duration } = useSelector(selectFilterIssuanceValueBySectorType)
  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(() => {
    const newData =
      !!data.length > 0
        ? data.map((item) => ({
            ...item,
            industryValue: valDivBillion(item.industryValue),
            industryName:
              item.industryName === 'Other' ? 'Others' : item.industryName,
          }))
        : []

    return chain(newData)
      .groupBy('industryName')
      .map((value, key) => {
        const newValue = chain(value)
          .keyBy('dateCheck')
          .mapValues('industryValue')
          .value()

        return {
          industryName: key,
          ...newValue,
        }
      })
      .value()
  }, [data])

  const renderTickerName = () => {
    const item = RELEASE_TERM.find((item) => item.value === duration)
    return item ? I18n.t(item.name) : ''
  }

  const renderTooltip = (dataChart) => (
    <ChartTooltip data={dataChart} duration={renderTickerName()} />
  )

  const rangeValue = useMemo(() => {
    const newData = data.filter((item) => listCheckbox.includes(item.dateCheck))
    const values = newData.map((item) => valDivBillion(item.industryValue))

    return {
      minValue: minBy(values) < 0 ? minBy(values) : 0,
      maxValue: maxBy(values),
    }
  }, [data, listCheckbox])

  const titleJpg = useMemo(() => {
    const item = RELEASE_TERM.find((item) => item.value === duration)

    const title = I18n.t('bond.corporateBond.issuanceValueBySector.TITLE')

    if (!item) {
      return title
    }

    return `${title}-${I18n.t(item.name)}`
  }, [duration, locale])

  useEffect(() => {
    if (list && !!list.length) {
      setListCheckbox(list)
    }
  }, [list])

  return (
    <Panel
      title="bond.corporateBond.issuanceValueBySector.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'issuanceValueBySectorChartId',
        nameScreen: I18n.t('bond.corporateBond.primaryMarket.TITLE'),
        chartName: I18n.t('bond.corporateBond.issuanceValueBySector.TITLE'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="issuanceValueBySectorChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {formatDataBarChart.length ? (
                        <ChartContainer
                          data={formatDataBarChart}
                          width={width}
                          height={height - size.height - 28}
                          keyXAxis="industryName"
                          isUseXAxisDiv
                          showAllTick
                          yAxis={[
                            {
                              id: 'left',
                              keys: listCheckbox,
                              orientation: 'left',
                              isStackedBar: true,
                              label: I18n.t(
                                'bond.corporateBond.issuanceValueBySector.NET_VALUE',
                              ),
                              labelPosition: AXIS_LABEL_POSITION.LEFT,
                              tickNum: 5,
                              min:
                                rangeValue.minValue < 0
                                  ? rangeValue.minValue
                                  : 0,
                              max: rangeValue.maxValue,
                            },
                          ]}
                          margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                          renderCustomTooltip={renderTooltip}
                        >
                          {({ chartContentWidth }) => (
                            <>
                              {listCheckbox.includes(list[0]) && (
                                <Bar
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={list[0]}
                                  fill={BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[0]}
                                  barSize={10}
                                />
                              )}
                              {listCheckbox.includes(list[1]) && (
                                <Bar
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={list[1]}
                                  fill={BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[1]}
                                  barSize={10}
                                />
                              )}
                              {listCheckbox.includes(list[2]) && (
                                <Bar
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={list[2]}
                                  fill={BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[2]}
                                  barSize={10}
                                />
                              )}
                              {listCheckbox.includes(list[3]) && (
                                <Bar
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={list[3]}
                                  fill={BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[3]}
                                  barSize={10}
                                />
                              )}
                              {listCheckbox.includes(list[4]) && (
                                <Bar
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={list[4]}
                                  fill={BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[4]}
                                  barSize={10}
                                />
                              )}
                            </>
                          )}
                        </ChartContainer>
                      ) : (
                        <NoData />
                      )}
                      <Footer
                        key={width}
                        list={
                          list && !!list.length
                            ? list.map((item, index) => ({
                                text:
                                  index === list.length - 1
                                    ? item + renderTimeType(timeType)
                                    : item,
                                dataKey: item,
                                type: TYPE.SQUARE,
                                before: {
                                  bgColor:
                                    BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR[index],
                                },
                              }))
                            : []
                        }
                        listCheckbox={listCheckbox}
                        setListCheckbox={setListCheckbox}
                      />
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default IssuanceValueBySector
