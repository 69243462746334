import { sumBy } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { valDivBillion } from '../../../../utils/Value'
import { TIME_FREQUENCY } from './constants'

export const renderNameChart = (value) => {
  switch (value) {
    case 1:
      return 'bond.corporateBond.remainingMaturities.UNDER_SIX_PERCENTS'
    case 2:
      return 'bond.corporateBond.remainingMaturities.SIX_TO_NINE_PERCENTS'
    case 3:
      return 'bond.corporateBond.remainingMaturities.NINE_TO_TWELVE_PERCENTS'
    case 4:
      return 'bond.corporateBond.remainingMaturities.TWELVE_TO_FIFTEEN_PERCENTS'
    case 5:
      return 'bond.corporateBond.remainingMaturities.OVER_FIFTEEN_PERCENTS'
    default:
      return ''
  }
}

export const renderName = (value) => {
  switch (value) {
    case 1:
      return 'bond.corporateBond.remainingMaturities.UNDER_ONE_YEAR'
    case 2:
      return 'bond.corporateBond.remainingMaturities.ONE_TWO_THREE_YEARS'
    case 3:
      return 'bond.corporateBond.remainingMaturities.THREE_TWO_FIVE_YEARS'
    case 4:
      return 'bond.corporateBond.remainingMaturities.FIVE_TO_SEVEN_YEARS'
    case 5:
      return 'bond.corporateBond.remainingMaturities.OVER_SEVEN_YEARS'
    default:
      return ''
  }
}

export const renderTimeType = (timeType) => {
  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return I18n.t('bond.corporateBond.issuers.MTD')
    case TIME_FREQUENCY.QUARTER:
      return I18n.t('bond.corporateBond.issuers.QTD')
    default:
      return I18n.t('bond.corporateBond.issuers.YTD')
  }
}

export const transferText = (text) => {
  switch (text) {
    case 'Private placement':
    case 'Phát hành riêng lẻ':
      return I18n.t('bond.corporateBond.issuers.PRIVATE')
    case 'Public placement':
    case 'Phát hành công chúng':
      return I18n.t('bond.corporateBond.issuers.PUBLIC')
    case 'Đối tượng chính':
    case 'Main subject':
      return '--'
    default:
      return text
  }
}

export const formatDataTable = (data, locale) => {
  if (!data || !data.length) {
    return []
  }

  const newData = data.map((item) => ({
    ...item,
    // nextInterestPaymentDate: item.nextInterestPaymentDate
    //   ? moment(item.nextInterestPaymentDate).format(FORMAT.DATE[locale])
    //   : '--',
    // issueDateId: moment(item.issueDateId).format(FORMAT.DATE[locale]),
    // maturityDateId: moment(item.maturityDateId).format(FORMAT.DATE[locale]),
    // couponInterestRate: item.couponInterestRate * 100,
    issueValue: valDivBillion(item.issueValue),
    outstandingValue: valDivBillion(item.outstandingValue),
    duration: item.duration,
    collateralValue: valDivBillion(item.collateralValue),
    interestPaid: valDivBillion(item.interestPaid),
    principalPaid: valDivBillion(item.principalPaid),
    overdueCoupon: valDivBillion(item.overdueCoupon),
    overduePrincipal: valDivBillion(item.overduePrincipal),
    level: 1,
    parentId: item.bondId,
    id: item.bondId,
    sourceUrl: item.sourceUrl ?? '',
  }))

  const totalIssueValue = sumBy(
    newData.filter((item) => !isNaN(item.issueValue)),
    (item) => parseFloat(item.issueValue),
  )
  const totalOutstandingValue = sumBy(
    newData.filter((item) => !isNaN(item.outstandingValue)),
    (item) => parseFloat(item.outstandingValue),
  )
  const totalCollateralValue = sumBy(
    newData.filter((item) => !isNaN(item.collateralValue)),
    (item) => parseFloat(item.collateralValue),
  )
  const totalInterestPaid = sumBy(
    newData.filter((item) => !isNaN(item.interestPaid)),
    (item) => parseFloat(item.interestPaid),
  )
  const totalPrincipalPaid = sumBy(
    newData.filter((item) => !isNaN(item.principalPaid)),
    (item) => parseFloat(item.principalPaid),
  )
  const totalOverdueCoupon = sumBy(
    newData.filter((item) => !isNaN(item.overdueCoupon)),
    (item) => parseFloat(item.overdueCoupon),
  )
  const totalOverduePrincipal = sumBy(
    newData.filter((item) => !isNaN(item.overduePrincipal)),
    (item) => parseFloat(item.overduePrincipal),
  )

  const totalItem = {
    bondTicker: 'bond.corporateBond.bondIssueInformation.SUMMARY',
    organizationShortName: '',
    ticker: '',
    roleName: '',
    issueMethodId: '',
    issueMethodName: '',
    nextInterestPaymentDate: '',
    issueDateId: '',
    maturityDateId: '',
    duration: '',
    activeStatusName: '',
    collateralTypeIds: '',
    collateralTypeNames: '',
    couponInterestRate: '',
    sourceUrl: '',
    issueValue: totalIssueValue,
    outstandingValue: totalOutstandingValue,
    collateralValue: totalCollateralValue,
    interestPaid: totalInterestPaid,
    principalPaid: totalPrincipalPaid,
    overdueCoupon: totalOverdueCoupon,
    overduePrincipal: totalOverduePrincipal,
    level: 1,
    parentId: 'Total',
    id: 'Total',
  }

  newData.push(totalItem)

  return newData
}
