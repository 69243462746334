import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { formatVal } from '../../utils/Value'
import { Input } from '../html/Input'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/ //positive and negative float number

const InputFormatCurrency = ({
  defaultValue,
  fractionDigits,
  prefix,
  suffix,
  handleBlur,
  handleChange,
  className,
  decimalRender,
  disabled,
  style,
  isEnter,
  regexValueCustom,
}) => {
  const [valueInput, setValueInput] = useState(() => {
    if (defaultValue) {
      return prefix
        ? prefix + String(defaultValue)
        : suffix
        ? formatVal(defaultValue, decimalRender) + suffix
        : formatVal(defaultValue, decimalRender)
    } else {
      return ''
    }
  })

  useEffect(() => {
    setValueInput(() => {
      if (defaultValue) {
        return prefix
          ? prefix + String(defaultValue)
          : suffix
          ? formatVal(defaultValue, decimalRender) + suffix
          : formatVal(defaultValue, decimalRender)
      } else {
        return ''
      }
    })
  }, [defaultValue])

  const inputRef = useRef(
    defaultValue ? parseFloat(defaultValue).toFixed(fractionDigits) : '',
  )

  const cancelFormatValues = (value) => {
    return value.replace(/,/g, '') || ''
  }

  const onChange = (e) => {
    if (regexValueCustom) {
      if (regexValueCustom.test(e.target.value)) {
        setValueInput(e.target.value)
        const newValue = e.target.value
          ? parseFloat(e.target.value).toFixed(fractionDigits)
          : ''

        inputRef.current = newValue
        handleChange?.(newValue)
      } else {
        setValueInput(inputRef.current)
        handleChange?.(inputRef.current)
      }

      return
    }

    if (e.target.value.match(regexValue)) {
      setValueInput(e.target.value)
      const newValue = e.target.value
        ? parseFloat(e.target.value).toFixed(fractionDigits)
        : ''
      inputRef.current = newValue
      handleChange?.(newValue)
    } else {
      setValueInput(inputRef.current)
      handleChange?.(inputRef.current)
    }
  }

  const onFocus = () => {
    if (valueInput) {
      setValueInput(cancelFormatValues(inputRef.current))
    } else {
      setValueInput('')
    }
  }

  const onBlur = (e) => {
    if (e.target.value) {
      prefix
        ? setValueInput(prefix + formatVal(e.target.value, decimalRender))
        : suffix
        ? setValueInput(formatVal(e.target.value, decimalRender) + suffix)
        : setValueInput(formatVal(e.target.value, decimalRender))
    }
    handleBlur(e.target.value)
  }

  const onKeyDown = (e) => {
    if (e?.keyCode === 13 && isEnter) {
      e.preventDefault()
      handleBlur(e.target.value)
    }
  }

  return (
    <Input
      type="text"
      value={valueInput}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      className={`${className} w-100 h-20`}
      disabled={disabled}
      style={style}
    />
  )
}

InputFormatCurrency.propTypes = {
  defaultValue: PropTypes.number,
  handleBlur: PropTypes.func,
  fractionDigits: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  className: PropTypes.string,
  decimalRender: PropTypes.number,
  disabled: PropTypes.bool,
  isEnter: PropTypes.bool,
  handleChange: PropTypes.func,
}

InputFormatCurrency.defaultProps = {
  fractionDigits: 2,
  className: '',
  decimalRender: 2,
  disabled: false,
  isEnter: false,
}

export default InputFormatCurrency
