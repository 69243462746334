import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import {
  getAccMonthAndYearKey,
  getMonthAndYearKey,
  getYearKey,
} from '../../../common/helper'
import style from '../../index.module.css'
import { selectMaxDate } from '../commodityChart/store/slice'

const FULL_YEAR_MONTHS = 12
const SETTINGS = {
  yTickNum: 6,
  xTickNum: 12,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKeys,
  isYearly,
  isAcc,
  currentMonth,
  currentYear,
}) => {
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const partnerTooltip = (valueTooltip) => {
    const isFullYearData = valueTooltip?.realMonth === FULL_YEAR_MONTHS

    return (
      <div className={style.chartTooltip}>
        <div className="d-flex mb-8 justify-content-space-between">
          <div style={{ fontStyle: 'italic' }}>
            <span style={{ fontSize: 11 }}>{valueTooltip.name}</span>
          </div>
          <div style={{ fontStyle: 'italic' }}>
            <span style={{ fontSize: 11 }}>
              {isYearly
                ? isFullYearData
                  ? getYearKey(currentYear, maxDate, locale)
                  : getAccMonthAndYearKey(
                      valueTooltip?.realMonth,
                      currentYear,
                      locale,
                    )
                : isAcc
                ? getAccMonthAndYearKey(currentMonth, currentYear, locale)
                : getMonthAndYearKey(currentMonth, currentYear)}
            </span>
          </div>
        </div>
        <table>
          <tbody>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {I18n.t(
                      'economy.importExport.importExport.overview.FOOTER_EXPORT',
                    )}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span
                  style={{
                    fontSize: 11,
                  }}
                >
                  {formatVal(valueTooltip.export)}
                  {` ${I18n.t(
                    'economy.importExport.importExport.MILLION_USD',
                  )}`}
                </Span>
              </td>
            </tr>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>
                    {I18n.t(
                      'economy.importExport.importExport.overview.FOOTER_IMPORT',
                    )}
                  </Span>
                  :
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span style={{ fontSize: 11 }}>
                  {formatVal(valueTooltip.import)}
                  {` ${I18n.t(
                    'economy.importExport.importExport.MILLION_USD',
                  )}`}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            isShowXAxis
            showAllTick
            isUseXAxisDiv
            hasBarChart
            yAxis={[
              {
                id: 'left',
                keys: barKeys,
                isBarChart: true,
                orientation: 'left',
                label: `(${I18n.t(
                  'economy.importExport.importExport.MILLION_USD',
                )})`,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
              },
            ]}
            renderCustomTooltip={(payload) => partnerTooltip(payload)}
          >
            <>
              <Bar
                yAxisId="left"
                dataKey="export"
                barSize={getColumnSizeInBarChart(width, data.length, 25)}
                isAnimationActive={false}
                fill="#185999"
              />
              <Bar
                yAxisId="left"
                dataKey="import"
                barSize={getColumnSizeInBarChart(width, data.length, 25)}
                isAnimationActive={false}
                fill="#facc5c"
              />
            </>
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
