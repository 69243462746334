export const DATE_TYPES = {
  ONE_DAY: 'OneDay',
  WEEK_DAY: 'WeekToDate',
  MONTH_DAY: 'MonthToDate',
  QUARTER_DAY: 'QuarterToDate',
  YEAR_DAY: 'YearToDate',
}

export const DATE_TABS = [
  // {
  //   title: 'D',
  //   value: DATE_TYPES.ONE_DAY,
  // },
  {
    title: 'WTD',
    value: DATE_TYPES.WEEK_DAY,
  },
  {
    title: 'MTD',
    value: DATE_TYPES.MONTH_DAY,
  },
  {
    title: 'QTD',
    value: DATE_TYPES.QUARTER_DAY,
  },
  {
    title: 'YTD',
    value: DATE_TYPES.YEAR_DAY,
  },
]

export const TIME_TYPES = {
  ONE_DAY: 'OneDay',
  ONE_WEEK: 'OneWeek',
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
}

export const DATE_LIQUIDITY_TABS = [
  {
    title: '1D',
    value: TIME_TYPES.ONE_DAY,
  },
  {
    title: '1W',
    value: TIME_TYPES.ONE_WEEK,
  },
  {
    title: '1M',
    value: TIME_TYPES.ONE_MONTH,
  },
  {
    title: '3M',
    value: TIME_TYPES.THREE_MONTHS,
  },
]

export const MARKET_LIQUIDITY_TABS = [
  {
    title: '1W',
    value: 'OneWeek',
  },
  {
    title: '1M',
    value: 'OneMonth',
  },
  {
    title: '3M',
    value: 'ThreeMonths',
  },
]

export const TCPH_TP_TABS = [
  {
    title: 'bond.corporateBond.topLiquidity.ISSUER',
    value: 'TCPH',
  },
  {
    title: 'bond.corporateBond.topLiquidity.TP',
    value: 'TP',
  },
]

export const TOTAL_VALUE_TYPE = {
  TotalValue: 'TotalValue',
  TotalMatchValue: 'TotalMatchValue',
  TotalDealValue: 'TotalDealValue',
}

export const ALL_MATCH_DEAL_RADIO = [
  {
    name: 'bond.corporateBond.topLiquidity.ALL',
    value: TOTAL_VALUE_TYPE.TotalValue,
  },
  {
    name: 'bond.corporateBond.topLiquidity.MATCH',
    value: TOTAL_VALUE_TYPE.TotalMatchValue,
  },
  {
    name: 'bond.corporateBond.topLiquidity.DEAL',
    value: TOTAL_VALUE_TYPE.TotalDealValue,
  },
]
export const BAR_COLOR_LIQUIDITY_CHART = ['#009CFF', '#FFC859']
export const VALUE_TIME = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}

export const GROUP_MARKET_LIQUIDITY_TYPES = {
  ALL: 150,
  PUBLIC: 46,
  PRIVATE: 128,
}

export const GROUP_MARKET_LIQUIDITY_RADIOS = [
  {
    name: 'bond.corporateBond.topLiquidity.ALL',
    value: GROUP_MARKET_LIQUIDITY_TYPES.ALL,
  },
  {
    name: 'bond.corporateBond.topLiquidity.PUBLIC',
    value: GROUP_MARKET_LIQUIDITY_TYPES.PUBLIC,
  },
  {
    name: 'bond.corporateBond.topLiquidity.PRIVATE',
    value: GROUP_MARKET_LIQUIDITY_TYPES.PRIVATE,
  },
]
