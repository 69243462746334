import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import { selectItemData } from '../store/slice'

const Description = ({ val, rowId, className, style }) => {
  const item = useSelector(selectItemData(rowId))

  if (item.isCollapse) {
    return (
      <td
        className={`${className} ${style['row-lv0']}`}
        style={{ width: '35%', maxWidth: '30px' }}
      >
        {''}
      </td>
    )
  } else {
    return (
      <td
        className={className}
        style={{
          textAlign: 'left',
          width: '35%',
          maxWidth: '30px',
        }}
      >
        <TextEllipsis isI18n={false} text={val} />
      </td>
    )
  }
}

Description.propTypes = {
  val: PropTypes.string,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Description
