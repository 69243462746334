import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { valueTab } from '../constants'
import { getDataCompanyRating, getDataThunk } from './thunk'

const name = 'bond/bondSecurity/tradingInformation'
const initialState = {
  loading: false,
  loadingCompany: false,
  data: {},
  dataCompany: {},
  dataHistory: {},
  idsHistory: [],
  activeTab: valueTab.dirtyPrice,
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    updateTab: (state, action) => {
      state.activeTab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      state.data = action.payload || {}
      state.loading = false
    })
    builder.addCase(getDataThunk.rejected, (state) => {
      state.data = {}
      state.loading = false
    })

    builder.addCase(getDataCompanyRating.pending, (state) => {
      state.loadingCompany = true
    })
    builder.addCase(getDataCompanyRating.fulfilled, (state, action) => {
      const dataHistory = action.payload || []
      const dataHistoryFormart = dataHistory.map((e, i) => ({
        ...e,
        id: i,
      }))

      state.dataCompany = action.payload || {}
      state.dataHistory = keyBy(dataHistoryFormart, 'id')
      state.idsHistory = dataHistoryFormart.map((e) => e.id)
      state.loadingCompany = false
    })
    builder.addCase(getDataCompanyRating.rejected, (state) => {
      state.dataCompany = {}
      state.loadingCompany = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectLoadingCompany = (state) => state[slice.name].loadingCompany
export const selectData = (state) => state[slice.name].data
export const selectDataCompany = (state) => state[slice.name].dataCompany
export const selectIdsHistory = (state) => state[slice.name].idsHistory

export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name]?.dataHistory?.[id], attr)
}

export const selectActive = (state) => state[slice.name].activeTab

export const { updateTab } = slice.actions

register(slice.name, slice.reducer)
