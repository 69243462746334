import TradingDataProxy from '../../../proxies/tool/dataExplorer/TradingDataProxy'
import { ServiceBase } from '../../ServiceBase'

class TradingDataService extends ServiceBase {
  getIndicator(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.tradingData.getIndicator',
      params,
    )(() => TradingDataProxy.getIndicator(params))
  }

  getCorporateResult(params) {
    return this.getData(() => TradingDataProxy.getCorporateResult(params))
  }

  getIndexSectorResult(params) {
    return this.getData(() => TradingDataProxy.getIndexSectorResult(params))
  }

  downloadExportExcel(params) {
    return this.getFileDownload(() =>
      TradingDataProxy.downloadExportExcel(params),
    )
  }
}

export default new TradingDataService()
