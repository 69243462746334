import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  DROPDOWN_RESET_POSITION,
  FULL_COMPONENT,
} from '../utils/EventEmitter'
import { ScrollComponent } from './ScrollComponent'

export const groupScrollComponentClass = 'group-scroll-component'

export const ScrollGroupComponent = ({ children }) => {
  const [isFullComponent, setIsFullComponent] = useState(false)
  useEffect(() => {
    const openFullScreen = () => {
      setIsFullComponent(true)
    }
    EventEmitter.subscribe(FULL_COMPONENT, openFullScreen)
    return () => {
      EventEmitter.unsubscribe(FULL_COMPONENT, openFullScreen)
    }
  }, [])

  useEffect(() => {
    const closeFullScreen = () => {
      setIsFullComponent(false)
    }
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, closeFullScreen)
    return () => {
      EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, closeFullScreen)
    }
  }, [])

  useEffect(() => {
    const groupScrollDom = document.querySelector(
      `#${groupScrollComponentClass} .scrollbars div:first-child`,
    )
    const handleScroll = () => EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    groupScrollDom.addEventListener('scroll', handleScroll)
    return () => groupScrollDom.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <div className="h-100" id={groupScrollComponentClass}>
      <ScrollComponent disableScroll={isFullComponent}>
        {children}
      </ScrollComponent>
    </div>
  )
}

ScrollGroupComponent.propTypes = {
  children: PropTypes.node.isRequired,
}
