import { Area, Bar, Line, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION, CHART_TYPES } from '../constants'
import { getColumnSizeInBarChart } from '../helper'
import {
  DEFAULT_COLORS,
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
  YAXIS_TICK_COUNT,
  YAXIS_TYPES,
} from './constants'

export const getDefaultChartDataKey = (index) =>
  `${DEFAULT_PRE_VALUE_KEY}${index + 1}`

export const getSchemas = (schemas) => {
  return schemas.map((item, index) => ({
    dataKey: item.dataKey || getDefaultChartDataKey(index),
    ...item,
  }))
}

export const getXAxis = ({ xAxis = {}, schema }) => {
  return {
    ...xAxis,
    dataKey: xAxis.dataKey || DEFAULT_XAXIS_DATA_KEY,
    tickCount: xAxis.tickCount || schema.length,
  }
}

export const getYAxis = ({ yAxis, schema }) => {
  if (!yAxis || !yAxis.length) {
    return [
      {
        id: 'yAxis',
        keys: schema.map((item) => item.dataKey),
        orientation: YAXIS_TYPES.LEFT,
        tickNum: YAXIS_TICK_COUNT,
        isBarChart: true,
      },
    ]
  }

  return yAxis.map((item, index) => {
    const props = {
      ...item,
      id: item.yAxisId || `yAxis${index}`,
      keys: item.dataKeys,
      orientation: item.orientation || YAXIS_TYPES.LEFT,
      tickNum: item.tickCount || YAXIS_TICK_COUNT,
      label: item.labelText,
      labelPosition: item.labelPosition || AXIS_LABEL_POSITION.LEFT,
    }

    if (
      item.isBarChart ||
      (!item.isBarChart &&
        !item.isGroupBar &&
        !item.isStackedBar &&
        !item.isLineChart)
    ) {
      props.isBarChart = item.isBarChart
    }

    if (item.isGroupBar) {
      props.isGroupBar = item.isGroupBar
    }

    if (item.isStackedBar) {
      props.isStackedBar = item.isStackedBar
    }

    if (item.isLineChart) {
      props.isLineChart = item.isLineChart
    }

    return props
  })
}

export const getCharts = ({
  colors,
  schema,
  yAxis,
  data,
  chartContentWidth,
  hoverActiveItem,
}) => {
  const barCharts = []
  const areaCharts = []
  const lineCharts = []
  const scatterCharts = []

  schema.forEach((item, index) => {
    const {
      yAxisId,
      stackId,
      dataKey,
      color,
      chart: { type: chartType, ...others } = {},
    } = item

    const props = {
      color:
        (color && (Array.isArray(color) ? color[0] : color)) || colors[index],
      yAxisId: yAxisId || yAxis[0].id,
      dataKey: dataKey,
      ...others,
    }

    if (CHART_TYPES.STACK_BAR === chartType) {
      props.stackId = stackId || 'stackBarId'
    }

    if (CHART_TYPES.STACK_AREA === chartType) {
      props.stackId = stackId || 'stackAreaId'
    }

    if (hoverActiveItem) {
      props.strokeOpacity = hoverActiveItem === item.dataKey ? 1 : 0.1
      props.strokeWidth = hoverActiveItem === item.dataKey ? 2.5 : 1.5
      props.fillOpacity = hoverActiveItem === item.dataKey ? 0.8 : 0.1
    }

    switch (chartType) {
      case CHART_TYPES.BAR:
      case CHART_TYPES.STACK_BAR:
        barCharts.push(props)
        break
      case CHART_TYPES.AREA:
      case CHART_TYPES.STACK_AREA:
        areaCharts.push(props)
        break
      case CHART_TYPES.LINE:
        lineCharts.push(props)
        break
      case CHART_TYPES.DOT:
        scatterCharts.push(props)
        break
      default:
        barCharts.push(props)
        break
    }
  })

  return [
    ...barCharts.map((chart) =>
      getBarCharts(data, {
        barSize: getColumnSizeInBarChart(chartContentWidth, data.length),
        ...chart,
      }),
    ),
    ...areaCharts.map((chart) => getAreaCharts(data, chart)),
    ...lineCharts.map((chart) => getLineCharts(data, chart)),
    ...scatterCharts.map((chart) => getScatterCharts(data, chart)),
  ]
}

const getBarCharts = (data, { color, render, ...props }) => {
  if (render) {
    return render(data, { color, ...props })
  }
  return <Bar isAnimationActive={false} fill={color} {...props} />
}

const getLineCharts = (data, { color, render, ...props }) => {
  if (render) {
    return render(data, { color, ...props })
  }
  return (
    <Line
      type="linear"
      stroke={color}
      fill={color}
      strokeWidth={2}
      dot={false}
      activeDot={false}
      isAnimationActive={false}
      connectNulls
      {...props}
    />
  )
}

const getAreaCharts = (data, { color, render, ...props }) => {
  if (render) {
    return render(data, { color, ...props })
  }
  return (
    <Area
      type="linear"
      stroke={color}
      fillOpacity={0.8}
      fill={color}
      dot={false}
      activeDot={false}
      isAnimationActive={false}
      connectNulls
      {...props}
    />
  )
}

const getScatterCharts = (data, { color, render, ...props }) => {
  if (render) {
    return render(data, { color, ...props })
  }
  return <Scatter isAnimationActive={false} fill={color} {...props} />
}

export const getDataPieChart = ({
  schema,
  xAxis,
  data,
  colors = DEFAULT_COLORS,
}) => {
  const chart = (schema && schema[0]) || { dataKey: getDefaultChartDataKey(0) }
  return data.map((item, index) => ({
    value: item[chart.dataKey],
    text: item[xAxis.dataKey],
    color: colors[index],
    ...item,
  }))
}
