import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import ContributionCreditGrowth from './panelContributionCreditGrowth'
import GrowthSupplyCredit from './panelGrowthSupplyCredit'
import GrowthYoY from './panelGrowthYoY'
import PanelMoneySupplyCredit from './panelMoneySupplyCredit'

const MAP_KEY = {
  MONEY_SUPPLY_CREDIT: 'MONEY_SUPPLY_CREDIT',
  GROWTH_MONEY_SUPPLY_CREDIT: 'GROWTH_MONEY_SUPPLY_CREDIT',
  CONTRIBUTION_CREDIT_GROWTH: 'CONTRIBUTION_CREDIT_GROWTH',
  GROWTH_YOY: 'GROWTH_YOY',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.MONEY_SUPPLY_CREDIT]: {
    minWidth: 500,
    minHeight: 320,
  },
  [MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT]: {
    minWidth: 350,
    minHeight: 320,
  },
  [MAP_KEY.CONTRIBUTION_CREDIT_GROWTH]: {
    minWidth: 500,
    minHeight: 320,
  },
  [MAP_KEY.GROWTH_YOY]: {
    minWidth: 350,
    minHeight: 320,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.MONEY_SUPPLY_CREDIT}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.MONEY_SUPPLY_CREDIT],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CONTRIBUTION_CREDIT_GROWTH,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GROWTH_YOY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT],
    minTop: 0,
    minLeft: 500 + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.MONEY_SUPPLY_CREDIT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.MONEY_SUPPLY_CREDIT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.CONTRIBUTION_CREDIT_GROWTH,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GROWTH_YOY,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CONTRIBUTION_CREDIT_GROWTH}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.CONTRIBUTION_CREDIT_GROWTH],
    minTop: 320 + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GROWTH_YOY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GROWTH_YOY}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GROWTH_YOY],
    minTop: 320 + 2 * COMPONENT.MARGIN,
    minLeft: 500 + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.CONTRIBUTION_CREDIT_GROWTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelMoneySupplyCredit
          panelRefs={panelRefs}
          panelKey={MAP_KEY.MONEY_SUPPLY_CREDIT}
          sizes={sizes}
          setSizes={setSizes}
        />
        <GrowthSupplyCredit
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GROWTH_MONEY_SUPPLY_CREDIT}
          sizes={sizes}
          setSizes={setSizes}
        />
        <ContributionCreditGrowth
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CONTRIBUTION_CREDIT_GROWTH}
          sizes={sizes}
          setSizes={setSizes}
        />
        <GrowthYoY
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GROWTH_YOY}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
