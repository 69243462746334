import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { BUBBLE_COLOR_OPTION } from '../constant'
import style from '../index.module.css'
import { changeBubbleColor, selectBubbleColor } from '../store/slice'

const BubbleColor = () => {
  const dispatch = useDispatch()

  const bubbleColor = useSelector(selectBubbleColor)

  const handleChange = (value) => {
    dispatch(changeBubbleColor(value))
  }

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.BUBBLE_COLOR" />
      </Span>
      <div className={style.mt4}>
        <SelectCustom
          isI18n={true}
          selectData={BUBBLE_COLOR_OPTION}
          value={bubbleColor}
          handleChange={handleChange}
          fontSizeItem={10}
        />
      </div>
    </div>
  )
}

export default BubbleColor
