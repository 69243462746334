import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Span } from '../html/Span'
import DropdownTooltip from '../textEllipsis/DropdownTooltip'

export const ThCellWithIconInfo = ({
  children,
  tooltipCustom,
  isI18n,
  styleContainer,
  styleIcon,
  styleTextTooltip,
}) => {
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <div className="d-flex" style={styleContainer}>
        {children}
        <Span style={{ fontSize: 11 }}>
          <i
            style={{ color: '#a8acb2', ...styleIcon }}
            className="icon-info ml-5"
            data-tip
            data-for={tooltipCustom}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Span>
      </div>

      {isShow && (
        <DropdownTooltip
          containerRef={ref}
          text={tooltipCustom}
          isOpenTooltip={true}
          isI18n={isI18n}
          styleTextTooltip={styleTextTooltip}
        />
      )}
    </>
  )
}

ThCellWithIconInfo.propTypes = {
  children: PropTypes.node.isRequired,
  tooltipCustom: PropTypes.string.isRequired,
  isI18n: PropTypes.bool,
}

ThCellWithIconInfo.defaultProps = {
  isI18n: true,
}
