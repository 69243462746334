import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleStackedData, handleStackKey } from '../helper'
import { getChangesInGRDPStructureBySectorInProvince } from './thunk'

const initialState = {
  stackedChartLoading: true,
  stackedChartData: [],
  stackKey: [],
}

const slice = createSlice({
  name: 'economy/gdp/gdpByProvince/panelGRDPChanges',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      getChangesInGRDPStructureBySectorInProvince.pending,
      (state) => {
        state.stackedChartLoading = true
      },
    )
    builder.addCase(
      getChangesInGRDPStructureBySectorInProvince.fulfilled,
      (state, action) => {
        state.stackedChartLoading = false
        state.stackedChartData = handleStackedData(action.payload || [])
        state.stackKey = handleStackKey(action.payload || [])
      },
    )
    builder.addCase(
      getChangesInGRDPStructureBySectorInProvince.rejected,
      (state, action) => {
        state.stackedChartLoading = action.payload.isLoading
      },
    )
  },
})

export const getStackedBarChartLoading = (state) =>
  state[slice.name].stackedChartLoading
export const getStackedBarChartData = (state) =>
  state[slice.name].stackedChartData
export const getStackKey = (state) => state[slice.name].stackKey

register(slice.name, slice.reducer)
