import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal } from '../../../../../utils/Value'

const BAR_COLOR = '#0096eb'
const YAXIS_ID = 'left'
const MARGIN = { ...MARGIN_RECHARTS, right: 16, left: 12 }

const ChartComponent = ({ data, width, height, barKey, keyXAxis }) => {
  const formatData = formatDataChart(data, barKey)

  const renderTooltip = (tooltipData, decimalLengths) => {
    const decimalLength = decimalLengths[YAXIS_ID] || 0
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.LABEL_CHART_CHANGE',
        ),
        value: tooltipData[barKey]
          ? formatVal(tooltipData[barKey], decimalLength || 2) + '%'
          : EMPTY_VALUE,
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={formatData}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      isUseXAxisDiv
      showAllTick
      yAxis={[
        {
          id: YAXIS_ID,
          keys: [barKey],
          orientation: 'left',
          isBarChart: true,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: '%',
        },
      ]}
      margin={MARGIN}
      renderCustomTooltip={renderTooltip}
    >
      {({ chartContentWidth }) => (
        <>
          <Bar
            isAnimationActive={false}
            yAxisId={YAXIS_ID}
            dataKey={barKey}
            barSize={getColumnSizeInBarChart(
              chartContentWidth,
              formatData.length,
            )}
            fill={BAR_COLOR}
          />
        </>
      )}
    </ChartContainer>
  )
}

export default ChartComponent

export const formatDataChart = (data, barKey) => {
  if (barKey) {
    return data.map((item) => ({
      ...item,
      [barKey]: item[barKey] ? formatVal(item[barKey] * 100) : item[barKey],
    }))
  }
  return data
}
