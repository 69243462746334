import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DataExplorerService from '../../../../core/services/tool/dataExplorer/DataExplorerService'
import {
  timeoutInterval,
  typeWorkSpace,
} from '../../../common/dataExplorer/constant'
import {
  convertTickerTemplate,
  getAllGroupIds,
} from '../../../common/dataExplorer/helper'
import { selectDataGeneralInfo } from '../../../setting/slice'
import { keyBy } from '../../../utils/Common'
import useGetStock from '../../masterData/useGetStock'
import {
  changeSheetParamsWhenLoadTemplate,
  selectActiveSheet,
  selectColumnById,
  selectFullColumnId,
  selectGroupColumnById,
  selectIndicator,
  selectIsLoadWorkSpace,
  selectListSheet,
  selectSheet,
  selectTickerById,
  selectTickerId,
} from './store/slice'
import { getShareTemplateThunk, getWorkSpaceThunk } from './store/thunk'

let workSpace
let interval

const WorkSpace = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const { stocks } = useGetStock()

  const sheets = useSelector(selectSheet)
  const listSheet = useSelector(selectListSheet)
  const userInfo = useSelector(selectDataGeneralInfo)
  const indicators = useSelector(selectIndicator)
  const activeSheet = useSelector(selectActiveSheet)
  const columnId = useSelector(selectFullColumnId(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const tickerId = useSelector(selectTickerId(activeSheet))
  const tickerById = useSelector(selectTickerById(activeSheet))
  const isLoadWorkSpace = useSelector(selectIsLoadWorkSpace)

  useEffect(() => {
    if (isLoadWorkSpace) {
      workSpace = {
        templateName: listSheet[0]?.title,
        dataType: typeWorkSpace.CORPORATE,
        rawParameter: Object.keys(sheets).map((id) => {
          const sheet = sheets[id]

          return {
            template: listSheet.find((sheet) => +sheet.value === +id),
            data: {
              tickerById: keyBy(
                sheet.tickerId
                  .filter((id) => sheet.tickerById[id])
                  .map((id) => convertTickerTemplate(sheet.tickerById[id])),
                'organizationId',
              ),
              tickerId: sheet.tickerId,
              columnId: sheet.columnId,
              columnById: sheet.columnById,
              groupColumnById: sheet.groupColumnById,
              newTickerId: sheet.tickerId,
              newGroupId: getAllGroupIds(sheet.groupColumnById),
            },
          }
        }),
      }
    }
  }, [columnId, columnById, groupColumnById, tickerId])

  useEffect(() => {
    interval = setInterval(async () => {
      if (workSpace) {
        await DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }, timeoutInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (workSpace) {
        DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }
  }, [])

  useEffect(() => {
    const shareCode = searchParams.get('shareCode')

    if (userInfo.userId) {
      if (!shareCode) {
        dispatch(getWorkSpaceThunk({ UserID: userInfo.userId }))
      } else {
        dispatch(
          getShareTemplateThunk({ ShareCode: shareCode.replace(/ /g, '+') }),
        )
      }
    }
  }, [userInfo.userId])

  useEffect(() => {
    let newColumnId = columnId
    let newTickerId = tickerId
    let newColumnById = columnById
    let newTickerById = tickerById
    let isChange = false

    const firstColumnById = columnById && Object.values(columnById)[0]
    const firstTickerById = tickerById && Object.values(tickerById)[0]

    if (
      (!newColumnId.length || !firstColumnById?.indicatorName) &&
      Object.keys(columnById).length
    ) {
      isChange = true
      const indicatorsById = keyBy(indicators, 'indicatorId')

      newColumnById = Object.values(columnById).reduce((obj, item) => {
        const indicator = indicatorsById[item.indicatorId]

        if (!columnId?.length) {
          newColumnId = [...newColumnId, item.alias]
        }

        if (indicator) {
          return {
            ...obj,
            [item.alias]: {
              ...item,
              ...indicator,
            },
          }
        }

        return { ...obj, [item.alias]: item }
      }, {})
    }

    if (
      (!newTickerId.length ||
        (!firstTickerById?.organizationShortName && !firstTickerById?.name)) &&
      Object.keys(tickerById).length
    ) {
      isChange = true
      const stocksById = keyBy(stocks, 'organizationId')

      newTickerById = Object.values(tickerById).reduce((obj, item) => {
        const ticker = stocksById[item.organizationId]

        if (!tickerId?.length) {
          newTickerId = [...newTickerId, item.organizationId]
        }

        if (ticker) {
          return {
            ...obj,
            [item.organizationId]: {
              ...item,
              ...ticker,
              tickerOrTaxCode: ticker.ticker || ticker.taxCode,
            },
          }
        }

        return { ...obj, [item.organizationId]: item }
      }, {})
    }

    if (isChange) {
      dispatch(
        changeSheetParamsWhenLoadTemplate({
          activeSheet,
          tickerById: newTickerById,
          tickerId: newTickerId,
          columnById: newColumnById,
          columnId: newColumnId,
        }),
      )
    }
  }, [JSON.stringify(tickerById), JSON.stringify(columnById)])

  return <></>
}

export default WorkSpace
