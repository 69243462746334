import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { changeRetryFlag } from './investmentStructureFDI/store/slice'
import style from './style.module.css'
import { listTimeFrequency } from './totalRegisteredFDI/constant'
import {
  changeTimeFrequency,
  resetDataTable,
  selectTimeFrequency,
} from './totalRegisteredFDI/store/slice'

const TitleHead = () => {
  const dispatch = useDispatch()

  const timeFrequency = useSelector(selectTimeFrequency)

  const onChangeTimeFrequency = (value) => {
    dispatch(resetDataTable())
    dispatch(changeTimeFrequency(value))
    dispatch(changeRetryFlag(true))
  }

  return (
    <div className={`${style.header}`}>
      <Title title={'economy.fdi.fdiByProvinces.TITLE_SCREEN'} />
      <VerticalLine />
      <div className="d-flex">
        {listTimeFrequency.map(({ value, title }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === timeFrequency && style.radioActive}
                  `}
              onClick={() => onChangeTimeFrequency(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === timeFrequency}
                readOnly={true}
              />
              <Span style={{ fontSize: 12, fontWeight: 500 }}>
                <Translate value={title} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TitleHead
