import { useSelector } from 'react-redux'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { Table } from '../../../../../../common/table'
import { tableHeaderSchema } from './constants'
import { getTableSchema } from './helper'
import {
  selectCompanyType,
  selectListTableDataByIds,
  selectTableData,
  selectTableDataByIds,
  selectTableIds,
  selectTableLoading,
} from './store/slice'

export const TableComponent = () => {
  // Use selector
  const ids = useSelector(selectTableIds)
  const data = useSelector(selectTableData)
  const isLoading = useSelector(selectTableLoading)
  const companyType = useSelector(selectCompanyType)
  const tableDataByIds = useSelector(selectListTableDataByIds)

  // Actions
  const getValueById = (rowId, attr) => {
    return tableDataByIds[rowId] ? tableDataByIds[rowId][attr] : ''
  }

  return (
    <div className="h-100">
      {isLoading ? (
        <Loading />
      ) : data.length > 0 ? (
        <Table
          ids={ids}
          getDataFromRedux={selectTableDataByIds}
          schema={getTableSchema(companyType, getValueById)}
          stickies={{
            [tableHeaderSchema.TICKER]: true,
          }}
          isLargeHead={true}
          hasFooter={false}
          isPagination={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}
