import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class FinancialChartProxy extends ProxyBase {
  getChartsById(params) {
    return this.get('Charts/ById', params)
  }

  getCharts(params) {
    return this.get('Charts', params, undefined, true)
  }

  getSharedCharts(params) {
    return this.get('Charts/Shared', params)
  }

  postCharts(params) {
    return this.post('Charts', params, {}, 'post', true)
  }

  updateCharts(params) {
    return this.put('Charts', params, 'get', true)
  }

  deleteCharts(params) {
    return this.delete('Charts', params)
  }

  postChartUserSettings(params) {
    return this.post('Charts/UserSetting', params, {}, 'post')
  }

  getChartUserSettings(params) {
    return this.get('Charts/UserSetting', params)
  }
}

export default new FinancialChartProxy(
  ServiceProxyConfig.FinancialChart.ServiceUrl,
)
