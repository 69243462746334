import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import TextEllipsis from '../../../../common/textEllipsis'
import { formatVal, valDivMillion, valToPercent } from '../../../../utils/Value'
import ColCellTicker from './ColCellTicker'
import ColCellType from './ColCellType'
import { indicator } from './constants'

export const DIRECTOR_DEAL_TABLE_ID = 'director-deal-table-id'

export const directorDealSchema = () =>
  Object.keys(indicator).map((key) => {
    const title = `market.marketInDepth.directorDeal.${key}`
    const colId = indicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if ([indicator.PUBLIC_DATE, indicator.END_DATE].includes(indicator[key])) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => <DateTimeSpan date={val} />,
      }
    }

    if (indicator[key] === indicator.OWNER) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          minWidth: '100px',
        },
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        canCustomTd: true,
        render: (val) => (
          <td style={{ width: '30%', maxWidth: '30px' }}>
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    if (indicator[key] === indicator.POSITION) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          minWidth: '100px',
        },
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        canCustomTd: true,
        render: (val) => (
          <td style={{ width: '70%', maxWidth: '70px' }}>
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    if (indicator[key] === indicator.TICKER) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }

    if (indicator[key] === indicator.TYPE) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => {
          return <ColCellType val={val} />
        },
      }
    }

    if (indicator[key] === indicator.TOTAL_EST_VALUE) {
      result = {
        ...result,
        isI18nThTooltip: true,
        thTooltipCustom:
          'market.marketInDepth.directorDeal.TOOLTIP_TOTAL_EST_VALUE',
        render: (val) => {
          return formatVal(valDivMillion(Math.abs(val), 2))
        },
      }
    }

    if (
      [indicator.SHARE_ACQUIRED, indicator.SHARE_REGISTERED].includes(
        indicator[key],
      )
    ) {
      result = {
        ...result,
        render: (val) => {
          return formatVal(Math.abs(val), 0)
        },
      }
    }

    if (indicator[key] === indicator.STATE) {
      result = {
        ...result,
        tdStyle: {
          whiteSpace: 'nowrap',
          textAlign: 'right',
        },
      }
    }

    if (indicator[key] === indicator.PERCENT_OWN) {
      result = {
        ...result,
        render: (val) => {
          return valToPercent(val)
        },
      }
    }

    return result
  })
