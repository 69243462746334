import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MessageHub from '../../core/signalr/SignalrMessageHub'

const data = {}
const timeout = {}
const timeoutAction = 1000

export const Realtime = ({
  children,
  channel,
  action,
  stackName,
  disableDispatch,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (stackName) {
      data[stackName] = []
      timeout[stackName] = null
    }
    return () => {
      if (stackName) {
        delete data[stackName]
        delete timeout[stackName]
      }
    }
  }, [])

  useEffect(() => {
    const dispatchAction = (message) => {
      if (stackName) {
        if (timeout[stackName]) {
          clearTimeout(timeout[stackName])
        }
        data[stackName] = data[stackName]?.concat(message)
        timeout[stackName] = setTimeout(() => {
          disableDispatch
            ? action(data[stackName])
            : dispatch(action(data[stackName]))
          data[stackName] = []
        }, timeoutAction)
      } else {
        disableDispatch ? action(message) : dispatch(action(message))
      }
    }

    MessageHub.subscribe(channel, dispatchAction)
    return () => {
      MessageHub.unsubscribe(channel, dispatchAction)
    }
  }, [dispatch, action, channel])

  return <>{children}</>
}

Realtime.propTypes = {
  children: PropTypes.node.isRequired,
  channel: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  stackName: PropTypes.string,
}
