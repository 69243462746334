import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import Unit from '../../common/Unit'
import {
  changeIsAccQuarterlyInvestmentStructureChart,
  changeIsYearlyInvestmentStructureChart,
} from '../investmentStructure/store/slice'
import { TIME_RANGE, TIME_RANGE_OPTIONS } from './constants'
import { handleTimeRange, selectTimeRange } from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const timeRange = useSelector(selectTimeRange)

  const onChangeTab = (tab) => {
    dispatch(handleTimeRange(tab.value))
    dispatch(
      changeIsYearlyInvestmentStructureChart(tab.value === TIME_RANGE.YEARLY),
    )
    dispatch(
      changeIsAccQuarterlyInvestmentStructureChart(
        tab.value === TIME_RANGE.ACC_QUARTER,
      ),
    )
  }

  return (
    <div className="d-flex j-b align-center mb-8">
      <Unit title={'economy.fiscal.stateBudget.UNIT_BILLION_VND'} />
      <DispatchActionTab
        data={TIME_RANGE_OPTIONS}
        activeTab={timeRange}
        onChangeTab={onChangeTab}
      />
    </div>
  )
}

export default Header
