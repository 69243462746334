import OutstandingBondProxy from '../../../../proxies/bond/overview/latePayment'
import { ServiceBase } from '../../../ServiceBase'

export class LatePayment extends ServiceBase {
  getDataChart(params) {
    return this.getData(() => OutstandingBondProxy.getDataChart(params))
  }
}

export default new LatePayment()
