export const common = {
  title: 'So sánh tương quan',
  billMoney: '(Tỷ VND)',
  unitPercent: '(%)',
  quarter: {
    YEAR: 'Cả năm',
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
    Q4: 'Q4',
  },
  times: 'lần',
}

export const comparableAnalysis = {
  tableENTER_PRISE: {
    delete: ' ',
    ticker: 'Mã',
    companyName: 'Công ty',
    bS64: 'Tổng tài sản',
    rT0027: 'Nợ vay/VCSH',
    rT0037: 'Nợ vay /ebitda',
    rT0328: 'Nợ vay ròng /ebitda',
    rT0046: 'EBITDA/ Chi phí lãi vay',
    rT0372: 'FFO/Tiền mặt chi trả lãi vay',
    rT0378: 'Dư nợ/(Tổng tiền gửi + Giấy tờ có giá + Tiền gửi tổ chức)',
    rT0075: 'CFO/Nợ vay',
    rT0093: 'FFO/ Nợ vay',
    Medium: 'Trung bình',
  },
  tableREAL_ESTATE: {
    delete: ' ',
    ticker: 'Mã',
    companyName: 'Công ty',
    bS64: 'Tổng tài sản',
    rT0027: 'Nợ vay/VCSH',
    rT0037: 'Nợ vay/EBITDA',
    rT0328: 'Nợ vay ròng/EBITDA',
    rT0046: 'EBITDA/Chi phí vay lãi',
    rT0372: 'FFO/Tiền mặt chi trả lãi vay',
    rT0378: 'Dư nợ/(Tổng tiền gửi + Giấy tờ có giá + Tiền gửi tổ chức)',
    rT0075: 'CFO/ Nợ vay',
    rT0093: 'FFO/ Nợ vay',
    Medium: 'Trung bình',
  },
  tableBANK: {
    delete: ' ',
    ticker: 'Mã',
    companyName: 'Công ty',
    bS64: 'Tổng tài sản',
    rT0027: 'Dư nợ cho vay/Tiền gửi',
    rT0037: 'Nợ xấu/Tổng dư nợ',
    rT0328: 'Dự phòng tổn thất cho vay/Tổng dư nợ',
    rT0046: 'Dự phòng cho vay/Nợ xấu',
    rT0372: 'Chi phí lãi/Tổng tiền gửi',
    rT0378: 'Tổng nợ/VCSH',
    rT0075: 'Tỷ lệ vốn cấp 1',
    rT0093: 'Tỷ lệ CAR',
    Medium: 'Trung bình',
  },
  tableSTOCK: {
    delete: ' ',
    ticker: 'Mã',
    companyName: 'Công ty',
    bS64: 'Tổng tài sản',
    rT0027: 'Tổng nợ/VCSH',
    rT0037: 'Dư nợ cho vay ký quỹ/VCSH',
    rT0328: 'Chi phí hoạt động/Tổng thu nhập hoạt động',
    rT0046: 'FFO/Tiền mặt chi trả lãi vay',
    rT0372: 'Hệ số thanh toán hiện thời',
    rT0378: 'Hệ số thanh toán tiền mặt',
    rT0075: 'Nợ ngắn hạn/Tổng nợ',
    rT0093: 'Hệ số vòng quay tài sản',
    Medium: 'Trung bình',
  },
}

export const chart = {
  title: 'Hệ số đánh giá tín dụng',
  lengend: {
    enterprise: 'Doanh nghiệp',
    industry: 'Ngành',
  },
  chart1: {
    title: 'Hệ số đánh giá tín dụng',
    debtToEBITDA: 'Tỷ lệ nợ vay trên EBITDA',
    ffoInterestExpense: 'FFO/Tiến mặt chi trả lãi vay',
    ebitdaInterestCoverage: 'EBITDA/Lãi vay',
    cfoToLiabilities: 'CFO/Nợ vay',
    ffOcToDebt: 'FFO/Nợ vay',
  },
  chart2: {
    titleENTER_PRISE: 'Tỷ lệ nợ vay/vốn chủ sở hữu',
    titleREAL_ESTATE: 'Tỉ lệ nợ vay trên vốn chủ sở hữu',
    titleBANK: 'Tỉ lệ nợ vay/VCSH',
    titleSTOCK: 'Hệ số thanh toán hiện thời',
  },
  chart3: {
    titleENTER_PRISE: 'FFOc/nợ vay',
    titleREAL_ESTATE: 'FFO trên nợ vay',
    titleBANK: 'Tỷ lệ vốn cấp 1',
    titleSTOCK: 'Hệ số thanh toán tiền mặt',
  },
  chart4: {
    titleENTER_PRISE: 'Tỷ lệ nợ vay/EBITDA',
    titleREAL_ESTATE: 'Tỉ lệ nợ vay trên EBITDA',
    titleBANK: 'Tỷ lệ CAR',
    titleSTOCK: 'Hệ số vòng quay tài sản',
  },
}
