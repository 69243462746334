import { TYPE } from '../../../../../common/chart/footer/Item'

export const quarters = {
  ANNUAL: 0,
  Q1: 1,
  Q2: 2,
  Q3: 3,
  Q4: 4,
}

export const LIST_FOOTER = [
  {
    text: 'PMI',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#1d97ef',
    },
  },
  {
    text: 'economy.productionAndConsumption.production.AVERAGE',
    type: TYPE.DASHED_LINE,
    dashedLine: {
      bgColor: '#f7b80c',
    },
    isI18n: true,
  },
  {
    text: 'economy.productionAndConsumption.production.MOM',
    type: TYPE.LINE,
    before: {
      bgColor: '#f7959c',
    },
    isI18n: true,
  },
]

export const KEY_WITH_TITLE = {
  '1-key': 'PMI',
  '0-key': 'economy.productionAndConsumption.production.AVERAGE',
  '3-key': 'economy.productionAndConsumption.production.MOM',
}
