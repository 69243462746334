import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'

import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import {
  keys,
  selectFilterTopLiquidity,
  selectLoading,
  selectTopLiquidityData,
} from '../store/slice'
import {
  downloadTopLiquidityByBond,
  downloadTopLiquidityByIssuer,
} from '../store/thunk'
import Filter from './Filter'
import Table from './Table'

const TopLiquidity = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.TOP_LIQUIDITY))
  const { data } = useSelector(selectTopLiquidityData)

  const { TCPH_TP, TimeRange, TotalValueType, Top, StartDate, EndDate } =
    useSelector(selectFilterTopLiquidity)

  const handleExportData = () => {
    const payload = {
      TotalValueType,
      Top,
    }

    if (TimeRange !== 'Calendar') {
      payload.TimeRange = TimeRange
    } else {
      payload.StartDate = StartDate
      payload.EndDate = EndDate
    }

    return TCPH_TP === 'TCPH'
      ? downloadTopLiquidityByIssuer(payload)
      : downloadTopLiquidityByBond(payload)
  }

  return (
    <Panel
      title="bond.corporateBond.topLiquidity.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${+size.height}px)`,
                  position: 'relative',
                }}
              >
                {isLoading ? (
                  <Loading />
                ) : !!data.length ? (
                  <Table />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

TopLiquidity.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default TopLiquidity
