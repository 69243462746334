import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../../common/chart/footer/Item'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../../../common/chart/helper'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { getFontSize } from '../../../../../utils/FontSize'
import {
  selectChartColumnData,
  selectChartColumnLoading,
  selectChartLineData,
  selectChartLineLoading,
} from '../store/slice'
import { LineChart } from './LineChart'
import { StackBarChart } from './StackBarChart'
import { barKeys, fillColor, lineKeys, mappingDisplayName } from './constants'

const SPACE_BETWEEN_CHART = 10

export const ConsensusHistoryChart = ({ width, height }) => {
  // Use selector
  const chartLineData = useSelector(selectChartLineData)
  const chartColumnData = useSelector(selectChartColumnData)
  const chartLineLoading = useSelector(selectChartLineLoading)
  const chartColumnLoading = useSelector(selectChartColumnLoading)

  // Y axis chart left
  let [leftMin, leftMax] = [0, 0]
  if (chartColumnData.length) {
    ;[leftMin, leftMax] = yExtentsCharts(chartColumnData, barKeys, true)
  }
  const leftTickValues = getRoundedTickValues([leftMin, leftMax])
  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  // Y axis chart right
  let [rightMin, rightMax] = [0, 0]
  if (chartLineData.length) {
    ;[rightMin, rightMax] = yExtentsCharts(chartLineData, lineKeys, false, true)
  }
  const rightTickValues = getRoundedTickValues([rightMin, rightMax])
  const rightDecimalLength = getDecimalLengthYAxis(rightTickValues)

  // Footer
  const footerData = () => {
    return [
      ...barKeys.map((i) => ({
        text: mappingDisplayName[i] ?? i,
        type: FOOTER_TYPE.CIRCLE,
        color: fillColor[i],
      })),
      ...lineKeys.map((i) => ({
        text: mappingDisplayName[i] ?? i,
        type: FOOTER_TYPE.LINE,
        color: fillColor[i],
      })),
    ]
  }

  // Margin
  const calcFontSize = getFontSize(12)
  const chartMargin = getChartMargin(
    leftTickValues,
    rightTickValues,
    leftDecimalLength,
    rightDecimalLength,
    calcFontSize,
  )

  return (
    <div id="consensusHistoryChart">
      <SizeTracker>
        {(size) => (
          <>
            {size.height && (
              <div style={{ height: height - size.height || 0 }}>
                <div
                  style={{
                    height:
                      (height - size.height) * 0.45 - SPACE_BETWEEN_CHART || 0,
                    marginBottom: SPACE_BETWEEN_CHART,
                  }}
                  className="position-relative"
                >
                  {chartLineLoading || chartColumnLoading ? (
                    <Loading />
                  ) : chartLineData.length ? (
                    <LineChart
                      data={chartLineData}
                      width={width}
                      height={(height - size.height) * 0.45}
                      tickValues={rightTickValues}
                      decimalLength={rightDecimalLength}
                      margin={chartMargin}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
                <div
                  style={{ height: (height - size.height) * 0.55 || 0 }}
                  className="position-relative"
                >
                  {chartColumnLoading || chartLineLoading ? (
                    <Loading />
                  ) : chartColumnData.length ? (
                    <StackBarChart
                      data={chartColumnData}
                      width={width}
                      height={(height - size.height) * 0.55}
                      tickValues={leftTickValues}
                      decimalLength={leftDecimalLength}
                      margin={chartMargin}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            )}
            <FooterContainer
              key={width}
              numRow={1}
              numItemPerRow={7}
              data={footerData()}
            />
          </>
        )}
      </SizeTracker>
    </div>
  )
}

ConsensusHistoryChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
