import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'

const HeaderChart = ({ title }) => {
  return (
    <div className="d-flex align-center justify-content-center">
      <Span style={{ textTransform: 'uppercase', fontWeight: 600 }}>
        <Translate value={title} />
      </Span>
    </div>
  )
}

HeaderChart.propTypes = {
  title: PropTypes.string.isRequired,
}

export default HeaderChart
