import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'

const initialState = {
  isLoading: false,
  data: [],
  dataById: {},
  ids: [],
  columns: [],
  columnsById: {},
  columnIds: {},
  dataDisplay: [],
  cellInfos: [],
  tableHeaderAttr: [],
}

export const slice = createSlice({
  name: 'financialChart/common/table',
  initialState,
  reducers: {
    changeData: (state, action) => {
      const ids = []
      const data = action.payload.map((item, index) => {
        ids.push(index + 1)
        return { ...item, index: index + 1 }
      })
      state.data = data
      state.dataById = keyBy(data, 'index')
      state.ids = ids
    },
    changeColumns: (state, action) => {
      state.columns = action.payload
    },
    changeColumnIds: (state, action) => {
      state.columnIds = action.payload
    },
    changeColumnsById: (state, action) => {
      state.columnsById = action.payload
    },
    changeDataDisplay(state, action) {
      state.dataDisplay = action.payload
    },
    changeTableHeaderAttr: (state, action) => {
      state.tableHeaderAttr = action.payload
    },
    changeCellInfos: (state, action) => {
      state.cellInfos = action.payload
    },
    resetDefault: (state) => {
      state.data = []
      state.dataById = {}
      state.ids = []
      state.dataDisplay = []
    },
  },
})

export const selectLoading = (state) => state[slice.name].isLoading

export const selectIds = (state) => state[slice.name].ids
export const selectValueById = (id, attr) => (state) => {
  return state[slice.name].dataById[id]?.[attr]
}
export const selectFullValueById = (state) => state[slice.name].dataById
export const selectData = (state) => state[slice.name].data
export const selectColumnId = (index) => (state) =>
  state[slice.name].columnIds[index]
export const selectFullColumnIds = (state) => state[slice.name].columnIds
export const selectColumnByIdCell = (id) => (state) =>
  state[slice.name].columnsById[id]
export const selectFullColumnsById = (state) => state[slice.name].columnsById
export const selectDataDisplay = (index) => (state) =>
  state[slice.name].dataDisplay[index]
export const selectFullDataDisplay = (state) => state[slice.name].dataDisplay

export const selectCellInfos = (state) => state[slice.name].cellInfos
export const selectColumns = (state) => state[slice.name].columns
export const selectTableHeaderAttr = (state) =>
  state[slice.name].tableHeaderAttr

export const {
  changeData,
  changeColumns,
  changeColumnIds,
  changeColumnsById,
  changeDataDisplay,
  changeTableHeaderAttr,
  changeCellInfos,
  resetDefault,
} = slice.actions

register(slice.name, slice.reducer)
