import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CostOfOperationService from '../../../../../core/services/corporate/CostOfOperationService'
import { handleExport } from '../../../../utils/Export'

export const getRevenueCost = createAsyncThunk(
  'corporate/businessModel/costOfOperation/getRevenueCost',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CostOfOperationService.getRevenueCost(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCostStructure = createAsyncThunk(
  'corporate/businessModel/costOfOperation/getCostStructure',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CostOfOperationService.getCostStructure(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getDetailMetrics = createAsyncThunk(
  'corporate/businessModel/costOfOperation/getDetailMetrics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CostOfOperationService.getDetailMetrics(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDetailMetrics = createAsyncThunk(
  'corporate/businessModel/costOfOperation/downloadDetailMetrics',
  async (data) => {
    const response = await CostOfOperationService.downloadDetailMetrics(data)
    handleExport(response.data, response.filename)
  },
)
