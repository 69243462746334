import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'
import { changeFilter } from '../../marketShare/store/slice'

const currentYear = new Date().getFullYear()

export const getCorpRevenueBreakdown = createAsyncThunk(
  'corporate/businessModel/penetration/corp/revenueBreakdown/getCorpRevenueBreakdown',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PenetrationAnalysisService.getCorpRevenueBreakdown(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCorpMaxPeriod = createAsyncThunk(
  'corporate/businessModel/penetration/corp/getCorpMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PenetrationAnalysisService.getCorpMaxPeriod(params)
      if (response.success) {
        dispatch(changeFilter({ year: response.data.year || currentYear }))

        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      dispatch(changeFilter({ year: currentYear }))
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
