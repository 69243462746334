import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import { Z_INDEX } from '../../../../../../constants/Common'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr }) => {
  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={`key-${index}`}
              {...row?.attrs}
              className={`${!row.additionText && style.verticalAlignInitial} ${
                style.firstCellHeader
              } ${row.alignRight && style.textRight} ${style.tableStickyHead}`}
              style={{ padding: '4px 8px' }}
            >
              <div>
                <Span
                  className={`${style.theadContent}`}
                  style={{ fontWeight: 600 }}
                >
                  <Translate value={row.title} />
                </Span>

                {row.additionText && (
                  <Span className={style.additionText} style={{ fontSize: 12 }}>
                    <Translate value={row.additionText} />
                  </Span>
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
