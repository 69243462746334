import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { findRoundOffset } from '../../../../common/chart/helper'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { keys, selectContribution, selectLoading } from '../store/slice'
import HalfContentContribution from './HalfContentContribution'
import XAxisContribution from './XAxisContribution'

const ContentContribution = ({ sizes, tabActive }) => {
  const loading = useSelector(selectLoading(keys.CONTRIBUTION))
  const contribution = useSelector(selectContribution)

  const data = contribution[tabActive]
  const topGainers =
    data?.topGainers.map((item) => {
      return { ...item, contributionPercent: item.contributionPercent * 100 }
    }) || []
  const topLosers =
    data?.topLosers?.map((item) => {
      return { ...item, contributionPercent: item.contributionPercent * 100 }
    }) || []

  const minNumberData = 10
  const numberData = Math.max(
    topGainers.length,
    topLosers.length,
    minNumberData,
  )

  const maxXAxis =
    topGainers.concat(topLosers).reduce((max, item) => {
      if (Math.abs(item.contributionPercent) > max) {
        return Math.abs(item.contributionPercent)
      }
      return max
    }, 0) || 1

  const offsetRound = findRoundOffset([-maxXAxis, maxXAxis])
  const maxRoundXAxis = Math.ceil(maxXAxis / offsetRound) * offsetRound

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <div
              style={{ height: `calc(100% - ${size.height}px)` }}
              className="d-flex position-relative"
            >
              {loading ? (
                <Loading />
              ) : !topGainers.length && !topLosers.length ? (
                <div className="w-100">
                  <NoData />
                </div>
              ) : (
                <>
                  <HalfContentContribution
                    sizes={sizes}
                    data={topLosers}
                    numberData={numberData}
                    maxValue={maxRoundXAxis}
                  />
                  <HalfContentContribution
                    sizes={sizes}
                    data={topGainers}
                    isPositive
                    numberData={numberData}
                    maxValue={maxRoundXAxis}
                  />
                </>
              )}
            </div>
          )}
          <XAxisContribution maxXAxis={maxRoundXAxis} />
        </>
      )}
    </SizeTracker>
  )
}

ContentContribution.propTypes = {
  tabActive: PropTypes.string.isRequired,
}

export default ContentContribution
