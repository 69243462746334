import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { INDICATORS } from '../../constant'
import {
  getAllOrganizationByIcbIdAndGroupId,
  getMarketCapOrganizationByIcbId,
} from './thunk'

const initialState = {
  isShowPopup: false,
  indicator: INDICATORS.basicPE,
  tickerList: [],
  selectedItems: [],
  loading: true,
  loadingDefaultItems: true,
  callDataFlag: true,
}

const slice = createSlice({
  name: 'sector/sectorConstituents/valuation/filter',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeIsShowPopup: (state, action) => {
      state.isShowPopup = action.payload
    },
    changeIndicator: (state, action) => {
      state.indicator = action.payload
    },
    changeSelectedItems: (state, action) => {
      state.selectedItems = action.payload
    },
    deleteTickerOrSector: (state, action) => {
      if (action.payload.organizationId) {
        const index = state.selectedItems.findIndex(
          (item) => item.organizationId === action.payload.organizationId,
        )
        if (index !== -1) {
          if (
            !!state.selectedItems[index - 1]?.icbId &&
            state.selectedItems[index - 1]?.isDeleted
          ) {
            state.selectedItems.splice(index - 1, 2)
          } else {
            state.selectedItems.splice(index, 1)
          }
        }
      }

      if (action.payload.icbId) {
        const sectorIndex = state.selectedItems.findIndex(
          (item) => item.icbId === action.payload.icbId,
        )
        if (
          sectorIndex === state.selectedItems.length - 1 ||
          !!state.selectedItems[sectorIndex + 1].icbId
        ) {
          state.selectedItems.splice(sectorIndex, 1)
        } else {
          state.selectedItems[sectorIndex].isDeleted = true
        }
      }
    },
    triggerCallData: (state) => {
      state.callDataFlag = !state.callDataFlag
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllOrganizationByIcbIdAndGroupId.pending,
      (state, action) => {
        const isSetDefaultItems = action.meta.arg.isSetDefaultItems
        isSetDefaultItems
          ? (state.loadingDefaultItems = true)
          : (state.loading = true)
      },
    )
    builder.addCase(
      getAllOrganizationByIcbIdAndGroupId.fulfilled,
      (state, action) => {
        state.tickerList = action.payload
        state.loading = false
      },
    )
    builder.addCase(
      getAllOrganizationByIcbIdAndGroupId.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(
      getMarketCapOrganizationByIcbId.pending,
      (state, action) => {
        state.loadingDefaultItems = true
      },
    )
    builder.addCase(
      getMarketCapOrganizationByIcbId.fulfilled,
      (state, action) => {
        const icbId = action.meta.arg.IcbId
        state.selectedItems = [
          { icbId, color: 0 },
          ...action.payload.map((item, index) => ({
            organizationId: item.organizationId,
            color: index + 1,
          })),
        ]
        state.loadingDefaultItems = false
      },
    )
  },
})

export const selectIsShowPopup = (state) => state[slice.name].isShowPopup
export const selectIndicator = (state) => state[slice.name].indicator
export const selectTickerList = (state) => state[slice.name].tickerList
export const selectLoading = (state) => state[slice.name].loading
export const selectLoadingDefaultItems = (state) =>
  state[slice.name].loadingDefaultItems
export const selectSelectedItems = (state) => state[slice.name].selectedItems
export const selectCallDataFlag = (state) => state[slice.name].callDataFlag

export const {
  resetStore,
  changeIsShowPopup,
  changeIndicator,
  deleteTickerOrSector,
  changeSelectedItems,
  triggerCallData,
} = slice.actions

register(slice.name, slice.reducer)
