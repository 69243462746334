import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import useGetICB from '../../../../common/masterData/useGetICB'
import PopupAlert from '../../../../common/popup/PopupAlert'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import {
  selectidsCompany,
  updateSelectCompany,
} from '../tradingStatistics/store/slice'
import { ISSUE_METHOD } from './constants'
import FilterCompany from './filter/FilterCompany'
import FilterSector from './filter/FilterSector'
import {
  END_TIME_HOUR,
  END_TIME_MINUTE,
  START_TIME_HOUR,
  START_TIME_MINUTE,
} from './helper'
import style from './index.module.css'
import {
  changeFilter,
  changePage,
  resetFilter,
  selectActiveTab,
  selectFilterPriceBoard,
  selectFilterSort,
  selectLoadingIcbIds,
} from './store/slice'
import { getTradingStatistics } from './store/thunk'

const Filter = () => {
  const dispatch = useDispatch()
  const OrganizationIds = useSelector(selectidsCompany)
  const activeTab = useSelector(selectActiveTab)
  const { SortBy, SortOrder } = useSelector(selectFilterSort)
  const filter = useSelector(selectFilterPriceBoard)
  const { issueMethodId, isCleanPrice, icbIds, timeRange, page, From, To } =
    filter
  const locale = useSelector((state) => state.i18n.locale)
  const { ICBs } = useGetICB()
  const listSector = ICBs.filter((item) => item.icbLevel === 2).map((item) => ({
    icbNameCustom: item.icbName + ' L2',
    ...item,
  }))
  const loadingIcbIds = useSelector(selectLoadingIcbIds)

  const timeZone = UseTimeZone()

  const [isShowAlert, setIsShowAlert] = useState('')
  const typeInput = 1
  const [isReset, setIsReset] = useState(false)

  const durationFromRef = useRef()
  const durationToRef = useRef()
  const interestFromRef = useRef()
  const interestToRef = useRef()

  const handleChangeFilter = ({ key, value }) => {
    dispatch(changeFilter({ key, value }))
    dispatch(changePage(page))
  }

  const handleChangeIssueMethod = (check, value) => {
    handleChangeFilter({
      key: 'issueMethodId',
      value: check
        ? [...issueMethodId, value]
        : issueMethodId.filter((item) => item !== value),
    })
    dispatch(changePage(1))
  }

  const handleResetFilter = () => {
    setIsReset(true)
    dispatch(resetFilter(listSector.map((item) => item.icbId)))
    dispatch(updateSelectCompany([]))
    dispatch(changePage(1))
  }

  const handleClosePopup = () => {
    switch (typeInput) {
      case 1:
        durationFromRef?.current?.focus()
        break
      case 2:
        durationToRef?.current?.focus()
        break
      case 3:
        interestFromRef?.current?.focus()
        break
      case 4:
        interestToRef?.current?.focus()
        break
      default:
        break
    }

    setIsShowAlert('')
  }

  const handleSearch = () => {
    const payload =
      From && To
        ? {
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Page: page,
            PageSize: 20,
            SortBy,
            SortOrder,
            From: formatDateTime(From, FORMAT.DATE),
            To: formatDateTime(To, FORMAT.DATE),
            OrganizationIds: OrganizationIds,
          }
        : {
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Page: page,
            PageSize: 20,
            TimeRange: timeRange,
            SortBy,
            SortOrder,
            OrganizationIds: OrganizationIds,
          }

    dispatch(getTradingStatistics(payload))
  }

  useEffect(() => {
    if (!loadingIcbIds && activeTab) {
      handleSearch()
    }
  }, [
    locale,
    // tradingStatusId.length,
    issueMethodId.length,
    icbIds.length,
    loadingIcbIds,
    activeTab,
    isCleanPrice,
    timeRange,
    page,
    From,
    To,
    SortOrder,
    SortBy,
    OrganizationIds,
  ])

  useEffect(() => {
    if (isReset) {
      handleSearch()
      setIsReset(false)
    }
  }, [isReset])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: +START_TIME_HOUR ?? 8,
      minute: +START_TIME_MINUTE ?? 50,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        handleSearch()
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: +END_TIME_HOUR ?? 15,
      minute: +END_TIME_MINUTE ?? 30,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        handleSearch()
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  return (
    <div>
      <div
        className="d-flex ali-center mb-8"
        style={{ flexWrap: 'wrap', gap: 10 }}
      >
        <div className="d-flex ali-center" style={{ flexWrap: 'wrap', gap: 8 }}>
          <FilterCompany />
          <FilterSector />
          <VerticalLine mr={0} ml={12} h={15} />

          <div className="d-flex ali-center" style={{ gap: 12 }}>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={issueMethodId.includes(ISSUE_METHOD.PUBLIC) ?? false}
                onChange={(e) =>
                  handleChangeIssueMethod(
                    e?.target?.checked ?? false,
                    ISSUE_METHOD.PUBLIC,
                  )
                }
              />
              <Translate
                value="bond.corporateBond.priceBoard.PUBLIC"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
            <label className="d-flex ali-center">
              <input
                type="checkbox"
                className="checkbox mr-8"
                checked={issueMethodId.includes(ISSUE_METHOD.PRIVATE) ?? false}
                onChange={(e) =>
                  handleChangeIssueMethod(
                    e?.target?.checked ?? false,
                    ISSUE_METHOD.PRIVATE,
                  )
                }
              />
              <Translate
                value="bond.corporateBond.priceBoard.PRIVATE"
                style={{ flexShrink: 0, fontWeight: 340 }}
              />
            </label>
          </div>
        </div>
        <div
          className="d-flex ali-center ml-2"
          style={{
            gap: 8,
            flexShrink: 0,
            alignSelf: 'flex-start',
          }}
        >
          <div className="w-80">
            <Button
              className={`btn btn-blue h-20 ${style.buttonReset}`}
              onClick={handleResetFilter}
            >
              <Translate value="bond.corporateBond.priceBoard.RESET" />
            </Button>
          </div>
        </div>
      </div>
      <PopupAlert
        message={<Translate value={isShowAlert} />}
        isShow={!!isShowAlert.length}
        handleClose={handleClosePopup}
      />
    </div>
  )
}

export default Filter
