import { UNIT_VALUE_CONSTANTS } from '../filter/constants'

export const TABLE_ROW_LEVEL = {
  LEVEL_0: 0,
  LEVEL_1: 1,
}

export const FORMAT_DATA_BY_UNIT = {
  [UNIT_VALUE_CONSTANTS.VND]: 1,
  [UNIT_VALUE_CONSTANTS.MILLION_VND]: 10 ** 6,
  [UNIT_VALUE_CONSTANTS.BILLION_VND]: 10 ** 9,
  [UNIT_VALUE_CONSTANTS.USD]: 1,
  [UNIT_VALUE_CONSTANTS.THOUSAND_USD]: 10 ** 3,
  [UNIT_VALUE_CONSTANTS.MILLION_USD]: 10 ** 6,
}

export const INDICATOR_FIX_UNIT_INT = ['IS23', 'IS24']
