export const paymentBalance = {
  BALANCE_OF_PAYMENT: 'Balance of Payment',
  CHANGE_BALANCE: 'Change in balance of payment',
  CHANGE_COMPONENTS: 'Change in Component of',

  FILTER_YEARLY: 'Yearly',
  FILTER_QUARTERLY: 'Quarterly',

  UNIT: 'Unit',
  MILLION_USD: 'Million USD',

  ALL: 'All',

  CURRENT_ACC: 'Current Account',
  CAPITAL_ACC: 'Capital Account',
  FINANCIAL_ACC: 'Financial Account',
  ERROR_OMISSION: 'Errors and Omissions',
  OVERALL_BALANCE: 'Overall balance',
  RESERVED_RELATED: 'Reserved and Related Items',

  WARNING_MESS: 'The number of indicators is no more than 20',
}
