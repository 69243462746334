import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { REVERSE_REPO_SELL_TABS } from '../../constants'
import { CalendarSearch } from '../panelOpenMarket/CalendarSearch'
import style from './index.module.css'
import {
  getFromDate,
  getLimitTime,
  getReverseSellTab,
  getToDate,
  handleFromDate,
  handleReverseSellTab,
  handleToDate,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const reverseSellTab = useSelector(getReverseSellTab)
  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const limitTime = useSelector(getLimitTime)

  const onChangeTab = (tab) => {
    dispatch(handleReverseSellTab(tab.value))
  }

  const onDateFromChange = (fromDate) => {
    dispatch(handleFromDate(fromDate))
  }

  const onDateToChange = (toDate) => {
    dispatch(handleToDate(toDate))
  }

  return (
    <div className={style.headerMarketAuction}>
      <DispatchActionTab
        data={REVERSE_REPO_SELL_TABS}
        activeTab={reverseSellTab}
        onChangeTab={onChangeTab}
        containerStyle={{ marginTop: 8 }}
      />
      <CalendarSearch
        dateFrom={fromDate}
        dateTo={toDate}
        onDateFromChange={onDateFromChange}
        onDateToChange={onDateToChange}
        limitDatepicker={limitTime}
      />
    </div>
  )
}

export default Header
