import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Filter } from '../../../common/news/filter'
import {
  changeMoreFilter,
  selectMoreCategories,
  selectMoreFilterData,
  selectMoreSources,
} from '../store/slice'
import { getNewsCategories, getNewsSources } from '../store/thunk'

export const MoreFilter = () => {
  const dispatch = useDispatch()
  const sources = useSelector(selectMoreSources)
  const categories = useSelector(selectMoreCategories)
  const filterData = useSelector(selectMoreFilterData)
  const locale = useSelector((state) => state.i18n.locale)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const type = params.get('type')

  useEffect(() => {
    dispatch(getNewsSources())
    dispatch(getNewsCategories({ NewsCategoryId: type }))
  }, [locale])

  const changeFilter = (data) => {
    dispatch(changeMoreFilter(data))
  }

  return (
    <Filter
      sources={sources}
      categories={categories}
      sourceValue={filterData.source}
      categoryValue={filterData.category}
      filterStartDate={filterData.startDate}
      filterEndDate={filterData.endDate}
      changeFilter={changeFilter}
      time={filterData.time}
      newsTypeValue={type}
      isShowNewType={false}
    />
  )
}
