import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'
import PieChart from '..'
import { getFontSize } from '../../../../utils/FontSize'
import { SizeTracker } from '../../../sizeTracker'
import { Footer } from '../../footer'
import { FooterContainer } from '../../footer/FooterContainer'
import PieChartTooltip from './PieChartTooltip'
import { getFooterData } from './helper'
import style from './index.module.css'

const PieChartWithFooter = ({
  data,
  height,
  width,
  footerData,
  footerRight,
  pieChartProps,
  footerProps,
  renderTooltip,
  isUseContainerFooter,
  themeDownloadPDF,
  customLabel,
  labelStyle,
  customStyleChartDiv,
  isScroll,
}) => {
  const fontSize = getFontSize(12)

  const [activeIndex, setActiveIndex] = useState(null)

  const footerDataMemo = useMemo(() => {
    return footerData || getFooterData(data)
  }, [data, footerData])

  const onMouseOverItem = useCallback(
    (dataItem) => {
      if (!themeDownloadPDF) {
        const segmentIndex = data.findIndex(
          (item) => item.color === dataItem.color,
        )
        setActiveIndex(segmentIndex)
      }
    },
    [data],
  )

  const onMouseLeaveItem = useCallback(() => {
    if (!themeDownloadPDF) {
      setActiveIndex(null)
    }
  }, [])

  const handleRenderTooltip = (_, extraData) => {
    if (renderTooltip) {
      return renderTooltip(data, extraData)
    } else {
      return (
        <PieChartTooltip data={data} decimalLength={extraData.decimalLength} />
      )
    }
  }

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <div className={footerRight ? style.pieChartFooterRight : ''}>
              {size.height && (
                <div
                  className={footerRight ? style.pieChartLeft : ''}
                  style={{ ...customStyleChartDiv }}
                >
                  <PieChart
                    style={{
                      fontSize,
                      height: footerRight ? height : height - size.height,
                    }}
                    data={data}
                    activeSegmentIndex={activeIndex}
                    renderTooltip={handleRenderTooltip}
                    themeDownloadPDF={themeDownloadPDF}
                    customLabel={customLabel}
                    labelStyle={labelStyle}
                    itemStyle={{ fixedFontSize: themeDownloadPDF ? 10 : '' }}
                    {...pieChartProps}
                  />
                </div>
              )}
              <div
                className={`${footerRight ? style.footerRight : ''} ${
                  isScroll ? style.footerRightScroll : ''
                }`}
              >
                {isUseContainerFooter ? (
                  <FooterContainer
                    key={width}
                    data={footerDataMemo}
                    onMouseOverItem={onMouseOverItem}
                    onMouseLeaveItem={onMouseLeaveItem}
                    {...footerProps}
                  />
                ) : (
                  <Footer
                    key={width}
                    onMouseOverItem={onMouseOverItem}
                    onMouseLeaveItem={onMouseLeaveItem}
                    list={footerDataMemo}
                    itemStyle={{ fixedFontSize: themeDownloadPDF ? 10 : '' }}
                    {...footerProps}
                  />
                )}
              </div>
            </div>
          )
        }}
      </SizeTracker>
    </>
  )
}

PieChartWithFooter.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
      color: PropTypes.string.isRequired,
      isI18n: PropTypes.bool,
    }),
  ).isRequired,
  footerData: PropTypes.object,
  pieChartProps: PropTypes.object,
  footerProps: PropTypes.object,
  renderTooltip: PropTypes.func,
  isUseContainerFooter: PropTypes.bool,
  themeDownloadPDF: PropTypes.bool,
  customLabel: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  labelStyle: PropTypes.object,
  isScroll: PropTypes.bool,
}

PieChartWithFooter.defaultProps = {
  pieChartProps: {},
  footerProps: {},
  isUseContainerFooter: false,
  themeDownloadPDF: false,
  customLabel: false,
  labelStyle: {},
  isScroll: false,
}

export default PieChartWithFooter
