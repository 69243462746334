export const FILTER_MIN_YEAR = 2004

export const VIEW_BY = {
  MARGIN_LENDING: 'MARGIN_LENDING',
  PROPRIETARY: 'PROPRIETARY',
  TREASURY: 'TREASURY',
}

export const MAP_VALUE_VIEW_BY = {
  MARGIN_LENDING: 'MarginLending',
  PROPRIETARY: 'Proprietary',
  TREASURY: 'Treasury',
}

export const QUARTER = {
  QUARTER_1: 'QUARTER_1',
  QUARTER_2: 'QUARTER_2',
  QUARTER_3: 'QUARTER_3',
  QUARTER_4: 'QUARTER_4',
  ANNUAL: 'ANNUAL',
}

export const RATIO_QUARTER = [
  { key: QUARTER.QUARTER_1, value: 1, name: 'quarter' },
  { key: QUARTER.QUARTER_2, value: 2, name: 'quarter' },
  { key: QUARTER.QUARTER_3, value: 3, name: 'quarter' },
  { key: QUARTER.QUARTER_4, value: 4, name: 'quarter' },
  { key: QUARTER.ANNUAL, value: 0, name: 'quarter' },
]
