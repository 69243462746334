import { useEffect, useRef, useState } from 'react'
import TextEllipsis from '../../../../../common/textEllipsis'
import DropdownTooltip from '../../../../../common/textEllipsis/DropdownTooltip'
import { Z_INDEX } from '../../../../../constants/Common'
import style from './index.module.css'

export const TdSelect = ({
  val,
  rowId,
  onRowCollapse,
  data,
  isRowCollapse,
  listItemChecked,
}) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const containerRef = useRef()

  const [isCollapse, setIsCollapse] = useState(false)
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const itemWithCollapse = data?.map((item) => ({
    ...item,
    isCollapsible: item.isGroupRow,
  }))

  const dataTableItem = itemWithCollapse?.find(
    (item) => item.organizationId === rowId,
  )

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listItemChecked?.some((item) => item.organizationId === rowId)
  }

  const handleDisableCheckbox = () => {}

  const handleOpenTooltip = () => setIsOpenTooltip(true)

  const handleCloseTooltip = () => setIsOpenTooltip(false)

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <div>
        {dataTableItem?.isCollapsible ? (
          <input
            type="checkbox"
            className={`checkbox ${style.checkbox}`}
            onChange={handleChangeListCategory}
            checked={handleChecked()}
            disabled={handleDisableCheckbox()}
          />
        ) : (
          <div style={{ width: '30px' }}></div>
        )}
      </div>
      <div
        className="d-flex"
        style={{
          marginLeft: !dataTableItem?.isCollapsible ? MARGIN_LEFT : 0,
        }}
      >
        {dataTableItem?.isCollapsible ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <></>
        )}
        <div
          onMouseOver={handleOpenTooltip}
          onMouseOut={handleCloseTooltip}
          ref={containerRef}
          className={dataTableItem?.isCollapsible ? style.pointer : ''}
        >
          <TextEllipsis isI18n={false} text={val} />
        </div>
        {isOpenTooltip && dataTableItem && (
          <DropdownTooltip
            containerRef={containerRef}
            isOpenTooltip={isOpenTooltip}
            zIndexTooltip={Z_INDEX.MODAL + 1}
            isI18n={true}
            text={
              dataTableItem?.fullName.length
                ? dataTableItem?.fullName
                : dataTableItem?.name
            }
          />
        )}
      </div>
    </div>
  )
}
