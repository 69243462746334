import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../../core/services/market/CoveredWarrantService'
import { handleExport } from '../../../../../../utils/Export'

export const getHistoricalPriceOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalPrice/getHistoricalPriceOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getHistoricalPriceOverview(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getHistoricalPriceDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalPrice/getHistoricalPriceDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getHistoricalPriceDetail(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getHistoricalPriceTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalStatistics/getHistoricalPriceTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CoveredWarrantService.getHistoricalPriceTicker(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getCoverWarrants = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalStatistics/getCoverWarrants',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getCoverWarrants(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadHistoricalOverview = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalStatistics/downloadHistoricalOverview',
  async (data) => {
    const response = await CoveredWarrantService.downloadHistoricalOverview(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadHistoricalDetail = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalStatistics/downloadHistoricalDetail',
  async (data) => {
    const response = await CoveredWarrantService.downloadHistoricalDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadHistoricalTicker = createAsyncThunk(
  'market/coveredWarrant/statistics/historicalStatistics/downloadHistoricalTicker',
  async (data) => {
    const response = await CoveredWarrantService.downloadHistoricalTicker(data)
    handleExport(response.data, response.filename)
  },
)
