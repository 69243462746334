import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TextEllipsis from '../../../../common/textEllipsis'
import { MAX_SECURITIES_ITEM } from '../../../common/constants'
import {
  selectIsLoading,
  selectListExchangeChecked,
  selectListParentExchangeChecked,
  selectListParentSectorChecked,
  selectListSectorChecked,
  selectTickers,
} from '../../common/store/slice'
import { getOrganizationsByExchangeAndICB } from '../../common/store/thunk'
import SelectExchangeSector from '../SelectExchangeSector'
import style from '../style.module.css'
import { ItemCheckAll } from './ItemCheckAll'

const ListCompanies = ({
  valueSearch,
  securitiesState,
  setSecuritiesState,
}) => {
  const dispatch = useDispatch()

  const isFirstMount = useRef(true)

  const loading = useSelector(selectIsLoading('companies'))
  const listExchangeChecked = useSelector(selectListExchangeChecked)
  const listParentExchangeChecked = useSelector(selectListParentExchangeChecked)
  const listSectorChecked = useSelector(selectListSectorChecked)
  const listParentSectorChecked = useSelector(selectListParentSectorChecked)
  const tickers = useSelector(selectTickers)

  const [filteredTickers, setFilteredTickers] = useState([])

  const isCheckAll = useMemo(() => {
    return filteredTickers.every((item) =>
      securitiesState.companies.includes(item.organizationId),
    )
  }, [securitiesState.companies, filteredTickers])

  const handleCheckboxChange = (value) => {
    const orgId = +value.organizationId
    const condition = securitiesState.companies.some((item) => item === orgId)

    if (!condition) {
      setSecuritiesState({
        ...securitiesState,
        companies: [...securitiesState.companies, orgId],
      })
    } else {
      setSecuritiesState({
        ...securitiesState,
        companies: [
          ...securitiesState.companies.filter((ticker) => ticker !== orgId),
        ],
      })
    }
  }

  const handleCheckAll = () => {
    if (isCheckAll) {
      setSecuritiesState({
        ...securitiesState,
        companies: [],
      })
    } else {
      const companiesId = filteredTickers.map((item) => +item.organizationId)
      setSecuritiesState({
        ...securitiesState,
        companies: companiesId,
      })
    }
  }

  useEffect(() => {
    if (listSectorChecked.length === 0 && isFirstMount.current) {
      return
    }
    isFirstMount.current = false
    dispatch(
      getOrganizationsByExchangeAndICB({
        groupIds: [...listExchangeChecked, ...listParentExchangeChecked],
        icbIds: [...listSectorChecked, ...listParentSectorChecked],
      }),
    )
  }, [
    listExchangeChecked,
    listParentExchangeChecked,
    listSectorChecked,
    listParentSectorChecked,
    isFirstMount.current,
  ])

  useEffect(() => {
    setFilteredTickers(
      tickers
        .filter((ticker) =>
          valueSearch
            ? ticker.tickerOrTaxCode
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase()) ||
              ticker.organizationShortName
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [tickers, valueSearch])

  const renderTickers = () => (
    <ScrollComponent bgColor="#D9D9D9">
      <ItemCheckAll checked={isCheckAll} onChange={handleCheckAll} />
      {filteredTickers.map((ticker, index) => {
        return (
          <div
            key={`${ticker.organizationId}_${index}`}
            className={style.itemTicker}
            onClick={() => handleCheckboxChange(ticker)}
          >
            <div className={`ml-8 ${style.checkBox}`}>
              <label>
                <input
                  type="checkbox"
                  className={`checkbox line `}
                  checked={securitiesState.companies.includes(
                    ticker.organizationId,
                  )}
                  value={ticker.organizationId}
                  readOnly
                />
              </label>
            </div>
            <div className={style.tickerInfo}>
              <div className={style.tickerCode}>
                <TextEllipsis isI18n={false} text={ticker.tickerOrTaxCode} />
              </div>
              <div className={style.orgName}>
                <TextEllipsis
                  isI18n={false}
                  text={ticker.organizationShortName || ticker.organizationName}
                />
              </div>
            </div>
          </div>
        )
      })}
    </ScrollComponent>
  )

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className={style.topFilter}>
              <SelectExchangeSector />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {loading ? (
                  <Loading isBlack />
                ) : filteredTickers.length ? (
                  renderTickers()
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ListCompanies
