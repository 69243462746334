import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import { getFirstDayOfMonth, getLastDayNext6Month } from '../helper'
import {
  getChecked,
  getEventTypesLoading,
  getMultipleActiveRows,
  getPage,
  getTab,
  getUpcomingStartMonth,
  selectEndDate,
  selectEventIds,
  selectLoadingUpcomingEvent,
  selectStartDate,
} from '../store/slice'
import { getRelateNews } from '../store/thunk'
import Default from './Default'
import ListEvent from './ListEvent'

const PAGE_SIZE = 20

const News = () => {
  const dispatch = useDispatch()

  const eventIds = useSelector(selectEventIds)
  const startDate = useSelector(selectStartDate)
  const endDate = useSelector(selectEndDate)
  const timeZone = UseTimeZone()
  const tab = useSelector(getTab)
  const eventTypeLoading = useSelector(getEventTypesLoading)
  const checked = useSelector(getChecked)
  const upcomingStartMonth = useSelector(getUpcomingStartMonth)
  const isLoadingUpcomingEvent = useSelector(selectLoadingUpcomingEvent)
  const locale = useSelector((state) => state.i18n.locale)
  const page = useSelector(getPage)
  const multipleActiveRowsIds = useSelector(getMultipleActiveRows)

  useEffect(() => {
    if (
      !isLoadingUpcomingEvent &&
      !eventTypeLoading &&
      !startDate &&
      !endDate
    ) {
      dispatch(
        getRelateNews({
          DateFrom: getISOStartOrEndOfDay(
            getFirstDayOfMonth(upcomingStartMonth),
            timeZone,
            true,
          ),
          DateTo: getISOStartOrEndOfDay(
            getLastDayNext6Month(upcomingStartMonth),
            timeZone,
            false,
          ),
          EventIds: multipleActiveRowsIds.length
            ? multipleActiveRowsIds.toString()
            : eventIds.toString(),
          Page: page,
          PageSize: PAGE_SIZE,
        }),
      )
    }
  }, [
    tab,
    checked,
    locale,
    page,
    upcomingStartMonth,
    eventTypeLoading,
    isLoadingUpcomingEvent,
    multipleActiveRowsIds,
  ])

  useEffect(() => {
    if (!isLoadingUpcomingEvent && !eventTypeLoading && startDate && endDate) {
      dispatch(
        getRelateNews({
          DateFrom: getISOStartOrEndOfDay(new Date(startDate), timeZone, true),
          DateTo: getISOStartOrEndOfDay(new Date(endDate), timeZone, false),
          EventIds: multipleActiveRowsIds.length
            ? multipleActiveRowsIds.toString()
            : eventIds.toString(),
          Page: page,
          PageSize: PAGE_SIZE,
        }),
      )
    }
  }, [
    startDate,
    endDate,
    tab,
    page,
    checked,
    locale,
    upcomingStartMonth,
    eventTypeLoading,
    isLoadingUpcomingEvent,
    multipleActiveRowsIds,
  ])

  if (multipleActiveRowsIds.length !== 0) {
    return (
      <ListEvent
        isLoadingUpcomingEvent={isLoadingUpcomingEvent}
        eventTypeLoading={eventTypeLoading}
      />
    )
  }

  return (
    <Default
      isLoadingUpcomingEvent={isLoadingUpcomingEvent}
      eventTypeLoading={eventTypeLoading}
      page={page}
    />
  )
}

export default News
