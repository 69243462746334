import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../common/table/helper'
import { keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../utils/Value'
import { valueTab } from '../constants'
import {
  getDateHasEvent,
  getDisableClick,
  getEventIds,
  getIds,
  handleActiveRows,
  handleChecked,
  handleLevels,
  handleNewsData,
  handleUpcomingData,
  keyByParentId,
} from '../helper'
import {
  getEventsDate,
  getRelateNews,
  getTargetIds,
  getUpcomingEvents,
} from './thunk'

const { ALL, VIET_NAM, OTHER } = valueTab
export const ALL_EVENT_ID = 'All'
export const INIT_CHECKED = {
  [ALL]: [ALL_EVENT_ID],
  [VIET_NAM]: [ALL_EVENT_ID],
  [OTHER]: [ALL_EVENT_ID],
}

const initialState = {
  isLoadingUpcomingEvent: true,
  valueByIdUpcomingEvent: {},
  idsUpcomingEvent: [],
  upcomingEventData: [],
  initIds: [],
  levels: [],
  idsWithParentId: {},
  eventIds: [],

  isLoadingNews: true,
  newsData: [],
  valueByIdNews: {},
  idsNews: [],
  startDate: null,
  endDate: null,
  tab: ALL,
  eventTypes: [],
  eventTypesLoading: true,
  checked: INIT_CHECKED,
  upcomingStartMonth: new Date().getMonth() - 1,
  dateHasEvents: [],
  page: 1,
  totalPage: 1,
  multipleActiveRowsIds: [],
  disableClick: [],
}

export const slice = createSlice({
  initialState,
  name: 'economy/event',
  reducers: {
    changeSelectedDate: (state, action) => {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    handleStartDate: (state, action) => {
      state.startDate = action.payload
    },
    handleEndDate: (state, action) => {
      state.endDate = action.payload
    },
    handleTab: (state, action) => {
      state.tab = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checked = action.payload
    },
    resetDate: (state) => {
      state.startDate = null
      state.endDate = null
    },
    handleUpcomingStartMonth: (state, action) => {
      state.upcomingStartMonth = action.payload
    },
    handleEventIds: (state, action) => {
      state.eventIds = action.payload
    },
    handlePage: (state, action) => {
      state.page = action.payload
    },
    changeActiveRow: (state, action) => {
      state.multipleActiveRowsIds = handleActiveRows(
        action.payload,
        state.multipleActiveRowsIds,
      )
    },
    handleActiveRowId: (state, action) => {
      state.multipleActiveRowsIds = action.payload
    },
    sort: (state, action) => {
      state.idsUpcomingEvent = getIdsFromProps(
        state.idsUpcomingEvent,
        state.valueByIdUpcomingEvent,
        action.payload,
        state.initIds,
        0,
        state.levels,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTargetIds.pending, (state) => {
      state.eventTypesLoading = true
    })
    builder.addCase(getTargetIds.fulfilled, (state, action) => {
      const data = action.payload || []
      state.eventTypesLoading = false
      state.eventTypes = data
      state.checked = handleChecked(data, action.meta.arg, state.checked)
      state.idsWithParentId = keyByParentId(data)
    })
    builder.addCase(getTargetIds.rejected, (state, action) => {
      state.eventTypesLoading = action.payload.isLoading
    })
    builder.addCase(getUpcomingEvents.pending, (state) => {
      state.isLoadingUpcomingEvent = true
    })
    builder.addCase(getUpcomingEvents.fulfilled, (state, action) => {
      const data = action.payload || []
      state.isLoadingUpcomingEvent = false
      state.upcomingEventData = handleUpcomingData(data)
      state.idsUpcomingEvent = state.initIds = getIds(state.upcomingEventData)
      state.valueByIdUpcomingEvent = keyBy(
        state.upcomingEventData,
        'macroEventId',
      )
      state.levels = handleLevels(state.upcomingEventData)
      state.eventIds = getEventIds(data)
      state.disableClick = getDisableClick(state.upcomingEventData)
    })
    builder.addCase(getUpcomingEvents.rejected, (state, action) => {
      state.isLoadingUpcomingEvent = action.payload.isLoading
    })
    builder.addCase(getRelateNews.pending, (state) => {
      state.isLoadingNews = true
    })
    builder.addCase(getRelateNews.fulfilled, (state, action) => {
      state.isLoadingNews = false
      state.newsData = handleNewsData(action.payload.data || [])
      state.totalPage = action.payload.totalPage
    })
    builder.addCase(getRelateNews.rejected, (state, action) => {
      state.isLoadingNews = action.payload.isLoading
    })
    builder.addCase(getEventsDate.fulfilled, (state, action) => {
      state.dateHasEvents = getDateHasEvent(action.payload)
    })
  },
})

export const selectLoadingUpcomingEvent = (state) =>
  state[slice.name].isLoadingUpcomingEvent
export const selectUpcomingEventIds = (state) =>
  state[slice.name].idsUpcomingEvent
export const selectUpcomingEventValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueByIdUpcomingEvent[id], attr)
export const selectItemData = (id) => (state) =>
  state[slice.name].valueByIdUpcomingEvent[id]
export const selectLoadingNews = (state) => state[slice.name].isLoadingNews
export const selectNewsIds = (state) => state[slice.name].idsNews
export const selectNewsValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueByIdNews[id], attr)
}
export const selectStartDate = (state) => state[slice.name].startDate
export const selectEndDate = (state) => state[slice.name].endDate
export const getTab = (state) => state[slice.name].tab
export const getEventTypes = (state) => state[slice.name].eventTypes
export const getEventTypesLoading = (state) =>
  state[slice.name].eventTypesLoading
export const getChecked = (state) => state[slice.name].checked
export const getUpcomingStartMonth = (state) =>
  state[slice.name].upcomingStartMonth
export const getUpcomingEventData = (state) =>
  state[slice.name].upcomingEventData
export const getIdsWithParentId = (state) => state[slice.name].idsWithParentId
export const selectDateHasEvents = (state) => state[slice.name].dateHasEvents
export const selectEventIds = (state) => state[slice.name].eventIds
export const getNewsData = (state) => state[slice.name].newsData
export const getPage = (state) => state[slice.name].page
export const getTotalPage = (state) => state[slice.name].totalPage
export const getMultipleActiveRows = (state) =>
  state[slice.name].multipleActiveRowsIds
export const selectDisableClick = (state) => state[slice.name].disableClick

export const {
  changeSelectedDate,
  handleTab,
  handleCheckboxChecked,
  resetDate,
  handleUpcomingStartMonth,
  sort,
  handleStartDate,
  handleEndDate,
  handleEventIds,
  handlePage,
  changeActiveRow,
  handleActiveRowId,
} = slice.actions

register(slice.name, slice.reducer)
