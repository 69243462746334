import { useSelector } from 'react-redux'
import useGetFund from '../../../common/masterData/useGetFund'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { formatNumber } from '../../../utils/Common'
import { Content } from './Content'
import { TABLE_TABS, VALUE_TYPES } from './constants'
import { getParams } from './helpers'
import {
  selectFilters,
  selectFundManagement,
  selectTableTab,
  selectValueType,
} from './store/slice'
import {
  downloadAssetAllocation,
  downloadFundIndex,
  downloadKeyMetric,
  downloadPerformance,
  downloadSectorAllocation,
  downloadStatisticsFundFlow,
} from './store/thunk'

const panelKey = 'STATISTIC_TABLE'
const panelRefs = {}

const PanelStatistic = ({ sizes, setSizes }) => {
  const titlePanel = `fund.fundCenter.statistic.TABLE`
  const { funds } = useGetFund()

  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const valueType = useSelector(selectValueType)
  const fundManagements = useSelector(selectFundManagement)

  const handleDownload = () => {
    const params = getParams({
      filters,
      tableTab,
      valueType,
      isDownload: true,
      funds,
      fundManagements,
    })
    switch (tableTab) {
      case TABLE_TABS.KEY_METRICS:
        return downloadKeyMetric(params)
      case TABLE_TABS.PERFORMANCE:
        return downloadPerformance(params)
      case TABLE_TABS.FUND_FLOW:
        return downloadStatisticsFundFlow(params)
      case TABLE_TABS.SECTOR_ALLOCATION:
        return downloadSectorAllocation({
          ...params,
          IsPercent: valueType === VALUE_TYPES.RELATIVE,
        })
      case TABLE_TABS.ASSET_ALLOCATION:
        return downloadAssetAllocation({
          ...params,
          IsRelative: valueType === VALUE_TYPES.RELATIVE,
        })
      case TABLE_TABS.FUND_INDEX:
        return downloadFundIndex(params)
      default:
        return
    }
  }

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadCsv
      downloadCsv={handleDownload}
    >
      <Content
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default withWrapper(
  PANEL_LAYOUTS[1](panelKey),
  panelRefs,
)(PanelStatistic)
