import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../common/html/Span'
import { formatVal, valToPercent } from '../../../utils/Value'
import { selectDataTable } from '../cpiAndInflationTable/store/slice'
import style from '../style.module.css'
import { COLOR_CHART, MONTHS_LIST } from './constants'
import { selectCPIId, selectYearArr } from './store/slice'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 12,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  getYearKeyFromRange,
}) => {
  const cpiId = useSelector(selectCPIId)
  const getYearKeyInData = useSelector(selectYearArr)
  const dataCPISector = useSelector(selectDataTable)
  const locale = useSelector((state) => state.i18n.locale)

  const getMonthKeyXAxisbyLocale = (month, locale) => {
    if (locale === 'en') {
      return MONTHS_LIST?.find(
        (item) => MONTHS_LIST?.indexOf(item) === month - 1,
      )
    } else {
      return month < 10 ? `Th0${month}` : `Th${month}`
    }
  }

  const getCPINameById = (id) => {
    return dataCPISector?.find((item) => item.cpivnTypeId === id)?.cpivnTypeName
  }

  const growthYearlyTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{getCPINameById(cpiId)}</span>
        </div>
        <table>
          <tbody>
            {getYearKeyInData.map((item) => (
              <tr key={item} className={style.trTable}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {getMonthKeyXAxisbyLocale(valueTooltip.time, locale)}-
                      {item}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{
                      fontSize: 11,
                      color: valueTooltip[item] < 0 ? '#ff4752' : '',
                    }}
                  >
                    {valToPercent(valueTooltip[item])}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            tickFormatter={(val) => getMonthKeyXAxisbyLocale(val, locale)}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                tickFormatter: (val) =>
                  formatVal(valToPercent(val, false, true)),
                unitYAxis: '%',
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              growthYearlyTooltip(payload, decimalLengths)
            }
          >
            {getYearKeyFromRange.map((item, index) => (
              <Line
                key={item}
                yAxisId="left"
                dataKey={item}
                type="linear"
                stroke={COLOR_CHART[index]}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={1.5}
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
