import FundPortfolioProxy from '../../proxies/fund/FundPortfolioProxy'
import { ServiceBase } from '../ServiceBase'

export class FundPortfolioService extends ServiceBase {
  getAssetAllocation(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getAssetAllocation',
      params,
    )(() => FundPortfolioProxy.getAssetAllocation(params))
  }

  getSectorAllocation(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getSectorAllocation',
      params,
    )(() => FundPortfolioProxy.getSectorAllocation(params))
  }

  getStockAllocation(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getStockAllocation',
      params,
    )(() => FundPortfolioProxy.getStockAllocation(params))
  }

  getAssetAllocationOverTime(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getAssetAllocationOverTime',
      params,
    )(() => FundPortfolioProxy.getAssetAllocationOverTime(params))
  }

  getSectorAllocationOverTime(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getSectorAllocationOverTime',
      params,
    )(() => FundPortfolioProxy.getSectorAllocationOverTime(params))
  }

  getStockCurrentSecurityHoldings(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getStockCurrentSecurityHoldings',
      params,
    )(() => FundPortfolioProxy.getStockCurrentSecurityHoldings(params))
  }

  getStockSecurityHoldingsHistory(params) {
    return this.applyMemoryCache(
      'FundPortfolioProxy.getStockSecurityHoldingsHistory',
      params,
    )(() => FundPortfolioProxy.getStockSecurityHoldingsHistory(params))
  }

  downloadStockCurrentSecurityHoldings(params) {
    return this.getFileDownload(() =>
      FundPortfolioProxy.downloadStockCurrentSecurityHoldings(params),
    )
  }

  downloadStockSecurityHoldingsHistory(params) {
    return this.getFileDownload(() =>
      FundPortfolioProxy.downloadStockSecurityHoldingsHistory(params),
    )
  }
}
export default new FundPortfolioService()
