import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { valToPercent } from '../../../../../utils/Value'
import {
  selectListParents,
  selectMaxYear,
} from '../importStatisticTable/store/slice'
import style from '../index.module.css'
import { ItemSearch } from '../ItemSearch'
import { YEARS_FILTER } from './constants'
import { formatChartData } from './helper'
import {
  changeCurrentYear,
  selectActiveItemProportionChart,
  selectCurrentYear,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { getImportContributionByMarket } from './store/thunk'

export const ImportProportion = ({ width, height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const activeItem = useSelector(selectActiveItemProportionChart)
  const currentYear = useSelector(selectCurrentYear)
  const listItems = useSelector(selectListParents)
  const maxYear = useSelector(selectMaxYear)
  const locale = useSelector((state) => state.i18n.locale)

  const toolTipChartContent = (data) => {
    return (
      <div className={style.chartTooltip}>
        <ul>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 10 }}>
                  {item.isI18n ? <Translate value={item.text} /> : item.text}:
                </Span>
                <Span
                  style={{ fontSize: 10 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }
  const formattedData = formatChartData(dataChart, 'value', 'name')

  const getActiveItemId = () => {
    if (activeItem) {
      return activeItem?.locationId
    }
  }
  const handleYearChange = (item) => {
    dispatch(changeCurrentYear(item))
  }

  useEffect(() => {
    if (listItems?.length) {
      dispatch(
        getImportContributionByMarket({
          Year: currentYear ?? maxYear,
          LocationId: getActiveItemId() ?? listItems[0]?.locationId,
        }),
      )
    }
  }, [currentYear, maxYear, activeItem, listItems, locale])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <SizeTracker>
      {(size) => {
        return (
          <div className="h-100">
            <div className="row j-b">
              <div style={{ width: '200px' }}>
                <ItemSearch />
              </div>
              <div
                className={`${style.inputDropdown}`}
                style={{ width: '100px' }}
              >
                <SelectCustom
                  value={currentYear ?? maxYear}
                  isI18n={false}
                  selectData={YEARS_FILTER}
                  handleChange={handleYearChange}
                />
              </div>
            </div>
            {size.height && (
              <div
                id="importProportionChart"
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                {formattedData.length ? (
                  <PieChartWithFooter
                    data={formattedData}
                    height={height - size.height}
                    width={width}
                    pieChartProps={{ radius: 30 }}
                    renderTooltip={() => toolTipChartContent(formattedData)}
                    footerRight
                    footerProps={{
                      isOneColumn: true,
                      appendStyle: { gridGap: 8 },
                    }}
                    customStyleChartDiv={{ minWidth: '50%' }}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}
