import PropTypes from 'prop-types'
import style from './index.module.css'

const VerticalLine = ({ ml, mr, h, styleProps }) => {
  return (
    <div
      className={style.root}
      style={{
        marginLeft: `${ml}px`,
        marginRight: `${mr}px`,
        height: `${h}px`,
        ...styleProps,
      }}
    />
  )
}

VerticalLine.propTypes = {
  ml: PropTypes.number,
  mr: PropTypes.number,
  h: PropTypes.number,
  styleProps: PropTypes.object,
}

VerticalLine.defaultProps = {
  ml: 28,
  mr: 28,
  h: 27,
}

export default VerticalLine
