import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { DetailContainer } from '../../../../common/news/DetailContainer'
import { Separator } from '../../../../common/separator'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import EventEmitter, { CHANGE_LOCALE } from '../../../../utils/EventEmitter'
import { LeftContent } from './left'
import { RightContent } from './right'

export const Content = ({ width }) => {
  const navigate = useCustomNavigate()
  const sectorInfo = useSelector(selectSectorInfo)

  useEffect(() => {
    const redirect = () => {
      navigate(
        FEATURES.sector.components['sector-constituents'].components[
          'news-report'
        ].route,
        sectorInfo,
      )
    }
    EventEmitter.subscribe(CHANGE_LOCALE, redirect)
    return () => EventEmitter.unsubscribe(CHANGE_LOCALE, redirect)
  }, [])

  return (
    <DetailContainer width={width}>
      <LeftContent />
      <Separator />
      <RightContent />
    </DetailContainer>
  )
}

Content.propTypes = {
  width: PropTypes.number,
}
