import { createSlice, current } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { listSelectIndicator, SEARCH_TIME_FRAME } from '../../constants'
import { getCompareValuation, getValuation } from './thunk'

const initialState = {
  isShow: false,
  loading: true,
  filter: {
    stateIndicator: listSelectIndicator[0].value,
    dateFrom: '',
    dateTo: '',
    timeFrameTab: SEARCH_TIME_FRAME['3M'],
  },
  listCompareChecked: [],
  dataValuationChart: [],
  lineActive: null,
  limitDatepicker: {
    minDate: '',
    minYear: '',
    maxDate: '',
    maxYear: '',
  },
}

export const slice = createSlice({
  name: 'market/marketInDepth/valuation',
  initialState,
  reducers: {
    setIsShow: (state, action) => {
      state.isShow = action.payload
    },
    changeStateIndicator: (state, action) => {
      state.filter.stateIndicator = action.payload
    },
    changeDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload
    },
    changeDateTo: (state, action) => {
      state.filter.dateTo = action.payload
    },
    changeTimeFrameChart: (state, action) => {
      state.filter.timeFrameTab = action.payload
    },
    setListCompareSearch: (state, action) => {
      state.listCompareChecked = action.payload
    },
    removeCompareSearch: (state, action) => {
      state.listCompareChecked = state.listCompareChecked.filter(
        (item) => item.id !== action.payload.id,
      )
    },
    changeLineActive: (state, action) => {
      state.lineActive = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getValuation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValuation.fulfilled, (state, action) => {
      state.loading = false
      state.dataValuationChart = action.payload
      const value = action.payload[0]
      state.limitDatepicker = {
        minDate: new Date(value?.minDate),
        minYear: new Date(value?.minDate).getFullYear(),
        maxDate: new Date(value?.maxDate),
        maxYear: new Date(value?.maxDate).getFullYear(),
      }
    })
    builder.addCase(getValuation.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(getCompareValuation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCompareValuation.fulfilled, (state, action) => {
      state.loading = false
      const currentData = [...current(state.dataValuationChart)]
      const groupData = { ...action.payload, valuation: currentData }

      const result = []
      while (isLoop(groupData)) {
        const min = getMinData(groupData, 'tradingDateId')
        let item = {}
        Object.keys(groupData).forEach((key) => {
          const data = groupData[key]
          if (data[0] && data[0].tradingDateId === min) {
            if (key === 'valuation') {
              item = { ...item, ...data[0] }
            } else {
              item[key + '_pb'] = data[0].pb
              item[key + '_pe'] = data[0].pe
              item.tradingDateId = data[0].tradingDateId
            }
            data.shift()
          }
        })
        result.push(item)
      }
      state.dataValuationChart = result
    })
    builder.addCase(getCompareValuation.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectIsShow = (state) => state[slice.name].isShow
export const selectFilter = (state) => state[slice.name].filter

export const selectListCompareSearch = (state) =>
  state[slice.name].listCompareChecked
export const selectValuationChart = (state) =>
  state[slice.name].dataValuationChart
export const selectLoading = (state) => state[slice.name].loading
export const selectLineActive = (state) => state[slice.name].lineActive
export const selectLimitDatepicker = (state) =>
  state[slice.name].limitDatepicker

export const {
  setIsShow,
  changeStateIndicator,
  changeDateFrom,
  changeDateTo,
  changeTimeFrameChart,
  setListCompareSearch,
  removeCompareSearch,
  mapCompareColor,
  changeLineActive,
} = slice.actions

register(slice.name, slice.reducer)

const getMinData = (data, keyMin) => {
  const sortData = Object.values(data)
    .map((value) => (value[0] ? value[0][keyMin] : undefined))
    .sort()
  return sortData[0]
}

const isLoop = (data) => {
  return !Object.keys(data).every((key) => data[key].length === 0)
}
