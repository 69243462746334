import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import TableHeader from './TableHeader'
import { valueTimefrequency } from './constants'
import styles from './index.module.css'
import {
  selectDataCirculateTopRelease,
  selectFinancialValue,
  selectGroupColumns,
  selectIds,
  selectIdsCirculate,
  selectLoading,
  selectTimeFilter,
  selectTopCirculateValue,
  sortData,
  sortDataCirculate,
} from './store/slice'

const TopIssuerTable = ({ width, height }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const dispatch = useDispatch()

  const navigate = useCustomNavigate()

  const ids = useSelector(selectIds)
  const idsCirculate = useSelector(selectIdsCirculate)
  const isLoading = useSelector(selectLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const timeFilter = useSelector(selectTimeFilter)
  const data = useSelector(selectDataCirculateTopRelease)

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  if (isLoading) {
    return <Loading />
  }

  if (timeFilter === valueTimefrequency.ISSUE && ids.length === 0) {
    return <NoData />
  }
  if (
    timeFilter === valueTimefrequency.OUTSTANDING &&
    idsCirculate.length === 0
  ) {
    return <NoData />
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  const renderTable = () => {
    return (
      <Table
        ids={timeFilter === valueTimefrequency.ISSUE ? ids : idsCirculate}
        columnDraggable={false}
        sort={
          timeFilter === valueTimefrequency.ISSUE ? sortData : sortDataCirculate
        }
        handleSortField={(e) =>
          timeFilter === valueTimefrequency.ISSUE
            ? dispatch(
                sortData({
                  ...e,
                  locale,
                }),
              )
            : dispatch(
                sortDataCirculate({
                  ...e,
                  locale,
                }),
              )
        }
        getDataFromRedux={
          timeFilter === valueTimefrequency.ISSUE
            ? selectFinancialValue
            : selectTopCirculateValue
        }
        isLoading={isLoading}
        rowDraggable={false}
        // changeIds={changeIds}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(groupColumns, stateSort, sortColumn)
        }
        schema={groupColumns.map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key
          const result = {
            colId,
            title,
          }
          switch (index) {
            case 0:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <Span style={{ ...item?.attrs?.style }}>{value}</Span>
                    </td>
                  )
                },
              }
            case 1:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <div
                        style={{
                          ...item?.attrs?.style,
                          width: `${(25 / 100) * width}`,
                        }}
                      >
                        <Span
                          className={styles.link}
                          onClick={() =>
                            redirectToBondIssuer(
                              timeFilter === valueTimefrequency.ISSUE
                                ? rowId
                                : data?.[rowId]?.organizationId,
                            )
                          }
                        >
                          <TextEllipsis text={`${value}`} isI18n={false} />
                        </Span>
                      </div>
                    </td>
                  )
                },
              }
            case 2:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <div style={{ ...item?.attrs?.style }}>
                        <Span style={{ ...item?.attrs?.style }}>
                          <TextEllipsis text={`${value}`} isI18n={false} />
                        </Span>
                      </div>
                    </td>
                  )
                },
              }
            case 3:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(valDivBillion(value))}
                      </Span>
                    </td>
                  )
                },
              }
            case 4:
              return {
                ...result,
                tdStyle: {},
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(value)}
                      </Span>
                    </td>
                  )
                },
              }
            case 5:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td style={{ ...item?.attrs?.style }}>
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(value * 100)}
                      </Span>
                    </td>
                  )
                },
              }

            default:
              return {}
          }
        })}
        stickyFirstColumn={false}
        isPagination={false}
        hasFooter={false}
        // changeActiveRow={changeIndicator}
        // defaultActiveRowId={defaultActiveRowId}
        resizable={false}
        // defaultScrollToRight
      />
    )
  }

  return renderTable()
}

export default TopIssuerTable
