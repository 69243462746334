import { createAsyncThunk } from '@reduxjs/toolkit'
import FisheryService from '../../../../../../../core/services/sector/sectorSpecific/FisheryService'
import { handleExport } from '../../../../../../utils/Export'

export const getImportStatisticsByProduct = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByProduct/getImportStatisticsByProduct',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getImportStatisticsByProduct(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getImportDownloadStatisticsByProduct = createAsyncThunk(
  'sector/sectorSpecific/fishery/importByProduct/getImportDownloadStatisticsByProduct',
  async (data) => {
    const response = await FisheryService.getImportDownloadStatisticsByProduct(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const getMaxDate = createAsyncThunk(
  'sector/sectorSpecific/fishery/exporter/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await FisheryService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
