import { sumBy } from 'lodash'
import moment from 'moment'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { invertKeyBy } from '../../../utils/Common'
import {
  FORMAT,
  compareTwoDateTime,
  getCurrentDateTime,
} from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import { selectData, selectQty } from './store/slice'

const Bottom = () => {
  const data = useSelector(selectData)
  const locale = useSelector((state) => state.i18n.locale)
  const qty = useSelector(selectQty)

  const total = useMemo(() => {
    const listData = invertKeyBy(data)
    const currentDate = getCurrentDateTime(FORMAT.DATE)
    const dataCheckDate = listData.filter(
      (e) =>
        compareTwoDateTime(
          moment(e.paymentDate, FORMAT.DATE[locale]),
          currentDate,
        ) === 1,
    )
    const totalIssueValue = sumBy(dataCheckDate, (item) =>
      parseFloat(item.interestAndPrincipal),
    )
    return totalIssueValue
  }, [data])

  return (
    <>
      <table className="w-100">
        <tbody>
          <tr>
            <td colSpan="2" style={{ width: '50%' }}>
              <div
                className="h-100"
                style={{
                  borderTop: '1px solid #546373',
                  padding: '8px',
                  boxSizing: 'border-box',
                }}
              >
                <Span>
                  <Translate
                    value={'bond.valuation.history.table.total'}
                    style={{
                      fontSize: 12,
                      fontWeight: 700,
                      fontStyle: 'italic',
                    }}
                  />
                </Span>
              </div>
            </td>
            <td colSpan="1" style={{ width: '25%' }}>
              <div
                style={{
                  borderTop: '1px solid #546373',
                  padding: '8px',
                  textAlign: 'right',
                  boxSizing: 'border-box',
                }}
                className="h-100"
              >
                <Span
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    fontStyle: 'italic',
                  }}
                >
                  {total ? formatVal(total * qty, 0) : 0}
                </Span>
              </div>
            </td>
            <td colSpan="1" style={{ width: '25%' }}>
              {' '}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  )
}

export default Bottom
