export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'All',
  HOSE: 'VNIndex',
  HNX: 'HNXIndex',
  UPCOM: 'UPCOMIndex',
  OTC: 'OTC',
  PRIVATE: 'Private',
}

export const TABLE_TYPE = {
  VALUATION: 'VALUATION',
  PROFITABILITY: 'PROFITABILITY',
  CREDIT: 'CREDIT',
  PERFORMANCE: 'PERFORMANCE',
  OWNERSHIP: 'OWNERSHIP',
  BALANCE_SHEET: 'BALANCE_SHEET',
  INCOME_STATEMENT: 'INCOME_STATEMENT',
}

export const MEAN_ROW_ID = 'MEAN'
export const NAME_COL_ID = 'name'

export const PERFORMANCE_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  marketCap: 'marketCap',
  lastPrice: 'closePrice',
  percent1DPriceChange: 'percentPriceChange',
  percent1WPriceChange: 'totalPercentChange1WeekClosePriceAdjusted',
  percent1MPriceChange: 'totalPercentChange1MonthClosePriceAdjusted',
  percent3MPriceChange: 'totalPercentChange3MonthClosePriceAdjusted',
  percent6MPriceChange: 'totalPercentChange6MonthClosePriceAdjusted',
  percent9MPriceChange: 'totalPercentChange9MonthClosePriceAdjusted',
  percent1YPriceChange: 'totalPercentChange1YearClosePriceAdjusted',
  percentYTDPriceChange: 'totalPercentChangeYTDClosePriceAdjusted',
}

export const VALUATION_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  marketCap: 'rtD0005',
  ev: 'rtD0006',
  evEbitda: 'rtD0027',
  eps: 'rtD0011',
  pE: 'rtD0022',
  pS: 'rtD0025',
  bvps: 'rtD0007',
  pB: 'rtD0019',
  dividendYield: 'rtD0045',
}

export const OWNERSHIP_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  marketCap: 'rtD0005',
  stateHolding: 'statePercentage',
  foreignHolding: 'foreignCurrentPercent',
  otherHolding: 'otherHoldingPercent',
  foreignRemainRoom: 'foreignRemainRoom',
  maximumForeignHolding: 'maximumForeignPercent',
  managementAffiliateHolding: 'mah',
  institutionHolding: 'ih',
}

export const CREDIT_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  netDebtEquity: 'rT0041',
  longDebtEquity: 'rT0025',
  debtEquity: 'rT0027',
  shortDebtEquity: 'rT0023',
  liabilitiesEquity: 'rT0034',
  ebitdaInterestCorverage: 'rT0046',
  financialLeverage: 'rT0035',
  fiinRating: 'fiinRating',
  sPRating: 'sPRating',
  moodyRating: 'moodyRating',
  fitchRanking: 'fitchRanking',
}

// Credit for BANK
export const ASSET_QUALITY_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  equityLoans: 'el',
  liabilitiesEquity: 'rT0034',
  loanDepositRatio: 'rT0277',
  nonperformingLoanRatio: 'rT0236',
  loanLossReservesNPLs: 'rT0237',
  loanLossReservesLoans: 'rT0238',
  provisionOutstandingLoans: 'rT0239',
  fiinRating: 'fiinRating',
  sPRating: 'sPRating',
  moodyRating: 'moodyRating',
  fitchRanking: 'fitchRanking',
}

export const PROFITABILITY_KEYS = {
  delete: 'delete',
  ticker: 'ticker',
  companyName: 'organizationShortName',
  grossMargin: 'rT0050',
  ebitdaMargin: 'rT0051',
  nim: 'rT0285',
  pretaxProfitMargin: 'rT0053',
  netMargin: 'rT0054',
  roa: 'rT0059',
  roe: 'rT0060',
  roic: 'rT0064',
}

export const QUARTERS = [
  {
    name: 'corporate.peerValuation.overview.QUARTER_1',
    value: 1,
  },
  {
    name: 'corporate.peerValuation.overview.QUARTER_2',
    value: 2,
  },
  {
    name: 'corporate.peerValuation.overview.QUARTER_3',
    value: 3,
  },
  {
    name: 'corporate.peerValuation.overview.QUARTER_4',
    value: 4,
  },
  {
    name: 'corporate.peerValuation.overview.ANNUAL',
    value: 5,
  },
]

export const LASTEST_YEAR = [...Array(20).keys()].map((v) => {
  const year = new Date().getFullYear()
  return {
    name: String(year - v),
    value: String(year - v),
  }
})

export const UNIT_VALUE_CONSTANTS = {
  VND: 'VND',
  MILLION_VND: 'millionVND',
  BILLION_VND: 'billionVND',
  USD: 'USD',
  THOUSAND_USD: 'thousandUSD',
  MILLION_USD: 'millionUSD',
}

export const CURRENCY = [
  {
    name: 'corporate.peerValuation.overview.VND',
    value: UNIT_VALUE_CONSTANTS.VND,
  },
  {
    name: 'corporate.peerValuation.overview.USD',
    value: UNIT_VALUE_CONSTANTS.USD,
  },
]

export const UNIT = {
  [UNIT_VALUE_CONSTANTS.VND]: [
    {
      name: 'corporate.peerValuation.overview.MILLION_VND',
      value: UNIT_VALUE_CONSTANTS.MILLION_VND,
    },
    {
      name: 'corporate.peerValuation.overview.BILLION_VND',
      value: UNIT_VALUE_CONSTANTS.BILLION_VND,
    },
  ],
  [UNIT_VALUE_CONSTANTS.USD]: [
    {
      name: 'corporate.peerValuation.overview.THOUSAND_USD',
      value: UNIT_VALUE_CONSTANTS.THOUSAND_USD,
    },
    {
      name: 'corporate.peerValuation.overview.MILLION_USD',
      value: UNIT_VALUE_CONSTANTS.MILLION_USD,
    },
  ],
}

export const FORMAT_DATA_BY_UNIT = {
  [UNIT_VALUE_CONSTANTS.VND]: 1,
  [UNIT_VALUE_CONSTANTS.MILLION_VND]: 10 ** 6,
  [UNIT_VALUE_CONSTANTS.BILLION_VND]: 10 ** 9,
  [UNIT_VALUE_CONSTANTS.USD]: 1,
  [UNIT_VALUE_CONSTANTS.THOUSAND_USD]: 10 ** 3,
  [UNIT_VALUE_CONSTANTS.MILLION_USD]: 10 ** 6,
}

export const INDICATOR_FIX_UNIT_INT = ['IS23', 'IS24']
