import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class InterestRate extends ProxyBase {
  getMaxDate(params) {
    return this.get('MaxDate', params, undefined, true)
  }

  getStatisticStateBank(params) {
    return this.get('StatisticStateBank', params)
  }

  getStatisticStateBankName(params) {
    return this.get('StatisticStateBankName', params)
  }

  downloadStatisticStateBank(params) {
    return this.get('DownloadStatisticStateBank', params, 'download')
  }

  getMovementDepositAndLending(params) {
    return this.get('MovementDepositAndLending', params)
  }

  getStatisticOtherBank(data) {
    return this.get('StatisticOtherBank', data)
  }

  downloadStatisticOtherBank(params) {
    return this.get('DownloadStatisticOtherBank', params, 'download')
  }

  getCompareOtherBank(params) {
    return this.get('CompareOtherBank', params)
  }
}

export default new InterestRate(
  ServiceProxyConfig.Economy.InterestRate.ServiceUrl,
)
