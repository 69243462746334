import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import { handleDataInitCondition } from '../helper'
import style from '../index.module.css'
import {
  changeCondition,
  selectItemCondition,
  selectItemConditionByIds,
  selectTypeInterims,
} from '../store/slice'

const Type = ({ setValueDataType, indicatorId, index, indicatorGroupId }) => {
  const dispatch = useDispatch()

  const indicatorTypeId = useSelector(
    selectItemCondition(`${indicatorId}.${index}.indicatorTypeId`),
  )
  const indicator = useSelector(selectItemConditionByIds(indicatorId))
  const dataTypes = useSelector(selectTypeInterims(`${indicatorGroupId}`))

  const handleChangeType = (value) => {
    const dataTypeIndex = dataTypes.findIndex(
      (item) => item.indicatorTypeId === value,
    )
    dispatch(
      changeCondition({
        key: `${indicatorId}.${index}`,
        value: handleDataInitCondition({
          ...indicator,
          ...dataTypes[dataTypeIndex],
        }),
      }),
    )
  }

  useEffect(() => {
    const dataTypeIndex = dataTypes.findIndex(
      (item) => item.indicatorTypeId === indicatorTypeId,
    )
    setValueDataType(dataTypeIndex)
  }, [indicatorTypeId])

  const selectData = dataTypes.map((item) => {
    return { value: item.indicatorTypeId, name: item.dataTypeName }
  })

  const disableSelect = selectData.length <= 1

  return (
    <div className={`input-dropdown ${style.w48} ${style.mr4}`}>
      <SelectCustom
        value={indicatorTypeId}
        selectData={selectData}
        handleChange={handleChangeType}
        isShowArrow={!disableSelect}
        isDisable={disableSelect}
      />
    </div>
  )
}

Type.propTypes = {
  setValueDataType: PropTypes.func.isRequired,
  indicatorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  index: PropTypes.number.isRequired,
  indicatorGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
}

export default Type
