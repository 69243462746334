import moment from 'moment'
import { Translate } from 'react-redux-i18n'
import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../common/chart/basicChart/constants'
import {
  AXIS_LABEL_POSITION,
  CHART_TYPES,
} from '../../../../common/chart/constants'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  FUND_KEY,
  INDEX_BY_FIELD,
  TICKER_OPTIONS,
  VALUE_TYPES,
  X_AXIS_KEY,
} from './constants'

export const formatDataApi = ({ data, locale }) => {
  if (!data.length) return []

  const sortData = [
    ...data.sort(
      (a, b) => moment(a.endDateId).unix() - moment(b.endDateId).unix(),
    ),
  ]
  const firstItem = sortData[0]
  const otherItems = sortData.slice(1)

  return otherItems.map((item) => {
    const formatItem = Object.keys(item).reduce((obj, key) => {
      if (key === X_AXIS_KEY) {
        return {
          ...obj,
          [DEFAULT_XAXIS_DATA_KEY]: formatDateTime(
            item[key],
            FORMAT.DATE,
            locale,
          ),
          endDateId: item[key],
        }
      } else {
        return {
          ...obj,
          [`${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[key]}_${VALUE_TYPES.ABSOLUTE}`]:
            item[key],
          [`${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[key]}_${VALUE_TYPES.RELATIVE}`]:
            (item[key] - firstItem[key]) / firstItem[key],
        }
      }
    }, {})

    return formatItem
  })
}

export const getSchema = ({ fundInfo, filters }) => {
  const schemas = [
    {
      yAxisId: 'navCcq',
      text: fundInfo.organizationShortName.replaceAll('.', ''),
      dataKey: `${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[FUND_KEY]}_${filters.valueType}`,
      unit: filters.valueType === VALUE_TYPES.ABSOLUTE ? 'common.UNIT.VND' : '',
      isI18n: true,
      chart: {
        type: CHART_TYPES.LINE,
      },
      tooltip: {
        formatValue: (val) =>
          filters.valueType === VALUE_TYPES.ABSOLUTE
            ? formatVal(val)
            : valToPercent(val),
      },
    },
  ]

  if (filters.ticker !== TICKER_OPTIONS.NO_COMPARISON) {
    schemas.push({
      yAxisId:
        filters.valueType === VALUE_TYPES.ABSOLUTE ? 'indexPoint' : 'navCcq',
      text: `fund.fundProfile.navData.navCertificate.${Object.keys(
        TICKER_OPTIONS,
      ).find((key) => TICKER_OPTIONS[key] === filters.ticker)}`,
      dataKey: `${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[filters.ticker]}_${
        filters.valueType
      }`,
      unit:
        filters.valueType === VALUE_TYPES.ABSOLUTE
          ? 'fund.fundProfile.navData.navCertificate.UNIT_POINT'
          : '',
      isI18n: true,
      chart: {
        type: CHART_TYPES.LINE,
      },
      tooltip: {
        renderTitle: () => (
          <Translate
            value={`fund.fundProfile.navData.navCertificate.${Object.keys(
              TICKER_OPTIONS,
            ).find((key) => TICKER_OPTIONS[key] === filters.ticker)}`}
          />
        ),
        formatValue: (val) =>
          filters.valueType === VALUE_TYPES.ABSOLUTE
            ? formatVal(val)
            : valToPercent(val),
      },
    })
  }

  return schemas
}

export const getYAxis = ({ filters, yAxisText, yAxisIndexText }) => {
  const yAxis = [
    {
      yAxisId: 'navCcq',
      dataKeys: [
        `${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[FUND_KEY]}_${filters.valueType}`,
      ],
      labelText: filters.valueType === VALUE_TYPES.ABSOLUTE ? yAxisText : '',
      isLineChart: true,
      tickFormatter:
        filters.valueType === VALUE_TYPES.RELATIVE
          ? (value) => valToPercent(value, false, false, 0)
          : undefined,
    },
  ]

  if (filters.ticker !== TICKER_OPTIONS.NO_COMPARISON) {
    if (filters.valueType === VALUE_TYPES.ABSOLUTE) {
      yAxis.push({
        yAxisId: 'indexPoint',
        dataKeys: [
          `${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[filters.ticker]}_${
            filters.valueType
          }`,
        ],
        orientation: 'right',
        labelText:
          filters.valueType === VALUE_TYPES.ABSOLUTE ? yAxisIndexText : '',
        labelPosition: AXIS_LABEL_POSITION.RIGHT,
        isLineChart: true,
        tickFormatter:
          filters.valueType === VALUE_TYPES.RELATIVE
            ? (value) => valToPercent(value, false, false, 0)
            : undefined,
      })
    } else {
      yAxis[0].dataKeys.push(
        `${DEFAULT_PRE_VALUE_KEY}${INDEX_BY_FIELD[filters.ticker]}_${
          filters.valueType
        }`,
      )
    }
  }

  return yAxis
}
