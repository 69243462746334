import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getSteelTypes } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/overviewSteel',
  initialState: {
    isLoadingSteelTypes: true,
    steelTypeIdsList: [],
    steelTypesList: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSteelTypes.pending, (state) => {
      state.isLoadingSteelTypes = true
      state.steelTypesList = []
      state.steelTypeIdsList = []
    })
    builder.addCase(getSteelTypes.fulfilled, (state, action) => {
      state.isLoadingSteelTypes = false
      const steelTypesList = [...action.payload].map((item) => ({
        ...item,
        name: item.steelTypeName,
        value: item.steelTypeId,
        children: {},
      }))
      state.steelTypesList = steelTypesList
      state.steelTypeIdsList = steelTypesList.map((item) => item.steelTypeId)
    })
    builder.addCase(getSteelTypes.rejected, (state) => {
      state.isLoadingSteelTypes = false
    })
  },
})

export const selectLoadingSteelTypes = (state) =>
  state[slice.name].isLoadingSteelTypes
export const selectSteelTypesList = (state) => state[slice.name].steelTypesList
export const selectSteelTypeIdsList = (state) =>
  state[slice.name].steelTypeIdsList

register(slice.name, slice.reducer)
