import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SizeTracker } from '../../../common/sizeTracker'
import { selectBasicInfoOverview } from '../store/slice'
import { FinancialMetricTab } from './FinancialMetricTab'
import FinancialMetricTable from './FinancialMetricTable'
import { selectTimeFilter } from './store/slice'
import { getFinancialMetricsDataArea } from './store/thunk'

export const FinancialMetric = ({ width }) => {
  const dispatch = useDispatch()

  const timeFilter = useSelector(selectTimeFilter)
  const basicInfo = useSelector(selectBasicInfoOverview)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getFinancialMetricsDataArea({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: timeFilter.value,
        }),
      )
    }
  }, [timeFilter, basicInfo.organizationId])

  return (
    <div className="h-100">
      <SizeTracker>
        {(size) => {
          return (
            <>
              <FinancialMetricTab />
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <FinancialMetricTable width={width} />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
