import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class PackageProxy extends ProxyBase {
  getPackageFunctions(params) {
    return this.get('functions', params)
  }

  getRequestRenewUpgradeAccount(params) {
    return this.get('RequestRenewUpgradeAccount', params)
  }
}

export default new PackageProxy(ServiceProxyConfig.Common.Package.ServiceUrl)
