import { createSlice } from '@reduxjs/toolkit'
import {
  funcSortAsc,
  funcSortDesc,
} from '../../../../../common/dataExplorer/helper'
import { SORT_TYPES } from '../../../../../common/dataExplorer/table/constant'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getBondTransactionData,
  getDataBondList,
  getDataBondListBySort,
  getDataBondListIsChange,
} from './thunk'

const initialSheet = {
  tickerById: {},
  tickerId: [],
  columnId: [],
  columnById: {},
  tickerDisplay: [],
  groupColumnById: {},
  defaultTickerId: [],
  newTickerId: [],
  newGroupId: [],
  isLoad: false,
  isLoadFiinXTemplate: false,
}

const initialState = {
  headers: [],
  loading: true,
  sheets: {
    1: initialSheet,
  },
  listSheet: [{ title: 'SHEET 1', value: 1 }],
  activeSheet: 1,
  indicator: [],
  indicatorById: {},
  detailPopupIndicator: null,
  shareIssueMethod: [],
  fiinXTemplateShareIssueMethod: [],
  actionType: [],
  stockById: {},
  templates: [],
  fiinXTemplates: [],
  mostRecent: {},
  detailFiinXTemplate: null,
  isLoadWorkSpace: false,
  listTicker: [],
  tickerFilter: null,
  totalData: 0,
  data: {},
  initialHeaders: [],
  isFirst: true,
}

export const slice = createSlice({
  name: 'bond/bondScreening/bondAggregator/table',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sort(state, action) {
      const activeSheet = state.sheets[state.activeSheet]

      const { idColumn, typeSort } = action.payload
      const tickerById = activeSheet.tickerById

      if (typeSort === SORT_TYPES.ASC) {
        state.sheets[1].tickerDisplay = state.sheets[1].tickerDisplay.sort(
          funcSortAsc(tickerById, idColumn),
        )
      }
      if (typeSort === SORT_TYPES.DESC) {
        state.sheets[1].tickerDisplay = state.sheets[1].tickerDisplay.sort(
          funcSortDesc(tickerById, idColumn),
        )
      }
      if (typeSort === SORT_TYPES.DEFAULT) {
        state.sheets[1].tickerDisplay = state.sheets[1].defaultTickerId
      }
    },
    swapTwoColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { column1, column2 } = action.payload
      const idColumn1 = activeSheet.columnId[column1]
      const idColumn2 = activeSheet.columnId[column2]
      activeSheet.columnId.splice(column1, 1)
      activeSheet.columnId.splice(
        activeSheet.columnId.indexOf(idColumn2),
        0,
        idColumn1,
      )
    },
    removeColumn(state, action) {
      const { formatDataTable, newDataTable } = action.payload

      state.headers = formatDataTable.headers
      state.sheets[1].columnId = formatDataTable.ids
      state.sheets[1].columnById = formatDataTable.columnById
      state.sheets[1].tickerDisplay = formatDataTable.tickerDisplay
      state.sheets[1].tickerId = formatDataTable.tickerDisplay
      state.sheets[1].defaultTickerId = formatDataTable.tickerDisplay
      state.sheets[1].tickerById = formatDataTable.tickerById
      state.data = newDataTable

      if (state.isFirst) {
        state.initialHeaders = formatDataTable.headers
        state.isFirst = false
      }
    },
    changeHeaders(state, action) {
      state.initialHeaders = action.payload
    },
    changeIsFirst(state, action) {
      state.isFirst = action.payload
    },
    changeLoadingTable(state, action) {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataBondList.rejected, (state, action) => {
      state.loading = action.payload
      state.data = []
      state.sheets = {
        1: initialSheet,
      }
      state.totalData = 0
      state.headers = []
      state.initialHeaders = []
    })
    builder.addCase(getDataBondList.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getDataBondList.fulfilled, (state, action) => {
      state.loading = false
      state.headers = action.payload.headers
      state.sheets[1].columnId = action.payload.ids
      state.sheets[1].columnById = action.payload.columnById
      state.sheets[1].tickerDisplay = action.payload.tickerDisplay
      state.sheets[1].tickerId = action.payload.tickerDisplay
      state.sheets[1].defaultTickerId = action.payload.tickerDisplay
      state.sheets[1].tickerById = action.payload.tickerById
      state.totalData = action.payload.total
      state.data = action.payload.data

      if (state.isFirst) {
        state.initialHeaders = action.payload.headers
        state.isFirst = false
      }
    })
    builder.addCase(getDataBondListBySort.rejected, (state, action) => {
      state.loading = action.payload
      state.data = []
      state.sheets = {
        1: initialSheet,
      }
      state.totalData = 0
      state.headers = []
      state.initialHeaders = []
    })
    builder.addCase(getDataBondListBySort.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getDataBondListBySort.fulfilled, (state, action) => {
      state.loading = false
      state.headers = action.payload.headers
      state.sheets[1].columnId = action.payload.ids
      state.sheets[1].columnById = action.payload.columnById
      state.sheets[1].tickerDisplay = action.payload.tickerDisplay
      state.sheets[1].tickerId = action.payload.tickerDisplay
      state.sheets[1].defaultTickerId = action.payload.tickerDisplay
      state.sheets[1].tickerById = action.payload.tickerById
      state.totalData = action.payload.total
      state.data = action.payload.data

      if (state.isFirst) {
        state.initialHeaders = action.payload.headers
        state.isFirst = false
      }
    })
    builder.addCase(getDataBondListIsChange.rejected, (state, action) => {
      state.loading = action.payload
      state.data = []
      state.sheets = {
        1: initialSheet,
      }
      state.totalData = 0
      state.headers = []
      state.initialHeaders = []
    })
    builder.addCase(getDataBondListIsChange.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getDataBondListIsChange.fulfilled, (state, action) => {
      state.loading = false
      state.headers = action.payload.headers
      state.sheets[1].columnId = action.payload.ids
      state.sheets[1].columnById = action.payload.columnById
      state.sheets[1].tickerDisplay = action.payload.tickerDisplay
      state.sheets[1].tickerId = action.payload.tickerDisplay
      state.sheets[1].defaultTickerId = action.payload.tickerDisplay
      state.sheets[1].tickerById = action.payload.tickerById
      state.totalData = action.payload.total
      state.data = action.payload.data

      if (state.isFirst) {
        state.initialHeaders = action.payload.headers
        state.isFirst = false
      }
    })
    builder.addCase(getBondTransactionData.rejected, (state, action) => {
      state.loading = action.payload
      state.data = []
      state.sheets = {
        1: initialSheet,
      }
      state.totalData = 0
      state.headers = []
      state.initialHeaders = []
    })
    builder.addCase(getBondTransactionData.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getBondTransactionData.fulfilled, (state, action) => {
      state.loading = false
      state.headers = action.payload.headers
      state.sheets[1].columnId = action.payload.ids
      state.sheets[1].columnById = action.payload.columnById
      state.sheets[1].tickerDisplay = action.payload.tickerDisplay
      state.sheets[1].tickerId = action.payload.tickerDisplay
      state.sheets[1].defaultTickerId = action.payload.tickerDisplay
      state.sheets[1].tickerById = action.payload.tickerById
      state.totalData = action.payload.total
      state.data = action.payload.data

      if (state.isFirst) {
        state.initialHeaders = action.payload.headers
        state.isFirst = false
      }
    })
  },
})
export const selectHeaders = (state) => state[slice.name].headers
export const selectLoading = (state) => state[slice.name].loading
export const selectTotal = (state) => state[slice.name].totalData

export const selectColumnId = (sheet) => (index) => (state) => {
  return state[slice.name].sheets?.[sheet]?.columnId?.[index]
}

export const selectColumnCell = (sheet) => (id) => (state) => {
  return state[slice.name].sheets?.[sheet]?.columnById?.[id]
}
export const selectTickerCell = (sheet) => (id, attr) => (state) => {
  return state[slice.name].sheets?.[sheet]?.tickerById[id]?.[attr]
}
export const selectTickerDisplay = (sheet) => (index) => (state) =>
  state[slice.name].sheets?.[sheet]?.tickerDisplay?.[index]

export const selectTickerId = (sheet) => (state) =>
  state[slice.name].sheets?.[sheet]?.tickerId

export const selectFullColumnId = (sheet) => (state) =>
  state[slice.name].sheets?.[sheet]?.columnId || []

export const selectFullTickerDisplay = (sheet) => (state) =>
  state[slice.name].sheets?.[sheet]?.tickerDisplay || []

export const selectActiveSheet = (state) => state[slice.name].activeSheet

export const selectStockById = (state) => state[slice.name].stockById

export const selectColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnById

export const selectIndicatorById = (state) => state[slice.name].indicatorById

export const selectListSheet = (state) => state[slice.name].listSheet
export const selectDataTable = (state) => state[slice.name].data
export const selectInitialHeaders = (state) => state[slice.name].initialHeaders

export const {
  resetStore,
  sort,
  swapTwoColumn,
  changeTickerDisplay,
  changeHeaders,
  removeColumn,
  changeIsFirst,
  changeLoadingTable,
} = slice.actions

register(slice.name, slice.reducer)
