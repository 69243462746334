import { Layout } from '../../common/layout'
import Menu from '../common/Menu'
import withWrapper from '../withWrapper'
import { Right } from './Right'

const NewChart = () => {
  return (
    <Layout>
      <div className="d-flex h-100">
        <Menu isCreateChart />
        <div className="flex-1">
          <Right />
        </div>
      </div>
    </Layout>
  )
}

export default withWrapper(NewChart)
