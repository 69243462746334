export const settingProfile = {
  MY_PROFILE: 'My Profile',
  NAME: 'Name',
  MAIL: 'Mail',
  PHONE: 'Phone',
  PASSWORD: 'Password',
  FAVORITE_CONTACT: 'Favourite contact',
  CHANGE_PASSWORD: 'Change password',
  EDIT_PROFILE: 'Edit profile',
  MY_SUBSCRIPTION: 'My Subcription',
  ACCOUNT_TYPE: 'Account type',
  EXPIRY_DATE: 'Expiry date',
  BUTTON_RENEW: 'Renew',
  BUTTON_UPGRADE: 'Upgrade',
  REQUEST_TO_CHANGE: 'Request to change',
  RECENT_PASSWORD: 'Recent password',
  ENTER_RECENT_PASSWORD: 'Enter recent password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  RE_ENTER_NEW_PASSWORD: 'Re-enter new password',
  BUTTON_CHANGE: 'Change',
  MESS_CHANGE_PASSWORD_SUCCEED: 'Your password has been changed successfully.',
  MESS_EDIT_INFO_USER_SUCCEED:
    'Your request was sent to our customer service and will be approved soon.',
  MESS_REQUEST_RENEWALS_UPGRADE_ACCOUNT:
    "Thank you for your request to upgrade/renew your FiinPro-X account. Your request has been recorded and forwarded to FiinGroup's Customer  Support Team. We will contact you as soon as possible to assist you in finishing upgrade/renewal process.",
}
