import { languageObjs } from '../../../../../../configs/Language'

export const AVERAGE_ID = 0
export const GROWTH_ID = 3
export const PURCHASE_ID = 1
export const AVERAGE_VALUE = 50

const { vi } = languageObjs

export const getTooltipContentData = (payload, keyWithTitle) => {
  return Object.keys(keyWithTitle).map((strKey) => {
    const key = parseInt(strKey)
    return {
      label: keyWithTitle[strKey],
      value: payload[key],
      key,
    }
  })
}

export const handleXAxisTitle = (data, locale) => {
  return data
    .map((item) => {
      if (item.month < 10) {
        return {
          ...item,
          monthYear:
            locale === vi
              ? `Th0${item.month}-${item.year}`
              : `0${item.month}-${item.year}`,
        }
      }

      return {
        ...item,
        monthYear:
          locale === vi
            ? `Th${item.month}-${item.year}`
            : `${item.month}-${item.year}`,
      }
    })
    .sort(sortByMonthYear)
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }
  }

  return 0
}

export const handleComboChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const dataWithoutAverage = payload.filter(
    (item) => item.vnTypeId !== AVERAGE_ID,
  )
  const dataAverage = payload.filter((item) => item.vnTypeId === AVERAGE_ID)
  let average = undefined
  if (dataAverage.length) {
    average = AVERAGE_VALUE
    // dataAverage[0].value
  }
  const dataNotDuplicate = handleDuplicateByKey(dataWithoutAverage, [
    'realYear',
    'realMonth',
  ])

  return dataNotDuplicate.map((item) => {
    const dataWithMonthYear = payload.filter(
      (element) =>
        element.realYear === item.realYear &&
        element.realMonth === item.realMonth,
    )
    return dataWithMonthYear.reduce((previous, current) => {
      return {
        ...previous,
        [current.vnTypeId]:
          current.vnTypeId === GROWTH_ID ? current.value * 100 : current.value,
        month: current.realMonth,
        year: current.realYear,
        [AVERAGE_ID]: average,
      }
    }, {})
  })
}

export const handleKeyWithTitle = (payload) => {
  if (!payload.length) {
    return {}
  }

  const keyNotDuplicate = handleDuplicateByKey(payload, ['vnTypeId'])
  return keyNotDuplicate.reduce((previous, current) => {
    return {
      ...previous,
      [current.vnTypeId]: current.vnTypeName,
    }
  }, {})
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}
