import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import styles from '../../style.module.css'
import { MethodSelectComponent } from './MethodSelectComponent'

export const StatisticalMethodsComponent = ({
  statisticalMethodSettingsRef,
  isParent,
  value,
  onChange,
}) => {
  return (
    <div className={styles.settingBlockItem}>
      <div className={styles.settingBlockLeft}>
        <Span
          className={
            isParent ? styles.settingBlockTitle : styles.settingBlockSubTitle
          }
          style={{ fontSize: isParent ? 12 : 10 }}
        >
          <Translate value="financialChart.chartSettings.STATISTICAL_METHODS" />
        </Span>
      </div>
      <div className={styles.settingBlockRight}>
        <div style={{ width: 'calc(100% - 36px)' }}>
          <MethodSelectComponent
            statisticalMethodSettingsRef={statisticalMethodSettingsRef}
            data={value}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  )
}
