import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { selectidsCompany } from '../tradingStatistics/store/slice'
import Table from './Table'
import { DATE_TABS, PRICE_TABS } from './constants'
import style from './index.module.css'
import {
  changeFilter,
  changePage,
  selectFilterPriceBoard,
  selectFilterSort,
} from './store/slice'
import { downloadStatisticInformationData } from './store/thunk'
export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1)
}
export function getFirstDayOfYear(year) {
  return new Date(year, 0, 1)
}

const PanelPrice = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const OrganizationIds = useSelector(selectidsCompany)
  const filter = useSelector(selectFilterPriceBoard)
  const { SortBy, SortOrder } = useSelector(selectFilterSort)
  const { issueMethodId, isCleanPrice, icbIds, timeRange, page, From, To } =
    filter

  // Actions
  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        key: 'timeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        key: 'To',
        value: payload.endDate,
      }),
    )
    dispatch(changePage(1))
  }

  const handleChangePriceType = (item) => {
    dispatch(
      changeFilter({
        key: 'isCleanPrice',
        value: item.value,
      }),
    )
    dispatch(changePage(1))
  }

  const handleChangeTimeRangeType = (item) => {
    dispatch(
      changeFilter({
        key: 'timeRange',
        value: item.value,
      }),
    )
  }

  const handleExportData = () => {
    const params =
      timeRange === calendarTabKey
        ? {
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Page: page,
            PageSize: 20,
            PriceType: isCleanPrice ? 'PriceClean' : 'PriceDirty',
            SortBy,
            SortOrder,
            From: formatDateTime(From, FORMAT.DATE),
            To: formatDateTime(To, FORMAT.DATE),
            OrganizationIds: OrganizationIds,
          }
        : {
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Page: page,
            PageSize: 20,
            TimeRange: timeRange,
            PriceType: isCleanPrice ? 'PriceClean' : 'PriceDirty',
            SortBy,
            SortOrder,
            OrganizationIds: OrganizationIds,
          }

    return downloadStatisticInformationData(params)
  }

  return (
    <Panel
      title="bond.corporateBond.secondaryMarket.TRADE_STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex justify-content-space-between mb-8">
              <DispatchActionTabWithCalendar
                id="tradingStatistic"
                tabs={DATE_TABS}
                activeTab={timeRange}
                startDate={From}
                endDate={To}
                datePreset={DATE_TABS}
                excludeDateIntervals={[]}
                onChangeTab={handleChangeTimeRangeType}
                onChangeCalendar={handleChangeCalendar}
              />

              <div style={{ marginRight: 4 }}>
                <DispatchActionTab
                  data={PRICE_TABS}
                  activeTab={isCleanPrice}
                  onChangeTab={handleChangePriceType}
                  itemStyle={{
                    width: '50%',
                    fontWeight: 600,
                  }}
                />
              </div>
            </div>

            <div className={style.trans}>
              <Span style={{ color: '#85939C', fontStyle: 'italic' }}>
                <Translate value={`bond.corporateBond.priceBoard.NOTE_3`} />
              </Span>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${+size.height}px)`,
                }}
              >
                <Table />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

PanelPrice.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default PanelPrice
