import macroProxy from '../../proxies/common/MacroProxy'
import { ServiceBase } from '../ServiceBase'

class MacroService extends ServiceBase {
  getExchangeRates(params) {
    return this.applyMemoryCache(
      'MacroService.getExchangeRates',
      params,
    )(() => macroProxy.getExchangeRates(params))
  }
}

export default new MacroService()
