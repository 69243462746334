import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import style from './styleTheme.module.css'

const PopupConfirm = ({
  titlePopup,
  isI18n,
  isShow,
  handleShow,
  handleOk,
  handleCancel,
}) => {
  const onOk = () => {
    handleShow(false)
    handleOk && handleOk()
  }

  const onCancel = () => {
    handleShow(false)
    handleCancel && handleCancel()
  }

  if (isShow) {
    return (
      <Popup overlayCustom={style.overlayCustom}>
        <div className={`modal modal-small ${style.modal}`}>
          <div
            className={`modal-content ${style.modalContentStyle}`}
            style={{ borderRadius: 4 }}
          >
            <Span className={style.colorText}>
              {isI18n ? <Translate value={titlePopup} /> : titlePopup}
            </Span>
            <div className="group-btn d-flex justify-content-end w-100">
              <Button
                className={`btn w-80 h-20 ${style.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
                onClick={onOk}
              >
                <Translate value="financialChart.YES" />
              </Button>
              <Button
                className={`btn w-80 h-20 ${style.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
                onClick={onCancel}
              >
                <Translate value="financialChart.CANCEL" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

PopupConfirm.propTypes = {
  isI18n: PropTypes.bool,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
}

PopupConfirm.defaultProps = {
  titlePopup: '',
  isI18n: true,
  handleOk: () => {},
  handleCancel: () => {},
}

export default PopupConfirm
