import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../../utils/Value'
import { quarterTypeOptions, tableYearOptions } from '../../../../constants'
import { tableConstants } from '../constants'
import { formatTableData } from '../helper'
import { getDataTable } from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/security/performance/dataTable',
  initialState: {
    ids: tableConstants.map((item) => item.id),
    dataByIds: {},
    groupColumns: [],
    loading: true,
    yearType: tableYearOptions[0].value,
    quarterType: quarterTypeOptions[0].value,
    retryFlag: true,
  },
  reducers: {
    changeYearType: (state, action) => {
      state.yearType = action.payload
    },
    changeQuarterType: (state, action) => {
      state.quarterType = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataTable.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataTable.fulfilled, (state, action) => {
      const { groupColumns, valueByIds } = formatTableData(state, action)

      state.groupColumns = groupColumns
      state.dataByIds = valueByIds
      state.loading = false
    })
    builder.addCase(getDataTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectDataByIds = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[id], attr)
export const selectTableLoading = (state) => state[slice.name].loading
export const selectRetryFlag = (state) => state[slice.name].retryFlag
export const selectYearType = (state) => state[slice.name].yearType
export const selectQuarterType = (state) => state[slice.name].quarterType
export const { changeYearType, changeQuarterType, changeRetryFlag } =
  slice.actions

register(slice.name, slice.reducer)
