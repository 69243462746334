import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DeepTransactionService from '../../../../../../core/services/market/equityTrading/DeepTransactionService'

export const getDeepTransactionTimeSaleAreaChart = createAsyncThunk(
  'market/equityTrading/deepTransaction/timeSale/GET_DEEP_TRANSACTION_TIME_SALE_AREA_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await DeepTransactionService.getDeepTransactionTimeSaleAreaChart(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getDeepTransactionTimeSaleBarChart = createAsyncThunk(
  'market/equityTrading/deepTransaction/timeSale/GET_DEEP_TRANSACTION_TIME_SALE_BAR_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await DeepTransactionService.getDeepTransactionTimeSaleBarChart(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
