import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { selectIndexSectorTickerInfo } from '../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  SEARCH_INDICATOR,
  SEARCH_ITEM_TYPE,
  SEARCH_TIME_FRAME,
} from '../constants'
import { findIndexBefore, findIndexIsSameOrAfter, getDayPass } from '../helper'
import FilterDataChart from './FilterDataChart'
import ChartComponent from './chart/ChartComponent'
import CompareList from './compareList'
import { clearCompareChecked } from './popupValuation/store/slice'
import {
  selectFilter,
  selectListCompareSearch,
  selectLoading,
  selectValuationChart,
  setListCompareSearch,
} from './store/slice'
import { getCompareValuation, getValuation } from './store/thunk'

const ValuationChart = ({ width, height }) => {
  const dispatch = useDispatch()
  const divTopRef = useRef()
  const divFooterRef = useRef()
  const compare = useSelector(selectListCompareSearch)
  const filter = useSelector(selectFilter)
  const showLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectValuationChart)
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const nameCompare =
    currentInfo.ticker || currentInfo.icbNameCustom || currentInfo.groupName
  const compareList = useSelector(selectListCompareSearch)

  useEffect(() => {
    if (nameCompare) {
      dispatch(
        getValuation({
          GroupType: currentInfo?.groupId
            ? SEARCH_ITEM_TYPE.INDICES
            : currentInfo?.organizationId
            ? SEARCH_ITEM_TYPE.TICKER
            : SEARCH_ITEM_TYPE.SECTOR,
          Id:
            currentInfo?.groupId ||
            currentInfo?.organizationId ||
            currentInfo?.icbId,
        }),
      )
      dispatch(clearCompareChecked())
      dispatch(setListCompareSearch([]))
    }
  }, [nameCompare])

  useEffect(() => {
    if (compareList.length) {
      dispatch(getCompareValuation(compareList))
    }
  }, [compareList])

  const getValueParams = () => {
    return [
      {
        id: 'valuationValue',
        textColor: '#1e242e',
        bgColor: '#f7b80c',
        nameCompare: nameCompare,
      },
    ]
  }

  const getLowerUpperParams = () => {
    return filter.stateIndicator === SEARCH_INDICATOR.priceToBook
      ? [
          {
            id: 'lowerLimitPB',
            bgColor: '#929292',
            nameCompare: I18n.t(
              'market.marketInDepth.valuation.LABEL_LOWER_LIMIT',
            ),
            strokeWidth: 1,
            hideLabel: true,
          },
          {
            id: 'upperLimitPB',
            bgColor: '#929292',
            nameCompare: I18n.t(
              'market.marketInDepth.valuation.LABEL_UPPER_LIMIT',
            ),
            strokeWidth: 1,
            hideLabel: true,
          },
        ]
      : [
          {
            id: 'lowerLimitPE',
            bgColor: '#929292',
            nameCompare: I18n.t(
              'market.marketInDepth.valuation.LABEL_LOWER_LIMIT',
            ),
            strokeWidth: 1,
            hideLabel: true,
          },
          {
            id: 'upperLimitPE',
            bgColor: '#929292',
            nameCompare: I18n.t(
              'market.marketInDepth.valuation.LABEL_UPPER_LIMIT',
            ),
            strokeWidth: 1,
            hideLabel: true,
          },
        ]
  }

  const getPEorPBParams = () => {
    return filter.stateIndicator === SEARCH_INDICATOR.priceToBook
      ? [
          {
            id: 'pb',
            textColor: '#1e242e',
            bgColor: '#009fe6',
            nameCompare: compare.length ? nameCompare : 'P/B',
          },
        ]
      : [
          {
            id: 'pe',
            textColor: '#1e242e',
            bgColor: '#009fe6',
            nameCompare: compare.length ? nameCompare : 'P/E',
          },
        ]
  }

  const getCompareParams = () => {
    return filter.stateIndicator === SEARCH_INDICATOR.priceToBook
      ? [
          ...compare.map((item) => ({
            id: item.idCompare + '_pb',
            textColor: item.color,
            bgColor: item.bgColor,
            nameCompare: item.nameCompare,
          })),
        ]
      : [
          ...compare.map((item) => ({
            id: item.idCompare + '_pe',
            textColor: item.color,
            bgColor: item.bgColor,
            nameCompare: item.nameCompare,
          })),
        ]
  }

  const filterDataChart = (dataChart) => {
    const mapSetDataChart = {
      [SEARCH_TIME_FRAME['3M']]: findIndexIsSameOrAfter(
        dataChart,
        getDayPass(0, 3),
      ),
      [SEARCH_TIME_FRAME['6M']]: findIndexIsSameOrAfter(
        dataChart,
        getDayPass(0, 6),
      ),
      [SEARCH_TIME_FRAME['9M']]: findIndexIsSameOrAfter(
        dataChart,
        getDayPass(0, 9),
      ),
      [SEARCH_TIME_FRAME['1Y']]: findIndexIsSameOrAfter(
        dataChart,
        getDayPass(1, 0),
      ),
      [SEARCH_TIME_FRAME['3Y']]: findIndexIsSameOrAfter(
        dataChart,
        getDayPass(3, 0),
      ),
      [SEARCH_TIME_FRAME['ALL']]: dataChart,
    }

    if (filter.timeFrameTab !== TIME_RANGES.YTD) {
      if (mapSetDataChart[filter.timeFrameTab] !== -1) {
        return dataChart.slice(mapSetDataChart[filter.timeFrameTab])
      } else return []
    }

    if (filter.dateFrom || filter.dateTo) {
      return dataChart.slice(
        findIndexIsSameOrAfter(
          dataChart,
          filter.dateFrom
            ? new Date(filter.dateFrom)
            : dataChart[0]?.tradingDateId,
        ),
        findIndexBefore(
          dataChart,
          filter.dateTo ? new Date(filter.dateTo) : new Date(),
        ),
      )
    }

    return []
  }

  const data = dataChart.length ? filterDataChart(dataChart) : []

  const [heightSizeTracker, setHeightSizeTracker] = useState()

  useEffect(() => {
    if (divFooterRef.current?.offsetHeight && divTopRef.current?.offsetHeight)
      setHeightSizeTracker(
        divTopRef.current.offsetHeight + divFooterRef.current.offsetHeight,
      )
  }, [width, showLoading, data])

  return (
    <>
      <div ref={divTopRef} style={{ paddingBottom: 30 }}>
        <FilterDataChart />
      </div>
      {showLoading ? (
        <Loading />
      ) : (
        <>
          {data.length ? (
            <div id="valuationChartId" style={{ position: 'relative' }}>
              {heightSizeTracker && (
                <>
                  <div className="d-flex justify-content-center">
                    <ChartComponent
                      data={data}
                      width={width || 0}
                      height={height - heightSizeTracker || 0}
                      keyXAxis={'tradingDateId'}
                      timeFrame={filter.timeFrameTab}
                      rightLineKeys={[
                        ...getLowerUpperParams(),
                        ...getPEorPBParams(),
                        ...getCompareParams(),
                      ]}
                      rightLineKeysSecond={getValueParams()}
                      rightLineKeysCompare={[
                        ...getPEorPBParams(),
                        ...getCompareParams(),
                      ]}
                      isCompare={compare.length}
                      stateIndicator={filter?.stateIndicator}
                      typeCurrentInfo={currentInfo?.organizationId}
                    />
                  </div>
                </>
              )}
              <div ref={divFooterRef} style={{ margin: 'auto' }}>
                {compare?.length > 0 ? (
                  <CompareList />
                ) : (
                  <Footer
                    key={width}
                    list={[
                      {
                        text: nameCompare,
                        type: TYPE.LINE,
                        before: {
                          bgColor: '#f7b80c',
                        },
                      },
                      {
                        text: I18n.t(filter?.stateIndicator),
                        type: TYPE.LINE,
                        before: {
                          bgColor: '#009fe6',
                        },
                      },
                      {
                        text:
                          filter.stateIndicator === SEARCH_INDICATOR.priceToBook
                            ? I18n.t(
                                'market.marketInDepth.valuation.LOWER_LIMIT_PB',
                              )
                            : I18n.t(
                                'market.marketInDepth.valuation.LOWER_LIMIT_PE',
                              ),
                        type: TYPE.LINE,
                        isI18n: true,
                        before: {
                          bgColor: '#929292',
                        },
                      },
                      {
                        text:
                          filter.stateIndicator === SEARCH_INDICATOR.priceToBook
                            ? I18n.t(
                                'market.marketInDepth.valuation.UPPER_LIMIT_PB',
                              )
                            : I18n.t(
                                'market.marketInDepth.valuation.UPPER_LIMIT_PE',
                              ),
                        type: TYPE.LINE,
                        isI18n: true,
                        before: {
                          bgColor: '#929292',
                        },
                      },
                    ]}
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                height: `calc(100% - ${divTopRef.current?.offsetHeight}px)`,
              }}
            >
              <NoData />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default ValuationChart
