import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundHoldingProxy extends ProxyBase {
  getListTickersSelect(params) {
    return this.get('GetListTickersSelect', params)
  }

  getAllFundHolding(params) {
    return this.get('GetAllFundHolding', params)
  }

  downloadAllFundHolding(params) {
    return this.get('GetAllFundHoldingExport', params, 'download')
  }

  getDetailFundHolding(params) {
    return this.get('GetDetailFundHolding', params)
  }

  downloadDetailFundHolding(params) {
    return this.get('GetDetailFundHoldingExport', params, 'download')
  }
}

export default new FundHoldingProxy(
  ServiceProxyConfig.Fund.FundCenter.FundHolding.ServiceUrl,
)
