import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getCorpMaxPeriod, getCorpRevenueBreakdown } from './thunk'

const currentYear = new Date().getFullYear()

const initialState = {
  filter: {
    year: null,
  },
  data: [],
  loading: true,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/corp/revenueBreakdown',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorpMaxPeriod.pending, (state) => {})
    builder.addCase(getCorpMaxPeriod.fulfilled, (state, action) => {
      state.filter.year = action.payload?.year || currentYear
    })
    builder.addCase(getCorpMaxPeriod.rejected, (state, action) => {
      state.filter.year = currentYear
    })
    builder.addCase(getCorpRevenueBreakdown.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getCorpRevenueBreakdown.fulfilled, (state, action) => {
      const totalRevenue =
        action.payload?.reduce((total, item) => total + item.rB1, 0) || 0
      state.data =
        action.payload?.map((item) => ({
          ...item,
          percent: item.rB1 / totalRevenue,
        })) || []
      state.loading = false
    })
    builder.addCase(getCorpRevenueBreakdown.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

export const { resetStore, changeFilter } = slice.actions

register(slice.name, slice.reducer)
