import PropTypes from 'prop-types'
import { EMPTY_VALUE } from '../../../../constants/Common'

export const OtherColumnsCell = ({ val, rowId, className }) => {
  return (
    <td
      className={className}
      style={{
        textAlign: 'right',
        color:
          (val === EMPTY_VALUE || parseInt(val)) >= 0 ? undefined : '#ff4752',
      }}
    >
      {val}
    </td>
  )
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
