import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import FilingTable from './filingTable'
import Filter from './filter'

const MAP_KEY = {
  FILTER: 'FILTER',
  TABLE: 'TABLE',
}

const FILTER_WIDTH = 309

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: `100%`,
    top: 0,
    left: 0,
    filterResize: [
      {
        key: MAP_KEY.TABLE,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TABLE}`]: {
    width: `calc(100% - ${FILTER_WIDTH + 2 * COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: 700,
    minLeft: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="corporate.newsEvents.filing.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>
      <Panel
        title="corporate.newsEvents.filing.FILING"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TABLE}
        sizes={sizes}
        setSizes={setSizes}
      >
        <FilingTable />
      </Panel>
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
