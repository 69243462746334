import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import { TopInfo } from '../../../common/topInfo/indexTopInfo'
import Content from './Content'
import style from './index.module.css'
import { selectTabType } from './store/slice'
import { TAB_TYPE } from './summary/constants'
import { selectData as selectForeignData } from './summary/foreign/store/slice'
import { selectData as selectOrderStatisticData } from './summary/orderStatistic/store/slice'
import { selectData as selectOverviewData } from './summary/overview/store/slice'
import { selectData as selectProprietaryData } from './summary/proprietary/store/slice'
import { selectData as selectStatisticInvestorData } from './summary/statisticInvest/store/slice'

export const IndexStatistics = () => {
  // Use selector
  const tabType = useSelector(selectTabType)
  const foreignData = useSelector(selectForeignData)
  const orderStatisticData = useSelector(selectOrderStatisticData)
  const overviewData = useSelector(selectOverviewData)
  const proprietaryData = useSelector(selectProprietaryData)
  const statisticInvestorData = useSelector(selectStatisticInvestorData)

  // Use state
  const [updatedDate, setUpdatedDate] = useState(new Date())

  // Use effect
  useEffect(() => {
    switch (tabType) {
      case TAB_TYPE.FOREIGN:
        if (foreignData.length) {
          const itemData = [...foreignData]
            .reverse()
            .find((item) => item.maxDate)
          setUpdatedDate(itemData ? new Date(itemData.maxDate) : new Date())
        }
        break
      case TAB_TYPE.ORDER_STATISTIC:
        if (orderStatisticData.length) {
          const itemData = [...orderStatisticData]
            .reverse()
            .find((item) => item.maxDate)
          setUpdatedDate(itemData ? new Date(itemData.maxDate) : new Date())
        }
        break
      case TAB_TYPE.OVERVIEW:
        if (overviewData.length) {
          const itemData = [...overviewData]
            .reverse()
            .find((item) => item.maxDate)
          setUpdatedDate(itemData ? new Date(itemData.maxDate) : new Date())
        }
        break
      case TAB_TYPE.PROPRIETARY:
        if (proprietaryData.length) {
          const itemData = [...proprietaryData]
            .reverse()
            .find((item) => item.maxDate)
          setUpdatedDate(itemData ? new Date(itemData.maxDate) : new Date())
        }
        break
      case TAB_TYPE.STATISTIC_INVEST:
        if (statisticInvestorData.length) {
          const itemData = [...statisticInvestorData]
            .reverse()
            .find((item) => item.maxDate)
          setUpdatedDate(itemData ? new Date(itemData.maxDate) : new Date())
        }
        break
      default:
        setUpdatedDate(new Date())
        break
    }
  }, [
    tabType,
    foreignData,
    orderStatisticData,
    overviewData,
    proprietaryData,
    statisticInvestorData,
  ])

  return (
    <div className="h-100 position-relative">
      <ScrollGroupComponent>
        <SizeTracker>
          {(size) => (
            <>
              <TopInfo title="market.marketInDepth.indexStatistics.SCREEN_LABEL" />
              {size.height && (
                <div
                  className="summary-page"
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                    position: 'relative',
                  }}
                >
                  <Content />
                </div>
              )}
              <div style={{ height: 32 }}></div>
            </>
          )}
        </SizeTracker>
      </ScrollGroupComponent>
      <div className={style['data-calc-time']}>
        <Span style={{ fontSize: 11 }}>
          <Translate
            value={'market.marketInDepth.indexStatistics.TABLE_FOOTER_TEXT'}
          />
          &nbsp;
          <DateTimeSpan date={updatedDate} />
        </Span>
      </div>
    </div>
  )
}
