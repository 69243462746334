import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN_RECHARTS } from '../../../../common/chart/constants'
import { StackedBarLineChart } from '../../../../common/chart/customCharts/StackedBarLineChart'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { TYPE_IMPORT_EXPORT } from '../constants'
import {
  selectImportExportDataType,
  selectOrigParentCategory,
  selectTypeFilter,
} from '../exportImportTable/store/slice'
import style from '../index.module.css'
import { COLOR_STRUCTURE_CHART } from './constants'
import { getProductNameById } from './helper'
import {
  selectActiveId,
  selectCurrentActiveName,
  selectCurrentMonth,
  selectCurrentYear,
  selectDataChart,
  selectIds,
  selectLoading,
  selectMaxDate,
  selectOrigData,
} from './store/slice'
import {
  getExportContributeByLocation,
  getImportContributeByLocation,
  getMaxDateDetail,
} from './store/thunk'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 80, right: 20 }
const PADDING_BOTTOM_X_AXIS_KEY = 20

export const ChartComponent = ({ width, height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const ids = useSelector(selectIds)
  const origData = useSelector(selectOrigData)
  const currentMonth = useSelector(selectCurrentMonth)
  const currentYear = useSelector(selectCurrentYear)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const activeId = useSelector(selectActiveId)
  const activeLocationName = useSelector(selectCurrentActiveName)
  const listParentCategory = useSelector(selectOrigParentCategory)
  const importExportDataType = useSelector(selectImportExportDataType)
  const maxDate = useSelector(selectMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT
  const activeCategoryId = activeId ?? listParentCategory[1]?.locationId

  const fillColor = {}
  const mappingDisplayName = {}
  for (const id of ids) {
    fillColor[id] = COLOR_STRUCTURE_CHART[ids.indexOf(id)]
    mappingDisplayName[id] = getProductNameById(origData, id)
  }

  const getHeader = () => {
    if (locale === 'vi') {
      return `${
        isImportData
          ? I18n.t(
              'economy.importExport.importExport.exportImportByLocation.STRUCTURE_IMPORT',
            )
          : I18n.t(
              'economy.importExport.importExport.exportImportByLocation.STRUCTURE_EXPORT',
            )
      } ${activeLocationName} `
    } else {
      return `${activeLocationName} ${
        isImportData
          ? I18n.t(
              'economy.importExport.importExport.exportImportByLocation.STRUCTURE_IMPORT',
            )
          : I18n.t(
              'economy.importExport.importExport.exportImportByLocation.STRUCTURE_EXPORT',
            )
      }`
    }
  }

  useEffect(() => {
    dispatch(
      getMaxDateDetail({
        ExImConstant: importExportDataType,
      }),
    )
  }, [importExportDataType])

  useEffect(() => {
    if (isImportData && activeCategoryId && maxDate) {
      dispatch(
        getImportContributeByLocation({
          DataType: currentTypeFilter,
          Year: currentYear ?? maxDate?.realYear,
          Month: currentMonth ?? maxDate?.realMonth,
          LocationId: activeCategoryId,
        }),
      )
    }
  }, [
    activeCategoryId,
    currentTypeFilter,
    isImportData,
    currentYear,
    currentMonth,
    maxDate,
    locale,
  ])

  useEffect(() => {
    if (!isImportData && activeCategoryId && maxDate) {
      dispatch(
        getExportContributeByLocation({
          DataType: currentTypeFilter,
          Year: currentYear ?? maxDate?.realYear,
          Month: currentMonth ?? maxDate?.realMonth,
          LocationId: activeCategoryId,
        }),
      )
    }
  }, [
    activeCategoryId,
    currentTypeFilter,
    isImportData,
    currentYear,
    currentMonth,
    maxDate,
    locale,
  ])

  return width && height && isLoading ? (
    <Loading />
  ) : !dataChart.length ? (
    <NoData />
  ) : (
    <SizeTracker>
      {(size) => (
        <div className="h-100">
          <div className={[style.titleHeader, 'canvas-chart-title'].join(' ')}>
            {getHeader().toUpperCase()}
          </div>
          {size.height && (
            <div
              style={{
                height: height - size.height - PADDING_BOTTOM_X_AXIS_KEY,
              }}
            >
              <StackedBarLineChart
                data={dataChart}
                width={width}
                height={height - size.height - PADDING_BOTTOM_X_AXIS_KEY}
                keyXAxis="time"
                barKeys={ids}
                lineKeys={[]}
                decimalLength={2}
                leftLabel={`(${I18n.t(
                  'economy.importExport.importExport.MILLION_USD',
                )})`}
                fillColor={fillColor}
                mappingDisplayName={mappingDisplayName}
                margin={MARGIN}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis={true}
                isFooterResize
                unit={I18n.t('economy.importExport.importExport.MILLION_USD')}
                listCheckbox={ids}
              />
            </div>
          )}
        </div>
      )}
    </SizeTracker>
  )
}
