import React from 'react'
import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { COMPARE_MAX_ADDED } from '../constants'
import style from '../style.module.css'

const ItemCompare = ({ item, onRowClick, listTicker }) => {
  const dataTopInfo = useSelector(selectBasicInfo)

  const handleChecked = () => {
    return listTicker.some((i) => i.organizationId === item.organizationId)
  }

  const checkDisableCompare = () => {
    const compareCoincidentTopInfo =
      dataTopInfo.organizationId === item.organizationId
    return (
      (listTicker.length === COMPARE_MAX_ADDED && !handleChecked()) ||
      compareCoincidentTopInfo
    )
  }

  return (
    <tr
      className={checkDisableCompare() ? style.disabled : undefined}
      onClick={() => !checkDisableCompare() && onRowClick(item)}
    >
      <td>
        <Span style={{ fontSize: 12 }}>{item.ticker}</Span>
      </td>
      <td>
        <div
          className={`d-flex justify-content-space-between ${style.opacity40}`}
        >
          <div>{item?.organizationShortName}</div>
        </div>
      </td>
      <td className={style.lineBorderLeft}>
        <input
          type="checkbox"
          className={`checkbox ${style.checkbox}`}
          readOnly
          checked={handleChecked()}
        />
      </td>
    </tr>
  )
}

export default ItemCompare
