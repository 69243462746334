import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { handleTickTemplate } from '../setting/companyNews/company/store/slice'
import { applyCorporateSetting } from '../setting/companyNews/corporateNews/store/slice'
import { applyMarketSetting } from '../setting/companyNews/marketNews/store/slice'
import { handleDate } from '../setting/settingArea/store/slice'
import EditName from './EditName'
import style from './index.module.css'
import PopupDelete from './PopupDelete'
import {
  getActiveTemplate,
  getSettingById,
  handleActiveTemplate,
} from './store/slice'

export const idDivFooterScroll = 'dailyReport-footer-scroll'
const DROP_DOWN_HEIGHT = 75

const TemplateItem = ({ id, title, date }) => {
  const dispatch = useDispatch()

  const templateRef = useRef()
  const [isDropdownTop, setIsDropdownTop] = useState(false)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isShowPopupDelete, setShowPopupDelete] = useState(false)
  const [isShowEdit, setIsShowEdit] = useState(false)

  const templateSetting = useSelector(getSettingById(id))
  const activeTemplate = useSelector(getActiveTemplate)
  const { organizationIds, corporateIds, marketIds } = templateSetting.setting

  const handleClose = (e) => {
    setShowPopupDelete(false)
    e.stopPropagation()
  }

  const handleEditNameTemplate = (e) => {
    e.stopPropagation()
    setIsShowEdit(true)
  }

  const handleDeleteTemplate = (e) => {
    e.stopPropagation()
    setShowPopupDelete(true)
  }

  const handleOpenDropdown = () => {
    const heightFromFooter =
      document.querySelector(`#${idDivFooterScroll}`).getBoundingClientRect()
        .top - templateRef.current.getBoundingClientRect().top
    if (heightFromFooter <= DROP_DOWN_HEIGHT) setIsDropdownTop(true)
    else setIsDropdownTop(false)

    setIsShowDropdown(true)
  }

  const handleCloseDropdown = () => setIsShowDropdown(false)

  const setActiveTemplate = () => {
    dispatch(handleActiveTemplate(id))
    dispatch(handleTickTemplate(organizationIds))
    dispatch(applyCorporateSetting(corporateIds))
    dispatch(applyMarketSetting(marketIds))
    dispatch(handleDate(date))
  }

  return (
    <div
      className={style.templateSaved}
      ref={templateRef}
      onClick={setActiveTemplate}
    >
      {isShowEdit ? (
        <EditName id={id} title={title} setIsShowEdit={setIsShowEdit} />
      ) : (
        <>
          <div
            className={`${style.textTemplate} ${
              activeTemplate === id ? style.active : undefined
            }`}
          >
            <TextEllipsis isI18n={false} text={title} isHardWidth={false} />
          </div>
          <div
            className={style.btnMore}
            onMouseOver={handleOpenDropdown}
            onMouseOut={handleCloseDropdown}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <i className="icon-menu-dot-hor" />
            <div
              className={`${style.dropdownMore} ${
                isDropdownTop ? style.dropdownTop : ''
              }`}
              style={{ display: isShowDropdown ? 'block' : 'none' }}
            >
              <ul>
                <li>
                  <button
                    className="cursor-pointer"
                    onClick={(e) => handleEditNameTemplate(e)}
                    style={{ width: '100%', textAlign: 'start' }}
                  >
                    <i
                      className="icon-edit"
                      style={{ display: 'inline-block', width: '8px' }}
                    />
                    <Span>
                      <Translate value="sector.sectorConstituents.segmentation.RENAME" />
                    </Span>
                  </button>
                </li>
                <li>
                  <button
                    className="cursor-pointer"
                    onClick={(e) => handleDeleteTemplate(e)}
                    style={{ width: '100%', textAlign: 'start' }}
                  >
                    <i
                      className="icon-trash"
                      style={{ display: 'inline-block', width: '8px' }}
                    />
                    <Span>
                      <Translate value="sector.sectorConstituents.segmentation.DELETE" />
                    </Span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
      <PopupDelete
        isShow={isShowPopupDelete}
        handleClose={handleClose}
        id={id}
        title={title}
        activeTemplate={activeTemplate}
      />
    </div>
  )
}

TemplateItem.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
}

export default TemplateItem
