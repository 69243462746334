import PropTypes from 'prop-types'
import { useRef } from 'react'

export const OtherColumnsCell = ({ val, rowId, props }) => {
  const tdRef = useRef()

  return (
    <td
      {...props}
      ref={tdRef}
      style={{ ...props.style, textAlign: 'right', fontSize: 9 }}
    >
      {val}
    </td>
  )
}

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.string.isRequired,
  props: PropTypes.object.isRequired,
}
