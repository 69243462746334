import chartingProxy from '../../proxies/common/ChartingProxy'
import { ServiceBase } from '../ServiceBase'

class ChartingService extends ServiceBase {
  getTradingViewData(params) {
    return this.getData(() => chartingProxy.getTradingViewData(params), true)
  }

  getStockEvents(params) {
    return this.getData(() => chartingProxy.getStockEvents(params))
  }

  createChartLayout(params) {
    return this.getData(() => chartingProxy.createChartLayout(params))
  }

  getChartLayout(params) {
    return this.applyMemoryCache(
      'ChartingService.getChartLayout',
      params,
    )(() => chartingProxy.getChartLayout(params))
  }

  getChartLayoutShareInfo(params) {
    return this.getData(() => chartingProxy.getChartLayoutShareInfo(params))
  }

  getSharedChartLayout(params) {
    return this.getData(() => chartingProxy.getSharedChartLayout(params))
  }

  getIndicators(params) {
    return this.getData(() => chartingProxy.getIndicators(params))
  }

  getAllMacroEconomicIndicator(params) {
    return this.applyMemoryCache(
      'ChartingService.getAllMacroEconomicIndicator',
      params,
    )(() => chartingProxy.getAllMacroEconomicIndicator(params))
  }

  getAllMoreIndicator(params) {
    return this.applyMemoryCache(
      'ChartingService.getAllMoreIndicator',
      params,
    )(() => chartingProxy.getAllMoreIndicator(params))
  }

  getMoreIndicatorData(params) {
    return this.applyMemoryCache(
      'ChartingService.getMoreIndicatorData',
      params,
    )(() => chartingProxy.getMoreIndicatorData(params))
  }
}

export default new ChartingService()
