import { Span } from '../../../../common/html/Span'
import style from './index.module.css'

const ThTable = ({ title, id, column }) => {
  return (
    <div style={{ ...column.style }}>
      <Span
        style={{
          fontSize: 12,
          textTransform: 'uppercase',
          fontWeight: 600,
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </Span>
      {column.additionText && (
        <Span className={style.additionText} style={{ fontSize: 12 }}>
          {column.additionText}
        </Span>
      )}
    </div>
  )
}

export default ThTable
