import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import style from '../index.module.css'
import {
  changeIsGroup,
  selectIndicatorPopupIndicator,
  selectIsGroup,
} from '../store/slice'

const Count = ({
  maxIndicator,
  lengthIndicator,
  errors,
  ratio,
  isDisabledSettingGroup,
}) => {
  const dispatch = useDispatch()

  const indicator = useSelector(selectIndicatorPopupIndicator)
  const isGroup = useSelector(selectIsGroup)

  const totalIndicator = indicator.length * (ratio || 1) + lengthIndicator

  const handleChangeCheckbox = () => {
    dispatch(changeIsGroup())
  }

  return (
    <div className="mt-8">
      <div className={style.disabled}>
        <Translate value="common.popup.SELECT" />
        &nbsp;{totalIndicator}/{maxIndicator}
        <Translate value="common.popup.OF_TOTAL_TABLE" />
      </div>
      <div>
        {totalIndicator > maxIndicator && (
          <div className={style.warning}>
            <i className="icon-warning" />
            <Translate value="common.popup.MAX_MORE_CONDITION" />
          </div>
        )}
        {errors.map((message, index) => (
          <div className={style.error} key={index}>
            {message}
          </div>
        ))}
      </div>
      {!isDisabledSettingGroup && (
        <div className={style.typeSetting}>
          <input
            onClick={handleChangeCheckbox}
            type="checkbox"
            className="checkbox cursor-pointer"
            checked={isGroup}
            readOnly
          />
          <div>
            <Translate value="common.popup.SETTING_GROUP_NOTE" />
          </div>
        </div>
      )}
    </div>
  )
}

Count.propTypes = {
  maxIndicator: PropTypes.number.isRequired,
  lengthIndicator: PropTypes.number,
  errors: PropTypes.array,
  ratio: PropTypes.number,
  isDisabledSettingGroup: PropTypes.bool,
}

Count.defaultProps = {
  lengthIndicator: 0,
  errors: [],
  ratio: 1,
  isDisabledSettingGroup: false,
}

export default Count
