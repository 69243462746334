import { useSelector } from 'react-redux'
import { GrowthYearChart } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Loading } from '../../../../common/loading'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectLoading } from '../table/store/slice'

const PanelGrowthYear = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const isLoading = useSelector(selectLoading)

  const nameScreen = UseI18n(
    'economy.productionAndConsumption.consumption.CONSUMPTION',
  )
  const chartName = UseI18n(
    'economy.productionAndConsumption.consumption.GROWTH_BY_YEAR',
  )

  return (
    <Panel
      title={'economy.productionAndConsumption.consumption.GROWTH_BY_YEAR'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: 'growthYearID',
        nameScreen,
        chartName,
        tabName: '',
        tickerName: '',
      }}
    >
      {isLoading ? (
        <Loading />
      ) : (
        <GrowthYearChart
          width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
          height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        />
      )}
    </Panel>
  )
}

export default PanelGrowthYear
