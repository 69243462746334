import { useSelector } from 'react-redux'
import { IncomeStructureChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectTimeType } from './store/slice'

export const PanelIncomeStructure = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const timeType = useSelector(selectTimeType)

  return (
    <Panel
      title={'sector.financialAnalysis.bank.profitability.INCOME_STRUCTURE'}
      panelRefs={panelRefs}
      panelKey={mapKey.INCOME_STRUCTURE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'incomeStructureChart',
        nameScreen: UseI18n('sector.financialAnalysis.bank.PROFITABILITY'),
        chartName: UseI18n(
          'sector.financialAnalysis.bank.profitability.INCOME_STRUCTURE',
        ),
        tabName: timeType,
        tickerName: currentInfo.ticker,
      }}
      titleJpg={UseI18n(
        'sector.financialAnalysis.bank.profitability.INCOME_STRUCTURE',
      )}
    >
      <IncomeStructureChart
        width={formatNumber(
          sizes[mapKey.INCOME_STRUCTURE].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.INCOME_STRUCTURE].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
