import PropTypes from 'prop-types'
import React from 'react'
import style from './index.module.css'

const DefaultDropdownItem = ({ label, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      {label}
    </div>
  )
}

DefaultDropdownItem.propTypes = {
  label: PropTypes.string.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}

export default DefaultDropdownItem
