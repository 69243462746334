import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Input } from '../../../common/html/Input'
import Tree from '../../../common/tree'
import { Z_INDEX } from '../../../constants/Common'

import { TABLE_TYPE } from '../constants'
import style from '../index.module.css'
import { selectDerivativeTree, setDerivativeActive } from './store/slice'
import { getDerivative } from './store/thunk'

const SearchDropdownDerivative = ({
  setTableType,
  changeDerivativeActive,
  setChangeFilter,
}) => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const derivativeTree = useSelector(selectDerivativeTree)

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handleClick = (value) => {
    if (value.parentFutureId) {
      if (value.futureId > 0) {
        dispatch(setTableType(TABLE_TYPE.TICKER))
      }

      dispatch(setDerivativeActive(value))
      dispatch(changeDerivativeActive(value))
      dispatch(setChangeFilter())
      setKeySearch(value?.futureName)
      handleHide()
    }
  }

  useEffect(() => {
    dispatch(getDerivative())
  }, [locale])

  useEffect(() => {
    if (!keySearch) {
      dispatch(setTableType(TABLE_TYPE.OVERVIEW))
      dispatch(setDerivativeActive({}))
      dispatch(changeDerivativeActive({}))
    }
  }, [keySearch])

  return (
    <>
      <div className="input-dropdown">
        <div className="ml-8 form-search-inbox" ref={parentDropdownRef}>
          <Input
            style={{
              fontSize: 11,
              backgroundColor: '#3c424b',
              width: 350,
            }}
            type="text"
            className="search-inbox"
            placeholder={I18n.t('market.derivative.SEARCH')}
            onChange={(e) => setKeySearch(e.target.value?.toLowerCase())}
            onFocus={handleShow}
            value={keySearch}
            onClick={handleShow}
          />
          <button type="button">
            <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
          </button>
        </div>
        <Dropdown parentRef={parentDropdownRef} isShow={isShowDropdown}>
          <HandleClickOutside
            handleClickOutside={handleHide}
            exceptRef={dropdownRef}
          >
            <div className="input-dropdown" style={{ marginTop: 2 }}>
              <div
                className={`dropdown-container ${style.dropdownContainerSearch}`}
                style={{ width: dropdownRef?.current?.clientWidth }}
              >
                <div style={{ height: 300, paddingLeft: 8 }}>
                  <Tree
                    valueSearch={keySearch}
                    treeData={derivativeTree}
                    nameKey={['futureName']}
                    defaultOpenLevel1
                    disabledCheckDbClick
                    onClickItem={handleClick}
                    doubleClickItem={undefined}
                    isSearchAbsolute={false}
                    zIndexTextEllipsis={Z_INDEX.DROPDOWN + 1}
                  />
                </div>
              </div>
            </div>
          </HandleClickOutside>
        </Dropdown>
      </div>
    </>
  )
}

SearchDropdownDerivative.propTypes = {}

export default SearchDropdownDerivative
