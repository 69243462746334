export const common = {
  title: 'Comparable Analysis',
  billMoney: '(Bil.VND)',
  unitPercent: '(%)',
  quarter: {
    YEAR: 'Annual',
    Q1: 'Q1',
    Q2: 'Q2',
    Q3: 'Q3',
    Q4: 'Q4',
  },
  times: 'times',
}

export const comparableAnalysis = {
  tableENTER_PRISE: {
    delete: ' ',
    ticker: 'Ticker',
    companyName: 'Company Name',
    bS64: 'Total Equity',
    rT0027: 'Debt to equity ratio',
    rT0037: 'Debt to EBITDA',
    rT0328: 'Net debt/EBITDA',
    rT0046: 'Interest Coverage',
    rT0372: 'FFOc to interest expense',
    rT0378: 'Total Loan to (deposit + financial bill)',
    rT0075: 'CFO to Liabilities',
    rT0093: 'FFOc to debt',
    Medium: 'Medium',
  },
  tableREAL_ESTATE: {
    delete: ' ',
    ticker: 'Ticker',
    companyName: 'Company Name',
    bS64: 'Total Equity',
    rT0027: 'Debt to equity ratio',
    rT0037: 'Debt to EBITDA',
    rT0328: 'Net debt/EBITDA',
    rT0046: 'EBITDA interest coverage',
    rT0372: 'FFOc to interest expense',
    rT0378: 'Total Loan to (deposit + financial bill)',
    rT0075: 'CFO to Liabilities',
    rT0093: 'FFOc to debt',
    Medium: 'Medium',
  },
  tableBANK: {
    delete: ' ',
    ticker: 'Ticker',
    companyName: 'Company Name',
    bS64: 'Total Equity',
    rT0027: 'Loans/Deposit',
    rT0037: 'Bank nonperforming loans to total gross loans',
    rT0328: 'Loan Loss Reserves',
    rT0046: 'Loan Loss Provision to nonperforming loans',
    rT0372: 'Average Cost of Financing (COF)',
    rT0378: 'Liabilities to equity ratio',
    rT0075: 'Tier 1 Capital',
    rT0093: 'CAR Ratio',
    Medium: 'Medium',
  },
  tableSTOCK: {
    delete: ' ',
    ticker: 'Ticker',
    companyName: 'Company Name',
    bS64: 'Total Equity',
    rT0027: 'Liabilities to equity ratio',
    rT0037: 'Margin Lending to equity ratio',
    rT0328: 'CFO/Revenue',
    rT0046: 'FFOc to interest expense',
    rT0372: 'Current ratio',
    rT0378: 'Cash ratio',
    rT0075: 'Short-Term Debts/Total Debts',
    rT0093: 'Asset Turnover',
    Medium: 'Medium',
  },
}

export const chart = {
  title: 'Credit Indicator ratio',
  lengend: {
    enterprise: 'Corporate',
    industry: 'Sector',
  },
  chart1: {
    title: 'Credit rating ratio',
    debtToEBITDA: 'DebtToEbitda',
    ffoInterestExpense: 'FFOInterestExpense',
    ebitdaInterestCoverage: 'EBITDAInterestCoverage',
    cfoToLiabilities: 'CFOToLiabilities',
    ffOcToDebt: 'FFOcToDebt',
  },
  chart2: {
    titleENTER_PRISE: 'Debt to equity ratio',
    titleREAL_ESTATE: 'Liabilities to equity ratio',
    titleBANK: 'Average Cost of Financing (COF)',
    titleSTOCK: ' Current ratio',
  },
  chart3: {
    titleENTER_PRISE: 'FFOc to debt',
    titleREAL_ESTATE: 'FFOc (Funds from operations) to debt',
    titleBANK: 'Tier 1 Capital',
    titleSTOCK: 'Cash ratio',
  },
  chart4: {
    titleENTER_PRISE: 'Debt to EBITDA',
    titleREAL_ESTATE: 'Debt to EBITDA',
    titleBANK: 'CAR Ratio',
    titleSTOCK: 'Asset Turnover',
  },
}
