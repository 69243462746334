import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/fdi/OverviewService'
import { handleExport } from '../../../../../utils/Export'

export const getTableData = createAsyncThunk(
  'economy/fdi/overview/GET_DATA_TABLE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getTotalRegisteredFDICapital(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDataTable = createAsyncThunk(
  'economy/fdi/overview/DOWNLOAD_DATA_TABLE',
  async (data) => {
    const response = await OverviewService.downloadTotalRegisteredFDICapital(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
