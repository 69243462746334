import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { getFontSize } from '../../../../../../utils/FontSize'
import { ChartContainer } from '../../../common/ChartContainer'
import { PieChart } from '../../../common/PieChart'
import { chartFillColor, indicatorMappingDisplayName } from './constants'
import { selectIndicatorType } from './store/slice'

export const ChartComponent = ({
  data,
  width,
  height,
  pieKeys,
  mappingDisplayName,
  loading,
}) => {
  const fontSize = getFontSize(12)

  // Use selector
  const indicatorType = useSelector(selectIndicatorType)

  // Get data
  const getTopLabel = useMemo(() => {
    return `${I18n.t(
      'sector.financialAnalysis.bank.assetQuality.LOAN_FOR',
    )} ${I18n.t(indicatorMappingDisplayName[indicatorType])}`
  }, [indicatorType])

  return (
    <div id="inDepthComparisonChart" style={{ height: height || 0 }}>
      <ChartContainer
        id="inDepthComparisonChartContainer"
        data={data ? [data] : []}
        height={height}
        width={width}
        topLabel={getTopLabel}
        isLoading={loading}
      >
        {(resizeSize) => (
          <PieChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            pieKeys={pieKeys}
            fontSize={fontSize}
            mappingDisplayName={mappingDisplayName}
            isFooterColumn
          />
        )}
      </ChartContainer>
    </div>
  )
}
