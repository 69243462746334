import PropTypes from 'prop-types'
import { useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import { changeFilter, selectFilters } from '../store/slice'
import style from './index.module.css'

const FilterOptionItem = ({ data }) => {
  const parentDropdownRef = useRef()
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)

  const getOptions = useMemo(() => {
    if (Array.isArray(data.options)) {
      return data.options
    } else if (data.field === 'unit') {
      return Object.keys(data.options[filters.currency]).map((key) => ({
        name: `fund.fundProfile.report.${key}`,
        value: data.options[filters.currency][key],
      }))
    } else {
      return Object.keys(data.options).map((key) => ({
        name: `fund.fundProfile.report.${key}`,
        value: data.options[key],
      }))
    }
  }, [filters.currency, data.options])

  const onSelectItem = (value) => {
    dispatch(changeFilter({ [data.field]: value }))
  }

  return (
    <div
      className={`input-dropdown ${style.inputDropdown}`}
      ref={parentDropdownRef}
    >
      <Span style={{ fontSize: 10 }} className={style.titleDropdown}>
        <Translate value={data.title} />
      </Span>
      <SelectCustom
        isI18n={true}
        selectData={getOptions}
        value={filters[data.field]}
        handleChange={onSelectItem}
        appendStyle={{ fontWeight: 'normal' }}
      />
    </div>
  )
}

FilterOptionItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FilterOptionItem
