import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetICB from '../../../common/masterData/useGetICB'
import { Table } from '../../../common/table'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { typeRowGroup } from '../constant'
import { getIcbId, getRowGroup } from '../helper'
import style from '../index.module.css'
import {
  changeActiveRow,
  changeSectorInDepthId,
  keys,
  selectLastActiveRow,
  selectLoading,
  selectSectorInDepthCell,
  selectSortSectorInDepthId,
  sortByLevel,
} from '../store/slice'
import { getLastestPriceThunk } from '../store/thunk'
import { resetDefaultActiveRow } from './ContentTableSector'
import HandleStateTableSector1D from './HandleStateTableSector1D'
import { formatTableSchema1D } from './helpers'

const STICKY_BOTTOM_ROW = 3

const ContentTableSector1D = ({ level, setLevel }) => {
  const dispatch = useDispatch()
  const tableRef = useRef()
  const { ICBs, ICBById, loading: loadingICB } = useGetICB(true)

  // Use selector
  const loading = useSelector(selectLoading(keys.SECTOR_IN_DEPTH))
  const indexInfo = useSelector(selectIndexInfo)
  const lastActiveRow = useSelector(selectLastActiveRow)
  const sortSectorInDepthId = useSelector(selectSortSectorInDepthId)

  // Use state
  const [rowsCollapse, setRowsCollapse] = useState([])
  const [levelCollapse, setLevelCollapse] = useState({})
  const [defaultActiveRow, setDefaultActiveRow] = useState()
  const [idsDisplay, setIdsDisplay] = useState([])

  // Actions
  const onRowCollapse = (id) => {
    const { children = [] } = ICBById[getIcbId(id)]
    const newRowsCollapse = [...rowsCollapse]
    if (newRowsCollapse.includes(id)) {
      const arrKey = Object.keys(levelCollapse)
      let index = arrKey.indexOf(id) + 1
      const arrChildren = []
      while (levelCollapse[arrKey[index]]?.level > levelCollapse[id]?.level) {
        if (
          levelCollapse[arrKey[index]].level ===
          levelCollapse[id].level + 1
        ) {
          arrChildren.push(arrKey[index])
        }
        index += 1
      }
      dispatch(
        getLastestPriceThunk({
          arrIcbId: arrChildren.map((item) => getIcbId(item)),
          groupId: indexInfo.groupId,
        }),
      )

      newRowsCollapse.splice(rowsCollapse.indexOf(id), 1)
      setRowsCollapse(newRowsCollapse)
      setIdsDisplay([
        ...idsDisplay,
        ...children.map((item) => `ICB-${item.icbId}`),
      ])
    } else {
      newRowsCollapse.push(id)
      setRowsCollapse(newRowsCollapse)
      setIdsDisplay([
        ...idsDisplay.filter(
          (id) => !children.map((item) => `ICB-${item.icbId}`).includes(id),
        ),
      ])
    }
  }

  // Get data
  const getSchema = useMemo(() => {
    return formatTableSchema1D({
      tableRef,
      level,
      levelCollapse,
      rowsCollapse,
      setLevel,
      onRowCollapse,
      selectSectorInDepthCell,
    })
  }, [
    tableRef,
    level,
    levelCollapse,
    rowsCollapse,
    setLevel,
    onRowCollapse,
    selectSectorInDepthCell,
  ])

  // Use effect
  useEffect(() => {
    if (defaultActiveRow === resetDefaultActiveRow) {
      const idIcbFirst =
        getRowGroup(sortSectorInDepthId[0]) === typeRowGroup.ICB
          ? sortSectorInDepthId[0]
          : ''
      setDefaultActiveRow(lastActiveRow || idIcbFirst) // reset to default to reactive default row
      dispatch(changeActiveRow(idIcbFirst))
    }
  }, [defaultActiveRow])

  useEffect(() => {
    const rows = sortSectorInDepthId.filter((id) =>
      id.includes(typeRowGroup.INDEX),
    )
    ICBs.forEach((icb) => {
      if (icb.icbLevel === level) {
        rows.push(`ICB-${icb.icbId}`)
      }
    })
    setIdsDisplay(rows)
  }, [level, sortSectorInDepthId])

  return (
    <div ref={tableRef} className={`h-100 ${style.tableSectorContainer}`}>
      <HandleStateTableSector1D
        levelCollapse={levelCollapse}
        level={level}
        setDefaultActiveRow={setDefaultActiveRow}
        setLevelCollapse={setLevelCollapse}
        setRowsCollapse={setRowsCollapse}
      />
      <Table
        ids={sortSectorInDepthId}
        idsDisplay={idsDisplay}
        columnDraggable={false}
        getDataFromRedux={selectSectorInDepthCell}
        isLoading={loading || loadingICB}
        changeIds={changeSectorInDepthId}
        rowDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        resizable={false}
        isCollapse={true}
        sort={sortByLevel}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        onRowCollapse={onRowCollapse}
        stickyFirstColumn={true}
        stickyBottomRowCount={STICKY_BOTTOM_ROW}
        changeActiveRow={changeActiveRow}
        disableClickGroups={sortSectorInDepthId.filter(
          (id) => getRowGroup(id) !== typeRowGroup.ICB,
        )}
        defaultActiveRowId={defaultActiveRow}
        schema={getSchema}
      />
    </div>
  )
}

ContentTableSector1D.propTypes = {
  iCBs: PropTypes.array.isRequired,
  loadingICB: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
  setLevel: PropTypes.func.isRequired,
}

export default ContentTableSector1D
