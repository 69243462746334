export const priceDataTableCol = {
  overview: {
    DATE: 'tradingDateId',
    UNMATCH_BUY_VOL: 'unmatchedBuyVolume',
    UNMATCH_SELL_VOL: 'unmatchedSellVolume',
    NR_BUY_ORDER: 'nrOfBuyOrder',
    BUY_VOL: 'buyVolume',
    VOL_BUY_ORDER: 'volumeBuyOrder',
    NR_SELL_ORDER: 'nrOfSellOrder',
    SELL_VOL: 'sellVolume',
    VOL_SELL_ORDER: 'volumeSellOrder',
    NET_VOL: 'netVolume',
  },
  detail: {
    DATE: 'tradingDateId',
    TICKER: 'ticker',
    UNMATCH_BUY_VOL: 'unmatchedBuyVolume',
    UNMATCH_SELL_VOL: 'unmatchedSellVolume',
    NR_BUY_ORDER: 'nrOfBuyOrder',
    BUY_VOL: 'buyVolume',
    VOL_BUY_ORDER: 'volumeBuyOrder',
    NR_SELL_ORDER: 'nrOfSellOrder',
    SELL_VOL: 'sellVolume',
    VOL_SELL_ORDER: 'volumeSellOrder',
    NET_VOL: 'netVolume',
  },
}

export const tableFormatValue = {
  unmatchedBuyVolume: 10 ** 3,
  unmatchedSellVolume: 10 ** 3,
  nrOfBuyOrder: 1,
  buyVolume: 10 ** 3,
  volumeBuyOrder: 1,
  nrOfSellOrder: 1,
  sellVolume: 10 ** 3,
  volumeSellOrder: 1,
  netVolume: 10 ** 3,
}
