import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { IndexSectorTickerTopInfo } from '../../../common/topInfo/indexSectorTickerTopInfo'
import Content from './Content'
import FooterValuation from './FooterValuation'

export const Valuation = () => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <div
                className="position-relative w-100"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollComponent>
                  <SizeTracker>
                    {(size2) => {
                      return (
                        <>
                          <IndexSectorTickerTopInfo title="market.marketInDepth.valuation.LB_VALUATION" />
                          {size2.height && (
                            <div
                              className="position-relative w-100"
                              style={{
                                height: `calc(100% - ${size2.height}px)`,
                              }}
                            >
                              <Content />
                            </div>
                          )}
                        </>
                      )
                    }}
                  </SizeTracker>
                </ScrollComponent>
              </div>
            )}
            <FooterValuation />
          </>
        )
      }}
    </SizeTracker>
  )
}
