import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'
import { selectFilterCorrelationOfMarket } from '../store/slice'

const CorrelationOfMarketChartTooltip = ({ dataChart }) => {
  const { isSector } = useSelector(selectFilterCorrelationOfMarket)
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {isSector ? dataChart?.icbName : dataChart?.organizationName}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.correlationOfSecondaryMarket.AVG_YTM" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart?.averageYTM)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>{'%'}</Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.correlationOfSecondaryMarket.REMAINING_DURATION" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart?.averageRemain)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.averageDurationBySector.YEAR" />
          </Span>
        </div>
      </div>
      {!isSector && (
        <div className="d-flex ali-center justify-content-space-between">
          <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
            <Translate value="bond.corporateBond.correlationOfSecondaryMarket.TOTAL_TRADING_VALUE" />
          </Span>
          <div className="d-flex ali-center">
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {formatVal(dataChart?.totalValue)}
            </Span>
            &nbsp;
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              <Translate value="bond.corporateBond.interestAndPrincipalPaid.UNIT" />
            </Span>
          </div>
        </div>
      )}
    </div>
  )
}

CorrelationOfMarketChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    icbName: PropTypes.string.isRequired,
  }).isRequired,
}

export default CorrelationOfMarketChartTooltip
