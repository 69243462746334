import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import {
  FORMAT,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../../../../utils/Datetime'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { MARKET_STATISTIC, MARKET_STATISTIC_TABS } from '../constants'
import { formatDataTable } from '../helper'
import {
  fetchListCompany,
  getLiquidityData,
  getListCompany,
  getTradingStatistics,
  getValueChartData,
} from './thunk'

const defaultFilter = {
  icbIds: [],
  tradingStatusId: [2, 4],
  issueMethodId: [5, 6],
  isCleanPrice: false,
  page: 1,
  pageSize: 20,
  timeRange: 'OneDay',
  totalPage: 1,
  From: '',
  To: '',
}

export const keys = {
  LIQUIDITY: 'liquidity',
  VALUE: 'value',
}
const defaultModal = {
  tradingDate: getCurrentDateTime(FORMAT.DATE),
  paymentDate: getDateTimeFrom(new Date(), +1, 'day', FORMAT.DATE),
  volume: '',
  totalParValue: '',
  ytm: '',
  dirtyPrice: '',
  cleanPrice: '',
}

const initialState = {
  loading: false,
  loadingTable: false,
  loadingBondList: false,
  loadingIcbIds: true,
  loadingBond: true,
  loadingLiquidity: false,
  loadingValueChart: false,
  activeTab: MARKET_STATISTIC_TABS[0].value,
  filter: defaultFilter,
  filterLiquidity: 'Daily',
  filterValueChart: {
    range: 'ThreeMonths',
    type: 'ytm',
    From: new Date(),
    To: new Date(),
  },
  isExchangePrice: true,
  dataLiquidity: [],
  dataValueChart: [],
  data: {
    data: [],
    ids: [],
    dataById: {},
    initialIds: [],
    levels: [],
    markIds: [],
    bondList: [],
  },
  enableInfinity: true,
  item: null,
  detail: null,
  modal: defaultModal,
  bondInfo: [],
  search: {
    data: [],
    page: 1,
    pageSize: 10,
    enableInfinity: true,
  },
  filterSort: {
    SortBy: 'tradingDateId',
    SortOrder: 1,
  },
  loadingFilterCompany: false,
  listFilterCompany: [],
  selectCompany: [],
  currentPageCompany: 0,
  enableScroll: false,
  listTextCompany: [],
}

export const slice = createSlice({
  name: 'bond/corporateBond/secondaryMarket/tradingStatistic',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeActiveTab(state, action) {
      state.activeTab = action.payload
    },

    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } = state.data

      const idsFromProps = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      )

      state.data = {
        ...state.data,
        ids: idsFromProps,
      }
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      }
    },
    changeFilterLiquidity: (state, action) => {
      state.filterLiquidity = action.payload.value
    },
    changeFilterSort: (state, action) => {
      state.filterSort = action.payload
    },
    changeFilterValueChart: (state, action) => {
      state.filterValueChart = {
        ...state.filterValueChart,
        [action.payload.key]: action.payload.value,
      }
    },
    resetFilter(state, action) {
      state.filter = cloneDeep({ ...defaultFilter, icbIds: action.payload })
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    changeMarkIds(state, action) {
      state.data = {
        ...state.data,
        markIds: action.payload,
      }
    },
    changeFilterIcbIds: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      }
      state.loadingIcbIds = false
    },
    changeLoadingIcbIds: (state, action) => {
      state.loadingIcbIds = action.payload
    },
    changeKeySort: (state, action) => {
      state.keySort = {
        ...state.keySort,
        [action.payload.key]: action.payload.value,
      }
    },
    resetIds: (state) => {
      state.data = {
        ...state.data,
        ids: state.data.initialIds,
      }
    },
    changeItemSelected: (state, action) => {
      state.item = action.payload
    },
    changeBondDetail: (state, action) => {
      state.detail = action.payload
    },
    changeCouponByBondId: (state, action) => {
      const indexOfBondData = state.data.data.findIndex(
        (item) => (item.bondId = action.payload.id),
      )

      if (indexOfBondData !== -1) {
        state.data = {
          ...state.data,
          data: [
            ...state.data.data.slice(0, indexOfBondData),
            {
              ...state.data.data[indexOfBondData],
              couponValue: action.payload.couponValue,
            },
            ...state.data.data.slice(indexOfBondData + 1),
          ],
        }
      }
    },
    changeModalData: (state, action) => {
      state.modal = {
        ...state.modal,
        [action.payload.key]: action.payload.value,
      }
    },
    resetModalData: (state) => {
      state.modal = cloneDeep(defaultModal)
    },
    changeLoadingBondList: (state, action) => {
      state.loadingBondList = action.payload
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },
    resetBondListData: (state, action) => {
      state.search = {
        ...state.search,
        data: [],
      }
    },
    subscribeBondBidAskId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.id === parseInt(realtimeData?.[0]),
      )

      if (index !== -1 && state.activeTab === MARKET_STATISTIC.ORDER_MATCHING) {
        const newData = [
          ...state.data.data.slice(0, index),
          {
            ...state.data.data[index],
            bidPrice: parseFloat(realtimeData?.[2] ?? 0),
            bidYtm: parseFloat(realtimeData?.[3] ?? 0),
            bidVolume: parseFloat(realtimeData?.[4] ?? 0),
            bidPrice2: parseFloat(realtimeData?.[5] ?? 0),
            bidYtm2: parseFloat(realtimeData?.[6] ?? 0),
            bidVolume2: parseFloat(realtimeData?.[7] ?? 0),
            bidPrice3: parseFloat(realtimeData?.[8] ?? 0),
            bidYtm3: parseFloat(realtimeData?.[9] ?? 0),
            bidVolume3: parseFloat(realtimeData?.[10] ?? 0),
            askPrice: parseFloat(realtimeData?.[11] ?? 0),
            askYtm: parseFloat(realtimeData?.[12] ?? 0),
            askVolume: parseFloat(realtimeData?.[13] ?? 0),
            askPrice2: parseFloat(realtimeData?.[14] ?? 0),
            askYtm2: parseFloat(realtimeData?.[15] ?? 0),
            askVolume2: parseFloat(realtimeData?.[16] ?? 0),
            askPrice3: parseFloat(realtimeData?.[17] ?? 0),
            askYtm3: parseFloat(realtimeData?.[18] ?? 0),
            askVolume3: parseFloat(realtimeData?.[19] ?? 0),
          },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondBidAskDealId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.id === parseInt(realtimeData?.[0]),
      )

      if (index !== -1 && state.activeTab === MARKET_STATISTIC.PUT_THROUGH) {
        const newData = [
          ...state.data.data.slice(0, index),
          {
            ...state.data.data[index],
            bidPrice: parseFloat(realtimeData?.[2] ?? 0),
            bidYtm: parseFloat(realtimeData?.[3] ?? 0),
            bidVolume: parseFloat(realtimeData?.[4] ?? 0),
            askPrice: parseFloat(realtimeData?.[11] ?? 0),
            askYtm: parseFloat(realtimeData?.[12] ?? 0),
            askVolume: parseFloat(realtimeData?.[13] ?? 0),
            totalHighVolumeBid: parseFloat(realtimeData?.[20] ?? 0),
            totalHighVolumeAsk: parseFloat(realtimeData?.[21] ?? 0),
          },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.id === parseInt(realtimeData?.[0]),
      )

      if (index !== -1) {
        const newData = [
          ...state.data.data.slice(0, index),
          state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
            ? {
                ...state.data.data[index],
                referencePrice: parseFloat(realtimeData?.[3] ?? 0),
                ytm:
                  parseFloat(realtimeData?.[8] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                matchedPrice: parseFloat(realtimeData?.[7] ?? 0),
                matchedYtm: parseFloat(realtimeData?.[8] ?? 0),
                matchedVolume: parseFloat(realtimeData?.[19] ?? 0),
                matchedValue: parseFloat(realtimeData?.[20] ?? 0),
                highPrice: parseFloat(realtimeData?.[13] ?? 0),
                highYtm: parseFloat(realtimeData?.[14] ?? 0),
                lowPrice: parseFloat(realtimeData?.[15] ?? 0),
                lowYtm: parseFloat(realtimeData?.[16] ?? 0),
                totalMatchedValue: parseFloat(realtimeData?.[21] ?? 0),
                avgPrice: parseFloat(realtimeData?.[33] ?? 0),
                avgYtm: parseFloat(realtimeData?.[34] ?? 0),
              }
            : {
                ...state.data.data[index],
                referencePrice: parseFloat(realtimeData?.[3] ?? 0),
                ytm:
                  parseFloat(realtimeData?.[24] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                matchedPrice: parseFloat(realtimeData?.[23] ?? 0),
                matchedYtm: parseFloat(realtimeData?.[24] ?? 0),
                matchedVolume: parseFloat(realtimeData?.[25] ?? 0),
                matchedValue:
                  parseFloat(realtimeData?.[23] ?? 0) *
                  parseFloat(realtimeData?.[25] ?? 0),
                totalDealVolume: parseFloat(realtimeData?.[26] ?? 0),
                totalDealValue: parseFloat(realtimeData?.[27] ?? 0),
              },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    updateSelectCompany: (state, action) => {
      state.selectCompany = action.payload
    },
    updatePageCompany: (state, action) => {
      state.currentPageCompany = action.payload
    },
    updateEnableCompany: (state, action) => {
      state.enableScroll = action.payload
    },
    updatelistTextCompany: (state, action) => {
      state.listTextCompany = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getTradingStatistics.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTradingStatistics.fulfilled, (state, action) => {
      const { data, type, hasNextPage } = action.payload

      const dataFormatted = formatDataTable({ data, type })
      const ids = dataFormatted.map((item) => item.id)

      const bookmark = localStorage.getItem('price_board_bookmark')
      const markIds = bookmark ? JSON.parse(bookmark) : []

      state.data = {
        // ...state.data,
        data: dataFormatted,
        ids,
        initialIds: ids,
        dataById: keyBy(dataFormatted, 'id'),
        levels: dataFormatted,
        markIds,
      }
      state.filter = {
        ...state.filter,
        page: 1,
      }
      state.filter.totalPage = action.payload.totalPage
      state.filter.page = action.payload.page
      state.enableInfinity = hasNextPage
      state.loading = false
    })
    builder.addCase(getTradingStatistics.rejected, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(getLiquidityData.pending, (state) => {
      state.loadingLiquidity = true
    })
    builder.addCase(getLiquidityData.fulfilled, (state, action) => {
      state.loadingLiquidity = false
      state.dataLiquidity = action.payload
    })
    builder.addCase(getLiquidityData.rejected, (state, action) => {
      state.loadingLiquidity = action.payload
    })
    builder.addCase(getValueChartData.pending, (state) => {
      state.loadingValueChart = true
    })
    builder.addCase(getValueChartData.fulfilled, (state, action) => {
      state.loadingValueChart = false
      state.dataValueChart = action.payload
    })
    builder.addCase(getValueChartData.rejected, (state, action) => {
      state.loadingValueChart = action.payload
    })
    builder.addCase(getListCompany.pending, (state) => {
      state.loadingFilterCompany = true
    })
    builder.addCase(getListCompany.fulfilled, (state, action) => {
      state.loadingFilterCompany = false
      state.listFilterCompany = action.payload
      state.currentPageCompany = 1
      state.enableScroll = false
    })
    builder.addCase(getListCompany.rejected, (state, action) => {
      state.loadingFilterCompany = action.payload
    })

    builder.addCase(fetchListCompany.pending, (state) => {
      state.loadingFilterCompany = true
    })
    builder.addCase(fetchListCompany.fulfilled, (state, action) => {
      state.loadingFilterCompany = false
      state.listFilterCompany = [
        ...state.listFilterCompany,
        ...action.payload.data,
      ]
      state.currentPageCompany = action.payload.page
      if (action.payload.data.length < 20) {
        state.enableScroll = true
      }
    })
    builder.addCase(fetchListCompany.rejected, (state, action) => {
      state.loadingFilterCompany = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectLoadingLiquidity = (state) =>
  state[slice.name].loadingLiquidity
export const selectLoadingValueChart = (state) =>
  state[slice.name].loadingValueChart
export const selectLoadingTable = (state) => state[slice.name].loadingTable
export const selectLoadingIcbIds = (state) => state[slice.name].loadingIcbIds
export const selectLoadingBondList = (state) =>
  state[slice.name].loadingBondList
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectFilterPriceBoard = (state) => state[slice.name].filter
export const selectIsExchangePrice = (state) =>
  state[slice.name].isExchangePrice
export const selectDataTradingStatistics = (state) => state[slice.name].data
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.dataById[id], attr)
export const selectEnableInfinity = (state) => state[slice.name].enableInfinity
export const selectKeySort = (state) => state[slice.name].keySort
export const selectItemSelected = (state) => state[slice.name].item
export const selectBondDetailSelected = (state) => state[slice.name].detail
export const selectModalData = (state) => state[slice.name].modal
export const selectBondInfo = (state) => state[slice.name].bondInfo
export const selectSearchFilter = (state) => state[slice.name].search
export const selectFilterLiquidity = (state) =>
  state[slice.name].filterLiquidity
export const selectFilterValueChart = (state) =>
  state[slice.name].filterValueChart
export const selectDataLiquidity = (state) => state[slice.name].dataLiquidity
export const selectDataValueChart = (state) => state[slice.name].dataValueChart
export const selectCurrentPage = (state) => state[slice.name].filter.page
export const selectTotalPage = (state) => state[slice.name].filter.totalPage
export const selectFilterSort = (state) => state[slice.name].filterSort
export const selectLoadingFilterCompany = (state) =>
  state[slice.name].loadingFilterCompany
export const selectListFilterCompany = (state) =>
  state[slice.name].listFilterCompany
export const selectidsCompany = (state) => state[slice.name].selectCompany
export const selectCurrentPageCompany = (state) =>
  state[slice.name].currentPageCompany
export const selectEnableCScrollCompany = (state) =>
  state[slice.name].enableScroll

export const selectlistTextCompany = (state) =>
  state[slice.name].listTextCompany

export const {
  resetStore,
  sort,
  changePage,
  changeActiveTab,
  changeFilter,
  changeFilterLiquidity,
  changeFilterSort,
  changeFilterValueChart,
  changeIsExchangePrice,
  resetFilter,
  setLoading,
  changeMarkIds,
  changeFilterIcbIds,
  changeKeySort,
  resetIds,
  changeItemSelected,
  changeBondDetail,
  changeCouponByBondId,
  changeModalData,
  resetModalData,
  changeLoadingBondList,
  resetBondListData,
  subscribeBondBidAskId,
  subscribeBondId,
  changeLoadingIcbIds,
  subscribeBondBidAskDealId,
  updateSelectCompany,
  updateEnableCompany,
  updatePageCompany,
  updatelistTextCompany,
} = slice.actions

register(slice.name, slice.reducer)
