import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { Table } from '../../../../common/table'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { TYPE_FORMAT } from '../../common/constants'
import FirstColumnCell from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import {
  changeIndicator,
  selectFDIByCountriesIds,
  selectFDIByCountriesValue,
  selectFilterStatus,
  selectGroupColumns,
  selectLevelCollapse,
  selectLoading,
  selectLocationChecked,
  selectMappingLoading,
  selectPayloadCallBack,
  selectTimeFrequency,
  selectTypeFormatData,
  sort,
} from './store/slice'
import { getTableData } from './store/thunk'

const TableComponent = ({ setSortField, isFirstTime, setIsFirstTime }) => {
  const dispatch = useDispatch()

  const isLoadingIndicator = useSelector(selectLoading)
  const ids = useSelector(selectFDIByCountriesIds)
  const levelCollapse = useSelector(selectLevelCollapse)
  const groupColumns = useSelector(selectGroupColumns)
  const filterStatus = useSelector(selectFilterStatus)
  const timeFrequency = useSelector(selectTimeFrequency)
  const locationChecked = useSelector(selectLocationChecked)
  const payloadCallBack = useSelector(selectPayloadCallBack)
  const isMappingLoading = useSelector(selectMappingLoading)
  const typeFormatData = useSelector(selectTypeFormatData)

  const [rowsCollapse, setRowsCollapse] = useState([])

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  const scrollToLeftCallback = () => {
    if (payloadCallBack.month && payloadCallBack.year) {
      setTimeout(() => {
        dispatch(
          getTableData({
            FDITypeId: filterStatus,
            TimeFrequency: timeFrequency,
            Year: payloadCallBack.year,
            Month: payloadCallBack.month,
            NumberOfPeriod: 5,
          }),
        )
      })
      setIsFirstTime(false)
    }
  }

  if ((isMappingLoading || isLoadingIndicator) && isFirstTime) {
    return <Loading />
  }

  return (
    <Table
      ids={ids}
      columnDraggable={false}
      rowDraggable={false}
      getDataFromRedux={selectFDIByCountriesValue}
      isLoading={isMappingLoading || isLoadingIndicator}
      schema={groupColumns.map((item, index) => {
        const title = I18n.t(item.title)
        const colId = item.key
        const result = {
          colId,
          title,
        }
        if (index === 0) {
          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'left',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, { style, className }) => {
              return (
                <FirstColumnCell
                  val={val}
                  rowId={rowId}
                  style={style}
                  className={className}
                  onRowCollapse={onRowCollapse}
                />
              )
            },
          }
        }
        return {
          ...result,
          isI18n: true,
          thStyle: {
            textAlign: 'right',
            fontSize: 10,
          },
          canCustomTd: true,
          render: (val, rowId, { style, className }) => {
            return (
              <OtherColumnsCell
                val={
                  isLoadingIndicator
                    ? EMPTY_VALUE
                    : typeFormatData === TYPE_FORMAT.CAPITAL
                    ? formatVal(valDivMillion(val))
                    : val
                }
                rowId={rowId}
                className={className}
              />
            )
          },
        }
      })}
      stickyFirstColumn={true}
      hasFooter={false}
      isCollapse={true}
      rowsCollapse={rowsCollapse}
      levelCollapse={levelCollapse}
      changeActiveRow={changeIndicator}
      defaultActiveRowId={locationChecked?.locationId || ids[0]}
      scrollToLeftCallback={scrollToLeftCallback}
      isLazyLoadLeft={true}
      defaultScrollToRight={isFirstTime}
      sort={sort}
      handleSortField={setSortField}
    />
  )
}

export default TableComponent
