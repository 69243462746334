import PropTypes from 'prop-types'
import style from '../panelTable/toiTable/toiTable.module.css'

const Row = ({ children, styleProps, isActive, className }) => {
  return (
    <tr
      style={styleProps}
      className={`${isActive && style.tableDataActive} ${className}`}
    >
      {children}
    </tr>
  )
}

Row.propTypes = {
  children: PropTypes.node,
  styleProps: PropTypes.object,
  isActive: PropTypes.bool,
  className: PropTypes.string,
}

export default Row
