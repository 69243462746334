import React, { useMemo } from 'react'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { removeAccents, removeSpecialCharacter } from '../../../../utils/Search'
import MetricItemTree from './MetricItemTree'
import style from './style.module.css'

const Metric = ({
  treeData,
  category,
  keyword,
  onGetIndicatorLatestPeriod,
}) => {
  const loading = false

  const getIndicatorsWithCategory = () => {
    if (keyword.trim()) {
      return Object.keys(treeData[category]).reduce((obj, key) => {
        const item = treeData[category][key]
        const isValid = removeSpecialCharacter(removeAccents(item.name))
          ?.toLowerCase()
          .includes(
            removeSpecialCharacter(removeAccents(keyword)).trim().toLowerCase(),
          )
        return isValid ? { ...obj, [key]: item } : obj
      }, {})
    } else {
      return treeData[category]
    }
  }

  const getIndicatorsWithoutCategory = () => {
    if (keyword.trim()) {
      return Object.values(treeData).reduce(
        (obj, item) => ({
          ...obj,
          ...Object.keys(item).reduce((childObj, key) => {
            const isValid = removeSpecialCharacter(
              removeAccents(item[key].name),
            )
              ?.toLowerCase()
              .includes(
                removeSpecialCharacter(removeAccents(keyword))
                  .trim()
                  .toLowerCase(),
              )
            return isValid ? { ...childObj, [key]: item[key] } : childObj
          }, {}),
        }),
        {},
      )
    } else {
      return {}
    }
  }

  const getIndicators = useMemo(() => {
    if (!category) {
      return getIndicatorsWithoutCategory()
    } else {
      return getIndicatorsWithCategory()
    }
  }, [treeData, category, keyword])

  return (
    <div className={style.metric}>
      <div className={style.categoryList}>
        <ScrollComponent bgColor="#D9D9D9">
          {loading ? (
            <Loading isBlack />
          ) : getIndicators && Object.values(getIndicators).length ? (
            <>
              {Object.values(getIndicators).map((item) => {
                return (
                  <MetricItemTree
                    key={item.id}
                    data={item}
                    category={category}
                    onGetIndicatorLatestPeriod={onGetIndicatorLatestPeriod}
                  />
                )
              })}
            </>
          ) : (
            <NoData />
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default Metric
