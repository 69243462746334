import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import NetValueOfInternalTrading from './netValueOfInternalTrading'
import { selectStateTimeFrame } from './netValueOfInternalTrading/store/slice'

const PanelNetValueOfInternalTrading = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const indexInfo = useSelector(selectIndexInfo)
  const nameScreen = UseI18n(
    'constants.route.market.marketInDepth.directorDeal.LABEL',
  )
  const chartName = UseI18n(
    'market.marketInDepth.directorDeal.NET_VALUE_OF_INTERNAL_TRADING',
  )
  const tabName = useSelector(selectStateTimeFrame)

  return (
    <Panel
      title={
        I18n.t(
          'market.marketInDepth.directorDeal.NET_VALUE_OF_INTERNAL_TRADING_OF',
        ) +
        ' ' +
        indexInfo.groupName
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={
        UseI18n(
          'market.marketInDepth.directorDeal.NET_VALUE_OF_INTERNAL_TRADING_OF',
        ) +
        ' ' +
        indexInfo.groupName
      }
      downloadJpgParams={{
        domId: 'netValueOfInternalTrading',
        nameScreen,
        chartName,
        tabName,
        tickerName: indexInfo?.groupCode,
      }}
    >
      <NetValueOfInternalTrading
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelNetValueOfInternalTrading
