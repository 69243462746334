import PropTypes from 'prop-types'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FEATURES } from '../../constants/routes'
import { getStockIndicesSectorInfo, highlightString } from '../../utils/Search'
import style from './index.module.css'

export const MarDerivativesItem = (props) => {
  const { searchStr, data, getBasicInfo } = props
  const navigate = useCustomNavigate()

  const getTicker = () => {
    return highlightString(data.ticker, data.highlight.ticker, searchStr)
  }

  const getDerivativeName = () => {
    let derivativeName = ''
    let derivativeNameHighlight
    if (data.derivativeName) {
      derivativeName = data.derivativeName
      derivativeNameHighlight = data.highlight.derivativeName
    } else if (data.en_DerivativeName) {
      derivativeName = data.en_DerivativeName
      derivativeNameHighlight = data.highlight.en_DerivativeName
    } else {
      return
    }
    return highlightString(derivativeName, derivativeNameHighlight, searchStr)
  }

  const getDerivativeUrl = () => {
    if (data.groupCode === 'HOSE') {
      return FEATURES.market.components['covered-warrant'].components.overview
        .route
    } else if (data.groupCode === 'HNX') {
      return FEATURES.market.components.derivative.route
    } else {
      return ''
    }
  }

  const onRedirect = () => {
    navigate(getDerivativeUrl(), getStockIndicesSectorInfo(data, getBasicInfo))
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div className={`search-content ${style['search-content']}`}>
        <div className={style['item-title']}>{getTicker()}</div>
        <div className="date">
          {data.groupCode && <span>{data.groupCode}/ </span>}
          <span>{getDerivativeName()}</span>
        </div>
      </div>
    </a>
  )
}

MarDerivativesItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
