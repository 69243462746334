import { TIME_FREQUENCY } from '../../common/constants'
import { titleData } from '../../common/helper'

export const convertData = (arr, timeFrequency) => {
  const NO_OF_NEW_APPROVAL_PROJECT_ID = 14
  let objList = {}
  const result = []
  arr.forEach((item) => {
    objList[titleData(item.month, item.year, timeFrequency)] = {
      ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
      [`fdiTypeId_${item.fdiTypeId}`]:
        item.fdiTypeId === NO_OF_NEW_APPROVAL_PROJECT_ID
          ? item.value
          : item.value / 1000000,
    }
  })
  Object.keys(objList).map((key) =>
    result.push({
      XAxis: key,
      ...objList[key],
    }),
  )

  return timeFrequency === TIME_FREQUENCY.YEARLY ? result : result.reverse()
}
