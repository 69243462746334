import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'

export const getNews = createAsyncThunk(
  'sector/sectorSpecific/steel/overviewSteel/newsAndReport/news',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getNews(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getReports = createAsyncThunk(
  'sector/sectorSpecific/steel/overviewSteel/newsAndReport/reports',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getReports(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
