import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import { DropdownItem } from './DropdownItem'
import { PriceData } from './PriceData'
import { SIMPLE_SEARCH_PARAMS } from './constants'
import style from './index.module.css'
import {
  changeLoadingSearch,
  selectIndexInfo,
  selectListIndex,
  selectLoadingSearch,
} from './store/slice'
import { getSearchIndex } from './store/thunk'

export const TopInfo = ({ title, filterIndex, customIndex }) => {
  const dispatch = useDispatch()

  const location = useLocation()
  const navigate = useCustomNavigate()

  const indexInfo = useSelector(selectIndexInfo)
  const isLoadingSearch = useSelector(selectLoadingSearch)
  const listIndex = useSelector(selectListIndex)

  useHeaderSearch(TYPE_HEADER.INDEX, false, filterIndex, customIndex)

  const [search, setSearch] = useState('')

  const options = !isLoadingSearch
    ? customIndex
      ? customIndex.reduce((arr, index) => {
          if (search) {
            return index.groupName.toLowerCase().includes(search) ||
              index.exchangeCode.toLowerCase().includes(search)
              ? [
                  ...arr,
                  {
                    id: index.groupCode,
                    value: { ...index },
                  },
                ]
              : arr
          }
          return [
            ...arr,
            {
              id: index.groupCode,
              value: { ...index },
            },
          ]
        }, [])
      : listIndex
          .filter((indice) => !filterIndex.includes(indice.groupId))
          .map((index) => ({
            id: index.groupCode,
            value: { ...index },
          }))
    : []

  const searchIndex = (keyword) => {
    const params = {
      Term: keyword || undefined,
      Page: SIMPLE_SEARCH_PARAMS.page,
      PageSize: SIMPLE_SEARCH_PARAMS.pageSize,
    }

    if (!customIndex) {
      dispatch(getSearchIndex(params))
    } else {
      setSearch(keyword)
    }
  }

  const handleSearchIndex = (keyword) => {
    searchIndex(keyword.toLowerCase().trim())
  }

  const handleFocusInputSearch = () => {
    searchIndex()
  }

  const handleSelect = (data) => {
    navigate(location.pathname, data)
  }

  const handleChangeLoading = () => {
    if (!customIndex) {
      dispatch(changeLoadingSearch(true))
    }
  }

  return (
    <div className="top-info">
      {title && (
        <>
          <Title title={title} />
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={indexInfo.groupCode}
        className={style.dropSearch}
        description={`${indexInfo.groupName} / ${indexInfo.exchangeCode}`}
        onFocus={handleFocusInputSearch}
        onTextChange={handleSearchIndex}
        options={options}
        loading={isLoadingSearch}
        onSelect={handleSelect}
        classNameInputSearch={style.inputSearch}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
        onChangeLoading={handleChangeLoading}
      />
      <PriceData />
    </div>
  )
}

TopInfo.propTypes = {
  title: PropTypes.string,
  filterIndex: PropTypes.array,
  customIndex: PropTypes.array,
}

TopInfo.defaultProps = {
  filterIndex: [],
}
