import moment from 'moment'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import {
  changeDateFrom,
  changeDateTo,
  changeTimeRange,
  selectFilter,
} from '../store/slice'

const CalendarSearch = () => {
  const dispatch = useDispatch()

  const filterData = useSelector(selectFilter)

  const handleChangeTimeRange = (data) => {
    dispatch(changeTimeRange(data.value))
  }

  const handleChangeCalendar = (payload) => {
    dispatch(changeDateFrom(payload.startDate))
    dispatch(changeDateTo(payload.endDate))
    dispatch(changeTimeRange(payload.activeTab))
  }

  useEffect(() => {
    dispatch(changeDateFrom(moment().subtract(1, 'year').toDate()))
    dispatch(changeDateTo(new Date()))
    dispatch(changeTimeRange(calendarTabKey))
  }, [])

  return (
    <DispatchActionTabWithCalendar
      activeTab={filterData.timeRange}
      startDate={filterData.dateFrom}
      endDate={filterData.dateTo}
      tabs={[
        { title: 'WTD', value: TIME_RANGES.WeekToDate },
        { title: 'MTD', value: TIME_RANGES.MonthToDate },
        { title: 'QTD', value: TIME_RANGES.QuarterToDate },
        { title: 'YTD', value: TIME_RANGES.YearToDate },
        { title: 'common.ALL', value: TIME_RANGES.All },
      ]}
      onChangeTab={handleChangeTimeRange}
      onChangeCalendar={handleChangeCalendar}
    />
  )
}

CalendarSearch.propTypes = {}

export default CalendarSearch
