import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import TextEllipsis from '../../../../../common/textEllipsis'
import { Z_INDEX } from '../../../../../constants/Common'
import { ICB_LEVELS } from '../../constants'
import { changeICBLevel, selectICBLevelData } from './store/slice'
import style from './style.module.css'

const DropdownICBLevel = () => {
  const dispatch = useDispatch()
  const onChange = (val) => {
    dispatch(changeICBLevel(val))
  }

  const ICBLevel = useSelector(selectICBLevelData)

  const selectData = Object.keys(ICB_LEVELS).map((key) => {
    return {
      name: `market.marketInDepth.valuation.${key}`,
      value: ICB_LEVELS[key],
    }
  })

  return (
    <div className="ml-8" style={{ width: '25%' }}>
      <SelectCustom
        value={ICBLevel}
        isI18n={true}
        selectData={selectData}
        handleChange={onChange}
        renderSelect={({ isShowDropdown, styleComponent }) => {
          return (
            <span className={style.select}>
              <span
                className={`${styleComponent.text}`}
                style={{ marginLeft: 0 }}
              >
                <TextEllipsis
                  isI18n={true}
                  text={
                    selectData?.find((item) => item.value === ICBLevel)?.name
                  }
                  zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                />
              </span>
              <i
                className={`${
                  !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
                } ${styleComponent.icon}`}
              />
            </span>
          )
        }}
      />
    </div>
  )
}

export default DropdownICBLevel
