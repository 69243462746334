import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DEFAULT_ORGANIZATION_ID } from '../../../../../../configs/Common'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { COM_GROUP } from '../../../../../constants/Group'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../../bondSecurity/bondSecurity/Filter/store/slice'
import { GROUP_COLUMNS } from '../constants'
import { transferText } from '../helper'
import style from '../index.module.css'
import {
  keys,
  selectDataBondIssueInformation,
  selectDataTableById,
  selectItemDataTable,
  selectLoading,
  sort,
} from '../store/slice'
import TableHeader from './TableHeader'

const TableContent = () => {
  const dispatch = useDispatch()

  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoading(keys.BOND_ISSUE_INFORMATION))
  const data = useSelector(selectItemDataTable)
  const { ids } = useSelector(selectDataBondIssueInformation)
  const locale = useSelector((state) => state.i18n.locale)

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <Table
      ids={ids}
      columnDraggable={false}
      sort={sort}
      getDataFromRedux={selectDataTableById}
      rowDraggable={false}
      isLoading={isLoading}
      renderHeader={(stateSort, sortColumn) => (
        <TableHeader
          tableHeaderColAttr={GROUP_COLUMNS}
          stateSort={stateSort}
          sortColumn={sortColumn}
        />
      )}
      idRowCustomStyle={['Total']}
      rowCustomStyle={{
        position: 'sticky',
        bottom: -1,
        zIndex: 3,
        background: '#1E242E',
      }}
      schema={GROUP_COLUMNS.map((item, index) => {
        const title = item.title
        const colId = item.key
        const result = {
          colId,
          title,
        }

        switch (index) {
          case 0:
            return {
              ...result,
              tdStyle: {
                minWidth: 120,
                maxWidth: 120,
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td {...props} className={style.firstCellColumn}>
                    {!!value?.length &&
                      (rowId === 'Total' ? (
                        <div className={style.itemRowTotal}>
                          <Translate
                            value={value}
                            style={{
                              fontWeight: 600,
                              textTransform: 'uppercase',
                            }}
                          />
                        </div>
                      ) : (
                        <a
                          className="cursor-pointer"
                          onClick={() => redirectToSecurityMaster(rowId)}
                        >
                          <TextEllipsis text={value} isI18n={false} />
                        </a>
                      ))}
                    <div className={style.borderLeftScroll} />
                  </td>
                )
              },
            }

          case 1:
            return {
              ...result,
              tdStyle: {
                minWidth: 170,
                maxWidth: 170,
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td {...props}>
                    <a
                      className="cursor-pointer"
                      onClick={() =>
                        redirectToBondIssuer(
                          data?.[rowId]?.organizationId ??
                            DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                        )
                      }
                    >
                      <TextEllipsis text={value} isI18n={false} />
                    </a>
                  </td>
                )
              },
            }

          case 2:
            return {
              ...result,
              tdStyle: {
                minWidth: 120,
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{transferText(value)}</Span>
                </td>
              ),
            }

          case 3:
            return {
              ...result,
              tdStyle: {
                minWidth: 60,
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{value}</Span>
                </td>
              ),
            }

          case 4:
            return {
              ...result,
              tdStyle: {
                minWidth: 110,
                width: 110,
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{transferText(value)}</Span>
                </td>
              ),
            }

          case 5:
          case 6:
          case 7:
            return {
              ...result,
              tdStyle: {
                minWidth: 90,
                width: 90,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>
                    {rowId !== 'Total'
                      ? formatDateTime(value, FORMAT.DATE, locale)
                      : value}
                  </Span>
                </td>
              ),
            }
          case 8:
            return {
              ...result,
              tdStyle: {
                minWidth: 90,
                width: 90,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{rowId !== 'Total' && formatVal(value * 100)}</Span>
                </td>
              ),
            }

          case 9:
            return {
              ...result,
              tdStyle: {
                minWidth: 130,
                width: 130,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td {...props}>
                    <Span>
                      {rowId === 'Total' ? (
                        <div className={style.itemRowTotal}>
                          {formatVal(value)}
                        </div>
                      ) : (
                        formatVal(value)
                      )}
                    </Span>
                  </td>
                )
              },
            }

          case 10:
            return {
              ...result,
              tdStyle: {
                minWidth: 142,
                width: 142,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{formatVal(value)}</Span>
                </td>
              ),
            }

          case 11:
            return {
              ...result,
              tdStyle: {
                minWidth: 115,
                width: 115,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{rowId !== 'Total' ? formatVal(value) : value}</Span>
                </td>
              ),
            }

          case 12:
            return {
              ...result,
              tdStyle: {
                minWidth: 125,
                width: 125,
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td {...props}>
                    <Span
                      style={{
                        color:
                          data?.[rowId]?.activeStatusId === 15
                            ? '#3DA967'
                            : data?.[rowId]?.activeStatusId === 17
                            ? '#FECF2F'
                            : '#FFFFFF',
                      }}
                    >
                      <TextEllipsis text={value} />
                    </Span>
                  </td>
                )
              },
            }

          case 13:
            return {
              ...result,
              tdStyle: {
                width: 100,
                minWidth: 100,
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>
                    {rowId !== 'Total' ? <TextEllipsis text={value} /> : ''}
                  </Span>
                </td>
              ),
            }

          case 14:
            return {
              ...result,
              tdStyle: {
                width: 100,
                minWidth: 100,
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{value}</Span>
                </td>
              ),
            }

          case 15:
            return {
              ...result,
              tdStyle: {
                minWidth: 135,
                width: 135,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{formatVal(value)}</Span>
                </td>
              ),
            }

          case 16:
          case 17:
          case 18:
          case 19:
            return {
              ...result,
              tdStyle: {
                width: 110,
                minWidth: 110,
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>{formatVal(value)}</Span>
                </td>
              ),
            }

          case 20:
            return {
              ...result,
              tdStyle: {
                minWidth: 70,
                width: 70,
                textAlign: 'center',
              },
              canCustomTd: true,
              render: (value, rowId, props) => (
                <td {...props}>
                  <Span>
                    {!!value.length ? (
                      <a href={value} target="_blank" rel="noreferrer">
                        <Translate value="bond.corporateBond.bondIssueInformation.VIEW" />
                      </a>
                    ) : rowId !== 'Total' ? (
                      formatVal(value)
                    ) : (
                      ''
                    )}
                  </Span>
                </td>
              ),
            }

          default:
            return {}
        }
      })}
      stickyFirstColumn={true}
      isPagination={false}
      hasFooter={false}
      resizable={false}
      hasTooltip={false}
    />
  )
}

export default TableContent
