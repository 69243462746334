import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Routers } from './app/router'
import RootPopup from './components/RootPopup'
import HandleErrorApi from './components/common/HandleErrorApi'
import useWindowDevicePixelRatio from './components/common/hooks/useWindowDevicePixelRatio'
import LoadingScreen from './components/common/loadingScreen'
import MasterData from './components/common/masterData'
import { ErrorBoundary } from './components/errors/ErrorBounding'
import { selectIsShow } from './components/screens/store/slice'
import { isWindowsOs } from './components/utils/Device'
import './styles/css/main.css'
import './styles/fonts/fiinx-v1.0/style.css'

const App = () => {
  const { zoom, devicePixelRatio, reverseFormatWithZoom } =
    useWindowDevicePixelRatio()
  const isShow = useSelector(selectIsShow)

  useEffect(() => {
    if (devicePixelRatio && isWindowsOs()) {
      document.body.style.zoom = `${reverseFormatWithZoom(100)}%`
    } else {
      document.body.style.zoom = `${100 * zoom}%`
    }
  }, [devicePixelRatio, zoom])

  useEffect(() => {
    const obj = { ...localStorage }

    // Object.keys(obj).forEach(
    //   (b) =>
    //     b.includes('oidc') &&
    //     !b.includes('oidc.user') &&
    //     localStorage.removeItem(b),
    // )

    const oidcUserKey = Object.keys(obj).find((key) =>
      key.includes('oidc.user'),
    )
    if (oidcUserKey) {
      const oidcUser = JSON.parse(obj[oidcUserKey])
      if (!oidcUser.refresh_token) {
        localStorage.removeItem(oidcUserKey)
        window.location.reload()
      }
    }
  }, [])

  return (
    <LoadingScreen>
      <ErrorBoundary>
        <Routers />
        <RootPopup />
        <HandleErrorApi />
        {isShow && <MasterData />}
      </ErrorBoundary>
    </LoadingScreen>
  )
}

export default App
