import PropTypes from 'prop-types'
import { Panel } from '.'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { AddNewChart } from './AddNewChart'
import style from './PanelContainer.module.css'

export const SCROLL_ID = 'financialChart/listDashboard'

export const PanelContainer = ({
  id,
  children,
  hasAddNewChart,
  appendStyle,
  widthPanelAddNewChart,
}) => {
  return (
    <div className={style.container} style={appendStyle}>
      <ScrollComponent bgColor="#D9D9D9" id={SCROLL_ID}>
        <div id={id ?? ''} className={style.content}>
          {children}
          {hasAddNewChart && (
            <Panel hasHeader={false} width={widthPanelAddNewChart}>
              <AddNewChart />
            </Panel>
          )}
        </div>
      </ScrollComponent>
    </div>
  )
}

PanelContainer.propTypes = {
  children: PropTypes.node,
  hasAddNewChart: PropTypes.bool,
  appendStyle: PropTypes.object,
  widthPanelAddNewChart: PropTypes.string,
}

PanelContainer.defaultProps = {
  hasAddNewChart: true,
  appendStyle: {},
}
