export const chartFillColor = {
  rT0295: '#1d97ef',
}

export const chartLeftKeys = ['rT0295']

export const chartMappingDisplayName = {
  rT0295: 'sector.financialAnalysis.bank.capitalStructure.CASA_RATIO',
}

export const chartFormat = {
  rT0295: 0.01,
}
