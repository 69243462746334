import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../common/sizeTracker'
import { selectIndicator } from '../summary/store/slice'
import { ChartFilter } from './ChartFilter'
import { tableChartSwitch } from './constants'
import HorizontalStackBarComponent from './HorizontalStackBarComponent'
import { resetState, selectPageSize, selectScrollId } from './store/slice'
import { getTimeAndSales, getTimeAndSalesChart } from './store/thunk'
import TableComponent from './TableComponent'

const TimeAndSalesPanel = ({
  width,
  height,
  viewType,
  setViewType,
  dateType,
  setDateType,
}) => {
  const dispatch = useDispatch()

  const derivativeActive = useSelector(selectIndicator)
  const pageSize = useSelector(selectPageSize)
  const scrollId = useSelector(selectScrollId)

  const [rowTimes, setRowTimes] = useState(1)

  useEffect(() => {
    if (
      derivativeActive?.futureId &&
      viewType === tableChartSwitch.HORIZONTAL_STACK_BAR
    ) {
      dispatch(
        getTimeAndSalesChart({
          FutureId: derivativeActive?.futureId,
          TimeRange: dateType,
        }),
      )
    }
  }, [derivativeActive?.futureId, dateType, viewType])

  useEffect(() => {
    dispatch(
      getTimeAndSales({
        FutureId: derivativeActive?.futureId,
        PageSize: pageSize,
        ScrollId: scrollId,
      }),
    )
  }, [rowTimes])

  useEffect(() => {
    if (derivativeActive?.futureId && viewType === tableChartSwitch.TABLE) {
      dispatch(resetState())
      dispatch(
        getTimeAndSales({
          FutureId: derivativeActive?.futureId,
          PageSize: pageSize,
        }),
      )
    }
  }, [viewType, derivativeActive?.futureId])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <ChartFilter
            viewType={viewType}
            dateType={dateType}
            setViewType={setViewType}
            setDateType={setDateType}
          />
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              <PreOpenMarket>
                {viewType === tableChartSwitch.HORIZONTAL_STACK_BAR ? (
                  <HorizontalStackBarComponent
                    width={width}
                    height={height - size.height}
                  />
                ) : (
                  <TableComponent setRowTimes={setRowTimes} />
                )}
              </PreOpenMarket>
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

export default TimeAndSalesPanel
