export const tableConstants = [
  {
    key: 'ticker',
    title: 'bond.interconnectionMap.table.table.BondTicker',
    attrs: {},
  },
  {
    key: 'outstandingValue',
    title: 'bond.interconnectionMap.table.table.Outstanding',
    additionText: 'bond.interconnectionMap.table.table.unit',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}
