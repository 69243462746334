import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  selectChart1Data,
  selectChart1Loading,
  selectChart2Data,
  selectChart2Loading,
} from '../charts/store/slice'
import { chartBank1, chartBank2 } from '../charts/store/thunk'
import { CHART_ORDER } from '../constants'
import {
  selectFieldName,
  selectFieldName_1,
  selectTimeRange,
  selectYear,
} from '../store/slice'

const useChartAPI = (chartType) => {
  const dispatch = useDispatch()
  const { CHART_1 } = CHART_ORDER

  const basicInfo = useSelector(selectBasicInfo)
  const year = useSelector(selectYear)
  const timeRange = useSelector(selectTimeRange)
  const fieldName = useSelector(selectFieldName)
  const fieldName_1 = useSelector(selectFieldName_1)
  const dataChart1 = useSelector(selectChart1Data)
  const dataChart2 = useSelector(selectChart2Data)
  const loadingChart1 = useSelector(selectChart1Loading)
  const loadingChart2 = useSelector(selectChart2Loading)
  const locale = useSelector((state) => state.i18n.locale)

  const data = chartType === CHART_1 ? dataChart1 : dataChart2
  const loading = chartType === CHART_1 ? loadingChart1 : loadingChart2

  useEffect(() => {
    if (basicInfo.organizationId) {
      if (chartType === CHART_1) {
        dispatch(
          chartBank1({
            OrganizationId: basicInfo?.organizationId,
            Year: year,
            TimeRange: timeRange,
            FieldName: fieldName,
            FieldName_1: fieldName_1,
          }),
        )
      } else {
        dispatch(
          chartBank2({
            OrganizationId: basicInfo?.organizationId,
            Year: year,
            TimeRange: timeRange,
            FieldName: fieldName,
            FieldName_1: fieldName_1,
          }),
        )
      }
    }
  }, [
    basicInfo?.organizationId,
    year,
    timeRange,
    fieldName,
    fieldName_1,
    locale,
  ])

  return [data, loading]
}

export default useChartAPI
