import SelectCustom from '../../../../../common/selectCustom'
import { priceChartTab } from './constants'

export const SelectType = ({ activeTab, onChangeTab }) => {
  return (
    <div style={{ width: 150 }}>
      <SelectCustom
        value={activeTab}
        isI18n={true}
        selectData={Object.keys(priceChartTab).map((key) => ({
          name: `sector.financialAnalysis.corporate.${key}`,
          value: priceChartTab[key],
        }))}
        handleChange={onChangeTab}
      />
    </div>
  )
}
