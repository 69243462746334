import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Filter } from '../../../../common/news/filter'
import {
  changeAnalyticFilter,
  selectAnalyticCategories,
  selectAnalyticFilterData,
  selectAnalyticSources,
} from '../store/slice'
import { getNewsCategoriesAndSourcesAnalytic } from '../store/thunk'

export const AnalyticFilter = () => {
  const dispatch = useDispatch()
  const sources = useSelector(selectAnalyticSources)
  const categories = useSelector(selectAnalyticCategories)
  const filterData = useSelector(selectAnalyticFilterData)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getNewsCategoriesAndSourcesAnalytic())
  }, [locale])

  const changeFilter = (data) => {
    dispatch(changeAnalyticFilter(data))
  }

  return (
    <Filter
      sources={sources}
      categories={categories}
      sourceValue={filterData.source}
      categoryValue={filterData.category}
      filterStartDate={filterData.startDate}
      filterEndDate={filterData.endDate}
      changeFilter={changeFilter}
      time={filterData.time}
    />
  )
}
