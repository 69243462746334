import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { ChannelConfig } from '../../../../../configs/Global'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Realtime } from '../../../../common/Realtime'
import { Table } from '../../../../common/table'
import { TextBlink } from '../../../../common/table/TextBlink'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import { keySchemaIndex, keyTableIndex } from '../constans'
import style from '../index.module.css'
import {
  changeIndexId,
  keys,
  selectAllIndex,
  selectIndexCell,
  selectIndexId,
  selectLoading,
  sortIndex,
  subscribeIndex,
} from '../store/slice'
import { getIndexThunk } from '../store/thunk'
import TdColor from '../TdColor'

const TableIndex = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate()

  const indexId = useSelector(selectIndexId)
  const loading = useSelector(selectLoading(keys.INDEX))
  const allIndex = useSelector(selectAllIndex)

  useEffect(() => {
    dispatch(getIndexThunk())
  }, [])

  const onRowClick = (rowId) => {
    navigate(
      location.pathname,
      allIndex.find((index) => index.groupId === rowId),
    )
  }

  return (
    <>
      <Table
        ids={indexId}
        columnDraggable={false}
        getDataFromRedux={selectIndexCell}
        isLoading={loading}
        changeIds={changeIndexId}
        rowDraggable={false}
        hasFooter={true}
        hasTooltip={false}
        resizable={false}
        stickyFirstColumn={true}
        onRowClick={onRowClick}
        idRowCustomStyle={indexId}
        rowCustomStyle={{ cursor: 'pointer' }}
        sort={sortIndex}
        schema={Object.keys(keyTableIndex).map((key) => {
          const title = `market.marketInDepth.indices.${key}`

          const colId = keyTableIndex[key]
          const result = {
            colId,
            title,
            disableSort: false,
            thStyle: {
              textAlign: key === keySchemaIndex.CODE ? 'left' : 'right',
            },
            tdStyle: {
              height: ![
                keySchemaIndex.CODE,
                keySchemaIndex.TOTAL_VALUE,
                keySchemaIndex.TOTAL_VOLUME,
              ].includes(key)
                ? '100%'
                : undefined,
              textAlign: key !== keySchemaIndex.CODE ? 'right' : undefined,
            },
          }

          return {
            ...result,
            isI18n: false,
            canCustomTd: [
              keySchemaIndex.CLOSE_PRICE,
              keySchemaIndex.PRICE_CHANGE,
              keySchemaIndex.PERCENT_PRICE_CHANGE,
              keySchemaIndex.TOTAL_VOLUME,
              keySchemaIndex.TOTAL_VALUE,
            ].includes(key),
            render: [
              keySchemaIndex.CLOSE_PRICE,
              keySchemaIndex.PRICE_CHANGE,
              keySchemaIndex.PERCENT_PRICE_CHANGE,
            ].includes(key)
              ? (value, colId, { style, className }) => (
                  <TdColor
                    value={
                      key === keySchemaIndex.PERCENT_PRICE_CHANGE
                        ? valToPercent(value)
                        : value
                    }
                    colId={colId}
                    keyColor="priceChange"
                    selectCell={selectIndexCell}
                    isPercent={
                      key === keySchemaIndex.PERCENT_PRICE_CHANGE ? true : false
                    }
                    style={style}
                    className={className}
                  />
                )
              : key === keySchemaIndex.TOTAL_VOLUME
              ? (value) => (
                  <td className={style.textRight}>
                    <TextBlink value={value}>
                      {formatVal(valDivThousand(value))}
                    </TextBlink>
                  </td>
                )
              : key === keySchemaIndex.TOTAL_VALUE
              ? (value) => (
                  <td className={style.textRight}>
                    <TextBlink value={value}>
                      {formatVal(valDivMillion(value))}
                    </TextBlink>
                  </td>
                )
              : undefined,
          }
        })}
      />
      {indexId.map((id) => (
        <Realtime
          channel={ChannelConfig.IndexChannel + id}
          action={subscribeIndex}
          key={id}
        >
          <></>
        </Realtime>
      ))}
    </>
  )
}

export default TableIndex
