import { titleData } from '../../common/helper'

export const convertData = (data, timeFrequency) => {
  let objList = {}
  const result = []
  data.forEach((item) => {
    return (objList[titleData(item.month, item.year, timeFrequency)] = {
      ...(objList[titleData(item.month, item.year, timeFrequency)] || {}),
      [item.locationId]: item.value,
    })
  })
  Object.keys(objList).map((key) =>
    result.push({
      name: key,
      ...objList[key],
    }),
  )
  return result
}

export const getIndicator = (data) => {
  let objList = {}
  const result = []
  data.forEach(
    (item) =>
      (objList[item.locationName] = {
        ...(objList[item.locationName] || {}),
        index: item.locationId,
        key: item.locationId,
      }),
  )
  Object.keys(objList).map((key) =>
    result.push({
      title: key,
      name: key,
      ...objList[key],
    }),
  )
  return result
}
