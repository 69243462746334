import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_LAYOUTS } from '../../../../constants/Layout'
import { PanelFundFlow } from './fundFlow/PanelFundFlow'
import { PanelFundOverview } from './fundOverview/PanelFundOverview'
import { PanelNavCertificateGrowth } from './navCertificateGrowth/PanelNavCertificateGrowth'
import { PanelTop10NetAssetValue } from './top10NetAssetValue/PanelTop10NetAssetValue'

const mapKeys = [
  { key: 'TOP_10_NET_ASSET_VALUE', component: PanelTop10NetAssetValue },
  { key: 'FUND_OVERVIEW_12_MONTHS', component: PanelFundOverview },
  { key: 'NAV_CCQ', component: PanelNavCertificateGrowth },
  { key: 'FUND_FLOW', component: PanelFundFlow },
]

const panelRefs = {}

const Performance = ({ sizes, setSizes }) => {
  return mapKeys.map((item) => {
    const Component = item.component
    return (
      <Component
        key={item.key}
        panelRefs={panelRefs}
        panelKey={item.key}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  })
}

export default withWrapper(PANEL_LAYOUTS[3333](mapKeys), panelRefs)(Performance)
