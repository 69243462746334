import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class SectorValuationProxy extends ProxyBase {
  getLineChart(params) {
    return this.post('SectorConstituents/Valuation/LineChart', params)
  }

  getPriceChart(params) {
    return this.post('SectorConstituents/Valuation/PriceChart', params)
  }

  getDataTable(params) {
    return this.post('SectorConstituents/Valuation/DataTable', params)
  }

  downloadDataTable(params) {
    return this.post(
      'SectorConstituents/DownloadDataTable',
      params,
      {},
      'download',
    )
  }

  getValuationReport(params) {
    return this.post('SectorConstituents/Valuation/ValuationReport', params)
  }

  getAllOrganizationByIcbIdAndGroupId(params) {
    return this.get('GetAllOrganizationByIcbIdAndGroupId', params)
  }

  getMarketCapOrganizationByIcbId(params) {
    return this.get('GetMarketCapOrganizationByIcbId', params)
  }
}

export default new SectorValuationProxy(ServiceProxyConfig.Sector.ServiceUrl)
