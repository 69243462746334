import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DataExplorerService from '../../../../core/services/tool/dataExplorer/DataExplorerService'
import {
  timeoutInterval,
  typeWorkSpace,
} from '../../../common/dataExplorer/constant'
import useGetExchange from '../../../common/masterData/useGetExchange'
import useGetICB from '../../../common/masterData/useGetICB'
import useGetStock from '../../../common/masterData/useGetStock'
import { selectDataGeneralInfo } from '../../../setting/slice'
import { keyBy } from '../../../utils/Common'
import {
  changeCheckedIndicatorsWhenLoadTemplate,
  changeTickersWhenLoadTemplate,
  selectCheckedIndicators,
  selectCheckedIndicatorsId,
  selectFilter,
  selectIndicators,
  selectIsLoadWorkSpace,
  selectListCheckedIndicatorsById,
  selectListTickersById,
  selectTickers,
  selectTickersId,
  selectViewBy,
} from './store/slice'
import { getShareTemplateThunk, getWorkSpaceThunk } from './store/thunk'

let workSpace
let interval

const WorkSpace = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()
  const { stocks } = useGetStock()
  const { exchanges } = useGetExchange()
  const { ICBs } = useGetICB()

  const userInfo = useSelector(selectDataGeneralInfo)
  const viewBy = useSelector(selectViewBy)
  const filter = useSelector(selectFilter)
  const tickers = useSelector(selectTickers(viewBy))
  const tickersById = useSelector(selectListTickersById(viewBy))
  const tickersId = useSelector(selectTickersId(viewBy))
  const checkedIndicators = useSelector(selectCheckedIndicators(viewBy))
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const checkedIndicatorsId = useSelector(selectCheckedIndicatorsId(viewBy))
  const indicators = useSelector(selectIndicators)
  const isLoadWorkSpace = useSelector(selectIsLoadWorkSpace)

  useEffect(() => {
    if (isLoadWorkSpace) {
      workSpace = {
        templateName: 'Trading Data',
        dataType: typeWorkSpace.TRADING_DATA,
        rawParameter: [
          {
            data: {
              viewBy,
              filter,
              tickers,
              tickersById,
              tickersId,
              checkedIndicators,
              checkedIndicatorsById,
              checkedIndicatorsId,
            },
          },
        ],
      }
    }
  }, [viewBy, filter, tickersById, checkedIndicatorsById])

  useEffect(() => {
    interval = setInterval(async () => {
      if (workSpace) {
        await DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }, timeoutInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (workSpace) {
        DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }
  }, [])

  useEffect(() => {
    const shareCode = searchParams.get('shareCode')

    if (userInfo.userId) {
      if (!shareCode) {
        dispatch(getWorkSpaceThunk({ UserID: userInfo.userId }))
      } else {
        dispatch(
          getShareTemplateThunk({ ShareCode: shareCode.replace(/ /g, '+') }),
        )
      }
    }
  }, [userInfo.userId])

  useEffect(() => {
    if (Object.keys(tickersById).length && !tickersId.length) {
      const stocksById = keyBy(stocks, 'organizationId')
      const exchangesById = keyBy(exchanges, 'groupId')
      const ICBsById = keyBy(ICBs, 'icbId')
      const tickers = []
      const tickersId = []
      const newTickersById = Object.values(tickersById).reduce((obj, item) => {
        let ticker = {}

        if (item.organizationId) {
          ticker = {
            ...(stocksById[item.organizationId] || {}),
            tickerOrTaxCode:
              stocksById[item.organizationId]?.ticker ||
              stocksById[item.organizationId]?.taxCode,
          }
        } else if (item.groupId) {
          ticker = {
            ...(exchangesById[item.groupId] || {}),
            indexSectorName: exchangesById[item.groupId]?.groupName,
          }
        } else if (item.icbId) {
          ticker = {
            ...(ICBsById[item.icbId] || {}),
            icbName:
              ICBsById[item.icbId]?.icbName +
              ' L' +
              ICBsById[item.icbId]?.icbLevel,
            indexSectorName:
              ICBsById[item.icbId]?.icbName +
              ' L' +
              ICBsById[item.icbId]?.icbLevel,
          }
        }

        tickersId.push(item.index)

        if (ticker) {
          tickers.push(ticker)
          return {
            ...obj,
            [item.index]: {
              ...item,
              ...ticker,
            },
          }
        }

        return { ...obj, [item.index]: item }
      }, {})

      dispatch(
        changeTickersWhenLoadTemplate({
          tickers,
          tickersById: newTickersById,
          tickersId,
        }),
      )
    }
  }, [tickersById, tickersId])

  useEffect(() => {
    if (
      Object.keys(checkedIndicatorsById).length &&
      !checkedIndicatorsId.length
    ) {
      const indicatorsById = keyBy(indicators, 'indicatorId')
      const checkedIndicators = []
      const checkedIndicatorsId = []
      const newCheckedIndicatorsById = Object.values(
        checkedIndicatorsById,
      ).reduce((obj, item) => {
        const indicator = indicatorsById[item.indicatorId]

        checkedIndicatorsId.push(item.index)

        if (indicator) {
          checkedIndicators.push(indicator)
          return {
            ...obj,
            [item.index]: {
              ...item,
              ...indicator,
            },
          }
        }

        return { ...obj, [item.index]: item }
      }, {})

      dispatch(
        changeCheckedIndicatorsWhenLoadTemplate({
          checkedIndicators,
          checkedIndicatorsById: newCheckedIndicatorsById,
          checkedIndicatorsId,
        }),
      )
    }
  }, [checkedIndicatorsById, checkedIndicatorsId])

  return <></>
}

export default WorkSpace
