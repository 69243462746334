import { Translate } from 'react-redux-i18n'
import { isInValidValue } from '../../../utils/Value'
import { Span } from '../../html/Span'

export const Tooltip = ({ data, schema, xAxis, tooltipProps = {} }) => {
  const getTitle = () => {
    if (tooltipProps.title)
      return typeof tooltipProps.title === 'string'
        ? tooltipProps.title
        : tooltipProps.title(data, schema)
    else return data[xAxis.dataKey]
  }

  return (
    <>
      <div style={{ fontStyle: 'italic' }}>
        <Span style={{ fontSize: 10 }}>{getTitle()}</Span>
      </div>

      {tooltipProps.render
        ? tooltipProps.render(data, schema)
        : schema.map((item) =>
            !isInValidValue(data[item.dataKey]) &&
            !isNaN(data[item.dataKey]) ? (
              <div key={item.dataKey} className="d-flex j-b">
                <div>
                  <Span style={{ fontSize: 11, fontWeight: 400 }}>
                    {item.tooltip?.renderTitle
                      ? item.tooltip.renderTitle(data)
                      : item.text}
                    :
                  </Span>
                </div>
                <div className="ml-8">
                  {item.tooltip?.renderValue ? (
                    item.tooltip.renderValue(data, item)
                  ) : (
                    <Span
                      style={{
                        fontSize: 11,
                        fontWeight: 500,
                      }}
                    >
                      {item.tooltip?.formatValue
                        ? item.tooltip.formatValue(data[item.dataKey])
                        : data[item.dataKey]}
                    </Span>
                  )}
                  {item.unit && (
                    <Span
                      style={{ fontSize: 11, fontWeight: 500, marginLeft: 4 }}
                    >
                      {item.isI18n ? (
                        <Translate value={item.unit} />
                      ) : (
                        item.unit
                      )}
                    </Span>
                  )}
                </div>
              </div>
            ) : null,
          )}
    </>
  )
}
