import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'

export const NoData = ({ schema, noDataText }) => {
  let content = <Translate value={'common.NO_DATA'} />
  if (noDataText && typeof noDataText !== 'undefined') {
    content = noDataText
  }

  return (
    <tbody>
      <tr>
        <td colSpan={schema.length}>
          <div className="no-data">{content}</div>
        </td>
      </tr>
    </tbody>
  )
}

NoData.propTypes = {
  schema: PropTypes.array.isRequired,
  noDataText: PropTypes.string,
}
