import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectIndices,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getIndicesV2Thunk } from './store/thunk'

const useGetIndicesV2 = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.INDICESV2))
  const indices = useSelector(selectIndices)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getIndicesV2Thunk())
    }
  }, [locale])

  return {
    loading,
    indices,
  }
}

export default useGetIndicesV2
