import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getThreeColorByValue } from '../../../utils/Color'
import { formatVal, valToPercent } from '../../../utils/Value'
import { selectCriteria } from '../store/slice'
import { isValToPercent } from './helper'

export const CriteriaCell = ({ val, rowId, hoverCode }) => {
  const criteria = useSelector(selectCriteria)

  return (
    <span
      style={{
        color:
          rowId !== hoverCode && isValToPercent(criteria)
            ? getThreeColorByValue(val)
            : '',
      }}
    >
      {isValToPercent(criteria) ? valToPercent(val) : formatVal(val)}
    </span>
  )
}

CriteriaCell.defaultProps = {
  val: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
}
