import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { SEARCH_ITEM_TYPE } from '../../constants'
import style from '../../style.module.css'
import { changeCompareTab, selectCompareTab } from './store/slice'

const CompareTab = () => {
  const dispatch = useDispatch()
  const compareTab = useSelector(selectCompareTab)

  const changeStatusCompareTab = (data) => {
    dispatch(changeCompareTab(data.value))
  }

  return (
    <div className={`tab ${style.w100Tab}`}>
      <DispatchActionTab
        data={Object.keys(SEARCH_ITEM_TYPE).map((key) => {
          return {
            title: I18n.t(`market.marketInDepth.valuation.${key}`),
            value: SEARCH_ITEM_TYPE[key],
          }
        })}
        activeTab={compareTab}
        onChangeTab={changeStatusCompareTab}
        itemStyle={{
          width: '25%',
        }}
      />
    </div>
  )
}

export default CompareTab
