import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MacroService from '../../../../../core/services/common/MacroService'
import FundReportService from '../../../../../core/services/fund/FundReportService'
import { handleExport } from '../../../../utils/Export'
import { STATEMENT_CONSTANTS } from '../constants'

export const getDataFinancialTable = createAsyncThunk(
  'fund/fundProfile/financialReport/GET_FINANCIAL_DATA_TABLE',
  async (data, { rejectWithValue }) => {
    try {
      const { statement, ...params } = data
      let response = {}
      switch (statement) {
        case STATEMENT_CONSTANTS.BALANCE_SHEET:
          response = await FundReportService.getDataFinancialBalanceSheet(
            params,
          )
          break
        case STATEMENT_CONSTANTS.CASH_FLOW:
          response = await FundReportService.getDataFinancialCashFlow(params)
          break
        case STATEMENT_CONSTANTS.INCOME_STATEMENT:
          response = await FundReportService.getDataFinancialIncomeStatement(
            params,
          )
          break
        case STATEMENT_CONSTANTS.NOTE:
          response = await FundReportService.getDataFinancialNotes(params)
          break
        default:
          break
      }

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicatorMapping = createAsyncThunk(
  'fund/fundProfile/financialReport/GET_INDICATOR_MAPPING',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FundReportService.getIndicatorFinancialMapping(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadFinancialReportExcel = createAsyncThunk(
  'fund/fundProfile/financialReport/DOWNLOAD_FINANCIAL_DATA',
  async (data) => {
    const response = await FundReportService.downloadFinancialReportExcel(data)
    handleExport(response.data, response.filename)
  },
)

export const getExchangeRates = createAsyncThunk(
  'fund/fundProfile/financialReport/GET_EXCHANGE_RATES',
  async (params, { rejectWithValue }) => {
    const response = await MacroService.getExchangeRates(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
