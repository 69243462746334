import ProductionProxy from '../../../proxies/economy/productionAndConsumption/ProductionProxy'
import { ServiceBase } from '../../ServiceBase'

export class ProductionService extends ServiceBase {
  getMaxDate(params) {
    return this.getData(() => ProductionProxy.getMaxDate(params))
  }

  getVNTypeIds(params) {
    return this.getData(() => ProductionProxy.getVNTypeIds(params))
  }

  getDataIIPCompareByTimeRange(params) {
    return this.getData(() =>
      ProductionProxy.getDataIIPCompareByTimeRange(params),
    )
  }

  getDataChartGrowthIIP(params) {
    return this.getData(() => ProductionProxy.getDataChartGrowthIIP(params))
  }

  getDataChartTopChangeIIP(params) {
    return this.getData(() => ProductionProxy.getDataChartTopChangeIIP(params))
  }

  downloadCompareByTimeRange(params) {
    return this.getFileDownload(() =>
      ProductionProxy.downloadCompareByTimeRange(params),
    )
  }

  getDataIICCompareByTimeRange(params) {
    return this.getData(() =>
      ProductionProxy.getDataIICCompareByTimeRange(params),
    )
  }

  getDataChartGrowthIIC(params) {
    return this.getData(() => ProductionProxy.getDataChartGrowthIIC(params))
  }

  getDataChartTopChangeIIC(params) {
    return this.getData(() => ProductionProxy.getDataChartTopChangeIIC(params))
  }

  downloadCompareByTimeRangeIIC(params) {
    return this.getFileDownload(() =>
      ProductionProxy.downloadCompareByTimeRangeIIC(params),
    )
  }

  getDataIIICompareByTimeRange(params) {
    return this.getData(() =>
      ProductionProxy.getDataIIICompareByTimeRange(params),
    )
  }

  getDataChartGrowthIII(params) {
    return this.getData(() => ProductionProxy.getDataChartGrowthIII(params))
  }

  getDataChartTopChangeIII(params) {
    return this.getData(() => ProductionProxy.getDataChartTopChangeIII(params))
  }

  downloadCompareByTimeRangeIII(params) {
    return this.getFileDownload(() =>
      ProductionProxy.downloadCompareByTimeRangeIII(params),
    )
  }

  getGeneralProductionIndexName(params) {
    return this.getData(() =>
      ProductionProxy.getGeneralProductionIndexName(params),
    )
  }

  getGeneralProductionIndex(params) {
    return this.getData(() => ProductionProxy.getGeneralProductionIndex(params))
  }

  downloadGeneralProductionIndex(params) {
    return this.getFileDownload(() =>
      ProductionProxy.downloadGeneralProductionIndex(params),
    )
  }

  getGrowthByTimeRange(params) {
    return this.applyMemoryCache(
      'ProductionProxy.getGrowthByTimeRange',
      params,
    )(() => ProductionProxy.getGrowthByTimeRange(params))
  }

  getPurchasingManagersIndex(params) {
    return this.applyMemoryCache(
      'ProductionProxy.getPurchasingManagersIndex',
      params,
    )(() => ProductionProxy.getPurchasingManagersIndex(params))
  }
}

export default new ProductionService()
