import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class MacroProxy extends ProxyBase {
  getExchangeRates(params) {
    return this.get('GetExchangeRates', params)
  }
}

export default new MacroProxy(ServiceProxyConfig.Common.Macro.ServiceUrl)
