import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../../../constants/Common'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import {
  getDataByMonthAndYear,
  getDataByYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
  reOrderByMonthAndYear,
} from '../../../common/helper'
import {
  LIMIT_FETCH_SCROLL,
  LIMIT_FIRST_FETCH_MONTHLY,
  LIMIT_FIRST_FETCH_YEARLY,
  TIME_FREQUENCY,
  VAL_GROWTH_FILTER,
  VAL_GROWTH_TYPE,
} from '../constants'
import { getUniqueFisheryTypesByProduct } from '../helper'
import { getImportStatisticsByProduct, getMaxDate } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/importByProduct/importStatisticTable',
  initialState: {
    ids: [],
    dataById: {},
    isLoading: true,
    listItems: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    listOrigParentItem: [],
    activeItem: null,
    isLoadingList: false,
    listSelection: [],
    isFirstTimeRenderChart: true,
    rowsCollapse: [],
    timeFrequencyType: TIME_FREQUENCY.MONTHLY,
    isValueOrGrowth: VAL_GROWTH_FILTER[0].value,
    initialIds: [],
    levels: [],
    currentMinMonth: null,
    currentMinYear: null,
    maxMonth: null,
    maxYear: null,
    isChangeLocale: false,
    isFirstRender: true,
    locale: 'en',
    listParents: [],
    countLimit: 0,
  },
  reducers: {
    addItemToList: (state, action) => {
      state.isGetData = true
      state.listItems = [...state.listItems, action.payload]
    },
    removeItemFromList: (state, action) => {
      state.isGetData = false
      state.listItems = state.listItems.filter(
        (item) => item.id !== action.payload,
      )
    },
    changeActiveItem: (state, action) => {
      const rowId = action.payload
      state.activeItem = state.dataById[rowId]
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
    },
    changeTimeFrequencyType: (state, action) => {
      if (state.timeFrequencyType !== action.payload) {
        state.groupColumns = []
      }
      state.timeFrequencyType = action.payload
    },
    changeIsValueOrGrowth: (state, action) => {
      state.isValueOrGrowth = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
    },
    changeLocale: (state, action) => {
      state.isChangeLocale = true
      state.currentMinMonth = null
      state.locale = action.payload
    },
    setIsFirstRenderImportProductTab: (state, action) => {
      state.isFirstRender = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportStatisticsByProduct.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getImportStatisticsByProduct.fulfilled, (state, action) => {
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          productId: item.seafoodProductId,
          id: `${item.seafoodProductId}_${item.locationId}`,
          month: item.realMonth,
          year: item.realYear,
          isGroupRow: item.locationLevel === 0,
          name:
            item.locationLevel === 0
              ? item.seafoodProductName
              : item.locationName,
          level: item.locationLevel === 0 ? 1 : 2,
          value:
            state.isValueOrGrowth === VAL_GROWTH_TYPE.VALUE
              ? item.accumulatedValue / 1000000
              : item.accumulatedValueYoY,
          type: state.isValueOrGrowth,
        })) || []

      if (
        [LIMIT_FIRST_FETCH_YEARLY, LIMIT_FIRST_FETCH_MONTHLY].includes(
          action.meta.arg.Limit,
        )
      ) {
        state.countLimit = action.meta.arg.Limit
      } else {
        state.countLimit += LIMIT_FETCH_SCROLL
      }

      const data = fullDataWithVal(
        payloadData,
        state.data,
        state.currentMinMonth,
        state.isChangeLocale,
        state.timeFrequencyType,
      )

      state.data = data
      state.ids = state.initialIds = data.map((item) => item.id)
      state.dataById = keyBy(data, 'id')

      state.levels = data.map((item) => ({
        id: item.id,
        parentId: `${item.productId}_1032`,
        level: item.level,
      }))

      const getGroupColumns = data?.map((item) => Object.keys(item))[0]
      const isYearly = state.timeFrequencyType === TIME_FREQUENCY.YEARLY
      const isAcc = state.timeFrequencyType === TIME_FREQUENCY.ACC_MONTHLY

      const filterGroupColumns = isYearly
        ? getGroupColumns?.filter((item) => /\d/.test(item))
        : reOrderByMonthAndYear(
            getGroupColumns?.filter((item) => /\d/.test(item)),
          )

      const groupColumns = [{ key: 'name', title: '' }]

      const getTitleForColKey = (colKey) => {
        const month = colKey.slice(0, 2)
        const year = colKey.slice(3, 7)
        if (Number(month) === 1) {
          return `${month}-${year}`
        } else
          return state.locale === 'vi'
            ? `${Number(month)}Th-${year}`
            : `${Number(month)}M-${year}`
      }

      filterGroupColumns?.forEach((item) => {
        groupColumns.push({
          key: item,
          title: isAcc ? getTitleForColKey(item) : item,
        })
      })
      state.groupColumns = groupColumns

      const listParents = data.filter((item) => item.isGroupRow)
      state.listItems =
        !state.listParents.length && !state.listItems.length
          ? listParents.slice(0, 5)
          : [...state.listItems].map((item) => ({
              ...item,
              locationName: state.dataById[item.id].locationName,
              name: state.dataById[item.id].name,
              productName:
                state.dataById[item.id].productName ||
                state.dataById[item.id].seafoodProductName,
            }))
      state.listParents = listParents

      state.isChangeLocale = false

      state.rowsCollapse = !state.activeItem
        ? state.ids.slice(1, state.ids.length)
        : [...state.rowsCollapse]

      state.isLoading = false

      state.currentMinMonth = isYearly
        ? null
        : Number(filterGroupColumns[0].slice(0, 2))
      state.currentMinYear = Number(filterGroupColumns[0].slice(3, 7))

      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getImportStatisticsByProduct.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getMaxDate.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.isContentLoading = false
      const payloadData = action.payload
      state.maxMonth = payloadData?.realMonth
      state.maxYear = payloadData?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.isContentLoading = false
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectItemIds = (state) => state[slice.name].ids
export const selectItemDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListItems = (state) => state[slice.name].listItems
export const selectDataTable = (state) => state[slice.name].data
export const selectDataTableById = (state) => state[slice.name].dataById
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectOrigParentItem = (state) =>
  state[slice.name].listOrigParentItem
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectTimeFrequencyType = (state) =>
  state[slice.name].timeFrequencyType
export const selectIsValueOrGrowth = (state) =>
  state[slice.name].isValueOrGrowth
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectMaxMonth = (state) => state[slice.name].maxMonth
export const selectMaxYear = (state) => state[slice.name].maxYear
export const selectIsFirstRender = (state) => state[slice.name].isFirstRender
export const selectListParents = (state) => state[slice.name].listParents
export const selectCountLimit = (state) => state[slice.name].countLimit

export const {
  addItemToList,
  removeItemFromList,
  changeActiveItem,
  changeStatisticType,
  setRowsCollapse,
  changeTimeFrequencyType,
  changeIsValueOrGrowth,
  sort,
  changeActiveItemWhenClicked,
  changeCurrentMinMonth,
  changeLocale,
  setIsFirstRenderImportProductTab,
} = slice.actions

register(slice.name, slice.reducer)

const fullDataWithVal = (
  dataContainVal,
  dataAfterFirstRender,
  currentMinMonth,
  isChangeLocale,
  timeFrequencyType,
) => {
  const isYearly = timeFrequencyType === TIME_FREQUENCY.YEARLY

  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(dataContainVal).map((item) => getYearKey(item.year))
    : getMonthAndYearInDataRange(dataContainVal).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )

  const getDataValueFromRange = isYearly
    ? getYearInDataRange(dataContainVal).map((item) => [
        ...getDataByYear(dataContainVal, item.year),
      ])
    : getMonthAndYearInDataRange(dataContainVal).map((item) => [
        ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
      ])

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.id === item.id))]?.value ??
      EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  const convertedData = getUniqueFisheryTypesByProduct(dataContainVal).map(
    (item) => ({
      ...item,
      ...getKeyAndValRange(item),
    }),
  )

  return !currentMinMonth || isChangeLocale
    ? convertedData
    : dataAfterFirstRender.map((item) => ({
        ...item,
        ...getKeyAndValRange(item),
      }))
}
