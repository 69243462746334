import TextEllipsis from '../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import { TABLE_KEYS, TABLE_SCHEMAS } from './constants'

export const getTableSchema = ({ locale }) => {
  return TABLE_SCHEMAS.map((item) => {
    if (item.colId === TABLE_KEYS.FULLNAME) {
      return {
        ...item,
        isI18n: false,
        tdStyle: { whiteSpace: 'nowrap' },
      }
    }

    if (item.colId === TABLE_KEYS.POSITION) {
      return {
        ...item,
        isI18n: false,
        canCustomTd: true,
        render: (value, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '35%',
              maxWidth: 350,
            }}
            className={className}
          >
            <TextEllipsis isI18n={false} text={value} />
          </td>
        ),
      }
    }

    if (item.colId === TABLE_KEYS.QUANTITY) {
      return {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (value) => formatVal(value, 0, true),
      }
    }

    if (item.colId === TABLE_KEYS.UPDATE_DATE) {
      return {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (value) => formatDateTime(value, FORMAT.DATE, locale),
      }
    }

    return item
  })
}
