import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { VIEW_BY } from '../constants'
import { convertType } from '../helper'
import { selectQuarter, selectTypeFilter, selectYear } from '../store/slice'
import style from '../style.module.css'
import { ChartComponent } from './ChartComponent'
import { selectLoading, selectValueAndGrowthChartData } from './store/slice'
import { getValueAndGrowthChartData } from './store/thunk'

const BrokerageRevenueGrowth = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const stockInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectValueAndGrowthChartData)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  useEffect(() => {
    if (stockInfo.organizationId && filterYear) {
      dispatch(
        getValueAndGrowthChartData({
          organizationId: stockInfo.organizationId,
          BusinessActivity: convertType(typeFilter),
          Quarter: filterQuarter ? filterQuarter : undefined,
          Year: filterYear,
        }),
      )
    }
  }, [locale, stockInfo, typeFilter, filterYear, filterQuarter])

  const getBarKeyChart = () => {
    if (typeFilter === VIEW_BY.MARGIN_LENDING) {
      return 'nS239'
    } else if (typeFilter === VIEW_BY.PROPRIETARY) {
      return 'td'
    } else {
      return 'bS8'
    }
  }

  const getPointKeyChart = () => {
    if (typeFilter === VIEW_BY.MARGIN_LENDING) {
      return 'mlyg'
    } else if (typeFilter === VIEW_BY.PROPRIETARY) {
      return 'tdyg'
    } else {
      return 'kdnvyg'
    }
  }

  return (
    <div id="brokerageRevenueGrowthId" className="h-100">
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {dataChart.length ? (
            <>
              <SizeTracker>
                {(size) => {
                  return (
                    <div className="position-relative">
                      <div className={style.titleHeader}>
                        <Translate
                          value={`corporate.businessModel.penetrationAnalysis.LABEL_CHART_${typeFilter}`}
                        />
                      </div>
                      {size.height && (
                        <ChartComponent
                          data={dataChart}
                          width={width}
                          height={height - size.height}
                          keyXAxis={'ti'}
                          barKey={getBarKeyChart()}
                          pointKey={getPointKeyChart()}
                          typeFilter={typeFilter}
                        />
                      )}
                      <Footer
                        key={width}
                        list={[
                          {
                            text: I18n.t(
                              `corporate.businessModel.penetrationAnalysis.${typeFilter}`,
                            ),
                            type: TYPE.SQUARE,
                            before: {
                              bgColor: '#0868ae',
                            },
                          },
                          {
                            text: I18n.t(
                              'corporate.businessModel.penetrationAnalysis.YOY_GROWTH',
                            ),
                            renderIcon: (
                              <ScatterDiamondShape
                                color="#f7b80c"
                                style={{ marginRight: 5 }}
                              />
                            ),
                          },
                        ]}
                      />
                    </div>
                  )
                }}
              </SizeTracker>
            </>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
}

export default BrokerageRevenueGrowth
