import TextEllipsis from '../../../../common/textEllipsis'
import style from './style.module.css'

export const CompanyItem = ({
  ticker = {},
  equationSecurities,
  isItemAll,
  isCheckAll,
  onClick,
}) => {
  return (
    <div className="d-flex cursor-pointer" onClick={() => onClick(ticker)}>
      <div className={style.checkBox}>
        <label>
          <input
            type="checkbox"
            className="checkbox line"
            checked={
              !isItemAll
                ? !!equationSecurities.companies &&
                  equationSecurities.companies.includes(ticker.organizationId)
                : isCheckAll
            }
            value={!isItemAll ? ticker.organizationId : ''}
            readOnly
          />
        </label>
      </div>
      <div className={style.tickerInfo}>
        {!isItemAll ? (
          <>
            <div className={style.tickerCode}>
              <TextEllipsis
                isI18n={false}
                text={ticker.tickerOrTaxCode}
                zIndexTooltip={12}
              />
            </div>
            <div className={style.orgName}>
              <TextEllipsis
                isI18n={false}
                text={ticker.organizationShortName}
                zIndexTooltip={12}
              />
            </div>
          </>
        ) : (
          <div className={style.tickerCode}>
            <TextEllipsis text="common.ALL" />
          </div>
        )}
      </div>
    </div>
  )
}
