import { createAsyncThunk } from '@reduxjs/toolkit'
import NewsService from '../../../../../core/services/corporate/NewsService'

export const getNewsAnalytic = createAsyncThunk(
  'corporate/news/GET_NEWS_ANALYTIC',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsAnalytic(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsEarningPaged = createAsyncThunk(
  'corporate/news/GET_NEWS_EARNING_PAGED',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsEarningPaged(data)
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getNewsTransactionsPaged = createAsyncThunk(
  'corporate/news/GET_NEWS_TRANSACTIONS_PAGED',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsTransactionsPaged(data)
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getNewsBusinessPaged = createAsyncThunk(
  'corporate/news/GET_NEWS_BUSINESS_PAGED',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsBusinessPaged(data)
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getNewsOther = createAsyncThunk(
  'corporate/news/GET_NEWS_OTHER',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsOther(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getLatestNews = createAsyncThunk(
  'corporate/news/GET_LATEST_NEWS',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getLatestNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsById = createAsyncThunk(
  'corporate/news/GET_NEWS_BY_ID',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsById(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsSources = createAsyncThunk(
  'corporate/news/GET_NEWS_SOURCES',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsSources(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsCategories = createAsyncThunk(
  'corporate/news/GET_NEWS_CATEGORIES',
  async (data, { rejectWithValue }) => {
    const response = await NewsService.getNewsCategories(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsCategoriesAndSourcesAnalytic = createAsyncThunk(
  'corporate/news/GET_NEWS_CATEGORIES_AND_SOURCES_ANALYTIC',
  async (data) => {
    const response = await Promise.allSettled([
      NewsService.getNewsCategoriesAnalytic(data),
      NewsService.getNewsSourcesAnalytic(data),
    ])
    const [categories, sources] = response

    return {
      categories:
        categories.status === 'fulfilled' ? categories.value.data : [],
      sources: sources.status === 'fulfilled' ? sources.value.data : [],
    }
  },
)
