import { ROW_IDS } from './constant'

export const formatDataDetailMetrics = (payload) => {
  const data = {}

  const tickers = payload
    .filter((item) => item.metricCode === 'BS158')
    .sort((a, b) => b.value - a.value)
    .slice(0, 10)
    .map((item) => item.ticker)

  payload.forEach((item) => {
    if (!data[item.metricCode]) {
      data[item.metricCode] = {}
    }
    data[item.metricCode][item.ticker] = item.value
  })

  return { data, tickers }
}

export const getDepositRowKeyByValue = (value) =>
  Object.keys(ROW_IDS).find((key) => ROW_IDS[key] === value)
