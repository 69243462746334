export const keyColumns = {
  time1: '<6M',
  time2: '6 - 12M',
  time3: '12 - 18M',
  time4: '18 - 24M',
  time5: '24 - 36M',
  time6: '3 - 5Y',
  time7: '5 - 7Y',
  time8: '>7Y',
}

export const keyColumnsNoReal = {
  time1: '3M',
  time2: '6M',
  time3: '9M',
  time4: '12M',
  time5: '18M',
  time6: '24M',
}
export const GROUP_COLUMNS = [
  {
    key: 'name',
    title: 'bond.corporateBond.averageYtmInterest.ISSUER',
    attrs: {
      style: { minWidth: 100, maxWidth: 120 },
    },
    keySort: 'name',
    alignRight: false,
  },
  {
    key: 'outstandingValue',
    title: 'bond.corporateBond.averageYtmInterest.outstandingValue',
    additionalText: 'bond.corporateBond.averageYtmInterest.UNIT',
    attrs: {
      style: { minWidth: 65 },
    },
    keySort: 'outstandingValue',
    alignRight: true,
  },
  {
    key: 'averageYTM',
    title: 'bond.corporateBond.averageYtmInterest.maturityDateId',
    additionalText: 'bond.corporateBond.averageYtmInterest.PERCENT',
    keySort: 'averageYTM',
    alignRight: true,
  },
  {
    key: 'averageRemainLength',
    title: 'bond.corporateBond.averageYtmInterest.remainingLength',
    additionalText: 'bond.corporateBond.averageYtmInterest.MONTH',
    keySort: 'averageRemainLength',
    alignRight: true,
  },
  {
    key: keyColumns.time1,
    title: keyColumns.time1,
    keySort: keyColumns.time1,
    alignRight: true,
  },
  {
    key: keyColumns.time2,
    title: keyColumns.time2,
    keySort: keyColumns.time2,
    alignRight: true,
  },
  {
    key: keyColumns.time3,
    title: keyColumns.time3,
    keySort: keyColumns.time3,
    alignRight: true,
  },
  {
    key: keyColumns.time4,
    title: keyColumns.time4,
    keySort: keyColumns.time4,
    alignRight: true,
  },
  {
    key: keyColumns.time5,
    title: keyColumns.time5,
    keySort: keyColumns.time5,
    alignRight: true,
  },
  {
    key: keyColumns.time6,
    title: keyColumns.time6,
    keySort: keyColumns.time6,
    alignRight: true,
  },
  {
    key: keyColumns.time7,
    title: keyColumns.time7,
    keySort: keyColumns.time7,
    alignRight: true,
  },
  {
    key: keyColumns.time8,
    title: keyColumns.time8,
    keySort: keyColumns.time8,
    alignRight: true,
  },
]
export const GROUP_COLUMNS_NOT_REAL = [
  {
    key: 'name',
    title: 'bond.corporateBond.averageYtmInterest.ISSUER',
    attrs: {
      style: { minWidth: 100, maxWidth: 120 },
    },
    keySort: 'name',
    alignRight: false,
  },
  {
    key: 'outstandingValue',
    title: 'bond.corporateBond.averageYtmInterest.outstandingValue',
    additionalText: 'bond.corporateBond.averageYtmInterest.UNIT',
    attrs: {
      style: { minWidth: 65 },
    },
    keySort: 'outstandingValue',
    alignRight: true,
  },
  {
    key: 'averageYTM',
    title: 'bond.corporateBond.averageYtmInterest.maturityDateId',
    additionalText: 'bond.corporateBond.averageYtmInterest.PERCENT',
    keySort: 'averageYTM',
    alignRight: true,
  },
  {
    key: 'averageRemainLength',
    title: 'bond.corporateBond.averageYtmInterest.remainingLength',
    additionalText: 'bond.corporateBond.averageYtmInterest.MONTH',
    keySort: 'averageRemainLength',
    alignRight: true,
  },
  {
    key: keyColumnsNoReal.time1,
    title: keyColumnsNoReal.time1,
    keySort: keyColumnsNoReal.time1,
    alignRight: true,
  },

  {
    key: keyColumnsNoReal.time2,
    title: keyColumnsNoReal.time2,
    keySort: keyColumnsNoReal.time2,
    alignRight: true,
  },
  {
    key: keyColumnsNoReal.time3,
    title: keyColumnsNoReal.time3,
    keySort: keyColumnsNoReal.time3,
    alignRight: true,
  },
  {
    key: keyColumnsNoReal.time4,
    title: keyColumnsNoReal.time4,
    keySort: keyColumnsNoReal.time4,
    alignRight: true,
  },
  {
    key: keyColumnsNoReal.time5,
    title: keyColumnsNoReal.time5,
    keySort: keyColumnsNoReal.time5,
    alignRight: true,
  },
  {
    key: keyColumnsNoReal.time6,
    title: keyColumnsNoReal.time6,
    keySort: keyColumnsNoReal.time6,
    alignRight: true,
  },
]
export const AVERAGE_YTM_INTEREST = [
  {
    title: 'bond.corporateBond.averageYtmInterest.REAL',
    value: 'real',
  },
  {
    title: 'bond.corporateBond.averageYtmInterest.NOT_REAL',
    value: 'notReal',
  },
]
