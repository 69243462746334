import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class PeerValuationProxy extends ProxyBase {
  //Chart
  getColumnChart(params) {
    return this.get('Valuation/ColumnChart', params)
  }

  getLineChart(params) {
    return this.get('Valuation/LineChart', params)
  }

  //Get orgIds by Icb
  getOrgIdsByIcb(params) {
    return this.post('Valuation/GetOrganizationIdsByIcb', params)
  }

  //Peer Comparison
  getPeerComparisonBankPerformance(params) {
    return this.post('PeerComparison/Bank/Performance', params)
  }

  downloadPeerComparisonBankPerformance(params) {
    return this.post(
      'PeerComparison/Bank/DownloadPerformance',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonBankValuation(params) {
    return this.post('PeerComparison/Bank/Valuation', params)
  }

  downloadPeerComparisonBankValuation(params) {
    return this.post(
      'PeerComparison/Bank/DownloadValuation',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonBankOwnership(params) {
    return this.post('PeerComparison/Bank/Ownership', params)
  }

  downloadPeerComparisonBankOwnership(params) {
    return this.post(
      'PeerComparison/Bank/DownloadOwnership',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonBankAssetQuality(params) {
    return this.post('PeerComparison/Bank/AssetQuality', params)
  }

  downloadPeerComparisonBankAssetQuality(params) {
    return this.post(
      'PeerComparison/Bank/DownloadAssetQuality',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonBankProfitability(params) {
    return this.post('PeerComparison/Bank/Profitability', params)
  }

  downloadPeerComparisonBankProfitability(params) {
    return this.post(
      'PeerComparison/Bank/DownloadProfitability',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonCorpPerformance(params) {
    return this.post('PeerComparison/Corporate/Performance', params)
  }

  downloadPeerComparisonCorpPerformance(params) {
    return this.post(
      'PeerComparison/Corporate/DownloadPerformance',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonCorpValuation(params) {
    return this.post('PeerComparison/Corporate/Valuation', params)
  }

  downloadPeerComparisonCorpValuation(params) {
    return this.post(
      'PeerComparison/Corporate/DownloadValuation',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonCorpOwnership(params) {
    return this.post('PeerComparison/Corporate/Ownership', params)
  }

  downloadPeerComparisonCorpOwnership(params) {
    return this.post(
      'PeerComparison/Corporate/DownloadOwnership',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonCorpCredit(params) {
    return this.post('PeerComparison/Corporate/Credit', params)
  }

  downloadPeerComparisonCorpCredit(params) {
    return this.post(
      'PeerComparison/Corporate/DownloadCredit',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonCorpProfitability(params) {
    return this.post('PeerComparison/Corporate/Profitability', params)
  }

  downloadPeerComparisonCorpProfitability(params) {
    return this.post(
      'PeerComparison/Corporate/DownloadProfitability',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonInsuPerformance(params) {
    return this.post('PeerComparison/Insurance/Performance', params)
  }

  downloadPeerComparisonInsuPerformance(params) {
    return this.post(
      'PeerComparison/Insurance/DownloadPerformance',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonInsuValuation(params) {
    return this.post('PeerComparison/Insurance/Valuation', params)
  }

  downloadPeerComparisonInsuValuation(params) {
    return this.post(
      'PeerComparison/Insurance/DownloadValuation',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonInsuOwnership(params) {
    return this.post('PeerComparison/Insurance/Ownership', params)
  }

  downloadPeerComparisonInsuOwnership(params) {
    return this.post(
      'PeerComparison/Insurance/DownloadOwnership',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonInsuCredit(params) {
    return this.post('PeerComparison/Insurance/Credit', params)
  }

  downloadPeerComparisonInsuCredit(params) {
    return this.post(
      'PeerComparison/Insurance/DownloadCredit',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonInsuProfitability(params) {
    return this.post('PeerComparison/Insurance/Profitability', params)
  }

  downloadPeerComparisonInsuProfitability(params) {
    return this.post(
      'PeerComparison/Insurance/DownloadProfitability',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonSecuPerformance(params) {
    return this.post('PeerComparison/Securities/Performance', params)
  }

  downloadPeerComparisonSecuPerformance(params) {
    return this.post(
      'PeerComparison/Securities/DownloadPerformance',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonSecuValuation(params) {
    return this.post('PeerComparison/Securities/Valuation', params)
  }

  downloadPeerComparisonSecuValuation(params) {
    return this.post(
      'PeerComparison/Securities/DownloadValuation',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonSecuOwnership(params) {
    return this.post('PeerComparison/Securities/Ownership', params)
  }

  downloadPeerComparisonSecuOwnership(params) {
    return this.post(
      'PeerComparison/Securities/DownloadOwnership',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonSecuCredit(params) {
    return this.post('PeerComparison/Securities/Credit', params)
  }

  downloadPeerComparisonSecuCredit(params) {
    return this.post(
      'PeerComparison/Securities/DownloadCredit',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonSecuProfitability(params) {
    return this.post('PeerComparison/Securities/Profitability', params)
  }

  downloadPeerComparisonSecuProfitability(params) {
    return this.post(
      'PeerComparison/Securities/DownloadProfitability',
      params,
      {},
      'download',
    )
  }

  getMaxPeriod(params) {
    return this.get('PeerComparison/GetMaxPeriod', params)
  }

  getPeerComparisonBalanceSheet(params) {
    return this.post('PeerComparison/BalanceSheet', params)
  }

  downloadPeerComparisonBalanceSheet(params) {
    return this.post(
      'PeerComparison/DownloadBalanceSheet',
      params,
      {},
      'download',
    )
  }

  getPeerComparisonIncomeStatement(params) {
    return this.post('PeerComparison/IncomeStatement', params)
  }

  downloadPeerComparisonIncomeStatement(params) {
    return this.post(
      'PeerComparison/DownloadIncomeStatement',
      params,
      {},
      'download',
    )
  }

  getRecommendation(params) {
    return this.get('Consensus/Recommendation', params)
  }

  getHistory(params) {
    return this.get('Consensus/History', params)
  }

  getHistoryStackColumn(params) {
    return this.get('Consensus/HistoryStackColumn', params)
  }

  getTargetPrice(params) {
    return this.get('Consensus/TargetPrice', params)
  }

  getTargetPriceDetail(params) {
    return this.get('Consensus/TargetPriceDetail', params)
  }
}

export default new PeerValuationProxy(ServiceProxyConfig.Corporate.ServiceUrl)
