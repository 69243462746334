import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceStatisticService from '../../../../../../core/services/market/equityTrading/PriceStatisticService'

export const getValueByInvestorChart = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/valueInvestor/GET_VALUE_BY_INVESTOR_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getValueByInvestorChart(
        params,
      )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
