import { useEffect, useState } from 'react'
import { MARGIN } from '../../../../../common/chart/constants'
import { getFontSize } from '../../../../../utils/FontSize'
import { encodeSizePanel } from '../../helper'
import style from '../../index.module.css'
import BUSDChart from './BUSDChart'
import LiquidityChart from './LiquidityChart'
import PriceChart from './PriceChart'

export const Liquidlity = ({ sizes, panelKey }) => {
  const [chartMargin, setChartMargin] = useState(MARGIN)

  const calcFontSize = getFontSize(12)

  const sizePanel = encodeSizePanel(
    sizes[panelKey].width,
    sizes[panelKey].height,
  )

  useEffect(() => {
    setChartMargin(MARGIN)
  }, [calcFontSize])

  return (
    <>
      <div className={style.height30}>
        <PriceChart
          sizes={sizePanel}
          chartMargin={chartMargin}
          setChartMargin={setChartMargin}
        />
      </div>
      <div className={style.height30}>
        <LiquidityChart
          sizes={sizePanel}
          chartMargin={chartMargin}
          setChartMargin={setChartMargin}
        />
      </div>
      <div className={style.height40}>
        <BUSDChart
          sizes={sizePanel}
          chartMargin={chartMargin}
          setChartMargin={setChartMargin}
        />
      </div>
    </>
  )
}
