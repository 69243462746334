import { createAsyncThunk } from '@reduxjs/toolkit'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getValueAndGrowthChartData = createAsyncThunk(
  'corporate/businessModel/penetration/securities/panelBrokerageRevenueGrowth/VALUE_AND_GROWTH_CHART_DATA)',
  async (params, { rejectWithValue, dispatch }) => {
    const response =
      await PenetrationAnalysisService.getSecValueAndGrowthChartData(params)

    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
