import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine'
import style from '../../index.module.css'
import { SearchBox } from '../searchBox/SearchBox'
import {
  ACC_MONTH_FILTER_EN,
  ACC_MONTH_FILTER_VI,
  MONTH_LIST_FILTER_EN,
  MONTH_LIST_FILTER_VI,
  TYPE_STATISTICS_FILTER,
  YEAR_LIST_FILTER,
} from './constants'

export const FilterList = ({
  width,
  currentMonthSelected,
  currentYearSelected,
  listCompanies,
  changeMonth,
  changeYear,
  changeIsAcc,
  changeIsYearly,
  changeIsYearlyChart,
  activeItem,
  currentItemInChart,
  changeActiveItem,
  changeCurrentItem,
  currentTypeStatistics,
  changeCurrentTypeStatistics,
  tableId,
  setRowsCollapse,
  rowsCollapse,
  keepRowsCollapse,
}) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)

  const isAccTypeStatistics = currentTypeStatistics.isAcc

  const getMonthListFilterByLocale = (locale) => {
    if (isAccTypeStatistics) {
      return locale === 'en' ? ACC_MONTH_FILTER_EN : ACC_MONTH_FILTER_VI
    } else {
      return locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI
    }
  }

  const handleMonthChange = (val) => {
    dispatch(changeMonth(val))
    dispatch(keepRowsCollapse(false))
  }

  const handleYearChange = (val) => {
    dispatch(changeYear(val))
    dispatch(keepRowsCollapse(false))
  }

  const handleChangeIsAcc = (item) => {
    dispatch(changeIsAcc(item.isAcc))
    dispatch(changeIsYearly(item.isYearly))
    dispatch(changeIsYearlyChart(item.isYearly))
    dispatch(changeCurrentTypeStatistics(item))
    dispatch(keepRowsCollapse(true))
  }

  return (
    <div style={{ width: width }} className="d-flex j-b">
      <div style={{ width: '200px', marginTop: '3px' }}>
        <SearchBox
          isInTable
          listCompanies={listCompanies}
          activeItem={activeItem}
          currentItemInChart={currentItemInChart}
          changeActiveItem={changeActiveItem}
          changeCurrentItem={changeCurrentItem}
          tableId={tableId}
          setRowsCollapse={setRowsCollapse}
          rowsCollapse={rowsCollapse}
        />
      </div>
      <div className="d-flex">
        <div
          className={`${style.inputDropdown}`}
          style={{
            width: '84px',
            height: '20px',
            marginRight: '4px',
            marginTop: '3px',
          }}
        >
          <SelectCustom
            value={currentMonthSelected}
            isI18n={false}
            selectData={getMonthListFilterByLocale(locale)}
            handleChange={handleMonthChange}
            isDisable={currentTypeStatistics.isYearly}
          />
        </div>
        <div
          className={`${style.inputDropdown}`}
          style={{ width: '84px', height: '20px', marginTop: '3px' }}
        >
          <SelectCustom
            value={currentYearSelected}
            isI18n={false}
            selectData={YEAR_LIST_FILTER}
            handleChange={handleYearChange}
          />
        </div>
        <VerticalLine mr={12} ml={12} />
        <div style={{ marginTop: '3px' }}>
          <DispatchActionTab
            data={TYPE_STATISTICS_FILTER}
            activeTab={currentTypeStatistics.value}
            onChangeTab={handleChangeIsAcc}
          />
        </div>
      </div>
    </div>
  )
}
