import React, { forwardRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Col from '../../common/Col'
import Row from '../../common/Row'
import TableData from '../../common/TableData'
import ToiTableHead from '../../common/ToiTableHead'
import { TYPE_CONFIG } from '../../configs'
import { mappingData } from '../../helper'
import style from '../../index.module.css'
import { selectChoose, selectIncomeStructure } from '../../store/slice'
import { selectFooterChildrenTable } from '../store/slice'

const FooterChildrenTable = (props, ref) => {
  const { GR } = TYPE_CONFIG
  const [mappingIncomeStructure, setMappingIncomeStructure] = useState([])

  const footerChildrenTable = useSelector(selectFooterChildrenTable)
  const choose = useSelector(selectChoose)
  const incomeStructureData = useSelector(selectIncomeStructure)

  useEffect(() => {
    if (footerChildrenTable.length && incomeStructureData.length) {
      setMappingIncomeStructure(
        mappingData(footerChildrenTable, incomeStructureData),
      )
    }
  }, [footerChildrenTable, incomeStructureData])

  return (
    <tfoot
      className={`${style.fixedFooter} ${style.childrenTableSticky}`}
      ref={ref}
    >
      {mappingIncomeStructure.length > 0 &&
        mappingIncomeStructure.map((row, index) => {
          const cols = row.map((col) => {
            if (col?.type === GR) {
              return (
                <ToiTableHead
                  key={col?.indicatorMappingId}
                  colSpan={6}
                  isCollapse={col?.expandable}
                  isActive
                  styleProps={{
                    cursor: col?.expandable ? 'pointer' : 'default',
                  }}
                >
                  <div
                    className={`${style.outlineLeft} ${style.outlineRight}`}
                  />
                  {col?.indicator}
                </ToiTableHead>
              )
            } else {
              return (
                <Col
                  key={col?.indicatorMappingId}
                  colSpan={6}
                  id={col?.alias}
                  isChoose={choose === col?.fieldName}
                  className={style.colSticky}
                  chart1Type={col?.chart1Type}
                  chart2Type={col?.chart2Type}
                  fieldName={col?.fieldName}
                  fieldName_1={col?.fieldName_1}
                  indicator={col?.indicator}
                  relativeIndicator={col?.relativeIndicator}
                  alias={col?.alias}
                  valueFormat={col?.format}
                >
                  <div
                    className={`${style.outlineLeft} ${style.outlineRight}`}
                  />
                  <TableData
                    alias={col?.alias}
                    parentData={col?.value}
                    childrenData={col?.relativeValue}
                    afterChildrenData={
                      col?.relativeIndicator === '%YoY' ? 'YoY' : ''
                    }
                    relativeField={col?.relativeField}
                    valueFormat={col?.format}
                    multiplier={col?.multiplier}
                  />
                </Col>
              )
            }
          })
          return <Row key={index}>{cols.map((col) => col)}</Row>
        })}
    </tfoot>
  )
}

export default forwardRef(FooterChildrenTable)
