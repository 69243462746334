export const NAME_POPUP = {
  DUPLICATE_DB: 'DUPLICATE_DB',
  DELETE_DB: 'DELETE_DB',
  COPY_CHART_TO_NEW_DB: 'COPY_CHART_TO_NEW_DB',
  WARNING_SAVE: 'WARNING_SAVE',
}

export const EXCHANGE_CODE = {
  ALL_EXCHANGE: 'All',
  HOSE: 'VNIndex',
  HNX: 'HNXIndex',
  UPCOM: 'UPCOMIndex',
  OTC: 'OTC',
  PRIVATE: 'Private',
}

export const TIME_FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
  TTM: 'ttm',
}

export const MAP_NAME_BY_TIME_FREQUENCY = {
  [TIME_FREQUENCY.DAILY]: '(D)',
  [TIME_FREQUENCY.WEEKLY]: '(W)',
  [TIME_FREQUENCY.MONTHLY]: '(M)',
  [TIME_FREQUENCY.QUARTERLY]: '(Q)',
  [TIME_FREQUENCY.YEARLY]: '(Y)',
  [TIME_FREQUENCY.TTM]: '(TTM)',
}

export const MAP_TIME_FREQUENCY = {
  [TIME_FREQUENCY.DAILY]: 'financialChart.DAILY',
  [TIME_FREQUENCY.WEEKLY]: 'financialChart.WEEKLY',
  [TIME_FREQUENCY.MONTHLY]: 'financialChart.MONTHLY',
  [TIME_FREQUENCY.QUARTERLY]: 'financialChart.QUARTERLY',
  [TIME_FREQUENCY.YEARLY]: 'financialChart.YEARLY',
  [TIME_FREQUENCY.TTM]: 'financialChart.TTM',
}
