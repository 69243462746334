import { useSelector } from 'react-redux'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { TradingView } from '../../../../common/tradingView'
import { STOCK_EVENT_TYPE } from '../../../../common/tradingView/constants'

const Charting = () => {
  const warrantInfo = useSelector(selectWarrantInfo)
  return (
    <TradingView
      defaultSymbol={warrantInfo?.ticker}
      containerId="coveredWarrant/charting"
      disabledFeatures={['left_toolbar', 'header_widget']}
      appendStyle={{ margin: '5px -6px 0' }}
      stockEventType={STOCK_EVENT_TYPE.ALL}
    />
  )
}

export default Charting
