import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../html/Input'
import Tree from '../../../tree'
import {
  getAllChildrenItem,
  getAllIdTreeIncludeParent,
} from '../../../tree/helper'
import style from '../index.module.css'
import {
  addIndicator,
  selectIndicatorPopupIndicator,
  selectIsEdit,
  selectIsGroup,
} from '../store/slice'

const TreeIndicator = ({ tree }) => {
  const dispatch = useDispatch()

  const indicator = useSelector(selectIndicatorPopupIndicator)
  const isEdit = useSelector(selectIsEdit)
  const isGroup = useSelector(selectIsGroup)

  const [valueSearch, setValueSearch] = useState('')
  const [listDisabled, setListDisabled] = useState({})
  const [indicatorDefaultOpen, setIndicatorDefaultOpen] = useState()

  useEffect(() => {
    const allIdTree = {}
    getAllIdTreeIncludeParent(tree, 'indicatorId').forEach(
      (indicatorId) => (allIdTree[indicatorId] = true),
    )
    setListDisabled(allIdTree)
  }, [tree])

  useEffect(() => {
    if (!indicatorDefaultOpen) {
      setIndicatorDefaultOpen(indicator)
    }
  }, [indicator])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDbClickItem = (item) => {
    if (Object.keys(item.children).length === 0) {
      dispatch(addIndicator([{ ...item, children: undefined }]))
    } else {
      dispatch(
        addIndicator(
          getAllChildrenItem(item).map((item) => {
            return { ...item, children: undefined }
          }),
        ),
      )
    }
  }

  return (
    <div className={`col ${style.colTree}`}>
      <div className={`col-header pl-12-pr-16 ${style.h37px} d-flex j-b`}>
        <Translate className={style.disabled} value="common.popup.INDICATOR" />
        <div className="form-search-inbox m-0">
          <Input
            onChange={handleChangeInput}
            type="text"
            className={`search-inbox w-248`}
            placeholder={I18n.t('common.popup.SEARCH')}
          />
          <button type="button">
            <i className="icon-search-2" />
          </button>
        </div>
      </div>
      <div className="col-content">
        <div className={`content-wrapper ${style.h300}`}>
          <Tree
            listDisabled={isEdit && !isGroup ? listDisabled : undefined}
            listHightlight={indicator}
            doubleClickItem={handleDbClickItem}
            valueSearch={valueSearch}
            treeData={tree}
            nameKey="indicatorName"
            idKey="indicatorId"
            sortKey="orderNumber"
            itemDefaultOpen={indicatorDefaultOpen}
          />
        </div>
      </div>
    </div>
  )
}

TreeIndicator.propTypes = {
  tree: PropTypes.object.isRequired,
}

export default TreeIndicator
