import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class FDIByCountriesProxy extends ProxyBase {
  getTotalRegisterFDICapital(params) {
    return this.get('TotalRegisterFDICapital', params)
  }

  downloadTotalRegisterCapital(params) {
    return this.get('DownloadTotalRegisterCapital', params, 'download')
  }

  getLocationName(params) {
    return this.get('LocationName', params)
  }

  getFDIStructureByCountry(params) {
    return this.get('FDIStructureByCountry', params)
  }

  getCompositionOfFDIInflowsByCountryPieChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionOfFDIInflowsByCountryPieChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionOfFDIInflowsByCountryPieChart', params)
  }

  getCompositionOfFDIInflowsByCountryColumnChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionOfFDIInflowsByCountryColumnChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionOfFDIInflowsByCountryColumnChart', params)
  }
}

export default new FDIByCountriesProxy(
  ServiceProxyConfig.Economy.FDI.FDIByCountries.ServiceUrl,
)
