import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { keyByYear } from '../helper'
import { getGDPGrowthBySpendingMethod } from './thunk'

const initialState = {
  lineChartData: [],
  lineChartLoading: true,
  activeLine: undefined,
  timeRange: 'SevenYears',
}

const slice = createSlice({
  name: 'economy/gdp/gdpBySpendingMethod/panelGDPGrowth',
  initialState,
  reducers: {
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPGrowthBySpendingMethod.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getGDPGrowthBySpendingMethod.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = keyByYear(action.payload || [])
    })
    builder.addCase(getGDPGrowthBySpendingMethod.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const { handleActiveLine, handleTimeRange } = slice.actions

export const getLineChartData = (state) => state[slice.name].lineChartData
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getTimeRange = (state) => state[slice.name].timeRange
export const getActiveLine = (state) => state[slice.name].activeLine

register(slice.name, slice.reducer)
