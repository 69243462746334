import {
  FORMAT,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../utils/Datetime'
import { TIME } from './constants'

export const getFilterDate = (time, startDate, endDate) => {
  switch (time) {
    case TIME.Custom:
      return {
        startDate,
        endDate,
      }
    case TIME.All:
      return {
        startDate: null,
        endDate: null,
      }
    case TIME['Last 7 days']:
      return {
        startDate: getDateTimeFrom(new Date(), -7, 'days', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME['Last month']:
      return {
        startDate: getDateTimeFrom(new Date(), -1, 'months', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME['Last 3 months']:
      return {
        startDate: getDateTimeFrom(new Date(), -3, 'months', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME['Last 6 months']:
      return {
        startDate: getDateTimeFrom(new Date(), -6, 'months', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }
    case TIME['Last year']:
      return {
        startDate: getDateTimeFrom(new Date(), -1, 'year', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }
    default:
      throw Error('Pls set time correctly!')
  }
}
