import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class FinancialAnalysisProxy extends ProxyBase {
  // Bank
  getAssetQualityLoanStructure(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Bank/AssetQuality/LoanStructure',
          paramsArr: params,
        })
      : this.get('Bank/AssetQuality/LoanStructure', params)
  }

  getAssetQualityNplCoverage(params) {
    return this.get('Bank/AssetQuality/NPLCoverage', params)
  }

  getAssetQualityNplSml(params) {
    return this.get('Bank/AssetQuality/NPLSML', params)
  }

  getAssetQualityTable(params) {
    return this.get('Bank/AssetQuality/Table', params)
  }

  getAssetQualityValueOfBondPortfolios(params) {
    return this.get('Bank/AssetQuality/ValueOfBondPortfolios', params)
  }

  getAssetQualityLoanStructureInDepthComparison(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Bank/AssetQuality/LoanStructureInDepthComparison',
          paramsArr: params,
        })
      : this.get('Bank/AssetQuality/LoanStructureInDepthComparison', params)
  }

  downloadAssetQualityTable(params) {
    return this.get('Bank/AssetQuality/DownloadTable', params, 'download')
  }

  getCapitalStructureCasaRatio(params) {
    return this.get('Bank/CapitalStructure/CASARatio', params)
  }

  getCapitalStructureDepositStructure(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Bank/CapitalStructure/DepositStructure',
          paramsArr: params,
        })
      : this.get('Bank/CapitalStructure/DepositStructure', params)
  }

  getCapitalStructureLdrRatio(params) {
    return this.get('Bank/CapitalStructure/LDRRatio', params)
  }

  getCapitalStructureLoanStructure(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Bank/CapitalStructure/LoanStructure',
          paramsArr: params,
        })
      : this.get('Bank/CapitalStructure/LoanStructure', params)
  }

  getCapitalStructureTable(params) {
    return this.get('Bank/CapitalStructure/Table', params)
  }

  getCapitalStructureTier1Capital(params) {
    return this.get('Bank/CapitalStructure/Tier1Capital', params)
  }

  downloadCapitalStructureTable(params) {
    return this.get('Bank/CapitalStructure/DownloadTable', params, 'download')
  }

  getProfitabilityAndEfficiencyCostToIncomeRatio(params) {
    return this.get('Bank/ProfitabilityAndEfficiency/CostToIncomeRatio', params)
  }

  getProfitabilityAndEfficiencyLoanStructure(params) {
    return this.get('Bank/ProfitabilityAndEfficiency/LoanStructure', params)
  }

  getProfitabilityAndEfficiencyNetInterestMargin(params) {
    return this.get('Bank/ProfitabilityAndEfficiency/NetInterestMargin', params)
  }

  getProfitabilityAndEfficiencyNonInterestIncomePerformance(params) {
    return this.get(
      'Bank/ProfitabilityAndEfficiency/NonInterestIncomePerformance',
      params,
    )
  }

  getProfitabilityAndEfficiencyOperatingAndEarningGrowth(params) {
    return this.get(
      'Bank/ProfitabilityAndEfficiency/OperatingAndEarningGrowth',
      params,
    )
  }

  getProfitabilityAndEfficiencyTable(params) {
    return this.get('Bank/ProfitabilityAndEfficiency/Table', params)
  }

  downloadProfitabilityAndEfficiencyTable(params) {
    return this.get(
      'Bank/ProfitabilityAndEfficiency/DownloadTable',
      params,
      'download',
    )
  }

  // NonBank
  getEfficiency(params) {
    return this.get('NonBank/Efficiency', params)
  }

  getGrowth(params) {
    return this.get('NonBank/Growth', params)
  }

  getNonFinancialCompany(params) {
    return this.get('NonBank/NonfinancialCompany', params)
  }

  getProfitability(params) {
    return this.get('NonBank/Profitability', params)
  }

  getStrength(params) {
    return this.get('NonBank/Strength', params)
  }

  getValuation(params) {
    return this.get('NonBank/Valuation', params)
  }

  // Security
  getAssetsStructureAFSDecomposition(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/AssetsStructure/AFSDecomposition',
          paramsArr: params,
        })
      : this.get('Secu/AssetsStructure/AFSDecomposition', params)
  }

  getAssetsStructureFVTPLSegmental(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/AssetsStructure/FVTPLSegmental',
          paramsArr: params,
        })
      : this.get('Secu/AssetsStructure/FVTPLSegmental', params)
  }

  getAssetsStructureOrganizationPortfolio(params) {
    return this.get('Secu/AssetsStructure/GetOrganizationPortfolio', params)
  }

  getAssetsStructureFVTPLListedShareDecompositionAll(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/AssetsStructure/FVTPLListedShareDecomposition_All',
          paramsArr: params,
        })
      : this.get(
          'Secu/AssetsStructure/FVTPLListedShareDecomposition_All',
          params,
        )
  }

  getAssetsStructureFVTPLListedShareDecompositionDetail(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/AssetsStructure/FVTPLListedShareDecomposition_Detail',
          paramsArr: params,
        })
      : this.get(
          'Secu/AssetsStructure/FVTPLListedShareDecomposition_Detail',
          params,
        )
  }

  downloadAssetsStructureFVTPLListedShareDecompositionTableAll(params) {
    return this.get(
      'Secu/AssetsStructure/DownloadFVTPLListedShareDecompositionTable_All',
      params,
      'download',
    )
  }

  downloadAssetsStructureFVTPLListedShareDecompositionTableDetail(params) {
    return this.get(
      'Secu/AssetsStructure/DownloadFVTPLListedShareDecompositionTable_Detail',
      params,
      'download',
    )
  }

  getAssetsStructureLoanAndMargin(params) {
    return this.get('Secu/AssetsStructure/LoanAndMargin', params)
  }

  getAssetsStructureTable(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/AssetsStructure/Table',
          paramsArr: params,
        })
      : this.get('Secu/AssetsStructure/Table', params)
  }

  downloadAssetsStructureDownloadTable(params) {
    return this.get('Secu/AssetsStructure/DownloadTable', params, 'download')
  }

  getPerformanceOperatingSale(params) {
    return this.get('Secu/Performance/OperatingSale', params)
  }

  getPerformanceRevenueFromBrokerage(params) {
    return this.get('Secu/Performance/RevenueFromBrokerage', params)
  }

  getPerformanceRevenueFromInvestment(params) {
    return this.get('Secu/Performance/RevenueFromInvestment', params)
  }

  getPerformanceRevenueFromProprietary(params) {
    return this.get('Secu/Performance/RevenueFromPropprietary', params)
  }

  getPerformanceTable(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Secu/Performance/Table',
          paramsArr: params,
        })
      : this.get('Secu/Performance/Table', params)
  }

  downloadPerformanceDownloadTable(params) {
    return this.get('Secu/Performance/DownloadTable', params, 'download')
  }

  downloadNonFinancialCompany(params) {
    return this.post(
      'NonBank/DownloadNonfinancialCompany',
      params,
      {},
      'download',
    )
  }
}

export default new FinancialAnalysisProxy(
  ServiceProxyConfig.Sector.SectorConstituents.SectorFinancialAnalysis.ServiceUrl,
)
