import Checkbox from './Checkbox'

export const getTableSchemaFormat = (
  selectDataById,
  columnConstants,
  checkboxKey,
  isMaxCheck,
) => {
  return columnConstants.map((key) => {
    const title = key
    const colId = key
    const result = {
      colId,
      title,
    }

    if (colId === 'index') {
      return {
        ...result,
        isI18n: false,
        tdStyle: {
          textAlign: 'left',
          width: 30,
          border: 'unset',
        },
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td style={style} className={className}>
            {val + 1}.
          </td>
        ),
      }
    }

    if (colId === 'actionIndex') {
      return {
        ...result,
        isI18n: false,
        tdStyle: {
          textAlign: 'center',
          width: 20,
          border: 'unset',
        },
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td style={style} className={className}>
            <Checkbox
              id={val}
              checkboxKey={checkboxKey}
              selectDataById={selectDataById}
              isMaxCheck={isMaxCheck}
            />
          </td>
        ),
      }
    }

    return {
      ...result,
      isI18n: false,
      tdStyle: {
        textAlign: 'left',
        border: 'unset',
      },
      canCustomTd: true,
      render: (val, _, { style, className }) => (
        <td style={style} className={className}>
          {val}
        </td>
      ),
    }
  })
}
