import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TextBlink } from '../../../common/table/TextBlink'
import { getThreeColorByValue } from '../../../utils/Color'

const CellHighLight = ({
  value,
  rowId,
  keyColor,
  selectCell,
  style,
  className,
}) => {
  const colorValue = useSelector(selectCell(rowId, keyColor))

  return (
    <td
      style={{ ...style, color: getThreeColorByValue(Number(colorValue)) }}
      className={className}
    >
      <TextBlink
        value={Number(String(value).replace('%', '').replace(',', ''))}
      >
        {value}
      </TextBlink>
    </td>
  )
}

CellHighLight.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keyColor: PropTypes.string.isRequired,
  selectCell: PropTypes.func.isRequired,
}

export default CellHighLight
