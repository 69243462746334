export const TABLE_KEYS = {
  FULLNAME: 'fullName',
  POSITION: 'positionName',
  QUANTITY: 'quantity',
  UPDATE_DATE: 'publicDateId',
}

export const TABLE_SCHEMAS = Object.keys(TABLE_KEYS).map((key) => ({
  colId: TABLE_KEYS[key],
  title: 'fund.fundProfile.profile.fundManagers.' + key,
}))
