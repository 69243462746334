import PropTypes from 'prop-types'

export const DiamondIcon = ({ x, y, color, style }) => {
  const position = x && y ? { x: x - 1, y } : {}

  return (
    <svg
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      version="1.1"
      {...position}
      style={style}
    >
      <title>Rectangle</title>
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="FiinX_Copporate_Peer-&amp;-Valuation_Peer-Comparison_Valuation_Table"
          transform="translate(-414.000000, -271.000000)"
          fill={color}
        >
          <g id="Group-9" transform="translate(125.000000, 209.000000)">
            <rect
              id="Rectangle"
              transform="translate(294.656854, 67.656854) rotate(45.000000) translate(-294.656854, -67.656854) "
              x="290.656854"
              y="63.6568542"
              width="8"
              height="8"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}

DiamondIcon.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  color: PropTypes.string.isRequired,
  style: PropTypes.object,
}

DiamondIcon.defaultProps = {
  color: '#45B29D',
  style: {},
}
