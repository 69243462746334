import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class OutstandingBondProxy extends ProxyBase {
  getDataChart(params) {
    return this.get('Overview/OutstandingBond', params)
  }
}

export default new OutstandingBondProxy(
  ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl,
)
