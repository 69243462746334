import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import { FIELD_NAME } from '../configs'
import style from '../index.module.css'
import {
  selectIncomeStructure,
  selectIncomeStructureLoading,
  selectTimeRange,
  selectYear,
} from '../store/slice'
import { getGetLatestPeriodBank, getIncomeStructure } from '../store/thunk'
import ChildrenTable from './childrenTable/ChildrenTable'
import FilterGroup from './filterGroup/FilterGroup'
import {
  selectDataMapping,
  selectLoading,
  selectMappingToiTable,
  selectMountChildrenTable,
} from './store/slice'
import { getIndicatorMapping } from './store/thunk'
import ToiTable from './toiTable/ToiTable'

const Container = ({ height }) => {
  const dispatch = useDispatch()
  const containerToiRef = useRef(null)
  const { NII_G } = FIELD_NAME
  const [containerToiHeight, setContainerToiHeight] = useState(0)
  const fontSizeGlobal = getFontSize()

  const loading = useSelector(selectLoading)
  const incomeStructureLoading = useSelector(selectIncomeStructureLoading)
  const incomeStructure = useSelector(selectIncomeStructure)
  const mountChildrenTable = useSelector(selectMountChildrenTable)
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const dataMapping = useSelector(selectDataMapping)
  const comGroup = useComGroup(basicInfo?.organizationId)
  const year = useSelector(selectYear)
  const timeRange = useSelector(selectTimeRange)
  const mappingToiTableData = useSelector(selectMappingToiTable)

  useEffect(() => {
    if (containerToiRef.current) {
      const height = containerToiRef.current.getBoundingClientRect().height
      setContainerToiHeight(height)
    }
  })

  useEffect(() => {
    if (containerToiRef.current) {
      const height = containerToiRef.current.getBoundingClientRect().height
      setContainerToiHeight(height)
    }
  }, [fontSizeGlobal, locale, mappingToiTableData])

  useEffect(() => {
    if (comGroup) {
      dispatch(getIndicatorMapping({ ComGroup: comGroup }))
    }
  }, [comGroup, locale])

  useEffect(() => {
    if (basicInfo?.organizationId) {
      dispatch(
        getIncomeStructure({
          OrganizationId: basicInfo?.organizationId,
          Year: year,
          TimeRange: timeRange,
        }),
      )
    }
  }, [basicInfo?.organizationId, year, timeRange, locale])

  useEffect(() => {
    dispatch(getGetLatestPeriodBank())
  }, [])

  return (
    <div className="h-100 w-100">
      {(loading || incomeStructureLoading) && <Loading />}
      {!loading && !incomeStructureLoading && (
        <>
          {dataMapping.length === 0 && <NoData />}
          {dataMapping.length > 0 && (
            <ScrollComponent>
              <div
                className={
                  incomeStructure.length === 0 ? `${style.containerToi}` : ''
                }
                ref={containerToiRef}
              >
                <FilterGroup />
                {incomeStructure.length === 0 ? (
                  <div style={{ flex: 1 }}>
                    <NoData />
                  </div>
                ) : (
                  <ToiTable />
                )}
              </div>
              {mountChildrenTable === NII_G &&
                containerToiHeight !== 0 &&
                incomeStructure.length > 0 && (
                  <ChildrenTable height={height - containerToiHeight} />
                )}
            </ScrollComponent>
          )}
        </>
      )}
    </div>
  )
}

Container.propTypes = {
  height: PropTypes.number,
}

export default Container
