import zaloIcon from './icon-zalo.png'
import style from '../index.module.css'
import { useEffect } from 'react'
import { ZALO_OAID } from '../../../../configs/SocialConfig'
import PropTypes from 'prop-types'
import { IconWrapper } from '../IconWrapper'

export const ZaloIcon = ({ url }) => {
  const initShareZalo = () => {
    const script = document.createElement('script')
    script.src = 'https://sp.zalo.me/plugins/sdk.js'
    document.body.appendChild(script)
    script.onload = () => {
      document.body.removeChild(script)
    }
  }

  useEffect(() => {
    initShareZalo()
  }, [url])

  return (
    <IconWrapper>
      <div
        className="zalo-share-button"
        data-href={url}
        data-oaid={ZALO_OAID}
        data-layout="2"
        data-color="blue"
        data-customize
      >
        <div className={style.img}>
          <img src={zaloIcon} alt="Zalo" width={72} height={72} />
          <span>Zalo</span>
        </div>
      </div>
    </IconWrapper>
  )
}

ZaloIcon.propTypes = {
  url: PropTypes.string.isRequired,
}
