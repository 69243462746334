import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  changeIds,
  changeIndicator,
  resetIndicatorMapping,
  selectFinancialById,
  selectFinancialIds,
  selectFinancialRatioFilter,
  selectFinancialValue,
  selectGroupColumns,
  selectLoading,
  selectMappingLoading,
  selectTableConstants,
  selectTypeOfRatio,
} from '../store/slice'
import { getFinancialDataRatio, getIndicatorMapping } from '../store/thunk'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import { TABLE_ROW_LEVEL } from './constants'

export const FinancialRatioTable = () => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const financialIds = useSelector(selectFinancialIds)
  const tableConstants = useSelector(selectTableConstants)
  const isLoading = useSelector(selectLoading)
  const isMappingLoading = useSelector(selectMappingLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)
  const financialRatioFilter = useSelector(selectFinancialRatioFilter)
  const financialByIds = useSelector(selectFinancialById)
  const typeOfRatio = useSelector(selectTypeOfRatio)

  // Use state
  const [prevComGroup, setPrevComGroup] = useState('')

  // Use effect
  useEffect(() => {
    const row = tableConstants.find(
      (item) => item.key === typeOfRatio.fieldName,
    )
    if (row) {
      const scrollTable = document.querySelector(
        `#tableData .scrollbars div:first-child`,
      )
      const headerTable = scrollTable?.querySelector('thead')
      const activeRow = scrollTable?.querySelector(`[id="${row.index}"]`)

      if (scrollTable && headerTable && activeRow) {
        scrollTable.scrollTop = activeRow.offsetTop - headerTable.offsetHeight
      }
    }
  }, [typeOfRatio])

  useEffect(() => {
    if (
      !isLoading &&
      !isMappingLoading &&
      basicInfo.organizationId &&
      financialIds.length
    ) {
      dispatch(
        getFinancialDataRatio({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: financialRatioFilter.viewBy.value,
          NumberOfPeriod: financialRatioFilter.numberOfPeriod.value,
          LatestYear: financialRatioFilter.lastestYear.value,
          Consolidated: financialRatioFilter.type.value,
        }),
      )
    }
  }, [
    JSON.stringify(financialRatioFilter),
    basicInfo.organizationId,
    financialIds,
  ])

  useEffect(() => {
    if (comGroup) {
      dispatch(
        getIndicatorMapping({
          Statement: 'Ratio',
          ComGroup: comGroup,
          Language: locale,
        }),
      )
    }
  }, [comGroup, locale])

  useEffect(() => {
    if (comGroup && comGroup !== prevComGroup) {
      setPrevComGroup(comGroup)
      dispatch(resetIndicatorMapping())
    }
  }, [comGroup])

  useEffect(() => {
    if (tableConstants.length) {
      dispatch(changeIndicator(tableConstants[1].index))
    }
  }, [financialRatioFilter])

  if (isLoading || isMappingLoading) {
    return <Loading />
  }

  if (!groupColumns.length || !Object.keys(financialByIds).length) {
    return <NoData />
  }

  return (
    <div id="tableData" className="h-100">
      <Table
        ids={financialIds}
        columnDraggable={false}
        getDataFromRedux={selectFinancialValue}
        isLoading={isLoading || isMappingLoading}
        rowDraggable={false}
        changeIds={changeIds}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'left',
                fontSize: 10,
              },
              canCustomTd: true,
              render: (val, rowId, { style, className }) => {
                return (
                  <FirstColumnCell
                    val={val}
                    rowId={rowId}
                    style={{ ...style, minWidth: 400 }}
                    className={className}
                    defaultActiveRowId={financialIds[1]}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, { style, className }) => {
              return (
                <OtherColumnsCell
                  val={val}
                  rowId={rowId}
                  colIndex={index}
                  numCol={groupColumns.length}
                  style={style}
                  className={className}
                  defaultActiveRowId={financialIds[1]}
                />
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        changeActiveRow={changeIndicator}
        disableClickGroups={tableConstants
          .filter((item) => item.level === TABLE_ROW_LEVEL.LEVEL_1)
          .map((item) => item.index)}
        defaultActiveRowId={financialIds[1]}
        resizable={false}
        defaultScrollToRight={true}
      />
    </div>
  )
}
