export const LINE_KEY_WITH_COLOR = {
  13: '#40abff',
  26: '#facc5c',
}

export const AREA_KEY_WITH_COLOR = {
  rangeDeposit: '#668094',
  rangeLoan: '#695a33',
}

export const DEPOSIT_ID = [5, 6]

export const LOAN_ID = [22, 23]

export const MIN_MAX_LINE_COLOR = ['#668094', '#695a33']

export const CHART_FOOTER = [
  {
    vi: 'LS huy động BQ trên 12 tháng',
    en: 'Avg. deposit rate over 12 months ',
    color: '#40abff',
  },
  {
    vi: 'LS cho vay VND BQ trung dài hạn',
    en: 'Avg. medium & long-term loans interest',
    color: '#facc5c',
  },
  {
    vi: 'LS huy động BQ trên 12 tháng Cao nhất/Thấp nhất',
    en: 'Max/Min Deposit rate over 12 months',
    color: '#668094',
  },
  {
    vi: 'LS cho vay VND BQ trung dài hạn  Cao nhất/Thấp nhất',
    en: 'Max/Min medium & long-term loans interest',
    color: '#695a33',
  },
]
