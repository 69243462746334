import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class TradingDataProxy extends ProxyBase {
  getIndicator(params) {
    return this.get('Indicators', params)
  }

  getCorporateResult(params) {
    return this.post('Corporate/Query', params, {}, 'post', true)
  }

  getIndexSectorResult(params) {
    return this.post('IndexSector/Query', params, {}, 'post', true)
  }

  downloadExportExcel(params) {
    return this.post('Export', params, {}, 'download')
  }
}

export default new TradingDataProxy(
  ServiceProxyConfig.Tool.DataExplorer.TradingData.ServiceUrl,
)
