import TopReleaseProxy from '../../../../proxies/bond/overview/IssuesPlan/IssuePlan'
import { ServiceBase } from '../../../ServiceBase'

export class IssuesPlan extends ServiceBase {
  getDataIssuesPlan(params) {
    return this.getData(() => TopReleaseProxy.getDataIssuePlan(params))
  }
  exportFile(params) {
    return this.getFileDownload(() => TopReleaseProxy.exportFileProxy(params))
  }
}

export default new IssuesPlan()
