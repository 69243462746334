import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../selectCustom'
import {
  changeValueDetailPopupTemplate,
  selectValueDetailPopupTemplate,
} from '../store/slice'

const ItemSelect = ({ defaultValue, keySelect, selectData, isI18n }) => {
  const dispatch = useDispatch()

  const value =
    useSelector(selectValueDetailPopupTemplate(keySelect)) || defaultValue

  useEffect(() => {
    dispatch(
      changeValueDetailPopupTemplate({ key: keySelect, value: defaultValue }),
    )
  }, [])

  const handleChange = (value) =>
    dispatch(changeValueDetailPopupTemplate({ key: keySelect, value: value }))

  return (
    <div className="w-100">
      <SelectCustom
        value={value}
        selectData={selectData}
        handleChange={handleChange}
        isI18n={isI18n}
      />
    </div>
  )
}

ItemSelect.propTypes = {
  defaultValue: PropTypes.any,
  keySelect: PropTypes.string.isRequired,
  selectData: PropTypes.array.isRequired,
  isI18n: PropTypes.bool,
}

ItemSelect.defaultProps = {
  defaultValue: '',
  isI18n: false,
}

export default ItemSelect
