import { useMemo } from 'react'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Chart } from './chart'
import { Description } from './Description'

export const Recommendation = ({ width, height }) => {
  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [width, height])

  return (
    <SizeTracker key={getKey}>
      {(size) => (
        <>
          {size.height && (
            <div
              id="consensusRecommendationChart"
              style={{
                height: height - size.height,
              }}
            >
              <Chart width={width} height={height - size.height} />
            </div>
          )}
          {/* height = height - chart's name */}
          <Description height={height - 20} />
        </>
      )}
    </SizeTracker>
  )
}
