import { useState } from 'react'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import style from './index.module.css'

const MARGIN_LEFT = 30

const FirstCellColumn = ({
  val,
  props,
  level,
  childrenIds,
  rowId,
  onRowCollapse,
  index,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const getStyle = (level, index, childrenIds) => {
    if (!index) {
      return { color: '#fecf2f', textTransform: 'uppercase' }
    } else {
      return {
        marginLeft: !childrenIds.length ? (level - 2) * MARGIN_LEFT : 0,
      }
    }
  }

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <td {...props} className={style.firstCellColumn}>
      <div className={`d-flex`} onClick={onCollapse}>
        {childrenIds.length ? (
          <button
            style={{ cursor: 'pointer', paddingLeft: 16 }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <></>
        )}
        <TextEllipsisStaticLine
          appendStyle={getStyle(level, index, childrenIds)}
          val={val}
          isI18n={false}
        />
      </div>
      <div className={style.borderLeftScroll} />
    </td>
  )
}

export default FirstCellColumn
