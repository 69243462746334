import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { listTimeFilter } from '../constants'
import { ChartComponent } from './ChartComponent'
import {
  changeTimeFilter,
  selectChartData,
  selectLoading,
  selectTimeFilter,
} from './store/slice'
import { getChartData } from './store/thunk'

const ChartData = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const dataChart = useSelector(selectChartData)
  const isLoading = useSelector(selectLoading)
  const timeFilter = useSelector(selectTimeFilter)

  const onChangeTab = ({ value }) => {
    dispatch(changeTimeFilter(value))
  }

  useEffect(() => {
    dispatch(getChartData({ TimeFrequency: timeFilter }))
  }, [locale, timeFilter])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex">
                <DispatchActionTab
                  onChangeTab={onChangeTab}
                  data={listTimeFilter.map((item) => ({
                    title: I18n.t(item.label),
                    value: item.value,
                  }))}
                  activeTab={timeFilter}
                />
              </div>
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {dataChart.length ? (
                        <SizeTracker>
                          {(size1) => {
                            return (
                              <>
                                <div
                                  style={{ position: 'relative' }}
                                  id="totalRegisteredFDICapitalChart"
                                >
                                  {size1.height && (
                                    <ChartComponent
                                      data={dataChart}
                                      width={width}
                                      height={
                                        height - size.height - size1.height
                                      }
                                      barKey={'fdiTypeId_6'}
                                      lineKeys={['fdiTypeId_1', 'fdiTypeId_2']}
                                      keyXAxis={'year'}
                                    />
                                  )}
                                  <Footer
                                    key={width}
                                    list={[
                                      {
                                        text: 'economy.fdi.overview.NEW_APPROVAL_PROJECT',
                                        type: TYPE.SQUARE,
                                        before: {
                                          bgColor: '#0096eb',
                                        },
                                      },
                                      {
                                        text: I18n.t(
                                          'economy.fdi.overview.DISBURSED_CAPITAL',
                                        ),
                                        type: TYPE.CIRCLE_MARKER_LINE,
                                        before: {
                                          bgColor: '#ecffdf',
                                        },
                                        after: {
                                          bgColor: '#ecffdf',
                                        },
                                      },
                                      {
                                        text: 'economy.fdi.overview.NEW_AND_ADDITIONAL_REGISTRATION',
                                        type: TYPE.CIRCLE_MARKER_LINE,
                                        before: {
                                          bgColor: '#facc5c',
                                        },
                                        after: {
                                          bgColor: '#facc5c',
                                        },
                                      },
                                    ]}
                                  />
                                </div>
                              </>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default ChartData
