export const ALL_TYPE = 'All'

export const TIME = {
  All: 'All',
  'Last 7 days': 'Last 7 days',
  'Last month': 'Last month',
  'Last 3 months': 'Last 3 months',
  'Last 6 months': 'Last 6 months',
  'Last year': 'Last year',
  Custom: 'Custom',
}
