export const COLOR_STRUCTURE_CHART = [
  '#185999',
  '#40abff',
  '#fff6df',
  '#fe7e6d',
  '#facc5c',
]

export const FOOTER_NAME_BARKEYS = [
  'market.coveredWarrant.cwStatistics.LOCAL_INSTITUTION',
  'market.coveredWarrant.cwStatistics.PROPRIETARY',
  'market.coveredWarrant.cwStatistics.FOREIGN',
  'market.coveredWarrant.cwStatistics.LOCAL_INDIVIDUAL',
]
