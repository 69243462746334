import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { Z_INDEX } from '../../../../../constants/Common'
import { sortStateMap } from '../../tradingStatistics/constants'
import Sort from './Sort'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text, row) => {
    return (
      <Span
        className={`${style.theadContent} ${
          row.alignRight && style.alignRight
        }`}
        style={{}}
      >
        <TextEllipsis text={text} />

        {row.additionalText && (
          <Span className={`${row.additionalText && style.additionText}`}>
            <Translate value={row.additionalText} />
          </Span>
        )}
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={`key-${index}`}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              } ${index === 0 && style.cellLeft} ${style.firstCellHeader} ${
                row.alignRight && style.textRight
              } ${style.noPadding}`}
              onClick={() =>
                sortColumn({
                  [row.keySort]: sortStateMap[stateSort[row.keySort]],
                })
              }
            >
              <div
                style={{
                  padding: '4px 8px',
                  display: 'flex',
                  gap: 4,
                  justifyContent: row.alignRight ? 'flex-end' : 'flex-start',
                }}
              >
                {renderTitle(row.title, row)}

                {!row?.disableSort && !row?.hideIconSort && (
                  <Sort type={stateSort[row.keySort]} />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
