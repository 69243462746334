import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import { renderTimeType } from '../../../primaryMarket/marketStatistics/helper'
import { BAR_COLOR_DEBT_RELATION, TIME_FREQUENCY } from '../constants'
import {
  keys,
  selectDataDebtRelationInBalance,
  selectFilterDebtAndLoan,
  selectLoading,
} from '../store/slice'
import { getDebtRelationInBsData } from '../store/thunk'
import ChartTooltip from './ChartTooltip'

const DebtRelationInBalance = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading(keys.DEBT_RELATION_IN_BALANCE))
  const { data, list } = useSelector(selectDataDebtRelationInBalance)

  const basicInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectFilterDebtAndLoan)

  const [listCheckbox, setListCheckbox] = useState(['totalDebt', 'ownerEquity'])

  const formatDataBarChart = useMemo(() => {
    return data.map((item, id) => ({
      ...item,
      date:
        activeTab === TIME_FREQUENCY.QUARTER
          ? `Q${item.realQuarter}-${item.realYear} ${renderTimeType(
              activeTab,
              `Q${item.realQuarter}-${item.realYear}`,
            )}`
          : `${item.realYear} ${renderTimeType(activeTab, item.realYear)}`,
      totalDebt: valDivBillion(item.totalDebt),
      ownerEquity: valDivBillion(item.bS95),
      ratio: item.rT0027 || 0,
      tts: item.rT0026 || 0,
    }))
  }, [data])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  useEffect(() => {
    if (basicInfo.organizationId) {
      const payload = {
        TimeFrequency: activeTab,
        OrganizationId: basicInfo.organizationId,
        Top: 5,
      }

      dispatch(getDebtRelationInBsData(payload))
    }
  }, [locale, basicInfo.organizationId, activeTab])

  return (
    <Panel
      title="bond.corporateBond.debtRelationInBalance.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.debtRelationInBalance.TITLE')}
      downloadJpgParams={{
        domId: 'debtRelationInBalanceChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: I18n.t('bond.corporateBond.debtRelationInBalance.TITLE'),
        tickerName: '',
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <div id="debtRelationInBalanceChartId">
              <div style={{ height: height }}>
                {isLoading ? (
                  <Loading />
                ) : !!formatDataBarChart.length ? (
                  <>
                    <div style={{ height: height - 34 }}>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - 34}
                        keyXAxis="date"
                        yAxis={[
                          {
                            id: 'left',
                            keys: listCheckbox,
                            orientation: 'left',
                            isStackedBar: true,
                            label: I18n.t(
                              'bond.corporateBond.relativeToEquity.VALUE',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 5,
                            isBarChart: true,
                          },
                          {
                            id: 'right',
                            keys: [list[2]],
                            orientation: 'right',
                            isStackedBar: true,
                            label: '',
                            labelPosition: AXIS_LABEL_POSITION.RIGHT,
                            tickNum: 10,
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            {listCheckbox.includes(list[0]) && (
                              <Bar
                                stackId="stackId1"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[0]}
                                fill={BAR_COLOR_DEBT_RELATION[0]}
                                barSize={20}
                              />
                            )}
                            {listCheckbox.includes(list[1]) && (
                              <Bar
                                stackId="stackId2"
                                isAnimationActive={false}
                                yAxisId="left"
                                dataKey={list[1]}
                                fill={BAR_COLOR_DEBT_RELATION[1]}
                                barSize={20}
                              />
                            )}

                            <Scatter
                              yAxisId="right"
                              dataKey={list[2]}
                              isAnimationActive={false}
                              line
                              fill={BAR_COLOR_DEBT_RELATION[2]}
                              shape={false}
                            />

                            <Scatter
                              yAxisId="right"
                              dataKey={list[3]}
                              line
                              isAnimationActive={false}
                              fill={BAR_COLOR_DEBT_RELATION[3]}
                              shape={false}
                              stroke={BAR_COLOR_DEBT_RELATION[1]}
                              strokeWidth={2}
                              strokeDasharray="5 5"
                            />
                          </>
                        )}
                      </ChartContainer>
                    </div>
                    <Footer
                      key={width}
                      list={[
                        {
                          text: 'bond.corporateBond.debtRelationInBalance.DEBT',
                          dataKey: list[0],
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_DEBT_RELATION[0],
                          },
                        },
                        {
                          text: 'bond.corporateBond.debtRelationInBalance.OWNER',
                          dataKey: list[1],
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_DEBT_RELATION[1],
                          },
                        },
                        {
                          text: 'bond.corporateBond.debtRelationInBalance.RATIO',
                          type: TYPE.LINE,
                          before: {
                            bgColor: BAR_COLOR_DEBT_RELATION[2],
                          },
                        },
                        {
                          text: 'bond.corporateBond.debtRelationInBalance.TTS',
                          type: TYPE.DASHED_LINE,
                          dashedLine: {
                            bgColor: BAR_COLOR_DEBT_RELATION[3],
                          },
                        },
                      ]}
                      appendStyle={{
                        height: 16,
                        marginBottom: 4,
                        marginTop: 8,
                      }}
                      listCheckbox={listCheckbox}
                      setListCheckbox={setListCheckbox}
                    />
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default DebtRelationInBalance
