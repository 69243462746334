export const newsOrReport = {
  ALL_NEWS: 'Tất cả tin tức',
  ANALYTICAL_REPORTS: 'Báo cáo phân tích',
  LATEST_NEWS: 'Tin Mới Nhất',
  DATE: 'NGÀY',
  ISSUER: 'CÔNG TY PH',
  TITLE: 'TIÊU ĐỀ',
  ACTION: ' ',
  SAME_CATEGORY: 'Tin cùng chuyên mục',
  DETAIL: 'Chi tiết',
  ISSUER_TOOLTIP: 'CÔNG TY PHÁT HÀNH',
}
