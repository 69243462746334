import ComparableProxy from '../../../proxies/bond/comparable/comparableProxy'
import { ServiceBase } from '../../ServiceBase'

export class Comparable extends ServiceBase {
  getDataList(params) {
    return this.getData(() => ComparableProxy.getDataProxy(params))
  }
  getCreditRatingCoefficientSv(params) {
    return this.getData(() =>
      ComparableProxy.getCreditRatingCoefficientProxy(params),
    )
  }
  getLiabilitiesToEquityRatioSv(params) {
    return this.getData(() =>
      ComparableProxy.getLiabilitiesToEquityRatioProxy(params),
    )
  }
  getFundsFromOperationsToDebtSv(params) {
    return this.getData(() =>
      ComparableProxy.getFundsFromOperationsToDebtProxy(params),
    )
  }
  getgetDebtToEBITDASv(params) {
    return this.getData(() => ComparableProxy.getDebtToEBITDAProxy(params))
  }
  exportFile(params) {
    return this.getFileDownload(() => ComparableProxy.exportFileProxy(params))
  }
}

export default new Comparable()
