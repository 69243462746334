import CPIAndInflation from '../../economy/cpiAndInflation/CPIAndInflation.js'
import Event from '../../economy/event'
import FDIByCountries from '../../economy/fdi/fdiByCountries/FDIByCountries.js'
import FDIByIndustries from '../../economy/fdi/fdiByIndustries/FDIByIndustries.js'
import FDIByProvinces from '../../economy/fdi/fdiByProvinces/FDIByProvinces.js'
import FDIOverview from '../../economy/fdi/overview/FDIOverview.js'
import StateBudget from '../../economy/fiscal/stateBudget/index.js'
import GDPByProvince from '../../economy/gdp/gdpByProvince/GDPByProvince'
import GDPBySpendingMethod from '../../economy/gdp/gdpBySpendingMethod/GDPBySpendingMethod.js'
import TotalGDP from '../../economy/gdp/totalGDP/TotalGDP.js'
import ExportImportByCommodities from '../../economy/importExport/exportImportByCommodities/ExportImportByCommodities.js'
import ExportImportByCountries from '../../economy/importExport/exportImportByCountries/ExportImportByCountries.js'
import ImportExportOverview from '../../economy/importExport/overview/ImportExportOverview.js'
import ExchangeRate from '../../economy/monetary/exchangeRate/ExchangeRate.js'
import InterestRate from '../../economy/monetary/interestRate/InterestRate.js'
import MoneySuppliesCredit from '../../economy/monetary/moneySuppliesCredit/MoneySuppliesCredit.js'
import OpenMarket from '../../economy/monetary/openMarket/OpenMarket.js'
import PaymentBalance from '../../economy/paymentBalance/PaymentBalance.js'
import Consumption from '../../economy/productionAndConsumption/consumption/index.js'
import Production from '../../economy/productionAndConsumption/production/index.js'
import { NewsReportDetail } from '../../market/newsReport/NewsReportDetail.js'
import { NewsReportMore } from '../../market/newsReport/NewsReportMore.js'

export const ECONOMY_ROUTE = {
  name: 'constants.route.economy.LABEL',
  routeDefault: '/economy/monetary/open-market',
  components: {
    event: {
      route: '/economy/event',
      name: 'constants.route.economy.event.LABEL',
      renderMain: () => <Event />,
    },
    newsAndReport: {
      route: '/economy/newsAndReport',
      name: 'constants.route.economy.newsAndReport.LABEL',
      relatedRoutes: ['/economy/news-report-detail'],
      renderMain: () => (
        <NewsReportMore
          defaultType={84}
          detailRoute="/economy/news-report-detail"
        />
      ),
    },
    'news-report-detail': {
      route: '/economy/news-report-detail',
      name: 'constants.route.market.newsReport.LABEL',
      renderMain: () => (
        <NewsReportDetail
          redirectRoute="/economy/newsAndReport"
          routeForItem="economy"
        />
      ),
      hiddenOnSideBar: true,
    },
    gdp: {
      name: 'constants.route.economy.gdp.LABEL',
      hasChildren: true,
      components: {
        totalGDP: {
          route: '/economy/gdp/total-gdp',
          name: 'constants.route.economy.gdp.totalGDP.LABEL',
          renderMain: () => <TotalGDP />,
        },
        gdpByProvince: {
          route: '/economy/gdp/gdp-by-province',
          name: 'constants.route.economy.gdp.gdpByProvince.LABEL',
          renderMain: () => <GDPByProvince />,
        },
        gdpBySpendingMethod: {
          route: '/economy/gdp/gdp-by-spending-method',
          name: 'constants.route.economy.gdp.gdpBySpendingMethod.LABEL',
          renderMain: () => <GDPBySpendingMethod />,
        },
      },
    },
    cpiAndInflation: {
      route: '/economy/cpi-and-inflation',
      name: 'constants.route.economy.cpiAndInflation.LABEL',
      renderMain: () => <CPIAndInflation />,
    },
    importExport: {
      name: 'constants.route.economy.importExport.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/economy/import-export/overview',
          name: 'constants.route.economy.importExport.overview.LABEL',
          renderMain: () => <ImportExportOverview />,
        },
        exportImportByCommodities: {
          route: '/economy/import-export/export-import-by-commodities',
          name: 'constants.route.economy.importExport.exportImportByCommodities.LABEL',
          renderMain: () => <ExportImportByCommodities />,
        },
        exportImportByCountries: {
          route: '/economy/import-export/export-import-by-countries',
          name: 'constants.route.economy.importExport.exportImportByCountries.LABEL',
          renderMain: () => <ExportImportByCountries />,
        },
      },
    },
    fdi: {
      name: 'constants.route.economy.fdi.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/economy/fdi/overview',
          name: 'constants.route.economy.fdi.overview.LABEL',
          renderMain: () => <FDIOverview />,
        },
        fdiByCountries: {
          route: '/economy/fdi/fdi-by-countries',
          name: 'constants.route.economy.fdi.fdiByCountries.LABEL',
          renderMain: () => <FDIByCountries />,
        },
        fdiByIndustries: {
          route: '/economy/fdi/fdi-by-industries',
          name: 'constants.route.economy.fdi.fdiByIndustries.LABEL',
          renderMain: () => <FDIByIndustries />,
        },
        fdiByProvinces: {
          route: '/economy/fdi/fdi-by-provinces',
          name: 'constants.route.economy.fdi.fdiByProvinces.LABEL',
          renderMain: () => <FDIByProvinces />,
        },
      },
    },
    paymentBalance: {
      route: '/economy/payment-balance',
      name: 'constants.route.economy.paymentBalance.LABEL',
      renderMain: () => <PaymentBalance />,
    },
    monetary: {
      name: 'constants.route.economy.monetary.LABEL',
      hasChildren: true,
      inactive: false,
      components: {
        openMarket: {
          route: '/economy/monetary/open-market',
          name: 'constants.route.economy.monetary.openMarket.LABEL',
          renderMain: () => <OpenMarket />,
          inactive: false,
        },
        'moneySupplies-Credit': {
          route: '/economy/monetary/money-supplies-credit',
          name: 'constants.route.economy.monetary.moneySupplies-Credit.LABEL',
          renderMain: () => <MoneySuppliesCredit />,
          inactive: false,
        },
        exchangeRate: {
          route: '/economy/monetary/exchange-rate',
          name: 'constants.route.economy.monetary.exchangeRate.LABEL',
          renderMain: () => <ExchangeRate />,
          inactive: false,
        },
        interestRate: {
          route: '/economy/monetary/interest-rate',
          name: 'constants.route.economy.monetary.interestRate.LABEL',
          renderMain: () => <InterestRate />,
          inactive: false,
        },
      },
    },
    fiscal: {
      name: 'constants.route.economy.fiscal.LABEL',
      hasChildren: true,
      components: {
        stateBudget: {
          route: '/economy/fiscal/stateBudget',
          name: 'constants.route.economy.fiscal.stateBudget.LABEL',
          renderMain: () => <StateBudget />,
        },
      },
    },
    productionAndConsumption: {
      name: 'constants.route.economy.productionAndConsumption.LABEL',
      hasChildren: true,
      components: {
        production: {
          route: '/economy/production-and-consumption/production',
          name: 'constants.route.economy.productionAndConsumption.production.LABEL',
          renderMain: () => <Production />,
        },
        consumption: {
          route: '/economy/production-and-consumption/consumption',
          name: 'constants.route.economy.productionAndConsumption.consumption.LABEL',
          renderMain: () => <Consumption />,
        },
      },
    },
  },
}
