import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import CreditRatio from './creditRatio'
import DebtDetails from './debtDetails'
import DebtRelationInBalance from './debtRelationInBalance'
import DebtStatistic from './debtStatistic'
import ScaleOfDebtByObjects from './scaleOfDebtByObjects'

const MAP_KEY = {
  DEBT_RELATION_IN_BALANCE: 'DEBT_RELATION_IN_BALANCE',
  SCALE_OF_DEBT_BY_OBJECTS: 'SCALE_OF_DEBT_BY_OBJECTS',
  SCALE_OF_DEBT: 'SCALE_OF_DEBT',
  DEBT_DETAIL: 'DEBT_DETAIL',
  CREDIT_RATIO: 'CREDIT_RATIO',
}

const MAP_SIZE = {
  [`${MAP_KEY.DEBT_RELATION_IN_BALANCE}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.SCALE_OF_DEBT_BY_OBJECTS,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.SCALE_OF_DEBT,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.DEBT_DETAIL,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    verticalResize: [
      {
        key: MAP_KEY.SCALE_OF_DEBT_BY_OBJECTS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.SCALE_OF_DEBT_BY_OBJECTS}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.CREDIT_RATIO,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.DEBT_DETAIL,
        ratioWidth: -1,
        ratioLeft: 0,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.SCALE_OF_DEBT}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    horizontalResize: [
      {
        key: MAP_KEY.DEBT_RELATION_IN_BALANCE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    verticalResize: [
      {
        key: MAP_KEY.DEBT_DETAIL,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.DEBT_DETAIL}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CREDIT_RATIO}`]: {
    width: `calc(100% / 3)`,
    height: '100%',
    top: 0,
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2 + COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <DebtRelationInBalance
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DEBT_RELATION_IN_BALANCE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ScaleOfDebtByObjects
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SCALE_OF_DEBT_BY_OBJECTS}
        sizes={sizes}
        setSizes={setSizes}
      />
      <DebtStatistic
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SCALE_OF_DEBT}
        sizes={sizes}
        setSizes={setSizes}
      />
      <DebtDetails
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DEBT_DETAIL}
        sizes={sizes}
        setSizes={setSizes}
      />
      <CreditRatio
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CREDIT_RATIO}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
