import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundHoldingService from '../../../../../core/services/fund/FundHoldingService'
import { handleExport } from '../../../../utils/Export'
import { TABLE_TABS } from '../constants'

export const getListTickersSelectThunk = createAsyncThunk(
  'fund/fundCenter/holding/GET_LIST_TICKER_SELECT',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FundHoldingService.getListTickersSelect(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTableDataThunk = createAsyncThunk(
  'fund/fundCenter/holding/GET_TABLE_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params, tableTab } = data
      let response = {}
      switch (tableTab) {
        case TABLE_TABS.ALL:
          response = await FundHoldingService.getAllFundHolding(params)
          break
        case TABLE_TABS.DETAIL:
          response = await FundHoldingService.getDetailFundHolding(params)
          break
        default:
          break
      }
      if (response.success) {
        return {
          data: response.data,
          totalPage: response.data.length
            ? Math.ceil(response.data[0].totalRow / params.PageSize)
            : 1,
        }
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadAllFundHolding = createAsyncThunk(
  'fund/fundCenter/holding/DOWNLOAD_ALL_HOLDING',
  async (data) => {
    const response = await FundHoldingService.downloadAllFundHolding(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadDetailFundHolding = createAsyncThunk(
  'fund/fundCenter/holding/DOWNLOAD_DETAIL_HOLDING',
  async (data) => {
    const response = await FundHoldingService.downloadDetailFundHolding(data)
    handleExport(response.data, response.filename)
  },
)
