import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { getThreeColorByValue } from '../../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../../../utils/Value'
import {
  GROUP_COLUMNS_DETAIL,
  GROUP_COLUMNS_OVERVIEW,
  GROUP_COLUMNS_TICKER,
  TABLE_TYPE,
} from './constants'
import style from './index.module.css'
import { LightBorderColumnCell } from './LightBorderColumnCell'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  const BORDER_DARK = '1px solid #2b303a'
  const BORDER_LIGHT = '1px solid #546373'

  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
      let childrenGroupColumns = [
        GROUP_COLUMNS_OVERVIEW[2],
        GROUP_COLUMNS_OVERVIEW[3],
        GROUP_COLUMNS_OVERVIEW[5],
        GROUP_COLUMNS_OVERVIEW[6],
      ]
      let schemaGroupColumns = GROUP_COLUMNS_OVERVIEW.filter(
        (item) => !item.key.includes('Parent'),
      )

      result.schema = schemaGroupColumns.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={formatDateTime(val, FORMAT.DATE, locale, timeZone)}
                rowId={rowId}
                isAlignTextLeft
              />
            ),
          }
        }
        if ([2, 4, 5, 6].includes(index)) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={
                  index === 5
                    ? formatVal(valDivThousand(val))
                    : formatVal(valDivMillion(val))
                }
                rowId={rowId}
              />
            ),
          }
        }
        if (![0, 2, 4, 5, 6].includes(index)) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return formatVal(valDivThousand(val))
            },
          }
        }
        return result
      })

      result.rowSpanSchema = [
        [
          {
            colId: GROUP_COLUMNS_OVERVIEW[0].key,
            renderTh: () => (
              <div>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[0].title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[1].key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[1].title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
              top: 0,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[4].key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[4].title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[7].key,
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[7].title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
          },
          {
            colId: GROUP_COLUMNS_OVERVIEW[8].key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={GROUP_COLUMNS_OVERVIEW[8].title} />
                </Span>
              </div>
            ),
          },
        ],
        [
          {
            title: childrenGroupColumns[0].title,
            colId: childrenGroupColumns[0].key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
            thClassName: style.customTableStickyHead,
          },
          ...childrenGroupColumns.slice(1, 4).map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight: [1, 3].includes(childrenGroupColumns.indexOf(item))
                ? BORDER_LIGHT
                : BORDER_DARK,
            },
          })),
        ],
      ]

      let tableHeadRowTop = {}
      childrenGroupColumns.slice(0, 2).forEach((element) => {
        tableHeadRowTop[element.key] = ['orderMatchingParent']
      })
      childrenGroupColumns.slice(2, 4).forEach((element) => {
        tableHeadRowTop[element.key] = ['dealParent']
      })
      result.tableHeadRowTop = tableHeadRowTop
      break
    case TABLE_TYPE.DETAIL:
      let childrenGroupColumnsDetail = GROUP_COLUMNS_DETAIL.filter((item) =>
        [
          'totalMatchVolume',
          'totalMatchValue',
          'totalDealVolume',
          'totalDealValue',
        ].includes(item.key),
      )
      let schemaGroupColumnsDetail = GROUP_COLUMNS_DETAIL.filter(
        (item) => !item.key.includes('Parent'),
      )
      let schemaDetail = schemaGroupColumnsDetail.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId, { style, className }) => (
              <LightBorderColumnCell
                val={formatDateTime(val, FORMAT.DATE, locale, timeZone)}
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={className}
              />
            ),
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId, { style, className }) => {
              const activeId = dataTable[rowId]?.activeStatusId || 0
              const organizationId = dataTable[rowId]?.coveredWarrantId || 0
              return (
                <LightBorderColumnCell
                  val={val}
                  rowId={rowId}
                  isAlignTextLeft
                  styleProps={style}
                  className={className}
                  isCWDetail={activeId === 1}
                  organizationId={organizationId}
                />
              )
            },
          }
        }
        if (
          [
            'percentPriceChange',
            'underlyingAssetPrice',
            'breakEvenPrice',
            'avgPrice5Days',
            'premiumPercentage',
            'totalVolume',
            'totalValue',
            'totalMatchValue',
            'totalDealValue',
          ].includes(item.key)
        ) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={
                  ['percentPriceChange'].includes(item.key)
                    ? valToPercent(val)
                    : ['premiumPercentage'].includes(item.key)
                    ? valToPercent(val)
                    : [
                        'underlyingAssetPrice',
                        'breakEvenPrice',
                        'avgPrice5Days',
                        'totalVolume',
                      ].includes(item.key)
                    ? formatVal(valDivThousand(val))
                    : formatVal(valDivMillion(val))
                }
                rowId={rowId}
                styleProps={
                  ['percentPriceChange', 'premiumPercentage'].includes(
                    item.key,
                  ) && val !== EMPTY_VALUE
                    ? {
                        color: getThreeColorByValue(val),
                      }
                    : {}
                }
              />
            ),
          }
        }
        if (['totalMatchVolume', 'totalDealVolume'].includes(item.key)) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return formatVal(valDivThousand(val))
            },
          }
        }
        return result
      })
      result.schema = schemaDetail

      let rowSpanSchemaDetail = [
        [
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            [
              'tradingDateId',
              'cwCode',
              'percentPriceChange',
              'underlyingAssetPrice',
              'breakEvenPrice',
              'avgPrice5Days',
              'premiumPercentage',
            ].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div
                style={{
                  textAlign: ['tradingDateId', 'cwCode'].includes(item.key)
                    ? 'left'
                    : 'right',
                }}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
          })),
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            ['totalMatchParent', 'totalDealParent'].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div style={{ textAlign: 'center', textTransform: 'uppercase' }}>
                <Span style={{ fontSize: 10 }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
              top: 0,
            },
          })),
          ...GROUP_COLUMNS_DETAIL.filter((item) =>
            ['totalVolume', 'totalValue'].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
          })),
        ],
        [
          {
            title: childrenGroupColumnsDetail[0].title,
            colId: childrenGroupColumnsDetail[0].key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
            thClassName: style.customTableStickyHead,
          },
          ...childrenGroupColumnsDetail.slice(1, 4).map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight: [1, 3].includes(
                childrenGroupColumnsDetail.indexOf(item),
              )
                ? BORDER_LIGHT
                : BORDER_DARK,
            },
          })),
        ],
      ]
      result.rowSpanSchema = rowSpanSchemaDetail

      let tableHeadRowTopDetail = {}
      childrenGroupColumnsDetail.slice(0, 2).forEach((element) => {
        tableHeadRowTopDetail[element.key] = ['totalMatchParent']
      })
      childrenGroupColumnsDetail.slice(2, 4).forEach((element) => {
        tableHeadRowTopDetail[element.key] = ['totalDealParent']
      })
      result.tableHeadRowTop = tableHeadRowTopDetail
      break
    case TABLE_TYPE.TICKER:
      let childrenGroupColumnsTicker = GROUP_COLUMNS_TICKER.filter((item) =>
        [
          'totalMatchVolume',
          'totalMatchValue',
          'totalDealVolume',
          'totalDealValue',
        ].includes(item.key),
      )
      let schemaGroupColumnsTicker = GROUP_COLUMNS_TICKER.filter(
        (item) => !item.key.includes('Parent'),
      )
      let schemaTicker = schemaGroupColumnsTicker.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId, { style, className }) => (
              <LightBorderColumnCell
                val={formatDateTime(val, FORMAT.DATE, locale, timeZone)}
                rowId={rowId}
                isAlignTextLeft
                styleProps={style}
                className={className}
              />
            ),
          }
        }
        if (
          [
            'percentPriceChange',
            'openPriceAdjusted',
            'highPriceAdjusted',
            'lowPriceAdjusted',
            'closePriceAdjusted',
            'totalValue',
            'totalMatchValue',
            'totalDealValue',
          ].includes(item.key)
        ) {
          result = {
            ...result,
            isI18n: false,
            canCustomTd: true,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            tdStyle: {
              textAlign: 'right',
              borderRight: BORDER_LIGHT,
            },
            render: (val, rowId) => (
              <LightBorderColumnCell
                val={
                  item.key === 'percentPriceChange'
                    ? valToPercent(val)
                    : [
                        'totalValue',
                        'totalMatchValue',
                        'totalDealValue',
                      ].includes(item.key)
                    ? formatVal(valDivMillion(val))
                    : formatVal(valDivThousand(val))
                }
                rowId={rowId}
                styleProps={
                  item.key === 'percentPriceChange'
                    ? {
                        color: getThreeColorByValue(val),
                      }
                    : {}
                }
              />
            ),
          }
        }
        if (
          ['totalMatchVolume', 'totalDealVolume', 'totalVolume'].includes(
            item.key,
          )
        ) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return formatVal(valDivThousand(val))
            },
          }
        }
        return result
      })
      result.schema = schemaTicker

      let rowSpanSchemaTicker = [
        [
          ...GROUP_COLUMNS_TICKER.filter((item) =>
            [
              'tradingDateId',
              'percentPriceChange',
              'openPriceAdjusted',
              'highPriceAdjusted',
              'lowPriceAdjusted',
              'closePriceAdjusted',
            ].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div
                style={{
                  textAlign: ['tradingDateId'].includes(item.key)
                    ? 'left'
                    : 'right',
                }}
              >
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
          })),
          ...GROUP_COLUMNS_TICKER.filter((item) =>
            ['totalMatchParent', 'totalDealParent'].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            renderTh: () => (
              <div style={{ textAlign: 'center' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
            colSpan: 2,
            thStyle: {
              textAlign: 'left',
              verticalAlign: 'baseline',
              borderRight: BORDER_LIGHT,
              top: 0,
            },
          })),
          ...GROUP_COLUMNS_TICKER.filter((item) =>
            ['totalVolume', 'totalValue'].includes(item.key),
          ).map((item) => ({
            colId: item.key,
            rowSpan: 2,
            thStyle: {
              borderRight: BORDER_LIGHT,
            },
            renderTh: () => (
              <div style={{ textAlign: 'right' }}>
                <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
                  <Translate value={item.title} />
                </Span>
              </div>
            ),
          })),
        ],
        [
          {
            title: childrenGroupColumnsTicker[0].title,
            colId: childrenGroupColumnsTicker[0].key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
            },
            thClassName: style.customTableStickyHead,
          },
          ...childrenGroupColumnsTicker.slice(1, 4).map((item) => ({
            title: item.title,
            colId: item.key,
            thStyle: {
              fontSize: 10,
              textAlign: 'right',
              borderRight: [1, 3].includes(
                childrenGroupColumnsTicker.indexOf(item),
              )
                ? BORDER_LIGHT
                : BORDER_DARK,
            },
          })),
        ],
      ]
      result.rowSpanSchema = rowSpanSchemaTicker

      let tableHeadRowTopTicker = {}
      childrenGroupColumnsTicker.slice(0, 2).forEach((element) => {
        tableHeadRowTopTicker[element.key] = ['totalMatchParent']
      })
      childrenGroupColumnsTicker.slice(2, 4).forEach((element) => {
        tableHeadRowTopTicker[element.key] = ['totalDealParent']
      })
      result.tableHeadRowTop = tableHeadRowTopTicker
      break
    default:
  }

  return result
}
