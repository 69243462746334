import PriceBoardProxy from '../../../../proxies/bond/corporateBond/secondaryMarket/PriceBoardProxy'
import { ServiceBase } from '../../../ServiceBase'

export class PriceBoardService extends ServiceBase {
  getPriceBoardMatched(params) {
    return this.getData(() => PriceBoardProxy.getPriceBoardMatched(params))
  }
  getPriceBoardDeal(params) {
    return this.getData(() => PriceBoardProxy.getPriceBoardDeal(params))
  }
  updateBookmark(params) {
    return this.getData(() => PriceBoardProxy.updateBookmark(params))
  }
}

export default new PriceBoardService()
