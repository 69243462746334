import { Translate } from 'react-redux-i18n'
import { Input } from '../../../../../common/html/Input'
import styles from './style.module.css'

const MIN_XAXIS_ANGLE = 0
const MAX_XAXIS_ANGLE = 180

export const RotateText = ({ value, disabled, onChange }) => {
  const getValue = () => {
    let val = value

    if (typeof value === 'undefined') {
      val = 0
    }

    return val + '°'
  }

  const handleChange = (e) => {
    if (disabled) return

    let newVal = e.target.value.replace('°', '')

    if (newVal && (typeof +newVal !== 'number' || isNaN(+newVal))) {
      return
    }

    if (newVal) {
      newVal = +newVal
    }

    if (newVal < MIN_XAXIS_ANGLE) {
      newVal = MIN_XAXIS_ANGLE
    }

    if (newVal > MAX_XAXIS_ANGLE) {
      newVal = MAX_XAXIS_ANGLE
    }

    onChange(newVal)
  }

  const handleIncrease = () => {
    if (disabled) return
    onChange((value || 0) + 1)
  }

  const handleDecrease = () => {
    if (disabled) return
    onChange((value || 0) - 1)
  }

  return (
    <div
      style={{
        opacity: disabled ? 0.5 : 1,
      }}
      className={styles.popupEditName}
    >
      <div className="mr-8">
        <Translate value="financialChart.ROTATE_TEXT" />
      </div>
      <div className={styles.inputFontSize}>
        <i
          className={[
            'icon-caret-top',
            styles.iconCaretTop,
            value >= MAX_XAXIS_ANGLE ? styles.disableSetting : '',
          ].join(' ')}
          onClick={handleIncrease}
        />
        <Input
          type="string"
          value={getValue()}
          disabled={disabled}
          onChange={handleChange}
        />
        <i
          className={[
            'icon-caret-down',
            styles.iconCaretDown,
            value <= MIN_XAXIS_ANGLE ? styles.disableSetting : '',
          ].join(' ')}
          onClick={handleDecrease}
        />
      </div>
    </div>
  )
}
