import { I18n } from 'react-redux-i18n'
import { TYPE } from '../../../../common/chart/footer/Item'
import { valDivTrillion } from '../../../../utils/Value'
import { STACK_COLORS } from './constants'

export const getTooltipContentData = (
  data,
  stackKey,
  keyWithTitle,
  isValue,
) => {
  const stackContent = stackKey?.map((item) => {
    const key = isValue ? item : parseInt(item)
    const content = keyWithTitle.find(
      (element) => element.sbvTotalLiquidityVNTypeId === key,
    )
    return {
      label: content?.sbvTotalLiquidityVNTypeName,
      value: data[item],
    }
  })

  return [
    ...stackContent,
    {
      label: I18n.t('economy.monetary.monetary.TOTAL_CREDIT'),
      value: data['lineKey'],
      key: 'lineKey',
    },
  ]
}

export const renderListFooter = (keyWithTitle) => {
  const MAX_STACK = 6
  const dataLength =
    keyWithTitle.length > MAX_STACK ? MAX_STACK : keyWithTitle.length
  const footerStack = keyWithTitle.map((item, index) => {
    return {
      text: item.sbvTotalLiquidityVNTypeName,
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS?.[dataLength]?.[index],
      },
      isI18n: false,
    }
  })

  return [
    ...footerStack,
    {
      text: I18n.t('economy.monetary.monetary.TOTAL_CREDIT'),
      type: TYPE.CIRCLE_MARKER_LINE,
      before: {
        bgColor: '#f7959c',
      },
      after: {
        bgColor: '#f7959c',
      },
      isI18n: false,
    },
  ]
}

export const handleComboChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const monthYearKeys = handleDuplicateMonthYear(payload)
  return monthYearKeys.map((item) => {
    const dataWithMonthYear = payload
      .filter(
        (element) => element.year === item.year && element.month === item.month,
      )
      .reduce(
        (previous, current) => ({
          ...previous,
          [current.sbvTotalLiquidityVNTypeId]: valDivTrillion(current.value),
          [`${current.sbvTotalLiquidityVNTypeId}-proportionValue`]:
            current.compositionPercentage * 100,
          monthYear: item.monthYear,
        }),
        {},
      )

    return {
      ...dataWithMonthYear,
      lineKey: valDivTrillion(item.valueComposition),
    }
  })
}

export const handleDuplicateMonthYear = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload
    .filter((item, index, arr) => {
      return (
        index ===
        arr.findIndex(
          (element) =>
            element.month === item.month && element.year === item.year,
        )
      )
    })
    .map((item) => {
      const monthYear = addCharacter0(`${item.month}-${item.year}`)
      return {
        ...item,
        monthYear,
      }
    })
}

const addCharacter0 = (monthYearString) => {
  const DEFAULT_LENGTH = 7
  if (monthYearString.length < DEFAULT_LENGTH) {
    return `0${monthYearString}`
  }

  return monthYearString
}

export const handleDuplicateId = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.filter((item, index, arr) => {
    return (
      index ===
      arr.findIndex(
        (element) =>
          element.sbvTotalLiquidityVNTypeId === item.sbvTotalLiquidityVNTypeId,
      )
    )
  })
}
