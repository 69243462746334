import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { setFilterToDefault } from '../store/slice'
import ExchangeFilter from './FilterExchange'
import MarketCap from './FilterMarketCap'
import SectorFilter from './FilterSector'
import TimeRange from './FilterTimeRange'
import FilterTop from './FilterTop'
import MyWatchListFilter from './MyWatchList'
import style from './style.module.css'

const Filter = () => {
  const dispatch = useDispatch()
  const resetFilter = () => {
    dispatch(setFilterToDefault())
  }
  return (
    <div
      className="wrapper-tool h-100 d-flex"
      style={{ flexDirection: 'column', position: 'relative' }}
    >
      <div>
        <FilterTop />
        <TimeRange />
        <ExchangeFilter />
        <SectorFilter />
        <MyWatchListFilter />
        <MarketCap />
      </div>
      <div className={style.btnResetFilter}>
        <Button
          style={{ fontSize: 11 }}
          className="btn bg-grey-1 w-80 h-20 mr-8"
          onClick={resetFilter}
        >
          <Translate value={'common.button.BUTTON_RESET'} />
        </Button>
      </div>
    </div>
  )
}

export default Filter
