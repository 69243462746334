import moment from 'moment'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import FundCenterService from '../../../../../../core/services/fund/FundCenterService'
import BasicChart from '../../../../../common/chart/basicChart'
import { DEFAULT_PRE_VALUE_KEY } from '../../../../../common/chart/basicChart/constants'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import {
  useCheckTimeRangeValid,
  useFormatDateTimeByTimeRange,
} from '../../../../../common/hooks/useDateTime'
import UseI18n from '../../../../../common/hooks/useI18n'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { TextNote } from '../../../../../common/textNote'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { FUND_STRUCTURE_OPTIONS, FUND_TYPE_OPTIONS } from '../../../constants'
import { PERFORMANCE_FUND_FLOW_TIME_RANGES } from '../../constants'
import { Filter } from '../filter'
import { formatDataApi, getSchema, renderTooltipContent } from './helpers'
import { changeFilter, selectFilters } from './store/slice'

export const FundFlow = ({ id, width, height }) => {
  const dispatch = useDispatch()
  const { getTimeRangeCheckValid } = useCheckTimeRangeValid()
  const { formatDateTimeByTimeRange } = useFormatDateTimeByTimeRange()

  const yAxisText = UseI18n(
    'fund.fundCenter.overview.performance.FUND_FLOW_Y_AXIS',
  )
  const yAxisAccText = UseI18n(
    'fund.fundCenter.overview.performance.FUND_FLOW_ACC_Y_AXIS',
  )

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)

  const [data, setData] = useState([])

  const getParams = useMemo(() => {
    return {
      FundStructureIds:
        filters.structure.join(',') ||
        FUND_STRUCTURE_OPTIONS.map((item) => item.value).join(','),
      FundTypeIds:
        filters.type.join(',') ||
        FUND_TYPE_OPTIONS.map((item) => item.value).join(','),
      TimeRange:
        filters.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : filters.timeRange,
      From: filters.timeRange === calendarTabKey ? filters.startDate : '',
      To: filters.timeRange === calendarTabKey ? filters.endDate : '',
    }
  }, [filters])

  const getSourceNumber = useMemo(() => {
    return data.length > 0 && data[0].source ? data[0].source : 0
  }, [JSON.stringify(data)])

  const getTimeRangeValid = useMemo(
    () =>
      getTimeRangeCheckValid({
        timeRange: filters.timeRange,
        startDate: filters.startDate,
        endDate: filters.endDate,
      }),
    [filters.timeRange, filters.startDate, filters.endDate],
  )

  const handleChange = (value) => {
    dispatch(changeFilter(value))
  }

  const renderTooltipTitle = (data) => {
    const startOfWeek = moment(data.endDateId).startOf('week').toString()
    const endOfWeek = moment(data.endDateId)
      .endOf('week')
      .subtract(2, 'day')
      .toString()
    return (
      formatDateTime(startOfWeek, FORMAT.DATE, locale) +
      ' - ' +
      formatDateTime(endOfWeek, FORMAT.DATE, locale)
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter
              {...filters}
              isStructure
              isType
              isNote
              isTimeRange
              timeRangeOptions={PERFORMANCE_FUND_FLOW_TIME_RANGES}
              className="mb-24"
              onChange={handleChange}
            />
            {size.height && (
              <BasicChart
                id={id}
                width={width}
                height={height - size.height}
                params={getParams}
                serviceApi={(params) =>
                  FundCenterService.getListFundFlow(params)
                }
                formatDataApi={(data) =>
                  formatDataApi({
                    data,
                    filters,
                    getTimeRangeCheckValid,
                    formatDateTimeByTimeRange,
                  })
                }
                onGetDataApi={setData}
                schema={getSchema()}
                chartProps={{
                  chartType: 'ComposedChart',
                  yAxis: [
                    {
                      yAxisId: 'fundFlow',
                      dataKeys: [`${DEFAULT_PRE_VALUE_KEY}1`],
                      labelText: yAxisText,
                    },
                    {
                      yAxisId: 'accFundFlow',
                      dataKeys: [`${DEFAULT_PRE_VALUE_KEY}2`],
                      orientation: 'right',
                      labelText: yAxisAccText,
                      labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      isLineChart: true,
                    },
                  ],
                }}
                tooltipProps={{
                  title: getTimeRangeValid.isLessThanSixMonth
                    ? renderTooltipTitle
                    : undefined,
                  render: renderTooltipContent,
                }}
              />
            )}
            <TextNote
              value={
                <Translate
                  value="fund.fundCenter.overview.performance.SOURCE_FUND"
                  total={getSourceNumber}
                />
              }
              fontSize={11}
              className="mb-8"
            />
          </>
        )
      }}
    </SizeTracker>
  )
}
