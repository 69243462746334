import { keyBy } from '../../../../../../utils/Common'
import { tableConstants, tableFormat } from './constants'

export const formatTableData = (state, action) => {
  const groupColumns = [{ key: 'name', title: '' }]
  const valueArr = tableConstants.map((item) => ({ ...item }))
  const tempObj = {}

  action.payload.forEach((item) =>
    tempObj[`${item.ticker}-${item.organizationId}`]
      ? (tempObj[`${item.ticker}-${item.organizationId}`][item.indicator] =
          item.value)
      : (tempObj[`${item.ticker}-${item.organizationId}`] = {
          organizationId: item.organizationId,
          ticker: item.ticker,
          [item.indicator]: item.value,
        }),
  )

  Object.values(tempObj)
    .filter((item) => item.ticker)
    .forEach((item) => {
      const key = `column${item.ticker}`

      groupColumns.push({
        key: key,
        title: item.ticker,
      })

      valueArr.forEach((element) => {
        element[key] = item[element.id] / tableFormat[element.id]
      })
    })

  return { groupColumns, valueByIds: keyBy(valueArr, 'id') }
}
