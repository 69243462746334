import SelectCustom from '../../../common/selectCustom'
import { Title } from './Title'
import style from './index.module.css'
import { CRITERIAS } from '../constants'
import { useDispatch, useSelector } from 'react-redux'
import { changeCriteria, selectCriteria } from '../store/slice'

export const Criteria = () => {
  const dispatch = useDispatch()
  const criteria = useSelector(selectCriteria)
  return (
    <div className={style.item}>
      <Title value="market.heatmap.CRITERIA" />
      <SelectCustom
        isI18n={true}
        value={criteria}
        selectData={Object.keys(CRITERIAS).map((key) => {
          return {
            name: `market.heatmap.criterias.${key}`,
            value: CRITERIAS[key],
          }
        })}
        handleChange={(value) => {
          dispatch(changeCriteria(value))
        }}
      />
    </div>
  )
}
