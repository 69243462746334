import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Span } from '../../../../../common/html/Span'
import { formatVal, valDivBillion } from '../../../../../utils/Value'

const BAR_COLOR = '#0868ae'
const DIAMOND_COLOR = '#f7b80c'
const YAXIS_LEFT = 'YAXIS_LEFT'
const YAXIS_RIGHT = 'YAXIS_RIGHT'

export const ChartComponent = ({
  data,
  width,
  height,
  barKey,
  pointKey,
  keyXAxis,
  typeFilter,
}) => {
  const formatData = formatDataChart(data, barKey, pointKey)
  if (formatData.length <= 0) {
    return <div style={{ height }} />
  }

  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(
          `corporate.businessModel.penetrationAnalysis.${typeFilter}`,
        ),
        value: formatVal(tooltipData[barKey], decimalLengths[YAXIS_LEFT] || 2),
      },
      {
        label: I18n.t('corporate.businessModel.penetrationAnalysis.YOY_GROWTH'),
        value:
          formatVal(tooltipData[pointKey], decimalLengths[YAXIS_RIGHT] || 2) +
          '%',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="d-flex ali-center justify-content-center">
      <ChartContainer
        data={formatData}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        isUseXAxisDiv
        margin={{ ...MARGIN_RECHARTS, right: 14 }}
        showAllTick
        yAxis={[
          {
            id: YAXIS_LEFT,
            keys: [barKey],
            orientation: 'left',
            isBarChart: true,
            label: I18n.t(
              'corporate.businessModel.penetrationAnalysis.BILLION',
            ),
            labelPosition: AXIS_LABEL_POSITION.LEFT,
          },
          {
            id: YAXIS_RIGHT,
            keys: [pointKey],
            orientation: 'right',
            isLineChart: true,
            labelPosition: AXIS_LABEL_POSITION.RIGHT,
            unitYAxis: '%',
          },
        ]}
        renderCustomTooltip={renderTooltip}
      >
        {({ chartContentWidth }) => (
          <>
            <Bar
              isAnimationActive={false}
              yAxisId={YAXIS_LEFT}
              dataKey={barKey}
              barSize={getColumnSizeInBarChart(
                chartContentWidth,
                formatData.length,
              )}
              fill={BAR_COLOR}
            />
            <Scatter
              yAxisId={YAXIS_RIGHT}
              dataKey={pointKey}
              fill={DIAMOND_COLOR}
              isAnimationActive={false}
              shape={
                <ScatterDiamondShape dataKey={pointKey} color={DIAMOND_COLOR} />
              }
            />
          </>
        )}
      </ChartContainer>
    </div>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  barKey: PropTypes.string.isRequired,
  pointKey: PropTypes.string.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export const formatDataChart = (data, barKey, pointKey) => {
  if (barKey) {
    return data.map((item) => ({
      ...item,
      [barKey]: item[barKey] ? valDivBillion(item[barKey]) : item[barKey],
      [pointKey]: item[pointKey] ? item[pointKey] * 100 : item[pointKey],
    }))
  }
  return data
}
