import { getMonthKey } from '../tableStatistics/helper'

export const convertData = (item, keysNotIncluded) => {
  const convertData = {}
  for (const key in item) {
    convertData[key] = keysNotIncluded.includes(key)
      ? item[key]
      : item[key] * 100
  }
  return convertData
}

export const getTimeKey = (isAcc, locale, month, year) => {
  const getAccMonth = () => {
    if (month === 1) {
      if (locale === 'en') {
        return 'Jan'
      } else {
        return 'Th01'
      }
    } else {
      if (locale === 'en') {
        return `${month}M`
      } else {
        return `${month}Th`
      }
    }
  }

  return month
    ? isAcc
      ? `${getAccMonth()}-${year}`
      : `${
          locale === 'en' ? getMonthKey(month) : `Th${getMonthKey(month)}`
        }-${year}`
    : year
}
