import { useSelector } from 'react-redux'
import { OverviewStatistics } from '.'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import { selectFilter } from './store/slice'
import { downloadOverviewStatistics } from './store/thunk'

export const PanelOverviewStatistics = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const filterData = useSelector(selectFilter)

  const timeZone = UseTimeZone()

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  return (
    <Panel
      title="market.coveredWarrant.cwStatistics.PRICE_DATA"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadOverviewStatistics({
          From: filterData?.dateFrom
            ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
            : firstDateOfCurrentYear,
          To: filterData?.dateTo
            ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
            : new Date(),
          Status: filterData?.status ? [filterData?.status] : null,
          UnderlyingIds: filterData?.currentUnderlyingId,
          Issuers: filterData?.currentIssuerId,
        })
      }
    >
      <OverviewStatistics
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
