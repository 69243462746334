export const COMPANY_GROWTH_LINE_KEYS = [
  'productionBlackPipeGrowth',
  'productionCoatedPipeGrowth',
  'totalProductionGrowth',

  'consumptionBlackPipeGrowth',
  'consumptionCoatedPipeGrowth',
  'totalConsumptionGrowth',

  'inventoryBlackPipeGrowth',
  'inventoryCoatedPipeGrowth',
  'totalInventoryGrowth',
]

export const TITLE_LINE_KEYS = [
  'sector.sectorSpecific.steelPipe.BLACK_PIPE',
  'sector.sectorSpecific.steelPipe.COATED_PIPE',
  'sector.sectorSpecific.steelPipe.TOTAL',
]

export const LINE_COLORS = {
  PURPLE: '#9c54e4',
  GREEN: '#c7ff58',
  ORANGE: '#f57f07',
}
