import SummaryPanel from '.'
import { Panel } from '../../../common/panel'
import { downloadSummary } from './store/thunk'

const PanelSummary = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const handleDownloadCsv = () => {
    return downloadSummary()
  }

  return (
    <Panel
      title={'market.derivative.SUMMARY'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={handleDownloadCsv}
    >
      <SummaryPanel />
    </Panel>
  )
}

export default PanelSummary
