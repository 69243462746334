import TextEllipsis from '../textEllipsis'
import style from './index.module.css'

const SelectBorderBlue = ({
  styleComponent,
  isDisable,
  isI18n,
  dataById,
  value,
  isShowDropdown,
}) => {
  return (
    <span className={`${styleComponent.select} ${style.customSelect}`}>
      <span
        className={`${styleComponent.text} ${style.customSelectText} ${
          isDisable ? styleComponent.disable : ''
        }`}
      >
        <TextEllipsis isI18n={isI18n} text={dataById[value]?.name || ''} />
      </span>
      <i
        className={`${!isShowDropdown ? 'icon-caret' : 'icon-caret-up'} ${
          styleComponent.icon
        } ${style.customSelectIcon}`}
      />
    </span>
  )
}

export default SelectBorderBlue
