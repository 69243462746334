export const getUniqueFisheryTypes = (data) => {
  const uniqueFisheryTypes = Object.values(
    data.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  )

  const parentSteelTypes = uniqueFisheryTypes.filter((item) => item.isGroupRow)
  const childrenSteelTypes = uniqueFisheryTypes.filter(
    (item) => !item.isGroupRow,
  )

  const getChildrenOfSpecificParent = (parentId, getIdsOnly) => {
    return !getIdsOnly
      ? childrenSteelTypes.filter((item) => item.locationId === parentId)
      : childrenSteelTypes
          .filter((item) => item.locationId === parentId)
          .map((item) => item.productId)
  }

  const parentSteelTypesWithChildren = parentSteelTypes.map((item) => ({
    ...item,
    childrenIds: getChildrenOfSpecificParent(item.locationId, true),
  }))

  return parentSteelTypesWithChildren
    .map((item) => [item, ...getChildrenOfSpecificParent(item.locationId)])
    .flat(1)
}

export const getUniqueFisheryTypesByProduct = (data) => {
  const uniqueFisheryTypes = Object.values(
    data.reduce((acc, curr) => {
      acc[curr.id] = curr
      return acc
    }, {}),
  )

  const parentSteelTypes = uniqueFisheryTypes.filter((item) => item.isGroupRow)
  const childrenSteelTypes = uniqueFisheryTypes.filter(
    (item) => !item.isGroupRow,
  )

  const getChildrenOfSpecificParent = (parentId, getIdsOnly) => {
    return !getIdsOnly
      ? childrenSteelTypes.filter((item) => item.productId === parentId)
      : childrenSteelTypes
          .filter((item) => item.productId === parentId)
          .map((item) => item.locationId)
  }

  const parentSteelTypesWithChildren = parentSteelTypes.map((item) => ({
    ...item,
    childrenIds: getChildrenOfSpecificParent(item.productId, true),
  }))

  return parentSteelTypesWithChildren
    .map((item) => [item, ...getChildrenOfSpecificParent(item.productId)])
    .flat(1)
}
