import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { useSearchParams } from 'react-router-dom'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { LIST_TYPE } from '../../constant'
import style from '../../index.module.css'
import { selectSelectedOrganizationGroups } from '../../store/slice'
import { getOrganizationsByIcb } from '../../store/thunk'
import CompanyPopup from './CompanyPopup'

const Company = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const [isShow, setIsShow] = useState(false)

  const sectorInfo = useSelector(selectSectorInfo)
  const selectedGroups = useSelector(selectSelectedOrganizationGroups)

  const firstRender = useRef(true)

  const handleChangeIcbTopInfo = () => {
    if (
      selectedGroups.some(
        (group) =>
          group.type === LIST_TYPE.SECTOR && group.id === sectorInfo.icbId,
      )
    )
      return

    dispatch(
      getOrganizationsByIcb({ ICBId: sectorInfo.icbId, isSetDefault: true }),
    )
  }

  useEffect(() => {
    if (
      firstRender.current &&
      sectorInfo.icbId &&
      searchParams.has('shareId')
    ) {
      firstRender.current = false
      return
    }
    if (!sectorInfo.icbId) return

    handleChangeIcbTopInfo()
  }, [sectorInfo.icbId])

  return (
    <>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.CHOOSE_COMPANY" />
      </Span>
      <div
        className={`form-search-inbox ${style.formSearch} `}
        onClick={() => setIsShow(true)}
      >
        <Input
          style={{ fontSize: 10 }}
          type="text"
          className="search-inbox h-20 cursor-pointer"
          placeholder={I18n.t('sector.sectorConstituents.segmentation.SEARCH')}
          readOnly
        />
        <button type="button">
          <i className="icon-search-2" />
        </button>
      </div>
      {isShow && <CompanyPopup setIsShow={setIsShow} />}
    </>
  )
}

export default Company
