import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../../common/html/Button'
import { Span } from '../../../../../../common/html/Span'
import Popup from '../../../../../../common/popup'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import { changeResponseOrgIds } from '../store/slice'
import style from './index.module.css'
import { changeOrgIds, changeSelectedWL } from './store/slice'

const DeleteAllTicker = () => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)

  const [isShow, setIsShow] = useState(false)

  const handleDeleteAll = () => {
    if (basicInfo.organizationId) {
      dispatch(changeOrgIds([basicInfo.organizationId]))
      dispatch(changeResponseOrgIds([basicInfo.organizationId]))
      dispatch(changeSelectedWL(null))
      setIsShow(false)
    }
  }

  return (
    <div className="d-flex ali-top h-20">
      <button onClick={() => setIsShow(true)} className={style.deleteButton}>
        <i className="icon-trash" />
      </button>
      {isShow && (
        <Popup>
          <div className="modal modal-small pb-8">
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="corporate.peerValuation.overview.DELETE_ALL_TICKER" />
              </h3>
              <a
                onClick={() => setIsShow(false)}
                className="close-modal"
                style={{ color: '#AEBCD2' }}
              >
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <div className="d-flex">
                <Span
                  style={{
                    fontSize: 12,
                  }}
                >
                  <Translate
                    dangerousHTML
                    value="corporate.peerValuation.overview.DELETE_MESSAGE"
                  />
                </Span>
              </div>
              <div className={`${style.groupButtonModal} mt-40`}>
                <Button
                  onClick={handleDeleteAll}
                  className="btn bg-grey-1 w-80 h-20 mr-8"
                >
                  <Translate value={'common.button.BUTTON_DELETE'} />
                </Button>
                <Button
                  className="btn btn-blue w-80 h-20"
                  onClick={() => setIsShow(false)}
                >
                  <Translate value={'common.button.BUTTON_CANCEL'} />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}

export default DeleteAllTicker
