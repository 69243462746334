import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown from '../../../../../common/dropdown'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

export const SearchBox = ({
  isInTable,
  listCompanies,
  activeItem,
  currentItemInChart,
  changeActiveItem,
  changeCurrentItem,
  tableId,
  setRowsCollapse,
  rowsCollapse,
}) => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const defaultCompanyName = listCompanies[0]?.name

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [eventPressKey, setEventPressKey] = useState()

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  useEffect(() => {
    if (!isInTable) {
      setKeySearch(currentItemInChart?.name || '')
    }
  }, [currentItemInChart])

  return (
    <div
      ref={exceptOutsideRef}
      className={`form-search-inbox ${style.inputSearch}`}
    >
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        type="text"
        className="search-inbox bg-grey"
        placeholder={
          isInTable
            ? activeItem?.name || defaultCompanyName
            : currentItemInChart?.name || defaultCompanyName
        }
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        ref={inputRef}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <DropdownItems
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listItems={listCompanies?.filter(
          (item) =>
            String(item.id)?.includes(keySearch) ||
            item.name?.toLowerCase().includes(keySearch.toLowerCase()),
        )}
        setKeySearch={setKeySearch}
        eventPressKey={eventPressKey}
        isInTable={isInTable}
        changeActiveItem={changeActiveItem}
        changeCurrentItem={changeCurrentItem}
        tableId={tableId}
        setRowsCollapse={setRowsCollapse}
        rowsCollapse={rowsCollapse}
      />
    </div>
  )
}

const DropdownItems = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listItems,
  exceptOutsideRef,
  setKeySearch,
  isInTable,
  changeActiveItem,
  changeCurrentItem,
  tableId,
  setRowsCollapse,
  rowsCollapse,
}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthItems = listItems.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listItems[activeIndex])
      else if (listItems[0]) handleClickItem(listItems[0])
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthItems - 1) setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthItems - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (value) => {
    if (isInTable) {
      const scrollTable = document.querySelector(
        `#${tableId} .scrollbars div:first-child`,
      )
      const headerTable = scrollTable?.querySelector('thead')
      const activeRow = scrollTable?.querySelector(`[id="${value.id}"]`)
      if (scrollTable && headerTable && activeRow) {
        scrollTable.scrollTo({
          top: activeRow.offsetTop - headerTable.offsetHeight,
          behavior: 'smooth',
        })
      }
      dispatch(setRowsCollapse(rowsCollapse?.filter((id) => id !== value.id)))
      dispatch(changeActiveItem(value.id))
    }

    if (changeCurrentItem) {
      dispatch(changeCurrentItem(value))
    }

    handleHide()
    setKeySearch(value.name)
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listItems.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listItems.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(item)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>{item.name}</div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()
  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
