import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { findRoundOffset } from '../../../../../../common/chart/helper'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { addTitleAndValue, getTypeName } from '../helper'
import { selectTimeRequest } from '../store/slice'
import HalfContentContribution from './HalfContentContribution'
import XAxisContribution from './XAxisContribution'

const ContentContribution = ({ sizes, pageActive, data }) => {
  const timeRequest = useSelector(selectTimeRequest)

  const typeName = getTypeName(pageActive)
  const topGainers = addTitleAndValue(data.topGainers, typeName)
  const topLosers = addTitleAndValue(data.topLosers, typeName).reverse()

  const maxXAxis =
    topGainers.concat(topLosers).reduce((max, item) => {
      if (Math.abs(item.value) > max) {
        return Math.abs(item.value)
      }
      return max
    }, 0) || 1

  const offsetRound = findRoundOffset([-maxXAxis, maxXAxis])
  const maxRoundXAxis = Math.ceil(maxXAxis / offsetRound) * offsetRound

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <div
              style={{ height: `calc(100% - ${size.height}px)` }}
              className="d-flex position-relative"
            >
              <HalfContentContribution
                sizes={sizes}
                data={topLosers}
                maxValue={maxRoundXAxis}
                timeRequest={timeRequest}
              />
              <HalfContentContribution
                sizes={sizes}
                data={topGainers}
                isPositive
                maxValue={maxRoundXAxis}
                timeRequest={timeRequest}
              />
            </div>
          )}
          <XAxisContribution maxXAxis={maxRoundXAxis} />
        </>
      )}
    </SizeTracker>
  )
}

ContentContribution.propTypes = {
  tabActive: PropTypes.any,
}

export default ContentContribution
