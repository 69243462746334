import HistoryProxy from '../../../proxies/bond/valuationProxy/historyProxy'
import { ServiceBase } from '../../ServiceBase'

export class History extends ServiceBase {
  getDataHistory(params) {
    return this.getData(() => HistoryProxy.getHistoryProxy(params))
  }
  exportFile(params) {
    return this.getFileDownload(() => HistoryProxy.exportFileProxy(params))
  }
}

export default new History()
