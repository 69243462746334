import moment from 'moment'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { FORMAT } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import style from '../index.module.css'

const RemainingMaturitiesChartTooltip = ({ dataChart }) => {
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {moment().format(FORMAT.DATE[locale])}
        &nbsp; - &nbsp;
        <Translate
          value="bond.corporateBond.remainingMaturities.REMAINING_DURATION"
          style={{ textTransform: 'none' }}
        />
        &nbsp;
        {dataChart.duration}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.VALUE_OF_OUTSTANDING_BONDS" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.valueOfOutstandingBond)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.remainingMaturities.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.AVG_COUPON" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {valToPercent(dataChart.averageCouponInterestRate, true)}
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.remainingMaturities.AVG_COUPON_ALL_PERIOD" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {valToPercent(dataChart.averageCouponInterestRateAllDuration, true)}
          </Span>
        </div>
      </div>
    </div>
  )
}

RemainingMaturitiesChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    valueOfOutstandingBond: PropTypes.number.isRequired,
    averageCouponInterestRate: PropTypes.number.isRequired,
    averageCouponInterestRateAllDuration: PropTypes.number.isRequired,
    duration: PropTypes.string.isRequired,
  }).isRequired,
}

export default RemainingMaturitiesChartTooltip
