import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { debounce } from '../../../utils/Common'
import { FORMAT } from '../../../utils/Datetime'
import { DateTimeSpan } from '../../DateTimeSpan'
import UseFontSize from '../../hooks/useFontSize'
import { P } from '../../html/P'
import { Span } from '../../html/Span'
import { ShareButton } from '../../shareButton'
import { SizeTracker } from '../../sizeTracker'
import { TextEllipsisStaticLine } from '../../textEllipsis/TextEllipsisStaticLine'
import style from './index.module.css'
import { Source } from './Source'

export const Item = ({
  item,
  isShowBtnShare,
  distanceBetweenItem,
  isSpaceBetween,
  lineClamp,
  titleLineClamp,
  titleFontSize,
  titleFontWeight,
  isShowDesc,
  onAction,
}) => {
  const titleRef = useRef()
  const [titleHeight, setTitleHeight] = useState(0)
  const [keyItem, setKeyItem] = useState(true)
  const typeFontSize = UseFontSize()
  const locale = useSelector((state) => state.i18n.locale)

  const changeKeyItem = () => {
    setKeyItem(!keyItem)
  }

  useEffect(() => {
    changeKeyItem()
  }, [typeFontSize])

  useEffect(() => {
    const calcTitleHeight = debounce(() => {
      if (titleRef.current) {
        setTitleHeight(titleRef.current.offsetHeight)
      }
    })
    calcTitleHeight()
    window.addEventListener('resize', calcTitleHeight)
    return () => {
      window.removeEventListener('resize', calcTitleHeight)
    }
  }, [titleRef, typeFontSize, locale])

  const renderTitle = () => {
    return (
      <Span style={{ fontSize: titleFontSize, fontWeight: titleFontWeight }}>
        <TextEllipsisStaticLine
          val={item.title || ''}
          isI18n={false}
          lineClamp={titleLineClamp}
        />
      </Span>
    )
  }

  return (
    <div className="w-100 h-100" key={keyItem}>
      <div className="title-news" ref={titleRef}>
        <a onClick={onAction} className="cursor-pointer">
          {renderTitle()}
        </a>
      </div>
      <div style={{ height: `calc(100% - ${titleHeight}px)` }}>
        <SizeTracker>
          {(size) => {
            return (
              <>
                {size.height && isShowDesc && (
                  <div
                    style={{
                      paddingBottom: 5,
                      height: `calc(100% - ${size.height + 5}px)`,
                    }}
                  >
                    <P
                      className={style.desc}
                      style={{ WebkitLineClamp: lineClamp, lineClamp }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: item.shortContent || '',
                        }}
                      />
                    </P>
                  </div>
                )}
                <div
                  className="d-flex box-des ali-center"
                  style={{
                    justifyContent: isSpaceBetween ? 'space-between' : 'normal',
                  }}
                >
                  <SizeTracker className="d-flex">
                    {(size) => {
                      return (
                        <>
                          <Span
                            className="time"
                            style={{
                              marginRight: distanceBetweenItem,
                              fontSize: 11,
                              whiteSpace: 'nowrap',
                            }}
                          >
                            <DateTimeSpan
                              date={item.publicDate}
                              formatDate={FORMAT.NEWS}
                            />
                          </Span>
                          {size.width && (
                            <Span
                              style={{
                                marginRight: distanceBetweenItem,
                                fontSize: 11,
                                textAlign: isSpaceBetween ? 'right' : 'unset',
                                width: isShowBtnShare
                                  ? 'unset'
                                  : `calc(100% - ${
                                      size.width + distanceBetweenItem
                                    }px)`,
                              }}
                            >
                              <Source
                                isShowBtnShare={isShowBtnShare}
                                item={item}
                              />
                            </Span>
                          )}
                          {isShowBtnShare && (
                            <ShareButton url={item.sourceUrl} />
                          )}
                        </>
                      )
                    }}
                  </SizeTracker>
                </div>
              </>
            )
          }}
        </SizeTracker>
      </div>
    </div>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  isShowBtnShare: PropTypes.bool,
  distanceBetweenItem: PropTypes.number,
  isSpaceBetween: PropTypes.bool,
  lineClamp: PropTypes.number,
  titleFontSize: PropTypes.number,
  titleFontWeight: PropTypes.number,
  isShowDesc: PropTypes.bool,
  titleLineClamp: PropTypes.number,
  onAction: PropTypes.func,
}

Item.defaultProps = {
  isShowBtnShare: false,
  distanceBetweenItem: 10,
  isSpaceBetween: false,
  lineClamp: 2,
  titleFontSize: 16,
  titleFontWeight: 600,
  isShowDesc: true,
  titleLineClamp: 1,
  onAction: () => {},
}
