const START_TIME = [8, 30, 0, 0]
const END_TIME = [8, 45, 0, 0]

export const getSecondsToOpenMarket = () => {
  const startCountTime = new Date()
  if (startCountTime.getDay() === 6 || startCountTime.getDay() === 0) {
    return 0
  }
  startCountTime.setHours(...START_TIME)
  const openTime = new Date()
  openTime.setHours(...END_TIME)
  const now = new Date()

  if (now < startCountTime || now > openTime) {
    return 0
  }

  return Math.ceil((openTime.getTime() - now.getTime()) / 1000)
}

export const setTimeToMarketOpen = (countDownSecondsToOpenMarket) => {
  const now = new Date()
  const destTime = new Date()
  destTime.setHours(...START_TIME)
  destTime.setSeconds(destTime.getSeconds() + 1)
  const timeout = destTime.getTime() - now.getTime()
  if (timeout < 0) {
    return
  }
  let interval
  const timeoutId = setTimeout(() => {
    const seconds = getSecondsToOpenMarket()
    if (seconds === 0) {
      return
    }
    interval = countDownSecondsToOpenMarket(seconds)
  }, timeout)
  return { timeout: timeoutId, interval }
}
