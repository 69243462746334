import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { DEFAULT_ORGANIZATION_ID } from '../../../../../configs/Common'
import { ChannelConfig } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../common/html/Span'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../common/popup/PopupAlert'
import { Table as TableContent } from '../../../../common/table'
import { TextBlink } from '../../../../common/table/TextBlink'
import TextEllipsis from '../../../../common/textEllipsis'
import { COM_GROUP } from '../../../../constants/Group'
import { selectReCalcWidths } from '../../../../market/equityTrading/priceStatistics/summary/overview/store/slice'
import { getBondPriceColor } from '../../../../utils/Color'
import { convertSortKeyToNumber } from '../../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valDivMillion, valToPercent } from '../../../../utils/Value'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../bondSecurity/bondSecurity/Filter/store/slice'
import TableHeader from './TableHeader'
import { GROUP_COLUMNS, GROUP_COLUMNS_DIRTY } from './constants'
import style from './index.module.css'
import {
  changeFilterSort,
  changePage,
  selectCurrentPage,
  selectDataTableById,
  selectDataTradingStatistics,
  selectFilterPriceBoard,
  selectLoading,
  selectTotalPage,
  sort,
  subscribeBondBidAskDealId,
  subscribeBondBidAskId,
  subscribeBondId,
} from './store/slice'

const Table = () => {
  const dispatch = useDispatch()

  const navigate = useCustomNavigate()

  const isLoading = useSelector(selectLoading)
  const currentPage = useSelector(selectCurrentPage)
  const totalPage = useSelector(selectTotalPage)
  const { data, ids } = useSelector(selectDataTradingStatistics)
  const locale = useSelector((state) => state.i18n.locale)
  const { isCleanPrice } = useSelector(selectFilterPriceBoard)
  const reCalcWidths = useSelector(selectReCalcWidths)

  const [isShow, setIsShow] = useState(false)

  const columns = useMemo(
    () => (isCleanPrice ? GROUP_COLUMNS : GROUP_COLUMNS_DIRTY),
    [isCleanPrice],
  )

  const handleChangePage = (page) => {
    dispatch(changePage(page))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeFilterSort({
          SortBy: Object.keys(data)[0],
          SortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(changeFilterSort({ SortBy: 'tradingDateId', SortOrder: 1 }))
    }
  }

  // Get value
  const getRowHighlights = () => {
    const itemIndex = {}
    data.forEach((item, index) => (itemIndex[item.date] = index))
    return Object.keys(itemIndex).map((key) => itemIndex[key])
  }

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <>
      <TableContent
        isLoading={isLoading}
        ids={ids}
        getDataFromRedux={selectDataTableById}
        renderHeader={(stateSort, sortColumn) => (
          <TableHeader
            tableHeaderColAttr={columns}
            stateSort={stateSort}
            sortColumn={sortColumn}
          />
        )}
        schema={columns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          switch (index) {
            case 0:
              return {
                ...result,
                tdStyle: {
                  minWidth: 100,
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props} className={style.firstCellColumn}>
                    <Span>{formatDateTime(value, FORMAT.DATE, locale)}</Span>
                    <div className={style.borderLeftScroll} />
                  </td>
                ),
              }

            case 1:
              return {
                ...result,
                tdStyle: {
                  minWidth: 100,
                  left: 117,
                  // zIndex: 100,
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>
                      <a
                        className="cursor-pointer"
                        onClick={() => redirectToSecurityMaster(rowId)}
                      >
                        <TextEllipsis text={value} isI18n={false} />
                      </a>
                    </Span>
                  </td>
                ),
              }

            case 2:
              return {
                ...result,
                tdStyle: {
                  maxWidth: 200,
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <Span>
                        <a
                          className="cursor-pointer"
                          onClick={() =>
                            redirectToBondIssuer(
                              row?.organizationId ??
                                DEFAULT_ORGANIZATION_ID[COM_GROUP.CORPORATE],
                            )
                          }
                        >
                          <TextEllipsis text={value} isI18n={false} />
                        </a>
                      </Span>
                    </td>
                  )
                },
              }

            case 3:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{value}</Span>
                  </td>
                ),
              }

            case 4:
              return {
                ...result,
                tdStyle: {
                  minWidth: 50,
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>
                      {`${formatVal(value * 100)} ${value !== `--` ? '%' : ''}`}
                    </Span>
                  </td>
                ),
              }

            case 5:
              return {
                ...result,
                tdStyle: {
                  minWidth: 90,
                  width: 90,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <div>
                          <Span
                            style={{
                              color: getBondPriceColor(
                                value,
                                isCleanPrice
                                  ? row?.cleanReferencePrice
                                  : row?.dirtyReferencePrice,
                              ),
                              cursor: 'pointer',
                            }}
                          >
                            {formatVal(value, 0)}
                          </Span>
                        </div>
                        <div>{valToPercent(row?.ytmOpenPrice, false)}</div>
                      </TextBlink>
                    </td>
                  )
                },
              }
            case 6:
              return {
                ...result,
                tdStyle: {
                  minWidth: 90,
                  width: 90,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <div>
                          <Span
                            style={{
                              color: getBondPriceColor(
                                value,
                                isCleanPrice
                                  ? row?.cleanReferencePrice
                                  : row?.dirtyReferencePrice,
                              ),
                              cursor: 'pointer',
                            }}
                          >
                            {formatVal(value, 0)}
                          </Span>
                        </div>
                        <div>{valToPercent(row?.ytmHighPrice, false)}</div>
                      </TextBlink>
                    </td>
                  )
                },
              }
            case 7:
              return {
                ...result,
                tdStyle: {
                  minWidth: 90,
                  width: 90,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <div>
                          <Span
                            style={{
                              color: getBondPriceColor(
                                value,
                                isCleanPrice
                                  ? row?.cleanReferencePrice
                                  : row?.dirtyReferencePrice,
                              ),
                              cursor: 'pointer',
                            }}
                          >
                            {formatVal(value, 0)}
                          </Span>
                        </div>
                        <div>{valToPercent(row?.ytmLowPrice, false)}</div>
                      </TextBlink>
                    </td>
                  )
                },
              }
            case 8:
              return {
                ...result,
                tdStyle: {
                  minWidth: 90,
                  width: 90,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => {
                  const row = data.find((item) => item.bondId === rowId)

                  return (
                    <td {...props}>
                      <TextBlink value={value}>
                        <div>
                          <Span
                            style={{
                              color: getBondPriceColor(
                                value,
                                isCleanPrice
                                  ? row?.cleanReferencePrice
                                  : row?.dirtyReferencePrice,
                              ),
                              cursor: 'pointer',
                            }}
                          >
                            {formatVal(value, 0)}
                          </Span>
                        </div>
                        <div>{valToPercent(row?.ytmClosePrice, false)}</div>
                      </TextBlink>
                    </td>
                  )
                },
              }

            case 9:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                  width: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(value, 0)}</Span>
                  </td>
                ),
              }

            case 10:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                  width: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(valDivMillion(value))}</Span>
                  </td>
                ),
              }

            case 11:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                  width: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>
                      {rowId !== 'Total' ? formatVal(value, 0) : value}
                    </Span>
                  </td>
                ),
              }

            case 12:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                  width: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span
                      style={{
                        color:
                          value === 'Bình thường' || value === 'Active'
                            ? '#3DA967'
                            : value === 'Huỷ do mua lại trái phiếu trước hạn' ||
                              value === 'Cancellation due to redemption'
                            ? '#FECF2F'
                            : '#FFFFFF',
                      }}
                    >
                      {formatVal(valDivMillion(value))}
                    </Span>
                  </td>
                ),
              }

            case 13:
              return {
                ...result,
                tdStyle: {
                  width: 60,
                  minWidth: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(value, 0)}</Span>
                  </td>
                ),
              }

            case 14:
              return {
                ...result,
                tdStyle: {
                  minWidth: 60,
                  width: 60,
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (value, rowId, props) => (
                  <td {...props}>
                    <Span>{formatVal(valDivMillion(value))}</Span>
                  </td>
                ),
              }

            default:
              return {}
          }
        })}
        stickies={{
          bondCode: true,
          tradingDateId: true,
        }}
        sort={sort}
        defaultSort={{ tradingDateId: 'desc' }}
        hasPagination
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
        currentPage={currentPage}
        totalPage={totalPage}
        handleChangePage={handleChangePage}
        handleSortField={onChangeSortField}
        isHighlightLine={true}
        rowHighlights={getRowHighlights()}
        reCalcWidths={reCalcWidths}
      />
      <PopupAlert
        message={I18n.t('common.FUNCTION_COMING_SOON')}
        isShow={isShow}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShow(false)}
      />
      {ids.map((id) => (
        <Realtime
          channel={ChannelConfig.BidAskBondChannel + id}
          action={subscribeBondBidAskId}
          key={id}
        >
          <></>
        </Realtime>
      ))}
      {ids.map((id) => (
        <Realtime
          channel={ChannelConfig.BondChannel + id}
          action={subscribeBondId}
          key={id}
        >
          <></>
        </Realtime>
      ))}
      {ids.map((id) => (
        <Realtime
          channel={ChannelConfig.BidAskBondDealChannel + id}
          action={subscribeBondBidAskDealId}
          key={id}
        >
          <></>
        </Realtime>
      ))}
    </>
  )
}

export default Table
