import { Translate } from 'react-redux-i18n'
import { Span } from '../../../html/Span'
import style from './index.module.css'

const Title = ({ title }) => {
  return (
    <Span style={{ fontSize: 17 }} className={style.title}>
      <Translate value={title} />
    </Span>
  )
}

export default Title
