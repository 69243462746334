import { createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { invertKeyBy, keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { valueTab } from '../constants'
import { fetchMoreDataThunk, getDataThunk } from './thunk'

const name = 'bond/bondSecurity/order'

const initialState = {
  tabActive: valueTab.History,
  tradingType: null,
  loading: false,
  data: [],
  ids: [],
  ScrollId: null,
  pageSize: 20,
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateTab: (state, action) => {
      state.tabActive = action.payload
    },
    updateTradingType: (state, action) => {
      state.tradingType = action.payload
    },
    updateScrollId: (state, action) => {
      state.ScrollId = action.payload
    },
    subscribeBondId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')
      const data = state.data
      const dataStateJSON = JSON.parse(JSON.stringify(data))

      const objectKeys = Object.keys(dataStateJSON)
      objectKeys.forEach((key) => {
        dataStateJSON[key] = {
          ...dataStateJSON,
          volume:
            dataStateJSON[key]?.value > 0
              ? realtimeData?.[22] - dataStateJSON[key]?.totalVolume
              : dataStateJSON[key]?.volume,
          value:
            dataStateJSON[key]?.value > 0
              ? realtimeData?.[21] - dataStateJSON[key]?.totalValue
              : dataStateJSON[key]?.value,
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      const dataJson = JSON.parse(JSON.stringify(action.payload.data))
      const data =
        dataJson.map((item, index) => ({
          ...item,
          id: index,
        })) || []

      state.data = keyBy(data, 'id')
      state.ids = data.map((e) => e?.id)
      state.loading = false
      state.ScrollId = action.payload.ScrollId
    })
    builder.addCase(getDataThunk.rejected, (state, action) => {
      state.data = {}
      state.ids = []
      state.loading = action.payload
    })

    builder.addCase(fetchMoreDataThunk.pending, (state) => {
      // state.loading = true
    })
    builder.addCase(fetchMoreDataThunk.fulfilled, (state, action) => {
      if (!isEmpty(action.payload.data)) {
        const dataJson = JSON.parse(JSON.stringify(action.payload.data))
        const dataCurrent = invertKeyBy(state.data)
        const data =
          dataJson.map((item, index) => ({
            ...item,
            id: dataCurrent.length + index,
          })) || []
        const dataUpdate = dataCurrent.concat(data)

        state.data = keyBy(dataUpdate, 'id')
        state.ids = dataUpdate.map((e) => e?.id)
        // state.loading = false
        state.ScrollId = action.payload.ScrollId
      }
    })
    builder.addCase(fetchMoreDataThunk.rejected, (state) => {
      // state.loading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectTabActive = (state) => state[slice.name].tabActive
export const selecTradingType = (state) => state[slice.name].tradingType
export const selectScrollId = (state) => state[slice.name].ScrollId
export const selectPageSize = (state) => state[slice.name].pageSize

export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name]?.data?.[id], attr)
}

export const {
  resetStore,
  updateTab,
  updateTradingType,
  subscribeBondId,
  updateScrollId,
} = slice.actions

register(slice.name, slice.reducer)
