import skypeIcon from './icon-skype.png'
import style from '../index.module.css'
import { IconWrapper } from '../IconWrapper'

export const SkypeIcon = ({ url }) => {
  const onClick = () => {
    window.open(
      `https://web.skype.com/share?url=${encodeURIComponent(url)}`,
      'Share on Skype', // Chia sẻ trên Skype
      'height=700,width=500',
    )
  }
  return (
    <IconWrapper>
      <div className={style.img} onClick={onClick}>
        <img src={skypeIcon} alt="Skype" width={72} height={72} />
        <span>Skype</span>
      </div>
    </IconWrapper>
  )
}
