import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../html/Button'
import { P } from '../../../html/P'
import { ScrollComponent } from '../../../ScrollComponent'
import { TextEllipsisStaticLine } from '../../../textEllipsis/TextEllipsisStaticLine'
import { withWidget } from '../../context'
import { resetState, selectCurrentChartLayout } from '../../store/slice'
import { deleteChartLayout } from '../../store/thunk'
import { Image } from '../image/Image'
import style from './index.module.css'

const RightContent = ({ widget, changePopUpStatus, hasDeleteTemplate }) => {
  const dispatch = useDispatch()
  const currentChartLayout = useSelector(selectCurrentChartLayout)
  const onLoadTemplate = () => {
    if (!currentChartLayout.layout) {
      return
    }
    const state = JSON.parse(currentChartLayout.layout)
    const symbol = widget.chart().symbol()
    widget.load(state)
    setTimeout(() => {
      widget.chart().setSymbol(symbol)
    })
    changePopUpStatus(false)
  }

  const onDeleteTemplate = () => {
    dispatch(deleteChartLayout({ layoutId: currentChartLayout.chartLayoutId }))
    dispatch(resetState())
    changePopUpStatus(false)
  }

  return (
    <div className={style.container}>
      <Image width={323} height={232} imgSrc={currentChartLayout.thumbnails} />
      <div className={style.title}>
        <TextEllipsisStaticLine
          val={currentChartLayout.name}
          lineClamp={1}
          isI18n={false}
          appendStyle={{ fontSize: 24 }}
        />
      </div>
      <ScrollComponent autoHeight autoHeightMax={80}>
        <P style={{ width: 323, wordBreak: 'break-word' }}>
          {currentChartLayout.description}
        </P>
      </ScrollComponent>
      <div className="d-flex">
        <Button
          onClick={onLoadTemplate}
          className={`btn btn-blue h-20 ${style.createBtn}`}
          style={{ fontSize: 11 }}
        >
          <Translate value="common.tradingView.LOAD_TEMPLATE" />
        </Button>
        {hasDeleteTemplate && (
          <Button
            onClick={onDeleteTemplate}
            className={`btn normal h-20 ${style.deleteBtn}`}
            style={{ fontSize: 11 }}
          >
            <Translate value="common.tradingView.DELETE_TEMPLATE" />
          </Button>
        )}
      </div>
    </div>
  )
}

RightContent.propTypes = {
  widget: PropTypes.object.isRequired,
  changePopUpStatus: PropTypes.func.isRequired,
  hasDeleteTemplate: PropTypes.bool,
}

RightContent.defaultProps = {
  hasDeleteTemplate: false,
}

export default withWidget(RightContent)
