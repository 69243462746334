import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import Tree from '../../../../../common/tree'
import {
  LOADING_TYPE,
  selectIndicatorTrees,
  selectLoading,
} from '../../store/slice'
import style from './index.module.css'

const SCROLL_DIV_ID = 'SCROLL_CATEGORY_TREE'

const Category = ({ category, setCategory, keyword }) => {
  const loading = useSelector(selectLoading(LOADING_TYPE.INDICATORS))

  const indicatorTrees = useSelector(selectIndicatorTrees)

  const handleClick = (item) => {
    if (Object.keys(item.children).length === 0) {
      setCategory(item.indicatorId)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const scrollTree = document.querySelector(
        `#${SCROLL_DIV_ID} .scrollbars div:first-child`,
      )
      const activeIndicator = scrollTree?.querySelector(`[id="${category}"]`)

      if (
        scrollTree &&
        activeIndicator &&
        activeIndicator.offsetTop + activeIndicator.offsetHeight >
          scrollTree.offsetHeight
      ) {
        scrollTree.scrollTo({
          top: activeIndicator.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }, 200)
  }, [])

  return (
    <div className={style.category}>
      <div className={style.header}>
        <Span style={{ opacity: 0.4, display: 'block', lineHeight: '20px' }}>
          <Translate value="sector.sectorConstituents.segmentation.CATEGORY" />
        </Span>
      </div>
      <div className={style.categoryList}>
        {loading ? (
          <Loading />
        ) : Object.keys(indicatorTrees).length ? (
          <>
            {!!keyword.trim() && (
              <div style={{ padding: '5px 18px', color: '#2f9aee' }}>
                <Translate value="common.ALL" />
              </div>
            )}
            <Tree
              treeData={indicatorTrees}
              idKey={'indicatorId'}
              nameKey="indicatorName"
              listHightlight={keyword ? [] : [category]}
              itemDefaultOpen={keyword ? [] : [category]}
              disabledCheckDbClick
              onClickItem={handleClick}
              doubleClickItem={undefined}
              scrollDivId={SCROLL_DIV_ID}
            />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

export default Category
