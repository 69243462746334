import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import GroupByDate from './GroupByDate'
import { getListDate, getTemplateLoading } from './store/slice'
import { getTemplate } from './store/thunk'
import { idDivFooterScroll } from './TemplateItem'

const Template = () => {
  const dispatch = useDispatch()

  const templateLoading = useSelector(getTemplateLoading)
  const listDate = useSelector(getListDate)

  useEffect(() => {
    dispatch(getTemplate())
  }, [])

  if (templateLoading) {
    return <Loading />
  }

  return (
    <>
      {listDate.map((date) => (
        <GroupByDate key={date} date={date} />
      ))}
      <div id={idDivFooterScroll} />
    </>
  )
}

export default Template
