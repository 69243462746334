import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TYPE_IMPORT_EXPORT } from '../../constants'
import { getMaxDateDetail } from '../../exportStructure/store/thunk'
import { DATA_TYPE_LIST, TIME_FILTER_TYPE } from '../constants'
import {
  handleImportByProduct,
  handleImportByProductAfterChangeFilter,
  handleImportByProductAfterScroll,
  handleImportByProductChildren,
  handleImportByProductChildrenAfterScroll,
} from '../helper'
import {
  getExportByProduct,
  getExportByProductAfterChangeFilter,
  getExportByProductAfterScroll,
  getExportByProductChildren,
  getExportByProductChildrenAfterScroll,
  getImportByProduct,
  getImportByProductAfterChangeFilter,
  getImportByProductAfterScroll,
  getImportByProductChildren,
  getImportByProductChildrenAfterScroll,
} from './thunk'

const slice = createSlice({
  name: 'economy/importExport/exportImportByCommodities/exportImportTable',
  initialState: {
    idsCategory: [],
    dataById: {},
    isLoading: true,
    listCategory: [],
    isGetData: true,
    data: [],
    groupColumns: [],
    listOrigParentCategory: [],
    activeItem: null,
    typeData: DATA_TYPE_LIST.VALUE,
    currentTypeFilter: TIME_FILTER_TYPE.MONTHLY,
    locale: null,
    isChangeLocale: false,
    currentMinMonth: null,
    currentMinYear: null,
    levels: [],
    initialIds: [],
    rowsCollapse: [],
    importExportDataType: TYPE_IMPORT_EXPORT.IMPORT,
    isChangeImExType: false,
    newMaxMonth: null,
    levelCollapse: {},
    isFirstTime: true,
    activeId: null,
    maxDate: null,
    unCollapseIds: [],
    payloadData: [],
    payloadNameData: [],
    limitQuarter: 0,
    keySearch: '',
    isKeepRowsCollapse: false,
    sortType: null,
    listCategoryOrder: [],
    unCollapseIdsOrder: [],
    rowsCollapseIdsOrder: [],
    activeItemOrder: null,
    isChangeFilter: false,
  },
  reducers: {
    addCategoryToList: (state, action) => {
      state.isGetData = true
      state.listCategory = [...state.listCategory, action.payload]
    },
    removeCategoryFromList: (state, action) => {
      state.isGetData = false
      state.listCategory = state.listCategory.filter(
        (category) => category.id !== action.payload,
      )
    },
    changeCommodity: (state, action) => {
      const rowId = action.payload
      state.activeItem = state.dataById[rowId]
      state.activeId = state.dataById[rowId]?.vnTypeId ?? null
      state.activeItemOrder = state.idsCategory?.findIndex(
        (item) => item === state.activeId,
      )
    },
    changeTypeData: (state, action) => {
      state.typeData = action.payload
      state.isChangeFilter = true
    },
    changeTypeFilter: (state, action) => {
      state.currentTypeFilter = action.payload
      state.isChangeFilter = true
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
      state.isChangeLocale = true
    },
    changeCurrentMinMonth: (state, action) => {
      state.currentMinMonth = action.payload
    },
    changeCurrentMinYear: (state, action) => {
      state.currentMinYear = action.payload
    },
    sort: (state, action) => {
      state.idsCategory = getIdsFromProps(
        state.idsCategory,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
      state.sortType = Object.values(action.payload)[0]
    },
    setRowsCollapse: (state, action) => {
      state.rowsCollapse = action.payload
      state.rowsCollapseIdsOrder = state.unCollapseIds.map((item) =>
        state.idsCategory.indexOf(item),
      )
    },
    changeImportExportDataType: (state, action) => {
      state.importExportDataType = action.payload
      state.isChangeImExType = true
      state.isChangeFilter = true
    },
    setNewMaxMonth: (state, action) => {
      state.newMaxMonth = action.payload
    },
    setLoadingTable: (state, action) => {
      state.isLoading = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    setUnCollapseIds: (state, action) => {
      state.unCollapseIds = action.payload
      state.unCollapseIdsOrder = state.unCollapseIds.map((item) =>
        state.idsCategory.indexOf(item),
      )
    },
    setLimitQuarter: (state, action) => {
      state.limitQuarter = action.payload
    },
    changeKeySearch: (state, action) => {
      state.keySearch = action.payload
    },
    setIsKeepRowsCollapse: (state, action) => {
      state.isKeepRowsCollapse = action.payload
    },
  },
  extraReducers: (builder) => {
    //Import By Product
    builder.addCase(getImportByProduct.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getImportByProduct.fulfilled, (state, action) => {
      handleImportByProduct(state, action)
    })
    builder.addCase(getImportByProduct.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportByProductChildren.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getImportByProductChildren.fulfilled, (state, action) => {
      handleImportByProductChildren(state, action)
    })
    builder.addCase(getImportByProductChildren.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportByProductAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getImportByProductAfterScroll.fulfilled,
      (state, action) => {
        handleImportByProductAfterScroll(state, action)
      },
    )
    builder.addCase(getImportByProductAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getImportByProductChildrenAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getImportByProductChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportByProductChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(getImportByProductChildrenAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getImportByProductAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getImportByProductAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportByProductAfterChangeFilter(state, action)
      },
    )
    builder.addCase(getImportByProductAfterChangeFilter.rejected, (state) => {
      state.isLoading = false
    })

    //Export By Product
    builder.addCase(getExportByProduct.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getExportByProduct.fulfilled, (state, action) => {
      handleImportByProduct(state, action)
    })
    builder.addCase(getExportByProduct.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getExportByProductChildren.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getExportByProductChildren.fulfilled, (state, action) => {
      handleImportByProductChildren(state, action)
    })
    builder.addCase(getExportByProductChildren.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getExportByProductAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getExportByProductAfterScroll.fulfilled,
      (state, action) => {
        handleImportByProductAfterScroll(state, action)
      },
    )
    builder.addCase(getExportByProductAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getExportByProductChildrenAfterScroll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getExportByProductChildrenAfterScroll.fulfilled,
      (state, action) => {
        handleImportByProductChildrenAfterScroll(state, action)
      },
    )
    builder.addCase(getExportByProductChildrenAfterScroll.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(
      getExportByProductAfterChangeFilter.pending,
      (state, action) => {
        state.isLoading = !action.meta.arg.isAfterSort
      },
    )
    builder.addCase(
      getExportByProductAfterChangeFilter.fulfilled,
      (state, action) => {
        handleImportByProductAfterChangeFilter(state, action)
      },
    )
    builder.addCase(getExportByProductAfterChangeFilter.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoading
export const selectCategoryIds = (state) => state[slice.name].idsCategory
export const selectCategoryDataTableById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCategory = (state) => state[slice.name].listCategory
export const selectDataTable = (state) => state[slice.name].data
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectOrigParentCategory = (state) =>
  state[slice.name].listOrigParentCategory
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectTypeData = (state) => state[slice.name].typeData
export const selectTypeFilter = (state) => state[slice.name].currentTypeFilter
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectRowsCollapse = (state) => state[slice.name].rowsCollapse
export const selectImportExportDataType = (state) =>
  state[slice.name].importExportDataType
export const selectIsChangeImExType = (state) =>
  state[slice.name].isChangeImExType
export const selectNewMaxMonth = (state) => state[slice.name].newMaxMonth
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectIsFirstTime = (state) => state[slice.name].isFirstTime
export const selectActiveId = (state) => state[slice.name].activeId
export const selectLimitQuarter = (state) => state[slice.name].limitQuarter
export const selectUnCollapseIds = (state) => state[slice.name].unCollapseIds
export const selectKeySearch = (state) => state[slice.name].keySearch
export const selectIsKeepRowsCollapse = (state) =>
  state[slice.name].isKeepRowsCollapse
export const selectSortType = (state) => state[slice.name].sortType
export const selectUnCollapseIdsOrder = (state) =>
  state[slice.name].unCollapseIdsOrder
export const selectActiveItemOrder = (state) =>
  state[slice.name].activeItemOrder
export const selectIsChangeFilter = (state) => state[slice.name].isChangeFilter

export const {
  addCategoryToList,
  removeCategoryFromList,
  changeCommodity,
  changeTypeData,
  changeTypeFilter,
  changeLocale,
  changeCurrentMinMonth,
  changeCurrentMinYear,
  sort,
  setRowsCollapse,
  changeImportExportDataType,
  setNewMaxMonth,
  setLoadingTable,
  handleIsFirstTime,
  setUnCollapseIds,
  setLimitQuarter,
  changeKeySearch,
  setIsKeepRowsCollapse,
} = slice.actions

register(slice.name, slice.reducer)
