export const TABLE_KEYS = {
  FUND_NAME: 'name',
  TOTAL_NAV: 'totalNAV',
  NAV_CCQ: 'naVperShareAdjusted',
  FUND_TYPE_NAME: 'fundTypeName',
  FUND_STRUCTURE_NAME: 'fundStructureName',
  REGISTER_DATE: 'registerDateId',
  FUND_MANAGEMENT: 'managementOrganizationShortName',
  SUPERVISOR_BANK: 'monitoringOrganizationShortName',
  SCHEDULE_NAME: 'scheduleName',
  FOREIGN_OWNERSHIP: 'foreignPercentage',
  MIN_INVEST: 'minInvest',
  ADDRESS: 'address',
  WEBSITE: 'website',
}

export const TABLE_SCHEMAS = Object.keys(TABLE_KEYS).map((key) => ({
  id: TABLE_KEYS[key],
  title: 'fund.fundProfile.profile.basicInfo.' + key,
}))
