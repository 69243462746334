import bond from './bond'
import { common } from './common'
import constants from './constants'
import corporate from './corporate'
import economy from './economy'
import { error } from './error'
import financialChart from './financialChart'
import fund from './fund'
import layout from './layout'
import logout from './logout'
import market from './market'
import newsReport from './newsReport'
import search from './search'
import sector from './sector'
import setting from './setting'
import tool from './tool'
import watchlist from './watchlist'
import watchlistBond from './watchlistBond'

const lang = {
  common,
  constants,
  corporate,
  layout,
  search,
  logout,
  market,
  setting,
  watchlist,
  tool,
  sector,
  error,
  economy,
  financialChart,
  newsReport,
  bond,
  watchlistBond,
  fund,
}

export default lang
