export const derivative = {
  HEAD_TABLE_TEXT: 'Volume: x1,000. Value: 1,000,000',
  TICKER: 'Ticker',
  EXPIRY_DATE: 'Expiry Date',
  REFERENCE: 'Reference',
  PRICE: 'Price',
  CHANGE: 'Change',
  PERCENT_CHANGE: '% 1D Change',
  VOLUME: 'Volume',
  BASIC: 'Basis',
  OI: 'OI',
  SUMMARY: 'Summary',
  TECHNICAL_CHART: 'Technical chart',
  TIME_AND_SALES: 'Time & Sales',
  LAST_PRICE: 'Price',
  BU_VOLUME: 'BU Volume',
  SD_VOLUME: 'SD Volume',
  UNIDENTIFIED: 'Unidentified',
  PRICE_DEPTH: 'Price Depth',
  BID: 'BID',
  ASK: 'ASK',
  BUY_VOL: 'BUY VOL.',
  SELL_VOL: 'SELL VOL.',
  TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
  TOTAL_MATCHED_VOL: 'TOTAL MATCHED VOL.',
  TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
  TIME: 'Time',
  TYPE: 'Type',
  MATCH_VOL: 'Matched Volume',
  TOTAL_MATCH_VOL: 'Total Matched Volume',
  ONE_DAY: '1D',
  FIVE_DAY: '5D',
  NINE_DAY: '10D',
  TWENTY_DAY: '20D',
  DERIVATIVE: 'Derivative',
  OVERVIEW: 'Overview',
  HISTORICAL_PRICE: 'Historical Price',
  FOREIGN: 'Foreign Trading',
  ORDER_STATISTICS: 'Order Statistics',
  PROPRIETARY: 'Proprietary',
  PRICE_DATA: 'Price data',
  DERIVATIVE_STATISTICS: 'Derivative statistics',
  DATE: 'Date',
  UNMATCHED_BUY: 'Unmatched Buy Volume',
  UNMATCHED_SELL: 'Unmatched Sell Volume',
  BUY_ORDERS: 'Buy Orders',
  BUY_VOLUME: 'Buy Volume',
  AVG_BUY: 'Avg Buy Volume Per Order (share)',
  SELL_ORDERS: 'Sell Orders',
  SELL_VOLUME: 'Sell Volume',
  AVG_SELL: 'Avg Sell Volume Per Order (share)',
  NET_BUY_SELL: 'Net Volume',
  BUY_VALUE: 'Buy Value',
  SELL_VALUE: 'Sell Value',
  NET_VOLUME: 'Net Volume',
  NET_VALUE: 'Net Value',
  ORDER_MATCHING_BUY_VOL: 'Order-matching Buy Volume',
  ORDER_MATCHING_BUY_VAL: 'Order-matching Buy Value',
  ORDER_MATCHING_SELL_VOL: 'Order-matching Sell Volume',
  ORDER_MATCHING_SELL_VAL: 'Order-matching Sell Value',
  PUT_THROUGH_BUY_VOL: 'Put-through Buy Volume',
  PUT_THROUGH_BUY_VAL: 'Put-through Buy Value',
  PUT_THROUGH_SELL_VOL: 'Put-through Sell Volume',
  PUT_THROUGH_SELL_VAL: 'Put-through Sell Value',
  CW_STATISTICS: 'COVER WARRANT STATISTICS',
  NET_VALUE_INVESTOR: 'Net value by Investor',
  NET_TRADING_BREAKDOWN: 'Net Value Breakdown',
  ALL: 'All',
  DETAIL: 'Detail',
  NOTE_TABLE: 'Val.: 1,000,000',
  FROM: 'From',
  TO: 'To',
  CLEAR: 'Clear',
  TICKER_TABLE: 'Ticker',
  ALL_MARKET: 'All',
  TRADING_DATE: 'Trading date',
  MILLION_USD: 'Million USD',
  VALUE_MILLION: 'Value (Million VND)',
  PRICE_THOUSAND: 'Price (Thousand VND)',
  MILLION_VND: 'Million VND',
  THOUSAND_VND: 'Thousand VND',
  VAL_BILLION: 'Val. (Billion VND)',
  BILLION: 'Billion VND',
  BUY: 'Buy',
  SELL: 'Sell',
  NET: 'Net',
  LOCAL_INDIVIDUAL: 'Local Individual',
  LOCAL_INSTITUTION: 'Local Institution',
  ORDER_MATCHING: 'Order-matching',
  VALUE: 'Value',
  PUT_THROUGH: 'Put-through',
  TOTAL_VOLUME: 'Total volume',
  TOTAL_VALUE: 'Total value',
  TICKER_TITLE: 'Ticker',
  UNDERLYING_ASSET: 'Underlying asset price',
  BREAK_EVEN: 'Breakeven price',
  AVG_FIVE_DAYS: '5 days average price',
  PERCENT_PREMIUM: '%Premium',
  OPEN: 'Open',
  HIGHEST: 'Highest',
  LOWEST: 'Lowest',
  CLOSE: 'Close',
  SEARCH: 'Search',
  TOTAL_BUY_VAL: 'Total Buy Volume',
  TOTAL_SELL_VAL: 'Total Sell Volume',
  NET_TOTAL_VOL: 'Net Total Volume',
  NET_TOTAL_VAL: 'Net Total Value',
  MATCH_BUY_VOL: 'Matched Buy Volume',
  MATCH_SELL_VOL: 'Matched Sell Volume',
  MATCH_BUY_VAL: 'Matched Buy Value',
  MATCH_SELL_VAL: 'Matched Sell Value',
  DEAL_BUY_VOL: 'Deal Buy Volume',
  DEAL_SELL_VOL: 'Deal Sell Volume',
  DEAL_BUY_VAL: 'Deal Buy Value',
  DEAL_SELL_VAL: 'Deal Sell Value',
  OPEN_INTEREST: 'OI',
  PERCENT_OPEN_INTEREST_CHANGE: '%OI change',
  OPEN_PRICE: 'Open',
  HIGH_PRICE: 'Highest',
  LOW_PRICE: 'Lowest',
  CLOSE_PRICE: 'Close',
  VIEW_BY: 'View by',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
}
