import { createSlice } from '@reduxjs/toolkit'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime'
import { register } from '../../../../../utils/ReducerRegistry'
import { quarters } from '../constants'
import {
  getTopRevenueChartData,
  getTopTotalOperatingIncomeChartData,
} from './thunk'

const initialState = {
  loading: true,
  quarter: quarters.ANNUAL,
  year: getCurrentDateTime(FORMAT.YEAR),
  dataRevenueChart: [],
  dataTotalOperatingIncomeChart: [],
  retryFlag: true,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/TopRevenue',
  initialState,
  reducers: {
    changeQuarter: (state, action) => {
      state.quarter = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTopRevenueChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTopRevenueChartData.fulfilled, (state, action) => {
      state.dataRevenueChart = action.payload
      state.loading = false
    })
    builder.addCase(getTopRevenueChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    builder.addCase(getTopTotalOperatingIncomeChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getTopTotalOperatingIncomeChartData.fulfilled,
      (state, action) => {
        state.dataTotalOperatingIncomeChart = action.payload
        state.loading = false
      },
    )
    builder.addCase(
      getTopTotalOperatingIncomeChartData.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeQuarter, changeYear, changeRetryFlag } = slice.actions

export const selectYear = (state) => state[slice.name].year
export const selectQuarter = (state) => state[slice.name].quarter
export const selectLoading = (state) => state[slice.name].loading
export const selectDataTopRevenue = (state) =>
  state[slice.name].dataRevenueChart
export const selectDataTopTotalOperatingIncome = (state) =>
  state[slice.name].dataTotalOperatingIncomeChart
export const selectRetryFlag = (state) => state[slice.name].retryFlag

register(slice.name, slice.reducer)
