import React, { useEffect, useMemo, useState } from 'react'
import { Loading } from '../../../../common/loading'
import useGetICB from '../../../../common/masterData/useGetICB'
import { NoData } from '../../../../common/noData'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import TextEllipsis from '../../../../common/textEllipsis'
import style from '../style.module.css'
import { ItemCheckAll } from './ItemCheckAll'

const ListSector = ({ valueSearch, securitiesState, setSecuritiesState }) => {
  const { ICBs, loading } = useGetICB(true, true)

  const [filteredSector, setFilteredSector] = useState([])

  const isCheckAll = useMemo(() => {
    return filteredSector.every((item) =>
      securitiesState.sector.includes(item.icbId),
    )
  }, [securitiesState.sector, filteredSector])

  const handleCheckboxChange = (value) => {
    const icbId = +value.icbId
    if (!securitiesState.sector.some((sector) => sector === icbId)) {
      setSecuritiesState({
        ...securitiesState,
        sector: [...securitiesState.sector, icbId],
      })
    } else {
      setSecuritiesState({
        ...securitiesState,
        sector: [...securitiesState.sector.filter((item) => item !== icbId)],
      })
    }
  }

  const handleCheckAll = () => {
    if (isCheckAll) {
      setSecuritiesState({
        ...securitiesState,
        sector: [],
      })
    } else {
      const sectorId = filteredSector.map((item) => +item.icbId)
      setSecuritiesState({
        ...securitiesState,
        sector: sectorId,
      })
    }
  }

  useEffect(() => {
    setFilteredSector(
      ICBs.filter((sector) =>
        `${sector.icbName} L${sector.icbLevel}`
          ?.toLowerCase()
          .includes(valueSearch.toLowerCase()),
      ),
    )
  }, [ICBs, valueSearch])

  const renderSector = () => (
    <ScrollComponent bgColor="#D9D9D9">
      <ItemCheckAll checked={isCheckAll} onChange={handleCheckAll} />
      {filteredSector.map((sector) => {
        return (
          <div
            key={sector.icbId}
            className={style.itemTicker}
            onClick={() => handleCheckboxChange(sector)}
          >
            <div className={`ml-8 ${style.checkBox}`}>
              <label>
                <input
                  type="checkbox"
                  className={`checkbox line `}
                  checked={securitiesState.sector.includes(sector.icbId)}
                  value={sector.icbId}
                  readOnly
                />
              </label>
            </div>
            <div className={style.tickerInfo}>
              <div>
                <TextEllipsis
                  isI18n={false}
                  text={sector.icbName + ' L' + sector.icbLevel}
                />
              </div>
            </div>
          </div>
        )
      })}
    </ScrollComponent>
  )

  return (
    <>
      {loading ? (
        <Loading isBlack />
      ) : filteredSector.length === 0 ? (
        <NoData />
      ) : (
        <>{renderSector()}</>
      )}
    </>
  )
}

export default ListSector
