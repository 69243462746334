import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelExchangeRate from './panelExchangeRate'
import ExchangeRateVolatility from './panelExchangeRateVolatility'
import NeerBeer from './panelNeerBeer'

const MAP_KEY = {
  EXCHANGE_RATE: 'EXCHANGE_RATE',
  EXCHANGE_RATE_VOLATILITY: 'EXCHANGE_RATE_VOLATILITY',
  NEER_REER: 'NEER_REER',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.EXCHANGE_RATE]: {
    minWidth: 600,
    minHeight: 600,
  },
  [MAP_KEY.EXCHANGE_RATE_VOLATILITY]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.NEER_REER]: {
    minWidth: 350,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.EXCHANGE_RATE}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.EXCHANGE_RATE],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.EXCHANGE_RATE_VOLATILITY,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.NEER_REER,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXCHANGE_RATE_VOLATILITY}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.EXCHANGE_RATE_VOLATILITY],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.EXCHANGE_RATE].minWidth + 2 * COMPONENT.MARGIN,
    horizontalResize: [
      {
        key: MAP_KEY.NEER_REER,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.NEER_REER}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.NEER_REER],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.EXCHANGE_RATE_VOLATILITY].minHeight +
      2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.EXCHANGE_RATE].minWidth + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelExchangeRate
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXCHANGE_RATE}
          sizes={sizes}
          setSizes={setSizes}
        />
        <ExchangeRateVolatility
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXCHANGE_RATE_VOLATILITY}
          sizes={sizes}
          setSizes={setSizes}
        />
        <NeerBeer
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NEER_REER}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
