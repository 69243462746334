import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectItemCondition } from '../store/slice'
import SubItemCondition from './SubItemCondition'

const CheckItemChange = ({ indicatorId, setListItemCondition }) => {
  const itemCondition = useSelector(selectItemCondition(`${indicatorId}`))

  const lastItem = useRef([])

  useEffect(() => {
    if (itemCondition.length !== lastItem.current.length) {
      setListItemCondition(itemCondition.map((item) => item.indicatorId))
      lastItem.current = itemCondition
    }
  }, [itemCondition])

  return <></>
}

const ItemCondition = ({ indicatorId, totalRow }) => {
  const [listItemCondition, setListItemCondition] = useState([])

  const lastIndex = listItemCondition.length - 1

  return (
    <>
      {listItemCondition.map((item, index) => (
        <SubItemCondition
          key={index}
          indicatorId={indicatorId}
          index={index}
          lastIndex={lastIndex}
          totalRow={totalRow}
        />
      ))}
      <CheckItemChange
        indicatorId={indicatorId}
        setListItemCondition={setListItemCondition}
      />
    </>
  )
}

ItemCondition.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  totalRow: PropTypes.number.isRequired,
}

export default ItemCondition
