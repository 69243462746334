import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Loading } from '../../../common/loading'
import { Image } from '../../../common/news/image'
import { Item } from '../../../common/news/item'
import { Title } from '../../../common/news/title'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { selectSectorInfo } from '../../../common/topInfo/sectorTopInfo/store/slice'
import { FEATURES } from '../../../constants/routes'

export const HeaderCommon = ({ height, isShowTitle, data, isLoading }) => {
  const navigate = useCustomNavigate()
  const sectorInfo = useSelector(selectSectorInfo)

  const renderContent = () => {
    if (isLoading) {
      return <Loading />
    }

    if (!data || Object.keys(data).length === 0) {
      return (
        <div className="w-100 d-flex ali-center justify-content-center">
          <NoData />
        </div>
      )
    }

    const onAction = (newsId) => () => {
      navigate(
        FEATURES.sector.components['sector-constituents'].components[
          'news-report-detail'
        ].route,
        sectorInfo,
        '?newsId=' + newsId,
      )
    }
    return (
      <>
        <div className="h-100">
          <Image
            url={data.imageUrl}
            isCrop
            width={366}
            height={214}
            className="h-100 b-radius-4"
            isDefaultSquareImg={false}
          />
        </div>
        <div className="w-100 h-100 ml-10">
          <Item
            item={data}
            isShowBtnShare
            distanceBetweenItem={30}
            titleFontSize={26}
            titleLineClamp={2}
            onAction={onAction(data.newsId)}
          />
        </div>
      </>
    )
  }

  return (
    <div style={{ height }}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              {isShowTitle && (
                <div className="mb-10">
                  <Title
                    title={'sector.sectorConstituents.newsReport.LATEST_NEWS'}
                  />
                </div>
              )}

              {(size.height || size.height === 0) && (
                <div
                  className="w-100 d-flex position-relative"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  {renderContent()}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

HeaderCommon.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isShowTitle: PropTypes.bool,
  data: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

HeaderCommon.defaultProps = {
  isShowTitle: false,
}
