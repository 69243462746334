import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'

const initialState = {
  idTab: 0,
}

export const slice = createSlice({
  name: 'corporate/ownership/tabContent',
  initialState,
  reducers: {
    changeIdTab: (state, action) => {
      state.idTab = action.payload
    },
  },
})

export const selectIdTab = (state) => state[slice.name].idTab

export const { changeIdTab } = slice.actions

register(slice.name, slice.reducer)
