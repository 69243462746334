import React, { forwardRef, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Title } from '../../common/Title'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { changeStateNameChart, selectNameChart } from '../store/slice'
import style from './iconEditNameChart.module.css'

const RATIO_WIDTH = 0.95
const ICON_RENAME_WIDTH = 32

const IconEditNameChart = forwardRef(({ width, isEdit }, ref) => {
  const dispatch = useDispatch()
  const textEditorRef = useRef()

  const nameChart = useSelector(selectNameChart)

  const [isShow, setIsShow] = useState(false)

  const handleChangeStateNameChart = (data) => {
    dispatch(changeStateNameChart(data))
  }

  const renderTitle = () => {
    return (
      <>
        <div
          className={`align-center cursor-pointer ${style.contentTitleChart}`}
        >
          <Title
            appendStyle={{
              fontWeight: nameChart.bold ? 600 : '400',
              transform: nameChart.italic ? 'skew(-20deg)' : '',
              textDecoration: nameChart.underline ? 'underline' : '',
              textAlign: 'center',
            }}
            data={nameChart}
            fontSize={nameChart?.fontSize}
            isEdit={isEdit && isShow}
            maxWidth={width * RATIO_WIDTH - ICON_RENAME_WIDTH}
            setIsEdit={setIsShow}
            isI18n={false}
            exceptRef={textEditorRef}
            onSubmit={handleChangeStateNameChart}
          />
          {isEdit && (
            <div
              className={`align-center ${style.editName} ${
                isShow ? style.visibilityVisible : ''
              }`}
            >
              <IconRename
                className="cursor-pointer"
                onClick={() => setIsShow(true)}
              />
            </div>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      <div
        ref={ref}
        className={style.titleChart}
        style={{ justifyContent: nameChart.position }}
      >
        {renderTitle()}
      </div>
    </>
  )
})

export default IconEditNameChart
