import { Translate } from 'react-redux-i18n'
import { Span } from '../html/Span'

export const TextNote = ({
  value,
  fontSize,
  opacity,
  isI18n,
  className,
  style,
}) => {
  return (
    <div className={className} style={style}>
      <Span className="t-italic" style={{ opacity, fontSize }}>
        {isI18n ? <Translate value={value} /> : value}
      </Span>
    </div>
  )
}

TextNote.defaultProps = {
  value: '',
  isI18n: false,
  className: '',
  style: {},
  opacity: 0.4,
}
