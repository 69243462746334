import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectIsAcc, selectIsYearly } from '../balanceOfTrade/store/slice'
import { DATE_FILTER, LIST_RADIO } from './constants'
import style from './index.module.css'
import { changeTab, changeTypeCode, selectTypeCode } from './store/slice'
import { getGrowthTradeBalance } from './store/thunk'

export const Filter = ({ headerRef }) => {
  const dispatch = useDispatch()

  const typeCode = useSelector(selectTypeCode)
  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)
  const locale = useSelector((state) => state.i18n.locale)

  const [currentDateType, setCurrentDateType] = useState(
    DATE_FILTER.MONTHLY[0].value,
  )
  const [currentDateTypeYearly, setCurrentDateTypeYearly] = useState(
    DATE_FILTER.YEARLY[0].value,
  )

  const currentTimeRange = isYearly ? currentDateTypeYearly : currentDateType

  const handleChangeTab = (item) => {
    dispatch(changeTab(true))
    return isYearly
      ? setCurrentDateTypeYearly(item.value) && setCurrentDateType(null)
      : setCurrentDateType(item.value) && setCurrentDateTypeYearly(null)
  }

  useEffect(() => {
    dispatch(
      getGrowthTradeBalance({
        DataType: isAcc ? 'AccMonthly' : isYearly ? 'Yearly' : 'Monthly',
        TimeRange: currentTimeRange,
      }),
    )
  }, [currentTimeRange, isAcc, isYearly, locale, typeCode])

  return (
    <div className="d-flex j-b" ref={headerRef}>
      <ul className={`list-check d-flex mt-0`}>
        {LIST_RADIO.map((item) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={item.value === typeCode}
                onChange={() => dispatch(changeTypeCode(item.value))}
              />
              <Span
                style={{ fontSize: 12 }}
                className={item.value === typeCode ? style.active : ''}
              >
                <Translate value={item.title} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
      <DispatchActionTab
        data={isYearly ? DATE_FILTER.YEARLY : DATE_FILTER.MONTHLY}
        activeTab={currentTimeRange}
        onChangeTab={handleChangeTab}
      />
    </div>
  )
}
