import { CASH_TOTAL_ROW_ID } from '../panelMoneySupplyCredit/OtherCellColumn'

export const handleLineChartData = (payload, isValue) => {
  if (!payload.length) {
    return []
  }

  const monthYearNotDuplicate = getMonthYearNotDuplicates(payload)
  const DEFAULT_TITLE_LENGTH = 7

  return monthYearNotDuplicate.map((item) => {
    const dataWithMonthYear = payload.filter(
      (i) => i.month === item.month && i.year === item.year,
    )
    return dataWithMonthYear.reduce((prev, cur) => {
      const title =
        `${cur.month}-${cur.year}`.length === DEFAULT_TITLE_LENGTH
          ? `${cur.month}-${cur.year}`
          : 0 + `${cur.month}-${cur.year}`
      const key = `${cur.sbvTotalLiquidityVNTypeId}-${cur.isMoneySupply}`
      return {
        ...prev,
        [key]:
          !isValue || key === CASH_TOTAL_ROW_ID
            ? cur.value * 100
            : cur.value / 1000000000000,
        monthYear: title,
      }
    }, {})
  })
}

export const handleKeyXAxis = (payload) => {
  if (!payload.length) {
    return []
  }

  const DEFAULT_TITLE_LENGTH = 7
  return getMonthYearNotDuplicates(payload).map((item) => {
    const title =
      `${item.month}-${item.year}`.length === DEFAULT_TITLE_LENGTH
        ? `${item.month}-${item.year}`
        : 0 + `${item.month}-${item.year}`
    return title
  })
}

const getMonthYearNotDuplicates = (data) => {
  return data
    .filter((item, index, arr) => {
      return (
        index ===
        arr.findIndex((i) => i.year === item.year && i.month === item.month)
      )
    })
    .sort(sortByMonthYear)
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }
  }

  return 0
}

export const getTooltipContentData = (ids, dataTypeName) => {
  return ids.map((id) => {
    const currentItem = dataTypeName.find((item) => item.id === id)
    return {
      id: currentItem.id,
      title: currentItem.sbvTotalLiquidityVNTypeName,
    }
  })
}

export const handleTooltipTwoColumn = (ids, dataTypeName) => {
  const MAX_ITEM_OF_COLUMN = 8
  const tooltipData = getTooltipContentData(ids, dataTypeName)
  if (tooltipData.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: tooltipData, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(tooltipData.length / 2)
  return {
    leftColumn: tooltipData.concat().splice(0, leftColumnLength),
    rightColumn: tooltipData.concat().splice(leftColumnLength),
  }
}
