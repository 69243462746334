import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  HOLDING_OPTIONS,
  SECURITY_HOLDING_TABS,
  TABLE_COL_KEYS,
  TABLE_TABS,
} from '../constants'
import { getMaxMinDataByColId, getSortFieldDefaultByTableTab } from '../helpers'
import { formatStockCurrentSecurityHoldingGroupByIndustry } from '../helpers/stockCurrentSecurityHoldingHelpers'
import { formatStockSecurityHoldingHistoryData } from '../helpers/stockSecurityHoldingHistoryHelpers'
import { getTableDataThunk } from './thunk'

const defaultFilter = {
  securityHoldingTab: SECURITY_HOLDING_TABS.CURRENT,
  assetIds: [],
  holdingOption: HOLDING_OPTIONS.CONTRIBUTION,
  month: 0,
  year: 0,
  startDate: '',
  endDate: '',
  timeRange: TIME_RANGES.OneYear,
  isGroupByIndustry: false,
  page: 1,
  totalPage: 1,
  sortField: '',
  sortOrder: 1,
}

const initialState = {
  filters: {
    [TABLE_TABS.STOCK]: defaultFilter,
    [TABLE_TABS.BOND]: defaultFilter,
  },
  tableTab: TABLE_TABS.STOCK,
  reCalcWidth: false,
  loading: false,
  data: [],
  ids: [],
  initialIds: [],
  dataById: {},
  levelCollapse: {},
  assetColumns: [],
  tickers: [],
  maxMinByColId: {
    [TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE]: { max: 0, min: 0 },
    [TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE_ICB]: { max: 0, min: 0 },
  },
  isDisableCallApi: false,
}

export const slice = createSlice({
  name: 'fund/fundProfile/portfolio',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      const filter = {}
      if (action.payload.securityHoldingTab) {
        filter.sortField = getSortFieldDefaultByTableTab(
          action.payload,
          state.valueType,
        )
      }
      state.filters[state.tableTab] = {
        ...state.filters[state.tableTab],
        ...action.payload,
        ...filter,
      }
    },
    changeTableTab: (state, action) => {
      state.tableTab = action.payload
      if (!state.filters[state.tableTab].sortField) {
        state.filters[state.tableTab] = {
          ...state.filters[state.tableTab],
          sortField: getSortFieldDefaultByTableTab(
            action.payload,
            state.valueType,
          ),
        }
      }
    },
    changeReCalcWidth: (state) => {
      state.reCalcWidth = !state.reCalcWidth
    },
    changeIsDisableCallApi: (state, action) => {
      state.isDisableCallApi = action.payload
    },
    sortTableData: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    // Get table data
    builder.addCase(getTableDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTableDataThunk.fulfilled, (state, action) => {
      let data = []
      if (
        state.filters[state.tableTab].securityHoldingTab ===
        SECURITY_HOLDING_TABS.HISTORY
      ) {
        const { data: rawData, assetColumns } =
          formatStockSecurityHoldingHistoryData(action.payload.data)
        data = rawData.map((item, index) => ({
          ...item,
          id: index,
        }))
        state.assetColumns = assetColumns
        if (!state.tickers.length) {
          state.tickers = assetColumns
        }
      } else if (state.filters[state.tableTab].isGroupByIndustry) {
        data = formatStockCurrentSecurityHoldingGroupByIndustry(
          action.payload.data,
        )
        state.levelCollapse = keyBy(data, 'id')
      } else {
        data = action.payload.data.map((item, index) => ({
          ...item,
          id: index,
        }))
      }

      if (
        state.filters[state.tableTab].securityHoldingTab ===
        SECURITY_HOLDING_TABS.CURRENT
      ) {
        state.maxMinByColId = getMaxMinDataByColId(
          data,
          Object.keys(state.maxMinByColId),
        )
        const filters = state.filters[state.tableTab]
        if (!filters.month && !filters.year && data.length) {
          state.isDisableCallApi = true
          state.filters[state.tableTab] = {
            ...state.filters[state.tableTab],
            month: data[0].reportMonth,
            year: data[0].reportYear,
          }
        }
      }

      state.data = data
      state.ids = state.initialIds = data.map((e) => e.id)
      state.dataById = keyBy(data, 'id')
      state.filters[state.tableTab].totalPage = action.payload.totalPage
      state.loading = false
      state.reCalcWidth = !state.reCalcWidth
    })
    builder.addCase(getTableDataThunk.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectTableTab = (state) => state[slice.name].tableTab
export const selectFilters = (state) => {
  const tableTab = state[slice.name].tableTab
  return state[slice.name].filters[tableTab]
}
export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectDataById = (state) => state[slice.name].dataById
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectReCalcWidth = (state) => state[slice.name].reCalcWidth
export const selectAssetColumns = (state) => state[slice.name].assetColumns
export const selectTickers = (state) => state[slice.name].tickers
export const selectMaxMinByColId = (state) => state[slice.name].maxMinByColId
export const selectLevelCollapse = (state) => state[slice.name].levelCollapse
export const selectIsDisableCallApi = (state) =>
  state[slice.name].isDisableCallApi

export const {
  changeFilter,
  changeTableTab,
  changeIsDisableCallApi,
  sortTableData,
} = slice.actions

register(slice.name, slice.reducer)
