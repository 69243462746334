export const event = {
  FILTER: 'filter',
  ALL: 'All',
  VIET_NAM: 'Vietnam',
  OTHERS: 'Others',
  EVENT_TYPES: 'Event types',
  UPCOMING_EVENTS: 'Upcoming events',
  NEWS: 'Related News',
  FROM: 'From ',
  TO: ' to ',
  DATE: 'Date',
  EVENT: 'Event',
  DESCRIPTION: 'Description',
  CURRENT_VALUE: 'Current Value',
  PREVIOUSVALUE: 'Previous Value',
  GROWTH_YOY: 'YoY Growth',
  MONTH_1: 'Jan',
  MONTH_2: 'Feb',
  MONTH_3: 'Mar',
  MONTH_4: 'Apr',
  MONTH_5: 'May',
  MONTH_6: 'Jun',
  MONTH_7: 'Jul',
  MONTH_8: 'Aug',
  MONTH_9: 'Sep',
  MONTH_10: 'Oct',
  MONTH_11: 'Nov',
  MONTH_12: 'Dec',
  THOUSAND: 'Thousand',
  MILLION: 'Mil.',
  BILLION: 'Bn.',
  TRILLION: 'Tril.',
}
