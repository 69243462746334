import { createSlice } from '@reduxjs/toolkit'
import { register } from '../utils/ReducerRegistry'
import { DEFAULT_TIME_ZONE_OFFSET } from './constants'
import { getDefaultFontSize } from './helper'
import { getGeneralInfo, getProfile, getTimeZone } from './thunk'

const initialState = {
  isShow: false,
  loading: false,
  offSetTimeZone: DEFAULT_TIME_ZONE_OFFSET,
  dataGeneralInfo: {},
  dataProfile: {},
  listTimeZone: [],
  typeFontSize: getDefaultFontSize().key,
  isFirstTimeLoad: true,
}

const slice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    changePopup: (state, action) => {
      const { isShow } = action.payload
      state.isShow = isShow
    },
    changeTimeZone: (state, action) => {
      state.offSetTimeZone = action.payload
    },
    changeTypeFontSize: (state, action) => {
      state.typeFontSize = action.payload
    },
    changeDataGeneral: (state, action) => {
      state.dataGeneralInfo = {
        ...state.dataGeneralInfo,
        ...action.payload,
      }
    },
    changeIsFirstTimeLoad: (state, action) => {
      state.isFirstTimeLoad = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralInfo.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getGeneralInfo.fulfilled, (state, action) => {
      state.loading = false
      const { fontSizeId } = action.payload
      state.dataGeneralInfo = {
        ...action.payload,
        fontSizeId: fontSizeId || getDefaultFontSize().value,
      }
    })
    builder.addCase(getGeneralInfo.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(getTimeZone.fulfilled, (state, action) => {
      state.listTimeZone = action.payload
    })

    builder.addCase(getProfile.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false
      state.dataProfile = action.payload
    })
    builder.addCase(getProfile.rejected, (state) => {
      state.loading = false
    })
  },
})

export const {
  changePopup,
  changeTimeZone,
  changeTypeFontSize,
  changeDataGeneral,
  changeIsFirstTimeLoad,
} = slice.actions

export const selectIsShow = (state) => state[slice.name].isShow
export const selectOffSetTimeZone = (state) => state[slice.name].offSetTimeZone
export const selectDataGeneralInfo = (state) =>
  state[slice.name].dataGeneralInfo
export const selectDataProfile = (state) => state[slice.name].dataProfile
export const selectLoading = (state) => state[slice.name].loading
export const selectTimeZone = (state) => state[slice.name].listTimeZone
export const selectTypeFontSize = (state) => state[slice.name].typeFontSize
export const selectIsFirstTimeLoad = (state) =>
  state[slice.name].isFirstTimeLoad

register(slice.name, slice.reducer)
