import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class BondListProxy extends ProxyBase {
  getTemplates() {
    return this.get('BondListGetTemplates')
  }
  getTemplateById(params) {
    return this.get('BondListGetTemplate', params)
  }
  saveTemplate(params) {
    return this.post('BondListSaveTemplate', params)
  }
  renameTemplateById(params) {
    return this.post('BondListReNameTemplate', params)
  }
  updateTemplateById(params) {
    return this.post('BondListUpdateTemplate', params)
  }
  deleteTemplateById(params) {
    return this.post('BondListDeleteTemplate', params)
  }
  getFilterIndicator(params) {
    return this.get('GetFilterIndicator', params)
  }
  getAllIndicator(params) {
    return this.get('GetAllIndicator', params)
  }
  getBondListProxy(params) {
    return this.post('', params)
  }
  getBondListCount(params) {
    return this.post('count', params)
  }
  exportBondList(params) {
    return this.post('export', params, {}, 'download')
  }
  getWatchlistByUser(params) {
    return this.get('GetWatchListByUserId', params)
  }
}

export default new BondListProxy(ServiceProxyConfig.Bond.BondList.ServiceUrl)
