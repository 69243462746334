import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'

export const getImportContributeByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportContributeByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getImportContributeByProduct(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getImportGrowthByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportGrowthByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getImportGrowthByProduct(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExportContributeByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportContributeByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getExportContributeByProduct(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExportGrowthByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportGrowthByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getExportGrowthByProduct(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxDateDetail = createAsyncThunk(
  'economy/importExport/importExportByProduct/exportStructure/getMaxDateDetail',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getMaxDateDetail(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getIdsMapping = createAsyncThunk(
  'economy/importExport/importExportByProduct/getIdsMapping',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getIdsMapping(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
