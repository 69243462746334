import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { Left } from '../../../../../common/news/detail/left'
import { selectNewsById, selectNewsByIdLoading } from '../../store/slice'
import { getNewsById } from '../../store/thunk'

export const LeftContent = () => {
  const dispatch = useDispatch()

  const newsById = useSelector(selectNewsById)
  const isLoading = useSelector(selectNewsByIdLoading)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const newsId = params.get('newsId')

  useEffect(() => {
    dispatch(getNewsById({ NewsId: newsId || 1 }))
  }, [newsId])

  return <Left isLoading={isLoading} newsById={newsById} />
}
