import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { positionDropdown } from '../../../../common/dropdown'
import { Span } from '../../../../common/html/Span'
import { debounce } from '../../../../utils/Common'
import InputSearch from '../InputSearch'
import PopupIndicators from '../popupIndicators'
import { changeEquationIndicators } from '../store/slice'
import style from '../style.module.css'

const IndicatorSearch = ({ isEquationTypeCount }) => {
  const dispatch = useDispatch()

  const indicatorRef = useRef()

  const [isOpenIndicator, setOpenIndicator] = useState(false)
  const [keyword, setKeyword] = useState('')

  const handleCloseIndicators = () => {
    setOpenIndicator(false)
    setKeyword('')
  }

  const handleDeleteIndicators = () => {
    dispatch(changeEquationIndicators())
  }

  return (
    <div
      className={
        !isEquationTypeCount ? 'd-flex justify-content-space-between' : ''
      }
    >
      <div
        className="align-center"
        style={{ marginTop: isEquationTypeCount ? 20 : 0 }}
      >
        <Span className={style.textOrange}>
          <Translate value="financialChart.INDICATORS_1" />
        </Span>
        <div
          ref={indicatorRef}
          style={{ width: isEquationTypeCount ? '100%' : 'auto' }}
          onClick={() => setOpenIndicator(true)}
        >
          <InputSearch
            onChange={debounce((value) => {
              setKeyword(value)
            }, 500)}
            placeholder={I18n.t('financialChart.SEARCH_INDICATORS')}
            height={24}
            appendStyle={{
              width: !isEquationTypeCount ? 264 : 'auto',
            }}
          />
        </div>
        <PopupIndicators
          parentRef={indicatorRef}
          isShow={isOpenIndicator}
          onClose={handleCloseIndicators}
          position={
            isEquationTypeCount ? positionDropdown.BOTTOM_LEFT_RIGHT : ''
          }
          keyword={keyword}
        />
      </div>
      {!isEquationTypeCount && (
        <div className="centerItems" onClick={handleDeleteIndicators}>
          <i
            className="icon-trash cursor-pointer mr-8"
            style={{ color: '#8B8B8B' }}
          />
        </div>
      )}
    </div>
  )
}

export default IndicatorSearch
