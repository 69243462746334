export const common = {
  TITLE: 'Thông tin trái phiếu',
  no: 'Không',
  yes: 'Có',
  generalSetting: 'Thông tin tổng quan',
  TradingInformation: 'Thông tin giao dịch',
  Information: 'Thông tin',
  Orderbook: 'Sổ lệnh',
  chartInterest: 'Biểu đồ giá và lãi suất đáo hạn',
  SC14_MS001: 'Tính năng đang phát triển',
  SC14_MS002: 'Bạn phải chọn ít nhất 1 danh mục để thêm mã',
  SC14_MS003: 'Mã đã được thêm vào danh mục',
  SC14_MS004: 'Chỉ có thể thêm tối đa 20 mã',
}

export const generalSetting = {
  btnPricing: 'Tính giá',
  BondIsinCode: 'Mã ISIN:',
  OrganizationShortName: 'Tổ chức phát hành:',
  IssueDateId: 'Ngày phát hành:',
  MaturityDateId: 'Ngày đáo hạn:',
  ParValue: 'Mệnh giá (VND):',
  DurationId: 'Kỳ hạn phát hành:',
  remainingDuration: 'Kỳ hạn còn lại (Tháng):',
  CouponTypeId: 'Loại lãi suất coupon:',
  CouponValue: 'Lãi suất Coupon (%):',
  PaymentCalendarId: 'Kỳ hạn trả lãi:',
  IsCollateral: 'Đảm bảo:',
  IsPaymentGuarantee: 'Hình thức đảm bảo:',
  detail: 'Xem chi tiết',
  updating: 'Đang cập nhật',
  view: 'Xem',
  report: 'Bản cáo bạch:',
  popup: {
    title: 'Thông tin chi tiết',
    btnOk: 'OK',
    infoBasic: {
      title: 'THÔNG TIN CƠ BẢN',
      bondId: 'Mã trái phiếu:',
      codeISIN: 'Mã ISIN:',
      issues: 'Tổ chức phát hành:',
      sector: 'Ngành:',
      typeBond: 'Loại trái phiếu:',
    },
    interestRate: {
      title: 'LÃI SUẤT',
      typeCouppon: 'Kiểu lãi suất coupon:',
      method: 'Phương thức trả lãi:',
      interestPaymentPeriod: 'Kỳ trả lãi:',
      NumberOfExpectedPayable: 'Số kỳ trả lãi dự kiến:',
      FixedCoupon: 'Lãi suất coupon cố định:',
      NumberOfFixedCoupon: 'Số kỳ trả coupon cố định:',
      ReferenceInterestRate: 'Lãi suất tham chiếu:',
      FloatInterestSpread: 'Phần trội:',
      NumberofFloatcoupon: 'Số kỳ trả coupon thả nổi:',
    },
    IssueInformation: {
      title: 'THÔNG TIN PHÁT HÀNH',
      IssueMarket: 'Thị trường phát hành:',
      IssueMethod: 'Phương thức phát hành:',
      IssueForm: 'Hình thức phát hành:',
      Currency: 'Tiền tệ:',
      IssueDate: 'Ngày phát hành:',
      MaturityDate: 'Ngày đáo hạn:',
      ParValue: 'Mệnh giá (VND):',
      Duration: 'Kỳ hạn phát hành:',
      IssueValue: 'Giá trị phát hành (Tỷ VND):',
      OutstandingValue: 'Giá trị lưu hành (Tỷ VND)',
      RemainingDuration: 'Kỳ hạn còn lại (Tháng)',
    },
    ConditionsAttached: {
      title: 'ĐIỀU KIỆN KÈM THEO',
      Redemption: 'Mua lại:',
      Convertible: 'Hoán đổi:',
      Coveredwarrant: 'Chứng quyền:',
      Issueguarantee: 'Bảo lãnh phát hành:',
      Paymentguarantee: 'Bảo lãnh thanh toán:',
      CollateralType: 'Tài sản đảm bảo:',
      GreenBond: 'Trái phiếu xanh:',
      Status: 'Tình trạng:',
    },
  },
  popupFl: {
    title: 'Danh sách danh mục',
    optionAll: 'Tất cả',
    btnCancel: 'Hủy bỏ',
    btnAdd: 'Thêm',
    btnCrete: 'Tạo mới',
  },
}

export const tradingInformation = {
  filter: {
    cleanPrice: 'Giá sạch',
    dirtyPrice: 'Giá bẩn',
    note: 'Giá x 1. Khối lượng x1 . Giá trị x1,000,000',
  },
  topBody: {
    change1D: '%Thay đổi 1D:',
    change1W: '%Thay đổi 1W:',
    change1M: '%Thay đổi 1M:',
    changeYTD: '%Thay đổi YTD:',
    TotalValue: 'Tổng giá trị:',
    TotalVolume: 'Tổng khối lượng:',
    TotalForeignBuyVolume: 'Khối lượng NN bán:',
    TotalForeignSellVolume: 'Khối lượng NN mua:',
    Avg10daystradingvolume: 'Khối lượng trung bình 10 ngày:',
    Avgmonthlytradingvolume: 'Khối lượng trung bình 1 tháng:',
  },
  bottomBody: {
    titleLfet: 'CHỈ SỐ',
    ZSpread: 'Z-Spread',
    CreditSpread: 'Credit Spread',
    BidAskSpread: 'Bid - Ask Spread',
    Maculayduration: 'Maculay duration',
    ModifiedDuration: 'Modified Duration',
    PVBP: 'PVBP(VND)',
    Convexity: 'Convexity',

    titleRight: 'XẾP HẠNG TÍN NHIỆM TCPH',
    btnRight: 'Điểm tín dụng',
    CreditRatingAgency: 'Tổ chức xếp hạng',
    RatingDate: 'Ngày xếp hạng',
    RatingType: 'Loại xếp hạng',
    RatingProspect: 'Triển vọng xếp hạng',
    RatingHistory: 'Lịch sử xếp hạng ',
  },
  popup: {
    title: 'Lịch sử xếp hạng',
    table: {
      No: 'STT',
      CreditRatingAgency: 'Tổ chức xếp hạng',
      RatingDate: 'Ngày xếp hạng',
      RatingType: 'Loại xếp hạng',
      RatingProspect: 'Triển vọng xếp hạng',
    },
  },
}

export const information = {
  filter: {
    News: 'Tin tức',
    Files: 'Tài liệu',
  },
  table: {
    Date: 'Ngày',
    News: 'Tin tức',
    Detail: 'Chi tiết',
    view: 'Xem',
    event: 'Sự kiện',
  },
}

export const order = {
  filter: {
    tab: {
      Realtime: 'Realtime',
      History: 'Lịch sử',
    },
    tradingType: {
      Deal: 'Thỏa thuận',
      Match: 'Khớp lệnh',
    },
  },
  table: {
    Time: 'Thời gian',
    DirtyPrice: 'Giá bẩn',
    CleanPrice: 'Giá sạch',
    PriceChange: 'Thay đổi giá',
    percentPriceChange: '% Thay đổi giá',
    YTM: 'YTM',
    sumYTM: '+/- YTM',
    Volume: 'Khối lượng',
    TotalVolume: 'Tổng KL',
    Value: 'Giá trị',
    TotalValue: 'Tổng GT',
  },
  Note: 'Giá x 1. Khối lượng x1. Giá trị x1,000,000',
}

export const interestChart = {
  filter: {
    dirtyPrice: 'Giá bẩn',
    cleanPrice: 'Giá sạch',
    ytm: 'YTM',
  },
}
