import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import style from '../index.module.css'

const XAxisContribution = ({ maxXAxis }) => {
  const listValue = [
    <Span className="canvas-chart-title">-{formatVal(maxXAxis)}</Span>,
    <Span className="canvas-chart-title">-{formatVal(maxXAxis / 2)}</Span>,
    <Span className="canvas-chart-title">0.00&nbsp;</Span>,
    <Span className="canvas-chart-title">{formatVal(maxXAxis / 2)}&nbsp;</Span>,
    <Span className="canvas-chart-title">{formatVal(maxXAxis)}&nbsp;</Span>,
  ]

  return (
    <div className={style.xAxisContribution}>
      <div className="d-flex j-b">
        {listValue.map((item, index) => (
          <Fragment key={index}>{item}</Fragment>
        ))}
      </div>
    </div>
  )
}

XAxisContribution.propTypes = {
  maxXAxis: PropTypes.number.isRequired,
}

export default XAxisContribution
