import { useDispatch } from 'react-redux'
import SearchTicker from '../../../../../common/dataExplorer/filter/SearchTicker'
import style from '../index.module.css'
import { handleAddOneTicker } from './store/slice'

const SearchTickerComponent = () => {
  const dispatch = useDispatch()

  const handleAddTicker = ([ticker]) => {
    dispatch(handleAddOneTicker(ticker.organizationId))
  }

  return (
    <div className={style.searchTickerComponent}>
      <SearchTicker handleAddTicker={handleAddTicker} />
    </div>
  )
}

export default SearchTickerComponent
