import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { GrowthSector } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getTab } from '../store/slice'
import { ID_DOM_PANEL_PRODUCTION } from './constants'

export const PanelChartFirst = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const onTab = useSelector(getTab)

  const [isFullScreen, setIsFullScreen] = useState(false)

  const getTitle = useMemo(() => {
    return `economy.productionAndConsumption.production.CHART_FIRST.${onTab}`
  }, [onTab])

  return (
    <Panel
      title={getTitle}
      panelRefs={panelRefs}
      panelKey={mapKey.CHART_FIRST}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(getTitle)}
      downloadJpgParams={{
        domId: ID_DOM_PANEL_PRODUCTION.FIRST_CHART,
        nameScreen: UseI18n(
          'economy.productionAndConsumption.production.PRODUCTION',
        ),
        chartName: UseI18n(getTitle),
      }}
      handleCustom={setIsFullScreen}
    >
      <GrowthSector
        width={formatNumber(sizes[mapKey.CHART_FIRST].width - PANEL_PADDING)}
        height={formatNumber(
          sizes[mapKey.CHART_FIRST].height - HEADER_PANEL_HEIGHT,
        )}
        activeTab={onTab}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}

PanelChartFirst.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
