import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { ChartTooltip } from '../../../../../common/chart/customCharts/horizontalStackedBarSeries/ChartTooltip'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { formatVal } from '../../../../../utils/Value'

const POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
}

const WIDTH_NUMBER = 100
const TEMP_LABEL_WIDTH = 250
const MARGIN = 11

export const BlockBarItem = ({
  isTextLeftVal,
  isTextRightVal,
  color,
  widthColor,
  positionColor = POSITION.left,
  item,
  width,
  height,
  numRow,
}) => {
  const barRef = useRef()
  const parentRef = useRef()

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const openTooltip = () => setIsOpenTooltip(true)
  const closeTooltip = () => setIsOpenTooltip(false)

  const getPositionColor = () => {
    switch (positionColor) {
      case POSITION.RIGHT:
        return 'flex-end'
      default:
        return POSITION.LEFT
    }
  }

  const tooltipContentData = [
    {
      label: '',
      keyValue: 'monthYearKey',
      fontSize: 11,
      fontStyle: 'italic',
      formatValue: (val) => val,
    },
    {
      label: isTextLeftVal ? item['commodity_left'] : item['commodity_right'],
      keyValue: isTextLeftVal ? 'price_left' : 'price_right',
      fontSize: 11,
      formatValue: (val) =>
        formatVal(val) +
        ` ${I18n.t('economy.importExport.importExport.BILLION_USD')}`,
      fontWeightValue: 400,
    },
  ]

  return (
    <>
      <div
        className="d-flex position-relative"
        style={{
          height: height / numRow - MARGIN,
          marginTop: 5,
          justifyContent: getPositionColor(),
          maxHeight: 30,
        }}
        ref={barRef}
        onMouseOver={openTooltip}
        onMouseOut={closeTooltip}
      >
        {isTextLeftVal ? (
          <Span
            style={{
              left: 8,
              position: 'absolute',
              width: width - WIDTH_NUMBER || TEMP_LABEL_WIDTH,
            }}
            className="align-center"
          >
            <TextEllipsis
              isI18n={true}
              text={item?.commodity_left ?? EMPTY_VALUE}
              className="canvas-chart-title"
            />
          </Span>
        ) : (
          <Span
            style={{
              left: 8,
              position: 'absolute',
            }}
            className="align-center canvas-chart-title"
          >
            {formatVal(item?.price_right)}
          </Span>
        )}
        {isTextRightVal ? (
          <Span
            style={{
              right: 8,
              position: 'absolute',
              justifyContent: 'flex-end',
              width: width - WIDTH_NUMBER || TEMP_LABEL_WIDTH,
            }}
            className="align-center"
          >
            <TextEllipsis
              isI18n={true}
              text={item?.commodity_right ?? EMPTY_VALUE}
              className="canvas-chart-title"
            />
          </Span>
        ) : (
          <Span
            style={{
              right: 8,
              position: 'absolute',
            }}
            className="align-center canvas-chart-title"
          >
            {formatVal(item?.price_left)}
          </Span>
        )}
        <div
          style={{ width: widthColor, backgroundColor: color }}
          className=""
          ref={parentRef}
        ></div>
      </div>

      <ChartTooltip
        parentRef={parentRef}
        data={item}
        isOpen={isOpenTooltip}
        tooltipContentData={tooltipContentData}
      />
    </>
  )
}

BlockBarItem.propTypes = {
  color: PropTypes.string,
  widthColor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  positionColor: PropTypes.string,
  item: PropTypes.object.isRequired,
}

BlockBarItem.defaultProps = {
  positionColor: POSITION.left,
}
