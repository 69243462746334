import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import UseFontSize from '../../../common/hooks/useFontSize'
import UseI18n from '../../../common/hooks/useI18n'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { useResize } from '../../common/useResize'
import { ChartContent } from './ChartContent'
import { ChartTitle } from './ChartTitle'
import styles from './index.module.css'
import { selectDownloadingChart } from './store/slice'
import useDownloadChart from './useDownloadChart'

const ChartContainer = ({
  width,
  height,
  data = [],
  item,
  isEdit,
  schema,
  footerData,
  updateTitle,
  setIsEdit,
  isHaveDataChartPreview,
}) => {
  const titleRef = useRef()

  const typeFontSize = UseFontSize()

  const dimensionTitle = useResize(titleRef)

  const downloadingChartID = useSelector(selectDownloadingChart)

  const downloadingMessage = UseI18n('financialChart.DOWNLOADING')

  const [isDownloading] = useDownloadChart(
    downloadingChartID,
    item.id,
    item.name,
    isHaveDataChartPreview,
  )

  return (
    <div style={{ height }}>
      <SizeTracker key={typeFontSize}>
        {(size) => (
          <>
            {isDownloading && (
              <div className={styles.downloadContainer}>
                {downloadingMessage}
              </div>
            )}
            <div ref={titleRef} className="t-center" style={{ width: width }}>
              {item.detail.nameChart && (
                <ChartTitle
                  data={item}
                  width={width}
                  nameChart={item.detail.nameChart}
                  chartId={item.id}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  updateTitle={updateTitle}
                />
              )}
            </div>
            {size.height && (
              <div style={{ height: height - size.height }}>
                {data.length ? (
                  <ChartContent
                    data={data}
                    item={item}
                    width={width}
                    height={height - size.height}
                    schema={schema}
                    footerData={footerData}
                    dimensionTitle={dimensionTitle}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}

export default ChartContainer
