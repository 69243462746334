import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import {
  changeType,
  changeTypeName,
  selectListType,
  selectType,
  selectTypeName,
} from './store/slice'

export const SelectType = () => {
  const dispatch = useDispatch()

  // Use selector
  const type = useSelector(selectType)
  const types = useSelector(selectListType)
  const typeName = useSelector(selectTypeName)

  const getCurrentTypeName = (type) => {
    return types[type].display ?? typeName
  }

  // Actions
  const onChange = (value) => {
    dispatch(changeType(value))
    dispatch(changeTypeName(getCurrentTypeName(value)))
  }

  return (
    <div style={{ width: 150 }}>
      <SelectCustom
        value={type}
        isI18n={true}
        selectData={Object.keys(types).map((key) => ({
          name: types[key].display,
          value: types[key].value,
        }))}
        handleChange={onChange}
      />
    </div>
  )
}
