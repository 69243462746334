import {
  DEFAULT_PRE_VALUE_KEY,
  DEFAULT_XAXIS_DATA_KEY,
} from '../../../../../common/chart/basicChart/constants'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal } from '../../../../../utils/Value'

export const getSchema = () => {
  return [
    {
      unit: 'common.UNIT.BILLION_VND',
      isI18n: true,
      color: '#40ABFF',
      footer: {
        before: {
          bgColor: '#40ABFF',
        },
      },
      tooltip: {
        renderTitle: (data) =>
          formatDateTime(data.endDateId, FORMAT.MONTH_YEAR_NUMBER),
        formatValue: (value) => formatVal(value),
      },
    },
  ]
}

export const formatDataApi = (data) => {
  return data.map((item) => ({
    ...item,
    [DEFAULT_XAXIS_DATA_KEY]: item.ticker,
    [DEFAULT_PRE_VALUE_KEY + 1]: item.nav / 10 ** 9,
  }))
}
