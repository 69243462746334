import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class FinancialChartDataProxy extends ProxyBase {
  getIndicators(params) {
    return this.get('Indicators', params)
  }

  getDataCompany(params) {
    return this.get('Data/Company', params, '', true)
  }

  getDataEconomy(params) {
    return this.get('Data/Economy', params, '', true)
  }

  getDataSector(params) {
    return this.get('Data/Sector', params, '', true)
  }

  getDataIndex(params) {
    return this.get('Data/Index', params, '', true)
  }

  getDataAggregate(params) {
    return this.post('Data/Aggregate', params, {}, 'post', true)
  }

  getDataCount(params) {
    return this.post('Data/Count', params, {}, 'post', true)
  }

  getDataLatestPeriod(params) {
    return this.get('Data/LatestPeriod', params, '', true)
  }

  getDataQuery(params) {
    return this.post('Query', params, {}, 'post', true)
  }

  downloadDataExport(params) {
    return this.post('Export', params, {}, 'download')
  }
}

export default new FinancialChartDataProxy(
  ServiceProxyConfig.FinancialChart.ServiceUrl,
)
