import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Table as TableComponent } from '../../../../../common/table'
import {
  convertSortKeyToNumber,
  convertSortKeyToString,
} from '../../../../../utils/Common'
import {
  HOLDING_OPTIONS,
  SECURITY_HOLDING_TABS,
  TABLE_COL_KEYS,
} from '../constants'
import {
  getSortFieldDefaultByTableTab,
  getTableSchema,
  getTableThCellTopById,
  getTableThSchema,
} from '../helpers'
import {
  changeFilter,
  changeIsDisableCallApi,
  selectAssetColumns,
  selectDataById,
  selectDataCellById,
  selectFilters,
  selectIds,
  selectLevelCollapse,
  selectLoading,
  selectMaxMinByColId,
  selectReCalcWidth,
  selectTableTab,
  sortTableData,
} from '../store/slice'
import { withGetData } from './withGetData'

const Table = () => {
  const dispatch = useDispatch()

  // Selector
  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectIds)
  const loading = useSelector(selectLoading)
  const tableTab = useSelector(selectTableTab)
  const filters = useSelector(selectFilters)
  const reCalcWidth = useSelector(selectReCalcWidth)
  const maxMinByColId = useSelector(selectMaxMinByColId)
  const assetColumns = useSelector(selectAssetColumns)
  const dataById = useSelector(selectDataById)
  const levelCollapse = useSelector(selectLevelCollapse)

  const [valueSort, setValueSort] = useState('')
  const [rowsCollapse, setRowsCollapse] = useState([])

  // Actions
  const handleChangeFilter = (data) => {
    dispatch(changeIsDisableCallApi(false))
    dispatch(changeFilter(data))
  }

  const handleChangePage = (page) => {
    if (page) {
      handleChangeFilter({ page })
    }
  }

  const handleChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      handleChangeFilter({
        sortField: Object.keys(data)[0],
        sortOrder: convertSortKeyToNumber(Object.values(data)[0]),
      })
    } else {
      handleChangeFilter({
        sortField: getSortFieldDefaultByTableTab({
          tableTab,
          securityHoldingTab: filters.securityHoldingTab,
        }),
        sortOrder: 1,
      })
    }
  }

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  // Use effect
  useEffect(() => {
    const sortField =
      filters.sortField ||
      getSortFieldDefaultByTableTab({
        tableTab,
        securityHoldingTab: filters.securityHoldingTab,
      })
    setValueSort({ [sortField]: convertSortKeyToString(filters.sortOrder) })
  }, [tableTab, filters.securityHoldingTab])

  return (
    <TableComponent
      key={tableTab + filters.securityHoldingTab}
      ids={ids}
      schema={getTableSchema({
        tableTab,
        filters,
        locale,
        maxMinByColId,
        assetColumns,
        dataById,
        onRowCollapse,
      })}
      rowSpanSchema={getTableThSchema({
        tableTab,
        securityHoldingTab: filters.securityHoldingTab,
      })}
      tableHeadRowTop={getTableThCellTopById({
        tableTab,
        securityHoldingTab: filters.securityHoldingTab,
      })}
      getDataFromRedux={selectDataCellById}
      isLoading={loading}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      hasTooltip={false}
      hasPagination={
        filters.securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY
      }
      currentPage={filters.page}
      totalPage={filters.totalPage}
      reCalcWidths={reCalcWidth}
      stickies={{
        [TABLE_COL_KEYS.COL_STOCK_TICKER]: true,
        [TABLE_COL_KEYS.COL_STOCK_NAME]: true,
        [TABLE_COL_KEYS.COL_STOCK_PUBLIC_DATE]: true,
      }}
      isCollapse={filters.isGroupByIndustry}
      rowsCollapse={filters.isGroupByIndustry ? rowsCollapse : undefined}
      levelCollapse={filters.isGroupByIndustry ? levelCollapse : undefined}
      valueSort={valueSort}
      sort={sortTableData}
      renderContentPagination={
        filters.securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY &&
        filters.holdingOption === HOLDING_OPTIONS.VOLUME
          ? () => (
              <Span style={{ fontSize: 11 }} className="t-italic opacity-5">
                <Translate value="fund.fundProfile.portfolio.portfolioTable.NOTE_UNIT_THOUSAND_VND" />
              </Span>
            )
          : undefined
      }
      handleChangePage={handleChangePage}
      handleSortField={handleChangeSortField}
    />
  )
}

export default withGetData(Table)
