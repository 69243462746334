import { useEffect, useState } from 'react'
import {
  CHART_TYPES,
  DOT_TYPES,
} from '../../../common/chart/financialChart/constant'
import { ChartTypeComponent } from './components/ChartTypeComponent'
import { DotTypeComponent } from './components/DotTypeComponent'
import { RadioComponent } from './components/RadioComponent'
import {
  dotsOnLineTypes,
  lineStyleTypes,
  lineWidthTypes,
  smoothLines,
} from './constants'
import styles from './style.module.css'

const ChartTypeSettings = ({ data, setData }) => {
  const [chartType, setChartType] = useState(CHART_TYPES.LINE)
  const [lineStyle, setLineStyle] = useState(lineStyleTypes.SOLID)
  const [lineWidth, setLineWidth] = useState(lineWidthTypes.NORMAL)
  const [dotsOnLine, setDotsOnLine] = useState(dotsOnLineTypes.NO)
  const [smoothLine, setSmoothLine] = useState(smoothLines.YES)
  const [dotType, setDotType] = useState(DOT_TYPES.OVAL)

  const isChange = () => {
    return (
      data.chartType !== chartType ||
      data.lineStyle !== lineStyle ||
      data.lineWidth !== lineWidth ||
      data.dotsOnLine !== dotsOnLine ||
      data.smoothLine !== smoothLine ||
      data.dotType !== dotType
    )
  }

  useEffect(() => {
    if (isChange()) {
      setData({
        ...data,
        chartType,
        lineStyle,
        lineWidth,
        dotsOnLine,
        smoothLine,
        dotType,
      })
    }
  }, [chartType, lineStyle, lineWidth, dotsOnLine, smoothLine, dotType])

  useEffect(() => {
    if (data.chartType !== chartType) {
      data.chartType && setChartType(data.chartType)
    }
    if (data.lineStyle !== lineStyle) {
      data.lineStyle && setLineStyle(data.lineStyle)
    }
    if (data.lineWidth !== lineWidth) {
      data.lineWidth && setLineWidth(data.lineWidth)
    }
    if (data.dotsOnLine !== dotsOnLine) {
      typeof data.dotsOnLine === 'boolean' && setDotsOnLine(data.dotsOnLine)
    }
    if (data.smoothLine !== smoothLine) {
      typeof data.smoothLine === 'boolean' && setSmoothLine(data.smoothLine)
    }
    if (data.dotType !== dotType) {
      data.dotType && setDotType(data.dotType)
    }
  }, [data])

  return (
    <div className={styles.settingBlockContainer}>
      <ChartTypeComponent isParent value={chartType} onChange={setChartType} />
      {[CHART_TYPES.LINE, CHART_TYPES.AREA, CHART_TYPES.STACK_AREA].includes(
        chartType,
      ) && (
        <>
          <RadioComponent
            title="financialChart.LINE_STYLE"
            value={lineStyle}
            options={Object.keys(lineStyleTypes).map((key) => ({
              title: 'financialChart.' + key,
              value: lineStyleTypes[key],
            }))}
            onChange={setLineStyle}
            itemClass={styles.settingBlockItemChartTypeRadio}
          />
          <RadioComponent
            title="financialChart.LINE_WIDTH"
            value={lineWidth}
            options={Object.keys(lineWidthTypes).map((key) => ({
              title: 'financialChart.' + key,
              value: lineWidthTypes[key],
            }))}
            onChange={setLineWidth}
            itemClass={styles.settingBlockItemChartTypeRadio}
          />
          <RadioComponent
            title="financialChart.DOTS_ON_LINE"
            value={dotsOnLine}
            options={Object.keys(dotsOnLineTypes).map((key) => ({
              title: 'financialChart.' + key,
              value: dotsOnLineTypes[key],
            }))}
            onChange={setDotsOnLine}
            itemClass={styles.settingBlockItemChartTypeRadio}
          />
          <RadioComponent
            title="financialChart.SMOOTH_LINE"
            value={smoothLine}
            options={Object.keys(smoothLines).map((key) => ({
              title: 'financialChart.' + key,
              value: smoothLines[key],
            }))}
            onChange={setSmoothLine}
            itemClass={styles.settingBlockItemChartTypeRadio}
          />
        </>
      )}
      {[CHART_TYPES.LINE, CHART_TYPES.DOT].includes(chartType) && (
        <DotTypeComponent value={dotType} onChange={setDotType} />
      )}
    </div>
  )
}

export default ChartTypeSettings
