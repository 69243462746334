import { CellWithMaxMinByCol } from '../../../../common/table/CellWithMaxMinByCol'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_SECTOR_ALLOCATION_KEYS,
  VALUE_TYPES,
} from '../constants'
import { selectMaxMinByColId } from '../store/slice'

export const sectorAllocationSchema = ({
  sectorAllocationColumns,
  valueType,
  locale,
}) => {
  return [...TABLE_SECTOR_ALLOCATION_KEYS, ...sectorAllocationColumns].map(
    (item) => {
      const result = {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
        },
        tdStyle: {
          textAlign: 'right',
        },
      }

      if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
        return {
          ...result,
          render: (val) => formatDateTime(val, FORMAT.DATE, locale),
        }
      }

      return {
        ...result,
        colId: valueType + item.colId,
        canCustomTd: true,
        render: (val, _, props) => (
          <CellWithMaxMinByCol
            val={val}
            colId={valueType + item.colId}
            format={(val) =>
              valueType === VALUE_TYPES.ABSOLUTE
                ? formatVal(val / TABLE_COL_FORMATS.COL_SECTOR_ABSOLUTE)
                : valToPercent(val)
            }
            props={props}
            selectMaxMinRedux={selectMaxMinByColId}
          />
        ),
      }
    },
  )
}

export const sectorSort = [
  136, 148, 154, 14, 2, 98, 29, 10, 33, 64, 72, 128, 164, 140, 59, 89, 113, 108,
  122,
]

export const formatSectorAllocationData = (rawData) => {
  const dataById = {}
  const data = []
  const sectorById = {}

  rawData.forEach((item, index) => {
    if (typeof dataById[item.organizationId] === 'number') {
      data[dataById[item.organizationId]] = {
        ...data[dataById[item.organizationId]],
        [`${VALUE_TYPES.ABSOLUTE}${item.icbId}`]: item.value_ICB,
        [`${VALUE_TYPES.RELATIVE}${item.icbId}`]: item.percent_Icb,
      }
    } else {
      dataById[item.organizationId] = data.length
      data.push({
        endDateId: item.endDateId,
        scheduleName: item.scheduleName,
        fundManagement: item.fundManagement,
        fundName: item.fundName,
        fundStructureName: item.fundStructureName,
        fundTypeName: item.fundTypeName,
        activeStatusName: item.activeStatusName,
        organizationId: item.organizationId,
        ticker: item.ticker,
        totalRow: item.totalRow,
        [`${VALUE_TYPES.ABSOLUTE}${item.icbId}`]: item.value_ICB,
        [`${VALUE_TYPES.RELATIVE}${item.icbId}`]: item.percent_Icb,
      })
    }

    if (!sectorById[item.icbId]) {
      sectorById[item.icbId] = { title: item.icbName, colId: item.icbId }
    }
  })

  return {
    data,
    sectorColumns: sectorSort.reduce(
      (arr, id) => (sectorById[id] ? [...arr, sectorById[id]] : arr),
      [],
    ),
  }
}
