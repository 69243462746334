export const marginLoans = {
  MARGIN_LOANS: 'Nợ vay ký quỹ',

  MARGIN_AVG_TRADING_VALUE: 'Tỉ lệ nợ vay ký quỹ/GTGD trung bình',
  MARGIN_AVG_MATCHED: 'Nợ vay ký quỹ/GTGD trung bình',
  MARGIN_AVG_MATCHED_TIMES: 'Nợ vay ký quỹ/GTGD trung bình (lần)',
  TIMES: 'lần',
  POINT: 'điểm',
  RATIO_TIMES: 'Tỉ lệ (lần)',
  VNINDEX_POINTS: 'VNINDEX (điểm)',
  NOTE_MARGIN_AVG_TRADING:
    'Ghi chú: GTGD trung bình được tính cho các giao dịch khớp lệnh trên sàn HOSE và HNX trong 15 ngày trước và sau thời điểm kết thúc mỗi quý.',

  MARGIN_LEVERAGE_RATIO: 'Tỷ lệ đòn bẩy',
  LEVERAGE_RATIO: 'Tỷ lệ đòn bẩy',
  LEVERAGE_RATIO_ADJ: 'Tỷ lệ đòn bẩy (%)',
  MARGIN: 'Nợ vay ký quỹ',
  MARGIN_BILLION: 'Nợ vay ký quỹ (Tỷ VNĐ)',
  BILLION: 'Tỷ VNĐ',
  NOTE_MARGIN_LEVERAGE:
    'Ghi chú: Tỷ lệ đòn bẩy (điều chỉnh) là Tỷ lệ nợ vay ký quỹ/ Tổng giá trị vốn hóa theo freefloat trên HOSE và HNX.',

  MARGIN_GROWTH: 'Tăng trưởng nợ vay ký quỹ',
  MARGIN_LOANS_OWNER_EQUITY: 'Nợ vay ký quỹ/VCSH',
  ADD_TICKER: 'Thêm mã',
  TICKER: 'Mã',
  NAME: 'Tên',
  OTHER: 'Khác',
  COMPARE_TO: 'So với<br>',
  SUM: 'Tổng',
  YEARLY: 'Hàng năm',
  ALL_EXCHANGE: 'Tất cả sàn',
  TITLE: 'Danh mục',
  DESCRIPTION: 'Mô tả',
  DELETE_ALL_TICKER: 'Xóa tất cả',
  DELETE_MESSAGE: 'Bạn muốn xóa <b><i>Tất cả mã</i></b>?',

  ALL: 'Tất cả công ty chứng khoán',
  BILLION_VND: '(Tỷ VNĐ)',
  THOUSAND_BILLION_VND: '(Nghìn tỷ VND)',
  MARGIN_LOANS_OF: 'Nợ vay ký quỹ của',
  SECURITIES: 'Chứng khoán',
  TOTAL: 'Tổng',
}
