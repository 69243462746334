import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { Span } from '../html/Span'
import style from './index.module.css'

export const Collapse = (props) => {
  const { children, id, title, open = false, onToggle } = props

  const openCollapse = () => {
    const el = document.getElementById(id)
    if (!el) {
      return
    } else {
      const content = el.nextElementSibling
      content.style.maxHeight = content.scrollHeight + 'px'
    }
  }

  const closeCollapse = () => {
    const el = document.getElementById(id)
    if (!el) {
      return
    } else {
      const content = el.nextElementSibling
      content.style.maxHeight = null
    }
  }

  useEffect(() => {
    if (open) {
      openCollapse()
    } else {
      closeCollapse()
    }
  }, [open, children])

  const onTogglePanel = useCallback(() => {
    onToggle(!open)
  }, [open])

  return (
    <>
      <button
        id={id}
        className={style['collapse-button']}
        onClick={onTogglePanel}
      >
        <div className={style['collapse-button-content']}>
          <Span style={{ fontSize: 12 }}>{title}</Span>
          <Span style={{ fontSize: 10 }}>
            <i
              className={`${open ? 'icon-arrow-up' : 'icon-arrow-down'} ${
                style['icon']
              }`}
            />
          </Span>
        </div>
      </button>
      <div className={style['collapse-content']}>{children}</div>
    </>
  )
}

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

Collapse.defaultProps = {
  open: false,
}
