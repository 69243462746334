export const monetary = {
  OPEN_MARKET: 'Open Market',
  MONEY_SUPPLIES_CREDIT: 'Money Supplies - Credit',
  EXCHANGE_RATE: 'Exchange Rate',
  INTEREST_RATE: 'Interest Rate',
  OPEN_MARKET_OPERATIONS: 'Open Market Operations',
  MONEY_MARKET_OPERATIONS: 'Money Market Operations',
  OPEN_MARKET_AUCTION: 'OPEN MARKET AUCTION RESULTS',
  OMO_NET_INJECTION: 'OMO net injection / withdrawal',
  MONEY_SUPPLY_CREDIT: 'Money supply - Credit to the economy',
  GROWTH_MONEY_SUPPLY_CREDIT_BALANCE:
    'Growth of Money supply and credit to the economy',
  CONTRIBUTION_CREDIT_GROWTH:
    'Contribution of Economic sectors to credit growth',
  GROWTH_YOY: 'Total liquidity & Credit growth over 5 years',
  EXCHANGE_RATE_VOLATILITY: 'Exchange rate volatility',
  NEER_BEER: 'Neer and Reer',
  DEPOSIT_RATE_COMMERCIAL: 'Deposit interest rates of Commercial banks',
  STATISTICAL_INTEREST_RATE: 'Deposit interest rates comparison ',
  DEPOSIT_RATE_VIET_NAM: 'SBV statistics interest rates',
  AVERAGE_FUNDING_COST: 'Average deposit & lending interest rates',
  UNIT_BILLION: 'Unit: billions VND',
  UNIT_TRILLION: 'Unit: trillions VND',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  REVERSE_REPO: 'Reverse Repo',
  SELL_OUTRIGHT: 'Sell outright',
  BILLION_VND: '(Billions VND)',
  TRILLION_VND: '(Trillions VND)',
  TRILLION_TOOLTIP: ' Tril. VND',
  PERCENT_YEAR: '(%/year)',
  DAY: '(day)',
  TENDER_DAY: 'Aunction Date',
  PARTICIPANTS_WINNERS: 'Participants/winners',
  AMOUNT_WINNING_BIDS: 'Amount of the winning bids',
  MATURITIES: 'Maturities',
  VALUE: 'Value',
  PROPORTION: 'Proportion',
  ALL: 'All',
  BUY_TM: 'Buy',
  BUY_CK: 'Transfer',
  SELL: 'Sell',
  BANK: 'Bank',
  UNIT_PERCENT: 'Unit: %',
  UNIT_VND: 'Unit: VND',
  DATE: 'Date',
  FROM: 'From',
  TO: 'to',
  TH1: 'Th1',
  TH2: 'Th2',
  TH3: 'Th3',
  TH4: 'Th4',
  TH5: 'Th5',
  TH6: 'Th6',
  TH7: 'Th7',
  TH8: 'Th8',
  TH9: 'Th9',
  TH10: 'Th10',
  TH11: 'Th11',
  TH12: 'Th12',
  SHARE_OUTSTANDING: 'Outstanding volume',
  TOTAL_CREDIT: 'Total outstanding loans',
  BUY: 'Buy',
  TRANSFER: 'Transfer',
  MAX_ITEM: 'The number of selected items is no more than 20 items.',
  HISTORICAL_DATA_INFO:
    'Historical data is updated monthly. The system navigates to most recent data date.',
  MONTH: 'Month',
  YEAR: 'Year',
  INSTITUTIONAL: 'Institutional',
  INDIVIDUAL: 'Individual',
  DATA_UPDATE: 'Open market data is updated to ',
}
