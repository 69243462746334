import { valDivTrillion } from '../../../../utils/Value'
import { quarters } from './constants'

export const formatData = (data, barKey, lineKey, scatterDiamondKey) => {
  return data.map((item) => ({
    ...item,
    [barKey]: valDivTrillion(item[barKey]),
    [lineKey]: item[lineKey] ? item[lineKey] * 100 : item[lineKey],
    [scatterDiamondKey]: item[scatterDiamondKey]
      ? item[scatterDiamondKey] * 100
      : item[scatterDiamondKey],
  }))
}

export const getLatestQuarters = (count) => {
  let quarter = Math.floor((new Date().getMonth() + 3) / 3)
  let year = new Date().getFullYear()
  return Array.from(Array(count)).map((_, index) => {
    const result = { year, quarter }
    quarter--
    if (quarter === quarters.ANNUAL) {
      year--
    }
    if (quarter < quarters.ANNUAL) {
      quarter = quarters.Q4
    }
    return result
  })
}
