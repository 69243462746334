export const chartFillColor = {
  ri: '#facc5c',
  riyg: '#45b29d',
}

export const chartBarKeys = ['ri']

export const chartLineKeys = ['riyg']

export const chartMappingDisplayName = {
  ri: 'sector.financialAnalysis.security.performance.REVENUE_INVESTMENT',
  riyg: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
}

export const chartFormat = {
  ri: 10 ** 9,
  riyg: 0.01,
}
