import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'

import { QUARTERS } from '../../../../../utils/Datetime'
import { TIME_FREQUENCY, colorArray } from '../constants'
import {
  keys,
  selectDebtStatistic,
  selectFilterDebtAndLoan,
  selectFilterDebtStatistic,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const DebtStatistic = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.DEBT_STATISTIC))
  const { data } = useSelector(selectDebtStatistic)
  const { year, quarter } = useSelector(selectFilterDebtStatistic)
  const filterDebtAndLoan = useSelector(selectFilterDebtAndLoan)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataPieChart = useMemo(() => {
    const filterData = data.filter((item) => item.loan !== 0)

    const result = filterData
      .map((item) => ({
        text: item.organizationShortName,
        value: item.loanPercent * 100,
        locationId: item?.lendingOrganizationId,
        totalValue: item.loan,
      }))
      .slice(0, 3)
    if (filterData.length !== 0) {
      result.unshift({
        text: 'bond.corporateBond.debtStatistic.BOND',
        value: data[0]?.bondPercent * 100,
        locationId: data[0]?.lendingOrganizationId,
        totalValue: data[0]?.bond,
        isI18n: true,
      })
      result.push({
        text: 'bond.corporateBond.debtStatistic.OTHER',
        value:
          (1 -
            (data[0]?.loanPercent ?? 0) -
            (data[1]?.loanPercent ?? 0) -
            (data[2]?.loanPercent ?? 0) -
            (data[0]?.bondPercent ?? 0)) *
          100,
        locationId: data[0]?.lendingOrganizationId,
        totalValue: data[0]?.other,
        isI18n: true,
      })
    }

    return result.map((item, index) => ({ ...item, color: colorArray[index] }))
  }, [data])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => <ChartTooltip data={dataChart} />

  const titleJpg = useMemo(() => {
    const title = I18n.t('bond.corporateBond.debtStatistic.TITLE')
    const time =
      filterDebtAndLoan === TIME_FREQUENCY.QUARTER
        ? `${I18n.t(
            QUARTERS.find((item) => item.value === quarter).name,
          )}-${year}`
        : year

    return `${title}-${time}`
  }, [quarter, year, locale])

  return (
    <Panel
      title="bond.corporateBond.debtStatistic.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'debtStatisticChartId',
        nameScreen: I18n.t('bond.corporateBond.secondaryMarket.TITLE'),
        chartName: I18n.t('bond.corporateBond.debtStatistic.TITLE'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="debtStatisticChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {!!formatDataPieChart.length ? (
                        <PieChartWithFooter
                          data={formatDataPieChart}
                          height={height - size.height}
                          width={width}
                          renderTooltip={renderTooltip}
                          footerRight
                          footerProps={{
                            isOneColumn: true,
                            appendStyle: { gridGap: 8 },
                          }}
                          isI18n
                        />
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

DebtStatistic.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default DebtStatistic
