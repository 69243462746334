const smartScreening = {
  MAX_TEMPLATE_1: 'Chỉ có thể lưu tối đa 100 biểu mẫu cá nhân.',
  MAX_TEMPLATE_2: 'Hãy thử lại!',
  MAX_CONDITION: 'Số lượng chỉ tiêu không vượt quá 100.',
  MAX_RESULT_1: 'Kết quả lọc vượt quá giới hạn số lượng 300 công ty.',
  MAX_RESULT_2: 'Vui lòng thay đổi điều kiện lọc để giảm số lượng kết quả.',
  TEMPLATE_EXIST: 'Tên biểu mẫu đã tồn tại.<br/>Hãy thử lại!',
  TEMPLATE_EXIST_2: 'Tên biểu mẫu đã tồn tại. Hãy thử lại!',
  TEMPLATE_REQUIRE: 'Nhập tên biểu mẫu.',
  MAX_MORE_CONDITION: 'Số lượng chỉ tiêu không vượt quá 100.',
  DUPLICATE_INDICATOR: 'Chỉ tiêu đã tồn tại.',
  TITLE_DELETE_TEMPLATE: 'Xóa biểu mẫu',
  ASK_DELETE_TEMPLATE: 'Bạn chắc chắn muốn xóa ',
  INTERIM_OUT_OF_DATE:
    'Kỳ lọc đã quá hạn. Hãy cập nhật lại kỳ lọc cho từng chỉ tiêu.',
  EXCHANGE_LOW: 'Sàn',
  ALL_EXCHANGE: 'Tất cả sàn',
  SECTOR_LOW: 'Ngành',
  ALL_SECTOR: 'Tất cả ngành',
  TEMPLATE: 'Biểu mẫu',
  RENAME: 'Sửa tên',
  DELETE: 'Xóa',
  SEARCH: 'Tìm kiếm',
  APPLY: 'Áp dụng',
  UNIT: 'Đơn vị',
  FROM_VALUE: 'Từ giá trị',
  TO_VALUE: 'đến giá trị',
  VALUE: 'Giá trị',
  NUMBER_OF_RESULT: 'Số lượng kết quả',
  RESULT: 'kết quả',
  CREATE_WATCHLIST: 'Tạo danh mục',
  0: 'STT',
  1: 'MÃ CK',
  2: 'TÊN CÔNG TY',
  3: 'SÀN',
  SECTOR_L1: 'NGÀNH CẤP 1',
  4: 'NGÀNH CẤP 2',
  SECTOR_L3: 'NGÀNH CẤP 3',
  SECTOR_L4: 'NGÀNH CẤP 4',
  SECTOR_L5: 'NGÀNH CẤP 5',
  YEAR: 'Năm',
  SELECTED: 'Đã chọn',
  CREATE: 'Tạo mới',
  UPDATE: 'Cập nhật',
  CREATE_WATCHLIST_2: 'TẠO DANH MỤC',
  UPDATE_WATCHLIST: 'Cập nhật danh mục',
  SCREENER_DATE: 'Ngày sàng lọc',
  TITLE_FILTER: 'Bộ lọc',
  TITLE_CONDITION: 'ĐIỀU KIỆN',
  TITLE_RESULT: 'KẾT QUẢ',
  INDICATOR: 'Chỉ số',
  RATIO: 'Chỉ số chính',
  FINANCIAL: 'Báo cáo tài chính',
  RESET: 'Reset',
  FIELD: 'Chỉ tiêu',
  TYPE: 'Kiểu dữ liệu',
  INTERIM: 'Kỳ sàng lọc',
  ADDITIONAL_CRITERIA: 'Tiêu chí mở rộng',
  ADD_MORE_INFOR: 'Thêm chỉ tiêu',
  EXPORT: 'Xuất Excel',
  PERIOD: 'Quý',
  QUICK_SELECT: 'GÓI DỮ LIỆU',
  SELECTED_ITEM: 'Chỉ tiêu được chọn',
  RATIO_2: 'CHỈ SỐ CHÍNH',
  FINANCIAL_2: 'BÁO CÁO TÀI CHÍNH',
  ADD_MORE_INFOR_2: 'THÊM CHỈ TIÊU',
  TOOL: 'Công cụ',
  SMART_SCREENING: 'Bộ lọc thông minh',
  TITLE_RESET: 'RESET',
  ASK_RESET: 'Bạn có muốn làm mới toàn bộ các điều kiện?',
  BTN_SAVE_AS: 'Lưu mới',
  LABEL_NAME_TEMPLATE: 'Tên của biểu mẫu:',
  WATCHLIST_ID_REQUIRE: 'Chọn danh mục.',
  CHOOSE_WATCHLIST: 'Chọn danh mục',
  NOTICE: 'LƯU Ý',
  CONTENT_NOTICE:
    'Bạn đang cố gắng thêm tất cả các chỉ số trong nhóm này bằng cách nhấp đúp. Bạn có muốn tiếp tục?',
  OF_TOTAL: ' tổng chỉ số được thêm vào trong bảng kết quả.',
  NO_MATCH_RESULT:
    'Không có cổ phiếu nào đáp ứng tiêu chí sàng lọc, quý vị có thể thay đổi tiêu chí sàng lọc và thực hiện lại.',
  CREATE_WATCHLIST_SUCCESS: 'Danh mục của bạn đã được tạo thành công.',
  UPDATE_WATCHLIST_SUCCESS: 'Thay đổi của bạn đã được cập nhật thành công!',
  CREATE_TEMPLATE_SUCCESS: 'Biểu mẫu của bạn đã được tạo thành công.',
  UPDATE_TEMPLATE_SUCCESS: 'Biểu mẫu của bạn đã được cập nhật thành công.',
  ANNUAL: 'Hàng năm',
  BLANK: 'trống',
  AND: 'và',
  THAN: 'so với',
}

export default smartScreening
