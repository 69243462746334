import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  getDataByMonthAndYear,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
} from '../../common/helper'
import {
  DEFAULT_LIST_CPI,
  NUM_OF_PERIOD,
  NUM_OF_PERIOD_FIRST_FETCH,
  TYPE_CODE,
} from '../constants'
import { getItemParent, keepCurrentListByIndex } from '../helper'
import {
  cpiAndInflationTableContent,
  cpiAndInflationTableContentFirstRender,
} from './thunk'

const slice = createSlice({
  name: 'economy/cpiAndInflation/cpiAndInflationTable',
  initialState: {
    idsCPI: [],
    dataById: {},
    isLoading: false,
    isContentLoading: false,
    listCPISector: [],
    isGetData: true,
    isChangeTypeCode: false,
    data: [],
    typeCode: TYPE_CODE.YoY,
    groupColumns: [],
    maxMonth: null,
    maxYear: null,
    currentMinMonth: null,
    currentMinYear: null,
    payloadData: [],
    dataWithColorId: [],
    periodNum: NUM_OF_PERIOD_FIRST_FETCH,
    countPeriodNum: NUM_OF_PERIOD_FIRST_FETCH,
    isAfterChangeAndLoading: false,
    currentAddedItems: [],
    levels: [],
    initialIds: [],
    isChangeLocale: false,
    isNotGetMaxMonth: false,
  },
  reducers: {
    addSectorCPIToList: (state, action) => {
      state.isGetData = true
      state.listCPISector = [...state.listCPISector, action.payload]
    },
    removeSectorCPIFromList: (state, action) => {
      state.isGetData = false
      state.listCPISector = state.listCPISector.filter(
        (sector) => sector.cpivnTypeId !== action.payload,
      )
    },
    changeTypeCode: (state, action) => {
      state.typeCode = action.payload
      state.isChangeTypeCode = true
      state.isAfterChangeAndLoading = true
    },
    keepCurrentItems: (state, action) => {
      state.currentAddedItems = action.payload
    },
    changePeriodNum: (state, action) => {
      state.periodNum = action.payload
    },
    changeLocale: (state) => {
      state.isChangeLocale = true
      state.isChangeTypeCode = true
    },
    sort: (state, action) => {
      state.idsCPI = getIdsFromProps(
        state.idsCPI,
        state.dataById,
        action.payload,
        state.initialIds,
        0,
        state.levels,
      )
    },
    changeIsNotGetMaxMonth: (state, action) => {
      state.isNotGetMaxMonth = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(cpiAndInflationTableContentFirstRender.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(
      cpiAndInflationTableContentFirstRender.fulfilled,
      (state, action) => {
        state.isContentLoading = false
        state.isChangeTypeCode = false

        const payloadNameData = action.payload?.nameData || []
        const nameData = reOrderData(payloadNameData)

        state.idsCPI = state.initialIds = nameData?.map((v) => v.cpivnTypeId)
        state.levels = nameData.map((item) => ({
          id: item.cpivnTypeId,
          parentId: item.parentCPIVNTypeId,
          level: item.cpivnTypeLevel,
        }))

        const payloadData = action.payload?.data || []
        const data = payloadData.length
          ? fullDataWithVal(payloadData, payloadNameData)
          : [...state.data]

        state.payloadData = payloadData
        state.data = data
        state.dataById = keyBy(data, 'cpivnTypeId')

        const dataWithColorId = addColorIdToData(data)
        state.dataWithColorId = dataWithColorId

        state.listCPISector =
          state.isAfterChangeAndLoading || state.isChangeLocale
            ? keepCurrentListByIndex(state.currentAddedItems, dataWithColorId)
            : !state.currentMinMonth
            ? DEFAULT_LIST_CPI(dataWithColorId)
            : [...state.listCPISector]

        state.isAfterChangeAndLoading = false
        state.isChangeLocale = false

        const getGroupColumns = data.length
          ? data?.map((item) => Object.keys(item))[0]
          : []

        const filterGroupColumns = reOrderByMonthAndYear(
          getGroupColumns?.filter((item) => item.includes('-')),
        )

        const groupColumns = []
        filterGroupColumns?.forEach((item) => {
          groupColumns.push({
            key: item,
            title: item,
          })
        })

        if (state.typeCode === 'YoY') {
          state.groupColumns = [
            { key: 'cpivnTypeName', title: '' },
            ...groupColumns,
            {
              key: 'growthYTD',
              title: 'economy.cpiAndInflation.cpiAndInflationTable.GROWTH_YTD',
            },
          ]
        } else {
          state.groupColumns = [
            { key: 'cpivnTypeName', title: '' },
            ...groupColumns,
          ]
        }

        state.currentMinMonth = payloadData.length
          ? payloadData?.map((item) => item.month)[0]
          : ''
        state.currentMinYear = payloadData.length
          ? payloadData?.map((item) => item.year)[0]
          : ''

        state.maxMonth = state.isNotGetMaxMonth
          ? state.maxMonth
          : Number(
              filterGroupColumns[filterGroupColumns.length - 1]?.slice(0, 2),
            )

        state.maxYear = state.isNotGetMaxMonth
          ? state.maxYear
          : Number(
              filterGroupColumns[filterGroupColumns.length - 1]?.slice(3, 7),
            )
      },
    )

    builder.addCase(
      cpiAndInflationTableContentFirstRender.rejected,
      (state) => {
        state.isContentLoading = false
      },
    )

    builder.addCase(cpiAndInflationTableContent.pending, (state) => {
      state.isContentLoading = true
    })
    builder.addCase(cpiAndInflationTableContent.fulfilled, (state, action) => {
      state.isContentLoading = false
      state.isChangeTypeCode = false

      const payloadData = action.payload || []
      const data = payloadData.length
        ? fullDataWithVal(payloadData, state.data)
        : [...state.data]

      state.countPeriodNum += NUM_OF_PERIOD
      state.payloadData = payloadData
      state.data = data
      state.dataById = keyBy(data, 'cpivnTypeId')
      const dataWithColorId = addColorIdToData(data)
      state.dataWithColorId = dataWithColorId

      state.listCPISector = [...state.listCPISector]

      state.isAfterChangeAndLoading = false
      state.isChangeLocale = false

      const getGroupColumns = data.length
        ? data?.map((item) => Object.keys(item))[0]
        : []

      const filterGroupColumns = reOrderByMonthAndYear(
        getGroupColumns?.filter((item) => item.includes('-')),
      )

      const groupColumns = []
      filterGroupColumns?.forEach((item) => {
        groupColumns.push({
          key: item,
          title: item,
        })
      })

      if (state.typeCode === 'YoY') {
        state.groupColumns = [
          { key: 'cpivnTypeName', title: '' },
          ...groupColumns,
          {
            key: 'growthYTD',
            title: 'economy.cpiAndInflation.cpiAndInflationTable.GROWTH_YTD',
          },
        ]
      } else {
        state.groupColumns = [
          { key: 'cpivnTypeName', title: '' },
          ...groupColumns,
        ]
      }

      state.currentMinMonth = payloadData.length
        ? payloadData?.map((item) => item.month)[0]
        : ''
      state.currentMinYear = payloadData.length
        ? payloadData?.map((item) => item.year)[0]
        : ''

      state.maxMonth = state.isNotGetMaxMonth
        ? state.maxMonth
        : Number(filterGroupColumns[filterGroupColumns.length - 1]?.slice(0, 2))

      state.maxYear = state.isNotGetMaxMonth
        ? state.maxYear
        : Number(filterGroupColumns[filterGroupColumns.length - 1]?.slice(3, 7))
    })

    builder.addCase(cpiAndInflationTableContent.rejected, (state) => {
      state.isContentLoading = false
    })
  },
})

export const selectLoadingTableCPI = (state) => state[slice.name].isLoading
export const selectLoadingTableContentCPI = (state) =>
  state[slice.name].isContentLoading
export const selectCPIIds = (state) => state[slice.name].idsCPI
export const selectCPIDataTable = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataById[id], attr)
}
export const selectListCPISector = (state) => state[slice.name].listCPISector
export const selectTypeCode = (state) => state[slice.name].typeCode
export const selectDataTable = (state) => state[slice.name].data
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectMaxMonth = (state) => state[slice.name].maxMonth
export const selectMaxYear = (state) => state[slice.name].maxYear
export const selectPayloadData = (state) => state[slice.name].payloadData
export const selectDataWithColorId = (state) =>
  state[slice.name].dataWithColorId
export const selectPeriodNum = (state) => state[slice.name].periodNum
export const selectCountPeriodNum = (state) => state[slice.name].countPeriodNum
export const selectMinCurrentMonth = (state) =>
  state[slice.name].currentMinMonth
export const selectMinCurrentYear = (state) => state[slice.name].currentMinYear
export const selectIsChangeTypeCode = (state) =>
  state[slice.name].isChangeTypeCode

export const {
  addSectorCPIToList,
  removeSectorCPIFromList,
  changeTypeCode,
  getPreviousData,
  changePeriodNum,
  keepCurrentItems,
  changeLocale,
  sort,
  changeIsNotGetMaxMonth,
} = slice.actions

register(slice.name, slice.reducer)

const reOrderData = (data) => {
  //get items which are children level 3 data
  const itemAtLowestLevel = data.filter((item) => item.cpivnTypeLevel === 4)
  //get items which are parents of level 3 data
  const parentArr = getItemParent(data)

  //create array contain items and their corresponding child
  const getItemParentAndChild = () => {
    let childArr = []
    let parentAndChildArr = []
    let parentGroup = []
    let parentAndChildGroupArr = []

    //create array contain childrens that have the same parent (1)
    for (let i = 0; i < parentArr.length; i++) {
      for (let j = 0; j < itemAtLowestLevel.length; j++) {
        if (
          parentArr[i].cpivnTypeId === itemAtLowestLevel[j].parentCPIVNTypeId
        ) {
          childArr.push(itemAtLowestLevel[j])
        }
      }
    }
    //create array contains all array in (1)
    let childArrGrouped = Object.values(
      childArr.reduce((r, o) => {
        ;(r[o.parentCPIVNTypeId] = r[o.parentCPIVNTypeId] || []).push(o)
        return r
      }, {}),
    )
    //re-order: childrens are ordered after their parent
    for (let k = 0; k < parentArr.length; k++) {
      for (let m = 0; m < childArrGrouped.length; m++) {
        if (
          parentArr[k].cpivnTypeId === childArrGrouped[m][0].parentCPIVNTypeId
        ) {
          parentGroup = []
          parentGroup.push(parentArr[k])
          parentAndChildGroupArr = parentGroup.concat(childArrGrouped[m])
          parentAndChildArr = parentAndChildArr.concat(parentAndChildGroupArr)
        }
      }
    }
    return parentAndChildArr
  }

  const parentAndChildArr = getItemParentAndChild()

  //get remaining items in data
  const nonChildArr = data.filter(
    (o) => !parentAndChildArr.some((i) => i.cpivnTypeId === o.cpivnTypeId),
  )

  //re-order followed by original data's order
  const finalArr = [...nonChildArr]
  const getFoodData = parentAndChildArr.slice(0, 4)
  const getMedicalData = parentAndChildArr.slice(4, 6)
  const getEducationData = parentAndChildArr.slice(6, 8)

  finalArr.splice(1, 0, ...getFoodData)
  finalArr.splice(9, 0, ...getMedicalData)
  finalArr.splice(13, 0, ...getEducationData)

  return finalArr
}

const fullDataWithVal = (dataContainVal, dataWithoutVal) => {
  const dataGrowthYTD = dataContainVal?.filter((item) => item.isAccYoY)

  //create keys by months and years in data range (ex: 01-2022) (2)
  const getDataKeyFromRange = !dataGrowthYTD.length
    ? getMonthAndYearInDataRange(dataContainVal).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )
    : [
        ...getMonthAndYearInDataRange(dataContainVal).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        ),
        'growthYTD',
      ]

  //get all values belong to months and years in data range
  const getDataValueFromRange = !dataGrowthYTD.length
    ? getMonthAndYearInDataRange(dataContainVal).map((item) => [
        ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
      ])
    : [
        ...getMonthAndYearInDataRange(dataContainVal).map((item) => [
          ...getDataByMonthAndYear(dataContainVal, item.month, item.year),
        ]),
        dataGrowthYTD.map((item) => ({
          cpivnTypeId: item.cpivnTypeId,
          value: item.value,
        })),
      ]

  //get all values corresponding to key in (2)
  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.cpivnTypeId === item.cpivnTypeId))]
        ?.value ?? EMPTY_VALUE
    )
  }

  //put values of all date keys into original data
  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []

    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return dataWithoutVal.map((item) => ({
    ...item,
    ...getKeyAndValRange(item),
  }))
}

const addColorIdToData = (data) => {
  return data.map((item) => ({
    ...item,
    colorId: data?.indexOf(item),
  }))
}

const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}
