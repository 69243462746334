import { SizeTracker } from '../../../../common/sizeTracker'
import Unit from '../../common/Unit'
import TableComponent from './TableComponent'

const Container = ({ height }) => {
  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Unit
              title={'economy.fiscal.stateBudget.UNIT_BILLION_VND'}
              styleProps={{ marginBottom: 8 }}
            />
            {typeof sizes.height === 'number' && (
              <TableComponent height={height - sizes.height} />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
