import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { MARGIN_RECHARTS } from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
} from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import UseTimeZone from './../../../../common/hooks/useTimeZone'
import { CHARTS } from './constant'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const YAXIS_ID = 'left'
const MARGIN = { ...MARGIN_RECHARTS, right: 16 }

const ChartLine = ({ data, width, height, type, keyXAxis, keyYAxis }) => {
  const timeZone = UseTimeZone()
  const locale = useSelector((state) => state.i18n.locale)

  const formatXTickValue = (val) => {
    const year = formatDateTime(val, FORMAT.YEAR, locale, timeZone)
    if (getCurrentDateTime(FORMAT.YEAR, locale, timeZone) === year)
      return formatDateTime(val, FORMAT.MONTH_YEAR_NUMBER, locale, timeZone)
    return year
  }

  const renderTooltip = (tooltipData, decimalLengths) => {
    const decimalLength = decimalLengths[YAXIS_ID] || 0
    const contentTooltip = [
      {
        label: I18n.t(
          `corporate.peerValuation.overview.chart.${CHARTS[type]}.title`,
        ),
        value: tooltipData[keyYAxis],
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            <DateTimeSpan
              date={tooltipData[keyXAxis]}
              formatDate={FORMAT.DATE}
            />
          </Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(el.value, decimalLength || 2)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      timeFrame="1M"
      tickFormatter={formatXTickValue}
      margin={MARGIN}
      yAxis={[
        {
          id: YAXIS_ID,
          keys: [keyYAxis],
          isLineChart: true,
          orientation: 'left',
          tickNum: SETTINGS.yTickNum,
        },
      ]}
      renderCustomTooltip={renderTooltip}
    >
      <Line
        yAxisId={YAXIS_ID}
        dataKey={keyYAxis}
        type="linear"
        stroke="#facc5c"
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    </ChartContainer>
  )
}

export default ChartLine
