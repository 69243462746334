import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import AdditionalInfoPrice from '../components/AdditionalInfoPrice'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import {
  selectDisplayInfo,
  selectIndexSectorTickerInfo,
  selectPriceData,
  subscribeIndexSector,
  subscribeTicker,
} from './store/slice'
import { getLatestPrice } from './store/thunk'

export const PriceData = () => {
  const dispatch = useDispatch()
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)
  const priceData = useSelector(selectPriceData)
  const displayInfo = useSelector(selectDisplayInfo)

  useEffect(() => {
    if (
      indexSectorTickerInfo.groupId ||
      indexSectorTickerInfo.organizationId ||
      indexSectorTickerInfo.icbId
    )
      dispatch(
        getLatestPrice({
          Ids:
            indexSectorTickerInfo.groupId ||
            indexSectorTickerInfo.organizationId ||
            indexSectorTickerInfo.icbId,
          GroupType: indexSectorTickerInfo.groupId
            ? GROUP_TYPE.INDEX
            : indexSectorTickerInfo.organizationId
            ? GROUP_TYPE.STOCK
            : GROUP_TYPE.SECTOR,
        }),
      )
  }, [
    indexSectorTickerInfo.groupId ||
      indexSectorTickerInfo.icbId ||
      indexSectorTickerInfo.organizationId,
  ])

  return (
    <div className="info-box">
      {displayInfo &&
        indexSectorTickerInfo &&
        indexSectorTickerInfo.organizationId && (
          <Realtime
            channel={
              ChannelConfig.BidAskChannel + indexSectorTickerInfo.organizationId
            }
            action={subscribeTicker}
          >
            <SummaryInfoPrice data={{ ...priceData, isTicker: true }} />
            <AdditionalInfoPrice data={{ ...priceData, isTicker: true }} />
          </Realtime>
        )}

      {displayInfo && indexSectorTickerInfo && indexSectorTickerInfo.groupId && (
        <Realtime
          channel={ChannelConfig.IndexChannel + indexSectorTickerInfo.groupId}
          action={subscribeIndexSector}
        >
          <SummaryInfoPrice data={{ ...priceData, isTicker: false }} />
          <AdditionalInfoPrice data={{ ...priceData, isTicker: false }} />
        </Realtime>
      )}

      {displayInfo &&
        indexSectorTickerInfo &&
        indexSectorTickerInfo.icbId &&
        !indexSectorTickerInfo.organizationId && (
          <Realtime
            channel={ChannelConfig.SectorChannel + indexSectorTickerInfo.icbId}
            action={subscribeIndexSector}
          >
            <SummaryInfoPrice data={{ ...priceData, isTicker: false }} />
            <AdditionalInfoPrice data={{ ...priceData, isTicker: false }} />
          </Realtime>
        )}

      {(!indexSectorTickerInfo || !displayInfo) && (
        <>
          <SummaryInfoPrice data={{ isTicker: false }} />
          <AdditionalInfoPrice data={{ isTicker: false }} />
        </>
      )}
    </div>
  )
}
