import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import useWrapper from '../../hooks/useWrapper'
import { selectLocale } from '../../masterData/store/slice'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import { PriceData } from './PriceData'
import DropdownItem from './dropdownItem'
import {
  changeLoadingSearch,
  changeWarrantInfo,
  selectListWarrant,
  selectLoadingSearch,
  selectWarrantInfo,
} from './store/slice'
import { getSearchWarrant } from './store/thunk'

const WarrantTopInfo = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate()
  const { width: wrapperWidth } = useWrapper()

  const locale = useSelector(selectLocale)
  const warrantInfo = useSelector(selectWarrantInfo)
  const listWarrant = useSelector(selectListWarrant)
  const isLoadingSearch = useSelector(selectLoadingSearch)

  useHeaderSearch(TYPE_HEADER.WARRANT)

  const [keySearch, setKeySearch] = useState('')

  const handleSelect = (data) => {
    if (data) {
      navigate(location.pathname, data)
    }
  }

  const handleFocusInputSearch = () => {
    setKeySearch('')
  }

  const handleChangeLoading = () => {
    dispatch(changeLoadingSearch(true))
  }

  const handleSearch = useMemo(() => {
    const data = [...listWarrant]
      .filter(
        (i) =>
          i.ticker?.toLowerCase().includes(keySearch) ||
          i.coveredWarrantName?.toLowerCase().includes(keySearch),
      )
      .sort((a, b) => a.coveredWarrantName?.localeCompare(b.coveredWarrantName))

    dispatch(changeLoadingSearch(false))
    return data
  }, [keySearch, listWarrant])

  const options = !isLoadingSearch
    ? handleSearch.map((index) => ({
        id: index.coveredWarrantId,
        value: { ...index },
      }))
    : []

  useEffect(() => {
    if (location.search) {
      const result = listWarrant.find(
        (item) =>
          '?coveredWarrantId=' + item.coveredWarrantId === location.search,
      )
      if (result) {
        dispatch(changeWarrantInfo(result))
      }
    }
  }, [location.search, listWarrant])

  useEffect(() => {
    const result = listWarrant.find(
      (item) => item.coveredWarrantId === warrantInfo.coveredWarrantId,
    )
    if (result?.coveredWarrantId !== warrantInfo.coveredWarrantId) {
      handleSelect(result)
    }
    if (!result && listWarrant.length) {
      handleSelect(listWarrant[0])
    }
  }, [listWarrant])

  useEffect(() => {
    dispatch(getSearchWarrant())
  }, [locale])

  return (
    <div className="top-info" style={{ width: wrapperWidth || '100%' }}>
      {props.title && (
        <>
          <Title title={props.title} />
          {props.customTopInfo}
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={warrantInfo.ticker}
        description={warrantInfo.coveredWarrantName}
        onFocus={handleFocusInputSearch}
        onTextChange={(e) => setKeySearch(e?.toLowerCase())}
        options={options}
        loading={isLoadingSearch}
        onSelect={props.handleSelect || handleSelect}
        renderOption={(val, isCurrentItem) => (
          <DropdownItem value={val} isCurrentItem={isCurrentItem} />
        )}
        onChangeLoading={handleChangeLoading}
      />
      <PriceData />
    </div>
  )
}

WarrantTopInfo.propTypes = {
  title: PropTypes.string,
}

export default WarrantTopInfo
