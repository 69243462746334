import PropTypes from 'prop-types'
import { languageObjs } from '../../../../configs/Language'
import { range } from '../../../utils/Common'
import SelectMonthYear from '../SelectMonthYear'
import {
  enMonths,
  enMonthsLowerCase,
  enMonthsThreeCharacter,
  viMonths,
  viMonthsLowerCase,
} from '../helper'
import style from '../index.module.css'

const HeaderCalendarWithoutHook = ({
  startYear,
  endYear,
  zIndex,
  isThreeCharacter,
  isLowerCase,
  lang,
}) => {
  const months = () => {
    const isEn = lang === languageObjs.en

    if (isThreeCharacter) return isEn ? enMonthsThreeCharacter : viMonths
    if (isLowerCase) return isEn ? enMonthsLowerCase : viMonthsLowerCase
    else return isEn ? enMonths : viMonths
  }

  const years = range(startYear, endYear)

  return ({ date, changeYear, changeMonth }) => {
    return (
      <div className={style.headerRangeContainer}>
        <div className={style.selectContainer}>
          <div className={style.inputMonth}>
            <SelectMonthYear
              currentValue={months()[date.getMonth()]}
              selectRef={
                <div className={`${style.inputSelect} `}>
                  {months()[date.getMonth()]}
                  <i className={`icon-arrow-down ${style.iconArrow}`} />
                </div>
              }
              handleChange={(value) => changeMonth(months().indexOf(value))}
              options={months()}
              marginTop={12}
              zIndex={zIndex}
            />
          </div>
          <SelectMonthYear
            currentValue={date.getFullYear()}
            selectRef={
              <div className={style.inputSelect}>
                {date.getFullYear()}
                <i className={`icon-arrow-down ${style.iconArrow}`} />
              </div>
            }
            handleChange={(value) => changeYear(value)}
            options={years}
            marginTop={12}
            zIndex={zIndex}
          />
        </div>
      </div>
    )
  }
}

HeaderCalendarWithoutHook.propTypes = {
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  zIndex: PropTypes.number,
  isThreeCharacter: PropTypes.bool,
  isLowerCase: PropTypes.bool,
}

export default HeaderCalendarWithoutHook
