import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { SelectType } from '../../../common/SelectType'
import { chartYearOptions } from '../../../constants'
import {
  formatDataPieChart,
  getQuarterYearParams,
  getTranslateSourceNote,
} from '../../../helps'
import { ChartComponent } from './ChartComponent'
import { chartFormat } from './constants'
import {
  changeRetryFlag,
  changeYearType,
  selectChartData,
  selectChartLoading,
  selectRetryFlag,
  selectYearType,
} from './store/slice'
import { getLoanStructureChartData } from './store/thunk'

export const LoanStructureChart = ({ width, height, dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)
  const retryFlag = useSelector(selectRetryFlag)
  const yearType = useSelector(selectYearType)

  // Get data
  const getData = useMemo(() => {
    const dataExist = data.find((item) => item.realYear === yearType)

    if (dataExist) {
      return formatDataPieChart(dataExist, chartFormat)
    }
  }, [data])

  const getSourceNote = useMemo(() => {
    return getTranslateSourceNote([getData], true)
  }, [locale, getData])

  // Actions
  const onChangeYear = (value) => {
    dispatch(changeYearType(value))
  }

  // Use effect
  useEffect(() => {
    if (sectorInfo && sectorInfo.icbId && retryFlag) {
      const paramsArr = getQuarterYearParams().map(({ year }) => ({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        Year: year,
      }))
      dispatch(getLoanStructureChartData(paramsArr))
    }
  }, [sectorInfo, retryFlag])

  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId || retryFlag) return
    dispatch(
      getLoanStructureChartData({
        Year: yearType,
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
      }),
    )
  }, [yearType, sectorInfo])

  useEffect(() => {
    dispatch(changeRetryFlag(true))
  }, [dataType])

  return (
    <div id="assetLoanStructureChart">
      <SizeTracker>
        {(size) => (
          <>
            <div
              className="d-flex"
              style={{ justifyContent: 'end' }}
              data-html2canvas-ignore="true"
            >
              <SelectType
                options={chartYearOptions}
                activeTab={yearType}
                onChangeTab={onChangeYear}
                style={{ width: 86 }}
              />
            </div>
            {size.height && (
              <ChartComponent
                data={getData}
                width={width}
                height={height - size.height}
                loading={loading}
              />
            )}
            <Span
              className="canvas-chart-title t-left"
              style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}
            >
              {getSourceNote}
            </Span>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
