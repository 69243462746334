import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { resetStore as resetStorePeerHeader } from './peersComparison/header/store/slice'
import PanelPeersComparison from './peersComparison/PanelPeersComparison'
import { resetStore as resetStorePeerComparison } from './peersComparison/store/slice'
import PanelValuation from './valuation/PanelValuation'
import { resetStore as resetStoreValuation } from './valuation/store/slice'

const MIN_WIDTH = 1000

export const MAP_KEY = {
  VALUATION: 'VALUATION',
  PEERS_COMPARISON: 'PEERS_COMPARISON',
}

const MAP_SIZE = {
  [`${MAP_KEY.PEERS_COMPARISON}`]: {
    width: '100%',
    height: `calc(60% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    horizontalResize: [
      {
        key: MAP_KEY.VALUATION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.VALUATION}`]: {
    width: '100%',
    height: `calc(40% - ${COMPONENT.MARGIN}px)`,
    top: `calc(60% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetStoreValuation())
      dispatch(resetStorePeerComparison())
      dispatch(resetStorePeerHeader())
    }
  }, [])

  return (
    <>
      <PanelValuation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUATION}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.VALUATION].width - PANEL_PADDING}
        height={sizes[MAP_KEY.VALUATION].height - HEADER_PANEL_HEIGHT}
      />
      <PanelPeersComparison
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PEERS_COMPARISON}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
