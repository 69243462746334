import PropTypes from 'prop-types'

export const Rectangle = ({ color, dotWidth, ...props }) => {
  const { cx, cy, value } = props
  const dotWidthSize = dotWidth * 2 * 10
  if (typeof value === 'number' && !isNaN(value))
    return (
      <svg
        width={dotWidthSize}
        height={dotWidthSize}
        x={cx - dotWidth}
        y={cy - dotWidth}
        fill={color}
        viewBox="0 0 1024 1024"
      >
        <rect
          width="100"
          height="50"
          x={0}
          y={100 / 4}
          stroke="#000000"
          strokeWidth={0}
        />
      </svg>
    )
  return null
}

Rectangle.propTypes = {
  color: PropTypes.string,
  dotWidth: PropTypes.number,
}

Rectangle.defaultProps = {
  color: '#000000',
  dotWidth: 6.5,
}
