import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateCharts } from '../chart/store/thunk'
import { ReactComponent as IconLinkChart } from '../icon/icon-link-chart.svg'
import {
  addLinkChart,
  removeLinkChart,
  selectMinimizeButtonLinkChart,
} from '../store/slice'
import style from './index.module.css'

export const LinkButton = ({ chartData }) => {
  const dispatch = useDispatch()
  const [isLinked, setIsLinked] = useState(chartData.linked)
  const minimizeButton = useSelector(selectMinimizeButtonLinkChart)
  const [first, setFirst] = useState(true)

  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    if (
      chartData.detail.treeSecuritiesIndicator.companies &&
      Object.keys(chartData.detail.treeSecuritiesIndicator.companies).length ===
        1
    ) {
      setIsShow(true)
    }
  }, [chartData.detail])

  const linkChart = (event) => {
    event.preventDefault()
    setIsLinked((prevState) => !prevState)
  }

  useEffect(() => {
    if (isLinked || first) {
      dispatch(addLinkChart(chartData.id))
      setFirst(false)
    } else {
      dispatch(removeLinkChart(chartData.id))
      dispatch(
        updateCharts({
          ...chartData,
          detail: JSON.stringify(chartData.detail),
          linked: false,
        }),
      )
    }
  }, [isLinked])

  if (!isShow) return <></>

  return (
    <div
      className={minimizeButton ? style.linkSmallButton : style.linkButton}
      data-html2canvas-ignore
    >
      <button
        onClick={linkChart}
        className={[style.linkIcon, isLinked ? style.linkedIcon : ''].join(' ')}
      >
        <IconLinkChart />
      </button>
    </div>
  )
}
