import { useState } from 'react'
import { useSelector } from 'react-redux'
import { PricePerformanceChart } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { VALUE_ORDER } from '../../constants'
import style from '../style.module.css'
import { selectOrderValue } from './store/slice'

const PanelPricePerformance = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const orderValue = useSelector(selectOrderValue)
  const nameScreen = UseI18n('sector.sectorStatistics.overview.SECTOR_SUMMARY')
  const chartName = UseI18n(
    'sector.sectorStatistics.overview.PRICE_PERFORMANCE',
  )
  const tabName =
    orderValue === VALUE_ORDER.ABSOLUTE
      ? UseI18n('sector.sectorStatistics.overview.ABSOLUTE')
      : UseI18n('sector.sectorStatistics.overview.RELATIVE')

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      headerClass={style.panelHeader}
      windowClass={style.windowClass}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('sector.sectorStatistics.overview.PRICE_PERFORMANCE')}
      downloadJpgParams={{
        domId: 'sectorStatisticsPricePerformanceChartId',
        nameScreen,
        chartName,
        tabName,
      }}
      handleCustom={setIsFullScreen}
    >
      <div className="h-100">
        <PricePerformanceChart
          width={width}
          height={height}
          isFullScreen={isFullScreen}
        />
      </div>
    </Panel>
  )
}

export default PanelPricePerformance
