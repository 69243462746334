import moment from 'moment'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Summary } from '.'
import { Panel } from '../../../../common/panel'
import { PANEL_PADDING } from '../../../../constants/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { PRICE_DATA_TIME_SELECT } from '../constants'
import UseTimeZone from './../../../../common/hooks/useTimeZone'
import { TABLE_DOWNLOAD, TABLE_TYPE, TAB_TYPE } from './constants'

export const PanelSummary = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()
  // Use state
  const [filter, setFilter] = useState({})
  const [tab, setTab] = useState(TAB_TYPE.OVERVIEW)
  const [tableType, setTableType] = useState(TABLE_TYPE.OVERVIEW)

  // Actions
  const downloadCsv = () => {
    return TABLE_DOWNLOAD[tab][tableType]({
      ...filter,
      StartDate: filter.StartDate
        ? getISOStartOrEndOfDay(filter.StartDate, timeZone, true)
        : (filter.TimeFilter === PRICE_DATA_TIME_SELECT.CUSTOM ||
            filter.TimeFrequency === PRICE_DATA_TIME_SELECT.CUSTOM) &&
          tableType === TABLE_TYPE.OVERVIEW
        ? getISOStartOrEndOfDay(
            moment().startOf('year').toDate(),
            timeZone,
            true,
          )
        : undefined,
      EndDate: filter.EndDate
        ? getISOStartOrEndOfDay(filter.EndDate, timeZone, false)
        : undefined,
    })
  }

  return (
    <Panel
      title="market.marketInDepth.indexStatistics.PRICE_DATA"
      panelRefs={panelRefs}
      panelKey={mapKey.SUMMARY}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <Summary
        onFilterChange={setFilter}
        onTabChange={setTab}
        onTableTypeChange={setTableType}
        width={sizes[mapKey.SUMMARY].width - PANEL_PADDING}
      />
    </Panel>
  )
}

PanelSummary.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
