import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { getTimeFrameTitleByValue } from '../helpers'
import ChartContainer from './ChartContainer'
import { resetStore, selectTimeFrame } from './store/slice'

export const CHART_DOM_ID = 'PriceChartID'

const PanelPriceChart = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  width,
  height,
}) => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)
  const timeFrame = useSelector(selectTimeFrame)
  const nameScreen = UseI18n('sector.sectorConstituents.valuation.NAME')
  const chartName = UseI18n('sector.sectorConstituents.valuation.PRICE_CHART')
  const tabName = UseI18n(
    `sector.sectorConstituents.valuation.${getTimeFrameTitleByValue(
      timeFrame,
    )}`,
  )

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <Panel
      title="sector.sectorConstituents.valuation.PRICE_CHART"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: CHART_DOM_ID,
        nameScreen,
        chartName,
        tickerName: sectorInfo?.icbName || '',
        tabName,
      }}
    >
      <ChartContainer width={width} height={height} />
    </Panel>
  )
}

export default PanelPriceChart
