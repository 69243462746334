import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../../common/DateTimeSpan'
import { Span } from '../../../../../common/html/Span'
import { formatVal, valDivMillion } from '../../../../../utils/Value'
import { CHART_TYPE } from '../constants'
import { selectDisplayChartType } from '../store/slice'
import style from './index.module.css'

const ChartTooltip = ({ data }) => {
  const chartType = useSelector(selectDisplayChartType)

  return (
    <div>
      <Span
        style={{ fontSize: 10, fontWeight: 400 }}
        className={style.tooltipTitle}
      >
        <DateTimeSpan date={data.date} />
      </Span>
      <Span style={{ fontSize: 11, fontWeight: 400 }}>
        {data.icbId === 'others' ? (
          <Translate value="market.marketInDepth.localInstitution.OTHERS" />
        ) : (
          data.icbName
        )}
        :{' '}
        <span
          style={{
            color: chartType === CHART_TYPE.BUY ? '#59c761' : '#ff4752',
            fontWeight: 500,
          }}
        >
          {formatVal(valDivMillion(data.value), 1)}{' '}
          <Translate value="market.marketInDepth.localInstitution.MILLION" />
        </span>{' '}
        ~ {formatVal(data.percent)}%
      </Span>
    </div>
  )
}

export default ChartTooltip
