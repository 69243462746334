import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import Content from './Content'
import { ALL_PUBLIC_PRIVATE_TABS } from './constants'
import style from './index.module.css'
import {
  changeActiveMethodType,
  // changeActiveTimeType,
  selectActiveMethodType,
} from './store/slice'

export const SecondaryMarketStatistics = () => {
  const dispatch = useDispatch()

  const isActive = useSelector(selectActiveMethodType)
  // const radioValue = useSelector(selectActiveTimeType)

  const handleChangeMethodType = (item) => {
    dispatch(changeActiveMethodType(item.value))
  }

  // const handleChangeRadio = (value) => {
  //   dispatch(changeActiveTimeType(value))
  // }

  return (
    <>
      <div className="d-flex ali-center mb-8">
        <div className={`${style.menuContainer}`}>
          <DispatchActionTab
            data={ALL_PUBLIC_PRIVATE_TABS}
            activeTab={isActive}
            onChangeTab={handleChangeMethodType}
            containerStyle={{ width: '100%' }}
            itemStyle={{
              width: 'calc(100% / 3)',
              fontWeight: 600,
            }}
          />
        </div>
        {/* <VerticalLine ml={16} mr={16} h={20} />
        <div className="d-flex ali-center">
          {MONTH_QUARTER_YEAR_RADIO.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
                onClick={() => handleChangeRadio(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === radioValue}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div> */}
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 28px)`,
          width: `calc(100% - 6px)`,
        }}
      >
        <Content />
      </div>
    </>
  )
}
