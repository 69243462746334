import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown, { positionDropdown } from '../../../../../../common/dropdown'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import { getTooltipLoanCompany } from '../../store/thunk'
import TableLoanCompany from './TableLoanCompany'

const MAX_WIDTH = 700
const MAX_HEIGHT = 300

const TooltipInfoValue = ({ rowIdCol, parentRef, time }) => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)

  useEffect(() => {
    if (rowIdCol && basicInfo.organizationId) {
      dispatch(
        getTooltipLoanCompany({
          LoanOrganizationId: basicInfo.organizationId,
          LendingOrganizationId: rowIdCol,
          Quarter: time?.quarter ?? 5,
          Year: time?.year,
        }),
      )
    }
  }, [rowIdCol, basicInfo.organizationId])

  const { left, right, top, bottom } =
    parentRef?.current?.getBoundingClientRect()

  const position = useMemo(() => {
    if (right + MAX_WIDTH > window.innerWidth) {
      return window.innerHeight - bottom > MAX_HEIGHT &&
        window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.LEFT_CENTER
        : window.innerHeight - bottom < MAX_HEIGHT
        ? positionDropdown.LEFT_BOTTOM
        : positionDropdown.LEFT_TOP
    } else {
      return window.innerHeight - bottom > MAX_HEIGHT &&
        window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.RIGHT_CENTER
        : window.innerHeight - bottom < MAX_HEIGHT
        ? positionDropdown.RIGHT_BOTTOM
        : positionDropdown.RIGHT_TOP
    }
  }, [left, right, top, bottom])

  return (
    <Dropdown
      position={position}
      appendStyle={{
        background: '#33373f',
        borderRadius: 4,
        width: MAX_WIDTH,
        height: 300,
        zIndex: 99,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.7)',
      }}
      isShow={true}
      parentRef={parentRef}
    >
      <TableLoanCompany />
    </Dropdown>
  )
}

export default TooltipInfoValue
