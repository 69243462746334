import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import ThePagination from '../../../../../common/pagination/ThePagination'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { PRICE_DATA_TIME_SELECT } from '../../constants'
import { TABLE_TYPE } from '../constants'
import { CalendarSearch } from './CalendarSearch'
import { PriceRate } from './PriceRate'
import { TableType } from './TableType'
import { Transaction } from './Transaction'

export const SummaryFilter = ({
  activeTab,
  page,
  totalPage,
  dateFrom,
  dateTo,
  transactionType,
  isTransactionType,
  priceRateText,
  onChangePage,
  onChangeTab,
  onChangeType,
  onChangeTransactionType,
  onDateFromChange,
  onDateToChange,
  onOpenDateFrom,
  onOpenDateTo,
  limitDatepicker,
}) => {
  const [tableType, setTableType] = useState(TABLE_TYPE.OVERVIEW)

  const isDisabledCalendar = useMemo(() => {
    return (
      tableType === TABLE_TYPE.DETAIL &&
      activeTab !== PRICE_DATA_TIME_SELECT.CUSTOM
    )
  }, [activeTab, tableType])

  const onChangeTableType = (value) => {
    onChangeType(value)
    setTableType(value)
  }

  return (
    <div>
      <div className="mb-8">
        <TableType onChange={onChangeTableType} />
      </div>
      <div className="d-flex align-center mb-8">
        <DispatchActionTab
          data={Object.keys(PRICE_DATA_TIME_SELECT).map((key) => {
            return {
              title: `market.marketInDepth.indexStatistics.priceData.${key}${
                tableType === TABLE_TYPE.DETAIL ? `_DETAIL` : ''
              }`,
              value: PRICE_DATA_TIME_SELECT[key],
            }
          })}
          activeTab={activeTab}
          itemStyle={{ fontWeight: 600, padding: '0 16px' }}
          containerStyle={{ marginRight: 20 }}
          onChangeTab={onChangeTab}
        />
        <CalendarSearch
          dateFrom={dateFrom}
          dateTo={dateTo}
          disabled={isDisabledCalendar}
          limitDatepicker={limitDatepicker}
          onDateFromChange={onDateFromChange}
          onDateToChange={onDateToChange}
          onOpenDateFrom={onOpenDateFrom}
          onOpenDateTo={onOpenDateTo}
        />
      </div>
      <div className="table-info mb-0">
        <div className="table-info-left mb-8 d-flex align-center">
          {isTransactionType && (
            <Transaction
              value={transactionType}
              onChange={onChangeTransactionType}
            />
          )}
          <PriceRate priceRateText={priceRateText} />
        </div>
        <div className="table-info-right">
          <ThePagination
            currentPage={page}
            totalPages={totalPage}
            changeCurrentPage={onChangePage}
          />
        </div>
      </div>
    </div>
  )
}

SummaryFilter.propTypes = {
  width: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  totalPage: PropTypes.number,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  transactionType: PropTypes.string,
  isTransactionType: PropTypes.bool,
  priceRateText: PropTypes.string,
  onChangePage: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  onChangeTransactionType: PropTypes.func,
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  onOpenDateFrom: PropTypes.func.isRequired,
  onOpenDateTo: PropTypes.func.isRequired,
  limitDatepicker: PropTypes.object,
}

SummaryFilter.defaultProps = {
  onChangeTransactionType: () => {},
}
