import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_KEY_METRICS_KEYS,
} from '../constants'

export const keyMetricsSchema = ({ locale }) => {
  return TABLE_KEY_METRICS_KEYS.map((item) => {
    const result = {
      ...item,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 600,
        minWidth: 130,
        maxWidth: 180,
        whiteSpace: 'normal',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_YEAR_IN_OPERATION) {
      return {
        ...result,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
          minWidth: 140,
          maxWidth: 180,
          whiteSpace: 'normal',
        },
        renderTh: () => (
          <div style={{ textAlign: 'right', whiteSpace: 'normal' }}>
            <Span className="mr-4">
              <Translate value={result.title} />
            </Span>
            <Span className="t-italic" style={{ fontWeight: 400 }}>
              (<Translate value="common.calendar.YEAR" />)
            </Span>
          </div>
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
      return {
        ...result,
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    if (
      [TABLE_COL_KEYS.COL_TOTAL_NAV, TABLE_COL_KEYS.COL_FUND_FLOW_1M].includes(
        item.colId,
      )
    ) {
      return {
        ...result,
        isI18nThTooltip: true,
        thTooltipCustom:
          item.colId === TABLE_COL_KEYS.COL_FUND_FLOW_1M &&
          'fund.fundCenter.FUND_FLOW_NOTE',
        renderTh: () => (
          <div style={{ textAlign: 'right' }} className="flex-column">
            <Span style={{ whiteSpace: 'nowrap' }}>
              <Translate value={result.title} />
            </Span>
            <Span className="t-italic" style={{ fontWeight: 400 }}>
              (<Translate value="common.UNIT.BILLION_VND" />)
            </Span>
          </div>
        ),
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => formatVal(val / TABLE_COL_FORMATS.COL_TOTAL_NAV)}
          />
        ),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_CHANGE_NAV_1M,
        TABLE_COL_KEYS.COL_CHANGE_NAV_YTD,
        TABLE_COL_KEYS.COL_STD_CHANGE_1Y,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    return result
  })
}
