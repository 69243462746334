import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelChartBottom from './panelChartBottom/PanelChartBottom'
import PanelChartTop from './panelChartTop/PanelChartTop'
import PanelTable from './panelTable/PanelTable'

const MAP_KEY = {
  STRUCTURE_TABLE: 'STRUCTURE_TABLE',
  CHART_TOP: 'CHART_TOP',
  CHART_BOTTOM: 'CHART_BOTTOM',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.STRUCTURE_TABLE]: {
    minWidth: 1080 + 2 * COMPONENT.MARGIN,
    minHeight: 600,
  },
  [MAP_KEY.CHART_TOP]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.CHART_BOTTOM]: {
    minWidth: 350,
    minHeight: 300,
  },
}

const minWidthResponsive = 1680

const MAP_SIZE = {
  [`${MAP_KEY.STRUCTURE_TABLE}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.STRUCTURE_TABLE],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.CHART_TOP,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.CHART_BOTTOM,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHART_TOP}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.CHART_TOP],
    minTop: 0,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.STRUCTURE_TABLE].minWidth,
    horizontalResize: [
      {
        key: MAP_KEY.CHART_BOTTOM,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CHART_BOTTOM}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.CHART_BOTTOM],
    minTop: MIN_SIZE_SETTING[MAP_KEY.CHART_BOTTOM].minHeight + COMPONENT.MARGIN,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.STRUCTURE_TABLE].minWidth,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const STRUCTURE_TABLE_HEIGHT_CONFIG = 730

const MIN_SIZE_SETTING_SCREEN_SMALL = {
  [MAP_KEY.STRUCTURE_TABLE]: {
    minWidth: 1080 + 2 * COMPONENT.MARGIN,
    minHeight: STRUCTURE_TABLE_HEIGHT_CONFIG,
  },
  [MAP_KEY.CHART_TOP]: {
    minWidth: 540,
    minHeight: 420,
  },
  [MAP_KEY.CHART_BOTTOM]: {
    minWidth: 540,
    minHeight: 420,
  },
}

const MAP_SIZE_SCREEN_SMALL = {
  [`${MAP_KEY.STRUCTURE_TABLE}`]: {
    width: `100%`,
    height: STRUCTURE_TABLE_HEIGHT_CONFIG,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.STRUCTURE_TABLE],
    minTop: 0,
    minLeft: 0,
    horizontalResize: [
      {
        key: MAP_KEY.CHART_TOP,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.CHART_BOTTOM,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CHART_TOP}`]: {
    width: `calc(50%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(${STRUCTURE_TABLE_HEIGHT_CONFIG}px + ${COMPONENT.MARGIN * 2}px)`,
    left: 0,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.CHART_TOP],
    minTop: `calc(${STRUCTURE_TABLE_HEIGHT_CONFIG}px + ${
      COMPONENT.MARGIN * 2
    }px)`,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.CHART_BOTTOM,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CHART_BOTTOM}`]: {
    width: `calc(50%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(${STRUCTURE_TABLE_HEIGHT_CONFIG}px + ${
      COMPONENT.MARGIN * 2
    }px) `,
    left: `calc(50%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.CHART_BOTTOM],
    minTop: `calc(${STRUCTURE_TABLE_HEIGHT_CONFIG}px + ${
      COMPONENT.MARGIN * 2
    }px)`,
    minLeft:
      MIN_SIZE_SETTING_SCREEN_SMALL[MAP_KEY.CHART_TOP].minWidth +
      COMPONENT.MARGIN,

    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelTable
          panelRefs={panelRefs}
          panelKey={MAP_KEY.STRUCTURE_TABLE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelChartTop
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CHART_TOP}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelChartBottom
          panelRefs={panelRefs}
          panelKey={MAP_KEY.CHART_BOTTOM}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SCREEN_SMALL,
  minWidthResponsive,
)(Content)
