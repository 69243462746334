import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import PanelCostStructure from './costStructure/PanelCostStructure'
import PanelDetailMetrics from './detailMetrics/PanelDetailMetrics'
import PanelParticular from './particular/PanelParticular'
import PanelRevenueCost from './revenueCost/PanelRevenueCost'
import { resetStore } from './store/slice'

const MAP_KEY = {
  REVENUE_COST: 'REVENUE_COST',
  COST_STRUCTURE: 'COST_STRUCTURE',
  DETAIL_METRICS: 'DETAIL_METRICS',
  PARTICULAR: 'PARTICULAR',
}

const MIN_WIDTH = 400

const MAP_SIZE = {
  [`${MAP_KEY.REVENUE_COST}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minLeft: 0,
    minTop: 0,
    minWidth: MIN_WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.COST_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.COST_STRUCTURE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.DETAIL_METRICS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.COST_STRUCTURE}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minLeft: MIN_WIDTH + 2 * COMPONENT.MARGIN,
    minTop: 0,
    minWidth: MIN_WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    verticalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.DETAIL_METRICS,
        ratioWidth: -1,
        ratioRight: 1,
      },
      {
        key: MAP_KEY.PARTICULAR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DETAIL_METRICS}`]: {
    width: `calc(100% / 3 * 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minLeft: 0,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: 2 * MIN_WIDTH + 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PARTICULAR}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: `calc(100% / 3 * 2 + ${COMPONENT.MARGIN}px)`,
    minLeft: 2 * MIN_WIDTH + 4 * COMPONENT.MARGIN,
    minTop: 0,
    minWidth: MIN_WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <>
      <PanelRevenueCost
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REVENUE_COST}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.REVENUE_COST].width - PANEL_PADDING}
        height={sizes[MAP_KEY.REVENUE_COST].height - HEADER_PANEL_HEIGHT}
      />
      <PanelCostStructure
        panelRefs={panelRefs}
        panelKey={MAP_KEY.COST_STRUCTURE}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.COST_STRUCTURE].width - PANEL_PADDING}
        height={sizes[MAP_KEY.COST_STRUCTURE].height - HEADER_PANEL_HEIGHT}
      />
      <PanelDetailMetrics
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DETAIL_METRICS}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelParticular
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PARTICULAR}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PARTICULAR].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PARTICULAR].height - HEADER_PANEL_HEIGHT}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
