import { useSelector } from 'react-redux'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'
import { STACK_COLORS } from '../../../constants'
import {
  getTooltipContentData,
  getTypeNameById,
  renderListFooter,
} from '../helper'
import style from '../index.module.css'
import { getDataTypeName } from '../store/slice'

const SETTINGS = {
  yTickNum: 5,
  maxBarWidth: 25,
}

const ChartComponent = ({ data, width, height, keyXAxis, top5Indicator }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const dataTypeName = useSelector(getDataTypeName)
  const fontSize = getFontSize(11)

  const stackKey = top5Indicator.concat({
    nationalAccountVNTypeId: 'Other',
  })
  const stackKeyIds = stackKey.map((item) => item.nationalAccountVNTypeId)
  const dataWithTypeName = getTypeNameById(stackKey, dataTypeName, locale)

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(dataWithTypeName, payload).map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxWidth: 200,
              }}
            >
              <TextEllipsisStaticLine
                appendStyle={{ fontWeight: 400, fontSize }}
                lineClamp={2}
                isI18n={false}
                val={`${item.label}:`}
              />
              <Span
                style={{ marginLeft: 10, fontSize: 11 }}
                className={style.toolTipContentValue}
              >
                {formatVal(item.value)}%
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {typeof size.height === 'number' && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                yAxis={[
                  {
                    id: 'left',
                    keys: stackKeyIds,
                    orientation: 'left',
                    isStackedBar: true,
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                    tickValues: [0, 25, 50, 75, 100],
                    unitYAxis: '%',
                  },
                ]}
                hasBarChart
                renderCustomTooltip={renderTooltip}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis
                margin={{
                  ...MARGIN_RECHARTS,
                  right: 12,
                }}
              >
                {stackKeyIds.length > 0 &&
                  stackKeyIds.map((item, index) => {
                    return (
                      <Bar
                        key={index}
                        barSize={getColumnSizeInBarChart(
                          width,
                          stackKeyIds.length,
                          SETTINGS.maxBarWidth,
                        )}
                        stackId="a"
                        dataKey={item}
                        fill={STACK_COLORS[stackKeyIds.length][index]}
                        yAxisId="left"
                        isAnimationActive={false}
                      />
                    )
                  })}
              </ChartContainer>
            )}
            <Footer key={width} list={renderListFooter(dataWithTypeName)} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
