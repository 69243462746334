import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OpenMarketService from '../../../../../../core/services/economy/OpenMarketService'
import { handleExport } from '../../../../../utils/Export'
import { defineGroupColumn } from '../helper'
import { handleGroupColumns } from './slice'

export const getOpenMarketOperations = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/getOpenMarketOperations',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const res = await OpenMarketService.getOpenMarketOperations(params)
      const states = getState()
      dispatch(
        handleGroupColumns(
          defineGroupColumn(
            res.data,
            states.setting.offSetTimeZone,
            states.i18n.locale,
          ),
        ),
      )
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getOpenMarketOperationsTitle = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/getOpenMarketOperationsTitle',
  async (params, { rejectWithValue }) => {
    try {
      const res = await OpenMarketService.getOpenMarketOperationsTitle(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadOpenMarketOperations = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/downloadOpenMarketOperations',
  async (data) => {
    const res = await OpenMarketService.downloadOpenMarketOperations(data)
    handleExport(res.data, res.filename)
  },
)

export const getMaxDateOMO = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/getMaxDateOMO',
  async (params, { rejectWithValue }) => {
    try {
      const res = await OpenMarketService.getMaxDateOMO(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
