import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import { renderTimeType, renderTitle } from '../helper'
import style from '../index.module.css'
import { selectActiveTimeType } from '../store/slice'

const ChartTooltip = ({ data, duration }) => {
  const timeType = useSelector(selectActiveTimeType)
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {`${data.industryName}-${duration}`}
      </Span>
      {Object.keys(data)
        .filter((item) => item !== 'industryName')
        .map((key, index) => (
          <div
            key={index}
            className="d-flex ali-center justify-content-space-between"
          >
            <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
              {locale === 'vi' && timeType === TIME_FREQUENCY.MONTHLY
                ? `${I18n.t(
                    'bond.corporateBond.primaryMarket.MONTH',
                  )} ${renderTitle(key, timeType, locale)}  ${renderTimeType(
                    timeType,
                    key,
                  )}`
                : `${renderTitle(key, timeType, locale)} ${renderTimeType(
                    timeType,
                    key,
                  )}`}
            </Span>
            <div className="d-flex ali-center">
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {formatVal(data[key])}
              </Span>
              &nbsp;
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="bond.corporateBond.issuanceValueBySector.UNIT" />
              </Span>
            </div>
          </div>
        ))}
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.shape({
    industryName: PropTypes.string.isRequired,
  }).isRequired,
  date: PropTypes.string,
}

export default ChartTooltip
