import CreateBondProxy from '../../../proxies/bond/bondScreening/CreateBondProxy'
import { ServiceBase } from '../../ServiceBase'

export class CreateBondService extends ServiceBase {
  getBondById(params) {
    return this.getData(() => CreateBondProxy.getBondById(params))
  }
  getAllBond(params) {
    return this.getData(() => CreateBondProxy.getAllBond(params))
  }
  createBond(params) {
    return this.getData(() => CreateBondProxy.createBond(params))
  }
  updateBond(params) {
    return this.getData(() => CreateBondProxy.updateBond(params))
  }
  deleteBond(params) {
    return this.getData(() => CreateBondProxy.deleteBond(params))
  }
}

export default new CreateBondService()
