export const common = {
  FINANCIAL_ANALYSIS: 'Financial Analysis',
  OTHER: 'Others',
  ANNUAL: 'Annual',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  CHART_PERCENT: '(%)',
  CHART_BILLION_VND: '(Billion VND)',
  CHART_DAYS: '(Days)',
  BILLION: 'Billion',
  PERCENT: '%',
  DAYS: 'Days',
  ALL: 'All',
  LISTED: 'Listed',
  PRIVATE: 'Private',
  ALL_COMPANIES: 'All companies',
  UNIT_BILLION_VND: 'Unit: Billion VND',
  SCREEN_NOTE:
    'The ratios of sector are calculated by the latest updated financial statements.',
  SOURCE_BANK_NOTE: 'Source: [totalOrganization] Banks',
  SOURCE_COMPANY_NOTE: 'Source: [totalOrganization] Companies',
  TIMES: 'Times',
}

export const bank = {
  ASSET_QUALITY: 'Asset Quality',
  CAPITAL_STRUCTURE: 'Capital Structure & Liquidity',
  PROFITABILITY: 'Profitability & Efficiency',

  assetQuality: {
    LOAN_BY_SECTOR: 'Loans by Sector',
    YOY_GROWTH: 'YoY Growth',
    TRADING: 'Trading',
    AGRICULTURE_FORESTRY: 'Agriculture and Forestry',
    MANUFACTURING: 'Manufacturing',
    CONSTRUCTION: 'Construction',
    PERSONAL_PUBLIC_SERVICE: 'Personal and Public Services',
    WAREHOUSING: 'Warehousing, Transportation and Communication',
    TRAINING_EDUCATION: 'Training and Education',
    ESTATE_AGENT: 'Estate Agents and Consultants',
    HOTEL_RESTAURANT: 'Hotels and Restaurants',
    FINANCIAL_SERVICE: 'Financial Services',
    OTHER: 'Others',
    IN_DEPTH_COMPARISON: 'Loan Structure - In-depth Comparison',
    LOAN_FOR: 'Loan for',
    LOAN_STRUCTURE: 'Loan Structure',
    NPL_COVERAGE: 'NPL Coverage',
    PROVISION_NPL: 'Provision/NPL',
    PROVISION_NPL_SML: 'Provision/(NPL + SML)',
    NPL_SML: 'NPL & SML/Total Loans',
    NPL: 'NPL/Total Loans',
    SML: 'SML/Total Loans',
    VALUE_BOND_PORTFOLIOS: 'Value of Bond Portfolios',
    GOVERNMENT_BOND: 'Government Bond',
    CREDIT_INSTITUTION_BOND: 'Credit Institutions Bond',
    CORPORATE_BOND: 'Corporate Bond',
    BOND_PORTFOLIOS_GROWTH: 'Bond Portfolio Value Growth',
  },

  capitalStructure: {
    CASA_RATIO: 'CASA Ratio',
    DATA_TABLE: 'Data Table',
    YOY_GROWTH: 'YoY Growth',
    CURRENT_DEPOSIT: 'Current Deposits',
    TERM_DEPOSIT: 'Term Deposits',
    SHORT_TERM_LOAN: 'Short-term Loan',
    MEDIUM_TERM_LOAN: 'Medium-term Loan',
    LONG_TERM_LOAN: 'Long-term Loan',
    LDR: 'LDR',
    DEPOSIT_STRUCTURE: 'Deposit Structure',
    STATE_OWNED_COMPANIES: 'State-owned Companies',
    LOCAL_AND_PRIVATE_COMPANIES: 'Local and Private Companies',
    FOREIGN_COMPANIES: 'Foreign Companies',
    INDIVIDUAL: 'Individuals',
    OTHER: 'Others',
    LDR_RATIO: 'LDR Ratio',
    DEPOSIT_GROWTH: 'Deposit Growth',
    LOAN_GROWTH: 'Loan Growth',
    LOAN_STRUCTURE: 'Loan Structure',
    TIER_ONE_CAPITAL: 'Tier 1 Capital',
    TIER_TOTAL_ASSET: 'Tier 1 Capital/ Total Assets',
    CHART_LDR_RATIO_PERCENT: 'LDR Ratio',
    CHART_GROWTH_PERCENT: 'Growth',
  },

  profitability: {
    COST: 'Cost - To Income Ratio',
    CIR: 'CIR',
    OPERATING_EXPENSE_GROWTH: 'Operating Expense Growth',
    DATA_TABLE: 'Data Table',
    YOY_GROWTH: 'YoY Growth',
    NET_AND_SIMILAR: 'Net Interest & Similar Income',
    NET_AND_COMMISSION: 'Net Fee & Commission Income',
    NET_FROM_ACTIVITIES: 'Net Income from Remaining Activities',
    TOTAL_OPERATING_INCOME: 'Total Operating Income',
    COST_INCOME_RATIO: 'Cost - to - Income Ratio',
    NIM: 'NIM',
    YOEA: 'YOEA',
    COF: 'COF',
    INCOME_STRUCTURE: 'Income Structure',
    NET_INTEREST: 'Net Interest Margin',
    NON_INTEREST: 'Non - Interest Income Performance',
    NET_GAIN_FX: 'Net Gain/ Loss from FX',
    NET_GAIN_SECURITIES: 'Net Gain/Loss from Securities',
    NON_INTEREST_INCOME_GROWTH: 'Non - Interest Income Growth',
    OPERATING_INCOME: 'Operating Income & Earning Growth',
    TOTAL_OPERATING: 'Total Operating Income Growth',
    NET_INCOME_GROWTH: 'Net Income Growth',
    CHART_CIR_PERCENT: 'CIR',
    CHART_OPERATING_EXPENSE_GROWTH_PERCENT: 'Operating Expense Growth',
    CHART_NIM_PERCENT: 'NIM',
    CHART_YOEA_COF_PERCENT: 'YOEA or COF',
  },
}

export const corporate = {
  FILTER: 'Filter',
  TABLE: 'Table',
  PRICE_CHART: 'Financial Ratios',
  RATIO: 'Ratio',
  TIME: 'Time',
  PERIOD: 'Number of period',
  LATEST_YEAR: 'Latest year',
  SEARCH: 'Search',
  PROFITABILITY: 'Profitability  ',
  STRENGTH: 'Strength',
  EFFICIENCY: 'Efficiency',
  VALUATION: 'Valuation',
  GROWTH: 'Growth',
  REVENUE_GROWTH: 'Revenue Growth',
  REVENUE: 'Revenue',
  PROFIT_AFTER_TAX_GROWTH: 'Profit After Tax Growth',
  PROFIT_AFTER_TAX: 'Profit After Tax',
  PROFIT_MARGIN: 'Profit Margin',
  NET_PROFIT_MARGIN: 'Net Profit Margin',
  GROWTH_PROFIT_MARGIN: 'Gross Profit Margin',
  WORKING_CAPITAL: 'Working Capital',
  NET_WORKING_CAPITAL: 'Net Working Capital',
  CASH_RATIO: 'Cash Ratio',
  CURRENT_RATIO: 'Current Ratio',
  COVERAGE_RATIO: 'Coverage Ratio',
  INTEREST_COVERAGE_RATIO: 'Interest Coverage Ratio',
  ASSET_COVERAGE_RATIO: 'Asset Coverage Rato',
  EBITDA_DEBT: 'EBITDA/Debt',
  FINANCIAL_LEVERAGE: 'Financial Leverage',
  FINANCIAL_LEVERAGE_RATIO: 'Financial Leverage',
  DEBT_EQUITY: 'Debt/Equity',
  ROA_ROE: 'ROA vs ROE',
  ROA: 'ROA',
  ROE: 'ROE',
  DUPONT_ANALYSIS: 'Dupont Analysis',
  ASSET_TURNOVER: 'Asset Turnover',
  ASSET_MANAGEMENT: 'Asset Management',
  CASH_CONVERSION_CYCLE: 'Cash Conversion Cycle',
  PE: 'P/E',
  SECTOR_MEDIAN_PE: "Sector's P/E",
  INDEX_MEDIAN_PE: "VNIndex's P/E",
  PB: 'P/B',
  SECTOR_MEDIAN_PB: "Sector's P/B",
  INDEX_MEDIAN_PB: "VNIndex's P/B",
  EV_EBITDA: 'EV/EBITDA',
  SECTOR_MEDIAN_EV_EBITDA: "Sector's EV/EBITDA",
  INDEX_MEDIAN_EV_EBITDA: "VNIndex's EV/EBITDA",
  TOTAL_ASSET_GROWTH: 'Total Assets Growth',
  TOTAL_ASSET: 'Total Assets',
  NET_DEBT_GROWTH: 'Net Debt Growth',
  NET_DEBT: 'Net Debt',
  EBITDA_GROWTH: 'EBITDA Growth',
  EBITDA: 'EBITDA',
  GROSS_PROFIT_MARGIN: 'Gross Profit Margin',
  EBITDA_MARGIN: 'EBITDA Margin',
  CHART_RATIO: 'Ratio (Times)',
  CHART_EBITDA_DEBT: 'EBITDA/Debt (Times)',
  CHART_FINANCIAL_LEVERAGE: 'Financial Leverage (Times)',
  CHART_DEBT_EQUITY: 'Debt/Equity (Times)',
  CHART_ASSET_NET: 'AT or NPM (Times)',

  table: {
    ACTIVITY_RATIO: 'Activity Ratio',
    TOTAL_ASSET_TURNOVER: 'Total Asset Turnover',
    EQUITY_TURNOVER: 'Equity Turnover',
    RECEIVABLES_TURNOVER: 'Receivables Turnover',
    DAY_SALE_OUTSTANDING: 'Days of Sales Outstanding (DSO)',
    INVENTORY_TURNOVER: 'Inventory Turnover',
    DAY_INVENTORY_HAND: 'Days of Inventory on Hand (DOH)',
    PAYABLE_TURNOVER: 'Payables Turnover',
    NUMBER_DAY_PAYABLE: 'Number of Days of Payables',
    CASH_CONVERSION_CYCLE: 'Cash Conversion Cycle',
    LIQUIDITY_RATIO: 'Liquidity Ratio',
    CASH_RATIO: 'Cash Ratio',
    QUICK_RATIO: 'Quick Ratio',
    CURRENT_RATIO: 'Current Ratio',
    SOLVENCY_RATIO: 'Solvency Ratio',
    ST_DEPT_ASSET_RATIO: 'ST Debt-to-Assets Ratio',
    ST_DEPT_EQUITY_RATIO: 'ST Debt-to-Equity Ratio',
    LT_DEPT_ASSET_RATIO: 'LT Debt-to-Assets Ratio',
    LT_DEPT_EQUITY_RATIO: 'LT Debt-to-Equity Ratio',
    DEPT_ASSET_RATIO: 'Debt-to-Assets Ratio',
    DEPT_EQUITY_RATIO: 'Debt-to-Equity Ratio',
    LIABILITIES_DEPT_ASSET_RATIO: 'Liabilities-to-Assets Ratio',
    LIABILITIES_DEPT_EQUITY_RATIO: 'Liabilities-to-Equity Ratio',
    FINANCIAL_LEVERAGE_RATIO: 'Financial Leverage Ratio',
    INTEREST_COVERAGE: 'Interest Coverage',
    PROFITABILITY_RATIO: 'Profitability Ratio',
    GROSS_PROFIT_MARGIN: 'Gross profit margin',
    EBITDA_MARGIN: 'EBITDA margin',
    EBIT_MARGIN: 'EBIT margin',
    PRETAX_MARGIN: 'Pretax margin',
    NET_PROFIT_MARGIN: 'Net profit margin',
    ROA: 'ROA',
    ROE: 'ROE',
    ROIC: 'ROIC',
    EFFECTIVE_TAX_RATE: 'Effective tax rate',
    CASHFLOW_RATIO: 'Cash Flow Ratio',
    CFO_LIABILITIES: 'CFO to Liabilities',
    FREE_CASHFLOW_REVENUE: 'Free cash flow/ revenue',
    FREE_CASHFLOW_OPERATING: 'Free cash flow/operating income',
    FREE_CASHFLOW_ASSET: 'Free Cash flow on assets',
    FREE_CASHFLOW_EQUITY: 'Free Cash flow on equity',
    REINVESTMENT_FCF: 'Reinvestment-FCF',
    GROWTH: 'Growth',
    NET_REVENUE_GROWTH: 'Net revenue Growth YoY',
    GROSS_PROFIT_GROWTH: 'Gross profit Growth YoY',
    EBITDA_GROWTH: 'EBITDA Growth YoY',
    EBIT_GROWTH: 'EBIT Growth YoY',
    EBT_GROWTH: 'EBT Growth YoY',
    RECEIVABLE_GROWTH: 'Receivable Growth YoY',
    NET_INVENTORY_GROWTH: 'Net inventory Growth YoY',
    SHORT_TERM_GROWTH: 'Short term borrowing Growth YoY',
    LONG_TERM_GROWTH: 'Long term borrowing Growth YoY',
    SGA_GROWTH: 'SGA Expense Growth YoY',
    ASSET_GROWTH: 'Asset Growth YoY',
    OWNER_EQUITY_GROWTH: 'Owner equity Growth YoY',
    CFO_GROWTH: 'CFO Growth YoY',
    VALUATION_RATIO: 'Valuation Ratio',
    MARKET_CAP: 'Market cap (Billion VND)',
    EV: 'EV (Billion VND)',
    BOOK_VALUE_PER_SHARE: 'Book value per Share',
    SALE_PER_SHARE: 'Sales per Share',
    BASIC_EPS: 'Basic EPS',
    DILUTED_EPS: 'Diluted EPS',
    FREE_CASHFLOW_PER_SHARE: 'Free Cashflow per Share',
    PB: 'P/B',
    PE: 'P/E',
    PS: 'P/S',
    EV_REVENUE: 'EV / Revenue',
    EV_EBITDA: 'EV / EBITDA',
    EV_EBIT: 'EV / EBIT',
    DIVIDEND_YIELD: 'Dividend Yield',
  },
}

export const security = {
  ASSET_STRUCTURE: 'Asset Structure',
  PERFORMANCE: 'Performance',

  assetStructure: {
    AFS_DECOMPOSITION: 'AFS - Decomposition',
    LISTED_SHARE: 'Listed Share',
    UNLISTED_SHARE: 'Unlisted Share',
    BONDS: 'Bonds',
    CERTIFICATE_DEPOSIT: 'Certificate of Deposit',
    OTHER: 'Others',
    CHART_CONTAINER: 'Chart',
    DATA_TABLE: 'Data Table',
    YOY_GROWTH: 'YoY Growth (%)',
    FINANCIAL_FVTPL:
      'Financial assets at fair value\nthrough profit or loss (FVTPL)',
    FINANCIAL_AFS: 'Available-for-sale\nfinancial assets (AFS)',
    MARGIN_LENDING: 'Margin Lending',
    OWNER_EQUITY: "Owner's Equity",
    MARGIN_AND_OWNER: "Margin Loan/ Owner's Equity",
    FVTPL_DECOMPOSITION: 'FVTPL - Listed Share Decomposition',
    TICKER: 'Ticker',
    SECTOR: 'Sector',
    COST: 'Cost',
    MARKET_VALUE: 'Market Value',
    FVTPL_SEGMENTAL: 'FVTPL - Segmental',
    MARGIN_LOAN: 'Margin Loan',
    MARGIN_AND_OWNER_PERCENT: "Margin Loan/ Owner's Equity (%)",
    LOAN_AND_MARGIN: 'Loan & Margin',
    PORTFOLIO_STRUCTURE: 'Portfolio Structure',
    CORE_ACTIVITIES: 'Core Activities',
  },

  performance: {
    DATA_TABLE: 'Data Table',
    YOY_GROWTH: 'YoY Growth (%)',
    OPERATING_SALE: 'Operating Sale',
    REVENUE_BROKERAGE_CUSTOMER_SERVICE:
      'Revenue from Brokerage & Customer Service',
    REVENUE_FROM_BROKERAGE: 'Revenue from Brokerage',
    REVENUE_FROM_MARGIN: 'Revenue from Margin Lending',
    REVENUE_FROM_PROPRIETARY: 'Revenue from Proprietary',
    INCOME_FVTPL: 'Income from FVTPL',
    INCOME_AFS: 'Income from AFS',
    REVENUE_FROM_INVESTMENT: 'Revenue from Investment',
    REVENUE_INVESTMENT: 'Revenue from Investment',
    REVENUE_PROPRIETARY: 'Revenue from Proprietary',
  },
}
