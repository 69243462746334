import { useDispatch } from 'react-redux'
import GroupStock from '../../../../../common/dataExplorer/filter/GroupStock'
import useGetExchange from '../../../../../common/masterData/useGetExchange'
import useGetICB from '../../../../../common/masterData/useGetICB'
import style from '../index.module.css'
import { handleAddManyTicker } from './store/slice'

const SearchGroupStock = () => {
  const dispatch = useDispatch()

  const exchange = useGetExchange(true, true)
  const icb = useGetICB(true, true)

  const handleAddTicker = (items) => {
    const listId = items.map((item) => item.organizationId)
    dispatch(handleAddManyTicker(listId))
  }

  return (
    <div className={style.searchGroupStock}>
      <GroupStock
        handleAddTicker={handleAddTicker}
        exchange={exchange}
        icb={icb}
        isIndicesSectors={false}
        isOnlyEnableLastExchange={false}
        inputClass={style.maxWidthInput}
      />
    </div>
  )
}

export default SearchGroupStock
