import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Sheet from '../../../../common/dataExplorer/Sheet'
import TableDataExplorer from '../../../../common/dataExplorer/table'
import CellOrder from '../../../../common/dataExplorer/table/CellOrder'
import { RESET_DEFAULT_SORT } from '../../../../common/dataExplorer/table/Thead'
import EventEmitter, { LOADING_TABLE_DE } from '../../../../utils/EventEmitter'
import { pageSize } from '../../table/constant'
import {
  addSheet,
  changeActiveSheet,
  changeListSheet,
  changeTickerDisplay,
  keys,
  removeColumn,
  removeSheet,
  selectActiveSheet,
  selectColumnById,
  selectColumnCell,
  selectColumnId,
  selectFullColumnId,
  selectFullTickerDisplay,
  selectIndicatorById,
  selectListSheet,
  selectLoading,
  selectTickerCell,
  selectTickerDisplay,
  selectTickerId,
  sort,
  swapColumns,
  swapTwoColumn,
} from '../store/slice'
import CellInfo from './CellInfo'
import Checkbox from './Checkbox'
import CheckboxAll from './CheckboxAll'
import SortColumn from './SortColumn'
import ThTable from './ThTable'

const cellInfo = [
  {
    id: 'check',
    title: 'CHECKBOX',
    render: (idTicker, id) => <Checkbox idTicker={idTicker} colId={id} />,
    renderTh: () => <CheckboxAll />,
    disableSort: true,
    appendStyleTh: { minWidth: '73px', maxWidth: '73px', left: 0 },
    appendStyle: { minWidth: '56px', maxWidth: '56px', left: 0 },
    isSticky: true,
  },
  {
    id: 'no',
    title: 'tool.dataExplorer.NO',
    appendStyleTh: { minWidth: '65px', maxWidth: '65px', left: 73 },
    appendStyle: { minWidth: '48px', maxWidth: '48px', left: 73 },
    disableSort: true,
    render: (_, __, ___, index) => <CellOrder index={index} />,
    isSticky: true,
  },
  {
    id: 'ticker',
    title: 'tool.dataExplorer.corporate.TICKER_TABLE',
    appendStyleTh: { minWidth: '65px', maxWidth: '65px', left: 138 },
    appendStyle: { minWidth: '48px', maxWidth: '48px', left: 138 },
    render: (idTicker) => (
      <CellInfo idTicker={idTicker} colId={['ticker', 'taxCode']} />
    ),
    isSticky: true,
  },
  {
    id: 'organizationShortName',
    title: 'tool.dataExplorer.corporate.COMPANY',
    appendStyleTh: { minWidth: '334px', maxWidth: '334px', left: 203 },
    appendStyle: { minWidth: '317px', maxWidth: '317px', left: 203 },
    render: (idTicker, id) => <CellInfo idTicker={idTicker} colId={id} />,
    isSticky: true,
  },
  {
    id: 'exchangeCode',
    title: 'tool.dataExplorer.corporate.EXCHANGE',
    appendStyleTh: { minWidth: '87px', maxWidth: '87px', left: 537 },
    appendStyle: { minWidth: '70px', maxWidth: '70px', left: 537 },
    render: (idTicker, id) => <CellInfo idTicker={idTicker} colId={id} />,
    isSticky: true,
  },
]

const ResultTable = ({ width }) => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const listSheet = useSelector(selectListSheet)

  const [loadingTicker, setLoadingTicker] = useState(false)

  useEffect(() => {
    EventEmitter.subscribe(LOADING_TABLE_DE, setLoadingTicker)
    return () => EventEmitter.unsubscribe(LOADING_TABLE_DE, setLoadingTicker)
  }, [])

  useEffect(() => {
    EventEmitter.dispatch(RESET_DEFAULT_SORT)
  }, [activeSheet])

  const changeIdDisplay = (ids) => dispatch(changeTickerDisplay(ids))

  const sortColumn = (idColumn, typeSort) => {
    dispatch(sort({ idColumn, typeSort }))
  }

  const swapColumn = (column1, column2) => {
    dispatch(swapTwoColumn({ column1, column2 }))
  }

  const swapManyColumns = ({ newColumnId, columnDelete }) => {
    dispatch(removeColumn(columnDelete))
    dispatch(swapColumns(newColumnId))
  }

  const setSheet = (sheet) => {
    dispatch(changeListSheet(sheet))
  }

  const setActiveTab = (value) => {
    dispatch(changeActiveSheet(value))
  }

  const handleNewSheet = (sheet) => {
    if (sheet?.value) {
      dispatch(addSheet(sheet?.value))
    }
  }

  const handleRemoveSheet = (sheet) => {
    if (sheet?.value) {
      dispatch(removeSheet(sheet))
    }
  }

  const isFiinXTemplate = !!listSheet.find(
    (sheet) => sheet.value === activeSheet,
  )?.templateGroup

  return (
    <>
      <TableDataExplorer
        isLoading={loadingTicker || loadingTable}
        maxColumn={250}
        selectColumnId={selectColumnId(activeSheet)}
        selectColumnCell={selectColumnCell(activeSheet)}
        maxRow={pageSize}
        selectTickerCell={selectTickerCell(activeSheet)}
        selectId={selectTickerDisplay(activeSheet)}
        selectFullId={selectTickerId(activeSheet)}
        selectFullColumnId={selectFullColumnId(activeSheet)}
        changeIdDisplay={changeIdDisplay}
        selectIdDisplay={selectFullTickerDisplay(activeSheet)}
        cellInfo={cellInfo}
        sortColumn={sortColumn}
        swapColumn={swapColumn}
        renderTh={(title, colId, column) => (
          <ThTable
            title={title}
            colId={colId}
            column={column}
            isFiinXTemplate={isFiinXTemplate}
          />
        )}
        renderContentPagination={() => (
          <Sheet
            width={width}
            handleNewSheet={handleNewSheet}
            handleRemoveSheet={handleRemoveSheet}
            selectColumnId={selectFullColumnId(activeSheet)}
            selectColumnById={selectColumnById(activeSheet)}
            selectIndicatorById={selectIndicatorById}
            selectSheet={selectListSheet}
            setSheet={setSheet}
            selectActiveTab={selectActiveSheet}
            setActiveTab={setActiveTab}
            swapColumns={swapManyColumns}
            idKey="alias"
            renderPopupSortColumn={(props) => (
              <SortColumn {...props} isDisableDelete={isFiinXTemplate} />
            )}
          />
        )}
        stateResetDefaultPage={activeSheet}
        noDataText=""
      />
    </>
  )
}

ResultTable.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default ResultTable
