import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { getColorByValueChart } from '../../../utils/Color'
import { SizeTracker } from '../../sizeTracker'
import { AXIS_LABEL_POSITION } from '../constants'
import { FooterContainer } from '../footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../footer/Item'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
  getXAxisFormatAndTicks,
} from '../helper'
import { ChartContainer } from '../rechart/ChartContainer'
import UseTimeZone from './../../hooks/useTimeZone'

export const StackedBarLineChart = ({
  data,
  width,
  height,
  barKeys,
  lineKeys,
  keyXAxis,
  decimalLength,
  margin,
  timeFrame,
  fillColor,
  mappingDisplayName,
  unit,
  lineUnit,
  leftLabel,
  rightLabel,
  valueKeysColor,
  formatTooltipTitle,
  isFooterResize,
  isFooterCheckbox,
  isLineFooterCheckbox,
  allowEscapeViewBoxY,
  isNotFormatXAxis,
  customStyleTooltipValue,
  isOneYAxis,
  listCheckbox,
  setListCheckbox,
  footerItemStyle,
  customMarginForOneYAxis,
  renderCustomTooltip,
}) => {
  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  // Get data
  const getDisplayBarKeys = useMemo(() => {
    return barKeys.filter((key) => listCheckbox.includes(key))
  }, [listCheckbox])

  const getDisplayLineKeys = useMemo(() => {
    return lineKeys.filter((key) =>
      isLineFooterCheckbox ? listCheckbox.includes(key) : true,
    )
  }, [listCheckbox])

  // Footer
  const footerData = () => {
    return [
      ...barKeys.map((key) => ({
        text: mappingDisplayName[key] ?? key,
        type: FOOTER_TYPE.SQUARE,
        color: fillColor[key],
        dataKey: isFooterCheckbox ? key : undefined,
      })),
      ...lineKeys.map((key) => ({
        text: mappingDisplayName[key] ?? key,
        type: FOOTER_TYPE.CIRCLE_MARKER_LINE,
        color: fillColor[key],
        dataKey: isLineFooterCheckbox ? key : undefined,
      })),
    ]
  }

  // Render tooltip
  const { format } = getXAxisFormatAndTicks({
    data,
    keyXAxis: keyXAxis,
    timeRange: timeFrame,
    width,
    locale,
    timeZone,
  })

  const getFormatValueColor = (val, key) => {
    return customStyleTooltipValue
      ? valueKeysColor.includes(key) && customStyleTooltipValue(val)
      : valueKeysColor.includes(key) && getColorByValueChart(val)
  }

  const getYAxisId = (isBarKey) => {
    if (isBarKey) {
      return isOneYAxis ? 'chart' : 'barChart'
    } else {
      return isOneYAxis ? 'chart' : 'lineChart'
    }
  }

  const getContent = () => {
    return [
      {
        keys: getDisplayBarKeys,
        yAxisId: getYAxisId(true),
        unit: unit,
        decimalLength: decimalLength,
        formatValueColor: getFormatValueColor,
      },
      {
        keys: getDisplayLineKeys,
        yAxisId: getYAxisId(false),
        unit: lineUnit,
        decimalLength: decimalLength,
        formatValueColor: getFormatValueColor,
      },
    ]
  }

  // Render
  const renderBarChart = (chartContentWidth) => {
    return getDisplayBarKeys.map((key) => (
      <Bar
        key={key}
        stackId="stackBar"
        yAxisId={getYAxisId(true)}
        dataKey={key}
        barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
        fill={fillColor[key]}
        isAnimationActive={false}
      />
    ))
  }

  const renderLineChart = (chartContentWidth) => {
    return getDisplayLineKeys.map((key) => (
      <Line
        key={key}
        yAxisId={getYAxisId(false)}
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={2}
        dot={{
          strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
        }}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker key={isFooterResize}>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={keyXAxis}
              yAxis={
                !isOneYAxis
                  ? [
                      {
                        id: 'barChart',
                        keys: getDisplayBarKeys,
                        orientation: 'left',
                        yAxisWidth: margin.left,
                        isStackedBar: true,
                        label: leftLabel ? I18n.t(leftLabel) : undefined,
                        labelPosition: AXIS_LABEL_POSITION.LEFT,
                      },
                      {
                        id: 'lineChart',
                        keys: getDisplayLineKeys,
                        orientation: 'right',
                        isLineChart: true,
                        yAxisWidth: margin.right,
                        label: rightLabel ? I18n.t(rightLabel) : undefined,
                        labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      },
                    ]
                  : [
                      {
                        id: 'chart',
                        keys: [...getDisplayBarKeys, ...getDisplayLineKeys],
                        orientation: 'left',
                        yAxisWidth: margin.left,
                        isStackedBar: true,
                        label: leftLabel ? I18n.t(leftLabel) : undefined,
                        labelPosition: AXIS_LABEL_POSITION.LEFT,
                      },
                    ]
              }
              margin={isOneYAxis && customMarginForOneYAxis}
              timeFrame={timeFrame}
              allowEscapeViewBoxY={allowEscapeViewBoxY}
              tooltipSchema={
                !renderCustomTooltip && {
                  title: {
                    formatDate: format,
                    formatValue: formatTooltipTitle,
                  },
                  content: getContent(),
                  mappingDisplayName: mappingDisplayName,
                }
              }
              renderCustomTooltip={renderCustomTooltip}
              isNotFormatXAxis={isNotFormatXAxis}
            >
              {({ chartContentWidth }) => (
                <>
                  {renderBarChart(chartContentWidth)}
                  {renderLineChart(chartContentWidth)}
                </>
              )}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            numItemPerRow={isFooterResize ? footerData().length : 3}
            listCheckbox={listCheckbox}
            setListCheckbox={setListCheckbox}
            itemStyle={footerItemStyle}
          />
        </>
      )}
    </SizeTracker>
  )
}

StackedBarLineChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  barKeys: PropTypes.array,
  lineKeys: PropTypes.array,
  keyXAxis: PropTypes.string.isRequired,
  decimalLength: PropTypes.number,
  margin: PropTypes.object,
  timeFrame: PropTypes.string,
  fillColor: PropTypes.object.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
  unit: PropTypes.string,
  lineUnit: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  valueKeysColor: PropTypes.array,
  isFooterResize: PropTypes.bool,
  isFooterCheckbox: PropTypes.bool,
  allowEscapeViewBoxY: PropTypes.bool,
  listCheckbox: PropTypes.array,
  setListCheckbox: PropTypes.func,
}

StackedBarLineChart.defaultProps = {
  barKeys: [],
  lineKeys: [],
  valueKeysColor: [],
  isFooterResize: false,
  isFooterCheckbox: false,
  allowEscapeViewBoxY: false,
  listCheckbox: [],
  setListCheckbox: () => {},
}
