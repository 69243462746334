import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import useGetICB from '../../../../../common/masterData/useGetICB'
import useGetIndices from '../../../../../common/masterData/useGetIndices'
import useGetStockHOHAUP from '../../../../../common/masterData/useGetStockHOHAUP'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { SEARCH_ITEM_TYPE } from '../../constants'
import { convertNameSectorsByLevel } from '../../helper'
import style from '../../style.module.css'
import CompareTab from './CompareTab'
import DropdownICBLevel from './DropdownICBLevel'
import ItemCompare from './ItemCompare'
import {
  selectCompareTab,
  selectICBLevelData,
  selectKeySearch,
  selectListCompareChecked,
} from './store/slice'

const ComponentListData = () => {
  const ICB = useGetICB()
  const indices = useGetIndices()
  const stockHOHAUP = useGetStockHOHAUP()

  const compareTab = useSelector(selectCompareTab)
  const keySearch = useSelector(selectKeySearch)
  const ICBLevel = useSelector(selectICBLevelData)
  const listCompareChecked = useSelector(selectListCompareChecked)

  const isLoading = {
    loadingGetICB: ICB.loading,
    loadingGetStocks: stockHOHAUP.loading,
    loadingGetIndices: indices.loading,
  }

  const [compareListSearch, setCompareListSearch] = useState({
    sector: [],
    indices: [],
    ticker: [],
  })

  useEffect(() => {
    setCompareListSearch({
      sector: ICB.ICBs.map((item) => ({
        icbNameCustom: convertNameSectorsByLevel(item),
        type: SEARCH_ITEM_TYPE.SECTOR,
        ...item,
      })),
      ticker: stockHOHAUP.stocksHohaup.map((item) => ({
        type: SEARCH_ITEM_TYPE.TICKER,
        ...item,
      })),
      indices: indices.indices.map((item) => ({
        type: SEARCH_ITEM_TYPE.INDICES,
        ...item,
      })),
    })
  }, [ICB.ICBs, stockHOHAUP.stocksHohaup, indices.indices])

  const handleSearchAll = () => {
    const result = [
      ...compareListSearch.indices,
      ...compareListSearch.sector,
      ...compareListSearch.ticker,
    ]
      .filter(
        (item) =>
          item.groupName?.toLowerCase().includes(keySearch) ||
          item.icbNameCustom?.toLowerCase().includes(keySearch) ||
          item.ticker?.toLowerCase().includes(keySearch),
      )
      .sort(
        (a, b) =>
          a.groupName?.localeCompare(b.groupName) ||
          a.icbNameCustom?.localeCompare(b.icbNameCustom) ||
          a.ticker?.localeCompare(b.ticker),
      )

    if (listCompareChecked.length) {
      listCompareChecked.forEach((element) => {
        result.sort((a, b) => {
          return (a.groupName || a.icbNameCustom || a.ticker) ===
            element.nameCompare
            ? -1
            : (b.groupName || b.icbNameCustom || b.ticker) ===
              element.nameCompare
            ? 1
            : 0
        })
      })
    }
    return result
  }

  const handleSearchIndex = () => {
    const result = compareListSearch.indices
      .filter((item) => item.groupName?.toLowerCase().includes(keySearch))
      .sort((a, b) => a.groupName.localeCompare(b.groupName))

    if (listCompareChecked.length) {
      listCompareChecked.forEach((element) => {
        result.sort((a, b) => {
          return a.groupName === element.nameCompare
            ? -1
            : b.groupName === element.nameCompare
            ? 1
            : 0
        })
      })
    }
    return result
  }

  const handleSearchSector = () => {
    const result = compareListSearch.sector
      .filter(
        (item) =>
          item.icbNameCustom?.toLowerCase().includes(keySearch) &&
          item.icbLevel === ICBLevel,
      )
      .sort((a, b) => a.icbNameCustom.localeCompare(b.icbNameCustom))

    if (listCompareChecked.length) {
      listCompareChecked.forEach((element) => {
        result.sort((a, b) => {
          return a.icbNameCustom === element.nameCompare
            ? -1
            : b.icbNameCustom === element.nameCompare
            ? 1
            : 0
        })
      })
    }

    return result
  }

  const handleSearchTicker = () => {
    let result = compareListSearch.ticker
      .filter((item) => item.ticker?.toLowerCase().includes(keySearch))
      .sort((a, b) => a.ticker.localeCompare(b.ticker))

    if (listCompareChecked.length) {
      listCompareChecked.forEach((element) => {
        result.sort((a, b) => {
          return a.ticker === element.nameCompare
            ? -1
            : b.ticker === element.nameCompare
            ? 1
            : 0
        })
      })
    }

    return result
  }

  const mapCompareTab = {
    [SEARCH_ITEM_TYPE.ALL]: handleSearchAll(),
    [SEARCH_ITEM_TYPE.INDICES]: handleSearchIndex(),
    [SEARCH_ITEM_TYPE.SECTOR]: handleSearchSector(),
    [SEARCH_ITEM_TYPE.TICKER]: handleSearchTicker(),
  }

  const filterDataCompareList = () => {
    return mapCompareTab[compareTab]
  }

  return (
    <>
      <div>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <CompareTab />
                {size.height && (
                  <div style={{ margin: '5px 0px' }}>
                    {Object.values(isLoading).some((v) => v === true) && (
                      <Loading />
                    )}
                    {Object.values(isLoading).every((v) => v === false) && (
                      <ScrollComponent autoHeight={true} autoHeightMax={390}>
                        <>
                          {compareTab === SEARCH_ITEM_TYPE.SECTOR && (
                            <DropdownICBLevel />
                          )}
                          <table
                            style={{ width: '100%' }}
                            className={style.customTable}
                          >
                            <tbody>
                              {filterDataCompareList()?.length > 0
                                ? filterDataCompareList().map((item, index) => {
                                    return (
                                      <ItemCompare item={item} key={index} />
                                    )
                                  })
                                : null}
                            </tbody>
                          </table>
                          {filterDataCompareList()?.length === 0 && <NoData />}
                        </>
                      </ScrollComponent>
                    )}
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
    </>
  )
}

export default ComponentListData
