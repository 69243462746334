import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { INDICATORS } from '../constant'
import style from './index.module.css'
import { changeIndicator, triggerCallData } from './store/slice'
import { getMarketCapOrganizationByIcbId } from './store/thunk'

const Reset = () => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)

  const handleReset = async () => {
    const getDefaultThenCallData = (params) => async (dispatch) => {
      await dispatch(getMarketCapOrganizationByIcbId(params))
      return await dispatch(triggerCallData())
    }

    dispatch(changeIndicator(INDICATORS.basicPE))
    dispatch(
      getDefaultThenCallData({
        IcbId: sectorInfo.icbId,
        Top: 5,
      }),
    )
  }

  return (
    <div className="mt-8">
      <Button
        onClick={handleReset}
        className={`btn normal ${style.resetButton}`}
      >
        <Span style={{ fontSize: 11 }}>
          <Translate value="sector.sectorConstituents.valuation.RESET" />
        </Span>
      </Button>
    </div>
  )
}

export default Reset
