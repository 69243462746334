import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { uuid } from '../../../utils/Common'
import {
  getChartNameBySampleName,
  getChartOrderByListChart,
} from '../../common/helpers'
import MenuFooterChart from '../../common/menuFooterChart'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { screenTypes } from '../../constants'
import { KEY_DASHBOARD_TABS } from '../../editDashboard/constants'
import {
  addChart,
  changeDashboard,
  selectDashboard,
  selectDashboardChartData,
  updateChart,
} from '../../store/slice'
import { lengthChartInLayout } from '../common/useGetLayoutDashboard'
import { CHART_PARAMETERS_DEFAULT } from '../constants'
import {
  changeChartTabActive,
  changeChartTabsData,
  changeStateNameChart,
  selectChartActiveID,
  selectChartTabActive,
  selectChartTabsData,
  selectLoading,
  selectShowTable,
  setChartActiveID,
} from '../store/slice'

export const MenuFooterContainer = ({
  menuFooterContainerRef,
  width,
  height,
  isEditChart,
  onChangeDimensions,
}) => {
  const dispatch = useDispatch()

  const { defaultChartSettings } = useGetUserSettings()

  // Use selector
  const { data = {} } = useSelector(selectDashboard)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const chartTabs = useSelector(selectChartTabsData)
  const chartTabActive = useSelector(selectChartTabActive)
  const isShowTable = useSelector(selectShowTable)
  const loading = useSelector(selectLoading)
  const chartActiveID = useSelector(selectChartActiveID)

  // Actions
  const handleChangeTab = (value, chartID) => {
    if (Object.values(loading).some((val) => val)) return

    if (isEditChart) {
      handleChangeTabEditChart(value)
    }
    dispatch(changeChartTabActive(+value))
    dispatch(setChartActiveID(chartID))
  }

  const handleChangeNameChart = (value) => {
    dispatch(changeStateNameChart(value))
  }

  const handleAddChart = () => {
    if (Object.values(loading).some((val) => val)) {
      return
    }

    const newChartTabActive = Object.keys(chartTabs).length + 1
    const nameChart = getChartNameBySampleName(
      Object.values(chartTabs).map((i) => i.nameChart?.name),
      'Chart ',
    )
    const chartValue = {
      ...CHART_PARAMETERS_DEFAULT,
      nameChart: {
        ...CHART_PARAMETERS_DEFAULT.nameChart,
        name: nameChart,
      },
      settings:
        defaultChartSettings?.settings || CHART_PARAMETERS_DEFAULT.settings,
      styleSetting:
        defaultChartSettings?.styleSetting ||
        CHART_PARAMETERS_DEFAULT.styleSetting,
      order: getChartOrderByListChart(dashboardChartData),
    }

    if (isEditChart) {
      dispatch(
        addChart({
          id: uuid(),
          dashboardId: data.id,
          name: nameChart,
          detail: JSON.stringify(chartValue),
          order: getChartOrderByListChart(dashboardChartData),
        }),
      )
    }

    dispatch(
      changeChartTabsData({
        [newChartTabActive]: {
          ...CHART_PARAMETERS_DEFAULT,
          nameChart: {
            ...CHART_PARAMETERS_DEFAULT.nameChart,
            name: nameChart,
          },
          settings:
            defaultChartSettings?.settings || CHART_PARAMETERS_DEFAULT.settings,
          styleSetting:
            defaultChartSettings?.styleSetting ||
            CHART_PARAMETERS_DEFAULT.styleSetting,
          order: getChartOrderByListChart(dashboardChartData),
        },
      }),
    )
    dispatch(changeChartTabActive(newChartTabActive))
  }

  const handleChangeTabEditChart = (value) => {
    if (isEditChart && +value === KEY_DASHBOARD_TABS) {
      dispatch(changeDashboard({ screenType: screenTypes.EDIT_CHART }))
    }
    dispatch(changeChartTabActive(+value))
  }

  useEffect(() => {
    if (isEditChart) {
      const chartValue = chartTabs[chartTabActive]
      if (dashboardChartData[chartTabActive - 1 - KEY_DASHBOARD_TABS]) {
        dispatch(
          updateChart({
            id:
              chartActiveID ||
              dashboardChartData.find((item) => item.order === chartValue.order)
                ?.id,
            value: JSON.stringify({
              ...chartValue,
              order: chartTabActive - KEY_DASHBOARD_TABS,
            }),
            order: chartTabActive - KEY_DASHBOARD_TABS,
            name: chartValue?.nameChart?.name,
          }),
        )
      }
    }
  }, [isEditChart, chartTabs[chartTabActive]])

  // Render
  return (
    <MenuFooterChart
      isEditChart={isEditChart}
      isShowTable={isShowTable}
      ref={menuFooterContainerRef}
      height={height}
      width={width}
      chartTabs={chartTabs}
      activeTab={chartTabActive}
      onChangeTab={handleChangeTab}
      onAddChart={handleAddChart}
      setDimensions={onChangeDimensions}
      onChangeNameChart={handleChangeNameChart}
      disableAddChart={
        data && dashboardChartData.length >= lengthChartInLayout[data?.layout]
      }
    />
  )
}
