import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'
import { handleExport } from '../../../../../../utils/Export'

export const getSteelCoatStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/steelCoat/steelStatistics',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getSteelCoatStatistics(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadSteelCoatStatistic = createAsyncThunk(
  'sector/sectorSpecific/steel/steelCoat/downloadSteelCoatStatistic',
  async (data) => {
    const response = await SteelService.getDownloadSteelCoatStatistic(data)
    handleExport(response.data, response.filename)
  },
)
