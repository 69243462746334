import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Chart } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectViewType } from '../store/slice'

export const PanelChart = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const viewType = useSelector(selectViewType)

  return (
    <Panel
      title={'sector.financialAnalysis.corporate.PRICE_CHART'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      isDownloadJpg
      windowZoom
      downloadJpgParams={{
        domId: 'sectorFinancialAnalysisCorporate',
        nameScreen: UseI18n(
          'sector.financialAnalysis.common.FINANCIAL_ANALYSIS',
        ),
        chartName: UseI18n('sector.financialAnalysis.corporate.PRICE_CHART'),
        tabName: viewType,
        tickerName: currentInfo.ticker,
      }}
    >
      <Chart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        dataType={dataType}
      />
    </Panel>
  )
}

PanelChart.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
