import { EXCHANGE_CODE, TABLE_TYPE } from './constant'

const QUARTER_TIME = 24 * 60 * 60 * 1000 * 90 // 90 day
export const getDefaultYearQuarter = () => {
  const datetime = new Date(new Date().getTime() - 2 * QUARTER_TIME)
  const quarter = Math.floor((datetime.getMonth() + 3) / 3)
  const year = datetime.getFullYear()
  return { year, quarter }
}

export const getExchangeNameByValue = (value) => {
  const key = Object.keys(EXCHANGE_CODE).find(
    (key) => EXCHANGE_CODE[key].toUpperCase() === value.toUpperCase(),
  )

  if (key) {
    return 'corporate.peerValuation.overview.exchanges.' + key
  } else {
    return ''
  }
}

export const getListTableTab = () =>
  Object.keys(TABLE_TYPE).map((key) => {
    return {
      value: TABLE_TYPE[key],
      title: 'bond.comparable.common.quarter.' + key,
    }
  })

export const getIcbByOrganization = ({
  stock,
  stockById,
  ICBById,
  icbByCode,
}) => {
  let icb = null
  if (stock.icbId) {
    icb = ICBById[stock.icbId]
  } else {
    const findStock = stockById[stock.organizationId]
    if (findStock) {
      icb = ICBById[findStock.icbId]
    }
  }
  if (icb) {
    const arrayIcbCodePath = icb.icbCodePath.split('/')
    const icbCodeLv2 = arrayIcbCodePath[1]
    return icbByCode[icbCodeLv2]
  }
}
