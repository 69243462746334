import { createAsyncThunk } from '@reduxjs/toolkit'
import NewsEventsService from '../../../../../core/services/corporate/NewsEventsService'

export const getFilingsThunk = createAsyncThunk(
  'corporate/filings/GET_FILINGS',
  async (params, { rejectWithValue }) => {
    const response = await NewsEventsService.getFilings(params)
    if (response.success) return response
    return rejectWithValue('Error')
  },
)

export const getFilingCategoriesThunk = createAsyncThunk(
  'corporate/filings/GET_FILING_CATEGORIES',
  async (params, { rejectWithValue }) => {
    const response = await NewsEventsService.getFilingCategories(params)
    if (response.success) return response.data
    return rejectWithValue('Error')
  },
)
