import { createSlice, current } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import { getIdsFromProps } from '../../common/table/helper'
import { keyBy } from '../../utils/Common'
import EventEmitter, {
  CALLBACK_SHOW_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../../utils/EventEmitter'
import { register } from '../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../utils/Value'
import { maxTicker } from '../constants'
import {
  createWatchlistThunk,
  filerMyWatchListThunk,
  getTickerByExchangeThunk,
  getWatchListThunk,
  getWatchlistDetailThunk,
  updateFavoriteThunk,
  updateMasterWatchListThunk,
  updateWatchlistThunk,
} from './thunk'

const regexTaxcode = /^[0-9\- ]+$/

const sortAlphabet = (tickersById, arr) => {
  return arr.sort((id1, id2) => {
    if (tickersById?.[id1].bondOrder > tickersById?.[id2].bondOrder) return 1
    else return -1
  })
}

const sortTickers = (tickersById, tickerIds) => {
  // first ticker -> taxcode     second 2.alphabet
  const ticker = []
  const taxcode = []
  const none = []

  tickerIds.forEach((id) => {
    if (tickersById?.[id].bondCode.match(regexTaxcode)) taxcode.push(id)
    else if (tickersById?.[id].bondOrder) ticker.push(id)
    else none.push(id)
  })

  return sortAlphabet(tickersById, ticker)
    .concat(sortAlphabet(tickersById, taxcode))
    .concat(none)
}

const checkTicker = (length, callback, oldTickersById, newTickersById) => {
  if (length > maxTicker) {
    let handleTickersById = { ...current(oldTickersById) }
    let length = Object.keys(handleTickersById).length
    Object.keys(newTickersById).forEach((id) => {
      if (!handleTickersById[id] && length < maxTicker) {
        handleTickersById[id] = newTickersById[id]
        length += 1
      }
    })

    EventEmitter.dispatch(
      CALLBACK_SHOW_POPUP_ERROR,
      changeTickers(handleTickersById),
    )
    EventEmitter.dispatch(SHOW_POPUP_ERROR, [
      <Translate value="watchlist.watchlistDetail.MAX_COMPANY" />,
    ])
  } else {
    callback()
  }
}

const initialState = {
  isShowPopup: false,
  watchlistIds: [],
  watchlistsById: {},
  listType: [],
  loading: {
    watchlist: false,
    tickerTable: false,
    tickerFilter: false,
  },
  checkedWl: [],
  editState: {},
  isShowWatchlistDetail: false,
  watchlistDetail: {},
  tickersById: {},
  tickerIds: [],
  isShowTickerFilter: false,
  tickersFilter: [],
  tickerTableFilter: '',
  tickerId: '',
}

export const slice = createSlice({
  name: 'watchlistBond',
  initialState,
  reducers: {
    // restore to default state
    resetWatchlistStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // handle show popup
    setIsShowPopupBond(state, action) {
      state.isShowPopup = action.payload
    },
    // action table watchlist
    changeWatchlistIds(state, action) {
      state.watchlistIds = action.payload
    },
    sortWatchlist(state, action) {
      getIdsFromProps(state.watchlistIds, state.watchlistsById, action.payload)
    },
    hideEditWatchlist(state, action) {
      if (state.editState[action.payload])
        state.editState[action.payload].editKey = ''
    },
    // action handle checkbox watchlist, ticker
    addChecked(state, action) {
      state.checkedWl.push(action.payload)
    },
    addCheckedAll(state, action) {
      state.checkedWl = action.payload
    },
    unchecked(state, action) {
      const newCheckedWl = [...state.checkedWl]
      if (newCheckedWl.includes(action.payload))
        newCheckedWl.splice(state.checkedWl.indexOf(action.payload), 1)
      state.checkedWl = newCheckedWl
    },
    uncheckedAll(state) {
      state.checkedWl = []
    },
    // action show input edit name || description
    addEditKey(state, action) {
      state.editState[action.payload.id].editKey = action.payload.editKey
    },
    // action table ticker
    changeTickerIds(state, action) {
      state.tickerIds = action.payload
    },
    changeTickersById(state, action) {
      state.tickersById = action.payload
    },
    changeTickerId(state, action) {
      state.tickerId = action.payload
    },
    sortTicker(state, action) {
      getIdsFromProps(state.tickerIds, state.tickersById, action.payload)
    },
    // handle show ticker filter
    setIsShowTickerFilter(state, action) {
      state.isShowTickerFilter = action.payload
    },
    // filter ticker table
    changeTickerTableFilter(state, action) {
      state.tickerTableFilter = action.payload
    },
    changeTickers(state, action) {
      state.tickersById = action.payload
      state.tickerIds = sortTickers(action.payload, Object.keys(action.payload))
    },
    // reset watchlist detail
    resetWatchlistDetail(state) {
      state.checkedWl = []
      state.watchlistDetail = {}
      state.tickersById = {}
      state.tickerIds = []
      state.isShowTickerFilter = false
      state.tickersFilter = []
      state.tickerTableFilter = ''
      state.isShowWatchlistDetail = false
    },
    setIsShowWatchlistDetail(state, action) {
      state.isShowWatchlistDetail = action.payload
    },
    updateWatchListDetail: (state, action) => {
      state.watchlistDetail = action.payload
    },
  },

  extraReducers: (builder) => {
    // action get watchlist
    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loading.watchlist = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.watchlistsById = keyBy(action.payload, 'bondWatchListId')
      state.watchlistIds = action.payload.map((item) => item.bondWatchListId)
      state.loading.watchlist = false

      state.editState = keyBy(
        Object.keys(state.watchlistsById).map((bondWatchListId) => {
          return { bondWatchListId, editKey: '' }
        }),
        'bondWatchListId',
      )
    })
    builder.addCase(getWatchListThunk.rejected, (state) => {
      state.loading.watchlist = false
    })
    // action edit watchlist
    builder.addCase(updateMasterWatchListThunk.rejected, (state, action) => {
      state.editState = keyBy(
        Object.keys(state.watchlistsById).map((watchListId) => {
          return { watchListId, editKey: '' }
        }),
        'watchListId',
      )
    })
    // action get ticker
    builder.addCase(getWatchlistDetailThunk.pending, (state) => {
      state.loading.tickerTable = true
    })
    builder.addCase(getWatchlistDetailThunk.fulfilled, (state, action) => {
      state.watchlistDetail.details = action.payload
      state.tickersById = keyBy(action.payload, 'bondId')
      state.tickerIds = action.payload.map((item) => item.bondId)
      state.loading.tickerTable = false
    })
    builder.addCase(getWatchlistDetailThunk.rejected, (state) => {
      state.loading.tickerTable = false
    })
    // get ticker by exchange
    builder.addCase(getTickerByExchangeThunk.pending, (state) => {
      state.loading.tickerTable = true
    })
    builder.addCase(getTickerByExchangeThunk.fulfilled, (state, action) => {
      const newArray = action.payload.filter(
        (e) => !state.tickerIds.includes(e.bondId),
      )
      const newTickersById = {
        ...state.tickersById,
        ...keyBy(newArray, 'bondId'),
      }
      const newTickerIds = Object.keys(newTickersById)
      const lengthTickers = newTickerIds.length

      checkTicker(
        lengthTickers,
        () => {
          state.tickersById = newTickersById
          state.tickerIds = sortTickers(newTickersById, newTickerIds)
        },
        state.tickersById,
        newTickersById,
      )
      state.loading.tickerTable = false
    })
    builder.addCase(getTickerByExchangeThunk.rejected, (state) => {
      state.loading.tickerTable = false
    })
    // action update watchlist
    builder.addCase(updateWatchlistThunk.pending, (state) => {
      state.loading.watchlist = true
    })
    builder.addCase(updateWatchlistThunk.rejected, (state) => {
      state.loading.watchlist = false
    })
    // action create watchlist
    builder.addCase(createWatchlistThunk.pending, (state) => {
      state.loading.watchlist = true
    })
    builder.addCase(createWatchlistThunk.rejected, (state) => {
      state.loading.watchlist = false
    })
    // action update favorite
    builder.addCase(updateFavoriteThunk.pending, (state) => {
      state.loading.watchlist = true
    })
    builder.addCase(updateFavoriteThunk.rejected, (state) => {
      state.loading.watchlist = false
    })

    builder.addCase(filerMyWatchListThunk.pending, (state) => {})
    builder.addCase(filerMyWatchListThunk.fulfilled, (state, action) => {
      state.listType = action.payload
    })
    builder.addCase(filerMyWatchListThunk.rejected, (state) => {
      state.listType = []
    })
  },
})

export const selectIsShowPopup = (state) => state[slice.name].isShowPopup

export const selectWatchlistsCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].watchlistsById[id], attr)
export const selectWatchlistsById = (state) => state[slice.name].watchlistsById
export const selectWatchlistIds = (state) => state[slice.name].watchlistIds

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectChecked = (state) => state[slice.name].checkedWl

export const selectEditKey = (id) => (state) =>
  state[slice.name].editState[id].editKey

export const selectIsShowTickerFilter = (state) =>
  state[slice.name].isShowTickerFilter

export const selectTickersCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tickersById[id], attr)
export const selectTickersById = (state) => state[slice.name].tickersById
export const selectTickerIds = (state) => state[slice.name].tickerIds

export const selectIsShowWatchlistDetail = (state) =>
  state[slice.name].isShowWatchlistDetail
export const selectWatchlistDetail = (state) =>
  state[slice.name].watchlistDetail

export const selectTickerTableFilter = (state) =>
  state[slice.name].tickerTableFilter

export const selectTickersFilter = (state) => state[slice.name].tickersFilter
export const selectListType = (state) => state[slice.name].listType
export const selectTickerId = (state) => state[slice.name].tickerId

export const {
  resetWatchlistStore,
  setIsShowPopupBond,
  addChecked,
  addCheckedAll,
  unchecked,
  uncheckedAll,
  addEditKey,
  hideEditWatchlist,
  setIsShowTickerFilter,
  changeWatchlistIds,
  sortWatchlist,
  changeTickerIds,
  changeTickerId,
  changeTickersById,
  sortTicker,
  resetWatchlistDetail,
  changeTickerTableFilter,
  setIsShowWatchlistDetail,
  changeTickers,
  updateWatchListDetail,
} = slice.actions

register('watchlistBond', slice.reducer)
