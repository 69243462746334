import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { sumBy } from 'lodash'
import InterconnectionMapSv from '../../../../../../../core/services/bond/interconnectionMap'

export const getDataThunk = createAsyncThunk(
  'bond/interConnectionMap/infomation/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InterconnectionMapSv.getDataList(params)
      if (response.success) {
        const data = response?.data
          ? [
              ...response?.data,
              {
                bondId: 'total',
                outstandingValue: sumBy(
                  response?.data,
                  (e) => e?.outstandingValue || 0,
                ),
              },
            ]
          : []
        return data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
