import { useSelector } from 'react-redux'
import { OperatingSaleChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectTimeType } from './store/slice'

export const PanelOperatingSale = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const timeType = useSelector(selectTimeType)

  return (
    <Panel
      title={'sector.financialAnalysis.security.performance.OPERATING_SALE'}
      panelRefs={panelRefs}
      panelKey={mapKey.OPERATING_SALE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'operatingSaleChart',
        nameScreen: UseI18n('sector.financialAnalysis.security.PERFORMANCE'),
        chartName: UseI18n(
          'sector.financialAnalysis.security.performance.OPERATING_SALE',
        ),
        tabName: timeType,
        tickerName: currentInfo.ticker,
      }}
      titleJpg={UseI18n(
        'sector.financialAnalysis.security.performance.OPERATING_SALE',
      )}
    >
      <OperatingSaleChart
        width={formatNumber(sizes[mapKey.OPERATING_SALE].width - PANEL_PADDING)}
        height={formatNumber(
          sizes[mapKey.OPERATING_SALE].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
