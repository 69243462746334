import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../../common/html/Input'
import SelectCustom from '../../../../common/selectCustom'
import { Z_INDEX } from '../../../../constants/Common'
import style from '../../index.module.css'
import {
  changeWatchlistInformation,
  selectWatchlistInformation,
  selectWatchlists,
} from '../../store/slice'

export const listRadio = {
  UPDATE: 'update',
  CREATE: 'create',
}

export const keyWatchlistInformation = {
  TYPE: 'type',
  WATCHLIST_NAME: 'watchListName',
  WATCHLIST_ID: 'watchListId',
}

const WatchlistInformation = () => {
  const dispatch = useDispatch()

  const watchlists = useSelector(selectWatchlists)
  const watchlistInformation = useSelector(selectWatchlistInformation)

  const [listSelect, setListSelect] = useState([])

  useEffect(() => {
    setListSelect(
      [
        { value: '', name: I18n.t('tool.smartScreening.CHOOSE_WATCHLIST') },
      ].concat(
        watchlists.map((item) => {
          return { value: item.watchListId, name: item.watchListName }
        }),
      ),
    )
  }, [watchlists])

  useEffect(() => {
    dispatch(
      changeWatchlistInformation({
        key: keyWatchlistInformation.TYPE,
        value: listRadio.CREATE,
      }),
    )
    dispatch(
      changeWatchlistInformation({
        key: keyWatchlistInformation.WATCHLIST_NAME,
        value: 'Watchlist 1',
      }),
    )
    dispatch(
      changeWatchlistInformation({
        key: keyWatchlistInformation.WATCHLIST_ID,
        value: '',
      }),
    )
  }, [])

  const handleChangeRadio = (e, value) => {
    if (e.target.checked) {
      dispatch(
        changeWatchlistInformation({
          key: keyWatchlistInformation.TYPE,
          value,
        }),
      )
    }
  }

  const handleChangeSelect = (value) => {
    dispatch(
      changeWatchlistInformation({
        key: keyWatchlistInformation.WATCHLIST_ID,
        value,
      }),
    )
  }

  const handleChangeInput = (e) => {
    dispatch(
      changeWatchlistInformation({
        key: keyWatchlistInformation.WATCHLIST_NAME,
        value: e.target.value,
      }),
    )
  }

  const type = watchlistInformation?.[keyWatchlistInformation.TYPE]
  const id = watchlistInformation?.[keyWatchlistInformation.WATCHLIST_ID]
  const name = watchlistInformation?.[keyWatchlistInformation.WATCHLIST_NAME]

  const isCreate = type === listRadio.CREATE

  return (
    <div className={`d-flex ${style.mb16px}`}>
      <div className={`${style.w50}`}>
        <label className={`d-flex ali-center ${style.mb13px}`}>
          <div className={style.w25px}>
            <input
              type="radio"
              className={`radiobox radiobox2 ${style.minWidth10px} mr-0`}
              onChange={(e) => handleChangeRadio(e, listRadio.UPDATE)}
              checked={!isCreate}
            />
          </div>
          <Translate
            value="tool.smartScreening.UPDATE_WATCHLIST"
            className={!isCreate ? style.textActive : ''}
          />
        </label>
        <div className={`${style.w50} ${style.ml25px}`}>
          <SelectCustom
            selectData={listSelect}
            value={id || ''}
            handleChange={handleChangeSelect}
            isDisable={isCreate}
            zIndexDropdown={Z_INDEX.COMMON_CSS_OVERLAY + 1}
            zIndexTextEllipsis={Z_INDEX.COMMON_CSS_OVERLAY + 2}
            fontSizeItem={isCreate ? 10 : 12}
            appendStyle={isCreate ? { fontStyle: 'italic' } : undefined}
          />
        </div>
      </div>
      <div className={`${style.w50}`}>
        <label className={`d-flex ali-center ${style.mb13px}`}>
          <div className={style.w25px}>
            <input
              type="radio"
              className={`radiobox radiobox2 ${style.minWidth10px} mr-0`}
              onChange={(e) => handleChangeRadio(e, listRadio.CREATE)}
              checked={isCreate}
            />
          </div>
          <Translate
            value="tool.smartScreening.CREATE_WATCHLIST"
            className={isCreate ? style.textActive : ''}
          />
        </label>
        <div className={style.pl25px}>
          <Input
            type="text"
            className={`bg-grey h-20 ${style.input} ${
              !isCreate ? style.disabledInput : ''
            }`}
            readOnly={!isCreate}
            value={name || ''}
            onChange={handleChangeInput}
          />
        </div>
      </div>
    </div>
  )
}

export default WatchlistInformation
