import { CellWithColor } from '../../../../common/table/CellWithColor'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { TABLE_COL_KEYS, TABLE_FUND_INDEX_KEYS } from '../constants'

export const fundIndexSchema = ({ locale }) => {
  return TABLE_FUND_INDEX_KEYS.map((item) => {
    const result = {
      ...item,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        fontSize: 12,
        fontWeight: 600,
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
      return {
        ...result,
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_FOREIGN_OWNED,
        TABLE_COL_KEYS.COL_MAX_DRAWDOWN,
        TABLE_COL_KEYS.COL_STD_1Y,
        TABLE_COL_KEYS.COL_STD_3Y,
        TABLE_COL_KEYS.COL_TRACKING_ERROR_1Y,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    if (
      [TABLE_COL_KEYS.COL_SHARPE_1Y, TABLE_COL_KEYS.COL_SHARPE_3Y].includes(
        item.colId,
      )
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor val={val} format={(val) => formatVal(val)} />
        ),
      }
    }

    return {
      ...result,
      render: (val) => (
        <CellWithColor val={val} format={(val) => formatVal(val)} />
      ),
    }
  })
}
