import { maxBy } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import SelectCustom from '../../../common/selectCustom'
import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { WATCHLIST_TYPES } from '../constants'
import {
  selectDataBondInformation,
  selectWatchlistBondInformation,
} from '../store/slice'
import style from './index.module.css'

const PopupAddToWatchlist = ({ handleClose, handleCreate, handleUpdate }) => {
  const watchList = useSelector(selectWatchlistBondInformation)
  const { data } = useSelector(selectDataBondInformation)
  const locale = useSelector((state) => state.i18n.locale)

  const [radio, setRadio] = useState(WATCHLIST_TYPES.CREATE)
  const [id, setId] = useState(null)
  const [name, setName] = useState()
  const [tickers, setTickers] = useState([])
  const [list, setList] = useState([])

  const handleChangeRadio = (value) => {
    setRadio(value)
  }

  const handleChangeSelect = (value) => {
    setId(value)
  }

  const handleChangeInput = (e) => {
    setName(e?.target?.value)
  }

  const handleChangeChecked = (item) => {
    const index = tickers.findIndex((o) => o.bondId === item.bondId)

    if (index !== -1) {
      setTickers([...tickers.slice(0, index), ...tickers.slice(index + 1)])
      return
    }

    setTickers([
      ...tickers,
      {
        bondId: item.bondId,
        isUserBond: item.isUserBond ?? false,
      },
    ])
  }

  const handleSubmit = () => {
    radio === WATCHLIST_TYPES.CREATE
      ? handleCreate(name, tickers)
      : handleUpdate(id, tickers)
  }

  const textPlaceholderWatchlist = useMemo(
    () => I18n.t('bond.portfolio.bondInformation.CHOOSE_WATCHLIST'),
    [locale],
  )

  useEffect(() => {
    const newData = data.filter(
      (item, index, self) =>
        index === self.findIndex((e) => e.bondId === item.bondId) &&
        item.id !== 'total',
    )

    setList(newData)
    setTickers(
      newData.map((item, index) => ({
        bondId: item.bondId,
        isUserBond: item.isUserBond ?? false,
      })),
    )
  }, [data])

  useEffect(() => {
    const defaultName = I18n.t('bond.portfolio.bondInformation.WATCHLIST', {
      number: '',
    }).trim()

    const newWatchlist = watchList
      .filter((item) => item?.bondWatchListName?.trim().includes(defaultName))
      .map((item) => ({
        ...item,
        bondWatchListName: !isNaN(
          parseInt(item?.bondWatchListName?.slice(defaultName?.length)?.trim()),
        )
          ? parseInt(
              item?.bondWatchListName?.slice(defaultName?.length)?.trim(),
            )
          : null,
      }))
      .filter(
        (item) => item?.bondWatchListName && !isNaN(item?.bondWatchListName),
      )

    const max = maxBy(newWatchlist, (item) => item?.bondWatchListName)

    if (!newWatchlist?.length || !max) {
      setName(
        I18n.t('bond.portfolio.bondInformation.WATCHLIST', {
          number: 1,
        }),
      )

      return
    }

    setName(
      I18n.t('bond.portfolio.bondInformation.WATCHLIST', {
        number: max?.bondWatchListName + 1,
      }),
    )
  }, [watchList])

  return (
    <Popup>
      <div className="modal modal-large tool-page">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.smartScreening.CREATE_WATCHLIST_2" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div
            className="d-flex ali-center"
            style={{ gap: 8, marginBottom: 16 }}
          >
            <div style={{ flex: 1 }}>
              <label
                className="ali-center cursor-pointer"
                style={{ marginBottom: 12, gap: 12, display: 'inline-flex' }}
              >
                <div style={{ flexShrink: 0 }}>
                  <input
                    type="radio"
                    className="radiobox radiobox2 mr-0"
                    onChange={(e) => handleChangeRadio(WATCHLIST_TYPES.UPDATE)}
                    checked={radio === WATCHLIST_TYPES.UPDATE}
                  />
                </div>
                <Translate
                  value="tool.smartScreening.UPDATE_WATCHLIST"
                  className={
                    radio === WATCHLIST_TYPES.UPDATE ? style.textActive : ''
                  }
                />
              </label>
              <div style={{ width: '50%', marginLeft: 25 }}>
                <SelectCustom
                  selectData={watchList.map((item) => ({
                    name: item.bondWatchListName,
                    value: item.bondWatchListId,
                  }))}
                  value={id}
                  handleChange={handleChangeSelect}
                  isDisable={radio === WATCHLIST_TYPES.CREATE}
                  zIndexDropdown={Z_INDEX.COMMON_CSS_OVERLAY + 1}
                  zIndexTextEllipsis={Z_INDEX.COMMON_CSS_OVERLAY + 2}
                  textPlaceholder={textPlaceholderWatchlist}
                  appendStyle={{
                    opacity: radio === WATCHLIST_TYPES.CREATE ? 0.5 : 1,
                  }}
                />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <label
                className="ali-center cursor-pointer"
                style={{ marginBottom: 12, gap: 12, display: 'inline-flex' }}
              >
                <div style={{ flexShrink: 0 }}>
                  <input
                    type="radio"
                    className="radiobox radiobox2 mr-0"
                    onChange={(e) => handleChangeRadio(WATCHLIST_TYPES.CREATE)}
                    checked={radio === WATCHLIST_TYPES.CREATE}
                  />
                </div>
                <Translate
                  value="tool.smartScreening.CREATE_WATCHLIST"
                  className={
                    radio === WATCHLIST_TYPES.CREATE ? style.textActive : ''
                  }
                />
              </label>
              <div style={{ paddingLeft: 25 }}>
                <Input
                  type="text"
                  className={`bg-grey h-20 ${style.input} ${
                    radio === WATCHLIST_TYPES.UPDATE ? style.disabledInput : ''
                  }`}
                  readOnly={radio === WATCHLIST_TYPES.UPDATE}
                  value={name}
                  onChange={handleChangeInput}
                />
              </div>
            </div>
          </div>
          <div className="content">
            <div className="col w-100">
              <div
                className={`col-content ${style.colContent}`}
                style={{ height: 350 }}
              >
                <ScrollComponent>
                  <table className="w-100">
                    <tbody>
                      {list.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: 50 }}>{index + 1}</td>
                          <td>
                            <div
                              className="d-flex ali-center"
                              style={{ gap: 16 }}
                            >
                              <div style={{ width: 150 }}>
                                <TextEllipsis
                                  text={item?.ticker ?? ''}
                                  isI18n={false}
                                />
                              </div>
                              <TextEllipsis
                                text={item?.organizationShortName ?? ''}
                                isI18n={false}
                              />
                            </div>
                          </td>
                          <td style={{ width: 70 }}>
                            <div className="d-flex justify-content-center">
                              <p>
                                <input
                                  type="checkbox"
                                  className={`checkbox ${style.checkbox}`}
                                  checked={
                                    tickers.find(
                                      (o) => o.bondId === item.bondId,
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() => handleChangeChecked(item)}
                                />
                              </p>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollComponent>
              </div>
            </div>
          </div>
          <div className={`mt-8`} style={{ opacity: 0.4 }}>
            <Translate value="tool.smartScreening.SELECTED" />:{' '}
            {tickers?.length ?? 0}/{list?.length ?? 0}
          </div>
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              onClick={handleClose}
              className="btn normal w-80 h-20"
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              style={{ fontSize: 11 }}
              onClick={handleSubmit}
              className="btn btn-blue w-80 h-20"
            >
              {radio === WATCHLIST_TYPES.CREATE ? (
                <Translate value="tool.smartScreening.CREATE" />
              ) : (
                <Translate value="tool.smartScreening.UPDATE" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupAddToWatchlist
