import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../common/html/Span'
import { valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

export const PieChart = ({
  data,
  width,
  height,
  fillColor,
  pieKeys,
  otherKey,
  mappingDisplayName,
  isFooterColumn,
  isHideOtherKey,
}) => {
  // Get data
  const getFormatData = useMemo(() => {
    const dataArr = pieKeys
      .filter((key) => key !== otherKey)
      .filter((key) => data[key])
      .map((key) => ({
        text: mappingDisplayName[key],
        value: data[key],
        isI18n: true,
      }))

    if (otherKey && dataArr.length <= 5) {
      return [
        ...dataArr,
        {
          text: mappingDisplayName[otherKey],
          value:
            data[otherKey] ||
            1 -
              dataArr
                .map((item) => item.value)
                .reduce((prev, cur) => prev + cur, 0),
          isI18n: true,
        },
      ].map((item, index) => ({ ...item, color: fillColor[index] }))
    } else {
      const formatData = dataArr
        .sort((a, b) => b.value - a.value)
        .filter((_, index) => index < 5)
        .map((item, index) => ({ ...item, color: fillColor[index] }))

      const totalMaxData = formatData
        .map((item) => item.value)
        .reduce((prev, cur) => prev + cur, 0)

      if (totalMaxData < 1 && !isHideOtherKey) {
        formatData.push({
          text: 'sector.financialAnalysis.common.OTHER',
          value: 1 - totalMaxData,
          color: fillColor[fillColor.length - 1],
          isI18n: true,
        })
      }

      return formatData
    }
  }, [data])

  // Render tooltip
  const getTooltipContent = () => {
    return (
      <div className={style.chartTooltip}>
        <Span style={{ fontSize: 10 }} className={style.chartTooltipDate}>
          {data.label}
        </Span>
        <ul>
          {getFormatData.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  <Translate value={item.text} />:
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div className={style.chartWrapper}>
      <PieChartWithFooter
        data={getFormatData}
        height={height}
        width={width}
        footerRight={isFooterColumn}
        footerProps={
          isFooterColumn
            ? {
                isOneColumn: true,
                appendStyle: { gridGap: 0 },
                itemStyle: { paddingRight: 8 },
              }
            : {}
        }
        pieChartProps={isFooterColumn ? {} : { radius: 35 }}
        renderTooltip={isFooterColumn ? null : getTooltipContent}
        isUseContainerFooter={!isFooterColumn}
      />
    </div>
  )
}

PieChart.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  fillColor: PropTypes.array.isRequired,
  pieKeys: PropTypes.array.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
  isFooterColumn: PropTypes.bool,
  isHideOtherKey: PropTypes.bool,
}
