import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import style from '../../style.module.css'
import {
  addCompareChecked,
  removeCompareChecked,
  selectListCompareChecked,
} from './store/slice'

const ItemCompare = ({ item }) => {
  const dispatch = useDispatch()
  const listCompareChecked = useSelector(selectListCompareChecked)

  const onChange = (e) => {
    if (e.target.checked) {
      dispatch(addCompareChecked(item))
    } else {
      dispatch(removeCompareChecked(item))
    }
  }

  const handleChecked = () => {
    return listCompareChecked.some(
      (e) => e.tickerOrTaxCode === item.tickerOrTaxCode,
    )
  }

  const onRowClick = () => {
    const condition = listCompareChecked.some(
      (elm) => elm.organizationId === item.organizationId,
    )
    if (!condition) dispatch(addCompareChecked(item))
    else dispatch(removeCompareChecked(item))
  }

  return (
    <>
      <li className={style.paddingSearchTicker} onClick={onRowClick}>
        <input
          type="checkbox"
          className="checkbox"
          checked={handleChecked()}
          onChange={onChange}
        />
        <label className="cus--chec">
          <Span style={{ fontSize: 11 }}>{item?.tickerOrTaxCode}</Span>
        </label>
      </li>
    </>
  )
}

export default ItemCompare
