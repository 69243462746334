import moment from 'moment'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { FORMAT, MONTHS, QUARTERS } from '../../../../utils/Datetime'
import { valDivBillion } from '../../../../utils/Value'
import { renderName as renderNameHelper } from '../../issuers/synthetic/helper'
import {
  COUPON_TYPES,
  GROUP_COLUMNS,
  GROUP_ROWS,
  TIME_FREQUENCY,
  TIME_FREQUENCY_TRADING_VALUE,
} from './constants'
import style from './index.module.css'

export const renderName = (value) => {
  switch (value) {
    case 3:
      return 'bond.corporateBond.primaryMarket.ONE_TWO_THREE_YEARS'
    case 4:
      return 'bond.corporateBond.primaryMarket.THREE_TWO_FIVE_YEARS'
    case 5:
      return 'bond.corporateBond.primaryMarket.FIVE_TO_SEVEN_YEARS'
    case 6:
      return 'bond.corporateBond.primaryMarket.OVER_SEVEN_YEAR'
    default:
      return ''
  }
}

export const formatDataTable = (data) => {
  if (!data || !data.length) return []

  const array = []

  data.reverse().forEach((item) => {
    const {
      averageRemainDuration,
      totalValue,
      totalValuePublic,
      totalValuePrivate,
      icbId,
      icbName,
      remainLengthDurationId,
      remainLengthDuration,
      averageYTM,
      ...rest
    } = item

    array.push({
      ...rest,
      averageRemainDuration: averageRemainDuration,
      averageYTM: averageYTM * 100,
      totalValue: valDivBillion(totalValue),
      totalValuePublic: valDivBillion(totalValuePublic),
      totalValuePrivate: valDivBillion(totalValuePrivate),
      id: remainLengthDurationId ? `${remainLengthDurationId}-${icbId}` : icbId,
      name: remainLengthDurationId
        ? renderNameHelper(remainLengthDurationId)
        : icbName,
      level: remainLengthDurationId ? 2 : 1,
      parentId: icbId,
      childIds: remainLengthDurationId
        ? []
        : data
            .filter(
              (item) =>
                item.icbId === icbId && item.remainLengthDurationId != null,
            )
            .map((item) => `${item.remainLengthDurationId}-${item.icbId}`),
    })
  })

  return array
}

export const getRowSpanSchema = () => {
  return [
    [
      {
        colId: GROUP_COLUMNS[0].key,
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_COLUMNS[0].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
        colSpan: 1,
        thStyle: {
          textAlign: 'center',
          borderRight: '1px solid #212832',
          borderLeft: '1px solid #212832',
        },
        disableSort: true,
      },
      {
        colId: GROUP_COLUMNS[1].key,
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_COLUMNS[1].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
        colSpan: 3,
        thStyle: {
          textAlign: 'center',
          borderRight: '1px solid #212832',
          borderLeft: '1px solid #212832',
        },
        disableSort: true,
      },
      {
        colId: GROUP_COLUMNS[2].key,
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_COLUMNS[2].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
        colSpan: 3,
        thStyle: {
          textAlign: 'center',
          borderRight: '1px solid #212832',
          borderLeft: '1px solid #212832',
        },
        disableSort: true,
      },
      {
        colId: GROUP_COLUMNS[3].key,
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_COLUMNS[3].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
        colSpan: 3,
        thStyle: {
          textAlign: 'center',
          borderRight: '1px solid #212832',
          borderLeft: '1px solid #212832',
        },
        disableSort: true,
      },
      {
        colId: GROUP_COLUMNS[4].key,
        colSpan: 2,
        thStyle: {
          textAlign: 'center',
          borderLeft: '1px solid #212832',
        },
        renderTh: () => (
          <div style={{ textAlign: 'center' }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_COLUMNS[4].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
        disableSort: true,
      },
    ],
    [
      {
        title: GROUP_ROWS[0].title,
        colId: GROUP_ROWS[0].key,
        thStyle: {
          fontSize: 12,
          textAlign: 'left',
          fontWeight: 600,
          top: 27,
          borderRight: '1px solid #212832',
          borderLeft: '1px solid #212832',
        },
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_ROWS[0].title} />
              <div className={style.borderRight}></div>
            </Span>
          </div>
        ),
      },
      ...GROUP_ROWS.slice(1).map((item, index) => ({
        title: item.title,
        colId: item.key,
        thStyle: {
          fontSize: 12,
          textAlign: 'right',
          fontWeight: 600,
          top: 27,
          borderRight:
            index !== GROUP_ROWS.slice(1).length - 1
              ? '1px solid #212832'
              : 'none',
          borderLeft: '1px solid #212832',
        },
        renderTh: () => (
          <div>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
              }}
            >
              <Translate value={GROUP_ROWS.slice(1)[index].title} />
              {index !== GROUP_ROWS.slice(1).length - 1 && (
                <div className={style.borderRight}></div>
              )}
            </Span>
          </div>
        ),
      })),
    ],
  ]
}

export const renderDate = (timeType, locale, filter) => {
  const { month, quarter, year } = filter

  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return moment(
        `${month}-${year}`,
        FORMAT.MONTH_YEAR_NUMBER[locale],
      ).format(FORMAT.MONTH[locale])
    case TIME_FREQUENCY.QUARTER:
      return moment(year, FORMAT.YEAR[locale])
        .quarter(quarter)
        .format(FORMAT.QUARTER_YEAR[locale])
    default:
      return moment(year, FORMAT.YEAR[locale]).format(FORMAT.YEAR[locale])
  }
}

export const renderDateTime = ({ timeType, month, quarter, year }) => {
  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return `${I18n.t(
        MONTHS.find((item) => item.value === month).name,
      )}-${year}`
    case TIME_FREQUENCY.QUARTER:
      return `${I18n.t(
        QUARTERS.find((item) => item.value === quarter).name,
      )}-${year}`
    default:
      return year
  }
}

export const renderTitle = (date, timeType, locale) => {
  if (!date || !date.length) {
    return ''
  }

  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return moment(date, FORMAT.MONTH_YEAR_NUMBER[locale]).format(
        locale === 'vi'
          ? FORMAT.MONTH_YEAR_NUMBER[locale]
          : FORMAT.MONTH[locale],
      )
    case TIME_FREQUENCY.QUARTER:
      const dateArray = date.split('-')
      return moment(dateArray[1], FORMAT.YEAR[locale])
        .quarter(dateArray[0].slice(1))
        .format(FORMAT.QUARTER_YEAR[locale])

    default:
      return moment(date, FORMAT.YEAR[locale]).format(FORMAT.YEAR[locale])
  }
}

export const renderTimeType = (timeType, item) => {
  if (item) {
    if (
      timeType === TIME_FREQUENCY.MONTHLY &&
      parseInt(item.split('-')[0]) === new Date().getMonth() + 1 &&
      parseInt(item.split('-')[1]) === new Date().getFullYear()
    ) {
      return I18n.t('bond.corporateBond.issuers.MTD')
    }
    if (
      timeType === TIME_FREQUENCY.QUARTER &&
      parseInt(item.split('-')[0]) ===
        `Q${Math.floor(new Date().getMonth() / 3 + 1)}` &&
      parseInt(item.split('-')[1]) === new Date().getFullYear()
    ) {
      return I18n.t('bond.corporateBond.issuers.QTD')
    }
    if (
      timeType === TIME_FREQUENCY.YEARLY &&
      parseInt(item) === new Date().getFullYear()
    ) {
      return I18n.t('bond.corporateBond.issuers.YTD')
    }
    return ''
  } else {
    switch (timeType) {
      case TIME_FREQUENCY.MONTHLY:
        return I18n.t('bond.corporateBond.issuers.MTD')
      case TIME_FREQUENCY.QUARTER:
        return I18n.t('bond.corporateBond.issuers.QTD')
      default:
        return I18n.t('bond.corporateBond.issuers.YTD')
    }
  }
}

export const renderTitleAverage = (timeType) => {
  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return I18n.t('bond.corporateBond.primaryMarket.AVERAGE_MONTH')
    case TIME_FREQUENCY.QUARTER:
      return I18n.t('bond.corporateBond.primaryMarket.AVERAGE_QUARTER')
    default:
      return I18n.t('bond.corporateBond.primaryMarket.AVERAGE_YEAR')
  }
}

export const renderCouponName = (couponType) => {
  switch (couponType) {
    case COUPON_TYPES.OTHERS:
      return I18n.t('bond.corporateBond.primaryMarket.OTHERS')
    case COUPON_TYPES.FLOAT:
      return I18n.t('bond.corporateBond.primaryMarket.FLOAT')
    case COUPON_TYPES.ZERO:
      return I18n.t('bond.corporateBond.primaryMarket.ZERO')
    case COUPON_TYPES.MIXED:
      return I18n.t('bond.corporateBond.primaryMarket.MIXED')
    case COUPON_TYPES.FIXED:
      return I18n.t('bond.corporateBond.primaryMarket.FIXED')
    default:
      return undefined
  }
}
export const renderDateAdditional = (timeType, item) => {
  if (
    timeType === TIME_FREQUENCY_TRADING_VALUE.M &&
    +item.split('/')[0] === new Date().getMonth() + 1 &&
    +item.split('/')[1] === new Date().getFullYear()
  ) {
    return I18n.t('bond.corporateBond.issuers.MTD')
  }
  if (
    timeType === TIME_FREQUENCY_TRADING_VALUE.Q &&
    item.split('-')[0] === `Q${Math.floor(new Date().getMonth() / 3 + 1)}` &&
    +item.split('-')[1] === new Date().getFullYear()
  ) {
    return I18n.t('bond.corporateBond.issuers.QTD')
  }
  if (
    timeType === TIME_FREQUENCY_TRADING_VALUE.Y &&
    +item === new Date().getFullYear()
  ) {
    return I18n.t('bond.corporateBond.issuers.YTD')
  }
  return ''
}
