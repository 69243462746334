import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../../../../common/dropdown'
import { Span } from '../../../../../../common/html/Span'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../../../constants/Common'
import { handleTooltipTitle, MAX_ROW } from '../helper'
import style from '../index.module.css'

const ItemContent = ({
  item,
  position,
  size,
  isPositive,
  maxValue,
  timeRequest,
}) => {
  const parentRef = useRef()
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)
  const locale = useSelector((state) => state.i18n.locale)

  const openTooltip = () => setIsOpenTooltip(true)

  const closeTooltip = () => setIsOpenTooltip(false)

  return (
    <div className="d-flex" style={{ height: size.height / MAX_ROW }}>
      {!isPositive ? (
        <div
          className={[style.wrapItem, style.flexEnd].join(' ')}
          onMouseOver={openTooltip}
          onMouseOut={closeTooltip}
          ref={parentRef}
        >
          <div
            className={style.downBackground}
            style={{
              width: `calc(70% * ${Math.abs(item.value) / maxValue})`,
              alignSelf: 'flex-end',
            }}
          >
            <div
              className="h-100 w-100"
              style={{
                backgroundColor: '#e0505b',
              }}
            />
          </div>
          <div className={style.absoluteItem}>
            <div className={style.width70}>
              <TextEllipsis
                isI18n={false}
                text={item.title}
                className="canvas-chart-title"
              />
            </div>
            <div
              className={[style.width30, style.flexEnd, style['pr-8']].join(
                ' ',
              )}
            >
              <Span className="canvas-chart-title">{item.value}%</Span>
            </div>
          </div>
        </div>
      ) : (
        <div
          className={style.wrapItem}
          onMouseOver={openTooltip}
          onMouseOut={closeTooltip}
          ref={parentRef}
        >
          <div
            className={style.downBackground}
            style={{
              width: `calc(70% * ${Math.abs(item.value) / maxValue})`,
            }}
          >
            <div
              className="h-100 w-100"
              style={{
                backgroundColor: '#3da967',
              }}
            />
          </div>
          <div className={style.absoluteItem}>
            <div className={[style.width30, style['pl-8']].join(' ')}>
              <Span className="canvas-chart-title">{item.value}%</Span>
            </div>
            <div className={[style.width70, style.flexEnd].join(' ')}>
              <TextEllipsis
                isI18n={false}
                text={item.title}
                className="canvas-chart-title"
              />
            </div>
          </div>
        </div>
      )}
      <Dropdown
        parentRef={parentRef}
        isShow={isOpenTooltip}
        position={positionDropdown.TOP_CENTER}
        appendStyle={{ pointerEvents: 'none' }}
      >
        <div className={`box-notice ${style.tooltipContribution}`}>
          <div className="d-flex" style={{ width: '90%' }}>
            <Span className={style.titleTooltip} style={{ fontSize: 12 }}>
              <div className={style.pt12}>
                {item.title ? item.title.toUpperCase() : EMPTY_VALUE}
              </div>
            </Span>
            <div className={style.relative}>
              <i
                className={`icon-ranking-in-tooltip ${style.iconTooltip}`}
                style={{
                  color: !isPositive ? '#e0505b' : '#3da967',
                }}
              >
                <div className={style.whiteCircle}>
                  <div className={style.textIcon}>{position}</div>
                </div>
              </i>
            </div>
          </div>
          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="economy.productionAndConsumption.production.VALUE" />
              </Span>
            </label>
            <div>
              <Span
                style={{
                  fontSize: 11,
                  color: !isPositive ? '#e0505b' : '#3da967',
                  fontWeight: 500,
                }}
              >
                {item.value}%
              </Span>
            </div>
          </div>

          <div className={`d-flex j-b ${style.itemTooltip}`}>
            <label className={style.textTooltip}>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                <Translate value="economy.productionAndConsumption.production.PERIOD" />
              </Span>
            </label>
            <div>
              <Span
                style={{
                  fontSize: 11,
                  color: !isPositive ? '#e0505b' : '#3da967',
                  fontWeight: 500,
                }}
              >
                {handleTooltipTitle(timeRequest, locale)}
              </Span>
            </div>
          </div>
        </div>
      </Dropdown>
    </div>
  )
}

export default ItemContent
