import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Dropdown from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import style from './index.module.css'
import ItemScroll from './ItemScroll'
import { handleKeySearch } from './store/slice'
import { TABLE_INTEREST_ID } from './TableComponent'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const DropdownLocaleName = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listBank,
  exceptOutsideRef,
}) => {
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthCompanies = listBank?.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listBank[activeIndex])
      else if (listBank[0]) handleClickItem(listBank[0])
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthCompanies > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthCompanies - 1)
        setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCompanies > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthCompanies - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (bank) => {
    const scrollTable = document.querySelector(
      `#${TABLE_INTEREST_ID} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${bank.id}"]`)
    if (scrollTable && headerTable && activeRow)
      scrollTable.scrollTo({
        top: activeRow.offsetTop - headerTable.offsetHeight,
        behavior: 'smooth',
      })

    dispatch(handleKeySearch(bank.title))
    handleHide()
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listBank?.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listBank?.map((bank, index) => {
                return (
                  <div
                    key={bank.id}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(bank)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>{bank.title}</div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

export default DropdownLocaleName
