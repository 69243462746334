export const TABLE_TABS = {
  ALL: 'all',
  DETAIL: 'detail',
}

export const PERIOD_TYPES = {
  // MOST_RECENT: 'mostRecent',
  SELECT_TIME_PERIOD: 'selectTimePeriod',
}

export const TABLE_COL_KEYS = {
  COL_ALL_TICKER: 'ticker',
  COL_ALL_NAME: 'organizationShortName',
  COL_ALL_CLOSE_PRICE: 'closePrice',
  COL_ALL_PRICE_CHANGE_1M: 'totalPercentChange1MonthClosePriceAdjusted',
  COL_ALL_VOLUME: 'volume',
  COL_ALL_OWNERSHIP_TOTAL_SHARE: 'percentVolume',
  COL_ALL_TOTAL_VALUE: 'totalValue',
  COL_ALL_OWNERSHIP_CHANGE_VOLUME: 'changeVolume',
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M: 'changeVol1M',
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD: 'changeVolYTD',
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y: 'changeVol1Y',
  COL_ALL_OWNERSHIP_CHANGE_VALUE: 'changeValue',
  COL_ALL_OWNERSHIP_CHANGE_VALUE_1M: 'changeValue1M',
  COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD: 'changeValueYTD',
  COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y: 'changeValue1Y',
  COL_ALL_NUMBER_FUND_HOLDING: 'numberofFund',
  COL_DETAIL_TICKER: 'ticker',
  COL_DETAIL_NAME: 'organizationShortName',
  COL_DETAIL_INVESTMENT_STRATEGY: 'fundStructureName',
  COL_DETAIL_NAV_CCQ: 'naVperShareAdjusted',
  COL_DETAIL_UPDATE_DATE: 'endDateId',
  COL_DETAIL_NAV_CCQ_PERFORMANCE: 'navCcq',
  COL_DETAIL_NAV_CCQ_PERFORMANCE_1M: 'rfD0009',
  COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y: 'rfD0012',
  COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y: 'rfD0013',
  COL_DETAIL_FUND_HOLDING: 'fundHolding',
  COL_DETAIL_FUND_HOLDING_VOLUME: 'quantity',
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M: 'quantity1M',
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M: 'quantity3M',
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M: 'quantity6M',
  COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M: 'priceChange1M',
  COL_DETAIL_FUND_HOLDING_TOTAL_VALUE: 'marketValue',
  COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET: 'portfolioWeight',
  COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE: 'quantityOutstandingShare',
}

export const TABLE_COL_FORMATS = {
  COL_ALL_CLOSE_PRICE: 1,
  COL_ALL_PRICE_CHANGE_1M: 0.01,
  COL_ALL_VOLUME: 1,
  COL_ALL_OWNERSHIP_TOTAL_SHARE: 0.01,
  COL_ALL_TOTAL_VALUE: 10 ** 9,
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M: 1,
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD: 1,
  COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y: 1,
  COL_ALL_OWNERSHIP_CHANGE_VALUE_1M: 10 ** 9,
  COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD: 10 ** 9,
  COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y: 10 ** 9,
  COL_ALL_NUMBER_FUND_HOLDING: 1,
  COL_DETAIL_NAV_CCQ: 1,
  COL_DETAIL_NAV_CCQ_PERFORMANCE_1M: 0.01,
  COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y: 0.01,
  COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y: 0.01,
  COL_DETAIL_FUND_HOLDING_VOLUME: 1,
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M: 1,
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M: 1,
  COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M: 1,
  COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M: 0.01,
  COL_DETAIL_FUND_HOLDING_TOTAL_VALUE: 10 ** 9,
  COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET: 0.01,
  COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE: 0.01,
}

export const TABLE_ALL_KEYS = [
  'COL_ALL_TICKER',
  'COL_ALL_NAME',
  'COL_ALL_CLOSE_PRICE',
  'COL_ALL_PRICE_CHANGE_1M',
  'COL_ALL_VOLUME',
  'COL_ALL_OWNERSHIP_TOTAL_SHARE',
  'COL_ALL_TOTAL_VALUE',
  'COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M',
  'COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD',
  'COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y',
  'COL_ALL_OWNERSHIP_CHANGE_VALUE_1M',
  'COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD',
  'COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y',
  'COL_ALL_NUMBER_FUND_HOLDING',
].map((key) => ({
  title: 'fund.fundCenter.holding.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_ALL_TH_ROW_KEYS = [
  [
    'COL_ALL_TICKER',
    'COL_ALL_NAME',
    'COL_ALL_CLOSE_PRICE',
    'COL_ALL_PRICE_CHANGE_1M',
    'COL_ALL_VOLUME',
    'COL_ALL_OWNERSHIP_TOTAL_SHARE',
    'COL_ALL_TOTAL_VALUE',
    'COL_ALL_OWNERSHIP_CHANGE_VOLUME',
    'COL_ALL_OWNERSHIP_CHANGE_VALUE',
    'COL_ALL_NUMBER_FUND_HOLDING',
  ].map((key) => ({
    title: 'fund.fundCenter.holding.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
  [
    'COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M',
    'COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD',
    'COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y',
    'COL_ALL_OWNERSHIP_CHANGE_VALUE_1M',
    'COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD',
    'COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y',
  ].map((key) => ({
    title: 'fund.fundCenter.holding.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]

export const TABLE_DETAIL_KEYS = [
  'COL_DETAIL_TICKER',
  'COL_DETAIL_NAME',
  'COL_DETAIL_INVESTMENT_STRATEGY',
  'COL_DETAIL_NAV_CCQ',
  'COL_DETAIL_UPDATE_DATE',
  'COL_DETAIL_NAV_CCQ_PERFORMANCE_1M',
  'COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y',
  'COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y',
  'COL_DETAIL_FUND_HOLDING_VOLUME',
  'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M',
  'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M',
  'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M',
  // 'COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M',
  'COL_DETAIL_FUND_HOLDING_TOTAL_VALUE',
  'COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET',
  'COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE',
].map((key) => ({
  title: 'fund.fundCenter.holding.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_DETAIL_TH_ROW_KEYS = [
  [
    'COL_DETAIL_TICKER',
    'COL_DETAIL_NAME',
    'COL_DETAIL_INVESTMENT_STRATEGY',
    'COL_DETAIL_NAV_CCQ',
    'COL_DETAIL_UPDATE_DATE',
    'COL_DETAIL_NAV_CCQ_PERFORMANCE',
    'COL_DETAIL_FUND_HOLDING',
  ].map((key) => ({
    title: 'fund.fundCenter.holding.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
  [
    'COL_DETAIL_NAV_CCQ_PERFORMANCE_1M',
    'COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y',
    'COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y',
    'COL_DETAIL_FUND_HOLDING_VOLUME',
    'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M',
    'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M',
    'COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M',
    // 'COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M',
    'COL_DETAIL_FUND_HOLDING_TOTAL_VALUE',
    'COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET',
    'COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE',
  ].map((key) => ({
    title: 'fund.fundCenter.holding.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]
