import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchFunction } from '../store/slice'
import { getSearchFunctionData } from '../store/thunk'
import { FuncFunctionsItem } from './FuncFunctionsItem'

export const FuncFunctions = (props) => {
  const { searchStr, open, onClickCollapse } = props
  const dispatch = useDispatch()
  const functions = useSelector(selectSearchFunction)

  const onClickPrevPage = () => {
    if (functions.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchFunctionData({
          Term: searchStr,
          Page: functions.data.page - 1,
          PageSize: functions.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (functions.data.page === functions.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchFunctionData({
          Term: searchStr,
          Page: functions.data.page + 1,
          PageSize: functions.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="func-func"
      title={`${UseI18n('search.fullSearch.FUNC_FUNCTIONS')} (${
        functions.data.totalRecords
      })`}
      blockData={functions}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!functions.data.items.length &&
        functions.data.items.map((item) => (
          <FuncFunctionsItem
            key={item.functionId}
            searchStr={searchStr}
            data={item}
          />
        ))}
    </SearchCollapseBlock>
  )
}

FuncFunctions.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
