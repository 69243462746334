import { GROUP_BY, VIEW_BY } from '../constant'

export const stickyColumnsConstants = {
  [VIEW_BY.CORPORATE]: ['TICKER', 'COMPANY_NAME', 'DATE'],
  [VIEW_BY.INDEX_AND_SECTOR]: ['NAME', 'DATE'],
  [GROUP_BY.COMPANY]: ['DATE'],
  [GROUP_BY.INDICATOR]: ['DATE'],
}

export const stickyColumnsKey = {
  TICKER: 'ticker',
  DATE: 'tradingDate',
  COMPANY_NAME: 'name',
  NAME: 'name',
}

export const stickyColumnsMappingDisplay = {
  TICKER: 'tool.dataExplorer.tradingData.TICKER',
  DATE: 'tool.dataExplorer.tradingData.DATE',
  COMPANY_NAME: 'tool.dataExplorer.tradingData.COMPANY_NAME',
  NAME: 'tool.dataExplorer.tradingData.NAME',
}

export const widthStickyColumn = {
  TICKER: 60,
  DATE: 90,
  COMPANY_NAME: 190,
  NAME: 190,
  OTHER: 180,
}
