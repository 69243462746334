import IndicatorSearch from './IndicatorSearch'
import IndicatorSelect from './IndicatorSelect'

const ListIndicator = ({
  viewType,
  isEquationTypeCount,
  indicatorsLatestPeriod,
}) => {
  return (
    <div style={{ marginTop: 24 }}>
      <IndicatorSearch isEquationTypeCount={isEquationTypeCount} />
      <IndicatorSelect
        viewType={viewType}
        isEquationTypeCount={isEquationTypeCount}
        indicatorsLatestPeriod={indicatorsLatestPeriod}
      />
    </div>
  )
}

export default ListIndicator
