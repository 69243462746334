import { SizeTracker } from '../../../../common/sizeTracker'
import Header from './Header'
import TableComponent from './TableComponent'

const Container = ({ height }) => {
  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header />
            {typeof sizes.height === 'number' && (
              <TableComponent height={height - sizes.height} />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
