import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import {
  getPriceColor,
  getThreeColorByValue,
} from '../../../../../components/utils/Color'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import useHeightTdTable from '../../../../common/hooks/useHeightTdTable'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { PriceIcon } from '../../../../common/priceIcon'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import {
  formatVal,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import { PriceRate } from './PriceRate'
import { selectData, selectLoading } from './store/slice'
import { getCoveredWarrantSummary } from './store/thunk'
import style from './summary.module.css'

const AVERAGE_UNDERLYING_ASSET_DAYS = 5
const Td = ({ children, ...props }) => {
  const heightTd = useHeightTdTable()

  return (
    <td {...props} style={{ ...props.style, height: heightTd }}>
      {children}
    </td>
  )
}

const renderItem = ({
  label,
  value,
  format = (value) => value,
  priceColor,
}) => {
  return (
    <>
      <Td className={[style.bRight0, style.fw440].join(' ')}>
        <Translate value={label} />
      </Td>
      <Td
        className={['t-right', style.bLeft0].join(' ')}
        style={{ color: priceColor }}
      >
        {format(value)}
      </Td>
    </>
  )
}

const priceOfUnderlyingAssetCell = (data, priceColor) => {
  return data?.matchPrice ? (
    <>
      <span style={{ color: priceColor }}>
        {formatVal(valDivThousand(data?.matchPrice))}
      </span>
      &nbsp;
      <PriceIcon
        item={{
          isTicker: true,
          matchPrice: data?.matchPrice,
          referencePrice: data?.referencePrice,
          ceilingPrice: data?.ceilingPrice,
          floorPrice: data?.floorPrice,
        }}
      />
      &nbsp;
      <span style={{ color: priceColor }}>
        {valToPercent(data?.percentPriceChange)}
      </span>
    </>
  ) : (
    <>{EMPTY_VALUE}</>
  )
}

export const Summary = () => {
  const dispatch = useDispatch()

  const topInfo = useSelector(selectWarrantInfo)
  const isLoading = useSelector(selectLoading)
  const data = useSelector(selectData)
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()

  useEffect(() => {
    if (topInfo.coveredWarrantId) {
      dispatch(
        getCoveredWarrantSummary({
          coveredWarrantId: topInfo.coveredWarrantId,
        }),
      )
    }
  }, [topInfo.coveredWarrantId, locale])

  const priceColor = getPriceColor(
    data?.matchPrice,
    data?.referencePrice,
    data?.ceilingPrice,
    data?.floorPrice,
  )

  const findItemByKey = (key, data) => {
    if (key === 'maturityDateId' || key === 'lastTradingDateId') {
      return `${formatDateTime(
        key === 'maturityDateId'
          ? data['maturityDateId']
          : data['lastTradingDateId'],
        FORMAT.DATE,
        locale,
        timeZone,
      )}`
    } else if (key === 'durationId') {
      return [
        data.durationId,
        I18n.t('market.coveredWarrant.summary.DAYS'),
      ].join('')
    }
    return data[key] ?? EMPTY_VALUE
  }

  const tickerTooltip = (val) => {
    const organizationId = data?.underlyingId
    return val !== EMPTY_VALUE ? (
      <span>
        {organizationId ? (
          <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
        ) : (
          val
        )}
      </span>
    ) : (
      <></>
    )
  }

  const avgPriceUnderlyingAsset5days =
    (findItemByKey('totalPrice4Days', data) +
      findItemByKey('matchPrice', data)) /
      AVERAGE_UNDERLYING_ASSET_DAYS ?? EMPTY_VALUE

  const estCWPriceMaturity =
    (avgPriceUnderlyingAsset5days -
      findItemByKey('adjustedExercisePrice', data)) /
      (findItemByKey('adjustedExecutionRateCoveredWarrant', data) /
        findItemByKey('adjustedExecutionRateUnderlying', data)) ?? EMPTY_VALUE

  return isLoading ? (
    <Loading />
  ) : data ? (
    <>
      <SizeTracker>
        {(size) => (
          <>
            <PriceRate />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <ScrollComponent>
                  <table className={`two-column-table ${style.table}`}>
                    <tbody>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.CW_ISSUER',
                          value: findItemByKey('organizationShortName', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.CW_TYPE',
                          value: findItemByKey('optionTypeName', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.EXERCISE_STYLE',
                          value: findItemByKey('optionStyleName', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.EXERCISE_PRICE',
                          value: findItemByKey('exercisePrice', data),
                          format: (val) => formatVal(valDivThousand(val)),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.CONVERSION_RATIO',
                          value: findItemByKey('coversionRatio', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.UNDERLYING_ASSET',
                          value: tickerTooltip(findItemByKey('ticker', data)),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.PRICE_OF_UNDERLYING_ASSET',
                          value: priceOfUnderlyingAssetCell(data, priceColor),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.AVG_5_DAYS',
                          value: avgPriceUnderlyingAsset5days,
                          format: (val) => formatVal(valDivThousand(val)),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.EST_CW_PRICE',
                          value: estCWPriceMaturity,
                          format: (val) => formatVal(valDivThousand(val)),
                          priceColor: getThreeColorByValue(estCWPriceMaturity),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.SETTLEMENT_STATUS',
                          value: findItemByKey('settlementMethodName', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.BREAK_EVEN_POINT',
                          value: findItemByKey('breakEvenPoint', data),
                          format: (val) => formatVal(valDivThousand(val)),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label:
                            'market.coveredWarrant.summary.ISSUED_QUANTITY',
                          value: findItemByKey('outstandingShare', data),
                          format: (val) => formatVal(valDivThousand(val)),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.MATURITY_DATE',
                          value: findItemByKey('maturityDateId', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.EXPIRY_DATE',
                          value: findItemByKey('lastTradingDateId', data),
                        })}
                      </tr>
                      <tr>
                        {renderItem({
                          label: 'market.coveredWarrant.summary.DURATION',
                          value: findItemByKey('durationId', data),
                        })}
                      </tr>
                    </tbody>
                  </table>
                </ScrollComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </>
  ) : (
    <NoData />
  )
}
