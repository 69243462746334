import { TYPE } from '../../../../../common/chart/footer/Item'

const colorsPieChart = {
  color: ['#45b29d', '#facc5c', '#df5a49', '#185999', '#0096eb'],
  other: '#ecffdf',
}

export const getFooterData = (chartData) => {
  return chartData.map((item) => ({
    text: item.text,
    type: TYPE.CIRCLE,
    before: {
      bgColor: item.color,
    },
  }))
}

export const formatChartData = (chartData) => {
  return chartData.map((item, index) => {
    if (item?.ticker === 'Khác' || item?.ticker === 'Other') {
      return {
        value: item?.value1,
        color: colorsPieChart.other,
        text: item?.ticker,
      }
    }
    return {
      value: item?.value1,
      color: colorsPieChart.color[index],
      text: item?.ticker,
    }
  })
}
