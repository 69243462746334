import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import Unit from '../../common/Unit'
import { VALUE_MOM_YOY_YTD } from '../../constants'
import {
  getValueMOM,
  handleIsAllowCallApi,
  handleValueMOM,
} from './store/slice'

const Header = () => {
  const dispatch = useDispatch()

  const valueMOM = useSelector(getValueMOM)

  const changeTabValueMOM = (tab) => {
    dispatch(handleValueMOM(tab.value))
    dispatch(handleIsAllowCallApi(true))
  }

  const renderUnit = (valueMoM) => {
    if (valueMoM === 'Value') {
      return 'economy.monetary.monetary.UNIT_TRILLION'
    }

    return 'economy.monetary.monetary.UNIT_PERCENT'
  }

  return (
    <div className="d-flex align-center j-b mb-8">
      <Unit title={renderUnit(valueMOM)} />
      <DispatchActionTab
        data={VALUE_MOM_YOY_YTD}
        activeTab={valueMOM}
        onChangeTab={changeTabValueMOM}
      />
    </div>
  )
}

export default Header
