import { createAsyncThunk } from '@reduxjs/toolkit'
import CPIAndInflationService from '../../../../../core/services/economy/CPIAndInflationService'
import { handleExport } from '../../../../utils/Export'

export const cpiAndInflationTableContentFirstRender = createAsyncThunk(
  'economy/cpiAndInflation/cpiAndInflationTableContentFirstRender',
  async (params, { rejectWithValue }) => {
    const response =
      await CPIAndInflationService.getCPIAndInflationTableContent(params)
    const responseName = await CPIAndInflationService.getCPIAndInflationTable({
      CPIVNTypeCode: params.CPIVNTypeCode,
    })

    if (response.success && responseName.success) {
      return {
        data: response.data,
        nameData: responseName.data,
      }
    }

    return rejectWithValue(response.errors)
  },
)

export const cpiAndInflationTableContent = createAsyncThunk(
  'economy/cpiAndInflation/cpiAndInflationTableContent',
  async (params, { rejectWithValue }) => {
    const response =
      await CPIAndInflationService.getCPIAndInflationTableContent(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadCPIAndInflationTableContent = createAsyncThunk(
  'economy/cpiAndInflation/downloadCPIAndInflationTableContent',
  async (data) => {
    const response =
      await CPIAndInflationService.downloadCPIAndInflationTableContent(data)
    handleExport(response.data, response.filename)
  },
)
