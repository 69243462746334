import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import {
  DEFAULT_SECTOR_LEVEL2_EN,
  DEFAULT_SECTOR_LEVEL2_VN,
} from '../constants'
import { getLatestPrice } from './thunk'

export const defaultState = {
  topInfo: { en: DEFAULT_SECTOR_LEVEL2_EN, vi: DEFAULT_SECTOR_LEVEL2_VN },
  priceData: {},
  loadingSearch: false,
}

const initialState = {
  topInfo: {
    icbCode: '',
    icbId: '',
    icbLevel: '',
    icbName: '',
    parentICBId: '',
  },
  priceData: {},
  loadingSearch: false,
}

const slice = createSlice({
  name: 'common/sectorTopInfo',
  initialState,
  reducers: {
    resetDefault(state, action) {
      state.topInfo = defaultState.topInfo[action.payload]
    },
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeSector: (state, action) => {
      state.topInfo = action.payload
    },
    changeLoadingSearch(state, action) {
      state.loadingSearch = action.payload
    },
    subscribeSector: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      } else {
        state.priceData = defaultState.priceData
      }
    })
  },
})

export const selectSectorInfo = (state) => state[slice.name].topInfo
export const selectPriceData = (state) => state[slice.name].priceData
export const selectLoadingSearch = (state) => state[slice.name].loadingSearch

export const {
  resetDefault,
  resetStore,
  changeSector,
  subscribeSector,
  changeLoadingSearch,
} = slice.actions

register(slice.name, slice.reducer)
