import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { resetFilter } from '../filter/FilterOption/store/slice'
import { filterItems } from './helper'
import {
  filterByText,
  selectListCompany,
  selectListIdSave,
  selectListIdSelect,
  updaetListIdSave,
  updateListId,
  updateListNodeHide,
  updateVisible,
} from './store/slice'

const Footer = () => {
  const dispatch = useDispatch()
  const dataCompany = useSelector(selectListCompany)
  const idsSelect = useSelector(selectListIdSelect)
  const idsSave = useSelector(selectListIdSave)

  const cancelPopup = () => {
    dispatch(filterByText(''))
    const check = idsSelect.some((e) => idsSave.includes(e))
    if (
      idsSelect.length !== idsSave.length ||
      (idsSelect.length === idsSave.length && !check)
    ) {
      dispatch(updateListId(idsSave))
    }
    dispatch(updateVisible(false))
  }

  const changeApply = () => {
    const nodesHide = filterItems(Object.values(dataCompany), idsSelect)

    dispatch(resetFilter())
    dispatch(updateListNodeHide(nodesHide))
    dispatch(updaetListIdSave(idsSelect))
    dispatch(updateVisible(false))
    dispatch(filterByText(''))
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'end',
        padding: '12px 8px 8px 0px',
        gap: '8px',
      }}
    >
      <Button
        className={`btn normal w-80 h-20`}
        style={{ fontSize: 11, fontWeight: 500, color: '#FFF' }}
        onClick={() => cancelPopup()}
      >
        <Translate value="bond.interconnectionMap.businessInformation.btnCancel" />
      </Button>
      <Button
        className={`btn btn-blue w-80 h-20`}
        style={{ fontSize: 11, fontWeight: 500, color: '#FFF' }}
        onClick={() => changeApply()}
      >
        <Translate value="bond.interconnectionMap.businessInformation.btnApply" />
      </Button>
    </div>
  )
}

export default Footer
