import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class TradingInformationProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('DetailTradingInformation', params)
  }
  getCompanyRatingProxy(params) {
    return this.get('CompanyRatingList', params)
  }
  exportFileProxy(params) {
    return this.get('TradingInformationExport', params, {}, 'download')
  }
}

export default new TradingInformationProxy(
  ServiceProxyConfig.Bond.SecurityMasterCb.ServiceUrl,
)
