import React, { useLayoutEffect, useRef, useState } from 'react'

const SizeComponent = ({ children }) => {
  const ref = useRef(null)

  const [height, setHeight] = useState()
  useLayoutEffect(() => {
    setHeight(ref?.current?.getBoundingClientRect()?.height)
  }, [])

  return (
    <div ref={ref} style={{ height: height }}>
      {children}
    </div>
  )
}

export default SizeComponent
