export const chartAttr = {
  buyPercentage: {
    name: 'corporate.peerValuation.consensus.BUY_PERCENTAGE',
    latest: 'corporate.peerValuation.consensus.BUY',
    color: '#3da967',
  },
  sellPercentage: {
    name: 'corporate.peerValuation.consensus.SELL_PERCENTAGE',
    latest: 'corporate.peerValuation.consensus.SELL',
    color: '#e0505b',
  },
  holdPercentage: {
    name: 'corporate.peerValuation.consensus.HOLD_PERCENTAGE',
    latest: 'corporate.peerValuation.consensus.HOLD',
    color: '#5aea78',
  },
  neutralPercentage: {
    name: 'corporate.peerValuation.consensus.NEUTRAL_PERCENTAGE',
    latest: 'corporate.peerValuation.consensus.NEUTRAL',
    color: '#fdf0a5',
  },
  noRecommendationPercentage: {
    name: 'corporate.peerValuation.consensus.NO_RECOMMENDATION_PERCENTAGE',
    latest: 'corporate.peerValuation.consensus.NO_RECOMMENDATION',
    color: '#f58d04',
  },
}
