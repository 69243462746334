export const areArraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    const obj1 = arr1[i]
    const obj2 = arr2[i]

    if (obj1.tradingDateId !== obj2.tradingDateId) {
      return false
    }
  }

  return true
}
