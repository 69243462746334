import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import MessageHub from '../../../../core/signalr/SignalrMessageHub'

export const RealtimeIndexCustom = ({
  children,
  channel,
  action,
  derivativeId,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const dispatchAction = (data) => {
      dispatch(action({ data: data, derivativeId: derivativeId }))
    }

    MessageHub.subscribe(channel, dispatchAction)
    return () => {
      MessageHub.unsubscribe(channel, dispatchAction)
    }
  }, [dispatch, action, channel])

  return <>{children}</>
}

RealtimeIndexCustom.propTypes = {
  channel: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}
