import style from './index.module.css'
import PropTypes from 'prop-types'
import { Span } from '../../../common/html/Span'
import { Translate } from 'react-redux-i18n'

export const Title = ({ value }) => {
  return (
    <div className={style.title}>
      <Span style={{ fontSize: 10 }}>
        <Translate value={value} />
      </Span>
    </div>
  )
}

Title.propTypes = {
  value: PropTypes.string.isRequired,
}
