export const getQuarterAndYearInDataRange = (dataRange) => {
  const allQuartersAndYears = dataRange?.map((item) => ({
    quarter: item.quarter,
    year: item.year,
  }))

  const uniqueMonthsAndYears = Object.values(
    allQuartersAndYears.reduce((acc, curr) => {
      acc[curr.quarter + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  return uniqueMonthsAndYears
}

export const getQuarterAndYearKey = (quarter, year) => {
  return quarter > 4 ? `${year}` : `Q${quarter}-${year}`
}

export const getDataByQuarterAndYear = (data, quarter, year) => {
  return data
    ?.filter((item) => item.quarter === quarter && item.year === year)
    ?.map((item) => ({
      value: item.value,
      marEquityValue: item.marEquity,
      id: item.id,
    }))
}

export const getOrganizationInfoById = (listOrganizations, id) => {
  return listOrganizations?.find((item) => item.organizationId === id) || {}
}

export const convertData = (data, getById) => {
  //convert months and years to date keys
  const getDataKeyFromRange = getQuarterAndYearInDataRange(data).map((item) =>
    getQuarterAndYearKey(item.quarter, item.year),
  )

  const dataWithQuarterAndYear = (quarter, year) => {
    return data
      .filter((item) => item.quarter === quarter && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.organizationId ?? 0,
        type: item.type,
      }))
  }

  const getDataValueFromRange = getQuarterAndYearInDataRange(data).map(
    (item) => [...dataWithQuarterAndYear(item.quarter, item.year)],
  )

  const getDataKeyIdValueFromRange = getById
    ? getDataValueFromRange.length
      ? getDataValueFromRange.map((item) =>
          item.map((e) => {
            return { [e.id]: e.value }
          }),
        )
      : []
    : getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.type]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange
    .map((item, i) => ({
      ...item,
      time: getDataKeyFromRange[i],
    }))
    .reverse()
}
