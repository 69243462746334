import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { removeAccents } from '../../../../utils/Search'
import { HEADER_VALUE } from '../../constants'
import { selectLocationName } from '../panelGRDPStructureProvince/store/slice'
import { getNominalRealValue } from '../store/slice'
import DropdownLocaleName from './DropdownLocaleName'
import style from './index.module.css'
import {
  getIsReCallApi,
  getKeySearch,
  getValueGrowthNominal,
  getValueGrowthReal,
  handleIsReCallApi,
  handleKeySearch,
  handleLocationId,
} from './store/slice'

const { NOMINAL } = HEADER_VALUE

const SearchBox = () => {
  const dispatch = useDispatch()
  const inputRef = useRef()
  const timeId = useRef()
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()
  const search = UseI18n('economy.gdp.gdp.SEARCH_PROVINCE')

  const keySearch = useSelector(getKeySearch)
  const listLocationName = useSelector(selectLocationName)
  const nominalRealValue = useSelector(getNominalRealValue)
  const isReCallApi = useSelector(getIsReCallApi)
  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  const handleChangeSearchInput = (e) => {
    dispatch(handleKeySearch(e.target.value))
    if (e.target.value === '') {
      dispatch(handleIsReCallApi(true))
    } else {
      dispatch(handleIsReCallApi(false))
    }
  }

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const filterListLocation = (list) => {
    const keySearchWithoutAccent = removeAccents(keySearch)
    return list.filter((item) =>
      item.locationNameWithoutAccent
        ?.toLowerCase()
        .includes(keySearchWithoutAccent.toLowerCase()),
    )
  }

  const reCallDefaultApi = () => {
    const timeId = setTimeout(() => {
      dispatch(handleLocationId(null))
    }, 2000)
    dispatch(handleIsReCallApi(false))

    return timeId
  }

  const avoidReCallDefaultApi = (timeId) => {
    if (timeId) {
      clearTimeout(timeId)
    }
  }

  useEffect(() => {
    if (!keySearch && isReCallApi) {
      timeId.current = reCallDefaultApi()
    }
    if (keySearch && timeId.current) {
      avoidReCallDefaultApi(timeId.current)
    }
  }, [keySearch, nominalRealValue, radioValue, isReCallApi])

  return (
    <div className={`form-search-inbox m-0 ${style.searchInput} `}>
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        ref={inputRef}
        value={keySearch}
        onChange={handleChangeSearchInput}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        type="text"
        placeholder={search}
        className={`search-inbox`}
      />
      <button type="button">
        <i className="icon-search-2" />
      </button>
      <DropdownLocaleName
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={inputRef}
        listLocationName={filterListLocation(listLocationName)}
        eventPressKey={eventPressKey}
      />
    </div>
  )
}

export default SearchBox
