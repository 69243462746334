import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetMaxMinTable from '../../../../../../common/masterData/useGetMaxMinTable'
import SelectCustom from '../../../../../../common/selectCustom'
import { YEAR_OPTIONS } from '../constant'
import { changeFilter, selectFilter } from '../store/slice'
import AddTicker from './AddTicker'
import DeleteAllTicker from './DeleteAllTicker'
import TableTab from './TableTab'
import ToggleChart from './ToggleChart'
import style from './index.module.css'

const Header = ({ width }) => {
  const dispatch = useDispatch()
  const headerFirstComponentRef = useRef()
  const headerLastComponentRef = useRef()
  const { maxMinTableById } = useGetMaxMinTable()
  const commonTopIssuer = maxMinTableById['FGFB_CORA_DE_FinancialRatioTTM']

  const filter = useSelector(selectFilter)

  const onChangeFilter = (value, key) => {
    dispatch(
      changeFilter({
        [key]: value,
      }),
    )
  }

  useEffect(() => {
    onChangeFilter(
      commonTopIssuer?.max_YearOfLengthReport || new Date().getFullYear(),
      'year',
    )
  }, [])

  return (
    <div className="d-flex mb-8">
      <div className="d-flex" ref={headerFirstComponentRef}>
        <DeleteAllTicker />
        <AddTicker />
      </div>
      <div ref={headerLastComponentRef}>
        <ToggleChart />
      </div>
      <div className={style.selectYearCustom}>
        <SelectCustom
          isI18n={true}
          selectData={YEAR_OPTIONS}
          value={filter.year || YEAR_OPTIONS[0].value}
          handleChange={(e) => onChangeFilter(e, 'year')}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <TableTab />
    </div>
  )
}

export default Header
