import { randomColor } from '../../chart/helper'
import { LIST_TYPE } from '../../common/constants'
import {
  getChartSchemaParams,
  getListSecuritiesIndicator,
} from '../../common/helpers'

export const checkOrientationLeft = (treeSecuritiesIndicator) => {
  if (Object.values(treeSecuritiesIndicator.companies).length) {
    const foundOrientationLeft = Object.values(
      treeSecuritiesIndicator.companies,
    ).some((items) =>
      items.some((item) => item.chartParameters.orientation === 'left'),
    )
    if (foundOrientationLeft) return true
  }
  if (Object.values(treeSecuritiesIndicator.indices).length) {
    const foundOrientationLeft = Object.values(
      treeSecuritiesIndicator.indices,
    ).some((items) =>
      items.some((item) => item.chartParameters.orientation === 'left'),
    )
    if (foundOrientationLeft) return true
  }
  if (Object.values(treeSecuritiesIndicator.sector).length) {
    const foundOrientationLeft = Object.values(
      treeSecuritiesIndicator.sector,
    ).some((items) =>
      items.some((item) => item.chartParameters.orientation === 'left'),
    )
    if (foundOrientationLeft) return true
  }
  if (Object.values(treeSecuritiesIndicator.economy).length) {
    const foundOrientationLeft = Object.values(
      treeSecuritiesIndicator.economy,
    ).some((item) => item.chartParameters.orientation === 'left')
    if (foundOrientationLeft) return true
  }
  return false
}

const isOrientationLeft = (
  securityType,
  securitiesState,
  haveOrientationLeft,
) => {
  const { companies, indices, sector, economy } = securitiesState

  switch (securityType) {
    case LIST_TYPE.COMPANIES:
      return !haveOrientationLeft && companies.length
    case LIST_TYPE.INDICES:
      return !haveOrientationLeft && !companies.length && indices.length
    case LIST_TYPE.SECTOR:
      return (
        !haveOrientationLeft &&
        !companies.length &&
        !indices.length &&
        sector.length
      )
    case LIST_TYPE.ECONOMY:
      return (
        !haveOrientationLeft &&
        !companies.length &&
        !indices.length &&
        !sector.length &&
        economy.length
      )
    default:
      return false
  }
}

const getColorBySecuritiesIndicatorId = (
  securitiesIndicator,
  usedColors,
  favoriteColors,
  indicatorIndexProp,
) => {
  const securityIndicatorArray = Object.values(securitiesIndicator)
  for (
    let securityIndex = 0;
    securityIndex < securityIndicatorArray.length;
    securityIndex++
  ) {
    const indicators = securityIndicatorArray[securityIndex]
    if (indicators.length && indicators[indicatorIndexProp]) {
      return indicators[indicatorIndexProp].color
    }
  }
  const color = randomColor(usedColors, favoriteColors)
  usedColors.push(color)
  return color
}

export const getSecuritiesIndicatorDataOnApply = ({
  treeSecuritiesIndicator,
  securities,
  securitiesState,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  viewType,
  replaceSecurities,
  typeSecuritiesReplace,
  usedColors,
  favoriteColors,
  replaceSecuritiesValue,
}) => {
  const listUsedColor = [...usedColors]
  const { companies, indices, sector, economy } = securitiesState

  const { companyIndicator, indexIndicator, sectorIndicator } =
    indicatorSelected
  const result = {
    companies: {},
    indices: {},
    sector: {},
    economy: {},
  }
  const treeSecuritiesIndicatorClone = JSON.parse(
    JSON.stringify(treeSecuritiesIndicator),
  )

  const templateBySecurities = {}

  const getValueReplace = (typeSecurities, keyUpdate) => {
    if (
      typeSecuritiesReplace &&
      typeSecuritiesReplace === typeSecurities &&
      replaceSecurities
    ) {
      const valueReplace =
        treeSecuritiesIndicatorClone[typeSecuritiesReplace][replaceSecurities]

      const valueReplaceWithUnit = replaceKey(
        valueReplace,
        valueReplace?.indicatorUnit,
        replaceSecuritiesValue?.unit,
      )
      if (typeSecurities === LIST_TYPE.ECONOMY) {
        const [oldId, oldTimeFrequency] = replaceSecurities.split('_')

        return {
          [`${keyUpdate.id}_${keyUpdate.timeFrequency}`]: replaceKey(
            replaceKey(valueReplaceWithUnit, oldId, keyUpdate.id),
            oldTimeFrequency,
            keyUpdate.timeFrequency,
          ),
        }
      } else {
        return {
          [keyUpdate]: replaceKey(
            valueReplace,
            [typeSecuritiesReplace, replaceSecurities].join('_'),
            [typeSecuritiesReplace, keyUpdate].join('_'),
          ),
        }
      }
    }
    return {}
  }

  // Company
  result.companies = {
    ...Object.keys(treeSecuritiesIndicatorClone.companies || {}).reduce(
      (obj, key) => {
        if (companies.includes(Number(key))) {
          const item = treeSecuritiesIndicatorClone.companies[key]
          templateBySecurities.companies = item
          return { ...obj, [key]: item }
        }
        return obj
      },
      {},
    ),
    ...companies
      .filter((id) => !securities.companies.includes(id))
      .reduce(
        (obj, oId) => ({
          ...obj,
          [oId]: companyIndicator.length
            ? getListSecuritiesIndicator({
                indicators: companyIndicator.map((item, index) => ({
                  ...item,
                  color: getColorBySecuritiesIndicatorId(
                    treeSecuritiesIndicatorClone.companies,
                    listUsedColor,
                    favoriteColors,
                    index,
                  ),
                })),
                securityType: LIST_TYPE.COMPANIES,
                securityId: oId,
                typeChart,
                viewType,
                isOrientationLeft: isOrientationLeft(
                  LIST_TYPE.COMPANIES,
                  securitiesState,
                  haveOrientationLeft,
                ),
                usedColors: listUsedColor,
                templateBySecurities,
                favoriteColors,
              })
            : [],
        }),
        {},
      ),
    ...getValueReplace(
      LIST_TYPE.COMPANIES,
      companies.find((id) => !securities.companies.includes(id)),
    ),
  }

  // Indices
  result.indices = {
    ...Object.keys(treeSecuritiesIndicatorClone.indices || {}).reduce(
      (obj, key) => {
        if (indices.includes(Number(key))) {
          const item = treeSecuritiesIndicatorClone.indices[key]
          templateBySecurities.indices = item
          return { ...obj, [key]: item }
        }
        return obj
      },
      {},
    ),
    ...indices
      .filter((id) => !securities.indices.includes(id))
      .reduce(
        (obj, groupId) => ({
          ...obj,
          [groupId]: indexIndicator.length
            ? getListSecuritiesIndicator({
                indicators: indexIndicator.map((item, index) => ({
                  ...item,
                  color: getColorBySecuritiesIndicatorId(
                    treeSecuritiesIndicatorClone.indices,
                    listUsedColor,
                    favoriteColors,
                    index,
                  ),
                })),
                securityType: LIST_TYPE.INDICES,
                securityId: groupId,
                typeChart,
                viewType,
                isOrientationLeft: isOrientationLeft(
                  LIST_TYPE.INDICES,
                  securitiesState,
                  haveOrientationLeft,
                ),
                usedColors: listUsedColor,
                templateBySecurities,
                favoriteColors,
              })
            : [],
        }),
        {},
      ),
    ...getValueReplace(
      LIST_TYPE.INDICES,
      indices.find((id) => !securities.indices.includes(id)),
    ),
  }

  // Sector
  result.sector = {
    ...Object.keys(treeSecuritiesIndicatorClone.sector || {}).reduce(
      (obj, key) => {
        if (sector.includes(Number(key))) {
          const item = treeSecuritiesIndicatorClone.sector[key]
          templateBySecurities.sector = item
          return { ...obj, [key]: item }
        }
        return obj
      },
      {},
    ),
    ...sector
      .filter((id) => !securities.sector.includes(id))
      .reduce(
        (obj, icbId) => ({
          ...obj,
          [icbId]: sectorIndicator.length
            ? getListSecuritiesIndicator({
                indicators: sectorIndicator.map((item, index) => ({
                  ...item,
                  color: getColorBySecuritiesIndicatorId(
                    treeSecuritiesIndicatorClone.sector,
                    listUsedColor,
                    favoriteColors,
                    index,
                  ),
                })),
                securityType: LIST_TYPE.SECTOR,
                securityId: icbId,
                typeChart,
                viewType,
                isOrientationLeft: isOrientationLeft(
                  LIST_TYPE.SECTOR,
                  securitiesState,
                  haveOrientationLeft,
                ),
                usedColors: listUsedColor,
                templateBySecurities,
                favoriteColors,
              })
            : [],
        }),
        {},
      ),
    ...getValueReplace(
      LIST_TYPE.SECTOR,
      sector.find((id) => !securities.sector.includes(id)),
    ),
  }

  // Economy
  const arrKeyEconomy = economy.map((item) =>
    [item.id, item.timeFrequency].join('_'),
  )
  result.economy = {
    ...Object.keys(treeSecuritiesIndicator.economy || {}).reduce((obj, key) => {
      if (arrKeyEconomy.includes(key)) {
        const item = treeSecuritiesIndicatorClone.economy[key]
        return { ...obj, [key]: item }
      }
      return obj
    }, {}),
    ...economy
      .filter(
        (item) =>
          !securities.economy.some(
            (el) =>
              el.id === item.id && el.timeFrequency === item.timeFrequency,
          ),
      )
      .reduce((obj, economyItem, index) => {
        const findEconomy = {
          ...(listSecuritiesEconomy.find(
            (item) => item.id === economyItem.id,
          ) || {}),
          ...economyItem,
        }
        const dataKey = [
          LIST_TYPE.ECONOMY,
          findEconomy.id,
          findEconomy.timeFrequency,
        ].join('_')
        return {
          ...obj,
          [`${findEconomy.id}_${findEconomy.timeFrequency}`]:
            getChartSchemaParams({
              item: findEconomy,
              securityType: LIST_TYPE.ECONOMY,
              dataKey,
              typeChart,
              isOrientationLeft:
                isOrientationLeft(
                  LIST_TYPE.ECONOMY,
                  securitiesState,
                  haveOrientationLeft,
                ) && index === 0,
              usedColors: listUsedColor,
              favoriteColors,
            }),
        }
      }, {}),
    ...getValueReplace(
      LIST_TYPE.ECONOMY,
      economy.find(
        (item) =>
          !securities.economy.some(
            (el) =>
              el.id === item.id && el.timeFrequency === item.timeFrequency,
          ),
      ),
    ),
  }
  return result
}

const replaceKey = (value, keyReplace, keyUpdate) => {
  const valueConvert = JSON.stringify(value)
  const result = valueConvert.replaceAll(keyReplace, keyUpdate)
  return JSON.parse(result)
}
