export const fundCenter = {
  STATISTIC: 'THỐNG KÊ',
  OVERVIEW: 'TỔNG QUAN',
  HOLDING: 'SỞ HỮU',

  ALL_FUND: 'Tất cả mã quỹ',
  SEARCH_FUND: 'Tìm kiếm mã quỹ',
  ALL_STRUCTURE: 'Tất cả chiến lược',
  STOCK_FUND: 'Quỹ cổ phiếu',
  BOND_FUND: 'Quỹ trái phiếu',
  BALANCE_FUND: 'Quỹ cân bằng',
  ALL_TYPE: 'Tất cả loại quỹ',
  OPEN_END_FUND: 'Quỹ mở',
  CLOSED_END_FUND: 'Quỹ đóng',
  ETF_FUND: 'Quỹ ETF',
  SPECIALTY_FUND: 'Quỹ BĐS',
  MEMBER_FUND: 'Quỹ thành viên',
  PENSION_FUND: 'Quỹ hưu trí',
  ALL_MANAGEMENT: 'Tất cả công ty quản lý quỹ',
  ALL_STATUS: 'Tất cả trạng thái',
  ACTIVE: 'Đang hoạt động',
  STOP: 'Ngừng hoạt động',
  PAUSE: 'Tạm ngừng hoạt động',
  OTHER: 'Không có thông tin',

  MOST_RECENT: 'Gần nhất',
  SELECT_TIME_PERIOD: 'Chọn kỳ',

  FUND_FLOW_NOTE: 'Dòng tiền quỹ được ước tính bởi FiinGroup',

  statistic: {
    TABLE: 'Danh sách',
    KEY_METRICS: 'Chỉ số chính',
    PERFORMANCE: 'Hiệu suất đầu tư',
    FUND_FLOW: 'Dòng tiền quỹ',
    SECTOR_ALLOCATION: 'Phân bổ ngành',
    ASSET_ALLOCATION: 'Phân bổ tài sản',
    FUND_INDEX: 'Chỉ số quỹ',
    RELATIVE: 'Tỷ trọng',
    ABSOLUTE: 'Giá trị',
    MOST_RECENT: 'Kỳ gần nhất',
    SELECT_TIME_PERIOD: 'Chọn kỳ',
    NOTE_UNIT_BILLION_VND: 'Đơn vị: Tỷ VNĐ',

    COL_INDEX: 'STT',
    COL_FUND_TICKER: 'Mã quỹ',
    COL_FUND_NAME: 'Tên quỹ',
    COL_FUND_STRUCTURE: 'Chiến lược đầu tư',
    COL_FUND_TYPE: 'Loại quỹ',
    COL_FUND_MANAGEMENT: 'Công ty quản lý quỹ',
    COL_FUND_FUND_STATUS: 'Trạng thái quỹ',
    COL_YEAR_IN_OPERATION: 'Thời gian hoạt động',
    COL_FREQUENCY: 'Tần suất',
    COL_FREQUENCY_NOTE:
      'Là tần suất cập nhật dữ liệu mới nhất của quỹ tại thời điểm được chọn',
    COL_UPDATE_DATE_NAV: 'Ngày cập nhật',
    COL_PERFORMANCE_UPDATE_DATE_NAV: 'Ngày cập nhật',
    COL_TOTAL_NAV: 'Tổng NAV',
    COL_CHANGE_NAV_1M: '% thay đổi NAV/CCQ 1M',
    COL_CHANGE_NAV_YTD: '% thay đổi NAV/CCQ YTD',
    COL_FUND_FLOW_1M: 'Dòng tiền quỹ 1M',
    COL_STD_CHANGE_1Y: 'Tăng trưởng Std 1Y',
    COL_NAV_CCQ: 'NAV/CCQ',
    COL_NAV_CCQ_PERFORMANCE: 'Tăng trưởng NAV/CCQ',
    COL_NAV_CCQ_PERFORMANCE_MOST_RECENT: 'Thay đổi gần nhất',
    COL_NAV_CCQ_PERFORMANCE_YTD: 'Từ đầu năm',
    COL_NAV_CCQ_PERFORMANCE_1M: '1 tháng',
    COL_NAV_CCQ_PERFORMANCE_6M: '6 tháng',
    COL_NAV_CCQ_PERFORMANCE_1Y: '1 năm',
    COL_NAV_CCQ_PERFORMANCE_3Y: '3 năm',
    COL_NAV_CCQ_PERFORMANCE_5Y: '5 năm',
    COL_TOTAL_FUND_FLOW: 'Tổng dòng tiền quỹ',
    COL_TOTAL_FUND_FLOW_MOST_RECENT: 'Kỳ trước',
    COL_TOTAL_FUND_FLOW_3M: '3 tháng',
    COL_TOTAL_FUND_FLOW_YTD: 'Từ đầu năm',
    COL_TOTAL_FUND_FLOW_1Y: '1 năm',
    COL_SECTOR: 'Ngành',
    COL_SECTOR_ABSOLUTE: 'Giá trị',
    COL_SECTOR_RELATIVE: 'Phần trăm',
    COL_STOCK: 'Cổ phiếu',
    COL_STOCK_VALUE: 'Giá trị',
    COL_STOCK_MOST_RECENT_CHANGE: 'Thay đổi gần nhất',
    COL_BOND: 'Trái phiếu',
    COL_BOND_VALUE: 'Giá trị',
    COL_BOND_MOST_RECENT_CHANGE: 'Thay đổi gần nhất',
    COL_CASH_AND_ORDER: 'Tiền và tài sản khác',
    COL_CASH_AND_ORDER_VALUE: 'Giá trị',
    COL_CASH_AND_ORDER_MOST_RECENT_CHANGE: 'Thay đổi gần nhất',
    COL_VOLATILITY: 'Volatility',
    COL_SHARPE_RATIO: 'Sharpe Ratio',
    COL_CORRELATION: 'Correlation',
    COL_FOREIGN_OWNED: 'Nước ngoài nắm giữ (%)',
    COL_MAX_DRAWDOWN: 'Max Drawdown',
    COL_TREYNOR_RATIO: 'Treynor Ratio',
    COL_STD_1Y: 'Std (1y)',
    COL_STD_3Y: 'Std (3y)',
    COL_SHARPE_1Y: 'Sharpe (1y)',
    COL_SHARPE_3Y: 'Sharpe (3y)',
    COL_TRACKING_ERROR_1Y: 'Tracking Error (1y)',
    TABLE_NOTE:
      '* Hiệu suất đầu tư đối với quỹ ngoại có thể bị ảnh hưởng bởi vấn đề tỷ giá do NAV (Giá trị Tài sản Ròng) được niêm yết bằng ngoại tệ.',
  },

  overview: {
    ASSET_ALLOCATION: 'Phân bố tài sản',
    PERFORMANCE: 'Hiệu quả hoạt động',

    performance: {
      TOP_10_NET_ASSET_VALUE: 'Top 10 tài sản ròng',
      FUND_OVERVIEW_12_MONTHS: 'Tổng quan quỹ trong 12 tháng',
      NAV_CCQ: 'Tăng trưởng NAV/CCQ',
      FUND_FLOW: 'Dòng tiền của quỹ',
      CHART_NAME_TOP_10_NET_ASSET_VALUE:
        'TOP 10 TÀI SẢN RÒNG THÁNG %{month} - %{year}',
      CHART_NAME_FUND_OVERVIEW_12_MONTHS:
        'TỔNG QUAN CÁC QUỸ TRONG 12 THÁNG, THÁNG %{month} - %{year}',
      CHART_NAME_NAV_CCQ: 'TĂNG TRƯỞNG NAV/CCQ',
      CHART_NAME_FUND_FLOW: 'DÒNG TIỀN CỦA QUỸ',
      FUND_STRUCTURE: 'Chiến lược đầu tư',
      STOCK_FUND: 'Quỹ cổ phiếu',
      BOND_FUND: 'Quỹ trái phiếu',
      BALANCE_FUND: 'Quỹ cân bằng',
      FUND_TYPE: 'Loại quỹ',
      OPEN_END_FUND: 'Quỹ mở',
      CLOSED_END_FUND: 'Quỹ đóng',
      ETF_FUND: 'Quỹ ETF',
      SOURCE_FUND: 'Nguồn: %{total} Quỹ',
      FUND_OVERVIEW_X_AXIS: '% thay đổi NAV/CCQ 1Y',
      FUND_OVERVIEW_Y_AXIS: 'Độ lệch chuẩn 1Y',
      FUND_OVERVIEW_Z_AXIS: 'Tổng NAV',
      FUND_OVERVIEW_NOTE: 'Kích thước = Tổng NAV',
      FUND_FLOW_Y_AXIS: 'Dòng tiền của quỹ (Tỷ VNĐ)',
      FUND_FLOW_ACC_Y_AXIS: 'Dòng tiền lũy kế (Tỷ VNĐ)',
      FUND_FLOW_FOOTER: 'Dòng tiền của quỹ',
      FUND_FLOW_ACC_FOOTER: 'Dòng tiền lũy kế 12 tháng',
      FUND_FLOW_NET_TOOLTIP: 'Dòng tiền ròng',
      FUND_FLOW_IN_TOOLTIP: 'Dòng tiền vào',
      FUND_FLOW_OUT_TOOLTIP: 'Dòng tiền ra',
      FUND_FLOW_ACC_TOOLTIP: 'Dòng tiền lũy kế 12 tháng',
      NAV_CCQ_FUND_AVERAGE: 'Bình quân các quỹ',
      NAV_CCQ_STOCK_FUND_AVERAGE: 'Bình quân quỹ cổ phiếu',
      NAV_CCQ_BOND_FUND_AVERAGE: 'Bình quân quỹ trái phiếu',
      NAV_CCQ_BALANCE_FUND_AVERAGE: 'Bình quân quỹ cân bằng',
    },
  },

  holding: {
    TABLE: 'Danh sách',
    ALL: 'Tất cả',
    DETAIL: 'Chi tiết',

    COL_ALL_TICKER: 'Mã CP',
    COL_ALL_NAME: 'Tên công ty',
    COL_ALL_CLOSE_PRICE: 'Giá đóng cửa',
    COL_ALL_PRICE_CHANGE_1M: '% Biến động giá 1 tháng',
    COL_ALL_VOLUME: 'Khối lượng CP nắm giữ',
    COL_ALL_OWNERSHIP_TOTAL_SHARE: 'Tỷ lệ nắm giữ/Tổng số CP lưu hành',
    COL_ALL_TOTAL_VALUE: 'Giá trị',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME: 'Thay đổi khối lượng CP nắm giữ',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M: '1 tháng',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD: 'Từ đầu năm',
    COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y: '1 năm',
    COL_ALL_OWNERSHIP_CHANGE_VALUE: 'Thay đổi giá trị nắm giữ',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_1M: '1 tháng',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD: 'Từ đầu năm',
    COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y: '1 năm',
    COL_ALL_NUMBER_FUND_HOLDING: 'Số quỹ nắm giữ',
    COL_DETAIL_TICKER: 'Mã quỹ',
    COL_DETAIL_NAME: 'Tên quỹ',
    COL_DETAIL_INVESTMENT_STRATEGY: 'Chiến lược đầu tư',
    COL_DETAIL_NAV_CCQ: 'NAV/CCQ',
    COL_DETAIL_UPDATE_DATE: 'Ngày cập nhật NAV',
    COL_DETAIL_NAV_CCQ_PERFORMANCE: 'Tăng trưởng NAV/CCQ',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_1M: '1 tháng',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_1Y: '1 năm',
    COL_DETAIL_NAV_CCQ_PERFORMANCE_3Y: '3 năm',
    COL_DETAIL_FUND_HOLDING: 'Quỹ nắm giữ',
    COL_DETAIL_FUND_HOLDING_VOLUME: 'Khối lượng (CP)',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_1M: 'Thay đổi KL 1M',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_3M: 'Thay đổi KL 3M',
    COL_DETAIL_FUND_HOLDING_VOLUME_CHANGE_6M: 'Thay đổi KL 6M',
    COL_DETAIL_FUND_HOLDING_PRICE_CHANGE_1M: '(+/-%) Giá 1M',
    COL_DETAIL_FUND_HOLDING_TOTAL_VALUE: 'Giá trị nắm giữ',
    COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET: '% Tỷ trọng',
    COL_DETAIL_FUND_HOLDING_OWNERSHIP_TOTAL_SHARE:
      'Tỷ lệ nắm giữ/Tổng số CP lưu hành',
    TABLE_NOTE:
      '*Số lượng cổ phiếu nắm giữ trên toàn thị trường có thể giảm do hệ thống FiinPro-X chưa cập nhật đầy đủ báo cáo sở hữu từ một số công ty quản lý quỹ trong tháng.',
  },
}
