import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import authProvider from '../../../core/AuthenticationProvider'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import { valByKeyWithDot } from '../../utils/Value'
import { MAP_INFO_USER } from '../constants'
import { selectDataProfile } from '../slice'
import { EditProfileBtn } from './EditProfileBtn'
import InputFavoriteContact from './InputFavoriteContact'
import style from './index.module.css'

const Profile = ({ setWidthInput }) => {
  const refGroupBtn = useRef()
  const userInfo = useSelector(selectDataProfile)
  const [stateWidth, setStateWidth] = useState(0)
  const [email, setEmail] = useState('')

  useEffect(() => {
    setStateWidth(refGroupBtn.current?.offsetWidth)
    setWidthInput(refGroupBtn.current?.offsetWidth)
  }, [refGroupBtn])

  useEffect(() => {
    const asyncFn = async () => {
      const user = await authProvider.getUser()
      setEmail(user?.profile?.email || '')
    }
    asyncFn()
  }, [])

  return (
    <div className="box-tab">
      <h5>
        <Span>
          <Translate value="setting.settingProfile.MY_PROFILE" />
        </Span>
      </h5>
      <form>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.NAME" />
            </Span>
          </label>
          <Input
            style={{ fontSize: 11, width: stateWidth }}
            type="text"
            className="bg-grey-2 h-20 disabled"
            value={valByKeyWithDot(userInfo, MAP_INFO_USER.userName)}
            disabled
          />
        </div>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.MAIL" />
            </Span>
          </label>
          <Input
            style={{ fontSize: 11, width: stateWidth }}
            type="text"
            className="bg-grey-2 h-20 disabled"
            value={valByKeyWithDot(userInfo, MAP_INFO_USER.email)}
            disabled
          />
        </div>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.PHONE" />
            </Span>
          </label>
          <Input
            style={{ fontSize: 11, width: stateWidth }}
            type="text"
            className="bg-grey-2 h-20 disabled"
            value={valByKeyWithDot(userInfo, MAP_INFO_USER.phoneNumber)}
            disabled
          />
        </div>
        <div className={['form-control', 'j-b', style.row].join(' ')}>
          <label className={style.label}>
            <Span>
              <Translate value="setting.settingProfile.FAVORITE_CONTACT" />
            </Span>
          </label>
          <InputFavoriteContact
            defaultValueFavoriteContact={parseInt(
              valByKeyWithDot(userInfo, MAP_INFO_USER.favoriteContact),
            )}
          />
        </div>
        <div className={style.groupBtn} ref={refGroupBtn}>
          <Button
            style={{ fontSize: 11 }}
            className="btn bg-grey-3 h-20"
            type="button"
            onClick={() =>
              window.open(
                process.env.REACT_APP_CHANGE_PASSWORD +
                  '?email=' +
                  email +
                  '&clientId=' +
                  process.env.REACT_APP_CLIENT_ID,
                '_blank',
              )
            }
          >
            <Translate value="setting.settingProfile.CHANGE_PASSWORD" />
          </Button>
          <EditProfileBtn />
        </div>
      </form>
    </div>
  )
}

export default Profile
