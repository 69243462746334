import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import SelectCustom from '../../../../../../common/selectCustom'
import { OPTION_RELEASR_METHOD } from '../../constants'
import { selecDataL2 } from '../../store/slice'
import {
  selectIcbSelect,
  selectMethodType,
  updateIcb,
  updateMethod,
} from '../store/slice'
import style from './style.module.css'

const FilterCountry = () => {
  const dispatch = useDispatch()
  const dataL2 = useSelector(selecDataL2)
  const valueL2 = useSelector(selectIcbSelect)
  const activeMethod = useSelector(selectMethodType)

  const handleMethod = (value) => {
    dispatch(updateMethod(value))
  }

  const listData = useMemo(() => {
    const data = dataL2.map((e) => ({
      name: `${e.icbName} L2`,
      value: e.icbId,
    }))
    return [
      {
        name: 'bond.overview.primaryMarket.ALL_SECTOR',
        value: '',
      },
      ...data,
    ]
  }, [dataL2])

  return (
    <div className="d-flex ali-center">
      <div className={style.selectYearCustom}>
        <SelectCustom
          isI18n={true}
          selectData={listData}
          value={valueL2}
          handleChange={(value) => dispatch(updateIcb(value))}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      <div className={`d-flex ali-center ml-8`}>
        {OPTION_RELEASR_METHOD.map(({ value, label }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === activeMethod && style.radioActive}
                  `}
              onClick={() => handleMethod(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === activeMethod}
                readOnly={true}
              />
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <Translate value={label} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}
export default FilterCountry
