import PropTypes from 'prop-types'
import { Bar, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../common/chart/constants'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { formatDataChart } from '../helper'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}
const yAxisLeftId = 'left'
const yAxisRightId = 'right'

export const ChartComponent = ({
  data,
  width,
  height,
  barKey,
  pointKey,
  keyXAxis,
  barLabel,
  pointLabel,
  isFormatValToPercent,
  isFormatValToPercentSub,
  leftLabel,
  rightLabel,
}) => {
  if (data.length <= 0) {
    return <div style={{ height }} />
  }

  const formatData = formatDataChart(
    data,
    barKey,
    pointKey,
    isFormatValToPercent,
    isFormatValToPercentSub,
  )

  // Tooltip
  const getContent = () => {
    const content = []
    const unit = pointKey
      ? 'corporate.overview.financial.SHORT_UNIT_TRILLION'
      : isFormatValToPercent
      ? '%'
      : ''

    if (barKey) {
      content.push({
        keys: [barKey],
        yAxisId: yAxisLeftId,
        unit,
        decimalLength: 2,
      })
    }

    if (pointKey) {
      content.push({
        keys: [pointKey],
        yAxisId: yAxisRightId,
        unit: isFormatValToPercentSub ? ' %' : '',
        decimalLength: 2,
      })
    }

    return content
  }

  const getMappingDisplayName = () => {
    const labels = {}

    if (barKey) {
      labels[barKey] = barLabel
    }

    if (pointKey) {
      labels[pointKey] = pointLabel
    }

    return labels
  }

  const yAxisBar = barKey
    ? [
        {
          id: yAxisLeftId,
          keys: [barKey],
          orientation: 'left',
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label: leftLabel,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: isFormatValToPercent ? '%' : '',
        },
      ]
    : []

  const yAxisPoint = pointKey
    ? [
        {
          id: yAxisRightId,
          keys: [pointKey],
          orientation: 'right',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          label: rightLabel,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          unitYAxis: !isFormatValToPercent ? '%' : '',
        },
      ]
    : []

  return (
    <ChartContainer
      data={formatData}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      margin={{
        top: 20,
        left: isFormatValToPercent ? 12 : 0,
        right: !isFormatValToPercent ? 12 : 0,
        bottom: 0,
      }}
      yAxis={yAxisBar.concat(yAxisPoint)}
      tooltipSchema={{
        content: getContent(),
        mappingDisplayName: getMappingDisplayName(),
      }}
    >
      {barKey && (
        <Bar
          isAnimationActive={false}
          yAxisId="left"
          dataKey={barKey}
          barSize={35}
          fill="#facc5c"
        />
      )}

      {pointKey && (
        <>
          <Scatter
            yAxisId="right"
            dataKey={pointKey}
            isAnimationActive={false}
            shape={<ScatterDiamondShape dataKey={pointKey} />}
          />
        </>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  barKey: PropTypes.string.isRequired,
  pointKey: PropTypes.string.isRequired,
  keyXAxis: PropTypes.string.isRequired,
  barLabel: PropTypes.string.isRequired,
  pointLabel: PropTypes.string.isRequired,
  isFormatValToPercent: PropTypes.bool,
  isFormatValToPercentSub: PropTypes.bool,
}
