import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FundCenterService from '../../../../../../../core/services/fund/FundCenterService'
import { formatDataApi } from '../helpers'

export const getFundsOverview12Months = createAsyncThunk(
  'fund/fundCenter/overview/performance/GET_FUND_OVERVIEW_12_MONTH',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FundCenterService.getFundsOverview12Months(params)
      if (response.success) return formatDataApi(response.data)
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
