import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/stockTopInfo'
import Content from './Content'
import style from './index.module.css'

export const FinancialStatement = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo title="corporate.financialData.financialStatement.FINANCIAL_STATEMENT" />
              {size.height && (
                <div
                  className={`summary-page ${style['overview-container']}`}
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
