import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import styles from './index.module.css'
import {
  getCollapseByDate,
  getTemplateSavedByDate,
  handleCollapse,
} from './store/slice'
import TemplateItem from './TemplateItem'

const GroupByDate = ({ date }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const dataByDate = useSelector(getTemplateSavedByDate(date))
  const locale = useSelector((state) => state.i18n.locale)
  const dateFormat = formatDateTime(date, FORMAT.DATE, locale, timeZone)
  const isCollapse = useSelector(getCollapseByDate(date))

  const onCollapse = () => {
    dispatch(handleCollapse(date))
  }

  if (!date) return <></>

  return (
    <div className={styles.wrapGroupByDate}>
      <div className={styles.groupDateHeader} onClick={onCollapse}>
        <div className={styles.blueBorder} />
        <Span style={{ opacity: 0.5 }}>{dateFormat}</Span>
        <div className={styles.centerLine} />
        {!isCollapse ? (
          <i className={`icon-arrow-right ${styles.iconArrow}`} />
        ) : (
          <i className={`icon-arrow-down ${styles.iconArrow}`} />
        )}
      </div>
      {isCollapse &&
        dataByDate.templateSaved.map((item) => (
          <TemplateItem
            key={item.id}
            id={item.id}
            title={item.title}
            date={date}
          />
        ))}
    </div>
  )
}

GroupByDate.propTypes = {
  date: PropTypes.string.isRequired,
}

export default GroupByDate
