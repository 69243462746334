export const summaryTableSchema = [
  {
    id: 'rtD0005',
    title: 'market.equityTrading.deepTransaction.summary.MARKET_CAP',
    multiplier: 10 ** 9,
  },
  {
    id: 'outstandingShare',
    title: 'market.equityTrading.deepTransaction.summary.OUTSTANDING',
    multiplier: 10 ** 6,
  },
  {
    id: 'freeFloat',
    title: 'market.equityTrading.deepTransaction.summary.FREE_FLOAT',
    multiplier: 10 ** 6,
  },
  {
    id: 'foreignCurrentRoom',
    title: 'market.equityTrading.deepTransaction.summary.FOREIGN_CURRENT_ROOM',
    multiplier: 10 ** 6,
  },
  {
    id: 'rT0060',
    title: 'market.equityTrading.deepTransaction.summary.ROE',
    multiplier: 0.01,
  },
  {
    id: 'rtD0022',
    title: 'market.equityTrading.deepTransaction.summary.PE',
    multiplier: 1,
  },
  {
    id: 'totalPercentChange1WeekClosePriceAdjusted',
    title: 'market.equityTrading.deepTransaction.summary.ONE_WEEK_CHANGE',
    multiplier: 0.01,
  },
  {
    id: 'totalPercentChange1MonthClosePriceAdjusted',
    title: 'market.equityTrading.deepTransaction.summary.ONE_MONTH_CHANGE',
    multiplier: 0.01,
  },
  {
    id: 'totalPercentChangeYTDClosePriceAdjusted',
    title: 'market.equityTrading.deepTransaction.summary.YTD_CHANGE',
    multiplier: 0.01,
  },
  {
    id: 'totalValue',
    title: 'market.equityTrading.deepTransaction.summary.TOTAL_VALUE',
    multiplier: 10 ** 6,
  },
  {
    id: 'totalVolume',
    title: 'market.equityTrading.deepTransaction.summary.TOTAL_VOLUME',
    multiplier: 10 ** 3,
  },
  {
    id: 'avg10DayTotalVolume',
    title: 'market.equityTrading.deepTransaction.summary.AVG_VOL_TEN_DAY',
    multiplier: 10 ** 3,
  },
  {
    id: 'avg1MonthTotalVolume',
    title: 'market.equityTrading.deepTransaction.summary.AVG_VOL_ONE_MONTH',
    multiplier: 10 ** 3,
  },
]
