import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import TextEllipsis from '../../../common/textEllipsis'
import { FEATURES } from '../../../constants/routes'
import { selectBasicInfoOverview } from '../store/slice'
import style from './index.module.css'
import { selectLoading, selectNewsInfo } from './store/slice'
import { getNewsInfo } from './store/thunk'

const News = ({ width }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const newsInfo = useSelector(selectNewsInfo)
  const isLoading = useSelector(selectLoading)
  const basicInfo = useSelector(selectBasicInfoOverview)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getNewsInfo({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId, locale])

  const [totalWidthOfFirstAndSecond, setTotalWidthOfFirstAndSecond] =
    useState(0)
  const secondDivRef = useRef()

  useEffect(() => {
    if (secondDivRef.current) {
      const FIRST_WIDTH = 120 // width first col is static
      setTotalWidthOfFirstAndSecond(
        secondDivRef.current.offsetWidth + FIRST_WIDTH,
      )
    }
  }, [newsInfo])

  const onClick = (newsId) => () => {
    const { icbId: _, ...others } = basicInfo
    navigate(
      FEATURES.corporate.components['news-events'].components['news-detail']
        .route,
      others,
      '?newsId=' + newsId,
    )
  }

  const LAST_COL_MIN_WIDTH = 0.15 // 15 percent
  const lastColumnMaxWidth = Math.max(
    width - totalWidthOfFirstAndSecond,
    width * LAST_COL_MIN_WIDTH,
  )

  return (
    <div className="box-news h-100">
      {!isLoading &&
        (newsInfo.length > 0 ? (
          totalWidthOfFirstAndSecond === 0 ? (
            <div style={{ display: 'inline-block' }} ref={secondDivRef}>
              {newsInfo.map((item, index) => {
                return <div key={index}>{item.title}</div>
              })}
            </div>
          ) : (
            <ScrollComponent>
              <table>
                <tbody>
                  {newsInfo.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        style={{ borderBottom: 'unset' }}
                        onClick={onClick(item.newsId)}
                      >
                        <td className={style.firstTd}>
                          <DateTimeSpan date={item.publicDate} />
                        </td>
                        <td className={style.secondTd}>
                          <TextEllipsis isI18n={false} text={item.title} />
                        </td>
                        <td
                          style={{ maxWidth: lastColumnMaxWidth }}
                          className={style.thirdTd}
                        >
                          <TextEllipsis isI18n={false} text={item.source} />
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </ScrollComponent>
          )
        ) : basicInfo?.organizationId ? (
          <NoData />
        ) : (
          ''
        ))}
      {isLoading && <Loading />}
    </div>
  )
}

News.propTypes = {
  width: PropTypes.number.isRequired,
}

export default News
