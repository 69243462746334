export const indicators = {
  FILING_DATE: 'reportDateId',
  DOCUMENT_TITLE: 'title',
  CATEGORY: 'category',
  SUB_CATEGORY: 'subCategory',
  YEAR: 'year',
  QUARTER: 'quarter',
  DOWNLOAD: 'reportURL',
}

export const QUARTERS = [1, 2, 3, 4]
