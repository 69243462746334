import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import {
  CURRENT_MONTH,
  CURRENT_YEAR,
  getFromMonth,
  getFromYear,
  getToMonth,
  getToYear,
} from './store/slice'
import { downloadStatisticStateBank } from './store/thunk'

const DepositRateVietNam = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const fromYear = useSelector(getFromYear)
  const fromMonth = useSelector(getFromMonth)
  const toYear = useSelector(getToYear)
  const toMonth = useSelector(getToMonth)

  const downloadCsv = () => {
    const params = {
      MonthFrom: fromMonth,
      YearFrom: fromYear,
      MonthTo: toMonth || CURRENT_MONTH,
      YearTo: toYear || CURRENT_YEAR,
    }

    return downloadStatisticStateBank(params)
  }

  return (
    <Panel
      title={'economy.monetary.monetary.DEPOSIT_RATE_VIET_NAM'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default DepositRateVietNam
