import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Setting } from '.'
import { positionDropdown } from '../../../common/dropdown'
import { Span } from '../../../common/html/Span'
import {
  changeCallChartAllDB,
  removeChartTabsData,
  selectChartAllDB,
} from '../../chart/store/slice'
import { postCharts } from '../../chart/store/thunk'
import { screenTypes } from '../../constants'
import { ReactComponent as IconDownloadBlack } from '../../icon/icon-download-black.svg'
import { ReactComponent as IconDownloadCsvBlack } from '../../icon/icon-download-csv-black.svg'
import { ReactComponent as IconDuplicate } from '../../icon/icon-duplicate-black.svg'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { ReactComponent as IconReplace } from '../../icon/icon-replace.svg'
import { ReactComponent as IconShare } from '../../icon/icon-share.svg'
import PopupCopyChartToNewDashboard from '../../popup/PopupCopyChartToNewDashboard'
import PopupDeleteChart from '../../popup/PopupDeleteChart'
import PopupMessageDialog from '../../popup/PopupMessageDialog'
import PopupSelectChart, { TYPE_POPUP } from '../../popup/popupSelectChart'
import PopupShareChart from '../../popup/PopupShareChart'
import {
  deleteChart,
  selectDashboard,
  selectIndicatorsLatestPeriod,
} from '../../store/slice'
import { getChartsInDashboard } from '../../store/thunk'
import { downloadChart } from '../chartInPanel/store/slice'
import { SCROLL_ID } from '../panel/PanelContainer'
import { useCallChartAllDB } from '../useCallChartAllDB'
import { useTableDownloadCsv } from '../useTableDownloadCsv'

export const ChartSetting = ({
  item,
  dashboard,
  renameChart,
  duplicateChart,
  positionCopyToLeft,
  chartTab,
}) => {
  const dispatch = useDispatch()
  const { onDownloadCsv } = useTableDownloadCsv()

  const [isShowSetting, setIsShowSetting] = useState(false)
  const [isShowPopupDelete, setShowPopupDelete] = useState(false)
  const [showPopupShare, setShowPopupShare] = useState(false)
  const [isShowPopupCopyToNewDB, setShowPopupCopyToNewDB] = useState(false)
  const [isShowPopupSuccess, setShowPopupSuccess] = useState(false)
  const [nameDB, setNameDB] = useState('')
  const [isShowPopupReplace, setShowPopupReplace] = useState(false)
  const { screenType } = useSelector(selectDashboard)
  const callChartAllDB = useSelector(selectChartAllDB)
  const indicatorLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const { callData } = useCallChartAllDB()

  const handleShareChart = () => {
    setShowPopupShare(true)
  }

  const handleDeleteChart = () => {
    if (screenType === screenTypes.DASHBOARD) {
      setShowPopupDelete(true)
    } else {
      dispatch(deleteChart(item.id))
      dispatch(removeChartTabsData(chartTab))
    }
  }

  const handleCopyToNewDB = () => {
    setShowPopupCopyToNewDB(true)
  }

  const handleReplaceChart = () => {
    setShowPopupReplace(true)
    if (callChartAllDB) {
      callData()
      dispatch(changeCallChartAllDB(false))
    }
  }

  const handleCopyToDB = async (dashboard) => {
    dispatch(
      getChartsInDashboard({
        dashboardId: dashboard.id,
        isTemplate: dashboard.isTemplate,
      }),
    )
      .unwrap()
      .then(async (data) => {
        const params = {
          ...item,
          dashboardId: dashboard.id,
          detail: JSON.stringify({ ...item.detail, order: data.length + 1 }),
          order: data.length + 1,
        }
        await dispatch(postCharts(params))
          .unwrap()
          .then(async (data) => {
            if (data.id) {
              setNameDB(dashboard.name)
              setShowPopupSuccess(true)
            }
          })
      })
  }

  const handleCopyTo = (type, dashboard) => {
    if (type === 'COPY_TO_NEW_DB') {
      handleCopyToNewDB()
    } else {
      handleCopyToDB(dashboard)
    }
  }

  return (
    <>
      <Setting
        data={[
          {
            icon: <IconRename />,
            title: 'financialChart.RENAME_CHART',
            action: renameChart,
            isHide: dashboard?.isTemplate,
          },
          {
            icon: <IconDuplicate />,
            title: 'financialChart.DUPLICATE_CHART',
            action: () => duplicateChart(item),
          },
          {
            icon: <IconReplace />,
            title: 'financialChart.REPLACE_CHART',
            action: () => {
              handleReplaceChart()
            },
            isHide: dashboard?.isTemplate,
          },
          {
            icon: <IconDuplicate />,
            title: 'financialChart.COPY_TO',
            action: handleCopyTo,
            isActionCopyTo: true,
            positionCopyToLeft: positionCopyToLeft,
          },
          {
            icon: (
              <i
                className="icon-trash"
                style={{ fontSize: 16, paddingRight: 2 }}
              />
            ),
            title: 'financialChart.DELETE_CHART1',
            action: () => {
              handleDeleteChart()
            },
            isHide: dashboard?.isTemplate,
          },
          {
            icon: (
              <Span
                style={{
                  width: 16,
                  height: 16,
                  paddingLeft: 2,
                  paddingRight: 2,
                }}
              >
                <IconDownloadCsvBlack />
              </Span>
            ),
            title: 'financialChart.DOWNLOAD_CSV',
            action: () => {
              onDownloadCsv({
                chartSettings: item.detail,
                indicatorLatestPeriod,
              })
            },
          },
          {
            icon: <IconDownloadBlack />,
            title: 'financialChart.DOWNLOAD_CHART',
            action: () => {
              dispatch(downloadChart({ id: item.id }))
            },
          },
          {
            icon: <IconShare />,
            title: 'financialChart.SHARE_CHART1',
            action: () => {
              handleShareChart(true)
            },
          },
        ].filter((item) => !item.isHide)}
        isShow={isShowSetting}
        setIsShow={setIsShowSetting}
        position={positionDropdown.BOTTOM_RIGHT_LEFT}
        scrollId={SCROLL_ID}
      />
      {isShowPopupDelete ? (
        <PopupDeleteChart
          handleShow={setShowPopupDelete}
          chartId={item.id}
          chartDetail={item}
        />
      ) : null}
      {showPopupShare ? (
        <PopupShareChart
          handleShow={setShowPopupShare}
          id={item.id}
          type="chart"
        />
      ) : null}
      {isShowPopupCopyToNewDB ? (
        <PopupCopyChartToNewDashboard
          isShow={isShowPopupCopyToNewDB}
          handleShow={setShowPopupCopyToNewDB}
          valueChart={item}
        />
      ) : null}
      <PopupMessageDialog
        titlePopup={
          I18n.t('financialChart.COPY_CHART_TO_NEW_DASHBOARD_SUCCESS') +
          nameDB +
          I18n.t('financialChart.COPY_CHART_TO_NEW_DASHBOARD_SUCCESS_1')
        }
        isShow={isShowPopupSuccess}
        handleShow={setShowPopupSuccess}
      />
      {isShowPopupReplace && (
        <PopupSelectChart
          handleShow={setShowPopupReplace}
          typePopup={TYPE_POPUP.REPLACE_CHART}
          itemActive={item}
          chartTab={chartTab}
        />
      )}
    </>
  )
}
