import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { FUND_STRUCTURES, FUND_TYPES } from '../../../../constants'
import { getFundsOverview12Months } from './thunk'

const initialState = {
  loading: true,
  filters: {
    fund: [],
    structure: [FUND_STRUCTURES.STOCK_FUND],
    type: [FUND_TYPES.OPEN_END_FUND],
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    timeRange: '',
    startDate: '',
    endDate: '',
  },
  data: [],
}

export const slice = createSlice({
  name: 'fund/fundCenter/overview/performance/fundOverview',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFundsOverview12Months.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFundsOverview12Months.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getFundsOverview12Months.rejected, (state, action) => {
      state.data = []
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectFilters = (state) => state[slice.name].filters
export const selectData = (state) => state[slice.name].data

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
