import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { languageObjs } from '../../../../../configs/Language'
import {
  enMonthsLowerCase,
  viMonthsLowerCase,
} from '../../../../common/calendar/helper'
import CalendarSelect from '../../../../common/dataExplorer/popupIndicator/common/CalendarSelect'
import ItemSelect from '../../../../common/dataExplorer/popupIndicator/common/ItemSelect'
import { nullValue } from '../../../../common/dataExplorer/popupIndicator/SettingEconomy'
import { resetStore } from '../../../../common/dataExplorer/popupIndicator/store/slice'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import { range } from '../../../../utils/Common'
import style from '../index.module.css'
import {
  changeDetailSetting,
  handlePopupSetting,
  selectActiveSheet,
  selectCondition,
  selectDetailSetting,
} from '../store/slice'

const SettingEconomy = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const activeSheet = useSelector(selectActiveSheet)
  const condition = useSelector(selectCondition(activeSheet))

  useEffect(() => {
    dispatch(resetStore())
  }, [condition])

  const handleOpenSetting = () => dispatch(handlePopupSetting())

  const detail = condition?.[condition.type]?.[condition.period] || {}

  const getKeySelect = (key) => `${condition.type}.${condition.period}.${key}`

  const arrMonth =
    locale === languageObjs.en ? enMonthsLowerCase : viMonthsLowerCase
  const thisYear = new Date().getFullYear()

  const weeks = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 53).map((week) => {
      return { value: week, name: week }
    }),
  )
  const months = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 12).map((month, index) => {
      return { value: month, name: arrMonth[index] }
    }),
  )
  const quarters = [
    { value: nullValue, name: I18n.t('tool.dataExplorer.ALL_SETTING') },
  ].concat(
    range(1, 4).map((quarter) => {
      return { value: quarter, name: quarter }
    }),
  )
  const years = range(2000, thisYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  return (
    <>
      {condition && (
        <div className={style.settingHeaderContainer}>
          <div>
            <div className={style.typeSetting}>
              <Translate
                value={`tool.dataExplorer.${condition.type?.toUpperCase()}`}
              />
              :
            </div>
            <div className={style.detailSetting}>
              <div>
                <Translate
                  value={`tool.dataExplorer.${condition.period?.toUpperCase()}`}
                />
                :
              </div>
              <div className={style.contentSetting}>
                {detail.fromDate && !detail.toDate && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.DATE" />
                    </Span>
                    <CalendarSelect
                      keySelect={getKeySelect('fromDate')}
                      defaultValue={detail.fromDate}
                      funcSelect={selectDetailSetting(activeSheet)}
                      funcChange={changeDetailSetting}
                    />
                  </div>
                )}
                {detail.fromDate && detail.toDate && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.FROM" />
                    </Span>
                    <div className={`${style.w195} ${style.calendar}`}>
                      <CalendarSelect
                        keySelect={getKeySelect('fromDate')}
                        defaultValue={detail.fromDate}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                    <div className={style.textTo2}>
                      <Span style={{ fontSize: 11 }}>
                        <Translate value="tool.dataExplorer.TO" />
                      </Span>
                    </div>
                    <div className={`${style.w195} ${style.calendar}`}>
                      <CalendarSelect
                        keySelect={getKeySelect('toDate')}
                        defaultValue={detail.toDate}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                  </div>
                )}
                {detail.week && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.WEEK" />
                    </Span>
                    <div className={style.w195}>
                      <ItemSelect
                        keySelect={getKeySelect('week')}
                        selectData={weeks}
                        defaultValue={detail.week}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                  </div>
                )}
                {detail.month && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.MONTH" />
                    </Span>
                    <div className={style.w195}>
                      <ItemSelect
                        keySelect={getKeySelect('month')}
                        selectData={months}
                        defaultValue={detail.month}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                  </div>
                )}
                {detail.quarter && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.QUARTER" />
                    </Span>
                    <div className={style.w195}>
                      <ItemSelect
                        keySelect={getKeySelect('quarter')}
                        selectData={quarters}
                        defaultValue={detail.quarter}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                  </div>
                )}
                {detail.fromYear &&
                  !detail.toYear &&
                  !detail.week &&
                  !detail.month &&
                  !detail.quarter && (
                    <div className={style.itemContent}>
                      <Span
                        className={style.labelSetting}
                        style={{ fontSize: 11 }}
                      >
                        <Translate value="tool.dataExplorer.YEAR" />
                      </Span>
                      <div className={style.w85}>
                        <ItemSelect
                          keySelect={getKeySelect('fromYear')}
                          selectData={years}
                          defaultValue={detail.fromYear}
                          funcSelect={selectDetailSetting(activeSheet)}
                          funcChange={changeDetailSetting}
                        />
                      </div>
                    </div>
                  )}
                {detail.fromYear &&
                  !detail.toYear &&
                  (detail.week || detail.month || detail.quarter) && (
                    <div className={`${style.itemContent} ${style.ml0}`}>
                      <div className={style.w85}>
                        <ItemSelect
                          keySelect={getKeySelect('fromYear')}
                          selectData={years}
                          defaultValue={detail.fromYear}
                          funcSelect={selectDetailSetting(activeSheet)}
                          funcChange={changeDetailSetting}
                        />
                      </div>
                    </div>
                  )}
                {detail.fromYear && detail.toYear && (
                  <div className={style.itemContent}>
                    <Span
                      className={style.labelSetting}
                      style={{ fontSize: 11 }}
                    >
                      <Translate value="tool.dataExplorer.YEAR" />
                    </Span>
                    <div className={style.w85}>
                      <ItemSelect
                        keySelect={getKeySelect('fromYear')}
                        selectData={years}
                        defaultValue={detail.fromYear}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                    <div className={style.textTo2}>
                      <Span style={{ fontSize: 11 }}>
                        <Translate value="tool.dataExplorer.TO" />
                      </Span>
                    </div>
                    <div className={style.w85}>
                      <ItemSelect
                        keySelect={getKeySelect('toYear')}
                        selectData={years}
                        defaultValue={detail.toYear}
                        funcSelect={selectDetailSetting(activeSheet)}
                        funcChange={changeDetailSetting}
                      />
                    </div>
                  </div>
                )}
              </div>
              <Button onClick={handleOpenSetting} className={style.btnEdit}>
                <Translate value="tool.dataExplorer.EDIT" />
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default SettingEconomy
