import React from 'react'
import { useSelector } from 'react-redux'
import TableData from '.'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectPayloadCallBack, selectTimeFilter } from './store/slice'
import { downloadDataTable } from './store/thunk'

const TableDataPanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeFilter = useSelector(selectTimeFilter)
  const payloadCallBack = useSelector(selectPayloadCallBack)

  const downloadCsv = () => {
    const params = {
      TimeFrequency: timeFilter,
      Year: payloadCallBack.year,
      Month: payloadCallBack.month,
    }
    return downloadDataTable(params)
  }

  return (
    <Panel
      title={'economy.fdi.overview.TITLE_PANEL_TABLE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <TableData
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
      />
    </Panel>
  )
}

export default TableDataPanel
