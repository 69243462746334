export const steel = {
  STEEL: 'Thép',
  OVERVIEW_TAB: 'Tổng quan',
  CONSTRUCTION_TAB: 'Thép xây dựng',
  STEEL_PIPE_TAB: 'Thép ống',
  STEEL_COAT_TAB: 'Tôn mạ',
  STEEL_COIL_TAB: 'Thép cán',
  PRICE_TAB: 'Giá thép',

  UNIT: 'Đơn vị',

  STATISTICS: 'Số liệu thống kê',
  PEERS_COMPARISON: 'So sánh cùng ngành',
  COMPANY_GROWTH: 'Đánh giá tăng trưởng doanh nghiệp',
  COMPANY_GROWTH_SHORT: 'Đánh giá tăng trưởng',

  FILTER_MONTHLY: 'Hàng tháng',
  FILTER_YEARLY: 'Hàng năm',
  FILTER_ACC_MONTHLY: 'Lũy kế tháng',

  MILLION_TONS: 'Triệu tấn',
  TONS: 'Tấn',
  PERCENT: '%',
  ALL: 'Tất cả',

  COMPANY_NAME: 'Tên công ty',
  TICKER: 'Mã',
  PRODUCTION: 'Sản xuất',
  CONSUMPTION: 'Tiêu thụ',
  TOTAL: 'Tổng',
  NORTH: 'Miền Bắc',
  CENTRAL: 'Miền Trung',
  SOUTH: 'Miền Nam',
  EXPORT: 'Xuất khẩu',
  INVENTORY: 'Tồn kho',

  WARNING_MESS:
    'Số lượng doanh nghiệp tối đa được chọn là 5. Hãy bỏ chọn trước khi thêm mới doanh nghiệp khác.',
}

export const overview = {
  OVERVIEW: 'Tổng quan',
  NEWS_AND_REPORT: 'Tin tức & Báo cáo',
  STEEL_PRODUCTION_AND_CONSUMPTION: 'Sản xuất và tiêu thụ thép',
  PRODUCTION_AND_CONSUMPTION: 'Sản xuất và tiêu thụ',
  INVENTORY: 'Tồn kho',
  STEEL_INVENTORY: 'Tồn kho thép',
  EXPORT: 'Xuất khẩu',
  STEEL_EXPORT: 'Xuất khẩu thép',

  HISTORY: 'Lịch sử của ngành:',
  PRODUCT: 'Các sản phẩm chính:',
  ROLE: 'Vai trò của ngành:',

  FILTER_RADIO_NEWS: 'Tin tức',
  FILTER_RADIO_REPORTS: 'Báo cáo',

  MILLION_USD: 'Triệu USD',
  MILLION_TONS: 'Triệu tấn',

  FOOTER_PRODUCTION: 'Sản xuất',
  FOOTER_CONSUMPTION: 'Tiêu thụ',
  TOOLTIP_INVENTORY: 'Tồn kho',
  TOOLTIP_EXPORT: 'Xuất khẩu',
}

export const construction = {
  TOTAL: 'Tổng',
  STEEL_BAR: 'Thép thanh',
  STEEL_SHAPE: 'Thép hình',
  STEEL_WIRE_ROD: 'Thép cuộn',
}

export const steelPipe = {
  TOTAL: 'Tổng',
  BLACK_PIPE: 'Thép ống đen',
  COATED_PIPE: 'Thép ống mạ',
}

export const steelCoat = {
  TOTAL: 'Tổng',
  STEEL_GAL: 'Tôn mạ kẽm',
  STEEL_PAINTED: 'Tôn mạ màu',
  OTHER: 'Tôn mạ khác',
}

export const steelCoil = {
  TOTAL: 'Tổng',
  HOT_ROLL: 'Thép cán nóng',
  COOL_ROLL: 'Thép cán nguội',
}

export const price = {
  PRICE_STATISTICS: 'Thống kê giá Thép',
  STEEL_PRICE_GROWTH: 'Tăng trưởng giá thép',

  FILTER_VALUE: 'Giá trị',
  FILTER_PERCENT_GROWTH: 'Tăng trưởng',

  PRODUCT: 'Sản phẩm',
  TICKER: 'Mã',

  WARNING_MESS: 'Số lượng chỉ tiêu tối đa được chọn là 20.',
  TABLE_INFO:
    'Giá sản phẩm thép là giá trung bình được công bố bởi các công ty sản xuất',
}

export const fishery = {
  FISHERY: 'Thủy sản',
  OVERVIEW: 'Tổng quan',
  EXPORT_BY_PRODUCT: 'XK theo mặt hàng',
  EXPORT_BY_MARKET: 'XK theo thị trường',
  EXPORTERS: 'Doanh nghiệp xuất khẩu',

  FILTER_MONTHLY: 'Hàng tháng',
  FILTER_YEARLY: 'Hàng năm',
  FILTER_ACC_MONTHLY: 'Lũy kế tháng',

  VALUE: 'Giá trị',
  GROWTH: 'Tăng trưởng YoY',

  OTHER: 'Khác',
  ALL: 'Tất cả',

  UNIT: 'Đơn vị',
  MILLION_USD: 'Triệu USD',
  USD: 'USD',
  PERCENT: '%',

  overview: {
    OVERVIEW: 'Tổng quan',
    NEWS_AND_REPORT: 'Tin tức & Báo cáo',
    EXPORT: 'Xuất khẩu thuỷ sản',
    EXPORT_YEARLY: 'Xuất khẩu thủy sản theo năm',
    EXPORT_PRODUCT: 'Xuất khẩu thủy sản theo mặt hàng',
    EXPORT_ONLY: 'Xuất khẩu',
    IMPORT: 'Nhập khẩu thuỷ sản',
    IMPORT_ONLY: 'Nhập khẩu',
    TOP_MARKET: 'Top 5 thị trường xuất khẩu của Việt Nam',
    TOP_FIVE: 'Top 5 thị trường xuất khẩu',
    MARKET: 'của Việt Nam',

    TITLE_FIRST: 'Lịch sử của ngành:',
    TITLE_SECOND: 'Các sản phẩm chính:',
    TITLE_THIRD: 'Vai trò của ngành:',

    FILTER_RADIO_NEWS: 'Tin tức',
    FILTER_RADIO_REPORTS: 'Báo cáo',
  },

  export: {
    EXPORT_STATISTICS: 'Thống kê giá trị xuất khẩu',
    GROWTH_PRODUCT: 'Tăng trưởng xuất khẩu thuỷ sản theo mặt hàng',
    CONTRIBUTION_PRODUCT: 'Tỷ trọng xuất khẩu thuỷ sản theo mặt hàng',
    GROWTH_MARKET: 'Tăng trưởng xuất khẩu thuỷ sản theo thị trường',
    CONTRIBUTION_MARKET: 'Tỷ trọng xuất khẩu thuỷ sản theo thị trường',
    CONTRIBUTION_BY: 'Tỷ trọng xuất khẩu thuỷ sản sang',
    CONTRIBUTION: 'Tỷ trọng xuất khẩu',
    NOTE_CHART: `*Tỷ trọng xuất khẩu thủy sản được tính bằng giá trị lũy kế tháng cuối cùng của các năm`,

    PRODUCT: 'Mặt hàng',
    MARKET: 'Thị trường',
  },

  exporters: {
    EXPORT_STATISTICS: 'Thống kê giá trị xuất khẩu của doanh nghiệp',
    GROWTH_EXPORTERS:
      'Tăng trưởng xuất khẩu thuỷ sản theo doanh nghiệp xuất khẩu',
    TOP_EXPORTERS: 'Top 5 Doanh nghiệp xuất khẩu',
    TICKER: 'Mã',
  },
}
