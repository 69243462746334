import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectFunctionsIdUrl,
  selectLoading,
  selectPackageFunctions,
} from './store/slice'
import { getAllFunctionsThunk, getPackageFunctionsThunk } from './store/thunk'

const useGetPackageFunctions = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const packageLoading = useSelector(
    selectLoading(loadingKey.PACKAGE_FUNCTIONS),
  )
  const functionLoading = useSelector(selectLoading(loadingKey.FUNCTIONS))
  const packageFunctions = useSelector(selectPackageFunctions)
  const functionsIdUrl = useSelector(selectFunctionsIdUrl)

  useEffect(() => {
    if (!disableCallApi) {
      dispatch(getPackageFunctionsThunk())
      dispatch(getAllFunctionsThunk())
    }
  }, [])

  return {
    loading: packageLoading || functionLoading,
    packageFunctions,
    functionsIdUrl,
  }
}

export default useGetPackageFunctions
