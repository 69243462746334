import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'
import { handleExport } from '../../../../utils/Export'

export const getMajorShareholder = createAsyncThunk(
  'corporate/ownership/majorShareholder/GET_MAJORSHAREHOLDER',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getMajorOwnership(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getHistoricalTooltip = createAsyncThunk(
  'corporate/ownership/majorShareholder/GET_HISTORICAL_TOOLTIP',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getHistoricalTooltip(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getOwnershipTooltip = createAsyncThunk(
  'corporate/ownership/majorShareholder/GET_OWNERSHIP_TOOLTIP',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getOwnershipTooltip(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadMajorShareHolders = createAsyncThunk(
  'corporate/ownership/majorShareholder/DOWNLOAD_MAJOR_SHARE_HOLDERS',
  async (data) => {
    const response = await OwnershipService.downloadMajorShareHolders(data)
    handleExport(response.data, response.filename)
  },
)
