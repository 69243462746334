import { EMPTY_VALUE } from '../../../constants/Common'
import { range } from '../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal, isInValidValue } from '../../../utils/Value'
import { typeOfIndicator } from '../constant'

export const handleIndex = ([min, max], lengthData, countPerView) => {
  if (lengthData <= countPerView) {
    return range(0, countPerView - 1)
  } else if (max - (lengthData - 1) > 0) {
    return range(lengthData - countPerView, lengthData - 1)
  } else return range(min, max)
}

export const handleCellValue = (value, typeOf, locale, timezone, unit) => {
  if (isInValidValue(value)) {
    return EMPTY_VALUE
  }
  if (typeOf) {
    const percentUnit = unit === '%' ? '%' : ''
    switch (typeOf) {
      case typeOfIndicator.SMALLINT:
        return value + percentUnit
      case typeOfIndicator.INT:
        return formatVal(value, 0) + percentUnit
      case typeOfIndicator.DECIMAL:
        return formatVal(value) + percentUnit
      case typeOfIndicator.DATE:
        return isInValidValue(value)
          ? EMPTY_VALUE
          : formatDateTime(value, FORMAT.DATE, locale, timezone)
      default:
        return value
    }
  }
  return value
}
