import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import {
  handleListCollapse,
  handleListDate,
  handleTemplateData,
  handleTemplateSetting,
} from '../helper'
import { getTemplate } from './thunk'

const initialState = {
  templateLoading: true,
  templateData: {},
  listDate: [],
  templateRaw: [],
  activeTemplate: null,
  templateSetting: {},
  listCollapse: {},
}

const slice = createSlice({
  name: 'newsReport/dailyReport/template',
  initialState,
  reducers: {
    handleCollapse: (state, action) => {
      const date = action.payload
      state.listCollapse[date] = !state.listCollapse[date]
    },
    handleActiveTemplate: (state, action) => {
      state.activeTemplate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTemplate.pending, (state) => {
      state.templateLoading = true
    })
    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.templateLoading = false
      state.templateData = handleTemplateData(action.payload)
      state.listDate = handleListDate(Object.keys(state.templateData))
      state.templateRaw = action.payload
      state.templateSetting = handleTemplateSetting(action.payload)
      state.listCollapse = handleListCollapse(state.listDate, state.listDate)
    })
    builder.addCase(getTemplate.rejected, (state, action) => {
      state.templateLoading = action.payload.isLoading
    })
  },
})

export const { handleCollapse, handleActiveTemplate } = slice.actions

export const getTemplateLoading = (state) => state[slice.name].templateLoading
export const getTemplateSavedByDate = (date) => (state) =>
  state[slice.name].templateData[date]
export const getTemplateData = (state) => state[slice.name].templateData
export const getListDate = (state) => state[slice.name].listDate
export const getTemplateRaw = (state) => state[slice.name].templateRaw
export const getActiveTemplate = (state) => state[slice.name].activeTemplate
export const getSettingById = (id) => (state) =>
  state[slice.name].templateSetting[id]
export const getCollapseByDate = (date) => (state) =>
  state[slice.name].listCollapse[date]

register(slice.name, slice.reducer)
