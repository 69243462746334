export const common = {
  table: {
    TABLE_FOOTER_TEXT:
      'Giá: x 1,000. Khối lượng: x 1,000. Giá trị: x 1,000,000',
    SIMPLE_TABLE_FOOTER_TEXT: 'Giá: x 1,000. Khối lượng: x 1,000',
    PRICE_TABLE_FOOTER_TEXT: 'Giá: x 1,000',
    PRICE_VALUE_FOOTER_TEXT: 'Giá: x 1,000. Giá trị: x 1,000,000.',
    LB_PAGE: 'Trang',
    LB_OF: 'trên',
  },
  button: {
    BUTTON_CREATE: 'Tạo',
    BUTTON_SAVE: 'Lưu',
    BUTTON_SAVE_AS: 'Lưu thành',
    BUTTON_CANCEL: 'Hủy bỏ',
    BUTTON_BACK: 'Quay lại',
    BUTTON_OK: 'Ok',
    BUTTON_RESET: 'Reset',
    BUTTON_APPLY: 'Áp dụng',
    BUTTON_DELETE: 'Xóa',
    BUTTON_YES: 'Có',
    BUTTON_NO: 'Không',
    BUTTON_WATCH_LIST: 'Danh mục',
    BUTTON_CLOSE: 'Đóng',
    BUTTON_SEARCH: 'Tìm kiếm',
    BUTTON_EDIT_NAME: 'Sửa tên',
    BUTTON_CHANGE_CORPORATE: 'Doanh nghiệp',
    BUTTON_CHANGE_BOND: 'Trái phiếu',
    BUTTON_NO_SAVE: 'Không lưu',
    BUTTON_EDIT: 'Sửa',
  },
  calendar: {
    DAY: 'Ngày',
    WEEK: 'Tuần',
    MONTH: 'Tháng',
    QUARTER: 'Quý',
    YEAR: 'Năm',
    FROM: 'Từ',
    TO: 'Đến',
  },
  NO_DATA: 'Không dữ liệu',
  DATA_NOT_AVAILABLE: 'Dữ liệu không có sẵn',
  SEARCH: 'Tìm kiếm',
  FILTER: 'Bộ lọc',
  ALL: 'Tất cả',
  ALL_FREQUENCY: 'Tất cả tần suất',
  FREE: 'Miễn phí',
  CONFIRM_REDIRECT: 'Các thay đổi bạn đã thực hiện có thể không được lưu.',
  INDUSTRY_LEVEL: 'Ngành cấp ',
  INDUSTRY_LEVEL_COMPACT: 'Cấp ',
  LABEL_HOHAUP: 'HOHAUP',
  preOpenMarket: {
    marketPreOpenTitle: 'Thị trường sẽ mở cửa trong',
  },
  shareButton: {
    SHARE: 'Chia sẻ',
    COPIED_TO_CLIPBOARD: 'Đã sao chép vào clipboard',
    COPY_LINK: 'Sao chép',
  },
  tradingView: {
    LOAD_TEMPLATE: 'Tải Biểu Mẫu',
    SAVE_TEMPLATE: 'Lưu Biểu Mẫu',
    SHARE: 'Chia Sẻ',
    NAME_OF_TEMPLATE: 'Tên biểu đồ:',
    DESCRIPTION: 'Mô tả:',
    TEMPLATE_LIBRARY: 'Thư Viện Mẫu',
    MY_TEMPLATE: 'Mẫu Của Tôi',
    DELETE_TEMPLATE: 'Xóa Biểu Mẫu',
    REVENUE: 'Doanh Thu',
    PROFIT: 'Lợi Nhuận',
    PERIOD_ENDING: 'Kỳ Báo Cáo',
    DIVIDENDS: 'Cổ Tức',
    EX_RATIO: 'Tỷ Lệ Chi Trả',
    EXRIGHT_DATE: 'Ngày GDKHQ',
    ISSUE_METHOD: 'Hình Thức Phát Hành',
    VOLUME: 'Khối Lượng',
    STOCK: 'Cổ phiếu',
    FUTURES: 'Phái sinh',
    INDICES: 'Chỉ số',
    ECONOMY: 'Vĩ mô',
    COVERED_WARRANT: 'Chứng quyền',
    ALL: 'Tất cả',
  },
  linkSectorTicker: {
    SECTOR_OVERVIEW: 'Tổng quan ngành',
    FINANCIAL_ANALYSIS: 'Phân tích tài chính',
    SEGMENTATION: 'Biểu đồ phân tán ngành',
    RANKING: 'Xếp hạng cổ phiếu',
    RATIO: 'Chỉ số ngành',
    VALUATION: 'Định giá',
    COMPANY_OVERVIEW: 'Tổng quan doanh nghiệp',
    COMPANY_NEWS: 'Tin tức doanh nghiệp',
    FINANCIAL_DATA: 'Dữ liệu tài chính',
    OWNERSHIP: 'Cơ cấu sở hữu',
    TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  },
  popup: {
    DONT_SHOW_AGAIN: 'Không hiển thị lại',
    ICB_LEVEL: 'Ngành cấp',
    INDUSTRY_LEVEL: 'Cấp ngành',
    DIVIDEND_YEAR: 'Năm trả cổ tức',
    DIVIDEND_STAGE: 'Kỳ trả cổ tức',
    MOST_RECENT: 'Kỳ trả gần nhất',
    ALL: 'Tất cả',
    YEAR: 'Năm',
    SELECT: 'Đã chọn:',
    MAX_MORE_CONDITION: 'Số lượng chỉ tiêu không vượt quá 250.',
    MOST_RECENT_2: 'Gần nhất',
    DATE: 'Ngày',
    SIX_MONTH_AGO: '6 tháng trước',
    ONE_WEEK_AGO: '1 tuần trước',
    NINE_MONTH_AGO: '9 tháng trước',
    ONE_MONTH_AGO: '1 tháng trước',
    ONE_YEAR_AGO: '1 năm trước',
    THREE_MONTH_AGO: '3 tháng trước',
    TWO_YEAR_AGO: '2 năm trước',
    STOCK_DIVIDEND: 'Cổ tức bằng cổ phiếu',
    YEAR_OF_REVIEW: 'Năm đánh giá',
    ISSUE_METHOD: 'Phương pháp phát hành',
    STAGE: 'Kỳ',
    ACTION: 'Hành động',
    ERROR_QUARTER: 'Vui lòng chọn "Quý" để hiển thị chỉ số TTM.',
    ERROR_TYPE:
      'Hãy chọn một trong hai loại chỉ số: chỉ số TTM hoặc chỉ số năm.',
    OF_TOTAL_TABLE: ' tổng số chỉ số được thêm vào bảng kết quả.',
    TITLE_TTM: 'Chỉ số TTM',
    MULTIPLE_REPORTING: 'Nhiều kỳ báo cáo',
    QUARTER: 'Quý',
    TITLE_YEAR: 'Chỉ số hàng năm',
    TO: 'đến',
    INDICATOR: 'Chỉ báo',
    SEARCH: 'Tìm kiếm',
    SELECT_ITEM: 'Các mục đã chọn',
    PERIOD: 'Giai đoạn',
    MOST_YEAR_RECENT: 'Năm gần nhất',
    MOST_QUARTER_RECENT: 'Quý gần nhất',
    REPORT_TYPE: 'Loại báo cáo',
    AUDIT: 'Loại kiểm toán',
    CONSOLIDATED: 'Hợp nhất',
    UNCONSOLIDATED: 'Công ty mẹ',
    BOTH_AUDIT: 'Đã kiểm toán và chưa kiểm toán',
    AUDITED: 'Đã kiểm toán',
    UNAUDITED: 'Chưa kiểm toán',
    SIX_MONTH: '6 tháng',
    NINE_MONTH: '9 tháng',
    ANNUAL: 'Hàng năm',
    SETTING_GROUP_NOTE: 'Đồng bộ cấu hình các chỉ số đã chọn.',
    SORT_COLUMN_NOTE:
      'Kéo thả chỉ số về vị trí mong muốn hoặc click đúp chuột vào số thứ tự và nhập số mới để sắp xếp lại thứ tự của chỉ số.',
    SORT_INDICATOR: 'SẮP XẾP CHỈ SỐ',
  },
  news: {
    VIEW_ALL: 'Xem tất cả',
    NEWS_TYPE: 'Loại tin tức',
    DETAIL_NEWS_TYPE: 'Chi tiết loại tin tức',
    SOURCE: 'Nguồn',
    TIME: 'Thời gian',
    time: {
      All: 'Tất cả',
      'Last 7 days': '7 ngày trước',
      'Last month': 'Tháng trước',
      'Last 3 months': '3 tháng trước',
      'Last 6 months': '6 tháng trước',
      'Last year': 'Năm trước',
      Custom: 'Tùy chọn',
    },
  },
  noteIcb: {
    NOTE_1: '* Tham khảo Phương pháp luận Phân Ngành của FiinGroup ',
    NOTE_2: 'tại đây.',
  },
  FUNCTION_COMING_SOON: 'Tính năng đang được phát triển',
  MONTH: {
    JAN: 'Tháng 01',
    FEB: 'Tháng 02',
    MAR: 'Tháng 03',
    APR: 'Tháng 04',
    MAY: 'Tháng 05',
    JUN: 'Tháng 06',
    JUL: 'Tháng 07',
    AUG: 'Tháng 08',
    SEP: 'Tháng 09',
    OCT: 'Tháng 10',
    NOV: 'Tháng 11',
    DEC: 'Tháng 12',
  },
  MONTHLY_OPTION: {
    MONTH_1: 'Th01',
    MONTH_2: 'Th02',
    MONTH_3: 'Th03',
    MONTH_4: 'Th04',
    MONTH_5: 'Th05',
    MONTH_6: 'Th06',
    MONTH_7: 'Th07',
    MONTH_8: 'Th08',
    MONTH_9: 'Th09',
    MONTH_10: 'Th10',
    MONTH_11: 'Th11',
    MONTH_12: 'Th12',
  },
  OTHERS: 'Khác',
  MONTHS: 'tháng',
  YEARS: 'năm',
  UNIT: {
    PERCENT: '%',
    VND: 'VNĐ',
    THOUSAND_VND: 'Nghìn VNĐ',
    MILLION_VND: 'Triệu VNĐ',
    BILLION_VND: 'Tỷ VNĐ',
    USD: 'USD',
    THOUSAND_USD: 'Nghìn USD',
    MILLION_USD: 'Triệu USD',
    BILLION_USD: 'Tỷ USD',
  },
  FREQUENCY: {
    DAILY: 'Hàng ngày',
    WEEKLY: 'Hàng tuần',
    MONTHLY: 'Hàng tháng',
    QUARTERLY: 'Hàng quý',
    YEARLY: 'Hàng năm',
  },
}
