import React, { useMemo, useRef } from 'react'
import SelectCustom from '../../../common/selectCustom'
import { ReactComponent as IconAddNew } from '../../icon/icon-add-new.svg'
import style from '../style.module.css'
import Tabs from './Tabs'

export const MenuFooterDashboard = ({
  chartTabs,
  activeTab,
  onChangeTab,
  onAddChart,
  disableAddChart,
}) => {
  const menuTabRef = useRef()
  const buttonMenuRef = useRef()

  const getTabs = useMemo(() => {
    const listTab = [
      ...Object.keys(chartTabs).map((key) => ({
        name: chartTabs[key]?.name || chartTabs[key]?.nameChart?.name,
        value: +key,
      })),
    ]
    return listTab
  }, [chartTabs])

  const isFirstTab = useMemo(() => {
    if (getTabs.length) return activeTab === getTabs[0].value
  }, [activeTab, getTabs])

  const isLastTab = useMemo(() => {
    if (getTabs.length) return activeTab === getTabs[getTabs.length - 1].value
  }, [activeTab, getTabs])

  const handleNextTab = () => {
    if (isLastTab) return
    onChangeTab(activeTab + 1)
    const el = document.getElementById('chartTab' + (activeTab + 1))
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }

  const handlePrevTab = () => {
    if (isFirstTab) return
    onChangeTab(activeTab - 1)
    const el = document.getElementById('chartTab' + (activeTab - 1))
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    })
  }

  const handleAddChart = () => {
    if (disableAddChart) return
    onAddChart && onAddChart()
  }

  return (
    <div className={style.menuFooterWrap}>
      <div ref={menuTabRef} className={style.menuFooter}>
        <div className={style.wrapIcon} onClick={handlePrevTab}>
          <i
            className={`icon-caret-left`}
            style={{ color: isFirstTab ? '#8c9194' : '#585858' }}
          />
        </div>
        <div className={style.wrapIcon} onClick={handleNextTab}>
          <i
            className={`icon-caret-right`}
            style={{ color: isLastTab ? '#8c9194' : '#585858' }}
          />
        </div>
        <SelectCustom
          value={activeTab}
          selectData={getTabs}
          appendDropdownStyle={{
            backgroundColor: '#ffffff',
          }}
          isI18n
          themeWhite
          isDropdownFitContent
          handleChange={onChangeTab}
          renderSelect={() => {
            return (
              <div ref={buttonMenuRef} className={style.wrapIcon}>
                <i className={`icon-hum`} />
              </div>
            )
          }}
        />
        <Tabs
          menuTabRef={menuTabRef}
          data={getTabs}
          activeTab={activeTab}
          onChangeTab={onChangeTab}
        />
        <div
          className={style.wrapIcon}
          style={
            disableAddChart
              ? {
                  pointerEvents: 'none',
                  opacity: 0.4,
                }
              : { cursor: 'pointer' }
          }
        >
          <IconAddNew
            onClick={handleAddChart}
            className={style.iconPlusCircleLine}
          />
        </div>
      </div>
    </div>
  )
}
