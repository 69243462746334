import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MonetaryService from '../../../../../../core/services/economy/MonetaryService'
import { handleExport } from '../../../../../utils/Export'

export const getCurrencyExchangeRate = createAsyncThunk(
  'economy/monetary/exchangeRate/panelExchangeRate/getCurrencyExchangeRate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await MonetaryService.getCurrencyExchangeRate(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadCurrencyExchangeRate = createAsyncThunk(
  'economy/monetary/exchangeRate/panelExchangeRate/downloadCurrencyExchangeRate',
  async (data) => {
    const res = await MonetaryService.downloadCurrencyExchangeRate(data)
    handleExport(res.data, res.filename)
  },
)
