import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../html/Button'
import { Span } from '../html/Span'
import Popup from '../popup'
import TextEllipsis from '../textEllipsis'
import style from './index.module.css'

const PopupSaveTemplate = ({
  handleHidePopupSaveAs,
  templates,
  handleSubmit,
}) => {
  const [templateName, setTemplateName] = useState('')
  const [error, setError] = useState()

  const handleChangeInput = (e) => {
    const value = e.target.value
    setTemplateName(value)
    if (value === '') {
      setError('tool.smartScreening.TEMPLATE_REQUIRE')
    } else if (
      templates.some((template) => template.templateName === value.trim())
    ) {
      setError('tool.smartScreening.TEMPLATE_EXIST_2')
    } else {
      setError(null)
    }
  }

  const handleSaveTemplate = () => {
    handleSubmit(templateName)
  }

  const disabledButton = error !== null

  return (
    <Popup>
      <div className="modal modal-small w-450">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.smartScreening.BTN_SAVE_AS" />
            </Span>
          </h3>
          <a onClick={handleHidePopupSaveAs} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <div className="content">
            <div className="form-control form-vertical ali-center j-b">
              <label className={`mb-0 ${style.labelPopup}`}>
                <Span>
                  <TextEllipsis text="tool.smartScreening.LABEL_NAME_TEMPLATE" />
                </Span>
              </label>
              <input
                value={templateName}
                type="text"
                className={`w-300 bg-grey-4 ${style.inputPopup}`}
                onChange={handleChangeInput}
              />
            </div>
            {error && (
              <div className="text-error t-right">
                <Span>
                  <Translate value={error} />
                </Span>
              </div>
            )}
          </div>
          <div className="mt-40 group-btn justify-content-end">
            <Button
              onClick={handleHidePopupSaveAs}
              className={`btn normal h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <Button
              onClick={handleSaveTemplate}
              disabled={disabledButton}
              className={`btn btn-blue ${
                disabledButton ? 'diasble' : ''
              } h-20 ${style.fontWeightNormal}`}
            >
              <Translate value="common.button.BUTTON_SAVE" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupSaveTemplate.propTypes = {
  handleHidePopupSaveAs: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default PopupSaveTemplate
