import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'

export const getImportChangeTurnoverByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getImportChangeTurnoverByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getImportChangeTurnoverByProduct(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getExportChangeTurnoverByProduct = createAsyncThunk(
  'economy/importExport/importExportByProduct/getExportChangeTurnoverByProduct',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getExportChangeTurnoverByProduct(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxDateDetail = createAsyncThunk(
  'economy/importExport/importExportByProduct/getMaxDateDetail',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getMaxDateDetail(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
