import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../../../../utils/Datetime'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getCurrentDate } from '../../../common/helper'
import { CHART_TYPE } from '../constants'
import { getSectorIds, getValueById } from '../helper'
import { getSectorSegmentation } from './thunk'

const initialState = {
  loading: true,
  displayChartType: CHART_TYPE.BUY,
  startDate: getDateTimeFrom(new Date(), -1, 'y', FORMAT.DATE),
  endDate: getCurrentDateTime(FORMAT.DATE),
  dates: [],
  ids: [],
  valueById: {},
  reCalcWidths: false,
  initialIds: [],
  limitDatepicker: {
    minDate: '',
    minYear: '',
    maxDate: '',
    maxYear: '',
  },
}

export const slice = createSlice({
  name: 'market/marketInDepth/foreign/sectorSegmentation',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeDisplayChartType: (state, action) => {
      state.displayChartType = action.payload
    },
    changeFilterDate: (state, action) => {
      state.startDate = action.payload.startDate
      state.endDate = action.payload.endDate
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.valueById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSectorSegmentation.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getSectorSegmentation.fulfilled, (state, action) => {
      state.reCalcWidths = !state.reCalcWidths
      state.loading = false
      const value = action.payload[0]
      state.limitDatepicker = {
        minDate: value?.minDate,
        minYear: new Date(value?.minDate).getFullYear(),
        maxDate: value?.maxDate,
        maxYear: new Date(value?.maxDate).getFullYear(),
      }
      let dates = []
      const data = action.payload.map((item) => {
        const formatDate = formatDateTime(item.tradingDateId, FORMAT.DATE)
        if (!dates.includes(formatDate)) dates.push(formatDate)
        return {
          ...item,
          date: formatDate,
        }
      })
      dates = dates.sort((a, b) => new Date(b) - new Date(a))
      const currentDate = getCurrentDate(data, dates)
      const sectorIds = getSectorIds(data, currentDate)
      const valueById = getValueById(data, sectorIds, dates, currentDate)
      state.dates = dates
      state.ids = state.initialIds = sectorIds
      state.valueById = valueById
    })
    builder.addCase(getSectorSegmentation.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectState = (state) => state[slice.name]
export const selectDisplayChartType = (state) =>
  state[slice.name].displayChartType
export const selectLoading = (state) => state[slice.name].loading
export const selectStartDate = (state) => state[slice.name].startDate
export const selectEndDate = (state) => state[slice.name].endDate
export const selectDates = (state) => state[slice.name].dates
export const selectIds = (state) => state[slice.name].ids
export const selectValueById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].valueById[id], attr)
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectLimitDatepicker = (state) =>
  state[slice.name].limitDatepicker

export const { resetStore, changeDisplayChartType, changeFilterDate, sort } =
  slice.actions

register(slice.name, slice.reducer)
