export const chartFillColor = {
  rbcs: '#1f669a',
  rbcsyg: '#facc5c',
}

export const chartBarKeys = ['rbcs']

export const chartLineKeys = ['rbcsyg']

export const chartMappingDisplayName = {
  rbcs: 'sector.financialAnalysis.security.performance.REVENUE_BROKERAGE_CUSTOMER_SERVICE',
  rbcsyg: 'sector.financialAnalysis.security.performance.YOY_GROWTH',
}

export const chartFormat = {
  rbcs: 10 ** 9,
  rbcsyg: 0.01,
}
