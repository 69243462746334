export const sortObjByQuarterAndYear = (data, isAsc = false) => {
  const sorted = isAsc
    ? data?.sort((a, b) => {
        if (a.year !== b.year) return a.year - b.year
        return a.quarter - b.quarter
      })
    : data?.sort((a, b) => {
        if (a.year !== b.year) return b.year - a.year
        return b.quarter - a.quarter
      })

  return sorted
}

export const sortObjByYear = (data) => {
  const sorted = data?.sort((a, b) => b.year - a.year)

  return sorted
}

export const getQuarterAndYearKey = (quarter, year, isYearly, isQuarterly) => {
  const QUARTER_FIVE = 5
  const QUARTER_SIX = 6
  const QUARTER_NINE = 9
  const QUARTER_ONE = 1

  return isYearly
    ? `${year}`
    : isQuarterly
    ? quarter === QUARTER_ONE
      ? `Q1-${year}`
      : quarter === QUARTER_FIVE
      ? `4Q-${year}`
      : quarter === QUARTER_SIX
      ? `2Q-${year}`
      : quarter === QUARTER_NINE
      ? `3Q-${year}`
      : `${quarter}Q-${year}`
    : `Q${quarter}-${year}`
}
