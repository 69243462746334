import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../common/panel'
import { valueTab } from '../infoSec/constants'
import {
  selecBondIds,
  selectActiveTab,
  selectCreateBondId,
} from '../infoSec/store/slice'
import Content from './Content'
import { resetStore } from './store/slice'
import { exportFile, getData } from './store/thunk'

const Index = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const bondIds = useSelector(selecBondIds)
  const locale = useSelector((state) => state.i18n.locale)
  const createBondId = useSelector(selectCreateBondId)
  const activeTab = useSelector(selectActiveTab)

  useEffect(() => {
    if (activeTab === valueTab.BOND_DEFAULT) {
      if (bondIds) {
        dispatch(
          getData({
            BondId: bondIds,
            isUserBond: false,
          }),
        )
      }

      return
    }

    if (!createBondId) {
      dispatch(resetStore())
      return
    }

    dispatch(
      getData({
        BondId: createBondId,
        isUserBond: true,
      }),
    )
  }, [bondIds, locale, activeTab, createBondId])

  const downloadexel = () => {
    return exportFile({
      BondId: activeTab === valueTab.BOND_DEFAULT ? bondIds : createBondId,
      isUserBond: activeTab !== valueTab.BOND_DEFAULT,
    })
  }

  return (
    <>
      <Panel
        title="bond.valuation.valuation.sameIndusTryBond"
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadCsv={true}
        downloadCsv={() => downloadexel()}
      >
        <div className="h-100">
          <Content />
        </div>
      </Panel>
    </>
  )
}

export default Index
