import { useSelector } from 'react-redux'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { getTimeFrameTitle } from '../foreign/netValue/helper'
import NetValue from './netValue'
import { selectTimeFrame } from './netValue/store/slice'

const PanelNetValue = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  width,
  height,
}) => {
  const nameScreen = UseI18n(
    'market.marketInDepth.localInstitution.NAME_SCREEN',
  )
  const chartName = UseI18n('market.marketInDepth.localInstitution.NET_VALUE')
  const timeFrame = useSelector(selectTimeFrame)
  const indexInfo = useSelector(selectIndexInfo)

  return (
    <Panel
      title={'market.marketInDepth.localInstitution.NET_VALUE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={UseI18n('market.marketInDepth.localInstitution.NET_VALUE')}
      downloadJpgParams={{
        domId: 'market-localInstitution-net-value',
        nameScreen,
        chartName,
        tabName: getTimeFrameTitle(timeFrame),
        tickerName: indexInfo?.groupCode || '',
      }}
    >
      <NetValue width={width} height={height} />
    </Panel>
  )
}

export default PanelNetValue
