import { useDispatch } from 'react-redux'
import { Search } from '../../../../common/search'
import { changeAnalyticFilter } from '../store/slice'
import { AnalyticFilter } from './AnalyticFilter'
import style from './header.module.css'

export const Header = () => {
  const dispatch = useDispatch()

  const onChange = (search) => {
    dispatch(changeAnalyticFilter({ search }))
  }
  return (
    <div className={style.container}>
      <Search onChange={onChange} />
      <AnalyticFilter />
    </div>
  )
}
