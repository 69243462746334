import { useSelector } from 'react-redux'
import { selectIcbInfoById } from '../../../../../common/masterData/store/slice'
import style from './index.module.css'

const SelectedSectorName = ({ icbId }) => {
  const sectorName = useSelector(selectIcbInfoById(icbId, 'icbName'))
  const sectorLevel = useSelector(selectIcbInfoById(icbId, 'icbLevel'))

  return (
    <div className={style.tickeNameItem}>
      <div
        style={{
          width: '25%',
          whiteSpace: 'nowrap',
          color: '#fecf2f',
        }}
      >
        {sectorName + ' L' + sectorLevel}
      </div>
    </div>
  )
}

export default SelectedSectorName
