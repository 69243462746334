import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable'
import SelectCustom from '../../../../../common/selectCustom'
import { YEAR_OPTIONS } from './constant'
import style from './index.module.css'
import { selectFilterChart1, updateFilterChart1 } from './store/slice'

const FilterChartRadar = () => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const commonTopIssuer = maxMinTableById['FGFB_CORA_DE_FinancialRatioTTM']

  const filter = useSelector(selectFilterChart1)

  const onChangeFilter = (value) => {
    dispatch(updateFilterChart1(value))
  }

  useEffect(() => {
    onChangeFilter(
      commonTopIssuer?.max_YearReport || new Date().getFullYear(),
      'year',
    )
  }, [commonTopIssuer])

  return (
    <div className="d-flex justify-content-end" data-html2canvas-ignore="true">
      <div className={style.selectYearCustom}>
        <SelectCustom
          isI18n={true}
          selectData={YEAR_OPTIONS}
          value={filter}
          handleChange={(e) => onChangeFilter(e, 'year')}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
    </div>
  )
}

export default FilterChartRadar
