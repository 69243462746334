import moment from 'moment'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { Table } from '../../../../common/table'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { EMPTY_ROW_ID } from './config'
import { getTableHeaderAttribute } from './constants'
import FirstCellColumn from './FirstCellColumn'
import {
  defineGroupColumns,
  findNearestDate,
  handleCollapseLevel,
} from './helper'
import OtherCellColumn from './OtherCellColumn'
import {
  getExchangeRateValue,
  getFromDate,
  getFromISODateView,
  getHeaderDate,
  getIds,
  getIsDecrease,
  getIsRecalculateSliceValue,
  getIsShowAlert,
  getSliceValue,
  getTableData,
  getTableDataLoading,
  getTimeRange,
  handleAllPageSorted,
  handleCurrentPage,
  handleFromISODateView,
  handleFromISOMaxDateParam,
  handleFromISOMinDateParam,
  handleIsRecalculateSliceValue,
  handleIsShowAlert,
  handleSliceValue,
} from './store/slice'
import { getCurrencyExchangeRate } from './store/thunk'
import TableHeader from './TableHeader'

const HEADER_CUSTOM_HEIGHT = 54
export const PAGE_NUMBER = 3
const COL_4 = 3
const COL_7 = 6

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()
  const previousPage = useRef([])
  const [rowCollapse, setRowCollapse] = useState([])

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableData = useSelector(getTableData)
  const timeRange = useSelector(getTimeRange)
  const fromDate = useSelector(getFromDate)
  const ids = useSelector(getIds)
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const headerDate = useSelector(getHeaderDate)
  const sliceValue = useSelector(getSliceValue)
  const fromISODateView = useSelector(getFromISODateView)
  const isRecalculateSliceValue = useSelector(getIsRecalculateSliceValue)
  const isDecrease = useSelector(getIsDecrease)
  const isShowAlert = useSelector(getIsShowAlert)

  const handleFromISODateParam = (headerDateWithFormat) => {
    if (headerDateWithFormat.length) {
      dispatch(handleFromISOMinDateParam(headerDateWithFormat[0].date))
      dispatch(
        handleFromISOMaxDateParam(
          headerDateWithFormat[headerDateWithFormat.length - 1].date,
        ),
      )

      if (isRecalculateSliceValue) {
        const firstDateViewIndex = handleFirstDateViewIndex(
          headerDateWithFormat,
          fromDate,
        )
        dispatch(
          handleSliceValue(
            isDecrease ? firstDateViewIndex : firstDateViewIndex + PAGE_NUMBER,
          ),
        )
        dispatch(handleIsRecalculateSliceValue(false))
      }
    }
  }

  const handleFirstDateViewIndex = (headerDateWithFormat, fromDate) => {
    const firstDateViewIndex = headerDateWithFormat.findIndex(
      (item) => item.dateAfterFormat === fromISODateView,
    )
    if (firstDateViewIndex === -1 && !fromDate) {
      return headerDateWithFormat.length
    }

    if (fromDate) {
      return firstDateViewIndex === -1
        ? findNearestDate(headerDateWithFormat, fromDate)
        : firstDateViewIndex + PAGE_NUMBER
    }

    return firstDateViewIndex
  }

  const headerDateWithFormat = useMemo(() => {
    const headerDateWithFormat = headerDate
      .map((item) => {
        return {
          date: item,
          dateAfterFormat: formatDateTime(item, FORMAT.DATE, locale, timeZone),
          timeStampValue: +new Date(item),
        }
      })
      .sort((a, b) =>
        moment(a.dateAfterFormat, FORMAT.DATE[locale]).diff(
          moment(b.dateAfterFormat, FORMAT.DATE[locale]),
        ),
      )

    handleFromISODateParam(headerDateWithFormat)
    dispatch(handleAllPageSorted(headerDateWithFormat))

    return headerDateWithFormat
  }, [headerDate])

  const currentPage = useMemo(() => {
    if (
      sliceValue >= PAGE_NUMBER &&
      headerDateWithFormat.length &&
      sliceValue <= headerDateWithFormat.length
    ) {
      const currentPage = headerDateWithFormat.slice(
        sliceValue - PAGE_NUMBER,
        sliceValue,
      )
      previousPage.current = currentPage

      return currentPage
    }

    return previousPage.current
  }, [JSON.stringify(headerDateWithFormat), sliceValue])

  const groupColumn = defineGroupColumns(
    currentPage.map((item) => getISOStartOrEndOfDay(item.date, timeZone, true)),
  )

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowCollapse.indexOf(rowId)
      if (index === -1) {
        setRowCollapse((old) => [...old, rowId])
      }
    } else {
      setRowCollapse((old) => old.filter((item) => item !== rowId))
    }
  }

  const renderThead = (tableHeaderColAttr) => {
    return <TableHeader tableHeaderColAttr={tableHeaderColAttr} />
  }

  const disableRowHovered = (ids) => {
    return ids.reduce((previous, current) => {
      return {
        ...previous,
        [current]: true,
      }
    }, {})
  }

  useEffect(() => {
    dispatch(
      getCurrencyExchangeRate({
        TimeFrequency: timeRange,
        From: fromDate
          ? getISOStartOrEndOfDay(fromDate, timeZone, true)
          : getISOStartOrEndOfDay(new Date(), timeZone, true),
      }),
    )
  }, [timeRange, fromDate, locale])

  useEffect(() => {
    dispatch(handleCurrentPage(currentPage))
    dispatch(handleFromISODateView(currentPage[0]?.dateAfterFormat))
  }, [headerDate, sliceValue])

  useEffect(() => {
    dispatch(handleFromISODateView(''))
  }, [timeRange])

  if (tableDataLoading) {
    return (
      <div style={{ height }}>
        <Loading />
      </div>
    )
  }

  if (Object.keys(tableData).length === 0) {
    return (
      <div style={{ height }}>
        <NoData />
      </div>
    )
  }

  const rowIds = ids.map((item) => item.id)

  return (
    <div style={{ height }}>
      <Table
        ids={rowIds}
        columnDraggable={false}
        getDataFromRedux={getExchangeRateValue}
        rowDraggable={false}
        renderHeader={() =>
          renderThead(
            getTableHeaderAttribute(
              currentPage.map((item) => item.dateAfterFormat),
            ),
          )
        }
        schema={groupColumn.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }
          if (index === 0) {
            return {
              ...result,
              tdStyle: {
                minWidth: 240,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHorizontalHead = ids.find(
                  (item) => item.id === rowId,
                )
                return (
                  <FirstCellColumn
                    val={tableHorizontalHead.title}
                    props={props}
                    rowId={tableHorizontalHead.id}
                    level={tableHorizontalHead.level}
                    onRowCollapse={onRowCollapse}
                    rowCollapse={rowCollapse}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            tdStyle: {
              textAlign: 'right',
              fontSize: 12,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              const isBorderLeft = index === COL_4 || index === COL_7
              return (
                <OtherCellColumn
                  val={val}
                  rowId={rowId}
                  props={props}
                  isBorderLeft={isBorderLeft}
                />
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight={true}
        hasUpdatedBorder={false}
        verticalTrackTop={HEADER_CUSTOM_HEIGHT}
        rowsCollapse={rowCollapse}
        isCollapse={true}
        levelCollapse={handleCollapseLevel(ids)}
        disableRowHovered={disableRowHovered(EMPTY_ROW_ID)}
      />
      <PopupAlert
        message={<Translate value={'economy.monetary.monetary.MAX_ITEM'} />}
        isShow={isShowAlert}
        handleClose={() => {
          dispatch(handleIsShowAlert(false))
        }}
      />
    </div>
  )
}

export default TableComponent
