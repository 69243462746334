import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { FILTER } from '../constants'
import { getListNews, getListReport } from './thunk'

const initialState = {
  filter: FILTER.NEWS,
  isLoadingNews: true,
  dataNews: [],
  totalPageNews: 1,
  isLoadingResearch: true,
  dataResearch: [],
  totalPageResearch: 1,
}

export const slice = createSlice({
  name: 'sector/sectorConstituents/overview/newsAndEvents',
  initialState,
  reducers: {
    changeTabFilter: (state, action) => {
      state.filter = action.payload
    },
    clearData: (state) => {
      state.dataNews = []
      state.totalPageNews = 1
      state.dataResearch = []
      state.totalPageResearch = 1
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListNews.pending, (state) => {
      if (state.totalPageNews === 1) {
        state.isLoadingNews = true
      }
    })
    builder.addCase(getListNews.fulfilled, (state, action) => {
      state.dataNews = action.payload.data.length
        ? [...state.dataNews, ...action.payload.data]
        : []
      state.totalPageNews = action.payload.totalPage
      state.isLoadingNews = false
    })
    builder.addCase(getListNews.rejected, (state, action) => {
      state.isLoadingNews = action.payload.loading
    })

    builder.addCase(getListReport.pending, (state) => {
      if (state.totalPageResearch === 1) {
        state.isLoadingResearch = true
      }
    })
    builder.addCase(getListReport.fulfilled, (state, action) => {
      state.dataResearch = action.payload.data.length
        ? [...state.dataResearch, ...action.payload.data]
        : []
      state.totalPageResearch = action.payload.totalPage
      state.isLoadingResearch = false
    })
    builder.addCase(getListReport.rejected, (state, action) => {
      state.isLoadingResearch = action.payload.loading
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter
export const selectLoadingNews = (state) => state[slice.name].isLoadingNews
export const selectDataNews = (state) => state[slice.name].dataNews
export const selectTotalPageNews = (state) => state[slice.name].totalPageNews
export const selectLoadingResearch = (state) =>
  state[slice.name].isLoadingResearch
export const selectDataResearch = (state) => state[slice.name].dataResearch
export const selectTotalPageResearch = (state) =>
  state[slice.name].totalPageResearch

export const { changeTabFilter, clearData } = slice.actions

register(slice.name, slice.reducer)
