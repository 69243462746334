import { createSlice, current } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import { maxSheet } from '../../../../common/dataExplorer/Sheet'
import {
  typeOfIndicator,
  typeWorkSpace,
} from '../../../../common/dataExplorer/constant'
import {
  checkDeleteGroup,
  funcSortAsc,
  funcSortDesc,
  getArrColumnIdByIndicatorId,
  getIgnoreColor,
  getListColumnId,
  getObjStringCheckDuplicateCondition,
  randomColor,
} from '../../../../common/dataExplorer/helper'
import { deepCopy } from '../../../../common/dataExplorer/popupIndicator/helper'
import { ADD_ROW } from '../../../../common/dataExplorer/table/ListenerScrollTable'
import { SORT_TYPES } from '../../../../common/dataExplorer/table/constant'
import { keyBy, uuid } from '../../../../utils/Common'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { register } from '../../../../utils/ReducerRegistry'
import { isInValidValue } from '../../../../utils/Value'
import { vndUnit } from '../../popupTemplate/FinancialStatement'
import { maxTicker, typePopupIndicator } from '../constant'
import { checkMaxTicker, sortFiinXTemplateResult } from '../helper'
import {
  getActionTypeThunk,
  getFiinXTemplateResultThunk,
  getFiinXTemplateShareIssueMethodThunk,
  getFiinXTemplatesThunk,
  getIndicatorThunk,
  getListTickerExchangeThunk,
  getListTickerThunk,
  getMostRecentThunk,
  getResultThunk,
  getShareIssueMethodThunk,
  getShareTemplateThunk,
  getTemplateThunk,
  getTemplatesThunk,
  getWorkSpaceThunk,
} from './thunk'

const initialSheet = {
  tickerById: {},
  tickerId: [],
  columnId: [],
  columnById: {},
  tickerDisplay: [],
  groupColumnById: {},
  defaultTickerId: [],
  newTickerId: [],
  newGroupId: [],
  isLoad: false,
  isLoadFiinXTemplate: false,
}

export const keys = {
  INDICATOR: 'indicator',
  TABLE_RESULT: 'tableResult',
  LIST_TICKER: 'listTicker',
}

const initialState = {
  loading: {
    [keys.INDICATOR]: false,
    [keys.TABLE_RESULT]: true,
    [keys.LIST_TICKER]: false,
  },
  sheets: {
    1: initialSheet,
  },
  listSheet: [{ title: 'SHEET 1', value: 1 }],
  activeSheet: 1,
  indicator: [],
  indicatorById: {},
  detailPopupIndicator: null,
  shareIssueMethod: [],
  fiinXTemplateShareIssueMethod: [],
  actionType: [],
  stockById: {},
  templates: [],
  fiinXTemplates: [],
  mostRecent: {},
  detailFiinXTemplate: null,
  isLoadWorkSpace: false,
  listTicker: [],
  tickerFilter: null,
}

export const slice = createSlice({
  name: 'dataExplorer/corporate',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // sheet
    changeActiveSheet(state, action) {
      state.activeSheet = action.payload
    },
    addSheet(state, action) {
      state.sheets[action.payload] = initialSheet
    },
    removeSheet(state, action) {
      delete state.sheets[action.payload.value]
      if (action.payload.templateId) {
        let countTemplate = 1
        state.listSheet.forEach((sheet, index) => {
          if (sheet.templateId === action.payload.templateId) {
            state.listSheet[index].title =
              action.payload.templateName +
              (countTemplate !== 1 ? ` (${countTemplate})` : '')
            countTemplate += 1
          }
        })
      }
    },
    changeListSheet(state, action) {
      state.listSheet = action.payload
    },
    // ticker
    addTicker(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const length = activeSheet.tickerId.length + action.payload.length
      checkMaxTicker(
        length,
        () => {
          let everyExist = true
          action.payload.forEach((ticker) => {
            if (!activeSheet.tickerById[ticker.organizationId]) {
              activeSheet.tickerId.push(ticker.organizationId)
              activeSheet.newTickerId.push(ticker.organizationId)
              activeSheet.tickerById[ticker.organizationId] = {
                check: false,
                ...ticker,
              }
              activeSheet.defaultTickerId.push(ticker.organizationId)
              everyExist = false
            }
          })
          if (everyExist && action.payload.length) {
            EventEmitter.dispatch(SHOW_POPUP_ERROR, [
              <Translate value="tool.dataExplorer.corporate.EXIST_TICKER" />,
            ])
          } else {
            EventEmitter.dispatch(ADD_ROW, activeSheet.tickerId.length)
          }
        },
        action.payload,
      )
    },
    callBackErrorAddTicker(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      let length = activeSheet.tickerId.length
      action.payload.forEach((ticker) => {
        if (
          !activeSheet.tickerById[ticker.organizationId] &&
          length < maxTicker
        ) {
          activeSheet.tickerId.push(ticker.organizationId)
          activeSheet.newTickerId.push(ticker.organizationId)
          activeSheet.tickerById[ticker.organizationId] = {
            check: false,
            ...ticker,
          }
          activeSheet.defaultTickerId.push(ticker.organizationId)
          length += 1
        }
      })
      EventEmitter.dispatch(ADD_ROW, activeSheet.tickerId.length)
    },
    // indicator
    addIndicator(state, action) {
      state.detailPopupIndicator = action.payload
    },
    editIndicator(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const column = state.sheets[state.activeSheet].columnById[action.payload]
      state.detailPopupIndicator = {
        ...column,
        ...activeSheet.groupColumnById[column.groupId],
      }
    },
    closePopupIndicator(state) {
      state.detailPopupIndicator = null
    },
    changeTickerDisplay(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      if (activeSheet) {
        activeSheet.tickerDisplay = action.payload
      }
    },
    addColumn(state, action) {
      const indexColumn = action.payload.indexColumn
      const activeSheet = state.sheets[state.activeSheet]
      const objCheck = getObjStringCheckDuplicateCondition(
        activeSheet.columnById,
      )
      const newIndicators = action.payload.indicators.filter((item) => {
        const isValid = !objCheck[item.stringCheckDuplicate]
        objCheck[item.stringCheckDuplicate] = true
        return isValid
      })
      const color = randomColor(
        getIgnoreColor(activeSheet.columnId, activeSheet.columnById),
      )

      if (newIndicators.length) {
        const newIndicatorId = newIndicators.map((item) => item.alias)
        const groupId = uuid()

        activeSheet.groupColumnById[groupId] = {
          ...action.payload.detail,
          indicators: newIndicatorId,
          groupId,
          isGroup: action.payload.isGroup,
        }

        activeSheet.newGroupId.push(groupId)

        if (isInValidValue(indexColumn)) {
          activeSheet.columnId = activeSheet.columnId.concat(newIndicatorId)
        } else {
          activeSheet.columnId.splice(indexColumn, 0, ...newIndicatorId)
        }

        newIndicators.forEach(
          (item) =>
            (activeSheet.columnById[item.alias] = {
              ...item,
              color,
              groupId,
            }),
        )
      }
    },
    clearGroup(state, action) {
      const groupId = action.payload
      const activeSheet = state.sheets[state.activeSheet]
      const arrColumnId = getListColumnId(activeSheet.groupColumnById[groupId])

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnId.includes(id),
      )
      activeSheet.tickerId.forEach((tickerId) => {
        arrColumnId.forEach((columnId) => {
          delete activeSheet.tickerById[tickerId]?.[columnId]
        })
      })
      arrColumnId.forEach((columnId) => delete activeSheet.columnById[columnId])
      activeSheet.newGroupId = activeSheet.newGroupId.filter(
        (id) => id !== groupId,
      )
      delete activeSheet.groupColumnById[groupId]
    },
    sliceSubGroup(state, action) {
      const indicatorId = action.payload.indicatorId
      const activeSheet = state.sheets[state.activeSheet]
      const groupId = action.payload.groupId
      const arrColumnId = getArrColumnIdByIndicatorId(
        activeSheet.groupColumnById[groupId],
        activeSheet.columnById,
        indicatorId,
      )
      const indexColumn = activeSheet.columnId.indexOf(arrColumnId[0])

      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnId.includes(id),
      )

      activeSheet.tickerId.forEach((tickerId) => {
        arrColumnId.forEach((columnId) => {
          delete activeSheet.tickerById[tickerId]?.[columnId]
        })
      })

      arrColumnId.forEach((columnId) => {
        delete activeSheet.columnById[columnId]
      })

      activeSheet.groupColumnById[groupId].indicators =
        activeSheet.groupColumnById[groupId].indicators.filter(
          (id) => !arrColumnId.includes(id),
        )

      if (checkDeleteGroup(activeSheet.groupColumnById[groupId])) {
        activeSheet.newGroupId = activeSheet.newGroupId.filter(
          (id) => id !== groupId,
        )
        delete activeSheet.groupColumnById[groupId]
      }

      const objCheck = getObjStringCheckDuplicateCondition(
        activeSheet.columnById,
      )
      const newIndicators = action.payload.indicators.filter(
        (item) => !objCheck[item.stringCheckDuplicate],
      )
      if (newIndicators.length) {
        const newGroupId = uuid()
        const newIndicatorId = newIndicators.map((item) => item.alias)

        activeSheet.groupColumnById[newGroupId] = {
          ...action.payload.detail,
          indicators: newIndicatorId,
          groupId: newGroupId,
          isGroup: action.payload.isGroup,
        }
        activeSheet.newGroupId.push(newGroupId)
        activeSheet.columnId.splice(indexColumn, 0, ...newIndicatorId)
        const color = randomColor(
          getIgnoreColor(activeSheet.columnId, activeSheet.columnById),
        )
        newIndicators.forEach(
          (item) =>
            (activeSheet.columnById[item.alias] = {
              ...item,
              color,
              groupId: newGroupId,
            }),
        )
      }
    },
    swapTwoColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { column1, column2 } = action.payload
      const idColumn1 = activeSheet.columnId[column1]
      const idColumn2 = activeSheet.columnId[column2]
      activeSheet.columnId.splice(column1, 1)
      activeSheet.columnId.splice(
        activeSheet.columnId.indexOf(idColumn2),
        0,
        idColumn1,
      )
    },
    sort(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const { idColumn, typeSort } = action.payload
      const isInfo = [
        'organizationShortName',
        'exchangeCode',
        'ticker',
      ].includes(idColumn)
      const tickerById = isInfo
        ? state.stockById
        : current(activeSheet.tickerById)

      switch (typeSort) {
        case SORT_TYPES.DEFAULT:
          activeSheet.tickerId = activeSheet.defaultTickerId
          break
        case SORT_TYPES.ASC:
          if (
            activeSheet.defaultTickerId.length !== activeSheet.tickerId.length
          ) {
            activeSheet.defaultTickerId = [...activeSheet.tickerId]
          }
          activeSheet.tickerId = activeSheet.tickerId.sort(
            funcSortAsc(tickerById, idColumn),
          )
          break
        case SORT_TYPES.DESC:
          activeSheet.tickerId = activeSheet.tickerId.sort(
            funcSortDesc(tickerById, idColumn),
          )
          break
        default:
          break
      }
    },
    swapColumns(state, action) {
      state.sheets[state.activeSheet].columnId = action.payload
    },
    changeStockById(state, action) {
      state.stockById = action.payload
    },
    changeCheckTicker(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.tickerById[action.payload].check =
        !activeSheet.tickerById[action.payload].check
    },
    checkAll(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.tickerDisplay.forEach(
        (id) => (activeSheet.tickerById[id].check = true),
      )
    },
    uncheckAll(state) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.tickerDisplay.forEach(
        (id) => (activeSheet.tickerById[id].check = false),
      )
    },
    deleteTicker(state) {
      const activeSheet = state.sheets[state.activeSheet]
      const tickerDeleteId = {}
      activeSheet.tickerDisplay.forEach((id) => {
        if (activeSheet.tickerById[id].check) {
          tickerDeleteId[id] = true
          delete activeSheet.tickerById[id]
        }
      })
      activeSheet.tickerId = activeSheet.tickerId.filter(
        (id) => !tickerDeleteId[id],
      )
      activeSheet.newTickerId = activeSheet.newTickerId.filter(
        (id) => !tickerDeleteId[id],
      )
      activeSheet.defaultTickerId = activeSheet.defaultTickerId.filter(
        (id) => !tickerDeleteId[id],
      )
    },
    deleteTickerByUncheck(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const tickerDeleteId = {}
      action.payload.forEach((organizationId) => {
        tickerDeleteId[organizationId] = true
        delete activeSheet.tickerById[organizationId]
      })
      activeSheet.tickerId = activeSheet.tickerId.filter(
        (id) => !tickerDeleteId[id],
      )
      activeSheet.newTickerId = activeSheet.newTickerId.filter(
        (id) => !tickerDeleteId[id],
      )
      activeSheet.defaultTickerId = activeSheet.defaultTickerId.filter(
        (id) => !tickerDeleteId[id],
      )
    },
    removeColumn(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      const arrColumnIdRemove = action.payload
      activeSheet.columnId = activeSheet.columnId.filter(
        (id) => !arrColumnIdRemove.includes(id),
      )

      arrColumnIdRemove.forEach((columnId) => {
        const groupId = activeSheet.columnById[columnId].groupId

        delete activeSheet.columnById[columnId]
        activeSheet.tickerId.forEach(
          (id) => delete activeSheet.tickerById[id]?.[columnId],
        )
        activeSheet.groupColumnById[groupId].indicators =
          activeSheet.groupColumnById[groupId].indicators.filter(
            (id) => id !== columnId,
          )
        if (checkDeleteGroup(activeSheet.groupColumnById[groupId])) {
          activeSheet.newGroupId = activeSheet.newGroupId.filter(
            (id) => id !== groupId,
          )
          delete activeSheet.groupColumnById[groupId]
        }
      })
    },
    // fiinX template
    openPopupTemplate(state, action) {
      state.detailFiinXTemplate = action.payload
    },
    closePopupTemplate(state) {
      state.detailFiinXTemplate = null
    },
    addFiinXTemplate(state, action) {
      const countSheetOfTemplate = state.listSheet.filter(
        (sheet) => sheet?.templateId === 'FiinX' + action.payload.templateId,
      ).length
      const value = state.listSheet[state.listSheet.length - 1].value + 1
      const title =
        action.payload.templateName +
        (countSheetOfTemplate ? ` (${countSheetOfTemplate + 1})` : '')
      state.listSheet.push({
        title,
        value,
        templateId: 'FiinX' + action.payload.templateId,
        templateName: action.payload.templateName,
        templateGroup: action.payload.templateGroup,
      })
      state.activeSheet = value
      state.sheets[value] = initialSheet
    },
    addIndicatorById(state, action) {
      state.indicatorById = { ...state.indicatorById, ...action.payload }
    },
    resetTickerFilter(state) {
      state.tickerFilter = null
    },
    changeTickerId(state, action) {
      const activeSheet = state.sheets[state.activeSheet]
      activeSheet.tickerId = action.payload
    },
    openTemplate(state, action) {
      if (state.listSheet.length >= maxSheet) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_1" />,
          <Translate value="tool.dataExplorer.corporate.MAX_SHEET_2" />,
        ])
      } else {
        const countSheetOfTemplate = state.listSheet.filter(
          (sheet) => sheet?.templateId === action.payload.templateId,
        ).length
        const value = state.listSheet[state.listSheet.length - 1].value + 1
        const title =
          action.payload.templateName +
          (countSheetOfTemplate ? ` (${countSheetOfTemplate + 1})` : '')
        state.listSheet.push({
          title,
          value,
          templateId: action.payload.templateId,
          templateName: action.payload.templateName,
        })
        state.sheets[value] = {
          ...initialSheet,
          ...action.payload.parameters?.rawParameter,
          isLoad: false,
        }
        state.activeSheet = value
      }
      state.loading[keys.TABLE_RESULT] = false
    },
    changeSheetParamsWhenLoadTemplate: (state, action) => {
      const { activeSheet, tickerId, tickerById, columnId, columnById } =
        action.payload

      state.sheets[activeSheet] = {
        ...state.sheets[activeSheet],
        tickerId,
        newTickerId: tickerId,
        tickerById,
        columnId,
        columnById,
      }
    },
  },
  extraReducers: (builder) => {
    // most recent
    builder.addCase(getMostRecentThunk.fulfilled, (state, action) => {
      action.payload?.forEach((item) => {
        state.mostRecent[item.indicatorID + (item.indicatorType || '')] = item
      })
    })
    // get indicator
    builder.addCase(getIndicatorThunk.pending, (state) => {
      state.loading[keys.INDICATOR] = true
    })
    builder.addCase(getIndicatorThunk.fulfilled, (state, action) => {
      const indicator = action.payload || []
      state.indicator = indicator
      state.indicatorById = {
        ...state.indicatorById,
        ...keyBy(indicator, 'indicatorId'),
      }
      state.loading[keys.INDICATOR] = false
    })
    builder.addCase(getIndicatorThunk.rejected, (state, action) => {
      state.loading[keys.INDICATOR] = action.payload
    })
    // popup indicator
    builder.addCase(getShareIssueMethodThunk.fulfilled, (state, action) => {
      state.shareIssueMethod = action.payload || []
    })
    builder.addCase(
      getFiinXTemplateShareIssueMethodThunk.fulfilled,
      (state, action) => {
        state.fiinXTemplateShareIssueMethod = action.payload || []
      },
    )
    builder.addCase(getActionTypeThunk.fulfilled, (state, action) => {
      state.actionType = action.payload || []
    })
    // result table
    builder.addCase(getResultThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getResultThunk.fulfilled, (state, action) => {
      const activeSheet = state.sheets[state.activeSheet]
      action.payload?.forEach((item) => {
        const ticker = activeSheet.tickerById[item.id]
        activeSheet.tickerById[item.id] = {
          ...ticker,
          ...item.items,
        }
      })
      activeSheet.isLoad = true
      activeSheet.newTickerId = initialSheet.newTickerId
      activeSheet.newGroupId = initialSheet.newGroupId
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getResultThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })

    builder.addCase(getFiinXTemplateResultThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getFiinXTemplateResultThunk.fulfilled, (state, action) => {
      const activeSheet = state.sheets[state.activeSheet]
      const tickerId = []
      const tickerById = {}
      const group = Object.values(activeSheet.groupColumnById)[0]
      const unit =
        group.conditionType === typePopupIndicator.DE01_SC26
          ? group.condition.unit
          : null
      const fiinxSheet = state.listSheet.find(
        (item) => item.value === state.activeSheet,
      )

      const sortResult = sortFiinXTemplateResult(
        action.payload || [],
        fiinxSheet.templateGroup,
        activeSheet.columnById,
      )

      sortResult.forEach((item) => {
        const newItem = deepCopy(item)

        if (unit) {
          Object.keys(newItem.items).forEach((key) => {
            const isReformat =
              activeSheet.columnById[key].typeOf === typeOfIndicator.DECIMAL
            if (unit === vndUnit) {
              if (isReformat) {
                activeSheet.columnById[key].typeOf = typeOfIndicator.INT
              }
            }

            if (isReformat) {
              newItem.items[key] =
                newItem.items[key] && newItem.items[key] * unit
            }
          })
        }

        tickerId.push(newItem.id)

        tickerById[newItem.id] = {
          ...newItem,
          ...newItem.items,
          items: undefined,
        }
      })
      activeSheet.tickerById = tickerById
      activeSheet.tickerId = tickerId
      activeSheet.defaultTickerId = tickerId
      activeSheet.isLoadFiinXTemplate = true
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getFiinXTemplateResultThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // template
    builder.addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.templates = action.payload || []
    })
    builder.addCase(getFiinXTemplatesThunk.fulfilled, (state, action) => {
      state.fiinXTemplates = action.payload || []
    })
    builder.addCase(getTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    builder.addCase(getShareTemplateThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getShareTemplateThunk.rejected, (state) => {
      state.loading[keys.TABLE_RESULT] = false
    })
    // get work space
    builder.addCase(getWorkSpaceThunk.pending, (state) => {
      state.loading[keys.TABLE_RESULT] = true
    })
    builder.addCase(getWorkSpaceThunk.fulfilled, (state, action) => {
      state.loading[keys.TABLE_RESULT] = false

      const workSpaces =
        action.payload?.filter(
          (item) => item.dataType === typeWorkSpace.CORPORATE,
        ) || []

      state.activeSheet = 1

      let countSheet = 1

      workSpaces.forEach((workSpace, index) => {
        const { data, template } = workSpace.parameters.rawParameter

        if (template) {
          state.sheets[countSheet] = {
            ...initialSheet,
            ...data,
            isLoad: false,
          }

          if (index === 0) {
            state.listSheet = []
          }

          state.listSheet.push({
            ...template,
            title:
              template?.title?.slice(0, 5) === 'SHEET'
                ? 'SHEET ' + countSheet
                : template?.title,
            value: countSheet,
          })

          countSheet += 1
        }
      })

      state.isLoadWorkSpace = true
    })
    builder.addCase(getWorkSpaceThunk.rejected, (state) => {
      state.isLoadWorkSpace = true

      state.loading[keys.TABLE_RESULT] = false
    })
    // ticker
    builder.addCase(getListTickerThunk.pending, (state) => {
      state.loading[keys.LIST_TICKER] = true
    })
    builder.addCase(getListTickerThunk.fulfilled, (state, action) => {
      state.listTicker = action.payload || []
      state.loading[keys.LIST_TICKER] = false
    })
    builder.addCase(getListTickerThunk.rejected, (state) => {
      state.loading[keys.LIST_TICKER] = false
    })
    builder.addCase(getListTickerExchangeThunk.fulfilled, (state, action) => {
      const newTickerFilter = {}
      action.payload?.forEach(
        (ticker) => (newTickerFilter[ticker.organizationId] = true),
      )
      state.tickerFilter = newTickerFilter
    })
  },
})

export const {
  resetStore,
  changeActiveSheet,
  addSheet,
  removeSheet,
  addTicker,
  callBackErrorAddTicker,
  addIndicator,
  closePopupIndicator,
  changeTickerDisplay,
  addColumn,
  swapTwoColumn,
  sort,
  swapColumns,
  changeStockById,
  changeCheckTicker,
  checkAll,
  uncheckAll,
  deleteTicker,
  removeColumn,
  editIndicator,
  clearGroup,
  sliceSubGroup,
  changeListSheet,
  openPopupTemplate,
  closePopupTemplate,
  addIndicatorById,
  addFiinXTemplate,
  resetTickerFilter,
  changeTickerId,
  deleteTickerByUncheck,
  openTemplate,
  changeSheetParamsWhenLoadTemplate,
} = slice.actions

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

export const selectActiveSheet = (state) => state[slice.name].activeSheet
export const selectSheet = (state) => state[slice.name].sheets

export const selectTickerById = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.tickerById
export const selectTickerId = (sheet) => (state) =>
  state[slice.name].sheets[sheet]?.tickerId
export const selectTickerCell = (sheet) => (id, attr) => (state) =>
  state[slice.name].sheets[sheet]?.tickerById[id]?.[attr]
export const selectColumnId = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].columnId?.[index]
export const selectColumnCell = (sheet) => (id) => (state) =>
  state[slice.name].sheets[sheet].columnById?.[id]
export const selectColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnById
export const selectFullColumnId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].columnId
export const selectTickerDisplay = (sheet) => (index) => (state) =>
  state[slice.name].sheets[sheet].tickerDisplay?.[index]
export const selectFullTickerDisplay = (sheet) => (state) =>
  state[slice.name].sheets[sheet].tickerDisplay
export const selectGroupColumnById = (sheet) => (state) =>
  state[slice.name].sheets[sheet].groupColumnById
export const selectNewTickerId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].newTickerId
export const selectNewGroupId = (sheet) => (state) =>
  state[slice.name].sheets[sheet].newGroupId
export const selectConditionGroup = (sheet) => (groupId) => (state) =>
  state[slice.name].sheets[sheet].groupColumnById?.[groupId]?.condition

export const selectIndicator = (state) => state[slice.name].indicator
export const selectIndicatorById = (state) => state[slice.name].indicatorById

export const selectDetailPopupIndicator = (state) =>
  state[slice.name].detailPopupIndicator

export const selectShareIssueMethod = (state) =>
  state[slice.name].shareIssueMethod
export const selectFiinXTemplateShareIssueMethod = (state) =>
  state[slice.name].fiinXTemplateShareIssueMethod

export const selectActionType = (state) => state[slice.name].actionType

export const selectStockById = (state) => state[slice.name].stockById

export const selectTemplates = (state) => state[slice.name].templates

export const selectFiinXTemplates = (state) => state[slice.name].fiinXTemplates

export const selectListSheet = (state) => state[slice.name].listSheet

export const selectIsLoad = (sheet) => (state) =>
  state[slice.name].sheets[sheet].isLoad
export const selectIsLoadFiinXTemplate = (sheet) => (state) =>
  state[slice.name].sheets[sheet].isLoadFiinXTemplate

export const selectMostRecent = (indicatorId, indicatorType) => (state) =>
  state[slice.name].mostRecent[indicatorId + (indicatorType || '')]

export const selectAllMostRecent = (state) => state[slice.name].mostRecent

export const selectDetailFiinXTemplate = (state) =>
  state[slice.name].detailFiinXTemplate

export const selectIsLoadWorkSpace = (state) =>
  state[slice.name].isLoadWorkSpace

export const selectListTicker = (state) => state[slice.name].listTicker

export const selectTickerFilter = (state) => state[slice.name].tickerFilter

register(slice.name, slice.reducer)
