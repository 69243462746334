import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'
import { tableHeadTab } from './constants'
import { changeThTab, selectThTab } from './store/slice'

export const ThCellTab = ({ left, width }) => {
  const dispatch = useDispatch()

  // Use selector
  const thTab = useSelector(selectThTab)

  // Actions
  const onChangeTab = (e, tab) => {
    e.stopPropagation()
    dispatch(changeThTab(tab))
  }

  return (
    <>
      {/* Dummy title */}
      <Span style={{ fontSize: 10 }}>dummy</Span>
      <div className={style.tabLinkActiveBorderBottom}></div>

      {/* Menu tab */}
      <div className={style.customTableHeadThContent}>
        <div
          style={{ width: width - left }}
          className={[
            style.tabLinkContainer,
            'mb-0 justify-content-center',
          ].join(' ')}
        >
          {Object.keys(tableHeadTab).map((tab) => (
            <a
              key={tab}
              className={[
                style.tabLink,
                'mt-0 mb-0',
                thTab === tableHeadTab[tab] ? style.tabLinkActive : '',
              ].join(' ')}
              style={{ color: '#ececec', opacity: 0.4 }}
              onClick={(e) => onChangeTab(e, tableHeadTab[tab])}
            >
              <Span style={{ fontSize: 10 }}>
                <Translate
                  value={`market.equityTrading.priceStatistics.priceData.statisticsInvest.${tab}`}
                />
              </Span>
              {thTab === tableHeadTab[tab] && (
                <div className={style.tabLinkActiveBorderBottom}></div>
              )}
            </a>
          ))}
        </div>
      </div>
    </>
  )
}
