import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getAxisProps } from '../../../common/chart/financialChart/helper'
import UseFontSize from '../../../common/hooks/useFontSize'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { keySettingChart } from '../../chart/constants'

const MIN_WIDTH_CHART_WITHOUT_YAXIS = 200

export const CalculateChartWidth = ({
  data,
  item,
  width,
  height,
  schema,
  dimensionTitle,
  setChartWidth,
}) => {
  const typeFontSize = UseFontSize()
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)

  const { totalLeftYAxisWidth, totalRightYAxisWidth } = getAxisProps({
    width,
    height,
    data,
    schema,
    typeFontSize,
    timeZone,
    locale,
    styleXAxis: item?.detail?.styleSetting
      ? item?.detail.styleSetting[keySettingChart.X_AXIS]
      : {},
    styleYAxis: item?.detail?.styleSetting
      ? item?.detail?.styleSetting[keySettingChart.Y_AXIS_POSITION]
      : {},
  })

  useEffect(() => {
    const chartWidth =
      dimensionTitle.width >
      MIN_WIDTH_CHART_WITHOUT_YAXIS + totalLeftYAxisWidth + totalRightYAxisWidth
        ? dimensionTitle.width
        : MIN_WIDTH_CHART_WITHOUT_YAXIS +
          totalLeftYAxisWidth +
          totalRightYAxisWidth

    setChartWidth(chartWidth)
  }, [dimensionTitle.width, totalLeftYAxisWidth, totalRightYAxisWidth])

  return <></>
}
