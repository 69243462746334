import { TIME_FREQUENCY } from '../../common/constants'

export const VIEW_BY = {
  CAPITAL: true,
  NUMBER_OF_PROJECT: false,
}

export const listViewBy = [
  {
    title: 'economy.fdi.fdiByProvinces.CAPITAL',
    value: VIEW_BY.CAPITAL,
  },
  {
    title: 'economy.fdi.fdiByProvinces.NUMBER_OF_PROJECT',
    value: VIEW_BY.NUMBER_OF_PROJECT,
  },
]

export const listTimeFrequency = [
  {
    title: 'economy.fdi.fdiByProvinces.YEARLY',
    value: TIME_FREQUENCY.YEARLY,
  },
  {
    title: 'economy.fdi.fdiByProvinces.ACC_MONTHLY',
    value: TIME_FREQUENCY.ACC_MONTHLY,
  },
]

export const TABLE_SECTOR_ID = 'totalRegisterFDIId'
