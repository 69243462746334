import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useComGroup from '../../../../../common/hooks/useComGroup'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../../common/loading'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { MEAN_ROW_ID } from '../constant'
import {
  selectIsShowChart,
  selectOrgIds,
  selectSelectedWL,
} from '../header/store/slice'
import { dispatchGetDataByTableType } from '../helper'
import {
  selectLoading,
  selectReCalcWidths,
  selectResponseOrgIds,
  selectValueById,
  sort,
} from '../store/slice'
import style from './index.module.css'
import { getSchema } from './schemas'

const TableChart = ({ tableType }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)

  const orgIds = useSelector(selectOrgIds)
  const selectedWL = useSelector(selectSelectedWL)
  const responseOrgIds = useSelector(selectResponseOrgIds)
  const loading = useSelector(selectLoading)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const isShowChart = useSelector(selectIsShowChart)

  const ids =
    basicInfo.organizationId && responseOrgIds.length
      ? [
          basicInfo.organizationId,
          ...responseOrgIds.filter((id) => id !== basicInfo.organizationId),
          ...(responseOrgIds.length > 1 ? [MEAN_ROW_ID] : []),
        ]
      : []

  const firstRowData = useSelector(selectValueById(ids[0]))

  const handleCallData = (peerOrganizationIds) => {
    if (basicInfo.organizationId && comGroup) {
      dispatchGetDataByTableType(
        dispatch,
        tableType,
        {
          organizationId: basicInfo.organizationId,
          peerOrganizationIds: peerOrganizationIds || orgIds,
        },
        comGroup,
      )
    }
  }

  useEffect(() => {
    handleCallData([])
  }, [basicInfo.organizationId, comGroup])

  useEffect(() => {
    handleCallData()
  }, [locale, tableType])

  useEffect(() => {
    if (selectedWL) handleCallData()
  }, [selectedWL])

  if (loading) {
    return <Loading />
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div style={{ height: `calc(100% - ${size.height || 0}px)` }}>
              <Table
                ids={ids}
                getDataFromRedux={selectValueById}
                isLoading={loading}
                schema={getSchema({
                  type: tableType,
                  currentOrgId: basicInfo.organizationId,
                  comGroup,
                  isShowChart,
                })}
                sort={sort}
                reCalcWidths={reCalcWidths}
                hasFooter={false}
                hasTooltip={false}
                stickies={{ delete: true, ticker: true }}
                stickyBottomRowCount={1}
              />
            </div>
            {firstRowData &&
              (firstRowData.tradingDateId ||
                (firstRowData.quarter && firstRowData.year)) && (
                <div className={style.note}>
                  <Translate value="corporate.peerValuation.overview.DATA_CALCULATE_NOTE" />
                  &nbsp;
                  {firstRowData.tradingDateId
                    ? formatDateTime(
                        firstRowData.tradingDateId,
                        FORMAT.DATE,
                        locale,
                        timezone,
                      )
                    : `Q${firstRowData.quarter}/${firstRowData.year}`}
                </div>
              )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableChart
