import { Span } from '../../../../common/html/Span'
import { Table } from '../../../../common/table'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valDivBillion } from '../../../../utils/Value'
import { GROUP_COLUMNS, TABLE_HEADER_COL_ATTR } from './constants'
import { getOpenMarketAuctionValue } from './store/slice'
import TableHeader from './TableHeader'

const TableComponent = ({ ids, tableData }) => {
  const renderThead = (tableHeaderColAttr) => {
    return <TableHeader tableHeaderColAttr={tableHeaderColAttr} />
  }

  const formatData = (colId, val) => {
    if (colId === 'winningVolume') {
      return formatVal(valDivBillion(val))
    }

    if (colId === 'value') {
      return typeof val === 'number' ? `${formatVal(val * 100)}%` : EMPTY_VALUE
    }

    return val
  }

  return (
    <Table
      ids={ids}
      columnDraggable={false}
      getDataFromRedux={getOpenMarketAuctionValue}
      rowDraggable={false}
      renderHeader={() => renderThead(TABLE_HEADER_COL_ATTR)}
      schema={GROUP_COLUMNS.map((item, index) => {
        const colId = item
        const result = {
          colId,
        }
        if (index === 0) {
          return {
            ...result,
            thStyle: {
              textAlign: 'left',
              fontSize: 10,
              fontWeight: 600,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <td {...props}>
                  <Span>{tableData[rowId].title}</Span>
                </td>
              )
            },
          }
        }

        return {
          ...result,
          isI18n: true,
          tdStyle: {
            textAlign: 'right',
            fontSize: 12,
          },
          thStyle: {
            textAlign: 'right',
            fontSize: 10,
            fontWeight: 600,
          },
          canCustomTd: true,
          render: (val, rowId, props) => {
            return (
              <td {...props}>
                <Span>{formatData(colId, val)}</Span>
              </td>
            )
          },
        }
      })}
      stickyFirstColumn={true}
      isPagination={false}
      hasFooter={false}
      resizable={false}
      defaultScrollToRight={true}
    />
  )
}

export default TableComponent
