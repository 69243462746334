import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import DividendTable from '.'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { downloadDividendHistory } from './store/thunk'

export const PanelDividendTable = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  // Actions
  const downloadCsv = () => {
    const params = {
      OrganizationId: basicInfo.organizationId,
    }

    return downloadDividendHistory(params)
  }

  return (
    <Panel
      title="corporate.financialData.financialDividend.DIVIDEND_HISTORY"
      panelRefs={panelRefs}
      panelKey={mapKey.FINANCIAL_TABLE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <DividendTable />
    </Panel>
  )
}

PanelDividendTable.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
