const validate = {
  NAME_REQUIRE: 'Please enter the watchlist name.',
  NAME_MAX: 'It exceeded 100 characters.',
  NAME_DUPLICATE: 'The name has already existed.',
}

const indices = {
  CODE: 'TICKER/TAXCODE',
  COMPANY_NAME: 'COMPANY NAME',
}

const watchlistDetail = {
  indices,
  validate,
  TITLE_CREATE: 'CREATE WATCHLIST',
  NAME: 'Name:',
  DESCRIPTION: 'Description:',
  BTN_ADD_TICKER: 'Add Ticker',
  NOTE: 'Rearranging the order of tickers by dragging the ticker into a desired position or double clicking into the number and replacing a new ordinal number for the ticker.',
  FILTER: 'Filter',
  TITLE_SEARCH_TICKER: 'Ticker/ Taxcode/ Company Name',
  NO_RESULT: 'No matched results',
  SEARCH_PLH: 'Search',
  TITLE_SEARCH_GROUP: 'Group Stock',
  EXCHANGE: 'Exchange',
  SECTOR: 'Sector',
  TOTAL: 'Total',
  BTN_PREVIOUS: 'Previous page',
  ASK_1: 'Do you want to save the watchlist?',
  ASK_2: 'If you click "Don\'t Save", you will be back to the previous page.',
  BTN_NOT_SAVE: "Don't save",
  BTN_SAVE_AS: 'Save as',
  EXCEEDED_WL:
    'It exceeded the number of watchlist. You cannot add more watchlists.',
  BTN_CREATE: 'Create',
  ASK_CREATE: 'Do you want to create this watchlist?',
  BTN_OK: 'Ok',
  TITLE_CREATE_2: 'Create Watchlist',
  LABEL_NAME_WL: 'Name of watchlist:',
  ASK_DELETE: 'Do you want to delete',
  TITLE_DELETE_TICKER: 'Delete Ticker',
  DELETE: 'Delete',
  TICKERS: 'tickers',
  MAX_COMPANY: 'The number of companies you can add in a watchlist is 300.',
}

export default watchlistDetail
