import { useEffect, useRef, useState } from 'react'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from '../index.module.css'
import TooltipInfo from './tooltipInfo'

const FirstCellColumn = ({
  val,
  props,
  level,
  childrenIds,
  rowId,
  onRowCollapse,
  isRowCollapse,
  onMouseEnter,
  onMouseLeave,
  isShow,
  idSelected,
  type,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const parentRef = useRef()

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <td
      {...props}
      className={`${style.firstCellColumn} ${
        [111, 222, 333, 444].includes(rowId) && style.firstCellColumnBackground
      } ${idSelected === rowId && style.rowHover}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      ref={parentRef}
    >
      <div
        className={`d-flex align-center ${style.hover}`}
        style={{
          paddingLeft: level > 0 ? (level - 1) * 20 + 4 : 4,
          paddingRight: 8,
          cursor: childrenIds ? 'pointer' : 'default',
          color: childrenIds
            ? '#FECF2F'
            : rowId === 111
            ? '#FECF2F'
            : '#ECECEC',
          height: 28,
          position: 'relative',
          boxSizing: 'border-box',
          overflow: 'hidden',
        }}
        onClick={onCollapse}
      >
        {childrenIds ? (
          <button
            style={{
              paddingLeft: 0,
              paddingRight: 8,
            }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <></>
        )}
        <Span
          style={{
            fontWeight: rowId === 444 ? 700 : 340,
            fontStyle: childrenIds ? 'normal' : 'oblique',
            overflow: 'hidden',
            display: 'block',
            flex: 1,
          }}
        >
          {val ? (
            <TextEllipsis
              text={val}
              isI18n={[111, 222, 333, 444].includes(rowId)}
            />
          ) : (
            '--'
          )}
        </Span>
        {isShow === type && idSelected === rowId && (
          <TooltipInfo
            rowIdCol={idSelected?.split('-')?.[0] ?? null}
            parentRef={parentRef}
          />
        )}
      </div>
      <div className={style.borderLeftScroll} />
    </td>
  )
}

export default FirstCellColumn
