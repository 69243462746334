import CostOfOperationProxy from '../../proxies/corporate/CostOfOperationProxy'
import { ServiceBase } from '../ServiceBase'

class CostOfOperationService extends ServiceBase {
  getRevenueCost(params) {
    return this.getData(() => CostOfOperationProxy.getRevenueCost(params))
  }

  getCostStructure(params) {
    return this.getData(() => CostOfOperationProxy.getCostStructure(params))
  }

  getDetailMetrics(params) {
    return this.getData(() => CostOfOperationProxy.getDetailMetrics(params))
  }

  downloadDetailMetrics(params) {
    return this.getFileDownload(() =>
      CostOfOperationProxy.getDownloadDetailMetrics(params),
    )
  }
}

export default new CostOfOperationService()
