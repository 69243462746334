import PropTypes from 'prop-types'
import style from './index.module.css'

export const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      {value.ticker ? (
        <span className={style.w25}>{value.ticker}</span>
      ) : (
        <span className={style.w25}>&nbsp;</span>
      )}
      <span className={style.textLongW50}>{value.organizationShortName}</span>
      <span className={style.w25AlignRight}>{value.exchangeCode}</span>
    </div>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
