import { Panel } from '../../../../common/panel'
import FilterGroup from './FilterGroup'

const PanelGDPCompositionBySector = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  return (
    <Panel
      title={'economy.gdp.gdp.GDP_COMPOSITION_BY_SECTOR'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <FilterGroup />
    </Panel>
  )
}

export default PanelGDPCompositionBySector
