export const LEVEL_MARGIN_LEFT = 24
export const PERCENT_MARGIN_LEFT = 16
export const LIMIT_FIRST_RENDER = 17
export const LIMIT_RENDER = 10

export const tabConstants = {
  MONTHLY: 'Monthly',
  ACCUMULATED_MONTH: 'AccMonthly',
  YEARLY: 'Yearly',
}

export const tabOptions = [
  {
    title: 'economy.productionAndConsumption.consumption.MONTHLY',
    value: tabConstants.MONTHLY,
  },
  {
    title: 'economy.productionAndConsumption.consumption.ACCUMULATED_MONTH',
    value: tabConstants.ACCUMULATED_MONTH,
  },
  {
    title: 'economy.productionAndConsumption.consumption.YEARLY',
    value: tabConstants.YEARLY,
  },
]
