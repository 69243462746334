import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import DataExplorerService from '../../../../core/services/tool/dataExplorer/DataExplorerService'
import {
  timeoutInterval,
  typeWorkSpace,
} from '../../../common/dataExplorer/constant'
import { selectDataGeneralInfo } from '../../../setting/slice'
import { keyBy } from '../../../utils/Common'
import {
  changeSheetParamsWhenLoadTemplate,
  selectActiveSheet,
  selectColumnById,
  selectCondition,
  selectFullColumnId,
  selectIndicator,
  selectIsLoadWorkSpace,
  selectListSheet,
  selectSheet,
} from './store/slice'
import { getShareTemplateThunk, getWorkSpaceThunk } from './store/thunk'

let workSpace
let interval

const WorkSpace = () => {
  const dispatch = useDispatch()

  const [searchParams] = useSearchParams()

  const sheets = useSelector(selectSheet)
  const listSheet = useSelector(selectListSheet)
  const userInfo = useSelector(selectDataGeneralInfo)
  const indicators = useSelector(selectIndicator)
  const activeSheet = useSelector(selectActiveSheet)
  const columnId = useSelector(selectFullColumnId(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const conditionStore = useSelector(selectCondition(activeSheet))
  const isLoadWorkSpace = useSelector(selectIsLoadWorkSpace)

  useEffect(() => {
    if (isLoadWorkSpace) {
      workSpace = {
        templateName: listSheet[0]?.title,
        dataType: typeWorkSpace.ECONOMY,
        rawParameter: Object.keys(sheets).map((id) => {
          const sheet = sheets[id]

          return {
            template: listSheet.find((sheet) => +sheet.value === +id),
            data: {
              condition: sheet.condition,
              columnId: sheet.columnId,
              columnById: sheet.columnById,
            },
          }
        }),
      }
    }
  }, [columnId, columnById, conditionStore])

  useEffect(() => {
    interval = setInterval(async () => {
      if (workSpace) {
        await DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }, timeoutInterval)

    return () => {
      if (interval) {
        clearInterval(interval)
      }

      if (workSpace) {
        DataExplorerService.saveWorkSpace(workSpace)
        workSpace = null
      }
    }
  }, [])

  useEffect(() => {
    const shareCode = searchParams.get('shareCode')

    if (userInfo.userId) {
      if (!shareCode) {
        dispatch(getWorkSpaceThunk({ UserID: userInfo.userId }))
      } else {
        dispatch(
          getShareTemplateThunk({ ShareCode: shareCode.replace(/ /g, '+') }),
        )
      }
    }
  }, [userInfo.userId])

  useEffect(() => {
    if (columnId?.length !== Object.keys(columnById).length) {
      const indicatorsById = keyBy(indicators, 'indicatorId')
      const columnId = []

      const newColumnById = Object.values(columnById).reduce((obj, item) => {
        const indicator = indicatorsById[item.indicatorId]
        columnId.push(item.indicatorId)

        if (indicator) {
          return {
            ...obj,
            [item.indicatorId]: {
              ...item,
              ...indicator,
            },
          }
        }

        return { ...obj, [item.indicatorId]: item }
      }, {})

      dispatch(
        changeSheetParamsWhenLoadTemplate({
          activeSheet,
          columnById: newColumnById,
          columnId,
        }),
      )
    }
  }, [columnId, columnById])

  return <></>
}

export default WorkSpace
