import { COMPONENT } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import { withWrapper } from '../../common/withWrapper'
import SettingPanel from './SettingPanel'
import Template from './template'
import style from './template/index.module.css'

const MAP_KEY = {
  TEMPLATE: 'TEMPLATE',
  SETTING: 'SETTING',
}

const MAP_SIZE = {
  [`${MAP_KEY.TEMPLATE}`]: {
    width: `calc(18% - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 170,
    minHeight: 346,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.SETTING,
        ratioLeft: 1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.SETTING}`]: {
    width: `calc(100% - 18%)`,
    height: '100%',
    top: 0,
    left: `calc(18%)`,
    minWidth: 780,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 170 + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title={'newsReport.dailyReport.dailyReport.TEMPLATE'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TEMPLATE}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Template />
      </Panel>
      <SettingPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SETTING}
        sizes={sizes}
        setSizes={setSizes}
        bodyClass={style.bodyClass}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
