import { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../../common/popup/PopupAlert'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import style from './index.module.css'
import PopupHistory from './popupHistory'
import { selectDataCompany } from './store/slice'

const BodyBottomRight = ({
  width,
  widthBottomLabelRight,
  widthBottomRight,
}) => {
  const data = useSelector(selectDataCompany)
  const locale = useSelector((state) => state.i18n.locale)

  const [show, setShow] = useState(false)
  const [showHistory, setShowHistory] = useState(false)

  const closePopupHistory = (value) => {
    setShowHistory(value)
  }

  return (
    <>
      <div className={style.column}>
        <div
          style={{ width: width * 0.55, gap: 8 }}
          className="d-flex ali-center group-btn"
        >
          <div style={{ width: 'calc(100% - 128px)' }}>
            <Span style={{ fontSize: 12, fontWeight: 700 }}>
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.titleRight'
                }
              />
            </Span>
          </div>
          <div style={{ width: 120 }}>
            <Button
              className={`btn btn-blue h-20`}
              onClick={() => setShow(true)}
            >
              <Span style={{ fontSize: 12, fontWeight: '600' }}>
                <TextEllipsis
                  text={
                    'bond.bondSecurity.tradingInformation.bottomBody.btnRight'
                  }
                  appendStyle={{ padding: '0 15px' }}
                />
              </Span>
            </Button>
          </div>
        </div>
        <div className="d-flex ali-center" style={{ gap: 8 }}>
          <div style={{ width: widthBottomLabelRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.CreditRatingAgency'
                }
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={data?.[0]?.organizationShortName || ''}
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={data?.[1]?.organizationShortName || ''}
              />
            </Span>
          </div>
        </div>
        <div className="d-flex ali-center" style={{ gap: 8 }}>
          <div style={{ width: widthBottomLabelRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.RatingDate'
                }
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={
                  data?.[0]?.ratingDate
                    ? formatDateTime(data?.[0]?.ratingDate, FORMAT.DATE, locale)
                    : ''
                }
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={
                  data?.[1]?.ratingDate
                    ? formatDateTime(data?.[1]?.ratingDate, FORMAT.DATE, locale)
                    : ''
                }
              />
            </Span>
          </div>
        </div>
        <div className="d-flex ali-center" style={{ gap: 8 }}>
          <div style={{ width: widthBottomLabelRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.RatingType'
                }
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={data?.[0]?.ratingScoreValue || ''}
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                isI18n={false}
                text={data?.[1]?.ratingScoreValue || ''}
              />
            </Span>
          </div>
        </div>
        <div className="d-flex ali-center" style={{ gap: 8 }}>
          <div style={{ width: widthBottomLabelRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.RatingProspect'
                }
              />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.[0]?.prospects || ''} />
            </Span>
          </div>
          <div style={{ width: widthBottomRight }}>
            <Span style={{ fontSize: 12, fontWeight: 340 }}>
              <TextEllipsis isI18n={false} text={data?.[1]?.prospects || ''} />
            </Span>
          </div>
        </div>
        <div className="d-flex ali-center" style={{ gap: 8 }}>
          <div style={{ width: widthBottomLabelRight }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 340,
                color: '#40ABFF',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => setShowHistory(true)}
            >
              <TextEllipsis
                text={
                  'bond.bondSecurity.tradingInformation.bottomBody.RatingHistory'
                }
              />
            </Span>
          </div>
        </div>
      </div>
      <PopupAlert
        message={I18n.t('bond.bondSecurity.common.SC14_MS001')}
        isShow={show}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setShow(false)}
      />
      {showHistory && <PopupHistory closePopupHistory={closePopupHistory} />}
    </>
  )
}

export default BodyBottomRight
