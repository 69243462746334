export const deepTransaction = {
  DEEP_TRANSACTION: 'Cổ phiếu trực tuyến',
  SUMMARY: 'Tổng quan',
  PRICE_PERFORMANCE: 'Giá cổ phiếu',
  PRICE_DEPTH: 'Chi tiết bước giá',
  TIME_SALE: 'Time & Sales',
  MATCHING_ORDER: 'Số lệnh',

  summary: {
    MARKET_CAP: 'Vốn hóa (Tỷ VND)',
    OUTSTANDING: 'Cổ phiếu lưu hành (Tr. CP)',
    FREE_FLOAT: 'Free float (Tr. CP)',
    FREE_FLOAT_RATE: 'Tỷ lệ free float',
    FOREIGN_CURRENT_ROOM: 'Room NN sở hữu (Tr. CP)',
    FOREIGN_CURRENT_PERCENT: '% SH nước ngoài',
    ROE: 'ROE (%)',
    PE: 'P/E (Lần)',
    ONE_DAY_CHANGE: '% Thay đổi 1N',
    ONE_WEEK_CHANGE: '% Thay đổi 1 tuần',
    ONE_MONTH_CHANGE: '% Thay đổi 1 tháng',
    YTD_CHANGE: '% Thay đổi YTD',
    TOTAL_VALUE: 'Tổng giá trị (Tr. VND)',
    TOTAL_VOLUME: 'Tổng khối lượng (Nghìn CP)',
    AVG_VOL_TEN_DAY: 'KLTB 10 ngày (Nghìn CP)',
    AVG_VOL_ONE_MONTH: 'KLTB 1 tháng (Nghìn CP)',
  },

  pricePerformance: {
    PRICE: 'Giá (Nghìn VND)',
    VOLUME: 'Khối lượng:',
    THOUSAND: 'Nghìn',
  },

  priceDepth: {
    BID: 'GIÁ MUA',
    ASK: 'GIÁ BÁN',
    BUY_VOL: 'KL MUA',
    SELL_VOL: 'KL BÁN',
    TOTAL_BUY_VOL: 'TỔNG KL MUA',
    TOTAL_MATCHED_VOL: 'TỔNG KL KHỚP',
    TOTAL_SELL_VOL: 'TỔNG KL BÁN',
  },

  timeSale: {
    BU_VOLUME: 'KL mua chủ động',
    SD_VOLUME: 'KL bán chủ động',
    UNIDENTIFIED: 'Không xác định',
    VOLUME_SHARE: 'Khối lượng (Nghìn CP)',
    PRICE_VND: 'Giá (Nghìn VND)',
    PRICE: 'Giá',
    THOUSAND: 'Nghìn',
  },

  matchingOrder: {
    TIME: 'Thời gian',
    TYPE: 'Kiểu',
    PRICE: 'Giá',
    CHANGE: 'Thay đổi',
    PERCENT_CHANGE: '% Thay đổi 1N',
    MATCH_VOL: 'KL khớp',
    TOTAL_MATCH_VOL: 'Tổng KL khớp',
    NOTE_TEXT: 'Giá: x 1,000. Khối lượng: x 1,000.',
  },
}

export const priceStatistics = {
  PRICE_DATA: 'THỐNG KÊ GIÁ',
  VALUE_INVESTOR: 'GIÁ TRỊ RÒNG THEO NHÀ ĐẦU TƯ',
  NET_VALUE: 'PHÂN TÁCH GIÁ TRỊ GIAO DỊCH',

  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',

  SCREEN_LABEL: 'Thống kê giá',
  TABLE_FOOTER_TEXT: 'Dữ liệu được cập nhật tại ',

  // TABS
  OVER_VIEW: 'Tổng quan',
  ORDER_STATISTICS: 'Đặt lệnh',
  FOREIGN: 'Nước ngoài',
  PROPRIETARY: 'Tự doanh',
  STATISTICS_INVESTOR: 'Phân loại nhà đầu tư',

  // COMPONENT
  valueInvestor: {
    NET_VALUE: 'GIÁ TRỊ RÒNG',
    PERCENT_VALUE: '% GIÁ TRỊ',
    NET_VALUE_UNIT: 'Giá trị (Tỷ VND)',
    PERCENT_VALUE_UNIT: 'Giá trị',
    PRICE_UNIT: 'Giá (Nghìn VND)',
    INDEX_POINT: 'Chỉ số (Điểm)',

    LOCAL_INSTITUTION: 'Tổ chức trong nước',
    PROPRIETARY: 'Tự doanh',
    FOREIGN: 'Nước ngoài',
    LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
    BILLION: 'Tỷ',
  },

  netValue: {
    VALUE_UNIT: 'Giá trị (Tỷ VND)',
    BUY: 'GT mua',
    SELL: 'GT bán',
    NET: 'GT ròng',
    BILLION: 'Tỷ',
  },

  listType: {
    FOREIGN: 'Nước ngoài',
    LOCAL_INSTITUTION: 'Tổ chức trong nước',
    LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
    PROPRIETARY: 'Tự doanh',
  },

  transactionType: {
    ALL: 'Tất cả các GD',
    MATCH: 'GD khớp lệnh',
    DEAL: 'GD Thỏa thuận',
  },

  priceData: {
    DAILY: 'Hàng ngày',
    WEEKLY: 'Hàng tuần',
    MONTHLY: 'Hàng tháng',
    QUARTERLY: 'Hàng quý',
    YEARLY: 'Hàng năm',

    DATE: 'NGÀY',
    WEEK: 'TUẦN',
    MONTH: 'THÁNG',
    QUARTER: 'QUÝ',
    YEAR: 'NĂM',

    overview: {
      TABLE_HEAD_TEXT:
        'Giá: x 1,000. Khối lượng: x 1,000. Giá trị: x 1,000,000.',
      DATE: 'NGÀY',
      PERCENT_CHANGE_DAILY: '% THAY ĐỔI 1D',
      PERCENT_CHANGE_WEEKLY: '% THAY ĐỔI 1W',
      PERCENT_CHANGE_MONTHLY: '% THAY ĐỔI 1M',
      PERCENT_CHANGE_YEARLY: '% THAY ĐỔI 1Y',
      PERCENT_CHANGE_QUARTERLY: '% THAY ĐỔI 1Q',
      OPEN: 'MỞ CỬA',
      HIGH: 'CAO NHẤT',
      LOW: 'THẤP NHẤT',
      CLOSE: 'ĐÓNG CỬA',
      MATCHED_VOLUME: 'KHỐI LƯỢNG KHỚP',
      MATCHED_VALUE: 'GIÁ TRỊ KHỚP',
      PUT_THROUGH_VALUE: 'GIÁ TRỊ THỎA THUẬN',
      PUT_THROUGH_VOLUME: 'KHỐI LƯỢNG THỎA THUẬN',
      TOTAL_VOL: 'TỔNG KHỐI LƯỢNG',
      TOTAL_VALUE: 'TỔNG GIÁ TRỊ',
      MARKET_CAP: 'VỐN HÓA',
    },
    orderStatistic: {
      DATE: 'NGÀY',
      UNMATCH_BUY_VOL: 'DƯ MUA',
      UNMATCH_SELL_VOL: 'DƯ BÁN',
      NR_BUY_ORDER: 'SỐ LỆNH MUA',
      BUY_VOL: 'KL ĐẶT MUA',
      VOL_BUY_ORDER: 'KLTB 1 LỆNH MUA',
      NR_SELL_ORDER: 'SỐ LỆNH BÁN',
      SELL_VOL: 'KL ĐẶT BÁN',
      VOL_SELL_ORDER: 'KLTB 1 LỆNH BÁN',
      NET_VOL: 'KL RÒNG',
    },
    foreign: {
      DATE: 'NGÀY',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      FOREIGN_CR_ROOM: 'ROOM NN CÒN LẠI',
      FOREIGN_TOTAL_ROOM: 'TỔNG ROOM NN',
      PERCENT_FOREIGN_TOTAL_ROOM: '% TỔNG ROOM NN\nĐƯỢC PHÉP SH',
      FOREIGN_OWNED: 'NN HIỆN ĐANG SỞ HỮU',
      PERCENT_FOREIGN_OWNED: '% SỞ HỮU NN',
    },
    proprietary: {
      DATE: 'NGÀY',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      MATCH_NET_VOL: 'KL KHỚP LỆNH RÒNG',
      MATCH_NET_VAL: 'GT KHỚP LỆNH RÒNG',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      DEAL_NET_VOL: 'KL THỎA THUẬN RÒNG',
      DEAL_NET_VAL: 'GT THỎA THUẬN RÒNG',
    },
    statisticsInvest: {
      DATE: 'NGÀY',
      LOCAL_INDIVIDUAL: 'CÁ NHÂN TRONG NƯỚC',
      LOCAL_INSTITUTION: 'TỔ CHỨC TRONG NƯỚC',
      FOREIGN_INDIVIDUAL: 'CÁ NHÂN NƯỚC NGOÀI',
      FOREIGN_INSTITUTION: 'TỔ CHỨC NƯỚC NGOÀI',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      MATCH_NET_VOL: 'KL KHỚP LỆNH RÒNG',
      MATCH_NET_VAL: 'GT KHỚP LỆNH RÒNG',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      DEAL_NET_VOL: 'KL THỎA THUẬN RÒNG',
      DEAL_NET_VAL: 'GT THỎA THUẬN RÒNG',
    },
  },
}
