import { LIST_TYPE } from '../constants'

export const getUsedColorsBySecuritiesAndEquations = (
  treeSecuritiesIndicator,
  equations,
) => {
  const colors = []
  Object.values(equations).forEach((item) => {
    if (item.color) {
      colors.push(item.color)
    }
  })
  Object.keys(treeSecuritiesIndicator).forEach((key) => {
    const security = treeSecuritiesIndicator[key]
    if (key === LIST_TYPE.ECONOMY) {
      Object.values(security).forEach((indicator) => {
        if (indicator.color) {
          colors.push(indicator.color)
        }
      })
    } else {
      Object.values(security).forEach((item) =>
        item.forEach((indicator) => {
          if (indicator.color) {
            colors.push(indicator.color)
          }
        }),
      )
    }
  })
  return colors
}
