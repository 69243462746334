import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize } from '../../utils/FontSize'
import { forwardRef } from 'react'

export const Button = forwardRef(({ children, style, ...others }, ref) => {
  return (
    <button
      ref={ref}
      style={{ ...style, fontSize: getFontSize(style.fontSize) }}
      {...others}
    >
      {children}
    </button>
  )
})

Button.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
}

Button.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
