import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valToPercent,
} from '../../../../utils/Value'
import { getBasicInfoThunk } from '../../store/thunk'
import { TABLE_ROW_LEVEL, listTimeFilter } from '../constants'
import { getFinancialMetricsDataArea } from './thunk'

const getTitle = (condition, result1, result2) => {
  if (condition) return result1
  return result2
}

const initialState = {
  financialById: {},
  ids: [],
  groupColumns: [],
  isLoading: true,
  timeFilter: listTimeFilter[0],
  indicator: {
    value: null,
    subValue: null,
  },
}

export const slice = createSlice({
  name: 'corporate/overview/financialMetric',
  initialState,
  reducers: {
    setFinancialById: (state, action) => {
      state.financialById = action.payload
    },
    changeIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      const rowData = state.financialById[rowId]

      if (rowData.childrenId) {
        const childId = rowData.childrenId[0]
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = state.financialById[childId]
      } else if (rowData.parentId) {
        const parentId = rowData.parentId
        state.indicator.value = state.financialById[parentId]
        state.indicator.subValue = state.financialById[rowId]
      } else {
        state.indicator.value = state.financialById[rowId]
        state.indicator.subValue = {}
      }
    },
    resetIndicator: (state) => {
      state.indicator = {
        value: null,
        subValue: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBasicInfoThunk.fulfilled, (state) => {
      state.indicator = {
        value: null,
        subValue: null,
      }
    })
    builder.addCase(getFinancialMetricsDataArea.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getFinancialMetricsDataArea.fulfilled, (state, action) => {
      state.isLoading = false
      if (!action.payload || action.payload.length === 0) {
        state.groupColumns = []
        return
      }

      const groupColumns = []
      const financialArr = Object.values(state.financialById)
      const timeFilter = action.meta.arg.TimeFilter
      const quarterly = listTimeFilter[0].value
      action.payload.forEach((item) => {
        groupColumns.push({
          key: getTitle(
            timeFilter === quarterly,
            `columnQ${item.quarter}/${item.year}`,
            `column${item.year}`,
          ),
          title: getTitle(
            timeFilter === quarterly,
            `Q${item.quarter}/${item.year}`,
            item.year,
          ),
        })
        financialArr.forEach((element) => {
          if (element.level !== TABLE_ROW_LEVEL.LEVEL_1) {
            const value =
              item[element.key[timeFilter]] ||
              item[element.key[timeFilter]] === 0
                ? item[element.key[timeFilter]] /
                  element.isFormatValue[timeFilter]
                : null

            element[
              getTitle(
                timeFilter === quarterly,
                `columnQ${item.quarter}/${item.year}`,
                `column${item.year}`,
              )
            ] = element.isFormatValToPercent
              ? valToPercent(value)
              : formatVal(value)
          }
        })
      })
      state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
    })
    builder.addCase(getFinancialMetricsDataArea.rejected, (state, action) => {
      state.isLoading = action.payload.isLoading
    })
  },
})

export const selectFinancialIds = (state) => state[slice.name].ids
export const selectFinancialValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].financialById[id], attr)
}
export const selectLoading = (state) => state[slice.name].isLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectTimeFilter = (state) => state[slice.name].timeFilter
export const selectIndicator = (state) => state[slice.name].indicator
export const selectItemData = (id) => (state) =>
  state[slice.name].financialById[id]

export const {
  changeIds,
  setFinancialById,
  changeTimeFilter,
  changeIndicator,
  resetIndicator,
} = slice.actions

register(slice.name, slice.reducer)
