import { createAsyncThunk } from '@reduxjs/toolkit'
import WatchlistService from '../../../../../core/services/watchlist/WatchlistService'

export const getWatchListThunk = createAsyncThunk(
  'popup/popupIndicator/GET_WATCH_LIST',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlist(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
