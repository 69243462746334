import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { keyBy } from '../../../../utils/Common'
import { selectExchangeCode } from '../slice'
import FirstColumnCell from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import { keyRatios } from './constants'
import {
  changeIds,
  selectGroupColumns,
  selectIds,
  selectLevelCollapse,
  selectLoading,
  selectValueById,
  setRatioKeyById,
} from './store/slice'
import { getKeyRatios } from './store/thunk'

const KeyRatios = ({ width }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(selectIds)
  const levelCollapse = useSelector(selectLevelCollapse)
  const groupColumns = useSelector(selectGroupColumns)
  const loading = useSelector(selectLoading)
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)
  const typeSector = useSectorGroup(sectorInfo.icbId)

  const [rowsCollapse, setRowsCollapse] = useState([])

  useEffect(() => {
    if (sectorInfo.icbId) {
      const mapType = {
        [COM_GROUP.BANK]: keyRatios.bank,
        [COM_GROUP.SECURITIES]: keyRatios.securities,
        [COM_GROUP.CORPORATE]: keyRatios.corporate,
      }
      dispatch(changeIds(mapType[typeSector]))
      dispatch(setRatioKeyById(keyBy(mapType[typeSector], 'index')))
      dispatch(
        getKeyRatios({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
        }),
      )
    }
  }, [sectorInfo.icbId, exchangeCode, locale])

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {groupColumns.length ? (
            <Table
              ids={ids}
              getDataFromRedux={selectValueById}
              isLoading={loading}
              schema={groupColumns.map((item, index) => {
                const title = item.title
                const colId = item.key
                const result = {
                  colId,
                  title,
                }

                if (index === 0) {
                  return {
                    ...result,
                    isI18n: true,
                    thStyle: {
                      textAlign: 'left',
                      fontSize: 10,
                    },
                    canCustomTd: true,
                    render: (val, rowId, { style, className }) => {
                      return (
                        <FirstColumnCell
                          val={val}
                          rowId={rowId}
                          style={{
                            ...style,
                            width: '33%',
                            minWidth: width / 3,
                          }}
                          className={className}
                          onRowCollapse={onRowCollapse}
                        />
                      )
                    },
                  }
                }

                return {
                  ...result,
                  isI18n: true,
                  thStyle: {
                    textAlign: 'right',
                    fontSize: 10,
                  },
                  canCustomTd: true,
                  render: (val, rowId, { style, className }) => {
                    return (
                      <OtherColumnsCell
                        val={val}
                        rowId={rowId}
                        style={style}
                        className={className}
                      />
                    )
                  },
                }
              })}
              stickyFirstColumn={true}
              hasFooter={false}
              isCollapse={true}
              rowsCollapse={rowsCollapse}
              levelCollapse={levelCollapse}
              defaultScrollToRight
            />
          ) : (
            <NoData />
          )}
        </>
      )}
    </>
  )
}

export default KeyRatios
