import ExpectedCashProxy from '../../../../proxies/bond/overview/expectedCash'
import { ServiceBase } from '../../../ServiceBase'

export class ExpectedCash extends ServiceBase {
  getDataChart(params) {
    return this.getData(() => ExpectedCashProxy.getDataChart(params))
  }
  getDataCashfollowAll(params) {
    return this.getData(() => ExpectedCashProxy.getExpectedCashFlowsAll(params))
  }
}

export default new ExpectedCash()
