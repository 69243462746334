import { useEffect, useState } from 'react'
import chartLayoutService from '../../core/services/common/ChartLayoutService'
import { TradingView } from '../common/tradingView'

export const Charting = () => {
  const [savedData, setSavedData] = useState(null)

  useEffect(() => {
    getSharedTemplate()
  }, [])

  const getSharedTemplate = () => {
    const query = new URLSearchParams(window.location.search)
    const shareId = query.get('shareId')
    const signature = query.get('signature')

    chartLayoutService
      .getSharedChartLayout({
        ShareId: shareId,
        Signature: signature,
      })
      .then((res) => {
        const chartState = JSON.parse(res.data.layout)
        setSavedData(chartState)
      })
  }

  return <TradingView savedData={savedData} isNewWindow={true} />
}
