import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal, valDivThousand } from '../../../../utils/Value'
import { COLOR_LINE_KEYS, TITLE_LINE_KEYS } from './constants'
import style from './index.module.css'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 5,
}
export const WIDTH_LABEL = 100
const MARGIN = { ...MARGIN_RECHARTS, top: 40, left: 10 }

const breakEvenPointTooltip = (valueTooltip) => {
  return (
    <div className={style.chartTooltip}>
      <div style={{ fontStyle: 'italic' }} className="mb-8">
        <span style={{ fontSize: 11 }}>
          <DateTimeSpan
            date={valueTooltip.tradingDateId}
            formatDate={FORMAT.DATE}
          />
        </span>
      </div>
      <table>
        <tbody>
          <tr className={style.trTable}>
            <td className={style.pdLabel}>
              <div className={style.mr12}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t(
                    'market.coveredWarrant.breakEvenPoint.CW_BREAK_EVEN_POINT',
                  )}
                </Span>
                :
              </div>
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
              className={`${style.pdLabel} ${style.fontWeight500}`}
            >
              <Span style={{ fontSize: 11 }}>
                {formatVal(valueTooltip.breakEvenPoint)}
              </Span>
            </td>
          </tr>
          <tr className={style.trTable}>
            <td className={style.pdLabel}>
              <div className={style.mr12}>
                <Span style={{ fontSize: 11 }}>
                  {I18n.t(
                    'market.coveredWarrant.breakEvenPoint.UNDERLYING_ASSET_PRICE',
                  )}
                </Span>
                :
              </div>
            </td>
            <td
              style={{
                textAlign: 'right',
              }}
              className={`${style.pdLabel} ${style.fontWeight500}`}
            >
              <Span style={{ fontSize: 11 }}>
                {formatVal(valueTooltip.closePriceAdjusted)}
              </Span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const ChartComponent = ({ data, width, height, keyXAxis, lineKeys }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const formattedData = data.map((item) => ({
    breakEvenPoint: valDivThousand(item.breakEvenPoint),
    closePriceAdjusted: valDivThousand(item.closePriceAdjusted),
    adjustedExercisePrice: valDivThousand(item.adjustedExercisePrice),
    tradingDateId: item.tradingDateId,
  }))
  const heightLabel = getFontSize(18)

  const CustomLabel = ({ value, lineKey }) => {
    const lineKeyIndex = lineKeys.indexOf(lineKey)
    return (
      <div
        className={style.labelChart}
        style={{
          backgroundColor: COLOR_LINE_KEYS[lineKeyIndex],
          color: '#1e242e',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '100%',
            alignItems: 'center',
            padding: '0px 3px',
            gap: 3,
          }}
        >
          <div className="flex-1" style={{ minWidth: 0 }}>
            <Span>
              <TextEllipsis
                text={TITLE_LINE_KEYS[lineKeyIndex]}
                isI18n={true}
              />
            </Span>
          </div>
          <Span>{formatVal(value)}</Span>
        </div>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={formattedData}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            tickFormatter={(value) =>
              formatDateTime(value, FORMAT.DATE, locale, timeZone)
            }
            timeFrame="All"
            yAxis={[
              {
                id: 'right',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'right',
                label: `${I18n.t(
                  'market.coveredWarrant.breakEvenPoint.THOUSAND_VND',
                )}`,
                labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
                hasReferenceLabel: true,
                referenceLabelWidth: WIDTH_LABEL,
                referenceLabelHeight: heightLabel,
                tickNum: SETTINGS.yTickNum,
                renderReferenceLabel: ({ data, key }) => {
                  return (
                    <CustomLabel
                      value={data[key]}
                      lineKey={lineKeys.find((lineKey) => lineKey === key)}
                    />
                  )
                },
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              breakEvenPointTooltip(payload, decimalLengths)
            }
          >
            {lineKeys.slice(0, 2).map((item, index) => (
              <Line
                key={index}
                yAxisId="right"
                dataKey={item}
                type="linear"
                stroke={COLOR_LINE_KEYS[index]}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={2}
              />
            ))}
            <Line
              yAxisId="right"
              dataKey={lineKeys[2]}
              type="linear"
              strokeDasharray="5"
              stroke={COLOR_LINE_KEYS[2]}
              dot={false}
              activeDot={false}
              isAnimationActive={false}
              strokeWidth={2}
            />
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  ticker: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
