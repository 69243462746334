import { useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { valToPercent } from '../../../../utils/Value'
import { keySchemaCommodities, keyTableCommodities } from '../constans'
import {
  changeCommoditiesId,
  keys,
  selectCommoditiesCell,
  selectCommoditiesId,
  selectLoading,
  sortCommodities,
} from '../store/slice'
import TdColor from '../TdColor'

const TableCommodities = () => {
  const commodities = useSelector(selectCommoditiesId)
  const loading = useSelector(selectLoading(keys.COMMODITIES))

  return (
    <>
      <Table
        ids={commodities}
        columnDraggable={false}
        getDataFromRedux={selectCommoditiesCell}
        isLoading={loading}
        changeIds={changeCommoditiesId}
        rowDraggable={false}
        hasFooter={false}
        stickyFirstColumn={true}
        hasTooltip={false}
        resizable={false}
        sort={sortCommodities}
        schema={Object.keys(keyTableCommodities).map((key) => {
          const title = `market.marketInDepth.indices.${key}`
          const colId = keyTableCommodities[key]
          const result = {
            colId,
            title,
            disableSort: false,
            thStyle: {
              textAlign:
                key === keySchemaCommodities.COMMODITY_NAME ? 'left' : 'right',
            },
            tdStyle: {
              textAlign:
                key !== keySchemaCommodities.COMMODITY_NAME ? 'right' : 'left',
            },
          }

          return {
            ...result,
            isI18n: false,
            canCustomTd: key !== keySchemaCommodities.COMMODITY_NAME,
            render:
              key !== keySchemaCommodities.COMMODITY_NAME
                ? (value, colId, { style, className }) => (
                    <TdColor
                      value={
                        key === keySchemaCommodities.PERCENT_PRICE_CHANGE
                          ? valToPercent(value)
                          : value
                      }
                      colId={colId}
                      keyColor="priceChange"
                      selectCell={selectCommoditiesCell}
                      isPercent={
                        key === keySchemaCommodities.PERCENT_PRICE_CHANGE
                          ? true
                          : false
                      }
                      style={style}
                      className={className}
                    />
                  )
                : undefined,
          }
        })}
      />
    </>
  )
}

export default TableCommodities
