import { COMPONENT } from '../../../../configs/Layout'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import Filter from './filter'
import PanelPriceChart from './priceChart/PanelPriceChart'
import PanelReport from './report/PanelReport'
import PanelValuationChart from './valuationChart/PanelValuationChart'
import PanelValuationTable from './valuationTable/PanelValuationTable'

const MAP_KEY = {
  FILTER: 'FILTER',
  VALUATION_CHART: 'VALUATION_CHART',
  VALUATION_TABLE: 'VALUATION_TABLE',
  PRICE_CHART: 'PRICE_CHART',
  REPORT: 'REPORT',
}

const FILTER_WIDTH = 243
const MIN_WIDTH = 525
const MIN_HEIGHT = 350

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: `100%`,
    top: 0,
    left: 0,
    filterResize: [
      {
        key: MAP_KEY.VALUATION_CHART,
        ratioWidth: 1,
        ratioLeft: -1,
      },
      {
        key: MAP_KEY.PRICE_CHART,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUATION_CHART}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.6)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.VALUATION_TABLE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.VALUATION_TABLE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.PRICE_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.REPORT,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.VALUATION_TABLE}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.4)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.6 + ${
      FILTER_WIDTH + 4 * COMPONENT.MARGIN
    }px)`,
    minTop: 0,
    minLeft: FILTER_WIDTH + 4 * COMPONENT.MARGIN + MIN_WIDTH,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRICE_CHART}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.6)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minTop: MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    minHeight: MIN_HEIGHT,
    verticalResize: [
      {
        key: MAP_KEY.REPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REPORT}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.4)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.6 + ${
      FILTER_WIDTH + 4 * COMPONENT.MARGIN
    }px)`,
    minTop: MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: FILTER_WIDTH + 4 * COMPONENT.MARGIN + MIN_WIDTH,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="sector.sectorConstituents.valuation.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>
      <PanelValuationChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUATION_CHART}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.VALUATION_CHART].width - PANEL_PADDING}
        height={sizes[MAP_KEY.VALUATION_CHART].height - HEADER_PANEL_HEIGHT}
      />
      <PanelValuationTable
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUATION_TABLE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelPriceChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PRICE_CHART}
        sizes={sizes}
        setSizes={setSizes}
        width={sizes[MAP_KEY.PRICE_CHART].width - PANEL_PADDING}
        height={sizes[MAP_KEY.PRICE_CHART].height - HEADER_PANEL_HEIGHT}
      />
      <PanelReport
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REPORT}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
