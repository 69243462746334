import { useSelector } from 'react-redux'
import KeyRatios from '.'
import { Panel } from '../../../../common/panel'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectExchangeCode } from '../slice'
import { downloadKeyRatios } from './store/thunk'

const PanelKeyRatios = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const sectorInfo = useSelector(selectSectorInfo)
  const exchangeCode = useSelector(selectExchangeCode)

  return (
    <Panel
      title={'sector.sectorConstituents.overview.KEY_RATIOS'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv={true}
      downloadCsv={() =>
        downloadKeyRatios({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
        })
      }
    >
      <KeyRatios width={formatNumber(sizes[panelKey].width) - PANEL_PADDING} />
    </Panel>
  )
}

export default PanelKeyRatios
