import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Header from '../common/Header'
import Content from './Content'
import { handleIsAllowCallApi as allowCallApiSpendingMethod } from './panelGDPSpendingMethod/store/slice'
import { handleIsAllowCallApi as allowCallApiStructure } from './panelGDPStructure/store/slice'
import { getNominalRealValue, handleNominalRealValue } from './store/slice'

const GDPBySpendingMethod = () => {
  const dispatch = useDispatch()
  const nominalRealValue = useSelector(getNominalRealValue)

  const handleChangeRadio = (value) => {
    dispatch(handleNominalRealValue(value))
    dispatch(allowCallApiSpendingMethod(true))
    dispatch(allowCallApiStructure(true))
  }

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(sizes) => {
          return (
            <>
              <Header
                title="economy.gdp.gdp.GDP_BY_SPENDING_METHOD"
                radioValue={nominalRealValue}
                handleChangeRadio={handleChangeRadio}
              />
              {sizes.height && (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${sizes.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default GDPBySpendingMethod
