import { BondBuyback } from '../../bond/bondBuyback'
import { BondScreening } from '../../bond/bondScreening'
import BondSecurity from '../../bond/corporateBond/bondSecurity'
import { InterconnectionMap } from '../../bond/corporateBond/interconnectionMap'
import { Issuers } from '../../bond/corporateBond/issuers'
import { PriceBoard } from '../../bond/corporateBond/priceBoard'
import { PrimaryMarket } from '../../bond/corporateBond/primaryMarket'
import { SecondaryMarket } from '../../bond/corporateBond/secondaryMarket'
import { Portfolio } from '../../bond/portfolio'
import Valuation from '../../bond/valuation'

export const BOND_ROUTE = {
  name: 'constants.route.bond.LABEL',
  routeDefault: '/bond/corporate-bond/primary-market',
  components: {
    'primary-market': {
      route: '/bond/corporate-bond/primary-market',
      name: 'constants.route.bond.corporateBond.primaryMarket.LABEL',
      renderMain: () => <PrimaryMarket />,
    },
    'secondary-market': {
      route: '/bond/corporate-bond/secondary-market',
      name: 'constants.route.bond.corporateBond.secondaryMarket.LABEL',
      renderMain: () => <SecondaryMarket />,
    },
    issuers: {
      route: '/bond/corporate-bond/issuers',
      name: 'constants.route.bond.corporateBond.issuers.LABEL',
      renderMain: () => <Issuers />,
    },
    'bond-security': {
      route: '/bond/corporate-bond/security-master',
      name: 'constants.route.bond.corporateBond.bondSecurity.LABEL',
      renderMain: () => <BondSecurity />,
      hiddenOnSideBar: true,
    },
    'bond-buyback': {
      route: '/bond/bond-buyback',
      name: 'constants.route.bond.bondBuyback.LABEL',
      renderMain: () => <BondBuyback />,
    },
    'price-board': {
      name: 'constants.route.bond.priceBoard.LABEL',
      hasChildren: false,
      route: '/bond/price-board',
      renderMain: () => <PriceBoard />,
    },
    'interconnection-map': {
      name: 'constants.route.bond.interconnectionMap.LABEL',
      hasChildren: false,
      route: '/bond/interconnection-map',
      renderMain: () => <InterconnectionMap />,
    },
    valuation: {
      name: 'constants.route.bond.corporateBond.valuation.LABEL',
      hasChildren: false,
      route: '/bond/valuation',
      renderMain: () => <Valuation />,
    },
    'bond-screening': {
      name: 'constants.route.bond.bondScreening.LABEL',
      hasChildren: false,
      route: '/bond/bond-screening',
      renderMain: () => <BondScreening />,
    },
    portfolio: {
      name: 'constants.route.bond.portfolio.LABEL',
      hasChildren: false,
      route: '/bond/watchlist-portfolio',
      renderMain: () => <Portfolio />,
    },
  },
}
