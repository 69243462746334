import { TIME_FILTER_VALUE } from '../filter/constants'

export const chartFillColor = {
  growthTimelineValue: '#facc5c',
  comparisonValue: '#0096eb',
}

export const chartSettings = {
  barWidth: 35,
  fontSize: 10,
}

export const chartTopLabelByTimeDisplay = {
  [TIME_FILTER_VALUE.YEARLY]: 'corporate.financialData.financialRatio.BY_YEAR',
  [TIME_FILTER_VALUE.QUARTERLY]:
    'corporate.financialData.financialRatio.BY_QUARTER',
  [TIME_FILTER_VALUE.SIX_MONTHS]:
    'corporate.financialData.financialRatio.BY_SIX_MONTHS',
  [TIME_FILTER_VALUE.NINE_MONTHS]:
    'corporate.financialData.financialRatio.BY_NINE_MONTHS',
}
