import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { HEADER_VALUE } from '../constants'
import style from './common.module.css'

const Header = ({ title, radioValue, handleChangeRadio }) => {
  const { NOMINAL, REAL } = HEADER_VALUE
  const RADIO_LIST = [
    {
      value: NOMINAL,
      label: 'economy.gdp.gdp.NOMINAL',
    },
    {
      value: REAL,
      label: 'economy.gdp.gdp.REAL',
    },
  ]

  return (
    <div className={`${style.header}`}>
      <Title title={title} />
      <VerticalLine />
      <div className="d-flex">
        {RADIO_LIST.map(({ value, label }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
              onClick={() => handleChangeRadio(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === radioValue}
                onChange={() => handleChangeRadio(value)}
              />
              <Span style={{ fontSize: 14, fontWeight: 500 }}>
                <Translate value={label} />
              </Span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  radioValue: PropTypes.string.isRequired,
  handleChangeRadio: PropTypes.func.isRequired,
}

export default Header
