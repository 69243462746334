import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Loading } from '../../../../common/loading'
import Tree from '../../../../common/tree'
import { Z_INDEX } from '../../../../constants/Common'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../utils/EventEmitter'
import style from '../../index.module.css'
import {
  changeCheckedSector,
  changeIsFirstMount,
  changeParentCheckedSector,
  key,
  selectChecked,
  selectIsFirstMount,
} from '../../store/slice'
import NameChecked from './NameChecked'

const DropdownSector = ({ ICBsTree, ICBs, loading }) => {
  const dispatch = useDispatch()

  const parentRef = useRef()

  const listChecked = useSelector(selectChecked(key.SECTOR))
  const isFirstMount = useSelector(selectIsFirstMount(key.SECTOR))

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')
  const [listAllChecked, setListAllChecked] = useState([])

  useEffect(() => {
    if (isFirstMount && listChecked.length > 0) {
      dispatch(changeIsFirstMount(key.SECTOR))
      setListAllChecked(listChecked)
    }
  }, [listChecked, isFirstMount])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  const setListChecked = (listChecked) =>
    dispatch(changeCheckedSector(listChecked))

  const setParentChecked = (listChecked) =>
    dispatch(changeParentCheckedSector(listChecked))

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className="span-input w-100 cursor-pointer h-20"
        style={{ borderRadius: isShowDropdown ? '4px 4px 0px 0px' : undefined }}
      >
        <NameChecked
          ICBs={ICBs}
          listAllChecked={listAllChecked}
          listChecked={listChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`wrapper-tool ${style.mt3}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox bg-grey h-20 ${style.search}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <Tree
                    valueSearch={valueSearch}
                    treeData={ICBsTree}
                    idKey="icbId"
                    nameKey="icbName"
                    levelKey="icbLevel"
                    isDefaultCheckAll={isFirstMount ? true : false}
                    listChecked={listChecked}
                    setListChecked={setListChecked}
                    setListParentChecked={setParentChecked}
                    isHaveCheckboxAll={true}
                    titleCheckboxAll="tool.smartScreening.ALL_SECTOR"
                    isHaveCheckbox={true}
                    isActiveSelected={false}
                    autoHeight={true}
                    autoHeightMin={200}
                    autoHeightMax={300}
                    isCheckedWhenClickText
                    zIndexTextEllipsis={Z_INDEX.DROPDOWN + 1}
                  />
                )}
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </>
  )
}

DropdownSector.propTypes = {
  ICBsTree: PropTypes.object.isRequired,
  ICBs: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default DropdownSector
