import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'
import { handleExport } from '../../../../../../utils/Export'

export const getBankLoanToCustomer = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanToCustomer',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PenetrationAnalysisService.getBankLoanToCustomer(
        params,
      )

      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankLoanMarketShare = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getBankLoanMarketShare(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankLoanChangeInMarketShare = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanChangeInMarketShare',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankLoanChangeInMarketShare(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankLoanBySector = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanBySector',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getBankLoanBySector(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankLoanMarketShareByLoanSector = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanMarketShareByLoanSector',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankLoanMarketShareByLoanSector(
          params,
        )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getBankLoanDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/getBankLoanDetailMetrics',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getBankLoanDetailMetrics(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadBankLoanDetailMetrics = createAsyncThunk(
  'corporate/businessModel/penetration/bank/loan/downloadBankLoanDetailMetrics',
  async (data) => {
    const response =
      await PenetrationAnalysisService.downloadBankLoanDetailMetrics(data)
    handleExport(response.data, response.filename)
  },
)
