import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { formatVal } from '../../../../utils/Value'
import SectorName from '../SectorName'
import TickerName from '../TickerName'

const ItemRow = ({ item, value, decimalLength, hasYAxisLabel }) => {
  return (
    <tr>
      <td>
        <Span style={{ fontSize: 11 }}>
          {item.icbId ? (
            <SectorName icbId={item.icbId} isEllipsis={false} />
          ) : (
            <TickerName
              organizationId={item.organizationId}
              isEllipsis={false}
            />
          )}
        </Span>
      </td>
      <td className="t-right" style={{ paddingLeft: 12 }}>
        <Span style={{ fontSize: 11 }}>
          {formatVal(value, decimalLength || 2)}
          {hasYAxisLabel && (
            <>
              &nbsp;
              <Translate value="sector.sectorConstituents.valuation.TRILLION_VND_TOOLTIP" />
            </>
          )}
        </Span>
      </td>
    </tr>
  )
}

const ChartTooltip = ({
  data,
  items,
  keyXAxis,
  decimalLength,
  hasYAxisLabel,
}) => {
  return (
    <>
      <Span style={{ fontStyle: 'italic', fontSize: 10 }}>
        <DateTimeSpan date={data[keyXAxis]} />
      </Span>
      {items.length <= 10 ? (
        <table>
          <tfoot>
            {items.map((item) => (
              <ItemRow
                key={item.id}
                item={item}
                value={data[item.id]}
                decimalLength={decimalLength}
                hasYAxisLabel={hasYAxisLabel}
              />
            ))}
          </tfoot>
        </table>
      ) : (
        <div className="d-flex" style={{ gap: 16 }}>
          <div>
            <table>
              <tfoot>
                {items.slice(0, Math.ceil(items.length / 2)).map((item) => (
                  <ItemRow
                    key={item.id}
                    item={item}
                    value={data[item.id]}
                    decimalLength={decimalLength}
                  />
                ))}
              </tfoot>
            </table>
          </div>
          <div>
            <table>
              <tfoot>
                {items.slice(Math.ceil(items.length / 2)).map((item) => (
                  <ItemRow
                    key={item.id}
                    item={item}
                    value={data[item.id]}
                    decimalLength={decimalLength}
                  />
                ))}
              </tfoot>
            </table>
          </div>
        </div>
      )}
    </>
  )
}

export default ChartTooltip
