import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupEvent from '../../../../../common/popup/PopupEvent'
import { Z_INDEX } from '../../../../../constants/Common'
import { deleteTemplate } from '../../store/thunk'

const DeleteTemplate = ({ item, handleClose }) => {
  const dispatch = useDispatch()

  const handleDelete = () => {
    dispatch(deleteTemplate({ templateId: item.value }))
    handleClose()
  }

  return (
    <PopupEvent
      isShow
      title={
        <Translate value="sector.sectorConstituents.segmentation.TITLE_DELETE_TEMPLATE" />
      }
      message={
        <>
          <Translate value="sector.sectorConstituents.segmentation.ASK_DELETE_TEMPLATE" />{' '}
          {item.templateName}
        </>
      }
      handleClose={handleClose}
      btnCancel={{
        btnName: <Translate value="common.button.BUTTON_CANCEL" />,
        event: handleClose,
      }}
      btnSubmit={{
        btnName: <Translate value="common.button.BUTTON_OK" />,
        event: handleDelete,
      }}
      zIndexOverlay={Z_INDEX.DROPDOWN + 1}
      zIndex={Z_INDEX.DROPDOWN + 1}
    />
  )
}

export default DeleteTemplate
