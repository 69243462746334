import { useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import Container from './Container'
import { getDate, getTab } from './store/slice'
import { downloadStatisticOtherBank } from './store/thunk'

const DepositRateCommercial = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeZone = UseTimeZone()

  const date = useSelector(getDate)
  const tab = useSelector(getTab)

  const downloadCsv = () => {
    const params = {
      DateTo: date ? getISOStartOrEndOfDay(date, timeZone, true) : undefined,
      IsIndividual: tab,
    }

    return downloadStatisticOtherBank(params)
  }

  return (
    <Panel
      title={'economy.monetary.monetary.DEPOSIT_RATE_COMMERCIAL'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default DepositRateCommercial
