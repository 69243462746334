import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { handleLineColor } from './helper'
import style from './index.module.css'
import {
  getCheckboxChecked,
  getIndexChecked,
  getLineChartColor,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from './store/slice'

const MAX_TICK = 20

const FirstCellColumn = ({ val, props, rowId, level, tableMapping }) => {
  const dispatch = useDispatch()

  const lineChartColor = useSelector(getLineChartColor)
  const indexChecked = useSelector(getIndexChecked)
  const checkboxChecked = useSelector(getCheckboxChecked)

  const customByLevel = (level) => {
    switch (level) {
      case 2:
        return { color: '#fecf2f' }
      case 3:
        return { marginLeft: 32 }
      default:
        return { marginLeft: 56 }
    }
  }

  const handleCheckbox = (id) => {
    const isChecked = checkboxChecked.includes(id)
    const currentIndex = tableMapping.findIndex(
      (item) => item.nationalAccountVNTypeId === id,
    )
    let newIndexChecked = []
    let newCheckboxChecked = checkboxChecked

    if (isChecked) {
      newCheckboxChecked = checkboxChecked.filter((item) => item !== id)
      newIndexChecked = indexChecked.filter((item) => item !== currentIndex)
    } else {
      if (checkboxChecked.length > MAX_TICK) {
        return
      } else {
        newCheckboxChecked = [...checkboxChecked, id]
        newIndexChecked = [...indexChecked, currentIndex]
      }
    }

    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(handleLineColor(id, lineChartColor, isChecked)),
    )
  }

  return (
    <td {...props}>
      <div className={style.firstCell} onClick={() => handleCheckbox(rowId)}>
        <input
          type="checkbox"
          checked={checkboxChecked.includes(rowId)}
          className={`checkbox mr-8`}
          style={{ cursor: 'pointer', minWidth: 12 }}
          readOnly
        />
        <TextEllipsisStaticLine
          val={val}
          isI18n={false}
          appendStyle={customByLevel(level)}
        />
      </div>
    </td>
  )
}

FirstCellColumn.propTypes = {
  val: PropTypes.string,
  props: PropTypes.object,
  level: PropTypes.number,
  rowId: PropTypes.number,
  tableMapping: PropTypes.array,
}

export default FirstCellColumn
