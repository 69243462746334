import { useSelector } from 'react-redux'

const Checkbox = ({ id, selectDataById, checkboxKey, isMaxCheck }) => {
  // Use selector
  const value = useSelector(selectDataById(id, checkboxKey))

  return (
    <div className="d-flex justify-content-center">
      <input
        type="checkbox"
        className="checkbox"
        checked={value}
        disabled={!value && isMaxCheck}
        readOnly
      />
    </div>
  )
}

export default Checkbox
