import React from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { TextArea } from '../../../common/html/TextArea'
import SelectCustom from '../../../common/selectCustom'
import IconTooltip from './IconTooltip'
import PopupSettingFinancialStatement from './popupSettingFinancialStatement'
import PopupSettingPeerAnalysis from './popupSettingPeerAnalysis'
import PopupSettingTimeRange from './popupSettingTimeRange'
import style from './style.module.css'

const Setting = ({
  isHandle,
  stateSetting,
  setStateSetting,
  commentaryOnEarnings,
  setCommentaryOnEarnings,
  setDropdownCompanyOverview,
  dropdownCompanyOverview,
}) => {
  const handleChangeSetting = (keyUpdate) => {
    if (keyUpdate) {
      const payload = {
        ...stateSetting,
        [keyUpdate]: !stateSetting[keyUpdate],
      }
      setStateSetting(payload)
    }
  }

  const handleDropdownCompanyOverview = (val) => {
    setDropdownCompanyOverview(val)
  }

  return (
    <>
      <div className={isHandle ? style.isHandle : ''}>
        <div>
          <div className={style.titleOrange}>
            <Translate value="corporate.companySnapshot.COMPANY_OVERVIEW" />
          </div>
          <div className="d-flex" style={{ marginLeft: 32 }}>
            <div className={style.divColOne}>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.companyProfile}
                  onChange={() => handleChangeSetting('companyProfile')}
                />
                <Translate value="corporate.companySnapshot.COMPANY_PROFILE" />
              </label>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.companyPosition}
                  onChange={() => handleChangeSetting('companyPosition')}
                />
                <Translate value="corporate.companySnapshot.COMPANY_POSITION" />
              </label>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.businessStrategies}
                  onChange={() => handleChangeSetting('businessStrategies')}
                />
                <Translate value="corporate.companySnapshot.BUSINESS_STRATEGIES" />
              </label>
              <div className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.dropdownCompanyOverview}
                  onChange={() =>
                    handleChangeSetting('dropdownCompanyOverview')
                  }
                />
                <div className={`mr-8 ${style.spanInputSearch}`}>
                  <SelectCustom
                    value={dropdownCompanyOverview}
                    isI18n={true}
                    selectData={[
                      {
                        name: 'corporate.companySnapshot.COMPARE_WITH_SECTOR',
                        value: 'COMPARE_WITH_SECTOR',
                      },
                      {
                        name: 'corporate.companySnapshot.REVENUE_BREAKDOWN',
                        value: 'REVENUE_BREAKDOWN',
                      },
                    ]}
                    handleChange={handleDropdownCompanyOverview}
                  />
                </div>
                <IconTooltip />
              </div>
            </div>
            <div className={style.divColTwo}>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.stockData}
                  onChange={() => handleChangeSetting('stockData')}
                />
                <Translate value="corporate.companySnapshot.STOCK_DATA" />
              </label>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.oneYear_PriceAndVolume}
                  onChange={() => handleChangeSetting('oneYear_PriceAndVolume')}
                />
                <Translate value="corporate.companySnapshot.ONE_YEAR_PRICE_AND_VOLUME" />
              </label>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.financialRatios}
                  onChange={() => handleChangeSetting('financialRatios')}
                />
                <Translate value="corporate.companySnapshot.FINANCIAL_RATIOS" />
              </label>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.valuationHistory}
                  onChange={() => handleChangeSetting('valuationHistory')}
                />
                <Translate value="corporate.companySnapshot.VALUATION_HISTORY" />
              </label>
            </div>
          </div>
        </div>
        <div>
          <div className={style.titleOrange}>
            <Translate value="corporate.companySnapshot.PEER_ANALYSIS_AND_FINANCIAL_DATA" />
          </div>
          <div style={{ marginLeft: 32 }}>
            <div className={`d-flex ali-center ${style.mb16}`}>
              <label className="d-flex ali-center">
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.peerAnalysis}
                  onChange={() => handleChangeSetting('peerAnalysis')}
                />
                <Translate value="corporate.companySnapshot.PEER_ANALYSIS" />
              </label>
              <PopupSettingPeerAnalysis />
            </div>
            <label className={`d-flex ali-center ${style.mb16}`}>
              <input
                type="checkbox"
                className="checkbox mr-8 "
                checked={stateSetting.financialData}
                onChange={() => handleChangeSetting('financialData')}
              />
              <Translate value="corporate.companySnapshot.FINANCIAL_DATA" />
            </label>
            <label className={`d-flex ali-center ${style.mb12}`}>
              <input
                type="checkbox"
                className="checkbox mr-8 "
                checked={stateSetting.yourCommentaryOnEarnings}
                onChange={() => handleChangeSetting('yourCommentaryOnEarnings')}
              />
              <Translate value="corporate.companySnapshot.YOUR_COMMENTARY_ON_EARNINGS" />
            </label>
            <TextArea
              id="commentaryOnEarnings"
              className={style.descTextArea}
              style={{ fontSize: 11 }}
              value={commentaryOnEarnings}
              placeholder={I18n.t(
                'corporate.companySnapshot.YOUR_COMMENTARY_ON_EARNINGS_NOTE',
              )}
              onChange={(e) => setCommentaryOnEarnings(e.target.value)}
            />
            <label className={`d-flex ali-center ${style.mb16}`}>
              <input
                type="checkbox"
                className="checkbox mr-8 "
                checked={stateSetting.ownershipAnalysis}
                onChange={() => handleChangeSetting('ownershipAnalysis')}
              />
              <Translate value="corporate.companySnapshot.OWNERSHIP_ANALYSIS" />
            </label>
          </div>
        </div>
        <div>
          <div className={style.titleOrange}>
            <Translate value="corporate.companySnapshot.MARKET_DATA" />
          </div>
          <div className="d-flex" style={{ marginLeft: 32 }}>
            <div className={style.divColOne}>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.priceData}
                  onChange={() => handleChangeSetting('priceData')}
                />
                <Translate value="corporate.companySnapshot.PRICE_DATA" />
              </label>
              <div className={`d-flex ali-center ${style.mb16}`}>
                <label className="d-flex ali-center">
                  <input
                    type="checkbox"
                    className="checkbox mr-8 "
                    checked={stateSetting.stockPerformanceVsIndex}
                    onChange={() =>
                      handleChangeSetting('stockPerformanceVsIndex')
                    }
                  />
                  <Translate value="corporate.companySnapshot.STOCK_PERFORMANCE_VS_INDEX" />
                </label>
                <PopupSettingTimeRange
                  typeSetting={'stockPerformanceVsIndex'}
                />
              </div>
            </div>
            <div className={style.divColTwo}>
              <label className={`d-flex ali-center ${style.mb16}`}>
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.pricePerformance}
                  onChange={() => handleChangeSetting('pricePerformance')}
                />
                <Translate value="corporate.companySnapshot.PRICE_PERFORMANCE" />
              </label>
              <div className={`d-flex ali-center ${style.mb16}`}>
                <label className="d-flex ali-center">
                  <input
                    type="checkbox"
                    className="checkbox mr-8 "
                    checked={stateSetting.foreignNetBuyingSelling}
                    onChange={() =>
                      handleChangeSetting('foreignNetBuyingSelling')
                    }
                  />
                  <Translate value="corporate.companySnapshot.FOREIGN_NET_BUYING_SELLING" />
                </label>
                <PopupSettingTimeRange
                  typeSetting={'foreignNetBuyingSelling'}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className={style.titleOrange}>
            <Translate value="corporate.companySnapshot.FINANCIAL_STATEMENTS" />
            <PopupSettingFinancialStatement />
          </div>
          <div className="d-flex" style={{ marginLeft: 32 }}>
            <div className={style.divColOne}>
              <label className="d-flex ali-center">
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.inComeStatement}
                  onChange={() => handleChangeSetting('inComeStatement')}
                />
                <Translate value="corporate.companySnapshot.INCOME_STATEMENT" />
              </label>
            </div>
            <div className={style.divColTwo}>
              <label className="d-flex ali-center">
                <input
                  type="checkbox"
                  className="checkbox mr-8 "
                  checked={stateSetting.balanceSheet}
                  onChange={() => handleChangeSetting('balanceSheet')}
                />
                <Translate value="corporate.companySnapshot.BALANCE_SHEET" />
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Setting
