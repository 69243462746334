import { chain, orderBy } from 'lodash'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, ReferenceLine, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { NoData } from '../../../../common/noData'
import { BOND_TYPES } from '../../constants'
import { selectDataOverviewOfPortfolio } from '../../store/slice'
import ChartTooltip from './ChartTooltip'
import ReferenceCoupon from './ReferenceCoupon'

const InterestChart = ({ data, width, height }) => {
  const { list } = useSelector(selectDataOverviewOfPortfolio)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataChart = useMemo(() => {
    if (!data?.length) return []

    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    if (!newDataIsHaveBuyValue?.length) return []

    const dataByGovernment = newDataIsHaveBuyValue.filter((item) =>
      BOND_TYPES.GOVERNMENT_BOND.includes(item.bondTypeId),
    )

    if (!dataByGovernment.length) {
      const newData = orderBy(
        chain(newDataIsHaveBuyValue)
          .groupBy('organizationId')
          .map((value, key) => ({
            organizationId: key,
            tickers: value,
            buyValue: value.reduce(
              (total, item) => (total += item?.buyValue ?? 0),
              0,
            ),
          }))
          .value(),
        ['buyValue'],
        ['desc'],
      )

      const topData = newData.slice(0, 10).map((item) => {
        const totalCoupon = item.tickers.reduce(
          (total, item) =>
            (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
          0,
        )

        return {
          name:
            locale === 'vi'
              ? item.tickers?.[0]?.organizationShortName
              : item.tickers?.[0]?.en_OrganizationShortName ??
                item.tickers?.[0]?.ticker,
          text:
            locale === 'vi'
              ? item.tickers?.[0]?.organizationShortName
              : item.tickers?.[0]?.en_OrganizationShortName ??
                item.tickers?.[0]?.ticker,
          buyValue: item.buyValue / 1000000,
          coupon: item.buyValue !== 0 ? (totalCoupon / item.buyValue) * 100 : 0,
        }
      })

      if (newData.length <= 10) {
        return topData
      }

      const other = newData
        .slice(10)
        .reduce((array, item) => array.concat(item.tickers), [])

      const totalOtherBuyValue = other.reduce(
        (total, item) => (total += item?.buyValue ?? 0),
        0,
      )

      const totalCoupon = other.reduce(
        (total, item) =>
          (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
        0,
      )

      const otherData = {
        name: I18n.t('common.OTHERS'),
        text: I18n.t('common.OTHERS'),
        buyValue: totalOtherBuyValue / 1000000,
        coupon:
          totalOtherBuyValue !== 0
            ? (totalCoupon / totalOtherBuyValue) * 100
            : 0,
      }

      return [...topData, otherData]
    }

    const newData = orderBy(
      chain(
        newDataIsHaveBuyValue.filter((item) =>
          BOND_TYPES.CORPORATE_BOND.includes(item.bondTypeId),
        ),
      )
        .groupBy('organizationId')
        .map((value, key) => ({
          organizationId: key,
          tickers: value,
          buyValue: value.reduce(
            (total, item) => (total += item?.buyValue ?? 0),
            0,
          ),
        }))
        .value(),
      ['buyValue'],
      ['desc'],
    )

    const totalGovernmentBuyValue = dataByGovernment.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const totalGovernmentCoupon = dataByGovernment.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const governmentData = {
      name: I18n.t('bond.portfolio.common.GOVERNMENT_BOND'),
      text: I18n.t('bond.portfolio.common.GOVERNMENT_BOND_2'),
      buyValue: totalGovernmentBuyValue / 1000000,
      coupon:
        totalGovernmentBuyValue !== 0
          ? (totalGovernmentCoupon / totalGovernmentBuyValue) * 100
          : 0,
    }

    const topData = newData.slice(0, 10).map((item) => {
      const totalCoupon = item.tickers.reduce(
        (total, item) =>
          (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
        0,
      )

      return {
        name:
          item.tickers?.[0]?.organizationShortName ?? item.tickers?.[0]?.ticker,
        buyValue: item.buyValue / 1000000,
        coupon: item.buyValue !== 0 ? (totalCoupon / item.buyValue) * 100 : 0,
      }
    })
    if (newData.length <= 9) {
      return [governmentData, ...topData]
    }

    const other = newData
      .slice(9)
      .reduce((array, item) => array.concat(item.tickers), [])

    const totalOtherBuyValue = other.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    const totalCoupon = other.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const otherData = {
      name: I18n.t('common.OTHERS'),
      text: I18n.t('common.OTHERS'),
      buyValue: totalOtherBuyValue / 1000000,
      coupon:
        totalOtherBuyValue !== 0 ? (totalCoupon / totalOtherBuyValue) * 100 : 0,
    }

    return [governmentData, ...topData, otherData]
  }, [data, locale])

  const allCoupon = useMemo(() => {
    const newDataIsHaveBuyValue = data.filter((item) => !isNaN(item?.buyValue))

    const totalCoupon = newDataIsHaveBuyValue.reduce(
      (total, item) =>
        (total += (item?.couponInterestRate ?? 0) * (item?.buyValue ?? 0)),
      0,
    )

    const totalBuyValue = newDataIsHaveBuyValue.reduce(
      (total, item) => (total += item?.buyValue ?? 0),
      0,
    )

    return totalBuyValue !== 0 ? (totalCoupon / totalBuyValue) * 100 : 0
  }, [data])

  const renderTooltip = (dataChart) => (
    <ChartTooltip data={{ ...dataChart, allCoupon }} />
  )

  const bondSizeLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_LABEL'),
    [locale],
  )

  const couponLabel = useMemo(
    () => I18n.t('bond.portfolio.overviewOfPortfolioSize.COUPON_LABEL'),
    [locale],
  )

  return (
    <>
      {!!formatDataChart.length && !!list?.length ? (
        <>
          <div style={{ height: height - 26 }}>
            <ChartContainer
              data={formatDataChart}
              width={width}
              height={height - 26}
              keyXAxis="name"
              isUseXAxisDiv
              showAllTick
              yAxis={[
                {
                  id: 'left',
                  keys: list.slice(0, 1),
                  orientation: 'left',
                  label: bondSizeLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  isBarChart: true,
                  tickNum: 5,
                  min: 0,
                },
                {
                  id: 'right',
                  keys: list.slice(1),
                  orientation: 'right',
                  isLineChart: true,
                  label: couponLabel,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  tickNum: 5,
                  min: 0,
                },
              ]}
              margin={{ left: 0, right: 16, top: 16, bottom: 16 }}
              renderCustomTooltip={renderTooltip}
            >
              {({ chartContentWidth }) => (
                <>
                  <Bar
                    isAnimationActive={false}
                    yAxisId="left"
                    dataKey={list[0]}
                    fill="#45B29D"
                    barSize={27}
                  />
                  <Scatter
                    yAxisId="right"
                    dataKey={list[1]}
                    isAnimationActive={false}
                    shape={
                      <ScatterDiamondShape dataKey={list[1]} color="#ECECEC" />
                    }
                  />
                  {allCoupon > 0 && (
                    <ReferenceLine
                      yAxisId="right"
                      y={allCoupon}
                      stroke="#FACC5C"
                      strokeWidth={2}
                      strokeDasharray="5 5"
                      label={(ctx) => (
                        <ReferenceCoupon ctx={ctx} value={allCoupon} />
                      )}
                    />
                  )}
                </>
              )}
            </ChartContainer>
          </div>
          <Footer
            key={width}
            list={[
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_2',
                type: TYPE.SQUARE,
                before: {
                  bgColor: '#45B29D',
                },
              },
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.AVG_COUPON_2',
                renderIcon: (
                  <ScatterDiamondShape
                    style={{ marginRight: 5 }}
                    color="#ECECEC"
                  />
                ),
              },
              {
                text: 'bond.portfolio.overviewOfPortfolioSize.ALL_AVG_COUPON_2',
                type: TYPE.DASHED_LINE,
                dashedLine: {
                  bgColor: '#FACC5C',
                },
              },
            ]}
          />
        </>
      ) : (
        <NoData />
      )}
    </>
  )
}

export default InterestChart
