import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { withWrapper } from '../../../common/withWrapper'
import { MatchingOrder } from './matchingOrder'
import { PanelPriceDepth } from './priceDepth/PanelPriceDepth'
import { PanelPricePerformance } from './pricePerformance/PanelPricePerformance'
import { Summary } from './summary'
import { PanelTimeSale } from './timeSale/PanelTimeSale'

const MAP_KEY = {
  SUMMARY: 'SUMMARY',
  PRICE_PERFORMANCE: 'PRICE_PERFORMANCE',
  PRICE_DEPTH: 'PRICE_DEPTH',
  TIME_SALE: 'TIME_SALE',
  MATCHING_ORDERS: 'MATCHING_ORDERS',
}

const MIN_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    minWidth: 270,
    minHeight: 248,
  },
  [`${MAP_KEY.PRICE_PERFORMANCE}`]: {
    minWidth: 510,
    minHeight: 248,
  },
  [`${MAP_KEY.PRICE_DEPTH}`]: {
    minWidth: 370,
    minHeight: 248,
  },
  [`${MAP_KEY.TIME_SALE}`]: {
    minWidth: 575,
    minHeight: 248,
  },
  [`${MAP_KEY.MATCHING_ORDERS}`]: {
    minWidth: 575,
    minHeight: 248,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: `calc(24% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.PRICE_PERFORMANCE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.PRICE_DEPTH,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.TIME_SALE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.MATCHING_ORDERS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.PRICE_PERFORMANCE}`]: {
    width: `calc(44% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(24% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.PRICE_PERFORMANCE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRICE_PERFORMANCE}`].minHeight,
    minTop: 0,
    minLeft: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minWidth + 2 * COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.PRICE_DEPTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.PRICE_DEPTH}`]: {
    width: `calc(32% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(68% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.PRICE_DEPTH}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.PRICE_DEPTH}`].minHeight,
    minTop: 0,
    minLeft:
      MIN_SIZE[`${MAP_KEY.PRICE_PERFORMANCE}`].minWidth +
      MIN_SIZE[`${MAP_KEY.SUMMARY}`].minWidth +
      4 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TIME_SALE}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.TIME_SALE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.TIME_SALE}`].minHeight,
    minTop: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.MATCHING_ORDERS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MATCHING_ORDERS}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.MATCHING_ORDERS}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.MATCHING_ORDERS}`].minHeight,
    minTop: MIN_SIZE[`${MAP_KEY.SUMMARY}`].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: MIN_SIZE[`${MAP_KEY.TIME_SALE}`].minWidth + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <Panel
          title="market.equityTrading.deepTransaction.SUMMARY"
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SUMMARY}
          sizes={sizes}
          setSizes={setSizes}
        >
          <Summary />
        </Panel>

        <PanelPricePerformance
          mapKey={MAP_KEY}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelPriceDepth
          mapKey={MAP_KEY}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelTimeSale
          mapKey={MAP_KEY}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <Panel
          title="market.equityTrading.deepTransaction.MATCHING_ORDER"
          panelRefs={panelRefs}
          panelKey={MAP_KEY.MATCHING_ORDERS}
          sizes={sizes}
          setSizes={setSizes}
          windowZoom={true}
        >
          <PreOpenMarket>
            <MatchingOrder />
          </PreOpenMarket>
        </Panel>
      </>
    )
  }

  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
