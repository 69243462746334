import PropTypes from 'prop-types'

export const Oval = ({ color, dotWidth, ...props }) => {
  const { cx, cy, value } = props

  if (typeof value === 'number' && !isNaN(value))
    return (
      <svg
        width={100}
        height={100}
        x={cx - 10}
        y={cy - 10}
        fill={color}
        viewBox="0 0 1024 1024"
      >
        <circle cx="100" cy="100" r={dotWidth * 10} />
      </svg>
    )
  return null
}

Oval.propTypes = {
  color: PropTypes.string,
  dotWidth: PropTypes.number,
}

Oval.defaultProps = {
  color: '#000000',
  dotWidth: 6.5,
}
