import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import SectorTopInfo from '../../../common/topInfo/sectorTopInfo'
import Content from './Content'
import HeaderViewBy from './HeaderViewBy'

export const Overview = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex">
                <div className="d-flex" style={{ alignItems: 'center' }}>
                  <Title
                    title={
                      'constants.route.sector.sectorConstituents.overview.LABEL'
                    }
                  />
                  <HeaderViewBy />
                  <VerticalLine mr={12} />
                </div>
                <SectorTopInfo showAdditionalInfoPrice={false} />
              </div>
              {size.height && (
                <div
                  className="row"
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
