import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  getCoveredWarrantTimeAndSales,
  getCoveredWarrantTimeAndSalesChart,
} from './thunk'

const initialState = {
  isLoading: true,
  ids: [],
  data: [],
  dataByIds: {},
  scrollId: null,
  pageSize: 100,
  isFirstTime: true,
  dataChart: [],
}

const slice = createSlice({
  name: 'market/coveredWarrant/timeAndSales',
  initialState,
  reducers: {
    changePageSize: (state, action) => {
      state.pageSize = action.payload
    },
    resetState: (state) => {
      state.ids = []
      state.data = []
      state.dataByIds = {}
      state.isLoading = true
      state.scrollId = null
    },
    subscribeTimeAndSales: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const newData = action.payload[0]
        if (newData.matchVolume && newData.matchVolume > 0) {
          const newDataRealTime = {
            tradingDate: newData.tradingDate,
            matchType: Number(newData.matchType),
            matchPrice: Number(newData.matchPrice),
            priceChange: Number(newData.priceChange),
            percentPriceChange: Number(newData.percentPriceChange),
            matchVolume: Number(newData.matchVolume),
            totalMatchVolume: Number(newData.totalMatchVolume),
          }
          const data = [newDataRealTime, ...state.data]
            .map((item, index) => ({
              ...item,
              id: index,
            }))
            .sort(
              (a, b) =>
                new Date(b.tradingDate).getTime() -
                new Date(a.tradingDate).getTime(),
            )
          state.data = data
          state.dataByIds = keyBy(data, 'id')
          state.ids = data.map((v) => v.id)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCoveredWarrantTimeAndSales.pending, (state) => {
      if (state.isFirstTime) {
        state.isLoading = true
      }
    })
    builder.addCase(
      getCoveredWarrantTimeAndSales.fulfilled,
      (state, action) => {
        state.isLoading = false
        const lastLength = state.data.length
        const newData =
          action.payload.data
            ?.map((item, index) => ({
              ...item,
              id: index + lastLength,
            }))
            .sort(
              (a, b) =>
                new Date(b.tradingDate).getTime() -
                new Date(a.tradingDate).getTime(),
            ) || []
        const data = [...state.data, ...newData]
        state.data = data
        state.dataByIds = keyBy(data, 'id')
        state.ids = data.map((v) => v.id)
        state.scrollId = action.payload.scrollId
        if (state.isFirstTime) {
          state.isFirstTime = false
        }
      },
    )
    builder.addCase(getCoveredWarrantTimeAndSales.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getCoveredWarrantTimeAndSalesChart.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getCoveredWarrantTimeAndSalesChart.fulfilled,
      (state, action) => {
        state.dataChart = action.payload.data || []
        state.isLoading = false
      },
    )
    builder.addCase(
      getCoveredWarrantTimeAndSalesChart.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const { changePageSize, subscribeTimeAndSales, resetState } =
  slice.actions
export const selectDataTimeAndSales = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectPageSize = (state) => state[slice.name].pageSize
export const selectScrollId = (state) => state[slice.name].scrollId
export const selectDataChart = (state) => state[slice.name].dataChart

register(slice.name, slice.reducer)
