import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { formatVal } from '../../../../utils/Value'
import {
  LINE_COLOR,
  RADIO_VALUE,
  STACK_COLORS,
  STATE_BUDGET_CHART_TYPE,
} from './constants'
import { getTooltipContentData, renderListFooter } from './helper'
import style from './index.module.css'
import { selectNameById } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
}

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  leftFrameValue,
  ids,
  radioValue,
}) => {
  const nameById = useSelector(selectNameById)

  const stackKeys = ids
  const lineKeysBalanceChart = [ids[0], ids[1]]
  const barKeysBalanceChart = ids[2]

  const isBalanceChart = leftFrameValue === STATE_BUDGET_CHART_TYPE.BALANCE
  const isPercentageUnit = radioValue === RADIO_VALUE.PERCENT

  const keyYAxis = isBalanceChart
    ? [
        {
          id: 'left',
          keys: [barKeysBalanceChart],
          orientation: 'left',
          label: I18n.t('economy.fiscal.stateBudget.BUDGET_DECIFIT'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
          isBarChart: true,
        },
        {
          id: 'right',
          keys: lineKeysBalanceChart,
          isLineChart: true,
          orientation: 'right',
          label: `(${I18n.t('economy.fiscal.stateBudget.BILLION_VND')})`,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          tickNum: SETTINGS.yTickNum,
        },
      ]
    : [
        {
          id: 'left',
          keys: stackKeys,
          orientation: 'left',
          isStackedBar: true,
          label: !isPercentageUnit
            ? `(${I18n.t('economy.fiscal.stateBudget.BILLION_VND')})`
            : '',
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
          unitYAxis: isPercentageUnit ? '%' : '',
          tickValues: isPercentageUnit ? [0, 25, 50, 75, 100] : [],
        },
      ]
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, ids, nameById).map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <Span className={style.tooltipContentKey}>{item.label}:</Span>
              <Span
                style={{ marginLeft: 25 }}
                className={style.toolTipContentValue}
              >
                {`${formatVal(item.value)} ${
                  isPercentageUnit && !isBalanceChart
                    ? '%'
                    : I18n.t('economy.fiscal.stateBudget.BILLION_VND_TOOLTIP')
                }`}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                isUseXAxisDiv
                showAllTick
                yAxis={keyYAxis}
                hasBarChart
                renderCustomTooltip={renderTooltip}
                margin={{ ...MARGIN_RECHARTS, left: isPercentageUnit ? 12 : 0 }}
              >
                {isBalanceChart ? (
                  <>
                    <Bar
                      barSize={getColumnSizeInBarChart(width, data.length)}
                      dataKey={barKeysBalanceChart}
                      fill={'#facc5c'}
                      yAxisId="left"
                      isAnimationActive={false}
                    />
                    {lineKeysBalanceChart.map((item, index) => {
                      return (
                        <>
                          <Line
                            key={index}
                            dataKey={item}
                            stroke={LINE_COLOR[index]}
                            strokeWidth={2}
                            yAxisId="right"
                            isAnimationActive={false}
                            dot={false}
                            activeDot={false}
                          />
                          <Scatter
                            yAxisId={'right'}
                            dataKey={item}
                            fill={LINE_COLOR[index]}
                            radius={getRadiusOfScatter(width, data.length)}
                            isAnimationActive={false}
                          />
                        </>
                      )
                    })}
                  </>
                ) : (
                  <>
                    {stackKeys.map((item, index) => {
                      return (
                        <Bar
                          key={index}
                          barSize={getColumnSizeInBarChart(width, data.length)}
                          stackId="a"
                          dataKey={item}
                          fill={STACK_COLORS[stackKeys.length][index]}
                          yAxisId="left"
                          isAnimationActive={false}
                        />
                      )
                    })}
                  </>
                )}
              </ChartContainer>
            )}
            <FooterContainer
              data={renderListFooter(
                stackKeys,
                isBalanceChart,
                barKeysBalanceChart,
                lineKeysBalanceChart,
                nameById,
              )}
              numItemPerRow={4}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
