import { useSelector } from 'react-redux'
import { HeaderCommon } from '../HeaderCommon'
import { selectListNews, selectListNewsLoading } from '../store/slice'

export const Header = ({ height, detailRoute }) => {
  const isLoading = useSelector(selectListNewsLoading)
  const data = useSelector(selectListNews)

  return (
    <HeaderCommon
      data={data.length > 0 ? data[0] : {}}
      isLoading={isLoading}
      height={height}
      detailRoute={detailRoute}
    />
  )
}
