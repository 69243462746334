import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { Table } from '../../../../../../common/table'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { FirstColumnCell } from '../../../common/FirstColumnCell'
import { timeTypeOptions } from '../../../constants'
import { getTranslateSourceNote } from '../../../helps'
import { OtherColumnCell } from './OtherColumnCell'
import {
  changeTimeType,
  selectData,
  selectDataByIds,
  selectGroupColumns,
  selectIds,
  selectTableLoading,
  selectTimeType,
} from './store/slice'
import { getDataTable } from './store/thunk'

export const DataTable = ({ dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const ids = useSelector(selectIds)
  const groupColumns = useSelector(selectGroupColumns)
  const loading = useSelector(selectTableLoading)
  const data = useSelector(selectData)
  const timeType = useSelector(selectTimeType)

  // Check data
  const isDataEmpty = useMemo(() => {
    return !loading && (!groupColumns.length || !ids.length || !data.length)
  }, [loading, groupColumns, ids, data])

  // Get data
  const getSourceNote = useMemo(() => {
    return getTranslateSourceNote(data, true)
  }, [locale, data])

  // Actions
  const onChangeTimeType = (item) => {
    dispatch(changeTimeType(item.value))
  }

  // Use effect
  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId) return
    dispatch(
      getDataTable({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        TimeFrequency: timeType,
      }),
    )
  }, [timeType, sectorInfo, dataType])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="align-center j-b mb-8">
            <DispatchActionTab
              data={timeTypeOptions}
              itemStyle={{ padding: '0 12px', fontWeight: 600 }}
              activeTab={timeType}
              onChangeTab={onChangeTimeType}
            />
            <Span style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}>
              <Translate value="sector.financialAnalysis.common.UNIT_BILLION_VND" />
            </Span>
          </div>
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              {loading ? (
                <Loading />
              ) : isDataEmpty ? (
                <NoData />
              ) : (
                <Table
                  ids={ids}
                  getDataFromRedux={selectDataByIds}
                  isLoading={loading}
                  schema={groupColumns.map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    const result = {
                      colId,
                      title,
                    }

                    if (index === 0) {
                      return {
                        ...result,
                        isI18n: true,
                        thStyle: {
                          textAlign: 'left',
                          fontSize: 10,
                        },
                        canCustomTd: true,
                        render: (val, rowId, { style, className }) => {
                          return (
                            <FirstColumnCell
                              val={val}
                              rowId={rowId}
                              style={{ ...style, minWidth: 200 }}
                              className={className}
                              selectDataByIds={selectDataByIds}
                            />
                          )
                        },
                      }
                    }

                    return {
                      ...result,
                      isI18n: true,
                      thStyle: {
                        textAlign: 'right',
                        fontSize: 10,
                      },
                      canCustomTd: true,
                      render: (val, rowId, { style, className }) => {
                        return (
                          <OtherColumnCell
                            val={val}
                            rowId={rowId}
                            className={className}
                          />
                        )
                      },
                    }
                  })}
                  stickyFirstColumn={true}
                  hasFooter={false}
                  defaultScrollToRight
                />
              )}
            </div>
          )}
          <div style={{ paddingTop: 8 }}>
            <Span
              style={{
                fontSize: 11,
                fontStyle: 'italic',
                opacity: 0.4,
              }}
            >
              {getSourceNote}
            </Span>
          </div>
        </>
      )}
    </SizeTracker>
  )
}
