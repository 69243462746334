import { Fragment, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainerWithZoom } from '../../../../common/chart/rechart/ChartContainerWithZoom'
import UseI18n from '../../../../common/hooks/useI18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { CHART_TYPE, COLORS } from '../constant'
import style from '../index.module.css'
import SectorName from '../SectorName'
import TickerName from '../TickerName'
import ChartTooltip from './ChartTooltip'
import { calcRelativeData } from './helper'
import { changeHoveredItem, selectHoveredItem } from './store/slice'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 5,
}

export const WIDTH_LABEL = 94
const MARGIN = { ...MARGIN_RECHARTS, top: 28, left: 48 }
export const YAXIS_ID = {
  PERCENT: 'PERCENT',
  POINT: 'POINT',
  PRICE: 'PRICE',
}

const MARGIN_ZOOM_X = { left: 10, right: 0, top: 25, bottom: -8 }
const MARGIN_ZOOM_Y = { left: 30, right: 0, top: 25, bottom: 8 }

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  items,
  chartType,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const pointLabel = UseI18n('sector.sectorConstituents.valuation.POINT')
  const priceLabel = UseI18n('sector.sectorConstituents.valuation.THOUSAND_VND')
  const heightLabel = getFontSize(18)

  const hoveredItem = useSelector(selectHoveredItem)

  const renderTooltip = useCallback(
    (data, decimalLengths) => {
      return (
        <ChartTooltip
          data={data}
          items={items}
          keyXAxis={keyXAxis}
          decimalLengths={decimalLengths}
          chartType={chartType}
        />
      )
    },
    [data, keyXAxis, items, chartType],
  )

  const getYAxis = useMemo(() => {
    const tickerKeys = items
      .filter((item) => !!item.organizationId)
      .map((item) => item.id)
    const sectorKeys = items
      .filter((item) => !!item.icbId)
      .map((item) => item.id)

    const referenceLabelOption = {
      hasReferenceLabel: true,
      referenceLabelWidth: WIDTH_LABEL,
      referenceLabelHeight: heightLabel,
      tickNum: SETTINGS.yTickNum,
      lineKeys: items,
      lineKey: 'id',
      renderReferenceLabel: ({ data, key, item }) => {
        return (
          <CustomLabel value={data[key]} item={item} chartType={chartType} />
        )
      },
    }

    if (chartType === CHART_TYPE.RELATIVE) {
      return [
        {
          id: YAXIS_ID.PERCENT,
          keys: items.map((item) => item.id),
          isLineChart: true,
          orientation: 'right',
          unitYAxis: '%',
          labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
          ...referenceLabelOption,
        },
      ]
    }

    const yAxisAbsolute = []

    if (sectorKeys.length) {
      yAxisAbsolute.unshift({
        id: YAXIS_ID.POINT,
        keys: sectorKeys,
        isLineChart: true,
        orientation: 'right',
        label: pointLabel,
        labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
        ...referenceLabelOption,
      })
    }
    if (tickerKeys.length) {
      yAxisAbsolute.push({
        id: YAXIS_ID.PRICE,
        keys: tickerKeys,
        isLineChart: true,
        orientation: 'right',
        label: priceLabel,
        labelPosition: AXIS_LABEL_POSITION.TOP_RIGHT,
        ...referenceLabelOption,
      })
    }

    return yAxisAbsolute
  }, [heightLabel, data, items, chartType])

  const transformData = useMemo(() => {
    return chartType === CHART_TYPE.RELATIVE ? calcRelativeData : undefined
  }, [chartType])

  return (
    <>
      <ChartContainerWithZoom
        key={chartType}
        data={data}
        transformData={transformData}
        width={width}
        height={height}
        keyXAxis={keyXAxis}
        xTickNum={SETTINGS.xTickNum}
        timeFrame="1M"
        tickFormatter={(value) =>
          formatDateTime(value, FORMAT.DATE, locale, timeZone)
        }
        yAxis={getYAxis}
        margin={MARGIN}
        renderCustomTooltip={renderTooltip}
        zoomRightYBarPosition="left"
        marginZoomX={MARGIN_ZOOM_X}
        marginZoomY={MARGIN_ZOOM_Y}
        listTitleTooltipZoom={[pointLabel, priceLabel]}
        yTickNum={SETTINGS.yTickNum}
      >
        {items.map((item, index) => {
          const yAxisId =
            chartType === CHART_TYPE.RELATIVE
              ? YAXIS_ID.PERCENT
              : item.icbId
              ? YAXIS_ID.POINT
              : YAXIS_ID.PRICE

          return (
            <Fragment key={index}>
              <Line
                yAxisId={yAxisId}
                dataKey={item.id}
                type="linear"
                stroke={COLORS[item.color]}
                strokeOpacity={
                  !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2
                }
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={
                  !hoveredItem ? 1.5 : hoveredItem === item.id ? 2.5 : 1.5
                }
              />
            </Fragment>
          )
        })}
      </ChartContainerWithZoom>
    </>
  )
}

const CustomLabel = ({ value, item, chartType }) => {
  const dispatch = useDispatch()
  const hoveredItem = useSelector(selectHoveredItem)

  const onHover = () => {
    dispatch(changeHoveredItem(item.id))
  }

  const onLeave = () => {
    dispatch(changeHoveredItem(null))
  }

  return (
    <div
      className={style.textNormal}
      style={{
        backgroundColor: item.bgColor,
        color: item.textColor,
        opacity: !hoveredItem ? 1 : hoveredItem === item.id ? 1 : 0.2,
      }}
      onMouseOver={onHover}
      onMouseLeave={onLeave}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="flex-1" style={{ minWidth: 0 }}>
          {item.icbId ? (
            <SectorName icbId={item.icbId} />
          ) : (
            <TickerName organizationId={item.organizationId} />
          )}
        </div>
        <div>{formatVal(value)}</div>
      </div>
    </div>
  )
}

export default ChartComponent
