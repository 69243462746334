import React from 'react'
import SelectCustom from '../../../../common/selectCustom'

const DropdownQuarter = ({ quarterSelect, setQuarter }) => {
  const QUARTER = [
    {
      name: 'Q1',
      value: 1,
    },
    {
      name: 'Q2',
      value: 2,
    },
    {
      name: 'Q3',
      value: 3,
    },
    {
      name: 'Q4',
      value: 4,
    },
  ]
  return (
    <div className="w-80">
      <SelectCustom
        value={quarterSelect}
        selectData={QUARTER}
        handleChange={setQuarter}
      />
    </div>
  )
}

export default DropdownQuarter
