import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { FONT_SIZE_CONFIG } from '../../../configs/Layout'
import UseFontSize from '../hooks/useFontSize'
import styles from './index.module.css'

export const Switch = ({
  title,
  isI18n,
  translateProps,
  checked,
  style,
  className,
  onChange,
  isCheckOnClickText,
  allowChange,
  appendStyle,
}) => {
  const typeFontSize = UseFontSize()

  const [isCheck, setIsCheck] = useState(false)

  useEffect(() => {
    setIsCheck(!!checked)
  }, [checked])

  const onToggle = (e) => {
    e?.stopPropagation()
    if (allowChange) {
      onChange ? onChange(!isCheck) : setIsCheck(!isCheck)
    }
  }

  const onClickText = (e) => {
    e?.stopPropagation()
    if (isCheckOnClickText) {
      onToggle(e)
    }
  }

  return (
    <div className="d-flex align-center" style={appendStyle}>
      <div
        className={styles.switchDiv}
        style={{
          backgroundColor: isCheck ? '#43acff' : '#939393',
          transform: `scale(${FONT_SIZE_CONFIG[typeFontSize].ratio})`,
          opacity: allowChange ? 1 : 0.4,
        }}
        onClick={onToggle}
      >
        <div
          className={styles.switchBtn}
          style={{
            left: isCheck ? 'calc(100% - 12px)' : 1,
            backgroundColor: '#FFFFFF',
          }}
        />
      </div>
      {title && (
        <span
          style={{ paddingLeft: 8, ...style }}
          className={[
            isCheckOnClickText ? styles.cursorPointer : '',
            className,
          ].join(' ')}
          onClick={onClickText}
        >
          {isI18n ? <Translate value={title} {...translateProps} /> : title}
        </span>
      )}
    </div>
  )
}

Switch.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  isI18n: PropTypes.bool,
  translateProps: PropTypes.object,
  checked: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  isCheckOnClickText: PropTypes.bool,
  onChange: PropTypes.func,
  allowChange: PropTypes.bool,
  appendStyle: PropTypes.object,
}

Switch.defaultProps = {
  isI18n: false,
  translateProps: {},
  checked: false,
  style: {},
  className: '',
  isCheckOnClickText: false,
  allowChange: true,
  appendStyle: {},
}
