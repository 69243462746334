import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class NewsReportProxy extends ProxyBase {
  getMarketReport(params) {
    return this.get('MarketReport', params)
  }

  getListNews(params) {
    return this.get('ListNews', params)
  }

  getMarketNews(params) {
    return this.get('MarketNews', params)
  }

  getTrendingNews(params) {
    return this.get('TrendingNews', params)
  }

  getNewsCategories(params) {
    return this.get('NewsCategory', params)
  }

  getReportTypes(params) {
    return this.get('ReportType', params)
  }

  getNewsSources(params) {
    return this.get('NewsSources', params)
  }

  getReportSources(params) {
    return this.get('ReportSources', params)
  }

  getNewsById(params) {
    return this.get('Detail', params)
  }

  getNewsSameCategory(params) {
    return this.get('NewsSameCategory', params)
  }
}

export default new NewsReportProxy(
  ServiceProxyConfig.Market.NewsReport.ServiceUrl,
)
