import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Loading } from '../../../common/loading'
import { ALL_TYPE } from '../../../common/news/constants'
import { getFilterDate } from '../../../common/news/helper'
import { ItemWithImage } from '../../../common/news/ItemWithImage'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { FEATURES } from '../../../constants/routes'
import {
  resetMore,
  selectListNews,
  selectListNewsLoading,
  selectMoreCategories,
  selectMoreFilterData,
  selectMoreSources,
  selectNewsCategoriesLoading,
  selectNewsSourcesLoading,
} from '../store/slice'
import { getListNews } from '../store/thunk'
import UseTimeZone from './../../../common/hooks/useTimeZone'

const NUM_COLUMN = 3

export const Grid = ({ defaultType, detailRoute }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const location = useLocation()
  const timeZone = UseTimeZone()

  const data = useSelector(selectListNews)
  const sources = useSelector(selectMoreSources)
  const categories = useSelector(selectMoreCategories)
  const isSourcesLoading = useSelector(selectNewsSourcesLoading)
  const isCategoriesLoading = useSelector(selectNewsCategoriesLoading)
  const isLoading = useSelector(selectListNewsLoading)
  const filterData = useSelector(selectMoreFilterData)
  const locale = useSelector((state) => state.i18n.locale)

  const params = new URLSearchParams(location.search)
  const type = defaultType || params.get('type')

  const fetchAPI = (page = 1) => {
    if (sources.length > 0 && categories.length > 0) {
      const dates = getFilterDate(
        filterData.time,
        filterData.startDate,
        filterData.endDate,
      )
      if (dates.startDate && !dates.endDate) return

      const params = {
        Page: page,
        PageSize: 30,
        NewsCategoryId:
          filterData.category === ALL_TYPE ? type : filterData.category,
        NewsSourceId: filterData.source === ALL_TYPE ? null : filterData.source,
        StartDate: dates.startDate,
        EndDate: dates.endDate,
        Term: filterData.search,
      }

      dispatch(getListNews(params))
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetMore())
    }
  }, [])

  useEffect(() => {
    fetchAPI()
  }, [locale, sources, categories, filterData, timeZone])

  if (isLoading || isSourcesLoading || isCategoriesLoading) {
    return (
      <div className="w-100 h-100 position-relative">
        <Loading />
      </div>
    )
  }

  if (sources.length === 0 || categories.length === 0 || data.length === 0) {
    return <NoData />
  }

  const onAction = (newsId) => () => {
    navigate(
      (detailRoute || FEATURES.market.components['news-report-detail'].route) +
        '?newsId=' +
        newsId,
    )
  }

  const dataIgnoreFirstItem = data.filter((_, index) => index !== 0)
  const length = dataIgnoreFirstItem.length

  return (
    <ScrollComponent>
      <div className="d-flex w-100" style={{ flexWrap: 'wrap' }}>
        {dataIgnoreFirstItem.map((item, index) => {
          if (index % NUM_COLUMN === 0) {
            return (
              <div
                style={{
                  width: `calc(100%/${NUM_COLUMN} - 20px)`,
                  marginRight: 20,
                }}
                key={index}
              >
                <ItemWithImage
                  item={item}
                  onAction={onAction(item.newsId)}
                  isItemInLastRow={index > length - NUM_COLUMN}
                />
              </div>
            )
          }

          if (index % NUM_COLUMN === NUM_COLUMN - 1) {
            return (
              <div
                style={{
                  width: `calc(100%/${NUM_COLUMN} - 20px)`,
                  marginLeft: 20,
                }}
                key={index}
              >
                <ItemWithImage
                  item={item}
                  onAction={onAction(item.newsId)}
                  isItemInLastRow={index > length - NUM_COLUMN}
                />
              </div>
            )
          }
          return (
            <div
              style={{
                width: `calc(100%/${NUM_COLUMN} - 20px)`,
                marginLeft: 10,
                marginRight: 10,
              }}
              key={index}
            >
              <ItemWithImage
                item={item}
                onAction={onAction(item.newsId)}
                isItemInLastRow={index > length - NUM_COLUMN}
              />
            </div>
          )
        })}
      </div>
    </ScrollComponent>
  )
}

Grid.propTypes = {
  defaultType: PropTypes.number,
  detailRoute: PropTypes.string,
}
