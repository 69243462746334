import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { changeQuarterType, changeRetryFlag, changeYearType } from './slice'

export const getDepositStructureChartData = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/bank/capitalStructure/depositStructure/GET_DEPOSIT_STRUCTURE_CHART_DATA',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await FinancialAnalysisService.getCapitalStructureDepositStructure(data)

      if (response.requestParams) {
        dispatch(changeYearType(response.requestParams.Year))
        dispatch(changeQuarterType(response.requestParams.Quarter))
      }
      dispatch(changeRetryFlag(false))

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
