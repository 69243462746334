import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import { TIME_FREQUENCY } from '../constants'
import style from '../index.module.css'
import {
  selectActiveTimeType,
  selectFilterAverageDurationBySector,
} from '../store/slice'

const ChartTooltip = ({
  data,
  date,
  lineData,
  renderTimeType,
  renderTitleAverage,
  renderTitle,
}) => {
  const timeType = useSelector(selectActiveTimeType)
  const { list, averageList } = useSelector(selectFilterAverageDurationBySector)
  const locale = useSelector((state) => state.i18n.locale)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {date}
      </Span>
      {!!list?.length &&
        list.toReversed().map((item, index) => (
          <div
            key={index}
            className="d-flex ali-center justify-content-space-between"
          >
            <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
              {`${
                timeType === TIME_FREQUENCY.YEARLY
                  ? `${data.industryName} L2 - `
                  : `${data.industryName} L2 `
              }${
                locale === 'vi' && timeType === TIME_FREQUENCY.MONTHLY
                  ? `${I18n.t(
                      'bond.corporateBond.primaryMarket.MONTH',
                    )} ${renderTitle(item, timeType, locale)}`
                  : renderTitle(item, timeType, locale)
              } ${index === 0 ? renderTimeType(timeType, item) : ''}`}
            </Span>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {`${formatVal(data[item] ?? 0, 1)} ${I18n.t(
                'bond.corporateBond.averageDurationBySector.YEAR',
              )}`}
            </Span>
          </div>
        ))}
      {!!averageList?.length &&
        averageList.toReversed().map((item, index) => {
          const lineMatched = lineData.find((line) => line.date === item)

          return (
            <div
              key={index}
              className="d-flex ali-center justify-content-space-between"
            >
              <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
                {`${
                  timeType === TIME_FREQUENCY.YEARLY
                    ? renderTitleAverage() + ' - '
                    : renderTitleAverage()
                } ${
                  locale === 'vi' && timeType === TIME_FREQUENCY.MONTHLY
                    ? `${I18n.t(
                        'bond.corporateBond.primaryMarket.MONTH',
                      )} ${renderTitle(item, timeType, locale)}`
                    : renderTitle(item, timeType, locale)
                } ${index === 0 ? renderTimeType(timeType, item) : ''}`}
              </Span>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {`${
                  lineMatched ? formatVal(lineMatched.industryDuration, 1) : 0
                } ${I18n.t('bond.corporateBond.averageDurationBySector.YEAR')}`}
              </Span>
            </div>
          )
        })}
    </div>
  )
}

export default ChartTooltip
