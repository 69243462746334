import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown, { positionDropdown } from '../../../../../../common/dropdown'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { keys, selectLendingComData, selectLoading } from '../../store/slice'
import { getTooltipLendingCompany } from '../../store/thunk'
import TableLendingCompany from './TableLendingCompany'

const MAX_WIDTH = 878
const MAX_HEIGHT = window.innerHeight / 2

const TooltipInfo = ({ rowIdCol, parentRef }) => {
  const dispatch = useDispatch()
  const { data } = useSelector(selectLendingComData)
  const isLoading = useSelector(selectLoading(keys.LENDING_COM))

  useEffect(() => {
    if (rowIdCol) {
      dispatch(getTooltipLendingCompany({ OrganizationId: rowIdCol }))
    }
  }, [rowIdCol])

  const { left, right, top, bottom } =
    parentRef?.current?.getBoundingClientRect()

  const position = useMemo(() => {
    if (right + MAX_WIDTH > window.innerWidth) {
      return window.innerHeight - bottom > MAX_HEIGHT &&
        window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.LEFT_CENTER
        : window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.LEFT_TOP
        : positionDropdown.LEFT_BOTTOM
    } else {
      return window.innerHeight - bottom > MAX_HEIGHT &&
        window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.RIGHT_CENTER
        : window.innerHeight - top > MAX_HEIGHT
        ? positionDropdown.RIGHT_TOP
        : positionDropdown.RIGHT_BOTTOM
    }
  }, [left, right, top, bottom])

  return (
    <Dropdown
      position={position}
      appendStyle={{
        background: '#33373f',
        borderRadius: 4,
        width: MAX_WIDTH,
        height: MAX_HEIGHT,
        zIndex: 99,
        boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.7)',
        overflow: 'hidden',
      }}
      isShow={true}
      parentRef={parentRef}
    >
      {isLoading ? (
        <Loading />
      ) : !!data?.length ? (
        <TableLendingCompany />
      ) : (
        <NoData />
      )}
    </Dropdown>
  )
}

export default TooltipInfo
