const formatFieldName = (fieldName) => {
  const isRtd = fieldName.indexOf('RTD') !== -1
  return fieldName
    .split('')
    .map((char, index) => {
      if (index === 0 || (index === 1 && isRtd)) {
        return char.toLowerCase()
      } else {
        return char
      }
    })
    .join('')
}

const formatIndicatorItem = (indicators, indicator, parent = { level: 0 }) => {
  const key = formatFieldName(indicator.fieldName)
  const isFormatValToPercent = !!indicator.multiplierCommonSize
  const childrenId = indicators
    .filter((v) => v.parentField === indicator.fieldName)
    .map((v) => v.indicatorMappingId)
  const hasChild = childrenId.length !== 0
  const childTable = {
    index: indicator.indicatorMappingId,
    parentIndex: parent.index,
    parentId: parent.index,
    childrenId: childrenId,
    name: indicator.indicator,
    key: key,
    isFormatValue: {
      formatValue: 1 / indicator.multiplier,
      formatCommonValue: indicator.multiplierCommonSize * 0.01,
    },
    level: parent.level + 1,
    isFormatValToPercent: isFormatValToPercent,
    isCollapse: hasChild,
  }
  const childItem = hasChild
    ? getChild(indicators, childTable, indicator.fieldName)
    : []
  return [childTable, ...childItem]
}

const getChild = (indicators, parent, parentField) => {
  let childTableArr = []
  indicators.forEach((indicator) => {
    if (indicator.parentField === parentField) {
      const child = formatIndicatorItem(indicators, indicator, parent)
      childTableArr = [...childTableArr, ...child]
    }
  })
  return childTableArr
}

export const formatIndicatorMapping = (indicators) => {
  let ids = []
  let tableConstants = []
  indicators
    .filter((indicator) => !indicator.parentField)
    .forEach((indicator) => {
      const child = formatIndicatorItem(indicators, indicator)
      ids = [...ids, ...child.map((v) => v.index)]
      tableConstants = [...tableConstants, ...child]
    })
  return { ids, tableConstants }
}

export const handleResponseTableTree = (state, action) => {
  const data = action.payload
  const responseOrgIds = data.map((ticker) => ticker.organizationId)
  const valueArr = Object.values(state.valueById)
  data.forEach((item) => {
    valueArr.forEach((element) => {
      element[item.organizationId] = item[element.key]
    })
  })
  state.responseOrgIds = responseOrgIds
  state.loading = false
  state.reCalcWidths = !state.reCalcWidths
}
