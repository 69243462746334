import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/stockTopInfo'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { debounce } from '../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import Content from './Content'
import {
  ALL_VALUE,
  resetStore,
  selectCurrentPage,
  selectFilterCategoryId,
  selectFilterEndDate,
  selectFilterStartDate,
  selectFilterSubCategoryId,
} from './store/slice'
import { getFilingCategoriesThunk, getFilingsThunk } from './store/thunk'

export const Filing = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <>
      <ScrollGroupComponent>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <TopInfo title="constants.route.corporate.newsEvents.filing.LABEL" />
                {size.height && (
                  <div
                    style={{
                      height: `calc(100% - ${size.height}px)`,
                      position: 'relative',
                    }}
                  >
                    <Content />
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </ScrollGroupComponent>
      <ListenGetFilings />
    </>
  )
}

const ListenGetFilings = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const filterCategoryId = useSelector(selectFilterCategoryId)
  const filterSubCategoryId = useSelector(selectFilterSubCategoryId)
  const filterStartDate = useSelector(selectFilterStartDate)
  const filterEndDate = useSelector(selectFilterEndDate)
  const currentPage = useSelector(selectCurrentPage)
  const timeZone = UseTimeZone()

  useEffect(() => {
    dispatch(
      getFilingCategoriesThunk({
        language: locale,
      }),
    )
  }, [locale])

  const handleGetFilings = useCallback(
    debounce((params) => {
      dispatch(getFilingsThunk(params))
    }, 300),
    [],
  )

  useEffect(() => {
    if (filterStartDate && filterEndDate && basicInfo.organizationId) {
      handleGetFilings({
        OrganizationId: basicInfo.organizationId,
        ReportTypeId:
          filterSubCategoryId === ALL_VALUE
            ? filterCategoryId
            : filterSubCategoryId,
        StartDate: getISOStartOrEndOfDay(filterStartDate, timeZone, true),
        EndDate: getISOStartOrEndOfDay(filterEndDate, timeZone, false),
        Page: currentPage,
        PageSize: 28,
        language: locale,
      })
    }
  }, [
    basicInfo,
    filterCategoryId,
    filterSubCategoryId,
    filterStartDate,
    filterEndDate,
    currentPage,
    locale,
    timeZone,
  ])

  return null
}
