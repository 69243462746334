import { createSlice, current } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { ORDER_CONFIG } from '../../configs'
import {
  collapseHelper,
  handleLayoutChildrenTableData,
  handleNonNiiData,
  handleTOIRowDataByOrder,
  initialCollapseTable,
  initialCollapseToiTable,
} from '../../helper'
import { getIndicatorMapping } from './thunk'

const { ORDER_HEADER_CONFIG, ORDER_FOOTER_CONFIG } = ORDER_CONFIG

const initialState = {
  dataMapping: [],
  toiTableData: [],
  loading: true,
  mountChildrenTable: '',
  nonNiiData: [],
  headerChildrenTable: [],
  footerChildrenTable: [],
  collapseTableData: {
    left1TableData: [],
    left2TableData: [],
    left3TableData: [],
    right1TableData: [],
    right2TableData: [],
    right3TableData: [],
  },
  mappingToiTableData: [],
  stackChartKey: [],
  currentStackKey: '',
}

const slice = createSlice({
  name: 'corporate/businessModel/segmentalAnalysis/panelTable',
  initialState,
  reducers: {
    toggleChildrenTable: (state, action) => {
      state.mountChildrenTable = action.payload
    },
    handleCollapse: (state, action) => {
      const { colId, group } = action.payload
      state.collapseTableData = {
        ...state.collapseTableData,
        [group]: collapseHelper(colId, current(state.collapseTableData[group])),
      }
    },
    handleToiTableData: (state, action) => {
      state.mappingToiTableData = action.payload
    },
    handleStackChartKey: (state, action) => {
      state.stackChartKey = action.payload
    },
    handleCurrentStackKey: (state, action) => {
      state.currentStackKey = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndicatorMapping.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getIndicatorMapping.fulfilled, (state, action) => {
      const data = action.payload || []
      state.loading = false
      state.dataMapping = data
      state.toiTableData = handleTOIRowDataByOrder(data)
      state.mountChildrenTable = initialCollapseToiTable(data)
      state.nonNiiData = handleNonNiiData(data)
      state.headerChildrenTable = handleLayoutChildrenTableData(
        data,
        ORDER_HEADER_CONFIG,
      )
      state.footerChildrenTable = handleLayoutChildrenTableData(
        data,
        ORDER_FOOTER_CONFIG,
      )
      state.collapseTableData = initialCollapseTable(data)
    })
    builder.addCase(getIndicatorMapping.rejected, (state, action) => {
      state.loading = action.payload.isLoading
    })
  },
})

export const {
  handleChooseCol,
  toggleChildrenTable,
  handleCollapse,
  handleToiTableData,
  handleStackChartKey,
  handleCurrentStackKey,
} = slice.actions
export const selectLoading = (state) => state[slice.name].loading
export const selectDataMapping = (state) => state[slice.name].dataMapping
export const selectToiTableData = (state) => state[slice.name].toiTableData
export const selectMountChildrenTable = (state) =>
  state[slice.name].mountChildrenTable
export const selectNonNiiData = (state) => state[slice.name].nonNiiData
export const selectHeaderChildrenTable = (state) =>
  state[slice.name].headerChildrenTable
export const selectFooterChildrenTable = (state) =>
  state[slice.name].footerChildrenTable
export const selectCollapseTableData = (state) =>
  state[slice.name].collapseTableData
export const selectMappingToiTable = (state) =>
  state[slice.name].mappingToiTableData
export const selectStackChartKey = (state) => state[slice.name].stackChartKey
export const selectCurrentStackKey = (state) =>
  state[slice.name].currentStackKey

register(slice.name, slice.reducer)
