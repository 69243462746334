import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class IndicesProxy extends ProxyBase {
  getCommodities(params) {
    return this.get('GetCommoditites', params)
  }

  downloadCommodities(params) {
    return this.get('DownloadCommodities', params, 'download')
  }

  getWorldIndex(params) {
    return this.get('GetWorldIndices', params)
  }

  downloadWorldIndex(params) {
    return this.get('DownloadWorldIndices', params, 'download')
  }

  downloadIndex(params) {
    return this.get('DownloadLatestIndices', params, 'download')
  }

  downloadSector(params) {
    return this.get('DownloadLatestSectors', params, 'download')
  }

  getIndexSeries(params) {
    return this.get('GetIndexSeries', params, '', true)
  }

  getIndexLiquiditySeries(params) {
    return this.get('GetIndexLiquiditySeries', params)
  }

  getBuSd(params) {
    return this.get('GetBuSdChart', params)
  }

  getContribution(params) {
    return this.get('GetContribution', params)
  }

  getIndexIntraday(params) {
    return this.get('GetIndexIntraday', params)
  }

  getIndexEOD(params) {
    return this.get('GetIndexEOD', params)
  }

  downloadIndexIntraday(params) {
    return this.get('DownloadIndexIntraday', params, 'download')
  }

  downloadIndexEOD(params) {
    return this.get('DownloadIndexEoD', params, 'download')
  }
}

export default new IndicesProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
