import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../common/panel'
import KeyIndicator from './keyIndicator/KeyIndicator'
import { downloadKeyIndicators } from './keyIndicator/store/thunk'
import { selectBasicInfoOverview } from './store/slice'

export const PanelKeyIndicator = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfoOverview)

  return (
    <Panel
      title={'corporate.overview.keyIndicators.TITLE_PANEL'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadKeyIndicators({
          OrganizationId: basicInfo.organizationId,
        })
      }
    >
      <KeyIndicator />
    </Panel>
  )
}

PanelKeyIndicator.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
