import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../common/hooks/useBasicInfo'
import UseI18n from '../../common/hooks/useI18n'
import { FEATURES } from '../../constants/routes'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchSecNew } from '../store/slice'
import { getSearchSecNewsData } from '../store/thunk'
import { NewReportItem } from './NewReportItem'

export const SecNewReport = (props) => {
  const { searchStr } = props
  const dispatch = useDispatch()
  const secNews = useSelector(selectSearchSecNew)
  const [open, setOpen] = useState(true)

  const onClickCollapse = (value) => {
    setOpen(value)
  }

  const onClickPrevPage = () => {
    if (secNews.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchSecNewsData({
          Term: searchStr,
          Page: secNews.data.page - 1,
          PageSize: secNews.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (secNews.data.page === secNews.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchSecNewsData({
          Term: searchStr,
          Page: secNews.data.page + 1,
          PageSize: secNews.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="sec-new"
      title={`${UseI18n('search.fullSearch.SEC_NEWS_REPORT')} (${
        secNews.data.totalRecords
      })`}
      blockData={secNews}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!secNews.data.items.length &&
        secNews.data.items.map((item) => (
          <NewReportItem
            key={item.newsId}
            searchStr={searchStr}
            data={item}
            idBasicInfo={item.icbId || item.icbName}
            typeBasicInfo={TYPE_STOCK_INDICES_SECTOR.SECTOR}
            url={
              FEATURES.sector.components['sector-constituents'].components[
                'news-report-detail'
              ].route
            }
          />
        ))}
    </SearchCollapseBlock>
  )
}

SecNewReport.propTypes = {
  searchStr: PropTypes.string.isRequired,
}
