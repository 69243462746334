import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import { PopupHeaderCommon } from '../../../../../common/popup/PopupHeaderCommon'
import TextEllipsis from '../../../../../common/textEllipsis'
import { setIsShowPopupBond } from '../../../../../myWatchlistBond/store/slice'
import { selectBasicInfo } from '../Filter/store/slice'
import style from './index.module.css'
import {
  selectDataFl,
  selectListCheckBox,
  updateListCheckBox,
  updatePopupAlert,
} from './store/slice'
import { updateMultiWatchlistDetails } from './store/thunk'

const PopupHistory = ({ onChangPopup }) => {
  const dispatch = useDispatch()
  const data = useSelector(selectDataFl)
  const listOption = useMemo(() => [...data], [data])
  const listCheck = useSelector(selectListCheckBox)
  const bondInfo = useSelector(selectBasicInfo)

  const changeCheckAll = () => {
    const dataCheck = data.map((e) => e.bondWatchListId)

    if (dataCheck.length === listCheck.length) {
      dispatch(updateListCheckBox([]))
    } else {
      dispatch(updateListCheckBox(dataCheck))
    }
  }

  const handleClickItem = (id) => {
    if (!listCheck.includes(id)) {
      dispatch(updateListCheckBox([...listCheck, id]))
      return
    }
    const dataUpdate = listCheck.filter((item) => item !== id)
    dispatch(updateListCheckBox(dataUpdate))
  }

  const updateWWatchListapi = () => {
    if (isEmpty(listCheck)) {
      return dispatch(
        updatePopupAlert({
          show: true,
          message: I18n.t('bond.bondSecurity.common.SC14_MS002'),
        }),
      )
    }
    if (listCheck.length === 1) {
      dispatch(
        updatePopupAlert({
          show: true,
          message: I18n.t('bond.bondSecurity.common.SC14_MS003'),
        }),
      )
    }
    dispatch(
      updateMultiWatchlistDetails({
        data: {
          bondWatchlistIds: listCheck,
          bondId: bondInfo?.bondId,
          isUserBond: 0,
        },
        cb: () => onChangPopup(false),
      }),
    )
  }

  const addNewWatchList = () => {
    onChangPopup(false)
    dispatch(setIsShowPopupBond(true))
  }

  return (
    <PopupHeaderCommon
      setIsShow={onChangPopup}
      title="bond.bondSecurity.generalSetting.popupFl.title"
      isHidePadding={true}
    >
      <div className={style.contentPopupFl}>
        <div style={{ padding: '4px 8px' }}>
          <ScrollComponent autoHeight={true} autoHeightMax={263}>
            <ul className="list-check">
              <li key={`key-all`}>
                <a
                  style={{
                    cursor: 'pointer',
                  }}
                >
                  <label className="d-flex ali-center">
                    <input
                      type="checkbox"
                      className="checkbox mr-8"
                      checked={listCheck.length === data.length ? true : false}
                      onChange={() => changeCheckAll()}
                    />
                    <TextEllipsis
                      text={I18n.t(
                        'bond.bondSecurity.generalSetting.popupFl.optionAll',
                      )}
                      zIndexTooltip={999}
                      isI18n={false}
                    />
                  </label>
                </a>
              </li>
              {listOption.map((item, index) => {
                return (
                  <li key={`key-${index}`}>
                    <a
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <label className="d-flex ali-center">
                        <input
                          type="checkbox"
                          className="checkbox mr-8"
                          checked={
                            listCheck?.includes(item?.bondWatchListId) ?? false
                          }
                          onChange={() =>
                            handleClickItem(item?.bondWatchListId)
                          }
                        />
                        <TextEllipsis
                          text={`${item?.bondWatchListName ?? ''}`}
                          zIndexTooltip={999}
                          isI18n={false}
                        />
                      </label>
                    </a>
                  </li>
                )
              })}
            </ul>
          </ScrollComponent>
        </div>
        <div className={style.groupBtnPopupFl}>
          <Button
            className={`btn h-20`}
            style={{ width: '81px', background: '#50545F' }}
            onClick={() => onChangPopup(false)}
          >
            <Span style={{ fixedFontSize: '12px', fontWeight: '600' }}>
              {I18n.t('bond.bondSecurity.generalSetting.popupFl.btnCancel')}
            </Span>
          </Button>
          <Button
            className={`btn h-20`}
            style={{ width: '81px', background: '#E8B714' }}
            onClick={() => updateWWatchListapi()}
          >
            <Span style={{ fixedFontSize: '12px', fontWeight: '600' }}>
              {I18n.t('bond.bondSecurity.generalSetting.popupFl.btnAdd')}
            </Span>
          </Button>
          <Button
            className={`btn btn-blue h-20`}
            style={{ width: '81px' }}
            onClick={() => addNewWatchList()}
          >
            <Span style={{ fixedFontSize: '12px', fontWeight: '600' }}>
              {I18n.t('bond.bondSecurity.generalSetting.popupFl.btnCrete')}
            </Span>
          </Button>
        </div>
      </div>
    </PopupHeaderCommon>
  )
}

export default PopupHistory
