import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import { getTooltipContentData, handleTime, renderFooter } from './helper'
import style from './index.module.css'

const YAXIS_LEFT = 'left'
const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
}

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKey,
  lineKey,
  keyWithTitle,
  locale,
}) => {
  const timeZone = UseTimeZone()
  const dataFormatDate = handleTime(data, timeZone, locale)

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: [...barKey, lineKey],
      orientation: YAXIS_LEFT,
      isLineChart: true,
      label: I18n.t('economy.monetary.monetary.BILLION_VND'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
    },
  ]

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, keyWithTitle).map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <Span
                style={{ fontSize: 11 }}
                className={style.tooltipContentKey}
              >
                {item.label}:
              </Span>
              <Span
                style={{ marginLeft: 25, fontSize: 11 }}
                className={`${style.toolTipContentValue} ${
                  typeof item.value === 'number' &&
                  item.value < 0 &&
                  style.redColor
                }`}
              >
                {formatVal(item.value)}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <SizeTracker className={style.heightAuto}>
      {(sizes) => {
        return (
          <>
            {typeof sizes.height === 'number' && (
              <ChartContainer
                data={dataFormatDate}
                height={height - sizes.height}
                width={width}
                keyXAxis={keyXAxis}
                yAxis={keyYAxis}
                renderCustomTooltip={renderTooltip}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis
                margin={{
                  ...MARGIN_RECHARTS,
                  right: 36,
                }}
              >
                {({ chartContentWidth }) => {
                  return (
                    <>
                      {barKey.map((barKey) => {
                        return (
                          <Bar
                            key={barKey}
                            stackId={'stack'}
                            isAnimationActive={false}
                            yAxisId={YAXIS_LEFT}
                            dataKey={barKey}
                            barSize={getColumnSizeInBarChart(
                              chartContentWidth,
                              barKey.length,
                            )}
                            fill={barKey === '11' ? '#185999' : '#e5604e'}
                          />
                        )
                      })}

                      <Line
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        dataKey={lineKey}
                        stroke={'#f7b80c'}
                        strokeWidth={SETTINGS.strokeWidth}
                        dot={false}
                        activeDot={false}
                      />
                    </>
                  )
                }}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={renderFooter(barKey, lineKey, keyWithTitle)}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
