export const production = {
  PRODUCTION: 'Sản xuất',
  CHART_FIRST: {
    generalIndex: 'Tăng trưởng các chỉ số sản xuất chung',
    IIP: 'Tăng trưởng các chỉ số sản xuất công nghiệp',
    IIC: 'Tăng trưởng các chỉ số tiêu thụ ngành CN chế biến, chế tạo',
    III: 'Tăng trưởng các chỉ số tồn kho ngành CN chế biến, chế tạo',
    PRODUCT: 'Tăng trưởng sản lượng sản phẩm công nghiệp',
  },
  CHART_SECOND: {
    generalIndex: 'Chỉ số quản trị mua hàng PMI',
    IIP: 'Top 10 ngành tăng/giảm mạnh nhất trong kỳ',
    IIC: 'Top 10 ngành tăng/giảm mạnh nhất trong kỳ',
    III: 'Top tăng trưởng trong kỳ',
    PRODUCT: 'Sản lượng một số sản phẩm công nghiệp quan trọng',
  },
  generalIndex: 'Chỉ số chung',
  IIP: 'IIP',
  IIC: 'IIC',
  III: 'III',
  PRODUCT_INDUSTRY: 'Sản phẩm công nghiệp',

  SAME_PERIOD: 'Kỳ trước',
  QOQ_GROWTH: 'Kỳ trước',
  PREVIOUS_PERIOD: 'Cùng kỳ',
  ORIGINAL_PERIOD: 'Kỳ gốc',

  timeRange: {
    YEARLY: 'Hàng năm',
    MONTHLY: 'Hàng tháng ',
    ACC_MONTHLY: 'Lũy kế tháng',
    QUARTERLY: 'Hàng quý',
  },

  COMPARE_TO: 'So với:',
  ALL_TIME: 'Tất cả',

  TIME_DATA_TYPE: {
    MONTH_1: 'Th1',
    MONTH_2: '2Th',
    MONTH_3: '3Th',
    MONTH_4: '4Th',
    MONTH_5: '5Th',
    MONTH_6: '6Th',
    MONTH_7: '7Th',
    MONTH_8: '8Th',
    MONTH_9: '9Th',
    MONTH_10: '10Th',
    MONTH_11: '11Th',
    MONTH_12: '12Th',
  },
  MONTHLY_OPTION: {
    MONTH_1: 'Th01',
    MONTH_2: 'Th02',
    MONTH_3: 'Th03',
    MONTH_4: 'Th04',
    MONTH_5: 'Th05',
    MONTH_6: 'Th06',
    MONTH_7: 'Th07',
    MONTH_8: 'Th08',
    MONTH_9: 'Th09',
    MONTH_10: 'Th10',
    MONTH_11: 'Th11',
    MONTH_12: 'Th12',
  },
  VALUE: 'Giá trị',
  PERIOD: 'Kỳ',
  PRODUCTION_INDEX: 'Chỉ số chung',
  IIP_TITLE: 'Chỉ số sản xuất công nghiệp (IIP)',
  IIC_TITLE: 'Chỉ số tiêu thụ toàn ngành CN chế biến, chế tạo (IIC)',
  III_TITLE: 'Chỉ số tồn kho toàn ngành CN chế biến, chế tạo (III)',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  ACC_QUARTERLY: 'Lũy kế quý',
  POINT: '(Điểm)',
  POINT_TOOLTIP: ' điểm',
  AVERAGE: 'Bình quân',
  MOM: 'So với cùng kỳ (%MoM)',
}

export const consumption = {
  CONSUMPTION: 'Tiêu thụ',
  GROWTH_BY_INDEX: 'Tăng trưởng các chỉ tiêu tiêu thụ hàng hóa',
  GROWTH_BY_YEAR: 'Tăng trưởng tổng mức bán lẻ hàng hóa qua các năm',
  RETAIL_SERVICE: 'Dịch chuyển cơ cấu bán lẻ hàng hoá và dịch vụ tiêu dùng',
  TOTAL_RETAIL_SALE: 'Tổng mức bán lẻ hàng hóa',
  MERCHANDISE_RETAIL: 'Bán lẻ hàng hóa',
  ACCOMMODATION_SERVICE: 'Dịch vụ lưu trú, ăn uống',
  TRAVELING_SERVICE: 'Dịch vụ lữ hành',
  OTHER_SERVICE: 'Dịch vụ khác',
  CONSUMER_TRUST_INDEX: 'Chỉ số niếm tin người tiêu dùng',
  GROWTH_YOY: 'Tăng trưởng YoY',
  TABLE_NOTE: 'Đơn vị: Tỷ VND',
  MONTHLY: 'Hàng tháng',
  YEARLY: ' Hàng năm',
  ACCUMULATED_MONTH: 'Lũy kế tháng',
  CHART_PERCENT: '(%)',
  VALUE: 'Giá trị',
  BILLION_VND: 'Tỷ',
  CHART_BILLION: '(Tỷ VND)',
  OVER_THE_YEAR: 'qua các năm',
  ALL: 'Tất cả',
  ACC_VALUE: 'Giá trị lũy kế',
}
