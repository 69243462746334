export const VIEW_BY = {
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
}

export const listTimeFilter = [
  {
    label: 'sector.sectorConstituents.overview.YEARLY',
    value: VIEW_BY.YEARLY,
  },
  {
    label: 'sector.sectorConstituents.overview.QUARTERLY',
    value: VIEW_BY.QUARTERLY,
  },
]
