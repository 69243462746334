export const fullSearch = {
  RESULT_FOR: 'kết quả cho',
  SECURITIES_FUNCTIONS: 'Chứng khoán & Tính năng',
  NEWS: 'Tin tức',
  PAGE: 'Trang',
  COR_OVERVIEW: 'COR - Tổng quát',
  FUNC_FUNCTIONS: 'FUNC - Tính năng',
  MAR_DERIVATIVES: 'MAR - Phái sinh',
  MAR_INDICES: 'MAR - Chỉ số',
  // BOND_OVERVIEW: 'BOND - Tổng quát',
  BO_BOND: 'BO - Trái phiếu',
  SEC_OVERVIEW: 'SEC - Tổng quát',
  COR_NEWS_EVENTS: 'COR - Tin tức & Sự kiện',
  SEC_NEWS_REPORT: 'SEC - Tin tức & Báo cáo',
  MAR_NEWS_REPORT: 'MAR - Tin tức & Báo cáo',
  SOURCE: 'Nguồn',
  TRADING_DATA: 'Thông tin giao dịch',
  OWNERSHIP: 'Cơ cấu sở hữu',
  SUBSIDIARIES_ASSOCIATES: 'Công ty con & Công ty liên kết',
  SEGMENTAL_ANALYSIS: 'Phân tích kinh doanh',
  PENETRATION_ANALYSIS: 'Phân tích thị phần',
  COST_OF_OPERATION: 'Chi phí hoạt động',
  FINANCIAL_RATIOS: 'Chỉ số tài chính',
  DEBT_CREDIT_RISK: 'Nợ & Rủi ro tín dụng',
  EARNING_QUALITY: 'Đánh giá lợi nhuận',
  FINANCIAL_STATEMENT: 'Báo cáo tài chính',
  DIVIDEND: 'Cổ tức',
  PEERS_VALUATION: 'So sánh & Định giá',
  DEEP_TRANSACTION: 'Cổ phiếu trực tuyến',
  PRICE_STATISTICS: 'Thống kê giá',
  TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  FINANCIAL_ANALYSIS: 'Phân tích tài chính',
  VALUE_CHAIN: 'Chuỗi giá trị',
  SEGMENTATION: 'Biểu đồ phân tán ngành',
  RANKING: 'Xếp hạng',
  RATIO: 'Chỉ số ngành',
  VALUATION: 'Định giá',
  FOREIGN: 'Giao dịch khối ngoại',
  DIRECTOR_DEAL: 'Giao dịch nội bộ',
  INDEX_STATISTICS: 'Thống kê thị trường',
  SECTOR_STATISTICS: 'Ngành trực tuyến',
  LOCAL_INSTITUTION: 'Giao dịch tổ chức trong nước',
}
