import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { SizeTracker } from '../../../../../common/sizeTracker'
import EventEmitter, {
  FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE,
} from '../../../../../utils/EventEmitter'
import {
  changeDashboard,
  changeDashboardSelected,
  selectDashboard,
  selectIsLoadingCreateDashboard,
} from '../../../../store/slice'
import {
  postDashboard,
  updateAndReloadDashboard,
} from '../../../../store/thunk'
import style from './Item.module.css'
import { ItemChildren } from './ItemChildren'
import { ItemSetting } from './ItemSetting'
import { ItemTitle } from './ItemTitle'

export const Item = ({
  data,
  isParentGroup,
  isNewDashboard,
  onClickItem,
  onAddDashboard,
  onAddNewDashboardInGroup,
}) => {
  const dispatch = useDispatch()
  const activeItem = useSelector(selectDashboard)
  const isLoadingCreateDashboard = useSelector(selectIsLoadingCreateDashboard)

  const isCollapseOpen = !!(
    data.children &&
    data.children.length &&
    data.children.some((item) => item.id === activeItem?.data?.id)
  )

  const [collapseOpen, setCollapseOpen] = useState(isCollapseOpen)
  const [isHover, setIsHover] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [isActive, setIsActive] = useState(false)
  const [isNewDashboardInGroup, setIsNewDashboardInGroup] = useState(false)

  const isHideCollapseIcon = () => {
    return !isParentGroup || (!data.isGroup && !data.isFixed && !data.children)
  }

  const onClick = () => {
    if (!isNewDashboard) {
      if (isHideCollapseIcon()) {
        if (activeItem?.data?.id !== data.id) {
          setIsActive(true)
          dispatch(changeDashboardSelected(data.id))
          onClickItem(data)
        }
      } else {
        setIsActive(false)
        setCollapseOpen(!collapseOpen)
      }
    }
  }

  const onMouseEnter = () => {
    setIsHover(true)
  }

  const onMouseLeave = () => {
    setIsHover(false)
  }

  const handleClickOutside = () => {
    setIsActive(false)
    setIsHover(false)
    if (isEdit) {
      EventEmitter.dispatch(FC_HANDLE_CLICK_OUTSIDE_DASHBOARD_TITLE)
    }
  }

  const handleGroupAddNewDashboard = () => {
    setCollapseOpen(true)
    setIsNewDashboardInGroup(true)
  }

  const handleAddNewDashboardInGroup = () => {
    setIsNewDashboardInGroup(false)
  }

  useEffect(() => {
    if (isNewDashboard) {
      setIsActive(true)
      setIsEdit(true)
    } else {
      setIsActive(false)
      setIsEdit(false)
    }
  }, [isNewDashboard])

  const isActiveBg = () => {
    if (activeItem?.data?.id === data.id) {
      return true
    }

    return isHover || isActive
  }

  const onSubmitUpdateTitle = (data) => {
    if (!isLoadingCreateDashboard) {
      if (isNewDashboard) {
        dispatch(postDashboard(data))
        onAddDashboard()
        onAddNewDashboardInGroup()
      } else {
        if (!data.isGroup) {
          dispatch(changeDashboard({ data }))
        }
        dispatch(updateAndReloadDashboard(data))
      }
    }
  }

  return (
    <>
      <HandleClickOutside handleClickOutside={handleClickOutside}>
        <div
          className={style.container}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          style={{
            backgroundColor: isActiveBg() ? '#e2edff' : '',
            paddingLeft: !isHideCollapseIcon() || !data.parentId ? 34 : 50,
          }}
          id={data.id}
        >
          <SizeTracker className="d-flex">
            {(size) => {
              return (
                <>
                  {(size.width || isNewDashboard) && (
                    <div
                      className="d-flex"
                      style={{
                        width: `calc(100% - ${isEdit ? 0 : size.width}px)`,
                      }}
                    >
                      <ItemTitle
                        data={data}
                        isHideCollapseIcon={isHideCollapseIcon()}
                        isEdit={isEdit}
                        isNewDashboard={isNewDashboard}
                        collapseOpen={collapseOpen}
                        setIsEdit={setIsEdit}
                        setIsActive={setIsActive}
                        setIsHover={setIsHover}
                        onSubmitUpdateTitle={onSubmitUpdateTitle}
                      />
                    </div>
                  )}
                  <ItemSetting
                    data={data}
                    isNewDashboard={isNewDashboard}
                    isHideCollapseIcon={isHideCollapseIcon()}
                    isHover={isHover}
                    setIsHover={setIsHover}
                    setIsEdit={setIsEdit}
                    setIsActive={setIsActive}
                    onGroupAddNewDashboard={handleGroupAddNewDashboard}
                  />
                </>
              )
            }}
          </SizeTracker>
        </div>
      </HandleClickOutside>
      <ItemChildren
        data={data}
        isParentGroup={isParentGroup}
        isNewDashboardInGroup={isNewDashboardInGroup}
        collapseOpen={collapseOpen}
        onClickItem={onClickItem}
        onAddNewDashboardInGroup={handleAddNewDashboardInGroup}
      />
    </>
  )
}

Item.propTypes = {
  data: PropTypes.object.isRequired,
  isParentGroup: PropTypes.bool,
  isNewDashboard: PropTypes.bool,
  onClickItem: PropTypes.func,
  onAddDashboard: PropTypes.func,
  onAddNewDashboardInGroup: PropTypes.func,
}

Item.defaultProps = {
  isParentGroup: true,
  isNewDashboard: false,
  onClickItem: () => {},
  onAddDashboard: () => {},
  onAddNewDashboardInGroup: () => {},
}
