import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { TIME_FREQ } from '../exportStatisticTable/constants'
import {
  removeItemFromList,
  selectFrequencyType,
  selectListItems,
  selectMaxMonth,
  selectMaxYear,
} from '../exportStatisticTable/store/slice'
import { ChartComponent } from './ChartComponent'
import { COLORS_LINE_CHART, TIME_RANGE } from './constants'
import {
  changeActiveItem,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  selectCurrentDateType,
  selectCurrentDateTypeYearly,
  selectDataChart,
  selectLoading,
} from './store/slice'
import { getExporterGrowth } from './store/thunk'

export const ExportGrowth = ({ width, height, isFullScreen }) => {
  const dispatch = useDispatch()

  const headerRef = useRef()
  const legendPanel = useRef()

  const dataChart = useSelector(selectDataChart)
  const listItems = useSelector(selectListItems)
  const isLoading = useSelector(selectLoading)
  const currentTimeFrequency = useSelector(selectFrequencyType)
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const currentDateType = useSelector(selectCurrentDateType)
  const currentDateTypeYearly = useSelector(selectCurrentDateTypeYearly)

  const isYearly = currentTimeFrequency === TIME_FREQ.YEARLY

  const [heightSizeTracker, setHeightSizeTracker] = useState()
  const [heightListItems, setHeightListItems] = useState(0)

  const getIdsInListItems = listItems?.map((item) => item.id)

  // Actions
  const handleChangeDateType = (item) => {
    return isYearly
      ? dispatch(changeCurrentDateTypeYearly(item.value))
      : dispatch(changeCurrentDateType(item.value))
  }

  const handleDelete = (item) => {
    dispatch(removeItemFromList(item.id))
    dispatch(changeActiveItem(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveItem(item.id))
  }

  const handleUnHover = () => {
    dispatch(changeActiveItem(null))
  }

  // Use effect
  useEffect(() => {
    if (headerRef.current?.offsetHeight && heightListItems)
      setHeightSizeTracker(
        height - headerRef.current?.offsetHeight - heightListItems,
      )
  }, [height, width, listItems, heightListItems])

  useEffect(() => {
    if (listItems.length) {
      dispatch(
        getExporterGrowth({
          TimeFrequency: currentTimeFrequency,
          TimeRange: 'AllTime',
          Ids: getIdsInListItems.toString(),
          Year: maxYear,
          Month: isYearly ? null : maxMonth,
        }),
      )
    }
  }, [listItems, currentDateType, currentDateTypeYearly, maxMonth])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <>
      <div className="h-100">
        <div className="d-flex justify-content-end" ref={headerRef}>
          <DispatchActionTab
            data={isYearly ? TIME_RANGE.YEARLY : TIME_RANGE.MONTHLY}
            activeTab={isYearly ? currentDateTypeYearly : currentDateType}
            onChangeTab={handleChangeDateType}
          />
        </div>
        {dataChart.length ? (
          <div id="exportGrowthChart">
            <ChartComponent
              data={dataChart}
              width={width}
              height={heightSizeTracker}
              keyXAxis={'time'}
              lineKeys={getIdsInListItems}
            />
            <FooterWithScroll
              width={width}
              data={listItems}
              idKey="id"
              nameKey="name"
              color={(item) =>
                COLORS_LINE_CHART[getIdsInListItems.indexOf(item.id)]
              }
              legendPanel={legendPanel}
              isFullScreen={isFullScreen}
              setHeightFooter={setHeightListItems}
              onDelete={handleDelete}
              onHover={handleHover}
              onUnHover={handleUnHover}
            />
          </div>
        ) : (
          <NoData />
        )}
      </div>
    </>
  )
}
