export const common = {
  title: 'Sơ đồ quan hệ',
  unit: '(tỷ VND)',
  unit2: 'tỷ VND',
  alertPopup:
    'Tỷ lệ sở hữu tối thiểu là 5% và tối đa là 100%. Vui lòng nhập lại',
  titlePopup: 'Xác nhận',
  contentPopup:
    'Bạn có chắc chắn muốn áp dụng Tùy chọn hiển thị thay cho Bộ Lọc công ty ?',
  btnCancel: 'Hủy bỏ',
  btnConfirm: 'Đồng ý',
}

export const filter = {
  title: 'TÙY CHỌN HIỂN THỊ',
  ShareHoldersType: {
    title: 'Cổ đông',
    IndividualOwner: 'Cá nhân',
    OrganizationOwner: 'Doanh nghiệp',
  },
  OwnershipRate: {
    title: 'Tỷ lệ % sở hữu',
  },
  Position: {
    tilte: 'Chức vụ',
    Chairman: 'Chủ tịch HĐQT',
    CEO: 'TGĐ',
  },
  Relations: {
    title: 'Quan hệ',
    SubsidiaryAffiliate: 'Công ty con + Công ty liên kết',
    PaymentGuarantee: 'Bảo lãnh thanh toán',
  },
  FurtherInformation: {
    titele: 'Thông tin hiển thị',
    OwnershipRate: 'Tỷ lệ % sở hữu',
    OutstandingBond: 'Trái phiếu lưu hành',
    PaymentGuarantee: 'Giá trị bảo lãnh thanh toán',
  },
}

export const table = {
  title: 'TRÁI PHIẾU LƯU HÀNH',
  table: {
    BondTicker: 'Mã Trái phiếu',
    Outstanding: 'GT lưu hành',
    Total: 'Tổng',
    unit: '(tỷ VND)',
  },
}

export const chart = {
  title: 'Ma trận sở hữu',
  filter: {
    Level1: '1 Cấp',
    Level2: '2 Cấp',
    Level3: '3 Cấp',
    labelRangeFilter: 'Tùy chỉnh mật độ',
  },
  lengend: {
    OwnerofMainIssuer: 'Sở hữu đối tượng chính',
    Individualconcurrent: 'Kiêm nhiệm cá nhân',
    Paymentguarantee: 'Bảo lãnh thanh toán (TT)',
    RelationshipL1: 'Quan hệ cấp 1',
    RelationshipL2: 'Quan hệ cấp 2',
    RelationshipL3: 'Quan hệ cấp 3',
  },
  btnView: 'Xem chi tiết doanh nghiệp',
  btnchangeBond: 'Đổi thành chủ thể chính',
  statusGuarantee: 'Bảo lãnh TT',
}

export const businessInformation = {
  btnOpenPopup: 'Lọc công ty',
  btnCancel: 'Hủy bỏ',
  btnApply: 'Áp dụng',
  title: 'Thông tin doanh nghiệp',
  filter: {
    placeholerBusiness: 'Tìm kiếm công ty',
  },
  table: {
    checkBox: ' ',
    Name: 'Tên công ty',
    CharterCapital: 'Vốn điều lệ',
    unitBillMoney: '(Tỷ VND)',
    Own: '% Sở hữu',
    Guarantee: 'GT Bảo lãnh',
    IssueValue: 'GT lưu hành',
  },
}
