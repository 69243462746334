import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectBasicInfo } from '../Filter/store/slice'
import Bottom from './bottom'
import Content from './content'
import style from './index.module.css'
import { resetStore } from './store/slice'
import { exportFileApi } from './store/thunk'

const GeneralSetting = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )
  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const exportFile = () => {
    return exportFileApi({
      BondId: basicInfo?.bondId,
    })
  }

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <Panel
      title="bond.bondSecurity.common.generalSetting"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      downloadCsv={() => exportFile()}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              {/* <Filter /> */}
              {size.height && (
                <div
                  style={{
                    height: height - size.height,
                    width: width,
                    overflow: 'auto',
                  }}
                  className={style.scroll}
                >
                  <Content width={width} />
                </div>
              )}
              <Bottom />
            </>
          )
        }}
      </SizeTracker>
    </Panel>
  )
}

export default GeneralSetting
