export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const columns = [
  {
    key: 'organizationShortName',
    title: 'bond.valuation.companny.table.issuer',
    attrs: {
      style: {
        textAlign: 'left',
        minWidth: '62px',
      },
    },
  },
  {
    key: 'ticker',
    title: 'bond.valuation.companny.table.bondTicker',
    attrs: {
      style: {
        textAlign: 'left',
        width: '80px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
  {
    key: 'issueDateId',
    title: 'bond.valuation.companny.table.issueDateId',
    attrs: {
      style: { textAlign: 'left' },
    },
  },
  {
    key: 'couponInterestRate',
    title: 'bond.valuation.companny.table.couponInterestRate',
    additionText: 'bond.valuation.companny.table.unitMoney',
    attrs: {
      style: { textAlign: 'right' },
    },
  },
  {
    key: 'remainingDuration',
    title: 'bond.valuation.companny.table.remainingDuration',
    additionText: 'bond.valuation.companny.table.unitMonth',

    attrs: {
      style: { textAlign: 'right' },
    },
  },
  {
    key: 'marketPrice',
    title: 'bond.valuation.companny.table.marketPrice',
    additionText: 'bond.valuation.companny.table.unit',

    attrs: {
      style: { textAlign: 'right' },
    },
  },
  {
    key: 'yieldtoMaturity',
    title: 'bond.valuation.companny.table.yieldtoMaturity',
    additionText: 'bond.valuation.companny.table.unitCoupon',
    attrs: {
      style: { textAlign: 'right' },
    },
  },
]

export const STATUE_COLOR = {
  paid: '#3DA967',
  unpaid: '#DF5A49',
  postpone: '#FECF2F',
  cancel: '#FF4752',
  payment: '#A6CF98',
  paid_unconfirmed: '#3DA967',
  late_payment: '#ECECEC',
}
