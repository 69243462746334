import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getBasicInfo } from './thunk'

export const SLICE_NAME = 'common/useStockBasicInfo'

const initialState = {
  pendingRequests: {},
  stockBasicInfo: {},
  loading: false,
}

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBasicInfo.pending, (state, action) => {
      const requestOrgId = action.meta.arg.OrganizationId
      state.pendingRequests[requestOrgId] = true
      state.loading = true
      state.stockBasicInfo[requestOrgId] = null
    })
    builder.addCase(getBasicInfo.fulfilled, (state, action) => {
      const requestOrgId = action.meta.arg.OrganizationId
      state.pendingRequests[requestOrgId] = false
      state.stockBasicInfo[requestOrgId] = action.payload
      state.loading = false
    })
    builder.addCase(getBasicInfo.rejected, (state, action) => {
      const requestOrgId = action.meta.arg.OrganizationId
      state.pendingRequests[requestOrgId] = false
      state.loading = false
    })
  },
})

export const selectStockBasicInfo = (orgId) => (state) =>
  state[slice.name].stockBasicInfo[orgId]
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
