import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class NewsProxy extends ProxyBase {
  getNewsAnalytic(params) {
    return this.get('GetNews_Analytic_Paged', params)
  }

  getNewsEarningPaged(params) {
    return this.get('GetNews_Earning_Paged', params)
  }

  getNewsTransactionsPaged(params) {
    return this.get('GetNews_Transactions_Paged', params)
  }

  getNewsBusinessPaged(params) {
    return this.post('GetNews_Business_Paged', params)
  }

  getNewsCategories(params) {
    return this.get('GetNewsCategories', params)
  }

  getNewsCategoriesAnalytic(params) {
    return this.get('GetNewsCategories_Analytic', params)
  }

  getNewsSources(params) {
    return this.get('GetNewsSources', params)
  }

  getNewsSourcesAnalytic(params) {
    return this.get('GetNewsSources_Analytic', params)
  }

  getNewsOther(params) {
    return this.get('GetNews_Other', params)
  }

  getLatestNews(params) {
    return this.get('GetLatestNews', params)
  }

  getNewsById(params) {
    return this.get('GetNewsById', params)
  }
}

export default new NewsProxy(ServiceProxyConfig.Corporate.ServiceUrl)
