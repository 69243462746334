import { bankTypes } from '../constants'
import AssetQualityContent from './assetQuality'
import CapitalStructureContent from './capitalStructure'
import ProfitabilityContent from './profitability'

export const Bank = ({ type, dataType }) => {
  return type === bankTypes.ASSET_QUALITY ? (
    <AssetQualityContent dataType={dataType} />
  ) : type === bankTypes.CAPITAL_STRUCTURE ? (
    <CapitalStructureContent dataType={dataType} />
  ) : type === bankTypes.PROFITABILITY ? (
    <ProfitabilityContent dataType={dataType} />
  ) : (
    ''
  )
}
