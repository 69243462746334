import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Z_INDEX } from '../../../../constants/Common'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr }) => {
  const renderTitle = (text) => {
    return (
      <Span className={style.theadContent} style={{ fontSize: 10 }}>
        <Translate value={text} />
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      {tableHeaderColAttr.map((row, index) => {
        return (
          <tr key={index}>
            {row.map((col) => {
              return (
                <th
                  key={col.name}
                  {...col?.attrs}
                  className={`${!col.value?.length && style.borderBottomNone} ${
                    !col.additionText && style.verticalAlignInitial
                  }`}
                >
                  {col.value.map((text, i) => (
                    <React.Fragment key={i}>
                      {!col.info && renderTitle(text)}
                    </React.Fragment>
                  ))}
                  {col.additionText && (
                    <Span
                      className={style.additionText}
                      style={{ fontSize: 10 }}
                    >
                      <Translate value={col.additionText} />
                    </Span>
                  )}
                  {col?.isFixedBorder && <div className={style.fixedBorder} />}
                </th>
              )
            })}
          </tr>
        )
      })}
    </thead>
  )
}

export default TableHeader
