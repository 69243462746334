import PropTypes from 'prop-types'
import React, { Component } from 'react'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  FULL_COMPONENT,
} from '../utils/EventEmitter'
import { debounce } from './../utils/Common'

export const wrapperId = 'WrappedComponentId'

export const withWrapper =
  (initialSizes, panelRefs, initialSizesScreenSmall, minWidthResponsive) =>
  (WrappedComponent) => {
    class WithWrapper extends Component {
      constructor(props) {
        super(props)
        this.parentRefs = React.createRef()
        this.state = {
          sizes: this.getInitialSizes(),
        }
        this.resetWhenCloseFullScreen = false
        this.resizeWindowDebounce = debounce(() => {
          this.resetWhenCloseFullScreen = true
          this.resizeWindow()
        })
        this.resizeFullScreen = () => {
          this.resetWhenCloseFullScreen = false
          this.resizeWindow()
        }
        this.resizeCloseFullScreen = () => {
          if (this.resetWhenCloseFullScreen) {
            this.resizeWindow()
          }
        }
      }

      getInitialSizes = () => {
        return this.parentRefs.current?.offsetWidth <= minWidthResponsive &&
          initialSizesScreenSmall
          ? initialSizesScreenSmall
          : initialSizes
      }

      setSizes = (sizes) => {
        this.setState({ sizes })
      }

      resizeComponent = (initialSizesState) => {
        const sizes = {}
        Object.keys(initialSizesState).forEach((key) => {
          if (panelRefs[key]) {
            sizes[key] = {
              ...initialSizesState[key],
              width: panelRefs[key].offsetWidth,
              height: panelRefs[key].offsetHeight,
              top: panelRefs[key].offsetTop,
              left: panelRefs[key].offsetLeft,
            }

            if (panelRefs[key].offsetWidth < initialSizesState[key].minWidth) {
              sizes[key] = {
                ...sizes[key],
                width: initialSizesState[key].minWidth,
                left: initialSizesState[key].minLeft,
              }
            }

            if (
              panelRefs[key].offsetHeight < initialSizesState[key].minHeight
            ) {
              sizes[key] = {
                ...sizes[key],
                height: initialSizesState[key].minHeight,
                top: initialSizesState[key].minTop,
              }
            }

            if (
              panelRefs[key].offsetHeight ===
                initialSizesState[key].minHeight &&
              panelRefs[key].offsetTop < initialSizesState[key].minTop
            ) {
              sizes[key] = {
                ...sizes[key],
                top: initialSizesState[key].minTop,
              }
            }
          }
        })
        this.setSizes(sizes)
      }

      resizeWindow = () => {
        this.setSizes(this.getInitialSizes())
        this.resizeComponent(this.getInitialSizes())
        EventEmitter.dispatch(COMPONENT_RESIZE)
      }

      componentDidMount() {
        this.timeOut = setTimeout(() => {
          this.setSizes(this.getInitialSizes())
          this.resizeComponent(this.getInitialSizes())
        }, 0)
        window.addEventListener('resize', this.resizeWindowDebounce)
        EventEmitter.subscribe(FULL_COMPONENT, this.resizeFullScreen)
        EventEmitter.subscribe(CLOSE_FULL_COMPONENT, this.resizeCloseFullScreen)
      }

      componentWillUnmount() {
        clearTimeout(this.timeOut)
        window.removeEventListener('resize', this.resizeWindowDebounce)
        EventEmitter.unsubscribe(FULL_COMPONENT, this.resizeFullScreen)
        EventEmitter.unsubscribe(
          CLOSE_FULL_COMPONENT,
          this.resizeCloseFullScreen,
        )
      }

      render() {
        return (
          <div id={wrapperId} className="w-100 h-100" ref={this.parentRefs}>
            <WrappedComponent
              sizes={this.state.sizes}
              setSizes={this.setSizes}
              {...this.props}
            />
          </div>
        )
      }
    }

    const nameWrappedComponent =
      WrappedComponent.displayName || WrappedComponent.name || 'Component'

    WithWrapper.displayName = `withWrapper(${nameWrappedComponent})`

    return WithWrapper
  }

withWrapper.propTypes = {
  initialSizes: PropTypes.object.isRequired,
  panelRefs: PropTypes.object.isRequired,
}
