export const rankColorConstants = {
  A: '#d34fff',
  B: '#26a512',
  C: '#ffcc24',
  D: '#ff8b24',
  E: '#fa4900',
  F: '#bd0000',
}

export const rankingTableConstants = {
  RANK_VALUE: 'rankValue',
  TICKER: 'ticker',
  COMPANY_NAME: 'organizationShortName',
  EXCHANGE: 'groupCode',
  SECTOR: 'icbName',
  MARKET_CAP: 'rtD0005',
  VALUE: 'value',
  GROWTH: 'growth',
  MOMENTUM: 'momentum',
  VGM: 'vgm',
  RANKING_STATUS: 'listHistory',
}

export const rankingTableFormat = {
  MARKET_CAP: 10 ** 9,
  MATCH_PRICE: 10 ** 3,
  PERCENT_PRICE_CHANGE: 0.01,
}

export const exchangeConstants = {
  ALL: 'Listed',
  VNINDEX: 'VNIndex',
  HNXINDEX: 'HNXIndex',
}

export const exchangeOptions = [
  {
    name: 'sector.sectorConstituents.ranking.ALL_EXCHANGE',
    value: exchangeConstants.ALL,
  },
  {
    name: 'HOSE',
    value: exchangeConstants.VNINDEX,
  },
  {
    name: 'HNX',
    value: exchangeConstants.HNXINDEX,
  },
]

export const scoreTypes = {
  VALUE_SCORE: 'value',
  GROWTH_SCORE: 'growth',
  MOMENTUM_SCORE: 'momentum',
  VGM_SCORE: 'vgm',
}
