import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../../common/loading'
import { selectICBs } from '../../../../../common/masterData/store/slice'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { SizeTracker } from '../../../../../common/sizeTracker'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FEATURES } from '../../../../../constants/routes'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import style from './index.module.css'
import { RadioGroup } from './RadioGroup'
import {
  selectDataNews,
  selectDataReports,
  selectIsNews,
  selectLoading,
} from './store/slice'
import { getNews, getReports } from './store/thunk'

export const NewsAndReport = ({ width, height }) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const timezone = UseTimeZone()

  const newsData = useSelector(selectDataNews)
  const reportsData = useSelector(selectDataReports)
  const isNews = useSelector(selectIsNews)
  const isLoading = useSelector(selectLoading)
  const ICBs = useSelector(selectICBs)
  const locale = useSelector((state) => state.i18n.locale)

  const [totalWidthOfFirstAndSecond, setTotalWidthOfFirstAndSecond] =
    useState(0)

  useEffect(() => {
    const FIRST_WIDTH = 120 // width first col is static
    setTotalWidthOfFirstAndSecond(FIRST_WIDTH)
  }, [])

  const LAST_COL_MIN_WIDTH = 0.15 // 15 percent
  const lastColumnMaxWidth = Math.max(
    width - totalWidthOfFirstAndSecond,
    width * LAST_COL_MIN_WIDTH,
  )

  const data = isNews ? newsData : reportsData

  const onClick = (newsId, icbId) => () => {
    if (isNews) {
      const sectorInfo = ICBs.find((item) => item.icbId === icbId)
      navigate(
        FEATURES.sector.components['sector-constituents'].components[
          'news-report-detail'
        ].route,
        sectorInfo,
        '?newsId=' + newsId,
      )
    }
  }

  useEffect(() => {
    if (isNews && locale) {
      dispatch(getNews({ Language: locale }))
    }
  }, [locale, isNews])

  useEffect(() => {
    if (!isNews && locale) {
      dispatch(getReports({ Language: locale }))
    }
  }, [locale, isNews])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => {
          return (
            <>
              <RadioGroup />
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <ScrollComponent>
                      <table>
                        <tbody>
                          {data.map((item, index) => {
                            return (
                              <tr
                                key={index}
                                style={{ borderBottom: 'unset' }}
                                onClick={onClick(item.newsId, item.icbId)}
                              >
                                <td className={style.firstTd}>
                                  <TextEllipsis
                                    text={
                                      isNews
                                        ? formatDateTime(
                                            item.publicDate,
                                            FORMAT.DATE,
                                            locale,
                                            timezone,
                                          )
                                        : formatDateTime(
                                            item.publicDateId,
                                            FORMAT.DATE,
                                            locale,
                                            timezone,
                                          )
                                    }
                                  />
                                </td>
                                <td className={style.secondTd}>
                                  <TextEllipsis
                                    isI18n={false}
                                    text={item.title}
                                  />
                                </td>
                                {isNews ? (
                                  <td
                                    style={{
                                      maxWidth: lastColumnMaxWidth,
                                      paddingRight: 20,
                                    }}
                                    className={style.thirdTd}
                                  >
                                    <TextEllipsis
                                      isI18n={false}
                                      text={item.source}
                                    />
                                  </td>
                                ) : (
                                  <div
                                    className="d-flex justify-content-space-between"
                                    style={{ paddingRight: 20 }}
                                  >
                                    <td
                                      style={{
                                        maxWidth: lastColumnMaxWidth / 2,
                                      }}
                                      className={style.thirdTd}
                                    >
                                      <TextEllipsis
                                        isI18n={false}
                                        text={item.organizationShortName}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        maxWidth: lastColumnMaxWidth / 2,
                                      }}
                                      className={style.thirdTd}
                                    >
                                      <a
                                        href={item.reportURL}
                                        className="cursor-pointer"
                                      >
                                        <i className="icon-download" />
                                      </a>
                                    </td>
                                  </div>
                                )}
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </ScrollComponent>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    )
  )
}
