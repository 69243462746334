import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import TimeFrame from '../../common/TimeFrame'
import { TIME_FRAME_OPTIONS } from './constants'
import style from './index.module.css'
import {
  getRadioValue,
  getTimeRange,
  handleRadioValue,
  handleTimeRange,
} from './store/slice'

const RADIO_LIST = [
  {
    value: 'VALUE',
    label: 'economy.monetary.monetary.VALUE',
  },
  {
    value: 'PROPORTION',
    label: 'economy.monetary.monetary.PROPORTION',
  },
]

const Header = () => {
  const dispatch = useDispatch()

  const timeRange = useSelector(getTimeRange)
  const radioValue = useSelector(getRadioValue)

  const handleChangeTimeRange = (timeRange) => {
    dispatch(handleTimeRange(timeRange?.value))
  }

  const handleChangeRadio = (radioValue) => {
    dispatch(handleRadioValue(radioValue))
  }

  return (
    <div className="d-flex align-center j-b">
      <div className="d-flex align-center ">
        {RADIO_LIST.map(({ value, label }, index) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  ${index === 0 && style.mr24}
                  `}
              onClick={() => handleChangeRadio(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === radioValue}
                onChange={() => handleChangeRadio(value)}
              />
              <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
                <Translate value={label} />
              </Span>
            </div>
          )
        })}
      </div>
      <TimeFrame
        timeRange={timeRange}
        onChangeTab={handleChangeTimeRange}
        timeFrameOption={TIME_FRAME_OPTIONS}
      />
    </div>
  )
}

export default Header
