import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ReactComponent as PinIcon } from '../../../icon/icon-pin.svg'
import {
  handleIsPinTooltip,
  removeAllTooltip,
  selectIsPinTooltip,
} from '../../store/slice'
import style from './style.module.css'

const IconPin = ({ isShowChart }) => {
  const dispatch = useDispatch()
  const isPinTooltip = useSelector(selectIsPinTooltip)

  const changeIsPinTooltip = () => {
    dispatch(handleIsPinTooltip(!isPinTooltip))
    if (isPinTooltip) {
      dispatch(removeAllTooltip())
    }
  }

  return (
    <div
      className={[
        style.borderIconPin,
        isPinTooltip && style.activePin,
        !isShowChart && style.pointerEventNone,
      ].join(' ')}
    >
      <PinIcon
        title={I18n.t('financialChart.PIN_TOOLTIP')}
        onClick={changeIsPinTooltip}
        className={style.iconPin}
      />
    </div>
  )
}

IconPin.propTypes = {
  isShowChart: PropTypes.bool,
}

export default IconPin
