export const multiplierTypes = {
  RAW: 1,
  THOUSAND: 10 ** 3,
  MILLION: 10 ** 6,
  BILLION: 10 ** 9,
  TRILLION: 10 ** 12,
}

export const dataFormatTypes = {
  VALUE: 'value',
  PERCENT: 'percent',
  PREV_PERCENT: 'prevPercent',
}

export const displayDataLabel = {
  YES: true,
  NO: false,
}

export const dataFrequencyTypes = {
  DAILY: 'Daily',
  TTM: 'TTM',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
}

export const lineStyleTypes = {
  SOLID: 'solid',
  DASHED: 'dashed',
  DOTTED: 'dotted',
}

export const lineWidthTypes = {
  THIN: 'thin',
  NORMAL: 'normal',
  THICK: 'thick',
}

export const dotsOnLineTypes = {
  YES: true,
  NO: false,
}

export const smoothLines = {
  YES: true,
  NO: false,
}

export const suggestColors = [
  '#0070C0',
  '#FFC700',
  '#00B0F0',
  '#A5A5A5',
  '#7030A0',
  '#ED7D31',
  '#C00000',
  '#92D050',
]

export const gradientColorTypes = {
  SOLID: 'solid',
  RADIO: 'radio',
  LINEAR: 'linear',
}

export const statisticalMethodTypes = {
  MEAN: 'mean',
  PLUS_ONE_SD: 'plusOneSD',
  MINUS_ONE_SD: 'minusOneSD',
  PLUS_TWO_SD: 'plusTwoSD',
  MINUS_TWO_SD: 'minusTwoSD',
  MEDIAN: 'median',
  HIGHEST: 'highest',
  LOWEST: 'lowest',
}

export const statisticalMethodDisplay = {
  all: 'financialChart.ALL',
  [statisticalMethodTypes.MEAN]: 'financialChart.chartSettings.MEAN',
  [statisticalMethodTypes.PLUS_ONE_SD]:
    'financialChart.chartSettings.PLUS_ONE_SD',
  [statisticalMethodTypes.MINUS_ONE_SD]:
    'financialChart.chartSettings.MINUS_ONE_SD',
  [statisticalMethodTypes.PLUS_TWO_SD]:
    'financialChart.chartSettings.PLUS_TWO_SD',
  [statisticalMethodTypes.MINUS_TWO_SD]:
    'financialChart.chartSettings.MINUS_TWO_SD',
  [statisticalMethodTypes.MEDIAN]: 'financialChart.chartSettings.MEDIAN',
  [statisticalMethodTypes.HIGHEST]: 'financialChart.chartSettings.HIGHEST',
  [statisticalMethodTypes.LOWEST]: 'financialChart.chartSettings.LOWEST',
}

export const statisticalMethodUpperLowerDifference = {
  [statisticalMethodTypes.MEAN]: 0,
  [statisticalMethodTypes.PLUS_ONE_SD]: 1,
  [statisticalMethodTypes.MINUS_ONE_SD]: -1,
  [statisticalMethodTypes.PLUS_TWO_SD]: 2,
  [statisticalMethodTypes.MINUS_TWO_SD]: -2,
  [statisticalMethodTypes.MEDIAN]: 0,
  [statisticalMethodTypes.HIGHEST]: 0,
  [statisticalMethodTypes.LOWEST]: 0,
}

export const statisticalMethodDefaultParams = {
  isCheck: false,
  lineStyle: lineStyleTypes.DASHED,
  lineWidth: lineWidthTypes.NORMAL,
  color: '#000000',
  upperLowerDifference: 0,
}
