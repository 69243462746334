import newsEventsProxy from '../../proxies/corporate/NewsEventsProxy'
import { ServiceBase } from '../ServiceBase'

class NewsEventsService extends ServiceBase {
  getFiinXEventTypes(params) {
    return this.applyMemoryCache(
      'NewsEventsService.getFiinXEventTypes',
      params,
    )(() => newsEventsProxy.getFiinXEventTypes(params))
  }

  getEvents(params) {
    return this.getData(() => newsEventsProxy.getEvents(params))
  }

  getEventCount(params) {
    return this.getData(() => newsEventsProxy.getEventCount(params))
  }

  getEventById(params) {
    return this.applyMemoryCache(
      'NewsEventsService.getEventById',
      params,
    )(() => newsEventsProxy.getEventById(params))
  }

  getFilings(params) {
    return this.applyMemoryCache(
      'NewsEventsService.getFilings',
      params,
    )(() => newsEventsProxy.getFilings(params))
  }

  getFilingCategories(params) {
    return this.applyMemoryCache(
      'NewsEventsService.getFilingCategories',
      params,
    )(() => newsEventsProxy.getFilingCategories(params))
  }

  downloadEvents(params) {
    return this.getFileDownload(() => newsEventsProxy.downloadEvents(params))
  }
}

export default new NewsEventsService()
