import useGetExchangeV2 from '../../../../common/masterData/useGetExchangeV2'
import style from '../style.module.css'

const ListIndicesSelected = ({
  indexStart,
  indicesChecked,
  securitiesState,
  setSecuritiesState,
}) => {
  const { exchanges } = useGetExchangeV2(true, true)

  const getValueItem = (groupId) => {
    return exchanges.find((i) => i.groupId === groupId)
  }

  const removeIndicesInList = (groupId) => {
    const value = indicesChecked.filter((i) => i !== groupId)
    setSecuritiesState({ ...securitiesState, indices: value })
  }

  return (
    <>
      {indicesChecked.map((groupId, index) => {
        return (
          <li key={groupId}>
            <div className={style.indexCol}>{indexStart + index + 1}</div>
            <div className={style.divSecurities}>
              <div className={style.tickerInfo}>
                {getValueItem(groupId)?.groupName}
              </div>
            </div>
            <div
              className={style.checkboxCol}
              onClick={() => removeIndicesInList(groupId)}
            >
              <i
                className="icon-delete-circle cursor-pointer"
                style={{ fontSize: 12 }}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default ListIndicesSelected
