import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import WorkSpace from '../../../common/dataExplorer/corporate/WorkSpace'
import {
  HIDE_LOADING,
  SHOW_LOADING,
} from '../../../common/dataExplorer/corporate/popupIndicator'
import { resetStore } from '../../../common/dataExplorer/corporate/store/slice'
import { Loading } from '../../../common/loading'
import { SizeTracker } from '../../../common/sizeTracker'
import SectorTopInfo from '../../../common/topInfo/sectorTopInfo'
import EventEmitter from '../../../utils/EventEmitter'
import Content from './Content'

export const Ratio = () => {
  const dispatch = useDispatch()

  const loadingRef = useRef()

  useEffect(() => {
    hideLoading()
    EventEmitter.subscribe(SHOW_LOADING, showLoading)
    EventEmitter.subscribe(HIDE_LOADING, hideLoading)
    return () => {
      dispatch(resetStore())
      EventEmitter.unsubscribe(SHOW_LOADING, showLoading)
      EventEmitter.unsubscribe(HIDE_LOADING, hideLoading)
    }
  }, [])

  const showLoading = () => {
    loadingRef.current.style.display = 'block'
  }

  const hideLoading = () => {
    loadingRef.current.style.display = 'none'
  }

  return (
    <ScrollGroupComponent>
      <div ref={loadingRef}>
        <Loading />
      </div>
      <WorkSpace />
      <SizeTracker>
        {(size) => (
          <>
            <SectorTopInfo title="sector.sectorConstituents.ratio.RATIO" />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
