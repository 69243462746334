export const coveredWarrant = {
  SUMMARY: 'Summary',
  PRICE_DEPTH: 'Price Depth',
  BREAK_EVEN_POINT: 'Break-even Point',
  TIME_AND_SALES: 'Time & Sales',
  CHARTING: 'Charting',
  COVERED_WARRANT: 'Covered Warrant',
  PRICE_VOLUME_UNIT: 'Price: x1,000. Volume: x1,000',
  summary: {
    CW_TICKER: 'CW Ticker',
    CW_ISSUER: 'CW Issuer',
    CW_TYPE: 'CW Type',
    EXERCISE_STYLE: 'Exercise Style',
    EXERCISE_PRICE: 'Exercise Price',
    CONVERSION_RATIO: 'Conversion Ratio',
    UNDERLYING_ASSET: 'Underlying Asset',
    PRICE_OF_UNDERLYING_ASSET: 'Price of Underlying Asset',
    SETTLEMENT_STATUS: 'Settlement Status',
    BREAK_EVEN_POINT: 'Break-even Point',
    ISSUED_QUANTITY: 'Issued Quantity',
    MATURITY_DATE: 'Maturity Date',
    EXPIRY_DATE: 'Expiry Date',
    DURATION: 'Duration',
    AVG_5_DAYS: 'Avg. Price of Underlying Asset 5D',
    EST_CW_PRICE: 'Est. CW Price at maturity date',
    DAYS: ' days',
  },
  breakEvenPoint: {
    CW_BREAK_EVEN_POINT: 'CW Break-even point',
    UNDERLYING_ASSET_PRICE: 'Underlying Asset Price',
    EXERCISE_PRICE: 'Exercise Price',
    THOUSAND_VND: 'Thousand',
  },
  priceDepth: {
    BID: 'BID',
    ASK: 'ASK',
    BUY_VOL: 'BUY VOL.',
    SELL_VOL: 'SELL VOL.',
    TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
    TOTAL_MATCHED_VOL: 'TOTAL MATCHED VOL.',
    TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
    PERCENT_1D_CHANGE: '% 1D Change',
    PERCENT_1W_CHANGE: '% 1W Change',
    PERCENT_1M_CHANGE: '% 1M Change',
    PERCENT_FTD_CHANGE: '% FTD Change',
    TOTAL_VALUE_BN_VND: 'Total Value (Bn. VND)',
    TOTAL_VOLUME: 'Total Volume (M)',
    FOREIGN_BUY_VOLUME: 'Foreign Buy Volume (M)',
    FOREIGN_SELL_VOLUME: 'Foreign Sell Volume (M)',
    AVG_VOLUME_10D: 'Avg. Volume 10D (M)',
    AVG_VOLUME_1M: 'Avg. Volume 1M (M)',
  },
  timeAndSales: {
    MATCH_PRICE: 'Match Price',
    BU_VOLUME: 'BU Volume',
    SD_VOLUME: 'SD Volume',
    UNIDENTIFIED: 'Unidentified',
    LAST_PRICE: 'Last Price',
    TYPE: 'Type',
    CHANGE: 'Change',
    PERCENT_CHANGE: '% 1D Change',
    MATCH_VOLUME: 'Matched Volume',
    TOTAL_MATCH_VOLUME: 'Total Matched Volume',
    BN: 'Bn.',
  },

  cwStatistics: {
    CW_STATISTICS: 'COVER WARRANT STATISTICS',

    PRICE_DATA: 'Price data',
    NET_VALUE_INVESTOR: 'Net value by Investor',
    NET_TRADING_BREAKDOWN: 'Net Value Breakdown',

    TICKER: 'Ticker',
    ALL: 'All',
    OVERVIEW: 'Overview',
    DETAIL: 'Detail',
    NOTE_TABLE: 'Price: x1,000. Vol.: x1,000. Val.: 1,000,000',
    FROM: 'From',
    TO: 'To',
    CLEAR: 'Clear',
    DATE: 'Date',
    TICKER_TABLE: 'Ticker',
    ALL_MARKET: 'All',
    TRADING_DATE: 'Trading date',

    WEEK: 'Week',
    MONTH: 'Month',
    QUARTER: 'Quarter',
    YEAR: 'Year',

    MILLION_USD: 'Million USD',
    VALUE_MILLION: 'Value (Million VND)',
    PRICE_THOUSAND: 'Price (Thousand VND)',
    MILLION_VND: 'Million VND',
    THOUSAND_VND: 'Thousand VND',
    VAL_BILLION: 'Val. (Billion VND)',
    BILLION: 'Billion VND',

    BUY: 'Buy',
    SELL: 'Sell',
    NET: 'Net',

    LOCAL_INDIVIDUAL: 'Local Individual',
    FOREIGN: 'Foreign',
    LOCAL_INSTITUTION: 'Local Institution',
    PROPRIETARY: 'Proprietary',

    VIEW_BY: 'View by',
    DAILY: 'Daily',
    WEEKLY: 'Weekly',
    MONTHLY: 'Monthly',
    QUARTERLY: 'Quarterly',
    YEARLY: 'Yearly',

    overview: {
      OVERVIEW: 'Overview',

      STOP: 'Stop',
      TRADING: 'Trading',

      ORDER: 'Order',
      TICKER: 'Ticker',
      TRADING_STATUS: 'Status',
      ISSUER: 'Issuer',
      CW_ASSURED: 'Covered Warrant',
      CW_TYPE: 'CW Type',
      EXECUTIVE_RATIO: 'Executive ratio',
      ISSUE_PRICE: 'Issue price',
      CW_PRICE: 'Current Price',
      UNDERLYING_ASSET: 'Underlying',
      UNDERLYING_ASSET_CHILD: 'Underlying asset',
      EXERCISE_PRICE: 'Exercise price',
      AVG_PRICE: '% days average price',
      BREAK_EVEN_PRICE: 'Breakeven price',
      CURRENT_PRICE: 'Current price',
      PERCENT_PREMIUM: '%Premium',
      PERCENT_CHANGE: '%1D change',
      CW_STATUS: 'CW status',
      DURATION: 'Duration (days)',
      LAST_TRADING_DATE: 'Last trading date',
      FIRST_TRADING_DATE: 'First Trading Date',
      CLOSE_PRICE: 'Close Price',
      PERCENT_CLOSE_PRICE_CHANGE: '%Change (Close Price)',
      CLOSE_PRICE_TSCS: 'Close Price',
      TOOLTIP_CLOSE_PRICE_UPDATED:
        'Close Price is updated latest on %{maxTradingDate}',
      TOOLTIP_PERCENT_CLOSE_PRICE_UPDATED:
        '%Change (Close Price) is updated latest on %{maxTradingDate}',
    },

    historicalPrice: {
      HISTORICAL_PRICE: 'Historical Price',

      DATE: 'Date',
      ORDER_MATCHING: 'Order-matching',
      VOLUME: 'Volume',
      VALUE: 'Value',
      PUT_THROUGH: 'Put-through',
      TOTAL_VOLUME: 'Total volume',
      TOTAL_VALUE: 'Total value',

      TICKER_TITLE: 'Ticker',
      PERCENT_CHANGE: '%1D Change',
      UNDERLYING_ASSET: 'Underlying asset price',
      BREAK_EVEN: 'Breakeven price',
      AVG_FIVE_DAYS: '5 days average price',
      PERCENT_PREMIUM: '%Premium',

      OPEN: 'Open',
      HIGHEST: 'Highest',
      LOWEST: 'Lowest',
      CLOSE: 'Close',
    },

    foreign: {
      FOREIGN: 'Foreign Trading',

      BUY_VOLUME: 'Buy Volume',
      BUY_VALUE: 'Buy Value',
      SELL_VOLUME: 'Sell Volume',
      SELL_VALUE: 'Sell Value',
      NET_VOLUME: 'Net Volume',
      NET_VALUE: 'Net Value',
      ORDER_MATCHING_BUY_VOL: 'Order-matching Buy Volume',
      ORDER_MATCHING_BUY_VAL: 'Order-matching Buy Value',
      ORDER_MATCHING_SELL_VOL: 'Order-matching Sell Volume',
      ORDER_MATCHING_SELL_VAL: 'Order-matching Sell Value',
      PUT_THROUGH_BUY_VOL: 'Put-through Buy Volume',
      PUT_THROUGH_BUY_VAL: 'Put-through Buy Value',
      PUT_THROUGH_SELL_VOL: 'Put-through Sell Volume',
      PUT_THROUGH_SELL_VAL: 'Put-through Sell Value',
    },

    orderStatistics: {
      ORDER_STATISTICS: 'Order Statistics',

      DATE: 'Date',
      TICKER: 'Ticker',
      UNMATCHED_BUY: 'Unmatched Buy Volume',
      UNMATCHED_SELL: 'Unmatched Sell Volume',
      BUY_ORDERS: 'Buy Orders',
      BUY_VOLUME: 'Buy Volume',
      AVG_BUY: 'Avg Buy Volume Per Order',
      SELL_ORDERS: 'Sell Orders',
      SELL_VOLUME: 'Sell Volume',
      AVG_SELL: 'Avg Sell Volume Per Order',
      NET_BUY_SELL: 'Net Volume',
    },

    proprietary: {
      PROPRIETARY: 'Proprietary',

      TOTAL_BUY_VOL: 'TOTAL BUY VOL.',
      TOTAL_BUY_VAL: 'TOTAL BUY VAL.',
      TOTAL_SELL_VOL: 'TOTAL SELL VOL.',
      TOTAL_SELL_VAL: 'TOTAL SELL VAL.',
      NET_TOTAL_VOL: 'NET TOTAL VOL.',
      NET_TOTAL_VAL: 'NET TOTAL VAL.',
      MATCH_BUY_VOL: 'MATCHED BUY VOL.',
      MATCH_BUY_VAL: 'MATCHED BUY VAL.',
      MATCH_SELL_VOL: 'MATCHED SELL VOL.',
      MATCH_SELL_VAL: 'MATCHED SELL VAL.',
      DEAL_BUY_VOL: 'DEAL BUY VOL.',
      DEAL_BUY_VAL: 'DEAL BUY VAL.',
      DEAL_SELL_VOL: 'DEAL SELL VOL.',
      DEAL_SELL_VAL: 'DEAL SELL VAL.',
    },
  },
}
