import { PropTypes } from 'prop-types'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { checkIpadScreen } from '../../../../../market/marketInDepth/indexStatistics/helps'
import {
  quartersAccMonthly,
  quartersMonthly,
} from '../../chartSecond/topGrowthIndustry/constans'
import { changeListTimeRanger } from '../../chartSecond/topGrowthIndustry/store/slice'
import {
  PRICE_DATA_TIME_SELECT,
  PRODUCTION_INDEX_DATA,
  TABLE_TYPE_PERIOD,
} from '../../constants'
import { TAB_TYPE } from '../constants'
import {
  getRadioValuePPI,
  handleIsAllowCallApi,
} from '../generalIndex/store/slice'
import {
  changeTypeDataTime,
  getYearlyQuarterValue,
  handleYearlyQuarterValue,
  selectTypeDataTime,
  selectTypePeriod,
} from '../store/slice'
import ComparisonType from './ComparisonType'

export const FilterTable = ({ width, activePage }) => {
  const dispatch = useDispatch()
  const { GENERAL_INDEX, III } = TAB_TYPE
  const { ACC_MONTHLY, MONTHLY } = PRICE_DATA_TIME_SELECT

  const typeDataTime = useSelector(selectTypeDataTime)
  const typePeriod = useSelector(selectTypePeriod)
  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const radioValuePPI = useSelector(getRadioValuePPI)

  const actionTabData =
    activePage === GENERAL_INDEX
      ? PRODUCTION_INDEX_DATA
      : Object.keys(PRICE_DATA_TIME_SELECT).map((key) => {
          return {
            title: `economy.productionAndConsumption.production.timeRange.${key}`,
            value: PRICE_DATA_TIME_SELECT[key],
          }
        })

  const disableAccMonthly =
    typePeriod === TABLE_TYPE_PERIOD.PRE_PERIOD ? [ACC_MONTHLY] : []

  const disableAccQuarterly =
    radioValuePPI === TABLE_TYPE_PERIOD.PRE_PERIOD ? ['AccQuarterly'] : []

  const onChangeTab = (type) => {
    if (activePage === GENERAL_INDEX) {
      dispatch(handleYearlyQuarterValue(type.value))
      dispatch(handleIsAllowCallApi(true))
    } else {
      dispatch(changeTypeDataTime(type.value))
      if (type.value === MONTHLY) {
        dispatch(changeListTimeRanger(quartersMonthly))
      } else if (type.value === ACC_MONTHLY) {
        dispatch(changeListTimeRanger(quartersAccMonthly))
      } else {
        dispatch(changeListTimeRanger([]))
      }
    }
  }

  const isIpadScreen = useMemo(() => {
    return checkIpadScreen(width, 1150)
  }, [width])

  useEffect(() => {
    if (activePage === III) {
      dispatch(changeTypeDataTime(MONTHLY))
      dispatch(changeListTimeRanger(quartersMonthly))
    }
  }, [activePage])

  return (
    <div>
      <div
        className={isIpadScreen ? 'd-flex j-b ali-center mb-8' : 'table-info'}
      >
        <div
          className={`table-info-left align-center w-100 ${
            isIpadScreen && 'mb-8'
          }`}
        >
          <ComparisonType />
        </div>
        {activePage !== III && (
          <div
            className={`table-info-right d-flex align-center j-b ${
              !isIpadScreen && 'ml-8'
            }`}
          >
            <DispatchActionTab
              data={actionTabData}
              activeTab={
                activePage === GENERAL_INDEX ? yearlyQuarterValue : typeDataTime
              }
              itemStyle={{ fontWeight: 600, padding: '0 16px' }}
              containerStyle={{ marginLeft: 8 }}
              onChangeTab={onChangeTab}
              listDisabled={
                activePage === GENERAL_INDEX
                  ? disableAccQuarterly
                  : disableAccMonthly
              }
            />
          </div>
        )}
      </div>
    </div>
  )
}

FilterTable.propTypes = {
  width: PropTypes.number.isRequired,
  activePage: PropTypes.string,
}
