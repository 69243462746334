import PropTypes from 'prop-types'
import { memo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Z_INDEX } from '../../../constants/Common'
import UseTimeZone from '../../hooks/useTimeZone'
import TextEllipsis from '../../textEllipsis'
import { typeOfIndicator } from '../constant'
import RowNoData from './RowNoData'
import { EMPTY_COLUMN_LEFT_TABLE } from './Thead'
import { handleCellValue } from './helper'
import style from './index.module.css'
import { selectDisplayColumns, selectIsScrolling } from './store/slice'
import withWrapperCell from './withWrapperCell'
import withWrapperRow from './withWrapperRow'

const companyOverviewIndicatorId = 19

const TdCell = withWrapperCell(
  ({
    idTicker,
    index,
    selectColumnId,
    selectTickerCell,
    selectColumnCell,
    customClassName,
  }) => {
    const timezone = UseTimeZone()

    const isScrolling = useSelector(selectIsScrolling)
    const idColumn = useSelector(selectColumnId(index))
    const value = useSelector(selectTickerCell(idTicker, idColumn))
    const column = useSelector(selectColumnCell(idColumn))
    const locale = useSelector((state) => state.i18n.locale)

    const div = useRef(document.createElement('div'))

    const isNumber = [
      typeOfIndicator.INT,
      typeOfIndicator.SMALLINT,
      typeOfIndicator.DECIMAL,
    ].includes(column?.typeOf)

    const styleTd = {
      textAlign: isNumber ? 'right' : 'left',
      color: isNumber && value < 0 ? '#ff4752' : undefined,
    }

    let convertValue = value

    if (column?.indicatorId === companyOverviewIndicatorId && value) {
      div.current.innerHTML = value
      convertValue = div.current.innerText
    }

    return (
      <>
        {!!idColumn && !isScrolling && (
          <td
            style={styleTd}
            colid={`col-${idColumn}`}
            className={[!column?.render && style.td, customClassName.td].join(
              ' ',
            )}
          >
            {convertValue !== undefined && (
              <>
                {column?.render ? (
                  column.render(idTicker, idColumn, convertValue)
                ) : (
                  <TextEllipsis
                    isI18n={false}
                    text={handleCellValue(
                      convertValue,
                      column?.typeOf,
                      locale,
                      timezone,
                      column?.unit,
                    )}
                  />
                )}
              </>
            )}
          </td>
        )}
      </>
    )
  },
)

const TdCellInfo = ({
  id,
  selectTickerCell,
  idTicker,
  appendStyle,
  render,
  isSticky,
  indexTicker,
  customClassName,
}) => {
  const value = useSelector(selectTickerCell(idTicker, id))

  const stickyStyle = isSticky
    ? { zIndex: Z_INDEX.STICKY_CELL, position: 'sticky', background: '#1e242e' }
    : {}

  return (
    <td
      style={{
        ...stickyStyle,
        ...(appendStyle || {}),
      }}
      className={
        isSticky
          ? [style.columnSticky, customClassName.columnSticky || ''].join(' ')
          : ''
      }
    >
      {render ? (
        render(idTicker, id, value, indexTicker)
      ) : (
        <div>
          <TextEllipsis isI18n={false} text={value} />
        </div>
      )}
    </td>
  )
}

export const TdInfo = memo(
  ({ cellInfo, selectTickerCell, idTicker, indexTicker, customClassName }) => {
    return (
      <>
        {cellInfo.map((item, index) => {
          return (
            <TdCellInfo
              key={index}
              id={item.id}
              selectTickerCell={selectTickerCell}
              idTicker={idTicker}
              appendStyle={item.appendStyle}
              render={item.render}
              isSticky={item.isSticky}
              indexTicker={indexTicker}
              customClassName={customClassName}
            />
          )
        })}
      </>
    )
  },
)

const Row = withWrapperRow(
  ({
    indexTicker,
    selectId,
    selectColumnId,
    selectTickerCell,
    cellInfo,
    selectColumnCell,
    customClassName,
  }) => {
    const idTicker = useSelector(selectId(indexTicker))
    const displayColumns = useSelector(selectDisplayColumns)

    if (!idTicker) return <></>

    return (
      <tr className={customClassName.tr || ''}>
        <TdInfo
          cellInfo={cellInfo}
          selectTickerCell={selectTickerCell}
          idTicker={idTicker}
          indexTicker={indexTicker}
          customClassName={customClassName}
        />
        <td colSpan={EMPTY_COLUMN_LEFT_TABLE} />
        {Object.keys(displayColumns).map((index) => (
          <TdCell
            key={index}
            index={index}
            idTicker={idTicker}
            selectColumnId={selectColumnId}
            selectTickerCell={selectTickerCell}
            selectColumnCell={selectColumnCell}
            customClassName={customClassName}
          />
        ))}
      </tr>
    )
  },
)

export const EMPTY_ROW_TOP_TABLE = 60 // fix bug header move when scroll

const Tbody = ({
  maxRow,
  selectId,
  columnIndex,
  selectColumnId,
  selectTickerCell,
  selectFullId,
  cellInfo,
  selectColumnCell,
  noDataText,
  customClassName,
}) => {
  const rowIndex = useRef([...Array(maxRow).keys()])

  return (
    <tbody>
      {[...Array(EMPTY_ROW_TOP_TABLE).keys()].map((id) => (
        <tr key={id} className={customClassName.tr || ''}>
          <TdInfo
            cellInfo={cellInfo.map((item) => ({
              ...item,
              render: () => <></>,
            }))}
            selectTickerCell={selectTickerCell}
            idTicker={'null'}
            indexTicker={0}
            customClassName={customClassName}
          />
          <td colSpan={EMPTY_COLUMN_LEFT_TABLE} />
        </tr>
      ))}
      <RowNoData selectFullId={selectFullId} noDataText={noDataText} />
      {rowIndex.current.map((index) => (
        <Row
          key={index}
          indexTicker={index}
          columnIndex={columnIndex}
          selectColumnId={selectColumnId}
          selectTickerCell={selectTickerCell}
          selectId={selectId}
          cellInfo={cellInfo}
          selectColumnCell={selectColumnCell}
          customClassName={customClassName}
        />
      ))}
    </tbody>
  )
}

Tbody.propTypes = {
  maxRow: PropTypes.number.isRequired,
  columnIndex: PropTypes.array.isRequired,
  selectId: PropTypes.func.isRequired,
  selectColumnId: PropTypes.func.isRequired,
  selectTickerCell: PropTypes.func.isRequired,
  selectFullId: PropTypes.func.isRequired,
}

export default Tbody
