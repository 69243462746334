export const companySnapshot = {
  SETTING: 'Setting',
  COMPANY_OVERVIEW: 'Company Overview',
  COMPANY_PROFILE: 'Company Profile',
  COMPANY_POSITION: 'Company Position',
  BUSINESS_STRATEGIES: 'Business Strategies',
  STOCK_DATA: 'Stock Data',
  ONE_YEAR_PRICE_AND_VOLUME: '1-Year; Price & Volume',
  FINANCIAL_RATIOS: 'Financial Ratios',
  VALUATION_HISTORY: 'Valuation History',
  PEER_ANALYSIS_AND_FINANCIAL_DATA: 'Peer Analysis & Financial Data',
  PEER_ANALYSIS: 'Peer Analysis',
  FINANCIAL_DATA: 'Financial Data',
  YOUR_COMMENTARY_ON_EARNINGS: 'Your Commentary on Earnings',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE:
    'Type your commentary with less than 500 characters',
  OWNERSHIP_ANALYSIS: 'Ownership Analysis',
  MARKET_DATA: 'Market Data',
  PRICE_DATA: 'Price Data',
  STOCK_PERFORMANCE_VS_INDEX: 'Stock Performance vs. Index',
  PRICE_PERFORMANCE: 'Price Performance',
  FOREIGN_NET_BUYING_SELLING: 'Foreign Net Buying/Selling',
  FINANCIAL_STATEMENTS: 'Financial Statements',
  BALANCE_SHEET: 'Balance Sheet',
  INCOME_STATEMENT: 'Income Statement',
  PRICE_VND: 'Price (VND)',
  HIGHEST_52W: 'Highest 52W (VND)',
  LOWEST_52W: 'Lowest 52W (VND)',
  OUTSTANDING_SHARE: 'Outstanding Share (mn Shares)',
  PERCENT_FOREIGN_OWNED: '% Foreign Owned',
  FOREIGN_CURRENT_ROOM: 'Foreign Current Room (Shares)',
  MARKET_CAP_BILLION_VND: 'Marketcap (billion VND)',
  DIVIDEND_YIELD: 'Dividend Yield',
  SECTOR: 'Sector: ',
  TICKER: 'Ticker: ',
  EXCHANGE: 'Exchange: ',
  OUTSTANDING_SHARE_1: 'Outstanding Share (Shares): ',
  FREE_FLOAT_SHARE: 'Free-float (Shares): ',
  REPORT_DATE: 'Report Date: ',
  COMPARE_WITH_SECTOR: 'Compare with Sector',
  REVENUE_BREAKDOWN: 'Revenue breakdown',
  PRICE_PERFORMANCE_CHART: 'Price Performance Chart',
  REPORT_TYPE: 'Report Type',
  REPORT_FORM: 'Report Form',
  AUDIT_STATUS: 'Audit Status',
  CURRENCY: 'Currency',
  SELECT_REPORTING_PERIOD: 'Select Reporting Period',
  COMPANY: 'Company',
  BANK: 'Bank',
  SECURITIES: 'Securities',
  INSURANCE: 'Insurance',
  CONSOLIDATED: 'Consolidated',
  PARENT: 'Parent',
  FINANCIAL_STATEMENTS_SETTING: 'Financial Statements Setting',
  AUDITED: 'Audited',
  UNAUDITED: 'Unaudited',
  BOTH_AUDITED_AND_UNAUDITED: 'Both Audited and Unaudited',
  YEAR: 'Year',
  QUARTER: 'Quarter',
  BILLION_VND: 'Billion VND',
  MILLION_VND: 'Million VND',
  PE: 'P/E',
  PB: 'P/B',
  EP_VND: 'EPS (VND)',
  BVPS_VND: 'BVPS (VND)',
  GROSS_MARGIN: 'Gross Margin',
  EBIT_MARGIN: 'EBIT Margin',
  EBITDA_MARGIN: 'EBITDA Margin',
  NET_MARGIN: 'Net Margin',
  ROE: 'ROE',
  ROA: 'ROA',
  CURRENT_RATIO: 'Current Ratio',
  QUICK_RATIO: 'Quick Ratio',
  TOTAL_LIABILITIES_TOTAL_ASSETS: 'Total Liabilities/<br/>Total Assets',
  TOTAL_DEBTS_EQUITY: 'Total Debts/<br/>Equity',
  DISCLAIMER: 'Disclaimer',
  TEXT_DISCLAIMER:
    'Copyright ©2015 - 2022 FiinGroup Vietnam Corporation. All rights reserved. This report has been automatically exported from FiinProX Platform based on publicly available information, internally developed data and other sources believed to be reliable, but has not been independently verified by FiinGroup. FiinGroup makes no representations or warranties with respect to the accuracy, correctness or completeness of the information contained herein. This report is provided only for information purposes of FiinPro’s users, and does not constitute any offers or solicitation to buy or sell any securities discussed herein. Past performance, if any, is not guide to the future. Investors must make their investment decisions based upon independent opinions subject to their particular financial situations and investment objectives. This report may not be copied, reproduced, published or redistributed by any persons for any purposes without the written permission of an authorized representative of FiinGroup. Please cite FiinProX source when quoting.',
  CONTACT: 'Contact ',
  TEXT_CONTACT:
    'FIINGROUP JSC <br/> Head office: Level 10, Peakview Tower, 36 Hoang Cau St., O Cho Dua Ward, Dong Da Dist., Hanoi, Vietnam <br/> Branch office: Level 46, Bitexco Financial Tower, 2 Hai Trieu Street, Ben Nghe Ward, District 1, Ho Chi Minh, Vietnam <br/> Email: fiinpro@fiingroup.vn',
  TEXT_CONTACT1:
    'CUSTOMER SUPPORT CENTER <br/> T: (84-24) - 3562 6962 - Ext: 103 <br/> Email: fiinpro@fiingroup.vn',
  TABLE_TOP_5: 'Top 5 Major Share Holder & Board of Directors',
  KEY_FINANCIAL_DATA: 'Key Financial Data',
  REPORT_DATE_1: 'Report Date',
  TODAY_CHANGE: 'Today change',
  YTD_CHANGE: 'YTD change',
  AVG_DAILY_VOL_3M: 'Avg. Daily Vol. 3M (share) ',
  AVG_DAILY_VAL_3M: 'Avg. Daily Val. 3M (million VND) ',
  W_RANGE: '52W range',
  HIGHEST: 'Highest *',
  LOWEST: 'Lowest *',
  INCOME_STATEMENT_BILLION_VND: 'Income Statement - VNDbn',
  INCOME_STATEMENT_MILLION_VND: 'Income Statement - million VND',
  INCOME_STATEMENT_VND: 'Income Statement - VND',
  SALES: 'Sales',
  NET_SALES: 'Net sales',
  GROSS_PROFIT: 'Gross profit',
  FINANCIAL_EXPENSES: 'Financial expenses',
  OF_WHICH_INTEREST_EXPENSES: 'of which: interest expenses',
  GAIN_FORM_JOINT_VENTURE: 'Gain/(loss) form joint venture',
  SELLING_EXPENSES: 'Selling expenses',
  GENERAL_AND_ADMIN_EXPENSES: 'General and admin expenses',
  OPERARING_PROFIT: 'Operaring profit/(loss)',
  NET_ACCOUNTING_PROFIT: 'Net accounting profit/(loss) before tax',
  BUSINESS_INCOME_TAX_ENPENSES: 'Business income tax enpenses',
  MINORITY_INTERESTS: 'Minority interests',
  ATTRIBUTE_TO_PARENT_COMPANY: 'Attribute to parent company',
  CURRENT_ASSETS: 'CURRENT ASSETS',
  CASH_AND_CASCH_EQUIVALENTS: 'Cash and casch equivalents',
  SHORT_TERM_INVESTMENTS: 'Short-term investments',
  ACCOUNT_RECEIVABLE: 'Account receivable',
  INVENTORIES: 'Inventories',
  OTHER_CURRENT_ASSETS: 'Other current assets',
  LONG_TERM_ASSETS: 'LONG-TERM ASSETS',
  LONG_TERM_TRADE_RECEIVABLES: 'Long-term trade receivables',
  FIXED_ASSETS: 'Fixed assets',
  INVESTMENT_PROPERTIES: 'Investment properties',
  LONG_TERM_INCOMPLETE_ASSETS: 'Long-term incomplete assets',
  LONG_TERM_INVESTMENTS: 'Long-term investments',
  OTHER_LONG_TERM_ASSETS: 'Other long-term assets',
  GOODWILL: 'Goodwill',
  TOTAL_ASSETS: 'TOTAL ASSETS',
  LIABILITIES: 'LIABILITIES',
  CURRENT_LIABILITES: 'Current liabilites',
  TRADE_ACCOUNTS_PAYABLE: 'Trade accounts payable',
  SHORT_TERM_UNREALIZED_REVENUE: 'Short-term unrealized revenue',
  SHORT_TERM_BORROWINGS: 'Short-term borrowings',
  LONG_TERM_LIABILITES: 'Long-term liabilites',
  LONG_TERM_TRADE_PAYABLES: 'Long-term trade payables',
  UNREALIZED_REVENUE: 'Unrealized revenue',
  LONG_TERM_BORROWINGS: 'Long-term borrowings',
  OWNERS_EQUITY: "OWNER'S EQUITY",
  CAPITAL_AND_RESEARVES: 'Capital and researves',
  PAID_IN_CAPITAL: 'Paid-in capital',
  SHARE_PREMIUM: 'Share premium',
  OWNERS_OTHER_CAPITAL: "Owner's other capital",
  UNDISTRIBUTED_EARNINGS: 'Undistributed earnings',
  MINIORITY_INTERESTS: 'Miniority interests',
  BUDGET_SOURCES_AND_OTHER_FUNDS: 'Budget sources and other funds',
  TOTAL_RESOURCES: 'TOTAL RESOURCES',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE1: '*This commentary belongs to user (',
  YOUR_COMMENTARY_ON_EARNINGS_NOTE2:
    '). FiinGroup makes no representations or warranties with respect to the accuracy, correctness or completeness of the information contained herein.',
  PRICE: 'Price',
  VOLUME: 'Volume',
  PRICE1: '(Price)',
  PE1: 'P/E(Times)',
  NET_BUYING_VALUE: 'Net Buying Value',
  NET_SELLING_VALUE: 'Net Selling Value',
  BALANCE_SHEET1: 'Balance Sheet - VNDbn',
  LASTEST_OWNERSHIP: 'Lastest Ownership Structure',
  LASTEST_OWNERSHIP2: '(updated as of ',
  CURRENT_POSITION: 'Current position',
  SHARES: 'Shares(thousands)',
  SHARES_1: 'Volume',
  PERCENTAGE: 'Ownership',
  LAST_UPDATE: 'Last update',
  REVENUE_FROM_INSURANCE_PREMIUM: 'Revenue from insurance premium',
  GROSS_WRITTEN_PREMIUM: 'GROSS WRITTEN PREMIUM',
  REINSURANCE_PREMIUM_ASSUMED: 'REINSURANCE PREMIUM ASSUMED',
  INCREASE1:
    'Increase/(dcrease) in unearnde premium reserve for firect insurance and outward reinsurance',
  DEDUCTIONS: 'Deductions',
  INCREASE_IN_UNEARNED_PREMIUM:
    'Increase in unearned premium reserve and techinal resrve',
  NET_REVENUE_OF_INSURANCE_PREMIUM: 'Net revenue of insurance premium',
  COMMISSION_INCOME_FROM_OUTWARD:
    'Commission income from outward reinsurance and other income from insurance activities',
  COMMISSION_ON_REINSURANE_CEDED: 'Commission on reinsurane ceded',
  OTHER_INCOME: 'Other income',
  NET_SALES_FROM_INSURANCE_BUSINESS: 'Net sales from insurance business',
  CLAIM_AND_MATURITY_PAYMENT_EXPENSES: 'Claim and maturity payment expenses',
  CLAIM_EXPENSE_FOR_REINSURANCE_ASSUMED:
    'Claim expense for reinsurance assumed',
  CLAIM_RECOVERIES_FROM_OUTWARD_REINSURANCE:
    'Claim recoveries from outward reinsurance',
  CLAIM_EXPENSE_ON_RETAINED_RISKS: 'Claim expense on retained risks',
  INCREASE_MATHEMATIC_RESERVES: 'Increase/(decrease) in mathematic reserves',
  INCREASE_CLAIM_RESERVE: 'Increase/(decrease) in claim reserve',
  INCREASE_CLAIM_RESERVE_FOR_DIRECT_INSURANCE_AND_INWARD_INSURANCE:
    'Increase/(decrease) in claim reserve for direct insurance and inward insurance',
  INCREASE_IN_CLAIM_RESERVE_FOR_OUTWARD_INSURANCE:
    'Increase/(decrease) in claim reserve for outward insurance',
  TOTAL_INSURANCE_CLAIM_SETTLEMENT_EXPENSES:
    'Total insurance claim settlement expenses',
  CLAIM_EXPENSES_USING_CATASTROPHE_RESERVE:
    'Claim expenses using catastrophe reserve',
  PROVISION_FOR_CATASTROPHE_RESERVE: 'Provision for catastrophe reserve',
  OTHER_INSURANCE_OPRERATING_EXPENSES: 'Other insurance oprerating expenses',
  OTHER_UNDERWRITING_EXPENSES: 'Other underwriting expenses',
  OTHER_REINSURANCE_ASSUMED_EXPENSES: 'Other reinsurance assumed expenses',
  OTHER_REINSURANCE_CEDED_EXPENSES: 'Other reinsurance ceded expenses',
  OTHER_DIRECT_INSURANCE_OPERATING_EXPENSES:
    'Other direct insurance operating expenses',
  TOTAL_DIRECT_INSURANCE_OPERATING_EXPENSES:
    'Total direct insurance operating expenses',
  GROSS_INSURANCE_OPERATING_PROFIT: 'GROSS INSURANCE OPERATING PROFIT',
  NET_OPERATING_INCOME_FORM_BANKING_ACTIVITIES:
    'Net operating income form banking activities',
  NET_OPERATING_INCOME_FORM_OTHER_ACTIVITIES:
    'Net Operating income form other activities',
  SELLING_EXPENSE: 'Selling expense',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES: 'General and administrative expenses',
  NET_OPERATING_PROFIT_FROM_INSURANCE_OPERATION:
    'Net operating profit from insurance operation',
  NET_PROFIT_FORM_BANK_OPERATION: 'Net profit form bank operation',
  NET_PROFIT_FROM_OTHERS_OPERATION: 'Net profit from others operation',
  FINANCIAL_INCOME: 'Financial income',
  PROFIT_FROM_FINANCIAL_ACTIVITIES: 'Profit from financial activities',
  NET_OPERATING_INCOME_FROM_OTHER_ACTIVITIES:
    'Net operating income from other activities',
  ENTERPRISE_INCOME_TAX_FOR_THE_YEAR: 'Enterprise income tax for the year',
  PROFIT_AFTER_TAX: 'Profit after tax',
  NET_PROFIT_ATTRIBUTABLE_TO_SHAREHOLDERS_OF_THE_GROUP:
    'Net profit attributable to shareholders of the group',
  REINSURANCE_ASSETS: 'Reinsurance assets',
  LOANS_AND_ADVANCES_TO_CUSTOMERS: 'LOANS AND ADVANCES TO CUSTOMERS',
  CONSTRUCTION_IN_PROGRESS: 'Construction in progress',
  SHORT_TERM_DEBTS_AND_BORROWINGS: 'Short-term debts and borrowings',
  ADVANCES_FROM_CUSTOMERS: 'Advances from customers',
  PROFIT_BEFORE_TAX: 'Profit before tax',
  AMOUNT_DUE_TO_CUSTOMERS: 'Amount due to customers',
  INSURANCE_RESERVE: 'Insurance reserve',
  LONG_TERM_BORROWINGS_AND_LIABILITIES: 'Long-term borrowings and liabilities',
  LONG_TERM_ADVANCES_FROM_CUSTOMERS: 'Long-term advances from customers',
  DEFFERED_REVENUE: 'Deffered revenue',
  EQUITY: 'Equity',
  SHARE_CAPITAL: 'Share capital',
  ADDITIONAL_PAID_IN_CAPITAL: 'Additional Paid-in capital',
  OTHER_RESERVES: 'Other reserves',
  OTHER_CAPITAL_FUNDS: 'Other capital funds',
  LIABILITIES_AND_SHAREHOLDERS_EQUITIES:
    "LIABILITIES AND SHAREHOLDERS'EQUITIES",
  INTEREST_AND_SIMILAR_INCOME: 'Interest and Similar Income',
  INTEREST_AND_SIMILAR_EXPENSES: 'Interest and Similar Expenses',
  NET_INTEREST_INCOME: 'Net Interest Income',
  NET_FEE_AND_COMMISION_INCOME: 'Net fee and commision income',
  NET_GAIN_FROM_FOREIGN_CURRENCY_AND_GOLD_DEALINGS:
    'Net gain/(loss) from foreign currency and gold dealings',
  NET_GAIN_FROM_TRADING_OF_TRADING_SECURITIES:
    'Net gain/(loss) from trading of trading securities',
  NET_GAIN_FROM_TRADING_OF_INVESTMENT_SECURITIES:
    'Net gain/(loss) from trading of investment securities',
  NET_OTHER_INCOME: 'Net other income/(expenses)',
  DIVIDENDS_INCOME: 'Dividends income',
  TOTAL_OPERAING_INCOME: 'Total operaing income',
  OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES:
    'Operating Profit Before Provision for Credit Losses',
  PROVISION_FOR_CREDIT_LOSSES: 'Provision for credit losses',
  BUSINESS_INCOME_TAX_: 'Business income tax',
  NET_PROFIT_AFTER_TAX: 'Net profit/(loss) after tax',
  CASH_AND_PRECIOUS_METALS: 'Cash and Precious Metals',
  BALANCES_WITH_THE_SBV: 'Balances with the SBV	',
  PLACEMENTS_WITH_AND_LOANS_TO_OTHER_CREDIT_INSTITUTIONS:
    'Placements with and loans to other credit institutions',
  TRADING_SECURITIES_NET: 'Trading Securities, net',
  DERIVATIVES_AND_OTHER_FINANCIAL_ASSETS:
    'Derivatives and other financial assets',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET: 'Loans and advances to customers, net',
  INVESTMENT_SECURITIES: 'Investment securities',
  INVESTMENT_IN_OTHER_ENTITIES_AND_LONG_TERM_INVESTMENTS:
    'Investment in other entities and long term investments',
  OTHER_ASSETS: 'Other Assets',
  LIABILITITES_AND_SHAREHOLDERS_EQUITY: "Liabilitites and Shareholder's Equity",
  TOTAL_LIABILITIES: 'Total Liabilities',
  DUE_TO_GOV_AND_BORROWING_FROM_SBV: 'Due to Gov and borrowing from SBV',
  DEPOSITS_AND_BORROWINGS_FROM_OTHER_CREDIT_INSTITUTIONS_:
    'Deposits and borrowings from other credit institutions',
  DEPOSITS_FROM_CUSTOMERS: 'Deposits from customers',
  DERIVATIVES_AND_OTHER_FINANCIAL_LIABILITIES:
    'Derivatives and other financial liabilities',
  FUNDS_RECEVIED_FROM_GOV_INTERNATIONAL_AND_OTHER_INSTITUTIONS:
    'Funds recevied from Gov, international and other institutions',
  CONVERTIBLE_BONDS_CDS_AND_OTHER_VALUABLE_PAPERS_ISSUED:
    'Convertible bonds/CDs and other valuable papers issued',
  OTHER_LIABILITIES: 'Other liabilities',
  SHAREHOLDERS_EQUITY: "Shareholder's Equity",
  CAPITAL: 'Capital',
  FUND_FOR_BASIC_CONSTRUCTION: 'Fund for basic construction',
  CAPITAL_SURPLUS: 'Capital surplus',
  TREASURY_SHARES: 'Treasury shares',
  PREFERRED_SHARES: 'Preferred shares',
  RETAINED_EARNINGS: 'Retained Earnings',
  REVENUE: 'REVENUE',
  REVENUE_IN_BROKERAGE_SERVICES: 'Revenue in Brokerage services',
  REVENUE_IN_SECURITIES_INVESTMENT_AND_OTHER_INVESTMENT_ACTIVITIES:
    'Revenue in Securities investment and other investment activities',
  REVENUE_IN_UNDERWRITING_SERVICES: 'Revenue in Underwriting services',
  REVENUE_IN_ISSUANCE_AGENCY_SERVICES: 'Revenue in Issuance agency services',
  REVENUE_IN_ADVISORY_SERVICES: 'Revenue in Advisory services',
  REVENUE_IN_SECURITIES_CUSTODY_SERVICES:
    'Revenue in Securities custody services',
  REVENUE_IN_AUCTION_TRUST_SERVICES: 'Revenue in Auction trust services',
  RENTAL_INCOME_FROM_INVESTMENT_PROPERTY:
    'Rental income from investment property',
  OTHERS_REVENUE: 'Others revenue',
  NET_SALES_AND_SERVICES: 'Net sales and services',
  COST_OF_SALES: 'Cost of sales',
  GROSS_PROFIT_FROM_OPERATING_ACTIVITIES:
    'GROSS PROFIT/(LOSS) FROM OPERATING ACTIVITIES',
  GAIN_FROM_JOINT_VENTURES: 'Gain/(loss) from joint ventures',
  NET_PROFIT_FROM_OPERATING_ACTIVITIES:
    'NET PROFIT/(LOSS) FROM OPERATING ACTIVITIES',
  OTHER_PROFIT: 'Other profit',
  SHARES_OF_PROFIT_IN_ASSOCIATE:
    'SHARES OF PROFIT/(LOSS) IN ASSOCIATES, JOINT-VENTURES',
  BUSINESS_INCOME_TAX: 'Business Income Tax',
  EQUITY_HOLDERS_OF_THE_PARENT: 'EQUITY HOLDERS OF THE PARENT',
  OWNERS_EQUITY_: "OWNER'S EQUITY",
  MARKET_CAP: 'Market Cap (billion VND)',
  TOTAL_ASSETS1: 'Total Assets (billion VND)',
  NET_PROFIT_GROWTH: 'Net Profit Growth',
  OWNERS_EQUITY1: "Owner's Equity",
  TOTAL_ASSETS2: 'Total Assets',
  NET_PROFIT: 'Net Profit',
  EPS: 'EPS (VND)',
  DIVIDEND: 'Dividend (VND)',
  REPORT_DATE1: 'Report Date',
  NAME: 'Name',
  MY_TEMPLATES: 'My Templates',
  SAVE_TEMPLATE: 'Save Template',
  UPDATE_CURRENT_TEMPLATE: 'Update Current Template',
  CANCEL: 'Cancel',
  APPLY: 'Apply',
  DELETE: 'Delete',
  TEXT_TOOLTIP:
    'Depends on each company data, <i>Revenue breakdown</i> can be unavailable. You may choose <i>Compare with Sector</i> instead.',
  TOTAL_SHARES: 'Total Shares',
  PERIOD: 'Period',
  RANGE: 'Range',
  ONE_WEEK: '1 Week',
  ONE_MONTH: '1 Month',
  THREE_MONTHS: '3 Months',
  SIX_MONTHS: '6 Months',
  ONE_YEAR: '1 Year',
  YTD: 'YTD',
  ALL: 'All',
  DELETE_TEMPLATE: 'Delete template',
  TEXT_DELETE_TEMPLATE: 'Are you sure you want to delete the ',
  TEXT_DELETE_TEMPLATE1: ' form ?',
  PREVIEW: 'Preview',
  SELECT_TIME_RANGE: 'Select Time Range',
  COMPANY_SNAPSHOT: 'Company Snapshot',
  SETTING1: 'SETTING',
  PEER_ANALYSIS_SETTING: 'Peer Analysis Setting',
  TEXT_WARNING_ADD_ITEM: 'You can select maximum 3 tickers.',
  ALL_EXCHANGE: 'All Exchanges',
  MESS_NAME_EXISTED: 'The template has already existed.',
  MESS_NAME_ENTER: 'Please enter the template name.',
  ADJUSTED_PRICE: '* Adjusted Price',
  SAVE: 'Save',
  RESET: 'Reset',
  PERIOD_LABEL: 'Period',
  RANGE_LABEL: 'Range',
  STATE_OWNED: 'State-owned',
  FOREIGN_OWNED: 'Foreign-Owned',
  OTHER: 'Other',
  VND_MILLION: 'VND Million',
  EXPORT: 'Export',
  NAME_FILE_DOWNLOAD: 'FiinProX_Company Snapshot_',
  SAVE_AS: 'Save as',
  MAJOR_SHAREHOLDER_OWNERSHIP: 'Major Shareholder Ownership',
  DIRECTOR_AND_RELATED_PERSON_OWNERSHIP:
    'Director and related person ownership',
  MAJOR_SHAREHOLDER_OWNERSHIP1: 'Major Shareholder',
  DIRECTOR_AND_RELATED_PERSON_OWNERSHIP1: 'Management Board',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1:
    'Loans and advances to customers, net (billion VND) ',
  NET_INTEREST_INCOME_1: 'Net Interest Income',
  NET_INTEREST_MARGIN: 'Net Interest Margin (%)',
  NON_INTEREST_INCOME: 'Non-interest income/ Net interest income (%)',
  ROA_PRE_PROVISION: 'ROA pre Provision (%)',
  LOAN_LOS_REVERVES_LOANS: ' Loan-los reverves/ Loans (%)',
  LOANS_DEPOSIT_RATIO: 'Loans/Deposit Ratio (%)',
  TOTAL_DEBTS: 'Total Debts/Equity(%)',
  CIR: 'CIR (%)',
  NPL: 'NPL (%)',
  NOTE_FINANCIAL_DATA_CHART:
    "*FiinGroup consensus is calculated from broker's views and FiinGroup Research",
  VNDBN: 'VNDbn',
  LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_2: 'Loans/Deposit Ratio (%)',
  SAVE_TEMPLATE_SUCCESS: 'Your template has been successfully created',
  BALANCE_SHEET1_BILLION_VND: 'Balance Sheet - VNDbn',
  BALANCE_SHEET1_MILLION_VND: 'Balance Sheet - million VND',
  BALANCE_SHEET1_VND: 'Balance Sheet - VND',
  YEAR1: '* Year: ',
  DEDUCTIONS1: 'Deductions',
  MESS_WARNING:
    ' is currently unlisted public company and thus its trading data is not available for relative performance with its listed peers',
}
