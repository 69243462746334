import moment from 'moment'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { TIME_FREQUENCY } from '../../chart/constants'
import { TIME_RANGE } from '../constants'

export const getChartNameBySampleName = (arrName = [], sampleName) => {
  const arrNumberName = arrName
    .filter((item) => item && item.includes(sampleName))
    .map((i) => Number(i.slice(sampleName.length)))
    .sort((a, b) => a - b)

  var missing = []
  for (var i = 1; i <= arrName.length; i++) {
    if (arrNumberName.indexOf(i) === -1) {
      missing.push(i)
    }
  }

  return (
    sampleName +
    (missing[0] || (arrNumberName[arrNumberName.length - 1] || 0) + 1)
  )
}

export const getChartOrderByListChart = (charts = []) => {
  let maxOrder = 0
  charts.forEach((chart) => {
    if (chart.order > maxOrder) {
      maxOrder = chart.order
    }
  })
  return maxOrder + 1
}

export const getChartTimeRange = (timeRange, timeRangeDate) => {
  if (!timeRangeDate?.dateFrom && !timeRangeDate?.dateTo) {
    return timeRange
  }

  if (!timeRangeDate.dateFrom && timeRangeDate.dateTo) {
    return TIME_RANGE.ALL_TIME
  }

  const dateFrom = moment(timeRangeDate.dateFrom || new Date())
    .clone()
    .startOf('day')
  const dateTo = moment(timeRangeDate.dateTo || new Date())
    .clone()
    .startOf('day')
  const rangeDate = Math.abs(dateFrom.diff(dateTo, 'days'))

  const MONTH_DAYS = 30

  switch (true) {
    case rangeDate > MONTH_DAYS * 6 && rangeDate <= MONTH_DAYS * 36:
      return TIME_RANGE.THREE_YEARS
    case rangeDate > MONTH_DAYS * 36:
      return TIME_RANGE.ALL_TIME
    default:
      return TIME_RANGE.SIX_MONTHS
  }
}

export const getXAxisTickGroupByYearFormatByTime = (
  timeRange,
  timeFrequency,
  data,
  timeZone,
  locale,
) => {
  switch (timeFrequency) {
    case TIME_FREQUENCY.DAILY:
    case TIME_FREQUENCY.WEEKLY: {
      switch (timeRange) {
        case TIME_RANGES.MTD:
        case TIME_RANGES.QTD:
        case TIME_RANGES.YTD:
        case TIME_RANGES['3M']:
        case TIME_RANGES['6M']: {
          return getXAxisTickByDaily(
            data,
            timeZone,
            locale,
            FORMAT.MONTH_YEAR_NUMBER,
          )
        }
        case TIME_RANGES['1Y']:
        case TIME_RANGES['3Y']: {
          return getXAxisTickByDaily(
            data,
            timeZone,
            locale,
            FORMAT.QUARTER_YEAR,
          )
        }
        case TIME_RANGES['5Y']:
        case TIME_RANGES.All: {
          return getXAxisTickByDaily(data, timeZone, locale, FORMAT.YEAR)
        }
        default: {
          break
        }
      }
      return {}
    }
    case TIME_FREQUENCY.MONTHLY: {
      switch (timeRange) {
        case TIME_RANGES.MTD:
        case TIME_RANGES.QTD:
        case TIME_RANGES.YTD:
        case TIME_RANGES['3M']:
        case TIME_RANGES['6M']:
        case TIME_RANGES['1Y']:
        case TIME_RANGES['3Y']: {
          return getXAxisTickByMonthlyTimeRange3Y(data, timeZone, locale)
        }
        case TIME_RANGES['5Y']: {
          return getXAxisTickByMonthlyTimeRange5Y(data, timeZone, locale)
        }
        case TIME_RANGES.All: {
          const countYear = data.reduce((arr, item) => {
            const year = formatDateTime(
              item.date,
              FORMAT.YEAR,
              locale,
              timeZone,
            )
            return !arr.includes(year) ? [...arr, year] : arr
          }, []).length

          if (countYear <= 3) {
            return getXAxisTickByMonthlyTimeRange3Y(data, timeZone, locale)
          } else if (countYear <= 5) {
            return getXAxisTickByMonthlyTimeRange5Y(data, timeZone, locale)
          } else {
            return getXAxisTickByMonthlyTimeRangeAll(data, timeZone, locale)
          }
        }
        default: {
          break
        }
      }
      return {}
    }
    case TIME_FREQUENCY.QUARTERLY: {
      switch (timeRange) {
        case TIME_RANGES.MTD:
        case TIME_RANGES.QTD:
        case TIME_RANGES.YTD:
        case TIME_RANGES['3M']:
        case TIME_RANGES['6M']:
        case TIME_RANGES['1Y']:
        case TIME_RANGES['3Y']:
        case TIME_RANGES['5Y']: {
          return getXAxisTickByQuarterlyTimeRange5Y(data, timeZone, locale)
        }
        case TIME_RANGES.All: {
          const countYear = data.reduce((arr, item) => {
            const year = formatDateTime(
              item.date,
              FORMAT.YEAR,
              locale,
              timeZone,
            )
            return !arr.includes(year) ? [...arr, year] : arr
          }, []).length

          if (countYear <= 5) {
            return getXAxisTickByQuarterlyTimeRange5Y(data, timeZone, locale)
          } else {
            return getXAxisTickByQuarterlyTimeRangeAll(data, timeZone, locale)
          }
        }
        default: {
          break
        }
      }
      return {}
    }
    case TIME_FREQUENCY.YEARLY: {
      return getXAxisTickByYearly(data, timeZone, locale)
    }
    default:
      return {}
  }
}

const getXAxisTickByDaily = (data, timeZone, locale, compareFormat) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))
  let compareDate = formatDateTime(
    dataSortAsc[0].date,
    compareFormat,
    locale,
    timeZone,
  )
  let dataByDate = []

  dataSortAsc.forEach((item, index) => {
    const date = formatDateTime(item.date, compareFormat, locale, timeZone)
    if (date === compareDate && index < dataSortAsc.length - 1) {
      dataByDate.push(item)
    } else {
      if (index === dataSortAsc.length - 1) {
        if (date === compareDate) {
          dataByDate.push(item)
        } else {
          const tickByDate = dataByDate[Math.floor(dataByDate.length / 2)]
          ticks.push(dataByDate[0].date)
          ticks.push(tickByDate.date)
          ticks.push(item.date)
          return
        }
      }

      const tickByDate = dataByDate[Math.floor(dataByDate.length / 2)]
      ticks.push(dataByDate[0].date)
      ticks.push(tickByDate.date)
      compareDate = date
      dataByDate = [item]
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.DAY_MONTH_TH, locale, timeZone),
  }
}

const getXAxisTickByMonthlyTimeRange3Y = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))
  let compareMonth = formatDateTime(
    dataSortAsc[0].date,
    FORMAT.MONTH_YEAR_NUMBER,
    locale,
    timeZone,
  )
  let dataByMonth = []

  dataSortAsc.forEach((item, index) => {
    const month = formatDateTime(
      item.date,
      FORMAT.MONTH_YEAR_NUMBER,
      locale,
      timeZone,
    )
    if (month === compareMonth && index < dataSortAsc.length - 1) {
      dataByMonth.push(item)
    } else {
      if (index === dataSortAsc.length - 1) {
        if (month === compareMonth) {
          dataByMonth.push(item)
        } else {
          const tickByMonth = dataByMonth[Math.floor(dataByMonth.length / 2)]
          ticks.push(tickByMonth.date)
          ticks.push(item.date)
          return
        }
      }
      const tickByMonth = dataByMonth[Math.floor(dataByMonth.length / 2)]
      ticks.push(tickByMonth.date)
      compareMonth = month
      dataByMonth = [item]
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.MONTH_M, locale, timeZone),
  }
}

const getXAxisTickByMonthlyTimeRange5Y = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))
  const isMonthEven = checkDataDateEven(
    dataSortAsc[0],
    FORMAT.MONTH_NUMBER,
    timeZone,
    locale,
  )
  let compareMonth = formatDateTime(
    dataSortAsc[0].date,
    FORMAT.MONTH_YEAR_NUMBER,
    locale,
    timeZone,
  )
  let dataByMonth = []

  dataSortAsc.forEach((item, index) => {
    const isItemMonthEven = checkDataDateEven(
      item,
      FORMAT.MONTH_NUMBER,
      timeZone,
      locale,
    )
    if (isMonthEven === isItemMonthEven) {
      const month = formatDateTime(
        item.date,
        FORMAT.MONTH_YEAR_NUMBER,
        locale,
        timeZone,
      )
      if (month === compareMonth && index < dataSortAsc.length - 1) {
        dataByMonth.push(item)
      } else {
        if (index === dataSortAsc.length - 1) {
          if (month === compareMonth) {
            dataByMonth.push(item)
          } else {
            const tickByMonth = dataByMonth[Math.floor(dataByMonth.length / 2)]
            ticks.push(tickByMonth.date)
            ticks.push(item.date)
            return
          }
        }
        const tickByMonth = dataByMonth[Math.floor(dataByMonth.length / 2)]
        ticks.push(tickByMonth.date)
        compareMonth = month
        dataByMonth = [item]
      }
    } else {
      if (index === dataSortAsc.length - 1) {
        const tickByMonth = dataByMonth[Math.floor(dataByMonth.length / 2)]
        ticks.push(tickByMonth.date)
      }
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.MONTH_M, locale, timeZone),
  }
}

const getXAxisTickByMonthlyTimeRangeAll = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))

  dataSortAsc.forEach((item) => {
    const month = formatDateTime(
      item.date,
      FORMAT.MONTH_NUMBER,
      locale,
      timeZone,
    )

    if ([2, 6, 10].includes(+month)) {
      ticks.push(item.date)
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.MONTH_M, locale, timeZone),
  }
}

const getXAxisTickByQuarterlyTimeRange5Y = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))
  let compareQuarter = formatDateTime(
    dataSortAsc[0].date,
    FORMAT.QUARTER_YEAR,
    locale,
    timeZone,
  )
  let dataByQuarter = []

  dataSortAsc.forEach((item, index) => {
    const quarter = formatDateTime(
      item.date,
      FORMAT.QUARTER_YEAR,
      locale,
      timeZone,
    )
    if (quarter === compareQuarter && index < dataSortAsc.length - 1) {
      dataByQuarter.push(item)
    } else {
      if (index === dataSortAsc.length - 1) {
        if (quarter === compareQuarter) {
          dataByQuarter.push(item)
        } else {
          const tickByQuarter =
            dataByQuarter[Math.floor(dataByQuarter.length / 2)]
          ticks.push(tickByQuarter.date)
          ticks.push(item.date)
          return
        }
      }
      const tickByQuarter = dataByQuarter[Math.floor(dataByQuarter.length / 2)]
      ticks.push(tickByQuarter.date)
      compareQuarter = quarter
      dataByQuarter = [item]
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.QUARTER_Q, locale, timeZone),
  }
}

const getXAxisTickByQuarterlyTimeRangeAll = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))
  const isQuarterEven = checkDataDateEven(
    dataSortAsc[0],
    FORMAT.QUARTER,
    timeZone,
    locale,
  )
  let compareQuarter = formatDateTime(
    dataSortAsc[0].date,
    FORMAT.QUARTER_YEAR,
    locale,
    timeZone,
  )
  let dataByQuarter = []

  dataSortAsc.forEach((item, index) => {
    const isItemQuarterEven = checkDataDateEven(
      item,
      FORMAT.QUARTER,
      timeZone,
      locale,
    )
    if (isQuarterEven === isItemQuarterEven) {
      const quarter = formatDateTime(
        item.date,
        FORMAT.QUARTER_YEAR,
        locale,
        timeZone,
      )
      if (quarter === compareQuarter && index < dataSortAsc.length - 1) {
        dataByQuarter.push(item)
      } else {
        if (index === dataSortAsc.length - 1) {
          if (quarter === compareQuarter) {
            dataByQuarter.push(item)
          } else {
            const tickByQuarter =
              dataByQuarter[Math.floor(dataByQuarter.length / 2)]
            ticks.push(tickByQuarter.date)
            ticks.push(item.date)
            return
          }
        }
        const tickByQuarter =
          dataByQuarter[Math.floor(dataByQuarter.length / 2)]
        ticks.push(tickByQuarter.date)
        compareQuarter = quarter
        dataByQuarter = [item]
      }
    } else {
      if (index === dataSortAsc.length - 1) {
        const tickByQuarter =
          dataByQuarter[Math.floor(dataByQuarter.length / 2)]
        ticks.push(tickByQuarter.date)
      }
    }
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.QUARTER_Q, locale, timeZone),
  }
}

const getXAxisTickByYearly = (data, timeZone, locale) => {
  const ticks = []
  const dataSortAsc = [...data].sort((a, b) => a.date.localeCompare(b.date))

  dataSortAsc.forEach((item) => {
    ticks.push(item.date)
  })

  return {
    ticks,
    tickFormatter: (d) =>
      formatDateTime(new Date(d), FORMAT.YEAR, locale, timeZone),
  }
}

const checkDataDateEven = (item, format, timeZone, locale) => {
  const formatNumber = +formatDateTime(item.date, format, locale, timeZone)
  return formatNumber && formatNumber % 2 === 0
}
