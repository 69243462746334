import { createAsyncThunk } from '@reduxjs/toolkit'
import StateBudgetService from '../../../../../../core/services/economy/fiscal/StateBudgetService'

export const getStateBudgetBalanceChart = createAsyncThunk(
  'economy/fiscal/stateBudget/getStateBudgetBalanceChart',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getStateBudgetBalanceChart(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getStateBudgetRevenueChart = createAsyncThunk(
  'economy/fiscal/stateBudget/getStateBudgetRevenueChart',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getStateBudgetRevenueChart(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getStateBudgetExpenditureChart = createAsyncThunk(
  'economy/fiscal/stateBudget/getStateBudgetExpenditureChart',
  async (params, { rejectWithValue }) => {
    const response = await StateBudgetService.getStateBudgetExpenditureChart(
      params,
    )
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
