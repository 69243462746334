import { ServiceProxyConfig } from './../../configs/ServiceProxy'
import ProxyBase from './ProxyBase'

class UserSettingProxy extends ProxyBase {
  getGeneralInfo(params) {
    return this.get('GetGeneralInfo', params)
  }

  getProfile(params) {
    return this.get('GetProfile', params)
  }

  updateProfile(params) {
    return this.post('InsertOrUpdate', params)
  }

  updateFafouriteContact(params) {
    return this.post('UpdateFafouriteContact', params)
  }
}

export default new UserSettingProxy(ServiceProxyConfig.UserSetting.ServiceUrl)
