const colorsPieChart = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#EE7067',
  '#FFD365',
  '#18975D',
  '#F98E30',
  '#F1C594',
]

export const formatChartData = ({
  data,
  datakey,
  dataName,
  currentTicker,
  top = 5,
}) => {
  let sortedData = [...data].sort((a, b) => b[datakey] - a[datakey])
  if (currentTicker) {
    const currentDataIndex = sortedData.findIndex(
      (item) => item.ticker === currentTicker,
    )
    if (currentDataIndex !== -1) {
      const [currentData] = sortedData.splice(currentDataIndex, 1)
      sortedData.unshift(currentData)
    }
  }
  const topData = sortedData.slice(0, top)

  const result = topData.map((item, index) => ({
    text: item[dataName],
    value: item[datakey],
    color: colorsPieChart[index],
  }))

  if (topData.length === data.length) return result

  const otherRatio =
    1 - topData.reduce((total, item) => total + item[datakey], 0)

  return [
    ...result,
    {
      text: 'corporate.businessModel.penetrationAnalysis.OTHERS',
      isI18n: true,
      value: otherRatio,
      color: '#ecffdf',
    },
  ]
}

export const getSortedDataColumnChart = (data, currentTicker) => {
  const currentDataIndex = data.findIndex(
    (item) => item?.ticker === currentTicker,
  )
  if (currentDataIndex !== -1) {
    const dataClone = [...data]
    dataClone.splice(currentDataIndex, 1)
    return [data[currentDataIndex], ...dataClone]
  }
  return data
}

export const getYearQuarter = (datetime) => {
  const date = new Date(datetime)
  const quarter = Math.floor((date.getMonth() + 3) / 3)
  const year = date.getFullYear()

  return { year, quarter }
}

const QUARTER_TIME = 24 * 60 * 60 * 1000 * 90 // 90 day
export const getLatestQuarters = (count) => {
  return Array.from(Array(count)).map((_, index) => {
    const datetime = new Date(new Date().getTime() - index * QUARTER_TIME)
    return getYearQuarter(datetime)
  })
}

export const getLatestYears = (count) => {
  const currentYear = new Date().getFullYear()
  return Array.from(Array(count)).map((_, index) => {
    return currentYear - index
  })
}
