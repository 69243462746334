import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { PRICE_DATA_TIME_SELECT } from '../constants'
import { TAB_TYPE } from '../table/constants'
import {
  getCheckboxChecked,
  getLineChartColor,
  getRadioValuePPI,
  getTableDataLoading,
  getTitleNameLoading,
  getTitleWithKey,
} from '../table/generalIndex/store/slice'
import {
  selectIdIIC,
  selectListSelectRowIIC,
  selectLoadingTableIIC,
} from '../table/iic/store/slice'
import {
  selectIdIII,
  selectListSelectRowIII,
  selectLoadingTableIII,
} from '../table/iii/store/slice'
import {
  selectIdIIP,
  selectListSelectRowIIP,
  selectLoadingTableIIP,
} from '../table/iip/store/slice'
import {
  getYearlyQuarterValue,
  selectTypeDataTime,
  selectTypePeriod,
} from '../table/store/slice'
import { ChartComponent } from './ChartComponent'
import TimeRange from './TimeRange'
import { PRODUCTION_PRICE, TRANSPORT_WARE_HOUSE } from './constants'
import { getIdsByCType, handleTitleXAxis } from './helper'
import ChartComponentPPI from './ppiChartComponent.js/ChartComponentPPI'
import {
  getPPIData,
  getPPILoading,
  getTimeRangeAcc,
  getTimeRangeYearly,
  selectDataChart,
  selectLoadingChartGrowth,
} from './store/slice'
import {
  getGrowthByTimeRange,
  growthIICChart,
  growthIIIChart,
  growthIIPChart,
} from './store/thunk'

export const GrowthSector = ({ width, height, activeTab, isFullScreen }) => {
  const dispatch = useDispatch()
  const { GENERAL_INDEX, IIP, IIC, III } = TAB_TYPE
  const { YEARLY, MONTHLY } = PRICE_DATA_TIME_SELECT

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoadingChartGrowth)
  const listSelectedIIP = useSelector(selectListSelectRowIIP)
  const idsSelect = useSelector(selectIdIIP)
  const listSelectedIIC = useSelector(selectListSelectRowIIC)
  const idsSelectIIC = useSelector(selectIdIIC)
  const listSelectedIII = useSelector(selectListSelectRowIII)
  const idsSelectIII = useSelector(selectIdIII)
  const typePeriod = useSelector(selectTypePeriod)
  const radioValuePPI = useSelector(getRadioValuePPI)
  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const typeDataTime = useSelector(selectTypeDataTime)
  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeAcc = useSelector(getTimeRangeAcc)
  const PPILoading = useSelector(getPPILoading)
  const PPIData = useSelector(getPPIData)
  const PPIChecked = useSelector(getCheckboxChecked)
  const locale = useSelector((state) => state.i18n.locale)
  const PPIIdWithTitle = useSelector(getTitleWithKey)
  const lineChartColor = useSelector(getLineChartColor)
  const loadingTableIIP = useSelector(selectLoadingTableIIP)
  const loadingTableIIC = useSelector(selectLoadingTableIIC)
  const loadingTableIII = useSelector(selectLoadingTableIII)
  const loadingTablePPI = useSelector(getTableDataLoading)
  const loadingPPITitle = useSelector(getTitleNameLoading)

  const getIdsInListSelectIIP = listSelectedIIP?.map((item) => item.iivnTypeId)
  const getIdsInListSelectIIC = listSelectedIIC?.map((item) => item.iivnTypeId)
  const getIdsInListSelectIII = listSelectedIII?.map((item) => item.iivnTypeId)

  const loading = activeTab === GENERAL_INDEX ? PPILoading : isLoading
  const currentChartData = activeTab === GENERAL_INDEX ? PPIData : dataChart

  let listIds = []
  let listListDataSelect = []
  let listIdsSelect = []

  switch (activeTab) {
    case IIP:
      listIds = idsSelect
      listListDataSelect = listSelectedIIP
      listIdsSelect = getIdsInListSelectIIP
      break
    case IIC:
      listIds = idsSelectIIC
      listListDataSelect = listSelectedIIC
      listIdsSelect = getIdsInListSelectIIC
      break
    case III:
      listIds = idsSelectIII
      listListDataSelect = listSelectedIII
      listIdsSelect = getIdsInListSelectIII
      break
    case GENERAL_INDEX:
      break
    default:
      listIds = []
      listListDataSelect = []
      listIdsSelect = []
  }

  useEffect(() => {
    if (activeTab === GENERAL_INDEX && !loadingTablePPI && !loadingPPITitle) {
      dispatch(
        getGrowthByTimeRange({
          FrequencyType: radioValuePPI,
          DataType: yearlyQuarterValue,
          TimeRange:
            yearlyQuarterValue === YEARLY ? timeRangeYearly : timeRangeAcc,
          ProducerPriceIds: getIdsByCType(PRODUCTION_PRICE, PPIChecked),
          TransportWarehouseIds: getIdsByCType(
            TRANSPORT_WARE_HOUSE,
            PPIChecked,
          ),
        }),
      )
    }
  }, [
    radioValuePPI,
    yearlyQuarterValue,
    activeTab,
    timeRangeYearly,
    timeRangeAcc,
    PPIChecked,
    locale,
    loadingTablePPI,
    loadingPPITitle,
  ])

  useEffect(() => {
    if (activeTab === IIP && !loadingTableIIP) {
      dispatch(
        growthIIPChart({
          FrequencyType: typePeriod,
          DataType: typeDataTime,
          TimeRange: typeDataTime === YEARLY ? timeRangeYearly : timeRangeAcc,
        }),
      )
    }
  }, [
    locale,
    activeTab,
    typePeriod,
    typeDataTime,
    timeRangeYearly,
    timeRangeAcc,
  ])

  useEffect(() => {
    if (activeTab === IIC && !loadingTableIIC) {
      dispatch(
        growthIICChart({
          FrequencyType: typePeriod,
          DataType: typeDataTime,
          TimeRange: typeDataTime === YEARLY ? timeRangeYearly : timeRangeAcc,
        }),
      )
    }
  }, [
    locale,
    activeTab,
    typePeriod,
    typeDataTime,
    timeRangeYearly,
    timeRangeAcc,
  ])

  useEffect(() => {
    if (activeTab === III && !loadingTableIII) {
      dispatch(
        growthIIIChart({
          FrequencyType: typePeriod,
          DataType: MONTHLY,
          TimeRange: timeRangeAcc,
        }),
      )
    }
  }, [locale, activeTab, typePeriod, typeDataTime, timeRangeAcc])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <TimeRange
              headerTab={activeTab}
              yearlyQuarterValue={yearlyQuarterValue}
              typeDataTime={typeDataTime}
            />
            {typeof sizes.height === 'number' && (
              <>
                {loading && <Loading />}
                {!loading && (
                  <>
                    {currentChartData.length === 0 && <NoData />}
                    {currentChartData.length !== 0 && (
                      <>
                        {activeTab === GENERAL_INDEX ? (
                          <ChartComponentPPI
                            data={PPIData}
                            width={width}
                            height={height - sizes.height}
                            keyXAxis={'time'}
                            keyYAxisProp={PPIChecked}
                            idWithTitle={PPIIdWithTitle}
                            lineChartColor={lineChartColor}
                            isFullScreen={isFullScreen}
                          />
                        ) : (
                          <ChartComponent
                            data={handleTitleXAxis(
                              dataChart,
                              locale,
                              typeDataTime,
                            )}
                            width={width}
                            height={height - sizes.height}
                            keyXAxis={typeDataTime === YEARLY ? 'year' : 'time'}
                            lineKeys={listIdsSelect}
                            idsSelect={listIds}
                            listItemSelect={listListDataSelect}
                            isFullScreen={isFullScreen}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

GrowthSector.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  activeTab: PropTypes.string,
  isFullScreen: PropTypes.bool,
}
