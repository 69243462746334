import SelectCustom from '../../../../common/selectCustom'
import { LATEST_YEARS, MONTHS } from '../../constants'

const MonthlySecurities = ({ value = {}, onChange }) => {
  const handleChangeYear = (year) => {
    onChange({ ...value, year })
  }

  const handleChangeMonth = (month) => {
    onChange({ ...value, month })
  }

  return (
    <div
      className={`h-20 w-100 d-flex ali-center`}
      style={{
        gap: 8,
        margin: 'auto',
        marginBottom: 16,
      }}
    >
      <div className="w-50">
        <SelectCustom
          isI18n={true}
          selectData={MONTHS}
          value={value.month}
          handleChange={handleChangeMonth}
          fontSizeItem={10}
          themeWhite
          appendStyleDivValue={{ border: 'solid 1px #939393', height: 24 }}
        />
      </div>

      <div className="w-50">
        <SelectCustom
          isI18n={false}
          selectData={LATEST_YEARS}
          value={value.year}
          handleChange={handleChangeYear}
          fontSizeItem={10}
          themeWhite
          appendStyleDivValue={{ border: 'solid 1px #939393', height: 24 }}
        />
      </div>
    </div>
  )
}
export default MonthlySecurities
