const smartScreening = {
  MAX_TEMPLATE_1: 'The number of personal templates is no more than 100.',
  MAX_TEMPLATE_2: 'Please try again!',
  MAX_CONDITION: 'The total number of indicators is no more than 100.',
  MAX_RESULT_1: 'Your screening results exceeded our limit of 300 companies.',
  MAX_RESULT_2: 'Please change your conditions to reduce the results.',
  TEMPLATE_EXIST: 'Template name is already existed.<br/>Please try again!',
  TEMPLATE_EXIST_2: 'Template name is already existed. Please try again!',
  TEMPLATE_REQUIRE: 'Please enter the template name.',
  MAX_MORE_CONDITION: 'The total number of indicators is no more than 100.',
  DUPLICATE_INDICATOR: 'Indicator is already existed.',
  TITLE_DELETE_TEMPLATE: 'Delete template',
  ASK_DELETE_TEMPLATE: 'Are you sure to delete ',
  INTERIM_OUT_OF_DATE:
    'Interim is out of date. Please update interim for each criteria.',
  EXCHANGE_LOW: 'Exchange',
  ALL_EXCHANGE: 'All exchange',
  SECTOR_LOW: 'Sector',
  ALL_SECTOR: 'All sector',
  TEMPLATE: 'Template',
  RENAME: 'Rename',
  DELETE: 'Delete',
  SEARCH: 'Search',
  APPLY: 'Apply',
  UNIT: 'Unit',
  FROM_VALUE: 'From value',
  TO_VALUE: 'to value',
  VALUE: 'Value',
  NUMBER_OF_RESULT: 'Number of result',
  RESULT: 'results',
  CREATE_WATCHLIST: 'Create watchlist',
  0: 'NO',
  1: 'TICKER',
  2: 'COMPANY NAME',
  3: 'EXCHANGE',
  SECTOR_L1: 'SECTOR L1',
  4: 'SECTOR L2',
  SECTOR_L3: 'SECTOR L3',
  SECTOR_L4: 'SECTOR L4',
  SECTOR_L5: 'SECTOR L5',
  YEAR: 'Year',
  SELECTED: 'Selected',
  CREATE: 'Create',
  UPDATE: 'Update',
  CREATE_WATCHLIST_2: 'CREATE WATCHLIST',
  UPDATE_WATCHLIST: 'Update watchlist',
  SCREENER_DATE: 'Screener date',
  TITLE_FILTER: 'Filter',
  TITLE_CONDITION: 'CONDITIONS',
  TITLE_RESULT: 'SCREENER RESULTS',
  INDICATOR: 'Indicator',
  RATIO: 'Key Ratio',
  FINANCIAL: 'Financial Statement',
  RESET: 'Reset',
  FIELD: 'Field',
  TYPE: 'Type',
  INTERIM: 'Interim',
  ADDITIONAL_CRITERIA: 'Additional Criteria',
  ADD_MORE_INFOR: 'Add more infor',
  EXPORT: 'Export Excel',
  PERIOD: 'Period',
  QUICK_SELECT: 'QUICK SELECT',
  SELECTED_ITEM: 'Selected items',
  RATIO_2: 'KEY RATIOS',
  FINANCIAL_2: 'FINANCIAL STATEMENT',
  ADD_MORE_INFOR_2: 'ADD MORE INFO',
  TOOL: 'Tool',
  SMART_SCREENING: 'Smart screening',
  TITLE_RESET: 'RESET',
  ASK_RESET: 'Do you want to reset all conditions?',
  BTN_SAVE_AS: 'Save as',
  LABEL_NAME_TEMPLATE: 'Name of template:',
  WATCHLIST_ID_REQUIRE: 'Please choose the watchlist.',
  CHOOSE_WATCHLIST: 'Choose watchlist',
  NOTICE: 'NOTICE',
  CONTENT_NOTICE:
    'You are trying to add all indicators in this group by double clicking. Do you want to continue?',
  OF_TOTAL: ' of total indicators added in the result table.',
  NO_MATCH_RESULT:
    'There are no companies matching your screening criteria, please revise your screening criteria and click Apply again.',
  CREATE_WATCHLIST_SUCCESS: 'Your watchlist has been successfully created.',
  UPDATE_WATCHLIST_SUCCESS: 'Your changes have been successfully saved!',
  CREATE_TEMPLATE_SUCCESS: 'Your template has been successfully created.',
  UPDATE_TEMPLATE_SUCCESS: 'Your template has been successfully updated.',
  ANNUAL: 'Annual',
  BLANK: 'blank',
  AND: 'and',
  THAN: 'than',
}

export default smartScreening
