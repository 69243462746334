export const BOND_DETAIL_TYPES = {
  CORPORATE_BOND: 1,
  GOVERNMENT_BOND: 4,
}

export const BOND_DETAIL_OPTIONS = [
  {
    name: 'bond.bondScreening.createBond.CORPORATE_BOND',
    value: BOND_DETAIL_TYPES.CORPORATE_BOND,
  },
  {
    name: 'bond.bondScreening.createBond.GOVERNMENT_BOND',
    value: BOND_DETAIL_TYPES.GOVERNMENT_BOND,
  },
]

export const COUPON_TYPES = {
  FIXED: 4,
  FLOAT: 1,
}

export const COUPON_OPTIONS = [
  {
    name: 'bond.bondScreening.createBond.FIXED',
    value: COUPON_TYPES.FIXED,
  },
  {
    name: 'bond.bondScreening.createBond.FLOAT',
    value: COUPON_TYPES.FLOAT,
  },
]

export const INTEREST_PAYMENT_PERIOD_TYPES = {
  ONE_MONTH: 10,
  THREE_MONTHS: 4,
  SIX_MONTHS: 3,
  TWELVE_MONTHS: 2,
  MATURITY_DATE: 13,
}

export const INTEREST_PAYMENT_PERIOD_OPTIONS = [
  {
    name: 'bond.bondScreening.createBond.ONE_MONTH',
    value: INTEREST_PAYMENT_PERIOD_TYPES.ONE_MONTH,
  },
  {
    name: 'bond.bondScreening.createBond.THREE_MONTHS',
    value: INTEREST_PAYMENT_PERIOD_TYPES.THREE_MONTHS,
  },
  {
    name: 'bond.bondScreening.createBond.SIX_MONTHS',
    value: INTEREST_PAYMENT_PERIOD_TYPES.SIX_MONTHS,
  },
  {
    name: 'bond.bondScreening.createBond.TWELVE_MONTHS',
    value: INTEREST_PAYMENT_PERIOD_TYPES.TWELVE_MONTHS,
  },
  {
    name: 'bond.bondScreening.createBond.MATURITY_DATE',
    value: INTEREST_PAYMENT_PERIOD_TYPES.MATURITY_DATE,
  },
]
