import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { getClassActive } from './helper'
import style from './index.module.css'

const ItemSecurities = ({
  securities,
  id,
  onClick,
  text,
  itemId,
  disableClick = false,
  securitiesType,
}) => {
  return (
    <div
      className={`align-center justify-content-space-between ${
        securities.some((i) => id === i) || disableClick ? style.disable : ''
      } ${style.wrapperItemSecurities}`}
      onClick={onClick}
      style={{
        opacity: getClassActive(id, itemId) ? 1 : undefined,
        background: getClassActive(id, itemId) ? '#e3e7e8' : undefined,
        paddingLeft: 12,
      }}
    >
      <div className={`align-center w-100 ${style.itemSecurities}`}>
        <div
          className={`${style.securitiesName} ${getClassActive(id, itemId)}`}
        >
          <TextEllipsis
            zIndexTooltip={Z_INDEX.DROPDOWN}
            isI18n={false}
            text={text}
          />
        </div>
        {securitiesType ? (
          <div className={style.securitiesType}>
            <TextEllipsis isI18n={false} text={securitiesType} />
          </div>
        ) : null}
      </div>
      {getClassActive(id, itemId) ? (
        <input
          type="checkbox"
          className="radiobox radiobox2"
          style={{
            border: '0.5px solid #979797',
            borderRadius: '50%',
            marginRight: 10,
          }}
          defaultChecked
        />
      ) : null}
    </div>
  )
}

ItemSecurities.propTypes = {}

export default ItemSecurities
