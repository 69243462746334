export const newsOrReport = {
  ALL_NEWS: 'All news',
  ANALYTICAL_REPORTS: 'Analytical Reports',
  LATEST_NEWS: 'Latest News',
  DATE: 'DATE',
  ISSUER: 'ISSUER',
  TITLE: 'TITLE',
  ACTION: ' ',
  SAME_CATEGORY: 'Same Category',
  DETAIL: 'Detail',
  ISSUER_TOOLTIP: 'ISSUER',
}
