import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Span } from '../../../common/html/Span'
import { ScrollComponent } from '../../../common/ScrollComponent'
import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { QuarterAndYearSelection } from '../common/QuarterAndYearSelection'
import style from '../index.module.css'
import {
  changeCurrentQuarter,
  changeCurrentYear,
} from '../tableMarginGrowth/store/slice'
import DropdownInput from './DropdownInput'
import { isTaxCode } from './helper'
import {
  changeCurrentOrganizationId,
  search,
  selectKeySearch,
} from './store/slice'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

export const FilterList = ({
  currentYear,
  currentQuarter,
  currentOrganizationId,
  listOrganizations,
}) => {
  const dispatch = useDispatch()

  const parentDropdownRef = useRef()
  const dropdownRef = useRef()
  const ref = useRef()

  const keySearch = useSelector(selectKeySearch)

  const [isShow, setIsShow] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)

  const toggleDropSearch = () => {
    if (isShow) {
      dispatch(search(''))
    }
    setIsShow(!isShow)
  }
  const hideDropSearch = () => {
    setIsShow(false)
    dispatch(search(''))
  }

  const getOrganizationInfoById = (id) => {
    return listOrganizations?.find((item) => item.value === Number(id)) || {}
  }

  const handleQuarterChange = (item) => {
    dispatch(changeCurrentQuarter(item))
  }

  const handleYearChange = (item) => {
    dispatch(changeCurrentYear(item))
  }

  const handleOrganizationChange = (item) => {
    dispatch(changeCurrentOrganizationId(item))
    hideDropSearch()
  }

  const resultSearch = listOrganizations?.filter(
    (item) =>
      I18n.t(item?.name).toLowerCase().includes(keySearch?.toLowerCase()) ||
      (item?.ticker &&
        item?.ticker.toLowerCase().includes(keySearch?.toLowerCase())),
  )

  const getDisplayName = (ticker, name) => {
    return ticker && !isTaxCode(ticker) ? `${ticker} - ${name}` : name
  }

  useEffect(() => {
    const handlePressKey = (e) => {
      const listOrganizationsLength = listOrganizations.length
      if (e?.keyCode === keyCode.ENTER) {
        handleOrganizationChange(resultSearch[activeIndex].value)
      }
      if (
        e?.keyCode === keyCode.DOWN_ARROW &&
        listOrganizationsLength > 0 &&
        activeIndex < listOrganizationsLength - 1
      ) {
        setActiveIndex((activeIndex) => activeIndex + 1)
      }
      if (
        e?.keyCode === keyCode.UP_ARROW &&
        listOrganizationsLength > 0 &&
        activeIndex > 0
      ) {
        setActiveIndex((activeIndex) => activeIndex - 1)
      }
    }

    if (isShow) {
      document.addEventListener('keydown', handlePressKey)
      return () => {
        document.removeEventListener('keydown', handlePressKey)
      }
    } else {
      document.removeEventListener('keydown', handlePressKey)
    }
  }, [isShow, activeIndex])

  return (
    <div className="d-flex j-b">
      <QuarterAndYearSelection
        handleQuarterChange={handleQuarterChange}
        handleYearChange={handleYearChange}
        currentYear={currentYear}
        currentQuarter={currentQuarter}
      />
      <div
        className="input-dropdown h-20"
        ref={parentDropdownRef}
        style={{ width: 230 }}
      >
        <span
          onClick={toggleDropSearch}
          ref={dropdownRef}
          className={`${style.spanInputSearch} span-input ${style.spanInput}`}
        >
          <div style={{ margin: '0px 10px' }}>
            <TextEllipsis
              text={getDisplayName(
                getOrganizationInfoById(currentOrganizationId)?.ticker,
                getOrganizationInfoById(currentOrganizationId)?.name,
              )}
              appendStyle={{ width: 190 }}
            />
          </div>
          <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
        </span>
        <Dropdown
          parentRef={parentDropdownRef}
          isShow={isShow}
          zIndex={Z_INDEX.DROPDOWN}
        >
          <HandleClickOutside
            handleClickOutside={hideDropSearch}
            exceptRef={dropdownRef}
          >
            <div className="input-dropdown" style={{ marginTop: -2 }}>
              <div
                className={style.dropdownContainerSearchTicker}
                id="dropdownList"
                style={{
                  width: `${parentDropdownRef?.current?.offsetWidth}px`,
                  display: isShow ? 'block' : 'none',
                }}
              >
                <DropdownInput
                  ref={ref}
                  setShowDropdown={setIsShow}
                  listOrganizations={listOrganizations}
                />
                <ScrollComponent autoHeight={true} autoHeightMax={200}>
                  <ul className={style.ul}>
                    {resultSearch.length
                      ? resultSearch.map((item, index) => (
                          <div key={index}>
                            <DetectScroll isActive={activeIndex === index} />
                            <li
                              className={`${style.li} ${
                                activeIndex === index && style.activeHover
                              }`}
                              style={index === 0 ? { marginTop: 5 } : {}}
                              onClick={() =>
                                handleOrganizationChange(item.value)
                              }
                            >
                              <Span
                                className={style.textDropdown}
                                style={{ fontSize: 12, marginLeft: 4 }}
                              >
                                <TextEllipsis
                                  text={getDisplayName(item.ticker, item.name)}
                                  zIndexTooltip={99}
                                />
                              </Span>
                            </li>
                          </div>
                        ))
                      : ''}
                  </ul>
                </ScrollComponent>
              </div>
            </div>
          </HandleClickOutside>
        </Dropdown>
      </div>
    </div>
  )
}

const DetectScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#dropdownList .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
