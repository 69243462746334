import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import TextEllipsis from '../../../../common/textEllipsis'
import { MAX_SECURITIES_ADD } from '../../../common/constants'
import { selectIsLoading, selectWatchList } from '../../common/store/slice'
import { getTickerByWLId } from '../../common/store/thunk'
import style from '../style.module.css'

const ListWatchList = ({
  valueSearch,
  securitiesState,
  setSecuritiesState,
}) => {
  const dispatch = useDispatch()
  const watchList = useSelector(selectWatchList)
  const loading = useSelector(selectIsLoading('watchList'))

  const { companies, indices, sector, economy } = securitiesState

  const checkDisable = (organizationCount) => {
    let count = 0
    const newArray = [companies, indices, sector, economy]
    newArray.forEach((arr) => (count += arr.length))
    return !organizationCount || organizationCount + count > MAX_SECURITIES_ADD
      ? true
      : false
  }

  const onCheckboxChange = (e, wl) => {
    const params = { WatchListId: wl.watchListId, WithMarketCap: true }
    const checked = e.target.checked

    dispatch(getTickerByWLId(params))
      .unwrap()
      .then((data) => {
        const { details: orgs } = data
        const validOrgIds = orgs.map((org) => org.organizationId)

        if (checked) {
          setSecuritiesState({
            ...securitiesState,
            companies: [
              ...new Set([...securitiesState.companies, ...validOrgIds]),
            ],
            watchList: [...securitiesState.watchList, wl.watchListId],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            companies: securitiesState.companies.filter(
              (id) => !validOrgIds.includes(id),
            ),
            watchList: securitiesState.watchList.filter(
              (id) => id !== wl.watchListId,
            ),
          })
        }
      })
  }

  const filterWatchList = useCallback(() => {
    return [...watchList]
      .filter((i) => i.watchListName?.toLowerCase().includes(valueSearch))
      .sort((a, b) =>
        valueSearch
          ? b.watchListName?.toLowerCase().indexOf(valueSearch.toLowerCase()) -
            a.watchListName?.toLowerCase().indexOf(valueSearch.toLowerCase())
          : 1,
      )
  }, [valueSearch, watchList])

  return (
    <>
      {loading ? (
        <Loading isBlack />
      ) : !filterWatchList().length ? (
        <NoData />
      ) : (
        <ScrollComponent bgColor="#D9D9D9">
          <ul style={{ paddingLeft: 8, marginTop: 3, color: '#4e4e4e' }}>
            {filterWatchList().map((wl) => {
              const isCheck = securitiesState.watchList.some(
                (watchListId) => watchListId === wl.watchListId,
              )
              return (
                <li
                  key={wl.watchListId}
                  className={`${style.tickerItem} ${
                    checkDisable(wl.organizationCount) ? style.disabled : ''
                  }`}
                >
                  <label>
                    <div className={style.checkBox}>
                      <input
                        type="checkbox"
                        className="checkbox line"
                        checked={isCheck}
                        onChange={(e) => onCheckboxChange(e, wl)}
                      />
                    </div>
                    <div className="flex-1">
                      <TextEllipsis isI18n={false} text={wl.watchListName} />
                    </div>
                  </label>
                </li>
              )
            })}
          </ul>
        </ScrollComponent>
      )}
    </>
  )
}

export default ListWatchList
