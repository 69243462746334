import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TIME_FREQUENCY, TYPE_FORMAT } from '../../../common/constants'
import { getMinData } from '../../../common/helper'
import { VIEW_BY } from '../constants'
import { convertData, getIndicator } from '../helper'
import { getFDITypeName, getTableData } from './thunk'

const initialState = {
  isLoading: true,
  viewBy: VIEW_BY.CAPITAL,
  status: undefined,
  listFDITypeName: [],
  valueById: {},
  ids: [],
  groupColumns: [],
  timeFrequency: TIME_FREQUENCY.YEARLY,
  indicator: null,
  dataTable: [],
  payloadCallBack: {
    month: undefined,
    year: undefined,
    prevMonth: undefined,
    prevYear: undefined,
  },
  initialIds: [],
  indicatorDefault: null,
  typeFormatData: TYPE_FORMAT.CAPITAL,
}

const slice = createSlice({
  name: 'economy/fdi/fdiByIndustries/tableData',
  initialState,
  reducers: {
    changeViewBy: (state, action) => {
      state.viewBy = action.payload
    },
    changeFilterStatus: (state, action) => {
      state.status = action.payload
    },
    changeTimeFrequency: (state, action) => {
      state.timeFrequency = action.payload
    },
    changeIndicator: (state, action) => {
      const rowId = action.payload
      state.indicator = state.valueById[rowId]
    },
    resetDataTable: (state) => {
      state.dataTable = []
      state.payloadCallBack = {
        month: undefined,
        year: undefined,
        prevMonth: undefined,
        prevYear: undefined,
      }
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.valueById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTableData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTableData.fulfilled, (state, action) => {
      const tableData = action.payload || []
      state.payloadCallBack = {
        month: getMinData(tableData).minMonth,
        year: getMinData(tableData).minYear,
        prevMonth: state.payloadCallBack.month,
        prevYear: state.payloadCallBack.year,
      }

      state.dataTable =
        state.timeFrequency === TIME_FREQUENCY.ACC_MONTHLY
          ? [...tableData, ...state.dataTable]
          : [...state.dataTable, ...tableData]

      const indicator = tableData.length
        ? getIndicator(tableData)
        : getIndicator(state.dataTable)
      state.ids = state.initialIds = indicator.map((item) => item.index)
      state.valueById = keyBy(indicator, 'index')

      const data = convertData(state.dataTable, state.timeFrequency)
      const groupColumns = []
      const dataKeyArr = Object.values(state.valueById)
      data.forEach((item) => {
        groupColumns.push({
          key: item.name,
          title: item.name,
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? item[e.key] : ''
          e[item.name] = value
        })
      })

      state.groupColumns = data.length
        ? [
            {
              key: 'name',
              title: 'economy.fdi.fdiByIndustry.INDUSTRY',
            },
            ...groupColumns,
          ]
        : []
      state.indicatorDefault = {
        key: indicator[0]?.key,
        name: indicator[0]?.name,
      }
      state.isLoading = false
      state.typeFormatData = state.viewBy
        ? TYPE_FORMAT.CAPITAL
        : TYPE_FORMAT.NUMBER_OF_PROJECT
    })
    builder.addCase(getTableData.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getFDITypeName.pending, (state) => {})
    builder.addCase(getFDITypeName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.listFDITypeName = data.map((item) => ({
        value: item.fdiTypeOrder,
        name: item.fdiTypeName,
      }))
      state.status = state.viewBy
        ? data[3]?.fdiTypeOrder
        : data[0]?.fdiTypeOrder
    })
    builder.addCase(getFDITypeName.rejected, (state) => {})
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectViewBy = (state) => state[slice.name].viewBy
export const selectFilterStatus = (state) => state[slice.name].status
export const selectListFDITypeName = (state) =>
  state[slice.name].listFDITypeName
export const selectIds = (state) => state[slice.name].ids
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectValueById = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].valueById[id], attr)
}
export const selectTimeFrequency = (state) => state[slice.name].timeFrequency
export const selectIndicator = (state) => state[slice.name].indicator
export const selectPayloadCallBack = (state) =>
  state[slice.name].payloadCallBack
export const selectIndicatorDefault = (state) =>
  state[slice.name].indicatorDefault
export const selectTypeFormatData = (state) => state[slice.name].typeFormatData

export const {
  changeViewBy,
  changeFilterStatus,
  changeTimeFrequency,
  changeIndicator,
  resetDataTable,
  sort,
} = slice.actions

register(slice.name, slice.reducer)
