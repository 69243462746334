import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { DEFAULT_WARRANT } from '../constants'
import { getLatestPrice, getSearchWarrant } from './thunk'

const defaultState = {
  loadingSearch: false,
  topInfo: DEFAULT_WARRANT,
  listCompany: [],
  priceData: {},
}

const initialState = {
  loadingSearch: false,
  topInfo: {
    coveredWarrantId: '',
    coveredWarrantName: '',
    ticker: '',
  },
  listWarrant: [],
  priceData: {},
}

const slice = createSlice({
  name: 'common/warrantTopInfo',
  initialState,
  reducers: {
    resetDefault(state) {
      Object.keys(defaultState).forEach((key) => {
        if (key !== 'priceData') {
          state[key] = defaultState[key]
        }
      })
    },
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeWarrantInfo(state, action) {
      state.topInfo = action.payload
    },
    changeLoadingSearch(state, action) {
      state.loadingSearch = action.payload
    },
    subscribeWarrant: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
    subscribeBidAsk: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchWarrant.pending, (state) => {
      state.loadingSearch = true
    })
    builder.addCase(getSearchWarrant.fulfilled, (state, action) => {
      const data = action.payload || []
      state.loadingSearch = false
      data.forEach((object) => {
        delete object['groupId']
      })
      state.listWarrant = data
    })
    builder.addCase(getSearchWarrant.rejected, (state) => {
      state.loadingSearch = false
    })

    builder.addCase(getLatestPrice.pending, (state) => {
      state.isLoading = true
      state.priceData = {}
    })
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      state.isLoading = false
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      } else {
        state.priceData = defaultState.priceData
      }
    })
    builder.addCase(getLatestPrice.rejected, (state) => {
      state.isLoading = false
    })
  },
})
export const selectWarrantInfo = (state) => state[slice.name].topInfo
export const selectLoadingSearch = (state) => state[slice.name].loadingSearch
export const selectListWarrant = (state) => state[slice.name].listWarrant
export const selectPriceData = (state) => state[slice.name].priceData

export const {
  resetDefault,
  resetStore,
  changeWarrantInfo,
  subscribeWarrant,
  subscribeBidAsk,
  changeLoadingSearch,
} = slice.actions

register(slice.name, slice.reducer)
