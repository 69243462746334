import PropTypes from 'prop-types'
import { EMPTY_VALUE } from '../../../constants/Common'
import Chart from './Chart'

export const ScatterChart = ({
  id,
  width,
  height,
  data,
  zAxisRange,
  xName,
  yName,
  xTooltip,
  yTooltip,
  zTooltip,
  xTooltipColor,
  yTooltipColor,
  zTooltipColor,
  xUnit,
  yUnit,
  zUnit,
  keyLabel,
  isShowLabel,
  isShowLabelOutside,
}) => {
  return (
    <div id={id} style={{ height }} className="position-relative">
      <Chart
        width={width}
        height={height}
        data={data}
        xAxisId="xValue"
        yAxisId="yValue"
        zAxisId="zValue"
        zAxisRange={zAxisRange}
        xLabel={
          xName !== EMPTY_VALUE
            ? `${xName}${xUnit ? ` (${xUnit})` : ''}`
            : '(--)'
        }
        yLabel={
          yName !== EMPTY_VALUE
            ? `${yName}${yUnit ? ` (${yUnit})` : ''}`
            : '(--)'
        }
        xTooltip={xTooltip}
        yTooltip={yTooltip}
        zTooltip={zTooltip}
        xTooltipColor={xTooltipColor}
        yTooltipColor={yTooltipColor}
        zTooltipColor={zTooltipColor}
        xUnit={xUnit}
        yUnit={yUnit}
        zUnit={zUnit}
        keyLabel={keyLabel}
        isShowLabel={isShowLabel}
        isShowLabelOutside={isShowLabelOutside}
      />
    </div>
  )
}

ScatterChart.propTypes = {
  id: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      xValue: PropTypes.number,
      yValue: PropTypes.number,
      zValue: PropTypes.number,
    }),
  ).isRequired,
  xName: PropTypes.string,
  yName: PropTypes.string,
  xTooltip: PropTypes.string,
  yTooltip: PropTypes.string,
  zTooltip: PropTypes.string,
  xTooltipColor: PropTypes.string,
  yTooltipColor: PropTypes.string,
  zTooltipColor: PropTypes.string,
  xUnit: PropTypes.string,
  yUnit: PropTypes.string,
  zUnit: PropTypes.string,
  zAxisRange: PropTypes.arrayOf(PropTypes.number),
  keyLabel: PropTypes.string,
  isShowLabel: PropTypes.bool,
  isShowLabelOutside: PropTypes.bool,
}

ScatterChart.defaultProps = {
  zAxisRange: [50, 10000],
}
