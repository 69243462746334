import { useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { marketWatchTabs, tableDataTypes } from '../constans'
import style from '../index.module.css'
import { keys, selectLoading, selectTableFilter } from '../store/slice'
import {
  downloadIndexEODThunk,
  downloadIndexIntradayThunk,
} from '../store/thunk'
import { Intraday } from './intraday'
import { Liquidlity } from './liquidlity'
import TimeRange from './liquidlity/TimeRange'

export const idMarketWatch = 'marketWatch'

const MarketWatch = ({ sizes, panelKey, panelRefs, setSizes }) => {
  const timeZone = UseTimeZone()

  const indexInfo = useSelector(selectIndexInfo)
  const tableFilter = useSelector(selectTableFilter)
  const loadingBuSd = useSelector(selectLoading(keys.BU_SD))
  const loadingPrice = useSelector(selectLoading(keys.INDEX_SERIES))
  const loadingIndexLiquitidy = useSelector(
    selectLoading(keys.INDEX_LIQUIDITY_SERIES),
  )
  const loading = loadingIndexLiquitidy || loadingBuSd || loadingPrice

  const [tab, setTab] = useState(marketWatchTabs.LIQUIDLITY)

  const handleDownloadCsv = () => {
    let params = {
      GroupId: indexInfo.groupId,
    }

    if (tableFilter.dataType === tableDataTypes.REALTIME) {
      params = {
        ...params,
        TimeBucket: tableFilter.timeRange,
        StartDate: tableFilter.dateRealTime
          ? getISOStartOrEndOfDay(tableFilter.dateRealTime, timeZone, true)
          : '',
        EndDate: tableFilter.dateRealTime
          ? getISOStartOrEndOfDay(tableFilter.dateRealTime, timeZone, false)
          : '',
      }

      return downloadIndexIntradayThunk(params)
    } else {
      params = {
        ...params,
        StartDate: tableFilter.dateFrom
          ? getISOStartOrEndOfDay(tableFilter.dateFrom, timeZone, true)
          : '',
        EndDate: tableFilter.dateTo
          ? getISOStartOrEndOfDay(tableFilter.dateTo, timeZone, false)
          : '',
        Page: 1,
        PageSize: 2000,
        SortField: tableFilter.sortField,
        SortOrder: tableFilter.sortOrder,
      }

      if (tableFilter.timeRange !== calendarTabKey) {
        params = {
          ...params,
          TimeRange: tableFilter.timeRange,
        }
      }

      return downloadIndexEODThunk(params)
    }
  }

  return (
    <Panel
      title="market.marketInDepth.indices.MARKET_WATCH"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={tab === marketWatchTabs.LIQUIDLITY}
      isDownloadCsv={tab === marketWatchTabs.INTRADAY}
      titleJpg={I18n.t('market.marketInDepth.indices.MARKET_WATCH')}
      downloadJpgParams={{
        domId: idMarketWatch,
        nameScreen: I18n.t(
          'market.marketInDepth.indices.NAME_SCREEN_LOWERCASE',
        ),
        chartName: I18n.t(
          'market.marketInDepth.indices.CHART_NAME_MARKET_WATCH',
        ),
        tickerName: indexInfo.groupName,
      }}
      downloadCsv={handleDownloadCsv}
    >
      <PreOpenMarket>
        <div className={`h-100 ${style.marketWatchContainer}`}>
          <SizeTracker>
            {(size) => (
              <>
                <div className="w-100 pb-8 d-flex justify-content-space-between">
                  <DispatchActionTab
                    onChangeTab={(e) => setTab(e.value)}
                    data={Object.keys(marketWatchTabs).map((key) => ({
                      title: 'market.marketInDepth.indices.' + key,
                      value: marketWatchTabs[key],
                    }))}
                    activeTab={tab}
                    textSize={12}
                  />
                  {tab === marketWatchTabs.LIQUIDLITY ? <TimeRange /> : <div />}
                </div>
                {size.height && (
                  <div
                    id={idMarketWatch}
                    className="position-relative"
                    style={{ height: `calc(100% - ${size.height}px)` }}
                  >
                    {loading ? (
                      <Loading />
                    ) : (
                      <>
                        {tab === marketWatchTabs.LIQUIDLITY ? (
                          <Liquidlity sizes={sizes} panelKey={panelKey} />
                        ) : (
                          <Intraday sizes={sizes} />
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </SizeTracker>
        </div>
      </PreOpenMarket>
    </Panel>
  )
}

export default MarketWatch
