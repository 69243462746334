import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { Z_INDEX } from '../constants/Common'
import EventEmitter, { SCROLL_COMPONENT } from '../utils/EventEmitter'

const ZOOM_HORIZONTAL_TRACK_WIDTH = 16

export const ScrollComponent = ({
  children,
  appendStyle,
  horizontalTrackLeft,
  verticalTrackTop,
  verticalTrackBottom,
  verticalTrackWidth,
  horizontalTrackWidth,
  horizontalTrackWidthHover,
  autoHeight,
  autoHeightMax,
  autoHeightMin,
  defaultScrollToRight,
  disableScroll,
  scrollToBottomCallback,
  scrollToTopCallback,
  scrollToRightCallback,
  scrollToLeftCallback,
  bgColor,
  scrollbarsRef,
  setValueAfterUpdate,
  scrollLeftValue,
  isScrollRight,
  isLoading,
  id,
  scrollCallBack,
}) => {
  const [isHover, setIsHover] = useState(false)
  const [isScrolling, setIsScrolling] = useState(false)
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [isInHorizontalTrack, setIsInHorizontalTrack] = useState(false)
  const [hoverHorizontalTrackWidth, setHoverHorizontalTrackWidth] =
    useState(horizontalTrackWidth)
  const [disable, setDisable] = useState(false)

  const renderTrackHorizontal = ({ style, ...props }) => {
    const hoverHorizontal =
      typeof horizontalTrackWidthHover === 'number'
        ? horizontalTrackWidthHover
        : ZOOM_HORIZONTAL_TRACK_WIDTH

    return (
      <div
        onMouseEnter={() => {
          setIsInHorizontalTrack(true)
          setHoverHorizontalTrackWidth(hoverHorizontal)
        }}
        onMouseLeave={() => {
          setIsInHorizontalTrack(false)
          if (!isScrolling && !isMouseDown) {
            setHoverHorizontalTrackWidth(horizontalTrackWidth)
          }
        }}
        style={{
          ...style,
          width: `calc(100% - ${horizontalTrackLeft}px)`,
          height: hoverHorizontal,
          left: horizontalTrackLeft,
          bottom: 0,
          opacity: isHover && !disable ? 1 : 0,
          zIndex: Z_INDEX.SCROLL_COMPONENT,
          backgroundColor: 'transparent',
        }}
        {...props}
      />
    )
  }

  const renderTrackVertical = ({ style, ...props }) => {
    return (
      <div
        style={{
          ...style,
          width: verticalTrackWidth,
          height: `calc(100% - ${verticalTrackTop + verticalTrackBottom}px)`,
          top: verticalTrackTop,
          right: 0,
          opacity: isHover && !disable ? 1 : 0,
          zIndex: Z_INDEX.SCROLL_COMPONENT,
          backgroundColor: 'transparent',
        }}
        {...props}
      />
    )
  }

  const renderThumbHorizontal = ({ style, ...props }) => {
    return (
      <div
        onMouseDownCapture={onMouseDownCapture}
        style={{
          ...style,
          width: '100%',
          height: hoverHorizontalTrackWidth,
          left: 0,
          bottom: 0,
          opacity: isHover && !disable ? 1 : 0,
          zIndex: Z_INDEX.SCROLL_COMPONENT,
          position: 'absolute',
          cursor: 'pointer',
          backgroundColor: bgColor,
          borderRadius: 8,
        }}
        {...props}
      />
    )
  }

  const renderThumbVertical = ({ style, ...props }) => {
    return (
      <div
        {...props}
        style={{
          ...style,
          cursor: 'pointer',
          backgroundColor: bgColor,
          borderRadius: 8,
        }}
      />
    )
  }

  const renderView = ({ style, ...props }) => {
    const customStyle = disable ? { overflow: 'hidden', width: '100%' } : {}
    return (
      <div
        {...props}
        style={{
          ...style,
          ...customStyle,
          zIndex: 0,
          marginRight: style.marginRight ? -17 : 0,
          marginBottom: style.marginBottom ? -17 : 0,
        }}
      />
    )
  }

  const showScroll = () => {
    if (!isHover) {
      setIsHover(true)
    }
  }

  const hideScroll = () => {
    if (isHover) {
      setIsHover(false)
    }
  }

  const onMouseEnter = () => {
    showScroll()
  }

  const onMouseLeave = () => {
    if (!isScrolling) {
      hideScroll()
    }
  }

  const onScroll = (e) => {
    if (isLoading) return

    scrollCallBack?.(e?.target.scrollLeft)

    const bottom =
      e?.target.scrollHeight - Math.ceil(e?.target.scrollTop) <=
      e?.target.clientHeight + 1
    if (bottom) {
      scrollToBottomCallback()
    }

    if (e?.target.scrollTop === 0) {
      scrollToTopCallback()
    }

    const right =
      e?.target.scrollWidth - Math.round(e?.target.scrollLeft) <=
      e?.target.clientWidth
    if (right && e?.target.scrollLeft !== 0) {
      scrollToRightCallback()
    }

    if (
      e?.target.scrollLeft === 0 &&
      e?.target.scrollWidth > e?.target.clientWidth
    ) {
      scrollToLeftCallback()
    }

    setIsScrolling(true)
    EventEmitter.dispatch(SCROLL_COMPONENT, id)
  }

  const onScrollStop = () => {
    setIsScrolling(false)
  }

  const wrappedRef = useRef()

  const onUpdate = (values) => {
    setValueAfterUpdate(values)
  }

  const onMouseUp = (event) => {
    setIsMouseDown(false)
    if (!isInHorizontalTrack) {
      setHoverHorizontalTrackWidth(horizontalTrackWidth)
    }
    if (
      wrappedRef.current &&
      !wrappedRef.current.closest('.scrollbars').contains(event.target)
    ) {
      setIsHover(false)
    }
  }

  const onMouseDownCapture = () => {
    setIsMouseDown(true)
  }

  const scrollRef = useRef(null)

  useEffect(() => {
    if (scrollRef.current && defaultScrollToRight && isScrollRight) {
      scrollRef.current.scrollToRight()
    }
  }, [children, scrollLeftValue])

  useEffect(() => {
    if (scrollRef.current && !defaultScrollToRight) {
      scrollRef.current.scrollToTop()
      scrollRef.current.scrollToLeft()
      setDisable(disableScroll)
    }
  }, [disableScroll])

  useEffect(() => {
    if (scrollRef.current && scrollLeftValue && !defaultScrollToRight) {
      scrollRef.current.scrollLeft(scrollLeftValue)
    }
  }, [children, scrollLeftValue])

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp)
    return () => {
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [isInHorizontalTrack])

  return (
    <Scrollbars
      ref={scrollbarsRef || scrollRef}
      className="scrollbars"
      style={appendStyle}
      autoHeight={autoHeight}
      autoHeightMax={autoHeightMax}
      autoHeightMin={autoHeightMin}
      renderTrackHorizontal={renderTrackHorizontal}
      renderTrackVertical={renderTrackVertical}
      renderThumbHorizontal={renderThumbHorizontal}
      renderThumbVertical={renderThumbVertical}
      hideTracksWhenNotNeeded
      renderView={renderView}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onScroll={onScroll}
      onScrollStop={onScrollStop}
      onUpdate={onUpdate}
    >
      <div ref={wrappedRef} className="w-100 h-100">
        {children}
      </div>
    </Scrollbars>
  )
}

ScrollComponent.propTypes = {
  children: PropTypes.node.isRequired,
  appendStyle: PropTypes.object,
  horizontalTrackLeft: PropTypes.number,
  verticalTrackTop: PropTypes.number,
  verticalTrackBottom: PropTypes.number,
  autoHeight: PropTypes.bool,
  autoHeightMax: PropTypes.number,
  autoHeightMin: PropTypes.number,
  verticalTrackWidth: PropTypes.number,
  horizontalTrackWidth: PropTypes.number,
  horizontalTrackWidthHover: PropTypes.number,
  defaultScrollToRight: PropTypes.bool,
  disableScroll: PropTypes.bool,
  scrollToBottomCallback: PropTypes.func,
  scrollToTopCallback: PropTypes.func,
  scrollToRightCallback: PropTypes.func,
  scrollToLeftCallback: PropTypes.func,
  bgColor: PropTypes.string,
  setValueAfterUpdate: PropTypes.func,
  scrollLeftValue: PropTypes.number,
  isScrollRight: PropTypes.bool,
  scrollCallBack: PropTypes.func,
}

ScrollComponent.defaultProps = {
  appendStyle: {},
  horizontalTrackLeft: 0,
  verticalTrackTop: 0,
  verticalTrackBottom: 0,
  verticalTrackWidth: 5,
  horizontalTrackWidth: 5,
  autoHeight: false,
  autoHeightMax: 0,
  autoHeightMin: 0,
  defaultScrollToRight: false,
  disableScroll: false,
  scrollToBottomCallback: () => {},
  scrollToTopCallback: () => {},
  scrollToRightCallback: () => {},
  scrollToLeftCallback: () => {},
  bgColor: '#454b56',
  setValueAfterUpdate: () => {},
}
