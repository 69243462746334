import { useEffect, useRef, useState } from 'react'
import { Input } from '../../../../common/html/Input'
import DropdownTooltip from '../../../../common/textEllipsis/DropdownTooltip'
import { formatVal } from '../../../../utils/Value'
import style from '../style.module.css'

const unitType = {
  PERCENT: '%',
}
const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/ //positive and negative float number

export const IndicatorSelectInput = ({ value, unit, handleChange }) => {
  const [displayValue, setDisplayValue] = useState('')
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const lastValue = useRef(displayValue)
  const inputRef = useRef()

  const cancelFormatValues = (value, unit) => {
    if (value) {
      if (unit === unitType.PERCENT) {
        return value.replace(/,/g, '').replace('%', '') || ''
      } else {
        return value.replace(/,/g, '') || ''
      }
    }
    return value
  }

  const formatValues = (value, unit) => {
    const valueNumber = String(value).length ? Number(value) : ''
    if (typeof valueNumber === 'number' && !isNaN(valueNumber)) {
      if (unit === unitType.PERCENT) {
        return formatVal(valueNumber, 10, false, '', 0) + '%' || ''
      } else {
        return formatVal(valueNumber, 10, false, '', 0) || ''
      }
    }
    return value
  }

  const focusInput = (setDisplayValue, displayValue) =>
    setDisplayValue(cancelFormatValues(displayValue, unit))

  const blurInput = (setDisplayValue, displayValue) => {
    if (displayValue === '+' || displayValue === '-') {
      handleChange(null)
      setDisplayValue(formatValues('', unit))
    } else {
      setDisplayValue(formatValues(displayValue, unit))
    }
  }

  const handleChangeValue = (e) => {
    if (e.target.value.match(regexValue) || e.target.value === '') {
      handleChange(e.target.value)
      setDisplayValue(e.target.value)
      lastValue.current = e.target.value
    } else {
      setDisplayValue(lastValue.current)
    }
  }

  const handleOpenTooltip = () => {
    setIsOpenTooltip(true)
  }

  const handleCloseTooltip = () => {
    setIsOpenTooltip(false)
  }

  useEffect(() => {
    if (typeof value === 'number' && !isNaN(value)) {
      const formatValue = unit === '%' ? value * 100 : value
      setDisplayValue(formatValues(formatValue, unit))
    }
  }, [])

  return (
    <>
      <Input
        ref={inputRef}
        type="text"
        value={displayValue}
        className={style.equationListItemValue}
        onFocus={() => focusInput(setDisplayValue, displayValue)}
        onBlur={() => blurInput(setDisplayValue, displayValue)}
        onChange={handleChangeValue}
        onMouseOver={handleOpenTooltip}
        onMouseOut={handleCloseTooltip}
      />
      {isOpenTooltip && displayValue && (
        <DropdownTooltip
          containerRef={inputRef}
          isOpenTooltip={isOpenTooltip}
          zIndexTooltip={12}
          isI18n={false}
          text={displayValue}
        />
      )}
    </>
  )
}
