export const associateAttr = {
  ASSOCIATE: 'organizationName',
  TICKER: 'ticker',
  STATUS: 'status',
  CHAPTER_CAPITAL: 'charterCapital',
  CAPITAL_CONTRIBUTION: 'capitalContribution',
  OWNERSHIP: 'percentage',
}

export const textByStatus = {
  1: 'corporate.ownership.associate.STATUS_DELISTED',
  2: 'corporate.ownership.associate.STATUS_LISTING',
  3: 'corporate.ownership.associate.STATUS_PLAN',
  4: 'corporate.ownership.associate.STATUS_UNLISTED',
}
