import { useState } from 'react'
import { GrowthCategory } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelGrowthCategory = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const nameScreen = UseI18n('economy.importExport.importExport.IMPORT_EXPORT')
  const chartName = UseI18n(
    'economy.importExport.importExport.overview.GROWTH_CATEGORY',
  )
  const tabName = UseI18n('economy.importExport.importExport.OVERVIEW')

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title="economy.importExport.importExport.overview.GROWTH_CATEGORY"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(
        'economy.importExport.importExport.overview.GROWTH_CATEGORY',
      )}
      downloadJpgParams={{
        domId: 'balanceOfTradeChart',
        nameScreen: nameScreen,
        chartName: chartName,
        tabName: tabName,
      }}
      handleCustom={setIsFullScreen}
    >
      <GrowthCategory
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
