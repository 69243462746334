import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { positionDropdown } from '../../../../../common/dropdown'
import { Filter } from '../../../../../common/news/filter'
import {
  changeAnalyticFilter,
  selectAnalyticCategories,
  selectAnalyticFilterData,
  selectAnalyticSources,
} from '../../store/slice'
import { getReportTypesAndSources } from '../../store/thunk'

export const ReportFilter = () => {
  const dispatch = useDispatch()
  const sources = useSelector(selectAnalyticSources)
  const categories = useSelector(selectAnalyticCategories)
  const filterData = useSelector(selectAnalyticFilterData)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getReportTypesAndSources())
  }, [locale])

  const changeFilter = (data) => {
    dispatch(changeAnalyticFilter(data))
  }

  return (
    <Filter
      sources={sources.map((item) => ({
        newsSourceId: item.organizationId,
        newsSourceName: item.reportSourceName,
      }))}
      categories={categories.map((item) => ({
        newsCategoryId: item.reportTypeId,
        newsCategoryName: item.reportTypeName,
      }))}
      sourceValue={filterData.source}
      categoryValue={filterData.category}
      filterStartDate={filterData.startDate}
      filterEndDate={filterData.endDate}
      changeFilter={changeFilter}
      time={filterData.time}
      position={positionDropdown.BOTTOM_RIGHT_LEFT}
    />
  )
}
