import { useSelector } from 'react-redux'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import ValuationChart from './valuationChart/ValuationChart'
import { selectFilter } from './valuationChart/store/slice'

const MAP_KEY = {
  VALUATION: 'VALUATION',
}

const MAP_SIZE = {
  VALUATION: {
    width: `100%`,
    minWidth: 1100,
    height: `100%`,
    minHeight: 500,
    top: 0,
    left: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)
  const nameScreen = UseI18n(
    'constants.route.market.marketInDepth.valuation.LABEL',
  )
  const chartName = UseI18n('market.marketInDepth.valuation.LB_VALUATION_CHART')
  const filter = useSelector(selectFilter)
  const tabName = filter.compareTab

  return (
    <>
      <Panel
        title={'market.marketInDepth.valuation.LB_VALUATION_CHART'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUATION}
        sizes={sizes}
        setSizes={setSizes}
        windowSize={sizes[MAP_KEY.VALUATION]}
        isDownloadJpg={true}
        titleJpg={UseI18n('market.marketInDepth.valuation.LB_VALUATION_CHART')}
        downloadJpgParams={{
          domId: 'valuationChartId',
          nameScreen,
          chartName,
          tabName,
          tickerName:
            indexSectorTickerInfo.groupName ||
            indexSectorTickerInfo.icbName ||
            indexSectorTickerInfo.ticker,
        }}
      >
        <ValuationChart
          width={formatNumber(sizes[MAP_KEY.VALUATION].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[MAP_KEY.VALUATION].height) - HEADER_PANEL_HEIGHT
          }
          panelKey={MAP_KEY.VALUATION}
        />
      </Panel>
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
