import SelectCustom from '../../../../common/selectCustom'
import { LATEST_YEARS, QUARTERS } from '../../constants'

const QuarterlySecurities = ({ value = {}, onChange }) => {
  const handleChangeYear = (year) => {
    onChange({ ...value, year })
  }

  const handleChangeQuarter = (quarter) => {
    onChange({ ...value, quarter })
  }

  return (
    <div
      className={`h-20 w-100 d-flex ali-center`}
      style={{
        gap: 8,
        margin: 'auto',
        marginBottom: 16,
      }}
    >
      <div className="w-50">
        <SelectCustom
          isI18n={true}
          selectData={QUARTERS}
          value={value.quarter}
          handleChange={handleChangeQuarter}
          fontSizeItem={10}
          themeWhite
          appendStyleDivValue={{ border: 'solid 1px #939393', height: 24 }}
        />
      </div>

      <div className="w-50">
        <SelectCustom
          isI18n={false}
          selectData={LATEST_YEARS}
          value={value.year}
          handleChange={handleChangeYear}
          fontSizeItem={10}
          themeWhite
          appendStyleDivValue={{ border: 'solid 1px #939393', height: 24 }}
        />
      </div>
    </div>
  )
}
export default QuarterlySecurities
