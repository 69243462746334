import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { valToPercent } from '../../../../utils/Value'
import style from '../index.module.css'

const ChartTooltip = ({ data }) => {
  return (
    <div
      className={style.tooltip}
      style={{ width: data?.length > 5 ? 380 : 180 }}
    >
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 11, fontWeight: 400 }}
      >
        <Translate value="bond.portfolio.overviewOfPortfolioSize.CONTRIBUTION" />
      </Span>
      {!!data.length && (
        <div className={`d-flex ali-center ${style.tooltipElement}`}>
          {data.slice(0, 10).map((item, index) => {
            return (
              <div
                key={index}
                style={{ width: 180, gap: 8 }}
                className="d-flex justify-content-space-between"
              >
                {item?.text && (
                  <TextEllipsis
                    text={item?.text}
                    isI18n={
                      item?.text === 'bond.portfolio.common.GOVERNMENT_BOND'
                    }
                  />
                )}
                <Span style={{ fontSize: 11, fontWeight: 400, flexShrink: 0 }}>
                  {valToPercent(item.value, false, false, 2)}
                </Span>
              </div>
            )
          })}
        </div>
      )}
      {data?.length > 10 && (
        <div className={`d-flex ali-center ${style.tooltipElement}`}>
          <div style={{ flex: 1 }}></div>
          <div
            style={{ flex: 1, gap: 8 }}
            className="d-flex ali-center justify-content-space-between"
          >
            <TextEllipsis
              text={data[data.length - 1]?.text ?? ''}
              isI18n={true}
              appendStyle={{
                fontSize: 11,
                fontWeight: 400,
              }}
            />
            <Span style={{ fontSize: 11, fontWeight: 400, flexShrink: 0 }}>
              {valToPercent(data[data.length - 1].value, false, false, 2)}
            </Span>
          </div>
        </div>
      )}
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.number,
    }),
  ).isRequired,
}

export default ChartTooltip
