import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../../configs/Global'
import { Realtime } from '../../../../../common/Realtime'
import { SESSION } from '../../../../../common/chart/constants'
import DispatchActionTab, {
  TIME_RANGES,
} from '../../../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../../../common/topInfo/indexTopInfo/store/slice'
import {
  changeTimeRange,
  selectChartTimeRange,
  subscribeIndexSeries,
} from '../../store/slice'
import {
  getBuSdThunk,
  getForeignTradingOneDayThunk,
  getIndexLiquiditySeriesThunk,
  getIndexSeriesThunk,
} from '../../store/thunk'

const listTab = [
  { title: TIME_RANGES['1D'], value: TIME_RANGES['1D'] },
  { title: TIME_RANGES['1W'], value: TIME_RANGES['1W'] },
  { title: TIME_RANGES['1M'], value: TIME_RANGES['1M'] },
  { title: TIME_RANGES['3M'], value: TIME_RANGES['3M'] },
]

export const valueTimeRange = {
  [TIME_RANGES['1D']]: 'OneDay',
  [TIME_RANGES['1W']]: 'OneWeek',
  [TIME_RANGES['1M']]: 'OneMonth',
  [TIME_RANGES['3M']]: 'ThreeMonths',
}

const TimeRange = () => {
  const dispatch = useDispatch()

  const indexInfo = useSelector(selectIndexInfo)
  const tabActive = useSelector(selectChartTimeRange)

  const interval = useRef()

  const startTimeSession = new Date().setHours(
    SESSION.start.hour,
    SESSION.start.minute,
    0,
    0,
  )

  const endTimeSession = new Date().setHours(
    SESSION.end.hour,
    SESSION.end.minute,
    0,
    0,
  )

  useEffect(() => {
    if (indexInfo.groupId) {
      const body = {
        GroupId: indexInfo.groupId,
        TimeRange: valueTimeRange[tabActive],
      }
      dispatch(getIndexSeriesThunk(body))
      dispatch(getIndexLiquiditySeriesThunk(body))
      dispatch(
        getForeignTradingOneDayThunk({
          GroupId: indexInfo.groupId,
          TimeRange: valueTimeRange[TIME_RANGES['1D']],
        }),
      )

      if (tabActive === TIME_RANGES['1D']) {
        dispatch(getBuSdThunk(body))
        interval.current = setInterval(() => {
          const now = new Date().getTime()
          if (now > endTimeSession) {
            clearIntervalRealtime()
          } else if (now >= startTimeSession) {
            dispatch(getBuSdThunk(body))
          }
        }, 30 * 1000)
      } else {
        clearIntervalRealtime()
        dispatch(getBuSdThunk(body))
      }
    }
    return () => {
      if (interval.current) {
        clearIntervalRealtime()
      }
    }
  }, [indexInfo.groupId, tabActive])

  const clearIntervalRealtime = () => {
    clearInterval(interval.current)
    interval.current = undefined
  }

  const handleChangeTab = (item) => dispatch(changeTimeRange(item.value))

  return (
    <>
      <Realtime
        channel={ChannelConfig.IndexChannel + indexInfo.groupId}
        action={subscribeIndexSeries}
      >
        <DispatchActionTab
          data={listTab}
          activeTab={tabActive}
          onChangeTab={handleChangeTab}
          itemStyle={{ fontWeight: 500, fontSize: 10 }}
        />
      </Realtime>
    </>
  )
}

export default TimeRange
