import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { DispatchActionTabWithCalendar } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { INDICATOR_GROUPS } from '../../../constants'
import { selectFilterParams, selectLoadingFilter } from '../../../store/slice'
import { selectLoading } from '../../Table/store/slice'
import { FILTER_TIME_RANGE_TABS, POPUP_TYPES } from '../../constants'
import style from '../index.module.css'

export const FilterButtons = ({
  isSmallScreen,
  setIsShow,
  onChangeCalendar,
  onChangeTimeRange,
}) => {
  const loadingTable = useSelector(selectLoading)
  const loadingFilter = useSelector(selectLoadingFilter)
  const { indicatorGroup, ...restParams } = useSelector(selectFilterParams)

  return (
    <div
      className={`d-flex ali-center justify-content-space-between ${
        isSmallScreen && 'w-100'
      }`}
    >
      {isSmallScreen && indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER && (
        <DispatchActionTabWithCalendar
          id="bondScreeningHeader"
          tabs={FILTER_TIME_RANGE_TABS}
          activeTab={restParams.timeRange}
          startDate={restParams.startDate}
          endDate={restParams.endDate}
          datePreset={FILTER_TIME_RANGE_TABS}
          excludeDateIntervals={[]}
          onChangeTab={onChangeTimeRange}
          onChangeCalendar={onChangeCalendar}
        />
      )}
      <div className="d-flex ali-center" style={{ gap: 8 }}>
        <div>
          <Button
            className={`btn btn-yellow h-20`}
            style={{
              padding: '0 12px',
              boxSizing: 'border-box',
              opacity: loadingTable || loadingFilter ? 0.5 : 1,
            }}
            onClick={() => setIsShow(POPUP_TYPES.SETTING_COLUMN)}
            disabled={loadingTable || loadingFilter}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              style={{ marginRight: 10 }}
            >
              <path
                d="M8.62569 3.85343L8.37325 3.24076C8.37325 3.24076 8.96058 1.88721 8.9087 1.83517L8.13099 1.05975C8.07726 1.00629 6.75159 1.62417 6.75159 1.62417L6.14016 1.372C6.14016 1.372 5.60007 0 5.52596 0H4.42864C4.35222 0 3.85242 1.37484 3.85242 1.37484L3.24193 1.62795C3.24193 1.62795 1.88846 1.04036 1.83658 1.09287L1.06026 1.86923C1.00653 1.92269 1.62444 3.24881 1.62444 3.24881L1.37199 3.86053C1.37199 3.86053 0 4.40034 0 4.4732V5.57175C0 5.64839 1.37524 6.14846 1.37524 6.14846L1.62768 6.75924C1.62768 6.75924 1.04034 8.11184 1.09176 8.16388L1.86901 8.94119C1.92181 8.99371 3.24795 8.37583 3.24795 8.37583L3.85984 8.62894C3.85984 8.62894 4.39993 10 4.4745 10H5.57182C5.64778 10 6.14804 8.62516 6.14804 8.62516L6.76039 8.37205C6.76039 8.37205 8.11108 8.95964 8.16342 8.90808L8.94066 8.13171C8.99486 8.0773 8.37556 6.75214 8.37556 6.75214L8.62754 6.14042C8.62754 6.14042 10 5.59966 10 5.52586V4.42825C10.0005 4.35208 8.62569 3.85343 8.62569 3.85343ZM6.60336 4.99976C6.60336 5.88305 5.88402 6.60217 5.00023 6.60217C4.11737 6.60217 3.3971 5.88305 3.3971 4.99976C3.3971 4.11648 4.11737 3.39831 5.00023 3.39831C5.88448 3.39878 6.60336 4.11648 6.60336 4.99976Z"
                fill="#ECECEC"
              />
            </svg>
            <Translate value="bond.bondScreening.bondList.ADD_INDICATORS" />
          </Button>
        </div>
        {indicatorGroup === INDICATOR_GROUPS.BOND_CREATING && (
          <div>
            <Button
              className={`btn btn-blue h-20`}
              style={{
                padding: '0 12px',
                boxSizing: 'border-box',
                opacity: loadingTable || loadingFilter ? 0.5 : 1,
              }}
              onClick={() => setIsShow(POPUP_TYPES.CREATE_BOND)}
              disabled={loadingTable || loadingFilter}
            >
              <Translate value="bond.bondScreening.bondList.CREATE_BOND" />
            </Button>
          </div>
        )}
        <div>
          <Button
            className={`btn btn-blue h-20 ${style.disabled}`}
            style={{ padding: '0 12px', boxSizing: 'border-box' }}
            disabled
          >
            <Translate value="bond.bondScreening.bondList.CREATE_WATCHLIST" />
          </Button>
        </div>
      </div>
    </div>
  )
}
