import MarketStatisticsProxy from '../../../../proxies/bond/corporateBond/primaryMarket/MarketStatisticsProxy'
import { ServiceBase } from '../../../ServiceBase'

export class MarketStatisticsService extends ServiceBase {
  getIssuanceValue(params) {
    return this.getData(() => MarketStatisticsProxy.getIssuanceValue(params))
  }
  getAverageCouponBySector(params) {
    return this.getData(() =>
      MarketStatisticsProxy.getAverageCouponBySector(params),
    )
  }
  getIssuanceValueBySector(params) {
    return this.getData(() =>
      MarketStatisticsProxy.getIssuanceValueBySector(params),
    )
  }
  getIssuanceValueByCoupon(params) {
    return this.getData(() =>
      MarketStatisticsProxy.getIssuanceValueByCoupon(params),
    )
  }
  getAverageDurationBySector(params) {
    return this.getData(() =>
      MarketStatisticsProxy.getAverageDurationBySector(params),
    )
  }
  downloadIssuanceValue(params) {
    return this.getFileDownload(() =>
      MarketStatisticsProxy.downloadIssuanceValue(params),
    )
  }
}

export default new MarketStatisticsService()
