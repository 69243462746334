import { FORMAT, getDateTimeFrom } from '../../../../utils/Datetime'
import { TIME_FRAME_OWNERSHIP } from './constants'

const filterData = (data, from) =>
  data.filter((item) => new Date(item.tradingDateId) >= new Date(from))

export const getChartData = (data, timeFrame) => {
  switch (timeFrame) {
    case TIME_FRAME_OWNERSHIP.ONE_MONTH:
      return filterData(
        data,
        getDateTimeFrom(new Date(), -1, 'months', FORMAT.DATE),
      )
    case TIME_FRAME_OWNERSHIP.THREE_MONTH:
      return filterData(
        data,
        getDateTimeFrom(new Date(), -3, 'months', FORMAT.DATE),
      )
    case TIME_FRAME_OWNERSHIP.SIX_MONTH:
      return filterData(
        data,
        getDateTimeFrom(new Date(), -6, 'months', FORMAT.DATE),
      )
    case TIME_FRAME_OWNERSHIP.ONE_YEAR:
      return filterData(
        data,
        getDateTimeFrom(new Date(), -1, 'years', FORMAT.DATE),
      )
    default:
      return data
  }
}
