import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { changePositionChart, selectDashboardChartData } from '../store/slice'
import { deleteCharts, updateCharts } from '../store/thunk'
import style from './styleTheme.module.css'

const PopupDeleteChart = ({ handleShow, chartId, chartDetail = {} }) => {
  const dispatch = useDispatch()

  const dashboardChartData = useSelector(selectDashboardChartData)

  const onClose = () => {
    handleShow(false)
  }

  const onApply = async () => {
    handleShow(false)

    const listChartUpdateOrder = dashboardChartData
      .filter((item) => item.order > chartDetail.order)
      .map((element) => ({
        ...element,
        order: element.order - 1,
        detail: JSON.stringify({
          ...JSON.parse(element.detail),
          order: element.order - 1,
        }),
      }))
    const listChartNotUpdateOrder = dashboardChartData.filter(
      (item) => item.order < chartDetail.order,
    )

    if (typeof chartId === 'object') {
      chartId.forEach((element) => {
        dispatch(
          deleteCharts({
            id: element.id,
          }),
        )
      })
    } else {
      dispatch(
        deleteCharts({
          id: chartId,
        }),
      )
      listChartUpdateOrder.forEach((element) => {
        dispatch(updateCharts(element))
        dispatch(
          changePositionChart([
            ...listChartNotUpdateOrder,
            ...listChartUpdateOrder,
          ]),
        )
      })
    }
  }

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div className={`modal modal-small ${style.modal}`}>
        <div className={`modal-title ${style.titleStyle}`}>
          <h3>
            <Span>
              <Translate value="financialChart.DELETE_CHART" />
            </Span>
          </h3>
          <a onClick={onClose} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentStyle}`}>
          <Span className={style.colorText}>
            <Translate value="financialChart.ALERT_DELETE_CHART" />
          </Span>
          <div className="group-btn justify-content-end">
            <Button
              className={`btn w-80 h-20 ${style.buttonGrayStyle}`}
              style={{ fontSize: 11 }}
              onClick={onApply}
            >
              <Translate value="financialChart.BTN_DELETE" />
            </Button>
            <Button
              className={`btn w-80 h-20 ${style.buttonBlueStyle}`}
              style={{ fontSize: 11 }}
              onClick={onClose}
            >
              <Translate value="financialChart.BTN_CANCEL" />
            </Button>
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupDeleteChart.propType = {
  chartId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  handleShow: PropTypes.func,
}

export default PopupDeleteChart
