import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Summary } from '.'
import { Panel } from '../../../../common/panel'
import { PANEL_PADDING } from '../../../../constants/Common'
import { getTab } from '../store/slice'
import { TAB_TYPE } from './constants'
import { getRadioValuePPI } from './generalIndex/store/slice'
import {
  downloadCompareByTimeRangeIIC,
  downloadCompareByTimeRangeIII,
  downloadGeneralProductionIndex,
} from './iic/store/thunk'
import { downloadCompareByTimeRangeIIP } from './iip/store/thunk'
import {
  getYearlyQuarterValue,
  selectCountLimit,
  selectMonth,
  selectQuarter,
  selectTypeDataTime,
  selectTypePeriod,
  selectYear,
} from './store/slice'

export const PanelTable = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const typePeriod = useSelector(selectTypePeriod)
  const typeTime = useSelector(selectTypeDataTime)
  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const radioValuePPI = useSelector(getRadioValuePPI)
  const onTab = useSelector(getTab)
  const countLimit = useSelector(selectCountLimit)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)
  const month = useSelector(selectMonth)

  const downloadCsv = () => {
    let params = {
      FrequencyType:
        onTab === TAB_TYPE.GENERAL_INDEX ? radioValuePPI : typePeriod,
      DataType:
        onTab === TAB_TYPE.GENERAL_INDEX ? yearlyQuarterValue : typeTime,
      Limit: countLimit,
      Year: year,
    }
    switch (onTab) {
      case TAB_TYPE.IIP:
        return downloadCompareByTimeRangeIIP({ ...params, Month: month })
      case TAB_TYPE.IIC:
        return downloadCompareByTimeRangeIIC({ ...params, Month: month })
      case TAB_TYPE.III:
        return downloadCompareByTimeRangeIII({ ...params, Month: month })
      default:
        return downloadGeneralProductionIndex({ ...params, Quarter: quarter })
    }
  }

  const renderTitle = (onTab) => {
    switch (onTab) {
      case TAB_TYPE.IIP:
        return 'economy.productionAndConsumption.production.IIP_TITLE'
      case TAB_TYPE.IIC:
        return 'economy.productionAndConsumption.production.IIC_TITLE'
      case TAB_TYPE.III:
        return 'economy.productionAndConsumption.production.III_TITLE'
      case TAB_TYPE.PRODUCT:
        return 'economy.productionAndConsumption.production.PRODUCT_INDUSTRY'
      default:
        return 'economy.productionAndConsumption.production.PRODUCTION_INDEX'
    }
  }

  return (
    <Panel
      title={renderTitle(onTab)}
      panelRefs={panelRefs}
      panelKey={mapKey.TABLE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => downloadCsv()}
    >
      <Summary
        onTab={onTab}
        width={sizes[mapKey.TABLE].width - PANEL_PADDING}
      />
    </Panel>
  )
}

PanelTable.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
