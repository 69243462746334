import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import useHeaderSearch, { TYPE_HEADER } from '../../hooks/useHeaderSearch'
import useWrapper from '../../hooks/useWrapper'
import SearchBox from '../components/SearchBox'
import Title from '../components/Title'
import VerticalLine from '../components/VerticalLine'
import { DropdownItem } from './DropdownItem'
import { PriceData } from './PriceData'
import { SIMPLE_SEARCH_PARAMS } from './constants'
import {
  changeLoadingSearch,
  selectBasicInfo,
  selectListCompany,
  selectLoadingSearch,
} from './store/slice'
import { getSearchCompanyData } from './store/thunk'

const TopInfo = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useCustomNavigate()
  const { width: wrapperWidth } = useWrapper()

  const basicInfo = useSelector(selectBasicInfo)
  const loadingSearch = useSelector(selectLoadingSearch)
  const listCompany = useSelector(selectListCompany)

  useHeaderSearch(
    TYPE_HEADER.STOCK,
    props.isOnlySearchHOHAUP,
    [],
    null,
    props.comTypeIds,
  )

  const options = useMemo(
    () =>
      !loadingSearch
        ? listCompany.map((company) => {
            return { id: company.organizationId, value: { ...company } }
          })
        : [],
    [listCompany, loadingSearch],
  )

  const searchCompany = (keyword) => {
    let params = {
      isIncludeBond: props.isIncludeBond,
      isOnlySearchHOHAUP: props.isOnlySearchHOHAUP,
      ComTypes: props.comTypeIds ? props.comTypeIds.join() : undefined,
      Page: SIMPLE_SEARCH_PARAMS.page,
      PageSize: SIMPLE_SEARCH_PARAMS.pageSize,
    }
    if (keyword) {
      params = { ...params, Term: keyword }
    }
    dispatch(getSearchCompanyData(params))
  }

  const handleSearch = (keyword) => {
    searchCompany(keyword.toLowerCase())
  }

  const handleFocusInputSearch = () => {
    const params = {
      isIncludeBond: props.isIncludeBond,
      isOnlySearchHOHAUP: props.isOnlySearchHOHAUP,
      ComTypes: props.comTypeIds ? props.comTypeIds.join() : undefined,
      Page: SIMPLE_SEARCH_PARAMS.page,
      PageSize: SIMPLE_SEARCH_PARAMS.pageSize,
    }
    dispatch(getSearchCompanyData(params))
  }

  const handleSelect = (company) => {
    navigate(location.pathname, {
      organizationId: company.organizationId,
      groupId: null,
      ticker: company.tickerOrTaxCode,
      companyName: company.organizationShortName,
      exchange: company.exchangeCode,
      sector: company.sector,
    })
  }

  const handleChangeLoading = () => {
    dispatch(changeLoadingSearch(true))
  }

  return (
    <div
      className="top-info"
      style={{
        width: wrapperWidth || '100%',
        height: props.height ?? '48px',
      }}
    >
      {props.title && (
        <>
          <Title title={props.title} />
          <VerticalLine mr={12} />
        </>
      )}
      {props.radioContent && (
        <>
          {props.radioContent}
          <VerticalLine mr={12} />
        </>
      )}
      <SearchBox
        title={basicInfo?.ticker}
        description={[basicInfo?.companyName, basicInfo?.exchange]
          .filter((value) => !!value)
          .join('/')}
        onFocus={handleFocusInputSearch}
        onTextChange={handleSearch}
        options={options}
        loading={loadingSearch}
        onSelect={props.handleSelect || handleSelect}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
        classNameInputSearch={props?.style}
        onChangeLoading={handleChangeLoading}
      />
      {!props.isHidePriceData && <PriceData />}
    </div>
  )
}

TopInfo.propTypes = {
  title: PropTypes.string,
  handleSelect: PropTypes.func,
  radioContent: PropTypes.element,
  isIncludeBond: PropTypes.bool,
  isOnlySearchHOHAUP: PropTypes.bool,
  comTypeIds: PropTypes.array, // COM_TYPE_ID values
  height: PropTypes.string,
  isHidePriceData: PropTypes.bool,
}

TopInfo.defaultProps = {
  isIncludeBond: false,
  isOnlySearchHOHAUP: false,
  height: undefined,
  isHidePriceData: false,
}

export default TopInfo
