import { COMPONENT } from '../../../configs/Layout'
import PanelFilter from '../../common/panelFilter'
import { withWrapper } from '../../common/withWrapper'
import Filter from './filter'
import PanelTopMover from './PanelTopMover'

const MAP_KEY = {
  FILTER: 'FILTER',
  TOP_MOVER: 'TOP_MOVER',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.FILTER]: {
    minWidth: 236,
    minHeight: 600,
  },
  [MAP_KEY.TOP_MOVER]: {
    minWidth: 704,
    minHeight: 600,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: `calc(240px - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.FILTER],
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.TOP_MOVER,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOP_MOVER}`]: {
    width: `calc(100% - 240px)`,
    height: '100%',
    top: 0,
    left: `calc(240px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.TOP_MOVER],
    minTop: 0,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.FILTER].minWidth + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <div className="w-100 h-100 position-relative">
      <PanelFilter
        title={'market.topMover.FILTER'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>
      <PanelTopMover
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_MOVER}
        sizes={sizes}
        setSizes={setSizes}
      />
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
