import watchlistDetail from './watchlistDetail'
import watchlistTable from './watchlistTable'

const common = {
  TITLE: 'Danh mục của tôi',
}

const watchlist = {
  watchlistTable,
  watchlistDetail,
  common,
}

export default watchlist
