import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import UseTimeZone from '../../hooks/useTimeZone'

export const ChartTooltipContent = ({
  tooltipData,
  tooltipSchema,
  xAxisKey,
  decimalLengths,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()

  const renderTooltipTitle = () => {
    let value = tooltipData[xAxisKey]
    if (tooltipSchema.title?.formatValue) {
      value = tooltipSchema.title.formatValue(tooltipData)
    } else if (tooltipSchema.title?.formatDate) {
      value = formatDateTime(
        new Date(tooltipData[xAxisKey]),
        tooltipSchema.title?.formatDate || FORMAT.DATE,
        locale,
        timezone,
      )
    }

    return (
      <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
        <Span style={{ fontSize: 10 }}>{value}</Span>
      </div>
    )
  }

  const renderTooltipContent = () => {
    return tooltipSchema.content.map((schema) => {
      const keys = schema.keys && schema.keys.length ? schema.keys : []
      return keys.map((key) => (
        <div key={key} className="d-flex j-b">
          <div>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {tooltipSchema.formatMappingDisplayName ? (
                tooltipSchema.formatMappingDisplayName(key, tooltipData)
              ) : (
                <Translate
                  value={tooltipSchema.mappingDisplayName[key] || key}
                />
              )}
              :
            </Span>
          </div>
          <div className="ml-8">
            <Span
              style={{
                fontSize: 11,
                fontWeight: 500,
                color:
                  schema.formatValueColor &&
                  schema.formatValueColor(tooltipData[key], key),
                whiteSpace: 'nowrap',
              }}
            >
              {schema.formatValue
                ? schema.formatValue(tooltipData[key], key)
                : formatVal(
                    tooltipData[key],
                    schema.decimalLength || decimalLengths[schema.yAxisId],
                  )}
              {schema.unit && (
                <>
                  {' '}
                  <Translate value={schema.unit} />
                </>
              )}
            </Span>
          </div>
        </div>
      ))
    })
  }

  return (
    <>
      {renderTooltipTitle()}
      {renderTooltipContent()}
    </>
  )
}

ChartTooltipContent.propTypes = {
  tooltipData: PropTypes.object.isRequired,
  tooltipSchema: PropTypes.shape({
    title: PropTypes.shape({
      formatDate: PropTypes.object,
      formatValue: PropTypes.func,
    }),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        keys: PropTypes.arrayOf(PropTypes.string).isRequired,
        yAxisId: PropTypes.string.isRequired,
        unit: PropTypes.string,
        decimalLength: PropTypes.number,
        formatValue: PropTypes.func,
        formatValueColor: PropTypes.func,
      }),
    ).isRequired,
    mappingDisplayName: PropTypes.object.isRequired,
    formatMappingDisplayName: PropTypes.func,
  }).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  decimalLengths: PropTypes.object.isRequired,
}

ChartTooltipContent.defaultProps = {
  tooltipData: {},
  tooltipSchema: {
    content: [],
    mappingDisplayName: {},
  },
}
