import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Input } from '../html/Input'
import style from './style.module.css'

const ThePagination = ({
  currentPage,
  totalPages,
  changeCurrentPage,
  renderContentPagination,
  renderRightContent,
  isHide,
  changePage,
  loading,
}) => {
  const [pagination, setPagination] = useState(currentPage)

  const handleChangePage = (page) => {
    if (currentPage !== page) {
      if (page > totalPages || isNaN(page) || page < 1) {
        setPagination(currentPage)
      } else {
        changeCurrentPage(page)
        changePage?.(page)
      }
    }
  }

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      handleChangePage(parseInt(e.target.value))
      e.currentTarget.blur()
    }
  }

  const handlePreviousPage = (page) => {
    if (loading) return

    if (currentPage > 1) {
      changeCurrentPage(page)
      changePage?.(page)
    }
  }

  const handleNextPage = (page) => {
    if (loading) return

    if (currentPage < totalPages) {
      changeCurrentPage(page)
      changePage?.(page)
    }
  }

  useEffect(() => {
    setPagination(currentPage)
  }, [currentPage])

  return (
    <>
      <div className="table-info">
        <div className="table-info-left">
          {renderContentPagination && renderContentPagination()}
        </div>
        {!isHide && (
          <div className="table-info-right">
            <div className="tb-text-info align-center">
              <span>
                <Translate value={'common.table.LB_PAGE'} />
              </span>
              <Input
                style={{ fontSize: 12 }}
                type="number"
                value={pagination}
                onChange={(e) => setPagination(parseInt(e.target.value))}
                onKeyDown={(e) => handleKeyEnter(e)}
                onBlur={(e) => handleChangePage(parseInt(e.target.value))}
              />
              <span style={{ whiteSpace: 'nowrap' }}>
                <Translate value={'common.table.LB_OF'} /> {totalPages}
              </span>
            </div>
            <div className="tb-page">
              <div className="group-btn-page d-flex">
                <button
                  className={`btn btn-prev h-20 ${style.btnLeft}`}
                  onClick={() => handlePreviousPage(currentPage - 1)}
                >
                  <i
                    className={`${
                      currentPage > 1 ? style.colorButtonActive : ''
                    } icon-arrow-right`}
                  />
                </button>
                <button
                  className={`btn btn-next h-20 ${style.btnRight}`}
                  onClick={() => handleNextPage(currentPage + 1)}
                >
                  <i
                    className={`${
                      currentPage < totalPages ? style.colorButtonActive : ''
                    } icon-arrow-right`}
                  />
                </button>
              </div>
            </div>
            <div className="ml-5">
              {renderRightContent && renderRightContent()}
            </div>
          </div>
        )}
      </div>
    </>
  )
}

ThePagination.propTypes = {
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  changeCurrentPage: PropTypes.func,
  renderRightContent: PropTypes.func,
  isHide: PropTypes.bool,
}

ThePagination.defaultProps = {
  isHide: false,
  loading: false,
}

export default ThePagination
