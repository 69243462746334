import { Foreign } from './foreign'
import {
  downloadStatisticsForeignDetail,
  downloadStatisticsForeignOverview,
} from './foreign/store/thunk'
import { OrderStatistic } from './orderStatistic'
import {
  downloadIndexOrderStatisticsDetail,
  downloadIndexOrderStatisticsOverview,
} from './orderStatistic/store/thunk'
import { Overview } from './overview'
import {
  downloadStatisticsOverviewDetail,
  downloadStatisticsOverviewOverview,
} from './overview/store/thunk'
import { Proprietary } from './proprietary'
import {
  downloadProprietaryDetail,
  downloadProprietaryOverview,
} from './proprietary/store/thunk'
import { StatisticsInvest } from './statisticInvest'
import {
  downloadIndexStatisticsByInvestorDetail,
  downloadIndexStatisticsByInvestorOverview,
} from './statisticInvest/store/thunk'

export const TAB_TYPE = {
  OVERVIEW: 'overview',
  ORDER_STATISTIC: 'overStatistic',
  FOREIGN: 'foreign',
  PROPRIETARY: 'proprietary',
  STATISTIC_INVEST: 'statisticInvest',
}

export const TAB_COMPONENT_DATA = {
  [TAB_TYPE.OVERVIEW]: {
    title: 'market.marketInDepth.indexStatistics.OVER_VIEW',
    Component: Overview,
  },
  [TAB_TYPE.ORDER_STATISTIC]: {
    title: 'market.marketInDepth.indexStatistics.ORDER_STATISTICS',
    Component: OrderStatistic,
  },
  [TAB_TYPE.FOREIGN]: {
    title: 'market.marketInDepth.indexStatistics.FOREIGN',
    Component: Foreign,
  },
  [TAB_TYPE.PROPRIETARY]: {
    title: 'market.marketInDepth.indexStatistics.PROPRIETARY',
    Component: Proprietary,
  },
  [TAB_TYPE.STATISTIC_INVEST]: {
    title: 'market.marketInDepth.indexStatistics.STATISTICS_INVESTOR',
    Component: StatisticsInvest,
  },
}

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
}

export const TABLE_TYPE_RADIO = [
  {
    title: 'market.marketInDepth.indexStatistics.priceData.tableType.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    title: 'market.marketInDepth.indexStatistics.priceData.tableType.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]

export const TABLE_DOWNLOAD = {
  [TAB_TYPE.OVERVIEW]: {
    [TABLE_TYPE.OVERVIEW]: (params) =>
      downloadStatisticsOverviewOverview(params),
    [TABLE_TYPE.DETAIL]: (params) => downloadStatisticsOverviewDetail(params),
  },
  [TAB_TYPE.FOREIGN]: {
    [TABLE_TYPE.OVERVIEW]: (params) =>
      downloadStatisticsForeignOverview(params),
    [TABLE_TYPE.DETAIL]: (params) => downloadStatisticsForeignDetail(params),
  },
  [TAB_TYPE.ORDER_STATISTIC]: {
    [TABLE_TYPE.OVERVIEW]: (params) =>
      downloadIndexOrderStatisticsOverview(params),
    [TABLE_TYPE.DETAIL]: (params) => downloadIndexOrderStatisticsDetail(params),
  },
  [TAB_TYPE.STATISTIC_INVEST]: {
    [TABLE_TYPE.OVERVIEW]: (params) =>
      downloadIndexStatisticsByInvestorOverview(params),
    [TABLE_TYPE.DETAIL]: (params) =>
      downloadIndexStatisticsByInvestorDetail(params),
  },
  [TAB_TYPE.PROPRIETARY]: {
    [TABLE_TYPE.OVERVIEW]: (params) => downloadProprietaryOverview(params),
    [TABLE_TYPE.DETAIL]: (params) => downloadProprietaryDetail(params),
  },
}
