import SelectCustom from '../../../../common/selectCustom'
import { LATEST_YEARS } from '../../constants'
import style from './style.module.css'

const YearlySecurities = ({ value = {}, onChange }) => {
  const handleChange = (year) => {
    onChange({ ...value, year })
  }

  return (
    <div className={style.dropdownCustom}>
      <SelectCustom
        isI18n={false}
        selectData={LATEST_YEARS}
        value={value.year}
        handleChange={handleChange}
        fontSizeItem={10}
        themeWhite
        appendStyleDivValue={{ border: 'solid 1px #939393', height: 24 }}
      />
    </div>
  )
}

export default YearlySecurities
