import { useSelector } from 'react-redux'
import EarningGrowth from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { Panel } from '../../../../common/panel'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { COM_GROUP } from '../../../../constants/Group'
import { formatNumber } from '../../../../utils/Common'

const PanelTopRevenue = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const sectorInfo = useSelector(selectSectorInfo)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const nameScreen = UseI18n(
    'constants.route.sector.sectorConstituents.overview.LABEL',
  )
  const chartName = UseI18n(
    typeSector === COM_GROUP.BANK
      ? 'sector.sectorConstituents.overview.TOP_TOTAL_OPERATING_INCOME'
      : 'sector.sectorConstituents.overview.TOP_REVENUE',
  )

  return (
    <Panel
      title={
        typeSector === COM_GROUP.BANK
          ? 'sector.sectorConstituents.overview.TOP_TOTAL_OPERATING_INCOME'
          : 'sector.sectorConstituents.overview.TOP_REVENUE'
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: 'panelTopRevenueId',
        nameScreen,
        chartName,
        tickerName: sectorInfo.icbName,
      }}
    >
      <EarningGrowth
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelTopRevenue
