import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../common/helper'
import { TIME_TYPE } from '../../balanceOfTrade/constants'
import { getMaxDateOverview } from '../../store/thunk'
import { getKeyNameById } from '../helper'
import { getImportExportAndTradeBalance } from './thunk'

const slice = createSlice({
  name: 'economy/importExport/importExportAndTradeBalance',
  initialState: {
    isLoading: true,
    data: [],
    currentTimeType: TIME_TYPE.MONTHLY,
    locale: 'en',
    maxDate: null,
  },
  reducers: {
    changeTimeTypeImExChart: (state, action) => {
      state.currentTimeType = action.payload
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportExportAndTradeBalance.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getImportExportAndTradeBalance.fulfilled,
      (state, action) => {
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.vnTypeId,
            name: item.vnTypeName,
            year: item.realYear,
            month: item.realMonth,
            value: item.value / 1000000000,
            keyName: getKeyNameById(item.vnTypeId),
          })) || []

        state.data = convertData(
          payloadData,
          state.currentTimeType,
          state.locale,
          state.maxDate,
        ).reverse()
        state.isLoading = false
      },
    )
    builder.addCase(
      getImportExportAndTradeBalance.rejected,
      (state, action) => {
        state.isLoading = action.payload.loading
      },
    )

    builder.addCase(getMaxDateOverview.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateOverview.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateOverview.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data

export const { changeTimeTypeImExChart, changeLocale } = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, currentTimeType, locale, maxDate) => {
  const isYearly = currentTimeType === TIME_TYPE.YEARLY
  const isAcc = currentTimeType === TIME_TYPE.ACC_MONTHLY

  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(data).map((item) =>
        getYearKey(item.year, maxDate, locale),
      )
    : isAcc
    ? getMonthAndYearInDataRange(data).map((item) =>
        getAccMonthAndYearKey(item.month, item.year, locale),
      )
    : getMonthAndYearInDataRange(data).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
        keyName: item.keyName ?? '',
      }))
  }

  const getDataValueFromRange = getMonthAndYearInDataRange(data).map((item) => [
    ...dataWithMonthAndYear(item.month, item.year),
  ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.keyName]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
