import { SizeTracker } from '../../../../common/sizeTracker'
import SelectionGroup from './SelectionGroup'
import TableComponent from './TableComponent'

const TableTotalGDP = ({ height }) => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <SelectionGroup isRealOption />
            {typeof size.height === 'number' && (
              <TableComponent height={height - size.height} />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableTotalGDP
