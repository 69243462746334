import { useEffect, useState } from 'react'
import { Input } from '../../../../../common/html/Input'
import {
  hexAndAlphaPercentageToHex,
  hexToHexAndAlphaPercentage,
  hexToRgba,
} from '../../../../../utils/Color'
import { ReactComponent as Bookmark } from '../../../../icon/icon-bookmark-outline.svg'
import { ReactComponent as BookmarkUnicorn } from '../../../../icon/icon-bookmark-unicorn.svg'
import { useGetUserSettings } from '../../../useGetUserSettings'
import styles from '../../style.module.css'

const hexRegex = new RegExp('^#([a-fA-F0-9]){3}$|[a-fA-F0-9]{6}$')
const VALID_HEX_LENGTH = [4, 7]

export const ColorInputComponent = ({ color, onChange }) => {
  const { favoriteColors, addFavoriteColor } = useGetUserSettings()

  const [hex, setHex] = useState('#000000')
  const [alpha, setAlpha] = useState(100)

  // Actions
  const handleChangeColor = (hex, alpha) => {
    const newColor = hexAndAlphaPercentageToHex(hex, alpha)
    onChange(newColor)
  }

  const handleHexChange = (e) => {
    let value = e.target.value
    setHex(value)

    if (!value.startsWith('#')) value = '#' + value
    if (hexRegex.test(value) && VALID_HEX_LENGTH.includes(value?.length)) {
      handleChangeColor(value, alpha)
    }
  }

  const handleAlphaChange = (e) => {
    const value = e.target.value

    if (typeof Number(value) === 'number') {
      let num = Math.ceil(value)
      if (value < 0) num = 0
      if (value > 100) num = 100
      setAlpha(num)
      handleChangeColor(hex, value)
    }
  }

  const handleAddFavoriteColor = () => {
    addFavoriteColor(hex)
  }

  // Use effect
  useEffect(() => {
    if (color) {
      const { hex, alpha } = hexToHexAndAlphaPercentage(color)
      setHex(hex)
      setAlpha(alpha)
    }
  }, [color])

  return (
    <div className={styles.settingBlockItemColorInputContainer}>
      <div className="d-flex ali-center">
        <div
          style={{
            backgroundColor: hexToRgba(hex, alpha),
          }}
          className={styles.settingBlockItemColorPreview}
        />
        <Input
          value={hex}
          className={styles.settingBlockItemColorInputHex}
          onChange={handleHexChange}
        />
        <div>
          <input
            value={alpha}
            type="number"
            min={0}
            max={100}
            className={styles.settingBlockItemColorInputAlpha}
            onChange={handleAlphaChange}
          />
        </div>
        <button
          className={[
            styles.settingBlockItemColorButtonAdd,
            'centerItems',
            styles.transparent,
          ].join(' ')}
          onClick={handleAddFavoriteColor}
        >
          {favoriteColors.includes(hex) ? <BookmarkUnicorn /> : <Bookmark />}
        </button>
      </div>
      <div className={styles.settingBlockItemColorDivider} />
    </div>
  )
}
