import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CONTRIBUTION_CREDIT_GROWTH } from '.'
import { Footer } from '../../../../common/chart/footer'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import ChartComponent from './ChartComponent'
import Header from './Header'
import { renderListFooter } from './helper'
import style from './index.module.css'
import {
  getKeyNotDuplicate,
  getRadioValue,
  getStackChartData,
  getStackChartLoading,
  getTimeRange,
} from './store/slice'
import { getContributionToCreditGrowth } from './store/thunk'

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const stackedChartData = useSelector(getStackChartData)
  const stackedChartLoading = useSelector(getStackChartLoading)
  const timeRange = useSelector(getTimeRange)
  const keyNotDuplicate = useSelector(getKeyNotDuplicate)
  const radioValue = useSelector(getRadioValue)
  const locale = useSelector((state) => state.i18n.locale)
  const isValue = radioValue === 'VALUE'

  useEffect(() => {
    dispatch(
      getContributionToCreditGrowth({
        TimeRange: timeRange,
      }),
    )
  }, [timeRange, locale])

  return (
    <div className="flex-column h-100">
      <Header />
      <div id={CONTRIBUTION_CREDIT_GROWTH} className={style.wrapChartComponent}>
        {stackedChartLoading && <Loading />}
        {!stackedChartLoading && (
          <>
            {stackedChartData.length === 0 && <NoData />}
            {stackedChartData.length > 0 && (
              <>
                <ChartComponent
                  width={width}
                  height={height}
                  data={stackedChartData}
                  keyXAxis={'monthYear'}
                  lineKey={'lineKey'}
                  keyNotDuplicate={keyNotDuplicate}
                  isValue={isValue}
                  timeRange={timeRange}
                />
                <Footer key={width} list={renderListFooter(keyNotDuplicate)} />
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

Container.propTypes = { height: PropTypes.number, width: PropTypes.number }

export default Container
