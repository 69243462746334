export const SEARCH_TIME_FRAME = {
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  HALF_A_YEAR: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',
}

export const SEARCH_STATUS = {
  ALL: 'All',
  PENDING: 'Pending',
  DONE: 'Done',
  PAUSE: 'Pause',
  PLAN: 'Plan',
  CANCEL_PLAN: 'CancelPlan',
  CHANGE_PLAN: 'ChangePlan',
}
