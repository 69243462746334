import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../../common/hooks/useTimeZone.js'
import { Span } from '../../../../../common/html/Span.js'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab.js'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime.js'
import { ALL_MATCH_DEAL_RADIO, DATE_TABS, TCPH_TP_TABS } from '../constants.js'
import { changeFilter, keys, selectFilterTopLiquidity } from '../store/slice.js'
import {
  getTopLiquidityDataByBond,
  getTopLiquidityDataByBondByReload,
  getTopLiquidityDataByIssuer,
  getTopLiquidityDataByIssuerByReload,
} from '../store/thunk.js'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()
  const timer = useRef()
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const { TotalValueType, TimeRange, Top, TCPH_TP, StartDate, EndDate } =
    useSelector(selectFilterTopLiquidity)

  const [reload, setReload] = useState(false)

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'TimeRange',
        value: item.value,
      }),
    )
  }

  const handleChangeTCPH_TP = (item) => {
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'TCPH_TP',
        value: item.value,
      }),
    )
  }

  const handleChangeParams = (item) => {
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'TotalValueType',
        value: item,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'TimeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'StartDate',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.TOP_LIQUIDITY,
        key: 'EndDate',
        value: payload.endDate,
      }),
    )
  }

  const handleSearch = () => {
    const payload = {
      TotalValueType,
      TimeRange,
      Top,
    }

    TCPH_TP === 'TCPH'
      ? dispatch(getTopLiquidityDataByIssuerByReload(payload))
      : dispatch(getTopLiquidityDataByBondByReload(payload))
  }

  // Use effect
  useEffect(() => {
    const payload = {
      TotalValueType,
      Top,
    }

    if (TimeRange === calendarTabKey) {
      payload.StartDate = getISOStartOrEndOfDay(StartDate, timeZone, true)
      payload.EndDate = getISOStartOrEndOfDay(EndDate, timeZone, true)
    } else {
      payload.TimeRange = TimeRange
    }

    TCPH_TP === 'TCPH'
      ? dispatch(getTopLiquidityDataByIssuer(payload))
      : dispatch(getTopLiquidityDataByBond(payload))
  }, [locale, TotalValueType, TimeRange, Top, TCPH_TP, StartDate, EndDate])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: 9,
      minute: 0,
      second: 0,
      millisecond: 0,
    })

    const endTime = moment().utcOffset(timeZone)

    endTime.set({
      hour: 15,
      minute: 15,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) - 30000 < 0) {
      if (endTime - moment().utcOffset(timeZone) - 30000) {
        setReload(true)
        return
      }

      setReload(false)

      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        setReload(true)
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    const countDownDate = moment().utcOffset(timeZone)

    countDownDate.set({
      hour: 15,
      minute: 15,
      second: 0,
      millisecond: 0,
    })

    if (countDownDate - moment().utcOffset(timeZone) - 30000 < 0) {
      return
    }

    const myInterval = setInterval(() => {
      const timeout = countDownDate - moment().utcOffset(timeZone)

      if (timeout < 0) {
        setReload(false)
        clearInterval(myInterval)
      }
    }, 1000)

    return () => {
      clearInterval(myInterval)
    }
  }, [])

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current)
      timer.current = null
    }

    if (TimeRange === 'OneDay' && reload) {
      timer.current = setInterval(handleSearch, 30000)
      return
    }

    return () => {
      clearInterval(timer.current)
      timer.current = null
    }
  }, [TotalValueType, TimeRange, Top, TCPH_TP, reload])

  useEffect(() => {
    return () => {
      clearInterval(timer.current)
      timer.current = null
    }
  }, [])

  return (
    <>
      <div className="d-flex justify-content-space-between ali-center mb-8">
        <div
          className="d-flex ali-center justify-content-space-between"
          style={{ gap: 8 }}
        >
          <DispatchActionTab
            data={TCPH_TP_TABS}
            activeTab={TCPH_TP}
            onChangeTab={handleChangeTCPH_TP}
            itemStyle={{
              width: '50%',
              fontWeight: 600,
            }}
          />
          {ALL_MATCH_DEAL_RADIO.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center cursor-pointer ${
                  TotalValueType === value ? style.radioActive : ''
                }`}
                onClick={() => handleChangeParams(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={TotalValueType === value}
                  readOnly={true}
                />
                <Span
                  style={{
                    fontSize: 12,
                    fontWeight: 340,
                  }}
                >
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div>
        <DispatchActionTabWithCalendar
          id="overviewTopLiquidity"
          tabs={DATE_TABS}
          activeTab={TimeRange}
          startDate={StartDate}
          endDate={EndDate}
          datePreset={DATE_TABS}
          excludeDateIntervals={[
            {
              start: new Date(),
              end: new Date(
                new Date().setFullYear(new Date().getFullYear() + 30),
              ),
            },
          ]}
          onChangeTab={handleChangeTimeType}
          onChangeCalendar={handleChangeCalendar}
        />
      </div>
    </>
  )
}

export default Filter
