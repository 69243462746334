import PropTypes from 'prop-types'
import TextEllipsis from '../../../textEllipsis'
import style from './index.module.css'

export const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      {value.ticker ? (
        <div className={style.w25}>
          <TextEllipsis text={value.ticker} />
        </div>
      ) : (
        <span className={style.w25}>&nbsp;</span>
      )}
      <div className={style.textLongW65}>
        <TextEllipsis text={value.organizationShortName} isI18n={false} />
      </div>
    </div>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
