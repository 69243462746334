import PropTypes from 'prop-types'
import { LOCAL_STORAGE_KEY } from '../../constants/Common'

export const OpenWindowIcon = ({ windowRoute, windowSize }) => {
  const onClick = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY.OPEN_WINDOW, windowRoute)
    window.open(
      windowRoute,
      '_blank',
      `location=yes,height=${windowSize.height},width=${windowSize.width},scrollbars=yes,status=yes`,
    )
  }

  return (
    <a onClick={onClick}>
      <i className="icon-external" />
    </a>
  )
}

OpenWindowIcon.propTypes = {
  windowRoute: PropTypes.string.isRequired,
  windowSize: PropTypes.object.isRequired,
}
