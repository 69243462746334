import SelectCustom from '../../../../common/selectCustom'
import { exchangeOptions } from '../constants'

export const SelectType = ({ activeTab, onChangeTab, style }) => {
  return (
    <div style={{ width: 150, ...style }}>
      <SelectCustom
        value={activeTab}
        isI18n={true}
        selectData={exchangeOptions}
        handleChange={onChangeTab}
      />
    </div>
  )
}
