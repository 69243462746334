import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import { STATE_BUDGET_CHART_TYPE } from './constants'
import { getLeftFrameValue } from './store/slice'

export const REVENUE_STRUCTURE = 'REVENUE_STRUCTURE'

const PanelRevenueStructure = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const leftFrameValue = useSelector(getLeftFrameValue)

  const isBalanceChart = leftFrameValue === STATE_BUDGET_CHART_TYPE.BALANCE
  const isRevenueChart = leftFrameValue === STATE_BUDGET_CHART_TYPE.REVENUE

  const nameScreen = UseI18n('economy.fiscal.stateBudget.STATE_BUDGET')
  const chartName = isBalanceChart
    ? UseI18n('economy.fiscal.stateBudget.BALANCE_CHART_NAME')
    : isRevenueChart
    ? UseI18n('economy.fiscal.stateBudget.REVENUE_CHART_NAME')
    : UseI18n('economy.fiscal.stateBudget.EXPENDITURE_CHART_NAME')

  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'economy.fiscal.stateBudget.REVENUE_STRUCTURE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      titleJpg={chartName}
      isDownloadJpg
      downloadJpgParams={{
        domId: REVENUE_STRUCTURE,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <Container
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelRevenueStructure
