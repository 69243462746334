export const XAxisVerticalLabel = ({ x, y, stroke, payload, angle }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={10}
        y={0}
        dy={5}
        textAnchor="end"
        fill="#666"
        transform={`rotate(${-angle})`}
      >
        {payload.value}
      </text>
    </g>
  )
}
