import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import style from './index.module.css'

const PopupDeleteBond = ({ handleClose, handleSubmit, data }) => {
  return (
    <Popup>
      <div className="modal modal-small" style={{ width: 364 }}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="bond.bondScreening.createBond.DELETE_TITLE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ paddingBottom: 16 }}>
          <div className="content" style={{ minHeight: 80 }}>
            <Span>
              <Translate
                value="bond.bondScreening.createBond.DELETE_CONTENT"
                ticker={data?.ticker ?? ''}
              />
            </Span>
          </div>
          <div className={style.groupBtn} style={{ padding: 0 }}>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={handleClose}
                className={`btn normal w-80 h-20`}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_CANCEL" />
              </Button>
              <Button
                onClick={() => handleSubmit(data)}
                className={`btn btn-blue w-80 h-20 `}
                style={{ fontWeight: 600 }}
              >
                <Translate value="common.button.BUTTON_DELETE" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupDeleteBond
