import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class DataExplorerProxy extends ProxyBase {
  getMostRecentDate(params) {
    return this.get('MostRecentSettingDate', params)
  }

  getTemplates(params) {
    return this.get('GetTemplates', params)
  }

  saveTemplate(params) {
    return this.post('SaveTemplate', params)
  }

  updateTemplate(params) {
    return this.post('UpdateTemplate', params)
  }

  renameTemplate(params) {
    return this.post('RenameTemplate', params)
  }

  getTemplate(params) {
    return this.get('GetTemplate', params)
  }

  getShareTemplate(params) {
    return this.get('GetShareTemplate', params)
  }

  deleteTemplate(params) {
    return this.delete('DeleteTemplate', params)
  }

  getMostRecent(params) {
    return this.post('MostRecentDate', params, undefined, undefined, true)
  }

  getFiinXTemplates(params) {
    return this.get('GetFiinXTemplates', params)
  }

  getIndicatorFiinXTemplate(params) {
    return this.get('FiinXTemplate/Query', params)
  }

  getTickerFiinXTemplate(params) {
    return this.post('FiinXTemplate/Ticker/Query', params)
  }

  getFiinXTemplateShareIssueMethod(params) {
    return this.get('FiinXTemplate/ShareIssueMethod', params)
  }

  saveWorkSpace(params) {
    return this.post('SaveWorkSpace', params)
  }

  getWorkSpace(params) {
    return this.get('GetWorkSpace', params)
  }
}

export default new DataExplorerProxy(
  ServiceProxyConfig.Tool.DataExplorer.ServiceUrl,
)
