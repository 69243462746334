import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import Container from './Container'
import { getMinYear, getPrevMinYear } from './store/slice'
import { downloadGDPBySpendingMethodStructure } from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const GDPStructure = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const nominalRealValue = useSelector(getNominalRealValue)
  const minYear = useSelector(getMinYear)
  const prevMinYear = useSelector(getPrevMinYear)

  const downloadCsv = () => {
    const params = {
      IsNominal: nominalRealValue === NOMINAL,
    }

    if (minYear || prevMinYear) {
      params.Year = minYear || prevMinYear
    }

    return downloadGDPBySpendingMethodStructure(params)
  }

  return (
    <Panel
      title={'economy.gdp.gdp.GDP_STRUCTURE_SPENDING_METHOD'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default GDPStructure
