import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'
import {
  checkAllItem,
  getCheckedAll,
  getListDisplay,
  handleCheckedAll,
  handleRemoveManyTicker,
} from './store/slice'

const AllCheckbox = () => {
  const dispatch = useDispatch()

  const isCheckedAll = useSelector(getCheckedAll)
  const listDisplay = useSelector(getListDisplay)

  const displayedValues = Object.values(listDisplay).filter(
    (value) => value.isDisplay,
  )

  const displayedItemIds = Object.keys(listDisplay).filter(
    (id) => listDisplay[id].isDisplay,
  )

  const itemCheckedIds = Object.keys(listDisplay).filter(
    (id) => listDisplay[id].isDisplay && listDisplay[id].isChecked,
  )

  const isCurrentCheckedAll =
    displayedValues.length > 0 &&
    displayedValues.every((item) => item.isChecked)
  const hasTickerItem = displayedValues.some((item) => item.isDisplay)

  const handleCheckAll = () => {
    dispatch(handleCheckedAll(true))
    dispatch(checkAllItem({ isCheckedAll, displayedItemIds }))
  }

  const handleUncheckAll = () => {
    dispatch(handleCheckedAll(false))
    dispatch(checkAllItem({ isCheckedAll, displayedItemIds }))
  }

  const removeCheckboxChecked = () => {
    dispatch(handleRemoveManyTicker(itemCheckedIds))
  }

  useEffect(() => {
    if (isCurrentCheckedAll) {
      dispatch(handleCheckedAll(true))
    } else {
      dispatch(handleCheckedAll(false))
    }
  }, [listDisplay, isCurrentCheckedAll])

  return (
    <>
      {hasTickerItem && (
        <div className={`group-btn-action ${style.wrapCheckboxAll}`}>
          <div className="d-flex align-center" style={{ marginRight: -4 }}>
            {isCheckedAll ? (
              <i
                className={`icon-minus-stroke mr-8 ${style.checkboxAll}`}
                onClick={handleUncheckAll}
              />
            ) : (
              <Span onClick={handleCheckAll}>
                <input
                  type="checkbox"
                  className={`checkbox line mr-8 ${style.btnUncheck}`}
                  readOnly
                />
              </Span>
            )}
            <div className={style.checkAllBorder} />
            <button
              onClick={removeCheckboxChecked}
              className={`${style.btnTrash}`}
            >
              <i className="icon-trash" />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default AllCheckbox
