import heatmapProxy from '../../proxies/market/HeatmapProxy'
import { ServiceBase } from '../ServiceBase'

class HeatmapService extends ServiceBase {
  getHeatMapChart(params) {
    return this.getData(() => heatmapProxy.getHeatMapChart(params))
  }
}

export default new HeatmapService()
