import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getTickerByExchange } from './thunk'

const initialState = {
  listTicker: [],
  loadingSearch: false,
  listCompareChecked: [],
  keySearchTicker: '',
}

export const slice = createSlice({
  name: 'marketDealSearchTicker',
  initialState,
  reducers: {
    searchAllTicker: (state, action) => {
      if (action.payload) {
        state.listCompareChecked = state.listTicker
      } else {
        state.listCompareChecked = []
      }
    },

    searchData: (state, action) => {
      state.keySearchTicker = action.payload?.toLowerCase()
    },

    addCompareChecked: (state, action) => {
      state.listCompareChecked = [...state.listCompareChecked, action.payload]
    },
    removeCompareChecked: (state, action) => {
      state.listCompareChecked = state.listCompareChecked.filter(
        (element) => element.organizationId !== action.payload.organizationId,
      )
    },
    setCompareChecked: (state, action) => {
      state.listCompareChecked = [action.payload]
    },
    clearCompareChecked: (state) => {
      state.listCompareChecked = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTickerByExchange.pending, (state) => {
      state.loadingSearch = true
    })
    builder.addCase(getTickerByExchange.fulfilled, (state, action) => {
      const payload = action.payload
      state.loadingSearch = false
      state.listCompareChecked = payload.tickerRedirect
        ? [payload.tickerRedirect]
        : payload.data
      state.listTicker = payload.data
    })
    builder.addCase(getTickerByExchange.rejected, (state) => {
      state.loadingSearch = false
    })
  },
})

export const selectListTicker = (state) => state[slice.name].listTicker
export const selectKeySearchTicker = (state) =>
  state[slice.name].keySearchTicker
export const selectListCompareChecked = (state) =>
  state[slice.name].listCompareChecked

export const {
  searchAllTicker,
  setTickerChecked,
  searchData,
  addCompareChecked,
  removeCompareChecked,
  setCompareChecked,
} = slice.actions

register(slice.name, slice.reducer)
