import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import LinkSector from '../../../../../common/dropdownLink/LinkSector'
import { Span } from '../../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { CHART_ID, TOTAL_ID } from '../constants'
import { getSectorColor } from '../helper'
import { selectValueById } from '../store/slice'
import ChartTypeTab from './ChartTypeTab'
import styles from './index.module.css'

export const TABLE_SECTOR_ID = 'SectorSegmentationTableId'

export const FirstColumnCell = ({ val, rowId, style, className }) => {
  const sectorName = useSelector(selectValueById(rowId, 'icbName'))

  const getClassName = () => {
    const rs = [className, styles.borderRightLight]
    if (rowId === TOTAL_ID) rs.push(styles.totalTextCell)
    if (rowId === CHART_ID) rs.push(styles.buySellCell)

    return rs.join(' ')
  }

  return (
    <td style={style} className={getClassName()}>
      {rowId === TOTAL_ID ? (
        <div>
          <Span style={{ fontWeight: 600, fontStyle: 'italic' }}>
            <Translate value="market.marketInDepth.localInstitution.TOTAL" />
          </Span>
        </div>
      ) : rowId === CHART_ID ? (
        <ChartTypeTab />
      ) : (
        <div className={styles.sectorDotBox}>
          <div
            style={{ background: getSectorColor(val) }}
            className={styles.sectorDot}
          />
          <div className="flex-1">
            <LinkSector icbId={rowId}>
              <TextEllipsisStaticLine val={sectorName} isI18n={false} />
            </LinkSector>
          </div>
        </div>
      )}
    </td>
  )
}
