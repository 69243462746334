export const typePopupIndicator = {
  DE02_SC09: 'DE02_SC09',
  DE02_SC10: 'DE02_SC10',
  DE02_SC11: 'DE02_SC11',
  DE02_SC12: 'DE02_SC12',
}

export const typeIndexSector = {
  INDEX: 'Index',
  SECTOR: 'Sector',
}
