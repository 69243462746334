import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/stockTopInfo'
import Content from './Content'

export const DeepTransaction = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <TopInfo
              title="market.equityTrading.deepTransaction.DEEP_TRANSACTION"
              isOnlySearchHOHAUP
            />
            {size.height && (
              <div
                className="row director-deal-page w-100 position-relative"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
