import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import Title from '../../common/topInfo/components/Title'
import Content from './Content'
import {
  getInitialDataBondInformation,
  resetStore,
  selectDataBondInformation,
} from './store/slice'

export const Portfolio = () => {
  const dispatch = useDispatch()

  const { data } = useSelector(selectDataBondInformation)

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  useEffect(() => {
    const portfolioData = JSON.parse(localStorage.getItem('portfolio_data'))

    if (!portfolioData?.length) return

    dispatch(getInitialDataBondInformation(portfolioData))
  }, [])

  useEffect(() => {
    const unloadCallback = (e) => {
      localStorage.setItem('portfolio_data', JSON.stringify(data))
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => window.removeEventListener('beforeunload', unloadCallback)
  }, [data])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.portfolio.common.TITLE" />
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                  position: 'relative',
                }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
