import { Charting } from '../../charting'
import { FinancialChart } from '../../financialChart'
import { ShareChart } from '../../financialChart/shareChart'
import DailyReport from '../../newsReport/dailyReport/DailyReport'
import { Search } from '../../search'
import { Corporate } from '../../tools/dataExplorer/corporate'
import { Economy } from '../../tools/dataExplorer/economy'
import { IndicesSector } from '../../tools/dataExplorer/indicesSector'
import { TradingData } from '../../tools/dataExplorer/tradingData'
import { ScatterPlot } from '../../tools/scatterPlot'
import { SmartScreening } from '../../tools/smartScreening'
import TechnicalChart from '../../tools/technicalChart'
import { BOND_ROUTE } from './bondRoute'
import { CORPORATE_ROUTE } from './corporateRoute'
import { ECONOMY_ROUTE } from './economyRoute'
import { FUND_ROUTE } from './fundRoute'
import { MARKET_ROUTE } from './marketRoute'
import { SECTOR_ROUTE } from './sectorRoute'

export const FEATURES = {
  market: MARKET_ROUTE,
  corporate: CORPORATE_ROUTE,
  sector: SECTOR_ROUTE,
  economy: ECONOMY_ROUTE,
  bond: BOND_ROUTE,
  fund: FUND_ROUTE,
  'smart-screening': {
    route: '/smart-screening',
    name: 'constants.route.tool.smartScreening.LABEL',
    renderMain: () => <SmartScreening />,
  },
  'data-explorer': {
    name: 'constants.route.tool.dataExplorer.LABEL',
    routeDefault: '/data-explorer/corporate',
    hasChildren: true,
    components: {
      corporate: {
        route: '/data-explorer/corporate',
        name: 'constants.route.tool.dataExplorer.corporate.LABEL',
        renderMain: () => <Corporate />,
      },
      'indices-sector': {
        route: '/data-explorer/indices-sector',
        name: 'constants.route.tool.dataExplorer.indicesSector.LABEL',
        renderMain: () => <IndicesSector />,
      },
      'trading-data': {
        route: '/data-explorer/trading-data',
        name: 'constants.route.tool.dataExplorer.tradingData.LABEL',
        renderMain: () => <TradingData />,
      },
      economy: {
        route: '/data-explorer/economy',
        name: 'constants.route.tool.dataExplorer.economy.LABEL',
        renderMain: () => <Economy />,
      },
    },
  },
  'financial-chart': {
    name: 'constants.route.financialChart.LABEL',
    routeDefault: '/financial-chart/financial-chart',
    hasChildren: true,
    components: {
      'financial-chart': {
        name: 'constants.route.financialChart.financialChart.LABEL',
        route: '/financial-chart/financial-chart',
        renderMain: () => <FinancialChart />,
      },
      'scatter-plot': {
        route: '/financial-chart/scatter-plot',
        name: 'constants.route.financialChart.scatterPlot.LABEL',
        renderMain: () => <ScatterPlot />,
      },
      'share-chart': {
        route: '/financial-chart/share-chart/:key',
        name: 'constants.route.financialChart.shareChart.LABEL',
        renderMain: () => <ShareChart type="chart" />,
        hiddenOnSideBar: true,
      },
      'share-dashboard': {
        route: '/financial-chart/share-dashboard/:key',
        name: 'constants.route.financialChart.shareDashboard.LABEL',
        renderMain: () => <ShareChart type="dashboard" />,
        hiddenOnSideBar: true,
      },
    },
  },
  'news-report': {
    name: 'constants.route.newsReport.LABEL',
    routeDefault: '/news-report/daily-report',
    hasChildren: true,
    components: {
      'daily-report': {
        route: '/news-report/daily-report',
        name: 'constants.route.newsReport.dailyReport.LABEL',
        renderMain: () => <DailyReport />,
      },
    },
  },
  'technical-chart': {
    route: '/technical-chart',
    name: 'constants.route.tool.technicalChart.LABEL',
    renderMain: () => <TechnicalChart />,
  },
  search: {
    route: '/search',
    name: 'constants.route.search.LABEL',
    renderMain: () => <Search />,
    hiddenOnSideBar: true,
  },
  charting: {
    route: '/charting',
    renderMain: () => <Charting />,
    hiddenOnSideBar: true,
    disableRenderLayout: true,
  },
}
