import penetrationAnalysisProxy from '../../proxies/corporate/PenetrationAnalysisProxy'
import { ServiceBase } from '../ServiceBase'

class PenetrationAnalysisService extends ServiceBase {
  //Securities
  getSecMarginLendingMaxPeriod(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecMarginLendingMaxPeriod(params),
    )
  }

  getSecProprietaryTreasuryMaxPeriod(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecProprietaryTreasuryMaxPeriod(params),
    )
  }

  getSecMarketShareChartData(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecMarketShareChartData(params),
    )
  }

  getSecValueAndGrowthChartData(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecValueAndGrowthChartData(params),
    )
  }

  getSecChangeInMarketShareChartData(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecChangeInMarketShareChartData(params),
    )
  }

  getSecDetailMetrics(params) {
    return this.getData(() =>
      penetrationAnalysisProxy.getSecDetailMetrics(params),
    )
  }

  getDownloadSecDetailMetrics(params) {
    return this.getFileDownload(() =>
      penetrationAnalysisProxy.getDownloadSecDetailMetrics(params),
    )
  }

  //Corporate
  getCorpMaxPeriod(params) {
    return this.getData(() => penetrationAnalysisProxy.getCorpMaxPeriod(params))
  }

  getCorpRevenueBreakdown(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getCorpRevenueBreakdown',
      params,
    )(() => penetrationAnalysisProxy.getCorpRevenueBreakdown(params))
  }

  getCorpMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getCorpMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getCorpMarketShare(params))
  }

  getCorpRevenueType(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getCorpRevenueType',
      params,
    )(() => penetrationAnalysisProxy.getCorpRevenueType(params))
  }

  getCorpRevenueChart(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getCorpRevenueChart',
      params,
    )(() => penetrationAnalysisProxy.getCorpRevenueChart(params))
  }

  getCorpDetailMetrics(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getCorpDetailMetrics',
      params,
    )(() => penetrationAnalysisProxy.getCorpDetailMetrics(params))
  }

  downloadCorpDetailMetrics(params) {
    return this.getFileDownload(() =>
      penetrationAnalysisProxy.downloadCorpDetailMetrics(params),
    )
  }

  //Insurance
  getInsuMaxPeriod(params) {
    return this.getData(() => penetrationAnalysisProxy.getInsuMaxPeriod(params))
  }

  getInsuRevenue(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getInsuRevenue',
      params,
    )(() => penetrationAnalysisProxy.getInsuRevenue(params))
  }

  getInsuMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getInsuMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getInsuMarketShare(params))
  }

  getInsuChangeInMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getInsuChangeInMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getInsuChangeInMarketShare(params))
  }

  getInsuClaimRatio(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getInsuClaimRatio',
      params,
    )(() => penetrationAnalysisProxy.getInsuClaimRatio(params))
  }

  getInsuDetailMetrics(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getInsuDetailMetrics',
      params,
    )(() => penetrationAnalysisProxy.getInsuDetailMetrics(params))
  }

  downloadInsuDetailMetrics(params) {
    return this.getFileDownload(() =>
      penetrationAnalysisProxy.downloadInsuDetailMetrics(params),
    )
  }

  //Bank
  getBankMaxPeriod(params) {
    return this.getData(() => penetrationAnalysisProxy.getBankMaxPeriod(params))
  }

  getBankLoanToCustomer(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanToCustomer',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanToCustomer(params))
  }

  getBankLoanMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanMarketShare(params))
  }

  getBankLoanChangeInMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanChangeInMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanChangeInMarketShare(params))
  }

  getBankLoanBySector(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanBySector',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanBySector(params))
  }

  getBankLoanMarketShareByLoanSector(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanMarketShareByLoanSector',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanMarketShareByLoanSector(params))
  }

  getBankLoanDetailMetrics(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankLoanDetailMetrics',
      params,
    )(() => penetrationAnalysisProxy.getBankLoanDetailMetrics(params))
  }

  downloadBankLoanDetailMetrics(params) {
    return this.getFileDownload(() =>
      penetrationAnalysisProxy.downloadBankLoanDetailMetrics(params),
    )
  }

  getBankDepositFromCustomer(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositFromCustomer',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositFromCustomer(params))
  }

  getBankDepositMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositMarketShare(params))
  }

  getBankDepositChangeInMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositChangeInMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositChangeInMarketShare(params))
  }

  getBankDepositCasa(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositCasa',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositCasa(params))
  }

  getBankDepositCasaMarketShare(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositCasaMarketShare',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositCasaMarketShare(params))
  }

  getBankDepositDetailMetrics(params) {
    return this.applyMemoryCache(
      'PenetrationAnalysisService.getBankDepositDetailMetrics',
      params,
    )(() => penetrationAnalysisProxy.getBankDepositDetailMetrics(params))
  }

  downloadBankDepositDetailMetrics(params) {
    return this.getFileDownload(() =>
      penetrationAnalysisProxy.downloadBankDepositDetailMetrics(params),
    )
  }
}

export default new PenetrationAnalysisService()
