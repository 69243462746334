export const common = {
  FINANCIAL_ANALYSIS: 'Phân tích tài chính',
  OTHER: 'Các ngành khác',
  ANNUAL: 'Hàng năm',
  YEARLY: 'Hàng năm',
  QUARTERLY: 'Hàng quý',
  CHART_PERCENT: '(%)',
  CHART_BILLION_VND: '(Tỷ VND)',
  CHART_DAYS: '(Ngày)',
  BILLION: 'Tỷ',
  PERCENT: '%',
  DAYS: 'Ngày',
  ALL: 'Tất cả',
  LISTED: 'Niêm yết',
  PRIVATE: 'Tư nhân',
  ALL_COMPANIES: 'Tất cả công ty',
  UNIT_BILLION_VND: 'Đơn vị: Tỷ VND',
  SCREEN_NOTE:
    'Chỉ số ngành được tính bằng dữ liệu báo cáo tài chính mới nhất.',
  SOURCE_BANK_NOTE: 'Nguồn: [totalOrganization] Ngân hàng',
  SOURCE_COMPANY_NOTE: 'Nguồn: [totalOrganization] Công ty',
  TIMES: 'Lần',
}

export const bank = {
  ASSET_QUALITY: 'Chất lượng tài sản',
  CAPITAL_STRUCTURE: 'Cơ cấu vốn & Thanh khoản',
  PROFITABILITY: 'Lợi nhuận & Hiệu quả',

  assetQuality: {
    LOAN_BY_SECTOR: 'Dư nợ theo ngành',
    YOY_GROWTH: 'Tăng trưởng YoY',
    TRADING: 'Thương mại',
    AGRICULTURE_FORESTRY: 'Nông nghiệp và Lâm nghiệp',
    MANUFACTURING: 'Sản xuất',
    CONSTRUCTION: 'Xây dựng',
    PERSONAL_PUBLIC_SERVICE: 'Dịch vụ cộng đồng và Cá nhân',
    WAREHOUSING: 'Kho bãi, Vận tải, Viễn thông',
    TRAINING_EDUCATION: 'Giáo dục và Đào tạo',
    ESTATE_AGENT: 'Bất động sản và Tư vấn',
    HOTEL_RESTAURANT: 'Khách sạn và Nhà hàng',
    FINANCIAL_SERVICE: 'Dịch vụ tài chính',
    OTHER: 'Các ngành khác',
    IN_DEPTH_COMPARISON: 'Cơ cấu khoản vay - So sánh chuyên sâu',
    LOAN_FOR: 'Cơ cấu khoản vay theo ngành',
    LOAN_STRUCTURE: 'Cơ cấu khoản vay',
    NPL_COVERAGE: 'Khả năng thanh toán nợ xấu',
    PROVISION_NPL: 'Dự phòng/NPL',
    PROVISION_NPL_SML: 'Dự phòng/(NPL + SML)',
    NPL_SML: 'Nợ xấu & Nợ cần chú ý/Tổng dư nợ',
    NPL: 'Nợ xấu/Tổng dư nợ',
    SML: 'Nợ cần chú ý/Tổng dư nợ',
    VALUE_BOND_PORTFOLIOS: 'Giá trị danh mục trái phiếu',
    GOVERNMENT_BOND: 'TPCP',
    CREDIT_INSTITUTION_BOND: 'TPTCTD',
    CORPORATE_BOND: 'TPDN',
    BOND_PORTFOLIOS_GROWTH: 'Tăng trưởng giá trị danh mục TP',
  },

  capitalStructure: {
    CASA_RATIO: 'Tỷ lệ CASA',
    DATA_TABLE: 'Dữ liệu chi tiết',
    YOY_GROWTH: 'Tăng trưởng YoY',
    CURRENT_DEPOSIT: 'Tiền gửi không kỳ hạn',
    TERM_DEPOSIT: 'Tiền gửi có kỳ hạn',
    SHORT_TERM_LOAN: 'Vay ngắn hạn',
    MEDIUM_TERM_LOAN: 'Vay trung hạn',
    LONG_TERM_LOAN: 'Vay dài hạn',
    LDR: 'Tỷ lệ LDR',
    DEPOSIT_STRUCTURE: 'Cơ cấu tiền gửi',
    STATE_OWNED_COMPANIES: 'Doanh nghiệp nhà nước',
    LOCAL_AND_PRIVATE_COMPANIES: 'Doanh nghiệp tư nhân',
    FOREIGN_COMPANIES: 'Doanh nghiệp nước ngoài',
    INDIVIDUAL: 'Cá nhân',
    OTHER: 'Khác',
    LDR_RATIO: 'Tỷ lệ LDR',
    DEPOSIT_GROWTH: 'Tăng trưởng tiền gửi',
    LOAN_GROWTH: 'Tăng trưởng cho vay',
    LOAN_STRUCTURE: 'Cơ cấu khoản vay',
    TIER_ONE_CAPITAL: 'Vốn cấp 1',
    TIER_TOTAL_ASSET: 'Vốn cấp 1/ Tổng tài sản',
    CHART_LDR_RATIO_PERCENT: 'Tỷ lệ LDR',
    CHART_GROWTH_PERCENT: 'Tăng trưởng',
  },

  profitability: {
    COST: 'Tỷ lệ chi phí hoạt động/ Thu nhập hoạt động',
    CIR: 'Tỷ lệ chi phí hoạt động/ Thu nhập hoạt động',
    OPERATING_EXPENSE_GROWTH: 'Tăng trưởng chi phí hoạt động',
    DATA_TABLE: 'Dữ liệu chi tiết',
    YOY_GROWTH: 'Tăng trưởng YoY',
    NET_AND_SIMILAR: 'Thu nhập lãi thuần',
    NET_AND_COMMISSION: 'Lãi thuần từ hoạt động dịch vụ',
    NET_FROM_ACTIVITIES: 'Lãi thuần từ hoạt động khác',
    TOTAL_OPERATING_INCOME: 'Tổng thu nhập hoạt động',
    COST_INCOME_RATIO: 'Tỷ lệ chi phí hoạt động/ Thu nhập hoạt động',
    NIM: 'NIM',
    YOEA: 'YOEA',
    COF: 'COF',
    INCOME_STRUCTURE: 'Cơ cấu thu nhập',
    NET_INTEREST: 'Biên lãi ròng',
    NON_INTEREST: 'Hiệu suất thu nhập ngoài lãi',
    NET_GAIN_FX: 'Lãi/(lỗ) thuần từ hoạy động kinh doanh ngoại hối và vàng',
    NET_GAIN_SECURITIES: 'Lãi/(lỗ) thuần từ mua bán chứng khoán ',
    NON_INTEREST_INCOME_GROWTH: 'Tăng trưởng thu nhập ngoài lãi',
    OPERATING_INCOME: 'Thu nhập hoạt động & Tăng trưởng thu nhập',
    TOTAL_OPERATING: 'Tăng trưởng tổng thu nhập hoạt động',
    NET_INCOME_GROWTH: 'Tăng trưởng lợi nhuận sau thuế',
    CHART_CIR_PERCENT: 'Tỷ lệ CPHD/ TNHD',
    CHART_OPERATING_EXPENSE_GROWTH_PERCENT: 'Tăng trưởng chi phí hoạt động',
    CHART_NIM_PERCENT: 'NIM',
    CHART_YOEA_COF_PERCENT: 'YOEA hoặc COF',
  },
}

export const corporate = {
  FILTER: 'Bộ lọc',
  TABLE: 'Bảng',
  PRICE_CHART: 'Chỉ số tài chính',
  RATIO: 'Tỷ lệ',
  TIME: 'Thời gian',
  LATEST_YEAR: 'Năm gần nhất',
  PERIOD: 'Số kỳ',
  SEARCH: 'Tìm kiếm',
  PROFITABILITY: 'Khả năng sinh lời',
  STRENGTH: 'Sức khỏe tài chính',
  EFFICIENCY: 'Hiệu quả',
  VALUATION: 'Định giá',
  GROWTH: 'Tăng trưởng',
  REVENUE_GROWTH: 'Tăng trưởng doanh thu',
  REVENUE: 'Doanh thu',
  PROFIT_AFTER_TAX_GROWTH: 'Tăng trưởng TNST',
  PROFIT_AFTER_TAX: 'Lợi nhuận sau thuế',
  PROFIT_MARGIN: 'Biên lợi nhuận',
  NET_PROFIT_MARGIN: 'Biên lợi nhuận ròng',
  GROWTH_PROFIT_MARGIN: 'Biên LN gộp',
  WORKING_CAPITAL: 'Vốn lưu động',
  NET_WORKING_CAPITAL: 'Vốn lưu động thuần',
  CASH_RATIO: 'Tỷ lệ tiền mặt',
  CURRENT_RATIO: 'Tỷ lệ thanh toán hiện hành',
  COVERAGE_RATIO: 'Tỷ lệ khả năng thanh toán',
  INTEREST_COVERAGE_RATIO: 'Tỷ lệ thanh toán lãi vay',
  ASSET_COVERAGE_RATIO: 'Tỷ lệ đảm bảo trả nợ bằng tài sản',
  EBITDA_DEBT: 'EBITDA/Nợ',
  FINANCIAL_LEVERAGE: 'Đòn bẩy tài chính',
  FINANCIAL_LEVERAGE_RATIO: 'Đòn bẩy tài chính',
  DEBT_EQUITY: 'Nợ/VCSH',
  ROA_ROE: 'ROA và ROE',
  ROA: 'ROA',
  ROE: 'ROE',
  DUPONT_ANALYSIS: 'Phân tích Dupont',
  ASSET_TURNOVER: 'Vòng quay tài sản',
  ASSET_MANAGEMENT: 'Quản lý tài sản',
  CASH_CONVERSION_CYCLE: 'Vòng quay tiền mặt',
  PE: 'P/E',
  SECTOR_MEDIAN_PE: 'P/E Ngành',
  INDEX_MEDIAN_PE: 'P/E Vnindex',
  PB: 'P/B',
  SECTOR_MEDIAN_PB: 'P/B Ngành',
  INDEX_MEDIAN_PB: 'P/B Vnindex',
  EV_EBITDA: 'EV/EBITDA',
  SECTOR_MEDIAN_EV_EBITDA: 'EV/EBITDA Ngành',
  INDEX_MEDIAN_EV_EBITDA: 'EV/EBITDA Vnindex',
  TOTAL_ASSET_GROWTH: 'Tăng trưởng tổng tài sản',
  TOTAL_ASSET: 'Tổng tài sản',
  NET_DEBT_GROWTH: 'Tăng trưởng nợ ròng',
  NET_DEBT: 'Nợ ròng',
  EBITDA_GROWTH: 'Tăng trưởng EBITDA',
  EBITDA: 'EBITDA',
  GROSS_PROFIT_MARGIN: 'Biên lợi nhuận gộp',
  EBITDA_MARGIN: 'Biên EBITDA',
  CHART_RATIO: 'Tỷ lệ TT (Lần)',
  CHART_EBITDA_DEBT: 'EBITDA/Nợ (Lần)',
  CHART_FINANCIAL_LEVERAGE: 'Đòn bẩy TC (Lần)',
  CHART_DEBT_EQUITY: 'Nợ/VCSH (Lần)',
  CHART_ASSET_NET: 'VQTS hoặc BLNR (Lần)',

  table: {
    ACTIVITY_RATIO: 'Hiệu quả hoạt động',
    TOTAL_ASSET_TURNOVER: 'Hệ số vòng quay tài sản',
    EQUITY_TURNOVER: 'Hệ số vòng quay vốn CSH',
    RECEIVABLES_TURNOVER: 'Hệ số vòng quay phải thu khách hàng',
    DAY_SALE_OUTSTANDING: 'Thời gian trung bình thu tiền khách hàng',
    INVENTORY_TURNOVER: 'Hệ số vòng quay HTK',
    DAY_INVENTORY_HAND: 'Thời gian trung bình xử lý HTK',
    PAYABLE_TURNOVER: 'Hệ số vòng quay phải trả nhà cung cấp',
    NUMBER_DAY_PAYABLE: 'Thời gian trung bình thanh toán cho nhà cung cấp',
    CASH_CONVERSION_CYCLE: 'Chu kỳ chuyển đổi tiền mặt',
    LIQUIDITY_RATIO: 'Chỉ số thanh toán',
    CASH_RATIO: 'Hệ số thanh toán tiền mặt',
    QUICK_RATIO: 'Hệ số thanh toán nhanh',
    CURRENT_RATIO: 'Hệ số thanh toán hiện thời',
    SOLVENCY_RATIO: 'Sức khỏe tài chính',
    ST_DEPT_ASSET_RATIO: 'Tỷ lệ nợ vay ngắn hạn trên tổng tài sản',
    ST_DEPT_EQUITY_RATIO: 'Tỷ lệ nợ vay ngắn hạn trên VCSH',
    LT_DEPT_ASSET_RATIO: 'Tỷ lệ nợ vay dài hạn trên tổng tài sản',
    LT_DEPT_EQUITY_RATIO: 'Tỷ lệ nợ vay dài hạn trên VCSH',
    DEPT_ASSET_RATIO: 'Tỷ lệ nợ vay trên tổng tài sản',
    DEPT_EQUITY_RATIO: 'Tỷ lệ nợ vay trên VCSH',
    LIABILITIES_DEPT_ASSET_RATIO: 'Tỷ lệ nợ phải trả trên tổng tài sản',
    LIABILITIES_DEPT_EQUITY_RATIO: 'Tỷ lệ nợ phải trả trên vốn chủ sở hữu',
    FINANCIAL_LEVERAGE_RATIO: 'Tỷ lệ đòn bẩy',
    INTEREST_COVERAGE: 'Khả năng chi trả lãi vay',
    PROFITABILITY_RATIO: 'Khả năng sinh lợi',
    GROSS_PROFIT_MARGIN: 'Tỷ suất lợi nhuận gộp',
    EBITDA_MARGIN: 'Tỷ suất EBITDA',
    EBIT_MARGIN: 'Tỷ suất EBIT',
    PRETAX_MARGIN: 'Tỷ suất lợi nhuận trước thuế',
    NET_PROFIT_MARGIN: 'Tỷ suất lợi nhuận thuần',
    ROA: 'ROA',
    ROE: 'ROE',
    ROIC: 'ROIC',
    EFFECTIVE_TAX_RATE: 'Tỷ suất thuế TNDN thực tế',
    CASHFLOW_RATIO: 'Chỉ số dòng tiền',
    CFO_LIABILITIES: 'Dòng tiền hoạt động/ Tổng nợ',
    FREE_CASHFLOW_REVENUE: 'Dòng tiền / doanh thu',
    FREE_CASHFLOW_OPERATING: 'Dòng tiền tự do / thu nhập hoạt động',
    FREE_CASHFLOW_ASSET: 'Dòng tiền tự do trên tài sản',
    FREE_CASHFLOW_EQUITY: 'Dòng tiền tự do trên VCSH',
    REINVESTMENT_FCF: 'Dòng tiền tự do/ CAPEX',
    GROWTH: 'Chỉ số tăng trưởng',
    NET_REVENUE_GROWTH: 'Tăng trưởng doanh thu thuần YoY',
    GROSS_PROFIT_GROWTH: 'Tăng trưởng lãi gộp',
    EBITDA_GROWTH: 'Tăng trưởng EBITDA YoY',
    EBIT_GROWTH: 'Tăng trưởng EBIT YoY',
    EBT_GROWTH: 'Tăng trưởng LN trước thuế',
    RECEIVABLE_GROWTH: 'Tăng trưởng phải thu YoY',
    NET_INVENTORY_GROWTH: 'Tăng trưởng Hàng tồn kho',
    SHORT_TERM_GROWTH: 'Tăng trưởng Vay ngắn hạn',
    LONG_TERM_GROWTH: 'Tăng trưởng Vay dài hạn ',
    SGA_GROWTH: 'Tăng trưởng chi phí SGA YoY',
    ASSET_GROWTH: 'Tăng trưởng tài sản YoY',
    OWNER_EQUITY_GROWTH: 'Tăng trưởng VCSH YoY',
    CFO_GROWTH: 'Tăng trưởng CFO YoY',
    VALUATION_RATIO: 'Chỉ số định giá',
    MARKET_CAP: 'Vốn hóa (Tỷ VND)',
    EV: 'Giá trị doanh nghiệp (Tỷ VND)',
    BOOK_VALUE_PER_SHARE: 'BVPS',
    SALE_PER_SHARE: 'Doanh số/ Cổ phiếu',
    BASIC_EPS: 'EPS cơ bản',
    DILUTED_EPS: 'EPS pha loãng',
    FREE_CASHFLOW_PER_SHARE: 'Dòng tiền tự do/ Cổ phiếu',
    PB: 'P/B',
    PE: 'P/E cơ bản',
    PS: 'P/S',
    EV_REVENUE: 'Giá trị doanh nghiệp/ Doanh thu',
    EV_EBITDA: 'Giá trị doanh nghiệp/ EBITDA',
    EV_EBIT: 'Giá trị doanh nghiệp/ EBIT',
    DIVIDEND_YIELD: 'Tỷ suất cổ tức',
  },
}

export const security = {
  ASSET_STRUCTURE: 'Cơ cấu tài sản',
  PERFORMANCE: 'Hiệu suất',

  assetStructure: {
    AFS_DECOMPOSITION: 'Phân tách AFS',
    LISTED_SHARE: 'Cổ phiếu niêm yết',
    UNLISTED_SHARE: 'Cổ phiếu chưa niêm yết',
    BONDS: 'Trái phiếu',
    CERTIFICATE_DEPOSIT: 'Chứng chỉ tiền gửi',
    OTHER: 'Khác',
    CHART_CONTAINER: 'Biểu đồ',
    DATA_TABLE: 'Dữ liệu chi tiết',
    YOY_GROWTH: 'Tăng trưởng YoY (%)',
    FINANCIAL_FVTPL: 'Các tài sản tài chính ghi nhận\nthông qua lãi lỗ (FVTPL)',
    FINANCIAL_AFS: 'Các khoản tài chính\nsẵn sàng để bán (AFS)',
    MARGIN_LENDING: 'Cho vay nghiệp vụ ký quỹ',
    OWNER_EQUITY: 'Vốn chủ sở hữu',
    MARGIN_AND_OWNER: 'Cho vay ký quỹ/ Vốn chủ sở hữu',
    FVTPL_DECOMPOSITION: 'FVTPL - Phân tích danh mục cổ phiếu',
    TICKER: 'Mã',
    SECTOR: 'Ngành',
    COST: 'Giá trị gốc',
    MARKET_VALUE: 'Giá trị hợp lí',
    FVTPL_SEGMENTAL: 'Phân tách FVTPL',
    MARGIN_LOAN: 'Cho vay nghiệp vụ ký quỹ',
    MARGIN_AND_OWNER_PERCENT: 'Cho vay ký quỹ/ Vốn chủ sở hữu (%)',
    LOAN_AND_MARGIN: 'Dư nợ cho vay ký quỹ',
    PORTFOLIO_STRUCTURE: 'Cơ cấu danh mục tự doanh',
    CORE_ACTIVITIES: 'Hoạt động chính',
  },

  performance: {
    DATA_TABLE: 'Dữ liệu chi tiết',
    YOY_GROWTH: 'Tăng trưởng YoY (%)',
    OPERATING_SALE: 'Doanh thu hoạt động',
    REVENUE_BROKERAGE_CUSTOMER_SERVICE:
      'Doanh thu từ hoạt động môi giới & Dịch vụ khách hàng',
    REVENUE_FROM_BROKERAGE: 'Doanh thu từ hoạt động môi giới ',
    REVENUE_FROM_MARGIN: 'Doanh thu từ hoạt động cho vay',
    REVENUE_FROM_PROPRIETARY: 'Doanh thu hoạt động tự doanh',
    INCOME_FVTPL: 'Thu nhập từ FVTPL',
    INCOME_AFS: 'Thu nhập từ AFS',
    REVENUE_FROM_INVESTMENT: 'Doanh thu từ hoạt động đầu tư',
    REVENUE_INVESTMENT: 'Doanh thu từ hoạt động đầu tư',
    REVENUE_PROPRIETARY: 'Doanh thu hoạt động tự doanh',
  },
}
