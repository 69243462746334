import { getTickValues } from '../helper'

export const getTickIndex = ({
  data,
  keyXAxis,
  width,
  tickNum,
  textMaxWidth,
  gap,
}) => {
  const { tickIndex, textMaxWidth: maxWidth } = getTickValues({
    data,
    keyXAxis,
    width,
    tickNum,
    textMaxWidth,
    margin: { left: 0, right: 0 },
    isXAxisDiv: true,
    gap,
  })

  return { tickIndex, maxWidth }
}
