import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class FundNavDataProxy extends ProxyBase {
  getListNAVCertificate(params) {
    return this.get('GetListNAVCertificate', params)
  }

  getListFundFlow(params) {
    return this.get('GetListFundFlow', params)
  }

  getNAVData(params) {
    return this.get('GetNAVData', params)
  }

  downloadNAVDataExport(params) {
    return this.get('NAVDataExport', params, 'download')
  }
}

export default new FundNavDataProxy(
  ServiceProxyConfig.Fund.FundProfile.FundNavData.ServiceUrl,
)
