import PropTypes from 'prop-types'
import { getThreeColorByValue } from '../../../../utils/Color'

const ColorCellType = ({ val, format }) => {
  return (
    <div style={{ color: isNaN(val) ? '' : getThreeColorByValue(+val) }}>
      {format ? format(val) : val}
    </div>
  )
}

ColorCellType.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.func,
}

export default ColorCellType
