import Exchange from './exchange'
import MenuTab from './MenuTab'
import ScreenerDate from './ScreenerDate'
import Sector from './sector'

const Filter = () => {
  return (
    <div
      style={{ flexDirection: 'column' }}
      className="wrapper-tool h-100 d-flex"
    >
      <div style={{ height: '40%' }}>
        <Exchange />
        <Sector />
        <ScreenerDate />
      </div>
      <div style={{ height: '60%' }}>
        <MenuTab />
      </div>
    </div>
  )
}

export default Filter
