export const settingGeneral = {
  LANGUAGE: 'Language',
  LANGUAGE_ENGLISH: 'English',
  LANGUAGE_VIETNAMESE: 'Tiếng Việt',
  FONT_SIZE: 'Font size',
  FONT_SIZE_SMALL: 'Small',
  FONT_SIZE_MEDIUM: 'Medium',
  FONT_SIZE_LARGE: 'Large',
  TIME_ZONE: 'Time zone',
}
