import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import YearFromTo from './common/YearFromTo'
import style from './index.module.css'
import { resetPopupTemplate } from './store/slice'

const IPO = ({ handleCancel, handleSubmit }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetPopupTemplate())
  }, [])

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>IPO</Span>
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div>
            <span>
              <Translate value="tool.dataExplorer.IPO_YEAR" />:
            </span>
            <div className={`d-flex ${style.biYearFromTo}`}>
              <YearFromTo keyFrom="fromYear" keyTo="toYear" />
            </div>
          </div>
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit isNotHaveTicker={true} submit={handleSubmit} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default IPO
