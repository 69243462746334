export const indices = {
  NAME_SCREEN_UPPERCASE: 'CHỈ SỐ',
  NAME_SCREEN_LOWERCASE: 'Chỉ số',
  INDEX_TITLE: 'VIỆT NAM',
  SECTOR_TITLE: 'NGÀNH',
  INDEX_TAB: 'CHỈ SỐ',
  CODE: 'CHỈ SỐ',
  CLOSE_PRICE: 'ĐIỂM',
  PRICE_CHANGE: 'THAY ĐỔI',
  PERCENT_PRICE_CHANGE: '% THAY ĐỔI 1D',
  TOTAL_VOLUME: 'KHỐI LƯỢNG',
  TOTAL_VALUE: 'GIÁ TRỊ',
  NAME: 'NGÀNH',
  PROSPECT: 'THẾ GIỚI',
  WORLD_INDEX: 'CHỈ SỐ',
  COMMODITIES: 'HÀNG HÓA',
  GLOBAL_INDEX_NAME: 'CHỈ SỐ',
  COMMODITY_NAME: 'HÀNG HÓA',
  MARKET_WATCH: 'THỊ TRƯỜNG',
  VALUE_BILLION: 'Giá trị (Tỷ VND)',
  TODAY_TOTAL_TRADING: 'Tổng GTGD hôm nay',
  YESTERDAY_TOTAL_TRADING: 'Tổng GTGD hôm qua',
  AVG_TOTAL_TRADING_1W: 'Tổng GTGD trung bình 1W',
  AVG_TOTAL_TRADING_1M: 'Tổng GTGD trung bình 1M',
  AVG_TOTAL_TRADING_6M: 'Tổng GTGD trung bình 6M',
  AVG_TOTAL_TRADING_1Y: 'Tổng GTGD trung bình 1Y',
  AVG_TOTAL_TRADING_3M: 'Tổng GTGD trung bình 3M',
  FOREIGN_BUY: 'Tổng GTNN mua',
  FOREIGN_SELL: 'Tổng GTNN bán',
  NET_FOREIGN: 'Tổng GTNN ròng',
  FOREIGN_TRADING: 'GIAO DỊCH NƯỚC NGOÀI',
  VOLUME_MILLION: 'Khối lượng (Triệu CP)',
  INDEX_POINT: 'Chỉ số (Điểm)',
  BUY_UP_VOLUME: 'KL mua chủ động',
  SELL_DOWN_VOLUME: 'KL bán chủ động',
  BUY_UP: 'Khối lượng mua chủ động',
  SELL_DOWN: 'Khối lượng bán chủ động',
  BU_SD_TITLE: 'BIỂU ĐỒ BU/SD',
  CONTRIBUTION: 'TỶ TRỌNG',
  CONTRIBUTION_TO_INDEX: '% Đóng góp',
  TOOLTIP_CONTRIBUTION: 'Đóng góp:',
  TOOLTIP_PRICE: 'Giá:',
  TOOLTIP_VOLUME: 'Khối lượng:',
  TOOLTIP_VALUE: 'Giá trị:',
  CHART_NAME_MARKET_WATCH: 'Thị trường',
  CHART_NAME_FOREIGN_TRADING: 'Giao dịch nước ngoài',
  CHART_NAME_CONTRIBUTION: 'Tỷ trọng',
  SECTOR_OVERVIEW: 'Tổng quan ngành',
  FINANCIAL_ANALYSIS: 'Phân tích tài chính',
  SEGMENTATION: 'Thị phần',
  RANKING: 'Xếp hạng',
  RATIO: 'Chỉ số ngành',
  VALUATION: 'Định giá',
  MILLION_PRICE: ' Tr. CP',
  MILLION: ' Triệu',
  BILLION: ' Tỷ',
  CONTRIBUTION_VOLUMN: 'Nghìn CP',
  CONTRIBUTION_VALUE: 'Tr. VND',
  BUSD_MILLION: ' Tr. CP',
  LIQUIDLITY: 'Thanh khoản',
  INTRADAY: 'Sổ lệnh',
  REALTIME: 'Trực tuyến',
  END_OF_DAY: 'Cuối ngày',
  INTRADAY_TRADING_DATE: 'THỜI GIAN',
  INTRADAY_CLOSE_INDEX: 'ĐIỂM',
  INTRADAY_PERCENT_INDEX_CHANGE: '%',
  INTRADAY_VOLUME: 'KHỐI LƯỢNG',
  INTRADAY_TOTAL_MATCH_VOLUME: 'TỔNG KL KHỚP LỆNH (CP)',
  INTRADAY_MATCH_VOLUME: 'KL KHỚP LỆNH',
  INTRADAY_TOTAL_VOLUME: 'TỔNG KHỐI LƯỢNG (CP)',
}

export const directorDeal = {
  TOP_PLANNING_DEALS: 'Top giao dịch dự kiến',
  TOP_EXECUTED_DEALS: 'Top giao dịch đã hoạt động',
  DEAL: 'Giao dịch nội bộ',
  NO: 'STT',
  PUBLIC_DATE: 'Ngày thông báo',
  END_DATE: 'Ngày kết thúc',
  TICKER: 'Mã',
  OWNER: 'Người thực hiện',
  POSITION: 'Vị trí',
  TYPE: 'Loại GD',
  TOTAL_EST_VALUE: 'Tổng GD ước tính',
  SHARE_REGISTERED: 'KL đăng kí',
  PERCENT_VOL: '% KL',
  STATE: 'Trạng thái',
  PERCENT_OWN: '% Sở hữu',
  BUY: 'GD Mua',
  SELL: 'GD Bán',
  TABLE_HEAD_TEXT: 'Giá trị: x 1,000,000.',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  HALF_A_YEAR: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',
  ALL_TYPE: 'Tất cả loại GD',
  ALL_TICKERS: 'Tất cả mã',
  ALL_STATUS: 'Tất cả trạng thái',
  PAGE: 'Trang',
  TYPE_BUY: 'Mua',
  TYPE_SELL: 'Bán',
  STATUS_PENDING: 'Đang hoạt động',
  STATUS_DONE: 'Đã thực hiện',
  STATUS_PAUSE: 'Tạm ngưng',
  STATUS_PLAN: 'Kế hoạch',
  STATUS_CANCEL_PLAN: 'Hủy kế hoạch',
  STATUS_CHANGE_PLAN: 'Thay đổi kế hoạch',
  LB_FROM: 'Từ ngày',
  LB_TO: 'Đến ngày',
  LB_CLEAR: 'Xóa ngày',
  LB_DIRECTOR_DEAL: 'Giao dịch nội bộ',
  LB_TICKERS: 'Nhiều mã',
  SHARE_ACQUIRED: 'KL đã giao dịch',
  NET_VALUE_BREAKDOWN: 'Phân tách giá trị giao dịch',
  NET_VALUE_OF_INTERNAL_TRADING: 'Giá trị ròng giao dịch nội bộ',
  NET_VALUE: 'Giá trị (Tỷ VND)',
  DIRECTORS_AND_RELATED_PEOPLE: 'BLĐ và người liên quan',
  INSTITUTIONAL_SHAREHOLDERS: 'Cổ đông tổ chức',
  NO_OF_TRANSACTIONS: 'SL giao dịch',
  TRANSACTION: 'Giao dịch',
  NET: 'GD ròng',
  DIRECTORS_AND_RELATED_PEOPLE_1: 'BLĐ và người liên quan: ',
  INSTITUTIONAL_SHAREHOLDERS_1: 'Cổ đông tổ chức: ',
  NO_OF_TRANSACTIONS_1: 'SL giao dịch: ',
  BUY_1: 'GD mua: ',
  SELL_1: 'GD bán: ',
  NET_1: 'GD ròng: ',
  LB_FOOTER_CHART:
    'GTGD của kì cuối cùng là tổng giá trị của GD đã kết thúc và GD có kế hoạch kết thúc trong kì.',
  TOOLTIP_TOTAL_EST_VALUE:
    'Đối với GD đã thực hiện, tổng giá trị bằng SLCP đã thực hiện nhân với giá tại ngày kết thúc GD. Đối với các GD còn lại, tổng giá trị bằng SLCP đăng ký nhân với giá đóng cửa gần nhất.',
  TOOLTIP_PERCENT_VOL:
    'Tỷ trọng SLCP đã thực hiện (đối với GD đã thực hiện) hoặc SLCP đăng kí (đối với GD khác) trên tổng khối lượng giao dịch vào ngày thông báo giao dịch. ',
  NO_TICKER: 'Không mã',
  BILLION: ' Tỷ.',
  OWNER_PLACEHOLDER: 'Người thực hiện',
  INTERNAL_TRADING: 'Giao dịch nội bộ của',
  NET_VALUE_BREAKDOWN_OF: 'Phân tách giá trị giao dịch của',
  NET_VALUE_OF_INTERNAL_TRADING_OF: 'Giá trị ròng giao dịch nội bộ của',
  ALL_TRANSACTION: 'Tất cả GD',
  INSTITUTION: 'GD Tổ chức',
  INDIVIDUAL: 'GD Cá nhân',
  RELATED_PERSON: 'GD người liên quan',
  TYPE_PUBLIC_DATE: 'Ngày thông báo',
  TYPE_END_DATE: 'Ngày kết thúc',
  TABLE_HEAD_TEXT_DATA_CALCULATE_FROM:
    'Giá trị: x 1,000,000. Dữ liệu được tính từ ngày %{startDate} đến ngày %{endDate}',
}

export const valuation = {
  LB_VALUATION: 'Định giá',
  LB_VALUATION_CHART: 'Biểu đồ định giá',
  TABLE_FOOTER_TEXT: 'Dữ liệu được cập nhật tại',
  '3M': '3M',
  '6M': '6M',
  '9M': '9M',
  '1Y': '1Y',
  '3Y': '3Y',
  ALL: 'Tất cả',
  PRICE_TO_EARNINGS: 'P/E',
  PRICE_TO_BOOK: 'P/B',
  LB_COMPARE: 'So sánh',
  CLEAR_ALL: 'Xóa tất cả',
  INDICES: 'Chỉ số',
  SECTOR: 'Ngành',
  TICKER: 'Mã CK',
  MESS_WARNING_COMPARE:
    'Tối đa 19 chỉ tiêu đã được thêm vào. Bạn không thể thêm chỉ tiêu vào biểu đồ.',
  ICB_LEVEL_1: 'Ngành cấp 1',
  ICB_LEVEL_2: 'Ngành cấp 2',
  ICB_LEVEL_3: 'Ngành cấp 3',
  ICB_LEVEL_4: 'Ngành cấp 4',
  ICB_LEVEL_5: 'Ngành cấp 5',
  LABEL_LOWER_LIMIT: 'Cận dưới',
  LABEL_UPPER_LIMIT: 'Cận trên',
  LOWER_LIMIT_PE: 'Cận dưới (P/E trung bình 90 ngày)',
  UPPER_LIMIT_PE: 'Cận trên (P/E trung bình 90 ngày)',
  LOWER_LIMIT_PB: 'Cận dưới (P/B trung bình 90 ngày)',
  UPPER_LIMIT_PB: 'Cận trên (P/B trung bình 90 ngày)',
  LABEL_INDEX_POINT: 'Chỉ số (Điểm)',
  LABEL_PRICE: 'Giá (Nghìn VND)',
  LABEL_PE: 'P/E (Lần)',
  LABEL_PB: 'P/B (Lần)',
  LB_COUNT_COMPARE: 'Đã chọn: ',
  LB_TIMES: '(Lần)',
  LB_POINT: '(Điểm)',
}

export const indexStatistics = {
  PRICE_DATA: 'THỐNG KÊ GIÁ',
  VALUE_INVESTOR: 'GIÁ TRỊ RÒNG THEO NHÀ ĐẦU TƯ',
  NET_VALUE: 'PHÂN TÁCH GIÁ TRỊ GIAO DỊCH',

  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  THREE_MONTH: '3M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
  THREE_YEAR: '3Y',
  FIVE_YEAR: '5Y',

  SCREEN_LABEL: 'Thống kê thị trường',
  TABLE_FOOTER_TEXT: 'Dữ liệu được cập nhật tại ',

  // TABS
  OVER_VIEW: 'Tổng quan',
  ORDER_STATISTICS: 'Đặt lệnh',
  FOREIGN: 'Nước ngoài',
  PROPRIETARY: 'Tự doanh',
  STATISTICS_INVESTOR: 'Phân loại nhà đầu tư',

  // COMPONENT
  valueInvestor: {
    NET_VALUE: 'GIÁ TRỊ RÒNG',
    PERCENT_VALUE: '% GIÁ TRỊ',
    NET_VALUE_UNIT: 'Giá trị (Tỷ VND)',
    PERCENT_VALUE_UNIT: 'Giá trị',
    PRICE_UNIT: 'Giá (Triệu VND)',
    INDEX_POINT: 'Chỉ số (Điểm)',

    LOCAL_INSTITUTION: 'Tổ chức trong nước',
    FOREIGN: 'Nước ngoài',
    PROPRIETARY: 'Tự doanh',
    LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
    BILLION: 'Tỷ',
  },

  netValue: {
    VALUE_UNIT: 'Giá trị (Tỷ VND)',
    BUY: 'GT mua',
    SELL: 'GT bán',
    NET: 'GT ròng',
    BILLION: 'Tỷ',
    NET_VALUE_BREAKDOWN: 'Phân tách giao dịch nội bộ',
  },

  listType: {
    FOREIGN: 'Nước ngoài',
    LOCAL_INSTITUTION: 'Tổ chức trong nước',
    LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
    PROPRIETARY: 'Tự doanh',
  },

  hyperlink: {
    COMPANY_OVERVIEW: 'Tổng quan doanh nghiệp',
    COMPANY_NEWS: 'Tin tức doanh nghiệp',
    FINANCIAL_DATA: 'Dữ liệu tài chính',
    BUSINESS_MODAL: 'Mô hình kinh doanh',
    SECTOR_OVERVIEW: 'Tổng quan ngành',
    TECHNICAL_CHART: 'Biểu đồ PT kĩ thuật',
  },

  transactionType: {
    ALL: 'Tất cả các GD',
    MATCH: 'GD khớp lệnh',
    DEAL: 'GD Thỏa thuận',
  },

  priceData: {
    DAILY: 'Hàng ngày',
    WEEKLY: 'Hàng tuần',
    MONTHLY: 'Hàng tháng',
    QUARTERLY: 'Hàng quý',
    YEARLY: 'Hàng năm',
    CUSTOM: 'Tùy chọn',
    DAILY_DETAIL: 'Mới nhất',
    WEEKLY_DETAIL: 'Từ đầu tuần',
    MONTHLY_DETAIL: 'Từ đầu tháng',
    QUARTERLY_DETAIL: 'Từ đầu quý',
    YEARLY_DETAIL: 'Từ đầu năm',
    CUSTOM_DETAIL: 'Tùy chọn',

    DATE: 'NGÀY',
    WEEK: 'TUẦN',
    MONTH: 'THÁNG',
    QUARTER: 'QUÝ',
    YEAR: 'NĂM',

    FROM_DATE: 'Từ',
    TO_DATE: 'Đến',
    CLEAR_DATE: 'Xóa',

    tableType: {
      OVERVIEW: 'Tổng quan',
      DETAIL: 'Chi tiết',
    },

    PRICE_RATE_PRICE_VOLUME_VALUE:
      'Giá: x 1,000. Khối lượng: x 1,000. Giá trị: x 1,000,000.',
    PRICE_RATE_VOLUME_VALUE: 'Khối lượng: x 1,000. Giá trị: x 1,000,000.',

    overview: {
      DATE: 'NGÀY',
      TICKER: 'MÃ',
      LAST: 'GIÁ',
      CHANGE: 'THAY ĐỔI',
      PERCENT_CHANGE_DAILY: '% THAY ĐỔI 1D',
      PERCENT_CHANGE_WEEKLY: '% THAY ĐỔI 1W',
      PERCENT_CHANGE_MONTHLY: '% THAY ĐỔI 1M',
      PERCENT_CHANGE_YEARLY: '% THAY ĐỔI 1Y',
      PERCENT_CHANGE_QUARTERLY: '% THAY ĐỔI 1Q',
      PERCENT_CHANGE_CUSTOM: '% THAY ĐỔI',
      OPEN: 'MỞ CỬA',
      HIGH: 'CAO NHẤT',
      LOW: 'THẤP NHẤT',
      CLOSE: 'ĐÓNG CỬA',
      AVG_CLOSE_PRICE: 'ĐÓNG CỬA',
      MATCHED_VOLUME: 'KHỐI LƯỢNG KHỚP',
      MATCHED_VALUE: 'GIÁ TRỊ KHỚP',
      PUT_THROUGH_VALUE: 'GIÁ TRỊ THỎA THUẬN',
      PUT_THROUGH_VOLUME: 'KHỐI LƯỢNG THỎA THUẬN',
      TOTAL_VOL: 'TỔNG KHỐI LƯỢNG',
      TOTAL_VALUE: 'TỔNG GIÁ TRỊ',
      MARKET_CAP: 'VỐN HÓA',
    },
    orderStatistic: {
      DATE: 'NGÀY',
      TICKER: 'MÃ',
      UNMATCH_BUY_VOL: 'DƯ MUA',
      UNMATCH_SELL_VOL: 'DƯ BÁN',
      NR_BUY_ORDER: 'SỐ LỆNH MUA',
      BUY_VOL: 'KL ĐẶT MUA',
      VOL_BUY_ORDER: 'KLTB 1 LỆNH MUA',
      NR_SELL_ORDER: 'SỐ LỆNH BÁN',
      SELL_VOL: 'KL ĐẶT BÁN',
      VOL_SELL_ORDER: 'KLTB 1 LỆNH BÁN',
      NET_VOL: 'KL RÒNG',
    },
    foreign: {
      DATE: 'NGÀY',
      TICKER: 'MÃ',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      FOREIGN_CR_ROOM: 'ROOM NN CÒN LẠI',
      FOREIGN_TOTAL_ROOM: 'TỔNG ROOM NN',
      PERCENT_FOREIGN_TOTAL_ROOM: '% TỔNG ROOM NN\nĐƯỢC PHÉP SỞ HỮU',
      FOREIGN_OWNED: 'NN HIỆN ĐANG SỞ HỮU',
      PERCENT_FOREIGN_OWNED: '% SỞ HỮU NN',
    },
    proprietary: {
      DATE: 'NGÀY',
      TICKER: 'MÃ',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      MATCH_NET_VOL: 'KL KHỚP LỆNH RÒNG',
      MATCH_NET_VAL: 'GT KHỚP LỆNH RÒNG',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      DEAL_NET_VOL: 'KL THỎA THUẬN RÒNG',
      DEAL_NET_VAL: 'GT THỎA THUẬN RÒNG',
    },
    statisticsInvest: {
      DATE: 'NGÀY',
      TICKER: 'MÃ',
      LOCAL_INDIVIDUAL: 'CÁ NHÂN TRONG NƯỚC',
      LOCAL_INSTITUTION: 'TỔ CHỨC TRONG NƯỚC',
      FOREIGN_INDIVIDUAL: 'CÁ NHÂN NƯỚC NGOÀI',
      FOREIGN_INSTITUTION: 'TỔ CHỨC NƯỚC NGOÀI',
      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      MATCH_NET_VOL: 'KL KHỚP LỆNH RÒNG',
      MATCH_NET_VAL: 'GT KHỚP LỆNH RÒNG',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
      DEAL_NET_VOL: 'KL THỎA THUẬN RÒNG',
      DEAL_NET_VAL: 'GT THỎA THUẬN RÒNG',
    },
  },
}

export const localInstitution = {
  NAME_SCREEN: 'Giao dịch tổ chức trong nước',
  NET_VALUE: 'Giá trị ròng',
  TOP_NET_VALUE: 'top giá trị ròng',
  SECTOR_SEGMENTATION: 'giá trị theo ngành',
  VALUE_LABEL: 'Giá trị ròng (Tỷ VND)',
  INDEX_LABEL: 'Index (Điểm)',
  LOCAL_INSTITUTION_NET_VALUE: 'GTGD ròng tự doanh',
  BUY: 'Mua',
  SELL: 'Bán',
  NOTE_TOP_NET_TABLE: 'Giá: x 1,000. Khối lượng: x1,000. Giá trị: x1,000,000.',
  TICKER: 'Mã',
  PRICE: 'Giá',
  TOTAL_VOLUME: 'Tổng khối lượng',
  TOTAL_BUY_VALUE: 'Tổng giá trị mua',
  TOTAL_SELL_VALUE: 'Tổng giá trị bán',
  NET_TOTAL_VALUE: 'Tổng GTGD ròng',
  PERCENT_LOCAL_INSTITUTION: 'Tỷ trọng GTGD tự doanh',
  SECTOR: 'Ngành',
  TOTAL: 'Tổng',
  BUY_VAL: 'GT mua',
  SELL_VAL: 'GT bán',
  NET_VAL: 'GT ròng',
  OTHERS: 'Ngành khác',
  BILLION: 'Tỷ',
  MILLION: 'Triệu',
  NOTE_SECTOR: 'Giá trị: x 1,000,000',
  LB_FROM: 'Từ',
  CLEAR: 'Xóa',
  TOTAL_BUY_VOLUME: 'Tổng khối lượng mua',
  TOTAL_SELL_VOLUME: 'Tổng khối lượng bán',
}

export const foreign = {
  NAME_SCREEN: 'Nước ngoài',
  NET_VALUE: 'Giá trị ròng',
  OWNERSHIP: 'Sở hữu nước ngoài',
  OWNERSHIP_CHART: 'Sở hữu',
  All: 'Tất cả',
  TOP_NET_VALUE: 'top giá trị ròng',
  VALUE_BY_SECTOR_SEGMENTATION: 'giá trị theo ngành',
  UNIT_BILLION: 'Giá trị ròng (Tỷ VND)',
  POINT: 'Chỉ số (Điểm)',
  FOREIGN_NET_VALUE: 'Tổng GT nước ngoài ròng',
  OWNERSHIP_LB_LEFT: 'Sở hữu NN',
  FOREIGN_OWNERSHIP: 'Sở hữu nước ngoài',
  NOTE_TOP_NET_TABLE: 'Giá: x 1,000. Khối lượng: x1,000. Giá trị: x1,000,000.',
  TICKER: 'Mã',
  PRICE: 'Giá',
  TOTAL_VOLUME: 'Tổng khối lượng',
  TOTAL_BUY_VALUE: 'Tổng giá trị mua',
  TOTAL_SELL_VALUE: 'Tổng giá trị bán',
  NET_TOTAL_VALUE: 'Tổng GTGD ròng',
  PERCENT_OWN: '% Sở hữu',
  SECTOR: 'Ngành',
  TOTAL: 'Tổng',
  BUY_VAL: 'GT mua',
  SELL_VAL: 'GT bán',
  NET_VAL: 'GT ròng',
  OTHERS: 'Ngành khác',
  BUY: 'Mua',
  SELL: 'Bán',
  BILLION: 'Tỷ',
  MILLION: 'Triệu',
  NOTE_SECTOR: 'Giá trị: x 1,000,000',
  LB_FROM: 'Từ',
  CLEAR: 'Xóa',
}
