import React from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import { selectDirectorValue } from './store/slice'

const PositionCell = ({ val, rowId }) => {
  const isRelatedPerson = useSelector(
    selectDirectorValue(rowId, 'isRelatedPerson'),
  )
  return (
    <div
      style={{
        fontStyle: isRelatedPerson ? 'italic' : 'normal',
        opacity: isRelatedPerson ? 0.5 : 1,
      }}
    >
      <TextEllipsis isI18n={false} text={val} />
    </div>
  )
}

export default PositionCell
