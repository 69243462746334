import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ChartInPanel from '../common/chartInPanel'
import {
  changeChartEditNameID,
  selectChartEditNameID,
} from '../common/chartInPanel/store/slice'
import useFeatureChart from '../common/chartInPanel/useFeatureChart'
import { LinkButton } from '../linkChart/LinkButton'
import { selectEnableLinkChart } from '../store/slice'

const defaultChartPanelHeight = 388

export const ChartPanelContent = ({
  item,
  index,
  isHaveDataChartPreview,
  isEdit,
  setIsEdit,
}) => {
  const dispatch = useDispatch()

  const enableLinkChart = useSelector(selectEnableLinkChart)
  const chartEditNameID = useSelector(selectChartEditNameID)

  const {
    formatData,
    footerData,
    schema,
    loading: chartLoading,
  } = useFeatureChart(item, true)

  useEffect(() => {
    if (chartEditNameID && item.id === chartEditNameID) {
      setIsEdit(true)
      dispatch(changeChartEditNameID(null))
    }
  }, [chartEditNameID])

  return (
    <>
      <ChartInPanel
        key={index}
        item={item}
        data={formatData}
        width="100%"
        height={defaultChartPanelHeight}
        schema={schema}
        footerData={footerData}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        loading={chartLoading}
        isHaveDataChartPreview={isHaveDataChartPreview}
      />
      {enableLinkChart && !chartLoading && <LinkButton chartData={item} />}
    </>
  )
}
