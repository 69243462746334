import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import style from '../index.module.css'
import { selectTickerPopupTemplate } from '../store/slice'

export const maxTicker = 4000

const Count = ({ errors }) => {
  const ticker = useSelector(selectTickerPopupTemplate)

  const totalTicker = ticker.length

  return (
    <div className="note mt-8">
      <Translate value="tool.dataExplorer.SELECTED" /> {totalTicker}/{maxTicker}
      <div>
        {errors &&
          errors.map((message, index) => (
            <div className={style.error} key={index}>
              {message}
            </div>
          ))}
      </div>
      <div>
        {totalTicker > maxTicker && (
          <div className={style.warning}>
            <i className="icon-warning" />
            <Translate value="tool.dataExplorer.MAX_TICKER" />
          </div>
        )}
      </div>
    </div>
  )
}

export default Count
