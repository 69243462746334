import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { HorizontalStackedBarSeries } from '../../../../common/chart/customCharts/horizontalStackedBarSeries'
import { Footer } from '../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { formatVal } from '../../../../utils/Value'
import { getQuarterAndYearKey } from '../../common/helper'
import { TIME_RANGE } from '../socialInvestment/constants'
import { selectTimeRange } from '../socialInvestment/store/slice'
import {
  FILL_COLOR,
  QUARTER_SELECTION_MONTHLY,
  RADIO_VALUE,
  STACK_COLORS,
  TYPE_CHART_SOCIAL_INVESTMENT,
} from './constants'
import { renderListFooter, xAxisLabelCustom } from './helper'
import style from './index.module.css'
import {
  selectCurrentQuarter,
  selectNameById,
  selectStateSectorKeys,
} from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  heightBarChart: 20,
}

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  typeChartValue,
  radioValue,
  ids,
}) => {
  const nameById = useSelector(selectNameById)
  const stateSectorKeys = useSelector(selectStateSectorKeys)
  const currentQuarter = useSelector(selectCurrentQuarter)
  const currentTimeRange = useSelector(selectTimeRange)

  const totalSocialKeys = ids
  const budgetProvincesKey = 'value'
  const yAxisKey = 'time'

  const isTotalSocialChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL
  const isBudgetProvinces =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.BUDGET_PROVINCES
  const isStateSector =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.STATE_SECTOR

  const isYearlyTabSelection =
    currentQuarter === QUARTER_SELECTION_MONTHLY[0].value ||
    currentTimeRange === TIME_RANGE.YEARLY
  const isAccQuarterly = currentTimeRange === TIME_RANGE.ACC_QUARTER

  const keys = isTotalSocialChart
    ? totalSocialKeys
    : isStateSector
    ? stateSectorKeys
    : [budgetProvincesKey]

  const fillColorTotalSocialChart = totalSocialKeys.reduce((acc, value) => {
    return { ...acc, [value]: FILL_COLOR[totalSocialKeys.indexOf(value)] }
  }, {})

  const keyYAxis = isTotalSocialChart
    ? [
        {
          id: 'left',
          orientation: 'left',
          keys: [yAxisKey],
          type: 'category',
          tickFormatter: (val) => formatVal(val),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
          interval: 0,
        },
      ]
    : [
        {
          id: 'left',
          keys,
          orientation: 'left',
          label: `(${I18n.t('economy.fiscal.stateBudget.BILLION_VND')})`,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
          isBarChart: true,
        },
      ]

  const renderTooltip = (payload) => {
    return isStateSector ? (
      <>
        <div style={{ fontStyle: 'italic', fontWeight: 400 }}>
          {payload.name}
        </div>
        {Object.keys(payload)
          .filter((item) => item !== 'id' && item !== 'name')
          .map((key, index) => (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Span className={style.tooltipContentKey}>{key}:</Span>
              <Span
                style={{ marginLeft: 25 }}
                className={style.toolTipContentValue}
              >
                {`${formatVal(Object.values(payload)[index])} ${I18n.t(
                  'economy.fiscal.stateBudget.BILLION_VND_TOOLTIP',
                )}`}
              </Span>
            </div>
          ))}
      </>
    ) : (
      <>
        <div style={{ fontStyle: 'italic', fontWeight: 400 }}>
          {getQuarterAndYearKey(
            payload.quarter,
            payload.year,
            isYearlyTabSelection,
            isAccQuarterly,
          )}
        </div>
        <div
          key={payload.id}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Span className={style.tooltipContentKey}>{payload.name}:</Span>
          <Span
            style={{ marginLeft: 25 }}
            className={style.toolTipContentValue}
          >
            {`${formatVal(payload.value)} ${I18n.t(
              'economy.fiscal.stateBudget.BILLION_VND_TOOLTIP',
            )}`}
          </Span>
        </div>
      </>
    )
  }

  const tooltipTotalSocialChart = [
    {
      label: '',
      keyValue: yAxisKey,
      formatValue: (val) => val,
      fontStyle: 'italic',
    },
    ...totalSocialKeys.map((key) => ({
      label: nameById?.find((item) => item.id === key)?.name,
      keyValue: key,
      formatValue: (val) =>
        `${formatVal(val)} ${
          radioValue === RADIO_VALUE.PERCENT
            ? I18n.t('economy.fiscal.stateBudget.PERCENT')
            : I18n.t('economy.fiscal.stateBudget.BILLION_VND_TOOLTIP')
        }`,
      fontSize: 11,
      fontWeightValue: 500,
    })),
  ]

  return (
    <SizeTracker className={`${style.heightAuto}`}>
      {(size) => {
        return (
          <>
            {size.height && (
              <>
                {isTotalSocialChart ? (
                  <div style={{ height: height - size.height }}>
                    <HorizontalStackedBarSeries
                      data={data}
                      width={width}
                      height={height - size.height}
                      yAxisCompareKey={yAxisKey}
                      barKeys={totalSocialKeys}
                      is100Percent={radioValue === RADIO_VALUE.PERCENT}
                      tooltipContentData={tooltipTotalSocialChart}
                      fillColor={fillColorTotalSocialChart}
                      margin={{ left: 80, bottom: 40, right: 0 }}
                      tickFormat={(val) => formatVal(val, 0)}
                      unitXAxis={
                        radioValue === RADIO_VALUE.PERCENT
                          ? '(%)'
                          : 'economy.fiscal.stateBudget.BILLION_VND_UNIT'
                      }
                    />
                  </div>
                ) : (
                  <ChartContainer
                    data={data}
                    width={width}
                    height={height - size.height}
                    keyXAxis={isTotalSocialChart ? totalSocialKeys : keyXAxis}
                    isUseXAxisDiv={!isTotalSocialChart}
                    showAllTick
                    yAxis={keyYAxis}
                    hasBarChart
                    renderCustomTooltip={renderTooltip}
                    layout={isTotalSocialChart ? 'vertical' : 'horizontal'}
                    xAxisType={isTotalSocialChart ? 'number' : 'category'}
                    xAxisLabelCustom={isTotalSocialChart && xAxisLabelCustom}
                    showAllTickInTwoLines
                  >
                    {isStateSector && (
                      <>
                        {stateSectorKeys.map((item, index) => {
                          return (
                            <Bar
                              key={index}
                              barSize={getColumnSizeInBarChart(
                                width,
                                data.length,
                              )}
                              dataKey={item}
                              fill={STACK_COLORS[2][index]}
                              yAxisId="left"
                              isAnimationActive={false}
                            />
                          )
                        })}
                      </>
                    )}
                    {isBudgetProvinces && (
                      <Bar
                        barSize={getColumnSizeInBarChart(width, data.length)}
                        dataKey={budgetProvincesKey}
                        fill={'#0096eb'}
                        yAxisId="left"
                        isAnimationActive={false}
                      />
                    )}
                  </ChartContainer>
                )}
              </>
            )}
            <Footer
              key={width}
              list={renderListFooter(keys, nameById, typeChartValue)}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
