export const COLOR_BAR = {
  YELLOW: '#facc5c',
  BLUE: '#185999',
  LIGHT_BLUE: '#40abff',
}

export const PEERS_COMPARISON_KEYS = [
  'totalProduction',
  'totalConsumption',
  'totalInventory',

  'productionBlackPipe',
  'consumptionBlackPipe',
  'inventoryBlackPipe',

  'productionCoatedPipe',
  'consumptionCoatedPipe',
  'inventoryCoatedPipe',
]

export const PEERS_COMPARISON_FILTER = [
  {
    name: 'sector.sectorSpecific.steelPipe.TOTAL',
    value: 0,
  },
  {
    name: 'sector.sectorSpecific.steelPipe.BLACK_PIPE',
    value: 1,
  },
  {
    name: 'sector.sectorSpecific.steelPipe.COATED_PIPE',
    value: 2,
  },
]
