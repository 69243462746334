export const topMover = {
  TOP_MOVER: 'Top mover',
  FILTER: 'Filter',
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative',
  TABLE_HEADER_TEXT: 'Price: x 1,000. Volume: x 1,000. Value: x 1,000,000.',
  MARKET_CAP: 'Market Cap (Billion VND) ',
  TIME_RANGE: 'Time range',
  ONE_DAY: '1 Day',
  ONE_WEEK: '1 Week',
  ONE_MONTH: '1 Month',
  THREE_MONTHS: '3 Month',
  SIX_MONTHS: '6 Month',
  ONE_YEAR: '1 Year',
  TICKER: 'TICKER',
  SECTOR: 'SECTOR',
  MATCH_PRICE: 'PRICE',
  TOTAL_VOLUME: 'TOTAL VOLUME',
  TOTAL_VALUE: 'TOTAL VALUE',
  CONTRIBUTION: 'CONTRIBUTION',
  PERCENT_CONTRIBUTION: '% CONTRIBUTION',
  NEWS: 'NEWS',
  EXCHANGE: 'Exchange',
  TOP: 'Top',
  LB_SECTOR: 'Sector',
  TABLE_HEADER_TEXT_TOP_BREAKOUT: 'Ratio of total volume to ',
  TABLE_HEADER_TEXT_TOP_BREAKOUT1: ' average volume is greater than ',
  ONE_POINT_TWO_TIMES: '1.2 times',
  ONE_POINT_FIVE_TIMES: '1.5 times',
  TWO_TIMES: '2 times',
  FIVE_TIMES: '5 times',
  TEN_TIMES: '10 times',
  LB_CONTRIBUTION: 'Contribution',
  LB_VOLUME: 'Volume',
  LB_VALUE: 'Value',
  LB_GAINER: 'Gainer',
  LB_LOSER: 'Loser',
  LB_BREAKOUT: 'Breakout',
  LB_NEW_HIGH: 'New High',
  LB_NEW_LOW: 'New Low',
  LB_NET_FOREIGN_VOLUME: 'Net Foreign Volume',
  LB_NET_FOREIGN_VALUE: 'Net Foreign Value',
  LB_ALL_SECTOR: 'All',
  LB_ALL_INDEX: 'All',
  FOREIGN_BUY_VOLUME_TOTAL: 'Fr. Total Buy Volume',
  FOREIGN_SELL_VOLUME_TOTAL: 'Fr. Total Sell Volume',
  FOREIGN_NET_VOLUME_TOTAL: 'Fr. Total Net Volume',
  AVG_TOTAL_VOLUME: 'Avg. Total Volume 1W',
  AVG_TOTAL_VOLUME_OneWeek: 'Avg. Total Volume 1W',
  AVG_TOTAL_VOLUME_OneMonth: 'Avg. Total Volume 1M',
  AVG_TOTAL_VOLUME_ThreeMonths: 'Avg. Total Volume 3M',
  AVG_TOTAL_VOLUME_SixMonths: 'Avg. Total Volume 6M',
  AVG_TOTAL_VOLUME_OneYear: 'Avg. Total Volume 1Y',
  TIMES: 'times',
  BUY: 'Buy',
  SELL: 'Sell',
  LOWEST_PRICE: 'Lowest Price 1D',
  LOWEST_PRICE_OneWeek: 'Lowest Price 1W',
  LOWEST_PRICE_OneMonth: 'Lowest Price 1M',
  LOWEST_PRICE_ThreeMonths: 'Lowest Price 3M',
  LOWEST_PRICE_SixMonths: 'Lowest Price 6M',
  LOWEST_PRICE_OneYear: 'Lowest Price 1Y',
  FOREIGN_BUY_VALUE_TOTAL: 'Fr. Total Buy Value',
  FOREIGN_SELL_VALUE_TOTAL: 'Fr. Total Sell Value',
  FOREIGN_NET_VALUE_TOTAL: 'Fr. Total Net Value',
  HIGHEST_PRICE: 'Highest Price 1D',
  HIGHEST_PRICE_OneWeek: 'Highest Price 1W',
  HIGHEST_PRICE_OneMonth: 'Highest Price 1M',
  HIGHEST_PRICE_ThreeMonths: 'Highest Price 3M',
  HIGHEST_PRICE_SixMonths: 'Highest Price 6M',
  HIGHEST_PRICE_OneYear: 'Highest Price 1Y',
  PERCENT_PRICE_CHANGE: '% 1D Change',
  PERCENT_PRICE_CHANGE_OneWeek: '% 1W Change',
  PERCENT_PRICE_CHANGE_OneMonth: '% 1M Change',
  PERCENT_PRICE_CHANGE_ThreeMonths: '% 3M Change',
  PERCENT_PRICE_CHANGE_SixMonths: '% 6M Change',
  PERCENT_PRICE_CHANGE_OneYear: '% 1Y Change',
  PRICE_CHANGE: 'Change',
  PRICE_CHANGE_OneWeek: '1W Change',
  PRICE_CHANGE_OneMonth: '1M Change',
  PRICE_CHANGE_ThreeMonths: '3M Change',
  PRICE_CHANGE_SixMonths: '6M Change',
  PRICE_CHANGE_OneYear: '1Y Change',
}
