import { getNewDashboardData } from '../../helpers'
import { Item } from './Item'
import { List } from '../List'

export const ItemChildren = ({
  data,
  isParentGroup,
  isNewDashboardInGroup,
  collapseOpen,
  onClickItem,
  onAddNewDashboardInGroup,
}) => {
  return (
    <>
      {isParentGroup && collapseOpen && (
        <>
          {data.children && (
            <List
              data={data.children}
              isNewDashboardGroup={false}
              isParentGroup={false}
              onClickItem={onClickItem}
            />
          )}
          {isNewDashboardInGroup && (
            <Item
              data={getNewDashboardData(data, false, true)}
              isNewDashboard
              isParentGroup={false}
              onClickItem={onClickItem}
              onAddNewDashboardInGroup={onAddNewDashboardInGroup}
            />
          )}
        </>
      )}
    </>
  )
}
