import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class BalanceOfPaymentProxy extends ProxyBase {
  getNameOrder(params) {
    return this.get('NameOrder', params)
  }

  getByTimeRange(params) {
    return this.get('GetByTimeRange', params)
  }

  getGrowthByTimeRange(params) {
    return this.get('GrowthByTimeRange', params)
  }

  getChangeByTimeRange(params) {
    return this.get('ChangeByTimeRange', params)
  }

  getDownloadBalanceOfPayment(params) {
    return this.get('DownloadBalanceOfPayment', params, 'download')
  }
}

export default new BalanceOfPaymentProxy(
  ServiceProxyConfig.Economy.BalanceOfPayment.ServiceUrl,
)
