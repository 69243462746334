import { SizeTracker } from '../../../common/sizeTracker'
import GroupButton from './GroupButton'
import TableCondition from './TableCondition'

const Condition = ({ sizes }) => {
  return (
    <div className="box-condition h-100">
      <SizeTracker>
        {(size) => (
          <>
            <div className="head-condition d-flex j-b">
              <GroupButton />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <TableCondition sizes={sizes} />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}

export default Condition
