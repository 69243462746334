import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { valDivThousand } from '../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const PriceAndVolumeChart = ({
  data,
  width,
  height,
  keyXAxis,
  lineKey,
  barKey,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const footerData = () => {
    return [
      {
        color: '#445366',
        text: 'corporate.companySnapshot.VOLUME',
        type: FOOTER_TYPE.SQUARE,
      },
      {
        color: '#EE6590',
        text: 'corporate.companySnapshot.PRICE',
        type: FOOTER_TYPE.LINE,
      },
    ]
  }

  const dataFormat = formatDataChart(data, barKey, lineKey)

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={dataFormat}
              width={width}
              height={height || 0}
              keyXAxis={keyXAxis}
              hideToolTipChart
              margin={{ right: -20, left: 0 }}
              styleTickXAxis={{
                fill: '#000000',
                fontSize: 8,
                fontWeight: 500,
              }}
              styleTickYAxis={{
                fill: '#000000',
                fontSize: 10,
                fontWeight: 500,
                labelFontSize: 8,
              }}
              tickFormatter={(val) => formatDateTime(val, FORMAT.DATE, locale)}
              timeFrame={TIME_RANGES['1M']}
              interval={0}
              xTickNum={SETTINGS.xTickNum}
              yAxis={[
                {
                  id: 'left',
                  keys: [barKey],
                  orientation: 'left',
                  isBarChart: true,
                  tickNum: SETTINGS.yTickNum,
                  label: I18n.t('corporate.companySnapshot.SHARES_1'),
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  themeDownloadPDF: true,
                },
                {
                  id: 'right',
                  keys: [lineKey],
                  orientation: 'right',
                  isBarChart: true, //request has a value of 0 on the vertical axis
                  tickNum: SETTINGS.yTickNum,
                  label: ' ',
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                  themeDownloadPDF: true,
                },
              ]}
            >
              <Bar
                isAnimationActive={false}
                yAxisId="left"
                dataKey={barKey}
                fill="#445366"
              />
              <Line
                yAxisId="right"
                dataKey={lineKey}
                type="linear"
                stroke={'#EE6590'}
                dot={false}
                strokeWidth={1}
                activeDot={false}
                isAnimationActive={false}
              />
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            itemStyle={{ fixedFontSize: 10 }}
          />
        </>
      )}
    </SizeTracker>
  )
}

PriceAndVolumeChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export const formatDataChart = (data, barKey, lineKey) => {
  return data.map((item) => ({
    ...item,
    [barKey]: valDivThousand(item[barKey]),
    [lineKey]: item[lineKey] / 1000,
  }))
}
