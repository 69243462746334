import { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { ChangeBalance } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'

export const PanelChangeBalance = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const titleJpg = I18n.t(
    'economy.paymentBalance.paymentBalance.CHANGE_BALANCE',
  )
  const nameScreen = I18n.t(
    'economy.paymentBalance.paymentBalance.BALANCE_OF_PAYMENT',
  )

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title="economy.paymentBalance.paymentBalance.CHANGE_BALANCE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'changeBalance',
        nameScreen: nameScreen,
        chartName: titleJpg,
      }}
      handleCustom={setIsFullScreen}
    >
      <ChangeBalance
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
