import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Span } from '../../../../common/html/Span'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import {
  getLocationId,
  selectLocationName,
} from '../panelGRDPStructureProvince/store/slice'
import GRDPChanges from './GRDPChanges'

export const CHANGES_IN_GRDP_STRUCTURE = 'ChangeInGRDPStructure'

const PanelGRDPChanges = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const locationName = useSelector(selectLocationName)
  const locationId = useSelector(getLocationId)
  const nameScreen = UseI18n('constants.route.economy.gdp.gdpByProvince.LABEL')
  const location = locationName.find((item) => item.locationId === locationId)
  const chartName =
    UseI18n('economy.gdp.gdp.CHANGES_IN_GDP') + location?.locationName
  const tickerName = basicInfo.ticker

  const renderCustomTitle = () => {
    return (
      <div>
        <Span>
          {UseI18n('economy.gdp.gdp.CHANGES_IN_GDP')}
          {` ${location?.locationName}`}
        </Span>
      </div>
    )
  }

  return (
    <Panel
      renderCustomTitle={renderCustomTitle}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CHANGES_IN_GRDP_STRUCTURE,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <GRDPChanges
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelGRDPChanges
