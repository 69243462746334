export const TYPE_DATA = {
  VALUE: 'Value',
  GROWTH: 'Growth',
}
export const LIST_RADIO = [
  {
    title: 'economy.importExport.importExport.RADIO_BUTTON_RELATIVE',
    value: TYPE_DATA.GROWTH,
  },
  {
    title: 'economy.importExport.importExport.RADIO_BUTTON_ABSOLUTE',
    value: TYPE_DATA.VALUE,
  },
]

export const TIME_TYPE = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
}

export const TABLE_FILTER = [
  {
    title: 'economy.importExport.importExport.FILTER_YEARLY',
    value: TIME_TYPE.YEARLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY',
    value: TIME_TYPE.MONTHLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY_ACC',
    value: TIME_TYPE.ACC_MONTHLY,
  },
]

export const TYPE_CATEGORY = {
  CONTINENT: 'Continent',
  TRADE_AGREEMENTS: 'Trade Agreements',
}

export const CATEGORY_FILTER = [
  {
    title:
      'economy.importExport.importExport.exportImportByLocation.FILTER_CONTINENT',
    value: TYPE_CATEGORY.CONTINENT,
  },
  {
    title:
      'economy.importExport.importExport.exportImportByLocation.FILTER_TRADE_AGREEMENTS',
    value: TYPE_CATEGORY.TRADE_AGREEMENTS,
  },
]

export const DEFAULT_MAX_MONTH = 6
export const DEFAULT_MAX_YEAR = 2022

export const LIMIT_FIRST_FETCH = 8
export const LIMIT_FETCH = 4
