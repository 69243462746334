import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import SelectCustom from '../../../common/selectCustom'
import {
  arrayExchanges,
  arrayExchangesTopContribution,
  TYPE_TOP_MOVER,
} from '../constants'
import { changeFilterExchange, selectFilter } from '../store/slice'

const FilterExchange = () => {
  const dispatch = useDispatch()
  const filter = useSelector(selectFilter)

  const changeDataExchange = (value) => {
    dispatch(changeFilterExchange(value))
  }

  const getListSelect = () => {
    if (filter.top === TYPE_TOP_MOVER.CONTRIBUTION) {
      return arrayExchangesTopContribution
    } else {
      return arrayExchanges
    }
  }

  useEffect(() => {
    if (filter.top === TYPE_TOP_MOVER.CONTRIBUTION) {
      dispatch(changeFilterExchange('VNIndex'))
    }
  }, [filter.top])

  return (
    <div className="mb-40">
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.topMover.EXCHANGE" />
      </Span>
      <div className="mt-8">
        <SelectCustom
          value={filter.exchange}
          isI18n={true}
          selectData={getListSelect().map((item) => ({
            name: item.groupCode,
            value: item.exchangeCode,
          }))}
          handleChange={changeDataExchange}
        />
      </div>
    </div>
  )
}

export default FilterExchange
