import { getFontSize } from '../../../../../../utils/FontSize'
import { ChartContainer } from '../../../common/ChartContainer'
import { PieChart } from '../../../common/PieChart'
import {
  chartFillColor,
  chartMappingDisplayName,
  chartOtherPieKey,
  chartPieKeys,
} from './constants'

export const ChartComponent = ({ data, width, height, loading }) => {
  const fontSize = getFontSize(12)

  return (
    <div style={{ height: height || 0 }}>
      <ChartContainer
        id="assetLoanStructureChartContainer"
        data={data ? [data] : []}
        height={height}
        width={width}
        isLoading={loading}
      >
        {(resizeSize) => (
          <PieChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            pieKeys={chartPieKeys}
            otherKey={chartOtherPieKey}
            fontSize={fontSize}
            mappingDisplayName={chartMappingDisplayName}
            isFooterColumn
          />
        )}
      </ChartContainer>
    </div>
  )
}
