import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import Unit from '../../common/Unit'
import { getLimitTime } from '../panelOpenMarketAuction/store/slice'
import { CalendarSearch } from './CalendarSearch'
import {
  getFromDate,
  getToDate,
  handleFromDate,
  handleIsScrollRight,
  handleToDate,
  selectMaxDateOMO,
} from './store/slice'
import { getMaxDateOMO } from './store/thunk'

const Header = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const fromDate = useSelector(getFromDate)
  const toDate = useSelector(getToDate)
  const limitTime = useSelector(getLimitTime)
  const maxDateOMO = useSelector(selectMaxDateOMO)
  const locale = useSelector((state) => state.i18n.locale)

  const onDateFromChange = (fromDate) => {
    dispatch(handleFromDate(fromDate))
    dispatch(handleIsScrollRight(true))
  }

  const onDateToChange = (toDate) => {
    dispatch(handleToDate(toDate))
    dispatch(handleIsScrollRight(true))
  }

  const OMODate = useMemo(() => {
    if (!maxDateOMO) return
    const [, maxDate] = maxDateOMO
    if (!maxDate || !Object.keys(maxDate).length) return
    const date = new Date(
      maxDate.realYear,
      maxDate.realMonth - 1,
      maxDate.realDay,
    )
    return formatDateTime(date, FORMAT.DATE, locale, timeZone)
  }, [maxDateOMO])

  useEffect(() => {
    dispatch(getMaxDateOMO())
  }, [locale])

  return (
    <div className="d-flex align-center j-b mb-8">
      <div className="d-flex align-center">
        <Unit title="economy.monetary.monetary.UNIT_BILLION" />
        <VerticalLine
          ml={8}
          mr={8}
          styleProps={{ height: 20, backgroundColor: '#5c5c5c' }}
        />
        <Unit title="economy.monetary.monetary.DATA_UPDATE" />
        &nbsp;{OMODate && <Unit title={OMODate} isI18n={false} />}
      </div>
      <div className="d-flex justify-center align-center">
        <CalendarSearch
          dateFrom={fromDate}
          dateTo={toDate}
          onDateFromChange={onDateFromChange}
          onDateToChange={onDateToChange}
          limitDatepicker={limitTime}
        />
      </div>
    </div>
  )
}

export default Header
