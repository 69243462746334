import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import FooterWithScroll from '../../../../../common/chart/footer/footerWithScroll'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { handleLineColor } from '../../table/generalIndex/helper'
import {
  getCheckboxChecked,
  handleCheckboxChecked,
  handleLineChartColor,
} from '../../table/generalIndex/store/slice'
import { ID_DOM_PANEL_PRODUCTION } from '../constants'
import { handleTooltipTwoColumn } from '../helper'
import style from '../index.module.css'
import { changeActiveSelect, selectActiveSelect } from '../store/slice'
import Tooltip from './Tooltip'

const YAXIS_LEFT = 'left'

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}

const ChartComponentPPI = ({
  data,
  width,
  height,
  keyXAxis,
  keyYAxisProp,
  idWithTitle,
  lineChartColor,
  isFullScreen,
}) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const activeLine = useSelector(selectActiveSelect)
  const checkboxChecked = useSelector(getCheckboxChecked)

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  const { leftColumn, rightColumn } = handleTooltipTwoColumn(
    keyYAxisProp,
    idWithTitle,
  )
  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: keyYAxisProp,
      orientation: YAXIS_LEFT,
      isLineChart: true,
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: '%',
    },
  ]

  // Actions
  const handleDelete = (item) => {
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => val !== item.author,
    )

    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(handleLineColor(item.author, lineChartColor, true)),
    )
    dispatch(changeActiveSelect(undefined))
  }

  const handleHover = (item) => {
    dispatch(changeActiveSelect(item.author))
  }

  const handleUnHover = () => {
    dispatch(changeActiveSelect(undefined))
  }

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
        </div>
      </>
    )
  }

  return (
    <div id={ID_DOM_PANEL_PRODUCTION.FIRST_CHART}>
      <>
        <ChartContainer
          data={data}
          height={height - heightListIndicator}
          width={width}
          keyXAxis={keyXAxis}
          yAxis={keyYAxis}
          renderCustomTooltip={renderTooltip}
          margin={{ ...MARGIN_RECHARTS, left: 12, right: 36 }}
          timeFrame={TIME_RANGES.CUSTOM}
          isNotFormatXAxis
        >
          {lineChartColorHasAuthor.map((item, index) => {
            return (
              <Line
                key={index}
                isAnimationActive={false}
                yAxisId={YAXIS_LEFT}
                dataKey={item.author}
                stroke={item.color}
                strokeWidth={
                  activeLine === item.author
                    ? SETTINGS.strokeActiveWidth
                    : SETTINGS.strokeWidth
                }
                dot={false}
                strokeOpacity={
                  activeLine !== item.author && activeLine
                    ? SETTINGS.blur
                    : SETTINGS.normalOrHover
                }
                activeDot={false}
              />
            )
          })}
        </ChartContainer>
        <FooterWithScroll
          width={width}
          data={lineChartColorHasAuthor}
          idKey="author"
          nameKey="indicatorName"
          formatName={(item) => idWithTitle[item.author]}
          color={(item) => item.color}
          legendPanel={legendPanel}
          isFullScreen={isFullScreen}
          setHeightFooter={setHeightListIndicator}
          onDelete={handleDelete}
          onHover={handleHover}
          onUnHover={handleUnHover}
        />
      </>
    </div>
  )
}

ChartComponentPPI.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
  keyYAxisProp: PropTypes.array.isRequired,
  idWithTitle: PropTypes.object.isRequired,
  lineChartColor: PropTypes.array.isRequired,
  isFullScreen: PropTypes.bool,
}

export default ChartComponentPPI
