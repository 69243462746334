import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import EventEmitter, { RESET_TABLE_SORT } from '../../../../utils/EventEmitter'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { TYPE_FORMAT } from '../../common/constants'
import { TABLE_SECTOR_ID } from './constant'
import Filter from './Filter'
import {
  changeDefaultScrollToRight,
  changeIndicator,
  resetDataTable,
  selectDefaultScrollToRight,
  selectFilterStatus,
  selectGroupColumns,
  selectIds,
  selectIndicator,
  selectLoading,
  selectPayloadCallBack,
  selectTimeFrequency,
  selectTypeFormatData,
  selectValueById,
  selectViewBy,
  sort,
} from './store/slice'
import { getTableData } from './store/thunk'
import style from './style.module.css'

const TotalRegisterFDI = ({ width }) => {
  const dispatch = useDispatch()
  const refSizeTracker = useRef()
  const refTextNote = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const FDITypeId = useSelector(selectFilterStatus)
  const isLoading = useSelector(selectLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const ids = useSelector(selectIds)
  const timeFrequency = useSelector(selectTimeFrequency)
  const indicator = useSelector(selectIndicator)
  const viewBy = useSelector(selectViewBy)
  const payloadCallBack = useSelector(selectPayloadCallBack)
  const defaultScrollToRight = useSelector(selectDefaultScrollToRight)
  const typeFormatData = useSelector(selectTypeFormatData)

  const [sizeTracker, setSizeTracker] = useState(0)
  const [sortField, setSortField] = useState({})

  const setIsFirstTime = (value) => {
    dispatch(changeDefaultScrollToRight(value))
  }

  const scrollToLeftCallback = () => {
    if (payloadCallBack.year && payloadCallBack.month) {
      setTimeout(() => {
        dispatch(
          getTableData({
            FDITypeId: FDITypeId,
            TimeFrequency: timeFrequency,
            Year: payloadCallBack.year,
            Month: payloadCallBack.month,
            NumberOfPeriod: 5,
          }),
        )
      })
      setIsFirstTime(false)
    }
  }

  useEffect(() => {
    setSortField({})
    EventEmitter.dispatch(RESET_TABLE_SORT)
  }, [viewBy, FDITypeId, timeFrequency])

  useEffect(() => {
    dispatch(sort(sortField))
    setIsFirstTime(true)
  }, [sortField])

  useEffect(() => {
    if (FDITypeId)
      dispatch(
        getTableData({
          FDITypeId: FDITypeId,
          TimeFrequency: timeFrequency,
          NumberOfPeriod: 20,
        }),
      )
    setIsFirstTime(true)
  }, [locale, FDITypeId, timeFrequency])

  useEffect(() => {
    if (refSizeTracker?.current?.offsetHeight)
      setSizeTracker(refSizeTracker?.current?.offsetHeight)
  }, [refSizeTracker?.current?.offsetHeight, width])

  useEffect(() => {
    dispatch(resetDataTable())
  }, [])

  return (
    <>
      <Filter ref={refSizeTracker} setIsFirstTime={setIsFirstTime} />
      <div
        key={timeFrequency}
        style={{
          height: `calc(100% - ${
            sizeTracker + refTextNote?.current?.offsetHeight
          }px)`,
        }}
        id={TABLE_SECTOR_ID}
      >
        {isLoading && defaultScrollToRight ? (
          <Loading />
        ) : (
          <Table
            ids={ids}
            columnDraggable={false}
            getDataFromRedux={selectValueById}
            isLoading={isLoading}
            rowDraggable={false}
            sort={sort}
            schema={groupColumns.map((item, index) => {
              const title = I18n.t(item.title)
              const colId = item.key
              let result = {
                colId,
                title,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  textAlign: 'right',
                },
                canCustomTd: true,
                render: (val, rowId, props) => {
                  return (
                    <td
                      {...props}
                      style={{
                        ...props.style,
                        textAlign: 'right',
                        color:
                          (val === EMPTY_VALUE || parseInt(val)) >= 0
                            ? undefined
                            : '#ff4752',
                      }}
                    >
                      {typeFormatData === TYPE_FORMAT.CAPITAL
                        ? formatVal(valDivMillion(val))
                        : val}
                    </td>
                  )
                },
              }
              if (index === 0) {
                result = {
                  ...result,
                  isI18n: true,
                  thStyle: {
                    textAlign: 'left',
                    textTransform: 'uppercase',
                  },
                  tdStyle: {
                    textAlign: 'left',
                  },
                  canCustomTd: true,
                  render: (val, rowId, { style, className }) => (
                    <td
                      className={className}
                      style={{
                        ...style,
                        width: '50%',
                        maxWidth: width / 3,
                      }}
                    >
                      <TextEllipsis isI18n={false} text={val} />
                    </td>
                  ),
                }
              }
              return result
            })}
            stickyFirstColumn
            isPagination={false}
            hasFooter={false}
            resizable={false}
            changeActiveRow={changeIndicator}
            defaultActiveRowId={indicator?.key || ids[0]}
            scrollToLeftCallback={scrollToLeftCallback}
            isLazyLoadLeft={true}
            defaultScrollToRight={defaultScrollToRight}
            handleSortField={setSortField}
          />
        )}
        <div ref={refTextNote}>
          {viewBy && (
            <Span className={style.labelTable} style={{ fontSize: 11 }}>
              <Translate value="economy.fdi.overview.LABEL_TABLE" />
            </Span>
          )}
        </div>
      </div>
    </>
  )
}

export default TotalRegisterFDI
