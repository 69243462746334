import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import { HEADER_VALUE } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import { getNominalRealValue } from '../store/slice'

import style from './index.module.css'
import SearchBox from './SearchBox'
import {
  getValueGrowthNominal,
  getValueGrowthReal,
  handleValueGrowthNominal,
  handleValueGrowthReal,
} from './store/slice'

const { NOMINAL } = HEADER_VALUE

const FilterGroup = () => {
  const dispatch = useDispatch()
  const { VALUE, GROWTH_YOY, PER_CAPITA } = GDP_PROVINCE_VALUE

  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const nominalRealValue = useSelector(getNominalRealValue)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  const RADIO_LIST = [
    {
      value: VALUE,
      label: 'economy.gdp.gdp.VALUE',
    },
    {
      value: GROWTH_YOY,
      label: 'economy.gdp.gdp.GROWTH_YOY',
    },
    {
      value: PER_CAPITA,
      label: `economy.gdp.gdp.${
        nominalRealValue === NOMINAL ? 'PER_CAPITA' : 'PER_CAPITA_REAL'
      }`,
    },
  ]

  const handleChangeRadio = (radioValue) => {
    if (nominalRealValue === NOMINAL) {
      dispatch(handleValueGrowthNominal(radioValue))
    } else {
      dispatch(handleValueGrowthReal(radioValue))
    }
  }

  return (
    <div className={`d-flex align-center ${style.wrapFilter}`}>
      <SearchBox />
      <VerticalLine ml={16} mr={16} styleProps={{ height: 20 }} />
      {RADIO_LIST.map(({ value, label }) => {
        return (
          <div
            key={value}
            className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  ${
                    value === GROWTH_YOY &&
                    nominalRealValue === NOMINAL &&
                    style.disabledText
                  }
                  `}
            onClick={
              value === GROWTH_YOY && nominalRealValue === NOMINAL
                ? () => {}
                : () => handleChangeRadio(value)
            }
          >
            <input
              type="radio"
              className={`radiobox radiobox2 ${
                value === GROWTH_YOY &&
                nominalRealValue === NOMINAL &&
                style.disabledRadio
              }`}
              checked={value === radioValue}
              onChange={
                value === GROWTH_YOY && nominalRealValue === NOMINAL
                  ? () => {}
                  : () => handleChangeRadio(value)
              }
            />
            <Span style={{ fontSize: 12, fontWeight: 'normal' }}>
              <Translate value={label} />
            </Span>
          </div>
        )
      })}
    </div>
  )
}

export default FilterGroup
