import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Table } from '../../../../common/table'
import {
  changeEventDetailId,
  changePage,
  changeSort,
  selectCurrentPage,
  selectEventIds,
  selectEventTypes,
  selectEventValue,
  selectFilter,
  selectLoadingEventTypes,
  selectLoadingEvents,
  selectReCalcWidths,
  selectTotalPage,
  selectTotalRecords,
  sortEvents,
} from '../../store/slice'
import style from '../index.module.css'
import * as schemas from './schemas'

const TableEvent = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const eventIds = useSelector(selectEventIds)
  const eventTypes = useSelector(selectEventTypes)
  const filter = useSelector(selectFilter)
  const loadingEvents = useSelector(selectLoadingEvents)
  const loadingEventTypes = useSelector(selectLoadingEventTypes)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const currentPage = useSelector(selectCurrentPage)
  const totalPage = useSelector(selectTotalPage)
  const totalRecords = useSelector(selectTotalRecords)
  const loading = loadingEvents || loadingEventTypes

  const [schema, setSchema] = useState(schemas.summarySchema())

  useEffect(() => {
    if (filter.fiinXEventTypeIds.length === 1) {
      setSchema(schemas.getSchemaById(filter.fiinXEventTypeIds[0]))
    } else {
      setSchema(schemas.summarySchema())
    }
  }, [filter.fiinXEventTypeIds, eventTypes, locale])

  const getStickies = () => {
    let stickies = {}
    if (schema.length > 0) {
      stickies = {
        [schema[0].colId]: true,
      }
    }

    if (schema.length > 1) {
      stickies = {
        ...stickies,
        [schema[1].colId]: true,
      }
    }

    return stickies
  }

  const handleChangePage = (page) => {
    dispatch(changePage(page))
  }

  const handleRowClick = (rowId) => {
    dispatch(changeEventDetailId(rowId))
  }

  const handleSortField = (sort) => {
    dispatch(changeSort(sort))
  }

  return (
    <Table
      ids={eventIds}
      getDataFromRedux={selectEventValue}
      isLoading={loading}
      schema={schema}
      sort={sortEvents}
      handleSortField={handleSortField}
      onRowClick={handleRowClick}
      isLargeHead={true}
      hasFooter={false}
      reCalcWidths={reCalcWidths}
      stickies={getStickies()}
      hasPagination={true}
      currentPage={currentPage}
      totalPage={totalPage}
      handleChangePage={handleChangePage}
      renderContentPagination={() => (
        <Span style={{ fontSize: 11 }} className={style.noteTable}>
          {I18n.t('market.event.TOTAL_RESULT', { totalRecords: totalRecords })}
        </Span>
      )}
    />
  )
}

export default TableEvent
