import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import Popup from '../../../../../common/popup'
import { Search } from '../../../../../common/search'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { EXCHANGE_CODE } from '../constant'
import AddByExchange from './AddByExchange'
import AddByWatchlist from './AddByWatchList'
import style from './index.module.css'
import {
  changeFirstMountPopup,
  changeOrgIds,
  changeSelectedExchange,
  changeSelectedWL,
} from './store/slice'

const AddTicker = () => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)

  const [isShow, setIsShow] = useState(false)
  const [isUseWL, setIsUseWL] = useState(false)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(changeSelectedWL(null))
      dispatch(changeOrgIds([]))
    }
  }, [basicInfo.organizationId])

  useEffect(() => {
    if (isShow) {
      setKeyword('')
    }
  }, [isShow])

  useEffect(() => {
    dispatch(changeSelectedExchange(EXCHANGE_CODE.ALL_EXCHANGE))
    dispatch(changeFirstMountPopup(true))
  }, [basicInfo.organizationId])

  const handleClosePopup = () => {
    setIsShow(false)
  }

  const handleOpenPopup = () => {
    setIsShow(true)
  }

  return (
    <div>
      <button
        onClick={handleOpenPopup}
        className={`btn btn-add-more ${style.addTickerButton}`}
      >
        <Span style={{ fontSize: 10, fontWeight: 'normal' }}>
          <Translate value="corporate.peerValuation.overview.ADD_TICKER" />
        </Span>
        <i className="icon-plus" />
      </button>
      {isShow && (
        <Popup>
          <div className="modal modal-medium" style={{ width: 594 }}>
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="corporate.peerValuation.overview.ADD_TICKER" />
              </h3>
              <a onClick={handleClosePopup} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content" style={{ paddingTop: 8 }}>
              <div className="d-flex j-b">
                <DispatchActionTab
                  data={[
                    {
                      title: 'corporate.peerValuation.overview.ALL',
                      value: false,
                    },
                    {
                      title: 'corporate.peerValuation.overview.MY_WATCHLIST',
                      value: true,
                    },
                  ]}
                  activeTab={isUseWL}
                  onChangeTab={(item) => setIsUseWL(item.value)}
                  itemStyle={{
                    fontWeight: 600,
                    fontSize: 12,
                    width: 124,
                  }}
                  containerStyle={{ marginRight: 32 }}
                />
                <Search
                  onChange={(val) => setKeyword(val.trim())}
                  margin={'0px'}
                  width={212}
                />
              </div>
              {isUseWL ? (
                <AddByWatchlist
                  keyword={keyword}
                  handleClosePopup={handleClosePopup}
                />
              ) : (
                <AddByExchange
                  keyword={keyword}
                  handleClosePopup={handleClosePopup}
                />
              )}
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}

export default AddTicker
