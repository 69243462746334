import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useGetStock from '../../common/masterData/useGetStock'
import SearchBox from '../../common/topInfo/components/SearchBox'
import { SIMPLE_SEARCH_PARAMS } from '../../common/topInfo/stockTopInfo/constants'
import {
  selectListCompany,
  selectLoadingSearch,
} from '../../common/topInfo/stockTopInfo/store/slice'
import { getSearchCompanyData } from '../../common/topInfo/stockTopInfo/store/thunk'
import { ReactComponent as IconSearch } from '../icon/icon-search.svg'
import { linkedSecurity, selectDashboard } from '../store/slice'
import style from './index.module.css'

export const SearchCompany = () => {
  const dispatch = useDispatch()
  const loadingSearch = useSelector(selectLoadingSearch)
  const listCompany = useSelector(selectListCompany)
  const [title, setTitle] = useState(I18n.t('financialChart.SEARCH_COMPANY'))
  const [description, setDescription] = useState('')
  const { data = {} } = useSelector(selectDashboard)
  const { stocks } = useGetStock()

  const options = useMemo(
    () =>
      !loadingSearch
        ? listCompany.map((company) => {
            return { id: company.organizationId, value: { ...company } }
          })
        : [],
    [listCompany, loadingSearch],
  )

  const searchCompany = (keyword) => {
    let params = {
      isOnlySearchHOHAUP: false,
      ComTypes: undefined,
      Page: SIMPLE_SEARCH_PARAMS.page,
      PageSize: SIMPLE_SEARCH_PARAMS.pageSize,
    }
    if (keyword) {
      params = { ...params, Term: keyword }
    }
    dispatch(getSearchCompanyData(params))
  }

  const handleFocusInputSearch = () => {
    const params = {
      isOnlySearchHOHAUP: false,
      ComTypes: undefined,
      Page: SIMPLE_SEARCH_PARAMS.page,
      PageSize: SIMPLE_SEARCH_PARAMS.pageSize,
    }
    dispatch(getSearchCompanyData(params))
  }

  const handleSelect = (company) => {
    setTitle('')
    setDescription(company.ticker)
    dispatch(linkedSecurity(company.organizationId))
  }

  const handleSearch = (keyword) => {
    searchCompany(keyword)
  }

  useEffect(() => {
    if (data?.note) {
      const { linkedSecurity } = JSON.parse(data.note)
      const ticker = stocks.find(
        (i) => i.organizationId === linkedSecurity,
      )?.ticker
      setTitle('')
      setDescription(ticker)
    } else {
      setTitle(I18n.t('financialChart.SEARCH_COMPANY'))
      setDescription('')
    }
  }, [data])

  return (
    <div className="position-relative">
      <div className={[style.inputSearchCompany].join(' ')}>
        <SearchBox
          title={title}
          description={description}
          options={options}
          onFocus={handleFocusInputSearch}
          onTextChange={handleSearch}
          loading={loadingSearch}
          onSelect={handleSelect}
          renderOption={(value, isCurrentItem) => (
            <DropdownItem value={value} isCurrentItem={isCurrentItem} />
          )}
          classNameInputSearch={style.inputSearch}
          className={style.searchBox}
          bgColorScroll="#D9D9D9"
        />
      </div>
      <IconSearch className={style.iconSearchCompany} width={20} height={20} />
    </div>
  )
}

const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      {value.ticker ? (
        <span className={style.w25}>{value.ticker}</span>
      ) : (
        <span className={style.w25}>&nbsp;</span>
      )}
      <span className={style.textLongW50}>{value.organizationShortName}</span>
      <span className={style.w25AlignRight}>{value.exchangeCode}</span>
    </div>
  )
}
