import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import { GROUP_ROWS } from '../constants'
import { getRowSpanSchema } from '../helper'
import {
  keys,
  selectDataTableById,
  selectIssuanceValueData,
  selectLoading,
  setRowsCollapse,
  sort,
} from '../store/slice'
import FirstCellColumn from './FirstCellColumn'

const TableContent = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading(keys.ISSUANCE_VALUE))
  const { data, ids, rowsCollapse } = useSelector(selectIssuanceValueData)

  const onRowCollapse = (rowId, isCollapse) => {
    const index = rowsCollapse.indexOf(rowId)

    if (isCollapse && index === -1) {
      dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      return
    }

    dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
  }

  const levelCollapse = useMemo(
    () =>
      keyBy(
        data?.map((item) => ({
          id: item.id,
          level: item.level,
          isCollapsible: !item?.childrenIds?.length ?? false,
        })),
        'id',
      ),
    [data],
  )

  return (
    <Table
      ids={ids}
      columnDraggable={false}
      sort={sort}
      getDataFromRedux={selectDataTableById}
      rowDraggable={false}
      isLoading={isLoading}
      rowSpanSchema={getRowSpanSchema()}
      defaultSort={{ issuanceValueSummary: 'desc' }}
      schema={GROUP_ROWS.map((item, index) => {
        const title = item.title
        const colId = item.key
        const result = {
          colId,
          title,
        }

        if (index === 0) {
          return {
            ...result,
            thStyle: {
              fontSize: 12,
              fontWeight: 600,
              minWidth: 160,
            },
            tdStyle: {
              minWidth: 160,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              const tableHorizontalHead = data.find((item) => item.id === rowId)

              return (
                <FirstCellColumn
                  props={props}
                  val={tableHorizontalHead?.name ?? ''}
                  level={tableHorizontalHead?.level ?? 1}
                  childrenIds={tableHorizontalHead?.childIds?.length}
                  rowId={rowId}
                  onRowCollapse={onRowCollapse}
                  isRowCollapse={rowsCollapse.includes(rowId)}
                />
              )
            },
          }
        }

        return {
          ...result,
          isI18n: true,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
            minWidth: 100,
            maxWidth: 120,
          },
          tdStyle: {
            fontSize: 12,
          },
          canCustomTd: true,
          render: (val, rowId, props) => {
            let value

            switch (index) {
              case 1:
              case 2:
              case 4:
              case 5:
              case 7:
              case 8:
              case 10:
              case 11:
                value = formatVal(val, 2)
                break
              case 3:
              case 6:
              case 9:
                value = valToPercent(val)
                break
              default:
                value = val
                break
            }

            return (
              <td
                {...props}
                style={{
                  background: [1, 2, 3, 7, 8, 9].includes(index)
                    ? 'rgba(92, 92, 92, 0.15)'
                    : '#1E242E',
                  textAlign: 'right',
                  minWidth: 100,
                  maxWidth: 120,
                }}
              >
                <Span>{value}</Span>
              </td>
            )
          },
        }
      })}
      stickyFirstColumn={true}
      isPagination={false}
      hasFooter={false}
      resizable={false}
      isCollapse={true}
      levelCollapse={levelCollapse}
      rowsCollapse={rowsCollapse}
      hasTooltip={false}
    />
  )
}

export default TableContent
