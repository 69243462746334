import { useDispatch, useSelector } from 'react-redux'
import { exportToImg } from '../../../../../../common/downloadJpg/helper'
import UseI18n from '../../../../../../common/hooks/useI18n'
import useWindowDevicePixelRatio from '../../../../../../common/hooks/useWindowDevicePixelRatio'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { handleNameFileDownload } from '../../../../../../utils/Export'
import { getFontSize } from '../../../../../../utils/FontSize'
import { companyOptions } from '../constants'
import {
  selectCompanyType,
  selectQuarterType,
  selectYearType,
} from './store/slice'
import {
  downloadAssetsStructureFVTPLListedShareDecompositionTableAll,
  downloadAssetsStructureFVTPLListedShareDecompositionTableDetail,
} from './store/thunk'

export const CustomPanelHeaderActions = ({ dataType }) => {
  const dispatch = useDispatch()
  const { formatWithZoom, reverseFormatWithZoom } = useWindowDevicePixelRatio()
  const nameScreen = UseI18n(
    'sector.financialAnalysis.security.ASSET_STRUCTURE',
  )
  const chartName = UseI18n(
    'sector.financialAnalysis.security.assetStructure.FVTPL_DECOMPOSITION',
  )
  const fontSize = getFontSize(11)

  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const companyType = useSelector(selectCompanyType)
  const quarterType = useSelector(selectQuarterType)
  const yearType = useSelector(selectYearType)

  // Actions
  const downloadJpg = () => {
    exportToImg({
      domId: 'fvtplDecompositionChart',
      exportName: `${handleNameFileDownload(
        nameScreen, // feature name of the current interface
        chartName, // name chart
        `Q${quarterType}_${yearType}`, // name of the tab in the table (do not take the time frame part)
        currentInfo.ticker, //stock ticker/ index/ company short name
      )}.jpg`,
      fontSize,
      formatWithZoom,
      reverseFormatWithZoom,
    })
  }

  const onClick = async () => {
    if (companyType === companyOptions[0].value) {
      await dispatch(
        downloadAssetsStructureFVTPLListedShareDecompositionTableAll({
          IcbId: sectorInfo.icbId,
          ExchangeCode: dataType,
          Year: yearType,
          Quarter: quarterType,
        }),
      )
    } else {
      await dispatch(
        downloadAssetsStructureFVTPLListedShareDecompositionTableDetail({
          OrganizationId: companyType,
          Year: yearType,
          Quarter: quarterType,
        }),
      )
    }
    downloadJpg()
  }

  return (
    <a className="cursor-pointer align-center" onClick={onClick}>
      <img src={'/iconDownloadCsv.svg'} alt="download" width={10} height={10} />
    </a>
  )
}
