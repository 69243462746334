import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { convertSortKeyToNumber } from '../../../../../utils/Common'
import { FilterList } from './FilterList'
import { HandleLoadData } from './HandleLoadData'
import {
  DEFAULT_FILTER_HEIGHT_PERCENT,
  PAGE_NAVIGATION_HEIGHT,
  tableId,
} from './constants'
import { getRowSpanSchema, getSchema, getTableHeadRowTop } from './helper'
import style from './index.module.css'
import {
  changePage,
  changeSortField,
  changeSortOrder,
  selectDataTable,
  selectDataTableById,
  selectFilter,
  selectIds,
  selectIssuerOrganizations,
  selectLoadingTable,
  selectRecalcWidths,
  selectUnderlyings,
  sort,
} from './store/slice'
export const OverviewStatistics = ({ width, height }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  // Use selector
  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoadingTable)
  const filterData = useSelector(selectFilter)
  const reCalcWidths = useSelector(selectRecalcWidths)
  const underlyingsList = useSelector(selectUnderlyings)
  const issuerOrganizationsList = useSelector(selectIssuerOrganizations)
  const dataTable = useSelector(selectDataTable)
  const locale = useSelector((state) => state.i18n.locale)

  const [tableHeight, setTableHeight] = useState(0)

  // Get data
  const filterListHeight =
    document.getElementById('filterList')?.getBoundingClientRect().height ??
    height * DEFAULT_FILTER_HEIGHT_PERCENT

  const getMaxTradingDate = useMemo(() => {
    return dataTable.length ? dataTable[0].maxDate : filterData?.dateTo
  }, [dataTable, filterData?.dateTo])

  // Actions
  const setPage = (value) => {
    dispatch(changePage(value))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(changeSortField(Object.keys(data)[0]))
      dispatch(changeSortOrder(convertSortKeyToNumber(Object.values(data)[0])))
    } else {
      dispatch(changeSortField(null))
      dispatch(changeSortOrder(null))
    }
  }

  // Use effect
  useEffect(() => {
    if (height && filterListHeight) {
      setTableHeight(height - filterListHeight - PAGE_NAVIGATION_HEIGHT)
    }
  }, [height - filterListHeight])

  // Render
  const renderContentPagination = () => {
    return (
      <Span className={style.noteTable}>
        <Translate value="market.coveredWarrant.cwStatistics.NOTE_TABLE" />
      </Span>
    )
  }

  return (
    width &&
    height && (
      <>
        <div id="filterList">
          <FilterList
            filterData={filterData}
            underlyingsList={underlyingsList}
            issuerOrganizationsList={issuerOrganizationsList}
            width={width}
          />
        </div>
        {!isLoading && !dataTable.length ? (
          <div
            style={{
              height: tableHeight,
              paddingTop: '5px',
            }}
          >
            <NoData />
          </div>
        ) : (
          <div
            style={{
              height: tableHeight,
              paddingTop: '5px',
            }}
            id={tableId}
          >
            <Table
              ids={ids}
              sort={sort}
              resizable={false}
              stickies={{ id: true, cwCode: true }}
              getDataFromRedux={selectDataTableById}
              isLoading={isLoading}
              columnDraggable={false}
              hasTooltip={false}
              hasFooter={false}
              schema={getSchema(filterData, locale, timeZone, dataTable)}
              rowSpanSchema={getRowSpanSchema(
                getMaxTradingDate,
                locale,
                timeZone,
              )}
              tableHeadRowTop={getTableHeadRowTop()}
              hasPagination={true}
              currentPage={filterData?.page}
              totalPage={filterData?.totalPage}
              handleChangePage={setPage}
              renderContentPagination={renderContentPagination}
              reCalcWidths={reCalcWidths}
              handleSortField={onChangeSortField}
            />
          </div>
        )}
        <HandleLoadData />
      </>
    )
  )
}
