import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import {
  loadingKey,
  selectExchangesV2,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getExchangeThunkV2 } from './store/thunk'

const firstLevel = 2

const useGetExchangeV2 = (
  disableCallApi = true,
  isTree = false,
  filterFunc,
  isGetTreeNotDeleteChildren = false,
) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.EXCHANGEV2))
  const exchangesV2 = useSelector(selectExchangesV2)

  const treeRef = useRef(null)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getExchangeThunkV2())
    }
    treeRef.current = null
  }, [locale])

  const handleExchanges = (isDeleteChildren = true) => {
    if (treeRef.current && isDeleteChildren) {
      return treeRef.current
    }
    const filteredExchanges = filterFunc
      ? exchangesV2.filter(filterFunc)
      : exchangesV2
    const exchangesSorted = [...filteredExchanges]?.sort(
      (exchange1, exchange2) => exchange2?.groupLevel - exchange1?.groupLevel,
    )
    const exchangesById = keyBy(
      filteredExchanges.map((exchange) => {
        return { ...exchange, children: {} }
      }),
      'groupId',
    )
    exchangesSorted.forEach((exchange) => {
      if (
        exchangesById[exchange.parentGroupId] &&
        exchange.groupLevel !== firstLevel
      ) {
        exchangesById[exchange.parentGroupId].children[exchange.groupId] =
          exchangesById[exchange.groupId]
        if (isDeleteChildren) {
          delete exchangesById[exchange.groupId]
        }
      }
    })

    Object.keys(exchangesById).forEach((exchangeId) => {
      const newKey = `${exchangesById[exchangeId].groupOrder} - ${exchangesById[exchangeId].groupId}`
      exchangesById[newKey] = exchangesById[exchangeId]
      delete exchangesById[exchangeId]
    })
    if (isDeleteChildren) {
      treeRef.current = exchangesById
    }
    return exchangesById
  }

  return {
    loading,
    exchanges: exchangesV2,
    exchangesTree:
      isTree && !loading && exchangesV2.length ? handleExchanges() : {},
    exchangeTreeNotDeleteChildren:
      isTree && !loading && exchangesV2.length && isGetTreeNotDeleteChildren
        ? handleExchanges(false)
        : {},
  }
}

export default useGetExchangeV2
