import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceStatisticService from '../../../../../../../core/services/market/equityTrading/PriceStatisticService'
import { handleExport } from '../../../../../../utils/Export'

export const getForeign = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/foreign/GET_FOREIGN',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PriceStatisticService.getForeign(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadForeign = createAsyncThunk(
  'market/equityTrading/priceStatistics/summary/foreign/DOWNLOAD_FOREIGN',
  async (data) => {
    const response = await PriceStatisticService.downloadForeign(data)
    handleExport(response.data, response.filename)
  },
)
