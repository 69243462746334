import DefaultBondIdProxy from '../../../proxies/bond/valuationProxy/defaultBonid'
import InfoSecProxy from '../../../proxies/bond/valuationProxy/infoSecProxy'
import { ServiceBase } from '../../ServiceBase'

export class InfoSec extends ServiceBase {
  getListBond(params) {
    return this.getData(() => InfoSecProxy.getDataIssuePlan(params))
  }
  getAccruedInterest(params) {
    return this.getData(() => InfoSecProxy.getAccruedInterest(params))
  }
  getBondInfo(params) {
    return this.getData(() => InfoSecProxy.getDataBondInfoProxy(params))
  }
  getBondCashFlowScenarioApi(params) {
    return this.getData(() => InfoSecProxy.getDataBondCashFlowScenario(params))
  }
  getCreditEventApi(params) {
    return this.getData(() => InfoSecProxy.getCreditEventProxy(params))
  }
  getDefautBondId(params) {
    return this.getData(() => DefaultBondIdProxy.getDefaultBondIdProxy(params))
  }
  exportFileApi(params) {
    return this.getFileDownload(() => InfoSecProxy.exportFileProxy(params))
  }
}

export default new InfoSec()
