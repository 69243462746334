import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import TextEllipsis from '../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import TableHeader from './TableHeader'
import { columns } from './constants'

// import exclamation_yellow from './exclamation_yellow.png'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../corporateBond/bondSecurity/bondSecurity/Filter/store/slice'
import {
  selectDataValue,
  selectIds,
  selectInitialData,
  selectLoading,
  sortData,
} from './store/slice'

const Content = () => {
  const dispatch = useDispatch()

  const navigate = useCustomNavigate()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const ids = useSelector(selectIds)
  const data = useSelector(selectInitialData)

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  if (isLoading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }
  return (
    <Table
      ids={ids}
      isCollapse
      columnDraggable={false}
      sort={sortData}
      handleSortField={(e) =>
        dispatch(
          sortData({
            ...e,
            locale,
          }),
        )
      }
      getDataFromRedux={selectDataValue}
      isLoading={isLoading}
      rowDraggable={false}
      // changeIds={changeIds}
      idRowCustomStyle={['Total']}
      renderHeader={(stateSort, sortColumn) =>
        renderThead(columns, stateSort, sortColumn)
      }
      schema={columns.map((item, index) => {
        const title = I18n.t(item.title)
        const colId = item.key
        const result = {
          colId,
          title,
        }
        switch (index) {
          case 0:
            return {
              ...result,
              tdStyle: {
                ...item?.attrs?.style,
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...item?.attrs?.style,
                    }}
                  >
                    <div style={{ ...item?.attrs?.style }}>
                      <a
                        className="cursor-pointer"
                        style={{ ...item?.attrs?.style }}
                        onClick={() =>
                          redirectToBondIssuer(data[rowId]?.organizationId)
                        }
                      >
                        <TextEllipsis text={value} isI18n={false} />
                      </a>
                    </div>
                  </td>
                )
              },
            }
          case 1:
            return {
              ...result,
              tdStyle: {
                ...item?.attrs?.style,
              },
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      // ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    <Span style={{ ...item?.attrs?.style }}>
                      <a
                        className="cursor-pointer"
                        onClick={() =>
                          redirectToSecurityMaster(data[rowId]?.ticker ?? null)
                        }
                      >
                        <TextEllipsis text={value} isI18n={false} />
                      </a>
                    </Span>
                  </td>
                )
              },
            }
          case 2:
            return {
              ...result,
              tdStyle: {},
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatDateTime(value, FORMAT.DATE, locale)}
                  </td>
                )
              },
            }
          case 3:
            return {
              ...result,
              tdStyle: {},
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatVal(value * 100, 2)}
                  </td>
                )
              },
            }
          case 4:
            return {
              ...result,
              tdStyle: {},
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatVal(value)}
                  </td>
                )
              },
            }
          case 5:
            return {
              ...result,
              tdStyle: {},
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatVal(value, 0)}
                  </td>
                )
              },
            }
          case 6:
            return {
              ...result,
              tdStyle: {},
              canCustomTd: true,
              render: (value, rowId, props) => {
                return (
                  <td
                    style={{
                      ...props.style,
                      ...item?.attrs?.style,
                    }}
                  >
                    {formatVal(value * 100)}
                  </td>
                )
              },
            }
          default:
            return {}
        }
      })}
      stickyFirstColumn={false}
      isPagination={false}
      hasFooter={false}
      // changeActiveRow={changeIndicator}
      // defaultActiveRowId={defaultActiveRowId}
      resizable={false}
      // defaultScrollToRight
    />
  )
}

export default Content
