import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../html/Span'
import style from './index.module.css'

export const TabLink = ({
  defaultTab,
  value,
  tabs,
  is18n,
  disabled,
  onClick,
}) => {
  const [currentTab, setCurrentTab] = useState(tabs[0].value)

  const handleClick = (tab) => {
    if (!disabled) {
      setCurrentTab(tab.value)
      onClick(tab)
    }
  }

  useEffect(() => {
    if (defaultTab && defaultTab !== currentTab) {
      setCurrentTab(defaultTab)
    }
  }, [])

  useEffect(() => {
    if (value !== currentTab) {
      setCurrentTab(value)
    }
  }, [value])

  return (
    <div className={style.tabLinkContainer}>
      {tabs.map((item) => (
        <a
          key={item.value}
          className={`${style.tabLink} ${
            currentTab === item.value ? style.tabLinkActive : ''
          }`}
          onClick={() => handleClick(item)}
        >
          <Span style={{ fontSize: 12 }}>
            {is18n ? <Translate value={item.title} /> : item.title}
          </Span>
          {currentTab === item.value && (
            <div className={style.tabLinkActiveBorderBottom}></div>
          )}
        </a>
      ))}
    </div>
  )
}

TabLink.propTypes = {
  defaultTab: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  is18n: PropTypes.bool,
  onClick: PropTypes.func,
}
