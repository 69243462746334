import { ServiceProxyConfig } from '../../../../../configs/ServiceProxy'
import ProxyBase from '../../../ProxyBase'

export class IssuesPlan extends ProxyBase {
  getDataIssuePlan(params) {
    return this.get('Overview/IssuePlan', params)
  }
  exportFileProxy(params) {
    return this.post('Overview/IssuePlanExport', params, {}, 'download')
  }
}

export default new IssuesPlan(ServiceProxyConfig.Bond.PrimaryMarket.ServiceUrl)
