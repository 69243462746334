export const calcRelativeData = (data) => {
  if (data.length === 0) return []
  const dataFirstDay = {}
  const newData = JSON.parse(JSON.stringify(data))

  newData.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (key !== 'tradingDateId') {
        if (!dataFirstDay[key]) {
          dataFirstDay[key] = item[key]
          item[key] = 0
        } else {
          item[key] =
            ((item[key] - dataFirstDay[key]) / dataFirstDay[key]) * 100
        }
      }
    })
  })

  return newData
}
