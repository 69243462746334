import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'
import { handleExport } from '../../../../../utils/Export'

export const getTopPerformance = createAsyncThunk(
  'sector/sectorConstituents/overview/TOP_PERFORMANCE',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getTopPerformance(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadTopPerformance = createAsyncThunk(
  'sector/sectorConstituents/overview/DOWNLOAD_TOP_PERFORMANCE',
  async (data) => {
    const response =
      await SectorConstituentsOverviewService.downloadTopPerformance(data)
    handleExport(response.data, response.filename)
  },
)
