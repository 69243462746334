import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import Popup from '../../common/popup'
import { resetChartTabData } from '../chart/store/slice'
import { postCharts, updateCharts } from '../chart/store/thunk'
import { useGetUserSettings } from '../common/useGetUserSettings'
import { dashboardTypes, screenTypes } from '../constants'
import {
  changeDashboard,
  selectChartOfTheDay,
  selectDashboard,
  selectDashboardChartData,
} from '../store/slice'
import { updateAndReloadDashboard } from '../store/thunk'
import style from './style.module.css'
import styles from './styleTheme.module.css'

const PopupWarningSave = ({
  isShow,
  handleShow,
  isScreenCreateChart,
  handleSaveChart,
}) => {
  const dispatch = useDispatch()

  const { defaultDashboard } = useGetUserSettings()

  const { data = {} } = useSelector(selectDashboard)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const chartOfTheDay = useSelector(selectChartOfTheDay)

  const onClose = () => {
    handleShow(false)
  }

  const onCancel = () => {
    onClose()
    if (isScreenCreateChart) {
      const params = {
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
      }
      if (!data) {
        params.data = defaultDashboard || chartOfTheDay
      }
      dispatch(changeDashboard(params))
    } else {
      dispatch(
        changeDashboard({
          screenType: screenTypes.DASHBOARD,
        }),
      )
      dispatch(resetChartTabData())
    }
  }

  const onApply = async () => {
    onClose()

    if (isScreenCreateChart) {
      handleSaveChart()
    } else {
      const promises = []

      dashboardChartData.forEach((element) => {
        if (typeof element.id === 'number') {
          promises.push(dispatch(updateCharts(element)))
        } else {
          promises.push(dispatch(postCharts(element)))
        }
      })

      await Promise.allSettled(promises)
      await dispatch(
        updateAndReloadDashboard({
          ...data,
        }),
      )
      dispatch(
        changeDashboard({
          screenType: screenTypes.DASHBOARD,
        }),
      )
    }
  }

  if (isShow) {
    return (
      <Popup overlayCustom={style.overlayCustom}>
        <div className={`modal modal-small ${styles.modal}`}>
          <div
            className="modal-title"
            style={{
              justifyContent: 'end',
              background: '#ffffff',
              paddingTop: 8,
            }}
          >
            <a onClick={onClose} className="close-modal">
              <i className="icon-delete-stroke" style={{ color: '#646464' }} />
            </a>
          </div>
          <div className={`modal-content ${styles.modalContentStyle}`}>
            <div className={style.contentWarring}>
              <i className="icon-warning" />
              <Translate value="financialChart.WARNING_CANCEL_EDIT_DASHBOARD" />
            </div>
            <div className="group-btn d-flex justify-content-end w-100">
              <div className="d-flex">
                <Button
                  className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
                  style={{ fontSize: 11 }}
                  onClick={onCancel}
                >
                  <Translate value="financialChart.DONT_SAVE" />
                </Button>
                <Button
                  className={`btn w-80 h-20 ${styles.buttonBlueStyle}`}
                  style={{ fontSize: 11 }}
                  onClick={onApply}
                >
                  <Translate value="financialChart.SAVE" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

export default PopupWarningSave
