import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectFundById,
  selectFunds,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getAllFundsThunk } from './store/thunk'

const useGetFund = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.BOND))
  const funds = useSelector(selectFunds)
  const fundById = useSelector(selectFundById)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getAllFundsThunk())
    }
  }, [locale])

  return {
    loading,
    funds,
    fundById,
  }
}

export default useGetFund
