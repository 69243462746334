import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class SectorInDepthProxy extends ProxyBase {
  getSectorInDepth(params) {
    return this.get('GetSectorInDepthData', params)
  }

  getTotalValueBySector(params) {
    return this.get('GetTotalValueBySectorChartData', params)
  }

  getTopValue(params) {
    return this.get('GetTopTotalValueBySector', params)
  }

  downloadSectorInDepth(params) {
    return this.get('DownloadSectorStatisticData', params, 'download')
  }

  downloadTopValue(params) {
    return this.get('DownloadTopTotalValueBySectorData', params, 'download')
  }
}

export default new SectorInDepthProxy(
  ServiceProxyConfig.Market.SectorInDepth.ServiceUrl,
)
