import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { formatVal } from '../../utils/Value'
import { Input } from '../html/Input'
import DropdownTooltip from '../textEllipsis/DropdownTooltip'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/ //positive and negative float number
const decimalRegex = /^[0-9]*$/

const InputFormatCurrency = ({
  defaultValue,
  fractionDigits,
  prefix,
  suffix,
  handleBlur,
  className,
  decimalRender,
  disabled,
  style,
  isNumberFloat,
}) => {
  const [valueInput, setValueInput] = useState(() => {
    if (defaultValue) {
      return prefix
        ? prefix + String(defaultValue)
        : suffix
        ? formatVal(defaultValue, decimalRender) + suffix
        : formatVal(defaultValue, decimalRender)
    } else {
      return ''
    }
  })
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    setValueInput(() => {
      if (defaultValue) {
        return prefix
          ? prefix + String(defaultValue)
          : suffix
          ? formatVal(defaultValue, decimalRender) + suffix
          : formatVal(defaultValue, decimalRender)
      } else {
        return ''
      }
    })
  }, [defaultValue])

  const inputRef = useRef(
    defaultValue ? parseFloat(defaultValue).toFixed(fractionDigits) : '',
  )

  const cancelFormatValues = (value) => {
    return value.replace(/,/g, '') || ''
  }

  const onChange = (e) => {
    if (isNumberFloat && !decimalRegex.test(e.target.value)) {
      setValueInput(e.target.value.replace(/[^0-9]/g, ''))
      inputRef.current = e.target.value
        ? parseFloat(e.target.value.replace(/[^0-9]/g, ''))
        : ''
    } else if (e.target.value.match(regexValue)) {
      setValueInput(e.target.value)
      inputRef.current = e.target.value
        ? parseFloat(e.target.value).toFixed(fractionDigits)
        : ''
    } else {
      setValueInput(inputRef.current)
    }
  }

  const onFocus = () => {
    if (valueInput) {
      setValueInput(cancelFormatValues(valueInput))
    } else {
      setValueInput('')
    }
  }

  const onBlur = (e) => {
    const value = e.target.value.replace(/,/g, '')
    if (value) {
      prefix
        ? setValueInput(prefix + formatVal(value, decimalRender))
        : suffix
        ? setValueInput(formatVal(value, decimalRender) + suffix)
        : setValueInput(formatVal(value, decimalRender))
    }
    handleBlur(value)
  }

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <Input
        type="text"
        value={valueInput}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        className={`${className} h-20`}
        disabled={disabled}
        style={style}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      />
      {isShow && valueInput.length > 0 && (
        <DropdownTooltip
          containerRef={ref}
          text={
            prefix
              ? prefix + formatVal(valueInput.replace(/,/g, ''), decimalRender)
              : suffix
              ? formatVal(valueInput.replace(/,/g, ''), decimalRender) + suffix
              : formatVal(valueInput.replace(/,/g, ''), decimalRender)
          }
          isOpenTooltip={true}
          isI18n={false}
        />
      )}
    </>
  )
}

InputFormatCurrency.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleBlur: PropTypes.func,
  fractionDigits: PropTypes.number,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  className: PropTypes.string,
  decimalRender: PropTypes.number,
  disabled: PropTypes.bool,
  isNumberFloat: PropTypes.bool,
}

InputFormatCurrency.defaultProps = {
  fractionDigits: 2,
  className: '',
  decimalRender: 2,
  disabled: false,
}

export default InputFormatCurrency
