import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'
import { handleExport } from '../../../../../../utils/Export'
import axios from 'axios'

export const getPriceStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/price/priceStatistics',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SteelService.getPriceStatistics(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadPriceStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/price/downloadPriceStatistics',
  async (data) => {
    const response = await SteelService.getDownloadPriceStatistics(data)
    handleExport(response.data, response.filename)
  },
)
