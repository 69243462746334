import { Translate } from 'react-redux-i18n'
import { Span } from '../../html/Span'
import SelectCustom from '../../selectCustom'
import { ALL_TYPE } from '../constants'
import style from './index.module.css'

export const DetailNewsTypeSelect = ({
  changeFilter,
  categoryDropdownRef,
  categoryValue,
  categories,
  newsType,
  isShowNewType,
}) => {
  return (
    <div className={isShowNewType ? 'mt-30' : ''}>
      <label className={style.titleSelect}>
        <Span style={{ fontSize: 11 }}>
          <Translate
            value={
              isShowNewType
                ? 'common.news.DETAIL_NEWS_TYPE'
                : 'common.news.NEWS_TYPE'
            }
          />
        </Span>
      </label>
      <SelectCustom
        isI18n
        appendDropdownRef={categoryDropdownRef}
        value={categoryValue}
        selectData={[
          {
            newsCategoryId: ALL_TYPE,
            newsCategoryName: 'common.ALL',
          },
          ...categories.filter(
            (item) => item.parentNewsCategoryId === +newsType,
          ),
        ].map((item) => {
          return {
            value: item.newsCategoryId,
            name: item.newsCategoryName,
          }
        })}
        handleChange={(value) => changeFilter({ category: value })}
      />
    </div>
  )
}
