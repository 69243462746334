import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import { ScatterDiamondShape } from '../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { SizeTrackerContainer } from '../../../common/sizeTracker/SizeTrackerContainer'
import { emptyInList, joinWithoutEmpty } from '../../../utils/Common'
import {
  selectIndicator,
  selectTimeFilter,
} from '../financialMetric/store/slice'
import { footerData, formatIndicator, getI18nTooltipLabel } from '../helper'
import { selectBasicInfoOverview } from '../store/slice'
import { ChartComponent } from './ChartComponent'
import { TopLabel } from './TopLabel'
import style from './peersComparison.module.css'
import { selectData, selectLoading } from './store/slice'
import { getFinancialMetricsComparisonChartData } from './store/thunk'

const PeersComparison = ({ width, height, downloadId }) => {
  const dispatch = useDispatch()

  const timeFilter = useSelector(selectTimeFilter)
  const indicator = useSelector(selectIndicator)
  const basicInfo = useSelector(selectBasicInfoOverview)

  const formatIndicatorData = formatIndicator(indicator, timeFilter, basicInfo)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getFinancialMetricsComparisonChartData({
          OrganizationId: basicInfo.organizationId,
          TimeFilter: timeFilter.value,
          Indicators: joinWithoutEmpty([
            formatIndicatorData.key,
            formatIndicatorData.subKey,
          ]),
        }),
      )
    }
  }, [
    timeFilter,
    indicator.value,
    indicator.subValue,
    basicInfo.organizationId,
  ])

  const isLoading = useSelector(selectLoading)
  const data = useSelector(selectData)
  const { indicatorName, subIndicatorName } = getI18nTooltipLabel(
    I18n,
    formatIndicatorData,
  )
  return (
    <ScrollComponent>
      <div id={downloadId} className={style['peers-comparison-container']}>
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            {emptyInList(data, [formatIndicatorData.key]) &&
              basicInfo?.organizationId && <NoData />}
            {!emptyInList(data, [formatIndicatorData.key]) && (
              <SizeTrackerContainer>
                <SizeTracker>
                  {(size) => {
                    return (
                      <>
                        <div
                          className={[
                            style['title-header'],
                            'canvas-chart-title',
                          ].join(' ')}
                        >
                          <TopLabel />
                        </div>
                        {size.height && (
                          <ChartComponent
                            data={data}
                            width={width}
                            height={height - size.height}
                            barKey={formatIndicatorData.key}
                            pointKey={formatIndicatorData.subKey}
                            isFormatValToPercent={
                              formatIndicatorData.isFormatValToPercent
                            }
                            keyXAxis={'ticker'}
                            barLabel={indicatorName}
                            pointLabel={subIndicatorName}
                            isFormatValToPercentSub={
                              formatIndicatorData.isFormatValToPercentSub
                            }
                            leftLabel={
                              formatIndicatorData.subKey
                                ? I18n.t(
                                    'corporate.overview.financial.TRILLION',
                                  )
                                : ''
                            }
                          />
                        )}
                        <Footer
                          key={width}
                          list={footerData(
                            data,
                            formatIndicatorData,
                            {
                              type: TYPE.SQUARE,
                              before: {
                                bgColor: '#facc5c',
                              },
                            },
                            {
                              renderIcon: (
                                <ScatterDiamondShape
                                  style={{ marginRight: 5 }}
                                />
                              ),
                            },
                          )}
                        />
                      </>
                    )
                  }}
                </SizeTracker>
              </SizeTrackerContainer>
            )}
          </>
        )}
      </div>
    </ScrollComponent>
  )
}

PeersComparison.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  downloadId: PropTypes.string.isRequired,
}

export default PeersComparison
