import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import CorporateItem from './CorporateItem'
import Header from './Header'
import style from './index.module.css'
import { getCorporateIds, getLoading } from './store/slice'
import { getNews } from './store/thunk'

const CorporateNews = () => {
  const dispatch = useDispatch()

  const loading = useSelector(getLoading)
  const corporateIds = useSelector(getCorporateIds)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(
      getNews({
        NewsType: 'CorporateNew',
      }),
    )
  }, [locale])

  return (
    <div className={style.corporateNewsContainer}>
      <Header />
      <div className={style.wrapCorporateItem}>
        <ScrollComponent>
          {loading && <Loading />}
          {!loading && (
            <>
              {corporateIds.length === 0 && <NoData />}
              {corporateIds.length > 0 && (
                <>
                  {corporateIds.map((id) => (
                    <CorporateItem id={id} key={id} />
                  ))}
                </>
              )}
            </>
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default CorporateNews
