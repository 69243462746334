export const DEFAULT_TIME_FRAME_OPTIONS = [
  {
    title: '1M',
    value: 'OneMonth',
  },
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
]

export const INDIVIDUAL_OPTIONS = [
  {
    title: 'economy.monetary.monetary.INSTITUTIONAL',
    value: 'NotIsIndividual',
  },
  {
    title: 'economy.monetary.monetary.INDIVIDUAL',
    value: 'IsIndividual',
  },
]

export const OPEN_MARKET_TIME_FRAME_OPTIONS = [
  { title: '1M', value: '1M' },
  { title: '3M', value: '3M' },
  { title: '6M', value: '6M' },
  { title: '1Y', value: '1Y' },
  { title: 'MTD', value: 'MTD' },
  { title: 'QTD', value: 'QTD' },
  { title: 'YTD', value: 'YTD' },
]

export const OPEN_MARKET_DAILY_OPTIONS = [
  { title: '1M', value: 'OneMonth' },
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: 'MTD', value: 'MonthToDate' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
]

export const OPEN_MARKET_WEEKLY_OPTIONS = [
  { title: '3M', value: 'ThreeMonths' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
  { title: 'MTD', value: 'MonthToDate' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
]

export const OPEN_MARKET_MONTHLY_OUTSTANDING = [
  { title: '1Y', value: 'OneYear' },
  { title: '3Y', value: 'ThreeYears' },
  { title: '5Y', value: 'FiveYears' },
  { title: '10Y', value: 'TenYears' },
  { title: 'QTD', value: 'QuarterToDate' },
  { title: 'YTD', value: 'YearToDate' },
]

export const VALUE_MOM_YOY_YTD = [
  {
    title: 'economy.monetary.monetary.VALUE',
    value: 'Value',
  },
  {
    title: '% MoM',
    value: 'MoM',
  },
  {
    title: '% YoY',
    value: 'YoY',
  },
  {
    title: '% YTD',
    value: 'YTD',
  },
]

export const DAY_WEEK_MONTH = [
  {
    title: 'economy.monetary.monetary.DAILY',
    value: 'Daily',
  },
  {
    title: 'economy.monetary.monetary.WEEKLY',
    value: 'Weekly',
  },
  {
    title: 'economy.monetary.monetary.MONTHLY',
    value: 'Monthly',
  },
]

export const DAY_WEEK_MONTH_RADIO = [
  {
    label: 'economy.monetary.monetary.DAILY',
    value: 'Daily',
  },
  {
    label: 'economy.monetary.monetary.WEEKLY',
    value: 'Weekly',
  },
  {
    label: 'economy.monetary.monetary.MONTHLY',
    value: 'Monthly',
  },
]

export const REVERSE_REPO_SELL_TABS = [
  {
    title: 'economy.monetary.monetary.REVERSE_REPO',
    value: 'ReverseRepo',
  },
  {
    title: 'economy.monetary.monetary.SELL_OUTRIGHT',
    value: 'SellOutright',
  },
]

export const LINE_CHART_COLORS = [
  '#ecffdf',
  '#9c54e4',
  '#c7ff58',
  '#f57f07',
  '#559668',
  '#ff2500',
  '#744d23',
  '#f9c105',
  '#85d4ff',
  '#56c10a',
  '#009fe6',
  '#9d9e9e',
  '#f4327b',
  '#930037',
  '#57426d',
  '#4bd9be',
  '#c52e14',
  '#00763e',
  '#975000',
  '#1a36a9',
]

export const MONTH_KEYS = {
  1: {
    vi: 'Th01',
    en: 'Jan',
  },
  2: {
    vi: 'Th02',
    en: 'Feb',
  },
  3: {
    vi: 'Th03',
    en: 'Mar',
  },
  4: {
    vi: 'Th04',
    en: 'Apr',
  },
  5: {
    vi: 'Th05',
    en: 'May',
  },
  6: {
    vi: 'Th06',
    en: 'Jun',
  },
  7: {
    vi: 'Th07',
    en: 'Jul',
  },
  8: {
    vi: 'Th08',
    en: 'Aug',
  },
  9: {
    vi: 'Th09',
    en: 'Sep',
  },
  10: {
    vi: 'Th10',
    en: 'Oct',
  },
  11: {
    vi: 'Th11',
    en: 'Nov',
  },
  12: {
    vi: 'Th12',
    en: 'Dec',
  },
}
