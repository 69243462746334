import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import { withWrapper } from '../../common/withWrapper'
import { formatNumber } from '../../utils/Common'
import News from './news/News'
import { PanelFinancialTable } from './PanelFinancialTable'
import { PanelKeyIndicator } from './PanelKeyIndicator'
import { PanelPeersComparison } from './PanelPeersComparison'
import { PanelSummary } from './PanelSummary'
import { PanelToi } from './PanelToi'

const MAP_KEY = {
  SUMMARY: 'SUMMARY',
  KEY_INDICATOR: 'KEY_INDICATOR',
  NEWS: 'NEWS',
  FINANCIAL_TABLE: 'FINANCIAL_TABLE',
  FINANCIAL_FIRST_CHART: 'FINANCIAL_FIRST_CHART',
  FINANCIAL_SECOND_CHART: 'FINANCIAL_SECOND_CHART',
}

const MAP_SIZE = {
  [`${MAP_KEY.SUMMARY}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.KEY_INDICATOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.KEY_INDICATOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.NEWS,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.FINANCIAL_TABLE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.FINANCIAL_FIRST_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.FINANCIAL_SECOND_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.KEY_INDICATOR}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.NEWS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NEWS}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_TABLE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.FINANCIAL_FIRST_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_FIRST_CHART}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.FINANCIAL_SECOND_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_SECOND_CHART}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelSummary
          panelRefs={panelRefs}
          panelKey={MAP_KEY.SUMMARY}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelKeyIndicator
          panelRefs={panelRefs}
          panelKey={MAP_KEY.KEY_INDICATOR}
          sizes={sizes}
          setSizes={setSizes}
        />

        <Panel
          title={'corporate.overview.news.TITLE_PANEL'}
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NEWS}
          sizes={sizes}
          setSizes={setSizes}
          windowZoom
        >
          <News width={formatNumber(sizes[MAP_KEY.NEWS].width)} />
        </Panel>

        <PanelFinancialTable
          panelRefs={panelRefs}
          panelKey={MAP_KEY.FINANCIAL_TABLE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelToi
          panelRefs={panelRefs}
          panelKey={MAP_KEY.FINANCIAL_FIRST_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelPeersComparison
          panelRefs={panelRefs}
          panelKey={MAP_KEY.FINANCIAL_SECOND_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }

  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
