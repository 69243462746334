import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import { INDICATOR_FIX_UNIT_INT, UNIT_VALUE_CONSTANTS } from '../constant'
import { selectFilter, selectItemData, selectValueById } from '../store/slice'

const MARGIN_LEFT = 40

export const FirstColumnCell = ({
  val,
  rowId,
  style,
  className,
  onRowCollapse,
}) => {
  const tdRef = useRef()
  const item = useSelector(selectItemData(rowId))
  const itemFixUnit = useSelector(selectValueById(rowId, 'fixUnit'))
  const { unit } = useSelector(selectFilter)

  const [isCollapse, setIsCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const formatValue = () => {
    if (
      INDICATOR_FIX_UNIT_INT.includes(item.key?.toUpperCase()) &&
      itemFixUnit &&
      [
        UNIT_VALUE_CONSTANTS.USD,
        UNIT_VALUE_CONSTANTS.THOUSAND_USD,
        UNIT_VALUE_CONSTANTS.MILLION_USD,
      ].includes(unit)
    ) {
      return val.replace('(VND)', '(USD)')
    }
    return val
  }

  return (
    <td ref={tdRef} className={className} style={{ ...style, minWidth: 200 }}>
      <div
        className="align-center"
        style={{
          marginLeft: (item.level - 1) * MARGIN_LEFT,
        }}
      >
        {item.isCollapse ? (
          <button
            style={{ paddingLeft: 0 }}
            className="align-center cursor-pointer"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <div style={{ width: 14 }} />
        )}
        <Span
          style={{
            lineHeight: 1.5,
            color: item.level === 1 ? '#fecf2f' : '',
          }}
        >
          {formatValue()}
        </Span>
      </div>
    </td>
  )
}

FirstColumnCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  onRowCollapse: PropTypes.func,
}
