import { useLayoutEffect, useRef, useState } from 'react'
import { FinancialChart } from '../../../../common/chart/financialChart'
import { NoData } from '../../../../common/noData'
import { Footer } from '../../../common/footer'
import { genFooterData } from '../../../common/footer/helper'
import YAxisSettingStyle from '../../common/axisSettingStyle/YAxisSettingStyle'
import { keySettingChart } from '../../constants'
import IconEditNameChart from '../IconEditNameChart'

export const ChartContentDetail = ({
  data,
  width,
  height,
  schema,
  styleSetting,
  footer,
  listLegendCustom,
  isShowFooter,
  isPinTooltip,
  onClickTooltip,
  onClickYAxis,
  onUpdateListLegendCustom,
  isEdit,
  typeLegendYAxis,
}) => {
  const headerRef = useRef()
  const footerRef = useRef()

  const [heightChart, setHeightChart] = useState(0)

  // Render
  const renderYAxisStyle = (props) => {
    return <YAxisSettingStyle handleClick={onClickYAxis} {...props} />
  }

  useLayoutEffect(() => {
    setHeightChart(
      (headerRef.current?.offsetHeight || 0) +
        (footerRef.current?.offsetHeight || 0),
    )
  }, [headerRef.current?.offsetHeight, footerRef.current?.offsetHeight])

  return (
    <>
      {data.length ? (
        <>
          <IconEditNameChart ref={headerRef} width={width} isEdit={isEdit} />
          <div
            style={{
              height: height - heightChart,
            }}
          >
            <FinancialChart
              width={width}
              height={height - heightChart}
              data={data}
              schema={schema}
              isPinTooltip={isPinTooltip}
              onClick={onClickTooltip}
              styleSetting={styleSetting}
              yAxisSettingStyle={renderYAxisStyle}
              marginChart={{ bottom: 10, right: 26, left: 26 }}
              typeLegendYAxis={typeLegendYAxis}
            />
          </div>
          {isShowFooter ? (
            <div ref={footerRef}>
              <Footer
                list={genFooterData(footer, listLegendCustom)}
                updateListLegendCustom={onUpdateListLegendCustom}
                isEditLegend
                styleSetting={styleSetting[keySettingChart.X_AXIS_LEGEND] || {}}
              />
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <NoData />
      )}
    </>
  )
}
