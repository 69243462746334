import { useSelector } from 'react-redux'
import style from '../index.module.css'
import { selectIndicatorById } from '../store/slice'

const IndicatorName = ({ indicatorId }) => {
  const indicatorById = useSelector(selectIndicatorById)

  return (
    <div className={style.indicatorName}>
      {indicatorById[indicatorId]?.indicatorName}
    </div>
  )
}

export default IndicatorName
