import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import { getStockIndicesSectorInfo } from '../../../utils/Search'
import style from './index.module.css'

export const SearchItemDropdown = (props) => {
  const { data, options, onToggle, getBasicInfo } = props
  const parentDropdownRef = useRef()
  const navigate = useCustomNavigate()

  const [isShow, setIsShow] = useState(false)

  const onClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setIsShow(!isShow)
    onToggle(!isShow)
  }

  const clickOutside = () => {
    setIsShow(false)
    onToggle(false)
  }

  const onRedirect = (e, item) => {
    e.stopPropagation()
    if (!item.disabled) {
      navigate(item.url, getStockIndicesSectorInfo(data, getBasicInfo))
    }
  }

  return (
    options.length > 0 && (
      <>
        <Button
          style={{ fontSize: 16 }}
          ref={parentDropdownRef}
          onClick={onClick}
          className={style.iconSearch}
        >
          <i className="icon-menu-dot-hor" />
        </Button>
        <Dropdown
          parentRef={parentDropdownRef}
          isShow={isShow}
          position={positionDropdown.RIGHT_TOP}
        >
          <HandleClickOutside
            exceptRef={parentDropdownRef}
            handleClickOutside={clickOutside}
          >
            <div className="hover-dropdown top-filter-search">
              <div
                className={`dropdown-container ${style['dropdown-container']}`}
              >
                <ul className={`list-check mt-10 ${style['list-check']}`}>
                  <Span style={{ fontSize: 11 }}>
                    {options.length > 0 &&
                      options.map((item) => (
                        <li key={JSON.stringify(data) + item.label}>
                          <a onClick={(e) => onRedirect(e, item)}>
                            <label
                              style={{ opacity: item.disabled ? 0.5 : 1 }}
                              className="cus--chec"
                            >
                              <Translate value={item.label} />
                            </label>
                          </a>
                        </li>
                      ))}
                  </Span>
                </ul>
              </div>
            </div>
          </HandleClickOutside>
        </Dropdown>
      </>
    )
  )
}

SearchItemDropdown.propTypes = {
  data: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
}
