import { useEffect } from 'react'
import { getFontSize } from '../../../../utils/FontSize'
import Category from './Category'
import DateRange from './DateRange'
import style from './index.module.css'

const Filter = () => {
  const fontSizeSelect = getFontSize(12) + 'px'
  useEffect(() => {
    const selects = document.querySelectorAll(`select`)
    if (selects) {
      selects.forEach((select) => (select.style.fontSize = fontSizeSelect))
    }
  }, [fontSizeSelect])

  return (
    <div className={style.root}>
      <Category />
      <DateRange />
    </div>
  )
}

export default Filter
