import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import InputFormatCurrency from '../../../../common/input/InputFormatCurrency'
import { selectFilterParams } from '../../store/slice'
import { RANGE_TYPES } from '../constants'
import style from '../index.module.css'

export const FilterTypeNumber = ({ data, handleChangeInputRange }) => {
  const params = useSelector(selectFilterParams)

  const initialParams = params.conditions.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  return (
    <div className="w-100" key={data.indicatorId}>
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          {`${data?.indicatorName ?? ''} ${data.unit ?? ''}`}
        </Span>
      </label>
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 7.2 }}
      >
        <div style={{ flex: 1 }}>
          <InputFormatCurrency
            className={`${style.input}`}
            handleBlur={(value) => {
              handleChangeInputRange(
                value,
                RANGE_TYPES.FROM,
                initialParams?.indicatorId,
                data.indicatorId,
              )
            }}
            defaultValue={+initialParams?.conditionValues?.[0] ?? null}
            fractionDigits={0}
          />
        </div>
        <span className={style.lineInput}></span>
        <div style={{ flex: 1 }}>
          <InputFormatCurrency
            className={`${style.input}`}
            handleBlur={(value) => {
              handleChangeInputRange(
                value,
                RANGE_TYPES.TO,
                initialParams?.indicatorId,
                data.indicatorId,
              )
            }}
            defaultValue={+initialParams?.conditionValues?.[1] ?? null}
            fractionDigits={0}
          />
        </div>
      </div>
    </div>
  )
}
