import GroupButton from './GroupButton'
import SelectDate from './SelectDate'

const SettingArea = () => {
  return (
    <div>
      <SelectDate />
      <GroupButton />
    </div>
  )
}

export default SettingArea
