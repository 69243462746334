import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MasterData from '../../../../../../core/services/bond/overview/MasterData'

export const getICBs = createAsyncThunk(
  'bond/overview/GetICBs',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterData.getICBsApi(params)

      if (response.success) {
        const data = response.data.filter((e) => e.icbLevel === 2)
        return data
      }

      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
