import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { DetailContainer } from '../../../../common/news/DetailContainer'
import { Separator } from '../../../../common/separator'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import EventEmitter, { CHANGE_LOCALE } from '../../../../utils/EventEmitter'
import { LeftContent } from './left'
import { RightContent } from './right'

export const Content = ({ width }) => {
  const navigate = useCustomNavigate()
  const basicInfo = useSelector(selectBasicInfo)

  useEffect(() => {
    const redirect = () => {
      navigate(
        FEATURES.corporate.components['news-events'].components.news.route,
        basicInfo,
      )
    }
    EventEmitter.subscribe(CHANGE_LOCALE, redirect)
    return () => EventEmitter.unsubscribe(CHANGE_LOCALE, redirect)
  }, [])

  return (
    <DetailContainer width={width}>
      <LeftContent />
      <Separator />
      <RightContent />
    </DetailContainer>
  )
}
