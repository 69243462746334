import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../utils/EventEmitter'
import UseI18n from '../hooks/useI18n'
import { Button } from '../html/Button'
import { Span } from '../html/Span'
import TextEllipsis from '../textEllipsis'
import PopupSortColumn from './PopupSortColumn'
import style from './sheet.module.css'

export const maxSheet = 10

const widthItem = 100

const widthText = 70

const totalWidthWithoutItem = 375

const Sheet = ({
  width,
  handleNewSheet,
  handleRemoveSheet,
  selectColumnId,
  selectColumnById,
  selectIndicatorById,
  idKey,
  swapColumns,
  selectSheet,
  setSheet,
  selectActiveTab,
  setActiveTab,
  renderPopupSortColumn,
}) => {
  const sheet = useSelector(selectSheet)
  const activeTab = useSelector(selectActiveTab)

  const sheetText = UseI18n('tool.dataExplorer.SHEET')

  const [idHover, setIdHover] = useState(null)
  const [isShowPopup, setIsShowPopup] = useState(false)

  const handleClickItem = (value) => {
    setActiveTab(value)
  }

  const handleNextSheet = () => {
    if (!isDisableNext) {
      setActiveTab(
        sheet[sheet.findIndex((item) => item.value === activeTab) + 1].value,
      )
    }
  }

  const handleBackSheet = () => {
    if (!isDisableBack) {
      setActiveTab(
        sheet[sheet.findIndex((item) => item.value === activeTab) - 1].value,
      )
    }
  }

  const handleMore = () => {
    setActiveTab(sheet[lastIndex + 1].value)
  }

  const handleBackMore = () => {
    setActiveTab(sheet[firstIndex - 1].value)
  }

  const handleAddSheet = () => {
    if (sheet.length >= maxSheet) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('tool.dataExplorer.corporate.MAX_SHEET_1'),
        I18n.t('tool.dataExplorer.corporate.MAX_SHEET_2'),
      ])
    } else {
      const newSheet = [...sheet]
      const value = sheet[sheet.length - 1]
        ? sheet[sheet.length - 1].value + 1
        : 1
      newSheet.push({ title: 'SHEET ' + value, value })
      setSheet(newSheet)
      setActiveTab(value)
      handleNewSheet({ title: 'SHEET ' + value, value })
    }
  }

  const handleDeleteSheet = (e, id) => {
    e.stopPropagation()
    if (sheet.length > 1) {
      setIdHover(sheet[sheet.findIndex((item) => item.value === id) + 1]?.value)
      const newSheet = [...sheet]
      newSheet.splice(
        newSheet.findIndex((item) => item.value === id),
        1,
      )
      setSheet(newSheet)
      if (id === activeTab) {
        setActiveTab(newSheet[0]?.value || '')
      }
      handleRemoveSheet(sheet.find((item) => item.value === id))
    }
  }

  const mouseOver = (id) => setIdHover(id)
  const mouseOut = () => setIdHover(null)

  const handleShowPopup = () => setIsShowPopup(true)
  const handleClosePopup = () => setIsShowPopup(false)

  const handleSubmitSort = (newColumnId) => {
    swapColumns(newColumnId)
    handleClosePopup()
  }

  const isDisableNext =
    activeTab === sheet[sheet.length - 1]?.value || sheet.length === 0
  const isDisableBack = activeTab === sheet[0]?.value || sheet.length === 0
  const maxItem = Math.floor((width - totalWidthWithoutItem) / widthItem)
  const indexActive = sheet.findIndex((item) => item.value === activeTab)
  const firstIndex =
    sheet.length <= maxItem || indexActive - Math.floor(maxItem / 2) < 0
      ? 0
      : indexActive - Math.floor(maxItem / 2) + maxItem > sheet.length
      ? sheet.length - maxItem
      : indexActive - Math.floor(maxItem / 2)
  const lastIndex =
    firstIndex + maxItem - 1 > sheet.length - 1
      ? sheet.length - 1
      : firstIndex + maxItem - 1

  return (
    <div style={{ maxWidth: `${width}px` }} className={style.sheetContainer}>
      <nav className="tab-in-panel">
        {firstIndex !== 0 && (
          <a onClick={handleBackMore} className={style.btnMore}>
            <span>...</span>
          </a>
        )}
        {sheet.map(({ title, value }, index) => (
          <Fragment key={index}>
            {index >= firstIndex && index <= lastIndex && (
              <a
                className={[
                  style.item,
                  activeTab === value ? style.btnActive : '',
                ].join(' ')}
                onClick={() => handleClickItem(value)}
                onMouseEnter={() => mouseOver(value)}
                onMouseLeave={mouseOut}
                style={{ width: `${widthItem}px` }}
              >
                <Span style={{ fontSize: 11, maxWidth: widthText + 'px' }}>
                  <TextEllipsis
                    isI18n={false}
                    text={title?.replace('SHEET', sheetText)}
                  />
                </Span>
                {idHover === value && (
                  <i
                    onClick={(e) => handleDeleteSheet(e, value)}
                    className="icon-delete-stroke"
                  />
                )}
              </a>
            )}
          </Fragment>
        ))}
        {lastIndex !== sheet.length - 1 && (
          <a onClick={handleMore} className={style.btnMore}>
            <span>...</span>
          </a>
        )}
        <a onClick={handleAddSheet} className={style.btnPlus}>
          <span>+</span>
        </a>
        <a className={style.btnNextBack}>
          <i
            onClick={handleBackSheet}
            className={`icon-caret-left ${isDisableBack ? style.disabled : ''}`}
          />
          <i
            onClick={handleNextSheet}
            className={`icon-caret-right ${
              isDisableNext ? style.disabled : ''
            }`}
          />
        </a>
      </nav>
      {selectColumnId && (
        <>
          <Button
            onClick={handleShowPopup}
            className={`btn bg-grey h-20 ${style.btnGrey}`}
          >
            <Translate value="tool.dataExplorer.SORT_COLUMN" />
          </Button>
          {isShowPopup && (
            <>
              {renderPopupSortColumn ? (
                renderPopupSortColumn({
                  handleClosePopup,
                  handleSubmit: handleSubmitSort,
                  selectColumnId,
                  selectColumnById,
                  selectIndicatorById,
                  idKey,
                })
              ) : (
                <PopupSortColumn
                  selectColumnId={selectColumnId}
                  selectColumnById={selectColumnById}
                  selectIndicatorById={selectIndicatorById}
                  idKey={idKey}
                  handleClosePopup={handleClosePopup}
                  handleSubmit={handleSubmitSort}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

Sheet.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  handleNewSheet: PropTypes.func.isRequired,
  handleRemoveSheet: PropTypes.func.isRequired,
  selectColumnId: PropTypes.func,
  selectColumnById: PropTypes.func,
  selectIndicatorById: PropTypes.func,
  swapColumns: PropTypes.func,
  selectSheet: PropTypes.func.isRequired,
  setSheet: PropTypes.func.isRequired,
  selectActiveTab: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  renderPopupSortColumn: PropTypes.func,
}

Sheet.defaultProps = {}

export default Sheet
