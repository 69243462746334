import { TYPE } from '../../../common/chart/footer/Item'

export const tableChartSwitch = {
  TABLE: 'table',
  HORIZONTAL_STACK_BAR: 'horizontalStackBar',
}

export const timeSalesDateFilter = {
  ONE_DAY: 'OneDay',
  FIVE_DAY: 'OneWeek',
  NINE_DAY: 'TwoWeeks',
  TWENTY_DAY: 'OneMonth',
}

export const indicator = {
  TIME: 'tradingDate',
  TYPE: 'matchType',
  LAST_PRICE: 'matchPrice',
  CHANGE: 'priceChange',
  PERCENT_CHANGE: 'percentPriceChange',
  MATCH_VOL: 'matchVolume',
  TOTAL_MATCH_VOL: 'totalMatchVolume',
}

export const listIconBarChart = [
  {
    text: 'market.derivative.BU_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#3da967',
    },
  },
  {
    text: 'market.derivative.SD_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#e0505b',
    },
  },
  {
    text: 'market.derivative.UNIDENTIFIED',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#32a6e8',
    },
  },
]
