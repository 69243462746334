import PropTypes from 'prop-types'
import { Fragment, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import {
  CartesianGrid,
  ComposedChart,
  Label,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import UseFontSize from '../../hooks/useFontSize'
import UseTimeZone from '../../hooks/useTimeZone'
import { SizeTracker } from '../../sizeTracker'
import {
  HEIGHT_XAXIS,
  MARGIN_RECHARTS,
  MIN_HEIGHT_XAXIS,
  TICK_MARGIN_XAXIS,
} from '../constants'
import { XAxisWithDiv } from '../xAxisWithDiv'
import { CustomTooltip } from './CustomTooltip'
import { getXAxisTicks, getYAxisProps } from './helper'

export const ChartContainer = ({
  chartType,
  data,
  width,
  height,
  xAxisType,
  xAxisUnit,
  xMinMax,
  keyXAxis,
  yAxis,
  children,
  tooltipSchema,
  renderCustomTooltip,
  xTickNum,
  xAxisLabel,
  xAxisPosition,
  timeFrame,
  tickFormatter,
  margin = MARGIN_RECHARTS,
  isUseXAxisDiv,
  isShowXAxis,
  heightXAxis,
  setLeftYWidth,
  setRightYWidth,
  isHasCartesianGrid,
  interval,
  allowEscapeViewBoxY,
  isBreakNewLine,
  allowDataOverflow,
  showAllTick,
  syncId,
  hideToolTipChart,
  layout,
  isScatterChart = false,
  xAxisLabelCustom,
  isShowOriginalXAxis,
  isHorizontalStackChart,
  styleTickXAxis,
  styleTickYAxis,
  isNotFormatXAxis,
  showAllTickInTwoLines,
  reversed,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const typeFontSize = UseFontSize()
  const timeZone = UseTimeZone()

  const xAxisNumberDecimalLength = useRef(0)

  const yAxisProps = useMemo(() => {
    return getYAxisProps({
      data,
      height,
      yAxis,
      heightXAxis,
      typeFontSize,
      margin,
      layout,
      styleTickYAxis,
    })
  }, [data, height, JSON.stringify(yAxis), heightXAxis, typeFontSize, margin])

  const totalLeftWidth = useMemo(() => {
    return (
      (margin.left || 0) +
      yAxisProps
        .filter((y) => !y.orientation || y.orientation === 'left')
        .reduce((total, curr) => total + curr.yAxisWidth, 0)
    )
  }, [yAxisProps, margin?.left])

  const totalRightWidth = useMemo(() => {
    return (
      (margin.right || 0) +
      yAxisProps
        .filter((y) => y.orientation === 'right')
        .reduce((total, curr) => total + curr.yAxisWidth, 0)
    )
  }, [yAxisProps, margin?.right])

  const decimalLengths = useMemo(() => {
    return yAxisProps.reduce(
      (obj, item) => Object.assign(obj, { [item.id]: item.decimalLength }),
      {},
    )
  }, [yAxisProps])

  useEffect(() => {
    if (setLeftYWidth) setLeftYWidth(totalLeftWidth)
  }, [totalLeftWidth])

  useEffect(() => {
    if (setRightYWidth) setRightYWidth(totalRightWidth)
  }, [totalRightWidth])

  const xAxisFontSize = getFontSize(12)
  const renderXAxis = () => {
    const { tickValues, defaultFormatter, decimalLength } = getXAxisTicks({
      xAxisType,
      data,
      xMinMax,
      timeFrame,
      xTickNum,
      keyXAxis,
      width: width - totalLeftWidth - totalRightWidth,
      fontSize: styleTickXAxis ? styleTickXAxis.fontSize : xAxisFontSize,
      locale,
      timeZone,
      isScatterChart,
      isHorizontalStackChart,
      isNotFormatXAxis,
    })

    xAxisNumberDecimalLength.current = decimalLength

    const domain = tickValues
      ? [tickValues[0], tickValues[tickValues.length - 1]]
      : undefined

    return (
      <XAxis
        axisLine={layout === 'vertical' ? true : false}
        type={xAxisType}
        tickSize={0}
        tickMargin={TICK_MARGIN_XAXIS}
        tick={
          isShowXAxis && !isUseXAxisDiv
            ? styleTickXAxis
              ? styleTickXAxis
              : { fill: '#ececec' }
            : false
        }
        dataKey={keyXAxis}
        domain={domain}
        ticks={tickValues}
        tickFormatter={tickFormatter || defaultFormatter}
        height={isShowXAxis && !isUseXAxisDiv ? heightXAxis : MIN_HEIGHT_XAXIS}
        interval={interval}
        allowDataOverflow={allowDataOverflow}
        reversed={reversed}
        unit={xAxisUnit}
      >
        {xAxisLabel && (
          <Label
            value={xAxisLabel}
            position={xAxisPosition}
            fontSize={getFontSize(11, typeFontSize)}
            fill="rgba(255, 255, 255)"
            opacity={0.4}
            offset={12}
          />
        )}
        {xAxisLabelCustom && xAxisLabelCustom()}
      </XAxis>
    )
  }
  const renderYAxis = useMemo(() => {
    if (!yAxisProps || yAxisProps.length === 0) return <></>
    return yAxisProps.map(
      (
        {
          id,
          keys,
          yAxisWidth,
          orientation,
          tickValues,
          decimalLength,
          label,
          labelProps,
          hasReferenceLabel,
          latestData,
          referenceLabelHeight,
          referenceLabelWidth,
          referenceLabelY,
          referenceLabelOffsetX,
          renderReferenceLabel,
          tickFormatter,
          customDomain,
          type,
          lineKeys,
          lineKey,
          tick,
          interval,
          unitYAxis,
          hide,
        },
        yAxisIndex,
      ) => (
        <Fragment key={id}>
          <YAxis
            yAxisId={id}
            width={yAxisWidth}
            dataKey={keys.length === 1 ? keys[0] : undefined}
            orientation={orientation}
            axisLine={false}
            tick={
              tick
                ? tick
                : styleTickYAxis
                ? styleTickYAxis
                : { fill: '#ececec' }
            }
            tickMargin={10}
            tickCount={8}
            ticks={tickValues}
            unit={unitYAxis}
            domain={
              customDomain
                ? customDomain([
                    tickValues[0],
                    tickValues[tickValues.length - 1],
                  ])
                : [tickValues[0], tickValues[tickValues.length - 1]]
            }
            tickFormatter={
              tickFormatter || ((value) => formatVal(value, decimalLength))
            }
            tickSize={0}
            allowDataOverflow={allowDataOverflow}
            type={type}
            interval={interval}
            hide={hide}
          >
            {label && <Label value={label} {...labelProps} />}
          </YAxis>
          {isShowOriginalXAxis &&
            yAxisIndex === 0 &&
            !isHorizontalStackChart && (
              <ReferenceLine
                yAxisId={id}
                y={
                  tickValues.includes(0)
                    ? 0
                    : tickValues[0] > 0
                    ? tickValues[0]
                    : tickValues[tickValues.length - 1]
                }
                stroke="#767676"
                strokeWidth={1}
              />
            )}
          {isHorizontalStackChart && (
            <ReferenceLine
              yAxisId={id}
              x={0}
              stroke="#767676"
              strokeWidth={1}
            />
          )}
          {hasReferenceLabel &&
            keys.map((key) => {
              const item = lineKeys?.find((item) => item[lineKey] === key)
              return (
                <ReferenceLine
                  key={key}
                  yAxisId={id}
                  y={referenceLabelY[key]}
                  stroke="none"
                  label={(ctx) => {
                    return (
                      <g color={item?.textColor || '#1e242e'}>
                        <foreignObject
                          x={
                            ctx.viewBox.width +
                            ctx.viewBox.x +
                            referenceLabelOffsetX
                          }
                          y={ctx.viewBox.y - referenceLabelHeight / 2}
                          width={referenceLabelWidth}
                          height={referenceLabelHeight}
                        >
                          {renderReferenceLabel({
                            data: latestData,
                            key,
                            item: item,
                          })}
                        </foreignObject>
                      </g>
                    )
                  }}
                />
              )
            })}
        </Fragment>
      ),
    )
  }, [yAxisProps, data])

  const renderChartContainer = (sizeHeight) => {
    const ChartWrapper = chartType || ComposedChart

    const exposeData = {
      chartContentWidth: width - totalLeftWidth - totalRightWidth,
      totalLeftWidth,
    }

    return (
      <ResponsiveContainer width="100%" height={height - sizeHeight || 0}>
        <ChartWrapper
          width={width}
          height={height - sizeHeight || 0}
          data={data}
          margin={margin}
          barGap={0}
          stackOffset="sign"
          syncId={syncId}
          layout={layout}
        >
          {isHasCartesianGrid && (
            <CartesianGrid
              stroke="#555555"
              strokeWidth={1}
              horizontal={layout === 'horizontal'}
              vertical={layout === 'vertical'}
              opacity={layout === 'horizontal' ? 0.5 : 1}
              strokeDasharray="5 3"
            />
          )}
          {renderXAxis()}
          {renderYAxis}
          {typeof children === 'function' ? children(exposeData) : children}
          {!hideToolTipChart && (
            <Tooltip
              wrapperStyle={{ zIndex: 10 }}
              cursor={{
                stroke: '#555555',
                strokeWidth: 1,
                strokeDasharray: '3 3',
              }}
              allowEscapeViewBox={{ y: allowEscapeViewBoxY }}
              isAnimationActive={false}
              content={
                <CustomTooltip
                  keyXAxis={keyXAxis}
                  xAxisDecimalLength={xAxisNumberDecimalLength.current}
                  decimalLengths={decimalLengths}
                  tooltipSchema={tooltipSchema}
                  renderCustomTooltip={renderCustomTooltip}
                />
              }
            />
          )}
        </ChartWrapper>
      </ResponsiveContainer>
    )
  }

  return (
    <div style={{ width: width || 0, height: height || 0, margin: 'auto' }}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              {renderChartContainer(size.height)}
              {isShowXAxis && isUseXAxisDiv && (
                <XAxisWithDiv
                  data={data}
                  keyXAxis={keyXAxis}
                  width={width - totalLeftWidth - totalRightWidth}
                  appendStyle={{
                    marginLeft: totalLeftWidth,
                    marginRight: totalRightWidth,
                  }}
                  isBreakNewLine={isBreakNewLine}
                  showAllTick={showAllTick}
                  showAllTickInTwoLines={showAllTickInTwoLines}
                />
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

ChartContainer.propTypes = {
  chartType: PropTypes.func, // Rechart types: ComposedChart, ScatterChart,...
  xAxisType: PropTypes.string,
  xAxisUnit: PropTypes.string,
  xTickNum: PropTypes.number,
  isUseXAxisDiv: PropTypes.bool,
  isShowXAxis: PropTypes.bool,
  tickFormatter: PropTypes.func,
  heightXAxis: PropTypes.number,
  yAxis: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      keys: PropTypes.array,
      isWithZero: PropTypes.bool,
      isGroupBar: PropTypes.bool,
      isStackedBar: PropTypes.bool,
      isLineChart: PropTypes.bool,
      isBarChart: PropTypes.bool,
      orientation: PropTypes.string,
      tickNum: PropTypes.number,
      label: PropTypes.string,
      labelPosition: PropTypes.string,
      hasReferenceLabel: PropTypes.bool,
      referenceLabelWidth: PropTypes.number,
      referenceLabelHeight: PropTypes.number,
      renderReferenceLabel: PropTypes.func,
      showLabelExactly: PropTypes.bool,
      tickFormatter: PropTypes.func,
      tickValues: PropTypes.array,
      yAxisWidth: PropTypes.number,
      customDomain: PropTypes.func,
      tick: PropTypes.any,
      interval: PropTypes.number,
      unitYAxis: PropTypes.string,
      hide: PropTypes.bool,
    }),
  ).isRequired,
  setLeftYWidth: PropTypes.func,
  setRightYWidth: PropTypes.func,
  isHasCartesianGrid: PropTypes.bool,
  interval: PropTypes.any,
  allowEscapeViewBoxY: PropTypes.bool,
  isBreakNewLine: PropTypes.bool,
  tooltipSchema: PropTypes.shape({
    title: PropTypes.shape({
      formatDate: PropTypes.object,
      formatValue: PropTypes.func,
    }),
    content: PropTypes.arrayOf(
      PropTypes.shape({
        keys: PropTypes.arrayOf(PropTypes.string).isRequired,
        yAxisId: PropTypes.string.isRequired,
        unit: PropTypes.string,
        decimalLength: PropTypes.number,
        formatValue: PropTypes.func,
        formatValueColor: PropTypes.func,
      }),
    ).isRequired,
    mappingDisplayName: PropTypes.object.isRequired,
    formatMappingDisplayName: PropTypes.func,
  }),
  renderCustomTooltip: PropTypes.func,
  allowDataOverflow: PropTypes.bool,
  showAllTick: PropTypes.bool,
  isHorizontalStackChart: PropTypes.bool,
  hideToolTipChart: PropTypes.bool,
  layout: PropTypes.string,
  isShowOriginalXAxis: PropTypes.bool,
  styleTickXAxis: PropTypes.object,
  styleTickYAxis: PropTypes.object,
  showAllTickInTwoLines: PropTypes.bool,
  reversed: PropTypes.bool,
}

ChartContainer.defaultProps = {
  xAxisType: 'category',
  xAxisPosition: 'bottom',
  xTickNum: 5,
  isUseXAxisDiv: false,
  isShowXAxis: true,
  heightXAxis: HEIGHT_XAXIS,
  interval: 'preserveStartEnd',
  isHasCartesianGrid: true,
  allowEscapeViewBoxY: false,
  isBreakNewLine: false,
  allowDataOverflow: false,
  showAllTick: false,
  hideToolTipChart: false,
  layout: 'horizontal',
  isShowOriginalXAxis: true,
  isHorizontalStackChart: false,
  reversed: false,
}
