import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FundPortfolioService from '../../../../../core/services/fund/FundPortfolioService'
import BasicChart from '../../../../common/chart/basicChart'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { Filter } from './Filter'
import { TABS } from './constants'
import { formatAssetDataApi, getAssetProps } from './helpers/assetHelpers'
import { formatSectorDataApi, getSectorProps } from './helpers/sectorHelpers'
import { formatStockDataApi, getStockProps } from './helpers/stockHelpers'
import {
  changeFilter,
  changeIsDisableCallApi,
  selectFilters,
  selectIsDisableCallApi,
} from './store/slice'

export const AssetAllocation = ({ id, width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const isDisableCallApi = useSelector(selectIsDisableCallApi)
  const fundInfo = useSelector(selectFundInfo)

  const [data, setData] = useState([])

  // Get data
  const getParams = useMemo(() => {
    return {
      OrganizationId: fundInfo.organizationId,
      Month: filters.month,
      Year: filters.year,
    }
  }, [filters.month, filters.year, fundInfo.organizationId])

  const getProps = useMemo(() => {
    switch (filters.type) {
      case TABS.SECTORS:
        return getSectorProps()
      case TABS.STOCKS:
        return getStockProps()
      case TABS.ASSETS:
      default:
        return getAssetProps({ data })
    }
  }, [filters.type, locale, JSON.stringify(data)])

  const getApiProps = useMemo(() => {
    switch (filters.type) {
      case TABS.SECTORS:
        return {
          serviceApi: (params) =>
            FundPortfolioService.getSectorAllocation(params),
          formatDataApi: formatSectorDataApi,
        }
      case TABS.STOCKS:
        return {
          serviceApi: (params) =>
            FundPortfolioService.getStockAllocation(params),
          formatDataApi: formatStockDataApi,
        }
      case TABS.ASSETS:
      default:
        return {
          serviceApi: (params) =>
            FundPortfolioService.getAssetAllocation(params),
          formatDataApi: formatAssetDataApi,
        }
    }
  }, [filters.type, locale])

  useEffect(() => {
    if (!filters.month && !filters.year && data.length) {
      dispatch(changeIsDisableCallApi(true))
      dispatch(
        changeFilter({ month: data[0].reportMonth, year: data[0].reportYear }),
      )
    }
  }, [JSON.stringify(data)])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <Fragment key={filters.type}>
                <BasicChart
                  id={id}
                  width={width}
                  height={height - size.height}
                  params={getParams}
                  isDisableCallApi={isDisableCallApi}
                  onGetDataApi={setData}
                  {...getApiProps}
                  {...getProps}
                />
              </Fragment>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
