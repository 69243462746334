import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { COMPOSITION_SECTOR_VALUE } from '../../../constants'
import {
  handlePieChartData,
  handleStackedData,
  selectTop5Indicator,
} from '../helper'
import {
  changesInGDPCompositionData,
  getGDPCompositionBySectorData,
  getGDPTypeName,
  getMaxPeriod,
} from './thunk'

const { VALUE } = COMPOSITION_SECTOR_VALUE
const DEFAULT_QUARTER = '2'

const initialState = {
  pieChartLoading: true,
  pieChartData: [],
  top5Indicator: [],
  stackedBarChartLoading: true,
  stackedBarChartData: [],
  year: new Date().getFullYear(),
  timeRangeValue: DEFAULT_QUARTER,
  timeRangeAccValue: DEFAULT_QUARTER,
  radioValue: VALUE,
  dataTypeName: [],
  dataTypeNameLoading: true,
  limitTimeLoading: true,
}

const slice = createSlice({
  name: 'economy/gdp/totalGDP/panelGDPCompositionBySector',
  initialState,
  reducers: {
    handleYear: (state, action) => {
      state.year = action.payload
    },
    handleTimeRangeValue: (state, action) => {
      state.timeRangeValue = action.payload
    },
    handleTimeRangeAccValue: (state, action) => {
      state.timeRangeAccValue = action.payload
    },
    handleRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPCompositionBySectorData.pending, (state) => {
      state.pieChartLoading = true
    })
    builder.addCase(
      getGDPCompositionBySectorData.fulfilled,
      (state, action) => {
        state.pieChartLoading = false
        state.pieChartData = handlePieChartData(action.payload || [])
        state.top5Indicator = selectTop5Indicator(action.payload || [])
      },
    )
    builder.addCase(getGDPCompositionBySectorData.rejected, (state, action) => {
      state.pieChartLoading = action.payload.isLoading
    })
    builder.addCase(changesInGDPCompositionData.pending, (state) => {
      state.stackedBarChartLoading = true
    })
    builder.addCase(changesInGDPCompositionData.fulfilled, (state, action) => {
      state.stackedBarChartLoading = false
      state.stackedBarChartData = handleStackedData(
        action.payload || [],
        action.meta.arg,
      )
    })
    builder.addCase(changesInGDPCompositionData.rejected, (state, action) => {
      state.stackedBarChartLoading = action.payload.isLoading
    })
    builder.addCase(getGDPTypeName.pending, (state) => {
      state.dataTypeNameLoading = true
    })
    builder.addCase(getGDPTypeName.fulfilled, (state, action) => {
      state.dataTypeNameLoading = false
      state.dataTypeName = action.payload || []
    })
    builder.addCase(getGDPTypeName.rejected, (state, action) => {
      state.dataTypeNameLoading = action.payload.isLoading
    })
    builder.addCase(getMaxPeriod.pending, (state) => {
      state.limitTimeLoading = true
    })
    builder.addCase(getMaxPeriod.fulfilled, (state, action) => {
      state.limitTimeLoading = false
      state.year = action.payload?.realYear || new Date().getFullYear()
      const realQuarter = action.payload?.realQuarter
      state.timeRangeValue = realQuarter ? realQuarter + '' : DEFAULT_QUARTER
      state.timeRangeAccValue = realQuarter ? realQuarter + '' : DEFAULT_QUARTER
    })
    builder.addCase(getMaxPeriod.rejected, (state, action) => {
      state.limitTimeLoading = action.payload.isLoading
    })
  },
})

export const {
  handleYear,
  handleTimeRangeValue,
  handleRadioValue,
  handleTimeRangeAccValue,
} = slice.actions

export const getPieChartLoading = (state) => state[slice.name].pieChartLoading
export const getStackedBarChartLoading = (state) =>
  state[slice.name].stackedBarChartLoading
export const getTop5Indicator = (state) => state[slice.name].top5Indicator
export const getPieChartData = (state) => state[slice.name].pieChartData
export const getStackedBarChartData = (state) =>
  state[slice.name].stackedBarChartData
export const getYear = (state) => state[slice.name].year
export const getTimeRangeValue = (state) => state[slice.name].timeRangeValue
export const getTimeRangeAccValue = (state) =>
  state[slice.name].timeRangeAccValue
export const getRadioValue = (state) => state[slice.name].radioValue
export const getDataTypeName = (state) => state[slice.name].dataTypeName
export const getDataTypeNameLoading = (state) =>
  state[slice.name].dataTypeNameLoading
export const getLimitTimeLoading = (state) => state[slice.name].limitTimeLoading

register(slice.name, slice.reducer)
