import { TYPE } from '../../../../common/chart/footer/Item'
import { LINE_COLOR, STACK_COLORS } from './constants'

export const getTooltipContentData = (data, stackKey, nameById) => {
  const getTextName = (id) => {
    return nameById?.find((item) => item.id === id)?.name
  }

  return stackKey?.map((key) => {
    return {
      label: getTextName(key),
      value: data[key],
    }
  })
}

export const renderListFooter = (
  stackKey,
  isBalanceChart,
  stackKey2,
  lineKey,
  nameById,
) => {
  const getTextName = (id) => {
    return nameById?.find((item) => item.id === id)?.name
  }

  if (isBalanceChart) {
    const lineFooter = lineKey.map((item, index) => {
      return {
        text: getTextName(item),
        type: TYPE.CIRCLE_MARKER_LINE,
        before: {
          bgColor: LINE_COLOR[index],
        },
        after: {
          bgColor: LINE_COLOR[index],
        },
        isI18n: false,
      }
    })

    return [
      {
        text: getTextName(stackKey2),
        type: TYPE.SQUARE,
        before: {
          bgColor: '#facc5c',
        },
        isI18n: false,
      },
      ...lineFooter,
    ]
  }
  const MAX_STACK = 6
  const dataLength = stackKey.length > MAX_STACK ? MAX_STACK : stackKey.length
  return stackKey.map((item, index) => {
    return {
      text: getTextName(item),
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS?.[dataLength]?.[index],
      },
      isI18n: false,
    }
  })
}
