import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import { LightBorderColumnCell } from './LightBorderColumnCell'
import { TdSelect } from './TdSelect'

const BORDER_DARK = '1px solid #2b303a'
const BORDER_LIGHT = '1px solid #546373'
const BORDER_BLACK = '1px solid #1e242e'

export const GROUP_COLUMNS = [
  {
    key: 'name',
    title: 'sector.sectorSpecific.steel.COMPANY_NAME',
  },
  {
    key: 'ticker',
    title: 'sector.sectorSpecific.steel.TICKER',
  },
  {
    key: 'production',
    title: 'sector.sectorSpecific.steel.PRODUCTION',
  },
  {
    key: 'consumption',
    title: 'sector.sectorSpecific.steel.TOTAL',
  },
  {
    key: 'consumptionNorth',
    title: 'sector.sectorSpecific.steel.NORTH',
  },
  {
    key: 'consumptionCentral',
    title: 'sector.sectorSpecific.steel.CENTRAL',
  },
  {
    key: 'consumptionSouth',
    title: 'sector.sectorSpecific.steel.SOUTH',
  },
  {
    key: 'export',
    title: 'sector.sectorSpecific.steel.EXPORT',
  },
  {
    key: 'inventory',
    title: 'sector.sectorSpecific.steel.INVENTORY',
  },
  {
    key: 'consumptionTotal',
    title: 'sector.sectorSpecific.steel.CONSUMPTION',
  },
]

const GROUP_COLUMNS_ROWSPAN = [
  GROUP_COLUMNS[0],
  GROUP_COLUMNS[1],
  GROUP_COLUMNS[2],
]

const GROUP_COLUMNS_REMAIN = [
  GROUP_COLUMNS[4],
  GROUP_COLUMNS[5],
  GROUP_COLUMNS[6],
  GROUP_COLUMNS[7],
]

export const rowSpanSchema = [
  [
    ...GROUP_COLUMNS_ROWSPAN.map((item) => ({
      colId: item.key,
      renderTh: () => (
        <div>
          <Span style={{ fontSize: 10 }}>
            <Translate
              value={item.title}
              style={{ textTransform: 'uppercase' }}
            />
          </Span>
        </div>
      ),
      rowSpan: 2,
      thStyle: {
        textAlign: GROUP_COLUMNS_ROWSPAN.indexOf(item) !== 2 ? 'left' : 'right',
        verticalAlign: 'baseline',
        borderRight:
          GROUP_COLUMNS_ROWSPAN.indexOf(item) === 0 ? BORDER_DARK : null,
      },
    })),
    {
      colId: GROUP_COLUMNS[9].key,
      colSpan: 5,
      thStyle: {
        borderRight: BORDER_LIGHT,
      },
      renderTh: () => (
        <div style={{ textAlign: 'center' }}>
          <Span style={{ fontSize: 10 }}>
            <Translate
              value={GROUP_COLUMNS[9].title}
              style={{ textTransform: 'uppercase' }}
            />
          </Span>
        </div>
      ),
    },
    {
      colId: GROUP_COLUMNS[8].key,
      renderTh: () => (
        <div>
          <Span style={{ fontSize: 10 }}>
            <Translate
              value={GROUP_COLUMNS[8].title}
              style={{ textTransform: 'uppercase' }}
            />
          </Span>
        </div>
      ),
      rowSpan: 2,
      thStyle: {
        textAlign: 'right',
        verticalAlign: 'baseline',
        borderLeft: BORDER_LIGHT,
      },
    },
  ],
  [
    {
      title: GROUP_COLUMNS[3].title,
      colId: GROUP_COLUMNS[3].key,
      thStyle: {
        fontSize: 10,
        textAlign: 'right',
      },
      // thClassName: style.customTableStickyHead,
    },
    ...GROUP_COLUMNS_REMAIN.map((item) => ({
      title: item.title,
      colId: item.key,
      thStyle: {
        fontSize: 10,
        textAlign: 'right',
        // borderLeft: BORDER_BLACK,
      },
    })),
  ],
]

export const getSchema = (
  onRowCollapse,
  rowsCollapse,
  data,
  listItemChecked,
) => {
  return GROUP_COLUMNS.slice(0, 9).map((item, index) => {
    const title = item.title
    const colId = item.key
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val, rowId) => {
          return (
            <TdSelect
              val={val}
              rowId={rowId}
              onRowCollapse={onRowCollapse}
              isRowCollapse={rowsCollapse.includes(rowId)}
              data={data}
              listItemChecked={listItemChecked}
            />
          )
        },
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        canCustomTd: true,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          borderRight: BORDER_LIGHT,
        },
        render: (val, rowId) => (
          <LightBorderColumnCell
            val={val}
            rowId={rowId}
            isAlignTextLeft
            isTicker
          />
        ),
      }
    }
    if (index === 7 || index === 2) {
      result = {
        ...result,
        isI18n: false,
        canCustomTd: true,
        tdStyle: {
          borderRight: BORDER_LIGHT,
        },
        render: (val) => <LightBorderColumnCell val={formatVal(val, 0)} />,
      }
    }
    if (![0, 1, 2, 7].includes(index)) {
      result = {
        ...result,
        render: (val) => formatVal(val, 0),
      }
    }
    return result
  })
}

export const TYPE_STATISTIC = {
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'Acc Monthly',
  YEARLY: 'Yearly',
}

export const TYPE_STATISTICS_FILTER = [
  {
    title: 'sector.sectorSpecific.steel.FILTER_MONTHLY',
    value: TYPE_STATISTIC.MONTHLY,
    isYearly: false,
    isAcc: false,
  },
  {
    title: 'sector.sectorSpecific.steel.FILTER_ACC_MONTHLY',
    value: TYPE_STATISTIC.ACC_MONTHLY,
    isYearly: false,
    isAcc: true,
  },
  {
    title: 'sector.sectorSpecific.steel.FILTER_YEARLY',
    value: TYPE_STATISTIC.YEARLY,
    isYearly: true,
    isAcc: false,
  },
]

export const DEFAULT_MONTH = 5
export const DEFAULT_YEAR = 2022

export const MONTH_LIST_FILTER_VI = [
  {
    name: 'Th01',
    value: 1,
  },
  {
    name: 'Th02',
    value: 2,
  },
  {
    name: 'Th03',
    value: 3,
  },
  {
    name: 'Th04',
    value: 4,
  },
  {
    name: 'Th05',
    value: 5,
  },
  {
    name: 'Th06',
    value: 6,
  },
  {
    name: 'Th07',
    value: 7,
  },
  {
    name: 'Th08',
    value: 8,
  },
  {
    name: 'Th09',
    value: 9,
  },
  {
    name: 'Th10',
    value: 10,
  },
  {
    name: 'Th11',
    value: 11,
  },
  {
    name: 'Th12',
    value: 12,
  },
]

export const MONTH_LIST_FILTER_EN = [
  {
    name: 'Jan',
    value: 1,
  },
  {
    name: 'Feb',
    value: 2,
  },
  {
    name: 'Mar',
    value: 3,
  },
  {
    name: 'Apr',
    value: 4,
  },
  {
    name: 'May',
    value: 5,
  },
  {
    name: 'Jun',
    value: 6,
  },
  {
    name: 'Jul',
    value: 7,
  },
  {
    name: 'Aug',
    value: 8,
  },
  {
    name: 'Sep',
    value: 9,
  },
  {
    name: 'Oct',
    value: 10,
  },
  {
    name: 'Nov',
    value: 11,
  },
  {
    name: 'Dec',
    value: 12,
  },
]

const START_YEAR = 2013
const currentYearByDateJs = new Date().getFullYear()

export const YEAR_LIST = [...Array(currentYearByDateJs - START_YEAR + 1)].map(
  (_, index) => currentYearByDateJs - index,
)

export const YEAR_LIST_FILTER = YEAR_LIST.map((item) => ({
  value: item,
  name: `${item}`,
}))

export const ACC_MONTH_FILTER_VI = Array.from(
  { length: 12 },
  (v, k) => k + 1,
).map((item) => ({
  name: item === 1 ? 'Th01' : `${item}Th`,
  value: item,
}))

export const ACC_MONTH_FILTER_EN = Array.from(
  { length: 12 },
  (v, k) => k + 1,
).map((item) => ({
  name: item === 1 ? 'Jan' : `${item}M`,
  value: item,
}))

export const DEFAULT_COMPANY_ID = 0

export const defaultTickItems = (data) => {
  return data.filter((item) => item.isGroupRow).slice(0, 5)
}
