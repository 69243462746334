import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IssuesPlan from '../../../../../../../core/services/bond/overview/issuesPlan/IssuesPlan'

import { handleExport } from '../../../../../../utils/Export'

export const getDataIssuePlan = createAsyncThunk(
  'overview/issueplan/getData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IssuesPlan.getDataIssuesPlan(data)
      if (response.success) {
        const dataList = response?.data || []

        const data = dataList
          .sort((a, b) => {
            const dateA = new Date(a.expectedDate)
            const dateB = new Date(b.expectedDate)
            return dateA - dateB
          })
          .map((e) => ({
            ...e,
            organizationShortName: e?.organizationShortName || '',
          }))
        return data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const issuePlanExport = createAsyncThunk(
  'overview/issueplan/IssuePlanExport',
  async (data) => {
    const response = await IssuesPlan.exportFile(data)
    handleExport(response.data, response.filename)
  },
)
