import { useState } from 'react'
import { useSelector } from 'react-redux'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../../../common/html/Span'
import { TABLE_ROW_LEVEL } from '../constants'
import { selectDataById } from '../store/slice'

const MARGIN_LEFT = 20

export const FirstColumnCell = ({ val, rowId, onRowCollapse }) => {
  const dataById = useSelector(selectDataById)
  const item = dataById[rowId]

  const [isCollapse, setIsCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <div
      className="d-flex align-center"
      style={{
        marginLeft: (item.level - 1) * MARGIN_LEFT,
      }}
    >
      {item.isCollapse ? (
        <button
          style={{ cursor: 'pointer' }}
          className="align-center"
          onClick={onCollapse}
        >
          <i
            className={`${isCollapse ? 'icon-caret-right' : 'icon-caret-down'}`}
            style={{ fontSize: '8px', color: '#8c9194' }}
          ></i>
        </button>
      ) : (
        <></>
      )}
      {item.level === TABLE_ROW_LEVEL.LEVEL_1 ? (
        <Span
          style={{
            color: '#fecf2f',
            lineHeight: 1.5,
            width: 'fit-content',
          }}
        >
          {val}
        </Span>
      ) : (
        <LinkTicker organizationId={item?.assetId}>{val}</LinkTicker>
      )}
    </div>
  )
}
