export const TYPE_TIME_RANGE = {
  PERIOD: 'PERIOD',
  RANGE: 'RANGE',
}

export const VALUE_PERIOD = {
  ONE_WEEK: 'OneWeek',
  ONE_MONTH: 'OneMonth',
  THREE_MONTHS: 'ThreeMonths',
  SIX_MONTHS: 'SixMonths',
  ONE_YEAR: 'OneYear',
  YTD: 'YearToDate',
  ALL: 'AllTime',
}

export const listPeriod = [
  { label: 'ONE_WEEK', value: VALUE_PERIOD.ONE_WEEK },
  { label: 'ONE_MONTH', value: VALUE_PERIOD.ONE_MONTH },
  { label: 'THREE_MONTHS', value: VALUE_PERIOD.THREE_MONTHS },
  { label: 'SIX_MONTHS', value: VALUE_PERIOD.SIX_MONTHS },
  { label: 'YTD', value: VALUE_PERIOD.YTD },
  { label: 'ONE_YEAR', value: VALUE_PERIOD.ONE_YEAR },
  { label: 'ALL', value: VALUE_PERIOD.ALL },
]

export const DROPDOWN_COMPANY_OVERVIEW = {
  COMPARE_WITH_SECTOR: 'COMPARE_WITH_SECTOR',
  REVENUE_BREAKDOWN: 'REVENUE_BREAKDOWN',
}

export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#f4327b',
  '#9d9e9e',
]

export const constantExchangeCode = {
  ALL_EXCHANGE: 'ALL_EXCHANGE',
  HOSE: 'HOSE',
  UPCOM: 'UPCOM',
  PRIVATE: 'PRIVATE',
}

export const listExchangeCode = [
  {
    name: 'corporate.companySnapshot.ALL_EXCHANGE',
    value: 'ALL_EXCHANGE',
  },
  {
    name: 'HOSE',
    value: 'HOSE',
  },
  {
    name: 'UPCOM',
    value: 'UPCOM',
  },
  {
    name: 'HNX',
    value: 'HNX',
  },
  {
    name: 'PRIVATE',
    value: 'PRIVATE',
  },
]

export const COMPARE_MAX_ADDED = 3

export const keyTabletop5MajorShareHolder = {
  NAME: 'name',
  CURRENT_POSITION: 'title',
  SHARES: 'quantity',
  PERCENTAGE: 'percentage',
  LAST_UPDATE: 'publicDateId',
}

export const REPORT_TYPE = {
  CONSOLIDATED: 1,
  PARENT: 2,
}

export const AUDIT_STATUS = {
  AUDITED: 1,
  UNAUDITED: 0,
  BOTH_AUDITED_AND_UNAUDITED: 2,
}

export const CURRENCY = {
  BILLION_VND: 'BILLION_VND',
  MILLION_VND: 'MILLION_VND',
  VND: 'VND',
}

export const indicatorFinancialDataCorp = [
  {
    index: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TOTAL_ASSETS2',
    key: 'bS64',
    isFormatBil: true,
  },
  {
    index: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.SHAREHOLDERS_EQUITY',
    key: 'bS95',
    isFormatBil: true,
  },
  {
    index: 'SALES',
    name: 'corporate.companySnapshot.SALES',
    key: 'iS1',
    isFormatBil: true,
  },
  {
    index: 'NET_PROFIT',
    name: 'corporate.companySnapshot.NET_PROFIT',
    key: 'iS20',
    isFormatBil: true,
  },
  {
    index: 'EPS',
    name: 'corporate.companySnapshot.EPS',
    key: 'rtD0011',
    isFormat: true,
  },
  {
    index: 'DIVIDEND',
    name: 'corporate.companySnapshot.DIVIDEND',
    key: 'rtD0044',
    isFormat: true,
  },
]

export const indicatorFinancialDataSec = [
  {
    index: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TOTAL_ASSETS2',
    key: 'bS64',
    isFormatBil: true,
  },
  {
    index: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.SHAREHOLDERS_EQUITY',
    key: 'bS95',
    isFormatBil: true,
  },
  {
    index: 'SALES',
    name: 'corporate.companySnapshot.SALES',
    key: 'iS3',
    isFormatBil: true,
  },
  {
    index: 'NET_PROFIT',
    name: 'corporate.companySnapshot.NET_PROFIT',
    key: 'iS20',
    isFormatBil: true,
  },
  {
    index: 'EPS',
    name: 'corporate.companySnapshot.EPS',
    key: 'rtD0011',
    isFormat: true,
  },
  {
    index: 'DIVIDEND',
    name: 'corporate.companySnapshot.DIVIDEND',
    key: 'rtD0044',
    isFormat: true,
  },
]

export const indicatorFinancialDataBank = [
  {
    index: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TOTAL_ASSETS2',
    key: 'bS64',
    isFormatBil: true,
  },
  {
    index: 'LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1',
    name: 'corporate.companySnapshot.LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1',
    key: 'bS158',
    isFormatBil: true,
  },
  {
    index: 'NET_INTEREST_INCOME_1',
    name: 'corporate.companySnapshot.NET_INTEREST_INCOME_1',
    key: 'iS27',
    isFormatBil: true,
  },
  {
    index: 'NET_PROFIT',
    name: 'corporate.companySnapshot.NET_PROFIT',
    key: 'iS21',
    isFormatBil: true,
  },
  {
    index: 'EPS',
    name: 'corporate.companySnapshot.EPS',
    key: 'rtD0011',
    isFormat: true,
  },
  {
    index: 'DIVIDEND',
    name: 'corporate.companySnapshot.DIVIDEND',
    key: 'rtD0044',
    isFormat: true,
  },
]

export const keyPriceDateTable = {
  YEAR: 'year',
  HIGHEST: 'max1YearHighPriceAdjusted',
  LOWEST: 'min1YearLowPriceAdjusted',
}

export const indicatorPricePerFormance = [
  {
    index: '52w_range',
    name: 'corporate.companySnapshot.W_RANGE',
    key: '',
  },
  {
    index: 'percentPriceChange',
    name: 'corporate.companySnapshot.TODAY_CHANGE',
    key: 'percentPriceChange',
    isFormatPercent: true,
  },
  {
    index: 'totalPercentChangeYTDClosePriceAdjusted',
    name: 'corporate.companySnapshot.YTD_CHANGE',
    key: 'totalPercentChangeYTDClosePriceAdjusted',
    isFormatPercent: true,
  },
  {
    index: 'avg3MonthTotalVolume',
    name: 'corporate.companySnapshot.AVG_DAILY_VOL_3M',
    key: 'avg3MonthTotalVolume',
    isFormatInteger: true,
  },
  {
    index: 'avg3MonthTotalValue',
    name: 'corporate.companySnapshot.AVG_DAILY_VAL_3M',
    key: 'avg3MonthTotalValue',
    isFormatMil: true,
  },
]

export const TABLE_ROW_LEVEL = {
  LEVEL_1: 1,
  LEVEL_2: 2,
}

export const indicatorIncomeStatementCorp = [
  {
    index: 'SALES',
    name: 'corporate.companySnapshot.SALES',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    key: 'iS1',
  },
  {
    index: 'NET_SALES',
    name: 'corporate.companySnapshot.NET_SALES',
    level: TABLE_ROW_LEVEL.LEVEL_1,
    key: 'iS3',
  },
  {
    index: 'GROSS_PROFIT',
    childrenId: [
      'FINANCIAL_INCOME',
      'FINANCIAL_EXPENSES',
      'OF_WHICH_INTEREST_EXPENSES',
      'GAIN_FORM_JOINT_VENTURE',
      'SELLING_EXPENSES',
      'GENERAL_AND_ADMIN_EXPENSES',
    ],
    name: 'corporate.companySnapshot.GROSS_PROFIT',
    key: 'iS5',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'FINANCIAL_INCOME',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.FINANCIAL_INCOME',
    key: 'iS6',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'FINANCIAL_EXPENSES',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.FINANCIAL_EXPENSES',
    key: 'iS7',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'OF_WHICH_INTEREST_EXPENSES',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.OF_WHICH_INTEREST_EXPENSES',
    key: 'iS8',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'GAIN_FORM_JOINT_VENTURE',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.GAIN_FORM_JOINT_VENTURE',
    key: 'iS9',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'SELLING_EXPENSES',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.SELLING_EXPENSES',
    key: 'iS10',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'GENERAL_AND_ADMIN_EXPENSES',
    parentId: 'GROSS_PROFIT',
    name: 'corporate.companySnapshot.GENERAL_AND_ADMIN_EXPENSES',
    key: 'iS11',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'OPERARING_PROFIT',
    name: 'corporate.companySnapshot.OPERARING_PROFIT',
    key: 'iS12',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_OTHER_INCOME',
    name: 'corporate.companySnapshot.NET_OTHER_INCOME',
    key: 'iS15',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_ACCOUNTING_PROFIT',
    name: 'corporate.companySnapshot.NET_ACCOUNTING_PROFIT',
    key: 'iS16',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'BUSINESS_INCOME_TAX_ENPENSES',
    name: 'corporate.companySnapshot.BUSINESS_INCOME_TAX_ENPENSES',
    key: 'iS17',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_PROFIT_AFTER_TAX',
    childrenId: ['MINORITY_INTERESTS'],
    name: 'corporate.companySnapshot.NET_PROFIT_AFTER_TAX',
    key: 'iS20',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'MINORITY_INTERESTS',
    parentId: 'NET_PROFIT_AFTER_TAX',
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    key: 'iS22',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'ATTRIBUTE_TO_PARENT_COMPANY',
    name: 'corporate.companySnapshot.ATTRIBUTE_TO_PARENT_COMPANY',
    key: 'iS21',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorIncomeStatementBank = [
  {
    index: 'INTEREST_AND_SIMILAR_INCOME',
    name: 'corporate.companySnapshot.INTEREST_AND_SIMILAR_INCOME',
    key: 'iS25',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'INTEREST_AND_SIMILAR_EXPENSES',
    name: 'corporate.companySnapshot.INTEREST_AND_SIMILAR_EXPENSES',
    key: 'iS26',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_INTEREST_INCOME',
    childrenId: [
      'NET_FEE_AND_COMMISION_INCOME',
      'NET_GAIN_FROM_FOREIGN_CURRENCY_AND_GOLD_DEALINGS',
      'NET_GAIN_FROM_TRADING_OF_TRADING_SECURITIES',
      'NET_GAIN_FROM_TRADING_OF_INVESTMENT_SECURITIES',
      'NET_OTHER_INCOME',
      'DIVIDENDS_INCOME',
    ],
    name: 'corporate.companySnapshot.NET_INTEREST_INCOME',
    key: 'iS27',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_FEE_AND_COMMISION_INCOME',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NET_FEE_AND_COMMISION_INCOME',
    key: 'iS30',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_GAIN_FROM_FOREIGN_CURRENCY_AND_GOLD_DEALINGS',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NET_GAIN_FROM_FOREIGN_CURRENCY_AND_GOLD_DEALINGS',
    key: 'iS31',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_GAIN_FROM_TRADING_OF_TRADING_SECURITIES',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NET_GAIN_FROM_TRADING_OF_TRADING_SECURITIES',
    key: 'iS32',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_GAIN_FROM_TRADING_OF_INVESTMENT_SECURITIES',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NET_GAIN_FROM_TRADING_OF_INVESTMENT_SECURITIES',
    key: 'iS33',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_OTHER_INCOME',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NET_OTHER_INCOME',
    key: 'iS36',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'DIVIDENDS_INCOME',
    parentId: 'NET_INTEREST_INCOME',
    name: 'corporate.companySnapshot.DIVIDENDS_INCOME',
    key: 'iS37',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'TOTAL_OPERAING_INCOME',
    name: 'corporate.companySnapshot.TOTAL_OPERAING_INCOME',
    key: 'iS38',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'GENERAL_AND_ADMIN_EXPENSES',
    name: 'corporate.companySnapshot.GENERAL_AND_ADMIN_EXPENSES',
    key: 'iS39',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    childrenId: [
      'PROVISION_FOR_CREDIT_LOSSES',
      'PROFIT_BEFORE_TAX',
      'BUSINESS_INCOME_TAX_',
      'NET_PROFIT_AFTER_TAX',
      'MINORITY_INTERESTS',
    ],
    name: 'corporate.companySnapshot.OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    key: 'iS40',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'PROVISION_FOR_CREDIT_LOSSES',
    parentId: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    name: 'corporate.companySnapshot.PROVISION_FOR_CREDIT_LOSSES',
    key: 'iS41',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'PROFIT_BEFORE_TAX',
    parentId: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    name: 'corporate.companySnapshot.PROFIT_BEFORE_TAX',
    key: 'iS16',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'BUSINESS_INCOME_TAX_',
    parentId: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    name: 'corporate.companySnapshot.BUSINESS_INCOME_TAX_',
    key: 'iS17',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'NET_PROFIT_AFTER_TAX',
    parentId: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    name: 'corporate.companySnapshot.NET_PROFIT_AFTER_TAX',
    key: 'iS20',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'MINORITY_INTERESTS',
    parentId: 'OPERATING_PROFIT_BEFORE_PROVISION_FOR_CREDIT_LOSSES',
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    key: 'iS22',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'ATTRIBUTE_TO_PARENT_COMPANY',
    name: 'corporate.companySnapshot.ATTRIBUTE_TO_PARENT_COMPANY',
    key: 'iS21',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorIncomeStatementIns = [
  {
    index: 'REVENUE_FROM_INSURANCE_PREMIUM',
    childrenId: [
      'GROSS_WRITTEN_PREMIUM',
      'REINSURANCE_PREMIUM_ASSUMED',
      'INCREASE1',
      'DEDUCTIONS',
      'INCREASE_IN_UNEARNED_PREMIUM',
      'NET_REVENUE_OF_INSURANCE_PREMIUM',
      'COMMISSION_INCOME_FROM_OUTWARD',
      'COMMISSION_ON_REINSURANE_CEDED',
      'OTHER_INCOME',
    ],
    name: 'corporate.companySnapshot.REVENUE_FROM_INSURANCE_PREMIUM',
    key: 'iS1',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'GROSS_WRITTEN_PREMIUM',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.GROSS_WRITTEN_PREMIUM',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS42',
  },
  {
    index: 'REINSURANCE_PREMIUM_ASSUMED',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.REINSURANCE_PREMIUM_ASSUMED',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS43',
  },
  {
    index: 'INCREASE1',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.INCREASE1',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS44',
  },
  {
    index: 'DEDUCTIONS',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.DEDUCTIONS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS45',
  },
  {
    index: 'INCREASE_IN_UNEARNED_PREMIUM',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.INCREASE_IN_UNEARNED_PREMIUM',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS52',
  },
  {
    index: 'NET_REVENUE_OF_INSURANCE_PREMIUM',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.NET_REVENUE_OF_INSURANCE_PREMIUM',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS51',
  },
  {
    index: 'COMMISSION_INCOME_FROM_OUTWARD',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.COMMISSION_INCOME_FROM_OUTWARD',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS53',
  },
  {
    index: 'COMMISSION_ON_REINSURANE_CEDED',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.COMMISSION_ON_REINSURANE_CEDED',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS54',
  },
  {
    index: 'OTHER_INCOME',
    parentId: 'REVENUE_FROM_INSURANCE_PREMIUM',
    name: 'corporate.companySnapshot.OTHER_INCOME',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS55',
  },
  {
    index: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    childrenId: [
      'CLAIM_AND_MATURITY_PAYMENT_EXPENSES',
      'CLAIM_EXPENSE_FOR_REINSURANCE_ASSUMED',
      'DEDUCTIONS1',
      'CLAIM_RECOVERIES_FROM_OUTWARD_REINSURANCE',
      'CLAIM_EXPENSE_ON_RETAINED_RISKS',
      'INCREASE_MATHEMATIC_RESERVES',
      'INCREASE_CLAIM_RESERVE',
      'INCREASE_CLAIM_RESERVE_FOR_DIRECT_INSURANCE_AND_INWARD_INSURANCE',
      'INCREASE_IN_CLAIM_RESERVE_FOR_OUTWARD_INSURANCE',
      'TOTAL_INSURANCE_CLAIM_SETTLEMENT_EXPENSES',
      'CLAIM_EXPENSES_USING_CATASTROPHE_RESERVE',
      'PROVISION_FOR_CATASTROPHE_RESERVE',
      'OTHER_INSURANCE_OPRERATING_EXPENSES',
      'OTHER_UNDERWRITING_EXPENSES',
      'OTHER_REINSURANCE_ASSUMED_EXPENSES',
      'OTHER_REINSURANCE_CEDED_EXPENSES',
      'OTHER_DIRECT_INSURANCE_OPERATING_EXPENSES',
      'TOTAL_DIRECT_INSURANCE_OPERATING_EXPENSES',
    ],
    name: 'corporate.companySnapshot.NET_SALES_FROM_INSURANCE_BUSINESS',
    key: 'iS12',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CLAIM_AND_MATURITY_PAYMENT_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.CLAIM_AND_MATURITY_PAYMENT_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS61',
  },
  {
    index: 'CLAIM_EXPENSE_FOR_REINSURANCE_ASSUMED',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.CLAIM_EXPENSE_FOR_REINSURANCE_ASSUMED',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS62',
  },
  {
    index: 'DEDUCTIONS1',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.DEDUCTIONS1',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS63',
  },
  {
    index: 'CLAIM_RECOVERIES_FROM_OUTWARD_REINSURANCE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.CLAIM_RECOVERIES_FROM_OUTWARD_REINSURANCE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS67',
  },
  {
    index: 'CLAIM_EXPENSE_ON_RETAINED_RISKS',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.CLAIM_EXPENSE_ON_RETAINED_RISKS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS68',
  },
  {
    index: 'INCREASE_MATHEMATIC_RESERVES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.INCREASE_MATHEMATIC_RESERVES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS69',
  },
  {
    index: 'INCREASE_CLAIM_RESERVE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.INCREASE_CLAIM_RESERVE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS70',
  },
  {
    index: 'INCREASE_CLAIM_RESERVE_FOR_DIRECT_INSURANCE_AND_INWARD_INSURANCE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.INCREASE_CLAIM_RESERVE_FOR_DIRECT_INSURANCE_AND_INWARD_INSURANCE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS71',
  },
  {
    index: 'INCREASE_IN_CLAIM_RESERVE_FOR_OUTWARD_INSURANCE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.INCREASE_IN_CLAIM_RESERVE_FOR_OUTWARD_INSURANCE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS72',
  },
  {
    index: 'TOTAL_INSURANCE_CLAIM_SETTLEMENT_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.TOTAL_INSURANCE_CLAIM_SETTLEMENT_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS73',
  },
  {
    index: 'CLAIM_EXPENSES_USING_CATASTROPHE_RESERVE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.CLAIM_EXPENSES_USING_CATASTROPHE_RESERVE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS74',
  },
  {
    index: 'PROVISION_FOR_CATASTROPHE_RESERVE',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.PROVISION_FOR_CATASTROPHE_RESERVE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS75',
  },
  {
    index: 'OTHER_INSURANCE_OPRERATING_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.OTHER_INSURANCE_OPRERATING_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS76',
  },
  {
    index: 'OTHER_UNDERWRITING_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.OTHER_UNDERWRITING_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS77',
  },
  {
    index: 'OTHER_REINSURANCE_ASSUMED_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.OTHER_REINSURANCE_ASSUMED_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS85',
  },
  {
    index: 'OTHER_REINSURANCE_CEDED_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.OTHER_REINSURANCE_CEDED_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS86',
  },
  {
    index: 'OTHER_DIRECT_INSURANCE_OPERATING_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.OTHER_DIRECT_INSURANCE_OPERATING_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS87',
  },
  {
    index: 'TOTAL_DIRECT_INSURANCE_OPERATING_EXPENSES',
    parentId: 'NET_SALES_FROM_INSURANCE_BUSINESS',
    name: 'corporate.companySnapshot.TOTAL_DIRECT_INSURANCE_OPERATING_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS4',
  },
  {
    index: 'GROSS_INSURANCE_OPERATING_PROFIT',
    childrenId: [
      'NET_OPERATING_INCOME_FORM_BANKING_ACTIVITIES',
      'NET_OPERATING_INCOME_FORM_OTHER_ACTIVITIES',
      'SELLING_EXPENSE',
      'GENERAL_AND_ADMINISTRATIVE_EXPENSES',
    ],
    name: 'corporate.companySnapshot.GROSS_INSURANCE_OPERATING_PROFIT',
    key: 'iS5',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_OPERATING_INCOME_FORM_BANKING_ACTIVITIES',
    parentId: 'GROSS_INSURANCE_OPERATING_PROFIT',
    name: 'corporate.companySnapshot.NET_OPERATING_INCOME_FORM_BANKING_ACTIVITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS88',
  },
  {
    index: 'NET_OPERATING_INCOME_FORM_OTHER_ACTIVITIES',
    parentId: 'GROSS_INSURANCE_OPERATING_PROFIT',
    name: 'corporate.companySnapshot.NET_OPERATING_INCOME_FORM_OTHER_ACTIVITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS15',
  },
]

export const indicatorIncomeStatementIns1 = [
  {
    index: 'SELLING_EXPENSE',
    parentId: 'GROSS_INSURANCE_OPERATING_PROFIT',
    name: 'corporate.companySnapshot.SELLING_EXPENSE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS10',
  },
  {
    index: 'GENERAL_AND_ADMINISTRATIVE_EXPENSES',
    parentId: 'GROSS_INSURANCE_OPERATING_PROFIT',
    name: 'corporate.companySnapshot.GENERAL_AND_ADMINISTRATIVE_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS11',
  },
  {
    index: 'NET_OPERATING_PROFIT_FROM_INSURANCE_OPERATION',
    name: 'corporate.companySnapshot.NET_OPERATING_PROFIT_FROM_INSURANCE_OPERATION',
    key: 'iS1',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_PROFIT_FORM_BANK_OPERATION',
    name: 'corporate.companySnapshot.NET_PROFIT_FORM_BANK_OPERATION',
    key: 'iS103',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_PROFIT_FROM_OTHERS_OPERATION',
    childrenId: [
      'FINANCIAL_INCOME',
      'FINANCIAL_EXPENSES',
      'PROFIT_FROM_FINANCIAL_ACTIVITIES',
      'NET_OPERATING_INCOME_FROM_OTHER_ACTIVITIES',
    ],
    name: 'corporate.companySnapshot.NET_PROFIT_FROM_OTHERS_OPERATION',
    key: 'iS104',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'FINANCIAL_INCOME',
    parentId: 'NET_PROFIT_FROM_OTHERS_OPERATION',
    name: 'corporate.companySnapshot.FINANCIAL_INCOME',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS6',
  },
  {
    index: 'FINANCIAL_EXPENSES',
    parentId: 'NET_PROFIT_FROM_OTHERS_OPERATION',
    name: 'corporate.companySnapshot.FINANCIAL_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS7',
  },
  {
    index: 'PROFIT_FROM_FINANCIAL_ACTIVITIES',
    parentId: 'NET_PROFIT_FROM_OTHERS_OPERATION',
    name: 'corporate.companySnapshot.PROFIT_FROM_FINANCIAL_ACTIVITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS94',
  },
  {
    index: 'NET_OPERATING_INCOME_FROM_OTHER_ACTIVITIES',
    parentId: 'NET_PROFIT_FROM_OTHERS_OPERATION',
    name: 'corporate.companySnapshot.NET_OPERATING_INCOME_FROM_OTHER_ACTIVITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS95',
  },
  {
    index: 'PROFIT_BEFORE_TAX',
    childrenId: ['ENTERPRISE_INCOME_TAX_FOR_THE_YEAR'],
    name: 'corporate.companySnapshot.PROFIT_BEFORE_TAX',
    key: 'iS16',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'ENTERPRISE_INCOME_TAX_FOR_THE_YEAR',
    parentId: 'PROFIT_BEFORE_TAX',
    name: 'corporate.companySnapshot.ENTERPRISE_INCOME_TAX_FOR_THE_YEAR',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS17',
  },
  {
    index: 'PROFIT_AFTER_TAX',
    childrenId: ['MINORITY_INTERESTS'],
    name: 'corporate.companySnapshot.PROFIT_AFTER_TAX',
    key: 'iS20',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'MINORITY_INTERESTS',
    parentId: 'PROFIT_AFTER_TAX',
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS22',
  },
  {
    index: 'NET_PROFIT_ATTRIBUTABLE_TO_SHAREHOLDERS_OF_THE_GROUP',
    name: 'corporate.companySnapshot.NET_PROFIT_ATTRIBUTABLE_TO_SHAREHOLDERS_OF_THE_GROUP',
    key: 'iS21',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorIncomeStatementSec = [
  {
    index: 'REVENUE',
    childrenId: [
      'REVENUE_IN_BROKERAGE_SERVICES',
      'REVENUE_IN_SECURITIES_INVESTMENT_AND_OTHER_INVESTMENT_ACTIVITIES',
      'REVENUE_IN_UNDERWRITING_SERVICES',
      'REVENUE_IN_ISSUANCE_AGENCY_SERVICES',
      'REVENUE_IN_ADVISORY_SERVICES',
      'REVENUE_IN_SECURITIES_CUSTODY_SERVICES',
      'REVENUE_IN_AUCTION_TRUST_SERVICES',
      'RENTAL_INCOME_FROM_INVESTMENT_PROPERTY',
      'OTHERS_REVENUE',
    ],
    name: 'corporate.companySnapshot.REVENUE',
    key: 'iS3',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'REVENUE_IN_BROKERAGE_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_BROKERAGE_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS114',
  },
  {
    index: 'REVENUE_IN_SECURITIES_INVESTMENT_AND_OTHER_INVESTMENT_ACTIVITIES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_SECURITIES_INVESTMENT_AND_OTHER_INVESTMENT_ACTIVITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'REVENUE_IN_UNDERWRITING_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_UNDERWRITING_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS115',
  },
  {
    index: 'REVENUE_IN_ISSUANCE_AGENCY_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_ISSUANCE_AGENCY_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'REVENUE_IN_ADVISORY_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_ADVISORY_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS117',
  },
  {
    index: 'REVENUE_IN_SECURITIES_CUSTODY_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_SECURITIES_CUSTODY_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS119',
  },
  {
    index: 'REVENUE_IN_AUCTION_TRUST_SERVICES',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.REVENUE_IN_AUCTION_TRUST_SERVICES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'RENTAL_INCOME_FROM_INVESTMENT_PROPERTY',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.RENTAL_INCOME_FROM_INVESTMENT_PROPERTY',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'OTHERS_REVENUE',
    parentId: 'REVENUE',
    name: 'corporate.companySnapshot.OTHERS_REVENUE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS123',
  },
  {
    index: 'DEDUCTIONS',
    childrenId: [],
    name: 'corporate.companySnapshot.DEDUCTIONS',
    key: 'iS2',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'NET_SALES_AND_SERVICES',
    childrenId: [],
    name: 'corporate.companySnapshot.NET_SALES_AND_SERVICES',
    key: 'iS3',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'COST_OF_SALES',
    childrenId: [],
    name: 'corporate.companySnapshot.COST_OF_SALES',
    key: 'iS4',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'GROSS_PROFIT_FROM_OPERATING_ACTIVITIES',
    childrenId: [],
    name: 'corporate.companySnapshot.GROSS_PROFIT_FROM_OPERATING_ACTIVITIES',
    key: 'iS5',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'GENERAL_AND_ADMINISTRATIVE_EXPENSES',
    parentId: 'GROSS_PROFIT_FROM_OPERATING_ACTIVITIES',
    name: 'corporate.companySnapshot.GENERAL_AND_ADMINISTRATIVE_EXPENSES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS11',
  },
  {
    index: 'GAIN_FROM_JOINT_VENTURES',
    parentId: 'GROSS_PROFIT_FROM_OPERATING_ACTIVITIES',
    name: 'corporate.companySnapshot.GAIN_FROM_JOINT_VENTURES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS9',
  },
  {
    index: 'NET_PROFIT_FROM_OPERATING_ACTIVITIES',
    childrenId: ['OTHER_PROFIT', 'SHARES_OF_PROFIT_IN_ASSOCIATE'],
    name: 'corporate.companySnapshot.NET_PROFIT_FROM_OPERATING_ACTIVITIES',
    key: 'iS12',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'OTHER_PROFIT',
    parentId: 'NET_PROFIT_FROM_OPERATING_ACTIVITIES',
    name: 'corporate.companySnapshot.OTHER_PROFIT',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS13',
  },
  {
    index: 'SHARES_OF_PROFIT_IN_ASSOCIATE',
    parentId: 'NET_PROFIT_FROM_OPERATING_ACTIVITIES',
    name: 'corporate.companySnapshot.SHARES_OF_PROFIT_IN_ASSOCIATE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'PROFIT_BEFORE_TAX',
    childrenId: ['BUSINESS_INCOME_TAX'],
    name: 'corporate.companySnapshot.PROFIT_BEFORE_TAX',
    key: 'iS16',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'BUSINESS_INCOME_TAX',
    parentId: 'PROFIT_BEFORE_TAX',
    name: 'corporate.companySnapshot.BUSINESS_INCOME_TAX',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'iS17',
  },
  {
    index: 'NET_PROFIT_AFTER_TAX',
    childrenId: [],
    name: 'corporate.companySnapshot.NET_PROFIT_AFTER_TAX',
    key: 'iS20',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'MINORITY_INTERESTS',
    childrenId: [],
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    key: 'iS22',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'EQUITY_HOLDERS_OF_THE_PARENT',
    childrenId: [],
    name: 'corporate.companySnapshot.EQUITY_HOLDERS_OF_THE_PARENT',
    key: 'iS21',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorBalanceSheetCorp = [
  {
    index: 'CURRENT_ASSETS',
    childrenId: [
      'CASH_AND_CASCH_EQUIVALENTS',
      'SHORT_TERM_INVESTMENTS',
      'ACCOUNT_RECEIVABLE',
      'INVENTORIES',
      'OTHER_CURRENT_ASSETS',
    ],
    name: 'corporate.companySnapshot.CURRENT_ASSETS',
    key: 'bS1',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CASH_AND_CASCH_EQUIVALENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.CASH_AND_CASCH_EQUIVALENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS2',
  },
  {
    index: 'SHORT_TERM_INVESTMENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.SHORT_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS5',
  },
  {
    index: 'ACCOUNT_RECEIVABLE',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.ACCOUNT_RECEIVABLE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS9',
  },
  {
    index: 'INVENTORIES',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.INVENTORIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS18',
  },
  {
    index: 'OTHER_CURRENT_ASSETS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.OTHER_CURRENT_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS21',
  },
  {
    index: 'LONG_TERM_ASSETS',
    childrenId: [
      'LONG_TERM_TRADE_RECEIVABLES',
      'FIXED_ASSETS',
      'INVESTMENT_PROPERTIES',
      'LONG_TERM_INCOMPLETE_ASSETS',
      'LONG_TERM_INVESTMENTS',
      'OTHER_LONG_TERM_ASSETS',
      'GOODWILL',
    ],
    name: 'corporate.companySnapshot.LONG_TERM_ASSETS',
    key: 'bS27',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LONG_TERM_TRADE_RECEIVABLES',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_TRADE_RECEIVABLES',
    key: 'bS28',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'FIXED_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.FIXED_ASSETS',
    key: 'bS36',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'INVESTMENT_PROPERTIES',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.INVESTMENT_PROPERTIES',
    key: 'bS46',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'LONG_TERM_INCOMPLETE_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INCOMPLETE_ASSETS',
    key: 'bS49',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'LONG_TERM_INVESTMENTS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INVESTMENTS',
    key: 'bS52',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'OTHER_LONG_TERM_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.OTHER_LONG_TERM_ASSETS',
    key: 'bS58',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'GOODWILL',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.GOODWILL',
    key: 'bS63',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TOTAL_ASSETS',
    key: 'bS64',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LIABILITIES',
    name: 'corporate.companySnapshot.LIABILITIES',
    key: 'bS65',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CURRENT_LIABILITES',
    childrenId: [
      'TRADE_ACCOUNTS_PAYABLE',
      'SHORT_TERM_UNREALIZED_REVENUE',
      'SHORT_TERM_BORROWINGS',
    ],
    name: 'corporate.companySnapshot.CURRENT_LIABILITES',
    key: 'bS66',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'TRADE_ACCOUNTS_PAYABLE',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.TRADE_ACCOUNTS_PAYABLE',
    key: 'bS67',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'SHORT_TERM_UNREALIZED_REVENUE',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_UNREALIZED_REVENUE',
    key: 'bS74',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'SHORT_TERM_BORROWINGS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_BORROWINGS',
    key: 'bS76',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },

  {
    index: 'LONG_TERM_LIABILITES',
    childrenId: [
      'LONG_TERM_TRADE_PAYABLES',
      'UNREALIZED_REVENUE',
      'LONG_TERM_BORROWINGS',
    ],
    name: 'corporate.companySnapshot.LONG_TERM_LIABILITES',
    key: 'bS81',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'MINORITY_INTERESTS',
    parentId: 'LONG_TERM_LIABILITES',
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    key: 'bS82',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'UNREALIZED_REVENUE',
    parentId: 'LONG_TERM_LIABILITES',
    name: 'corporate.companySnapshot.UNREALIZED_REVENUE',
    key: 'bS87',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'LONG_TERM_BORROWINGS',
    parentId: 'LONG_TERM_LIABILITES',
    name: 'corporate.companySnapshot.LONG_TERM_BORROWINGS',
    key: 'bS89',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.OWNERS_EQUITY',
    key: 'bS95',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CAPITAL_AND_RESEARVES',
    childrenId: [
      'PAID_IN_CAPITAL',
      'SHARE_PREMIUM',
      'OWNERS_OTHER_CAPITAL',
      'UNDISTRIBUTED_EARNINGS',
      'MINIORITY_INTERESTS',
    ],
    name: 'corporate.companySnapshot.CAPITAL_AND_RESEARVES',
    key: 'bS96',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'PAID_IN_CAPITAL',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.PAID_IN_CAPITAL',
    key: 'bS97',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'SHARE_PREMIUM',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.SHARE_PREMIUM',
    key: 'bS100',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'OWNERS_OTHER_CAPITAL',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.OWNERS_OTHER_CAPITAL',
    key: 'bS102',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'UNDISTRIBUTED_EARNINGS',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.UNDISTRIBUTED_EARNINGS',
    key: 'bS109',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'MINIORITY_INTERESTS',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.MINIORITY_INTERESTS',
    key: 'bS112',
    level: TABLE_ROW_LEVEL.LEVEL_2,
  },
  {
    index: 'BUDGET_SOURCES_AND_OTHER_FUNDS',
    name: 'corporate.companySnapshot.BUDGET_SOURCES_AND_OTHER_FUNDS',
    key: 'bS115',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'TOTAL_RESOURCES',
    name: 'corporate.companySnapshot.TOTAL_RESOURCES',
    key: 'bS117',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorBalanceSheetBank = [
  {
    index: 'TOTAL_ASSETS',
    childrenId: [
      'CASH_AND_PRECIOUS_METALS',
      'BALANCES_WITH_THE_SBV',
      'PLACEMENTS_WITH_AND_LOANS_TO_OTHER_CREDIT_INSTITUTIONS',
      'TRADING_SECURITIES_NET',
      'DERIVATIVES_AND_OTHER_FINANCIAL_ASSETS',
      'LOANS_AND_ADVANCES_TO_CUSTOMERS_NET',
      'INVESTMENT_SECURITIES',
      'INVESTMENT_IN_OTHER_ENTITIES_AND_LONG_TERM_INVESTMENTS',
      'FIXED_ASSETS',
      'OTHER_ASSETS',
    ],
    name: 'corporate.companySnapshot.TOTAL_ASSETS',
    key: 'bS64',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CASH_AND_PRECIOUS_METALS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.CASH_AND_PRECIOUS_METALS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS2',
  },
  {
    index: 'BALANCES_WITH_THE_SBV',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.BALANCES_WITH_THE_SBV',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS151',
  },
  {
    index: 'PLACEMENTS_WITH_AND_LOANS_TO_OTHER_CREDIT_INSTITUTIONS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.PLACEMENTS_WITH_AND_LOANS_TO_OTHER_CREDIT_INSTITUTIONS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS152',
  },
  {
    index: 'TRADING_SECURITIES_NET',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TRADING_SECURITIES_NET',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS156',
  },
  {
    index: 'DERIVATIVES_AND_OTHER_FINANCIAL_ASSETS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.DERIVATIVES_AND_OTHER_FINANCIAL_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS157',
  },
  {
    index: 'LOANS_AND_ADVANCES_TO_CUSTOMERS_NET',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.LOANS_AND_ADVANCES_TO_CUSTOMERS_NET',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS158',
  },
  {
    index: 'INVESTMENT_SECURITIES',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.INVESTMENT_SECURITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS164',
  },
  {
    index: 'INVESTMENT_IN_OTHER_ENTITIES_AND_LONG_TERM_INVESTMENTS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.INVESTMENT_IN_OTHER_ENTITIES_AND_LONG_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS52',
  },
  {
    index: 'FIXED_ASSETS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.FIXED_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS36',
  },
  {
    index: 'OTHER_ASSETS',
    parentId: 'TOTAL_ASSETS',
    name: 'corporate.companySnapshot.OTHER_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS169',
  },
  {
    index: 'LIABILITITES_AND_SHAREHOLDERS_EQUITY',
    childrenId: [],
    name: 'corporate.companySnapshot.LIABILITITES_AND_SHAREHOLDERS_EQUITY',
    key: 'bS117',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'TOTAL_LIABILITIES',
    childrenId: [
      'DUE_TO_GOV_AND_BORROWING_FROM_SBV',
      'DEPOSITS_AND_BORROWINGS_FROM_OTHER_CREDIT_INSTITUTIONS_',
      'DEPOSITS_FROM_CUSTOMERS',
      'DERIVATIVES_AND_OTHER_FINANCIAL_LIABILITIES',
      'FUNDS_RECEVIED_FROM_GOV_INTERNATIONAL_AND_OTHER_INSTITUTIONS',
      'CONVERTIBLE_BONDS_CDS_AND_OTHER_VALUABLE_PAPERS_ISSUED',
      'OTHER_LIABILITIES',
    ],
    name: 'corporate.companySnapshot.TOTAL_LIABILITIES',
    key: 'bS65',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'DUE_TO_GOV_AND_BORROWING_FROM_SBV',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.DUE_TO_GOV_AND_BORROWING_FROM_SBV',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS174',
  },
  {
    index: 'DEPOSITS_AND_BORROWINGS_FROM_OTHER_CREDIT_INSTITUTIONS_',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.DEPOSITS_AND_BORROWINGS_FROM_OTHER_CREDIT_INSTITUTIONS_',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS175',
  },
  {
    index: 'DEPOSITS_FROM_CUSTOMERS',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.DEPOSITS_FROM_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS178',
  },
  {
    index: 'DERIVATIVES_AND_OTHER_FINANCIAL_LIABILITIES',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.DERIVATIVES_AND_OTHER_FINANCIAL_LIABILITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS179',
  },
  {
    index: 'FUNDS_RECEVIED_FROM_GOV_INTERNATIONAL_AND_OTHER_INSTITUTIONS',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.FUNDS_RECEVIED_FROM_GOV_INTERNATIONAL_AND_OTHER_INSTITUTIONS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS180',
  },
  {
    index: 'CONVERTIBLE_BONDS_CDS_AND_OTHER_VALUABLE_PAPERS_ISSUED',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.CONVERTIBLE_BONDS_CDS_AND_OTHER_VALUABLE_PAPERS_ISSUED',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS181',
  },
  {
    index: 'OTHER_LIABILITIES',
    parentId: 'TOTAL_LIABILITIES',
    name: 'corporate.companySnapshot.OTHER_LIABILITIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS182',
  },
  {
    index: 'SHAREHOLDERS_EQUITY',
    childrenId: [
      'CAPITAL',
      'PAID_IN_CAPITAL',
      'FUND_FOR_BASIC_CONSTRUCTION',
      'CAPITAL_SURPLUS',
      'TREASURY_SHARES',
      'PREFERRED_SHARES',
      'OWNERS_OTHER_CAPITAL',
      'RETAINED_EARNINGS',
    ],
    name: 'corporate.companySnapshot.SHAREHOLDERS_EQUITY',
    key: 'bS95',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CAPITAL',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS186',
  },
  {
    index: 'PAID_IN_CAPITAL',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.PAID_IN_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS98',
  },
  {
    index: 'FUND_FOR_BASIC_CONSTRUCTION',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.FUND_FOR_BASIC_CONSTRUCTION',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS113',
  },
  {
    index: 'CAPITAL_SURPLUS',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.CAPITAL_SURPLUS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS100',
  },
  {
    index: 'TREASURY_SHARES',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.TREASURY_SHARES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS103',
  },
  {
    index: 'PREFERRED_SHARES',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.PREFERRED_SHARES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS99',
  },
  {
    index: 'OWNERS_OTHER_CAPITAL',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.OWNERS_OTHER_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS102',
  },
  {
    index: 'RETAINED_EARNINGS',
    parentId: 'SHAREHOLDERS_EQUITY',
    name: 'corporate.companySnapshot.RETAINED_EARNINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS109',
  },
]

export const indicatorBalanceSheetIns = [
  {
    index: 'CURRENT_ASSETS',
    childrenId: [
      'CASH_AND_CASCH_EQUIVALENTS',
      'SHORT_TERM_INVESTMENTS',
      'ACCOUNT_RECEIVABLE',
      'INVENTORIES',
      'OTHER_CURRENT_ASSETS',
      'REINSURANCE_ASSETS',
    ],
    name: 'corporate.companySnapshot.CURRENT_ASSETS',
    key: 'bS1',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CASH_AND_CASCH_EQUIVALENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.CASH_AND_CASCH_EQUIVALENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS2',
  },
  {
    index: 'SHORT_TERM_INVESTMENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.SHORT_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS5',
  },
  {
    index: 'ACCOUNT_RECEIVABLE',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.ACCOUNT_RECEIVABLE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS9',
  },
  {
    index: 'INVENTORIES',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.INVENTORIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS18',
  },
  {
    index: 'OTHER_CURRENT_ASSETS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.OTHER_CURRENT_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS21',
  },
  {
    index: 'REINSURANCE_ASSETS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.REINSURANCE_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS217',
  },
  {
    index: 'LOANS_AND_ADVANCES_TO_CUSTOMERS',
    childrenId: [],
    name: 'corporate.companySnapshot.LOANS_AND_ADVANCES_TO_CUSTOMERS',
    key: '',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LONG_TERM_ASSETS',
    childrenId: [
      'FIXED_ASSETS',
      'CONSTRUCTION_IN_PROGRESS',
      'INVESTMENT_PROPERTIES',
      'LONG_TERM_INCOMPLETE_ASSETS',
      'LONG_TERM_INVESTMENTS',
      'OTHER_LONG_TERM_ASSETS',
      'GOODWILL',
    ],
    name: 'corporate.companySnapshot.LONG_TERM_ASSETS',
    key: 'bS27',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'FIXED_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.FIXED_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS36',
  },
  {
    index: 'CONSTRUCTION_IN_PROGRESS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.CONSTRUCTION_IN_PROGRESS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS51',
  },
  {
    index: 'INVESTMENT_PROPERTIES',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.INVESTMENT_PROPERTIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'LONG_TERM_INCOMPLETE_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INCOMPLETE_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS49',
  },
  {
    index: 'LONG_TERM_INVESTMENTS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS52',
  },
  {
    index: 'OTHER_LONG_TERM_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.OTHER_LONG_TERM_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS58',
  },
  {
    index: 'GOODWILL',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.GOODWILL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS63',
  },
  {
    index: 'TOTAL_ASSETS',
    childrenId: [],
    name: 'corporate.companySnapshot.TOTAL_ASSETS',
    key: 'bS64',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LIABILITIES',
    childrenId: [],
    name: 'corporate.companySnapshot.LIABILITIES',
    key: 'bS65',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CURRENT_LIABILITES',
    childrenId: [],
    name: 'corporate.companySnapshot.CURRENT_LIABILITES',
    key: 'bS66',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'SHORT_TERM_DEBTS_AND_BORROWINGS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_DEBTS_AND_BORROWINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS76',
  },
  {
    index: 'TRADE_ACCOUNTS_PAYABLE',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.TRADE_ACCOUNTS_PAYABLE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS67',
  },
  {
    index: 'ADVANCES_FROM_CUSTOMERS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.ADVANCES_FROM_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS68',
  },
  {
    index: 'SHORT_TERM_UNREALIZED_REVENUE',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_UNREALIZED_REVENUE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS74',
  },
  {
    index: 'UNEARED_COMMISSON_INCOME',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.UNEARED_COMMISSON_INCOME',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS228',
  },
  {
    index: 'AMOUNT_DUE_TO_CUSTOMERS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.AMOUNT_DUE_TO_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 0,
  },
  {
    index: 'INSURANCE_RESERVE',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.INSURANCE_RESERVE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS229',
  },
  {
    index: 'LONG_TERM_BORROWINGS_AND_LIABILITIES',
    childrenId: [
      'LONG_TERM_TRADE_PAYABLES',
      'LONG_TERM_ADVANCES_FROM_CUSTOMERS',
      'LONG_TERM_BORROWINGS',
      'DEFFERED_REVENUE',
    ],
    name: 'corporate.companySnapshot.LONG_TERM_BORROWINGS_AND_LIABILITIES',
    key: 'bS81',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LONG_TERM_TRADE_PAYABLES',
    parentId: 'LONG_TERM_BORROWINGS_AND_LIABILITIES',
    name: 'corporate.companySnapshot.LONG_TERM_TRADE_PAYABLES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS82',
  },
  {
    index: 'LONG_TERM_ADVANCES_FROM_CUSTOMERS',
    parentId: 'LONG_TERM_BORROWINGS_AND_LIABILITIES',
    name: 'corporate.companySnapshot.LONG_TERM_ADVANCES_FROM_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: '',
  },
  {
    index: 'LONG_TERM_BORROWINGS',
    parentId: 'LONG_TERM_BORROWINGS_AND_LIABILITIES',
    name: 'corporate.companySnapshot.LONG_TERM_BORROWINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS89',
  },
  {
    index: 'DEFFERED_REVENUE',
    parentId: 'LONG_TERM_BORROWINGS_AND_LIABILITIES',
    name: 'corporate.companySnapshot.DEFFERED_REVENUE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS87',
  },
  {
    index: 'OWNERS_EQUITY',
    childrenId: [
      'EQUITY',
      'SHARE_CAPITAL',
      'ADDITIONAL_PAID_IN_CAPITAL',
      'OTHER_RESERVES',
      'MINORITY_INTERESTS',
      'OTHER_CAPITAL_FUNDS',
      'MINIORITY_INTERESTS',
    ],
    name: 'corporate.companySnapshot.OWNERS_EQUITY',
    key: 'bS95',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'EQUITY',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.EQUITY',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS96',
  },
  {
    index: 'SHARE_CAPITAL',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.SHARE_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS100',
  },
  {
    index: 'ADDITIONAL_PAID_IN_CAPITAL',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.ADDITIONAL_PAID_IN_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS97',
  },
  {
    index: 'OTHER_RESERVES',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.OTHER_RESERVES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS103',
  },
]

export const indicatorBalanceSheetIns1 = [
  {
    index: 'MINORITY_INTERESTS',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: '',
  },
  {
    index: 'OTHER_CAPITAL_FUNDS',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.OTHER_CAPITAL_FUNDS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS108',
  },
  {
    index: 'MINIORITY_INTERESTS',
    parentId: 'OWNERS_EQUITY',
    name: 'corporate.companySnapshot.MINIORITY_INTERESTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS112',
  },
  {
    index: 'LIABILITIES_AND_SHAREHOLDERS_EQUITIES',
    childrenId: [],
    name: 'corporate.companySnapshot.LIABILITIES_AND_SHAREHOLDERS_EQUITIES',
    key: 'bS117',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorBalanceSheetSec = [
  {
    index: 'CURRENT_ASSETS',
    childrenId: [
      'CASH_AND_CASCH_EQUIVALENTS',
      'SHORT_TERM_INVESTMENTS',
      'ACCOUNT_RECEIVABLE',
      'INVENTORIES',
      'OTHER_CURRENT_ASSETS',
    ],
    name: 'corporate.companySnapshot.CURRENT_ASSETS',
    key: 'bS1',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CASH_AND_CASCH_EQUIVALENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.CASH_AND_CASCH_EQUIVALENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS2',
  },
  {
    index: 'SHORT_TERM_INVESTMENTS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.SHORT_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS247',
  },
  {
    index: 'ACCOUNT_RECEIVABLE',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.ACCOUNT_RECEIVABLE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS9',
  },
  {
    index: 'INVENTORIES',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.INVENTORIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: '',
  },
  {
    index: 'OTHER_CURRENT_ASSETS',
    parentId: 'CURRENT_ASSETS',
    name: 'corporate.companySnapshot.OTHER_CURRENT_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS21',
  },
  {
    index: 'LONG_TERM_ASSETS',
    childrenId: [
      'LONG_TERM_TRADE_RECEIVABLES',
      'FIXED_ASSETS',
      'INVESTMENT_PROPERTIES',
      'LONG_TERM_INCOMPLETE_ASSETS',
      'LONG_TERM_INVESTMENTS',
      'OTHER_LONG_TERM_ASSETS',
      'GOODWILL',
    ],
    name: 'corporate.companySnapshot.LONG_TERM_ASSETS',
    key: 'bS27',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LONG_TERM_TRADE_RECEIVABLES',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_TRADE_RECEIVABLES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS28',
  },
  {
    index: 'FIXED_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.FIXED_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS36',
  },
  {
    index: 'INVESTMENT_PROPERTIES',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.INVESTMENT_PROPERTIES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS46',
  },
  {
    index: 'LONG_TERM_INCOMPLETE_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INCOMPLETE_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS49',
  },
  {
    index: 'LONG_TERM_INVESTMENTS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.LONG_TERM_INVESTMENTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS52',
  },
  {
    index: 'OTHER_LONG_TERM_ASSETS',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.OTHER_LONG_TERM_ASSETS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS58',
  },
  {
    index: 'GOODWILL',
    parentId: 'LONG_TERM_ASSETS',
    name: 'corporate.companySnapshot.GOODWILL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS63',
  },
  {
    index: 'TOTAL_ASSETS',
    childrenId: [],
    name: 'corporate.companySnapshot.TOTAL_ASSETS',
    key: 'bS64',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LIABILITIES',
    childrenId: [],
    name: 'corporate.companySnapshot.LIABILITIES',
    key: 'bS65',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CURRENT_LIABILITES',
    childrenId: [
      'SHORT_TERM_BORROWINGS',
      'SHORT_TERM_TRADE_PAYABLES',
      'ADVANCES_FROM_CUSTOMERS',
    ],
    name: 'corporate.companySnapshot.CURRENT_LIABILITES',
    key: 'bS66',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'SHORT_TERM_BORROWINGS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_BORROWINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS275',
  },
  {
    index: 'SHORT_TERM_TRADE_PAYABLES',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.SHORT_TERM_TRADE_PAYABLES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS67',
  },
  {
    index: 'ADVANCES_FROM_CUSTOMERS',
    parentId: 'CURRENT_LIABILITES',
    name: 'corporate.companySnapshot.ADVANCES_FROM_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS76',
  },
  {
    index: 'SHORT_TERM_UNREALIZED_REVENUE',
    childrenId: [
      'LONG_TERM_LIABILITES',
      'LONG_TERM_TRADE_PAYABLES',
      'LONG_TERM_ADVANCES_FROM_CUSTOMERS',
      'LONG_TERM_BORROWINGS',
      'DEFFERED_REVENUE',
    ],
    name: 'corporate.companySnapshot.SHORT_TERM_UNREALIZED_REVENUE',
    key: 'bS68',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'LONG_TERM_LIABILITES',
    parentId: 'SHORT_TERM_UNREALIZED_REVENUE',
    name: 'corporate.companySnapshot.LONG_TERM_LIABILITES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS81',
  },
  {
    index: 'LONG_TERM_TRADE_PAYABLES',
    parentId: 'SHORT_TERM_UNREALIZED_REVENUE',
    name: 'corporate.companySnapshot.LONG_TERM_TRADE_PAYABLES',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS82',
  },
  {
    index: 'LONG_TERM_ADVANCES_FROM_CUSTOMERS',
    parentId: 'SHORT_TERM_UNREALIZED_REVENUE',
    name: 'corporate.companySnapshot.LONG_TERM_ADVANCES_FROM_CUSTOMERS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS87',
  },
  {
    index: 'LONG_TERM_BORROWINGS',
    parentId: 'SHORT_TERM_UNREALIZED_REVENUE',
    name: 'corporate.companySnapshot.LONG_TERM_BORROWINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS89',
  },
  {
    index: 'DEFFERED_REVENUE',
    parentId: 'SHORT_TERM_UNREALIZED_REVENUE',
    name: 'corporate.companySnapshot.DEFFERED_REVENUE',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS87',
  },
  {
    index: 'OWNERS_EQUITY_',
    childrenId: [],
    name: 'corporate.companySnapshot.OWNERS_EQUITY_',
    key: 'bS95',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'CAPITAL_AND_RESEARVES',
    childrenId: [
      'PAID_IN_CAPITAL',
      'SHARE_PREMIUM',
      'OWNERS_OTHER_CAPITAL',
      'UNDISTRIBUTED_EARNINGS',
      'MINIORITY_INTERESTS',
    ],
    name: 'corporate.companySnapshot.CAPITAL_AND_RESEARVES',
    key: 'bS96',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'PAID_IN_CAPITAL',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.PAID_IN_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS97',
  },
  {
    index: 'SHARE_PREMIUM',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.SHARE_PREMIUM',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS100',
  },
  {
    index: 'OWNERS_OTHER_CAPITAL',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.OWNERS_OTHER_CAPITAL',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS102',
  },
  {
    index: 'UNDISTRIBUTED_EARNINGS',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.UNDISTRIBUTED_EARNINGS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS109',
  },
  {
    index: 'MINIORITY_INTERESTS',
    parentId: 'CAPITAL_AND_RESEARVES',
    name: 'corporate.companySnapshot.MINIORITY_INTERESTS',
    level: TABLE_ROW_LEVEL.LEVEL_2,
    key: 'bS112',
  },
  {
    index: 'BUDGET_SOURCES_AND_OTHER_FUNDS',
    childrenId: [],
    name: 'corporate.companySnapshot.BUDGET_SOURCES_AND_OTHER_FUNDS',
    key: 'bS114',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'TOTAL_RESOURCES',
    childrenId: [],
    name: 'corporate.companySnapshot.TOTAL_RESOURCES',
    key: 'bS117',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
  {
    index: 'MINORITY_INTERESTS',
    childrenId: [],
    name: 'corporate.companySnapshot.MINORITY_INTERESTS',
    key: '',
    level: TABLE_ROW_LEVEL.LEVEL_1,
  },
]

export const indicatorFinancialRatiosCorp = [
  {
    index: 'PE',
    name: 'corporate.companySnapshot.PE',
    key: 'rtD0022',
    isFormat: true,
  },
  {
    index: 'PB',
    name: 'corporate.companySnapshot.PB',
    key: 'rtD0019',
    isFormat: true,
  },
  {
    index: 'EPS',
    name: 'corporate.companySnapshot.EPS',
    key: 'rtD0011',
    isFormatInteger: true,
  },
  {
    index: 'BVPS',
    name: 'corporate.companySnapshot.BVPS',
    key: 'rtD0007',
    isFormatInteger: true,
  },
  {
    index: 'GROSS_MARGIN',
    name: 'corporate.companySnapshot.GROSS_MARGIN',
    key: 'rT0050',
    isFormatPercent: true,
  },
  {
    index: 'EBIT_MARGIN',
    name: 'corporate.companySnapshot.EBIT_MARGIN',
    key: 'rT0052',
    isFormatPercent: true,
  },
  {
    index: 'EBITDA_MARGIN',
    name: 'corporate.companySnapshot.EBITDA_MARGIN',
    key: 'rT0051',
    isFormatPercent: true,
  },
  {
    index: 'NET_MARGIN',
    name: 'corporate.companySnapshot.NET_MARGIN',
    key: 'rT0054',
    isFormatPercent: true,
  },
  {
    index: 'ROE',
    name: 'corporate.companySnapshot.ROE',
    key: 'rT0060',
    isFormatPercent: true,
  },
  {
    index: 'ROA',
    name: 'corporate.companySnapshot.ROA',
    key: 'rT0059',
    isFormatPercent: true,
  },
  {
    index: 'CURRENT_RATIO',
    name: 'corporate.companySnapshot.CURRENT_RATIO',
    key: 'rT0020',
    isFormat: true,
  },
  {
    index: 'QUICK_RATIO',
    name: 'corporate.companySnapshot.QUICK_RATIO',
    key: 'rT0019',
    isFormat: true,
  },
  {
    index: 'TOTAL_LIABILITIES_TOTAL_ASSETS',
    name: 'corporate.companySnapshot.TOTAL_LIABILITIES_TOTAL_ASSETS',
    key: 'rT0033',
    isFormat: true,
  },
  {
    index: 'TOTAL_DEBTS_EQUITY',
    name: 'corporate.companySnapshot.TOTAL_DEBTS_EQUITY',
    key: 'rT0034',
    isFormat: true,
  },
]

export const indicatorFinancialRatiosBank = [
  {
    index: 'PE',
    name: 'corporate.companySnapshot.PE',
    key: 'rtD0022',
    isFormat: true,
  },
  {
    index: 'PB',
    name: 'corporate.companySnapshot.PB',
    key: 'rtD0019',
    isFormat: true,
  },
  {
    index: 'EPS',
    name: 'corporate.companySnapshot.EPS',
    key: 'rtD0011',
    isFormatInteger: true,
  },
  {
    index: 'BVPS',
    name: 'corporate.companySnapshot.BVPS',
    key: 'rtD0007',
    isFormatInteger: true,
  },
  {
    index: 'NET_INTEREST_MARGIN',
    name: 'corporate.companySnapshot.NET_INTEREST_MARGIN',
    key: 'rT0285',
    isFormatPercent: true,
  },
  {
    index: 'CIR',
    name: 'corporate.companySnapshot.CIR',
    key: 'rT0252',
    isFormatPercent: true,
  },
  {
    index: 'NON_INTEREST_INCOME',
    name: 'corporate.companySnapshot.NON_INTEREST_INCOME',
    key: 'rT0251',
    isFormatPercent: true,
  },
  {
    index: 'ROA_PRE_PROVISION',
    name: 'corporate.companySnapshot.ROA_PRE_PROVISION',
    key: 'rT0288',
    isFormatPercent: true,
  },
  {
    index: 'ROE',
    name: 'corporate.companySnapshot.ROE',
    key: 'rT0060',
    isFormatPercent: true,
  },
  {
    index: 'ROA',
    name: 'corporate.companySnapshot.ROA',
    key: 'rT0059',
    isFormatPercent: true,
  },
  {
    index: 'NPL',
    name: 'corporate.companySnapshot.NPL',
    key: 'rT0236',
    isFormatPercent: true,
  },
  {
    index: 'LOAN_LOS_REVERVES_LOANS',
    name: 'corporate.companySnapshot.LOAN_LOS_REVERVES_LOANS',
    key: 'rT0234',
    isFormatPercent: true,
  },
  {
    index: 'LOANS_DEPOSIT_RATIO',
    name: 'corporate.companySnapshot.LOANS_DEPOSIT_RATIO',
    key: 'rT0277',
    isFormatPercent: true,
  },
  {
    index: 'TOTAL_DEBTS',
    name: 'corporate.companySnapshot.TOTAL_DEBTS',
    key: 'deb',
    isFormatPercent: true,
  },
]

export const indicatorKeyFinancialCorp = [
  {
    index: 'MARKET_CAP',
    name: 'corporate.companySnapshot.MARKET_CAP',
    key: 'rtD0005',
    isFormatBil: true,
  },
  {
    index: 'PE',
    name: 'corporate.companySnapshot.PE',
    key: 'rtD0022',
    isFormat: true,
  },
  {
    index: 'ROA',
    name: 'corporate.companySnapshot.ROA',
    key: 'rT0059',
    isFormatPercent: true,
  },
  {
    index: 'ROE',
    name: 'corporate.companySnapshot.ROE',
    key: 'rT0060',
    isFormatPercent: true,
  },
  {
    index: 'TOTAL_ASSETS1',
    name: 'corporate.companySnapshot.TOTAL_ASSETS1',
    key: 'bS64',
    isFormatBil: true,
  },
  {
    index: 'OWNERS_EQUITY1',
    name: 'corporate.companySnapshot.OWNERS_EQUITY1',
    key: 'bS95',
    isFormatBil: true,
  },
  {
    index: 'NET_PROFIT_GROWTH',
    name: 'corporate.companySnapshot.NET_PROFIT_GROWTH',
    key: 'rT0161',
    isFormatPercent: true,
  },
]

export const indicatorKeyFinancialBank = [
  {
    index: 'MARKET_CAP',
    name: 'corporate.companySnapshot.MARKET_CAP',
    key: 'rtD0005',
    isFormatBil: true,
  },
  {
    index: 'PE',
    name: 'corporate.companySnapshot.PE',
    key: 'rtD0022',
    isFormat: true,
  },
  {
    index: 'ROA',
    name: 'corporate.companySnapshot.ROA',
    key: 'rT0059',
    isFormatPercent: true,
  },
  {
    index: 'ROE',
    name: 'corporate.companySnapshot.ROE',
    key: 'rT0060',
    isFormatPercent: true,
  },
  {
    index: 'TOTAL_ASSETS1',
    name: 'corporate.companySnapshot.TOTAL_ASSETS1',
    key: 'bS64',
    isFormatBil: true,
  },
  {
    index: 'LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1',
    name: 'corporate.companySnapshot.LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_1',
    key: 'bS158',
    isFormatBil: true,
  },
  {
    index: 'LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_2',
    name: 'corporate.companySnapshot.LOANS_AND_ADVANCES_TO_CUSTOMERS_NET_2',
    key: 'rT0277',
    isFormatPercent: true,
  },
]
