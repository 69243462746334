export const advancedSearch = {
  ADVANCED_SEARCH: 'Tìm kiếm nâng cao',
  NO_MATCHED_RESULTS: 'Không có công ty, ngành và tính năng phù hợp cho',
  MORE_RESULTS_FOR: 'Kết quả khác cho',
  NEW_SEARCH_FOR: 'Tìm kiếm tin tức cho',
  COMPANIES: 'Công ty',
  MORE_COMPANIES: 'Các công ty khác ...',
  BOND: 'Trái phiếu',
  MORE_BONDS: 'Các trái phiếu khác ...',
  DERIVATIVE: 'Phái sinh',
  MORE_DERIVATIVES: 'Các phái sinh khác ...',
  FUNCTION: 'Tính năng',
  MORE_FUNCTIONS: 'Các tính năng khác ...',
  INDEX: 'Chỉ số',
  MORE_INDICES: 'Các chỉ số khác ...',
  SECTOR: 'Ngành',
  MORE_SECTOR: 'Các ngành khác ...',
  ICB_LEVEL: 'ICB Level',
}
