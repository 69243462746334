import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  removeAccents,
  removeSpecialCharacter,
} from '../../../../../utils/Search'
import {
  LOADING_TYPE,
  selectIndicatorsByCategory,
  selectLoading,
} from '../../store/slice'
import style from './index.module.css'

const SCROLL_DIV_ID = 'SCROLL_METRIC'

const Metric = ({ category, indicator, setIndicator, setDate, keyword }) => {
  const loading = useSelector(selectLoading(LOADING_TYPE.INDICATORS))
  const indicators = useSelector(selectIndicatorsByCategory(category))
  const timeZone = UseTimeZone()
  const deepestIndicators = useSelector(selectIndicatorsByCategory())

  const getDeepestIndicators = useMemo(() => {
    return Object.values(deepestIndicators).reduce(
      (obj, indicators) => [...obj, ...Object.values(indicators)],
      [],
    )
  }, [JSON.stringify(deepestIndicators)])

  const handleClick = (item) => {
    setIndicator(item.indicatorId)
    setDate({
      date: formatDateTime(item.date, FORMAT.DATE, undefined, timeZone),
      year: item.year,
      quarter: item.quarter,
    })
  }

  useEffect(() => {
    setTimeout(() => {
      const scrollTree = document.querySelector(
        `#${SCROLL_DIV_ID} .scrollbars div:first-child`,
      )
      const activeIndicator = scrollTree?.querySelector(`[id="${indicator}"]`)

      if (
        scrollTree &&
        activeIndicator &&
        activeIndicator.offsetTop + activeIndicator.offsetHeight >
          scrollTree.offsetHeight
      ) {
        scrollTree.scrollTo({
          top: activeIndicator.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }, 100)
  }, [])

  const renderIndicatorList = () => {
    let listIndicators = []

    if (category) {
      listIndicators = Object.values(indicators)
    }

    if (keyword.trim()) {
      listIndicators = getDeepestIndicators.filter((indicator) =>
        removeSpecialCharacter(removeAccents(indicator.indicatorName))
          ?.toLowerCase()
          .includes(
            removeSpecialCharacter(removeAccents(keyword)).trim().toLowerCase(),
          ),
      )
    }

    return listIndicators.map((item, index) => (
      <div
        className={style.indicator}
        key={`${item.indicatorId}_${index}`}
        id={item.indicatorId}
        onClick={() => handleClick(item)}
      >
        <div
          className={style.indicatorRadio}
          style={{ opacity: item.indicatorId === indicator ? 1 : 0 }}
        >
          <input
            type="radio"
            className={`radiobox radiobox2 cursor-pointer`}
            checked
            readOnly
          />
        </div>
        <div
          className={style.indicatorName}
          style={{
            color: item.indicatorId === indicator ? '#2f9aee' : 'inherit',
          }}
        >
          <TextEllipsis isI18n={false} text={item.indicatorName} />
        </div>
      </div>
    ))
  }

  return (
    <div className={style.metric}>
      <div className={style.header}>
        <Span style={{ opacity: 0.4, display: 'block', lineHeight: '20px' }}>
          <Translate value="Metric" />
        </Span>
      </div>
      <div className={style.metricList} id={SCROLL_DIV_ID}>
        <ScrollComponent>
          {loading ? (
            <Loading />
          ) : keyword || (indicators && Object.values(indicators).length) ? (
            renderIndicatorList()
          ) : (
            <NoData />
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default Metric
