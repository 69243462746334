import moment from 'moment'
import { keyBy } from '../../../utils/Common'
import { AUDIT_CONSTANTS, AUDIT_DATA, VIEW_BY_CONSTANTS } from './constants'

export const getDataTitle = (timeFilter, item, separator = '/') => {
  switch (timeFilter) {
    case VIEW_BY_CONSTANTS.YEARLY:
      return item.year
    case VIEW_BY_CONSTANTS.QUARTERLY:
      return `Q${item.quarter < 5 ? item.quarter : 4}${separator}${item.year}`
    case VIEW_BY_CONSTANTS.NINE_MONTHS:
      return `9M${separator}${item.year}`
    case VIEW_BY_CONSTANTS.SIX_MONTHS:
      return `6M${separator}${item.year}`
    default:
      return item.year
  }
}

export const formatFinancialData = (state, action) => {
  state.loading = false
  const groupColumns = []
  const indicators = Object.values(state.indicatorById)
  const indicatorKeys = indicators.map((item) => item.key)
  const timeFilter = action.meta.arg.TimeFilter

  action.payload
    .sort((a, b) => {
      if (a.quarter && b.quarter) {
        return (
          moment().set('year', b.year).quarter(b.quarter).unix() -
          moment().set('year', a.year).quarter(a.quarter).unix()
        )
      } else {
        return (
          moment().set('year', b.year).unix() -
          moment().set('year', a.year).unix()
        )
      }
    })
    .filter((_, index) => index < action.meta.arg.NumberOfPeriod)
    .reverse()
    .forEach((item) => {
      const formatItem = Object.keys(item).reduce((obj, key) => {
        if (indicatorKeys.includes(key.toUpperCase())) {
          return { ...obj, [key.toUpperCase()]: item[key] }
        }
        return { ...obj, [key]: item[key] }
      }, {})
      groupColumns.push({
        key:
          timeFilter === VIEW_BY_CONSTANTS.QUARTERLY
            ? `columnQ${formatItem.quarter}/${formatItem.year}`
            : `column${formatItem.year}`,
        title: getDataTitle(timeFilter, formatItem),
        year: formatItem.year,
        quarter: formatItem.quarter,
      })
      indicators.forEach((element) => {
        const key =
          timeFilter === VIEW_BY_CONSTANTS.QUARTERLY
            ? `columnQ${formatItem.quarter}/${formatItem.year}`
            : `column${formatItem.year}`
        if (Object.values(AUDIT_CONSTANTS).includes(element.index)) {
          element[key] = formatItem[element.key] || formatItem[element.en_key]
        } else {
          element[key] = formatItem[element.key]
        }
      })
    })
  state.groupColumns = [{ key: 'name', title: '' }, ...groupColumns]
}

const formatIndicatorItem = (indicators, indicator, parent = { level: 0 }) => {
  const isFormatValToPercent = indicator.formating.includes('%')
  const childrenId = indicators
    .filter((v) => v.parentId === indicator.fieldName)
    .map((v) => v.indicatorMappingId)
  const hasChild = childrenId.length !== 0
  const childTable = {
    index: indicator.indicatorMappingId,
    parentIndex: parent.index,
    parentId: parent.index,
    childrenId: childrenId,
    name: indicator.indicator,
    key: indicator.fieldName,
    isFormatValue: {
      formatValue: 1 / indicator.multiplier,
    },
    level: parent.level + 1,
    isFormatValToPercent: isFormatValToPercent,
    isCollapse: hasChild,
    style: indicator.style || '',
    fixUnit: indicator.fixUnit || false,
  }
  const childItem = hasChild
    ? getChild(indicators, childTable, indicator.fieldName)
    : []
  return [childTable, ...childItem]
}

const getChild = (indicators, parent, parentId) => {
  let childTableArr = []
  indicators.forEach((indicator) => {
    if (indicator.parentId === parentId) {
      const child = formatIndicatorItem(indicators, indicator, parent)
      childTableArr = [...childTableArr, ...child]
    }
  })
  return childTableArr
}

const formatIndicatorMapping = (indicators) => {
  let ids = []
  let tableConstants = []
  indicators
    .filter((indicator) => !indicator.parentId)
    .forEach((indicator) => {
      const child = formatIndicatorItem(indicators, indicator)
      ids = [...ids, ...child.map((v) => v.index)]
      tableConstants = [...tableConstants, ...child]
    })
  return { ids, tableConstants }
}

export const setFormatIndicatorMapping = (state) => {
  const { ids, tableConstants } = formatIndicatorMapping(
    state.indicators.filter(
      (indicator) => indicator.statement === state.filters.statement,
    ),
  )
  // const auditStatus = {
  //   index: 'audit_status',
  //   name: 'corporate.financialData.financialStatement.AUDIT_STATUS',
  //   key: 'auditStatus',
  //   en_key: 'en_AuditStatus',
  //   level: 0,
  //   style: '',
  // }
  state.tableConstants = [...AUDIT_DATA, ...tableConstants]
  state.ids = [...Object.values(AUDIT_CONSTANTS), ...ids]
  state.indicatorById = keyBy([...AUDIT_DATA, ...tableConstants], 'index')
  state.indicatorLoading = false
}

export const getStyle = (item) => {
  return {
    // fontWeight: item.style.includes('B') && 'bold',
    fontStyle: item.style.includes('I') ? 'italic' : '',
    textDecoration: item.style.includes('U') ? 'underline' : '',
  }
}
