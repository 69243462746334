import TopReleaseProxy from '../../../../proxies/bond/overview/topissues/IssuesPlan'
import { ServiceBase } from '../../../ServiceBase'

export class TopReleaseService extends ServiceBase {
  getDataTop(params) {
    return this.getData(() => TopReleaseProxy.getDataTopRealease(params, true))
  }
  getExportTop(params) {
    return this.getFileDownload(() =>
      TopReleaseProxy.exportDataTopRealease(params),
    )
  }
  getDataTopIssuerCirculate(params) {
    return this.getData(() => TopReleaseProxy.getDataTopCirculate(params, true))
  }
  getExportTopCirculate(params) {
    return this.getFileDownload(() =>
      TopReleaseProxy.exportDataTopCirculate(params),
    )
  }
}

export default new TopReleaseService()
