import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Table } from '../../../../common/table'
import { EMPTY_VALUE } from '../../../../constants/Common'
import {
  changePage,
  selectCurrentPage,
  selectFilingIds,
  selectFilingValue,
  selectLoadingCategory,
  selectLoadingFiling,
  selectReCalcWidths,
  selectTotalPage,
} from '../store/slice'
import { indicators, QUARTERS } from './constant'
import style from './index.module.css'

const FilingTable = () => {
  const dispatch = useDispatch()

  const filingIds = useSelector(selectFilingIds)
  const loadingFilings = useSelector(selectLoadingFiling)
  const loadingCategory = useSelector(selectLoadingCategory)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const currentPage = useSelector(selectCurrentPage)
  const totalPage = useSelector(selectTotalPage)

  const loading = loadingFilings || loadingCategory

  const handleChangePage = (page) => {
    dispatch(changePage(page))
  }

  return (
    <div className={style.root}>
      <Table
        ids={filingIds}
        getDataFromRedux={selectFilingValue}
        isLoading={loading}
        schema={Object.keys(indicators).map((key, index) => {
          const colId = indicators[key]
          const title = `corporate.newsEvents.filing.indicators.${key}`

          return {
            colId,
            title,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
            },
            tdStyle: {
              whiteSpace: 'nowrap',
            },
            render: (val) => {
              switch (colId) {
                case indicators.FILING_DATE:
                  return <DateTimeSpan date={val} />
                case indicators.QUARTER:
                  return QUARTERS.includes(val) ? val : ''
                case indicators.DOWNLOAD:
                  return val && val !== EMPTY_VALUE ? (
                    <a href={val} target="_blank" rel="noopener noreferrer">
                      link
                    </a>
                  ) : null
                default:
                  return val
              }
            },
          }
        })}
        isLargeHead={true}
        hasFooter={false}
        reCalcWidths={reCalcWidths}
        hasPagination={true}
        currentPage={currentPage}
        totalPage={totalPage}
        handleChangePage={handleChangePage}
      />
    </div>
  )
}

export default FilingTable
