import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { TABLE_ROW_LEVEL } from '../filter/constants'
export const FirstColumnCell = ({
  val,
  rowId,
  className,
  style,
  selectItem,
}) => {
  const tdRef = useRef()
  const item = useSelector(selectItem(rowId))

  if (item?.level === TABLE_ROW_LEVEL.LEVEL_1) {
    return (
      <td className={className} style={style} ref={tdRef}>
        <div style={{ fontWeight: 600, fontSize: 9 }}>
          <Translate value={val} />
        </div>
      </td>
    )
  }

  return (
    <td className={className} style={style} ref={tdRef}>
      <div style={{ paddingLeft: 8, fontSize: 9 }}>
        <Translate value={val} />
      </div>
    </td>
  )
}

FirstColumnCell.propTypes = {
  val: PropTypes.string.isRequired,
  rowId: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
}
