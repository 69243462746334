import { COMPONENT } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import PanelFilter from '../../common/panelFilter'
import { withWrapper } from '../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import Filter from './filter'
import News from './news'
import UpcomingEvents from './upcomingEvents'

const LARGE_SCREEN_BREAKPOINT = 1600 - 16 //viewport padding = 16

const MAP_KEY = {
  FILTER: 'FILTER',
  CALENDAR: 'CALENDAR',
  EVENTS: 'EVENTS',
}

const FILTER_WIDTH = 309
const MIN_WIDTH = 500

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: `100%`,
    minWidth: FILTER_WIDTH,
    top: 0,
    left: 0,
    filterResize: [
      {
        key: MAP_KEY.CALENDAR,
        ratioWidth: 0,
        ratioLeft: -1,
      },
      {
        key: MAP_KEY.EVENTS,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.CALENDAR}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.55)`,
    height: `100%`,
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.EVENTS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EVENTS}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.45)`,
    height: `100%`,
    top: 0,
    left: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px) * 0.55 + ${
      FILTER_WIDTH + 4 * COMPONENT.MARGIN
    }px)`,
    minLeft: FILTER_WIDTH + 4 * COMPONENT.MARGIN + MIN_WIDTH,
    minWidth: MIN_WIDTH,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const CALENDAR_MIN_HEIGHT = 600
const TABLE_MIN_HEIGHT = 300

const MAP_SIZE_SMALL_SCREEN = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: CALENDAR_MIN_HEIGHT,
    top: 0,
    left: 0,
    minWidth: FILTER_WIDTH,
    minHeight: CALENDAR_MIN_HEIGHT,
    filterResize: [
      {
        key: MAP_KEY.CALENDAR,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.CALENDAR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.EVENTS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.CALENDAR}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 2 * COMPONENT.MARGIN}px))`,
    height: CALENDAR_MIN_HEIGHT,
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: 500,
    minHeight: CALENDAR_MIN_HEIGHT,
    minTop: 0,
    minLeft: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EVENTS}`]: {
    width: `100%`,
    height: `calc(60% - ${COMPONENT.MARGIN}px)`,
    top: CALENDAR_MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    left: 0,
    minTop: CALENDAR_MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: FILTER_WIDTH + 500 + 2 * COMPONENT.MARGIN,
    minHeight: TABLE_MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="economy.event.event.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter
          width={formatNumber(sizes[MAP_KEY.FILTER].width - PANEL_PADDING)}
        />
      </PanelFilter>
      <Panel
        title="economy.event.event.UPCOMING_EVENTS"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CALENDAR}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <UpcomingEvents
          height={formatNumber(
            sizes[MAP_KEY.FILTER].height - HEADER_PANEL_HEIGHT,
          )}
        />
      </Panel>
      <Panel
        title="economy.event.event.NEWS"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.EVENTS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <News />
      </Panel>
    </>
  )
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SMALL_SCREEN,
  LARGE_SCREEN_BREAKPOINT,
)(Content)
