export const matchingOrderTableSchema = {
  TIME: 'tradingDate',
  TYPE: 'matchType',
  PRICE: 'matchPrice',
  CHANGE: 'priceChange',
  PERCENT_CHANGE: 'percentPriceChange',
  MATCH_VOL: 'matchVolume',
  TOTAL_MATCH_VOL: 'totalMatchVolume',
}

export const matchingOrderFormat = {
  PRICE: 10 ** 3,
  CHANGE: 10 ** 3,
  PERCENT_CHANGE: 0.01,
  MATCH_VOL: 10 ** 3,
  TOTAL_MATCH_VOL: 10 ** 3,
}
