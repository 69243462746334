export const event = {
  FILTER: 'Bộ lọc',
  ALL: 'Tất cả',
  VIET_NAM: 'Việt Nam',
  OTHERS: 'Khác',
  EVENT_TYPES: 'Loại sự kiện',
  UPCOMING_EVENTS: 'Sự kiện sắp diễn ra',
  NEWS: 'Tin tức liên quan',
  FROM: 'Từ ',
  TO: ' đến ',
  DATE: 'Ngày',
  EVENT: 'Sự kiện',
  DESCRIPTION: 'Nội dung sự kiện',
  CURRENT_VALUE: 'Kỳ hiện tại',
  PREVIOUSVALUE: 'Kỳ trước (YoY)',
  GROWTH_YOY: '%YoY',
  MONTH_1: 'Th01',
  MONTH_2: 'Th02',
  MONTH_3: 'Th03',
  MONTH_4: 'Th04',
  MONTH_5: 'Th05',
  MONTH_6: 'Th06',
  MONTH_7: 'Th07',
  MONTH_8: 'Th08',
  MONTH_9: 'Th09',
  MONTH_10: 'Th10',
  MONTH_11: 'Th11',
  MONTH_12: 'Th12',
  THOUSAND: 'Nghìn',
  MILLION: 'Triệu',
  BILLION: 'Tỷ',
  TRILLION: 'N.Tỷ',
}
