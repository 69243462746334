import { SizeTracker } from '../../../../common/sizeTracker'
import { ChartComponent } from './ChartComponent'
import FilterGroup from './FilterGroup'

export const ExportStructure = ({ width, height }) => {
  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <FilterGroup />
            {size.height && (
              <div style={{ height: height - size.height }} id="structureChart">
                <ChartComponent width={width} height={height} />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
