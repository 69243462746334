import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  FIINX_EVENT_TYPE,
  TABLE_INDICATORS,
  TABLE_INDICATORS_ALIGN_LEFT,
  TABLE_INDICATORS_ALIGN_RIGHT,
  TABLE_INDICATORS_DATE,
  TABLE_INDICATORS_FORMAT_MIL,
  TABLE_INDICATORS_FORMAT_PERCENTAGE,
  TABLE_INDICATORS_FORMAT_VALUE,
  aGMResolutionIndicators,
  additionalListingIndicators,
  annualGeneralMeetingIndicators,
  bODMeetingIndicators,
  cashDividendIndicators,
  directorDealIndicators,
  directorDealIndividualtransactionsIndicators,
  directorDealInstitutionaltransactionsIndicators,
  directorDealRelatedPersontransactionsIndicators,
  exchangeSwitchingIndicators,
  extraordinaryGeneralMeetingIndicators,
  finalAnnualResultsIndicators,
  keyPersonnelChangeIndicators,
  liquidationIndicators,
  listingSuspensionIndicators,
  mergerAndAcquisitionIndicators,
  nameChangeIndicators,
  newListingIndicators,
  otherEventsIndicators,
  quarterlyResultsIndicators,
  reListingIndicators,
  recorddateforballotIndicators,
  shareIssueIndicators,
  summaryIndicators,
  treasuryStocksIndicators,
  yearInterimResultIndicators,
} from '../../constant'
import style from '../index.module.css'
import TickerCell from './TickerCell'

const ThMillion = ({ title }) => (
  <div>
    <Translate value={title} />
    <div style={{ fontStyle: 'italic', fontWeight: 300 }}>
      (<Translate value="market.event.MILLION_VND" />)
    </div>
  </div>
)

export const summarySchema = () =>
  summaryIndicators.map((val, index) => {
    const title = `market.event.indicators.${val}`
    let result = {
      colId: val,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'left',
      },
      tdStyle: {
        whiteSpace: 'nowrap',
      },
    }
    if (val === TABLE_INDICATORS.ticker) {
      result = {
        ...result,
        render: (val, rowId) => <TickerCell val={val} rowId={rowId} />,
      }
    }

    if (TABLE_INDICATORS_DATE.includes(val)) {
      result = {
        ...result,
        render: (val) => <DateTimeSpan date={val} />,
      }
    }
    if (val === TABLE_INDICATORS.source) {
      const handleDropdown = (e) => {
        e.stopPropagation()
      }
      result = {
        ...result,
        disableSort: true,
        thStyle: {
          textAlign: 'center',
        },
        tdStyle: {
          ...result.tdStyle,
          textAlign: 'center',
        },
        render: (val) =>
          val && val !== EMPTY_VALUE ? (
            <a
              onClick={handleDropdown}
              href={val}
              target="_blank"
              rel="noopener noreferrer"
            >
              link
            </a>
          ) : null,
      }
    }

    if (TABLE_INDICATORS_FORMAT_PERCENTAGE.includes(val)) {
      result = {
        ...result,
        render: (val) => valToPercent(val),
      }
    }

    if (TABLE_INDICATORS_FORMAT_VALUE.includes(val)) {
      result = {
        ...result,
        render: (val) => formatVal(val, 0, true),
      }
    }

    if (TABLE_INDICATORS_FORMAT_MIL.includes(val)) {
      result = {
        ...result,
        renderTh: () => <ThMillion title={title} />,
        render: (val) => formatVal(val / 10 ** 6, 2, true),
      }
    }

    if (TABLE_INDICATORS_ALIGN_LEFT.includes(val)) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
      }
    } else if (TABLE_INDICATORS_ALIGN_RIGHT.includes(val)) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          ...result.tdStyle,
          textAlign: 'end',
        },
      }
    }

    return result
  })

export const getSchemaById = (id) => {
  switch (id) {
    case FIINX_EVENT_TYPE.annualGeneralMeeting.id:
      return annualGeneralMeetingSchema()
    case FIINX_EVENT_TYPE.aGMResolution.id:
      return aGMResolutionSchema()
    case FIINX_EVENT_TYPE.additionalListing.id:
      return additionalListingSchema()
    case FIINX_EVENT_TYPE.nameChange.id:
      return nameChangeSchema()
    case FIINX_EVENT_TYPE.recordDateForBallot.id:
      return recordDateForBallotSchema()
    case FIINX_EVENT_TYPE.keyPersonnelChange.id:
      return keyPersonnelChangeSchema()
    case FIINX_EVENT_TYPE.bODMeeting.id:
      return bODMeetingSchema()
    case FIINX_EVENT_TYPE.finalAnnualResults.id:
      return finalAnnualResultsSchema()
    case FIINX_EVENT_TYPE.quarterlyResults.id:
      return quarterlyResultsSchema()
    case FIINX_EVENT_TYPE.yearInterimResult.id:
      return yearInterimResultSchema()
    case FIINX_EVENT_TYPE.mergerAndAcquisition.id:
      return mergerAndAcquisitionSchema()
    case FIINX_EVENT_TYPE.newListing.id:
      return newListingSchema()
    case FIINX_EVENT_TYPE.otherEvents.id:
      return otherEventsSchema()
    case FIINX_EVENT_TYPE.reListing.id:
      return reListingSchema()
    case FIINX_EVENT_TYPE.listingSuspension.id:
      return listingSuspensionSchema()
    case FIINX_EVENT_TYPE.treasuryStocks.id:
      return treasuryStocksSchema()
    case FIINX_EVENT_TYPE.liquidation.id:
      return liquidationSchema()
    case FIINX_EVENT_TYPE.cashDividend.id:
      return cashDividendSchema()
    case FIINX_EVENT_TYPE.shareIssue.id:
      return shareIssueSchema()
    case FIINX_EVENT_TYPE.extraordinaryGeneralMeeting.id:
      return extraordinaryGeneralMeetingSchema()
    case FIINX_EVENT_TYPE.exchangeSwitching.id:
      return exchangeSwitchingSchema()
    case FIINX_EVENT_TYPE.directorDeal.id:
      return directorDealSchema()
    case FIINX_EVENT_TYPE.directorDealIndividualTransactions.id:
      return directorDealIndividualTransactionsSchema()
    case FIINX_EVENT_TYPE.directorDealInstitutionalTransactions.id:
      return directorDealInstitutionalTransactionsSchema()
    case FIINX_EVENT_TYPE.directorDealRelatedPersonTransactions.id:
      return directorDealRelatedPersonTransactionsSchema()
    default:
      return annualGeneralMeetingSchema()
  }
}
const getResult = (val) => {
  let result = {
    colId: val,
    title: `market.event.indicators.${val}`,
    isI18n: false,
    tdStyle: {
      whiteSpace: 'nowrap',
    },
  }
  if (TABLE_INDICATORS_ALIGN_LEFT.includes(val)) {
    result = {
      ...result,
      thStyle: {
        textAlign: 'left',
      },
    }
  } else if (TABLE_INDICATORS_ALIGN_RIGHT.includes(val)) {
    result = {
      ...result,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        ...result.tdStyle,
        textAlign: 'end',
      },
    }
  }
  if (val === TABLE_INDICATORS.ticker) {
    result = {
      ...result,
      render: (val, rowId) => <TickerCell val={val} rowId={rowId} />,
    }
  }
  if (TABLE_INDICATORS_DATE.includes(val)) {
    result = {
      ...result,
      render: (val) => <DateTimeSpan date={val} />,
    }
  }

  if (TABLE_INDICATORS_FORMAT_PERCENTAGE.includes(val)) {
    result = {
      ...result,
      render: (val) => {
        return (
          <Span style={{ ...(val < 0 && { color: '#FF5661 ' }) }}>
            {valToPercent(val)}
          </Span>
        )
      },
    }
  }

  if (TABLE_INDICATORS_FORMAT_VALUE.includes(val)) {
    result = {
      ...result,
      render: (val) => {
        return (
          <Span style={{ ...(val < 0 && { color: '#FF5661 ' }) }}>
            {formatVal(val, 0, true)}
          </Span>
        )
      },
    }
  }

  if (TABLE_INDICATORS_FORMAT_MIL.includes(val)) {
    result = {
      ...result,
      renderTh: () => <ThMillion title={result.title} />,
      render: (val) => formatVal(val / 10 ** 6, 2, true),
    }
  }

  if (val === TABLE_INDICATORS.source) {
    const handleDropdown = (e) => {
      e.stopPropagation()
    }
    result = {
      ...result,
      disableSort: true,
      thStyle: {
        textAlign: 'center',
      },
      tdStyle: {
        ...result.tdStyle,
        textAlign: 'center',
      },
      render: (val) =>
        val && val !== EMPTY_VALUE ? (
          <a
            onClick={handleDropdown}
            href={val}
            target="_blank"
            rel="noopener noreferrer"
          >
            link
          </a>
        ) : null,
    }
  }

  if (val === TABLE_INDICATORS.dividendYear) {
    result = {
      ...result,
      render: (val) => {
        return (
          <Span style={{ ...(val < 0 && { color: '#FF5661 ' }) }}>
            {val === 0 ? '--' : val}
          </Span>
        )
      },
    }
  }

  if (
    [
      TABLE_INDICATORS.address,
      TABLE_INDICATORS.position,
      TABLE_INDICATORS.time,
    ].includes(val)
  ) {
    result = {
      ...result,
      canCustomTd: true,
      render: (cellVal) => (
        <td
          className={style.tdEllipsis}
          style={{
            minWidth: val === TABLE_INDICATORS.time ? '150px' : '300px',
          }}
        >
          <TextEllipsis isI18n={false} text={cellVal} />
        </td>
      ),
    }
  }

  return result
}

export const annualGeneralMeetingSchema = () =>
  annualGeneralMeetingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const aGMResolutionSchema = () =>
  aGMResolutionIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const additionalListingSchema = () =>
  additionalListingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const nameChangeSchema = () =>
  nameChangeIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const recordDateForBallotSchema = () =>
  recorddateforballotIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const keyPersonnelChangeSchema = () =>
  keyPersonnelChangeIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const bODMeetingSchema = () =>
  bODMeetingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const finalAnnualResultsSchema = () =>
  finalAnnualResultsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const quarterlyResultsSchema = () =>
  quarterlyResultsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const yearInterimResultSchema = () =>
  yearInterimResultIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const mergerAndAcquisitionSchema = () =>
  mergerAndAcquisitionIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const newListingSchema = () =>
  newListingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const otherEventsSchema = () =>
  otherEventsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const reListingSchema = () =>
  reListingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const listingSuspensionSchema = () =>
  listingSuspensionIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const treasuryStocksSchema = () =>
  treasuryStocksIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const liquidationSchema = () =>
  liquidationIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const cashDividendSchema = () =>
  cashDividendIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const shareIssueSchema = () =>
  shareIssueIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const extraordinaryGeneralMeetingSchema = () =>
  extraordinaryGeneralMeetingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const exchangeSwitchingSchema = () =>
  exchangeSwitchingIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const directorDealSchema = () =>
  directorDealIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })
export const directorDealIndividualTransactionsSchema = () =>
  directorDealIndividualtransactionsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const directorDealInstitutionalTransactionsSchema = () =>
  directorDealInstitutionaltransactionsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })

export const directorDealRelatedPersonTransactionsSchema = () =>
  directorDealRelatedPersontransactionsIndicators.map((val, index) => {
    let result = getResult(val)
    return result
  })
