import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'

const initialState = {
  numPages: null,
  pageNumber: 1,
  zoomFile: '100%',
}

export const slice = createSlice({
  name: 'corporate/companySnapshot',
  initialState,
  reducers: {
    changeNumPages: (state, action) => {
      state.numPages = action.payload
    },
    changePageNumber: (state, action) => {
      state.pageNumber = action.payload
    },
    changeZoomFile: (state, action) => {
      state.zoomFile = action.payload
    },
  },
})

export const selectNumPages = (state) => state[slice.name].numPages
export const selectPageNumber = (state) => state[slice.name].pageNumber
export const selectZoomFile = (state) => state[slice.name].zoomFile

export const { changeNumPages, changePageNumber, changeZoomFile } =
  slice.actions

register(slice.name, slice.reducer)
