import PropTypes from 'prop-types'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Input } from '../../../common/html/Input'
import { typeTreeItem } from '../constants'
import DashboardItem from './DashboardItem'
import GroupDashboard from './GroupDashboard'
import style from './style.module.css'

const DropdownMoreDashboard = ({
  tree,
  buttonSaveRef,
  isShowDashboardCreate,
  containerStyle,
  childrenStyle,
  iconCollapse,
  iconExpand,
  isAddNewDashboard,
  newDashboardName,
  setNewDashboardName,
  setIsAddNewDashboard,
  setIsEnterAddNewDashboard,
  groupDashboardClass,
  dashboardClass,
  chartClass,
  isShowChart,
  selectMultipleItems,
  searchKey,
  isChartLibrary,
}) => {
  const handleChangeNewDashboardName = (e) => {
    setNewDashboardName(e.target.value)
  }

  const handleFocusNewDashboardName = (e) => {
    e.target.select()
  }

  const handleEnterNewDashboardName = (e) => {
    if (e.code === 'Enter') {
      setIsEnterAddNewDashboard(true)
    }
  }

  const handleClickOutside = () => {
    setIsAddNewDashboard(false)
    setNewDashboardName('')
  }

  const filterDataByKeySearch = (array, searchKey) => {
    return !searchKey
      ? array
      : array.reduce((r, item) => {
          if (item.name.toUpperCase().includes(searchKey.toUpperCase())) {
            return [...r, item]
          }
          if (item.children) {
            const children = filterDataByKeySearch(item.children, searchKey)
            if (children.length) r.push({ ...item, children })
          }
          return r
        }, [])
  }

  return (
    <div className={`${style.listDashBoard}`} style={containerStyle}>
      {filterDataByKeySearch(tree, searchKey).map((item, index) => (
        <React.Fragment key={index}>
          {item.parentId ? (
            <GroupDashboard
              key={typeTreeItem.GROUP_DASHBOARD + item.id}
              groupDashboard={item}
              childrenStyle={childrenStyle}
              iconCollapse={iconCollapse}
              iconExpand={iconExpand}
              groupDashboardClass={groupDashboardClass}
              dashboardClass={dashboardClass}
              chartClass={chartClass}
              selectMultipleItems={selectMultipleItems}
              isShowChart={isShowChart}
            />
          ) : (
            <DashboardItem
              key={typeTreeItem.DASHBOARD + item.id}
              dashboard={item}
              dashboardClass={dashboardClass}
              chartClass={chartClass}
              childrenStyle={childrenStyle}
              iconCollapse={iconCollapse}
              iconExpand={iconExpand}
              selectMultipleItems={selectMultipleItems}
              isShowChart={isShowChart}
              isChartLibrary={isChartLibrary}
            />
          )}
        </React.Fragment>
      ))}
      {isShowDashboardCreate && (
        <div className={style.newDashboard}>
          <i
            className="icon-plus-circle-line"
            style={{ marginRight: 4, paddingLeft: 18 }}
          />
          <Translate value="financialChart.NEW_DASHBOARD_GROUP" />
        </div>
      )}
      {isAddNewDashboard && (
        <div
          className={[
            style.groupDashBoard,
            style.backgroundActive,
            style.addNewDashboardInput,
          ].join(' ')}
        >
          <HandleClickOutside
            handleClickOutside={handleClickOutside}
            exceptRef={buttonSaveRef}
          >
            <Input
              value={newDashboardName}
              autoFocus
              style={{ color: '#4E4E4E' }}
              onChange={handleChangeNewDashboardName}
              onFocus={handleFocusNewDashboardName}
              onKeyDown={handleEnterNewDashboardName}
            />
          </HandleClickOutside>
        </div>
      )}
    </div>
  )
}

export default DropdownMoreDashboard

DropdownMoreDashboard.propTypes = {
  true: PropTypes.array.isRequired,
  isShowDashboardCreate: PropTypes.bool,
  containerStyle: PropTypes.object,
  childrenStyle: PropTypes.func,
  isShowChart: PropTypes.bool,
  selectMultipleItems: PropTypes.bool,
}

DropdownMoreDashboard.defaultProps = {
  true: [],
  isShowDashboardCreate: false,
  containerStyle: {},
  childrenStyle: () => {},
  isShowChart: false,
  selectMultipleItems: false,
}
