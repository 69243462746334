import TextEllipsis from '../../../common/textEllipsis'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../utils/Value'
import {
  breakoutIndicator,
  contributionIndicator,
  newHighIndicator,
  newLowIndicator,
  SELECT_TIME_RANGE,
  topMoverIndicator,
  topNetForeignValue,
  topNetForeignVolume,
  TYPE_TOP_MOVER,
} from '../constants'
import { selectTopMoverValue } from '../store/slice'
import CellExchange from './CellExchange'
import CellHighLight from './CellHighLight'
import CellNewsCustom from './CellNewsCustom'
import ColCellTicker from './ColCellTicker'

export const TOP_MOVER_TABLE_ID = 'top-mover-table-id'

export const getSchemaById = (id, timeRange) => {
  switch (id) {
    case TYPE_TOP_MOVER.CONTRIBUTION:
      return contributionSchema(timeRange)
    case TYPE_TOP_MOVER.VOLUME:
    case TYPE_TOP_MOVER.VALUE:
    case TYPE_TOP_MOVER.GAINER:
    case TYPE_TOP_MOVER.LOSER:
      return topMoverSchema(timeRange)
    case TYPE_TOP_MOVER.BREAKOUT:
      return breakoutSchema(timeRange)
    case TYPE_TOP_MOVER.NEW_HIGH:
      return newHighSchema(timeRange)
    case TYPE_TOP_MOVER.NEW_LOW:
      return newLowSchema(timeRange)
    case TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE:
      return topNetForeignValueSchema(timeRange)
    case TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME:
      return topNetForeignVolumeSchema(timeRange)
    default:
      return []
  }
}

export const topMoverSchema = (timeRange) => {
  return Object.keys(topMoverIndicator).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'PERCENT_PRICE_CHANGE' || key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = topMoverIndicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6 || index === 8 || index === 9) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }

    if (index === 10) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }

    return result
  })
}

export const contributionSchema = (timeRange) => {
  return Object.keys(contributionIndicator).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'PERCENT_PRICE_CHANGE' || key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = contributionIndicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 8) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 9) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 10) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        canCustomTd: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }

    return result
  })
}

export const breakoutSchema = (timeRange) => {
  return Object.keys(breakoutIndicator).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'AVG_TOTAL_VOLUME' ||
        key === 'PERCENT_PRICE_CHANGE' ||
        key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = breakoutIndicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6 || index === 9 || index === 10) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 8) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 11) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }
    return result
  })
}

export const newHighSchema = (timeRange) => {
  return Object.keys(newHighIndicator).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'HIGHEST_PRICE' ||
        key === 'PERCENT_PRICE_CHANGE' ||
        key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = newHighIndicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6 || index === 9 || index === 10) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 8) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 11) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }
    return result
  })
}

export const newLowSchema = (timeRange) => {
  return Object.keys(newLowIndicator).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'LOWEST_PRICE' ||
        key === 'PERCENT_PRICE_CHANGE' ||
        key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = newLowIndicator[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6 || index === 9 || index === 10) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 8) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 11) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }
    return result
  })
}

export const topNetForeignVolumeSchema = (timeRange) => {
  return Object.keys(topNetForeignVolume).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'PERCENT_PRICE_CHANGE' || key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = topNetForeignVolume[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6 || index === 8 || index === 9) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 10) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 11) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }
    return result
  })
}

export const topNetForeignValueSchema = (timeRange) => {
  return Object.keys(topNetForeignValue).map((key, index) => {
    const title =
      timeRange !== SELECT_TIME_RANGE.ONE_DAY &&
      (key === 'PERCENT_PRICE_CHANGE' || key === 'PRICE_CHANGE')
        ? `market.topMover.${key}_${timeRange}`
        : `market.topMover.${key}`
    const colId = topNetForeignValue[key]
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        textTransform: 'uppercase',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <ColCellTicker val={val} rowId={rowId} />,
      }
    }
    if (index === 1) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          textTransform: 'uppercase',
        },
        tdStyle: {
          textAlign: 'left',
        },
        disableSort: true,
        render: (val, rowId) => <CellExchange val={val} rowId={rowId} />,
      }
    }
    if (index === 2) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          minWidth: 150,
        },
        disableSort: true,
        render: (val) => <TextEllipsis isI18n={false} text={val} />,
      }
    }
    if (index === 3 || index === 6) {
      result = {
        ...result,
        render: (val) => formatVal(valDivThousand(val)),
      }
    }
    if (index === 4) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivThousand(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 5) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={valToPercent(val)}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }
    if (index === 7 || index === 8 || index === 9) {
      result = {
        ...result,
        render: (val) => formatVal(valDivMillion(val)),
      }
    }
    if (index === 10) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => {
          return (
            <CellHighLight
              value={formatVal(valDivMillion(val))}
              rowId={rowId}
              keyColor={colId}
              selectCell={selectTopMoverValue}
              style={style}
              className={className}
            />
          )
        },
      }
    }

    if (index === 11) {
      result = {
        ...result,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        canCustomTd: true,
        disableSort: true,
        render: (val, rowId) => <CellNewsCustom val={val} rowId={rowId} />,
      }
    }
    return result
  })
}
