import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import {
  loadingKey,
  selectICBs,
  selectIcbById,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getICBThunk } from './store/thunk'

const firstLevel = 1

const useGetICB = (
  disableCallApi = true,
  isTree = false,
  filterFunc,
  mapFunc,
  isGetTreeNotDeleteChildren = false,
) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.ICB))
  const ICBs = useSelector(selectICBs)
  const icbById = useSelector(selectIcbById)

  const treeRef = useRef(null)
  const currentFilterFunc = useRef(filterFunc)

  if (currentFilterFunc.current !== filterFunc) {
    treeRef.current = null
    currentFilterFunc.current = filterFunc
  }

  const mapIcbs = mapFunc ? ICBs.map(mapFunc) : ICBs
  const filteredIcbs = filterFunc ? mapIcbs.filter(filterFunc) : mapIcbs
  const icbByIdWithChild = { ...icbById }
  ICBs.forEach((icb) => {
    if (icb.parentICBId) {
      icbByIdWithChild[icb.parentICBId] = {
        ...(icbByIdWithChild[icb.parentICBId] || {}),
        children: [...(icbByIdWithChild[icb.parentICBId]?.children || []), icb],
      }
    }
  })

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getICBThunk())
    }
    treeRef.current = null
  }, [locale])

  const handleICBs = (isDeleteChildren = true) => {
    if (treeRef.current && isDeleteChildren) {
      return treeRef.current
    }
    const ICBsSorted = [...filteredIcbs]?.sort(
      (ICB1, ICB2) => ICB2?.icbLevel - ICB1?.icbLevel,
    )
    const ICBsById = keyBy(
      filteredIcbs.map((ICB) => {
        return { ...ICB, children: {} }
      }),
      'icbId',
    )
    ICBsSorted.forEach((ICB) => {
      if (ICBsById[ICB.parentICBId] && ICB.icbLevel !== firstLevel) {
        ICBsById[ICB.parentICBId].children[ICB.icbId] = ICBsById[ICB.icbId]
        if (isDeleteChildren) {
          delete ICBsById[ICB.icbId]
        }
      }
    })
    if (isDeleteChildren) {
      treeRef.current = ICBsById
    }
    return ICBsById
  }

  return {
    loading,
    ICBs: filteredIcbs,
    ICBById: icbByIdWithChild,
    ICBsTree: isTree && !loading && filteredIcbs.length > 0 ? handleICBs() : {},
    ICBTreeNotDeleteChildren:
      isTree &&
      !loading &&
      filteredIcbs.length > 0 &&
      isGetTreeNotDeleteChildren
        ? handleICBs(false)
        : {},
    handleICBs,
  }
}

export default useGetICB

const formatIcbItem = (ICBs, icb) => {
  const hasChild = ICBs.some((v) => v.parentICBId === icb.icbId)
  const childItem = hasChild ? getChild(ICBs, icb) : []
  return [icb, ...childItem]
}

const getChild = (ICBs, parent) => {
  let childTableArr = []
  ICBs.forEach((icb) => {
    if (icb.parentICBId === parent.icbId) {
      const child = formatIcbItem(ICBs, icb)
      childTableArr = [...childTableArr, ...child]
    }
  })
  return childTableArr
}

export const sortIcbData = (ICBs) => {
  let newICBs = []
  const ICBsWithoutLevel1 = ICBs.filter((icb) => icb.parentICBId !== icb.icbId)
  ICBs.filter((icb) => icb.parentICBId === icb.icbId).forEach((icb) => {
    const child = formatIcbItem(ICBsWithoutLevel1, icb)
    newICBs = [...newICBs, ...child]
  })
  return newICBs
}
