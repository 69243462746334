import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE_STOCK_INDICES_SECTOR } from '../../common/hooks/useBasicInfo'
import UseI18n from '../../common/hooks/useI18n'
import { FEATURES } from '../../constants/routes'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchMarNew } from '../store/slice'
import { getSearchMarNewsData } from '../store/thunk'
import { NewReportItem } from './NewReportItem'

export const MarNewReport = (props) => {
  const { searchStr } = props
  const dispatch = useDispatch()
  const marNews = useSelector(selectSearchMarNew)
  const [open, setOpen] = useState(true)

  const onClickCollapse = (value) => {
    setOpen(value)
  }

  const onClickPrevPage = () => {
    if (marNews.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchMarNewsData({
          Term: searchStr,
          Page: marNews.data.page - 1,
          PageSize: marNews.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (marNews.data.page === marNews.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchMarNewsData({
          Term: searchStr,
          Page: marNews.data.page + 1,
          PageSize: marNews.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="mar-new"
      title={`${UseI18n('search.fullSearch.MAR_NEWS_REPORT')} (${
        marNews.data.totalRecords
      })`}
      blockData={marNews}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!marNews.data.items.length &&
        marNews.data.items.map((item) => (
          <NewReportItem
            key={item.newsId}
            searchStr={searchStr}
            data={item}
            idBasicInfo={item.groupId || item.groupCode}
            typeBasicInfo={TYPE_STOCK_INDICES_SECTOR.INDICES}
            url={FEATURES.market.components['news-report-detail'].route}
          />
        ))}
    </SearchCollapseBlock>
  )
}

MarNewReport.propTypes = {
  searchStr: PropTypes.string.isRequired,
}
