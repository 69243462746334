import ValuesOfBonsProxy from '../../../../proxies/bond/overview/valueOfBonds/TopRelease'
import { ServiceBase } from '../../../ServiceBase'

export class ValuesOfBonds extends ServiceBase {
  getDataChart(params) {
    return this.getData(() => ValuesOfBonsProxy.getDataChart(params))
  }
  getDataChartCollateral(params) {
    return this.getData(() => ValuesOfBonsProxy.getDataChartCollateral(params))
  }
}

export default new ValuesOfBonds()
