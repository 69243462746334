import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CHART_TYPE_RADIO } from '../constant'
import { changeChartType, selectChartType } from './store/slice'

const ChartType = () => {
  const dispatch = useDispatch()
  const chartType = useSelector(selectChartType)

  const handleChangeChartType = (value) => {
    dispatch(changeChartType(value))
  }

  return (
    <div>
      <ul
        style={{ width: 'auto' }}
        className="list-check d-flex align-center mt-0"
      >
        {CHART_TYPE_RADIO.map((item) => (
          <li key={item.value} className="mb-0">
            <label>
              <input
                type="radio"
                className="radiobox radiobox2"
                readOnly
                value={item.value}
                checked={item.value === chartType}
                onClick={(e) => handleChangeChartType(e.target.value)}
              />
              <Span
                style={{
                  color: item.value === chartType ? '#2f9aee' : '',
                  whiteSpace: 'nowrap',
                  fontSize: 12,
                }}
              >
                <Translate value={item.title} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ChartType
