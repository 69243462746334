import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getDerivative } from './thunk'

const initialState = {
  isLoading: true,
  derivativeList: [],
  derivativeTree: [],
  derivativeActive: {},
}

const slice = createSlice({
  name: 'market/derivativeStatistics',
  initialState,
  reducers: {
    setDerivativeActive: (state, action) => {
      state.derivativeActive = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDerivative.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDerivative.fulfilled, (state, action) => {
      state.isLoading = false
      state.derivativeList = action.payload
      state.derivativeTree = arrayToTree(action.payload)
    })
    builder.addCase(getDerivative.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDerivativeList = (state) => state[slice.name].derivativeList
export const selectDerivativeTree = (state) => state[slice.name].derivativeTree
export const selectDerivativeActive = (state) =>
  state[slice.name].derivativeActive

export const { setDerivativeActive } = slice.actions

register(slice.name, slice.reducer)

const arrayToTree = (arr, parent = null) =>
  arr
    .filter((item) => item.parentFutureId === parent)
    .map((child) => ({ ...child, children: arrayToTree(arr, child.futureId) }))
