import moment from 'moment'
import { languageObjs } from '../../../../configs/Language'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { valDivBillion } from '../../../utils/Value'

export const convertToFixedValue = (data) => {
  if (Math.abs(data) < 10000) {
    return +data.toFixed(2)
  } else {
    return +data.toFixed(0)
  }
}

export const formatDataChart = (
  data,
  dataFormatKey,
  timeFrame,
  locale,
  lineKey,
  isConvertData = false,
  timezone,
) => {
  if (dataFormatKey) {
    let newData = [...data]
    dataFormatKey.forEach((element) => {
      newData = newData.map((item, index) => ({
        ...item,
        [element]: convertToFixedValue(valDivBillion(item[element])),
        XAxis: `${
          timeFrame === '1M'
            ? formatDateTime(item.tradingDateId, FORMAT.DATE, locale, timezone)
            : timeFrame === '3M' || timeFrame === '6M'
            ? 'W' + item.week + ' - ' + item.year
            : timeFrame === '1Y' || timeFrame === '3Y'
            ? valConvertDateOfMonth(item.month, item.year, locale)
            : 'Q' + item.quarter + ' - ' + item.year
        }`,
        tooltipHover: `${
          timeFrame === '1M'
            ? formatDateTime(item.tradingDateId, FORMAT.DATE, locale, timezone)
            : timeFrame === '3M' || timeFrame === '6M'
            ? formatDateTime(
                item.minTradingDateId,
                FORMAT.DATE,
                locale,
                timezone,
              ) +
              ' - ' +
              formatDateTime(item.tradingDateId, FORMAT.DATE, locale, timezone)
            : timeFrame === '1Y' || timeFrame === '3Y'
            ? valConvertDateOfMonth(item.month, item.year, locale)
            : 'Q' + item.quarter + ' - ' + item.year
        }`,
        lineSolid:
          index + 1 < newData.length && timeFrame !== '1M'
            ? isConvertData
              ? convertToFixedValue(valDivBillion(item[lineKey]))
              : item[lineKey]
            : undefined,
        lineDash:
          index + 1 >= newData.length - 1 && timeFrame !== '1M'
            ? isConvertData
              ? convertToFixedValue(valDivBillion(item[lineKey]))
              : item[lineKey]
            : undefined,
      }))
    })
    return newData
  }
}

export const valConvertDateOfMonth = (m, y, locale) => {
  if (locale === languageObjs.en) {
    return moment()
      .set({ month: m - 1, year: y, date: 1 })
      .locale(locale)
      .format('MMM - YYYY')
  }
  return (
    'T' +
    moment()
      .set({ month: m - 1, year: y, date: 1 })
      .format('MM - YYYY')
  )
}

export const convertTimeFrame = (timeFrame) => {
  const mapTimeFrame = {
    '1M': 'OneMonth',
    '3M': 'ThreeMonths',
    '6M': 'SixMonths',
    '1Y': 'OneYear',
    '3Y': 'ThreeYears',
    '5Y': 'FiveYears',
  }
  return mapTimeFrame[timeFrame]
}
