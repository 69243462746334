import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../common/DateTimeSpan'
import useBasicInfo from '../../common/hooks/useBasicInfo'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FORMAT } from '../../utils/Datetime'
import { highlightString } from '../../utils/Search'
import style from './index.module.css'

export const NewReportItem = ({
  searchStr,
  data,
  url,
  idBasicInfo,
  typeBasicInfo,
}) => {
  const navigate = useCustomNavigate()
  const { getBasicInfo } = useBasicInfo()

  const getTitle = () => {
    return highlightString(data.title, data.highlight.title, searchStr)
  }

  const getSource = () => {
    return data.newsSourceName || ''
  }

  const onRedirect = () => {
    navigate(
      url,
      getBasicInfo(idBasicInfo, typeBasicInfo),
      '?newsId=' + data.newsId,
    )
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div className={`search-content ${style['search-content']}`}>
        <div className={style['item-title']}>{getTitle()}</div>
        <div className="date">
          <DateTimeSpan date={data.publicDate} formatDate={FORMAT.NEWS} />
          <span className="ml-10">
            <Translate value="search.fullSearch.SOURCE" />
            <span>: {getSource()}</span>
          </span>
        </div>
      </div>
    </a>
  )
}

NewReportItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object,
  url: PropTypes.string.isRequired,
}
