import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import style from './index.module.css'
import {
  changeIsShowPopup,
  selectSelectedItems,
  triggerCallData,
} from './store/slice'
import { getMarketCapOrganizationByIcbId } from './store/thunk'

const Compare = () => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)
  const selectedItems = useSelector(selectSelectedItems)

  const handleOpen = () => {
    dispatch(changeIsShowPopup(true))
  }

  const handleChangeIcbTopInfo = async () => {
    if (
      !sectorInfo.icbId ||
      selectedItems.filter((item) => !!item.icbId).includes(sectorInfo.icbId)
    )
      return

    const getDefaultThenCallData = (params) => async (dispatch) => {
      await dispatch(getMarketCapOrganizationByIcbId(params))
      return await dispatch(triggerCallData())
    }

    dispatch(
      getDefaultThenCallData({
        IcbId: sectorInfo.icbId,
        Top: 5,
      }),
    )
  }

  useEffect(() => {
    handleChangeIcbTopInfo()
  }, [sectorInfo.icbId])

  return (
    <div className="mt-16">
      <Span style={{ fontSize: 11 }} className={style.selectTitle}>
        <Translate value="sector.sectorConstituents.valuation.COMPARISION" />
      </Span>

      <button onClick={handleOpen} className={style.btnCompare}>
        <i className="icon-plus" />
        <Span style={{ fontWeight: 500 }}>
          <Translate value="sector.sectorConstituents.valuation.ADD_COMPARISION" />
        </Span>
      </button>
    </div>
  )
}

export default Compare
