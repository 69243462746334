import IconLeftRight from './IconLeftRight'

export const BUY_TM = 'economy.monetary.monetary.BUY_TM'
export const BUY_CK = 'economy.monetary.monetary.BUY_CK'
export const SELL = 'economy.monetary.monetary.SELL'

export const getTableHeaderAttribute = (currentPage) => [
  [
    {
      name: 'firstCell1',
      value: '',
      style: {
        width: '22%',
        borderRight: '1px solid #546373',
        borderBottomColor: '#273040',
      },
    },
    {
      name: 'column1',
      value: currentPage[0],
      style: {
        textAlign: 'center',
        width: '26%',
        position: 'relative',
        borderRight: '1px solid #546373',
      },
      attrs: {
        colSpan: 3,
      },
      icon: <IconLeftRight />,
    },
    {
      name: 'column2',
      value: currentPage[1],
      style: {
        textAlign: 'center',
        width: '26%',
        borderRight: '1px solid #546373',
      },
      attrs: {
        colSpan: 3,
      },
    },
    {
      name: 'lastCell1',
      value: currentPage[2],
      style: {
        textAlign: 'center',
        width: '26%',
        position: 'relative',
      },
      attrs: {
        colSpan: 3,
      },
    },
  ],
  [
    {
      name: 'firstCell2',
      value: '',
      style: { width: '22%', borderRight: '1px solid #546373' },
    },
    {
      name: 'column1-1',
      value: BUY_TM,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column1-2',
      value: BUY_CK,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column1-3',
      value: SELL,
      style: {
        textAlign: 'right',
        borderRight: '1px solid #546373',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column2-1',
      value: BUY_TM,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column2-2',
      value: BUY_CK,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column2-3',
      value: SELL,
      style: {
        textAlign: 'right',
        borderRight: '1px solid #546373',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column3-1',
      value: BUY_TM,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'column3-2',
      value: BUY_CK,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
    {
      name: 'lastCell2',
      value: SELL,
      style: {
        textAlign: 'right',
      },
      attrs: {
        colSpan: 1,
      },
    },
  ],
]
