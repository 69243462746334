import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span.js'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import VerticalLine from '../../../../../common/topInfo/components/VerticalLine/index.js'
import FilterSector from '../../../priceBoard/filter/FilterSector.js'
import { selectFilterPriceBoard } from '../../../priceBoard/store/slice.js'
import {
  MARKET_TRADING_VALUE,
  TIME_FREQUENCY_TABS_TRADING,
  TIME_FREQUENCY_TRADING_VALUE,
} from '../constants.js'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectFilterMarketTradingValue,
} from '../store/slice.js'
import { getMarketTradingValueData } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const methodType = useSelector(selectActiveMethodType)
  const { icbIds } = useSelector(selectFilterPriceBoard)
  const { timeFrequency, isTradingMethod, From, To } = useSelector(
    selectFilterMarketTradingValue,
  )

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE,
        key: 'timeFrequency',
        value: item.value,
      }),
    )
  }

  const handleChangeParams = (item) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE,
        key: 'isTradingMethod',
        value: item,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE,
        key: 'timeFrequency',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE,
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.MARKET_TRADING_VALUE,
        key: 'To',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload =
      timeFrequency === calendarTabKey
        ? {
            issueMethodId: methodType,
            isTradingMethod,
            icbIds,
            From,
            To,
          }
        : {
            issueMethodId: methodType,
            timeFrequency: timeFrequency,
            isTradingMethod,
            icbIds,
          }

    dispatch(getMarketTradingValueData(payload))
  }, [
    locale,
    methodType,
    timeFrequency,
    isTradingMethod,
    icbIds.length,
    From,
    To,
  ])

  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 8 }}
      >
        {MARKET_TRADING_VALUE.map(({ value, name }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center cursor-pointer ${
                isTradingMethod === value && style.radioActive
              }`}
              onClick={() => handleChangeParams(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={isTradingMethod === value}
                readOnly={true}
              />
              <Span
                style={{
                  fontSize: 12,
                  fontWeight: 340,
                }}
              >
                <Translate value={name} />
              </Span>
            </div>
          )
        })}
        <VerticalLine mr={0} ml={0} h={15} />
        <FilterSector />
      </div>
      <DispatchActionTabWithCalendar
        id="marketTradingValue"
        tabs={TIME_FREQUENCY_TABS_TRADING}
        activeTab={timeFrequency}
        startDate={From}
        endDate={To}
        isShowCalendar={[
          TIME_FREQUENCY_TRADING_VALUE.D,
          calendarTabKey,
        ].includes(timeFrequency)}
        datePreset={TIME_FREQUENCY_TABS_TRADING}
        excludeDateIntervals={[]}
        onChangeTab={handleChangeTimeType}
        onChangeCalendar={handleChangeCalendar}
      />
    </div>
  )
}

export default Filter
