import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getHistoricalTooltip, getOwnershipTooltip } from './thunk'

const initialState = {
  historicalDealsLoading: false,
  historicalDealsData: [],
  ownershipLoading: false,
  ownershipData: [],
  isLoadingDownloadCsv: false,
}

export const slice = createSlice({
  name: 'corporate/ownership/toolTipInfo',
  initialState,
  reducers: {
    changeLoadingDownloadCsv: (state, action) => {
      state.isLoadingDownloadCsv = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHistoricalTooltip.pending, (state) => {
      state.historicalDealsLoading = true
    })
    builder.addCase(getHistoricalTooltip.fulfilled, (state, action) => {
      state.historicalDealsLoading = false
      state.historicalDealsData = action.payload || []
    })
    builder.addCase(getHistoricalTooltip.rejected, (state) => {
      state.historicalDealsLoading = false
    })

    builder.addCase(getOwnershipTooltip.pending, (state) => {
      state.ownershipLoading = true
    })
    builder.addCase(getOwnershipTooltip.fulfilled, (state, action) => {
      state.ownershipLoading = false
      state.ownershipData = action.payload || []
    })
    builder.addCase(getOwnershipTooltip.rejected, (state) => {
      state.ownershipLoading = false
    })
  },
})

export const selectHistoricalDealsLoading = (state) =>
  state[slice.name].historicalDealsLoading
export const selectHistoricalDealsData = (state) =>
  state[slice.name].historicalDealsData

export const selectOwnershipLoading = (state) =>
  state[slice.name].ownershipLoading
export const selectOwnershipData = (state) => state[slice.name].ownershipData
export const selectLoadingDownloadCsv = (state) =>
  state[slice.name].isLoadingDownloadCsv

export const { changeLoadingDownloadCsv } = slice.actions

register(slice.name, slice.reducer)
