import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import { TabLink } from '../../../common/tabs/TabLink'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import PanelHolding from './PanelHolding'
import { TABLE_TABS } from './constants'
import { changeTableTab, selectLoading, selectTableTab } from './store/slice'
import { getListTickersSelectThunk } from './store/thunk'

export const FundCenterHolding = () => {
  const dispatch = useDispatch()

  const tableTab = useSelector(selectTableTab)
  const loading = useSelector(selectLoading)

  const handleChangeTab = (tab) => {
    dispatch(changeTableTab(tab.value))
  }

  useEffect(() => {
    dispatch(getListTickersSelectThunk())
  }, [])

  return (
    <div className="h-100 position-relative">
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="fund.fundCenter.HOLDING" />
              <VerticalLine />
              <div style={{ marginTop: 6 }}>
                <TabLink
                  defaultTab={TABLE_TABS.ALL}
                  value={tableTab}
                  tabs={Object.keys(TABLE_TABS).map((key) => ({
                    title: `fund.fundCenter.holding.${key}`,
                    value: TABLE_TABS[key],
                  }))}
                  is18n
                  disabled={loading}
                  onClick={handleChangeTab}
                />
              </div>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <ScrollGroupComponent>
                  <div className="position-relative w-100 h-100">
                    <PanelHolding />
                  </div>
                </ScrollGroupComponent>
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
