import { ServiceBase } from '../ServiceBase'
import newsReportProxy from '../../proxies/market/NewsReportProxy'

class NewsReportService extends ServiceBase {
  getMarketReport(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getMarketReport',
      params,
    )(() => newsReportProxy.getMarketReport(params))
  }

  getListNews(params) {
    return this.getData(() => newsReportProxy.getListNews(params))
  }

  getMarketNews(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getMarketNews',
      params,
    )(() => newsReportProxy.getMarketNews(params))
  }

  getTrendingNews(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getTrendingNews',
      params,
    )(() => newsReportProxy.getTrendingNews(params))
  }

  getNewsCategories(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsCategories',
      params,
    )(() => newsReportProxy.getNewsCategories(params))
  }

  getReportTypes(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getReportTypes',
      params,
    )(() => newsReportProxy.getReportTypes(params))
  }

  getNewsSources(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsSources',
      params,
    )(() => newsReportProxy.getNewsSources(params))
  }

  getReportSources(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getReportSources',
      params,
    )(() => newsReportProxy.getReportSources(params))
  }

  getNewsById(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsById',
      params,
    )(() => newsReportProxy.getNewsById(params))
  }

  getNewsSameCategory(params) {
    return this.applyMemoryCache(
      'NewsReportsService.getNewsSameCategory',
      params,
    )(() => newsReportProxy.getNewsSameCategory(params))
  }
}

export default new NewsReportService()
