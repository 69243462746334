import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../common/helper'
import { getMaxDateOverview } from '../../store/thunk'
import { LIST_RADIO } from '../constants'
import { getGrowthTradeBalance } from './thunk'

const slice = createSlice({
  name: 'economy/importExport/growthTradeBalance',
  initialState: {
    isLoading: true,
    data: [],
    activeCategory: null,
    isChangingTab: false,
    typeCode: LIST_RADIO[0].value,
    isYearly: false,
    isAccMonthly: false,
    locale: null,
    maxDate: null,
  },
  reducers: {
    changeActiveCategory: (state, action) => {
      state.activeCategory = action.payload
    },
    changeTab: (state, action) => {
      state.isChangingTab = action.payload
    },
    changeTypeCode: (state, action) => {
      state.typeCode = action.payload
    },
    changeIsYearlyGrowthChart: (state, action) => {
      state.isYearly = action.payload
    },
    changeIsAccMonthlyGrowthChart: (state, action) => {
      state.isAccMonthly = action.payload
    },
    changeLocaleGrowthChart: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGrowthTradeBalance.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getGrowthTradeBalance.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangingTab = false

      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.vnTypeId || 1,
          name: item.vnTypeName,
          year: item.realYear,
          month: item.realMonth,
        })) || []

      state.data = convertData(
        payloadData,
        state.typeCode,
        state.isYearly,
        state.isAccMonthly,
        state.locale,
        state.maxDate,
      ).reverse()
    })
    builder.addCase(getGrowthTradeBalance.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getMaxDateOverview.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateOverview.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateOverview.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveCategory = (state) => state[slice.name].activeCategory
export const selectTypeCode = (state) => state[slice.name].typeCode

export const {
  changeActiveCategory,
  changeTab,
  changeTypeCode,
  changeIsAccMonthlyGrowthChart,
  changeIsYearlyGrowthChart,
  changeLocaleGrowthChart,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (
  data,
  typeCode,
  isYearly,
  isAccMonthly,
  locale,
  maxDate,
) => {
  //convert months and years to date keys
  const getDataKeyFromRange = isYearly
    ? getYearInDataRange(data).map((item) =>
        getYearKey(item.year, maxDate, locale),
      )
    : isAccMonthly
    ? getMonthAndYearInDataRange(data).map((item) =>
        getAccMonthAndYearKey(item.month, item.year, locale),
      )
    : getMonthAndYearInDataRange(data).map((item) =>
        getMonthAndYearKey(item.month, item.year),
      )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value:
          typeCode === 0 ? item.value / 1000000000 : item.growth * 100 ?? 0,
        id: item.id ?? 0,
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value:
          typeCode === 0 ? item.value / 1000000000 : item.growth * 100 ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange = isYearly
    ? getYearInDataRange(data).map((item) => [...dataWithYear(item.year)])
    : getMonthAndYearInDataRange(data).map((item) => [
        ...dataWithMonthAndYear(item.month, item.year),
      ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of id-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
