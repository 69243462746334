import useGetBond from './useGetBond'
import useGetCoveredWarrant from './useGetCoveredWarrant'
import useGetExchange from './useGetExchange'
import useGetExchangeV2 from './useGetExchangeV2'
import useGetFund from './useGetFund'
import useGetICB from './useGetICB'
import useGetIndices from './useGetIndices'
import useGetIndicesV2 from './useGetIndicesV2'
import useGetMaxMinTable from './useGetMaxMinTable'
import useGetPackageFunctions from './useGetPackageFunctions'
import useGetStock from './useGetStock'

const MasterData = () => {
  useGetExchange(false)
  useGetICB(false)
  useGetStock(false)
  useGetIndices(false)
  useGetCoveredWarrant(false)
  useGetPackageFunctions(false)
  useGetBond(false)
  useGetIndicesV2(false)
  useGetExchangeV2(false)
  useGetMaxMinTable(false)
  useGetFund(false)

  return <></>
}

export default MasterData
