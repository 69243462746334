import { useEffect } from 'react'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../common/calendar/CalendarPopper'
import InputDateCalendar from '../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import '../../index.css'
import HeaderCalendar from './HeaderCalendar'
import { TIME_RANGE } from './constants'
import style from './style.module.css'

const CalendarSearch = ({
  timeRangeState,
  setTimeRangeState,
  timeRangeDateState,
  setTimeRangeDateState,
}) => {
  const currentDate = [new Date()]
  const timeZone = UseTimeZone()
  const currentYear = new Date().getFullYear()

  const setDateFrom = (value) => {
    setTimeRangeDateState({
      ...timeRangeDateState,
      dateFrom: value || '',
    })
  }

  const setDateTo = (value) => {
    setTimeRangeDateState({
      ...timeRangeDateState,
      dateTo: value || '',
    })
  }

  const clearInputDate = () => {
    setTimeRangeDateState({
      dateTo: '',
      dateFrom: '',
    })

    setTimeRangeState(TIME_RANGE.ALL_TIME)
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  useEffect(() => {
    if (timeRangeDateState?.dateFrom || timeRangeDateState?.dateTo) {
      setTimeRangeState(TIME_RANGES['YTD'])
    } else if (!timeRangeDateState?.dateFrom && !timeRangeDateState?.dateTo) {
      setTimeRangeState(timeRangeState)
    }
  }, [timeRangeDateState])

  return (
    <>
      <div className="form-date-select">
        <Span style={{ fontSize: 12 }}>
          <Translate value="financialChart.FROM" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={
              timeRangeDateState?.dateFrom
                ? getDateWithTimeZone(timeRangeDateState?.dateFrom)
                : ''
            }
            handleChange={setDateFrom}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendar({
              startYear: 2005,
              endYear: currentYear,
            })}
            customInputStyle={{
              color: '#4E4E4E',
              width: 90,
              fontSize: 11,
              borderBottom: '1px solid #2f9aee',
            }}
            calendarClassName={'calender-white-theme'}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <Span style={{ fontSize: 12 }}>
          <Translate value="financialChart.TO" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={
              timeRangeDateState?.dateTo
                ? getDateWithTimeZone(timeRangeDateState?.dateTo)
                : ''
            }
            handleChange={(value) => setDateTo(value)}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendar({
              startYear: 2005,
              endYear: currentYear,
            })}
            customInputStyle={{
              color: '#4E4E4E',
              width: 90,
              fontSize: 11,
              borderBottom: '1px solid #2f9aee',
            }}
            calendarClassName={'calender-white-theme'}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <button
          className={style.buttonClear}
          type="button"
          disabled={
            (!timeRangeDateState?.dateFrom === timeRangeDateState?.dateTo) ===
            null
          }
          onClick={() => clearInputDate()}
        >
          <Span style={{ fontSize: 9 }}>
            <Translate value="financialChart.CLEAR" />
          </Span>
        </button>
      </div>
    </>
  )
}

CalendarSearch.propTypes = {}

export default CalendarSearch
