import { useSelector } from 'react-redux'
import PriceDepthPanel from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { HEADER_PANEL_HEIGHT } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { selectIndicator } from '../summary/store/slice'

const PanelPriceDepth = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const derivativeActive = useSelector(selectIndicator)
  const nameScreen = UseI18n('constants.route.market.derivative.LABEL')
  const chartName = UseI18n('market.derivative.PRICE_DEPTH')

  return (
    <Panel
      title={'market.derivative.PRICE_DEPTH'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('market.derivative.PRICE_DEPTH')}
      downloadJpgParams={{
        domId: 'derivativePriceDepth',
        nameScreen,
        chartName,
        tickerName: derivativeActive?.ticker,
      }}
    >
      <PreOpenMarket>
        <PriceDepthPanel
          height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        />
      </PreOpenMarket>
    </Panel>
  )
}

export default PanelPriceDepth
