import { TYPE } from '../../../../../common/chart/footer/Item'
import { valDivBillion } from '../../../../../utils/Value'
import { STACK_COLORS } from '../../constants'

export const renderListFooter = (stackChartKey) => {
  return stackChartKey.map((item, index) => {
    return {
      text: item,
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS[stackChartKey?.length][index],
      },
      isI18n: true,
    }
  })
}

export const getTooltipContentData = (data, listKey, activeTab) => {
  return listKey?.map((item, index) => {
    const isValueTab = activeTab === 'value'
    return {
      label: listKey[index],
      value: isValueTab
        ? data[`value${index + 1}`]
        : data[`value${index + 1}_P`],
    }
  })
}

export const formatDataStacked = (data, isValueTab) => {
  return data.map((item) => {
    let format = {}
    const values = Object.values(item)
    Object.keys(item).map((key, index) => {
      const isValue = key.includes('value')
      if (isValue) {
        format = {
          ...format,
          [key]: isValueTab
            ? valDivBillion(values[index])
            : values[index] * 100,
        }
      } else {
        format = {
          ...format,
          [key]: values[index],
        }
      }
      return true
    })
    return format
  })
}

export const findFooterStackChart = (currentStackKey, data) => {
  return data
    .filter((item) => {
      return item?.parentField === currentStackKey
    })
    .map((item) => item?.indicator)
}
