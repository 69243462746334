export const TABLE_TABS = {
  KEY_METRICS: 'keyMetrics',
  PERFORMANCE: 'performance',
  FUND_FLOW: 'fundFlow',
  SECTOR_ALLOCATION: 'sectorAllocation',
  ASSET_ALLOCATION: 'assetAllocation',
  // FUND_INDEX: 'fundIndex',
}

export const VALUE_TYPES = {
  RELATIVE: 'Relative',
  ABSOLUTE: 'Absolute',
}

export const TIME_RANGE_TYPES = {
  MOST_RECENT: 'mostRecent',
  SELECT_TIME_PERIOD: 'selectTimePeriod',
}

export const TIME_FREQUENCY_OPTIONS = [
  {
    name: 'common.FREQUENCY.DAILY',
    value: '17',
  },
  {
    name: 'common.FREQUENCY.WEEKLY',
    value: '18',
  },
  {
    name: 'common.FREQUENCY.MONTHLY',
    value: '19',
  },
  {
    name: 'common.FREQUENCY.QUARTERLY',
    value: '20',
  },
  {
    name: 'common.FREQUENCY.YEARLY',
    value: '21',
  },
]

export const ACTIVE_STATUS_TYPES = [
  {
    name: 'fund.fundCenter.ACTIVE',
    value: '1',
  },
  {
    name: 'fund.fundCenter.STOP',
    value: '2',
  },
  {
    name: 'fund.fundCenter.PAUSE',
    value: '3',
  },
  {
    name: 'fund.fundCenter.OTHER',
    value: '4',
  },
]

export const TABLE_COL_KEYS = {
  COL_INDEX: 'index',
  COL_FUND_TICKER: 'ticker',
  COL_FUND_NAME: 'fundName',
  COL_FUND_STRUCTURE: 'fundStructureName',
  COL_FUND_TYPE: 'fundTypeName',
  COL_FUND_MANAGEMENT: 'fundManagement',
  COL_FUND_FUND_STATUS: 'activeStatusName',
  COL_YEAR_IN_OPERATION: 'yearInOperation',
  COL_FREQUENCY: 'scheduleName',
  COL_UPDATE_DATE_NAV: 'endDateId',
  COL_TOTAL_NAV: 'totalNAV',
  COL_CHANGE_NAV_1M: 'rfD12',
  COL_CHANGE_NAV_YTD: 'rfD14',
  COL_FUND_FLOW_1M: 'fundFlow',
  COL_STD_CHANGE_1Y: 'stdChange1Y',
  COL_NAV_CCQ: 'naVperShareAdjusted',
  COL_NAV_CCQ_PERFORMANCE: 'colNavPerformance',
  COL_NAV_CCQ_PERFORMANCE_MOST_RECENT: 'percentNAVperShareChange',
  COL_NAV_CCQ_PERFORMANCE_YTD: 'rfD14',
  COL_NAV_CCQ_PERFORMANCE_1M: 'rfD9',
  COL_NAV_CCQ_PERFORMANCE_6M: 'rfD11',
  COL_NAV_CCQ_PERFORMANCE_1Y: 'rfD12',
  COL_NAV_CCQ_PERFORMANCE_3Y: 'rfD13',
  COL_NAV_CCQ_PERFORMANCE_5Y: 'navPerformance5Y',
  COL_TOTAL_FUND_FLOW: 'colTotalFundFlow',
  COL_TOTAL_FUND_FLOW_MOST_RECENT: 'fundFlow',
  COL_TOTAL_FUND_FLOW_3M: 'fundFlow3M',
  COL_TOTAL_FUND_FLOW_YTD: 'fundFlowYTD',
  COL_TOTAL_FUND_FLOW_1Y: 'fundFlow1Y',
  COL_SECTOR_ABSOLUTE: 'value',
  COL_SECTOR_RELATIVE: 'percent',
  COL_STOCK: 'colStock',
  COL_STOCK_VALUE: 'stock',
  COL_STOCK_MOST_RECENT_CHANGE: 'stockChange',
  COL_BOND: 'colBond',
  COL_BOND_VALUE: 'bond',
  COL_BOND_MOST_RECENT_CHANGE: 'bondChange',
  COL_CASH_AND_ORDER: 'colOther',
  COL_CASH_AND_ORDER_VALUE: 'others',
  COL_CASH_AND_ORDER_MOST_RECENT_CHANGE: 'otherChange',
  COL_VOLATILITY: 'rfD7',
  COL_SHARPE_RATIO: 'rfD5',
  COL_CORRELATION: 'rfD8',
  COL_FOREIGN_OWNED: 'foreignPercentage',
  COL_MAX_DRAWDOWN: 'maxDrawdown',
  COL_TREYNOR_RATIO: 'treynorRatio',
  COL_STD_1Y: 'std1Y',
  COL_STD_3Y: 'std3Y',
  COL_SHARPE_1Y: 'sharpe1Y',
  COL_SHARPE_3Y: 'sharpe3Y',
  COL_TRACKING_ERROR_1Y: 'trackingError1Y',
}

export const TABLE_COL_FORMATS = {
  COL_TOTAL_NAV: 10 ** 9,
  COL_CHANGE_NAV_1M: 1,
  COL_CHANGE_NAV_YTD: 1,
  COL_FUND_FLOW_1M: 10 ** 9,
  COL_STD_CHANGE_1Y: 1,
  COL_NAV_CCQ: 1,
  COL_NAV_CCQ_PERFORMANCE_MOST_RECENT: 1,
  COL_NAV_CCQ_PERFORMANCE_YTD: 1,
  COL_NAV_CCQ_PERFORMANCE_1M: 1,
  COL_NAV_CCQ_PERFORMANCE_6M: 1,
  COL_NAV_CCQ_PERFORMANCE_1Y: 1,
  COL_NAV_CCQ_PERFORMANCE_3Y: 1,
  COL_NAV_CCQ_PERFORMANCE_5Y: 1,
  COL_TOTAL_FUND_FLOW_MOST_RECENT: 10 ** 9,
  COL_TOTAL_FUND_FLOW_3M: 10 ** 9,
  COL_TOTAL_FUND_FLOW_YTD: 10 ** 9,
  COL_TOTAL_FUND_FLOW_1Y: 10 ** 9,
  COL_SECTOR_ABSOLUTE: 10 ** 9,
  COL_SECTOR_RELATIVE: 1,
  COL_STOCK_VALUE: 10 ** 9,
  COL_STOCK_MOST_RECENT_CHANGE: 10 ** 9,
  COL_BOND_VALUE: 10 ** 9,
  COL_BOND_MOST_RECENT_CHANGE: 10 ** 9,
  COL_CASH_AND_ORDER_VALUE: 10 ** 9,
  COL_CASH_AND_ORDER_MOST_RECENT_CHANGE: 10 ** 9,
  COL_VOLATILITY: 1,
  COL_SHARPE_RATIO: 1,
  COL_CORRELATION: 1,
  COL_FOREIGN_OWNED: 1,
  COL_MAX_DRAWDOWN: 1,
  COL_TREYNOR_RATIO: 1,
  COL_STD_1Y: 1,
  COL_STD_3Y: 1,
  COL_SHARPE_1Y: 1,
  COL_SHARPE_3Y: 1,
  COL_TRACKING_ERROR_1Y: 1,
}

export const TABLE_DEFAULT_KEYS = [
  'COL_INDEX',
  'COL_FUND_TICKER',
  'COL_FUND_NAME',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_DEFAULT_BOTTOM_KEYS = [
  'COL_FUND_STRUCTURE',
  'COL_FUND_TYPE',
  'COL_FUND_MANAGEMENT',
  'COL_FREQUENCY',
  'COL_FUND_FUND_STATUS',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_KEY_METRICS_KEYS = [
  // 'COL_YEAR_IN_OPERATION',
  'COL_UPDATE_DATE_NAV',
  'COL_TOTAL_NAV',
  'COL_CHANGE_NAV_1M',
  'COL_CHANGE_NAV_YTD',
  'COL_FUND_FLOW_1M',
  // 'COL_STD_CHANGE_1Y',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_PERFORMANCE_KEYS = [
  'COL_UPDATE_DATE_NAV',
  'COL_TOTAL_NAV',
  'COL_NAV_CCQ',
  'COL_NAV_CCQ_PERFORMANCE_MOST_RECENT',
  'COL_NAV_CCQ_PERFORMANCE_YTD',
  'COL_NAV_CCQ_PERFORMANCE_1M',
  'COL_NAV_CCQ_PERFORMANCE_6M',
  'COL_NAV_CCQ_PERFORMANCE_1Y',
  'COL_NAV_CCQ_PERFORMANCE_3Y',
  // 'COL_NAV_CCQ_PERFORMANCE_5Y',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_PERFORMANCE_TH_ROW_KEYS = [
  [
    'COL_UPDATE_DATE_NAV',
    'COL_TOTAL_NAV',
    'COL_NAV_CCQ',
    'COL_NAV_CCQ_PERFORMANCE',
  ].map((key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
  [
    'COL_NAV_CCQ_PERFORMANCE_MOST_RECENT',
    'COL_NAV_CCQ_PERFORMANCE_YTD',
    'COL_NAV_CCQ_PERFORMANCE_1M',
    'COL_NAV_CCQ_PERFORMANCE_6M',
    'COL_NAV_CCQ_PERFORMANCE_1Y',
    'COL_NAV_CCQ_PERFORMANCE_3Y',
    // 'COL_NAV_CCQ_PERFORMANCE_5Y',
  ].map((key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]

export const TABLE_FUND_FLOW_KEYS = [
  'COL_UPDATE_DATE_NAV',
  'COL_TOTAL_FUND_FLOW_MOST_RECENT',
  'COL_TOTAL_FUND_FLOW_3M',
  'COL_TOTAL_FUND_FLOW_YTD',
  'COL_TOTAL_FUND_FLOW_1Y',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_FUND_FLOW_TH_ROW_KEYS = [
  ['COL_UPDATE_DATE_NAV', 'COL_TOTAL_FUND_FLOW'].map((key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
  [
    'COL_TOTAL_FUND_FLOW_MOST_RECENT',
    'COL_TOTAL_FUND_FLOW_3M',
    'COL_TOTAL_FUND_FLOW_YTD',
    'COL_TOTAL_FUND_FLOW_1Y',
  ].map((key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]

export const TABLE_SECTOR_ALLOCATION_KEYS = ['COL_UPDATE_DATE_NAV'].map(
  (key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  }),
)

export const TABLE_ASSET_ALLOCATION_KEYS = [
  'COL_UPDATE_DATE_NAV',
  'COL_STOCK_VALUE',
  'COL_STOCK_MOST_RECENT_CHANGE',
  'COL_BOND_VALUE',
  'COL_BOND_MOST_RECENT_CHANGE',
  'COL_CASH_AND_ORDER_VALUE',
  'COL_CASH_AND_ORDER_MOST_RECENT_CHANGE',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))

export const TABLE_ASSET_ALLOCATION_TH_ROW_KEYS = [
  ['COL_UPDATE_DATE_NAV', 'COL_STOCK', 'COL_BOND', 'COL_CASH_AND_ORDER'].map(
    (key) => ({
      title: 'fund.fundCenter.statistic.' + key,
      colId: TABLE_COL_KEYS[key],
    }),
  ),
  [
    'COL_STOCK_VALUE',
    'COL_STOCK_MOST_RECENT_CHANGE',
    'COL_BOND_VALUE',
    'COL_BOND_MOST_RECENT_CHANGE',
    'COL_CASH_AND_ORDER_VALUE',
    'COL_CASH_AND_ORDER_MOST_RECENT_CHANGE',
  ].map((key) => ({
    title: 'fund.fundCenter.statistic.' + key,
    colId: TABLE_COL_KEYS[key],
  })),
]

export const TABLE_FUND_INDEX_KEYS = [
  'COL_UPDATE_DATE_NAV',
  'COL_VOLATILITY',
  'COL_SHARPE_RATIO',
  'COL_CORRELATION',
  'COL_FOREIGN_OWNED',
  // 'COL_MAX_DRAWDOWN',
  // 'COL_TREYNOR_RATIO',
  // 'COL_STD_1Y',
  // 'COL_STD_3Y',
  // 'COL_SHARPE_1Y',
  // 'COL_SHARPE_3Y',
  // 'COL_TRACKING_ERROR_1Y',
].map((key) => ({
  title: 'fund.fundCenter.statistic.' + key,
  colId: TABLE_COL_KEYS[key],
}))
