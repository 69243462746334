import { COM_GROUP } from '../../../../constants/Group'
import { CHARTS } from './constant'

export const getChartTypes = (comGroup) => {
  if (comGroup && comGroup === COM_GROUP.BANK) {
    return Object.fromEntries(
      Object.entries(CHARTS).filter(
        ([key, value]) => value !== CHARTS.EVEBITDA,
      ),
    )
  } else {
    return Object.fromEntries(
      Object.entries(CHARTS).filter(([key, value]) => value !== CHARTS.PS),
    )
  }
}

export const getChartColumnKeyYAxis = (type) => {
  switch (type) {
    case CHARTS.PE:
      return {
        diamondKey: 'rtD0022_4',
        lineKey: 'rtD0022_3',
        minBarKey: 'rtD0022_2',
        maxBarKey: 'rtD0022_1',
      }
    case CHARTS.PB:
      return {
        diamondKey: 'rtD0019_4',
        lineKey: 'rtD0019_3',
        minBarKey: 'rtD0019_2',
        maxBarKey: 'rtD0019_1',
      }
    case CHARTS.PS:
      return {
        diamondKey: 'rtD0025_4',
        lineKey: 'rtD0025_3',
        minBarKey: 'rtD0025_2',
        maxBarKey: 'rtD0025_1',
      }
    default:
      // CHARTS.EVSALE
      return {
        diamondKey: 'rtD0026_4',
        lineKey: 'rtD0026_3',
        minBarKey: 'rtD0026_2',
        maxBarKey: 'rtD0026_1',
      }
  }
}

export const getChartLineKeyYAxis = (type) => {
  switch (type) {
    case CHARTS.PE:
      return 'rtD0022'
    case CHARTS.PB:
      return 'rtD0019'
    case CHARTS.PS:
      return 'rtD0025'
    case CHARTS.EVSALE:
      return 'rtD0026'
    default:
      return ''
  }
}
