import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import useGetHeaderSearch from '../common/hooks/useGetHeaderSearch'
import { LOCAL_STORAGE_KEY } from '../constants/Common'
import { FEATURES } from '../constants/routes'
import CurrentState from '../utils/CurrentState'
import EventEmitter, { SHOW_LOADING_SCREEN } from '../utils/EventEmitter'
import authProvider from './../../core/AuthenticationProvider'

const SignInCallBack = () => {
  const navigate = useNavigate()
  const headerSearch = useGetHeaderSearch()

  useEffect(() => {
    const currentState = CurrentState.getCurrentState()
    authProvider
      .signinCallback()
      .then(async () => {
        const user = await authProvider.getUser()
        EventEmitter.dispatch(SHOW_LOADING_SCREEN, true)
        if (user && !user?.profile?.user_id) {
          return authProvider.signout()
        }

        const returnUrl = localStorage.getItem(LOCAL_STORAGE_KEY.RETURN_URL)
        if (returnUrl) {
          navigate(returnUrl)
          localStorage.removeItem(LOCAL_STORAGE_KEY.RETURN_URL)
        } else {
          navigate(currentState.data.route, {
            state: { headerSearch },
          })
        }
      })
      .catch(() => {
        navigate(FEATURES.corporate.routeDefault)
      })
  }, [])

  return null
}

export default SignInCallBack
