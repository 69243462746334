import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import { SESSION } from '../../../../common/chart/constants'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { AreaLineChartComponent } from './AreaLineChartComponent'
import { ChartFilter } from './ChartFilter'
import { HorizontalStackBarChartComponent } from './HorizontalStackBarChartComponent'
import {
  timeSaleChartType,
  timeSaleDateFilter,
  timeSaleDateFormatAPI,
} from './constants'
import {
  changeLoading,
  selectAreaData,
  selectBarData,
  selectLoading,
  subscribeTimeSaleBarChart,
} from './store/slice'
import {
  getDeepTransactionTimeSaleAreaChart,
  getDeepTransactionTimeSaleBarChart,
} from './store/thunk'

export const TimeSale = ({ width, height, onChangeChart, onChangeDate }) => {
  const dispatch = useDispatch()
  const interval = useRef()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const areaData = useSelector(selectAreaData)
  const barData = useSelector(selectBarData)
  const isLoading = useSelector(selectLoading)

  // Use state
  const [chartType, setChartType] = useState(
    timeSaleChartType.HORIZONTAL_STACK_BAR,
  )
  const [dateType, setDateType] = useState(timeSaleDateFilter.ONE_DAY)

  // Get data
  const startTimeSession = new Date().setHours(
    SESSION.start.hour,
    SESSION.start.minute,
    0,
    0,
  )

  const endTimeSession = new Date().setHours(
    SESSION.end.hour,
    SESSION.end.minute,
    0,
    0,
  )

  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [basicInfo.organizationId, dateType])

  // Actions
  const onChangeChartType = (value) => {
    setChartType(value)
    onChangeChart(value)
  }

  const onChangeDateType = (value) => {
    setDateType(value)
    onChangeDate(value)
  }

  const clearIntervalRealtime = () => {
    clearInterval(interval.current)
    interval.current = undefined
  }

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(changeLoading())

      const params = {
        OrganizationId: basicInfo.organizationId,
        TimeRange: timeSaleDateFormatAPI[dateType],
        ExchangeCode: basicInfo.exchange,
      }

      if (chartType === timeSaleChartType.AREA_LINE) {
        dispatch(getDeepTransactionTimeSaleAreaChart(params))

        if (dateType === timeSaleDateFilter.ONE_DAY) {
          interval.current = setInterval(() => {
            const now = new Date().getTime()

            if (now > endTimeSession) {
              clearIntervalRealtime()
            } else if (now >= startTimeSession) {
              dispatch(getDeepTransactionTimeSaleAreaChart(params))
            }
          }, 30 * 1000)
        } else {
          clearIntervalRealtime()
        }
      } else {
        dispatch(getDeepTransactionTimeSaleBarChart(params))
        clearIntervalRealtime()
      }
    }

    return () => {
      if (interval.current) {
        clearIntervalRealtime()
      }
    }
  }, [basicInfo.organizationId, chartType, dateType])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <ChartFilter
            chartType={chartType}
            dateType={dateType}
            setChartType={onChangeChartType}
            setDateType={onChangeDateType}
          />
          {size.height && (
            <div
              id="timeSale"
              style={{ height: `calc(100% - ${size.height}px)` }}
            >
              {isLoading ? (
                <Loading />
              ) : chartType === timeSaleChartType.AREA_LINE ? (
                <>
                  {areaData &&
                  areaData.filter((item) => !item.isFakeData).length > 0 ? (
                    <AreaLineChartComponent
                      key={getKey}
                      width={width}
                      height={height - size.height}
                      data={areaData}
                      dateType={dateType}
                    />
                  ) : (
                    <NoData />
                  )}
                </>
              ) : (
                <>
                  {barData && barData.length > 0 ? (
                    <HorizontalStackBarChartComponent
                      key={getKey}
                      width={width}
                      height={height - size.height}
                      data={barData}
                    />
                  ) : (
                    <NoData />
                  )}
                </>
              )}
              <Realtime
                channel={ChannelConfig.StockChannel + basicInfo.organizationId}
                action={subscribeTimeSaleBarChart}
              >
                <></>
              </Realtime>
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

TimeSale.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  onChangeChart: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func.isRequired,
}
