import React, { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Sample from './Sample'
import { changePageNumber, selectZoomFile } from './store/slice'

const Preview = forwardRef((_, ref) => {
  const dispatch = useDispatch()
  let els = document.getElementsByClassName('A4')
  const zoomFile = useSelector(selectZoomFile)

  const setValueAfterUpdate = (val) => {
    const zoom = parseFloat(zoomFile) / 100
    const page = val.scrollTop / (els[0]?.offsetHeight * zoom) + 1
    dispatch(changePageNumber(Math.floor(page)))
  }

  return (
    <div className="h-100" style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 40,
          height: 'calc(100% - 40px)',
          width: '100%',
        }}
      >
        <ScrollComponent
          scrollbarsRef={ref}
          setValueAfterUpdate={setValueAfterUpdate}
        >
          <Sample />
        </ScrollComponent>
      </div>
    </div>
  )
})

export default Preview
