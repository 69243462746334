import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import PopupAddIndicator from '../../popup/popupAddIndicator'
import style from '../style.module.css'

const AddMoreIndicator = ({ typeIndicator }) => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(true)
  }

  return (
    <>
      <div className={style.moreIndicator} onClick={onClick}>
        <i className="icon-plus-circle-line" style={{ marginRight: 4 }} />
        <Translate value="financialChart.MORE_INDICATORS" />
      </div>
      {isShow && (
        <PopupAddIndicator
          setIsShow={setIsShow}
          typeIndicator={typeIndicator}
        />
      )}
    </>
  )
}

export default AddMoreIndicator
