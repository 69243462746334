import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getBankMaxPeriod = createAsyncThunk(
  'corporate/businessModel/penetration/bank/getBankMaxPeriod',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await PenetrationAnalysisService.getBankMaxPeriod(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
