import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import UseI18n from '../../../../common/hooks/useI18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { formatVal } from '../../../../utils/Value'
import { HEADER_VALUE } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import {
  getLimitChartLoading,
  getValueGrowthNominal,
  getValueGrowthReal,
} from '../panelGDPProvince/store/slice'
import { getNominalRealValue } from '../store/slice'
import style from './index.module.css'
import {
  getBarChartDataInSlice,
  getBarChartLoading,
  getYear,
} from './store/slice'
import { getProvincesByGRDP } from './store/thunk'

const { NOMINAL } = HEADER_VALUE
const MARGIN_BOTTOM_CHART = 10

const ChartComponent = ({ height, width }) => {
  const dispatch = useDispatch()
  const { PER_CAPITA, GROWTH_YOY } = GDP_PROVINCE_VALUE
  const KEY_X_AXIS = 'locationName'

  const barChartLoading = useSelector(getBarChartLoading)
  const barChartData = useSelector(getBarChartDataInSlice)
  const nominalRealValue = useSelector(getNominalRealValue)
  const year = useSelector(getYear)
  const locale = useSelector((state) => state.i18n.locale)
  const limitChartLoading = useSelector(getLimitChartLoading)
  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  const renderLabelYAxis = (nominalRealValue, valueGrowthOptions) => {
    switch (valueGrowthOptions) {
      case PER_CAPITA: {
        if (nominalRealValue === NOMINAL) {
          return 'economy.gdp.gdp.MILLION_VND'
        } else {
          return ''
        }
      }
      case GROWTH_YOY: {
        return ''
      }
      default:
        return 'economy.gdp.gdp.BILLION_VND'
    }
  }

  const keyYAxis = [
    {
      id: 'left',
      keys: ['value'],
      orientation: 'left',
      isBarChart: true,
      label: UseI18n(renderLabelYAxis(nominalRealValue, radioValue)),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: 5,
      unitYAxis:
        radioValue === GROWTH_YOY ||
        (nominalRealValue !== NOMINAL && radioValue === PER_CAPITA)
          ? '%'
          : '',
    },
  ]

  const renderTooltipUnit = (nominalRealValue, valueGrowthOptions) => {
    switch (valueGrowthOptions) {
      case PER_CAPITA: {
        if (nominalRealValue === NOMINAL) {
          return 'economy.gdp.gdp.MILLION_TOOLTIP'
        } else {
          return '%'
        }
      }
      case GROWTH_YOY: {
        return '%'
      }
      default:
        return 'economy.gdp.gdp.BILLION_TOOLTIP'
    }
  }

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{year}</Span>
        </div>
        <div className={style.chartTooltip}>
          <Span className={style.tooltipContentKey} style={{ fontSize: 11 }}>
            {payload[KEY_X_AXIS]}:
          </Span>
          <Span
            style={{ marginLeft: 25, fontSize: 11 }}
            className={`${style.toolTipContentValue} ${
              typeof payload.value === 'number' &&
              payload.value < 0 &&
              style.redColor
            }`}
          >
            {formatVal(payload.value)}
            {typeof payload.value === 'number' && (
              <Translate
                value={renderTooltipUnit(nominalRealValue, radioValue)}
              />
            )}
          </Span>
        </div>
      </>
    )
  }

  useEffect(() => {
    dispatch(
      getProvincesByGRDP({
        IsNominal: nominalRealValue === NOMINAL,
        Year: year,
        GDPTypeCode: radioValue,
      }),
    )
  }, [nominalRealValue, year, valueGrowthNominal, valueGrowthReal, locale])

  if (barChartLoading || limitChartLoading) {
    return <Loading />
  }

  if (barChartData.length === 0) {
    return <NoData />
  }

  return (
    <ChartContainer
      data={barChartData}
      height={height - MARGIN_BOTTOM_CHART}
      width={width}
      keyXAxis={KEY_X_AXIS}
      yAxis={keyYAxis}
      renderCustomTooltip={renderTooltip}
      isUseXAxisDiv
      showAllTickInTwoLines
      showAllTick
      isBreakNewLine
      isShowXAxis
      margin={{
        ...MARGIN_RECHARTS,
        left:
          radioValue === GROWTH_YOY ||
          (nominalRealValue !== NOMINAL && radioValue === PER_CAPITA)
            ? 10
            : 0,
      }}
    >
      {({ chartContentWidth }) => {
        return (
          <Bar
            isAnimationActive={false}
            yAxisId={'left'}
            dataKey={'value'}
            barSize={getColumnSizeInBarChart(
              chartContentWidth,
              barChartData.length,
            )}
            fill={'#0096eb'}
          />
        )
      }}
    </ChartContainer>
  )
}

export default ChartComponent
