import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span.js'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab.js'
import {
  GROUP_MARKET_LIQUIDITY_RADIOS,
  MARKET_LIQUIDITY_TABS,
} from '../constants.js'
import {
  changeFilter,
  keys,
  selectFilterMarketLiquidity,
} from '../store/slice.js'
import { getMarketLiquidityChartData } from '../store/thunk.js'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const { TimeRange, GroupId } = useSelector(selectFilterMarketLiquidity)

  const handleChangeRadio = (value) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_LIQUIDITY,
        key: 'GroupId',
        value,
      }),
    )
  }

  const handleChangeActiveTab = (item) => {
    dispatch(
      changeFilter({
        label: keys.MARKET_LIQUIDITY,
        key: 'TimeRange',
        value: item.value,
      }),
    )
  }

  useEffect(() => {
    const payload = {
      TimeRange,
      GroupId,
    }

    dispatch(getMarketLiquidityChartData(payload))
  }, [locale, TimeRange, GroupId])

  return (
    <div className="d-flex mb-8 justify-content-space-between">
      <div className="d-flex ali-center">
        {GROUP_MARKET_LIQUIDITY_RADIOS.map(({ value, name }) => {
          return (
            <div
              key={value}
              className={`d-flex align-center ${style.wrapRadio}
                  ${value === GroupId && style.radioActive}
                  `}
              onClick={() => handleChangeRadio(value)}
            >
              <input
                type="radio"
                className="radiobox radiobox2"
                checked={value === GroupId}
                readOnly={true}
              />
              <Span style={{ fontSize: 12, fontWeight: 340 }}>
                <Translate value={name} />
              </Span>
            </div>
          )
        })}
      </div>
      <div>
        <DispatchActionTab
          data={MARKET_LIQUIDITY_TABS}
          activeTab={TimeRange}
          onChangeTab={handleChangeActiveTab}
          containerStyle={{ width: 135 }}
          itemStyle={{
            width: 'calc(100% / 3)',
            fontWeight: 600,
          }}
        />
      </div>
    </div>
  )
}

export default Filter
