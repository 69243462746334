import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import TableTotalGDP from './TableTotalGDP'
import { GDP_SECTOR_VALUE } from './constants'
import {
  getMinQuarterYear,
  getValueGrowthYoY,
  getYearlyQuarterly,
} from './store/slice'
import { downloadGDPBySector } from './store/thunk'

const { NOMINAL } = HEADER_VALUE
const { VALUE } = GDP_SECTOR_VALUE

const PanelTableTotalGDP = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const minQuarterYear = useSelector(getMinQuarterYear)
  const timeFrequency = useSelector(getYearlyQuarterly)
  const nominalRealValue = useSelector(getNominalRealValue)
  const valueGrowthYoY = useSelector(getValueGrowthYoY)
  const isValue =
    nominalRealValue === NOMINAL ? false : valueGrowthYoY === VALUE

  const downloadCsv = () => {
    const params = {
      TimeFrequency: timeFrequency,
      IsNominal: nominalRealValue === NOMINAL,
      IsValue: isValue,
    }

    if (minQuarterYear.minQuarter || minQuarterYear.prevMinQuarter) {
      params.Quarter =
        minQuarterYear.minQuarter || minQuarterYear.prevMinQuarter
    }

    if (minQuarterYear.minYear || minQuarterYear.prevMinYear) {
      params.Year = minQuarterYear.minYear || minQuarterYear.prevMinYear
    }

    return downloadGDPBySector(params)
  }

  return (
    <Panel
      title={'economy.gdp.gdp.TOTAL_GDP'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <TableTotalGDP
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelTableTotalGDP
