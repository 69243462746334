import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectActiveMethodType } from '../store/slice'
import { ChartComponent } from './ChartComponent'
import FilterCountry from './dropdown'
import {
  selectDataChart,
  selectIcbSelect,
  selectLoading,
  selectMethodType,
} from './store/slice'
import { getDataValueOfBonds } from './store/thunk'

const Content = ({ width, height }) => {
  const icb = useSelector(selectIcbSelect)
  const activeMethod = useSelector(selectMethodType)
  const dispatch = useDispatch()
  const TimeRangeActive = useSelector(selectActiveMethodType)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)

  const [listCheckbox, setListCheckbox] = useState([
    'issueValue',
    'valueOfCancelBond',
    'redemptionValue',
    'outstandingBond',
  ])
  useEffect(() => {
    dispatch(
      getDataValueOfBonds({
        TimeRange: TimeRangeActive,
        ReleaseMethodType: activeMethod,
        ICBId: icb || null,
      }),
    )
  }, [TimeRangeActive, activeMethod, icb])

  const checkFooter = () => {
    return [
      {
        text: 'bond.overview.outStandingBons.chart.issueValue',
        dataKey: 'issueValue',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#185999',
        },
      },
      {
        text: 'bond.overview.outStandingBons.chart.redemptionValue',
        dataKey: 'valueOfCancelBond',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#0096EB',
        },
      },
      {
        text: 'bond.overview.outStandingBons.chart.valueOfCancelBond',
        dataKey: 'redemptionValue',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#FACC5C',
        },
      },
      {
        text: 'bond.overview.outStandingBons.chart.outstandingBond',
        dataKey: 'outstandingBond',
        type: TYPE.SQUARE,
        before: {
          bgColor: '#A6CF98',
        },
      },
    ]
  }
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterCountry />
            {size.height && (
              <div style={{ height: height - size.height }}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChart.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div id="FDIoutStandingBons">
                              {size.height && (
                                <ChartComponent
                                  data={dataChart}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'date'}
                                  displayData={listCheckbox}
                                  name1={'outstandingBond'}
                                  name2={'issueValue'}
                                  name3={'valueOfCancelBond'}
                                  name4={'redemptionValue'}
                                  listFooter={checkFooter()}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={checkFooter()}
                                listCheckbox={listCheckbox}
                                // setListCheckbox={setListCheckbox}
                                setListCheckbox={(e) => setListCheckbox(e)}
                                numItemPerRow={4}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Content
