import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import Information from './Information'
import GeneralSetting from './generalSetting'
import InterestChart from './interestChart'
import OderBook from './oderBook'
import TradingInformation from './tradingInformation'

const MAP_KEY = {
  GENERAL_SETTING: 'GENERAL_SETTING',
  TRADING_INFORMATION: 'TRADING_INFORMATION',
  INFORMATION: 'INFORMATION',
  ODER_BOOK: 'ODER_BOOK',
  INTERREST_CHART: 'INTERREST_CHART',
}

const MAP_SIZE = {
  [`${MAP_KEY.GENERAL_SETTING}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.TRADING_INFORMATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],

    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.TRADING_INFORMATION,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.INFORMATION,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.ODER_BOOK,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.INTERREST_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.TRADING_INFORMATION}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.INFORMATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INFORMATION}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.ODER_BOOK}`]: {
    width: `calc(100% /2 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5 - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.INTERREST_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INTERREST_CHART}`]: {
    width: `calc(50%)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 'calc(50%)',
    left: 'calc(50%)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 1.5,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 1.5,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <GeneralSetting
        panelRefs={panelRefs}
        panelKey={MAP_KEY.GENERAL_SETTING}
        sizes={sizes}
        setSizes={setSizes}
      />
      <TradingInformation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TRADING_INFORMATION}
        sizes={sizes}
        setSizes={setSizes}
      />
      <Information
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INFORMATION}
        sizes={sizes}
        setSizes={setSizes}
      />
      <OderBook
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ODER_BOOK}
        sizes={sizes}
        setSizes={setSizes}
      />
      <InterestChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INTERREST_CHART}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
