import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GeneralSetting from '../../../../../../../core/services/bond/bondSecurity/generalSetting'
import WatchList from '../../../../../../../core/services/bond/bondSecurity/watchList'
import { handleExport } from '../../../../../../utils/Export'

export const getDataThunk = createAsyncThunk(
  'bond/bondSecurity/generalSetting/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await GeneralSetting.getDataSecurity(params)
      if (response.success) {
        return response?.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getDataWatchList = createAsyncThunk(
  'bond/bondSecurity/generalSetting/GET_DATA_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchList.getDataWatchLisst()
      if (response.success) {
        const data = response?.data || []
        const dataSort = data.sort((a, b) =>
          a?.bondWatchListName.localeCompare(b?.bondWatchListName, params),
        )
        return dataSort
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const exportFileApi = createAsyncThunk(
  'bond/bondSecurity/generalSetting/exportFile',
  async (data) => {
    const response = await GeneralSetting.exportFile(data)
    handleExport(response.data, response.filename)
  },
)

export const updateMultiWatchlistDetails = createAsyncThunk(
  'bond/bondSecurity/generalSetting/UPDATE_MULTI_WATCHLIST_DETAILS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchList.updateMultiWatchlistDetailsSv(
        params.data,
      )
      if (params.cb) return params.cb()
      if (response.success) {
        // return dataSort
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
