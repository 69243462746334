import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import { listPeriod, TYPE_TIME_RANGE } from '../constants'
import {
  changeTimeRangeForeignNetBuyingSelling,
  changeTimeRangeStockPerformanceVsIndex,
  selectTimeRangeForeignNetBuyingSelling,
  selectTimeRangeStockPerformanceVsIndex,
} from '../store/slice'
import style from '../style.module.css'
import CalendarSearch from './CalendarSearch'

const PopupSettingTimeRange = ({ typeSetting }) => {
  const [isShow, setIsShow] = useState(false)

  const showPopup = () => {
    setIsShow(true)
  }

  return (
    <>
      <i
        className={`${style.iconSettingHeader} icon-setting-stroke`}
        onClick={showPopup}
      />
      <SettingTimeRange
        isShow={isShow}
        setIsShow={setIsShow}
        typeSetting={typeSetting}
      />
    </>
  )
}

export default PopupSettingTimeRange

const SettingTimeRange = ({ isShow, setIsShow, typeSetting }) => {
  const dispatch = useDispatch()
  const timeRangeStockPerformanceVsIndex = useSelector(
    selectTimeRangeStockPerformanceVsIndex,
  )
  const timeRangeForeignNetBuyingSelling = useSelector(
    selectTimeRangeForeignNetBuyingSelling,
  )

  const [valuePopupTimeRange, setValuePopupTimeRange] = useState(
    typeSetting === 'stockPerformanceVsIndex'
      ? timeRangeStockPerformanceVsIndex
      : timeRangeForeignNetBuyingSelling,
  )

  const handleCancel = () => {
    setIsShow(false)
  }

  const handleChangeRadioType = (val) => {
    setValuePopupTimeRange({ ...valuePopupTimeRange, type: val })
  }

  const handleChangeRadioPeriod = (val) => {
    setValuePopupTimeRange({ ...valuePopupTimeRange, period: val })
  }

  const handleChangeDateFrom = (val) => {
    setValuePopupTimeRange({ ...valuePopupTimeRange, rangeForm: val })
  }

  const handleChangeDateTo = (val) => {
    setValuePopupTimeRange({ ...valuePopupTimeRange, rangeTo: val })
  }

  const handleSubmit = () => {
    if (typeSetting === 'stockPerformanceVsIndex') {
      dispatch(changeTimeRangeStockPerformanceVsIndex(valuePopupTimeRange))
    } else {
      dispatch(changeTimeRangeForeignNetBuyingSelling(valuePopupTimeRange))
    }
    handleCancel()
  }

  useEffect(() => {
    if (typeSetting === 'stockPerformanceVsIndex') {
      setValuePopupTimeRange(timeRangeStockPerformanceVsIndex)
    } else {
      setValuePopupTimeRange(timeRangeForeignNetBuyingSelling)
    }
  }, [isShow])

  return (
    <>
      {isShow ? (
        <Popup>
          <div className="modal modal-medium" style={{ width: 764 }}>
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="corporate.companySnapshot.SELECT_TIME_RANGE" />
              </h3>
              <a onClick={handleCancel} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div
              className={`modal-content ${style.modalContent}`}
              style={{ paddingBottom: 16 }}
            >
              <div>
                <ul className={`list-check mt-0`}>
                  <li key={TYPE_TIME_RANGE.PERIOD} className={style.liOrange}>
                    <label>
                      <input
                        type="radio"
                        className={`radiobox radiobox2 ${style.radioOrange} ${style.sizeRadio}`}
                        checked={
                          valuePopupTimeRange.type === TYPE_TIME_RANGE.PERIOD
                        }
                        onChange={() =>
                          handleChangeRadioType(TYPE_TIME_RANGE.PERIOD)
                        }
                      />
                      <Span
                        style={{ fontSize: 12 }}
                        className={
                          valuePopupTimeRange.type === TYPE_TIME_RANGE.PERIOD
                            ? style.activeCheckOrange
                            : ''
                        }
                      >
                        <Translate value="corporate.companySnapshot.PERIOD_LABEL" />
                      </Span>
                    </label>
                  </li>
                  <ul
                    className={`list-check d-flex align-center ${
                      style.liBlue
                    } ${
                      valuePopupTimeRange.type === TYPE_TIME_RANGE.RANGE
                        ? style.disabled
                        : ''
                    }`}
                    style={{
                      justifyContent: 'space-between',
                      marginBottom: 28,
                      marginTop: 12,
                      marginLeft: 18,
                    }}
                  >
                    {listPeriod.map((item) => (
                      <li
                        key={item.value}
                        className="mb-0"
                        style={{ marginRight: 24 }}
                      >
                        <label>
                          <input
                            type="radio"
                            className={`radiobox radiobox2 ${style.sizeRadio}`}
                            checked={item.value === valuePopupTimeRange.period}
                            onChange={() => handleChangeRadioPeriod(item.value)}
                            disabled={
                              valuePopupTimeRange.type === TYPE_TIME_RANGE.RANGE
                            }
                          />
                          <Span
                            style={{ fontSize: 12, whiteSpace: 'nowrap' }}
                            className={
                              item.value === valuePopupTimeRange.period
                                ? style.active
                                : ''
                            }
                          >
                            <Translate
                              value={`corporate.companySnapshot.${item.label}`}
                            />
                          </Span>
                        </label>
                      </li>
                    ))}
                  </ul>
                  <li key={TYPE_TIME_RANGE.RANGE} className={style.liOrange}>
                    <label>
                      <input
                        type="radio"
                        className={`radiobox radiobox2 ${style.radioOrange} ${style.sizeRadio}`}
                        checked={
                          valuePopupTimeRange.type === TYPE_TIME_RANGE.RANGE
                        }
                        onChange={() =>
                          handleChangeRadioType(TYPE_TIME_RANGE.RANGE)
                        }
                      />
                      <Span
                        style={{ fontSize: 12 }}
                        className={
                          valuePopupTimeRange.type === TYPE_TIME_RANGE.RANGE
                            ? style.activeCheckOrange
                            : ''
                        }
                      >
                        <Translate value="corporate.companySnapshot.RANGE_LABEL" />
                      </Span>
                    </label>
                  </li>
                  <CalendarSearch
                    type={typeSetting}
                    disabled={
                      valuePopupTimeRange.type === TYPE_TIME_RANGE.PERIOD
                    }
                    value={valuePopupTimeRange}
                    handleChangeDateFrom={handleChangeDateFrom}
                    handleChangeDateTo={handleChangeDateTo}
                  />
                </ul>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: 56 }}
              >
                <Button
                  onClick={handleCancel}
                  style={{ fontSize: 11 }}
                  className={`btn w-80 h-20 ${style.btnCancel} ${style.mr16}`}
                >
                  <Translate value="common.button.BUTTON_CANCEL" />
                </Button>
                <Button
                  onClick={handleSubmit}
                  style={{ fontSize: 11 }}
                  className={`btn btn-blue w-80 h-20`}
                >
                  <Translate value="tool.smartScreening.APPLY" />
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      ) : (
        <></>
      )}
    </>
  )
}
