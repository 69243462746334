import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import { NUMBER_OF_PERIOD } from '../constants'
import { changeFilter, selectPeriod } from '../store/slice'

export const FilterPeriod = () => {
  const dispatch = useDispatch()

  // Use selector
  const period = useSelector(selectPeriod)

  // Actions
  const onSelectPeriod = (val) => {
    dispatch(changeFilter({ period: val }))
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <Span style={{ opacity: 0.4, fontSize: 10 }}>
        <Translate value={'sector.financialAnalysis.corporate.PERIOD'} />
      </Span>
      <div className="mt-8">
        <SelectCustom
          isI18n={true}
          selectData={NUMBER_OF_PERIOD}
          value={period}
          handleChange={onSelectPeriod}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
    </div>
  )
}
