import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { valDivBillion } from '../../../../../utils/Value'
import {
  BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID,
  MONTH_QUARTER_YEAR_TABS,
} from '../constants'
import { renderTimeType } from '../helper'
import {
  keys,
  selectDataInterestAndPrincipalPaid,
  selectFilterInterestAndPrincipalPaid,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 10,
  fontSize: 11,
}

const InterestAndPrincipalPaid = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const isLoading = useSelector(selectLoading(keys.INTEREST_AND_PRINCIPAL_PAID))
  const { timeType } = useSelector(selectFilterInterestAndPrincipalPaid)
  const { data, list } = useSelector(selectDataInterestAndPrincipalPaid)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const formatDataBarChart = useMemo(
    () =>
      data.map((item, index) => ({
        ...item,
        date: index === 0 ? item.date + renderTimeType(timeType) : item.date,
        couponPaid: valDivBillion(item.couponPaid),
        principalPaid: valDivBillion(item.principalPaid),
      })),
    [data],
  )

  const renderTickerName = () => {
    const item = MONTH_QUARTER_YEAR_TABS.find((item) => item.value === timeType)
    return item ? I18n.t(item.title) : ''
  }

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />

  return (
    <Panel
      title="bond.corporateBond.interestAndPrincipalPaid.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.interestAndPrincipalPaid.TITLE')}
      downloadJpgParams={{
        domId: 'interestAndPrincipalPaidChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: I18n.t('bond.corporateBond.interestAndPrincipalPaid.TITLE'),
        tickerName: renderTickerName(),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div
                id="interestAndPrincipalPaidChartId"
                style={{ height: height - size.height }}
              >
                {isLoading ? (
                  <Loading />
                ) : !!formatDataBarChart.length ? (
                  <>
                    <div style={{ height: height - size.height - 34 }}>
                      <ChartContainer
                        data={formatDataBarChart}
                        width={width}
                        height={height - size.height - 34}
                        keyXAxis="date"
                        xTickNum={SETTINGS.xTickNum}
                        timeFrame="CUSTOM"
                        tickFormatter={(d) => d}
                        yAxis={[
                          {
                            id: 'left',
                            keys: list,
                            orientation: 'left',
                            isStackedBar: true,
                            label: I18n.t(
                              'bond.corporateBond.interestAndPrincipalPaid.VALUE',
                            ),
                            labelPosition: AXIS_LABEL_POSITION.LEFT,
                            tickNum: 5,
                          },
                        ]}
                        margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                        renderCustomTooltip={renderTooltip}
                      >
                        {({ chartContentWidth }) => (
                          <>
                            {list &&
                              !!list.length &&
                              list.map((item, index) => (
                                <Bar
                                  stackId="id"
                                  key={index}
                                  isAnimationActive={false}
                                  yAxisId="left"
                                  dataKey={item}
                                  fill={
                                    BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID[index]
                                  }
                                  barSize={30}
                                />
                              ))}
                          </>
                        )}
                      </ChartContainer>
                    </div>
                    <Footer
                      key={width}
                      list={[
                        {
                          text: 'bond.corporateBond.interestAndPrincipalPaid.PRINCIPAL_PAID',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID[0],
                          },
                        },
                        {
                          text: 'bond.corporateBond.interestAndPrincipalPaid.COUPON_PAID',
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: BAR_COLOR_INTEREST_AND_PRINCIPAL_PAID[1],
                          },
                        },
                      ]}
                    />
                  </>
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default InterestAndPrincipalPaid
