import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../common/DateTimeSpan'
import { Loading } from '../../../common/loading'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { formatVal, valToPercent } from '../../../utils/Value'
import {
  selectHistoricalDealsData,
  selectHistoricalDealsLoading,
} from './store/slice'
import style from './style.module.css'

const tableAttr = [
  {
    key: 'startDateId',
    name: 'corporate.ownership.hoverTooltip.START_DATE',
  },
  {
    key: 'actionTypeName',
    name: 'corporate.ownership.hoverTooltip.TYPE',
  },
  {
    key: 'shareRegister',
    name: 'corporate.ownership.hoverTooltip.REGISTER',
  },
  {
    key: 'shareAcquire',
    name: 'corporate.ownership.hoverTooltip.RESULT',
  },
  {
    key: 'shareBeforeTrade',
    name: 'corporate.ownership.hoverTooltip.SHARE',
    addition: 'corporate.ownership.hoverTooltip.BEFORE',
  },
  {
    key: 'shareAfterTrade',
    name: 'corporate.ownership.hoverTooltip.SHARE',
    addition: 'corporate.ownership.hoverTooltip.AFTER',
  },
  {
    key: 'ownershipAfterTrade',
    name: 'corporate.ownership.hoverTooltip.PERCENTAGE',
  },
]

const HistoricalDeals = () => {
  const historicalDeals = useSelector(selectHistoricalDealsData)
  const isLoading = useSelector(selectHistoricalDealsLoading)

  const renderData = (colId, data, className) => {
    let renderItem = ''
    switch (colId) {
      case 0:
        renderItem = (
          <div className={className} style={{ textAlign: 'left' }}>
            <DateTimeSpan date={data} />
          </div>
        )
        break
      case 1:
        renderItem = (
          <div className={className} style={{ textAlign: 'center' }}>
            {data}
          </div>
        )
        break
      case 2:
        renderItem = <div className={className}>{formatVal(data, 0)}</div>
        break
      case 3:
        renderItem = (
          <div className={`${data < 0 ? style.register : ''} ${className}`}>
            {formatVal(data, 0)}
          </div>
        )
        break
      case 4:
      case 5:
        renderItem = <div className={className}>{formatVal(data, 0)}</div>
        break
      case 6:
        renderItem = <div className={className}>{valToPercent(data)}</div>
        break

      default:
        renderItem = <div className={className}>{data}</div>
        break
    }

    return renderItem
  }

  return (
    <ScrollComponent autoHeight={true} autoHeightMax={250}>
      <div className={style.body}>
        {!isLoading ? (
          tableAttr.map((headerAttr, index) => (
            <div key={index}>
              <div
                className={
                  [2, 3, 4, 5, 6].includes(index)
                    ? [style.headerItem, style.textRight].join(' ')
                    : style.headerItem
                }
                style={
                  index === 0
                    ? {
                        whiteSpace: 'nowrap',
                      }
                    : index === 1
                    ? {
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                      }
                    : { whiteSpace: 'nowrap' }
                }
              >
                <Translate value={headerAttr.name} />
                {'addition' in headerAttr && (
                  <Translate
                    value={headerAttr.addition}
                    className={style.addition}
                  />
                )}
              </div>
              {historicalDeals.length > 0 &&
                historicalDeals.map((item, i) => (
                  <div key={i} className={style.bodyItem}>
                    {renderData(
                      index,
                      item[headerAttr.key],
                      headerAttr.key === 'register' && item['type'] === 'Sell'
                        ? style.register
                        : '',
                    )}
                  </div>
                ))}
            </div>
          ))
        ) : (
          <Loading />
        )}
      </div>
      {historicalDeals.length === 0 && (
        <div className="no-data">
          <Translate value={'common.NO_DATA'} />
        </div>
      )}
    </ScrollComponent>
  )
}

export default HistoricalDeals
