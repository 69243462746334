import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { debounce } from '../../../utils/Common'
import useIsShowChart from '../../chart/common/toolHeader/useIsShowChart'
import { ReactComponent as IconLock } from '../../icon/icon-lock-black.svg'
import { ReactComponent as IconUnLock } from '../../icon/icon-unlock-black.svg'
import style from './style.module.css'

const ToolResizeChart = ({
  width,
  height,
  isShowTable,
  setDimensions,
  isPreview,
  widthDefault,
}) => {
  const [rangeSliderWidth, setRangeSliderWidth] = useState(width)
  const [rangeSliderHeight, setRangeSliderHeight] = useState(height)
  const [syncRange, setSyncRange] = useState(false)
  const isShowChart = useIsShowChart()

  const onChangeRangeSlider = (value, type) => {
    if (isShowTable) return

    if (syncRange) {
      if (type === 'width') {
        setRangeSliderWidth(Math.round(value))
        setRangeSliderHeight(
          Math.round(
            Math.min((value / rangeSliderWidth) * rangeSliderHeight, height),
          ),
        )
      }
      if (type === 'height') {
        setRangeSliderWidth(
          Math.round(
            Math.min((value / rangeSliderHeight) * rangeSliderWidth, width),
          ),
        )
        setRangeSliderHeight(Math.round(value))
      }
    } else {
      if (type === 'width') {
        setRangeSliderWidth(Math.round(value))
      }
      if (type === 'height') {
        setRangeSliderHeight(Math.round(value))
      }
    }
  }

  const handleChangeSize = useCallback(
    debounce((rangeSlider) => {
      setDimensions({
        width: +rangeSlider.width,
        height: +rangeSlider.height,
      })
    }, 500),
    [],
  )

  const handleSyncRange = () => {
    if (isShowTable) return
    setSyncRange(!syncRange)
  }

  useEffect(() => {
    setRangeSliderWidth(Math.round(widthDefault || width))
  }, [width])

  useEffect(() => {
    setRangeSliderHeight(Math.round(height))
  }, [height])

  useEffect(() => {
    handleChangeSize({ width: rangeSliderWidth, height: rangeSliderHeight })
  }, [rangeSliderWidth, rangeSliderHeight])

  if (!width && !height) return null

  return (
    <div
      className={[
        'align-center',
        !isShowChart && !isPreview && style.pointerEventNone,
      ].join(' ')}
    >
      <div className={style.rangeText}>
        <Translate value="W" />
      </div>
      <div
        className={[style.rangeValue, isShowTable ? style.disabled : ''].join(
          ' ',
        )}
      >
        {rangeSliderWidth + 'px'}
      </div>
      <input
        value={Math.floor(rangeSliderWidth)}
        type="range"
        min={Math.floor(width / 2)}
        step="1"
        max={Math.floor(width)}
        className={[style.rangeSlider, isShowTable ? style.disabled : ''].join(
          ' ',
        )}
        onChange={(e) => onChangeRangeSlider(e.target.value, 'width')}
        disabled={isShowTable}
        readOnly={isShowTable}
      />
      <div
        className={isShowTable ? style.disabled : ''}
        onClick={handleSyncRange}
      >
        {syncRange ? <IconLock /> : <IconUnLock />}
      </div>
      <div className={style.rangeText}>
        <Translate value="H" />
      </div>
      <div
        className={[style.rangeValue, isShowTable ? style.disabled : ''].join(
          ' ',
        )}
      >
        {rangeSliderHeight + 'px'}
      </div>
      <input
        value={Math.floor(rangeSliderHeight)}
        type="range"
        min={Math.floor(height / 2)}
        step="1"
        max={Math.floor(height)}
        className={[style.rangeSlider, isShowTable ? style.disabled : ''].join(
          ' ',
        )}
        onChange={(e) => onChangeRangeSlider(e.target.value, 'height')}
        disabled={isShowTable}
        readOnly={isShowTable}
      />
    </div>
  )
}

export default ToolResizeChart

ToolResizeChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  isShowTable: PropTypes.bool,
  setDimensions: PropTypes.func,
  isPreview: PropTypes.bool,
}

ToolResizeChart.defaultProps = {
  setDimensions: () => {},
  width: 0,
  height: 0,
  isShowTable: false,
}
