import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'

const ChartTooltip = ({ dataChart }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {`${dataChart.date}`}
      </Span>

      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.scaleOfDebtByObjects.DEBT" />:
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalDebt)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.scaleOfDebtByObjects.OWNER" />:
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.totalBondIssue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.scaleOfDebtByObjects.RATIO" />:
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.bankLoan)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value="bond.corporateBond.scaleOfDebtByObjects.OTHER" />:
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(dataChart.other)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  dataChart: PropTypes.shape({
    realYear: PropTypes.number.isRequired,
    totalDebt: PropTypes.number.isRequired,
    totalBondIssue: PropTypes.number.isRequired,
    bankLoan: PropTypes.number.isRequired,
    other: PropTypes.number.isRequired,
  }).isRequired,
}

export default ChartTooltip
