import { uniq } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Button } from '../../../common/html/Button'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { PanelHeader } from '../../../common/panelHeader'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../common/tabs/DispatchActionTabWithCalendar'
import TextEllipsis from '../../../common/textEllipsis'
import { debounce } from '../../../utils/Common'
import { getDataBondList } from '../BondList/Table/store/thunk'
import { INDICATOR_GROUPS, TAB_TYPES } from '../constants'
import {
  changeParameters,
  changeTemplate,
  changeTemplateList,
  changeTemplateMoreList,
  resetFilter,
  selectActiveTab,
  selectCountData,
  selectDataRaw,
  selectFilter,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  selectTemplate,
  selectTemplateById,
  setFilter,
} from '../store/slice'
import { getBondListCount } from '../store/thunk'
import Filter from './Filter'

const Screening = ({ redirectToBondIssuer }) => {
  const dispatch = useDispatch()

  const { loading } = useSelector(selectFilter)
  const templates = useSelector(selectTemplate)
  const params = useSelector(selectFilterParams)
  const template = useSelector(selectTemplateById)
  const count = useSelector(selectCountData)
  const currentTab = useSelector(selectActiveTab)
  const rawData = useSelector(selectDataRaw)
  const listParent = useSelector(selectParentSectors)
  const {
    sectors,
    ticker,
    watchListId,
    indicatorGroup,
    conditions,
    addedInformations,
    sortBy,
    order,
  } = params
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)

  const handleSearch = () => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    if (raw) {
      dispatch(
        changeTemplate({
          ...rawData,
          parameter: params,
        }),
      )
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          params.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: params.indicatorGroup,
      }

      const newTemplateIds = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplateIds))
      dispatch(changeTemplateMoreList(newTemplateIds))

      dispatch(
        changeTemplate({
          ...data,
          parameter: params,
        }),
      )
    }

    const newParams = {
      ...params,
      sectors:
        uniq([...listParent, ...sectors])?.length ===
        uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...sectors]),
      conditions,
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

    dispatch(setFilter({ key: 'visible', value: false }))
  }

  const handleClose = () => {
    dispatch(setFilter({ key: 'visible', value: false }))
    if (currentTab === TAB_TYPES.ALL) {
      dispatch(resetFilter())
      return
    }

    if (currentTab === TAB_TYPES.RAW) {
      dispatch(changeParameters(rawData))
      return
    }

    if (template) {
      dispatch(changeParameters(template))
    }
  }

  const handleGetBondListCount = useCallback(
    debounce((payload) => {
      dispatch(getBondListCount(payload))
    }, 500),
    [],
  )

  useEffect(() => {
    const newParams = {
      ...params,
      sectors: !!addedInformations.length
        ? uniq([...listParent, ...sectors])?.length ===
          uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...sectors])
        : [],
      conditions,
      pageSize: 300,
      pageIndex: 1,
      timeRange:
        params.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : params.timeRange,
    }

    handleGetBondListCount(newParams)
  }, [
    listParent,
    sectors,
    ticker,
    watchListId,
    indicatorGroup,
    conditions?.conditionValues,
    conditions,
    addedInformations,
    sortBy,
    order,
    initialSectors,
    initialParentSectors,
  ])

  return (
    <div className="window" style={{ width: 290, flexShrink: 0 }}>
      <PanelHeader>
        <div className="d-flex w-100 j-b ali-center">
          <div className="window-header-title" style={{ gap: 4 }}>
            <img src="/iconFilter.svg" alt="Filter Icon" />
            <Span>
              <TextEllipsis text="bond.bondScreening.filter.TITLE" />
            </Span>
          </div>
          <i
            className="icon-delete-stroke cursor-pointer"
            style={{ fontSize: 9, color: '#E5E5E5' }}
            onClick={handleClose}
          />
        </div>
      </PanelHeader>
      <div className="window-body h-100" style={{ padding: '8px 0' }}>
        <SizeTracker>
          {(size) => (
            <>
              <Span
                style={{
                  fontSize: 10,
                  fontStyle: 'italic',
                  fontWeight: 400,
                  color: '#85939C',
                  padding: '0 16px 8px 16px',
                  height: 21,
                  alignItems: 'flex-end',
                }}
                className="d-flex"
              >
                <Translate
                  value="bond.bondScreening.filter.NUMBER_OF_RESULT"
                  count={count}
                />
              </Span>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <ScrollComponent>
                    <div
                      className="flex-column"
                      style={{
                        padding: '0 16px',
                        gap: 8,
                      }}
                    >
                      {loading ? <Loading /> : <Filter />}
                    </div>
                  </ScrollComponent>
                </div>
              )}
              <div
                style={{
                  padding: 14,
                  gap: 8,
                  background: '#252A36',
                  marginTop: 8,
                }}
                className="flex-column"
              >
                <div className="w-100">
                  <Button
                    className="btn btn-blue w-100"
                    style={{ height: 20, fontWeight: 340 }}
                    onClick={handleSearch}
                  >
                    <Translate value="common.button.BUTTON_SEARCH" />
                  </Button>
                </div>
                <div className="w-100">
                  <Button
                    className="btn normal w-100"
                    style={{ height: 20, fontWeight: 340 }}
                    onClick={() => {
                      dispatch(resetFilter())
                    }}
                  >
                    <Translate value="bond.bondScreening.common.RESET" />
                  </Button>
                </div>
              </div>
            </>
          )}
        </SizeTracker>
      </div>
    </div>
  )
}

export default Screening
