import { useEffect, useMemo, useRef, useState } from 'react'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import EventEmitter from '../../../utils/EventEmitter'
import ChartColorSettings from './ChartColorSettings'
import ChartTypeSettings from './ChartTypeSettings'
import DataFormatSettings from './DataFormatSettings'
import StatisticalMethodSettings from './StatisticalMethodSettings'
import { CLICK_OUTSIDE_SET_COLOR } from './components/chartColorComponent'
import styles from './style.module.css'

const PADDING = 16

const MenuChartSettings = ({
  item,
  parentRef,
  isShow,
  isEquation,
  isBySecurities,
  onClose,
}) => {
  const statisticalMethodSettingsRef = useRef()

  const [data, setData] = useState(item)

  const getElTopMenuTop = () => {
    const el = document.querySelector('.main .top-menu')
    return el.offsetTop || 0
  }

  const getCustomPosition = (position) => {
    return {
      ...position,
      top: getElTopMenuTop(),
    }
  }

  const getMaxHeight = useMemo(() => {
    return document.body.offsetHeight - getElTopMenuTop() - PADDING * 4
  }, [document.body.offsetHeight])

  const handleClose = () => {
    if (!statisticalMethodSettingsRef.current) {
      EventEmitter.dispatch(CLICK_OUTSIDE_SET_COLOR)
    }
  }

  useEffect(() => {
    if (JSON.stringify(item) !== JSON.stringify(data)) {
      setData(item)
    }
  }, [item])

  return (
    <Dropdown
      parentRef={parentRef}
      isShow={isShow}
      dropdownSpace={20}
      position={positionDropdown.RIGHT_TOP}
      customPosition={getCustomPosition}
    >
      <HandleClickOutside
        handleClickOutside={handleClose}
        exceptRef={[parentRef, statisticalMethodSettingsRef]}
      >
        <div className={styles.settingContainer}>
          <ScrollComponent
            autoHeight
            autoHeightMax={getMaxHeight}
            bgColor="#D9D9D9"
          >
            <div style={{ paddingLeft: 18, paddingRight: 18, margin: '4px 0' }}>
              <DataFormatSettings
                isEquation={isEquation}
                isBySecurities={isBySecurities}
                data={data}
                setData={setData}
              />
              {/* <DataFrequencySettings data={data} setData={setData} /> */}
              <ChartTypeSettings data={data} setData={setData} />
              <ChartColorSettings
                data={data}
                setData={setData}
                onClose={onClose}
              />
              <StatisticalMethodSettings
                statisticalMethodSettingsRef={statisticalMethodSettingsRef}
                data={data}
                setData={setData}
              />
            </div>
          </ScrollComponent>
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

export default MenuChartSettings
