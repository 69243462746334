import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { I18n, Translate } from 'react-redux-i18n'
import DataExplorerService from '../../../../../core/services/tool/dataExplorer/DataExplorerService'
import TradingDataService from '../../../../../core/services/tool/dataExplorer/TradingDataService'
import { typeData } from '../../../../common/dataExplorer/constant'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../../utils/EventEmitter'
import { openTemplate } from './slice'

const maxPageSize = 500

export const getIndicatorThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_INDICATOR',
  async (data, { rejectWithValue }) => {
    try {
      const response = await TradingDataService.getIndicator(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplatesThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_TEMPLATES',
  async (_, { rejectWithValue }) => {
    const response = await DataExplorerService.getTemplates({
      DataType: typeData.TRADING_DATA,
      Page: 1,
      PageSize: maxPageSize,
    })
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const saveTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/SAVE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.saveTemplate(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.CREATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplatesThunk())
    }
  },
)

export const updateTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/UPDATE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.updateTemplate(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.dataExplorer.UPDATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      dispatch(getTemplatesThunk())
    }
  },
)

export const renameTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/RENAME_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.renameTemplate(data)
    if (response.success) {
      dispatch(getTemplatesThunk())
    }
  },
)

export const deleteTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/DELETE_TEMPLATE',
  async (data, { dispatch }) => {
    const response = await DataExplorerService.deleteTemplate(data)
    if (response.success) {
      dispatch(getTemplatesThunk())
    }
  },
)

export const getTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await DataExplorerService.getTemplate(data)
    if (response.success) {
      dispatch(openTemplate(response.data))
    }
    return rejectWithValue(response.errors)
  },
)

export const getShareTemplateThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_SHARE_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await DataExplorerService.getShareTemplate(data)
    if (response.success) {
      dispatch(openTemplate(response.data))
    }
    return rejectWithValue(response.errors)
  },
)

export const getWorkSpaceThunk = createAsyncThunk(
  'dataExplorer/tradingData/GET_WORK_SPACE',
  async (data, { rejectWithValue }) => {
    const response = await DataExplorerService.getWorkSpace(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getMostRecentSettingDate = createAsyncThunk(
  'dataExplorer/tradingData/GET_MOST_RECENT_SETTING_DATE',
  async (data, { rejectWithValue }) => {
    const response = await DataExplorerService.getMostRecentDate(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
