import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import Order from '../../../../../../../core/services/bond/bondSecurity/order'
import { handleExport } from '../../../../../../utils/Export'

export const getDataThunk = createAsyncThunk(
  'bond/bondSecurity/order/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Order.getDataSecurity(params)
      if (response.success) {
        const data = response?.data || []
        return { data, ScrollId: response.scrollId }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const fetchMoreDataThunk = createAsyncThunk(
  'bond/bondSecurity/order/FETCH_MORE_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await Order.getDataSecurity(params)
      if (response.success) {
        if (response?.data && response?.data.length > 0) {
          const data = response?.data || []
          return { data, ScrollId: response.scrollId }
        }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const exportFileApi = createAsyncThunk(
  'bond/bondSecurity/order/exportFile',
  async (data) => {
    const response = await Order.exportFile(data)
    handleExport(response.data, response.filename)
  },
)
