export const valueTab = {
  dirtyPrice: 'DirtyPrice',
  cleanPrice: 'CleanPrice',
  ytm: 'YTM',
}

export const listTab = [
  {
    title: 'bond.bondSecurity.interestChart.filter.dirtyPrice',
    value: valueTab.dirtyPrice,
  },
  {
    title: 'bond.bondSecurity.interestChart.filter.cleanPrice',
    value: valueTab.cleanPrice,
  },
  {
    title: 'bond.bondSecurity.interestChart.filter.ytm',
    value: valueTab.ytm,
  },
]
