import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Z_INDEX } from '../../../../../constants/Common'
import Sort from '../../tradingStatistics/Sort'
import { sortStateMap } from '../../tradingStatistics/constants'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text, row) => {
    return (
      <Span
        className={`${style.theadContent} ${row.isHover && style.withIcon}`}
        style={{
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'baseline',
          gap: 4,
          justifyContent: row.key !== 'name' ? 'flex-end' : 'flex-start',
        }}
      >
        <Translate value={text} />

        {!row?.disableSort && !row?.hideIconSort && (
          <Sort type={stateSort[row.keySort]} />
        )}
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={`key-${index}`}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              } ${index === 0 && style.cellLeft} ${style.firstCellHeader} ${
                row.alignRight && style.textRight
              }`}
              onClick={() =>
                sortColumn({
                  [row.keySort]: sortStateMap[stateSort[row.keySort]],
                })
              }
            >
              <div className={stateSort[row?.keySort] ? 'mr-5' : undefined}>
                {renderTitle(row.title, row)}

                {row.additionText && (
                  <div
                    className={style.additionText}
                    style={{ fontSize: 10, fontWeight: 'normal' }}
                  >
                    <Translate value={row.additionText} />
                  </div>
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
