import { tableDataTypes } from '../../constans'

export const priceDataTableCol = {
  [tableDataTypes.REALTIME]: {
    INTRADAY_TRADING_DATE: 'tradingDate',
    INTRADAY_CLOSE_INDEX: 'closeIndex',
    INTRADAY_PERCENT_INDEX_CHANGE: 'percentIndexChange',
    INTRADAY_MATCH_VOLUME: 'matchVolume',
    INTRADAY_TOTAL_VOLUME: 'totalMatchVolume',
  },
  [tableDataTypes.END_OF_DAY]: {
    INTRADAY_TRADING_DATE: 'tradingDate',
    INTRADAY_CLOSE_INDEX: 'closeIndex',
    INTRADAY_PERCENT_INDEX_CHANGE: 'percentIndexChange',
    INTRADAY_MATCH_VOLUME: 'totalMatchVolume',
    INTRADAY_TOTAL_VOLUME: 'totalVolume',
  },
}

export const tableFormatValue = {
  closeIndex: 1,
  totalMatchVolume: 1,
  matchVolume: 1,
  totalVolume: 1,
}
