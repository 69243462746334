import { TableGeneralIndex } from './generalIndex/tableGeneralIndex'
import TableIIC from './iic/tableIIC'
import TableIII from './iii/tableIII'
import TableIIP from './iip/tableIIP'

export const TAB_TYPE = {
  GENERAL_INDEX: 'generalIndex',
  IIP: 'IIP',
  IIC: 'IIC',
  III: 'III',
}

export const TAB_COMPONENT_DATA = {
  [TAB_TYPE.GENERAL_INDEX]: {
    title: 'economy.productionAndConsumption.production.generalIndex',
    Component: TableGeneralIndex,
  },
  [TAB_TYPE.IIP]: {
    title: 'economy.productionAndConsumption.production.IIP',
    Component: TableIIP,
  },
  [TAB_TYPE.IIC]: {
    title: 'economy.productionAndConsumption.production.IIC',
    Component: TableIIC,
  },
  [TAB_TYPE.III]: {
    title: 'economy.productionAndConsumption.production.III',
    Component: TableIII,
  },
}

export const TABLE_TYPE = {
  CUR_PERIOD: 'CurPeriod',
  PRE_PERIOD: 'PrePeriod',
}

export const TABLE_TYPE_RADIO = [
  {
    title: 'economy.productionAndConsumption.production.SAME_PERIOD',
    value: TABLE_TYPE.PRE_PERIOD,
  },
  {
    title: 'economy.productionAndConsumption.production.PREVIOUS_PERIOD',
    value: TABLE_TYPE.CUR_PERIOD,
  },
]

export const TABLE_TYPE_RADIO_PPI = [
  {
    title: 'economy.productionAndConsumption.production.QOQ_GROWTH',
    value: TABLE_TYPE.PRE_PERIOD,
  },
  {
    title: 'economy.productionAndConsumption.production.PREVIOUS_PERIOD',
    value: TABLE_TYPE.CUR_PERIOD,
  },
]

export const ACC_MONTHLY_TITLE = {
  1: {
    vi: 'Th1',
    en: 'Jan',
  },
  2: {
    vi: '2Th',
    en: '2M',
  },
  3: {
    vi: '3Th',
    en: '3M',
  },
  4: {
    vi: '4Th',
    en: '4M',
  },
  5: {
    vi: '5Th',
    en: '5M',
  },
  6: {
    vi: '6Th',
    en: '6M',
  },
  7: {
    vi: '7Th',
    en: '7M',
  },
  8: {
    vi: '8Th',
    en: '8M',
  },
  9: {
    vi: '9Th',
    en: '9M',
  },
  10: {
    vi: '10Th',
    en: '10M',
  },
  11: {
    vi: '11Th',
    en: '11M',
  },
  12: {
    vi: '12Th',
    en: '12M',
  },
}

export const MONTHLY_TITLE = {
  1: {
    vi: 'Th01',
    en: 'Jan',
  },
  2: {
    vi: 'Th02',
    en: 'Feb',
  },
  3: {
    vi: 'Th03',
    en: 'Mar',
  },
  4: {
    vi: 'Th04',
    en: 'Apr',
  },
  5: {
    vi: 'Th05',
    en: 'May',
  },
  6: {
    vi: 'Th06',
    en: 'Jun',
  },
  7: {
    vi: 'Th07',
    en: 'Jul',
  },
  8: {
    vi: 'Th08',
    en: 'Aug',
  },
  9: {
    vi: 'Th09',
    en: 'Sep',
  },
  10: {
    vi: 'Th10',
    en: 'Oct',
  },
  11: {
    vi: 'Th11',
    en: 'Nov',
  },
  12: {
    vi: 'Th12',
    en: 'Dec',
  },
}
