import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { listTabIndex } from '../constans'
import style from '../index.module.css'
import { changeActiveTab, keys, selectActiveTab } from '../store/slice'
import { downloadIndexThunk, downloadSectorThunk } from '../store/thunk'
import TableIndex from './TableIndex'
import TableSector from './TableSector'

const Index = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const tabActive = useSelector(selectActiveTab(keys.INDEX))

  const handleChangeTab = (item) =>
    dispatch(changeActiveTab({ key: keys.INDEX, value: item.value }))

  return (
    <Panel
      title="market.marketInDepth.indices.INDEX_TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={
        tabActive === listTabIndex[0].value
          ? downloadIndexThunk
          : downloadSectorThunk
      }
    >
      <PreOpenMarket>
        <SizeTracker>
          {(size) => (
            <>
              <div className={`w-100 ${style.menuContainer}`}>
                <DispatchActionTab
                  data={listTabIndex}
                  activeTab={tabActive}
                  onChangeTab={handleChangeTab}
                  containerStyle={{ width: '100%' }}
                  itemStyle={{ width: '50%', fontWeight: 600 }}
                />
              </div>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {tabActive === listTabIndex[0].value ? (
                    <TableIndex />
                  ) : (
                    <TableSector />
                  )}
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </PreOpenMarket>
    </Panel>
  )
}

export default Index
