const CustomScatterLine = (props) => {
  const { fill, x, y, widthBar } = props
  return (
    <svg>
      <rect
        x={x - 12}
        y={y}
        width={widthBar}
        height={4}
        stroke="none"
        fill={fill}
      />
    </svg>
  )
}

export default CustomScatterLine
