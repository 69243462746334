export const stateBudget = {
  STATE_BUDGET: 'Ngân sách nhà nước',
  INDICATOR: 'CHỈ TIÊU',
  STATE_BUDGET_REVENUE: 'Thu chi Ngân sách nhà nước',
  REVENUE_STRUCTURE: 'Cơ cấu thu chi Ngân sách nhà nước',
  SOCIAL_INVESTMENT: 'Vốn đầu tư toàn xã hội',
  INVESTMENT_STRUCTURE: 'Cơ cấu vốn đầu tư toàn xã hội',
  UNIT_BILLION_VND: 'Đơn vị: Tỷ VND.',
  YEAR: 'Năm',
  QUARTER: 'Quý',
  ACC_QUARTER: 'Lũy kế quý',
  BALANCE: 'Cán cân NSNN',
  REVENUE: 'Cơ cấu Thu',
  EXPENDITURE: 'Cơ cấu Chi',
  BALANCE_CHART_NAME: 'Cán cân ngân sách nhà nước',
  REVENUE_CHART_NAME: 'Cơ cấu Thu NSNN',
  EXPENDITURE_CHART_NAME: 'Cơ cấu Chi NSNN',
  TOTAL_SOCIAL: 'VĐT Toàn xã hội',
  STATE_SECTOR: 'VĐT Khu vực NN',
  BUDGET_PROVINCES: 'VĐT thuộc NSNN',
  TOTAL_SOCIAL_CHART_NAME: 'Vốn đầu tư toàn xã hội',
  STATE_SECTOR_CHART_NAME: 'Vốn đầu tư khu vực nhà nước',
  BUDGET_PROVINCES_CHART_NAME: 'Vốn đầu tư thuộc ngân sách nhà nước',
  YEARLY: 'Hàng năm',
  VALUE: 'Giá trị',
  PERCENT_VALUE: '% Giá trị',
  BILLION_VND: 'Tỷ VND',
  BILLION_VND_TOOLTIP: 'Tỷ VND',
  BILLION_VND_UNIT: '(Tỷ VND)',
  PERCENT: '%',
  BUDGET_DECIFIT: 'Bội chi NSNN (Tỷ VND)',
}

export const publicDebt = {
  PUBLIC_DEBT: 'Nợ công',
  PUBLIC_DEBT_INDICATOR: 'Các chỉ tiêu nợ công',
  PUBLIC_DEBT_1: 'Các chỉ tiêu nợ công và nợ công nước ngoài của quốc gia',
  PUBLIC_DEBT_2: 'Cơ cấu nợ trong nước và nước ngoài',
  PUBLIC_DEBT_3: 'Deposit structure',
  UNIT_MILLION_USD: 'Đơn vị: triệu USD.',
  NCP: 'Nợ chính phủ',
  NCPBL: 'Nợ chính phủ bảo lãnh',
}
