import moment from 'moment'

const dummyBarChartData = {
  buyPercentage: 0,
  holdPercentage: 0,
  neutralPercentage: 0,
  noRecommendationPercentage: 0,
  sellPercentage: 0,
}

const dummyLineChartData = {
  closePrice: null,
  highestPrice: null,
}

export const getBarChartDataWithDummy = (data, minDay, maxDay) => {
  const minDayMoment = moment(minDay)
  const maxDayMoment = moment(maxDay)

  // Get data of 24 month from now + 2 more months
  // so oldest item will equal to oldest month of lineChart's data
  return [...new Array(26)]
    .map((_, index) => {
      const day = maxDayMoment.clone().subtract(index, 'month')
      const month = day.month() + 1
      const year = day.year()
      const dayData = data.find(
        (item) => item.month === month && item.year === year,
      )

      if (dayData) {
        return dayData
      } else {
        if (
          (minDayMoment.month() >= month && minDayMoment.year() === year) ||
          minDayMoment.year() > year
        ) {
          return undefined
        }
        return {
          ...dummyBarChartData,
          month,
          year,
        }
      }
    })
    .filter((item) => item)
}

export const getLineChartDataWithDummy = (data) => {
  const minDay = moment().subtract(24, 'month').startOf('month').startOf('day')

  // Data from api
  const dataSort = [...data].sort(
    (a, b) =>
      new Date(a.tradingDateId).getTime() - new Date(b.tradingDateId).getTime(),
  )
  const dataOldest = dataSort[0]
  const dataNewest = dataSort[dataSort.length - 1]
  let minDayData = moment(dataOldest?.tradingDateId)
  const maxDayData = moment(dataNewest?.tradingDateId)

  // Add dummy data equal to minDay
  if (minDay.unix() < minDayData.unix()) {
    minDayData = minDay
    data.push({
      ...dataOldest,
      tradingDateId: minDay.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    })
  }

  // Add dummy data equal to start of month of minDayData
  if (minDayData.date() > minDayData.clone().startOf('month').date()) {
    minDayData = minDayData.startOf('month')
    data.push({
      ...dataOldest,
      tradingDateId: minDayData.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    })
  }

  const dataByKey = data.reduce((obj, item) => {
    const itemDay = moment(item.tradingDateId)
    return {
      ...obj,
      [`${itemDay.year()}-${itemDay.month()}-${itemDay.date()}`]: item,
    }
  }, {})

  // Fill dummy data to real data
  // Get data of (24 months X 31 days) days from now + 2 more months
  // so oldest item will start at day 1 of oldest month
  return [...new Array(26 * 31)]
    .map((_, index) => {
      const day = maxDayData.clone().endOf('month').subtract(index, 'day')
      const dayData = dataByKey[`${day.year()}-${day.month()}-${day.date()}`]

      if (dayData) {
        return dayData
      } else {
        if (minDayData.unix() > day.unix()) {
          return undefined
        }

        return {
          tradingDateId: day
            .subtract(1, 'day')
            .hour(17)
            .format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
          ...dummyLineChartData,
        }
      }
    })
    .filter((item) => item)
}
