import { Cell, LabelList, Scatter, ScatterChart, ZAxis } from 'recharts'
import { shadeColor } from '../../../utils/Color'
import { AXIS_LABEL_POSITION, MARGIN_RECHARTS } from '../constants'
import { ChartContainerWithZoom } from '../rechart/ChartContainerWithZoom'
import Label from './Label'
import Tooltip from './Tooltip'

const DEFAULT_COLOR = '#4bd9be'

const Chart = ({
  width,
  height,
  data,
  xAxisId,
  yAxisId,
  zAxisId,
  zAxisRange,
  keyLabel,
  xLabel,
  yLabel,
  xTooltip,
  yTooltip,
  zTooltip,
  xTooltipColor,
  yTooltipColor,
  zTooltipColor,
  xUnit,
  yUnit,
  zUnit,
  isShowLabel,
  isShowLabelOutside,
}) => {
  const renderTooltip = (data, decimalLengths, xAxisDecimalLength) => (
    <Tooltip
      data={data}
      yAxisDecimalLength={decimalLengths[yAxisId]}
      xAxisDecimalLength={xAxisDecimalLength}
      xAxisId={xAxisId}
      yAxisId={yAxisId}
      zAxisId={zAxisId}
      xTooltip={xTooltip}
      yTooltip={yTooltip}
      zTooltip={zTooltip}
      xTooltipColor={xTooltipColor}
      yTooltipColor={yTooltipColor}
      zTooltipColor={zTooltipColor}
      xUnit={xUnit}
      yUnit={yUnit}
      zUnit={zUnit}
      keyLabel={keyLabel}
    />
  )

  return (
    <ChartContainerWithZoom
      chartType={ScatterChart}
      data={data}
      width={width}
      height={height}
      keyXAxis={xAxisId}
      xAxisType="number"
      xTickNum={7}
      xAxisLabel={xLabel}
      isScatterChart={true}
      xAxisUnit={xUnit}
      yAxis={[
        {
          id: yAxisId,
          keys: [yAxisId],
          orientation: 'left',
          tickNum: 7,
          isScatterChart: true,
          label: yLabel,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: yUnit,
        },
      ]}
      zoomLeftYBarPosition="right"
      marginZoomY={{ bottom: 28, left: 0 }}
      margin={{ ...MARGIN_RECHARTS, left: 0, right: 24, bottom: 28 }}
      renderCustomTooltip={renderTooltip}
    >
      <ZAxis zAxisId={zAxisId} dataKey={zAxisId} range={zAxisRange} />
      <Scatter
        data={data}
        yAxisId={yAxisId}
        zAxisId={zAxisId}
        isAnimationActive={false}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color || DEFAULT_COLOR}
            fillOpacity={0.86}
            stroke={shadeColor(entry.color || DEFAULT_COLOR)}
          />
        ))}
        {isShowLabel && (
          <LabelList
            content={<Label isShowLabelOutside={isShowLabelOutside} />}
            dataKey={keyLabel}
          />
        )}
      </Scatter>
    </ChartContainerWithZoom>
  )
}

export default Chart
