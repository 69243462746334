import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getYearQuarter } from '../../common/helper'
import { BANK_TAB } from '../constant'
import { getBankMaxPeriod } from './thunk'

const { year: currentYear, quarter: currentQuarter } = getYearQuarter(
  new Date(),
)

const initialState = {
  tab: BANK_TAB.LOAN,
  year: null,
  quarter: null,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/bank',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeBankTab: (state, action) => {
      state.tab = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    changeQuarter: (state, action) => {
      state.quarter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBankMaxPeriod.pending, (state) => {})
    builder.addCase(getBankMaxPeriod.fulfilled, (state, action) => {
      state.year = action.payload?.year || currentYear
      state.quarter = action.payload?.quarter || currentQuarter
    })
    builder.addCase(getBankMaxPeriod.rejected, (state, action) => {
      state.year = currentYear
      state.quarter = currentQuarter
    })
  },
})

export const selectBankTab = (state) => state[slice.name].tab
export const selectYear = (state) => state[slice.name].year
export const selectQuarter = (state) => state[slice.name].quarter

export const { resetStore, changeBankTab, changeYear, changeQuarter } =
  slice.actions

register(slice.name, slice.reducer)
