import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { formatApiData, getTableSchema } from './helpers'
import {
  changeDataById,
  selectData,
  selectDataById,
  selectDataCellById,
  selectIds,
  selectLoading,
} from './store/slice'
import { getBasicInformationThunk } from './store/thunk'

export const BasicInformation = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const fundInfo = useSelector(selectFundInfo)
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectIds)
  const data = useSelector(selectData)
  const dataById = useSelector(selectDataById)

  useEffect(() => {
    if (fundInfo.organizationId && !loading) {
      dispatch(
        getBasicInformationThunk({ OrganizationId: fundInfo.organizationId }),
      )
    }
  }, [fundInfo.organizationId, locale])

  useEffect(() => {
    if (data && Object.keys(data).length) {
      dispatch(changeDataById(formatApiData({ data, locale })))
    } else {
      dispatch(changeDataById([]))
    }
  }, [JSON.stringify(data)])

  return Object.keys(dataById).length ? (
    <Table
      ids={ids}
      getDataFromRedux={selectDataCellById}
      schema={getTableSchema({ dataById })}
      isLoading={loading}
      isPagination={false}
      hasFooter={false}
      hasTwoColumnBorder
      renderHeader={() => <></>}
    />
  ) : (
    <NoData />
  )
}
