import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../common/ScrollComponent'
import useBasicInfo from '../../common/hooks/useBasicInfo'
import { SEARCH_TYPE } from '../constants'
import { BondOverview } from './BondOverview'
import { CorOverview } from './CorOverview'
import { FuncFunctions } from './FuncFunctions'
import { MarDerivatives } from './MarDerivatives'
import { MarIndices } from './MarIndices'
import { SecOverview } from './SecOverview'
import style from './index.module.css'

const blockOpens = {
  corOverview: false,
  secOverview: false,
  funcFunction: false,
  marIndices: false,
  bond: false,
  marDerivatives: false,
}

export const SearchSecurityFunction = (props) => {
  const { getBasicInfo } = useBasicInfo()

  const { searchStr, searchType } = props
  const [opens, setOpens] = useState(blockOpens)

  const setBlocksOpen = (type, value) => {
    if (
      [
        SEARCH_TYPE.COMPANY,
        SEARCH_TYPE.DERIVATIVE,
        SEARCH_TYPE.FUNCTION,
        SEARCH_TYPE.SECTOR,
        SEARCH_TYPE.INDICE,
        SEARCH_TYPE.BOND,
      ].includes(searchType)
    ) {
      Object.keys(blockOpens).forEach((key) => (blockOpens[key] = false))
    }
    switch (type) {
      case SEARCH_TYPE.COMPANY: {
        blockOpens.corOverview = value
        break
      }
      case SEARCH_TYPE.SECTOR: {
        blockOpens.secOverview = value
        break
      }
      case SEARCH_TYPE.FUNCTION: {
        blockOpens.funcFunction = value
        break
      }
      case SEARCH_TYPE.INDICE: {
        blockOpens.marIndices = value
        break
      }
      case SEARCH_TYPE.BOND: {
        blockOpens.bond = value
        break
      }
      case SEARCH_TYPE.DERIVATIVE: {
        blockOpens.marDerivatives = value
        break
      }
      default: {
        Object.keys(blockOpens).forEach((key) => (blockOpens[key] = true))
        break
      }
    }
    setOpens((old) => ({ ...old, ...blockOpens }))
  }

  useEffect(() => {
    setBlocksOpen(searchType, true)
  }, [searchType])

  const onClickCollapse = (value, type) => {
    setBlocksOpen(type, value)
  }

  return (
    <>
      <div className={`header-1 ${style['header-1']}`}>
        <Translate value="search.fullSearch.SECURITIES_FUNCTIONS" />
      </div>
      <ScrollComponent
        appendStyle={{ height: 'calc(100% - 28px)' }}
        horizontalTrackWidth={0}
      >
        <CorOverview
          searchStr={searchStr}
          open={opens.corOverview}
          onClickCollapse={(value) =>
            onClickCollapse(value, SEARCH_TYPE.COMPANY)
          }
          getBasicInfo={getBasicInfo}
        />
        <SecOverview
          searchStr={searchStr}
          open={opens.secOverview}
          onClickCollapse={(value) =>
            onClickCollapse(value, SEARCH_TYPE.SECTOR)
          }
          getBasicInfo={getBasicInfo}
        />
        <FuncFunctions
          searchStr={searchStr}
          open={opens.funcFunction}
          onClickCollapse={(value) =>
            onClickCollapse(value, SEARCH_TYPE.FUNCTION)
          }
        />
        <MarIndices
          searchStr={searchStr}
          open={opens.marIndices}
          onClickCollapse={(value) =>
            onClickCollapse(value, SEARCH_TYPE.INDICE)
          }
          getBasicInfo={getBasicInfo}
        />
        <BondOverview
          searchStr={searchStr}
          open={opens.bond}
          onClickCollapse={(value) => onClickCollapse(value, SEARCH_TYPE.BOND)}
          getBasicInfo={getBasicInfo}
        />

        <MarDerivatives
          searchStr={searchStr}
          open={opens.marDerivatives}
          onClickCollapse={(value) =>
            onClickCollapse(value, SEARCH_TYPE.DERIVATIVE)
          }
          getBasicInfo={getBasicInfo}
        />
      </ScrollComponent>
    </>
  )
}

SearchSecurityFunction.propTypes = {
  searchStr: PropTypes.string.isRequired,
  searchType: PropTypes.string.isRequired,
}
