import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import styles from '../styleTheme.module.css'
import { checkEquationValid } from './helpers'
import {
  selectEquationFormatUnit,
  selectEquationFormula,
  selectEquationIndicatorName,
  selectEquationIndicators,
  selectEquationSecurities,
  selectEquationType,
} from './store/slice'
import style from './style.module.css'

const GroupButton = ({
  isEquationTypeCount,
  isFormulaValid,
  onCancel,
  onApply,
  setMessageError,
}) => {
  const equationSecurities = useSelector(selectEquationSecurities)
  const equationIndicators = useSelector(selectEquationIndicators)
  const equationType = useSelector(selectEquationType)
  const equationIndicatorName = useSelector(selectEquationIndicatorName)
  const equationFormula = useSelector(selectEquationFormula)
  const equationFormatUnit = useSelector(selectEquationFormatUnit)

  const handleApply = () => {
    if (!equationIndicatorName.trim()) {
      setMessageError('financialChart.EQUATION_NAME_REQUIRED')
      return
    }

    const isValid = checkEquationValid(
      isEquationTypeCount,
      isFormulaValid,
      equationSecurities,
      equationIndicators,
      setMessageError,
    )

    if (isValid) {
      onApply({
        equationType,
        equationIndicatorName: equationIndicatorName.trim(),
        equationSecurities,
        equationIndicators,
        equationUnit: Object.values(equationIndicators)[0]?.unit,
        equationFormula,
        equationFormatUnit,
      })
    }
  }

  return (
    <div className={style.groupButtonModal}>
      <Button
        className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
        onClick={onCancel}
      >
        <Translate value={'common.button.BUTTON_CANCEL'} />
      </Button>
      <Button
        className={`btn w-80 h-20 ${styles.buttonBlueStyle}`}
        onClick={handleApply}
      >
        <Translate value={'common.button.BUTTON_APPLY'} />
      </Button>
    </div>
  )
}

export default GroupButton
