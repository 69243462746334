import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { Footer } from '.'
import { range } from '../../../utils/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  FULL_COMPONENT,
} from '../../../utils/EventEmitter'
import UseFontSize from '../../hooks/useFontSize'
import useWindowDevicePixelRatio from '../../hooks/useWindowDevicePixelRatio'
import { genFooterData } from './helper'

export const FooterContainer = memo(
  ({
    data,
    isOneRow,
    isOneColumn,
    isDownTheLine,
    numRow,
    numItemPerRow,
    listCheckbox,
    appendStyle,
    itemStyle,
    setListCheckbox,
    onMouseOverItem,
    onMouseLeaveItem,
  }) => {
    const { formatWithZoom } = useWindowDevicePixelRatio()

    const [rowWidth, setRowWidth] = useState(null)
    const ref = useRef({
      firstRefs: {},
      secondRefs: {},
    })

    const fontSize = UseFontSize()

    const calcWidth = () => {
      ref.current.timeOut = setTimeout(() => {
        const totalFirstWidth = calcRowWidth(ref.current.firstRefs)
        const totalSecondWidth = calcRowWidth(ref.current.secondRefs)

        if (totalFirstWidth >= totalSecondWidth) {
          ref.current.isRowWidthOfFirstGThanSecond = true
          setRowWidth(totalFirstWidth)
        } else {
          ref.current.isRowWidthOfFirstGThanSecond = false
          setRowWidth(totalSecondWidth)
        }
      }, 0)
    }

    const reCalcWidth = () => {
      ref.current.timeOut = setTimeout(() => {
        if (ref.current.isRowWidthOfFirstGThanSecond) {
          setRowWidth(calcRowWidth(ref.current.firstRefs))
        } else {
          setRowWidth(calcRowWidth(ref.current.secondRefs))
        }
      }, 0)
    }

    const calcRowWidth = (refs) => {
      let totalWidth = 0
      Object.keys(refs).forEach((key) => {
        if (refs[key]) {
          totalWidth += refs[key].getBoundingClientRect().width
        }
      })

      return totalWidth
    }

    useEffect(() => {
      calcWidth()
      EventEmitter.subscribe(COMPONENT_RESIZE, reCalcWidth)
      EventEmitter.subscribe(FULL_COMPONENT, reCalcWidth)
      EventEmitter.subscribe(CLOSE_FULL_COMPONENT, reCalcWidth)

      return () => {
        EventEmitter.unsubscribe(COMPONENT_RESIZE, reCalcWidth)
        EventEmitter.unsubscribe(FULL_COMPONENT, reCalcWidth)
        EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, reCalcWidth)
        clearTimeout(ref.current.timeOut)
        ref.current.timeOut = null
      }
    }, [fontSize])

    const getAppendStyle = () => {
      const MARGIN_ITEM = 25
      const appendStyleWithCalcWidth =
        !isOneColumn && rowWidth
          ? ref.current.isRowWidthOfFirstGThanSecond
            ? {
                width: formatWithZoom(
                  rowWidth +
                    MARGIN_ITEM * Object.keys(ref.current.firstRefs).length,
                ),
                justifyContent: 'flex-start',
              }
            : {
                width: formatWithZoom(
                  rowWidth +
                    MARGIN_ITEM * Object.keys(ref.current.secondRefs).length,
                ),
                justifyContent: 'flex-start',
              }
          : {}
      return { ...appendStyle, ...appendStyleWithCalcWidth }
    }

    const renderFooter = () => {
      const NUMBER_ITEM_ONE_LINE = Math.max(
        Math.ceil(data.length / 2),
        numItemPerRow,
      )
      const NUMBER_LINE =
        numRow || Math.ceil(data.length / NUMBER_ITEM_ONE_LINE)

      return range(0, NUMBER_LINE - 1).map((_, index) => {
        if (index === 0) {
          return (
            <Footer
              key={index}
              listRefs={ref.current.firstRefs}
              list={
                isOneColumn
                  ? data
                  : genFooterData(data, 0, NUMBER_ITEM_ONE_LINE - 1)
              }
              isOneRow={isOneRow}
              isOneColumn={isOneColumn}
              isDownTheLine={isDownTheLine}
              appendStyle={getAppendStyle()}
              itemStyle={itemStyle}
              listCheckbox={listCheckbox}
              setListCheckbox={setListCheckbox}
              onMouseOverItem={onMouseOverItem}
              onMouseLeaveItem={onMouseLeaveItem}
            />
          )
        }

        return (
          <Footer
            key={index}
            listRefs={ref.current.secondRefs}
            list={genFooterData(
              data,
              NUMBER_ITEM_ONE_LINE * index,
              NUMBER_ITEM_ONE_LINE * (index + 1) - 1,
            )}
            isOneRow={isOneRow}
            isOneColumn={isOneColumn}
            isDownTheLine={isDownTheLine}
            appendStyle={getAppendStyle()}
            itemStyle={itemStyle}
            listCheckbox={listCheckbox}
            setListCheckbox={setListCheckbox}
            onMouseOverItem={onMouseOverItem}
            onMouseLeaveItem={onMouseLeaveItem}
          />
        )
      })
    }

    return renderFooter()
  },
)

FooterContainer.propTypes = {
  data: PropTypes.array.isRequired,
  numRow: PropTypes.number,
  numItemPerRow: PropTypes.number,
  listCheckbox: PropTypes.array,
  setListCheckbox: PropTypes.func,
  itemStyle: PropTypes.object,
  isDownTheLine: PropTypes.bool,
}

FooterContainer.defaultProps = {
  numItemPerRow: 3,
  isDownTheLine: false,
}
