export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'ticker',
    title: 'market.derivative.TICKER',
  },
  {
    key: 'totalBuyVolume',
    title: 'market.derivative.TOTAL_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalSellVolume',
    title: 'market.derivative.TOTAL_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalBuyValue',
    title: 'market.derivative.TOTAL_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalSellValue',
    title: 'market.derivative.TOTAL_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'netTotalVolume',
    title: 'market.derivative.NET_TOTAL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'netTotalValue',
    title: 'market.derivative.NET_TOTAL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalMatchBuyVolume',
    title: 'market.derivative.MATCH_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchSellVolume',
    title: 'market.derivative.MATCH_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchBuyValue',
    title: 'market.derivative.MATCH_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalMatchSellValue',
    title: 'market.derivative.MATCH_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalDealBuyVolume',
    title: 'market.derivative.DEAL_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealSellVolume',
    title: 'market.derivative.DEAL_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealBuyValue',
    title: 'market.derivative.DEAL_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalDealSellValue',
    title: 'market.derivative.DEAL_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
]

export const GROUP_COLUMNS = [
  {
    key: 'tradingDateId',
    title: 'market.derivative.DATE',
  },
  {
    key: 'totalBuyVolume',
    title: 'market.derivative.TOTAL_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalSellVolume',
    title: 'market.derivative.TOTAL_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalBuyValue',
    title: 'market.derivative.TOTAL_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalSellValue',
    title: 'market.derivative.TOTAL_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'netTotalVolume',
    title: 'market.derivative.NET_TOTAL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'netTotalValue',
    title: 'market.derivative.NET_TOTAL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalMatchBuyVolume',
    title: 'market.derivative.MATCH_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchSellVolume',
    title: 'market.derivative.MATCH_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalMatchBuyValue',
    title: 'market.derivative.MATCH_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalMatchSellValue',
    title: 'market.derivative.MATCH_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalDealBuyVolume',
    title: 'market.derivative.DEAL_BUY_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealSellVolume',
    title: 'market.derivative.DEAL_SELL_VOL',
    format: 10 ** 0,
    count: 0,
  },
  {
    key: 'totalDealBuyValue',
    title: 'market.derivative.DEAL_BUY_VAL',
    format: 10 ** 6,
    count: 2,
  },
  {
    key: 'totalDealSellValue',
    title: 'market.derivative.DEAL_SELL_VAL',
    format: 10 ** 6,
    count: 2,
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.derivative.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.derivative.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
