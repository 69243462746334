import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { TABLE_KEYS } from '../constants'
import { getBasicInformationThunk } from './thunk'

const initialState = {
  loading: false,
  data: {},
  ids: [],
  dataById: {},
}

export const slice = createSlice({
  name: 'fund/fundProfile/profile/basicInformation',
  initialState,
  reducers: {
    changeDataById: (state, action) => {
      const data = action.payload
      state.dataById = keyBy(data, 'id')
    },
  },
  extraReducers: (builder) => {
    // Get table data
    builder.addCase(getBasicInformationThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getBasicInformationThunk.fulfilled, (state, action) => {
      const data = action.payload.data
      state.data = data
      state.ids = Object.values(TABLE_KEYS)
      state.loading = false
    })
    builder.addCase(getBasicInformationThunk.rejected, (state, action) => {
      state.data = {}
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectData = (state) => state[slice.name].data
export const selectDataById = (state) => state[slice.name].dataById
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)

export const { changeDataById } = slice.actions

register(slice.name, slice.reducer)
