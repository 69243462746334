import { createAsyncThunk } from '@reduxjs/toolkit'
import NewsReportsService from '../../../../core/services/market/NewsReportService'

export const getListReport = createAsyncThunk(
  'market/newsReport/GET_LIST_REPORT',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getMarketReport(data)
    if (response.success) {
      return response
    }
    return rejectWithValue('Error')
  },
)

export const getListNews = createAsyncThunk(
  'market/newsReport/GET_LIST_NEWS',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getListNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getMarketNews = createAsyncThunk(
  'market/newsReport/GET_MARKET_NEWS',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getMarketNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getTrendingNews = createAsyncThunk(
  'market/newsReport/GET_TRENDING_NEWS',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getTrendingNews(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsSameCategory = createAsyncThunk(
  'market/newsReport/GET_NEWS_SAME_CATEGORY',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsSameCategory(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsById = createAsyncThunk(
  'market/newsReport/GET_NEWS_BY_ID',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsById(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsSources = createAsyncThunk(
  'market/newsReport/GET_NEWS_SOURCES',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsSources(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getNewsCategories = createAsyncThunk(
  'market/newsReport/GET_NEWS_CATEGORIES',
  async (data, { rejectWithValue }) => {
    const response = await NewsReportsService.getNewsCategories(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getReportTypesAndSources = createAsyncThunk(
  'market/newsReport/GET_REPORT_TYPES_AND_SOURCES',
  async (data) => {
    const response = await Promise.allSettled([
      NewsReportsService.getReportTypes(data),
      NewsReportsService.getReportSources(data),
    ])
    const [types, sources] = response

    return {
      types: types.status === 'fulfilled' ? types.value.data : [],
      sources: sources.status === 'fulfilled' ? sources.value.data : [],
    }
  },
)
