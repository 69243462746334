import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { debounce } from '../../../utils/Common'
import { Loading } from '../../loading'
import { CONTAINER_CHART_TYPES } from './constants'
import { getDataPieChart, getSchemas, getXAxis } from './helpers'

export const withHandleApi =
  (Component) =>
  ({
    id,
    key,
    width,
    height,
    schema,
    colors,
    chartProps,
    footerProps,
    tooltipProps,
    params,
    serviceApi,
    formatDataApi,
    isDisableCallApi,
    onGetDataApi,
  }) => {
    const locale = useSelector((state) => state.i18n.locale)

    const [dataRaw, setDataRaw] = useState([])
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)

    const schemas = getSchemas(schema)
    const xAxisProps = getXAxis({ xAxis: chartProps.xAxis, schema: schemas })

    const handleData = (value) => {
      setData(value)
      onGetDataApi && onGetDataApi(value)
    }

    const handleApi = useCallback(
      debounce(async (payload, serviceApi) => {
        setLoading(true)
        try {
          if (key) {
            // Todo
          } else if (serviceApi) {
            const response = await serviceApi(payload)
            if (response.success) {
              setDataRaw(response.data)
            }
            setLoading(false)
          }
        } catch (err) {
          setLoading(false)
          setDataRaw([])
        }
      }, 300),
      [],
    )

    useEffect(() => {
      if (!isDisableCallApi) {
        handleApi(params, serviceApi)
      }
    }, [key, JSON.stringify(params), locale, serviceApi])

    useEffect(() => {
      const dataFormat =
        dataRaw.length && formatDataApi ? formatDataApi(dataRaw) : dataRaw
      if (chartProps.chartType === CONTAINER_CHART_TYPES.PIE) {
        handleData(
          getDataPieChart({
            schema: schemas,
            xAxis: xAxisProps,
            data: dataFormat,
            colors,
          }),
        )
      } else {
        handleData(dataFormat)
      }
    }, [JSON.stringify(dataRaw), locale])

    useEffect(() => {
      const dataFormat = formatDataApi ? formatDataApi(dataRaw) : dataRaw
      if (chartProps.chartType === CONTAINER_CHART_TYPES.PIE) {
        handleData(
          getDataPieChart({
            schema: schemas,
            xAxis: xAxisProps,
            data: dataFormat,
          }),
        )
      } else {
        handleData(dataFormat)
      }
    }, [JSON.stringify(dataRaw), locale])

    if (loading) {
      return (
        <div style={{ height }}>
          <Loading />
        </div>
      )
    }

    return (
      <Component
        id={id}
        width={width}
        height={height}
        data={data}
        schema={schemas}
        colors={colors}
        chartProps={chartProps}
        footerProps={footerProps}
        tooltipProps={tooltipProps}
      />
    )
  }

withHandleApi.propTypes = {
  id: PropTypes.string.isRequired,
  key: PropTypes.string.isRequired,
  params: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  schema: PropTypes.object,
  colors: PropTypes.array,
  chartProps: PropTypes.object,
  footerProps: PropTypes.object,
  tooltipProps: PropTypes.object,
  isDisableCallApi: PropTypes.bool,
  onGetDataApi: PropTypes.func,
}
