import { useDispatch } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import useGetICB from '../../../common/masterData/useGetICB'
import useGetIndicesV2 from '../../../common/masterData/useGetIndicesV2'
import { NoData } from '../../../common/noData'
import {
  changeChartTabSecurities,
  changeTreeSecuritiesIndicator,
} from '../../chart/store/slice'
import {
  LIST_TYPE,
  LIST_TYPE_SHORT_TEXT,
  MAX_SECURITIES_ADD,
  MAX_SECURITIES_ITEM,
} from '../../common/constants'
import { changeFullSecurities } from '../common/store/slice'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import useGetIndicator from '../popupAddSecurities/useGetIndicator'

import { I18n } from 'react-redux-i18n'
import useGetStock from '../../../common/masterData/useGetStock'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import ItemSecurities from './ItemSecurities'
import ItemSecuritiesCompany from './ItemSecuritiesCompany'
import withWrapperData from './withWrapperData'

const ListAll = ({
  valueSearch,
  securities,
  onClose,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  itemId,
  usedColors,
  watchLists,
  viewType,
}) => {
  const dispatch = useDispatch()
  const stocks = useGetStock()
  const indices = useGetIndicesV2()
  const ICBs = useGetICB(true, true)
  const loading = false
  const { deepestEconomies } = useGetIndicator()

  const { favoriteColors } = useGetUserSettings()

  const truncateArray = (array) => {
    return array
      .filter((item) =>
        valueSearch
          ? item.ticker?.toLowerCase().includes(valueSearch.toLowerCase()) ||
            item.organizationShortName
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.groupName?.toLowerCase().includes(valueSearch.toLowerCase()) ||
            `${item.icbName} L${item.icbLevel}`
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.secNameDisplay
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.watchListName
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase())
          : true,
      )
      .slice(0, MAX_SECURITIES_ITEM)
  }

  const addSecurities = (id, type) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [type]: [...securities[type], id],
      },
      usedColors,
      viewType,
      favoriteColors,
    })

    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(changeChartTabSecurities({ [type]: [...securities[type], id] }))
    dispatch(
      changeFullSecurities({
        ...securities,
        [type]: [...securities[type], id],
      }),
    )
    onClose()
  }

  return (
    <div style={{ height: `calc(100% )` }}>
      {loading ? (
        <Loading isBlack />
      ) : (
        <ScrollComponent bgColor="#D9D9D9">
          {truncateArray(stocks.stocks).length ? (
            <>
              {truncateArray(stocks.stocks).map((ticker) => {
                return (
                  <ItemSecuritiesCompany
                    key={ticker.organizationId}
                    companies={securities.companies}
                    ticker={ticker}
                    onClick={() =>
                      addSecurities(ticker.organizationId, LIST_TYPE.COMPANIES)
                    }
                    itemId={itemId}
                  />
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(indices.indices).length ? (
            <>
              {truncateArray(indices.indices).map((item) => {
                return (
                  <ItemSecurities
                    key={item.groupId}
                    securities={securities.indices}
                    id={item.groupId}
                    text={item.groupName}
                    onClick={() =>
                      addSecurities(item.groupId, LIST_TYPE.INDICES)
                    }
                    itemId={itemId}
                    securitiesType={LIST_TYPE_SHORT_TEXT.INDICES}
                  />
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(ICBs.ICBs).length ? (
            <>
              {truncateArray(ICBs.ICBs).map((sector) => {
                return (
                  <ItemSecurities
                    key={sector.icbId}
                    securities={securities.sector}
                    id={sector.icbId}
                    text={sector.icbName + ' L' + sector.icbLevel}
                    onClick={() =>
                      addSecurities(sector.icbId, LIST_TYPE.SECTOR)
                    }
                    itemId={itemId}
                    securitiesType={LIST_TYPE_SHORT_TEXT.SECTOR}
                  />
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(deepestEconomies).length ? (
            <>
              {truncateArray(deepestEconomies).map((economy) => (
                <ItemSecurities
                  key={economy.id}
                  securities={securities.economy.map((item) =>
                    [item.id, item.timeFrequency].join('_'),
                  )}
                  id={economy.id}
                  text={economy.secNameDisplay}
                  onClick={() =>
                    addSecurities(
                      {
                        id: economy.parentId,
                        timeFrequency: economy.timeFrequency,
                      },
                      LIST_TYPE.ECONOMY,
                    )
                  }
                  itemId={itemId}
                  securitiesType={LIST_TYPE_SHORT_TEXT.ECONOMY}
                />
              ))}
              <br />
            </>
          ) : null}
          {truncateArray(watchLists).map((watchList) => (
            <ItemSecurities
              key={watchList.watchListId}
              securities={securities.watchList}
              id={watchList.watchListId}
              text={watchList.watchListName}
              onClick={() =>
                addSecurities(watchList.watchListId, LIST_TYPE.WATCH_LIST)
              }
              securitiesType={LIST_TYPE_SHORT_TEXT.WATCH_LIST}
            />
          ))}
          {!truncateArray(stocks.stocks).length &&
          !truncateArray(indices.indices).length &&
          !truncateArray(ICBs.ICBs).length &&
          !truncateArray(deepestEconomies).length &&
          !truncateArray(watchLists).length ? (
            <NoData />
          ) : null}
        </ScrollComponent>
      )}
    </div>
  )
}

export default withWrapperData(ListAll)
