import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Panel } from '../../common/panel'
import { selectBasicInfoOverview } from './store/slice'
import { downloadSummaryInfo } from './summary/store/thunk'
import Summary from './summary/Summary'

export const PanelSummary = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfoOverview)

  return (
    <Panel
      title={'corporate.overview.summary.TITLE_PANEL'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        downloadSummaryInfo({
          OrganizationId: basicInfo.organizationId,
        })
      }
    >
      <Summary />
    </Panel>
  )
}

PanelSummary.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
