import { createAsyncThunk } from '@reduxjs/toolkit'
import ProductionService from '../../../../../../../core/services/economy/productionAndConsumption/ProductionService'
import { handleExport } from '../../../../../../utils/Export'

export const getDataTableIIPCompareByTimeRange = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iip/getDataTableCompareByTimeRange',
  async (params, { rejectWithValue }) => {
    const responseTitle = await ProductionService.getVNTypeIds(params)
    const response = await ProductionService.getDataIIPCompareByTimeRange(
      params,
    )
    if (response.success && responseTitle.success) {
      return {
        dataIIP: response.data,
        titleIIP: responseTitle.data,
      }
    }
    return rejectWithValue({ isLoading: false })
  },
)

export const downloadCompareByTimeRangeIIP = createAsyncThunk(
  'economy/productionAndConsumption/production/table/iip/downloadCompareByTimeRangeIIP',
  async (params) => {
    const response = await ProductionService.downloadCompareByTimeRange(params)
    handleExport(response.data, response.filename)
  },
)
