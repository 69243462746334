import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Radio from '../../../../common/html/Radio'
import useGetMaxMinTable from '../../../../common/masterData/useGetMaxMinTable'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { DispatchActionTabWithCalendar } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import {
  ASSET_ALLOCATION_OVERTIME_TIME_RANGES,
  TABS,
  VALUE_TYPES,
} from './constants'
import { changeFilter, selectFilters } from './store/slice'

export const Filter = () => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const maxMinTable = maxMinTableById['FGFB_COFS_DR_FundStockHolding']

  const filters = useSelector(selectFilters)

  // Actions
  const handleChangeFilter = (value) => {
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  const handleChangeValueType = (value) => {
    dispatch(changeFilter({ valueType: value }))
  }

  const handleChangeTimeRange = (item) => {
    handleChangeFilter({ timeRange: item.value })
  }

  const handleChangeCalendar = (payload) => {
    handleChangeFilter({
      timeRange: payload.activeTab,
      startDate: payload.startDate,
      endDate: payload.endDate,
    })
  }

  useEffect(() => {
    if (!filters.month && !filters.year && maxMinTable) {
      handleChangeFilter({
        month: maxMinTable.max_MonthReport,
        year: maxMinTable.max_YearOfMonthReport,
      })
    }
  }, [maxMinTable])

  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <div className="d-flex ali-center">
        <DispatchActionTab
          onChangeTab={({ value }) => handleChangeFilter({ type: value })}
          data={Object.keys(TABS).map((key) => ({
            title: 'fund.fundProfile.portfolio.' + key,
            value: TABS[key],
          }))}
          activeTab={filters.type}
          textSize={11}
        />
        <Radio
          data={Object.keys(VALUE_TYPES).map((key) => ({
            title: 'fund.fundProfile.portfolio.' + key,
            value: VALUE_TYPES[key],
          }))}
          value={filters.valueType}
          onChange={handleChangeValueType}
          containerClass="w-auto d-flex ml-8"
        />
      </div>
      <div className="ml-8">
        <DispatchActionTabWithCalendar
          id="fundPortfolioOverTime"
          tabs={ASSET_ALLOCATION_OVERTIME_TIME_RANGES}
          activeTab={filters.timeRange}
          startDate={filters.startDate}
          endDate={filters.endDate}
          datePreset={ASSET_ALLOCATION_OVERTIME_TIME_RANGES}
          endYear={maxMinTable?.max_YearOfMonthReport}
          endMonth={maxMinTable?.max_MonthReport}
          onChangeTab={handleChangeTimeRange}
          onChangeCalendar={handleChangeCalendar}
        />
      </div>
    </div>
  )
}
