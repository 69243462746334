import { useEffect, useState } from 'react'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import style from './index.module.css'

const MARGIN_LEFT = 20

const FirstCellColumn = ({
  val,
  props,
  level,
  childrenIds,
  rowId,
  onRowCollapse,
  isRowCollapse,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const getStyle = (level) => {
    if (level === 1) {
      return { color: '#fecf2f', textTransform: 'uppercase' }
    }
  }

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <td {...props} className={style.firstCellColumn}>
      <div
        className={`d-flex align-center`}
        style={{ marginLeft: (level - 1) * MARGIN_LEFT }}
        onClick={onCollapse}
      >
        {childrenIds ? (
          <button
            style={{
              cursor: 'pointer',
              paddingLeft: 0,
              paddingRight: 8,
              marginLeft: -16,
            }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <></>
        )}
        <TextEllipsisStaticLine
          appendStyle={getStyle(level)}
          val={val}
          isI18n={false}
        />
      </div>
      <div className={style.borderLeftScroll} />
    </td>
  )
}

export default FirstCellColumn
