import { useMemo } from 'react'
import { I18n } from 'react-redux-i18n'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Filter from './Filter'
import LiquidityChart from './LiquidityChart'

const LiquidityChartComponent = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  return (
    <Panel
      title="bond.corporateBond.secondaryMarket.LIQUIDITY"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.secondaryMarket.LIQUIDITY')}
      downloadJpgParams={{
        domId: 'liquidityChartData',
        nameScreen: I18n.t(
          'bond.corporateBond.secondaryMarket.TRADE_STATISTICS',
        ),
        chartName: I18n.t('bond.corporateBond.secondaryMarket.LIQUIDITY'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <LiquidityChart
              sizeHeight={size.height}
              width={width}
              height={height}
            />
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default LiquidityChartComponent
