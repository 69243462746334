import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Area } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  roundTime,
  yExtentsCharts,
} from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import ContentTooltip from '../../../../../common/chart/rechart/ContentTooltip'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { PANEL_PADDING } from '../../../../../constants/Common'
import { keyBy } from '../../../../../utils/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import { decodeSizePanel } from '../../helper'
import style from '../../index.module.css'
import {
  keys,
  selectChartTimeRange,
  selectForeignTradingOneDay,
  selectIndexLiquiditySeries,
  selectLoading,
} from '../../store/slice'

let currentChartMargin = {}

const ChartComponent = ({ sizes, chartMargin, setChartMargin }) => {
  const timezone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const indexLiquiditySeries = useSelector(selectIndexLiquiditySeries)
  const foreignTradingOneDay = useSelector(selectForeignTradingOneDay)
  const timeRange = useSelector(selectChartTimeRange)
  const loadingIndexLiquitidy = useSelector(
    selectLoading(keys.INDEX_LIQUIDITY_SERIES),
  )
  const loadingIndexSeries = useSelector(selectLoading(keys.INDEX_SERIES))
  const loading = loadingIndexLiquitidy || loadingIndexSeries

  const chartContainerRef = useRef()

  const calcFontSize = getFontSize(12)

  const [size, setSize] = useState({
    width: decodeSizePanel(sizes).width - PANEL_PADDING,
    height: 100,
  })
  const [indexLiquidityByTime, setIndexLiquidityByTime] = useState({})
  const [dataChart, setDataChart] = useState([])

  useEffect(() => {
    setSize({
      width: chartContainerRef.current?.offsetWidth,
      height: chartContainerRef.current?.offsetHeight,
    })
  }, [sizes])

  useEffect(() => {
    if (!loading)
      setIndexLiquidityByTime(
        keyBy(indexLiquiditySeries, 'tradingDate', (time) =>
          roundTime(time, 60 * 1000),
        ),
      )
  }, [indexLiquiditySeries])

  useEffect(() => {
    if (!loading)
      setDataChart(
        foreignTradingOneDay.map((item) => {
          const historyTotalMatchValue = valDivBillion(
            indexLiquidityByTime[item.tradingDate]?.totalValue,
          )
          const totalMatchValue = valDivBillion(item.totalValue)
          return {
            ...item,
            historyTotalMatchValue:
              indexLiquidityByTime[item.tradingDate]?.totalValue !== undefined
                ? historyTotalMatchValue
                : undefined,
            totalMatchValue:
              item.totalValue !== undefined ? totalMatchValue : undefined,
          }
        }),
      )
  }, [indexLiquidityByTime, foreignTradingOneDay])

  const [leftMin, leftMax] =
    dataChart.length > 0
      ? yExtentsCharts(dataChart, ['totalMatchValue', 'historyTotalMatchValue'])
      : [0, 0]

  const leftTickValues = getRoundedTickValues([leftMin, leftMax], 5)

  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  useEffect(() => {
    const calcChartMargin = getChartMargin(
      leftTickValues,
      [],
      leftDecimalLength,
      0,
      calcFontSize,
    )
    const newChartMargin = {
      ...calcChartMargin,
      left: Math.max(calcChartMargin.left, chartMargin.left),
      right: Math.max(calcChartMargin.right, chartMargin.right),
    }
    if (JSON.stringify(newChartMargin) !== JSON.stringify(currentChartMargin)) {
      setChartMargin(newChartMargin)
      currentChartMargin = newChartMargin
    }
  }, [dataChart, size, calcFontSize, chartMargin])

  return (
    <div className={`h-100 ${style.relative}`}>
      <div className="h-100">
        <div className="h-100 position-relative" ref={chartContainerRef}>
          <ChartContainer
            data={dataChart}
            margin={{ ...MARGIN_RECHARTS, right: chartMargin.right }}
            width={decodeSizePanel(sizes).width - PANEL_PADDING}
            height={size.height}
            keyXAxis="tradingDate"
            yAxis={[
              {
                id: 'left',
                keys: ['totalMatchValue', 'historyTotalMatchValue'],
                orientation: 'left',
                yAxisWidth: chartMargin.left,
                label: I18n.t('market.marketInDepth.indices.VALUE_BILLION'),
                labelPosition: AXIS_LABEL_POSITION.LEFT,
              },
            ]}
            timeFrame={TIME_RANGES['1D']}
            renderCustomTooltip={(data) => {
              const contentTooltip = [
                {
                  label:
                    formatDateTime(
                      data.tradingDate,
                      FORMAT.DATE,
                      locale,
                      timezone,
                    ) +
                    ' ' +
                    formatDateTime(
                      data.tradingDate,
                      FORMAT.TIME_HOUR_MINUTE,
                      locale,
                      timezone,
                    ),
                  value: '',
                  styleLabel: { fontStyle: 'italic', fontSize: 10 },
                  styleValue: { fontStyle: 'italic', fontSize: 10 },
                },
                {
                  label: `${I18n.t(
                    'market.marketInDepth.indices.TODAY_TOTAL_TRADING',
                  )}:`,
                  value:
                    formatVal(data.totalMatchValue) +
                    I18n.t('market.marketInDepth.indices.BILLION'),
                  styleLabel: { fontSize: 11, fontWeight: 400 },
                  styleValue: { fontSize: 11, fontWeight: 500 },
                },
                {
                  label: `${
                    timeRange === TIME_RANGES['1D']
                      ? I18n.t(
                          'market.marketInDepth.indices.YESTERDAY_TOTAL_TRADING',
                        )
                      : I18n.t(
                          'market.marketInDepth.indices.AVG_TOTAL_TRADING_' +
                            timeRange,
                        )
                  }:`,
                  value:
                    formatVal(data.historyTotalMatchValue) +
                    I18n.t('market.marketInDepth.indices.BILLION'),
                  styleLabel: { fontSize: 11, fontWeight: 400 },
                  styleValue: { fontSize: 11, fontWeight: 500 },
                },
              ]
              return <ContentTooltip contentTooltip={contentTooltip} />
            }}
          >
            <Area
              dataKey="historyTotalMatchValue"
              fill="#ffc859"
              yAxisId="left"
              stroke="none"
              fillOpacity={1}
              isAnimationActive={false}
              activeDot={false}
            />
            <Area
              dataKey="totalMatchValue"
              fill="rgb(0, 156, 255)"
              yAxisId="left"
              stroke="none"
              fillOpacity={0.8}
              isAnimationActive={false}
              activeDot={false}
            />
          </ChartContainer>
        </div>
      </div>
    </div>
  )
}

ChartComponent.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

const LiquidityChart = ({ sizes, chartMargin, setChartMargin }) => {
  const timeRange = useSelector(selectChartTimeRange)
  const foreignTradingOneDay = useSelector(selectForeignTradingOneDay)

  const listIconChart = [
    {
      text: 'market.marketInDepth.indices.TODAY_TOTAL_TRADING',
      type: TYPE.SQUARE,
      before: {
        bgColor: '#009cff',
      },
    },
    {
      text:
        timeRange === TIME_RANGES['1D']
          ? 'market.marketInDepth.indices.YESTERDAY_TOTAL_TRADING'
          : 'market.marketInDepth.indices.AVG_TOTAL_TRADING_' + timeRange,
      type: TYPE.SQUARE,
      before: {
        bgColor: '#ffc859',
      },
    },
  ]

  return (
    <div className="h-100 position-relative">
      {!foreignTradingOneDay.length ? (
        <NoData />
      ) : (
        <div className={style.liquidityChartContainer}>
          <SizeTracker>
            {(size) => (
              <>
                {size.height && (
                  <div style={{ height: `calc(100% - ${size.height}px)` }}>
                    <ChartComponent
                      sizes={sizes}
                      chartMargin={chartMargin}
                      setChartMargin={setChartMargin}
                    />
                  </div>
                )}
                <Footer key={sizes.width} list={listIconChart} />
              </>
            )}
          </SizeTracker>
        </div>
      )}
    </div>
  )
}

LiquidityChart.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

export default memo(LiquidityChart)
