import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialAnalysisService from '../../../../../../../../core/services/sector/sectorConstituents/FinancialAnalysisService'
import { handleExport } from '../../../../../../../utils/Export'
import { changeQuarterType, changeRetryFlag, changeYearType } from './slice'

export const getDataTable = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/assetStructure/dataTable/GET_DATA_TABLE',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await FinancialAnalysisService.getAssetsStructureTable(
        data,
      )

      if (response.requestParams) {
        dispatch(changeYearType(response.requestParams.Year))
        dispatch(changeQuarterType(response.requestParams.Quarter))
      }
      dispatch(changeRetryFlag(false))

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadAssetsStructureDownloadTable = createAsyncThunk(
  'sector/sectorConstituents/financialAnalysis/security/assetStructure/DOWNLOAD_TABLE',
  async (data) => {
    const response =
      await FinancialAnalysisService.downloadAssetsStructureDownloadTable(data)
    handleExport(response.data, response.filename)
  },
)
