import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class ValuationProxy extends ProxyBase {
  getValuation(params) {
    return this.get('GetValuation', params)
  }

  getCompareValuation(params) {
    return this.get('CompareValuation', params, '', true)
  }
}

export default new ValuationProxy(
  ServiceProxyConfig.Market.MarketInDepth_Valuation.ServiceUrl,
)
