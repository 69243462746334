import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getInvestmentStructure } from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
}

const slice = createSlice({
  name: 'economy/fdi/fdiByCountries/barChartData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvestmentStructure.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getInvestmentStructure.fulfilled, (state, action) => {
      state.dataChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getInvestmentStructure.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart

register(slice.name, slice.reducer)
