import { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { PopupHeaderCommon } from '../../../../../common/popup/PopupHeaderCommon'
import BodyPopup from './bodyPopup'
import style from './index.module.css'

const Bottom = () => {
  const [show, setshow] = useState(false)

  return (
    <>
      <div style={{ textAlign: 'end' }}>
        <Span
          style={{ fontsizeL: 12, fontWeight: 340 }}
          className={style.link}
          onClick={() => setshow(true)}
        >
          {I18n.t('bond.bondSecurity.generalSetting.detail')}
        </Span>
      </div>
      {show && (
        <PopupHeaderCommon
          setIsShow={setshow}
          title="bond.bondSecurity.generalSetting.popup.title"
        >
          <BodyPopup setIsShow={(value) => setshow(value)} />
        </PopupHeaderCommon>
      )}
    </>
  )
}

export default Bottom
