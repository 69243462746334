import useGetStock from '../../../../common/masterData/useGetStock'
import TextEllipsis from '../../../../common/textEllipsis'
import style from '../style.module.css'

const ListCompaniesSelected = ({
  indexStart,
  companiesChecked,
  securitiesState,
  setSecuritiesState,
}) => {
  const { stocks } = useGetStock()

  const getValueItem = (organizationId) => {
    return stocks.find((i) => i.organizationId === organizationId)
  }

  const removeCompanyListInList = (organizationId) => {
    const value = companiesChecked.filter((i) => i !== organizationId)
    setSecuritiesState({ ...securitiesState, companies: value })
  }

  return (
    <>
      {companiesChecked.map((organizationId, index) => {
        return (
          <li key={organizationId}>
            <div className={style.indexCol}>{indexStart + index + 1}</div>
            <div className={style.divSecurities}>
              <div className={style.tickerInfo}>
                <div className={style.tickerCode1}>
                  <TextEllipsis
                    isI18n={false}
                    text={
                      getValueItem(organizationId)?.ticker ||
                      getValueItem(organizationId)?.taxCode
                    }
                  />
                </div>
                <div className={style.orgName}>
                  <TextEllipsis
                    isI18n={false}
                    text={
                      getValueItem(organizationId)?.organizationShortName ||
                      getValueItem(organizationId)?.organizationName
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className={style.checkboxCol}
              onClick={() => removeCompanyListInList(organizationId)}
            >
              <i
                className="icon-delete-circle cursor-pointer"
                style={{ fontSize: 12 }}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default ListCompaniesSelected
