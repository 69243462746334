import { useState } from 'react'
import { PriceGrowth } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'

export const PanelPriceGrowth = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const nameScreen = UseI18n('sector.sectorSpecific.steel.STEEL')
  const tabName = UseI18n('sector.sectorSpecific.steel.PRICE_TAB')
  const chartName = UseI18n('sector.sectorSpecific.price.STEEL_PRICE_GROWTH')

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title="sector.sectorSpecific.price.STEEL_PRICE_GROWTH"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={chartName}
      downloadJpgParams={{
        domId: 'priceGrowth',
        nameScreen: nameScreen,
        chartName: chartName,
        tabName: tabName,
      }}
      handleCustom={setIsFullScreen}
    >
      <PriceGrowth
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
