import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import VerticalLine from '../../../../common/topInfo/components/VerticalLine'
import CalendarFilter from '../../common/CalendarFilter'
import SearchDropdownDerivative from '../../common/SearchDropdownDerivative'
import style from '../../index.module.css'
import { LIST_RADIO, TABLE_TYPE } from './constants'
import {
  changeCurrentDerivativeId,
  changeDateFrom,
  changeDateTo,
  changeTableType,
  changeTimeFrequency,
  resetGroupColumns,
  setChangeFilter,
} from './store/slice'

const RESPONSIVE_WIDTH_RATIO = 0.5

export const FilterList = ({ filterData, width }) => {
  const dispatch = useDispatch()

  const [responsiveWidthRatioSmall, setResponsiveWidthRatioSmall] =
    useState(false)

  const selectionListWidth =
    document.getElementById('searchDropdown')?.getBoundingClientRect().width +
      document.getElementById('radioSelection')?.getBoundingClientRect()
        .width ?? width

  const handleChangeTableType = (item) => {
    dispatch(changeTableType(item.value))
    dispatch(setChangeFilter())
    dispatch(resetGroupColumns())
  }

  useEffect(() => {
    if (selectionListWidth / width < RESPONSIVE_WIDTH_RATIO) {
      setResponsiveWidthRatioSmall(true)
    } else {
      setResponsiveWidthRatioSmall(false)
    }
  }, [selectionListWidth / width])

  return filterData?.tableType !== TABLE_TYPE.TICKER ? (
    responsiveWidthRatioSmall ? (
      <div className="row" style={{ marginBottom: 8 }}>
        <div className="row" id="searchDropdown">
          <Span className={style.ticker}>
            <Translate value="market.derivative.TICKER" />
          </Span>
          <SearchDropdownDerivative
            setTableType={changeTableType}
            setChangeFilter={setChangeFilter}
            changeDerivativeActive={changeCurrentDerivativeId}
          />
        </div>
        <VerticalLine styleProps={{ height: 20 }} ml={15} mr={15} />
        <div className="d-flex">
          <ul className={`list-check d-flex mt-0`} id="radioSelection">
            {LIST_RADIO.map((item) => (
              <li key={item.value} className="mb-0">
                <label>
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    checked={item.value === filterData?.tableType}
                    onChange={() => {
                      handleChangeTableType(item)
                    }}
                  />
                  <Span
                    style={{ fontSize: 12 }}
                    className={
                      item.value === filterData?.tableType ? style.active : ''
                    }
                  >
                    <Translate value={item.label} />
                  </Span>
                </label>
              </li>
            ))}
          </ul>
          <VerticalLine styleProps={{ height: 20 }} ml={15} mr={15} />
          <CalendarFilter
            changeDateFrom={changeDateFrom}
            changeDateTo={changeDateTo}
            filterData={filterData}
            isShowTimeFrequency
            changeTimeFrequency={changeTimeFrequency}
          />
        </div>
      </div>
    ) : (
      <>
        <div className="row">
          <div className="row" id="searchDropdown">
            <Span className={style.ticker}>
              <Translate value="market.derivative.TICKER" />
            </Span>
            <SearchDropdownDerivative
              setTableType={changeTableType}
              setChangeFilter={setChangeFilter}
              changeDerivativeActive={changeCurrentDerivativeId}
            />
          </div>
          <VerticalLine styleProps={{ height: 20 }} ml={15} mr={15} />
          <ul
            className={`list-check d-flex mt-0`}
            style={{ width: 220 }}
            id="radioSelection"
          >
            {LIST_RADIO.map((item) => (
              <li key={item.value} className="mb-0">
                <label>
                  <input
                    type="radio"
                    className="radiobox radiobox2"
                    checked={item.value === filterData?.tableType}
                    onChange={() => {
                      handleChangeTableType(item)
                    }}
                  />
                  <Span
                    style={{ fontSize: 12 }}
                    className={
                      item.value === filterData?.tableType ? style.active : ''
                    }
                  >
                    <Translate value={item.label} />
                  </Span>
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className={style.calendarFilterSmall}>
          <CalendarFilter
            changeDateFrom={changeDateFrom}
            changeDateTo={changeDateTo}
            filterData={filterData}
            isShowTimeFrequency
            changeTimeFrequency={changeTimeFrequency}
          />
        </div>
      </>
    )
  ) : (
    <div className="row" style={{ marginBottom: 8 }}>
      <div className="row">
        <Span className={style.ticker}>
          <Translate value="market.derivative.TICKER" />
        </Span>
        <SearchDropdownDerivative
          setTableType={changeTableType}
          setChangeFilter={setChangeFilter}
          changeDerivativeActive={changeCurrentDerivativeId}
        />
      </div>
      <VerticalLine styleProps={{ height: 20 }} ml={15} mr={15} />
      <div className="d-flex">
        <CalendarFilter
          changeDateFrom={changeDateFrom}
          changeDateTo={changeDateTo}
          filterData={filterData}
          isShowTimeFrequency
          changeTimeFrequency={changeTimeFrequency}
        />
      </div>
    </div>
  )
}
