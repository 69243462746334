import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'

export const getSteelCoatPeersComparison = createAsyncThunk(
  'sector/sectorSpecific/steel/steelCoat/peersComparison',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SteelService.getSteelCoatPeersComparison(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
