import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { TIME_TYPE } from '../../balanceOfTrade/constants'
import { selectIsAcc, selectIsYearly } from '../../balanceOfTrade/store/slice'
import { MONTH_LIST_FILTER_VI } from '../constants'
import { BlockBarItem } from './BlockBarItem'
import { XAxisForBlockBar } from './XAxisForBlockBar'
import style from './index.module.css'
import { selectDataChart, selectLoading } from './store/slice'
import { getTopTradeGood } from './store/thunk'

const DEFAULT_FILTER_HEIGHT_PERCENT = 0.4
const REMAINING_HEIGHT = 55

export const CommodityChartContent = ({
  height,
  width,
  currentMonth,
  currentYear,
  locale,
}) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const isYearly = useSelector(selectIsYearly)
  const isAcc = useSelector(selectIsAcc)

  const [chartHeight, setChartHeight] = useState(0)

  const maxValPriceLeft = Math.ceil(dataChart.map((item) => item.price_left)[0])
  const maxValPriceRight = Math.ceil(
    dataChart.map((item) => item.price_right)[0],
  )

  const getWidthPercent = (price, isLeft) => {
    const comparisonPrice = isLeft ? maxValPriceLeft : maxValPriceRight

    if (price === 0) {
      return 0
    } else {
      return (price / comparisonPrice) * 50 + '%'
    }
  }

  const headerAndFooterHeight =
    document.getElementById('header')?.getBoundingClientRect().height +
      REMAINING_HEIGHT || height * DEFAULT_FILTER_HEIGHT_PERCENT

  useEffect(() => {
    if (currentMonth && currentYear) {
      dispatch(
        getTopTradeGood({
          DataType: isYearly
            ? TIME_TYPE.YEARLY
            : isAcc
            ? TIME_TYPE.ACC_MONTHLY
            : TIME_TYPE.MONTHLY,
          Year: currentYear,
          Month: isYearly
            ? MONTH_LIST_FILTER_VI[MONTH_LIST_FILTER_VI.length - 1].value
            : currentMonth,
        }),
      )
    }
  }, [currentMonth, currentYear, locale, isYearly, isAcc])

  useEffect(() => {
    if (height && headerAndFooterHeight) {
      setChartHeight(height - headerAndFooterHeight)
    }
  }, [height - headerAndFooterHeight])

  return (
    height && (
      <>
        <div className={style.titleHeader} id="header">
          <Translate
            className="canvas-chart-title"
            value="economy.importExport.importExport.overview.COMMODITIES_TOP_10"
          />
        </div>
        <div className="d-flex j-b">
          <Span
            className={[style.headerImport, 'canvas-chart-title'].join(' ')}
          >
            {`(${I18n.t(
              'economy.importExport.importExport.overview.FOOTER_IMPORT',
            )})`}
          </Span>
          <Span
            className={[style.headerExport, 'canvas-chart-title'].join(' ')}
          >
            {`(${I18n.t(
              'economy.importExport.importExport.overview.FOOTER_EXPORT',
            )})`}
          </Span>
        </div>
        {isLoading ? (
          <Loading />
        ) : !dataChart.length ? (
          <div style={{ height: chartHeight }}>
            <NoData />
          </div>
        ) : (
          <>
            <div style={{ height: chartHeight }}>
              {dataChart.map((item, index) => (
                <div key={index} className={`${style.rowBlockBarItem} d-flex`}>
                  <div className={`w-50 ${style.firstBlockBarItem}`}>
                    {item.price_left ? (
                      <BlockBarItem
                        item={item}
                        isTextLeftVal
                        color={'#bf5757'}
                        widthColor={getWidthPercent(item.price_left, true)}
                        positionColor="right"
                        width={width / 2}
                        height={chartHeight}
                        numRow={dataChart.length}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="w-50">
                    {item.price_right ? (
                      <BlockBarItem
                        item={item}
                        isTextRightVal
                        color={'#3da967'}
                        widthColor={getWidthPercent(item.price_right)}
                        positionColor="left"
                        width={width / 2}
                        height={chartHeight}
                        numRow={dataChart.length}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              ))}
              <div>
                <XAxisForBlockBar
                  maxValLeft={maxValPriceLeft}
                  maxValRight={maxValPriceRight}
                />
              </div>
            </div>
          </>
        )}
      </>
    )
  )
}
