export const titlePanel = {
  CPI_INFLATION: 'CPI & Lạm phát',
  CHANGES_INDICATORS: 'Tăng trưởng các chỉ tiêu CPI & Lạm phát',
  CHANGES_OVER_YEARS: 'Tăng trưởng cùng kỳ qua các năm',
  CONTRIBUTION_OF_GOODS: 'Đóng góp của các nhóm hàng vào chỉ số CPI',
  OF: 'của',
}

export const cpiAndInflationTable = {
  COMPARE: 'So với:',
  MOM: 'Kỳ trước',
  YOY: 'Cùng kỳ',
  RTD: 'Kỳ gốc',
  GROWTH_YTD: 'Lũy kế (YTD)',
  ALL: 'Tất cả',
}
