import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'

export const PriceRate = () => {
  return (
    <Span style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}>
      <Translate value="market.coveredWarrant.PRICE_VOLUME_UNIT" />
    </Span>
  )
}
