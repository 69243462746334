import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import style from '../index.module.css'

export const ChartFooter = ({
  totalMatchVolume,
  totalBuyVolume,
  totalSellVolume,
}) => {
  return (
    <div className={`${style.blockFooter}`}>
      <div className="align-center j-b">
        <Span className="canvas-chart-title">
          <Translate value="market.equityTrading.deepTransaction.priceDepth.TOTAL_BUY_VOL" />
        </Span>
        <Span className="canvas-chart-title">
          <Translate value="market.equityTrading.deepTransaction.priceDepth.TOTAL_MATCHED_VOL" />
        </Span>
        <Span className="canvas-chart-title">
          <Translate value="market.equityTrading.deepTransaction.priceDepth.TOTAL_SELL_VOL" />
        </Span>
      </div>
      <div className={`align-center j-b ${style.mt4px}`}>
        <Span className="canvas-chart-title" style={{ fontWeight: 'bold' }}>
          {totalBuyVolume}
        </Span>
        <Span className="canvas-chart-title" style={{ fontWeight: 'bold' }}>
          {totalMatchVolume}
        </Span>
        <Span className="canvas-chart-title" style={{ fontWeight: 'bold' }}>
          {totalSellVolume}
        </Span>
      </div>
    </div>
  )
}

ChartFooter.propTypes = {
  totalMatchVolume: PropTypes.string.isRequired,
  totalBuyVolume: PropTypes.string.isRequired,
  totalSellVolume: PropTypes.string.isRequired,
}
