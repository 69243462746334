import moment from 'moment'
import { TIME_RANGES } from '../../common/tabs/DispatchActionTab'
import { keyBy } from '../../utils/Common'
import { FORMAT, formatDateTime } from '../../utils/Datetime'
import { formatVal, isInValidValue } from '../../utils/Value'
import { MARGIN, SESSION } from './constants'

export const getRoundedTickValues = (
  [min, max],
  ticks = 6,
  isBarChart = false,
) => {
  ticks = ticks - 1

  if (min < 0 && max > 0) {
    return getYAxisHasZero([min, max], ticks)
  }

  // As for bar chart, always there is zero value at y Axis
  if (max < 0 && isBarChart) max = 0
  if (min > 0 && isBarChart) min = 0

  const roundOffsetMinMax = findRoundOffset([min, max])
  const roundedMax = roundMaxValue(max, roundOffsetMinMax)
  const roundedMin = roundMinValue(min, roundOffsetMinMax)
  const offset = (roundedMax - roundedMin) / ticks
  const roundedOffset = roundOffset(offset, roundOffsetMinMax)

  const results = []
  for (let i = 0; i <= ticks; i++) {
    min >= 0
      ? results.push(roundedMin + i * roundedOffset)
      : results.unshift(roundedMax - i * roundedOffset)
  }

  return results
}

const getYAxisHasZero = ([min, max], ticks) => {
  const roundOffsetMinMax = findRoundOffset([min, max])
  if (ticks <= 3) {
    const offset = roundOffset(Math.max(Math.abs(min), max), roundOffsetMinMax)
    return [-offset, 0, offset]
  } else {
    let numberIntervalNegative = (ticks / (Math.abs(min) + max)) * Math.abs(min)
    if (numberIntervalNegative < 1) {
      numberIntervalNegative = 1
    } else if (numberIntervalNegative > ticks - 1) {
      numberIntervalNegative = ticks - 1
    }
    const numberIntervalPositive = ticks - numberIntervalNegative
    //case 1: floor numberIntervalNegative, ceil numberIntervalPositive
    const offsetCase1 = roundOffset(
      Math.max(
        Math.floor(numberIntervalNegative)
          ? Math.abs(min) / Math.floor(numberIntervalNegative)
          : 1,
        Math.ceil(numberIntervalPositive)
          ? max / Math.ceil(numberIntervalPositive)
          : 1,
      ),
      roundOffsetMinMax,
    )
    //case 2: ceil numberIntervalNegative, floor numberIntervalPositive
    const offsetCase2 = roundOffset(
      Math.max(
        Math.ceil(numberIntervalNegative)
          ? Math.abs(min) / Math.ceil(numberIntervalNegative)
          : 1,
        Math.floor(numberIntervalPositive)
          ? max / Math.floor(numberIntervalPositive)
          : 1,
      ),
      roundOffsetMinMax,
    )

    let offset
    let roundNumberIntervalNegative
    let roundNumberIntervalPositive
    if (
      Math.max(
        offsetCase1 * Math.floor(numberIntervalNegative) - Math.abs(min),
        offsetCase1 * Math.ceil(numberIntervalPositive) - max,
      ) <=
      Math.max(
        offsetCase2 * Math.ceil(numberIntervalNegative) - Math.abs(min),
        offsetCase2 * Math.floor(numberIntervalPositive) - max,
      )
    ) {
      offset = roundOffset(offsetCase1, roundOffsetMinMax)
      roundNumberIntervalNegative = Math.floor(numberIntervalNegative)
      roundNumberIntervalPositive = Math.ceil(numberIntervalPositive)
    } else {
      offset = roundOffset(offsetCase2, roundOffsetMinMax)
      roundNumberIntervalNegative = Math.ceil(numberIntervalNegative)
      roundNumberIntervalPositive = Math.floor(numberIntervalPositive)
    }

    const negativeResults = [0]
    let negativeValue = 0
    while (negativeValue < roundNumberIntervalNegative) {
      negativeValue += 1
      negativeResults.push(negativeResults[negativeResults.length - 1] - offset)
    }

    let positiveValue = 0
    const positiveResults = [0]
    while (positiveValue < roundNumberIntervalPositive) {
      positiveValue += 1
      positiveResults.push(positiveResults[positiveResults.length - 1] + offset)
    }

    return [...new Set([...negativeResults.reverse(), ...positiveResults])]
  }
}

const roundMaxValue = (value, roundOffset) => {
  return fixMultiply(Math.ceil(fixDivide(value, roundOffset)), roundOffset)
}

const roundMinValue = (value, roundOffset) => {
  return fixMultiply(Math.floor(fixDivide(value, roundOffset)), roundOffset)
}

export const findRoundOffset = ([min, max]) => {
  const absOffset = Math.abs(max - min)
  let i = 10
  let count
  if (absOffset < 1) {
    count = 1
    while (absOffset * i < 1) {
      count += 1
      i *= 10
    }
    return 1 / Math.pow(10, count + 1)
  } else {
    count = 0
    while (absOffset / i >= 1) {
      count += 1
      i *= 10
    }
    return Math.pow(10, count - 1)
  }
}

const roundOffset = (value, roundOffset) => {
  return fixMultiply(Math.ceil(fixDivide(value, roundOffset)), roundOffset)
}

// Format min, max when min equal max
export const formatMaxMin = ([min, max], isOriginalZero) => {
  if (min === max && min === 0) {
    return isOriginalZero ? [0, 0] : [0, 100]
  }

  if (min === max) {
    if (max > 0) {
      return [0, max]
    } else {
      return [min, 0]
    }
  }

  return [min, max]
}

// Increase max and decrease min for line chart to make chart more beautiful
const formatMinMaxWithLine = (min, max) => {
  const newMin = min - (max - min) * 0.1
  const newMax = max + (max - min) * 0.1
  return [
    min === 0 ||
    (min > 0 && newMin < 0) ||
    (max > 2 * min && min > 0 && Math.round(min) === 0 && Math.round(max) > 0)
      ? 0
      : newMin,
    max === 0 ||
    (max < 0 && newMax > 0) ||
    (max > 2 * min && max < 0 && Math.round(max) === 0 && Math.round(min) < 0)
      ? 0
      : newMax,
  ]
}

const formatMinMaxScatter = (min, max) => {
  const newMin = min - (max - min) * 0.15
  const newMax = max + (max - min) * 0.15
  return [newMin, newMax]
}

const getCountDecimalNumber = (value) => {
  if (value > -1 && value < 0) {
    return String(value).length - 3
  } else if (value > 0 && value < 1) {
    return String(value).length - 2
  } else {
    return 0
  }
}

const fixDivide = (a, b) => {
  const countDecimalA = getCountDecimalNumber(a)
  const countDecimalB = getCountDecimalNumber(b)

  if (countDecimalA > countDecimalB) {
    return (
      (a * Math.pow(10, countDecimalA)) /
      (b * Math.pow(10, countDecimalB)) /
      Math.pow(10, countDecimalA - countDecimalB)
    )
  } else {
    return (
      ((a * Math.pow(10, countDecimalA)) / (b * Math.pow(10, countDecimalB))) *
      Math.pow(10, countDecimalB - countDecimalA)
    )
  }
}

const fixMultiply = (a, b) => {
  const countDecimalA = getCountDecimalNumber(a)
  const countDecimalB = getCountDecimalNumber(b)

  return (
    (a * Math.pow(10, countDecimalA) * (b * Math.pow(10, countDecimalB))) /
    Math.pow(10, countDecimalA + countDecimalB)
  )
}

export const yExtentsCharts = (
  data,
  arrKey,
  isStackedBar = false,
  isLineChart = false,
  isScatterChart = false,
  isOriginalZero = false,
) => {
  const [min, max] = getMinMax({ data, arrKey, isStackedBar, isScatterChart })

  if (isLineChart) {
    return formatMaxMin(formatMinMaxWithLine(min, max), isOriginalZero)
  }
  if (isScatterChart) {
    return formatMaxMin(formatMinMaxScatter(min, max), isOriginalZero)
  }

  return formatMaxMin([min, max], isOriginalZero)
}

const getMinMax = ({
  data,
  arrKey,
  isStackedBar = false,
  isScatterChart = false,
}) => {
  let max = -Infinity
  let min = Infinity
  const newArr = Array.isArray(arrKey) ? arrKey : [arrKey]

  if (isStackedBar) {
    data.forEach((item) => {
      let newMax = 0
      let newMin = 0
      newArr.forEach((key) => {
        newMax += item[key] && item[key] >= 0 ? item[key] : 0
        newMin += item[key] && item[key] <= 0 ? item[key] : 0
      })
      max = Math.max(newMax, max)
      min = Math.min(newMin, min)
    })
  } else if (isScatterChart) {
    newArr.forEach((key) => {
      const list = []
      data.forEach((a) => {
        if (!isInValidValue(a[key]) && !isNaN(a[key])) {
          list.push(a[key])
        }
      })
      const newMax = list.length ? Math.max(...list) : 0
      const newMin = list.length ? Math.min(...list) : 0
      max = Math.max(newMax, max)
      min = Math.min(newMin, min)
    })
  } else {
    newArr.forEach((key) => {
      const list = []
      data.forEach((a) => {
        if (!isInValidValue(a[key])) {
          list.push(a[key])
        }
      })
      const newMax = list.length ? getMaxData(list) : 0
      const newMin = list.length ? getMinData(list) : 0
      max = Math.max(newMax, max)
      min = Math.min(newMin, min)
    })
  }
  return [min, max]
}

const getMaxData = (array) => {
  return array.reduce((a, b) => Math.max(a || 0, b ? parseFloat(b) : 0))
}

const getMinData = (array) => {
  return array.reduce((a, b) => Math.min(a || 0, b ? parseFloat(b) : 0))
}

export const getColumnSizeInBarChart = (
  width,
  length,
  maxBarWidth,
  numGroupColumn = 1,
) => {
  if (width && length) {
    const MIN_SPACE = 2
    const MAX_BAR_WIDTH = maxBarWidth || 35
    let calcBarWidth = Math.floor((width - (length - 1) * MIN_SPACE) / length)

    if (numGroupColumn > 1) {
      calcBarWidth = (calcBarWidth / numGroupColumn) * 0.6
    }

    if (calcBarWidth > MAX_BAR_WIDTH) {
      return MAX_BAR_WIDTH
    }

    return calcBarWidth
  }

  return undefined
}

export const getXAxisFormatAndTicks = ({
  data,
  keyXAxis,
  timeRange,
  tickNum,
  width,
  textMaxWidth,
  locale,
  margin = MARGIN,
  fontSize = 12,
  timeZone,
  isNotFormatXAxis,
}) => {
  switch (timeRange) {
    case TIME_RANGES['1D']:
      return {
        format: FORMAT.TIME_HOUR_MINUTE,
        tickValues: getOneDayTickValues(data, keyXAxis),
      }
    case TIME_RANGES['1W']:
      return {
        format: FORMAT.DATE,
        tickValues: getOneWeekTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['1W_DATE']:
    case TIME_RANGES['2W']:
      return {
        format: FORMAT.DATE,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.DATE,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['1M']:
      return {
        format: FORMAT.DATE,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.DATE,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['3M']:
    case TIME_RANGES['6M']:
    case TIME_RANGES['9M']:
      return {
        format: FORMAT.WEEK_YEAR_W,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          format: FORMAT.WEEK_YEAR_W,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['1Y']:
      return {
        format: FORMAT.MONTH_YEAR_NUMBER,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.MONTH_YEAR_NUMBER,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['2Y']:
    case TIME_RANGES.YTD:
      return {
        format: FORMAT.DATE,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.DATE,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['3Y']:
      return {
        format: FORMAT.MONTH_YEAR_NUMBER,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.MONTH_YEAR_NUMBER,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES['5Y']:
    case TIME_RANGES.All:
      return {
        format: FORMAT.QUARTER_YEAR,
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          format: FORMAT.QUARTER_YEAR,
          margin,
          fontSize,
          timeZone,
        }),
      }
    case TIME_RANGES.CUSTOM:
      return {
        tickValues: getTickValues({
          data,
          keyXAxis,
          tickNum,
          width,
          textMaxWidth,
          locale,
          margin,
          fontSize,
          timeZone,
          isNotFormatXAxis,
        }),
      }
    default:
      throw Error('Pls set timeRange correctly!')
  }
}

export const getFormat = (timeRange) => {
  switch (timeRange) {
    case TIME_RANGES['1D']:
      return FORMAT.TIME_HOUR_MINUTE
    case TIME_RANGES['1W']:
      return FORMAT.DATE
    case TIME_RANGES['1W_DATE']:
    case TIME_RANGES['2W']:
      return FORMAT.DATE
    case TIME_RANGES['1M']:
      return FORMAT.DATE
    case TIME_RANGES['3M']:
    case TIME_RANGES['6M']:
    case TIME_RANGES['9M']:
      return FORMAT.WEEK_YEAR_W
    case TIME_RANGES['1Y']:
      return FORMAT.MONTH_YEAR_NUMBER
    case TIME_RANGES['2Y']:
    case TIME_RANGES.YTD:
      return FORMAT.DATE
    case TIME_RANGES['3Y']:
      return FORMAT.MONTH_YEAR_NUMBER
    case TIME_RANGES['5Y']:
    case TIME_RANGES.All:
      return FORMAT.QUARTER_YEAR
    default:
      throw Error('Pls set timeRange correctly!')
  }
}

const getOneDayTickValues = (data, keyXAxis) => {
  const now = data[0]?.[keyXAxis] ? new Date(data[0]?.[keyXAxis]) : new Date()
  return [
    now.setHours(9, 0, 0, 0),
    now.setHours(11, 30, 0, 0),
    now.setHours(13, 0, 0, 0),
    now.setHours(15, 0, 0, 0),
  ]
}

const getOneWeekTickValues = ({
  data,
  keyXAxis,
  tickNum,
  width,
  textMaxWidth,
  locale,
  format,
  margin,
  timeZone,
}) => {
  const mapDayIndex = {}
  data.forEach((item) => {
    const date = moment(item[keyXAxis])
    const dayOfWeek = date.format('ddd')
    const day = date.format('DD')

    if (mapDayIndex[`${dayOfWeek}_${day}`] !== undefined) {
      return
    }
    mapDayIndex[`${dayOfWeek}_${day}`] = item[keyXAxis]
  })

  if (width) {
    return getTickValues({
      data: Object.values(mapDayIndex).map((value) => {
        return { [keyXAxis]: value }
      }),
      keyXAxis,
      tickNum,
      width,
      textMaxWidth,
      locale,
      format,
      margin,
      timeZone,
    })
  }

  return Object.values(mapDayIndex)
}

const MAX_TICK_NUM = 5
export const getTickValues = ({
  data,
  keyXAxis,
  tickNum = MAX_TICK_NUM,
  width,
  textMaxWidth,
  locale = 'en',
  format,
  margin,
  gap,
  fontSize = 12,
  timeZone,
  isXAxisDiv = false,
  isNotFormatXAxis,
  isTickNumAsMax = false,
}) => {
  const length = data?.length
  const maxTickNum = isTickNumAsMax ? tickNum : MAX_TICK_NUM

  if (!length) return []

  textMaxWidth = textMaxWidth
    ? textMaxWidth
    : Math.max(
        ...data.map((item) =>
          Math.ceil(
            getTextWidth(
              format
                ? formatDateTime(item[keyXAxis], format, locale, timeZone)
                : item[keyXAxis],
              fontSize,
            ),
          ),
        ),
      )

  // Recalculate num ticks follow width chart
  if (width) {
    if (!gap) {
      gap = fontSize
    }

    const calcTicks = calcTicksFollowWidth({ width, textMaxWidth, margin, gap })
    tickNum =
      calcTicks > 0 &&
      ([FORMAT.YEAR, FORMAT.MONTH_NUMBER].includes(format) || !format
        ? true
        : calcTicks <= maxTickNum)
        ? calcTicks
        : tickNum && tickNum <= maxTickNum
        ? tickNum
        : MAX_TICK_NUM

    if (length <= tickNum) {
      tickNum = length
    } else if (Math.ceil(length / 2) <= tickNum) {
      tickNum = Math.ceil(length / 2)
    }

    if (isNotFormatXAxis) {
      tickNum = MAX_TICK_NUM
    }
  }

  const division = (length - 1) / (tickNum - 1)
  const defaultStep = Math.floor(division)
  let dataItemLeft = Math.round((division - defaultStep) * (tickNum - 1))
  let prevIndex = 0
  const result = []

  ;[...new Array(tickNum)].forEach((_, index) => {
    if (index === 0) {
      result.push(isXAxisDiv ? index : data[0][keyXAxis])
    } else if (index + 1 === tickNum) {
      result.push(isXAxisDiv ? length - 1 : data[length - 1][keyXAxis])
    } else {
      let curIndex = prevIndex + defaultStep
      if (dataItemLeft > 0) {
        curIndex++
        dataItemLeft--
      }
      prevIndex = curIndex
      result.push(isXAxisDiv ? curIndex : data[curIndex][keyXAxis])
    }
  })

  return isXAxisDiv ? { tickIndex: result, textMaxWidth } : result
}

const calcTicksFollowWidth = ({ width, textMaxWidth, margin, gap }) => {
  return Math.floor((width - margin.left - margin.right) / (textMaxWidth + gap))
}

const keyDataNotHandled = ['matchVolume', 'totalMatchVolume']
const keyDataFakeBeforeFirstData = ['openIndex', 'referencePrice']

export const handleDataRealtime = (interval, data, keyTime, sessionParam) => {
  if (data && data.length > 0) {
    const session = sessionParam || SESSION
    const dataByTime = keyBy(
      data.map((item) => {
        return { ...item, [keyTime]: roundTime(item[keyTime], interval) }
      }),
      keyTime,
    )
    const dataHandled = []
    const firstDataTime = roundTime(data[0][keyTime], interval)
    const lastDataTime = roundTime(data[data.length - 1][keyTime], interval)
    let indexDataFake = firstDataTime
    let now = new Date().getTime()
    now = lastDataTime > now ? lastDataTime : now

    const startTimeSession = new Date(firstDataTime).setHours(
      session.start.hour,
      session.start.minute,
      0,
      0,
    )
    const endTimeSession = new Date(lastDataTime).setHours(
      session.end.hour,
      session.end.minute,
      0,
      0,
    )
    const startTime =
      firstDataTime < startTimeSession ? firstDataTime : startTimeSession

    const endTime =
      lastDataTime > endTimeSession ? lastDataTime : endTimeSession

    for (let i = startTime; i <= endTime; i += interval) {
      if (i > now) {
        dataHandled.push({ [keyTime]: i, isFakeData: true })
      } else if (dataByTime[i]) {
        dataHandled.push(dataByTime[i])
        indexDataFake = i
      } else if (i < firstDataTime) {
        const data = { [keyTime]: i }
        keyDataFakeBeforeFirstData.forEach((key) => {
          data[key] = dataByTime[firstDataTime][key]
        })
        dataHandled.push(data)
      } else {
        const data = {
          ...dataByTime[indexDataFake],
          [keyTime]: i,
        }
        keyDataNotHandled.forEach((key) => {
          delete data[key]
        })
        dataHandled.push(data)
      }
    }
    return dataHandled
  } else {
    return []
  }
}

export const pushDataRealtime = (
  interval,
  lastData,
  dataPush,
  keyTime,
  sessionParam,
  isIncrement = false,
  keysIncrement,
) => {
  if (
    lastData[0]?.isFakeData ||
    new Date(lastData[0]?.[keyTime]).getDate() !==
      new Date(dataPush?.[keyTime]).getDate()
  ) {
    return handleDataRealtime(interval, [dataPush], keyTime, sessionParam)
  } else {
    const newData = [...lastData]
    const indexFakeData = newData.findIndex((item) => item.isFakeData)
    const indexLast =
      indexFakeData !== -1 ? indexFakeData - 1 : newData.length - 1
    const itemLast = newData[indexLast]
    const roundTimeDataPush = roundTime(dataPush[keyTime], interval)
    let i = indexLast

    while (newData[i]?.[keyTime] <= roundTimeDataPush) {
      const time = newData[i][keyTime]
      if (time < roundTimeDataPush) {
        const data = { ...itemLast, [keyTime]: time }
        if (newData[i].isFakeData) {
          keyDataNotHandled.forEach((key) => {
            delete data[key]
          })
        }
        newData[i] = data
      } else {
        let data = { ...dataPush }
        if (
          isIncrement &&
          moment(new Date(time)).format('hh:mm') ===
            moment(new Date(roundTimeDataPush)).format('hh:mm')
        ) {
          const newDataItem = { ...newData[i] }
          const incrementDataPush = {}
          Object.keys(newDataItem).forEach((key) => {
            if (
              key !== keyTime &&
              (!keysIncrement || keysIncrement.includes(key))
            ) {
              incrementDataPush[key] =
                Number(newDataItem[key]) + Number(dataPush[key])
            }
          })
          data = { ...data, ...incrementDataPush }
        }
        newData[i] = { ...data, [keyTime]: time }
      }
      i += 1
    }
    return newData
  }
}

export const roundTime = (time, interval) => {
  return new Date(time).getTime() - (new Date(time).getTime() % interval)
}

export const getFirstDayOfWeek = (date) => moment(date).isoWeekday(1).toDate()
export const getLastDayOfWeek = (date) => moment(date).isoWeekday(5).toDate()

export const getTooltipDate = ({
  val,
  minDate,
  timeFrame,
  locale,
  timeZone,
}) => {
  switch (timeFrame) {
    case TIME_RANGES['1W']:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
    case TIME_RANGES['1M']:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
    case TIME_RANGES['3M']:
    case TIME_RANGES['6M']:
    case TIME_RANGES['9M']:
      return (
        formatDateTime(
          minDate || getFirstDayOfWeek(val),
          FORMAT.DATE,
          locale,
          timeZone,
        ) +
        ' - ' +
        formatDateTime(val, FORMAT.DATE, locale, timeZone)
      )
    case TIME_RANGES['1Y']:
    case TIME_RANGES['2Y']:
    case TIME_RANGES.YTD:
    case TIME_RANGES['3Y']:
      return formatDateTime(val, FORMAT.MONTH, locale, timeZone)
    case TIME_RANGES['5Y']:
    case TIME_RANGES.All:
      return formatDateTime(val, FORMAT.QUARTER_YEAR, locale, timeZone)
    default:
      return ''
  }
}

export const getDecimalLengthYAxis = (tickValues) => {
  const diff = tickValues[tickValues.length - 1] - tickValues[0]
  if (diff < 0.09) return 3
  if (diff > 0.09 && diff < 10) return 2
  return 0
}

export const getTextWidth = (
  txt,
  fontSize = 12,
  fontWeight = 'normal',
  gap = 4,
) => {
  const element = document.createElement('canvas')
  const context = element.getContext('2d')
  context.font = `${fontWeight} ${fontSize}px Poppins`
  return Math.ceil(context.measureText(txt).width) + gap
}

export const getTextHeight = (txt, fontSize = 12, fontWeight = 'normal') => {
  var text = document.createElement('span')
  text.style.fontFamily = 'Poppins'
  text.style.fontSize = fontSize + 'px'
  text.style.fontWeight = fontWeight
  text.textContent = txt
  document.body.appendChild(text)
  var result = text.getBoundingClientRect().height
  document.body.removeChild(text)
  return result
}

export const getChartMargin = (
  leftTickValues,
  rightTickValues,
  leftDecimalLength = 2,
  rightDecimalLength = 2,
  fontSize = 12,
  margin,
  padding,
  isEqualMargin = false,
) => {
  let leftMaxText = ''
  let rightMaxText = ''

  if (leftTickValues.length) {
    const leftTickFormat = leftTickValues
      .filter((val) => !isNaN(val))
      .map((val) => (val === 0 ? '0' : formatVal(val, leftDecimalLength)))
    for (const tick of leftTickFormat) {
      if (leftMaxText.length < tick.length) {
        leftMaxText = tick
      }
    }
  }

  if (rightTickValues.length) {
    const rightTickFormat = rightTickValues
      .filter((val) => !isNaN(val))
      .map((val) => (val === 0 ? '0' : formatVal(val, rightDecimalLength)))
    for (const tick of rightTickFormat) {
      if (rightMaxText.length < tick.length) {
        rightMaxText = tick
      }
    }
  }

  const PADDING = padding ?? 25
  const leftTextWidth = Math.ceil(getTextWidth(leftMaxText, fontSize))
  const rightTextWidth = Math.ceil(getTextWidth(rightMaxText, fontSize))

  const leftMargin = isEqualMargin
    ? Math.max(leftTextWidth, rightTextWidth)
    : leftTextWidth
  const rightMargin = isEqualMargin
    ? Math.max(leftTextWidth, rightTextWidth)
    : rightTextWidth
  const OFFSET = 5

  return {
    top: margin && margin.top ? margin.top : MARGIN.top,
    bottom: margin && margin.bottom ? margin.bottom : MARGIN.bottom,
    left: margin && margin.left ? margin.left : leftMargin + OFFSET + PADDING,
    right:
      margin && margin.right ? margin.right : rightMargin + OFFSET + PADDING,
  }
}

export const getRadiusOfScatter = (width, length) => {
  const DEFAULT_RADIUS = 5
  const MIN_SPACE = 13
  const MIN_RADIUS = 0
  return width && length
    ? Math.max(
        Math.min(
          DEFAULT_RADIUS,
          Math.floor((width - (length - 1) * MIN_SPACE) / length),
        ),
        MIN_RADIUS,
      )
    : DEFAULT_RADIUS
}

export const adjustPosition = (
  arrayData,
  heightChart,
  heightLabel,
  tickValueMax,
  tickValueMin,
) => {
  const MAX_HGT = Math.abs(tickValueMax - tickValueMin)

  const HEIGHT_LABEL_RESULT = (heightLabel / heightChart) * MAX_HGT

  const adjTopPos = arrayData.map((a) => Object.assign({}, a))
  const isFullNegative = !adjTopPos.some((item) => item.val >= 0)
  let adjTopPosLen = adjTopPos.length

  for (let i = 0; i < adjTopPosLen; i++) {
    let tempPoint = adjTopPos[i].val
    let restOfLabel = adjTopPosLen - (i + 1)

    tempPoint =
      i > 0 && adjTopPos[i].val - adjTopPos[i - 1].val < HEIGHT_LABEL_RESULT
        ? adjTopPos[i - 1].val + HEIGHT_LABEL_RESULT
        : adjTopPos[i].val

    if (
      !isFullNegative &&
      tempPoint >
        tickValueMax - HEIGHT_LABEL_RESULT * restOfLabel - HEIGHT_LABEL_RESULT
    ) {
      tempPoint = tickValueMax - HEIGHT_LABEL_RESULT * restOfLabel - heightLabel
    }

    adjTopPos[i].val = tempPoint
  }

  return adjTopPos
}
