import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class ForeignProxy extends ProxyBase {
  getForeignNetValue(params) {
    return this.get('Get_Foreign_NetValue', params)
  }

  getForeignTopNetValue(params) {
    return this.get('Get_Foreign_TopNetTrading', params)
  }

  getForeignOwnerShip(params) {
    return this.get('Get_Foreign_Ownership', params)
  }

  getForeignSectorSegmentation(params) {
    return this.get('Get_Foreign_SectorSegmentation', params)
  }

  downloadForeignSectorSegmentation(params) {
    return this.get('Download_Foreign_SectorSecmentation', params, 'download')
  }

  downloadForeignTopNetValue(params) {
    return this.get('Download_Foreign_TopNetTradingValue', params, 'download')
  }
}

export default new ForeignProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
