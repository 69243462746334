import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../../common/chart/constants'
import { Footer } from '../../../../../../common/chart/footer'
import { TYPE } from '../../../../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Span } from '../../../../../../common/html/Span'

import { SizeTracker } from '../../../../../../common/sizeTracker'
import { formatVal } from '../../../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
}

const BAR_COLOR = '#185999'
const TRIANGLE_COLOR = '#facc5c'

const YAXIS_LEFT = 'left'
const YAXIS_RIGHT = 'right'

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  barKey,
  triangleKey,
}) => {
  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.DEPOSIT_FROM_CUSTOMER',
        ),
        value: formatVal(tooltipData[barKey], decimalLengths[YAXIS_LEFT] || 2),
      },
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.LOAN_TO_DEPOSIT_RATIO',
        ),
        value:
          formatVal(
            tooltipData[triangleKey],
            decimalLengths[YAXIS_RIGHT] || 2,
          ) + '%',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <>
                <ChartContainer
                  data={data}
                  width={width}
                  height={height - size.height}
                  keyXAxis={keyXAxis}
                  isUseXAxisDiv
                  margin={{ ...MARGIN_RECHARTS, right: 14 }}
                  showAllTick
                  yAxis={[
                    {
                      id: YAXIS_LEFT,
                      keys: [barKey],
                      orientation: 'left',
                      isBarChart: true,
                      label: I18n.t(
                        'corporate.businessModel.penetrationAnalysis.BILLION',
                      ),
                      labelPosition: AXIS_LABEL_POSITION.LEFT,
                      tickNum: SETTINGS.yTickNum,
                    },
                    {
                      id: YAXIS_RIGHT,
                      keys: [triangleKey],
                      orientation: 'right',
                      isLineChart: true,
                      labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      tickNum: SETTINGS.yTickNum,
                      unitYAxis: '%',
                    },
                  ]}
                  renderCustomTooltip={renderTooltip}
                >
                  {({ chartContentWidth }) => (
                    <>
                      <Bar
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        dataKey={barKey}
                        barSize={getColumnSizeInBarChart(
                          chartContentWidth,
                          data.length,
                        )}
                        fill={BAR_COLOR}
                      />
                      <Scatter
                        yAxisId={YAXIS_RIGHT}
                        dataKey={triangleKey}
                        fill={TRIANGLE_COLOR}
                        isAnimationActive={false}
                        shape={
                          <ScatterDiamondShape
                            dataKey={triangleKey}
                            color={TRIANGLE_COLOR}
                          />
                        }
                      />
                    </>
                  )}
                </ChartContainer>
              </>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'corporate.businessModel.penetrationAnalysis.DEPOSIT_FROM_CUSTOMER',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR,
                  },
                },
                {
                  text: 'corporate.businessModel.penetrationAnalysis.LOAN_TO_DEPOSIT_RATIO',
                  type: TYPE.TRIANGLE,
                  renderIcon: (
                    <ScatterDiamondShape
                      color={TRIANGLE_COLOR}
                      style={{ marginRight: 5 }}
                    />
                  ),
                },
              ]}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
