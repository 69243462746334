import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { VALUE_TYPE } from './constants'
import { changeType, selectType } from './store/slice'

const NetValueTypeTab = () => {
  const dispatch = useDispatch()
  const type = useSelector(selectType)

  const handleChange = ({ value }) => {
    dispatch(changeType(value))
  }

  return (
    <DispatchActionTab
      data={Object.keys(VALUE_TYPE).map((key) => ({
        title: 'market.marketInDepth.localInstitution.' + key,
        value: VALUE_TYPE[key],
      }))}
      activeTab={type}
      onChangeTab={handleChange}
    />
  )
}

export default NetValueTypeTab
