import { I18n } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import { Span } from '../../../../common/html/Span'
import style from './style.module.css'

const CalendarSearch = ({
  disabled,
  value,
  handleChangeDateFrom,
  handleChangeDateTo,
}) => {
  return (
    <div className="d-flex ali-center" style={{ marginLeft: 18 }}>
      <div className="d-flex ali-center mt-8 mr-8">
        <div className={style.labelTable}>
          <Span style={{ fontSize: 11 }}>
            {I18n.t('tool.dataExplorer.FROM')}
          </Span>
        </div>
        <div
          className={`h-20 d-flex ali-center ${style.calendarSelect} ${
            disabled ? style.disabled : ''
          }`}
        >
          <CalendarPopper
            date={value.rangeForm ? new Date(value.rangeForm) : new Date()}
            handleChange={handleChangeDateFrom}
            customHeader={HeaderOneCalendarRange({
              startYear: 2007,
              endYear: new Date().getFullYear(),
              isOptionSelectedDefaultColor: false,
            })}
            CustomInput={InputDateCalendar}
            maxDate={undefined}
            minDate={undefined}
          />
          <i className="icon-date" />
        </div>
      </div>
      <div className="d-flex ali-center mt-8">
        <div className={style.labelTable}>
          <Span style={{ fontSize: 11 }}>{I18n.t('tool.dataExplorer.TO')}</Span>
        </div>
        <div
          className={`h-20 d-flex ali-center ${style.calendarSelect} ${
            disabled ? style.disabled : ''
          }`}
        >
          <CalendarPopper
            date={value.rangeTo ? new Date(value.rangeTo) : new Date()}
            handleChange={handleChangeDateTo}
            customHeader={HeaderOneCalendarRange({
              startYear: 2007,
              endYear: new Date().getFullYear(),
              isOptionSelectedDefaultColor: false,
            })}
            CustomInput={InputDateCalendar}
            maxDate={undefined}
            minDate={undefined}
          />
          <i className="icon-date" />
        </div>
      </div>
    </div>
  )
}

CalendarSearch.propTypes = {}

export default CalendarSearch
