import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GROWTH_GDP_BY_SPENDING_METHOD } from '.'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import TimeFrame from '../../common/TimeFrame'
import { handleLineColor } from '../panelGDPSpendingMethod/helper'
import {
  getCheckboxChecked,
  getIndexChecked,
  getLineChartColor,
  getTableMappingData,
  handleCheckboxChecked,
  handleIndexChecked,
  handleLineChartColor,
} from '../panelGDPSpendingMethod/store/slice'
import LineChart from './LineChart'
import { YEAR_TIME_FRAME_OPTIONS } from './constants'
import { getIndicatorName } from './helper'
import {
  getLineChartData,
  getLineChartLoading,
  getTimeRange,
  handleActiveLine,
  handleTimeRange,
} from './store/slice'
import { getGDPGrowthBySpendingMethod } from './store/thunk'

const Container = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const GDPTypeName = useSelector(getTableMappingData)
  const lineChartData = useSelector(getLineChartData)
  const lineChartLoading = useSelector(getLineChartLoading)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const indexChecked = useSelector(getIndexChecked)
  const timeRange = useSelector(getTimeRange)
  const lineChartColor = useSelector(getLineChartColor)
  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )

  const [heightListIndicator, setHeightListIndicator] = useState(0)

  // Actions
  const handleChangeTab = (timeRange) => {
    dispatch(handleTimeRange(timeRange.value))
  }

  const handleDelete = (item) => {
    const isChecked = checkboxChecked.includes(item.author)
    const newCheckboxChecked = checkboxChecked.filter(
      (val) => val !== item.author,
    )
    const currentIndex = GDPTypeName.findIndex(
      (val) => val.nationalAccountVNTypeId === item.author,
    )
    const newIndexChecked = indexChecked.filter((val) => val !== currentIndex)
    dispatch(handleIndexChecked(newIndexChecked))
    dispatch(handleCheckboxChecked(newCheckboxChecked))
    dispatch(
      handleLineChartColor(
        handleLineColor(item.author, lineChartColor, isChecked),
      ),
    )
    dispatch(handleActiveLine(undefined))
  }

  const handleHover = (item) => {
    dispatch(handleActiveLine(item.author))
  }

  const handleUnHover = () => {
    dispatch(handleActiveLine(undefined))
  }

  useEffect(() => {
    dispatch(
      getGDPGrowthBySpendingMethod({
        nationalAccountVNTypeIds: checkboxChecked,
        timeRange,
      }),
    )
  }, [locale, checkboxChecked, timeRange])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <TimeFrame
              styleProps={{ justifyContent: 'end' }}
              timeFrameOption={YEAR_TIME_FRAME_OPTIONS}
              timeRange={timeRange}
              onChangeTab={handleChangeTab}
            />
            {lineChartLoading && <Loading />}
            {!lineChartLoading && (
              <>
                {lineChartData.length === 0 && <NoData />}
                {lineChartData.length !== 0 && (
                  <>
                    {typeof sizes.height === 'number' && (
                      <div id={GROWTH_GDP_BY_SPENDING_METHOD}>
                        <LineChart
                          width={width}
                          height={height - sizes.height}
                          data={lineChartData}
                          keyXAxis={'year'}
                          keyYAxisProps={checkboxChecked}
                          heightListIndicator={heightListIndicator}
                        />
                        <FooterWithScroll
                          width={width}
                          data={lineChartColorHasAuthor}
                          idKey="author"
                          nameKey="indicatorName"
                          formatName={(item) =>
                            getIndicatorName(GDPTypeName, item.author)
                          }
                          color={(item) => item.color}
                          legendPanel={legendPanel}
                          isFullScreen={isFullScreen}
                          setHeightFooter={setHeightListIndicator}
                          onDelete={handleDelete}
                          onHover={handleHover}
                          onUnHover={handleUnHover}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
