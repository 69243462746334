import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { LIST_TYPE } from '../../../common/constants'
import {
  equationFormatUnitConstants,
  equationIndicatorAliasAlphabet,
  equationType as equationTypeConstants,
} from '../constants'
import { getOrganizationsByExchangeAndICB, getWatchList } from './thunk'

const initialState = {
  equationSecurities: {
    [equationTypeConstants.SUM]: {},
    [equationTypeConstants.AVERAGE]: {},
    [equationTypeConstants.COUNT]: {},
  },
  equationIndicators: {
    [equationTypeConstants.SUM]: {},
    [equationTypeConstants.AVERAGE]: {},
    [equationTypeConstants.COUNT]: {},
  },
  equationType: equationTypeConstants.SUM,
  equationIndicatorName: '',
  formula: {
    [equationTypeConstants.SUM]: '',
    [equationTypeConstants.AVERAGE]: '',
  },
  formatUnit: {
    [equationTypeConstants.SUM]:
      equationFormatUnitConstants.EQUATION_FORMAT_VND,
    [equationTypeConstants.AVERAGE]:
      equationFormatUnitConstants.EQUATION_FORMAT_VND,
  },
  loading: false,
  firstMountPopupSector: true,
  firstMountPopupExchange: true,
  tickersByExchangeIcbs: [],
  watchList: [],
}

export const slice = createSlice({
  name: 'financialChart/popupAddEquation',
  initialState,
  reducers: {
    changeEquationDefaultState: (state, action) => {
      const {
        equationType,
        equationSecurities,
        equationIndicators,
        equationIndicatorName,
        equationFormula,
        equationFormatUnit,
      } = action.payload
      state.equationType = equationType
      state.equationIndicatorName = equationIndicatorName
      if (equationType === equationTypeConstants.COUNT) {
        state.equationSecurities[equationTypeConstants.COUNT] =
          equationSecurities || {}
        state.equationIndicators[equationTypeConstants.COUNT] =
          equationIndicators || {}
      } else {
        state.equationSecurities[equationTypeConstants.SUM] =
          equationSecurities || {}
        state.equationSecurities[equationTypeConstants.AVERAGE] =
          equationSecurities || {}
        state.equationIndicators[equationTypeConstants.SUM] =
          equationIndicators || {}
        state.equationIndicators[equationTypeConstants.AVERAGE] =
          equationIndicators || {}
        state.formula[equationType] = equationFormula || ''
        state.formatUnit[equationType] =
          equationFormatUnit || equationFormatUnitConstants.EQUATION_FORMAT_VND
      }
    },
    changeEquationType: (state, action) => {
      state.equationType = action.payload
      if (
        state.equationType !== equationTypeConstants.COUNT &&
        !state.formula[action.payload]
      ) {
        const usedAlias = []
        Object.values(state.equationIndicators[action.payload]).forEach(
          (indicator) =>
            Object.values(indicator.data).forEach(
              (item) => item.alias && usedAlias.push(item.alias),
            ),
        )
        state.formula[action.payload] = usedAlias.join('+')
      }
    },
    changeEquationSecurities: (state, action) => {
      if (action.payload) {
        const { value, type } = action.payload
        if (state.equationType === equationTypeConstants.COUNT) {
          state.equationSecurities[equationTypeConstants.COUNT][type] = value
        } else {
          state.equationSecurities[equationTypeConstants.SUM][type] = value
          state.equationSecurities[equationTypeConstants.AVERAGE][type] = value
        }
      } else {
        state.equationSecurities[state.equationType] = {}
      }
    },
    changeEquationIndicators: (state, action) => {
      if (action.payload) {
        const { id, value } = action.payload
        if (state.equationType === equationTypeConstants.COUNT) {
          state.equationIndicators[equationTypeConstants.COUNT][id] = value
        } else {
          state.equationIndicators[equationTypeConstants.SUM][id] = value
          state.equationIndicators[equationTypeConstants.AVERAGE][id] = value
        }
      } else {
        state.equationIndicators[state.equationType] = {}
      }
    },
    removeEquationIndicator: (state, action) => {
      const { id, dataKey } = action.payload
      const indicators = state.equationIndicators[state.equationType]
      const indicatorUpdate = {
        ...indicators[id],
        data: dataKey
          ? Object.keys(indicators[id].data).reduce(
              (obj, key, index) =>
                dataKey === key
                  ? obj
                  : { ...obj, [index]: indicators[id].data[key] },
              {},
            )
          : {},
      }
      const isIndicatorHaveData = Object.keys(indicatorUpdate.data).length > 0
      const usedAlias = []
      const getAlias = () => {
        const remainingAlphabet = equationIndicatorAliasAlphabet
          .split('')
          .filter((val) => !usedAlias.includes(val))
        return remainingAlphabet.length ? remainingAlphabet[0] : '--'
      }
      const newIndicators = Object.keys(indicators).reduce(
        (obj, key) =>
          id === key
            ? isIndicatorHaveData
              ? {
                  ...obj,
                  [key]: {
                    ...indicatorUpdate,
                    data: Object.keys(indicatorUpdate.data).reduce(
                      (obj, key) => {
                        const alias = getAlias()
                        usedAlias.push(alias)
                        return {
                          ...obj,
                          [key]: { ...indicatorUpdate.data[key], alias },
                        }
                      },
                      {},
                    ),
                  },
                }
              : obj
            : {
                ...obj,
                [key]: {
                  ...indicators[key],
                  data: Object.keys(indicators[key].data).reduce(
                    (obj, childKey) => {
                      const alias = getAlias()
                      usedAlias.push(alias)
                      return {
                        ...obj,
                        [childKey]: {
                          ...indicators[key].data[childKey],
                          alias,
                        },
                      }
                    },
                    {},
                  ),
                },
              },
        {},
      )

      if (state.equationType === equationTypeConstants.COUNT) {
        state.equationIndicators[equationTypeConstants.COUNT] = newIndicators
      } else {
        state.equationIndicators[equationTypeConstants.SUM] = newIndicators
        state.equationIndicators[equationTypeConstants.AVERAGE] = newIndicators
      }
    },
    changeSectorChecked: (state, action) => {
      if (
        state.equationSecurities[equationTypeConstants.COUNT][LIST_TYPE.SECTOR]
      ) {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.SECTOR
        ].childrenSector = action.payload
      } else {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.SECTOR
        ] = {
          childrenSector: action.payload,
        }
      }
    },
    changeParentSectorChecked: (state, action) => {
      if (
        state.equationSecurities[equationTypeConstants.COUNT][LIST_TYPE.SECTOR]
      ) {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.SECTOR
        ].parentSector = action.payload
      } else {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.SECTOR
        ] = {
          parentSector: action.payload,
        }
      }
    },
    changeExchangeChecked: (state, action) => {
      if (
        state.equationSecurities[equationTypeConstants.COUNT][LIST_TYPE.INDICES]
      ) {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.INDICES
        ].childrenExchange = action.payload
      } else {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.INDICES
        ] = {
          childrenExchange: action.payload,
        }
      }
    },
    changeParentExchangeChecked: (state, action) => {
      if (
        state.equationSecurities[equationTypeConstants.COUNT][LIST_TYPE.INDICES]
      ) {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.INDICES
        ].parentExchange = action.payload
      } else {
        state.equationSecurities[equationTypeConstants.COUNT][
          LIST_TYPE.INDICES
        ] = {
          parentExchange: action.payload,
        }
      }
    },
    changeFirstMountPopupSector: (state, action) => {
      state.firstMountPopupSector = action.payload
    },
    changeFirstMountPopupExchange: (state, action) => {
      state.firstMountPopupExchange = action.payload
    },
    changeEquationIndicatorName: (state, action) => {
      state.equationIndicatorName = action.payload
    },
    changeEquationFormula: (state, action) => {
      state.formula[state.equationType] = action.payload
    },
    changeEquationFormatUnit: (state, action) => {
      state.formatUnit[state.equationType] = action.payload
    },
    reset: (state) => {
      state.equationIndicators = {
        [equationTypeConstants.SUM]: {},
        [equationTypeConstants.AVERAGE]: {},
        [equationTypeConstants.COUNT]: {},
      }
      state.equationSecurities = {
        [equationTypeConstants.SUM]: {},
        [equationTypeConstants.AVERAGE]: {},
        [equationTypeConstants.COUNT]: {},
      }
      state.equationType = equationTypeConstants.SUM
      state.equationIndicatorName = ''
      state.firstMountPopupExchange = true
      state.firstMountPopupSector = true
      state.formula = {
        [equationTypeConstants.SUM]: '',
        [equationTypeConstants.AVERAGE]: '',
      }
      state.formatUnit = {
        [equationTypeConstants.SUM]:
          equationFormatUnitConstants.EQUATION_FORMAT_VND,
        [equationTypeConstants.AVERAGE]:
          equationFormatUnitConstants.EQUATION_FORMAT_VND,
      }
    },
  },
  extraReducers: (builder) => {
    //GetOrgIdsByIcb
    builder.addCase(getOrganizationsByExchangeAndICB.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getOrganizationsByExchangeAndICB.fulfilled,
      (state, action) => {
        const data = action.payload
        state.tickersByExchangeIcbs = [
          ...data
            .filter((item) => !!item.ticker)
            .sort((a, b) => a.ticker?.localeCompare(b.ticker)),
          ...data
            .filter((item) => !item.ticker)
            .sort((a, b) => a.taxCode?.localeCompare(b.taxCode)),
        ]
        state.loading = false
      },
    )
    builder.addCase(
      getOrganizationsByExchangeAndICB.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    //GetWatchList
    builder.addCase(getWatchList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getWatchList.fulfilled, (state, action) => {
      state.watchList = action.payload
      state.loading = false
    })
    builder.addCase(getWatchList.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const {
  changeEquationDefaultState,
  changeEquationSecurities,
  changeEquationIndicators,
  removeEquationIndicator,
  changeEquationType,
  changeParentSectorChecked,
  changeSectorChecked,
  changeParentExchangeChecked,
  changeExchangeChecked,
  changeFirstMountPopupSector,
  changeFirstMountPopupExchange,
  changeEquationIndicatorName,
  changeEquationFormula,
  changeEquationFormatUnit,
  reset,
} = slice.actions

export const selectEquationSecurities = (state) => {
  const equationType = state[slice.name].equationType
  return state[slice.name].equationSecurities[equationType]
}
export const selectEquationIndicators = (state) => {
  const equationType = state[slice.name].equationType
  return state[slice.name].equationIndicators[equationType]
}
export const selectEquationIndicatorName = (state) =>
  state[slice.name].equationIndicatorName
export const selectEquationType = (state) => state[slice.name].equationType
export const selectEquationLoading = (state) => state[slice.name].loading
export const selectSectorChecked = (state) =>
  state[slice.name].equationSecurities[equationTypeConstants.COUNT][
    LIST_TYPE.SECTOR
  ]
export const selectExchangeChecked = (state) =>
  state[slice.name].equationSecurities[equationTypeConstants.COUNT][
    LIST_TYPE.INDICES
  ]
export const selectFirstMountPopupSector = (state) =>
  state[slice.name].firstMountPopupSector
export const selectFirstMountPopupExchange = (state) =>
  state[slice.name].firstMountPopupExchange
export const selectTickersByExchangeIcbs = (state) =>
  state[slice.name].tickersByExchangeIcbs
export const selectWatchlist = (state) => state[slice.name].watchList
export const selectEquationFormula = (state) => {
  const equationType = state[slice.name].equationType
  return state[slice.name].formula
    ? state[slice.name].formula[equationType]
    : ''
}
export const selectEquationFormatUnit = (state) => {
  const equationType = state[slice.name].equationType
  return state[slice.name].formatUnit
    ? state[slice.name].formatUnit[equationType]
    : equationFormatUnitConstants.EQUATION_FORMAT_VND
}

register(slice.name, slice.reducer)
