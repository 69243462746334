import { useState } from 'react'
import { useLocation } from 'react-router'
import useBasicInfo, {
  TYPE_STOCK_INDICES_SECTOR,
} from '../../../../common/hooks/useBasicInfo'
import { FEATURES } from '../../../../constants/routes'
import CurrentState from '../../../../utils/CurrentState'
import style from '../index.module.css'

export const LinkCWDetail = ({ organizationId, val }) => {
  const location = useLocation()
  const { getBasicInfo } = useBasicInfo()

  const [isHoverText, setIsHoverText] = useState(false)

  const overText = () => setIsHoverText(true)

  const outText = () => setIsHoverText(false)

  const handleOpenNewTab = (route, headerSearch) => {
    CurrentState.addRoute({
      key: location.pathname,
      data: {
        route: route,
        disableRecentVisited: true,
        headerSearch,
      },
    })
    window.open(route, '_blank')
  }

  const openCWDetailTab = (organizationId) => {
    return handleOpenNewTab(
      FEATURES.market.components['covered-warrant'].components.overview.route,
      getBasicInfo(organizationId, TYPE_STOCK_INDICES_SECTOR.COVERER_WARRANT),
    )
  }

  return (
    <>
      <span
        className={`cursor-pointer ${isHoverText ? style.active : ''}`}
        onMouseOver={overText}
        onMouseOut={outText}
        style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
        onClick={(e) => {
          e.stopPropagation()
          openCWDetailTab(organizationId)
        }}
      >
        {val}
      </span>
    </>
  )
}
