import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'
import {
  getIndexStatisticsForeignDetail,
  getIndexStatisticsForeignOverview,
  getIndexStatisticsForeignOverviewTotal,
} from './thunk'

const slice = createSlice({
  name: 'market/marketInDepth/indexStatistics/summary/foreign',
  initialState: {
    ids: [],
    initialIds: [],
    data: [],
    dataByIds: {},
    filter: {
      Page: 1,
      PageSize: 22,
      TimeFrequency: PRICE_DATA_TIME_SELECT.DAILY,
      SortField: 'tradingDateId',
      SortOrder: 1,
    },
    dataDateRange: {
      startDate: '',
      endDate: '',
      minDate: '',
      minYear: '',
      maxDate: '',
      maxYear: '',
    },
    loading: true,
    totalPage: 0,
    reCalcWidths: false,
  },
  reducers: {
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataByIds,
        action.payload,
        state.initialIds,
      )
    },
    changeFilter: (state, action) => {
      state.filter = { ...state.filter, ...action.payload }
    },
    resetData: (state) => {
      state.ids = []
      state.data = []
      state.dataByIds = {}
      state.initialIds = []
      state.totalPage = 0
      state.isLoading = false
      state.dataDateRange = {
        startDate: '',
        endDate: '',
        minDate: '',
        minYear: '',
        maxDate: '',
        maxYear: '',
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIndexStatisticsForeignOverview.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getIndexStatisticsForeignOverview.fulfilled,
      (state, action) => {
        state.loading = false
        const data = action.payload.data.map((item, index) => ({
          ...item,
          id: index,
        }))
        state.data = data
        state.ids = data.map((item) => item.id)
        state.initialIds = data.map((item) => item.id)
        state.dataByIds = keyBy(data, 'id')
        state.totalPage = action.payload.totalPage
        state.reCalcWidths = !state.reCalcWidths

        if (action.payload.data.length) {
          state.dataDateRange = {
            ...state.dataDateRange,
            minDate: action.payload.data[0].minDate,
            minYear: new Date(action.payload.data[0].minDate).getFullYear(),
            maxDate: action.payload.data[0].maxDate,
            maxYear: new Date(action.payload.data[0].maxDate).getFullYear(),
          }
        }

        if (
          !state.dataDateRange.startDate &&
          (!state.filter.StartDate || !state.filter.EndDate) &&
          action.payload.data.length
        ) {
          state.dataDateRange = {
            ...state.dataDateRange,
            startDate: action.payload.data[0].startDate || '',
            endDate: action.payload.data[0].endDate || '',
          }
        }
      },
    )
    builder.addCase(
      getIndexStatisticsForeignOverview.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(getIndexStatisticsForeignDetail.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getIndexStatisticsForeignDetail.fulfilled,
      (state, action) => {
        state.loading = false
        const data = action.payload.data.map((v, index) => ({
          ...v,
          id: index,
        }))
        state.data = data
        state.ids = data.map((item) => item.id)
        state.initialIds = data.map((item) => item.id)
        state.dataByIds = keyBy(data, 'id')
        state.totalPage = action.payload.totalPage
        state.reCalcWidths = !state.reCalcWidths

        if (action.payload.data.length) {
          state.dataDateRange = {
            ...state.dataDateRange,
            minDate: action.payload.data[0].minDate,
            minYear: new Date(action.payload.data[0].minDate).getFullYear(),
            maxDate: action.payload.data[0].maxDate,
            maxYear: new Date(action.payload.data[0].maxDate).getFullYear(),
          }
        }

        if (
          action.payload.data.length &&
          !state.dataDateRange.startDate &&
          (!state.filter.StartDate || !state.filter.EndDate) &&
          action.payload.data.length
        ) {
          state.dataDateRange = {
            ...state.dataDateRange,
            startDate: action.payload.data[0].startDate || '',
            endDate: action.payload.data[0].endDate || '',
          }
        }
      },
    )
    builder.addCase(
      getIndexStatisticsForeignDetail.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
    builder.addCase(getIndexStatisticsForeignOverviewTotal.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getIndexStatisticsForeignOverviewTotal.fulfilled,
      (state, action) => {
        state.loading = false
        let data = state.data
        if (!data[0].total) {
          data = [
            ...action.payload.data
              .map((item) => ({
                ...item,
                tradingDateId: item.total,
              }))
              .filter((_, index) => index === 0),
            ...state.data,
          ].map((item, index) => ({
            ...item,
            id: index,
          }))
        }
        state.data = data
        state.ids = data.map((item) => item.id)
        state.initialIds = data.map((item) => item.id)
        state.dataByIds = keyBy(data, 'id')
        state.reCalcWidths = !state.reCalcWidths
      },
    )
    builder.addCase(
      getIndexStatisticsForeignOverviewTotal.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { sort, changeFilter, resetData } = slice.actions
export const selectDataForeign = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name]?.dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectFilter = (state) => state[slice.name].filter
export const selectLoading = (state) => state[slice.name].loading
export const selectTotalPage = (state) => state[slice.name].totalPage
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectDataDateRange = (state) => state[slice.name].dataDateRange
export const selectDataByIds = (state) => state[slice.name].dataByIds

register(slice.name, slice.reducer)
