import PropTypes from 'prop-types'
import { useState } from 'react'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FEATURES } from '../../constants/routes'
import {
  getSearchItemMoreOptions,
  getStockIndicesSectorInfo,
  highlightString,
} from '../../utils/Search'
import { SEARCH_TYPE } from '../constants'
import style from './index.module.css'
import { SearchItemDropdown } from './SearchItemDropdown'

export const MarIndicesItem = (props) => {
  const { searchStr, data, getBasicInfo } = props
  const navigate = useCustomNavigate()

  const [isActive, setIsActive] = useState(false)

  const getGroupName = () => {
    let groupName = ''
    let groupNameHighlight
    if (data.groupName) {
      groupName = data.groupName
      groupNameHighlight = data.highlight.groupName
    } else if (data.en_GroupName) {
      groupName = data.en_GroupName
      groupNameHighlight = data.highlight.en_GroupName
    } else {
      return
    }
    return highlightString(groupName, groupNameHighlight, searchStr)
  }

  const getGroupCode = () => {
    return highlightString(data.groupCode, data.highlight.groupCode, searchStr)
  }

  const onToggleMoreOption = (value) => {
    setIsActive(value)
  }

  const onRedirect = () => {
    navigate(
      FEATURES.market.components['market-in-depth'].components.indices.route,
      getStockIndicesSectorInfo(data, getBasicInfo),
    )
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div
        className={`d-flex align-center j-b search-content ${
          style['search-content']
        } ${isActive ? style['search-content-active'] : ''}`}
      >
        <div>
          <div className={style['item-title']}>{getGroupName()}</div>
          <div className="date">
            {data.exchangeCode && <span>{data.exchangeCode}/ </span>}
            <span>{getGroupCode()}</span>
          </div>
        </div>
        <SearchItemDropdown
          data={data}
          options={getSearchItemMoreOptions(
            SEARCH_TYPE.INDICE,
            [data.groupCode],
            FEATURES,
          )}
          onToggle={onToggleMoreOption}
          getBasicInfo={getBasicInfo}
        />
      </div>
    </a>
  )
}

MarIndicesItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
