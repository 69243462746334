import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import CalendarPopper from '../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Icon } from '../../../common/html/Icon'
import {
  FORMAT,
  compareTwoDateTime,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { selecBondInfo } from '../infoSec/store/slice'
import { typePopup } from './constants'
import style from './index.module.css'
import { openPopup } from './store/slice'
const startYear = 2000
const endYear = new Date().getFullYear() + 30

const InputExDate = ({
  date,
  onChangeDate,
  disabled,
  checkMaxDate = true,
  isWrap = true,
}) => {
  const dispatch = useDispatch()
  const bondInfo = useSelector(selecBondInfo)
  const timeZone = UseTimeZone()
  const handleChangeDate = (date) => {
    if (compareTwoDateTime(date, bondInfo?.issueDateId) === -1) {
      dispatch(
        openPopup({
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_MS0010'),
          isShow: true,
          type: typePopup.WARNING,
        }),
      )
      return
    }
    if (
      compareTwoDateTime(date, bondInfo?.maturityDateId) === 1 &&
      checkMaxDate === true
    ) {
      dispatch(
        openPopup({
          message: I18n.t('bond.valuation.sameIndusTryBond.SC07_MS0010'),
          isShow: true,
          type: typePopup.WARNING,
        }),
      )
      return
    }
    onChangeDate(date ? getISOStartOrEndOfDay(date, timeZone, true) : null)
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE, undefined, timeZone))
  }

  return (
    <div style={{ width: 134, flexShrink: 0 }}>
      <div className="form-control calendar mb-0">
        <div className={`${isWrap && style.width120} ${style.bgGrey} w-100`}>
          <CalendarPopper
            date={date ? getDateWithTimeZone(date) : null}
            // minDate={bondInfo?.issueDateId}
            // maxDate={bondInfo?.maturityDateId}
            handleChange={handleChangeDate}
            customHeader={HeaderOneCalendarRange({
              startYear,
              endYear,
            })}
            CustomInput={InputDateCalendar}
            disabled={disabled}
          />
        </div>
        <Icon
          className={`icon-date ${style.eventNone}`}
          style={{ fontSize: 12 }}
        />
      </div>
    </div>
  )
}

export default InputExDate
