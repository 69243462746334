export const coveredWarrant = {
  SUMMARY: 'Tổng quan',
  PRICE_DEPTH: 'Chi tiết bước giá',
  BREAK_EVEN_POINT: 'Điểm hòa vốn',
  TIME_AND_SALES: 'Time & Sales',
  CHARTING: 'Biểu đồ PT kĩ thuật',
  COVERED_WARRANT: 'Chứng quyền trực tuyến',
  PRICE_VOLUME_UNIT: 'Giá: x1,000. Khối lượng: x1,000',
  summary: {
    CW_TICKER: 'Mã CQ',
    CW_ISSUER: 'Tổ chức phát hành',
    CW_TYPE: 'Loại CQ',
    EXERCISE_STYLE: 'Kiểu thực hiện CQ',
    EXERCISE_PRICE: 'Giá thực hiện',
    CONVERSION_RATIO: 'Tỉ lệ chuyển đổi',
    UNDERLYING_ASSET: 'Chứng khoán cơ sở',
    PRICE_OF_UNDERLYING_ASSET: 'Giá của chứng khoán cơ sở',
    SETTLEMENT_STATUS: 'Phương thức thanh toán',
    BREAK_EVEN_POINT: 'Điểm hòa vốn',
    ISSUED_QUANTITY: 'Khối lượng phát hành',
    MATURITY_DATE: 'Ngày đáo hạn',
    EXPIRY_DATE: 'Ngày giao dịch cuối cùng',
    DURATION: 'Thời gian đáo hạn',
    AVG_5_DAYS: 'Giá CKCS TB 5 phiên',
    EST_CW_PRICE: 'Giá CQ dự kiến tại ngày đáo hạn',
    DAYS: ' ngày',
  },
  breakEvenPoint: {
    CW_BREAK_EVEN_POINT: 'Điểm hòa vốn của CQ',
    UNDERLYING_ASSET_PRICE: 'Giá CK cơ sở',
    EXERCISE_PRICE: 'Giá thực hiện',
    THOUSAND_VND: 'Nghìn VND',
  },
  priceDepth: {
    BID: 'GIÁ MUA',
    ASK: 'GIÁ BÁN',
    BUY_VOL: 'KL MUA',
    SELL_VOL: 'KL BÁN',
    TOTAL_BUY_VOL: 'TỔNG KL MUA',
    TOTAL_MATCHED_VOL: 'TỔNG KL KHỚP',
    TOTAL_SELL_VOL: 'TỔNG KL BÁN',
    PERCENT_1D_CHANGE: '% Thay đổi 1 ngày',
    PERCENT_1W_CHANGE: '% Thay đổi 1 tuần',
    PERCENT_1M_CHANGE: '% Thay đổi 1 tháng',
    PERCENT_FTD_CHANGE: '% Thay đổi FTD',
    TOTAL_VALUE_BN_VND: 'Tổng giá trị',
    TOTAL_VOLUME: 'Tổng khối lượng',
    FOREIGN_BUY_VOLUME: 'Khối lượng NN mua',
    FOREIGN_SELL_VOLUME: 'Khối lượng NN bán',
    AVG_VOLUME_10D: 'Khối lượng trung bình 10 ngày',
    AVG_VOLUME_1M: 'Khối lượng trung bình 1 tháng',
  },
  timeAndSales: {
    MATCH_PRICE: 'Giá khớp',
    BU_VOLUME: 'KL mua chủ động',
    SD_VOLUME: 'KL bán chủ động',
    UNIDENTIFIED: 'Không xác định',
    TYPE: 'Loại',
    LAST_PRICE: 'Giá khớp',
    CHANGE: 'Thay đổi',
    PERCENT_CHANGE: '% Thay đổi',
    MATCH_VOLUME: 'Khối lượng khớp',
    TOTAL_MATCH_VOLUME: 'Tổng khối lượng khớp',
    BN: 'Bn.',
  },

  cwStatistics: {
    CW_STATISTICS: 'THỐNG KÊ CHỨNG QUYỀN',

    PRICE_DATA: 'Thống kê giá',
    NET_VALUE_INVESTOR: 'Giá trị ròng theo nhà đầu tư',
    NET_TRADING_BREAKDOWN: 'Phân tách giá trị giao dịch',

    TICKER: 'Chứng quyền',
    ALL: 'Tất cả',
    OVERVIEW: 'Tổng quan',
    DETAIL: 'Chi tiết',
    NOTE_TABLE: 'Giá: x 1,000. Khối lượng: x 1,000. Giá trị: x 1,000,000.',
    FROM: 'Từ',
    TO: 'Đến',
    CLEAR: 'Xóa',
    DATE: 'Ngày',
    TICKER_TABLE: 'Mã CQ',
    ALL_MARKET: 'Toàn thị trường',
    TRADING_DATE: 'Ngày giao dịch',

    WEEK: 'Tuần',
    MONTH: 'Tháng',
    QUARTER: 'Quý',
    YEAR: 'Năm',

    MILLION_USD: 'Triệu USD',
    VALUE_MILLION: 'Giá trị (Triệu VND)',
    PRICE_THOUSAND: 'Giá (Nghìn VND)',
    MILLION_VND: 'Triệu VND',
    THOUSAND_VND: 'Nghìn VND',
    VAL_BILLION: 'Giá trị ròng (Tỷ VND)',
    BILLION: 'Tỷ VND',

    BUY: 'GT Mua',
    SELL: 'GT Bán',
    NET: 'GT Ròng',

    LOCAL_INDIVIDUAL: 'Cá nhân trong nước',
    FOREIGN: 'Nước ngoài',
    LOCAL_INSTITUTION: 'Tổ chức trong nước',
    PROPRIETARY: 'Tự doanh',

    VIEW_BY: 'Xem dữ liệu theo',
    DAILY: 'Ngày',
    WEEKLY: 'Tuần',
    MONTHLY: 'Tháng',
    QUARTERLY: 'Quý',
    YEARLY: 'Năm',

    overview: {
      OVERVIEW: 'Tổng quan',

      STOP: 'Ngừng giao dịch',
      TRADING: 'Đang giao dịch',

      ORDER: 'STT',
      TICKER: 'Mã CQ',
      TRADING_STATUS: 'Trạng thái GD',
      ISSUER: 'TCPH',
      CW_ASSURED: 'Chứng quyền có đảm bảo',
      CW_TYPE: 'Loại chứng quyền',
      EXECUTIVE_RATIO: 'Tỷ lệ chuyển đổi',
      ISSUE_PRICE: 'Giá phát hành',
      CW_PRICE: 'Giá hiện tại',
      UNDERLYING_ASSET: 'TSCS',
      UNDERLYING_ASSET_CHILD: 'TSCS',
      EXERCISE_PRICE: 'Giá thực hiện',
      AVG_PRICE: 'Giá TB 5 phiên',
      BREAK_EVEN_PRICE: 'Giá hoà vốn',
      CURRENT_PRICE: 'Giá hiện tại',
      PERCENT_PREMIUM: '% Premium',
      PERCENT_CHANGE: '%Thay đổi 1D',
      CW_STATUS: 'Trạng thái CW',
      DURATION: 'Thời gian đáo hạn (ngày)',
      LAST_TRADING_DATE: 'Ngày GD cuối cùng',
      FIRST_TRADING_DATE: 'Ngày GD đầu tiên',
      CLOSE_PRICE: 'Giá đóng cửa',
      PERCENT_CLOSE_PRICE_CHANGE: '%Thay đổi (Giá đóng cửa)',
      CLOSE_PRICE_TSCS: 'Giá đóng cửa (TSCS)',
      TOOLTIP_CLOSE_PRICE_UPDATED:
        'Giá đóng cửa mới nhất được cập nhật ngày %{maxTradingDate}',
      TOOLTIP_PERCENT_CLOSE_PRICE_UPDATED:
        '%Thay đổi (Giá đóng cửa) mới nhất được cập nhật ngày %{maxTradingDate}',
    },

    historicalPrice: {
      HISTORICAL_PRICE: 'Lịch sử giá',

      DATE: 'Ngày',
      ORDER_MATCHING: 'Khớp lệnh',
      VOLUME: 'Khối lượng',
      VALUE: 'Giá trị',
      PUT_THROUGH: 'Thỏa thuận',
      TOTAL_VOLUME: 'Khối lượng giao dịch',
      TOTAL_VALUE: 'Giá trị giao dịch',

      TICKER_TITLE: 'Mã CQ',
      PERCENT_CHANGE: '%Thay đổi 1D',
      UNDERLYING_ASSET: 'Giá CKCS',
      BREAK_EVEN: 'Giá hoà vốn',
      AVG_FIVE_DAYS: 'Giá TB 5 phiên',
      PERCENT_PREMIUM: '%Premium',

      OPEN: 'Mở cửa',
      HIGHEST: 'Cao nhất',
      LOWEST: 'Thấp nhất',
      CLOSE: 'Đóng cửa',
    },

    foreign: {
      FOREIGN: 'Giao dịch nước ngoài',

      BUY_VOLUME: 'Tổng KL mua',
      BUY_VALUE: 'Tổng GT mua',
      SELL_VOLUME: 'Tổng KL bán',
      SELL_VALUE: 'Tổng GT bán',
      NET_VOLUME: 'Tổng KL ròng',
      NET_VALUE: 'Tổng GT ròng',
      ORDER_MATCHING_BUY_VOL: 'KL mua khớp lệnh',
      ORDER_MATCHING_BUY_VAL: 'GT mua khớp lệnh',
      ORDER_MATCHING_SELL_VOL: 'KL bán khớp lệnh',
      ORDER_MATCHING_SELL_VAL: 'GT bán khớp lệnh',
      PUT_THROUGH_BUY_VOL: 'KL mua thoả thuận',
      PUT_THROUGH_BUY_VAL: 'GT mua thoả thuận',
      PUT_THROUGH_SELL_VOL: 'KL bán thoả thuận',
      PUT_THROUGH_SELL_VAL: 'GT bán thoả thuận',
    },

    orderStatistics: {
      ORDER_STATISTICS: 'Thống kê đặt lệnh',

      DATE: 'Ngày',
      TICKER: 'Mã CQ',
      UNMATCHED_BUY: 'Dư mua',
      UNMATCHED_SELL: 'Dư bán',
      BUY_ORDERS: 'Số lệnh mua',
      BUY_VOLUME: 'Khối lượng mua',
      AVG_BUY: 'Trung bình lệnh mua',
      SELL_ORDERS: 'Số lệnh bán',
      SELL_VOLUME: 'Khối lượng bán',
      AVG_SELL: 'Trung bình lệnh bán',
      NET_BUY_SELL: 'KL ròng',
    },

    proprietary: {
      PROPRIETARY: 'Tự doanh',

      TOTAL_BUY_VOL: 'TỔNG KL MUA',
      TOTAL_BUY_VAL: 'TỔNG GT MUA',
      TOTAL_SELL_VOL: 'TỔNG KL BÁN',
      TOTAL_SELL_VAL: 'TỔNG GT BÁN',
      NET_TOTAL_VOL: 'TỔNG KL RÒNG',
      NET_TOTAL_VAL: 'TỔNG GT RÒNG',
      MATCH_BUY_VOL: 'KL MUA KHỚP LỆNH',
      MATCH_BUY_VAL: 'GT MUA KHỚP LỆNH',
      MATCH_SELL_VOL: 'KL BÁN KHỚP LỆNH',
      MATCH_SELL_VAL: 'GT BÁN KHỚP LỆNH',
      DEAL_BUY_VOL: 'KL MUA THỎA THUẬN',
      DEAL_BUY_VAL: 'GT MUA THỎA THUẬN',
      DEAL_SELL_VOL: 'KL BÁN THỎA THUẬN',
      DEAL_SELL_VAL: 'GT BÁN THỎA THUẬN',
    },
  },
}
