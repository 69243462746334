export const SIMPLE_SEARCH_PARAMS = {
  page: 1,
  pageSize: 25,
}

export const OVERVIEW_GROUP_ID = {
  BANK: 80,
  SECURITIES: 81,
  CORPORATE: 0 || null,
  INSURANCE: 79,
}
