import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  TIME_FREQUENCY_TABS_TRADING_VALUE,
  TIME_FREQUENCY_TRADING_VALUE,
} from '../constants'
import { formatDataTable } from '../helper'
import {
  getCorrelationOfMarket,
  getMarketTradingValueBySectorData,
  getMarketTradingValueData,
  getSectorStatistics,
} from './thunk'

export const keys = {
  SECTOR_STATISTIC: 'sectorStatistic',
  CORRELATION_OF_MARKET: 'correlationOfMarket',
  MARKET_TRADING_VALUE: 'marketTradingValue',
  MARKET_TRADING_VALUE_BY_SECTOR: 'marketTradingValueBySector',
}

const initialState = {
  loading: {
    [keys.SECTOR_STATISTIC]: true,
    [keys.CORRELATION_OF_MARKET]: true,
    [keys.MARKET_TRADING_VALUE]: true,
    [keys.MARKET_TRADING_VALUE_BY_SECTOR]: true,
  },
  loadingIcbIds: true,
  activeTab: {
    methodType: 'All',
    timeType: 'Yearly',
  },
  data: {
    [keys.SECTOR_STATISTIC]: {
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      rowsCollapse: [],
    },
    [keys.CORRELATION_OF_MARKET]: {
      data: [],
    },
    [keys.MARKET_TRADING_VALUE]: [],
    [keys.MARKET_TRADING_VALUE_BY_SECTOR]: [],
  },
  filter: {
    [keys.SECTOR_STATISTIC]: {
      timeRange: 'QuarterToDate',
      From: new Date(),
      To: new Date(),
    },
    [keys.CORRELATION_OF_MARKET]: {
      timeRange: 'QuarterToDate',
      isSector: true,
      remainFrom: '',
      remainTo: '',
      From: new Date(),
      To: new Date(),
    },
    [keys.MARKET_TRADING_VALUE]: {
      timeFrequency: TIME_FREQUENCY_TABS_TRADING_VALUE[3].value,
      isTradingMethod: false,
      icbIds: [],
      From: new Date(),
      To: new Date(),
    },
    [keys.MARKET_TRADING_VALUE_BY_SECTOR]: {
      timeRange: 'QuarterToDate',
      top: 5,
      icbIds: [],
      remainingDurationType: 'All',
    },
  },
}

export const slice = createSlice({
  name: 'bond/corporateBond/secondaryMarketStatistic',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeActiveMethodType(state, actions) {
      state.activeTab = {
        ...state.activeTab,
        methodType: actions.payload,
      }
    },
    changeActiveTimeType(state, actions) {
      state.activeTab = {
        ...state.activeTab,
        timeType: actions.payload,
      }
    },
    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } =
        state.data[keys.SECTOR_STATISTIC]

      const idsFromProps = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      ).filter((item) => typeof item === 'number')

      const newIds = []

      idsFromProps.forEach((item) => {
        newIds.push(item)

        const idsByParent = initialIds.filter(
          (id) =>
            typeof id === 'string' && parseInt(id.split('-')?.[1]) === item,
        )

        idsByParent.forEach((id) => newIds.push(id))
      })

      state.data[keys.SECTOR_STATISTIC] = {
        ...state.data[keys.SECTOR_STATISTIC],
        ids: newIds,
      }
    },
    setRowsCollapse: (state, action) => {
      state.data[keys.SECTOR_STATISTIC] = {
        ...state.data[keys.SECTOR_STATISTIC],
        rowsCollapse: action.payload,
      }
    },
    changeFilter: (state, action) => {
      state.filter[action.payload.label] = {
        ...state.filter[action.payload.label],
        [action.payload.key]: action.payload.value,
      }
      if (action.payload.label === keys.MARKET_TRADING_VALUE_BY_SECTOR) {
        state.loadingIcbIds = false
      }
    },
    changeLoadingIcbIds: (state, action) => {
      state.loadingIcbIds = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getSectorStatistics.pending, (state) => {
      state.loading[keys.SECTOR_STATISTIC] = true
    })
    builder.addCase(getSectorStatistics.fulfilled, (state, action) => {
      const data = formatDataTable(action.payload)

      state.data[keys.SECTOR_STATISTIC] = {
        ...state.data[keys.SECTOR_STATISTIC],
        data,
        ids: data.map((item) => item.id),
        initialIds: data.map((item) => item.id),
        dataById: keyBy(data, 'id'),
        levels: data,
      }
      state.loading[keys.SECTOR_STATISTIC] = false
    })
    builder.addCase(getSectorStatistics.rejected, (state, action) => {
      state.loading[keys.SECTOR_STATISTIC] = action.payload
    })
    builder.addCase(getCorrelationOfMarket.pending, (state) => {
      state.loading[keys.CORRELATION_OF_MARKET] = true
    })
    builder.addCase(getCorrelationOfMarket.fulfilled, (state, action) => {
      state.data[keys.CORRELATION_OF_MARKET] = {
        ...state.data[keys.CORRELATION_OF_MARKET],
        data: action.payload.data,
      }
      state.loading[keys.CORRELATION_OF_MARKET] = false
    })
    builder.addCase(getCorrelationOfMarket.rejected, (state, action) => {
      state.loading[keys.CORRELATION_OF_MARKET] = action.payload
    })
    builder.addCase(getMarketTradingValueData.pending, (state) => {
      state.loading[keys.MARKET_TRADING_VALUE] = true
    })
    builder.addCase(getMarketTradingValueData.fulfilled, (state, action) => {
      state.data[keys.MARKET_TRADING_VALUE] =
        state.filter[keys.MARKET_TRADING_VALUE].timeFrequency ===
        TIME_FREQUENCY_TRADING_VALUE.M
          ? action.payload.data.sort((a, b) =>
              a.date.split('-')[1] === b.date.split('-')[1]
                ? a.date.split('-')[0] - b.date.split('-')[0]
                : a.date.split('-')[1] - b.date.split('-')[1],
            )
          : state.filter[keys.MARKET_TRADING_VALUE].timeFrequency ===
            TIME_FREQUENCY_TRADING_VALUE.Q
          ? action.payload.data.sort((a, b) =>
              a.date.split('-')[1] === b.date.split('-')[1]
                ? a.date.split('-')[0].replace('Q', '') -
                  b.date.split('-')[0].replace('Q', '')
                : a.date.split('-')[1] - b.date.split('-')[1],
            )
          : action.payload.data.sort(
              (a, b) => moment(a.date).unix() - moment(b.date).unix(),
            )
      state.loading[keys.MARKET_TRADING_VALUE] = false
    })
    builder.addCase(getMarketTradingValueData.rejected, (state, action) => {
      state.loading[keys.MARKET_TRADING_VALUE] = action.payload
    })
    builder.addCase(getMarketTradingValueBySectorData.pending, (state) => {
      state.loading[keys.MARKET_TRADING_VALUE_BY_SECTOR] = true
    })
    builder.addCase(
      getMarketTradingValueBySectorData.fulfilled,
      (state, action) => {
        state.data[keys.MARKET_TRADING_VALUE_BY_SECTOR] = action.payload
        state.loading[keys.MARKET_TRADING_VALUE_BY_SECTOR] = false
      },
    )
    builder.addCase(
      getMarketTradingValueBySectorData.rejected,
      (state, action) => {
        state.loading[keys.MARKET_TRADING_VALUE_BY_SECTOR] = action.payload
      },
    )
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]
export const selectActiveMethodType = (state) =>
  state[slice.name].activeTab.methodType
export const selectActiveTimeType = (state) =>
  state[slice.name].activeTab.timeType

// Sector Statistics
export const selectFilterSectorStatistic = (state) =>
  state[slice.name].filter[keys.SECTOR_STATISTIC]
export const selectSectorStatisticData = (state) =>
  state[slice.name].data[keys.SECTOR_STATISTIC]
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.SECTOR_STATISTIC].dataById[id],
    attr,
  )

// Correlation of secondary market
export const selectFilterCorrelationOfMarket = (state) =>
  state[slice.name].filter[keys.CORRELATION_OF_MARKET]
export const selectCorrelationOfMarketDataChart = (state) =>
  state[slice.name].data[keys.CORRELATION_OF_MARKET]

// Trading Market Data
export const selectFilterMarketTradingValue = (state) =>
  state[slice.name].filter[keys.MARKET_TRADING_VALUE]
export const selectMarketTradingValueData = (state) =>
  state[slice.name].data[keys.MARKET_TRADING_VALUE]

// Trading Market Data By Sector
export const selectFilterMarketTradingValueBySector = (state) =>
  state[slice.name].filter[keys.MARKET_TRADING_VALUE_BY_SECTOR]
export const selectMarketTradingValueBySectorData = (state) =>
  state[slice.name].data[keys.MARKET_TRADING_VALUE_BY_SECTOR]
export const selectLoadingIcbIds = (state) => state[slice.name].loadingIcbIds

export const {
  resetStore,
  changeActiveMethodType,
  changeActiveTimeType,
  changeFilter,
  setRowsCollapse,
  sort,
  changeFilterIcbIds,
  changeLoadingIcbIds,
  selectFilterPriceBoard,
} = slice.actions

register(slice.name, slice.reducer)
