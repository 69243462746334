import LinkSector from '../../../../common/dropdownLink/LinkSector'
import LinkTicker from '../../../../common/dropdownLink/LinkTicker'
import TextEllipsis from '../../../../common/textEllipsis'
import { ITEM_TYPE } from '../constant'

const NameCell = ({ value, rowId }) => {
  // eslint-disable-next-line no-unused-vars
  const [_, type, id] = rowId.split('-')

  return type === ITEM_TYPE.TICKER ? (
    <LinkTicker organizationId={Number(id)}>{value}</LinkTicker>
  ) : (
    <LinkSector icbId={Number(id)}>
      <TextEllipsis isI18n={false} text={value} />
    </LinkSector>
  )
}

export default NameCell
