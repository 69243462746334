import FisheryProxy from '../../../proxies/sector/sectorSpecific/FisheryProxy'
import { ServiceBase } from '../../ServiceBase'

class FisheryService extends ServiceBase {
  getFisheryProfile(params) {
    return this.getData(() => FisheryProxy.getFisheryProfile(params))
  }

  getReports(params) {
    return this.getData(() => FisheryProxy.getReports(params))
  }

  getNews(params) {
    return this.getData(() => FisheryProxy.getNews(params))
  }

  getOverviewIds(params) {
    return this.getData(() => FisheryProxy.getOverviewIds(params))
  }

  getExportYear(params) {
    return this.getData(() => FisheryProxy.getExportYear(params))
  }

  getImportYear(params) {
    return this.getData(() => FisheryProxy.getImportYear(params))
  }

  getTopExportMarketsOfVN(params) {
    return this.getData(() => FisheryProxy.getTopExportMarketsOfVN(params))
  }

  getExportStatisticsByMarket(params) {
    return this.getData(() => FisheryProxy.getExportStatisticsByMarket(params))
  }

  getExportStatisticsByProduct(params) {
    return this.getData(() => FisheryProxy.getExportStatisticsByProduct(params))
  }

  getExportGrowthByProduct(params) {
    return this.getData(() => FisheryProxy.getExportGrowthByProduct(params))
  }

  getExportGrowthByMarket(params) {
    return this.getData(() => FisheryProxy.getExportGrowthByMarket(params))
  }

  getExportContributionByProduct(params) {
    return this.getData(() =>
      FisheryProxy.getExportContributionByProduct(params),
    )
  }

  getExportContributionByMarket(params) {
    return this.getData(() =>
      FisheryProxy.getExportContributionByMarket(params),
    )
  }

  getImportStatisticsByMarket(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportStatisticsByMarket',
      params,
    )(() => FisheryProxy.getImportStatisticsByMarket(params))
  }

  getImportStatisticsByProduct(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportStatisticsByProduct',
      params,
    )(() => FisheryProxy.getImportStatisticsByProduct(params))
  }

  getImportGrowthByProduct(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportGrowthByProduct',
      params,
    )(() => FisheryProxy.getImportGrowthByProduct(params))
  }

  getImportGrowthByMarket(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportGrowthByMarket',
      params,
    )(() => FisheryProxy.getImportGrowthByMarket(params))
  }

  getImportContributionByMarket(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportContributionByMarket',
      params,
    )(() => FisheryProxy.getImportContributionByMarket(params))
  }

  getImportContributionByProduct(params) {
    return this.applyMemoryCache(
      'FisheryService.getImportContributionByProduct',
      params,
    )(() => FisheryProxy.getImportContributionByProduct(params))
  }

  getExportDownloadStatisticsByMarket(params) {
    return this.getFileDownload(() =>
      FisheryProxy.getExportDownloadStatisticsByMarket(params),
    )
  }

  getExportDownloadStatisticsByProduct(params) {
    return this.getFileDownload(() =>
      FisheryProxy.getExportDownloadStatisticsByProduct(params),
    )
  }

  getImportDownloadStatisticsByMarket(params) {
    return this.getFileDownload(() =>
      FisheryProxy.getImportDownloadStatisticsByMarket(params),
    )
  }

  getImportDownloadStatisticsByProduct(params) {
    return this.getFileDownload(() =>
      FisheryProxy.getImportDownloadStatisticsByProduct(params),
    )
  }

  getExporterStatistics(params) {
    return this.getData(() => FisheryProxy.getExporterStatistics(params))
  }

  getExporterGrowth(params) {
    return this.getData(() => FisheryProxy.getExporterGrowth(params))
  }

  getTopExporters(params) {
    return this.getData(() => FisheryProxy.getTopExporters(params))
  }

  getMaxDate(params) {
    return this.getData(() => FisheryProxy.getMaxDate(params))
  }

  getExporterDownloadStatistics(params) {
    return this.getFileDownload(() =>
      FisheryProxy.getExporterDownloadStatistics(params),
    )
  }
}

export default new FisheryService()
