import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useGetPackageFunctions from '../../common/masterData/useGetPackageFunctions'
import { highlightString } from '../../utils/Search'
import style from './index.module.css'

export const FuncFunctionsItem = ({ searchStr, data }) => {
  const { functionsIdUrl } = useGetPackageFunctions()

  const getFunctionName = () => {
    let functionName = ''
    let functionNameHighlight
    if (data.functionName) {
      functionName = data.functionName
      functionNameHighlight = data.highlight.functionName
    } else if (data.en_FunctionName) {
      functionName = data.en_FunctionName
      functionNameHighlight = data.highlight.en_FunctionName
    } else {
      return
    }
    return highlightString(functionName, functionNameHighlight, searchStr)
  }

  const getDescription = () => {
    let description = ''
    let descriptionHighlight
    if (data.description) {
      description = data.description
      descriptionHighlight = data.highlight.description
    } else if (data.en_Description) {
      description = data.en_Description
      descriptionHighlight = data.highlight.en_Description
    } else {
      return
    }
    return highlightString(description, descriptionHighlight, searchStr)
  }

  return (
    <Link
      className={style['item-link']}
      to={`${functionsIdUrl[data.functionId] || ''}?functionId=${
        data.functionId
      }`}
    >
      <div className={`search-content ${style['search-content']}`}>
        <div className={style['item-title']}>{getFunctionName()}</div>
        <div className="date">{getDescription()}</div>
      </div>
    </Link>
  )
}

FuncFunctionsItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
