import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { HorizontalStackedBarSeries } from '../../../../common/chart/customCharts/horizontalStackedBarSeries'
import { Footer } from '../../../../common/chart/footer'
import { getTextWidth } from '../../../../common/chart/helper'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { PANEL_PADDING } from '../../../../constants/Common'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal, valDivThousand } from '../../../../utils/Value'
import { listIconBarChart } from './constants'
import { selectDataChart } from './store/slice'

const SETTINGS = {
  padding: 30,
  marginBottom: 40,
  tickNumber: 5,
  fontSize: 12,
}

const fillColor = {
  buMatchVolume: '#3da967',
  sdMatchVolume: '#e0505b',
  unMatchVolume: '#32a6e8',
}

const HorizontalStackBarComponent = ({ width, height }) => {
  const fontSize = getFontSize(SETTINGS.fontSize)
  const dataBarChart = useSelector(selectDataChart)

  const barKeys = ['buMatchVolume', 'sdMatchVolume', 'unMatchVolume']

  const maxLeftLabelWidth =
    Math.max(
      ...dataBarChart.map((item) =>
        item.priceFrom !== item.priceTo
          ? getTextWidth(`${item.priceFrom} - ${item.priceTo}`, fontSize)
          : getTextWidth(item.priceFrom, fontSize),
      ),
    ) + SETTINGS.padding

  const getTooltipContentData = () => [
    {
      label: 'market.coveredWarrant.timeAndSales.BU_VOLUME',
      keyValue: 'buMatchVolume',
      formatValue: (val) =>
        formatVal(valDivThousand(val)) +
        ` ${I18n.t('market.coveredWarrant.timeAndSales.BN')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
    {
      label: 'market.coveredWarrant.timeAndSales.SD_VOLUME',
      keyValue: 'sdMatchVolume',
      formatValue: (val) =>
        formatVal(valDivThousand(val)) +
        ` ${I18n.t('market.coveredWarrant.timeAndSales.BN')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
    {
      label: 'market.coveredWarrant.timeAndSales.UNIDENTIFIED',
      keyValue: 'unMatchVolume',
      formatValue: (val) =>
        formatVal(valDivThousand(val)) +
        ` ${I18n.t('market.coveredWarrant.timeAndSales.BN')}`,
      fontSize: 11,
      fontWeightValue: 500,
    },
  ]

  return (
    <div id="coveredWarrantTimeAndSalesChart" className="h-100">
      <SizeTracker>
        {(size) => {
          return (
            <>
              {dataBarChart.length ? (
                <>
                  {size.height && (
                    <div style={{ height: `calc(100% - ${size.height}px)` }}>
                      <HorizontalStackedBarSeries
                        data={dataBarChart}
                        width={width - PANEL_PADDING * 2}
                        height={height - size.height}
                        yAxisCompareKey="referencePrice"
                        yAxisMaxKey="priceTo"
                        yAxisMinKey="priceFrom"
                        barKeys={barKeys}
                        margin={{
                          left: maxLeftLabelWidth,
                          bottom: SETTINGS.marginBottom,
                        }}
                        isShowTotal={true}
                        isFillColorYAxis={true}
                        tooltipContentData={getTooltipContentData()}
                        fillColor={fillColor}
                        tickFormat={(val) => formatVal(valDivThousand(val))}
                        formatYAxisLabel={(val) =>
                          formatVal(valDivThousand(val))
                        }
                        formatTotal={(val) => formatVal(valDivThousand(val))}
                      />
                    </div>
                  )}
                  <div>
                    <Footer key={width} list={listIconBarChart} />
                  </div>
                </>
              ) : (
                <NoData />
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default HorizontalStackBarComponent
