import { useSelector } from 'react-redux'
import { OverviewStatistics } from '.'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { TABLE_TYPE } from './constants'
import { selectFilter } from './store/slice'
import {
  downloadForeignDetail,
  downloadForeignOverview,
  downloadForeignTicker,
} from './store/thunk'

export const PanelOverviewStatistics = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const timeZone = UseTimeZone()

  const filterData = useSelector(selectFilter)

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  const getDownloadCsv = () => {
    if (filterData?.derivativeId) {
      return downloadForeignTicker({
        FutureId: filterData?.derivativeId,
        From: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        To: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
      })
    } else if (filterData?.tableType === TABLE_TYPE.OVERVIEW) {
      return downloadForeignOverview({
        From: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        To: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
      })
    } else {
      return downloadForeignDetail({
        From: filterData?.dateFrom
          ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
          : firstDateOfCurrentYear,
        To: filterData?.dateTo
          ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
          : new Date(),
      })
    }
  }

  return (
    <Panel
      title="market.derivative.PRICE_DATA"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => getDownloadCsv()}
    >
      <OverviewStatistics
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
