import FinancialChartDashboardProxy from '../../proxies/financialChart/FinancialChartDashboardProxy'
import { ServiceBase } from '../ServiceBase'

class FinancialChartDashboardService extends ServiceBase {
  getDashboardById(params) {
    return this.getData(() =>
      FinancialChartDashboardProxy.getDashboardById(params),
    )
  }

  getDashboard(params) {
    return this.getData(() => FinancialChartDashboardProxy.getDashboard(params))
  }

  getSharedDashboard(params) {
    return this.getData(() =>
      FinancialChartDashboardProxy.getSharedDashboard(params),
    )
  }

  postDashboard(params) {
    return this.getData(() =>
      FinancialChartDashboardProxy.postDashboard(params),
    )
  }

  updateDashboard(params) {
    return this.getData(() =>
      FinancialChartDashboardProxy.updateDashboard(params),
    )
  }

  deleteDashboard(params) {
    return this.getData(() =>
      FinancialChartDashboardProxy.deleteDashboard(params),
    )
  }
}

export default new FinancialChartDashboardService()
