import { useMemo } from 'react'
import { Translate } from 'react-redux-i18n'
import Radio from '../../../../../common/html/Radio'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import { SelectSearchMultiple } from '../../../../../common/selectCustom/SelectSearchMultiple'
import { DispatchActionTabWithCalendar } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import TextEllipsis from '../../../../../common/textEllipsis'
import { TooltipInfo } from '../../../../../common/tooltipInfo'
import { MONTH_OPTIONS } from '../../../../../constants/Datetime'
import { FUND_STRUCTURE_OPTIONS, FUND_TYPE_OPTIONS } from '../../../constants'
import { VALUE_TYPES } from '../../constants'

export const Filter = ({
  fund,
  structure,
  type,
  valueType,
  month,
  year,
  timeRange,
  startDate,
  endDate,
  isFund,
  isStructure,
  isType,
  isValueType,
  isNote,
  isMonth,
  isYear,
  isTimeRange,
  fundOptions,
  timeRangeOptions,
  className,
  onChange,
}) => {
  // Get data
  const getYears = useMemo(() => {
    return new Array(new Date().getFullYear() - 2000 + 1)
      .fill(null)
      .map((_, index) => ({
        name: String(new Date().getFullYear() - index),
        value: new Date().getFullYear() - index,
      }))
  }, [])

  const getMonths = useMemo(() => {
    return MONTH_OPTIONS
  }, [])

  const getOptionsBySearch = ({ search, options }) => {
    return options.filter(
      (item) =>
        item.organizationShortName
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        item.name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  // Actions
  const handleChange = (value) => {
    onChange && onChange(value)
  }

  const handleChangeTimeRange = (item) => {
    onChange && onChange({ timeRange: item.value })
  }

  const handleChangeCalendar = (payload) => {
    onChange &&
      onChange({
        timeRange: payload.activeTab,
        startDate: payload.startDate,
        endDate: payload.endDate,
      })
  }

  return (
    <div
      className={[
        'd-flex justify-content-space-between ali-center',
        className,
      ].join(' ')}
    >
      <div className="d-flex ali-center">
        {isFund && (
          <div className="mr-16">
            <SelectSearchMultiple
              values={fund}
              width={220}
              options={fundOptions}
              optionsBySearch={getOptionsBySearch}
              isSearch
              isDisableUnSelect={fund.length >= 10}
              renderOptionName={(item) => (
                <div className="w-100 d-flex ali-center">
                  <div className="mr-16" style={{ width: '20%' }}>
                    <TextEllipsis
                      text={item.name}
                      zIndexTooltip={999}
                      isI18n={false}
                    />
                  </div>
                  <div style={{ width: '60%' }}>
                    <TextEllipsis
                      text={item.organizationShortName}
                      zIndexTooltip={999}
                      isI18n={false}
                    />
                  </div>
                </div>
              )}
              onChange={(value) => handleChange({ fund: value })}
            />
          </div>
        )}
        {isStructure && (
          <div className="d-flex ali-center mr-16">
            <Span className="mr-8">
              <Translate value="fund.fundCenter.overview.performance.FUND_STRUCTURE" />
            </Span>
            <SelectSearchMultiple
              values={structure}
              width={150}
              isOptionAll
              isI18n
              options={FUND_STRUCTURE_OPTIONS}
              onChange={(value) => handleChange({ structure: value })}
            />
          </div>
        )}
        {isType && (
          <div className="d-flex ali-center mr-16">
            <Span className="mr-8">
              <Translate value="fund.fundCenter.overview.performance.FUND_TYPE" />
            </Span>
            <SelectSearchMultiple
              values={type}
              width={170}
              isOptionAll
              isI18n
              options={FUND_TYPE_OPTIONS}
              onChange={(value) => handleChange({ type: value })}
            />
          </div>
        )}
        {isValueType && (
          <Radio
            data={Object.keys(VALUE_TYPES).map((key) => ({
              title: 'fund.fundProfile.navData.' + key,
              value: VALUE_TYPES[key],
            }))}
            value={valueType}
            onChange={(value) => handleChange({ valueType: value })}
            containerClass="w-auto d-flex mr-16"
          />
        )}
        {isNote && <TooltipInfo tooltip="fund.fundCenter.FUND_FLOW_NOTE" />}
      </div>
      <div className="d-flex ali-center">
        {isMonth && (
          <div style={{ width: 85 }} className="ml-8">
            <SelectCustom
              value={month}
              selectData={getMonths}
              handleChange={(value) => handleChange({ month: value })}
              isI18n
            />
          </div>
        )}
        {isYear && (
          <div style={{ width: 85 }} className="ml-8">
            <SelectCustom
              value={year}
              selectData={getYears}
              handleChange={(value) => handleChange({ year: value })}
              isI18n
            />
          </div>
        )}
        {isTimeRange && (
          <div className="ml-8">
            <DispatchActionTabWithCalendar
              id="overviewTopLiquidity"
              tabs={timeRangeOptions}
              activeTab={timeRange}
              startDate={startDate}
              endDate={endDate}
              datePreset={timeRangeOptions}
              onChangeTab={handleChangeTimeRange}
              onChangeCalendar={handleChangeCalendar}
            />
          </div>
        )}
      </div>
    </div>
  )
}
