import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { MarketCap } from './index'

const PanelMarketCap = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const nameScreen = UseI18n('Sector Statistics')
  const chartName = UseI18n('sector.sectorStatistics.overview.MARKET_CAP')

  return (
    <Panel
      title={'sector.sectorStatistics.overview.MARKET_CAP'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: 'marketCapId',
        nameScreen,
        chartName,
      }}
    >
      <MarketCap
        width={formatNumber(sizes[panelKey].width)}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelMarketCap
