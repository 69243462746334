export const YEAR_TIME_FRAME_OPTIONS = [
  { title: '5Y', value: '5Y', num: 5 },
  { title: '7Y', value: '7Y', num: 7 },
  { title: '10Y', value: '10Y', num: 10 },
  { title: '20Y', value: '20Y', num: 20 },
  { title: 'economy.gdp.gdp.ALL', value: 'All', num: 0 },
]

export const QUARTERLY_TIME_FRAME_OPTIONS = [
  { title: '1Y', value: '1Y', num: 1 },
  { title: '3Y', value: '3Y', num: 3 },
  { title: '5Y', value: '5Y', num: 5 },
  { title: '10Y', value: '10Y', num: 10 },
  { title: 'economy.gdp.gdp.ALL', value: 'All', num: 0 },
]

export const QUARTERLY = [1, 2, 3, 4]
