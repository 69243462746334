import PropTypes from 'prop-types'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../utils/FontSize'
import { formatVal } from '../../../../utils/Value'
import { formatChartData } from './helper'
import style from './index.module.css'

const MAX_HEIGHT_CHART = 500

const PieChartVerticalFooter = ({ data, height, width }) => {
  const chartData = formatChartData(data)
  const chartHeight = Math.min(height, MAX_HEIGHT_CHART)
  const fontSize = getFontSize(11)

  const renderTooltip = (chartData) => {
    return (
      <div className={style.tooltipMaxWidth}>
        {chartData.map((item) => {
          return (
            <div
              key={item.text}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TextEllipsisStaticLine
                appendStyle={{ fontSize, fontWeight: 400 }}
                lineClamp={2}
                isI18n={false}
                val={`${item.text}:`}
              />
              <Span
                style={{ marginLeft: 10, fontSize: 11 }}
                className={style.toolTipContentValue}
              >
                {formatVal(item.value * 100)}%
              </Span>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <PieChartWithFooter
      data={chartData}
      height={chartHeight}
      width={width}
      renderTooltip={renderTooltip}
      footerRight
      footerProps={{
        isOneColumn: true,
        appendStyle: { gridGap: 8 },
        itemStyle: { paddingRight: 8 },
      }}
    />
  )
}

PieChartVerticalFooter.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
}

export default PieChartVerticalFooter
