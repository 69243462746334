import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import NewsEventsService from '../../../../core/services/corporate/NewsEventsService'
import WatchlistService from '../../../../core/services/watchlist/WatchlistService'
import { handleExport } from '../../../utils/Export'

export const getFiinXEventTypesThunk = createAsyncThunk(
  'market/events/GET_FIINX_EVENT_TYPES',
  async (params, { rejectWithValue }) => {
    const response = await NewsEventsService.getFiinXEventTypes(params)
    if (response.success) return response.data
    return rejectWithValue('Error')
  },
)

export const getEventsThunk = createAsyncThunk(
  'market/events/GET_EVENTS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await NewsEventsService.getEvents(params)
      if (response.success) return response
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getEventCountThunk = createAsyncThunk(
  'market/events/GET_EVENT_COUNT',
  async (params, { rejectWithValue }) => {
    try {
      const response = await NewsEventsService.getEventCount(params)
      if (response.success) return response
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getEventByIdThunk = createAsyncThunk(
  'market/events/GET_EVENT_BY_ID',
  async (params, { rejectWithValue }) => {
    const response = await NewsEventsService.getEventById(params)
    if (response.success) return response.data
    return rejectWithValue('Error')
  },
)

export const getWatchListThunk = createAsyncThunk(
  'market/events/GET_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlist(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadEvents = createAsyncThunk(
  'market/events/DOWNLOAD_EVENTS',
  async (data) => {
    const response = await NewsEventsService.downloadEvents(data)
    handleExport(response.data, response.filename)
  },
)
