import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { DURATION_YTM_TABS, VALUE_CHART_TABS } from '../constants'
import style from '../index.module.css'
import { DATE_TABS_VALUE_CHART } from '../tradingStatistics/constants'
import {
  changeFilterValueChart,
  selectFilterPriceBoard,
  selectFilterValueChart,
  selectidsCompany,
} from '../tradingStatistics/store/slice'
import { getValueChartData } from '../tradingStatistics/store/thunk'

const Filter = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const OrganizationIds = useSelector(selectidsCompany)
  const { range, type, From, To } = useSelector(selectFilterValueChart)
  const locale = useSelector((state) => state.i18n.locale)
  const filter = useSelector(selectFilterPriceBoard)
  const { tradingStatusId, issueMethodId, icbIds } = filter

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilterValueChart({
        value: item.value,
        key: 'range',
      }),
    )
  }

  const handleChangeDuration = (item) => {
    dispatch(
      changeFilterValueChart({
        value: item.value,
        key: 'type',
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilterValueChart({
        key: 'range',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilterValueChart({
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilterValueChart({
        key: 'To',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload =
      range === calendarTabKey
        ? {
            From: getISOStartOrEndOfDay(From, timeZone, true),
            To: getISOStartOrEndOfDay(To, timeZone, true),
            TradingStatusIds: tradingStatusId.toString(),
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Top: 5,
            OrganizationIds: OrganizationIds,
          }
        : {
            TimeRange: range,
            TradingStatusIds: tradingStatusId.toString(),
            IssueMethodIds: issueMethodId.toString(),
            IcbIds: icbIds.toString(),
            Top: 5,
            OrganizationIds: OrganizationIds,
          }

    dispatch(getValueChartData(payload))
  }, [
    locale,
    range,
    tradingStatusId.length,
    issueMethodId.length,
    icbIds.length,
    From,
    To,
    OrganizationIds,
  ])

  return (
    <div className="d-flex justify-content-space-between mb-8">
      <div className={`${style.menuContainer}`}>
        <DispatchActionTab
          data={DURATION_YTM_TABS}
          activeTab={type}
          onChangeTab={handleChangeDuration}
          containerStyle={{ width: '100%' }}
          itemStyle={{
            width: '50%',
            fontWeight: 600,
          }}
        />
      </div>

      <DispatchActionTabWithCalendar
        id="valueChart"
        tabs={VALUE_CHART_TABS}
        activeTab={range}
        startDate={From}
        endDate={To}
        datePreset={DATE_TABS_VALUE_CHART}
        excludeDateIntervals={[]}
        onChangeTab={handleChangeTimeType}
        onChangeCalendar={handleChangeCalendar}
      />
    </div>
  )
}

export default Filter
