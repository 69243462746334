import { useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { ImportGrowth } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'

export const PanelImportGrowth = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const nameScreen = I18n.t('sector.sectorSpecific.fishery.FISHERY')
  const chartName = 'sector.sectorSpecific.fishery.export.GROWTH_PRODUCT'
  const tabName = I18n.t('sector.sectorSpecific.fishery.EXPORT_BY_PRODUCT')

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title={chartName}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={I18n.t(chartName)}
      downloadJpgParams={{
        domId: 'importGrowthChart',
        nameScreen: nameScreen,
        chartName: I18n.t(chartName),
        tabName: tabName,
      }}
      handleCustom={setIsFullScreen}
    >
      <ImportGrowth
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}
