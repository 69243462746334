import { InvestmentStrategy } from '.'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelInvestmentStrategy = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const titlePanel = `fund.fundProfile.profile.${panelKey}`

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <InvestmentStrategy
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
