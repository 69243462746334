import moment from 'moment'
import { keyBy } from '../../../../utils/Common'
import { VIEW_TYPE } from './constants'

const formatIndicatorItem = (indicator, parent = { level: 0 }) => {
  const childrenId = indicator.children
    ? Object.values(indicator.children).map((v) => v.indicatorId)
    : []
  const hasChild = childrenId.length > 0
  const childTable = {
    indicatorId: indicator.indicatorId,
    parentIndicatorId: parent.indicatorId || indicator.parentIndicatorId,
    childrenId: childrenId,
    indicatorGroupId: indicator.indicatorGroupId,
    indicatorName: indicator.indicatorName,
    tableFieldCode: indicator.tableFieldCode,
    orderNumber: indicator.orderNumber,
    multiplier: indicator.multiplier,
    unit: indicator.unit,
    level: parent.level + 1,
    isCollapse: hasChild,
  }
  const childItem = hasChild
    ? getChild(childTable, Object.values(indicator.children))
    : []
  return [childTable, ...childItem]
}

const getChild = (parent, children) => {
  let childTableArr = []
  children.forEach((indicator) => {
    const child = formatIndicatorItem(indicator, parent)
    childTableArr = [...childTableArr, ...child]
  })
  return childTableArr
}

export const formatIndicatorMapping = (indicators) => {
  let ids = []
  let tableConstants = []
  Object.values(indicators).forEach((indicator) => {
    const child = formatIndicatorItem(indicator)
    ids = [...ids, ...child.map((v) => v.indicatorId)]
    tableConstants = [...tableConstants, ...child]
  })
  return { ids, tableConstants }
}

export const getDataTitle = (timeFilter, item) => {
  switch (timeFilter) {
    case VIEW_TYPE.YEAR:
      return item.isTTM
        ? `Q${item.realQuarter}/${item.realYear}(TTM)`
        : item.realYear
    case VIEW_TYPE.QUARTER:
      return `Q${item.realQuarter}/${item.realYear}`
    default:
      return item.realYear
  }
}

export const formatFinancialData = (tableConstants, action) => {
  const groupColumns = [{ key: 'indicatorName', title: '' }]
  const valueArr = tableConstants.map((item) => ({ ...item }))
  const timeFilter = action.meta.arg.TimeFrequency
  const period = Number(action.meta.arg.NumberOfPeriod)
  const quarter = VIEW_TYPE.QUARTER
  const tempObj = {}

  action.payload.forEach((item) =>
    tempObj[`${item.realQuarter}-${item.realYear}-${item.isTTM}`]
      ? (tempObj[`${item.realQuarter}-${item.realYear}-${item.isTTM}`][
          item.indicator
        ] = item.value)
      : (tempObj[`${item.realQuarter}-${item.realYear}-${item.isTTM}`] = {
          groupId: item.groupId,
          realYear: item.realYear,
          realQuarter: item.realQuarter,
          isTTM: item.isTTM,
          [item.indicator]: item.value,
        }),
  )

  const sortData = Object.values(tempObj)
    .sort((a, b) => {
      if (a.realQuarter && b.realQuarter) {
        return (
          moment().set('year', b.realYear).quarter(b.realQuarter).unix() -
          moment().set('year', a.realYear).quarter(a.realQuarter).unix()
        )
      } else {
        return (
          moment().set('year', b.realYear).unix() -
          moment().set('year', a.realYear).unix()
        )
      }
    })
    .filter((_, index) => index < period)
    .reverse()
  const ttmData = sortData.filter((item) => item.isTTM)
  const otherData = sortData.filter((item) => !item.isTTM)
  ;[...otherData, ...ttmData].forEach((item) => {
    const key = item.isTTM
      ? 'columnTTM'
      : timeFilter === quarter
      ? `columnQ${item.realQuarter}/${item.realYear}`
      : `column${item.realYear}`

    groupColumns.push({
      key: key,
      title: getDataTitle(timeFilter, item),
    })

    valueArr.forEach((element) => {
      element[key] = element.tableFieldCode ? item[element.tableFieldCode] : ''
    })
  })

  return { groupColumns, valuesById: keyBy(valueArr, 'indicatorId') }
}
