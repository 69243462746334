import { useSelector } from 'react-redux'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { selectDataByKey } from '../../store/slice'

export const TickerTooltip = ({ value, rowId }) => {
  // Use selector
  const dataByKeys = useSelector(selectDataByKey)

  return (
    <LinkTicker organizationId={dataByKeys[rowId].organizationId}>
      {value}
    </LinkTicker>
  )
}
