import { TRANSACTION_TYPES } from '../../constants'

export const priceDataTableCol = {
  overview: {
    DATE: 'tradingDateId',
    TOTAL_BUY_VOL: 'totalBuyVolume',
    TOTAL_SELL_VOL: 'totalSellVolume',
    TOTAL_BUY_VAL: 'totalBuyValue',
    TOTAL_SELL_VAL: 'totalSellValue',
    NET_TOTAL_VOL: 'netTotalVolume',
    NET_TOTAL_VAL: 'netTotalValue',
    MATCH_BUY_VOL: 'totalMatchBuyVolume',
    MATCH_SELL_VOL: 'totalMatchSellVolume',
    MATCH_BUY_VAL: 'totalMatchBuyValue',
    MATCH_SELL_VAL: 'totalMatchSellValue',
    MATCH_NET_VOL: 'netTotalMatchVolume',
    MATCH_NET_VAL: 'netTotalMatchValue',
    DEAL_BUY_VOL: 'totalDealBuyVolume',
    DEAL_SELL_VOL: 'totalDealSellVolume',
    DEAL_BUY_VAL: 'totalDealBuyValue',
    DEAL_SELL_VAL: 'totalDealSellValue',
    DEAL_NET_VOL: 'netTotalDealVolume',
    DEAL_NET_VAL: 'netTotalDealValue',
  },
  detail: {
    DATE: 'tradingDateId',
    TICKER: 'ticker',
    TOTAL_BUY_VOL: 'totalBuyVolume',
    TOTAL_SELL_VOL: 'totalSellVolume',
    TOTAL_BUY_VAL: 'totalBuyValue',
    TOTAL_SELL_VAL: 'totalSellValue',
    NET_TOTAL_VOL: 'netTotalVolume',
    NET_TOTAL_VAL: 'netTotalValue',
    MATCH_BUY_VOL: 'totalMatchBuyVolume',
    MATCH_SELL_VOL: 'totalMatchSellVolume',
    MATCH_BUY_VAL: 'totalMatchBuyValue',
    MATCH_SELL_VAL: 'totalMatchSellValue',
    MATCH_NET_VOL: 'netTotalMatchVolume',
    MATCH_NET_VAL: 'netTotalMatchValue',
    DEAL_BUY_VOL: 'totalDealBuyVolume',
    DEAL_SELL_VOL: 'totalDealSellVolume',
    DEAL_BUY_VAL: 'totalDealBuyValue',
    DEAL_SELL_VAL: 'totalDealSellValue',
    DEAL_NET_VOL: 'netTotalDealVolume',
    DEAL_NET_VAL: 'netTotalDealValue',
  },
}

export const tableFormatValue = {
  totalBuyVolume: 10 ** 3,
  totalSellVolume: 10 ** 3,
  totalBuyValue: 10 ** 6,
  totalSellValue: 10 ** 6,
  netTotalVolume: 10 ** 3,
  netTotalValue: 10 ** 6,
  totalMatchBuyVolume: 10 ** 3,
  totalMatchSellVolume: 10 ** 3,
  totalMatchBuyValue: 10 ** 6,
  totalMatchSellValue: 10 ** 6,
  totalDealBuyVolume: 10 ** 3,
  totalDealSellVolume: 10 ** 3,
  totalDealBuyValue: 10 ** 6,
  totalDealSellValue: 10 ** 6,
  netTotalMatchVolume: 10 ** 3,
  netTotalMatchValue: 10 ** 6,
  netTotalDealVolume: 10 ** 3,
  netTotalDealValue: 10 ** 6,
}

export const tableColByTransactionType = {
  [TRANSACTION_TYPES.ALL]: [
    'DATE',
    'TICKER',
    'TOTAL_BUY_VOL',
    'TOTAL_SELL_VOL',
    'TOTAL_BUY_VAL',
    'TOTAL_SELL_VAL',
    'NET_TOTAL_VOL',
    'NET_TOTAL_VAL',
    'MATCH_BUY_VOL',
    'MATCH_SELL_VOL',
    'MATCH_BUY_VAL',
    'MATCH_SELL_VAL',
    'MATCH_NET_VOL',
    'MATCH_NET_VAL',
    'DEAL_BUY_VOL',
    'DEAL_SELL_VOL',
    'DEAL_BUY_VAL',
    'DEAL_SELL_VAL',
    'DEAL_NET_VOL',
    'DEAL_NET_VAL',
  ],
  [TRANSACTION_TYPES.DEAL]: [
    'DATE',
    'TICKER',
    'DEAL_BUY_VOL',
    'DEAL_SELL_VOL',
    'DEAL_BUY_VAL',
    'DEAL_SELL_VAL',
    'DEAL_NET_VOL',
    'DEAL_NET_VAL',
  ],
  [TRANSACTION_TYPES.MATCH]: [
    'DATE',
    'TICKER',
    'MATCH_BUY_VOL',
    'MATCH_SELL_VOL',
    'MATCH_BUY_VAL',
    'MATCH_SELL_VAL',
    'MATCH_NET_VOL',
    'MATCH_NET_VAL',
  ],
}
