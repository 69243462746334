import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../../configs/Layout'
import { withWrapper } from '../../../../../common/withWrapper'
import { PanelDataTable } from './dataTable/PanelDataTable'
import { PanelInDepthComparison } from './inDepthComparison/PanelInDepthComparison'
import { PanelLoanStructure } from './loanStructure/PanelLoanStructure'
import { PanelNplCoverage } from './nplCoverage/PanelNplCoverage'
import { PanelNplSml } from './nplSml/PanelNplSml'
import { PanelValueBondPortfolios } from './valueBondPortfolios/PanelValueBondPortfolios'

const MAP_KEY = {
  DATA_TABLE: 'DATA_TABLE',
  LOAN_STRUCTURE: 'LOAN_STRUCTURE',
  IN_DEPTH_COMPARISON: 'IN_DEPTH_COMPARISON',
  VALUE_BOND_PORTFOLIOS: 'VALUE_BOND_PORTFOLIOS',
  NPL_SML: 'NPL_SML',
  NPL_COVERAGE: 'NPL_COVERAGE',
}

const MAP_SIZE = {
  [`${MAP_KEY.DATA_TABLE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.LOAN_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.LOAN_STRUCTURE,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.IN_DEPTH_COMPARISON,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.VALUE_BOND_PORTFOLIOS,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.NPL_SML,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.NPL_COVERAGE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.LOAN_STRUCTURE}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.IN_DEPTH_COMPARISON,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.IN_DEPTH_COMPARISON}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUE_BOND_PORTFOLIOS}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NPL_SML,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NPL_SML}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.NPL_COVERAGE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NPL_COVERAGE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const AssetQualityContent = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelDataTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelLoanStructure
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelInDepthComparison
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelValueBondPortfolios
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelNplSml
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelNplCoverage
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

AssetQualityContent.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(AssetQualityContent)
