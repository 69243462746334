import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../common/textEllipsis/TextEllipsisStaticLine'
import { typeTreeItem } from '../constants'
import ChartItem from './ChartItem'
import {
  selectChartReplaceChecked,
  selectDashboardSelectedPopup,
  setChartReplaceChecked,
  setDashBoardSelected,
  setDashBoardSelectedDetail,
} from './store/slice'
import style from './style.module.css'

const DashboardItem = ({
  dashboard,
  dashboardClass,
  childrenStyle,
  iconCollapse,
  iconExpand,
  chartClass,
  selectMultipleItems,
  isShowChart,
  isChartLibrary,
}) => {
  const dispatch = useDispatch()

  const [collapseOpen, setCollapseOpen] = useState(true)

  const dashboardSelected = useSelector(selectDashboardSelectedPopup)
  const chartReplaceChecked = useSelector(selectChartReplaceChecked)

  const handleCollapse = () => {
    if (isChartLibrary) {
      dispatch(setChartReplaceChecked(dashboard))
    } else if (isShowChart) {
      setCollapseOpen(!collapseOpen)
    } else {
      dispatch(setDashBoardSelected(dashboard.id))
      dispatch(setDashBoardSelectedDetail(dashboard))
    }
  }

  const getChildrenStyle = (val) => {
    if (val) {
      return {
        backgroundColor: '#afdff9',
        borderRadius: 'unset',
      }
    }
  }

  return (
    <div key={dashboard.id}>
      <div
        className={`${style.groupDashBoard} ${dashboardClass}`}
        style={
          Object.values(chartReplaceChecked).length
            ? getChildrenStyle(dashboard.id === chartReplaceChecked?.id)
            : !isShowChart
            ? childrenStyle(dashboard.id === dashboardSelected)
            : {}
        }
        onClick={handleCollapse}
      >
        <div className="align-center">
          {dashboard.children && isShowChart ? (
            <>
              {collapseOpen ? (
                <i
                  className={`${iconExpand || 'icon-caret-down'} ${
                    style.iconCollapse
                  }`}
                  style={{ fontSize: 8, color: '#b0b7bb' }}
                />
              ) : (
                <i
                  className={`${iconCollapse || 'icon-caret-right'} ${
                    style.iconCollapse
                  }`}
                  style={{ fontSize: 8, color: '#b0b7bb' }}
                />
              )}
            </>
          ) : (
            <div style={{ width: 0 }} className={style.iconCollapse} />
          )}

          <TextEllipsisStaticLine
            val={dashboard.name}
            lineClamp={1}
            isI18n={false}
          />
        </div>
      </div>
      {collapseOpen &&
        dashboard.children &&
        dashboard.children.map((children) => (
          <ChartItem
            key={typeTreeItem.CHART + children.id}
            children={children}
            childrenClass={chartClass}
            childrenStyle={childrenStyle}
            iconCollapse={iconCollapse}
            iconExpand={iconExpand}
            selectMultipleItems={selectMultipleItems}
          />
        ))}
    </div>
  )
}

export default DashboardItem
