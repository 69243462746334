import { Translate } from 'react-redux-i18n'
import { DangerouslySetInnerHTML } from '../../../../common/html/DangerouslySetInnerHTML'
import { TABLE_KEYS } from './constants'

export const getTableSchema = ({ dataById }) => {
  return ['value'].map((key) => {
    return {
      colId: key,
      isI18n: false,
      canCustomTd: true,
      render: (value, rowId, { style, className }) => (
        <td
          className={className}
          style={
            dataById[rowId]?.isTitle
              ? { ...style, backgroundColor: '#273040', fontWeight: 600 }
              : { ...style, paddingLeft: 24 }
          }
        >
          <div style={{ textAlign: 'left' }}>
            {dataById[rowId]?.isTitle ? (
              <Translate value={value} />
            ) : (
              <DangerouslySetInnerHTML html={value} />
            )}
          </div>
        </td>
      ),
    }
  })
}

export const formatApiData = (data) => {
  if (!data || !data.length) return []
  return Object.keys(TABLE_KEYS).reduce(
    (arr, key, index) => [
      ...arr,
      {
        id: index * 2,
        value: 'fund.fundProfile.profile.investmentStrategy.' + key,
        isTitle: true,
      },
      {
        id: index * 2 + 1,
        value: data[0][TABLE_KEYS[key]],
      },
    ],
    [],
  )
}
