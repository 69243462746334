import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { COMPONENT } from '../../../configs/Layout'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import Content from './Content'
import style from './index.module.css'
import { resetStore } from './store/slice'
import WorkSpace from './WorkSpace'

export const SmartScreening = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <div
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <div className={`h-100 ${style.minWidthContainer}`}>
          <WorkSpace />
          <Content />
        </div>
      </ScrollGroupComponent>
    </div>
  )
}
