import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../../../../common/HandleClickOutside'
import Dropdown from '../../../../../../../common/dropdown'
import UseI18n from '../../../../../../../common/hooks/useI18n'
import { Input } from '../../../../../../../common/html/Input'
import { Loading } from '../../../../../../../common/loading'
import useGetICB from '../../../../../../../common/masterData/useGetICB'
import Tree from '../../../../../../../common/tree'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../../../../../utils/EventEmitter'
import {
  changeFirstMountPopup,
  changeListParentChecked,
  changeSectorChecked,
  selectFirstMountPopup,
  selectListParentChecked,
  selectListSectorChecked,
} from '../store/slice'
import NameChecked from './NameChecked'
import style from './index.module.css'

const SelectSector = () => {
  const parentRef = useRef()
  const dispatch = useDispatch()

  const { ICBsTree, ICBs, loading } = useGetICB(true, true)
  const listChecked = useSelector(selectListSectorChecked)
  const listParentChecked = useSelector(selectListParentChecked)
  const isFirstMount = useSelector(selectFirstMountPopup)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [valueSearch, setValueSearch] = useState('')

  const ICBsTreeMemo = useMemo(() => {
    return ICBsTree
  }, [JSON.stringify(ICBsTree)])

  useEffect(() => {
    return () => dispatch(changeFirstMountPopup(false))
  }, [])

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const setListChecked = (data) => {
    dispatch(changeSectorChecked(data))
  }

  const setListParentChecked = (data) => {
    dispatch(changeListParentChecked(data))
  }

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <div className={`input-dropdown h-20 ${style.selectSector}`}>
      <span
        ref={parentRef}
        onClick={handleDropdown}
        className="span-input w-100 cursor-pointer h-20"
        style={{
          padding: 0,
          borderBottomLeftRadius: isShowDropdown ? 0 : 4,
          borderBottomRightRadius: isShowDropdown ? 0 : 4,
        }}
      >
        <NameChecked
          ICBs={ICBs}
          icbTreeLength={Object.keys(ICBsTreeMemo).length}
          listParentChecked={listParentChecked}
        />{' '}
        <i className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`wrapper-tool`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0`}>
                  <Input
                    type="text"
                    className={`search-inbox bg-grey h-20 ${style.search}`}
                    placeholder={placeHolder}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
                <div className={style.treeContainer}>
                  {loading ? (
                    <Loading />
                  ) : (
                    <Tree
                      valueSearch={valueSearch}
                      treeData={ICBsTreeMemo}
                      idKey="icbId"
                      nameKey="icbName"
                      levelKey="icbLevel"
                      listChecked={listChecked}
                      setListChecked={setListChecked}
                      setListParentChecked={setListParentChecked}
                      isHaveCheckbox={true}
                      isHaveCheckboxAll={true}
                      isDefaultCheckAll={isFirstMount}
                      titleCheckboxAll="corporate.peerValuation.overview.ALL_SECTOR"
                      isActiveSelected={false}
                      isTextEllipsis
                      zIndexTextEllipsis={12}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default SelectSector
