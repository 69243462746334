import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import FinancialChartDataService from '../../../../core/services/financialChart/FinancialChartDataService'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import Popup from '../../../common/popup'
import {
  getEquationDataAggregateParams,
  getEquationDataCountParams,
  getTimeFrequencyByIndicator,
} from '../../common/helpers'
import style from '../styleTheme.module.css'
import DispatchActionTabEquation from './DispatchActionTabEquation'
import GroupButton from './GroupButton'
import IndicatorName from './IndicatorName'
import InputFormula from './InputFormula'
import { RadioUnit } from './RadioUnit'
import { equationIndicatorLimit, equationType } from './constants'
import ListIndicator from './listIndicator'
import ListSecurities from './listSecurities'
import {
  changeEquationDefaultState,
  changeEquationIndicatorName,
  reset,
  selectEquationIndicators,
  selectEquationType,
} from './store/slice'

const PopupAddEquation = ({
  viewType,
  equations,
  equation,
  setIsShow,
  onApply,
}) => {
  const dispatch = useDispatch()

  const equationTypeState = useSelector(selectEquationType)
  const equationIndicators = useSelector(selectEquationIndicators)

  const [loading, setLoading] = useState(false)
  const [isFormulaValid, setIsFormulaValid] = useState(false)
  const [messageError, setMessageError] = useState('')
  const [indicatorsLatestPeriod, setIndicatorsLatestPeriod] = useState({})

  const getMessageIndicatorsLimit = () => {
    const isShow =
      Object.keys(equationIndicators).length >= equationIndicatorLimit
    return isShow ? (
      <div className="mt-8">
        <Span
          style={{
            fontSize: 10,
            color: '#e0505b',
            fontStyle: 'italic',
          }}
        >
          <Translate value="financialChart.EQUATION_INDICATOR_LIMIT" />
        </Span>
      </div>
    ) : (
      ''
    )
  }

  const getIndicatorLatestPeriod = async (indicators) => {
    const promises = Object.values(indicators).map((item) =>
      FinancialChartDataService.getDataLatestPeriod({
        indicatorId: item.indicatorId,
        timeFrequency: item.timeFrequency,
      }),
    )

    const responses = await Promise.allSettled(promises)
    const results = {}

    responses.forEach((res, index) => {
      const item = Object.values(indicators)[index]
      if (res.status === 'fulfilled' && res.value.success) {
        results[`${item.indicatorId}_${getTimeFrequencyByIndicator(item)}`] =
          res.value.data
      }
    })

    return results
  }

  const handleApply = async (data) => {
    setLoading(true)
    const newEquationData = equation ? { ...equation, ...data } : data
    const indicatorsLatestPeriod = await getIndicatorLatestPeriod(
      newEquationData.equationIndicators,
    )
    let functionGetEquationDataParams = getEquationDataAggregateParams
    let thunkGetDataApi = (params) =>
      FinancialChartDataService.getDataAggregate(params)

    if (newEquationData.equationType === equationType.COUNT) {
      functionGetEquationDataParams = getEquationDataCountParams
      thunkGetDataApi = (params) =>
        FinancialChartDataService.getDataCount(params)
    }

    const params = functionGetEquationDataParams(
      viewType,
      newEquationData,
      indicatorsLatestPeriod,
    )

    // Check equation is valid before apply
    await thunkGetDataApi(params)
      .then(() => {
        onApply(newEquationData)
        setLoading(false)
        handleCancel()
      })
      .catch(() => setLoading(false))
  }

  const handleCancel = () => {
    dispatch(reset())
    setIsShow(false)
  }

  useEffect(() => {
    if (!equation) {
      dispatch(reset())
      const defaultEquationName =
        I18n.t('financialChart.EQUATION') +
        ' ' +
        (Object.keys(equations).length + 1)
      dispatch(changeEquationIndicatorName(defaultEquationName))
    }
  }, [])

  useEffect(() => {
    const asyncFn = async () => {
      if (equation) {
        dispatch(changeEquationDefaultState(equation))
        const result = await getIndicatorLatestPeriod(
          equation.equationIndicators,
        )
        setIndicatorsLatestPeriod(result)
      }
    }
    asyncFn()
  }, [equation])

  return (
    <Popup overlayCustom={style.overlayCustom}>
      <div
        className={`modal modal-medium ${style.modal}`}
        style={{ width: 800, pointerEvents: loading ? 'none' : 'auto' }}
      >
        {loading && <Loading isBlack />}
        <div className={`modal-title ${style.titleStyle}`}>
          <h3 className="text-up">
            <Translate value="financialChart.ADD_EQUATION" />
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" style={{ color: '#646464' }} />
          </a>
        </div>
        <div className={`modal-content ${style.modalContentStyle}`}>
          <DispatchActionTabEquation />
          <ListSecurities
            isEquationTypeCount={equationTypeState === equationType.COUNT}
            isCheckAll={!equation}
          />
          <ListIndicator
            viewType={viewType}
            isEquationTypeCount={equationTypeState === equationType.COUNT}
            indicatorsLatestPeriod={indicatorsLatestPeriod}
          />
          <IndicatorName />
          {equationTypeState !== equationType.COUNT && (
            <>
              <InputFormula setIsFormulaValid={setIsFormulaValid} />
              <RadioUnit />
              {getMessageIndicatorsLimit()}
            </>
          )}
          {messageError && (
            <div className="mt-8">
              <Span style={{ fontSize: 11, color: '#c48b09' }}>
                <Translate value={messageError} />
              </Span>
            </div>
          )}
          <GroupButton
            isEquationTypeCount={equationTypeState === equationType.COUNT}
            isFormulaValid={isFormulaValid}
            onCancel={handleCancel}
            onApply={handleApply}
            setMessageError={setMessageError}
          />
        </div>
      </div>
    </Popup>
  )
}

PopupAddEquation.propTypes = { setIsShow: PropTypes.func }

export default PopupAddEquation
