import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { DetailContainer } from '../../../common/news/DetailContainer'
import { Separator } from '../../../common/separator'
import { FEATURES } from '../../../constants/routes'
import EventEmitter, { CHANGE_LOCALE } from '../../../utils/EventEmitter'
import { LeftContent } from './left'
import { RightContent } from './right'

export const Content = ({ width, redirectRoute, routeForItem }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const redirect = () => {
      navigate(redirectRoute || FEATURES.market.components['news-report'].route)
    }
    EventEmitter.subscribe(CHANGE_LOCALE, redirect)
    return () => EventEmitter.unsubscribe(CHANGE_LOCALE, redirect)
  }, [])

  return (
    <DetailContainer width={width}>
      <LeftContent />
      <Separator />
      <RightContent routeForItem={routeForItem} />
    </DetailContainer>
  )
}

Content.propTypes = {
  width: PropTypes.number,
  redirectRoute: PropTypes.string,
}
