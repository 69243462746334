import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import BondIssueInformation from './bondIssueInformation'
import InterestAndPrincipalPaid from './interestAndPrincipalPaid'
import RelativeToEquity from './relativeToEquity'
import RemainingMaturities from './remainingMaturities'

const MAP_KEY = {
  BOND_ISSUE_INFORMATION: 'BOND_ISSUE_INFORMATION',
  RELATIVE_TO_EQUITY: 'RELATIVE_TO_EQUITY',
  REMAINING_MATURITIES: 'REMAINING_MATURITIES',
  INTEREST_AND_PRINCIPAL_PAID: 'INTEREST_AND_PRINCIPAL_PAID',
}

const MAP_SIZE = {
  [`${MAP_KEY.BOND_ISSUE_INFORMATION}`]: {
    width: `calc(100%)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    horizontalResize: [
      {
        key: MAP_KEY.RELATIVE_TO_EQUITY,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.REMAINING_MATURITIES,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.INTEREST_AND_PRINCIPAL_PAID,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.RELATIVE_TO_EQUITY}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.REMAINING_MATURITIES,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.REMAINING_MATURITIES}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    verticalResize: [
      {
        key: MAP_KEY.INTEREST_AND_PRINCIPAL_PAID,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INTEREST_AND_PRINCIPAL_PAID}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <BondIssueInformation
        panelRefs={panelRefs}
        panelKey={MAP_KEY.BOND_ISSUE_INFORMATION}
        sizes={sizes}
        setSizes={setSizes}
      />
      <RelativeToEquity
        panelRefs={panelRefs}
        panelKey={MAP_KEY.RELATIVE_TO_EQUITY}
        sizes={sizes}
        setSizes={setSizes}
      />
      <RemainingMaturities
        panelRefs={panelRefs}
        panelKey={MAP_KEY.REMAINING_MATURITIES}
        sizes={sizes}
        setSizes={setSizes}
      />
      <InterestAndPrincipalPaid
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INTEREST_AND_PRINCIPAL_PAID}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
