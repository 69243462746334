import { useEffect, useRef, useState } from 'react'
import { COMPONENT } from '../../../configs/Layout'
import { debounce } from '../../utils/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  FULL_COMPONENT,
} from '../../utils/EventEmitter'
import { wrapperId } from '../withWrapper'

const useWrapper = () => {
  const [width, setWidth] = useState(0)
  const [isFullComponent, setIsFullComponent] = useState(false)

  const wrapperContent = useRef(null)
  wrapperContent.current = document.querySelectorAll(`#${wrapperId} > div`)

  useEffect(() => {
    const openFullScreen = () => {
      setIsFullComponent(true)
    }
    EventEmitter.subscribe(FULL_COMPONENT, openFullScreen)
    return () => {
      EventEmitter.unsubscribe(FULL_COMPONENT, openFullScreen)
    }
  }, [])

  useEffect(() => {
    const closeFullScreen = () => {
      setIsFullComponent(false)
    }
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, closeFullScreen)
    return () => {
      EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, closeFullScreen)
    }
  }, [])

  useEffect(() => {
    if (wrapperContent.current && wrapperContent.current.length) {
      const handleResize = debounce(() => {
        const panels = Array.from(wrapperContent.current).filter(
          (div) => div.style.top === '0px',
        )
        setWidth(
          panels.reduce((prev, curr) => prev + curr.offsetWidth, 0) +
            (panels.length - 1) * 2 * COMPONENT.MARGIN,
        )
      })
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [wrapperContent.current, isFullComponent])

  return { width }
}

export default useWrapper
