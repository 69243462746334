import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { NAVIGATION_SECONDARY_MARKET } from './constants'
import style from './index.module.css'

export const SecondaryMarket = () => {
  const [currentTab, setCurrentTab] = useState(
    NAVIGATION_SECONDARY_MARKET[0].value,
  )
  const [currentComponent, setCurrentComponent] = useState(
    NAVIGATION_SECONDARY_MARKET[0].component,
  )

  const handleClickTab = (item) => () => {
    setCurrentTab(item.value)
    setCurrentComponent(item.component)
  }

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.corporateBond.secondaryMarket.TITLE" />
              <VerticalLine mr={28} />
              <div className={style.tabLinkContainer}>
                {NAVIGATION_SECONDARY_MARKET.map((item) => (
                  <a
                    key={item.value}
                    className={`${style.tabLink} ${
                      currentTab === item.value ? style.tabLinkActive : ''
                    }`}
                    onClick={handleClickTab(item)}
                  >
                    <Span style={{ fontSize: 12 }}>
                      <Translate value={item.title} />
                    </Span>
                    {currentTab === item.value && (
                      <div className={style.tabLinkActiveBorderBottom}></div>
                    )}
                  </a>
                ))}
              </div>
            </div>
            {size.height && (
              <div
                style={{
                  height: `calc(100% - 48px)`,
                  position: 'relative',
                }}
              >
                {currentComponent}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
