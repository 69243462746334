import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import SelectExchange from '../../common/selectExchange'
import SelectSector from '../../common/selectSector'
import { getOrganizationsByExchangeAndICB } from '../store/thunk'
import styles from './style.module.css'

export const SelectExchangeSector = ({
  selectSectorRef,
  selectExchangeRef,
}) => {
  const dispatch = useDispatch()

  const [firstMountSector, setFirstMountSector] = useState(true)
  const [exchange, setExchange] = useState([])
  const [parentExchange, setParentExchange] = useState([])
  const [sector, setSector] = useState([])
  const [parentSector, setParentSector] = useState([])

  useEffect(() => {
    if ((sector.length === 0 || exchange.length === 0) && firstMountSector) {
      return
    }

    setFirstMountSector(false)
    dispatch(
      getOrganizationsByExchangeAndICB({
        groupIds: [...exchange, ...parentExchange],
        icbIds: [...sector, ...parentSector],
      }),
    )
  }, [exchange, parentExchange, sector, parentSector, firstMountSector])

  return (
    <div className={styles.selectExchangeSectorContainer}>
      <SelectExchange
        selectExchangeRef={selectExchangeRef}
        listChecked={exchange}
        listParentChecked={parentExchange}
        changeListParentChecked={setParentExchange}
        changeExchangeChecked={setExchange}
      />
      <SelectSector
        selectSectorRef={selectSectorRef}
        listChecked={sector}
        listParentChecked={parentSector}
        isFirstMount={firstMountSector}
        changeFirstMountPopup={setFirstMountSector}
        changeListParentChecked={setParentSector}
        changeSectorChecked={setSector}
      />
    </div>
  )
}
