import { withWrapper } from '../../../common/withWrapper'
import { PANEL_LAYOUTS } from '../../../constants/Layout'
import { PanelFundFlow } from './fundFlow/PanelFundFlow'
import { PanelNavCertificate } from './navCertificate/PanelNavCertificate'
import { PanelNavDataTable } from './navDataTable/PanelNavDataTable'

const mapKeys = [
  { key: 'NAV_CERTIFICATE', component: PanelNavCertificate },
  { key: 'FUND_FLOW', component: PanelFundFlow },
  {
    key: 'NAV_DATA_TABLE',
    component: PanelNavDataTable,
  },
]
const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return mapKeys.map((item) => {
    const Component = item.component

    return (
      <Component
        key={item.key}
        panelRefs={panelRefs}
        panelKey={item.key}
        sizes={sizes}
        setSizes={setSizes}
      />
    )
  })
}

export default withWrapper(PANEL_LAYOUTS[336](mapKeys), panelRefs)(Content)
