import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import { GDP_SECTOR_VALUE } from './constants'
import FirstColumnCell from './FirstColumnCell'
import style from './index.module.css'
import OtherCellColumn from './OtherCellColumn'
import {
  getGDPBySectorLoading,
  getGDPSectorById,
  getGDPTypeNameData,
  getGDPTypeNameLoading,
  getGroupColumns,
  getIds,
  getIsAllowCallApi,
  getIsFirsTime,
  getIsShowAlert,
  getMinQuarterYear,
  getValueGrowthYoY,
  getYearlyQuarterly,
  handleIsFirstTime,
  handleIsShowAlert,
  selectGDPSectorValue,
  sort,
} from './store/slice'
import { getGDPBySector, getGDPTypeName } from './store/thunk'

const { NOMINAL } = HEADER_VALUE
const { VALUE } = GDP_SECTOR_VALUE
export const NUMBER_OF_PERIOD = 10
export const NUMBER_OF_PERIOD_FIRST_TIME = 17
const HORIZONTAL_TRACK_LEFT = 28

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const GDPBySectorLoading = useSelector(getGDPBySectorLoading)
  const GDPTypeNameLoading = useSelector(getGDPTypeNameLoading)
  const GDPSectorById = useSelector(getGDPSectorById)
  const GDPTypeNameData = useSelector(getGDPTypeNameData)
  const timeFrequency = useSelector(getYearlyQuarterly)
  const nominalRealValue = useSelector(getNominalRealValue)
  const valueGrowthYoY = useSelector(getValueGrowthYoY)
  const ids = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const isFirstTime = useSelector(getIsFirsTime)
  const { minYear, minQuarter } = useSelector(getMinQuarterYear)
  const isAllowCallApi = useSelector(getIsAllowCallApi)
  const isShowAlert = useSelector(getIsShowAlert)

  const isValue =
    nominalRealValue === NOMINAL ? false : valueGrowthYoY === VALUE

  const renderFooter = () => {
    if (nominalRealValue === NOMINAL || valueGrowthYoY === VALUE) {
      return <Translate value="economy.gdp.gdp.TRILLION_VND" />
    }
    return <Translate value="economy.gdp.gdp.GROWTH_YOY_FOOTER" />
  }

  const scrollToLeft = () => {
    dispatch(handleIsFirstTime(false))

    if (timeFrequency === 'Yearly' && isAllowCallApi && minYear) {
      dispatch(
        getGDPBySector({
          TimeFrequency: timeFrequency,
          IsNominal: nominalRealValue === NOMINAL,
          IsValue: isValue,
          Year: minYear - 1,
          NumberOfPeriod: NUMBER_OF_PERIOD,
        }),
      )
    }

    if (timeFrequency !== 'Yearly' && isAllowCallApi && minYear && minQuarter) {
      dispatch(
        getGDPBySector({
          TimeFrequency: timeFrequency,
          IsNominal: nominalRealValue === NOMINAL,
          IsValue: isValue,
          Year: minYear,
          Quarter: minQuarter,
          NumberOfPeriod: NUMBER_OF_PERIOD,
        }),
      )
    }
  }

  useEffect(() => {
    dispatch(
      getGDPBySector({
        TimeFrequency: timeFrequency,
        IsNominal: nominalRealValue === NOMINAL,
        IsValue: isValue,
        NumberOfPeriod: NUMBER_OF_PERIOD_FIRST_TIME,
      }),
    )
    dispatch(handleIsFirstTime(true))
  }, [timeFrequency, nominalRealValue, valueGrowthYoY, locale])

  useEffect(() => {
    dispatch(
      getGDPTypeName({
        TimeFrequency: timeFrequency,
        IsNominal: nominalRealValue === NOMINAL,
        IsValue: isValue,
      }),
    )
  }, [timeFrequency, nominalRealValue, valueGrowthYoY, locale])

  if ((GDPBySectorLoading || GDPTypeNameLoading) && isFirstTime) {
    return <Loading />
  }

  if (Object.keys(GDPSectorById).length === 0 || GDPTypeNameData.length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <div
                style={{
                  height: height - size.height,
                }}
              >
                <Table
                  ids={ids}
                  columnDraggable={false}
                  getDataFromRedux={selectGDPSectorValue}
                  rowDraggable={false}
                  sort={sort}
                  horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                  isLoading={GDPBySectorLoading || GDPTypeNameLoading}
                  schema={[
                    {
                      key: 'indicator',
                      title: 'economy.gdp.gdp.INDICATOR',
                      is18n: true,
                    },
                    ...groupColumns,
                  ].map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    const result = {
                      colId,
                      title,
                    }
                    if (index === 0) {
                      return {
                        ...result,
                        thStyle: {
                          textAlign: 'left',
                        },
                        tdStyle: {
                          minWidth: 240,
                        },
                        canCustomTd: true,
                        render: (val, rowId, props) => {
                          const tableHorizontalHead = GDPTypeNameData.find(
                            (item) => item.nationalAccountVNTypeId === rowId,
                          )
                          return (
                            <FirstColumnCell
                              props={props}
                              val={
                                tableHorizontalHead?.nationalAccountVNTypeName
                              }
                              level={
                                tableHorizontalHead?.nationalAccountVNTypeLevel ||
                                1
                              }
                              rowId={rowId}
                              GDPTypeNameData={GDPTypeNameData}
                            />
                          )
                        },
                      }
                    }

                    return {
                      ...result,
                      isI18n: true,
                      tdStyle: {
                        textAlign: 'right',
                      },
                      thStyle: {
                        textAlign: 'right',
                      },
                      canCustomTd: true,
                      render: (val, rowId, props) => {
                        return <OtherCellColumn val={val} props={props} />
                      },
                    }
                  })}
                  stickyFirstColumn={true}
                  isPagination={false}
                  hasFooter={false}
                  resizable={false}
                  scrollToLeftCallback={scrollToLeft}
                  defaultScrollToRight={isFirstTime}
                  isLazyLoadLeft={true}
                />
              </div>
            )}
            <div className={style.wapFooterGDPBySector}>
              <Span
                style={{ fontSize: 11 }}
                className={style.footerGDPBySector}
              >
                {renderFooter()}
              </Span>
            </div>
            <PopupAlert
              message={<Translate value={'economy.gdp.gdp.MAX_ITEM'} />}
              isShow={isShowAlert}
              handleClose={() => {
                dispatch(handleIsShowAlert(false))
              }}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableComponent
