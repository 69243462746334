import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { leftUnit } from '../growthYear/constants'
import { dataTypeConstants, timeTypeConstants, xAxisKey } from './constants'
import { getCorrespondingCheckboxKeys } from './helper'
import {
  changeListCheckbox,
  selectIsKeepListCheckbox,
  selectListCheckbox,
} from './store/slice'

export const ChartComponent = ({
  width,
  height,
  data,
  leftKeysValue,
  mappingDisplayName,
  fillColor,
  dataType,
  timeRange,
}) => {
  const dispatch = useDispatch()

  const listCheckbox = useSelector(selectListCheckbox)
  const isKeepListCheckbox = useSelector(selectIsKeepListCheckbox)

  const footerData = () => {
    let keys = []
    if (leftKeysValue && leftKeysValue.length) {
      keys = [...leftKeysValue]
    }
    return keys.map((i, key) => ({
      text: mappingDisplayName[i] ?? i,
      type: FOOTER_TYPE.LINE,
      color: fillColor[key],
      dataKey: i,
    }))
  }

  const getCheckboxIndexByKey = (key) => {
    return leftKeysValue?.findIndex((item) => item === key)
  }

  const getContent = () => {
    return [
      {
        keys: listCheckbox,
        yAxisId: 'leftChart',
        unit: dataType === dataTypeConstants.VALUE ? leftUnit : '%',
        decimalLength: 2,
        formatValueColor: (val) => (val < 0 ? '#ff4752' : ''),
      },
      {
        keys: [],
        yAxisId: 'rightChart',
      },
    ]
  }

  const renderLineChart = () => {
    return listCheckbox.map((key) => (
      <Line
        key={key}
        yAxisId="leftChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[getCheckboxIndexByKey(key)]}
        fill={fillColor[getCheckboxIndexByKey(key)]}
        strokeWidth={2}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  const setListCheckbox = (item) => {
    dispatch(changeListCheckbox(item))
  }

  useEffect(() => {
    if (!isKeepListCheckbox) {
      setListCheckbox(
        [...listCheckbox].length
          ? getCorrespondingCheckboxKeys([...listCheckbox], dataType)
          : leftKeysValue,
      )
    } else {
      setListCheckbox([...listCheckbox])
    }
  }, [leftKeysValue])

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              margin={{
                ...MARGIN_RECHARTS,
                left: dataType === dataTypeConstants.GROWTH ? 12 : 0,
              }}
              keyXAxis={xAxisKey}
              yAxis={[
                {
                  id: 'leftChart',
                  keys: listCheckbox,
                  orientation: 'left',
                  isLineChart: true,
                  label:
                    dataType === dataTypeConstants.GROWTH
                      ? ''
                      : I18n.t(
                          'economy.productionAndConsumption.consumption.CHART_BILLION',
                        ),
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  unitYAxis: dataType === dataTypeConstants.GROWTH ? '%' : '',
                },
                {
                  id: 'rightChart',
                  keys: [],
                  orientation: 'right',
                  yAxisWidth: 50,
                },
              ]}
              timeFrame={
                timeRange !== timeTypeConstants.SIX_MONTHS && TIME_RANGES.CUSTOM
              }
              isNotFormatXAxis={timeRange !== timeTypeConstants.SIX_MONTHS}
              tickFormatter={(val) => val}
              tooltipSchema={{
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {renderLineChart()}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            listCheckbox={listCheckbox}
            setListCheckbox={setListCheckbox}
          />
        </>
      )}
    </SizeTracker>
  )
}

ChartComponent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
