import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { orderBy, sortBy } from 'lodash'
import moment from 'moment'
import ExpectedCash from '../../../../../../../core/services/bond/overview/expectedCash'
import { VALUE_TIME } from '../../constants'
import { renderTimeType } from '../../helper'
import { name } from '../../store/slice'

export const getDataValueOfBonds = createAsyncThunk(
  'overview/latePayment/exptedCashIndutry',
  async (data, { rejectWithValue, getState }) => {
    try {
      const percent = 1000000000
      const response = await ExpectedCash.getDataChart(data)
      if (response.success) {
        const dataList = response?.data || []

        const resultMap = new Map()
        const uniqueDates = [...new Set(dataList.map((item) => item.date))]
        const uniqueIds = [...new Set(dataList.map((item) => item.icbId))]

        dataList.forEach((item) => {
          const { date, icbId, value } = item
          const key = `${date}-${icbId}`
          const accumulatedSum = resultMap.get(key) || {
            date,
            icbId,
            icbName: item.icbName,
            value: 0,
          }
          accumulatedSum.value += value / percent
          resultMap.set(key, accumulatedSum)
        })
        const formattedData = Array.from(resultMap.values())
        const datatChart = uniqueDates.map((e) => {
          const item = formattedData.filter((i) => i.date === e)
          const object = {}

          uniqueIds.forEach((key) => {
            const itemFor = item.find((z) => z.icbId === key)
            object[`${key}-value`] = itemFor.value
            object[`${key}-name`] = itemFor.icbName
          })

          return {
            XAxis: e,
            ...object,
          }
        })

        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1

        const currentYear = currentDate.getFullYear()
        const currentTime = moment()
        const currentQuarter = currentTime.quarter()

        const allState = getState()
        const state = allState[name]
        let dataReturn = []
        let dataSort = []
        if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataSort = orderBy(
            datatChart,
            (item) => {
              const dates = item.XAxis.split('-')
              return !!dates?.length
                ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                : item.XAxis
            },
            'asc',
          )
        } else {
          dataSort = sortBy(datatChart, (item) => {
            const [month, year] = item.XAxis.split('-')
            return new Date(`${year}-${month}`)
          })
        }

        if (state?.activeTab === VALUE_TIME.MONTHLY) {
          const dataFilter = dataSort.filter((item) => {
            const [month, year] = item.XAxis.split('-')

            return (
              parseInt(month) >= currentMonth && parseInt(year) >= currentYear
            )
          })

          dataReturn = dataFilter.map((e) => {
            const [month, year] = e.XAxis.split('-')
            if (
              parseInt(month) === currentMonth &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                XAxis:
                  moment(e.XAxis, 'M-YYYY').format('MM-YYYY') +
                  renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: moment(e.XAxis, 'M-YYYY').format('MM-YYYY'),
            }
          })
        } else if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataReturn = dataSort.map((e) => {
            const [quarter, year] = e.XAxis.split('-')

            if (
              parseInt(quarter.slice(1), 10) === currentQuarter &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                XAxis: e.XAxis + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: e.XAxis,
            }
          })
        } else {
          dataReturn = dataSort.map((e) => {
            if (parseInt(e.XAxis) === currentYear) {
              return {
                ...e,
                XAxis: e.XAxis + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: e.XAxis,
            }
          })
        }

        return {
          data: dataReturn,
          ids: uniqueIds,
        }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataCashfollowAll = createAsyncThunk(
  'overview/latePayment/exptedCashAll',
  async (data, { rejectWithValue, getState }) => {
    try {
      const percent = 1000000000
      const response = await ExpectedCash.getDataCashfollowAll(data)
      if (response.success) {
        const dataCheck = response?.data || []
        const data = dataCheck.map((e) => ({
          ...e,
          value: e.value / percent,
          valueB: e.valueB / percent,
        }))

        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1
        const currentYear = currentDate.getFullYear()
        const currentTime = moment()
        const currentQuarter = currentTime.quarter()

        const allState = getState()
        const state = allState[name]
        let dataReturn = []
        let dataSort = []
        if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataSort = orderBy(
            data,
            (item) => {
              const dates = item.date.split('-')
              return !!dates?.length
                ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                : item.date
            },
            'asc',
          )
        } else {
          dataSort = sortBy(data, (item) => {
            const [month, year] = item.date.split('-')
            return new Date(`${year}-${month}`)
          })
        }

        if (state?.activeTab === VALUE_TIME.MONTHLY) {
          dataReturn = dataSort.map((e, index) => {
            const [month, year] = e.date.split('-')
            if (
              parseInt(month) === currentMonth &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                date: moment(e.date, 'M-YYYY').format('MM-YYYY'),
              }
            }
            return {
              ...e,
              date: moment(e.date, 'M-YYYY').format('MM-YYYY'),
            }
          })
        } else if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataReturn = dataSort.map((e) => {
            const [quarter, year] = e.date.split('-')

            if (
              parseInt(quarter.slice(1), 10) === currentQuarter &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                // date: e.date + renderTimeType(state?.activeTab),
                date: e.date,
              }
            }
            return {
              ...e,
              date: e.date,
            }
          })
        } else {
          dataReturn = dataSort.map((e) => {
            if (parseInt(e.date) === currentYear) {
              return {
                ...e,
                date: e.date,
              }
            }
            return {
              ...e,
              date: e.date,
            }
          })
        }
        return dataReturn
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
