import { useState } from 'react'
import { useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { selectValueById } from '../store/slice'
import styles from './index.module.css'

const MARGIN_LEFT = 40

export const FirstColumnCell = ({
  val,
  rowId,
  style,
  className,
  onRowCollapse,
}) => {
  const level = useSelector(selectValueById(rowId, 'level'))
  const isItemCollapse = useSelector(selectValueById(rowId, 'isCollapse'))
  const [isCollapse, setIsCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  return (
    <td
      className={`${className} ${level === 1 ? styles.borderTopTd : ''}`}
      style={style}
    >
      <div
        className="align-center"
        style={{ marginLeft: (level - 1) * MARGIN_LEFT || 0 }}
      >
        {isItemCollapse && (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#ececec' }}
            ></i>
          </button>
        )}
        <TextEllipsisStaticLine
          val={val}
          appendStyle={{ lineHeight: 1.5, color: level === 1 ? '#fecf2f' : '' }}
        />
      </div>
    </td>
  )
}
