import { TYPE_STOCK_INDICES_SECTOR } from '../common/hooks/useBasicInfo'
import { HNX_GROUP, HOSE_GROUP, UPCOM_GROUP } from '../constants/Group'
import { FEATURES } from '../constants/routes'
import {
  COMPANY_HOHAUP_OPTIONS,
  COMPANY_OTC_PRIVATE_OPTIONS,
  INDICE_HNX_UPCOM_OPTIONS,
  INDICE_HOSE_OPTIONS,
  INDICE_INDEX_OPTIONS,
  SEARCH_TYPE,
  SECTOR_OPTIONS,
} from '../search/constants'

export const highlightString = (str, highlightStr, searchStr) => {
  const searchIndex = str.toLowerCase().indexOf(searchStr.toLowerCase())
  return searchIndex !== -1 ? (
    <span>
      <span>{str.substring(0, searchIndex)}</span>
      <span style={{ color: '#2f9aee' }}>
        {str.substring(searchIndex, searchIndex + searchStr.length)}
      </span>
      <span>{str.substring(searchIndex + searchStr.length)}</span>
    </span>
  ) : (
    <span>{str}</span>
  )
}

export const getGroupExchange = (groupCodes) => {
  const isOtc =
    groupCodes.findIndex((code) => code.toLowerCase() === 'otc') !== -1
  if (isOtc) {
    return 'OTC'
  }
  const isHose =
    groupCodes.findIndex(
      (code) =>
        HOSE_GROUP.map((v) => v.toLowerCase()).includes(code.toLowerCase()) ||
        code.toLowerCase() === 'hose',
    ) !== -1
  if (isHose) {
    return 'HOSE'
  }
  const isHnx =
    groupCodes.findIndex(
      (code) =>
        HNX_GROUP.map((v) => v.toLowerCase()).includes(code.toLowerCase()) ||
        code.toLowerCase() === 'hnx',
    ) !== -1
  if (isHnx) {
    return 'HNX'
  }
  const isUpcom =
    groupCodes.findIndex(
      (code) =>
        UPCOM_GROUP.map((v) => v.toLowerCase()).includes(code.toLowerCase()) ||
        code.toLowerCase() === 'upcom',
    ) !== -1
  if (isUpcom) {
    return 'UPCOM'
  }
  return ''
}

export const getSearchRoute = (searchStr, searchType) => {
  return `${FEATURES.search.route}?text=${searchStr}&type=${searchType}`
}

const getCompanyGroupTicker = (groupCodes) => {
  const isOtc =
    groupCodes.findIndex((code) => code.toLowerCase() === 'otc') !== -1
  const isPrivate =
    groupCodes.findIndex((code) => code.toLowerCase() === 'private') !== -1
  const isHohaup =
    groupCodes.findIndex((code) => code.toLowerCase() === 'hohaup') !== -1
  if (isOtc) {
    return 'OTC'
  } else if (isPrivate) {
    return 'PRIVATE'
  } else if (isHohaup) {
    return 'HOHAUP'
  } else {
    return ''
  }
}

export const getSearchItemMoreOptions = (searchType, groupCodes, features) => {
  switch (searchType) {
    case SEARCH_TYPE.COMPANY: {
      const groupTicker = getCompanyGroupTicker(groupCodes)
      switch (groupTicker) {
        case 'OTC':
        case 'PRIVATE':
          return COMPANY_OTC_PRIVATE_OPTIONS(features)
        case 'HOHAUP':
          return COMPANY_HOHAUP_OPTIONS(features)
        default:
          return []
      }
    }
    case SEARCH_TYPE.SECTOR: {
      return SECTOR_OPTIONS(features)
    }
    case SEARCH_TYPE.INDICE: {
      const groupCode = getGroupExchange(groupCodes)
      if (
        groupCodes.findIndex((code) =>
          ['vnindex', 'hnxindex', 'upcomindex'].includes(code.toLowerCase()),
        ) !== -1
      ) {
        return INDICE_INDEX_OPTIONS(features)
      } else if (groupCode === 'HOSE') {
        return INDICE_HOSE_OPTIONS(features)
      } else if (groupCode === 'HNX' || groupCode === 'UPCOM') {
        return INDICE_HNX_UPCOM_OPTIONS(features)
      } else {
        return []
      }
    }
    case SEARCH_TYPE.BOND: {
      return SECTOR_OPTIONS(features)
    }
    default:
      return []
  }
}

export const getStockIndicesSectorInfo = (data, getBasicInfo) => {
  if (data.organizationId) {
    return getBasicInfo(data.organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK)
  } else if (data.groupId) {
    return getBasicInfo(data.groupId, TYPE_STOCK_INDICES_SECTOR.INDICES)
  } else if (data.icbId) {
    return getBasicInfo(data.icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR)
  } else if (data.derivativeId) {
    return getBasicInfo(
      data.derivativeId,
      TYPE_STOCK_INDICES_SECTOR.COVERER_WARRANT,
    )
  } else {
    return getBasicInfo('', TYPE_STOCK_INDICES_SECTOR.STOCK)
  }
}

export const getSearchResultInfo = (data, getBasicInfo) => {
  if (data.bondId) {
    return getBasicInfo(data.bondId, TYPE_STOCK_INDICES_SECTOR.BOND)
  } else if (data.organizationId) {
    return getBasicInfo(data.organizationId, TYPE_STOCK_INDICES_SECTOR.STOCK)
  } else if (data.groupId) {
    return getBasicInfo(data.groupId, TYPE_STOCK_INDICES_SECTOR.INDICES)
  } else if (data.icbId) {
    return getBasicInfo(data.icbId, TYPE_STOCK_INDICES_SECTOR.SECTOR)
  } else if (data.derivativeId) {
    return getBasicInfo(
      data.derivativeId,
      TYPE_STOCK_INDICES_SECTOR.COVERER_WARRANT,
    )
  } else {
    return getBasicInfo('', TYPE_STOCK_INDICES_SECTOR.STOCK)
  }
}

export const removeAccents = (str) => {
  return str
    ? str
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/đ/g, 'd')
        .replace(/Đ/g, 'D')
    : ''
}

export const removeSpecialCharacter = (str) => {
  return str ? str.replace(/[^a-zA-Z0-9 ]/g, '') : ''
}
