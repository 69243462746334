import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { initLineColor } from '../../panelStatisticalInterestRate/helper'
import {
  defineGroupColumns,
  getIdKeys,
  handleIdWithTitle,
  handleMaxMinValueByColId,
  initCheckboxChecked,
  keyById,
} from '../helper'
import { getMaxDate, getStatisticOtherBank } from './thunk'

const initialState = {
  date: '',
  dateHasData: '',
  limitTime: {
    startDate: '',
    endDate: '',
    minDate: '',
    minYear: '',
    maxDate: '',
    maxYear: '',
  },
  tableDataLoading: true,
  tableData: {},
  checkboxChecked: [],
  idKey: [],
  idWithTitle: [],
  initIds: [],
  groupColumns: [],
  keySearch: '',
  lineChartColor: [],
  isShowAlert: false,
  tab: 'IsIndividual',
  minMaxValueByColId: {},
}

const slice = createSlice({
  name: 'economy/monetary/interestRate/panelDepositRateCommercial',
  initialState,
  reducers: {
    handleDate: (state, action) => {
      state.date = action.payload
    },
    handleLimitTime: (state, action) => {
      state.limitTime = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    sort: (state, action) => {
      state.idKey = getIdsFromProps(
        state.idKey,
        state.tableData,
        action.payload,
        state.initIds,
        3,
      )
    },
    handleKeySearch: (state, action) => {
      state.keySearch = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleIsShowAlert: (state, action) => {
      state.isShowAlert = action.payload
    },
    handleDateHasData: (state, action) => {
      state.dateHasData = action.payload
    },
    handleTab: (state, action) => {
      state.tab = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatisticOtherBank.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(getStatisticOtherBank.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableDataLoading = false
      state.tableData = keyById(data)
      state.idWithTitle = handleIdWithTitle(data)
      state.idKey = state.initIds = getIdKeys(data)
      state.groupColumns = defineGroupColumns(data)
      state.checkboxChecked = initCheckboxChecked(state.idKey)
      state.lineChartColor = initLineColor(state.checkboxChecked)
      state.minMaxValueByColId = handleMaxMinValueByColId(data)
      if (data?.length) state.dateHasData = data[0].effectiveDateId
    })
    builder.addCase(getStatisticOtherBank.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.limitTime = action.payload || {}
    })
  },
})

export const {
  handleDate,
  handleLimitTime,
  handleCheckboxChecked,
  sort,
  handleKeySearch,
  handleLineChartColor,
  handleIsShowAlert,
  handleDateHasData,
  handleTab,
} = slice.actions

export const getDate = (state) => state[slice.name].date
export const getLimitTime = (state) => state[slice.name].limitTime
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableData = (state) => state[slice.name].tableData
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getDepositRateCommercialValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableData[id], attr)
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIdKey = (state) => state[slice.name].idKey
export const getIdWithTitles = (state) => state[slice.name].idWithTitle
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getKeySearch = (state) => state[slice.name].keySearch
export const getIsShowAlert = (state) => state[slice.name].isShowAlert
export const getDateHasData = (state) => state[slice.name].dateHasData
export const getTab = (state) => state[slice.name].tab
export const getMinMaxValueByColId = (colId) => (state) =>
  state[slice.name].minMaxValueByColId[colId]

register(slice.name, slice.reducer)
