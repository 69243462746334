import { createSlice } from '@reduxjs/toolkit'
import { TIME_RANGES } from '../../../../../../common/tabs/DispatchActionTab'
import { register } from '../../../../../../utils/ReducerRegistry'
import { VALUE_TYPES } from '../../../constants'

const defaultFundIds = [2172623, 7725, 4110, 12219, 7723]

const initialState = {
  filters: {
    fund: defaultFundIds,
    structure: [],
    type: [],
    valueType: VALUE_TYPES.RELATIVE,
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    timeRange: TIME_RANGES.OneYear,
    startDate: '',
    endDate: '',
  },
}

export const slice = createSlice({
  name: 'fund/fundCenter/overview/performance/navCertificateGrowth',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload }
    },
  },
  extraReducers: (builder) => {},
})

export const selectFilters = (state) => state[slice.name].filters

export const { changeFilter } = slice.actions

register(slice.name, slice.reducer)
