import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { titleData } from '../../common/helper'
import {
  selectFilterMonth,
  selectFilterYear,
} from '../keyIndicator/store/slice'
import ChartComponent from './ChartComponent'
import { selectChartData, selectLoading } from './store/slice'
import { getChartData } from './store/thunk'

const KeyIndicatorProvince = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const dataChart = useSelector(selectChartData)
  const isLoading = useSelector(selectLoading)
  const filterMonth = useSelector(selectFilterMonth)
  const filterYear = useSelector(selectFilterYear)

  useEffect(() => {
    if (filterMonth && filterYear)
      dispatch(
        getChartData({
          Month: filterMonth,
          Year: filterYear,
        }),
      )
  }, [locale, filterMonth, filterYear])

  return (
    <div id="keyIndicatorProvinceId" style={{ height: height }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {dataChart.length ? (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    {size.height && (
                      <div style={{ position: 'relative' }}>
                        <ChartComponent
                          data={dataChart}
                          width={width}
                          height={height - size.height}
                          barKeys={['prevValue', 'value']}
                          keyYAxis={'locationName'}
                        />
                      </div>
                    )}
                    <Footer
                      key={width}
                      list={[
                        {
                          text: titleData(filterMonth, filterYear),
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: '#facc5c',
                          },
                        },
                        {
                          text: titleData(filterMonth, filterYear - 1),
                          type: TYPE.SQUARE,
                          before: {
                            bgColor: '#185999',
                          },
                        },
                      ]}
                    />
                  </>
                )
              }}
            </SizeTracker>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
}

export default KeyIndicatorProvince
