export const getLatestQuarters = (
  count,
  realYear = new Date().getFullYear(),
  realQuarter = Math.floor((new Date().getMonth() + 3) / 3),
) => {
  const quarters = {
    Q1: 1,
    Q2: 2,
    Q3: 3,
    Q4: 4,
  }
  let quarter = realQuarter < 5 ? realQuarter : 4
  let year = realYear

  return Array.from(Array(count)).map((_, index) => {
    const result = { year, quarter }
    quarter--
    if (quarter === 0) {
      year--
    }
    if (quarter < quarters.Q1) {
      quarter = quarters.Q4
    }
    return result
  })
}

export const getLatestYears = (realYear, realQuarter) => {
  const arrYear = []
  let year = realYear
  for (let index = 0; index < 3; index++) {
    if (realQuarter !== 5) {
      arrYear.push(year - 1)
    } else {
      arrYear.push(year)
    }
    year = year - 1
  }
  return arrYear
}
