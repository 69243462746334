export const common = {
  TITLE: 'Security Description',
  no: 'No',
  yes: 'Yes',
  generalSetting: 'Overview',
  TradingInformation: 'Trading Information',
  Information: 'Information',
  Orderbook: 'Orderbook',
  chartInterest: 'Price and Maturity interest rate chart',
  SC14_MS001: 'Features in development',
  SC14_MS002: 'You must select at least 1 watchlist to add ticker',
  SC14_MS003: 'Ticker has been added to watchlist',
  SC14_MS004: 'Only up to 20 tikcers can be added',
}

export const generalSetting = {
  btnPricing: 'Valuation',
  BondIsinCode: 'ISIN code:',
  OrganizationShortName: 'Issuer:',
  IssueDateId: 'Issue date:',
  MaturityDateId: 'Maturity date:',
  ParValue: 'Par value (VND):',
  DurationId: 'Issue period:',
  remainingDuration: 'Remain duration (Months):',
  CouponTypeId: 'Coupon Type:',
  CouponValue: 'Coupon (%):',
  PaymentCalendarId: 'Intest payment period:',
  IsCollateral: 'Collateral:',
  IsPaymentGuarantee: 'Collateral Type:',
  detail: 'Details',
  updating: 'Updating',
  view: 'View',
  report: 'Prospectus:',
  popup: {
    title: 'Details',
    btnOk: 'OK',
    infoBasic: {
      title: 'GENERAL INFORMATION',
      bondId: 'Bond Ticker:',
      codeISIN: 'ISIN Code:',
      issues: 'Issuer:',
      sector: 'Industry:',
      typeBond: 'Bond Type:',
    },
    interestRate: {
      title: 'INTEREST',
      typeCouppon: 'Coupon Type:',
      method: 'Interest Payment Method:',
      interestPaymentPeriod: 'Interest Period:',
      NumberOfExpectedPayable: 'Number of Expected Payable:',
      FixedCoupon: 'Fixed Coupon:',
      NumberOfFixedCoupon: 'Number of Fixed Coupon:',
      ReferenceInterestRate: 'Reference Interest Rate:',
      FloatInterestSpread: 'Float Interest Spread:',
      NumberofFloatcoupon: 'Number of Float coupon:',
    },
    IssueInformation: {
      title: 'ISSUE INFORMATION',
      IssueMarket: 'Issue Market:',
      IssueMethod: 'Issue Method:',
      IssueForm: 'Issue Form:',
      Currency: 'Currency:',
      IssueDate: 'Issue Date:',
      MaturityDate: 'Maturity Date:',
      ParValue: 'Par Value (VND):',
      Duration: 'Duration:',
      IssueValue: 'Issue Value (bil. VND):',
      OutstandingValue: 'Outstanding Value (bil. VND)',
      RemainingDuration: 'Remaining Duration (Months)',
    },
    ConditionsAttached: {
      title: 'CONDITIONS ATTACH',
      Redemption: 'Redemption:',
      Convertible: 'Convertible:',
      Coveredwarrant: 'Covered warrant:',
      Issueguarantee: 'Issue guarantee:',
      Paymentguarantee: 'Payment guarantee:',
      CollateralType: 'Collateral Type:',
      GreenBond: 'Green Bond:',
      Status: 'Status:',
    },
  },
  popupFl: {
    title: 'Watchlist List',
    optionAll: 'All',
    btnCancel: 'Cancel',
    btnAdd: 'Add',
    btnCrete: 'Create',
  },
}

export const tradingInformation = {
  filter: {
    cleanPrice: 'Clean price',
    dirtyPrice: 'Dirty price',
    note: 'Price x 1 .Volume x1 . Value x1,000,000',
  },
  topBody: {
    change1D: '% change 1D:',
    change1W: '% change 1W:',
    change1M: '% change 1M:',
    changeYTD: '% change YTD:',
    TotalValue: 'Total Value:',
    TotalVolume: 'Total Volume:',
    TotalForeignBuyVolume: 'Total Foreign Buy Volume:',
    TotalForeignSellVolume: 'Total Foreign Sell Volume:',
    Avg10daystradingvolume: 'Avg 10 days trading volume:',
    Avgmonthlytradingvolume: 'Avg monthly trading volume:',
  },
  bottomBody: {
    titleLfet: 'INDEX',
    ZSpread: 'Z-Spread',
    CreditSpread: 'Credit Spread',
    BidAskSpread: 'Bid - Ask Spread',
    Maculayduration: 'Maculay Duration',
    ModifiedDuration: 'Modified Duration',
    PVBP: 'PVBP(VND)',
    Convexity: 'Convexity',

    titleRight: 'ISSUER CREDIT RATING',
    btnRight: 'Credit score',
    CreditRatingAgency: 'Credit Rating Agency',
    RatingDate: 'Rating Date',
    RatingType: 'Rating Type',
    RatingProspect: 'Rating Prospect',
    RatingHistory: 'Rating History',
  },
  popup: {
    title: 'Rating History',
    table: {
      No: 'No',
      CreditRatingAgency: 'Credit Rating Agency',
      RatingDate: 'Rating Date',
      RatingType: 'Rating Type',
      RatingProspect: 'Rating Prospect',
    },
  },
}

export const information = {
  filter: {
    News: 'News',
    Files: 'Files',
  },
  table: {
    Date: 'Date',
    News: 'News',
    Detail: 'Detail',
    view: 'View',
    event: 'Events',
  },
}

export const order = {
  filter: {
    tab: {
      Realtime: 'Realtime',
      History: 'History',
    },
    tradingType: {
      Deal: 'Deal',
      Match: 'Match',
    },
  },
  table: {
    Time: 'Time',
    DirtyPrice: 'Dirty Price',
    CleanPrice: 'Clean Price',
    PriceChange: 'Price Change',
    percentPriceChange: '% Price Change',
    YTM: 'YTM',
    sumYTM: '+/- YTM',
    Volume: 'Volume',
    TotalVolume: 'Total Volume',
    Value: 'Value',
    TotalValue: 'Total Value',
  },
  Note: 'Price x 1 .Volume x1. Value x1,000,000',
}

export const interestChart = {
  filter: {
    dirtyPrice: 'Dirty price',
    cleanPrice: 'Clean price',
    ytm: 'YTM',
  },
}
