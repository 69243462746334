import PropTypes from 'prop-types'
import { useState } from 'react'
import { getNewDashboardData } from '../helpers'
import { Item } from './item/Item'
import { NewDashboardGroup } from './NewDashboardGroup'

export const List = ({
  data,
  dashboardData,
  isNewDashboardGroup,
  isParentGroup,
  isNewDashboard,
  onClickItem,
}) => {
  const [isAddNewGroupDashboard, setIsAddNewGroupDashboard] = useState(false)

  return (
    <>
      {data.map((item, index) => {
        return (
          <Item
            key={index}
            data={item}
            isParentGroup={isParentGroup}
            onClickItem={onClickItem}
          />
        )
      })}
      {isAddNewGroupDashboard && (
        <Item
          data={getNewDashboardData(dashboardData, true)}
          isNewDashboard
          isParentGroup={true}
          onClickItem={onClickItem}
          onAddDashboard={() => setIsAddNewGroupDashboard(false)}
        />
      )}
      {isNewDashboard && (
        <Item
          data={getNewDashboardData(dashboardData)}
          isNewDashboard
          isParentGroup={false}
          onClickItem={onClickItem}
        />
      )}
      {isNewDashboardGroup && (
        <NewDashboardGroup onClick={() => setIsAddNewGroupDashboard(true)} />
      )}
    </>
  )
}

List.propTypes = {
  data: PropTypes.array,
  isNewDashboardGroup: PropTypes.bool,
  isParentGroup: PropTypes.bool,
  onClickItem: PropTypes.func,
}

List.defaultProps = {
  isNewDashboardGroup: false,
  isParentGroup: true,
  onClickItem: () => {},
}
