import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MacroService from '../../../../../core/services/common/MacroService'
import FundReportService from '../../../../../core/services/fund/FundReportService'
import { handleExport } from '../../../../utils/Export'
import { STATEMENT_CONSTANTS } from '../constants'

export const getDataFinancialTable = createAsyncThunk(
  'fund/fundProfile/periodicalReport/GET_PERIODICAL_DATA_TABLE',
  async (data, { rejectWithValue }) => {
    try {
      const { statement, ...params } = data
      let response = {}
      switch (statement) {
        case STATEMENT_CONSTANTS.ASSET_REPORT:
          response = await FundReportService.getDataPeriodicalAssetReport(
            params,
          )
          break
        case STATEMENT_CONSTANTS.PROFIT_AND_LOSS_REPORT:
          response = await FundReportService.getDataPeriodicalProfitLossReport(
            params,
          )
          break
        case STATEMENT_CONSTANTS.PORTFOLIO_REPORT:
          response = await FundReportService.getDataPeriodicalPortfolioReport(
            params,
          )
          break
        case STATEMENT_CONSTANTS.OTHER_REPORT:
          response = await FundReportService.getDataPeriodicalOtherReport(
            params,
          )
          break
        default:
          break
      }

      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicatorMapping = createAsyncThunk(
  'fund/fundProfile/periodicalReport/GET_INDICATOR_MAPPING',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FundReportService.getIndicatorPeriodicalMapping(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadPeriodicalReportExcel = createAsyncThunk(
  'fund/fundProfile/periodicalReport/DOWNLOAD_PERIODICAL_DATA',
  async (data) => {
    const response = await FundReportService.downloadPeriodicalReportExcel(data)
    handleExport(response.data, response.filename)
  },
)

export const getExchangeRates = createAsyncThunk(
  'fund/fundProfile/periodicalReport/GET_EXCHANGE_RATES',
  async (params, { rejectWithValue }) => {
    const response = await MacroService.getExchangeRates(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
