import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../../core/services/economy/importExport/OverviewService'
import { handleExport } from '../../../../../utils/Export'

export const balanceOfTradeTableContent = createAsyncThunk(
  'economy/importExport/balanceOfTradeTableContent',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getBalanceOfTrade(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadBalanceOfTradeTable = createAsyncThunk(
  'economy/importExport/downloadBalanceOfTradeTable',
  async (data) => {
    const response = await OverviewService.getDownloadTradeBalance(data)
    handleExport(response.data, response.filename)
  },
)
