import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { selectTemplates } from '../../store/slice'
import { updateTemplate } from '../../store/thunk'
import style from './index.module.css'

const enterKey = 13

const EditName = ({ item, setIsShowEdit, isDropdownTop }) => {
  const dispatch = useDispatch()

  const allTemplates = useSelector(selectTemplates)

  const inputRef = useRef()

  const [valueInput, setValueInput] = useState(item.templateName)
  const [error, setError] = useState(null)

  const handleBlur = () => {
    setIsShowEdit(false)
  }

  const handleSubmit = () => {
    if (
      valueInput.trim() &&
      !error &&
      valueInput.trim() !== item.templateName
    ) {
      dispatch(
        updateTemplate({
          templateId: item.value,
          templateName: valueInput,
          templateParameters: item.templateParameters,
        }),
      )
    } else {
      setValueInput(item.templateName)
      setError(null)
    }
    setIsShowEdit(false)
  }

  const handlePressEnter = (e) => {
    if (e.keyCode === enterKey) {
      e.preventDefault()
      if (error) return
      handleSubmit()
    }
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    setValueInput(value)
    if (value.trim().length < 3) {
      setError('sector.sectorConstituents.segmentation.TEMPLATE_NAME_TOO_SHORT')
    } else if (
      allTemplates.some(
        (template) =>
          template.templateName === value.trim() &&
          template.templateId !== item.value,
      )
    ) {
      setError('sector.sectorConstituents.segmentation.TEMPLATE_EXIST')
    } else setError(null)
  }

  return (
    <>
      <div className={style.inputForm}>
        <Input
          onBlur={handleBlur}
          onKeyDown={handlePressEnter}
          ref={inputRef}
          type="text"
          value={valueInput}
          onChange={handleChangeInput}
          className={`${style.inputTemplate}`}
          autoFocus
        />
        {error && (
          <div
            style={{ maxWidth: `${inputRef.current?.offsetWidth}px` }}
            className={`box-notice ${
              isDropdownTop ? style.tooltipError : style.tooltipErrorBottom
            }`}
          >
            <Span style={{ fontSize: 9 }}>
              {<Translate value={error} dangerousHTML />}
            </Span>
          </div>
        )}
      </div>
      <button
        onClick={handleSubmit}
        className={`btn-ok cursor-pointer ${style.btnOk}`}
      >
        <i className="icon-true" />
      </button>
    </>
  )
}

export default EditName
