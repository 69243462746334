import { COMPONENT } from '../../../configs/Layout'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import List from './list'

export const NewsReport = () => {
  return (
    <div
      className="w-100 position-relative"
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <List />
      </ScrollGroupComponent>
    </div>
  )
}
