import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import Detail from './detail'

export const NewsReportDetail = ({ redirectRoute, routeForItem }) => {
  return (
    <div
      className="w-100 position-relative"
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <Detail redirectRoute={redirectRoute} routeForItem={routeForItem} />
      </ScrollGroupComponent>
    </div>
  )
}

NewsReportDetail.propTypes = {
  redirectRoute: PropTypes.string,
}
