import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { getBasicInfoThunk } from './thunk'

const initialState = {
  basicInfo: {},
}

const slice = createSlice({
  name: 'corporate/overview',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBasicInfoThunk.fulfilled, (state, action) => {
      state.basicInfo = action.payload || {}
    })
  },
})

export const selectBasicInfoOverview = (state) => state[slice.name].basicInfo

register(slice.name, slice.reducer)
