import PropTypes from 'prop-types'
import { useState } from 'react'
import useCustomNavigate from '../../common/hooks/useCustomNavigate'
import { FEATURES } from '../../constants/routes'
import {
  getSearchItemMoreOptions,
  getStockIndicesSectorInfo,
  highlightString,
} from '../../utils/Search'
import { SEARCH_TYPE } from '../constants'
import style from './index.module.css'
import { SearchItemDropdown } from './SearchItemDropdown'

export const CorOverviewItem = (props) => {
  const { searchStr, data, getBasicInfo } = props
  const navigate = useCustomNavigate()

  const [isActive, setIsActive] = useState(false)

  const getTicker = () => {
    if (data.ticker) {
      return highlightString(data.ticker, data.highlight.ticker, searchStr)
    } else {
      return data.taxCode
    }
  }

  const getOrganizationShortName = () => {
    let organizationShortName = ''
    let organizationShortNameHighlight
    if (data.organizationShortName) {
      organizationShortName = data.organizationShortName
      organizationShortNameHighlight = data.highlight.organizationShortName
    } else if (data.en_OrganizationShortName) {
      organizationShortName = data.en_OrganizationShortName
      organizationShortNameHighlight = data.highlight.en_OrganizationShortName
    } else {
      return
    }
    return highlightString(
      organizationShortName,
      organizationShortNameHighlight,
      searchStr,
    )
  }

  const getOrganizationName = () => {
    let organizationName = ''
    let organizationNameHighlight
    if (data.organizationName) {
      organizationName = data.organizationName
      organizationNameHighlight = data.highlight.organizationName
    } else if (data.en_OrganizationName) {
      organizationName = data.en_OrganizationName
      organizationNameHighlight = data.highlight.en_OrganizationName
    } else {
      return
    }
    return highlightString(
      organizationName,
      organizationNameHighlight,
      searchStr,
    )
  }

  const onToggleMoreOption = (value) => {
    setIsActive(value)
  }

  const onRedirect = () => {
    navigate(
      FEATURES.corporate.components.overview.route,
      getStockIndicesSectorInfo(data, getBasicInfo),
    )
  }

  return (
    <a className={style['item-link']} onClick={onRedirect}>
      <div
        className={`d-flex align-center j-b search-content ${
          style['search-content']
        } ${isActive ? style['search-content-active'] : ''}`}
      >
        <div>
          <div className={style['item-title']}>
            {getTicker()}
            <span> - </span>
            {getOrganizationShortName()}
          </div>
          <div className="date">
            {data.exchangeCode && <span>{data.exchangeCode}/ </span>}
            <span>{getOrganizationName()}</span>
          </div>
        </div>
        <SearchItemDropdown
          data={data}
          options={getSearchItemMoreOptions(
            SEARCH_TYPE.COMPANY,
            data.groupCodes,
            FEATURES,
          )}
          onToggle={onToggleMoreOption}
          getBasicInfo={getBasicInfo}
        />
      </div>
    </a>
  )
}

CorOverviewItem.propTypes = {
  searchStr: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}
