import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import { LIST_TYPE } from '../../constant'
import { LOADING_TYPE, selectLoading, selectWatchlist } from '../../store/slice'
import { getTickerByWLId } from '../../store/thunk'
import style from './company.module.css'
import { getValueInTrillion, randomColor, randomCompanyColor } from './helper'

const WatchList = ({
  organizations,
  setOrganizations,
  groups,
  setGroups,
  filteredOrgIds,
  setFilteredOrgIds,
  exchanges,
  marketCap,
  keyword,
  handleOpenAlert,
}) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading(LOADING_TYPE.CHOOSE_ITEM))
  const loadingAddGroup = useSelector(selectLoading(LOADING_TYPE.ADD_GROUP))
  const watchlist = useSelector(selectWatchlist)

  const onCheckboxChange = (e, wl) => {
    const checked = e.target.checked

    if (checked) {
      const params = { WatchListId: wl.watchListId, WithMarketCap: true }
      dispatch(getTickerByWLId(params))
        .unwrap()
        .then((data) => {
          const { watchListId, details: orgs } = data

          const validOrgIds = orgs
            .filter(
              (org) =>
                exchanges.includes(org.exchangeCode) &&
                (getValueInTrillion(marketCap.from) || 0) <=
                  (org.marketCap || 0) &&
                (getValueInTrillion(marketCap.to) || Infinity) >=
                  (org.marketCap || 0),
            )
            .map((org) => org.organizationId)

          if (validOrgIds.length === 0) {
            handleOpenAlert()
            return
          }

          const color = randomColor(organizations)
          const newGroups = JSON.parse(JSON.stringify(groups))
          newGroups.push({
            type: LIST_TYPE.WATCHLIST,
            id: watchListId,
            color,
          })
          setGroups(newGroups)

          const newOrganizations = JSON.parse(JSON.stringify(organizations))
          orgs.forEach((org) => {
            let prevGroups = []
            const oldOrg = newOrganizations[org.organizationId]
            if (oldOrg) {
              prevGroups = [
                ...oldOrg.prevGroups,
                {
                  type: oldOrg.type,
                  id: oldOrg.typeId,
                  color: oldOrg.color,
                },
              ]
            }

            newOrganizations[org.organizationId] = {
              type: LIST_TYPE.WATCHLIST,
              typeId: watchListId,
              organizationId: org.organizationId,
              color,
              companyColor: randomCompanyColor(newOrganizations),
              prevGroups,
              marketCap: org.marketCap || 0,
              exchange: org.exchangeCode,
            }
          })
          setOrganizations(newOrganizations)
          setFilteredOrgIds([
            ...filteredOrgIds.filter((id) => !validOrgIds.includes(id)),
            ...validOrgIds,
          ])
        })
    } else {
      const newOrganizations = JSON.parse(JSON.stringify(organizations))
      let newFilteredOrgIds = [...filteredOrgIds]
      Object.keys(organizations).forEach((orgId) => {
        const org = newOrganizations[orgId]

        if (
          org?.type === LIST_TYPE.WATCHLIST &&
          org?.typeId === wl.watchListId
        ) {
          if (org.prevGroups?.length) {
            const lastGroup = org.prevGroups.pop()
            org.type = lastGroup.type
            org.typeId = lastGroup.id
            org.color = lastGroup.color

            if (
              !newFilteredOrgIds.includes(org.organizationId) &&
              exchanges.includes(org.exchangeCode) &&
              (getValueInTrillion(marketCap.from) || 0) <=
                (org.marketCap || 0) &&
              (getValueInTrillion(marketCap.to) || Infinity) >=
                (org.marketCap || 0)
            ) {
              newFilteredOrgIds.push(org.organizationId)
            }
          } else {
            delete newOrganizations[orgId]
            newFilteredOrgIds = newFilteredOrgIds.filter(
              (id) => id !== Number(orgId),
            )
          }
        } else if (org.prevGroups?.length) {
          const groupIndex = org.prevGroups.findIndex(
            (group) =>
              group.type === LIST_TYPE.WATCHLIST && group.id === wl.watchListId,
          )
          if (groupIndex !== -1) {
            org.prevGroups.splice(groupIndex, 1)
          }
        }
      })
      setOrganizations(newOrganizations)
      setFilteredOrgIds(newFilteredOrgIds)

      const newGroups = groups.filter(
        (group) =>
          !(group.type === LIST_TYPE.WATCHLIST && group.id === wl.watchListId),
      )
      setGroups(newGroups)
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : watchlist.length === 0 ? (
        <NoData />
      ) : (
        <ScrollComponent>
          <ul>
            {[...watchlist]
              .sort((a, b) =>
                keyword
                  ? b.watchListName
                      ?.toLowerCase()
                      .indexOf(keyword.toLowerCase()) -
                    a.watchListName
                      ?.toLowerCase()
                      .indexOf(keyword.toLowerCase())
                  : 1,
              )
              .map((wl) => {
                const isCheck =
                  groups.findIndex(
                    (group) =>
                      group.type === LIST_TYPE.WATCHLIST &&
                      group.id === wl.watchListId,
                  ) === -1
                    ? false
                    : true
                const disabled = loadingAddGroup

                return (
                  <li
                    key={wl.watchListId}
                    className={`${style.tickerItem} ${
                      disabled ? style.disabled : ''
                    }`}
                  >
                    <label>
                      <div className={style.checkBox}>
                        <input
                          type="checkbox"
                          className="checkbox line"
                          checked={isCheck}
                          disabled={disabled}
                          onChange={(e) => onCheckboxChange(e, wl)}
                        />
                      </div>
                      <div className="flex-1">
                        <TextEllipsis isI18n={false} text={wl.watchListName} />
                      </div>
                    </label>
                  </li>
                )
              })}
          </ul>
        </ScrollComponent>
      )}
    </>
  )
}

export default WatchList
