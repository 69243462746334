import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getFinancialMetricsChartData } from './thunk'

const initialState = {
  data: [],
  loading: true,
}

const slice = createSlice({
  name: 'corporate/overview/toi',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getFinancialMetricsChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getFinancialMetricsChartData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload || []
    })
    builder.addCase(getFinancialMetricsChartData.rejected, (state, action) => {
      state.loading = action.payload.isLoading
    })
  },
})

export const selectTimelineChart = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
