import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../../core/services/market/marketInDepth/IndexStatisticService'
import { handleExport } from '../../../../../../utils/Export'
import { PRICE_DATA_TIME_SELECT } from '../../../constants'

export const getIndexOrderStatisticsOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/orderStatistic/GET_INDEX_ORDER_STATISTICS_OVERVIEW',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await IndexStatisticService.getIndexOrderStatisticsOverview(params)
      if (response.success) {
        if (params.TimeFilter === PRICE_DATA_TIME_SELECT.CUSTOM) {
          dispatch(getIndexStatisticsOrderStatisticsOverviewTotal(params))
        }
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexOrderStatisticsDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/orderStatistic/GET_INDEX_ORDER_STATISTICS_DETAIL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexOrderStatisticsDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadIndexOrderStatisticsOverview = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/orderStatistic/DOWNLOAD_INDEX_ORDER_STATISTICS_OVERVIEW',
  async (data) => {
    const response =
      await IndexStatisticService.downloadIndexOrderStatisticsOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadIndexOrderStatisticsDetail = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/orderStatistic/DOWNLOAD_INDEX_ORDER_STATISTICS_DETAIL',
  async (data) => {
    const response =
      await IndexStatisticService.downloadIndexOrderStatisticsDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexStatisticsOrderStatisticsOverviewTotal = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/orderStatistic/GET_INDEX_STATISTICS_ORDER_STATISTIC_OVERVIEW_TOTAL',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getIndexStatisticsOrderStatisticsOverviewTotal(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
