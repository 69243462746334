import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../common/html/Span'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  CALLBACK_CLOSE_SHOW_POPUP_ERROR,
  MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR,
  MODAL_BUTTON_STYLE_POPUP_ERROR,
  MODAL_STYLE_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../../utils/EventEmitter'
import { useGetNameSecuritiesIndicator } from '../chart/common/useGetNameSecuritiesIndicator'
import { ReactComponent as IconDownloadCsvBlack } from '../icon/icon-download-csv-black.svg'
import { selectIndicatorsLatestPeriod } from '../store/slice'
import { downloadDataExport } from '../store/thunk'
import { TIME_RANGE } from './constants'
import { getCsvHeaders, getCsvParams } from './helpers/tableDownloadCsvHelpers'

export const useTableDownloadCsv = () => {
  const dispatch = useDispatch()

  const { getSecuritiesName, getIndicatorNameTimeFrequency } =
    useGetNameSecuritiesIndicator()

  const onDownloadCsv = ({ chartSettings, indicatorLatestPeriod }) => {
    const header = getCsvHeaders({
      chartSettings,
      indicatorLatestPeriod,
      getSecuritiesName,
      getIndicatorNameTimeFrequency,
    })

    const parameters = getCsvParams({ chartSettings, indicatorLatestPeriod })
    if (parameters) {
      dispatch(
        downloadDataExport({
          parameters: [
            {
              chartName: chartSettings.nameChart.name,
              timeFrequency: chartSettings.timeFrequency,
              timeRange:
                chartSettings.timeRangeDate.dateFrom ||
                chartSettings.timeRangeDate.dateTo
                  ? TIME_RANGE.ALL_TIME
                  : chartSettings.timeRange,
              from: chartSettings.timeRangeDate.dateFrom,
              to: chartSettings.timeRangeDate.dateTo,
              header,
              parameters,
            },
          ],
        }),
      )
    }
  }

  return { onDownloadCsv, IconDownloadCsv }
}

const IconDownloadCsv = ({ chartSettings, style, disable }) => {
  const { onDownloadCsv } = useTableDownloadCsv()

  const indicatorLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const haveEquations = useMemo(
    () => Object.keys(chartSettings.equations).length > 0,
    [chartSettings.equations],
  )

  const handleDownload = () => {
    if (haveEquations) {
      const callback = () => () =>
        onDownloadCsv({ chartSettings, indicatorLatestPeriod })

      EventEmitter.dispatch(CALLBACK_CLOSE_SHOW_POPUP_ERROR, callback)
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(MODAL_STYLE_POPUP_ERROR, {
        backgroundColor: '#ffffff',
        color: '#4E4E4E',
        fontWeight: 400,
      })
      EventEmitter.dispatch(MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR, {
        width: '100%',
      })
      EventEmitter.dispatch(MODAL_BUTTON_STYLE_POPUP_ERROR, {
        backgroundColor: '#2f9aee',
        color: '#ffffff',
        fontWeight: 500,
        padding: '3px 10px',
        margin: '-24px auto',
      })
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="financialChart.DOWNLOAD_CSV_NOTE" />,
      ])
    } else {
      onDownloadCsv({ chartSettings, indicatorLatestPeriod })
    }
  }

  return (
    <Span
      style={{
        width: 16,
        height: 16,
        pointerEvents: disable ? 'none' : 'unset',
        opacity: disable ? 0.4 : 1,
        ...style,
      }}
    >
      <IconDownloadCsvBlack
        title={I18n.t('financialChart.DOWNLOAD_CSV')}
        style={{ cursor: 'pointer' }}
        onClick={handleDownload}
      />
    </Span>
  )
}

IconDownloadCsv.defaultProps = {
  style: {},
}
