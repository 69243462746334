export const COMPANY_GROWTH_LINE_KEYS = [
  'productionBarGrowth',
  'productionShapeGrowth',
  'productionWireRodGrowth',
  'totalProductionGrowth',

  'consumptionBarGrowth',
  'consumptionShapeGrowth',
  'consumptionWireRodGrowth',
  'totalConsumptionGrowth',

  'inventoryBarGrowth',
  'inventoryShapeGrowth',
  'inventoryWireRodGrowth',
  'totalInventoryGrowth',
]

export const TITLE_LINE_KEYS = [
  'sector.sectorSpecific.construction.STEEL_BAR',
  'sector.sectorSpecific.construction.STEEL_SHAPE',
  'sector.sectorSpecific.construction.STEEL_WIRE_ROD',
  'sector.sectorSpecific.construction.TOTAL',
]

export const LINE_COLORS = {
  WHITE_GREEN: '#ecffdf',
  PURPLE: '#9c54e4',
  GREEN: '#c7ff58',
  ORANGE: '#f57f07',
}
