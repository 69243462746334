import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TotalGDPService from '../../../../../../core/services/economy/gdp/TotalGDPService'
import { handleExport } from '../../../../../utils/Export'

export const getGDPGrowthForecast = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPGrowthForecast/getGDPGrowthForecast',
  async (params, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getGDPGrowthForecast(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadGDPGrowthForecast = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPGrowthForecast/downloadGDPGrowthForecast',
  async (data) => {
    const response = await TotalGDPService.downloadGDPGrowthForecast(data)
    handleExport(response.data, response.filename)
  },
)
