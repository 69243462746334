import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import IndexStatisticService from '../../../../../../core/services/market/marketInDepth/IndexStatisticService'

export const getStatisticsValueByInvestorChart = createAsyncThunk(
  'market/marketInDepth/indexStatistics/summary/valueInvestor/GET_STATISTICS_VALUE_BY_INVESTOR_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await IndexStatisticService.getStatisticsValueByInvestorChart(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
