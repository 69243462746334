export const getMonthAndYearInDataRange = (dataRange) => {
  const allMonthsAndYears = dataRange?.map((item) => ({
    month: item.month,
    year: item.year,
  }))

  const uniqueMonthsAndYears = Object.values(
    allMonthsAndYears.reduce((acc, curr) => {
      acc[curr.month + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  return uniqueMonthsAndYears
}

export const getMonthInDataRange = (data) => {
  const allMonths = data?.map((item) => item.month)
  const uniqueMonths = [...new Set(allMonths)].sort((prev, curr) => prev - curr)

  return uniqueMonths.map((item) => ({ month: item }))
}

export const getYearInDataRange = (data) => {
  const allYears = data?.map((item) => item.year)
  const uniqueYears = [...new Set(allYears)]

  return uniqueYears.map((item) => ({ year: item }))
}

export const getDataByMonthAndYear = (data, month, year) => {
  return data
    ?.filter((item) => item.month === month && item.year === year)
    ?.map((item) => ({
      value: item.value,
      id: item.id,
    }))
}

export const getDataByYear = (data, year) => {
  return data
    ?.filter((item) => item.year === year)
    ?.map((item) => ({
      value: item.value,
      id: item.id,
    }))
}

export const getMonthAndYearKey = (month, year) => {
  return month < 10 ? `0${month}-${year}` : `${month}-${year}`
}

export const getMonthKey = (month) => {
  return month < 10 ? `Th0${month}` : `Th${month}`
}

export const getAccMonthAndYearKey = (month, year, locale) => {
  if (locale === 'en') {
    return month === 1 ? `Jan-${year}` : `${month}M-${year}`
  } else {
    return month === 1 ? `01-${year}` : `${month}Th-${year}`
  }
}

export const getYearKey = (year, maxDate, locale) => {
  const maxYear = maxDate && maxDate?.realYear
  const maxMonth = maxDate && maxDate?.realMonth
  return year === maxYear && maxMonth < 12
    ? getAccMonthAndYearKey(maxMonth, year, locale)
    : `${year}`
}
