export const encodeSizePanel = (width, height) => {
  return `${width}-${height}`
}

export const decodeSizePanel = (str) => {
  const [width, height] = str.split('-')
  return {
    width: +width,
    height: +height,
  }
}
