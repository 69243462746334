import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class DirectorDealProxy extends ProxyBase {
  getNetValueInternalTrading(params) {
    return this.get('GetNetValueInternalTrading', params)
  }

  getValueNetBreakDown(params) {
    return this.get('GetValueNetBreakDown', params)
  }

  getDirectorDeals(params) {
    return this.post('GetDirectorDeals_Paged', params)
  }

  getActionTypes(params) {
    return this.get('GetActionTypes', params)
  }

  getActionStatus(params) {
    return this.get('GetActiveStatus', params)
  }

  downloadInfoDirectorDeals(params) {
    return this.post('DownloadDirectorDeals', params, {}, 'download')
  }
}

export default new DirectorDealProxy(
  ServiceProxyConfig.Market.MarketInDepth.ServiceUrl,
)
