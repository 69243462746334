import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import TextEllipsis from '../../../../common/textEllipsis'
import { LIST_TYPE } from '../../../common/constants'
import {
  changeEquationSecurities,
  selectEquationLoading,
  selectEquationSecurities,
  selectWatchlist,
} from '../store/slice'
import { getTickerByWLId, getWatchList } from '../store/thunk'
import style from './style.module.css'

const WatchList = ({ valueSearch }) => {
  const dispatch = useDispatch()

  const watchList = useSelector(selectWatchlist)
  const equationSecurities = useSelector(selectEquationSecurities)
  const loading = useSelector(selectEquationLoading)

  const isDisabledWatchlist = (item) => {
    // if (
    //   item.organizationCount > 30 ||
    //   (equationSecurities.companies &&
    //     item.organizationCount + equationSecurities.companies.length > 30)
    // ) {
    //   return true
    // }

    return false
  }

  const handleUpdateCompanies = (validOrgIds, isCheck) => {
    let newCompanies = equationSecurities.companies || []

    if (isCheck) {
      newCompanies = [...new Set([...newCompanies, ...validOrgIds])]
    } else {
      newCompanies = equationSecurities.companies.filter(
        (val) => validOrgIds.findIndex((id) => id === val) < 0,
      )
    }

    dispatch(
      changeEquationSecurities({
        value: newCompanies,
        type: LIST_TYPE.COMPANIES,
      }),
    )
  }

  const handleUpdateWatchlist = (id, isCheck) => {
    let newWatchlist = equationSecurities.watchList || []

    if (isCheck) {
      newWatchlist = [...new Set([...newWatchlist, id])]
    } else {
      newWatchlist = newWatchlist.filter((val) => val !== id)
    }

    dispatch(
      changeEquationSecurities({
        value: newWatchlist,
        type: LIST_TYPE.WATCH_LIST,
      }),
    )
  }

  const onCheckboxChange = (e, wl) => {
    const checked = e.target.checked

    if (isDisabledWatchlist(wl)) {
      return
    }

    const params = { WatchListId: wl.watchListId, WithMarketCap: true }

    dispatch(getTickerByWLId(params))
      .unwrap()
      .then((data) => {
        const { details: orgs } = data
        const validOrgIds = orgs.map((org) => org.organizationId)
        handleUpdateCompanies(validOrgIds, checked)
        handleUpdateWatchlist(wl.watchListId, checked)
      })
  }

  useEffect(() => {
    dispatch(getWatchList())
  }, [])

  return (
    <>
      {loading ? (
        <Loading isBlack />
      ) : watchList.length === 0 ? (
        <NoData />
      ) : (
        <ScrollComponent bgColor="#D9D9D9">
          <ul style={{ paddingLeft: 0, marginTop: 3 }}>
            {[...watchList]
              .filter((i) =>
                i.watchListName?.toLowerCase().includes(valueSearch),
              )
              .sort((a, b) =>
                valueSearch
                  ? b.watchListName
                      ?.toLowerCase()
                      .indexOf(valueSearch.toLowerCase()) -
                    a.watchListName
                      ?.toLowerCase()
                      .indexOf(valueSearch.toLowerCase())
                  : 1,
              )
              .map((wl) => (
                <li key={wl.watchListId}>
                  <label
                    className="d-flex ali-center"
                    style={{
                      justifyContent: 'space-between',
                      cursor: 'pointer',
                      opacity: isDisabledWatchlist(wl) ? 0.5 : 1,
                    }}
                  >
                    <div className={style.checkBox}>
                      <input
                        type="checkbox"
                        className="checkbox line"
                        checked={
                          equationSecurities?.watchList?.some(
                            (watchListId) => watchListId === wl.watchListId,
                          ) || false
                        }
                        onChange={(e) => onCheckboxChange(e, wl)}
                      />
                    </div>
                    <div
                      className="flex-1"
                      style={{ padding: '8px 0', color: '#4e4e4e' }}
                    >
                      <TextEllipsis
                        isI18n={false}
                        text={wl.watchListName}
                        zIndexTooltip={12}
                      />
                    </div>
                  </label>
                </li>
              ))}
          </ul>
        </ScrollComponent>
      )}
    </>
  )
}

export default WatchList
