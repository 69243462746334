import { hierarchy, treemap } from 'd3-hierarchy'
import memoize from 'fast-memoize'
import PropTypes from 'prop-types'
import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { formatVal } from '../../../utils/Value'
import { selectIsLoading } from '../store/slice'
import { MAX_FONT_SIZE, MIN_FONT_SIZE } from './constants'
import { fontSizeFitWidth, setZeroWhenMinimum } from './helper'
import { Sector } from './Sector'

const memoizedCalcFontSize = memoize(fontSizeFitWidth)

export const TreeMap = ({ data, width, height }) => {
  const getBgColor = (value) => {
    if (value < 0) {
      return '#e0505b'
    }

    if (value > 0) {
      return '#3da967'
    }

    return '#f3b662'
  }

  const formatData = (sectors) => {
    return {
      name: '',
      children: sectors.map((sector) => ({
        ...sector,
        rate: setZeroWhenMinimum(sector.displayValue),
      })),
    }
  }

  const getRoot = (width, height, data) => {
    const root = hierarchy(data)
      .sum((d) => d.value)
      .sort((a, b) => b.value - a.value)

    treemap().size([width, height]).paddingInner(4)(root)

    return root
  }

  const isLoading = useSelector(selectIsLoading)
  const root = getRoot(width, height, formatData(data))
  const rootRef = useRef()

  const getStyle = () => {
    return isLoading
      ? {
          opacity: 0.4,
          pointerEvents: 'none',
        }
      : {
          opacity: 1,
          pointerEvents: 'all',
        }
  }

  return (
    <div
      ref={rootRef}
      className="position-relative"
      style={{
        width,
        height,
        ...getStyle(),
      }}
    >
      {isLoading && <Loading />}
      {root.leaves().map((node, index) => {
        const nodeWidth = node.x1 - node.x0
        const nodeHeight = node.y1 - node.y0
        const name = node.data.name ? node.data.name.toUpperCase() : ''
        const bgColor = getBgColor(node.data.rate)
        const fontSize = memoizedCalcFontSize({
          width: nodeWidth,
          height: nodeHeight,
          max: MAX_FONT_SIZE,
          min: MIN_FONT_SIZE,
          mainText: name,
          subText: formatVal(node.data.rate),
        })

        return (
          <Sector
            key={index}
            width={nodeWidth}
            height={nodeHeight}
            data={node.data}
            bgColor={bgColor}
            fontSize={fontSize}
            rootRef={rootRef}
            node={node}
            rootSize={{ width, height }}
          />
        )
      })}
    </div>
  )
}

TreeMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
