import { Translate } from 'react-redux-i18n'
import useGetStock from '../../../../common/masterData/useGetStock'
import DropdownCompany from './DropdownCompany'

const Sector = () => {
  const { stocks, loading } = useGetStock()

  return (
    <>
      <Translate
        value="market.event.COMPANY"
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />
      <div className="input-dropdown mb-8">
        <DropdownCompany loading={loading} stocks={stocks} />
      </div>
    </>
  )
}

export default Sector
