import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../../../core/services/economy/importExport/OverviewService'

export const getTopTradePartner = createAsyncThunk(
  'economy/importExport/outstandingTarget/topTradePartner',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getTopTradePartner(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
