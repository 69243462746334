import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import Tree from '../../../common/tree'
import { selectLoadingIndicators } from '../../store/slice'
import style from './style.module.css'

const SCROLL_DIV_ID = 'SCROLL_CATEGORY_TREE'

const Category = ({ treeData, category, setCategory, keyword }) => {
  const loading = useSelector(selectLoadingIndicators)

  const handleClick = (item) => {
    if (!item) {
      setCategory(null)
    } else if (Object.keys(item.children).length === 0) {
      setCategory(item.id)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      const scrollTree = document.querySelector(
        `#${SCROLL_DIV_ID} .scrollbars div:first-child`,
      )
      const activeIndicator = scrollTree?.querySelector(`[id="${category}"]`)
      if (
        scrollTree &&
        activeIndicator &&
        activeIndicator.offsetTop + activeIndicator.offsetHeight >
          scrollTree.offsetHeight
      ) {
        scrollTree.scrollTo({
          top: activeIndicator.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }, 300)
  }, [])

  return (
    <div className={style.category}>
      <div className={style.categoryList}>
        {loading ? (
          <Loading isBlack />
        ) : Object.keys(treeData).length ? (
          <>
            {!!keyword.trim() && (
              <div
                style={{
                  padding: '5px 18px',
                  color: category ? '#4E4E4E' : '#2f9aee',
                  cursor: 'pointer',
                }}
                onClick={() => handleClick()}
              >
                <Translate value="common.ALL" />
              </div>
            )}
            <Tree
              defaultOpenLevel1
              treeData={treeData}
              idKey={'id'}
              nameKey="name"
              listHightlight={[category]}
              itemDefaultOpen={[category]}
              disabledCheckDbClick
              onClickItem={handleClick}
              doubleClickItem={undefined}
              bgColorScroll="#D9D9D9"
              classItemStyle={style.itemStyle}
              scrollDivId={SCROLL_DIV_ID}
            />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

export default Category
