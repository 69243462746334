import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  changValuePopupFinancialStatement,
  selectPopupFinancialStatement,
} from '../store/slice'
import style from '../style.module.css'
import DropdownQuarter from './DropdownQuarter'
import DropdownYear from './DropdownYear'

const SelectReportingPeriod = () => {
  const dispatch = useDispatch()
  const popupFinancialStatement = useSelector(selectPopupFinancialStatement)

  const changeYearQuarter = (val, key) => {
    dispatch(
      changValuePopupFinancialStatement({
        ...popupFinancialStatement,
        [key]: val,
      }),
    )
  }

  return (
    <div className={style.divRight}>
      <div
        className="d-flex mt-16 mb-8"
        style={{
          borderBottom: 'solid 1px #383c43',
          paddingBottom: '12px',
          marginRight: '22px',
        }}
      >
        <div className={`${style.opacity40} mr-8`}>
          <Translate value="corporate.companySnapshot.YEAR" />
        </div>
        <DropdownYear
          yearSelect={popupFinancialStatement.year1}
          setYear={(val) => changeYearQuarter(val, 'year1')}
        />
        <div className={`${style.opacity40} mr-8 ml-8`}>;</div>
        <DropdownYear
          yearSelect={popupFinancialStatement.year2}
          setYear={(val) => changeYearQuarter(val, 'year2')}
        />
        <div className={`${style.opacity40} mr-8 ml-8`}>;</div>
        <DropdownYear
          yearSelect={popupFinancialStatement.year3}
          setYear={(val) => changeYearQuarter(val, 'year3')}
        />
      </div>
      <div className="d-flex">
        <div className={`${style.opacity40} mr-8 mt-8`}>
          <Translate value="corporate.companySnapshot.QUARTER" />
        </div>
        <div>
          <div className="d-flex mt-8">
            <DropdownQuarter
              quarterSelect={popupFinancialStatement.q_quarter1}
              setQuarter={(val) => changeYearQuarter(val, 'q_quarter1')}
            />
            <div className={`${style.opacity40} mr-8 ml-8`}>-</div>
            <DropdownYear
              yearSelect={popupFinancialStatement.q_year1}
              setYear={(val) => changeYearQuarter(val, 'q_year1')}
            />
          </div>
          <div className="d-flex mt-16">
            <DropdownQuarter
              quarterSelect={popupFinancialStatement.q_quarter2}
              setQuarter={(val) => changeYearQuarter(val, 'q_quarter2')}
            />
            <div className={`${style.opacity40} mr-8 ml-8`}>-</div>
            <DropdownYear
              yearSelect={popupFinancialStatement.q_year2}
              setYear={(val) => changeYearQuarter(val, 'q_year2')}
            />
          </div>
          <div className="d-flex mt-16">
            <DropdownQuarter
              quarterSelect={popupFinancialStatement.q_quarter3}
              setQuarter={(val) => changeYearQuarter(val, 'q_quarter3')}
            />
            <div className={`${style.opacity40} mr-8 ml-8`}>-</div>
            <DropdownYear
              yearSelect={popupFinancialStatement.q_year3}
              setYear={(val) => changeYearQuarter(val, 'q_year3')}
            />
          </div>
          <div className="d-flex mt-16">
            <DropdownQuarter
              quarterSelect={popupFinancialStatement.q_quarter4}
              setQuarter={(val) => changeYearQuarter(val, 'q_quarter4')}
            />
            <div className={`${style.opacity40} mr-8 ml-8`}>-</div>
            <DropdownYear
              yearSelect={popupFinancialStatement.q_year4}
              setYear={(val) => changeYearQuarter(val, 'q_year4')}
            />
          </div>
          <div className="d-flex mt-16">
            <DropdownQuarter
              quarterSelect={popupFinancialStatement.q_quarter5}
              setQuarter={(val) => changeYearQuarter(val, 'q_quarter5')}
            />
            <div className={`${style.opacity40} mr-8 ml-8`}>-</div>
            <DropdownYear
              yearSelect={popupFinancialStatement.q_year5}
              setYear={(val) => changeYearQuarter(val, 'q_year5')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectReportingPeriod
