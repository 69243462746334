export const valueTab = {
  Realtime: 'Realtime',
  History: 'History',
}

export const listTab = [
  {
    title: 'bond.bondSecurity.order.filter.tab.Realtime',
    value: valueTab.Realtime,
  },
  {
    title: 'bond.bondSecurity.order.filter.tab.History',
    value: valueTab.History,
  },
]

export const valueOption = {
  Deal: 'Deal',
  Match: 'Match',
}

export const listOption = [
  {
    name: 'common.ALL',
    value: '',
  },
  {
    name: 'bond.bondSecurity.order.filter.tradingType.Deal',
    value: valueOption.Deal,
  },
  {
    name: 'bond.bondSecurity.order.filter.tradingType.Match',
    value: valueOption.Match,
  },
]

export const ISSUE_METHOD_TYPES = {
  PUBLIC: 6,
  PRIVATE: 5,
}

export const groupColumn = [
  {
    key: 'tradingDate',
    title: 'bond.bondSecurity.order.table.Time',
    attrs: {},
  },
  {
    key: 'dirtyPrice',
    title: 'bond.bondSecurity.order.table.DirtyPrice',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'cleanPrice',
    title: 'bond.bondSecurity.order.table.CleanPrice',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'priceChange',
    title: 'bond.bondSecurity.order.table.PriceChange',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'percentPriceChange',
    title: 'bond.bondSecurity.order.table.percentPriceChange',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'ytm',
    title: 'bond.bondSecurity.order.table.YTM',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'ytmChange',
    title: 'bond.bondSecurity.order.table.sumYTM',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'volume',
    title: 'bond.bondSecurity.order.table.Volume',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'totalVolume',
    title: 'bond.bondSecurity.order.table.TotalVolume',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'value',
    title: 'bond.bondSecurity.order.table.Value',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'totalValue',
    title: 'bond.bondSecurity.order.table.TotalValue',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
]

export const groupColumnHistory = [
  {
    key: 'tradingDate',
    title: 'bond.bondSecurity.order.table.Time',
    attrs: {},
  },
  {
    key: 'dirtyPrice',
    title: 'bond.bondSecurity.order.table.DirtyPrice',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'cleanPrice',
    title: 'bond.bondSecurity.order.table.CleanPrice',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'priceChange',
    title: 'bond.bondSecurity.order.table.PriceChange',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'percentPriceChange',
    title: 'bond.bondSecurity.order.table.percentPriceChange',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'ytm',
    title: 'bond.bondSecurity.order.table.YTM',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
  {
    key: 'ytmChange',
    title: 'bond.bondSecurity.order.table.sumYTM',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },

  {
    key: 'totalVolume',
    title: 'bond.bondSecurity.order.table.TotalVolume',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },

  {
    key: 'totalValue',
    title: 'bond.bondSecurity.order.table.TotalValue',
    attrs: {
      style: {
        textAlign: 'right',
      },
    },
  },
]
