import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import TdColor from '../../TdColor'
import { priceDataTableCol, tableFormatValue } from './constants'

const getValue = (val, colId) => {
  return val / tableFormatValue[colId]
}

const getFormatDateTime = (val, type, locale, timeZone) => {
  switch (type) {
    case TIME_RANGES.OneTick:
      return formatDateTime(val, FORMAT.TIME, locale, timeZone)
    case TIME_RANGES.OneMinute:
    case TIME_RANGES.FiveMinutes:
      return formatDateTime(val, FORMAT.TIME_HOUR_MINUTE, locale, timeZone)
    case TIME_RANGES.OneMonth:
    case TIME_RANGES.ThreeMonths:
    case TIME_RANGES.SixMonths:
    case TIME_RANGES.YearToDate:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
    default:
      return formatDateTime(val, FORMAT.DATE, locale, timeZone)
  }
}

export const getTableSchema = (
  { dataType, timeRange },
  locale,
  timeZone,
  selectTableDataCell,
  isTextBlink,
  ids,
) =>
  Object.keys(priceDataTableCol[dataType]).map((key) => {
    const title = `market.marketInDepth.indices.${key}`
    const colId = priceDataTableCol[dataType][key]
    const result = {
      colId,
      title,
    }

    if (colId === priceDataTableCol[dataType].INTRADAY_TRADING_DATE) {
      return {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 10,
          fontWeight: 'bold',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => {
          const date = getFormatDateTime(val, timeRange, locale, timeZone)
          return date === EMPTY_VALUE ? val : date
        },
      }
    }

    const customSchema = {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        fontSize: 10,
        fontWeight: 'bold',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (
      [
        priceDataTableCol[dataType].INTRADAY_CLOSE_INDEX,
        priceDataTableCol[dataType].INTRADAY_PERCENT_INDEX_CHANGE,
      ].includes(colId)
    ) {
      if (colId === priceDataTableCol[dataType].INTRADAY_PERCENT_INDEX_CHANGE) {
        customSchema.title = '%'
      }

      return {
        ...customSchema,
        canCustomTd: true,
        title: title,
        render: (value, rowId, { style, className }) => (
          <TdColor
            value={
              colId ===
              priceDataTableCol[dataType].INTRADAY_PERCENT_INDEX_CHANGE
                ? valToPercent(value)
                : value
            }
            colId={rowId}
            keyColor={priceDataTableCol[dataType].INTRADAY_PERCENT_INDEX_CHANGE}
            selectCell={selectTableDataCell}
            isPercent={
              colId ===
              priceDataTableCol[dataType].INTRADAY_PERCENT_INDEX_CHANGE
            }
            isTextBlink={isTextBlink && rowId === ids[0]}
            style={style}
            className={className}
          />
        ),
      }
    }

    return {
      ...customSchema,
      render: (val) => formatVal(getValue(val, colId), 0),
    }
  })
