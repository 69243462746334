import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import Dropdown from '../../../common/dropdown'
import UseI18n from '../../../common/hooks/useI18n'
import { Button } from '../../../common/html/Button'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import useGetICB from '../../../common/masterData/useGetICB'
import TextEllipsis from '../../../common/textEllipsis'
import Tree from '../../../common/tree'
import { getAllIdItem, getAllParentChecked } from '../../../common/tree/helper'
import { Z_INDEX } from '../../../constants/Common'
import { handleDuplicateArray, keyBy } from '../../../utils/Common'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../utils/EventEmitter'
import { withFilterSectorActions } from '../Hoc/withFilterSectorActions'
import {
  selectFilterParams,
  selectInitialSectors,
  selectParentSectors,
  setParentSector,
} from '../store/slice'
import style from './index.module.css'

const FilterSectorSelect = ({
  list,
  parentList,
  isShowDropdown,
  setList,
  setParentList,
  setIsShowDropdown,
  handleChange,
}) => {
  const dispatch = useDispatch()
  const parentRef = useRef()
  const dropdownRef = useRef()
  const { ICBsTree, ICBs, loading, ICBTreeNotDeleteChildren } = useGetICB(
    true,
    true,
    undefined,
    undefined,
    true,
  )

  // Use selector
  const { sectors = [] } = useSelector(selectFilterParams)
  const initialSectors = useSelector(selectInitialSectors)
  const listParent = useSelector(selectParentSectors)

  const [valueSearch, setValueSearch] = useState('')

  const text = useMemo(() => {
    const newList = list.filter((item) => !parentList.includes(item))

    return isShowDropdown ? (
      !!newList.length && !!initialSectors.length ? (
        newList.length === initialSectors.length ? (
          <Translate value="bond.corporateBond.priceBoard.ALL" />
        ) : (
          <TextEllipsis
            text={parentList
              .map((key) => keyBy(ICBs, 'icbCode')[key]?.icbName)
              .join(', ')}
            zIndexTooltip={999}
          />
        )
      ) : (
        <Span style={{ opacity: 0 }}>
          <Translate value="bond.bondScreening.common.INDUSTRY" />
        </Span>
      )
    ) : (
      <Span>
        <Translate value="bond.bondScreening.common.INDUSTRY" />
      </Span>
    )
  }, [isShowDropdown, list, initialSectors, parentList])

  // Actions
  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => {
    setIsShowDropdown(false)
    setList(sectors)
    setParentList(listParent)
    setValueSearch('')
  }

  const setListChecked = (listChecked) => {
    setList(listChecked.filter((item) => !parentList.includes(item)))
  }

  const setParentChecked = (listChecked) => {
    setParentList(listChecked)
  }

  // Use effect
  useEffect(() => {
    const sectorIcbCodes = keyBy(ICBs, 'icbCode')
    const sectorIcbIds = keyBy(ICBs, 'icbId')

    const childIds = handleDuplicateArray(
      sectors.reduce((arr, item) => {
        const idsChildren = getAllIdItem(
          ICBTreeNotDeleteChildren[sectorIcbCodes[item].icbId],
          'icbId',
        )
        return arr.concat(idsChildren)
      }, []),
    )

    const childSectors = handleDuplicateArray(
      childIds.reduce(
        (arr, id) =>
          sectorIcbIds[id] ? [...arr, sectorIcbIds[id].icbCode] : arr,
        [],
      ),
    )

    setList(childSectors)

    const parent = getAllParentChecked(ICBsTree, 'icbCode', childSectors)

    dispatch(setParentSector(parent))
  }, [JSON.stringify(sectors)])

  return (
    <div className={`input-dropdown ${style.input}`} ref={parentRef}>
      <span
        className={`span-input ${style.inputDropdown} `}
        style={{
          padding: '2px 0',
          width: '100%',
          gap: 8,
          height: '100%',
          boxSizing: 'border-box',
        }}
        ref={dropdownRef}
        onClick={handleDropdown}
      >
        <div style={{ flex: 1, overflow: 'hidden' }}>{text}</div>
        <i
          className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'}
          style={{ position: 'static' }}
        />
      </span>
      <Dropdown isShow={true} parentRef={parentRef}>
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={dropdownRef}
        >
          <div
            className={`wrapper-tool ${style.mt3}`}
            style={{
              width: `${parentRef.current?.offsetWidth}px`,
              display: isShowDropdown ? 'block' : 'none',
            }}
          >
            <div className="input-dropdown mb-8">
              <div
                className={`dropdown-container active w-100 ${style.filterDropdown}`}
              >
                <div className={`form-search-inbox m-0 ${style.pb5}`}>
                  <Input
                    type="text"
                    className={`search-inbox h-20 ${style.search}`}
                    placeholder={UseI18n('common.SEARCH')}
                    value={valueSearch}
                    onChange={handleChangeInput}
                  />
                  <button className={style.btnSearch} type="button">
                    <i className="icon-search-2" style={{ fontSize: 8 }} />
                  </button>
                </div>
                {loading ? (
                  <Loading />
                ) : (
                  <Tree
                    valueSearch={valueSearch}
                    treeData={ICBsTree}
                    idKey="icbCode"
                    nameKey="icbName"
                    levelKey="icbLevel"
                    isDefaultCheckAll={false}
                    listChecked={list}
                    setListChecked={setListChecked}
                    setListParentChecked={setParentChecked}
                    isHaveCheckboxAll={true}
                    titleCheckboxAll="common.ALL"
                    isHaveCheckbox={true}
                    isActiveSelected={false}
                    autoHeight={true}
                    autoHeightMin={200}
                    autoHeightMax={350}
                    isCheckedWhenClickText
                    zIndexTextEllipsis={Z_INDEX.DROPDOWN + 1}
                    customStyle={style.customTree}
                    classCheckboxStyle={style.checkbox}
                  />
                )}
                <div
                  className="d-flex al-center"
                  style={{ gap: 10, padding: '8px 0 0 0' }}
                >
                  <div style={{ flex: 1 }}>
                    <Button
                      className={`btn btn-blue h-20`}
                      style={{
                        padding: '0 12px',
                        boxSizing: 'border-box',
                        fontWeight: 340,
                      }}
                      onClick={handleHideDropdown}
                    >
                      <Translate value="common.button.BUTTON_CANCEL" />
                    </Button>
                  </div>
                  <div style={{ flex: 1 }}>
                    <Button
                      className={`btn btn-blue h-20`}
                      style={{
                        padding: '0 12px',
                        boxSizing: 'border-box',
                        fontWeight: 340,
                      }}
                      onClick={handleChange}
                    >
                      <Translate value="common.button.BUTTON_APPLY" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default withFilterSectorActions(FilterSectorSelect)
