import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import PopupAlert from '../../../../../common/popup/PopupAlert'
import { Table } from '../../../../../common/table'
import { formatVal } from '../../../../../utils/Value'
import { TABLE_TYPE } from '../constants'
import {
  changeCountLimit,
  changeQuarter,
  changeYear,
  getYearlyQuarterValue,
  selectCountLimit,
} from '../store/slice'
import FirstColumnCell from './FirstCellColumn'
import { getRowId, handleCollapseLevel } from './helper'
import style from './index.module.css'

import {
  getGroupColumns,
  getIds,
  getIsAllowCallApi,
  getIsFirstTime,
  getIsShowAlert,
  getMaxQuarterYear,
  getMinQuarterYear,
  getRadioValuePPI,
  getTableData,
  getTableDataLoading,
  getTitleData,
  getTitleNameLoading,
  handleIsFirstTime,
  handleIsShowAlert,
  selectCellValue,
  sort,
} from './store/slice'
import {
  getGeneralProductionIndex,
  getGeneralProductionIndexName,
} from './store/thunk'

const HORIZONTAL_TRACK_LEFT = 28
export const NUMBER_OF_PERIOD_FIRST_TIME = 20
export const NUMBER_OF_PERIOD = 10
const MAX_QUARTER = 4

export const TableGeneralIndex = () => {
  const { CUR_PERIOD } = TABLE_TYPE
  const dispatch = useDispatch()
  const [rowCollapse, setRowCollapse] = useState([])

  const ids = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const tableData = useSelector(getTableData)
  const titleData = useSelector(getTitleData)
  const isFirstTime = useSelector(getIsFirstTime)
  const tableDataLoading = useSelector(getTableDataLoading)
  const titleNameLoading = useSelector(getTitleNameLoading)
  const locale = useSelector((state) => state.i18n.locale)
  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const isShowAlert = useSelector(getIsShowAlert)
  const radioValuePPI = useSelector(getRadioValuePPI)
  const isAllowCallApi = useSelector(getIsAllowCallApi)
  const { minYear, minQuarter } = useSelector(getMinQuarterYear)
  const maxQuarterYear = useSelector(getMaxQuarterYear)

  const countLimit = useSelector(selectCountLimit)

  const scrollToLeft = () => {
    dispatch(handleIsFirstTime(false))

    if (yearlyQuarterValue === 'Yearly' && isAllowCallApi && minYear) {
      dispatch(
        getGeneralProductionIndex({
          FrequencyType:
            yearlyQuarterValue === 'Yearly' ? CUR_PERIOD : radioValuePPI,
          DataType: yearlyQuarterValue,
          Limit: NUMBER_OF_PERIOD,
          Year: minYear - 1,
        }),
      )
      dispatch(changeCountLimit(countLimit + NUMBER_OF_PERIOD))
    }

    if (
      yearlyQuarterValue !== 'Yearly' &&
      isAllowCallApi &&
      minYear &&
      minQuarter
    ) {
      dispatch(
        getGeneralProductionIndex({
          FrequencyType:
            yearlyQuarterValue === 'Yearly' ? CUR_PERIOD : radioValuePPI,
          DataType: yearlyQuarterValue,
          Limit: NUMBER_OF_PERIOD,
          Year: minQuarter === 1 ? minYear - 1 : minYear,
          Quarter: minQuarter === 1 ? MAX_QUARTER : minQuarter - 1,
        }),
      )
      dispatch(changeCountLimit(countLimit + NUMBER_OF_PERIOD))
    }
  }

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowCollapse.indexOf(rowId)
      if (index === -1) {
        setRowCollapse((old) => [...old, rowId])
      }
    } else {
      setRowCollapse((old) => old.filter((item) => item !== rowId))
    }
  }

  useEffect(() => {
    dispatch(
      getGeneralProductionIndexName({
        FrequencyType:
          yearlyQuarterValue === 'Yearly' ? CUR_PERIOD : radioValuePPI,
      }),
    )
    dispatch(handleIsFirstTime(true))
  }, [locale, radioValuePPI, yearlyQuarterValue])

  useEffect(() => {
    dispatch(changeCountLimit(NUMBER_OF_PERIOD_FIRST_TIME))
    dispatch(
      getGeneralProductionIndex({
        FrequencyType:
          yearlyQuarterValue === 'Yearly' ? CUR_PERIOD : radioValuePPI,
        DataType: yearlyQuarterValue,
        Limit: NUMBER_OF_PERIOD_FIRST_TIME,
      }),
    )
  }, [locale, radioValuePPI, yearlyQuarterValue])

  useEffect(() => {
    dispatch(changeYear(maxQuarterYear.maxYear))
    dispatch(changeQuarter(maxQuarterYear.maxQuarter))
  }, [maxQuarterYear])

  if ((tableDataLoading || titleNameLoading) && isFirstTime) {
    return <Loading />
  }

  if (Object.keys(tableData).length === 0 || titleData.length === 0) {
    return <NoData />
  }

  return (
    <>
      <Table
        ids={ids}
        getDataFromRedux={selectCellValue}
        columnDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        isLoading={tableDataLoading || titleNameLoading}
        resizable={false}
        sort={sort}
        schema={[{ key: 'emptyColumn', title: '' }, ...groupColumns].map(
          (item, index) => {
            const title = item.title
            const colId = item.key
            let result = {
              colId,
              title,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
              },
              tdStyle: {
                textAlign: 'right',
              },
            }
            if (index === 0) {
              result = {
                ...result,
                isI18n: false,
                tdStyle: {
                  textAlign: 'left',
                  minWidth: 240,
                },
                disableSort: true,
                canCustomTd: true,
                render: (val, rowId, props) => {
                  const tableHorizontalHead = titleData.find(
                    (item) => getRowId(item) === rowId,
                  )
                  return (
                    <FirstColumnCell
                      props={props}
                      tableHorizontalHead={tableHorizontalHead}
                      rowId={rowId}
                      onRowCollapse={onRowCollapse}
                    />
                  )
                },
              }
            } else {
              result = {
                ...result,
                render: (val) => {
                  return (
                    <Span
                      className={`${
                        typeof val === 'number' && val < 0 && style.redColor
                      }`}
                      style={{ minWidth: 80 }}
                    >
                      {formatVal(val)}
                      {typeof val === 'number' && '%'}
                    </Span>
                  )
                },
              }
            }
            return result
          },
        )}
        isCollapse={true}
        rowsCollapse={rowCollapse}
        levelCollapse={handleCollapseLevel(titleData)}
        stickyFirstColumn={true}
        scrollToLeftCallback={scrollToLeft}
        defaultScrollToRight={isFirstTime}
        isLazyLoadLeft={true}
      />
      <PopupAlert
        message={<Translate value={'economy.gdp.gdp.MAX_ITEM'} />}
        isShow={isShowAlert}
        handleClose={() => {
          dispatch(handleIsShowAlert(false))
        }}
      />
    </>
  )
}
