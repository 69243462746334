import { useSelector } from 'react-redux'
import { SizeTracker } from '../../common/sizeTracker'
import Content from './Content'
import { RadioGroup } from './cpiAndInflationTable/RadioGroup'
import {
  selectDataTable,
  selectListCPISector,
} from './cpiAndInflationTable/store/slice'

const CPIAndInflation = () => {
  const listCPISector = useSelector(selectListCPISector)
  const dataTable = useSelector(selectDataTable)

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="top-info">
              <RadioGroup listCPISector={listCPISector} dataTable={dataTable} />
            </div>
            {size.height && (
              <div
                className="row w-100 position-relative"
                style={{
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default CPIAndInflation
