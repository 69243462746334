import { emptyInList } from '../../../utils/Common'
import { formatDateTime } from '../../../utils/Datetime'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import { AXIS_LABEL_POSITION } from '../constants'
import {
  adjustPosition,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  getTextWidth,
  getTickValues,
  getXAxisFormatAndTicks,
  yExtentsCharts,
} from '../helper'

export const getLatestData = ({ data, keys = [] }) => {
  const result = {}

  for (let index = data.length - 1; index >= 0; index--) {
    const item = data[index]
    keys.forEach((key) => {
      if (result[key] === undefined && (item[key] || item[key] === 0)) {
        result[key] = item[key]
      }
    })
    if (Object.values(result) === keys.length) break
  }

  return result
}

export const getXAxisMinMax = ({
  data,
  keyXAxis,
  isScatterChart,
  isHorizontalStackChart,
}) => {
  const arrKeyXAxis = Array.isArray(keyXAxis) ? keyXAxis : [keyXAxis]
  return yExtentsCharts(
    data,
    arrKeyXAxis,
    isHorizontalStackChart,
    false,
    isScatterChart,
  )
}

export const getXAxisTicks = ({
  xAxisType,
  data,
  xMinMax,
  timeFrame,
  xTickNum,
  keyXAxis,
  width,
  fontSize,
  locale,
  timeZone,
  isScatterChart,
  isHorizontalStackChart,
  isNotFormatXAxis,
}) => {
  if (xAxisType === 'number') {
    const [minVal, maxVal] =
      xMinMax ||
      getXAxisMinMax({ data, keyXAxis, isScatterChart, isHorizontalStackChart })
    const tickValues = getRoundedTickValues([minVal, maxVal], xTickNum)
    const decimalLength = getDecimalLengthYAxis(tickValues)
    return {
      tickValues,
      defaultFormatter: (val) =>
        val === 0 ? 0 : formatVal(val, decimalLength),
      decimalLength,
    }
  } else {
    const { tickValues, format } =
      timeFrame && xTickNum
        ? getXAxisFormatAndTicks({
            data,
            keyXAxis,
            timeRange: timeFrame,
            tickNum: xTickNum,
            width,
            locale,
            fontSize,
            margin: { left: 0, right: 0 },
            timeZone,
            isNotFormatXAxis,
          })
        : {
            tickValues: getTickValues({
              data,
              keyXAxis,
              tickNum: data.length,
              width,
              margin: { left: 0, right: 0 },
              fontSize,
              isTickNumAsMax: true,
            }),
          }
    const defaultFormatter =
      timeFrame && xTickNum && !isNotFormatXAxis
        ? (d) => formatDateTime(new Date(d), format, locale, timeZone)
        : (d) => d

    return { tickValues, defaultFormatter }
  }
}

export const getYAxisProps = ({
  data,
  height,
  yAxis,
  heightXAxis,
  typeFontSize,
  margin,
  layout,
  styleTickYAxis,
}) => {
  let currentTotalRightYAxisWidth = 0

  return yAxis.map((props) => {
    let {
      keys,
      isGroupBar = false,
      isStackedBar = false,
      isLineChart = false,
      isBarChart = false,
      isScatterChart = false,
      orientation,
      tickNum = 5,
      min,
      max,
      label,
      labelPosition,
      yAxisWidth,
      tickValues,
      unitYAxis,
      hasReferenceLabel = false,
      referenceLabelWidth = 84,
      referenceLabelHeight = 14,
      showLabelExactly = false,
      themeDownloadPDF,
    } = props

    let [minVal, maxVal] =
      typeof min === 'number' && typeof max === 'number'
        ? [min, max]
        : yExtentsCharts(data, keys, isStackedBar, isLineChart, isScatterChart)

    if (isGroupBar && isStackedBar) {
      let [yMin, yMax] = [0, 0]
      keys.forEach((keyStack) => {
        const [newMin, newMax] = emptyInList(data, keyStack)
          ? [0, 0]
          : yExtentsCharts(data, keyStack, true, false)
        ;[yMin, yMax] = [Math.min(newMin, yMin), Math.max(newMax, yMax)]
      })
      ;[minVal, maxVal] = [yMin, yMax]
    }

    if (!tickValues || !tickValues.length) {
      tickValues =
        layout === 'vertical'
          ? data.map((item) => item[keys[0]])
          : getRoundedTickValues([minVal, maxVal], tickNum, isBarChart)
    }

    const decimalLength = getDecimalLengthYAxis(tickValues)

    const labelProps =
      label && labelPosition
        ? getPropLabelPosition({
            labelPosition,
            typeFontSize,
            themeDownloadPDF,
          })
        : {}

    if (!yAxisWidth) {
      yAxisWidth = getYAxisWidth({
        tickValues,
        labelText: label,
        labelFontSize: styleTickYAxis
          ? styleTickYAxis?.labelFontSize
          : getFontSize(10, typeFontSize),
        labelPosition,
        decimalLength,
        fontSize: styleTickYAxis
          ? styleTickYAxis.fontSize
          : getFontSize(12, typeFontSize),
        unitYAxis,
        hasReferenceLabel,
        referenceLabelWidth,
        labelWeight: styleTickYAxis?.fontWeight,
      })
    }

    if (orientation === 'right') {
      currentTotalRightYAxisWidth += yAxisWidth
    }

    const referenceLabelOffsetX = currentTotalRightYAxisWidth - yAxisWidth

    let referenceLabelY = {}
    let latestData = {}
    if (hasReferenceLabel) {
      latestData = getLatestData({ data, keys })
      const sortData = Object.keys(latestData)
        .filter(
          (key) =>
            keys.map(String).includes(key) &&
            latestData[key] >= tickValues[0] &&
            latestData[key] <= tickValues[tickValues.length - 1],
        )
        .map((key) => ({ key, val: latestData[key] }))
        .sort((a, b) => a.val - b.val)

      if (showLabelExactly) {
        sortData.forEach((item) => (referenceLabelY[item.key] = item.val))
      } else {
        adjustPosition(
          sortData,
          height - heightXAxis - margin.top - margin.bottom,
          referenceLabelHeight,
          tickValues[tickValues.length - 1],
          tickValues[0],
        ).forEach((item) => (referenceLabelY[item.key] = item.val))
      }
    }

    return {
      ...props,
      minVal,
      maxVal,
      yAxisWidth,
      tickValues,
      decimalLength,
      labelProps,
      latestData,
      referenceLabelY,
      referenceLabelOffsetX,
    }
  })
}

export const getYAxisWidth = ({
  tickValues,
  labelText,
  labelPosition,
  labelFontSize = 10,
  decimalLength = 2,
  fontSize = 12,
  unitYAxis = '',
  hasReferenceLabel,
  referenceLabelWidth,
  labelWeight,
}) => {
  const PADDING = 8
  const LABEL_GAP = 16

  let maxYAxisText = ''

  const isStringsArray = (arr) => arr.every((i) => typeof i === 'string')

  if (tickValues.length) {
    const tickFormat = isStringsArray(tickValues)
      ? tickValues
      : tickValues
          .filter((val) => !isNaN(val))
          .map((val) => (val === 0 ? '0' : formatVal(val, decimalLength)))
    for (const tick of tickFormat) {
      if (maxYAxisText.length < tick.length) {
        maxYAxisText = tick
      }
    }
  }

  let yAxisWidth = Math.ceil(
    getTextWidth(maxYAxisText + unitYAxis, fontSize, labelWeight),
  )

  if (labelText && labelPosition) {
    const labelWidth = Math.ceil(
      getTextWidth(labelText, labelFontSize, labelWeight),
    )

    if (
      [AXIS_LABEL_POSITION.TOP_LEFT, AXIS_LABEL_POSITION.TOP_RIGHT].includes(
        labelPosition,
      )
    ) {
      yAxisWidth = Math.max(yAxisWidth, labelWidth)
    } else {
      yAxisWidth += labelFontSize + LABEL_GAP
    }
  }

  if (hasReferenceLabel && referenceLabelWidth) {
    yAxisWidth = Math.max(yAxisWidth, referenceLabelWidth)
  }

  return yAxisWidth + PADDING
}

export const getPropLabelPosition = ({
  labelText,
  labelPosition,
  labelColor,
  fontSize = 11,
  typeFontSize,
  themeDownloadPDF,
  textDecoration,
  fontStyle,
  fontWeight,
  labelOpacity,
}) => {
  const commonProps = themeDownloadPDF
    ? {
        fontSize: 10,
        fill: labelColor || '#000000',
        opacity: 1,
        textDecoration: textDecoration,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
      }
    : {
        fontSize: getFontSize(fontSize, typeFontSize),
        fill: labelColor || 'rgba(255, 255, 255)',
        opacity: labelOpacity || 0.4,
        textDecoration: textDecoration,
        fontStyle: fontStyle,
        fontWeight: fontWeight,
      }

  if (labelText) {
    commonProps.value = labelText
  }

  switch (labelPosition) {
    case AXIS_LABEL_POSITION.LEFT:
      return {
        ...commonProps,
        position: 'insideLeft',
        offset: 8,
        angle: -90,
        style: { textAnchor: 'middle' },
      }
    case AXIS_LABEL_POSITION.RIGHT:
      return {
        ...commonProps,
        position: 'insideRight',
        offset: 8,
        angle: 90,
        style: { textAnchor: 'middle' },
      }
    case AXIS_LABEL_POSITION.TOP_RIGHT:
      return {
        ...commonProps,
        position: 'insideTopLeft',
        offset: 10,
        style: { textAnchor: 'start', transform: 'translateY(-36px)' },
      }
    case AXIS_LABEL_POSITION.TOP_LEFT:
      return {
        ...commonProps,
        position: 'insideTopRight',
        offset: 10,
        style: { textAnchor: 'end', transform: 'translateY(-36px)' },
      }
    case AXIS_LABEL_POSITION.TOP:
      return {
        ...commonProps,
        position: 'insideTopRight',
        offset: 10,
        style: { textAnchor: 'middle', transform: 'translateY(-36px)' },
      }
    default:
      return {}
  }
}
