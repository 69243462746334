import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from '../../../../utils/Common'
import { getParams } from '../helpers'
import { selectFilters, selectTableTab } from '../store/slice'
import { getTableDataThunk } from '../store/thunk'

export const withGetData = (Component) => (props) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)

  const handleGetData = useCallback(
    debounce((params, tab) => {
      dispatch(getTableDataThunk({ params, tableTab: tab }))
    }, 200),
    [],
  )

  useEffect(() => {
    const params = getParams(filters, tableTab)
    handleGetData(params, tableTab)
  }, [
    filters.assetId,
    filters.period,
    filters.month,
    filters.year,
    filters.page,
    filters.sortField,
    filters.sortOrder,
    tableTab,
    locale,
  ])

  return <Component {...props} />
}
