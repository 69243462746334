import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class SegmentalAnalysisProxy extends ProxyBase {
  getGetLatestPeriodBank(params) {
    return this.get('GetLatestPeriodBank', params)
  }

  getIndicatorMapping(params) {
    return this.get('GetIndicatorMapping', params)
  }

  getIncomeStructure(params) {
    return this.get('GetIncomeStructure', params)
  }

  downloadIncomeStructure(params) {
    return this.get('DownloadIncomeStructure', params, 'download')
  }

  chartBank1(params) {
    return this.get('Chart/ChartBank1', params)
  }

  chartBank2(params) {
    return this.get('Chart/ChartBank2', params)
  }
}

export default new SegmentalAnalysisProxy(
  ServiceProxyConfig.Corporate.SegmentalAnalysis.ServiceUrl,
)
