import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { PRICE_DATA_TIME_SELECT, TABLE_TYPE_PERIOD } from '../../constants'

export const PRODUCTION_SLICE_NAME =
  'economy/productionAndConsumption/production'

const slice = createSlice({
  name: PRODUCTION_SLICE_NAME,
  initialState: {
    listVNTypeIds: [],
    isLoading: false,
    typePeriod: TABLE_TYPE_PERIOD.PRE_PERIOD,
    typeDataTime: PRICE_DATA_TIME_SELECT.MONTHLY,
    yearlyQuarterlyValue: 'Quarterly',
    countLimit: 0,
    year: null,
    month: null,
    quarter: null,
  },
  reducers: {
    changeTypePeriod: (state, action) => {
      state.typePeriod = action.payload
    },
    changeTypeDataTime: (state, action) => {
      state.typeDataTime = action.payload
    },
    handleYearlyQuarterValue: (state, action) => {
      state.yearlyQuarterlyValue = action.payload
    },
    changeCountLimit: (state, action) => {
      state.countLimit = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    changeMonth: (state, action) => {
      state.month = action.payload
    },
    changeQuarter: (state, action) => {
      state.quarter = action.payload
    },
  },
})

export const selectTypePeriod = (state) => state[slice.name].typePeriod
export const selectTypeDataTime = (state) => state[slice.name].typeDataTime
export const getYearlyQuarterValue = (state) =>
  state[slice.name].yearlyQuarterlyValue
export const selectCountLimit = (state) => state[slice.name].countLimit
export const selectYear = (state) => state[slice.name].year
export const selectMonth = (state) => state[slice.name].month
export const selectQuarter = (state) => state[slice.name].quarter

export const {
  changeTypePeriod,
  changeTypeDataTime,
  handleYearlyQuarterValue,
  changeCountLimit,
  changeMonth,
  changeQuarter,
  changeYear,
} = slice.actions

register(slice.name, slice.reducer)
