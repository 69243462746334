import { useState } from 'react'
import { SizeTracker } from '../../sizeTracker'
import DispatchActionTab from '../../tabs/DispatchActionTab'
import style from './index.module.css'
import Indicator from './Indicator'
import Template from './Template'

const listTab = [
  { title: 'tool.dataExplorer.INDICATOR', value: 1 },
  { title: 'tool.dataExplorer.TEMPLATE', value: 2 },
]

const MenuTab = ({
  handleAddIndicator,
  templates,
  fiinXTemplates,
  handleRenameTemplate,
  handleDeleteTemplate,
  handleGetTemplate,
  indicatorTree,
  loadingIndicator,
  isEconomy,
  isHasFiinXTemplate,
  isFiinXTemplate,
  keyResetTree,
  handleGetFiinXTemplate,
}) => {
  const [activeTab, setActiveTab] = useState(listTab[0].value)

  const handleChangeTab = (item) => setActiveTab(item.value)

  const isIndicator = activeTab === listTab[0].value

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className={`w-100 ${!isEconomy ? style.menuTabContainer : ''}`}>
            <DispatchActionTab
              data={listTab}
              activeTab={activeTab}
              onChangeTab={handleChangeTab}
              itemStyle={{
                width: '50%',
                fontWeight: 600,
                fontSize: 11,
                padding: 'unset',
              }}
              containerStyle={{ width: '100%' }}
            />
          </div>
          {size.height && (
            <div
              className="wrapper-tool"
              style={{ height: `calc(100% - ${size.height}px)` }}
            >
              {isIndicator ? (
                <Indicator
                  key={keyResetTree}
                  indicatorTree={indicatorTree}
                  loadingIndicator={loadingIndicator}
                  handleAddIndicator={handleAddIndicator}
                  isEconomy={isEconomy}
                  isFiinXTemplate={isFiinXTemplate}
                />
              ) : (
                <Template
                  templates={templates}
                  fiinXTemplates={fiinXTemplates}
                  handleRenameTemplate={handleRenameTemplate}
                  handleDeleteTemplate={handleDeleteTemplate}
                  handleGetTemplate={handleGetTemplate}
                  loading={loadingIndicator}
                  isEconomy={isEconomy}
                  isHasFiinXTemplate={isHasFiinXTemplate}
                  handleGetFiinXTemplate={handleGetFiinXTemplate}
                />
              )}
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}

export default MenuTab
