import SelectCustom from '../../../../../common/selectCustom'
import { LASTEST_YEARS } from '../../constant'

const YearlyPoint = ({ value, onChange }) => {
  return (
    <div className={`mt-8 h-20 w-100`}>
      <SelectCustom
        isI18n={false}
        selectData={LASTEST_YEARS}
        value={value}
        handleChange={onChange}
        fontSizeItem={10}
      />
    </div>
  )
}

export default YearlyPoint
