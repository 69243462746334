import { useSelector } from 'react-redux'
import { Top10NetAssetValue } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectFilters } from './store/slice'

export const PanelTop10NetAssetValue = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const filters = useSelector(selectFilters)

  const titlePanel = `fund.fundCenter.overview.performance.${panelKey}`
  const nameScreen = UseI18n('constants.route.fund.fundCenter.LABEL')
  const chartName = UseI18n('fund.fundCenter.overview.PERFORMANCE')
  const tabName = UseI18n(`fund.fundCenter.overview.performance.${panelKey}`)
  const titleJpg = UseI18n(
    `fund.fundCenter.overview.performance.CHART_NAME_${panelKey}`,
    {
      month: filters.month,
      year: filters.year,
    },
  )

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: panelKey,
        nameScreen,
        chartName,
        tabName,
      }}
    >
      <Top10NetAssetValue
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
