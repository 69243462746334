import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialDataService from '../../../../../../core/services/corporate/FinancialDataService'
import { handleExport } from '../../../../../utils/Export'

export const getFinancialDataDividendTable = createAsyncThunk(
  'corporate/financialData/dividend/table/GET_FINANCIAL_DATA_DIVIDEND_TABLE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataDividendTable(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFinancialDataDividendChart = createAsyncThunk(
  'corporate/financialData/dividend/table/GET_FINANCIAL_DATA_DIVIDEND_CHART',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataDividendChart(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDividendHistory = createAsyncThunk(
  'corporate/financialData/dividend/table/DOWNLOAD_DIVIDEND_HISTORY',
  async (data) => {
    const response = await FinancialDataService.downloadDividendHistory(data)
    handleExport(response.data, response.filename)
  },
)
