import PropTypes from 'prop-types'
import { Component } from 'react'
import { debounce } from '../../utils/Common'
import { formatWithDevicePixelRatio } from '../../utils/Device'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  FULL_COMPONENT,
} from '../../utils/EventEmitter'
import { ThCell } from './ThCell'
import style from './index.module.css'

export class TableHead extends Component {
  constructor(props) {
    super(props)
    this.state = {
      resizeState: {},
    }
    this.columnRefs = {}
    this.initWidths = {}
    this.debounce = debounce(this.resizeComponent)
  }

  calcWidths = () => {
    let widths = {}
    this.props.schema.forEach(({ colId }) => {
      if (this.columnRefs && this.columnRefs[colId]) {
        const maxWidth = +this.columnRefs[colId].style.maxWidth.replace(
          'px',
          '',
        )
        const width = this.columnRefs[colId].getBoundingClientRect().width
        if (maxWidth && maxWidth < width) {
          widths[colId] = formatWithDevicePixelRatio(maxWidth)
        } else {
          widths[colId] = formatWithDevicePixelRatio(width)
        }
      }
    })

    this.initWidths = widths
    this.props.setWidths(widths)
  }

  resizeComponent = async () => {
    await this.disableResizeColumn()
    this.calcWidths()
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.reCalcWidths !== this.props.reCalcWidths ||
      prevProps.typeFontSize !== this.props.typeFontSize
    ) {
      this.calcWidths()
      this.resizeComponent()
    }
  }

  componentDidMount() {
    this.calcWidths()

    EventEmitter.subscribe(COMPONENT_RESIZE, this.resizeComponent)
    EventEmitter.subscribe(FULL_COMPONENT, this.resizeComponent)
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, this.resizeComponent)
    window.addEventListener('resize', this.debounce)
  }

  componentWillUnmount() {
    EventEmitter.unsubscribe(COMPONENT_RESIZE, this.resizeComponent)
    EventEmitter.unsubscribe(FULL_COMPONENT, this.resizeComponent)
    EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, this.resizeComponent)
    window.removeEventListener('resize', this.debounce)
  }

  disableResizeColumn = () => {
    return new Promise((resolve) => {
      const initWidths = Object.keys(this.state.resizeState).reduce(
        (result, key) => {
          return {
            ...result,
            [key]: this.props.widths[key],
          }
        },
        {},
      )
      this.props.setWidths(initWidths)
      setTimeout(() => {
        resolve(true)
      }, 200)
    })
  }

  setResizeState = (resizeState) => {
    this.setState({
      ...this.state,
      resizeState,
    })
  }

  render() {
    const {
      schema,
      rowSpanSchema,
      headRef,
      columnDraggable,
      reorderColumn,
      sortColumn,
      stateSort,
      resizable,
      hasTooltip,
      stickies,
      stickyFirstColumn,
      tableHeadRowTop,
      hasBorderTHead,
      setIsWidth100,
    } = this.props

    const renderThCell = (col, colIndex) => {
      return (
        <ThCell
          key={col.colId}
          col={col}
          colIndex={colIndex}
          reorderColumn={reorderColumn}
          columnDraggable={columnDraggable}
          sortColumn={sortColumn}
          stateSort={stateSort}
          resizable={resizable}
          columnRefs={this.columnRefs}
          widths={this.props.widths}
          setWidths={this.props.setWidths}
          setIsWidth100={setIsWidth100}
          schema={schema}
          initWidths={this.initWidths}
          resizeState={this.state.resizeState}
          setResizeState={this.setResizeState}
          hasTooltip={hasTooltip}
          sticky={
            !!stickies[col.colId] || (stickyFirstColumn && colIndex === 0)
          }
          tableHeadRowTop={tableHeadRowTop}
          hasBorderTHead={hasBorderTHead}
        />
      )
    }

    return (
      <>
        {rowSpanSchema && rowSpanSchema.length ? (
          <thead ref={headRef} className={style.thead}>
            {rowSpanSchema.map((schema, rowIndex) => (
              <tr
                key={rowIndex}
                className={rowIndex !== 0 ? style.borderTop : ''}
              >
                {schema.map((col, colIndex) => renderThCell(col, colIndex))}
              </tr>
            ))}
          </thead>
        ) : (
          <thead ref={headRef}>
            <tr>
              {schema.map((col, colIndex) => renderThCell(col, colIndex))}
            </tr>
          </thead>
        )}
      </>
    )
  }
}

TableHead.propTypes = {
  schema: PropTypes.array.isRequired,
  rowSpanSchema: PropTypes.array,
  headRef: PropTypes.object.isRequired,
  reorderColumn: PropTypes.func.isRequired,
  columnDraggable: PropTypes.bool.isRequired,
  sortColumn: PropTypes.func.isRequired,
  stateSort: PropTypes.object.isRequired,
  resizable: PropTypes.bool.isRequired,
  hasTooltip: PropTypes.bool.isRequired,
  stickies: PropTypes.object.isRequired,
  tableHeadRowTop: PropTypes.object,
  reCalcWidths: PropTypes.any,
}
