import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class CompanyBondProxy extends ProxyBase {
  getBondIssueInformation(params) {
    return this.get('BondIssueInformation', params)
  }
  getRelativeToEquity(params) {
    return this.get('RelativeToEquity', params)
  }
  getRemainingMaturities(params) {
    return this.get('RemainingMaturities', params)
  }
  getCouponInterestRate(params) {
    return this.get('CouponInterestRate', params)
  }
  getInterestAndPrincipalPaid(params) {
    return this.get('InterestAndPrincipalPaid', params)
  }
  downloadBondIssueInformation(params) {
    return this.post('BondIssueInformationExport', params, {}, 'download')
  }
}

export default new CompanyBondProxy(
  ServiceProxyConfig.Bond.CompanyBond.ServiceUrl,
)
