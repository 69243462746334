import { DEFAULT_LIST_CPI } from './constants'

//get items which are parents of level 3 data
export const getItemParent = (data) => {
  const itemAtLevel3 = data.filter((item) => item.cpivnTypeLevel === 3)
  const itemParent = data.filter((item) => item.cpivnTypeLevel !== 3)
  let parentArr = []
  for (let i = 0; i < itemParent.length; i++) {
    for (let j = 0; j < itemAtLevel3.length; j++)
      if (
        itemParent[i].cpivnTypeId === itemAtLevel3[j].parentCPIVNTypeId &&
        parentArr.indexOf(itemParent[i]) === -1
      ) {
        parentArr.push(itemParent[i])
      }
  }
  return parentArr
}

export const keepCurrentListByIndex = (indexArr, data) => {
  const addedItems =
    indexArr.length && data.length
      ? indexArr?.map((index) => data[index])
      : DEFAULT_LIST_CPI(data)

  return addedItems
}
