import { StatisticalMethodsComponent } from './components/statisticalMethodsComponent'
import styles from './style.module.css'

const StatisticalMethodSettings = ({
  statisticalMethodSettingsRef,
  data,
  setData,
}) => {
  const handleChange = (value) => {
    setData({ ...data, statisticalMethods: value })
  }

  return (
    <div className={styles.settingBlockContainer}>
      <StatisticalMethodsComponent
        isParent
        statisticalMethodSettingsRef={statisticalMethodSettingsRef}
        value={data.statisticalMethods || {}}
        onChange={handleChange}
      />
    </div>
  )
}

export default StatisticalMethodSettings
