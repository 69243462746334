import { createAsyncThunk } from '@reduxjs/toolkit'
import ConsumptionService from '../../../../../../core/services/economy/productionAndConsumption/ConsumptionService'

export const getChartShortRetails = createAsyncThunk(
  'economy/productionAndConsumption/consumption/retailService/GET_CHART_RETAIL',
  async (data, { rejectWithValue }) => {
    const response =
      await ConsumptionService.getChangesInRetailSalesComposition(data)
    if (response.success) {
      return response.data
    }

    return rejectWithValue({ loading: false })
  },
)
