import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import ProductionService from '../../../../../../../core/services/economy/productionAndConsumption/ProductionService'

export const getPurchasingManagersIndex = createAsyncThunk(
  'economy/productionAndConsumption/product/purchaseIndex/getPurchasingManagersIndex',
  async (params, { rejectWithValue }) => {
    try {
      const response = await ProductionService.getPurchasingManagersIndex(
        params,
      )
      return response.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
