import { getTextHeight, getTextWidth } from '../../../../../common/chart/helper'
import { getFontSize } from '../../../../../utils/FontSize'
import { valToPercent } from '../../../../../utils/Value'

const ReferencePercent = ({ ctx, value }) => {
  const calcFontSize = getFontSize(10)

  const labelWidth = getTextWidth(valToPercent(value, true), calcFontSize, 600)
  const labelHeight = getTextHeight(
    valToPercent(value, true),
    calcFontSize,
    600,
  )

  return (
    <>
      {value && (
        <g>
          <foreignObject
            x={ctx.viewBox.x + ctx.viewBox.width - labelWidth + 18}
            y={ctx.viewBox.y - labelHeight - 8}
            width={labelWidth + 14}
            height={labelHeight + 4}
            color="#FFFFF"
          >
            {valToPercent(value, true)}
          </foreignObject>
        </g>
      )}
    </>
  )
}

export default ReferencePercent
