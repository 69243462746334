import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { DATA_TYPE } from '../constant'
import { getTickerByWLId } from '../header/store/thunk'
import { getDefaultYearQuarter } from '../helper'
import {
  getData,
  getExchangeRates,
  getMaxPeriod,
  getValuationThunk,
} from './thunk'

const defaultYearQuarter = getDefaultYearQuarter()
const currentDate = new Date()
const currentYear = currentDate.getFullYear()
const currentTime = moment()
const currentQuarter = currentTime.quarter()

const initialState = {
  icbLv2: '',
  dataType: DATA_TYPE.STOCK,
  tableType: false,
  valueById: {},
  maxMinValueByColId: {},
  responseOrgIds: [],
  initialResponseOrgIds: [],
  filter: {
    quarter: currentQuarter,
    year: currentYear,
  },
  rowIds: [],
  exchangeRates: {},
  tableConstants: [],
  loading: true,
  reCalcWidths: false,
  isFirst: true,
  valuation: [],
  isSuccessCallValuation: false,
}

const slice = createSlice({
  name: 'bond/comparable/comparable',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTableType: (state, action) => {
      state.tableType = action.payload
    },
    changeResponseOrgIds: (state, action) => {},
    sort: (state, action) => {
      const idsSort = getIdsFromProps(
        state.rowIds,
        state.valueById,
        action.payload,
        state.initialResponseOrgIds,
      )
      const newIds = idsSort.filter((item) => item !== 'total')
      state.rowIds = !!newIds.length ? [...newIds, 'total'] : []
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    resetIndicatorMapping: (state) => {
      state.tableConstants = []
      state.rowIds = []
      state.valueById = {}
    },
    changeLoading: (state, action) => {
      state.loading = action.payload
    },
    updateIsSuccessCallValuation: (state, action) => {
      state.isSuccessCallValuation = action.payload
    },
    updateRowids: (state, action) => {
      state.rowIds = action.payload
    },
    updateValuation: (state, action) => {
      state.valuation = action.payload
    },
    updateDataType: (state, action) => {
      state.dataType = action.payload
    },
    updateDataIcbLv2: (state, action) => {
      state.icbLv2 = action.payload
    },
    changeIsFirst: (state, action) => {
      state.isFirst = action.payload
    },
  },
  extraReducers: (builder) => {
    //getMaxPeriod
    builder.addCase(getMaxPeriod.fulfilled, (state, { payload }) => {
      if (payload && payload[0]) {
        state.filter.quarter = payload[0].quarter || defaultYearQuarter.quarter
        state.filter.year = payload[0].year || defaultYearQuarter.year
      }
    })
    builder.addCase(getMaxPeriod.rejected, (state, action) => {
      state.filter.quarter = defaultYearQuarter.quarter
      state.filter.year = defaultYearQuarter.year
    })
    //ExchangeRate
    builder.addCase(getExchangeRates.fulfilled, (state, action) => {
      const exchangeRates = {}
      action.payload?.forEach(
        (item) =>
          (exchangeRates[`${item.quarter}-${item.year}`] = item.exchangeRate),
      )
      state.exchangeRates = exchangeRates
    })

    //Get Valuation
    builder.addCase(getValuationThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValuationThunk.fulfilled, (state, action) => {
      state.loading = false
      state.isSuccessCallValuation = true
      state.valuation = action.payload
    })
    builder.addCase(getValuationThunk.rejected, (state, action) => {
      state.loading = action.payload
    })

    builder.addCase(getData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getData.fulfilled, (state, action) => {
      state.loading = false
      state.rowIds = action.payload.data.map((e) => e?.organizationId)
      state.initialResponseOrgIds = action.payload.data.map(
        (e) => e?.organizationId,
      )
      state.valueById = keyBy(action.payload.data, 'organizationId')
      state.maxMinValueByColId = action.payload.dataMinMax
    })
    builder.addCase(getData.rejected, (state, action) => {
      state.loading = action.payload
      state.rowIds = []
      state.valueById = {}
    })

    builder.addCase(getTickerByWLId.pending, (state) => {})
    builder.addCase(getTickerByWLId.fulfilled, (state, action) => {
      const currentOrgId = action.meta.arg.currentOrgId

      const newOrgIds =
        action.payload?.details?.map((ticker) => ticker.organizationId) || []

      if (!newOrgIds.includes(currentOrgId)) {
        newOrgIds.push(currentOrgId)
      }

      state.valuation = newOrgIds.map((e) => ({ organizationId: e }))
    })
    builder.addCase(getTickerByWLId.rejected, (state, action) => {})
  },
})

export const selectResponseOrgIds = (state) => state[slice.name].responseOrgIds
export const selectTableType = (state) => state[slice.name].tableType
export const selectValueById = (id, attr) => (state) => {
  const data = state[slice.name].valueById[id]
  return attr ? valByKeyWithDot(data, attr) : data
}

export const selectItemData = (id) => (state) => state[slice.name].valueById[id]
export const selectLoading = (state) => state[slice.name].loading
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths

export const selectMaxMinValueByColId = (colId) => (state) =>
  state[slice.name].maxMinValueByColId[colId]

export const selectFilter = (state) => state[slice.name].filter
export const selectExchangeRates = (state) => state[slice.name].exchangeRates
export const selectRowIds = (state) => state[slice.name].rowIds
export const selectTableConstants = (state) => state[slice.name].tableConstants
export const selectDataType = (state) => state[slice.name].dataType
export const selectIcbLv2 = (state) => state[slice.name].icbLv2
export const selectIsFirst = (state) => state[slice.name].isFirst

export const selectValuation = (state) => state[slice.name].valuation
export const selectIsSuccessCallValuation = (state) =>
  state[slice.name].isSuccessCallValuation

export const {
  resetStore,
  changeTableType,
  changeResponseOrgIds,
  changeFilter,
  changeLoading,
  resetIndicatorMapping,
  sort,
  updateIsSuccessCallValuation,
  updateRowids,
  updateValuation,
  updateDataType,
  updateDataIcbLv2,
  changeIsFirst,
} = slice.actions

register(slice.name, slice.reducer)
