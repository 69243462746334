import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import ChartComponent from './ChartComponent'

export const AVERAGE_FUNDING_COST = 'AVERAGE_FUNDING_COST'

const AverageFundingCost = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n('economy.monetary.monetary.INTEREST_RATE')
  const chartName = UseI18n('economy.monetary.monetary.AVERAGE_FUNDING_COST')
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'economy.monetary.monetary.AVERAGE_FUNDING_COST'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: AVERAGE_FUNDING_COST,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <ChartComponent
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default AverageFundingCost
