import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { getTableSchema } from './helpers'
import { selectDataCellById, selectIds, selectLoading } from './store/slice'
import { getBoardOfFundManagersThunk } from './store/thunk'

export const FundManager = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const fundInfo = useSelector(selectFundInfo)
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectIds)

  useEffect(() => {
    if (fundInfo.organizationId && !loading) {
      dispatch(
        getBoardOfFundManagersThunk({
          OrganizationId: fundInfo.organizationId,
        }),
      )
    }
  }, [fundInfo.organizationId, locale])

  return (
    <Table
      ids={ids}
      schema={getTableSchema({ locale })}
      getDataFromRedux={selectDataCellById}
      isLoading={loading}
      isPagination={false}
      isLargeHead
      hasFooter={false}
    />
  )
}
