export const MAX_INDICATOR = 100

export const MAX_TICKER = 100

export const MAX_TEMPLATE = 100

export const LOADING_KEYS = {
  FILTER: 'filter',
  PREVIEW: 'preview',
}

export const VIEW_BY = {
  CORPORATE: 'corporate',
  INDEX_AND_SECTOR: 'index_sector',
}

export const DATA_TYPE = {
  CORPORATE: 'Corporate',
  SECTOR: 'Sector',
  INDEX: 'Index',
}

export const VIEW_BY_CONDITION_TYPE_CODE = {
  [VIEW_BY.CORPORATE]: 'DE04_SC01',
  [VIEW_BY.INDEX_AND_SECTOR]: 'DE04_SC02',
}

export const RADIO_VIEW_BY = [
  {
    title: 'tool.dataExplorer.tradingData.COMPARE',
    value: VIEW_BY.CORPORATE,
    name: 'view_by',
  },
  {
    title: 'tool.dataExplorer.tradingData.INDEX_AND_SECTOR',
    value: VIEW_BY.INDEX_AND_SECTOR,
    name: 'view_by',
  },
]

export const EXPORT_TYPE = {
  A_FILE: 'Single',
  MULTIPLE_FILES: 'Multiple',
}

export const RADIO_EXPORT_TYPE = [
  {
    title: 'tool.dataExplorer.tradingData.A_FILE',
    value: EXPORT_TYPE.A_FILE,
    name: 'export_type',
  },
  {
    title: 'tool.dataExplorer.tradingData.MULTIPLE_FILES',
    value: EXPORT_TYPE.MULTIPLE_FILES,
    name: 'export_type',
  },
]

export const GROUP_BY = {
  DATE: 'TradingDate',
  COMPANY: 'Company',
  INDICATOR: 'Indicator',
}

export const RADIO_GROUP_BY = [
  {
    title: 'tool.dataExplorer.tradingData.DATE',
    value: GROUP_BY.DATE,
    name: 'group_by',
    isCompany: true,
    isSector: true,
  },
  {
    title: 'tool.dataExplorer.tradingData.COMPANY',
    value: GROUP_BY.COMPANY,
    name: 'group_by',
    isCompany: true,
    isSector: false,
  },
  {
    title: 'tool.dataExplorer.tradingData.SECTOR',
    value: GROUP_BY.COMPANY,
    name: 'group_by',
    isCompany: false,
    isSector: true,
  },
  {
    title: 'tool.dataExplorer.tradingData.INDICATOR',
    value: GROUP_BY.INDICATOR,
    name: 'group_by',
    isCompany: true,
    isSector: true,
  },
]

export const indicator = {
  TICKER: 'ticker',
  COMPANY_NAME: 'companyName',
  DATE: 'date',
  FLOOR_PRICE: 'floorPrice',
  CELLING_PRICE: 'cellingPrice',
  CLOSE_PRICE: 'closePrice',
  FOREIGN_BUY_DEAL_VOLUME: 'foreignBuyDealVolume',
  PE: 'PE',
  ROE: 'ROE',
}

export const TIME_FRAME_TRADING_DATA = {
  THREE_MONTH: 'ThreeMonthsAgo',
  SIX_MONTH: 'SixMonthsAgo',
  NINE_MONTH: 'NineMonthsAgo',
  ONE_YEAR: 'OneYearAgo',
  YTB: 'Specific',
}

export const RADIO_TIME_FRAME_TRADING_DATA = [
  {
    title: 'tool.dataExplorer.tradingData.THREE_MONTH',
    value: TIME_FRAME_TRADING_DATA.THREE_MONTH,
    name: 'times',
  },
  {
    title: 'tool.dataExplorer.tradingData.SIX_MONTH',
    value: TIME_FRAME_TRADING_DATA.SIX_MONTH,
    name: 'times',
  },
  {
    title: 'tool.dataExplorer.tradingData.NINE_MONTH',
    value: TIME_FRAME_TRADING_DATA.NINE_MONTH,
    name: 'times',
  },
  {
    title: 'tool.dataExplorer.tradingData.ONE_YEAR',
    value: TIME_FRAME_TRADING_DATA.ONE_YEAR,
    name: 'times',
  },
]

export const tableHeaderColAttr = [
  [
    {
      name: 'ticker',
      value: ['tool.dataExplorer.tradingData.TICKER'],
    },
    {
      name: 'company',
      value: ['tool.dataExplorer.tradingData.COMPANY_NAME'],
    },
    {
      name: 'status',
      value: ['tool.dataExplorer.tradingData.DATE'],
    },
    {
      name: 'chaperCapital',
      value: ['tool.dataExplorer.tradingData.FLOOR_PRICE'],
      additionText: 'tool.dataExplorer.tradingData.(VND)',
    },
    {
      name: 'capitalContribution',
      value: ['tool.dataExplorer.tradingData.CELLING_PRICE'],
      additionText: 'tool.dataExplorer.tradingData.(VND)',
    },
    {
      name: 'ownership',
      value: ['tool.dataExplorer.tradingData.CLOSE_PRICE'],
      additionText: 'tool.dataExplorer.tradingData.(VND)',
    },
    {
      name: 'ownership',
      value: ['tool.dataExplorer.tradingData.FOREIGN_BUY_DEAL_VOLUME'],
      additionText: 'tool.dataExplorer.tradingData.(VND)',
    },
    {
      name: 'ownership',
      value: ['tool.dataExplorer.tradingData.PE'],
      additionText: 'tool.dataExplorer.tradingData.(Times)',
    },
    {
      name: 'ownership',
      value: ['tool.dataExplorer.tradingData.ROE'],
      additionText: 'tool.dataExplorer.tradingData.(%)',
    },
  ],
]
