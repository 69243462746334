import { I18n } from 'react-redux-i18n'
import { getDataByTimeFrequency, getDataUnitWithMultiplier } from '.'
import { AXIS_LABEL_POSITION } from '../../../common/chart/constants'
import { CHART_TYPES } from '../../../common/chart/financialChart/constant'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { getDateWithTimezone } from '../../../utils/Datetime'
import { valToPercent } from '../../../utils/Value'
import {
  CHART_PARAMETERS_DEFAULT,
  MAP_TYPE_CHART_FOOTER,
  TIME_FREQUENCY,
  keyToolHeaderSettings,
  positionYAxis,
} from '../../chart/constants'
import {
  equationFormatUnit,
  equationFormatUnitConstants,
  equationType,
} from '../../popup/popupAddEquation/constants'
import {
  XAXIS_DATA_KEY,
  lineTypes,
  strokeDasharrayTypes,
  strokeWidthTypes,
} from '../constants'
import {
  dataFormatTypes,
  lineStyleTypes,
  lineWidthTypes,
  smoothLines,
  statisticalMethodDisplay,
} from '../menuChartSettings/constants'
import { listTimeRangeMapKey } from '../timeRange/constants'
import { getXAxisTickGroupByYearFormatByTime } from './chartHelpers'

export const ID_CHART_DUMMY = {
  DUMMY_RIGHT_ID: 'dummyRight',
  DUMMY_LEFT_ID: 'dummyLeft',
}

const YEAR_TEXT_MAX_WIDTH = 40
export const GROUP_BY_YEAR = 'groupByYear'

export const formatChartSchemaByTime = ({
  treeSecuritiesIndicator,
  equations = {},
  settings = CHART_PARAMETERS_DEFAULT.settings,
  getNameSecuritiesIndicator,
  listLegendCustom = {},
  listYAxisCustom = {},
  getIndicator,
  data,
  timeZone,
  timeFrequency,
  timeRange,
  locale,
}) => {
  const xAxis = [
    {
      dataKey: XAXIS_DATA_KEY.DATE,
      timeRange: listTimeRangeMapKey[timeRange] || TIME_RANGES['1M'],
    },
  ]
  let chartSchema = {
    yAxis: {},
    charts: {},
    footerChart: {},
    tooltips: {},
    stackData: {},
  }

  // Format to raw chart schema
  Object.values(treeSecuritiesIndicator).forEach((obj) =>
    Object.values(obj).forEach((obj2) => {
      if (Array.isArray(obj2))
        return obj2.forEach((item) => {
          chartSchema = getDetailFormatChartSchema(
            chartSchema,
            item,
            getNameSecuritiesIndicator(
              item.dataKeyDuplicate || item.dataKey,
              listLegendCustom,
            ),
            locale,
          )
        })
      else {
        chartSchema = getDetailFormatChartSchema(
          chartSchema,
          obj2,
          getNameSecuritiesIndicator(
            obj2.dataKeyDuplicate || obj2.dataKey,
            listLegendCustom,
          ),
          locale,
        )
      }
    }),
  )

  Object.values(equations).forEach((obj) => {
    chartSchema = getDetailFormatChartSchema(
      chartSchema,
      obj,
      obj.equationIndicatorName,
      locale,
    )
  })

  // Format chart schema by group or not
  if (!settings[keyToolHeaderSettings.GROUP_Y_AXIS]) {
    const { yAxisFormat, chartsFormat } = getYAxisByStack(
      chartSchema.yAxis,
      chartSchema.charts,
      chartSchema.stackData,
      settings,
      equations,
      getIndicator,
    )
    chartSchema.yAxis = yAxisFormat
    chartSchema.charts = chartsFormat
  } else {
    const { yAxisFormat, chartsFormat } = getYAxisByGroup(
      chartSchema.yAxis,
      chartSchema.charts,
      chartSchema.stackData,
      settings,
      equations,
      getIndicator,
    )
    chartSchema.yAxis = yAxisFormat
    chartSchema.charts = chartsFormat
  }

  // Add x-axis group by year
  if (settings.xAxisGroupByYear && data && data.length) {
    xAxis[0] = {
      ...xAxis[0],
      ...getXAxisTickGroupByYearFormatByTime(
        xAxis[0].timeRange,
        timeFrequency,
        data,
        timeZone,
        locale,
      ),
    }

    if (timeFrequency !== TIME_FREQUENCY.YEARLY) {
      xAxis.push({
        dataKey: XAXIS_DATA_KEY.DATE,
        axisLine: false,
        tickLine: false,
        interval: 0,
        calcTickByWidth: (width) =>
          renderYearTick(
            data[0].date,
            getDataByTimeFrequency(
              data,
              TIME_FREQUENCY.YEARLY,
              timeZone,
              chartSchema.charts.map(
                (chart) => chart.dataKeyDuplicate || chart.dataKey,
              ),
            ).map((item) => item.date),
            getMiddleDateOfEachYears(data, timeZone, width),
          ),
        height: 1,
        xAxisId: GROUP_BY_YEAR,
      })
    }
  }

  // Update custom yAxis
  chartSchema.yAxis = setCustomYAxis(chartSchema.yAxis, listYAxisCustom)

  // Fake yAxis to have space around chart
  if (!chartSchema.yAxis.some((item) => item.orientation === 'right')) {
    chartSchema.yAxis.push({
      yAxisId: ID_CHART_DUMMY.DUMMY_RIGHT_ID,
      dataKeys: [],
      orientation: 'right',
      width: 32,
      tickFormatter: () => '',
      hasReferenceLabel: false,
    })
  }

  if (!chartSchema.yAxis.some((item) => item.orientation === 'left')) {
    chartSchema.yAxis.push({
      yAxisId: ID_CHART_DUMMY.DUMMY_LEFT_ID,
      dataKeys: [],
      orientation: 'left',
      width: 32,
      tickFormatter: () => '',
    })
  }

  // Final result
  const result = {
    xAxis,
    yAxis: chartSchema.yAxis,
    charts: chartSchema.charts,
    tooltips: Object.values(chartSchema.tooltips),
    hasMouseCoordinateY: true,
    hasCartesianGrid: !!settings.gridDisplay,
    hasReferenceLabel: !!settings.chartLegend,
  }

  return {
    chartSchema: result,
    chartFooter: Object.values(chartSchema.footerChart),
  }
}

const getDetailFormatChartSchema = (chartSchema, item, formatName, locale) => {
  if (item.visibility) {
    const stackKey = getStackKey(item, locale)
    const {
      indicatorChart,
      indicatorFooterChart,
      indicatorYAxis,
      indicatorTooltip,
      indicatorStackData,
    } = getChartSchemaByIndicator(
      item,
      formatName,
      Object.keys(chartSchema.charts).length,
    )

    // Format detail chart schema
    chartSchema.yAxis = { ...chartSchema.yAxis, ...indicatorYAxis }
    chartSchema.charts = { ...chartSchema.charts, ...indicatorChart }
    chartSchema.footerChart = {
      ...chartSchema.footerChart,
      ...indicatorFooterChart,
    }
    chartSchema.tooltips = { ...chartSchema.tooltips, ...indicatorTooltip }
    chartSchema.stackData[stackKey] = {
      ...(chartSchema.stackData[stackKey] || {}),
      ...indicatorStackData,
      dataKeys: chartSchema.stackData[stackKey]
        ? [
            ...chartSchema.stackData[stackKey].dataKeys,
            ...indicatorStackData.dataKeys,
          ]
        : indicatorStackData.dataKeys,
      indicatorIds: chartSchema.stackData[stackKey]
        ? [
            ...chartSchema.stackData[stackKey].indicatorIds,
            ...indicatorStackData.indicatorIds,
          ]
        : indicatorStackData.indicatorIds,
    }
  }

  return chartSchema
}

const getChartSchemaByIndicator = (item, formatName, chartOrder) => {
  const decimalLength =
    typeof item.decimalPlace === 'number' ? item.decimalPlace : 2
  let multiplier = 1
  let dataUnit = '%'

  if (item.indicatorUnit !== '%') {
    multiplier = item.multiplier
    dataUnit = item.indicatorUnit || item.equationUnit
  }

  if (item.equationFormatUnit && item.equationType !== equationType.COUNT) {
    switch (item.equationFormatUnit) {
      case equationFormatUnitConstants.EQUATION_FORMAT_PERCENT:
        multiplier = 1
        dataUnit = '%'
        break
      case equationFormatUnitConstants.EQUATION_FORMAT_TIMES:
        multiplier = 1
        dataUnit = I18n.t('financialChart.EQUATION_FORMAT_TIMES')
        break
      default:
        dataUnit = 'VND'
        break
    }
  }

  if (item.equationType === equationType.COUNT) {
    multiplier = 1
    dataUnit = I18n.t('financialChart.EQUATION_COUNT_UNIT_FIRMS')
  }

  if (
    item.dataFormatType === dataFormatTypes.PERCENT ||
    item.dataFormatType === dataFormatTypes.PREV_PERCENT
  ) {
    multiplier = item.multiplier
    dataUnit = '%'
  }

  const dataKey = item.dataKeyDuplicate || item.dataKey

  const yAxis = {
    [dataKey]: {
      ...item.chartParameters,
      decimalLength: decimalLength,
    },
  }
  const chart = {
    [dataKey]: {
      chartType: item.chartType || CHART_TYPES.LINE,
      yAxisId: dataKey,
      dataKey:
        item.chartParameters?.dataKeysDuplicate?.join('') ||
        item.chartParameters.dataKeys.join(''),
      color: item.color,
      dataType: item.dataFormatType,
      numberPeriod: item.numberPeriod,
      multiplier: multiplier,
      dataUnit: dataUnit,
      decimalLength: decimalLength,
      isDisplayDataLabel: item.isDisplayDataLabel,
      formatName: formatName,
      strokeDasharray:
        item.lineStyle !== lineStyleTypes.DOTTED
          ? strokeDasharrayTypes[item.lineStyle || lineStyleTypes.SOLID]
          : strokeDasharrayTypes[lineStyleTypes.DOTTED]
              .split(' ')
              .map(
                (val) =>
                  +val *
                  strokeWidthTypes[item.lineWidth || lineWidthTypes.NORMAL],
              )
              .join(' '),
      dot: !!item.dotsOnLine,
      strokeWidth: strokeWidthTypes[item.lineWidth || lineWidthTypes.NORMAL],
      type: lineTypes[
        typeof item.smoothLine === 'boolean' ? item.smoothLine : smoothLines.YES
      ],
      dotType: item.dotType,
      dotWidth: item.dotWidth,
      chartOrder: chartOrder,
    },
  }
  const footerChart = {
    [dataKey]: {
      color: item.color,
      text: formatName,
      type: MAP_TYPE_CHART_FOOTER[
        item.dotsOnLine || item.chartType === 'DOT'
          ? [item.chartType, item.dotType].join('_')
          : item.chartType
      ],
      isI18n: false,
      id: dataKey,
    },
  }
  const tooltips = {
    [dataKey]: {
      dataKey: dataKey,
      formatName: formatName,
      decimalLength: decimalLength,
      dataUnit:
        dataUnit === '%'
          ? dataUnit
          : getDataUnitWithMultiplier(dataUnit, multiplier),
    },
  }
  const stackData = {
    dataKeys: [dataKey],
    chartType: item.chartType,
    dataFormatType: item.dataFormatType,
    indicatorIds: [item.equationType ? dataKey : item.indicatorId],
    unit: dataUnit,
    multiplier: multiplier,
    decimalLength: decimalLength,
  }

  const { SMChart, SMFooterChart, SMTooltips } =
    getChartSchemaByStatisticalMethods(
      item,
      formatName,
      chart,
      footerChart,
      tooltips,
    )

  return {
    indicatorYAxis: yAxis,
    indicatorChart: SMChart,
    indicatorFooterChart: SMFooterChart,
    indicatorTooltip: SMTooltips,
    indicatorStackData: stackData,
  }
}

const getChartSchemaByStatisticalMethods = (
  item,
  formatName,
  chart,
  footerChart,
  tooltips,
) => {
  if (
    item.statisticalMethods &&
    Object.values(item.statisticalMethods).some((method) => method.isCheck)
  ) {
    let decimalLength = 2
    let multiplier = 1
    let dataUnit = '%'
    if (item.indicatorUnit !== '%') {
      decimalLength =
        typeof item.decimalPlace === 'number' ? item.decimalPlace : 2
      multiplier = item.multiplier
      dataUnit =
        item.dataFormatType === dataFormatTypes.VALUE
          ? item.indicatorUnit || item.equationUnit
          : '%'
    }

    const dataKeyMap = item.dataKeyDuplicate || item.dataKey
    chart[dataKeyMap] = {
      ...chart[dataKeyMap],
      referenceLineYAxisId: `${dataKeyMap}_statisticalMethods`,
      statisticalMethods: item.statisticalMethods,
    }
    Object.values(item.statisticalMethods)
      .filter((method) => method.isCheck)
      .forEach((method) => {
        const dataKey = `${dataKeyMap}_${method.value}`
        chart[dataKey] = {
          chartType: CHART_TYPES.LINE,
          yAxisId: `${dataKeyMap}`,
          referenceLineYAxisId: `${dataKeyMap}_statisticalMethods`,
          dataKey: dataKey,
          color: method.color,
          dataType: item.dataFormatType,
          multiplier: multiplier,
          dataUnit: dataUnit,
          formatName: `${I18n.t(
            statisticalMethodDisplay[method.value],
          )} (${formatName})`,
          strokeDasharray:
            method.lineStyle !== lineStyleTypes.DOTTED
              ? strokeDasharrayTypes[method.lineStyle || lineStyleTypes.SOLID]
              : strokeDasharrayTypes[lineStyleTypes.DOTTED]
                  .split(' ')
                  .map(
                    (val) =>
                      +val *
                      strokeWidthTypes[
                        method.lineWidth || lineWidthTypes.NORMAL
                      ],
                  )
                  .join(' '),
          dot: false,
          strokeWidth:
            strokeWidthTypes[method.lineWidth || lineWidthTypes.NORMAL],
          type: lineTypes[smoothLines.NO],
        }

        footerChart[dataKey] = {
          color: method.color,
          text: `${I18n.t(
            statisticalMethodDisplay[method.value],
          )} (${formatName})`,
          type: MAP_TYPE_CHART_FOOTER[CHART_TYPES.LINE],
          isI18n: false,
          id: dataKey,
        }

        tooltips[dataKey] = {
          dataKey: dataKey,
          formatName: `${I18n.t(
            statisticalMethodDisplay[method.value],
          )} (${formatName})`,
          decimalLength: decimalLength,
          dataUnit:
            dataUnit === '%'
              ? dataUnit
              : getDataUnitWithMultiplier(dataUnit, multiplier),
        }
      })
  }

  return {
    SMChart: chart,
    SMFooterChart: footerChart,
    SMTooltips: tooltips,
  }
}

const getYAxisByStack = (
  yAxis,
  charts,
  stackData,
  settings,
  equations,
  getIndicator,
) => {
  const yAxisFormat = []

  Object.values(stackData).forEach((item) => {
    const orientation = getOrientationByDataKeys(yAxis, item.dataKeys, settings)
    const labelPosition = getLabelPositionByOrientation(orientation, settings)
    const yAxisData = {
      dataKeys: item.dataKeys,
      yAxisId: item.dataKeys.join('-'),
      orientation,
      labelText: getLabelTextByIndicators(item, getIndicator, equations),
      labelPosition,
      labelColor: '#4e4e4e',
      decimalLength: item.decimalLength,
    }

    switch (item.chartType) {
      case CHART_TYPES.LINE:
        yAxisData.isLineChart = true
        break
      case CHART_TYPES.BAR:
        yAxisData.isBarChart = true
        break
      case CHART_TYPES.STACK_AREA:
        yAxisData.isStackArea = true
        break
      case CHART_TYPES.STACK_BAR:
        yAxisData.isStackedBar = true
        break
      default:
        break
    }

    if (item.unit === '%') {
      yAxisData.tickFormatter = (val) =>
        valToPercent(val, true, false, item.decimalLength)
    }

    yAxisFormat.push(yAxisData)
  })

  return {
    yAxisFormat,
    chartsFormat: getChartsByStack(charts, stackData),
  }
}

const getChartsByStack = (charts, stackData) => {
  const chartsByStack = { ...charts }

  Object.values(stackData).forEach((item) => {
    item.dataKeys.forEach((key) => {
      chartsByStack[key] = {
        ...chartsByStack[key],
        yAxisId: item.dataKeys.join('-'),
      }
      if (
        chartsByStack[key].referenceLineYAxisId &&
        chartsByStack[key].statisticalMethods
      ) {
        Object.values(chartsByStack[key].statisticalMethods).forEach(
          (method) => {
            const chartKey = `${chartsByStack[key].dataKey}_${method.value}`
            chartsByStack[chartKey] = {
              ...chartsByStack[chartKey],
              yAxisId: item.dataKeys.join('-'),
            }
          },
        )
      }
      if (
        [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(item.chartType)
      ) {
        chartsByStack[key].stackId = item.dataKeys.join('-')
      }
    })
  })

  return Object.values(chartsByStack)
}

const getYAxisByGroup = (
  yAxis,
  charts,
  stackData,
  settings,
  equations,
  getIndicator,
) => {
  const groupData = getGroupData(stackData)
  const yAxisFormat = Object.values(groupData).map((item) => {
    const orientation = getOrientationByDataKeys(yAxis, item.dataKeys, settings)
    const labelPosition = getLabelPositionByOrientation(orientation, settings)

    const isLineChart = item.dataKeys
      .flat()
      .every((dataKey) => charts[dataKey]?.chartType === CHART_TYPES.LINE)
    const isStackChart = item.dataKeys.some((childItem) => {
      return (
        Array.isArray(childItem) &&
        childItem.length > 1 &&
        childItem.filter((dataKey) =>
          [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(
            charts[dataKey]?.chartType,
          ),
        ).length > 1
      )
    })

    const yAxisData = {
      dataKeys: item.dataKeys,
      yAxisId: item.dataKeys.flat().join('-'),
      orientation,
      labelText: getLabelTextByIndicators(item, getIndicator, equations),
      labelPosition,
      labelColor: '#4e4e4e',
      decimalLength: item.decimalLength,
      isLineChart: isLineChart,
      isStackedBar: isStackChart,
      isGroupBar: !isLineChart,
    }

    if (item.unit === '%') {
      yAxisData.tickFormatter = (val) =>
        valToPercent(val, true, false, item.decimalLength)
    }

    return yAxisData
  })

  return {
    yAxisFormat,
    chartsFormat: getChartsByGroup(charts, groupData),
  }
}

const getGroupData = (stackData) => {
  return Object.values(stackData).reduce((obj, item) => {
    const groupKey = getGroupKey(item)
    return {
      ...obj,
      [groupKey]: {
        ...(obj[groupKey] || {}),
        ...item,
        dataKeys: obj[groupKey]
          ? [...obj[groupKey].dataKeys, item.dataKeys]
          : [item.dataKeys],
        indicatorIds: obj[groupKey]
          ? [...obj[groupKey].indicatorIds, item.indicatorIds]
          : [item.indicatorIds],
        chartType: !obj[groupKey]?.chartType
          ? item.chartType
          : [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(
              obj[groupKey].chartType,
            )
          ? obj[groupKey].chartType
          : item.chart,
      },
    }
  }, {})
}

const getChartsByGroup = (charts, groupData) => {
  const chartsByStack = { ...charts }

  Object.values(groupData).forEach((items) => {
    items.dataKeys.forEach((item) => {
      item.forEach((key) => {
        chartsByStack[key] = {
          ...chartsByStack[key],
          yAxisId: items.dataKeys.flat().join('-'),
        }
        if (
          chartsByStack[key].referenceLineYAxisId &&
          chartsByStack[key].statisticalMethods
        ) {
          Object.values(chartsByStack[key].statisticalMethods).forEach(
            (method) => {
              const chartKey = `${chartsByStack[key].dataKey}_${method.value}`
              chartsByStack[chartKey] = {
                ...chartsByStack[chartKey],
                yAxisId: items.dataKeys.flat().join('-'),
              }
            },
          )
        }
        if (
          item.length > 1 &&
          [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(
            items.chartType,
          )
        ) {
          chartsByStack[key].stackId = item.join('-')
        }
      })
    })
  })

  return Object.values(chartsByStack)
}

const getStackKey = (item, locale) => {
  if (
    [CHART_TYPES.STACK_AREA, CHART_TYPES.STACK_BAR].includes(item.chartType)
  ) {
    const equationUnit = item.equationFormatUnit
      ? equationFormatUnit[item.equationFormatUnit][locale]
      : ''

    if (
      item.dataFormatType === dataFormatTypes.PERCENT ||
      item.dataFormatType === dataFormatTypes.PREV_PERCENT
    ) {
      return `${item.chartType}_${item.dataFormatType}_${
        item.indicatorUnit || equationUnit
      }`
    }
    return `${item.chartType}_${item.dataFormatType}_${
      item.indicatorUnit || equationUnit
    }_${item.multiplier}`
  }

  return (
    item.dataKeyBySecurityDuplicate ||
    item.dataKeyDuplicate ||
    item.dataKeyBySecurity ||
    item.dataKey
  )
}

const getGroupKey = (item) => {
  return `${item.unit}_${item.multiplier}`
}

const getOrientationByDataKeys = (yAxis, dataKeys, settings) => {
  if (settings.yAxisLabel) return 'right'

  const isLeft = dataKeys
    .flat()
    .some((key) => yAxis[key] && yAxis[key].orientation === 'left')
  return isLeft ? 'left' : 'right'
}

const getLabelPositionByOrientation = (orientation, settings) => {
  if (settings.yAxisLabel) {
    return AXIS_LABEL_POSITION.RIGHT
  }
  if (orientation === 'left') {
    if (settings.positionYAxisLabel === positionYAxis.ABOVE_Y_AXIS) {
      return AXIS_LABEL_POSITION.TOP_LEFT
    } else {
      return AXIS_LABEL_POSITION.LEFT
    }
  }
  if (orientation === 'right') {
    if (settings.positionYAxisLabel === positionYAxis.ABOVE_Y_AXIS) {
      return AXIS_LABEL_POSITION.TOP_RIGHT
    } else {
      return AXIS_LABEL_POSITION.RIGHT
    }
  }
}

const getLabelTextByIndicators = (item, getIndicator, equations) => {
  const equationsById = Object.values(equations).reduce(
    (obj, item) => ({
      ...obj,
      [item.dataKey]: {
        name: item.equationIndicatorName,
        unit: item.equationUnit,
      },
    }),
    {},
  )
  const ids = item.indicatorIds
    .flat()
    .filter((id, index, arr) => index === arr.findIndex((val) => val === id))

  if (ids.length > 3) {
    return !item.unit || item.unit === '%'
      ? item.unit
      : getDataUnitWithMultiplier(item.unit, item.multiplier)
  } else {
    let indicatorUnit = ''

    return `${ids
      .map((id) => {
        const indicator = getIndicator(id)
        const equation = equationsById[id]

        if (!indicatorUnit && (indicator || equation)) {
          indicatorUnit = indicator?.unit || equation?.unit
        }

        return indicator?.secName || indicator?.name || equation?.name
      })
      .filter((val) => !!val)
      .join(', ')} (${
      !(item.unit || indicatorUnit) || (item.unit || indicatorUnit) === '%'
        ? item.unit || indicatorUnit
        : getDataUnitWithMultiplier(item.unit || indicatorUnit, item.multiplier)
    })`
  }
}

const renderYearTick =
  (firstDateData, lastDateOfEachYears, middleDateOfEachYears) =>
  (tickProps) => {
    const { x, y, payload } = tickProps
    const { value } = payload

    if (middleDateOfEachYears.includes(value)) {
      return (
        <text x={x} y={y} textAnchor="middle">
          {new Date(value).getFullYear()}
        </text>
      )
    }

    if ([firstDateData, ...lastDateOfEachYears].includes(value)) {
      const pathX = x + 0.5
      return <path d={`M${pathX},${y}v${-16}`} stroke="#bebebe" />
    }

    return null
  }

const getMiddleDateOfEachYears = (data, timeZone, width) => {
  const middleDateOfEachYears = []
  const countDateOfEachYears = []
  let yearData = []
  let compareYear =
    (data.length &&
      getDateWithTimezone(data[0].date, timeZone).getFullYear()) ||
    null

  const setMiddleDateOfYear = (item) => {
    const middleDateIndex = Math.ceil(yearData.length / 2) - 1
    middleDateOfEachYears.push(yearData[middleDateIndex])
    countDateOfEachYears.push(yearData.length)
    yearData = [item.date]
    compareYear = getDateWithTimezone(item.date, timeZone).getFullYear()
  }

  data.forEach((item, index) => {
    if (
      compareYear === getDateWithTimezone(item.date, timeZone).getFullYear()
    ) {
      yearData.push(item.date)
      if (index === data.length - 1) {
        setMiddleDateOfYear(item)
      }
    } else {
      setMiddleDateOfYear(item)
    }
  })

  const middleDateValid = []
  countDateOfEachYears.forEach(
    (item, index) =>
      getWidthForItem(width, data, item) > YEAR_TEXT_MAX_WIDTH &&
      middleDateValid.push(middleDateOfEachYears[index]),
  )

  return middleDateValid
}

const getWidthForItem = (width, data, countDate) => {
  if (data.length === 0 || typeof width !== 'number') return 0
  const ratio = width / data.length
  return countDate * ratio
}

const setCustomYAxis = (yAxis, listYAxisCustom) => {
  return yAxis.map((item) => {
    const yAxisId = item?.yAxisIdDuplicate || item.yAxisId
    if (listYAxisCustom[yAxisId]) {
      if (listYAxisCustom[yAxisId].labelText) {
        item.labelText = listYAxisCustom[yAxisId].labelText
      }
      item.orientation = listYAxisCustom[yAxisId].orientation
      const labelPosition = getYAxisLabelPosition(
        item,
        listYAxisCustom[yAxisId].orientation,
      )
      if (labelPosition) {
        item.labelPosition = labelPosition
      }
    }

    return item
  })
}

const getYAxisLabelPosition = (yAxis, orientation) => {
  switch (orientation) {
    case 'left':
      return yAxis.labelPosition.replace(
        AXIS_LABEL_POSITION.RIGHT,
        AXIS_LABEL_POSITION.LEFT,
      )
    case 'right':
      return yAxis.labelPosition.replace(
        AXIS_LABEL_POSITION.LEFT,
        AXIS_LABEL_POSITION.RIGHT,
      )
    default:
      return
  }
}
