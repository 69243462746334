import { createSlice } from '@reduxjs/toolkit'
import { invertKeyBy, keyBy } from '../../../../../../utils/Common'
// import { FORMAT, getCurrentDateTime } from '../../../../../../utils/Datetime'
import { getIdsFromProps } from '../../../../../../common/table/helper'
import { FORMAT, getCurrentDateTime } from '../../../../../../utils/Datetime'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { tableConstants, valueTimefrequency } from '../constants'
import { getDataTopCirculate, getDataTopRealse } from './thunk.js'

const currentDate = new Date()
const currentMonth = currentDate.getMonth() + 1
const currentQuarter = Math.floor(currentMonth / 3) + 1
const currentYear = currentDate.getFullYear()

const initialState = {
  activeTab: valueTimefrequency.ISSUE,
  financialById: {},
  ids: [],
  initialIds: [],
  idsCirculate: [],
  groupColumns: tableConstants,
  data: [],
  dataCirculate: [],
  initialIdsCirculate: [],
  isLoading: false,
  filter: {
    career: '',
    quarter: currentQuarter,
    month: currentMonth,
    year: currentYear,
    DateCheck: getCurrentDateTime(FORMAT.DATE),
  },
}

export const slice = createSlice({
  name: 'demo/overview/financialMetric',
  initialState,
  reducers: {
    updateTab(state, actions) {
      state.activeTab = actions.payload
    },
    updateFilter: (state, actions) => {
      state.filter = {
        ...state.filter,
        [actions.payload.key]: actions.payload.value,
      }
    },
    updateMultiFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      }
    },
    updateGroupColums: (state, actions) => {
      state.groupColumns = actions.payload
    },
    sortData(state, actions) {
      if (actions.payload.hasOwnProperty('icbName')) {
        const data = state.data
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['icbName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.icbName.localeCompare(b?.icbName, actions.payload.locale),
          )
          state.ids = idList.map((e) => e.organizationId)
        }
        if (actions.payload['icbName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.icbName.localeCompare(b?.icbName, actions.payload.locale),
            )
            .reverse()
          state.ids = idList.map((e) => e.organizationId)
        }
        if (actions.payload['icbName'] === undefined) {
          state.ids = state.initialIds
        }
        return
      }
      if (actions.payload.hasOwnProperty('organizationShortName')) {
        const data = state.data
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['organizationShortName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.organizationShortName.localeCompare(
              b?.organizationShortName,
              'vi',
            ),
          )
          state.ids = idList.map((e) => e.organizationId)
        }
        if (actions.payload['organizationShortName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.organizationShortName.localeCompare(
                b?.organizationShortName,
                'vi',
              ),
            )
            .reverse()
          state.ids = idList.map((e) => e.organizationId)
        }
        if (actions.payload['organizationShortName'] === undefined) {
          state.ids = state.initialIds
        }
        return
      }
      state.ids = getIdsFromProps(
        state.ids,
        state.data,
        actions.payload,
        state.initialIds,
        0,
      )
    },
    sortDataCirculate(state, actions) {
      if (actions.payload.hasOwnProperty('icbName')) {
        const data = state.dataCirculate
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['icbName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.icbName.localeCompare(b?.icbName, actions.payload.locale),
          )
          state.idsCirculate = idList.map((e) => e.stt)
        }
        if (actions.payload['icbName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.icbName.localeCompare(b?.icbName, actions.payload.locale),
            )
            .reverse()
          state.idsCirculate = idList.map((e) => e.stt)
        }
        if (actions.payload['icbName'] === undefined) {
          state.idsCirculate = state.initialIdsCirculate
        }
        return
      }
      if (actions.payload.hasOwnProperty('organizationShortName')) {
        const data = state.dataCirculate
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['organizationShortName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.organizationShortName.localeCompare(
              b?.organizationShortName,
              'vi',
            ),
          )
          state.idsCirculate = idList.map((e) => e.stt)
        }
        if (actions.payload['organizationShortName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.organizationShortName.localeCompare(
                b?.organizationShortName,
                'vi',
              ),
            )
            .reverse()
          state.idsCirculate = idList.map((e) => e.stt)
        }
        if (actions.payload['organizationShortName'] === undefined) {
          state.idsCirculate = state.initialIdsCirculate
        }
        return
      }
      state.idsCirculate = getIdsFromProps(
        state.idsCirculate,
        state.dataCirculate,
        actions.payload,
        state.initialIdsCirculate,
        0,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataTopRealse.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataTopRealse.fulfilled, (state, action) => {
      const data = keyBy(action.payload, 'organizationId')
      state.ids = action.payload.map((e) => e.organizationId)
      state.initialIds = action.payload.map((e) => e.organizationId)
      state.data = data
      state.isLoading = false
    })
    builder.addCase(getDataTopRealse.rejected, (state, action) => {
      state.ids = []
      state.initialIds = []
      state.data = {}
      state.isLoading = action.payload
    })

    builder.addCase(getDataTopCirculate.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataTopCirculate.fulfilled, (state, action) => {
      const data = keyBy(action.payload, 'stt')
      state.idsCirculate = action.payload.map((e) => e.stt)
      state.dataCirculate = data
      state.initialIdsCirculate = action.payload.map((e) => e.stt)
      state.isLoading = false
    })
    builder.addCase(getDataTopCirculate.rejected, (state, action) => {
      state.idsCirculate = []
      state.dataCirculate = {}
      state.initialIdsCirculate = []
      state.isLoading = action.payload
    })
  },
})

export const selectIds = (state) => state[slice.name].ids
export const selectIdsCirculate = (state) => state[slice.name].idsCirculate

export const selectFinancialValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].data[id], attr)
}
export const selectTopCirculateValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dataCirculate[id], attr)
}
export const selectDataTopRelease = (state) => state[slice.name].data
export const selectDataCirculateTopRelease = (state) =>
  state[slice.name].dataCirculate

export const selectLoading = (state) => state[slice.name].isLoading
export const selectGroupColumns = (state) => state[slice.name].groupColumns
export const selectTimeFilter = (state) => state[slice.name].activeTab
export const selectFilterTopRelease = (state) => state[slice.name].filter

export const {
  updateTab,
  updateFilter,
  updateGroupColums,
  sortData,
  sortDataCirculate,
  updateMultiFilter,
} = slice.actions

register(slice.name, slice.reducer)
