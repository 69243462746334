import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import { ACTIVE_STATUS_TYPES } from '../constants'

export const FundStatus = ({ value, onChange }) => {
  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={180}
        isOptionAll
        isI18n
        textEmpty="fund.fundCenter.ALL_STATUS"
        textAll="fund.fundCenter.ALL_STATUS"
        options={ACTIVE_STATUS_TYPES}
        onChange={(value) => onChange({ status: value })}
      />
    </div>
  )
}
