import { useEffect, useState } from 'react'
import { DataFormatComponent } from './components/DataFormatComponent'
import { DecimalPlacesComponent } from './components/DecimalPlacesComponent'
import { RadioComponent } from './components/RadioComponent'
import { dataFormatTypes, displayDataLabel, multiplierTypes } from './constants'
import styles from './style.module.css'

const DataFormatSettings = ({ isEquation, isBySecurities, data, setData }) => {
  const [dataFormatType, setDataFormatType] = useState(dataFormatTypes.VALUE)
  const [multiplier, setMultiplier] = useState(multiplierTypes.RAW)
  const [decimalPlace, setDecimalPlace] = useState(2)
  const [isDisplayDataLabel, setIsDisplayDataLabel] = useState(
    displayDataLabel.NO,
  )
  const [numberPeriod, setNumberPeriod] = useState(4)

  const handleChangeTab = (value) => {
    setDataFormatType(value)
  }

  useEffect(() => {
    setData({
      ...data,
      dataFormatType,
      multiplier,
      decimalPlace,
      isDisplayDataLabel,
      numberPeriod,
    })
  }, [
    dataFormatType,
    multiplier,
    decimalPlace,
    isDisplayDataLabel,
    numberPeriod,
  ])

  useEffect(() => {
    if (data.dataFormatType !== dataFormatType) {
      data.dataFormatType && setDataFormatType(data.dataFormatType)
    }
    if (data.multiplier !== multiplier) {
      data.multiplier && setMultiplier(data.multiplier)
    }
    if (data.decimalPlace !== decimalPlace) {
      typeof data.decimalPlace === 'number' &&
        setDecimalPlace(data.decimalPlace)
    }
    if (data.isDisplayDataLabel !== isDisplayDataLabel) {
      data.isDisplayDataLabel && setIsDisplayDataLabel(data.isDisplayDataLabel)
    }
    if (data.numberPeriod !== numberPeriod) {
      typeof data.numberPeriod === 'number' &&
        setNumberPeriod(data.numberPeriod)
    }
  }, [data])

  return (
    <div className={styles.settingBlockContainer}>
      <DataFormatComponent
        isParent
        isEquation={isEquation}
        isBySecurities={isBySecurities}
        value={dataFormatType}
        periodValue={numberPeriod}
        onChange={handleChangeTab}
        onChangePeriod={setNumberPeriod}
      />
      {dataFormatType === dataFormatTypes.VALUE && (
        <RadioComponent
          title="financialChart.chartSettings.MULTIPLIER"
          value={multiplier}
          options={Object.keys(multiplierTypes).map((key) => ({
            title: 'financialChart.' + key,
            value: multiplierTypes[key],
          }))}
          onChange={setMultiplier}
          radioClass={'justify-content-space-between ali-center'}
        />
      )}
      <DecimalPlacesComponent value={decimalPlace} onChange={setDecimalPlace} />
      <RadioComponent
        title="financialChart.chartSettings.DATA_LABEL"
        value={isDisplayDataLabel}
        options={Object.keys(displayDataLabel).map((key) => ({
          title: 'financialChart.' + key,
          value: displayDataLabel[key],
        }))}
        onChange={setIsDisplayDataLabel}
        itemClass={styles.settingBlockItemDataFormatRadio}
      />
    </div>
  )
}

export default DataFormatSettings
