import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { keyBy } from '../../../../utils/Common'
import { TABLE_ROW_LEVEL } from '../constants'
import {
  changeIds,
  selectGroupColumns,
  selectIds,
  selectIndicatorById,
  selectIndicatorLoading,
  selectIndicatorValueByCell,
  selectLoading,
  selectTableConstants,
} from '../store/slice'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import { withGetData } from './withGetData'

const FinancialStatementTable = () => {
  // Use selector
  const ids = useSelector(selectIds)
  const tableConstants = useSelector(selectTableConstants)
  const isLoading = useSelector(selectLoading)
  const isIndicatorLoading = useSelector(selectIndicatorLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const indicatorById = useSelector(selectIndicatorById)

  // Use state
  const [rowsCollapse, setRowsCollapse] = useState([])

  // Actions
  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  if (isLoading || isIndicatorLoading) {
    return <Loading />
  }

  if (!groupColumns.length || !Object.keys(indicatorById).length) {
    return <NoData />
  }

  const renderTable = () => {
    const levelCollapse = keyBy(
      tableConstants.map((v) => ({
        index: v.index,
        level: v.level,
        isRowCollapse: v.isCollapse,
      })),
      'index',
    )

    return (
      <Table
        ids={ids}
        columnDraggable={false}
        getDataFromRedux={selectIndicatorValueByCell}
        isLoading={isLoading || isIndicatorLoading}
        rowDraggable={false}
        changeIds={changeIds}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'left',
                fontSize: 10,
              },
              canCustomTd: true,
              render: (val, rowId, { style, className }) => {
                return (
                  <FirstColumnCell
                    val={val}
                    rowId={rowId}
                    style={{ ...style, minWidth: 400 }}
                    className={className}
                    defaultActiveRowId={ids[1]}
                    onRowCollapse={onRowCollapse}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, { style, className }) => {
              return (
                <OtherColumnsCell
                  val={val}
                  rowId={rowId}
                  className={className}
                  columnItem={item}
                />
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        disableClickGroups={tableConstants
          .filter((item) => item.level === TABLE_ROW_LEVEL.LEVEL_0)
          .map((item) => item.index)}
        defaultActiveRowId={ids[1]}
        resizable={false}
        defaultScrollToRight={true}
        isCollapse={true}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
      />
    )
  }

  return renderTable()
}

export default withGetData(FinancialStatementTable)
