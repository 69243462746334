import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  selectDataFDF,
  selectSetting,
  selectShowPreview,
} from '../../filter/store/slice'
import style from '../../preview/style.module.css'
import ForeignNetTradingChart from './ForeignNetTradingChart'
import PriceDataTable from './PriceDataTable'
import PricePerformanceTable from './PricePerformanceTable'
import StockPerformanceVsIndexChart from './StockPerformanceVsIndexChart'

const MarketData = () => {
  const data = useSelector(selectDataFDF)
  const setting = useSelector(selectSetting)
  const showPreview = useSelector(selectShowPreview)

  const [positionTitle, setPositionTitle] = useState(0)

  const objMapPositionTitle = {
    priceData: 0,
    pricePerformance: 1,
    stockPerformanceVsIndex: 2,
    foreignNetBuyingSelling: 3,
  }

  useEffect(() => {
    const array = [
      setting.priceData,
      setting.pricePerformance,
      setting.stockPerformanceVsIndex,
      setting.foreignNetBuyingSelling,
    ]
    setPositionTitle(array.findIndex((e) => e === true))
  }, [
    setting.priceData,
    setting.pricePerformance,
    setting.stockPerformanceVsIndex,
    setting.foreignNetBuyingSelling,
  ])

  return (
    <>
      <div>
        {(positionTitle === objMapPositionTitle.priceData ||
          positionTitle === objMapPositionTitle.pricePerformance) &&
        (data.stockPerformanceVsIndex.length ||
          data.foreignNetBuyingSelling.length) &&
        showPreview ? (
          <div className={style.textBackgroundBlue}>
            <Translate value="corporate.companySnapshot.MARKET_DATA" />
          </div>
        ) : null}
        <div
          className={`d-flex ${style.paddingTop8}`}
          style={{ paddingBottom: 24 }}
        >
          <div className={style.wGroup50l}>
            {setting.priceData && data.priceData.ids.length ? (
              <div>
                <div
                  className="d-flex justify-content-space-between align-center"
                  style={{
                    borderBottom: '5px solid #a7d3ef',
                    marginLeft: '8px',
                  }}
                >
                  <div
                    style={{
                      fontSize: 16.5,
                      fontWeight: 400,
                      padding: '0px 0px 10px 12px',
                    }}
                  >
                    <Translate value="corporate.companySnapshot.PRICE_DATA" />
                  </div>
                  <div
                    style={{
                      fontSize: 10,
                      fontWeight: 300,
                      padding: '0px 0px 10px 12px',
                      fontStyle: 'italic',
                    }}
                  >
                    <Translate value="corporate.companySnapshot.ADJUSTED_PRICE" />
                  </div>
                </div>
                <PriceDataTable />
              </div>
            ) : null}
          </div>
          <div className={style.wGroup50r}>
            {setting.pricePerformance &&
            data.pricePerformance.groupColumns.length ? (
              <>
                <div className={style.titleGroup}>
                  <Translate value="corporate.companySnapshot.PRICE_PERFORMANCE" />
                </div>
                <PricePerformanceTable />
              </>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        {(positionTitle === objMapPositionTitle.stockPerformanceVsIndex ||
          positionTitle === objMapPositionTitle.foreignNetBuyingSelling) &&
          showPreview && (
            <div className={style.textBackgroundBlue}>
              <Translate value="corporate.companySnapshot.MARKET_DATA" />
            </div>
          )}
        <div className={`d-flex ${style.paddingTop8}`}>
          <div className={style.wGroup45l}>
            {setting.stockPerformanceVsIndex &&
            data.stockPerformanceVsIndex.length ? (
              <>
                <div className={style.titleGroup}>
                  <Translate value="corporate.companySnapshot.STOCK_PERFORMANCE_VS_INDEX" />
                </div>
                <div style={{ height: 250 }}>
                  <StockPerformanceVsIndexChart />
                </div>
              </>
            ) : null}
          </div>
          <div className={style.wGroup55r}>
            {setting.foreignNetBuyingSelling &&
            data.foreignNetBuyingSelling.length ? (
              <>
                <div className={style.titleGroup}>
                  <Translate value="corporate.companySnapshot.FOREIGN_NET_BUYING_SELLING" />
                </div>
                <div style={{ height: 250 }}>
                  <ForeignNetTradingChart
                    data={data.foreignNetBuyingSelling}
                    width={390}
                    height={250}
                    keyXAxis="tradingDateId"
                    barKeys={['foreignBuyValueTotal', 'foreignSellValueTotal']}
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}

export default MarketData
