import { Charting } from '../../market/charting'
import { CoveredWarrant } from '../../market/coveredWarrant/overview'
import { CoveredWarrantStatistics } from '../../market/coveredWarrant/statistics'
import { Derivative } from '../../market/derivative'
import { DerivativeStatistic } from '../../market/derivativeStatistic'
import { DeepTransaction } from '../../market/equityTrading/deepTransaction'
import { PriceStatistics } from '../../market/equityTrading/priceStatistics'
import { Events } from '../../market/events'
import HeatMap from '../../market/heatmap'
import MarginLoans from '../../market/marginLoans'
import { DirectorDeal } from '../../market/marketInDepth/directorDeal'
import Foreign from '../../market/marketInDepth/foreign'
import { IndexStatistics } from '../../market/marketInDepth/indexStatistics'
import { Indices } from '../../market/marketInDepth/indices'
import { LocalInstitution } from '../../market/marketInDepth/localInstitution'
import { Valuation } from '../../market/marketInDepth/valuation'
import { NewsReport } from '../../market/newsReport'
import { NewsReportDetail } from '../../market/newsReport/NewsReportDetail'
import { NewsReportMore } from '../../market/newsReport/NewsReportMore'
import { SectorStatistics } from '../../market/sectorInDepth'
import { TopMover } from '../../market/topMover'

export const MARKET_ROUTE = {
  name: 'constants.route.market.LABEL',
  routeDefault: '/market/market-in-depth/indices',
  components: {
    'market-in-depth': {
      name: 'constants.route.market.marketInDepth.LABEL',
      hasChildren: true,
      components: {
        indices: {
          route: '/market/market-in-depth/indices',
          name: 'constants.route.market.marketInDepth.indices.LABEL',
          renderMain: () => <Indices />,
        },
        foreign: {
          route: '/market/market-in-depth/foreign',
          name: 'constants.route.market.marketInDepth.foreign.LABEL',
          renderMain: () => <Foreign />,
        },
        proprietary: {
          route: '/market/market-in-depth/proprietary',
          name: 'constants.route.market.marketInDepth.proprietary.LABEL',
          renderMain: () => <LocalInstitution />,
        },
        'director-deal': {
          route: '/market/market-in-depth/director-deal',
          name: 'constants.route.market.marketInDepth.directorDeal.LABEL',
          renderMain: () => <DirectorDeal />,
        },
        valuation: {
          route: '/market/market-in-depth/valuation',
          name: 'constants.route.market.marketInDepth.valuation.LABEL',
          renderMain: () => <Valuation />,
        },
        'index-statistics': {
          route: '/market/market-in-depth/index-statistics',
          name: 'constants.route.market.marketInDepth.indexStatistics.LABEL',
          renderMain: () => <IndexStatistics />,
        },
      },
    },
    'sector-statistics': {
      route: '/market/sector-statistics',
      name: 'constants.route.market.sectorStatistics.LABEL',
      renderMain: () => <SectorStatistics />,
    },
    'news-report': {
      route: '/market/news-report',
      relatedRoutes: ['/market/news-report-detail', '/market/news-report-more'],
      name: 'constants.route.market.newsReport.LABEL',
      renderMain: () => <NewsReport />,
    },
    'news-report-detail': {
      route: '/market/news-report-detail',
      name: 'constants.route.market.newsReport.LABEL',
      renderMain: () => <NewsReportDetail />,
      hiddenOnSideBar: true,
    },
    'news-report-more': {
      route: '/market/news-report-more',
      name: 'constants.route.market.newsReport.LABEL',
      renderMain: () => <NewsReportMore />,
      hiddenOnSideBar: true,
    },
    events: {
      route: '/market/events',
      name: 'constants.route.market.events.LABEL',
      renderMain: () => <Events />,
    },
    heatmap: {
      route: '/market/heatmap',
      name: 'constants.route.market.heatmap.LABEL',
      renderMain: () => <HeatMap />,
    },
    'top-mover': {
      route: '/market/top-mover',
      name: 'constants.route.market.topMover.LABEL',
      renderMain: () => <TopMover />,
    },
    'equity-trading': {
      name: 'constants.route.market.equityTrading.LABEL',
      hasChildren: true,
      components: {
        'deep-transaction': {
          route: '/market/equity-trading/deep-transaction',
          name: 'constants.route.market.equityTrading.deepTransaction.LABEL',
          renderMain: () => <DeepTransaction />,
        },
        'price-statistics': {
          route: '/market/equity-trading/price-statistics',
          name: 'constants.route.market.equityTrading.priceStatistics.LABEL',
          renderMain: () => <PriceStatistics />,
        },
      },
    },
    derivative: {
      name: 'constants.route.market.derivative.LABEL',
      hasChildren: true,
      components: {
        'deep-transaction': {
          route: '/market/derivative',
          name: 'constants.route.market.derivative.OVERVIEW',
          renderMain: () => <Derivative />,
        },
        'price-statistics': {
          route: '/market/derivative-statistics',
          name: 'constants.route.market.derivative.DERIVATIVE_STATISTICS',
          renderMain: () => <DerivativeStatistic />,
        },
      },
    },
    'covered-warrant': {
      name: 'constants.route.market.coveredWarrant.LABEL',
      hasChildren: true,
      components: {
        overview: {
          route: '/market/covered-warrant/overview',
          name: 'constants.route.market.coveredWarrant.overview.LABEL',
          renderMain: () => <CoveredWarrant />,
        },
        'cw-statistics': {
          route: '/market/covered-warrant/cw-statistics',
          name: 'constants.route.market.coveredWarrant.cwStatistics.LABEL',
          renderMain: () => <CoveredWarrantStatistics />,
        },
      },
    },
    margin_loans: {
      route: '/market/margin_loans',
      name: 'constants.route.market.marginLoans.LABEL',
      renderMain: () => <MarginLoans />,
    },
    charting: {
      route: '/market/charting',
      name: 'constants.route.market.charting.LABEL',
      renderMain: () => <Charting />,
    },
  },
}
