import packageProxy from '../../proxies/common/PackageProxy'
import { ServiceBase } from '../ServiceBase'

class PackageService extends ServiceBase {
  getPackageFunctions(params) {
    return this.applyMemoryCache(
      'PackageService.getPackageFunctions',
      params,
    )(() => packageProxy.getPackageFunctions(params))
  }

  getRequestRenewUpgradeAccount(params) {
    return this.getData(() =>
      packageProxy.getRequestRenewUpgradeAccount(params),
    )
  }
}

export default new PackageService()
