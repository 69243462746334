import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { chartYearOptions, quarterTypeOptions } from '../../../../constants'
import { getLoanStructureChartData } from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/bank/capitalStructure/loanStructure',
  initialState: {
    data: [],
    loading: true,
    yearType: chartYearOptions[0].value,
    quarterType: quarterTypeOptions[0].value,
    retryFlag: true,
  },
  reducers: {
    changeYearType: (state, action) => {
      state.yearType = action.payload
    },
    changeQuarterType: (state, action) => {
      state.quarterType = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoanStructureChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLoanStructureChartData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
    })
    builder.addCase(getLoanStructureChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartData = (state) => state[slice.name].data
export const selectChartLoading = (state) => state[slice.name].loading
export const selectYearType = (state) => state[slice.name].yearType
export const selectRetryFlag = (state) => state[slice.name].retryFlag
export const selectQuarterType = (state) => state[slice.name].quarterType
export const { changeYearType, changeQuarterType, changeRetryFlag } =
  slice.actions

register(slice.name, slice.reducer)
