import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TextBlink } from '../../../common/table/TextBlink'
import { getThreeColorByValue } from '../../../utils/Color'
import { formatVal } from '../../../utils/Value'

const TdColor = ({
  value,
  colId,
  keyColor,
  selectCell,
  isPercent,
  style,
  className,
  isTextBlink,
}) => {
  const colorValue = useSelector(selectCell(colId, keyColor))
  return (
    <td
      style={{ ...style, color: getThreeColorByValue(Number(colorValue)) }}
      className={className}
    >
      {isTextBlink ? (
        <TextBlink value={Number(String(value).replace('%', ''))}>
          {isPercent ? value : formatVal(value)}
        </TextBlink>
      ) : (
        <>{isPercent ? value : formatVal(value)}</>
      )}
    </td>
  )
}

TdColor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  colId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  keyColor: PropTypes.string.isRequired,
  selectCell: PropTypes.func.isRequired,
  isPercent: PropTypes.bool.isRequired,
}

TdColor.defaultProps = {
  isTextBlink: true,
}

export default TdColor
