export const priceDataTableCol = {
  overview: {
    DATE: 'tradingDateId',
    TOTAL_BUY_VOL: 'foreignBuyVolumeTotal',
    TOTAL_BUY_VAL: 'foreignBuyValueTotal',
    TOTAL_SELL_VOL: 'foreignSellVolumeTotal',
    TOTAL_SELL_VAL: 'foreignSellValueTotal',
    NET_TOTAL_VOL: 'foreignNetVolumeTotal',
    NET_TOTAL_VAL: 'foreignNetValueTotal',
    MATCH_BUY_VOL: 'foreignBuyVolumeMatched',
    MATCH_BUY_VAL: 'foreignBuyValueMatched',
    MATCH_SELL_VOL: 'foreignSellVolumeMatched',
    MATCH_SELL_VAL: 'foreignSellValueMatched',
    DEAL_BUY_VOL: 'foreignBuyVolumeDeal',
    DEAL_BUY_VAL: 'foreignBuyValueDeal',
    DEAL_SELL_VOL: 'foreignSellVolumeDeal',
    DEAL_SELL_VAL: 'foreignSellValueDeal',
    FOREIGN_CR_ROOM: 'foreignCurrentRoom',
    FOREIGN_TOTAL_ROOM: 'foreignTotalRoom',
    PERCENT_FOREIGN_TOTAL_ROOM: 'percentForeignTotalRoom',
    FOREIGN_OWNED: 'foreignOwned',
    PERCENT_FOREIGN_OWNED: 'percentForeignOwned',
  },
  detail: {
    DATE: 'tradingDateId',
    TICKER: 'ticker',
    TOTAL_BUY_VOL: 'foreignBuyVolumeTotal',
    TOTAL_BUY_VAL: 'foreignBuyValueTotal',
    TOTAL_SELL_VOL: 'foreignSellVolumeTotal',
    TOTAL_SELL_VAL: 'foreignSellValueTotal',
    NET_TOTAL_VOL: 'foreignNetVolumeTotal',
    NET_TOTAL_VAL: 'foreignNetValueTotal',
    MATCH_BUY_VOL: 'foreignBuyVolumeMatched',
    MATCH_BUY_VAL: 'foreignBuyValueMatched',
    MATCH_SELL_VOL: 'foreignSellVolumeMatched',
    MATCH_SELL_VAL: 'foreignSellValueMatched',
    DEAL_BUY_VOL: 'foreignBuyVolumeDeal',
    DEAL_BUY_VAL: 'foreignBuyValueDeal',
    DEAL_SELL_VOL: 'foreignSellVolumeDeal',
    DEAL_SELL_VAL: 'foreignSellValueDeal',
    FOREIGN_CR_ROOM: 'foreignCurrentRoom',
    FOREIGN_TOTAL_ROOM: 'foreignTotalRoom',
    PERCENT_FOREIGN_TOTAL_ROOM: 'percentForeignTotalRoom',
    FOREIGN_OWNED: 'foreignOwned',
    PERCENT_FOREIGN_OWNED: 'percentForeignOwned',
  },
}

export const tableFormatValue = {
  foreignBuyVolumeTotal: 10 ** 3,
  foreignSellVolumeTotal: 10 ** 3,
  foreignBuyValueTotal: 10 ** 6,
  foreignSellValueTotal: 10 ** 6,
  foreignNetVolumeTotal: 10 ** 3,
  foreignNetValueTotal: 10 ** 6,
  foreignBuyVolumeMatched: 10 ** 3,
  foreignSellVolumeMatched: 10 ** 3,
  foreignBuyValueMatched: 10 ** 6,
  foreignSellValueMatched: 10 ** 6,
  foreignBuyVolumeDeal: 10 ** 3,
  foreignSellVolumeDeal: 10 ** 3,
  foreignBuyValueDeal: 10 ** 6,
  foreignSellValueDeal: 10 ** 6,
  foreignCurrentRoom: 10 ** 3,
  foreignTotalRoom: 10 ** 3,
  percentForeignTotalRoom: 0.01,
  foreignOwned: 10 ** 3,
  percentForeignOwned: 0.01,
}
