import { useState } from 'react'
import style from './index.module.css'
import { Item } from './Item'

export const List = ({
  data,
  dropdownRef,
  onSelectedItem,
  position,
  popupRef,
}) => {
  const [isHoverLastItem, setIsLastItem] = useState(false)
  const [isHoverFirstItem, setIsFirstItem] = useState(false)

  const getClassName = () => {
    if (position.isTop) {
      return isHoverLastItem ? style.triangleBottomHover : style.triangleBottom
    }

    return isHoverFirstItem ? style.triangleTopHover : style.triangleTop
  }

  return (
    <div
      ref={dropdownRef}
      className={`${style.dropdownContainer} ${getClassName()}`}
    >
      {data.map((item, index) => (
        <Item
          key={index}
          item={item}
          isLastItem={index === data.length - 1}
          isFirstItem={index === 0}
          setIsLastItem={setIsLastItem}
          setIsFirstItem={setIsFirstItem}
          onSelectedItem={onSelectedItem}
          popupRef={popupRef}
        />
      ))}
    </div>
  )
}
