import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SESSION } from '../../../../../common/chart/constants'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import { RECORD_NUM_PER_PAGE } from './constants'
import { selectFilter, selectIsPushDataRealTime } from './store/slice'
import {
  getIssuerOrganizationIds,
  getOverviewStatistics,
  getUnderlyingIds,
} from './store/thunk'

export const HandleLoadData = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const interval = useRef({ realtime: null, table: null })

  // Use selector
  const isPushDataRealTime = useSelector(selectIsPushDataRealTime)
  const filterData = useSelector(selectFilter)
  const locale = useSelector((state) => state.i18n.locale)

  // Get data
  const startTimeSession = new Date().setHours(
    SESSION.start.hour,
    SESSION.start.minute,
    0,
    0,
  )

  const endTimeSession = new Date().setHours(
    SESSION.end.hour,
    SESSION.end.minute,
    0,
    0,
  )

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  const params = {
    From: filterData?.dateFrom
      ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
      : firstDateOfCurrentYear,
    To: filterData?.dateTo
      ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
      : new Date(),
    Page: filterData?.page,
    PageSize: RECORD_NUM_PER_PAGE,
    Status: filterData?.status ? [filterData?.status] : null,
    UnderlyingIds: filterData?.currentUnderlyingId,
    Issuers: filterData?.currentIssuerId,
    SortField: filterData?.sortField ?? '',
    SortOrder: filterData?.sortOrder ?? '',
  }

  // Actions
  const clearIntervalRealtime = () => {
    clearInterval(interval.current.realtime)
    interval.current.realtime = undefined
  }

  // Use effect
  useEffect(() => {
    dispatch(getIssuerOrganizationIds({}))
  }, [locale])

  useEffect(() => {
    dispatch(getUnderlyingIds({}))
  }, [locale])

  useEffect(() => {
    clearTimeout(interval.current.table)
    interval.current.table = setTimeout(() => {
      dispatch(getOverviewStatistics(params))
      clearTimeout(interval.current.table)
    }, 100)
    return () => {
      clearTimeout(interval.current.table)
    }
  }, [
    locale,
    filterData.page,
    filterData.dateFrom,
    filterData.dateTo,
    filterData.status,
    filterData.currentUnderlyingId,
    filterData.currentIssuerId,
    filterData.sortField,
    filterData.sortOrder,
  ])

  useEffect(() => {
    if (isPushDataRealTime) {
      interval.current.realtime = setInterval(() => {
        const now = new Date().getTime()
        if (now > endTimeSession) {
          clearIntervalRealtime()
        } else if (now >= startTimeSession) {
          params.isGetRealtimeData = true
          dispatch(getOverviewStatistics(params))
        }
      }, 30 * 1000)
    } else {
      clearIntervalRealtime()
    }

    return () => {
      if (interval.current.realtime) {
        clearIntervalRealtime()
      }
    }
  }, [isPushDataRealTime, locale])

  return null
}
