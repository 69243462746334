import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '../../../common/html/Button'
import style from '../index.module.css'
import { selectResultTickerIds } from '../store/slice'
import PopupCondition from './popupCondition'
import { Translate } from 'react-redux-i18n'

const FilterResult = () => {
  const tickerIds = useSelector(selectResultTickerIds)

  const [isShowPopup, setIsShowPopup] = useState(false)

  const handleShowPopup = () => setIsShowPopup(true)

  const handleHidePopup = () => setIsShowPopup(false)

  const isNoResult = tickerIds.length === 0

  return (
    <>
      <Button
        style={{ fontSize: 10 }}
        onClick={handleShowPopup}
        className={`btn btn-add-more w-200 h-20 mr-8 ${
          isNoResult ? style.disabled : ''
        }`}
        disabled={isNoResult}
      >
        <Translate value="tool.smartScreening.ADD_MORE_INFOR" />
        <i className="icon-plus" />
      </Button>
      {isShowPopup && <PopupCondition handleHidePopup={handleHidePopup} />}
    </>
  )
}

export default FilterResult
