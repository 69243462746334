import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetICB from '../../../common/masterData/useGetICB'
import { Table } from '../../../common/table'
import { typeRowGroup } from '../constant'
import { getIcbId, getRowGroup } from '../helper'
import style from '../index.module.css'
import {
  changeActiveRow,
  changeActiveSector,
  changeSectorInDepthId,
  handleLevelSort,
  handleSortSectorInDepthId,
  keys,
  selectActiveRow,
  selectLastActiveRow,
  selectLoading,
  selectSectorInDepthById,
  selectSectorInDepthCell,
  selectSectorInDepthId,
  selectSortSectorInDepthId,
  selectTimeRange,
  sortByLevel,
} from '../store/slice'
import { formatTableSchema } from './helpers'

const defaultMaxPercent = 500
const STICKY_BOTTOM_ROW = 3

const findMax = (sectorInDepthId, sectorInDepthById, keyMax) => {
  return (
    sectorInDepthId.reduce((max, key) => {
      const value = sectorInDepthById[key]?.[keyMax]
        ? Math.abs(sectorInDepthById[key]?.[keyMax])
        : 0
      if (value > max && getRowGroup(key) === typeRowGroup.ICB) {
        return value
      }
      return max
    }, 0) || defaultMaxPercent
  )
}

export const useActiveSector = (icbById, levelCollapse) => {
  const dispatch = useDispatch()

  const activeRow = useSelector(selectActiveRow)

  useEffect(() => {
    if (activeRow && activeRow !== resetDefaultActiveRow) {
      const id = getIcbId(activeRow)
      dispatch(
        changeActiveSector(
          icbById[id]?.icbName + ' L' + (levelCollapse[activeRow]?.level || ''),
        ),
      )
    }
  }, [activeRow, levelCollapse, icbById])
}

export const resetDefaultActiveRow = 'resetDefaultActiveRow'

const ContentTableSector = ({ level, setLevel }) => {
  const dispatch = useDispatch()
  const tableRef = useRef({ timeout: null })
  const { ICBs, ICBById, loading: loadingICB } = useGetICB(true)

  // Use selector
  const loading = useSelector(selectLoading(keys.SECTOR_IN_DEPTH))
  const sectorInDepthId = useSelector(selectSectorInDepthId)
  const sectorInDepthById = useSelector(selectSectorInDepthById)
  const timeRange = useSelector(selectTimeRange)
  const lastActiveRow = useSelector(selectLastActiveRow)
  const sortSectorInDepthId = useSelector(selectSortSectorInDepthId)

  // Use state
  const [rowsCollapse, setRowsCollapse] = useState([])
  const [levelCollapse, setLevelCollapse] = useState({})
  const [defaultActiveRow, setDefaultActiveRow] = useState()
  const [maxValueChange, setMaxValueChange] = useState(defaultMaxPercent)
  const [maxVolumnChange, setMaxVolumnChange] = useState(defaultMaxPercent)
  const [idsDisplay, setIdsDisplay] = useState([])

  useActiveSector(ICBById, levelCollapse)

  // Actions
  const onRowCollapse = (id) => {
    const { children = [] } = ICBById[getIcbId(id)]
    const newRowsCollapse = [...rowsCollapse]
    if (newRowsCollapse.includes(id)) {
      newRowsCollapse.splice(rowsCollapse.indexOf(id), 1)
      setRowsCollapse(newRowsCollapse)
      setIdsDisplay([
        ...idsDisplay,
        ...children.map((item) => `ICB-${item.icbId}`),
      ])
    } else {
      newRowsCollapse.push(id)
      setRowsCollapse(newRowsCollapse)
      setIdsDisplay([
        ...idsDisplay.filter(
          (id) => !children.map((item) => `ICB-${item.icbId}`).includes(id),
        ),
      ])
    }
  }

  // Get data
  const getSchema = useMemo(() => {
    return formatTableSchema({
      timeRange,
      level,
      maxValueChange,
      maxVolumnChange,
      rowsCollapse,
      levelCollapse,
      onRowCollapse,
      setLevel,
    })
  }, [
    timeRange,
    level,
    maxValueChange,
    maxVolumnChange,
    rowsCollapse,
    levelCollapse,
    onRowCollapse,
    setLevel,
  ])

  // Use effect
  useEffect(() => {
    setMaxValueChange(defaultMaxPercent)
    setMaxVolumnChange(defaultMaxPercent)
  }, [timeRange])

  useEffect(() => {
    if (Object.keys(sectorInDepthById).length > 0) {
      let lastId = ''
      const newSectorInDepthId = []
      const newLevelCollapse = {}
      const newRowsCollapse = []
      const newLevelSort = []
      ICBs.forEach((sector, index) => {
        const id = `${typeRowGroup.ICB}-${sector.icbId}`
        if (
          sectorInDepthById[id] &&
          sector.icbLevel >= level &&
          sectorInDepthById[id].totalValue
        ) {
          newSectorInDepthId.push(id)
          newLevelSort.push({
            id,
            level: sector.icbLevel,
            parentId: `${typeRowGroup.ICB}-${sector.parentICBId}`,
          })
          newLevelCollapse[id] = {
            index: id,
            level: sector.icbLevel,
            isRowCollapse: false,
            isLastChild: false,
          }
          if (
            lastId &&
            newLevelCollapse[lastId]?.level >= newLevelCollapse[id].level
          ) {
            newLevelCollapse[lastId].isLastChild = true
          } else if (lastId) {
            newRowsCollapse.push(lastId)
          }
          lastId = id
        }
        if (lastId && index === ICBs.length - 1) {
          newLevelCollapse[lastId].isLastChild = true
        }
      })
      setRowsCollapse(newRowsCollapse)
      setDefaultActiveRow(resetDefaultActiveRow) // reset to default to reactive default row
      setLevelCollapse(newLevelCollapse)
      dispatch(
        handleSortSectorInDepthId(
          newSectorInDepthId.concat(
            sectorInDepthId.filter(
              (id) => sectorInDepthById[id]?.rowGroup !== typeRowGroup.ICB,
            ),
          ),
        ),
      )
      dispatch(handleLevelSort(newLevelSort))
    }
  }, [ICBs, sectorInDepthById, level])

  useEffect(() => {
    if (defaultActiveRow === resetDefaultActiveRow) {
      const idIcbFirst =
        getRowGroup(sortSectorInDepthId[0]) === typeRowGroup.ICB
          ? sortSectorInDepthId[0]
          : ''
      setDefaultActiveRow(lastActiveRow || idIcbFirst) // reset to default to reactive default row
      dispatch(changeActiveRow(idIcbFirst))
    }
  }, [defaultActiveRow])

  useEffect(() => {
    const displaySectorInDepthId = []
    const listTr = tableRef.current?.querySelectorAll('tbody tr') || []
    listTr.forEach((element) => {
      if (element && element.style.display !== 'none') {
        const id = element.getAttribute('id')
        displaySectorInDepthId.push(id)
      }
    })
    setMaxValueChange(
      findMax(displaySectorInDepthId, sectorInDepthById, 'percentValueChange'),
    )
    setMaxVolumnChange(
      findMax(displaySectorInDepthId, sectorInDepthById, 'percentVolumeChange'),
    )
  }, [rowsCollapse])

  useEffect(() => {
    const rows = sortSectorInDepthId.filter((id) =>
      id.includes(typeRowGroup.INDEX),
    )
    ICBs.forEach((icb) => {
      if (icb.icbLevel === level) {
        rows.push(`ICB-${icb.icbId}`)
      }
    })
    setIdsDisplay(rows)
  }, [level, sortSectorInDepthId])

  return (
    <div ref={tableRef} className={`h-100 ${style.tableSectorContainer}`}>
      <Table
        ids={sortSectorInDepthId}
        idsDisplay={idsDisplay}
        columnDraggable={false}
        getDataFromRedux={selectSectorInDepthCell}
        isLoading={loading || loadingICB}
        changeIds={changeSectorInDepthId}
        rowDraggable={false}
        hasFooter={false}
        hasTooltip={false}
        resizable={false}
        isCollapse={true}
        sort={sortByLevel}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        onRowCollapse={onRowCollapse}
        stickyFirstColumn={true}
        stickyBottomRowCount={STICKY_BOTTOM_ROW}
        changeActiveRow={changeActiveRow}
        disableClickGroups={sortSectorInDepthId.filter(
          (id) => getRowGroup(id) !== typeRowGroup.ICB,
        )}
        defaultActiveRowId={defaultActiveRow}
        schema={getSchema}
      />
    </div>
  )
}

ContentTableSector.propTypes = {
  iCBs: PropTypes.array.isRequired,
  loadingICB: PropTypes.bool.isRequired,
  level: PropTypes.number.isRequired,
  setLevel: PropTypes.func.isRequired,
}

export default ContentTableSector
