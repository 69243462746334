import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchIndice } from '../store/slice'
import { getSearchIndicesData } from '../store/thunk'
import { MarIndicesItem } from './MarIndicesItem'

export const MarIndices = (props) => {
  const { searchStr, open, onClickCollapse, getBasicInfo } = props
  const dispatch = useDispatch()
  const indices = useSelector(selectSearchIndice)

  const onClickPrevPage = () => {
    if (indices.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchIndicesData({
          Term: searchStr,
          Page: indices.data.page - 1,
          PageSize: indices.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (indices.data.page === indices.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchIndicesData({
          Term: searchStr,
          Page: indices.data.page + 1,
          PageSize: indices.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="mar-ind"
      title={`${UseI18n('search.fullSearch.MAR_INDICES')} (${
        indices.data.totalRecords
      })`}
      blockData={indices}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!indices.data.items.length &&
        indices.data.items.map((item) => (
          <MarIndicesItem
            key={item.groupId}
            searchStr={searchStr}
            data={item}
            getBasicInfo={getBasicInfo}
          />
        ))}
    </SearchCollapseBlock>
  )
}

MarIndices.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
