import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getSecMarginLendingMaxPeriod = createAsyncThunk(
  'corporate/businessModel/penetration/insurance/getSecMarginLendingMaxPeriod',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getSecMarginLendingMaxPeriod(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getSecProprietaryTreasuryMaxPeriod = createAsyncThunk(
  'corporate/businessModel/penetration/insurance/getSecProprietaryTreasuryMaxPeriod',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await PenetrationAnalysisService.getSecProprietaryTreasuryMaxPeriod(
          params,
        )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
