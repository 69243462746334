import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SEC_GROUP } from '../../common/constants'
import { resetIndicatorSelectedByKey } from '../../popup/popupAddIndicator/store/slice'
import { changeChartTabIndicatorSelected } from '../store/slice'

const ClearIndicator = ({ treeSecuritiesIndicator }) => {
  const dispatch = useDispatch()

  const { companies, indices, sector } = treeSecuritiesIndicator

  useEffect(() => {
    if (!Object.keys(companies).length) {
      dispatch(changeChartTabIndicatorSelected({ [SEC_GROUP.COMPANY]: [] }))
      dispatch(
        resetIndicatorSelectedByKey({ typeIndicator: SEC_GROUP.COMPANY }),
      )
    }
  }, [JSON.stringify(companies)])

  useEffect(() => {
    if (!Object.keys(indices).length) {
      dispatch(changeChartTabIndicatorSelected({ [SEC_GROUP.INDEX]: [] }))
      dispatch(resetIndicatorSelectedByKey({ typeIndicator: SEC_GROUP.INDEX }))
    }
  }, [JSON.stringify(indices)])

  useEffect(() => {
    if (!Object.keys(sector).length) {
      dispatch(changeChartTabIndicatorSelected({ [SEC_GROUP.SECTOR]: [] }))
      dispatch(resetIndicatorSelectedByKey({ typeIndicator: SEC_GROUP.SECTOR }))
    }
  }, [JSON.stringify(sector)])

  return <></>
}

export default ClearIndicator
