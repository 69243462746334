import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Area, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import ContentTooltip from '../../../../common/chart/rechart/ContentTooltip'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valDivBillion } from '../../../../utils/Value'
import { maxTickYAxis } from '../constans'
import { decodeSizePanel } from '../helper'
import style from '../index.module.css'
import { keys, selectForeignTradingOneDay, selectLoading } from '../store/slice'

const heightYAsisTick = 40

const ForeignTradingChart = ({ sizes }) => {
  const timezone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const foreignTradingOneDay = useSelector(selectForeignTradingOneDay)
  const loadingForeignTrading = useSelector(selectLoading(keys.FOREIGN_TRADING))

  const [dataChart, setDataChart] = useState([])

  const width = decodeSizePanel(sizes).width
  const height = decodeSizePanel(sizes).height

  useEffect(() => {
    setDataChart(
      foreignTradingOneDay.map((item) => {
        if (item.isFakeData) return item
        const netForeign = valDivBillion(
          item.foreignBuyValueTotal - item.foreignSellValueTotal,
        )
        return {
          ...item,
          foreignBuy: valDivBillion(item.foreignBuyValueTotal),
          foreignSell: valDivBillion(item.foreignSellValueTotal),
          netForeign: netForeign,
          positiveNetForeign: netForeign > 0 ? netForeign : 0,
          negativeNetForeign: netForeign < 0 ? netForeign : 0,
        }
      }),
    )
  }, [foreignTradingOneDay])

  return (
    <div className={`h-100 ${style.relative}`}>
      {loadingForeignTrading ? (
        <Loading />
      ) : !dataChart.length ? (
        <NoData />
      ) : (
        <ChartContainer
          data={dataChart}
          width={width}
          height={height}
          margin={{ ...MARGIN_RECHARTS, right: 10 }}
          keyXAxis="tradingDate"
          yAxis={[
            {
              id: 'left',
              keys: ['netForeign', 'foreignBuy', 'foreignSell'],
              orientation: 'left',
              label: I18n.t('market.marketInDepth.indices.VALUE_BILLION'),
              labelPosition: AXIS_LABEL_POSITION.LEFT,
              tickNum: Math.min(
                Math.floor(height / heightYAsisTick),
                maxTickYAxis,
              ),
            },
          ]}
          timeFrame={TIME_RANGES['1D']}
          renderCustomTooltip={(data) => {
            const contentTooltip = [
              {
                label:
                  formatDateTime(
                    data.tradingDate,
                    FORMAT.DATE,
                    locale,
                    timezone,
                  ) +
                  '   -   ' +
                  formatDateTime(
                    data.tradingDate,
                    FORMAT.TIME_HOUR_MINUTE,
                    locale,
                    timezone,
                  ),
                value: '',
                styleLabel: { fontStyle: 'italic', fontSize: 10 },
                styleValue: { fontStyle: 'italic', fontSize: 10 },
              },
              {
                label: `${I18n.t('market.marketInDepth.indices.FOREIGN_BUY')}:`,
                value:
                  formatVal(data.foreignBuy) +
                  I18n.t('market.marketInDepth.indices.BILLION'),
                styleLabel: { fontSize: 11, fontWeight: 400 },
                styleValue: { fontSize: 11, fontWeight: 500 },
              },
              {
                label: `${I18n.t(
                  'market.marketInDepth.indices.FOREIGN_SELL',
                )}:`,
                value:
                  formatVal(data.foreignSell) +
                  I18n.t('market.marketInDepth.indices.BILLION'),
                styleLabel: { fontSize: 11, fontWeight: 400 },
                styleValue: { fontSize: 11, fontWeight: 500 },
              },
              {
                label: `${I18n.t('market.marketInDepth.indices.NET_FOREIGN')}:`,
                value:
                  formatVal(data.netForeign) +
                  I18n.t('market.marketInDepth.indices.BILLION'),
                styleLabel: { fontSize: 11, fontWeight: 400 },
                styleValue: { fontSize: 11, fontWeight: 500 },
              },
            ]
            return <ContentTooltip contentTooltip={contentTooltip} />
          }}
        >
          <Area
            dataKey="positiveNetForeign"
            fill="#1d97ef"
            yAxisId="left"
            stroke="none"
            fillOpacity={1}
            isAnimationActive={false}
            activeDot={false}
          />
          <Area
            dataKey="negativeNetForeign"
            fill="#e94235"
            yAxisId="left"
            stroke="none"
            fillOpacity={1}
            isAnimationActive={false}
            activeDot={false}
          />
          <Line
            yAxisId="left"
            dataKey="foreignBuy"
            stroke="#47c3ff"
            dot={false}
            isAnimationActive={false}
            activeDot={false}
          />
          <Line
            yAxisId="left"
            dataKey="foreignSell"
            stroke="#facc5c"
            dot={false}
            isAnimationActive={false}
            activeDot={false}
          />
        </ChartContainer>
      )}
    </div>
  )
}

ForeignTradingChart.propTypes = {
  sizes: PropTypes.string.isRequired,
}

export default ForeignTradingChart
