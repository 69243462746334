export const INDICATOR_GROUPS = {
  BOND_AGGREGATOR: 'AllBond',
  BOND_TRANSACTION: 'BondTransaction',
  BOND_CREATING: 'UserBond',
  ALL_ISSUER: 'AllIssuer',
}

export const NAVIGATION_BOND_SCREENING = [
  {
    title: 'bond.bondScreening.common.BOND_AGGREGATOR',
    value: INDICATOR_GROUPS.BOND_AGGREGATOR,
  },
  // {
  //   title: 'bond.bondScreening.common.BOND_TRANSACTION',
  //   value: INDICATOR_GROUPS.BOND_TRANSACTION,
  // },
  {
    title: 'bond.bondScreening.common.BOND_CREATING',
    value: INDICATOR_GROUPS.BOND_CREATING,
  },
  {
    title: 'bond.bondScreening.common.ALL_ISSUER',
    value: INDICATOR_GROUPS.ALL_ISSUER,
  },
]

export const TAB_TYPES = {
  ALL: 0,
  RAW: -1,
  MORE: -2,
}

export const TAB_LIST = [TAB_TYPES.ALL, TAB_TYPES.RAW, TAB_TYPES.MORE]
