import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { HEADER_VALUE } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import { getNominalRealValue } from '../store/slice'
import FirstCellColumn from './FirstCellColumn'
import style from './index.module.css'
import OtherCellColumn from './OtherCellColumn'
import {
  getGDPProvinceValue,
  getGroupColumns,
  getIds,
  getIsAllowCallApi,
  getIsFirstTime,
  getLocationId,
  getMinYear,
  getTableData,
  getTableDataLoading,
  getTableMappingData,
  getTableMappingLoading,
  getValueGrowthNominal,
  getValueGrowthReal,
  handleIsFirstTime,
  sort,
} from './store/slice'
import { getGDPByProvince, getGDPTypeName, getMaxPeriod } from './store/thunk'

export const TABLE_PROVINCE_ID = 'TABLE_PROVINCE_ID'
export const NUMBER_OF_PERIOD = 10
export const NUMBER_OF_PERIOD_FIRST_TIME = 17
const { NOMINAL } = HEADER_VALUE

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()
  const { GROWTH_YOY, PER_CAPITA } = GDP_PROVINCE_VALUE

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableMappingLoading = useSelector(getTableMappingLoading)
  const tableData = useSelector(getTableData)
  const tableMapping = useSelector(getTableMappingData)
  const locale = useSelector((state) => state.i18n.locale)
  const locationId = useSelector(getLocationId)
  const nominalRealValue = useSelector(getNominalRealValue)
  const ids = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const isFirstTime = useSelector(getIsFirstTime)
  const isAllowCallApi = useSelector(getIsAllowCallApi)
  const minYear = useSelector(getMinYear)
  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  const renderFooter = (nominalRealValue, valueGrowthOptions) => {
    switch (valueGrowthOptions) {
      case PER_CAPITA: {
        if (nominalRealValue === NOMINAL) {
          return 'economy.gdp.gdp.UNIT_MILLION_VND'
        } else {
          return 'economy.gdp.gdp.UNIT_PERCENT'
        }
      }
      case GROWTH_YOY: {
        return 'economy.gdp.gdp.UNIT_PERCENT'
      }
      default:
        return 'economy.gdp.gdp.UNIT_BILLION_VND'
    }
  }

  const scrollToLeft = () => {
    if (isAllowCallApi && minYear) {
      dispatch(
        getGDPByProvince({
          LocationId: locationId,
          NumberOfPeriod: NUMBER_OF_PERIOD,
          IsNominal: nominalRealValue === NOMINAL,
          GDPTypeCode: radioValue,
          Year: minYear - 1,
        }),
      )
    }
    dispatch(handleIsFirstTime(false))
  }

  useEffect(() => {
    dispatch(
      getGDPByProvince({
        LocationId: locationId,
        NumberOfPeriod: NUMBER_OF_PERIOD_FIRST_TIME,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: radioValue,
      }),
    )
  }, [
    locale,
    locationId,
    nominalRealValue,
    valueGrowthNominal,
    valueGrowthReal,
  ])

  useEffect(() => {
    dispatch(
      getGDPTypeName({
        LocationId: locationId,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: radioValue,
      }),
    )
  }, [
    locale,
    locationId,
    nominalRealValue,
    valueGrowthNominal,
    valueGrowthReal,
  ])

  useEffect(() => {
    dispatch(
      getMaxPeriod({
        LocationId: locationId,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: radioValue,
      }),
    )
  }, [
    locale,
    locationId,
    nominalRealValue,
    valueGrowthNominal,
    valueGrowthReal,
  ])

  if ((tableDataLoading || tableMappingLoading) && isFirstTime) {
    return <Loading />
  }

  if (Object.keys(tableData).length === 0 || tableMapping.length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            {(sizes.height || sizes.height === 0) && (
              <div
                style={{
                  height: height - sizes.height ? height - sizes.height : 0,
                }}
                id={TABLE_PROVINCE_ID}
              >
                <Table
                  ids={ids}
                  sort={!locationId && sort}
                  columnDraggable={false}
                  getDataFromRedux={getGDPProvinceValue}
                  rowDraggable={false}
                  isLoading={tableDataLoading || tableMappingLoading}
                  schema={[
                    { key: 'emptyColumn', title: '' },
                    ...groupColumns,
                  ].map((item, index) => {
                    const title = item.title
                    const colId = item.key
                    const result = {
                      colId,
                      title,
                    }
                    if (index === 0) {
                      return {
                        ...result,
                        thStyle: {
                          textAlign: 'left',
                          fontSize: 10,
                          fontWeight: 600,
                        },
                        tdStyle: {
                          minWidth: 240,
                        },
                        disableSort: true,
                        canCustomTd: true,
                        render: (val, rowId, props) => {
                          const tableHorizontalHead = tableMapping.find(
                            (item) => item.nationalAccountVNTypeId === rowId,
                          )

                          return (
                            <FirstCellColumn
                              val={
                                tableHorizontalHead?.nationalAccountVNTypeName
                              }
                              props={props}
                              level={
                                tableHorizontalHead.nationalAccountVNTypeLevel
                              }
                            />
                          )
                        },
                      }
                    }

                    return {
                      ...result,
                      isI18n: true,
                      tdStyle: {
                        textAlign: 'right',
                        fontSize: 12,
                      },
                      thStyle: {
                        textAlign: 'right',
                        fontSize: 10,
                        fontWeight: 600,
                      },
                      canCustomTd: true,
                      render: (val, rowId, props) => {
                        return <OtherCellColumn val={val} props={props} />
                      },
                    }
                  })}
                  stickyFirstColumn={true}
                  isPagination={false}
                  hasFooter={false}
                  resizable={false}
                  scrollToLeftCallback={scrollToLeft}
                  defaultScrollToRight={isFirstTime}
                  isLazyLoadLeft={true}
                />
              </div>
            )}
            <div className={style.wrapFooterProvince}>
              <Span
                style={{ fontSize: 11 }}
                className={style.footerGDPProvinceTable}
              >
                <Translate value={renderFooter(nominalRealValue, radioValue)} />
              </Span>
            </div>
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableComponent
