import { languageObjs } from '../../../../../configs/Language'
import { TYPE } from '../../../../common/chart/footer/Item'
import { DEPOSIT_ID, LINE_KEY_WITH_COLOR, LOAN_ID } from './config'

export const renderListFooter = (lineKey, locale) => {
  return lineKey.map((item) => {
    return {
      text: item[locale],
      type: TYPE.RECTANGLE,
      before: {
        bgColor: item.color,
      },
      isI18n: false,
    }
  })
}

export const handleAreaChartDate = (payload) => {
  if (!payload.length) {
    return []
  }

  const dateNotDuplicate = handleDuplicateByKey(payload, [
    'realMonth',
    'realYear',
  ])

  return dateNotDuplicate
    .map((item) => {
      const dataWithDate = payload.filter(
        (element) =>
          element.realMonth === item.realMonth &&
          element.realYear === item.realYear,
      )

      return dataWithDate.reduce((previous, current) => {
        const value = current.value * 100
        const obj = handleDataAreaChartById(
          current.sbvRateTypeId,
          value,
          previous,
        )
        return {
          ...previous,
          ...obj,
          month: item.realMonth,
          year: item.realYear,
        }
      }, {})
    })
    .sort(sortByMonthYear)
}

export const handleXAxisTitle = (month, locale) => {
  const isViLanguage = locale === languageObjs.vi
  if (month < 10) {
    return isViLanguage ? `Th0${month}` : `0${month}`
  }

  return isViLanguage ? `Th${month}` : `${month}`
}

const handleDataAreaChartById = (id, value, previousObj) => {
  if (Object.keys(LINE_KEY_WITH_COLOR).includes(`${id}`)) {
    return {
      [id]: value,
    }
  }

  if (DEPOSIT_ID.includes(id)) {
    if (!previousObj?.rangeDeposit) {
      return {
        rangeDeposit: [value],
        [id]: value,
      }
    } else {
      if (previousObj.rangeDeposit.includes(value)) {
        return previousObj.rangeDeposit
      }
      return {
        rangeDeposit: [...previousObj.rangeDeposit, value].sort(
          (a, b) => a - b,
        ),
        [id]: value,
      }
    }
  }

  if (LOAN_ID.includes(id)) {
    if (!previousObj?.rangeLoan) {
      return {
        rangeLoan: [value],
        [id]: value,
      }
    } else {
      if (previousObj.rangeLoan.includes(value)) {
        return previousObj.rangeLoan
      }
      return {
        rangeLoan: [...previousObj.rangeLoan, value].sort((a, b) => a - b),
        [id]: value,
      }
    }
  }

  return {}
}

export const handleKeyWithTitle = (payload) => {
  if (!payload.length) {
    return {}
  }

  const sbvRateTypeIdNotDuplicate = handleDuplicateByKey(payload, [
    'sbvRateTypeId',
  ])

  return sbvRateTypeIdNotDuplicate.reduce((previous, current) => {
    return {
      ...previous,
      [current.sbvRateTypeId]: current.sbvRateVNTypeShortName,
    }
  }, {})
}

export const getTooltipContentData = (payload, title) => {
  const keys = Object.keys(title)

  return keys.map((key) => {
    return {
      label: title[key],
      value: payload[key],
    }
  })
}

const sortByMonthYear = (a, b) => {
  if (a.year > b.year) {
    return 1
  }

  if (a.year < b.year) {
    return -1
  }

  if (a.year === b.year) {
    if (a.month > b.month) {
      return 1
    }

    if (a.month < b.month) {
      return -1
    }
  }

  return 0
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}
