import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { PanelEarningAgainstChart } from './chart/PanelEarningAgainstChart'
import { PanelPriceEarningChart } from './chart/PanelPriceEarningChart'
import { PanelDividendTable } from './table/PanelDividendTable'

const MAP_KEY = {
  FINANCIAL_TABLE: 'FINANCIAL_TABLE',
  FINANCIAL_CHART_PRICE_EARNING: 'FINANCIAL_CHART_PRICE_EARNING',
  FINANCIAL_CHART_EARNING_AGAINST: 'FINANCIAL_CHART_EARNING_AGAINST',
}

const MAP_SIZE = {
  [`${MAP_KEY.FINANCIAL_TABLE}`]: {
    width: `100%`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 3,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_CHART_PRICE_EARNING}`]: {
    width: `calc(40% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 3 * 0.4 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.FINANCIAL_CHART_EARNING_AGAINST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.FINANCIAL_CHART_EARNING_AGAINST,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.FINANCIAL_TABLE,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.FINANCIAL_CHART_EARNING_AGAINST}`]: {
    width: `calc(60% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(40% + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH * 3 * 0.6 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 3 * 0.4 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelPriceEarningChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelEarningAgainstChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelDividendTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
