import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { lengthChartInLayout } from '../chart/common/useGetLayoutDashboard'
import { postCharts } from '../chart/store/thunk'
import { getChartNameBySampleName } from '../common/helpers'
import { dashboardTypes, screenTypes } from '../constants'
import { changeDashboard, selectListDashboard } from '../store/slice'
import { postDashboard } from '../store/thunk'
import style from './style.module.css'
import styles from './styleTheme.module.css'

const PopupCopyChartToNewDashboard = ({ isShow, handleShow, valueChart }) => {
  const dispatch = useDispatch()

  const listDashboard = useSelector(selectListDashboard)

  const [dashboardName, setDashboardName] = useState('')
  const [chartName, setChartName] = useState(valueChart?.name)

  const onClose = () => {
    handleShow(false)
  }

  const onApply = async () => {
    if (!dashboardName.trim()) return
    await dispatch(
      postDashboard({
        name: dashboardName,
        isGroup: false,
        layout: 'layout_1',
        maxChartCount: lengthChartInLayout.layout_1,
        cancelBackToDB: true,
      }),
    )
      .unwrap()
      .then(async (data) => {
        const params = {
          ...valueChart,
          dashboardId: data.id,
          name: chartName,
          detail: JSON.stringify({ ...valueChart.detail, order: 1 }),
          order: 1,
        }
        await dispatch(postCharts(params))
          .unwrap()
          .then(() => {
            dispatch(
              changeDashboard({
                screenType: screenTypes.DASHBOARD,
                dashboardType: dashboardTypes.MY_DASHBOARD,
                data: data,
              }),
            )
          })
      })

    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
      }),
    )
    handleShow(true)
  }

  useEffect(() => {
    setDashboardName(
      getChartNameBySampleName(
        listDashboard.map((i) => i.name),
        'Dashboard ',
      ),
    )
  }, [listDashboard])

  if (isShow) {
    return (
      <Popup overlayCustom={styles.overlayCustom}>
        <div
          className={`modal modal-small ${styles.modal}`}
          style={{ width: 470 }}
        >
          <div className={`modal-title ${styles.titleStyle}`}>
            <h3>
              <Span>
                <Translate value="financialChart.COPY_CHART_TO_NEW_DASHBOARD" />
              </Span>
            </h3>
            <a onClick={onClose} className="close-modal">
              <i className="icon-delete-stroke" style={{ color: '#646464' }} />
            </a>
          </div>
          <div className={`modal-content ${styles.modalContentStyle}`}>
            <div className={`${style.contentPopupCopy} mb-8`}>
              <Span className={styles.colorText}>
                <Translate value="financialChart.NEW_DASHBOARD_1" />
              </Span>
              <Input
                className={styles.inputStyle}
                value={dashboardName}
                onChange={(e) => setDashboardName(e.target.value)}
              />
            </div>
            <div className={style.contentPopupCopy}>
              <Span className={styles.colorText}>
                <Translate value="financialChart.CHART_TITLE" />
              </Span>
              <Input
                className={styles.inputStyle}
                value={chartName}
                onChange={(e) => setChartName(e.target.value)}
              />
            </div>
            <div
              className="group-btn justify-content-end"
              style={{ marginTop: 40 }}
            >
              <Button
                className={`btn w-80 h-20 ${styles.buttonGrayStyle}`}
                style={{ fontSize: 11 }}
                onClick={onClose}
              >
                <Translate value="financialChart.CANCEL" />
              </Button>
              <Button
                className={`btn w-80 h-20 ${styles.buttonBlueStyle}`}
                style={{ fontSize: 11 }}
                onClick={onApply}
              >
                <Translate value="financialChart.OK" />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )
  }

  return null
}

export default PopupCopyChartToNewDashboard
