import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import {
  HIDE_LOADING,
  SHOW_LOADING,
} from '../../../common/dataExplorer/indexSector/popupIndicator'
import { resetStore } from '../../../common/dataExplorer/indexSector/store/slice'
import WorkSpace from '../../../common/dataExplorer/indexSector/WorkSpace'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import EventEmitter from '../../../utils/EventEmitter'
import Content from './Content'
import style from './index.module.css'

export const IndicesSector = () => {
  const dispatch = useDispatch()

  const loadingRef = useRef()

  useEffect(() => {
    hideLoading()
    EventEmitter.subscribe(SHOW_LOADING, showLoading)
    EventEmitter.subscribe(HIDE_LOADING, hideLoading)
    return () => {
      dispatch(resetStore())
      EventEmitter.unsubscribe(SHOW_LOADING, showLoading)
      EventEmitter.unsubscribe(HIDE_LOADING, hideLoading)
    }
  }, [])

  const showLoading = () => {
    loadingRef.current.style.display = 'block'
  }

  const hideLoading = () => {
    loadingRef.current.style.display = 'none'
  }

  return (
    <ScrollGroupComponent>
      <div ref={loadingRef}>
        <Loading />
      </div>
      <WorkSpace />
      <SizeTracker>
        {(size) => (
          <>
            <h3 className={style.title}>
              <Span style={{ fontSize: 16 }}>
                <Translate value="tool.dataExplorer.indicesSector.INDEX_SECTOR" />
              </Span>
            </h3>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
