import { VIEW_BY } from '../constants'
import { colorArray } from './constants'

export const getColorPieChart = (data, keyData, currentTicker) => {
  let sortedData = [...data]
  if (currentTicker) {
    const currentDataIndex = sortedData.findIndex(
      (item) => item.ti === currentTicker,
    )
    if (currentDataIndex !== -1) {
      const [currentData] = sortedData.splice(currentDataIndex, 1)
      sortedData.unshift(currentData)
    }
  }

  sortedData = sortedData.map((item, index) => ({
    text: item.ti,
    value: item[keyData],
    organizationId: item.organizationId,
    color: item.organizationId
      ? colorArray[index]
      : colorArray[colorArray.length - 1],
  }))

  if (sortedData.length === 1) sortedData = []

  return sortedData
}

export const getMarketShareChartName = (tab) => {
  const basePath = 'corporate.businessModel.penetrationAnalysis.'

  switch (tab) {
    case VIEW_BY.MARGIN_LENDING:
      return basePath + 'MARGIN_LENDING_MARKET_SHARE'
    case VIEW_BY.PROPRIETARY:
      return basePath + 'PROPRIETARY_MARKET_SHARE'
    case VIEW_BY.TREASURY:
      return basePath + 'TREASURY_MARKET_SHARE'
    default:
      return ''
  }
}
