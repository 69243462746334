import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { TAB_COMPONENT_DATA } from './constants'
import { FilterTable } from './filterTable'

export const Summary = ({ width, onTab }) => {
  const renderComponent = useMemo(() => {
    const Component = TAB_COMPONENT_DATA[onTab].Component
    return <Component />
  }, [onTab, width])

  return (
    <div
      className="d-flex h-100"
      style={{
        flexFlow: 'column',
      }}
    >
      <FilterTable width={width} activePage={onTab} />
      {renderComponent}
    </div>
  )
}

Summary.propTypes = {
  width: PropTypes.number.isRequired,
  onTab: PropTypes.string.isRequired,
}
