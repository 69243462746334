import { Fragment, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Radio from '../../../../../common/html/Radio'
// import ThePagination from '../../../../../common/pagination/ThePagination'
import { languageObjs } from '../../../../../../configs/Language'
import DispatchActionTab, {
  TIME_RANGES,
} from '../../../../../common/tabs/DispatchActionTab'
import { DispatchActionTabWithCalendar } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { tableDataTypes } from '../../constans'
import styles from '../../index.module.css'
import { changeTableFilter, selectTableFilter } from '../../store/slice'
import { SelectDate } from './SelectDate'

export const Filter = ({ sizes }) => {
  const dispatch = useDispatch()

  // Use selector
  const tableFilter = useSelector(selectTableFilter)

  // Get data
  const getTimeRangeByDataType = useMemo(() => {
    return tableFilter.dataType === tableDataTypes.REALTIME
      ? [
          { title: '1 tick', value: TIME_RANGES.OneTick },
          { title: '1m', value: TIME_RANGES.OneMinute },
          { title: '5m', value: TIME_RANGES.FiveMinutes },
        ]
      : [
          { title: '1M', value: TIME_RANGES.OneMonth },
          { title: '3M', value: TIME_RANGES.ThreeMonths },
          { title: '6M', value: TIME_RANGES.SixMonths },
          { title: 'YTD', value: TIME_RANGES.YearToDate },
        ]
  }, [tableFilter.dataType])

  // Actions
  const handleChangeDate = (value) => {
    let newFilter = {}
    if (value === formatDateTime(new Date(), FORMAT.DATE, languageObjs.en)) {
      newFilter = {
        dateRealTime: '',
        timeRange: TIME_RANGES.OneTick,
      }
    } else {
      newFilter = {
        dateRealTime: value,
        timeRange:
          tableFilter.timeRange === TIME_RANGES.OneTick
            ? TIME_RANGES.OneMinute
            : tableFilter.timeRange,
      }
    }
    dispatch(changeTableFilter(newFilter))
  }

  const handleChangeTimeRange = (data) => {
    dispatch(
      changeTableFilter({ timeRange: data.value, dateFrom: '', dateTo: '' }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeTableFilter({
        dateFrom: payload.startDate,
        dateTo: payload.endDate,
        timeRange: payload.activeTab,
      }),
    )
  }

  const handleChange = (val) => {
    dispatch(
      changeTableFilter({
        dataType: val,
        timeRange:
          val === tableDataTypes.REALTIME
            ? TIME_RANGES.OneTick
            : TIME_RANGES.OneMonth,
        dateFrom: '',
        dateTo: '',
        dateRealTime: '',
      }),
    )
  }

  return (
    <div>
      <div className="d-flex mb-8">
        <Radio
          data={Object.keys(tableDataTypes).map((key) => ({
            title: 'market.marketInDepth.indices.' + key,
            value: tableDataTypes[key],
          }))}
          value={tableFilter.dataType}
          onChange={handleChange}
          containerClass="w-auto d-flex"
        />
        <div className={styles.divider} />
        {tableFilter.dataType === tableDataTypes.REALTIME ? (
          <>
            <SelectDate
              value={
                tableFilter.dateRealTime ||
                formatDateTime(new Date(), FORMAT.DATE)
              }
              onChange={handleChangeDate}
            />
            <DispatchActionTab
              data={getTimeRangeByDataType}
              activeTab={tableFilter.timeRange}
              listDisabled={
                tableFilter.dateRealTime ? [TIME_RANGES.OneTick] : undefined
              }
              onChangeTab={handleChangeTimeRange}
              itemStyle={{
                width: '50%',
                fontWeight: 600,
              }}
            />
          </>
        ) : (
          <Fragment key={JSON.stringify(sizes)}>
            <DispatchActionTabWithCalendar
              id="indicesMarketWatchIntraday"
              tabs={getTimeRangeByDataType}
              activeTab={tableFilter.timeRange}
              startDate={tableFilter.dateFrom}
              endDate={tableFilter.dateTo}
              datePreset={getTimeRangeByDataType}
              onChangeTab={handleChangeTimeRange}
              onChangeCalendar={handleChangeCalendar}
            />
          </Fragment>
        )}
      </div>
      {/* <div>
        <ThePagination
          currentPage={tableFilter.page}
          totalPages={tableFilter.totalPage}
          changeCurrentPage={handleChangePage}
        />
      </div> */}
    </div>
  )
}
