export const PRICE_SCALE = 100

export const supportedResolutions = [
  '1',
  '5',
  '15',
  '30',
  '60',
  '120',
  '240',
  'D',
  'W',
  'M',
  '3M',
  '12M',
]
export const MAX_ITEM_LENGTH = 100

export const EXCHANGES = {
  ALL: 'All',
  HOSE: 'HOSE',
  HNX: 'HNX',
  UPCOM: 'UPCOM',
}

export const MAP_EXCHANGES = {
  HOSE: ['HOSE'],
  HNX: ['HNX'],
  UPCOM: ['UPCoM'],
}

export const SYMBOL_TYPES = {
  ALL: 'All',
  STOCK: 'Stock',
  FUTURES: 'Futures',
  INDICES: 'Indices',
  ECONOMY: 'Economy',
  COVERED_WARRANT: 'CW',
  COMMODITY: 'Commodity',
  MORE_INDICATOR: 'MoreIndicator',
}

export const config = (symbolsTypes) => ({
  supported_resolutions: supportedResolutions,
  exchanges: [
    { name: 'All Exchanges', value: EXCHANGES.ALL },
    { name: 'HOSE', value: EXCHANGES.HOSE },
    { name: 'HNX', value: EXCHANGES.HNX },
    { name: 'UPCOM', value: EXCHANGES.UPCOM },
  ],
  supports_group_request: false,
  supports_marks: true,
  supports_search: true,
  supports_time: true,
  supports_timescale_marks: true,
  symbols_types: symbolsTypes,
})
