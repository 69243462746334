import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { ConsensusHistoryChart } from './chart'
import {
  selectChartLineData,
  selectTableData,
  selectTableLoading,
} from './store/slice'
import {
  getHistory,
  getHistoryStackColumn,
  getTargetPriceDetail,
} from './store/thunk'
import { ConsensusHistoryTable } from './table'

export const ConsensusHistory = ({ width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const tableData = useSelector(selectTableData)
  const chartLineData = useSelector(selectChartLineData)
  const tableLoading = useSelector(selectTableLoading)

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getHistory({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo])

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getTargetPriceDetail({ OrganizationId: basicInfo.organizationId }),
      )
    }
  }, [basicInfo])

  useEffect(() => {
    if (chartLineData.length) {
      dispatch(
        getHistoryStackColumn({ OrganizationId: basicInfo.organizationId }),
      )
    }
  }, [chartLineData])

  return (
    <>
      <div style={{ height: height * 0.6 || 0 }}>
        <ConsensusHistoryChart width={width} height={height * 0.6} />
      </div>
      {/* Table's height = panel's height - padding's top */}
      <div
        style={{
          height: height * 0.4 - 20 || 0,
          paddingTop: 20,
          position: 'relative',
        }}
      >
        {tableLoading ? (
          <Loading />
        ) : tableData.length ? (
          <ConsensusHistoryTable />
        ) : (
          <NoData />
        )}
      </div>
    </>
  )
}
