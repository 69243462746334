import React, { useEffect, useState } from 'react'
import TextEllipsis from '../../../common/textEllipsis'
import { getTimeFrequencyIndicator } from '../../chart/helper'
import style from './style.module.css'

const MetricItemTree = ({
  item,
  indicator,
  setIndicator,
  category,
  typeIndicator,
  setItemChoose,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)
  const arrChildren = Object.values(item.children)

  const handleClickParents = (item) => {
    if (arrChildren) {
      setIsCollapse(!isCollapse)
    } else {
      setIndicator(item.id)
      setItemChoose({
        typeIndicator: typeIndicator,
        value: {
          id: item.id,
          timeFrequency: getTimeFrequencyIndicator(item),
          unit: item.unit,
        },
      })
    }
  }

  const handleClickChildren = (item) => {
    setIndicator(item.id)
    setItemChoose({
      typeIndicator: typeIndicator,
      value: {
        id: item.parentId,
        parentId: category,
        timeFrequency: item.timeFrequency,
        unit: item.unit,
      },
    })
  }

  useEffect(() => {
    if (parseInt(indicator) === item.id) {
      setIsCollapse(true)
    }
  }, [indicator])

  return (
    <div id={item.id}>
      <div
        className={style.indicator}
        key={item.id}
        onClick={() => handleClickParents(item)}
      >
        <div
          className={`${style.indicatorName} d-flex ali-center w-100`}
          style={{
            color: item.id === indicator ? '#2f9aee' : '#4E4E4E',
          }}
        >
          {arrChildren.length ? (
            <>
              {isCollapse ? (
                <i className={`${style.i} icon-caret-down`} />
              ) : (
                <i className={`${style.i} icon-caret-right`} />
              )}
            </>
          ) : null}
          <TextEllipsis isI18n={false} text={item.name} />
        </div>
        {!arrChildren.length ? (
          <div
            className={style.indicatorRadio}
            style={{
              opacity: item.id === indicator ? 1 : 0,
            }}
          >
            <input
              type="radio"
              className={`radiobox radiobox2 cursor-pointer`}
              style={{ border: '0.5px solid #979797', borderRadius: '50%' }}
              checked
              readOnly
            />
          </div>
        ) : null}
      </div>
      {isCollapse &&
        arrChildren.map((itemChildren) => (
          <div
            className={style.indicator}
            style={{ marginLeft: 34 }}
            key={itemChildren.id}
            onClick={() => handleClickChildren(itemChildren)}
          >
            <div
              className={`${style.indicatorName} d-flex ali-center w-100`}
              style={{
                color: itemChildren.id === indicator ? '#2f9aee' : '#4E4E4E',
              }}
            >
              <TextEllipsis isI18n={false} text={itemChildren.name} />
            </div>
            <div
              className={style.indicatorRadio}
              style={{
                opacity: itemChildren.id === indicator ? 1 : 0,
              }}
            >
              <input
                type="radio"
                className={`radiobox radiobox2 cursor-pointer`}
                style={{ border: '0.5px solid #979797', borderRadius: '50%' }}
                checked
                readOnly
              />
            </div>
          </div>
        ))}
    </div>
  )
}

export default MetricItemTree
