import { uniq } from 'lodash'
import { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import PopupEvent from '../../../../common/popup/PopupEvent'
import { TAB_TYPES } from '../../constants'
import {
  renderAddedInformaticsParams,
  renderConditionParams,
} from '../../helper'
import {
  changeActiveTab,
  changeParameters,
  changeTemplateId,
  changeTemplateList,
  changeTemplateMoreList,
  paramsDefault,
  selectActiveTab,
  selectAddInformatics,
  selectConditions,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectIsRename,
  selectIsSave,
  selectIsUpdate,
  selectTemplate,
  selectTemplateId,
  selectTemplateMore,
  setInitialSort,
  setIsRename,
} from '../../store/slice'
import {
  deleteTemplateByIdData,
  getTemplateByIdData,
  getTemplatesDataBySave,
  getTemplatesDataByUpdate,
  renameTemplateByIdData,
} from '../../store/thunk'
import { changeIsFirst } from '../Table/store/slice'
import { getDataBondList } from '../Table/store/thunk'
import InputEditName from './InputEditName'
import TabItem from './TabItem'
import TabItemMore from './TabItemMore'
import style from './index.module.css'

const Tab = ({ redirectToBondIssuer }) => {
  const dispatch = useDispatch()

  const templates = useSelector(selectTemplate)
  const templatesMore = useSelector(selectTemplateMore)
  const templateId = useSelector(selectTemplateId)
  const currentTab = useSelector(selectActiveTab)
  const initialSectors = useSelector(selectInitialSectors)
  const initialConditions = useSelector(selectConditions)
  const initialInformation = useSelector(selectAddInformatics)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const { indicatorGroup } = useSelector(selectFilterParams)
  const isRename = useSelector(selectIsRename)
  const isSave = useSelector(selectIsSave)
  const isUpdate = useSelector(selectIsUpdate)

  const [isShow, setIsShow] = useState(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isDelete, setIsDelete] = useState(false)

  const handleClickTab = (id, check) => {
    if (currentTab === id) return

    dispatch(changeActiveTab(id))
    dispatch(
      getTemplateByIdData({ params: { TemplateId: id }, redirectToBondIssuer }),
    )
    dispatch(changeIsFirst(true))
    if (check) {
      dispatch(changeTemplateList(templatesMore))
    }
  }

  const handleEdit = (item) => {
    setIsShow(null)
    setIsEdit(true)
    dispatch(changeTemplateId(item.templateId))
  }

  const handleDelete = (id) => {
    const rawIndex = templates.findIndex(
      (item) => item.templateId === TAB_TYPES.RAW,
    )

    if (rawIndex !== -1) {
      const newTemplates = [
        ...templates.slice(0, rawIndex),
        ...templates.slice(rawIndex + 1),
      ]

      dispatch(changeTemplateList(newTemplates))
      dispatch(changeActiveTab(TAB_TYPES.ALL))
      const newParams = {
        ...paramsDefault.parameter,
        sectors: [],
        conditions: renderConditionParams(
          initialConditions,
          initialInformation,
        ),
        addedInformations: renderAddedInformaticsParams(initialInformation),
        indicatorGroup,
        pageSize: 300,
        pageIndex: 1,
      }
      dispatch(
        changeParameters({
          ...paramsDefault,
          parameter: {
            ...newParams,
            sectors: uniq([...initialParentSectors, ...initialSectors]),
          },
        }),
      )
      dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

      dispatch(
        setInitialSort({
          sortBy: paramsDefault.parameter.sortBy,
          order: paramsDefault.parameter.order,
        }),
      )

      setIsDelete(null)
      return
    }

    dispatch(
      deleteTemplateByIdData({
        templateId: id,
        redirectToBondIssuer,
      }),
    )
    setIsDelete(null)
  }

  const handleEditTemplate = (name, id) => {
    if (!name?.length) {
      setIsEdit(false)
      dispatch(changeTemplateId(null))
      return
    }

    const rawIndex = templates.findIndex(
      (item) => item.templateId === TAB_TYPES.RAW,
    )

    if (rawIndex !== -1 && id === TAB_TYPES.RAW) {
      const newTemplates = [
        ...templates.slice(0, rawIndex),
        { ...templates[rawIndex], templateName: name },
        ...templates.slice(rawIndex + 1),
      ]

      dispatch(changeTemplateList(newTemplates))

      dispatch(changeTemplateId(null))
      setIsEdit(false)
      return
    }

    const params = {
      templateId: id,
      templateName: name,
    }

    dispatch(renameTemplateByIdData(params))
    dispatch(changeTemplateId(null))
    setIsEdit(false)
  }

  const handleShowPopupEdit = (id) => {
    setIsDelete(id)
  }

  const renderMessage = () => {
    const template = templates.find((item) => item.templateId === isDelete)

    return (
      <>
        <Span style={{ fontSize: 12 }}>
          <Translate value={'bond.bondScreening.filter.DELETE_1'} />
        </Span>
        <Span style={{ fontSize: 12, fontWeight: 600, margin: '0 4px' }}>
          <Translate value={template?.templateName ?? ''} />
        </Span>
        <Span style={{ fontSize: 12 }}>
          <Translate value={'bond.bondScreening.filter.DELETE_2'} />
        </Span>
      </>
    )
  }

  useEffect(() => {
    if (isRename) {
      const index = templates.findIndex(
        (item) => item.templateId === isRename.templateId,
      )

      if (index !== -1) {
        const newTemplates = [
          ...templates.slice(0, index),
          { ...templates[index], templateName: isRename.templateName },
          ...templates.slice(index + 1),
        ]
        dispatch(changeTemplateList(newTemplates))
        dispatch(changeTemplateMoreList(newTemplates))

        dispatch(setIsRename(null))
      }
    }
  }, [isRename])

  useEffect(() => {
    if (isSave) {
      dispatch(getTemplatesDataBySave({ type: indicatorGroup, isSave }))
    }
  }, [isSave])

  useEffect(() => {
    if (isUpdate) {
      dispatch(getTemplatesDataByUpdate({ type: indicatorGroup, isUpdate }))
    }
  }, [isUpdate, indicatorGroup])

  const getListStyle = () => ({
    background: 'transparent',
    display: 'flex',
    gap: 16,
    padding: 0,
  })

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',

    // change background colour if dragging
    background: isDragging ? 'rgb(83, 95, 111)' : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle,
  })

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const items = reorder(
      templates,
      result.source.index,
      result.destination.index,
    )

    dispatch(changeTemplateList(items))
    dispatch(changeTemplateMoreList(items))
  }

  return (
    <div>
      <div className={style.tabLinkContainer}>
        {!!templates.length && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle()}
                  {...provided.droppableProps}
                >
                  {templates.slice(0, 7).map((item, index) => (
                    <Draggable
                      key={`item-${item.templateId}`}
                      draggableId={`item-${item.templateId}`}
                      index={index}
                      isDragDisabled={isEdit && templateId === item.templateId}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          {isEdit && templateId === item.templateId ? (
                            <InputEditName
                              key={index}
                              item={item}
                              handleSubmit={handleEditTemplate}
                              handleClickOutside={() =>
                                dispatch(changeTemplateId(null))
                              }
                            />
                          ) : (
                            <TabItem
                              key={index}
                              item={item}
                              handleClickTab={handleClickTab}
                              setIsShow={setIsShow}
                              isShow={isShow}
                              handleEdit={handleEdit}
                              handleDelete={() => setIsDelete(item.templateId)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
        {templates.length >= 7 && (
          <TabItemMore
            handleDelete={handleShowPopupEdit}
            handleEdit={handleEditTemplate}
            handleClickTab={handleClickTab}
          />
        )}
      </div>
      <PopupEvent
        title={I18n.t('bond.bondScreening.filter.DELETE_TITLE')}
        isShow={isDelete ? true : false}
        message={renderMessage()}
        handleClose={() => setIsDelete(null)}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: () => setIsDelete(null),
        }}
        btnSubmit={{
          btnName: <Translate value="common.button.BUTTON_DELETE" />,
          event: () => handleDelete(isDelete),
        }}
        contentCustomStyle={style.breakWord}
      />
    </div>
  )
}

export default Tab
