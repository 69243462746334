import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import Popup from '../../../../common/popup'
import style from '../../index.module.css'
import ButtonSubmit from './ButtonSubmit'
import Count from './Count'
import FilterCondition from './FilterCondition'
import HeaderTable from './HeaderTable'
import TableCondition from './TableCondition'

const PopupCondition = ({ handleHidePopup }) => {
  return (
    <Popup>
      <div className="modal modal-large tool-page">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.smartScreening.ADD_MORE_INFOR_2" />
            </Span>
          </h3>
          <a onClick={handleHidePopup} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className="content">
            <FilterCondition />
            <div className="col">
              <HeaderTable />
              <div
                className={`col-content ${style.colContent} ${style.tableConditionContainer}`}
              >
                <TableCondition />
              </div>
            </div>
          </div>
          <Count />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              onClick={handleHidePopup}
              className={`btn w-80 h-20 ${style.btnCancel}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit handleHidePopup={handleHidePopup} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

PopupCondition.propTypes = {
  handleHidePopup: PropTypes.func.isRequired,
}

export default PopupCondition
