import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectActiveMethodType } from '../store/slice'
import { ChartComponent } from './ChartComponent'
// import { valueTimefrequency } from './constants'
import { isEmpty } from 'lodash'
import { FooterContainer } from '../../../../../common/chart/footer/FooterContainer'
import FilterCountry from './dropdown'
import {
  selectActiveMethod,
  selectActiveTab,
  selectDataChart,
  selectIdsChart,
  selectLoading,
} from './store/slice'
import { getDataValueOfBonds } from './store/thunk'

const color = ['#185999', '#0096EB', '#FACC5C', '#A6CF98', '#FA9AA7']

const Content = ({ width, height }) => {
  const activeMethod = useSelector(selectActiveMethod)
  const dispatch = useDispatch()
  const TimeRangeActive = useSelector(selectActiveMethodType)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const ids = useSelector(selectIdsChart)
  const locale = useSelector((state) => state.i18n.locale)
  const methodType = useSelector(selectActiveTab)

  const formattedArrayBar = ids
    .map((number) => `${number}-latePaymentValue`)
    .sort((a, b) => (a.includes('0') ? 1 : b.includes('0') ? -1 : 0))

  const formattedArrayScarter = useMemo(
    () =>
      ids
        .map((number) => `${number}-number`)
        .sort((a, b) => (a.includes('0') ? 1 : b.includes('0') ? -1 : 0)),
    [ids],
  )

  useEffect(() => {
    setListCheckbox([...formattedArrayBar])
  }, [ids])

  const [listCheckbox, setListCheckbox] = useState([])

  useEffect(() => {
    dispatch(
      getDataValueOfBonds({
        TimeRange: TimeRangeActive,
        MethodType: activeMethod,
        LatePaymentType: methodType,
      }),
    )
  }, [TimeRangeActive, activeMethod, methodType, locale])

  const checkFooter = () => {
    const data = ids.map((id, index) => {
      const foundItem = dataChart.find((item) => item[`${id}-name`])
      return {
        text: foundItem[`${id}-name`],
        dataKey: `${id}-latePaymentValue`,
        type: TYPE.SQUARE,
        before: {
          bgColor: color[index],
        },
      }
    })
    return !isEmpty(data)
      ? [
          ...data,
          {
            text: 'bond.overview.latePayment.chart.numberDelay',
            dataKey: 'number',
            type: TYPE.SQUARE,
            before: {
              bgColor: '#FFF',
            },
          },
        ]
      : []
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterCountry />
            {size.height && (
              <div style={{ height: height - size.height }}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChart.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div
                              className="position-relative"
                              id="FDILatePayment"
                            >
                              {size.height && (
                                <ChartComponent
                                  data={dataChart}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'XAxis'}
                                  displayData={listCheckbox}
                                  listBar={formattedArrayBar}
                                  listScater={formattedArrayScarter}
                                  listfooter={checkFooter()}
                                  ids={ids}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={checkFooter()}
                                listCheckbox={listCheckbox}
                                setListCheckbox={(e) => setListCheckbox(e)}
                                numItemPerRow={3}
                                // itemStyle={{
                                //   height: '15px',
                                // }}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Content
