import { useRef, useState } from 'react'
import { Span } from '../../../../common/html/Span'
import DropdownTooltip from '../../../../common/textEllipsis/DropdownTooltip'

export const TooltipDisabled = () => {
  const ref = useRef()
  const [isShow, setIsShow] = useState(false)

  const onMouseEnter = () => {
    setIsShow(true)
  }

  const onMouseLeave = () => {
    setIsShow(false)
  }

  return (
    <>
      <div
        ref={ref}
        className="d-flex justify-content-end"
        style={{ paddingLeft: 8 }}
      >
        <Span style={{ fontSize: 11 }}>
          <i
            style={{ color: '#a8acb2' }}
            className="icon-info"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        </Span>
      </div>

      {isShow && (
        <DropdownTooltip
          containerRef={ref}
          text="sector.sectorConstituents.segmentation.MAX_ITEMS_TEXT"
          isOpenTooltip={true}
          isI18n={true}
        />
      )}
    </>
  )
}
