import { createAsyncThunk } from '@reduxjs/toolkit'
import PeerValuationService from '../../../../../../core/services/corporate/PeerValuationService'

export const getHistory = createAsyncThunk(
  'corporate/peerValuation/consensus/consensusHistory/GET_HISTORY',
  async (data, { rejectWithValue }) => {
    const response = await PeerValuationService.getHistory(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getHistoryStackColumn = createAsyncThunk(
  'corporate/peerValuation/consensus/consensusHistory/GET_HISTORY_STACK_COLUMN',
  async (data, { rejectWithValue }) => {
    const response = await PeerValuationService.getHistoryStackColumn(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getTargetPriceDetail = createAsyncThunk(
  'corporate/peerValuation/consensus/consensusHistory/GET_TARGET_PRICE_DETAIL',
  async (data, { rejectWithValue }) => {
    const response = await PeerValuationService.getTargetPriceDetail(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
