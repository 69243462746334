import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  changeFilter,
  keys,
  selectDebtDetailsData,
  selectFilterDebtAndLoan,
  selectLoading,
} from '../store/slice'
import { downloadDebtDetailsData, getDebtDetailsData } from '../store/thunk'
import TableContent from './Table'
import style from './index.module.css'

const DebtDetails = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectLoading(keys.DEBT_DETAIL))
  const basicInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectFilterDebtAndLoan)
  const locale = useSelector((state) => state.i18n.locale)
  const { ids } = useSelector(selectDebtDetailsData)

  const [zoom, setZoom] = useState(false)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    if (basicInfo.organizationId) {
      const payload = {
        TimeFrequency: activeTab,
        OrganizationId: basicInfo.organizationId,
      }

      return downloadDebtDetailsData(payload)
    }
  }

  useEffect(() => {
    if (basicInfo.organizationId) {
      const payload = {
        TimeFrequency: activeTab,
        OrganizationId: basicInfo.organizationId,
      }

      dispatch(getDebtDetailsData(payload))
      dispatch(
        changeFilter({
          label: keys.DEBT_DETAIL,
          key: 'isFirst',
          value: true,
        }),
      )
    }
  }, [locale, basicInfo.organizationId, activeTab])

  const handleZoom = (value) => {
    setZoom(value)
  }

  return (
    <Panel
      title="bond.corporateBond.debtDetails.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
      handleCustom={handleZoom}
    >
      {isLoading ? (
        <Loading />
      ) : !!ids?.length ? (
        <SizeTracker>
          {(size) => (
            <>
              <div style={{ height: 20 }}>
                <div
                  className={`d-flex justify-content-end ${style.noteTable}`}
                >
                  <Span>
                    <Translate value="economy.productionAndConsumption.consumption.TABLE_NOTE" />
                  </Span>
                </div>
              </div>
              {size.height && (
                <div style={{ height: height - size.height, width: width }}>
                  <TableContent width={width} zoom={zoom} />
                </div>
              )}
            </>
          )}
        </SizeTracker>
      ) : (
        <NoData />
      )}
    </Panel>
  )
}

export default DebtDetails
