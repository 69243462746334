import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Checkbox } from '../../../../../common/html/Checkbox'
import SelectCustom from '../../../../../common/selectCustom'
import { selectFundInfo } from '../../../../../common/topInfo/fundTopInfo/store/slice'
import { MONTH_OPTIONS } from '../../../../../constants/Datetime'

export const TimeOption = ({ month, year, isGroup, onChange }) => {
  const fundInfo = useSelector(selectFundInfo)

  const getYears = useMemo(() => {
    return new Array(new Date().getFullYear() - 2000 + 1)
      .fill(null)
      .map((_, index) => ({
        name: String(new Date().getFullYear() - index),
        value: new Date().getFullYear() - index,
      }))
  }, [])

  const getMonths = useMemo(() => {
    return MONTH_OPTIONS
  }, [])

  useEffect(() => {
    if (month && year) {
      onChange({
        month: 0,
        year: 0,
      })
    }
  }, [fundInfo.organizationId])

  return (
    <div className="d-flex ali-center">
      <div className="d-flex ali-center mr-16">
        <div style={{ width: 85 }} className="mr-8">
          <SelectCustom
            value={month}
            selectData={getMonths}
            handleChange={(value) => onChange({ month: value })}
            isI18n
          />
        </div>
        <div style={{ width: 85 }}>
          <SelectCustom
            value={year}
            selectData={getYears}
            handleChange={(value) => onChange({ year: value })}
            isI18n
          />
        </div>
      </div>
      <Checkbox
        value={isGroup}
        title="fund.fundProfile.portfolio.portfolioTable.GROUP_BY_INDUSTRY"
        isI18n
        onChange={(val) => onChange({ isGroupByIndustry: val })}
      />
    </div>
  )
}
