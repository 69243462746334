import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { Table } from '../../../../common/table'
import { keyBy } from '../../../../utils/Common'
import { formatVal } from '../../../../utils/Value'
import FirstCellColumn from './FirstCellColumn'
import {
  selectDataTable,
  selectDataTableById,
  selectGroupColumns,
  selectIds,
  selectLoadingTable,
  selectRowsCollapse,
  setRowsCollapse,
  sort,
} from './store/slice'
import { getStateBudgetBalance } from './store/thunk'

const PERIOD_NUM = 20
const TIME_RANGE = 'OneYear'
const MIN_WIDTH_COL = 130

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoadingTable)
  const ids = useSelector(selectIds)
  const groupColumns = useSelector(selectGroupColumns)
  const dataTable = useSelector(selectDataTable)
  const locale = useSelector((state) => state.i18n.locale)
  const rowsCollapse = useSelector(selectRowsCollapse)

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
    }
  }

  const levelCollapse = keyBy(
    dataTable?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.childrenIds.length !== 0,
    })),
    'id',
  )

  useEffect(() => {
    dispatch(
      getStateBudgetBalance({
        NumberOfPeriod: PERIOD_NUM,
        TimeRange: TIME_RANGE,
      }),
    )
  }, [locale])

  return (
    <div style={{ height: height }}>
      <Table
        ids={ids}
        columnDraggable={false}
        sort={sort}
        getDataFromRedux={selectDataTableById}
        rowDraggable={false}
        isLoading={isLoading}
        schema={groupColumns?.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }
          if (index === 0) {
            return {
              ...result,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                minWidth: 240,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHorizontalHead = dataTable.find(
                  (item) => item.id === rowId,
                )
                return (
                  <FirstCellColumn
                    props={props}
                    val={tableHorizontalHead?.name}
                    level={tableHorizontalHead?.level}
                    childrenIds={tableHorizontalHead?.childrenIds.length !== 0}
                    rowId={rowId}
                    onRowCollapse={onRowCollapse}
                    isRowCollapse={rowsCollapse.includes(rowId)}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            tdStyle: {
              textAlign: 'right',
              fontSize: 12,
              minWidth: MIN_WIDTH_COL,
            },
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
              fontWeight: 600,
              whiteSpace: 'pre-wrap',
              minWidth: MIN_WIDTH_COL,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <td {...props}>
                  <Span>{formatVal(val)}</Span>
                </td>
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight={true}
        isCollapse={true}
        levelCollapse={levelCollapse}
        rowsCollapse={rowsCollapse}
      />
    </div>
  )
}

export default TableComponent
