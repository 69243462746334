import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { useSearchParams } from 'react-router-dom'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import SelectCustom from '../../../../../common/selectCustom'
import {
  changeSlectedTemplateId,
  LOADING_TYPE,
  selectLoading,
  selectSelectedTemplateId,
  selectShareTemplate,
  selectTemplates,
} from '../../store/slice'
import {
  getTemplateByShareId,
  getTemplateDetail,
  getTemplates,
} from '../../store/thunk'
import DeleteTemplate from './DeleteTemplate'
import style from './index.module.css'
import TemplateItem from './TemplateItem'

const Template = () => {
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  const shareTemplate = useSelector(selectShareTemplate)
  const templates = useSelector(selectTemplates)
  const selectedTemplateId = useSelector(selectSelectedTemplateId)
  const loadingTemplate = useSelector(selectLoading(LOADING_TYPE.TEMPLATE))
  const loadingIndicators = useSelector(selectLoading(LOADING_TYPE.INDICATORS))

  const [currentTemplate, setCurrentTemplate] = useState(null)
  const [isShowDelete, setIsShowDelete] = useState(false)

  const handleCloseDelete = () => {
    setIsShowDelete(false)
  }

  const handleShowDelete = () => {
    setIsShowDelete(true)
  }

  useEffect(() => {
    if (searchParams.has('shareId')) {
      dispatch(getTemplateByShareId({ ShareId: searchParams.get('shareId') }))
    }
  }, [searchParams])

  useEffect(() => {
    dispatch(getTemplates())
  }, [])

  const handleChangeTemplate = (templateId) => {
    dispatch(changeSlectedTemplateId(templateId))
    dispatch(getTemplateDetail({ TemplateId: templateId }))
  }

  const renderItemSelect = (props) => {
    return (
      <TemplateItem
        {...props}
        setCurrentTemplate={setCurrentTemplate}
        handleShowDelete={handleShowDelete}
      />
    )
  }

  const selectData =
    loadingTemplate || loadingIndicators
      ? []
      : templates.map((item) => ({
          value: item.templateId,
          name: item.templateName,
          ...item,
        }))

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.TEMPLATE_LABEL" />
      </Span>
      <div className={style.mt4}>
        <SelectCustom
          selectData={selectData}
          value={selectedTemplateId}
          handleChange={handleChangeTemplate}
          textPlaceholder={
            <Span
              className={shareTemplate ? '' : style.disabled}
              style={{ fontSize: shareTemplate ? 12 : 10 }}
            >
              {shareTemplate ? (
                shareTemplate.templateName
              ) : (
                <Translate value="sector.sectorConstituents.segmentation.CHOOSE_TEMPLATE" />
              )}
            </Span>
          }
          renderItemSelect={renderItemSelect}
          dropdownMinHeight={120}
          noDataContent={
            loadingTemplate || loadingIndicators ? (
              <Loading />
            ) : (
              <div className={style.noTemplate}>
                <Translate value="sector.sectorConstituents.segmentation.NO_TEMPLATE" />
              </div>
            )
          }
        />
      </div>
      {isShowDelete && (
        <DeleteTemplate
          item={currentTemplate}
          handleClose={handleCloseDelete}
        />
      )}
    </div>
  )
}

export default Template
