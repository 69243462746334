import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useCustomNavigate from '../../../../common/hooks/useCustomNavigate'
import { List } from '../../../../common/news/List'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { FEATURES } from '../../../../constants/routes'
import { ANNOUNCEMENT_TYPE } from '../constants'
import {
  selectNewsTransactionLoading,
  selectNewsTransactions,
} from '../store/slice'
import { getNewsTransactionsPaged } from '../store/thunk'

export const Transaction = ({
  numColumn,
  marginLeft,
  marginRight,
  offsetWidth,
}) => {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const { data = [] } = useSelector(selectNewsTransactions)
  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectNewsTransactionLoading)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getNewsTransactionsPaged({
          OrganizationId: basicInfo.organizationId,
          Page: 1,
          PageSize: 10,
        }),
      )
    }
  }, [locale, basicInfo.organizationId])

  const onMoreAction = () => {
    navigate(
      FEATURES.corporate.components['news-events'].components['news-more']
        .route,
      basicInfo,
      '?announcementType=' + ANNOUNCEMENT_TYPE.TRANSACTION,
    )
  }
  const onDetailAction = (newsId) => () => {
    navigate(
      FEATURES.corporate.components['news-events'].components['news-detail']
        .route,
      basicInfo,
      '?newsId=' + newsId,
    )
  }

  return (
    <List
      title={'corporate.news.TRANSACTIONS'}
      data={data}
      isLoading={isLoading}
      isNodata={data.length === 0}
      numColum={numColumn}
      marginLeft={marginLeft}
      marginRight={marginRight}
      offsetWidth={offsetWidth}
      onMoreAction={onMoreAction}
      onDetailAction={onDetailAction}
    />
  )
}

Transaction.propTypes = {
  numColumn: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  offsetWidth: PropTypes.number,
}
