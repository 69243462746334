import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { SizeTracker } from '../../../common/sizeTracker'
import PieChartWithHover from '../common/PieChartWithHover'
import style from './index.module.css'

const ChartPieOverview = ({ width, height, data }) => {
  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <div className={style.chartWrapper}>
              <Span
                className={[style.chartTitle, 'canvas-chart-title'].join(' ')}
              >
                <Translate value="corporate.ownership.title.overview" />
              </Span>
              <div className={style.canvasChartContainer}>
                {size.height && (
                  <PieChartWithHover
                    width={width}
                    height={height / 2 - size.height}
                    data={data}
                  />
                )}
              </div>
            </div>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default ChartPieOverview
