import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TotalGDPService from '../../../../../../core/services/economy/gdp/TotalGDPService'

export const getGDPCompositionBySectorData = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPCompositionBySector/getGDPCompositionBySectorData',
  async (params, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getGDPCompositionBySector(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const changesInGDPCompositionData = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPCompositionBySector/changesInGDPCompositionData',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.changesInGDPComposition(data)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGDPTypeName = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPCompositionBySector/getGDPTypeName',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getGDPTypeName(data)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxPeriod = createAsyncThunk(
  'economy/gdp/totalGDP/panelGDPCompositionBySector/getMaxPeriod',
  async (data, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getMaxPeriod(data)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
