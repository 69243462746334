import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useGetStockHOHAUP from '../../../../common/masterData/useGetStockHOHAUP'
import { Table } from '../../../../common/table'
import {
  selectDataFDF,
  selectKeyFinancialDataValue,
} from '../../filter/store/slice'

const KeyFinancialDataTable = () => {
  const data = useSelector(selectDataFDF)
  const { stocksHohaup } = useGetStockHOHAUP()

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  return (
    <div style={{ height: dimensions.height }}>
      <Table
        ids={data.pricePerformanceTable.ids}
        columnDraggable={false}
        getDataFromRedux={selectKeyFinancialDataValue}
        isLoading={false}
        rowDraggable={false}
        schema={data.pricePerformanceTable.groupColumns.map((item, index) => {
          const title =
            item.key === 'name'
              ? ''
              : stocksHohaup.find((i) => i.organizationId === item.title)
                  ?.ticker
          const colId = item.key
          const result = {
            colId,
            title,
            tdStyle: {
              textAlign: 'right',
              fontSize: 9,
            },
            thStyle: {
              padding: 11,
              textAlign: 'right',
              fontSize: 9,
            },
          }
          if (index === 0) {
            return {
              ...result,
              thStyle: {
                textAlign: 'left',
                fontSize: 9,
              },
              canCustomTd: true,
              render: (val) => (
                <td style={{ fontSize: 9 }}>
                  <Translate value={val} />
                </td>
              ),
            }
          }
          return result
        })}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        dimensionsTable={setDimensions}
        hasTooltip={false}
      />
    </div>
  )
}

export default KeyFinancialDataTable
