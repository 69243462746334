import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import Title from '../../common/topInfo/components/Title'
import Content from './Content'
import style from './index.module.css'

const Valuation = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="top-info">
                <Title title="bond.valuation.valuation.TITLE" />
              </div>
              {size.height && (
                <div
                  className={style.relative}
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default Valuation
