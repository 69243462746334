import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { convertSortKeyToNumber } from '../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import { PRICE_DATA_TIME_SELECT } from '../../common/CalendarFilter'
import { TABLE_TYPE } from './constants'
import { FilterList } from './FilterList'
import { getGroupColumnsByTableType } from './helper'
import style from './index.module.css'
import {
  changeGroupColumns,
  changePage,
  changeSortField,
  changeSortOrder,
  changeTableType,
  selectDataTable,
  selectDataTableById,
  selectFilter,
  selectGroupColumns,
  selectIds,
  selectIsChangeFilter,
  selectLoadingTable,
  selectRecalcWidths,
  sort,
} from './store/slice'
import {
  getProprietaryDetail,
  getProprietaryOverview,
  getProprietaryTicker,
} from './store/thunk'

const tableId = 'overviewStatistics'
const PAGE_SIZE = 22
const PAGE_NAVIGATION_HEIGHT = 20
const DEFAULT_FILTER_HEIGHT_PERCENT = 0.4

export const OverviewStatistics = ({ width, height }) => {
  const dispatch = useDispatch()

  const timeZone = UseTimeZone()

  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoadingTable)
  const filterData = useSelector(selectFilter)
  const groupColumns = useSelector(selectGroupColumns)
  const reCalcWidths = useSelector(selectRecalcWidths)
  const dataTable = useSelector(selectDataTable)
  const isChangeFilter = useSelector(selectIsChangeFilter)
  const locale = useSelector((state) => state.i18n.locale)

  const [tableHeight, setTableHeight] = useState(0)

  const renderContentPagination = () => {
    return (
      <Span className={style.noteTable}>
        <Translate value="market.derivative.NOTE_TABLE" />
      </Span>
    )
  }

  const firstDateOfCurrentYear = new Date(new Date().getFullYear(), 0, 1)

  const filterListHeight =
    document.getElementById('filterList')?.getBoundingClientRect().height ??
    height * DEFAULT_FILTER_HEIGHT_PERCENT

  const setPage = (value) => {
    dispatch(changePage(value))
  }

  const onChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(changeSortField(Object.keys(data)[0]))
      dispatch(changeSortOrder(convertSortKeyToNumber(Object.values(data)[0])))
    } else {
      dispatch(changeSortField(null))
      dispatch(changeSortOrder(null))
    }
  }

  useEffect(() => {
    dispatch(
      changeGroupColumns(
        getGroupColumnsByTableType(filterData, locale, timeZone, dataTable),
      ),
    )
  }, [filterData?.tableType, dataTable])

  useEffect(() => {
    if (!filterData?.isTotal) {
      dispatch(changeTableType(TABLE_TYPE.TICKER))
    } else if (
      filterData?.tableType === TABLE_TYPE.OVERVIEW ||
      !filterData?.tableType
    ) {
      dispatch(changeTableType(TABLE_TYPE.OVERVIEW))
    } else {
      dispatch(changeTableType(TABLE_TYPE.DETAIL))
    }
  }, [filterData?.isTotal])

  const params = {
    StartDate: filterData?.dateFrom
      ? getISOStartOrEndOfDay(filterData?.dateFrom, timeZone, true)
      : firstDateOfCurrentYear,
    EndDate: filterData?.dateTo
      ? getISOStartOrEndOfDay(filterData?.dateTo, timeZone, false)
      : new Date(),
    Page: filterData?.page,
    PageSize: PAGE_SIZE,
    SortOrder: filterData?.sortOrder ?? '',
    TimeFilter: filterData?.timeFrequency || PRICE_DATA_TIME_SELECT.DAILY,
  }

  useEffect(() => {
    if (filterData?.sortField) {
      params.SortField = filterData?.sortField
    }
    if (filterData?.derivativeId && filterData?.derivativeId > 0) {
      params.FutureId = filterData?.derivativeId
      dispatch(getProprietaryTicker(params))
    } else if (filterData?.tableType === TABLE_TYPE.OVERVIEW) {
      params.GroupId = filterData?.derivativeId
      dispatch(getProprietaryOverview(params))
    } else {
      params.GroupId = filterData?.derivativeId
      dispatch(getProprietaryDetail(params))
    }
  }, [locale, filterData])

  useEffect(() => {
    if (height && filterListHeight) {
      setTableHeight(height - filterListHeight - PAGE_NAVIGATION_HEIGHT)
    }
  }, [height - filterListHeight])

  return (
    width &&
    height && (
      <>
        <div style={{ height: '100%' }}>
          <div id="filterList">
            <FilterList filterData={filterData} width={width} />
          </div>
          {isChangeFilter && <Loading />}
          {!isChangeFilter &&
            (!dataTable.length ? (
              <div
                style={{
                  height: tableHeight,
                }}
              >
                <NoData />
              </div>
            ) : (
              groupColumns?.schema.length &&
              groupColumns?.rowSpanSchema.length && (
                <div
                  style={{
                    height: tableHeight,
                  }}
                  id={tableId}
                >
                  <Table
                    ids={ids}
                    getDataFromRedux={selectDataTableById}
                    isLoading={isLoading}
                    columnDraggable={false}
                    stickies={
                      filterData?.tableType === TABLE_TYPE.DETAIL
                        ? { tradingDateId: true, ticker: true }
                        : { tradingDateId: true }
                    }
                    hasTooltip={false}
                    hasFooter={false}
                    resizable={false}
                    schema={groupColumns?.schema}
                    rowSpanSchema={groupColumns?.rowSpanSchema}
                    hasPagination={true}
                    currentPage={filterData?.page}
                    totalPage={filterData?.totalPage}
                    handleChangePage={setPage}
                    renderContentPagination={renderContentPagination}
                    reCalcWidths={reCalcWidths}
                    sort={sort}
                    handleSortField={onChangeSortField}
                  />
                </div>
              )
            ))}
        </div>
      </>
    )
  )
}
