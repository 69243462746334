import PropTypes from 'prop-types'
import { handleNameFileDownload } from '../../utils/Export'
import { getFontSize } from '../../utils/FontSize'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { exportToImg } from './helper'

const DownloadJpg = ({ data, titleJpg }) => {
  const {
    domId,
    nameScreen,
    chartName,
    tabName,
    tickerName,
    isUseOriginHtml2Canvas,
  } = data
  const fontSize = getFontSize(11)

  const { formatWithZoom, reverseFormatWithZoom } = useWindowDevicePixelRatio()

  const saveImageStockChar = () => {
    exportToImg({
      domId,
      exportName: `${handleNameFileDownload(
        nameScreen, // feature name of the current interface
        chartName, // name chart
        tabName, // name of the tab in the table (do not take the time frame part)
        tickerName, //stock ticker/ index/ company short name
      )}.jpg`,
      titleJpg,
      fontSize,
      formatWithZoom,
      reverseFormatWithZoom,
      isUseOriginHtml2Canvas,
    })
  }

  return (
    <a
      onClick={saveImageStockChar}
      className="cursor-pointer"
      style={{ marginLeft: 16 }}
    >
      <i className="icon-download" />
    </a>
  )
}

DownloadJpg.propTypes = {
  data: PropTypes.shape({
    domId: PropTypes.string.isRequired,
    nameScreen: PropTypes.string.isRequired,
    chartName: PropTypes.string.isRequired,
    tickerName: PropTypes.string,
    tabName: PropTypes.string,
  }).isRequired,
  titleJpg: PropTypes.string,
}

export default DownloadJpg
