import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DataExplorerService from '../../../../../core/services/tool/dataExplorer/DataExplorerService'
import {
  countIndicator,
  getListIndicator,
} from '../../../../common/dataExplorer/helper'
import AGM from '../../../../common/dataExplorer/popupIndicator/AGM'
import CashDividend from '../../../../common/dataExplorer/popupIndicator/CashDividend'
import CashDividendPlan from '../../../../common/dataExplorer/popupIndicator/CashDividendPlan'
import FinancialRatio from '../../../../common/dataExplorer/popupIndicator/FinancialRatio'
import FinancialStatement from '../../../../common/dataExplorer/popupIndicator/FinancialStatement'
import FinancialTarget from '../../../../common/dataExplorer/popupIndicator/FinancialTarget'
import IcbName from '../../../../common/dataExplorer/popupIndicator/IcbName'
import IndicatorShareholders from '../../../../common/dataExplorer/popupIndicator/IndicatorShareholders'
import OtherIcb from '../../../../common/dataExplorer/popupIndicator/OtherIcb'
import Performance from '../../../../common/dataExplorer/popupIndicator/Performance'
import ShareIssue from '../../../../common/dataExplorer/popupIndicator/ShareIssue'
import StockDividend from '../../../../common/dataExplorer/popupIndicator/StockDividend'
import StockDividendPlan from '../../../../common/dataExplorer/popupIndicator/StockDividendPlan'
import TradingData from '../../../../common/dataExplorer/popupIndicator/TradingData'
import TreasuryStock from '../../../../common/dataExplorer/popupIndicator/TreasuryStock'
import { maxIndicator } from '../../../../common/dataExplorer/popupIndicator/constant'
import EventEmitter from '../../../../utils/EventEmitter'
import { typePopupIndicator } from '../constant'
import { convertDataColumns, convertParamGetMostRecent } from '../helper'
import {
  addColumn,
  clearGroup,
  closePopupIndicator,
  selectActionType,
  selectActiveSheet,
  selectColumnById,
  selectDetailPopupIndicator,
  selectFullColumnId,
  selectGroupColumnById,
  selectIndicatorById,
  selectShareIssueMethod,
  sliceSubGroup,
} from '../store/slice'
import {
  getActionTypeThunk,
  getFiinXTemplateShareIssueMethodThunk,
  getMostRecentThunk,
  getShareIssueMethodThunk,
} from '../store/thunk'
import useGetIndicator from '../useGetIndicator'

export const SHOW_LOADING = 'SHOW_LOADING'
export const HIDE_LOADING = 'HIDE_LOADING'

const PopupIndicator = () => {
  const dispatch = useDispatch()

  const { handleTreePopupIndicator } = useGetIndicator()

  const activeSheet = useSelector(selectActiveSheet)
  const detail = useSelector(selectDetailPopupIndicator)
  const allColumnId = useSelector(selectFullColumnId(activeSheet))
  const shareIssueMethod = useSelector(selectShareIssueMethod)
  const actionType = useSelector(selectActionType)
  const locale = useSelector((state) => state.i18n.locale)
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const columnById = useSelector(selectColumnById(activeSheet))
  const indicatorById = useSelector(selectIndicatorById)

  const [mostRecentDate, setMostRecentDate] = useState(null)

  useEffect(() => {
    dispatch(getShareIssueMethodThunk())
    dispatch(getFiinXTemplateShareIssueMethodThunk())
    dispatch(getActionTypeThunk())
  }, [locale])

  useEffect(() => {
    const asyncFn = async () => {
      if (detail) {
        try {
          EventEmitter.dispatch(SHOW_LOADING)
          const response = await DataExplorerService.getMostRecentDate({
            ConditionType: detail.conditionType,
          })
          if (response.success) {
            setMostRecentDate(response.data)
          }
          EventEmitter.dispatch(HIDE_LOADING)
        } catch {
          EventEmitter.dispatch(HIDE_LOADING)
        }
      } else {
        setMostRecentDate(null)
      }
    }

    asyncFn()
  }, [detail])

  const handleCancel = () => dispatch(closePopupIndicator())

  const handleSubmit = (data) => {
    dispatch(
      getMostRecentThunk(
        convertParamGetMostRecent(
          data.indicator.map((indicatorId) => data.indicatorById[indicatorId]),
        ),
      ),
    )
    const indicators = convertDataColumns(
      detail.conditionType,
      data,
      indicatorById,
    )
    const isEdit = detail.alias
    const dataSubmit = {
      detail: {
        conditionType: detail.conditionType,
        condition: data.detail,
        valuePopupIndicator: data.valuePopupIndicator,
      },
      isGroup: data.isGroup,
    }

    if (!isEdit) {
      dispatch(
        addColumn({
          indicators,
          ...dataSubmit,
        }),
      )
    } else if (data.isGroup) {
      const indexColumn = allColumnId.findIndex(
        (id) => columnById[id].groupId === detail.groupId,
      )
      dispatch(clearGroup(detail.groupId))
      dispatch(
        addColumn({
          indicators,
          indexColumn,
          ...dataSubmit,
        }),
      )
    } else {
      dispatch(
        sliceSubGroup({
          indicators: indicators.filter(
            (indicator) => indicator.indicatorId === detail.indicatorId,
          ),
          indicatorId: detail.indicatorId,
          groupId: detail.groupId,
          ...dataSubmit,
        }),
      )
    }
    handleCancel()
  }

  const renderPopup = () => {
    if (detail && mostRecentDate) {
      const {
        maxDate,
        maxYear,
        minDate,
        minYear,
        ttmMaxQuarter,
        ttmMaxYear,
        ttmMinYear,
      } = mostRecentDate
      const isEdit = detail.alias

      const treeIndicator = handleTreePopupIndicator(detail.conditionType)

      const defaultProps = {
        handleSubmit,
        handleCancel,
        title: indicatorById[detail.indicatorId]?.indicatorName,
        maxIndicator,
        lengthIndicator: allColumnId.length,
        tree: treeIndicator,
        defaultIndicators: [detail],
        ...(isEdit
          ? {
              lengthIndicator:
                allColumnId.length -
                countIndicator(groupColumnById[detail.groupId]),
              defaultIndicators: getListIndicator(
                groupColumnById[detail.groupId],
                columnById,
                indicatorById,
              ),
              defaultDetail: {
                detail: detail.valuePopupIndicator,
                isGroup: detail.isGroup,
                mainIndicator: columnById[isEdit].indicatorId,
                isEdit: true,
              },
            }
          : {}),
      }
      switch (detail.conditionType) {
        case typePopupIndicator.DE01_SC07:
          return <IcbName {...defaultProps} />
        case typePopupIndicator.DE01_SC08:
          return <OtherIcb {...defaultProps} />
        case typePopupIndicator.DE01_SC09:
          return (
            <CashDividend
              minYear={minYear}
              maxYear={maxYear}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC10:
          return (
            <StockDividend
              minYear={minYear}
              maxYear={maxYear}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC11:
          return (
            <ShareIssue
              minYear={minYear}
              maxYear={maxYear}
              shareIssueMethod={shareIssueMethod.map((item) => {
                return {
                  value: item.shareIssueMethodId,
                  name: item.shareIssueMethodName,
                }
              })}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC12:
          return (
            <TreasuryStock
              minDate={minDate}
              maxDate={maxDate}
              actionType={actionType.map((item) => {
                return {
                  value: item.actionTypeId,
                  name: item.actionTypeName,
                }
              })}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC13:
          return <AGM minDate={minDate} maxDate={maxDate} {...defaultProps} />
        case typePopupIndicator.DE01_SC14:
          return (
            <CashDividendPlan
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC15:
          return (
            <StockDividendPlan
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC16:
          return (
            <IndicatorShareholders
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC17:
          return (
            <TradingData
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC18:
          return (
            <FinancialRatio
              maxDate={maxDate}
              maxYear={maxYear}
              minDate={minDate}
              minYear={minYear}
              ttmMaxQuarter={ttmMaxQuarter}
              ttmMaxYear={ttmMaxYear}
              ttmMinYear={ttmMinYear}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC19:
          return (
            <Performance
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC20:
          return (
            <FinancialStatement
              ttmMaxQuarter={ttmMaxQuarter}
              ttmMaxYear={ttmMaxYear}
              ttmMinYear={ttmMinYear}
              {...defaultProps}
            />
          )
        case typePopupIndicator.DE01_SC21:
          return (
            <FinancialTarget
              minDate={minDate}
              maxDate={maxDate}
              {...defaultProps}
            />
          )
        default:
          return ''
      }
    }
  }

  return <>{renderPopup()}</>
}

export default PopupIndicator
