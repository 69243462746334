import { range } from 'd3-array'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { languageObjs } from '../../../../../configs/Language'
import { useGetMonths } from '../../../../common/calendar/helper'
import SelectCustom from '../../../../common/selectCustom'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { enMonthsLowerCase, viMonthsLowerCase } from '../constants'
import { selectMaxMonthData, selectMaxYearData } from '../tableData/store/slice'
import {
  changeFilterMonth,
  changeFilterYear,
  selectFilterMonth,
  selectFilterYear,
} from './store/slice'

const FilterMonthYear = () => {
  const dispatch = useDispatch()

  const MONTHS = useGetMonths(false, true)
  const YEARS = range(
    getCurrentDateTime(FORMAT.YEAR) * 1 - 10,
    getCurrentDateTime(FORMAT.YEAR) * 1 + 1,
  ).reverse()

  const locale = useSelector((state) => state.i18n.locale)
  const filterMonth = useSelector(selectFilterMonth)
  const monthDefault = useSelector(selectMaxMonthData)
  const filterYear = useSelector(selectFilterYear)
  const yearDefault = useSelector(selectMaxYearData)

  const onMonthChange = (value) => {
    dispatch(changeFilterMonth(value))
  }

  const onYearChange = (value) => {
    dispatch(changeFilterYear(value))
  }

  useEffect(() => {
    if (monthDefault) {
      onMonthChange(monthDefault)
    }
  }, [monthDefault])

  useEffect(() => {
    if (yearDefault) {
      onYearChange(yearDefault)
    }
  }, [yearDefault])

  return (
    <div className="d-flex justify-content-start">
      <div style={{ width: 120 }}>
        <SelectCustom
          value={filterMonth}
          selectData={MONTHS.map((month, index) => ({
            name:
              locale === languageObjs.en
                ? enMonthsLowerCase[index]
                : viMonthsLowerCase[index],
            value: index + 1,
          }))}
          handleChange={onMonthChange}
        />
      </div>
      <div className="w-80 ml-10">
        <SelectCustom
          value={filterYear}
          selectData={YEARS.map((year) => ({
            name: year,
            value: year,
          }))}
          handleChange={onYearChange}
        />
      </div>
    </div>
  )
}

export default FilterMonthYear
