import { useDispatch, useSelector } from 'react-redux'
import { INDICATOR_TYPE, PERIOD_TYPE } from '../../constant'
import {
  changePointInTime,
  selectIndicatorInfoById,
  selectPointInTime,
  selectXIndicator,
  selectYIndicator,
} from '../../store/slice'
import DailyPoint from './DailyPoint'
import QuarterlyPoint from './QuarterlyPoint'
import YearlyPoint from './YearlyPoint'

const TimePoint = ({ indicatorType }) => {
  const dispatch = useDispatch()

  const indicatorId = useSelector(
    indicatorType === INDICATOR_TYPE.X ? selectXIndicator : selectYIndicator,
  )
  const periodType = useSelector(
    selectIndicatorInfoById(indicatorId, 'periodType'),
  )
  const pointInTime = useSelector(selectPointInTime)

  const handleChangeTime = (value) => {
    dispatch(
      changePointInTime({
        indicatorType,
        data: { [periodType]: value },
      }),
    )
  }

  const renderPointTime = () => {
    switch (periodType) {
      case PERIOD_TYPE.DAILY:
        return (
          <DailyPoint
            value={pointInTime[indicatorType][PERIOD_TYPE.DAILY]}
            onChange={handleChangeTime}
          />
        )
      case PERIOD_TYPE.QUARTERLY:
        return (
          <QuarterlyPoint
            value={pointInTime[indicatorType][PERIOD_TYPE.QUARTERLY]}
            onChange={handleChangeTime}
          />
        )
      case PERIOD_TYPE.YEARLY:
        return (
          <YearlyPoint
            value={pointInTime[indicatorType][PERIOD_TYPE.YEARLY]}
            onChange={handleChangeTime}
          />
        )
      default:
        return <DailyPoint disabled />
    }
  }

  return <div className="mt-8">{renderPointTime()}</div>
}

export default TimePoint
