import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { formatVal, valDivTrillion } from '../../../../utils/Value'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import { GDP_SECTOR_VALUE } from './constants'
import style from './index.module.css'
import { getValueGrowthYoY } from './store/slice'

const { NOMINAL } = HEADER_VALUE
const { VALUE } = GDP_SECTOR_VALUE

const OtherCellColumn = ({ val, props }) => {
  const nominalRealValue = useSelector(getNominalRealValue)
  const valueGrowthYoY = useSelector(getValueGrowthYoY)
  const isPercent = nominalRealValue !== NOMINAL && valueGrowthYoY !== VALUE

  return (
    <td {...props}>
      <Span className={`${val < 0 && style.redColor}`}>
        {isPercent ? formatVal(val * 100) : formatVal(valDivTrillion(val))}
        {isPercent && typeof val === 'number' && `%`}
      </Span>
    </td>
  )
}

OtherCellColumn.propTypes = {
  val: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  props: PropTypes.object,
}

export default OtherCellColumn
