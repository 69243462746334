import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../../configs/Global'
import { Realtime } from '../../../../common/Realtime'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import style from '../index.module.css'
import { RenderTable } from './RenderTable'
import { UpdateReferencePrice } from './UpdateReferencePrice'
import {
  resetState,
  selectData,
  selectLoading,
  selectPageSize,
  selectScrollId,
  subscribeMatchingOrder,
} from './store/slice'
import { getDeepTransactionMatchingOrders } from './store/thunk'

export const MatchingOrder = () => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const isLoading = useSelector(selectLoading)
  const pageSize = useSelector(selectPageSize)
  const scrollId = useSelector(selectScrollId)

  // Use state
  const [rowTimes, setRowTimes] = useState(1)
  const [organizationId, setOrganizationId] = useState(0)

  // Use effect
  useEffect(() => {
    if (organizationId) {
      dispatch(
        getDeepTransactionMatchingOrders({
          OrganizationId: organizationId,
          PageSize: pageSize,
          ScrollId: scrollId,
        }),
      )
    }
  }, [organizationId, rowTimes])

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(resetState())
      setOrganizationId(basicInfo.organizationId)
    }
  }, [basicInfo.organizationId])

  // Render
  const renderNote = useMemo(() => {
    return (
      <div className={style.priceRate}>
        <Span style={{ fontSize: 11 }}>
          <Translate
            value={
              'market.equityTrading.deepTransaction.matchingOrder.NOTE_TEXT'
            }
          />
        </Span>
      </div>
    )
  }, [])

  return (
    <SizeTracker>
      {(size) => (
        <>
          {renderNote}
          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              {isLoading ? (
                <Loading />
              ) : data.length > 0 ? (
                <>
                  <RenderTable rowTimes={rowTimes} setRowTimes={setRowTimes} />
                  <UpdateReferencePrice />
                </>
              ) : (
                <NoData />
              )}
              <Realtime
                channel={ChannelConfig.StockChannel + basicInfo.organizationId}
                action={subscribeMatchingOrder}
              >
                <></>
              </Realtime>
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
