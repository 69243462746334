import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import Dropdown from '../../../../../common/dropdown'
import { Z_INDEX } from '../../../../../constants/Common'
import style from '../style.module.css'
import DropdownInput from './DropdownInput'
import DropdownTitle from './DropdownTitle'
import ItemCompare from './ItemCompare'
import RadioSearchAllTicker from './RadioSearchAllTicker'
import {
  searchData,
  selectKeySearchTicker,
  selectListCompareChecked,
  selectListTicker,
} from './store/slice'

const DropdownTicker = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()
  const ref = useRef()

  const listTicker = useSelector(selectListTicker)
  const keySearch = useSelector(selectKeySearchTicker)
  const listCompareChecked = useSelector(selectListCompareChecked)

  const [isShow, setIsShow] = useState(false)

  const toggleDropSearch = () => {
    if (isShow) {
      dispatch(searchData(''))
    }
    setIsShow(!isShow)
  }
  const hideDropSearch = () => {
    setIsShow(false)
    dispatch(searchData(''))
  }

  const handleSearchTicker = () => {
    let result = listTicker
      .filter((item) => item.tickerOrTaxCode?.toLowerCase().includes(keySearch))
      .sort((a, b) => a.tickerOrTaxCode.localeCompare(b.tickerOrTaxCode))

    if (listCompareChecked.length) {
      listCompareChecked.forEach((element) => {
        result.sort((a, b) => {
          return a.tickerOrTaxCode === element.tickerOrTaxCode
            ? -1
            : b.tickerOrTaxCode === element.tickerOrTaxCode
            ? 1
            : 0
        })
      })
    }
    return result
  }

  return (
    <div className="input-dropdown mr-8 h-20" ref={parentDropdownRef}>
      <span
        onClick={toggleDropSearch}
        ref={dropdownRef}
        className={`${style.spanInputSearch} span-input ${style.spanInput}`}
        style={{ width: 90 }}
      >
        <DropdownTitle />
        <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown
        parentRef={parentDropdownRef}
        isShow={isShow}
        zIndex={Z_INDEX.DROPDOWN}
      >
        <HandleClickOutside
          handleClickOutside={hideDropSearch}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: -2 }}>
            <div
              className={style.dropdownContainerSearchTicker}
              style={{
                width: `${parentDropdownRef?.current?.offsetWidth}px`,
                display: isShow ? 'block' : 'none',
              }}
            >
              <DropdownInput ref={ref} setShowDropdown={setIsShow} />
              <ScrollComponent autoHeight={true} autoHeightMax={200}>
                <ul className="list-check">
                  <RadioSearchAllTicker />
                  {handleSearchTicker()?.length > 0
                    ? handleSearchTicker().map((item, index) => {
                        return <ItemCompare item={item} key={index} />
                      })
                    : null}
                </ul>
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default DropdownTicker
