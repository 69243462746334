import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { groupHOHAUP } from '../../../../configs/Global'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Panel } from '../../../common/panel'
import { PreOpenMarket } from '../../../common/preOpenMarket/PreOpenMarket'
import { SizeTracker } from '../../../common/sizeTracker'
import DispatchActionTab, {
  TIME_RANGES,
} from '../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../common/textEllipsis'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { getIcbId } from '../helper'
import style from '../index.module.css'
import {
  keys,
  selectActiveRow,
  selectActiveSector,
  selectLoading,
  selectTimeRange,
  selectTotalValueBySector,
} from '../store/slice'
import { getTotalValueBySectorThunk } from '../store/thunk'
import { timeRangeApi } from '../tableSector'
import { resetDefaultActiveRow } from '../tableSector/ContentTableSector'
import ChartPercentValue from './ChartPercentValue'
import ChartValue from './ChartValue'
import ChartValueRelative from './ChartValueRelative'

const listTab = [
  { title: 'market.sectorInDepth.TAB_VALUE', value: 1 },
  { title: 'market.sectorInDepth.TAB_PERCENT_VALUE', value: 2 },
]

const idPanel = 'chartValueBySector'

export const ChartValueBySector = ({
  widthChart,
  heightChart,
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const dispatch = useDispatch()

  const activeRow = useSelector(selectActiveRow)
  const indexInfo = useSelector(selectIndexInfo)
  const timeRange = useSelector(selectTimeRange)
  const activeSector = useSelector(selectActiveSector)
  const totalValueBySector = useSelector(selectTotalValueBySector)
  const loadingChart = useSelector(selectLoading(keys.TOTAL_VALUE_BY_SECTOR))

  const [isRelative, setIsRelative] = useState(false)
  const [tabActive, setTabActive] = useState(listTab[0].value)

  useEffect(() => {
    if (activeRow && activeRow !== resetDefaultActiveRow && indexInfo.groupId) {
      const handleTimeRange =
        timeRange === TIME_RANGES['1D'] ? TIME_RANGES['1W'] : timeRange
      dispatch(
        getTotalValueBySectorThunk({
          ICBId: getIcbId(activeRow),
          GroupId: indexInfo.groupId,
          Period: timeRangeApi[handleTimeRange],
        }),
      )
    }
  }, [activeRow, timeRange, indexInfo.groupId])

  useEffect(() => {
    setIsRelative(false)
  }, [tabActive])

  const handleChangeTab = (item) => setTabActive(item.value)

  const handleChangeCheckbox = (e) => setIsRelative(e.target.checked)

  const isValue = tabActive === listTab[0].value
  const isPercentValue = tabActive === listTab[1].value
  const isHOHAUP = +indexInfo.groupId === +groupHOHAUP
  const displayName = activeSector?.toUpperCase()

  return (
    <Panel
      title="market.sectorInDepth.TITLE_CHART"
      titleJpg={displayName}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      downloadJpgParams={{
        domId: idPanel,
        nameScreen: I18n.t('market.sectorInDepth.NAME_SCREEN'),
        chartName: I18n.t('market.sectorInDepth.TAB_NAME'),
        tabName:
          isValue && isRelative
            ? I18n.t('market.sectorInDepth.CHART_VALUE_RELATIVE_WITH_INDEX')
            : isValue
            ? I18n.t('market.sectorInDepth.CHART_VALUE')
            : I18n.t('market.sectorInDepth.CHART_PERCENT_VALUE'),
        tickerName: activeSector,
      }}
    >
      <PreOpenMarket disabled={timeRange !== TIME_RANGES['1D']}>
        <div className="h-100">
          <SizeTracker>
            {(size) => (
              <>
                <div className="d-flex ali-center">
                  <DispatchActionTab
                    data={listTab}
                    activeTab={tabActive}
                    onChangeTab={handleChangeTab}
                    itemStyle={{ width: '75px', fontWeight: 600, fontSize: 11 }}
                  />
                  <label
                    className={`d-flex ali-center ${style.checkboxContainer} ${
                      isPercentValue || isHOHAUP ? style.disabled : ''
                    }`}
                  >
                    <input
                      type="checkbox"
                      className="checkbox line"
                      checked={isRelative}
                      onChange={handleChangeCheckbox}
                      disabled={isPercentValue || isHOHAUP}
                    />
                    <Span
                      style={{ fontSize: 11 }}
                      className={style.textCheckbox}
                    >
                      <Translate value="market.sectorInDepth.RELATIVE_INDEX" />
                    </Span>
                  </label>
                </div>
                <div className={style.titleChart}>
                  {displayName ? (
                    <TextEllipsis isI18n={false} text={displayName} />
                  ) : (
                    <span className={style.opacity0}>Blank</span>
                  )}
                </div>
                {size.height && (
                  <div
                    id={idPanel}
                    style={{ height: `calc(100% - ${size.height}px)` }}
                  >
                    {loadingChart ? (
                      <Loading />
                    ) : !totalValueBySector.length ? (
                      <NoData />
                    ) : (
                      <>
                        {isValue && isRelative ? (
                          <ChartValueRelative
                            widthChart={widthChart}
                            heightChart={heightChart - size.height}
                          />
                        ) : isValue ? (
                          <ChartValue
                            widthChart={widthChart}
                            heightChart={heightChart - size.height}
                          />
                        ) : (
                          <ChartPercentValue
                            widthChart={widthChart}
                            heightChart={heightChart - size.height}
                          />
                        )}
                      </>
                    )}
                  </div>
                )}
              </>
            )}
          </SizeTracker>
        </div>
      </PreOpenMarket>
    </Panel>
  )
}

ChartValueBySector.propTypes = {
  widthChart: PropTypes.number.isRequired,
  heightChart: PropTypes.number.isRequired,
}

export default ChartValueBySector
