import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ImportProportion } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  selectDataTable,
  selectMaxYear,
} from '../importStatisticTable/store/slice'
import {
  selectActiveItemProportionChart,
  selectCurrentYear,
} from './store/slice'

export const PanelImportProportion = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const activeItem = useSelector(selectActiveItemProportionChart)
  const currentYear = useSelector(selectCurrentYear)
  const dataTable = useSelector(selectDataTable)
  const maxYear = useSelector(selectMaxYear)

  const currentSelectedYear = currentYear ?? maxYear
  const activeItemName = activeItem ? activeItem.name : dataTable[0]?.name
  const nameScreen = I18n.t('sector.sectorSpecific.fishery.FISHERY')
  const titleName = 'sector.sectorSpecific.fishery.export.CONTRIBUTION_MARKET'
  const chartName = `${I18n.t(
    'sector.sectorSpecific.fishery.export.CONTRIBUTION_BY',
  )} ${activeItemName} ${currentSelectedYear}`
  const tabName = I18n.t('sector.sectorSpecific.fishery.EXPORT_BY_MARKET')

  return (
    <Panel
      title={titleName}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={I18n.t(chartName)}
      downloadJpgParams={{
        domId: 'importProportionChart',
        nameScreen: nameScreen,
        chartName: I18n.t(chartName),
        tabName: tabName,
      }}
    >
      <ImportProportion
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
