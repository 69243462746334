export const barKeys = ['matchVolume']

export const lineKeys = ['closePrice']

export const keyXAxis = 'tradingDate'

export const fillColor = {
  matchVolume: '#376691',
  closePrice: '#007cff',
}

export const pricePerformanceFormatValue = {
  openPrice: 10 ** 3,
  closePrice: 10 ** 3,
  referencePrice: 10 ** 3,
  matchVolume: 10 ** 3,
}

export const listTab = [
  { title: '1D', value: 'OneDay' },
  { title: '1W', value: 'OneWeek' },
  { title: '1M', value: 'OneMonth' },
  { title: '6M', value: 'SixMonths' },
  { title: '1Y', value: 'OneYear' },
]
