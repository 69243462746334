import { getTextWidth } from '../../helper'

export const getTickValuesDisplay = (
  width,
  maxTextWidth,
  widthTickBlock,
  tickValues,
) => {
  if (maxTextWidth < widthTickBlock) {
    return tickValues
  } else {
    const maxTickNum = Math.ceil(tickValues.length / 2)
    let tickNum = maxTickNum

    for (let i = maxTickNum; i > 1; i--) {
      if (maxTextWidth * i <= width) {
        tickNum = i
        break
      }
    }

    const division = (tickValues.length - 1) / (tickNum - 1)
    const defaultStep = Math.floor(division)
    let dataItemLeft = Math.round((division - defaultStep) * (tickNum - 1))
    let prevIndex = 0
    const result = []

    ;[...new Array(tickNum)].forEach((_, index) => {
      if (index === 0) {
        result.push(tickValues[0])
      } else if (index + 1 === tickNum) {
        result.push(tickValues[tickValues.length - 1])
      } else {
        let curIndex = prevIndex + defaultStep
        if (dataItemLeft > 0) {
          curIndex++
          dataItemLeft--
        }
        prevIndex = curIndex
        result.push(tickValues[curIndex])
      }
    })

    return result
  }
}

export const getPositionLeft = (text, widthTickBlock, fontSize) => {
  const textWidth = getTextWidth(text, fontSize)
  return (widthTickBlock - textWidth * 1.2) / 2
}
