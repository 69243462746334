import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getManagementAndDirectors } from './thunk'

const initialState = {
  directorById: {},
  ids: [],
  directorsIds: [],
  isLoading: false,
  initialIds: [],
}

export const slice = createSlice({
  name: 'corporate/ownership/director',
  initialState,
  reducers: {
    setDirectorById: (state, action) => {
      state.directorById = action.payload
    },
    setIds: (state, action) => {
      state.ids = action.payload.map((item) => item.index)
    },
    changeIds: (state, action) => {
      state.ids = action.payload
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.directorById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getManagementAndDirectors.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getManagementAndDirectors.fulfilled, (state, action) => {
      state.isLoading = false
      state.showTable = true

      state.initialIds = state.ids =
        action.payload?.map((item, index) => index) || []
      const formatRes =
        action.payload?.map((item, index) => {
          return { ...item, index }
        }) || []

      state.directorById = keyBy(formatRes, 'index')
    })
    builder.addCase(getManagementAndDirectors.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectDirectorIds = (state) => state[slice.name].ids
export const selectDirectorValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].directorById[id], attr)
export const selectIsLoading = (state) => state[slice.name].isLoading
export const selectHolderById = (state) => state[slice.name].directorById

export const { setDirectorById, setIds, changeIds, sort } = slice.actions

register(slice.name, slice.reducer)
