import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import PanelPriceDepth from './priceDepth/PanelPriceDepth'
import PanelSummary from './summary/PanelSummary'
import PanelTechnicalChart from './technicalChart/PanelTechnicalChart'
import PanelTimeAndSales from './timeAndSales/PanelTimeAndSales'

const MAP_KEY = {
  SUMMARY: 'SUMMARY',
  TECHNICAL_CHART: 'TECHNICAL_CHART',
  PRICE_DEPTH: 'PRICE_DEPTH',
  TIME_AND_SALES: 'TIME_AND_SALES',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.SUMMARY]: {
    minWidth: 713,
    minHeight: 243,
  },
  [MAP_KEY.PRICE_DEPTH]: {
    minWidth: 383,
    minHeight: 348,
  },
  [MAP_KEY.TECHNICAL_CHART]: {
    minWidth: 713,
    minHeight: 453,
  },
  [MAP_KEY.TIME_AND_SALES]: {
    minWidth: 383,
    minHeight: 348,
  },
}

const MAP_SIZE = {
  [MAP_KEY.SUMMARY]: {
    width: `calc(65% - ${COMPONENT.MARGIN}px)`,
    height: `calc(35% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.SUMMARY],
    verticalResize: [
      {
        key: MAP_KEY.PRICE_DEPTH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.TIME_AND_SALES,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.TECHNICAL_CHART,
        ratioWidth: -1,
        ratioLeft: 0,
      },
    ],
    horizontalResize: [
      {
        key: MAP_KEY.TECHNICAL_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [MAP_KEY.PRICE_DEPTH]: {
    width: `calc(35% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65% + ${COMPONENT.MARGIN}px)`,
    minTop: 0,
    minLeft: MIN_SIZE_SETTING[MAP_KEY.SUMMARY].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.PRICE_DEPTH],
    horizontalResize: [
      {
        key: MAP_KEY.TIME_AND_SALES,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [MAP_KEY.TECHNICAL_CHART]: {
    width: `calc(65% - ${COMPONENT.MARGIN}px)`,
    height: `calc(65% - ${COMPONENT.MARGIN}px)`,
    top: `calc(35% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minTop: MIN_SIZE_SETTING[MAP_KEY.SUMMARY].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.TECHNICAL_CHART],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.TIME_AND_SALES]: {
    width: `calc(35% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65% + ${COMPONENT.MARGIN}px)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.PRICE_DEPTH].minHeight + 2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TECHNICAL_CHART].minWidth + 2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.TIME_AND_SALES],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <div className="w-100 h-100 position-relative">
      <PanelSummary
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SUMMARY}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelPriceDepth
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PRICE_DEPTH}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelTechnicalChart
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TECHNICAL_CHART}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelTimeAndSales
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TIME_AND_SALES}
        sizes={sizes}
        setSizes={setSizes}
      />
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
