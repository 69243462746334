export const DEFAULT_CHECKED = [
  {
    id: '1-5-1',
    typeId: 5,
  },
  {
    id: '1-5-2',
    typeId: 5,
  },
  {
    id: '2-2-1',
    typeId: 2,
  },
  {
    id: '2-2-2',
    typeId: 2,
  },
  {
    id: '5-105-1',
    typeId: 105,
  },
]
