import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../html/Span'

export const Toggle = ({ isOn, handleClick, label }) => {
  return (
    <div className="align-center d-flex" style={{ gap: 8 }}>
      <img
        className="cursor-pointer"
        src={isOn ? '/iconToggleOn.svg' : '/iconToggleOff.svg'}
        alt="Toggle Icon"
        onClick={handleClick}
      />
      {label && (
        <Span>
          <Translate value={label} />
        </Span>
      )}
    </div>
  )
}

Toggle.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleClick: PropTypes.func,
  label: PropTypes.string,
}

Toggle.defaultProps = {
  label: undefined,
}
