import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import { EXCHANGES } from '../constants'
import { changeExchange, selectExchange } from '../store/slice'
import style from './index.module.css'
import { Title } from './Title'

export const Exchange = () => {
  const dispatch = useDispatch()
  const exchange = useSelector(selectExchange)
  return (
    <div className={style.item}>
      <Title value="market.heatmap.EXCHANGE" />
      <SelectCustom
        isI18n={true}
        value={exchange}
        selectData={Object.keys(EXCHANGES).map((key) => {
          return {
            name: `market.heatmap.exchanges.${key}`,
            value: EXCHANGES[key],
          }
        })}
        handleChange={(value) => {
          dispatch(changeExchange(value))
        }}
      />
    </div>
  )
}
