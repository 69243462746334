import { CorporateExportFishery } from './corporateExport'
import { ImportByMarket } from './importByMarket'
import { ImportByProduct } from './importByProduct'
import { OverviewFishery } from './overviewFishery'

export const NAVIGATION_FISHERY = [
  {
    title: 'sector.sectorSpecific.fishery.OVERVIEW',
    component: <OverviewFishery />,
    value: 'overview',
  },
  {
    title: 'sector.sectorSpecific.fishery.EXPORT_BY_PRODUCT',
    component: <ImportByProduct />,
    value: 'productImport',
  },
  {
    title: 'sector.sectorSpecific.fishery.EXPORT_BY_MARKET',
    component: <ImportByMarket />,
    value: 'marketImport',
  },
  {
    title: 'sector.sectorSpecific.fishery.EXPORTERS',
    component: <CorporateExportFishery />,
    value: 'corporateExport',
  },
]
