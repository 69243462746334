import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import OverviewService from '../../../../../../core/services/economy/fdi/OverviewService'

export const getFDIStructure = createAsyncThunk(
  'economy/fdi/overview/GET_FDI_STRUCTURE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await OverviewService.getFDIStructureRequest(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
