export const common = {
  MORE_SETTING: 'More Setting',
  label: {
    SETTING: 'Setting',
    GENERAL: 'General',
    PROFILE: 'Profile',
  },
  validate: {
    VALIDATION_EMAIL_FORMAT: 'Please enter valid email',
    VALIDATION_EMAIL_REQUIRED: 'The fields Email are required.',
    VALIDATION_PASSWORD_INCORRECT: 'Password is incorrect',
    VALIDATE_RECENT_PASSWORD_REQUIRED:
      'The fields Recent password are required.',
    VALIDATE_NEW_PASSWORD_REQUIRED: 'The fields New password are required.',
    VALIDATE_CONFIRM_NEW_PASSWORD_REQUIRED:
      'The fields Confirm new password are required.',
    VALIDATE_PASSWORD_DOES_NOT_MATCH: 'Password does not match',
    VALIDATION_PHONE_REQUIRED: 'The fields Phone are required.',
    VALIDATION_NAME_REQUIRED: 'The fields Name are required.',
    VALIDATION_CHARACTER_MINIUM_PASSWORD: '8 - character minimum',
    VALIDATION_NO_SPECIAL_CHARACTERS: 'No special characters',
    VALIDATION_AT_LEAST_1_NUMBER: 'At least 1 number',
  },
}
