import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TotalGDPService from '../../../../../../core/services/economy/gdp/TotalGDPService'
import { handleExport } from '../../../../../utils/Export'

export const getGDPBySector = createAsyncThunk(
  'economy/gdp/totalGDP/panelTotalGDP/getGDPBySector',
  async (params, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getGDPBySector(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGDPTypeName = createAsyncThunk(
  'economy/gdp/totalGDP/panelTotalGDP/getGDPTypeName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await TotalGDPService.getGDPTypeName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadGDPBySector = createAsyncThunk(
  'economy/gdp/totalGDP/panelTotalGDP/downloadGDPBySector',
  async (data) => {
    const response = await TotalGDPService.downloadGDPBySector(data)
    handleExport(response.data, response.filename)
  },
)
