import { SizeTracker } from '../../../common/sizeTracker'
import FilterResult from './FilterResult'
import GroupButton from './GroupButton'
import TableResult from './TableResult'

const Result = ({ setIsLoadingDownloadCsv }) => {
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div className="box-head d-flex j-b mb-20">
              <FilterResult />
              <GroupButton setIsLoadingDownloadCsv={setIsLoadingDownloadCsv} />
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <TableResult />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Result
