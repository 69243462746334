import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../../utils/Value'
import { KEY_WITH_TITLE, LIST_FOOTER } from './constants'
import {
  AVERAGE_ID,
  getTooltipContentData,
  GROWTH_ID,
  handleXAxisTitle,
  PURCHASE_ID,
} from './helper'
import { selectLoading } from './store/slice'
import style from './style.module.css'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKey,
  barKey,
  locale,
  averageKey,
  keyWithTitle,
}) => {
  const isLoading = useSelector(selectLoading)
  const dataFormat = handleXAxisTitle(data, locale)

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, KEY_WITH_TITLE).map((item, index) => {
          return (
            <div
              key={item.key}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <Span
                style={{ fontSize: 11 }}
                className={style.tooltipContentKey}
              >
                <Translate value={item.label} />:
              </Span>
              <Span
                style={{ marginLeft: 25, fontSize: 11 }}
                className={`${style.toolTipContentValue} ${
                  typeof item.value === 'number' &&
                  item.value < 0 &&
                  style.redColor
                }`}
              >
                {formatVal(item.value)}
                {typeof item.value === 'number' &&
                  (item.key === AVERAGE_ID || item.key === PURCHASE_ID) && (
                    <Translate
                      value={
                        'economy.productionAndConsumption.production.POINT_TOOLTIP'
                      }
                    />
                  )}
                {typeof item.value === 'number' &&
                  item.key === GROWTH_ID &&
                  '%'}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  if (isLoading) {
    return <Loading />
  }

  if (data.length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            {typeof sizes.height === 'number' && (
              <ChartContainer
                data={dataFormat}
                width={width}
                height={height - sizes.height}
                keyXAxis={keyXAxis}
                hasBarChart
                margin={{ ...MARGIN_RECHARTS, right: 14 }}
                yAxis={[
                  {
                    id: 'left',
                    keys: [barKey, averageKey],
                    orientation: 'left',
                    tickNum: SETTINGS.yTickNum,
                    label: I18n.t(
                      'economy.productionAndConsumption.production.POINT',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                  },
                  {
                    id: 'right',
                    keys: [lineKey],
                    orientation: 'right',
                    isLineChart: true,
                    tickNum: SETTINGS.yTickNum,
                    labelPosition: AXIS_LABEL_POSITION.RIGHT,
                    unitYAxis: '%',
                  },
                ]}
                renderCustomTooltip={renderTooltip}
                timeFrame={TIME_RANGES.CUSTOM}
                isNotFormatXAxis
              >
                <Bar
                  isAnimationActive={false}
                  yAxisId="left"
                  dataKey={barKey}
                  barSize={getColumnSizeInBarChart(width, dataFormat.length)}
                  fill="#0096eb"
                />
                <Line
                  yAxisId="right"
                  dataKey={lineKey}
                  stroke="#f7959c"
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                  strokeWidth={2}
                />
                <Line
                  yAxisId="left"
                  dataKey={averageKey}
                  type="linear"
                  stroke="#f7b80c"
                  dot={false}
                  activeDot={false}
                  isAnimationActive={false}
                  strokeDasharray="5 3"
                  strokeWidth={2}
                />
              </ChartContainer>
            )}
            <Footer key={width} list={LIST_FOOTER} />
          </>
        )
      }}
    </SizeTracker>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
  lineKey: PropTypes.number.isRequired,
  barKey: PropTypes.number.isRequired,
  locale: PropTypes.string.isRequired,
  averageKey: PropTypes.number.isRequired,
  keyWithTitle: PropTypes.object.isRequired,
}
