import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { uuid } from '../../../utils/Common'
import { CHART_PARAMETERS_DEFAULT } from '../../chart/constants'
import {
  changeChartTabActive,
  changeChartTabsData,
  selectChartTabsData,
} from '../../chart/store/slice'
import { screenTypes } from '../../constants'
import {
  addChart,
  changeDashboard,
  selectDashboard,
  selectDashboardChartData,
} from '../../store/slice'
import { getChartNameBySampleName, getChartOrderByListChart } from '../helpers'
import { useGetUserSettings } from '../useGetUserSettings'
import style from './AddNewChart.module.css'

export const AddNewChart = () => {
  const dispatch = useDispatch()

  const { defaultChartSettings } = useGetUserSettings()

  const { data = {} } = useSelector(selectDashboard)
  const dashboardChartData = useSelector(selectDashboardChartData)
  const chartTabs = useSelector(selectChartTabsData)

  const redirectNewChart = () => {
    if (data && data.id) {
      const newChartTabActive = Object.keys(chartTabs).length + 1
      const nameChart = getChartNameBySampleName(
        Object.values(chartTabs).map((i) => i.nameChart?.name),
        'Chart ',
      )
      const chartValue = {
        ...CHART_PARAMETERS_DEFAULT,
        nameChart: {
          ...CHART_PARAMETERS_DEFAULT.nameChart,
          name: nameChart,
        },
        settings:
          defaultChartSettings?.settings || CHART_PARAMETERS_DEFAULT.settings,
        styleSetting:
          defaultChartSettings?.styleSetting ||
          CHART_PARAMETERS_DEFAULT.styleSetting,
        order: getChartOrderByListChart(dashboardChartData),
      }

      dispatch(changeDashboard({ screenType: screenTypes.EDIT_CHART }))
      dispatch(
        changeChartTabsData({
          [newChartTabActive]: chartValue,
        }),
      )
      dispatch(changeChartTabActive(newChartTabActive))
      dispatch(
        addChart({
          id: uuid(),
          dashboardId: data.id,
          name: nameChart,
          detail: JSON.stringify(chartValue),
          order: getChartOrderByListChart(dashboardChartData),
        }),
      )
    }
  }

  return (
    <div className={style.container} data-html2canvas-ignore>
      <div className={style.content} onClick={redirectNewChart}>
        <i className={`icon-plus ${style.icon}`} style={{ fontSize: 18 }} />
        <Span className={style.label} style={{ fontSize: 18 }}>
          <Translate value="financialChart.CHART" />
        </Span>
      </div>
    </div>
  )
}
