export const NUM_OF_PERIOD_FIRST_FETCH = 20
export const NUM_OF_PERIOD = 5
export const NUM_OF_PERIOD_DOWNLOAD_DEFAULT = 10

export const TYPE_STATISTIC = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
}

export const TYPE_STATISTICS_FILTER = [
  {
    title: 'sector.sectorSpecific.steel.FILTER_MONTHLY',
    value: TYPE_STATISTIC.MONTHLY,
  },
  {
    title: 'sector.sectorSpecific.steel.FILTER_YEARLY',
    value: TYPE_STATISTIC.YEARLY,
  },
]

export const TYPE_DATA = {
  PRICE: 'Price',
  GROWTH: 'Growth',
}

export const LIST_RADIO = [
  {
    value: TYPE_DATA.PRICE,
    label: 'sector.sectorSpecific.price.FILTER_VALUE',
  },
  {
    value: TYPE_DATA.GROWTH,
    label: 'sector.sectorSpecific.price.FILTER_PERCENT_GROWTH',
  },
]

export const DEFAULT_MAX_MONTH = 6
export const DEFAULT_MAX_YEAR = 2022

export const LIMIT_SELECTION = 20

export const getDefaultCheckedItems = (data) => {
  return data?.filter((item) => item.isGroupRow).slice(0, 5)
}
