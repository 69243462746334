import { LOAN_SECTOR } from '../../constant'
import { MS_KEY, YOY_GROWTH_KEY } from './constants'

export const formatDataDetailMetrics = (payload, indicator) => {
  const data = {}
  const rowIds = [indicator, MS_KEY + indicator, YOY_GROWTH_KEY + indicator]

  payload.forEach((item) => {
    if (!rowIds.includes(item.metricCode)) rowIds.push(item.metricCode)

    if (!data[item.metricCode]) {
      data[item.metricCode] = {}
    }
    data[item.metricCode][item.ticker] = item.value
  })

  return { data, rowIds }
}

export const getLoanSectorKeyByValue = (value) =>
  Object.keys(LOAN_SECTOR).find(
    (key) => LOAN_SECTOR[key].toUpperCase() === value.toUpperCase(),
  )
