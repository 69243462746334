import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { StackedBarLineChart } from '../../../../common/chart/customCharts/StackedBarLineChart'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { getFontSize } from '../../../../utils/FontSize'
import { VALUE_TYPE } from '../constants'
import {
  checkHnxGroup,
  formatTooltipTitle,
  getCompareChartMargin,
  getValueInvestorNetValueChartData,
  getValueInvestorPercentValueChartData,
} from '../helps'
import {
  selectData,
  selectDateTime,
  selectValueType,
} from '../valueInvestor/store/slice'
import {
  barKeys,
  fillColor,
  keyXAxis,
  lineKeys,
  mappingDisplayName,
} from './constants'

export const ChartContent = ({ data, width, height, dateType }) => {
  const calcFontSize = getFontSize(12)

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const timezone = UseTimeZone()
  const basicInfo = useSelector(selectBasicInfo)
  const valueInvestorData = useSelector(selectData)
  const valueInvestorType = useSelector(selectValueType)
  const valueInvestorDateType = useSelector(selectDateTime)

  // Check data
  const isHnxGroup = useMemo(() => {
    return checkHnxGroup(basicInfo.exchangeCode)
  }, [basicInfo.exchangeCode])

  // Get data
  const getValueInvestorBarKeys = () => {
    if (!isHnxGroup) {
      return ['localInstitution', 'proprietary', 'foreign', 'localIndividual']
    }
    return ['foreign']
  }

  const getValueInvestorLineKeys = () => {
    if (!isHnxGroup) {
      return ['closeIndex']
    }
    return []
  }

  const getValueInvestorData = useMemo(() => {
    if (valueInvestorType === VALUE_TYPE.NET_VALUE) {
      return getValueInvestorNetValueChartData(
        valueInvestorData,
        valueInvestorType,
        valueInvestorDateType,
        isHnxGroup,
      )
    } else {
      return getValueInvestorPercentValueChartData(
        valueInvestorData,
        valueInvestorType,
        valueInvestorDateType,
        isHnxGroup,
      )
    }
  }, [valueInvestorData, valueInvestorType, valueInvestorDateType, isHnxGroup])

  const chartMargin = getCompareChartMargin(
    data,
    getValueInvestorData,
    barKeys,
    getValueInvestorBarKeys(),
    lineKeys,
    getValueInvestorLineKeys(),
    calcFontSize,
  )

  const [listCheckbox, setListCheckbox] = useState([...barKeys, ...lineKeys])

  return (
    <StackedBarLineChart
      data={data}
      width={width}
      height={height}
      barKeys={barKeys}
      lineKeys={lineKeys}
      keyXAxis={keyXAxis}
      decimalLength={2}
      margin={chartMargin}
      timeFrame={
        dateType === TIME_RANGES['1W']
          ? TIME_RANGES['1W_DATE']
          : dateType === TIME_RANGES['3M']
          ? TIME_RANGES['1M']
          : dateType
      }
      fillColor={fillColor}
      mappingDisplayName={mappingDisplayName}
      unit="market.equityTrading.priceStatistics.netValue.BILLION"
      lineUnit="market.equityTrading.priceStatistics.netValue.BILLION"
      leftLabel="market.equityTrading.priceStatistics.netValue.VALUE_UNIT"
      rightLabel="market.equityTrading.priceStatistics.netValue.VALUE_UNIT"
      valueKeysColor={[...barKeys, ...lineKeys]}
      formatTooltipTitle={(item) =>
        formatTooltipTitle(
          item,
          dateType,
          ['date', 'minDate'],
          keyXAxis,
          locale,
          timezone,
        )
      }
      listCheckbox={listCheckbox}
      setListCheckbox={setListCheckbox}
    />
  )
}

ChartContent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dateType: PropTypes.string,
}
