import { ChartContainer } from '../../../common/ChartContainer'
import { LineChart } from '../../../common/LineChart'
import {
  chartFillColor,
  chartLeftKeys,
  chartMappingDisplayName,
} from './constants'

export const ChartComponent = ({ data, width, height, loading }) => {
  return (
    <div style={{ height: height || 0 }} className="position-relative">
      <ChartContainer
        id="nplCoverageChartContainer"
        data={data || []}
        height={height}
        width={width}
        isLoading={loading}
      >
        {(resizeSize) => (
          <LineChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            leftKeys={chartLeftKeys}
            leftUnit="sector.financialAnalysis.common.PERCENT"
            xAxisKey="label"
            fillColor={chartFillColor}
            mappingDisplayName={chartMappingDisplayName}
            margin={{ right: 50 }}
            isYAxisPercentLeft={true}
          />
        )}
      </ChartContainer>
    </div>
  )
}
