import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MarginLoansService from '../../../../../core/services/market/MarginLoansService'

export const getAverageTradingValue = createAsyncThunk(
  'market/marginLoans/getAvgTradingValue',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarginLoansService.getAverageTradingValue(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getMaxDate = createAsyncThunk(
  'market/marginLoans/getMaxDate',
  async (params, { rejectWithValue }) => {
    const response = await MarginLoansService.getMaxDate(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
