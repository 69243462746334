import { memo } from 'react'
import { useSelector } from 'react-redux'
import CompanyNews from './companyNews'
import SettingArea from './settingArea'
import style from './settingArea/index.module.css'
import { getLoading } from './settingArea/store/slice'

const Setting = () => {
  const loading = useSelector(getLoading)

  return (
    <div className={'h-100 relative'}>
      {loading && (
        <div className={style.spinLoading}>
          <div className={style.loadingBox}>
            <div className={style.loader}></div>
          </div>
        </div>
      )}
      <div
        className={[style.wrapSettingArea, loading ? style.blurPanel : ''].join(
          ' ',
        )}
      >
        <CompanyNews />
        <SettingArea />
      </div>
    </div>
  )
}

export default memo(Setting)
