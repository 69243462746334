import { FundFlow } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelFundFlow = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const titlePanel = `fund.fundProfile.navData.${panelKey}`
  const nameScreen = UseI18n('constants.route.fund.fundProfile.navData.LABEL')
  const chartName = UseI18n(`fund.fundProfile.navData.fundFlow.${panelKey}`)
  const titleJpg = UseI18n(
    `fund.fundProfile.navData.fundFlow.CHART_NAME_${panelKey}`,
  )

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: panelKey,
        nameScreen,
        chartName,
      }}
    >
      <FundFlow
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
