import PropTypes from 'prop-types'
import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../common/HandleClickOutside'
import { Button } from '../../common/html/Button'
import PopupAlert from '../../common/popup/PopupAlert'
import PopupEvent from '../../common/popup/PopupEvent'
import TextEllipsis from '../../common/textEllipsis'
import { maxWatchlist, tableKeys, zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'
import { addEditKey, selectWatchlistsById, unchecked } from '../store/slice'
import { copyWatchlistThunk, deleteWatchlistThunk } from '../store/thunk'

const WatchlistButtonMore = ({ id }) => {
  const dispatch = useDispatch()

  const watchlistsById = useSelector(selectWatchlistsById)

  const buttonRef = useRef()

  const [isShowPopover, setIsShowPopover] = useState(false)
  const [isPopoverTop, setIsPopoverTop] = useState(false)
  const [isShowPopupCopy, setIsShowPopupCopy] = useState(false)
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const handleShowEditName = () => {
    dispatch(addEditKey({ id, editKey: tableKeys.NAME }))
    handleHidePopover()
  }

  const handleShowEditDescription = () => {
    dispatch(addEditKey({ id, editKey: tableKeys.DESCRIPTION }))
    handleHidePopover()
  }

  const handleCopyWatchlist = () => {
    if (Object.keys(watchlistsById).length + 1 > maxWatchlist)
      setIsShowPopupCopy(true)
    else dispatch(copyWatchlistThunk({ watchlistIds: [+id] }))
    handleHidePopover()
  }

  const handleShowPopupDelete = () => {
    setIsShowPopupDelete(true)
    handleHidePopover()
  }

  const handleDeleteWatchlist = () => {
    dispatch(deleteWatchlistThunk({ watchlistIds: [+id] }))
    dispatch(unchecked(id))
    handleHidePopover()
    closePopupDelete()
  }

  const handleHidePopover = useCallback(() => {
    setIsShowPopover(false)
    document
      .querySelector(`#table-watchlist .scrollbars > div:first-child`)
      .removeEventListener('scroll', handleHidePopover)
  }, [])

  const handleShowPopover = () => {
    const heightFromFooter =
      document.querySelector('#footer-table-watchlist').getBoundingClientRect()
        .top - buttonRef.current.getBoundingClientRect().top
    if (heightFromFooter <= 160) setIsPopoverTop(true)
    else setIsPopoverTop(false)

    setIsShowPopover(true)

    document
      .querySelector(`#table-watchlist .scrollbars > div:first-child`)
      .addEventListener('scroll', handleHidePopover)
  }

  const closePopupCopy = () => setIsShowPopupCopy(false)

  const closePopupDelete = () => setIsShowPopupDelete(false)

  return (
    <>
      {isShowPopover ? (
        <div className="hover-dropdown d-flex justify-content-center">
          <HandleClickOutside handleClickOutside={handleHidePopover}>
            <div style={{ position: 'relative' }}>
              <button
                ref={buttonRef}
                onClick={handleHidePopover}
                className={`btn-more ${style.pointer}`}
              >
                <i className="icon-menu-dot-ver" />
              </button>

              <div
                className={`dropdown-container ${style.dropdown} ${
                  isPopoverTop ? style.dropdownTop : ''
                }`}
              >
                <ul>
                  <li>
                    <Button
                      onClick={handleCopyWatchlist}
                      className="btn-watchlist"
                    >
                      <i className="icon-duplicate" />
                      <Translate
                        className={style.textEllipsis}
                        value="watchlist.watchlistTable.btnMore.BTN_DUPLICATE"
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleShowEditName}
                      className="btn-watchlist"
                    >
                      <i className="icon-edit" />
                      <Translate
                        className={style.textEllipsis}
                        value="watchlist.watchlistTable.btnMore.BTN_RENAME"
                      />
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleShowEditDescription}
                      className="btn-watchlist d-flex ali-center"
                    >
                      <i className="icon-edit" />
                      <div className={`${style.inlineBlock} ${style.w70}`}>
                        <TextEllipsis text="watchlist.watchlistTable.btnMore.BTN_EDIT" />
                      </div>
                    </Button>
                  </li>
                  <li>
                    <Button
                      onClick={handleShowPopupDelete}
                      className="btn-watchlist"
                    >
                      <i className="icon-trash" />
                      <Translate
                        className={style.textEllipsis}
                        value="watchlist.watchlistTable.btnMore.BTN_DELETE"
                      />
                    </Button>
                  </li>
                </ul>
              </div>
            </div>
          </HandleClickOutside>
        </div>
      ) : (
        <div className=" d-flex justify-content-center">
          <button
            ref={buttonRef}
            onClick={handleShowPopover}
            className={`btn-more ${style.pointer}`}
          >
            <i className="icon-menu-dot-ver" />
          </button>
        </div>
      )}
      <PopupAlert
        message={<Translate value="watchlist.watchlistTable.EXCEEDED_WL" />}
        isShow={isShowPopupCopy}
        handleClose={closePopupCopy}
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
      />
      <PopupEvent
        title={<Translate value="watchlist.watchlistTable.TITLE_DELETE_WL" />}
        message={
          <div>
            <Translate value="watchlist.watchlistTable.ASK_DELETE" />{' '}
            <span style={{ fontWeight: 'bold' }}>
              {watchlistsById[id].watchListName}
            </span>
            ?
          </div>
        }
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
        isShow={isShowPopupDelete}
        handleClose={closePopupDelete}
        btnCancel={{
          btnName: <Translate value="watchlist.watchlistTable.BTN_DELETE_2" />,
          event: handleDeleteWatchlist,
        }}
        btnSubmit={{
          btnName: <Translate value="watchlist.watchlistTable.BTN_CANCEL" />,
          event: closePopupDelete,
        }}
      />
    </>
  )
}

WatchlistButtonMore.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default WatchlistButtonMore
