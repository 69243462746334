import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Content from './Content'
import Filter from './Filter'
import PopupDetail from './PopUpDetail'
import { resetStore, selectBondDetailSelected } from './store/slice'

const TradingStatistics = () => {
  const dispatch = useDispatch()

  const item = useSelector(selectBondDetailSelected)

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <>
      <div
        style={{
          paddingBottom: '4px',
        }}
      >
        <Filter />
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 40px)`,
        }}
      >
        <Content />
      </div>

      {item && <PopupDetail />}
    </>
  )
}

export default TradingStatistics
