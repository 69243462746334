import { useSelector } from 'react-redux'
import { CpiAndInflationTable } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import {
  selectCountPeriodNum,
  selectMaxMonth,
  selectMaxYear,
  selectTypeCode,
} from './store/slice'
import { downloadCPIAndInflationTableContent } from './store/thunk'

export const PanelCpiAndInflationTable = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const typeCode = useSelector(selectTypeCode)
  const maxMonth = useSelector(selectMaxMonth)
  const maxYear = useSelector(selectMaxYear)
  const countPeriodNum = useSelector(selectCountPeriodNum)

  const downloadCsv = () => {
    const params = {
      CPIVNTypeCode: typeCode,
      NumOfPeriod: countPeriodNum,
    }

    if (maxMonth) {
      params.Month = maxMonth
    }

    if (maxYear) {
      params.Year = maxYear
    }

    return downloadCPIAndInflationTableContent(params)
  }

  return (
    <Panel
      title="economy.cpiAndInflation.titlePanel.CPI_INFLATION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <CpiAndInflationTable
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
