import { I18n, Translate } from 'react-redux-i18n'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../../../common/html/Span'
import { TextBlink } from '../../../../../common/table/TextBlink'
import TextEllipsis from '../../../../../common/textEllipsis'
import { TooltipInfo } from '../../../../../common/tooltipInfo'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  formatVal,
  valDivThousand,
  valToPercent,
} from '../../../../../utils/Value'
import { LinkCWDetail } from '../../common/LinkCWDetail'
import { LightBorderColumnCell } from './LightBorderColumnCell'
import {
  GROUP_COLUMNS,
  GROUP_ID_CONVERT,
  RECORD_NUM_PER_PAGE,
} from './constants'
import style from './index.module.css'

const BORDER_DARK = '1px solid #2b303a'
const BORDER_LIGHT = '1px solid #546373'

const childrenGroupColumns = [
  GROUP_COLUMNS[5],
  GROUP_COLUMNS[6],
  GROUP_COLUMNS[7],
  GROUP_COLUMNS[8],
  GROUP_COLUMNS[9],
  GROUP_COLUMNS[10],
  GROUP_COLUMNS[22],
  GROUP_COLUMNS[23],
  GROUP_COLUMNS[12],
  GROUP_COLUMNS[13],
  GROUP_COLUMNS[14],
  GROUP_COLUMNS[15],
  GROUP_COLUMNS[16],
  GROUP_COLUMNS[24],
]

const schemaGroupColumns = GROUP_COLUMNS.filter(
  (item) => !item.key.includes('Parent'),
).sort((a, b) => a.index - b.index)

export const getSchema = (filterData, locale, timeZone, dataTable) => {
  return schemaGroupColumns.map((item, index) => {
    const title = item.title
    const colId = item.key
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => {
          return RECORD_NUM_PER_PAGE * (filterData?.page - 1) + (val + 1)
        },
      }
    }
    if (index === 3 || index === 17 || index === 11) {
      result = {
        ...result,
        isI18n: false,
        canCustomTd: true,
        thStyle: {
          textAlign: 'right',
          borderRight: BORDER_LIGHT,
        },
        tdStyle: {
          textAlign: 'right',
          borderRight: BORDER_LIGHT,
        },
        render: (val, rowId) => {
          const formattedVal =
            index === 11
              ? valToPercent(val)
              : index === 17
              ? formatVal(valDivThousand(val))
              : val
          const organizationId =
            index === 3 && (dataTable[rowId]?.issuerOrganizationId || 0)
          return (
            <LightBorderColumnCell
              val={formattedVal}
              rowId={rowId}
              isAlignTextLeft={index === 3}
              styleProps={
                index === 11 && val !== EMPTY_VALUE
                  ? {
                      color: getColorThreeConditions(val, true),
                    }
                  : index === 3
                  ? { minWidth: 70, maxWidth: 150 }
                  : {}
              }
              isTicker={index === 3}
              organizationId={organizationId}
              isTextBlink={index === 11 || index === 17}
            />
          )
        },
      }
    }
    if (index === 5 || index === 6) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val) => {
          return formatVal(val)
        },
      }
    }
    if ([1, 2, 4, 12].includes(index)) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          maxWidth: index === 2 ? 150 : index === 4 ? 180 : 'unset',
        },
        render: (val, rowId) => {
          if (index === 1) {
            const organizationId = dataTable[rowId]?.coveredWarrantId || 0
            const activeId = dataTable[rowId]?.activeStatusId || 0
            return activeId !== 1 ? (
              val
            ) : (
              <LinkCWDetail organizationId={organizationId} val={val} />
            )
          } else if (index === 12) {
            const organizationId = dataTable[rowId]?.underlyingId || 0
            return (
              <LinkTicker organizationId={organizationId}>{val}</LinkTicker>
            )
          } else {
            return <TextEllipsis text={val} />
          }
        },
      }
    }
    if (index === 22) {
      result = {
        ...result,
        isI18n: false,
        canCustomTd: true,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => (
          <td
            style={{
              color: getColorThreeConditions(val, false, 'OUT', 'IN'),
            }}
          >
            {val}
          </td>
        ),
      }
    }
    if (index === 19) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
        },
        tdStyle: {
          textAlign: 'right',
        },
        render: (val) => val,
      }
    }
    if (index === 21) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
      }
    }
    if (index === 20) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
        },
        render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
      }
    }
    if ([7, 8, 9, 10, 13, 14, 15, 16, 18].includes(index)) {
      result = {
        ...result,
        canCustomTd: true,
        render: (val, rowId) => {
          const matchPrice =
            val !== EMPTY_VALUE
              ? formatVal(valDivThousand(val))
              : formatVal(valDivThousand(dataTable[rowId]?.cwPrice))
          return (
            <td
              style={
                (index === 18 || index === 9) && val !== EMPTY_VALUE
                  ? {
                      color: getColorThreeConditions(val, true),
                      textAlign: 'right',
                    }
                  : { textAlign: 'right' }
              }
            >
              {index === 7 ? (
                formatVal(valDivThousand(val))
              ) : (
                <TextBlink value={val}>
                  {index === 18 || index === 9
                    ? valToPercent(val)
                    : index === 8
                    ? matchPrice
                    : formatVal(valDivThousand(val))}
                </TextBlink>
              )}
            </td>
          )
        },
      }
    }
    return result
  })
}

export const getRowSpanSchema = (maxTradingDate, locale, timeZone) => {
  return [
    [
      {
        colId: GROUP_COLUMNS[0].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[0].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
        },
      },
      {
        colId: GROUP_COLUMNS[1].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[1].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          top: 0,
        },
      },
      {
        colId: GROUP_COLUMNS[2].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[2].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
        },
      },
      {
        colId: GROUP_COLUMNS[3].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[3].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_LIGHT,
        },
      },
      {
        colId: GROUP_COLUMNS[4].key,
        colSpan: 8,
        thStyle: {
          borderRight: BORDER_LIGHT,
        },
        renderTh: () => (
          <div style={{ textAlign: 'center' }}>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[4].title} />
            </Span>
          </div>
        ),
      },
      {
        colId: GROUP_COLUMNS[11].key,
        colSpan: 6,
        thStyle: {
          borderRight: BORDER_LIGHT,
        },
        renderTh: () => (
          <div style={{ textAlign: 'center' }}>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[11].title} />
            </Span>
          </div>
        ),
      },
      {
        colId: GROUP_COLUMNS[17].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[17].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
        },
        disableSort: true,
      },
      {
        colId: GROUP_COLUMNS[18].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[18].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'right',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
          whiteSpace: 'pre-wrap',
          minWidth: 85,
          maxWidth: 90,
        },
      },
      {
        colId: GROUP_COLUMNS[19].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[19].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
        },
      },
      {
        colId: GROUP_COLUMNS[20].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[20].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
        },
      },
      {
        colId: GROUP_COLUMNS[21].key,
        renderTh: () => (
          <div>
            <Span style={{ fontSize: 10, textTransform: 'uppercase' }}>
              <Translate value={GROUP_COLUMNS[21].title} />
            </Span>
          </div>
        ),
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
          borderRight: BORDER_DARK,
        },
      },
    ],
    [
      {
        title: childrenGroupColumns[0].title,
        colId: childrenGroupColumns[0].key,
        thStyle: {
          fontSize: 10,
          textAlign: 'left',
        },
        thClassName: style.customTableStickyHead,
      },
      {
        title: childrenGroupColumns[1].title,
        colId: childrenGroupColumns[1].key,
        colSpan: 2,
        thStyle: {
          fontSize: 10,
          textAlign: 'right',
        },
      },
      ...childrenGroupColumns.slice(3).map((item, index) => ({
        title: item.title,
        colId: item.key,
        thStyle: {
          fontSize: 10,
          textAlign:
            childrenGroupColumns.indexOf(item) === 6 ? 'left' : 'right',
          borderLeft: [0, 8].includes(childrenGroupColumns.indexOf(item))
            ? BORDER_LIGHT
            : BORDER_DARK,
          borderRight:
            childrenGroupColumns.indexOf(item) === 13 && BORDER_LIGHT,
        },
        disableSort: [1, 2, 5, 6, 7].includes(index),
        renderTh: ['closePrice1', 'closePrice2', 'prePercent'].includes(
          item.key,
        )
          ? () => (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Span style={{ fontSize: 10, textAlign: 'right' }}>
                  <Translate value={item.title} />
                </Span>
                <div style={{ width: 15, flexShrink: 0, marginLeft: 4 }}>
                  <TooltipInfo
                    fontSize={12}
                    tooltip={
                      item.key === 'prePercent'
                        ? I18n.t(
                            'market.coveredWarrant.cwStatistics.overview.TOOLTIP_PERCENT_CLOSE_PRICE_UPDATED',
                            {
                              maxTradingDate: formatDateTime(
                                maxTradingDate,
                                FORMAT.DATE,
                                locale,
                                timeZone,
                              ),
                            },
                          )
                        : I18n.t(
                            'market.coveredWarrant.cwStatistics.overview.TOOLTIP_CLOSE_PRICE_UPDATED',
                            {
                              maxTradingDate: formatDateTime(
                                maxTradingDate,
                                FORMAT.DATE,
                                locale,
                                timeZone,
                              ),
                            },
                          )
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
              </div>
            )
          : undefined,
      })),
    ],
  ]
}

export const getTableHeadRowTop = () => {
  const tableHeadRowTop = {}
  childrenGroupColumns.slice(0, 8).forEach((element) => {
    tableHeadRowTop[element.key] = ['cwAssuredParent']
  })
  childrenGroupColumns.slice(8, 14).forEach((element) => {
    tableHeadRowTop[element.key] = ['underlyingAssetParent']
  })
  return tableHeadRowTop
}

export const getGroupById = (id) => {
  return Object.keys(GROUP_ID_CONVERT).find(
    (key) => GROUP_ID_CONVERT[key] === id,
  )
}

export const getColorThreeConditions = (
  val,
  numberComparison,
  conditionRed,
  conditionGreen,
) => {
  if (numberComparison) {
    return val < 0 ? '#ff4752' : val > 0 ? '#2de352' : '#f7b80c'
  } else {
    return val === conditionRed
      ? '#ff4752'
      : val === conditionGreen
      ? '#2de352'
      : '#f7b80c'
  }
}
