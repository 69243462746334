import { createSlice } from '@reduxjs/toolkit'
import { Span } from '../../../../../common/html/Span'
import { getThreeColorByValue } from '../../../../../utils/Color'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valToPercent,
} from '../../../../../utils/Value'
import { summaryTableSchema } from '../constants'
import { getDeepTransactionSummary } from './thunk'

const getData = (data) => {
  return summaryTableSchema.map((v) => {
    let value = ''
    if (v.id === 'freeFloat') {
      value = `${formatVal(
        data.freeFloat ? data.freeFloat / v.multiplier : data.freeFloat,
      )} (${valToPercent(data.freeFloatRate)})`
    } else if (v.id === 'foreignCurrentRoom') {
      value = `${formatVal(
        data.foreignCurrentRoom
          ? data.foreignCurrentRoom / v.multiplier
          : data.foreignCurrentRoom,
      )} (${valToPercent(data.foreignCurrentPercent)})`
    } else if (v.id === 'rT0060') {
      value = valToPercent(data[v.id])
    } else if (
      [
        'totalPercentChange1WeekClosePriceAdjusted',
        'totalPercentChange1MonthClosePriceAdjusted',
        'totalPercentChangeYTDClosePriceAdjusted',
      ].includes(v.id)
    ) {
      value = (
        <Span style={{ color: getThreeColorByValue(data[v.id]) }}>
          {valToPercent(data[v.id])}
        </Span>
      )
    } else {
      value = formatVal(data[v.id] ? data[v.id] / v.multiplier : data[v.id])
    }
    return { ...v, value }
  })
}

const initialState = {
  data: [],
  dataByIds: {},
  ids: [],
  loading: true,
}

export const slice = createSlice({
  name: 'market/equityTrading/deepTransaction/summary',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDeepTransactionSummary.pending, (state) => {
      state.loading = true
      state.data = []
      state.dataByIds = {}
      state.ids = []
    })
    builder.addCase(getDeepTransactionSummary.fulfilled, (state, action) => {
      state.loading = false
      const data = getData(action.payload.data)
      state.data = data
      state.dataByIds = keyBy(data, 'id')
      state.ids = data.map((v) => v.id)
    })
    builder.addCase(getDeepTransactionSummary.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectDataSummary = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
