import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { CommoditiesSearch } from '../CommoditiesSearch'
import { changeTypeDataForTurnOverChart } from '../exportTurnOver/store/slice'
import style from '../index.module.css'
import { LIST_RADIO } from './constants'
import {
  changeKeySearch,
  changeTypeData,
  handleIsFirstTime,
  selectTypeData,
  setIsKeepRowsCollapse,
  setLimitQuarter,
} from './store/slice'

export const FilterList = ({ width }) => {
  const dispatch = useDispatch()

  const leftHeaderRef = useRef()
  const radioBoxRef = useRef()

  const currentTypeData = useSelector(selectTypeData)

  const handleChangeTypeData = (item) => {
    dispatch(changeTypeData(item.value))
    dispatch(changeTypeDataForTurnOverChart(item.value))
    dispatch(handleIsFirstTime(true))
    dispatch(setLimitQuarter(0))
    dispatch(changeKeySearch(''))
    dispatch(setIsKeepRowsCollapse(true))
  }

  return (
    <div style={{ width: width }} className="d-flex j-b">
      <div ref={leftHeaderRef} style={{ width: '200px' }}>
        <CommoditiesSearch />
      </div>
      <div className="d-flex">
        <ul className={`list-check d-flex mt-0`} ref={radioBoxRef}>
          {LIST_RADIO.map((item) => (
            <li key={item.value} className="mb-0">
              <label>
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={item.value === currentTypeData}
                  onChange={() => handleChangeTypeData(item)}
                />
                <Span
                  style={{ fontSize: 12 }}
                  className={item.value === currentTypeData ? style.active : ''}
                >
                  <TextEllipsis text={item.title} />
                </Span>
              </label>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
