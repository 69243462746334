import CompanyBondDebtAndLoanProxy from '../../../../proxies/bond/issuers/CompanyBondDebtAndLoanProxy'
import { ServiceBase } from '../../../ServiceBase'

export class CompanyBondDebtAndLoanService extends ServiceBase {
  getDebtRelationInBsData(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getDebtRelationInBsData(params),
    )
  }
  getScaleOfDebtByObjects(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getScaleOfDebtByObjects(params),
    )
  }
  getDebtStatisticData(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getDebtStatisticData(params),
    )
  }
  getDebtDetailsData(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getDebtDetailsData(params),
    )
  }
  getCreditRatio(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getCreditRatio(params),
    )
  }
  getTooltipLendingCompany(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getTooltipLendingCompany(params),
    )
  }
  getTooltipLoanCompany(params) {
    return this.getData(() =>
      CompanyBondDebtAndLoanProxy.getTooltipLoanCompany(params),
    )
  }
  downloadDebtDetailsData(params) {
    return this.getFileDownload(() =>
      CompanyBondDebtAndLoanProxy.downloadDebtDetailsData(params),
    )
  }
  downloadTooltipLendingCompany(params) {
    return this.getFileDownload(() =>
      CompanyBondDebtAndLoanProxy.downloadTooltipLendingCompany(params),
    )
  }
}

export default new CompanyBondDebtAndLoanService()
