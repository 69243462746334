import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

class IndicesSectorProxy extends ProxyBase {
  getIndicator(params) {
    return this.get('Indicators', params)
  }

  getResult(params) {
    return this.post('Query', params, {}, 'post', true)
  }

  exportExcel(params) {
    return this.post('Export', params, {}, 'download')
  }
}

export default new IndicesSectorProxy(
  ServiceProxyConfig.Tool.DataExplorer.IndicesSector.ServiceUrl,
)
