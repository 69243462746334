import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import { arrColors, ICB_LEVELS, SEARCH_ITEM_TYPE } from '../../constants'
import { getColor } from '../../helper'
import { setIsShow, setListCompareSearch } from '../store/slice'
import {
  changeCompareTab,
  changeICBLevel,
  searchData,
  selectListCompareChecked,
} from './store/slice'

const ButtonApplyCompare = () => {
  const dispatch = useDispatch()
  const dataListCompareChecked = useSelector(selectListCompareChecked)

  const closePopup = () => {
    dispatch(setIsShow(false))
    dispatch(searchData(''))
    dispatch(changeCompareTab(SEARCH_ITEM_TYPE.ALL))
    dispatch(changeICBLevel(ICB_LEVELS.ICB_LEVEL_2))
  }

  const handleApplyCompare = () => {
    const exceptColors = []
    const compareWithColor = dataListCompareChecked.map((item) => {
      const color = getColor(arrColors, dataListCompareChecked, exceptColors)
      exceptColors.push(color)
      return {
        ...item,
        color: color.color,
        bgColor: color.bgColor,
      }
    })
    dispatch(setListCompareSearch(compareWithColor))
    closePopup()
  }

  return (
    <Button className={`btn btn-blue w-80 h-20 `} onClick={handleApplyCompare}>
      <Translate value={'common.button.BUTTON_APPLY'} />
    </Button>
  )
}

export default ButtonApplyCompare
