import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class CoveredWarrantProxy extends ProxyBase {
  getAll(params) {
    return this.get('GetAll', params)
  }

  getCoveredWarrantSummary(params) {
    return this.get('Summary', params)
  }

  getCoveredWarrantBreakEvenPoint(params) {
    return this.get('BreakEvenPoint', params)
  }

  getCoveredWarrantPriceDepth(params) {
    return this.get('PriceDepth', params)
  }

  getCoveredWarrantTimeAndSales(params) {
    return this.get('TimeAndSales', params)
  }

  getCoveredWarrantTimeAndSalesChart(params) {
    return this.get('TimeAndSalesChart', params)
  }

  downloadTimeAndSales(params) {
    return this.post('downloadTimeAndSales', params, {}, 'download')
  }

  getOverviewStatistics(params) {
    return this.post('Overview', params)
  }

  getNetValueByInvestorChartOverview(params) {
    return this.get('NetValueByInvestorChartOverview', params)
  }

  getNetTradingValueChartOverview(params) {
    return this.get('NetTradingValueChartOverview', params)
  }

  getIssuerOrganizationIds(params) {
    return this.get('GetIssuerOrganizationIds', params)
  }

  getUnderlyingIds(params) {
    return this.get('GetUnderlyingIds', params)
  }

  downloadOverviewStatistics(params) {
    return this.post('DownloadOverview', params, {}, 'download')
  }

  getHistoricalPriceOverview(params) {
    return this.get('HistorycalPrice/Overview', params)
  }

  getHistoricalPriceDetail(params) {
    return this.get('HistorycalPrice/Detail', params)
  }

  getHistoricalPriceTicker(params) {
    return this.get('HistorycalPrice/Ticker', params)
  }

  getNetValueByInvestorChartTicker(params) {
    return this.get('NetValueByInvestorChartTicker', params)
  }

  getNetTradingValueChartTicker(params) {
    return this.get('NetTradingValueChartTicker', params)
  }

  getCoverWarrants(params) {
    return this.get('GetCoverWarrants', params)
  }

  downloadHistoricalOverview(params) {
    return this.get('HistorycalPrice/DownloadOverview', params, 'download')
  }

  downloadHistoricalDetail(params) {
    return this.get('HistorycalPrice/DownloadDetail', params, 'download')
  }

  downloadHistoricalTicker(params) {
    return this.get('HistorycalPrice/DownloadTicker', params, 'download')
  }

  getForeignOverview(params) {
    return this.get('Foreign/Overview', params)
  }

  getForeignDetail(params) {
    return this.get('Foreign/Detail', params)
  }

  getForeignTicker(params) {
    return this.get('Foreign/Ticker', params)
  }

  getForeignChartOverview(params) {
    return this.get('Foreign/ChartOverview', params)
  }

  getForeignChartTicker(params) {
    return this.get('Foreign/ChartTicker', params)
  }

  downloadForeignOverview(params) {
    return this.get('Foreign/DownloadOverview', params, 'download')
  }

  downloadForeignDetail(params) {
    return this.get('Foreign/DownloadDetail', params, 'download')
  }

  downloadForeignTicker(params) {
    return this.get('Foreign/DownloadTicker', params, 'download')
  }

  getOrderedStatisticsOverview(params) {
    return this.get('OrderStatistics/Overview', params)
  }

  getOrderedStatisticsDetail(params) {
    return this.get('OrderStatistics/Detail', params)
  }

  getOrderedStatisticsTicker(params) {
    return this.get('OrderStatistics/Ticker', params)
  }

  downloadOrderedStatisticsOverview(params) {
    return this.get('OrderStatistics/DownloadOverview', params, 'download')
  }

  downloadOrderedStatisticsTicker(params) {
    return this.get('OrderStatistics/DownloadTicker', params, 'download')
  }

  downloadOrderedStatisticsDetail(params) {
    return this.get('OrderStatistics/DownloadDetail', params, 'download')
  }

  getProprietaryOverview(params) {
    return this.get('CoverWarrants/ProprieataryOverview', params)
  }

  getProprietaryDetail(params) {
    return this.get('CoverWarrants/ProprieataryDetail', params)
  }

  getProprietaryTicker(params) {
    return this.get('CoverWarrants/ProprieataryTicker', params)
  }

  downloadProprietaryOverview(params) {
    return this.get(
      'CoverWarrants/DownloadProprieataryOverview',
      params,
      'download',
    )
  }

  downloadProprietaryTicker(params) {
    return this.get(
      'CoverWarrants/DownloadProprieataryTicker',
      params,
      'download',
    )
  }

  downloadProprietaryDetail(params) {
    return this.get(
      'CoverWarrants/DownloadProprieataryDetail',
      params,
      'download',
    )
  }
}

export default new CoveredWarrantProxy(
  ServiceProxyConfig.Market.CoveredWarrant.ServiceUrl,
)
