import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'

export const CONTRIBUTION_CREDIT_GROWTH = 'CONTRIBUTION_CREDIT_GROWTH'

const ContributionCreditGrowth = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n('economy.monetary.monetary.MONEY_SUPPLIES_CREDIT')
  const chartName = UseI18n(
    'economy.monetary.monetary.CONTRIBUTION_CREDIT_GROWTH',
  )
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'economy.monetary.monetary.CONTRIBUTION_CREDIT_GROWTH'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CONTRIBUTION_CREDIT_GROWTH,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <Container
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default ContributionCreditGrowth
