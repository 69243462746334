import { I18n } from 'react-redux-i18n'
import { Label } from 'recharts'
import { TYPE } from '../../../../common/chart/footer/Item'
import TextEllipsis from '../../../../common/textEllipsis'
import { getFontSize } from '../../../../utils/FontSize'
import {
  LEFT_FRAME_OPTIONS,
  STACK_COLORS,
  TYPE_CHART_SOCIAL_INVESTMENT,
} from './constants'

const HEIGHT_BAR_CHART = 10

export const renderListFooter = (keys, nameById, typeChartValue) => {
  const isTotalSocialChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL
  const isStateSectorChart =
    typeChartValue === TYPE_CHART_SOCIAL_INVESTMENT.STATE_SECTOR

  const getNameText = (key) => {
    return isTotalSocialChart
      ? nameById?.find((item) => item.id === key)?.name
      : isStateSectorChart
      ? key
      : LEFT_FRAME_OPTIONS.find((item) => item.value === typeChartValue)?.name
  }

  return keys?.map((key, index) => {
    return {
      text: getNameText(key),
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS[keys.length][index],
      },
      isI18n: true,
    }
  })
}

export const customYAxisTick = (fontSize) => (props) => {
  return (
    <g>
      <foreignObject
        x={0}
        y={props.y - HEIGHT_BAR_CHART}
        height={props.height}
        width={props.width}
        color="#ececec"
      >
        <TextEllipsis
          appendStyle={{ fontSize }}
          text={props.payload.value}
          isI18n={true}
        />
      </foreignObject>
    </g>
  )
}

export const xAxisLabelCustom = () => (
  <Label
    value={I18n.t('economy.fdi.overview.LABEL_MILLION_USD')}
    position="insideBottomRight"
    content={(props) => {
      return (
        <text
          style={{ fontSize: getFontSize(10), opacity: 0.4 }}
          x={props.viewBox.x + props.viewBox.width}
          y={props.viewBox.y + props.viewBox.height + 8}
          textAnchor="end"
          fill="rgba(255, 255, 255)"
        >
          {props.value}
        </text>
      )
    }}
  />
)

export const getTooltipContentData = (data, stackKey, nameById) => {
  const getTextName = (id) => {
    return nameById?.find((item) => item.id === id)?.name
  }

  return stackKey?.map((key) => {
    return {
      label: getTextName(key),
      value: data[key],
    }
  })
}
