import { useDispatch, useSelector } from 'react-redux'
import { Button } from '../../../../common/html/Button'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import style from '../index.module.css'
import { getOrganizationsByIcb } from '../store/thunk'

const ButtonReset = () => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)

  const handleReset = () => {
    dispatch(
      getOrganizationsByIcb({
        ICBId: sectorInfo.icbId,
        isSetDefault: true,
        isReset: true,
      }),
    )
  }

  return (
    <div className={style.mt32}>
      <Button
        onClick={handleReset}
        style={{ fontSize: 11 }}
        className={`btn h-20 w-80 ${style.btnReset}`}
      >
        Reset
      </Button>
    </div>
  )
}

export default ButtonReset
