import { useSelector } from 'react-redux'
import { SEC_GROUP_INDICATOR } from '../../../common/constants'
import { selectIndicatorByTypeSecurities } from '../../../store/slice'
import { MAP_NAME_BY_TIME_FREQUENCY } from '../../constants'
import style from '../style.module.css'

const ListEconomySelected = ({
  indexStart,
  economyChecked,
  securitiesState,
  setSecuritiesState,
}) => {
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )

  const getValueItem = (item) => {
    const indicator = listSecuritiesEconomy.find((i) => i.id === item.id)
    return {
      name: `${indicator?.secName} ${
        MAP_NAME_BY_TIME_FREQUENCY[item.timeFrequency]
      }`,
    }
  }

  const removeEconomyInList = (indicatorId) => {
    const value = economyChecked.filter((item) => item.id !== indicatorId)
    setSecuritiesState({ ...securitiesState, economy: value })
  }

  return (
    <>
      {economyChecked.map((item, index) => {
        return (
          <li key={item.id}>
            <div className={style.indexCol}>{indexStart + index + 1}</div>
            <div className={style.divSecurities}>
              <div className={style.tickerInfo}>{getValueItem(item)?.name}</div>
            </div>
            <div
              className={style.checkboxCol}
              onClick={() => removeEconomyInList(item.id)}
            >
              <i
                className="icon-delete-circle cursor-pointer"
                style={{ fontSize: 12 }}
              />
            </div>
          </li>
        )
      })}
    </>
  )
}

export default ListEconomySelected
