export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}
