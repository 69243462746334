import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../../utils/ReducerRegistry'
import { chartYearOptions } from '../../../../constants'
import { indicatorOptions } from '../constants'
import { getInDepthComparisonChartData } from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/bank/assetQuality/inDepthComparison',
  initialState: {
    data: [],
    loading: true,
    yearType: chartYearOptions[0].value,
    indicatorType: indicatorOptions[0].value,
    retryFlag: true,
  },
  reducers: {
    changeYearType: (state, action) => {
      state.yearType = action.payload
    },
    changeIndicatorType: (state, action) => {
      state.indicatorType = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInDepthComparisonChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getInDepthComparisonChartData.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = action.payload
      },
    )
    builder.addCase(getInDepthComparisonChartData.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartData = (state) => state[slice.name].data
export const selectChartLoading = (state) => state[slice.name].loading
export const selectYearType = (state) => state[slice.name].yearType
export const selectIndicatorType = (state) => state[slice.name].indicatorType
export const selectRetryFlag = (state) => state[slice.name].retryFlag
export const { changeYearType, changeIndicatorType, changeRetryFlag } =
  slice.actions

register(slice.name, slice.reducer)
