import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { FORMAT } from '../../../../../utils/Datetime'
import { barKeys, fillColor, mappingDisplayName, xAxisKey } from './constants'

export const StackBarChart = ({
  data,
  width,
  height,
  decimalLength,
  margin,
}) => {
  // Get data
  const getData = () => {
    return [...data]
      .reverse()
      .filter(
        (item, index, arr) =>
          index === arr.findIndex((v) => v[xAxisKey] === item[xAxisKey]),
      )
      .reverse()
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: barKeys,
        yAxisId: 'barChart',
        unit: '%',
        decimalLength: decimalLength || 2,
      },
    ]
  }

  return (
    <ChartContainer
      data={getData()}
      width={width}
      height={height}
      keyXAxis={xAxisKey}
      yAxis={[
        {
          id: 'barChart',
          keys: barKeys,
          orientation: 'left',
          isStackedBar: true,
          yAxisWidth: margin.left,
          tickValues: [0, 25, 50, 75, 100],
          label: '(%)',
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
        {
          id: 'dummy',
          keys: [],
          orientation: 'right',
          yAxisWidth: margin.right,
        },
      ]}
      timeFrame={TIME_RANGES['1Y']}
      allowEscapeViewBoxY={true}
      tooltipSchema={{
        title: {
          formatDate: FORMAT.DATE,
        },
        content: getContent(),
        mappingDisplayName: mappingDisplayName,
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {barKeys.map((key) => (
            <Bar
              key={key}
              stackId="stackBar"
              yAxisId="barChart"
              dataKey={key}
              barSize={getColumnSizeInBarChart(chartContentWidth, data.length)}
              fill={fillColor[key]}
              isAnimationActive={false}
            />
          ))}
        </>
      )}
    </ChartContainer>
  )
}
