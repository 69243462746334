import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getGDPGrowthBySector } from './thunk'

const initialState = {
  timeRangeYearly: '7Y',
  timeRangeQuarterly: '3Y',
  lineChartLoading: true,
  lineChartData: [],
  activeLine: undefined,
}

const slice = createSlice({
  name: 'economy/gdp/totalGDP/panelGDPGrowthBySector',
  initialState,
  reducers: {
    handleTimeRangeYearly: (state, action) => {
      state.timeRangeYearly = action.payload
    },
    handleTimeRangeQuarterly: (state, action) => {
      state.timeRangeQuarterly = action.payload
    },
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGDPGrowthBySector.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getGDPGrowthBySector.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = action.payload || []
    })
    builder.addCase(getGDPGrowthBySector.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const {
  handleTimeRangeYearly,
  handleTimeRangeQuarterly,
  handleActiveLine,
} = slice.actions
export const getTimeRangeYearly = (state) => state[slice.name].timeRangeYearly
export const getTimeRangeQuarterly = (state) =>
  state[slice.name].timeRangeQuarterly
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getLineChartData = (state) => state[slice.name].lineChartData
export const getActiveLine = (state) => state[slice.name].activeLine

register(slice.name, slice.reducer)
