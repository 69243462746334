import { debounce } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import Dropdown from '../../../../../common/dropdown'
import { Input } from '../../../../../common/html/Input'
import TextEllipsis from '../../../../../common/textEllipsis'
import { keyBy } from '../../../../../utils/Common'
import {
  selectCurrentPageCompany,
  selectEnableCScrollCompany,
  selectListFilterCompany,
  selectidsCompany,
  updateSelectCompany,
} from '../store/slice'
import { fetchListCompany, getListCompany } from '../store/thunk'
import style from './style.module.css'

const FilterCompany = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()
  const idsCompany = useSelector(selectidsCompany)

  const locale = useSelector((state) => state.i18n.locale)
  const currentPage = useSelector(selectCurrentPageCompany)
  const enableScroll = useSelector(selectEnableCScrollCompany)
  const listCompany = useSelector(selectListFilterCompany)
  const listCompanyById = keyBy(listCompany, 'value')

  const [isShow, setIsShow] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [companySelected, setCompanySelected] = useState([])

  const getTickerName = useMemo(() => {
    return companySelected.map((item) => item.label1).join(',') || ''
  }, [companySelected])

  const companyNotSelected = useMemo(() => {
    return listCompany.filter((item) => !idsCompany.includes(item.value))
  }, [idsCompany, listCompany])

  const toggleDropSearch = () => {
    setIsShow(!isShow)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    setKeySearch('')
  }

  const changeValueSector = (value) => {
    let companies = []
    let newCompaniesSelected = [...companySelected]

    if (idsCompany.findIndex((e) => e === value) !== -1) {
      companies = idsCompany.filter((e) => e !== value)
      newCompaniesSelected = newCompaniesSelected.filter(
        (item) => item.value !== value,
      )
    } else {
      companies = [...idsCompany, value]
      newCompaniesSelected = [...newCompaniesSelected, listCompanyById[value]]
    }

    setCompanySelected(newCompaniesSelected)
    dispatch(updateSelectCompany(companies))
  }

  useEffect(() => {
    let params = {
      language: locale,
      page: 1,
      PageSize: 20,
    }
    if (keySearch) {
      params['Term'] = keySearch
    }
    dispatch(getListCompany(params))
  }, [locale, keySearch])

  useEffect(() => {
    if (!idsCompany.length) {
      setCompanySelected([])
    }
  }, [idsCompany])

  const fetchMoreListCompany = () => {
    if (!enableScroll) {
      let params = {
        language: locale,
        page: currentPage + 1,
        PageSize: 20,
      }
      if (keySearch) {
        params['Term'] = keySearch
      }
      dispatch(fetchListCompany(params))
    }
  }

  return (
    <div
      className={`input-dropdown ${style.inputCompany}`}
      ref={parentDropdownRef}
      style={{ marginRight: '26px' }}
    >
      <span
        className={`span-input w-100 ${style.inputDropdown} `}
        style={{ padding: '2px 20px 2px 8px' }}
        ref={dropdownRef}
        onClick={toggleDropSearch}
      >
        <TextEllipsis
          text={getTickerName || 'bond.corporateBond.priceBoard.filterTCPH'}
          isI18n
        />

        <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside
          handleClickOutside={hideDropSearch}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width: dropdownRef?.current?.clientWidth }}
            >
              <div
                className="form-search-inbox"
                style={{ width: 'auto', margin: '4px 8px 8px 8px' }}
              >
                <Input
                  style={{
                    fontSize: 11,
                  }}
                  type="text"
                  className={`search-inbox ${style.inputSearch}`}
                  placeholder={I18n.t('common.SEARCH')}
                  onChange={debounce((e) =>
                    setKeySearch(e.target.value?.toLowerCase()),
                  )}
                />
                <button type="button">
                  <i className="icon-search-2" />
                </button>
              </div>
              <ScrollComponent
                autoHeight={true}
                autoHeightMax={200}
                scrollToBottomCallback={() => fetchMoreListCompany()}
              >
                {!!listCompany.length && (
                  <ul className="list-check">
                    {!!listCompany.length &&
                      [...companySelected, ...companyNotSelected]
                        .filter((item) => {
                          if (keySearch) {
                            return (
                              item.label1?.toLowerCase().includes(keySearch) ||
                              item.label2?.toLowerCase().includes(keySearch)
                            )
                          } else {
                            return item
                          }
                        })
                        .map((item) => {
                          return (
                            <li key={item.value}>
                              <a>
                                <label className="d-flex ali-center">
                                  <input
                                    type="checkbox"
                                    className="checkbox mr-8"
                                    checked={
                                      idsCompany.includes(item.value) ?? false
                                    }
                                    onChange={() =>
                                      changeValueSector(item.value)
                                    }
                                  />
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '20px',
                                      width: '100%',
                                    }}
                                  >
                                    <div style={{ width: '40px' }}>
                                      <TextEllipsis
                                        text={item.label1}
                                        zIndexTooltip={999}
                                      />
                                    </div>

                                    <div style={{ width: '60%' }}>
                                      <TextEllipsis
                                        text={item.label2}
                                        zIndexTooltip={999}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </a>
                            </li>
                          )
                        })}
                  </ul>
                )}
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default FilterCompany
