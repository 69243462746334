import React from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Z_INDEX } from '../../../../../constants/Common'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn }) => {
  const renderTitle = (text, styleHeader) => {
    return (
      <Span
        className={style.theadContent}
        style={{
          fontSize: 12,
          fontWeight: 600,
          ...styleHeader,
        }}
      >
        <Translate value={text} />
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={row.name}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              }`}
            >
              <div className={stateSort[row?.key] ? 'mr-5' : undefined}>
                {renderTitle(row.title, row?.attrs?.styleHeader)}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
