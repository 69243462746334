import React from 'react'
import { useSelector } from 'react-redux'
import InvestmentStructure from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { selectFilter } from './store/slice'

const InvestmentStructurePanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const VSIC = useSelector(selectFilter)

  return (
    <Panel
      title={'economy.fdi.fdiByIndustry.FDI_STRUCTURE_BY_INDUSTRY'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'FDIStructureByIndustryId',
        nameScreen: UseI18n('economy.fdi.fdiByIndustry.NAME_SCREEN'),
        chartName: UseI18n(
          'economy.fdi.fdiByIndustry.FDI_STRUCTURE_BY_INDUSTRY',
        ),
        tabName: VSIC?.vsicName,
      }}
      titleJpg={UseI18n('economy.fdi.fdiByIndustry.FDI_STRUCTURE_BY_INDUSTRY')}
    >
      <InvestmentStructure
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default InvestmentStructurePanel
