import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { maxIndicator } from '../constant'
import { EMPTY_COLUMN_LEFT_TABLE } from './Thead'
import style from './index.module.css'

const RowNoData = ({ selectFullId, noDataText }) => {
  const fullId = useSelector(selectFullId)
  const text = typeof noDataText !== 'undefined' ? noDataText : 'common.NO_DATA'

  let content = <Translate value={text} />

  return (
    <>
      {fullId?.length === 0 && (
        <tr>
          <td colSpan={EMPTY_COLUMN_LEFT_TABLE} />
          <td colSpan={maxIndicator}>
            <div className={`no-data ${style.noData}`}>{content}</div>
          </td>
        </tr>
      )}
    </>
  )
}

export default RowNoData
