import { useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { SizeTracker } from '../../../../common/sizeTracker'
import { getKeyByValue } from '../../../../utils/Common'
import Title from '../common/Title'
import { MAIN_METRIC_CODE, PREFIX_METRIC_CODE } from '../constants'
import {
  selectDetailMetricsDataByDate,
  selectLoadingDetailMetrics,
} from '../store/slice'
import ChartComponent from './ChartComponent'
import style from './index.module.css'
import { CHART_DOM_ID } from './PanelParticular'

const PADDING = 8

const ChartContainer = ({ width, height }) => {
  const loading = useSelector(selectLoadingDetailMetrics)
  const data = useSelector(selectDetailMetricsDataByDate)

  return (
    <ScrollComponent>
      <div id={CHART_DOM_ID}>
        {Object.values(MAIN_METRIC_CODE).map((code, index) => (
          <div
            key={code}
            className={style.chartComponent}
            style={{ height: height / 2, padding: `0 ${PADDING}px` }}
          >
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <Title
                      text={`indicators.${getKeyByValue(
                        MAIN_METRIC_CODE,
                        code,
                      )}`}
                    />
                    {size.height && (
                      <div
                        style={{
                          position: 'relative',
                          height: height / 2 - size.height,
                        }}
                      >
                        {loading ? (
                          <Loading />
                        ) : data.length === 0 ? (
                          <NoData />
                        ) : (
                          <ChartComponent
                            width={width - 2 * PADDING}
                            height={height / 2 - size.height}
                            data={data}
                            keyXAxis="date"
                            barKey={code}
                            lineKey={PREFIX_METRIC_CODE.GROWTH + code}
                          />
                        )}
                      </div>
                    )}
                    {index !== Object.values(MAIN_METRIC_CODE).length - 1 && (
                      <div className={style.divider} />
                    )}
                  </>
                )
              }}
            </SizeTracker>
          </div>
        ))}
      </div>
    </ScrollComponent>
  )
}

export default ChartContainer
