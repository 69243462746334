import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MasterService from '../../../../../core/services/common/MasterService'
import MarginLoansService from '../../../../../core/services/market/MarginLoansService'
import { handleExport } from '../../../../utils/Export'

export const getMarginGrowth = createAsyncThunk(
  'market/marginLoans/getMarginGrowth',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarginLoansService.getMarginGrowth(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getOrganizationsInfo = createAsyncThunk(
  'market/marginLoans/getOrganizationsInfo',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterService.getOrganizationsByIcb(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTopCompany = createAsyncThunk(
  'market/marginLoans/getTopCompany',
  async (params, { rejectWithValue }) => {
    const response = await MarginLoansService.getTopCompany(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getOrganizationsListPopup = createAsyncThunk(
  'market/marginLoans/getOrganizationsListPopup',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterService.getOrganizationsByIcb(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadMarginGrowth = createAsyncThunk(
  'market/marginLoans/downloadMarginGrowth',
  async (data) => {
    const response = await MarginLoansService.downloadMarginGrowth(data)
    handleExport(response.data, response.filename)
  },
)
