export const ALL_PUBLIC_PRIVATE_TABS = [
  {
    title: 'bond.corporateBond.primaryMarket.ALL',
    value: 'All',
  },
  {
    title: 'bond.corporateBond.primaryMarket.PUBLIC',
    value: 'PublicOffering',
  },
  {
    title: 'bond.corporateBond.primaryMarket.PRIVATE',
    value: 'PrivatePlacement',
  },
]

export const MIN_DATE_FILTER = 2009

export const TIME_FREQUENCY = {
  MONTHLY: 'Monthly',
  QUARTER: 'Quarterly',
  YEARLY: 'Yearly',
}

export const MONTH_QUARTER_YEAR_RADIO = [
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY',
    value: TIME_FREQUENCY.MONTHLY,
  },
  {
    name: 'bond.corporateBond.primaryMarket.QUARTERLY',
    value: TIME_FREQUENCY.QUARTER,
  },
  {
    name: 'bond.corporateBond.primaryMarket.YEARLY',
    value: TIME_FREQUENCY.YEARLY,
  },
]

export const MONTHS = [
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_1',
    value: 1,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_2',
    value: 2,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_3',
    value: 3,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_4',
    value: 4,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_5',
    value: 5,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_6',
    value: 6,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_7',
    value: 7,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_8',
    value: 8,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_9',
    value: 9,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_10',
    value: 10,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_11',
    value: 11,
  },
  {
    name: 'bond.corporateBond.primaryMarket.MONTHLY_OPTION.MONTH_12',
    value: 12,
  },
]

export const QUARTER = [
  {
    name: 'Q1',
    value: 1,
  },
  {
    name: 'Q2',
    value: 2,
  },
  {
    name: 'Q3',
    value: 3,
  },
  {
    name: 'Q4',
    value: 4,
  },
]

export const colorArray = ['#45b29d', '#facc5c', '#185999']

export const RELEASE_TERM = [
  {
    name: 'bond.corporateBond.primaryMarket.ALL',
    value: 'All',
  },
  {
    name: 'bond.corporateBond.primaryMarket.ONE_TWO_THREE_YEARS',
    value: 'OneToThreeYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.THREE_TWO_FIVE_YEARS',
    value: 'ThreeToFiveYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.FIVE_TO_SEVEN_YEARS',
    value: 'FiveToSevenYears',
  },
  {
    name: 'bond.corporateBond.primaryMarket.OVER_SEVEN_YEAR',
    value: 'OverSevenYears',
  },
]

export const BAR_COLOR_AVERAGE_COUPON_BY_SECTOR = ['#185999', '#FACC5C']

export const BAR_COLOR_ISSUANCE_VALUE_BY_SECTOR = [
  '#185999',
  '#0096EB',
  '#FACC5C',
  '#FFF6DF',
  '#FA9AA7',
]

export const GROUP_ROWS = [
  {
    key: 'name',
    title: 'bond.corporateBond.issuanceValue.INDUSTRY',
  },
  {
    key: 'issuanceValueSummary',
    title: 'bond.corporateBond.issuanceValue.RELEASE_VALUE',
  },
  {
    key: 'averageDurationSummary',
    title: 'bond.corporateBond.issuanceValue.RELEASE_DURATION',
  },
  {
    key: 'averageCouponSummary',
    title: 'bond.corporateBond.issuanceValue.COUPON',
  },
  {
    key: 'issuanceValueFixed',
    title: 'bond.corporateBond.issuanceValue.RELEASE_VALUE',
  },
  {
    key: 'averageDurationFixed',
    title: 'bond.corporateBond.issuanceValue.RELEASE_DURATION',
  },
  {
    key: 'averageCouponFixed',
    title: 'bond.corporateBond.issuanceValue.COUPON',
  },
  {
    key: 'issuanceValueFloat',
    title: 'bond.corporateBond.issuanceValue.RELEASE_VALUE',
  },
  {
    key: 'averageDurationFloat',
    title: 'bond.corporateBond.issuanceValue.RELEASE_DURATION',
  },
  {
    key: 'averageCouponFloat',
    title: 'bond.corporateBond.issuanceValue.COUPON',
  },
  {
    key: 'issuanceValueOther',
    title: 'bond.corporateBond.issuanceValue.RELEASE_VALUE',
  },
  {
    key: 'averageDurationOther',
    title: 'bond.corporateBond.issuanceValue.RELEASE_DURATION',
  },
]

export const GROUP_COLUMNS = [
  {
    key: 'id',
    title: '',
  },
  {
    key: 'summary',
    title: 'bond.corporateBond.issuanceValue.SUMMARY',
  },
  {
    key: 'fixed',
    title: 'bond.corporateBond.issuanceValue.FIXED',
  },
  {
    key: 'float',
    title: 'bond.corporateBond.issuanceValue.FLOATING_AND_COMBINE',
  },
  {
    key: 'other',
    title: 'bond.corporateBond.issuanceValue.OTHER',
  },
]

export const COUPON_TYPES = {
  OTHERS: 0,
  FLOAT: 1,
  ZERO: 2,
  MIXED: 3,
  FIXED: 4,
}
