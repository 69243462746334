import { I18n } from 'react-redux-i18n'
import { getTextWidth } from '../chart/helper'

export const getItemTextWidth = (data, itemStyle, fontSize, widthComponent) => {
  const itemsTextWidth = data
    .map((item) => I18n.t(item.title))
    .map((text) =>
      getTextWidth(
        itemStyle?.textTransform === 'uppercase' ? text.toUpperCase() : text,
        fontSize,
        itemStyle?.fontWeight,
      ),
    )
  const totalWidth = itemsTextWidth.length
    ? itemsTextWidth
        .map(
          (item, index) =>
            item +
            itemStyle?.paddingLeft +
            itemStyle?.paddingRight +
            (index === itemsTextWidth.length - 1 ? 0 : 1),
        )
        .reduce((prev, cur) => prev + cur)
    : 0

  if (widthComponent < totalWidth) {
    let widthNeedSubtract = Math.round(totalWidth - widthComponent)
    do {
      const maxTextWidth = Math.max(...itemsTextWidth)
      const indexMaxTextWidth = itemsTextWidth.findIndex(
        (num) => num === maxTextWidth,
      )
      itemsTextWidth[indexMaxTextWidth]--
      widthNeedSubtract--
    } while (widthNeedSubtract > 0)
    return itemsTextWidth
  }
  return []
}
