import StateBudgetProxy from '../../../proxies/economy/fiscal/StateBudgetProxy'
import { ServiceBase } from '../../ServiceBase'

export class StateBudgetService extends ServiceBase {
  getBudgetRevenueTypeName(params) {
    return this.getData(() => StateBudgetProxy.getBudgetRevenueTypeName(params))
  }

  getStateBudgetBalance(params) {
    return this.getData(() => StateBudgetProxy.getStateBudgetBalance(params))
  }

  getDownloadStateBudgetBalance(params) {
    return this.getFileDownload(() =>
      StateBudgetProxy.getDownloadStateBudgetBalance(params),
    )
  }

  getStateBudgetBalanceChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getStateBudgetBalanceChart(params),
    )
  }

  getStateBudgetRevenueChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getStateBudgetRevenueChart(params),
    )
  }

  getStateBudgetExpenditureChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getStateBudgetExpenditureChart(params),
    )
  }

  getTotalSocialInvesmentCapitalChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getTotalSocialInvesmentCapitalChart(params),
    )
  }

  getTotalInvesmentCapitalUnderTheStateBudgetChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getTotalInvesmentCapitalUnderTheStateBudgetChart(params),
    )
  }

  getTotalInvesmentCapitalOfTheStateSectorChart(params) {
    return this.getData(() =>
      StateBudgetProxy.getTotalInvesmentCapitalOfTheStateSectorChart(params),
    )
  }

  getTotalSocialInvesmentCapital(params) {
    return this.getData(() =>
      StateBudgetProxy.getTotalSocialInvesmentCapital(params),
    )
  }

  getTotalSocialInvesmentCapitalTypeName(params) {
    return this.getData(() =>
      StateBudgetProxy.getTotalSocialInvesmentCapitalTypeName(params),
    )
  }

  getDownloadTotalSocialInvesmentCapital(params) {
    return this.getFileDownload(() =>
      StateBudgetProxy.getDownloadTotalSocialInvesmentCapital(params),
    )
  }

  getMaxPeriod(params) {
    return this.getData(() => StateBudgetProxy.getMaxPeriod(params))
  }

  getDataStatusName(params) {
    return this.getData(() => StateBudgetProxy.getDataStatusName(params))
  }
}

export default new StateBudgetService()
