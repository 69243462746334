import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../common/chart/footer'
import { TYPE } from '../../../common/chart/footer/Item'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import SelectCustom from '../../../common/selectCustom'
import { SizeTracker } from '../../../common/sizeTracker'
import { getYearInDataRange } from '../common/helper'
import {
  selectDataTable,
  selectTypeCode,
} from '../cpiAndInflationTable/store/slice'
import style from '../style.module.css'
import { ChartComponent } from './ChartComponent'
import { COLOR_CHART } from './constants'
import {
  changeDataChart,
  selectCPIId,
  selectDataChart,
  selectLoading,
  selectOrigDataChart,
} from './store/slice'
import { growthYearlyChart } from './store/thunk'

const PADDING_BOTTOM_CHART = 12

export const GrowthYearly = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const origDataChart = useSelector(selectOrigDataChart)
  const dataCPIFull = useSelector(selectDataTable)
  const cpiId = useSelector(selectCPIId)
  const isLoading = useSelector(selectLoading)
  const typeCode = useSelector(selectTypeCode)

  const listFilter = dataCPIFull.map((item) => ({
    name: item.cpivnTypeName,
    value: item.cpivnTypeId,
  }))

  const getYearInRange = getYearInDataRange(origDataChart)

  const getYearKeyFromRange = getYearInRange.map((item) => `${item.year}`)

  const footerList = getYearKeyFromRange?.map((item, index) => ({
    text: item,
    type: TYPE.LINE,
    before: {
      bgColor: COLOR_CHART[index],
    },
  }))

  const handleTypeChange = (val) => {
    dispatch(changeDataChart(val))
  }

  useEffect(() => {
    if (cpiId) {
      dispatch(growthYearlyChart({ CPIVNTypeId: cpiId }))
    }
  }, [cpiId, typeCode])
  return isLoading ? (
    <Loading />
  ) : (
    <SizeTracker>
      {(size) => (
        <div className="h-100">
          <div className={`${style.inputDropdown}`}>
            <SelectCustom
              value={cpiId}
              isI18n={true}
              selectData={listFilter}
              handleChange={handleTypeChange}
            />
          </div>
          <div id="growthYearly">
            {size.height && dataChart.length ? (
              <ChartComponent
                data={dataChart}
                width={width}
                height={height - size.height - PADDING_BOTTOM_CHART}
                keyXAxis={'time'}
                lineKeys={getYearKeyFromRange}
                getYearKeyFromRange={getYearKeyFromRange}
              />
            ) : (
              size.height && (
                <div style={{ height: height - size.height }}>
                  <NoData />
                </div>
              )
            )}
            {dataChart.length && <Footer key={width} list={footerList} />}
          </div>
        </div>
      )}
    </SizeTracker>
  )
}
