import { NewsAndReport } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'

export const PanelNewsAndReport = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  return (
    <Panel
      title="sector.sectorSpecific.overview.NEWS_AND_REPORT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <NewsAndReport
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
