import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { LIST_TYPE_SHORT_TEXT } from '../../common/constants'
import { getClassActive } from './helper'
import style from './index.module.css'

const ItemSecuritiesCompany = ({ companies, ticker, onClick, itemId }) => {
  const { organizationId, organizationName, organizationShortName } = ticker

  return (
    <div
      key={organizationId}
      className={`${
        companies.some((i) => organizationId === i) ? style.disable : ''
      } ${style.wrapperItemSecurities}`}
      onClick={onClick}
      style={{
        opacity: getClassActive(organizationId, itemId) ? 1 : undefined,
        background: getClassActive(organizationId, itemId)
          ? '#e3e7e8'
          : undefined,
        paddingLeft: 12,
      }}
    >
      <div className={`align-center ${style.itemSecurities}`}>
        <div
          className={`${style.securitiesNameTicker} ${getClassActive(
            organizationId,
            itemId,
          )}`}
        >
          <TextEllipsis isI18n={false} text={ticker.ticker || ticker.taxCode} />
        </div>
        <div className={style.orgName}>
          <TextEllipsis
            isI18n={false}
            text={organizationShortName || organizationName}
            zIndexTooltip={Z_INDEX.DROPDOWN}
          />
        </div>
        {!itemId ? (
          <div className={style.securitiesType}>
            <TextEllipsis
              isI18n={false}
              text={LIST_TYPE_SHORT_TEXT.COMPANIES}
            />
          </div>
        ) : null}
        {getClassActive(organizationId, itemId) ? (
          <input
            type="checkbox"
            className="radiobox radiobox2"
            style={{
              border: '0.5px solid #979797',
              borderRadius: '50%',
              marginRight: 10,
              marginLeft: 10,
            }}
            defaultChecked
          />
        ) : null}
      </div>
    </div>
  )
}

ItemSecuritiesCompany.propTypes = {}

export default ItemSecuritiesCompany
