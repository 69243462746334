import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { LASTEST_YEARS } from '../../common/constant'
import style from './index.module.css'
import { changeFilter, selectFilter } from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const handleChangeYear = (year) => dispatch(changeFilter({ year }))

  return (
    <div className={style.select}>
      <SelectCustom
        isI18n={true}
        selectData={LASTEST_YEARS}
        value={filter.year}
        handleChange={handleChangeYear}
        appendStyle={{ fontWeight: 'normal' }}
      />
    </div>
  )
}

export default Filter
