import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valueTimefrequency } from '../constants'
import { getDataValueOfBonds, getDataValueOfBondsCollateral } from './thunk'

const initialState = {
  isLoading: false,
  dataChart: [],
  activeTab: valueTimefrequency.ISSUEMETHOD,
}

const slice = createSlice({
  name: 'bond/overview/issuesPlan',
  initialState,
  reducers: {
    updateTab: (state, actions) => {
      state.activeTab = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataValueOfBonds.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataValueOfBonds.fulfilled, (state, action) => {
      state.dataChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getDataValueOfBonds.rejected, (state, action) => {
      state.dataChart = []
      state.isLoading = action.payload
    })

    builder.addCase(getDataValueOfBondsCollateral.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getDataValueOfBondsCollateral.fulfilled,
      (state, action) => {
        state.dataChart = action.payload || []
        state.isLoading = false
      },
    )
    builder.addCase(getDataValueOfBondsCollateral.rejected, (state, action) => {
      state.dataChart = []
      state.isLoading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectActiveTab = (state) => state[slice.name].activeTab
export const { updateTab } = slice.actions

register(slice.name, slice.reducer)
