import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useGetUserSettings } from '../common/useGetUserSettings'
import { CHART_PARAMETERS_DEFAULT } from './constants'
import {
  changeChartTabActive,
  changeChartTabsData,
  selectChartTabActive,
  selectChartTabsData,
} from './store/slice'

const withWrapper = (Component) => (props) => {
  const dispatch = useDispatch()

  const { defaultChartSettings } = useGetUserSettings()

  const chartTabs = useSelector(selectChartTabsData)
  const chartTabActive = useSelector(selectChartTabActive)

  useEffect(() => {
    if (!Object.keys(chartTabs).length) {
      dispatch(
        changeChartTabsData({
          1: {
            ...CHART_PARAMETERS_DEFAULT,
            settings:
              defaultChartSettings?.settings ||
              CHART_PARAMETERS_DEFAULT.settings,
            styleSetting:
              defaultChartSettings?.styleSetting ||
              CHART_PARAMETERS_DEFAULT.styleSetting,
          },
        }),
      )
      dispatch(changeChartTabActive(1))
    } else {
      dispatch(
        changeChartTabsData({ [chartTabActive]: chartTabs[chartTabActive] }),
      )
      dispatch(changeChartTabActive(chartTabActive))
    }
  }, [])

  if (!Object.keys(chartTabs).length || !chartTabActive)
    return (
      <div
        style={{
          height: `calc(100% - 18px)`,
          backgroundColor: 'white',
          margin: '18px -8px 0',
        }}
      />
    )

  return <Component {...props} />
}

export default withWrapper
