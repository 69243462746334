import PropTypes from 'prop-types'
import { Bar, Cell } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import {
  getColumnSizeInBarChart,
  getRoundedTickValues,
} from '../../../common/chart/helper'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../common/html/Span'
import { formatVal, valToPercent } from '../../../utils/Value'
import style from '../style.module.css'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 13,
}

const BLUE_COLOR_BAR = '#185999'
const RED_COLOR_BAR = '#e5604e'

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

const ChartComponent = ({ data, width, height, keyXAxis, barKey }) => {
  const valueAtPoint = (point) => {
    return point.value[1] - point.value[0]
  }

  const listValues = data.reduce((arr, item) => [...arr, ...item.value], [])
  const minValue = Math.min(...listValues)
  const maxValue = Math.max(...listValues)
  const tickValues = getRoundedTickValues(
    [minValue, maxValue],
    SETTINGS.yTickNum,
    true,
  )

  const contributionTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <table>
          <tbody>
            <tr className={style.trTable}>
              <td className={style.pdLabel}>
                <div className={style.mr12}>
                  <Span style={{ fontSize: 11 }}>{valueTooltip.name}</Span>:
                </div>
              </td>
              <td
                style={{
                  textAlign: 'right',
                }}
                className={`${style.pdLabel} ${style.fontWeight500}`}
              >
                <Span
                  style={{
                    fontSize: 11,
                    color: valueAtPoint(valueTooltip) < 0 ? '#ff4752' : '',
                  }}
                >
                  {valToPercent(valueAtPoint(valueTooltip))}
                </Span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            isShowXAxis
            isUseXAxisDiv
            showAllTick
            showAllTickInTwoLines
            yAxis={[
              {
                id: 'left',
                orientation: 'left',
                keys: [barKey],
                isBarChart: true,
                tickNum: SETTINGS.yTickNum,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                tickFormatter: (val) =>
                  formatVal(valToPercent(val, false, true)),
                unitYAxis: '%',
                tickValues,
              },
            ]}
            renderCustomTooltip={(payload) => contributionTooltip(payload)}
          >
            <Bar
              yAxisId={'left'}
              dataKey={barKey}
              barSize={getColumnSizeInBarChart(width, data.length, 25)}
              isAnimationActive={false}
            >
              {data.map((item) => (
                <Cell
                  key={item.cpivnTypeId}
                  fill={valueAtPoint(item) > 0 ? BLUE_COLOR_BAR : RED_COLOR_BAR}
                />
              ))}
            </Bar>
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

export default ChartComponent
