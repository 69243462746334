import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DEFAULT_CHECKED } from '../config'
import {
  defineDropDownYoY,
  defineGroupColumns,
  findMinMonthYear,
  getYearMonthNotDuplicate,
  handleIds,
  initialCheckboxChecked,
  initLineColor,
  keyByArray,
} from '../helper'
import { getMonetaryTypeName, getMoneySupplyOutstandingLoan } from './thunk'

const initialState = {
  valueMOM: 'Value',
  checkboxChecked: {},
  tableDataLoading: true,
  tableMappingLoading: true,
  tableData: [],
  tableMapping: [],
  tableDataById: {},
  isFirstTime: true,
  minMonthYear: { minYear: 0, minMonth: 0 },
  isAllowCallApi: true,
  groupColumns: [],
  lineChartColor: [],
  ids: [],
  dropdownOptions: [],
  dropdownValue: '',
  indexChecked: DEFAULT_CHECKED,
}

const slice = createSlice({
  name: 'economy/monetary/moneySuppliesCredit/panelMoneySupplyCredit',
  initialState,
  reducers: {
    handleValueMOM: (state, action) => {
      state.valueMOM = action.payload
    },
    handleCheckboxChecked: (state, action) => {
      state.checkboxChecked = action.payload
    },
    handleIsFirstTime: (state, action) => {
      state.isFirstTime = action.payload
    },
    handleLineChartColor: (state, action) => {
      state.lineChartColor = action.payload
    },
    handleDropdownValue: (state, action) => {
      state.dropdownValue = action.payload
    },
    handleIsAllowCallApi: (state, action) => {
      state.isAllowCallApi = action.payload
    },
    handleIndexChecked: (state, action) => {
      state.indexChecked = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMoneySupplyOutstandingLoan.pending, (state) => {
      state.tableDataLoading = true
    })
    builder.addCase(
      getMoneySupplyOutstandingLoan.fulfilled,
      (state, action) => {
        const data = action.payload || []
        state.tableDataLoading = false
        const monthYear = getYearMonthNotDuplicate(data)
        state.groupColumns = defineGroupColumns(
          monthYear,
          state.groupColumns,
          action.meta.arg.NumberOfPeriod,
        )
        state.tableData = data
        state.tableDataById = keyByArray(
          handleIds(data),
          'id',
          undefined,
          state.tableDataById,
          action.meta.arg.NumberOfPeriod,
        )
        state.minMonthYear = findMinMonthYear(data)
        if (!data?.length) {
          state.isAllowCallApi = false
        }
      },
    )
    builder.addCase(getMoneySupplyOutstandingLoan.rejected, (state, action) => {
      state.tableDataLoading = action.payload.isLoading
    })
    builder.addCase(getMonetaryTypeName.pending, (state) => {
      state.tableMappingLoading = true
    })
    builder.addCase(getMonetaryTypeName.fulfilled, (state, action) => {
      const data = action.payload || []
      state.tableMappingLoading = false
      state.ids = handleIds(data)
      state.tableMapping = data
      state.checkboxChecked = initialCheckboxChecked(
        handleIds(data),
        state.indexChecked,
      )
      state.lineChartColor = initLineColor(handleIds(data), state.indexChecked)
      state.dropdownOptions = defineDropDownYoY(data)
      if (state.dropdownOptions.length) {
        state.dropdownValue = state.dropdownOptions[0].value
      }
    })
    builder.addCase(getMonetaryTypeName.rejected, (state, action) => {
      state.tableMappingLoading = action.payload.isLoading
    })
  },
})

export const {
  handleValueMOM,
  handleCheckboxChecked,
  handleIsFirstTime,
  handleLineChartColor,
  handleDropdownValue,
  handleIsAllowCallApi,
  handleIndexChecked,
} = slice.actions

export const getValueMOM = (state) => state[slice.name].valueMOM
export const getCheckboxChecked = (state) => state[slice.name].checkboxChecked
export const getTableDataLoading = (state) => state[slice.name].tableDataLoading
export const getTableMappingLoading = (state) =>
  state[slice.name].tableMappingLoading
export const getTableData = (state) => state[slice.name].tableData
export const getTableMappingData = (state) => state[slice.name].tableMapping
export const getMoneySupplyValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].tableDataById[id], attr)
export const getTableDataById = (state) => state[slice.name].tableDataById
export const getIds = (state) => state[slice.name].ids
export const getGroupColumns = (state) => state[slice.name].groupColumns
export const getIsFirsTime = (state) => state[slice.name].isFirstTime
export const getMinMonthYear = (state) => state[slice.name].minMonthYear
export const getIsAllowCallApi = (state) => state[slice.name].isAllowCallApi
export const getLineChartColor = (state) => state[slice.name].lineChartColor
export const getDropdownOptions = (state) => state[slice.name].dropdownOptions
export const getDropDownValue = (state) => state[slice.name].dropdownValue
export const getIndexChecked = (state) => state[slice.name].indexChecked

register(slice.name, slice.reducer)
