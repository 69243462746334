import PropTypes from 'prop-types'
import style from './index.module.css'

export const DropdownItem = ({ value, isCurrentItem }) => {
  return (
    <div
      className={`${style.dropdownItem} ${isCurrentItem && style.currentItem}`}
    >
      {value && (
        <span className={style.w100}>
          {value.ticker}-
          {value?.organizationShortName ?? value?.organizationName ?? ''}
        </span>
      )}
    </div>
  )
}

DropdownItem.propTypes = {
  value: PropTypes.object.isRequired,
  isCurrentItem: PropTypes.bool.isRequired,
}
