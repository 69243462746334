import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../common/textEllipsis'
import { keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'

const NameChecked = ({ exchanges, listParentChecked, exchangeTreeLength }) => {
  const [exchangesById, setExchangesById] = useState({})
  useEffect(() => {
    setExchangesById(keyBy(exchanges, 'groupId'))
  }, [exchanges])

  return (
    <div className={style.w85}>
      {listParentChecked?.length > 0 &&
      exchangeTreeLength === listParentChecked?.length ? (
        <Translate value="tool.smartScreening.ALL_EXCHANGE" />
      ) : listParentChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listParentChecked
            .map((key) => exchangesById[key]?.groupName)
            .join('; ')}
        />
      ) : (
        <span className={style.opacity0}>
          <Translate value="tool.smartScreening.ALL_EXCHANGE" />
        </span>
      )}
    </div>
  )
}

export default NameChecked
