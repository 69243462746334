export const valueLevel = {
  Level1: 1,
  Level2: 2,
  Level3: 3,
}

export const listLevel = [
  {
    name: 'bond.interconnectionMap.chart.filter.Level1',
    value: valueLevel.Level1,
  },
  {
    name: 'bond.interconnectionMap.chart.filter.Level2',
    value: valueLevel.Level2,
  },
  {
    name: 'bond.interconnectionMap.chart.filter.Level3',
    value: valueLevel.Level3,
  },
]
