import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../../common/html/Span'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { Table } from '../../../../../../common/table'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { SelectType } from '../../../common/SelectType'
import { quarterTypeOptions, tableYearOptions } from '../../../constants'
import { getQuarterYearParams } from '../../../helps'
import { getTableSchema } from './helper'
import {
  changeQuarterType,
  changeRetryFlag,
  changeYearType,
  selectDataByIds,
  selectIds,
  selectQuarterType,
  selectRetryFlag,
  selectTableLoading,
  selectYearType,
  sort,
} from './store/slice'
import { getDataTable } from './store/thunk'

export const DataTable = ({ dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const ids = useSelector(selectIds)
  const loading = useSelector(selectTableLoading)
  const retryFlag = useSelector(selectRetryFlag)
  const yearType = useSelector(selectYearType)
  const quarterType = useSelector(selectQuarterType)

  // Use state
  const [isResize, setIsResize] = useState(false)

  // Actions
  const onChangeYear = (value) => {
    dispatch(changeYearType(value))
  }

  const onChangeQuarterType = (item) => {
    dispatch(changeQuarterType(item.value))
  }

  // Use effect
  useEffect(() => {
    if (sectorInfo && sectorInfo.icbId && retryFlag) {
      const paramsArr = getQuarterYearParams(5, true).map(
        ({ year, quarter }) => ({
          IcbId: sectorInfo.icbId,
          ExchangeCode: dataType,
          Year: year,
          Quarter: quarter,
        }),
      )
      dispatch(getDataTable(paramsArr))
    }
  }, [sectorInfo, retryFlag])

  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId || retryFlag) return
    dispatch(
      getDataTable({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        Year: yearType,
        Quarter: quarterType,
      }),
    )
  }, [yearType, quarterType, sectorInfo])

  useEffect(() => {
    dispatch(changeRetryFlag(true))
  }, [dataType])

  useEffect(() => {
    setIsResize((old) => !old)
  }, [ids])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="align-center j-b mb-8">
            <div className="d-flex mr-8">
              <SelectType
                options={tableYearOptions}
                activeTab={yearType}
                onChangeTab={onChangeYear}
                style={{ marginRight: 8, width: 86 }}
              />
              <DispatchActionTab
                data={quarterTypeOptions}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={quarterType}
                onChangeTab={onChangeQuarterType}
              />
            </div>
            <Span style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}>
              <Translate value="sector.financialAnalysis.common.UNIT_BILLION_VND" />
            </Span>
          </div>

          {size.height && (
            <div style={{ height: `calc(100% - ${size.height}px)` }}>
              {loading ? (
                <Loading />
              ) : !ids.length ? (
                <NoData />
              ) : (
                <Table
                  ids={ids}
                  getDataFromRedux={selectDataByIds}
                  isLoading={loading}
                  schema={getTableSchema()}
                  stickyFirstColumn={true}
                  hasFooter={false}
                  reCalcWidths={isResize}
                  sort={sort}
                />
              )}
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
