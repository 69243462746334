import PropTypes from 'prop-types'
import { useEffect, useReducer, useRef, useState } from 'react'
import { HandleClickOutside } from '../../../HandleClickOutside'
import { ScrollComponent } from '../../../ScrollComponent'
import Dropdown from '../../../dropdown'
import { Input } from '../../../html/Input'
import { Span } from '../../../html/Span'
import { Loading } from '../../../loading'
import { NoData } from '../../../noData'
import DefaultDropdownItem from '../DefaultDropdownItem'
import style from './index.module.css'

const ACTION_EVENT = {
  KEYUP: 'ArrowUp',
  KEYDOWN: 'ArrowDown',
  ENTER: 'Enter',
  SELECT: 'select',
}

const currentItemReducer = (state, action) => {
  const options = action?.payload?.options
  const index = action?.payload?.index

  switch (action.type) {
    case ACTION_EVENT.KEYUP:
      return state === options[0] ? state : options[index - 1]
    case ACTION_EVENT.KEYDOWN:
      return state === options[options.length - 1] ? state : options[index + 1]
    case ACTION_EVENT.SELECT:
      return action.data
    default:
      return state
  }
}

const SearchBox = ({
  title,
  description,
  onFocus,
  onTextChange,
  onSelect,
  onHideDropSearch,
  options,
  renderOption,
  loading,
  className,
  classNameInputSearch,
  onChangeLoading,
  bgColorScroll,
  scrollToBottomCallback,
}) => {
  const [isShow, setIsShow] = useState(false)
  const [isPressEnter, setIsPressEnter] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [element] = useState(() => document.createElement('div'))
  const [currentItem, dispatchCurrentItem] = useReducer(currentItemReducer, {})

  // Use ref
  const parentDropdownRef = useRef()
  const tmpRef = useRef({ timeout: null })

  // Actions
  const showDropSearch = () => {
    setIsShow(true)
    document.body.appendChild(element)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    document.body.removeChild(element)
    parentDropdownRef.current.value = ''
    dispatchCurrentItem({
      type: ACTION_EVENT.SELECT,
      data: options[0],
    })
    onHideDropSearch && onHideDropSearch()
  }

  const handleTextChange = (e) => {
    onChangeLoading && onChangeLoading()
    setSearchText(e.target.value)
  }

  const handlePressKey = (event) => {
    const index = options.indexOf(currentItem)
    const isEnter = event.key === ACTION_EVENT.ENTER
    const isUp = event.key === ACTION_EVENT.KEYUP
    const isDown = event.key === ACTION_EVENT.KEYDOWN

    if (isUp || isDown) {
      dispatchCurrentItem({
        type: event.key,
        payload: { index, options },
      })
    }

    if (isEnter) {
      if (!loading && options.length) {
        onSelect(currentItem.value)
        hideDropSearch()
      } else {
        setIsPressEnter(true)
      }
    }
  }

  const handleInputFocus = (e) => {
    setSearchText('')
    setIsPressEnter(false)
    onClearTimeout()
    onFocus && onFocus(e)
  }

  const onClearTimeout = () => {
    clearTimeout(tmpRef.current.timeout)
    tmpRef.current.timeout = null
  }

  // Use effect
  useEffect(() => {
    if (options.length > 0) {
      dispatchCurrentItem({
        type: ACTION_EVENT.SELECT,
        data: options[0],
      })
    }
  }, [options])

  useEffect(() => {
    if (tmpRef.current.timeout) {
      onClearTimeout()
    }

    tmpRef.current.timeout = setTimeout(() => {
      onTextChange(searchText)
      onClearTimeout()
    }, 500)
  }, [searchText])

  useEffect(() => {
    if (!loading && options.length && isPressEnter) {
      hideDropSearch()
      onSelect(options[0].value)
      setIsPressEnter(false)
    }
  }, [loading, options])

  return (
    <div className={['search-box', classNameInputSearch].join(' ')}>
      {isShow ? (
        <Input
          type="text"
          autoFocus
          ref={parentDropdownRef}
          className={[style.inputSearch, 'input-search'].join(' ')}
          style={{ fontSize: 17 }}
          onChange={handleTextChange}
          onKeyDown={handlePressKey}
          onFocus={handleInputFocus}
        />
      ) : (
        <div
          ref={parentDropdownRef}
          className={style.searchBoxText}
          onClick={showDropSearch}
        >
          <Span className="search-title" style={{ fontSize: 17 }}>
            {title}
          </Span>
          <div
            className={[
              style.description,
              style.mb2,
              'search-description',
            ].join(' ')}
          >
            {description}
          </div>
        </div>
      )}

      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside handleClickOutside={hideDropSearch}>
          <div
            id="topInfoDropSearch"
            className={`${style.dropSearch} ${className}`}
            onClick={hideDropSearch}
          >
            <ScrollComponent
              autoHeight={true}
              autoHeightMax={253}
              autoHeightMin={30}
              verticalTrackWidth={5}
              horizontalTrackWidth={5}
              bgColor={bgColorScroll}
              scrollToBottomCallback={scrollToBottomCallback}
            >
              {loading && <Loading />}

              {options.length > 0
                ? options.map((item) => {
                    const isCurrentItem = currentItem?.id === item.id
                    return (
                      <div
                        key={item.id}
                        onClick={() => onSelect(item.value)}
                        className={loading ? style.dropdownItemLoadingBlur : ''}
                      >
                        <DetectScroll isActive={isCurrentItem} />
                        {renderOption ? (
                          renderOption(item.value, isCurrentItem)
                        ) : (
                          <DefaultDropdownItem
                            label={item?.label || ''}
                            isCurrentItem={isCurrentItem}
                          />
                        )}
                      </div>
                    )
                  })
                : !loading && <NoData />}
            </ScrollComponent>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

const DetectScroll = ({ isActive }) => {
  const itemRef = useRef()
  useEffect(() => {
    if (isActive) {
      document
        .querySelector('#topInfoDropSearch .scrollbars div:first-child')
        .scroll({ top: itemRef.current?.offsetTop - 5 })
    }
  }, [isActive])
  return <div ref={itemRef} />
}

SearchBox.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onFocus: PropTypes.func,
  onTextChange: PropTypes.func,
  onSelect: PropTypes.func,
  onHideDropSearch: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
      value: PropTypes.object.isRequired,
    }),
  ).isRequired,
  renderOption: PropTypes.func,
  loading: PropTypes.bool.isRequired,
  className: PropTypes.string,
  classNameInputSearch: PropTypes.string,
  onChangeLoading: PropTypes.func,
}

SearchBox.defaultProps = {
  title: '',
  description: '',
  options: [],
  loading: false,
  className: '',
  classNameInputSearch: '',
}

export default SearchBox
