import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/corporate/OverviewService'
import axios from 'axios'

export const getFinancialMetricsComparisonChartData = createAsyncThunk(
  'corporate/overview/peersComparison/GET_FINANCIAL_METRICS_COMPARISON_CHART_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await OverviewService.getFinancialMetricsComparisonChartData(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ isLoading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
