import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import InputFormatCurrency from '../../../../common/input/InputFormatCurrency'
import Popup from '../../../../common/popup'
import SelectCustom from '../../../../common/selectCustom'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  FORMAT,
  formatDateTime,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'
import {
  BOND_DETAIL_OPTIONS,
  BOND_DETAIL_TYPES,
  COUPON_OPTIONS,
  COUPON_TYPES,
  INTEREST_PAYMENT_PERIOD_OPTIONS,
  INTEREST_PAYMENT_PERIOD_TYPES,
} from './constants'
import style from './index.module.css'

const defaultData = {
  ticker: '',
  bondTypeId: BOND_DETAIL_TYPES.CORPORATE_BOND,
  organizationId: null,
  issueDateId: null,
  maturityDateId: null,
  parValue: '',
  durationDay: '',
  paymentCalendarId: INTEREST_PAYMENT_PERIOD_TYPES.ONE_MONTH,
  couponTypeId: COUPON_TYPES.FIXED,
  interestRate: '',
}

const defaultError = {
  ticker: '',
  organizationId: '',
  issueDateId: '',
  maturityDateId: '',
  parValue: '',
  interestRate: '',
}

const messageRequired = 'bond.bondScreening.createBond.MESSAGE_REQUIRED'

const PopupUpdateBond = ({ handleClose, handleSubmit, item }) => {
  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)

  const [data, setData] = useState(defaultData)
  const [error, setError] = useState(defaultError)

  const customHeader = HeaderOneCalendarRange({
    startYear: 2010,
    endYear: new Date(
      new Date().setFullYear(new Date().getFullYear() + 30),
    ).getFullYear(),
  })

  const getDateWithTimezone = (date) =>
    new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))

  const validateIssueDate = () => {
    if (!data.issueDateId) {
      setError((prev) => ({
        ...prev,
        issueDateId: messageRequired,
      }))

      return false
    }

    if (data.maturityDateId) {
      const years = moment(data.maturityDateId).diff(
        data.issueDateId,
        'years',
        true,
      )

      if (years > 30) {
        setError((prev) => ({
          ...prev,
          issueDateId: 'bond.bondScreening.createBond.DATE_MAX',
        }))

        return false
      }
    }

    return true
  }

  const handleChangeIssueDate = (value) => {
    setData((prev) => ({ ...prev, issueDateId: value }))
    setError((prev) => ({
      ...prev,
      issueDateId: '',
    }))
  }

  const validateMaturityDate = () => {
    if (!data.maturityDateId) {
      setError((prev) => ({
        ...prev,
        maturityDateId: messageRequired,
      }))

      return false
    }

    if (data.issueDateId) {
      const years = moment(data.maturityDateId).diff(
        data.issueDateId,
        'years',
        true,
      )

      if (years > 30) {
        setError((prev) => ({
          ...prev,
          maturityDateId: 'bond.bondScreening.createBond.DATE_MAX',
        }))

        return false
      }
    }

    return true
  }

  const handleChangeMaturityDate = (value) => {
    setData((prev) => ({ ...prev, maturityDateId: value }))
    setError((prev) => ({
      ...prev,
      maturityDateId: '',
    }))
  }

  const handleChangeParValue = () => {
    setError((prev) => ({
      ...prev,
      parValue: '',
    }))
  }

  const validateParValue = (value) => {
    setData((prev) => ({ ...prev, parValue: value }))

    if (!value.length) {
      setError((prev) => ({
        ...prev,
        parValue: messageRequired,
      }))

      return false
    }

    if (parseInt(value) > 10000000000) {
      setError((prev) => ({
        ...prev,
        parValue: 'bond.bondScreening.createBond.PAR_VALUE_MAX',
      }))

      return false
    }

    return true
  }

  const handleChangeInterestPayment = (value) => {
    setData((prev) => ({ ...prev, paymentCalendarId: value }))
  }

  const handleChangeCouponType = (value) => {
    setData((prev) => ({ ...prev, couponTypeId: value }))
  }

  const handleChangeInterestRate = () => {
    setError((prev) => ({
      ...prev,
      interestRate: '',
    }))
  }

  const validateInterestRate = (value) => {
    setData((prev) => ({ ...prev, interestRate: value }))

    if (!value.length) {
      setError((prev) => ({
        ...prev,
        interestRate: messageRequired,
      }))

      return false
    }

    if (parseInt(value) > 100) {
      setError((prev) => ({
        ...prev,
        interestRate: 'bond.bondScreening.createBond.INTEREST_RATE_MAX',
      }))

      return false
    }

    return true
  }

  const handleSave = () => {
    setError(defaultError)

    const checkParValue = validateParValue(data.parValue)
    const checkIssueDate = validateIssueDate()
    const checkMaturityDate = validateMaturityDate()
    const checkInterestRate = validateInterestRate(data.interestRate)

    if (
      !checkParValue ||
      !checkIssueDate ||
      !checkMaturityDate ||
      !checkInterestRate
    )
      return

    const {
      interestRate,
      bondTypeId,
      organizationId,
      parValue,
      bondId,
      ...restData
    } = data

    const months = moment(data.maturityDateId).diff(
      data.issueDateId,
      'months',
      true,
    )

    const params = {
      ...restData,
      durationDay: parseInt(months),
      fixedInterestRate:
        restData.couponTypeId === COUPON_TYPES.FIXED
          ? parseFloat(formatVal(parseFloat(interestRate) / 100, 4))
          : null,
      floatInterestSpread:
        restData.couponTypeId === COUPON_TYPES.FLOAT
          ? parseFloat(formatVal(parseFloat(interestRate) / 100, 4))
          : null,
      parValue: parseInt(parValue),
      bondUserId: bondId,
    }

    handleSubmit(params)
  }

  const handleCancel = () => {
    setData(defaultData)
    setError(defaultError)
    handleClose()
  }

  const duration = useMemo(() => {
    if (!data.issueDateId || !data.maturityDateId) return ''

    const months = moment(data.maturityDateId).diff(
      data.issueDateId,
      'months',
      true,
    )

    if (months > 36)
      return I18n.t('bond.bondScreening.createBond.YEAR', {
        number: formatVal(months / 12, 2),
      })

    return I18n.t('bond.bondScreening.createBond.MONTH', {
      number: formatVal(months, 0),
    })
  }, [data.issueDateId, data.maturityDateId])

  const expected = useMemo(() => {
    if (!data.issueDateId || !data.maturityDateId) return ''

    const months = moment(data.maturityDateId).diff(data.issueDateId, 'months')

    switch (data.paymentCalendarId) {
      case INTEREST_PAYMENT_PERIOD_TYPES.THREE_MONTHS:
        return formatVal(months / 3, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.SIX_MONTHS:
        return formatVal(months / 6, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.TWELVE_MONTHS:
        return formatVal(months / 12, 0)
      case INTEREST_PAYMENT_PERIOD_TYPES.MATURITY_DATE:
        return '1'
      default:
        return formatVal(months, 0)
    }
  }, [data.issueDateId, data.maturityDateId, data.paymentCalendarId])

  const renderError = (message) => (
    <div style={{ margin: '-10px 0' }}>
      <Span
        style={{
          fontStyle: 'italic',
          color: '#ff2b66',
          fontSize: 11,
        }}
      >
        <Translate value={message} />
      </Span>
    </div>
  )

  useEffect(() => {
    if (!item) return

    const {
      fixedInterestRate,
      floatInterestSpread,
      parValue,
      bondTicker,
      ticker,
      bondName,
      en_OrganizationShortName,
      organizationShortName,
      paymentCalendarMonth,
      ...restItem
    } = item

    setData({
      ...restItem,
      interestRate: fixedInterestRate
        ? (fixedInterestRate * 100).toString()
        : floatInterestSpread
        ? (floatInterestSpread * 100).toString()
        : '',
      parValue: parValue?.toString() ?? '',
      ticker: bondTicker?.toUpperCase() ?? ticker?.toUpperCase() ?? '',
    })
  }, [item])

  return (
    <Popup>
      <div className="modal modal-small" style={{ width: 502 }}>
        <div className="modal-title">
          <h3>
            <Span style={{ textTransform: 'uppercase' }}>
              <Translate value="bond.bondScreening.createBond.CREATE_TITLE" />
            </Span>
          </h3>
          <a onClick={handleClose} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content" style={{ padding: 0 }}>
          <div className="content" style={{ padding: 16 }}>
            <div className="flex-column" style={{ gap: 16 }}>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.BOND_TICKER" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>
                    <TextEllipsis text={data.ticker} isI18n={false} />
                  </Span>
                </div>
              </div>
              {!!error.ticker.length && renderError(error.ticker)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.BOND_TYPE" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>
                    <TextEllipsis
                      text={
                        BOND_DETAIL_OPTIONS.find(
                          (item) => item.value === data.bondTypeId,
                        )?.name ?? ''
                      }
                    />
                  </Span>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUER" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>
                    <TextEllipsis
                      text={
                        locale === 'vi'
                          ? item?.organizationShortName
                          : item?.en_OrganizationShortName ?? ''
                      }
                      appendStyle={{ width: '100%' }}
                    />
                  </Span>
                </div>
              </div>
              {!!error.organizationId.length &&
                renderError(error.organizationId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUE_DATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <div className="form-control calendar mb-0">
                      <div className={`${style.bgGrey} w-100`}>
                        <CalendarPopper
                          date={
                            data.issueDateId
                              ? getDateWithTimezone(data.issueDateId)
                              : null
                          }
                          handleChange={(value) =>
                            handleChangeIssueDate(
                              !!value?.length
                                ? getISOStartOrEndOfDay(value, timeZone, true)
                                : null,
                            )
                          }
                          customHeader={customHeader}
                          CustomInput={InputDateCalendar}
                          popperClassName={style.popperClassNameLeft}
                          onClose={validateIssueDate}
                          excludeDateIntervals={
                            data.maturityDateId
                              ? [
                                  {
                                    start: new Date(
                                      moment(data.maturityDateId)
                                        .subtract(1, 'days')
                                        .format('YYYY-MM-DD'),
                                    ),
                                    end: new Date(
                                      `${new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() + 30,
                                        ),
                                      ).getFullYear()}-12-31`,
                                    ),
                                  },
                                ]
                              : undefined
                          }
                          placeholder="dd/mm /yyyy"
                        />
                      </div>
                      <Icon className="icon-date" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                </div>
              </div>
              {!!error.issueDateId.length && renderError(error.issueDateId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.MATURITY_DATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <div className="form-control calendar mb-0">
                      <div className={`${style.bgGrey} w-100`}>
                        <CalendarPopper
                          date={
                            data.maturityDateId
                              ? getDateWithTimezone(data.maturityDateId)
                              : null
                          }
                          handleChange={(value) =>
                            handleChangeMaturityDate(
                              !!value?.length
                                ? getISOStartOrEndOfDay(value, timeZone, true)
                                : null,
                            )
                          }
                          customHeader={customHeader}
                          CustomInput={InputDateCalendar}
                          popperClassName={style.popperClassNameLeft}
                          onClose={validateMaturityDate}
                          excludeDateIntervals={
                            data.issueDateId
                              ? [
                                  {
                                    start: new Date('1999-12-31'),
                                    end: new Date(
                                      moment(data.issueDateId).format(
                                        'YYYY-MM-DD',
                                      ),
                                    ),
                                  },
                                ]
                              : undefined
                          }
                          placeholder="dd/mm /yyyy"
                        />
                      </div>
                      <Icon className="icon-date" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                </div>
              </div>
              {!!error.maturityDateId.length &&
                renderError(error.maturityDateId)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.PAR_VALUE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <InputFormatCurrency
                      className={`${style.input} ${style.inputBackground}`}
                      handleBlur={(value) => validateParValue(value)}
                      defaultValue={data.parValue}
                      fractionDigits={0}
                      style={{ width: '100%' }}
                      decimalRender={0}
                      regexValueCustom={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                      handleChange={handleChangeParValue}
                    />
                  </div>
                </div>
              </div>
              {!!error.parValue.length && renderError(error.parValue)}
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate
                    value="bond.bondScreening.createBond.INTEREST_PAYMENT_PERIOD"
                    unit={
                      data.paymentCalendarId !==
                      INTEREST_PAYMENT_PERIOD_TYPES.MATURITY_DATE
                        ? I18n.t(
                            'bond.bondScreening.createBond.INTEREST_PAYMENT_PERIOD_UNIT',
                          )
                        : ''
                    }
                  />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <SelectCustom
                      value={data.paymentCalendarId}
                      selectData={INTEREST_PAYMENT_PERIOD_OPTIONS}
                      handleChange={handleChangeInterestPayment}
                      appendStyle={{ fontWeight: 340 }}
                      isI18n
                    />
                  </div>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.EXPECTED" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>{expected}</Span>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.ISSUE_PERIOD" />
                </Span>
                <div style={{ width: 240 }}>
                  <Span style={{ fontWeight: 340 }}>{duration}</Span>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.COUPON_TYPE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <SelectCustom
                      value={data.couponTypeId}
                      selectData={COUPON_OPTIONS}
                      handleChange={handleChangeCouponType}
                      appendStyle={{ fontWeight: 340 }}
                      isI18n
                    />
                  </div>
                </div>
              </div>
              <div
                className="d-flex ali-center"
                style={{ gap: 8, minHeight: 20 }}
              >
                <Span style={{ fontWeight: 600, flex: 1 }}>
                  <Translate value="bond.bondScreening.createBond.COUPON_INTEREST_RATE" />
                </Span>
                <div style={{ width: 240 }}>
                  <div style={{ width: 160 }}>
                    <InputFormatCurrency
                      className={`${style.input} ${style.inputBackground}`}
                      handleBlur={(value) => validateInterestRate(value)}
                      handleChange={handleChangeInterestRate}
                      defaultValue={data.interestRate}
                      fractionDigits={2}
                      style={{ width: '100%' }}
                      decimalRender={2}
                      regexValueCustom={/^[+]?[0-9]*(?:\.[0-9]*)?$/}
                      suffix="%"
                    />
                  </div>
                </div>
              </div>
              {!!error.interestRate.length && renderError(error.interestRate)}
              <div
                className={style.groupBtn}
                style={{ minHeight: 20, padding: 0 }}
              >
                <div className="d-flex ali-center" style={{ gap: 8 }}>
                  <Button
                    onClick={handleCancel}
                    className={`btn normal w-80 h-20`}
                    style={{ fontWeight: 600, flex: 1 }}
                  >
                    <Translate value="common.button.BUTTON_CANCEL" />
                  </Button>
                  <Button
                    onClick={handleSave}
                    className={`btn btn-blue w-80 h-20 `}
                    style={{ fontWeight: 600, flex: 1 }}
                  >
                    <Translate value="common.button.BUTTON_SAVE" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default PopupUpdateBond
