import { SizeTracker } from '../../../../../common/sizeTracker'
import { PANEL_PADDING } from '../../../../../constants/Common'
import { FilterPeriod } from './FilterPeriod'
import { FilterRatio } from './FilterRatio'
import { FilterTimeOptions } from './FilterTimeOptions'

export const Filter = () => {
  return (
    <div className="flex-column j-b h-100">
      <SizeTracker>
        {(size) => (
          <>
            <FilterTimeOptions />
            <FilterPeriod />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height + PANEL_PADDING}px)`,
                }}
              >
                <FilterRatio />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}
