import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { COMPOSITION_SECTOR_VALUE, HEADER_VALUE } from '../../../constants'
import { getYearlyQuarterly } from '../../panelTotalGDP/store/slice'
import { getNominalRealValue } from '../../store/slice'
import { getTypeNameById } from '../helper'
import {
  getDataTypeName,
  getDataTypeNameLoading,
  getLimitTimeLoading,
  getPieChartData,
  getPieChartLoading,
  getRadioValue,
  getTimeRangeAccValue,
  getTimeRangeValue,
  getYear,
} from '../store/slice'
import { getGDPCompositionBySectorData, getGDPTypeName } from '../store/thunk'
import { GDP_COMPOSITION_LEFT_CHILD } from './PanelGDPCompositionLeftChild'
import PieChartVerticalFooter from './PieChartVerticalFooter'

const GDPCompositionLeftChild = ({ height, width }) => {
  const dispatch = useDispatch()
  const { NOMINAL } = HEADER_VALUE
  const { VALUE } = COMPOSITION_SECTOR_VALUE

  const pieChartLoading = useSelector(getPieChartLoading)
  const GDPTypeNameLoading = useSelector(getDataTypeNameLoading)
  const pieChartData = useSelector(getPieChartData)
  const timeRangeValue = useSelector(getTimeRangeValue)
  const timeRangeAccValue = useSelector(getTimeRangeAccValue)
  const year = useSelector(getYear)
  const nominalRealValue = useSelector(getNominalRealValue)
  const radioValue = useSelector(getRadioValue)
  const dataTypeName = useSelector(getDataTypeName)
  const locale = useSelector((state) => state.i18n.locale)
  const yearlyQuarterlyOption = useSelector(getYearlyQuarterly)
  const limitTimeLoading = useSelector(getLimitTimeLoading)

  const timeRange = radioValue === VALUE ? timeRangeValue : timeRangeAccValue
  const dataWithTypeName = getTypeNameById(pieChartData, dataTypeName, locale)

  const handleTimeFrequency = (radioValue, timeRange) => {
    if (radioValue === VALUE && timeRange === '5') {
      return 'Yearly'
    }

    if (radioValue !== VALUE) {
      return 'AccQuarterly'
    }

    return 'Quarterly'
  }

  useEffect(() => {
    if (!limitTimeLoading) {
      dispatch(
        getGDPCompositionBySectorData({
          Quarter: timeRange,
          Year: year,
          IsNominal: nominalRealValue === NOMINAL,
          IsValue: radioValue === VALUE,
        }),
      )
    }
  }, [
    timeRangeValue,
    timeRangeAccValue,
    year,
    nominalRealValue,
    radioValue,
    locale,
    limitTimeLoading,
  ])

  useEffect(() => {
    dispatch(
      getGDPTypeName({
        TimeFrequency: handleTimeFrequency(radioValue, timeRange),
        IsNominal: nominalRealValue === NOMINAL,
        IsValue: nominalRealValue !== NOMINAL ? true : radioValue === VALUE,
      }),
    )
  }, [
    locale,
    nominalRealValue,
    radioValue,
    timeRangeValue,
    timeRangeAccValue,
    yearlyQuarterlyOption,
  ])

  return (
    <div className="h-100">
      {(pieChartLoading || GDPTypeNameLoading || limitTimeLoading) && (
        <Loading />
      )}
      {!pieChartLoading && !GDPTypeNameLoading && !limitTimeLoading && (
        <>
          {(pieChartData.length === 0 || dataTypeName.length === 0) && (
            <NoData />
          )}
          {pieChartData.length > 0 && dataTypeName.length > 0 && (
            <SizeTracker>
              {(size) => {
                return (
                  <div id={GDP_COMPOSITION_LEFT_CHILD}>
                    {(size.height || size.height === 0) && (
                      <div
                        style={{
                          position: 'relative',
                          height: height - size.height,
                        }}
                      >
                        <PieChartVerticalFooter
                          data={dataWithTypeName}
                          height={height - size.height}
                          width={width}
                        />
                      </div>
                    )}
                  </div>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

export default GDPCompositionLeftChild
