export const stickyColumnsConstants = ['DATE']

export const stickyColumnsKey = {
  DATE: 'date',
}

export const stickyColumnsMappingDisplay = {
  DATE: 'financialChart.table.DATE',
}

export const widthStickyColumn = {
  DATE: 110,
  OTHER: 180,
}
