import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { LIST_TYPE } from '../../constant'
import { LOADING_TYPE, selectLoading } from '../../store/slice'
import style from './company.module.css'
import SelectExchange from './SelectExchange'
import SelectMarketCap from './SelectMarketCap'
import TickerName from './TickerName'

const SelectedTicker = ({
  organizations,
  setOrganizations,
  filteredOrgIds,
  setFilteredOrgIds,
  groups,
  setGroups,
  exchanges,
  setExchanges,
  marketCap,
  setMarketCap,
  checkedOrgIds,
  setCheckedOrgIds,
}) => {
  const loading = useSelector(selectLoading(LOADING_TYPE.ADD_GROUP))

  const onCheckAllChange = (e) => {
    const checked = e.target.checked
    if (checked) {
      setCheckedOrgIds(
        filteredOrgIds.reduce((o, key) => ({ ...o, [key]: true }), {}),
      )
    } else {
      setCheckedOrgIds({})
    }
  }

  const onCheckboxChange = (e, orgId) => {
    const checked = e.target.checked
    if (checked) {
      setCheckedOrgIds({ ...checkedOrgIds, [orgId]: true })
    } else {
      setCheckedOrgIds({ ...checkedOrgIds, [orgId]: false })
    }
  }

  const handleDelete = () => {
    let newGroups = JSON.parse(JSON.stringify(groups))
    let newOrganizations = JSON.parse(JSON.stringify(organizations))

    const newFilteredOrgIds = filteredOrgIds.filter(
      (orgId) => !checkedOrgIds[orgId],
    )

    const deleteGroups = Object.keys(checkedOrgIds)
      .map((orgId) => ({
        type: newOrganizations[orgId]?.type,
        id: newOrganizations[orgId]?.typeId,
      }))
      .filter(
        (value, index, self) =>
          index ===
          self.findIndex((e) => e.type === value.type && e.id === value.id),
      )

    Object.keys(checkedOrgIds).forEach((orgId) => {
      delete newOrganizations[orgId]
    })

    deleteGroups.forEach((group) => {
      if (
        group.type !== LIST_TYPE.COMPANY &&
        Object.values(newOrganizations).every(
          (org) => !(org.type === group.type && org.typeId === group.id),
        )
      ) {
        newGroups = newGroups.filter(
          (g) => !(g.type === group.type && g.id === group.id),
        )
        Object.keys(newOrganizations).forEach((orgId) => {
          const org = newOrganizations[orgId]
          if (org.prevGroups?.length) {
            const groupIndex = org.prevGroups.findIndex(
              (g) => g.type === group.type && g.id === group.id,
            )
            if (groupIndex !== -1) {
              org.prevGroups.splice(groupIndex, 1)
            }
          }
        })
      }
    })

    newGroups = newGroups.filter((group) =>
      Object.values(newOrganizations).some(
        (org) =>
          (org.type === group.type && org.typeId === group.id) ||
          org?.prevGroups?.some(
            (g) => g.type === group.type && g.id === group.id,
          ),
      ),
    )

    setFilteredOrgIds(newFilteredOrgIds)
    setOrganizations(newOrganizations)
    setGroups(newGroups)
    setCheckedOrgIds({})
  }

  const renderList = () => {
    return (
      <ScrollComponent>
        <ul className={style.selectedTickerList}>
          {filteredOrgIds.map((orgId, index) => {
            return (
              <li key={orgId}>
                <div className={style.indexCol}>{index + 1}</div>
                <TickerName organizationId={orgId} />
                <div className={style.checkboxCol}>
                  <div className={style.checkBox}>
                    <input
                      type="checkbox"
                      className="checkbox line"
                      checked={!!checkedOrgIds[orgId]}
                      onChange={(e) => onCheckboxChange(e, orgId)}
                    />
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      </ScrollComponent>
    )
  }

  return (
    <div className={style.selectedTickers}>
      <div className={style.selectedTitle}>
        <Span style={{ opacity: 0.4, display: 'block' }}>
          <Translate value="sector.sectorConstituents.segmentation.SELECTED_ITEMS" />
        </Span>
      </div>
      <div className={style.selectedFilter}>
        <div className={style.selectExchangeMarketCap}>
          <SelectExchange exchanges={exchanges} setExchanges={setExchanges} />
          <SelectMarketCap marketCap={marketCap} setMarketCap={setMarketCap} />
        </div>
        <div className={style.selectTool}>
          <div className={style.checkBox}>
            <label>
              <input
                type="checkbox"
                className={`checkbox line`}
                checked={
                  Object.keys(checkedOrgIds).length > 0 &&
                  Object.keys(checkedOrgIds).length === filteredOrgIds.length
                }
                onChange={onCheckAllChange}
              />
            </label>
          </div>
          <div className={style.verticalLine} />
          <button className={style.deleteButton} onClick={handleDelete}>
            <i className="icon-trash" />
          </button>
        </div>
      </div>
      <div className={style.selectedTickerList}>
        {loading && <Loading />}
        {filteredOrgIds.length ? renderList() : <NoData />}
      </div>
    </div>
  )
}

export default SelectedTicker
