export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.DATE',
  },
  {
    key: 'orderMatchingParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.ORDER_MATCHING',
  },
  {
    key: 'totalMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalMatchValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'dealParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PUT_THROUGH',
  },
  {
    key: 'totalDealVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalDealValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'totalVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VOLUME',
  },
  {
    key: 'totalValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VALUE',
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.DATE',
  },
  {
    key: 'cwCode',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TICKER_TITLE',
  },
  {
    key: 'percentPriceChange',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PERCENT_CHANGE',
  },
  {
    key: 'underlyingAssetPrice',
    title:
      'market.coveredWarrant.cwStatistics.historicalPrice.UNDERLYING_ASSET',
  },
  {
    key: 'breakEvenPrice',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.BREAK_EVEN',
  },
  {
    key: 'avgPrice5Days',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.AVG_FIVE_DAYS',
  },
  {
    key: 'premiumPercentage',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PERCENT_PREMIUM',
  },
  {
    key: 'totalMatchParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.ORDER_MATCHING',
  },
  {
    key: 'totalMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalMatchValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'totalDealParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PUT_THROUGH',
  },
  {
    key: 'totalDealVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalDealValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'totalVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VOLUME',
  },
  {
    key: 'totalValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VALUE',
  },
]

export const GROUP_COLUMNS_TICKER = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.DATE',
  },
  {
    key: 'percentPriceChange',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PERCENT_CHANGE',
  },
  {
    key: 'openPriceAdjusted',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.OPEN',
  },
  {
    key: 'highPriceAdjusted',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.HIGHEST',
  },
  {
    key: 'lowPriceAdjusted',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.LOWEST',
  },
  {
    key: 'closePriceAdjusted',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.CLOSE',
  },
  {
    key: 'totalMatchParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.ORDER_MATCHING',
  },
  {
    key: 'totalMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalMatchValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'totalDealParent',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.PUT_THROUGH',
  },
  {
    key: 'totalDealVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VOLUME',
  },
  {
    key: 'totalDealValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.VALUE',
  },
  {
    key: 'totalVolume',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VOLUME',
  },
  {
    key: 'totalValue',
    title: 'market.coveredWarrant.cwStatistics.historicalPrice.TOTAL_VALUE',
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.coveredWarrant.cwStatistics.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.coveredWarrant.cwStatistics.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
