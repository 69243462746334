import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import { OVERVIEW_GROUP_ID } from '../../../common/topInfo/stockTopInfo/constants'
import { keyBy } from '../../../utils/Common'
import { selectBasicInfoOverview } from '../store/slice'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import { TABLE_ROW_LEVEL, financialMetricTableConstants } from './constants'
import {
  changeIds,
  changeIndicator,
  selectFinancialIds,
  selectFinancialValue,
  selectGroupColumns,
  selectLoading,
  setFinancialById,
} from './store/slice'

const MAP_INDUSTRY_AND_ACTIVE_ROW_ID = {
  [OVERVIEW_GROUP_ID.BANK]: financialMetricTableConstants.bank[1].index,
  [OVERVIEW_GROUP_ID.INSURANCE]:
    financialMetricTableConstants.insurance[0].index,
  [OVERVIEW_GROUP_ID.SECURITIES]:
    financialMetricTableConstants.securities[0].index,
  [null]: financialMetricTableConstants.corporate[1].index,
  0: financialMetricTableConstants.corporate[1].index,
}

const MAP_INDUSTRY = {
  [OVERVIEW_GROUP_ID.BANK]: financialMetricTableConstants.bank,
  [OVERVIEW_GROUP_ID.INSURANCE]: financialMetricTableConstants.insurance,
  [OVERVIEW_GROUP_ID.SECURITIES]: financialMetricTableConstants.securities,
  [null]: financialMetricTableConstants.corporate,
  0: financialMetricTableConstants.corporate,
}

const FinancialMetricTable = ({ width }) => {
  const dispatch = useDispatch()

  const financialIds = useSelector(selectFinancialIds)
  const isLoading = useSelector(selectLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const basicInfo = useSelector(selectBasicInfoOverview)

  useEffect(() => {
    if (!basicInfo.groupId) {
      dispatch(changeIds(financialMetricTableConstants.corporate))
      dispatch(
        setFinancialById(
          keyBy(financialMetricTableConstants.corporate, 'index'),
        ),
      )
    } else {
      switch (basicInfo.groupId) {
        case OVERVIEW_GROUP_ID.INSURANCE:
          dispatch(changeIds(financialMetricTableConstants.insurance))
          dispatch(
            setFinancialById(
              keyBy(financialMetricTableConstants.insurance, 'index'),
            ),
          )
          return
        case OVERVIEW_GROUP_ID.BANK:
          dispatch(changeIds(financialMetricTableConstants.bank))
          dispatch(
            setFinancialById(
              keyBy(financialMetricTableConstants.bank, 'index'),
            ),
          )
          return
        case OVERVIEW_GROUP_ID.SECURITIES:
          dispatch(changeIds(financialMetricTableConstants.securities))
          dispatch(
            setFinancialById(
              keyBy(financialMetricTableConstants.securities, 'index'),
            ),
          )
          return

        default:
          dispatch(changeIds(financialMetricTableConstants.corporate))
          dispatch(
            setFinancialById(
              keyBy(financialMetricTableConstants.corporate, 'index'),
            ),
          )
          return
      }
    }
  }, [basicInfo?.groupId])

  if (isLoading) {
    return <Loading />
  }

  if (groupColumns.length === 0 && basicInfo?.organizationId) {
    return <NoData />
  }

  const defaultActiveRowId = MAP_INDUSTRY_AND_ACTIVE_ROW_ID[basicInfo?.groupId]

  const renderTable = () => {
    return (
      <Table
        ids={financialIds}
        columnDraggable={false}
        getDataFromRedux={selectFinancialValue}
        isLoading={isLoading}
        rowDraggable={false}
        changeIds={changeIds}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: true,
              thStyle: {
                textAlign: 'left',
                fontSize: 10,
              },
              canCustomTd: true,
              render: (val, rowId, { style, className }) => {
                return (
                  <FirstColumnCell
                    val={val}
                    rowId={rowId}
                    defaultActiveRowId={defaultActiveRowId}
                    style={{
                      ...style,
                      width: '33%',
                      minWidth: width / 3,
                    }}
                    className={className}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <OtherColumnsCell
                  val={val}
                  rowId={rowId}
                  colIndex={index}
                  defaultActiveRowId={defaultActiveRowId}
                  numCol={groupColumns.length}
                  props={props}
                />
              )
            },
          }
        })}
        stickyFirstColumn
        isPagination={false}
        hasFooter={false}
        changeActiveRow={changeIndicator}
        disableClickGroups={MAP_INDUSTRY[basicInfo?.groupId]
          ?.filter((item) => item.level === TABLE_ROW_LEVEL.LEVEL_1)
          ?.map((item) => item.index)}
        defaultActiveRowId={defaultActiveRowId}
        resizable={false}
        defaultScrollToRight
      />
    )
  }

  return renderTable()
}

export default FinancialMetricTable
