import { Translate } from 'react-redux-i18n'
import { Span } from '../../html/Span'
import style from './index.module.css'
import PropTypes from 'prop-types'

export const Title = ({ title, isI18n }) => {
  return (
    <Span style={{ fontSize: 18 }} className={style.title}>
      {isI18n ? <Translate value={title} /> : title}
    </Span>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
  isI18n: PropTypes.bool,
}

Title.defaultProps = {
  isI18n: true,
}
