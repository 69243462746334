import { Panel } from '../../../../common/panel'
import ReportTable from './ReportTable'

const PanelReport = ({ panelRefs, sizes, setSizes, panelKey }) => {
  return (
    <Panel
      title="sector.sectorConstituents.valuation.VALUATION_REPORT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
    >
      <ReportTable />
    </Panel>
  )
}

export default PanelReport
