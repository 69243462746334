import DailyReportProxy from '../../proxies/newsReport/DailyReportProxy'
import { ServiceBase } from '../ServiceBase'

class DailyReportService extends ServiceBase {
  postTemplates(params) {
    return this.getData(() => DailyReportProxy.postTemplates(params))
  }

  putTemplates(params) {
    return this.getData(() => DailyReportProxy.putTemplates(params))
  }

  getTemplates(params) {
    return this.getData(() => DailyReportProxy.getTemplates(params))
  }

  deleteTemplates(params) {
    return this.getData(() => DailyReportProxy.deleteTemplates(params))
  }

  getTemplatesByDate(params) {
    return this.getData(() => DailyReportProxy.getTemplatesByDate(params))
  }

  getNews(params) {
    return this.getData(() => DailyReportProxy.getNews(params))
  }

  downloadNews(params) {
    return this.getFileDownload(() => DailyReportProxy.downloadNews(params))
  }
}

export default new DailyReportService()
