import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { getIdsFromProps } from '../../../common/table/helper'
import { keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../utils/Value'
import {
  BOND_INFORMATION_TAB_TYPES,
  BOND_VALUES,
  EXPECTED_MOMENT_TYPES,
  EXPECTED_TYPES,
  FILTER_BOND_INFORMATION_TYPES,
  OVERVIEW_OF_PORTFOLIO_TYPES,
} from '../constants'
import {
  formatDataCashflowTable,
  formatDataTable,
  formatItem,
  renderTotalItem,
} from '../helper'
import {
  addPortfolioData,
  addPortfolioDataWithClearData,
  changeValuationDatePortfolioInvestmentData,
  createBondPortfolio,
  createWatchlistData,
  deletePortfolioData,
  deleteWatchlistData,
  fetchBondAndUserBondData,
  getBondAndUserBondData,
  getBondDetailData,
  getExpectedCashflowData,
  getPortfolioData,
  getPortfolioInvestmentByIdData,
  getPortfolioInvestmentData,
  getWatchListData,
  getWatchListDetailData,
  postPortfolioInvestmentData,
  renamePortfolioData,
  renameWatchListData,
  updatePortfolioData,
  updatePortfolioDataWithClearData,
  updateWatchlistData,
} from './thunk'

export const keys = {
  BOND_INFORMATION: 'bondInformation',
  OVERVIEW_OF_PORTFOLIO_SIZE: 'overviewOfPortfolioSize',
  PORTFOLIO_SIZE_BY_ISSUER: 'portfolioByIssuer',
  EXPECTED: 'expected',
}

const initialState = {
  loading: {
    [keys.BOND_INFORMATION]: false,
    [keys.PORTFOLIO_SIZE_BY_ISSUER]: true,
    [keys.EXPECTED]: false,
  },
  data: {
    [keys.BOND_INFORMATION]: {
      data: [],
      initialData: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
      watchList: [],
      portfolio: [],
      sort: {},
    },
    [keys.OVERVIEW_OF_PORTFOLIO_SIZE]: {
      list: ['buyValue', 'coupon', 'allCoupon'],
      list2: ['buyValue', 'duration', 'allDuration'],
    },
    [keys.PORTFOLIO_SIZE_BY_ISSUER]: {
      list: ['buyValue', 'coupon', 'duration'],
    },
    [keys.EXPECTED]: {
      list: ['couponValue', 'principalValue'],
      data: [],
      ids: [],
      dataById: {},
      initialIds: [],
      levels: [],
    },
  },
  filter: {
    [keys.BOND_INFORMATION]: {
      [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: null,
      [FILTER_BOND_INFORMATION_TYPES.BOND_ID]: null,
      [FILTER_BOND_INFORMATION_TYPES.IS_USER_BOND]: null,
      [FILTER_BOND_INFORMATION_TYPES.BOND_VOLUME]: null,
      valuationDate: null,
      activeTab: BOND_INFORMATION_TAB_TYPES.BOND_INFORMATION,
    },
    [keys.OVERVIEW_OF_PORTFOLIO_SIZE]: {
      activeTab: OVERVIEW_OF_PORTFOLIO_TYPES.CONTRIBUTION,
    },
    [keys.PORTFOLIO_SIZE_BY_ISSUER]: {
      activeTab: BOND_VALUES.CORPORATE_BOND,
    },
    [keys.EXPECTED]: {
      activeTab: EXPECTED_TYPES.CASHFLOW_TABLE,
      activeMoment: EXPECTED_MOMENT_TYPES.DAY,
    },
  },
  search: {
    loading: false,
    data: [],
    page: 1,
    pageSize: 10,
    enableInfinity: true,
  },
  saveStatus: '',
  updateStatus: '',
  error: false,
  createWatchlist: '',
  updateWatchlist: '',
  createBond: null,
}

export const slice = createSlice({
  name: 'bond/portfolio',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      localStorage.setItem(
        'portfolio_data',
        JSON.stringify(state.data[keys.BOND_INFORMATION].data),
      )

      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    sort: (state, action) => {
      const sortIds = getIdsFromProps(
        state.data[keys.BOND_INFORMATION].ids,
        state.data[keys.BOND_INFORMATION].dataById,
        action.payload,
        state.data[keys.BOND_INFORMATION].initialIds,
      )

      const newIds = sortIds.filter((item) => item !== 'total')

      state.data[keys.BOND_INFORMATION].ids = !!newIds.length
        ? [...newIds, 'total']
        : []
      state.data[keys.BOND_INFORMATION].sort = action.payload
    },
    sortCashflow: (state, action) => {
      state.data[keys.EXPECTED].ids = getIdsFromProps(
        state.data[keys.EXPECTED].ids,
        state.data[keys.EXPECTED].dataById,
        action.payload,
        state.data[keys.EXPECTED].initialIds,
      )
    },
    changeFilterBondInformation: (state, action) => {
      state.filter[keys.BOND_INFORMATION] = {
        ...state.filter[keys.BOND_INFORMATION],
        [action.payload.key]: action.payload.value,
      }
    },
    changeDataBondInformation: (state, action) => {
      const data = action.payload.filter((item) => item?.id !== 'total')
      const totalBookValue = data.reduce(
        (total, item) => (total += item?.bookValue ?? 0),
        0,
      )
      const newData = data.map((item) => ({
        ...item,
        weighted:
          totalBookValue !== 0
            ? (item?.bookValue ?? 0) / totalBookValue
            : undefined,
      }))
      const totalItem = renderTotalItem(newData)
      const dataWithTotal = [...newData, totalItem]
      const ids = dataWithTotal.map((item) => item.id)
      const newIds = ids.filter((item) => item !== 'total')

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        data: dataWithTotal,
        ids: !!newIds?.length ? [...newIds, 'total'] : [],
        initialIds: !!newIds?.length ? [...newIds, 'total'] : [],
        dataById: keyBy(dataWithTotal, 'id'),
        levels: !!newData.length ? dataWithTotal : [],
      }
    },
    resetDataBondInformation: (state) => {
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        data: [],
        ids: [],
        initialIds: [],
        dataById: {},
        levels: [],
      }
    },
    resetDataCashflow: (state) => {
      state.data[keys.EXPECTED] = {
        ...state.data[keys.EXPECTED],
        data: [],
        ids: [],
        initialIds: [],
        dataById: {},
        levels: [],
      }
    },
    getInitialDataBondInformation: (state, action) => {
      const data = action.payload
      const ids = data.map((item) => item.id)

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        data,
        ids,
        initialIds: ids,
        dataById: keyBy(data, 'id'),
        levels: data,
      }
    },
    changeFilterOverviewOfPortfolio: (state, action) => {
      state.filter[keys.OVERVIEW_OF_PORTFOLIO_SIZE] = {
        ...state.filter[keys.OVERVIEW_OF_PORTFOLIO_SIZE],
        [action.payload.key]: action.payload.value,
      }
    },
    changeFilterPortfolioByIssuer: (state, action) => {
      state.filter[keys.PORTFOLIO_SIZE_BY_ISSUER] = {
        ...state.filter[keys.PORTFOLIO_SIZE_BY_ISSUER],
        [action.payload.key]: action.payload.value,
      }
    },
    changeFilterExpected: (state, action) => {
      state.filter[keys.EXPECTED] = {
        ...state.filter[keys.EXPECTED],
        [action.payload.key]: action.payload.value,
      }
    },
    changeLoadingSearchBond: (state, action) => {
      state.search.loading = action.payload
    },
    changeErrorMaxLength: (state, action) => {
      state.error = action.payload
    },
    changeStatusCreateWatchlist: (state, action) => {
      state.createWatchlist = action.payload
    },
    changeStatusUpdateWatchlist: (state, action) => {
      state.updateWatchlist = action.payload
    },
    changePortfolioList: (state, action) => {
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        portfolio: action.payload,
      }
    },
    changeWatchList: (state, action) => {
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        watchList: action.payload,
      }
    },
    changeBondVolume: (state, action) => {
      const { value, id } = action.payload
      const index = state.data[keys.BOND_INFORMATION].data.findIndex(
        (item) => item.id === id,
      )

      if (index !== -1) {
        const buyValue =
          !isNaN(value) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index].buyPrice)
            ? value * state.data[keys.BOND_INFORMATION].data[index].buyPrice
            : undefined

        const extraDiscounts =
          !isNaN(buyValue) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.couponInterestRate,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]
              ?.paymentCalendarMonth ?? 12,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.countLastPaymentDate,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.countDistanceDate,
          )
            ? buyValue -
              state.data[keys.BOND_INFORMATION].data[index]?.parValue *
                value *
                (1 +
                  (state.data[keys.BOND_INFORMATION].data[index]
                    ?.couponInterestRate /
                    (12 /
                      (state.data[keys.BOND_INFORMATION].data[index]
                        ?.paymentCalendarMonth ?? 12))) *
                    (state.data[keys.BOND_INFORMATION].data[index]
                      ?.countLastPaymentDate /
                      state.data[keys.BOND_INFORMATION].data[index]
                        ?.countDistanceDate))
            : undefined

        const maturityDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.maturityDateId
          ? moment(
              state.data[keys.BOND_INFORMATION].data[index]?.maturityDateId,
            )
          : undefined
        const valuationDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.valuationDate
          ? moment(state.data[keys.BOND_INFORMATION].data[index]?.valuationDate)
          : undefined
        const tradingDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.tradingDateId
          ? moment(state.data[keys.BOND_INFORMATION].data[index]?.tradingDateId)
          : undefined

        const maturityDateDiffValuationDate =
          maturityDate && valuationDate
            ? maturityDate.diff(valuationDate, 'days')
            : undefined

        const maturityDateDiffTradingDate =
          maturityDate && valuationDate
            ? maturityDate.diff(tradingDate, 'days')
            : undefined

        const unallocatedExtra =
          !isNaN(maturityDateDiffValuationDate) &&
          !isNaN(maturityDateDiffTradingDate) &&
          !isNaN(extraDiscounts)
            ? (extraDiscounts * maturityDateDiffValuationDate) /
              (maturityDateDiffTradingDate + 1)
            : undefined

        const bookValue =
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
          !isNaN(value) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.accruedInterest,
          ) &&
          !isNaN(unallocatedExtra)
            ? state.data[keys.BOND_INFORMATION].data[index]?.parValue * value +
              state.data[keys.BOND_INFORMATION].data[index]?.accruedInterest +
              unallocatedExtra
            : undefined

        const totalValue =
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.dirtyPrice) &&
          !isNaN(value)
            ? state.data[keys.BOND_INFORMATION].data[index]?.dirtyPrice * value
            : !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
              !isNaN(value)
            ? state.data[keys.BOND_INFORMATION].data[index]?.parValue * value
            : undefined

        const profitLoss =
          !isNaN(totalValue) && !isNaN(bookValue)
            ? totalValue - bookValue
            : undefined

        const newData = [
          ...state.data[keys.BOND_INFORMATION].data.slice(0, index),
          {
            ...state.data[keys.BOND_INFORMATION].data[index],
            bondVolume: value,
            buyValue,
            extraDiscounts,
            unallocatedExtra,
            bookValue,
            totalValue,
            profitLoss,
          },
          ...state.data[keys.BOND_INFORMATION].data.slice(index + 1),
        ].filter((item) => item.id !== 'total')
        const totalBookValue = newData.reduce(
          (total, item) => (total += item?.bookValue ?? 0),
          0,
        )
        const dataWithWeight = newData.map((item) => ({
          ...item,
          weighted:
            totalBookValue !== 0
              ? (item?.bookValue ?? 0) / totalBookValue
              : undefined,
        }))
        const totalItem = renderTotalItem(dataWithWeight)
        const dataWithTotal = [...dataWithWeight, totalItem]
        const ids = !!dataWithWeight.length
          ? dataWithTotal.map((item) => item.id)
          : []

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: !!dataWithWeight.length ? dataWithTotal : [],
          ids: ids,
          initialIds: ids,
          dataById: keyBy(dataWithTotal, 'id'),
          levels: !!dataWithWeight.length ? dataWithTotal : [],
        }
      }
    },
    changeBuyPrice: (state, action) => {
      const { value, id } = action.payload
      const index = state.data[keys.BOND_INFORMATION].data.findIndex(
        (item) => item.id === id,
      )

      if (index !== -1) {
        const buyValue =
          !isNaN(value) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index].bondVolume)
            ? value * state.data[keys.BOND_INFORMATION].data[index].bondVolume
            : undefined

        const extraDiscounts =
          !isNaN(buyValue) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.couponInterestRate,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]
              ?.paymentCalendarMonth ?? 12,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.countLastPaymentDate,
          ) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.countDistanceDate,
          )
            ? buyValue -
              state.data[keys.BOND_INFORMATION].data[index]?.parValue *
                state.data[keys.BOND_INFORMATION].data[index].bondVolume *
                (1 +
                  (state.data[keys.BOND_INFORMATION].data[index]
                    ?.couponInterestRate /
                    (12 /
                      (state.data[keys.BOND_INFORMATION].data[index]
                        ?.paymentCalendarMonth ?? 12))) *
                    (state.data[keys.BOND_INFORMATION].data[index]
                      ?.countLastPaymentDate /
                      state.data[keys.BOND_INFORMATION].data[index]
                        ?.countDistanceDate))
            : undefined

        const maturityDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.maturityDateId
          ? moment(
              state.data[keys.BOND_INFORMATION].data[index]?.maturityDateId,
            )
          : undefined
        const valuationDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.valuationDate
          ? moment(state.data[keys.BOND_INFORMATION].data[index]?.valuationDate)
          : undefined
        const tradingDate = state.data[keys.BOND_INFORMATION].data[index]
          ?.tradingDateId
          ? moment(state.data[keys.BOND_INFORMATION].data[index]?.tradingDateId)
          : undefined

        const maturityDateDiffValuationDate =
          maturityDate && valuationDate
            ? maturityDate.diff(valuationDate, 'days')
            : undefined

        const maturityDateDiffTradingDate =
          maturityDate && valuationDate
            ? maturityDate.diff(tradingDate, 'days')
            : undefined

        const unallocatedExtra =
          !isNaN(maturityDateDiffValuationDate) &&
          !isNaN(maturityDateDiffTradingDate) &&
          !isNaN(extraDiscounts)
            ? (extraDiscounts * maturityDateDiffValuationDate) /
              (maturityDateDiffTradingDate + 1)
            : undefined

        const bookValue =
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index].bondVolume) &&
          !isNaN(
            state.data[keys.BOND_INFORMATION].data[index]?.accruedInterest,
          ) &&
          !isNaN(unallocatedExtra)
            ? state.data[keys.BOND_INFORMATION].data[index]?.parValue *
                state.data[keys.BOND_INFORMATION].data[index].bondVolume +
              state.data[keys.BOND_INFORMATION].data[index]?.accruedInterest +
              unallocatedExtra
            : undefined

        const totalValue =
          !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.dirtyPrice) &&
          !isNaN(state.data[keys.BOND_INFORMATION].data[index].bondVolume)
            ? state.data[keys.BOND_INFORMATION].data[index]?.dirtyPrice *
              state.data[keys.BOND_INFORMATION].data[index].bondVolume
            : !isNaN(state.data[keys.BOND_INFORMATION].data[index]?.parValue) &&
              !isNaN(state.data[keys.BOND_INFORMATION].data[index].bondVolume)
            ? state.data[keys.BOND_INFORMATION].data[index]?.parValue *
              state.data[keys.BOND_INFORMATION].data[index].bondVolume
            : undefined

        const profitLoss =
          !isNaN(totalValue) && !isNaN(bookValue)
            ? totalValue - bookValue
            : undefined

        const newData = [
          ...state.data[keys.BOND_INFORMATION].data.slice(0, index),
          {
            ...state.data[keys.BOND_INFORMATION].data[index],
            buyPrice: value,
            buyValue,
            extraDiscounts,
            profitLoss,
            unallocatedExtra,
            bookValue,
            totalValue,
          },
          ...state.data[keys.BOND_INFORMATION].data.slice(index + 1),
        ].filter((item) => item.id !== 'total')
        const totalBookValue = newData.reduce(
          (total, item) => (total += item?.bookValue ?? 0),
          0,
        )
        const dataWithWeight = newData.map((item) => ({
          ...item,
          weighted:
            totalBookValue !== 0
              ? (item?.bookValue ?? 0) / totalBookValue
              : undefined,
        }))
        const totalItem = renderTotalItem(dataWithWeight)
        const dataWithTotal = [...dataWithWeight, totalItem]
        const ids = !!dataWithWeight.length
          ? dataWithTotal.map((item) => item.id)
          : []

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: !!dataWithWeight.length ? dataWithTotal : [],
          ids: ids,
          initialIds: ids,
          dataById: keyBy(dataWithTotal, 'id'),
          levels: !!dataWithWeight.length ? dataWithTotal : [],
        }
      }
    },
    changeSaveStatus: (state, action) => {
      state.saveStatus = action.payload
    },
    changeUpdateStatus: (state, action) => {
      state.updateStatus = action.payload
    },
    changeCreateBondStatus: (state, action) => {
      state.createBond = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBondAndUserBondData.pending, (state, action) => {
      state.search = {
        ...state.search,
        loading: true,
      }
    })
    builder.addCase(getBondAndUserBondData.fulfilled, (state, action) => {
      const { data, hasNextPage, page } = action.payload

      state.search = {
        ...state.search,
        data,
        page: page + 1,
        enableInfinity: hasNextPage,
        loading: false,
      }
    })
    builder.addCase(getBondAndUserBondData.rejected, (state, action) => {
      state.search = {
        ...state.search,
        loading: action.payload,
      }
    })
    builder.addCase(fetchBondAndUserBondData.pending, (state, action) => {})
    builder.addCase(fetchBondAndUserBondData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      state.search = {
        ...state.search,
        data: [...state.search.data, ...data],
        page: state.search.page + 1,
        enableInfinity: hasNextPage,
      }
    })
    builder.addCase(fetchBondAndUserBondData.rejected, (state, action) => {})
    builder.addCase(getWatchListData.pending, (state, action) => {})
    builder.addCase(getWatchListData.fulfilled, (state, action) => {
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        watchList: action.payload,
      }
    })
    builder.addCase(getWatchListData.rejected, (state, action) => {})
    builder.addCase(getPortfolioData.pending, (state, action) => {})
    builder.addCase(getPortfolioData.fulfilled, (state, action) => {
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        portfolio: action.payload,
      }
    })
    builder.addCase(getPortfolioData.rejected, (state, action) => {})
    builder.addCase(getPortfolioInvestmentData.pending, (state, action) => {
      state.loading[keys.BOND_INFORMATION] = true
    })
    builder.addCase(getPortfolioInvestmentData.fulfilled, (state, action) => {
      const { data, watchListId } = action.payload

      const dataNotTotal = state.data[keys.BOND_INFORMATION].data.filter(
        (item) => item.id !== 'total',
      )

      const dataFormatted = formatDataTable(
        dataNotTotal[dataNotTotal.length - 1]?.id ?? 0,
        data,
        [],
        watchListId,
      )
      const newData = [...dataNotTotal, ...dataFormatted]
      const totalBookValue = newData.reduce(
        (total, item) => (total += item?.bookValue ?? 0),
        0,
      )
      const dataWithWeight = newData.map((item) => ({
        ...item,
        weighted:
          totalBookValue !== 0
            ? (item?.bookValue ?? 0) / totalBookValue
            : undefined,
      }))

      const totalItem = renderTotalItem(dataWithWeight)
      const dataWithTotal = [...dataWithWeight, totalItem]
      const ids = !!dataWithWeight.length
        ? dataWithTotal.map((item) => item.id)
        : []

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        data: !!dataWithWeight.length ? dataWithTotal : [],
        ids: ids,
        initialIds: ids,
        dataById: keyBy(dataWithTotal, 'id'),
        levels: !!dataWithWeight.length ? dataWithTotal : [],
      }

      if (watchListId) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]: watchListId,
        }
      }

      state.loading[keys.BOND_INFORMATION] = false
    })
    builder.addCase(getPortfolioInvestmentData.rejected, (state, action) => {
      state.loading[keys.BOND_INFORMATION] = action.payload
    })
    builder.addCase(getPortfolioInvestmentByIdData.pending, (state, action) => {
      state.loading[keys.BOND_INFORMATION] = true
    })
    builder.addCase(
      getPortfolioInvestmentByIdData.fulfilled,
      (state, action) => {
        const dataFormatted = formatDataTable(0, action.payload)
        const totalBookValue = dataFormatted.reduce(
          (total, item) => (total += item?.bookValue ?? 0),
          0,
        )
        const dataWithWeight = dataFormatted.map((item) => ({
          ...item,
          weighted:
            totalBookValue !== 0
              ? (item?.bookValue ?? 0) / totalBookValue
              : undefined,
        }))
        const totalItem = renderTotalItem(dataWithWeight)
        const dataWithTotal = [...dataWithWeight, totalItem]
        const ids = !!dataWithWeight.length
          ? dataWithTotal.map((item) => item.id)
          : []

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: !!dataWithWeight.length ? dataWithTotal : [],
          initialData: !!dataWithWeight.length
            ? dataWithWeight.map((item) => {
                const {
                  bondId,
                  interestBasisTypeId,
                  tradingDateId,
                  valuationDate,
                  bondVolume,
                  buyPrice,
                  ytm,
                  dirtyPrice,
                  cleanPrice,
                } = item

                return {
                  bondId,
                  interestBasisTypeId,
                  tradingDateId,
                  valuationDate,
                  bondVolume,
                  buyPrice,
                  ytm,
                  dirtyPrice,
                  cleanPrice,
                }
              })
            : [],
          ids: ids,
          initialIds: ids,
          dataById: keyBy(dataWithTotal, 'id'),
          levels: !!dataWithWeight.length ? dataWithTotal : [],
        }

        state.loading[keys.BOND_INFORMATION] = false
      },
    )
    builder.addCase(
      getPortfolioInvestmentByIdData.rejected,
      (state, action) => {
        state.loading[keys.BOND_INFORMATION] = action.payload
      },
    )
    builder.addCase(postPortfolioInvestmentData.pending, (state, action) => {
      state.loading[keys.BOND_INFORMATION] = true
    })
    builder.addCase(postPortfolioInvestmentData.fulfilled, (state, action) => {
      const { data, id, bondVolume } = action.payload

      if (!data) return

      const index = state.data[keys.BOND_INFORMATION].data.findIndex(
        (item) => item.id === id,
      )

      if (index !== -1) {
        const dataFormatted = formatItem(id, data, bondVolume)
        const newData = [
          ...state.data[keys.BOND_INFORMATION].data.slice(0, index),
          dataFormatted,
          ...state.data[keys.BOND_INFORMATION].data.slice(index + 1),
        ].filter((item) => item.id !== 'total')
        const totalBookValue = newData.reduce(
          (total, item) => (total += item?.bookValue ?? 0),
          0,
        )
        const dataWithWeight = newData.map((item) => ({
          ...item,
          weighted:
            totalBookValue !== 0
              ? (item?.bookValue ?? 0) / totalBookValue
              : undefined,
        }))
        const totalItem = renderTotalItem(dataWithWeight)
        const dataWithTotal = [...dataWithWeight, totalItem]
        const dataById = keyBy(dataWithTotal, 'id')
        const ids = getIdsFromProps(
          newData.map((item) => item.id),
          dataById,
          state.data[keys.BOND_INFORMATION].sort,
          state.data[keys.BOND_INFORMATION].initialIds,
        )

        const newIds = !!dataWithWeight.length
          ? ids.filter((item) => item !== 'total')
          : []

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: !!dataWithWeight.length ? dataWithTotal : [],
          ids: !!newIds?.length ? [...newIds, 'total'] : [],
          dataById,
          levels: !!dataWithWeight.length ? dataWithTotal : [],
        }
      }

      state.loading[keys.BOND_INFORMATION] = false
    })
    builder.addCase(postPortfolioInvestmentData.rejected, (state, action) => {
      state.loading[keys.BOND_INFORMATION] = action.payload
    })
    builder.addCase(
      changeValuationDatePortfolioInvestmentData.pending,
      (state, action) => {
        state.loading[keys.BOND_INFORMATION] = true
      },
    )
    builder.addCase(
      changeValuationDatePortfolioInvestmentData.fulfilled,
      (state, action) => {
        const dataFormatted = formatDataTable(0, action.payload)
        const totalBookValue = dataFormatted.reduce(
          (total, item) => (total += item?.bookValue ?? 0),
          0,
        )
        const dataWithWeight = dataFormatted.map((item) => ({
          ...item,
          weighted:
            totalBookValue !== 0
              ? (item?.bookValue ?? 0) / totalBookValue
              : undefined,
        }))
        const totalItem = renderTotalItem(dataWithWeight)
        const dataWithTotal = [...dataWithWeight, totalItem]
        const dataById = keyBy(dataWithTotal, 'id')
        const ids = dataFormatted.map((item) => item.id)
        const sortIds = getIdsFromProps(
          ids,
          dataById,
          state.data[keys.BOND_INFORMATION].sort,
          ids,
        )
        const newIds = !!dataWithWeight.length
          ? sortIds.filter((item) => item !== 'total')
          : []

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: !!dataWithWeight.length ? dataWithTotal : [],
          ids: !!newIds?.length ? [...newIds, 'total'] : [],
          initialIds: !!newIds?.length ? [...newIds, 'total'] : [],
          dataById,
          levels: !!dataWithWeight.length ? dataWithTotal : [],
        }

        state.loading[keys.BOND_INFORMATION] = false
      },
    )
    builder.addCase(
      changeValuationDatePortfolioInvestmentData.rejected,
      (state, action) => {
        state.loading[keys.BOND_INFORMATION] = action.payload
      },
    )
    builder.addCase(getExpectedCashflowData.pending, (state, action) => {
      state.loading[keys.EXPECTED] = true
    })
    builder.addCase(getExpectedCashflowData.fulfilled, (state, action) => {
      const dataFormatted = formatDataCashflowTable(action.payload)
      const ids = dataFormatted.map((item) => item.id)

      state.data[keys.EXPECTED] = {
        ...state.data[keys.EXPECTED],
        data: dataFormatted,
        ids: ids,
        initialIds: ids,
        dataById: keyBy(dataFormatted, 'id'),
        levels: dataFormatted,
      }

      state.loading[keys.EXPECTED] = false
    })
    builder.addCase(getExpectedCashflowData.rejected, (state, action) => {
      state.loading[keys.EXPECTED] = action.payload
    })
    builder.addCase(addPortfolioData.pending, (state, action) => {})
    builder.addCase(addPortfolioData.fulfilled, (state, action) => {
      const { data, portfolio } = action.payload

      if (portfolio) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: portfolio?.portfolioId,
        }

        state.saveStatus = portfolio?.portfolioName
      }
      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        portfolio: data,
      }
    })
    builder.addCase(addPortfolioData.rejected, (state, action) => {})
    builder.addCase(
      addPortfolioDataWithClearData.pending,
      (state, action) => {},
    )
    builder.addCase(
      addPortfolioDataWithClearData.fulfilled,
      (state, action) => {
        const { data, portfolio } = action.payload

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          portfolio: data,
        }

        state.saveStatus = portfolio?.portfolioName ?? ''
      },
    )
    builder.addCase(
      addPortfolioDataWithClearData.rejected,
      (state, action) => {},
    )
    builder.addCase(updatePortfolioData.pending, (state, action) => {})
    builder.addCase(updatePortfolioData.fulfilled, (state, action) => {
      const { data, portfolio, isClear } = action.payload

      if (portfolio && !isClear) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: portfolio?.portfolioId,
        }

        state.updateStatus = portfolio?.portfolioName
      }

      if (!isClear) {
        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          portfolio: data,
        }
      }
    })
    builder.addCase(updatePortfolioData.rejected, (state, action) => {})
    builder.addCase(
      updatePortfolioDataWithClearData.pending,
      (state, action) => {},
    )
    builder.addCase(
      updatePortfolioDataWithClearData.fulfilled,
      (state, action) => {
        const { data, portfolio } = action.payload

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          portfolio: data,
        }

        state.updateStatus = portfolio?.portfolioName ?? ''
      },
    )
    builder.addCase(
      updatePortfolioDataWithClearData.rejected,
      (state, action) => {},
    )
    builder.addCase(deletePortfolioData.pending, (state, action) => {})
    builder.addCase(deletePortfolioData.fulfilled, (state, action) => {
      const { data, portfolioId } = action.payload

      if (
        portfolioId ===
        state.filter[keys.BOND_INFORMATION][
          FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID
        ]
      ) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: null,
          [FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]: null,
        }

        state.data[keys.BOND_INFORMATION] = {
          ...state.data[keys.BOND_INFORMATION],
          data: [],
          ids: [],
          initialIds: [],
          dataById: {},
          levels: [],
          portfolio: data,
        }

        return
      }

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        portfolio: data,
      }
    })
    builder.addCase(deletePortfolioData.rejected, (state, action) => {})
    builder.addCase(renamePortfolioData.pending, (state, action) => {})
    builder.addCase(renamePortfolioData.fulfilled, (state, action) => {
      const { data, portfolioId } = action.payload

      if (portfolioId) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: portfolioId,
        }
      }

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        portfolio: data,
      }
    })
    builder.addCase(renamePortfolioData.rejected, (state, action) => {})
    builder.addCase(renameWatchListData.pending, (state, action) => {})
    builder.addCase(renameWatchListData.fulfilled, (state, action) => {
      const { data, watchlistId } = action.payload

      if (watchlistId) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]: watchlistId,
        }
      }

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        watchList: data,
      }
    })
    builder.addCase(renameWatchListData.rejected, (state, action) => {})
    builder.addCase(deleteWatchlistData.pending, (state, action) => {})
    builder.addCase(deleteWatchlistData.fulfilled, (state, action) => {
      const { data, watchlistId } = action.payload

      if (
        watchlistId ===
        state.filter[keys.BOND_INFORMATION][
          FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID
        ]
      ) {
        state.filter[keys.BOND_INFORMATION] = {
          ...state.filter[keys.BOND_INFORMATION],
          [FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]: null,
        }
      }

      const newData = state.data[keys.BOND_INFORMATION].data.filter(
        (item) => item?.watchListId !== watchlistId && item.id !== 'total',
      )
      const totalBookValue = newData.reduce(
        (total, item) => (total += item?.bookValue ?? 0),
        0,
      )
      const dataWithWeight = newData.map((item) => ({
        ...item,
        weighted:
          totalBookValue !== 0
            ? (item?.bookValue ?? 0) / totalBookValue
            : undefined,
      }))
      const totalItem = renderTotalItem(dataWithWeight)
      const dataWithTotal = [...dataWithWeight, totalItem]
      const dataById = keyBy(dataWithTotal, 'id')
      const ids = newData.map((item) => item.id)
      const sortIds = getIdsFromProps(
        ids,
        dataById,
        state.data[keys.BOND_INFORMATION].sort,
        ids,
      )
      const newIds = !!dataWithWeight.length
        ? sortIds.filter((item) => item !== 'total')
        : []

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        data: !!dataWithWeight.length ? dataWithTotal : [],
        ids: [...newIds, 'total'],
        initialIds: [...newIds, 'total'],
        dataById,
        levels: !!dataWithWeight.length ? dataWithTotal : [],
        watchList: data,
      }
    })
    builder.addCase(deleteWatchlistData.rejected, (state, action) => {})
    builder.addCase(createWatchlistData.pending, (state, action) => {})
    builder.addCase(createWatchlistData.fulfilled, (state, action) => {
      const { data, watchlist } = action.payload

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        watchList: data,
      }

      if (watchlist) {
        state.createWatchlist = watchlist?.bondWatchListName ?? ''
      }
    })
    builder.addCase(createWatchlistData.rejected, (state, action) => {
      state.createWatchlist = action.payload
    })
    builder.addCase(updateWatchlistData.pending, (state, action) => {})
    builder.addCase(updateWatchlistData.fulfilled, (state, action) => {
      const { data, watchlist } = action.payload

      state.data[keys.BOND_INFORMATION] = {
        ...state.data[keys.BOND_INFORMATION],
        watchlist: data,
      }

      if (watchlist) {
        state.updateWatchlist = watchlist?.bondWatchListName ?? ''
      }
    })
    builder.addCase(updateWatchlistData.rejected, (state, action) => {
      state.updateWatchlist = action.payload
    })
    builder.addCase(createBondPortfolio.pending, (state, action) => {})
    builder.addCase(createBondPortfolio.fulfilled, (state, action) => {
      state.createBond = action.payload
    })
    builder.addCase(createBondPortfolio.rejected, (state, action) => {
      state.createBond = action.payload
    })
    builder.addCase(getWatchListDetailData.pending, (state, action) => {})
    builder.addCase(getWatchListDetailData.fulfilled, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getWatchListDetailData.rejected, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getBondDetailData.pending, (state, action) => {})
    builder.addCase(getBondDetailData.fulfilled, (state, action) => {
      state.error = action.payload
    })
    builder.addCase(getBondDetailData.rejected, (state, action) => {
      state.error = action.payload
    })
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]

// Bond Information
export const selectFilterBondInformation = (state) =>
  state[slice.name].filter[keys.BOND_INFORMATION]
export const selectDataBondInformation = (state) =>
  state[slice.name].data[keys.BOND_INFORMATION]
export const selectWatchlistBondInformation = (state) =>
  state[slice.name].data[keys.BOND_INFORMATION].watchList
export const selectPortfolioBondInformation = (state) =>
  state[slice.name].data[keys.BOND_INFORMATION].portfolio
export const selectItemDataTable = (state) =>
  state[slice.name].data[keys.BOND_INFORMATION].dataById
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data[keys.BOND_INFORMATION].dataById[id],
    attr,
  )
export const selectFilterSearchBondAnhUserBond = (state) =>
  state[slice.name].search
export const selectSavePortfolioStatus = (state) => state[slice.name].saveStatus
export const selectUpdatePortfolioStatus = (state) =>
  state[slice.name].updateStatus
export const selectErrorMaxLength = (state) => state[slice.name].error
export const selectCreateWatchlistStatus = (state) =>
  state[slice.name].createWatchlist
export const selectUpdateWatchlistStatus = (state) =>
  state[slice.name].updateWatchlist
export const selectCreateBondStatus = (state) => state[slice.name].createBond

// Overview of portfolio by size
export const selectFilterOverviewOfPortfolio = (state) =>
  state[slice.name].filter[keys.OVERVIEW_OF_PORTFOLIO_SIZE]
export const selectDataOverviewOfPortfolio = (state) =>
  state[slice.name].data[keys.OVERVIEW_OF_PORTFOLIO_SIZE]

// Portfolio by issuer
export const selectFilterPortfolioIssuer = (state) =>
  state[slice.name].filter[keys.PORTFOLIO_SIZE_BY_ISSUER]
export const selectDataPortfolioIssuer = (state) =>
  state[slice.name].data[keys.PORTFOLIO_SIZE_BY_ISSUER]

// Expected
export const selectFilterExpected = (state) =>
  state[slice.name].filter[keys.EXPECTED]
export const selectDataExpected = (state) =>
  state[slice.name].data[keys.EXPECTED]
export const selectDataCashflowTableById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data[keys.EXPECTED].dataById[id], attr)

export const {
  resetStore,
  sort,
  changeFilterBondInformation,
  changeDataBondInformation,
  resetDataBondInformation,
  changeFilterOverviewOfPortfolio,
  changeFilterPortfolioByIssuer,
  changeFilterExpected,
  changeBondVolume,
  changeBuyPrice,
  sortCashflow,
  resetDataCashflow,
  changeLoadingSearchBond,
  changeSaveStatus,
  changePortfolioList,
  changeErrorMaxLength,
  getInitialDataBondInformation,
  changeWatchList,
  changeStatusCreateWatchlist,
  changeUpdateStatus,
  changeStatusUpdateWatchlist,
  changeCreateBondStatus,
} = slice.actions

register(slice.name, slice.reducer)
