import { EMPTY_VALUE } from '../../constants/Common'
import { emptyInList } from '../../utils/Common'
import { valDivTrillion } from '../../utils/Value'
import { MAP_INDUSTRY_AND_INDICATOR_DATA } from './financialMetric/constants'

export const formatIndicator = (indicator, timeFilter, basicInfo) => {
  if (basicInfo?.groupId === undefined) {
    return {}
  }

  const formatIndicator = indicator.value
    ? indicator
    : MAP_INDUSTRY_AND_INDICATOR_DATA[basicInfo?.groupId]

  return {
    key: formatIndicator?.value?.key[timeFilter?.value],
    name: formatIndicator?.value?.name,
    isFormatValToPercent: formatIndicator?.value?.isFormatValToPercent,
    subKey: formatIndicator?.subValue?.key
      ? formatIndicator?.subValue?.key[timeFilter?.value]
      : '',
    subName: formatIndicator?.subValue?.name,
    isFormatValToPercentSub: formatIndicator?.subValue?.isFormatValToPercent,
  }
}

export const footerData = (data, formatIndicatorData, left, right) => {
  const result = []
  if (!emptyInList(data, [formatIndicatorData.key])) {
    result.push({ text: formatIndicatorData.name, ...left })
  }

  if (!emptyInList(data, [formatIndicatorData.subKey])) {
    result.push({
      text: formatIndicatorData.subName,
      ...right,
    })
  }

  return result
}

export const formatDataChart = (
  data,
  barKey,
  lineOrPointKey,
  isFormatValToPercent,
  isFormatValToPercentSub,
) => {
  if (lineOrPointKey) {
    return data.map((item) => ({
      ...item,
      [barKey]: valDivTrillion(item[barKey]),
      [lineOrPointKey]:
        isFormatValToPercentSub && item[lineOrPointKey]
          ? item[lineOrPointKey] * 100
          : item[lineOrPointKey],
    }))
  }

  return data.map((item) => ({
    ...item,
    [barKey]:
      isFormatValToPercent && item[barKey] ? item[barKey] * 100 : item[barKey],
  }))
}

export const getI18nTooltipLabel = (I18n, formatIndicatorData) => {
  return {
    indicatorName: formatIndicatorData.name
      ? I18n.t(formatIndicatorData.name)
      : '',
    subIndicatorName: formatIndicatorData.subName
      ? I18n.t(formatIndicatorData.subName)
      : '',
  }
}

export const getLabelQuarterly = (item) => {
  return Object.keys(item).length > 0
    ? ['Q', item.quarter, '-', item.year].join('')
    : EMPTY_VALUE
}
