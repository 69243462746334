import { useSelector } from 'react-redux'
import { ExportStructure } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { TYPE_IMPORT_EXPORT } from '../constants'
import {
  selectActiveItem,
  selectImportExportDataType,
  selectOrigParentCategory,
} from '../exportImportTable/store/slice'
import { TYPE_CHART_STRUCTURE } from './constants'
import {
  getStructureChartName,
  getValueChartName,
} from './structureChart/helper'
import { selectCurrentChartType } from './structureChart/store/slice'

export const PanelExportStructure = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const importExportDataType = useSelector(selectImportExportDataType)
  const activeCommodity = useSelector(selectActiveItem)
  const origCategories = useSelector(selectOrigParentCategory)
  const currentChartType = useSelector(selectCurrentChartType)
  const locale = useSelector((state) => state.i18n.locale)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT
  const activeCategoryId =
    activeCommodity?.vnTypeId ?? origCategories[0]?.vnTypeId
  const activeName = origCategories?.find(
    (item) => item.vnTypeId === activeCategoryId,
  )?.name

  const title = isImportData
    ? 'economy.importExport.importExport.exportImportByCommodities.IMPORT_COMMODITIES_STRUCTURE'
    : 'economy.importExport.importExport.exportImportByCommodities.EXPORT_COMMODITIES_STRUCTURE'

  const nameScreen = UseI18n('economy.importExport.importExport.IMPORT_EXPORT')

  const getChartName = (typeChart) => {
    if (typeChart === TYPE_CHART_STRUCTURE.STRUCTURE) {
      return getStructureChartName(locale, isImportData, activeName)
    } else {
      return getValueChartName(locale, isImportData, activeName)
    }
  }

  const tabName = UseI18n(
    'economy.importExport.importExport.EXPORT_IMPORT_BY_COMMODITIES',
  )

  return (
    <Panel
      title={title}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      downloadJpgParams={{
        domId: 'structureChart',
        nameScreen: nameScreen,
        chartName: getChartName(currentChartType),
        tabName: tabName,
      }}
    >
      <ExportStructure
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
