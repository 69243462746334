export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.DATE',
  },
  {
    key: 'totalBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_BUY_VOL',
  },
  {
    key: 'totalSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_SELL_VOL',
  },
  {
    key: 'totalBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_BUY_VAL',
  },
  {
    key: 'totalSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_SELL_VAL',
  },
  {
    key: 'netTotalVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.NET_TOTAL_VOL',
  },
  {
    key: 'netTotalValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.NET_TOTAL_VAL',
  },
  {
    key: 'totalMatchBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_BUY_VOL',
  },
  {
    key: 'totalMatchSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_SELL_VOL',
  },
  {
    key: 'totalMatchBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_BUY_VAL',
  },
  {
    key: 'totalMatchSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_SELL_VAL',
  },
  {
    key: 'totalDealBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_BUY_VOL',
  },
  {
    key: 'totalDealSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_SELL_VOL',
  },
  {
    key: 'totalDealBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_BUY_VAL',
  },
  {
    key: 'totalDealSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_SELL_VAL',
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.DATE',
  },
  {
    key: 'cwCode',
    title: 'market.coveredWarrant.cwStatistics.TICKER_TABLE',
  },
  {
    key: 'totalBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_BUY_VOL',
  },
  {
    key: 'totalSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_SELL_VOL',
  },
  {
    key: 'totalBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_BUY_VAL',
  },
  {
    key: 'totalSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.TOTAL_SELL_VAL',
  },
  {
    key: 'netTotalVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.NET_TOTAL_VOL',
  },
  {
    key: 'netTotalValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.NET_TOTAL_VAL',
  },
  {
    key: 'totalMatchBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_BUY_VOL',
  },
  {
    key: 'totalMatchSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_SELL_VOL',
  },
  {
    key: 'totalMatchBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_BUY_VAL',
  },
  {
    key: 'totalMatchSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.MATCH_SELL_VAL',
  },
  {
    key: 'totalDealBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_BUY_VOL',
  },
  {
    key: 'totalDealSellVolume',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_SELL_VOL',
  },
  {
    key: 'totalDealBuyValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_BUY_VAL',
  },
  {
    key: 'totalDealSellValue',
    title: 'market.coveredWarrant.cwStatistics.proprietary.DEAL_SELL_VAL',
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.coveredWarrant.cwStatistics.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.coveredWarrant.cwStatistics.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
