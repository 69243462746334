import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { OVERVIEW_OF_PORTFOLIO_TABS } from '../constants'
import {
  changeFilterOverviewOfPortfolio,
  selectFilterOverviewOfPortfolio,
} from '../store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const { activeTab } = useSelector(selectFilterOverviewOfPortfolio)

  const handleChangeTab = (item) => {
    dispatch(
      changeFilterOverviewOfPortfolio({
        key: 'activeTab',
        value: item.value,
      }),
    )
  }

  return (
    <div>
      <DispatchActionTab
        data={OVERVIEW_OF_PORTFOLIO_TABS}
        activeTab={activeTab}
        onChangeTab={handleChangeTab}
        itemStyle={{
          fontWeight: 600,
          minWidth: 50,
        }}
      />
    </div>
  )
}

export default Filter
