import { useSelector } from 'react-redux'
import { PortfolioTable } from '.'
import useGetFund from '../../../../common/masterData/useGetFund'
import { Panel } from '../../../../common/panel'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { SECURITY_HOLDING_TABS, TABLE_TABS } from './constants'
import { getParams } from './helpers'
import { selectFilters, selectTableTab } from './store/slice'
import {
  downloadStockCurrentSecurityHoldings,
  downloadStockSecurityHoldingsHistory,
} from './store/thunk'

export const PanelPortfolioTable = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const titlePanel = `fund.fundProfile.portfolio.${panelKey}`

  const { funds } = useGetFund()

  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const fundInfo = useSelector(selectFundInfo)

  const handleDownload = () => {
    const params = getParams({
      organizationId: fundInfo.organizationId,
      filters,
      tableTab,
      funds,
      isDownload: true,
    })
    switch (true) {
      case tableTab === TABLE_TABS.STOCK &&
        filters.securityHoldingTab === SECURITY_HOLDING_TABS.CURRENT:
        return downloadStockCurrentSecurityHoldings(params)
      case tableTab === TABLE_TABS.STOCK &&
        filters.securityHoldingTab === SECURITY_HOLDING_TABS.HISTORY:
        return downloadStockSecurityHoldingsHistory(params)
      default:
        return
    }
  }

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={handleDownload}
    >
      <PortfolioTable
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
