import { useDispatch, useSelector } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from '../index.module.css'
import { getDataByTickerId, handleRemoveOneTicker } from './store/slice'

const TickerItemSelected = ({ tickerId, index }) => {
  const dispatch = useDispatch()
  const dataByTickerId = useSelector(getDataByTickerId(tickerId))

  const removeOneTicker = () => {
    dispatch(handleRemoveOneTicker(tickerId))
  }

  return (
    <div className="d-flex align-center" style={{ marginBottom: 12 }}>
      <div style={{ width: '10%', paddingLeft: 8 }}>
        <TextEllipsis isI18n={false} text={index + 1} />
      </div>
      <div style={{ width: '10%' }}>
        <TextEllipsis isI18n={false} text={dataByTickerId?.ticker} />
      </div>
      <div style={{ width: '70%' }}>
        <TextEllipsis
          isI18n={false}
          text={dataByTickerId?.organizationShortName}
          appendStyle={{ opacity: 0.4 }}
        />
      </div>
      <div className={style.checkboxTicker} onClick={removeOneTicker}>
        <i className={`icon-delete-round ${style.iconDeleteRound}`} />
      </div>
    </div>
  )
}

export default TickerItemSelected
