import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleLineChartData } from '../helper'
import { getChangeOfExchangeRate } from './thunk'

const initialState = {
  timeRangeDaily: 'OneMonth',
  timeRangeWeekly: 'ThreeMonths',
  timeRangeMonthly: 'SixMonths',
  lineChartData: [],
  lineChartLoading: true,
  activeLine: undefined,
  dropdownValue: 'askRate',
}

const slice = createSlice({
  name: 'economy/monetary/exchangeRate/panelExchangeRateVolatility',
  initialState,
  reducers: {
    handleTimeRangeDaily: (state, action) => {
      state.timeRangeDaily = action.payload
    },
    handleTimeRangeWeekly: (state, action) => {
      state.timeRangeWeekly = action.payload
    },
    handleTimeRangeMonthly: (state, action) => {
      state.timeRangeMonthly = action.payload
    },
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
    handleDropdownValue: (state, action) => {
      state.dropdownValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChangeOfExchangeRate.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getChangeOfExchangeRate.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = handleLineChartData(action.payload || [])
    })
    builder.addCase(getChangeOfExchangeRate.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const {
  handleTimeRangeDaily,
  handleActiveLine,
  handleDropdownValue,
  handleTimeRangeWeekly,
  handleTimeRangeMonthly,
} = slice.actions

export const getTimeRangeDaily = (state) => state[slice.name].timeRangeDaily
export const getTimeRangeWeekly = (state) => state[slice.name].timeRangeWeekly
export const getTimeRangeMonthly = (state) => state[slice.name].timeRangeMonthly
export const getLineChartData = (state) => state[slice.name].lineChartData
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getActiveLine = (state) => state[slice.name].activeLine
export const getDropdownValue = (state) => state[slice.name].dropdownValue

register(slice.name, slice.reducer)
