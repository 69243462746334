import { ScrollComponent } from '../../../common/ScrollComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import ChartOfTheDay from './ChartOfTheDay'
import DashboardTemplate from './DashboardTemplate'
import MyChartLibraryBtn from './MyChartLibraryBtn'
import MyDashboard from './MyDashboard'
import NewChartBtn from './NewChartBtn'
import NewDashboardBtn from './NewDashboardBtn'
import style from './style.module.css'

export const SCROLL_ID = 'financialChart/menuDashboard'
export const SCROLL_MENU_ID = 'menuDB'

const MenuDashboard = () => {
  return (
    <div className={style.container}>
      <div className={style.content}>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div className={style.groupBtnNewChartAndDashboard}>
                  <NewChartBtn />
                  <NewDashboardBtn />
                </div>
                {size.height && (
                  <div
                    style={{ height: `calc(100% - ${size.height}px)` }}
                    id={SCROLL_MENU_ID}
                  >
                    <ScrollComponent bgColor="#D9D9D9" id={SCROLL_ID}>
                      <div className={style.scrollContent}>
                        <ChartOfTheDay />
                        <DashboardTemplate />
                        <MyDashboard />
                        {process.env.REACT_APP_ENV !== 'production' && (
                          <MyChartLibraryBtn />
                        )}
                      </div>
                    </ScrollComponent>
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
    </div>
  )
}

export default MenuDashboard
