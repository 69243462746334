import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FDIByIndustriesService from '../../../../../../core/services/economy/fdi/FDIByIndustriesService'
import FDIByProvincesService from '../../../../../../core/services/economy/fdi/FDIByProvincesService'
import { handleExport } from '../../../../../utils/Export'

export const getTableData = createAsyncThunk(
  'economy/fdi/fdiByProvinces/GET_DATA_TABLE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByProvincesService.getTotalRegisterFDICapital(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getFDITypeName = createAsyncThunk(
  'economy/fdi/fdiByProvinces/GET_FDI_TYPE_NAME',
  async (params, { rejectWithValue }) => {
    try {
      const response = await FDIByIndustriesService.getFDITypeName(params)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadDataTable = createAsyncThunk(
  'economy/fdi/fdiByProvinces/DOWNLOAD_DATA_TABLE',
  async (data) => {
    const response =
      await FDIByProvincesService.downloadTotalRegisterFDICapital(data)
    handleExport(response.data, response.filename)
  },
)
