import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import Header from './Header'
import style from './index.module.css'
import NewsItem from './NewsItem'
import { getLoading, getNewsIds } from './store/slice'
import { getNews } from './store/thunk'

const MarketNews = () => {
  const dispatch = useDispatch()

  const loading = useSelector(getLoading)
  const newsIds = useSelector(getNewsIds)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(
      getNews({
        NewsType: 'MarketNew',
      }),
    )
  }, [locale])

  return (
    <div className={style.marketNewsContainer}>
      <Header />
      <div className={style.wrapNewsItem}>
        <ScrollComponent>
          {loading && <Loading />}
          {!loading && (
            <>
              {newsIds.length === 0 && <NoData />}
              {newsIds.length > 0 && (
                <>
                  {newsIds.map((id) => (
                    <NewsItem id={id} key={id} />
                  ))}
                </>
              )}
            </>
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default MarketNews
