import { useSelector } from 'react-redux'
import { InDepthComparisonChart } from '.'
import UseI18n from '../../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../../common/panel'
import { selectIndexSectorTickerInfo } from '../../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../../constants/Common'
import { formatNumber } from '../../../../../../utils/Common'
import { selectIndicatorType, selectYearType } from './store/slice'

export const PanelInDepthComparison = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const yearType = useSelector(selectYearType)
  const indicatorType = useSelector(selectIndicatorType)

  return (
    <Panel
      title={'sector.financialAnalysis.bank.assetQuality.IN_DEPTH_COMPARISON'}
      panelRefs={panelRefs}
      panelKey={mapKey.IN_DEPTH_COMPARISON}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'inDepthComparisonChart',
        nameScreen: UseI18n('sector.financialAnalysis.bank.ASSET_QUALITY'),
        chartName: UseI18n(
          'sector.financialAnalysis.bank.assetQuality.IN_DEPTH_COMPARISON',
        ),
        tabName: indicatorType + '_' + yearType,
        tickerName: currentInfo.ticker,
      }}
    >
      <InDepthComparisonChart
        width={formatNumber(
          sizes[mapKey.IN_DEPTH_COMPARISON].width - PANEL_PADDING,
        )}
        height={formatNumber(
          sizes[mapKey.IN_DEPTH_COMPARISON].height - HEADER_PANEL_HEIGHT,
        )}
        dataType={dataType}
      />
    </Panel>
  )
}
