export const monetary = {
  OPEN_MARKET: 'Thị trường mở',
  MONEY_SUPPLIES_CREDIT: 'Cung tiền - Tín dụng',
  EXCHANGE_RATE: 'Tỷ giá',
  INTEREST_RATE: 'Lãi suất',
  OPEN_MARKET_OPERATIONS: 'Nghiệp vụ thị trường mở',
  MONEY_MARKET_OPERATIONS: 'Diễn biến trên thị trường tiền tệ',
  OPEN_MARKET_AUCTION: 'Kết quả đấu thầu trên thị trường mở',
  OMO_NET_INJECTION: 'Khối lượng bơm hút ròng',
  MONEY_SUPPLY_CREDIT: 'Cung tiền & Dư nợ tín dụng',
  GROWTH_MONEY_SUPPLY_CREDIT_BALANCE:
    'Tăng trưởng các chỉ tiêu cung tiền & tín dụng',
  CONTRIBUTION_CREDIT_GROWTH: 'Đóng góp vào tăng trưởng tín dụng',
  GROWTH_YOY: 'Tăng trưởng cung tiền & tín dụng qua các năm',
  EXCHANGE_RATE_VOLATILITY: 'Biểu đồ đường thay đổi tỷ giá',
  NEER_BEER: 'Tỷ giá neer/reer',
  DEPOSIT_RATE_COMMERCIAL: 'Lãi suất huy động của các ngân hàng',
  STATISTICAL_INTEREST_RATE: 'So sánh lãi suất huy động giữa các ngân hàng',
  DEPOSIT_RATE_VIET_NAM: 'Lãi suất thống kê của NHNN',
  AVERAGE_FUNDING_COST: 'Diễn biến lãi suất huy động, cho vay',
  UNIT_BILLION: 'Đơn vị: tỷ VND',
  UNIT_TRILLION: 'Đơn vị: nghìn tỷ VND',
  DAILY: 'Hàng ngày',
  WEEKLY: 'Hàng tuần',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  REVERSE_REPO: 'Reverse Repo',
  SELL_OUTRIGHT: 'Tín phiếu',
  BILLION_VND: '(Tỷ VND)',
  TRILLION_VND: '(Nghìn tỷ VND)',
  TRILLION_TOOLTIP: ' Nghìn tỷ',
  PERCENT_YEAR: '(%/năm)',
  DAY: '(ngày)',
  TENDER_DAY: 'Ngày đấu thầu',
  PARTICIPANTS_WINNERS: 'Tham gia/trúng thầu',
  AMOUNT_WINNING_BIDS: 'KL trúng thầu',
  MATURITIES: 'Kỳ hạn ',
  VALUE: 'Giá trị',
  PROPORTION: 'Tỷ trọng',
  ALL: 'Tất cả',
  BUY_TM: 'Mua TM',
  BUY_CK: 'Mua CK',
  SELL: 'Bán',
  BANK: 'Ngân hàng',
  UNIT_PERCENT: 'Đơn vị: %',
  UNIT_VND: 'Đơn vị: VND',
  DATE: 'Ngày',
  FROM: 'Từ',
  TO: 'đến',
  TH1: 'Th1',
  TH2: 'Th2',
  TH3: 'Th3',
  TH4: 'Th4',
  TH5: 'Th5',
  TH6: 'Th6',
  TH7: 'Th7',
  TH8: 'Th8',
  TH9: 'Th9',
  TH10: 'Th10',
  TH11: 'Th11',
  TH12: 'Th12',
  SHARE_OUTSTANDING: 'Khối lượng lưu hành',
  TOTAL_CREDIT: 'Tổng dư nợ tín dụng',
  BUY: 'Mua',
  TRANSFER: 'Chuyển khoản',
  MAX_ITEM: 'Số lượng chỉ tiêu lựa chọn không vượt quá 20.',
  HISTORICAL_DATA_INFO:
    'Dữ liệu cũ được cập nhật theo tháng. Hệ thống tự điều hướng về ngày gần nhất có dữ liệu.',
  MONTH: 'Tháng',
  YEAR: 'Năm',
  INSTITUTIONAL: 'Tổ chức',
  INDIVIDUAL: 'Cá nhân',
  DATA_UPDATE: 'Dữ liệu thị trường mở được cập nhật đến ngày ',
}
