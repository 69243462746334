import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { VIEW_TYPE } from '../../constants'
import { getListIndicatorLatestPeriod } from './thunk'

export const slice = createSlice({
  name: 'financialChart/dashboard',
  initialState: {
    dataChartByTime: {},
    dataChartBySecurities: {},
    dataChartEquation: {},
    indicatorsLatestPeriod: {},
    downloadingChartID: null,
    downloadingDashboardID: null,
    downloadChartLoading: false,
    chartEditNameID: null,
  },
  reducers: {
    changeChartData: (state, action) => {
      const { id, data, viewType, isEquation } = action.payload
      if (isEquation) {
        state.dataChartEquation[id] = {
          ...(state.dataChartEquation[id] || {}),
          ...data,
        }
      } else {
        if (viewType === VIEW_TYPE.BY_SECURITIES) {
          state.dataChartBySecurities[id] = {
            ...(state.dataChartBySecurities[id] || []),
            ...data,
          }
        } else {
          state.dataChartByTime[id] = [
            ...(state.dataChartByTime[id] || []),
            ...data,
          ]
        }
      }
    },
    downloadChart: (state, action) => {
      const { id } = action.payload
      state.downloadingChartID = id
    },
    downloadAllChart: (state, action) => {
      const { id } = action.payload
      state.downloadingDashboardID = id
    },
    changeDownloadChartLoading: (state, action) => {
      state.downloadChartLoading = action.payload
    },
    changeChartEditNameID: (state, action) => {
      state.chartEditNameID = action.payload
    },
    resetDataChartSetting: (state) => {
      state.dataChartByTime = {}
      state.dataChartBySecurities = {}
      state.dataChartEquation = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getListIndicatorLatestPeriod.fulfilled, (state, action) => {
      state.indicatorsLatestPeriod = {
        ...state.indicatorsLatestPeriod,
        ...action.payload,
      }
    })
  },
})

export const selectDataChartBySecurities = (id) => (state) =>
  id
    ? state[slice.name].dataChartBySecurities[id]
    : state[slice.name].dataChartBySecurities
export const selectDataChartByTime = (id) => (state) =>
  id ? state[slice.name].dataChartByTime[id] : state[slice.name].dataChartByTime
export const selectDataChartEquation = (id) => (state) =>
  id
    ? state[slice.name].dataChartEquation[id]
    : state[slice.name].dataChartEquation
export const selectDownloadingChart = (state) =>
  state[slice.name].downloadingChartID || null
export const selectDownloadingDashboardChart = (state) =>
  state[slice.name].downloadingDashboardID || null
export const selectIndicatorsLatestPeriod = (state) =>
  state[slice.name].indicatorsLatestPeriod
export const selectDownloadChartLoading = (state) =>
  state[slice.name].downloadChartLoading
export const selectChartEditNameID = (state) =>
  state[slice.name].chartEditNameID

export const {
  changeChartData,
  changeDownloadChartLoading,
  downloadChart,
  downloadAllChart,
  changeChartEditNameID,
  resetDataChartSetting,
} = slice.actions

register(slice.name, slice.reducer)
