import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import UseI18n from '../../common/hooks/useI18n'
import { Z_INDEX } from '../../constants/Common'
import { Span } from '../html/Span'
import { isInValidValue } from './../../utils/Value'
import Sort from './Sort'
import { ThCellWithIconInfo } from './ThCellWithIConInfo'
import { ThResize } from './ThResize'
import { ThTooltip } from './ThTooltip'
import { DEFAULT_FONTSIZE_THEAD, SORT_TYPES, textDragColumn } from './constants'
import { calcLeftOfSticky } from './helper'
import style from './index.module.css'

let dragColumnIndex = null
let tableDragClone = null
let checkIsResizing = false
const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const ThCell = ({
  col,
  colIndex,
  columnDraggable,
  reorderColumn,
  stateSort,
  sortColumn,
  resizable,
  columnRefs,
  widths,
  setWidths,
  schema,
  initWidths,
  resizeState,
  setResizeState,
  hasTooltip,
  sticky,
  tableHeadRowTop,
  hasBorderTHead,
  setIsWidth100,
}) => {
  const getTooltipText = (title) => {
    if (typeof title === 'number') {
      return title
    }

    if (typeof title === 'string') {
      // get only text when title is dangerouslySetInnerHTML
      return title.replace(/<[\w,/]*>/g, '')
    }

    if (Array.isArray(title.props.children)) {
      return title.props.children[0]
    }

    return I18n.t(title.props.children)
  }

  const getTitleTooltip = () => {
    if (col.thTooltip) {
      return col.isI18nThTooltip ? UseI18n(col.thTooltip) : col.thTooltip
    }

    return getTooltipText(title)
  }

  const titleI18n = UseI18n(col.title)
  const title = col.isI18n ? col.title : titleI18n
  const displayTitle =
    typeof title === 'string' ? (
      <div dangerouslySetInnerHTML={{ __html: title }} />
    ) : (
      title
    )

  const tooltip = getTitleTooltip()
  const tooltipCustom = col.thTooltipCustom

  const [isResizing, setIsResizing] = useState(false)

  const canDrag = () => {
    if (!columnDraggable || checkIsResizing) {
      return false
    }

    return !sticky
  }

  useEffect(() => {
    checkIsResizing = isResizing
  }, [isResizing])

  const onDragStart = (index) => (e) => {
    e.dataTransfer.setData('text', textDragColumn)
    if (!canDrag(index)) {
      e.preventDefault()
    }

    const thClone = e.target.cloneNode(true)
    tableDragClone = e.target.closest('table').cloneNode(true)
    tableDragClone.classList.remove('w-100')
    tableDragClone.classList.add(style.imageDrag)
    tableDragClone.innerHTML = `<thead>
    <tr>
    ${thClone.outerHTML}
    </tr>
    </thead>
    <tbody>
    ${Array.from(document.querySelectorAll(`[colid=${col.colId}]`))
      .map((el) => `<tr>${el.outerHTML}</tr>`)
      .join('')}
    </tbody>`
    document.body.appendChild(tableDragClone)
    e.dataTransfer.setDragImage(tableDragClone, e.target.offsetWidth / 2, 20)

    dragColumnIndex = index
  }

  const onDragOver = (e) => {
    e.preventDefault()
  }

  const onDragEnd = () => {
    dragColumnIndex = null
    document.body.removeChild(tableDragClone)
    tableDragClone = null
  }

  const onDrop = (index) => (e) => {
    e.preventDefault()
    if (e.dataTransfer.getData('text') === textDragColumn) {
      if (
        isInValidValue(dragColumnIndex) ||
        index === dragColumnIndex ||
        !canDrag(index)
      ) {
        return
      }

      reorderColumn(dragColumnIndex, index)
    }
    dragColumnIndex = null
  }

  const resizeRef = useRef()
  const onClick = (e) => {
    if (col.disableSort || e.target === resizeRef.current) {
      return
    }
    e.preventDefault()
    sortColumn({ [col.colId]: sortStateMap[stateSort[col.colId]] })
  }

  const getRowTop = () => {
    if (!tableHeadRowTop || !tableHeadRowTop[col.colId]) {
      return 0
    } else {
      return tableHeadRowTop[col.colId]
        .map((id) => {
          if (columnRefs && columnRefs[id]) {
            return columnRefs[id].offsetHeight
          } else {
            return 0
          }
        })
        .reduce((prev, cur) => prev + cur, 0)
    }
  }

  const getStyle = () => {
    const width = widths[col.colId]

    let style = {
      fontWeight: 500,
      position: 'relative',
      cursor: isResizing ? 'col-resize' : 'default',
      zIndex: sticky
        ? Z_INDEX.TH_TABLE_STICKY_FIRST_COLUMN
        : Z_INDEX.STICKY_THEAD,
      top: getRowTop(),
      borderLeft: hasBorderTHead ? '1px solid #1b2029' : 'unset',
      ...col.thStyle,
    }

    if (sticky) {
      style = {
        ...style,
        left: calcLeftOfSticky(widths, schema, colIndex),
      }
    }

    if (width) {
      return {
        ...style,
        minWidth: style.minWidth || width,
        width,
      }
    }

    return style
  }

  const renderTitle = (displayTitle) => {
    return col.renderTh ? (
      <Span
        style={{ fontSize: col?.thStyle?.fontSize || DEFAULT_FONTSIZE_THEAD }}
      >
        {col.renderTh({ left: calcLeftOfSticky(widths, schema, colIndex) })}
      </Span>
    ) : (
      <Span
        style={{ fontSize: col?.thStyle?.fontSize || DEFAULT_FONTSIZE_THEAD }}
      >
        {displayTitle}
      </Span>
    )
  }

  const renderTooltipAndTitle = () => {
    return tooltipCustom ? (
      <ThCellWithIconInfo
        isI18n={col.isI18nThTooltip}
        tooltipCustom={tooltipCustom}
        styleContainer={{
          justifyContent: (col.thStyle && col.thStyle.textAlign) || 'left',
        }}
      >
        {renderTitle(displayTitle)}
      </ThCellWithIconInfo>
    ) : hasTooltip ? (
      <ThTooltip tooltip={tooltip}>{renderTitle(displayTitle)}</ThTooltip>
    ) : (
      renderTitle(displayTitle)
    )
  }

  const canColumnSort = () => {
    return !col.disableSort && stateSort[col.colId]
  }

  return (
    <th
      ref={(el) => (columnRefs[col.colId] = el)}
      className={[style.tableStickyHead, col.thClassName].join(' ')}
      style={getStyle()}
      rowSpan={col.rowSpan}
      colSpan={col.colSpan}
      onClick={onClick}
      draggable={columnDraggable}
      onDragStart={onDragStart(colIndex)}
      onDragOver={onDragOver}
      onDrop={onDrop(colIndex)}
      onDragEnd={onDragEnd}
    >
      {!canColumnSort() && renderTooltipAndTitle()}
      {canColumnSort() && (
        <>
          <div style={{ marginRight: 5 }}>{renderTooltipAndTitle()}</div>
          <Sort type={stateSort[col.colId]} />
        </>
      )}
      <ThResize
        col={col}
        resizable={resizable}
        widths={widths}
        setWidths={setWidths}
        setIsResizing={setIsResizing}
        schema={schema}
        resizeRef={resizeRef}
        initWidths={initWidths}
        resizeState={resizeState}
        setResizeState={setResizeState}
        setIsWidth100={setIsWidth100}
      />
      <div className={style.thBorderLeft} />
    </th>
  )
}

ThCell.propTypes = {
  col: PropTypes.object.isRequired,
  colIndex: PropTypes.number.isRequired,
  reorderColumn: PropTypes.func.isRequired,
  columnDraggable: PropTypes.bool.isRequired,
  stateSort: PropTypes.object.isRequired,
  sortColumn: PropTypes.func.isRequired,
  resizable: PropTypes.bool.isRequired,
  columnRefs: PropTypes.object.isRequired,
  widths: PropTypes.object.isRequired,
  setWidths: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  initWidths: PropTypes.object.isRequired,
  resizeState: PropTypes.object.isRequired,
  setResizeState: PropTypes.func.isRequired,
  hasTooltip: PropTypes.bool.isRequired,
  hasBorderTHead: PropTypes.bool.isRequired,
  sticky: PropTypes.bool.isRequired,
  tableHeadRowTop: PropTypes.object,
}
