import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DerivativeService from '../../../../../../core/services/market/DerivativeService'
import { handleExport } from '../../../../../utils/Export'

export const getHistoricalOverview = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/getHistoricalOverview',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getHistoricalOverview(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getHistoricalDetail = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/getHistoricalDetail',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getHistoricalDetail(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getHistoricalTicker = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/getHistoricalTicker',
  async (params, { rejectWithValue }) => {
    try {
      const response = await DerivativeService.getHistoricalTicker(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadHistoricalOverview = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/downloadHistoricalOverview',
  async (data) => {
    const response = await DerivativeService.downloadHistoricalOverview(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadHistoricalDetail = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/downloadHistoricalDetail',
  async (data) => {
    const response = await DerivativeService.downloadHistoricalDetail(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadHistoricalTicker = createAsyncThunk(
  'market/derivativeStatistics/historicalPrice/downloadHistoricalTicker',
  async (data) => {
    const response = await DerivativeService.downloadHistoricalTicker(data)
    handleExport(response.data, response.filename)
  },
)
