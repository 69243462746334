import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { CHART_TYPES } from '../../../../common/chart/financialChart/constant'
import { useTableDownloadCsv } from '../../../common/useTableDownloadCsv'
import { ReactComponent as IconAreaChartChoose } from '../../../icon/icon-area-chart-grey-choose.svg'
import { ReactComponent as IconAreaChart } from '../../../icon/icon-area-chart-grey.svg'
import { ReactComponent as IconColumnChartChoose } from '../../../icon/icon-column-chart-grey-choose.svg'
import { ReactComponent as IconColumnChart } from '../../../icon/icon-column-chart-grey.svg'
import { ReactComponent as IconLineChartChoose } from '../../../icon/icon-line-chart-grey-choose.svg'
import { ReactComponent as IconLineChart } from '../../../icon/icon-line-chart-grey.svg'
import { ReactComponent as IconStackAreaChoose } from '../../../icon/icon-stack-area-grey-choose.svg'
import { ReactComponent as IconStackArea } from '../../../icon/icon-stack-area-grey.svg'
import { ReactComponent as IconStackColumnChoose } from '../../../icon/icon-stack-column-grey-choose.svg'
import { ReactComponent as IconStackColumn } from '../../../icon/icon-stack-column-grey.svg'
import { ReactComponent as IconTableActive } from '../../../icon/icon-table-active.svg'
import { ReactComponent as IconTable } from '../../../icon/icon-table.svg'
import { VIEW_TYPE, listViewTime } from '../../constants'
import IconDownload from './IconDownload'
import IconPin from './IconPin'
import IconShare from './IconShare'
import { SettingStyleChart } from './settingStyleChart'
import style from './style.module.css'
import useIsShowChart from './useIsShowChart'

const ToolHeader = ({
  typeChart,
  settings,
  viewType,
  chartSettings,
  isSetAsDefault,
  isShowTable,
  isViewTypeSync,
  toggleShowTable,
  changeIsSetAsDefault,
  changeSettings,
  changeTypeChart,
  handleChangeViewType,
}) => {
  const dispatch = useDispatch()
  const isShowChart = useIsShowChart()
  const { IconDownloadCsv } = useTableDownloadCsv()

  const changeLayout = (layout) => {
    changeTypeChart(layout)
  }

  const changeShowTable = () => {
    dispatch(toggleShowTable())
  }

  return (
    <div className={`${style.toolHeaderSetting}`}>
      <div
        className={`${
          !isShowChart && style.pointerEventNone
        } d-flex ali-center`}
      >
        <div className={style.xAxis}>
          <div className="mr-5">
            <Translate value="financialChart.X_AXIS" />
          </div>
          <ul className={`list-check ${style.radioCheckbox}`}>
            {listViewTime.map((item, index) => (
              <li key={index}>
                <label>
                  <input
                    type="checkbox"
                    className="radiobox radiobox2"
                    value={viewType}
                    checked={viewType === item.value ? true : false}
                    onChange={() => handleChangeViewType(item.value)}
                  />
                  <Translate value={item.name} />
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div style={{ borderRight: '0.5px solid #dcdcdc', height: 28 }} />
        <div
          className={[style.groupBtnChooseChart].join(' ')}
          style={{ marginLeft: 16 }}
        >
          <div>
            {typeChart === CHART_TYPES.AREA && isViewTypeSync ? (
              <IconAreaChartChoose />
            ) : (
              <IconAreaChart onClick={() => changeLayout(CHART_TYPES.AREA)} />
            )}
          </div>
          <div>
            {typeChart === CHART_TYPES.BAR && isViewTypeSync ? (
              <IconColumnChartChoose />
            ) : (
              <IconColumnChart onClick={() => changeLayout(CHART_TYPES.BAR)} />
            )}
          </div>
          <div>
            {typeChart === CHART_TYPES.LINE && isViewTypeSync ? (
              <IconLineChartChoose />
            ) : (
              <IconLineChart onClick={() => changeLayout(CHART_TYPES.LINE)} />
            )}
          </div>
          <div>
            {typeChart === CHART_TYPES.STACK_BAR && isViewTypeSync ? (
              <IconStackColumnChoose />
            ) : (
              <IconStackColumn
                onClick={() => changeLayout(CHART_TYPES.STACK_BAR)}
              />
            )}
          </div>
          <div>
            {typeChart === CHART_TYPES.STACK_AREA && isViewTypeSync ? (
              <IconStackAreaChoose />
            ) : (
              <IconStackArea
                onClick={() => changeLayout(CHART_TYPES.STACK_AREA)}
              />
            )}
          </div>
        </div>
      </div>
      <div className={style.groupBtnToolSetting}>
        {isShowTable ? (
          <IconTableActive
            title={I18n.t('financialChart.SHOW_TABLE')}
            style={{ height: 18, cursor: 'pointer' }}
            onClick={changeShowTable}
            className={`${!isShowChart && style.pointerEventNone}`}
          />
        ) : (
          <IconTable
            title={I18n.t('financialChart.SHOW_TABLE')}
            style={{ height: 18, cursor: 'pointer' }}
            onClick={changeShowTable}
            className={`${!isShowChart && style.pointerEventNone}`}
          />
        )}
        <IconPin isShowChart={isShowChart} />
        <SettingStyleChart
          settings={settings}
          isBySecurities={viewType === VIEW_TYPE.BY_SECURITIES}
          isSetAsDefault={isSetAsDefault}
          isShowChart={isShowChart}
          changeSettings={changeSettings}
          changeIsSetAsDefault={changeIsSetAsDefault}
        />
        <IconDownloadCsv chartSettings={chartSettings} disable={!isShowChart} />
        <IconDownload />
        <IconShare />
      </div>
    </div>
  )
}

ToolHeader.propTypes = {
  typeChart: PropTypes.string,
  settings: PropTypes.object,
  isSetAsDefault: PropTypes.bool,
  changeTypeChart: PropTypes.func,
  changeSettings: PropTypes.func,
  changeIsSetAsDefault: PropTypes.func,
  toggleShowTable: PropTypes.func,
  isViewTypeSync: PropTypes.bool,
  viewType: PropTypes.string,
  handleChangeViewType: PropTypes.func,
}

export default ToolHeader
