import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown from '../../../common/dropdown'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import useGetICB from '../../../common/masterData/useGetICB'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { debounce } from '../../../utils/Common'
import { changeFilterSector, selectFilter } from '../store/slice'
import style from './style.module.css'

const FilterSector = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()

  const filter = useSelector(selectFilter)
  const { ICBs } = useGetICB()
  const listSector = ICBs.filter((item) => item.icbLevel === 2).map((item) => ({
    icbNameCustom: item.icbName + ' L2',
    ...item,
  }))

  const [isShow, setIsShow] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const toggleDropSearch = () => {
    setIsShow(!isShow)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    setKeySearch('')
  }

  const changeValueSector = (val) => {
    dispatch(changeFilterSector(val))
    hideDropSearch()
  }

  return (
    <div className="mb-40">
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.topMover.LB_SECTOR" />
      </Span>
      <div className="mt-8">
        <div className="input-dropdown" ref={parentDropdownRef}>
          <span
            className={`span-input w-100 ${style.inputDropdown} `}
            style={{ padding: '2px 20px 2px 8px' }}
            ref={dropdownRef}
            onClick={toggleDropSearch}
          >
            {Object.keys(filter.sector).length === 0 ? (
              <Translate value="market.topMover.LB_ALL_SECTOR" />
            ) : (
              filter.sector?.icbNameCustom
            )}
            <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
          </span>
          <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
            <HandleClickOutside
              handleClickOutside={hideDropSearch}
              exceptRef={dropdownRef}
            >
              <div className="input-dropdown" style={{ marginTop: 2 }}>
                <div
                  className={`dropdown-container ${style.dropdownContainerSearch}`}
                  style={{ width: dropdownRef?.current?.clientWidth }}
                >
                  <div
                    className="form-search-inbox"
                    style={{ width: 'auto', margin: '4px 8px 8px 8px' }}
                  >
                    <Input
                      style={{
                        fontSize: 11,
                      }}
                      type="text"
                      className={`search-inbox ${style.inputSearch}`}
                      placeholder={I18n.t('common.SEARCH')}
                      onChange={debounce((e) =>
                        setKeySearch(e.target.value?.toLowerCase()),
                      )}
                    />
                    <button type="button">
                      <i className="icon-search-2" />
                    </button>
                  </div>
                  <ScrollComponent autoHeight={true} autoHeightMax={200}>
                    <ul className="list-check">
                      {!keySearch && (
                        <li>
                          <a onClick={() => changeValueSector({})}>
                            <Span style={{ fontSize: 12 }}>
                              <Translate value="market.topMover.LB_ALL_SECTOR" />
                            </Span>
                          </a>
                        </li>
                      )}
                      {listSector.length &&
                        listSector
                          .filter((item) =>
                            item.icbNameCustom
                              ?.toLowerCase()
                              .includes(keySearch),
                          )
                          .map((sector) => {
                            return (
                              <li
                                key={sector.icbId}
                                onClick={() => changeValueSector(sector)}
                              >
                                <a>
                                  <Span style={{ fontSize: 12 }}>
                                    {sector.icbNameCustom}
                                  </Span>
                                </a>
                              </li>
                            )
                          })}
                    </ul>
                  </ScrollComponent>
                </div>
              </div>
            </HandleClickOutside>
          </Dropdown>
        </div>
      </div>
    </div>
  )
}

export default FilterSector
