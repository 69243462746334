export const TIME_FRAME_TOP_NET_VALUE = {
  ONE_DAY: {
    title: '1D',
    value: 'OneDay',
  },
  ONE_WEEK: {
    title: '1W',
    value: 'OneWeek',
  },
  ONE_MONTH: {
    title: '1M',
    value: 'OneMonth',
  },
  THREE_MONTH: {
    title: '3M',
    value: 'ThreeMonths',
  },
  SIX_MONTH: {
    title: '6M',
    value: 'SixMonths',
  },
  ONE_YEAR: {
    title: '1Y',
    value: 'OneYear',
  },
}

export const TOP_COUNT = 30

export const VALUE_TYPE = {
  BUY: 'BUY',
  SELL: 'SELL',
}

export const indicator = {
  TICKER: 'ticker',
  PRICE: 'closePriceAdjusted',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_BUY_VALUE: 'foreignBuyValueTotal',
  TOTAL_SELL_VALUE: 'foreignSellValueTotal',
  NET_TOTAL_VALUE: 'totalNetValue',
  PERCENT_OWN: 'foreignCurrentPercent',
}
