import style from './index.module.css'
import { useState } from 'react'
import { SharePopup } from './SharePopup'
import { Button } from '../html/Button'
import PropTypes from 'prop-types'
import { Icon } from '../html/Icon'
import { Translate } from 'react-redux-i18n'

export const ShareButton = ({ url }) => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(true)
  }

  return (
    <>
      <Button
        style={{ fontSize: 11 }}
        className={style.button}
        onClick={onClick}
      >
        <Icon
          className={['icon-share-2', style.iconShare].join(' ')}
          style={{ fontSize: 9 }}
        />
        <Translate value="common.shareButton.SHARE" />
      </Button>
      {isShow && <SharePopup setIsShow={setIsShow} url={url || ''} />}
    </>
  )
}

ShareButton.propTypes = {
  url: PropTypes.string,
}
