import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'
import { changeQuarter, changeRetryFlag, changeYear } from './slice'

export const getTopRevenueChartData = createAsyncThunk(
  'sector/sectorConstituents/overview/TOP_REVENUE',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getTopRevenueChartData(params)
      if (response.requestParams) {
        dispatch(changeYear(response.requestParams.Year))
        dispatch(changeQuarter(response.requestParams.Quarter || 0))
      }
      dispatch(changeRetryFlag(false))
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTopTotalOperatingIncomeChartData = createAsyncThunk(
  'sector/sectorConstituents/overview/TOP_TOTAL_OPERATING_INCOME',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response =
        await SectorConstituentsOverviewService.getTopTotalOperatingIncomeChartData(
          params,
        )
      if (response.requestParams) {
        dispatch(changeYear(response.requestParams.Year))
        dispatch(changeQuarter(response.requestParams.Quarter || 0))
      }
      dispatch(changeRetryFlag(false))
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
