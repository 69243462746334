import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getValueChartDetails } from '../../table/helpers'
import { getChartShortRetails } from './thunk'

export const slice = createSlice({
  name: 'economy/productionAndConsumption/consumption/retailService',
  initialState: {
    data: {},
    loading: false,
    locale: null,
  },
  reducers: {
    changeLocale: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartShortRetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getChartShortRetails.fulfilled, (state, action) => {
      const data = getValueChartDetails(
        action.payload || [],
        action.meta.arg?.DataType,
        state.locale,
      )
      state.data = data
      state.loading = false
    })
    builder.addCase(getChartShortRetails.rejected, (state, action) => {
      state.loading = false
    })
  },
})

export const selectChartLoading = (state) => state[slice.name].loading
export const selectChartDataRetails = (state) => state[slice.name].data

export const { changeLocale } = slice.actions

register(slice.name, slice.reducer)
