import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import SelectCustom from '../../../../../common/selectCustom'
import TextEllipsis from '../../../../../common/textEllipsis'
import { EXCHANGE_CODE } from '../../constant'
import style from './company.module.css'

const SelectExchange = ({ exchanges, setExchanges }) => {
  const onCheckboxChange = (e) => {
    const isChecked = e.target.checked
    const exchange = e.target.value
    let newExchanges

    if (isChecked) {
      if (
        exchange === EXCHANGE_CODE.ALL_EXCHANGE ||
        exchanges.length === Object.keys(EXCHANGE_CODE).length - 2
      ) {
        newExchanges = Object.values(EXCHANGE_CODE)
      } else {
        newExchanges = [...exchanges, exchange]
      }
    } else {
      if (exchange === EXCHANGE_CODE.ALL_EXCHANGE) {
        newExchanges = []
      } else {
        newExchanges = exchanges.filter(
          (e) => e !== exchange && e !== EXCHANGE_CODE.ALL_EXCHANGE,
        )
      }
    }
    setExchanges(newExchanges)
  }

  const renderItemSelect = ({
    styleComponent,
    key,
    item,
    currentItemRef,
    turnOffActiveSelected,
    heightItem,
    value,
  }) => {
    return (
      <li
        key={key}
        ref={value === item.value ? currentItemRef : undefined}
        className={styleComponent.li}
        style={{ height: `${heightItem}px` }}
        onMouseOver={turnOffActiveSelected}
      >
        <label className="d-flex">
          <div className={style.checkBox}>
            <input
              type="checkbox"
              className="checkbox line"
              checked={exchanges.includes(item.value)}
              value={item.value}
              onChange={onCheckboxChange}
            />
          </div>
          <div
            className={`${styleComponent.textDropdown} ${style.textDropdown}`}
          >
            <TextEllipsis isI18n={true} text={item.name} isHardWidth={false} />
          </div>
        </label>
      </li>
    )
  }

  const getTextPlaceholder = () => {
    if (exchanges.length === Object.values(EXCHANGE_CODE).length) {
      return I18n.t(
        'sector.sectorConstituents.segmentation.exchanges.ALL_EXCHANGE',
      )
    } else if (exchanges.length === 0) {
      return I18n.t('sector.sectorConstituents.segmentation.NO_EXCHANGE')
    } else {
      return exchanges.join(', ')
    }
  }

  return (
    <div>
      <Span style={{ opacity: 0.4, display: 'block' }}>
        <Translate value="sector.sectorConstituents.segmentation.EXCHANGES" />
      </Span>
      <div className={style.selectExchange}>
        <SelectCustom
          value={''}
          selectData={Object.keys(EXCHANGE_CODE).map((key) => ({
            name: 'sector.sectorConstituents.segmentation.exchanges.' + key,
            value: EXCHANGE_CODE[key],
          }))}
          textPlaceholder={
            <TextEllipsis
              isI18n={false}
              text={getTextPlaceholder()}
              isHardWidth={false}
            />
          }
          renderItemSelect={renderItemSelect}
        />
      </div>
    </div>
  )
}

export default SelectExchange
