import PropTypes from 'prop-types'
import { debounce } from '../../utils/Common'
import UseI18n from '../hooks/useI18n'
import { Input } from '../html/Input'

export const Search = ({
  onChange,
  width,
  height,
  margin,
  fontSize,
  appendStyle,
  placeholder,
}) => {
  const placeHolder = placeholder || UseI18n('common.SEARCH')

  return (
    <div
      className="form-search-inbox"
      style={{ width, margin, ...appendStyle }}
    >
      <Input
        style={{ fontSize, height }}
        type="text"
        className={'search-inbox bg-grey'}
        placeholder={placeHolder}
        onChange={debounce((e) => onChange(e.target.value))}
      />
      <button type="button">
        <i className="icon-search-2" />
      </button>
    </div>
  )
}

Search.propTypes = {
  onChange: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  margin: PropTypes.string,
  appendStyle: PropTypes.object,
  fontSize: PropTypes.number,
  placeholder: PropTypes.string,
}

Search.defaultProps = {
  onChange: () => {},
  width: 230,
  height: 20,
  margin: '0 8px',
  fontSize: 11,
  appendStyle: {},
}
