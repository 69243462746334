export const getOverrides = () => {
  return {
    'mainSeriesProperties.candleStyle.upColor': '#27A69A',
    'mainSeriesProperties.candleStyle.borderUpColor': '#27A69A',
    'mainSeriesProperties.candleStyle.downColor': '#EF5351',
    'mainSeriesProperties.candleStyle.borderDownColor': '#EF5351',
    'mainSeriesProperties.barStyle.upColor': '#379448',
    'mainSeriesProperties.barStyle.downColor': '#BB0001',
    'paneProperties.background': '#181C26',
    'paneProperties.vertGridProperties.color': '#1F2331',
    'paneProperties.horzGridProperties.color': '#1F2331',
  }
}

export const commonConfig = {
  autosize: true,
  timezone: 'Asia/Bangkok',
  debug: false,
  disabled_features: [
    'header_saveload',
    'header_screenshot',
    // 'header_fullscreen_button',
    'use_localstorage_for_settings',
    'display_market_status',
    'symbol_search_hot_key',
    'show_dialog_on_snapshot_ready',
  ],
  enabled_features: ['countdown'],
  studies_overrides: {
    'volume.volume.color.0': '#EF5351',
    'volume.volume.color.1': '#27A69A',
    'volume.show ma': true,
    'volume.volume ma.color': 'rgba(255,156,87, 0)',
  },
  time_frames: [
    { text: '1d', resolution: '1', title: '1D' },
    { text: '5d', resolution: '5', title: '5D' },
    { text: '1m', resolution: 'D', title: '1M' },
    { text: '3m', resolution: 'D', title: '3M' },
    { text: '1y', resolution: 'D', title: '1Y' },
    { text: '5y', resolution: 'W', title: '5Y' },
  ],
  custom_css_url: '/charting_library/style.css',
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const drawImageToCanvas = (ctx, content, dy) =>
  new Promise((resolve) => {
    const image = new Image()
    image.onload = () => {
      ctx.drawImage(image, 0, dy)
      resolve(true)
    }
    image.src = content
  })

export const getImage = (data) => {
  const width = data[0].contentWidth
  const imageData = data.reduce(
    (result, item) => {
      return {
        height: result.height + item.contentHeight,
        items: result.items.concat({
          ...item,
          dy: result.height,
        }),
      }
    },
    { height: 0, items: [] },
  )
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = imageData.height
  const ctx = canvas.getContext('2d')
  const promises = imageData.items.map((item) =>
    drawImageToCanvas(ctx, item.content, item.dy),
  )
  return Promise.all(promises).then(() => canvas.toDataURL('image/jpeg'))
}

export const takeScreenShot = (widget) =>
  new Promise((resolve) => {
    widget.showSaveAsChartDialog()
    widget.takeScreenshot()
    const onScreenShotReady = async ({ data }) => {
      widget.showSaveAsChartDialog()
      resolve(await getImage(data))
      widget.unsubscribe('onScreenshotReady', onScreenShotReady)
    }
    widget.subscribe('onScreenshotReady', onScreenShotReady)
  })
