import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import PopupChangeYAxisPosition from '../../../popup/popupChangeYAxisPosition'
import {
  changeEquations,
  selectEquations,
  selectIsPinTooltip,
  selectTooltipItems,
} from '../../store/slice'
import PinTooltipModal from '../pinTooltip'
import TooltipItem from '../pinTooltip/TooltipItem'
import { ChartContentDetail } from './ChartContentDetail'

export const ChartContent = ({
  width,
  height,
  data,
  schema,
  footer,
  listLegendCustom,
  styleSetting,
  isEdit,
  onChangeListLegendCustom,
  isShowFooter,
  onChangeListYAxisCustom,
  onClickTooltip,
  onRemoveAllTooltip,
  typeLegendYAxis,
}) => {
  const dispatch = useDispatch()

  const isPinTooltip = useSelector(selectIsPinTooltip)
  const tooltipItems = useSelector(selectTooltipItems)
  const equations = useSelector(selectEquations)

  const [isShow, setIsShow] = useState(false)
  const [valuePopup, setValuePopup] = useState({})

  // Actions
  const updateListLegendCustom = (data) => {
    let isUpdateEquation = false

    for (let index = 0; index < Object.keys(equations).length; index++) {
      const equation = equations[Object.keys(equations)[index]]
      if (equation.dataKey === data.key) {
        isUpdateEquation = true
        dispatch(
          changeEquations({
            key: Object.keys(equations)[index],
            value: { ...equation, equationIndicatorName: data.name },
          }),
        )
        break
      }
    }

    if (!isUpdateEquation) {
      onChangeListLegendCustom({
        [data.key]: data.name,
      })
    }
  }

  const handleClickYAxis = (value) => {
    setIsShow(true)
    setValuePopup(value)
  }

  const handleChangeYAxis = (value) => {
    onChangeListYAxisCustom({ [value.yAxisId]: value })
  }

  const handleClickTooltip = (clickPosition, baseEvent) => {
    if (isPinTooltip && clickPosition) {
      onClickTooltip({ clickPosition, baseEvent })
    }
  }

  // Use effect
  useEffect(() => {
    onRemoveAllTooltip()
  }, [JSON.stringify(schema), width, height, data])

  return (
    <div style={{ width, height }}>
      {!schema?.charts?.length ? (
        <div
          style={{ width, height }}
          className="d-flex ali-center justify-content-center"
        >
          <Span style={{ fontSize: 14 }}>
            <Translate value="financialChart.ADD_SECURITIES_AND_INDICATORS" />
          </Span>
        </div>
      ) : (
        <>
          <ChartContentDetail
            data={data}
            width={width}
            height={height}
            schema={schema}
            styleSetting={styleSetting}
            footer={footer}
            listLegendCustom={listLegendCustom}
            isShowFooter={isShowFooter}
            isPinTooltip={isPinTooltip}
            onClickTooltip={handleClickTooltip}
            onClickYAxis={handleClickYAxis}
            onUpdateListLegendCustom={updateListLegendCustom}
            isEdit={isEdit}
            typeLegendYAxis={typeLegendYAxis}
          />
          <PinTooltipModal
            isPinTooltip={isPinTooltip}
            listKey={Object.keys(tooltipItems)}
          >
            {Object.keys(tooltipItems).map((key) => (
              <TooltipItem
                key={key}
                activeIndex={key}
                timeRange={schema.xAxis[0]?.timeRange}
                tooltipSchema={schema?.tooltips}
              />
            ))}
          </PinTooltipModal>
          <PopupChangeYAxisPosition
            isShow={isShow}
            valuePopup={valuePopup}
            onShow={setIsShow}
            onChange={handleChangeYAxis}
          />
        </>
      )}
    </div>
  )
}

ChartContent.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  schema: PropTypes.object,
  footer: PropTypes.array,
  listLegendCustom: PropTypes.object,
  styleSetting: PropTypes.object,
  isEdit: PropTypes.bool,
  onChangeListLegendCustom: PropTypes.func,
  onChangeListYAxisCustom: PropTypes.func,
  onChangeStyleSettings: PropTypes.func,
  isShowFooter: PropTypes.bool,
}

ChartContent.defaultProps = {
  listLegendCustom: {},
  styleSetting: {},
  isEdit: false,
  onChangeListLegendCustom: () => {},
  onChangeStyleSettings: () => {},
  isShowFooter: true,
  onChangeListYAxisCustom: () => {},
}
