import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MARGIN_RECHARTS } from '../../../common/chart/constants'
import { StackedBarLineChart } from '../../../common/chart/customCharts/StackedBarLineChart'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { COLOR_STRUCTURE_CHART } from './constants'
import { getNameById } from './helper'
import {
  selectDataChart,
  selectIdLineChart,
  selectIds,
  selectKeepListCheckbox,
  selectLoading,
  selectOrigData,
} from './store/slice'
import { getChangeByTimeRange } from './store/thunk'

const MARGIN = { ...MARGIN_RECHARTS, left: 100 }
const CUSTOM_MARGIN_ONE_Y_AXIS = {
  ...MARGIN_RECHARTS,
  top: 20,
  left: 10,
  right: 40,
  bottom: 20,
}

export const ChartComponent = ({
  width,
  height,
  currentType,
  currentComponentFilter,
}) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const idsBarkey = useSelector(selectIds)
  const idLineChart = useSelector(selectIdLineChart)
  const origData = useSelector(selectOrigData)
  const isLoading = useSelector(selectLoading)
  const isKeepListCheckbox = useSelector(selectKeepListCheckbox)
  const locale = useSelector((state) => state.i18n.locale)

  const fillColor = {}
  const mappingDisplayName = {}
  for (const id of [...idsBarkey, ...idLineChart]) {
    fillColor[id] =
      COLOR_STRUCTURE_CHART[[...idsBarkey, ...idLineChart].indexOf(id)]
    mappingDisplayName[id] = getNameById(origData, Number(id))
  }

  const [listCheckbox, setListCheckbox] = useState([
    ...idsBarkey,
    ...idLineChart,
  ])

  useEffect(() => {
    if (!isKeepListCheckbox) {
      setListCheckbox([...idsBarkey, ...idLineChart])
    } else {
      setListCheckbox([...listCheckbox])
    }
  }, [idsBarkey, idLineChart, isKeepListCheckbox])

  useEffect(() => {
    dispatch(
      getChangeByTimeRange({
        DataType: currentType,
        TypeCode: currentComponentFilter,
      }),
    )
  }, [currentType, currentComponentFilter, locale])

  return width && height && isLoading ? (
    <Loading />
  ) : !dataChart.length ? (
    <NoData />
  ) : (
    <StackedBarLineChart
      data={dataChart}
      width={width}
      height={height}
      keyXAxis={'time'}
      barKeys={idsBarkey}
      lineKeys={idLineChart}
      decimalLength={0}
      leftLabel={`(${I18n.t(
        'economy.paymentBalance.paymentBalance.MILLION_USD',
      )})`}
      fillColor={fillColor}
      mappingDisplayName={mappingDisplayName}
      margin={MARGIN}
      timeFrame={TIME_RANGES.CUSTOM}
      isFooterResize
      isFooterCheckbox
      isLineFooterCheckbox
      isNotFormatXAxis
      unit={I18n.t('economy.paymentBalance.paymentBalance.MILLION_USD')}
      lineUnit={I18n.t('economy.paymentBalance.paymentBalance.MILLION_USD')}
      valueKeysColor={[...idsBarkey, ...idLineChart]}
      customStyleTooltipValue={(val) => (val < 0 ? '#ff4752' : '')}
      isOneYAxis
      listCheckbox={listCheckbox}
      setListCheckbox={setListCheckbox}
      customMarginForOneYAxis={CUSTOM_MARGIN_ONE_Y_AXIS}
    />
  )
}
