import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PenetrationAnalysisService from '../../../../../../../core/services/corporate/PenetrationAnalysisService'

export const getCorpRevenueChart = createAsyncThunk(
  'corporate/businessModel/penetration/corp/revenueChart/getCorpRevenueChart',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PenetrationAnalysisService.getCorpRevenueChart(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
