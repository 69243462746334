import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import { SEARCH_TIME_FRAME } from '../constants'
import { convertTimeFrame, formatDataChart } from '../helper'
import { ChartComponent } from './ChartComponent'
import {
  changeStateTimeFrame,
  selectLoading,
  selectNetValueInternalTrading,
  selectStateTimeFrame,
} from './store/slice'
import { getNetValueInternalTrading } from './store/thunk'
import style from './style.module.css'

const NetValueOfInternalTrading = ({ width, height }) => {
  const dispatch = useDispatch()
  const timezone = UseTimeZone()

  const changeStateTimeFrameSearch = (data) => {
    dispatch(changeStateTimeFrame(data.value))
  }

  const locale = useSelector((state) => state.i18n.locale)
  const showLoading = useSelector(selectLoading)
  const timeFrame = useSelector(selectStateTimeFrame)
  const data = useSelector(selectNetValueInternalTrading)
  const basicInfo = useSelector(selectIndexInfo)

  const [listCheckbox, setListCheckbox] = useState([
    'netDirectorDeal',
    'netInstitution',
  ])

  const [dataFormat, setDataFormat] = useState(data)

  const getLineKey = () => {
    if (listCheckbox.length === 1 && listCheckbox.includes('netDirectorDeal')) {
      return 'transCountDirectordeal'
    } else if (
      listCheckbox.length === 1 &&
      listCheckbox.includes('netInstitution')
    ) {
      return 'transCountInstitution'
    } else {
      return 'transactionCount'
    }
  }

  useEffect(() => {
    setDataFormat(
      formatDataChart(
        data,
        ['netDirectorDeal', 'netInstitution'],
        timeFrame,
        locale,
        getLineKey(),
        false,
        timezone,
      ),
    )
  }, [data, locale, listCheckbox, timezone])

  useEffect(() => {
    if (basicInfo.groupId) {
      const params = {
        GroupId: basicInfo.groupId,
        Period: convertTimeFrame(timeFrame),
      }
      dispatch(getNetValueInternalTrading(params))
    }
  }, [timeFrame, basicInfo.groupId])

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex justify-content-end">
                <div style={{ width: 'auto' }}>
                  <DispatchActionTab
                    data={Object.keys(SEARCH_TIME_FRAME).map((key) => {
                      return {
                        title: I18n.t(
                          `market.marketInDepth.directorDeal.${key}`,
                        ),
                        value: SEARCH_TIME_FRAME[key],
                      }
                    })}
                    activeTab={timeFrame}
                    onChangeTab={changeStateTimeFrameSearch}
                  />
                </div>
              </div>
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {showLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {dataFormat.length ? (
                        <SizeTracker>
                          {(size1) => {
                            return (
                              <div
                                id="netValueOfInternalTrading"
                                className="position-relative"
                              >
                                {dataFormat.length !== 0 && size.height && (
                                  <ChartComponent
                                    data={dataFormat}
                                    width={width}
                                    height={height - size1.height - size.height}
                                    keyXAxis={'XAxis'}
                                    displayData={listCheckbox}
                                    timeFrame={timeFrame}
                                    barNetDirectorDealKey={'netDirectorDeal'}
                                    barNetInstitutionKey={'netInstitution'}
                                    lineKey={getLineKey()}
                                  />
                                )}
                                <Footer
                                  key={width}
                                  list={[
                                    {
                                      text: 'market.marketInDepth.directorDeal.DIRECTORS_AND_RELATED_PEOPLE',
                                      dataKey: 'netDirectorDeal',
                                      type: TYPE.SQUARE,
                                      before: {
                                        bgColor: '#0096eb',
                                      },
                                    },
                                    {
                                      text: 'market.marketInDepth.directorDeal.INSTITUTIONAL_SHAREHOLDERS',
                                      dataKey: 'netInstitution',
                                      type: TYPE.SQUARE,
                                      before: {
                                        bgColor: '#fff6df',
                                      },
                                    },
                                    {
                                      text: 'market.marketInDepth.directorDeal.NO_OF_TRANSACTIONS',
                                      type: TYPE.CIRCLE_MARKER_LINE,
                                      before: {
                                        bgColor: '#f7b80c',
                                      },
                                      after: {
                                        bgColor: '#f7b80c',
                                      },
                                    },
                                  ]}
                                  listCheckbox={listCheckbox}
                                  setListCheckbox={setListCheckbox}
                                />
                              </div>
                            )
                          }}
                        </SizeTracker>
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
              <div className={style.labelFooterChart}>
                <Span style={{ fontSize: 11 }}>
                  <TextEllipsis
                    isI18n={true}
                    text={'market.marketInDepth.directorDeal.LB_FOOTER_CHART'}
                  />
                </Span>
              </div>
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}

export default NetValueOfInternalTrading
