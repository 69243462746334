import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter'
import { useCurrentDateTime } from '../../../../common/hooks/useDateTime'
import { Span } from '../../../../common/html/Span'
import { FORMAT } from '../../../../utils/Datetime'
import { valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

const PieChartWithHover = ({ data, height, width }) => {
  const nowText = useCurrentDateTime(FORMAT.DATE)
  const makeToolTipChartContent = (data) => {
    return (
      <div className={style.chartTooltip}>
        <Span
          style={{ fontSize: 10, fontWeight: 400 }}
          className={style.chartTooltipDate}
        >
          {nowText}
        </Span>
        <ul>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {item.isI18n ? <Translate value={item.text} /> : item.text}:
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div style={{ width }}>
      <PieChartWithFooter
        height={height}
        width={width}
        data={data}
        pieChartProps={{ radius: 28 }}
        renderTooltip={() => makeToolTipChartContent(data)}
        isUseContainerFooter
        footerProps={{ numRow: 2 }}
      />
    </div>
  )
}

export default PieChartWithHover
