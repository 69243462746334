import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import TextEllipsis from '../../../../../common/textEllipsis'
import {
  statisticalMethodDefaultParams,
  statisticalMethodDisplay,
  statisticalMethodTypes,
  statisticalMethodUpperLowerDifference,
} from '../../constants'
import styles from '../../style.module.css'
import { MethodItemComponent } from './MethodItemComponent'

const ALL_KEY = 'all'

export const MethodSelectComponent = ({
  statisticalMethodSettingsRef,
  data,
  onChange,
}) => {
  const locale = useSelector((state) => state.i18n.locale)

  const [isCollapse, setIsCollapse] = useState(true)

  const isCheckAll = useMemo(() => {
    return (
      !Object.values(data).some((item) => !item.isCheck) &&
      Object.keys(data).length === Object.keys(statisticalMethodTypes).length
    )
  }, [data])

  const isUncheckAll = useMemo(() => {
    return !data || !Object.values(data).some((item) => item.isCheck)
  }, [data])

  const getLabel = useMemo(() => {
    if (isUncheckAll) return I18n.t('financialChart.NONE')

    if (isCheckAll) return I18n.t('financialChart.ALL')

    return Object.values(data)
      .reduce(
        (arr, item) =>
          item.isCheck
            ? [...arr, I18n.t(statisticalMethodDisplay[item.value])]
            : arr,
        [],
      )
      .join(', ')
  }, [data, locale])

  const handleSelectMethod = (item) => {
    onChange({ ...data, [item.value]: item })
  }

  const handleSelectAll = () => {
    let newData = {}
    if (isCheckAll) {
      newData = Object.keys(data).reduce(
        (obj, key) => ({ ...obj, [key]: { ...data[key], isCheck: false } }),
        {},
      )
    } else {
      newData = Object.values(statisticalMethodTypes).reduce(
        (obj, val) => ({
          ...obj,
          [val]: {
            ...statisticalMethodDefaultParams,
            ...(data || data[val] ? data[val] : {}),
            value: val,
            isCheck: true,
          },
        }),
        {},
      )
    }
    onChange(newData)
  }

  return (
    <div className="w-100">
      <div
        className={[
          styles.settingBlockItemSelect,
          !isCollapse ? styles.settingBlockItemSelectExpand : '',
        ].join(' ')}
        onClick={() => setIsCollapse(!isCollapse)}
      >
        <div style={{ width: `calc(100% - 30px)` }}>
          <TextEllipsis text={getLabel} isI18n={false} />
        </div>
        <i
          className={isCollapse ? 'icon-arrow-down' : 'icon-arrow-up'}
          style={{ fontSize: 10, color: '#8b8b8b' }}
        />
      </div>
      {!isCollapse && (
        <div className={styles.settingBlockItemSelectContent}>
          <MethodItemComponent
            data={{ value: ALL_KEY, isCheck: isCheckAll }}
            onChange={handleSelectAll}
          />
          {Object.values(statisticalMethodTypes).map((val) => (
            <MethodItemComponent
              key={val}
              statisticalMethodSettingsRef={statisticalMethodSettingsRef}
              data={
                data[val] || {
                  value: val,
                  ...statisticalMethodDefaultParams,
                  upperLowerDifference:
                    statisticalMethodUpperLowerDifference[val],
                }
              }
              isShowSetting
              onChange={handleSelectMethod}
            />
          ))}
        </div>
      )}
    </div>
  )
}
