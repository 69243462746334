import { I18n } from 'react-redux-i18n'

export const getCountryNameById = (data, id) => {
  return data.find((item) => item.id === id)?.name
}

export const getStructureChartName = (locale, isImportData, activeName) => {
  if (locale === 'en') {
    return `${activeName} ${
      isImportData
        ? I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.STRUCTURE_IMPORT',
          )
        : I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.STRUCTURE',
          )
    }`
  } else {
    return `${
      isImportData
        ? I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.STRUCTURE_IMPORT',
          )
        : I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.STRUCTURE',
          )
    } ${activeName} ${I18n.t(
      'economy.importExport.importExport.exportImportByCommodities.BY_PARTNERS',
    )}`
  }
}

export const getValueChartName = (locale, isImportData, activeName) => {
  if (locale === 'en') {
    return `${activeName} ${
      isImportData
        ? I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.VALUE_YEARLY_IMPORT',
          )
        : I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.VALUE_YEARLY',
          )
    }`
  } else {
    return `${
      isImportData
        ? I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.VALUE_YEARLY_IMPORT',
          )
        : I18n.t(
            'economy.importExport.importExport.exportImportByCommodities.VALUE_YEARLY',
          )
    } ${activeName} ${I18n.t(
      'economy.importExport.importExport.exportImportByCommodities.YEARLY',
    )}`
  }
}
