import SteelProxy from '../../../proxies/sector/sectorSpecific/SteelProxy'
import { ServiceBase } from '../../ServiceBase'

class SteelService extends ServiceBase {
  getOverview(params) {
    return this.getData(() => SteelProxy.getOverview(params))
  }

  getNews(params) {
    return this.getData(() => SteelProxy.getNews(params))
  }

  getReports(params) {
    return this.getData(() => SteelProxy.getReports(params))
  }

  getSteelTypes(params) {
    return this.getData(() => SteelProxy.getSteelTypes(params))
  }

  getProductionAndConsumption(params) {
    return this.getData(() => SteelProxy.getProductionAndConsumption(params))
  }

  getInventory(params) {
    return this.getData(() => SteelProxy.getInventory(params))
  }

  getExport(params) {
    return this.getData(() => SteelProxy.getExport(params))
  }

  getConstructionStatistics(params) {
    return this.getData(() => SteelProxy.getConstructionStatistics(params))
  }

  getDownloadConstructionStatistic(params) {
    return this.getFileDownload(() =>
      SteelProxy.getDownloadConstructionStatistic(params),
    )
  }

  getConstructionPeersComparison(params) {
    return this.getData(() => SteelProxy.getConstructionPeersComparison(params))
  }

  getConstructionCompanyGrowth(params) {
    return this.getData(() => SteelProxy.getConstructionCompanyGrowth(params))
  }

  getOrganizations(params) {
    return this.getData(() => SteelProxy.getOrganizations(params))
  }

  getSteelPipeStatistics(params) {
    return this.getData(() => SteelProxy.getSteelPipeStatistics(params))
  }

  getDownloadSteelPipeStatistic(params) {
    return this.getFileDownload(() =>
      SteelProxy.getDownloadSteelPipeStatistic(params),
    )
  }

  getSteelPipePeersComparison(params) {
    return this.getData(() => SteelProxy.getSteelPipePeersComparison(params))
  }

  getSteelPipeCompanyGrowth(params) {
    return this.getData(() => SteelProxy.getSteelPipeCompanyGrowth(params))
  }

  getSteelCoatStatistics(params) {
    return this.getData(() => SteelProxy.getSteelCoatStatistics(params))
  }

  getDownloadSteelCoatStatistic(params) {
    return this.getFileDownload(() =>
      SteelProxy.getDownloadSteelCoatStatistic(params),
    )
  }

  getSteelCoatPeersComparison(params) {
    return this.getData(() => SteelProxy.getSteelCoatPeersComparison(params))
  }

  getSteelCoatCompanyGrowth(params) {
    return this.getData(() => SteelProxy.getSteelCoatCompanyGrowth(params))
  }

  getSteelCoilStatistics(params) {
    return this.getData(() => SteelProxy.getSteelCoilStatistics(params))
  }

  getDownloadSteelCoilStatistic(params) {
    return this.getFileDownload(() =>
      SteelProxy.getDownloadSteelCoilStatistic(params),
    )
  }

  getSteelCoilPeersComparison(params) {
    return this.getData(() => SteelProxy.getSteelCoilPeersComparison(params))
  }

  getSteelCoilCompanyGrowth(params) {
    return this.getData(() => SteelProxy.getSteelCoilCompanyGrowth(params))
  }

  getPriceStatistics(params) {
    return this.getData(() => SteelProxy.getPriceStatistics(params))
  }

  getDownloadPriceStatistics(params) {
    return this.getFileDownload(() =>
      SteelProxy.getDownloadPriceStatistics(params),
    )
  }

  getPriceGrowth(params) {
    return this.getData(() => SteelProxy.getPriceGrowth(params))
  }

  getLatestPeriods(params) {
    return this.getData(() => SteelProxy.getLatestPeriods(params))
  }
}

export default new SteelService()
