import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { listEquationTypeTab } from './constants'
import { changeEquationType, selectEquationType } from './store/slice'
import style from './style.module.css'

const DispatchActionTabEquation = () => {
  const dispatch = useDispatch()

  const equationType = useSelector(selectEquationType)

  const handleChangeTab = (tab) => {
    dispatch(changeEquationType(tab.value))
  }

  return (
    <DispatchActionTab
      data={listEquationTypeTab}
      activeTab={equationType}
      containerStyle={{
        height: 24,
        backgroundColor: '#ffffff',
      }}
      itemClass={style.customTab}
      itemActiveClass={style.customTabActive}
      onChangeTab={handleChangeTab}
    />
  )
}

export default DispatchActionTabEquation
