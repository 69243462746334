import { corporate } from './corporate'
import { economy } from './economy'
import { indicesSector } from './indicesSector'
import { tradingData } from './tradingData'

export const dataExplorer = {
  corporate,
  indicesSector,
  economy,
  tradingData,
  TICKER: 'Cổ phiếu',
  SEARCH: 'Tìm kiếm',
  GROUP_STOCK: 'Nhóm cổ phiếu',
  INDEX_SECTOR: 'Chỉ số/ngành',
  EXCHANGE: 'Sàn',
  SECTOR: 'Ngành',
  WATCHLIST: 'Danh mục',
  INDICATOR: 'CHỈ SỐ',
  TEMPLATE: 'BIỂU MẪU',
  SORT_COLUMN: 'Sắp xếp cột',
  EXPORT: 'Xuất Excel',
  ASK_EXPORT: 'Bạn có muốn xuất excel:',
  SINGLE_EXPORT: 'Trên một trang tính',
  MULTI_EXPORT: 'Trên nhiều trang tính',
  ALL: 'Tất cả trang tính',
  SETTING: 'CÀI ĐẶT',
  SINGLE: 'Một kỳ báo cáo',
  MULTIPLE: 'Nhiều kỳ báo cáo',
  DAILY: 'Hằng ngày',
  DATE: 'Ngày',
  FROM: 'Từ',
  TO: 'đến',
  WEEKLY: 'Hàng tuần',
  WEEK: 'Tuần',
  MONTHLY: 'Hàng tháng',
  MONTH: 'Tháng',
  QUARTERLY: 'Hàng quý',
  QUARTER: 'Quý',
  YEARLY: 'Hàng năm',
  YEAR: 'Năm',
  ALL_SETTING: 'Tất cả',
  EDIT: 'Sửa',
  PERSONAL: 'Cá nhân',
  FIINX_TEMPLATE: 'Biểu mẫu FiinProX',
  LOAD: 'Tải dữ liệu',
  SHEET: 'Trang',
  NO: 'STT',
  NOTICE: 'LƯU Ý',
  ADD_ALL: 'Thêm toàn bộ các ngành con',
  ADD_ONE: 'Chỉ thêm cấp ngành này',
  INDICATOR_LABEL: 'Chỉ số',
  FS: 'BÁO CÁO TÀI CHÍNH',
  FS2: 'Báo cáo tài chính',
  FINANCIAL_TARGET: 'Mục tiêu tài chính',
  REPORT_TYPE: 'Loại báo cáo',
  REPORT_FORM: 'Kiểu công ty',
  AUDIT_STATUS: 'Loại kiểm toán',
  CURRENCY: 'Loại tiền tệ',
  UNIT: 'Loại đơn vị',
  SELECTED_ITEM: 'Các mục đã chọn',
  SELECTED: 'Đã chọn:',
  BILLION_VND: 'Tỷ VND',
  MILLION_VND: 'Triệu VND',
  THOUSAND_VND: 'Nghìn VND',
  COMPANY: 'Công ty',
  BANK: 'Ngân hàng',
  SERCURITY: 'Chứng khoán',
  NONLIFE_INSURANCE: 'Bảo hiểm phi nhân thọ',
  LIFE_INSURANCE: 'Bảo hiểm nhân thọ',
  MAX_TICKER: 'Chỉ có thể lưu tối đa 4000 mã chứng khoán vào bảng dữ liệu.',
  CREATE_TEMPLATE_SUCCESS: 'Biểu mẫu của bạn đã được tạo thành công.',
  UPDATE_TEMPLATE_SUCCESS: 'Biểu mẫu của bạn đã được cập nhật thành công.',
  COPY_TEMPLATE_SUCCESS: 'Đã sao chép biểu mẫu.',
  ISSUE_BOND: 'PHÁT HÀNH TRÁI PHIẾU',
  YEAR_REVIEW: 'Năm đánh giá',
  CASH_DIVIDEND_PAYOUT: 'TRẢ CỔ TỨC TIỀN MẶT',
  BOD: 'BAN LÃNH ĐẠO',
  MAJOR_SHAREHOLDER: 'CỔ ĐÔNG LỚN',
  ISSUE_SHARE: 'PHÁT HÀNH CỔ PHIẾU',
  ISSUE_METHOD: 'Hình thức phát hành',
  Overview: 'TỔNG QUAN',
  Valuation: 'ĐỊNH GIÁ',
  Credit: 'TÍN DỤNG',
  PERFORMANCE: 'BIẾN ĐỘNG GIÁ',
  IPO_YEAR: 'Năm IPO',
  NOTE_DETAIL_TEMPLATE:
    'Khi áp dụng biểu mẫu, các công ty không thuộc ngành hiện tại sẽ bị loại bỏ. Bạn vẫn chắc chắn muốn áp dụng?',
  OK: 'Đồng ý',
  SHARE_TEMPLATE: 'Sao chép link',
}
