import { maxBy, uniq } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { v4 as uuidv4 } from 'uuid'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown from '../../../common/dropdown'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { selectFullColumnId } from '../BondList/Table/store/slice'
import { getDataBondList } from '../BondList/Table/store/thunk'
import { TABLE_FIELD_CODES } from '../Screening/constants'
import { INDICATOR_GROUPS, TAB_TYPES } from '../constants'
import { formatAddInformation } from '../helper'
import {
  changeTemplate,
  changeTemplateList,
  changeTemplateMoreList,
  selectAddInformatics,
  selectDataRaw,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  selectTemplate,
  selectWatchlist,
  setFilterParameters,
} from '../store/slice'
import style from './index.module.css'

export const FilterWatchlistSelect = ({ width, redirectToBondIssuer }) => {
  const dispatch = useDispatch()
  const dropdownRef = useRef()

  const params = useSelector(selectFilterParams)
  const locale = useSelector((state) => state.i18n.locale)
  const data = useSelector(selectWatchlist)
  const templates = useSelector(selectTemplate)
  const rawData = useSelector(selectDataRaw)
  const listParent = useSelector(selectParentSectors)
  const addInformatics = useSelector(selectAddInformatics)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const listIds = useSelector(selectFullColumnId(1))

  const [watchListIds, setWatchListIds] = useState([])
  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handleClickItem = (id) => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)
    const newSectors =
      uniq([...listParent, ...params.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...params.sectors])

    const information = addInformatics.find(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.ISSUERS ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_ISSUERS,
    )
    const indexInformation = params.addedInformations.findIndex(
      (item) =>
        item.tableFieldCode === TABLE_FIELD_CODES.ISSUERS ||
        item.tableFieldCode === TABLE_FIELD_CODES.EN_ISSUERS,
    )

    const newWatchlistIds = [id]

    dispatch(
      setFilterParameters({ key: 'watchListId', value: newWatchlistIds }),
    )

    if (raw) {
      if (!information) return

      const { indicatorId, tableCode, tableFieldCode } = information

      let newAddedInformatics = params.addedInformations

      if (!!newWatchlistIds.length && indexInformation === -1) {
        const max = maxBy(newAddedInformatics, (e) => e.order)

        newAddedInformatics = [
          ...newAddedInformatics,
          {
            id: uuidv4(),
            order: (max?.order ?? 0) + 1,
            indicatorId,
            tableCode,
            tableFieldCode,
          },
        ]
      }

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: newAddedInformatics,
        }),
      )

      dispatch(
        changeTemplate({
          ...rawData,
          parameter: {
            ...rawData.parameter,
            addedInformations: formatAddInformation(
              newAddedInformatics,
              listIds,
            ),
            watchListId: newWatchlistIds,
          },
        }),
      )

      const newParams = {
        ...params,
        watchListId: newWatchlistIds,
        sectors: newSectors,
        conditions: params.conditions,
        addedInformations: formatAddInformation(newAddedInformatics, listIds),
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

      handleHide()
      return
    }
    const data = {
      templateId: TAB_TYPES.RAW,
      templateName:
        params.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
          ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
          : 'bond.bondScreening.filter.TEMPLATE_NAME',
      templateType: params.indicatorGroup,
    }

    const newTemplateIds = [
      ...templates.slice(0, 6),
      data,
      ...templates.slice(6),
    ]

    dispatch(changeTemplateList(newTemplateIds))
    dispatch(changeTemplateMoreList(newTemplateIds))

    if (!information) return

    const { indicatorId, tableCode, tableFieldCode } = information

    let newAddedInformatics = params.addedInformations

    if (!!newWatchlistIds.length && indexInformation === -1) {
      const max = maxBy(newAddedInformatics, (e) => e.order)

      newAddedInformatics = [
        ...newAddedInformatics,
        {
          id: uuidv4(),
          order: (max?.order ?? 0) + 1,
          indicatorId,
          tableCode,
          tableFieldCode,
        },
      ]
    }

    dispatch(
      setFilterParameters({
        key: 'addedInformations',
        value: newAddedInformatics,
      }),
    )

    dispatch(
      changeTemplate({
        ...data,
        parameter: {
          ...params,
          watchListId: newWatchlistIds,
          addedInformations: formatAddInformation(newAddedInformatics, listIds),
        },
      }),
    )

    const newParams = {
      ...params,
      watchListId: newWatchlistIds,
      sectors: newSectors,
      conditions: params.conditions,
      addedInformations: formatAddInformation(newAddedInformatics, listIds),
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

    handleHide()
  }

  useEffect(() => {
    if (isShowDropdown) {
      if (!keySearch.length) {
        setWatchListIds(!!data?.length ? data : [])
        return
      }

      const newWatchListIds = data.filter((item) =>
        item?.watchListName?.toLowerCase()?.includes(keySearch?.toLowerCase()),
      )

      setWatchListIds(newWatchListIds)
    }
  }, [keySearch.length, data?.length, isShowDropdown])

  const placeholderInput = useMemo(
    () => I18n.t('bond.bondScreening.bondList.WATCHLIST'),
    [locale],
  )

  useEffect(() => {
    if (!!data?.length) {
      setWatchListIds(data)
    }
  }, [data?.length])

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button" style={{ top: 2 }}>
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {!!watchListIds?.length ? (
                <ScrollComponent autoHeight={true} autoHeightMax={200}>
                  <ul className="list-check">
                    {watchListIds.map((item, index) => {
                      return (
                        item.watchListId && (
                          <li key={`key-${index}`}>
                            <a
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => handleClickItem(item.watchListId)}
                            >
                              <label className="d-flex ali-center">
                                <TextEllipsis
                                  text={`${item?.watchListName ?? ''}`}
                                  zIndexTooltip={999}
                                  isI18n={false}
                                />
                              </label>
                            </a>
                          </li>
                        )
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
