import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getSelectTimeOptions, SELECT_VALUES } from '../filter/DateRange'
import { getFilingCategoriesThunk, getFilingsThunk } from './thunk'

export const ALL_VALUE = 0

const initialState = {
  filter: {
    startDate: getSelectTimeOptions()[SELECT_VALUES.LAST_YEAR].startDate,
    endDate: getSelectTimeOptions()[SELECT_VALUES.LAST_YEAR].endDate,
    categoryId: ALL_VALUE,
    subCategoryId: ALL_VALUE,
    page: 1,
    totalPage: 1,
  },
  loading: {
    category: true,
    filing: true,
  },
  categories: [],
  subCategories: {},
  filingIds: [],
  filingById: {},
  reCalcWidths: false,
}

const slice = createSlice({
  name: 'corporate/filings',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    clearData: (state) => {
      state.filingIds = []
      state.filingById = {}
    },
    changeStartDate: (state, action) => {
      state.filter.startDate = action.payload
    },
    changeEndDate: (state, action) => {
      state.filter.endDate = action.payload
      state.filter.page = 1
    },
    changeFilterCategoryId: (state, action) => {
      state.filter.categoryId = action.payload
      state.filter.subCategoryId = 0
      state.filter.page = 1
    },
    changeFilterSubCategoryId: (state, action) => {
      state.filter.subCategoryId = action.payload
      state.filter.page = 1
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFilingsThunk.pending, (state) => {
      state.loading.filing = true
    })
    builder.addCase(getFilingsThunk.fulfilled, (state, action) => {
      const data =
        action.payload.data?.map((item, index) => ({
          ...item,
          id: item.reportDateId + index,
        })) || []
      state.filingIds = data.map((item) => item.id)
      state.filingById = keyBy(data, 'id')
      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = !state.reCalcWidths
      state.loading.filing = false
    })
    builder.addCase(getFilingCategoriesThunk.pending, (state) => {
      state.loading.category = true
    })
    builder.addCase(getFilingCategoriesThunk.fulfilled, (state, action) => {
      state.loading.category = false
      const categories =
        action.payload?.filter((cat) => !cat.parentReportTypeId) || []

      let subCategories = {}
      categories.forEach((cat) => {
        subCategories[cat.reportTypeId] = action.payload.filter(
          (subCat) => subCat.parentReportTypeId === cat.reportTypeId,
        )
      })

      state.categories = categories
      state.subCategories = subCategories
    })
  },
})

export const selectFilterStartDate = (state) =>
  state[slice.name].filter.startDate
export const selectFilterEndDate = (state) => state[slice.name].filter.endDate
export const selectFilterCategoryId = (state) =>
  state[slice.name].filter.categoryId
export const selectFilterSubCategoryId = (state) =>
  state[slice.name].filter.subCategoryId
export const selectTotalPage = (state) => state[slice.name].filter.totalPage
export const selectCurrentPage = (state) => state[slice.name].filter.page

export const selectLoadingCategory = (state) =>
  state[slice.name].loading.category
export const selectLoadingFiling = (state) => state[slice.name].loading.filing

export const selectCategories = (state) => state[slice.name].categories
export const selectSubCategoriesByParentId = (parentId) => (state) =>
  state[slice.name].subCategories[parentId]
export const selectFilingIds = (state) => state[slice.name].filingIds
export const selectFilingValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].filingById[id], attr)
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths

export const {
  resetStore,
  clearData,
  changeStartDate,
  changeEndDate,
  changeFilterCategoryId,
  changeFilterSubCategoryId,
  changePage,
} = slice.actions

register(slice.name, slice.reducer)
