import { createAsyncThunk } from '@reduxjs/toolkit'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import CoveredWarrantService from '../../../../../core/services/market/CoveredWarrantService'

export const getSearchWarrant = createAsyncThunk(
  'common/warrantTopInfo/GET_ALL',
  async (params, { rejectWithValue }) => {
    const response = await CoveredWarrantService.getAll()
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getLatestPrice = createAsyncThunk(
  'common/warrantTopInfo/GET_LATEST_PRICE',
  async (params, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestPrice(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
