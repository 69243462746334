import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import GDPSpendingMethodService from '../../../../../../core/services/economy/gdp/GDPSpendingMethodService'
import { handleExport } from '../../../../../utils/Export'

export const getGDPBySpendingMethodStructure = createAsyncThunk(
  'economy/gdp/gdpBySpendingMethod/panelGDPStructure/getGDPBySpendingMethodStructure',
  async (params, { rejectWithValue }) => {
    try {
      const res =
        await GDPSpendingMethodService.getGDPBySpendingMethodStructure(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getGDPTypeName = createAsyncThunk(
  'economy/gdp/gdpBySpendingMethod/panelGDPStructure/getGDPTypeName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await GDPSpendingMethodService.getGDPTypeName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadGDPBySpendingMethodStructure = createAsyncThunk(
  'economy/gdp/gdpBySpendingMethod/panelGDPStructure/downloadGDPBySpendingMethodStructure',
  async (data) => {
    const response =
      await GDPSpendingMethodService.downloadGDPBySpendingMethodStructure(data)
    handleExport(response.data, response.filename)
  },
)
