import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import InputFormatCurrency from '../../../../../common/input/InputFormatCurrency'
import style from './company.module.css'

const SelectMarketCap = ({ marketCap, setMarketCap }) => {
  const changeMarketCapFrom = (val) => {
    const from = val ? Number(val) : null
    if (marketCap.from !== from) {
      setMarketCap({ ...marketCap, from })
    }
  }

  const changeMarketCapTo = (val) => {
    const to = val ? Number(val) : null
    if (marketCap.to !== to) {
      setMarketCap({ ...marketCap, to })
    }
  }

  return (
    <div style={{ maxWidth: 250, marginLeft: 16 }}>
      <Span style={{ opacity: 0.4, display: 'block', marginBottom: 4 }}>
        <Translate value="sector.sectorConstituents.segmentation.MARKET_CAP_LABEL" />
      </Span>
      <div className="d-flex">
        <div className="form-control mb-0">
          <InputFormatCurrency
            defaultValue={marketCap.from}
            handleBlur={changeMarketCapFrom}
            suffix={'B'}
            className={style.inputMarketCap}
          />
        </div>
        <span className="ml-8 mr-8">-</span>
        <div className="form-control mb-0">
          <InputFormatCurrency
            defaultValue={marketCap.to}
            handleBlur={changeMarketCapTo}
            suffix={'B'}
            className={style.inputMarketCap}
          />
        </div>
      </div>
    </div>
  )
}

export default SelectMarketCap
