import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'

const FirstCellColumn = ({
  val,
  props,
  level,
  childrenIds,
  rowId,
  onRowCollapse,
  isRowCollapse,
}) => {
  const [isCollapse, setIsCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <td {...props} className={style.firstCellColumn}>
      <div
        className={`d-flex align-center`}
        style={{
          marginLeft: (level - 1) * 20,
          cursor: childrenIds ? 'pointer' : 'default',
          color: childrenIds ? '#FECF2F' : '#ECECEC',
        }}
        onClick={onCollapse}
      >
        {childrenIds ? (
          <button
            style={{
              paddingLeft: 0,
              paddingRight: 8,
            }}
            className="align-center"
            onClick={onCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            />
          </button>
        ) : (
          <></>
        )}
        <Span>
          <Translate
            value={val}
            style={{ fontStyle: childrenIds ? 'normal' : 'oblique' }}
          />
        </Span>
      </div>
      <div className={style.borderLeftScroll} />
    </td>
  )
}

export default FirstCellColumn
