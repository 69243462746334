export const ValueTypeShareHolders = {
  IndividualOwner: 'IndividualOwner',
  OrganizationOwner: 'OrganizationOwner',
}

export const listTypeShareHolders = [
  {
    label: 'bond.interconnectionMap.filter.ShareHoldersType.IndividualOwner',
    values: ValueTypeShareHolders.IndividualOwner,
  },
  {
    label: 'bond.interconnectionMap.filter.ShareHoldersType.OrganizationOwner',
    values: ValueTypeShareHolders.OrganizationOwner,
  },
]

export const ValuePosition = {
  Chairman: 3,
  CEO: 7,
}

export const listPosition = [
  {
    label: 'bond.interconnectionMap.filter.Position.Chairman',
    values: ValuePosition.Chairman,
  },
  {
    label: 'bond.interconnectionMap.filter.Position.CEO',
    values: ValuePosition.CEO,
  },
]

export const ValueRelations = {
  SubsidiaryAffiliate: 'Affliate',
  PaymentGuarantee: 'Guarantee',
}

export const listRelations = [
  {
    label: 'bond.interconnectionMap.filter.Relations.SubsidiaryAffiliate',
    values: ValueRelations.SubsidiaryAffiliate,
  },
  {
    label: 'bond.interconnectionMap.filter.Relations.PaymentGuarantee',
    values: ValueRelations.PaymentGuarantee,
  },
]

export const ValueFurtherInformation = {
  OwnershipRate: 'Percentage',
  OutstandingBond: 'OutstandingBond',
  PaymentGuarantee: 'TotalValue',
}

export const listFurtherInformation = [
  {
    label: 'bond.interconnectionMap.filter.FurtherInformation.OwnershipRate',
    values: ValueFurtherInformation.OwnershipRate,
  },
  {
    label: 'bond.interconnectionMap.filter.FurtherInformation.OutstandingBond',
    values: ValueFurtherInformation.OutstandingBond,
  },
  {
    label: 'bond.interconnectionMap.filter.FurtherInformation.PaymentGuarantee',
    values: ValueFurtherInformation.PaymentGuarantee,
  },
]
