import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { HEADER_VALUE } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import {
  getLocationId,
  getYear,
} from '../panelGRDPStructureProvince/store/slice'
import { getNominalRealValue } from '../store/slice'
import ChartComponent from './ChartComponent'
import { CHANGES_IN_GRDP_STRUCTURE } from './PanelGRDPChanges'
import {
  getStackedBarChartData,
  getStackedBarChartLoading,
  getStackKey,
} from './store/slice'
import { getChangesInGRDPStructureBySectorInProvince } from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const GRDPChanges = ({ height, width }) => {
  const dispatch = useDispatch()

  const stackedChartLoading = useSelector(getStackedBarChartLoading)
  const stackedChartData = useSelector(getStackedBarChartData)
  const locationId = useSelector(getLocationId)
  const nominalRealValue = useSelector(getNominalRealValue)
  const year = useSelector(getYear)
  const locale = useSelector((state) => state.i18n.locale)
  const stackKey = useSelector(getStackKey)

  useEffect(() => {
    dispatch(
      getChangesInGRDPStructureBySectorInProvince({
        LocationId: locationId,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: GDP_PROVINCE_VALUE.VALUE,
        Year: year,
      }),
    )
  }, [locale, locationId, nominalRealValue, year])

  if (stackedChartLoading) {
    return <Loading />
  }

  if (stackedChartData.length === 0) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div id={CHANGES_IN_GRDP_STRUCTURE}>
            {(size.height || size.height === 0) && (
              <ChartComponent
                data={stackedChartData}
                width={width}
                height={height - size.height}
                keyXAxis={'year'}
                stackKey={stackKey}
              />
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}

export default GRDPChanges
