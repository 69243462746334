export const typeRowGroup = {
  ICB: 'ICB',
  INDEX: 'Index',
}

export const keyTableSectorInDepth = {
  DISPLAY_NAME: 'displayName',
  CHANGE: 'percentIndexChange',
  TRADING_VALUE: 'totalValue',
  VALUE: 'valContribution',
  AVG_TRADING_VALUE: 'avgTotalValue',
  CHANGE_VALUE: 'percentValueChange',
  TRADING_VOLUME: 'totalVolume',
  VOLUME: 'volContribution',
  AVG_TRADING_VOLUME: 'avgTotalVolume',
  CHANGE_VOLUME: 'percentVolumeChange',
}

export const keySchemaTableSectorInDepth = {
  DISPLAY_NAME: 'DISPLAY_NAME',
  CHANGE: 'CHANGE',
  TRADING_VALUE: 'TRADING_VALUE',
  VALUE: 'VALUE',
  AVG_TRADING_VALUE: 'AVG_TRADING_VALUE',
  CHANGE_VALUE: 'CHANGE_VALUE',
  TRADING_VOLUME: 'TRADING_VOLUME',
  VOLUME: 'VOLUME',
  AVG_TRADING_VOLUME: 'AVG_TRADING_VOLUME',
  CHANGE_VOLUME: 'CHANGE_VOLUME',
}

export const keyLastestSector = {
  DISPLAY_NAME: 'name',
  LAST: 'closePrice',
  ONE_DAY_CHANGE: 'priceChange',
  ONE_DAY_PERCENT_CHANGE: 'percentPriceChange',
  ONE_DAY_VALUE: 'totalValue',
  ONE_DAY_VOLUMN: 'totalVolume',
  FOREIGN_VALUE: 'foreignNetValue',
  FOREIGN_VOLUMN: 'foreignNetVolumn',
}

export const keySchemaLastestSector = {
  DISPLAY_NAME: 'DISPLAY_NAME',
  LAST: 'LAST',
  ONE_DAY_CHANGE: 'ONE_DAY_CHANGE',
  ONE_DAY_PERCENT_CHANGE: 'ONE_DAY_PERCENT_CHANGE',
  ONE_DAY_VALUE: 'ONE_DAY_VALUE',
  ONE_DAY_VOLUMN: 'ONE_DAY_VOLUMN',
  FOREIGN_VALUE: 'FOREIGN_VALUE',
  FOREIGN_VOLUMN: 'FOREIGN_VOLUMN',
}

export const keyTableTopValue = {
  NO: 'index',
  TICKER: 'ticker',
  PRICE: 'closePriceAdjusted',
  CHANGE: 'percentPriceChange',
  TRADING_VALUE: 'totalValue',
  LOCAL_INDIVIDUAL: 'localIndividual',
  LOCAL_INSTITUTION: 'localInstitution',
  FOREIGN: 'foreign',
  PROPRIETARY: 'proprietary',
}

export const keySchemaTableTopValue = {
  NO: 'NO',
  TICKER: 'TICKER',
  PRICE: 'PRICE',
  CHANGE: 'CHANGE',
  TRADING_VALUE: 'TRADING_VALUE',
  LOCAL_INDIVIDUAL: 'LOCAL_INDIVIDUAL',
  LOCAL_INSTITUTION: 'LOCAL_INSTITUTION',
  FOREIGN: 'FOREIGN',
  PROPRIETARY: 'PROPRIETARY',
}
