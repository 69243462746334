export const tradingData = {
  TRADING_DATA: 'Trading data',
  FILTER: 'Filter',
  SELECTED_ITEMS: 'Selected items',
  EXPORT_SETTING: 'Export setting',
  PREVIEW: 'Preview',
  COMPARE: 'Corporate',
  INDEX_AND_SECTOR: 'Index & Sector',
  LB_COMPANIES: 'Companies:',
  LB_SECTORS: 'Sector:',
  LB_INDICATORS: 'Indicators:',
  LB_INDICES_AND_SECTORS: 'Indices & Sectors:',
  LB_TIME: 'Time:',
  EXPORT_FILE: 'Export type:',
  A_FILE: 'A file',
  MULTIPLE_FILES: 'Multiple files',
  GROUP_BY: 'Group column by:',
  DATE: 'Date',
  COMPANY: 'Company',
  SECTOR: 'Sector',
  INDICATOR: 'Indicator',
  BUTTON_LOAD: 'Load',
  BUTTON_SAVE: 'Save',
  BUTTON_EXPORT_EXCEL: 'Export Excel',
  LB_TABLE:
    'This preview only shows [numIndicator] indicators of [numCompany] [viewType]. Please export Excel file to see full results.',
  MULTI_FILE_NOTE_TABLE:
    'This preview only shows 1 file representing 1 [viewType]. Please export Excel file to see full results.',
  VIEW_TYPE_COMPANY: 'company',
  VIEW_TYPE_COMPANIES: 'companies',
  VIEW_TYPE_SECTOR: 'sector',
  VIEW_TYPE_SECTORS: 'sectors',
  THREE_MONTH: '3 Months',
  SIX_MONTH: '6 Months',
  NINE_MONTH: '9 Months',
  ONE_YEAR: '1 Year',
  YTB: 'YTB',
  LB_FROM: 'From',
  LB_TO: 'To',
  WARNING_MAX_TICKERS: 'The number of tickers is no more than 100.',
  WARNING_MAX_INDICATORS: 'The total number of indicators is no more than 100.',
  TICKER: 'Ticker',
  COMPANY_NAME: 'Company name',
  NAME: 'Name',
  UNIT: 'Unit: ',
  SELECTED: 'Selected: ',
  MAX_TICKER_WARNING: 'Maximum [viewType] added are 100.',
  MAX_INDICATOR_WARNING: 'Maximum indicators added are 100.',
  RESET: 'Reset',
  NO_DATA_TEXT_CORPORATE:
    'Please tick in the checkbox of at least 1 company and 1 indicator to see Preview and export Excel.',
  NO_DATA_TEXT_INDEX_SECTOR:
    'Please tick in the checkbox of at least 1 index/sector and 1 indicator to see Preview and export Excel.',
  EXIST_INDEX_SECTOR: 'Index/sector is already existed.',
  EXIST_TICKER_SECTOR: 'Ticker is already existed.',
}
