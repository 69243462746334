import PropTypes from 'prop-types'
import { Loading } from '../loading'
import { NoData } from '../noData'
import { ScrollComponent } from '../ScrollComponent'
import { SizeTracker } from '../sizeTracker'
import { ItemWithImage } from './ItemWithImage'
import { MoreButton } from './moreButton'
import { Title } from './title'

export const List = ({
  title,
  isI18nTitle,
  data,
  isLoading,
  isNodata,
  numColum,
  childNumColumn,
  marginLeft,
  marginRight,
  childMargin,
  offsetWidth,
  onMoreAction,
  onDetailAction,
}) => {
  const renderContent = () => {
    if (isLoading || isLoading === undefined) {
      return <Loading />
    }

    if (isNodata) {
      return <NoData />
    }

    return (
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="col-header d-flex j-b ali-center mb-10">
                <Title title={title} isI18n={isI18nTitle} />
                <MoreButton onAction={onMoreAction} />
              </div>
              {size.height && (
                <div
                  className="w-100"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <ScrollComponent>
                    <div className="d-flex w-100" style={{ flexWrap: 'wrap' }}>
                      {data.map((item, index) => {
                        if (index % childNumColumn === 0) {
                          return (
                            <div
                              key={index}
                              style={{
                                width: `calc(100%/${childNumColumn} - ${childMargin}px)`,
                                marginRight: childMargin,
                              }}
                            >
                              <ItemWithImage
                                item={item}
                                onAction={onDetailAction(item.newsId)}
                              />
                            </div>
                          )
                        } else if (index % childNumColumn === 1) {
                          return (
                            <div
                              key={index}
                              style={{
                                width: `calc(100%/${childNumColumn} - ${childMargin}px)`,
                                marginLeft: childMargin,
                              }}
                            >
                              <ItemWithImage
                                item={item}
                                onAction={onDetailAction(item.newsId)}
                              />
                            </div>
                          )
                        }

                        return (
                          <div
                            key={index}
                            style={{
                              width: `calc(100%/${childNumColumn} - ${childMargin}px)`,
                              marginLeft: childMargin / 2,
                              marginRight: childMargin / 2,
                            }}
                          >
                            <ItemWithImage
                              item={item}
                              onAction={onDetailAction(item.newsId)}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </ScrollComponent>
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    )
  }
  return (
    <div
      className="position-relative"
      style={{
        width: `calc(100%/${numColum} - ${
          marginLeft + marginRight + offsetWidth
        }px)`,
        marginLeft,
        marginRight,
      }}
    >
      {renderContent()}
    </div>
  )
}

List.propTypes = {
  title: PropTypes.string.isRequired,
  isI18nTitle: PropTypes.bool,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isNodata: PropTypes.bool.isRequired,
  numColum: PropTypes.number,
  childNumColumn: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  childMargin: PropTypes.number,
  offsetWidth: PropTypes.number,
  onMoreAction: PropTypes.func,
}

List.defaultProps = {
  isI18nTitle: true,
  numColum: 3,
  childNumColumn: 1,
  marginLeft: 0,
  marginRight: 0,
  childMargin: 0,
  offsetWidth: 0,
  isLoading: false,
  onMoreAction: () => {},
}
