import { useDispatch } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import { getFontSize } from '../../../../../utils/FontSize'
import { removeCompareChecked } from '../popupValuation/store/slice'
import { changeLineActive, removeCompareSearch } from '../store/slice'
import style from './style.module.css'

const CompareItem = ({ compare }) => {
  const dispatch = useDispatch()
  const fontSize10 = getFontSize(10)

  const onClick = () => {
    dispatch(removeCompareSearch(compare))
    dispatch(removeCompareChecked(compare))
    dispatch(changeLineActive(null))
  }

  const HoverItemSector = () => {
    dispatch(changeLineActive(compare.idCompare))
  }

  const UnHoverItemSector = () => {
    dispatch(changeLineActive(null))
  }

  return (
    <div
      className={style.itemSector}
      onMouseEnter={HoverItemSector}
      onMouseLeave={UnHoverItemSector}
    >
      <div
        className={style.color}
        style={{ backgroundColor: compare.bgColor }}
      ></div>
      <div className={style.content}>
        <TextEllipsis
          appendStyle={{
            fontSize: fontSize10,
            marginRight: compare.hideBtnClose && 20,
          }}
          isI18n={false}
          text={compare.nameCompare}
          className="canvas-chart-title"
        />
        {!compare.hideBtnClose && (
          <i
            className="icon-delete-stroke"
            style={{ cursor: 'pointer' }}
            onClick={onClick}
          ></i>
        )}
      </div>
    </div>
  )
}

export default CompareItem
