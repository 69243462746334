import memoize from 'fast-memoize'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCriteria, selectTicker } from '../store/slice'
import { valToPercent } from './../../../utils/Value'
import {
  fontSizeFitWidth,
  getDisplayValue,
  getTickerBgColor,
  setZeroWhenMinimum,
} from './helper'
import style from './ticker.module.css'

const memoizedCalcFontSize = memoize(fontSizeFitWidth)

const getStyle = (node, tickerName) => {
  const width = node.x1 - node.x0
  const height = node.y1 - node.y0

  const fontSize = memoizedCalcFontSize({
    width,
    height,
    max: 32,
    min: 5,
    mainText: tickerName,
    subText: `${valToPercent(node.data.rate)}`,
  })

  return {
    left: node.x0,
    top: node.y0,
    width,
    height,
    fontSize,
  }
}

const getBgColor = (node, item, bgColor, criteria) => {
  if (!node.data.organizationId) {
    return bgColor
  }

  return item
    ? getTickerBgColor(item, criteria)
    : getTickerBgColor(node.data, criteria)
}

export const Ticker = ({ bgColor, node, onMouseMove }) => {
  const criteria = useSelector(selectCriteria)
  const item = useSelector(selectTicker(node.data.organizationId))
  const tickerName = node.data.name
  const [displayValue, setDisplayValue] = useState('')
  const [tickerBgColor, setTickerBgColor] = useState('')

  useEffect(() => {
    setDisplayValue(
      getDisplayValue(item.displayValue, criteria, (val) =>
        setZeroWhenMinimum(val),
      ),
    )
    setTickerBgColor(getBgColor(node, item, bgColor, criteria))
  }, [item])

  return (
    <div
      className={style.node}
      style={{
        ...getStyle(node, tickerName),
        backgroundColor: tickerBgColor,
      }}
      onMouseMove={(e) =>
        onMouseMove(e, node.data.organizationId, tickerBgColor)
      }
    >
      <div>
        <div>{tickerName}</div>
        {item.ticker && (
          <div className={style.tickerRate}>
            {node.data.organizationId && displayValue}
          </div>
        )}
      </div>
    </div>
  )
}

Ticker.propTypes = {
  bgColor: PropTypes.string.isRequired,
  node: PropTypes.object.isRequired,
}
