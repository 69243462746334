import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemCheckbox from './common/ItemCheckbox'
import ItemRadio from './common/ItemRadio'
import ItemSelect from './common/ItemSelect'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import TTM from './common/TTM'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import YearFromTo from './common/YearFromTo'
import { deepCopy } from './helper'
import style from './index.module.css'
import { selectValueDetailPopupIndicator } from './store/slice'

const FinancialStatement = ({
  handleSubmit,
  handleCancel,
  title,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
  isIndexSector,
  ttmMaxQuarter,
  ttmMaxYear,
  ttmMinYear,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const period = useSelector(selectValueDetailPopupIndicator('period'))
  const quarters = useSelector(selectValueDetailPopupIndicator('quarters'))
  const from = useSelector(selectValueDetailPopupIndicator('from'))
  const to = useSelector(selectValueDetailPopupIndicator('to'))

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    const dataConvert = {
      reportType: detail.reportType,
      audit: detail.audit,
      period: detail.period,
    }
    switch (detail.period) {
      case 'Specific':
        dataConvert.quarters = [detail.quarter]
        dataConvert.years = [detail.year]
        break
      case 'MultipleReporting':
        dataConvert.quarters = detail.quarters
        dataConvert.years = range(detail.from, detail.to)
        break
      default:
        break
    }
    handleSubmit({ ...data, detail: dataConvert })
  }

  const getErrors = () => {
    if (period === 'MultipleReporting' && !quarters.length) {
      return [<Translate value="common.popup.ERROR_QUARTER" />]
    }
  }

  const getRatio = () => {
    if (period === 'MultipleReporting') {
      return quarters.length * (to - from + 1)
    }
    return 1
  }

  const isError = period === 'MultipleReporting' && !quarters.length

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={style.frContent}>
            <div className={style.textCheckbox}>
              <Translate value="common.popup.PERIOD" />
            </div>
            <div className="d-flex j-b w-100">
              <div className={style.fr40}>
                <ItemRadio
                  keyRadio="period"
                  valueRadio="MostRecentYear"
                  textRadio={I18n.t('common.popup.MOST_YEAR_RECENT')}
                />
                <ItemRadio
                  keyRadio="period"
                  valueRadio="MostRecentQuarter"
                  textRadio={I18n.t('common.popup.MOST_QUARTER_RECENT')}
                />
                <ItemRadio
                  defaultCheck={true}
                  keyRadio="period"
                  valueRadio="Specific"
                  renderContent={(isDisabled) => (
                    <TTM
                      keyQuarter="quarter"
                      keyYear="year"
                      disabled={isDisabled}
                      ttmMaxQuarter={ttmMaxQuarter}
                      ttmMaxYear={ttmMaxYear}
                      ttmMinYear={ttmMinYear}
                      isFinancialStatement={true}
                    />
                  )}
                />
              </div>
              <div className={style.fr40}>
                <ItemRadio
                  keyRadio="period"
                  valueRadio="MultipleReporting"
                  textRadio={I18n.t('common.popup.MULTIPLE_REPORTING')}
                  renderContent={(isDisabled) => (
                    <div className="w-100">
                      <div
                        className={`d-flex j-b ali-center ${style.itemMultiple}`}
                      >
                        <span className={isDisabled ? style.disabled : ''}>
                          <Translate value="common.popup.YEAR" />:
                        </span>
                        <YearFromTo
                          keyFrom="from"
                          keyTo="to"
                          disabled={isDisabled}
                          minYear={ttmMinYear}
                          maxYear={ttmMaxYear}
                        />
                      </div>
                      <div className={`d-flex j-b ${style.itemMultiple}`}>
                        <span className={isDisabled ? style.disabled : ''}>
                          <Translate value="common.popup.QUARTER" />:
                        </span>
                        <div>
                          <div className={`d-flex j-b ${style.groupCheckbox}`}>
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={1}
                              textCheckbox="Q1"
                              defaultCheck={ttmMaxQuarter === 1}
                              disabled={isDisabled}
                              isError={isError}
                            />
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={2}
                              textCheckbox="Q2"
                              defaultCheck={ttmMaxQuarter === 2}
                              disabled={isDisabled}
                              isError={isError}
                            />
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={3}
                              defaultCheck={ttmMaxQuarter === 3}
                              textCheckbox="Q3"
                              disabled={isDisabled}
                              isError={isError}
                            />
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={4}
                              defaultCheck={ttmMaxQuarter === 4}
                              textCheckbox="Q4"
                              disabled={isDisabled}
                              isError={isError}
                            />
                          </div>
                          <div className={`d-flex j-b ${style.groupCheckbox}`}>
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={6}
                              textCheckbox={I18n.t('common.popup.SIX_MONTH')}
                              disabled={isDisabled}
                              isError={isError}
                              defaultCheck={ttmMaxQuarter === 6}
                            />
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={9}
                              textCheckbox={I18n.t('common.popup.NINE_MONTH')}
                              disabled={isDisabled}
                              isError={isError}
                              defaultCheck={ttmMaxQuarter === 9}
                            />
                            <ItemCheckbox
                              keyCheckbox="quarters"
                              valueCheckbox={5}
                              textCheckbox={I18n.t('common.popup.ANNUAL')}
                              disabled={isDisabled}
                              isError={isError}
                              defaultCheck={ttmMaxQuarter === 5}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
            {!isIndexSector && (
              <>
                <div className={style.textCheckbox}>
                  <Translate value="common.popup.REPORT_TYPE" />
                </div>
                <div className="d-flex j-b w-100">
                  <div className={`d-flex ali-center ${style.reportTypeItem}`}>
                    <span>
                      <Translate value="common.popup.REPORT_TYPE" />
                    </span>
                    <ItemSelect
                      selectData={[
                        {
                          value: 'Consolidated',
                          name: I18n.t('common.popup.CONSOLIDATED'),
                        },
                        {
                          value: 'Unconsolidated',
                          name: I18n.t('common.popup.UNCONSOLIDATED'),
                        },
                      ]}
                      keySelect="reportType"
                      defaultValue="Consolidated"
                    />
                  </div>
                  <div className={`d-flex ali-center ${style.reportTypeItem}`}>
                    <span>
                      <Translate value="common.popup.AUDIT" />
                    </span>
                    <ItemSelect
                      selectData={[
                        {
                          value: 'Both',
                          name: I18n.t('common.popup.BOTH_AUDIT'),
                        },
                        {
                          value: 'Audited',
                          name: I18n.t('common.popup.AUDITED'),
                        },
                        {
                          value: 'Unaudited',
                          name: I18n.t('common.popup.UNAUDITED'),
                        },
                      ]}
                      keySelect="audit"
                      defaultValue="Both"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
            errors={getErrors()}
            ratio={getRatio()}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              submit={convertData}
              isError={isError}
              ratio={getRatio()}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

FinancialStatement.propTypes = {
  isIndexSector: PropTypes.bool,
}

FinancialStatement.defaultProps = {
  isIndexSector: false,
  mostRecentDate: new Date(),
}

export default withWrapperPopupIndicator(FinancialStatement)
