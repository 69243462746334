import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { TYPE_STOCK_INDICES_SECTOR } from '../../../../common/hooks/useBasicInfo'
import { usePackagePermission } from '../../../../common/hooks/usePackagePermission'
import { Span } from '../../../../common/html/Span'
import { PopupCanNotAccess } from '../../../../layout/PopupCanNotAccess'
import Content from './Content'
import { MONTH_QUARTER_YEAR_RADIO, VALUE_TIME } from './constants'
import style from './index.module.css'
import { selectActiveMethodType, updateTab } from './store/slice'
import { getICBs } from './store/thunk'

export const Overview = () => {
  const dispatch = useDispatch()
  const { isTrial } = usePackagePermission(TYPE_STOCK_INDICES_SECTOR.BOND)

  const locale = useSelector((state) => state.i18n.locale)
  const radioValue = useSelector(selectActiveMethodType)

  const handleChangeRadio = (value) => {
    dispatch(updateTab(value))
  }

  useEffect(() => {
    dispatch(getICBs())
  }, [locale])

  return (
    <>
      <div className={`d-flex ali-center ${style.mb8px}`}>
        <div className={`d-flex ali-center`}>
          {MONTH_QUARTER_YEAR_RADIO.map(({ value, label }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center ${style.wrapRadio}
                  ${value === radioValue && style.radioActive}
                  `}
                onClick={() => handleChangeRadio(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === radioValue}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={label} />
                </Span>
              </div>
            )
          })}
        </div>
      </div>
      <div
        style={{
          position: 'relative',
          height: `calc(100% - 28px)`,
        }}
      >
        {!isTrial || (isTrial && radioValue === VALUE_TIME.MONTHLY) ? (
          <Content />
        ) : (
          <PopupCanNotAccess />
        )}
      </div>
    </>
  )
}
