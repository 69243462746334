import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import PanelGDPProvince from './panelGDPProvince/PanelGDPProvince'
import PanelGRDPChanges from './panelGRDPChanges/PanelGRDPChanges'
import PanelGRDPStructureProvince from './panelGRDPStructureProvince/PanelGRDPStructureProvince'
import PanelTop10Provinces from './panelTop10Provinces/PanelTop10Provinces'

const MAP_KEY = {
  GDP_PROVINCE: 'GDP_PROVINCE',
  GDP_TOP10_PROVINCES: 'GDP_TOP10_PROVINCES',
  GRDP_STRUCTURE_PROVINCE: 'GRDP_STRUCTURE_PROVINCE',
  GRDP_CHANGES: 'GRDP_CHANGES',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.GDP_PROVINCE]: {
    minWidth: 650,
    minHeight: 300,
  },
  [MAP_KEY.GDP_TOP10_PROVINCES]: {
    minWidth: 350,
    minHeight: 300,
  },
  [MAP_KEY.GRDP_STRUCTURE_PROVINCE]: {
    minWidth: 500,
    minHeight: 300,
  },
  [MAP_KEY.GRDP_CHANGES]: {
    minWidth: 500,
    minHeight: 300,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.GDP_PROVINCE}`]: {
    width: `calc(65%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_PROVINCE],
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GDP_TOP10_PROVINCES,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.GDP_TOP10_PROVINCES,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.GRDP_STRUCTURE_PROVINCE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.GRDP_CHANGES,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.GDP_TOP10_PROVINCES}`]: {
    width: `calc(35%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GDP_TOP10_PROVINCES],
    minTop: 0,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GDP_PROVINCE].minWidth + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GRDP_STRUCTURE_PROVINCE}`]: {
    width: `calc(50%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.GRDP_STRUCTURE_PROVINCE],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.GDP_PROVINCE].minHeight + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.GRDP_CHANGES,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.GRDP_CHANGES}`]: {
    width: `calc(50%  - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(50%  + ${COMPONENT.MARGIN}px)`,
    ...MIN_SIZE_SETTING[MAP_KEY.GRDP_CHANGES],
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.GDP_PROVINCE].minHeight + 2 * COMPONENT.MARGIN,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.GRDP_STRUCTURE_PROVINCE].minWidth +
      2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelGDPProvince
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_PROVINCE}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelTop10Provinces
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GDP_TOP10_PROVINCES}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGRDPStructureProvince
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GRDP_STRUCTURE_PROVINCE}
          sizes={sizes}
          setSizes={setSizes}
        />
        <PanelGRDPChanges
          panelRefs={panelRefs}
          panelKey={MAP_KEY.GRDP_CHANGES}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
