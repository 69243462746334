import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import styles from '../index.module.css'
import { changeCurrentCompaniesIds } from './store/slice'

export const ResetButton = () => {
  const dispatch = useDispatch()

  const handleReset = () => {
    dispatch(changeCurrentCompaniesIds([]))
  }

  return (
    <div className="d-flex h-20">
      <button
        onClick={() => {
          handleReset()
        }}
        className={styles.resetButton}
      >
        <Span style={{ color: 'white', fontSize: 11 }}>
          <Translate value="common.button.BUTTON_RESET" />
        </Span>
      </button>
    </div>
  )
}
