const joinArray = (array) => {
  return array.join('/')
}

export const transformToDescription = (data) => {
  if (data.groupId) {
    return joinArray([data.groupCode || data.groupName, data.exchangeCode])
  } else if (data.icbId && !data.organizationId) {
    return data.icbNameCustom
  } else {
    return joinArray(
      data.sector
        ? [
            data.organizationShortName || data.companyName,
            data.exchangeCode || data.exchange,
            data.sector,
          ]
        : [
            data.organizationShortName || data.companyName,
            data.exchangeCode || data.exchange,
          ],
    )
  }
}
