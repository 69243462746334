import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { FEATURES } from '../components/constants/routes'
import SignInCallBack from '../components/screens/SignInCallback'
import CurrentState from '../components/utils/CurrentState'

const Dashboard = React.lazy(() => import('./../components/screens/Dashboard'))
const Error404 = React.lazy(() => import('../components/errors/Error404'))
const Error500 = React.lazy(() => import('../components/errors/Error500'))
const Error454 = React.lazy(() => import('../components/errors/Error454'))

export const Routers = () => {
  const renderComponent = (component, key) => {
    return (
      <Route
        key={key}
        exact
        path={component.route}
        element={<Dashboard component={component} />}
      />
    )
  }

  const renderComponents = (components) => {
    if (!components) {
      return null
    }

    return Object.keys(components).map((key) => {
      return renderComponent(components[key], key)
    })
  }

  return (
    <Suspense fallback={null}>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={'/'}
            element={
              <Navigate to={CurrentState.getCurrentState().data.route} />
            }
          />
          <Route exact path={'/signin-callback'} element={<SignInCallBack />} />
          {renderComponents(FEATURES)}
          {Object.keys(FEATURES).map((key) => {
            return renderComponents(FEATURES[key].components)
          })}
          {Object.keys(FEATURES).map((key) => {
            const components = FEATURES[key].components
            if (!components) {
              return null
            }

            return Object.keys(components).map((subKey) => {
              return renderComponents(components[subKey].components)
            })
          })}
          <Route path="/error" element={<Error500 />} />
          <Route path="*" element={<Error404 />} />
          <Route path="/error-454" element={<Error454 />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  )
}
