import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { selectLoading } from '../panelPreview/store/slice'
import { getTableSchemaFormat } from './helps'

export const SelectedListItem = ({
  ids,
  columnKeys,
  selectDataById,
  onChangeCheck,
  checkboxKey,
  loading,
  isMaxCheck,
}) => {
  const dispatch = useDispatch()

  // Use selector
  const isLoading = useSelector(selectLoading)

  // Get data
  const getColumnConstants = useMemo(() => {
    return ['index', ...columnKeys, 'actionIndex']
  }, [columnKeys])

  if (!ids?.length) {
    return <></>
  }

  // Actions
  const handleRowClick = (rowId) => {
    dispatch(onChangeCheck(rowId))
  }

  return (
    <div className="h-100">
      <Table
        ids={ids}
        isLoading={isLoading || loading}
        columnDraggable={false}
        getDataFromRedux={selectDataById}
        schema={getTableSchemaFormat(
          selectDataById,
          getColumnConstants,
          checkboxKey,
          isMaxCheck,
        )}
        rowCustomStyle={{ border: 'unset' }}
        idRowCustomStyle={ids}
        renderHeader={() => <></>}
        hasFooter={false}
        resizable={false}
        onRowClick={handleRowClick}
      />
    </div>
  )
}
