import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import EventService from '../../../../core/services/economy/EventService'

export const getTargetIds = createAsyncThunk(
  'economy/event/getTargetIds',
  async (params, { rejectWithValue }) => {
    try {
      const res = await EventService.getTargetIds(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getRelateNews = createAsyncThunk(
  'economy/event/getRelateNews',
  async (params, { rejectWithValue }) => {
    try {
      const res = await EventService.getRelateNews(params)
      return res
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getUpcomingEvents = createAsyncThunk(
  'economy/event/getUpcomingEvents',
  async (params, { rejectWithValue }) => {
    try {
      const res = await EventService.getUpcomingEvents(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getEventsDate = createAsyncThunk(
  'economy/event/getEventsDate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await EventService.getEventsDate(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
