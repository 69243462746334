import { keyBy } from '../../utils/Common'
import { removeAccents, removeSpecialCharacter } from '../../utils/Search'

export const convertDataTree = (data, idKey, parentIdKey) => {
  const byId = keyBy(
    data.map((item) => {
      return { ...item, children: item.children || {} }
    }),
    idKey,
  )
  data.forEach((item) => {
    if (item[parentIdKey] && byId[item[parentIdKey]]) {
      byId[item[parentIdKey]].children[item[idKey]] = byId[item[idKey]]
    }
  })
  data.forEach((item) => {
    if (item && item[parentIdKey] && item[parentIdKey] !== item[idKey]) {
      delete byId[item[idKey]]
    }
  })
  return byId
}

export const pushChildren = (item, callback, isIncludeParent) => {
  const childrenKeys = Object.keys(item.children)
  if (childrenKeys.length > 0) {
    if (isIncludeParent) {
      callback(item)
    }
    childrenKeys.forEach((key) => {
      pushChildren(item.children[key], callback, isIncludeParent)
    })
  } else {
    callback(item)
  }
}

export const getAllChildrenItem = (item, isIncludeParent = false) => {
  const ids = []
  pushChildren(item, (item) => ids.push(item), isIncludeParent)
  return ids
}

export const pushIdChildren = (
  item,
  idKey,
  callback,
  exceptChildren,
  isGetItemDisplay,
) => {
  if (item?.children) {
    const childrenKeys = Object.keys(item.children)
    if (childrenKeys.length > 0) {
      childrenKeys.forEach((key) => {
        pushIdChildren(
          item.children[key],
          idKey,
          callback,
          exceptChildren,
          isGetItemDisplay,
        )
      })
    } else if (!exceptChildren || !exceptChildren?.includes(item[idKey])) {
      if ((isGetItemDisplay && item.isDisplay) || !isGetItemDisplay) {
        callback(item[idKey])
      }
    }
  }
}

export const getAllIdTree = (treeData, idKey, isGetItemDisplay) => {
  const ids = []
  Object.keys(treeData).forEach((key) =>
    pushIdChildren(
      treeData[key],
      idKey,
      (item) => ids.push(item),
      undefined,
      isGetItemDisplay,
    ),
  )
  return ids
}

export const getAllIdItem = (item, idKey, exceptChildren) => {
  const ids = []
  pushIdChildren(item, idKey, (item) => ids.push(item), exceptChildren)
  return ids
}

export const pushIdIncludeParent = (item, idKey, callback) => {
  const childrenKeys = Object.keys(item.children)
  callback(item[idKey])
  if (childrenKeys.length > 0) {
    childrenKeys.forEach((key) => {
      pushIdIncludeParent(item.children[key], idKey, callback)
    })
  }
}

export const getAllIdTreeIncludeParent = (treeData, idKey) => {
  const ids = []
  Object.keys(treeData).forEach((key) =>
    pushIdIncludeParent(treeData[key], idKey, (item) => ids.push(item)),
  )
  return ids
}

export const pushParentChecked = (item, idKey, checked, callback) => {
  const idsChildren = []
  pushIdChildren(
    item,
    idKey,
    (item) => idsChildren.push(item),
    item.exceptChildren,
  )
  if (idsChildren.every((id) => checked.includes(id))) {
    callback(item[idKey])
  } else if (Object.keys(item.children).length > 0) {
    Object.keys(item.children).forEach((key) =>
      pushParentChecked(item.children[key], idKey, checked, callback),
    )
  }
}

export const getAllParentChecked = (treeData, idKey, checked) => {
  const idsParent = []
  Object.keys(treeData).forEach((key) =>
    pushParentChecked(treeData[key], idKey, checked, (item) =>
      idsParent.push(item),
    ),
  )
  return idsParent
}

const deleteDisplayItem = (item, callback) => {
  callback()
  Object.keys(item.children).forEach((key) => {
    deleteDisplayItem(item.children[key], () => {
      delete item.children[key].isDisplay
      delete item.children[key].isOpen
    })
  })
}

const displayItem = (item, callback) => {
  callback()
  Object.keys(item.children).forEach((key) => {
    displayItem(item.children[key], () => {
      item.children[key].isDisplay = true
      delete item.children[key].isOpen
    })
  })
}

export const handleName = (item, nameKey, levelKey) => {
  if (typeof nameKey === 'string') {
    return (
      item[nameKey] +
      `${levelKey && item[levelKey] ? ` L${item[levelKey]}` : ''}`
    )
  } else {
    let nameItem
    for (let i = 0; i < nameKey.length; i++) {
      if (item[nameKey[i]]) {
        nameItem = item[nameKey[i]]
        break
      }
    }

    return levelKey && item[levelKey]
      ? nameItem + ` L${item[levelKey]}`
      : nameItem
  }
}

const checkItem = (
  item,
  nameKey,
  levelKey,
  valueSearch,
  isSearchAbsolute,
  callback,
) => {
  const name = handleName(item, nameKey, levelKey)
  const isActive =
    typeof name === 'string'
      ? isSearchAbsolute
        ? valueSearch.trim() &&
          name.toUpperCase() === valueSearch.trim().toUpperCase()
        : valueSearch.trim() &&
          removeSpecialCharacter(removeAccents(name))
            .toUpperCase()
            .includes(
              removeSpecialCharacter(removeAccents(valueSearch)).toUpperCase(),
            )
      : false
  if (isActive) {
    item.isDisplay = true
    callback()
    displayItem(item, () => {
      item.isDisplay = true
      delete item.isOpen
    })
  }
  const keysChildren = Object.keys(item.children)
  if (keysChildren.length > 0) {
    keysChildren.forEach((key) => {
      checkItem(
        item.children[key],
        nameKey,
        levelKey,
        valueSearch,
        isSearchAbsolute,
        () => {
          callback()
          item.isDisplay = true
          item.isOpen = true
        },
      )
    })
  }
}

export const filterTreeData = (
  treeData,
  nameKey,
  levelKey,
  valueSearch,
  isSearchAbsolute,
  defaultOpenLevel1,
) => {
  const newTreeData = JSON.parse(JSON.stringify(treeData))
  const keys = Object.keys(newTreeData)
  if (!valueSearch.trim()) {
    keys.forEach((key) => {
      if (defaultOpenLevel1) {
        displayItem(newTreeData[key], () => {
          newTreeData[key].isDisplay = true
          newTreeData[key].isOpen = true
        })
      } else {
        displayItem(newTreeData[key], () => {
          newTreeData[key].isDisplay = true
          delete newTreeData[key].isOpen
        })
      }
    })
  } else {
    keys.forEach((key) => {
      deleteDisplayItem(newTreeData[key], () => {
        delete newTreeData[key].isDisplay
        delete newTreeData[key].isOpen
      })
    })
    keys.forEach((key) => {
      checkItem(
        newTreeData[key],
        nameKey,
        levelKey,
        valueSearch,
        isSearchAbsolute,
        () => {
          newTreeData[key].isDisplay = true
          newTreeData[key].isOpen = true
        },
      )
    })
  }
  return newTreeData
}
