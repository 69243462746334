import useGetFund from '../../../../common/masterData/useGetFund'
import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import TextEllipsis from '../../../../common/textEllipsis'

export const FundCode = ({ value, onChange }) => {
  const { funds } = useGetFund()

  const getOptionsBySearch = ({ search, options }) => {
    return options.filter(
      (item) =>
        item.ticker.toLowerCase().includes(search.toLowerCase()) ||
        item.name.toLowerCase().includes(search.toLowerCase()),
    )
  }

  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={220}
        options={funds.map((item) => ({
          ticker: item.ticker,
          name: item.organizationShortName,
          value: item.organizationId,
        }))}
        optionsBySearch={getOptionsBySearch}
        isSearch
        isOptionAll
        isDisableUnSelect={value.length >= 10}
        renderOptionName={(item) => (
          <div className="w-100 d-flex ali-center">
            <div className="mr-16" style={{ width: '20%' }}>
              <TextEllipsis
                text={item.ticker}
                zIndexTooltip={999}
                isI18n={false}
              />
            </div>
            <div style={{ width: '60%' }}>
              <TextEllipsis
                text={item.name}
                zIndexTooltip={999}
                isI18n={false}
              />
            </div>
          </div>
        )}
        textEmpty="fund.fundCenter.SEARCH_FUND"
        textAll="fund.fundCenter.ALL_FUND"
        onChange={(value) => onChange({ fund: value })}
      />
    </div>
  )
}
