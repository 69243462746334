import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { BreakEvenPoint } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

export const PanelBreakEvenPoint = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const warrantInfo = useSelector(selectWarrantInfo)

  return (
    <Panel
      title="market.coveredWarrant.BREAK_EVEN_POINT"
      panelRefs={panelRefs}
      panelKey={mapKey.BREAK_EVEN_POINT}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n('market.coveredWarrant.BREAK_EVEN_POINT')}
      downloadJpgParams={{
        domId: 'breakEvenPoint',
        nameScreen: UseI18n('market.coveredWarrant.COVERED_WARRANT'),
        chartName: UseI18n('market.coveredWarrant.BREAK_EVEN_POINT'),
        tickerName: warrantInfo.ticker,
      }}
    >
      <PreOpenMarket>
        <BreakEvenPoint
          width={formatNumber(
            sizes[mapKey.BREAK_EVEN_POINT].width - PANEL_PADDING,
          )}
          height={formatNumber(
            sizes[mapKey.BREAK_EVEN_POINT].height - HEADER_PANEL_HEIGHT,
          )}
        />
      </PreOpenMarket>
    </Panel>
  )
}

PanelBreakEvenPoint.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
