import { Contribution } from '.'
import UseI18n from '../../../common/hooks/useI18n'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'

export const PanelContribution = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title="economy.cpiAndInflation.titlePanel.CONTRIBUTION_OF_GOODS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg
      titleJpg={UseI18n(
        'economy.cpiAndInflation.titlePanel.CONTRIBUTION_OF_GOODS',
      )}
      downloadJpgParams={{
        domId: 'contribution',
        nameScreen: UseI18n('economy.cpiAndInflation.titlePanel.CPI_INFLATION'),
        chartName: UseI18n(
          'economy.cpiAndInflation.titlePanel.CONTRIBUTION_OF_GOODS',
        ),
      }}
    >
      <Contribution
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
