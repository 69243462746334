export const filing = {
  FILTER: 'Filter',
  FILING: 'Filing',
  CATEGORY: 'Category',
  SUB_CATEGORY: 'Sub - Category',
  ALL_CATEGORY: 'All Categories',
  ALL_SOURCES: 'All Sub-categories',
  TIME: 'Time',
  selectTime: {
    SEVEN_DAYS: 'Last 7 days',
    LAST_MONTH: 'Last month',
    LAST_THREE_MONTH: 'Last 3 months',
    LAST_SIX_MONTH: 'Last 6 months',
    LAST_YEAR: 'Last year',
    ALL: 'All',
    CUSTOM: 'Custom',
  },
  indicators: {
    FILING_DATE: 'FILING DATE',
    DOCUMENT_TITLE: 'DOCUMENT TITLE',
    CATEGORY: 'CATEGORY',
    SUB_CATEGORY: 'SUB-CATEGORY',
    YEAR: 'YEAR',
    QUARTER: 'QUARTER',
    DOWNLOAD: 'DOWNLOAD',
  },
}
