import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectBasicInfo } from '../Filter/store/slice'
import { selectDataBondDetail } from '../generalSetting/store/slice'
import Content from './content'
import Filter from './filter'
import style from './index.module.css'
import {
  exportFileApi,
  getDataCompanyRating,
  getDataThunk,
} from './store/thunk'

const TradingInformation = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const bondInfo = useSelector(selectBasicInfo)
  const generalSetting = useSelector(selectDataBondDetail)

  const locale = useSelector((state) => state.i18n.locale)
  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const getData = () => {
    dispatch(
      getDataThunk({
        BondId: bondInfo?.bondId,
      }),
    )
  }
  useEffect(() => {
    if (bondInfo?.bondId) {
      getData()
    }
  }, [bondInfo?.bondId, locale])

  useEffect(() => {
    if (generalSetting?.organizationId) {
      dispatch(
        getDataCompanyRating({
          OrganizationId: generalSetting?.organizationId,
        }),
      )
    }
  }, [generalSetting?.organizationId, locale])

  const exportFile = () => {
    return exportFileApi({
      BondId: bondInfo?.bondId,
    })
  }

  return (
    <Panel
      title="bond.bondSecurity.common.TradingInformation"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={false}
      downloadCsv={() => exportFile()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div
                style={{
                  height: height - size.height,
                  width: width,
                  overflow: 'auto',
                }}
                className={style.scroll}
              >
                <Content width={width} />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default TradingInformation
