const indices = {
  NAME: 'NAME',
  DESCRIPTION: 'DESCRIPTION',
  DATE: 'MODIFIED DATE',
  FAVORITE: 'FAVORITE',
  REFRESH: 'REFRESH',
  MORE: 'More',
}

const btnMore = {
  BTN_DUPLICATE: 'Duplicate',
  BTN_RENAME: 'Rename',
  BTN_EDIT: 'Edit description',
  BTN_DELETE: 'Delete',
}

const validate = {
  NAME_MAX: 'It excceeded 100 characters.',
  NAME_DUPLICATE: 'The name has already existed.',
}

const watchlistTable = {
  indices,
  btnMore,
  validate,
  BTN_CREATE: 'Create',
  BTN_DUPLICATE: 'Duplicate',
  BTN_DELETE: 'Delete',
  EXCEEDED_WL:
    'It exceeded the number of watchlist. You cannot add more watchlists.',
  TITLE_DELETE_WL: 'Delete Watchlist',
  ASK_DELETE: 'Do you want to delete',
  ITEMS: 'Items',
  BTN_CANCEL: 'Cancel',
  BTN_DELETE_2: 'Delete',
}

export default watchlistTable
