import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { changeFinancialRatioFilter } from '../store/slice'
import style from './index.module.css'

export const FilterInputRatioItem = ({ data }) => {
  const dispatch = useDispatch()
  // Use state
  const [type, setType] = useState(data.options[0].value)

  // Actions
  const onSelectItem = (item) => {
    dispatch(changeFinancialRatioFilter({ field: data.field, option: item }))
  }

  const onRadioClick = (e) => {
    const value = e.target.value
    setType(value)
    onSelectItem(value)
  }

  return (
    <div className={`input-dropdown ${style['input-dropdown']}`}>
      <Span style={{ fontSize: 10 }} className={style['title-dropdown']}>
        <Translate value={data.title} />
      </Span>
      <ul className="list-check ml-8">
        {data.options.map((item, index) => (
          <li key={item.value} className="mb-0">
            <label className="fs-12">
              <input
                type="radio"
                className="radiobox radiobox2"
                name={data.field}
                value={item.value}
                defaultChecked={index === 0}
                onClick={onRadioClick}
              />
              <Span
                style={{
                  fontSize: 12,
                  color: item.value === type ? '#2f9aee' : '',
                }}
              >
                <Translate value={item.name} />
              </Span>
            </label>
          </li>
        ))}
      </ul>
    </div>
  )
}

FilterInputRatioItem.propTypes = {
  data: PropTypes.object.isRequired,
}
