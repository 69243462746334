import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { exportToImg } from '../../../common/downloadJpg/helper'
import UseI18n from '../../../common/hooks/useI18n'
import useWindowDevicePixelRatio from '../../../common/hooks/useWindowDevicePixelRatio'
import { handleNameFileDownload } from '../../../utils/Export'
import { getFontSize } from '../../../utils/FontSize'
import { changeDownloadChartLoading, downloadChart } from './store/slice'

const useDownloadChart = (
  downloadingChartID,
  chartID,
  chartName,
  isPreviewChart,
) => {
  const dispatch = useDispatch()
  const { formatWithZoom, reverseFormatWithZoom } = useWindowDevicePixelRatio()

  const nameScreen = UseI18n('financialChart.FINANCIAL_CHART')
  const fontSize = getFontSize(11)

  const [isDownloading, setIsDownloading] = useState(false)

  useEffect(() => {
    dispatch(changeDownloadChartLoading(isDownloading))
  }, [isDownloading])

  useEffect(() => {
    if (downloadingChartID !== chartID) return
    let interval = null
    const domId = isPreviewChart
      ? `previewChart${downloadingChartID}`
      : `chart${downloadingChartID}`
    if (downloadingChartID >= 0) {
      setIsDownloading(true)
      interval = setInterval(() => {
        if (document.getElementById(domId)) {
          exportToImg({
            domId,
            exportName: `${handleNameFileDownload(
              nameScreen,
              chartName.replace(/[./:*?"<>|]/g, ''),
            )}.jpg`,
            fontSize,
            formatWithZoom,
            reverseFormatWithZoom,
          })
          dispatch(downloadChart({ id: null }))
          clearInterval(interval)
          setIsDownloading(false)
        }
      }, 1000)
    }

    return () => {
      clearInterval(interval)
    }
  }, [downloadingChartID])

  return [isDownloading, setIsDownloading]
}

export default useDownloadChart
