import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getDataNotDuplicates } from '../helper'
import { getGrowthOverYears } from './thunk'

const initialState = {
  lineChartData: [],
  lineChartLoading: true,
  years: [],
}

const slice = createSlice({
  name: 'economy/monetary/moneySuppliesCredit/panelGrowthYoY',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGrowthOverYears.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getGrowthOverYears.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = action.payload || []
      state.years = getDataNotDuplicates(action.payload || [], 'year')
    })
    builder.addCase(getGrowthOverYears.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const getLineChartData = (state) => state[slice.name].lineChartData
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading
export const getYears = (state) => state[slice.name].years

register(slice.name, slice.reducer)
