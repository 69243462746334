import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import SelectCustom from '../../../common/selectCustom'
import { checkSmallScreen } from '../../chart/helper'
import {
  resetChartTabData,
  selectAddEditChartLoading,
  setChartActiveID,
} from '../../chart/store/slice'
import { postCharts, updateCharts } from '../../chart/store/thunk'
import { dashboardTypes, screenTypes } from '../../constants'
import { ReactComponent as IconAddNew } from '../../icon/icon-add-new.svg'
import PopupSaveChartToNewDashboard from '../../popup/popupSaveChartToNewDashboard'
import PopupWarningSave from '../../popup/PopupWarningSave'
import {
  changeDashboard,
  selectDashboard,
  selectDashboardChartData,
  selectEditDashboardLoading,
} from '../../store/slice'
import style from '../style.module.css'
import ToolResizeChart from '../toolResizeChart'
import Tabs from './Tabs'

const MenuFooterChart = forwardRef(
  (
    {
      chartTabs,
      activeTab,
      onChangeTab,
      onAddChart,
      onChangeNameChart,
      width,
      height,
      setDimensions,
      isEditChart,
      isShowTable,
      disableAddChart,
    },
    ref,
  ) => {
    const dispatch = useDispatch()
    const menuTabRef = useRef()
    const buttonMenuRef = useRef()

    const { data = {} } = useSelector(selectDashboard)
    const dashboardChartData = useSelector(selectDashboardChartData)
    const addEditChartLoading = useSelector(selectAddEditChartLoading)
    const editDashboardLoading = useSelector(selectEditDashboardLoading)

    const [isShowPopupSaveChart, setIsShowPopupSaveChart] = useState(false)
    const [isShowPopupWarningSave, setShowPopupWarningSave] = useState(false)
    const [isShowPopupSaveAsChart, setIsShowPopupSaveAsChart] = useState(false)
    const [isSaveDashboard, setIsSaveDashboard] = useState(false)

    // Get data
    const isSmallScreen = useMemo(() => {
      return checkSmallScreen(width, 1200)
    }, [width])

    const getTabs = useMemo(() => {
      return Object.keys(chartTabs).map((key) => ({
        name: chartTabs[key].name || chartTabs[key].nameChart.name,
        value: key,
        chartID: chartTabs[key].chartID,
      }))
    }, [chartTabs])

    const isFirstTab = useMemo(() => {
      return String(activeTab) === getTabs[0].value
    }, [activeTab, getTabs])

    const isLastTab = useMemo(() => {
      return String(activeTab) === getTabs[getTabs.length - 1].value
    }, [activeTab, getTabs])

    // Actions
    const onSaveChartDashboard = () => {
      setIsShowPopupSaveChart(true)
    }

    const handleNextTab = () => {
      if (isLastTab) return
      onChangeTab(activeTab + 1)
      const el = document.getElementById('chartTab' + (activeTab + 1))
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    }

    const handlePrevTab = () => {
      if (isFirstTab) return
      onChangeTab(activeTab - 1)
      const el = document.getElementById('chartTab' + (activeTab - 1))
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      })
    }

    const saveDashboard = () => {
      const listCharts = [...dashboardChartData]
      listCharts.forEach((element) => {
        if (typeof element.id === 'number') {
          dispatch(updateCharts(element))
        } else {
          dispatch(postCharts(element))
        }
      })
      setIsSaveDashboard(true)
      dispatch(setChartActiveID(null))
    }

    useEffect(() => {
      if (isSaveDashboard && !addEditChartLoading && !editDashboardLoading) {
        setIsSaveDashboard(false)
        dispatch(resetChartTabData())
        dispatch(
          changeDashboard({
            screenType: screenTypes.DASHBOARD,
            dashboardType: dashboardTypes.MY_DASHBOARD,
          }),
        )
      }
    }, [isSaveDashboard, addEditChartLoading, editDashboardLoading])

    // Render
    const renderFooterTabMenu = () => {
      return (
        <div className={style.menuFooterWrapChart}>
          <div ref={menuTabRef} className={style.menuFooter}>
            <div className={style.wrapIcon} onClick={handlePrevTab}>
              <i
                className={`icon-caret-left`}
                style={{ color: isFirstTab ? '#8c9194' : '#585858' }}
              />
            </div>
            <div className={style.wrapIcon} onClick={handleNextTab}>
              <i
                className={`icon-caret-right`}
                style={{ color: isLastTab ? '#8c9194' : '#585858' }}
              />
            </div>
            <SelectCustom
              value={activeTab}
              selectData={getTabs}
              appendDropdownStyle={{
                backgroundColor: '#ffffff',
              }}
              isI18n
              themeWhite
              isDropdownFitContent
              handleChange={onChangeTab}
              renderSelect={() => {
                return (
                  <div ref={buttonMenuRef} className={style.wrapIcon}>
                    <i className={`icon-hum`} />
                  </div>
                )
              }}
            />
            <Tabs
              menuTabRef={menuTabRef}
              data={getTabs}
              activeTab={activeTab}
              onChangeTab={onChangeTab}
            />
            <div
              className={style.wrapIcon}
              style={
                disableAddChart
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.4,
                    }
                  : { cursor: 'pointer' }
              }
            >
              <IconAddNew
                onClick={onAddChart}
                className={style.iconPlusCircleLine}
              />
            </div>
          </div>
        </div>
      )
    }

    const renderFooterSettings = () => {
      return (
        <>
          <ToolResizeChart
            width={width}
            height={height}
            setDimensions={setDimensions}
            isShowTable={isShowTable}
          />
          <div className={style.groupBtn}>
            <Button className={style.btnBlue} onClick={onSaveChartDashboard}>
              <Translate value="financialChart.SAVE" />
            </Button>
            <Button
              className={style.btnGray}
              onClick={() => setShowPopupWarningSave(true)}
            >
              <Translate value="financialChart.CANCEL" />
            </Button>
            <PopupWarningSave
              isShow={isShowPopupWarningSave}
              handleShow={setShowPopupWarningSave}
              isScreenCreateChart
              handleSaveChart={onSaveChartDashboard}
            />
          </div>
        </>
      )
    }

    const renderFooterSettingsEditChart = () => {
      return (
        <>
          <ToolResizeChart
            width={width}
            height={height}
            setDimensions={setDimensions}
            isShowTable={isShowTable}
          />
          <div className={style.groupBtn}>
            {data.isTemplate ? (
              <Button className={style.btnBlue} onClick={onSaveChartDashboard}>
                <Translate value="financialChart.SAVE_AS" />
              </Button>
            ) : (
              <Button className={style.btnBlue} onClick={saveDashboard}>
                <Translate value="financialChart.SAVE" />
              </Button>
            )}
            <Button
              className={style.btnGray}
              onClick={() => setShowPopupWarningSave(true)}
            >
              <Translate value="financialChart.CANCEL" />
            </Button>
          </div>
          <PopupWarningSave
            isShow={isShowPopupWarningSave}
            handleShow={setShowPopupWarningSave}
          />
        </>
      )
    }

    return (
      <>
        <div
          ref={ref}
          className="align-center"
          style={{ marginLeft: 16, marginRight: 16 }}
        >
          {isSmallScreen ? (
            <div className="h-100 w-100">
              {renderFooterTabMenu()}
              <div
                className="d-flex justify-content-space-between ali-center"
                style={{ height: 40, marginBottom: 8 }}
              >
                {isEditChart
                  ? renderFooterSettingsEditChart()
                  : renderFooterSettings()}
              </div>
            </div>
          ) : (
            <>
              {renderFooterTabMenu()}
              {isEditChart
                ? renderFooterSettingsEditChart()
                : renderFooterSettings()}
            </>
          )}
        </div>
        {isShowPopupSaveChart && (
          <PopupSaveChartToNewDashboard
            handleShow={setIsShowPopupSaveChart}
            chartTabActive={activeTab}
            chartTabs={chartTabs}
            onChangeNameChart={onChangeNameChart}
          />
        )}
        {isShowPopupSaveAsChart && (
          <PopupSaveChartToNewDashboard
            handleShow={setIsShowPopupSaveAsChart}
            chartTabActive={activeTab}
            chartTabs={chartTabs}
            onChangeNameChart={onChangeNameChart}
            isSaveAsChart
          />
        )}
      </>
    )
  },
)

export default MenuFooterChart
