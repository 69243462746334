import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import { formatVal } from '../../../utils/Value'
import style from './index.module.css'

const ChartTooltip = ({ data }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 11, fontWeight: 400 }}
      >
        {data.tradingDateId}
      </Span>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 340, marginRight: 8 }}>
          <Translate value="bond.portfolio.expected.COUPON_2" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 340 }}>
            {formatVal(data.couponValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 340 }}>
            <Translate value="bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 340, marginRight: 8 }}>
          <Translate value="bond.portfolio.expected.PRINCIPAL_2" />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 340 }}>
            {formatVal(data.principalValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 340 }}>
            <Translate value="bond.portfolio.overviewOfPortfolioSize.BOND_SIZE_UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.shape({
    tradingDateId: PropTypes.string,
    couponValue: PropTypes.number,
    principalValue: PropTypes.number,
  }).isRequired,
}

export default ChartTooltip
