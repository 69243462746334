import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import TableTicker from './common/TableTicker'
import YearFromTo from './common/YearFromTo'
import style from './index.module.css'
import {
  changeValueDetailPopupTemplate,
  resetPopupTemplate,
  selectValueDetailPopupTemplate,
} from './store/slice'

const ItemMethod = ({ item }) => {
  const dispatch = useDispatch()

  const checked =
    useSelector(selectValueDetailPopupTemplate('shareIssueMethodId')) || []

  const handleChange = (e) => {
    const newChecked = [...checked]
    if (e.target.checked) {
      newChecked.push(item.shareIssueMethodId)
      dispatch(
        changeValueDetailPopupTemplate({
          key: 'shareIssueMethodId',
          value: newChecked,
        }),
      )
    } else {
      newChecked.splice(newChecked.indexOf(item.shareIssueMethodId), 1)
      dispatch(
        changeValueDetailPopupTemplate({
          key: 'shareIssueMethodId',
          value: newChecked,
        }),
      )
    }
  }

  return (
    <div className={style.itemMethod}>
      <div className={style.siItemName}>{item.shareIssueMethodName}</div>
      <div className={style.siCheckbox}>
        <input
          checked={checked.includes(item.shareIssueMethodId)}
          onChange={handleChange}
          type="checkbox"
          className="checkbox cursor-pointer"
        />
      </div>
    </div>
  )
}

const Methods = ({ listMethod }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      changeValueDetailPopupTemplate({
        key: 'shareIssueMethodId',
        value: listMethod.map((item) => item.shareIssueMethodId),
      }),
    )
  }, [listMethod])

  return (
    <>
      {listMethod.map((item) => (
        <ItemMethod key={item.shareIssueMethodId} item={item} />
      ))}
    </>
  )
}

const StockIssue = ({ handleCancel, handleSubmit, listMethod }) => {
  const dispatch = useDispatch()

  const checked =
    useSelector(selectValueDetailPopupTemplate('shareIssueMethodId')) || []

  const handleChangeCheckAll = (e) => {
    if (e.target.checked) {
      dispatch(
        changeValueDetailPopupTemplate({
          key: 'shareIssueMethodId',
          value: listMethod.map((item) => item.shareIssueMethodId),
        }),
      )
    } else {
      dispatch(
        changeValueDetailPopupTemplate({
          key: 'shareIssueMethodId',
          value: [],
        }),
      )
    }
  }

  useEffect(() => {
    return () => dispatch(resetPopupTemplate())
  }, [])

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="tool.dataExplorer.ISSUE_SHARE" />
            </Span>
          </h3>
          <a onClick={handleCancel} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`${style.cdpContainer} d-flex ali-center`}>
            <span>
              <Translate value="tool.dataExplorer.ISSUE_SHARE" />:
            </span>
            <YearFromTo keyFrom="fromIssueYear" keyTo="toIssueYear" />
          </div>
          <div className="content">
            <div className={`col ${style.colMethod}`}>
              <div className={`col-header pl-12-pr-16 ${style.h37px}`}>
                <div className="d-flex j-b w-100 ali-center">
                  <Translate value="tool.dataExplorer.ISSUE_METHOD" />
                  <input
                    checked={checked.length === listMethod.length}
                    onChange={handleChangeCheckAll}
                    type="checkbox"
                    className="checkbox cursor-pointer"
                  />
                </div>
              </div>
              <div className="col-content">
                <Methods listMethod={listMethod} />
              </div>
            </div>
            <TableTicker />
          </div>
          <Count />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit submit={handleSubmit} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default StockIssue
