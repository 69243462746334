import PropTypes from 'prop-types'
import React from 'react'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { getColorByValueChart } from '../../../../utils/Color'
import { formatVal } from '../../../../utils/Value'
import { SEARCH_TIME_FRAME } from '../constants'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  timeFrame,
  lineKey,
  barKeys,
}) => {
  const renderTooltip = (tooltipData) => {
    const tooltipContentData = [
      {
        label: I18n.t('market.marketInDepth.directorDeal.BUY_1'),
        value: 'buy',
        format: (val) =>
          formatVal(val, 2) +
          I18n.t('market.marketInDepth.directorDeal.BILLION'),
      },
      {
        label: I18n.t('market.marketInDepth.directorDeal.SELL_1'),
        value: 'sell',
        format: (val) =>
          formatVal(val, 2) +
          I18n.t('market.marketInDepth.directorDeal.BILLION'),
      },
      {
        label: I18n.t('market.marketInDepth.directorDeal.NET_1'),
        value: lineKey,
        format: (val) =>
          formatVal(val, 2) +
          I18n.t('market.marketInDepth.directorDeal.BILLION'),
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.tooltipHover}</Span>
        </div>
        {tooltipContentData.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {I18n.t(el.label)}
              </Span>
            </div>
            <div
              className="ml-8"
              style={{ color: getColorByValueChart(tooltipData[el.value]) }}
            >
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {el.format(tooltipData[el.value])}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      timeFrame={timeFrame}
      xTickNum={SETTINGS.xTickNum}
      tickFormatter={(d) => d}
      margin={{ top: 20, bottom: 0, left: 0, right: 40 }}
      yAxis={[
        {
          id: 'left',
          keys: [...barKeys, lineKey],
          orientation: 'left',
          isBarChart: true,
          tickNum: SETTINGS.yTickNum,
          label: I18n.t('market.marketInDepth.directorDeal.NET_VALUE'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
        },
      ]}
      renderCustomTooltip={(dataFormat) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => (
        <>
          {barKeys.map((barKey, index) => (
            <React.Fragment key={index}>
              <Bar
                stackId="barRight"
                isAnimationActive={false}
                yAxisId="left"
                dataKey={barKey}
                fill={index === 0 ? '#3da967' : '#e0505b'}
                barSize={getColumnSizeInBarChart(
                  chartContentWidth,
                  data.length,
                )}
              />
            </React.Fragment>
          ))}
          {timeFrame !== SEARCH_TIME_FRAME.ONE_MONTH && (
            <Line
              yAxisId="left"
              dataKey="lineDash"
              type="linear"
              strokeDasharray="5"
              stroke="#f7b80c"
              fill="#f7b80c"
              strokeWidth={2}
              activeDot={false}
              isAnimationActive={false}
              dot={{
                strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
                strokeDasharray: '',
              }}
            />
          )}
          <Line
            yAxisId="left"
            dataKey={
              timeFrame === SEARCH_TIME_FRAME.ONE_MONTH ? lineKey : 'lineSolid'
            }
            type="linear"
            stroke={'#f7b80c'}
            fill={'#f7b80c'}
            strokeWidth={2}
            activeDot={false}
            isAnimationActive={false}
            dot={{
              strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
              strokeDasharray: '',
            }}
          />
        </>
      )}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
