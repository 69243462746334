import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { indicators } from '../../constants'
import { changeIndicator, selectIndicator } from './store/slice'

export const IndicatorSelectOption = () => {
  const dispatch = useDispatch()
  const indicator = useSelector(selectIndicator)
  const onChange = (val) => {
    dispatch(changeIndicator(val))
  }

  return (
    <div className="w-148">
      <SelectCustom
        value={indicator}
        selectData={Object.keys(indicators).map((key) => ({
          name: key,
          value: indicators[key],
        }))}
        handleChange={onChange}
      />
    </div>
  )
}
