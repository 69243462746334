export const common = {
  TITLE: 'Danh mục',
  BOND_INFORMATION: 'Thông tin trái phiếu',
  OVERVIEW_OF_PORTFOLIO_SIZE: 'Quy mô danh mục trái phiếu',
  PORTFOLIO_SIZE_BY_ISSUER: 'Quy mô theo kỳ hạn còn lại',
  EXPECTED: 'Dòng tiền nợ gốc & Lãi Coupon dự kiến nhận',
  GOVERNMENT_BOND: 'TPCP',
  GOVERNMENT_BOND_2: 'Trái phiếu chính phủ',
  CORPORATE_BOND: 'TPDN',
  CORPORATE_BOND_2: 'Trái phiếu doanh nghiệp',
  TOTAL: 'Tổng',
  RENAME: 'Đổi tên',
}

export const bondInformation = {
  ADD_BOND_TICKER: 'Thêm mã trái phiếu',
  CHOOSE_PORTFOLIO: 'Chọn danh mục',
  CHOOSE_WATCHLIST: 'Chọn watchlist',
  CREATE_BOND: 'Tạo trái phiếu',
  VALUATION_DATE: 'Ngày định giá',
  CREATE_PORTFOLIO: 'Tạo Danh mục mới',
  ADD_TO_WATCHLIST: 'Thêm vào Watchlist',
  SAVE_AS: 'Lưu thành',
  SAVE: 'Lưu',
  TRADING_INFORMATION: 'Thông tin vị thế & giao dịch',
  PORTFOLIO_ANALYSIS: 'Phân tích danh mục',
  BOND_TICKER: 'Mã trái phiếu',
  BOND_TYPE: 'Loại trái phiếu',
  ISSUER: 'TCPH',
  INDUSTRY: 'Ngành',
  ISSUE_DATE: 'Ngày phát hành',
  MATURITY_DATE: 'Ngày đáo hạn',
  NEXT_PAYMENT_DATE: 'Ngày trả lãi tiếp theo',
  PAR_VALUE: 'Mệnh giá',
  PAR_VALUE_UNIT: '(VND)',
  COUPON: 'Coupon',
  COUPON_UNIT: '(%/Năm)',
  CREDIT_SPREAD: 'CL điểm tín dụng',
  INTEREST_PAYMENT_PERIOD: 'Kỳ trả lãi',
  INTEREST_PAYMENT_PERIOD_UNIT: '(tháng/lần)',
  INTEREST_BASIS: 'Cơ sở tính lãi',
  NO_DATA: 'Tìm kiếm, tạo mới mã trái phiếu để thêm vào danh mục',
  VALUATION_DATE_TOOLTIP:
    'Ngày định giá được áp dụng cho tất cả các mã trái phiếu trong bảng Danh mục',
  TRADING_DATE: 'Ngày mua',
  BOND_VOLUME: 'KLTP nắm giữ',
  BUY_PRICE: 'Giá vốn',
  BUY_PRICE_UNIT: '(VND)',
  BUY_VALUE: 'GT mua',
  BUY_VALUE_UNIT: '(VND)',
  EXTRA_DISCOUNTS: 'CK/Phụ trội TP',
  UNALLOCATED_EXTRA: 'CK/Phụ trội chưa phân bổ',
  LAST_PAYMENT_DATE: 'Ngày trả lãi gần nhất',
  ACCRUED_INTEREST: 'Lãi cộng dồn',
  BOOK_VALUE: 'GT sổ sách',
  YTM: 'Ytm',
  DIRTY_PRICE: 'Giá bẩn',
  CLEAN_PRICE: 'Giá sạch',
  TOTAL_ESTIMATED_VALUE: 'Giá Trị Ước tính',
  PROFIT_LOSS: 'Lãi lỗ dự kiến',
  REMAINING_DURATION: 'KHCL',
  LAST_TRADING_DATE: 'Ngày GD gần nhất',
  LAST_YTM: 'Ytm gần nhất',
  LAST_DIRTY_PRICE: 'Giá bẩn gần nhất',
  LAST_CLEAN_PRICE: 'Giá sạch gần nhất',
  MACAULAY_DURATION: 'Macaulay Duration',
  MODIFIED_DURATION: 'Modified Duration',
  CONVEXITY: 'Độ lồi',
  CONVEXITY_UNIT: '(25bps)',
  PVBP: 'PVBP',
  CREDIT_Q: 'FiinCreditQ',
  WEIGHTED: 'Tỷ trọng',
  CREDIT_EVENT: 'Sự kiện tín dụng',
  DELETE_TITLE: 'Xóa mã trái phiếu',
  DELETE_ALL_TITLE: 'Xóa bảng dữ liệu',
  DELETE_CONTENT: 'Bạn chắc chắn muốn xóa %{ticker} khỏi bảng danh mục?',
  DELETE_ALL_CONTENT: 'Bạn chắc chắn muốn xóa toàn bộ bảng danh mục?',
  YES: 'Đồng ý',
  NO: 'Huỷ bỏ',
  VALUATION_DATE_TITLE: 'Áp dụng Ngày định giá',
  VALUATION_DATE_CONTENT:
    'Bạn chắc chắn muốn áp dụng Ngày định giá cho toàn bộ mã trái phiếu có trong danh mục không?',
  MESSAGE_DATE_1: 'Ngày mua phải nhỏ hơn ngày định giá',
  MESSAGE_DATE_2: 'Ngày định giá phải lớn hơn ngày mua',
  MESSAGE_DATE_3:
    'Ngày mua phải lớn hơn ngày phát hành và nhỏ hơn ngày đáo hạn',
  MESSAGE_DATE_4:
    'Ngày định giá phải lớn hơn ngày phát hành và nhỏ hơn ngày đáo hạn',
  MESSAGE_YTM: 'YTM phải lớn hơn -100% và nhỏ hơn 100%',
  MESSAGE_DIRTY_PRICE: 'Giá bẩn phải lớn hơn 0',
  MESSAGE_CLEAN_PRICE: 'Giá sạch phải lớn hơn 0',
  TOOLTIP_1: 'Giá trên một trái phiếu trung bình',
  TOOLTIP_2:
    'Chỉ số FiinCreditQ là chỉ số liên quan đến rủi ro tín dụng, chỉ số càng thấp mức độ rủi ro càng cao và ngược lại. Mô hình được xây dựng dựa trên Merton Model',
  SAVE_PORTFOLIO_TITLE: 'Lưu thành danh mục  mới',
  SAVE_PORTFOLIO_CONTENT: 'Tên danh mục mới:',
  MESSAGE_PORTFOLIO_1: 'Đây là trường bắt buộc',
  MESSAGE_PORTFOLIO_2: 'Tên danh mục đã tồn tại. Vui lòng nhập lại',
  PORTFOLIO: 'Danh mục %{number}',
  MESSAGE_PORTFOLIO_3: 'Lưu thành danh mục mới "%{name}" thành công',
  MESSAGE_PORTFOLIO_4: 'Danh mục "%{name}" đã được lưu thành công',
  CREATE_PORTFOLIO_CONTENT: 'Bạn có muốn lưu trước khi tạo danh mục mới không?',
  CREATE_PORTFOLIO_CONTENT_2: 'Bạn có muốn lưu thay đổi không?',
  DELETE_PORTFOLIO_TITLE: 'Xóa danh mục',
  DELETE_PORTFOLIO_CONTENT: 'Bạn chắc chắn muốn xóa danh mục ',
  RENAME_PORTFOLIO_CONTENT: 'Tên danh mục đã tồn tại. Vui lòng nhập lại',
  MAX_LENGTH: 'Số lượng mã trái phiếu được chọn không vượt quá %{number}',
  RENAME_WATCHLIST_CONTENT: 'Tên watchlist đã tồn tại. Vui lòng nhập lại',
  WATCHLIST: 'Watchlist %{number}',
  MESSAGE_WATCHLIST_1: 'Lưu thành watchlist mới "%{name}" thành công',
  MESSAGE_WATCHLIST_2: 'Watchlist "%{name}" đã được lưu thành công',
  MESSAGE_CREATE_1: 'Lưu ý: Vui lòng truy cập',
  MESSAGE_CREATE_2: 'để xem chi tiết thông tin trái phiếu vừa tạo',
}

export const overviewOfPortfolioSize = {
  CONTRIBUTION: 'Tỷ trọng',
  INTEREST: 'Lãi suất',
  REMAINING_DURATION: 'KHCL',
  BOND_SIZE_LABEL: 'Quy mô (tr. VND)',
  COUPON_LABEL: 'Coupon bình quân (%/năm)',
  COUPON_UNIT: '%/năm',
  BOND_SIZE: 'Quy mô (GT mua):',
  BOND_SIZE_UNIT: 'tr. VND',
  AVG_COUPON: 'Coupon BQ:',
  ALL_AVG_COUPON: 'Coupon BQ toàn danh mục:',
  BOND_SIZE_2: 'Quy mô',
  AVG_COUPON_2: 'Coupon BQ',
  ALL_AVG_COUPON_2: 'Coupon BQ toàn danh mục',
  DURATION_LABEL: 'KHCL bình quân (Năm)',
  DURATION_UNIT: 'năm',
  AVG_DURATION: 'KHCL BQ:',
  AVG_DURATION_2: 'KHCL BQ',
  ALL_AVG_DURATION: 'KHCL BQ toàn danh mục:',
  ALL_AVG_DURATION_2: 'KHCL BQ toàn danh mục',
}

export const portfolioByIssuer = {
  YEAR: '%{year}N',
  MONTH: '%{month}Th',
  AVG_DURATION: 'KHCL bình quân TPCP',
  AVG_DURATION_2: 'KHCL bình quân TPCP:',
  AVG_DURATION_3: 'KHCL bình quân TPDN',
  AVG_DURATION_4: 'KHCL bình quân TPDN:',
}

export const expected = {
  UNIT: 'Đơn vị: tr. VND',
  UNIT_2: 'Đơn vị (tr. VND)',
  DAY: 'Ngày',
  MONTH: 'Tháng',
  QUARTER: 'Quý',
  YEAR: 'Năm',
  TOOLTIP:
    'Dòng tiền phải trả dự kiến đã bao gồm nợ tái cấu trúc của các TCPH theo thông tin cập nhật tại thời điểm mới nhất',
  TRADING_DATE: 'Lịch dòng tiền',
  COUPON: 'Lãi Coupon',
  COUPON_2: 'Lãi Coupon:',
  PRINCIPAL: 'Nợ gốc',
  PRINCIPAL_2: 'Nợ gốc:',
  TOTAL: 'Tổng',
}
