import { useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Panel } from '../../../common/panel'
import { calendarTabKey } from '../../../common/tabs/DispatchActionTabWithCalendar'
import { selectIndexInfo } from '../../../common/topInfo/indexTopInfo/store/slice'
import { PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import Deal from './deal'
import {
  selectListCompareChecked,
  selectListTicker,
} from './deal/dropdownSearchTickers/store/slice'
import { selectFilter } from './deal/store/slice'
import { downloadInfoDirectorDeals } from './deal/store/thunk'

const PanelDirectorDeal = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const filterData = useSelector(selectFilter)
  const listCompareChecked = useSelector(selectListCompareChecked)
  const listTicker = useSelector(selectListTicker)
  const basicInfo = useSelector(selectIndexInfo)
  const timeZone = UseTimeZone()

  const handleDownload = () => {
    let params = {
      GroupId: basicInfo.groupId,
      OrganizationIds:
        listCompareChecked.length !== listTicker.length
          ? listCompareChecked.map((item) => item.organizationId).toString()
          : null,
      Type: filterData.stateTypes || null,
      Status: filterData.stateStatus || null,
      SortField: filterData.sortField,
      SortOrder: filterData.sortOrder,
      Term: filterData.owner,
      TransactionType: filterData.transactionType,
      DateType: filterData.dateType,
    }

    if (filterData.timeRange === calendarTabKey) {
      params = {
        ...params,
        StartDate: filterData.dateFrom
          ? getISOStartOrEndOfDay(filterData.dateFrom, timeZone, true)
          : null,
        EndDate: filterData.dateTo
          ? getISOStartOrEndOfDay(filterData.dateTo, timeZone, false)
          : null,
      }
    } else {
      params = {
        ...params,
        TimeRange: filterData.timeRange,
        StartDate: null,
        EndDate: null,
      }
    }

    return downloadInfoDirectorDeals(params)
  }

  return (
    <>
      <Panel
        title={'market.marketInDepth.directorDeal.DEAL'}
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadCsv={true}
        downloadCsv={handleDownload}
      >
        <Deal width={formatNumber(sizes[panelKey].width) - PANEL_PADDING} />
      </Panel>
    </>
  )
}

export default PanelDirectorDeal
