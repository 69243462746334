import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../common/ScrollComponent'
import { keyBy } from '../../utils/Common'
import style from '../index.module.css'
import { selectListType, selectTickerId } from '../store/slice'
import { filerMyWatchListThunk, getTickerByExchangeThunk } from '../store/thunk'

const scrollContainerClass = 'exchange-sector-container'

const paddingTopScroll = 80

const infinity = 9999

const timeoutCheckDoubleClick = 350

const TreeItem = ({
  item,
  nameKey,
  valueSearch,
  isParentOpen,
  handleParentOpen,
  isResearch,
  offsetTopScroll,
}) => {
  const dispatch = useDispatch()
  const id = useSelector(selectTickerId)

  const pRef = useRef()
  const isDoubleClick = useRef(false)

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (isOpen) handleParentOpen()
  }, [isOpen])

  useEffect(() => {
    if (!isParentOpen) setIsOpen(false)
  }, [isParentOpen])

  useEffect(() => {
    if (
      valueSearch.trim() &&
      item[nameKey].toUpperCase().includes(valueSearch.trim().toUpperCase())
    ) {
      handleParentOpen()
      setTimeout(() => {
        if (pRef.current.offsetTop < offsetTopScroll.current) {
          offsetTopScroll.current = pRef.current.offsetTop
          document
            .querySelector(
              `.${scrollContainerClass} .scrollbars div:first-child`,
            )
            .scroll({
              top: pRef.current.offsetTop - paddingTopScroll,
            })
        }
      }, 50)
    }
  }, [valueSearch, isResearch])

  const handleOpen = () => {
    if (!isOpen) setIsOpen(true)
  }

  const handleClick = () => {
    setTimeout(() => {
      if (!isDoubleClick.current) setIsOpen(!isOpen)
    }, timeoutCheckDoubleClick)
  }

  const handleAddTickers = () => {
    isDoubleClick.current = true
    dispatch(getTickerByExchangeThunk({ Id: item.id, TypeName: item.typeId }))

    setTimeout(() => {
      isDoubleClick.current = false
    }, timeoutCheckDoubleClick + 50)
  }

  useEffect(() => {
    dispatch(getTickerByExchangeThunk({ Id: id, TypeName: 'OrganizationId' }))
  }, [id])
  return (
    <li
      className={`${style.clearHover} ${
        !isParentOpen ? style.displayNone : ''
      }`}
    >
      <p
        ref={pRef}
        className={`mb-10 mt-0 ${style.pHover} ${
          valueSearch.trim() &&
          item[nameKey].toUpperCase().includes(valueSearch.trim().toUpperCase())
            ? style.pActive
            : ''
        }`}
        onDoubleClick={handleAddTickers}
        onClick={handleClick}
      >
        {item?.children && (
          <i
            className={
              item?.children && Object.keys(item?.children).length === 0
                ? style.ml13
                : isOpen
                ? 'icon-caret-down'
                : 'icon-caret-right'
            }
          />
        )}

        {item[nameKey]}
      </p>
      {item?.children && Object.keys(item?.children).length > 0 && (
        <ul>
          {Object.keys(item.children).map((key) => (
            <div key={item.children[key].icbId || item.children[key].groupId}>
              <TreeItem
                item={item.children[key]}
                nameKey={nameKey}
                valueSearch={valueSearch}
                isParentOpen={isOpen}
                handleParentOpen={handleOpen}
                isResearch={isResearch}
                offsetTopScroll={offsetTopScroll}
              />
            </div>
          ))}
        </ul>
      )}
    </li>
  )
}

TreeItem.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  isResearch: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  nameKey: PropTypes.string.isRequired,
  isParentOpen: PropTypes.bool.isRequired,
  handleParentOpen: PropTypes.func.isRequired,
  offsetTopScroll: PropTypes.object.isRequired,
}

const ExchangeTree = ({ valueSearch, isResearch, offsetTopScroll }) => {
  const data = useSelector(selectListType)
  const dataRender = useMemo(() => {
    let dataParent = {}
    if (data.length > 0) {
      dataParent = keyBy(
        data
          .filter((e) => e.parentId === e.id)
          .map((key) => ({
            ...key,
            children: keyBy(
              data.filter(
                (itemChil) =>
                  itemChil.parentId !== itemChil.id &&
                  itemChil.parentId === key.parentId,
              ),
              'id',
            ),
          })),
        'id',
      )
    }

    return dataParent
  }, [data])

  const handleOpen = () => {}

  return (
    <li>
      {Object.keys(dataRender).length > 0 && (
        <ul>
          {Object.keys(dataRender)
            .sort()
            .map((key) => (
              <div key={dataRender[key].id}>
                <TreeItem
                  item={dataRender[key]}
                  nameKey={'typeName'}
                  valueSearch={valueSearch}
                  isParentOpen={true}
                  handleParentOpen={handleOpen}
                  isResearch={isResearch}
                  offsetTopScroll={offsetTopScroll}
                />
              </div>
            ))}
        </ul>
      )}
    </li>
  )
}

ExchangeTree.propTypes = {
  valueSearch: PropTypes.string.isRequired,
  isResearch: PropTypes.bool.isRequired,
  offsetTopScroll: PropTypes.object.isRequired,
}

const ExchangeSectorList = () => {
  const offsetTopScroll = useRef(infinity)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(filerMyWatchListThunk())
  }, [])

  return (
    <>
      {/* <div className="filter-box">
        <p className="name">
          <Span style={{ fontSize: 10 }}>
            <Translate value="watchlistBond.watchlistDetail.TITLE_SEARCH_GROUP" />
          </Span>
        </p>
        <div className="form-search-inbox m-0">
          <Input
            onChange={handleChangeInput}
            type="text"
            className="search-inbox h-20"
            placeholder={I18n.t('watchlistBond.watchlistDetail.SEARCH_PLH')}
            style={{ fontSize: 10 }}
          />
          <button
            onClick={handleResearch}
            className={style.pointer}
            type="button"
          >
            <i className="icon-search-2" />
          </button>
        </div>
      </div> */}
      <div
        style={{ paddingBottom: '20px' }}
        className={'content-wrapper ' + scrollContainerClass}
      >
        <ScrollComponent
          autoHeight={true}
          autoHeightMax={200}
          verticalTrackWidth={5}
          horizontalTrackWidth={5}
        >
          <ul className={style.pl0}>
            <ExchangeTree
              valueSearch={''}
              isResearch={false}
              offsetTopScroll={offsetTopScroll}
            />
          </ul>
        </ScrollComponent>
      </div>
    </>
  )
}

export default ExchangeSectorList
