import localInstitutionProxy from '../../../proxies/market/marketInDepth/LocalInstitutionProxy'
import { ServiceBase } from '../../ServiceBase'

export class LocalInstitutionService extends ServiceBase {
  getNetValue(params) {
    return this.getData(() => localInstitutionProxy.getNetValue(params))
  }

  getTopNetValue(params) {
    return this.getData(() => localInstitutionProxy.getTopNetValue(params))
  }

  getSectorSegmentation(params) {
    return this.applyMemoryCache(
      'LocalInstitutionService.getSectorSegmentation',
      params,
    )(() => localInstitutionProxy.getSectorSegmentation(params))
  }

  downloadSectorSegmentation(params) {
    return this.getFileDownload(() =>
      localInstitutionProxy.downloadSectorSegmentation(params),
    )
  }

  downloadTopNetValue(params) {
    return this.getFileDownload(() =>
      localInstitutionProxy.downloadTopNetValue(params),
    )
  }
}

export default new LocalInstitutionService()
