import moment from 'moment'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { LINE_CHART_COLORS } from '../../../constants'
import { formatMonthYear } from '../../panelOpenMarket/helper'

export const renderFooter = (keyArea, lineKeys, title) => {
  const lineFooter = lineKeys.map((key, index) => {
    return {
      text: title[key],
      type: TYPE.CIRCLE_MARKER_LINE,
      after: {
        bgColor: LINE_CHART_COLORS[index],
      },
      isI18n: false,
    }
  })

  return [
    {
      text: title[keyArea],
      type: TYPE.SQUARE,
      before: {
        bgColor:
          'linear-gradient(#37a77d 0%, #37a77d80 50%, #e84855 50%, #e8485580 100%)',
      },
      isI18n: false,
    },
    ...lineFooter,
  ]
}

export const handleComboChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const dateNotDuplicate = handleDuplicateByKey(payload, ['publicDateId'])
  return dateNotDuplicate.map((item) => {
    const dataWithPublicDateId = payload.filter(
      (i) => i.publicDateId === item.publicDateId,
    )

    return dataWithPublicDateId.reduce((previous, current) => {
      return {
        ...previous,
        [current.moneyMarketOperationsVNTypeId]:
          current.winningVolume / 1000000000,
        day: item.publicDateId,
      }
    }, {})
  })
}

export const handleTitle = (payload) => {
  if (!payload.length) {
    return []
  }

  return handleDuplicateByKey(payload, [
    'moneyMarketOperationsVNTypeName',
  ]).reduce((previous, current) => {
    return {
      ...previous,
      [current.moneyMarketOperationsVNTypeId]:
        current.moneyMarketOperationsVNTypeName,
    }
  }, {})
}

export const handleTime = (chartData, timeZone, locale) => {
  return chartData
    .map((item) => ({
      ...item,
      day: formatDateTime(item.day, FORMAT.DATE, locale, timeZone),
      monthYear: formatMonthYear(new Date(item.day)),
    }))
    .sort((a, b) =>
      moment(a.day, FORMAT.DATE[locale]).diff(
        moment(b.day, FORMAT.DATE[locale]),
      ),
    )
}

export const getTooltipContentData = (payload, title) => {
  const keys = Object.keys(title)

  return keys
    .map((key) => {
      return {
        label: title[key],
        value: payload[key],
        key,
      }
    })
    .reverse()
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}
