import PropTypes from 'prop-types'
import { COMPONENT, COMPONENT_FILTER } from '../../../configs/Layout'
import { Panel } from '../../common/panel'
import PanelFilter from '../../common/panelFilter'
import { PreOpenMarket } from '../../common/preOpenMarket/PreOpenMarket'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { withWrapper } from '../../common/withWrapper'
import { Filter } from './filter'
import { TreeMapPanel } from './TreeMapPanel'

const MAP_KEY = {
  FILTER: 'FILTER',
  HEATMAP: 'HEATMAP',
}

const MAP_SIZE = {
  [MAP_KEY.FILTER]: {
    width: `calc(240px - ${2 * COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: COMPONENT_FILTER.MIN_SIZE.WIDTH,
    minHeight: 500,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.HEATMAP,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.HEATMAP]: {
    width: `calc(100% - ${240}px)`,
    height: '100%',
    top: 0,
    left: 240,
    minWidth: COMPONENT_FILTER.MIN_SIZE.WIDTH * 4,
    minHeight: 500,
    minTop: 0,
    minLeft: COMPONENT_FILTER.MIN_SIZE.WIDTH + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const HeatMap = ({ sizes, setSizes }) => {
  return (
    <div
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <div className="w-100 h-100 position-relative">
          <PanelFilter
            title="market.heatmap.FILTER"
            panelRefs={panelRefs}
            panelKey={MAP_KEY.FILTER}
            sizes={sizes}
            setSizes={setSizes}
          >
            <Filter />
          </PanelFilter>
          <Panel
            panelRefs={panelRefs}
            panelKey={MAP_KEY.HEATMAP}
            sizes={sizes}
            setSizes={setSizes}
            windowSize={sizes[MAP_KEY.HEATMAP]}
            windowZoom={true}
          >
            <PreOpenMarket>
              <TreeMapPanel
                width={sizes[MAP_KEY.HEATMAP].width}
                height={sizes[MAP_KEY.HEATMAP].height}
              />
            </PreOpenMarket>
          </Panel>
        </div>
      </ScrollGroupComponent>
    </div>
  )
}

HeatMap.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(HeatMap)
