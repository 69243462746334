import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { TAB_TYPE } from '../../table/constants'
import { convertData } from '../../table/helper'
import { handlePPIData } from '../helper'
import {
  getGrowthByTimeRange,
  growthIICChart,
  growthIIIChart,
  growthIIPChart,
} from './thunk'

const slice = createSlice({
  name: 'economy/productionAndConsumption/productGrowthChart',
  initialState: {
    isLoading: true,
    data: [],
    activeSelect: null,
    timeRangeYearly: 'SevenYears',
    timeRangeAcc: 'OneYear',
    PPILoading: true,
    PPIData: [],
  },
  reducers: {
    changeActiveSelect: (state, action) => {
      state.activeSelect = action.payload
    },
    handleTimeRangeYearly: (state, action) => {
      state.timeRangeYearly = action.payload
    },
    handleTimeRangeAcc: (state, action) => {
      state.timeRangeAcc = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(growthIIPChart.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(growthIIPChart.fulfilled, (state, action) => {
      state.data = convertData(action.payload || [], TAB_TYPE.IIP)
      state.isLoading = false
    })
    builder.addCase(growthIIPChart.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(growthIICChart.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(growthIICChart.fulfilled, (state, action) => {
      state.data = convertData(action.payload || [], TAB_TYPE.IIC)
      state.isLoading = false
    })
    builder.addCase(growthIICChart.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(growthIIIChart.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(growthIIIChart.fulfilled, (state, action) => {
      state.data = convertData(action.payload || [], TAB_TYPE.III)
      state.isLoading = false
    })
    builder.addCase(growthIIIChart.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getGrowthByTimeRange.pending, (state) => {
      state.PPILoading = true
    })
    builder.addCase(getGrowthByTimeRange.fulfilled, (state, action) => {
      state.PPILoading = false
      state.PPIData = handlePPIData(action.payload || [], action.meta.arg)
    })
    builder.addCase(getGrowthByTimeRange.rejected, (state, action) => {
      state.isLoading = action.payload.isLoading
    })
  },
})

export const selectLoadingChartGrowth = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveSelect = (state) => state[slice.name].activeSelect
export const getTimeRangeYearly = (state) => state[slice.name].timeRangeYearly
export const getTimeRangeAcc = (state) => state[slice.name].timeRangeAcc
export const getPPILoading = (state) => state[slice.name].PPILoading
export const getPPIData = (state) => state[slice.name].PPIData

export const { changeActiveSelect, handleTimeRangeYearly, handleTimeRangeAcc } =
  slice.actions

register(slice.name, slice.reducer)
