import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ExportChart } from '.'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectMaxYear, selectYear } from './store/slice'

export const PanelExportChart = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const currentYear = useSelector(selectYear)
  const maxYear = useSelector(selectMaxYear)
  const locale = useSelector((state) => state.i18n.locale)

  const currentSelectedYear = currentYear ?? maxYear

  const nameScreen = I18n.t('sector.sectorSpecific.fishery.OVERVIEW')
  const getChartNameByLocale = () => {
    if (locale === 'en') {
      return `${I18n.t(
        'sector.sectorSpecific.fishery.overview.EXPORT_PRODUCT',
      )} ${currentSelectedYear}`
    } else {
      return `${I18n.t(
        'sector.sectorSpecific.fishery.overview.EXPORT_PRODUCT',
      )} ${currentSelectedYear}`
    }
  }

  return (
    <Panel
      title="sector.sectorSpecific.fishery.overview.EXPORT_PRODUCT"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={getChartNameByLocale()}
      downloadJpgParams={{
        domId: 'exportChart',
        nameScreen: nameScreen,
        chartName: getChartNameByLocale(),
      }}
    >
      <ExportChart
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
