import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class OrderProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('Order', params)
  }

  exportFileProxy(params) {
    return this.post('ExportOrder', params, {}, 'download')
  }
}

export default new OrderProxy(
  ServiceProxyConfig.Bond.SecurityMasterCb.ServiceUrl,
)
