export const MULTIPLIER_FORMAT_DATA = {
  bestBid: 10 ** 3,
  bestBidVolume: 10 ** 3,
  bestOffer: 10 ** 3,
  bestOfferVolume: 10 ** 3,
  totalBuyVolume: 10 ** 3,
  totalSellVolume: 10 ** 3,
  totalMatchVolume: 10 ** 3,
}
export const priceDepthTableSchema = [
  {
    id: 'totalPercentChange1WeekClosePrice',
  },
  {
    id: 'totalPercentChange1MonthClosePrice',
  },
  {
    id: 'totalPercentChangeFTDClosePrice',
  },
  {
    id: 'foreignBuyVolumeTotal',
  },
  {
    id: 'foreignSellVolumeTotal',
  },
  {
    id: 'avg10DayTotalVolume',
  },
  {
    id: 'avg1MonthTotalVolume',
  },
]
