import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../common/calendar/headers/HeaderOneCalendarRange'
import { convertDateFromDatePicker } from '../../../common/calendar/helper'
import InputDateCalendar from '../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Icon } from '../../../common/html/Icon'
import { Span } from '../../../common/html/Span'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import style from '../index.module.css'
import {
  changeScreenerDate,
  selectMaxScreenerDate,
  selectScreenerDate,
} from '../store/slice'
import { getScreeningDateThunk } from '../store/thunk'

const startYear = 2000
const endYear = new Date().getFullYear()

const ScreenerDate = () => {
  const dispatch = useDispatch()

  const timezone = UseTimeZone()

  const screenerDate = useSelector(selectScreenerDate)
  const maxScreenerDate = useSelector(selectMaxScreenerDate)

  useEffect(() => {
    dispatch(getScreeningDateThunk())
  }, [])

  useEffect(() => {
    if (maxScreenerDate && new Date(screenerDate) > new Date(maxScreenerDate)) {
      dispatch(changeScreenerDate(convertDateFromDatePicker(maxScreenerDate)))
    } else {
      dispatch(
        changeScreenerDate(
          convertDateFromDatePicker(
            getISOStartOrEndOfDay(new Date(), timezone, true),
          ),
        ),
      )
    }
  }, [maxScreenerDate])

  const handleChangeDate = (date) => {
    dispatch(changeScreenerDate(date))
  }

  return (
    <>
      <h5 className={style.labelFilter}>
        <Span style={{ fontSize: 11 }}>
          <Translate value="tool.smartScreening.SCREENER_DATE" />
        </Span>
      </h5>
      <div className="form-control calendar mb-0">
        <div className={`${style.bgGrey} w-100 h-20`}>
          <CalendarPopper
            date={screenerDate ? new Date(screenerDate) : new Date()}
            maxDate={
              maxScreenerDate
                ? new Date(convertDateFromDatePicker(maxScreenerDate))
                : undefined
            }
            handleChange={handleChangeDate}
            customHeader={HeaderOneCalendarRange({
              startYear,
              endYear,
            })}
            CustomInput={InputDateCalendar}
          />
        </div>
        <Icon
          className={`icon-date ${style.eventNone}`}
          style={{ fontSize: 12 }}
        />
      </div>
    </>
  )
}

export default ScreenerDate
