import SameIndustryBondProxy from '../../../proxies/bond/valuationProxy/sameIndustryBondsProxy'
import { ServiceBase } from '../../ServiceBase'

export class SameIndustryBonds extends ServiceBase {
  getDataSameIndustry(params) {
    return this.getData(() => SameIndustryBondProxy.getDataProxy(params))
  }
  exportFile(params) {
    return this.getFileDownload(() =>
      SameIndustryBondProxy.exportFileProxy(params),
    )
  }
}

export default new SameIndustryBonds()
