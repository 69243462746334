import FilterOption from './FilterOption'
import Table from './table'

const Filter = () => {
  return (
    <div style={{ flexDirection: 'column' }} className="h-100 d-flex">
      <div style={{ height: '55%', overflow: 'scroll' }}>
        <FilterOption />
      </div>
      <div style={{ height: '45%', position: 'relative' }}>
        <Table />
      </div>
    </div>
  )
}

export default Filter
