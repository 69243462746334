import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { FinancialStatementTable } from '.'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { selectFilterLocal, selectFinancialFilter } from '../store/slice'
import { downloadFinancialData } from '../store/thunk'
import { FORMAT_DATA_BY_UNIT } from './constants'

const DOWNLOAD_OPTION = [
  {
    text: 'corporate.financialData.financialStatement.FIINPRO_X_VERSION',
    params: {
      form: 'FiinProX',
    },
  },
  {
    text: 'corporate.financialData.financialStatement.FIINPRO_VERSION',
    params: {
      form: 'FiinPro',
    },
  },
]

export const PanelTable = ({ panelRefs, mapKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const financialFilter = useSelector(selectFinancialFilter)
  const filterLocal = useSelector(selectFilterLocal)

  const downloadCsv = (moreParams) => {
    const params = {
      OrganizationId: basicInfo.organizationId,
      TimeFilter: financialFilter.viewBy.value,
      NumberOfPeriod: financialFilter.numberOfPeriod.value,
      LatestYear: financialFilter.lastestYear.value,
      Consolidated: financialFilter.type.value,
      CommonSize: financialFilter.commonSize.value,
      CurrencyCode: filterLocal.currency.value,
      Divisor: FORMAT_DATA_BY_UNIT[filterLocal.unit.value],
      ...moreParams,
    }

    return downloadFinancialData(params)
  }

  return (
    <Panel
      title="corporate.financialData.financialStatement.FINANCIAL_STATEMENT"
      panelRefs={panelRefs}
      panelKey={mapKey.FINANCIAL_TABLE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
      isMultiOption={true}
      options={DOWNLOAD_OPTION}
    >
      <FinancialStatementTable />
    </Panel>
  )
}

PanelTable.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  mapKey: PropTypes.object.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}
