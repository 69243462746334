import { useSelector } from 'react-redux'
import useComGroup from '../../../../common/hooks/useComGroup'
import { Panel } from '../../../../common/panel'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { FORMAT_DATA_BY_UNIT, TABLE_TYPE } from './constant'
import Header from './header'
import { selectOrgIds } from './header/store/slice'
import { downloadTableByType } from './helper'
import { selectFilter, selectTableType } from './store/slice'
import TableChart from './tableChart'
import TableTree from './tableTree'

const PanelPeersComparison = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const type = useSelector(selectTableType)
  const basicInfo = useSelector(selectBasicInfo)
  const orgIds = useSelector(selectOrgIds)
  const filter = useSelector(selectFilter)
  const comGroup = useComGroup(basicInfo?.organizationId)

  const isTreeTable = [
    TABLE_TYPE.BALANCE_SHEET,
    TABLE_TYPE.INCOME_STATEMENT,
  ].includes(type)

  const params = {
    organizationId: basicInfo.organizationId,
    peerOrganizationIds: orgIds,
    Quarter: isTreeTable ? filter.quarter : undefined,
    Year: isTreeTable ? filter.year : undefined,
    currencyCode: isTreeTable ? filter.currency : undefined,
    divisor: isTreeTable ? FORMAT_DATA_BY_UNIT[filter.unit] : undefined,
  }

  return (
    <Panel
      title="corporate.peerValuation.overview.PEERS_COMPARISON"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadTableByType({ type, params, comGroup })}
    >
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Header
                width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
              />
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  {isTreeTable ? (
                    <TableTree tableType={type} />
                  ) : (
                    <TableChart tableType={type} />
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </Panel>
  )
}

export default PanelPeersComparison
