import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import PieChartComponent from '../../../common/PieChartComponent'
import Title from '../../../common/Title'
import { selectQuarter, selectYear } from '../../store/slice'
import {
  selectDataCasaMarketShare,
  selectLoadingCasaMarketShare,
} from '../store/slice'
import { getBankDepositCasaMarketShare } from '../store/thunk'

const DepositCasaMarketShare = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataCasaMarketShare)
  const loading = useSelector(selectLoadingCasaMarketShare)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getBankDepositCasaMarketShare({
          OrganizationId: basicInfo.organizationId,
          Year: year,
          Quarter: quarter,
        }),
      )
    }
  }, [year, quarter, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title text="CASA_MARKET_SHARE" />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <PieChartComponent
                    data={dataChart}
                    height={height - size.height}
                    datakey="cms"
                    currentTicker={basicInfo?.ticker}
                    width={width}
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default DepositCasaMarketShare
