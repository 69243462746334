export const DATE_FILTER = {
  YEARLY: [
    {
      value: 'FiveYears',
      title: '5Y',
    },
    {
      value: 'SevenYears',
      title: '7Y',
    },
    {
      value: 'TenYears',
      title: '10Y',
    },
    {
      value: 'TwentyYears',
      title: '20Y',
    },
  ],
  MONTHLY: [
    {
      value: 'OneYear',
      title: '1Y',
    },
    {
      value: 'ThreeYears',
      title: '3Y',
    },
    {
      value: 'FiveYears',
      title: '5Y',
    },
    {
      value: 'TenYears',
      title: '10Y',
    },
  ],
}

export const BALANCE_ID_LIST = [0]
export const EXPORT_ID_LIST = [80, 78]
export const IMPORT_ID_LIST = [484, 482]
