export const BANK_TAB = {
  LOAN: 'LOAN',
  DEPOSIT: 'DEPOSIT',
}

export const BANK_TAB_OPTION = [
  {
    title: 'corporate.businessModel.penetrationAnalysis.LOAN',
    value: BANK_TAB.LOAN,
  },
  {
    title: 'corporate.businessModel.penetrationAnalysis.DEPOSIT',
    value: BANK_TAB.DEPOSIT,
  },
]

export const LOAN_SECTOR = {
  trading: 'nB38',
  agricultureForestry: 'nB39',
  manufacturing: 'nB40',
  construction: 'nB45',
  personalPublicServices: 'nB46',
  warehousing: 'nB55',
  trainingEducation: 'nB58',
  estateAgent: 'nB61',
  hotelRestaurants: 'nB62',
  financialServices: 'nB63',
  others: 'nB64',
}
