import React from 'react'
import { useSelector } from 'react-redux'
import ChartData from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { VIEW_BY } from '../constants'
import { selectTimeFilter } from './store/slice'

const ChartDataPanel = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeFilter = useSelector(selectTimeFilter)
  const chartName = UseI18n('economy.fdi.overview.TITLE_PANEL_CHART')

  return (
    <Panel
      title={'economy.fdi.overview.TITLE_PANEL_CHART'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'totalRegisteredFDICapitalChart',
        nameScreen: UseI18n('economy.fdi.overview.NAME_SCREEN'),
        chartName: UseI18n('economy.fdi.overview.TITLE_PANEL_CHART'),
        tabName:
          timeFilter === VIEW_BY.MONTHLY
            ? UseI18n('economy.fdi.overview.MONTHLY')
            : UseI18n('economy.fdi.overview.YEARLY'),
      }}
      titleJpg={chartName}
    >
      <ChartData
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default ChartDataPanel
