import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { Button } from '../../../../../common/html/Button'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { changeLoading } from '../store/slice'
import style from './index.module.css'
import { selectLoading, selectSelectedWL, selectWatchlist } from './store/slice'
import { getTickerByWLId, getWatchList } from './store/thunk'

const AddByWatchlist = ({ keyword, handleClosePopup }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const watchlist = useSelector(selectWatchlist)
  const selectedWL = useSelector(selectSelectedWL)
  const loading = useSelector(selectLoading)

  const [filteredWL, setFilteredWL] = useState([])
  const [selectingWL, setSelectingWL] = useState(null)

  useEffect(() => {
    dispatch(getWatchList())
  }, [])

  useEffect(() => {
    setSelectingWL(selectedWL)
  }, [selectedWL])

  useEffect(() => {
    setFilteredWL(
      watchlist.filter((wl) =>
        wl.watchListName?.toLowerCase().includes(keyword.toLowerCase()),
      ),
    )
  }, [watchlist, keyword])

  const handleChooseWL = (wlId) => {
    setSelectingWL(wlId)
  }

  const handleCancel = () => {
    handleClosePopup()
  }

  const handleApply = () => {
    if (selectedWL !== selectingWL) {
      dispatch(
        getTickerByWLId({
          WatchListId: selectingWL,
          currentOrgId: basicInfo.organizationId,
        }),
      )
      dispatch(changeLoading(true))
    }
    handleClosePopup()
  }

  const renderWatchList = () => (
    <ScrollComponent>
      {filteredWL.map((wl) => (
        <div
          key={wl.watchListId}
          onClick={() => handleChooseWL(wl.watchListId)}
          className={`${style.wlItem} ${
            wl.watchListId === selectingWL ? style.active : ''
          }`}
        >
          <div className={style.wlTitle}>
            <TextEllipsis isI18n={false} text={wl.watchListName} />
          </div>
          <div className={style.wlItemDescription}>
            <TextEllipsis isI18n={false} text={wl.description} />
          </div>
        </div>
      ))}
    </ScrollComponent>
  )

  return (
    <>
      <div className={style.modalContent}>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div className={style.listWLHeader}>
                  <div className={style.wlTitle}>
                    <Span style={{ fontSize: 10, fontWeight: 600 }}>
                      <Translate value="corporate.peerValuation.overview.TITLE" />
                    </Span>
                  </div>
                  <div className={style.wlDescription}>
                    <Span style={{ fontSize: 10, fontWeight: 600 }}>
                      <Translate value="corporate.peerValuation.overview.DESCRIPTION" />
                    </Span>
                  </div>
                </div>
                {size.height && (
                  <div style={{ height: `calc(100% - ${size.height}px)` }}>
                    {loading ? (
                      <Loading />
                    ) : filteredWL.length ? (
                      renderWatchList()
                    ) : (
                      <NoData />
                    )}
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
      <div className={style.groupButtonModal}>
        <Button className="btn bg-grey-1 w-80 h-20" onClick={handleCancel}>
          <Translate value={'common.button.BUTTON_CANCEL'} />
        </Button>
        <Button onClick={handleApply} className={`btn btn-blue w-80 h-20 `}>
          <Translate value={'common.button.BUTTON_APPLY'} />
        </Button>
      </div>
    </>
  )
}

export default AddByWatchlist
