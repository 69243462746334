import { EMPTY_VALUE } from '../../../constants/Common'
import { LIST_TYPE } from '../../common/constants'
import { equationAlphabet, equationIndicatorAliasAlphabet } from './constants'

export const getDataAfterDeleteCompany = (objData, ids) => {
  if (objData) {
    const newObjData = Object.keys(objData).reduce((obj, key) => {
      const isDelete = ids.every((id) =>
        objData[key].every((val) => val !== id),
      )
      return isDelete
        ? obj
        : {
            ...obj,
            [key]: objData[key],
          }
    }, {})

    return Object.keys(objData).length !== Object.keys(newObjData)
      ? newObjData
      : undefined
  }
}

export const getDataAfterDeleteSecurities = (
  equationSecurities,
  deleteType,
  id,
) => {
  const { companies, indices, sector } = equationSecurities
  const newIndices =
    deleteType === LIST_TYPE.INDICES
      ? Object.keys(indices).reduce(
          (obj, key) =>
            key === String(id) ? obj : { ...obj, [key]: indices[key] },
          {},
        )
      : indices || []
  const newSector =
    deleteType === LIST_TYPE.SECTOR
      ? Object.keys(sector).reduce(
          (obj, key) =>
            key === String(id) ? obj : { ...obj, [key]: sector[key] },
          {},
        )
      : sector || []
  const deleteCompanyIds = equationSecurities[deleteType][id]
  const compareCompanyIds = []
  const newCompanyIds = companies.filter((val) => {
    if (deleteCompanyIds.includes(val)) {
      compareCompanyIds.push(val)
      return false
    }
    return true
  })
  const otherSecuritiesCompanyIds = [
    ...new Set(
      [].concat(...[...Object.values(newIndices), ...Object.values(newSector)]),
    ),
  ]
  compareCompanyIds.forEach(
    (id) => otherSecuritiesCompanyIds.includes(id) && newCompanyIds.push(id),
  )
  return {
    companies: newCompanyIds,
    [deleteType]: deleteType === LIST_TYPE.INDICES ? newIndices : newSector,
  }
}

export const checkEquationCountInvalid = (
  equationSecurities,
  equationIndicators,
) => {
  return (
    (![
      ...(equationSecurities.indices?.childrenExchange || []),
      ...(equationSecurities.indices?.parentExchange || []),
    ].length &&
      ![
        ...(equationSecurities.sector?.childrenSector || []),
        ...(equationSecurities.sector?.parentSector || []),
      ].length) ||
    !Object.keys(equationIndicators).length
  )
}

export const checkEquationCountConditionsValid = (equationIndicators) => {
  for (
    let indicatorIndex = 0;
    indicatorIndex < Object.values(equationIndicators).length;
    indicatorIndex++
  ) {
    const indicator = Object.values(equationIndicators)[indicatorIndex]

    for (
      let itemIndex = 0;
      itemIndex < Object.values(indicator.data).length;
      itemIndex++
    ) {
      const item = indicator.data[itemIndex]

      if (
        (typeof item.valueFrom !== 'number' || isNaN(item.valueFrom)) &&
        (typeof item.valueTo !== 'number' || isNaN(item.valueTo))
      ) {
        return false
      }
    }
  }

  return true
}

export const checkEquationSumAvgInvalid = (
  equationSecurities,
  equationIndicators,
) => {
  return (
    !(equationSecurities.companies || []).length ||
    !Object.keys(equationIndicators).length
  )
}

export const checkEquationValid = (
  isEquationTypeCount,
  isFormulaValid,
  equationSecurities,
  equationIndicators,
  setMessageError,
) => {
  if (
    (isEquationTypeCount &&
      checkEquationCountInvalid(equationSecurities, equationIndicators)) ||
    (!isEquationTypeCount &&
      checkEquationSumAvgInvalid(equationSecurities, equationIndicators))
  ) {
    setMessageError('financialChart.EQUATION_SECURITIES_INDICATORS_REQUIRED')
    return false
  }

  if (!isEquationTypeCount) {
    const units = Object.values(equationIndicators)
      .map((item) => item.unit)
      .filter(
        (unit, index, arr) => index === arr.findIndex((val) => val === unit),
      )

    if (units.length > 1) {
      setMessageError('financialChart.EQUATION_INDICATORS_NOT_SAME_UNIT')
      return false
    }

    if (!isFormulaValid) {
      setMessageError('financialChart.EQUATION_FORMULA_INVALID')
      return false
    }
  }

  return true
}

export const getIndicatorAlias = (equationIndicators) => {
  const usedAlias = []
  Object.values(equationIndicators).forEach((indicator) =>
    Object.values(indicator.data).forEach(
      (item) => item.alias && usedAlias.push(item.alias),
    ),
  )
  const remainingAlphabet = equationIndicatorAliasAlphabet
    .split('')
    .filter((val) => !usedAlias.includes(val))
  return remainingAlphabet.length ? remainingAlphabet[0] : EMPTY_VALUE
}

export const getIndicatorAliasUsed = (equationIndicators) => {
  const usedAlias = []
  Object.values(equationIndicators).forEach((indicator) =>
    Object.values(indicator.data).forEach(
      (item) => item.alias && usedAlias.push(item.alias),
    ),
  )
  return usedAlias
}

export const checkFormulaCharacterValid = (value) => {
  const specialCharValid = '+-/*()'
  const charValid = equationAlphabet + specialCharValid

  if (!value) return true
  return !value.split('').some((char) => !charValid.includes(char))
}

export const checkFormulaValid = (value, alias) => {
  if (!value || !checkFormulaCharacterValid(value)) {
    return false
  }

  const formulaWithAlphabetOnly = value.replace(/[0-9/*()+\-/]/g, '')

  if (formulaWithAlphabetOnly.split('').some((val) => !alias.includes(val))) {
    return false
  }

  const formulaWithoutSpecialChar = value.replace(/[*()+\-/]/g, '#')

  if (
    formulaWithoutSpecialChar
      .split('#')
      .some(
        (val) =>
          (val.length > 1 && (isNaN(+val) || !+val)) ||
          (val.length === 1 && +val === 0),
      )
  ) {
    return false
  }

  try {
    let number = 1
    const formulaWithNumber = value
      .split('')
      .map((val) => (equationAlphabet.includes(val) ? number++ : val))
      .join('')
    // eslint-disable-next-line no-eval
    const result = eval(formulaWithNumber)

    if (isNaN(result) || !isFinite(result)) {
      return false
    }
  } catch (error) {
    return false
  }

  return true
}
