export const convertWaterfallData = (data, item) => {
  const waterfallData = []
  const origVal = data.map((item) => item.value)
  const indexOfItem = data.indexOf(item)

  const getFirstVal = (indexOfItem) => {
    let firstVal = 0
    for (let i = indexOfItem; i > 0; i--) {
      firstVal += origVal[i - 1]
    }
    return firstVal
  }

  if (indexOfItem === 0) {
    waterfallData.push(0, origVal[0])
  } else {
    waterfallData.push(
      getFirstVal(indexOfItem),
      getFirstVal(indexOfItem) + origVal[indexOfItem],
    )
  }

  return waterfallData
}
