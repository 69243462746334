import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import { SizeTracker } from '../../../common/sizeTracker'
import style from '../index.module.css'
import { ChartComponent } from './ChartComponent'
import { COMPONENTS_FILTER } from './constants'
import {
  changeComponent,
  keepListCheckbox,
  selectCurrentTypeStatistics,
} from './store/slice'

export const ChangeComponents = ({ width, height }) => {
  const dispatch = useDispatch()

  const currentType = useSelector(selectCurrentTypeStatistics)

  const [currentComponentFilter, setCurrentComponentFilter] = useState(
    COMPONENTS_FILTER[0].value,
  )

  const handleChangeComponent = (item) => {
    setCurrentComponentFilter(item)
    dispatch(changeComponent(item))
    dispatch(keepListCheckbox(false))
  }

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <>
            <div className={`${style.inputDropdown}`}>
              <SelectCustom
                value={currentComponentFilter}
                isI18n={true}
                selectData={COMPONENTS_FILTER}
                handleChange={handleChangeComponent}
              />
            </div>
            {size.height && (
              <div
                style={{ height: height - size.height }}
                id="changeComponents"
              >
                <ChartComponent
                  width={width}
                  height={height - size.height}
                  currentType={currentType}
                  currentComponentFilter={currentComponentFilter}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  )
}
