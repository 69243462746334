import PropTypes from 'prop-types'
import { DangerouslySetInnerHTML } from '../../../common/html/DangerouslySetInnerHTML'

const EnterpriseInformation = ({ profile }) => {
  return <DangerouslySetInnerHTML html={profile} />
}

export default EnterpriseInformation

EnterpriseInformation.propTypes = {
  profile: PropTypes.string,
}
