import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import Tree from '../../../../../common/tree'
import {
  changeFilter,
  selectCheckedIndicators,
  selectIndicators,
  selectLoadingIndicators,
} from '../store/slice'
import style from './index.module.css'

const checkStatus = {
  UNCHECK: 'uncheck',
  CHECK_HALF: 'checkHalf',
  CHECK_ALL: 'checkAll',
}

export const FilterRatio = () => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const indicators = useSelector(selectIndicators)
  const checkedIndicators = useSelector(selectCheckedIndicators)
  const loading = useSelector(selectLoadingIndicators)

  // Use state
  const [valueSearch, setValueSearch] = useState('')
  const [defaultCheckedIndicators, setDefaultCheckedIndicators] = useState([])
  const [checkAllStatus, setCheckAllStatus] = useState(checkStatus.CHECK_ALL)
  const [isFirstTime, setIsFirstTime] = useState(true)

  // Get data
  const getIndicators = useMemo(() => {
    const translateChildItem = (child) => {
      Object.values(child).forEach((item) => {
        item.indicatorName = I18n.t(item.indicatorName)
        if (Object.keys(item.children).length) {
          translateChildItem(item.children)
        }
      })
    }

    const newIndicators = JSON.parse(JSON.stringify(indicators))
    translateChildItem(newIndicators)

    return newIndicators
  }, [indicators, locale])

  const getCheckStatusIcon = useMemo(() => {
    switch (checkAllStatus) {
      case checkStatus.CHECK_ALL:
        return 'icon-true'
      case checkStatus.CHECK_HALF:
        return 'icon-minus'
      default:
        return ''
    }
  }, [checkAllStatus])

  // Actions
  const changeIndicatorsChecked = (value) => {
    if (value.length && isFirstTime) {
      setDefaultCheckedIndicators(value)
      setCheckAllStatus(checkStatus.CHECK_ALL)
      setIsFirstTime(false)
    }

    if (value.length < defaultCheckedIndicators.length) {
      setCheckAllStatus(checkStatus.CHECK_HALF)
    }

    if (!value.length) {
      setCheckAllStatus(checkStatus.UNCHECK)
    }

    if (value.length === defaultCheckedIndicators.length) {
      setCheckAllStatus(checkStatus.CHECK_ALL)
    }

    dispatch(
      changeFilter({
        checkedIndicators: value,
      }),
    )
  }

  const handleChangeInput = (e) => setValueSearch(e.target.value)

  const handleChangeCheckStatus = () => {
    let newCheckedIndicators = []
    let newCheckAllStatus = checkStatus.UNCHECK

    switch (checkAllStatus) {
      case checkStatus.UNCHECK:
      case checkStatus.CHECK_HALF:
        newCheckAllStatus = checkStatus.CHECK_ALL
        newCheckedIndicators = defaultCheckedIndicators
        break
      default:
        newCheckAllStatus = checkStatus.UNCHECK
        newCheckedIndicators = []
        break
    }

    setCheckAllStatus(newCheckAllStatus)
    dispatch(
      changeFilter({
        checkedIndicators: newCheckedIndicators,
      }),
    )
  }

  // Render
  const renderIndicators = () => {
    return (
      <SizeTracker>
        {(size) => (
          <>
            <div
              className="form-search-inbox mb-10 mt-8"
              style={{ marginLeft: 0, marginRight: 0 }}
            >
              <Input
                type="text"
                className={`search-inbox bg-grey`}
                style={{ fontSize: 11 }}
                placeholder={I18n.t(
                  'sector.financialAnalysis.corporate.SEARCH',
                )}
                value={valueSearch}
                onChange={handleChangeInput}
              />
              <button type="button">
                <i className="icon-search-2" />
              </button>
            </div>
            <div
              style={{
                width: 12,
                paddingBottom: 8,
                borderBottom: '1px solid #3a3e49',
              }}
            >
              <div
                className={style.checkBoxAll}
                style={{
                  color:
                    checkAllStatus === checkStatus.CHECK_ALL ? '#2f9aee' : '',
                }}
                onClick={handleChangeCheckStatus}
              >
                <i className={getCheckStatusIcon} />
              </div>
            </div>
            {size.height && (
              <div
                style={{ height: `calc(100% - ${size.height}px` }}
                className="flex-1 h-100"
              >
                <Tree
                  valueSearch={valueSearch}
                  treeData={getIndicators}
                  listChecked={checkedIndicators}
                  idKey="indicatorId"
                  nameKey="indicatorName"
                  sortKey="orderNumber"
                  isHaveCheckbox
                  defaultOpenLevel1={true}
                  setListChecked={changeIndicatorsChecked}
                  isDefaultCheckAll
                  isTextEllipsis
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    )
  }

  return (
    <div className="flex-1 flex-column h-100">
      <Span style={{ opacity: 0.4, fontSize: 10 }}>
        <Translate value={'sector.financialAnalysis.corporate.RATIO'} />
      </Span>
      {loading ? (
        <Loading />
      ) : Object.keys(indicators) === 0 ? (
        <NoData />
      ) : (
        renderIndicators()
      )}
    </div>
  )
}
