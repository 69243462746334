export const advancedSearch = {
  ADVANCED_SEARCH: 'Advanced Search',
  NO_MATCHED_RESULTS: 'No matched companies, sectors and functions for',
  MORE_RESULTS_FOR: 'More results for',
  NEW_SEARCH_FOR: 'News search for',
  COMPANIES: 'Companies',
  MORE_COMPANIES: 'More companies ...',
  BOND: 'Bond',
  MORE_BONDS: 'More bonds...',
  DERIVATIVE: 'Derivative',
  MORE_DERIVATIVES: 'More derivatives ...',
  FUNCTION: 'Function',
  MORE_FUNCTIONS: 'More functions ...',
  INDEX: 'Index',
  MORE_INDICES: 'More indices ...',
  SECTOR: 'Sector',
  MORE_SECTOR: 'More sectors ...',
  ICB_LEVEL: 'ICB Level',
}
