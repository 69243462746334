import { useEffect, useRef, useState } from 'react'
import TextEllipsis from '../../../../../common/textEllipsis'
import DropdownTooltip from '../../../../../common/textEllipsis/DropdownTooltip'
import { Z_INDEX } from '../../../../../constants/Common'
import style from '../../common/tableStatistics/index.module.css'

export const TdSelect = ({
  val,
  rowId,
  onRowCollapse,
  data,
  isRowCollapse,
  listCheckedItems,
}) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const containerRef = useRef()

  const [isCollapse, setIsCollapse] = useState(false)
  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const itemWithCollapse = data?.map((item) => ({
    ...item,
    isCollapsible: item.isGroupRow,
  }))

  const dataTableItem = itemWithCollapse?.find((item) => item.id === rowId)

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listCheckedItems?.some((item) => item.id === rowId)
  }

  const handleDisableCheckbox = () => {}

  const handleOpenTooltip = () => setIsOpenTooltip(true)

  const handleCloseTooltip = () => setIsOpenTooltip(false)

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <input
        type="checkbox"
        className={`checkbox ${style.checkbox}`}
        onChange={handleChangeListCategory}
        checked={handleChecked()}
        disabled={handleDisableCheckbox()}
      />
      <div
        className="d-flex"
        style={{
          marginLeft: !dataTableItem?.isCollapsible ? MARGIN_LEFT : 0,
        }}
      >
        {dataTableItem?.isCollapsible ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <div style={{ marginLeft: MARGIN_LEFT }}></div>
        )}
        <div
          onMouseOver={handleOpenTooltip}
          onMouseOut={handleCloseTooltip}
          ref={containerRef}
          className={dataTableItem?.isCollapsible ? style.pointer : ''}
        >
          <TextEllipsis isI18n={false} text={val} />
        </div>
        {dataTableItem && dataTableItem?.fullName.length ? (
          isOpenTooltip && (
            <DropdownTooltip
              containerRef={containerRef}
              isOpenTooltip={isOpenTooltip}
              zIndexTooltip={Z_INDEX.MODAL + 1}
              isI18n={false}
              text={dataTableItem?.fullName}
            />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
