import { createSlice, current } from '@reduxjs/toolkit'
import moment from 'moment'
import { defaultTimeZone } from '../../../../../configs/TimeZone'
import {
  handleDataRealtime,
  pushDataRealtime,
} from '../../../../common/chart/helper'
import { getIdsFromProps } from '../../../../common/table/helper'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot, valDivMillion } from '../../../../utils/Value'
import {
  listTab,
  listTabIndex,
  listTabProspect,
  tableDataTypes,
} from '../constans'
import { valueTimeRange } from '../marketWatch/liquidlity/TimeRange'
import {
  getAllIndexThunk,
  getBuSdThunk,
  getCommoditiesThunk,
  getContributionThunk,
  getForeignTradingOneDayThunk,
  getIndexEODThunk,
  getIndexIntradayThunk,
  getIndexLiquiditySeriesThunk,
  getIndexSeriesThunk,
  getIndexThunk,
  getSectorThunk,
  getWorldIndexThunk,
} from './thunk'

export const keys = {
  COMMODITIES: 'commodities',
  WORLD_INDEX: 'worldIndex',
  PROSPECT: 'prospect',
  INDEX: 'index',
  SECTOR: 'sector',
  INDEX_SERIES: 'indexSeries',
  INDEX_LIQUIDITY_SERIES: 'indexLiquiditySeries',
  BU_SD: 'buSd',
  CONTRIBUTION: 'contribution',
  FOREIGN_TRADING: 'foreignTrading',
  TABLE_INTRADAY: 'tableIntraday',
}

const initialState = {
  loading: {
    [keys.COMMODITIES]: true,
    [keys.WORLD_INDEX]: true,
    [keys.INDEX]: true,
    [keys.SECTOR]: true,
    [keys.INDEX_SERIES]: true,
    [keys.INDEX_LIQUIDITY_SERIES]: true,
    [keys.BU_SD]: true,
    [keys.CONTRIBUTION]: true,
    [keys.FOREIGN_TRADING]: true,
    [keys.TABLE_INTRADAY]: false,
  },
  activeTab: {
    [keys.PROSPECT]: listTabProspect[0].value,
    [keys.INDEX]: listTabIndex[0].value,
  },
  dataChart: {
    timeRange: TIME_RANGES['1D'],
    commoditiesId: [],
    commoditiesById: {},
    initialCommoditiesId: [],
    worldIndexId: [],
    worldIndexById: {},
    initialWorldIndexId: [],
    indexId: [],
    indexById: {},
    initialIndexId: [],
    sectorId: [],
    sectorById: {},
    initialSectorId: [],
    indexSeries: [],
    indexSeriesOneDay: [],
    indexLiquiditySeries: [],
    foreignTradingOneDay: [],
    allIndex: [],
    buSd: [],
    groupIdBuSd: 0,
    contribution: {},
    groupIdContribution: 0,
    contributionTabActive: listTab[0].value,
  },
  dataTable: {
    filter: {
      dataType: tableDataTypes.REALTIME,
      timeRange: TIME_RANGES.OneTick,
      dateFrom: '',
      dateTo: '',
      dateRealTime: '',
      sortField: 'tradingDate',
      sortOrder: 1,
      page: 1,
      pageSize: 50,
      totalPage: 1,
    },
    ids: [],
    data: [],
    dataById: {},
    initialIds: [],
    isFirstTime: true,
    isPushDataRealTime: true,
    scrollId: '',
    reCalcWidth: false,
    timeZone: defaultTimeZone.offset,
  },
}

export const slice = createSlice({
  name: 'market/marketInDepth/indices',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    // change active tab
    changeActiveTab(state, action) {
      const { key, value } = action.payload
      state.activeTab[key] = value
    },
    // commodities table
    changeCommoditiesId(state, action) {
      state.dataChart.commoditiesId = action.payload
    },
    // commodities table
    changeWorldIndexId(state, action) {
      state.dataChart.worldIndexId = action.payload
    },
    // index table
    changeIndexId(state, action) {
      state.dataChart.indexId = action.payload
    },
    subscribeIndex(state, action) {
      const index = action.payload[0]
      state.dataChart.indexById[index.groupId].closePrice = index.matchPrice
      state.dataChart.indexById[index.groupId].priceChange = index.priceChange
      state.dataChart.indexById[index.groupId].percentPriceChange =
        index.percentPriceChange
      state.dataChart.indexById[index.groupId].totalValue =
        +index.totalMatchValue + +index.totalDealValue
      state.dataChart.indexById[index.groupId].totalVolume =
        +index.totalMatchVolume + +index.totalDealVolume
    },
    changeSectorId(state, action) {
      state.dataChart.indexId = action.payload
    },
    subscribeSector(state, action) {
      const sector = action.payload[0]
      const id = sector.icbId
      state.dataChart.sectorById[id].closePrice = sector.closeIndex
      state.dataChart.sectorById[id].priceChange = sector.indexChange
      state.dataChart.sectorById[id].percentPriceChange =
        sector.percentIndexChange
      state.dataChart.sectorById[id].totalValue = sector.totalValue
      state.dataChart.sectorById[id].totalVolume = sector.totalVolume
    },
    subscribeIndexSeries(state, action) {
      const newData = action.payload[0]
      const newIndexSeries = pushDataRealtime(
        60 * 1000,
        state.dataChart.indexSeriesOneDay,
        {
          ...newData,
          openIndex: newData.openPrice,
          indexValue: newData.closeIndex,
          comGroupCode: newData.index,
          totalValue: +newData.totalMatchValue + +newData.totalDealValue,
          totalVolume: +newData.totalMatchVolume + +newData.totalDealVolume,
        },
        'tradingDate',
        null,
        true,
        ['matchVolume'],
      )
      const newForeignTrading = pushDataRealtime(
        60 * 1000,
        state.dataChart.foreignTradingOneDay,
        {
          ...newData,
          openIndex: newData.openPrice,
          indexValue: newData.closeIndex,
          comGroupCode: newData.index,
          totalValue: +newData.totalMatchValue + +newData.totalDealValue,
          totalVolume: +newData.totalMatchVolume + +newData.totalDealVolume,
        },
        'tradingDate',
        null,
        true,
        ['matchVolume'],
      )
      if (state.dataChart.timeRange === TIME_RANGES['1D']) {
        state.dataChart.indexSeries = newIndexSeries
        state.dataChart.indexSeriesOneDay = newIndexSeries
      } else {
        state.dataChart.indexSeriesOneDay = newIndexSeries
      }
      state.dataChart.foreignTradingOneDay = newForeignTrading
    },
    subscribeIndexIntraday: (state, action) => {
      const { data, filter, isPushDataRealTime, timeZone } = state.dataTable
      const dataTable = current(data)
      if (
        isPushDataRealTime &&
        !filter.dateRealTime &&
        Array.isArray(action.payload) &&
        action.payload.length > 0
      ) {
        const newData = action.payload[0]
        if (newData.matchVolume && newData.matchVolume > 0) {
          const newDataRealTime = {
            id: dataTable[0]?.id - 1 || 0,
            tradingDate: moment(newData.tradingDate)
              .subtract(Number(timeZone.slice(1, 3)), 'hour')
              .subtract(Number(timeZone.slice(4, 6)), 'minute')
              .format('YYYY-MM-DD[T]hh:mm:ss[Z]'),
            closeIndex: Number(newData.closeIndex),
            percentIndexChange: Number(newData.percentPriceChange),
            matchVolume: Number(newData.matchVolume),
            totalMatchVolume: Number(newData.totalMatchVolume),
          }
          const currentData = [...dataTable]
          const latestDate = currentData[0]?.tradingDate
          let newDataTable = [...currentData]

          if (filter.timeRange === TIME_RANGES.OneMinute) {
            if (
              latestDate &&
              moment(latestDate).format('hh:mm') ===
                moment(newDataRealTime.tradingDate).format('hh:mm')
            ) {
              currentData[0] = {
                ...currentData[0],
                closeIndex: newDataRealTime.closeIndex,
                percentIndexChange: newDataRealTime.percentIndexChange,
                matchVolume: newDataRealTime.matchVolume,
                totalMatchVolume: newDataRealTime.totalMatchVolume,
              }
              newDataTable = [...currentData]
            } else {
              newDataTable = [newDataRealTime, ...currentData]
            }
          } else if (filter.timeRange === TIME_RANGES.FiveMinutes) {
            if (
              latestDate &&
              Math.ceil(moment(latestDate).minute() / 5) ===
                Math.ceil(moment(newDataRealTime.tradingDate).minute() / 5)
            ) {
              currentData[0] = {
                ...currentData[0],
                tradingDate: newDataRealTime.tradingDate,
                closeIndex: newDataRealTime.closeIndex,
                percentIndexChange: newDataRealTime.percentIndexChange,
                matchVolume: newDataRealTime.matchVolume,
                totalMatchVolume: newDataRealTime.totalMatchVolume,
              }
              newDataTable = [...currentData]
            } else {
              newDataTable = [newDataRealTime, ...currentData]
            }
          } else {
            if (
              newDataTable.matchVolume !== newDataRealTime.matchVolume ||
              newDataTable.totalMatchVolume !== newDataRealTime.totalMatchVolume
            ) {
              newDataTable = [newDataRealTime, ...currentData]
            }
          }

          if (newDataTable.length >= filter.pageSize) {
            newDataTable = newDataTable.slice(0, filter.pageSize)
          }

          newDataTable = newDataTable.map((item, index) => ({
            ...item,
            id: index,
          }))
          state.dataTable.data = newDataTable
          state.dataTable.dataById = keyBy(newDataTable, 'id')
          state.dataTable.ids = state.dataTable.initialIds = newDataTable.map(
            (item) => item.id,
          )
          // if (state.dataTable.data.length < state.dataTable.filter.pageSize) {
          //   state.dataTable.ids = [data.id, ...state.dataTable.ids]
          //   state.dataTable.initialIds = state.dataTable.ids
          // }
        }
      }
    },
    changeTimeRange(state, action) {
      state.dataChart.timeRange = action.payload
    },
    sortCommodities: (state, action) => {
      state.dataChart.commoditiesId = getIdsFromProps(
        state.dataChart.commoditiesId,
        state.dataChart.commoditiesById,
        action.payload,
        state.dataChart.initialCommoditiesId,
      )
    },
    sortWorldIndex: (state, action) => {
      state.dataChart.worldIndexId = getIdsFromProps(
        state.dataChart.worldIndexId,
        state.dataChart.worldIndexById,
        action.payload,
        state.dataChart.initialWorldIndexId,
      )
    },
    sortIndex: (state, action) => {
      state.dataChart.indexId = getIdsFromProps(
        state.dataChart.indexId,
        state.dataChart.indexById,
        action.payload,
        state.dataChart.initialIndexId,
      )
    },
    sortSector: (state, action) => {
      state.dataChart.sectorId = getIdsFromProps(
        state.dataChart.sectorId,
        state.dataChart.sectorById,
        action.payload,
        state.dataChart.initialSectorId,
      )
    },
    sortTableData: (state, action) => {
      state.dataTable.ids = getIdsFromProps(
        state.dataTable.ids,
        state.dataTable.dataById,
        action.payload,
        state.dataTable.initialIds,
      )
    },
    handleContributionTabActive: (state, action) => {
      state.dataChart.contributionTabActive = action.payload
    },
    changeTableFilter: (state, action) => {
      state.dataTable.filter = { ...state.dataTable.filter, ...action.payload }
    },
    changeTableDataId(state, action) {
      state.dataTable.ids = action.payload
    },
    changeTablePageSize: (state, action) => {
      state.dataTable.filter.pageSize = action.payload
    },
    changeTableStateWhenScrollToTop: (state) => {
      state.dataTable.scrollId = null
      state.dataTable.isPushDataRealTime = true
    },
    changeTableStateWhenScrollToBottom: (state) => {
      state.dataTable.isPushDataRealTime = false
    },
    changeTableReCalcWidths: (state) => {
      state.dataTable.reCalcWidth = !state.dataTable.reCalcWidth
    },
    resetTableState: (state) => {
      state.dataTable.ids = []
      state.dataTable.initialIds = []
      state.dataTable.data = []
      state.dataTable.dataById = {}
      state.loading[keys.TABLE_INTRADAY] = true
      state.dataTable.scrollId = null
      state.dataTable.isPushDataRealTime = true
      state.dataTable.isFirstTime = true
      state.dataTable.filter = {
        ...state.dataTable.filter,
        sortField: 'tradingDate',
        sortOrder: 1,
        page: 1,
      }
    },
    changeTimeZone: (state, action) => {
      state.timeZone = action.payload
    },
  },

  extraReducers: (builder) => {
    //get commodities
    builder.addCase(getCommoditiesThunk.pending, (state) => {
      state.dataChart.commoditiesById = initialState.dataChart.commoditiesById
      state.dataChart.commoditiesId = initialState.dataChart.commoditiesId
      state.loading[keys.COMMODITIES] = true
    })
    builder.addCase(getCommoditiesThunk.fulfilled, (state, action) => {
      state.dataChart.commoditiesById = keyBy(action.payload, 'commodityName')
      state.dataChart.commoditiesId = action.payload.map(
        (item) => item.commodityName,
      )
      state.dataChart.initialCommoditiesId = state.dataChart.commoditiesId
      state.loading[keys.COMMODITIES] = false
    })
    builder.addCase(getCommoditiesThunk.rejected, (state) => {
      state.loading[keys.COMMODITIES] = false
    })
    //get world index
    builder.addCase(getWorldIndexThunk.pending, (state) => {
      state.dataChart.worldIndexById = initialState.dataChart.worldIndexById
      state.dataChart.worldIndexId = initialState.dataChart.worldIndexId
      state.loading[keys.WORLD_INDEX] = true
    })
    builder.addCase(getWorldIndexThunk.fulfilled, (state, action) => {
      state.dataChart.worldIndexById = keyBy(action.payload, 'globalIndexName')
      state.dataChart.worldIndexId = action.payload.map(
        (item) => item.globalIndexName,
      )
      state.dataChart.initialWorldIndexId = state.dataChart.worldIndexId
      state.loading[keys.WORLD_INDEX] = false
    })
    builder.addCase(getWorldIndexThunk.rejected, (state) => {
      state.loading[keys.WORLD_INDEX] = false
    })
    // get all index
    builder.addCase(getAllIndexThunk.pending, (state) => {
      state.loading[keys.INDEX] = true
    })
    builder.addCase(getAllIndexThunk.fulfilled, (state, action) => {
      state.dataChart.allIndex = action.payload
      state.loading[keys.INDEX] = false
    })
    builder.addCase(getAllIndexThunk.rejected, (state) => {
      state.loading[keys.INDEX] = false
    })
    // get index
    builder.addCase(getIndexThunk.pending, (state) => {
      state.dataChart.indexById = initialState.dataChart.indexById
      state.dataChart.indexId = initialState.dataChart.indexId
      state.loading[keys.INDEX] = true
    })
    builder.addCase(getIndexThunk.fulfilled, (state, action) => {
      state.dataChart.indexById = keyBy(action.payload, 'id')
      state.dataChart.indexId = action.payload.map((item) => item.id)
      state.dataChart.initialIndexId = state.dataChart.indexId
      state.loading[keys.INDEX] = false
    })
    builder.addCase(getIndexThunk.rejected, (state) => {
      state.loading[keys.INDEX] = false
    })
    // get sector
    builder.addCase(getSectorThunk.pending, (state) => {
      state.dataChart.sectorById = initialState.dataChart.sectorById
      state.dataChart.sectorId = initialState.dataChart.sectorId
      state.loading[keys.SECTOR] = true
    })
    builder.addCase(getSectorThunk.fulfilled, (state, action) => {
      state.dataChart.sectorById = keyBy(action.payload, 'id')
      state.dataChart.sectorId = action.payload.map((item) => item.id)
      state.dataChart.initialSectorId = state.dataChart.sectorId
      state.loading[keys.SECTOR] = false
    })
    builder.addCase(getSectorThunk.rejected, (state) => {
      state.loading[keys.SECTOR] = false
    })
    // get index series
    builder.addCase(getIndexSeriesThunk.pending, (state, action) => {
      state.loading[keys.INDEX_SERIES] = true
      const timeRange = action.meta.arg.TimeRange
      if (timeRange === valueTimeRange[TIME_RANGES['1D']]) {
        state.dataChart.indexSeriesOneDay =
          initialState.dataChart.indexSeriesOneDay
      }
      state.dataChart.indexSeries = initialState.dataChart.indexSeries
    })
    builder.addCase(getIndexSeriesThunk.fulfilled, (state, action) => {
      const timeRange = action.meta.arg.TimeRange
      if (timeRange === valueTimeRange[TIME_RANGES['1D']]) {
        const dataIndexSeries = handleDataRealtime(
          60 * 1000,
          action.payload,
          'tradingDate',
        )

        state.dataChart.indexSeries = dataIndexSeries
        state.dataChart.indexSeriesOneDay = dataIndexSeries
      } else {
        state.dataChart.indexSeries = action.payload
      }
      state.loading[keys.INDEX_SERIES] = false
    })
    builder.addCase(getIndexSeriesThunk.rejected, (state, action) => {
      state.loading[keys.INDEX_SERIES] = action.payload
    })
    // get index liquidity series
    builder.addCase(getIndexLiquiditySeriesThunk.pending, (state) => {
      state.dataChart.indexLiquiditySeries =
        initialState.dataChart.indexLiquiditySeries
      state.loading[keys.INDEX_LIQUIDITY_SERIES] = true
    })
    builder.addCase(getIndexLiquiditySeriesThunk.fulfilled, (state, action) => {
      state.dataChart.indexLiquiditySeries = handleDataRealtime(
        60 * 1000,
        action.payload,
        'tradingDate',
      )
      state.loading[keys.INDEX_LIQUIDITY_SERIES] = false
    })
    builder.addCase(getIndexLiquiditySeriesThunk.rejected, (state, action) => {
      state.loading[keys.INDEX_LIQUIDITY_SERIES] = action.payload
    })
    //get bu sd
    builder.addCase(getBuSdThunk.pending, (state, action) => {
      if (
        state.dataChart.timeRange !== TIME_RANGES['1D'] ||
        action.meta.arg.GroupId !== state.dataChart.groupIdBuSd
      ) {
        state.loading[keys.BU_SD] = true
      }
    })
    builder.addCase(getBuSdThunk.fulfilled, (state, action) => {
      const dataHandle = action.payload.map((item) => {
        return {
          ...item,
          buyUpVolume: valDivMillion(item.buyUpVolume),
          sellDownVolume: valDivMillion(item.sellDownVolume),
        }
      })
      state.dataChart.groupIdBuSd = action.meta.arg.GroupId
      if (action.meta.arg.TimeRange === valueTimeRange[TIME_RANGES['1D']]) {
        const dataBuSd = handleDataRealtime(
          15 * 60 * 1000,
          dataHandle,
          'tradingDate',
        )
        state.dataChart.buSd = dataBuSd
      } else {
        state.dataChart.buSd = dataHandle
      }
      state.loading[keys.BU_SD] = false
    })
    builder.addCase(getBuSdThunk.rejected, (state, action) => {
      state.loading[keys.BU_SD] = action.payload
    })
    // get foreign trading
    builder.addCase(getForeignTradingOneDayThunk.pending, (state, action) => {
      state.loading[keys.FOREIGN_TRADING] = true
      const timeRange = action.meta.arg.TimeRange
      if (timeRange === valueTimeRange[TIME_RANGES['1D']]) {
        state.dataChart.foreignTradingOneDay =
          initialState.dataChart.foreignTradingOneDay
      }
    })
    builder.addCase(getForeignTradingOneDayThunk.fulfilled, (state, action) => {
      const timeRange = action.meta.arg.TimeRange
      if (timeRange === valueTimeRange[TIME_RANGES['1D']]) {
        const dataIndexSeries = handleDataRealtime(
          60 * 1000,
          action.payload,
          'tradingDate',
        )

        state.dataChart.foreignTradingOneDay = dataIndexSeries
      }
      state.loading[keys.FOREIGN_TRADING] = false
    })
    builder.addCase(getForeignTradingOneDayThunk.rejected, (state, action) => {
      state.loading[keys.FOREIGN_TRADING] = action.payload
    })
    // get contribution
    builder.addCase(getContributionThunk.pending, (state, action) => {
      if (action.meta.arg.GroupId !== state.dataChart.groupIdContribution) {
        state.loading[keys.CONTRIBUTION] = true
      }
    })
    builder.addCase(getContributionThunk.fulfilled, (state, action) => {
      state.dataChart.groupIdContribution = action.meta.arg.GroupId
      state.dataChart.contribution = action.payload
      state.loading[keys.CONTRIBUTION] = false
    })
    builder.addCase(getContributionThunk.rejected, (state) => {
      state.loading[keys.CONTRIBUTION] = false
    })
    // get table data realtime
    builder.addCase(getIndexIntradayThunk.pending, (state) => {
      if (state.dataTable.isFirstTime) state.loading[keys.TABLE_INTRADAY] = true
    })
    builder.addCase(getIndexIntradayThunk.fulfilled, (state, action) => {
      const { data, scrollId } = action.payload
      const { data: dataTable } = state.dataTable
      const lastLength = action.meta.arg.ScrollId ? dataTable.length : 0
      const newData = [...data]
        .sort(
          (a, b) =>
            new Date(b.tradingDate).getTime() -
            new Date(a.tradingDate).getTime(),
        )
        .map((item, index) => ({
          ...item,
          id: index + lastLength,
        }))
      let newDataTable = [...newData]
      if (action.meta.arg.ScrollId && !state.dataTable.filter.dateRealTime) {
        newDataTable = [...dataTable, ...newData]
      }
      state.dataTable.data = newDataTable
      state.dataTable.ids = newDataTable.map((v) => v.id)
      state.dataTable.initialIds = state.dataTable.ids
      state.dataTable.dataById = keyBy(newDataTable, 'id')
      if (
        action.meta.arg.TimeBucket === TIME_RANGES.OneTick &&
        data.length > 0
      ) {
        state.dataTable.scrollId = scrollId
      } else {
        state.dataTable.scrollId = null
      }
      if (state.dataTable.isFirstTime) {
        state.dataTable.isFirstTime = false
      }
      state.loading[keys.TABLE_INTRADAY] = false
    })
    builder.addCase(getIndexIntradayThunk.rejected, (state, action) => {
      state.loading[keys.TABLE_INTRADAY] = action.payload.loading
    })
    // get table data eod
    builder.addCase(getIndexEODThunk.pending, (state) => {
      state.loading[keys.TABLE_INTRADAY] = true
    })
    builder.addCase(getIndexEODThunk.fulfilled, (state, action) => {
      const data = action.payload.data.map((item, index) => ({
        ...item,
        id: index,
      }))
      state.dataTable.data = data
      state.dataTable.ids = data.map((e) => e.id)
      state.dataTable.initialIds = state.dataTable.ids
      state.dataTable.dataById = keyBy(data, 'id')
      state.dataTable.filter.totalPage =
        action.payload.totalPage || action.payload.totalRecords
          ? Math.ceil(
              action.payload.totalRecords / state.dataTable.filter.pageSize,
            )
          : 0
      state.loading[keys.TABLE_INTRADAY] = false
    })
    builder.addCase(getIndexEODThunk.rejected, (state, action) => {
      state.loading[keys.TABLE_INTRADAY] = action.payload.loading
    })
  },
})

export const selectLoading = (key) => (state) => state[slice.name].loading[key]
export const selectActiveTab = (key) => (state) =>
  state[slice.name].activeTab[key]
export const selectCommoditiesId = (state) =>
  state[slice.name].dataChart.commoditiesId
export const selectCommoditiesCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataChart.commoditiesById[id], attr)
export const selectWorldIndexId = (state) =>
  state[slice.name].dataChart.worldIndexId
export const selectWorldIndexCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataChart.worldIndexById[id], attr)
export const selectIndexId = (state) => state[slice.name].dataChart.indexId
export const selectIndexCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataChart.indexById[id], attr)
export const selectSectorId = (state) => state[slice.name].dataChart.sectorId
export const selectSectorCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataChart.sectorById[id], attr)
export const selectIndexSeries = (state) =>
  state[slice.name].dataChart.indexSeries
export const selectIndexSeriesOneDay = (state) =>
  state[slice.name].dataChart.indexSeriesOneDay
export const selectIndexLiquiditySeries = (state) =>
  state[slice.name].dataChart.indexLiquiditySeries
export const selectForeignTradingOneDay = (state) =>
  state[slice.name].dataChart.foreignTradingOneDay
export const selectChartTimeRange = (state) =>
  state[slice.name].dataChart.timeRange
export const selectAllIndex = (state) => state[slice.name].dataChart.allIndex
export const selectLocale = (state) => state.i18n.locale
export const selectBuSd = (state) => state[slice.name].dataChart.buSd
export const selectContribution = (state) =>
  state[slice.name].dataChart.contribution
export const selectContributionTabActive = (state) =>
  state[slice.name].dataChart.contributionTabActive
export const selectTableFilter = (state) => state[slice.name].dataTable.filter
export const selectTableIsPushDataRealTime = (state) =>
  state[slice.name].dataTable.isPushDataRealTime
export const selectTableScrollId = (state) =>
  state[slice.name].dataTable.scrollId
export const selectTableData = (state) => state[slice.name].dataTable.data
export const selectTableDataId = (state) => state[slice.name].dataTable.ids
export const selectTableDataCell = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataTable.dataById[id], attr)
export const selectTableDataReCalcWidth = (state) =>
  state[slice.name].dataTable.reCalcWidth

export const {
  resetStore,
  changeIndexIds,
  changeCommoditiesId,
  changeWorldIndexId,
  changeIndexId,
  changeActiveTab,
  subscribeIndex,
  changeSectorId,
  subscribeSector,
  subscribeIndexSeries,
  changeTimeRange,
  sortCommodities,
  sortWorldIndex,
  sortIndex,
  sortSector,
  handleContributionTabActive,
  changeTableFilter,
  sortTableData,
  changeTableDataId,
  subscribeIndexIntraday,
  changeTablePageSize,
  changeTableStateWhenScrollToTop,
  changeTableStateWhenScrollToBottom,
  changeTableReCalcWidths,
  resetTableState,
  changeTimeZone,
} = slice.actions

register(slice.name, slice.reducer)
