import React from 'react'
import { useSelector } from 'react-redux'
import { Area, Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import {
  AREA_KEY_WITH_COLOR,
  CHART_FOOTER,
  DEPOSIT_ID,
  LINE_KEY_WITH_COLOR,
  LOAN_ID,
  MIN_MAX_LINE_COLOR,
} from './config'
import {
  getTooltipContentData,
  handleXAxisTitle,
  renderListFooter,
} from './helper'
import style from './index.module.css'
import { getKeyWithTitle } from './store/slice'

const YAXIS_LEFT = 'left'
const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeWidthBorder: 0.5,
  areaOpacity: 0.15,
}

const LineChart = ({ data, width, height, keyXAxis, locale, timeRange }) => {
  const dataFormatWithMonthYear = data.map((item) => ({
    ...item,
    date: `${handleXAxisTitle(item.month, locale)}-${item.year}`,
  }))

  const keyWithTitle = useSelector(getKeyWithTitle)

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, keyWithTitle).map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 10,
              }}
            >
              <Span style={{ fontSize: 11 }}>{item.label}:</Span>
              <Span
                style={{ marginLeft: 25, fontSize: 11 }}
                className={`${style.toolTipContentValue} ${
                  typeof item.value === 'number' &&
                  item.value < 0 &&
                  style.redColor
                }`}
              >
                {formatVal(item.value)}
                {typeof item.value === 'number' && '%'}
              </Span>
            </div>
          )
        })}
      </>
    )
  }

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: [...Object.keys(LINE_KEY_WITH_COLOR), ...DEPOSIT_ID, ...LOAN_ID],
      orientation: YAXIS_LEFT,
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: '%',
    },
  ]

  return (
    <SizeTracker className={style.heightAuto}>
      {(sizes) => {
        return (
          <>
            {typeof sizes.height === 'number' && (
              <ChartContainer
                data={dataFormatWithMonthYear}
                height={height - sizes.height}
                width={width}
                keyXAxis={keyXAxis}
                yAxis={keyYAxis}
                renderCustomTooltip={renderTooltip}
                margin={{ ...MARGIN_RECHARTS, left: 12, right: 46 }}
                timeFrame={
                  timeRange !== 'SixMonths' && timeRange !== 'OneYear'
                    ? TIME_RANGES.CUSTOM
                    : ''
                }
                isNotFormatXAxis
              >
                {Object.keys(AREA_KEY_WITH_COLOR).map((item, index) => {
                  return (
                    <React.Fragment key={item}>
                      <Area
                        yAxisId={YAXIS_LEFT}
                        dataKey={item}
                        stroke="#8884d8"
                        strokeWidth={0}
                        fill={AREA_KEY_WITH_COLOR[item]}
                        isAnimationActive={false}
                        dot={false}
                        opacity={SETTINGS.areaOpacity}
                        activeDot={false}
                        connectNulls={true}
                      />

                      <Line
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        type="linear"
                        dataKey={
                          item === 'rangeDeposit' ? DEPOSIT_ID[0] : LOAN_ID[0]
                        }
                        strokeWidth={SETTINGS.strokeWidthBorder}
                        stroke={MIN_MAX_LINE_COLOR[index]}
                        dot={false}
                        activeDot={false}
                        connectNulls={true}
                      />
                      <Line
                        isAnimationActive={false}
                        yAxisId={YAXIS_LEFT}
                        type="linear"
                        dataKey={
                          item === 'rangeDeposit' ? DEPOSIT_ID[1] : LOAN_ID[1]
                        }
                        strokeWidth={SETTINGS.strokeWidthBorder}
                        stroke={MIN_MAX_LINE_COLOR[index]}
                        dot={false}
                        activeDot={false}
                        connectNulls={true}
                      />
                    </React.Fragment>
                  )
                })}

                {Object.keys(LINE_KEY_WITH_COLOR).map((item) => {
                  return (
                    <Line
                      key={item}
                      isAnimationActive={false}
                      yAxisId={YAXIS_LEFT}
                      dataKey={item}
                      stroke={LINE_KEY_WITH_COLOR[item]}
                      dot={false}
                      activeDot={false}
                      strokeWidth={SETTINGS.strokeWidth}
                      connectNulls={true}
                    />
                  )
                })}
              </ChartContainer>
            )}
            <FooterContainer
              key={width}
              data={renderListFooter(CHART_FOOTER, locale)}
              numRow={2}
              numItemPerRow={2}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default LineChart
