import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { LASTEST_YEARS, QUARTERS } from '../common/constant'
import { BANK_TAB_OPTION } from './constant'
import style from './index.module.css'
import {
  changeBankTab,
  changeQuarter,
  changeYear,
  selectBankTab,
  selectQuarter,
  selectYear,
} from './store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const bankTab = useSelector(selectBankTab)
  const year = useSelector(selectYear)
  const quarter = useSelector(selectQuarter)

  const handleChangeTab = (tab) => dispatch(changeBankTab(tab.value))
  const handleChangeYear = (year) => dispatch(changeYear(year))
  const handleChangeQuarter = (quarter) => dispatch(changeQuarter(quarter))

  return (
    <div className="d-flex">
      <DispatchActionTab
        data={BANK_TAB_OPTION}
        activeTab={bankTab}
        onChangeTab={handleChangeTab}
        itemStyle={{
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: 11,
        }}
      />

      <div className={style.verticalLine} />

      <div className={style.select}>
        <SelectCustom
          isI18n={false}
          selectData={LASTEST_YEARS}
          value={year}
          handleChange={handleChangeYear}
        />
      </div>
      <div className={style.select}>
        <SelectCustom
          isI18n={true}
          selectData={QUARTERS}
          value={quarter}
          handleChange={handleChangeQuarter}
        />
      </div>
    </div>
  )
}

export default Filter
