import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupSaveTemplate from '../../../../common/dataExplorer/PopupSaveTemplate'
import {
  DEFAULT_PARAM_TEMPLATE,
  typeData,
} from '../../../../common/dataExplorer/constant'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { MAX_TEMPLATE, VIEW_BY } from '../constant'
import {
  resetData,
  selectFilter,
  selectListCheckedIndicatorsById,
  selectListTickersById,
  selectMostRecentSettingDate,
  selectTemplateId,
  selectTemplates,
  selectViewBy,
} from '../store/slice'
import { saveTemplateThunk, updateTemplateThunk } from '../store/thunk'
import { getHeaderExportExcel, getParamsQuery } from './helps'
import { resetDefault, selectLoading } from './store/slice'
import { downloadExportExcel } from './store/thunk'
import style from './style.module.css'

const GroupButton = ({ onLoadData, setIsLoadingDownloadCsv }) => {
  const dispatch = useDispatch()
  const timezone = UseTimeZone()

  // Use selector
  const viewBy = useSelector(selectViewBy)
  const tickersById = useSelector(selectListTickersById(viewBy))
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const templates = useSelector(selectTemplates)
  const filter = useSelector(selectFilter)
  const loading = useSelector(selectLoading)
  const mostRecentDate = useSelector(selectMostRecentSettingDate)
  const templateId = useSelector(selectTemplateId)
  const detailTemplate = templates.find(
    (template) => template.templateId === templateId,
  )

  // Use state
  const [isShowPopupSaveAs, setIsShowPopupSaveAs] = useState(false)

  // Get data
  const isDisableButton = useMemo(() => {
    const tickersCheck = Object.values(tickersById).filter(
      (item) => item.isCheck,
    )
    const indicatorsCheck = Object.values(checkedIndicatorsById).filter(
      (item) => item.isCheck,
    )
    return !tickersCheck.length || !indicatorsCheck.length || loading
  }, [filter, tickersById, checkedIndicatorsById])

  // Actions
  const handleShowSaveAs = () => {
    if (isDisableButton) return

    if (templates.length >= MAX_TEMPLATE) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_1" />,
        <Translate value="tool.dataExplorer.corporate.MAX_TEMPLATE_2" />,
      ])
    } else {
      setIsShowPopupSaveAs(true)
    }
  }

  const handleHideSaveAs = () => setIsShowPopupSaveAs(false)

  const handleSubmitSaveAs = (templateName) => {
    if (isDisableButton) return

    const newTickerById = Object.values(tickersById).reduce((obj, item) => {
      let data = {
        actionIndex: item.actionIndex,
        index: item.index,
        isCheck: item.isCheck,
      }

      if (item.organizationId) {
        data = { ...data, organizationId: item.organizationId }
      } else if (item.groupId) {
        data = { ...data, groupId: item.groupId }
      } else if (item.icbId) {
        data = { ...data, icbId: item.icbId }
      }

      return {
        ...obj,
        [item.index]: data,
      }
    }, {})

    const newCheckedIndicatorsById = Object.values(
      checkedIndicatorsById,
    ).reduce(
      (obj, item) => ({
        ...obj,
        [item.index]: {
          indicatorId: item.indicatorId,
          actionIndex: item.actionIndex,
          index: item.index,
          isCheck: item.isCheck,
        },
      }),
      {},
    )

    const param = {
      templateName,
      dataType: typeData.TRADING_DATA,
      parameter: {
        header: [],
        parameter: DEFAULT_PARAM_TEMPLATE,
        rawParameter: {
          viewBy,
          filter,
          tickersById: newTickerById,
          checkedIndicatorsById: newCheckedIndicatorsById,
        },
      },
    }

    dispatch(saveTemplateThunk(param))
    handleHideSaveAs()
  }

  const handleSave = () => {
    if (isDisableButton) return

    if (templateId && detailTemplate) {
      const newTickerById = Object.values(tickersById).reduce((obj, item) => {
        let data = {
          actionIndex: item.actionIndex,
          index: item.index,
          isCheck: item.isCheck,
        }

        if (item.organizationId) {
          data = { ...data, organizationId: item.organizationId }
        } else if (item.groupId) {
          data = { ...data, groupId: item.groupId }
        } else if (item.icbId) {
          data = { ...data, icbId: item.icbId }
        }

        return {
          ...obj,
          [item.index]: data,
        }
      }, {})
      const newCheckedIndicatorsById = Object.values(
        checkedIndicatorsById,
      ).reduce(
        (obj, item) => ({
          ...obj,
          [item.index]: {
            indicatorId: item.indicatorId,
            actionIndex: item.actionIndex,
            index: item.index,
            isCheck: item.isCheck,
          },
        }),
        {},
      )

      const param = {
        templateId,
        templateName: detailTemplate.templateName,
        dataType: typeData.TRADING_DATA,
        parameter: {
          header: [],
          parameter: DEFAULT_PARAM_TEMPLATE,
          rawParameter: {
            viewBy,
            filter,
            tickersById: newTickerById,
            checkedIndicatorsById: newCheckedIndicatorsById,
          },
        },
      }

      dispatch(updateTemplateThunk(param))
    }
  }

  const handleResetData = () => {
    if (!loading) {
      dispatch(resetData())
      dispatch(resetDefault(viewBy))
    }
  }

  const handleExportExcel = async () => {
    if (isDisableButton) return

    const tickersCheck = Object.values(tickersById).filter(
      (item) => item.isCheck,
    )
    const indicatorsCheck = Object.values(checkedIndicatorsById).filter(
      (item) => item.isCheck,
    )
    const params = {
      sheetName: 'Sheet 1',
      groupBy: filter.groupBy,
      dataType: viewBy === VIEW_BY.CORPORATE ? 'Corporate' : 'Index',
      multiType: filter.exportType,
      header: getHeaderExportExcel(tickersCheck, indicatorsCheck, filter),
      paramerters:
        getParamsQuery(
          tickersCheck,
          indicatorsCheck,
          filter,
          viewBy,
          timezone,
          mostRecentDate,
        ) || DEFAULT_PARAM_TEMPLATE,
    }

    setIsLoadingDownloadCsv(true)
    await dispatch(downloadExportExcel({ parameters: [params] }))
    setIsLoadingDownloadCsv(false)
  }

  return (
    <div className="d-flex justify-content-space-between mb-20">
      <div className="d-flex">
        <Button
          style={{ fontSize: 11, opacity: isDisableButton ? 0.5 : 1 }}
          className={`btn h-20 ${style.btnLoad}`}
          onClick={isDisableButton ? () => {} : onLoadData}
        >
          <Translate value="tool.dataExplorer.LOAD" />
        </Button>
        <Button
          style={{ fontSize: 11 }}
          className={`btn h-20 ${style.btnReset}`}
          onClick={handleResetData}
        >
          <Translate value="tool.dataExplorer.tradingData.RESET" />
        </Button>
      </div>
      <div className="d-flex">
        <Button
          style={{ fontSize: 12 }}
          className={`btn btn-blue w-100px h-20 ${style.btnSave}  ${
            isDisableButton ? style.disabled : ''
          }`}
          disabled={isDisableButton}
          onClick={handleShowSaveAs}
        >
          {templateId && detailTemplate ? (
            <Translate value="common.button.BUTTON_SAVE_AS" />
          ) : (
            <Translate value="common.button.BUTTON_SAVE" />
          )}
        </Button>
        {templateId && detailTemplate && (
          <Button
            style={{ fontSize: 11, marginLeft: 16 }}
            className={`btn btn-blue w-80 h-20 ${style.btnSave}  ${
              isDisableButton ? style.disabled : ''
            }`}
            disabled={isDisableButton}
            onClick={handleSave}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        )}
        <Button
          style={{ fontSize: 10, opacity: isDisableButton ? 0.5 : 1 }}
          className={`btn btn-blue h-20 btn-export ${style.btnExport}`}
          onClick={handleExportExcel}
        >
          <i className={`icon-print ${style.iconPrint}`} />
          <Translate value="tool.smartScreening.EXPORT" />
        </Button>
      </div>
      {isShowPopupSaveAs && (
        <PopupSaveTemplate
          templates={templates}
          handleHidePopupSaveAs={handleHideSaveAs}
          handleSubmit={handleSubmitSaveAs}
        />
      )}
    </div>
  )
}

export default GroupButton
