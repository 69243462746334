import _ from 'lodash'
import { ValueRelations } from '../filter/FilterOption/constants'

const regexDataTable = /MainSubject|ChairmanAndDirector/
const regexPercentage = /Owned|Affliate/

export const transformData = (data) => {
  const nodesMap = {}
  let ids = []
  function recursiveTransform(node, parent, parentID) {
    const nodeId = node?.id

    if (!nodesMap[nodeId]) {
      nodesMap[nodeId] = {
        ...node,
        id: nodeId,
        name: node?.name,
        relationType: node?.relationType,
        outstandingBond: node?.outstandingBond,
        percentage:
          parent?.id === parentID ||
          (parent?.id === parentID && regexPercentage.test(node.relationType))
            ? node?.percentage
            : null,
        posision: node?.posision,
        posisionId: node?.posisionId,
        parentId: parent?.id,
        parentRelationType: parent?.relationType,
        relationTypeMulti: node?.relationType,
        relations: node?.relations || [],
        totalValue: node?.totalValue,
        paramsRelationType: {
          [parent?.id || '0']: node?.relationType,
        },
      }
    } else {
      const existingNode = nodesMap[nodeId]

      if (node?.relations && node?.relations.length > 0) {
        existingNode.relations = node?.relations
      }
      if (
        parent?.id === parentID ||
        (parent?.id === parentID && regexPercentage.test(node.relationType))
      ) {
        existingNode.percentage = node?.percentage
      }

      existingNode.paramsRelationType = {
        ...existingNode.paramsRelationType,
        [parent?.id || '']: node?.relationType,
      }

      if (node?.totalValue) {
        existingNode.totalValue = node?.totalValue
      }

      if (
        existingNode?.parentRelationType &&
        !existingNode.parentRelationType.includes(node?.relationType)
      ) {
        existingNode.parentRelationType += `|${parent?.relationType || ''}`
      }
      if (
        existingNode?.relationTypeMulti &&
        !existingNode.relationTypeMulti.includes(node?.relationType)
      ) {
        existingNode.relationTypeMulti += `|${node?.relationType || ''}`
        existingNode.numberRelationType += 1
      }
      if (existingNode?.relationType !== node?.relationType) {
        existingNode.relationType2 = node?.relationType
      }
    }

    if (node.relations && node.relations.length > 0) {
      node.relations.forEach((childNode) => {
        recursiveTransform(childNode, node, parentID)
      })
    }
  }

  recursiveTransform(data, null, data.id)

  const nodes = Object.values(nodesMap)
    .filter(
      (e) => e.relationTypeMulti && !regexDataTable.test(e.relationTypeMulti),
    )
    .map((e) => {
      const check =
        e?.relations && e?.relations.length > 0
          ? e?.relations.some(
              (e) =>
                e.relationType &&
                e.relationType.includes(ValueRelations.PaymentGuarantee),
            )
          : false
      if (check) {
        return {
          ...e,
          [ROW_KEY.GUARANTEE]: _.sumBy(e?.relations, (e) => {
            if (
              e.relationType &&
              e.relationType.includes(ValueRelations.PaymentGuarantee)
            ) {
              return e?.totalValue || 0
            }
          }),
        }
      }
      return { ...e }
    })
  ids = nodes.map((e) => e.id)
  return {
    nodes,
    ids,
  }
}

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}
export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const ROW_KEY = {
  NAME: 'name',
  CHARTERCAPITAL: 'charterCapital',
  PERCENTAGE: 'percentage',
  ISSUEVALUE: 'outstandingBond',
  GUARANTEE: 'Guarantee',
}

export const Columns = [
  {
    key: ROW_KEY.NAME,
    title: 'bond.interconnectionMap.businessInformation.table.Name',
    attrs: {
      style: {},
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    key: ROW_KEY.CHARTERCAPITAL,
    title: 'bond.interconnectionMap.businessInformation.table.CharterCapital',
    additionText:
      'bond.interconnectionMap.businessInformation.table.unitBillMoney',
    attrs: {
      style: {
        textAlign: 'right',
      },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    key: ROW_KEY.PERCENTAGE,
    title: 'bond.interconnectionMap.businessInformation.table.Own',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    key: ROW_KEY.GUARANTEE,
    title: 'bond.interconnectionMap.businessInformation.table.Guarantee',
    additionText:
      'bond.interconnectionMap.businessInformation.table.unitBillMoney',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
  {
    key: ROW_KEY.ISSUEVALUE,
    title: 'bond.interconnectionMap.businessInformation.table.IssueValue',
    additionText:
      'bond.interconnectionMap.businessInformation.table.unitBillMoney',
    attrs: {
      style: { textAlign: 'right' },
      // styleHeader: { textTransform: 'uppercase' },
    },
  },
]

export const filterItems = (node, arrayfilter) => {
  const listNodes = [...node]
  const nodeHide = listNodes
    .map((node) => {
      let listIdnode = []
      const filterid = (conpany) => {
        listIdnode.push(conpany.id)
        if (conpany.relations && conpany.relations.length > 0) {
          conpany.relations.forEach((relation) => filterid(relation))
        }
      }
      filterid(node)

      const checkHide = listIdnode.some((e) => arrayfilter.includes(e))
      if (!checkHide) {
        return node.id
      }

      return null
    })
    .filter((e) => e)
  return nodeHide
}
