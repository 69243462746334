import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import SelectYearCustom from '../../common/SelectYearCustom'
import TopLabel from '../../common/TopLabel'
import useChartAPI from '../../common/useChartAPI'
import { BOTTOM_CHART_ID } from '../../panelChartBottom/PanelChartBottom'
import {
  selectIncomeStructure,
  selectIncomeStructureLoading,
} from '../../store/slice'
import PieChartVerticalFooter from './PieChartVerticalFooter'

const PieChart = ({ width, height, chartType }) => {
  const incomeStructureData = useSelector(selectIncomeStructure)
  const incomeStructureLoading = useSelector(selectIncomeStructureLoading)

  const [data, loading] = useChartAPI(chartType)

  return (
    <div className="h-100">
      {(loading || incomeStructureLoading) && <Loading />}
      {!loading && !incomeStructureLoading && (
        <>
          {(data?.length === 0 || incomeStructureData.length === 0) && (
            <NoData />
          )}
          {data?.length > 0 && incomeStructureData.length > 0 && (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <SelectYearCustom />
                    <div id={BOTTOM_CHART_ID}>
                      <TopLabel />
                      {(size.height || size.height === 0) && (
                        <div
                          style={{
                            position: 'relative',
                            height: height - size.height,
                          }}
                        >
                          <PieChartVerticalFooter
                            data={data}
                            height={height - size.height}
                            dataKey="value1"
                            dataName="ticker"
                            width={width}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )
              }}
            </SizeTracker>
          )}
        </>
      )}
    </div>
  )
}

PieChart.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default PieChart
