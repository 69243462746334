import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { TIME_FREQUENCY_TABS } from '../constants.js'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectFilterSectorStatistic,
} from '../store/slice.js'
import { getSectorStatistics } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const methodType = useSelector(selectActiveMethodType)
  const { timeRange, From, To } = useSelector(selectFilterSectorStatistic)

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.SECTOR_STATISTIC,
        key: 'timeRange',
        value: item.value,
      }),
    )
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.SECTOR_STATISTIC,
        key: 'timeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.SECTOR_STATISTIC,
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.SECTOR_STATISTIC,
        key: 'To',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload =
      timeRange === calendarTabKey
        ? {
            IssueMethodId: methodType,
            From,
            To,
          }
        : {
            IssueMethodId: methodType,
            TimeRange: timeRange,
          }

    dispatch(getSectorStatistics(payload))
  }, [locale, methodType, timeRange, From, To])

  return (
    <div className="d-flex justify-content-end ali-center mb-8">
      <DispatchActionTabWithCalendar
        id="sectorStatistic"
        tabs={TIME_FREQUENCY_TABS}
        activeTab={timeRange}
        startDate={From}
        endDate={To}
        datePreset={TIME_FREQUENCY_TABS}
        excludeDateIntervals={[]}
        onChangeTab={handleChangeTimeType}
        onChangeCalendar={handleChangeCalendar}
      />
    </div>
  )
}

export default Filter
