import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import FooterWithScroll from '../../../../common/chart/footer/footerWithScroll'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { removeSelectListTableIIC } from '../table/iic/store/slice'
import { removeSelectListTableIII } from '../table/iii/store/slice'
import { removeSelectListTableIIP } from '../table/iip/store/slice'
import Tooltip from './Tooltip'
import { COLORS_LINE_CHART, ID_DOM_PANEL_PRODUCTION } from './constants'
import { handleTooltipTwoColumnWithoutPPI } from './helper'
import style from './index.module.css'
import { changeActiveSelect, selectActiveSelect } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

export const ChartComponent = ({
  width,
  height,
  keyXAxis,
  lineKeys,
  data,
  listItemSelect,
  isFullScreen,
}) => {
  const dispatch = useDispatch()
  const legendPanel = useRef()

  const activeSelect = useSelector(selectActiveSelect)

  const [heightListSelect, setHeightListSelect] = useState(0)

  const { leftColumn, rightColumn } =
    handleTooltipTwoColumnWithoutPPI(listItemSelect)

  // Actions
  const handleDelete = (item) => {
    dispatch(removeSelectListTableIIP(item.iivnTypeId))
    dispatch(removeSelectListTableIIC(item.iivnTypeId))
    dispatch(removeSelectListTableIII(item.iivnTypeId))
    dispatch(changeActiveSelect(null))
  }

  const handleHover = (item) => {
    dispatch(changeActiveSelect(item.iivnTypeId))
  }

  const handleUnHover = () => {
    dispatch(changeActiveSelect(null))
  }

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
        </div>
      </>
    )
  }

  return (
    <div id={ID_DOM_PANEL_PRODUCTION.FIRST_CHART}>
      <ChartContainer
        data={data}
        width={width}
        height={height - heightListSelect}
        keyXAxis={keyXAxis}
        margin={{ ...MARGIN_RECHARTS, left: 12, right: 42 }}
        xTickNum={SETTINGS.xTickNum}
        yAxis={[
          {
            id: 'left',
            keys: lineKeys,
            isLineChart: true,
            orientation: 'left',
            labelPosition: AXIS_LABEL_POSITION.LEFT,
            unitYAxis: '%',
          },
        ]}
        renderCustomTooltip={renderTooltip}
        timeFrame={TIME_RANGES.CUSTOM}
        isNotFormatXAxis
      >
        {listItemSelect.map((item) => {
          return (
            <Line
              key={item.iivnTypeId}
              yAxisId="left"
              dataKey={item.iivnTypeId}
              stroke={COLORS_LINE_CHART[item.colorId]}
              dot={false}
              activeDot={false}
              strokeOpacity={
                !activeSelect || activeSelect === item.iivnTypeId ? 1 : 0.1
              }
              isAnimationActive={false}
              strokeWidth={
                !activeSelect || activeSelect !== item.iivnTypeId ? 1.5 : 2.5
              }
            />
          )
        })}
      </ChartContainer>
      <FooterWithScroll
        width={width}
        data={listItemSelect}
        idKey="iivnTypeId"
        nameKey="iivnTypeName"
        color={(item) => COLORS_LINE_CHART[item.colorId]}
        legendPanel={legendPanel}
        isFullScreen={isFullScreen}
        setHeightFooter={setHeightListSelect}
        onDelete={handleDelete}
        onHover={handleHover}
        onUnHover={handleUnHover}
      />
    </div>
  )
}

ChartComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  keyXAxis: PropTypes.string,
  lineKeys: PropTypes.array,
  data: PropTypes.array,
  isFullScreen: PropTypes.bool,
}
