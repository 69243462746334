import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { range } from '../../../utils/Common'
import { positionDropdown } from '../../dropdown'
import SelectMonthYear from '../SelectMonthYear'
import { useGetMonths } from '../helper'
import style from '../index.module.css'
import '../indexCustormHeader.module.css'

const HeaderOneCalendarRange = ({
  endMonth,
  startYear,
  endYear,
  zIndex,
  monthContentDropdownRef,
  yearContentDropdownRef,
  isOptionSelectedDefaultColor,
  position,
  marginTop,
  maxHeightDropdown,
  themeWhite,
  headerRef,
  dateCustorm,
  changeDateCustorm,
}) => {
  const dateCheck = dateCustorm ? dateCustorm : new Date()

  const months = useGetMonths(true)

  const getMonths = useMemo(() => {
    if (endYear === dateCheck.getFullYear() && endMonth) {
      return months.filter((_, index) => index < endMonth)
    }
    return months
  }, [dateCheck])

  return ({
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const changeMonthDate = (value) => {
      changeDateCustorm(
        new Date(dateCheck.getFullYear(), months.indexOf(value), 1),
      )
      changeMonth(months.indexOf(value))
    }

    const changeYearHeader = (value) => {
      changeDateCustorm(new Date(value, dateCheck.getMonth(), 1))
      changeYear(value)
    }
    const changeDecreaseMonth = (value) => {
      changeDateCustorm(
        new Date(dateCheck.getFullYear(), dateCheck.getMonth() - 1, 1),
      )
      decreaseMonth(value)
    }

    const changeIncreaseMonth = (value) => {
      changeDateCustorm(
        new Date(dateCheck.getFullYear(), dateCheck.getMonth() + 1, 1),
      )
      increaseMonth(value)
    }
    return (
      <div ref={headerRef} className={style.headerOneCalendarRangeContainer}>
        <button
          className={
            themeWhite ? style.buttonArrowThemeWhite : style.buttonArrow
          }
          onClick={changeDecreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <i className={`icon-arrow-left ${style.iconArrow}`} />
        </button>

        <div className={style.selectContainer}>
          <div className={style.selectMonthOneCalendarRange}>
            <SelectMonthYear
              currentValue={months[dateCheck.getMonth()]}
              selectRef={
                <div
                  className={`${
                    themeWhite
                      ? style.selectOneCalendarRangeThemeWhite
                      : style.selectOneCalendarRange
                  } `}
                >
                  {months[dateCheck.getMonth()]}
                  <i className={`icon-arrow-down ${style.iconArrow}`} />
                </div>
              }
              handleChange={(value) => changeMonthDate(value)}
              options={getMonths}
              marginTop={marginTop}
              zIndex={zIndex}
              contentDropdownRef={monthContentDropdownRef}
              isOptionSelectedDefaultColor={isOptionSelectedDefaultColor}
              position={position}
              maxHeightDropdown={maxHeightDropdown}
              themeWhite={themeWhite}
            />
          </div>

          <SelectMonthYear
            currentValue={dateCheck.getFullYear()}
            selectRef={
              <div
                className={
                  themeWhite
                    ? style.selectOneCalendarRangeThemeWhite
                    : style.selectOneCalendarRange
                }
              >
                {dateCheck.getFullYear()}
                <i className={`icon-arrow-down ${style.iconArrow}`} />
              </div>
            }
            handleChange={(value) => changeYearHeader(value)}
            options={range(startYear, endYear, true)}
            marginTop={marginTop}
            zIndex={zIndex}
            contentDropdownRef={yearContentDropdownRef}
            isOptionSelectedDefaultColor={isOptionSelectedDefaultColor}
            position={position}
            maxHeightDropdown={maxHeightDropdown}
            themeWhite={themeWhite}
          />
        </div>

        <button
          className={
            themeWhite ? style.buttonArrowThemeWhite : style.buttonArrow
          }
          onClick={changeIncreaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <i className={`icon-arrow-right ${style.iconArrow}`} />
        </button>
      </div>
    )
  }
}

HeaderOneCalendarRange.propTypes = {
  endMonth: PropTypes.number,
  startYear: PropTypes.number.isRequired,
  endYear: PropTypes.number.isRequired,
  zIndex: PropTypes.number,
  monthContentDropdownRef: PropTypes.object,
  yearContentDropdownRef: PropTypes.object,
  isOptionSelectedDefaultColor: PropTypes.bool,
  marginTop: PropTypes.number,
  position: PropTypes.string,
  themeWhite: PropTypes.bool,
}

HeaderOneCalendarRange.defaultProps = {
  isOptionSelectedDefaultColor: true,
  marginTop: 8,
  position: positionDropdown.BOTTOM_CENTER,
  themeWhite: false,
}

export default HeaderOneCalendarRange
