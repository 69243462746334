import GDPSpendingMethodProxy from '../../../proxies/economy/gdp/GDPSpendingMethodProxy'
import { ServiceBase } from '../../ServiceBase'

class GDPSpendingMethodService extends ServiceBase {
  getGDPTypeName(data) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getGDPTypeName',
      data,
    )(() => GDPSpendingMethodProxy.getGDPTypeName(data))
  }

  getGDPBySpendingMethod(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getGDPBySpendingMethod',
      params,
    )(() => GDPSpendingMethodProxy.getGDPBySpendingMethod(params))
  }

  downloadGDPBySpendingMethodValue(params) {
    return this.getFileDownload(() =>
      GDPSpendingMethodProxy.downloadGDPBySpendingMethodValue(params),
    )
  }

  downloadGDPBySpendingMethodStructure(params) {
    return this.getFileDownload(() =>
      GDPSpendingMethodProxy.downloadGDPBySpendingMethodStructure(params),
    )
  }

  getGDPGrowthBySpendingMethod(data) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getGDPGrowthBySpendingMethod',
      data,
    )(() => GDPSpendingMethodProxy.getGDPGrowthBySpendingMethod(data))
  }

  getGDPStructureBySpendingMethod(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getGDPStructureBySpendingMethod',
      params,
    )(() => GDPSpendingMethodProxy.getGDPStructureBySpendingMethod(params))
  }

  getGDPBySpendingMethodStructure(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getGDPBySpendingMethodStructure',
      params,
    )(() => GDPSpendingMethodProxy.getGDPBySpendingMethodStructure(params))
  }

  getMaxPeriod(params) {
    return this.applyMemoryCache(
      'GDPSpendingMethodService.getMaxPeriod',
      params,
    )(() => GDPSpendingMethodProxy.getMaxPeriod(params))
  }
}

export default new GDPSpendingMethodService()
