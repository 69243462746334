export const fillColor = { buy: '#59c761', sell: '#ff4752', net: '#f7b80c' }

export const barKeys = ['buy', 'sell']

export const lineKeys = ['net']

export const keyXAxis = 'x'

export const mappingDisplayName = {
  buy: 'market.equityTrading.priceStatistics.netValue.BUY',
  sell: 'market.equityTrading.priceStatistics.netValue.SELL',
  net: 'market.equityTrading.priceStatistics.netValue.NET',
}
