import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import FirstColumnCell from './FirstCellColumn'
import { EMPTY_ROW } from './helper'
import OtherCellColumn from './OtherCellColumn'
import {
  getGroupColumns,
  getIds,
  getIsAllowCallApi,
  getIsFirsTime,
  getMinMonthYear,
  getMoneySupplyValue,
  getTableDataById,
  getTableDataLoading,
  getTableMappingData,
  getTableMappingLoading,
  getValueMOM,
  handleIsFirstTime,
} from './store/slice'
import {
  getMonetaryTypeName,
  getMoneySupplyOutstandingLoan,
} from './store/thunk'

export const NUMBER_OF_PERIOD = 12
const HORIZONTAL_TRACK_LEFT = 28
export const NUMBER_OF_PERIOD_FIRST_TIME = 18

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableMappingLoading = useSelector(getTableMappingLoading)
  const tableDataById = useSelector(getTableDataById)
  const tableMapping = useSelector(getTableMappingData)
  const valueMOM = useSelector(getValueMOM)
  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const isFirstTime = useSelector(getIsFirsTime)
  const { minYear, minMonth } = useSelector(getMinMonthYear)
  const isAllowCallApi = useSelector(getIsAllowCallApi)

  const scrollTopLeft = () => {
    if (minYear && minMonth && isAllowCallApi) {
      setTimeout(() => {
        dispatch(
          getMoneySupplyOutstandingLoan({
            SBVType: valueMOM,
            NumberOfPeriod: NUMBER_OF_PERIOD,
            Year: minYear,
            Month: minMonth,
          }),
        )
      })
    }
    dispatch(handleIsFirstTime(false))
  }

  useEffect(() => {
    dispatch(
      getMoneySupplyOutstandingLoan({
        SBVType: valueMOM,
        NumberOfPeriod: NUMBER_OF_PERIOD_FIRST_TIME,
      }),
    )
    dispatch(handleIsFirstTime(true))
  }, [valueMOM, locale])

  useEffect(() => {
    dispatch(
      getMonetaryTypeName({
        SBVType: valueMOM,
      }),
    )
  }, [valueMOM, locale])

  if ((tableDataLoading || tableMappingLoading) && isFirstTime) {
    return <Loading />
  }

  if (Object.keys(tableDataById).length === 0 || tableMapping.length === 0) {
    return <NoData />
  }

  return (
    <div style={{ height: height }}>
      <Table
        ids={ids.map((item) => item.id)}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        columnDraggable={false}
        getDataFromRedux={getMoneySupplyValue}
        rowDraggable={false}
        isLoading={tableDataLoading || tableMappingLoading}
        schema={[{ key: 'emptyColumn', title: '' }, ...groupColumns].map(
          (item, index) => {
            const title = item.title
            const colId = item.key
            const result = {
              colId,
              title,
            }
            if (index === 0) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'left',
                },
                tdStyle: {
                  minWidth: 240,
                },
                canCustomTd: true,
                render: (val, rowId, props) => {
                  const tableHorizontalHead = ids.find(
                    (item) => item.id === rowId,
                  )
                  return (
                    <FirstColumnCell
                      props={props}
                      val={tableHorizontalHead?.sbvTotalLiquidityVNTypeName}
                      level={
                        tableHorizontalHead?.sbvTotalLiquidityVNTypeLevel || 1
                      }
                      rowId={rowId}
                      data={tableHorizontalHead}
                      ids={ids}
                    />
                  )
                },
              }
            }

            return {
              ...result,
              isI18n: true,
              tdStyle: {
                textAlign: 'right',
                fontSize: 12,
              },
              thStyle: {
                textAlign: 'right',
                fontSize: 10,
                fontWeight: 600,
              },
              canCustomTd: true,
              render: (val, rowId, props, colId) => {
                return (
                  <OtherCellColumn
                    props={props}
                    val={val}
                    rowId={rowId}
                    isValue={valueMOM === 'Value'}
                    isLastCell={
                      colId === groupColumns[groupColumns.length - 1].key
                    }
                  />
                )
              },
            }
          },
        )}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        scrollToLeftCallback={scrollTopLeft}
        defaultScrollToRight={isFirstTime}
        isLazyLoadLeft={true}
        hasUpdatedBorder={false}
        disableRowHovered={{ [EMPTY_ROW[0]]: true, [EMPTY_ROW[1]]: true }}
      />
    </div>
  )
}

TableComponent.propTypes = { height: PropTypes.number }

export default TableComponent
