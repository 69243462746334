import { useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SizeTracker } from '../../../common/sizeTracker'
import TextEllipsis from '../../../common/textEllipsis'
import MenuChartSettings from '../../common/menuChartSettings'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import { ReactComponent as IconVisibilityOff } from '../../icon/icon-visibility-off.svg'
import { ReactComponent as IconVisibilityOn } from '../../icon/icon-visibility-on.svg'
import { VIEW_TYPE } from '../constants'
import { changeEquations, removeEquation } from '../store/slice'
import style from '../style.module.css'

export const AddEquationItem = ({
  viewType,
  equationKey,
  equation,
  onEditEquation,
}) => {
  const dispatch = useDispatch()

  const equationRef = useRef()

  const [visibility, setVisibility] = useState(equation.visibility)
  const [isOpenSettings, setIsOpenSettings] = useState(false)

  const handleChangeEquation = (data) => {
    dispatch(
      changeEquations({ key: equationKey, value: { ...equation, ...data } }),
    )
  }

  const handleVisibility = () => {
    setVisibility(!visibility)
    handleChangeEquation({ visibility: !visibility })
  }

  const handleOpenSettings = () => {
    setIsOpenSettings(true)
  }

  const handleCloseSettings = () => {
    setIsOpenSettings(false)
  }

  const handleEditEquation = () => {
    onEditEquation(equationKey)
  }

  const handleRemoveEquation = () => {
    dispatch(removeEquation(equationKey))
  }

  const handleClosePopup = (data) => {
    handleChangeEquation(data)
    handleCloseSettings()
  }

  return (
    <div className={style.card}>
      <div className={style.cardHeader}>
        <SizeTracker>
          {(size) => (
            <div className="align-center w-100 h-100">
              {visibility ? (
                <IconVisibilityOn
                  className={style.iconVisibility}
                  onClick={handleVisibility}
                />
              ) : (
                <IconVisibilityOff
                  className={style.iconVisibility}
                  onClick={handleVisibility}
                />
              )}
              <div
                className={style.colorIndicator}
                style={{ backgroundColor: equation.color }}
              />
              {size.width && (
                <div style={{ width: `calc(100% - ${size.width}px)` }}>
                  <TextEllipsis
                    isI18n={false}
                    text={equation.equationIndicatorName}
                  />
                </div>
              )}
              <IconRename
                className={[style.iconRename, style.iconContainer].join(' ')}
                onClick={handleEditEquation}
              />
              <div
                ref={equationRef}
                className={['align-center', style.iconContainer].join(' ')}
                onClick={handleOpenSettings}
              >
                <i className="icon-setting-bold" style={{ fontSize: 11 }} />
              </div>
              <i
                className={[
                  'icon-delete-stroke',
                  style.iconTrack,
                  style.iconContainer,
                ].join(' ')}
                onClick={handleRemoveEquation}
                style={{ fontSize: 11 }}
              />
            </div>
          )}
        </SizeTracker>
      </div>
      <MenuChartSettings
        parentRef={equationRef}
        item={equation}
        isBySecurities={viewType === VIEW_TYPE.BY_SECURITIES}
        isShow={isOpenSettings}
        onClose={handleClosePopup}
        isEquation
      />
    </div>
  )
}
