import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Top10Provinces from './Top10Provinces'

export const TOP_10_GDP = 'TOP_10_GDP'

const PanelTop10Provinces = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const nameScreen = UseI18n('constants.route.economy.gdp.gdpByProvince.LABEL')
  const chartName = UseI18n('economy.gdp.gdp.TOP_10_GDP')
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'economy.gdp.gdp.TOP_10_GDP'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: TOP_10_GDP,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <Top10Provinces
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
      />
    </Panel>
  )
}

export default PanelTop10Provinces
