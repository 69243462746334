import { useSelector } from 'react-redux'
import { INDICATOR_TYPE } from '../../../../common/dataExplorer/constant'
import PopupSortColumn from '../../../../common/dataExplorer/PopupSortColumn'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { typePopupIndicator } from '../constant'
import { getHeaderIndicator } from '../helper'
import {
  selectActionType,
  selectActiveSheet,
  selectAllMostRecent,
  selectGroupColumnById,
  selectIndicatorById,
  selectShareIssueMethod,
} from '../store/slice'

const SortColumn = (props) => {
  const timezone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const activeSheet = useSelector(selectActiveSheet)
  const indicatorById = useSelector(selectIndicatorById)
  const groupColumnById = useSelector(selectGroupColumnById(activeSheet))
  const issueMethod = useSelector(selectShareIssueMethod)
  const actionType = useSelector(selectActionType)
  const mostRecent = useSelector(selectAllMostRecent)

  const getDetailColumn = (column) => {
    const indicatorType =
      column.conditionType === typePopupIndicator.DE01_SC20
        ? groupColumnById[column.groupId].condition?.period ===
          'MostRecentQuarter'
          ? INDICATOR_TYPE.TTM
          : INDICATOR_TYPE.YEARLY
        : column.indicatorType

    return getHeaderIndicator(
      indicatorById[column.indicatorId],
      groupColumnById[column.groupId]?.condition,
      column.conditionType,
      column.title,
      indicatorById,
      issueMethod,
      actionType,
      locale,
      timezone,
      mostRecent[column.indicatorId + (indicatorType || '')] || {},
    )
  }

  return <PopupSortColumn getDetailColumn={getDetailColumn} {...props} />
}

export default SortColumn
