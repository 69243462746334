import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../common/table'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { getTableSchema } from './helpers'
import {
  selectDataById,
  selectDataCellById,
  selectIds,
  selectLoading,
} from './store/slice'
import { getDistributorsThunk } from './store/thunk'

export const Distributor = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const fundInfo = useSelector(selectFundInfo)
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectIds)
  const dataById = useSelector(selectDataById)

  useEffect(() => {
    if (fundInfo.organizationId && !loading) {
      dispatch(
        getDistributorsThunk({ OrganizationId: fundInfo.organizationId }),
      )
    }
  }, [fundInfo.organizationId, locale])

  return (
    <Table
      ids={ids}
      getDataFromRedux={selectDataCellById}
      schema={getTableSchema({ dataById })}
      isLoading={loading}
      isPagination={false}
      hasFooter={false}
      hasTwoColumnBorder
      renderHeader={() => <></>}
    />
  )
}
