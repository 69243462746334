import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useBasicInfo from '../../../common/hooks/useBasicInfo'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import { SizeTracker } from '../../../common/sizeTracker'
import { Table } from '../../../common/table'
import {
  FILTER_COUNT_TOP,
  SELECT_TIME_RANGE,
  SELECT_TIME_RANGE_OPTIONS,
  TYPE_TOP_MOVER,
} from '../constants'
import {
  selectFilter,
  selectLoading,
  selectReCalcWidthTable,
  selectTopMoverIds,
  selectTopMoverValue,
  sort,
} from '../store/slice'
import { getTopMover, refreshTopMover } from '../store/thunk'
import TopMoverContext from './context'
import DropdownVolume from './DropdownVolume'
import { formatCurrencyToNumber } from './helper'
import RadioSortTopMover from './RadioOrderTopMover'
import * as schemas from './schemas'
import style from './style.module.css'

const TopMoverPanel = () => {
  const dispatch = useDispatch()
  const { getBasicInfo } = useBasicInfo()
  const REFRESH_DATA_TIME = 30000
  const ref = useRef({})

  const locale = useSelector((state) => state.i18n.locale)
  const topMoverIds = useSelector(selectTopMoverIds)
  const isLoading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)
  const reCalcWidths = useSelector(selectReCalcWidthTable)

  const [schema, setSchema] = useState(schemas.contributionSchema(locale))
  const [sortField, setSortField] = useState({})

  useEffect(() => {
    setSchema(schemas.getSchemaById(filter.top, filter.timeRange))
  }, [filter.top, filter.timeRange])

  const getPayload = () => {
    const payload = {
      Type: filter.top,
      ExchangeCode: filter.exchange,
      TimeRange: filter.timeRange,
      ICBId: filter.sector?.icbId || 0,
      WatchListId: filter.watchList?.watchListId,
      FromMarketCap: formatCurrencyToNumber(filter.marketCapFrom),
      ToMarketCap: formatCurrencyToNumber(filter.marketCapTo),
      Top: FILTER_COUNT_TOP,
    }
    if (filter.top === TYPE_TOP_MOVER.BREAKOUT) {
      payload.Times = filter.times
    }
    if (
      filter.top === TYPE_TOP_MOVER.CONTRIBUTION ||
      filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE ||
      filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME
    ) {
      payload.Order = filter.orderTopMover
    }
    return payload
  }

  useEffect(() => {
    setSortField({})
  }, [filter.orderTopMover, filter.top, filter.exchange, filter.sector])

  useEffect(() => {
    dispatch(sort(sortField))
  }, [sortField, reCalcWidths])

  useEffect(() => {
    if (
      !(
        filter.top === TYPE_TOP_MOVER.CONTRIBUTION &&
        filter.exchange === 'Listed'
      )
    )
      dispatch(getTopMover(getPayload()))
  }, [locale, filter])

  useEffect(() => {
    if (ref.current && ref.current.interval) {
      clearInterval(ref.current.interval)
    }
    if (filter.timeRange === SELECT_TIME_RANGE.ONE_DAY) {
      ref.current.interval = setInterval(() => {
        dispatch(refreshTopMover(getPayload()))
      }, REFRESH_DATA_TIME)
    }
    return () => {
      clearInterval(ref.current.interval)
    }
  }, [filter])

  return (
    <div className={style.panelTopMover} id={schemas.TOP_MOVER_TABLE_ID}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex justify-content-space-between">
                <div>
                  {(filter.top === TYPE_TOP_MOVER.CONTRIBUTION ||
                    filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VALUE ||
                    filter.top === TYPE_TOP_MOVER.TOP_NET_FOREIGN_VOLUME) && (
                    <RadioSortTopMover />
                  )}
                  {filter.top === TYPE_TOP_MOVER.BREAKOUT && (
                    <div className="d-flex align-center">
                      <Span style={{ fontSize: 12 }}>
                        <Translate value="market.topMover.TABLE_HEADER_TEXT_TOP_BREAKOUT" />
                        <Translate
                          value={
                            SELECT_TIME_RANGE_OPTIONS[filter.timeRange]
                              .textValTopBreakout
                          }
                        />
                        <Translate value="market.topMover.TABLE_HEADER_TEXT_TOP_BREAKOUT1" />
                      </Span>
                      <DropdownVolume />
                    </div>
                  )}
                </div>
                <div className={`${style.labelHeadText} mb-8`}>
                  <Span style={{ fontSize: 11 }}>
                    <Translate value="market.topMover.TABLE_HEADER_TEXT" />
                  </Span>
                </div>
              </div>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <TopMoverContext.Provider value={{ getBasicInfo }}>
                      <Table
                        ids={topMoverIds}
                        getDataFromRedux={selectTopMoverValue}
                        isLoading={isLoading}
                        schema={schema}
                        isLargeHead={true}
                        hasFooter={false}
                        columnDraggable={false}
                        hasTooltip={false}
                        stickyFirstColumn={true}
                        reCalcWidths={reCalcWidths}
                        sort={sort}
                        handleSortField={setSortField}
                      />
                    </TopMoverContext.Provider>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default TopMoverPanel
