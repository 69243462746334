import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellRedirect } from '../../../../common/table/CellRedirect'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import TextEllipsis from '../../../../common/textEllipsis'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_ALL_KEYS,
  TABLE_ALL_TH_ROW_KEYS,
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
} from '../constants'

export const allSchema = ({ onClick }) => {
  return TABLE_ALL_KEYS.map((item) => {
    let result = {
      ...item,
      tdStyle: {
        textAlign: 'left',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_ALL_TICKER) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'left',
          maxWidth: 110,
          whiteSpace: 'nowrap',
        },
        canCustomTd: true,
        render: (val, rowId, { style, className }) => (
          <CellRedirect
            val={val}
            rowId={rowId}
            style={style}
            className={className}
            onClick={onClick}
          />
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_ALL_NAME) {
      return {
        ...result,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: 285,
            }}
            className={className}
          >
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    result = {
      ...result,
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_ALL_NUMBER_FUND_HOLDING) {
      return result
    }

    if (
      [
        TABLE_COL_KEYS.COL_ALL_PRICE_CHANGE_1M,
        TABLE_COL_KEYS.COL_ALL_OWNERSHIP_TOTAL_SHARE,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) => valToPercent(val)}
            isPercent
          />
        ),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_ALL_TOTAL_VALUE,
        TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_1M,
        TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD,
        TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) =>
              formatVal(val / TABLE_COL_FORMATS.COL_ALL_TOTAL_VALUE)
            }
          />
        ),
      }
    }

    return {
      ...result,
      render: (val) => (
        <CellWithColor val={val} format={(val) => formatVal(val, 0)} />
      ),
    }
  })
}

export const allTableThSchema = () => {
  return TABLE_ALL_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      const result = {
        ...col,
        isI18n: false,
        rowSpan: 2,
      }

      if (col.colId === TABLE_COL_KEYS.COL_ALL_TICKER) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            minWidth: 90,
            maxWidth: 100,
          },
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_ALL_NAME) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            minWidth: 250,
            maxWidth: 285,
          },
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_ALL_CLOSE_PRICE,
          TABLE_COL_KEYS.COL_ALL_TOTAL_VALUE,
        ].includes(col.colId)
      ) {
        return {
          ...result,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
          },
          renderTh: () => (
            <div style={{ textAlign: 'right' }} className="flex-column">
              <Span>
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (
                <Translate
                  value={
                    result.colId === TABLE_COL_KEYS.COL_ALL_CLOSE_PRICE
                      ? 'common.UNIT.VND'
                      : 'common.UNIT.BILLION_VND'
                  }
                />
                )
              </Span>
            </div>
          ),
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_ALL_PRICE_CHANGE_1M,
          TABLE_COL_KEYS.COL_ALL_VOLUME,
          TABLE_COL_KEYS.COL_ALL_OWNERSHIP_TOTAL_SHARE,
          TABLE_COL_KEYS.COL_ALL_NUMBER_FUND_HOLDING,
        ].includes(col.colId)
      ) {
        return {
          ...result,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
            maxWidth: 200,
            minWidth: 180,
            whiteSpace: 'normal',
          },
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME,
          TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE,
        ].includes(col.colId)
      ) {
        return {
          ...col,
          isI18n: false,
          thStyle: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
          },
          colSpan: 3,
          renderTh: () => (
            <div style={{ textAlign: 'center' }}>
              <Span className="mr-4">
                <Translate value={col.title} />
              </Span>
              {col.colId === TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE && (
                <Span className="t-italic" style={{ fontWeight: 400 }}>
                  (<Translate value="common.UNIT.BILLION_VND" />)
                </Span>
              )}
            </div>
          ),
        }
      }

      return {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
        },
      }
    }),
  )
}

export const allThCellTopById = {
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME_1M]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME,
  ],
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME_YTD]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME,
  ],
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME_1Y]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VOLUME,
  ],
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_1M]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE,
  ],
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_YTD]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE,
  ],
  [TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE_1Y]: [
    TABLE_COL_KEYS.COL_ALL_OWNERSHIP_CHANGE_VALUE,
  ],
}
