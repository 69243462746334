import { createAsyncThunk } from '@reduxjs/toolkit'
import OverviewService from '../../../../../core/services/economy/importExport/OverviewService'

export const getMaxDateOverview = createAsyncThunk(
  'economy/importExport/importExportByProduct/getMaxDateOverview',
  async (params, { rejectWithValue }) => {
    const response = await OverviewService.getMaxDateOverview(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
