import { useState } from 'react'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import ChartComponent from './ChartComponent'

export const GROWTH_MONEY_SUPPLY_CREDIT_BALANCE =
  'GROWTH_MONEY_SUPPLY_CREDIT_BALANCE'

const GrowthSupplyCredit = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n('economy.monetary.monetary.MONEY_SUPPLIES_CREDIT')
  const chartName = UseI18n(
    'economy.monetary.monetary.GROWTH_MONEY_SUPPLY_CREDIT_BALANCE',
  )
  const tickerName = basicInfo.ticker

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title={'economy.monetary.monetary.GROWTH_MONEY_SUPPLY_CREDIT_BALANCE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: GROWTH_MONEY_SUPPLY_CREDIT_BALANCE,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
      handleCustom={setIsFullScreen}
    >
      <ChartComponent
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}

export default GrowthSupplyCredit
