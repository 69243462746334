import { useState } from 'react'
import { I18n } from 'react-i18nify'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { SizeTracker } from '../../common/sizeTracker'
import { Left } from '../common/header/Left'
import { ReactComponent as IconDownloadBlack } from '../icon/icon-download-black.svg'
import { ReactComponent as IconLinkChart } from '../icon/icon-link-chart.svg'
import { ReactComponent as IconShare } from '../icon/icon-share.svg'
import PopupDeleteDashboard from '../popup/PopupDeleteDashboard'
import style from './index.module.css'

export const Header = ({ data, dashboardType }) => {
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const showPopupDelete = () => {
    setIsShowPopupDelete(true)
  }

  return (
    <div className={style.container}>
      <SizeTracker className={style.content}>
        {(size) => {
          return (
            <>
              {size.width && (
                <div style={{ width: `calc(100% - ${size.width}px)` }}>
                  <Left data={data} dashboardType={dashboardType} />
                </div>
              )}

              <div className={style.right}>
                <Button className={style.btnLinkActive}>
                  <IconLinkChart className="mr-5" height={8} width={16} />
                  <Translate value={'financialChart.LINK_CHART'} />
                </Button>
                <IconDownloadBlack
                  className={style.disable}
                  title={I18n.t(
                    'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_DASHBOARD',
                  )}
                />
                <IconShare
                  className={style.disable}
                  title={I18n.t(
                    'financialChart.PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_DASHBOARD',
                  )}
                  style={{ fontSize: 15 }}
                />
                <i
                  className="icon-trash cursor-pointer"
                  title={I18n.t('financialChart.DELETE_DASHBOARD')}
                  onClick={showPopupDelete}
                  style={{ fontSize: 15 }}
                />
              </div>
              {isShowPopupDelete && (
                <PopupDeleteDashboard
                  handleShow={setIsShowPopupDelete}
                  dashboard={data}
                />
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
