import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class AdvancedSearchProxy extends ProxyBase {
  getLiveSearchData(params) {
    return this.get('LiveSearch', params)
  }

  getFullSearchData(params) {
    return this.get('FullSearch', params)
  }

  getSearchCompanyData(params) {
    return this.get('SearchCompany', params)
  }

  getSearchSectorData(params) {
    return this.get('SearchSector', params)
  }

  getSearchIndicesData(params) {
    return this.get('SearchIndices', params)
  }

  getSearchBondData(params) {
    return this.get('SearchBond', params)
  }

  getSearchDerivativesData(params) {
    return this.get('SearchDerivatives', params)
  }

  getSearchFunctionData(params) {
    return this.get('SearchFunction', params)
  }

  getSearchCorNewsData(params) {
    return this.get('SearchCorNews', params)
  }

  getSearchSecNewsData(params) {
    return this.get('SearchSecNews', params)
  }
  SearchBondAndUserBond(params) {
    return this.get('SearchBondAndUserBond', params)
  }

  getSearchMarNewsData(params) {
    return this.get('SearchMarNews', params)
  }

  getLogSearch(params) {
    return this.get('LogSearch', params)
  }

  postLogSearch(params) {
    return this.post('LogSearch', params)
  }

  deleteLogSearch(params) {
    return this.delete('LogSearch', params)
  }
}

export default new AdvancedSearchProxy(
  ServiceProxyConfig.Common.AdvancedSearch.ServiceUrl,
)
