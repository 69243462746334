import { useSelector } from 'react-redux'
import { selectDisplayRow } from './store/slice'

const withWrapperRow = (Row) => (props) => {
  const isDisplay = useSelector(selectDisplayRow(props.indexTicker))

  if (!isDisplay) return <></>

  return <Row {...props} />
}

export default withWrapperRow
