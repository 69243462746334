import { Translate } from 'react-redux-i18n'
import style from './countDown.module.css'

const formatNumberWithTwoCharacter = (n) => {
  let number = n.toString()
  if (number.length === 1) number = '0' + n
  return number
}

const CountDown = ({ secondsToOpenMarket }) => {
  const hours = Math.floor(secondsToOpenMarket / 3600)
  const minutes = Math.floor(secondsToOpenMarket / 60)
  const seconds = secondsToOpenMarket - minutes * 60

  return (
    <div className={`${style.container}`}>
      <span className={style.title}>
        <Translate value="common.preOpenMarket.marketPreOpenTitle" />
      </span>
      <span className={style.time}>
        {formatNumberWithTwoCharacter(hours)}:
        {formatNumberWithTwoCharacter(minutes)}:
        {formatNumberWithTwoCharacter(seconds)}
      </span>
    </div>
  )
}

export default CountDown
