import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'
import { handleExport } from '../../../../utils/Export'

export const getAssociate = createAsyncThunk(
  'corporate/ownership/associate/GET_ASSOCIATE',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getAssociate(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadAssociates = createAsyncThunk(
  'corporate/ownership/associate/DOWNLOAD_ASSOCIATES',
  async (data) => {
    const response = await OwnershipService.downloadAssociates(data)
    handleExport(response.data, response.filename)
  },
)
