import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MasterService from '../../../../../core/services/common/MasterService'
import WatchlistService from '../../../../../core/services/watchlist/WatchlistService'

export const getOrganizationsByExchangeAndICB = createAsyncThunk(
  'financialChart/popup/GET_ORGANIZATIONS_BY_EXCHANGE_AND_ICB',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MasterService.getOrganizationsByExchangeAndICB(
        params,
      )
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getWatchList = createAsyncThunk(
  'financialChart/popup/GET_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlist(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getTickerByWLId = createAsyncThunk(
  'financialChart/popup/GET_TICKER_BY_WL_ID',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlistDetail(params)
      if (response.success) return response.data
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
