import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../../common/helper'
import { TIME_FILTER_TYPE } from '../../../exportImportTable/constants'
import { TYPE_CHART_STRUCTURE } from '../../constants'
import {
  getExportContributeByProduct,
  getImportContributeByProduct,
  getMaxDateDetail,
} from '../../store/thunk'

const slice = createSlice({
  name: 'economy/importExport/importExportByProduct/getImportContributeByProduct',
  initialState: {
    isLoading: true,
    origData: [],
    data: [],
    ids: [],
    activeId: null,
    currentMonth: null,
    currentYear: null,
    locale: null,
    currentActiveName: null,
    typeStatistics: TIME_FILTER_TYPE.MONTHLY,
    currentChartType: TYPE_CHART_STRUCTURE.STRUCTURE,
    maxDate: null,
  },
  reducers: {
    changeActiveIdStructureChart: (state, action) => {
      state.activeId = action.payload
    },
    changeCurrentMonth: (state, action) => {
      state.currentMonth = action.payload
    },
    changeCurrentYear: (state, action) => {
      state.currentYear = action.payload
    },
    changeLocaleStructureChart: (state, action) => {
      state.locale = action.payload
    },
    changeTypeStatisticsStructureChart: (state, action) => {
      state.typeStatistics = action.payload
    },
    changeCurrentChartType: (state, action) => {
      state.currentChartType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportContributeByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getImportContributeByProduct.fulfilled, (state, action) => {
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: `${item.locationId}`,
          name: item.locationName,
          month: item.realMonth,
          year: item.realYear,
          value: item.value / 1000000,
        })) || []

      state.origData = payloadData
      state.currentActiveName = payloadData[0]?.vnTypeName
      state.data = convertData(
        payloadData,
        state.typeStatistics,
        state.locale,
        state.maxDate,
      ).reverse()
      state.ids = [...new Set(payloadData.map((item) => item.id))]
      state.isLoading = false
    })
    builder.addCase(getImportContributeByProduct.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getExportContributeByProduct.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getExportContributeByProduct.fulfilled, (state, action) => {
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: `${item.locationId}`,
          name: item.locationName,
          month: item.realMonth,
          year: item.realYear,
          value: item.value / 1000000,
        })) || []

      state.origData = payloadData
      state.currentActiveName = payloadData[0]?.vnTypeName
      state.data = convertData(
        payloadData,
        state.typeStatistics,
        state.locale,
        state.maxDate,
      ).reverse()
      state.ids = [...new Set(payloadData.map((item) => item.id))]
      state.isLoading = false
    })
    builder.addCase(getExportContributeByProduct.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectIds = (state) => state[slice.name].ids
export const selectCurrentMonth = (state) => state[slice.name].currentMonth
export const selectCurrentYear = (state) => state[slice.name].currentYear
export const selectActiveId = (state) => state[slice.name].activeId
export const selectCurrentActiveName = (state) =>
  state[slice.name].currentActiveName
export const selectCurrentChartType = (state) =>
  state[slice.name].currentChartType
export const selectMaxDate = (state) => state[slice.name].maxDate

export const {
  changeCurrentMonth,
  changeCurrentYear,
  changeLocale,
  changeActiveIdStructureChart,
  changeLocaleStructureChart,
  changeTypeStatisticsStructureChart,
  changeCurrentChartType,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, typeStatistics, locale, maxDate) => {
  //convert months and years to date keys
  const getDataKeyFromRange =
    typeStatistics === TIME_FILTER_TYPE.MONTHLY
      ? getMonthAndYearInDataRange(data).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )
      : typeStatistics === TIME_FILTER_TYPE.YEARLY
      ? getYearInDataRange(data).map((item) =>
          getYearKey(item.year, maxDate, locale),
        )
      : getMonthAndYearInDataRange(data).map((item) =>
          getAccMonthAndYearKey(item.month, item.year, locale),
        )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? ``,
        name: item.name ?? 0,
        key: item.key ?? '',
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? ``,
        name: item.name ?? 0,
        key: item.key ?? '',
      }))
  }

  const getDataValueFromRange =
    typeStatistics !== TIME_FILTER_TYPE.YEARLY
      ? getMonthAndYearInDataRange(data).map((item) => [
          ...dataWithMonthAndYear(item.month, item.year),
        ])
      : getYearInDataRange(data).map((item) => [...dataWithYear(item.year)])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
