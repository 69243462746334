import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { indicators } from '../../../constants'
import { getValuation } from './thunk'

const initialState = {
  isLoading: true,
  quarter: null,
  indicator: indicators['P/E'],
  year: null,
  dataChart: [],
}

const slice = createSlice({
  name: 'sector/sectorStatistics/overview/pricePerformance/chartValuation',
  initialState,
  reducers: {
    changeQuarter: (state, action) => {
      state.quarter = action.payload
    },
    changeIndicator: (state, action) => {
      state.indicator = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    clearDataChart: (state) => {
      state.dataChart = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getValuation.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getValuation.fulfilled, (state, action) => {
      state.dataChart = action.payload
      state.isLoading = false
    })
    builder.addCase(getValuation.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const { clearDataChart, changeQuarter, changeIndicator, changeYear } =
  slice.actions

export const selectQuarter = (state) => state[slice.name].quarter
export const selectIndicator = (state) => state[slice.name].indicator
export const selectYear = (state) => state[slice.name].year
export const selectLoadingValuationChart = (state) =>
  state[slice.name].isLoading
export const selectDataChartValuation = (state) => state[slice.name].dataChart

register(slice.name, slice.reducer)
