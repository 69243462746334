export const IconDoubleArrowRight = ({
  width,
  height,
  handleClick,
  className,
}) => {
  return (
    <div
      style={{ width, height }}
      onClick={handleClick}
      className={`cursor-pointer ${className ?? ''}`}
    >
      <i className="icon-arrow-right-two" />
    </div>
  )
}
