import { useState } from 'react'
import { COMPONENT } from '../../../../configs/Layout'
import PopupIndicator from '../../../common/dataExplorer/indexSector/popupIndicator'
import Result from '../../../common/dataExplorer/indexSector/result'
import { Panel } from '../../../common/panel'
import PanelFilter from '../../../common/panelFilter'
import { withWrapper } from '../../../common/withWrapper'
import IndicesSectorFilter from './IndicesSectorFilter'

const MAP_KEY = {
  TICKER: 'TICKER',
  RESULT: 'RESULT',
}

const MAP_SIZE = {
  [`${MAP_KEY.TICKER}`]: {
    width: `calc(16% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: 160 - 2 * COMPONENT.MARGIN,
    minHeight: 500,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.RESULT,
        ratioLeft: -1,
        ratioWidth: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.RESULT}`]: {
    width: `calc(100% - 16%)`,
    height: `calc(100% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: '16%',
    minWidth: 840,
    minHeight: 500,
    minTop: 0,
    minLeft: 160,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const [isLoadingDownloadCsv, setIsLoadingDownloadCsv] = useState(false)

  return (
    <div className="w-100 h-100 position-relative">
      <PanelFilter
        title="tool.dataExplorer.indicesSector.INDEX_SECTOR_2"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TICKER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <IndicesSectorFilter />
      </PanelFilter>
      <Panel
        title="tool.dataExplorer.corporate.RESULTS"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.RESULT}
        sizes={sizes}
        setSizes={setSizes}
        isLoadingDownloadCsv={isLoadingDownloadCsv}
      >
        <Result
          width={sizes[MAP_KEY.RESULT].width}
          setIsLoadingDownloadCsv={setIsLoadingDownloadCsv}
        />
      </Panel>
      <PopupIndicator />
    </div>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
