import { DateTimeSpan } from '../../../../../common/DateTimeSpan'
import { PRICE_DATA_TIME_SELECT, TRANSACTION_TYPES } from '../../constants'
import { formatValNumber, getFormatDateTime } from '../../helps'
import ColorCellType from '../ColorCell'
import {
  priceDataTableCol,
  tableColByTransactionType,
  tableFormatValue,
  tableHeadColDummy,
  tableHeaderColAttr,
  tableHeaderColByTransactionType,
  tableHeadTab,
} from './constants'
import { ThCellTab } from './ThCellTab'

const getValue = (val, colId) => {
  return val / tableFormatValue[colId]
}

export const getTableSchema = (
  { TimeFilter: timeFilter },
  thTab,
  transactionType,
) =>
  Object.keys(priceDataTableCol[thTab])
    .filter((key) => tableColByTransactionType[transactionType].includes(key))
    .map((key, index) => {
      const title = `market.equityTrading.priceStatistics.priceData.statisticsInvest.${key}`
      const colId = priceDataTableCol[thTab][key]
      const result = {
        colId,
        title,
      }

      if (![priceDataTableCol[thTab].DATE].includes(colId)) {
        const customSchema = {
          ...result,
          isI18n: false,
          tdStyle: {
            textAlign: 'right',
          },
        }

        if (
          [
            priceDataTableCol[thTab].LOCAL_INDI_NET_VOL,
            priceDataTableCol[thTab].LOCAL_INDI_NET_VAL,
            priceDataTableCol[thTab].LOCAL_INDI_NET_DEAL_VOL,
            priceDataTableCol[thTab].LOCAL_INDI_NET_DEAL_VAL,
            priceDataTableCol[thTab].LOCAL_INDI_NET_MATCH_VOL,
            priceDataTableCol[thTab].LOCAL_INDI_NET_MATCH_VAL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_VOL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_VAL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_DEAL_VOL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_DEAL_VAL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_MATCH_VOL,
            priceDataTableCol[thTab].LOCAL_INSTI_NET_MATCH_VAL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_VAL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_VOL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_DEAL_VOL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_DEAL_VAL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_MATCH_VOL,
            priceDataTableCol[thTab].FOREIGN_INDI_NET_MATCH_VAL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_VAL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_VOL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_DEAL_VOL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_DEAL_VAL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_MATCH_VOL,
            priceDataTableCol[thTab].FOREIGN_INSTI_NET_MATCH_VAL,
          ].includes(colId)
        ) {
          return {
            ...customSchema,
            render: (val) => {
              return (
                <ColorCellType
                  val={getValue(val, colId)}
                  format={(val) => formatValNumber(val)}
                />
              )
            },
          }
        }

        return {
          ...customSchema,
          render: (val) => formatValNumber(getValue(val, colId)),
        }
      }

      const customSchema = {
        ...result,
        isI18n: false,
        tdStyle: {
          textAlign: 'left',
        },
      }

      if (colId === priceDataTableCol[thTab].DATE) {
        switch (timeFilter) {
          case PRICE_DATA_TIME_SELECT.DAILY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.DATE'
            break
          case PRICE_DATA_TIME_SELECT.WEEKLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.WEEK'
            break
          case PRICE_DATA_TIME_SELECT.MONTHLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.MONTH'
            break
          case PRICE_DATA_TIME_SELECT.QUARTERLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.QUARTER'
            break
          case PRICE_DATA_TIME_SELECT.YEARLY:
            customSchema.title =
              'market.equityTrading.priceStatistics.priceData.YEAR'
            break
          default:
            break
        }
        return {
          ...customSchema,
          render: (val) => (
            <div style={{ width: 'max-content' }}>
              <DateTimeSpan
                date={val}
                formatDate={getFormatDateTime(timeFilter)}
              />
            </div>
          ),
        }
      }

      return customSchema
    })

export const getTableRowSpanSchema = (
  { TimeFilter: timeFilter },
  thTab,
  width,
  transactionType,
) =>
  tableHeaderColAttr[thTab].map((row, rowIndex) => {
    return row
      .filter(
        (col) =>
          rowIndex === 0 ||
          tableHeaderColByTransactionType[transactionType].includes(col.title),
      )
      .map((col, colIndex) => {
        let title = `market.equityTrading.priceStatistics.priceData.statisticsInvest.${col.title}`

        if (col.colId === priceDataTableCol[thTab].DATE) {
          switch (timeFilter) {
            case PRICE_DATA_TIME_SELECT.DAILY:
              title = 'market.equityTrading.priceStatistics.priceData.DATE'
              break
            case PRICE_DATA_TIME_SELECT.WEEKLY:
              title = 'market.equityTrading.priceStatistics.priceData.WEEK'
              break
            case PRICE_DATA_TIME_SELECT.MONTHLY:
              title = 'market.equityTrading.priceStatistics.priceData.MONTH'
              break
            case PRICE_DATA_TIME_SELECT.QUARTERLY:
              title = 'market.equityTrading.priceStatistics.priceData.QUARTER'
              break
            case PRICE_DATA_TIME_SELECT.YEARLY:
              title = 'market.equityTrading.priceStatistics.priceData.YEAR'
              break
            default:
              break
          }
        }

        const result = {
          ...col,
          isI18n: false,
          title,
          thStyle: {
            ...col.thStyle,
            fontSize: 10,
            fontWeight: 'bold',
          },
        }

        if (col.colSpan) {
          result.disableSort = true
        }

        if (Object.values(tableHeadTab).includes(col.colId)) {
          result.renderTh = ({ left }) => (
            <ThCellTab left={left} width={width} />
          )
        }

        if (col.colId === tableHeadColDummy) {
          result.renderTh = () => <></>

          if (transactionType !== TRANSACTION_TYPES.ALL) {
            result.colSpan = 5
          }
        }

        return result
      })
  })
