import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { VIEW_BY } from '../../constants'
import { convertData } from '../helper'
import { getChartData } from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
  timeFilter: VIEW_BY.YEARLY,
}

export const slice = createSlice({
  name: 'economy/fdi/overview/chartData',
  initialState,
  reducers: {
    changeTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChartData.fulfilled, (state, action) => {
      state.isLoading = false
      state.dataChart = convertData(action.payload || [], state.timeFilter)
    })
    builder.addCase(getChartData.rejected, (state, action) => {
      state.isLoading = action.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectTimeFilter = (state) => state[slice.name].timeFilter
export const selectChartData = (state) => state[slice.name].dataChart

export const { changeTimeFilter } = slice.actions

register(slice.name, slice.reducer)
