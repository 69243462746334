import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { COMPARE_MAX_ADDED } from '../../constants'
import { selectListCompareChecked } from './store/slice'
import style from './style.module.css'

const MessWarningAddCompare = () => {
  const listCompareChecked = useSelector(selectListCompareChecked)

  return (
    <div className="d-flex" style={{ marginTop: 4 }}>
      <Span style={{ fontSize: 11 }} className={style.textCount}>
        <Translate value="market.marketInDepth.valuation.LB_COUNT_COMPARE" />
        {listCompareChecked?.length + '/' + COMPARE_MAX_ADDED}
      </Span>
      <Span
        style={{
          fontSize: 11,
          opacity: listCompareChecked?.length === COMPARE_MAX_ADDED ? 1 : 0,
        }}
        className={style.textWarningAddCompare}
      >
        <Translate value="market.marketInDepth.valuation.MESS_WARNING_COMPARE" />
      </Span>
    </div>
  )
}

export default MessWarningAddCompare
