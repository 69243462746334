import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class InfoSecProxy extends ProxyBase {
  getDataIssuePlan(params) {
    return this.get('BondList', params)
  }
  getAccruedInterest(params) {
    return this.get('AccruedInterest', params)
  }
  getDataBondInfoProxy(params) {
    return this.get('BondInformation', params)
  }
  getDataBondCashFlowScenario(params) {
    return this.get('BondCashFlowScenario', params)
  }
  getCreditEventProxy(params) {
    return this.get('CreditEvent', params)
  }
  exportFileProxy(params) {
    return this.post('DownloadBondInformation', params, {}, 'download')
  }
}

export default new InfoSecProxy(ServiceProxyConfig.Bond.BondAnalysis.ServiceUrl)
