import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class FinancialChartDataProxy extends ProxyBase {
  getDashboardById(params) {
    return this.get('Dashboards/ById', params, '', true)
  }

  getDashboard(params) {
    return this.get('Dashboards', params)
  }

  getSharedDashboard(params) {
    return this.get('Dashboards/Shared', params)
  }

  postDashboard(params) {
    return this.post('Dashboards', params, {}, 'post')
  }

  updateDashboard(params) {
    return this.put('Dashboards', params)
  }

  deleteDashboard(params) {
    return this.delete('Dashboards', params)
  }
}

export default new FinancialChartDataProxy(
  ServiceProxyConfig.FinancialChart.ServiceUrl,
)
