import { ChartContainer } from '../../../common/ChartContainer'
import { StackedHorizontalBarChart } from '../../../common/StackedHorizontalBarChart'
import {
  chartBarKeys,
  chartFillColor,
  chartMappingDisplayName,
} from './constants'

export const ChartComponent = ({ data, width, height, loading }) => {
  return (
    <div
      style={{ height: height || 0, padding: '0 8px' }}
      className="position-relative"
    >
      {/* container's width = width - padding's left - padding's right */}
      <ChartContainer
        id="incomeStructureChartContainer"
        data={data || []}
        height={height}
        width={width - 16}
        isLoading={loading}
      >
        {(resizeSize) => (
          <StackedHorizontalBarChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            barKeys={chartBarKeys}
            unit="sector.financialAnalysis.common.PERCENT"
            yAxisKey="label"
            fillColor={chartFillColor}
            mappingDisplayName={chartMappingDisplayName}
          />
        )}
      </ChartContainer>
    </div>
  )
}
