import { useSelector } from 'react-redux'
import { Inventory } from '.'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Panel } from '../../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { getDefaultMaxYear, getSteelTypesFilter } from '../helper'
import { selectSteelTypesList } from '../store/slice'
import {
  selectDefaultMaxDate,
  selectSteelTypeId,
  selectYear,
} from './store/slice'

const DIFF_YEAR = 4

export const PanelInventory = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const steelTypesList = useSelector(selectSteelTypesList)
  const currentSteelTypeId = useSelector(selectSteelTypeId)
  const currentYear = useSelector(selectYear)
  const defaultMaxDate = useSelector(selectDefaultMaxDate)
  const locale = useSelector((state) => state.i18n.locale)

  const steelTypesFilter = getSteelTypesFilter(steelTypesList)
  const defaultMaxYear = getDefaultMaxYear(
    steelTypesFilter,
    currentSteelTypeId,
    defaultMaxDate,
  )

  const currentSelectedYear = currentYear ?? defaultMaxYear

  const currentSteelName = steelTypesList?.find(
    (item) => item.steelTypeId === currentSteelTypeId,
  )?.steelTypeName

  const nameChart = (locale) => {
    const startYear = currentSelectedYear - DIFF_YEAR
    return locale === 'en'
      ? `${currentSteelName} ${UseI18n(
          'sector.sectorSpecific.overview.INVENTORY',
        )} ${startYear}-${currentSelectedYear}`
      : `${UseI18n(
          'sector.sectorSpecific.overview.INVENTORY',
        )} ${currentSteelName} ${startYear}-${currentSelectedYear}`
  }
  const nameScreen = 'sector.sectorSpecific.steel.STEEL'
  const tabName = 'sector.sectorSpecific.steel.OVERVIEW_TAB'

  return (
    <Panel
      title="sector.sectorSpecific.overview.STEEL_INVENTORY"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={nameChart(locale)}
      downloadJpgParams={{
        domId: 'inventoryChart',
        nameScreen: UseI18n(nameScreen),
        chartName: nameChart(locale),
        tabName: UseI18n(tabName),
      }}
    >
      <Inventory
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
