import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Translate } from 'react-redux-i18n'
import { DynamicInput } from '../../common/formValidate/DynamicInput'
import { Button } from '../../common/html/Button'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import TextEllipsis from '../../common/textEllipsis'
import { Z_INDEX } from '../../constants/Common'
import { zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'

const ContentInput = ({ form, handleHidePopupSaveAs }) => {
  const nameValue = form.useValueInput('watchListNameSaveAs')
  const error = form.useValueError('watchListNameSaveAs')
  const touch = form.useValueTouch('watchListNameSaveAs')

  useEffect(() => {
    if (nameValue?.[0] === ' ')
      form.changeValues('watchListNameSaveAs', nameValue.trimStart())
  }, [nameValue])

  return (
    <>
      <div className="content">
        <div className="form-control form-vertical ali-center j-b">
          <label className={`mb-0 w-100`}>
            <Span>
              <TextEllipsis
                zIndexTooltip={zIndexOverlayPopup.alertEvent + 1}
                text="watchlist.watchlistDetail.LABEL_NAME_WL"
              />
            </Span>
          </label>
          <DynamicInput
            typeInput="text"
            nameInput="watchListNameSaveAs"
            touchOnBlur={true}
            form={form}
            classInput="w-300 bg-grey-4"
            renderMessage={() => <></>}
          />
        </div>
        {error && touch && (
          <div className="text-error t-right">
            <Span>
              <Translate value={error} />
            </Span>
          </div>
        )}
      </div>
      <div className="mt-40 group-btn justify-content-end">
        <Button
          onClick={handleHidePopupSaveAs}
          className={`btn normal h-20 ${style.fontWeightNormal}`}
        >
          <Translate value="common.button.BUTTON_CANCEL" />
        </Button>
        <Button
          onClick={error ? undefined : form.handleSubmit}
          className={
            error
              ? `btn btn-blue diasble h-20 ${style.fontWeightNormal}`
              : `btn btn-blue h-20 ${style.fontWeightNormal}`
          }
        >
          <Translate value="common.button.BUTTON_SAVE" />
        </Button>
      </div>
    </>
  )
}

ContentInput.propTypes = {
  form: PropTypes.object.isRequired,
  handleHidePopupSaveAs: PropTypes.func.isRequired,
}

const PopupSaveAs = ({ form, handleHidePopupSaveAs }) => {
  return (
    <Popup
      zIndex={Z_INDEX.MODAL + 1}
      zIndexOverlay={zIndexOverlayPopup.alertEvent}
    >
      <div className="modal modal-small w-450">
        <div className="modal-title">
          <h3>
            <Span>
              <Translate value="watchlist.watchlistDetail.BTN_SAVE_AS" />
            </Span>
          </h3>
          <a onClick={handleHidePopupSaveAs} className="close-modal">
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className="modal-content">
          <ContentInput
            form={form}
            handleHidePopupSaveAs={handleHidePopupSaveAs}
          />
        </div>
      </div>
    </Popup>
  )
}

PopupSaveAs.propTypes = {
  form: PropTypes.object.isRequired,
  handleHidePopupSaveAs: PropTypes.func.isRequired,
}

export default PopupSaveAs
