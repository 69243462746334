import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'
import { selectFilterMarketTradingValue } from '../store/slice'

const ChartTooltip = ({ data }) => {
  const { isTradingMethod } = useSelector(selectFilterMarketTradingValue)

  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {data.date}
      </Span>

      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate
            value={
              isTradingMethod
                ? 'bond.corporateBond.marketTradingValue.MATCHED_VALUE'
                : 'bond.corporateBond.marketTradingValue.PUBLIC'
            }
          />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {isTradingMethod
              ? formatVal(data.matchValue)
              : formatVal(data.totalValuePublic)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate
            value={
              isTradingMethod
                ? 'bond.corporateBond.marketTradingValue.PUT_THROUGH_VALUE'
                : 'bond.corporateBond.marketTradingValue.PRIVATE'
            }
          />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {isTradingMethod
              ? formatVal(data.dealValue)
              : formatVal(data.totalValuePrivate)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center justify-content-space-between">
        <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
          <Translate value={'bond.corporateBond.marketTradingValue.TOTAL'} />
        </Span>
        <div className="d-flex ali-center">
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            {formatVal(data.totalValue)}
          </Span>
          &nbsp;
          <Span style={{ fontSize: 11, fontWeight: 400 }}>
            <Translate value="bond.corporateBond.relativeToEquity.UNIT" />
          </Span>
        </div>
      </div>
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.string,
  }),
  date: PropTypes.string,
}

export default ChartTooltip
