import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { TIME_RANGE } from '../../../common/chartCompanyGrowth/constants'
import { TYPE_DATA, TYPE_STATISTIC } from '../../priceStatistics/constants'
import { convertData } from '../helper'
import { getPriceGrowth } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/price/priceGrowth',
  initialState: {
    isLoading: true,
    data: [],
    activeItem: null,
    isChangeDateType: false,
    locale: null,
    typeDataChart: TYPE_DATA.PRICE,
    typeStatisticsChart: TYPE_STATISTIC.MONTHLY,
    currentDateType: TIME_RANGE.MONTHLY[0].value,
    currentDateTypeYearly: TIME_RANGE.YEARLY[0].value,
  },
  reducers: {
    changeActiveItem: (state, action) => {
      state.activeItem = action.payload
    },
    changeDateType: (state) => {
      state.isChangeDateType = true
    },
    changeLocale: (state) => {
      state.isChangeLocale = true
    },
    changeTypeDataChart: (state, action) => {
      state.typeDataChart = action.payload
    },
    changeTypeStatisticsChart: (state, action) => {
      state.typeStatisticsChart = action.payload
    },
    setLoadingChart: (state, action) => {
      state.isLoading = action.payload
    },
    changeCurrentDateType: (state, action) => {
      state.currentDateType = action.payload
    },
    changeCurrentDateTypeYearly: (state, action) => {
      state.currentDateTypeYearly = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPriceGrowth.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getPriceGrowth.fulfilled, (state, action) => {
      state.isLoading = false
      state.isChangeDateType = false
      state.isChangeLocale = false

      const typeDataChart = state.typeDataChart
      const typeStatisticsChart = state.typeStatisticsChart
      const isYearlyChart = typeStatisticsChart === TYPE_STATISTIC.YEARLY

      const payloadData = action.payload || []
      const latestYear = Math.max(
        ...new Set(payloadData.map((item) => item.year)),
      )
      const latestMonth = Math.max(
        ...new Set(
          payloadData
            .filter((item) => item.year === latestYear)
            .map((item) => item.month),
        ),
      )
      const yearOfCurrentDateType = isYearlyChart
        ? latestYear - state.currentDateTypeYearly + 1
        : latestYear - state.currentDateType

      const allYearsInRange = Array.from(
        { length: latestYear - yearOfCurrentDateType + 1 },
        (_, i) => yearOfCurrentDateType + i,
      )

      const filteredData = isYearlyChart
        ? payloadData.filter((item) => allYearsInRange.includes(item.year))
        : payloadData.filter(
            (item) =>
              allYearsInRange.slice(1, -1).includes(item.year) ||
              (item.year === latestYear && item.month <= latestMonth) ||
              (item.year === yearOfCurrentDateType &&
                item.month >= latestMonth),
          )

      const data = filteredData.map((item) => ({
        ...item,
        id: item.code.length === 3 ? item.code.replace('_', '') : item.code,
        name: item.steelProductTypeName,
        value:
          typeDataChart === TYPE_DATA.PRICE
            ? item.price
            : item.priceGrowth * 100,
      }))

      state.data = convertData(data, typeStatisticsChart)
    })
    builder.addCase(getPriceGrowth.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveItem = (state) => state[slice.name].activeItem
export const selectIsChangeDateType = (state) =>
  state[slice.name].isChangeDateType
export const selectIsChangeLocale = (state) => state[slice.name].isChangeLocale
export const selectCurrentDateType = (state) =>
  state[slice.name].currentDateType
export const selectCurrentDateTypeYearly = (state) =>
  state[slice.name].currentDateTypeYearly

export const {
  changeActiveItem,
  changeDateType,
  changeTypeDataChart,
  changeTypeStatisticsChart,
  setLoadingChart,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
} = slice.actions

register(slice.name, slice.reducer)
