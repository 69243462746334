import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { VIEW_BY_CONDITION_TYPE_CODE } from '../constant'
import { selectLoading } from '../panelPreview/store/slice'
import {
  selectMostRecentSettingDate,
  selectMostRecentSettingDateLoading,
  selectViewBy,
} from '../store/slice'
import { getMostRecentSettingDate } from '../store/thunk'
import RadioExportType from './RadioExportType'
import RadioGroupBy from './RadioGroupBy'
import RadioTime from './RadioTime'
import style from './style.module.css'

const ExportSetting = () => {
  const dispatch = useDispatch()

  // Use sector
  const loading = useSelector(selectLoading)
  const dateLoading = useSelector(selectMostRecentSettingDateLoading)
  const viewBy = useSelector(selectViewBy)
  const mostRecentDate = useSelector(selectMostRecentSettingDate)

  // Use effect
  useEffect(() => {
    dispatch(
      getMostRecentSettingDate({
        ConditionType: VIEW_BY_CONDITION_TYPE_CODE[viewBy],
      }),
    )
  }, [viewBy])

  return (
    <div className={style.settingContainer}>
      {(loading || dateLoading) && (
        <div
          style={{ position: 'absolute', zIndex: 1 }}
          className="h-100 w-100"
        >
          <Loading />
        </div>
      )}
      <div className="d-flex j-b" style={{ opacity: loading ? 0.5 : 1 }}>
        <RadioTime
          minDate={mostRecentDate?.minDate}
          maxDate={mostRecentDate?.maxDate}
        />
        <RadioExportType />
        <RadioGroupBy />
      </div>
    </div>
  )
}

export default ExportSetting
