export const keyTableIndex = {
  CODE: 'code',
  CLOSE_PRICE: 'closePrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
}

export const keySchemaIndex = {
  CODE: 'CODE',
  CLOSE_PRICE: 'CLOSE_PRICE',
  PRICE_CHANGE: 'PRICE_CHANGE',
  PERCENT_PRICE_CHANGE: 'PERCENT_PRICE_CHANGE',
  TOTAL_VOLUME: 'TOTAL_VOLUME',
  TOTAL_VALUE: 'TOTAL_VALUE',
}

export const keyTableSector = {
  NAME: 'name',
  CLOSE_PRICE: 'closePrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
  TOTAL_VOLUME: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
}

export const keySchemaSector = {
  NAME: 'NAME',
  CLOSE_PRICE: 'CLOSE_PRICE',
  PRICE_CHANGE: 'PRICE_CHANGE',
  PERCENT_PRICE_CHANGE: 'PERCENT_PRICE_CHANGE',
  TOTAL_VOLUME: 'TOTAL_VOLUME',
  TOTAL_VALUE: 'TOTAL_VALUE',
}

export const keyTableCommodities = {
  COMMODITY_NAME: 'commodityName',
  CLOSE_PRICE: 'closePrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
}

export const keySchemaCommodities = {
  COMMODITY_NAME: 'COMMODITY_NAME',
  CLOSE_PRICE: 'CLOSE_PRICE',
  PRICE_CHANGE: 'PRICE_CHANGE',
  PERCENT_PRICE_CHANGE: 'PERCENT_PRICE_CHANGE',
}

export const keyTableWorldIndex = {
  GLOBAL_INDEX_NAME: 'globalIndexName',
  CLOSE_PRICE: 'closePrice',
  PRICE_CHANGE: 'priceChange',
  PERCENT_PRICE_CHANGE: 'percentPriceChange',
}

export const keySchemaWorldIndex = {
  GLOBAL_INDEX_NAME: 'GLOBAL_INDEX_NAME',
  CLOSE_PRICE: 'CLOSE_PRICE',
  PRICE_CHANGE: 'PRICE_CHANGE',
  PERCENT_PRICE_CHANGE: 'PERCENT_PRICE_CHANGE',
}

export const listTabIndex = [
  { title: 'market.marketInDepth.indices.INDEX_TAB', value: 'INDEX' },
  { title: 'market.marketInDepth.indices.SECTOR_TITLE', value: 'SECTOR' },
]

export const listTabProspect = [
  { title: 'market.marketInDepth.indices.WORLD_INDEX', value: 'WORLD INDEX' },
  { title: 'market.marketInDepth.indices.COMMODITIES', value: 'COMMODITIES' },
]

export const listTab = [
  { title: '1D', value: 'contrib1Day' },
  { title: '5D', value: 'contrib5Day' },
  { title: '10D', value: 'contrib10Day' },
  { title: '20D', value: 'contrib20Day' },
]

export const maxTickYAxis = 10

export const marketWatchTabs = {
  LIQUIDLITY: 'liquidlity',
  INTRADAY: 'intraday',
}

export const tableDataTypes = {
  REALTIME: 'realtime',
  END_OF_DAY: 'endOfDay',
}
