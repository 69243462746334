import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import PriceDataService from '../../../../../core/services/common/PriceDataService'
import SimpleSearchService from '../../../../../core/services/common/SimpleSearchService'
import IndicesService from '../../../../../core/services/market/marketInDepth/IndicesService'
import { handleExport } from '../../../../utils/Export'

const maxPageSizeIndices = 100

export const getCommoditiesThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_COMMODITIES',
  async (data, { rejectWithValue }) => {
    const response = await IndicesService.getCommodities(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadCommoditiesThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_COMMODITIES',
  async (data) => {
    const response = await IndicesService.downloadCommodities(data)
    handleExport(response.data, response.filename)
  },
)

export const getWorldIndexThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_WORLD_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await IndicesService.getWorldIndex(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadWorldIndexThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_WORLD_INDEX',
  async (data) => {
    const response = await IndicesService.downloadWorldIndex(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestIndices(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadIndexThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_INDEX',
  async (data) => {
    const response = await IndicesService.downloadIndex(data)
    handleExport(response.data, response.filename)
  },
)

export const getSectorThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_SECTOR',
  async (data, { rejectWithValue }) => {
    const response = await PriceDataService.getLatestSectors(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadSectorThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_SECTOR',
  async (data) => {
    const response = await IndicesService.downloadSector(data)
    handleExport(response.data, response.filename)
  },
)

export const getIndexSeriesThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_INDEX_SERIES',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getIndexSeries(data)
      if (response.success) {
        return response.data.reverse()
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getForeignTradingOneDayThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_FOREIGN_TRADING_ONE_DAY',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getIndexSeries(data)
      if (response.success) {
        return response.data.reverse()
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getIndexLiquiditySeriesThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_INDEX_LIQUIDITY_SERIES',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getIndexLiquiditySeries(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getAllIndexThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_ALL_INDEX',
  async (data, { rejectWithValue }) => {
    const response = await SimpleSearchService.searchIndies({
      PageSize: maxPageSizeIndices,
    })
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getBuSdThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_BU_SD',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getBuSd(data)
      if (response.success) {
        return response.data.reverse()
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getContributionThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_CONTRIBUTION',
  async (data, { rejectWithValue }) => {
    const response = await IndicesService.getContribution(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getIndexIntradayThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_INDEX_INTRADAY',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getIndexIntraday(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndexEODThunk = createAsyncThunk(
  'market/marketInDepth/indices/GET_INDEX_EOD',
  async (data, { rejectWithValue }) => {
    try {
      const response = await IndicesService.getIndexEOD(data)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadIndexIntradayThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_INDEX_INTRADAY',
  async (data) => {
    const response = await IndicesService.downloadIndexIntraday(data)
    handleExport(response.data, response.filename)
  },
)

export const downloadIndexEODThunk = createAsyncThunk(
  'market/marketInDepth/indices/DOWNLOAD_INDEX_EOD',
  async (data) => {
    const response = await IndicesService.downloadIndexEOD(data)
    handleExport(response.data, response.filename)
  },
)
