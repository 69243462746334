import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTimeType } from '../table/store/slice'
import { ChartContainer } from './ChartContainer'
import { RETAIL_SERVICE_OPTION } from './constants'
import { RetailServiceChart } from './RetailServiceChart'
import {
  changeLocale,
  selectChartDataRetails,
  selectChartLoading,
} from './store/slice'
import { getChartShortRetails } from './store/thunk'

const RetailService = ({ width, height }) => {
  const dispatch = useDispatch()

  const typeTime = useSelector(selectTimeType)
  const isLoading = useSelector(selectChartLoading)
  const data = useSelector(selectChartDataRetails)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(
      getChartShortRetails({
        DataType: typeTime,
        Limit: 12,
      }),
    )
  }, [typeTime, locale])

  useEffect(() => {
    dispatch(changeLocale(locale))
  }, [locale])

  return (
    <>
      <ChartContainer
        id="consumptionRetailService"
        data={data || []}
        height={height}
        width={width}
        isLoading={isLoading}
      >
        {(resizeSize) => {
          const propsDefault = RETAIL_SERVICE_OPTION.props
          const props = {
            data: data,
            height: height,
            width: width,
            margin: {},
            ...propsDefault,
          }
          return <RetailServiceChart {...props} />
        }}
      </ChartContainer>
    </>
  )
}

RetailService.propTypes = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
}

export default RetailService
