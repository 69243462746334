import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../common/table/helper'
import { convertSortKeyToNumber, getUnique, keyBy } from '../../../utils/Common'
import { register } from '../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../utils/Value'
import {
  getEventsThunk,
  getFiinXEventTypesThunk,
  getWatchListThunk,
} from './thunk'

export const key = {
  EXCHANGE: 'exchange',
  SECTOR: 'sector',
  COMPANY: 'company',
  EVENT_TYPE: 'event-type',
  QUICK_SELECT_INDICATORS: 'quickSelectIndicators',
  RATIO_INDICATORS: 'ratioIndicators',
  FINANCIAL_STATEMENT_INDICATORS: 'financialStatementIndicators',
  TEMPLATES: 'templates',
  CONDITIONS: 'conditions',
  RESULT: 'result',
  WATCHLIST: 'watchlist',
}

const initialState = {
  filter: {
    organizationId: [],
    groupId: [],
    watchlistId: [],
    icbId: [],
    fiinXEventTypeIds: [],
    startDate: null,
    endDate: null,
    startRecordDate: null,
    endRecordDate: null,
    startExrightDate: null,
    endExrightDate: null,
    startExerciseDate: null,
    endExerciseDate: null,
    sortField: 'Date',
    sortOrder: 0,
    page: 1,
    pageSize: 28,
    totalPage: 1,
  },
  eventTypes: [],
  events: [],
  eventIds: [],
  eventById: {},
  watchList: [],
  loading: {
    eventTypes: true,
    events: true,
    eventCount: true,
    eventDetail: false,
    watchList: true,
  },
  eventCount: [],
  eventDetailId: null,
  eventDetail: null,
  reCalcWidths: false,
  isFirstMount: {
    [key.EXCHANGE]: true,
    [key.SECTOR]: true,
    [key.COMPANY]: true,
    [key.EVENT_TYPE]: true,
  },
  parentChecked: { [key.EXCHANGE]: [], [key.SECTOR]: [] },
  checked: {
    [key.EXCHANGE]: { isCheckedAll: false, value: [] },
    [key.SECTOR]: { isCheckedAll: false, value: [] },
    [key.COMPANY]: { isCheckedAll: false, value: [] },
    [key.EVENT_TYPE]: { isCheckedAll: true, value: [] },
    [key.WATCHLIST]: { isCheckedAll: true, value: [] },
  },
  dateSelected: {
    startDate: null,
    endDate: null,
    startRecordDate: null,
    endRecordDate: null,
    startExrightDate: null,
    endExrightDate: null,
    startExerciseDate: null,
    endExerciseDate: null,
  },
  totalRecords: 0,
}

const slice = createSlice({
  name: 'market/events',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeSelectedWatchList: (state, action) => {
      state.selectedWatchList = action.payload
      state.filter.page = 1
    },

    checkEvent: (state, action) => {
      state.filter.checkedEvents.push(action.payload)
      state.filter.page = 1
    },
    uncheckEvent: (state, action) => {
      const index = state.filter.checkedEvents.indexOf(action.payload)
      if (index !== -1) {
        if (state.filter.checkedEvents.length === 1) {
          state.filter.checkedEvents = []
          state.events = []
          state.eventIds = []
          state.eventById = {}
          state.eventCount = []
          state.filter.totalPage = 1
        }
        state.filter.checkedEvents.splice(index, 1)
      }
      state.filter.page = 1
    },
    checkAllEvent: (state) => {
      state.filter.checkedEvents = state.eventTypes.map(
        (e) => e.fiinXEventTypeId,
      )
      state.filter.page = 1
    },
    uncheckAllEvent: (state) => {
      state.filter.checkedEvents = []
      state.events = []
      state.eventIds = []
      state.eventById = {}
      state.eventCount = []
      state.filter.page = 1
      state.filter.totalPage = 1
    },
    resetFilter: (state) => {
      state.events = []
      state.eventIds = []
      state.eventById = {}
      state.selectedWatchList = null
      state.filter.checkedEvents = state.eventTypes.map(
        (e) => e.fiinXEventTypeId,
      )
      state.filter = { ...state.filter, ...initialState.filter }
      for (const checkedType of Object.keys(initialState.checked)) {
        if (checkedType !== key.EVENT_TYPE) {
          state.checked[checkedType] = initialState.checked[checkedType]
        } else {
          state.checked[checkedType].value = state.eventTypes.map(
            (e) => e.fiinXEventTypeId,
          )
        }
      }
      state.isResetFilter = true
      state.dateSelected = { ...initialState.dateSelected }
    },

    applyFilter: (state, action) => {
      state.filter = {
        ...initialState.filter,
        ...action.payload,
      }
      state.isApplyFilter = true
    },

    sortEvents(state, action) {
      state.eventIds = getIdsFromProps(
        state.eventIds,
        state.eventById,
        action.payload,
        state.eventIds,
      )
    },
    changeSort: (state, action) => {
      const sort = action.payload
      state.filter.sortField =
        Object.keys(sort)[0] || initialState.filter.sortField
      state.filter.sortOrder = Object.values(sort)[0]
        ? convertSortKeyToNumber(Object.values(sort)[0])
        : initialState.filter.sortOrder
    },
    changeEventDetailId: (state, action) => {
      state.eventDetailId = action.payload
    },
    changeEventDetail: (state, action) => {
      state.eventDetail = action.payload
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },

    changeIsFirstMount(state, action) {
      state.isFirstMount[action.payload] = false
    },
    // action handle checkbox exchange, sector
    changeCheckedSector(state, action) {
      state.checked[key.SECTOR].value = action.payload
    },
    changeCheckedExchange(state, action) {
      state.checked[key.EXCHANGE].value = action.payload
    },
    changeParentCheckedSector(state, action) {
      state.parentChecked[key.SECTOR] = action.payload
    },
    changeParentCheckedExchange(state, action) {
      state.parentChecked[key.EXCHANGE] = action.payload
    },
    changeChecked(state, action) {
      const { isChecked, key, id } = action.payload
      if (isChecked) {
        state.checked[key].value.push(id)
      } else {
        state.checked[key].value = state.checked[key].value.filter(
          (el) => el !== id,
        )
      }
    },

    changeCheckedAll(state, action) {
      const { isCheckedAll, key, ids } = action.payload
      state.checked[key].value = ids
      state.checked[key].isCheckedAll = isCheckedAll
    },
    changeDate: (state, action) => {
      const { dateKey, value } = action.payload
      // state.filter[dateKey] = value
      state.dateSelected[dateKey] = value
    },
  },
  extraReducers: (builder) => {
    //Get EventTypes
    builder.addCase(getFiinXEventTypesThunk.pending, (state) => {
      state.loading.eventTypes = true
    })
    builder.addCase(getFiinXEventTypesThunk.fulfilled, (state, action) => {
      state.loading.eventTypes = false
      state.eventTypes = action.payload
      state.filter.checkedEvents = action.payload.map((e) => e.fiinXEventTypeId)
    })
    //Get Events
    builder.addCase(getEventsThunk.pending, (state) => {
      state.loading.events = true
    })
    builder.addCase(getEventsThunk.fulfilled, (state, action) => {
      state.reCalcWidths = !state.reCalcWidths
      state.loading.events = false
      const events = action.payload.data.map((e, index) => {
        const id = getUnique() + index
        return {
          ...e,
          corporateEventId:
            e.corporateEventId === null ? `NULL-${id}` : e.corporateEventId,
        }
      })
      state.events = events
      state.eventIds = [...new Set(events.map((e) => e.corporateEventId))]
      state.eventById = keyBy(events, 'corporateEventId')
      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.totalRecords = action.payload.totalRecords
    })
    builder.addCase(getEventsThunk.rejected, (state, action) => {
      state.loading.events = action.payload.isLoading
    })

    //Get watchlist
    builder.addCase(getWatchListThunk.pending, (state) => {
      state.loading.watchList = true
    })
    builder.addCase(getWatchListThunk.fulfilled, (state, action) => {
      state.loading.watchList = false
      state.watchList = action.payload
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter
export const selectCurrentPage = (state) => state[slice.name].filter.page
export const selectTotalPage = (state) => state[slice.name].filter.totalPage
export const selectEventTypes = (state) => state[slice.name].eventTypes
export const selectEvents = (state) => state[slice.name].events
export const selectEventIds = (state) => state[slice.name].eventIds
export const selectEventValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].eventById[id], attr)
export const selectEventDetailId = (state) => state[slice.name].eventDetailId
export const selectEventDetail = (state) => state[slice.name].eventDetail
export const selectEventCount = (state) => state[slice.name].eventCount
export const selectWatchList = (state) => state[slice.name].watchList
export const selectLoadingEventCount = (state) =>
  state[slice.name].loading.eventCount
export const selectLoadingEventDetail = (state) =>
  state[slice.name].loading.eventDetail
export const selectLoadingEventTypes = (state) =>
  state[slice.name].loading.eventTypes
export const selectLoadingEvents = (state) => state[slice.name].loading.events
export const selectReCalcWidths = (state) => state[slice.name].reCalcWidths
export const selectChecked = (key) => (state) =>
  state[slice.name].checked[key].value
export const selectListParentChecked = (key) => (state) =>
  state[slice.name].parentChecked[key]
export const selectIsCheckedAll = (key) => (state) =>
  state[slice.name].checked[key].isCheckedAll
export const selectDate = (key) => (state) =>
  state[slice.name].dateSelected[key]
export const selectParentChecked = (key) => (state) =>
  state[slice.name].parentChecked?.[key]
export const selectIsFirstMount = (key) => (state) =>
  state[slice.name]?.isFirstMount[key]
export const selectCheckedExchange = (state) =>
  state[slice.name].checked[key.EXCHANGE].value
export const selectTotalRecords = (state) => state[slice.name].totalRecords

export const {
  resetStore,
  resetFilter,
  resetIsResetFilter,
  resetStockExchangeSectorWlFilter,
  resetIsApplyFilter,
  applyFilter,
  changeSelectedWatchList,
  changeFilterMonth,
  changeFilterYear,
  checkEvent,
  uncheckEvent,
  checkAllEvent,
  uncheckAllEvent,
  sortEvents,
  changePage,
  changeEventDetailId,
  changeEventDetail,
  changeSort,
  changeCheckedSector,
  changeCheckedExchange,
  changeIsFirstMount,
  changeParentCheckedExchange,
  changeParentCheckedSector,
  checkStock,
  uncheckStock,
  changeChecked,
  changeCheckedAll,
  changeDate,
} = slice.actions

register(slice.name, slice.reducer)
