import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { HEADER_VALUE } from '../../constants'
import { GDP_SECTOR_VALUE } from '../panelTotalGDP/constants'
import {
  getGDPTypeNameData,
  getGDPTypeNameLoading,
  getLineChartColor,
  getValueGrowthYoY,
  getYearlyQuarterly,
} from '../panelTotalGDP/store/slice'
import { getNominalRealValue } from '../store/slice'
import Tooltip from './Tooltip'
import { filterLineChartData, handleTooltipTwoColumn } from './helper'
import style from './index.module.css'
import {
  getActiveLine,
  getTimeRangeQuarterly,
  getTimeRangeYearly,
} from './store/slice'

const YAXIS_LEFT = 'left'

const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}
const { REAL } = HEADER_VALUE
const { GROWTH_YOY } = GDP_SECTOR_VALUE

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  checkboxChecked,
  heightListIndicator,
}) => {
  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeQuarterly = useSelector(getTimeRangeQuarterly)
  const yearlyQuarterlyOption = useSelector(getYearlyQuarterly)
  const dataTypeName = useSelector(getGDPTypeNameData)
  const nominalRealValue = useSelector(getNominalRealValue)
  const valueGrowthYoY = useSelector(getValueGrowthYoY)
  const lineChartColor = useSelector(getLineChartColor)
  const activeLine = useSelector(getActiveLine)
  const typeNameLoading = useSelector(getGDPTypeNameLoading)
  const isPercentFormat =
    valueGrowthYoY === GROWTH_YOY && nominalRealValue === REAL
  const { leftColumn, rightColumn } = handleTooltipTwoColumn(
    checkboxChecked,
    dataTypeName,
  )

  const chartData = useMemo(() => {
    if (!typeNameLoading) {
      return filterLineChartData(
        data,
        timeRangeYearly,
        timeRangeQuarterly,
        yearlyQuarterlyOption,
        isPercentFormat,
      )
    }
    return []
  }, [typeNameLoading, timeRangeYearly, timeRangeQuarterly])

  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )

  // Render
  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return (
                  <Tooltip
                    key={index}
                    item={item}
                    payload={payload}
                    isPercentFormat={isPercentFormat}
                  />
                )
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return (
                  <Tooltip
                    key={index}
                    item={item}
                    payload={payload}
                    isPercentFormat={isPercentFormat}
                  />
                )
              })}
          </div>
        </div>
      </>
    )
  }

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: checkboxChecked,
      orientation: 'left',
      isLineChart: true,
      label: isPercentFormat ? '' : I18n.t('economy.gdp.gdp.TRILLION'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
      unitYAxis: isPercentFormat ? '%' : '',
    },
  ]

  return (
    <ChartContainer
      data={chartData}
      height={height - heightListIndicator}
      width={width}
      keyXAxis={keyXAxis}
      yAxis={keyYAxis}
      renderCustomTooltip={renderTooltip}
      margin={{
        ...MARGIN_RECHARTS,
        left: isPercentFormat ? 12 : 0,
        right: 36,
      }}
      timeFrame={TIME_RANGES.CUSTOM}
      isNotFormatXAxis
    >
      {lineChartColorHasAuthor.map((item, index) => {
        return (
          <Line
            key={index}
            isAnimationActive={false}
            yAxisId={YAXIS_LEFT}
            dataKey={item.author}
            stroke={item.color}
            strokeWidth={
              activeLine === item.author
                ? SETTINGS.strokeActiveWidth
                : SETTINGS.strokeWidth
            }
            dot={false}
            strokeOpacity={
              activeLine !== item.author && activeLine
                ? SETTINGS.blur
                : SETTINGS.normalOrHover
            }
            activeDot={false}
          />
        )
      })}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  keyXAxis: PropTypes.string,
  checkboxChecked: PropTypes.array,
}

export default ChartComponent
