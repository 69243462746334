export const COST_STRUCTURE_KEY = {
  rawMaterials: 'nC161_A',
  labourCosts: 'nC162_A',
  depreciation: 'nC163_A',
  outsideService: 'nC164_A',
  otherCash: 'nC165_A',
}

export const COST_STRUCTURE_COLOR = {
  [COST_STRUCTURE_KEY.rawMaterials]: '#176491',
  [COST_STRUCTURE_KEY.labourCosts]: '#3cabeb',
  [COST_STRUCTURE_KEY.depreciation]: '#fff6df',
  [COST_STRUCTURE_KEY.outsideService]: '#a6cf98',
  [COST_STRUCTURE_KEY.otherCash]: '#f7959c',
}

export const MAIN_METRIC_CODE = {
  rawMaterials: 'NC161',
  labourCosts: 'NC162',
  depreciation: 'NC163',
  outsideService: 'NC164',
  otherCash: 'NC165',
}

export const PREFIX_METRIC_CODE = {
  GROWTH: 'Growth',
  PORTION: 'Portion',
}

export const ALL_METRIC_CODE = Object.values(MAIN_METRIC_CODE)
  .map((item) => [
    item,
    PREFIX_METRIC_CODE.GROWTH + item,
    PREFIX_METRIC_CODE.PORTION + item,
  ])
  .flat()
