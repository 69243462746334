import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CoveredWarrantService from '../../../../../../core/services/market/CoveredWarrantService'

export const getCoveredWarrantBreakEvenPoint = createAsyncThunk(
  'market/coveredWarrant/breakEvenPoint/GET_COVERED_WARRANT_BREAK_EVEN_POINT',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CoveredWarrantService.getCoveredWarrantBreakEvenPoint(params)
      if (response.success) {
        return response
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
