import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import ConsumptionService from '../../../../../../core/services/economy/productionAndConsumption/ConsumptionService'

export const getConsumptionGrowthByTimeRange = createAsyncThunk(
  'economy/productionAndConsumption/consumption/GET_GROWTH_BY_TIME_RANGE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await ConsumptionService.getGrowthByTimeRange(data)
      if (response.success) {
        return response.data
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
