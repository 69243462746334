export const CHART_TYPE = {
  OVERVIEW: 'overview',
  OWNERSHIP: 'ownership',
}

export const chartAttr = {
  [CHART_TYPE.OVERVIEW]: {
    ForeignCurrentPercent: {
      name: 'corporate.ownership.chart.ForeignOwned',
      value: 'ForeignCurrentPercent',
    },
    StatePercentage: {
      name: 'corporate.ownership.chart.StateOwned',
      value: 'StatePercentage',
    },
    OtherPercentage: {
      name: 'corporate.ownership.chart.Other',
      value: 'OtherPercentage',
    },
  },
  [CHART_TYPE.OWNERSHIP]: {
    ManagerAndAffiliate: {
      name: 'corporate.ownership.chart.Management',
      value: 'ManagerAndAffiliate',
    },
    Funds: {
      name: 'corporate.ownership.chart.Funds',
      value: 'Funds',
    },
    State: {
      name: 'corporate.ownership.chart.StateOwned',
      value: 'State',
    },
    Others: {
      name: 'corporate.ownership.chart.Other',
      value: 'Others',
    },
  },
}

export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#ecffdf',
]
