export const importExport = {
  IMPORT_EXPORT: 'Import/Export',
  OVERVIEW: 'Overview',
  EXPORT_IMPORT_BY_COUNTRIES: 'Import/Export by country',
  EXPORT_IMPORT_BY_COMMODITIES: 'Import/Export by commodities',

  UNIT: 'Unit',

  FILTER_IMPORT: 'Import',
  FILTER_EXPORT: 'Export',
  FILTER_YEARLY: 'Yearly',
  FILTER_MONTHLY: 'Monthly',
  FILTER_MONTHLY_ACC: 'Acc. Monthly',
  ALL: 'All',

  RADIO_BUTTON_GROWTH: 'Growth',
  RADIO_BUTTON_VALUE: 'Value',

  YOY_GROWTH: 'YoY Growth (%)',

  MILLION_USD: 'Million USD',
  THOUSAND_BILLION_USD: 'thousand billion USD',
  BILLION_USD: 'Billion USD',
  PERCENT: '%',

  RADIO_BUTTON_RELATIVE: 'Growth YoY',
  RADIO_BUTTON_ABSOLUTE: 'Value',

  WARNING_MESS: 'The number of indicators is no more than 20',

  overview: {
    BALANCE_OF_TRADE: 'Trade Balance',
    GROWTH_CATEGORY: 'Development of Trade balance',
    EXPORT_IMPORT_AND_BALANCE: 'Import, Export and Trade balance',
    OUTSTANDING_TARGET: 'Key Indicator',
    COMMODITIES_TOP_10: 'Top 10 traded goods',
    PARTNERS_TOP_10: 'Top 10 trading partners',
    EXCHANGE_RESERVE: `Vietnam foreign exchange reservers`,

    NAME: 'Name',
    VALUE: 'Value',

    FOOTER_BALANCE_OF_TRADE: 'Balance of trade',
    FOOTER_EXPORT: 'Export',
    FOOTER_IMPORT: 'Import',
    FOOTER_VALUE_BILLION_USD: 'Value (Billion USD)',
    FOOTER_FOREIGN_RESERVE: 'Foreign exchange reserve',
    FOOTER_WEEK_IMPORT: 'Week of import',

    FILTER_COMMODITIES: 'Commodities',
    FILTER_PARTNERS: 'Partners',
    FILTER_FOREIGN_RESERVE: 'Foreign exchange reserve',

    RADIO_BUTTON_GROWTH: 'YoY Growth',
    RADIO_BUTTON_VALUE: 'Value',
  },

  exportImportByCommodities: {
    IMPORT_TURNOVER_TABLE: 'Import turnover by Commodities',
    EXPORT_TURNOVER_TABLE: 'Export turnover by Commodities',

    EXPORT_COMMODITIES_STRUCTURE: 'Commodity export value',
    IMPORT_COMMODITIES_STRUCTURE: 'Commodity import value',

    EXPORT_TURN_OVER: 'Change in Export turnover by Commodities',
    IMPORT_TURN_OVER: 'Change in Import turnover by Commodities',

    STRUCTURE: 'export composition by country',
    STRUCTURE_IMPORT: 'import composition by country',
    VALUE_YEARLY: 'export value over 5 years',
    VALUE_YEARLY_IMPORT: 'import value over 5 years',

    FILTER_STRUCTURE: 'Structure',
    FILTER_GROWTH: 'Value',
    ACC_VALUE: 'Acc.Value',
  },

  exportImportByLocation: {
    IMPORT_TURNOVER_TABLE: 'Import turnover by partners',
    EXPORT_TURNOVER_TABLE: 'Export turnover by partners',

    CHANGE_TURN_OVER_PARTNERS_EXPORT: 'Change in Export turnover by partners',
    CHANGE_TURN_OVER_PARTNERS_IMPORT: 'Change in Import turnover by partners',

    GROWTH_PARTNERS_EXPORT: 'Growth of export value to partners',
    GROWTH_PARTNERS_IMPORT: 'Growth of import value from partners',

    STRUCTURE_EXPORT: 'export composition by commodities',
    STRUCTURE_IMPORT: 'import composition by commodities',

    FILTER_CONTINENT: 'Continent',
    FILTER_TRADE_AGREEMENTS: 'Free-Trade Agreement',
  },
}
