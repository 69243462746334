import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import useGetStock from '../../../common/masterData/useGetStock'
import { NoData } from '../../../common/noData'
import { ScrollComponent } from '../../../common/ScrollComponent'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../utils/EventEmitter'
import {
  changeInListYAxisCustom,
  changeTreeSecuritiesIndicator,
} from '../../chart/store/slice'
import {
  LIST_TYPE,
  MAX_SECURITIES_ADD,
  MAX_SECURITIES_ITEM,
} from '../../common/constants'
import { useGetUserSettings } from '../../common/useGetUserSettings'
import { changeSecurities } from '../common/store/slice'
import { getSecuritiesIndicatorDataOnApply } from '../popupAddSecurities/helpers'
import ItemSecuritiesCompany from './ItemSecuritiesCompany'
import withWrapperData from './withWrapperData'

const ListCompanies = ({
  valueSearch,
  securities,
  onClose,
  treeSecuritiesIndicator,
  indicatorSelected,
  listSecuritiesEconomy,
  typeChart,
  haveOrientationLeft,
  itemId,
  usedColors,
  viewType,
}) => {
  const dispatch = useDispatch()
  const { loading, stocks } = useGetStock()
  const { companies } = securities

  const { favoriteColors } = useGetUserSettings()

  const [filteredTickers, setFilteredTickers] = useState([])

  const addSecurities = (id) => {
    const securityCount = Object.values(securities).reduce(
      (arr, ids) => arr.concat(ids),
      [],
    ).length
    if (securityCount >= MAX_SECURITIES_ADD) {
      return EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('financialChart.WANNING_ADD_SECURITIES'),
      ])
    }

    const arrNew = [...companies, id]
    const arrValue = itemId ? arrNew.filter((i) => i !== itemId) : arrNew
    const result = getSecuritiesIndicatorDataOnApply({
      treeSecuritiesIndicator,
      securities,
      indicatorSelected,
      listSecuritiesEconomy,
      typeChart,
      haveOrientationLeft,
      securitiesState: {
        ...securities,
        [LIST_TYPE.COMPANIES]: arrValue,
      },
      replaceSecurities: itemId,
      typeSecuritiesReplace: LIST_TYPE.COMPANIES,
      usedColors,
      viewType,
      favoriteColors,
    })
    dispatch(
      changeInListYAxisCustom({
        replaceId: [LIST_TYPE.COMPANIES, itemId].join('_'),
        updateId: [LIST_TYPE.COMPANIES, id].join('_'),
      }),
    )
    dispatch(changeTreeSecuritiesIndicator(result))
    dispatch(
      changeSecurities({
        typeSecurities: LIST_TYPE.COMPANIES,
        value: arrValue,
      }),
    )
    onClose()
  }

  useEffect(() => {
    setFilteredTickers(
      stocks
        .filter((ticker) =>
          valueSearch
            ? ticker.ticker
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase()) ||
              ticker.organizationShortName
                ?.toLowerCase()
                .includes(valueSearch.toLowerCase())
            : true,
        )
        .slice(0, MAX_SECURITIES_ITEM),
    )
  }, [stocks, valueSearch])

  const renderTickers = () => (
    <ScrollComponent bgColor="#D9D9D9">
      {filteredTickers.map((ticker) => {
        return (
          <ItemSecuritiesCompany
            key={ticker.organizationId}
            companies={companies}
            ticker={ticker}
            onClick={() => addSecurities(ticker.organizationId)}
            itemId={itemId}
          />
        )
      })}
    </ScrollComponent>
  )

  return (
    <div className="h-100 w-100">
      {loading ? (
        <Loading isBlack />
      ) : filteredTickers.length ? (
        renderTickers()
      ) : (
        <NoData />
      )}
    </div>
  )
}

ListCompanies.propTypes = {}

export default withWrapperData(ListCompanies)
