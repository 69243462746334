import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { orderBy, sortBy } from 'lodash'
import moment from 'moment'
import LatePayment from '../../../../../../../core/services/bond/overview/latePayment'
import { VALUE_TIME } from '../../constants'
import { renderTimeType } from '../../helper'
import { name } from '../../store/slice'

export const getDataValueOfBonds = createAsyncThunk(
  'overview/latePayment/getData',
  async (data, { rejectWithValue, getState }) => {
    try {
      const percent = 1000000000
      const response = await LatePayment.getDataChart(data)
      if (response.success) {
        const resultMap = new Map()
        const dataList = response?.data || []

        const uniqueDates = [...new Set(dataList.map((item) => item.date))]
        const uniqueIds = [...new Set(dataList.map((item) => item.icbId))]

        dataList.forEach((item) => {
          const { date, icbId, latePaymentValue, number } = item
          const key = `${date}-${icbId}`
          const accumulatedSum = resultMap.get(key) || {
            date,
            icbId,
            icbName: item.icbName,
            latePaymentValue: 0,
            number: 0,
          }
          accumulatedSum.latePaymentValue += latePaymentValue / percent
          accumulatedSum.number += number
          resultMap.set(key, accumulatedSum)
        })

        const formattedData = Array.from(resultMap.values())

        const datatChart = uniqueDates.map((e) => {
          const item = formattedData.filter((i) => i.date === e)
          const object = {}

          uniqueIds.forEach((key) => {
            const itemFor = item.find((z) => z.icbId === key)
            object[`${key}-latePaymentValue`] = itemFor?.latePaymentValue || 0
            object[`${key}-number`] = itemFor?.number || 0
            object[`${key}-name`] = itemFor.icbName
          })

          return {
            XAxis: e,
            ...object,
          }
        })

        const allState = getState()
        const state = allState[name]
        const currentDate = new Date()
        const currentMonth = currentDate.getMonth() + 1
        const currentYear = currentDate.getFullYear()
        const currentTime = moment()
        const currentQuarter = currentTime.quarter()
        let dataReturn = []
        let dataSort = []
        if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataSort = orderBy(
            datatChart,
            (item) => {
              const dates = item.XAxis.split('-')

              return !!dates?.length
                ? moment(dates?.[1]).quarter(dates?.[0]?.slice(1))
                : item.date
            },
            'asc',
          )
        } else {
          dataSort = sortBy(datatChart, (item) => {
            const [month, year] = item.XAxis.split('-')
            return new Date(`${year}-${month}`)
          })
        }

        if (state?.activeTab === VALUE_TIME.MONTHLY) {
          dataReturn = dataSort.map((e) => {
            const [month, year] = e.XAxis.split('-')
            if (
              parseInt(month) === currentMonth &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                XAxis:
                  moment(e.XAxis, 'M-YYYY').format('MM-YYYY') +
                  renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: moment(e.XAxis, 'M-YYYY').format('MM-YYYY'),
            }
          })
        } else if (state?.activeTab === VALUE_TIME.QUARTERLY) {
          dataReturn = dataSort.map((e) => {
            const [quarter, year] = e.XAxis.split('-')

            if (
              parseInt(quarter.slice(1), 10) === currentQuarter &&
              parseInt(year) === currentYear
            ) {
              return {
                ...e,
                XAxis: e.XAxis + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: e.XAxis,
            }
          })
        } else {
          dataReturn = dataSort.map((e) => {
            if (parseInt(e.XAxis) === currentYear) {
              return {
                ...e,
                XAxis: e.XAxis + renderTimeType(state?.activeTab),
              }
            }
            return {
              ...e,
              XAxis: e.XAxis,
            }
          })
        }
        return { data: dataReturn, ids: uniqueIds }
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
