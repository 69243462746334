import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../utils/Datetime'
import { getGroupMonth } from '../helper'
import styles from './index.module.css'

const GroupByMonth = ({ month, rowsByMonth, isLast }) => {
  const timeZone = UseTimeZone()
  const [isCollapse, setIsCollapse] = useState(false)
  const locale = useSelector((state) => state.i18n.locale)

  const onCollapse = () => {
    setIsCollapse(!isCollapse)
  }

  const redirectPage = (newId) => {
    if (newId) {
      window.open(`/economy/news-report-detail?newsId=${newId}`, '_blank')
    }
  }

  return (
    <div>
      <div
        className={`d-flex align-center ${styles.itemCollapse}`}
        onClick={onCollapse}
      >
        <div>
          <Span style={{ marginLeft: 5 }}>{`${getGroupMonth(
            month,
            locale,
          )}`}</Span>
        </div>
        {isCollapse ? (
          <i className={`icon-arrow-right ${styles.iconArrow}`} />
        ) : (
          <i className={`icon-arrow-down ${styles.iconArrow}`} />
        )}
      </div>
      {!isCollapse &&
        rowsByMonth.map((row) => {
          return (
            <div
              className={`d-flex align-center ${styles.eventRow}`}
              key={row.date}
            >
              <div className={styles.dateCol}>
                <TextEllipsis
                  isI18n={false}
                  appendStyle={{ opacity: 0.4, marginLeft: 8 }}
                  text={formatDateTime(row.date, FORMAT.DATE, locale, timeZone)}
                />
              </div>
              <div className={styles.eventCol}>
                <TextEllipsis isI18n={false} text={row.event} />
              </div>
              <div
                className={styles.desCol}
                onClick={() => redirectPage(row.newsId)}
                style={{ cursor: row.newsId ? 'pointer' : 'default' }}
              >
                <TextEllipsis
                  isI18n={false}
                  text={row.news}
                  appendStyle={{ marginLeft: 8 }}
                />
              </div>
            </div>
          )
        })}
      {!isLast && <hr className={styles.hrTag} />}
    </div>
  )
}

GroupByMonth.propTypes = {
  month: PropTypes.string.isRequired,
  rowsByMonth: PropTypes.array.isRequired,
  isLast: PropTypes.bool,
}

export default GroupByMonth
