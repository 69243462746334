import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getTopTradePartner } from './thunk'

const slice = createSlice({
  name: 'economy/importExport/outstandingTarget/topTradePartner',
  initialState: {
    dataChart: [],
    isLoading: true,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopTradePartner.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getTopTradePartner.fulfilled, (state, action) => {
      state.isLoading = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          id: item.locationId,
          name: item.locationName,
          import: item.import / 1000000,
          export: item.export / 1000000,
        })) || []

      state.dataChart = payloadData
    })
    builder.addCase(getTopTradePartner.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectDataChart = (state) => state[slice.name].dataChart
export const selectLoading = (state) => state[slice.name].isLoading

register(slice.name, slice.reducer)
