import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { YEARS_FILTER } from '../../constants'
import { getLatestPeriods } from '../../construction/steelStatistics/store/thunk'
import style from '../../index.module.css'
import {
  getDefaultMaxYear,
  getSteelTypesFilter,
  getTreeSteelTypesFilter,
} from '../helper'
import { selectSteelTypeIdsList, selectSteelTypesList } from '../store/slice'
import ChartComponent from './ChartComponent'
import {
  changeLocale,
  changeSteelType,
  changeYear,
  selectDataChart,
  selectDefaultMaxDate,
  selectLoading,
  selectSteelTypeId,
  selectYear,
} from './store/slice'
import { getExport } from './store/thunk'

export const Export = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataChart = useSelector(selectDataChart)
  const isLoadingChart = useSelector(selectLoading)
  const steelTypesList = useSelector(selectSteelTypesList)
  const currentSteelTypeId = useSelector(selectSteelTypeId)
  const currentYear = useSelector(selectYear)
  const locale = useSelector((state) => state.i18n.locale)
  const steelTypeIdsList = useSelector(selectSteelTypeIdsList)
  const defaultMaxDate = useSelector(selectDefaultMaxDate)

  const steelTypesFilter = getSteelTypesFilter(steelTypesList)
  const treeSteelTypesFilter = getTreeSteelTypesFilter(steelTypesList)
  const defaultMaxYear = getDefaultMaxYear(
    steelTypesFilter,
    currentSteelTypeId,
    defaultMaxDate,
  )

  const currentSelectedYear = currentYear ?? defaultMaxYear

  const handleYearChange = (val) => {
    dispatch(changeYear(val))
  }

  const onClickItem = (item) => {
    dispatch(changeSteelType(item.value))
  }

  useEffect(() => {
    dispatch(getLatestPeriods())
  }, [])

  useEffect(() => {
    if (currentSteelTypeId && currentSelectedYear) {
      dispatch(
        getExport({
          SteelTypeId: currentSteelTypeId,
          Year: currentSelectedYear,
        }),
      )
    }
  }, [currentSteelTypeId, currentSelectedYear, locale])

  useEffect(() => {
    dispatch(changeLocale(locale))
  }, [locale])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => {
          return !steelTypesFilter ? (
            <Loading />
          ) : (
            <div className="h-100">
              <div className="row j-b">
                <div className={`${style.inputDropdown}`}>
                  <SelectCustom
                    value={currentSteelTypeId}
                    isI18n={true}
                    selectData={steelTypesFilter}
                    isCollapse={true}
                    selectDataCollapse={treeSteelTypesFilter}
                    appendDropdownStyle={{ height: 300 }}
                    idKey={'value'}
                    nameKey={'name'}
                    onClickItem={onClickItem}
                    itemDefaultOpen={steelTypeIdsList}
                    appendTreeItemStyle={{ paddingLeft: 10 }}
                  />
                </div>
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '100px' }}
                >
                  <SelectCustom
                    value={currentSelectedYear}
                    isI18n={false}
                    selectData={YEARS_FILTER}
                    handleChange={handleYearChange}
                  />
                </div>
              </div>
              {size.height && (
                <div
                  id="exportChart"
                  className="position-relative"
                  style={{ height: height - size.height }}
                >
                  {isLoadingChart ? (
                    <Loading />
                  ) : !dataChart.length ? (
                    <NoData />
                  ) : (
                    <ChartComponent
                      data={dataChart}
                      width={width}
                      height={height - size.height}
                      keyXAxis="time"
                      barKeys={'value'}
                    />
                  )}
                </div>
              )}
            </div>
          )
        }}
      </SizeTracker>
    )
  )
}
