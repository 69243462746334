import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import { tabConstants } from '../table/constants'
import { selectTimeType } from '../table/store/slice'
import { ChartComponent } from './ChartComponent'
import { SelectType } from './SelectType'
import { ACC_VALUE } from './constants'
import {
  changeAccValue,
  changeCurrentDataTypeId,
  changeIsFirstTimeRenderChart,
  changeLocale,
  selectAccValue,
  selectCurrentDataTypeId,
  selectData,
  selectLeftKey,
  selectListOptions,
  selectLoading,
  selectMaxYear,
} from './store/slice'
import {
  getDataConsumptionGrowthYear,
  getMaxDate,
  getSelectionList,
} from './store/thunk'

const PADDING_BOTTOM_CHART = 20

export const GrowthYearChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)
  const data = useSelector(selectData)
  const listOptions = useSelector(selectListOptions)
  const typeTime = useSelector(selectTimeType)
  const leftKey = useSelector(selectLeftKey)
  const accValue = useSelector(selectAccValue)
  const locale = useSelector((state) => state.i18n.locale)
  const maxYear = useSelector(selectMaxYear)
  const currentDataTypeId = useSelector(selectCurrentDataTypeId)

  const dataType = listOptions[currentDataTypeId]?.value

  const checkAccValue = () => {
    dispatch(changeAccValue(!accValue))
  }

  const getData = useMemo(() => {
    return data?.map((item) => {
      const formatData = {
        label: item.label,
      }
      leftKey.forEach((key) => (formatData[key] = item[key]))
      return formatData
    })
  }, [data])

  const getTopLabel = listOptions?.find((item) => item.value === dataType)?.name

  const onChangeDataType = (value) => {
    dispatch(changeIsFirstTimeRenderChart(false))
    dispatch(
      changeCurrentDataTypeId(
        listOptions?.findIndex((item) => item.value === value),
      ),
    )
  }

  useEffect(() => {
    dispatch(
      getMaxDate({
        IIVNType: 'Consumption',
      }),
    )
  }, [])

  useEffect(() => {
    if (typeTime === tabConstants.YEARLY) {
      dispatch(
        getSelectionList({
          DataType: accValue
            ? tabConstants.ACCUMULATED_MONTH
            : tabConstants.MONTHLY,
          Criteria: 'Normal',
        }),
      )
    } else {
      dispatch(
        getSelectionList({
          DataType: typeTime,
          Criteria: 'Normal',
        }),
      )
    }
  }, [accValue, dataType])

  useEffect(() => {
    if (dataType && maxYear) {
      if (typeTime === tabConstants.YEARLY) {
        dispatch(
          getDataConsumptionGrowthYear({
            RetailVNTypeId: dataType,
            DataType: accValue
              ? tabConstants.ACCUMULATED_MONTH
              : tabConstants.MONTHLY,
            AccValue: accValue ? ACC_VALUE.VALUE : ACC_VALUE.NOT_VALUE,
            RealYear: maxYear,
          }),
        )
      } else {
        dispatch(
          getDataConsumptionGrowthYear({
            RetailVNTypeId: dataType,
            DataType: typeTime,
            RealYear: maxYear,
          }),
        )
      }
    }
  }, [typeTime, dataType, maxYear])

  useEffect(() => {
    dispatch(changeLocale(locale))
  }, [locale])

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div style={{ display: 'flex' }}>
            <SelectType
              options={listOptions}
              activeTab={dataType}
              onChangeTab={onChangeDataType}
              style={{ marginBottom: 8 }}
            />
            {typeTime === tabConstants.YEARLY && (
              <div
                style={{
                  display: 'flex',
                  paddingLeft: '16px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 8,
                }}
              >
                <input
                  checked={accValue}
                  onChange={checkAccValue}
                  type="checkbox"
                  className="checkbox cursor-pointer"
                  name="accValue"
                  id="accValue"
                />
                <label htmlFor="accValue">
                  <Translate value="economy.productionAndConsumption.consumption.ACC_VALUE" />
                </label>
              </div>
            )}
          </div>
          <div id="growthYearID">
            <div className="centerItems">
              <div
                style={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  fontWeight: '500',
                }}
                className="canvas-chart-title"
              >
                {getTopLabel ?? listOptions[0]?.name}
              </div>
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {loading ? (
                  <Loading />
                ) : (
                  <ChartComponent
                    data={getData}
                    width={width}
                    height={height - size.height - PADDING_BOTTOM_CHART}
                    leftKeys={leftKey}
                    activeName={getTopLabel ?? listOptions[0]?.name}
                    maxYear={maxYear}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}
    </SizeTracker>
  )
}
