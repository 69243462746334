import { createAsyncThunk } from '@reduxjs/toolkit'
import PeerValuationService from '../../../../../../core/services/corporate/PeerValuationService'

export const getRecommendation = createAsyncThunk(
  'corporate/peersValuation/consensus/recommendation/GET_RECOMMENDATION',
  async (data, { rejectWithValue }) => {
    const response = await PeerValuationService.getRecommendation(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
