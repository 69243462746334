import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { HEADER_VALUE } from '../../constants'
import { GDP_PROVINCE_VALUE } from '../constants'
import {
  getValueGrowthNominal,
  getValueGrowthReal,
} from '../panelGDPProvince/store/slice'
import { getNominalRealValue } from '../store/slice'
import { GRDP_STRUCTURE_PROVINCE } from './PanelGRDPStructureProvince'
import PieChartVerticalFooter from './PieChartVerticalFooter'
import SelectGroup from './SelectGroup'
import {
  getLimitChartLoading,
  getLocationId,
  getLocationNameLoading,
  getPieChartData,
  getPieChartLoading,
  getYear,
  selectLocationName,
} from './store/slice'
import {
  getGRDPStructureInByProvince,
  getLocationName,
  getMaxPeriod,
} from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const GRDPStructureProvince = ({ height, width }) => {
  const dispatch = useDispatch()

  const pieChartLoading = useSelector(getPieChartLoading)
  const pieChartData = useSelector(getPieChartData)
  const locationName = useSelector(selectLocationName)
  const locationNameLoading = useSelector(getLocationNameLoading)
  const locationId = useSelector(getLocationId)
  const nominalRealValue = useSelector(getNominalRealValue)
  const locale = useSelector((state) => state.i18n.locale)
  const limitChartLoading = useSelector(getLimitChartLoading)
  const year = useSelector(getYear)
  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  useEffect(() => {
    dispatch(
      getGRDPStructureInByProvince({
        LocationId: locationId,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: GDP_PROVINCE_VALUE.VALUE,
        Year: year,
      }),
    )
  }, [locale, locationId, nominalRealValue, year])

  useEffect(() => {
    dispatch(getLocationName())
  }, [locale])

  useEffect(() => {
    dispatch(
      getMaxPeriod({
        LocationId: locationId,
        IsNominal: nominalRealValue === NOMINAL,
        GDPTypeCode: radioValue,
      }),
    )
  }, [
    locale,
    locationId,
    nominalRealValue,
    valueGrowthNominal,
    valueGrowthReal,
  ])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <div>
            <SelectGroup />
            {(size.height || size.height === 0) && (
              <div
                style={{
                  position: 'relative',
                  height: height - size.height,
                }}
                id={GRDP_STRUCTURE_PROVINCE}
              >
                {(pieChartLoading ||
                  locationNameLoading ||
                  limitChartLoading) && <Loading />}
                {!pieChartLoading &&
                  !locationNameLoading &&
                  !limitChartLoading && (
                    <>
                      {(pieChartData.length === 0 || locationName === 0) && (
                        <NoData />
                      )}
                      {pieChartData.length !== 0 && locationName !== 0 && (
                        <PieChartVerticalFooter
                          data={pieChartData}
                          height={height - size.height}
                          dataKey="percentageValue"
                          dataName="key"
                          width={width}
                        />
                      )}
                    </>
                  )}
              </div>
            )}
          </div>
        )
      }}
    </SizeTracker>
  )
}

export default GRDPStructureProvince
