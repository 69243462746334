import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import UseFontSize from '../../hooks/useFontSize'
import { HEIGHT_XAXIS, MARGIN_RECHARTS } from '../constants'
import ZoomChart from '../zoomChart'
import { ChartContainer } from './ChartContainer'
import { getXAxisMinMax, getYAxisProps } from './helper'

const MARGIN_ZOOM_X = { left: 8, right: 0, top: 0, bottom: 0 }
const MARGIN_ZOOM_Y = { left: 8, right: 0, top: 20, bottom: 10 }

export const ChartContainerWithZoom = (props) => {
  const {
    data,
    width,
    height,
    yAxis,
    children,
    margin,
    xAxisType,
    xTickNum,
    heightXAxis,
    zoomRightYBarPosition,
    zoomLeftYBarPosition,
    marginZoomX,
    marginZoomY,
    listTitleTooltipZoom,
    yTickNum,
    keyXAxis,
    transformData,
    isScatterChart,
  } = props

  const [leftYWidth, setLeftYWidth] = useState(0)
  const [rightYWidth, setRightYWidth] = useState(0)
  const [zoomXData, setZoomXData] = useState(data)

  const formatZoomXData = useMemo(() => {
    return transformData ? transformData(zoomXData) : zoomXData
  }, [zoomXData, transformData])

  useEffect(() => {
    setZoomXData(data)
  }, [data])

  const typeFontSize = UseFontSize()

  const yAxisProps = useMemo(() => {
    return getYAxisProps({
      data: formatZoomXData,
      height,
      yAxis,
      heightXAxis,
      typeFontSize,
      margin,
    })
  }, [formatZoomXData, height, yAxis, heightXAxis, typeFontSize, margin])

  const leftY = yAxisProps
    .filter((yAxis) => yAxis.orientation === 'left')
    .map((yAxis) => [yAxis.minVal, yAxis.maxVal])

  const rightY = yAxisProps
    .filter((yAxis) => yAxis.orientation === 'right')
    .map((yAxis) => [yAxis.minVal, yAxis.maxVal])

  const xMinMax =
    xAxisType === 'number'
      ? getXAxisMinMax({ data, keyXAxis, isScatterChart })
      : undefined

  return (
    <ZoomChart
      marginZoomX={{ ...MARGIN_ZOOM_X, ...marginZoomX }}
      marginZoomY={{ ...MARGIN_ZOOM_Y, ...marginZoomY }}
      width={width}
      height={height}
      xAxisType={xAxisType}
      heightXAxis={heightXAxis}
      xData={data}
      formatZoomXData={formatZoomXData}
      setZoomXData={setZoomXData}
      xMinMax={xMinMax}
      xTickNum={xTickNum}
      leftY={leftY.length ? leftY : undefined}
      rightY={rightY.length ? rightY : undefined}
      zoomLeftYBarPosition={zoomLeftYBarPosition}
      zoomRightYBarPosition={zoomRightYBarPosition}
      leftYWidth={leftYWidth}
      rightYWidth={rightYWidth}
      listTitleTooltipZoom={listTitleTooltipZoom}
      yTickNum={yTickNum}
      keyXAxis={keyXAxis}
    >
      {({ widthChart, heightChart, data, leftY, rightY, xMinMax }) => {
        let leftIndex = 0
        let rightIndex = 0

        const newYAxis = yAxis.map((y) => ({ ...y }))
        newYAxis.forEach((item) => {
          if (item.orientation === 'left' && leftY && leftY[leftIndex]) {
            item.min = leftY[leftIndex][0]
            item.max = leftY[leftIndex][1]
            leftIndex++
          }
          if (item.orientation === 'right' && rightY && rightY[rightIndex]) {
            item.min = rightY[rightIndex][0]
            item.max = rightY[rightIndex][1]
            rightIndex++
          }
        })

        return (
          <ChartContainer
            {...props}
            data={data}
            width={widthChart}
            height={heightChart}
            xMinMax={xMinMax}
            yAxis={newYAxis}
            margin={margin}
            heightXAxis={heightXAxis}
            setLeftYWidth={setLeftYWidth}
            setRightYWidth={setRightYWidth}
            allowDataOverflow={true}
          >
            {children}
          </ChartContainer>
        )
      }}
    </ZoomChart>
  )
}

ChartContainerWithZoom.propTypes = {
  //All ChartContainer props
  xAxisType: PropTypes.string,
  margin: PropTypes.object,
  zoomRightYBarPosition: PropTypes.string, // "left" / "right"
  zoomLeftYBarPosition: PropTypes.string,
  listTitleTooltipZoom: PropTypes.array,
  yTickNum: PropTypes.number,
  xTickNum: PropTypes.number,
  transformData: PropTypes.func,
}

ChartContainerWithZoom.defaultProps = {
  xAxisType: 'category',
  margin: MARGIN_RECHARTS,
  heightXAxis: HEIGHT_XAXIS,
  marginZoomX: MARGIN_ZOOM_X,
  marginZoomY: MARGIN_ZOOM_Y,
  listTitleTooltipZoom: [],
  yTickNum: 5,
}
