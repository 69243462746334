export const ROW_IDS = {
  deposit: 'BS158',
  depositYoYGrowth: 'YoYGrowthBS158',
  depositMarketShare: 'DMSBS158',
  depositChangeInMarketShare: 'CDM',
  casa: 'NB121',
  casaYoYGrowth: 'YoYGrowthNB121',
  casaMarketShare: 'CMSNB121',
  casaChangeInMarketShare: 'C5',
}
