import { SizeTracker } from '../../../common/sizeTracker'
import Content from './Content'
import HeaderScreen from './HeaderScreen'
import { UpdateDataByLanguage } from './UpdateDataByLanguage'
import WorkSpace from './WorkSpace'

export const TradingData = () => {
  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <HeaderScreen />
              {size.height && (
                <div
                  className="position-relative w-100"
                  style={{
                    height: `calc(100% - ${size.height}px)`,
                  }}
                >
                  <Content />
                  <UpdateDataByLanguage />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
      <WorkSpace />
    </>
  )
}
