import PropTypes from 'prop-types'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { formatChartData } from '../helper'

const MAX_HEIGHT_CHART = 500

const PieChartVerticalFooter = ({ data, height, width }) => {
  const chartData = formatChartData(data)
  const chartHeight = Math.min(height, MAX_HEIGHT_CHART)

  return (
    <PieChartWithFooter
      data={chartData}
      height={chartHeight}
      width={width}
      footerRight
      footerProps={{
        isOneColumn: true,
        appendStyle: { gridGap: 6 },
        itemStyle: { paddingRight: 8 },
      }}
    />
  )
}

PieChartVerticalFooter.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number,
}

export default PieChartVerticalFooter
