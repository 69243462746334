import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class FDIByProvincesProxy extends ProxyBase {
  getTotalRegisterFDICapital(params) {
    return this.get('TotalRegisterFDICapital', params)
  }

  downloadTotalRegisterFDICapital(params) {
    return this.get('DownloadTotalRegisterFDICapital', params, 'download')
  }

  getLocationName(params) {
    return this.get('LocationName', params)
  }

  getFDIStructureByProvince(params) {
    return this.get('FDIStructureByProvince', params)
  }

  getCompositionInTotalFDIPieChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionInTotalFDIPieChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionInTotalFDIPieChart', params)
  }

  getCompositionInTotalFDIColumnChart(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'CompositionInTotalFDIColumnChart',
          paramsArr: params,
          isPostMethod: false,
        })
      : this.get('CompositionInTotalFDIColumnChart', params)
  }
}

export default new FDIByProvincesProxy(
  ServiceProxyConfig.Economy.FDI.FDIByProvinces.ServiceUrl,
)
