import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { selectIsShowPopup } from '../../../../myWatchlist/store/slice'
import {
  changeChecked,
  key,
  selectChecked,
  selectWatchList,
} from '../../store/slice'
import { getWatchListThunk } from '../../store/thunk'
import LinkCreateWatchList from './LinkCreateWatchList'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const DropdownBox = ({ isShow, parentRef, handleHide, exceptOusideRef }) => {
  const dispatch = useDispatch()

  const [keyword, setKeyword] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)
  const listChecked = useSelector(selectChecked(key.WATCHLIST))
  const watchlistState = useSelector(selectWatchList)
  const isShowPopupWatchlist = useSelector(selectIsShowPopup)
  const watchlist = useMemo(() => {
    if (keyword) {
      return watchlistState.filter((item) =>
        item.watchListName.toLowerCase().includes(keyword.toLowerCase()),
      )
    }
    return watchlistState
  }, [keyword, watchlistState])

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value)
    setActiveIndex(0)
  }

  const handleChange = (watchlistId, isChecked) => {
    dispatch(changeChecked({ isChecked, key: key.WATCHLIST, id: watchlistId }))
  }

  useEffect(() => {
    if (isShowPopupWatchlist === false) {
      dispatch(getWatchListThunk())
    }
  }, [isShowPopupWatchlist])

  return (
    <Dropdown isShow={isShow} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOusideRef}
      >
        <div
          className={`wrapper-tool ${style.mt3}`}
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
        >
          <div className="input-dropdown mb-8">
            <div
              className={`dropdown-container active w-100 ${style.filterDropdown}`}
            >
              <div className={`form-search-inbox  m-0 ${style.pb5}`}>
                <Input
                  onChange={handleChangeKeyword}
                  type="text"
                  value={keyword}
                  className="search-inbox h-20"
                  placeholder={I18n.t('market.event.SEARCH_PLH')}
                  style={{ fontSize: 11, backgroundColor: '#2c313e' }}
                />
                <button type="button">
                  <i className="icon-search-2" />
                </button>
              </div>

              <div className={style.watchlistDropdown} id="watchlistDropdownId">
                {watchlist.length === 0 ? (
                  <div className={style.noData}>
                    <Span style={{ fontSize: 10 }}>
                      <Translate value="market.event.NO_WATCHLIST" />
                    </Span>
                  </div>
                ) : (
                  <ScrollComponent
                    autoHeight={true}
                    autoHeightMin={200}
                    autoHeightMax={300}
                  >
                    <ul className={style.ulFirst}>
                      {watchlist.map((wl) => (
                        <li className={style.wlElement} key={wl.watchListId}>
                          <label className="d-flex">
                            <input
                              type="checkbox"
                              className={`checkbox line mr-8 ${style.darkCheckbox}`}
                              checked={listChecked.includes(wl.watchListId)}
                              onChange={(e) =>
                                handleChange(wl.watchListId, e.target.checked)
                              }
                            />
                            <span className={style.wlName}>
                              {wl.watchListName}
                            </span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  </ScrollComponent>
                )}
              </div>
            </div>
          </div>
          <LinkCreateWatchList handleHide={handleHide} />
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const DetectScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#watchlistDropdownId .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

DropdownBox.propTypes = {
  isShow: PropTypes.bool.isRequired,
  parentRef: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
}

export default DropdownBox
