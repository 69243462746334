import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getSearchData } from './thunk'

export const name = 'bond/security/ticker'
const initialState = {
  loadingSearch: false,
  list: [],
  bondSelect: null,
  bondId: '',
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    changeBasicInfo(state, action) {
      state.bondSelect = action.payload
    },
    changeBondTicker(state, action) {
      state.bondId = action.payload
    },
    changeLoadingSearch: (state, action) => {
      state.loadingSearch = action.payload
    },
    subscribeStock: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
    subscribeBidAsk: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchData.pending, (state) => {
      state.loadingSearch = true
    })
    builder.addCase(getSearchData.fulfilled, (state, action) => {
      state.loadingSearch = false
      state.list = action.payload || []
    })
    builder.addCase(getSearchData.rejected, (state, action) => {
      state.loadingSearch = action.payload
    })
  },
})

export const selectBasicInfo = (state) => state[slice.name].bondSelect
export const selectBondTicker = (state) => state[slice.name].bondId
export const selectLoadingSearch = (state) => state[slice.name].loadingSearch
export const selectList = (state) => state[slice.name].list
export const selectPriceData = (state) => state[slice.name].priceData
export const selectPriceDataLoading = (state) => state[slice.name].isLoading

export const {
  subscribeStock,
  subscribeBidAsk,
  changeBasicInfo,
  changeLoadingSearch,
  changeBondTicker,
} = slice.actions

register(slice.name, slice.reducer)
