import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Title from '../../../common/topInfo/components/Title'
import VerticalLine from '../../../common/topInfo/components/VerticalLine'
import { RADIO_VIEW_BY } from './constant'
import { changeViewBy, selectViewBy } from './store/slice'

const HeaderScreen = () => {
  const dispatch = useDispatch()

  const viewBy = useSelector(selectViewBy)

  const handleChangeViewBy = (e) => {
    dispatch(changeViewBy(e.target.value))
  }

  return (
    <div className="top-info">
      <Title title={'tool.dataExplorer.tradingData.TRADING_DATA'} />
      <VerticalLine mr={12} />
      <ul className="list-check d-flex ml-25">
        {RADIO_VIEW_BY.map((item) => {
          return (
            <li key={item.value} className="mb-0">
              <label className="fs-12">
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  name={item.name}
                  value={item.value}
                  checked={item.value === viewBy}
                  onChange={handleChangeViewBy}
                />
                <Span
                  style={{
                    color: item.value === viewBy ? '#2f9aee' : '',
                    fontSize: 14,
                    fontWeight: 500,
                  }}
                >
                  <Translate value={item.title} />
                </Span>
              </label>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default HeaderScreen
