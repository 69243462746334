import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertDataTree } from '../../common/tree/helper'
import {
  key,
  selectFinancialStatementIndicators,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getFinancialStatementIndicatorsThunk } from './store/thunk'

const useGetFinancialStatementIndicators = (disabledCallApi = false) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const financialStatementIndicators = useSelector(
    selectFinancialStatementIndicators,
  )
  const loadingFinancialStatement = useSelector(
    selectLoading(key.FINANCIAL_STATEMENT_INDICATORS),
  )

  const [
    financialStatementIndicatorsTree,
    setFinancialStatementIndicatorsTree,
  ] = useState({})

  useEffect(() => {
    if (!disabledCallApi) {
      dispatch(getFinancialStatementIndicatorsThunk())
    }
  }, [locale])

  useEffect(() => {
    setFinancialStatementIndicatorsTree(
      convertDataTree(
        financialStatementIndicators,
        'indicatorId',
        'parentIndicatorId',
      ),
    )
  }, [financialStatementIndicators])

  return {
    financialStatementIndicators,
    financialStatementIndicatorsTree,
    loadingFinancialStatement,
  }
}

export default useGetFinancialStatementIndicators
