import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { addDefaultDetail } from '../store/slice'

const useAddDefaultDetail = (defaultDetail) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (defaultDetail) {
      dispatch(addDefaultDetail(defaultDetail))
    }
  }, [defaultDetail])
}

export default useAddDefaultDetail
