import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { handleTickerIds } from '../../../../template/helper'
import {
  addDisplayValues,
  checkedAllItem,
  removeAllItemListDisplay,
  tickTemplate,
} from '../../helper'
import { getVNIndex } from './thunk'

const initialState = {
  tickerIds: [],
  stockData: {},
  checkedAll: false,
  listDisplay: {},
  vnIndexId: [],
}

const slice = createSlice({
  name: 'newsReport/dailyReport/setting/companyNews/company',
  initialState,
  reducers: {
    setStockData: (state, action) => {
      state.stockData = keyBy(action.payload, 'organizationId')
      state.tickerIds = handleTickerIds(action.payload)
      state.listDisplay = addDisplayValues(state.stockData, state.vnIndexId)
    },
    handleCheckedAll: (state, action) => {
      state.checkedAll = action.payload
    },
    handleTickById: (state, action) => {
      const id = action.payload
      state.listDisplay[id].isChecked = !state.listDisplay[id].isChecked
    },
    checkAllItem: (state, action) => {
      state.listDisplay = checkedAllItem(state.listDisplay, action.payload)
    },
    handleAddOneTicker: (state, action) => {
      const id = action.payload
      state.listDisplay[id] = {
        isDisplay: true,
        isChecked: true,
      }
    },
    handleAddManyTicker: (state, action) => {
      const listId = action.payload
      listId.forEach((id) => {
        if (state.listDisplay[id]) {
          state.listDisplay[id].isDisplay = true
        }
      })
    },
    handleRemoveOneTicker: (state, action) => {
      const id = action.payload
      state.listDisplay[id].isChecked = false
    },
    handleRemoveAllTicker: (state, action) => {
      const listId = action.payload
      listId.forEach((id) => {
        if (state.listDisplay[id]) {
          state.listDisplay[id].isChecked = false
        }
      })
    },
    handleRemoveManyTicker: (state, action) => {
      const listId = action.payload
      listId.forEach((id) => {
        if (state.listDisplay[id]) {
          state.listDisplay[id] = {
            isDisplay: false,
            isChecked: false,
          }
        }
      })
    },
    resetListDisplay: (state) => {
      state.listDisplay = removeAllItemListDisplay(state.listDisplay)
    },
    handleTickTemplate: (state, action) => {
      state.listDisplay = tickTemplate(state.listDisplay, action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVNIndex.fulfilled, (state, action) => {
      state.vnIndexId = keyBy(action.payload, 'organizationId')
    })
  },
})

export const {
  setStockData,
  handleCheckedAll,
  handleTickById,
  checkAllItem,
  handleAddOneTicker,
  handleAddManyTicker,
  handleRemoveOneTicker,
  handleRemoveAllTicker,
  resetListDisplay,
  handleTickTemplate,
  handleRemoveManyTicker,
} = slice.actions

export const getTickerIds = (state) => state[slice.name].tickerIds
export const getDataByTickerId = (tickerId) => (state) =>
  state[slice.name].stockData[tickerId]
export const getCheckedAll = (state) => state[slice.name].checkedAll
export const getDisplayById = (id) => (state) =>
  state[slice.name].listDisplay[id]
export const getListDisplay = (state) => state[slice.name].listDisplay
export const getVNIndexData = (state) => state[slice.name].vnIndexId

register(slice.name, slice.reducer)
