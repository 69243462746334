export const TYPE_CODE = {
  MoM: 'MoM',
  YoY: 'YoY',
  RTD: 'RTD',
}

export const LIST_RADIO = [
  {
    value: TYPE_CODE.MoM,
    label: 'economy.cpiAndInflation.cpiAndInflationTable.MOM',
  },
  {
    value: TYPE_CODE.YoY,
    label: 'economy.cpiAndInflation.cpiAndInflationTable.YOY',
  },
  {
    value: TYPE_CODE.RTD,
    label: 'economy.cpiAndInflation.cpiAndInflationTable.RTD',
  },
]

export const NUM_OF_PERIOD_FIRST_FETCH = 20
export const NUM_OF_PERIOD = 5

export const DEFAULT_LIST_CPI = (data) => {
  return [...data.slice(0, 2), ...data.slice(17, 20)]
}
