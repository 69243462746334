import { useEffect, useRef, useState } from 'react'
import { FORMAT } from '../../../utils/Datetime'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../utils/EventEmitter'
import { useFormatDateTime } from '../../hooks/useDateTime'
import useWindowDevicePixelRatio from '../../hooks/useWindowDevicePixelRatio'
import DropdownTooltip from '../../textEllipsis/DropdownTooltip'
import { SIZE_ZOOM_CHART } from '../constants'
import style from './index.module.css'
import { ReactComponent as ZoomChartIcon } from './ZoomChartIcon.svg'

const widthResize = 0
const rangeMinMax = 100
let mouseStart = 0
let positionStart = 0
let keyPosition = ''
let minResize = 0
let maxResize = 0
let positionHorizol = {}
let positionLeft = 0
let positionRight = 0

const ZoomX = ({
  width,
  margin,
  xData,
  setData,
  disabled,
  leftYWidth,
  rightYWidth,
  keyXAxis,
}) => {
  const horizolBarRef = useRef()
  const minMaxResize = useRef()
  const leftHorizolRef = useRef()
  const rightHorizolRef = useRef()

  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [positionResize, setPositionResize] = useState({
    leftHorizol: 0,
    rightHorizol: 0,
  })
  const [valueResize, setValueResize] = useState({
    firstPoint: xData[0],
    finalPoint: xData[xData.length - 1],
  })
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    setPositionResize({
      leftHorizol: 0,
      rightHorizol: formatWithZoom(
        horizolBarRef.current.getBoundingClientRect().width - widthResize,
      ),
    })
    minMaxResize.current = {
      minHorizol: 0,
      maxHorizol: formatWithZoom(
        horizolBarRef.current.getBoundingClientRect().width - widthResize,
      ),
    }
  }, [leftYWidth, rightYWidth])

  const getDataAfterZoom = () => {
    const lengthData = xData.length
    return xData.slice(
      Math.floor(
        (positionHorizol.leftHorizol / minMaxResize.current.maxHorizol) *
          lengthData,
      ),
      Math.ceil(
        (positionHorizol.rightHorizol / minMaxResize.current.maxHorizol) *
          lengthData,
      ) + 1,
    )
  }

  const zoomStart = (e, keyPositionParam, minResizeParam, maxResizeParam) => {
    mouseStart = formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true)
    positionStart = positionResize[keyPositionParam]
    keyPosition = keyPositionParam
    minResize = minResizeParam
    maxResize = maxResizeParam
    positionHorizol = positionResize
    window.addEventListener('mousemove', zoomOpen)
    window.addEventListener('mouseup', zoomStop)
    window.addEventListener('touchmove', zoomOpen)
    window.addEventListener('touchend', zoomStop)
  }

  const zoomOpen = (e) => {
    const position =
      formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
      mouseStart +
      positionStart
    if (position >= minResize && position <= maxResize) {
      positionHorizol = { ...positionResize, [keyPosition]: position }
    } else if (position < minResize) {
      positionHorizol = { ...positionResize, [keyPosition]: minResize }
    } else {
      positionHorizol = {
        ...positionResize,
        [keyPosition]: maxResize,
      }
    }
    setPositionResize(positionHorizol)
    setValueResize({
      firstPoint: getDataAfterZoom()[0],
      finalPoint: getDataAfterZoom()[getDataAfterZoom().length - 1],
    })
    setShowTooltip(true)
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
  }

  const zoomStop = () => {
    setData(getDataAfterZoom())
    setShowTooltip(false)
    window.removeEventListener('mousemove', zoomOpen)
    window.removeEventListener('mousemove', zoomBarMove)
    window.removeEventListener('mouseup', zoomStop)
    window.removeEventListener('touchmove', zoomOpen)
    window.removeEventListener('touchmove', zoomBarMove)
    window.removeEventListener('touchend', zoomStop)
  }

  const zoomBarMoveStart = (e) => {
    mouseStart = formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true)
    positionLeft = positionResize.leftHorizol
    positionRight = positionResize.rightHorizol
    minResize = minMaxResize.current.minHorizol
    maxResize = minMaxResize.current.maxHorizol
    positionHorizol = positionResize
    window.addEventListener('mousemove', zoomBarMove)
    window.addEventListener('mouseup', zoomStop)
    window.addEventListener('touchmove', zoomBarMove)
    window.addEventListener('touchend', zoomStop)
  }

  const zoomBarMove = (e) => {
    const positionLeftHorizol =
      formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
      mouseStart +
      positionLeft
    const positionRightHorizol =
      formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
      mouseStart +
      positionRight
    const rangeLeftRight = positionRight - positionLeft
    if (positionLeftHorizol >= minResize && positionRightHorizol <= maxResize) {
      positionHorizol = {
        leftHorizol: positionLeftHorizol,
        rightHorizol: positionRightHorizol,
      }
    } else if (positionLeftHorizol < minResize) {
      positionHorizol = {
        leftHorizol: minResize,
        rightHorizol: minResize + rangeLeftRight,
      }
    } else {
      positionHorizol = {
        leftHorizol: maxResize - rangeLeftRight,
        rightHorizol: maxResize,
      }
    }
    setPositionResize(positionHorizol)

    setValueResize({
      firstPoint: getDataAfterZoom()[0],
      finalPoint: getDataAfterZoom()[getDataAfterZoom().length - 1],
    })
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setShowTooltip(true)
  }

  const resize = () => {
    const oldMinMaxResize = { ...minMaxResize.current }
    minMaxResize.current = {
      minHorizol: 0,
      maxHorizol: formatWithZoom(
        horizolBarRef.current.getBoundingClientRect().width - widthResize,
      ),
    }
    setPositionResize({
      leftHorizol:
        (positionResize.leftHorizol / oldMinMaxResize.maxHorizol) *
        minMaxResize.current.maxHorizol,
      rightHorizol:
        (positionResize.rightHorizol / oldMinMaxResize.maxHorizol) *
        minMaxResize.current.maxHorizol,
    })
  }

  useEffect(() => {
    resize()
  }, [width])

  return (
    <div
      className={`position-relative ${style.horizolBar}`}
      ref={horizolBarRef}
      style={{
        zIndex: 1,
        display: disabled ? 'none' : 'block',
        width: `calc(100% - ${
          margin.left + margin.right + leftYWidth + rightYWidth
        }px)`,
        marginLeft: margin.left + leftYWidth,
        marginBottom: margin.bottom,
      }}
      data-html2canvas-ignore="true"
    >
      <div
        style={{
          left: positionResize.leftHorizol,
          width:
            positionResize.rightHorizol - positionResize.leftHorizol + 'px',
        }}
        className={style.horizolResizeBar}
      />
      <div
        style={{
          left: positionResize.leftHorizol,
          width:
            positionResize.rightHorizol - positionResize.leftHorizol + 'px',
        }}
        className={style.horizolResizeBarCover}
        onTouchStart={zoomBarMoveStart}
        onMouseDown={zoomBarMoveStart}
      />
      <ZoomChartIcon
        className={style.horizolIconZoomChart}
        style={{
          left:
            (positionResize.leftHorizol + positionResize.rightHorizol) / 2 -
            SIZE_ZOOM_CHART / 2,
        }}
      />
      <div
        className={style.horizolResize}
        style={{ left: positionResize.leftHorizol }}
      >
        <DropdownTooltip
          containerRef={leftHorizolRef}
          isOpenTooltip={showTooltip}
          isI18n={false}
          text={useFormatDateTime(
            valueResize.firstPoint[keyXAxis],
            FORMAT.DATE,
          )}
        />
      </div>
      <div
        ref={leftHorizolRef}
        className={style.horizolResizeCoverLeft}
        style={{ left: positionResize.leftHorizol - 15 }} // subtract cover div width
        onTouchStart={(e) =>
          zoomStart(
            e,
            'leftHorizol',
            minMaxResize.current.minHorizol,
            positionResize.rightHorizol - rangeMinMax,
          )
        }
        onMouseDown={(e) =>
          zoomStart(
            e,
            'leftHorizol',
            minMaxResize.current.minHorizol,
            positionResize.rightHorizol - rangeMinMax,
          )
        }
      />
      <div
        className={style.horizolResize}
        style={{ left: positionResize.rightHorizol }}
      >
        <DropdownTooltip
          containerRef={rightHorizolRef}
          isOpenTooltip={showTooltip}
          isI18n={false}
          text={useFormatDateTime(
            valueResize.finalPoint[keyXAxis],
            FORMAT.DATE,
          )}
        />
      </div>
      <div
        ref={rightHorizolRef}
        className={style.horizolResizeCoverRight}
        style={{ left: positionResize.rightHorizol - 15 }} // subtract cover div width
        onTouchStart={(e) =>
          zoomStart(
            e,
            'rightHorizol',
            positionResize.leftHorizol + rangeMinMax,
            minMaxResize.current.maxHorizol,
          )
        }
        onMouseDown={(e) =>
          zoomStart(
            e,
            'rightHorizol',
            positionResize.leftHorizol + rangeMinMax,
            minMaxResize.current.maxHorizol,
          )
        }
      />
      <div />
    </div>
  )
}

export default ZoomX
