import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { TIME_FRAME_OPTIONS } from '../constants'
import style from '../index.module.css'
import { selectDataMapping } from '../panelTable/store/slice'
import {
  handleChartName,
  selectChartName,
  selectTimeRange,
  selectYear,
} from '../store/slice'

const TopLabel = ({ isChart1, isBarPointChart }) => {
  const dispatch = useDispatch()

  const year = useSelector(selectYear)
  const timeRange = useSelector(selectTimeRange)
  const chartName = useSelector(selectChartName)
  const dataMapping = useSelector(selectDataMapping)
  const { fieldName, value, relativeValue, isPercentFormat } = chartName

  useEffect(() => {
    if (dataMapping.length && isBarPointChart) {
      const itemMapping = dataMapping.find(
        (item) => item.fieldName === fieldName,
      )
      const currentRelativeValue = isChart1
        ? itemMapping?.chart1Legend2
          ? itemMapping?.chart1Legend2
          : itemMapping.relativeIndicator
        : itemMapping?.chart2Legend2
        ? itemMapping?.chart2Legend2
        : itemMapping.relativeIndicator
      if (
        itemMapping?.indicator !== value ||
        currentRelativeValue !== relativeValue
      ) {
        dispatch(
          handleChartName({
            fieldName: itemMapping?.fieldName,
            value: itemMapping?.indicator,
            relativeValue: currentRelativeValue,
            isPercentFormat,
          }),
        )
      }
    }
  }, [])

  const timeNum = TIME_FRAME_OPTIONS.find((item) => item.value === timeRange)

  return (
    <div className={style.topLabel}>
      {isChart1 ? (
        <Span>{chartName.value}</Span>
      ) : timeRange === 'OneYear' ? (
        <Span>
          <Translate value="corporate.businessModel.segmentalAnalysis.PEERS_COMPARISON" />{' '}
          <Translate value="corporate.businessModel.segmentalAnalysis.IN" />{' '}
          {year}
        </Span>
      ) : (
        <Span>
          <Translate value="corporate.businessModel.segmentalAnalysis.PEERS_COMPARISON" />{' '}
          <Translate value="corporate.businessModel.segmentalAnalysis.FOR_THE_FIRST" />{' '}
          {timeNum?.num}{' '}
          <Translate value="corporate.businessModel.segmentalAnalysis.MONTH_OF" />{' '}
          {year}
        </Span>
      )}
    </div>
  )
}

TopLabel.propTypes = {
  isChart1: PropTypes.bool,
  isBarPointChart: PropTypes.bool,
}

TopLabel.defaultProps = {
  isBarPointChart: false,
}

export default TopLabel
