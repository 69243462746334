import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class SectorStatisticsOverviewProxy extends ProxyBase {
  getSectorsReport(params) {
    return this.post('SectorsReport', params)
  }

  getMarketCap(params) {
    return this.get('MarketCap', params)
  }

  getValuation(params) {
    return Array.isArray(params)
      ? this.retryWhileResEmpty({
          path: 'Valuation',
          paramsArr: params,
          isPostMethod: true,
        })
      : this.post('Valuation', params)
  }

  pricePerformanceAbsoluteChart(params) {
    return this.post('PricePerformance_AbsoluteChart', params)
  }

  pricePerformance(params) {
    return this.get('PricePerformance', params)
  }
}

export default new SectorStatisticsOverviewProxy(
  ServiceProxyConfig.Sector.SectorStatistics.Overview.ServiceUrl,
)
