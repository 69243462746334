import { differenceBy, isEmpty, isEqual, maxBy, uniq } from 'lodash'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import {
  FILTER_FIELD_CODES,
  TABLE_FIELD_CODES,
} from '../../../Screening/constants'
import { INDICATOR_GROUPS, TAB_LIST, TAB_TYPES } from '../../../constants'
import {
  formatAddInformation,
  formatTable,
  getSortFieldByIndicatorGroup,
  renderTableFieldCode,
} from '../../../helper'
import {
  changeColumns,
  changeTemplate,
  changeTemplateList,
  selectActiveTab,
  selectAddInformatics,
  selectDataRaw,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParams,
  selectParentSectors,
  selectTemplate,
  setFilterParameters,
} from '../../../store/slice'
import { saveTemplateData, updateTemplateByIdData } from '../../../store/thunk'
import {
  removeColumn,
  selectDataTable,
  selectFullColumnId,
} from '../../Table/store/slice'
import {
  getBondTransactionData,
  getDataBondList,
  getDataBondListIsChange,
} from '../../Table/store/thunk'
import { MAX_TEMPLATE, SAVE_TEMPLATE_TYPES } from '../../constants'

export const withFilterActions = (Component) => (props) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const templates = useSelector(selectTemplate)
  const params = useSelector(selectParams)
  const ids = useSelector(selectFullColumnId(1))
  const listParent = useSelector(selectParentSectors)
  const { indicatorGroup, ...restParams } = useSelector(selectFilterParams)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const dataTable = useSelector(selectDataTable)
  const rawData = useSelector(selectDataRaw)
  const addInformatics = useSelector(selectAddInformatics)
  const currentTab = useSelector(selectActiveTab)

  const [error, setError] = useState('')
  const [isShow, setIsShow] = useState(null)

  // Get data
  const templateList = useMemo(
    () => templates.filter((item) => !TAB_LIST.includes(item.templateId)),
    [templates.length],
  )

  const getFilterConditions = (information, value) => {
    const newItem = {
      id: uuidv4(),
      order: restParams?.conditions?.length ?? 0,
      indicatorId: information.indicatorId,
      tableCode: information.tableCode,
      conditionTableFieldCode: information.conditionTableFieldCode,
      tableFieldCode: information.tableFieldCode,
      selectionType: information.selectionType,
      selectionTypeId: information.selectionTypeId ?? '',
      conditionValues: value,
    }

    if (information.multiplier) {
      newItem.multiplier = information.multiplier
    }

    return [...restParams.conditions, newItem]
  }

  const getFilterAddedInformation = (
    information,
    indexInformation,
    addedInformation,
    value,
  ) => {
    let newAddedInformatics = addedInformation
    const max = maxBy(newAddedInformatics, (e) => e.order)

    if (indexInformation === -1 && !isEqual(value, ['0'])) {
      newAddedInformatics = [
        ...newAddedInformatics,
        {
          id: uuidv4(),
          order: (max?.order ?? 0) + 1,
          indicatorId: information.indicatorId,
          tableCode: information.tableCode,
          tableFieldCode: information.tableFieldCode,
        },
      ]
    } else if (
      isEqual(value, ['0']) &&
      indexInformation !== -1 &&
      !information?.isDefault
    ) {
      newAddedInformatics = [
        ...newAddedInformatics.slice(0, indexInformation),
        ...newAddedInformatics.slice(indexInformation + 1),
      ]
    }

    return newAddedInformatics
  }

  // Actions
  const handleSubmit = ({ name, id, type }) => {
    if (type === SAVE_TEMPLATE_TYPES.SAVE) {
      if (!name.length) {
        setError('bond.bondScreening.filter.MESSAGE1')
        return
      }

      if (templateList?.length === MAX_TEMPLATE) {
        setError('bond.bondScreening.filter.MESSAGE4')
        return
      }

      const template = templateList.find((item) => item.templateName === name)

      if (template) {
        setError('bond.bondScreening.filter.MESSAGE2')
        return
      }

      const { templateId, ...restParams } = params

      const addedInformations = restParams.parameter.addedInformations.map(
        (item) => {
          const index = ids.findIndex((id) => id === item.tableFieldCode)

          return {
            ...item,
            order: index !== -1 ? index + 2 : item.order,
          }
        },
      )

      const max = maxBy(addedInformations, (e) => e.order)

      const addedInformaticsNotDuplicate = addedInformations.filter(
        (item) => ids.findIndex((id) => id === item.tableFieldCode) !== -1,
      )

      const addedInformaticsIsDuplicate = addedInformations
        .filter(
          (item) => ids.findIndex((id) => id === item.tableFieldCode) === -1,
        )
        .map((item, index) => ({
          ...item,
          order: (max?.order ?? 0) + index + 1,
        }))

      dispatch(
        saveTemplateData({
          ...restParams,
          parameter: {
            ...restParams.parameter,
            sectors: uniq([...listParent, ...restParams.parameter.sectors]),
            addedInformations: formatAddInformation(
              [...addedInformaticsNotDuplicate, ...addedInformaticsIsDuplicate],
              ids,
            ),
          },
          templateName: name,
          templateType: indicatorGroup,
          ids,
        }),
      )

      dispatch(changeColumns({}))
    }

    if (type === SAVE_TEMPLATE_TYPES.UPDATE) {
      if (!id) {
        setError('bond.bondScreening.filter.MESSAGE3')
        return
      }

      const addedInformations = params.parameter.addedInformations.map(
        (item) => {
          const index = ids.findIndex((id) => id === item.tableFieldCode)

          return {
            ...item,
            order: index !== -1 ? index + 2 : item.order,
          }
        },
      )

      const max = maxBy(addedInformations, (e) => e.order)

      const addedInformaticsNotDuplicate = addedInformations.filter(
        (item) => ids.findIndex((id) => id === item.tableFieldCode) !== -1,
      )

      const addedInformaticsIsDuplicate = addedInformations
        .filter(
          (item) => ids.findIndex((id) => id === item.tableFieldCode) === -1,
        )
        .map((item, index) => ({
          ...item,
          order: (max?.order ?? 0) + index + 1,
        }))

      dispatch(
        updateTemplateByIdData({
          ...params,
          parameter: {
            ...params.parameter,
            sectors: uniq([...listParent, ...params.parameter.sectors]),
            addedInformations: formatAddInformation(
              [...addedInformaticsNotDuplicate, ...addedInformaticsIsDuplicate],
              ids,
            ),
          },
          templateType: indicatorGroup,
          templateId: id,
          templateName: name,
          ids,
        }),
      )

      dispatch(changeColumns({}))
    }

    setIsShow(null)
  }

  const handleChange = (value) => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)
    const newSectors =
      uniq([...listParent, ...restParams.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...restParams.sectors])
    const information = addInformatics.find(
      (item) =>
        item.indicatorGroup === indicatorGroup &&
        (item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
          item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE),
    )

    if (!information) return

    let newConditions = [],
      newAddedInformatics = [],
      newTemplate = {}

    if (raw) {
      const index = rawData.parameter.conditions.findIndex(
        (item) =>
          item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
          item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE,
      )
      const indexInformation = rawData.parameter.addedInformations.findIndex(
        (item) =>
          item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
          item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE,
      )

      if (index === -1) {
        newConditions = getFilterConditions(information, value)
        newAddedInformatics = getFilterAddedInformation(
          information,
          indexInformation,
          rawData.parameter.addedInformations,
          value,
        )
        newTemplate = {
          ...rawData,
          parameter: {
            ...restParams,
            conditions: newConditions,
            addedInformations: formatAddInformation(newAddedInformatics, ids),
            indicatorGroup,
          },
        }
      } else {
        newConditions = [
          ...rawData.parameter.conditions.slice(0, index),
          {
            ...rawData.parameter.conditions[index],
            conditionValues: value,
          },
          ...rawData.parameter.conditions.slice(index + 1),
        ]
        newAddedInformatics = getFilterAddedInformation(
          information,
          indexInformation,
          restParams.addedInformations,
          value,
        )
        newTemplate = {
          ...rawData,
          parameter: {
            ...rawData.parameter,
            conditions: newConditions,
            addedInformations: formatAddInformation(newAddedInformatics, ids),
          },
        }
      }
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: indicatorGroup,
      }
      const newTemplates = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]
      const indexConditions = restParams.conditions.findIndex(
        (item) =>
          item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
          item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE,
      )
      const indexInformation = restParams.addedInformations.findIndex(
        (item) =>
          item.indicatorGroup === indicatorGroup &&
          (item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
            item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE),
      )

      dispatch(changeTemplateList(newTemplates))

      if (indexConditions !== -1) {
        newConditions = [
          ...restParams.conditions.slice(0, indexConditions),
          {
            ...restParams.conditions[indexConditions],
            conditionValues: value,
          },
          ...restParams.conditions.slice(indexConditions + 1),
        ]
        newAddedInformatics = getFilterAddedInformation(
          information,
          indexInformation,
          restParams.addedInformations,
          value,
        )
        newTemplate = {
          ...data,
          parameter: {
            ...restParams,
            conditions: newConditions,
            addedInformations: formatAddInformation(newAddedInformatics, ids),
            indicatorGroup,
          },
        }
      } else {
        newConditions = getFilterConditions(information, value)
        newAddedInformatics = getFilterAddedInformation(
          information,
          indexInformation,
          restParams.addedInformations,
          value,
        )
        newTemplate = {
          ...data,
          parameter: {
            ...restParams,
            conditions: newConditions,
            indicatorGroup,
          },
        }
      }
    }

    const newParams = {
      ...restParams,
      sectors: newSectors,
      conditions: newConditions,
      addedInformations: formatAddInformation(newAddedInformatics, ids),
      indicatorGroup,
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(changeTemplate(newTemplate))
    dispatch(
      setFilterParameters({
        key: 'addedInformations',
        value: newAddedInformatics,
      }),
    )
    dispatch(
      setFilterParameters({
        key: 'conditions',
        value: newConditions,
      }),
    )
    dispatch(
      getDataBondList({
        params: newParams,
        redirectToBondIssuer: props.redirectToBondIssuer,
      }),
    )
  }

  const checkSortBy = (sortBy, addInformation) =>
    addInformation.some(
      (item) =>
        renderTableFieldCode(item.tableFieldCode) ===
        renderTableFieldCode(sortBy),
    )

  const handleSubmitPopupChangeColumn = (information) => {
    let array = []

    if (!!information?.length) {
      information.forEach((item) => {
        array = array.concat(item?.children ?? [])
      })
    }

    const data = array.filter((item) => item.isDefault)

    if (indicatorGroup !== INDICATOR_GROUPS.BOND_AGGREGATOR) {
      const newSectors =
        uniq([...listParent, ...restParams.sectors])?.length ===
        uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...restParams.sectors])

      const dataNotChange = restParams.addedInformations.filter((item) =>
        data.find(
          (item2) =>
            renderTableFieldCode(item2.tableFieldCode) ===
            renderTableFieldCode(item.tableFieldCode),
        ),
      )

      const max = maxBy(dataNotChange, (e) => e.order)

      const dataChange = data
        .filter(
          (item) =>
            !restParams.addedInformations.find(
              (item2) =>
                renderTableFieldCode(item2.tableFieldCode) ===
                renderTableFieldCode(item.tableFieldCode),
            ),
        )
        .map((item, index) => ({
          id: uuidv4(),
          order: (max?.order ?? 0) + index + 1,
          indicatorId: item.indicatorId,
          tableCode: item.tableCode,
          tableFieldCode: item.tableFieldCode,
        }))

      const newData = [...dataNotChange, ...dataChange]

      const dataSort = newData.find(
        (item) =>
          renderTableFieldCode(item.tableFieldCode) ===
          renderTableFieldCode(restParams.sortBy),
      )

      const newData2 = [...newData]

      if (!dataSort) {
        const newMax = maxBy(newData2, (e) => e.order)

        const newInformation = addInformatics.find(
          (item) =>
            item.indicatorGroup === indicatorGroup &&
            item.tableFieldCode === renderTableFieldCode(restParams.sortBy),
        )

        if (newInformation) {
          newData2.push({
            id: uuidv4(),
            order: (newMax?.order ?? 0) + 1,
            indicatorId: newInformation.indicatorId,
            tableCode: newInformation.tableCode,
            tableFieldCode: newInformation.tableFieldCode,
          })
        }
      }

      const newParams = {
        ...restParams,
        sectors: !isEmpty(newData2) ? newSectors : [],
        conditions: !isEmpty(newData2) ? restParams.conditions : [],
        addedInformations: formatAddInformation(newData2, ids),
        indicatorGroup,
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(
        setFilterParameters({
          key: 'addedInformations',
          value: newData,
        }),
      )
      dispatch(
        getBondTransactionData({
          params: newParams,
          addedInformations: newData,
          redirectToBondIssuer: props.redirectToBondIssuer,
        }),
      )
      setIsShow(null)

      return
    }

    const dataNotChange = restParams.addedInformations.filter((item) =>
      data.find(
        (item2) =>
          renderTableFieldCode(item2.tableFieldCode) ===
          renderTableFieldCode(item.tableFieldCode),
      ),
    )

    const dataRemove = differenceBy(
      restParams.addedInformations.map((item) => ({
        ...item,
        tableFieldCode: renderTableFieldCode(item.tableFieldCode),
      })),
      dataNotChange.map((item) => ({
        ...item,
        tableFieldCode: renderTableFieldCode(item.tableFieldCode),
      })),
      'tableFieldCode',
    )

    const max = maxBy(dataNotChange, (e) => e.order)

    const dataChange = data
      .filter(
        (item) =>
          !restParams.addedInformations.find(
            (item2) =>
              renderTableFieldCode(item2.tableFieldCode) ===
              renderTableFieldCode(item.tableFieldCode),
          ),
      )
      .map((item, index) => ({
        id: uuidv4(),
        order: (max?.order ?? 0) + index + 1,
        indicatorId: item.indicatorId,
        tableCode: item.tableCode,
        tableFieldCode: item.tableFieldCode,
      }))

    const newAddedInformatics = [...dataNotChange, ...dataChange]

    dispatch(
      setFilterParameters({
        key: 'addedInformations',
        value: [...dataNotChange, ...dataChange],
      }),
    )

    const check = checkSortBy(restParams.sortBy, [
      ...dataNotChange,
      ...dataChange,
    ])

    if (
      !check &&
      restParams.sortBy !== FILTER_FIELD_CODES.ISSUE_DATE &&
      restParams.order !== 'desc'
    ) {
      dispatch(
        setFilterParameters({
          key: 'sortBy',
          value: FILTER_FIELD_CODES.ISSUE_DATE,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'order',
          value: 'desc',
        }),
      )
    }

    dispatch(
      changeColumns({
        id: currentTab,
        data: [...dataNotChange, ...dataChange],
      }),
    )

    if (!!dataRemove.length && !dataChange.length) {
      const headerRemove = dataRemove
        .map((item) => {
          const value = dataTable.header.find(
            (item2) =>
              renderTableFieldCode(item2.indicatorCode) ===
              renderTableFieldCode(item.tableFieldCode),
          )

          if (!value) return null

          return value
        })
        .filter((item) => item)

      const newDataHeader = dataTable.header.map((item) => ({
        ...item,
        indicatorCode: renderTableFieldCode(item.indicatorCode),
      }))

      const newHeaderRemove = headerRemove.map((header) => ({
        ...header,
        indicatorCode: renderTableFieldCode(header.indicatorCode),
      }))

      const headerNotChange = []

      newDataHeader.forEach((item) => {
        const index1 = newHeaderRemove.findIndex(
          (item1) => item1.indicatorCode === item.indicatorCode,
        )

        if (index1 === -1) {
          headerNotChange.push(item)
        }
      })

      const body = dataTable.body.map((item) => {
        const bodyRemove = dataRemove
          .map((item2) => {
            const value = item.items.find(
              (item3) =>
                renderTableFieldCode(item3.indicatorCode) ===
                renderTableFieldCode(item2.tableFieldCode),
            )

            if (!value) return null

            return value
          })
          .filter((item) => item)

        const newDataBody = item.items.map((item) => ({
          ...item,
          indicatorCode: renderTableFieldCode(item.indicatorCode),
        }))

        const newBodyRemove = bodyRemove.map((header) => ({
          ...header,
          indicatorCode: renderTableFieldCode(header.indicatorCode),
        }))

        const bodyNotChange = []

        newDataBody.forEach((item) => {
          const index1 = newBodyRemove.findIndex(
            (item1) => item1.indicatorCode === item.indicatorCode,
          )

          if (index1 === -1) {
            bodyNotChange.push(item)
          }
        })

        return { ...item, items: bodyNotChange }
      })

      const newDataTable = { ...dataTable, header: headerNotChange, body }

      const formatDataTable = formatTable(
        locale,
        newDataTable,
        newAddedInformatics,
      )

      dispatch(removeColumn({ formatDataTable, newDataTable }))
      setIsShow(null)

      return
    }

    if (!!dataChange.length) {
      const newSectors =
        uniq([...listParent, ...restParams.sectors])?.length ===
        uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...restParams.sectors])

      const check = checkSortBy(restParams.sortBy, [
        ...dataNotChange,
        ...dataChange,
      ])

      if (
        !check &&
        restParams.sortBy !== FILTER_FIELD_CODES.ISSUE_DATE &&
        restParams.order !== 'desc'
      ) {
        const newParams = {
          ...restParams,
          sectors: !isEmpty(newAddedInformatics) ? newSectors : [],
          conditions: !isEmpty(newAddedInformatics)
            ? restParams.conditions
            : [],
          addedInformations: [...dataNotChange, ...dataChange],
          sortBy: getSortFieldByIndicatorGroup('', indicatorGroup),
          order: 'desc',
          indicatorGroup,
          pageSize: 300,
          pageIndex: 1,
        }

        dispatch(
          getDataBondList({
            params: newParams,
            redirectToBondIssuer: props.redirectToBondIssuer,
          }),
        )
        setIsShow(null)

        return
      }

      const checkColumn = dataChange.find(
        (item) =>
          renderTableFieldCode(item.tableFieldCode) ===
          renderTableFieldCode(restParams.sortBy),
      )

      const dataSort = dataNotChange.find(
        (item) =>
          renderTableFieldCode(item.tableFieldCode) ===
          renderTableFieldCode(restParams.sortBy),
      )

      const newParams = {
        ...restParams,
        sectors: !isEmpty(newAddedInformatics) ? newSectors : [],
        conditions: !isEmpty(newAddedInformatics) ? restParams.conditions : [],
        addedInformations: formatAddInformation(
          !checkColumn &&
            restParams.sortBy !== FILTER_FIELD_CODES.ISSUE_DATE &&
            dataSort
            ? [...dataChange, dataSort]
            : dataChange,
          ids,
        ),
        indicatorGroup,
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(
        getDataBondListIsChange({
          params: newParams,
          dataRemove: dataRemove ?? [],
          addInformatics: [...dataNotChange, ...dataChange],
          dataSort:
            !checkColumn &&
            restParams.sortBy !== FILTER_FIELD_CODES.ISSUE_DATE &&
            dataSort
              ? renderTableFieldCode(dataSort.tableFieldCode)
              : undefined,
          redirectToBondIssuer: props.redirectToBondIssuer,
        }),
      )
    }

    setIsShow(null)
  }

  return (
    <Component
      {...props}
      templateList={templateList}
      error={error}
      isShow={isShow}
      setError={setError}
      setIsShow={setIsShow}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleSubmitPopupChangeColumn={handleSubmitPopupChangeColumn}
    />
  )
}
