import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getYearQuarter } from '../../common/helper'
import { VIEW_BY } from '../constants'
import {
  getSecMarginLendingMaxPeriod,
  getSecProprietaryTreasuryMaxPeriod,
} from './thunk'

const { year: currentYear, quarter: currentQuarter } = getYearQuarter(
  new Date(),
)

const initialState = {
  typeFilter: VIEW_BY.MARGIN_LENDING,
  year: null,
  quarter: null,
}

const slice = createSlice({
  name: 'corporate/businessModel/penetration/securities',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTypeFilter: (state, action) => {
      state.typeFilter = action.payload
      state.year = null
      state.quarter = null
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    changeQuarter: (state, action) => {
      state.quarter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSecMarginLendingMaxPeriod.pending, (state) => {
      state.year = null
      state.quarter = null
    })
    builder.addCase(getSecMarginLendingMaxPeriod.fulfilled, (state, action) => {
      const quarter = action.payload
        ? action.payload.quarter === 5
          ? 0
          : action.payload.quarter
        : currentQuarter
      state.year = action.payload?.year || currentYear
      state.quarter = quarter
    })
    builder.addCase(getSecMarginLendingMaxPeriod.rejected, (state, action) => {
      state.year = currentYear
      state.quarter = currentQuarter
    })
    builder.addCase(getSecProprietaryTreasuryMaxPeriod.pending, (state) => {
      state.year = null
      state.quarter = null
    })
    builder.addCase(
      getSecProprietaryTreasuryMaxPeriod.fulfilled,
      (state, action) => {
        const quarter = action.payload
          ? action.payload.quarter === 5
            ? 0
            : action.payload.quarter
          : currentQuarter
        state.year = action.payload?.year || currentYear
        state.quarter = quarter
      },
    )
    builder.addCase(
      getSecProprietaryTreasuryMaxPeriod.rejected,
      (state, action) => {
        state.year = currentYear
        state.quarter = currentQuarter
      },
    )
  },
})

export const { resetStore, changeTypeFilter, changeYear, changeQuarter } =
  slice.actions

export const selectTypeFilter = (state) => state[slice.name].typeFilter
export const selectYear = (state) => state[slice.name].year
export const selectQuarter = (state) => state[slice.name].quarter

register(slice.name, slice.reducer)
