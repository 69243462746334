export const colorArray = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#ecffdf',
]

export const viMonthsLowerCase = [
  'Tháng 1',
  '2 tháng',
  '3 tháng',
  '4 tháng',
  '5 tháng',
  '6 tháng',
  '7 tháng',
  '8 tháng',
  '9 tháng',
  '10 tháng',
  '11 tháng',
  '12 tháng',
]

export const enMonthsLowerCase = [
  'January',
  '2 months',
  '3 months',
  '4 months',
  '5 months',
  '6 months',
  '7 months',
  '8 months',
  '9 months',
  '10 months',
  '11 months',
  '12 months',
]

export const VIEW_BY = {
  MONTHLY: 'AccMonthly',
  YEARLY: 'Yearly',
}

export const listTimeFilter = [
  {
    label: 'economy.fdi.overview.YEARLY',
    value: VIEW_BY.YEARLY,
  },
  {
    label: 'economy.fdi.overview.MONTHLY',
    value: VIEW_BY.MONTHLY,
  },
]
