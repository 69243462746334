import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import { PanelExportChart } from './exportChart/PanelExportChart'
import { PanelImportChart } from './importChart/PanelImportChart'
import { PanelNewsAndReport } from './newsAndReport/PanelNewsAndReport'
import { PanelOverview } from './overview/PanelOverview'
import { PanelTopMarketChart } from './topMarketChart/PanelTopMarketChart'

const MAP_KEY = {
  OVERVIEW: 'OVERVIEW',
  NEWS_REPORT: 'NEWS AND REPORT',
  EXPORT_CHART: 'EXPORT CHART',
  IMPORT_CHART: 'IMPORT CHART',
  TOP_MARKET_CHART: 'TOP MARKET CHART',
}

const MIN_SIZE = {
  [`${MAP_KEY.OVERVIEW}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.NEWS_REPORT}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.EXPORT_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.IMPORT_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 - COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.TOP_MARKET_CHART}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.4) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.OVERVIEW}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.OVERVIEW}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.OVERVIEW}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NEWS_REPORT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.NEWS_REPORT,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.EXPORT_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.IMPORT_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.TOP_MARKET_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.NEWS_REPORT}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.NEWS_REPORT}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.NEWS_REPORT}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.EXPORT_CHART}`]: {
    width: `calc(33% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.EXPORT_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.EXPORT_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.IMPORT_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.IMPORT_CHART}`]: {
    width: `calc(33% - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(33% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.IMPORT_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.IMPORT_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 3.3) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.TOP_MARKET_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOP_MARKET_CHART}`]: {
    width: `calc(34% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(66% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.TOP_MARKET_CHART}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.TOP_MARKET_CHART}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 6.6) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelOverview
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OVERVIEW}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelNewsAndReport
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NEWS_REPORT}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelExportChart
          panelRefs={panelRefs}
          panelKey={MAP_KEY.EXPORT_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelImportChart
          panelRefs={panelRefs}
          panelKey={MAP_KEY.IMPORT_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelTopMarketChart
          panelRefs={panelRefs}
          panelKey={MAP_KEY.TOP_MARKET_CHART}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
