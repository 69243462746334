import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { ALL_TYPE } from '../../../../common/news/constants'
import { getFilterDate } from '../../../../common/news/helper'
import { ReportDownload } from '../../../../common/news/reports/ReportDownload'
import { ReportIssuer } from '../../../../common/news/reports/SectorReportIssuer'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  changePage,
  resetReport,
  selectReCalcWidths,
  selectReport,
  selectReportFilterData,
  selectReportIds,
  selectReportLoading,
  selectReportTypesAndSourcesLoading,
} from '../../store/slice'
import { getListReport } from '../../store/thunk'
import UseTimeZone from './../../../../common/hooks/useTimeZone'
import { ReportFilter } from './ReportFilter'

const MAP_KEY = {
  DATE: 'publicDateId',
  ISSUER: 'ticker',
  TITLE: 'title',
  ACTION: 'reportURL',
}

export const MarketReportTable = () => {
  const dispatch = useDispatch()

  const timeZone = UseTimeZone()
  const reportIds = useSelector(selectReportIds)
  const isLoading = useSelector(selectReportLoading)
  const isReportTypesAndSourcesLoading = useSelector(
    selectReportTypesAndSourcesLoading,
  )
  const filterData = useSelector(selectReportFilterData)
  const reCalcWidths = useSelector(selectReCalcWidths)

  useEffect(() => {
    return () => {
      dispatch(resetReport())
    }
  }, [])

  useEffect(() => {
    const dates = getFilterDate(
      filterData.time,
      filterData.startDate,
      filterData.endDate,
    )

    if (dates.startDate && !dates.endDate) {
      return null
    }

    dispatch(
      getListReport({
        Page: filterData.page,
        PageSize: 50,
        ReportTypeId:
          filterData.category !== ALL_TYPE ? filterData.category : undefined,
        SourceId:
          filterData.source !== ALL_TYPE ? filterData.source : undefined,
        StartDate: dates.startDate,
        EndDate: dates.endDate,
      }),
    )
  }, [filterData, timeZone])

  const handleChangePage = (page) => {
    dispatch(changePage(page))
  }

  return (
    <>
      <Table
        ids={reportIds}
        isLoading={isLoading || isReportTypesAndSourcesLoading}
        schema={Object.keys(MAP_KEY).map((key, index) => {
          const result = {
            colId: MAP_KEY[key],
            title: 'market.newsOrReport.' + key,
          }

          if (index === 0) {
            return {
              ...result,
              render: (data) => {
                return <DateTimeSpan date={data} />
              },
            }
          }

          if (index === 1) {
            return {
              ...result,
              thTooltip: 'market.newsOrReport.' + key + '_TOOLTIP',
              isI18nThTooltip: true,
              render: (data, rowId) => {
                return (
                  <ReportIssuer
                    data={data}
                    rowId={rowId}
                    selectReport={selectReport}
                  />
                )
              },
            }
          }

          if (index === 2) {
            return {
              ...result,
              canCustomTd: true,
              render: (data) => {
                return (
                  <td style={{ width: '100%', maxWidth: 0 }}>
                    <TextEllipsis text={data || ''} isI18n={false} />
                  </td>
                )
              },
            }
          }

          if (index === 3) {
            return {
              ...result,
              render: (data) => <ReportDownload data={data} />,
            }
          }

          return result
        })}
        getDataFromRedux={selectReport}
        hasFooter={false}
        hasPagination
        currentPage={filterData.page}
        totalPage={filterData.totalPage}
        handleChangePage={handleChangePage}
        renderRightContent={() => <ReportFilter />}
        reCalcWidths={reCalcWidths}
      />
    </>
  )
}
