import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { fetchMoreIssuerListData, getIssuerListData } from './thunk'

const initialState = {
  filter: {
    page: 1,
    pageSize: 20,
    enableInfinity: true,
  },
  data: [],
  loading: true,
}

export const slice = createSlice({
  name: 'bond/bondScreening/searchIssuer',
  initialState,
  reducers: {
    // restore to default state
    resetCreateBondStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    setLoadingSearchIssuer: (state, action) => {
      state.loading = action.payload
    },
    setIssuerData: (state, action) => {
      const { data, page, pageSize, enableInfinity } = action.payload

      state.filter = {
        ...state.filter,
        page: page + 1,
        pageSize: (page + 1) * pageSize,
        enableInfinity,
      }

      state.data = data
      state.loading = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIssuerListData.pending, (state, action) => {})
    builder.addCase(getIssuerListData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      state.filter = {
        ...state.filter,
        page: 2,
        enableInfinity: hasNextPage,
      }

      state.data = data
      state.loading = false
    })
    builder.addCase(getIssuerListData.rejected, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(fetchMoreIssuerListData.pending, (state, action) => {})
    builder.addCase(fetchMoreIssuerListData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      state.filter = {
        ...state.filter,
        page: state.filter.page + 1,
        enableInfinity: hasNextPage,
      }

      state.data = [...state.data, ...data]
    })
    builder.addCase(fetchMoreIssuerListData.rejected, (state, action) => {})
  },
})

export const selectFilterSearchIssuer = (state) => state[slice.name].filter
export const selectLoadingSearchIssuer = (state) => state[slice.name].loading
export const selectDataSearchIssuer = (state) => state[slice.name].data

export const { resetCreateBondStore, setIssuerData, setLoadingSearchIssuer } =
  slice.actions

register(slice.name, slice.reducer)
