import React from 'react'
import InvestmentStructureFDI from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

const InvestmentStructureFDIPanel = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  return (
    <Panel
      title={
        'economy.fdi.fdiByCountries.COMPOSITION_OF_FDI_INFLOWS_BY_ORIGIN_COUNTRY'
      }
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'CompositionInTotalFDIChartId',
        nameScreen: UseI18n('economy.fdi.fdiByCountries.TITLE_SCREEN'),
        chartName: UseI18n(
          'economy.fdi.fdiByCountries.COMPOSITION_OF_FDI_INFLOWS_BY_ORIGIN_COUNTRY',
        ),
      }}
      titleJpg={UseI18n(
        'economy.fdi.fdiByCountries.COMPOSITION_OF_FDI_INFLOWS_BY_ORIGIN_COUNTRY',
      )}
    >
      <InvestmentStructureFDI
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default InvestmentStructureFDIPanel
