import { Translate } from 'react-redux-i18n'
import DropdownSector from './DropdownSector'

const Sector = ({
  ICBs,
  ICBsTree,
  listChecked,
  listParentChecked,
  loading,
}) => {
  return (
    <>
      <Translate
        value="tool.smartScreening.SECTOR_LOW"
        style={{ fontSize: 11, opacity: 0.4 }}
        className="mb-8 d-block"
      />
      <div className="input-dropdown mb-8">
        <DropdownSector
          ICBs={ICBs}
          ICBsTree={ICBsTree}
          listChecked={listChecked}
          listParentChecked={listParentChecked}
          loading={loading}
        />
      </div>
    </>
  )
}

export default Sector
