import OutstandingBondProxy from '../../../../proxies/bond/overview/outStandingBons/'
import { ServiceBase } from '../../../ServiceBase'

export class OutStandingBons extends ServiceBase {
  getDataChart(params) {
    return this.getData(() => OutstandingBondProxy.getDataChart(params))
  }
}

export default new OutStandingBons()
