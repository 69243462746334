export const formatChartData = {
  closePrice: 10 ** 3,
  highestPrice: 10 ** 3,
  buyPercentage: 0.01,
  sellPercentage: 0.01,
  holdPercentage: 0.01,
  neutralPercentage: 0.01,
  noRecommendationPercentage: 0.01,
}

export const tableSchema = {
  TICKER: 'ticker',
  DATE: 'reportDateId',
  REPORT: 'title',
  RECOMMENDATION_TYPE_NAME: 'recommendationTypeName',
  PRICE_TARGET: 'highestPrice',
  DFCP: 'dfcp',
  DOWNLOAD_REPORT: 'reportURL',
}

export const tableFormatData = {
  [tableSchema.PRICE_TARGET]: 10 ** 3,
  [tableSchema.DFCP]: 0.01,
}

export const recommendationTypeConstants = {
  BUY: 'buy',
  HOLD: 'hold',
  NEUTRAL: 'neutral',
  NO_RECOMMENDATION: 'noRecommendation',
  SELL: 'sell',
}

export const recommendationTypeById = {
  1: recommendationTypeConstants.BUY,
  3: recommendationTypeConstants.HOLD,
  7: recommendationTypeConstants.NEUTRAL,
  8: recommendationTypeConstants.NO_RECOMMENDATION,
  11: recommendationTypeConstants.SELL,
}

export const recommendationTypeColor = {
  [recommendationTypeConstants.BUY]: '#3da967',
  [recommendationTypeConstants.HOLD]: '#5aea78',
  [recommendationTypeConstants.NEUTRAL]: '#fdf0a5',
  [recommendationTypeConstants.NO_RECOMMENDATION]: '#f58d04',
  [recommendationTypeConstants.SELL]: '#e0505b',
}
