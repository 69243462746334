import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { changeTemplateList, selectTemplateMore } from '../../store/slice'
import PopupMoreTemplate from '../Popup/PopupMoreTemplate'
import style from './index.module.css'

const TabItemMore = ({ handleDelete, handleEdit, handleClickTab }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()
  const templatesMore = useSelector(selectTemplateMore)

  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleClose = () => {
    setIsShowDropdown(false)
  }

  const handleClick = (template) => {
    const index = templatesMore.findIndex(
      (item) => item.templateId === template?.templateId,
    )

    if (index <= 6) {
      handleClickTab(template?.templateId)
      return
    }

    const newTemplates = templatesMore.filter(
      (item) => item?.templateId !== template?.templateId,
    )

    dispatch(
      changeTemplateList([
        ...newTemplates.slice(0, 6),
        template,
        ...newTemplates.slice(6),
      ]),
    )

    handleClickTab(template?.templateId)
  }

  return (
    <a
      className={`${style.tabLink}`}
      ref={dropdownRef}
      onClick={() => setIsShowDropdown(true)}
      style={{ marginLeft: 12 }}
    >
      <Span style={{ overflow: 'hidden', fontSize: 12 }}>
        <TextEllipsis text="bond.bondScreening.filter.MORE" />
      </Span>
      <PopupMoreTemplate
        isShowDropdown={isShowDropdown}
        dropdownRef={dropdownRef}
        handleClose={handleClose}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        handleClick={handleClick}
      />
    </a>
  )
}

export default TabItemMore
