import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../common/ScrollComponent'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { removeAccents, removeSpecialCharacter } from '../../../utils/Search'
import { selectLoadingIndicators } from '../../store/slice'
import MetricItemTree from './MetricItemTree'
import style from './style.module.css'

const SCROLL_DIV_ID = 'SCROLL_METRIC_TREE'

const Metric = ({
  treeData,
  category,
  indicator,
  setIndicator,
  keyword,
  typeIndicator,
  setItemChoose,
}) => {
  const loading = useSelector(selectLoadingIndicators)

  const getIndicatorsWithCategory = () => {
    if (keyword.trim()) {
      return Object.keys(treeData[category]).reduce((obj, key) => {
        const item = treeData[category][key]
        const isValid = removeSpecialCharacter(removeAccents(item.name))
          ?.toLowerCase()
          .includes(
            removeSpecialCharacter(removeAccents(keyword)).trim().toLowerCase(),
          )
        return isValid ? { ...obj, [key]: item } : obj
      }, {})
    } else {
      return treeData[category]
    }
  }

  const getIndicatorsWithoutCategory = () => {
    if (keyword.trim()) {
      return Object.values(treeData).reduce(
        (obj, item) => ({
          ...obj,
          ...Object.keys(item).reduce((childObj, key) => {
            const isValid = removeSpecialCharacter(
              removeAccents(item[key].name),
            )
              ?.toLowerCase()
              .includes(
                removeSpecialCharacter(removeAccents(keyword))
                  .trim()
                  .toLowerCase(),
              )
            return isValid ? { ...childObj, [key]: item[key] } : childObj
          }, {}),
        }),
        {},
      )
    } else {
      return {}
    }
  }

  useEffect(() => {
    const idItemScroll = indicator ? indicator.split('_')[0] : null
    setTimeout(() => {
      const scrollTree = document.querySelector(
        `#${SCROLL_DIV_ID} .scrollbars div:first-child`,
      )
      const activeIndicator = scrollTree?.querySelector(
        `[id="${idItemScroll}"]`,
      )
      if (
        scrollTree &&
        activeIndicator &&
        activeIndicator.offsetTop + activeIndicator.offsetHeight >
          scrollTree.offsetHeight
      ) {
        scrollTree.scrollTo({
          top: activeIndicator.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }, 600)
  }, [])

  const getIndicators = useMemo(() => {
    if (!category) {
      return getIndicatorsWithoutCategory()
    } else {
      return getIndicatorsWithCategory()
    }
  }, [treeData, category, keyword])

  return (
    <div className={style.metric}>
      <div className={style.categoryList} id={SCROLL_DIV_ID}>
        <ScrollComponent bgColor="#D9D9D9">
          {loading ? (
            <Loading isBlack />
          ) : getIndicators && Object.values(getIndicators).length ? (
            <>
              {Object.values(getIndicators).map((item) => {
                return (
                  <MetricItemTree
                    key={item.id}
                    item={item}
                    indicator={indicator}
                    setIndicator={setIndicator}
                    category={category}
                    typeIndicator={typeIndicator}
                    setItemChoose={setItemChoose}
                  />
                )
              })}
            </>
          ) : (
            <NoData />
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default Metric
