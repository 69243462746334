import { I18n } from 'react-redux-i18n'
import { periodSettingEconomy } from '../../../common/dataExplorer/popupIndicator/constant'
import { nullValue } from '../../../common/dataExplorer/popupIndicator/SettingEconomy'
import { EMPTY_VALUE } from '../../../constants/Common'
import { uuid } from '../../../utils/Common'

export const getHeaders = (columnId, indicatorById) => {
  return columnId.map((id, index) => {
    return {
      order: index + 1,
      alias: id,
      value:
        indicatorById[id].indicatorName +
        (indicatorById[id].unit ? '\n' + indicatorById[id].unit : ''),
    }
  })
}

const checkNull = (value) => {
  if (value === nullValue) {
    return null
  }
  return value
}

export const convertCondition = (condition) => {
  if (!condition) {
    return {}
  }
  const detail = condition?.[condition.type]?.[condition.period]
  switch (condition.period) {
    case periodSettingEconomy.DAILY:
      return {
        conditionType: 'Daily',
        condition: {
          fromDate: detail.fromDate,
          toDate: detail.toDate || detail.fromDate,
        },
      }
    case periodSettingEconomy.WEEKLY:
      return {
        conditionType: 'Weekly',
        condition: {
          week: checkNull(detail.week),
          fromYear: detail.fromYear,
          toYear: detail.toYear || detail.fromYear,
        },
      }
    case periodSettingEconomy.MONTHLY:
      return {
        conditionType: 'Monthly',
        condition: {
          month: checkNull(detail.month),
          fromYear: detail.fromYear,
          toYear: detail.toYear || detail.fromYear,
        },
      }
    case periodSettingEconomy.QUARTERLY:
      return {
        conditionType: 'Quarterly',
        condition: {
          quarter: checkNull(detail.quarter),
          fromYear: detail.fromYear,
          toYear: detail.toYear || detail.fromYear,
        },
      }
    case periodSettingEconomy.YEARLY:
      return {
        conditionType: 'Yearly',
        condition: {
          fromYear: detail.fromYear,
          toYear: detail.toYear || detail.fromYear,
        },
      }
    default:
      return null
  }
}

export const convertParamGetResultEconomy = (
  conditionType,
  condition,
  columnId,
  columnById,
) => {
  let order = 0
  const conditionGroups = [
    {
      groupId: uuid(),
      order: 1,
      conditionType,
      condition,
      indicators: columnId.map((id) => {
        order++
        const indicator = columnById[id]
        return {
          order,
          indicatorId: indicator.indicatorId,
          indicatorType: indicator.indicatorType,
          alias: indicator.indicatorId,
        }
      }),
    },
  ]

  return columnId.length
    ? {
        dataIds: [],
        conditionGroups,
      }
    : null
}

export const getTimeDataPeriod = (period) => {
  if (period) {
    return period.split('|')[0]
  }
  return EMPTY_VALUE
}

const newLine = '\n'
const space = ': '

export const getHeaderIndicator = (indicator, indicatorById) => {
  return (
    indicatorById[indicator.indicatorId]?.indicatorName +
    (indicator.unit
      ? newLine +
        I18n.t('tool.dataExplorer.corporate.UNIT') +
        space +
        indicator.unit
      : '')
  )
}
