import { useSelector } from 'react-redux'
import { selectStockInfoById } from '../../../../../common/masterData/store/slice'
import TextEllipsis from '../../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../../constants/Common'
import style from './company.module.css'

const TickerName = ({ organizationId }) => {
  const ticker = useSelector(selectStockInfoById(organizationId, 'ticker'))
  const taxCode = useSelector(selectStockInfoById(organizationId, 'taxCode'))
  const companyName = useSelector(
    selectStockInfoById(organizationId, 'organizationShortName'),
  )

  return (
    <>
      <div className={style.tickerCol}>
        <TextEllipsis
          isI18n={false}
          text={(ticker === EMPTY_VALUE ? '' : ticker) || taxCode || ''}
        />
      </div>
      <div className={style.tickerNameCol}>
        <TextEllipsis isI18n={false} text={companyName || ''} />
      </div>
    </>
  )
}

export default TickerName
