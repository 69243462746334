import { ReactComponent as IconDownloadBlack } from '../../icon/icon-download-black.svg'
import { ReactComponent as IconTable } from '../../icon/icon-table.svg'
import { useTableDownloadCsv } from '../useTableDownloadCsv'
import style from './style.module.css'

const Header = ({
  dataChartPreview,
  setPreviewChart,
  changeShowTable,
  onDownloadChart,
}) => {
  const { IconDownloadCsv } = useTableDownloadCsv()

  const handlePreviewChart = () => {
    setPreviewChart(false)
  }

  return (
    <div className={style.header}>
      <IconTable
        style={{ height: 18, cursor: 'pointer', marginRight: 16 }}
        onClick={changeShowTable}
      />
      <div style={{ marginRight: 20 }} onClick={handlePreviewChart}>
        <i
          className="icon-minus cursor-pointer"
          style={{ fontSize: 16, color: 'rgb(78, 78, 78)' }}
        />
      </div>
      <IconDownloadCsv
        chartSettings={dataChartPreview.detail}
        style={{ marginRight: 16 }}
      />
      <IconDownloadBlack
        style={{ marginRight: 16 }}
        className="cursor-pointer"
        onClick={onDownloadChart}
      />
    </div>
  )
}

export default Header
