export const TYPE_PARENT = {
  ALL: 'All',
  STEEL_COAT: 'SteelCoat',
  STEEL_PIPE: 'SteelPipe',
  STEEL_COIL: 'SteelCoil',
  STEEL_CONSTRUCTION: 'SteelConstruction',
}
export const PARENT_ID_MAPPING = [
  {
    value: 1,
    title: TYPE_PARENT.ALL,
  },
  {
    value: 2,
    title: TYPE_PARENT.STEEL_CONSTRUCTION,
  },
  {
    value: 6,
    title: TYPE_PARENT.STEEL_PIPE,
  },
  {
    value: 9,
    title: TYPE_PARENT.STEEL_COIL,
  },
  {
    value: 13,
    title: TYPE_PARENT.STEEL_CONSTRUCTION,
  },
]
