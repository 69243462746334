import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../../common/textEllipsis'
import CoppyLink from './CoppyLink'
import EditName from './EditName'
import style from './index.module.css'

const TemplateItem = ({
  styleComponent,
  item,
  currentItemRef,
  isActiveSelected,
  handleSelect,
  turnOffActiveSelected,
  isI18n,
  heightItem,
  value,
  setCurrentTemplate,
  handleShowDelete,
  dropdownRef,
}) => {
  const itemRef = useRef()

  const [isShowEdit, setIsShowEdit] = useState(false)
  const [isDropdownTop, setIsDropdownTop] = useState(false)

  const handleShowEdit = () => {
    setIsShowEdit(true)
  }

  const handleOverBtnMore = () => {
    if (dropdownRef.current && itemRef.current) {
      const heightFromBottom =
        dropdownRef.current.getBoundingClientRect().bottom -
        itemRef.current.getBoundingClientRect().bottom
      setIsDropdownTop(heightFromBottom < 60)
    }
  }

  const handleClickDeleteBtn = () => {
    setCurrentTemplate(item)
    handleShowDelete()
  }

  const getStyleDropdown = () => {
    if (isDropdownTop) {
      return { bottom: 20 }
    } else {
      return { top: 20 }
    }
  }

  return (
    <>
      <li
        ref={value === item.value ? currentItemRef : undefined}
        className={`d-flex h-20 ${style.liDropdown} ${styleComponent.li} ${
          value === item.value && isActiveSelected ? styleComponent.active : ''
        } ${isShowEdit ? style.liDropdownEditing : ''}`}
        style={{ height: `${heightItem}px` }}
        onMouseOver={turnOffActiveSelected}
      >
        {isShowEdit ? (
          <EditName
            item={item}
            setIsShowEdit={setIsShowEdit}
            isDropdownTop={isDropdownTop}
          />
        ) : (
          <>
            <div
              onClick={() => handleSelect(item.value)}
              className={`${styleComponent.textDropdown} ${style.textDropdown}`}
            >
              <TextEllipsis
                isI18n={isI18n}
                text={item.name}
                isHardWidth={false}
              />
            </div>
            <div
              ref={itemRef}
              className={style.btnMore}
              onMouseOver={handleOverBtnMore}
            >
              <i className="icon-menu-dot-hor" />
              <div className={style.dropdownMore} style={getStyleDropdown()}>
                <ul>
                  <li onClick={handleShowEdit}>
                    <button className="cursor-pointer">
                      <i
                        className="icon-edit"
                        style={{ display: 'inline-block', width: '8px' }}
                      />
                      <Translate value="sector.sectorConstituents.segmentation.RENAME" />
                    </button>
                  </li>
                  <li>
                    <CoppyLink item={item} />
                  </li>
                  <li onClick={handleClickDeleteBtn}>
                    <button className="cursor-pointer">
                      <i
                        className="icon-trash"
                        style={{ display: 'inline-block', width: '8px' }}
                      />
                      <Translate value="sector.sectorConstituents.segmentation.DELETE" />
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}
      </li>
    </>
  )
}

export default TemplateItem
