import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import ExpectedCash from './expectedCash'
import IssuesPlan from './issuesPlan'
import LatePayment from './latePayment'
import OutStandingBons from './outStandingBons'
import TopIssuerRelease from './topIssuerRelease'
import ValueOfBonds from './valueOfBonds'

const MAP_KEY = {
  TOP_ISSUER_RELEASE: 'TOP_ISSUER_RELEASE',
  AVERAGE_COUPON_BY_SECTOR: 'AVERAGE_COUPON_BY_SECTOR',
  ISSUES_PLAN: 'ISSUES_PLAN',
  OUT_STANDING_BONDS: 'OUT_STANDING_BONDS',
  LATE_PAYMENT: 'LATE_PAYMENT',
  EXPECTED_CASH: 'EXPECTED_CASH',
}

const MAP_SIZE = {
  [`${MAP_KEY.TOP_ISSUER_RELEASE}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: false,
    verticalResize: [
      {
        key: MAP_KEY.AVERAGE_COUPON_BY_SECTOR,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.AVERAGE_COUPON_BY_SECTOR,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.ISSUES_PLAN,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.OUT_STANDING_BONDS,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.LATE_PAYMENT,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.EXPECTED_CASH,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [`${MAP_KEY.AVERAGE_COUPON_BY_SECTOR}`]: {
    width: `calc(100% / 3 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.ISSUES_PLAN,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.ISSUES_PLAN}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50% - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: 'calc(100% * 2 / 3)',
    minWidth: COMPONENT.MIN_SIZE.WIDTH,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.OUT_STANDING_BONDS}`]: {
    width: `calc(100% / 3 - (${3.5 * COMPONENT.MARGIN}px))`,
    height: `calc(50%)`,
    top: `calc(50%)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 0,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.LATE_PAYMENT,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.LATE_PAYMENT}`]: {
    width: `calc(100% / 3 - (${3.5 * COMPONENT.MARGIN}px))`,
    height: `calc(50%)`,
    top: `calc(50%)`,
    left: `calc(100% / 3)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 3.5 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH,
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.EXPECTED_CASH,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [`${MAP_KEY.EXPECTED_CASH}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%)`,
    top: `calc(50%)`,
    left: `calc(100% * 2 / 3)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <TopIssuerRelease
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOP_ISSUER_RELEASE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ValueOfBonds
        panelRefs={panelRefs}
        panelKey={MAP_KEY.AVERAGE_COUPON_BY_SECTOR}
        sizes={sizes}
        setSizes={setSizes}
      />
      <IssuesPlan
        panelRefs={panelRefs}
        panelKey={MAP_KEY.ISSUES_PLAN}
        sizes={sizes}
        setSizes={setSizes}
      />
      <OutStandingBons
        panelRefs={panelRefs}
        panelKey={MAP_KEY.OUT_STANDING_BONDS}
        sizes={sizes}
        setSizes={setSizes}
      />
      <LatePayment
        panelRefs={panelRefs}
        panelKey={MAP_KEY.LATE_PAYMENT}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ExpectedCash
        panelRefs={panelRefs}
        panelKey={MAP_KEY.EXPECTED_CASH}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
