import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import {
  addTicker,
  deleteTickerByUncheck,
  keys,
  resetTickerFilter,
  selectListTicker,
  selectLoading,
  selectTickerFilter,
} from '../../../../common/dataExplorer/corporate/store/slice'
import {
  getListTickerExchangeThunk,
  getListTickerThunk,
} from '../../../../common/dataExplorer/corporate/store/thunk'
import UseI18n from '../../../../common/hooks/useI18n'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import SelectCustom from '../../../../common/selectCustom'
import { SizeTracker } from '../../../../common/sizeTracker'
import TextEllipsis from '../../../../common/textEllipsis'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import style from '../index.module.css'
import HandleFilterTickerInSector from './HandleFilterTickerInSector'

const valueAll = 'all'

const exchangeSelect = [
  { value: 2, name: 'HOSE' },
  { value: 44, name: 'HNX' },
  { value: 101, name: 'UPCoM' },
  { value: 65, name: 'OTC' },
  { value: 68, name: 'Private' },
  {
    value: valueAll,
    name: <Translate value="sector.sectorConstituents.ratio.ALL_EXCHANGE" />,
  },
]

const ItemTicker = ({ item, handleCheckItem, listChecked }) => {
  const isChecked = listChecked.includes(item.organizationId)

  const onRowClick = () => {
    handleCheckItem(!isChecked, item)
  }

  return (
    <div className={style.itemTicker} onClick={onRowClick}>
      <input
        readOnly
        type="checkbox"
        className={`checkbox ${style.checkboxListTicker}`}
        checked={isChecked}
      />
      <div
        className={`${style.tickerTaxcode} ${
          isChecked ? style.tickerTaxcodeActive : ''
        }`}
      >
        <TextEllipsis text={item.tickerOrTaxCode} isI18n={false} />
      </div>
      <div className={style.tickerShortName}>
        <TextEllipsis text={item.organizationShortName} isI18n={false} />
      </div>
    </div>
  )
}

const ListTicker = ({ valueSearch }) => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)
  const listTicker = useSelector(selectListTicker)
  const tickerFilter = useSelector(selectTickerFilter)
  const locale = useSelector((state) => state.i18n.locale)
  const loading = useSelector(selectLoading(keys.LIST_TICKER))

  const [listTickerFilter, setListTickerFilter] = useState([])
  const [listChecked, setListChecked] = useState([])

  useEffect(() => {
    if (!tickerFilter) {
      setListTickerFilter(
        listTicker.filter((ticker) =>
          ticker.tickerOrTaxCode
            ?.toLowerCase()
            .includes(valueSearch.toLowerCase()),
        ),
      )
    } else {
      setListTickerFilter(
        listTicker.filter(
          (ticker) =>
            tickerFilter?.[ticker.organizationId] &&
            ticker.tickerOrTaxCode
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()),
        ),
      )
    }
  }, [listTicker, tickerFilter, valueSearch])

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(getListTickerThunk({ ICBId: sectorInfo.icbId }))
      setListChecked([])
      dispatch(deleteTickerByUncheck(listChecked))
    }
  }, [sectorInfo.icbId, locale])

  const handleCheckAll = () => {
    dispatch(addTicker(listTickerFilter))
    setListChecked(
      listChecked.concat(
        listTickerFilter.map((ticker) => ticker.organizationId),
      ),
    )
  }

  const handleUncheckAll = () => {
    const objCheck = {}
    dispatch(
      deleteTickerByUncheck(
        listTickerFilter
          .filter((ticker) => {
            const filter = listChecked.includes(ticker.organizationId)
            if (filter) {
              objCheck[ticker.organizationId] = true
            }
            return filter
          })
          .map((ticker) => ticker.organizationId),
      ),
    )
    setListChecked(listChecked.filter((id) => !objCheck[id]))
  }

  const handleCheckItem = (condition, item) => {
    if (condition) {
      setListChecked(listChecked.concat([item.organizationId]))
      dispatch(addTicker([item]))
    } else {
      dispatch(deleteTickerByUncheck([item.organizationId]))
      setListChecked(listChecked.filter((id) => id !== item.organizationId))
    }
  }

  const isDeleteAll = listTickerFilter.some((ticker) =>
    listChecked.includes(ticker.organizationId),
  )

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <HandleFilterTickerInSector
              listTicker={listTicker}
              setListChecked={setListChecked}
            />
            <div className={`d-flex j-b align-item-start ${style.pb8}`}>
              {isDeleteAll ? (
                <div className={style.btnMinus}>
                  <button onClick={handleUncheckAll} className="cursor-pointer">
                    -
                  </button>
                </div>
              ) : (
                <label
                  className={`d-flex justify-content-center ${style.checkboxAll}`}
                >
                  <input
                    type="checkbox"
                    className={`checkbox line cursor-pointer ${style.inputCheckAll}`}
                    checked={false}
                    onClick={handleCheckAll}
                    readOnly
                  />
                </label>
              )}
            </div>
            {size.height && (
              <div
                className="position-relative"
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                {loading && <Loading />}
                <ScrollComponent>
                  <div>
                    {listTickerFilter.map((ticker) => (
                      <ItemTicker
                        key={ticker.organizationId}
                        item={ticker}
                        handleCheckItem={handleCheckItem}
                        listChecked={listChecked}
                      />
                    ))}
                  </div>
                </ScrollComponent>
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

const TickerFilter = () => {
  const dispatch = useDispatch()

  const [exchange, setExchange] = useState(valueAll)
  const [valueSearch, setValueSearch] = useState('')

  const textSearch = UseI18n('sector.sectorConstituents.ratio.SEARCH')

  const handleChangeSearch = (e) => {
    setValueSearch(e.target.value)
  }

  const handleChangeExchange = (value) => {
    setExchange(value)
    if (value === valueAll) {
      dispatch(resetTickerFilter())
    } else {
      dispatch(getListTickerExchangeThunk({ GroupId: value }))
    }
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <div>
              <Span
                style={{ fontSize: 11 }}
                className={`${style.disabled} ${style.mt4}`}
              >
                <Translate value="sector.sectorConstituents.ratio.EXCHANGE" />
              </Span>
              <div className={style.mt4}>
                <SelectCustom
                  value={exchange}
                  selectData={exchangeSelect}
                  handleChange={handleChangeExchange}
                />
              </div>
            </div>
            <div className={style.mt24}>
              <Span style={{ fontSize: 11 }} className={style.disabled}>
                <Translate value="sector.sectorConstituents.ratio.COMPANY" />
              </Span>
              <div className={style.mt4}>
                <div className={`form-search-inbox ${style.searchInput}`}>
                  <Input
                    type="text"
                    className="search-inbox bg-grey h-20"
                    placeholder={textSearch}
                    onChange={handleChangeSearch}
                    value={valueSearch}
                  />
                  <button type="button">
                    <i className="icon-search-2" />
                  </button>
                </div>
              </div>
            </div>
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <ListTicker valueSearch={valueSearch} />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TickerFilter
