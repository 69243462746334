import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { selectIsShowPopup } from '../../../../myWatchlist/store/slice'
import { changeFilterWatchList, selectWatchList } from '../../store/slice'
import { getWatchListThunk } from '../../store/thunk'
import style from './index.module.css'
import LinkCreateWatchList from './LinkCreateWatchList'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const DropdownBox = ({ isShow, parentRef, handleHide, exceptOutsideRef }) => {
  const dispatch = useDispatch()

  const [keyword, setKeyword] = useState('')
  const [activeIndex, setActiveIndex] = useState(0)

  const watchListData = useSelector(selectWatchList)
  const isShowPopupWatchList = useSelector(selectIsShowPopup)

  const watchList = useMemo(() => {
    if (keyword) {
      return watchListData.filter((item) =>
        item.watchListName.toLowerCase().includes(keyword.toLowerCase()),
      )
    }
    return watchListData
  }, [keyword, watchListData])

  const handleChangeKeyword = (e) => {
    setKeyword(e.target.value)
    setActiveIndex(0)
  }

  const handleSelectWatchListItem = (item, index) => {
    setActiveIndex(index)
    dispatch(changeFilterWatchList(item))
    handleHide()
  }

  useEffect(() => {
    if (isShowPopupWatchList === false) {
      dispatch(getWatchListThunk())
    }
  }, [isShowPopupWatchList])

  useEffect(() => {
    const handlePressKey = (e) => {
      const watchListLength = watchList.length
      if (e?.keyCode === keyCode.ENTER && activeIndex) {
        handleSelectWatchListItem(watchList[activeIndex], activeIndex)
      }
      if (
        e?.keyCode === keyCode.DOWN_ARROW &&
        watchListLength > 0 &&
        activeIndex < watchListLength - 1
      ) {
        setActiveIndex((activeIndex) => activeIndex + 1)
      }
      if (
        e?.keyCode === keyCode.UP_ARROW &&
        watchListLength > 0 &&
        activeIndex > 0
      ) {
        setActiveIndex((activeIndex) => activeIndex - 1)
      }
    }

    if (isShow) {
      document.addEventListener('keydown', handlePressKey)
      return () => {
        document.removeEventListener('keydown', handlePressKey)
      }
    } else {
      document.removeEventListener('keydown', handlePressKey)
    }
  }, [isShow, activeIndex])

  return (
    <Dropdown parentRef={parentRef} isShow={isShow}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownWrapper}
        >
          <div className="form-search-inbox">
            <Input
              onChange={handleChangeKeyword}
              type="text"
              value={keyword}
              className="search-inbox h-20"
              placeholder={I18n.t('market.event.SEARCH_PLH')}
              style={{ fontSize: 11, backgroundColor: '#2c313e' }}
            />
            <button type="button">
              <i className="icon-search-2" />
            </button>
          </div>

          <div className={style.watchListDropdown} id="watchListDropdownId">
            {watchList.length === 0 ? (
              <div className={style.noData}>
                <Span style={{ fontSize: 10 }}>
                  <Translate value="market.event.NO_WATCHLIST" />
                </Span>
              </div>
            ) : (
              <ScrollComponent>
                {watchList.map((item, index) => {
                  return (
                    <div
                      key={item.watchListId}
                      className={`${style.watchListItem} ${
                        activeIndex === index && style.active
                      }`}
                      onClick={() => handleSelectWatchListItem(item, index)}
                    >
                      <DetectScroll isActive={activeIndex === index} />
                      {item.watchListName}
                    </div>
                  )
                })}
              </ScrollComponent>
            )}
          </div>

          <LinkCreateWatchList handleHide={handleHide} />
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const DetectScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#watchListDropdownId .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

DropdownBox.propTypes = {
  isShow: PropTypes.bool.isRequired,
  parentRef: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
}

export default DropdownBox
