import * as cpiAndInflation from './cpiAndInflation'
import * as event from './event'
import * as fdi from './fdi'
import * as fiscal from './fiscal'
import * as gdp from './gdp'
import * as importExport from './importExport'
import * as monetary from './monetary'
import * as overview from './overview'
import * as paymentBalance from './paymentBalance'
import * as productionAndConsumption from './productionAndConsumption'

const economy = {
  overview,
  gdp,
  cpiAndInflation,
  importExport,
  fdi,
  paymentBalance,
  monetary,
  fiscal,
  productionAndConsumption,
  event,
}

export default economy
