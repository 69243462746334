import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../../../common/table'
import { keyBy } from '../../../../../utils/Common'
import { getRowSpanSchema } from '../../../primaryMarket/marketStatistics/helper'
import { TIME_FREQUENCY } from '../constants'
import {
  changeFilter,
  keys,
  selectDataTableById,
  selectDebtDetailsData,
  selectFilterDebtAndLoan,
  selectFilterDetail,
  selectLoading,
  setRowsCollapse,
  sort,
} from '../store/slice'
import FirstCellColumn from './FirstCellColumn'
import SecondCellColumn from './SecondCellColumn'
import TableHeader from './TableHeader'

const TableContent = ({ width, zoom }) => {
  const dispatch = useDispatch()
  const [isShow, setIsShow] = useState('')
  const [isShowValue, setIsShowValue] = useState('')
  const [idSelected, setId] = useState('')
  const isLoading = useSelector(selectLoading(keys.DEBT_DETAIL))
  const [time, setTime] = useState({
    quarter: null,
    year: null,
  })
  const [idColumn, setIdColumn] = useState('')
  const [indexSelected, setIndex] = useState(null)

  const { data, ids, rowsCollapse, initialData } = useSelector(
    selectDebtDetailsData,
  )
  const activeTab = useSelector(selectFilterDebtAndLoan)
  const { isFirst } = useSelector(selectFilterDetail)

  const onRowCollapse = (rowId, isCollapse) => {
    const index = rowsCollapse.indexOf(rowId)

    if (isCollapse && index === -1) {
      dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      return
    }

    dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))
  }

  const onMouseEnter = (rowId, type, value) => {
    if (value === -1) return

    if (typeof rowId === 'string') {
      setIsShow(type)
      setId(rowId)
    }
  }
  const onMouseEnterValue = (rowId, type, columnId, value, index) => {
    if (!value || value === '--' || !columnId) {
      setIdColumn('')
      return
    }

    const rowDataById = initialData.find(
      (item) =>
        item.realYear === parseInt(columnId) &&
        item.lendingOrganizationId ===
          parseInt(typeof rowId === 'string' ? rowId?.split('-')?.[0] : -1),
    )

    if (!rowDataById) {
      setIdColumn('')
      return
    }

    setTime({
      quarter: rowDataById.realQuarter,
      year: rowDataById.realYear,
    })

    setIdColumn(columnId)

    if (typeof rowId === 'string') {
      setIsShowValue(type)
      setId(rowId)
    }

    setIndex(index)
  }

  const handleMouseLeave = () => {
    setIsShow('')
    setId('')
  }
  const handleMouseLeaveValue = () => {
    setIsShowValue('')
    setIndex('')
    setIdColumn()
    setId('')
  }

  const GROUP_ROWS = useMemo(() => {
    const years = initialData
      .map((item) =>
        activeTab === TIME_FREQUENCY.QUARTER
          ? `Q${item.realQuarter}/${item.realYear}`
          : `${item.realYear}`,
      )
      .filter((year) => year)

    const groups = years
      .filter((item, index) => years.indexOf(item) === index)
      .map((item) => ({
        key: `${item}`,
        title: item,
        additionText: '',
        keySort: `${item}`,
        alignRight: true,
        attrs: {
          style: {
            minWidth: '15%',
          },
        },
      }))

    groups.unshift({
      key: 'name',
      title: '',
      additionText: '',
      keySort: 'name',
      alignRight: true,
      disableSort: true,
      attrs: {
        style: {
          minWidth: 160,
          maxWidth: 160,
        },
      },
    })

    return groups
  }, [initialData, activeTab])

  const levelCollapse = useMemo(
    () =>
      keyBy(
        data?.map((item) => ({
          id: item.id,
          level: item.level,
          isCollapsible: !item?.childrenIds?.length ?? false,
        })),
        'id',
      ),
    [data],
  )

  const scrollToLeftCallback = () => {
    dispatch(
      changeFilter({
        label: keys.DEBT_DETAIL,
        key: 'isFirst',
        value: false,
      }),
    )
  }

  return (
    <>
      <Table
        ids={ids}
        columnDraggable={false}
        sort={sort}
        getDataFromRedux={selectDataTableById}
        rowDraggable={false}
        isLoading={isLoading}
        rowSpanSchema={getRowSpanSchema()}
        isLargeHead={false}
        idRowCustomStyle={[111, 222, 333, 444]}
        rowCustomStyle={{
          position: 'sticky',
          bottom: -1,
          zIndex: 3,
          background: '#232A37',
        }}
        defaultScrollToRight={isFirst}
        scrollToLeftCallback={scrollToLeftCallback}
        renderHeader={(stateSort, sortColumn) => (
          <TableHeader
            tableHeaderColAttr={GROUP_ROWS}
            stateSort={stateSort}
            sortColumn={sortColumn}
            width={width}
          />
        )}
        schema={GROUP_ROWS.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              tdStyle: {
                minWidth: 167,
                maxWidth: !zoom ? 167 : 'auto',
                padding: 0,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHorizontalHead = data.find(
                  (item) => item.id === rowId,
                )

                return (
                  <FirstCellColumn
                    props={props}
                    val={tableHorizontalHead?.name}
                    level={tableHorizontalHead?.level ?? 1}
                    childrenIds={tableHorizontalHead?.childIds?.length}
                    rowId={rowId}
                    onRowCollapse={onRowCollapse}
                    isRowCollapse={rowsCollapse.includes(rowId)}
                    onMouseEnter={() =>
                      onMouseEnter(
                        rowId,
                        'organizationShortName',
                        tableHorizontalHead?.lendingOrganizationId,
                      )
                    }
                    onMouseLeave={handleMouseLeave}
                    isShow={isShow}
                    idSelected={idSelected}
                    type="organizationShortName"
                  />
                )
              },
            }
          }

          return {
            ...result,
            tdStyle: {
              textAlign: 'right',
              padding: 0,
            },
            canCustomTd: true,
            render: (value, rowId, props) => {
              const rowData = data.find((item) => item.id === rowId)
              const columnId = Object.keys(rowData).find(
                (key) => rowData[key] === value,
              )

              return (
                <SecondCellColumn
                  props={props}
                  onMouseEnter={() =>
                    onMouseEnterValue(rowId, 'price', columnId, value, index)
                  }
                  onMouseLeave={handleMouseLeaveValue}
                  value={value}
                  idSelected={idSelected}
                  isShowValue={isShowValue}
                  rowId={rowId}
                  type="price"
                  time={time}
                  columnId={columnId}
                  idColumnSelect={idColumn}
                  width={!zoom ? (width - 167) / 5 : 'auto'}
                  index={index}
                  rowIndex={indexSelected}
                />
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        isCollapse={true}
        levelCollapse={levelCollapse}
        rowsCollapse={rowsCollapse}
        hasTooltip={false}
      />
    </>
  )
}

export default TableContent
