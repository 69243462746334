import PropTypes from 'prop-types'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { Content } from './Content'

const MAP_KEY = {
  DETAIL: 'DETAIL',
}

const MAP_SIZE = {
  [MAP_KEY.DETAIL]: {
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 1000,
    minHeight: 200,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Detail = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title={'corporate.news.DETAIL'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.DETAIL}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Content
          width={formatNumber(sizes[MAP_KEY.DETAIL].width) - PANEL_PADDING}
        />
      </Panel>
    </>
  )
}

Detail.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Detail)
