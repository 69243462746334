import { useSelector } from 'react-redux'
import { NavCertificate } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { TICKER_OPTIONS } from './constants'
import { selectFilters } from './store/slice'

export const PanelNavCertificate = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const filter = useSelector(selectFilters)

  const titlePanel = `fund.fundProfile.navData.${panelKey}`
  const nameScreen = UseI18n('constants.route.fund.fundProfile.navData.LABEL')
  const chartName = UseI18n(
    `fund.fundProfile.navData.navCertificate.${panelKey}`,
  )
  const ticker = UseI18n(
    `fund.fundProfile.navData.navCertificate.${Object.keys(TICKER_OPTIONS).find(
      (key) => TICKER_OPTIONS[key] === filter.ticker,
    )}`,
  ).toUpperCase()
  const titleJpg = UseI18n(
    `fund.fundProfile.navData.navCertificate.CHART_NAME_${panelKey}`,
    { ticker },
  )

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: panelKey,
        nameScreen,
        chartName,
      }}
    >
      <NavCertificate
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
