import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { jobType, latePaymentTypeValue } from '../constants'
import { getDataCashfollowAll, getDataValueOfBonds } from './thunk'

const initialState = {
  isLoading: false,
  dataChart: [],
  activeTab: jobType.ALL,
  CouponType: latePaymentTypeValue.ALL,
  dataAll: [],
  ids: [],
}

const slice = createSlice({
  name: 'bond/overview/expectedCash',
  initialState,
  reducers: {
    updateTab: (state, actions) => {
      state.activeTab = actions.payload
    },
    updateCoupom: (state, actions) => {
      state.CouponType = actions.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataValueOfBonds.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataValueOfBonds.fulfilled, (state, action) => {
      state.dataChart = action.payload.data || []
      state.ids = action.payload.ids || []
      state.isLoading = false
    })
    builder.addCase(getDataValueOfBonds.rejected, (state, action) => {
      state.dataChart = []
      state.isLoading = action.payload
    })

    builder.addCase(getDataCashfollowAll.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataCashfollowAll.fulfilled, (state, action) => {
      state.dataAll = action.payload
      state.isLoading = false
    })
    builder.addCase(getDataCashfollowAll.rejected, (state, action) => {
      state.dataAll = []
      state.isLoading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectCouponTypeTab = (state) => state[slice.name].CouponType
export const selectDataAll = (state) => state[slice.name].dataAll
export const selectIds = (state) => state[slice.name].ids

export const { updateTab, updateCoupom } = slice.actions

register(slice.name, slice.reducer)
