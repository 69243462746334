import newsProxy from '../../proxies/corporate/NewsProxy'
import { ServiceBase } from '../ServiceBase'

class NewsService extends ServiceBase {
  getNewsAnalytic(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsAnalytic',
      params,
    )(() => newsProxy.getNewsAnalytic(params))
  }

  getNewsEarningPaged(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsEarningPaged',
      params,
    )(() => newsProxy.getNewsEarningPaged(params))
  }

  getNewsTransactionsPaged(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsTransactionsPaged',
      params,
    )(() => newsProxy.getNewsTransactionsPaged(params))
  }

  getNewsBusinessPaged(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsBusinessPaged',
      params,
    )(() => newsProxy.getNewsBusinessPaged(params))
  }

  getNewsSources(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsSources',
      params,
    )(() => newsProxy.getNewsSources(params))
  }

  getNewsSourcesAnalytic(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsSourcesAnalytic',
      params,
    )(() => newsProxy.getNewsSourcesAnalytic(params))
  }

  getNewsCategories(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsCategories',
      params,
    )(() => newsProxy.getNewsCategories(params))
  }

  getNewsCategoriesAnalytic(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsCategoriesAnalytic',
      params,
    )(() => newsProxy.getNewsCategoriesAnalytic(params))
  }

  getNewsOther(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsOther',
      params,
    )(() => newsProxy.getNewsOther(params))
  }

  getLatestNews(params) {
    return this.applyMemoryCache(
      'NewsService.getLatestNews',
      params,
    )(() => newsProxy.getLatestNews(params))
  }

  getNewsById(params) {
    return this.applyMemoryCache(
      'NewsService.getNewsById',
      params,
    )(() => newsProxy.getNewsById(params))
  }
}

export default new NewsService()
