import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class CompanyBondDebtAndLoanProxy extends ProxyBase {
  getDebtRelationInBsData(params) {
    return this.get('GetDebtRelationInBS', params)
  }
  getScaleOfDebtByObjects(params) {
    return this.get('GetDebtSizeByObject', params)
  }
  getDebtStatisticData(params) {
    return this.get('GetDebtStatistics', params)
  }
  getDebtDetailsData(params) {
    return this.get('GetDebtDetail', params)
  }
  getCreditRatio(params) {
    return this.get('GetCreditRatio', params)
  }
  getTooltipLendingCompany(params) {
    return this.get('GetTooltipLendingCompany', params)
  }
  getTooltipLoanCompany(params) {
    return this.get('GetTooltipLoanCompany', params)
  }
  downloadDebtDetailsData(params) {
    return this.get('DownloadDebtDetail', params, 'download')
  }
  downloadTooltipLendingCompany(params) {
    return this.get('DownloadTooltipLendingCompany', params, 'download')
  }
}

export default new CompanyBondDebtAndLoanProxy(
  ServiceProxyConfig.Bond.CompanyBondDebtAndLoan.ServiceUrl,
)
