import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { formatVal, valDivMillion } from '../../../../../../utils/Value'
import { getLatestPeriods } from '../../../construction/steelStatistics/store/thunk'
import { getXAxisKey } from '../../helper'
import { getExport } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/overviewSteel/export',
  initialState: {
    isLoading: true,
    isLoadingSteelTypes: true,
    dataChart: [],
    steelTypeId: 1,
    year: null,
    locale: null,
    isLoadingLatestPeriods: true,
    defaultMaxDate: null,
  },
  reducers: {
    changeSteelType: (state, action) => {
      state.steelTypeId = action.payload
    },
    changeYear: (state, action) => {
      state.year = action.payload
    },
    changeLocale: (state, action) => {
      state.locale = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExport.pending, (state) => {
      state.isLoading = true
      state.dataChart = []
    })
    builder.addCase(getExport.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || []
      const formattedData = data.map((item) => ({
        ...item,
        value: formatVal(valDivMillion(item.value)),
        time: getXAxisKey(item.totalMonth, item.year, state.locale),
      }))
      state.dataChart = formattedData
    })
    builder.addCase(getExport.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getLatestPeriods.pending, (state) => {
      state.isLoadingLatestPeriods = true
      state.defaultMaxDate = null
    })
    builder.addCase(getLatestPeriods.fulfilled, (state, action) => {
      state.isLoadingLatestPeriods = false
      state.defaultMaxDate = action.payload
    })
    builder.addCase(getLatestPeriods.rejected, (state) => {
      state.isLoadingLatestPeriods = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectLoadingSteelTypes = (state) =>
  state[slice.name].isLoadingSteelTypes
export const selectDataChart = (state) => state[slice.name].dataChart
export const selectSteelTypeId = (state) => state[slice.name].steelTypeId
export const selectYear = (state) => state[slice.name].year
export const selectLoadingLatestPeriod = (state) =>
  state[slice.name].isLoadingLatestPeriods
export const selectDefaultMaxDate = (state) => state[slice.name].defaultMaxDate

export const { changeSteelType, changeYear, changeLocale } = slice.actions

register(slice.name, slice.reducer)
