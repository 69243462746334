import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot, valDivBillion } from '../../../../utils/Value'
import { ALL_METRIC_CODE, MAIN_METRIC_CODE } from '../constants'
import { getCostStructure, getDetailMetrics, getRevenueCost } from './thunk'

const initialState = {
  loading: {
    revenueCost: true,
    costStructure: true,
    detailMetrics: true,
  },
  data: {
    revenueCost: [],
    costStructure: [],
    detailMetricsTimes: [],
    detailMetricsDataById: {},
    detailMetricsDataByDate: [],
  },
  reCalcWiths: false,
}

const slice = createSlice({
  name: 'corporate/businessModel/costOfOperation',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
  },
  extraReducers: (builder) => {
    //RevenueCost
    builder.addCase(getRevenueCost.pending, (state) => {
      state.loading.revenueCost = true
    })
    builder.addCase(getRevenueCost.fulfilled, (state, action) => {
      state.data.revenueCost =
        action.payload
          ?.map((item) => ({
            ...item,
            iS3: valDivBillion(item.iS3),
            nC160: valDivBillion(item.nC160),
            cr: item.cr * 100,
            keyXAxis: item.realYear,
          }))
          .sort(
            (a, b) =>
              new Date(a.realYear, 3 * (a.realQuarter - 1)) -
              new Date(b.realYear, 3 * (b.realQuarter - 1)),
          ) || []
      state.loading.revenueCost = false
    })
    builder.addCase(getRevenueCost.rejected, (state, action) => {
      state.loading.revenueCost = action.payload.loading
    })
    //CostStructure
    builder.addCase(getCostStructure.pending, (state) => {
      state.loading.costStructure = true
    })
    builder.addCase(getCostStructure.fulfilled, (state, action) => {
      state.data.costStructure =
        action.payload?.map((item) => ({
          ...item,
          date: item.realYear,
          nC161_A: Math.round(item.nC161_A * 10000) / 100,
          nC162_A: Math.round(item.nC162_A * 10000) / 100,
          nC163_A: Math.round(item.nC163_A * 10000) / 100,
          nC164_A: Math.round(item.nC164_A * 10000) / 100,
          nC165_A: Math.round(item.nC165_A * 10000) / 100,
        })) || []
      state.loading.costStructure = false
    })
    builder.addCase(getCostStructure.rejected, (state, action) => {
      state.loading.costStructure = action.payload.loading
    })
    //DetailMetrics
    builder.addCase(getDetailMetrics.pending, (state) => {
      state.loading.detailMetrics = true
    })
    builder.addCase(getDetailMetrics.fulfilled, (state, action) => {
      const responseData =
        action.payload?.sort((a, b) => {
          return a.realYear - b.realYear
        }) || []

      const times = []
      const dataByCode = {}
      ALL_METRIC_CODE.forEach((code) => (dataByCode[code] = {}))
      responseData.forEach((item) => {
        if (!times.includes(item.date)) {
          times.push(item.date)
        }

        if (dataByCode[item.metricCode]) {
          dataByCode[item.metricCode][item.date] = Object.values(
            MAIN_METRIC_CODE,
          ).includes(item.metricCode)
            ? valDivBillion(item.value)
            : item.value * 100
        }
      })

      const lastFiveDate = times.slice(-5)
      const dataByDate = {}
      lastFiveDate.forEach((date) => (dataByDate[date] = { date }))
      responseData
        .filter((item) => lastFiveDate.includes(item.date))
        .forEach((item) => {
          dataByDate[item.date][item.metricCode] = Object.values(
            MAIN_METRIC_CODE,
          ).includes(item.metricCode)
            ? valDivBillion(item.value)
            : item.value * 100
        })

      state.data.detailMetricsTimes = times
      state.data.detailMetricsDataById = dataByCode
      state.data.detailMetricsDataByDate = Object.values(dataByDate)
      state.reCalcWiths = !state.reCalcWiths
      state.loading.detailMetrics = false
    })
    builder.addCase(getDetailMetrics.rejected, (state, action) => {
      state.loading.detailMetrics = action.payload.loading
    })
  },
})

export const selectLoadingRevenueCost = (state) =>
  state[slice.name].loading.revenueCost
export const selectLoadingCostStructure = (state) =>
  state[slice.name].loading.costStructure
export const selectLoadingDetailMetrics = (state) =>
  state[slice.name].loading.detailMetrics

export const selectDataRevenueCost = (state) =>
  state[slice.name].data.revenueCost
export const selectDataCostStructure = (state) =>
  state[slice.name].data.costStructure
export const selectDetailMetricsTimes = (state) =>
  state[slice.name].data.detailMetricsTimes
export const selectDetailMetricsDataById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.detailMetricsDataById[id], attr)
export const selectDetailMetricsDataByDate = (state) =>
  state[slice.name].data.detailMetricsDataByDate
export const selectReCalcWiths = (state) => state[slice.name].reCalcWiths

export const { resetStore, changeTimeFrequency } = slice.actions

register(slice.name, slice.reducer)
