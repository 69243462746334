import moment from 'moment'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { formatVal } from '../../../../utils/Value'
import style from '../style.module.css'
import { BlockBarItem } from './BlockBarItem'

const ChartContent = ({ totalBuyVolume, totalSellVolume, data, numberBar }) => {
  const getColor = (price) => {
    if (data.priceInfo && price < data.priceInfo.referencePrice) {
      return '#bf5757'
    } else if (data.priceInfo && price > data.priceInfo.referencePrice) {
      return '#3da967'
    } else {
      return '#e59e2e'
    }
  }

  const getBidOfferData = () => {
    return [...Array(numberBar)].map((_, index) => ({
      bestBid: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Bid`])
        : 0,
      bestBidVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}BidVolume`])
        : 0,
      bestOffer: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}Offer`])
        : 0,
      bestOfferVolume: data.bidAskInfo
        ? Number(data.bidAskInfo[`best${index + 1}OfferVolume`])
        : 0,
    }))
  }

  const getWidthPercent = (bestVolume, totalVolume) => {
    if (bestVolume === 0) {
      return 0
    } else {
      return (bestVolume / totalVolume) * 100 + '%'
    }
  }

  const getAtcAto = () => {
    if (
      moment().unix() >= moment().set('hour', 8).set('minute', 45).unix() &&
      moment().unix() <= moment().set('hour', 9).set('minute', 0).unix()
    ) {
      return 'ATO'
    }

    if (
      moment().unix() >= moment().set('hour', 14).set('minute', 30).unix() &&
      moment().unix() <= moment().set('hour', 14).set('minute', 45).unix()
    ) {
      return 'ATC'
    }

    return EMPTY_VALUE
  }

  return (
    <ScrollComponent>
      <div>
        {getBidOfferData().map((item, index) => (
          <div key={index} className={`${style.rowBlockBarItem} d-flex`}>
            <div className={`w-50 ${style.firstBlockBarItem}`}>
              <BlockBarItem
                leftValue={formatVal(item.bestBidVolume)}
                rightValue={
                  item.bestBid ? formatVal(item.bestBid) : getAtcAto()
                }
                color={getColor(item.bestBid)}
                widthColor={getWidthPercent(item.bestBidVolume, totalBuyVolume)}
                positionColor="right"
              />
            </div>
            <div className="w-50">
              <BlockBarItem
                leftValue={
                  item.bestOffer ? formatVal(item.bestOffer) : getAtcAto()
                }
                rightValue={formatVal(item.bestOfferVolume)}
                color={getColor(item.bestOffer)}
                widthColor={getWidthPercent(
                  item.bestOfferVolume,
                  totalSellVolume,
                )}
                positionColor="left"
              />
            </div>
          </div>
        ))}
      </div>
    </ScrollComponent>
  )
}

export default ChartContent
