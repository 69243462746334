import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { SEARCH_TIME_FRAME } from '../constants'
import style from '../style.module.css'
import {
  changeDateFrom,
  changeDateTo,
  changeTimeFrameChart,
  selectFilter,
  selectLimitDatepicker,
} from './store/slice'

export const DatePicker = () => {
  const dispatch = useDispatch()
  const currentDate = [new Date()]
  const currentYear = new Date().getFullYear()
  const timeZone = UseTimeZone()

  const filter = useSelector(selectFilter)
  const limitDatepicker = useSelector(selectLimitDatepicker)

  const setDateFrom = (value) => {
    dispatch(changeDateFrom(value || ''))
  }

  const setDateTo = (value) => {
    dispatch(changeDateTo(value || ''))
  }

  const clearInputDate = () => {
    dispatch(changeDateFrom(''))
    dispatch(changeDateTo(''))
    dispatch(changeTimeFrameChart(SEARCH_TIME_FRAME['3M']))
  }

  useEffect(() => {
    if (filter.dateFrom || filter.dateTo) {
      dispatch(changeTimeFrameChart(TIME_RANGES['YTD']))
    } else if (!filter.dateFrom && !filter.dateTo) {
      dispatch(changeTimeFrameChart(filter.timeFrameTab))
    }
  }, [filter.dateFrom, filter.dateTo])

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  return (
    <>
      <div className="form-date-select" style={{ marginRight: 32 }}>
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.marketInDepth.directorDeal.LB_FROM" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={filter.dateFrom ? getDateWithTimeZone(filter.dateFrom) : ''}
            handleChange={setDateFrom}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            minDate={getDateWithTimeZone(limitDatepicker.minDate)}
            maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
            customHeader={HeaderCalendarPopper({
              startYear: limitDatepicker.minYear || currentYear - 10,
              endYear: limitDatepicker.maxYear || currentYear,
            })}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.marketInDepth.directorDeal.LB_TO" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={filter.dateTo ? getDateWithTimeZone(filter.dateTo) : ''}
            handleChange={setDateTo}
            hightlightDates={currentDate}
            minDate={getDateWithTimeZone(limitDatepicker.minDate)}
            maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
            customHeader={HeaderCalendarPopper({
              startYear: limitDatepicker.minYear || currentYear - 10,
              endYear: limitDatepicker.maxYear || currentYear,
            })}
            CustomInput={InputDateCalendar}
          />
          <span className={`btn-select-date ${style.iconCaret}`}>
            <i className="icon-caret-down" />
          </span>
        </div>
        <button
          type="button"
          disabled={!filter.dateFrom && !filter.dateTo}
          onClick={() => clearInputDate()}
          style={{ height: 'auto' }}
          className={style.btnClear}
        >
          <Span style={{ fontSize: 9 }}>
            <Translate value="market.marketInDepth.directorDeal.LB_CLEAR" />
          </Span>
        </button>
      </div>
    </>
  )
}
