export const COLOR_STRUCTURE_CHART = ['#3da967', '#e0505b', '#f7b80c']

export const FOOTER_NAME_BARKEYS = [
  'market.coveredWarrant.cwStatistics.BUY',
  'market.coveredWarrant.cwStatistics.SELL',
  'market.coveredWarrant.cwStatistics.NET',
]

export const COMPONENTS_VALUE_BREAKDOWN = {
  LOCAL_INDIVIDUAL: 'market.coveredWarrant.cwStatistics.LOCAL_INDIVIDUAL',
  FOREIGN: 'market.coveredWarrant.cwStatistics.FOREIGN',
  LOCAL_INSTITUTION: 'market.coveredWarrant.cwStatistics.LOCAL_INSTITUTION',
  PROPRIETARY: 'market.coveredWarrant.cwStatistics.PROPRIETARY',
}

export const VALUE_BREAKDOWN_FILTER = [
  {
    name: COMPONENTS_VALUE_BREAKDOWN.LOCAL_INDIVIDUAL,
    value: COMPONENTS_VALUE_BREAKDOWN.LOCAL_INDIVIDUAL,
  },
  {
    name: COMPONENTS_VALUE_BREAKDOWN.FOREIGN,
    value: COMPONENTS_VALUE_BREAKDOWN.FOREIGN,
  },
  {
    name: COMPONENTS_VALUE_BREAKDOWN.LOCAL_INSTITUTION,
    value: COMPONENTS_VALUE_BREAKDOWN.LOCAL_INSTITUTION,
  },
  {
    name: COMPONENTS_VALUE_BREAKDOWN.PROPRIETARY,
    value: COMPONENTS_VALUE_BREAKDOWN.PROPRIETARY,
  },
]
