import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Icon } from '../../../common/html/Icon'
import { Span } from '../../../common/html/Span'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { TooltipInfo } from '../../../common/tooltipInfo'
import {
  EXPECTED_MOMENT_TABS,
  EXPECTED_MOMENT_TYPES,
  EXPECTED_TYPES,
} from '../constants'
import { changeFilterExpected, selectFilterExpected } from '../store/slice'

const Filter = () => {
  const dispatch = useDispatch()

  const { activeTab, activeMoment } = useSelector(selectFilterExpected)

  const select = useMemo(
    () =>
      activeTab === EXPECTED_TYPES.CASHFLOW_CHART
        ? EXPECTED_MOMENT_TABS.filter(
            (item) => item.value !== EXPECTED_MOMENT_TYPES.DAY,
          )
        : EXPECTED_MOMENT_TABS,
    [activeTab],
  )

  const handleChangeTab = (item) => {
    dispatch(changeFilterExpected({ key: 'activeMoment', value: item.value }))
  }

  const handleChangeComponent = (item) => {
    dispatch(
      changeFilterExpected({
        key: 'activeTab',
        value:
          activeTab === EXPECTED_TYPES.CASHFLOW_TABLE
            ? EXPECTED_TYPES.CASHFLOW_CHART
            : EXPECTED_TYPES.CASHFLOW_TABLE,
      }),
    )

    if (
      activeMoment === EXPECTED_MOMENT_TYPES.DAY &&
      activeTab === EXPECTED_TYPES.CASHFLOW_TABLE
    ) {
      dispatch(
        changeFilterExpected({
          key: 'activeMoment',
          value: EXPECTED_MOMENT_TYPES.YEAR,
        }),
      )
    }
  }

  return (
    <div style={{ marginBottom: 8 }}>
      <div className="d-flex ali-center justify-content-space-between">
        <div style={{ flex: 1 }}>
          {activeTab === EXPECTED_TYPES.CASHFLOW_TABLE && (
            <Span style={{ color: '#85939C', fontStyle: 'italic' }}>
              <Translate value="bond.portfolio.expected.UNIT" />
            </Span>
          )}
        </div>
        <div className="d-flex ali-center" style={{ gap: 7 }}>
          <Icon
            className={`${
              activeTab === EXPECTED_TYPES.CASHFLOW_TABLE
                ? 'icon-chart'
                : 'icon-dash'
            } cursor-pointer`}
            style={{ color: '#808080', fontSize: 20 }}
            onClick={handleChangeComponent}
          />
          <div>
            <DispatchActionTab
              data={select}
              activeTab={activeMoment}
              onChangeTab={handleChangeTab}
              itemStyle={{
                width:
                  activeTab === EXPECTED_TYPES.CASHFLOW_TABLE
                    ? '25%'
                    : 'calc(100% / 3)',
                fontWeight: 600,
              }}
              containerStyle={{
                width: activeTab === EXPECTED_TYPES.CASHFLOW_TABLE ? 240 : 180,
              }}
            />
          </div>
          <TooltipInfo
            tooltip="bond.portfolio.expected.TOOLTIP"
            fontSize={12}
          />
        </div>
      </div>
    </div>
  )
}

export default Filter
