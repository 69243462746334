import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { maxWatchlist } from '../../../../myWatchlist/constants'
import {
  setIsShowPopup,
  setIsShowWatchlistDetail,
} from '../../../../myWatchlist/store/slice'
import { selectWatchList } from '../../store/slice'
import style from './index.module.css'

const LinkCreateWatchList = ({ handleHide }) => {
  const dispatch = useDispatch()
  const watchList = useSelector(selectWatchList)

  const handleClick = () => {
    handleHide()
    dispatch(setIsShowPopup(true))
    if (watchList.length < maxWatchlist) {
      dispatch(setIsShowWatchlistDetail(true))
    }
  }

  return (
    <div className={style.createWL}>
      <i className={`icon-arrow-right-bold ${style.iconGotoWl}`} />
      &nbsp;
      <Span style={{ fontSize: 11, opacity: 0.4 }}>
        <Translate value="market.event.GO_TO" />
      </Span>
      &nbsp;
      <Span onClick={handleClick}>
        <Translate
          value="market.event.CREATE_WATCHLIST"
          className={style.linkCreate}
        />
      </Span>
    </div>
  )
}

export default LinkCreateWatchList
