import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../common/html/Span'
import { FAVORITE_CONTACT } from '../constants'
import { updateFafouriteContact } from '../thunk'
import style from './index.module.css'

export default function InputFavoriteContact({ defaultValueFavoriteContact }) {
  const dispatch = useDispatch()

  const listFavoriteContact = [
    {
      value: FAVORITE_CONTACT.EMAIL,
      label: I18n.t('setting.settingProfile.MAIL'),
      name: 'favoriteContact',
    },
    {
      value: FAVORITE_CONTACT.PHONE,
      label: I18n.t('setting.settingProfile.PHONE'),
      name: 'favoriteContact',
    },
  ]

  const [stateRadio, setStateRadio] = useState(defaultValueFavoriteContact)

  const changeFafouriteContact = (favouriteContact) => {
    setStateRadio(favouriteContact)
    dispatch(updateFafouriteContact({ favouriteContact: favouriteContact }))
  }

  return (
    <div className={['box-form', 'ali-center', style.ratioGroup].join(' ')}>
      {listFavoriteContact.map((item) => {
        return (
          <div key={`${item.name}-${item.value}`}>
            <label
              htmlFor={`${item.name}-${item.value}`}
              className={style.radioLabel}
            >
              <input
                className="radiobox radiobox3"
                type="radio"
                id={`${item.name}-${item.value}`}
                value={item.value}
                name={item.name}
                checked={stateRadio === item.value ? true : false}
                onChange={(e) =>
                  changeFafouriteContact(parseInt(e.target.value))
                }
              />
              <Span>{item.label}</Span>
            </label>
          </div>
        )
      })}
    </div>
  )
}
