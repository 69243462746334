import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useSectorGroup from '../../../../common/hooks/useSectorGroup'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../common/topInfo/sectorTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { selectExchangeCode } from '../slice'
import {
  TOP_COMPANIES_TAB_BANK,
  TOP_COMPANIES_TAB_CORPORATE,
  TOP_COMPANIES_TAB_SECURITIES,
} from './constants'
import { getSchema } from './schemas'
import {
  changeTabFilter,
  selectCompaniesIds,
  selectFilter,
  selectLoading,
  selectTopCompaniesValue,
  sort,
} from './store/slice'
import { getTopPerformance } from './store/thunk'
import style from './style.module.css'

const TopCompanies = ({ width }) => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)
  const isLoading = useSelector(selectLoading)
  const rowIds = useSelector(selectCompaniesIds)
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const typeSector = useSectorGroup(sectorInfo.icbId)
  const exchangeCode = useSelector(selectExchangeCode)

  const handleChangeTab = (val) => {
    dispatch(
      changeTabFilter({
        key: typeSector,
        val: val.value,
      }),
    )
  }

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(
        getTopPerformance({
          ICBId: sectorInfo.icbId,
          ExchangeCode: exchangeCode,
        }),
      )
    }
  }, [exchangeCode, locale, sectorInfo.icbId])

  const mapConstantTab = {
    [COM_GROUP.BANK]: TOP_COMPANIES_TAB_BANK,
    [COM_GROUP.SECURITIES]: TOP_COMPANIES_TAB_SECURITIES,
    [COM_GROUP.CORPORATE]: TOP_COMPANIES_TAB_CORPORATE,
  }

  const dataTab = Object.keys(mapConstantTab[typeSector]).map((key) => {
    return {
      title: I18n.t(`sector.sectorConstituents.overview.${key}`),
      value: mapConstantTab[typeSector][key],
    }
  })

  return (
    <div className="h-100">
      <SizeTracker>
        {(size) => {
          return (
            <>
              <DispatchActionTab
                data={dataTab}
                activeTab={filter[typeSector]}
                onChangeTab={handleChangeTab}
                containerStyle={{ marginBottom: 8 }}
                itemStyle={{
                  textTransform: 'uppercase',
                  fontWeight: 500,
                  fontSize: 11,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
                widthComponent={width}
              />
              {size.height && (
                <div
                  style={{ height: `calc(100% - ${size.height}px)` }}
                  className={`${style.tableSectorContainer}`}
                >
                  <Table
                    ids={rowIds}
                    getDataFromRedux={selectTopCompaniesValue}
                    isLoading={isLoading}
                    schema={getSchema(typeSector, filter[typeSector])}
                    hasFooter={false}
                    hasTooltip={false}
                    reCalcWidths={filter}
                    stickyBottomRowCount={3}
                    sort={sort}
                    stickies={{
                      shortName: true,
                      organizationShortName: true,
                      marketCap: true,
                    }}
                  />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}

export default TopCompanies
