import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HeaderCommon } from '../../HeaderCommon'
import { selectTrending, selectTrendingLoading } from '../../store/slice'
import { getTrendingNews } from '../../store/thunk'

export const LatestNews = ({ height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const data = useSelector(selectTrending)
  const isLoading = useSelector(selectTrendingLoading)

  useEffect(() => {
    dispatch(getTrendingNews())
  }, [locale])

  return (
    <HeaderCommon
      height={height}
      isShowTitle
      data={data}
      isLoading={isLoading}
    />
  )
}

LatestNews.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}
