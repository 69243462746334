import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { EXPECTED_MOMENT_TABS, EXPECTED_TYPES } from '../constants'
import {
  keys,
  resetDataCashflow,
  selectDataBondInformation,
  selectFilterExpected,
  selectLoading,
} from '../store/slice'
import {
  downloadExpectedCashflowData,
  getExpectedCashflowData,
} from '../store/thunk'
import CashflowChart from './CashflowChart'
import CashflowTable from './CashflowTable'
import Filter from './Filter'
import style from './index.module.css'

const Expected = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading(keys.BOND_INFORMATION))
  const { data } = useSelector(selectDataBondInformation)
  const { activeTab, activeMoment } = useSelector(selectFilterExpected)
  const locale = useSelector((state) => state.i18n.locale)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    const params = {
      bonds: !!data?.length
        ? data
            .filter((item) => item?.id !== 'total')
            .map((item) => ({
              bondId: item?.bondId,
              isUserBond: item?.isUserBond ?? false,
              bondVolume: item?.bondVolume ?? 0,
            }))
        : [],
      periodTakeBy: activeMoment,
    }

    return downloadExpectedCashflowData(params)
  }

  const renderTitle = useMemo(() => {
    let type = EXPECTED_MOMENT_TABS.find((item) => item.value === activeMoment)

    return type
      ? `${I18n.t('bond.portfolio.common.EXPECTED')}-${I18n.t(type.title)}`
      : I18n.t('bond.portfolio.common.EXPECTED')
  }, [locale, activeMoment])

  useEffect(() => {
    if (!isLoading) {
      if (!data.length) {
        dispatch(resetDataCashflow())
        return
      }

      const params = {
        bonds: data.map((item) => ({
          bondId: item?.bondId,
          isUserBond: item?.isUserBond ?? false,
          bondVolume: item?.bondVolume ? parseInt(item?.bondVolume) : 0,
        })),
        periodTakeBy: activeMoment,
      }

      dispatch(getExpectedCashflowData(params))
    }
  }, [data, activeMoment, isLoading])

  return (
    <Panel
      title="bond.portfolio.common.EXPECTED"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={activeTab === EXPECTED_TYPES.CASHFLOW_CHART}
      titleJpg={renderTitle}
      downloadJpgParams={
        activeTab === EXPECTED_TYPES.CASHFLOW_CHART
          ? {
              domId: 'expectedId',
              nameScreen: I18n.t('bond.portfolio.common.TITLE'),
              chartName: I18n.t('bond.portfolio.common.EXPECTED'),
              tickerName: '',
            }
          : {}
      }
      isDownloadCsv={activeTab === EXPECTED_TYPES.CASHFLOW_TABLE}
      downloadCsv={() => handleExportData()}
    >
      {isLoading ? (
        <Loading />
      ) : !!data.length ? (
        <SizeTracker>
          {(size) => (
            <>
              <Filter />
              <div id="expectedId" className={style.tableCashflow}>
                {size.height && (
                  <div style={{ height: height - size.height }}>
                    {(() => {
                      switch (activeTab) {
                        case EXPECTED_TYPES.CASHFLOW_TABLE:
                          return <CashflowTable />
                        case EXPECTED_TYPES.CASHFLOW_CHART:
                          return (
                            <CashflowChart
                              width={width}
                              height={height - size.height}
                            />
                          )

                        default:
                          return <></>
                      }
                    })()}
                  </div>
                )}
              </div>
            </>
          )}
        </SizeTracker>
      ) : (
        <NoData />
      )}
    </Panel>
  )
}

export default Expected
