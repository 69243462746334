import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

export class CompanySnapshotProxy extends ProxyBase {
  getOverview(params) {
    return this.get('Overview', params)
  }

  getRevenueBreakdown(params) {
    return this.get('RevenueBreakdown', params)
  }

  getCompareWithSector(params) {
    return this.get('CompareWithSector', params)
  }

  getStockData(params) {
    return this.get('StockData', params)
  }

  getStockData1Yrs(params) {
    return this.get('StockData1Yrs', params)
  }

  getFinancialRatio(params) {
    return this.get('FinancialRatio', params)
  }

  getValuationHistory(params) {
    return this.get('ValuationHistory', params)
  }

  getPricePerformanceChart(params) {
    return this.get('PricePerformanceChart', params)
  }

  getPeerAnalysis(params) {
    return this.get('PeerAnalysis', params)
  }

  getKeyFinancialData(params) {
    return this.get('KeyFinancialData', params)
  }

  getFinancialData(params) {
    return this.get('FinancialData', params)
  }

  getOwnershipAnalysis(params) {
    return this.get('OwnershipAnalysis', params)
  }

  getTop5MajorShareHolder(params) {
    return this.get('Top5MajorShareHolder', params)
  }

  getPriceData(params) {
    return this.get('PriceData', params)
  }

  getStockPerformanceAndIndex(params) {
    return this.get('StockPerformanceAndIndex', params)
  }

  getPricePerformance(params) {
    return this.get('PricePerformance', params)
  }

  getForeignNetTrading(params) {
    return this.get('ForeignNetTrading', params)
  }

  getIncomeStatement(params) {
    return this.get('IncomeStatement', params)
  }

  getBalanceStatement(params) {
    return this.get('BalanceStatement', params)
  }

  getReportDate(params) {
    return this.get('ReportDate', params)
  }

  getSummary(params) {
    return this.get('Summary', params)
  }

  getMajorShareHolder(params) {
    return this.get('MajorShareHolder', params)
  }

  getOwnershipAnalysisChart(params) {
    return this.get('OwnershipAnalysisChart', params)
  }

  getMaxDateStatement(params) {
    return this.get('MaxDateStament', params)
  }
}

export default new CompanySnapshotProxy(
  ServiceProxyConfig.Corporate.CompanySnapshot.ServiceUrl,
)
