import PropTypes from 'prop-types'
import { getFontSize } from '../../../utils/FontSize'
import { MARGIN } from '../constants'
import { getTextWidth } from '../helper'
import { getTickIndex } from './helper'
import { TickItem } from './TickItem'

export const XAxisWithDiv = ({
  data,
  keyXAxis,
  width,
  textMaxWidth,
  showAllTick,
  isBreakNewLine,
  appendStyle,
  showAllTickInTwoLines,
}) => {
  const fontSize = getFontSize(12)
  const dataMaxTextWidth = Math.max(
    ...data.map((item) => {
      const text = isBreakNewLine
        ? String(item[keyXAxis]).split('\n')[0]
        : item[keyXAxis]
      return getTextWidth(text, fontSize)
    }),
  )

  const { tickIndex, maxWidth } = getTickIndex({
    data,
    keyXAxis,
    width: width,
    textMaxWidth: showAllTick
      ? textMaxWidth || Math.floor(width / (data.length + 1 || 1))
      : dataMaxTextWidth,
    gap: 0,
  })

  return (
    <div
      className="d-flex justify-content-around"
      style={{
        marginLeft: MARGIN.left,
        marginRight: MARGIN.right,
        alignItems: showAllTickInTwoLines ? 'flex-start' : 'center',
        ...appendStyle,
      }}
    >
      {data.map((item, index) => (
        <TickItem
          key={index}
          text={item[keyXAxis]}
          keyXAxis={keyXAxis}
          maxWidth={maxWidth}
          isHide={!showAllTick && !tickIndex.includes(index)}
          isBreakNewLine={isBreakNewLine}
          showAllTick={showAllTick}
          showAllTickInTwoLines={showAllTickInTwoLines}
        />
      ))}
    </div>
  )
}

XAxisWithDiv.propTypes = {
  data: PropTypes.array.isRequired,
  keyXAxis: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  textMaxWidth: PropTypes.number,
  showAllTick: PropTypes.bool,
  isBreakNewLine: PropTypes.bool,
  appendStyle: PropTypes.object,
  showAllTickInTwoLines: PropTypes.bool,
}

XAxisWithDiv.defaultProps = {
  isBreakNewLine: false,
  showAllTick: false,
  appendStyle: {},
}
