import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../common/table/helper'
import { invertKeyBy, keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getData } from './thunk.js'

const initialState = {
  loading: false,
  data: {},
  ids: [],
  column: [],
  initialIds: [],
  qty: 1,
  initialData: [],
}

export const slice = createSlice({
  name: 'demo/valuation/samaIndustryBond',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    updateBondIds(state, actions) {
      state.BondIds = actions.payload
    },

    updateQty: (state, actions) => {
      state.qty = actions.payload
    },
    sortData: (state, actions) => {
      if (actions.payload.hasOwnProperty('organizationShortName')) {
        const data = state.data
        const dataStateJSON = JSON.parse(JSON.stringify(data))
        if (actions.payload['organizationShortName'] === 'asc') {
          const idList = invertKeyBy(dataStateJSON).sort((a, b) =>
            a?.organizationShortName.localeCompare(
              b?.organizationShortName,
              actions.payload.locale,
            ),
          )
          state.ids = idList.map((e) => e.id)
        }
        if (actions.payload['organizationShortName'] === 'desc') {
          const idList = invertKeyBy(dataStateJSON)
            .sort((a, b) =>
              a?.organizationShortName.localeCompare(
                b?.organizationShortName,
                actions.payload.locale,
              ),
            )
            .reverse()
          state.ids = idList.map((e) => e.id)
        }
        if (actions.payload['organizationShortName'] === undefined) {
          state.ids = state.initialIds
        }
        return
      }
      state.ids = getIdsFromProps(
        state.ids,
        state.data,
        actions.payload,
        state.initialIds,
        0,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getData.fulfilled, (state, action) => {
      const data = keyBy(action.payload, 'id')
      state.ids = action.payload.map((e) => e.id)
      state.initialIds = action.payload.map((e) => e.id)
      state.data = data
      state.loading = false
      state.initialData = action.payload
    })
    builder.addCase(getData.rejected, (state, action) => {
      state.loading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectInitialData = (state) => state[slice.name].initialData
export const selectQty = (state) => state[slice.name].qty
export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].data[id], attr)
}

export const { updateBondIds, sortData, updateQty, resetStore } = slice.actions

register(slice.name, slice.reducer)
