import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MarketStatisticsService from '../../../../../../core/services/bond/corporateBond/primaryMarket/MarketStatisticsService'
import { handleExport } from '../../../../../utils/Export'

export const getIssuanceValueData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/GET_ISSUANCE_VALUE',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarketStatisticsService.getIssuanceValue(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getAverageCouponBySectorData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/GET_AVERAGE_COUPON_BY_SECTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarketStatisticsService.getAverageCouponBySector(
        params,
      )

      if (response.success) {
        return { data: response.data, timeType: params.TimeRange }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getIssuanceValueBySectorData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/GET_ISSUANCE_VALUE_BY_SECTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarketStatisticsService.getIssuanceValueBySector(
        params,
      )

      if (response.success) {
        return {
          data: response.data,
          timeType: params.TimeRange,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getIssuanceValueByCouponData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/GET_ISSUANCE_VALUE_BY_COUPON',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarketStatisticsService.getIssuanceValueByCoupon(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getAverageDurationBySectorData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/GET_AVERAGE_DURATION_BY_SECTOR',
  async (params, { rejectWithValue }) => {
    try {
      const response = await MarketStatisticsService.getAverageDurationBySector(
        params,
      )

      if (response.success) {
        return { data: response.data, timeType: params.TimeRange }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadIssuanceValueData = createAsyncThunk(
  'bond/corporateBond/primaryMarket/DOWNLOAD_ISSUANCE_VALUE',
  async (data) => {
    const response = await MarketStatisticsService.downloadIssuanceValue(data)
    handleExport(response.data, response.filename)
  },
)
