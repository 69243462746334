import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import PopupAlert from '../../../../common/popup/PopupAlert'
import { Table } from '../../../../common/table'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { TYPE_IMPORT_EXPORT } from '../constants'
import { TdSelect } from './TdSelect'
import {
  LIMIT_FETCH,
  LIMIT_FIRST_FETCH,
  TYPE_CATEGORY,
  TYPE_DATA,
} from './constants'
import {
  addCategoryToList,
  handleIsFirstTime,
  removeCategoryFromList,
  selectActiveItemTable,
  selectCategoryDataTableById,
  selectCategoryIds,
  selectCurrentDataCategory,
  selectDataTable,
  selectGroupColumns,
  selectImportExportDataType,
  selectIsFirstTime,
  selectLevelCollapse,
  selectListCategory,
  selectLoadingTable,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectMultipleActiveRowsIds,
  selectOrigParentCategory,
  selectRowsCollapse,
  selectTypeFilter,
  selectUnCollapseIds,
  setLimitQuarter,
  setRowsCollapse,
  setUnCollapseIds,
  sort,
} from './store/slice'
import {
  getExportByLocationAfterScroll,
  getExportByLocationChildren,
  getExportByLocationChildrenAfterScroll,
  getExportTradeAgreementsByLocationAfterScroll,
  getExportTradeAgreementsByLocationChildren,
  getExportTradeAgreementsByLocationChildrenAfterScroll,
  getImportByLocationAfterScroll,
  getImportByLocationChildren,
  getImportByLocationChildrenAfterScroll,
  getImportTradeAgreementsByLocationAfterScroll,
  getImportTradeAgreementsByLocationChildren,
  getImportTradeAgreementsByLocationChildrenAfterScroll,
} from './store/thunk'

const HORIZONTAL_TRACK_LEFT = 28
const LIMIT_SELECTION = 20
const WIDTH_FIRST_COL = 320

const TableComponent = ({ height, limitQuarter }) => {
  const dispatch = useDispatch()

  const dataTable = useSelector(selectDataTable)
  const isFirstTime = useSelector(selectIsFirstTime)
  const isLoading = useSelector(selectLoadingTable)
  const groupColumns = useSelector(selectGroupColumns)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const listCategory = useSelector(selectListCategory)
  const listParentCategory = useSelector(selectOrigParentCategory)
  const activeItem = useSelector(selectActiveItemTable)
  const importExportDataType = useSelector(selectImportExportDataType)
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const categoryIds = useSelector(selectCategoryIds)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const levelCollapse = useSelector(selectLevelCollapse)
  const multipleActiveRowsIds = useSelector(selectMultipleActiveRowsIds)
  const unCollapseIds = useSelector(selectUnCollapseIds)
  const [isShowWarning, setIsShowWarning] = useState(false)

  const getItemById = (id) => {
    return listParentCategory?.find((item) => item.id === id)
  }

  const isListCategoryIncludeItem = (id) => {
    return listCategory?.some((item) => item.id === id)
  }

  const onRowClick = (rowId) => {
    if (getItemById(rowId)?.isCollapse) {
      if (!isListCategoryIncludeItem(rowId)) {
        if (listCategory.length < LIMIT_SELECTION) {
          const category = {
            id: rowId,
            name: getItemById(rowId)?.name,
            locationId: getItemById(rowId)?.locationId,
          }
          dispatch(addCategoryToList(category))
        } else {
          setIsShowWarning(true)
        }
      } else {
        dispatch(removeCategoryFromList(rowId))
      }
    }
  }

  const getChildrenDataByType = (rowId) => {
    if (currentCategoryFilter === TYPE_CATEGORY.CONTINENT) {
      if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
        dispatch(
          getImportByLocationChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            LocationIds: rowId,
          }),
        )
      } else {
        dispatch(
          getExportByLocationChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            LocationIds: rowId,
          }),
        )
      }
    } else {
      if (importExportDataType === TYPE_IMPORT_EXPORT.IMPORT) {
        dispatch(
          getImportTradeAgreementsByLocationChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            LocationIds: getItemById(rowId)?.locationId,
            areaIds: getItemById(rowId)?.parentId,
          }),
        )
      } else {
        dispatch(
          getExportTradeAgreementsByLocationChildren({
            DataType: currentTypeFilter,
            Limit: limitQuarter
              ? limitQuarter + LIMIT_FIRST_FETCH
              : LIMIT_FIRST_FETCH,
            LocationIds: getItemById(rowId)?.locationId,
            areaIds: getItemById(rowId)?.parentId,
          }),
        )
      }
    }
  }

  const onRowCollapse = (rowId, isCollapse) => {
    if (isCollapse) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        dispatch(setRowsCollapse([...rowsCollapse, rowId]))
      }
    } else {
      dispatch(setRowsCollapse(rowsCollapse.filter((item) => item !== rowId)))

      if (!unCollapseIds.includes(rowId)) {
        dispatch(setUnCollapseIds([rowId, ...unCollapseIds]))
        getChildrenDataByType(rowId)
      }
    }
  }

  const scrollToLeftCallback = () => {
    dispatch(handleIsFirstTime(false))
    dispatch(setLimitQuarter(limitQuarter + LIMIT_FETCH))
    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentCategoryFilter === TYPE_CATEGORY.CONTINENT
    ) {
      dispatch(
        getImportByLocationAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseIds.length) {
        dispatch(
          getImportByLocationChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            LocationIds: unCollapseIds.toString(),
          }),
        )
      }
    }

    const unCollapseLocationIds = unCollapseIds?.map(
      (item) => getItemById(item)?.locationId,
    )
    const unCollapseAreaIds = unCollapseIds?.map(
      (item) => getItemById(item)?.parentId,
    )

    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentCategoryFilter === TYPE_CATEGORY.CONTINENT
    ) {
      dispatch(
        getExportByLocationAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseIds.length) {
        dispatch(
          getExportByLocationChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            LocationIds: unCollapseIds.toString(),
          }),
        )
      }
    }

    if (
      importExportDataType === TYPE_IMPORT_EXPORT.IMPORT &&
      currentCategoryFilter === TYPE_CATEGORY.TRADE_AGREEMENTS
    ) {
      dispatch(
        getImportTradeAgreementsByLocationAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseLocationIds.length) {
        dispatch(
          getImportTradeAgreementsByLocationChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            LocationIds: unCollapseLocationIds.toString(),
            areaIds: unCollapseAreaIds.toString(),
          }),
        )
      }
    }

    if (
      importExportDataType === TYPE_IMPORT_EXPORT.EXPORT &&
      currentCategoryFilter === TYPE_CATEGORY.TRADE_AGREEMENTS
    ) {
      dispatch(
        getExportTradeAgreementsByLocationAfterScroll({
          DataType: currentTypeFilter,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: LIMIT_FETCH,
        }),
      )
      if (unCollapseLocationIds.length) {
        dispatch(
          getExportTradeAgreementsByLocationChildrenAfterScroll({
            DataType: currentTypeFilter,
            Year: minCurrentYear,
            Month: minCurrentMonth,
            Limit: LIMIT_FETCH,
            LocationIds: unCollapseLocationIds.toString(),
            areaIds: unCollapseAreaIds.toString(),
          }),
        )
      }
    }
  }

  const isGrowth = dataTable.length
    ? dataTable[0].type === TYPE_DATA.GROWTH
    : ''

  const schema = groupColumns.map((item, index) => {
    const title = item.title
    const colId = item.key
    let result = {
      colId,
      title,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }
    if (index === 0) {
      result = {
        ...result,
        isI18n: false,
        thStyle: {
          width: WIDTH_FIRST_COL,
          textAlign: 'left',
        },
        tdStyle: {
          textAlign: 'left',
          width: WIDTH_FIRST_COL,
        },
        render: (val, rowId) => {
          return (
            <TdSelect
              val={val}
              rowId={rowId}
              onRowCollapse={onRowCollapse}
              data={dataTable}
              isRowCollapse={rowsCollapse.includes(rowId)}
              width={WIDTH_FIRST_COL}
            />
          )
        },
      }
    }
    if (index !== 0) {
      result = {
        ...result,
        thStyle: {
          minWidth: 150,
          textAlign: 'right',
        },
        render: (val) => {
          return (
            <Span
              style={{
                color: val < 0 ? '#ff4752' : '',
              }}
            >
              {isGrowth ? valToPercent(val) : formatVal(val)}
            </Span>
          )
        },
      }
    }
    return result
  })

  return (
    <div id="exportImportTable" style={{ height: height }}>
      <Table
        ids={categoryIds}
        horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
        sort={sort}
        resizable={false}
        getDataFromRedux={selectCategoryDataTableById}
        isLoading={isLoading}
        columnDraggable={false}
        hasTooltip={false}
        hasFooter={false}
        onRowClick={onRowClick}
        stickyFirstColumn
        schema={schema}
        isCollapse={true}
        rowsCollapse={rowsCollapse}
        levelCollapse={levelCollapse}
        defaultActiveRowId={activeItem?.id ?? listCategory[0]?.id}
        scrollToLeftCallback={scrollToLeftCallback}
        defaultScrollToRight={isFirstTime}
        isLazyLoadLeft={true}
        multipleActiveRowsIds={multipleActiveRowsIds}
      />
      <PopupAlert
        message={I18n.t('economy.importExport.importExport.WARNING_MESS')}
        isShow={isShowWarning}
        handleClose={() => setIsShowWarning(false)}
        zIndexOverlay={12}
      />
    </div>
  )
}

export default TableComponent
