import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CHANGES_IN_SPENDING_METHOD } from '.'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import SelectYear from '../../common/SelectYear'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import ChartComponent from './ChartComponent'
import {
  getStackedBarChartData,
  getStackedBarChartLoading,
  getYear,
  getYearOption,
  handleYear,
  selectStackKey,
} from './store/slice'
import { getGDPStructureBySpendingMethod, getMaxPeriod } from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const year = useSelector(getYear)
  const barChartLoading = useSelector(getStackedBarChartLoading)
  const barChartData = useSelector(getStackedBarChartData)
  const locale = useSelector((state) => state.i18n.locale)
  const nominalRealValue = useSelector(getNominalRealValue)
  const stackKey = useSelector(selectStackKey)
  const yearOptions = useSelector(getYearOption)

  const handleChangeYear = (year) => {
    dispatch(handleYear(year))
  }

  useEffect(() => {
    dispatch(
      getMaxPeriod({
        IsNominal: nominalRealValue === NOMINAL,
      }),
    )
  }, [nominalRealValue])

  useEffect(() => {
    dispatch(
      getGDPStructureBySpendingMethod({
        IsNominal: nominalRealValue === NOMINAL,
        Year: year,
      }),
    )
  }, [locale, year, nominalRealValue])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <SelectYear
              year={year}
              changeYear={handleChangeYear}
              isFlexEnd={false}
              yearOptions={yearOptions}
            />
            {barChartLoading && <Loading />}
            {!barChartLoading && (
              <>
                {barChartData.length === 0 && (
                  <div style={{ height: 'auto' }}>
                    <NoData />
                  </div>
                )}
                {barChartData.length !== 0 && (
                  <>
                    {typeof sizes.height === 'number' && (
                      <div id={CHANGES_IN_SPENDING_METHOD}>
                        <ChartComponent
                          height={height - sizes.height}
                          width={width}
                          data={barChartData}
                          keyXAxis={'year'}
                          stackKey={stackKey}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
