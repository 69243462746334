import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { withWrapper } from '../../../../common/withWrapper'
import LiquidityChartComponent from '../liquidityChart'
import ValueChartComponent from '../valueChart'
import PanelPrice from './PanelPrice'

export const MAP_KEY = {
  PRICE_BOARD: 'PRICE_BOARD',
  LIQUIDITY_CHART: 'LIQUIDITY_CHART',
  VALUE_CHART: 'VALUE_CHART',
}

const MAP_SIZE = {
  [`${MAP_KEY.PRICE_BOARD}`]: {
    width: `calc(100% / 3 * 2 - ${3.5 * COMPONENT.MARGIN}px)`,
    height: `calc(100%)`,
    top: 0,
    left: 0,
    minWidth: 840,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2 + 2 * COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.LIQUIDITY_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.VALUE_CHART,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.LIQUIDITY_CHART}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%  - ${3.5 * COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 * 2)`,
    minWidth: 420,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 847,
    horizontalResize: [
      {
        key: MAP_KEY.VALUE_CHART,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.VALUE_CHART}`]: {
    width: `calc(100% / 3)`,
    height: `calc(50%)`,
    top: 'calc(50%)',
    left: `calc(100% / 3 * 2)`,
    minWidth: 420,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + 2 * COMPONENT.MARGIN,
    minLeft: 847,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelPrice
        panelRefs={panelRefs}
        panelKey={MAP_KEY.PRICE_BOARD}
        sizes={sizes}
        setSizes={setSizes}
      />
      <LiquidityChartComponent
        panelRefs={panelRefs}
        panelKey={MAP_KEY.LIQUIDITY_CHART}
        sizes={sizes}
        setSizes={setSizes}
      />
      <ValueChartComponent
        panelRefs={panelRefs}
        panelKey={MAP_KEY.VALUE_CHART}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
