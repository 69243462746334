import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorConstituentsOverviewService from '../../../../../../core/services/sector/sectorConstituents/OverviewService'
import { handleExport } from '../../../../../utils/Export'

export const getKeyRatios = createAsyncThunk(
  'sector/sectorConstituents/overview/GET_KEY_RATIOS',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SectorConstituentsOverviewService.getKeyRatios(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(response.error)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadKeyRatios = createAsyncThunk(
  'sector/sectorConstituents/overview/DOWNLOAD_KEY_RATIOS',
  async (data) => {
    const response = await SectorConstituentsOverviewService.downloadKeyRatios(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
