import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { changeDashboardSelected } from '../../store/slice'
import style from '../styleTheme.module.css'
import { PopupGroupButtonActionSave } from './PopupGroupButtonActionSave'

export const PopupGroupButton = ({
  chartTitle,
  newDashboardName,
  isAddNewDashboard,
  chartTabActive,
  chartTabs,
  handleShow,
  setIsAddNewDashboard,
  buttonSaveRef,
  isSaveAsChart,
  isEnterAddNewDashboard,
}) => {
  const dispatch = useDispatch()
  const childRef = useRef()

  const onClose = () => {
    handleShow(false)
  }

  const handleAddNewDashboard = () => {
    if (isAddNewDashboard) return
    dispatch(changeDashboardSelected())
    setIsAddNewDashboard(true)
  }

  const handleSave = () => {
    childRef.current.handleSave()
  }

  useEffect(() => {
    if (isEnterAddNewDashboard) {
      handleSave()
    }
  }, [isEnterAddNewDashboard])

  return (
    <>
      <div className="j-b d-flex w-100" style={{ marginTop: 40 }}>
        <Button
          className={`btn h-20 ${style.buttonGrayStyle}`}
          style={{
            fontSize: 11,
            width: 'fit-content',
            padding: '0 16px',
            opacity: isAddNewDashboard ? 0.5 : 1,
          }}
          onClick={handleAddNewDashboard}
        >
          <Translate value="financialChart.NEW_DASHBOARD" />
        </Button>
        <div className="d-flex justify-content-end">
          <Button
            className={`btn w-80 h-20 ${style.buttonGrayStyle}`}
            style={{ fontSize: 11 }}
            onClick={onClose}
          >
            <Translate value="common.button.BUTTON_CANCEL" />
          </Button>
          <Button
            ref={buttonSaveRef}
            className={`btn w-80 h-20 ml-20 ${style.buttonBlueStyle}`}
            style={{ fontSize: 11 }}
            onClick={handleSave}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        </div>
      </div>
      <PopupGroupButtonActionSave
        ref={childRef}
        isAddNewDashboard={isAddNewDashboard}
        newDashboardName={newDashboardName}
        chartTitle={chartTitle}
        chartTabActive={chartTabActive}
        chartTabs={chartTabs}
        handleShow={handleShow}
        isSaveAsChart={isSaveAsChart}
      />
    </>
  )
}
