import Content from './Content'

export const Overview = () => {
  return (
    <div
      style={{
        position: 'relative',
        height: `calc(100% - 5px)`,
      }}
    >
      <Content />
    </div>
  )
}
