import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import { formatVal } from '../../../../utils/Value'
import { EMPTY_ROW } from './constant'
import FirstCellColumn from './FirstCellColumn'
import { handleLimitDate, handleTitleGroupColumn } from './helper'
import {
  CURRENT_MONTH,
  CURRENT_YEAR,
  getDepositRateVietNamValue,
  getFromMonth,
  getFromYear,
  getGroupColumns,
  getIds,
  getIsAllowCallApi,
  getIsFirsTime,
  getLimitDate,
  getMinMonthYear,
  getTableData,
  getTableDataLoading,
  getTableMappingLoading,
  getToMonth,
  getToYear,
  handleIsFirstTime,
} from './store/slice'
import {
  getMaxDate,
  getStatisticStateBank,
  getStatisticStateBankName,
} from './store/thunk'

const DECEMBER = 12

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  const tableDataLoading = useSelector(getTableDataLoading)
  const tableMappingLoading = useSelector(getTableMappingLoading)
  const fromYear = useSelector(getFromYear)
  const fromMonth = useSelector(getFromMonth)
  const toYear = useSelector(getToYear)
  const toMonth = useSelector(getToMonth)
  const locale = useSelector((state) => state.i18n.locale)
  const ids = useSelector(getIds)
  const groupColumns = useSelector(getGroupColumns)
  const tableData = useSelector(getTableData)
  const isAllowCallApi = useSelector(getIsAllowCallApi)
  const isFirstTime = useSelector(getIsFirsTime)
  const limitDate = useSelector(getLimitDate)
  const limitDateFormat = handleLimitDate(limitDate, timeZone)
  const { minYear, minMonth } = useSelector(getMinMonthYear)

  const scrollTopLeft = () => {
    dispatch(handleIsFirstTime(false))
    if (Object.keys(limitDateFormat) && isAllowCallApi) {
      const { minMonth: limitMonth, minYear: limitYear } = limitDateFormat
      if (
        minYear > limitYear ||
        (minYear === limitYear && minMonth > limitMonth)
      ) {
        dispatch(
          getStatisticStateBank({
            MonthFrom:
              minYear === limitYear
                ? limitMonth
                : minMonth === 1
                ? DECEMBER
                : minMonth - 1,
            YearFrom:
              minYear === limitYear
                ? minYear
                : minMonth === 1
                ? minYear - 2
                : minYear - 1,
            MonthTo: minMonth === 1 ? DECEMBER : minMonth - 1,
            YearTo: minMonth === 1 ? minYear - 1 : minYear,
          }),
        )
      }
    }
  }

  useEffect(() => {
    dispatch(
      getStatisticStateBank({
        MonthFrom: fromMonth || CURRENT_MONTH,
        YearFrom: fromYear || CURRENT_YEAR - 2,
        MonthTo: toMonth || CURRENT_MONTH,
        YearTo: toYear || CURRENT_YEAR,
      }),
    )
    dispatch(handleIsFirstTime(true))
  }, [fromMonth, fromYear, toMonth, toYear, locale])

  useEffect(() => {
    dispatch(getStatisticStateBankName())
  }, [locale])

  useEffect(() => {
    dispatch(
      getMaxDate({
        InterestRateBank: 'Statistic',
      }),
    )
  }, [])

  if ((tableDataLoading || tableMappingLoading) && isFirstTime) {
    return (
      <div style={{ height: height }}>
        <Loading />
      </div>
    )
  }

  if (Object.keys(tableData).length === 0 || ids.length === 0) {
    return (
      <div style={{ height: height }}>
        <NoData />
      </div>
    )
  }

  return (
    <div style={{ height: height }}>
      <Table
        ids={ids.map((item) => item.id)}
        columnDraggable={false}
        isLoading={tableDataLoading || tableMappingLoading}
        getDataFromRedux={getDepositRateVietNamValue}
        rowDraggable={false}
        schema={[{ key: 'emptyColumn', title: '' }, ...groupColumns].map(
          (item, index) => {
            const title =
              item.title === undefined
                ? `${handleTitleGroupColumn(item.month, locale)}-${item.year}`
                : ''
            const colId = item.key
            const result = {
              colId,
              title,
            }
            if (index === 0) {
              return {
                ...result,
                thStyle: {
                  textAlign: 'right',
                },
                tdStyle: {
                  minWidth: 240,
                },
                canCustomTd: true,
                render: (val, rowId, props) => {
                  const tableHorizontalHead = ids.find(
                    (item) => item.id === rowId,
                  )
                  return (
                    <FirstCellColumn
                      props={props}
                      val={tableHorizontalHead?.title}
                      level={tableHorizontalHead?.level || 1}
                      rowId={rowId}
                    />
                  )
                },
              }
            }

            return {
              ...result,
              isI18n: true,
              tdStyle: {
                textAlign: 'right',
                minWidth: 100,
              },
              thStyle: {
                textAlign: 'right',
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                return (
                  <td {...props}>
                    <Span>
                      {!EMPTY_ROW.includes(rowId) ? formatVal(val) : ''}
                      {typeof val === 'number' ? '%' : ''}
                    </Span>
                  </td>
                )
              },
            }
          },
        )}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        scrollToLeftCallback={scrollTopLeft}
        defaultScrollToRight={isFirstTime}
        isLazyLoadLeft={true}
      />
    </div>
  )
}

export default TableComponent
