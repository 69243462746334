import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { selectBasicInfo } from '../Filter/store/slice'
import { selectDataBondDetail } from '../generalSetting/store/slice'
import { ISSUE_METHOD_TYPES, valueOption, valueTab } from './constants'
import Content from './contents'
import Filter from './filter'
import {
  selecTradingType,
  selectPageSize,
  selectTabActive,
  updateScrollId,
  updateTradingType,
} from './store/slice'
import { exportFileApi, getDataThunk } from './store/thunk'

const OderBook = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const bondInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectTabActive)
  const bondDetail = useSelector(selectDataBondDetail)

  const tradingType = useSelector(selecTradingType)
  const locale = useSelector((state) => state.i18n.locale)
  const pageSize = useSelector(selectPageSize)
  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const getData = (params) => {
    dispatch(getDataThunk(params))
    dispatch(updateScrollId(null))
  }

  useEffect(() => {
    if (bondInfo?.bondId) {
      getData({
        BondId: bondInfo?.bondId,
        TradingType: tradingType || undefined,
        PageSize: pageSize,
        ScrollId: null,
        IsRealtime: activeTab === valueTab.Realtime ? true : false,
      })
    }
  }, [bondInfo?.bondId, locale, activeTab, tradingType])

  const exportFile = () => {
    return exportFileApi({
      BondId: bondInfo?.bondId,
      IsRealtime: activeTab === valueTab.Realtime ? true : false,
      TradingType: tradingType || undefined,
    })
  }

  useEffect(() => {
    if (bondDetail) {
      dispatch(
        updateTradingType(
          bondDetail?.issueMethodId === ISSUE_METHOD_TYPES.PRIVATE
            ? valueOption.Deal
            : valueOption.Match,
        ),
      )
    }
  }, [bondDetail])

  return (
    <Panel
      title="bond.bondSecurity.common.Orderbook"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => exportFile()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                <Content />
              </div>
            )}
            <div>
              <Span
                style={{
                  fontSize: 10,
                  color: '#85939C',
                  fontStyle: 'italic',
                }}
              >
                {I18n.t('bond.bondSecurity.order.Note')}
              </Span>
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default OderBook
