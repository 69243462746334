import classes from './style.module.css'

export const Divider = ({ isVertical, style }) => {
  return (
    <div
      style={style}
      className={
        isVertical ? classes.dividerVertical : classes.dividerHorizontal
      }
    />
  )
}

Divider.defaultProps = {
  isVertical: false,
}
