import { useSelector } from 'react-redux'
import { CorporateTable } from '.'
import { Panel } from '../../../../../common/panel'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import {
  selectCheckedTableConstants,
  selectPeriod,
  selectViewType,
} from '../store/slice'
import { downloadNonFinancialCompany } from '../store/thunk'

export const PanelTable = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
  dataType,
}) => {
  const sectorInfo = useSelector(selectSectorInfo)
  const viewType = useSelector(selectViewType)
  const period = useSelector(selectPeriod)
  const checkedTableConstants = useSelector(selectCheckedTableConstants)

  // Actions
  const downloadCsv = () => {
    const params = {
      TimeFrequency: viewType,
      NumberOfPeriod: period,
      IcbId: sectorInfo.icbId,
      ExchangeCode: dataType,
      Indicators: checkedTableConstants
        .map((item) => item.tableFieldCode)
        .filter((val) => val),
    }

    return downloadNonFinancialCompany(params)
  }

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <CorporateTable dataType={dataType} />
    </Panel>
  )
}
