import { useDispatch, useSelector } from 'react-redux'
import style from '../index.module.css'
import {
  checkAll,
  deleteTicker,
  selectActiveSheet,
  selectFullTickerDisplay,
  selectListSheet,
  selectTickerById,
  uncheckAll,
} from '../store/slice'

const CheckboxAll = () => {
  const dispatch = useDispatch()

  const activeSheet = useSelector(selectActiveSheet)
  const tickerDisplayId = useSelector(selectFullTickerDisplay(activeSheet))
  const tickerById = useSelector(selectTickerById(activeSheet))
  const listSheet = useSelector(selectListSheet)

  const isHaveCheck = tickerDisplayId.some((id) => tickerById[id]?.check)

  const handleCheckAll = () => {
    dispatch(checkAll())
  }

  const handleUncheckAll = () => {
    dispatch(uncheckAll())
  }

  const handleDeleteTicker = () => {
    dispatch(deleteTicker())
  }

  const isFiinXTemplate = !!listSheet.find(
    (sheet) => sheet.value === activeSheet,
  )?.templateGroup

  return (
    <div
      className={`d-flex ali-center justify-content-center ${style.checkboxContainer}`}
    >
      {!isHaveCheck ? (
        <label className={`d-flex justify-content-center ${style.checkboxAll}`}>
          <input
            type="checkbox"
            className={`checkbox line cursor-pointer ${style.inputCheckAll} ${
              isFiinXTemplate ? style.disabled : ''
            }`}
            onClick={handleCheckAll}
            checked={false}
            readOnly
            disabled={isFiinXTemplate}
          />
        </label>
      ) : (
        <div className={style.btnMinus}>
          <button
            disabled={isFiinXTemplate}
            onClick={handleUncheckAll}
            className="cursor-pointer"
          >
            -
          </button>
        </div>
      )}
      <div className={style.checkAllBorder} />
      <button
        disabled={isFiinXTemplate}
        onClick={handleDeleteTicker}
        className={`${style.btnTrash} ${isFiinXTemplate ? style.disabled : ''}`}
      >
        <i className="icon-trash" />
      </button>
    </div>
  )
}

export default CheckboxAll
