import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  FULL_COMPONENT,
} from '../../../../utils/EventEmitter'
import { MAP_KEY } from '../Content'
import ChartColumn from './ChartColumn'
import ChartLine from './ChartLine'
import { CHARTS, CHART_TYPE, CHART_TYPE_HEIGHT } from './constant'
import { getChartColumnKeyYAxis, getChartLineKeyYAxis } from './helper'
import style from './index.module.css'
import { selectChartData, selectChartType, selectLoading } from './store/slice'

const CHART_ITEM_PADDING = 12

const Charts = ({ width, height }) => {
  const chartType = useSelector(selectChartType)
  const loading = useSelector(selectLoading)
  const data = useSelector(selectChartData)

  const [isFullComponent, setIsFullComponent] = useState(false)

  const chartItemWidth = isFullComponent ? width / 2 - 2 : width / 4
  const chartItemHeight = isFullComponent ? height / 2 : height
  const chartMargin = isFullComponent ? 20 : 0

  useEffect(() => {
    EventEmitter.subscribe(FULL_COMPONENT, (key) => {
      if (key === MAP_KEY.VALUATION) setIsFullComponent(true)
    })
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, (key) => {
      if (key === MAP_KEY.VALUATION) setIsFullComponent(false)
    })
  }, [setIsFullComponent])

  return (
    <div className="d-flex" style={{ flexWrap: isFullComponent ? 'wrap' : '' }}>
      {Object.values(CHARTS).map((type, index) => (
        <div
          key={type}
          style={{
            width: chartItemWidth,
            borderLeft:
              isFullComponent && index === 2 ? '1px solid #191d25' : '',
          }}
          className={style.chartItem}
        >
          <div className={style.chartTitle}>
            <Span style={{ fontSize: 10, fontWeight: 600 }}>
              <Translate
                value={`corporate.peerValuation.overview.chart.${CHARTS[type]}.title`}
                className="canvas-chart-title"
              />
            </Span>
          </div>
          <div
            className={style.chartContent}
            style={{
              height: chartItemHeight - CHART_TYPE_HEIGHT,
              padding: `0px ${CHART_ITEM_PADDING}px`,
            }}
          >
            {loading ? (
              <Loading />
            ) : data.length ? (
              chartType === CHART_TYPE.COLUMN ? (
                <ChartColumn
                  data={data}
                  width={chartItemWidth - 2 * CHART_ITEM_PADDING}
                  height={chartItemHeight - CHART_TYPE_HEIGHT - chartMargin}
                  type={type}
                  keyXAxis="year"
                  keyYAxis={getChartColumnKeyYAxis(type)}
                />
              ) : (
                <ChartLine
                  data={data}
                  width={chartItemWidth - 2 * CHART_ITEM_PADDING}
                  height={chartItemHeight - CHART_TYPE_HEIGHT - chartMargin}
                  type={type}
                  keyXAxis="tradingDateId"
                  keyYAxis={getChartLineKeyYAxis(type)}
                />
              )
            ) : (
              <NoData />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default React.memo(Charts)
