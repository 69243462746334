import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../common/chart/constants'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import style from '../../index.module.css'
import { COLOR_GROWTH_CHART, MONTH_LIST_FILTER_EN } from '../constants'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 12,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 10, right: 20 }

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKeys,
  activeName,
}) => {
  const locale = useSelector((state) => state.i18n.locale)

  const getMonthKeyXAxisbyLocale = (month, locale) => {
    if (locale === 'en') {
      return MONTH_LIST_FILTER_EN.find((item) => item.value === month).name
    } else {
      return month < 10 ? `Th0${month}` : `Th${month}`
    }
  }

  const growthChartTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{activeName}</span>
        </div>
        <table>
          <tbody>
            {lineKeys.map((item) => (
              <tr className={style.trTable} key={item}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>
                      {getMonthKeyXAxisbyLocale(valueTooltip.time, locale)} -{' '}
                      {item}
                    </Span>
                    :
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span style={{ fontSize: 11 }}>
                    {`${formatVal(valueTooltip[item])} ${I18n.t(
                      'economy.importExport.importExport.MILLION_USD',
                    )}`}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            tickFormatter={(val) => getMonthKeyXAxisbyLocale(val, locale)}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                label: `(${I18n.t(
                  'economy.importExport.importExport.MILLION_USD',
                )})`,
                labelPosition: AXIS_LABEL_POSITION.LEFT,
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              growthChartTooltip(payload, decimalLengths)
            }
          >
            {lineKeys.map((item) => (
              <Line
                yAxisId="left"
                dataKey={item}
                key={item}
                type="linear"
                stroke={COLOR_GROWTH_CHART[lineKeys.indexOf(item)]}
                dot={false}
                activeDot={false}
                isAnimationActive={false}
                strokeWidth={1.5}
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
