import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { LASTEST_YEARS } from '../../common/constant'
import style from './index.module.css'
import { changeFilter, selectFilter, selectRevenueTypes } from './store/slice'
import { getCorpRevenueType } from './store/thunk'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)
  const filter = useSelector(selectFilter)
  const revenueTypes = useSelector(selectRevenueTypes)

  const handleChangRevenueType = (revenueTypeId) => {
    dispatch(changeFilter({ revenueTypeId }))
  }
  const handleChangeYear = (year) => {
    dispatch(changeFilter({ year, revenueTypeId: null }))
  }

  useEffect(() => {
    if (basicInfo.organizationId && filter.year) {
      dispatch(
        getCorpRevenueType({
          OrganizationId: basicInfo.organizationId,
          Year: filter.year,
        }),
      )
    }
  }, [filter.year, basicInfo.organizationId, locale])

  useEffect(() => {
    if (revenueTypes.length) {
      handleChangRevenueType(revenueTypes[0]?.revenueTypeId)
    } else {
      handleChangRevenueType(null)
    }
  }, [revenueTypes])

  return (
    <div className="d-flex">
      <div className={style.selectYear}>
        <SelectCustom
          isI18n={false}
          selectData={LASTEST_YEARS}
          value={filter.year}
          handleChange={handleChangeYear}
          appendStyle={{ fontWeight: 'normal' }}
        />
      </div>
      {filter.revenueTypeId && (
        <div className={style.selectRevenue}>
          <SelectCustom
            isI18n={false}
            selectData={revenueTypes.map((item) => ({
              value: item.revenueTypeId,
              name: item.revenueTypeName,
            }))}
            value={filter.revenueTypeId}
            handleChange={handleChangRevenueType}
            appendStyle={{ fontWeight: 'normal' }}
          />
        </div>
      )}
    </div>
  )
}

export default Filter
