import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { SizeTracker } from '../../common/sizeTracker'
import { Header } from '../common/header'
import { PanelContainer } from '../common/panel/PanelContainer'
import { changeIsNewDashboard } from '../store/slice'

export const NewDashboard = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(changeIsNewDashboard(true))
  }, [])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Header
              data={{ name: I18n.t('financialChart.NEW_DASHBOARD_NAME') }}
            />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <PanelContainer />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
