import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'
import {
  FORMAT,
  compareTwoDateTime,
  formatDateTimeLocal,
} from '../../../utils/Datetime'
import { getDateByLocale } from '../helper'

const MASK = '99/99/9999'
const placeholderChar = '\u2000'
const defaultText = '\u2000\u2000/\u2000\u2000/\u2000\u2000\u2000\u2000'

const InputDate = ({ date, onChange, onInput, minDate, maxDate, ...props }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const eventRef = useRef(null)

  const [mask, setMask] = useState('')
  const [text, setText] = useState('')

  useEffect(() => {
    if (date) {
      setMask(MASK)
      setText(formatDateTimeLocal(date, FORMAT.DATE, locale))
    } else {
      setText('')
    }
  }, [date, locale])

  const onInputChange = (e) => {
    eventRef.current = e
    setText(e.target.value)
    onInput(e)
  }

  useEffect(() => {
    setMask(text.length ? MASK : '')
    if (!eventRef.current) return
    if (text.length === 10 && !text.includes(placeholderChar)) {
      let date = getDateByLocale(text, locale)
      if (compareTwoDateTime(date, minDate) === -1) date = new Date(minDate)
      if (compareTwoDateTime(date, maxDate) === 1) date = new Date(maxDate)
      setText(formatDateTimeLocal(date, FORMAT.DATE, locale))
      onChange(date, eventRef.current)
    }
    if (text === defaultText) {
      onChange(null, eventRef.current)
    }
  }, [text])

  return (
    <InputMask
      mask={mask}
      maskPlaceholder={placeholderChar}
      value={text}
      onChange={onInputChange}
      {...props}
    />
  )
}

InputDate.propTypes = {
  onChange: PropTypes.func.isRequired,
  onInput: PropTypes.func,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

InputDate.defaultProps = {
  minDate: new Date(1950, 0, 1),
  maxDate: new Date(2050, 0, 0),
  onInput: () => {},
}

export default InputDate
