import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span.js'
import InputFormatCurrency from '../../../../../common/input/InputFormatCurrency.js'
import {
  DispatchActionTabWithCalendar,
  calendarTabKey,
} from '../../../../../common/tabs/DispatchActionTabWithCalendar.js'
import { CORRELATION_TYPE_RADIOS, TIME_FREQUENCY_TABS } from '../constants.js'
import style from '../index.module.css'
import {
  changeFilter,
  keys,
  selectActiveMethodType,
  selectFilterCorrelationOfMarket,
} from '../store/slice.js'
import { getCorrelationOfMarket } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const methodType = useSelector(selectActiveMethodType)
  const { timeRange, isSector, remainFrom, remainTo, From, To } = useSelector(
    selectFilterCorrelationOfMarket,
  )

  // Actions
  const handleChangeTimeType = (item) => {
    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: 'timeRange',
        value: item.value,
      }),
    )
  }

  const handleChangeParams = (item) => {
    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: 'isSector',
        value: item,
      }),
    )
  }

  const handleSubmit = (value, type) => {
    const from = parseFloat(type === 1 ? value : remainFrom)
    const to = parseFloat(type === 2 ? value : remainFrom)

    const payload = {
      IssueMethodId: methodType,
      TimeRange: timeRange,
      Top: 5,
      isSector,
      FromAverageRemain: !isNaN(from) ? from : '',
      ToAverageRemain: !isNaN(to) ? to : '',
    }

    if (timeRange === calendarTabKey) {
      payload.From = From
      payload.To = To
      delete payload.TimeRange
    }

    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: type === 1 ? 'remainFrom' : 'remainTo',
        value: value,
      }),
    )

    dispatch(getCorrelationOfMarket(payload))
  }

  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: 'timeRange',
        value: payload.activeTab,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: 'From',
        value: payload.startDate,
      }),
    )
    dispatch(
      changeFilter({
        label: keys.CORRELATION_OF_MARKET,
        key: 'To',
        value: payload.endDate,
      }),
    )
  }

  // Use effect
  useEffect(() => {
    const payload =
      !remainFrom && !remainTo
        ? timeRange === calendarTabKey
          ? {
              IssueMethodId: methodType,
              Top: 5,
              isSector,
              From,
              To,
            }
          : {
              IssueMethodId: methodType,
              TimeRange: timeRange,
              Top: 5,
              isSector,
            }
        : timeRange === calendarTabKey
        ? {
            IssueMethodId: methodType,
            Top: 5,
            isSector,
            FromAverageRemain: +remainFrom,
            ToAverageRemain: +remainTo,
            From,
            To,
          }
        : {
            IssueMethodId: methodType,
            TimeRange: timeRange,
            Top: 5,
            isSector,
            FromAverageRemain: +remainFrom,
            ToAverageRemain: +remainTo,
          }

    dispatch(getCorrelationOfMarket(payload))
  }, [locale, methodType, isSector, timeRange, From, To])

  return (
    <>
      <div className="d-flex justify-content-space-between ali-center mb-8">
        <div
          className="d-flex ali-center justify-content-space-between"
          style={{ gap: 8 }}
        >
          {CORRELATION_TYPE_RADIOS.map(({ value, name }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center cursor-pointer ${
                  isSector === value ? style.radioActive : ''
                }`}
                onClick={() => handleChangeParams(value)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={isSector === value}
                  readOnly={true}
                />
                <Span
                  style={{
                    fontSize: 12,
                    fontWeight: 340,
                  }}
                >
                  <Translate value={name} />
                </Span>
              </div>
            )
          })}
        </div>

        <DispatchActionTabWithCalendar
          id="correlationOfSecondaryMarket"
          tabs={TIME_FREQUENCY_TABS}
          activeTab={timeRange}
          startDate={From}
          endDate={To}
          datePreset={TIME_FREQUENCY_TABS}
          excludeDateIntervals={[]}
          onChangeTab={handleChangeTimeType}
          onChangeCalendar={handleChangeCalendar}
        />
      </div>
      <div className="d-flex ali-center" style={{ gap: 8 }}>
        <Span style={{ fontWeight: 340 }}>
          <Translate value="bond.corporateBond.correlationOfSecondaryMarket.REMAINING" />
        </Span>
        <div
          className={`d-flex ali-center ${style.inputWrapper}`}
          style={{ gap: 4 }}
        >
          <div style={{ width: 60 }}>
            <InputFormatCurrency
              handleBlur={(value) => handleSubmit(value, 1)}
              defaultValue={remainFrom}
              className={style.input}
              decimalRender={0}
              fractionDigits={0}
            />
          </div>
        </div>
        <Span style={{ color: '#50545F' }}>-</Span>
        <div
          className={`d-flex ali-center ${style.inputWrapper}`}
          style={{ gap: 4 }}
        >
          <div style={{ width: 60 }}>
            <InputFormatCurrency
              handleBlur={(value) => handleSubmit(value, 2)}
              defaultValue={remainTo}
              className={style.input}
              decimalRender={0}
              fractionDigits={0}
            />
          </div>
        </div>
        <Span style={{ fontWeight: 340 }}>
          <Translate value="bond.corporateBond.correlationOfSecondaryMarket.MONTH" />
        </Span>
      </div>
    </>
  )
}

export default Filter
