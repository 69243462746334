import useCustomNavigate from '../../../common/hooks/useCustomNavigate'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/sectorTopInfo'
import { FEATURES } from '../../../constants/routes'
import Detail from './detail'

export const NewsReportDetail = () => {
  const navigate = useCustomNavigate()

  const handleSelect = (company) => {
    navigate(
      FEATURES.sector.components['sector-constituents'].components[
        'news-report'
      ].route,
      {
        icbCode: company.icbCode,
        icbId: company.icbId,
        icbLevel: company.icbLevel,
        icbName: company.icbName,
        parentICBId: company.parentICBId,
      },
    )
  }

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo
                title="constants.route.sector.sectorConstituents.newsReport.LABEL"
                handleSelect={handleSelect}
              />
              {size.height && (
                <div
                  className="w-100 position-relative"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <Detail />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
