import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../configs/Layout'
import { Panel } from '../../../../common/panel'
import { withWrapper } from '../../../../common/withWrapper'
import { PANEL_PADDING } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { SectorNews } from './sectorNews'
import { SectorReports } from './sectorReports'

const MAP_KEY = {
  SECTOR_NEWS: 'SECTOR_NEWS',
  SECTOR_REPORTS: 'SECTOR_REPORTS',
}

const MAP_SIZE = {
  [MAP_KEY.SECTOR_NEWS]: {
    width: `calc(300% / 4 - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 750,
    minHeight: 700,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.SECTOR_REPORTS]: {
    width: `calc(100% / 4 - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: `calc(300% / 4 + ${COMPONENT.MARGIN}px)`,
    minWidth: 750 / 3,
    minHeight: 700,
    minTop: 0,
    minLeft: 750 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const List = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title={'sector.sectorConstituents.newsReport.SECTOR_NEWS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SECTOR_NEWS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <SectorNews
          width={formatNumber(sizes[MAP_KEY.SECTOR_NEWS].width) - PANEL_PADDING}
        />
      </Panel>
      <Panel
        title={'sector.sectorConstituents.newsReport.SECTOR_REPORTS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.SECTOR_REPORTS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <SectorReports
          width={
            formatNumber(sizes[MAP_KEY.SECTOR_REPORTS].width) - PANEL_PADDING
          }
        />
      </Panel>
    </>
  )
}

List.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(List)
