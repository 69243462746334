import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../../../common/chart/constants'
import { Footer } from '../../../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import { formatVal } from '../../../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
}

const BAR_COLOR = '#facc5c'
const YAXIS_ID = 'left'
const MARGIN = { ...MARGIN_RECHARTS, right: 16 }

const ChartComponent = ({ data, width, height, keyXAxis, barKey }) => {
  const renderTooltip = (tooltipData, decimalLengths) => {
    const decimalLength = decimalLengths[YAXIS_ID] || 0
    const contentTooltip = [
      {
        label: I18n.t(
          'corporate.businessModel.penetrationAnalysis.LOAN_BY_SECTOR',
        ),
        value: formatVal(tooltipData[barKey], decimalLength || 2),
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData[keyXAxis]}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                isUseXAxisDiv
                showAllTick
                yAxis={[
                  {
                    id: YAXIS_ID,
                    keys: [barKey],
                    orientation: 'left',
                    isBarChart: true,
                    label: I18n.t(
                      'corporate.businessModel.penetrationAnalysis.BILLION',
                    ),
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                  },
                ]}
                margin={MARGIN}
                renderCustomTooltip={renderTooltip}
              >
                {({ chartContentWidth }) => (
                  <>
                    <Bar
                      isAnimationActive={false}
                      yAxisId={YAXIS_ID}
                      dataKey={barKey}
                      barSize={getColumnSizeInBarChart(
                        chartContentWidth,
                        data.length,
                      )}
                      fill={BAR_COLOR}
                    />
                  </>
                )}
              </ChartContainer>
            )}
            <Footer list={[]} />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
