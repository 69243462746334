import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import { Span } from '../../../common/html/Span'
import SelectCustom from '../../../common/selectCustom'
import TextEllipsis from '../../../common/textEllipsis'
import { Z_INDEX } from '../../../constants/Common'
import { blankValue } from '../constant'
import style from '../index.module.css'
import {
  changeCondition,
  selectItemCondition,
  selectTypeInterims,
} from '../store/slice'

const maxWidthMedia = 1500

const CriteriaValue = ({
  indicatorId,
  index,
  valueDataType,
  indicatorGroupId,
}) => {
  const dispatch = useDispatch()

  const itemRef = useRef()

  const indicatorInterimId = useSelector(
    selectItemCondition(
      `${indicatorId}.${index}.additionalCondition.indicatorInterimId`,
    ),
  )
  const functionValue = useSelector(
    selectItemCondition(`${indicatorId}.${index}.additionalCondition.function`),
  )
  const additionalCriterias = useSelector(
    selectTypeInterims(
      `${indicatorGroupId}.${valueDataType}.additionalCriterias`,
    ),
  )

  useEffect(() => {
    if (functionValue === 'None') {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.additionalCondition.function`,
          value: undefined,
        }),
      )
    }
  }, [functionValue])

  const [valueHover, setValueHover] = useState('')

  const handleChangeAdditionalCondition = (item, callback) => {
    if (item.functions && !item.function) {
    } else {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.additionalCondition.indicatorInterimId`,
          value: item.value,
        }),
      )
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.additionalCondition.interimCode`,
          value: item.interimCode,
        }),
      )
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.additionalCondition.interimName`,
          value: item.interimName,
        }),
      )
      if (item.function) {
        dispatch(
          changeCondition({
            key: `${indicatorId}.${index}.additionalCondition.function`,
            value: item.function,
          }),
        )
      } else {
        dispatch(
          changeCondition({
            key: `${indicatorId}.${index}.additionalCondition.function`,
            value: undefined,
          }),
        )
      }
      callback()
      setValueHover('')
    }
  }

  const handleHoverItem = (value) => setValueHover(value)
  const handleMouseLeaveItem = () => setValueHover('')

  const selectData = additionalCriterias.map((item) => {
    return {
      value: item.indicatorInterimId,
      name: item.interimName,
      interimCode: item.interimCode,
      functions: item.functions,
    }
  })
  selectData.unshift({
    value: blankValue,
    name: I18n.t('tool.smartScreening.BLANK'),
    interimCode: blankValue,
  })

  const isLargeDevide = window.innerWidth >= maxWidthMedia

  return (
    <div className={`input-dropdown ${style.value}`}>
      <SelectCustom
        value={indicatorInterimId}
        selectData={selectData}
        handleChange={handleChangeAdditionalCondition}
        timeoutClickOutside={200}
        renderSelect={({ isShowDropdown, styleComponent }) => {
          return (
            <span className={styleComponent.select}>
              <span className={`${styleComponent.text}`}>
                <TextEllipsis
                  isI18n={false}
                  text={
                    (functionValue ? functionValue + ' - ' : '') +
                    selectData?.find(
                      (item) => item.value === indicatorInterimId,
                    )?.name
                  }
                  zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                />
              </span>
              <i
                className={`${
                  !isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'
                } ${styleComponent.icon}`}
              />
            </span>
          )
        }}
        renderItemSelect={({
          item,
          styleComponent,
          heightItem,
          currentItemRef,
          isActiveSelected,
          turnOffActiveSelected,
          closeDropdown,
        }) => {
          return (
            <div
              key={item.value}
              onMouseEnter={() => {
                turnOffActiveSelected()
                handleHoverItem(item.value)
              }}
              onMouseLeave={handleMouseLeaveItem}
            >
              <li
                ref={
                  valueHover && valueHover === item.value
                    ? itemRef
                    : indicatorInterimId === item.value
                    ? currentItemRef
                    : undefined
                }
                className={`${styleComponent.li} ${
                  indicatorInterimId === item.value && isActiveSelected
                    ? styleComponent.active
                    : ''
                } ${style.relative}`}
                style={{ height: `${heightItem}px` }}
                onClick={() =>
                  handleChangeAdditionalCondition(item, closeDropdown)
                }
              >
                {!item.functions ? (
                  <Span className={styleComponent.textDropdown}>
                    <TextEllipsis
                      isI18n={false}
                      text={item.name}
                      zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                    />
                  </Span>
                ) : (
                  <div
                    className={`${style.boxSz} ${
                      isLargeDevide ? style.pr15 : style.pl15
                    } w-100`}
                  >
                    {!isLargeDevide && (
                      <i
                        className={`icon-caret-left ${style.iconSelectCriteriaLeft}`}
                      />
                    )}
                    <Span className={styleComponent.textDropdown}>
                      <TextEllipsis
                        isI18n={false}
                        text={item.name}
                        zIndexTooltip={Z_INDEX.DROPDOWN + 1}
                      />
                    </Span>
                    {isLargeDevide && (
                      <i
                        className={`icon-caret-right ${style.iconSelectCriteria}`}
                      />
                    )}
                    {valueHover === item.value && (
                      <Dropdown
                        parentRef={itemRef}
                        position={
                          isLargeDevide
                            ? positionDropdown.RIGHT_TOP
                            : positionDropdown.LEFT_TOP
                        }
                      >
                        <div className={`${style.dropdownCriteria}`}>
                          <ul className={style.ulCriteria}>
                            {item.functions.map((itemFunction, index) => (
                              <li
                                key={index}
                                className={`${styleComponent.li}`}
                                style={{
                                  height: `${heightItem}px`,
                                }}
                                onClick={() =>
                                  handleChangeAdditionalCondition(
                                    {
                                      ...item,
                                      function: itemFunction,
                                    },
                                    closeDropdown,
                                  )
                                }
                              >
                                {itemFunction}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </Dropdown>
                    )}
                  </div>
                )}
              </li>
            </div>
          )
        }}
      />
    </div>
  )
}

CriteriaValue.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  index: PropTypes.number.isRequired,
  valueDataType: PropTypes.number.isRequired,
  indicatorGroupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
}

export default CriteriaValue
