import IndicesSectorProxy from '../../../proxies/tool/dataExplorer/IndicesSectorProxy'
import { ServiceBase } from '../../ServiceBase'

class IndicesSectorServices extends ServiceBase {
  getIndicator(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.indicesSector.getIndicator',
      params,
    )(() => IndicesSectorProxy.getIndicator(params))
  }

  getResult(params) {
    return this.getData(() => IndicesSectorProxy.getResult(params))
  }

  exportExcel(params) {
    return this.getFileDownload(() => IndicesSectorProxy.exportExcel(params))
  }
}

export default new IndicesSectorServices()
