import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../utils/Value'
import {
  selectDataDetailMetrics,
  selectDetailMetricsTickers,
  selectFilter,
  selectLoadingDetailMetrics,
  selectReCalcWiths,
} from '../store/slice'
import { getInsuDetailMetrics } from '../store/thunk'
import { METRIC_CODE } from './constant'

const TableDetailMetrics = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const tickers = useSelector(selectDetailMetricsTickers)
  const loading = useSelector(selectLoadingDetailMetrics)
  const reCalcWidths = useSelector(selectReCalcWiths)
  const filter = useSelector(selectFilter)

  useEffect(() => {
    if (basicInfo.organizationId && filter.year && filter.quarter) {
      dispatch(
        getInsuDetailMetrics({
          OrganizationId: basicInfo.organizationId,
          Year: filter.year,
          Quarter: filter.quarter,
        }),
      )
    }
  }, [filter, basicInfo.organizationId])

  return (
    <div className="h-100">
      {loading ? (
        <Loading />
      ) : tickers.length ? (
        <Table
          ids={Object.values(METRIC_CODE)}
          getDataFromRedux={selectDataDetailMetrics}
          isLoading={loading}
          schema={[
            'indicator',
            basicInfo.ticker,
            ...tickers.filter((ticker) => ticker !== basicInfo.ticker),
          ].map((ticker, index) => {
            const colId = ticker
            return {
              colId,
              title:
                index === 0
                  ? 'corporate.businessModel.penetrationAnalysis.DETAIL_METRICS'
                  : ticker,
              isI18n: false,
              thStyle: {
                textAlign: index === 0 ? 'left' : 'right',
              },
              tdStyle: {
                textAlign: index === 0 ? 'left' : 'right',
              },
              render: (val, rowId) => {
                if (index === 0)
                  return (
                    <Translate
                      value={`corporate.businessModel.penetrationAnalysis.insuTable.${rowId}`}
                    />
                  )
                switch (rowId) {
                  case METRIC_CODE.TPIS1:
                  case METRIC_CODE.YoYGrowthIS1:
                  case METRIC_CODE.GWP:
                  case METRIC_CODE.LIG:
                  case METRIC_CODE.NLIG:
                  case METRIC_CODE.RT0338:
                  case METRIC_CODE.RT0339:
                    return valToPercent(val)
                  case METRIC_CODE.NI153:
                  case METRIC_CODE.NI154:
                  case METRIC_CODE.NI155:
                  case METRIC_CODE.IS73:
                    return formatVal(valDivBillion(val))
                  default:
                    return val
                }
              },
            }
          })}
          isLargeHead
          reCalcWidths={reCalcWidths}
          stickyFirstColumn
          stickyBgColor="#212833"
          hasFooter={false}
          hasTooltip={false}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default TableDetailMetrics
