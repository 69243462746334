import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import {
  selectIcbInfoById,
  selectIndexInfoById,
  selectStockInfoById,
} from '../../../../common/masterData/store/slice'
import { NoData } from '../../../../common/noData'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { BUBBLE_COLOR_TYPE, DEFAULT_BUBBLE_COLOR, LIST_TYPE } from '../constant'
import style from '../index.module.css'
import {
  changeAllowCallData,
  changeFilteredOrganizationIds,
  changeSelectedOrganizationGroups,
  changeSelectedOrganizations,
  LOADING_TYPE,
  selectBubbleColor,
  selectFilteredOrganizationIds,
  selectFilterExchanges,
  selectFilterMarketCap,
  selectLoading,
  selectSelectedOrganizationGroups,
  selectSelectedOrganizations,
  selectWatchListInfoById,
} from '../store/slice'
import { getValueInTrillion } from './company/helper'

const selectMethod = (type) => {
  switch (type) {
    case LIST_TYPE.WATCHLIST:
      return selectWatchListInfoById
    case LIST_TYPE.INDEX:
      return selectIndexInfoById
    case LIST_TYPE.SECTOR:
      return selectIcbInfoById
    default:
      return selectIcbInfoById
  }
}

const getAttrName = (type) => {
  switch (type) {
    case LIST_TYPE.WATCHLIST:
      return 'watchListName'
    case LIST_TYPE.INDEX:
      return 'groupName'
    case LIST_TYPE.SECTOR:
      return 'icbName'
    default:
      return ''
  }
}

const TopItem = ({ item }) => {
  const dispatch = useDispatch()

  const selectedGroups = useSelector(selectSelectedOrganizationGroups)
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const exchanges = useSelector(selectFilterExchanges)
  const marketCap = useSelector(selectFilterMarketCap)

  const groupName = useSelector(
    selectMethod(item.type)(item.id, getAttrName(item.type)),
  )

  const handleDelete = () => {
    const newOrganizations = JSON.parse(JSON.stringify(selectedOrganizations))
    let newFilteredOrgIds = [...filteredOrgIds]

    Object.keys(newOrganizations).forEach((orgId) => {
      const org = newOrganizations[orgId]

      if (org?.type === item.type && org?.typeId === item.id) {
        if (org.prevGroups?.length) {
          const lastGroup = org.prevGroups.pop()
          org.type = lastGroup.type
          org.typeId = lastGroup.id
          org.color = lastGroup.color

          if (
            !newFilteredOrgIds.includes(org.organizationId) &&
            exchanges.includes(org.exchangeCode) &&
            (getValueInTrillion(marketCap.from) || 0) <= (org.marketCap || 0) &&
            (getValueInTrillion(marketCap.to) || Infinity) >=
              (org.marketCap || 0)
          ) {
            newFilteredOrgIds.push(org.organizationId)
          }
        } else {
          delete newOrganizations[orgId]
          newFilteredOrgIds = newFilteredOrgIds.filter(
            (id) => id !== Number(orgId),
          )
        }
      } else if (org.prevGroups?.length) {
        const groupIndex = org.prevGroups.findIndex(
          (group) => group.type === item.type && group.id === item.id,
        )
        if (groupIndex !== -1) {
          org.prevGroups.splice(groupIndex, 1)
        }
      }
    })
    dispatch(changeSelectedOrganizations(newOrganizations))
    dispatch(changeFilteredOrganizationIds(newFilteredOrgIds))
    dispatch(
      changeSelectedOrganizationGroups(
        selectedGroups.filter(
          (g) => !(g.type === item.type && g.id === item.id),
        ),
      ),
    )
  }

  return (
    <div className={`d-flex ali-center ${style.topItem}`}>
      <i
        className={`icon-delete-stroke ${style.iconDelete}`}
        onClick={handleDelete}
      />
      <div className={style.circle} style={{ background: item.color }} />
      <div className="flex-1" style={{ minWidth: 0 }}>
        <TextEllipsis isI18n={false} text={groupName} />
      </div>
    </div>
  )
}

const BottomItem = ({ item }) => {
  const dispatch = useDispatch()

  const selectedGroups = useSelector(selectSelectedOrganizationGroups)
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const bubbleColor = useSelector(selectBubbleColor)

  const itemColor =
    bubbleColor === BUBBLE_COLOR_TYPE.GROUP
      ? item?.color || DEFAULT_BUBBLE_COLOR
      : bubbleColor === BUBBLE_COLOR_TYPE.COMPANY
      ? item?.companyColor || DEFAULT_BUBBLE_COLOR
      : DEFAULT_BUBBLE_COLOR

  const handleDelete = () => {
    dispatch(changeAllowCallData(false))
    const { [item.organizationId]: deletedOrg, ...newOrganizations } =
      selectedOrganizations
    let newGroups = JSON.parse(JSON.stringify(selectedGroups))

    if (
      deletedOrg.type !== LIST_TYPE.COMPANY &&
      Object.values(newOrganizations).every(
        (org) =>
          !(org.type === deletedOrg.type && org.typeId === deletedOrg.typeId),
      )
    ) {
      newGroups = newGroups.filter(
        (group) =>
          !(group.type === deletedOrg.type && group.id === deletedOrg.typeId),
      )

      Object.keys(newOrganizations).forEach((orgId) => {
        const org = newOrganizations[orgId]
        if (org.prevGroups?.length) {
          const groupIndex = org.prevGroups.findIndex(
            (group) =>
              group.type === deletedOrg.type && group.id === deletedOrg.typeId,
          )
          if (groupIndex !== -1) {
            org.prevGroups.splice(groupIndex, 1)
          }
        }
      })
    }

    newGroups = newGroups.filter((group) =>
      Object.values(newOrganizations).some(
        (org) =>
          (org.type === group.type && org.typeId === group.id) ||
          org?.prevGroups?.some(
            (g) => g.type === group.type && g.id === group.id,
          ),
      ),
    )

    dispatch(changeSelectedOrganizationGroups(newGroups))
    dispatch(changeSelectedOrganizations(newOrganizations))
    dispatch(
      changeFilteredOrganizationIds(
        filteredOrgIds.filter((id) => id !== item.organizationId),
      ),
    )
  }

  const ticker = useSelector(selectStockInfoById(item.organizationId, 'ticker'))
  const taxCode = useSelector(
    selectStockInfoById(item.organizationId, 'taxCode'),
  )
  const companyName = useSelector(
    selectStockInfoById(item.organizationId, 'organizationShortName'),
  )

  return (
    <div className={`d-flex ali-center ${style.topItem}`}>
      <i
        className={`icon-delete-stroke ${style.iconDelete}`}
        onClick={handleDelete}
      />
      <div className="d-flex ali-center">
        <div className={style.circle} style={{ background: itemColor }} />
        <div className={style.itemTicker}>
          <TextEllipsis
            isI18n={false}
            text={ticker !== EMPTY_VALUE ? ticker : taxCode}
          />
        </div>
      </div>
      <div className="flex-1" style={{ minWidth: 0 }}>
        <Span className={style.disabled} style={{ fontSize: 11 }}>
          <TextEllipsis isI18n={false} text={companyName} />
        </Span>
      </div>
    </div>
  )
}

const SelectedItem = () => {
  const selectedOrganizationGroups = useSelector(
    selectSelectedOrganizationGroups,
  )
  const selectedOrganizations = useSelector(selectSelectedOrganizations)
  const filteredOrgIds = useSelector(selectFilteredOrganizationIds)
  const loading = useSelector(selectLoading(LOADING_TYPE.DEFAULT))

  const renderItems = () => {
    return (
      <>
        {selectedOrganizationGroups.length ? (
          <div>
            {selectedOrganizationGroups.map((item, index) => (
              <TopItem item={item} key={index} />
            ))}
          </div>
        ) : null}
        {selectedOrganizationGroups.length && filteredOrgIds.length ? (
          <div className={style.line} />
        ) : null}
        {filteredOrgIds.length ? (
          <div>
            {filteredOrgIds.map((id) => (
              <BottomItem item={selectedOrganizations[id]} key={id} />
            ))}
          </div>
        ) : null}
      </>
    )
  }

  return (
    <div className={style.mt24}>
      <Span className={style.disabled} style={{ fontSize: 11 }}>
        <Translate value="sector.sectorConstituents.segmentation.SELECTED_ITEMS" />
      </Span>
      <div className={`${style.mt4} ${style.selectedItem}`}>
        <ScrollComponent>
          {loading ? (
            <Loading />
          ) : selectedOrganizationGroups.length === 0 &&
            filteredOrgIds.length === 0 ? (
            <NoData />
          ) : (
            renderItems()
          )}
        </ScrollComponent>
      </div>
    </div>
  )
}

export default SelectedItem
