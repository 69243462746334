import { PERIOD_TYPES, TABLE_COL_KEYS, TABLE_TABS } from '../constants'
import { allSchema, allTableThSchema, allThCellTopById } from './allHelpers'
import {
  detailSchema,
  detailTableThSchema,
  detailThCellTopById,
} from './detailHelpers'

export const getTableSchema = ({ tableTab, locale, onClickTicker }) => {
  switch (tableTab) {
    case TABLE_TABS.ALL:
      return allSchema({ onClick: onClickTicker })
    case TABLE_TABS.DETAIL:
      return detailSchema({ locale, onClick: onClickTicker })
    default:
      return []
  }
}

export const getTableThSchema = (tableTab) => {
  switch (tableTab) {
    case TABLE_TABS.ALL:
      return allTableThSchema()
    case TABLE_TABS.DETAIL:
      return detailTableThSchema()
    default:
      return [[], []]
  }
}

export const getTableThCellTopById = (tableTab) => {
  switch (tableTab) {
    case TABLE_TABS.ALL:
      return allThCellTopById
    case TABLE_TABS.DETAIL:
      return detailThCellTopById
    default:
      return
  }
}

export const getParams = (filters, tableTab, isDownload = false) => {
  let params = {
    MostRecent: filters.period === PERIOD_TYPES.MOST_RECENT,
    Month:
      filters.period === PERIOD_TYPES.SELECT_TIME_PERIOD
        ? filters.month
        : undefined,
    Year:
      filters.period === PERIOD_TYPES.SELECT_TIME_PERIOD
        ? filters.year
        : undefined,
    SortField: filters.sortField || getSortFieldDefaultByTableTab(tableTab),
    SortOrder: filters.sortOrder,
  }

  if (tableTab === TABLE_TABS.DETAIL) {
    params = { ...params, AssetId: filters.assetId }
  }

  if (!isDownload) {
    params = {
      ...params,
      Page: filters.page,
      PageSize: 1000,
    }
  }

  return params
}

export const getSortFieldDefaultByTableTab = (tableTab) => {
  switch (tableTab) {
    case TABLE_TABS.ALL:
      return TABLE_COL_KEYS.COL_ALL_VOLUME
    case TABLE_TABS.DETAIL:
      return TABLE_COL_KEYS.COL_DETAIL_FUND_HOLDING_PERCENT_TOTAL_ASSET
    default:
      return ''
  }
}
