export const chartTypes = {
  COLUMN_CHART: 'Column',
  PIE_CHART: 'Pie',
  STACKED_BAR_CHART: 'StackedColumn',
}

export const CHART_ORDER = {
  CHART_1: 'CHART_1',
  CHART_2: 'CHART_2',
}

export const YEAR_OPTIONS = (function () {
  const MIN_YEAR_OPTION = 2003
  const year = new Date().getFullYear()
  const range = year - MIN_YEAR_OPTION + 1
  return [...Array(range).keys()].map((item) => {
    return {
      name: String(year - item),
      value: String(year - item),
    }
  })
})()

export const TIME_FRAME_OPTIONS = [
  {
    title: 'corporate.businessModel.segmentalAnalysis.ANNUAL',
    value: 'OneYear',
    num: '',
  },
  { title: '3M', value: 'ThreeMonths', num: 3 },
  { title: '6M', value: 'SixMonths', num: 6 },
  { title: '9M', value: 'NineMonths', num: 9 },
]

export const VALUE_OPTIONS = [
  { title: 'corporate.businessModel.segmentalAnalysis.VALUE', value: 'value' },
  {
    title: 'corporate.businessModel.segmentalAnalysis.VALUE_PERCENT',
    value: 'percentValue',
  },
]

export const STACK_VALUE_KEYS = {
  VALUE: ['value1', 'value2', 'value3', 'value4', 'value5'],
  PERCENT_VALUE: ['value1_P', 'value2_P', 'value3_P', 'value4_P', 'value5_P'],
}

export const STACK_COLORS = {
  2: ['#185999', '#fff6df'],
  3: ['#185999', '#fff6df', '#fe7e6d'],
  4: ['#185999', '#3cabeb', '#fff6df', '#fe7e6d'],
  5: ['#185999', '#40abff', '#f6ad5c', '#a6cf98', '#fff6df'],
}
