export const BREAK_NUMBER = 18

export const separate = (payload) => {
  if (payload.length <= BREAK_NUMBER) {
    return {
      left: payload,
      right: [],
    }
  }

  const breakPoint = Math.ceil(payload.length / 2)
  return {
    left: payload.slice(0, breakPoint),
    right: payload.slice(breakPoint),
  }
}
