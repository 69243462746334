import { useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import Dropdown, { positionDropdown } from '../../../../../common/dropdown'
import {
  gradientColorTypes,
  lineStyleTypes,
  lineWidthTypes,
  statisticalMethodDisplay,
} from '../../constants'
import styles from '../../style.module.css'
import { RadioComponent } from '../RadioComponent'
import { ChartColorComponent } from '../chartColorComponent'

export const MethodItemComponent = ({
  statisticalMethodSettingsRef,
  isShowSetting,
  data,
  onChange,
}) => {
  const parentRef = useRef()

  const [isShowPopup, setIsShowPopup] = useState(false)
  const [lineStyle, setLineStyle] = useState(data.lineStyle)
  const [lineWidth, setLineWidth] = useState(data.lineWidth)
  const [color, setColor] = useState(data.color)
  const [gradientColor, setGradientColor] = useState(gradientColorTypes.SOLID)

  const handleChangeCheckbox = () => {
    onChange({ ...data, isCheck: !data.isCheck })
  }

  const handleShowPopup = () => {
    if (!isShowSetting) return
    setIsShowPopup(true)
  }

  const handleClosePopup = () => {
    setIsShowPopup(false)
    onChange({ ...data, lineStyle, lineWidth, color })
  }

  return (
    <div className={styles.settingBlockItemSelectItem}>
      <div className="d-flex ali-center" style={{ cursor: 'pointer' }}>
        <label className={styles.labelCheckbox}>
          <input
            checked={data.isCheck}
            type="checkbox"
            className={`checkbox line cursor-pointer ${styles.checkbox}`}
            onChange={handleChangeCheckbox}
          />
        </label>
        <div className={styles.label} onClick={handleChangeCheckbox}>
          <Translate value={statisticalMethodDisplay[data.value]} />
        </div>
      </div>
      <div
        ref={parentRef}
        className={[styles.iconSetting, isShowPopup ? styles.active : ''].join(
          ' ',
        )}
        onClick={handleShowPopup}
      >
        {isShowSetting && (
          <i
            className={'icon-setting-bold'}
            style={{ fontSize: 10, color: '#8b8b8b', padding: 5 }}
          />
        )}
      </div>

      <Dropdown
        parentRef={parentRef}
        isShow={isShowPopup}
        dropdownSpace={20}
        position={positionDropdown.RIGHT_BOTTOM}
      >
        <HandleClickOutside
          handleClickOutside={handleClosePopup}
          exceptRef={parentRef}
        >
          <div
            ref={statisticalMethodSettingsRef}
            className={styles.settingContainer}
            style={{ paddingRight: 16, paddingLeft: 16 }}
          >
            <RadioComponent
              isParent
              title="financialChart.LINE_STYLE"
              value={lineStyle}
              options={Object.keys(lineStyleTypes).map((key) => ({
                title: 'financialChart.' + key,
                value: lineStyleTypes[key],
              }))}
              onChange={setLineStyle}
              itemClass={styles.settingBlockItemChartTypeRadio}
            />
            <RadioComponent
              isParent
              title="financialChart.LINE_WIDTH"
              value={lineWidth}
              options={Object.keys(lineWidthTypes).map((key) => ({
                title: 'financialChart.' + key,
                value: lineWidthTypes[key],
              }))}
              onChange={setLineWidth}
              itemClass={styles.settingBlockItemChartTypeRadio}
            />
            <ChartColorComponent
              isHideGradient
              isParent
              value={color}
              gradientValue={gradientColor}
              onChange={setColor}
              onChangeGradient={setGradientColor}
            />
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
