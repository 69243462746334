import { useSelector } from 'react-redux'
import { DataTable } from '.'
import { Panel } from '../../../../../../common/panel'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { selectQuarterType, selectYearType } from './store/slice'
import { downloadPerformanceDownloadTable } from './store/thunk'

export const PanelDataTable = ({
  panelRefs,
  mapKey,
  sizes,
  setSizes,
  dataType,
}) => {
  // Use selector
  const sectorInfo = useSelector(selectSectorInfo)
  const yearType = useSelector(selectYearType)
  const quarterType = useSelector(selectQuarterType)

  // Actions
  const downloadTable = () => {
    return downloadPerformanceDownloadTable({
      IcbId: sectorInfo.icbId,
      ExchangeCode: dataType,
      Year: yearType,
      Quarter: quarterType,
    })
  }

  return (
    <Panel
      title={'sector.financialAnalysis.security.performance.DATA_TABLE'}
      panelRefs={panelRefs}
      panelKey={mapKey.DATA_TABLE}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadTable}
    >
      <DataTable dataType={dataType} />
    </Panel>
  )
}
