import { createAsyncThunk } from '@reduxjs/toolkit'
import HeatmapService from '../../../../core/services/market/HeatmapService'

export const getHeatMapChart = createAsyncThunk(
  'market/heatmap/GET_HEATMAP_CHART',
  async (data, { rejectWithValue }) => {
    const response = await HeatmapService.getHeatMapChart(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const refreshHeatMapChart = createAsyncThunk(
  'market/heatmap/REFRESH_HEATMAP_CHART',
  async (data, { rejectWithValue }) => {
    const response = await HeatmapService.getHeatMapChart(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
