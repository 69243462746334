import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Panel } from '../../../common/panel'
import PopupAlertSuccess from '../../../common/popup/PopupAlertSuccess'
import { SizeTracker } from '../../../common/sizeTracker'
import { convertLanguage } from '../../../setting/helper'
import { selectDataGeneralInfo } from '../../../setting/slice'
import Content from './Content'
import Filter from './Filter'
import { valueTab } from './constants'
import {
  changeIsCreate,
  resetBondInformation,
  resetStore,
  selecBondIds,
  selectActiveTab,
  selectCreateBondId,
  selectCreateBondStatus,
} from './store/slice'
import { exportFile, getDataBondInfo } from './store/thunk'
const Index = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { languageId } = useSelector(selectDataGeneralInfo)
  const lang = convertLanguage(languageId)
  const locale = useSelector((state) => state.i18n.locale)
  const previousValueRef = useRef(lang)
  const dispatch = useDispatch()
  const BondIds = useSelector(selecBondIds)
  const createBondId = useSelector(selectCreateBondId)
  const activeTab = useSelector(selectActiveTab)
  const createStatus = useSelector(selectCreateBondStatus)

  const [isShowCreate, setIsShowCreate] = useState(false)

  useEffect(() => {
    if (activeTab === valueTab.BOND_DEFAULT) {
      if (BondIds) {
        dispatch(
          getDataBondInfo({
            data: { BondIds, isUserBond: false },
          }),
        )
      }

      return
    }

    if (!createBondId) {
      dispatch(resetBondInformation())
      return
    }

    dispatch(
      getDataBondInfo({
        data: { BondIds: createBondId, isUserBond: true },
      }),
    )
  }, [BondIds, createBondId, activeTab])

  useEffect(() => {
    const previousValue = previousValueRef.current

    if (previousValue !== locale) {
      if (BondIds && activeTab === valueTab.BOND_DEFAULT) {
        dispatch(
          getDataBondInfo({
            data: { BondIds, isUserBond: false },
            isEditlocale: true,
          }),
        )
      } else if (createBondId && activeTab === valueTab.BOND_DEFAULT) {
        dispatch(
          getDataBondInfo({
            data: { BondIds: createBondId, isUserBond: true },
            isEditlocale: true,
          }),
        )
      } else {
        dispatch(resetBondInformation())
      }

      previousValueRef.current = locale
    }
  }, [locale, activeTab, createBondId])

  const exportFileFC = () => {
    return exportFile({
      bondId: activeTab === valueTab.BOND_DEFAULT ? BondIds : createBondId,
      isUserBond: activeTab !== valueTab.BOND_DEFAULT,
    })
  }

  useEffect(() => {
    if (createStatus) {
      setIsShowCreate(true)
      dispatch(changeIsCreate(false))
    }
  }, [createStatus])

  useEffect(() => {
    return () => {
      dispatch(resetStore())
    }
  }, [])

  return (
    <>
      <Panel
        title="bond.valuation.valuation.infoSec"
        panelRefs={panelRefs}
        panelKey={panelKey}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom={true}
        isDownloadCsv={true}
        downloadCsv={() => exportFileFC()}
      >
        <div className="h-100">
          <SizeTracker>
            {(size) => {
              return (
                <>
                  <Filter />
                  {size.height && (
                    <div style={{ height: `calc(100% - ${size.height}px)` }}>
                      <Content />
                    </div>
                  )}
                  <PopupAlertSuccess
                    message={
                      <Translate value="bond.bondScreening.createBond.MESSAGE_CREATE" />
                    }
                    isShow={isShowCreate}
                    handleClose={() => setIsShowCreate(false)}
                  />
                </>
              )
            }}
          </SizeTracker>
        </div>
      </Panel>
    </>
  )
}

export default Index
