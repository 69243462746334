import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { Table } from '../../../../common/table'
import TextEllipsis from '../../../../common/textEllipsis'
import { EMPTY_VALUE } from '../../../../constants/Common'
import {
  selectLoadingDefaultItems,
  selectSelectedItems,
} from '../filter/store/slice'
import NameCell from './NameCell'
import { INDICATORS } from './constant'
import {
  changeDataEmpty,
  changePage,
  selectLoading,
  selectPage,
  selectReCalcWidths,
  selectRowIds,
  selectTotalPage,
  selectValueById,
} from './store/slice'

const ReportTable = () => {
  const dispatch = useDispatch()

  // Use selector
  const selectedItems = useSelector(selectSelectedItems)
  const loadingDefaultItems = useSelector(selectLoadingDefaultItems)
  const loading = useSelector(selectLoading)
  const rowIds = useSelector(selectRowIds)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const page = useSelector(selectPage)
  const totalPage = useSelector(selectTotalPage)

  const handleChangePage = (value) => {
    dispatch(changePage(value))
  }

  useEffect(() => {
    if (!selectedItems.length) {
      dispatch(changeDataEmpty())
    }
  }, [selectedItems.length])

  return (
    <div className="h-100">
      {loading || loadingDefaultItems ? (
        <Loading />
      ) : rowIds.length ? (
        <Table
          ids={rowIds}
          getDataFromRedux={selectValueById}
          isLoading={loading}
          schema={Object.keys(INDICATORS).map((key, index) => {
            const colId = INDICATORS[key]
            return {
              colId,
              title:
                'sector.sectorConstituents.valuation.valuationReport.' + key,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                textAlign: colId === INDICATORS.download ? 'center' : 'left',
              },
              canCustomTd: [
                INDICATORS.title,
                INDICATORS.name,
                INDICATORS.issuer,
              ].includes(colId),
              render: (val, rowId) => {
                switch (colId) {
                  case INDICATORS.date:
                    return <DateTimeSpan date={val} />
                  case INDICATORS.name:
                    return (
                      <td style={{ maxWidth: 0, width: '10%' }}>
                        <NameCell rowId={rowId} value={val} />
                      </td>
                    )
                  case INDICATORS.issuer:
                    return (
                      <td style={{ maxWidth: 0, width: '20%' }}>
                        <TextEllipsis isI18n={false} text={val} />
                      </td>
                    )
                  case INDICATORS.title:
                    return (
                      <td style={{ maxWidth: 0, width: '70%' }}>
                        <TextEllipsis isI18n={false} text={val} />
                      </td>
                    )
                  case INDICATORS.download:
                    return !val || val === EMPTY_VALUE ? (
                      <></>
                    ) : (
                      <Span style={{ fontSize: 10 }} className="cursor-pointer">
                        <a href={val} target="_blank" rel="noopener noreferrer">
                          <i className="icon-download" />
                        </a>
                      </Span>
                    )
                  default:
                    return <span style={{ whiteSpace: 'nowrap' }}>{val}</span>
                }
              },
            }
          })}
          reCalcWidths={reCalcWidths}
          stickyFirstColumn
          hasFooter={false}
          hasTooltip={false}
          hasPagination
          currentPage={page}
          totalPage={totalPage}
          handleChangePage={handleChangePage}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default ReportTable
