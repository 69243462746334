import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectTimeFrequency } from '../totalRegisteredFDI/store/slice'
import { ChartComponent } from './ChartComponent'
import FilterVSIC from './dropdown'
import { convertData } from './helper'
import { selectDataChart, selectFilter, selectLoading } from './store/slice'
import { getInvestmentStructure, getVSICName } from './store/thunk'

const InvestmentStructure = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const VSIC = useSelector(selectFilter)
  const timeFrequency = useSelector(selectTimeFrequency)

  const [listCheckbox, setListCheckbox] = useState([
    'fdiTypeId_15',
    'fdiTypeId_17',
    'fdiTypeId_19',
    'fdiTypeId_14',
    'fdiTypeId_20',
  ])

  const dataChartFormat = convertData(dataChart, timeFrequency)

  useEffect(() => {
    dispatch(getVSICName())
  }, [locale])

  useEffect(() => {
    if (VSIC?.vsicId) {
      dispatch(
        getInvestmentStructure({
          VSICId: VSIC?.vsicId,
          TimeFrequency: timeFrequency,
        }),
      )
    }
  }, [locale, VSIC?.vsicId, timeFrequency])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterVSIC />
            {size.height && (
              <div
                style={{ height: height - size.height }}
                id="FDIStructureByIndustryId"
              >
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChart.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div className="position-relative">
                              {size1.height && (
                                <ChartComponent
                                  data={dataChartFormat}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'XAxis'}
                                  displayData={listCheckbox}
                                  name1={'fdiTypeId_15'}
                                  name2={'fdiTypeId_17'}
                                  name3={'fdiTypeId_19'}
                                  name4={'fdiTypeId_20'}
                                  lineKey={'fdiTypeId_14'}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={[
                                  {
                                    text: 'economy.fdi.fdiByIndustry.FDI_NEW_REGISTERED_CAPITAL',
                                    dataKey: 'fdiTypeId_15',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#185999',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByIndustry.FDI_REGISTERED_ADDITIONAL_CAPITAL',
                                    dataKey: 'fdiTypeId_17',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#fff6df',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByIndustry.FDI_VALUE_REGISTERED_FOR_SHARE_PURCHASE',
                                    dataKey: 'fdiTypeId_19',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#fe7e6d',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByIndustry.FDI_NO_OF_NEW_PROJECTS',
                                    dataKey: 'fdiTypeId_14',
                                    type: TYPE.CIRCLE_MARKER_LINE,
                                    before: {
                                      bgColor: '#f7b80c',
                                    },
                                    after: {
                                      bgColor: '#f7b80c',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByIndustry.FDI_NEW_REGISTERED_AND_ADDITIONAL_CAPITAL',
                                    dataKey: 'fdiTypeId_20',
                                    type: TYPE.DASHED_LINE,
                                    dashedLine: {
                                      bgColor: '#838383',
                                    },
                                  },
                                ]}
                                listCheckbox={listCheckbox}
                                setListCheckbox={setListCheckbox}
                                numItemPerRow={3}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default InvestmentStructure
