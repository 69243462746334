import InfoValuationProxy from '../../../proxies/bond/valuationProxy/infoValuationProxy'
import { ServiceBase } from '../../ServiceBase'

export class InfoValuation extends ServiceBase {
  getPriceBond(params) {
    return this.getData(() => InfoValuationProxy.getPrice(params))
  }
  getAccruedInterestSv(params) {
    return this.getData(() =>
      InfoValuationProxy.getAccruedInterestProxy(params, true),
    )
  }
  exportFileApi(params) {
    return this.getFileDownload(() =>
      InfoValuationProxy.exportFileProxy(params),
    )
  }
}

export default new InfoValuation()
