import { EMPTY_VALUE } from '../../../constants/Common'
import { getThreeColorByValue } from '../../../utils/Color'
import {
  formatVal,
  valDivMillion,
  valDivThousand,
  valToPercent,
} from '../../../utils/Value'
import {
  keyLastestSector,
  keySchemaLastestSector,
  keySchemaTableSectorInDepth,
  keyTableSectorInDepth,
} from '../constant'
import TdColor from './TdColor'
import TdPercentChange from './TdPercentChange'
import TdSector from './TdSector'
import ThSector from './ThSector'

export const formatTableSchema = ({
  timeRange,
  level,
  maxValueChange,
  maxVolumnChange,
  rowsCollapse,
  levelCollapse,
  onRowCollapse,
  setLevel,
}) =>
  Object.keys(keyTableSectorInDepth).map((key, index) => {
    const title = `market.sectorInDepth.${
      key +
      ([
        keySchemaTableSectorInDepth.CHANGE,
        keySchemaTableSectorInDepth.CHANGE_VALUE,
        keySchemaTableSectorInDepth.CHANGE_VOLUME,
        keySchemaTableSectorInDepth.AVG_TRADING_VALUE,
        keySchemaTableSectorInDepth.AVG_TRADING_VOLUME,
      ].includes(key)
        ? timeRange
        : '')
    }`
    const colId = keyTableSectorInDepth[key]
    const result = {
      colId,
      title,
      disableSort: !index,
    }
    const isPercentChange = [
      keySchemaTableSectorInDepth.CHANGE_VALUE,
      keySchemaTableSectorInDepth.CHANGE_VOLUME,
    ].includes(key)

    return {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: isPercentChange ? 'center' : 'right',
        height: '40px',
      },
      tdStyle: {
        textAlign:
          key !== keySchemaTableSectorInDepth.DISPLAY_NAME ? 'right' : 'left',
      },
      render:
        key === keySchemaTableSectorInDepth.DISPLAY_NAME
          ? (value, colId) => (
              <TdSector
                value={value}
                colId={colId}
                onRowCollapse={onRowCollapse}
                rowsCollapse={rowsCollapse}
                levelCollapse={levelCollapse}
                level={level}
              />
            )
          : key === keySchemaTableSectorInDepth.CHANGE
          ? (value) => {
              const valueFormat = valToPercent(value)
              const isEmpty = valueFormat === EMPTY_VALUE
              return (
                <>
                  <div
                    style={{
                      color: !isEmpty
                        ? getThreeColorByValue(Number(value))
                        : undefined,
                    }}
                    className="h-100 d-flex ali-center justify-content-end"
                  >
                    {valueFormat > 0 && '+'}
                    {valueFormat}
                  </div>
                </>
              )
            }
          : isPercentChange
          ? (value, colId) => {
              return (
                <TdPercentChange
                  value={value}
                  colId={colId}
                  maxValue={
                    key === keySchemaTableSectorInDepth.CHANGE_VALUE
                      ? maxValueChange
                      : maxVolumnChange
                  }
                />
              )
            }
          : [
              keySchemaTableSectorInDepth.VALUE,
              keySchemaTableSectorInDepth.VOLUME,
            ].includes(key)
          ? (value) => {
              const valueFormat = formatVal(valToPercent(value))
              return (
                <>
                  <span>
                    {valueFormat !== EMPTY_VALUE
                      ? `${valueFormat}%`
                      : EMPTY_VALUE}
                  </span>
                </>
              )
            }
          : [
              keySchemaTableSectorInDepth.TRADING_VALUE,
              keySchemaTableSectorInDepth.AVG_TRADING_VALUE,
            ].includes(key)
          ? (value) => formatVal(valDivMillion(value))
          : [
              keySchemaTableSectorInDepth.TRADING_VOLUME,
              keySchemaTableSectorInDepth.AVG_TRADING_VOLUME,
            ].includes(key)
          ? (value) => formatVal(valDivThousand(value))
          : undefined,
      renderTh:
        key === keySchemaTableSectorInDepth.DISPLAY_NAME
          ? () => <ThSector level={level} setLevel={setLevel} />
          : undefined,
    }
  })

export const formatTableSchema1D = ({
  tableRef,
  level,
  levelCollapse,
  rowsCollapse,
  setLevel,
  onRowCollapse,
  selectSectorInDepthCell,
}) =>
  Object.keys(keyLastestSector).map((key, index) => {
    const title = `market.sectorInDepth.${key}`

    const colId = keyLastestSector[key]
    const result = {
      colId,
      title,
      disableSort: !index,
    }

    return {
      ...result,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
        height: '40px',
      },
      tdStyle: {
        textAlign:
          key !== keySchemaLastestSector.DISPLAY_NAME ? 'right' : 'left',
        width: key === keySchemaLastestSector.DISPLAY_NAME ? '50px' : undefined,
      },
      canCustomTd: ![
        keySchemaLastestSector.ONE_DAY_VALUE,
        keySchemaLastestSector.ONE_DAY_VOLUMN,
        keySchemaLastestSector.DISPLAY_NAME,
      ].includes(key),
      render:
        key === keySchemaLastestSector.DISPLAY_NAME
          ? (value, colId) => (
              <TdSector
                value={value}
                colId={colId}
                onRowCollapse={onRowCollapse}
                rowsCollapse={rowsCollapse}
                levelCollapse={levelCollapse}
                level={level}
              />
            )
          : [
              keySchemaLastestSector.LAST,
              keySchemaLastestSector.ONE_DAY_CHANGE,
            ].includes(key)
          ? (value, colId, { style: cellStyle, className }) => (
              <>
                {
                  <TdColor
                    value={formatVal(value)}
                    colId={colId}
                    keyColor={keyLastestSector.ONE_DAY_CHANGE}
                    selectCell={selectSectorInDepthCell}
                    isLastPrice={key === keySchemaLastestSector.LAST}
                    tableRef={tableRef}
                    style={cellStyle}
                    className={className}
                  />
                }
              </>
            )
          : key === keySchemaLastestSector.ONE_DAY_PERCENT_CHANGE
          ? (value, colId, { style: cellStyle, className }) => (
              <>
                {
                  <TdColor
                    value={valToPercent(value)}
                    colId={colId}
                    keyColor={keyLastestSector.ONE_DAY_CHANGE}
                    selectCell={selectSectorInDepthCell}
                    tableRef={tableRef}
                    style={cellStyle}
                    className={className}
                  />
                }
              </>
            )
          : [
              keySchemaLastestSector.FOREIGN_VALUE,
              keySchemaLastestSector.FOREIGN_VOLUMN,
            ].includes(key)
          ? (value, colId, { style: cellStyle, className }) => {
              const isVolumn = key === keySchemaLastestSector.FOREIGN_VOLUMN
              return (
                <>
                  {
                    <TdColor
                      value={
                        isVolumn
                          ? formatVal(valDivThousand(value))
                          : formatVal(valDivMillion(value))
                      }
                      colId={colId}
                      keyColor={
                        key === keySchemaLastestSector.FOREIGN_VOLUMN
                          ? keyLastestSector.FOREIGN_VOLUMN
                          : keyLastestSector.FOREIGN_VALUE
                      }
                      selectCell={selectSectorInDepthCell}
                      tableRef={tableRef}
                      style={cellStyle}
                      className={className}
                      isTwoColor={true}
                    />
                  }
                </>
              )
            }
          : (value) => {
              const isVolumn = [
                keySchemaLastestSector.ONE_DAY_VOLUMN,
                keySchemaLastestSector.FOREIGN_VOLUMN,
              ].includes(key)
              return (
                <>
                  {isVolumn
                    ? formatVal(valDivThousand(value))
                    : formatVal(valDivMillion(value))}
                </>
              )
            },
      renderTh:
        key === keySchemaLastestSector.DISPLAY_NAME
          ? () => (
              <ThSector level={level} setLevel={setLevel} tableRef={tableRef} />
            )
          : undefined,
    }
  })
