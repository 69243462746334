import { colorArray } from './constants'

export const formatChartData = (data, dataKey, dataName) => {
  const sortedData = [...data].sort((a, b) => b[dataKey] - a[dataKey])
  const otherRatio = sortedData.filter((i) => !i.organizationId === true)
  const sortedDataNew = sortedData.filter((i) => i.organizationId)
  const result = sortedDataNew.concat(otherRatio)
  return result.map((item, index) => ({
    text: item[dataName],
    value: item[dataKey],
    color: item.organizationId
      ? colorArray[index]
      : colorArray[colorArray.length - 1],
  }))
}
