import { createSlice } from '@reduxjs/toolkit'
import { COM_GROUP } from '../../../../../constants/Group'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getFinancialRatioChartData,
  getPeersComparisonChartData,
} from './thunk'

export const slice = createSlice({
  name: 'corporate/financialData/financialRatio/chart',
  initialState: {
    comGroup: COM_GROUP.BANK,
    growthTimelineChartData: [],
    peerComparisonChartData: [],
    growthTimelineChartLoading: false,
    peerComparisonChartLoading: false,
  },
  reducers: {
    changeComGroup: (state, action) => {
      state.comGroup = action.payload
    },
    resetChartData: (state) => {
      state.growthTimelineChartData = []
      state.peerComparisonChartData = []
    },
  },
  extraReducers: (builder) => {
    // getFinancialRatioChartData
    builder.addCase(getFinancialRatioChartData.pending, (state) => {
      state.growthTimelineChartLoading = true
    })
    builder.addCase(getFinancialRatioChartData.fulfilled, (state, action) => {
      state.growthTimelineChartLoading = false
      state.growthTimelineChartData = action.payload || []
    })
    builder.addCase(getFinancialRatioChartData.rejected, (state, action) => {
      state.growthTimelineChartLoading = action.payload.loading
    })
    // getPeersComparisonChartData
    builder.addCase(getPeersComparisonChartData.pending, (state) => {
      state.peerComparisonChartLoading = true
    })
    builder.addCase(getPeersComparisonChartData.fulfilled, (state, action) => {
      state.peerComparisonChartLoading = false
      state.peerComparisonChartData = action.payload || []
    })
    builder.addCase(getPeersComparisonChartData.rejected, (state, action) => {
      state.peerComparisonChartLoading = action.payload.loading
    })
  },
})

export const selectGrowthTimelineChartLoading = (state) =>
  state[slice.name].growthTimelineChartLoading
export const selectGrowthTimelineChartData = (state) =>
  state[slice.name].growthTimelineChartData
export const selectPeerComparisonChartLoading = (state) =>
  state[slice.name].peerComparisonChartLoading
export const selectPeerComparisonChartData = (state) =>
  state[slice.name].peerComparisonChartData
export const { changeComGroup, resetChartData } = slice.actions

register(slice.name, slice.reducer)
