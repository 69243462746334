import { I18n } from 'react-redux-i18n'
import { getTextHeight, getTextWidth } from '../../../../../common/chart/helper'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'

const ReferenceYear = ({ ctx, value, isLabelBelow }) => {
  const calcFontSize = getFontSize(10)

  const valueFormatted = `${formatVal(value, 1)} ${I18n.t(
    'bond.corporateBond.averageDurationBySector.YEAR',
  )}`

  const labelWidth = getTextWidth(valueFormatted, calcFontSize, 600)
  const labelHeight = getTextHeight(valueFormatted, calcFontSize, 600)

  const label = isLabelBelow ? 20 : -8

  return (
    <>
      {value && (
        <g>
          <foreignObject
            x={ctx.viewBox.x + ctx.viewBox.width - labelWidth + 12}
            y={ctx.viewBox.y - labelHeight + label}
            width={labelWidth + 14}
            height={labelHeight + 4}
            color="#FFFFF"
          >
            {valueFormatted}
          </foreignObject>
        </g>
      )}
    </>
  )
}

export default ReferenceYear
