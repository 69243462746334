import Company from './company'
import CorporateNews from './corporateNews/CorporateNews'
import styles from './index.module.css'
import MarketNews from './marketNews/MarketNews'

const CompanyNews = () => {
  return (
    <div className={styles.container}>
      <Company />
      <MarketNews />
      <CorporateNews />
    </div>
  )
}

export default CompanyNews
