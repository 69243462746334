export const SEARCH_ITEM_TYPE = {
  ALL: 'All',
  INDICES: 'Index',
  SECTOR: 'Sector',
  TICKER: 'Stock',
}

export const COLOR_LINE_KEYS = ['#007cff', '#f7b80c', '#f78595']

export const TITLE_LINE_KEYS = [
  'market.coveredWarrant.breakEvenPoint.CW_BREAK_EVEN_POINT',
  'market.coveredWarrant.breakEvenPoint.UNDERLYING_ASSET_PRICE',
  'market.coveredWarrant.breakEvenPoint.EXERCISE_PRICE',
]
