import { useSelector } from 'react-redux'
import { ExportImportTable } from '.'
import { Panel } from '../../../../common/panel'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { TYPE_IMPORT_EXPORT } from '../../exportImportByCommodities/constants'
import { CATEGORY_FILTER, LIMIT_FIRST_FETCH } from './constants'
import {
  selectCurrentDataCategory,
  selectImportExportDataType,
  selectLimitQuarter,
  selectOrigParentCategory,
  selectRowsCollapse,
  selectTypeData,
  selectTypeFilter,
  selectUnCollapseIds,
} from './store/slice'
import {
  getExportDownloadByLocation,
  getExportTradeAgreementsDownloadByLocation,
  getImportDownloadByLocation,
  getImportTradeAgreementsDownloadByLocation,
} from './store/thunk'

export const PanelExportImportTable = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const currentCategoryFilter = useSelector(selectCurrentDataCategory)
  const importExportDataType = useSelector(selectImportExportDataType)
  const currentTypeFilter = useSelector(selectTypeFilter)
  const unCollapseIds = useSelector(selectUnCollapseIds)
  const rowsCollapse = useSelector(selectRowsCollapse)
  const currentTypeData = useSelector(selectTypeData)
  const listParentCategory = useSelector(selectOrigParentCategory)
  const limitQuarter = useSelector(selectLimitQuarter)

  const isImportData = importExportDataType === TYPE_IMPORT_EXPORT.IMPORT
  const isByLocation = currentCategoryFilter === CATEGORY_FILTER[0].value

  const getItemById = (id) => {
    return listParentCategory?.find((item) => item.id === id)
  }
  const unCollapseAreaIds = unCollapseIds?.map(
    (item) => getItemById(item)?.parentId,
  )

  const currentDetailIds = unCollapseIds
    ?.filter((item) => !rowsCollapse.includes(item))
    .map((item) => getItemById(item)?.locationId)

  const params = {
    DataType: currentTypeFilter,
    limit: limitQuarter + LIMIT_FIRST_FETCH,
    LocationIds: currentDetailIds.toString(),
    valueGrowthType: currentTypeData,
    AreaIds: unCollapseAreaIds.toString(),
  }
  const downloadCsv = () => {
    if (isImportData && isByLocation) {
      return getImportDownloadByLocation(params)
    } else if (isImportData && !isByLocation) {
      return getImportTradeAgreementsDownloadByLocation(params)
    } else if (!isImportData && isByLocation) {
      return getExportDownloadByLocation(params)
    } else {
      return getExportTradeAgreementsDownloadByLocation(params)
    }
  }

  const title = isImportData
    ? 'economy.importExport.importExport.exportImportByLocation.IMPORT_TURNOVER_TABLE'
    : 'economy.importExport.importExport.exportImportByLocation.EXPORT_TURNOVER_TABLE'

  return (
    <Panel
      title={title}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv
      downloadCsv={() => downloadCsv()}
    >
      <ExportImportTable
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}
