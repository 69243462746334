export const FONT_SIZE_CONFIG = {
  Small: {
    key: 'Small',
    value: 1,
    ratio: 1,
  },
  Medium: {
    key: 'Medium',
    value: 2,
    ratio: 15 / 12,
  },
  Large: {
    key: 'Large',
    value: 3,
    ratio: 16 / 12,
  },
}

export const DEFAULT_FONT_SIZE = 12
export const DEFAULT_LINE_HEIGHT_P = 16

export const COMPONENT = {
  MIN_SIZE: {
    WIDTH: 350,
    HEIGHT: 250,
  },
  MARGIN: 2,
  MARGIN_TOP: 5,
}

export const COMPONENT_FILTER = {
  MIN_SIZE: {
    WIDTH: 150,
    HEIGHT: 346,
  },
  MAX_SIZE: {
    WIDTH: 400,
  },
  MARGIN: 2,
  WIDTH_IF_HIDE: 28,
}
