import { createSlice, current } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { EXPORT_TYPE, GROUP_BY, VIEW_BY } from '../../constant'
import { getDataGroupByCompanyOrIndicator, getDataGroupByDate } from '../helps'
import { getCorporateResultThunk, getIndexSectorResultThunk } from './thunk'

const initialState = {
  isLoading: false,
  viewBy: VIEW_BY.CORPORATE,
  data: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  dataById: { [VIEW_BY.CORPORATE]: {}, [VIEW_BY.INDEX_AND_SECTOR]: {} },
  ids: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  columns: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  columnsById: { [VIEW_BY.CORPORATE]: {}, [VIEW_BY.INDEX_AND_SECTOR]: {} },
  columnIds: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  dataDisplay: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  cellInfos: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  tickersChecked: [],
  indicatorsChecked: [],
  tableHeaderAttr: [],
}

export const slice = createSlice({
  name: 'dataExplorer/tradingData/preview',
  initialState,
  reducers: {
    changeDataById: (state, action) => {
      const { schema, groupBy, exportType, viewBy } = action.payload
      let data = []

      if (exportType === EXPORT_TYPE.A_FILE) {
        switch (groupBy) {
          case GROUP_BY.DATE:
            data = getDataGroupByDate(
              current(state.data[viewBy]),
              [...state.cellInfos[viewBy], ...schema],
              viewBy,
            )
            break
          case GROUP_BY.COMPANY:
          case GROUP_BY.INDICATOR:
            data = getDataGroupByCompanyOrIndicator(
              current(state.data[viewBy]),
              [...state.cellInfos[viewBy], ...schema],
              viewBy,
            )
            break
          default:
            break
        }
      } else {
        data = getDataGroupByDate(
          current(state.data[viewBy]),
          [...state.cellInfos[viewBy], ...schema],
          viewBy,
        )
      }
      state.dataById[viewBy] = keyBy(data, 'index')
      state.ids[viewBy] = data.map((item) => item.index)
    },
    changeColumns: (state, action) => {
      state.columns[state.viewBy] = action.payload
    },
    changeColumnIds: (state, action) => {
      state.columnIds[state.viewBy] = action.payload
    },
    changeColumnsById: (state, action) => {
      state.columnsById[state.viewBy] = action.payload
    },
    changeDataDisplay(state, action) {
      state.dataDisplay[state.viewBy] = action.payload
    },
    changeTickersChecked: (state, action) => {
      state.tickersChecked = action.payload
    },
    changeIndicatorsChecked: (state, action) => {
      state.indicatorsChecked = action.payload
    },
    changeTableHeaderAttr: (state, action) => {
      state.tableHeaderAttr = action.payload
    },
    changeCellInfos: (state, action) => {
      state.cellInfos[state.viewBy] = action.payload
    },
    changeTableSettingsViewBy: (state, action) => {
      state.viewBy = action.payload
    },
    resetDefault: (state, action) => {
      state.data[action.payload] = []
      state.dataById[action.payload] = {}
      state.ids[action.payload] = []
      state.dataDisplay[action.payload] = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCorporateResultThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCorporateResultThunk.fulfilled, (state, action) => {
      state.data[VIEW_BY.CORPORATE] = action.payload
      state.isLoading = false
    })
    builder.addCase(getCorporateResultThunk.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getIndexSectorResultThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getIndexSectorResultThunk.fulfilled, (state, action) => {
      state.data[VIEW_BY.INDEX_AND_SECTOR] = action.payload
      state.isLoading = false
    })
    builder.addCase(getIndexSectorResultThunk.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading

export const selectIds = (viewBy) => (state) => state[slice.name].ids[viewBy]
export const selectValueById = (viewBy) => (id, attr) => (state) =>
  state[slice.name].dataById[viewBy][id]?.[attr]
export const selectFullValueById = (viewBy) => (state) =>
  state[slice.name].dataById[viewBy]
export const selectData = (viewBy) => (state) => state[slice.name].data[viewBy]
export const selectColumnId = (viewBy) => (index) => (state) =>
  state[slice.name].columnIds[viewBy][index]
export const selectFullColumnIds = (viewBy) => (state) =>
  state[slice.name].columnIds[viewBy]
export const selectColumnByIdCell = (viewBy) => (id) => (state) =>
  state[slice.name].columnsById[viewBy][id]
export const selectFullColumnsById = (viewBy) => (state) =>
  state[slice.name].columnsById[viewBy]
export const selectDataDisplay = (viewBy) => (index) => (state) =>
  state[slice.name].dataDisplay[viewBy][index]
export const selectFullDataDisplay = (viewBy) => (state) =>
  state[slice.name].dataDisplay[viewBy]

export const selectCellInfos = (viewBy) => (state) =>
  state[slice.name].cellInfos[viewBy]
export const selectColumns = (viewBy) => (state) =>
  state[slice.name].columns[viewBy]
export const selectTickersChecked = (state) => state[slice.name].tickersChecked
export const selectIndicatorsChecked = (state) =>
  state[slice.name].indicatorsChecked
export const selectTableHeaderAttr = (state) =>
  state[slice.name].tableHeaderAttr

export const {
  changeDataById,
  changeColumns,
  changeColumnIds,
  changeColumnsById,
  changeDataDisplay,
  changeTickersChecked,
  changeIndicatorsChecked,
  changeTableHeaderAttr,
  changeCellInfos,
  changeTableSettingsViewBy,
  resetDefault,
} = slice.actions

register(slice.name, slice.reducer)
