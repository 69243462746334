import { TYPE } from '../../../common/chart/footer/Item'

export const listTab = [
  { title: '1D', value: '1D' },
  { title: '1W', value: '1W' },
  { title: '1M', value: '1M' },
  { title: '6M', value: '6M' },
  { title: '1Y', value: '1Y' },
]

export const pricePerformanceDateFilter = {
  ONE_DAY: '1D',
  ONE_WEEK: '1W',
  ONE_MONTH: '1M',
  SIX_MONTH: '6M',
  ONE_YEAR: '1Y',
}

export const listIconBarChart = [
  {
    text: 'market.equityTrading.deepTransaction.timeSale.BU_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#3da967',
    },
  },
  {
    text: 'market.equityTrading.deepTransaction.timeSale.SD_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#e0505b',
    },
  },
  {
    text: 'market.equityTrading.deepTransaction.timeSale.UNIDENTIFIED',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#32a6e8',
    },
  },
]
