import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import style from './index.module.css'
import { getDate, getLimitTime, handleDate } from './store/slice'

const SelectDate = () => {
  const dispatch = useDispatch()
  const [isOpenDate, setIsOpenDate] = useState(false)
  const currentDate = [new Date()]
  const currentYear = new Date().getFullYear()

  const timeZone = UseTimeZone()
  const date = useSelector(getDate)
  const limitTime = useSelector(getLimitTime)

  const onDateChange = (date) => {
    dispatch(handleDate(date))
  }

  const onDateFromClose = () => {
    setIsOpenDate(false)
  }

  const onClickDateFrom = () => {
    setIsOpenDate(true)
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE, undefined, timeZone))
  }

  const getInputStyle = () => {
    return {
      border: 0,
      backgroundColor: '#50545f',
      borderRadius: '4px',
      paddingLeft: '10px',
      height: '22px',
      width: '228px',
    }
  }

  return (
    <div className={style.selectDate}>
      <div className={`form-date-select`}>
        <Span className={style.yellowColor}>
          <Translate value="economy.monetary.monetary.DATE" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={date ? getDateWithTimeZone(date) : ''}
            minDate={getDateWithTimeZone(limitTime.minDate)}
            maxDate={getDateWithTimeZone(limitTime.maxDate)}
            handleChange={onDateChange}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendarPopper({
              startYear: limitTime.minYear || currentYear - 10,
              endYear: limitTime.maxYear || currentYear,
              zIndex: 11,
            })}
            isOpen={isOpenDate}
            onClose={onDateFromClose}
            customInputStyle={getInputStyle()}
            position={'top'}
            popperClassName={style.topPopper}
          />
          <Span className={`${style.iconDate}`} onClick={onClickDateFrom}>
            <i className="icon-date" style={{ fontSize: 12 }} />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default SelectDate
