import Title from '../../../common/topInfo/components/Title'
import style from './common.module.css'

const Header = ({ title }) => {
  return (
    <div className={style.header}>
      <Title title={title} />
    </div>
  )
}

export default Header
