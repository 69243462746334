import { I18n } from 'react-redux-i18n'
import { VALUE_TIME } from './constants'

export const renderTimeType = (timeType) => {
  switch (timeType) {
    case VALUE_TIME.MONTHLY:
      return I18n.t('bond.overview.issuers.MTD')
    case VALUE_TIME.QUARTERLY:
      return I18n.t('bond.overview.issuers.QTD')
    default:
      return I18n.t('bond.overview.issuers.YTD')
  }
}

export const renderTimeValueBond = (timeType) => {
  switch (timeType) {
    case VALUE_TIME.MONTHLY:
      return I18n.t('bond.overview.issuers.MOM')
    case VALUE_TIME.QUARTERLY:
      return I18n.t('bond.overview.issuers.QOQ')
    default:
      return I18n.t('bond.overview.issuers.YOY')
  }
}
