import { Fragment, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { VIEW_TYPE } from '../../../chart/constants'
import { getTimeFrequencyByIndicator } from '../../../common/helpers'
import { selectIndicatorsLatestPeriod } from '../../../store/slice'
import {
  equationIndicatorAliasAlphabet,
  equationIndicatorColWidth,
  indicatorDefaultData,
  indicatorKeyByTimeFrequency,
} from '../constants'
import { getIndicatorAlias, getIndicatorAliasUsed } from '../helpers'
import {
  changeEquationIndicators,
  removeEquationIndicator,
  selectEquationIndicators,
} from '../store/slice'
import style from '../style.module.css'
import { IndicatorSelectItem } from './IndicatorSelectItem'
import { IndicatorSelectTitle } from './IndicatorSelectTitle'
import { IndicatorSelectUpdateData } from './IndicatorSelectUpdateData'

const IndicatorSelect = ({
  viewType,
  isEquationTypeCount,
  indicatorsLatestPeriod,
}) => {
  const dispatch = useDispatch()
  const titleRef = useRef()

  const equationIndicators = useSelector(selectEquationIndicators)
  const indicatorLatestPeriod = useSelector(selectIndicatorsLatestPeriod)

  const [titleHeight, setTitleHeight] = useState(0)

  const getIndicatorNameColWidth = () => {
    let totalOtherWidth =
      equationIndicatorColWidth.alias + equationIndicatorColWidth.iconDelete
    if (isEquationTypeCount) {
      totalOtherWidth +=
        equationIndicatorColWidth.valueFrom +
        equationIndicatorColWidth.divider +
        equationIndicatorColWidth.valueTo +
        equationIndicatorColWidth.unit
    }

    if (viewType === VIEW_TYPE.BY_SECURITIES) {
      totalOtherWidth += equationIndicatorColWidth.timeGroup
    }

    return `calc(100% - ${totalOtherWidth}px)`
  }

  const handleChangeEquationIndicator = (data) => {
    dispatch(changeEquationIndicators(data))
  }

  const handleChangeIndicator = (val, dataKey, indicator, key) => {
    let formatValue = val

    if (['valueFrom', 'valueTo'].includes(key)) {
      if (indicator.unit === '%') {
        formatValue = Number(val) / 100
      } else {
        if (!val) {
          formatValue = null
        } else {
          formatValue = Number(val)
        }
      }
    }

    handleChangeEquationIndicator({
      id: indicator.indicatorTypeId,
      value: {
        ...indicator,
        data: {
          ...indicator.data,
          [dataKey]: {
            ...indicator.data[dataKey],
            [key]: formatValue,
          },
        },
      },
    })
  }

  const handleChangeIndicatorDate = (val, key, indicator, timeFrequency) => {
    handleChangeIndicator(
      val,
      key,
      indicator,
      indicatorKeyByTimeFrequency[timeFrequency.toLowerCase()],
    )
  }

  const handleDeleteIndicator = (id, dataKey) => {
    dispatch(removeEquationIndicator({ id, dataKey }))
  }

  const handleAddIndicatorData = (indicator) => {
    const newAlias = getIndicatorAlias(equationIndicators)

    if (newAlias !== EMPTY_VALUE) {
      const indicatorKey = `${
        indicator.indicatorId
      }_${getTimeFrequencyByIndicator(indicator)}`

      handleChangeEquationIndicator({
        id: indicator.indicatorTypeId,
        value: {
          ...indicator,
          data: {
            ...indicator.data,
            [Object.keys(indicator.data).length]: {
              ...indicatorDefaultData,
              ...(indicatorLatestPeriod[indicatorKey] || {}),
              alias: newAlias,
            },
          },
        },
      })
    }
  }

  useEffect(() => {
    let interval = null

    if (isEquationTypeCount) {
      interval = setInterval(() => {
        if (titleRef.current?.getBoundingClientRect().height) {
          setTitleHeight(titleRef.current?.getBoundingClientRect().height)
          clearInterval(interval)
        }
      }, 100)
    } else {
      setTitleHeight(0)
    }

    return () => clearInterval(interval)
  }, [isEquationTypeCount])

  useEffect(() => {
    const usedAlias = []
    Object.keys(equationIndicators).forEach((id) => {
      let isChange = false
      const data = equationIndicators[id].data
      const newData = Object.keys(data).reduce((obj, key) => {
        if (data[key].alias) {
          return { ...obj, [key]: data[key] }
        } else {
          isChange = true
          const remainingAlphabet = equationIndicatorAliasAlphabet
            .split('')
            .filter((val) => !usedAlias.includes(val))
          const alias = remainingAlphabet.length ? remainingAlphabet[0] : '--'
          usedAlias.push(alias)
          return { ...obj, [key]: { ...data[key], alias } }
        }
      }, {})

      if (isChange) {
        handleChangeEquationIndicator({
          id: id,
          value: {
            ...equationIndicators[id],
            data: newData,
          },
        })
      }
    })
  }, [])

  return (
    <>
      <div
        className={
          isEquationTypeCount ? style.listDataIndicatorCount : style.listData
        }
      >
        {isEquationTypeCount && (
          <div ref={titleRef}>
            <IndicatorSelectTitle
              viewType={viewType}
              colNameWidth={getIndicatorNameColWidth()}
            />
          </div>
        )}
        <div style={{ height: `calc(100% - ${titleHeight}px)` }}>
          <ScrollComponent bgColor="#D9D9D9">
            <div>
              {equationIndicators &&
                Object.values(equationIndicators).map(
                  (equationIndicator, index) => (
                    <Fragment key={`${equationIndicator.indicatorId}_${index}`}>
                      <IndicatorSelectItem
                        equationIndicator={equationIndicator}
                        viewType={viewType}
                        isEquationTypeCount={isEquationTypeCount}
                        indicatorNameColWidth={getIndicatorNameColWidth()}
                        indicatorsLatestPeriod={indicatorsLatestPeriod}
                        onChangeIndicator={handleChangeIndicator}
                        onChangeIndicatorDate={handleChangeIndicatorDate}
                        onDeleteIndicator={handleDeleteIndicator}
                        onAddIndicatorData={handleAddIndicatorData}
                      />
                      <IndicatorSelectUpdateData
                        equationIndicator={equationIndicator}
                        indicatorLatestPeriod={indicatorLatestPeriod}
                        onChangeEquationIndicator={
                          handleChangeEquationIndicator
                        }
                      />
                    </Fragment>
                  ),
                )}
            </div>
          </ScrollComponent>
        </div>
      </div>
      <div style={{ color: '#4e4e4e' }} className="d-flex align-center mt-6">
        <Translate value="financialChart.SELECTED" />
        <span className="ml-5">
          {(equationIndicators &&
            getIndicatorAliasUsed(equationIndicators).length) ||
            0}
        </span>
      </div>
    </>
  )
}

export default IndicatorSelect
