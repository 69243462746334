import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import useGetExchangeV2 from '../../../common/masterData/useGetExchangeV2'
import useGetICB from '../../../common/masterData/useGetICB'
import useGetStock from '../../../common/masterData/useGetStock'
import { LIST_TYPE, SEC_GROUP_INDICATOR } from '../../common/constants'
import {
  MAP_NAME_BY_TIME_FREQUENCY,
  TIME_FREQUENCY,
} from '../../popup/constants'
import {
  selectIndicatorByTypeSecurities,
  selectIndicators,
} from '../../store/slice'

export const useGetNameSecuritiesIndicator = (
  itemType,
  securitiesId,
  indicatorId = undefined,
  timeFrequency = TIME_FREQUENCY.DAILY,
) => {
  const { stocks } = useGetStock()
  const { exchanges } = useGetExchangeV2(true, true)
  const { ICBs } = useGetICB(true, true)
  const indicators = useSelector(selectIndicators)
  const indicatorName = indicators.find((i) => i.id === +indicatorId)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )

  const getSecuritiesName = useCallback(
    (itemType, securitiesId) => {
      switch (itemType) {
        case LIST_TYPE.COMPANIES: {
          const company = stocks.find((i) => i.organizationId === +securitiesId)
          return (
            company?.ticker ||
            company?.organizationShortName ||
            company?.taxCode
          )
        }
        case LIST_TYPE.SECTOR:
          const icb = ICBs.find((i) => i.icbId === +securitiesId)
          return icb?.icbName + ' L' + icb?.icbLevel
        case LIST_TYPE.INDICES:
          return exchanges.find((i) => i.groupId === +securitiesId)?.groupName
        default:
          return ''
      }
    },
    [stocks, ICBs, exchanges],
  )

  const getIndicator = useCallback(
    (indicatorId) => {
      return indicators.find((i) => i.id === +indicatorId)
    },
    [indicators],
  )

  const getIndicatorNameTimeFrequency = useCallback(
    (indicatorId, timeFrequency, indicatorType) => {
      const indicator = indicators.find((i) => i.id === +indicatorId)
      if (indicator) {
        const indicatorName =
          indicatorType === LIST_TYPE.ECONOMY
            ? indicator.secName
            : indicator.name
        return indicatorName + ' ' + MAP_NAME_BY_TIME_FREQUENCY[timeFrequency]
      }
    },
    [indicators],
  )

  const getNameSecuritiesIndicator = useCallback(
    (
      keyConvertName = '',
      listLegendCustom = {},
      isHideSecurityName = false,
    ) => {
      if (listLegendCustom[keyConvertName]) {
        return listLegendCustom[keyConvertName]
      }

      const [itemTypeFunc] = keyConvertName.split('_')

      if (itemTypeFunc === LIST_TYPE.ECONOMY) {
        const keyConvertNameDetail = keyConvertName.split('_')
        const securitiesIdFunc = keyConvertNameDetail[1]
        const timeFrequencyFunc = keyConvertNameDetail[2]

        return `${
          listSecuritiesEconomy.find((i) => i.id === +securitiesIdFunc)?.secName
        } ${MAP_NAME_BY_TIME_FREQUENCY[timeFrequencyFunc]}`
      } else {
        const [
          itemTypeFunc,
          securitiesIdFunc,
          indicatorIdFunc,
          timeFrequencyFunc,
        ] = keyConvertName.split('_')
        let securitiesNameFunc = ''

        switch (itemTypeFunc) {
          case LIST_TYPE.COMPANIES:
            const company = stocks.find(
              (i) => i.organizationId === +securitiesIdFunc,
            )
            securitiesNameFunc =
              company?.ticker ||
              company?.organizationShortName ||
              company?.taxCode
            break
          case LIST_TYPE.SECTOR:
            const icb = ICBs.find((i) => i.icbId === +securitiesIdFunc)
            securitiesNameFunc = icb?.icbName + ' L' + icb?.icbLevel
            break
          case LIST_TYPE.INDICES:
            securitiesNameFunc = exchanges.find(
              (i) => i.groupId === +securitiesIdFunc,
            )?.groupName
            break
          default:
            break
        }

        return isHideSecurityName
          ? [
              indicators.find((i) => i.id === +indicatorIdFunc)?.name,
              ' ',
              MAP_NAME_BY_TIME_FREQUENCY[timeFrequencyFunc],
            ].join('')
          : [
              securitiesNameFunc,
              ' - ',
              indicators.find((i) => i.id === +indicatorIdFunc)?.name,
              ' ',
              MAP_NAME_BY_TIME_FREQUENCY[timeFrequencyFunc],
            ].join('')
      }
    },
    [indicators, stocks, exchanges, ICBs],
  )

  const securitiesName = getSecuritiesName(itemType, securitiesId)
  const nameSecuritiesIndicator = [
    securitiesName,
    ' - ',
    indicatorName?.name,
    ' ',
    MAP_NAME_BY_TIME_FREQUENCY[timeFrequency],
  ].join('')

  return {
    securitiesName,
    indicatorName,
    nameSecuritiesIndicator,
    getIndicator,
    getIndicatorNameTimeFrequency,
    getSecuritiesName,
    getNameSecuritiesIndicator,
  }
}
