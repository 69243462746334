import { I18n } from 'react-redux-i18n'
import { Bar, Scatter } from 'recharts'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import CustomScatterLine from '../../../../../common/chart/scatterLine'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'

const SETTINGS = {
  yTickNum: 7,
  xTickNum: 11,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  barKey,
  barKeyMax,
  barKeyMin,
  scatterDiamondKey,
  scatterLineKey,
  keyXAxis,
  indicator,
}) => {
  const renderTooltip = (tooltipData) => {
    const contentTooltip = [
      {
        label: I18n.t(`sector.sectorStatistics.overview.MAX_${indicator}`),
        value: tooltipData[barKey][1],
      },
      {
        label: I18n.t(`sector.sectorStatistics.overview.MIN_${indicator}`),
        value: tooltipData[barKey][0],
      },
      {
        label: I18n.t(`sector.sectorStatistics.overview.AVERAGE_${indicator}`),
        value: tooltipData[scatterLineKey],
      },
      {
        label: I18n.t(`sector.sectorStatistics.overview.CURRENT_${indicator}`),
        value: tooltipData[scatterDiamondKey],
      },
    ]
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{tooltipData.icbName}</Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>
                {formatVal(el.value)}
              </Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <div className="position-relative" style={{ height: height || 0 }}>
      <ChartContainer
        data={data}
        width={width || 0}
        height={height || 0}
        keyXAxis={keyXAxis}
        yTickNum={SETTINGS.yTickNum}
        margin={{ top: 20, left: 25, right: 25, bottom: 10 }}
        isUseXAxisDiv
        showAllTick
        yAxis={[
          {
            id: 'left',
            keys: [barKeyMin, barKeyMax, scatterDiamondKey, scatterLineKey],
            orientation: 'left',
            isBarChart: true,
            tickNum: SETTINGS.yTickNum,
          },
        ]}
        renderCustomTooltip={(data) => {
          return renderTooltip(data)
        }}
      >
        <Bar
          isAnimationActive={false}
          yAxisId="left"
          dataKey={barKey}
          barSize={getColumnSizeInBarChart(width, data.length, 25)}
          fill="#facc5c"
        />
        <Scatter
          yAxisId="left"
          dataKey={scatterDiamondKey}
          fill="#228a9f"
          isAnimationActive={false}
          shape={<ScatterDiamondShape dataKey={scatterDiamondKey} />}
        />
        <Scatter
          yAxisId="left"
          dataKey={scatterLineKey}
          fill="#dc6f00"
          isAnimationActive={false}
          shape={
            <CustomScatterLine
              widthBar={getColumnSizeInBarChart(width, data.length, 33)}
            />
          }
        />
      </ChartContainer>
    </div>
  )
}

export default ChartComponent
