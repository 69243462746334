import CompanyBondProxy from '../../../../proxies/bond/issuers/CompanyBondProxy'
import { ServiceBase } from '../../../ServiceBase'

export class CompanyBondService extends ServiceBase {
  getBondIssueInformation(params) {
    return this.getData(() => CompanyBondProxy.getBondIssueInformation(params))
  }
  getRelativeToEquity(params) {
    return this.getData(() => CompanyBondProxy.getRelativeToEquity(params))
  }
  getRemainingMaturities(params) {
    return this.getData(() => CompanyBondProxy.getRemainingMaturities(params))
  }
  getCouponInterestRate(params) {
    return this.getData(() => CompanyBondProxy.getCouponInterestRate(params))
  }
  getInterestAndPrincipalPaid(params) {
    return this.getData(() =>
      CompanyBondProxy.getInterestAndPrincipalPaid(params),
    )
  }
  downloadBondIssueInformation(params) {
    return this.getFileDownload(() =>
      CompanyBondProxy.downloadBondIssueInformation(params),
    )
  }
}

export default new CompanyBondService()
