import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { BANK_TAB } from './constant'
import DepositChangeInMarketShare from './deposit/changeInMarketShare'
import style from './index.module.css'
import LoanChangeInMarketShare from './loan/changeInMarketShare'
import { selectBankTab } from './store/slice'

const CHART_DOM_ID = 'BankPanelThree'

const PanelThree = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)
  const bankTab = useSelector(selectBankTab)

  const nameScreen = UseI18n(
    'constants.route.corporate.businessModel.penetrationAnalysis.LABEL',
  )
  const chartName = UseI18n(
    `corporate.businessModel.penetrationAnalysis.${
      bankTab === BANK_TAB.LOAN ? 'LOAN_CHART_CHANGE' : 'DEPOSIT_CHART_CHANGE'
    }`,
  )
  const tickerName = basicInfo.ticker

  return (
    <Panel
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowClass={style.windowClass}
      headerClass={style.panelHeader}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CHART_DOM_ID,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <div className="h-100" id={CHART_DOM_ID}>
        {bankTab === BANK_TAB.LOAN ? (
          <LoanChangeInMarketShare width={width} height={height} />
        ) : (
          <DepositChangeInMarketShare width={width} height={height} />
        )}
      </div>
    </Panel>
  )
}

export default PanelThree
