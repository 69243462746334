import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valDivMillion } from '../../../../../utils/Value'
import {
  getAccMonthAndYearKey,
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../../../common/helper'
import { TIME_TYPE } from '../../exportImportTable/constants'
import {
  getExportContributeByLocation,
  getImportContributeByLocation,
  getMaxDateDetail,
} from './thunk'

const slice = createSlice({
  name: 'economy/importExport/importExportByLocation/getImportContributeByLocation',
  initialState: {
    isLoading: true,
    origData: [],
    data: [],
    ids: [],
    activeCategory: null,
    activeId: null,
    currentMonth: null,
    currentYear: null,
    isAcc: false,
    currentActiveName: null,
    locale: null,
    typeStatistics: TIME_TYPE.MONTHLY,
    maxDate: null,
  },
  reducers: {
    changeActiveCategoryStructureChart: (state, action) => {
      state.activeCategory = action.payload
    },
    changeActiveIdStructureChart: (state, action) => {
      state.activeId = action.payload
    },
    changeCurrentMonthStructureChart: (state, action) => {
      state.currentMonth = action.payload
    },
    changeCurrentYearStructureChart: (state, action) => {
      state.currentYear = action.payload
    },
    changeIsAccStructureChart: (state, action) => {
      state.isAcc = action.payload
    },
    changeLocaleStructureChart: (state, action) => {
      state.locale = action.payload
    },
    changeTypeStatisticsStructureChart: (state, action) => {
      state.typeStatistics = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportContributeByLocation.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getImportContributeByLocation.fulfilled,
      (state, action) => {
        state.isLoading = false

        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: `${item.vnTypeId}`,
            name: item.vnTypeName,
            month: item.realMonth,
            year: item.realYear,
            value: valDivMillion(item.value),
          })) || []

        state.origData = payloadData
        state.data = convertData(
          payloadData,
          state.typeStatistics,
          state.locale,
          state.maxDate,
        ).reverse()
        state.currentActiveName = payloadData[0]?.locationName
        state.ids = [...new Set(payloadData.map((item) => item.id))]
      },
    )
    builder.addCase(getImportContributeByLocation.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getExportContributeByLocation.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(
      getExportContributeByLocation.fulfilled,
      (state, action) => {
        state.isLoading = false

        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: `${item.vnTypeId}`,
            name: item.vnTypeName,
            month: item.realMonth,
            year: item.realYear,
            value: item.value / 1000000,
          })) || []

        state.origData = payloadData
        state.data = convertData(
          payloadData,
          state.typeStatistics,
          state.locale,
          state.maxDate,
        ).reverse()
        state.currentActiveName = payloadData.length
          ? payloadData[0]?.locationName
          : ''
        state.ids = [...new Set(payloadData.map((item) => item.id))]
      },
    )
    builder.addCase(getExportContributeByLocation.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })

    builder.addCase(getMaxDateDetail.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxDateDetail.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxDateDetail.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectIds = (state) => state[slice.name].ids
export const selectActiveCategory = (state) => state[slice.name].activeCategory
export const selectCurrentMonth = (state) => state[slice.name].currentMonth
export const selectCurrentYear = (state) => state[slice.name].currentYear
export const selectIsAcc = (state) => state[slice.name].isAcc
export const selectActiveId = (state) => state[slice.name].activeId
export const selectCurrentActiveName = (state) =>
  state[slice.name].currentActiveName
export const selectMaxDate = (state) => state[slice.name].maxDate

export const {
  changeActiveCategoryStructureChart,
  changeCurrentMonthStructureChart,
  changeCurrentYearStructureChart,
  changeIsAccStructureChart,
  changeLocaleStructureChart,
  changeActiveIdStructureChart,
  changeTypeStatisticsStructureChart,
} = slice.actions

register(slice.name, slice.reducer)

const convertData = (data, typeStatistics, locale, maxDate) => {
  //convert months and years to date keys
  const getDataKeyFromRange =
    typeStatistics === TIME_TYPE.MONTHLY
      ? getMonthAndYearInDataRange(data).map((item) =>
          getMonthAndYearKey(item.month, item.year),
        )
      : typeStatistics === TIME_TYPE.YEARLY
      ? getYearInDataRange(data).map((item) =>
          getYearKey(item.year, maxDate, locale),
        )
      : getMonthAndYearInDataRange(data).map((item) =>
          getAccMonthAndYearKey(item.month, item.year, locale),
        )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? ``,
        name: item.name ?? 0,
        key: item.key ?? '',
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: `${item.id}` ?? ``,
        name: item.name ?? 0,
        key: item.key ?? '',
      }))
  }

  const getDataValueFromRange =
    typeStatistics !== TIME_TYPE.YEARLY
      ? getMonthAndYearInDataRange(data).map((item) => [
          ...dataWithMonthAndYear(item.month, item.year),
        ])
      : getYearInDataRange(data).map((item) => [...dataWithYear(item.year)])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
