import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { positionDropdown } from '../../../../common/dropdown'
import { Filter } from '../../../../common/news/filter'
import {
  changeReportFilter,
  selectReportFilterData,
  selectReportSources,
  selectReportTypes,
} from '../../store/slice'
import { getReportTypesAndSources } from '../../store/thunk'

export const ReportFilter = () => {
  const dispatch = useDispatch()
  const sources = useSelector(selectReportSources)
  const types = useSelector(selectReportTypes)
  const filterData = useSelector(selectReportFilterData)
  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getReportTypesAndSources())
  }, [locale])

  const changeFilter = (data) => {
    dispatch(changeReportFilter(data))
  }

  return (
    <Filter
      categories={types.map((item) => ({
        newsCategoryId: item.reportTypeId,
        newsCategoryName: item.reportTypeName,
      }))}
      sources={sources.map((item) => ({
        newsSourceId: item.organizationId,
        newsSourceName: item.reportSourceName,
      }))}
      sourceValue={filterData.source}
      categoryValue={filterData.category}
      filterStartDate={filterData.startDate}
      filterEndDate={filterData.endDate}
      changeFilter={changeFilter}
      time={filterData.time}
      position={positionDropdown.BOTTOM_RIGHT_LEFT}
    />
  )
}
