import { isEmpty } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import InputFormatCurrency from '../../../common/input/InputFormatCurrencyCopy'
import PopupAlert, { POPUP_ICON_STATUS } from '../../../common/popup/PopupAlert'
import PopupEvent from '../../../common/popup/PopupEvent'
import SelectCustom from '../../../common/selectCustom'
import DispatchActionTab, {
  VALUE_ADD_MORE,
} from '../../../common/tabs/DispatchActionTabAddMore'
import TextEllipsis from '../../../common/textEllipsis'
import { TooltipInfo } from '../../../common/tooltipInfo'
import { invertKeyBy, keyBy } from '../../../utils/Common'
import {
  compareTwoDateTime,
  getDateWithTimezone,
  getISOStartOrEndOfDay,
} from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import { valueTab as valueTabType } from '../infoSec/constants'
import {
  selecBondIds,
  selecBondInfo,
  selectActiveTab as selectActiveTabType,
  selectCouponInput,
  selectCreateBondId,
} from '../infoSec/store/slice'
import { getBondCashFlowScenario } from '../infoSec/store/thunk'
import InputDate from './InputDate'
import InputExDate from './InputExDate'
import {
  tabInterrestOption,
  typePopup,
  valueCheckBox,
  valueTab,
} from './constants'
import { areArraysEqual } from './helper'
import style from './index.module.css'
import {
  addMoreTab,
  deleteCashFlowClient,
  openPopup,
  selectActiveTab,
  selectDataTab,
  selectInfoSameIndustry,
  selectListTab,
  selectPopup,
  selectStateSameIndustry,
  selectStatusAddTab,
  updateActiveTab,
  updateForm,
  updateStatusAddMore,
  updateTab,
} from './store/slice'
import {
  // addNewApi,
  checkNameApi,
  deleteCashFlowRowApi,
  getPriceYtmApi,
} from './store/thunk'

const minTradingVolume = 1
const maxTradingVolume = 1000000000
const RESPONSIVE_WIDTH = 1440

const filterArrayDate = (array = [], tradingDate, locale) => {
  if (isEmpty(array)) {
    return null
  }
  const currentDate = getISOStartOrEndOfDay(tradingDate, locale, true)
  const closestElement = array.reduce((prev, curr) => {
    const prevSettlementDate = new Date(
      getISOStartOrEndOfDay(prev.tradingDateId, locale, true),
    )
    const currSettlementDate = new Date(
      getISOStartOrEndOfDay(curr.tradingDateId, locale, true),
    )
    const prevDiff = Math.abs(prevSettlementDate - new Date(currentDate))

    const currDiff = Math.abs(currSettlementDate - new Date(currentDate))

    return prevDiff < currDiff ? prev : curr
  })
  return closestElement
}

const Filter = ({ size, width, height }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const form = useSelector(selectStateSameIndustry)
  const info = useSelector(selectInfoSameIndustry)
  const bondInfo = useSelector(selecBondInfo)
  const bondId = useSelector(selecBondIds)
  const dataTab = useSelector(selectDataTab)
  const tabActive = useSelector(selectActiveTab)
  const listTab = useSelector(selectListTab)
  const statusAddTab = useSelector(selectStatusAddTab)
  const couponInput = useSelector(selectCouponInput)
  const activeTab = useSelector(selectActiveTabType)
  const createBondId = useSelector(selectCreateBondId)
  const popup = useSelector(selectPopup)

  const listTabByValue = keyBy(listTab, 'value')

  // Use state
  const [popupDelTab, setPopupDelTab] = useState(false)
  const [popupItemDelTab, setItemPopupDelTab] = useState()

  // Get data
  const addCashValue = useMemo(() => {
    let value = 0
    if (!isEmpty(info)) {
      const item = info.find((e) => e.bondPaymentTypeId === 4)
      value = item?.parValue || 0
    }
    return value
  }, [info])

  const totalCashFlow = useMemo(() => {
    let value = 0
    const data = invertKeyBy(dataTab?.[tabActive]?.data || {})
    if (!isEmpty(data)) {
      const dataformat = data.filter(
        (e) => compareTwoDateTime(e.tradingDateId, form.tradingDate) === 1,
      )
      dataformat.forEach((e) => {
        value += parseInt(e?.estimatedValue)
      })
    }
    return value
  }, [tabActive, form.tradingDate, dataTab?.[tabActive]?.data])

  const checkDate = () => {
    const date1 = moment(form.paymentDate)
    const date2 = moment(bondInfo.maturityDateId)
    const monthsDiff = date2.diff(date1, 'months')
    const dayDiff = date2.diff(date1, 'days')

    let result
    if (dayDiff < 30) {
      result = `${dayDiff} ${I18n.t('common.calendar.DAY')}`
    } else if (dayDiff > 30 && monthsDiff < 36) {
      result = `${parseFloat(dayDiff / 30).toFixed(2)} ${I18n.t(
        'common.calendar.MONTH',
      )}`
    } else {
      result = `${parseFloat(monthsDiff / 12).toFixed(2)} ${I18n.t(
        'common.calendar.YEAR',
      )}`
    }
    return result
  }

  const getStyle = (customWidth = 0) => {
    const style = {
      fontSize: 12,
      fontWeight: 600,
      width: `calc(100% - ${customWidth}px)`,
    }
    return width > RESPONSIVE_WIDTH ? style : { ...style }
  }

  const getStyleContent = () => {
    const style = {
      fontSize: 12,
      fontWeight: 600,
    }
    return width > RESPONSIVE_WIDTH ? style : { ...style, maxWidth: '180px' }
  }

  // Actions
  const onChangeTradingDate = (value) => {
    const compareDate = compareTwoDateTime(form.paymentDate, value)
    if (compareDate === -1) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t(
            'bond.valuation.sameIndusTryBond.contentComparePaymentDateTradingDate',
          ),
        }),
      )
    }
    const checkDateMin = compareTwoDateTime(
      form.paymentDate,
      bondInfo.issueDateId,
    )
    const checkDateMax = compareTwoDateTime(
      form.paymentDate,
      bondInfo.maturityDateId,
    )
    if (checkDateMin === -1 || checkDateMax === 1) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t(
            'bond.valuation.sameIndusTryBond.contentCheckPaymentDate',
          ),
        }),
      )
    }
    dispatch(
      updateForm({
        ...form,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        tradingDate: value,
      }),
    )
  }

  const onChangePaymentDate = (value) => {
    const compareDate = compareTwoDateTime(value, form.tradingDate)
    if (compareDate === -1) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t(
            'bond.valuation.sameIndusTryBond.contentComparePaymentDateTradingDate',
          ),
        }),
      )
    }
    const checkDateMin = compareTwoDateTime(value, bondInfo.issueDateId)
    const checkDateMax = compareTwoDateTime(value, bondInfo.maturityDateId)
    if (checkDateMin === -1 || checkDateMax === 1) {
      return dispatch(
        openPopup({
          isShow: true,
          message: I18n.t(
            'bond.valuation.sameIndusTryBond.contentCheckPaymentDate',
          ),
        }),
      )
    }
    dispatch(
      updateForm({
        ...form,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        paymentDate: value,
      }),
    )
    getBondCashFlowScenarioChange(value, form.interestBasis)
  }

  const onChangeTradingVolume = (value) => {
    const valueFormat = parseInt(value)
    if (valueFormat < minTradingVolume) {
      return dispatch(
        updateForm({
          ...form,
          TradingVolume: formatVal(minTradingVolume, 0),
        }),
      )
    }
    if (valueFormat > maxTradingVolume) {
      return dispatch(
        updateForm({
          ...form,
          TradingVolume: formatVal(maxTradingVolume, 0),
        }),
      )
    }
    dispatch(
      updateForm({
        ...form,
        TradingVolume: value,
      }),
    )
  }

  const onChangeForm = (value, key) => {
    if (key === 'checkBox' && value !== form.checkBox) {
      return dispatch(
        updateForm({
          ...form,
          ytm: '',
          dirtyprice: '',
          cleanPrice: '',
          ytmRender: '',
          [key]: value,
        }),
      )
    }
    if (key === 'interestBasis') {
      const price =
        form.checkBox === valueCheckBox.DIRTY_PRICE
          ? form.dirtyprice
          : form.checkBox === valueCheckBox.DIRTY_PRICE
          ? form.cleanPrice
          : 0
      getBondCashFlowScenarioChange(form.paymentDate, value)
      onChangePrice(form.ytm, price, tabActive, value)
      return dispatch(
        updateForm({
          ...form,
          [key]: value,
        }),
      )
    }
    dispatch(
      updateForm({
        ...form,
        [key]: value,
      }),
    )
  }

  const onClickAddNewTab = (listBondCashFlow) => {
    const price =
      form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.dirtyprice || 0
        : form.checkBox === valueCheckBox.CLEAN_PRICE
        ? parseFloat(form.cleanPrice) + parseFloat(form.accInterest) || 0
        : 0

    if (price > 0 || parseFloat(form?.ytm) > 0) {
      const listBondCreated = invertKeyBy(dataTab?.[valueTab.BOND_DEFAULT].data)
      const checkWeigh = areArraysEqual(listBondCashFlow, listBondCreated)
      const listBond = listBondCashFlow
        .filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) >= new Date(form.paymentDate),
        )
        .map((e) => {
          const result = {
            settlementdate: e.tradingDateId,
            frequency: bondInfo.paymentCalendarMonth,
            paymentStatusId: e.paymentStatusId,
            coupon:
              e.bondPaymentTypeId === 1
                ? parseFloat(e?.couponInterestRate || 0) / 100
                : 0,
          }

          return result
        })
      const pastDay = filterArrayDate(
        listBondCashFlow.filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )
      const paramsLstBondCashFlow = [...listBond]
      if (pastDay) {
        paramsLstBondCashFlow.unshift({
          settlementdate: pastDay?.tradingDateId,
          frequency: bondInfo?.paymentCalendarMonth,
          paymentStatusId: pastDay?.paymentStatusId,
          coupon: parseFloat(pastDay?.couponInterestRate) / 100,
        })
      }

      const originalDebt = listBondCashFlow.find(
        (e) =>
          e.bondPaymentTypeId === 4 &&
          new Date(e.tradingDateId) > new Date(form.paymentDate),
      )

      const params = {
        lstBondCashFlow: paramsLstBondCashFlow,
        issueDate: bondInfo.issueDateId,
        weigh: checkWeigh ? 0 : 1,
        paydate: new Date(getDateWithTimezone(form.paymentDate, locale)),
        interestBasisTypeId: form.interestBasis,
        price:
          form.checkBox === valueCheckBox.DIRTY_PRICE ? form.dirtyprice : 0,
        ytm:
          form.checkBox === valueCheckBox.YTM ? parseFloat(form?.ytm) / 100 : 0,
        parValue: parseInt(originalDebt?.estimatedValue ?? 0),
        exPayDate: form.exDate
          ? new Date(getDateWithTimezone(form.exDate, locale))
          : null,
        cleanPrice:
          form.checkBox === valueCheckBox.CLEAN_PRICE
            ? parseFloat(form.cleanPrice)
            : 0,
      }
      dispatch(getPriceYtmApi(params))
    }
  }

  const onChangePrice = (ytm = 0, price = 0, tab, interestBasis) => {
    const active = tab ?? tabActive

    if (price > 0 || parseFloat(ytm) > 0) {
      const listBond = invertKeyBy(dataTab?.[active].data)
        .filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) >= new Date(form.paymentDate),
        )
        .map((e) => {
          const result = {
            settlementdate: e.tradingDateId,
            frequency: bondInfo.paymentCalendarMonth,
            paymentStatusId: e.paymentStatusId,
            coupon:
              e.bondPaymentTypeId === 1
                ? parseFloat(e?.couponInterestRate || 0) / 100
                : 0,
          }

          return result
        })
      const pastDay = filterArrayDate(
        invertKeyBy(dataTab?.[active]?.data || []).filter(
          (e) =>
            (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
            new Date(e.tradingDateId) < new Date(form.paymentDate),
        ),
        form.paymentDate,
        locale,
      )

      const listBondCreated = invertKeyBy(dataTab?.[valueTab.BOND_DEFAULT].data)

      const checkWeigh = areArraysEqual(
        invertKeyBy(dataTab?.[active].data),
        listBondCreated,
      )
      const paramsLstBondCashFlow = [...listBond]
      if (pastDay) {
        paramsLstBondCashFlow.unshift({
          settlementdate: pastDay?.tradingDateId,
          frequency: bondInfo?.paymentCalendarMonth,
          paymentStatusId: pastDay?.paymentStatusId,
          coupon: parseFloat(pastDay?.couponInterestRate) / 100,
        })
      }

      const originalDebt = invertKeyBy(dataTab?.[active].data).find(
        (e) =>
          e.bondPaymentTypeId === 4 &&
          new Date(e.tradingDateId) > new Date(form.paymentDate),
      )

      const params = {
        lstBondCashFlow: paramsLstBondCashFlow,
        issueDate: bondInfo.issueDateId,
        weigh: checkWeigh ? 0 : 1,
        paydate: new Date(getDateWithTimezone(form.paymentDate, locale)),
        interestBasisTypeId: interestBasis ? interestBasis : form.interestBasis,
        price: form.checkBox === valueCheckBox.DIRTY_PRICE ? price : 0,
        ytm: form.checkBox === valueCheckBox.YTM ? parseFloat(ytm) / 100 : 0,
        parValue: parseInt(originalDebt?.estimatedValue ?? 0),
        exPayDate: form.exDate
          ? new Date(getDateWithTimezone(form.exDate, locale))
          : null,
        cleanPrice:
          form.checkBox === valueCheckBox.CLEAN_PRICE ? parseFloat(price) : 0,
      }

      dispatch(getPriceYtmApi(params))
    } else {
      dispatch(
        updateForm({
          ...form,
          ytm: '',
          dirtyprice: '',
          cleanPrice: '',
          ytmRender: '',
        }),
      )
    }
  }

  const onChangeTab = (item) => {
    if (
      (!bondId && activeTab === valueTabType.BOND_DEFAULT) ||
      (!createBondId && activeTab === valueTabType.BOND_CREATED)
    )
      return

    if (item.isDelete) {
      setPopupDelTab(true)
      setItemPopupDelTab(item)
    }
    if (item.isEditName) {
      dispatch(
        checkNameApi({
          item,
          isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
        }),
      )
    } else {
      if (item.value === VALUE_ADD_MORE) {
        if (listTab.length >= 7) {
          return dispatch(
            openPopup({
              isShow: true,
              message: I18n.t('bond.valuation.sameIndusTryBond.SC07_M005'),
              typePopup: typePopup.ADD_ROW,
            }),
          )
        }
        const nameTab = I18n.t('bond.valuation.common.nameTab', {
          number: listTab.length - 1,
        })
        const { data } = dataTab?.[valueTab.BOND_CREATED]
        const listBondCashFlow = invertKeyBy(data, 'id')

        onClickAddNewTab(listBondCashFlow)
        dispatch(addMoreTab({ nameTab }))

        return
      }
      const price =
        form.checkBox === valueCheckBox.DIRTY_PRICE
          ? form.dirtyprice
          : form.checkBox === valueCheckBox.DIRTY_PRICE
          ? form.cleanPrice
          : 0
      if (item.value !== tabActive) {
        onChangePrice(form.ytm, price, item.value)
      }

      dispatch(updateActiveTab(item.value))
    }
  }

  const onClosePopup = () => {
    dispatch(
      openPopup({
        isShow: false,
        message: '',
        type: '',
        itemSelect: {},
      }),
    )
  }

  const deleteRow = () => {
    const item = popup.itemSelect
    const dataaInvert = dataTab?.[tabActive]
    const data = invertKeyBy(dataaInvert?.data).filter(
      (e) => e?.id !== item?.id,
    )

    const dataSort = data
      .map((e, index) => {
        if (index === 0) {
          if (new Date(e.tradingDateId) < new Date(form.paymentDate)) {
            return e
          }

          const days = moment(e?.tradingDateId).diff(e?.issueDateId, 'days')

          return {
            ...e,
            couponInterestRate: e?.couponInterestRate,
            estimatedValue:
              ((e?.couponInterestRate / 100) * e.parValue) /
              ((days ? (days === 366 ? 365 : days) : 0) / 365),
          }
        }

        if (index !== 0 && e?.bondPaymentTypeId === 4) {
          return e
        }

        const days = moment(e?.tradingDateId).diff(
          data[index - 1]?.tradingDateId,
          'days',
        )

        return {
          ...e,
          couponInterestRate: e?.couponInterestRate,
          estimatedValue:
            ((e?.couponInterestRate / 100) * e.parValue) /
            ((days ? (days === 366 ? 365 : days) : 0) / 365),
        }
      })
      .map((e, index) => ({ ...e, id: index }))

    dispatch(
      updateTab({
        data: keyBy(dataSort, 'id'),
        ids: dataSort.map((e) => e.id),
      }),
    )
    const pastDay = filterArrayDate(
      data.filter(
        (e) =>
          e.bondPaymentTypeId === 1 &&
          new Date(e.tradingDateId) < new Date(form.paymentDate),
      ),
      form.paymentDate,
      locale,
    )
    const listBond = data
      .filter(
        (e) =>
          (e.bondPaymentTypeId === 1 || e.bondPaymentTypeId === 4) &&
          new Date(e.tradingDateId) >= new Date(form.paymentDate),
      )
      .map((e) => {
        const result = {
          settlementdate: e.tradingDateId,
          frequency: bondInfo.paymentCalendarMonth,
          paymentStatusId: e.paymentStatusId,
          coupon:
            e.bondPaymentTypeId === 1
              ? parseFloat(e?.couponInterestRate || 0) / 100
              : 0,
        }

        return result
      })
    const listBondCreated = invertKeyBy(dataTab?.[valueTab.BOND_DEFAULT].data)
    const checkWeigh = areArraysEqual(data, listBondCreated)

    const paramsLstBondCashFlow = [...listBond]
    if (pastDay) {
      paramsLstBondCashFlow.unshift({
        settlementdate: pastDay?.tradingDateId,
        frequency: bondInfo?.paymentCalendarMonth,
        paymentStatusId: pastDay?.paymentStatusId,
        coupon: parseFloat(pastDay?.couponInterestRate) / 100,
      })
    }

    const originalDebt = data.find(
      (e) =>
        e.bondPaymentTypeId === 4 &&
        new Date(e.tradingDateId) > new Date(form.paymentDate),
    )

    const params = {
      lstBondCashFlow: paramsLstBondCashFlow,
      issueDate: bondInfo.issueDateId,
      weigh: checkWeigh ? 0 : 1,
      paydate: new Date(getDateWithTimezone(form.paymentDate, locale)),
      interestBasisTypeId: form.interestBasis,
      price: form.checkBox === valueCheckBox.DIRTY_PRICE ? form.dirtyprice : 0,
      ytm:
        form.checkBox === valueCheckBox.YTM ? parseFloat(form?.ytm) / 100 : 0,
      parValue: parseInt(originalDebt?.estimatedValue ?? 0),
      cleanPrice:
        form.checkBox === valueCheckBox.DIRTY_PRICE
          ? parseFloat(form.cleanPrice)
          : 0,
      exPayDate: form.exDate
        ? new Date(getDateWithTimezone(form.exDate, locale))
        : null,
    }
    const checkInputPrice =
      form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.dirtyprice || 0
        : form.checkBox === valueCheckBox.CLEAN_PRICE
        ? form.cleanPrice || 0
        : form.ytm || 0
    if (checkInputPrice !== 0) {
      dispatch(getPriceYtmApi(params))
    }

    onClosePopup()
  }

  const getBondCashFlowScenarioChange = (paymentDate, interestBasis) => {
    const params = {
      BondIds: activeTab === valueTabType.BOND_DEFAULT ? bondId : createBondId,
      TradingDateId: getDateWithTimezone(paymentDate, locale),
      InterestBasisTypeId: interestBasis,
      InterestRate: couponInput / 100,
      BondInterestId: bondInfo.BondInterestId,
      isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
    }
    if (
      parseFloat(couponInput / 100).toFixed(4) !==
      parseFloat(bondInfo.interestRate).toFixed(4)
    ) {
      params.floatInterestBenchmarkId = bondInfo.floatInterestBenchmarkId
    }

    dispatch(
      getBondCashFlowScenario({
        param: params,
        editDate: true,
      }),
    )
  }

  const changeDelTab = () => {
    if (!dataTab?.[tabActive]?.data?.[0]?.bondCashFlowId) {
      dispatch(deleteCashFlowClient(popupItemDelTab.value))
    } else {
      dispatch(
        deleteCashFlowRowApi({
          bondCashFlowId: popupItemDelTab.value,
          isUserBond: activeTab !== valueTabType.BOND_DEFAULT,
        }),
      )
    }
    const price =
      form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.dirtyprice
        : form.checkBox === valueCheckBox.DIRTY_PRICE
        ? form.cleanPrice
        : 0
    onChangePrice(form.ytm, price, valueTab.BOND_DEFAULT, form.interestBasis)
    setPopupDelTab(false)
  }

  const onChangeExtDate = (value) => {
    dispatch(
      updateForm({
        ...form,
        ytm: '',
        dirtyprice: '',
        cleanPrice: '',
        ytmRender: '',
        exDate: value,
      }),
    )
    getBondCashFlowScenarioByExDate(value, form.interestBasis)
  }

  const getBondCashFlowScenarioByExDate = (exDate, interestBasis) => {
    // const params = {
    //   BondIds: bondId,
    //   TradingDateId: getDateWithTimezone(form.paymentDate, locale),
    //   InterestBasisTypeId: interestBasis,
    //   InterestRate: couponInput / 100,
    //   BondInterestId: bondInfo.BondInterestId,
    //   exPayDate: exDate,
    // }
    // if (
    //   parseFloat(couponInput / 100).toFixed(4) !==
    //   parseFloat(bondInfo.interestRate).toFixed(4)
    // ) {
    //   params.floatInterestBenchmarkId = bondInfo.floatInterestBenchmarkId
    // }
    // dispatch(
    //   getBondCashFlowScenario({
    //     param: params,
    //     editDate: true,
    //   }),
    // )
  }

  // Use effect
  useEffect(() => {
    if (!listTabByValue[tabActive]) {
      dispatch(updateActiveTab(listTab[0].value))
    }
  }, [tabActive, JSON.stringify(listTabByValue)])

  useEffect(() => {
    if (statusAddTab) {
      // dispatch(updateActiveTab(tabActive))
      dispatch(updateStatusAddMore(false))
    }
  }, [statusAddTab])

  return (
    <>
      <div className={`${style.wrapFilter}`}>
        <div className={style.wrapRow} style={{ marginBottom: '4px' }}>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <Span
              style={{
                fontSize: 12,
                fontWeight: 600,
                textDecorationLine: 'underline',
                textTransform: 'uppercase',
              }}
            >
              <Translate value="bond.valuation.sameIndusTryBond.inputPrice.title" />
            </Span>
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 120, gap: 4 }}
            >
              <TextEllipsis
                text={'bond.valuation.sameIndusTryBond.inputPrice.ProfitBasis'}
                appendStyle={{ fontSize: 12, fontWeight: 600 }}
              />
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.YTM"
                  fontSize={8}
                />
              </div>
            </div>
            <div
              style={{
                boxSizing: 'border-box',
                flex: 1,
                maxWidth: 124,
              }}
            >
              <SelectCustom
                value={form.interestBasis}
                selectData={tabInterrestOption}
                handleChange={(e) => onChangeForm(e, 'interestBasis')}
                isI18n={true}
              />
            </div>
          </div>
        </div>
        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 120, gap: 4 }}
            >
              <Span style={getStyle(10)}>
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.tradingDate" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.tradingDate'
                  }
                />
              </Span>
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Tradingdate"
                  fontSize={8}
                />
              </div>
            </div>

            <InputDate
              date={form.tradingDate}
              onChangeDate={onChangeTradingDate}
              minDate={bondInfo?.issueDateId}
              maxDate={bondInfo?.maturityDateId}
            />
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div
              className="d-flex ali-center"
              style={{
                minWidth: 20,
                maxWidth: 116,
                gap: 4,
                flex: 1,
              }}
            >
              <label className="d-flex ali-center w-100">
                <input
                  type="checkbox"
                  className="radiobox radiobox2"
                  checked={form.checkBox === valueCheckBox.YTM ? true : false}
                  onChange={() => onChangeForm(valueCheckBox.YTM, 'checkBox')}
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <Span style={getStyle(27)}>
                  {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.YTM" /> */}
                  <TextEllipsis
                    text={'bond.valuation.sameIndusTryBond.inputPrice.YTM'}
                  />
                </Span>
                <div style={{ flexShrink: 0 }}>
                  <TooltipInfo
                    tooltip="bond.valuation.sameIndusTryBond.tooltipInput.YTM"
                    fontSize={8}
                  />
                </div>
              </label>
            </div>
            <div
              className="mt-0"
              style={{
                maxWidth: 124,
                boxSizing: 'border-box',
              }}
            >
              <InputFormatCurrency
                className={`${style.inputFormatCurrency}`}
                handleBlur={(e) => {
                  onChangeForm(e, 'ytm')
                  onChangePrice(e, 0, null, form.interestBasis)
                }}
                defaultValue={form.ytm}
                fractionDigits={0}
                suffix={'%'}
                disabled={form.checkBox === valueCheckBox.YTM ? false : true}
              />
            </div>
          </div>
        </div>
        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 120, gap: 4 }}
            >
              <Span style={getStyle(10)}>
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.paymentDate'
                  }
                />
              </Span>
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Paymentdate"
                  fontSize={8}
                />
              </div>
            </div>

            <InputDate
              date={form.paymentDate}
              onChangeDate={onChangePaymentDate}
              minDate={bondInfo?.issueDateId}
              maxDate={bondInfo?.maturityDateId}
            />
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div
              className="d-flex ali-center"
              style={{
                minWidth: 20,
                maxWidth: 118,
                gap: 4,
                flex: 1,
              }}
            >
              <label className="d-flex ali-center w-100">
                <input
                  type="checkbox"
                  className="radiobox radiobox2"
                  checked={
                    form.checkBox === valueCheckBox.DIRTY_PRICE ? true : false
                  }
                  onChange={() =>
                    onChangeForm(valueCheckBox.DIRTY_PRICE, 'checkBox')
                  }
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <Span style={getStyle(27)}>
                  <TextEllipsis
                    text={
                      'bond.valuation.sameIndusTryBond.inputPrice.dirtyPrice'
                    }
                  />
                </Span>
                <div style={{ flexShrink: 0 }}>
                  <TooltipInfo
                    tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Dirtyprice"
                    fontSize={8}
                  />
                </div>
              </label>
            </div>
            <div
              className="mt-0"
              style={{
                maxWidth: 124,
                boxSizing: 'border-box',
              }}
            >
              <InputFormatCurrency
                className={`${style.inputFormatCurrency}`}
                handleBlur={(e) => {
                  onChangeForm(e, 'dirtyprice')
                  onChangePrice(0, e, null, form.interestBasis)
                }}
                defaultValue={form.dirtyprice}
                fractionDigits={0}
                decimalRender={0}
                disabled={
                  form.checkBox === valueCheckBox.DIRTY_PRICE ? false : true
                }
              />
            </div>
          </div>
        </div>

        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 120, gap: 4 }}
            >
              <Span style={getStyle(10)}>
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.Exrightdate'
                  }
                />
              </Span>
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Exrightdate"
                  fontSize={8}
                />
              </div>
            </div>

            <InputExDate date={form.exDate} onChangeDate={onChangeExtDate} />
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div
              className="d-flex ali-center"
              style={{
                minWidth: 20,
                maxWidth: 124,
                gap: 4,
                flex: 1,
              }}
            >
              <label className="d-flex ali-center w-100">
                <input
                  type="checkbox"
                  className="radiobox radiobox2"
                  checked={
                    form.checkBox === valueCheckBox.CLEAN_PRICE ? true : false
                  }
                  onChange={() =>
                    onChangeForm(valueCheckBox.CLEAN_PRICE, 'checkBox')
                  }
                  style={{ marginRight: 4, flexShrink: 0 }}
                />
                <Span style={getStyle(27)}>
                  {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.cleanPrice" /> */}
                  <TextEllipsis
                    text={
                      'bond.valuation.sameIndusTryBond.inputPrice.cleanPrice'
                    }
                  />
                </Span>
                <div style={{ flexShrink: 0 }}>
                  <TooltipInfo
                    tooltip="bond.valuation.sameIndusTryBond.tooltipInput.Cleanprice"
                    fontSize={8}
                  />
                </div>
              </label>
            </div>
            <div
              className="mt-0"
              style={{
                maxWidth: 124,
                boxSizing: 'border-box',
              }}
            >
              <InputFormatCurrency
                className={`${style.inputFormatCurrency}`}
                handleBlur={(e) => {
                  onChangeForm(e, 'cleanPrice')
                  onChangePrice(0, e, null, form.interestBasis)
                }}
                defaultValue={form.cleanPrice}
                fractionDigits={0}
                decimalRender={0}
                disabled={
                  form.checkBox === valueCheckBox.CLEAN_PRICE ? false : true
                }
              />
            </div>
          </div>
        </div>

        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 120, gap: 4 }}
            >
              <Span style={getStyle(10)}>
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.tradingVolume" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.tradingVolume'
                  }
                />
              </Span>
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.TradingVolume"
                  fontSize={8}
                />
              </div>
            </div>
            <div className="mt-0" style={{ width: 134, gap: 4, flexShrink: 0 }}>
              <InputFormatCurrency
                className={`${style.inputFormatCurrency}`}
                handleBlur={onChangeTradingVolume}
                defaultValue={form.TradingVolume}
                fractionDigits={0}
                decimalRender={0}
              />
            </div>
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div
              className="d-flex ali-center"
              style={{ minWidth: 20, maxWidth: 90, gap: 4, flex: 1 }}
            >
              <Span style={getStyle(10)}>
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.accurateInterest" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.accurateInterest'
                  }
                />
              </Span>
              <div style={{ flexShrink: 0 }}>
                <TooltipInfo
                  tooltip="bond.valuation.sameIndusTryBond.tooltipInput.AccurateInterest"
                  fontSize={8}
                />
              </div>
            </div>
            <div style={{ flexShrink: 0, textAlign: 'right' }}>
              <Span style={{ fontSize: 12 }}>
                {formatVal(form.accInterest, 2)}
              </Span>
            </div>
          </div>
        </div>

        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div className="d-flex ali-center" style={{ flex: 1 }}>
              <Span style={getStyleContent()} className="w-100">
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.TotalParValue" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.TotalParValue'
                  }
                />
              </Span>
            </div>
            <Span style={{ fontSize: 12, color: '#FACC5C' }}>
              {formatVal(addCashValue * parseInt(form?.TradingVolume), 0)}
            </Span>
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div style={{ flex: 1 }} className="w-100">
              <Span style={getStyleContent()}>
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.YieldToMaturity" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.YieldToMaturity'
                  }
                />
              </Span>
            </div>
            <Span style={{ fontSize: 12, color: '#3DA967' }}>
              {form.ytmRender ? `${formatVal(form.ytmRender)}%` : ''}
            </Span>
          </div>
        </div>

        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <div style={{ flex: 1 }} className="w-100">
              <Span style={getStyleContent()}>
                {/* <Translate value="bond.valuation.sameIndusTryBond.inputPrice.TotalExpectedReceivedCashFlow" /> */}
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.TotalExpectedReceivedCashFlow'
                  }
                />
              </Span>
            </div>
            <Span
              style={{
                fontSize: 12,
                color: '#FACC5C',
                fontWeight: 340,
              }}
            >
              <TextEllipsis
                text={formatVal(
                  totalCashFlow * parseInt(form.TradingVolume),
                  0,
                )}
              />
            </Span>
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}>
            <div style={{ flex: 1 }} className="w-100">
              <Span style={getStyleContent()}>
                <TextEllipsis
                  text={
                    'bond.valuation.sameIndusTryBond.inputPrice.RemainingDuration'
                  }
                />
              </Span>
            </div>
            <Span style={{ fontSize: 12, color: '#3DA967' }}>
              {checkDate()}
            </Span>
          </div>
        </div>

        <div className={style.wrapRow}>
          <div
            className={style.wrapItem}
            style={{ width: '50%', flexShrink: 0 }}
          >
            <Span style={{ fontSize: 12, fontWeight: 600, overflow: 'hidden' }}>
              <TextEllipsis
                text={
                  'bond.valuation.sameIndusTryBond.inputPrice.TotalInvestmentValue'
                }
              />
            </Span>
            <Span
              style={{
                fontSize: 12,
                color: '#FACC5C',
                fontWeight: 340,
              }}
            >
              <TextEllipsis
                text={formatVal(
                  (parseInt(form?.dirtyprice) || 0) *
                    parseInt(form.TradingVolume || 1),
                  0,
                )}
              />
            </Span>
          </div>
          <div className={style.wrapItem} style={{ width: '50%' }}></div>
        </div>
      </div>
      <div style={{ marginTop: '21px' }}>
        <Span
          style={{
            fontSize: 12,
            fontWeight: 600,
            textDecorationLine: 'underline',
            textTransform: 'uppercase',
          }}
        >
          <Translate value="bond.valuation.sameIndusTryBond.inputPrice.cashLineScenels" />
        </Span>
      </div>
      <div className="mb-8 mt-8">
        <DispatchActionTab
          onChangeTab={(e) => onChangeTab(e)}
          data={listTab}
          item={{
            height: '20px',
          }}
          containerStyle={{
            width: listTab.length > 4 ? '' : ``,
          }}
          itemStyle={{ width: '80px', fontWeight: 600, fontSize: 11 }}
          activeTab={tabActive}
          // widthComponent={0.4 * width}
          isAddMore={width > RESPONSIVE_WIDTH ? false : true}
          limit={width > 520 ? 4 : 3}
        />
      </div>

      <PopupAlert
        message={popup.message}
        isShow={popup.type !== typePopup.DELETE_ROW && popup.isShow}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => onClosePopup()}
      />
      <PopupEvent
        title={I18n.t('bond.valuation.sameIndusTryBond.popupDelete.title')}
        isShow={popup.type === typePopup.DELETE_ROW && popup.isShow}
        message={popup.message}
        handleClose={() => onClosePopup()}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_DELETE" />,
          event: () => deleteRow(),
        }}
        btnSubmit={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: () => onClosePopup(),
        }}
      />
      <PopupEvent
        title={I18n.t('bond.valuation.sameIndusTryBond.popupDelete.title')}
        isShow={popupDelTab}
        message={I18n.t('bond.valuation.sameIndusTryBond.popupDelete.message')}
        handleClose={() => setPopupDelTab(false)}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_DELETE" />,
          event: () => changeDelTab(),
        }}
        btnSubmit={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: () => setPopupDelTab(false),
        }}
      />
    </>
  )
}

export default Filter
