export const gdp = {
  INDICATOR: 'Indicator',
  TOTAL_GDP: 'Total GDP',
  GDP_BY_SECTOR: 'GDP by sector',
  GDP_GROWTH_BY_SECTOR: 'GDP GROWTH BY SECTORS',
  GDP_BY_PROVINCE: 'GDP by Provinces',
  GDP_GROWTH_FORECAST: 'GDP GROWTH FORECAST',
  GDP_COMPOSITION_BY_SECTOR: 'GDP composition by sectors',
  GDP_BY_SPENDING_METHOD: 'GDP by Spending Method',
  GROWTH_GDP_BY_SPENDING_METHOD: 'Growth of GDP by Spending Method',
  TOP_10_GDP: 'TOP 10 GDP BY PROVINCES',
  GDP_STRUCTURE_PROVINCES: 'GDP structure of provinces',
  DOWNLOAD_STRUCTURE_PROVINCES: 'GDP structure of ',
  GDP_STRUCTURE_SPENDING_METHOD: 'GDP Structure by spending method',
  CHANGES_IN_GDP: 'Changes in GDP structure by sectors in ',
  CHANGES_IN_SPENDING_METHOD: 'Changes in GDP Structure by spending method',
  NOMINAL: 'Nominal',
  REAL: 'Real',
  ANNUAL: 'Annual',
  ALL: 'All',
  VALUE_PERCENT: '(Value)',
  VALUE: 'Value',
  ACC_VALUE: 'Acc. Value',
  GROWTH_YOY: 'Growth YoY',
  YEARLY: 'Yearly',
  QUARTERLY: 'Quarterly',
  ACC_QUARTERLY: 'Acc. Quarterly',
  TRILLION_VND: 'Value: trillion VND',
  TRILLION: '(Trillion VND)',
  GROWTH_YOY_FOOTER: 'Unit: %',
  SEARCH_PROVINCE: 'Search province',
  PER_CAPITA: 'Per Capita',
  PROVINCE_VALUE: 'Value (million VND)',
  PROVINCE_GROWTH_YOY: 'Growth YoY (%)',
  PROVINCE_PER_HEAD: 'Per head (million VND)',
  UNIT_BILLION_VND: 'Unit: billion VND',
  UNIT_PERCENT: 'Unit: %',
  UNIT_TRILLION_VND: 'Unit: trillion VND',
  UNIT_MILLION_VND: 'Unit: million VND',
  BILLION_VND: '(Billion VND)',
  BILLION_TOOLTIP: ' Billion VND',
  MILLION_VND: '(Million VND)',
  MILLION_TOOLTIP: ' Million VND',
  MAX_ITEM: 'The number of selected items is no more than 20 items.',
  PER_CAPITA_REAL: 'Index of GDP per capita',
}
