import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { convertKeyNavigate } from '../../utils/Common'

const useCustomNavigate = (keepSearchParams = []) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const customNavigate = (
    pathname,
    headerSearch,
    queryStr,
    replace = false,
  ) => {
    navigate(
      pathname +
        (queryStr ||
          convertKeyNavigate(
            headerSearch,
            keepSearchParams,
            searchParams,
            createSearchParams,
          )),
      {
        replace: replace,
        state: { headerSearch },
      },
    )
  }
  return customNavigate
}

export default useCustomNavigate
