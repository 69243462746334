import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from '../../utils/Common'
import { loadingKey, selectCompanies, selectLoading } from './store/slice'
import { searchCompanyThunk } from './store/thunk'

const useSearchCompany = (screen, params) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading(screen)(loadingKey.SEARCH_COMPANY))
  const companies = useSelector(selectCompanies(screen))

  useEffect(() => {
    if (params) handleSearchCompany(screen, params)
  }, [params])

  const handleSearchCompany = useCallback(
    debounce((screen, params) => {
      dispatch(searchCompanyThunk({ screen, data: params }))
    }, 800),
    [],
  )

  return {
    loading,
    companies,
  }
}

export default useSearchCompany
