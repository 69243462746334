import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Z_INDEX } from '../../constants/Common'
import { convertDateFromDatePicker, useGetDayTitle } from './helper'
import './index.css'
import style from './index.module.css'

const CalendarPopper = ({
  zIndex,
  hightlightDates,
  CustomInput,
  date,
  handleChange,
  position,
  customHeader,
  minDate,
  maxDate,
  isOpen,
  onClose,
  onInput,
  onOpen,
  customInputStyle,
  placeholder,
  popperClassName,
  disabled,
  popperContainer,
  scrollIds,
  calendarClassName,
  excludeDateIntervals,
}) => {
  const getDayTitle = useGetDayTitle()

  const thisCalendar = useRef()

  const [popperPlacement, setPopperPlacement] = useState('')

  const handleCalendarOpen = () => {
    const el = document.querySelector('.react-datepicker-popper')
    if (el) {
      el.style.zIndex = zIndex || Z_INDEX.DATE_PICKER
      const { right } = el.getBoundingClientRect()
      if (right > document.body.clientWidth) {
        el.classList.remove(style.defaultPopperClassStart)
        el.classList.add(style.defaultPopperClassEnd)
        setPopperPlacement('bottom-end')
      }
    }
    onOpen && onOpen()
  }

  const handleCalendarClose = () => {
    onClose && onClose()
  }

  const handleOpenCalendar = () => thisCalendar.current.setOpen(true)
  const handleCloseCalendar = () => thisCalendar.current.setOpen(false)

  useEffect(() => {
    if (isOpen) {
      handleOpenCalendar()
    }
  }, [isOpen])

  useEffect(() => {
    if (position) {
      setPopperPlacement(position)
    }
  }, [position])

  useEffect(() => {
    if (scrollIds && scrollIds.length) {
      const scrollElements = scrollIds.map((id) =>
        document.querySelector(`#${id} .scrollbars > div:first-child`),
      )
      scrollElements.forEach((element) => {
        element?.addEventListener('scroll', handleCloseCalendar)
      })
      return () => {
        scrollElements.forEach((element) => {
          element?.removeEventListener('scroll', handleCloseCalendar)
        })
      }
    }
  }, [scrollIds])

  const handleChangeDateTime = (dates) => {
    return handleChange(convertDateFromDatePicker(dates))
  }

  return (
    <div className={style.container}>
      <DatePicker
        ref={thisCalendar}
        selected={date}
        onChange={handleChangeDateTime}
        onCalendarOpen={handleCalendarOpen}
        onCalendarClose={handleCalendarClose}
        highlightDates={hightlightDates}
        formatWeekDay={getDayTitle}
        customInput={
          <CustomInput
            date={date}
            maxDate={maxDate}
            minDate={minDate}
            placeholderText={placeholder}
            style={customInputStyle}
            disabled={disabled}
            onInput={onInput}
          />
        }
        popperPlacement={popperPlacement}
        renderCustomHeader={customHeader}
        minDate={minDate}
        maxDate={maxDate}
        popperClassName={popperClassName || style.defaultPopperClassStart}
        disabled={disabled}
        popperContainer={popperContainer}
        calendarClassName={calendarClassName}
        excludeDateIntervals={excludeDateIntervals}
      />
    </div>
  )
}

CalendarPopper.propTypes = {
  zIndex: PropTypes.number,
  hightlightDates: PropTypes.array,
  CustomInput: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
    .isRequired,
  handleChange: PropTypes.func.isRequired,
  position: PropTypes.string,
  customHeader: PropTypes.func,
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  customInputStyle: PropTypes.object,
  placeholder: PropTypes.string,
  popperClassName: PropTypes.string,
  disabled: PropTypes.bool,
  scrollIds: PropTypes.array,
  calendarClassName: PropTypes.string,
  excludeDateIntervals: PropTypes.arrayOf(
    PropTypes.shape({
      start: PropTypes.number,
      end: PropTypes.number,
    }),
  ),
}

export default CalendarPopper
