import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import InvestmentStructurePanel from './investmentStructure/InvestmentStructurePanel'
import InvestmentStructureFDIPanel from './investmentStructureFDI/InvestmentStructureFDIPanel'
import TotalRegisteredFDIPanel from './totalRegisteredFDI/TotalRegisteredFDIPanel'

const MAP_KEY = {
  INVESTMENT_STRUCTURE_FDI: 'INVESTMENT_STRUCTURE_FDI',
  INVESTMENT_STRUCTURE: 'INVESTMENT_STRUCTURE',
  TOTAL_REGISTERED_FDI: 'TOTAL_REGISTERED_FDI',
}

const MIN_SIZE_SETTING = {
  [MAP_KEY.INVESTMENT_STRUCTURE_FDI]: {
    minWidth: 364,
    minHeight: 346,
  },
  [MAP_KEY.INVESTMENT_STRUCTURE]: {
    minWidth: 364,
    minHeight: 342,
  },
  [MAP_KEY.TOTAL_REGISTERED_FDI]: {
    minWidth: 731,
    minHeight: 692,
  },
}

const MAP_SIZE = {
  [MAP_KEY.TOTAL_REGISTERED_FDI]: {
    width: `calc(200% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${4 * COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: 0,
    minLeft: 0,
    ...MIN_SIZE_SETTING[MAP_KEY.TOTAL_REGISTERED_FDI],
    disableVerticalResize: false,
    disableHorizontalResize: true,
    verticalResize: [
      {
        key: MAP_KEY.INVESTMENT_STRUCTURE,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.INVESTMENT_STRUCTURE_FDI,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
  },
  [MAP_KEY.INVESTMENT_STRUCTURE]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${2 * COMPONENT.MARGIN}px)`,
    top: 0,
    minTop: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_REGISTERED_FDI].minWidth +
      2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.INVESTMENT_STRUCTURE],
    disableVerticalResize: true,
    disableHorizontalResize: false,
    horizontalResize: [
      {
        key: MAP_KEY.INVESTMENT_STRUCTURE_FDI,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
  },
  [MAP_KEY.INVESTMENT_STRUCTURE_FDI]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${4 * COMPONENT.MARGIN}px)`,
    top: `calc(50%)`,
    minTop:
      MIN_SIZE_SETTING[MAP_KEY.INVESTMENT_STRUCTURE].minHeight +
      2 * COMPONENT.MARGIN,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minLeft:
      MIN_SIZE_SETTING[MAP_KEY.TOTAL_REGISTERED_FDI].minWidth +
      2 * COMPONENT.MARGIN,
    ...MIN_SIZE_SETTING[MAP_KEY.INVESTMENT_STRUCTURE_FDI],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <TotalRegisteredFDIPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.TOTAL_REGISTERED_FDI}
        sizes={sizes}
        setSizes={setSizes}
      />
      <InvestmentStructurePanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INVESTMENT_STRUCTURE}
        sizes={sizes}
        setSizes={setSizes}
      />
      <InvestmentStructureFDIPanel
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INVESTMENT_STRUCTURE_FDI}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
