import { FinancialChart } from '../../../common/chart/financialChart'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import { keySettingChart } from '../../chart/constants'
import { Footer } from '../footer'
import { genFooterData } from '../footer/helper'
import { getFormatXAxisDashboardView } from '../helpers'
import TitleChart from './TitleChart'

const ChartComponent = ({
  width,
  height,
  data,
  schema,
  footerData,
  detailChart,
}) => {
  if (!data.length) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          <TitleChart width={width} nameChart={detailChart.nameChart} />
          {size.height && (
            <FinancialChart
              width={width}
              height={height - size.height}
              data={data}
              schema={schema}
              marginChart={{ left: 26, right: 26, bottom: 10 }}
              styleSetting={detailChart.styleSetting || {}}
              typeLegendYAxis={
                detailChart.settings[keySettingChart.Y_AXIS_LEGEND]
              }
              formatXAxis={getFormatXAxisDashboardView(
                Object.values(detailChart.indicatorSelected)
                  .flat()
                  .map((item) => item.timeFrequency),
              )}
            />
          )}
          <Footer
            key={width}
            list={genFooterData(footerData, detailChart.listLegendCustom)}
            styleSetting={
              detailChart.styleSetting[keySettingChart.X_AXIS_LEGEND] || {}
            }
          />
        </>
      )}
    </SizeTracker>
  )
}

export default ChartComponent
