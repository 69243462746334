import { useDispatch } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Input } from '../../../../../common/html/Input'
import { debounce } from '../../../../../utils/Common'
import { changeOwner } from '../store/slice'
import style from '../style.module.css'

const OwnerSearch = ({ isIpadScreen }) => {
  const dispatch = useDispatch()

  const onChange = (val) => {
    dispatch(changeOwner(val))
  }

  return (
    <div className={`${style.widthMax}`}>
      <div className={`form-search-inbox ${style.inputOwner}`}>
        <Input
          type="text"
          className={'search-inbox bg-grey'}
          placeholder={I18n.t(
            'market.marketInDepth.directorDeal.OWNER_PLACEHOLDER',
          )}
          onChange={debounce((e) => onChange(e.target.value), 1000)}
        />
        <button type="button">
          <i className={`icon-search-2 ${style.colorIcon}`} />
        </button>
      </div>
    </div>
  )
}

export default OwnerSearch
