import PropTypes from 'prop-types'
import { CalcResize } from '../../../common/CalcResize'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { SizeTracker } from '../../../common/sizeTracker'
import style from './index.module.css'
import { TopLabel } from './TopLabel'

export const ChartContainer = ({
  id,
  children,
  data,
  width,
  height,
  topLabel,
  isLoading,
}) => {
  return (
    <div id={id} className="h-100">
      {isLoading ? (
        <Loading />
      ) : !data || !data.length ? (
        <NoData />
      ) : topLabel ? (
        <SizeTracker>
          {(size) => (
            <>
              {topLabel && (
                <div className={`${style['title-header']} canvas-chart-title`}>
                  <TopLabel label={topLabel} />
                </div>
              )}
              {height && size.height && (
                <div>
                  <CalcResize
                    initSize={{
                      width: width,
                      height: height - size.height,
                    }}
                  >
                    {(resizeSize) => (
                      <div
                        style={{ width: resizeSize.width, margin: '0 auto' }}
                      >
                        {children(resizeSize)}
                      </div>
                    )}
                  </CalcResize>
                </div>
              )}
            </>
          )}
        </SizeTracker>
      ) : (
        <>
          {height && (
            <div>
              <CalcResize
                initSize={{
                  width: width,
                  height: height,
                }}
              >
                {(resizeSize) => (
                  <div style={{ width: resizeSize.width, margin: '0 auto' }}>
                    {children(resizeSize)}
                  </div>
                )}
              </CalcResize>
            </div>
          )}
        </>
      )}
    </div>
  )
}

ChartContainer.propTypes = {
  id: PropTypes.string,
  children: PropTypes.func,
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  topLabel: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
}
