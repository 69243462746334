export const MAP_STOCKS_SYMBOL = {
  stockSymbol: 'stockSymbol',
  matchPrice: 'priceInfo.matchPrice',
  ceilingPrice: 'priceInfo.ceilingPrice',
  floorPrice: 'priceInfo.floorPrice',
  referencePrice: 'priceInfo.referencePrice',
  openPrice: 'priceInfo.openPrice',
  highPrice: 'priceInfo.highPrice',
  lowPrice: 'priceInfo.lowPrice',
  best1Bid: 'bidAskInfo.best1Bid',
  best1Offer: 'bidAskInfo.best1Offer',
  percentPriceChange: 'priceInfo.percentPriceChange',
  priceChange: 'priceInfo.priceChange',
  tradingDate: 'priceInfo.tradingDate',
}
