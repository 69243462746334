import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import style from '../index.module.css'
import Container from './Container'
import SearchTickerComponent from './SearchTickerComponent'

const Company = () => {
  return (
    <div className={style.companyContainer}>
      <div className={style.wrap} style={{ paddingRight: 12 }}>
        <Span className={style.yellowColor}>
          <Translate value={'newsReport.dailyReport.dailyReport.COMPANY'} />
        </Span>
        <SearchTickerComponent />
        <Container />
      </div>
    </div>
  )
}

export default Company
