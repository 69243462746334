import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import TopReleaseService from '../../../../../../../core/services/bond/overview/topissues/TopIssues'
import { handleExport } from '../../../../../../utils/Export'

export const getDataTopRealse = createAsyncThunk(
  'demo/top20/getData/GET_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const response = await TopReleaseService.getDataTop(data)
      if (response.success) {
        const dataList = response?.data || []
        const data = dataList.map((e, index) => ({
          ...e,
          stt: index + 1,
          icbName: e?.icbName || '',
          organizationShortName: e?.organizationShortName || '',
        }))
        return data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getDataTopCirculate = createAsyncThunk(
  'demo/top20/getDataCirculate',
  async (data, { rejectWithValue }) => {
    try {
      const response = await TopReleaseService.getDataTopIssuerCirculate(data)
      if (response.success) {
        const dataList = response?.data || []
        const data = dataList.map((e, index) => ({
          ...e,
          stt: index + 1,
        }))
        return data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTopIssuerReleaseExport = createAsyncThunk(
  'demo/top20/TopIssuerReleaseExport',
  async (data) => {
    const response = await TopReleaseService.getExportTop(data)
    handleExport(response.data, response.filename)
  },
)

export const getCirculateExport = createAsyncThunk(
  'demo/top20/TopIssuerReleaseExport',
  async (data) => {
    const response = await TopReleaseService.getExportTopCirculate(data)
    handleExport(response.data, response.filename)
  },
)
