import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { cancelFormatValues, checkRadioButton, formatValues } from '../helper'
import style from '../index.module.css'
import {
  changeCondition,
  selectItemCondition,
  selectItemConditionByIds,
  selectTemplate,
} from '../store/slice'

const regexValue = /^[+-]?[0-9]*\.?[0-9]*$/ //positive and negative float number

const InputType = ({ indicatorId, index, valueDataType }) => {
  const dispatch = useDispatch()

  const valueFrom = useSelector(
    selectItemCondition(`${indicatorId}.${index}.conditionValues.0`),
  )
  const valueTo = useSelector(
    selectItemCondition(`${indicatorId}.${index}.conditionValues.1`),
  )
  const unit = useSelector(selectItemCondition(`${indicatorId}.${index}.unit`))
  const template = useSelector(selectTemplate)

  const [displayValueFrom, setDisplayValueFrom] = useState(valueFrom || '')
  const [displayValueTo, setDisplayValueTo] = useState(valueTo || '')

  const lastValueFrom = useRef(displayValueFrom)
  const lastValueTo = useRef(displayValueTo)

  useEffect(() => {
    if (!valueFrom && !valueTo) {
      setDisplayValueFrom('')
      setDisplayValueTo('')
    }
  }, [valueDataType])

  useEffect(() => {
    if (
      valueFrom !== lastValueFrom.current ||
      valueTo !== lastValueTo.current
    ) {
      setDisplayValueFrom(formatValues(valueFrom, unit))
      setDisplayValueTo(formatValues(valueTo, unit))
      lastValueFrom.current = valueFrom
      lastValueTo.current = valueTo
    }
  }, [valueFrom, valueTo])

  useEffect(() => {
    setDisplayValueFrom(formatValues(valueFrom, unit))
    setDisplayValueTo(formatValues(valueTo, unit))
  }, [template])

  const handleChangeValueFrom = (e) => {
    if (e.target.value.match(regexValue) || e.target.value === '') {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.conditionValues.0`,
          value: e.target.value,
        }),
      )
      setDisplayValueFrom(e.target.value)
      lastValueFrom.current = e.target.value
    } else {
      setDisplayValueFrom(lastValueFrom.current)
    }
  }

  const focusInput = (setDisplayValue, displayValue) =>
    setDisplayValue(cancelFormatValues(displayValue, unit))

  const blurInput = (setDisplayValue, displayValue) => {
    if (displayValue === '+' || displayValue === '-') {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.conditionValues.0`,
          value: '',
        }),
      )
      setDisplayValue(formatValues('', unit))
    } else {
      setDisplayValue(formatValues(displayValue, unit))
    }
  }

  const handleChangeValueTo = (e) => {
    if (e.target.value.match(regexValue) || e.target.value === '') {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.conditionValues.1`,
          value: e.target.value,
        }),
      )
      setDisplayValueTo(e.target.value)
      lastValueTo.current = e.target.value
    } else {
      setDisplayValueTo(lastValueTo.current)
    }
  }

  return (
    <>
      <div className={`form-control ${style.w45}`}>
        <Input
          type="text"
          className="bg-blue-1 w-100 h-20"
          value={displayValueFrom}
          onFocus={() => focusInput(setDisplayValueFrom, displayValueFrom)}
          onBlur={() => blurInput(setDisplayValueFrom, displayValueFrom)}
          onChange={handleChangeValueFrom}
        />
      </div>
      <div className={`${style.w10} text-center ${style.textCondition}`}>-</div>
      <div className={`form-control ${style.w45}`}>
        <Input
          type="text"
          className="bg-blue-1 w-100 h-20"
          value={displayValueTo}
          onFocus={() => focusInput(setDisplayValueTo, displayValueTo)}
          onBlur={() => blurInput(setDisplayValueTo, displayValueTo)}
          onChange={handleChangeValueTo}
        />
      </div>
    </>
  )
}

const RadioType = ({ indicatorId, index }) => {
  const dispatch = useDispatch()

  const value = useSelector(
    selectItemCondition(`${indicatorId}.${index}.conditionValues.0`),
  )
  const selectData = useSelector(
    selectItemConditionByIds(indicatorId),
  ).selectionValues

  const handleChangeRadio = (e, value) => {
    if (e.target.checked) {
      dispatch(
        changeCondition({
          key: `${indicatorId}.${index}.conditionValues`,
          value: [value],
        }),
      )
    }
  }

  return (
    <>
      <div className={`form-control ${style.w45}`}>
        <label className={`w-100 d-flex ali-center mb-0 ${style.mt4}`}>
          <input
            type="radio"
            className={`radiobox radiobox2 ${style.minWidth10px} ${style.inputRadio}`}
            checked={value === selectData[0]?.value}
            onChange={(e) => handleChangeRadio(e, selectData[0]?.value)}
          />
          <Span
            className={
              value === selectData[0]?.value
                ? style.textActive
                : style.textRadio
            }
          >
            <TextEllipsis text={selectData[0]?.text} isI18n={false} />
          </Span>
        </label>
      </div>
      <div className={`${style.w10} text-center ${style.textCondition}`} />
      <div className={`form-control ${style.w45}`}>
        <label className={`w-100 d-flex ali-center mb-0 ${style.mt4}`}>
          <input
            type="radio"
            className={`radiobox radiobox2 ${style.minWidth10px} ${style.inputRadio}`}
            checked={value === selectData[1]?.value}
            onChange={(e) => handleChangeRadio(e, selectData[1]?.value)}
          />
          <Span
            className={
              value === selectData[1]?.value
                ? style.textActive
                : style.textRadio
            }
          >
            <TextEllipsis text={selectData[1]?.text} isI18n={false} />
          </Span>
        </label>
      </div>
    </>
  )
}

const Values = ({ indicatorId, index, valueDataType }) => {
  const selectionType = useSelector(
    selectItemConditionByIds(indicatorId),
  ).selectionType

  return (
    <div className="d-flex ali-center mb-8">
      {checkRadioButton(
        selectionType,
        <RadioType indicatorId={indicatorId} index={index} />,
        <InputType
          indicatorId={indicatorId}
          index={index}
          valueDataType={valueDataType}
        />,
      )}
    </div>
  )
}

Values.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  index: PropTypes.number.isRequired,
  valueDataType: PropTypes.number.isRequired,
}

export default Values
