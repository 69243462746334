import { useDispatch, useSelector } from 'react-redux'
import { DispatchActionTabWithCalendar } from '../../../../common/tabs/DispatchActionTabWithCalendar'
import { TooltipInfo } from '../../../../common/tooltipInfo'
import { NAV_DATA_FUND_FLOW_TIME_RANGES } from './constants'
import { changeFilter, selectFilters } from './store/slice'

export const Filter = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilters)

  // Actions
  const handleChangeTimeRange = (item) => {
    dispatch(changeFilter({ timeRange: item.value }))
  }
  const handleChangeCalendar = (payload) => {
    dispatch(
      changeFilter({
        timeRange: payload.activeTab,
        startDate: payload.startDate,
        endDate: payload.endDate,
      }),
    )
  }

  return (
    <div className="d-flex ali-center justify-content-end mb-8">
      <TooltipInfo
        tooltip="fund.fundProfile.FUND_FLOW_NOTE"
        style={{ marginRight: 16 }}
      />
      <DispatchActionTabWithCalendar
        id="navDataFundFlow"
        tabs={NAV_DATA_FUND_FLOW_TIME_RANGES}
        activeTab={filter.timeRange}
        startDate={filter.startDate}
        endDate={filter.endDate}
        datePreset={NAV_DATA_FUND_FLOW_TIME_RANGES}
        onChangeTab={handleChangeTimeRange}
        onChangeCalendar={handleChangeCalendar}
      />
    </div>
  )
}
