import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useGetFund from '../../../../common/masterData/useGetFund'
import { debounce } from '../../../../utils/Common'
import { TABLE_TABS } from '../constants'
import { getParams } from '../helpers'
import {
  selectFilters,
  selectFundManagement,
  selectTableTab,
} from '../store/slice'
import { getTableDataThunk } from '../store/thunk'

export const withGetData = (Component) => (props) => {
  const dispatch = useDispatch()
  const { funds } = useGetFund()

  const locale = useSelector((state) => state.i18n.locale)
  const filters = useSelector(selectFilters)
  const tableTab = useSelector(selectTableTab)
  const fundManagements = useSelector(selectFundManagement)

  const handleGetData = useCallback(
    debounce((params, tab) => {
      dispatch(getTableDataThunk({ params, tableTab: tab }))
    }, 200),
    [],
  )

  useEffect(() => {
    const params = getParams({
      filters,
      tableTab,
      funds,
      fundManagements,
    })
    handleGetData(params, tableTab)
  }, [
    JSON.stringify(filters.fund),
    JSON.stringify(filters.management),
    JSON.stringify(filters.structure),
    JSON.stringify(filters.type),
    JSON.stringify(filters.frequency),
    JSON.stringify(filters.status),
    filters.timeRange,
    filters.month,
    filters.year,
    filters.page,
    tableTab,
    locale,
  ])

  useEffect(() => {
    if (tableTab !== TABLE_TABS.SECTOR_ALLOCATION) {
      const params = getParams({
        filters,
        tableTab,
        funds,
        fundManagements,
      })
      handleGetData(params, tableTab)
    }
  }, [filters.sortField, filters.sortOrder])

  return <Component {...props} />
}
