import copy from 'copy-to-clipboard'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import Popup from '../../common/popup'
import { getChartById, getDashboardById } from '../store/thunk'
import style from './style.module.css'
import styles from './styleTheme.module.css'

const PopupShareChart = ({ type, id, handleShow }) => {
  const inputRef = useRef()
  const dispatch = useDispatch()
  const [copied, setCopied] = useState(false)
  const [url, setURL] = useState('')

  useEffect(() => {
    const asyncFn = async () => {
      if (id) {
        if (type === 'chart') {
          const { payload } = await dispatch(getChartById({ Id: id }))
          if (payload) {
            setURL(generateShareChartURL(payload.shareId))
          }
        } else if (type === 'dashboard') {
          const { payload } = await dispatch(getDashboardById({ Id: id }))
          if (payload) {
            setURL(generateShareDashboardURL(payload.shareId))
          }
        }
      }
    }
    asyncFn()
  }, [id])

  const copyURL = () => {
    const copyText = inputRef.current
    // Select the text field
    copyText.select()
    copyText.setSelectionRange(0, 99999) // For mobile devices
    copy(url)
    setCopied(true)
  }

  const generateShareChartURL = (shareKey) => {
    return `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' : ''
    }${window.location.port}/financial-chart/share-chart/${shareKey}`
  }

  const generateShareDashboardURL = (shareKey) => {
    return `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' : ''
    }${window.location.port}/financial-chart/share-dashboard/${shareKey}`
  }

  const onClose = () => {
    handleShow(false)
  }

  return (
    <>
      {url && (
        <Popup overlayCustom={styles.overlayCustom}>
          <div className={`modal modal-small ${styles.modal}`}>
            <div className={`modal-title ${styles.titleStyle}`}>
              <h3>
                <Span>
                  <Translate value="financialChart.SHARE_CHART" />
                </Span>
              </h3>
              <a onClick={onClose} className="close-modal">
                <i
                  className="icon-delete-stroke"
                  style={{ color: '#646464' }}
                />
              </a>
            </div>
            <div className={`modal-content ${styles.modalContentStyle}`}>
              <Input
                ref={inputRef}
                type="text"
                value={url}
                className={[style.copyLinkInput, styles.inputStyle].join(' ')}
                readOnly
              />
              <div
                className="group-btn justify-content-end"
                style={{ marginTop: '15px' }}
              >
                <Button
                  className={`btn h-20 ${styles.buttonBlueStyle}`}
                  style={{
                    fontSize: 11,
                    padding: '0 16px',
                    width: 'fit-content',
                  }}
                  onClick={copyURL}
                  disabled={copied}
                >
                  {!copied && <Translate value="financialChart.COPY_URL" />}
                  {copied && <Translate value="financialChart.COPIED_URL" />}
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

PopupShareChart.propType = {
  chart: PropTypes.object,
  handleShow: PropTypes.func,
}

export default PopupShareChart
