import { isEqual, uniq } from 'lodash'
import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { convertDateFromDatePicker } from '../../../../common/calendar/helper'
import useGetICB from '../../../../common/masterData/useGetICB'
import TextEllipsis from '../../../../common/textEllipsis'
import { getAllIdItem } from '../../../../common/tree/helper'
import { handleDuplicateArray, keyBy } from '../../../../utils/Common'
import { FORMAT } from '../../../../utils/Datetime'
import {
  ALL_VALUE,
  BOND_TYPES,
  BOND_TYPE_RADIOS,
  CREATE_BOND_TYPE_RADIOS,
  FILTER_FIELD_CODES,
  FILTER_FIELD_TYPES,
  TABLE_FIELD_CODES,
} from '../../Screening/constants'
import { INDICATOR_GROUPS, TAB_TYPES } from '../../constants'
import { renderConditionParams } from '../../helper'
import {
  changeDataRaw,
  changeTemplateList,
  changeTemplateOnSearch,
  selectAddInformatics,
  selectConditions,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  selectTemplate,
  selectWatchlist,
  setFilterParameters,
} from '../../store/slice'
import { getDataBondList } from '../Table/store/thunk'
import style from './index.module.css'

const List = ({ redirectToBondIssuer }) => {
  const dispatch = useDispatch()

  const { conditions, ...restParams } = useSelector(selectFilterParams)
  const information = useSelector(selectAddInformatics)
  const initialConditions = useSelector(selectConditions)
  const initialSectors = useSelector(selectInitialSectors)
  const templates = useSelector(selectTemplate)
  const listParent = useSelector(selectParentSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const locale = useSelector((state) => state.i18n.locale)
  const watchlist = useSelector(selectWatchlist)

  const { ICBs, ICBTreeNotDeleteChildren } = useGetICB(
    true,
    true,
    undefined,
    undefined,
    true,
  )

  const data = conditions
    ?.filter((item) => {
      const isFilterDefault = [
        FILTER_FIELD_TYPES.MULTI_SELECT,
        FILTER_FIELD_TYPES.SELECT,
        FILTER_FIELD_TYPES.RADIO,
      ].includes(item.selectionType)

      return (
        !isFilterDefault ||
        (isFilterDefault && item.conditionValues[0] !== ALL_VALUE)
      )
    })
    .filter(
      (item) =>
        item?.tableFieldCode !== TABLE_FIELD_CODES.BOND_TYPE ||
        (item?.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE &&
          item.conditionValues[0] !== ALL_VALUE),
    )

  const renderValue = (value, item) => {
    if (!item) {
      return ''
    }

    switch (item.selectionType) {
      case FILTER_FIELD_TYPES.DATE:
        return item?.conditionValues
          .map((date) => convertDateFromDatePicker(date, FORMAT.DATE[locale]))
          ?.join(' - ')
      case FILTER_FIELD_TYPES.SELECT:
      case FILTER_FIELD_TYPES.MULTI_SELECT:
      case FILTER_FIELD_TYPES.RADIO:
        if (
          item.tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
          item.tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE
        ) {
          const check = (
            restParams?.indicatorGroup === INDICATOR_GROUPS.BOND_CREATING
              ? CREATE_BOND_TYPE_RADIOS
              : BOND_TYPE_RADIOS
          ).find((bondType) => isEqual(bondType.value, item?.conditionValues))

          return check ? I18n.t(check.name) : ''
        }

        if (item.tableFieldCode === TABLE_FIELD_CODES.STATUS) {
          const added = information.find(
            (value) => value.indicatorId === item.indicatorId,
          )

          if (!added) {
            return ''
          }

          return added?.selectionValues?.length ===
            item?.conditionValues?.length
            ? I18n.t('common.ALL')
            : item?.conditionValues
                ?.map((type) => {
                  const option = value?.selectionValues?.find(
                    (select) => select.value === type,
                  )

                  if (!option) {
                    return type
                  }

                  return option.text
                })
                ?.join(', ')
        }

        return item?.conditionValues
          ?.map((type) => {
            const option = value?.selectionValues?.find(
              (select) => select.value === type,
            )

            if (!option) {
              return type
            }

            return option.text
          })
          ?.join(', ')
      case FILTER_FIELD_TYPES.NUMBER: {
        return item?.conditionValues?.join(' - ') + ` ${value?.unit ?? ''}`
      }
      default:
        if (
          restParams.indicatorGroup === INDICATOR_GROUPS.BOND_CREATING &&
          item.tableFieldCode === FILTER_FIELD_CODES.BOND_TYPE
        ) {
          const check = CREATE_BOND_TYPE_RADIOS.find((bondType) =>
            isEqual(bondType.value, item?.conditionValues),
          )

          return check ? I18n.t(check.name) : ''
        }

        return ''
    }
  }

  const renderText = (value, item) => {
    if (!item) {
      return ''
    }

    const title = value?.indicatorName?.trim()
    const stringValue = renderValue(value, item)

    return `${title}: ${stringValue}`
  }

  const renderConditions = (conditionsList, index, tableFieldCode) => {
    const conditionParams = renderConditionParams(
      initialConditions,
      tableFieldCode === TABLE_FIELD_CODES.BOND_TYPE ||
        tableFieldCode === TABLE_FIELD_CODES.EN_BOND_TYPE
        ? information
        : undefined,
    )

    const isExist = conditionParams?.find(
      (item) => item.indicatorId === conditionsList[index].indicatorId,
    )

    if (!isExist) {
      return [
        ...conditionsList.slice(0, index),
        ...conditionsList.slice(index + 1),
      ]
    }

    return [
      ...conditionsList.slice(0, index),
      isExist,
      ...conditionsList.slice(index + 1),
    ]
  }

  const handleRemoveFilter = (id, tableFieldCode) => {
    const index = conditions.findIndex((item) => item.indicatorId === id)
    const newSectors =
      uniq([...listParent, ...restParams.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...restParams.sectors])

    if (index === -1) {
      return
    }

    const addInformation = information.find((item) => item.indicatorId === id)

    if (!addInformation) return

    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    if (raw) {
      const newConditions = renderConditions(conditions, index, tableFieldCode)

      dispatch(
        changeDataRaw({
          key: 'conditions',
          value: newConditions,
        }),
      )

      dispatch(
        setFilterParameters({
          key: 'conditions',
          value: newConditions,
        }),
      )

      const newParams = {
        ...restParams,
        sectors: newSectors,
        conditions: newConditions,
        ticker: restParams.ticker,
        pageSize: 300,
        pageIndex: 1,
      }

      dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))

      return
    }
    const data = {
      templateId: TAB_TYPES.RAW,
      templateName:
        restParams.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
          ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
          : 'bond.bondScreening.filter.TEMPLATE_NAME',
      templateType: restParams.indicatorGroup,
    }

    const newTemplates = [...templates.slice(0, 6), data, ...templates.slice(6)]

    dispatch(changeTemplateList(newTemplates))

    const newConditions = renderConditions(conditions, index)

    dispatch(
      changeTemplateOnSearch({
        ...data,
        parameter: {
          ...restParams,
          conditions: newConditions,
        },
      }),
    )

    dispatch(
      setFilterParameters({
        key: 'conditions',
        value: newConditions,
      }),
    )

    const newParams = {
      ...restParams,
      sectors: newSectors,
      conditions: newConditions,
      ticker: [],
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))
  }

  const handleRemoveTicker = () => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    const newSectors =
      uniq([...listParent, ...restParams.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...restParams.sectors])

    if (raw) {
      dispatch(
        changeDataRaw({
          key: 'ticker',
          value: [],
        }),
      )
      dispatch(setFilterParameters({ key: 'ticker', value: [] }))
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          restParams.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: restParams.indicatorGroup,
      }

      const newTemplates = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplates))

      dispatch(
        changeTemplateOnSearch({
          ...data,
          parameter: {
            ...restParams,
            ticker: [],
            conditions,
          },
        }),
      )

      dispatch(setFilterParameters({ key: 'ticker', value: [] }))
    }

    const newParams = {
      ...restParams,
      sectors: newSectors,
      conditions: conditions,
      ticker: [],
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))
  }

  const handleRemoveWatchlist = () => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    const newSectors =
      uniq([...listParent, ...restParams.sectors])?.length ===
      uniq([...initialSectors, ...initialParentSectors])?.length
        ? []
        : uniq([...listParent, ...restParams.sectors])

    if (raw) {
      dispatch(
        changeDataRaw({
          key: 'watchListId',
          value: [],
        }),
      )
      dispatch(setFilterParameters({ key: 'watchListId', value: [] }))
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          restParams.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: restParams.indicatorGroup,
      }

      const newTemplates = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplates))

      dispatch(
        changeTemplateOnSearch({
          ...data,
          parameter: {
            ...restParams,
            watchListId: [],
            conditions,
          },
        }),
      )

      dispatch(setFilterParameters({ key: 'watchListId', value: [] }))
    }

    const newParams = {
      ...restParams,
      sectors: newSectors,
      conditions: conditions,
      watchListId: [],
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))
  }

  const handleRemoveSectors = () => {
    const raw = templates.find((item) => item.templateId === TAB_TYPES.RAW)

    const newSectors = uniq([...initialParentSectors, ...initialSectors])

    if (raw) {
      dispatch(
        changeDataRaw({
          key: 'sectors',
          value: newSectors,
        }),
      )
      dispatch(setFilterParameters({ key: 'sectors', value: newSectors }))
    } else {
      const data = {
        templateId: TAB_TYPES.RAW,
        templateName:
          restParams.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
            ? 'bond.bondScreening.allIssuer.TEMPLATE_NAME'
            : 'bond.bondScreening.filter.TEMPLATE_NAME',
        templateType: restParams.indicatorGroup,
      }

      const newTemplates = [
        ...templates.slice(0, 6),
        data,
        ...templates.slice(6),
      ]

      dispatch(changeTemplateList(newTemplates))

      dispatch(
        changeTemplateOnSearch({
          ...data,
          parameter: {
            ...restParams,
            sectors: newSectors,
            conditions,
          },
        }),
      )

      dispatch(setFilterParameters({ key: 'sectors', value: newSectors }))
    }

    const newParams = {
      ...restParams,
      sectors: newSectors,
      conditions: conditions,
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(getDataBondList({ params: newParams, redirectToBondIssuer }))
  }

  const bondTickerText = useMemo(
    () =>
      `${I18n.t(
        restParams?.indicatorGroup === INDICATOR_GROUPS.BOND_CREATING
          ? 'bond.bondScreening.createBond.BOND_TICKER'
          : restParams?.indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
          ? 'bond.bondScreening.allIssuer.TICKER_OR_ISSUERS'
          : 'bond.bondScreening.filter.TICKER_OR_ISSUERS',
      )}: ${restParams?.ticker?.join(', ')}`,
    [restParams?.ticker, restParams?.indicatorGroup],
  )

  const renderWatchlistText = (watchListIds) => {
    const watchlistSelected = watchlist.filter((item) =>
      watchListIds.includes(item.watchListId),
    )

    return `${I18n.t('bond.bondScreening.bondList.WATCHLIST')}: ${
      !!watchlistSelected?.length
        ? watchlistSelected.map((item) => item.watchListName).join(', ')
        : ''
    }`
  }

  const renderIndustryText = () => {
    const text = listParent
      .map((key) => keyBy(ICBs, 'icbCode')[key]?.icbName)
      .join(', ')

    return `${I18n.t('bond.bondScreening.common.INDUSTRY')}: ${text}`
  }

  const childSectors = useMemo(() => {
    const sectorIcbCodes = keyBy(ICBs, 'icbCode')
    const sectorIcbIds = keyBy(ICBs, 'icbId')

    const childIds = handleDuplicateArray(
      (restParams.sectors || []).reduce((arr, item) => {
        const idsChildren = getAllIdItem(
          ICBTreeNotDeleteChildren[sectorIcbCodes[item].icbId],
          'icbId',
        )
        return arr.concat(idsChildren)
      }, []),
    )

    const list = handleDuplicateArray(
      childIds.reduce(
        (arr, id) =>
          sectorIcbIds[id] ? [...arr, sectorIcbIds[id].icbCode] : arr,
        [],
      ),
    )

    return list
  }, [ICBs.length, restParams.sectors?.length, ICBTreeNotDeleteChildren.length])

  const isShow = useMemo(
    () =>
      !!restParams?.ticker?.length ||
      (!!restParams?.sectors?.length &&
        childSectors?.length !== initialSectors?.length) ||
      !!restParams?.watchListId?.length ||
      !!data?.length,
    [
      restParams?.ticker,
      restParams?.sectors,
      childSectors,
      initialSectors,
      restParams?.watchListId,
      data,
    ],
  )

  return isShow ? (
    <div
      style={{
        maxWidth: 1840,
      }}
    >
      <ScrollComponent
        autoHeight={true}
        autoHeightMax={76}
        verticalTrackWidth={1}
      >
        <div className={style.list}>
          {!!restParams?.ticker?.length && (
            <div className={style.listItem} style={{ maxWidth: 300 }}>
              <TextEllipsis text={bondTickerText} isI18n={false} />
              <div className={style.listIcon}>
                <svg
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  onClick={() => handleRemoveTicker()}
                >
                  <path
                    d="M8 0.471351L7.52865 0L4 3.52865L0.471351 0L0 0.471351L3.52865 4L0 7.52865L0.471351 8L4 4.47135L7.52865 8L8 7.52865L4.47135 4L8 0.471351Z"
                    fill="#ECECEC"
                  />
                </svg>
              </div>
            </div>
          )}
          {!!restParams?.sectors?.length &&
            childSectors?.length !== initialSectors?.length && (
              <div className={style.listItem} style={{ maxWidth: 300 }}>
                <TextEllipsis
                  text={renderIndustryText(restParams?.sectors)}
                  isI18n={false}
                />
                <div className={style.listIcon}>
                  <svg
                    className="cursor-pointer"
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    onClick={() => handleRemoveSectors()}
                  >
                    <path
                      d="M8 0.471351L7.52865 0L4 3.52865L0.471351 0L0 0.471351L3.52865 4L0 7.52865L0.471351 8L4 4.47135L7.52865 8L8 7.52865L4.47135 4L8 0.471351Z"
                      fill="#ECECEC"
                    />
                  </svg>
                </div>
              </div>
            )}
          {!!restParams?.watchListId?.length && (
            <div className={style.listItem} style={{ maxWidth: 300 }}>
              <TextEllipsis
                text={renderWatchlistText(restParams?.watchListId)}
                isI18n={false}
              />
              <div className={style.listIcon}>
                <svg
                  className="cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  onClick={() => handleRemoveWatchlist()}
                >
                  <path
                    d="M8 0.471351L7.52865 0L4 3.52865L0.471351 0L0 0.471351L3.52865 4L0 7.52865L0.471351 8L4 4.47135L7.52865 8L8 7.52865L4.47135 4L8 0.471351Z"
                    fill="#ECECEC"
                  />
                </svg>
              </div>
            </div>
          )}
          {!!data?.length &&
            data
              ?.filter((e) => e?.conditionValues !== BOND_TYPES.ALL)
              ?.map((item, index) => {
                const value = information.find(
                  (item1) => item1.indicatorId === item.indicatorId,
                )

                return (
                  <div
                    key={index}
                    className={style.listItem}
                    style={{ maxWidth: 300 }}
                  >
                    <TextEllipsis text={renderText(value, item)} />
                    <div className={style.listIcon}>
                      <svg
                        className="cursor-pointer"
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        onClick={() => handleRemoveFilter(item.indicatorId)}
                      >
                        <path
                          d="M8 0.471351L7.52865 0L4 3.52865L0.471351 0L0 0.471351L3.52865 4L0 7.52865L0.471351 8L4 4.47135L7.52865 8L8 7.52865L4.47135 4L8 0.471351Z"
                          fill="#ECECEC"
                        />
                      </svg>
                    </div>
                  </div>
                )
              })}
        </div>
      </ScrollComponent>
    </div>
  ) : (
    <></>
  )
}

export default List
