import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getFisheryProfile } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/overview/fisheryProfile',
  initialState: {
    isLoading: true,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFisheryProfile.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getFisheryProfile.fulfilled, (state, action) => {
      const payloadData = action.payload || []
      state.data = payloadData

      state.isLoading = false
    })
    builder.addCase(getFisheryProfile.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectData = (state) => state[slice.name].data

register(slice.name, slice.reducer)
