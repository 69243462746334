import { uniq } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupAlertSuccess from '../../../../common/popup/PopupAlertSuccess'
import { debounce } from '../../../../utils/Common'
import { INDICATOR_GROUPS, TAB_TYPES } from '../../constants'
import {
  formatAddInformation,
  renderAddedInformaticsParams,
  renderConditionParams,
} from '../../helper'
import {
  changeActiveTab,
  changeIsCreateBond,
  changeIsDeleteBond,
  changeIsUpdateBond,
  changeParameters,
  paramsDefault,
  selectActiveTab,
  selectAddInformatics,
  selectConditions,
  selectCreateStatus,
  selectDeleteStatus,
  selectFilter,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectIsDelete,
  selectParentSectors,
  selectUpdateStatus,
  setInitialSort,
} from '../../store/slice'
import {
  getCreateBondListData,
  getTemplateByIdData,
  getTemplatesData,
  getTemplatesDataByDelete,
} from '../../store/thunk'
import { changeIsFirst, selectFullColumnId } from '../Table/store/slice'
import { getDataBondList } from '../Table/store/thunk'
import List from './List'
import Tab from './Tab'
import Filter from './filter/index'

const Header = ({ containerRef, redirectToBondIssuer }) => {
  const dispatch = useDispatch()

  const { indicatorGroup, ...restParams } = useSelector(selectFilterParams)
  const isDelete = useSelector(selectIsDelete)
  const { loading } = useSelector(selectFilter)
  const isCreateBond = useSelector(selectCreateStatus)
  const isUpdateBond = useSelector(selectUpdateStatus)
  const isDeleteBond = useSelector(selectDeleteStatus)
  const listParent = useSelector(selectParentSectors)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const initialConditions = useSelector(selectConditions)
  const initialAddedInformatics = useSelector(selectAddInformatics)
  const currentTab = useSelector(selectActiveTab)
  const ids = useSelector(selectFullColumnId(1))

  const [isShowCreate, setIsShowCreate] = useState(false)
  const [isShowUpdate, setIsShowUpdate] = useState(false)
  const [isShowDelete, setIsShowDelete] = useState(false)

  // Actions
  const handleGetDataBondList = useCallback(
    debounce((params) => {
      dispatch(getDataBondList({ params, redirectToBondIssuer }))
    }, 500),
    [],
  )

  const handleGetTemplates = () => {
    dispatch(getTemplatesData({}))
  }

  const handleSearchBond = () => {
    const conditionsParams = renderConditionParams(
      initialConditions,
      initialAddedInformatics,
    )

    const addedInformaticsParams = renderAddedInformaticsParams(
      initialAddedInformatics,
    )

    const newParams = {
      ...paramsDefault.parameter,
      indicatorGroup,
      sectors: [],
      conditions: conditionsParams,
      addedInformations: addedInformaticsParams,
      pageSize: 300,
      pageIndex: 1,
    }

    dispatch(
      changeParameters({
        ...paramsDefault,
        parameter: {
          ...newParams,
          sectors: uniq([...initialParentSectors, ...initialSectors]),
        },
      }),
    )

    dispatch(
      setInitialSort({
        sortBy: paramsDefault.parameter.sortBy,
        order: paramsDefault.parameter.order,
      }),
    )

    dispatch(changeActiveTab(TAB_TYPES.ALL))
    dispatch(changeIsFirst(true))
    handleGetDataBondList(newParams)
  }

  // Use effect
  useEffect(() => {
    handleGetTemplates()
  }, [])

  useEffect(() => {
    if (isDelete) {
      dispatch(getTemplatesDataByDelete({ type: indicatorGroup }))
    }
  }, [isDelete, indicatorGroup])

  useEffect(() => {
    if (isCreateBond) {
      setIsShowCreate(true)
      dispatch(changeIsCreateBond(false))
      dispatch(getCreateBondListData())
      handleSearchBond()
    }
  }, [isCreateBond])

  useEffect(() => {
    if (!!isUpdateBond?.length) {
      dispatch(getCreateBondListData())
      setIsShowUpdate(true)
      dispatch(
        getTemplateByIdData({
          params: {
            TemplateId: currentTab,
          },
          redirectToBondIssuer,
        }),
      )
      dispatch(changeIsFirst(true))
    }
  }, [isUpdateBond, currentTab])

  useEffect(() => {
    if (!!isDeleteBond?.length) {
      setIsShowDelete(true)
      dispatch(getCreateBondListData())
      dispatch(
        getTemplateByIdData({
          params: { TemplateId: currentTab },
          redirectToBondIssuer,
        }),
      )
      dispatch(changeIsFirst(true))
    }
  }, [isDeleteBond, currentTab])

  useEffect(() => {
    if (
      restParams.conditions.length &&
      indicatorGroup === INDICATOR_GROUPS.ALL_ISSUER
    ) {
      const newSectors =
        uniq([...listParent, ...restParams.sectors])?.length ===
        uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...restParams.sectors])
      const params = {
        ...restParams,
        sectors: newSectors,
        conditions: restParams.conditions,
        addedInformations: formatAddInformation(
          restParams.addedInformations,
          ids,
        ),
        indicatorGroup,
        pageIndex: 1,
      }

      handleGetDataBondList(params)
    }
  }, [restParams.timeRange, restParams.startDate, restParams.endDate])

  return (
    <div className="flex-column" style={{ gap: 12 }}>
      <Tab redirectToBondIssuer={redirectToBondIssuer} />
      <Filter
        containerRef={containerRef}
        redirectToBondIssuer={redirectToBondIssuer}
      />
      {!loading && <List redirectToBondIssuer={redirectToBondIssuer} />}
      <PopupAlertSuccess
        message={
          <Translate value="bond.bondScreening.createBond.MESSAGE_CREATE" />
        }
        isShow={isShowCreate}
        handleClose={() => setIsShowCreate(false)}
      />
      <PopupAlertSuccess
        message={
          <Translate
            value="bond.bondScreening.createBond.MESSAGE_UPDATE"
            ticker={isUpdateBond}
          />
        }
        isShow={isShowUpdate}
        handleClose={() => {
          dispatch(changeIsUpdateBond(''))
          setIsShowUpdate(false)
        }}
      />
      <PopupAlertSuccess
        message={
          <Translate
            value="bond.bondScreening.createBond.MESSAGE_DELETE"
            ticker={isDeleteBond}
          />
        }
        isShow={isShowDelete}
        handleClose={() => {
          dispatch(changeIsDeleteBond(''))
          setIsShowDelete(false)
        }}
      />
    </div>
  )
}

export default Header
