import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MARGIN } from '../../../../../common/chart/constants'
import { Span } from '../../../../../common/html/Span'
import { SizeTracker } from '../../../../../common/sizeTracker'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { getFontSize } from '../../../../../utils/FontSize'
import { getTranslateSourceNote } from '../../helps'
import { selectViewType } from '../store/slice'
import { ChartComponent } from './ChartComponent'
import { SelectType } from './SelectType'
import {
  chartType,
  priceChartTab,
  priceChartTabItems,
  priceChartTabThunkFunc,
} from './constants'
import {
  getChartDataFormat,
  getCompareMargin,
  getMarginGroupChart,
  getMarginLineChart,
  getMarginStackChart,
} from './helper'
import style from './index.module.css'
import { reset, selectChartData } from './store/slice'

const MIN_WIDTH = 430

export const Chart = ({ width, height, dataType }) => {
  const calcFontSize = getFontSize(12)
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const viewType = useSelector(selectViewType)
  const data = useSelector(selectChartData)

  // Use state
  const [type, setType] = useState(priceChartTab.PROFITABILITY)

  // Get data
  const getSourceNote = useMemo(() => {
    return getTranslateSourceNote(data, false)
  }, [locale, data])

  const getListChart = useMemo(() => {
    return priceChartTabItems[type]
  }, [type])

  const getMargin = useMemo(() => {
    if (data.length) {
      const margins = getListChart.map((chart) => {
        switch (chart.chartType) {
          case chartType.GROUP:
            return getMarginGroupChart(
              chart,
              getChartDataFormat(data, chart),
              calcFontSize,
            )
          case chartType.STACK:
            return getMarginStackChart(
              chart,
              getChartDataFormat(data, chart),
              calcFontSize,
            )
          case chartType.LINE:
            return getMarginLineChart(
              chart,
              getChartDataFormat(data, chart),
              calcFontSize,
            )
          default:
            return MARGIN
        }
      })

      return getCompareMargin(margins)
    } else {
      return MARGIN
    }
  }, [data, getListChart, calcFontSize])

  // Use effect
  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId || !getListChart.length) return
    dispatch(reset())
    dispatch(
      priceChartTabThunkFunc[type]({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        TimeFrequency: viewType,
      }),
    )
  }, [viewType, type, dataType, sectorInfo])

  // Render
  const renderCharts = (sizeHeight) => {
    const customHeight = (height - sizeHeight) / 3

    return getListChart.map((chartOption, index) => (
      <React.Fragment key={chartOption.thunkFuncName}>
        {index > 0 && <div className={style.strokeDivider}></div>}
        <ChartComponent
          chartOption={chartOption}
          width={width}
          height={customHeight || 0}
          margin={getMargin}
        />
      </React.Fragment>
    ))
  }

  return (
    <div id="sectorFinancialAnalysisCorporate">
      <SizeTracker>
        {(size) => (
          <>
            <div data-html2canvas-ignore="true">
              {width > MIN_WIDTH ? (
                <DispatchActionTab
                  data={Object.keys(priceChartTab).map((key) => {
                    return {
                      title: `sector.financialAnalysis.corporate.${key}`,
                      value: priceChartTab[key],
                    }
                  })}
                  itemStyle={{
                    paddingLeft: 12,
                    paddingRight: 12,
                    fontWeight: 600,
                  }}
                  activeTab={type}
                  onChangeTab={(el) => setType(el.value)}
                  widthComponent={width}
                />
              ) : (
                <SelectType activeTab={type} onChangeTab={setType} />
              )}
            </div>
            {size.height && renderCharts(size.height)}
            <Span
              className="canvas-chart-title t-left"
              style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}
            >
              {getSourceNote}
            </Span>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
