import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../../common/html/Button'
import Popup from '../../../../../common/popup'
import { MAX_ITEM } from '../constant'
import {
  changeIsShowPopup,
  changeSelectedItems,
  selectIsShowPopup,
  selectSelectedItems,
  triggerCallData,
} from '../store/slice'
import style from './index.module.css'
import Sector from './Sector'
import SelectedItems from './SelectedItems'

const ComparePopup = () => {
  const dispatch = useDispatch()

  const isShow = useSelector(selectIsShowPopup)
  const selectedItems = useSelector(selectSelectedItems)

  const [items, setItems] = useState([])

  const selectedItemCount = items.filter((item) => !item.isDeleted).length

  useEffect(() => {
    setItems(selectedItems)
  }, [selectedItems])

  const handleCancel = () => {
    setItems(selectedItems)
    dispatch(changeIsShowPopup(false))
  }

  const handleApply = () => {
    dispatch(changeSelectedItems(items))
    dispatch(triggerCallData())
    dispatch(changeIsShowPopup(false))
  }

  if (isShow)
    return (
      <Popup>
        <div className="modal modal-medium" style={{ minWidth: 900 }}>
          <div className="modal-title">
            <h3 className="text-up">
              <Translate value="sector.sectorConstituents.valuation.COMPARE" />
            </h3>
            <a onClick={handleCancel} className="close-modal">
              <i className="icon-delete-stroke" />
            </a>
          </div>
          <div className="modal-content">
            <div className={style.modalContent}>
              <Sector items={items} setItems={setItems} />
              <SelectedItems items={items} setItems={setItems} />
            </div>
            <div className={style.selectedCount}>
              <Translate value="sector.sectorConstituents.valuation.SELECTED_COUNT" />
              {` ${selectedItemCount}/${MAX_ITEM}`}
            </div>
            {selectedItemCount >= MAX_ITEM && (
              <div className={style.maxItemText}>
                <Translate value="sector.sectorConstituents.valuation.MAX_ITEMS_TEXT" />
              </div>
            )}
            <div className={style.groupButtonModal}>
              <Button
                className="btn bg-grey-1 w-80 h-20"
                onClick={handleCancel}
              >
                <Translate value={'common.button.BUTTON_CANCEL'} />
              </Button>
              <Button
                onClick={handleApply}
                className={`btn btn-blue w-80 h-20 `}
              >
                <Translate value={'common.button.BUTTON_APPLY'} />
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    )

  return <></>
}

export default ComparePopup
