import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import PopupWarningSave from '../popup/PopupWarningSave'
import style from './EditMenu.module.css'

export const CancelBtn = () => {
  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(true)
  }

  return (
    <>
      <Button
        className={style.btnGray}
        style={{ marginRight: 20 }}
        onClick={onClick}
      >
        <Translate value="financialChart.CANCEL" />
      </Button>
      <PopupWarningSave isShow={isShow} handleShow={setIsShow} />
    </>
  )
}
