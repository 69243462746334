import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import { Table } from '../../../common/table'
import { getISOStartOrEndOfDay } from '../../../utils/Datetime'
import { indicatorUpcomingEvent } from '../constants'
import {
  disableRowHovered,
  getFirstDayOfMonth,
  getLastDayNext6Month,
  handleCollapseLevel,
} from '../helper'
import {
  ALL_EVENT_ID,
  changeActiveRow,
  getChecked,
  getEventTypesLoading,
  getMultipleActiveRows,
  getTab,
  getUpcomingEventData,
  getUpcomingStartMonth,
  selectDisableClick,
  selectEndDate,
  selectLoadingUpcomingEvent,
  selectStartDate,
  selectUpcomingEventIds,
  selectUpcomingEventValue,
  sort,
} from '../store/slice'
import { getUpcomingEvents } from '../store/thunk'
import Description from './Description'
import EventColumnCell from './EventColumnCell'
import FirstColumnCell from './FirstColumnCell'
import OtherColumnsCell from './OtherColumnsCell'

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()
  const [rowCollapse, setRowCollapse] = useState([])

  const ids = useSelector(selectUpcomingEventIds)
  const startDate = useSelector(selectStartDate)
  const endDate = useSelector(selectEndDate)
  const timeZone = UseTimeZone()
  const tab = useSelector(getTab)
  const checked = useSelector(getChecked)
  const locale = useSelector((state) => state.i18n.locale)
  const upcomingStartMonth = useSelector(getUpcomingStartMonth)
  const isLoadingUpcomingEvent = useSelector(selectLoadingUpcomingEvent)
  const eventTypeLoading = useSelector(getEventTypesLoading)
  const upcomingEventData = useSelector(getUpcomingEventData)
  const multipleActiveRowsIds = useSelector(getMultipleActiveRows)
  const disableClick = useSelector(selectDisableClick)

  const eventTypeIds = checked[tab]
    .filter((item) => item !== ALL_EVENT_ID)
    .toString()

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowCollapse.indexOf(rowId)
      if (index === -1) {
        setRowCollapse((old) => [...old, rowId])
      }
    } else {
      setRowCollapse((old) => old.filter((item) => item !== rowId))
    }
  }

  useEffect(() => {
    if (!eventTypeLoading && !startDate && !endDate) {
      dispatch(
        getUpcomingEvents({
          DateFrom: getISOStartOrEndOfDay(
            getFirstDayOfMonth(upcomingStartMonth),
            timeZone,
            true,
          ),
          DateTo: getISOStartOrEndOfDay(
            getLastDayNext6Month(upcomingStartMonth),
            timeZone,
            false,
          ),
          EventTypeIds: eventTypeIds,
        }),
      )
    }
  }, [
    tab,
    checked,
    locale,
    upcomingStartMonth,
    eventTypeLoading,
    startDate,
    endDate,
  ])

  useEffect(() => {
    if (!eventTypeLoading && startDate && endDate) {
      dispatch(
        getUpcomingEvents({
          DateFrom: getISOStartOrEndOfDay(new Date(startDate), timeZone, true),
          DateTo: getISOStartOrEndOfDay(new Date(endDate), timeZone, false),
          EventTypeIds: eventTypeIds,
        }),
      )
    }
  }, [
    startDate,
    endDate,
    tab,
    checked,
    locale,
    upcomingStartMonth,
    eventTypeLoading,
  ])

  if (isLoadingUpcomingEvent || eventTypeLoading) {
    return <Loading />
  }

  return (
    <div style={{ height }}>
      {ids.length ? (
        <Table
          ids={ids}
          sort={sort}
          changeActiveRow={changeActiveRow}
          multipleActiveRowsIds={multipleActiveRowsIds}
          disableClickGroups={disableClick}
          columnDraggable={false}
          getDataFromRedux={selectUpcomingEventValue}
          rowDraggable={false}
          schema={Object.keys(indicatorUpcomingEvent).map((key, index) => {
            const title = I18n.t(`economy.event.event.${key}`)
            const colId = indicatorUpcomingEvent[key]
            const result = {
              colId,
              title,
              thStyle: {
                textAlign: 'center',
                fontWeight: 600,
              },
              tdStyle: {
                textAlign: 'right',
              },
              disableSort: true,
            }
            if (index === 0) {
              return {
                ...result,
                canCustomTd: true,
                disableSort: false,
                render: (val, rowId, { className, style }) => {
                  return (
                    <FirstColumnCell
                      rowId={rowId}
                      style={style}
                      className={className}
                      locale={locale}
                      onRowCollapse={onRowCollapse}
                    />
                  )
                },
              }
            }
            if (index === 1) {
              return {
                ...result,
                canCustomTd: true,
                render: (val, rowId) => (
                  <EventColumnCell val={val} rowId={rowId} />
                ),
              }
            }
            if (index === 2) {
              return {
                ...result,
                canCustomTd: true,
                render: (val, rowId, { className, style }) => (
                  <Description
                    val={val}
                    rowId={rowId}
                    columnItem={index}
                    style={style}
                    className={className}
                  />
                ),
              }
            }
            return {
              ...result,
              canCustomTd: true,
              render: (val, rowId, { className, style }) => {
                return (
                  <OtherColumnsCell
                    val={val}
                    rowId={rowId}
                    columnItem={index}
                    style={style}
                    className={className}
                    colId={colId}
                  />
                )
              },
            }
          })}
          stickyFirstColumn
          isPagination={false}
          hasFooter={false}
          resizable={false}
          rowsCollapse={rowCollapse}
          isCollapse={true}
          levelCollapse={handleCollapseLevel(upcomingEventData)}
          disableRowHovered={disableRowHovered(upcomingEventData)}
        />
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default TableComponent
