import { useSelector } from 'react-redux'
import { Recommendation } from '.'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'

export const PanelRecommendation = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)
  const chartName = UseI18n('corporate.peerValuation.consensus.RECOMMENDATION')
  const nameScreen = UseI18n('corporate.peerValuation.consensus.NAME')

  return (
    <Panel
      title="corporate.peerValuation.consensus.RECOMMENDATION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      titleJpg={UseI18n('corporate.peerValuation.consensus.RECOMMENDATION')}
      downloadJpgParams={{
        domId: 'consensusRecommendationChart',
        nameScreen,
        chartName,
        tabName: '',
        tickerName: basicInfo.ticker,
      }}
    >
      <Recommendation width={width} height={height} />
    </Panel>
  )
}
