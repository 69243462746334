import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'

export const TopLabel = ({ label }) => {
  return (
    <div style={{ textTransform: 'uppercase', textAlign: 'center' }}>
      <Translate value={label} />
    </div>
  )
}

TopLabel.propTypes = {
  label: PropTypes.string,
}
