import financialDataProxy from '../../proxies/corporate/FinancialDataProxy'
import { ServiceBase } from '../ServiceBase'

class FinancialDataService extends ServiceBase {
  getFinancialDataBalanceSheet(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataBalanceSheet',
      params,
    )(() => financialDataProxy.getFinancialDataBalanceSheet(params))
  }

  getFinancialDataIncomeStatement(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataIncomeStatement',
      params,
    )(() => financialDataProxy.getFinancialDataIncomeStatement(params))
  }

  getFinancialDataCashFlow(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataCashFlow',
      params,
    )(() => financialDataProxy.getFinancialDataCashFlow(params))
  }

  getFinancialDataNote(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataNote',
      params,
    )(() => financialDataProxy.getFinancialDataNote(params))
  }

  getFinancialDataRatio(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataRatio',
      params,
    )(() => financialDataProxy.getFinancialDataRatio(params))
  }

  getFinancialDataDividendChart(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataDividendChart',
      params,
    )(() => financialDataProxy.getFinancialDataDividendChart(params))
  }

  getFinancialDataDividendTable(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialDataDividendTable',
      params,
    )(() => financialDataProxy.getFinancialDataDividendTable(params))
  }

  getIndicatorMapping(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getIndicatorMapping',
      params,
    )(() => financialDataProxy.getIndicatorMapping(params))
  }

  downloadFinancialData(params) {
    return this.getFileDownload(() =>
      financialDataProxy.downloadFinancialData(params),
    )
  }

  downloadRatio(params) {
    return this.getFileDownload(() => financialDataProxy.downloadRatio(params))
  }

  downloadDividendHistory(params) {
    return this.getFileDownload(() =>
      financialDataProxy.downloadDividendHistory(params),
    )
  }

  getBalanceSheetStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getBalanceSheetStructureChartData',
      params,
    )(() => financialDataProxy.getBalanceSheetStructureChartData(params))
  }

  getDGAndCGChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getDGAndCGChartData',
      params,
    )(() => financialDataProxy.getDGAndCGChartData(params))
  }

  getTOIStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getTOIStructureChartData',
      params,
    )(() => financialDataProxy.getTOIStructureChartData(params))
  }

  getProfitAndLossChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getProfitAndLossChartData',
      params,
    )(() => financialDataProxy.getProfitAndLossChartData(params))
  }

  getCashFlowStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getCashFlowStructureChartData',
      params,
    )(() => financialDataProxy.getCashFlowStructureChartData(params))
  }

  getOperatingCashFlowChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getOperatingCashFlowChartData',
      params,
    )(() => financialDataProxy.getOperatingCashFlowChartData(params))
  }

  getLoansByIndustryChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getLoansByIndustryChartData',
      params,
    )(() => financialDataProxy.getLoansByIndustryChartData(params))
  }

  getLoansByTermChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getLoansByTermChartData',
      params,
    )(() => financialDataProxy.getLoansByTermChartData(params))
  }

  getFinancialRatioChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialRatioChartData',
      params,
    )(() => financialDataProxy.getFinancialRatioChartData(params))
  }

  getPeersComparisonChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getPeersComparisonChartData',
      params,
    )(() => financialDataProxy.getPeersComparisonChartData(params))
  }

  getAssetBreakdownChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getAssetBreakdownChartData',
      params,
    )(() => financialDataProxy.getAssetBreakdownChartData(params))
  }

  getOperatingSalesStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getOperatingSalesStructureChartData',
      params,
    )(() => financialDataProxy.getOperatingSalesStructureChartData(params))
  }

  getOperatingExpensesStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getOperatingExpensesStructureChartData',
      params,
    )(() => financialDataProxy.getOperatingExpensesStructureChartData(params))
  }

  getMarginLendingChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getMarginLendingChartData',
      params,
    )(() => financialDataProxy.getMarginLendingChartData(params))
  }

  getFinancialAssetsStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialAssetsStructureChartData',
      params,
    )(() => financialDataProxy.getFinancialAssetsStructureChartData(params))
  }

  getSTAndLTBorrowingChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getSTAndLTBorrowingChartData',
      params,
    )(() => financialDataProxy.getSTAndLTBorrowingChartData(params))
  }

  getRevenueAndProfitChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getRevenueAndProfitChartData',
      params,
    )(() => financialDataProxy.getRevenueAndProfitChartData(params))
  }

  getProfitabilityChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getProfitabilityChartData',
      params,
    )(() => financialDataProxy.getProfitabilityChartData(params))
  }

  getInventoryStructureChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getInventoryStructureChartData',
      params,
    )(() => financialDataProxy.getInventoryStructureChartData(params))
  }

  getFinancialExpensesChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialExpensesChartData',
      params,
    )(() => financialDataProxy.getFinancialExpensesChartData(params))
  }

  getReservesChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getReservesChartData',
      params,
    )(() => financialDataProxy.getReservesChartData(params))
  }

  getTICSEChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getTICSEChartData',
      params,
    )(() => financialDataProxy.getTICSEChartData(params))
  }

  getInsurancePremiumBreakdownChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getInsurancePremiumBreakdownChartData',
      params,
    )(() => financialDataProxy.getInsurancePremiumBreakdownChartData(params))
  }

  getFinancialIncomeChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getFinancialIncomeChartData',
      params,
    )(() => financialDataProxy.getFinancialIncomeChartData(params))
  }

  getPriceEarningRatioDividendYieldChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getPriceEarningRatioDividendYieldChartData',
      params,
    )(() =>
      financialDataProxy.getPriceEarningRatioDividendYieldChartData(params),
    )
  }

  getEarningsAgainstDividendsChartData(params) {
    return this.applyMemoryCache(
      'FinancialDataService.getEarningsAgainstDividendsChartData',
      params,
    )(() => financialDataProxy.getEarningsAgainstDividendsChartData(params))
  }
}

export default new FinancialDataService()
