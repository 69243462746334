import watchlistDetail from './watchlistDetail'
import watchlistTable from './watchlistTable'

const common = {
  TITLE: 'Danh mục trái phiếu theo dõi',
}

const watchlistBond = {
  watchlistTable,
  watchlistDetail,
  common,
}

export default watchlistBond
