import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { COMPONENT } from '../../../../../../../configs/Layout'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { SelectType } from '../../../common/SelectType'
import { chartYearOptions, quarterTypeOptions } from '../../../constants'
import { getQuarterYearParams } from '../../../helps'
import { companyOptions } from '../constants'
import { ChartComponent } from './ChartComponent'
import { TableComponent } from './TableComponent'
import style from './index.module.css'
import {
  changeCompanyType,
  changeQuarterType,
  changeRetryFlag,
  changeYearType,
  selectCompanyType,
  selectQuarterType,
  selectRetryFlag,
  selectTickerData,
  selectYearType,
} from './store/slice'
import {
  getFVTPLListedShareDecompositionAll,
  getFVTPLListedShareDecompositionDetail,
  getOrganizationPortfolio,
} from './store/thunk'

export const FvtplDecomposition = ({ width, height, dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const tickerData = useSelector(selectTickerData)
  const companyType = useSelector(selectCompanyType)
  const quarterType = useSelector(selectQuarterType)
  const yearType = useSelector(selectYearType)
  const retryFlag = useSelector(selectRetryFlag)

  // Get data
  const getCompanyOptions = useMemo(() => {
    return [
      ...companyOptions,
      ...tickerData.map((item) => ({
        name: item.tickerOrTaxCode,
        value: item.organizationId,
      })),
    ]
  }, [tickerData])

  // Actions
  const onChangeCompany = (val) => {
    dispatch(changeCompanyType(val))
  }

  const onChangeQuarter = (item) => {
    dispatch(changeQuarterType(item.value))
  }

  const onChangeYear = (val) => {
    dispatch(changeYearType(val))
  }

  // Use effect
  useEffect(() => {
    if (
      companyType === companyOptions[0].value &&
      sectorInfo &&
      sectorInfo.icbId &&
      retryFlag
    ) {
      const paramsArr = getQuarterYearParams(5, true).map(
        ({ year, quarter }) => ({
          IcbId: sectorInfo.icbId,
          ExchangeCode: dataType,
          Year: year,
          Quarter: quarter,
        }),
      )
      dispatch(getFVTPLListedShareDecompositionAll(paramsArr))
    }
  }, [sectorInfo, retryFlag])

  useEffect(() => {
    if (retryFlag) return
    if (companyType === companyOptions[0].value) {
      if (sectorInfo.icbId) {
        dispatch(
          getFVTPLListedShareDecompositionAll({
            IcbId: sectorInfo.icbId,
            ExchangeCode: dataType,
            Year: yearType,
            Quarter: quarterType,
          }),
        )
      }
    } else {
      if (companyType) {
        dispatch(
          getFVTPLListedShareDecompositionDetail({
            OrganizationId: companyType,
            Year: yearType,
            Quarter: quarterType,
          }),
        )
      }
    }
  }, [companyType, yearType, quarterType, locale])

  useEffect(() => {
    dispatch(changeRetryFlag(true))
  }, [dataType])

  useEffect(() => {
    if (!retryFlag) {
      dispatch(
        getOrganizationPortfolio({
          ICBId: sectorInfo.icbId,
          ExchangeCode: dataType,
          Year: yearType,
          Quarter: quarterType,
        }),
      )
    }
  }, [retryFlag, yearType, quarterType])

  useEffect(() => {
    if (sectorInfo.icbId) {
      dispatch(changeCompanyType(companyOptions[0].value))
    }
  }, [sectorInfo.icbId])

  return (
    <SizeTracker>
      {(size) => (
        <>
          {/* Filter */}
          <div className="align-center j-b mb-8">
            <div className="d-flex">
              <SelectType
                options={getCompanyOptions}
                activeTab={companyType}
                onChangeTab={onChangeCompany}
                style={{ marginRight: 8 }}
              />
              <SelectType
                options={chartYearOptions}
                activeTab={yearType}
                onChangeTab={onChangeYear}
                style={{ marginRight: 8, width: 86 }}
              />
              <DispatchActionTab
                data={quarterTypeOptions}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={quarterType}
                onChangeTab={onChangeQuarter}
              />
            </div>
            <Span style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}>
              <Translate value="sector.financialAnalysis.common.UNIT_BILLION_VND" />
            </Span>
          </div>

          {/* Content */}
          {size.height && (
            <div
              className="d-flex"
              style={{ height: height - size.height || 0 }}
            >
              <div
                className={style.childPanelContainer}
                style={{
                  width: 'calc(50% - 4px)',
                  marginRight: COMPONENT.MARGIN * 2,
                }}
              >
                <ChartComponent
                  width={width / 2 - 4}
                  height={height - size.height}
                />
              </div>
              <div
                className={style.childPanelContainer}
                style={{
                  width: 'calc(50% - 4px)',
                  marginLeft: COMPONENT.MARGIN * 2,
                }}
              >
                <TableComponent />
              </div>
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
