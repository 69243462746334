const getDecimalLength = (values) => {
  if (values.some((value) => Math.abs(value) < 0.09)) {
    return 3
  }

  return 2
}

export const roundData = (data) => {
  const total = data.reduce((pre, cur) => pre + cur.value, 0)

  const formatData =
    total === 0
      ? []
      : data.map((item) => ({
          ...item,
          value: Math.round((item.value / total) * 10000) / 100,
        }))

  const decimalLength = getDecimalLength(formatData.map((item) => item.value))

  return {
    formatData,
    decimalLength,
  }
}
