import { LINE_CHART_COLORS } from '../../constants'

export const handleLineChartData = (payload) => {
  if (!payload.length) {
    return []
  }

  const dateNotDuplicate = handleDuplicateByKey(payload, [
    'effectiveDateId',
  ]).map((item) => item.effectiveDateId)

  return dateNotDuplicate.map((item) => {
    const dataWithDate = payload.filter(
      (element) => element.effectiveDateId === item,
    )
    return dataWithDate.reduce((previous, current) => {
      return {
        ...previous,
        [current.organizationId]: current.value * 100,
        day: item,
      }
    }, {})
  })
}

export const initLineColor = (defaultChecked) => {
  if (defaultChecked.length === 0) {
    return []
  }
  return LINE_CHART_COLORS.map((color, index) => {
    return {
      color,
      author: defaultChecked[index],
    }
  })
}

export const handleLineChartHasAuthor = (lineChartData, idsWithInfo) => {
  return lineChartData
    .filter((item) => item.author !== undefined)
    .map((item) => {
      return {
        ...item,
        indicatorName: idsWithInfo[item.author],
      }
    })
}

export const removeColor = (lineChartColor, id) => {
  return lineChartColor.map((color) => {
    if (color.author === id) {
      return {
        ...color,
        author: undefined,
      }
    }
    return color
  })
}

export const addColor = (lineChartColor, id) => {
  let count = 0
  return lineChartColor.map((color) => {
    if (count === 0 && color.author === undefined) {
      count++
      return {
        ...color,
        author: id,
      }
    }
    return color
  })
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const handleTooltipTwoColumn = (idChecked) => {
  const MAX_ITEM_OF_COLUMN = 8
  if (idChecked.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: idChecked, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(idChecked.length / 2)
  return {
    leftColumn: idChecked.concat().splice(0, leftColumnLength),
    rightColumn: idChecked.concat().splice(leftColumnLength),
  }
}
