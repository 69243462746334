import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { useLocation, useNavigate } from 'react-router'
import { Span } from '../../../common/html/Span'
import { Panel } from '../../../common/panel'
import PopupAlertSuccess from '../../../common/popup/PopupAlertSuccess'
import { SizeTracker } from '../../../common/sizeTracker'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { formatDuration } from '../../../utils/Datetime'
import { formatVal } from '../../../utils/Value'
import { INDICATOR_GROUPS } from '../../bondScreening/constants'
import { setFilterParameters } from '../../bondScreening/store/slice'
import {
  FILTER_BOND_INFORMATION_TYPES,
  INTEREST_BASIS_OPTIONS,
} from '../constants'
import {
  changeCreateBondStatus,
  selectCreateBondStatus,
  selectDataBondInformation,
  selectFilterBondInformation,
} from '../store/slice'
import {
  downloadPortfolioInvestmentData,
  getPortfolioData,
  getPortfolioInvestmentData,
  getWatchListData,
} from '../store/thunk'
import Filter from './Filter'
import Table from './Table'
import style from './index.module.css'

const BondInformation = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const navigate = useNavigate()
  const { search } = useLocation()

  const { data } = useSelector(selectDataBondInformation)
  const { activeTab, ...restFilter } = useSelector(selectFilterBondInformation)
  const isCreateBond = useSelector(selectCreateBondStatus)

  const [isShowCreate, setIsShowCreate] = useState(false)

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    const params = {
      portfolioInfo: data.map((item) => ({
        ...item,
        ticker: item.id === 'total' ? I18n.t(item.ticker) : item.ticker,
        interestBasisTypeId:
          INTEREST_BASIS_OPTIONS.find(
            (option) => option.value === item?.interestBasisTypeId,
          )?.name ?? '',
        remainDuration: formatDuration(item?.remainDuration),
        ytm: !isNaN(item?.ytm) ? parseFloat(formatVal(item?.ytm / 100, 4)) : '',
      })),
    }

    return downloadPortfolioInvestmentData(params)
  }

  useEffect(() => {
    dispatch(getWatchListData())
    dispatch(getPortfolioData())
  }, [])

  useEffect(() => {
    if (isCreateBond) {
      if (isCreateBond?.bondId) {
        const params = {
          [FILTER_BOND_INFORMATION_TYPES.BOND_ID]: isCreateBond.bondId,
          [FILTER_BOND_INFORMATION_TYPES.IS_USER_BOND]:
            isCreateBond?.isUserBond ?? false,
        }

        dispatch(getPortfolioInvestmentData(params))
      }

      setIsShowCreate(true)
      dispatch(changeCreateBondStatus(null))
    }
  }, [isCreateBond])

  return (
    <Panel
      title="bond.portfolio.common.BOND_INFORMATION"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                <div className={`h-100 ${style.tableCustom}`}>
                  {!data?.length &&
                  !restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID] ? (
                    <div className="h-100 d-flex ali-center justify-content-center">
                      <Span style={{ fontWeight: 340 }}>
                        <Translate value="bond.portfolio.bondInformation.NO_DATA" />
                      </Span>
                    </div>
                  ) : (
                    <Table width={width} />
                  )}
                </div>
                <PopupAlertSuccess
                  modalStyle={{ width: 410 }}
                  message={
                    <div>
                      <div style={{ textAlign: 'center' }}>
                        <Span style={{ fontSize: 14, fontWeight: 500 }}>
                          <Translate value="bond.bondScreening.createBond.MESSAGE_CREATE" />
                        </Span>
                      </div>
                      <Span
                        style={{
                          fontStyle: 'italic',
                          textAlign: 'center',
                          display: 'block',
                          fontSize: 14,
                        }}
                      >
                        <Translate value="bond.portfolio.bondInformation.MESSAGE_CREATE_1" />
                        &nbsp;
                        <a
                          onClick={() => {
                            navigate(`/bond/bond-screening${search}`)
                            dispatch(
                              setFilterParameters({
                                key: 'indicatorGroup',
                                value: INDICATOR_GROUPS.BOND_CREATING,
                              }),
                            )
                          }}
                          className={`${style.link} cursor-pointer`}
                        >
                          <Translate value="constants.route.bond.bondScreening.LABEL" />
                        </a>
                        &nbsp;
                        <Translate value="bond.portfolio.bondInformation.MESSAGE_CREATE_2" />
                      </Span>
                    </div>
                  }
                  isShow={isShowCreate}
                  handleClose={() => setIsShowCreate(false)}
                />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default BondInformation
