import { useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal } from '../../../../../utils/Value'
import style from './index.module.css'
import { selectData } from './store/slice'

const BodyTopRight = ({ widthTopRightLabel, widthTopRight }) => {
  const data = useSelector(selectData)

  return (
    <div className={style.column}>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopRightLabel }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={'bond.bondSecurity.tradingInformation.topBody.TotalVolume'}
            />
          </Span>
        </div>
        <div style={{ width: widthTopRight, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.tradingInformation?.totalVolume, 0)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopRightLabel }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.topBody.TotalForeignBuyVolume'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopRight, textAlign: 'end' }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.tradingInformation?.foreignSellVolume, 0)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopRightLabel }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.topBody.TotalForeignSellVolume'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopRight }}>
          <Span style={{ fontSize: 12, fontWeight: 340, textAlign: 'end' }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.tradingInformation?.foreignBuyVolume, 0)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopRightLabel }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.topBody.Avg10daystradingvolume'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopRight }}>
          <Span style={{ fontSize: 12, fontWeight: 340, textAlign: 'end' }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.tradingInformation?.averageVolume10Days, 0)}
            />
          </Span>
        </div>
      </div>
      <div className="d-flex ali-center">
        <div style={{ width: widthTopRightLabel }}>
          <Span style={{ fontSize: 12, fontWeight: 340 }}>
            <TextEllipsis
              text={
                'bond.bondSecurity.tradingInformation.topBody.Avgmonthlytradingvolume'
              }
            />
          </Span>
        </div>
        <div style={{ width: widthTopRight }}>
          <Span style={{ fontSize: 12, fontWeight: 340, textAlign: 'end' }}>
            <TextEllipsis
              isI18n={false}
              text={formatVal(data?.tradingInformation?.averageVolume1Month, 0)}
            />
          </Span>
        </div>
      </div>
    </div>
  )
}

export default BodyTopRight
