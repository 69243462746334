export const chartFillColor = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#1f669a',
  '#297ebd',
  '#f1ffe7',
]

export const chartPieKeys = [
  'nB38_A',
  'nB39_A',
  'nB40_A',
  'nB45_A',
  'nB46_A',
  'nB55_A',
  'nB58_A',
  'nB61_A',
  'nB62_A',
  'nB63_A',
]

export const chartOtherPieKey = 'nB37_A'

export const chartMappingDisplayName = {
  nB38_A: 'sector.financialAnalysis.bank.assetQuality.TRADING',
  nB39_A: 'sector.financialAnalysis.bank.assetQuality.AGRICULTURE_FORESTRY',
  nB40_A: 'sector.financialAnalysis.bank.assetQuality.MANUFACTURING',
  nB45_A: 'sector.financialAnalysis.bank.assetQuality.CONSTRUCTION',
  nB46_A: 'sector.financialAnalysis.bank.assetQuality.PERSONAL_PUBLIC_SERVICE',
  nB55_A: 'sector.financialAnalysis.bank.assetQuality.WAREHOUSING',
  nB58_A: 'sector.financialAnalysis.bank.assetQuality.TRAINING_EDUCATION',
  nB61_A: 'sector.financialAnalysis.bank.assetQuality.ESTATE_AGENT',
  nB62_A: 'sector.financialAnalysis.bank.assetQuality.HOTEL_RESTAURANT',
  nB63_A: 'sector.financialAnalysis.bank.assetQuality.FINANCIAL_SERVICE',
  nB37_A: 'sector.financialAnalysis.bank.assetQuality.OTHER',
}

export const chartFormat = {
  nB38_A: 10 ** 9,
  nB39_A: 10 ** 9,
  nB40_A: 10 ** 9,
  nB45_A: 10 ** 9,
  nB46_A: 10 ** 9,
  nB55_A: 10 ** 9,
  nB58_A: 10 ** 9,
  nB61_A: 10 ** 9,
  nB62_A: 10 ** 9,
  nB63_A: 10 ** 9,
  nB37_A: 10 ** 9,
}
