import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table } from '../../../common/table'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { TabContainer } from '../common/TabContainer'
import TableHeader from '../common/TableHeader'
import PositionCell from './PositionCell'
import TableCell from './TableCell'
import { directorAttr, tableHeaderColAttr } from './constants'
import {
  changeIds,
  selectDirectorIds,
  selectDirectorValue,
  selectHolderById,
  selectIsLoading,
  sort,
} from './store/slice'
import { getManagementAndDirectors } from './store/thunk'

const Director = () => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const directorIds = useSelector(selectDirectorIds)
  const holdersData = useSelector(selectHolderById)
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectIsLoading)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getManagementAndDirectors({ OrganizationId: basicInfo.organizationId }),
      )
    }
  }, [basicInfo.organizationId, locale])

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  return (
    <TabContainer>
      <Table
        ids={directorIds}
        columnDraggable={false}
        getDataFromRedux={selectDirectorValue}
        stickyFirstColumn={false}
        isLargeHead={true}
        hasFooter={false}
        resizable={false}
        changeIds={changeIds}
        rowDraggable={false}
        hasTooltip={false}
        isLoading={isLoading}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(tableHeaderColAttr, stateSort, sortColumn)
        }
        sort={sort}
        schema={Object.keys(directorAttr).map((key, index) => {
          const colId = key
          const title = directorAttr[key]
          const result = {
            colId,
            title,
          }

          if (index === 0) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                width: '50%',
              },
              tdStyle: {
                textAlign: 'left',
                position: 'relative',
                whiteSpace: 'nowrap',
              },
              render: (val, rowId) => {
                const rowData = holdersData[rowId]
                let additionalData = null
                if (rowData) {
                  additionalData = {
                    organizationId: rowData.organizationId,
                    ownerOrganizationId: rowData.ownerOrganizationId,
                    ownerPersonId: rowData.ownerPersonId,
                  }
                }

                return (
                  <TableCell
                    val={val}
                    colId={index}
                    additionalData={additionalData}
                  />
                )
              },
            }
          }

          if (index === 1) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                verticalAlign: 'top',
              },
              tdStyle: {
                textAlign: 'left',
                minWidth: 200,
              },
              render: (val, rowId) => {
                return <PositionCell val={val} rowId={rowId} />
              },
            }
          }

          if ([4, 5].includes(index)) {
            return {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'left',
                verticalAlign: 'top',
              },
              tdStyle: {
                textAlign: 'right',
                position: 'relative',
              },
              render: (val) => {
                return <TableCell val={val} colId={index} />
              },
            }
          }

          if (index === 6) {
            return {
              ...result,
              tdStyle: {
                textAlign: 'right',
              },
              render: (val, rowId) => {
                return <TableCell val={val} colId={index} rowId={rowId} />
              },
            }
          }

          return {
            ...result,
            thStyle: {
              textAlign: 'right',
              verticalAlign: 'top',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return <TableCell val={val} colId={index} />
            },
          }
        })}
      />
      <div id="footer-table-deal" />
    </TabContainer>
  )
}

export default Director
