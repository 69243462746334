import { NoData } from '../../noData'
import PieChartWithFooter from '../pieChart/pieChartWithFooter'
import { ChartDefault } from './chartType/ChartDefault'
import { ChartFooterScroll } from './chartType/ChartFooterScroll'
import { CONTAINER_CHART_TYPES, DEFAULT_COLORS } from './constants'
import { BasicChartProps } from './props'
import { withHandleApi } from './withHandleApi'

const BasicChart = (props) => {
  const {
    id,
    width,
    height,
    data,
    colors,
    chartProps,
    footerProps,
    tooltipProps,
  } = props

  const displayColors = colors || DEFAULT_COLORS

  // Render
  const renderComposedChart = () => {
    if (footerProps.isFooterScroll) {
      return <ChartFooterScroll {...props} colors={displayColors} />
    }
    return <ChartDefault {...props} colors={displayColors} />
  }

  const renderPieChart = () => {
    const { themeDownloadPDF, isScroll, ..._chartProps } = chartProps
    const { isUseContainerFooter, isFooterScroll, ..._footerProps } =
      footerProps

    return (
      <PieChartWithFooter
        data={data}
        height={height}
        width={width}
        themeDownloadPDF={themeDownloadPDF}
        isScroll={isFooterScroll}
        isUseContainerFooter={isUseContainerFooter}
        footerRight={footerProps?.isOneColumn}
        footerProps={_footerProps}
        pieChartProps={_chartProps}
        renderTooltip={tooltipProps?.render}
      />
    )
  }

  const render = () => {
    switch (chartProps.chartType) {
      case CONTAINER_CHART_TYPES.COMPOSED:
        return renderComposedChart()
      case CONTAINER_CHART_TYPES.PIE:
        return renderPieChart()
      default:
        return null
    }
  }

  return (
    <div id={id} style={{ height }}>
      {!data.length ? <NoData /> : render()}
    </div>
  )
}

export default withHandleApi(BasicChart)

BasicChart.propTypes = BasicChartProps

BasicChart.defaultProps = {
  height: 0,
  loading: false,
  data: [],
  schema: [],
  chartProps: {},
  footerProps: {},
}
