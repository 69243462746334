import { TYPE } from '../../../../common/chart/footer/Item'

export const areaKeys = ['buyUpVolume', 'sellDownVolume']

export const lineKeys = ['matchPrice']

export const keyXAxis = 'tradingDate'

export const fillColor = {
  buyUpVolume: '#31e984',
  sellDownVolume: '#fd3e4d',
  matchPrice: '#ffd73e',
}

export const timeSaleDateFilter = {
  ONE_DAY: '1D',
  ONE_WEEK: '1W',
  TWO_WEEKS: '2W',
  ONE_MONTH: '1M',
}

export const timeSaleDateFormatAPI = {
  '1D': 'OneDay',
  '1W': 'OneWeek',
  '2W': 'TwoWeeks',
  '1M': 'OneMonth',
}

export const timeSaleChartType = {
  AREA_LINE: 'areaLine',
  HORIZONTAL_STACK_BAR: 'horizontalStackBar',
}

export const timeSaleChartTypeName = {
  areaLine: 'BU/ SD Area Chart',
  horizontalStackBar: 'BU/ SD Bar Chart',
}

export const timeSaleAreaFormatValue = {
  matchPrice: 10 ** 3,
  sellDownVolume: 10 ** 3,
  buyUpVolume: 10 ** 3,
}

export const timeSaleBarFormatValue = {
  priceFrom: 10 ** 3,
  priceTo: 10 ** 3,
  buyUpVolume: 10 ** 3,
  sellDownVolume: 10 ** 3,
  unidentifiedVolume: 10 ** 3,
  closePrice: 10 ** 3,
}

export const listIconAreaChart = (ticker) => [
  {
    text: 'market.equityTrading.deepTransaction.timeSale.BU_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#31e984',
    },
  },
  {
    text: 'market.equityTrading.deepTransaction.timeSale.SD_VOLUME',
    type: TYPE.SQUARE,
    before: {
      bgColor: '#fe5d6a',
    },
  },
  {
    text: ticker,
    type: TYPE.LINE,
    before: {
      bgColor: '#ffd73e',
    },
  },
]
