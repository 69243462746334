import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import { FUND_TYPE_OPTIONS } from '../../constants'

export const FundType = ({ value, onChange }) => {
  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={150}
        isOptionAll
        isI18n
        textEmpty="fund.fundCenter.ALL_TYPE"
        textAll="fund.fundCenter.ALL_TYPE"
        options={FUND_TYPE_OPTIONS}
        onChange={(value) => onChange({ type: value })}
      />
    </div>
  )
}
