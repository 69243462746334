import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../common/loading'
import { NoData } from '../../common/noData'
import { SizeTracker } from '../../common/sizeTracker'
import { TableFooter } from '../../common/table/TableFooter'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import { CRITERIAS } from './constants'
import {
  selectCriteria,
  selectData,
  selectExchange,
  selectIsLoading,
  selectSizeBy,
} from './store/slice'
import { getHeatMapChart, refreshHeatMapChart } from './store/thunk'
import { TreeMap } from './treemap'

const REFRESH_DATA_TIME = 30000 // 30 seconds

export const TreeMapPanel = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const exchange = useSelector(selectExchange)
  const sizeBy = useSelector(selectSizeBy)
  const criteria = useSelector(selectCriteria)
  const isLoading = useSelector(selectIsLoading)
  const data = useSelector(selectData)
  const ref = useRef({})

  useEffect(() => {
    dispatch(
      getHeatMapChart({
        GroupId: exchange,
        SizeBy: sizeBy,
        Criterial: criteria,
      }),
    )
  }, [locale, exchange, sizeBy, criteria])

  useEffect(() => {
    if (ref.current && ref.current.interval) {
      clearInterval(ref.current.interval)
    }
    if (criteria === CRITERIAS['1_DAY_PERFORMANCE']) {
      ref.current.interval = setInterval(() => {
        dispatch(
          refreshHeatMapChart({
            GroupId: exchange,
            SizeBy: sizeBy,
            Criterial: criteria,
          }),
        )
      }, REFRESH_DATA_TIME)
    }

    return () => {
      clearInterval(ref.current.interval)
    }
  }, [exchange, sizeBy, criteria])

  if (!isLoading && data.length === 0) {
    return <NoData />
  }

  if (isLoading && data.length === 0) {
    return <Loading />
  }

  return (
    <>
      <SizeTracker>
        {(size) => {
          return (
            <>
              {size.height && (
                <TreeMap
                  data={data}
                  width={formatNumber(width) - PANEL_PADDING}
                  height={
                    formatNumber(height) - HEADER_PANEL_HEIGHT - size.height
                  }
                />
              )}
              <TableFooter />
            </>
          )
        }}
      </SizeTracker>
    </>
  )
}
