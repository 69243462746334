import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import DropdownTooltip from '../../../../../common/textEllipsis/DropdownTooltip'
import { Z_INDEX } from '../../../../../constants/Common'
import style from '../index.module.css'
import { selectListItems } from './store/slice'

export const TdSelect = ({ val, rowId, data }) => {
  const GAP_ITEM = 8

  const containerRef = useRef()

  const listItems = useSelector(selectListItems)

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listItems?.some((item) => item.id === rowId)
  }

  const dataTableItem = data?.find((item) => item.id === rowId)

  const handleDisableCheckbox = () => {}
  const handleOpenTooltip = () => setIsOpenTooltip(true)
  const handleCloseTooltip = () => setIsOpenTooltip(false)

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <div>
        <input
          type="checkbox"
          className={`checkbox ${style.checkbox}`}
          onChange={handleChangeListCategory}
          checked={handleChecked()}
          disabled={handleDisableCheckbox()}
        />
      </div>
      <div
        onMouseOver={handleOpenTooltip}
        onMouseOut={handleCloseTooltip}
        ref={containerRef}
        className={dataTableItem?.isCollapsible ? style.pointer : ''}
      >
        <TextEllipsis isI18n={false} text={val} />
      </div>
      {isOpenTooltip && dataTableItem && (
        <DropdownTooltip
          containerRef={containerRef}
          isOpenTooltip={isOpenTooltip}
          zIndexTooltip={Z_INDEX.MODAL + 1}
          isI18n={false}
          text={
            dataTableItem?.fullName.length
              ? dataTableItem?.fullName
              : dataTableItem?.name
          }
        />
      )}
    </div>
  )
}
