import PropTypes from 'prop-types'
import { useState } from 'react'
import { HEIGHT_XAXIS } from '../constants'
import ZoomX from './ZoomX'
import ZoomXNumber from './ZoomXNumber'
import ZoomY from './ZoomY'

const widthHeightResizeBar = 7
const resizeBarMargin = 8

const ZoomChart = ({
  children,
  width,
  height,
  xAxisType,
  xMinMax,
  xTickNum,
  xData,
  formatZoomXData,
  setZoomXData,
  leftY,
  rightY,
  disabled,
  heightXAxis,
  zoomLeftYBarPosition,
  zoomRightYBarPosition,
  leftYWidth,
  rightYWidth,
  marginZoomX,
  marginZoomY,
  listTitleTooltipZoom,
  yTickNum,
  keyXAxis,
}) => {
  const [extend, setExtend] = useState({
    leftY,
    rightY,
    xMinMax,
  })

  const isDisplayZoomX = !!xData
  const isDisplayZoomLeftY = !!leftY
  const isDisplayZoomRightY = !!rightY

  return (
    <div className="h-100 w-100">
      {isDisplayZoomX && xAxisType === 'number' && (
        <ZoomXNumber
          width={width}
          margin={marginZoomX}
          disabled={disabled}
          xMinMax={xMinMax}
          xTickNum={xTickNum}
          extend={extend}
          setExtend={setExtend}
          leftYWidth={leftYWidth}
          rightYWidth={rightYWidth}
        />
      )}
      {isDisplayZoomX && xAxisType === 'category' && (
        <ZoomX
          width={width}
          margin={marginZoomX}
          disabled={disabled}
          xData={xData}
          setData={setZoomXData}
          leftYWidth={leftYWidth}
          rightYWidth={rightYWidth}
          keyXAxis={keyXAxis}
        />
      )}
      <div
        style={{
          height: `calc(100% - ${
            isDisplayZoomX ? widthHeightResizeBar + resizeBarMargin : 0
          }px)`,
        }}
        className="d-flex"
      >
        {isDisplayZoomLeftY && (
          <ZoomY
            margin={marginZoomY}
            disabled={disabled}
            y={leftY}
            extend={extend}
            setExtend={setExtend}
            isLeft={true}
            height={height}
            heightXAxis={heightXAxis}
            position={zoomLeftYBarPosition}
            listTitleTooltipZoom={listTitleTooltipZoom}
            yTickNum={yTickNum}
          />
        )}
        <div style={{ order: 2 }}>
          {children({
            widthChart:
              isDisplayZoomLeftY && isDisplayZoomRightY
                ? width - 2 * widthHeightResizeBar
                : !isDisplayZoomLeftY && !isDisplayZoomRightY
                ? width
                : width - widthHeightResizeBar,
            heightChart: isDisplayZoomX
              ? height - widthHeightResizeBar - resizeBarMargin
              : height,
            data: formatZoomXData,
            leftY: extend.leftY,
            rightY: extend.rightY,
            xMinMax: extend.xMinMax,
          })}
        </div>
        {isDisplayZoomRightY && (
          <ZoomY
            margin={marginZoomY}
            disabled={disabled}
            y={rightY}
            extend={extend}
            setExtend={setExtend}
            isLeft={false}
            height={height}
            heightXAxis={heightXAxis}
            position={zoomRightYBarPosition}
            listTitleTooltipZoom={listTitleTooltipZoom}
            yTickNum={yTickNum}
          />
        )}
      </div>
    </div>
  )
}

ZoomChart.propTypes = {
  children: PropTypes.func.isRequired,
  xAxisType: PropTypes.string.isRequired,
  xMinMax: PropTypes.array,
  xTickNum: PropTypes.number,
  xData: PropTypes.array,
  leftY: PropTypes.array,
  rightY: PropTypes.array,
  disabled: PropTypes.bool,
  zoomLeftYBarPosition: PropTypes.string,
  zoomRightYBarPosition: PropTypes.string,
  leftYWidth: PropTypes.number,
  rightYWidth: PropTypes.number,
  marginZoomX: PropTypes.object,
  marginZoomY: PropTypes.object,
  listTitleTooltipZoom: PropTypes.array,
  yTickNum: PropTypes.number,
}

ZoomChart.defaultProps = {
  disabled: false,
  heightXAxis: HEIGHT_XAXIS,
  zoomLeftYBarPosition: 'left',
  zoomRightYBarPosition: 'right',
  leftYWidth: 0,
  rightYWidth: 0,
  listTitleTooltipZoom: [],
  yTickNum: 5,
  xTickNum: 5,
}

export default ZoomChart
