import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import style from '../../index.module.css'
import {
  changeCheckedItemMoreCondition,
  selectAddMoreConditionIds,
  selectItemAddMoreCondition,
  selectItemConditionByIds,
} from '../../store/slice'

export const idScrollTable = 'scroll-table-popup-add-more'

const ItemCondition = ({ indicatorId, index }) => {
  const dispatch = useDispatch()

  const itemCondition = useSelector(selectItemAddMoreCondition(indicatorId))
  const indicator = useSelector(selectItemConditionByIds(indicatorId))

  const handleChangeChecked = (e) =>
    dispatch(
      changeCheckedItemMoreCondition({
        id: itemCondition.indicatorId,
        value: e.target.checked,
      }),
    )

  return (
    <tr>
      <td>{index + 1}</td>
      <td>{indicator?.indicatorName}</td>
      <td>
        <div className="d-flex justify-content-center">
          <p>
            <input
              type="checkbox"
              className="checkbox"
              checked={itemCondition?.checked}
              onChange={handleChangeChecked}
            />
          </p>
        </div>
      </td>
    </tr>
  )
}

ItemCondition.propTypes = {
  indicatorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  index: PropTypes.number.isRequired,
}

const TableCondition = () => {
  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)

  return (
    <div className="h-100" id={idScrollTable}>
      <ScrollComponent>
        <table className={`w-100 ${style.tableAddMoreCondition}`}>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {addMoreConditionIds.map((indicatorId, index) => (
              <ItemCondition
                key={indicatorId}
                indicatorId={indicatorId}
                index={index}
              />
            ))}
          </tbody>
        </table>
      </ScrollComponent>
    </div>
  )
}

export default TableCondition
