import moment from 'moment'
import { TYPE } from '../../../../common/chart/footer/Item'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatMonthYear } from '../panelOpenMarket/helper'
import { STACK_COLORS } from './constants'

export const getTooltipContentData = (payload, title) => {
  const keys = Object.keys(title)

  return keys
    .map((key) => {
      return {
        label: title[key],
        value: payload[key],
      }
    })
    .reverse()
}

export const renderFooter = (stackKey, lineKey, keyWithTitle) => {
  const stackFooter = stackKey.map((key, index) => {
    return {
      text: keyWithTitle[key],
      type: TYPE.SQUARE,
      before: {
        bgColor: STACK_COLORS[index],
      },
      isI18n: false,
    }
  })

  return [
    ...stackFooter,
    {
      text: keyWithTitle[lineKey],
      type: TYPE.CIRCLE_MARKER_LINE,
      after: {
        bgColor: '#f7b80c',
      },
      isI18n: false,
    },
  ]
}

export const handleChartData = (payload) => {
  if (payload.length === 0) {
    return []
  }

  const dateNotDuplicate = handleDuplicateByKey(payload, ['publicDateId'])

  return dateNotDuplicate.map((item) => {
    const dataWithPublicDateId = payload.filter(
      (i) => i.publicDateId === item.publicDateId,
    )

    return dataWithPublicDateId.reduce((previous, current) => {
      return {
        ...previous,
        [current.moneyMarketOperationsVNTypeId]:
          current.winningVolume / 1000000000,
        day: item.publicDateId,
      }
    }, {})
  })
}

export const handleKeyWithTitle = (payload) => {
  if (payload.length === 0) {
    return {}
  }

  const idNotDuplicate = handleDuplicateByKey(payload, [
    'moneyMarketOperationsVNTypeId',
  ])
  return idNotDuplicate.reduce((previous, current) => {
    return {
      ...previous,
      [current.moneyMarketOperationsVNTypeId]:
        current.moneyMarketOperationsVNTypeName,
    }
  }, {})
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const handleTime = (chartData, timeZone, locale) => {
  return chartData
    .map((item) => ({
      ...item,
      day: formatDateTime(item.day, FORMAT.DATE, locale, timeZone),
      monthYear: formatMonthYear(new Date(item.day)),
    }))
    .sort((a, b) =>
      moment(a.day, FORMAT.DATE[locale]).diff(
        moment(b.day, FORMAT.DATE[locale]),
      ),
    )
}
