import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class InterconnectionMapProxy extends ProxyBase {
  getDataProxy(params) {
    return this.get('GetOutStandingBond', params)
  }
  getInterConnectionMapProxy(params) {
    return this.get('GetInterConnectionMap', params)
  }
}

export default new InterconnectionMapProxy(
  ServiceProxyConfig.Bond.InterConnectionMap.ServiceUrl,
)
