import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valDivBillion,
  valDivMillion,
  valToPercent,
} from '../../../../utils/Value'
import {
  AUDIT_STATUS,
  CURRENCY,
  DROPDOWN_COMPANY_OVERVIEW,
  indicatorPricePerFormance,
  REPORT_TYPE,
  TYPE_TIME_RANGE,
  VALUE_PERIOD,
} from '../constants'
import {
  getBalanceStatement,
  getCompareWithSector,
  getFinancialData,
  getFinancialRatio,
  getForeignNetTrading,
  getIncomeStatement,
  getKeyFinancialData,
  getMajorShareHolder,
  getMapping_GetAll,
  getMaxDateStatement,
  getOverview,
  getOwnershipAnalysis,
  getOwnershipAnalysisChart,
  getPeerAnalysis,
  getPriceData,
  getPricePerformance,
  getPricePerformanceChart,
  getProfile,
  getReportDate,
  getRevenueBreakdown,
  getStockData,
  getStockData1Yrs,
  getStockPerformanceAndIndex,
  getSummary,
  getTemplate,
  getTop5MajorShareHolder,
  getValuationHistory,
} from './thunk'

const initialState = {
  isLoading: false,
  showPreview: false,
  isReloadData: false,
  reportDate: undefined,
  dropdownCompanyOverviewValue: DROPDOWN_COMPANY_OVERVIEW.COMPARE_WITH_SECTOR,
  template: [],
  templateActive: {
    templateId: null,
    templateName: '',
    templateParameters: '',
  },
  setting: {
    //Company Overview
    companyProfile: true,
    companyPosition: true,
    businessStrategies: true,
    dropdownCompanyOverview: true,
    stockData: true,
    oneYear_PriceAndVolume: true,
    financialRatios: true,
    valuationHistory: true,
    //Peer Analysis And Financial Data
    peerAnalysis: true,
    financialData: true,
    yourCommentaryOnEarnings: true,
    ownershipAnalysis: true,
    //Market Data
    priceData: true,
    stockPerformanceVsIndex: true,
    pricePerformance: true,
    foreignNetBuyingSelling: true,
    //Financial Statements
    balanceSheet: true,
    inComeStatement: true,
  },
  data: {
    userName: '',
    mapping_GetAll: [],
    summary: {},
    companyProfile: '',
    companyPosition: '',
    businessStrategies: '',
    dropdownCompareWithSector: [],
    dropdownRevenueBreakdown: [],
    stockData: {},
    oneYear_PriceAndVolume: [],
    financialRatios: {
      ids: [],
      dataById: {},
      groupColumns: [],
    },
    valuationHistory: true,
    //Peer Analysis And Financial Data
    peerAnalysis: [],
    pricePerformanceChart: [],
    pricePerformanceTable: {
      ids: [],
      dataById: {},
      groupColumns: [],
      titleChart: '',
    },
    top5MajorShareHolder: {
      ids: [],
      dataById: {},
    },
    financialData: {
      ids: [],
      dataById: {},
      groupColumns: [],
    },
    ownershipAnalysis: [],
    majorShareHolder: {},
    ownershipAnalysisChart: [],
    //Market Data
    priceData: {
      ids: [],
      dataById: {},
    },
    pricePerformance: {
      ids: [],
      dataById: {},
      groupColumns: [],
    },
    stockPerformanceVsIndex: [],
    foreignNetBuyingSelling: [],
    //Financial Statements
    balanceSheet: {
      ids: [],
      dataById: {},
      groupColumns: [],
    },
    inComeStatement: {
      ids: [],
      dataById: {},
      groupColumns: [],
    },
  },
  yourCommentaryOnEarnings: '',
  popupTimeRangeStockPerformanceVsIndex: {
    type: TYPE_TIME_RANGE.PERIOD,
    period: VALUE_PERIOD.ONE_YEAR,
    rangeForm: new Date(),
    rangeTo: new Date(),
  },
  popupTimeRangeForeignNetBuyingSelling: {
    type: TYPE_TIME_RANGE.PERIOD,
    period: VALUE_PERIOD.ONE_WEEK,
    rangeForm: new Date(),
    rangeTo: new Date(),
  },
  popupPeerAnalysis: [],
  popupFinancialStatement: {
    reportType: REPORT_TYPE.CONSOLIDATED,
    auditStatus: AUDIT_STATUS.BOTH_AUDITED_AND_UNAUDITED,
    currency: CURRENCY.BILLION_VND,
    year1: null,
    year2: null,
    year3: null,
    q_quarter1: null,
    q_year1: null,
    q_quarter2: null,
    q_year2: null,
    q_quarter3: null,
    q_year3: null,
    q_quarter4: null,
    q_year4: null,
    q_quarter5: null,
    q_year5: null,
  },
  maxDateStatement: { realQuarter: null, realYear: null },
  organizationIdTemplate: null,
}

export const slice = createSlice({
  name: 'corporate/companySnapshot/filter',
  initialState,
  reducers: {
    changeLoadingPDF(state, action) {
      state.isLoading = action.payload
    },
    changeTimeRangeStockPerformanceVsIndex(state, action) {
      state.popupTimeRangeStockPerformanceVsIndex = action.payload
    },
    changeTimeRangeForeignNetBuyingSelling(state, action) {
      state.popupTimeRangeForeignNetBuyingSelling = action.payload
    },
    changeSetting(state, action) {
      state.setting = action.payload
    },
    resetSetting(state) {
      state.templateActive = initialState.templateActive
      state.setting = initialState.setting
      state.showPreview = false
      state.yourCommentaryOnEarnings = ''
      state.popupTimeRangeStockPerformanceVsIndex =
        initialState.popupTimeRangeStockPerformanceVsIndex
      state.popupTimeRangeForeignNetBuyingSelling =
        initialState.popupTimeRangeForeignNetBuyingSelling
      state.popupPeerAnalysis = []
      state.organizationIdTemplate = null
    },
    changeShowPreview(state, action) {
      state.showPreview = action.payload
    },
    changeDropdownCompanyOverview(state, action) {
      state.dropdownCompanyOverviewValue = action.payload
    },
    changePopupPeerAnalysis(state, action) {
      state.popupPeerAnalysis = action.payload
    },
    changValuePopupFinancialStatement(state, action) {
      state.popupFinancialStatement = action.payload
    },
    setInComeStatementById(state, action) {
      state.data.inComeStatement.dataById = action.payload
    },
    setBalanceSheetById(state, action) {
      state.data.balanceSheet.dataById = action.payload
    },
    setPricePerformanceById(state, action) {
      state.data.pricePerformance.dataById = action.payload
    },
    setFinancialDataById(state, action) {
      state.data.financialData.dataById = action.payload
    },
    setFinancialRatiosById(state, action) {
      state.data.financialRatios.dataById = action.payload
    },
    setKeyFinancialById(state, action) {
      state.data.pricePerformanceTable.dataById = action.payload
    },
    changeYourCommentaryOnEarnings(state, action) {
      state.yourCommentaryOnEarnings = action.payload
    },
    applyTemplate(state, action) {
      state.templateActive = action.payload
      const {
        setting,
        popupTimeRangeStockPerformanceVsIndex,
        popupTimeRangeForeignNetBuyingSelling,
        popupPeerAnalysis,
        dropdownCompanyOverviewValue,
        yourCommentaryOnEarnings,
        organizationIdTemplate,
      } = JSON.parse(action.payload.templateParameters)
      state.setting = setting
      state.popupTimeRangeStockPerformanceVsIndex =
        popupTimeRangeStockPerformanceVsIndex
      state.popupTimeRangeForeignNetBuyingSelling =
        popupTimeRangeForeignNetBuyingSelling
      state.popupPeerAnalysis = popupPeerAnalysis
      state.dropdownCompanyOverviewValue = dropdownCompanyOverviewValue
      state.yourCommentaryOnEarnings = yourCommentaryOnEarnings
      state.organizationIdTemplate = organizationIdTemplate
    },
    changeBalanceSheetIds(state, action) {
      state.data.balanceSheet.ids = action.payload
    },
    changeIncomeStatementIds(state, action) {
      state.data.inComeStatement.ids = action.payload
    },
    changeValueReloadData(state, action) {
      state.isReloadData = action.payload
    },
    changeIndicatorFinancialDataCorp(state, action) {
      state.data.financialData.ids = action.payload
    },
    changeIndicatorFinancialRatiosCorp(state, action) {
      state.data.financialRatios.ids = action.payload
    },
    changeIndicatorKeyFinancial(state, action) {
      state.data.pricePerformanceTable.ids = action.payload
    },
    changeReportDate(state, action) {
      state.reportDate = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReportDate.fulfilled, (state, action) => {
      state.reportDate = action.payload
    })

    builder.addCase(getOverview.fulfilled, (state, action) => {
      const { businessStrategy, position, profile } = action.payload || {}
      state.data.companyProfile = profile || ''
      state.data.companyPosition = position || ''
      state.data.businessStrategies = businessStrategy || ''
    })

    builder.addCase(getStockData.fulfilled, (state, action) => {
      state.data.stockData = action.payload || {}
    })

    builder.addCase(getSummary.fulfilled, (state, action) => {
      state.data.summary = action.payload || {}
    })

    builder.addCase(getCompareWithSector.fulfilled, (state, action) => {
      state.data.dropdownCompareWithSector = action.payload
        ? action.payload.map((item) => ({
            ...item,
            percentPriceChange: item.percentPriceChange * 100,
            percentSectorChange: item.percentSectorChange * 100,
          }))
        : []
    })

    builder.addCase(getRevenueBreakdown.fulfilled, (state, action) => {
      state.data.dropdownRevenueBreakdown = action.payload || []
    })

    builder.addCase(getValuationHistory.fulfilled, (state, action) => {
      state.data.valuationHistory = action.payload
    })

    builder.addCase(getPricePerformanceChart.fulfilled, (state, action) => {
      state.data.pricePerformanceChart = convertData(
        action.payload || [],
        'performance',
      )
    })

    builder.addCase(getStockData1Yrs.fulfilled, (state, action) => {
      state.data.oneYear_PriceAndVolume = action.payload || []
    })

    builder.addCase(getPeerAnalysis.fulfilled, (state, action) => {
      const data = action.payload || []
      state.popupPeerAnalysis = data.slice(0, 3)
      state.data.peerAnalysis = data
    })

    builder.addCase(getOwnershipAnalysis.fulfilled, (state, action) => {
      state.data.ownershipAnalysis = action.payload || []
    })

    builder.addCase(getTop5MajorShareHolder.fulfilled, (state, action) => {
      state.data.top5MajorShareHolder.ids =
        action.payload?.map((item) => item.ownerPersonId) || []
      state.data.top5MajorShareHolder.dataById = keyBy(
        action.payload || [],
        'ownerPersonId',
      )
    })

    builder.addCase(getIncomeStatement.fulfilled, (state, action) => {
      const data = action.payload || []
      const groupColumns = []
      const dataKeyArr = Object.values(state.data.inComeStatement.dataById)
      data.forEach((i) => {
        groupColumns.push({
          key: getKeySort(i.realYear, i.realQuarter),
          title: getKey(i.realYear, i.realQuarter),
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? i[e.key] : 0
          e[getKeySort(i.realYear, i.realQuarter)] =
            state.popupFinancialStatement?.currency === CURRENCY.BILLION_VND
              ? formatVal(valDivBillion(value))
              : state.popupFinancialStatement?.currency === CURRENCY.MILLION_VND
              ? formatVal(valDivMillion(value))
              : formatVal(value, 0)
        })
        state.data.inComeStatement.groupColumns = data.length
          ? [
              {
                key: 'name',
                title:
                  state.popupFinancialStatement?.currency ===
                  CURRENCY.BILLION_VND
                    ? 'corporate.companySnapshot.INCOME_STATEMENT_BILLION_VND'
                    : state.popupFinancialStatement?.currency ===
                      CURRENCY.MILLION_VND
                    ? 'corporate.companySnapshot.INCOME_STATEMENT_MILLION_VND'
                    : 'corporate.companySnapshot.INCOME_STATEMENT_VND',
              },
              ...groupColumns.sort((a, b) => a.key.localeCompare(b.key)),
            ]
          : []
      })
    })

    builder.addCase(getBalanceStatement.fulfilled, (state, action) => {
      const data = action.payload || []
      const groupColumns = []
      const dataKeyArr = Object.values(state.data.balanceSheet.dataById)
      data.forEach((i) => {
        groupColumns.push({
          key: getKeySort(i.realYear, i.realQuarter),
          title: getKey(i.realYear, i.realQuarter),
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? i[e.key] : 0
          e[getKeySort(i.realYear, i.realQuarter)] =
            state.popupFinancialStatement?.currency === CURRENCY.BILLION_VND
              ? formatVal(valDivBillion(value))
              : state.popupFinancialStatement?.currency === CURRENCY.MILLION_VND
              ? formatVal(valDivMillion(value))
              : formatVal(value, 0)
        })
        state.data.balanceSheet.groupColumns = data.length
          ? [
              {
                key: 'name',
                title:
                  state.popupFinancialStatement?.currency ===
                  CURRENCY.BILLION_VND
                    ? 'corporate.companySnapshot.BALANCE_SHEET1_BILLION_VND'
                    : state.popupFinancialStatement?.currency ===
                      CURRENCY.MILLION_VND
                    ? 'corporate.companySnapshot.BALANCE_SHEET1_MILLION_VND'
                    : 'corporate.companySnapshot.BALANCE_SHEET1_VND',
              },
              ...groupColumns.sort((a, b) => a.key.localeCompare(b.key)),
            ]
          : []
      })
    })

    builder.addCase(getMapping_GetAll.fulfilled, (state, action) => {
      state.data.mapping_GetAll = action.payload || []
    })

    builder.addCase(getFinancialData.fulfilled, (state, action) => {
      const data = action.payload || []
      const groupColumns = []
      const dataKeyArr = Object.values(state.data.financialData.dataById)
      data.forEach((i) => {
        groupColumns.push({
          key: getKeySort(i.year, i.quarter, i.tType),
          title: getKey(i.year, i.quarter, i.tType),
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? i[e.key] : ''
          e[getKeySort(i.year, i.quarter, i.tType)] = e.isFormatBil
            ? formatVal(valDivBillion(value))
            : e.isFormat
            ? formatVal(value, 0)
            : value
        })
      })
      state.data.financialData.groupColumns = data.length
        ? [
            { key: 'name', title: 'corporate.companySnapshot.VNDBN' },
            ...groupColumns.sort((a, b) => a.key.localeCompare(b.key)),
          ]
        : []
    })

    builder.addCase(getPriceData.fulfilled, (state, action) => {
      state.data.priceData.ids =
        action.payload?.map((item) => item.year).sort() || []
      state.data.priceData.dataById = keyBy(action.payload, 'year')
    })

    builder.addCase(getPricePerformance.fulfilled, (state, action) => {
      const data = action.payload
      state.data.pricePerformance.ids = indicatorPricePerFormance.map(
        (item) => item.index,
      )
      const groupColumns = []
      const dataKeyArr = Object.values(state.data.pricePerformance.dataById)
      if (data) {
        groupColumns.push({
          key: data?.organizationId.toString(),
          title: '',
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? data[e.key] : ''
          e[data.organizationId] = e.isFormatPercent
            ? valToPercent(value)
            : e.isFormatInteger
            ? formatVal(value, 0)
            : e.isFormat
            ? formatVal(value)
            : e.isFormatMil
            ? formatVal(valDivMillion(value))
            : `${formatVal(data['min1YearLowPriceAdjusted'], 0)} - ${formatVal(
                data['max1YearHighPriceAdjusted'],
                0,
              )}`
          state.data.pricePerformance.groupColumns = [
            { key: 'name', title: '' },
            ...groupColumns,
          ]
        })
      }
    })

    builder.addCase(getFinancialRatio.fulfilled, (state, action) => {
      const data = action.payload || []
      const groupColumns = []
      const dataKeyArr = Object.values(state.data.financialRatios.dataById)
      data.forEach((i) => {
        groupColumns.push({
          key: i.year.toString(),
          title: i.year,
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? i[e.key] : ''
          e[i.year] = e.isFormat
            ? formatVal(value, 2)
            : e.isFormatPercent
            ? valToPercent(value)
            : e.isFormatInteger
            ? formatVal(value, 0)
            : value
        })
      })
      state.data.financialRatios.groupColumns = data.length
        ? [
            {
              key: 'name',
              title: 'corporate.companySnapshot.FINANCIAL_RATIOS',
            },
            ...groupColumns.sort((a, b) => a.key - b.key),
          ]
        : []
    })

    builder.addCase(getStockPerformanceAndIndex.fulfilled, (state, action) => {
      state.data.stockPerformanceVsIndex = action.payload
        ? action.payload.map((item) => ({
            ...item,
            percentPriceChange: item.percentPriceChange * 100,
            percentIndexChange: item.percentIndexChange * 100,
          }))
        : []
    })
    builder.addCase(getForeignNetTrading.fulfilled, (state, action) => {
      state.data.foreignNetBuyingSelling = action.payload || []
    })

    builder.addCase(getKeyFinancialData.fulfilled, (state, action) => {
      const data = action.payload || []
      const groupColumns = []
      const dataKeyArr = Object.values(
        state.data.pricePerformanceTable.dataById,
      )
      data.forEach((i, index) => {
        groupColumns.push({
          key: 'key' + index,
          title: i.organizationId,
        })
        dataKeyArr.forEach((e) => {
          const value = e.key ? i[e.key] : ''
          e['key' + index] = e.isFormat
            ? formatVal(value, 2)
            : e.isFormatBil
            ? formatVal(valDivBillion(value))
            : e.isFormatPercent
            ? valToPercent(value)
            : value
        })
      })
      state.data.pricePerformanceTable.titleChart = data.length
        ? data[0].quarter === 5
          ? data[0].year
          : ['*TTM Q', data[0].quarter, '/', data[0].year].join('')
        : ''
      state.data.pricePerformanceTable.groupColumns = data.length
        ? [
            {
              key: 'name',
              title: 'corporate.companySnapshot.FINANCIAL_RATIOS',
            },
            ...groupColumns,
          ]
        : []
    })

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.data.userName = action.payload
    })

    builder.addCase(getTemplate.fulfilled, (state, action) => {
      state.template = action.payload || []
    })

    builder.addCase(getMajorShareHolder.fulfilled, (state, action) => {
      state.data.majorShareHolder = action.payload || {}
    })

    builder.addCase(getOwnershipAnalysisChart.fulfilled, (state, action) => {
      state.data.ownershipAnalysisChart = action.payload || []
    })

    builder.addCase(getMaxDateStatement.fulfilled, (state, action) => {
      state.maxDateStatement = action.payload || {
        realQuarter: null,
        realYear: null,
      }
    })
  },
})

export const selectLoadingPDF = (state) => state[slice.name].isLoading
export const selectTimeRangeStockPerformanceVsIndex = (state) =>
  state[slice.name].popupTimeRangeStockPerformanceVsIndex
export const selectTimeRangeForeignNetBuyingSelling = (state) =>
  state[slice.name].popupTimeRangeForeignNetBuyingSelling
export const selectSetting = (state) => state[slice.name].setting
export const selectDataFDF = (state) => state[slice.name].data
export const selectReportDate = (state) => state[slice.name].reportDate
export const selectShowPreview = (state) => state[slice.name].showPreview
export const selectDropdownCompanyOverviewValue = (state) =>
  state[slice.name].dropdownCompanyOverviewValue
export const selectPopupPeerAnalysis = (state) =>
  state[slice.name].popupPeerAnalysis
export const selectTop5MajorShareHolderValue = (id, attr) => (state) =>
  valByKeyWithDot(
    state[slice.name].data.top5MajorShareHolder.dataById[id],
    attr,
  )
export const selectFinancialDataValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.financialData.dataById[id], attr)
export const selectPopupFinancialStatement = (state) =>
  state[slice.name].popupFinancialStatement
export const selectPriceDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].data.priceData.dataById[id], attr)
}
export const selectPricePerformanceValue = (id, attr) => (state) => {
  return valByKeyWithDot(
    state[slice.name].data.pricePerformance.dataById[id],
    attr,
  )
}
export const selectInComeStatementValue = (id, attr) => (state) => {
  return valByKeyWithDot(
    state[slice.name].data.inComeStatement.dataById[id],
    attr,
  )
}
export const selectItemInComeStatementData = (id) => (state) =>
  state[slice.name].data.inComeStatement.dataById[id]
export const selectBalanceSheetValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].data.balanceSheet.dataById[id], attr)
}
export const selectItemBalanceSheetData = (id) => (state) =>
  state[slice.name].data.balanceSheet.dataById[id]
export const selectFinancialRatiosValue = (id, attr) => (state) => {
  return valByKeyWithDot(
    state[slice.name].data.financialRatios.dataById[id],
    attr,
  )
}
export const selectKeyFinancialDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(
    state[slice.name].data.pricePerformanceTable.dataById[id],
    attr,
  )
}
export const selectTemplate = (state) => state[slice.name].template
export const selectTemplateActive = (state) => state[slice.name].templateActive
export const selectStore = (state) => state[slice.name]
export const selectYourCommentaryOnEarnings = (state) =>
  state[slice.name].yourCommentaryOnEarnings
export const selectIsReloadData = (state) => state[slice.name].isReloadData
export const selectMaxDateStatement = (state) =>
  state[slice.name].maxDateStatement
export const selectOrganizationIdTemplate = (state) =>
  state[slice.name].organizationIdTemplate

export const {
  changeLoadingPDF,
  changeTimeRangeForeignNetBuyingSelling,
  changeTimeRangeStockPerformanceVsIndex,
  changeSetting,
  resetSetting,
  changeShowPreview,
  changeDropdownCompanyOverview,
  changePopupPeerAnalysis,
  changValuePopupFinancialStatement,
  setInComeStatementById,
  setPricePerformanceById,
  setBalanceSheetById,
  setFinancialDataById,
  setFinancialRatiosById,
  setKeyFinancialById,
  changeYourCommentaryOnEarnings,
  applyTemplate,
  changeBalanceSheetIds,
  changeIncomeStatementIds,
  changeValueReloadData,
  changeIndicatorFinancialDataCorp,
  changeIndicatorFinancialRatiosCorp,
  changeIndicatorKeyFinancial,
  changeReportDate,
} = slice.actions

register(slice.name, slice.reducer)

const getMinData = (data, keyMin) => {
  const sortData = data
    .map((value) => (value[keyMin] ? value[keyMin] : undefined))
    .sort()
  return sortData[0]
}

const convertData = (data, keyData) => {
  let newArray = [...data]
  const result = []
  let object = {}
  while (newArray.length) {
    const min = getMinData(newArray, 'tradingDateId')
    const arr = []
    newArray.forEach((item) => {
      if (item.tradingDateId === min) {
        arr.push(item)
      }
    })
    newArray = newArray.filter((i) => i.tradingDateId !== min)
    object = arr.reduce(
      (obj, item) =>
        Object.assign(obj, {
          tradingDateId: item.tradingDateId,
          [item.ticker]: item[keyData] * 100,
        }),
      {},
    )
    result.push(object)
  }
  return result
}

const getKey = (year, quarter, tType = '') => {
  if (tType === 'Consensus') {
    return year + 'F*'
  } else if (quarter === 5) {
    return year + 'A'
  } else {
    return `Q${quarter}/${year}`
  }
}

const getKeySort = (year, quarter, tType = '') => {
  if (tType === 'Consensus') {
    return year + 'F*'
  } else if (quarter === 5) {
    return year + '/A'
  } else {
    return `Q${year}/${quarter}`
  }
}
