import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class NewsEventsProxy extends ProxyBase {
  getFiinXEventTypes(params) {
    return this.get('GetFiinXEventTypes', params)
  }

  getEvents(params) {
    return this.post('GetEvents_Paged', params)
  }

  getEventCount(params) {
    return this.get('GetEventCount', params)
  }

  getEventById(params) {
    return this.get('GetEventById', params)
  }

  getFilings(params) {
    return this.get('GetFilings_Paged', params)
  }

  getFilingCategories(params) {
    return this.get('GetFilingCategories', params)
  }

  downloadEvents(params) {
    return this.post('DownloadEvents', params, {}, 'download')
  }
}

export default new NewsEventsProxy(ServiceProxyConfig.Corporate.ServiceUrl)
