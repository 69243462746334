import {
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
  getYearInDataRange,
  getYearKey,
} from '../helper'
import { TYPE_STATISTIC } from '../priceStatistics/constants'

export const convertData = (data, typeStatistic) => {
  //convert months and years to date keys
  const getDataKeyFromRange =
    typeStatistic === TYPE_STATISTIC.YEARLY
      ? getYearInDataRange(data).map((item) => getYearKey(item.year))
      : reOrderByMonthAndYear(
          getMonthAndYearInDataRange(data).map((item) =>
            getMonthAndYearKey(item.month, item.year),
          ),
        )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const dataWithYear = (year) => {
    return data
      .filter((item) => item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        id: item.id ?? 0,
      }))
  }

  const getDataValueFromRange =
    typeStatistic === TYPE_STATISTIC.YEARLY
      ? getYearInDataRange(data).map((item) => [...dataWithYear(item.year)])
      : sortObjByMonthAndYear(getMonthAndYearInDataRange(data)).map((item) => [
          ...dataWithMonthAndYear(item.month, item.year),
        ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}

const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}

const sortObjByMonthAndYear = (data) => {
  const sorted = data?.sort((a, b) => {
    if (a.year !== b.year) return a.year - b.year
    return a.month - b.month
  })

  return sorted
}

export const getItemName = (item) =>
  item.steelProductTypeName
    ? `${item.steelProductTypeName} - ${item.name}`
    : item.name
