import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemSelect from './common/ItemSelect'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import YearFromTo from './common/YearFromTo'
import { deepCopy } from './helper'
import style from './index.module.css'
import { selectValueDetailPopupIndicator } from './store/slice'

const listDividendStage = [
  { value: 'MostRecent', name: <Translate value="common.popup.MOST_RECENT" /> },
  { value: 'All', name: <Translate value="common.popup.ALL" /> },
]

const CashDividend = ({
  handleSubmit,
  handleCancel,
  title,
  minYear,
  maxYear,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const from = useSelector(selectValueDetailPopupIndicator('from'))
  const to = useSelector(selectValueDetailPopupIndicator('to'))

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    detail.sourceDividendYears = range(detail.from, detail.to)
    delete detail.from
    delete detail.to
    handleSubmit({ ...data, detail })
  }

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`${style.cdContent} d-flex`}>
            <div className="d-flex ali-center">
              <Translate value="common.popup.DIVIDEND_YEAR" />
              <div>
                <YearFromTo
                  keyFrom="from"
                  keyTo="to"
                  minYear={minYear}
                  maxYear={maxYear}
                  isSmallInput={true}
                />
              </div>
            </div>
            <div className="d-flex ali-center">
              <Translate value="common.popup.DIVIDEND_STAGE" />
              <div>
                <ItemSelect
                  selectData={listDividendStage}
                  keySelect="dividendStage"
                  defaultValue="MostRecent"
                />
              </div>
            </div>
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
            ratio={to - from + 1}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              onClick={handleCancel}
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              ratio={to - from + 1}
              submit={convertData}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

CashDividend.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(CashDividend)
