import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import { selectMinYear } from './store/slice'
import { getDownloadStateBudgetBalance } from './store/thunk'

const PanelStateBudgetRevenue = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const minYear = useSelector(selectMinYear)

  return (
    <Panel
      title={'economy.fiscal.stateBudget.STATE_BUDGET_REVENUE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() => getDownloadStateBudgetBalance({ Year: minYear })}
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelStateBudgetRevenue
