import moment from 'moment'

export const BOND_TYPES = {
  ALL: ['0'],
  GOVERNMENT_BOND: ['4', '5', '6'],
  CORPORATE_BOND: ['1', '2'],
}

export const BOND_TYPE_RADIOS = [
  {
    name: 'bond.bondScreening.common.ALL',
    value: BOND_TYPES.ALL,
  },
  {
    name: 'bond.bondScreening.filter.BOND_TYPE_1',
    value: BOND_TYPES.GOVERNMENT_BOND,
  },
  {
    name: 'bond.bondScreening.filter.BOND_TYPE_2',
    value: BOND_TYPES.CORPORATE_BOND,
  },
]

export const CREATE_BOND_TYPES = {
  ALL: ['0'],
  GOVERNMENT_BOND: ['4'],
  CORPORATE_BOND: ['1'],
}

export const CREATE_BOND_TYPE_RADIOS = [
  {
    name: 'bond.bondScreening.common.ALL',
    value: CREATE_BOND_TYPES.ALL,
  },
  {
    name: 'bond.bondScreening.filter.BOND_TYPE_1',
    value: CREATE_BOND_TYPES.GOVERNMENT_BOND,
  },
  {
    name: 'bond.bondScreening.filter.BOND_TYPE_2',
    value: CREATE_BOND_TYPES.CORPORATE_BOND,
  },
]

export const FILTER_FIELD_CODES = {
  TICKER: 'Ticker',
  BOND_TICKER: 'BondTicker',
  ISSUE_DATE: 'IssueDateId',
  MATURITY_DATE: 'MaturityDateId',
  DURATION: 'DurationName',
  ISSUE_VALUE: 'IssueValue',
  OUT_VALUE: 'Outsdval',
  REMAIN_DURATION: 'RemainDuration',
  COUPON: 'RBD0009',
  COUPON_FIXED: 'FixedInterestRate',
  TRADING_DATE: 'TradingDateId',
  COLLATERAL: 'Collateral',
  PAYMENT: 'PaymentGuarantee',
  ORGANIZATION: 'OrganizationShortName',
  STATUS: 'ActiveStatusName',
  INDUSTRY: 'IcbName',
  REDEMPTION_DATE: 'RedemptionEndDateId',
  BOND_TYPE: 'BondTypeName',
}

export const FILTER_FIELD_TYPES = {
  DATE: 'DateRange',
  SELECT: 'Dropdownlist',
  MULTI_SELECT: 'Dropdownlist,Checkbox',
  RADIO: 'RadioButton',
  NUMBER: 'TextInputRange',
  INPUT: 'TextInput',
}

export const TABLE_FIELD_CODES = {
  INDUSTRY: 'IcbName',
  EN_INDUSTRY: 'en_IcbName',
  BOND_TYPE: 'BondTypeName',
  EN_BOND_TYPE: 'en_BondTypeName',
  ISSUERS: 'OrganizationShortName',
  EN_ISSUERS: 'en_OrganizationShortName',
  STATUS: 'ActiveStatusName',
}

export const ALL_VALUE = '0'

export const RANGE_TYPES = {
  FROM: 1,
  TO: 2,
}

export const ISSUE_DATE_OPTIONS = [
  {
    name: 'YTD',
    date: [moment().startOf('year'), moment()],
  },
  {
    name: 'QTD',
    date: [moment().startOf('quarter'), moment()],
  },
  {
    name: 'MTD',
    date: [moment().startOf('month'), moment()],
  },
]

export const MATURITY_DATE_OPTIONS = [
  {
    name: 'bond.bondScreening.filter.YTD',
    date: [moment(), moment().endOf('year')],
  },
  {
    name: 'bond.bondScreening.filter.QTD',
    date: [moment(), moment().endOf('quarter')],
  },
  {
    name: 'bond.bondScreening.filter.MTD',
    date: [moment(), moment().endOf('month')],
  },
]

export const BOND_OVERVIEW_MATCH_IDS = [1, 55, 97, 102, 99999]
