export const stateBudget = {
  STATE_BUDGET: 'State Budget',
  INDICATOR: 'Indicator',
  STATE_BUDGET_REVENUE: 'State Budget Revenue & Expenditure',
  REVENUE_STRUCTURE: 'State Revenue & Expenditure structure',
  SOCIAL_INVESTMENT: 'Total social investment capital',
  INVESTMENT_STRUCTURE: 'Total social investment capital structure',
  UNIT_BILLION_VND: 'Unit: Billion VND.',
  YEAR: 'Yearly',
  QUARTER: 'Quarterly',
  ACC_QUARTER: `Acc. Quarterly`,
  BALANCE: 'State Budget Balance',
  REVENUE: 'State Budget Revenue',
  EXPENDITURE: 'State Budget Expenditure',
  BALANCE_CHART_NAME: 'State Budget Balance',
  REVENUE_CHART_NAME: 'State Budget Revenue',
  EXPENDITURE_CHART_NAME: 'State Budget Expenditure',
  TOTAL_SOCIAL: 'Total social investment capital',
  STATE_SECTOR: 'Investment capital of the state sector',
  BUDGET_PROVINCES: 'Investment capital of the state budget',
  TOTAL_SOCIAL_CHART_NAME: 'Total social investment capital',
  STATE_SECTOR_CHART_NAME: 'Investment capital of the state sector',
  BUDGET_PROVINCES_CHART_NAME: 'Investment capital of the state budget',
  YEARLY: 'Annual',
  VALUE: 'Value',
  PERCENT_VALUE: '% Value',
  BILLION_VND: 'Billion VND',
  BILLION_VND_TOOLTIP: 'Bn. VND',
  BILLION_VND_UNIT: '(Bn. VND)',
  PERCENT: '%',
  BUDGET_DECIFIT: 'Budget deficit (Billion VND)',
}

export const publicDebt = {
  PUBLIC_DEBT: 'Public Debt',
  PUBLIC_DEBT_INDICATOR: 'Các chỉ tiêu nợ công',
  PUBLIC_DEBT_1: 'Các chỉ tiêu nợ công và nợ công nước ngoài của quốc gia',
  PUBLIC_DEBT_2: 'Cơ cấu nợ trong nước và nước ngoài',
  PUBLIC_DEBT_3: 'Deposit structure',
  UNIT_MILLION_USD: 'Unit: Million USD.',
  NCP: 'Nợ chính phủ',
  NCPBL: 'Nợ chính phủ bảo lãnh',
}
