import { EMPTY_VALUE } from '../../../../constants/Common'
import { PRICE_DATA_TIME_SELECT } from '../constants'
import { ACC_MONTHLY_TITLE, TAB_TYPE } from './constants'
import { DEFAULT_MIN_TIME } from './iip/store/slice'

const { MONTHLY, ACC_MONTHLY, YEARLY } = PRICE_DATA_TIME_SELECT

export const getDataByMonth = (data, month) => {
  return data
    ?.filter((item) => item.month === month)
    ?.map((item) => ({
      value: item.value ?? 0,
      year: item.year ?? 0,
    }))
}

export const addColorIdToData = (data) => {
  return data.map((item) => ({
    ...item,
    colorId: data?.indexOf(item),
  }))
}

export const addColorIdToDataDefault = (data, request, prevData) => {
  if (!request?.Year) {
    return data
      .map((item) => ({
        ...item,
        colorId: data?.indexOf(item),
      }))
      .slice(0, 5)
  }
  return prevData
}

export const sortDataByMonthYear = (dataRange) => {
  return dataRange
    ?.map((item) => ({
      month: item.realMonth,
      year: item.realYear,
    }))
    ?.filter(
      (value, index, self) =>
        index ===
        self.findIndex((t) => t.month === value.month && t.year === value.year),
    )
    .sort((a, b) => {
      if (a.year === b.year) {
        if (a.month < b.month) return -1
      } else {
        if (a.year < b.year) return -1
      }
      return null
    })
}

export const getMonthAndYearInDataRange = (dataRange) => {
  return dataRange
    ?.map((item) => ({
      month: item.realMonth,
      year: item.realYear,
    }))
    ?.filter((value, index, self) => {
      return (
        index ===
        self.findIndex((t) => t.month === value.month && t.year === value.year)
      )
    })
    .sort((a, b) => {
      return a.year - b.year || a.month - b.month
    })
}

export const convertData = (data, type) => {
  const getDataKeyFromRange = sortDataByMonthYear(data)
  const dataWithMonthAndYear = (month, year, type) => {
    if (type === TAB_TYPE.IIC) {
      return data
        .filter((item) => item.realMonth === month && item.realYear === year)
        .map((item) => ({
          value: item.value * 100 ?? 0,
          typeID: item.iicvnTypeId ?? 0,
        }))
    }
    if (type === TAB_TYPE.IIP) {
      return data
        .filter((item) => item.realMonth === month && item.realYear === year)
        .map((item) => ({
          value: item.value * 100 ?? 0,
          typeID: item.iipvnTypeId ?? 0,
        }))
    }
    if (type === TAB_TYPE.III) {
      return data
        .filter((item) => item.realMonth === month && item.realYear === year)
        .map((item) => ({
          value: item.value * 100 ?? 0,
          typeID: item.iiivnTypeId ?? 0,
        }))
    }
  }
  const getDataValueFromRange = sortDataByMonthYear(data).map((item) => [
    ...dataWithMonthAndYear(item.month, item.year, type),
  ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.typeID]: e.value }
        }),
      )
    : []
  //convert to array of objects, each object contain date keys and pairs of id-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    ...getDataKeyFromRange[i],
  }))
}

export const getItemParent = (data) => {
  const itemAtLevel3 = data.filter((item) => item.iivnTypeLevel === 3)
  const itemParent = data.filter((item) => item.iivnTypeLevel !== 3)
  let parentArr = []
  for (let i = 0; i < itemParent.length; i++) {
    for (let j = 0; j < itemAtLevel3.length; j++)
      if (
        itemParent[i].iivnTypeId === itemAtLevel3[j].parentiivnTypeId &&
        parentArr.indexOf(itemParent[i]) === -1
      ) {
        parentArr.push(itemParent[i])
      }
  }
  return parentArr
}

export const getMonthAndYearKey = (month, year) => {
  return `${month}-${year}`
}

export const getDataByMonthAndYear = (data, month, year, type) => {
  if (type === TAB_TYPE.IIC) {
    return data
      ?.filter((item) => item.realMonth === month && item.realYear === year)
      ?.map((item) => ({
        value: item.value ?? 0,
        iivnTypeId: item.iicvnTypeId ?? 0,
      }))
  }
  if (type === TAB_TYPE.IIP) {
    return data
      ?.filter((item) => item.realMonth === month && item.realYear === year)
      ?.map((item) => ({
        value: item.value ?? 0,
        iivnTypeId: item.iipvnTypeId ?? 0,
      }))
  }
  if (type === TAB_TYPE.III) {
    return data
      ?.filter((item) => item.realMonth === month && item.realYear === year)
      ?.map((item) => ({
        value: item.value ?? 0,
        iivnTypeId: item.iiivnTypeId ?? 0,
      }))
  }
  return data
    ?.filter((item) => item.realMonth === month && item.realYear === year)
    ?.map((item) => ({
      value: item.value ?? 0,
      id: item?.typeID ?? 0,
    }))
}

export const convertDataTable = (data, dataWithoutVal = [], type) => {
  const getDataKeyFromRange = getMonthAndYearInDataRange(data)?.map((item) =>
    getMonthAndYearKey(item.month, item.year),
  )

  const getDataValueFromRange = getMonthAndYearInDataRange(data)?.map(
    (item) => [...getDataByMonthAndYear(data, item.month, item.year, type)],
  )

  const getCorrespondingDataWithKey = (data, item) => {
    return (
      data[data.indexOf(data.find((i) => i.iivnTypeId === item.iivnTypeId))]
        ?.value ?? EMPTY_VALUE
    )
  }

  const getKeyAndValRange = (item) => {
    let dataKeyAndValRange = []
    getDataKeyFromRange.forEach(
      (key, i) =>
        (dataKeyAndValRange[key] = getCorrespondingDataWithKey(
          getDataValueFromRange[i],
          item,
        )),
    )
    return dataKeyAndValRange
  }

  return dataWithoutVal?.map((item) => ({
    ...item,
    ...getKeyAndValRange(item),
  }))
}

//Convert Group Column
export const convertGroupColumn = (data) => {
  const getGroupColumns = data?.map((item) => Object.keys(item))[0]
  const filterGroupColumns = getGroupColumns?.filter((item) =>
    item.includes('-'),
  )
  return filterGroupColumns
}

//Convert title header
export const convertTitleHeader = (title, typeDataTime, locale) => {
  const year = title.slice(title.lastIndexOf('-') + 1)
  const month = title.slice(0, title.lastIndexOf('-'))

  if (month && year) {
    if (typeDataTime === MONTHLY) {
      return Number(month) < 10 ? `0${month}-${year}` : `${month}-${year}`
    }

    if (typeDataTime === ACC_MONTHLY) {
      return `${ACC_MONTHLY_TITLE[month][locale]}-${year}`
    }

    if (typeDataTime === YEARLY) {
      if (Number(month) === 12) {
        return year
      } else {
        return `${month}-${year}`
      }
    }
    return title
  }
  return ''
}

//Get time min
export const getTimeMinCallApi = (data) => {
  if (!Array.isArray(data) || !data?.length) {
    return DEFAULT_MIN_TIME
  }

  const minTime = data.sort((a, b) => {
    if (a.realYear > b.realYear) return -1
    if (a.realYear < b.realYear) return 1
    if (a.realYear === b.realYear) {
      if (a.realMonth > b.realMonth) return -1
      if (a.realMonth < b.realMonth) return 1
    }
    return 0
  })[data.length - 1]
  return { month: minTime.realMonth, year: minTime.realYear }
}

//Get time min
export const getTimeMaxCallApi = (data) => {
  if (!Array.isArray(data) || !data?.length) {
    return DEFAULT_MIN_TIME
  }

  const maxTime = data.sort((a, b) => {
    if (a.realYear > b.realYear) return -1
    if (a.realYear < b.realYear) return 1
    if (a.realYear === b.realYear) {
      if (a.realMonth > b.realMonth) return -1
      if (a.realMonth < b.realMonth) return 1
    }
    return 0
  })[0]

  return { month: maxTime.realMonth, year: maxTime.realYear }
}

export const handleYearHasData = (typeDataTime, realMonth, realYear) => {
  if (!realYear) {
    return new Date().getFullYear()
  }

  if (typeDataTime === 'Yearly' && realMonth) {
    return realMonth < 12 ? realYear - 1 : realYear
  }

  return realYear
}
