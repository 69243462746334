// Banks
export const TOP_COMPANIES_TAB_BANK = {
  PROFITABILITY: 'PROFITABILITY',
  ASSET_QUALITY: 'ASSET_QUALITY',
  EFFICIENCY: 'EFFICIENCY',
  VALUATION: 'VALUATION',
  GROWTH: 'GROWTH',
}

export const profitabilityIndicatorBank = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  YOEA: 'rT0286',
  COF: 'rT0287',
  NIM: 'rT0285',
  ROA: 'rT0059',
  ROE: 'rT0060',
}

export const assetQualityIndicatorBank = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  NPL_RATIO: 'rT0236',
  COVERAGE_RATIO_TABLE: 'rT0237',
  LOAN_LOSS_PROVISIONS_TABLE: 'rT0238',
  CASA_RATIO: 'rT0295',
  LDR_RATIO: 'rT0277',
  ST_FUNDING_TABLE: 'rT0318',
}

export const efficiencyIndicatorBank = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  NII_TOI: 'rT0250',
  NFI_TOI: 'rT0319',
  CIR: 'rT0252',
  GENERAL_AND_ADMINISTRATIVE_EXPENSES_TABLE: 'rT0260',
}

export const valuationIndicatorBank = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  BVPS: 'rtD0007',
  PE: 'rtD0022',
  PB: 'rtD0019',
  PE_FORWARD: 'rtD0065',
}

export const growthIndicatorBank = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  AVERAGE_LOANS_GROWTH_TABLE: 'rT0296',
  AVERAGE_DEPOSIT_GROWTH_TABLE: 'rT0297',
  INTEREST_INCOME_GROWTH_TABLE: 'rT0300',
  NON_INTEREST_INCOME_GROWTH_TABLE: 'rT0301',
  PREPROVISION_INCOME_GROWTH_TABLE: 'rT0299',
}

// Securities
export const TOP_COMPANIES_TAB_SECURITIES = {
  PROFITABILITY: 'PROFITABILITY',
  FINANCIAL_STRENGTH: 'FINANCIAL_STRENGTH',
  MANAGEMENT_EFFICIENCY: 'MANAGEMENT_EFFICIENCY',
  VALUATION: 'VALUATION',
  GROWTH: 'GROWTH',
}

export const profitabilityIndicatorSecurities = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  GROSS_PROFIT_MARGIN: 'rT0050',
  EBITDA_MARGIN: 'rT0051',
  EBIT_MARGIN: 'rT0052',
  PRETAX_MARGIN: 'rT0053',
  NET_PROFIT_MARGIN: 'rT0054',
  ROA: 'rT0059',
  ROE: 'rT0060',
  ROIC: 'rT0064',
}

export const financialStrengthIndicatorSecurities = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  CURRENT_RATIO_TABLE: 'rT0020',
  CASH_RATIO_TABLE: 'rT0018',
  QUICK_RATIO_TABLE: 'rT0019',
  TOTAL_DEBT_OR_EQUITY_TABLE: 'rT0027',
  MARGIN_LENDING_OR_OWNERS_EQUITY_TABLE: 'mloe',
}

export const managementEfficiencyIndicatorSecurities = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  ACCOUNT_RECEIVABLE_TURNOVER_TABLE: 'rT0008',
  ACCOUNT_PAYABLE_TURNOVER_TABLE: 'rT0012',
  REVENUE_PER_EMPLOYEE: 'rT0004',
  NET_INCOME_PER_EMPLOYEE: 'rT0003',
}

export const valuationIndicatorSecurities = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  PE: 'rtD0022',
  PS: 'rtD0025',
  PB: 'rtD0019',
  PE_FORWARD: 'rtD0065',
  EV_EBITDA: 'rtD0027',
}

export const growthIndicatorSecurities = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  NET_REVENUE_GROWTH: 'rT0156',
  EBIT_GROWTH: 'rT0159',
  EBITDA_GROWTH: 'rT0158',
  OWNERS_EQUITY_GROWTH: 'rT0174',
  TOTAL_ASSETS_GROWTH: 'rT0172',
}

// Corporate
export const TOP_COMPANIES_TAB_CORPORATE = {
  PROFITABILITY: 'PROFITABILITY',
  FINANCIAL_STRENGTH: 'FINANCIAL_STRENGTH',
  MANAGEMENT_EFFICIENCY: 'MANAGEMENT_EFFICIENCY',
  VALUATION: 'VALUATION',
  GROWTH: 'GROWTH',
}

export const profitabilityIndicatorCorporate = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  GROSS_PROFIT_MARGIN: 'rT0050',
  EBITDA_MARGIN: 'rT0051',
  EBIT_MARGIN: 'rT0052',
  PRETAX_MARGIN: 'rT0053',
  NET_PROFIT_MARGIN: 'rT0054',
  ROA: 'rT0059',
  ROE: 'rT0060',
  ROIC: 'rT0064',
}

export const financialStrengthIndicatorCorporate = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  CURRENT_RATIO_TABLE: 'rT0020',
  CASH_RATIO_TABLE: 'rT0018',
  QUICK_RATIO_TABLE: 'rT0019',
  TOTAL_DEBT_OR_EQUITY_TABLE: 'rT0027',
  INTEREST_COVERAGE_TABLE: 'rT0044',
}

export const managementEfficiencyIndicatorCorporate = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  ACCOUNT_RECEIVABLE_TURNOVER_TABLE: 'rT0008',
  INVENTORY_TURNOVER: 'rT0010',
  ACCOUNT_PAYABLE_TURNOVER_TABLE: 'rT0012',
  CASH_CONVERSION_CYCLE_TABLE: 'rT0015',
  CAPEX_OR_SALES_TABLE: 'rT0007',
  ASSET_TURNOVER: 'rT0003',
}

export const valuationIndicatorCorporate = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  PE: 'rtD0022',
  PS: 'rtD0025',
  PB: 'rtD0019',
  PE_FORWARD: 'rtD0065',
  EV_EBITDA: 'rtD0027',
}

export const growthIndicatorCorporate = {
  COMPANY: 'shortName',
  COMPANY_NAME: 'organizationShortName',
  MARKET_CAP: 'marketCap',
  NET_REVENUE_GROWTH: 'rT0156',
  EBITDA_GROWTH: 'rT0158',
  EBIT_GROWTH: 'rT0159',
  OWNERS_EQUITY_GROWTH: 'rT0174',
  TOTAL_ASSETS_GROWTH: 'rT0172',
}
