export const MARKET_STATISTIC = {
  ORDER_MATCHING: 'orderMatching',
  PUT_THROUGH: 'putThrough',
}

export const MARKET_STATISTIC_TABS = [
  {
    title: 'bond.corporateBond.priceBoard.ORDER_MATCHING',
    value: MARKET_STATISTIC.ORDER_MATCHING,
  },
  {
    title: 'bond.corporateBond.priceBoard.PUT_THROUGH',
    value: MARKET_STATISTIC.PUT_THROUGH,
  },
]

export const PRICE_TABS = [
  {
    title: 'bond.corporateBond.priceBoard.DIRTY_PRICE',
    value: false,
  },
  {
    title: 'bond.corporateBond.priceBoard.CLEAN_PRICE',
    value: true,
  },
]

export const TRADING_STATUS = {
  LISTED: 2,
  OTC: 4,
}

export const ISSUE_METHOD = {
  PUBLIC: 6,
  PRIVATE: 5,
}

export const COLLATERAL = {
  YES: 1,
  NO: 0,
}

export const PRICE_TYPES = {
  ALL: 'All',
  UNDER_ONE_THOUSANDS: 'LessThan100Thousand',
  FROM_ONE_THOUSANDS_TO_ONE_MILLION: 'HundredThousandTo1Million',
  FROM_ONE_MILLION_TO_ONE_HUNDRED_MILLION: 'OneMillionTo100Million',
  FROM_ONE_HUNDRED_MILLION_TO_ONE_BILLION: 'HundredMillionTo1Billion',
  OVER_ONE_BILLION: 'MoreThan1Billion',
}

export const PRICE_OPTIONS = [
  {
    name: 'bond.corporateBond.priceBoard.ALL_PRICE',
    value: PRICE_TYPES.ALL,
  },
  {
    name: 'bond.corporateBond.priceBoard.UNDER_ONE_THOUSANDS',
    value: PRICE_TYPES.UNDER_ONE_THOUSANDS,
  },
  {
    name: 'bond.corporateBond.priceBoard.FROM_ONE_THOUSANDS_TO_ONE_MILLION',
    value: PRICE_TYPES.FROM_ONE_THOUSANDS_TO_ONE_MILLION,
  },
  {
    name: 'bond.corporateBond.priceBoard.FROM_ONE_MILLION_TO_ONE_HUNDRED_MILLION',
    value: PRICE_TYPES.FROM_ONE_MILLION_TO_ONE_HUNDRED_MILLION,
  },
  {
    name: 'bond.corporateBond.priceBoard.FROM_ONE_HUNDRED_MILLION_TO_ONE_BILLION',
    value: PRICE_TYPES.FROM_ONE_HUNDRED_MILLION_TO_ONE_BILLION,
  },
  {
    name: 'bond.corporateBond.priceBoard.OVER_ONE_BILLION',
    value: PRICE_TYPES.OVER_ONE_BILLION,
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const tableHeadColumns = ({
  isCollapseBid,
  isCollapseAsk,
  handleClickCollapseBid,
  handleClickCollapseAsk,
  bidPrice,
  bidPrice2,
  bidPrice3,
  askPrice,
  askPrice2,
  askPrice3,
  lowPrice,
  highPrice,
  avgPrice,
  matchedPrice,
  type,
}) => {
  let columns = [
    [
      {
        name: 'id',
        value: [],
        disableSort: true,
        attrs: {
          colSpan: 3,
        },
      },
      {
        name: 'bid',
        value: [
          type === MARKET_STATISTIC.ORDER_MATCHING
            ? 'bond.corporateBond.priceBoard.BID'
            : 'bond.corporateBond.priceBoard.BID_2',
        ],
        attrs: {
          colSpan: isCollapseBid ? 6 : 2,
        },
        disableSort: true,
        isFirstRow: true,
        isCollapseLeft: type === MARKET_STATISTIC.ORDER_MATCHING && true,
        isCollapse: type === MARKET_STATISTIC.ORDER_MATCHING && isCollapseBid,
        justifyContent: 'justify-content-center',
        onClick: handleClickCollapseBid,
      },
      {
        name: 'matched',
        value: [
          type === MARKET_STATISTIC.ORDER_MATCHING
            ? 'bond.corporateBond.priceBoard.MATCHED'
            : 'bond.corporateBond.priceBoard.MATCHED_2',
        ],
        attrs: {
          colSpan: 4,
        },
        disableSort: true,
        isFirstRow: true,
        justifyContent: 'justify-content-center',
      },
      {
        name: 'ask',
        value: [
          type === MARKET_STATISTIC.ORDER_MATCHING
            ? 'bond.corporateBond.priceBoard.ASK'
            : 'bond.corporateBond.priceBoard.ASK_2',
        ],
        attrs: {
          colSpan: isCollapseAsk ? 6 : 2,
        },
        disableSort: true,
        isFirstRow: true,
        isCollapseRight: type === MARKET_STATISTIC.ORDER_MATCHING && true,
        justifyContent: 'justify-content-center',
        onClick: handleClickCollapseAsk,
        isCollapse: type === MARKET_STATISTIC.ORDER_MATCHING && isCollapseAsk,
      },
      {
        name: 'tradingPrice',
        value: [
          type === MARKET_STATISTIC.ORDER_MATCHING
            ? 'bond.corporateBond.priceBoard.TRADING_PRICE'
            : 'bond.corporateBond.priceBoard.TRADING_PRICE_2',
        ],
        attrs: {
          colSpan: 4,
        },
        disableSort: true,
        isFirstRow: true,
        justifyContent: 'justify-content-center',
      },
      {
        name: 'information',
        value: ['bond.corporateBond.priceBoard.INFORMATION'],
        attrs: {
          colSpan: 5,
        },
        disableSort: true,
        isFirstRow: true,
        justifyContent: 'justify-content-center',
      },
    ],
  ]

  let theadSecond = [
    {
      name: 'ticker',
      value: [
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? 'bond.corporateBond.priceBoard.BOND_CODE'
          : 'bond.corporateBond.priceBoard.TICKER',
      ],
      keySort: 'ticker',
      justifyContent: 'justify-content-start',
      attrs: {
        style: {
          minWidth: 97,
        },
      },
    },
    {
      name: 'organizationShortName',
      value: ['bond.corporateBond.priceBoard.ISSUER'],
      keySort: 'organizationShortName',
      justifyContent: 'justify-content-start',
      attrs: {
        style: {
          minWidth: 150,
          maxWidth: 150,
        },
      },
    },
    {
      name: 'couponTypeName',
      value: ['bond.corporateBond.priceBoard.COUPON_TYPE_2'],
      keySort: 'couponTypeName',
      justifyContent: 'justify-content-start',
      attrs: {
        style: {
          minWidth: 84,
        },
      },
    },
  ]

  const columnsCollapseBid = [
    {
      name: 'bidPrice3',
      value: ['bond.corporateBond.priceBoard.PRICE_3'],
      keySort: bidPrice3,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'bidPrice3',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'bidYtm3',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'bidVolume3',
      value: ['bond.corporateBond.priceBoard.VOLUME_3'],
      keySort: 'bidVolume3',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'bidPrice2',
      value: ['bond.corporateBond.priceBoard.PRICE_2'],
      keySort: bidPrice2,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'bidPrice2',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'bidYtm2',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'bidVolume2',
      value: ['bond.corporateBond.priceBoard.VOLUME_2'],
      keySort: 'bidVolume2',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
  ]

  if (isCollapseBid) {
    theadSecond = [...theadSecond, ...columnsCollapseBid]
  }

  const columnsSecond = [
    {
      name: 'bidPrice',
      value:
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? ['bond.corporateBond.priceBoard.PRICE_1']
          : [
              'bond.corporateBond.priceBoard.PRICE_5',
              'bond.corporateBond.priceBoard.PRICE_6',
            ],
      keySort: bidPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: type === MARKET_STATISTIC.ORDER_MATCHING ? 90 : 115,
        },
      },
      sortBy: [
        {
          name: 'bidPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'bidYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'bidVolume',
      value:
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? ['bond.corporateBond.priceBoard.VOLUME_1']
          : [
              'bond.corporateBond.priceBoard.VOLUME_5',
              'bond.corporateBond.priceBoard.VOLUME_6',
            ],
      justifyContentChild: 'justify-content-end',
      keySort: 'bidVolume',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'ytm',
      value: ['bond.corporateBond.priceBoard.YTM'],
      keySort: 'ytm',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'matchedPrice',
      value: ['bond.corporateBond.priceBoard.PRICE_YTM'],
      keySort: matchedPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'matchedPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'matchedYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'matchedVolume',
      value: [
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? 'bond.corporateBond.priceBoard.MATCHED_VOLUME'
          : 'bond.corporateBond.priceBoard.MATCHED_VOLUME_2',
      ],
      keySort: 'matchedVolume',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'matchedValue',
      value: [
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? 'bond.corporateBond.priceBoard.MATCHED_VALUE'
          : 'bond.corporateBond.priceBoard.MATCHED_VALUE_2',
      ],
      keySort: 'matchedValue',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'askPrice',
      value:
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? ['bond.corporateBond.priceBoard.PRICE_1']
          : [
              'bond.corporateBond.priceBoard.PRICE_7',
              'bond.corporateBond.priceBoard.PRICE_8',
            ],
      keySort: askPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: type === MARKET_STATISTIC.ORDER_MATCHING ? 90 : 115,
        },
      },
      sortBy: [
        {
          name: 'askPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'askYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'askVolume',
      value:
        type === MARKET_STATISTIC.ORDER_MATCHING
          ? ['bond.corporateBond.priceBoard.VOLUME_1']
          : [
              'bond.corporateBond.priceBoard.VOLUME_7',
              'bond.corporateBond.priceBoard.VOLUME_8',
            ],
      justifyContentChild: 'justify-content-end',
      keySort: 'askVolume',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
  ]

  theadSecond = [...theadSecond, ...columnsSecond]

  const columnsCollapseAsk = [
    {
      name: 'askPrice2',
      value: ['bond.corporateBond.priceBoard.PRICE_2'],
      keySort: askPrice2,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'askPrice2',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'askYtm2',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'askVolume2',
      value: ['bond.corporateBond.priceBoard.VOLUME_2'],
      keySort: 'askVolume2',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'askPrice3',
      value: ['bond.corporateBond.priceBoard.PRICE_3'],
      keySort: askPrice3,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'askPrice3',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'askYtm3',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'askVolume3',
      value: ['bond.corporateBond.priceBoard.VOLUME_3'],
      keySort: 'askVolume3',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
  ]

  if (isCollapseAsk) {
    theadSecond = [...theadSecond, ...columnsCollapseAsk]
  }

  const columnsOrder = [
    {
      name: 'highPrice',
      value: ['bond.corporateBond.priceBoard.HIGH'],
      keySort: highPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'highPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'highYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'lowPrice',
      value: ['bond.corporateBond.priceBoard.LOW'],
      keySort: lowPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: 90,
        },
      },
      sortBy: [
        {
          name: 'lowPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'lowYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'avgPrice',
      value: ['bond.corporateBond.priceBoard.AVG'],
      keySort: avgPrice,
      isHover: true,
      attrs: {
        style: {
          minWidth: 120,
        },
      },
      sortBy: [
        {
          name: 'avgPrice',
          value: 'bond.corporateBond.priceBoard.SORT_BY_PRICE',
        },
        {
          name: 'avgYtm',
          value: 'bond.corporateBond.priceBoard.SORT_BY_YTM',
        },
      ],
    },
    {
      name: 'totalMatchedValue',
      value: ['bond.corporateBond.priceBoard.TOTAL_VALUE'],
      keySort: 'totalMatchedValue',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
  ]

  if (type === MARKET_STATISTIC.ORDER_MATCHING) {
    theadSecond = [...theadSecond, ...columnsOrder]
  }

  const columnsDeal = [
    {
      name: 'totalHighVolumeBid',
      value: [
        'bond.corporateBond.priceBoard.HIGH_2',
        'bond.corporateBond.priceBoard.HIGH_3',
      ],
      keySort: 'totalHighVolumeBid',
      justifyContentChild: 'justify-content-end',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'totalHighVolumeAsk',
      value: [
        'bond.corporateBond.priceBoard.LOW_2',
        'bond.corporateBond.priceBoard.LOW_3',
      ],
      keySort: 'totalHighVolumeAsk',
      justifyContentChild: 'justify-content-end',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
    {
      name: 'totalDealVolume',
      value: ['bond.corporateBond.priceBoard.AVG_2'],
      keySort: 'totalDealVolume',
      justifyContentChild: 'justify-content-end',
      attrs: {
        style: {
          minWidth: 120,
        },
      },
    },
    {
      name: 'totalDealValue',
      value: ['bond.corporateBond.priceBoard.TOTAL_VALUE_2'],
      keySort: 'totalDealValue',
      justifyContentChild: 'justify-content-end',
      attrs: {
        style: {
          minWidth: 90,
        },
      },
    },
  ]

  if (type === MARKET_STATISTIC.PUT_THROUGH) {
    theadSecond = [...theadSecond, ...columnsDeal]
  }

  const columnsThird = [
    {
      name: 'isCollateral',
      value: ['bond.corporateBond.priceBoard.COLLATERAL'],
      keySort: 'isCollateral',
      justifyContent: 'justify-content-start',
      attrs: {
        style: {
          minWidth: 80,
        },
      },
    },
    {
      name: 'couponValue',
      value: ['bond.corporateBond.priceBoard.COUPON'],
      keySort: 'couponValue',
      attrs: {
        style: {
          minWidth: 80,
        },
      },
    },
    {
      name: 'remainingDuration',
      value: ['bond.corporateBond.priceBoard.REMAINING_DURATION'],
      additionText: 'bond.corporateBond.secondaryMarket.MONTH',
      keySort: 'remainingDuration',
      attrs: {
        style: {
          minWidth: 80,
        },
      },
    },
    // {
    //   name: 'tradingStatusId',
    //   value: ['bond.corporateBond.priceBoard.LISTED'],
    //   keySort: 'tradingStatusId',
    //   justifyContent: 'justify-content-start',
    //   attrs: {
    //     style: {
    //       minWidth: 80,
    //       maxWidth: 80,
    //     },
    //   },
    // },
    {
      name: 'issueMethodId',
      value: [
        'bond.corporateBond.priceBoard.ISSUE_METHOD',
        'bond.corporateBond.priceBoard.ISSUE_METHOD_2',
      ],
      keySort: 'issueMethodId',
      justifyContent: 'justify-content-start',
      attrs: {
        style: {
          minWidth: 95,
          maxWidth: 95,
        },
      },
    },
  ]

  theadSecond = [...theadSecond, ...columnsThird]

  return [...columns, theadSecond]
}

export const RADIO_TYPES = {
  YTM: 1,
  DIRTY_PRICE: 2,
  CLEAN_PRICE: 3,
}

export const CASH_FLOW_TYPES = {
  NOMINAL: 1,
  REAL: 2,
}
