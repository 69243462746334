import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import TopInfo from '../../../common/topInfo/stockTopInfo'
import Content from './Content'

export const InterconnectionMap = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.interconnectionMap.common.title" />
            </div>
            <div style={{ paddingBottom: '4px' }}>
              <TopInfo height="30px" isHidePriceData />
            </div>
            {size.height && (
              <div
                style={{
                  position: 'relative',
                  height: `calc(100% - ${size.height}px)`,
                }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
