import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import {
  selectLocationChecked,
  selectTimeFrequency,
} from '../totalRegisteredFDI/store/slice'
import { ChartComponent } from './ChartComponent'
import FilterCountry from './dropdown'
import { convertData } from './helper'
import { selectDataChart, selectLoading } from './store/slice'
import { getInvestmentStructure } from './store/thunk'
import style from './style.module.css'

const InvestmentStructure = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectDataChart)
  const timeFrequency = useSelector(selectTimeFrequency)
  const locationChecked = useSelector(selectLocationChecked)

  const [listCheckbox, setListCheckbox] = useState([
    'fdiTypeId_15',
    'fdiTypeId_17',
    'fdiTypeId_19',
    'fdiTypeId_14',
    'fdiTypeId_20',
  ])

  useEffect(() => {
    if (locationChecked.locationId)
      dispatch(
        getInvestmentStructure({
          LocationId: locationChecked.locationId,
          TimeFrequency: timeFrequency,
        }),
      )
  }, [locale, timeFrequency, locationChecked.locationId])

  const dataChartFormat = convertData(dataChart, timeFrequency)
  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <FilterCountry />
            {size.height && (
              <div style={{ height: height - size.height }}>
                {isLoading ? (
                  <Loading />
                ) : (
                  <>
                    {dataChartFormat.length ? (
                      <SizeTracker>
                        {(size1) => {
                          return (
                            <div
                              className="position-relative"
                              id="FDIStructureByProvinceId"
                            >
                              <div
                                className={[
                                  style.canvasChartContainer,
                                  'canvas-chart-title',
                                ].join(' ')}
                              >
                                <Translate value="economy.fdi.fdiByCountries.LABEL_CHART" />{' '}
                                {locationChecked.locationName}
                              </div>
                              {size.height && (
                                <ChartComponent
                                  data={dataChartFormat}
                                  width={width}
                                  height={height - size1.height - size.height}
                                  keyXAxis={'XAxis'}
                                  displayData={listCheckbox}
                                  name1={'fdiTypeId_15'}
                                  name2={'fdiTypeId_17'}
                                  name3={'fdiTypeId_19'}
                                  name4={'fdiTypeId_20'}
                                  lineKey={'fdiTypeId_14'}
                                />
                              )}
                              <FooterContainer
                                key={width}
                                data={[
                                  {
                                    text: 'economy.fdi.fdiByCountries.NEW_REGISTRATION',
                                    dataKey: 'fdiTypeId_15',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#185999',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByCountries.ADDITIONAL_REGISTRATION',
                                    dataKey: 'fdiTypeId_17',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#fff6df',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByCountries.CAPITAL_CONTRIBUTION_SHARE_PURCHASE',
                                    dataKey: 'fdiTypeId_19',
                                    type: TYPE.SQUARE,
                                    before: {
                                      bgColor: '#fe7e6d',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByCountries.NUMBER_OF_NEW_REGISTRATION_PROJECT',
                                    dataKey: 'fdiTypeId_14',
                                    type: TYPE.CIRCLE_MARKER_LINE,
                                    before: {
                                      bgColor: '#f7b80c',
                                    },
                                    after: {
                                      bgColor: '#f7b80c',
                                    },
                                  },
                                  {
                                    text: 'economy.fdi.fdiByCountries.REGISTERED_CAPITAL',
                                    dataKey: 'fdiTypeId_20',
                                    type: TYPE.DASHED_LINE,
                                    dashedLine: {
                                      bgColor: '#838383',
                                    },
                                  },
                                ]}
                                listCheckbox={listCheckbox}
                                setListCheckbox={setListCheckbox}
                                numItemPerRow={3}
                              />
                            </div>
                          )
                        }}
                      </SizeTracker>
                    ) : (
                      <NoData />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default InvestmentStructure
