import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import OneCalendarRange from '../../../../common/calendar/OneCalendarRange'
import { Span } from '../../../../common/html/Span'
import SelectCustom from '../../../../common/selectCustom'
import {
  FORMAT,
  formatDateTime,
  getCurrentDateTime,
} from '../../../../utils/Datetime'
import {
  changeEndDate,
  changeStartDate,
  clearData,
  selectFilterEndDate,
  selectFilterStartDate,
} from '../store/slice'
import UseTimeZone from './../../../../common/hooks/useTimeZone'
import { FILTER_MIN_YEAR } from './constant'
import style from './index.module.css'

const DAY_MILISECONDS = 24 * 60 * 60 * 1000

export const SELECT_VALUES = {
  SEVEN_DAYS: 'SEVEN_DAYS',
  LAST_MONTH: 'LAST_MONTH',
  LAST_THREE_MONTH: 'LAST_THREE_MONTH',
  LAST_SIX_MONTH: 'LAST_SIX_MONTH',
  LAST_YEAR: 'LAST_YEAR',
  ALL: 'ALL',
  CUSTOM: 'CUSTOM',
}

export const getSelectTimeOptions = () => ({
  [SELECT_VALUES.SEVEN_DAYS]: {
    text: 'corporate.newsEvents.filing.selectTime.SEVEN_DAYS',
    startDate: formatDateTime(
      new Date(Date.now() - 7 * DAY_MILISECONDS),
      FORMAT.DATE,
    ),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.LAST_MONTH]: {
    text: 'corporate.newsEvents.filing.selectTime.LAST_MONTH',
    startDate: formatDateTime(
      new Date(Date.now() - 30 * DAY_MILISECONDS),
      FORMAT.DATE,
    ),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.LAST_THREE_MONTH]: {
    text: 'corporate.newsEvents.filing.selectTime.LAST_THREE_MONTH',
    startDate: formatDateTime(
      new Date(Date.now() - 90 * DAY_MILISECONDS),
      FORMAT.DATE,
    ),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.LAST_SIX_MONTH]: {
    text: 'corporate.newsEvents.filing.selectTime.LAST_SIX_MONTH',
    startDate: formatDateTime(
      new Date(Date.now() - 180 * DAY_MILISECONDS),
      FORMAT.DATE,
    ),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.LAST_YEAR]: {
    text: 'corporate.newsEvents.filing.selectTime.LAST_YEAR',
    startDate: formatDateTime(
      new Date(Date.now() - 365 * DAY_MILISECONDS),
      FORMAT.DATE,
    ),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.ALL]: {
    text: 'corporate.newsEvents.filing.selectTime.ALL',
    startDate: formatDateTime(new Date(2000, 0, 2), FORMAT.DATE),
    endDate: formatDateTime(new Date(), FORMAT.DATE),
  },
  [SELECT_VALUES.CUSTOM]: {
    text: 'corporate.newsEvents.filing.selectTime.CUSTOM',
  },
})

const DateRange = () => {
  const timeZone = UseTimeZone()
  const [value, setValue] = useState(SELECT_VALUES.LAST_YEAR)

  const selectOption = useMemo(getSelectTimeOptions, [timeZone])

  const dispatch = useDispatch()
  const filterStartDate = useSelector(selectFilterStartDate)
  const filterEndDate = useSelector(selectFilterEndDate)

  const handleSelectTimeChange = (value) => {
    setValue(value)
    if (value === SELECT_VALUES.CUSTOM) {
      dispatch(changeStartDate(null))
      dispatch(changeEndDate(null))
      dispatch(clearData())
    }
    dispatch(changeStartDate(selectOption[value].startDate))
    dispatch(changeEndDate(selectOption[value].endDate))
  }

  const handleChange = (dates) => {
    setValue(SELECT_VALUES.CUSTOM)
    const [start, end] = dates
    dispatch(changeStartDate(start))
    dispatch(changeEndDate(end))
  }

  const handleTimeZoneChange = () => {
    if (value !== SELECT_VALUES.CUSTOM) {
      dispatch(changeStartDate(selectOption[value].startDate))
      dispatch(changeEndDate(selectOption[value].endDate))
    }
  }

  useEffect(() => {
    handleTimeZoneChange()
  }, [timeZone])

  return (
    <div className="mt-30">
      <Span className={style.filterTitle} style={{ fontSize: 11 }}>
        <Translate value="corporate.newsEvents.filing.TIME" />
      </Span>
      <SelectCustom
        value={value}
        isI18n={true}
        selectData={Object.keys(selectOption).map((key) => ({
          name: selectOption[key].text,
          value: key,
        }))}
        handleChange={handleSelectTimeChange}
      />
      <div className={style.dateRange}>
        <OneCalendarRange
          startDate={filterStartDate ? new Date(filterStartDate) : null}
          endDate={filterEndDate ? new Date(filterEndDate) : null}
          customHeader={HeaderOneCalendarRange({
            startYear: FILTER_MIN_YEAR,
            endYear: +getCurrentDateTime(FORMAT.YEAR),
          })}
          minDate={new Date(FILTER_MIN_YEAR, 0)}
          maxDate={new Date(new Date().getFullYear(), 11, 31)}
          handleChange={handleChange}
        />
      </div>
    </div>
  )
}

export default DateRange
