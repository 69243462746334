import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar, LabelList } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { VALUE_PERIOD } from '../../filter/constants'
import { selectTimeRangeForeignNetBuyingSelling } from '../../filter/store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const ForeignNetTradingChart = ({ data, width, height, keyXAxis, barKeys }) => {
  const footerData = () => {
    return [
      {
        color: '#97ba53',
        text: 'corporate.companySnapshot.NET_BUYING_VALUE',
        type: FOOTER_TYPE.SQUARE,
      },
      {
        color: '#487eb9',
        text: 'corporate.companySnapshot.NET_SELLING_VALUE',
        type: FOOTER_TYPE.SQUARE,
      },
    ]
  }

  const dataFormat = formatDataChart(data, barKeys)
  const timeRangeForeignNetBuyingSelling = useSelector(
    selectTimeRangeForeignNetBuyingSelling,
  )
  const locale = useSelector((state) => state.i18n.locale)

  const [timeRangePeriod, setTimeRangePeriod] = useState(
    timeRangeForeignNetBuyingSelling.period,
  )

  useEffect(() => {
    setTimeRangePeriod(timeRangeForeignNetBuyingSelling.period)
  }, [])

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={dataFormat}
              width={width}
              height={height - size.height || 0}
              keyXAxis={keyXAxis}
              hideToolTipChart
              styleTickXAxis={{ fill: '#000000', fontSize: 8, fontWeight: 500 }}
              styleTickYAxis={{
                fill: '#000000',
                fontSize: 9,
                fontWeight: 500,
                labelFontSize: 8,
              }}
              margin={{ left: 0, right: 10, top: 20, bottom: 10 }}
              xTickNum={SETTINGS.xTickNum}
              tickFormatter={(val) =>
                formatDateTime(
                  val,
                  timeRangePeriod === VALUE_PERIOD.ONE_YEAR ||
                    timeRangePeriod === VALUE_PERIOD.SIX_MONTHS ||
                    timeRangePeriod === VALUE_PERIOD.ALL
                    ? FORMAT.MONTH_YEAR_NUMBER
                    : FORMAT.DATE,
                  locale,
                )
              }
              interval={
                timeRangePeriod === VALUE_PERIOD.ONE_WEEK ||
                timeRangePeriod === VALUE_PERIOD.SIX_MONTHS
                  ? 0
                  : 'preserveStartEnd'
              }
              reversed={true}
              yAxis={[
                {
                  id: 'left',
                  keys: barKeys,
                  orientation: 'left',
                  isBarChart: true,
                  tickNum: SETTINGS.yTickNum,
                  label: I18n.t('corporate.companySnapshot.VND_MILLION'),
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  themeDownloadPDF: true,
                },
              ]}
            >
              {barKeys.map((barKey, index) => (
                <React.Fragment key={index}>
                  <Bar
                    stackId="barRight"
                    isAnimationActive={false}
                    yAxisId="left"
                    dataKey={barKey}
                    fill={index === 0 ? '#97ba53' : '#487eb9'}
                    barSize={getColumnSizeInBarChart(
                      width,
                      dataFormat.length,
                      35,
                    )}
                  >
                    {(timeRangePeriod === VALUE_PERIOD.ONE_WEEK ||
                      timeRangePeriod === VALUE_PERIOD.SIX_MONTHS) && (
                      <LabelList
                        dataKey={barKey}
                        position="top"
                        fontSize={9}
                        fontWeight={500}
                        formatter={(val) => formatVal(val)}
                      />
                    )}
                  </Bar>
                </React.Fragment>
              ))}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            data={footerData()}
            numItemPerRow={2}
            itemStyle={{ fixedFontSize: 10 }}
            isDownTheLine
          />
        </>
      )}
    </SizeTracker>
  )
}

export default ForeignNetTradingChart

export const formatDataChart = (data, barKeys) => {
  return data.map((item) => ({
    ...item,
    [barKeys[0]]:
      item[barKeys[0]] - item[barKeys[1]] >= 0
        ? valDivMillion(item[barKeys[0]] - item[barKeys[1]])
        : null,
    [barKeys[1]]:
      item[barKeys[0]] - item[barKeys[1]] < 0
        ? valDivMillion(item[barKeys[0]] - item[barKeys[1]])
        : null,
  }))
}
