import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SizeTracker } from '../../common/sizeTracker'
import {
  downloadChart,
  selectDownloadingChart,
} from '../common/chartInPanel/store/slice'
import useDownloadChart from '../common/chartInPanel/useDownloadChart'
import withWrapperDataChart from '../common/chartInPanel/withWrapperDataChart'
import ContentChart from '../common/previewChart/ContentChart'
import FooterPreviewChart from '../common/previewChart/FooterPreviewChart'
import { useResize } from '../common/useResize'
import style from '../dashboard/index.module.css'
import { getIndicators } from '../store/thunk'
import { Header } from './Header'

const SharedChart = ({ item }) => {
  const dispatch = useDispatch()
  const chartRef = useRef(null)
  const wrapperChartRef = useRef(null)
  const downloadingMessage = UseI18n('financialChart.DOWNLOADING')
  const locale = useSelector((state) => state.i18n.locale)
  const downloadingID = useSelector(selectDownloadingChart)
  const [isDownloading] = useDownloadChart(downloadingID, item.id, item.name)

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    dispatch(getIndicators())
  }, [locale])

  const onDownloadChart = (event) => {
    event.preventDefault()
    dispatch(downloadChart({ id: item.id }))
  }

  const dimensionsWrapperChart = useResize(wrapperChartRef)
  return (
    <div className="flex-column h-100">
      <div className="position-relative w-100 h-100">
        {item && (
          <SizeTracker>
            {(size) => {
              return (
                <>
                  {isDownloading && (
                    <div className={style.downloadContainer}>
                      {downloadingMessage}
                    </div>
                  )}
                  <Header
                    onDownloadChart={onDownloadChart}
                    typeDuplicate={'duplicateChart'}
                    chartItem={item}
                  />
                  {size.height && (
                    <div
                      ref={wrapperChartRef}
                      style={{ height: `calc(100% - ${size.height}px)` }}
                    >
                      <ContentChart
                        ref={chartRef}
                        dimensions={dimensions}
                        dimensionsWrapperChart={dimensionsWrapperChart}
                        dataChartPreview={item}
                      />
                    </div>
                  )}
                  <FooterPreviewChart
                    width={chartRef.current?.offsetWidth}
                    height={chartRef.current?.offsetHeight}
                    setDimensions={setDimensions}
                  />
                </>
              )
            }}
          </SizeTracker>
        )}
      </div>
    </div>
  )
}

export default withWrapperDataChart(SharedChart)
