import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import style from '../../index.module.css'
import { ChartComponent } from './ChartComponent'
import { VALUE_BREAKDOWN_FILTER } from './constants'
import {
  changeCurrentComponentFilter,
  selectCurrentComponentFilter,
  selectDataChart,
  selectLoading,
} from './store/slice'
import {
  getNetTradingValueChartOverview,
  getNetTradingValueChartTicker,
} from './store/thunk'

export const ValueBreakdown = ({ width, height, filterData }) => {
  const dispatch = useDispatch()

  const currentComponentFilter = useSelector(selectCurrentComponentFilter)
  const dataChart = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)

  const handleChangeComponent = (item) => {
    dispatch(changeCurrentComponentFilter(item))
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className={`${style.inputDropdown}`}>
            <SelectCustom
              value={currentComponentFilter}
              isI18n={true}
              selectData={VALUE_BREAKDOWN_FILTER}
              handleChange={handleChangeComponent}
            />
          </div>
          {size.height && (
            <div style={{ height: height - size.height }} id="valueBreakdown">
              <ChartComponent
                width={width}
                height={height - size.height}
                currentComponentFilter={currentComponentFilter}
                dataChart={dataChart}
                isLoading={isLoading}
                filterData={filterData}
                getNetTradingValueChartTicker={getNetTradingValueChartTicker}
                getNetTradingValueChartOverview={
                  getNetTradingValueChartOverview
                }
              />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
