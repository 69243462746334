import { debounce } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import Dropdown from '../../../../common/dropdown'
import { Input } from '../../../../common/html/Input'
import { Span } from '../../../../common/html/Span'
import useGetICB from '../../../../common/masterData/useGetICB'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  changeFilter,
  changeFilterIcbIds,
  changeLoadingIcbIds,
  selectFilterPriceBoard,
} from '../store/slice'
import style from './style.module.css'

const FilterSector = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()

  const { icbIds } = useSelector(selectFilterPriceBoard)
  const { ICBs } = useGetICB()
  const listSector = ICBs.filter((item) => item.icbLevel === 2).map((item) => ({
    icbNameCustom: item.icbName + ' L2',
    ...item,
  }))

  const [isShow, setIsShow] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const toggleDropSearch = () => {
    setIsShow(!isShow)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    setKeySearch('')
  }

  const changeValueSector = (value) => {
    dispatch(
      changeFilter({
        key: 'icbIds',
        value: !icbIds.includes(value)
          ? [...icbIds, value]
          : icbIds.filter((item) => item !== value),
      }),
    )
  }

  const handleChangeAllSector = (value) => {
    dispatch(
      changeFilter({
        key: 'icbIds',
        value: value ? listSector.map((item) => item.icbId) : [],
      }),
    )
  }

  const renderValueName = () => {
    const names = []

    listSector.forEach((item) => {
      if (icbIds.includes(item.icbId) && !!item.icbNameCustom?.length) {
        names.push(item.icbNameCustom)
      }
    })

    return names.join(',')
  }

  useEffect(() => {
    if (!!listSector.length) {
      dispatch(
        changeFilterIcbIds({
          key: 'icbIds',
          value: listSector.map((item) => item.icbId),
        }),
      )
    }

    dispatch(changeLoadingIcbIds(false))
  }, [listSector.length])

  return (
    <div className={`input-dropdown ${style.input}`} ref={parentDropdownRef}>
      <span
        className={`span-input w-100 ${style.inputDropdown} `}
        style={{ padding: '2px 20px 2px 8px' }}
        ref={dropdownRef}
        onClick={toggleDropSearch}
      >
        {!!icbIds.length ? (
          icbIds.length === listSector.length ? (
            <Translate value="bond.corporateBond.priceBoard.ALL_SECTOR" />
          ) : (
            <TextEllipsis text={renderValueName()} zIndexTooltip={999} />
          )
        ) : (
          <Span style={{ opacity: 0 }}>
            <Translate value="bond.corporateBond.priceBoard.ALL_SECTOR" />
          </Span>
        )}
        <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside
          handleClickOutside={hideDropSearch}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width: dropdownRef?.current?.clientWidth }}
            >
              <div
                className="form-search-inbox"
                style={{ width: 'auto', margin: '4px 8px 8px 8px' }}
              >
                <Input
                  style={{
                    fontSize: 11,
                  }}
                  type="text"
                  className={`search-inbox ${style.inputSearch}`}
                  placeholder={I18n.t('common.SEARCH')}
                  onChange={debounce((e) =>
                    setKeySearch(e.target.value?.toLowerCase()),
                  )}
                />
                <button type="button">
                  <i className="icon-search-2" />
                </button>
              </div>
              <ScrollComponent autoHeight={true} autoHeightMax={200}>
                {!!listSector.length && (
                  <ul className="list-check">
                    {!keySearch && (
                      <li>
                        <a>
                          <label className="d-flex ali-center">
                            <input
                              type="checkbox"
                              className="checkbox mr-8"
                              checked={icbIds.length === listSector.length}
                              onChange={(e) =>
                                handleChangeAllSector(
                                  e?.target?.checked ?? false,
                                )
                              }
                            />
                            <Span style={{ fontSize: 12 }}>
                              <Translate value="bond.corporateBond.priceBoard.ALL_SECTOR" />
                            </Span>
                          </label>
                        </a>
                      </li>
                    )}
                    {!!listSector.length &&
                      listSector
                        .filter((item) =>
                          item.icbNameCustom?.toLowerCase().includes(keySearch),
                        )
                        .map((sector) => {
                          return (
                            <li key={sector.icbId}>
                              <a>
                                <label className="d-flex ali-center">
                                  <input
                                    type="checkbox"
                                    className="checkbox mr-8"
                                    checked={
                                      icbIds.includes(sector.icbId) ?? false
                                    }
                                    onChange={() =>
                                      changeValueSector(sector.icbId)
                                    }
                                  />
                                  <TextEllipsis
                                    text={sector.icbNameCustom}
                                    zIndexTooltip={999}
                                  />
                                </label>
                              </a>
                            </li>
                          )
                        })}
                  </ul>
                )}
              </ScrollComponent>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}

export default FilterSector
