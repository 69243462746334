import { BALANCE_ID_LIST, EXPORT_ID_LIST, IMPORT_ID_LIST } from './constants'

export const getKeyNameById = (id) => {
  let keyName = ''
  if (BALANCE_ID_LIST.includes(id)) {
    keyName = 'balance_of_trade'
  } else if (EXPORT_ID_LIST.includes(id)) {
    keyName = 'export'
  } else if (IMPORT_ID_LIST.includes(id)) {
    keyName = 'import'
  }
  return keyName
}
