export const EXCHANGES = {
  ALL: 91,
  HOSE: 11,
  HNX: 46,
  // UPCOM: 47, // TODO: after BE fixed, will rollback
}

export const SIZE_BYES = {
  MARKET_CAPITALIZATION: 'MarketCap',
  TOTAL_VOLUME: 'TotalVolume',
  TOTAL_VALUE: 'TotalValue',
  FOREIGN_SELL_VOLUME: 'ForeignTotalSellVolume',
  FOREIGN_BUY_VOLUME: 'ForeignTotalBuyVolume',
  FOREIGN_SELL_VALUE: 'ForeignTotalSellValue',
  FOREIGN_BUY_VALUE: 'ForeignTotalBuyValue',
}

export const CRITERIAS = {
  '1_DAY_PERFORMANCE': 'OneDayPerformance',
  '1_WEEK_PERFORMANCE': 'OneWeekPerformance',
  '1_MONTH_PERFORMANCE': 'OneMonthPerformance',
  '3_MONTHS_PERFORMANCE': 'ThreeMonthsPerformance',
  '6_MONTHS_PERFORMANCE': 'SixMonthsPerformance',
  '1_YEAR_PERFORMANCE': 'OneYearPerformance',
  YTD_PERFORMANCE: 'YTDPerformance',
  RELATIVE_VOLUME: 'RelativeVolume',
  'P/E': 'PE',
  'P/B': 'PB',
}

export const MAP_LIST_TICKER = {
  TICKER: 'name',
  PRICE: 'matchPrice',
  CRITERIA: 'displayValue',
  SIZE_BY: 'value',
}
