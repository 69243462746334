import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  changeOrgIds,
  changeSelectedWL,
  selectOrgIds,
} from './header/store/slice'
import { changeResponseOrgIds, selectResponseOrgIds } from './store/slice'

const DeleteOrganization = ({ organizationId }) => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const orgIds = useSelector(selectOrgIds)
  const responseOrgIds = useSelector(selectResponseOrgIds)

  const handleClick = () => {
    dispatch(changeOrgIds(orgIds.filter((id) => id !== organizationId)))
    dispatch(
      changeResponseOrgIds(
        responseOrgIds.filter((id) => id !== organizationId),
      ),
    )
    dispatch(changeSelectedWL(null))
  }

  return organizationId !== basicInfo.organizationId ? (
    <div className="cursor-pointer">
      <Span onClick={handleClick} style={{ fontSize: 7, color: '#e5e5e5' }}>
        <i className="icon-delete-stroke" />
      </Span>
    </div>
  ) : (
    <div />
  )
}

export default DeleteOrganization
