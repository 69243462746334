import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { LIST_TYPE } from '../../common/constants'
import { formatEquationData, formatListTypeData } from '../../common/helpers'
import {
  getFormatDataQueryBySecurities,
  getFormatDataQueryByTime,
} from '../../common/helpers/dashboardApiParamsAndResponseHelpers'
import { CHART_PARAMETERS_DEFAULT, VIEW_TYPE } from '../constants'
import { setSyncTreeSecuritiesIndicatorsBySecurities } from '../helper'
import {
  getDataAggregate,
  getDataCompany,
  getDataCount,
  getDataEconomy,
  getDataIndex,
  getDataQuery,
  getDataSector,
  postCharts,
  updateCharts,
} from './thunk'

const initialState = {
  loading: {
    [LIST_TYPE.COMPANIES]: false,
    [LIST_TYPE.INDICES]: false,
    [LIST_TYPE.SECTOR]: false,
    [LIST_TYPE.ECONOMY]: false,
    equation: false,
  },
  dataChartByTime: [],
  dataChartBySecurities: {},
  dataChartEquation: {},
  chartTabs: {},
  chartTabActive: null,
  chartActiveID: null,
  securitiesEconomyById: {},
  securitiesEconomyChildren: [],
  equationChange: null,
  isPinTooltip: false,
  clickedItems: {},
  addChartLoading: false,
  editChartLoading: false,
  callChartAllDB: true,
}

export const slice = createSlice({
  name: 'financialChart/create-chart',
  initialState,
  reducers: {
    changeViewType(state, action) {
      state.chartTabs[state.chartTabActive].viewType = action.payload
    },
    changeTimeFrequency(state, action) {
      state.chartTabs[state.chartTabActive].timeFrequency = action.payload
    },
    changeTimeRange(state, action) {
      state.chartTabs[state.chartTabActive].timeRange = action.payload
    },
    changeTimeRangeDate(state, action) {
      state.chartTabs[state.chartTabActive].timeRangeDate = action.payload
    },
    changeTreeSecuritiesIndicator: (state, action) => {
      state.chartTabs[state.chartTabActive].treeSecuritiesIndicator =
        action.payload
    },
    changeTypeChart: (state, action) => {
      state.chartTabs[state.chartTabActive].typeChart = action.payload
    },
    changeShowTable: (state) => {
      state.chartTabs[state.chartTabActive].isShowTable =
        !state.chartTabs[state.chartTabActive].isShowTable
    },
    changeSettings: (state, action) => {
      state.chartTabs[state.chartTabActive].settings = {
        ...(state.chartTabs[state.chartTabActive].settings ||
          CHART_PARAMETERS_DEFAULT.settings),
        ...action.payload,
      }
    },
    changeStateNameChart: (state, action) => {
      state.chartTabs[state.chartTabActive].nameChart = action.payload
    },
    changeEquationChange: (state, action) => {
      state.equationChange = action.payload
    },
    changeFullEquations: (state, action) => {
      state.chartTabs[state.chartTabActive].equations = action.payload
    },
    changeEquations: (state, action) => {
      const { key, value } = action.payload
      state.chartTabs[state.chartTabActive].equations[key] = value
      state.equationChange = value
    },
    removeEquation: (state, action) => {
      const equations = state.chartTabs[state.chartTabActive].equations
      const newDataChartEquation = {}
      state.chartTabs[state.chartTabActive].equations = Object.keys(equations)
        .filter((key) => key !== action.payload)
        .reduce((obj, key, index) => {
          const oldOrder = equations[key].order
          newDataChartEquation[index] = state.dataChartEquation[oldOrder]
          return {
            ...obj,
            [index]: { ...equations[key], order: index },
          }
        }, {})
      state.dataChartEquation = newDataChartEquation
    },
    changeChartTabSecurities: (state, action) => {
      state.chartTabs[state.chartTabActive].securities = {
        ...state.chartTabs[state.chartTabActive].securities,
        ...action.payload,
      }
    },
    changeChartTabIndicatorSelected: (state, action) => {
      state.chartTabs[state.chartTabActive].indicatorSelected = {
        ...state.chartTabs[state.chartTabActive].indicatorSelected,
        ...action.payload,
      }
    },
    changeDetailTreeSecuritiesIndicator: (state, action) => {
      const {
        typeSecurities,
        securitiesId,
        indexIndicator,
        keyUpdate,
        valueUpdate,
      } = action.payload
      if (typeSecurities === LIST_TYPE.ECONOMY) {
        state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
          typeSecurities
        ][securitiesId][keyUpdate] = valueUpdate
      } else {
        state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
          typeSecurities
        ][securitiesId][indexIndicator][keyUpdate] = valueUpdate
      }
    },
    changeFullTreeSecuritiesEconomy: (state, action) => {
      const { securitiesId, data } = action.payload

      state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
        LIST_TYPE.ECONOMY
      ][securitiesId] = {
        ...state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
          LIST_TYPE.ECONOMY
        ][securitiesId],
        ...data,
      }
    },
    changeFullTreeSecuritiesIndicatorByTime: (state, action) => {
      const { typeSecurities, securitiesId, indexIndicator, data } =
        action.payload
      const { dataFormatType, decimalPlace, multiplier, numberPeriod } = data
      const securities =
        state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
          typeSecurities
        ]

      Object.keys(securities).forEach((id) => {
        if (+id === securitiesId) {
          state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
            typeSecurities
          ][securitiesId][indexIndicator] = {
            ...state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
              typeSecurities
            ][securitiesId][indexIndicator],
            ...data,
          }
        } else {
          state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
            typeSecurities
          ][id][indexIndicator] = {
            ...state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
              typeSecurities
            ][id][indexIndicator],
            dataFormatType,
            decimalPlace,
            multiplier,
            numberPeriod,
          }
        }
      })
    },
    changeFullTreeSecuritiesIndicatorBySecurities: (state, action) => {
      if (action.payload) {
        setSyncTreeSecuritiesIndicatorsBySecurities(state, action)
      } else {
        Object.keys(
          state.chartTabs[state.chartTabActive].treeSecuritiesIndicator,
        ).forEach((typeSecurities) => {
          let data = null
          Object.keys(
            state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
              typeSecurities
            ],
          ).forEach((securityKey) => {
            if (!data) {
              data =
                state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
                  typeSecurities
                ][securityKey]
            } else {
              state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
                typeSecurities
              ][securityKey] = [...data]
            }
          })
        })
      }
    },
    changSyncTreeSecuritiesIndicator: (state, action) => {
      if (state.chartTabs[state.chartTabActive]) {
        Object.values(
          state.chartTabs[state.chartTabActive].treeSecuritiesIndicator,
        ).forEach((obj) =>
          Object.values(obj).forEach((obj2) => {
            if (Array.isArray(obj2)) {
              obj2.forEach((item) => {
                item[action.payload.keyUpdate] = action.payload.value
              })
            } else {
              obj2[action.payload.keyUpdate] = action.payload.value
            }
          }),
        )
      }
    },
    changSyncEquations: (state, action) => {
      if (state.chartTabs[state.chartTabActive]) {
        Object.values(state.chartTabs[state.chartTabActive].equations).forEach(
          (obj) => (obj[action.payload.keyUpdate] = action.payload.value),
        )
      }
    },
    changeChartTabActive: (state, action) => {
      state.chartTabActive = action.payload
    },
    changeChartTabActiveData: (state, action) => {
      state.chartTabs[state.chartTabActive] = {
        ...state.chartTabs[state.chartTabActive],
        ...action.payload,
      }
    },
    changeChartTabsData: (state, action) => {
      state.chartTabs = { ...state.chartTabs, ...action.payload }
    },
    changeEquationLoading: (state, action) => {
      state.loading.equation = action.payload
    },
    removeChartTabsData: (state, action) => {
      const arrChartTabs = Object.values(state.chartTabs).filter(
        (_, index) => index + 1 !== action.payload,
      )
      state.chartTabs = arrChartTabs.reduce(
        (obj, cur, index) => ({ ...obj, [index + 1]: cur }),
        {},
      )
    },
    resetChartTabData: (state, action) => {
      state.chartTabs = {}
    },
    resetDataChart: (state) => {
      state.dataChartByTime = []
      state.dataChartBySecurities = {}
    },
    resetDataChartEquation: (state) => {
      state.dataChartEquation = {}
    },
    changeListLegendCustom: (state, action) => {
      state.chartTabs[state.chartTabActive].listLegendCustom = {
        ...state.chartTabs[state.chartTabActive].listLegendCustom,
        ...action.payload,
      }
    },
    changeStyleSetting: (state, action) => {
      state.chartTabs[state.chartTabActive].styleSetting = {
        ...state.chartTabs[state.chartTabActive].styleSetting,
        ...action.payload,
      }
    },
    changeListYAxisCustom: (state, action) => {
      state.chartTabs[state.chartTabActive].listYAxisCustom = {
        ...state.chartTabs[state.chartTabActive].listYAxisCustom,
        ...action.payload,
      }
    },
    changeInListYAxisCustom: (state, action) => {
      const { replaceId, updateId } = action.payload
      const valueConvertString = JSON.stringify(
        state.chartTabs[state.chartTabActive].listYAxisCustom,
      )
      const result = valueConvertString.replaceAll(replaceId, updateId)
      state.chartTabs[state.chartTabActive].listYAxisCustom = JSON.parse(result)
    },
    changeDataChartBySecurities: (state, action) => {
      state.dataChartBySecurities = action.payload
    },
    changeDataChartByTime: (state, action) => {
      state.dataChartByTime = action.payload
    },
    handleIsPinTooltip: (state, action) => {
      state.isPinTooltip = action.payload
    },
    handleClickedItems: (state, action) => {
      const { clickPosition } = action.payload
      const { activeTooltipIndex } = clickPosition
      if (state.clickedItems[activeTooltipIndex]) {
        delete state.clickedItems[activeTooltipIndex]
      } else {
        state.clickedItems[activeTooltipIndex] = action.payload
      }
    },
    removeAllTooltip: (state) => {
      state.clickedItems = {}
    },
    resetDefault: (state) => {
      Object.keys(initialState).forEach((key) => {
        if (key !== 'chartTabs' && key !== 'chartTabActive') {
          state[key] = initialState[key]
        }
      })
    },
    resetChart: (state) => {
      state.chartTabs[state.chartTabActive] = {
        ...CHART_PARAMETERS_DEFAULT,
        nameChart: state.chartTabs[state.chartTabActive].nameChart,
      }
    },
    setChartActiveID: (state, action) => {
      state.chartActiveID = action.payload
    },

    changeCallChartAllDB: (state, action) => {
      state.callChartAllDB = action.payload
    },
  },
  extraReducers: (builder) => {
    //companies
    builder.addCase(getDataCompany.pending, (state) => {
      state.loading[LIST_TYPE.COMPANIES] = true
    })
    builder.addCase(getDataCompany.fulfilled, (state, action) => {
      const viewType = action.meta.arg.ViewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const result = formatListTypeData(action.payload)
        state.dataChartByTime = [...state.dataChartByTime, ...result]
      } else {
        state.dataChartBySecurities = {
          ...state.dataChartBySecurities,
          ...action.payload,
        }
      }
      state.loading[LIST_TYPE.COMPANIES] = false
    })
    builder.addCase(getDataCompany.rejected, (state) => {
      state.loading[LIST_TYPE.COMPANIES] = false
    })
    //indices
    builder.addCase(getDataIndex.pending, (state) => {
      state.loading[LIST_TYPE.INDICES] = true
    })
    builder.addCase(getDataIndex.fulfilled, (state, action) => {
      const viewType = action.meta.arg.ViewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const result = formatListTypeData(action.payload)
        state.dataChartByTime = [...state.dataChartByTime, ...result]
      } else {
        state.dataChartBySecurities = {
          ...state.dataChartBySecurities,
          ...action.payload,
        }
      }
      state.loading[LIST_TYPE.INDICES] = false
    })
    builder.addCase(getDataIndex.rejected, (state) => {
      state.loading[LIST_TYPE.INDICES] = false
    })
    //sector
    builder.addCase(getDataSector.pending, (state) => {
      state.loading[LIST_TYPE.SECTOR] = true
    })
    builder.addCase(getDataSector.fulfilled, (state, action) => {
      const viewType = action.meta.arg.ViewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const result = formatListTypeData(action.payload)
        state.dataChartByTime = [...state.dataChartByTime, ...result]
      } else {
        state.dataChartBySecurities = {
          ...state.dataChartBySecurities,
          ...action.payload,
        }
      }
      state.loading[LIST_TYPE.SECTOR] = false
    })
    builder.addCase(getDataSector.rejected, (state) => {
      state.loading[LIST_TYPE.SECTOR] = false
    })
    //economy
    builder.addCase(getDataEconomy.pending, (state) => {
      state.loading[LIST_TYPE.ECONOMY] = true
    })
    builder.addCase(getDataEconomy.fulfilled, (state, action) => {
      const viewType = action.meta.arg.ViewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const result = formatListTypeData(action.payload)
        state.dataChartByTime = [...state.dataChartByTime, ...result]
      } else {
        state.dataChartBySecurities = {
          ...state.dataChartBySecurities,
          ...action.payload,
        }
      }
      state.loading[LIST_TYPE.ECONOMY] = false
    })
    builder.addCase(getDataEconomy.rejected, (state) => {
      state.loading[LIST_TYPE.ECONOMY] = false
    })
    //query
    builder.addCase(getDataQuery.pending, (state) => {
      Object.keys(state.loading).forEach((key) => (state.loading[key] = true))
    })
    builder.addCase(getDataQuery.fulfilled, (state, action) => {
      const viewType = action.meta.arg.viewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const result = getFormatDataQueryByTime(action.payload)
        state.dataChartByTime = [...state.dataChartByTime, ...result]
      } else {
        const result = getFormatDataQueryBySecurities(action.payload)
        state.dataChartBySecurities = {
          ...state.dataChartBySecurities,
          ...result,
        }
      }
      Object.keys(state.loading).forEach((key) => (state.loading[key] = false))
    })
    builder.addCase(getDataQuery.rejected, (state) => {
      Object.keys(state.loading).forEach((key) => (state.loading[key] = false))
    })
    // Equation Aggregate Sum/Avg
    builder.addCase(getDataAggregate.fulfilled, (state, action) => {
      const viewType = action.meta.arg.viewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const order = action.meta.arg.order
        const equationId = action.meta.arg.equationId
        state.dataChartEquation[order] = formatEquationData(
          action.payload,
          equationId,
        )
      } else {
        state.dataChartEquation = {
          ...state.dataChartEquation,
          ...action.payload,
        }
      }
      state.equationChange = null
    })
    // Equation Count
    builder.addCase(getDataCount.fulfilled, (state, action) => {
      const viewType = action.meta.arg.viewType
      if (viewType === VIEW_TYPE.BY_TIME) {
        const order = action.meta.arg.order
        const equationId = action.meta.arg.equationId
        state.dataChartEquation[order] = formatEquationData(
          action.payload,
          equationId,
        )
      } else {
        state.dataChartEquation = {
          ...state.dataChartEquation,
          ...action.payload,
        }
      }
      state.equationChange = null
    })
    // Post chart
    builder.addCase(postCharts.pending, (state) => {
      state.addChartLoading = true
    })
    builder.addCase(postCharts.fulfilled, (state) => {
      state.addChartLoading = false
    })
    builder.addCase(postCharts.rejected, (state) => {
      state.addChartLoading = false
    })
    // Update chart
    builder.addCase(updateCharts.pending, (state) => {
      state.editChartLoading = true
    })
    builder.addCase(updateCharts.fulfilled, (state) => {
      state.editChartLoading = false
    })
    builder.addCase(updateCharts.rejected, (state) => {
      state.editChartLoading = false
    })
  },
})

export const {
  changeViewType,
  changeTimeFrequency,
  changeTimeRange,
  changeTreeSecuritiesIndicator,
  changeDetailTreeSecuritiesIndicator,
  changeFullTreeSecuritiesEconomy,
  changeFullTreeSecuritiesIndicatorByTime,
  changeFullTreeSecuritiesIndicatorBySecurities,
  changeTypeChart,
  changeStateNameChart,
  resetDataChart,
  changSyncTreeSecuritiesIndicator,
  changSyncEquations,
  changeChartTabActive,
  changeChartTabActiveData,
  changeChartTabsData,
  changeChartTabSecurities,
  changeChartTabIndicatorSelected,
  changeTimeRangeDate,
  changeFullEquations,
  changeEquations,
  changeEquationChange,
  changeEquationLoading,
  changeShowTable,
  changeSettings,
  removeEquation,
  resetChartTabData,
  resetDataChartEquation,
  changeListLegendCustom,
  changeStyleSetting,
  changeListYAxisCustom,
  changeDataChartBySecurities,
  changeDataChartByTime,
  handleIsPinTooltip,
  handleClickedItems,
  removeAllTooltip,
  resetDefault,
  removeChartTabsData,
  resetChart,
  changeInListYAxisCustom,
  setChartActiveID,
  changeCallChartAllDB,
} = slice.actions

export const selectViewType = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.viewType
}
export const selectTimeFrequency = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.timeFrequency
}
export const selectTimeRange = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.timeRange
}
export const selectTimeRangeDate = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.timeRangeDate
}
export const selectLoading = (state) => state[slice.name].loading
export const selectTreeSecuritiesIndicator = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.treeSecuritiesIndicator
}
export const selectDataChartByTime = (state) =>
  state[slice.name].dataChartByTime
export const selectDataChartBySecurities = (state) =>
  state[slice.name].dataChartBySecurities
export const selectTypeChart = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.typeChart
}
export const selectShowTable = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.isShowTable
}
export const selectSettings = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.settings
}
export const selectNameChart = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.nameChart
}
export const selectEquations = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.equations
}
export const selectEquationChange = (state) => state[slice.name].equationChange
export const selectDataChartEquation = (state) => {
  return state[slice.name].dataChartEquation
}
export const selectChartTabSecurities = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.securities
}
export const selectChartTabIndicatorSelected = (type) => (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return type && state[slice.name].chartTabs[chartTabActive].indicatorSelected
    ? state[slice.name].chartTabs[chartTabActive].indicatorSelected[type]
    : state[slice.name].chartTabs[chartTabActive].indicatorSelected
}
export const selectChartTabActive = (state) => state[slice.name].chartTabActive
export const selectChartTabActiveData = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]
}
export const selectChartTabsData = (state) => state[slice.name].chartTabs
export const selectSecuritiesEconomyChildren = (state) =>
  state[slice.name].securitiesEconomyChildren
export const selectListLegendCustom = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.listLegendCustom
}
export const selectStyleSetting = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.styleSetting
}
export const selectYAxisCustom = (state) => {
  const chartTabActive = state[slice.name].chartTabActive
  return state[slice.name].chartTabs[chartTabActive]?.listYAxisCustom
}
export const selectIsPinTooltip = (state) => state[slice.name].isPinTooltip
export const selectTooltipItems = (state) => state[slice.name].clickedItems
export const selectTooltipItemById = (id) => (state) =>
  state[slice.name].clickedItems[id]
export const selectAddEditChartLoading = (state) =>
  state[slice.name].addChartLoading || state[slice.name].editChartLoading
export const selectChartActiveID = (state) =>
  state[slice.name].addChartLoading || state[slice.name].chartActiveID
export const selectChartAllDB = (state) => state[slice.name].callChartAllDB

register(slice.name, slice.reducer)
