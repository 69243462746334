import { TRANSACTION_TYPES } from '../../constants'

export const tableHeadTab = {
  LOCAL_INDIVIDUAL: 'localIndividual',
  FOREIGN_INDIVIDUAL: 'foreignIndividual',
  LOCAL_INSTITUTION: 'localInstitution',
  FOREIGN_INSTITUTION: 'foreignInstitution',
}

export const tableHeadColDummy = 'dummyTh'

const localIndividualFields = {
  LOCAL_INDI_BUY_VOL: 'localIndividualBuyVolume',
  LOCAL_INDI_BUY_VAL: 'localIndividualBuyValue',
  LOCAL_INDI_SELL_VOL: 'localIndividualSellVolume',
  LOCAL_INDI_SELL_VAL: 'localIndividualSellValue',
  LOCAL_INDI_NET_VOL: 'localIndividualNetVolume',
  LOCAL_INDI_NET_VAL: 'localIndividualNetValue',
  LOCAL_INDI_BUY_MATCH_VOL: 'localIndividualBuyMatchVolume',
  LOCAL_INDI_BUY_MATCH_VAL: 'localIndividualBuyMatchValue',
  LOCAL_INDI_SELL_MATCH_VOL: 'localIndividualSellMatchVolume',
  LOCAL_INDI_SELL_MATCH_VAL: 'localIndividualSellMatchValue',
  LOCAL_INDI_NET_MATCH_VOL: 'localIndividualNetMatchVolume',
  LOCAL_INDI_NET_MATCH_VAL: 'localIndividualNetMatchValue',
  LOCAL_INDI_BUY_DEAL_VOL: 'localIndividualBuyDealVolume',
  LOCAL_INDI_BUY_DEAL_VAL: 'localIndividualBuyDealValue',
  LOCAL_INDI_SELL_DEAL_VOL: 'localIndividualSellDealVolume',
  LOCAL_INDI_SELL_DEAL_VAL: 'localIndividualSellDealValue',
  LOCAL_INDI_NET_DEAL_VOL: 'localIndividualNetDealVolume',
  LOCAL_INDI_NET_DEAL_VAL: 'localIndividualNetDealValue',
}

const foreignIndividualFields = {
  FOREIGN_INDI_BUY_VOL: 'foreignIndividualBuyVolume',
  FOREIGN_INDI_BUY_VAL: 'foreignIndividualBuyValue',
  FOREIGN_INDI_SELL_VOL: 'foreignIndividualSellVolume',
  FOREIGN_INDI_SELL_VAL: 'foreignIndividualSellValue',
  FOREIGN_INDI_NET_VOL: 'foreignNetVolumeTotal',
  FOREIGN_INDI_NET_VAL: 'foreignNetValueTotal',
  FOREIGN_INDI_BUY_MATCH_VOL: 'foreignIndividualBuyMatchVolume',
  FOREIGN_INDI_BUY_MATCH_VAL: 'foreignIndividualBuyMatchValue',
  FOREIGN_INDI_SELL_MATCH_VOL: 'foreignIndividualSellMatchVolume',
  FOREIGN_INDI_SELL_MATCH_VAL: 'foreignIndividualSellMatchValue',
  FOREIGN_INDI_NET_MATCH_VOL: 'foreignIndividualNetMatchVolume',
  FOREIGN_INDI_NET_MATCH_VAL: 'foreignIndividualNetMatchValue',
  FOREIGN_INDI_BUY_DEAL_VOL: 'foreignIndividualBuyDealVolume',
  FOREIGN_INDI_BUY_DEAL_VAL: 'foreignIndividualBuyDealValue',
  FOREIGN_INDI_SELL_DEAL_VOL: 'foreignIndividualSellDealVolume',
  FOREIGN_INDI_SELL_DEAL_VAL: 'foreignIndividualSellDealValue',
  FOREIGN_INDI_NET_DEAL_VOL: 'foreignIndividualNetDealVolume',
  FOREIGN_INDI_NET_DEAL_VAL: 'foreignIndividualNetDealValue',
}

const localInstitutionalFields = {
  LOCAL_INSTI_BUY_VOL: 'localInstitutionalBuyVolume',
  LOCAL_INSTI_BUY_VAL: 'localInstitutionalBuyValue',
  LOCAL_INSTI_SELL_VOL: 'localInstitutionalSellVolume',
  LOCAL_INSTI_SELL_VAL: 'localInstitutionalSellValue',
  LOCAL_INSTI_NET_VOL: 'localInstitutionalNetVolume',
  LOCAL_INSTI_NET_VAL: 'localInstitutionalNetValue',
  LOCAL_INSTI_BUY_MATCH_VOL: 'localInstitutionalBuyMatchVolume',
  LOCAL_INSTI_BUY_MATCH_VAL: 'localInstitutionalBuyMatchValue',
  LOCAL_INSTI_SELL_MATCH_VOL: 'localInstitutionalSellMatchVolume',
  LOCAL_INSTI_SELL_MATCH_VAL: 'localInstitutionalSellMatchValue',
  LOCAL_INSTI_NET_MATCH_VOL: 'localInstitutionalNetMatchVolume',
  LOCAL_INSTI_NET_MATCH_VAL: 'localInstitutionalNetMatchValue',
  LOCAL_INSTI_BUY_DEAL_VOL: 'localInstitutionalBuyDealVolume',
  LOCAL_INSTI_BUY_DEAL_VAL: 'localInstitutionalBuyDealValue',
  LOCAL_INSTI_SELL_DEAL_VOL: 'localInstitutionalSellDealVolume',
  LOCAL_INSTI_SELL_DEAL_VAL: 'localInstitutionalSellDealValue',
  LOCAL_INSTI_NET_DEAL_VOL: 'localInstitutionalNetDealVolume',
  LOCAL_INSTI_NET_DEAL_VAL: 'localInstitutionalNetDealValue',
}

const foreignInstitutionalFields = {
  FOREIGN_INSTI_BUY_VOL: 'foreignInstitutionalBuyVolume',
  FOREIGN_INSTI_BUY_VAL: 'foreignInstitutionalBuyValue',
  FOREIGN_INSTI_SELL_VOL: 'foreignInstitutionalSellVolume',
  FOREIGN_INSTI_SELL_VAL: 'foreignInstitutionalSellValue',
  FOREIGN_INSTI_NET_VOL: 'foreignInstitutionalNetVolume',
  FOREIGN_INSTI_NET_VAL: 'foreignInstitutionalNetValue',
  FOREIGN_INSTI_BUY_MATCH_VOL: 'foreignInstitutionalBuyMatchVolume',
  FOREIGN_INSTI_BUY_MATCH_VAL: 'foreignInstitutionalBuyMatchValue',
  FOREIGN_INSTI_SELL_MATCH_VOL: 'foreignInstitutionalSellMatchVolume',
  FOREIGN_INSTI_SELL_MATCH_VAL: 'foreignInstitutionalSellMatchValue',
  FOREIGN_INSTI_NET_MATCH_VOL: 'foreignInstitutionalNetMatchVolume',
  FOREIGN_INSTI_NET_MATCH_VAL: 'foreignInstitutionalNetMatchValue',
  FOREIGN_INSTI_BUY_DEAL_VOL: 'foreignInstitutionalBuyDealVolume',
  FOREIGN_INSTI_BUY_DEAL_VAL: 'foreignInstitutionalBuyDealValue',
  FOREIGN_INSTI_SELL_DEAL_VOL: 'foreignInstitutionalSellDealVolume',
  FOREIGN_INSTI_SELL_DEAL_VAL: 'foreignInstitutionalSellDealValue',
  FOREIGN_INSTI_NET_DEAL_VOL: 'foreignInstitutionalNetDealVolume',
  FOREIGN_INSTI_NET_DEAL_VAL: 'foreignInstitutionalNetDealValue',
}

export const priceDataTableCol = {
  [tableHeadTab.LOCAL_INDIVIDUAL]: {
    DATE: 'tradingdateId',
    ...localIndividualFields,
  },
  [tableHeadTab.FOREIGN_INDIVIDUAL]: {
    DATE: 'tradingdateId',
    ...foreignIndividualFields,
  },
  [tableHeadTab.LOCAL_INSTITUTION]: {
    DATE: 'tradingdateId',
    ...localInstitutionalFields,
  },
  [tableHeadTab.FOREIGN_INSTITUTION]: {
    DATE: 'tradingdateId',
    ...foreignInstitutionalFields,
  },
}

export const tableHeadRowTop = {
  [tableHeadTab.LOCAL_INDIVIDUAL]: Object.values(localIndividualFields).reduce(
    (obj, key) => ({ ...obj, [key]: ['localIndividual'] }),
    {},
  ),
  [tableHeadTab.FOREIGN_INDIVIDUAL]: Object.values(
    foreignIndividualFields,
  ).reduce((obj, key) => ({ ...obj, [key]: ['foreignIndividual'] }), {}),
  [tableHeadTab.LOCAL_INSTITUTION]: Object.values(
    localInstitutionalFields,
  ).reduce((obj, key) => ({ ...obj, [key]: ['localInstitution'] }), {}),
  [tableHeadTab.FOREIGN_INSTITUTION]: Object.values(
    foreignInstitutionalFields,
  ).reduce((obj, key) => ({ ...obj, [key]: ['foreignInstitution'] }), {}),
}

export const tableHeaderColAttr = {
  [tableHeadTab.LOCAL_INDIVIDUAL]: [
    [
      {
        title: 'DATE',
        colId: 'tradingdateId',
        rowSpan: 2,
        thStyle: { textAlign: 'left' },
      },
      {
        title: 'LOCAL_INDIVIDUAL',
        colId: 'localIndividual',
        colSpan: 1,
      },
      {
        title: '',
        colId: 'dummyTh',
        colSpan: 17,
      },
    ],
    [
      {
        title: 'TOTAL_BUY_VOL',
        colId: 'localIndividualBuyVolume',
      },
      {
        title: 'TOTAL_BUY_VAL',
        colId: 'localIndividualBuyValue',
      },
      {
        title: 'TOTAL_SELL_VOL',
        colId: 'localIndividualSellVolume',
      },
      {
        title: 'TOTAL_SELL_VAL',
        colId: 'localIndividualSellValue',
      },
      {
        title: 'NET_TOTAL_VOL',
        colId: 'localIndividualNetVolume',
      },
      {
        title: 'NET_TOTAL_VAL',
        colId: 'localIndividualNetValue',
      },
      {
        title: 'MATCH_BUY_VOL',
        colId: 'localIndividualBuyMatchVolume',
      },
      {
        title: 'MATCH_BUY_VAL',
        colId: 'localIndividualBuyMatchValue',
      },
      {
        title: 'MATCH_SELL_VOL',
        colId: 'localIndividualSellMatchVolume',
      },
      {
        title: 'MATCH_SELL_VAL',
        colId: 'localIndividualSellMatchValue',
      },
      {
        title: 'MATCH_NET_VOL',
        colId: 'localIndividualNetMatchVolume',
      },
      {
        title: 'MATCH_NET_VAL',
        colId: 'localIndividualNetMatchValue',
      },
      {
        title: 'DEAL_BUY_VOL',
        colId: 'localIndividualBuyDealVolume',
      },
      {
        title: 'DEAL_BUY_VAL',
        colId: 'localIndividualBuyDealValue',
      },
      {
        title: 'DEAL_SELL_VOL',
        colId: 'localIndividualSellDealVolume',
      },
      {
        title: 'DEAL_SELL_VAL',
        colId: 'localIndividualSellDealValue',
      },
      {
        title: 'DEAL_NET_VOL',
        colId: 'localIndividualNetDealVolume',
      },
      {
        title: 'DEAL_NET_VAL',
        colId: 'localIndividualNetDealValue',
      },
    ],
  ],
  [tableHeadTab.FOREIGN_INDIVIDUAL]: [
    [
      {
        title: 'DATE',
        colId: 'tradingdateId',
        rowSpan: 2,
        thStyle: { textAlign: 'left' },
      },
      {
        title: 'FOREIGN_INDIVIDUAL',
        colId: 'foreignIndividual',
        colSpan: 1,
      },
      {
        title: '',
        colId: 'dummyTh',
        colSpan: 17,
      },
    ],
    [
      {
        title: 'TOTAL_BUY_VOL',
        colId: 'foreignIndividualBuyVolume',
      },
      {
        title: 'TOTAL_BUY_VAL',
        colId: 'foreignIndividualBuyValue',
      },
      {
        title: 'TOTAL_SELL_VOL',
        colId: 'foreignIndividualSellVolume',
      },
      {
        title: 'TOTAL_SELL_VAL',
        colId: 'foreignIndividualSellValue',
      },
      {
        title: 'NET_TOTAL_VOL',
        colId: 'foreignNetVolumeTotal',
      },
      {
        title: 'NET_TOTAL_VAL',
        colId: 'foreignNetValueTotal',
      },
      {
        title: 'MATCH_BUY_VOL',
        colId: 'foreignIndividualBuyMatchVolume',
      },
      {
        title: 'MATCH_BUY_VAL',
        colId: 'foreignIndividualBuyMatchValue',
      },
      {
        title: 'MATCH_SELL_VOL',
        colId: 'foreignIndividualSellMatchVolume',
      },
      {
        title: 'MATCH_SELL_VAL',
        colId: 'foreignIndividualSellMatchValue',
      },
      {
        title: 'MATCH_NET_VOL',
        colId: 'foreignIndividualNetMatchVolume',
      },
      {
        title: 'MATCH_NET_VAL',
        colId: 'foreignIndividualNetMatchValue',
      },
      {
        title: 'DEAL_BUY_VOL',
        colId: 'foreignIndividualBuyDealVolume',
      },
      {
        title: 'DEAL_BUY_VAL',
        colId: 'foreignIndividualBuyDealValue',
      },
      {
        title: 'DEAL_SELL_VOL',
        colId: 'foreignIndividualSellDealVolume',
      },
      {
        title: 'DEAL_SELL_VAL',
        colId: 'foreignIndividualSellDealValue',
      },
      {
        title: 'DEAL_NET_VOL',
        colId: 'foreignIndividualNetDealVolume',
      },
      {
        title: 'DEAL_NET_VAL',
        colId: 'foreignIndividualNetDealValue',
      },
    ],
  ],
  [tableHeadTab.LOCAL_INSTITUTION]: [
    [
      {
        title: 'DATE',
        colId: 'tradingdateId',
        rowSpan: 2,
        thStyle: { textAlign: 'left' },
      },
      {
        title: 'LOCAL_INSTITUTION',
        colId: 'localInstitution',
        colSpan: 1,
      },
      {
        title: '',
        colId: 'dummyTh',
        colSpan: 17,
      },
    ],
    [
      {
        title: 'TOTAL_BUY_VOL',
        colId: 'localInstitutionalBuyVolume',
      },
      {
        title: 'TOTAL_BUY_VAL',
        colId: 'localInstitutionalBuyValue',
      },
      {
        title: 'TOTAL_SELL_VOL',
        colId: 'localInstitutionalSellVolume',
      },
      {
        title: 'TOTAL_SELL_VAL',
        colId: 'localInstitutionalSellValue',
      },
      {
        title: 'NET_TOTAL_VOL',
        colId: 'localInstitutionalNetVolume',
      },
      {
        title: 'NET_TOTAL_VAL',
        colId: 'localInstitutionalNetValue',
      },
      {
        title: 'MATCH_BUY_VOL',
        colId: 'localInstitutionalBuyMatchVolume',
      },
      {
        title: 'MATCH_BUY_VAL',
        colId: 'localInstitutionalBuyMatchValue',
      },
      {
        title: 'MATCH_SELL_VOL',
        colId: 'localInstitutionalSellMatchVolume',
      },
      {
        title: 'MATCH_SELL_VAL',
        colId: 'localInstitutionalSellMatchValue',
      },
      {
        title: 'MATCH_NET_VOL',
        colId: 'localInstitutionalNetMatchVolume',
      },
      {
        title: 'MATCH_NET_VAL',
        colId: 'localInstitutionalNetMatchValue',
      },
      {
        title: 'DEAL_BUY_VOL',
        colId: 'localInstitutionalBuyDealVolume',
      },
      {
        title: 'DEAL_BUY_VAL',
        colId: 'localInstitutionalBuyDealValue',
      },
      {
        title: 'DEAL_SELL_VOL',
        colId: 'localInstitutionalSellDealVolume',
      },
      {
        title: 'DEAL_SELL_VAL',
        colId: 'localInstitutionalSellDealValue',
      },
      {
        title: 'DEAL_NET_VOL',
        colId: 'localInstitutionalNetDealVolume',
      },
      {
        title: 'DEAL_NET_VAL',
        colId: 'localInstitutionalNetDealValue',
      },
    ],
  ],
  [tableHeadTab.FOREIGN_INSTITUTION]: [
    [
      {
        title: 'DATE',
        colId: 'tradingdateId',
        rowSpan: 2,
        thStyle: { textAlign: 'left' },
      },
      {
        title: 'FOREIGN_INSTITUTION',
        colId: 'foreignInstitution',
        colSpan: 1,
      },
      {
        title: '',
        colId: 'dummyTh',
        colSpan: 17,
      },
    ],
    [
      {
        title: 'TOTAL_BUY_VOL',
        colId: 'foreignInstitutionalBuyVolume',
      },
      {
        title: 'TOTAL_BUY_VAL',
        colId: 'foreignInstitutionalBuyValue',
      },
      {
        title: 'TOTAL_SELL_VOL',
        colId: 'foreignInstitutionalSellVolume',
      },
      {
        title: 'TOTAL_SELL_VAL',
        colId: 'foreignInstitutionalSellValue',
      },
      {
        title: 'NET_TOTAL_VOL',
        colId: 'foreignInstitutionalNetVolume',
      },
      {
        title: 'NET_TOTAL_VAL',
        colId: 'foreignInstitutionalNetValue',
      },
      {
        title: 'MATCH_BUY_VOL',
        colId: 'foreignInstitutionalBuyMatchVolume',
      },
      {
        title: 'MATCH_BUY_VAL',
        colId: 'foreignInstitutionalBuyMatchValue',
      },
      {
        title: 'MATCH_SELL_VOL',
        colId: 'foreignInstitutionalSellMatchVolume',
      },
      {
        title: 'MATCH_SELL_VAL',
        colId: 'foreignInstitutionalSellMatchValue',
      },
      {
        title: 'MATCH_NET_VOL',
        colId: 'foreignInstitutionalNetMatchVolume',
      },
      {
        title: 'MATCH_NET_VAL',
        colId: 'foreignInstitutionalNetMatchValue',
      },
      {
        title: 'DEAL_BUY_VOL',
        colId: 'foreignInstitutionalBuyDealVolume',
      },
      {
        title: 'DEAL_BUY_VAL',
        colId: 'foreignInstitutionalBuyDealValue',
      },
      {
        title: 'DEAL_SELL_VOL',
        colId: 'foreignInstitutionalSellDealVolume',
      },
      {
        title: 'DEAL_SELL_VAL',
        colId: 'foreignInstitutionalSellDealValue',
      },
      {
        title: 'DEAL_NET_VOL',
        colId: 'foreignInstitutionalNetDealVolume',
      },
      {
        title: 'DEAL_NET_VAL',
        colId: 'foreignInstitutionalNetDealValue',
      },
    ],
  ],
}

export const tableFormatValue = {
  localIndividualBuyVolume: 10 ** 3,
  localIndividualBuyValue: 10 ** 6,
  localIndividualSellVolume: 10 ** 3,
  localIndividualSellValue: 10 ** 6,
  localIndividualNetVolume: 10 ** 3,
  localIndividualNetValue: 10 ** 6,
  localIndividualBuyMatchVolume: 10 ** 3,
  localIndividualSellMatchVolume: 10 ** 3,
  localIndividualBuyMatchValue: 10 ** 6,
  localIndividualSellMatchValue: 10 ** 6,
  localIndividualNetMatchVolume: 10 ** 3,
  localIndividualNetMatchValue: 10 ** 6,
  localIndividualBuyDealVolume: 10 ** 3,
  localIndividualSellDealVolume: 10 ** 3,
  localIndividualBuyDealValue: 10 ** 6,
  localIndividualSellDealValue: 10 ** 6,
  localIndividualNetDealVolume: 10 ** 3,
  localIndividualNetDealValue: 10 ** 6,
  foreignIndividualBuyVolume: 10 ** 3,
  foreignIndividualBuyValue: 10 ** 6,
  foreignIndividualSellVolume: 10 ** 3,
  foreignIndividualSellValue: 10 ** 6,
  foreignNetVolumeTotal: 10 ** 3,
  foreignNetValueTotal: 10 ** 6,
  foreignIndividualBuyMatchVolume: 10 ** 3,
  foreignIndividualSellMatchVolume: 10 ** 3,
  foreignIndividualBuyMatchValue: 10 ** 6,
  foreignIndividualSellMatchValue: 10 ** 6,
  foreignIndividualNetMatchVolume: 10 ** 3,
  foreignIndividualNetMatchValue: 10 ** 6,
  foreignIndividualBuyDealVolume: 10 ** 3,
  foreignIndividualSellDealVolume: 10 ** 3,
  foreignIndividualBuyDealValue: 10 ** 6,
  foreignIndividualSellDealValue: 10 ** 6,
  foreignIndividualNetDealVolume: 10 ** 3,
  foreignIndividualNetDealValue: 10 ** 6,
  localInstitutionalBuyVolume: 10 ** 3,
  localInstitutionalBuyValue: 10 ** 6,
  localInstitutionalSellVolume: 10 ** 3,
  localInstitutionalSellValue: 10 ** 6,
  localInstitutionalNetVolume: 10 ** 3,
  localInstitutionalNetValue: 10 ** 6,
  localInstitutionalBuyMatchVolume: 10 ** 3,
  localInstitutionalSellMatchVolume: 10 ** 3,
  localInstitutionalBuyMatchValue: 10 ** 6,
  localInstitutionalSellMatchValue: 10 ** 6,
  localInstitutionalNetMatchVolume: 10 ** 3,
  localInstitutionalNetMatchValue: 10 ** 6,
  localInstitutionalBuyDealVolume: 10 ** 3,
  localInstitutionalSellDealVolume: 10 ** 3,
  localInstitutionalBuyDealValue: 10 ** 6,
  localInstitutionalSellDealValue: 10 ** 6,
  localInstitutionalNetDealVolume: 10 ** 3,
  localInstitutionalNetDealValue: 10 ** 6,
  foreignInstitutionalBuyVolume: 10 ** 3,
  foreignInstitutionalBuyValue: 10 ** 6,
  foreignInstitutionalSellVolume: 10 ** 3,
  foreignInstitutionalSellValue: 10 ** 6,
  foreignInstitutionalNetVolume: 10 ** 3,
  foreignInstitutionalNetValue: 10 ** 6,
  foreignInstitutionalBuyMatchVolume: 10 ** 3,
  foreignInstitutionalSellMatchVolume: 10 ** 3,
  foreignInstitutionalBuyMatchValue: 10 ** 6,
  foreignInstitutionalSellMatchValue: 10 ** 6,
  foreignInstitutionalNetMatchVolume: 10 ** 3,
  foreignInstitutionalNetMatchValue: 10 ** 6,
  foreignInstitutionalBuyDealVolume: 10 ** 3,
  foreignInstitutionalSellDealVolume: 10 ** 3,
  foreignInstitutionalBuyDealValue: 10 ** 6,
  foreignInstitutionalSellDealValue: 10 ** 6,
  foreignInstitutionalNetDealVolume: 10 ** 3,
  foreignInstitutionalNetDealValue: 10 ** 6,
}

export const tableColByTransactionType = {
  [TRANSACTION_TYPES.ALL]: [
    'DATE',
    ...Object.keys(localIndividualFields),
    ...Object.keys(localInstitutionalFields),
    ...Object.keys(foreignIndividualFields),
    ...Object.keys(foreignInstitutionalFields),
  ],
  [TRANSACTION_TYPES.DEAL]: [
    'DATE',
    // Local Individual
    'LOCAL_INDI_BUY_DEAL_VOL',
    'LOCAL_INDI_BUY_DEAL_VAL',
    'LOCAL_INDI_SELL_DEAL_VOL',
    'LOCAL_INDI_SELL_DEAL_VAL',
    'LOCAL_INDI_NET_DEAL_VOL',
    'LOCAL_INDI_NET_DEAL_VAL',
    // Foreign Individual
    'FOREIGN_INDI_BUY_DEAL_VOL',
    'FOREIGN_INDI_BUY_DEAL_VAL',
    'FOREIGN_INDI_SELL_DEAL_VOL',
    'FOREIGN_INDI_SELL_DEAL_VAL',
    'FOREIGN_INDI_NET_DEAL_VOL',
    'FOREIGN_INDI_NET_DEAL_VAL',
    // Local Institutional
    'LOCAL_INSTI_BUY_DEAL_VOL',
    'LOCAL_INSTI_BUY_DEAL_VAL',
    'LOCAL_INSTI_SELL_DEAL_VOL',
    'LOCAL_INSTI_SELL_DEAL_VAL',
    'LOCAL_INSTI_NET_DEAL_VOL',
    'LOCAL_INSTI_NET_DEAL_VAL',
    // Foreign Institutional
    'FOREIGN_INSTI_BUY_DEAL_VOL',
    'FOREIGN_INSTI_BUY_DEAL_VAL',
    'FOREIGN_INSTI_SELL_DEAL_VOL',
    'FOREIGN_INSTI_SELL_DEAL_VAL',
    'FOREIGN_INSTI_NET_DEAL_VOL',
    'FOREIGN_INSTI_NET_DEAL_VAL',
  ],
  [TRANSACTION_TYPES.MATCH]: [
    'DATE',
    // Local Individual
    'LOCAL_INDI_BUY_MATCH_VOL',
    'LOCAL_INDI_BUY_MATCH_VAL',
    'LOCAL_INDI_SELL_MATCH_VOL',
    'LOCAL_INDI_SELL_MATCH_VAL',
    'LOCAL_INDI_NET_MATCH_VOL',
    'LOCAL_INDI_NET_MATCH_VAL',
    // Foreign Individual
    'FOREIGN_INDI_BUY_MATCH_VOL',
    'FOREIGN_INDI_BUY_MATCH_VAL',
    'FOREIGN_INDI_SELL_MATCH_VOL',
    'FOREIGN_INDI_SELL_MATCH_VAL',
    'FOREIGN_INDI_NET_MATCH_VOL',
    'FOREIGN_INDI_NET_MATCH_VAL',
    // Local Institutional
    'LOCAL_INSTI_BUY_MATCH_VOL',
    'LOCAL_INSTI_BUY_MATCH_VAL',
    'LOCAL_INSTI_SELL_MATCH_VOL',
    'LOCAL_INSTI_SELL_MATCH_VAL',
    'LOCAL_INSTI_NET_MATCH_VOL',
    'LOCAL_INSTI_NET_MATCH_VAL',
    // Foreign Institutional
    'FOREIGN_INSTI_BUY_MATCH_VOL',
    'FOREIGN_INSTI_BUY_MATCH_VAL',
    'FOREIGN_INSTI_SELL_MATCH_VOL',
    'FOREIGN_INSTI_SELL_MATCH_VAL',
    'FOREIGN_INSTI_NET_MATCH_VOL',
    'FOREIGN_INSTI_NET_MATCH_VAL',
  ],
}

export const tableHeaderColByTransactionType = {
  [TRANSACTION_TYPES.ALL]: [
    'TOTAL_BUY_VOL',
    'TOTAL_BUY_VAL',
    'TOTAL_SELL_VOL',
    'TOTAL_SELL_VAL',
    'NET_TOTAL_VOL',
    'NET_TOTAL_VAL',
    'MATCH_BUY_VOL',
    'MATCH_BUY_VAL',
    'MATCH_SELL_VOL',
    'MATCH_SELL_VAL',
    'MATCH_NET_VOL',
    'MATCH_NET_VAL',
    'DEAL_BUY_VOL',
    'DEAL_BUY_VAL',
    'DEAL_SELL_VOL',
    'DEAL_SELL_VAL',
    'DEAL_NET_VOL',
    'DEAL_NET_VAL',
  ],
  [TRANSACTION_TYPES.DEAL]: [
    'DEAL_BUY_VOL',
    'DEAL_BUY_VAL',
    'DEAL_SELL_VOL',
    'DEAL_SELL_VAL',
    'DEAL_NET_VOL',
    'DEAL_NET_VAL',
  ],
  [TRANSACTION_TYPES.MATCH]: [
    'MATCH_BUY_VOL',
    'MATCH_BUY_VAL',
    'MATCH_SELL_VOL',
    'MATCH_SELL_VAL',
    'MATCH_NET_VOL',
    'MATCH_NET_VAL',
  ],
}
