import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { I18n, Translate } from 'react-redux-i18n'
import SmartScreeningService from '../../../../core/services/tool/SmartScreeningService'
import DataExplorerService from '../../../../core/services/tool/dataExplorer/DataExplorerService'
import WatchlistService from '../../../../core/services/watchlist/WatchlistService'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  SHOW_POPUP_ERROR,
} from '../../../utils/EventEmitter'
import { handleExport } from '../../../utils/Export'
import { SET_TEMPLATES } from '../condition/GroupButton'
import { maxTemplate } from '../constant'

export const getQuickSelectIndicatorsThunk = createAsyncThunk(
  'tools/smartScreening/GET_QUICK_SELECT_INDICATORS',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SmartScreeningService.getQuickSelectIndicators(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getRatioIndicatorsThunk = createAsyncThunk(
  'tools/smartScreening/GET_RATIO_INDICATORS',
  async (data, { rejectWithValue }) => {
    try {
      const response = await SmartScreeningService.getRatioIndicators(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getFinancialStatementIndicatorsThunk = createAsyncThunk(
  'tools/smartScreening/GET_FINANCIAL_STATEMENT_INDICATORS',
  async (data, { rejectWithValue }) => {
    try {
      const response =
        await SmartScreeningService.getFinancialStatementIndicators(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue(false)
    } catch (err) {
      if (axios.isCancel(err)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTemplatesThunk = createAsyncThunk(
  'tools/smartScreening/GET_TEMPLATES',
  async (data, { rejectWithValue }) => {
    const response = await SmartScreeningService.getTemplates(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getTemplatesValidateThunk = createAsyncThunk(
  'tools/smartScreening/GET_TEMPLATES_VALIDATE',
  async (data) => {
    const response = await SmartScreeningService.getTemplates({
      PageSize: maxTemplate,
    })
    if (response.success) {
      EventEmitter.dispatch(SET_TEMPLATES, response.data)
    }
  },
)

export const renameTemplateThunk = createAsyncThunk(
  'watchlist/RENAME_TEMPLATE-',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SmartScreeningService.renameTemplate(data.params)
    dispatch(
      getTemplatesThunk({
        PageSize: maxTemplate,
        TemplateName: data.valueSearch,
      }),
    )
    if (!response.success) return rejectWithValue(response.errors)
  },
)

export const getScreeningDateThunk = createAsyncThunk(
  'tools/smartScreening/GET_SCREENING_DATE',
  async (data, { rejectWithValue }) => {
    const response = await SmartScreeningService.getScreeningDate(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getTemplateThunk = createAsyncThunk(
  'tools/smartScreening/GET_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SmartScreeningService.getTemplate(data)
    if (response.success) {
      dispatch(getResultThunk(response.data.parameters))
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getTypeInterimThunk = createAsyncThunk(
  'tools/smartScreening/GET_TYPE_INTERIM',
  async (data, { rejectWithValue }) => {
    const response = await SmartScreeningService.getTypeInterim(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const getResultThunk = createAsyncThunk(
  'tools/smartScreening/GET_RESULT',
  async (data, { rejectWithValue }) => {
    const response = await SmartScreeningService.getResult(data)
    if (response?.data?.errorMessage) {
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [response?.data?.errorMessage])
    } else if (response.success) {
      if (response.data.totalRecord === 0) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          <Translate value="tool.smartScreening.NO_MATCH_RESULT" />,
        ])
      }
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const saveTemplateThunk = createAsyncThunk(
  'tools/smartScreening/SAVE_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SmartScreeningService.saveTemplate(data)
    dispatch(getTemplatesThunk({ PageSize: maxTemplate }))
    dispatch(getTemplatesValidateThunk())
    if (!response.success) rejectWithValue(response.errors)
    else {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.smartScreening.CREATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      return response.data
    }
  },
)

export const updateTemplateThunk = createAsyncThunk(
  'tools/smartScreening/UPDATE_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SmartScreeningService.updateTemplate(data)
    dispatch(getTemplatesThunk({ PageSize: maxTemplate }))
    dispatch(getTemplatesValidateThunk())
    if (!response.success) rejectWithValue(response.errors)
    else {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.smartScreening.UPDATE_TEMPLATE_SUCCESS" />
          </div>
        </div>,
      ])
      return response.data
    }
  },
)

export const deleteTemplateThunk = createAsyncThunk(
  'tools/smartScreening/DELETE_TEMPLATE',
  async (data, { rejectWithValue, dispatch }) => {
    const response = await SmartScreeningService.deleteTemplate(data.params)
    dispatch(
      getTemplatesThunk({
        TemplateName: data.valueSearch,
        PageSize: maxTemplate,
      }),
    )
    dispatch(getTemplatesValidateThunk())
    if (!response.success) rejectWithValue(response.errors)
  },
)

export const exportResultThunk = createAsyncThunk(
  'tools/smartScreening/EXPORT_RESULT',
  async (data) => {
    const response = await SmartScreeningService.exportResult(data)
    handleExport(response.data, response.filename)
  },
)

export const getWatchListThunk = createAsyncThunk(
  'tools/smartScreening/GET_WATCH_LIST',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.getWatchlist(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const updateWatchlistThunk = createAsyncThunk(
  'tools/smartScreening/UPDATE_WATCH_LIST',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.updateWatchlist(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        I18n.t('tool.smartScreening.UPDATE_WATCHLIST_SUCCESS'),
      ])
    } else {
      rejectWithValue(response.errors)
    }
  },
)

export const createWatchlistThunk = createAsyncThunk(
  'tools/smartScreening/CREATE_WATCH_LIST',
  async (data, { rejectWithValue }) => {
    const response = await WatchlistService.createWatchlist(data)
    if (response.success) {
      EventEmitter.dispatch(
        BUTTON_TEXT_POPUP_ERROR,
        I18n.t('common.button.BUTTON_OK'),
      )
      EventEmitter.dispatch(ICON_STATUS_POPUP_ERROR, '')
      EventEmitter.dispatch(IS_SHOW_BUTTON_ERROR, false)
      EventEmitter.dispatch(SHOW_POPUP_ERROR, [
        <div className="mb-20">
          <div className="centerItems mb-20">
            <img src="/iconLike.svg" alt="Like" width={38} height={46} />
          </div>
          <div>
            <Translate value="tool.smartScreening.CREATE_WATCHLIST_SUCCESS" />
          </div>
        </div>,
      ])
    } else {
      rejectWithValue(response.errors)
    }
  },
)

export const getTotalRecord = createAsyncThunk(
  'tools/smartScreening/GET_TOTAL_RECORD',
  async (params, { rejectWithValue }) => {
    try {
      const res = await SmartScreeningService.getCount(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getWorkSpaceThunk = createAsyncThunk(
  'tools/smartScreening/GET_WORK_SPACE',
  async (data, { rejectWithValue }) => {
    const response = await DataExplorerService.getWorkSpace(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
