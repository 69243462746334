import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Z_INDEX } from '../constants/Common'
import EventEmitter, {
  BUTTON_TEXT_POPUP_ERROR,
  CALLBACK_CLOSE_SHOW_POPUP_ERROR,
  CALLBACK_SHOW_POPUP_ERROR,
  ICON_STATUS_POPUP_ERROR,
  IS_SHOW_BUTTON_ERROR,
  MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR,
  MODAL_BUTTON_STYLE_POPUP_ERROR,
  MODAL_STYLE_POPUP_ERROR,
  SHOW_POPUP_ERROR,
} from '../utils/EventEmitter'
import PopupAlert, { POPUP_ICON_STATUS } from './popup/PopupAlert'

const HandleErrorApi = () => {
  const dispatch = useDispatch()

  const [errors, setErrors] = useState(null)
  const [callback, setCallback] = useState(null)
  const [callbackClose, setCallbackClose] = useState(null)
  const [buttonText, setButtonText] = useState()
  const [iconStatus, setIconStatus] = useState(POPUP_ICON_STATUS.WARNING)
  const [isShowButton, setIsShowButton] = useState(true)
  const [modalStyle, setModalStyle] = useState({})
  const [buttonContainerStyle, setButtonContainerStyle] = useState({})
  const [buttonStyle, setButtonStyle] = useState({})

  useEffect(() => {
    EventEmitter.subscribe(SHOW_POPUP_ERROR, setErrors)
    EventEmitter.subscribe(BUTTON_TEXT_POPUP_ERROR, setButtonText)
    EventEmitter.subscribe(CALLBACK_SHOW_POPUP_ERROR, setCallback)
    EventEmitter.subscribe(CALLBACK_CLOSE_SHOW_POPUP_ERROR, setCallbackClose)
    EventEmitter.subscribe(ICON_STATUS_POPUP_ERROR, setIconStatus)
    EventEmitter.subscribe(IS_SHOW_BUTTON_ERROR, setIsShowButton)
    EventEmitter.subscribe(MODAL_STYLE_POPUP_ERROR, setModalStyle)
    EventEmitter.subscribe(
      MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR,
      setButtonContainerStyle,
    )
    EventEmitter.subscribe(MODAL_BUTTON_STYLE_POPUP_ERROR, setButtonStyle)
    return () => {
      EventEmitter.unsubscribe(SHOW_POPUP_ERROR, setErrors)
      EventEmitter.unsubscribe(BUTTON_TEXT_POPUP_ERROR, setButtonText)
      EventEmitter.unsubscribe(CALLBACK_SHOW_POPUP_ERROR, setCallback)
      EventEmitter.unsubscribe(
        CALLBACK_CLOSE_SHOW_POPUP_ERROR,
        setCallbackClose,
      )
      EventEmitter.unsubscribe(ICON_STATUS_POPUP_ERROR, setIconStatus)
      EventEmitter.unsubscribe(IS_SHOW_BUTTON_ERROR, setIsShowButton)
      EventEmitter.unsubscribe(MODAL_STYLE_POPUP_ERROR, setModalStyle)
      EventEmitter.unsubscribe(
        MODAL_BUTTON_CONTAINER_STYLE_POPUP_ERROR,
        setButtonContainerStyle,
      )
      EventEmitter.unsubscribe(MODAL_BUTTON_STYLE_POPUP_ERROR, setButtonStyle)
    }
  }, [])

  const handleClose = () => {
    setErrors(null)
    setButtonText()
    setIconStatus(POPUP_ICON_STATUS.WARNING)
    setIsShowButton(true)
    if (callback) {
      dispatch(callback)
    }
    setCallback(null)
    if (callbackClose) {
      callbackClose()
    }
    setCallbackClose(null)
  }

  return (
    <PopupAlert
      message={
        <div>
          {errors !== null &&
            errors?.map((message, index) => <div key={index}>{message}</div>)}
        </div>
      }
      isShow={errors !== null}
      handleClose={handleClose}
      zIndex={Z_INDEX.COMMON_CSS_OVERLAY + 2}
      zIndexOverlay={Z_INDEX.COMMON_CSS_OVERLAY + 1}
      buttonText={buttonText}
      iconStatus={iconStatus}
      isShowButton={isShowButton}
      modalStyle={modalStyle}
      buttonContainerStyle={buttonContainerStyle}
      buttonStyle={buttonStyle}
    />
  )
}

export default HandleErrorApi
