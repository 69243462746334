import { useDispatch, useSelector } from 'react-redux'
import { dashboardTypes, screenTypes } from '../../constants'
import { ReactComponent as IconMyDashboard } from '../../icon/icon-my-dashboard.svg'
import {
  changeDashboard,
  selectIsNewDashboard,
  selectMyDashboard,
} from '../../store/slice'
import { List } from './common/List'
import { RootButtonWithCollapse } from './common/RootButtonWithCollapse'

const MyDashboard = () => {
  const dispatch = useDispatch()
  const data = useSelector(selectMyDashboard)
  const isNewDashboard = useSelector(selectIsNewDashboard)

  const onClickItem = (data) => {
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
        data,
      }),
    )
  }

  return (
    data && (
      <div>
        <RootButtonWithCollapse
          data={data}
          renderIcon={() => <IconMyDashboard style={{ height: 11 }} />}
        >
          <List
            data={data.children}
            dashboardData={data}
            isNewDashboardGroup
            isNewDashboard={isNewDashboard}
            onClickItem={onClickItem}
          />
        </RootButtonWithCollapse>
      </div>
    )
  )
}

export default MyDashboard
