import { I18n } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { debounce } from '../../../utils/Common'
import style from './style.module.css'

const InputSearch = ({ value, onChange, appendStyle, placeholder, height }) => {
  return (
    <div className="form-search-inbox" style={appendStyle}>
      <Input
        value={value}
        type="text"
        style={{ height: height, backgroundColor: '#F2F2F2', color: '#4E4E4E' }}
        className={`search-inbox ${style.placeholder}`}
        placeholder={I18n.t(placeholder)}
        onChange={debounce((e) => onChange(e.target.value))}
      />
      <button type="button">
        <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
      </button>
    </div>
  )
}

InputSearch.propTypes = {}

export default InputSearch
