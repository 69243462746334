import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { getFontSize } from '../../utils/FontSize'
import { HandleClickOutside } from '../HandleClickOutside'
import Dropdown, { positionDropdown } from '../dropdown'
import { Input } from '../html/Input'
import { Span } from '../html/Span'
import TextEllipsis from '../textEllipsis'
import { getItemTextWidth } from './helper'
import style from './index.module.css'

export const VALUE_ADD_MORE = 'VALUE_ADD_MORE'
export const VALUE_ADD_MORE_PARENT = 'VALUE_ADD_MORE_PARENT'
export const VALUE_MORE = 'VALUE_MORE'
export const DELETE = 'DELETE'

const ItemParentTab = (props) => {
  const { item, onClickTabParent, index, activeTab, deleteParent } = props

  const itemDropdownRef = useRef()
  const inputRef = useRef()
  const [textName, settextName] = useState('')

  const [isShow, setisShow] = useState(false)
  const [isShowEdit, setisShowEdit] = useState(false)

  const setShowInput = () => {
    setisShowEdit(true)
    setisShow(false)
    settextName(item.title)
    setTimeout(() => inputRef.current?.focus(), 100)
  }

  const clickIconInput = () => {
    if (item?.value === VALUE_ADD_MORE_PARENT) {
      onClickTabParent(VALUE_ADD_MORE)()
    }
    setisShowEdit(false)
  }

  const renderContnet = () => {
    if (isShowEdit) {
      return (
        <div
          style={{ background: '#63696f' }}
          className="d-flex group-btn ali-center"
        >
          <Input
            ref={inputRef}
            value={textName}
            type="text"
            style={{
              color: '#FFF',
              padding: '8px 0px 8px 8px',
            }}
            onBlur={() => {
              onClickTabParent(index, textName)()
              setisShowEdit(false)
            }}
            onChange={(e) => settextName(e.target.value)}
          />
          <i className="icon-true mr-8" onClick={() => clickIconInput()} />
        </div>
      )
    }
    if (item?.value === VALUE_ADD_MORE_PARENT) {
      return (
        <div
          onClick={() => onClickTabParent(VALUE_ADD_MORE)()}
          className={`${style.dropdownListTabItemParent}`}
        >
          <div className="d-flex ali-center">
            <Span
              style={{
                fontSize: 16,
                fontWeight: 600,
                width: 16,
                opacity: 0.5,
              }}
              className="mr-4 ml-4"
            >
              +
            </Span>
            <div className="flex-4">
              <Span>
                <Translate value={item.title} />
              </Span>
            </div>
          </div>
        </div>
      )
    }
    return (
      <div
        className={`${style.dropdownListTabItemParent}`}
        onClick={() => onClickTabParent(index)()}
      >
        <div className="d-flex ali-center">
          <i
            className="icon-menu-dot-hor"
            style={{ opacity: 0.5, width: '4px' }}
          />
          <i className="icon-menu-dot-hor" style={{ opacity: 0.5 }} />
          <div
            className="flex-4"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              width: '168px',
            }}
          >
            <Span>{item.title}</Span>
          </div>
        </div>
        <div className="mr-8">
          <i
            className="icon-menu-dot-hor"
            style={{ opacity: 0.5 }}
            onMouseEnter={() => setisShow(true)}
          />
        </div>
      </div>
    )
  }
  return (
    <>
      <div
        key={index}
        ref={itemDropdownRef}
        className={`w-100 ${style.itemDropDown}`}
        onMouseLeave={() => setisShow(false)}
        style={{
          backgroundColor: activeTab === item.value ? '#2a8cd9' : '',
        }}
      >
        {renderContnet()}
        <Dropdown
          isShow={isShow}
          parentRef={itemDropdownRef}
          position={positionDropdown.RIGHT_TOP}
        >
          <HandleClickOutside handleClickOutside={() => setisShow(false)}>
            <div className={style.dropdownListTab}>
              <div
                className={`${style.dropdownListTabItem} ${style.itemParentTop}`}
                onClick={() => setShowInput(true)}
              >
                <div>
                  <Span>
                    <Translate value="tool.smartScreening.RENAME" />
                  </Span>
                </div>
                <i className="icon-edit" style={{ opacity: 0.5 }} />
              </div>
              <div
                className={`${style.dropdownListTabItem} ${style.itemParentBottom}`}
                onClick={() => {
                  deleteParent(index)
                  setisShow(false)
                }}
              >
                <div>
                  <Span>
                    <Translate value="tool.smartScreening.DELETE" />
                  </Span>
                </div>
                <i className="icon-trash" style={{ opacity: 0.5 }} />
              </div>
            </div>
          </HandleClickOutside>
        </Dropdown>
      </div>
    </>
  )
}

const ItemTab = (props) => {
  const {
    index,
    activeTab,
    value,
    itemActiveClass,
    listDisabled,
    itemClass,
    onClickTab,
    itemStyle,
    widthTab,
    title,
    isDropdownDot,
    textSize,
    data,
    limit,
  } = props

  const [isShowEdit, setIsShowEdit] = useState(false)
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [isShowDropdownMore, setIsShowDropdownMore] = useState(false)
  const [textName, settextName] = useState('')

  const fontSize11 = getFontSize(11)
  const parentDropdownRef = useRef()
  const inputRef = useRef()
  const tabRef = useRef()

  const setShowInput = () => {
    setIsShowEdit(true)
    settextName(data[index]?.title)
    setTimeout(() => inputRef.current?.focus(), 100)
  }
  const dataFormat = [...data]

  if (data.length < 7) {
    dataFormat.push({
      title: 'bond.valuation.sameIndusTryBond.addTab',
      value: VALUE_ADD_MORE_PARENT,
    })
  }

  const removeTab = (e) => {
    e.stopPropagation()
    onClickTab(index, null, DELETE)()
    setIsShowDropdown(false)
  }

  const deleteParent = (index) => {
    onClickTab(index, null, DELETE)()
    setIsShowDropdownMore(false)
  }

  return (
    <>
      {value === VALUE_MORE ? (
        <a
          key={index}
          style={{ padding: '0 10px', ...itemStyle }}
          className={[
            style.item,
            listDisabled.includes(value) ? style.isDisabled : '',
            itemClass,
            'hover-dropdown d-flex justify-content-center',
            dataFormat
              .slice(limit, dataFormat.length)
              .find((item) => item.value === activeTab)
              ? style.btnActive
              : '',
          ].join(' ')}
          onMouseEnter={() => setIsShowDropdownMore(true)}
          onMouseLeave={() => setIsShowDropdownMore(false)}
          ref={parentDropdownRef}
        >
          <Span
            key={fontSize11 + index}
            style={{
              fontSize: textSize,
            }}
          >
            {widthTab[index] ? (
              <TextEllipsis text={title} isI18n={true} />
            ) : (
              <Translate value={title} />
            )}
          </Span>
          <Dropdown isShow={isShowDropdownMore} parentRef={parentDropdownRef}>
            <HandleClickOutside
              handleClickOutside={() => setIsShowDropdown(false)}
            >
              <div className={style.dropdownListTabParent}>
                {dataFormat.slice(limit, dataFormat.length).map((e, index) => (
                  <ItemParentTab
                    key={index}
                    item={e}
                    onClickTabParent={onClickTab}
                    index={limit + index}
                    activeTab={activeTab}
                    deleteParent={deleteParent}
                  />
                ))}
              </div>
            </HandleClickOutside>
          </Dropdown>
        </a>
      ) : (
        <a
          key={index}
          className={[
            style.item,
            activeTab === value && !isShowEdit
              ? itemActiveClass || style.btnActive
              : '',
            listDisabled.includes(value) ? style.isDisabled : '',
            itemClass,
          ].join(' ')}
          style={{
            padding: '0 10px',
            ...itemStyle,
            width: value === VALUE_ADD_MORE ? '32px' : itemStyle?.width,
            minWidth: value === VALUE_ADD_MORE ? '32px' : '40px',
            position: 'relative',
          }}
          onMouseLeave={() => setIsShowDropdown(false)}
          ref={tabRef}
          onClick={() => {
            onClickTab(value === VALUE_ADD_MORE ? VALUE_ADD_MORE : index)()
          }}
        >
          {isShowEdit ? (
            <div
              style={{
                background: '#454b56',
              }}
            >
              <Input
                ref={inputRef}
                type="text"
                style={{
                  color: '#FFF',
                  width: '60px',
                }}
                value={textName}
                onBlur={() => {
                  // e.stopPropagation()
                  onClickTab(index, textName)()
                  setIsShowEdit(false)
                }}
                onChange={(e) => settextName(e.target.value)}
              />
              <button
                className={`btn-ok cursor-pointer`}
                onClick={() => setIsShowEdit(false)}
              >
                <i className="icon-true" style={{ color: '#FFF' }} />
              </button>
            </div>
          ) : (
            <>
              <div
                // className={`d-flex ali-center`}
                style={{
                  width: '80px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                <Span
                  key={fontSize11 + index}
                  style={{
                    fontSize: value === VALUE_ADD_MORE ? 16 : textSize,
                    fontWeight: value === VALUE_ADD_MORE ? 500 : 600,
                  }}
                >
                  <TextEllipsis text={title} isI18n={true} />
                </Span>
              </div>
              {isDropdownDot && (
                <div
                  style={{
                    position: 'absolute',
                    right: '1px',
                  }}
                >
                  <i
                    className="icon-menu-dot-hor"
                    style={{ opacity: 0.5 }}
                    onMouseEnter={() => setIsShowDropdown(true)}
                  />
                  <Dropdown
                    isShow={isShowDropdown}
                    parentRef={tabRef}
                    position={positionDropdown.BOTTOM_LEFT_RIGHT}
                  >
                    <HandleClickOutside
                      handleClickOutside={() => setIsShowDropdown(false)}
                    >
                      <div className={style.dropdownListTab}>
                        <div
                          className={style.dropdownListTabItem}
                          onClick={() => setShowInput()}
                        >
                          <div>
                            <Span>
                              <Translate value="tool.smartScreening.RENAME" />
                            </Span>
                          </div>
                          <i className="icon-edit" style={{ opacity: 0.5 }} />
                        </div>
                        <div
                          className={style.dropdownListTabItem}
                          onClick={(e) => removeTab(e)}
                        >
                          <div>
                            <Span>
                              <Translate value="tool.smartScreening.DELETE" />
                            </Span>
                          </div>
                          <i className="icon-trash" style={{ opacity: 0.5 }} />
                        </div>
                      </div>
                    </HandleClickOutside>
                  </Dropdown>
                </div>
              )}
            </>
          )}
        </a>
      )}
    </>
  )
}

const DispatchActionTab = ({
  activeTab,
  data,
  onChangeTab,
  itemStyle,
  containerStyle,
  itemClass,
  itemActiveClass,
  widthComponent,
  listDisabled,
  textSize,
  isAddMore,
  limit,
}) => {
  const fontSize11 = getFontSize(11)
  const refNav = useRef()
  const locale = useSelector((state) => state.i18n.locale)
  const dataIsAddMore = data

  const onClickTab = (index, nameTab, type) => () => {
    if (listDisabled.includes(dataIsAddMore[index]?.value)) {
      return null
    } else {
      if (type === DELETE) {
        return onChangeTab({
          ...dataIsAddMore[index],
          isDelete: true,
        })
      }
      if (nameTab && nameTab !== dataIsAddMore[index]?.title) {
        return onChangeTab({
          value: dataIsAddMore[index].value,
          title: nameTab,
          isEditName: true,
        })
      }
      if (index === VALUE_ADD_MORE) {
        return onChangeTab({
          title: '+',
          value: VALUE_ADD_MORE,
        })
      }
      onChangeTab(dataIsAddMore[index])
    }
  }

  const [widthTab, setWidthTab] = useState('')

  useEffect(() => {
    if (widthComponent) {
      const widths = getItemTextWidth(
        dataCheckMore,
        itemStyle,
        fontSize11,
        widthComponent,
      )
      setWidthTab(widths)
    }
  }, [widthComponent, data, refNav.current, locale])

  const formatData = () => {
    if (isAddMore) {
      const copiedArray = data.slice(
        0,
        data.length > limit ? limit : data.length,
      )
      if (data.length <= limit) {
        copiedArray.push({
          title: '+',
          value: VALUE_ADD_MORE,
        })
      } else {
        copiedArray.push(
          {
            title: 'bond.valuation.sameIndusTryBond.more',
            value: VALUE_MORE,
          },
          {
            title: '+',
            value: VALUE_ADD_MORE,
          },
        )
      }

      return copiedArray
    } else {
      return data
    }
  }
  const dataCheckMore = formatData()

  return (
    <nav ref={refNav} className="tab-in-panel lst-temp" style={containerStyle}>
      {dataCheckMore.map(({ title, value, isDropdownDot }, index) => {
        return (
          <ItemTab
            key={index}
            index={index}
            activeTab={activeTab}
            value={value}
            itemActiveClass={itemActiveClass}
            listDisabled={listDisabled}
            itemClass={itemClass}
            onClickTab={onClickTab}
            itemStyle={itemStyle}
            widthTab={widthTab}
            title={title}
            isDropdownDot={isDropdownDot}
            textSize={textSize}
            data={data}
            limit={limit}
          />
        )
      })}
    </nav>
  )
}

DispatchActionTab.propTypes = {
  activeTab: PropTypes.any.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    }),
  ).isRequired,
  onChangeTab: PropTypes.func,
  itemClass: PropTypes.string,
  itemActiveClass: PropTypes.string,
  listDisabled: PropTypes.array,
  textSize: PropTypes.number,
}

DispatchActionTab.defaultProps = {
  onChangeTab: () => {},
  listDisabled: [],
  textSize: 11,
}

export default React.memo(DispatchActionTab)
