import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { Span } from '../../../../common/html/Span'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { tableChartSwitch, timeSalesDateFilter } from './constants'
import style from './style.module.css'

export const ChartFilter = ({
  viewType,
  setViewType,
  dateType,
  setDateType,
}) => {
  return (
    <div className="align-center j-b">
      <Button
        className="button-filter-chart"
        onClick={(_) =>
          setViewType(
            viewType === tableChartSwitch.HORIZONTAL_STACK_BAR
              ? tableChartSwitch.TABLE
              : tableChartSwitch.HORIZONTAL_STACK_BAR,
          )
        }
      >
        <i
          className={`${
            viewType === tableChartSwitch.TABLE ? 'icon-chart' : 'icon-dash'
          }`}
        />
      </Button>
      {viewType === tableChartSwitch.HORIZONTAL_STACK_BAR ? (
        <DispatchActionTab
          data={timeSalesDateFilter}
          activeTab={dateType}
          onChangeTab={(item) => setDateType(item.value)}
          itemStyle={{ width: '20px', fontWeight: 500, fontSize: 10 }}
        />
      ) : (
        <div className={style.spanTableColor}>
          <Span style={{ fontSize: 11 }}>
            <Translate value="market.coveredWarrant.PRICE_VOLUME_UNIT" />
          </Span>
        </div>
      )}
    </div>
  )
}

ChartFilter.propTypes = {
  viewType: PropTypes.string.isRequired,
  setViewType: PropTypes.func.isRequired,
  dateType: PropTypes.string.isRequired,
  setDateType: PropTypes.func.isRequired,
}
