import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import ChartContainer from '../common/ChartContainer'
import { CHART_ORDER } from '../constants'
import { selectActiveTab2, selectChartType } from '../store/slice'

export const BOTTOM_CHART_ID = 'bottomChartSegmentalAnalysis'

const PanelChartBottom = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const { chart2Type } = useSelector(selectChartType)
  const basicInfo = useSelector(selectBasicInfo)
  const activeTab2 = useSelector(selectActiveTab2)

  const nameScreen = UseI18n(
    'constants.route.corporate.businessModel.segmentalAnalysis.LABEL',
  )
  const chartName = UseI18n(
    'corporate.businessModel.segmentalAnalysis.PEERS_COMPARISON',
  )
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title={'corporate.businessModel.segmentalAnalysis.PEERS_COMPARISON'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: BOTTOM_CHART_ID,
        nameScreen,
        chartName,
        tickerName,
      }}
    >
      <ChartContainer
        keyChart={chart2Type}
        sizes={sizes}
        panelKey={panelKey}
        chartType={CHART_ORDER.CHART_2}
        activeTab={activeTab2}
      />
    </Panel>
  )
}

export default PanelChartBottom
