import { useSelector } from 'react-redux'
import { Panel } from '../../common/panel'
import { selectIndexSectorTickerInfo } from '../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import EventTable from './eventTable'
import { key, selectFilter, selectIsCheckedAll } from './store/slice'
import { downloadEvents } from './store/thunk'

const PanelEventTable = ({ title, panelRefs, sizes, setSizes, panelKey }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)
  const filter = useSelector(selectFilter)
  const isCheckedAllEventType = useSelector(selectIsCheckedAll(key.EVENT_TYPE))
  const isCheckedAllCompany = useSelector(selectIsCheckedAll(key.COMPANY))

  const handleDownload = () => {
    let params = {
      StartDate: filter.startDate,
      EndDate: filter.endDate,
      StartRecordDate: filter.startRecordDate,
      EndRecordDate: filter.endRecordDate,
      StartExrightDate: filter.startExrightDate,
      EndExrightDate: filter.endExrightDate,
      StartExerciseDate: filter.startExerciseDate,
      EndExerciseDate: filter.endExerciseDate,
      SortField: filter.sortField,
      SortOrder: filter.sortOrder,
      Page: filter.page,
      PageSize: 2000,
      language: locale,
    }

    if (
      (indexSectorTickerInfo.organizationId ||
        indexSectorTickerInfo.groupId ||
        (indexSectorTickerInfo.icbId &&
          !indexSectorTickerInfo.organizationId)) &&
      filter.organizationId.length === 0 &&
      filter.groupId.length === 0 &&
      filter.icbId.length === 0 &&
      filter.watchlistId.length === 0
    ) {
      params = {
        GroupId: indexSectorTickerInfo.groupId
          ? [indexSectorTickerInfo.groupId]
          : [],
        OrganizationId: indexSectorTickerInfo.organizationId
          ? [indexSectorTickerInfo.organizationId]
          : [],
        IcbId:
          indexSectorTickerInfo.icbId && !indexSectorTickerInfo.organizationId
            ? [indexSectorTickerInfo.icbId]
            : [],
        WatchListId: [],
        FiinXEventTypeIds: isCheckedAllEventType
          ? []
          : filter.fiinXEventTypeIds,
        ...params,
      }
    } else if (
      filter.organizationId.length > 0 ||
      filter.groupId.length > 0 ||
      filter.icbId.length > 0 ||
      filter.watchlistId.length > 0
    ) {
      params = {
        GroupId: filter.groupId,
        OrganizationId: isCheckedAllCompany ? [] : filter.organizationId,
        IcbId: filter.icbId,
        WatchListId: filter.watchlistId,
        FiinXEventTypeIds: isCheckedAllEventType
          ? []
          : filter.fiinXEventTypeIds,
        ...params,
      }
    }

    return downloadEvents(params)
  }

  return (
    <Panel
      title={title}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={handleDownload}
    >
      <EventTable />
    </Panel>
  )
}

export default PanelEventTable
