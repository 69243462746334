import { useSelector } from 'react-redux'
import { FundManager } from '.'
import { Panel } from '../../../../common/panel'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { downloadBoardOfFundManagers } from './store/thunk'

export const PanelFundManager = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const titlePanel = `fund.fundProfile.profile.${panelKey}`

  const fundInfo = useSelector(selectFundInfo)

  const handleDownload = () => {
    return downloadBoardOfFundManagers({
      organizationId: fundInfo.organizationId,
    })
  }

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={handleDownload}
    >
      <FundManager
        id={panelKey}
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}
