import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'
import { handleExport } from '../../../../../../utils/Export'

export const getSteelPipeStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/steelPipe/steelStatistics',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getSteelPipeStatistics(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadSteelPipeStatistic = createAsyncThunk(
  'sector/sectorSpecific/steel/steelPipe/downloadSteelPipeStatistic',
  async (data) => {
    const response = await SteelService.getDownloadSteelPipeStatistic(data)
    handleExport(response.data, response.filename)
  },
)
