import PropTypes from 'prop-types'
import { memo, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Area, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import {
  getChartMargin,
  getDecimalLengthYAxis,
  getRoundedTickValues,
  yExtentsCharts,
} from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import ContentTooltip from '../../../../../common/chart/rechart/ContentTooltip'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { selectIndexInfo } from '../../../../../common/topInfo/indexTopInfo/store/slice'
import { PANEL_PADDING } from '../../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'
import { decodeSizePanel } from '../../helper'
import style from '../../index.module.css'
import { selectBuSd, selectChartTimeRange } from '../../store/slice'

let currentChartMargin = {}

const ChartComponent = ({ sizes, chartMargin, setChartMargin }) => {
  const timezone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const timeRange = useSelector(selectChartTimeRange)
  const indexInfo = useSelector(selectIndexInfo)
  const buSd = useSelector(selectBuSd)

  const chartContainerRef = useRef()

  const calcFontSize = getFontSize(12)

  const [size, setSize] = useState({
    width: decodeSizePanel(sizes).width - PANEL_PADDING,
    height: decodeSizePanel(sizes).height / 3,
  })

  useEffect(() => {
    setSize({
      width: chartContainerRef.current?.offsetWidth,
      height: chartContainerRef.current?.offsetHeight,
    })
  }, [sizes])

  const [leftMin, leftMax] =
    buSd.length > 0
      ? yExtentsCharts(buSd, ['buyUpVolume', 'sellDownVolume'])
      : [0, 0]

  const leftTickValues = getRoundedTickValues([leftMin, leftMax], 5)

  const leftDecimalLength = getDecimalLengthYAxis(leftTickValues)

  const [rightMin, rightMax] = buSd
    ? yExtentsCharts(
        buSd.filter((item) => item.indexValue),
        ['indexValue'],
        false,
        true,
      )
    : [0, 0]

  const rightTickValues = getRoundedTickValues([rightMin, rightMax], 5)

  const rightDecimalLength = getDecimalLengthYAxis(rightTickValues)

  useEffect(() => {
    const calcChartMargin = getChartMargin(
      leftTickValues,
      rightTickValues,
      leftDecimalLength,
      rightDecimalLength,
      calcFontSize,
    )
    const newChartMargin = {
      ...calcChartMargin,
      left: Math.max(calcChartMargin.left, chartMargin.left),
      right: Math.max(calcChartMargin.right, chartMargin.right),
    }
    if (JSON.stringify(newChartMargin) !== JSON.stringify(currentChartMargin)) {
      setChartMargin(newChartMargin)
      currentChartMargin = newChartMargin
    }
  }, [buSd, size, calcFontSize, chartMargin])

  return (
    <div className={`h-100 ${style.relative}`}>
      <div className="h-100">
        <div className="h-100" ref={chartContainerRef}>
          <ChartContainer
            data={buSd}
            width={decodeSizePanel(sizes).width - PANEL_PADDING}
            height={size.height}
            keyXAxis="tradingDate"
            yAxis={[
              {
                id: 'left',
                keys: ['buyUpVolume', 'sellDownVolume'],
                orientation: 'left',
                yAxisWidth: chartMargin.left,
                label: I18n.t('market.marketInDepth.indices.VOLUME_MILLION'),
                labelPosition: AXIS_LABEL_POSITION.LEFT,
              },
              {
                id: 'right',
                keys: ['indexValue'],
                orientation: 'right',
                yAxisWidth: chartMargin.right,
                isLineChart: true,
                label: I18n.t('market.marketInDepth.indices.INDEX_POINT'),
                labelPosition: AXIS_LABEL_POSITION.RIGHT,
              },
            ]}
            timeFrame={timeRange}
            tickFormatter={
              timeRange === TIME_RANGES['3M']
                ? (val) => formatDateTime(val, FORMAT.DATE, locale)
                : undefined
            }
            renderCustomTooltip={(data) => {
              const contentTooltip = [
                {
                  label:
                    formatDateTime(
                      data.tradingDate,
                      FORMAT.DATE,
                      locale,
                      timezone,
                    ) +
                    ' ' +
                    ([TIME_RANGES['6M'], TIME_RANGES['1Y']].includes(timeRange)
                      ? ''
                      : formatDateTime(
                          data.tradingDate,
                          FORMAT.TIME_HOUR_MINUTE,
                          locale,
                          timezone,
                        )),
                  value: '',
                  styleLabel: { fontStyle: 'italic', fontSize: 10 },
                  styleValue: { fontStyle: 'italic', fontSize: 10 },
                },
                {
                  label: `${I18n.t('market.marketInDepth.indices.BUY_UP')}:`,
                  value:
                    formatVal(data.buyUpVolume) +
                    I18n.t('market.marketInDepth.indices.BUSD_MILLION'),
                  styleLabel: { fontSize: 11, fontWeight: 400 },
                  styleValue: { fontSize: 11, fontWeight: 500 },
                },
                {
                  label: `${I18n.t('market.marketInDepth.indices.SELL_DOWN')}:`,
                  value:
                    formatVal(data.sellDownVolume) +
                    I18n.t('market.marketInDepth.indices.BUSD_MILLION'),
                  styleLabel: { fontSize: 11, fontWeight: 400 },
                  styleValue: { fontSize: 11, fontWeight: 500 },
                },
                {
                  label: `${indexInfo.groupName}:`,
                  value: formatVal(data.indexValue),
                  styleLabel: { fontSize: 11, fontWeight: 400 },
                  styleValue: { fontSize: 11, fontWeight: 500 },
                },
              ]
              return <ContentTooltip contentTooltip={contentTooltip} />
            }}
          >
            <defs>
              <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                <stop offset="45%" stopColor="#37a77d" stopOpacity={0.5} />
                <stop
                  offset="55%"
                  stopColor="rgb(55, 167, 125)"
                  stopOpacity={0.5}
                />
              </linearGradient>
              <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                <stop offset="30%" stopColor="#e84855" stopOpacity={0.5} />
                <stop
                  offset="60%"
                  stopColor="rgba(255, 81, 81)"
                  stopOpacity={0.5}
                />
              </linearGradient>
            </defs>
            <Area
              dataKey="buyUpVolume"
              fill="url(#color1)"
              yAxisId="left"
              stroke="#31e984"
              fillOpacity={1}
              isAnimationActive={false}
              activeDot={false}
            />
            <Area
              dataKey="sellDownVolume"
              fill="url(#color2)"
              yAxisId="left"
              stroke="#fd3e4d"
              fillOpacity={1}
              isAnimationActive={false}
              activeDot={false}
            />
            <Line
              yAxisId="right"
              dataKey="indexValue"
              stroke="#ffd73e"
              dot={false}
              isAnimationActive={false}
              activeDot={false}
            />
          </ChartContainer>
        </div>
      </div>
    </div>
  )
}

ChartComponent.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

const BUSDChart = ({ sizes, chartMargin, setChartMargin }) => {
  const indexInfo = useSelector(selectIndexInfo)
  const buSd = useSelector(selectBuSd)

  const listIconChart = [
    {
      text: 'market.marketInDepth.indices.BUY_UP_VOLUME',
      type: TYPE.RECTANGLE,
      before: {
        bgColor: '#31e984',
      },
    },
    {
      text: 'market.marketInDepth.indices.SELL_DOWN_VOLUME',
      type: TYPE.RECTANGLE,
      before: {
        bgColor: '#fe5d6a',
      },
    },
    {
      text: indexInfo.groupName,
      type: TYPE.RECTANGLE,
      before: {
        bgColor: '#ffd73e',
      },
    },
  ]

  return (
    <div className={style.busdChartContainer}>
      {!buSd.length ? (
        <NoData />
      ) : (
        <SizeTracker>
          {(size) => (
            <>
              <h3 className={style.titleChart}>
                <Span>
                  <Translate
                    value="market.marketInDepth.indices.BU_SD_TITLE"
                    className="canvas-chart-title"
                  />
                </Span>
              </h3>
              {size.height && (
                <div style={{ height: `calc(100% - ${size.height}px)` }}>
                  <ChartComponent
                    sizes={sizes}
                    chartMargin={chartMargin}
                    setChartMargin={setChartMargin}
                  />
                </div>
              )}
              <div className={style.footerContainer}>
                <Footer key={sizes.width} list={listIconChart} />
              </div>
            </>
          )}
        </SizeTracker>
      )}
    </div>
  )
}

BUSDChart.propTypes = {
  sizes: PropTypes.string.isRequired,
  chartMargin: PropTypes.object.isRequired,
  setChartMargin: PropTypes.func.isRequired,
}

export default memo(BUSDChart)
