import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class OpenMarketProxy extends ProxyBase {
  getOpenMarketOperations(params) {
    return this.get('OpenMarketOperations', params)
  }

  getOpenMarketOperationsTitle(params) {
    return this.get('OpenMarketOperationsTitle', params)
  }

  downloadOpenMarketOperations(params) {
    return this.get('DownloadOpenMarketOperations', params, 'download')
  }

  getOpenMarketDisplay(params) {
    return this.get('OpenMarketDisplay', params)
  }

  getBiddingResultsOnTheOpenMarket(data) {
    return this.get('BiddingResultsOnTheOpenMarket', data)
  }

  downloadBiddingResults(params) {
    return this.get('DownloadBiddingResults', params, 'download')
  }

  getOutstandingVolume(params) {
    return this.get('OutstandingVolume', params)
  }

  getNetPumpVolume(params) {
    return this.get('NetPumpVolume', params)
  }

  getMaxDate(params) {
    return this.get('MaxDate', params)
  }

  getMaxDateOMO(params) {
    return this.get('MaxDateOMO', params)
  }
}

export default new OpenMarketProxy(
  ServiceProxyConfig.Economy.OpenMarket.ServiceUrl,
)
