import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemSelect from './common/ItemSelect'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import style from './index.module.css'

const listSelect = [
  {
    value: 1,
    name: '1',
  },
  {
    value: 2,
    name: '2',
  },
  {
    value: 3,
    name: '3',
  },
  {
    value: 4,
    name: '4',
  },
  {
    value: 5,
    name: '5',
  },
]

const IcbName = ({
  handleSubmit,
  handleCancel,
  title,
  maxIndicator,
  lengthIndicator,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={`${style.icbNameContent} d-flex ali-center`}>
            <Translate value="common.popup.INDUSTRY_LEVEL" />
            <ItemSelect
              selectData={listSelect}
              keySelect="icbLevel"
              defaultValue={listSelect[4].value}
            />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
            isDisabledSettingGroup={true}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              submit={handleSubmit}
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

IcbName.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(IcbName)
