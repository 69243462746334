import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar, Line } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'

export const GroupedBarLineChart = ({
  width,
  height,
  data,
  xAxisKey,
  barKeys,
  lineKeys,
  unit,
  lineUnit,
  margin,
  fillColor,
  mappingDisplayName,
  leftLabel,
  rightLabel,
  isXAxisDiv,
  isBreakNewLine,
}) => {
  // Footer
  const footerData = () => {
    let footer = [
      ...barKeys.map((i) => ({
        text: mappingDisplayName[i] ?? i,
        type: FOOTER_TYPE.SQUARE,
        color: fillColor[i],
      })),
    ]
    if (lineKeys.length) {
      footer = [
        ...footer,
        ...lineKeys.map((i) => ({
          text: mappingDisplayName[i] ?? i,
          type: FOOTER_TYPE.CIRCLE_MARKER_LINE,
          color: fillColor[i],
        })),
      ]
    }
    return footer
  }

  // Render tooltip
  const getContent = () => {
    return [
      {
        keys: barKeys,
        yAxisId: 'barChart',
        unit: unit,
        decimalLength: 2,
      },
      {
        keys: lineKeys,
        yAxisId: 'lineChart',
        unit: lineUnit,
        decimalLength: 2,
      },
    ]
  }

  // Render
  const renderBarChart = (chartContentWidth) => {
    return barKeys.map((key) => (
      <Bar
        key={key}
        yAxisId="barChart"
        dataKey={key}
        barSize={getColumnSizeInBarChart(
          chartContentWidth,
          data.length,
          25,
          barKeys.length,
        )}
        fill={fillColor[key]}
        isAnimationActive={false}
      />
    ))
  }

  const renderLineChart = (chartContentWidth) => {
    return lineKeys.map((key) => (
      <Line
        key={key}
        yAxisId="lineChart"
        dataKey={key}
        type="linear"
        stroke={fillColor[key]}
        fill={fillColor[key]}
        strokeWidth={2}
        dot={{
          strokeWidth: getRadiusOfScatter(chartContentWidth, data.length),
        }}
        activeDot={false}
        isAnimationActive={false}
      />
    ))
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              yAxis={[
                {
                  id: 'barChart',
                  keys: barKeys,
                  orientation: 'left',
                  isBarChart: true,
                  yAxisWidth: margin?.left,
                  label: leftLabel ? I18n.t(leftLabel) : undefined,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                },
                {
                  id: 'lineChart',
                  keys: lineKeys,
                  orientation: 'right',
                  isLineChart: true,
                  yAxisWidth: margin?.right,
                  label: rightLabel ? I18n.t(rightLabel) : undefined,
                  labelPosition: AXIS_LABEL_POSITION.RIGHT,
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              isUseXAxisDiv={isXAxisDiv}
              isBreakNewLine={isBreakNewLine}
              tickFormatter={(val) => val}
              tooltipSchema={{
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {({ chartContentWidth }) => (
                <>
                  {renderBarChart(chartContentWidth)}
                  {renderLineChart(chartContentWidth)}
                </>
              )}
            </ChartContainer>
          )}
          <FooterContainer key={width} data={footerData()} />
        </>
      )}
    </SizeTracker>
  )
}

GroupedBarLineChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  barKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  lineKeys: PropTypes.arrayOf(PropTypes.string),
  unit: PropTypes.string,
  lineUnit: PropTypes.string,
  fillColor: PropTypes.object,
  mappingDisplayName: PropTypes.objectOf(PropTypes.string),
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  margin: PropTypes.object,
  isXAxisDiv: PropTypes.bool,
  isBreakNewLine: PropTypes.bool,
}

GroupedBarLineChart.defaultProps = {
  fillColor: {},
  unit: '',
  lineUnit: '',
  mappingDisplayName: {},
  barKeys: [],
  lineKeys: [],
  isXAxisDiv: false,
  isBreakNewLine: false,
}
