import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import ChartContainer from './ChartContainer'

export const CHART_DOM_ID = 'RevenueCostChartId'

const PanelRevenueCost = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
  width,
  height,
}) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n(
    'constants.route.corporate.businessModel.costOfOperation.LABEL',
  )
  const chartName = UseI18n(
    'corporate.businessModel.costOfOperation.REVENUE_COST_TITLE',
  )
  const tickerName = basicInfo.ticker

  return (
    <Panel
      title="corporate.businessModel.costOfOperation.REVENUE_COST_TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: CHART_DOM_ID,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
    >
      <ChartContainer width={width} height={height} />
    </Panel>
  )
}

export default PanelRevenueCost
