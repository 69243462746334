import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Content from './Content'
import { getSteelTypes } from './store/thunk'

export const OverviewSteel = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)

  useEffect(() => {
    dispatch(getSteelTypes({ Language: locale }))
  }, [locale])

  return <Content />
}
