import { useDispatch, useSelector } from 'react-redux'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import {
  GROWTH_SECTOR_DATA,
  GROWTH_SECTOR_DATA_YEARLY,
} from '../../chartFirst/constants'
import { getYearlyQuarterValue } from '../../table/store/slice'
import {
  getTimeRangeAcc,
  getTimeRangeYearly,
  handleTimeRangeAcc,
  handleTimeRangeYearly,
} from './store/slice'

const ActionTab = () => {
  const dispatch = useDispatch()

  const yearlyQuarterValue = useSelector(getYearlyQuarterValue)
  const timeRangeYearly = useSelector(getTimeRangeYearly)
  const timeRangeAcc = useSelector(getTimeRangeAcc)
  const activeTab =
    yearlyQuarterValue === 'Yearly' ? timeRangeYearly : timeRangeAcc

  const onChangeTab = (tab) => {
    if (yearlyQuarterValue === 'Yearly') {
      dispatch(handleTimeRangeYearly(tab.value))
    } else {
      dispatch(handleTimeRangeAcc(tab.value))
    }
  }

  return (
    <div className="d-flex justify-content-end box-price-chart">
      <DispatchActionTab
        data={
          yearlyQuarterValue === 'Yearly'
            ? GROWTH_SECTOR_DATA_YEARLY
            : GROWTH_SECTOR_DATA
        }
        activeTab={activeTab}
        onChangeTab={onChangeTab}
      />
    </div>
  )
}

export default ActionTab
