import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import UseI18n from '../../../../common/hooks/useI18n'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal } from '../../../../utils/Value'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const YAXIS_LEFT = 'YAXIS_LEFT'
const LINE_COLOR = '#f7b80c'
const MARGIN = { ...MARGIN_RECHARTS, right: 16 }

const ChartComponent = ({ data, width, height, keyXAxis, lineKey, locale }) => {
  const timeZone = UseTimeZone()
  const leftLabel = UseI18n('market.marketInDepth.foreign.OWNERSHIP_LB_LEFT')

  const renderTooltip = (tooltipData, decimalLengths) => {
    const contentTooltip = [
      {
        label: I18n.t(`market.marketInDepth.foreign.FOREIGN_OWNERSHIP`),
        value:
          formatVal(tooltipData[lineKey], decimalLengths[YAXIS_LEFT] || 2) +
          '%',
      },
    ]

    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            {formatDateTime(
              tooltipData[keyXAxis],
              FORMAT.DATE,
              locale,
              timeZone,
            )}
          </Span>
        </div>
        {contentTooltip.map((el, index) => (
          <div key={index} className="d-flex j-b">
            <div>
              <Span style={{ fontSize: 11, fontWeight: 400 }}>
                {el.label + ': '}
              </Span>
            </div>
            <div className="ml-8">
              <Span style={{ fontSize: 11, fontWeight: 500 }}>{el.value}</Span>
            </div>
          </div>
        ))}
      </>
    )
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      timeFrame="1M"
      tickFormatter={(val) =>
        formatDateTime(val, FORMAT.DATE, locale, timeZone)
      }
      margin={{ ...MARGIN, right: 50 }}
      yAxis={[
        {
          id: YAXIS_LEFT,
          keys: [lineKey],
          orientation: 'left',
          isLineChart: true,
          tickNum: SETTINGS.yTickNum,
          label: leftLabel,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: '%',
        },
      ]}
      renderCustomTooltip={renderTooltip}
    >
      <Line
        yAxisId={YAXIS_LEFT}
        dataKey={lineKey}
        type="linear"
        stroke={LINE_COLOR}
        strokeWidth={2}
        dot={false}
        activeDot={false}
        isAnimationActive={false}
      />
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}

ChartComponent.defaultProps = {
  fontSize: 12,
}

export default ChartComponent
