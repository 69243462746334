import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class SmartScreeningProxy extends ProxyBase {
  getQuickSelectIndicators(params) {
    return this.get('GetQuickSelectIndicators', params)
  }

  getRatioIndicators(params) {
    return this.get('GetRatioIndicators', params)
  }

  getFinancialStatementIndicators(params) {
    return this.get('GetFinancialStatementIndicators', params)
  }

  getTemplates(params) {
    return this.get('ScreeningGetTemplates', params, 'get', true)
  }

  renameTemplate(params) {
    return this.post('ScreeningRenameTemplate', params)
  }

  getScreeningDate(params) {
    return this.get('GetScreeningDate', params)
  }

  getTemplate(params) {
    return this.get('ScreeningGetTemplate', params)
  }

  getTypeInterim(params) {
    return this.get('GetTypeInterims', params)
  }

  getResult(params) {
    return this.post('', params)
  }

  getCount(params) {
    return this.post('count', params)
  }

  saveTemplate(params) {
    return this.post('ScreeningSaveTemplate', params)
  }

  updateTemplate(params) {
    return this.post('ScreeningUpdateTemplate', params)
  }

  deleteTemplate(params) {
    return this.delete('ScreeningDeleteTemplate', params)
  }

  exportResult(params) {
    return this.post('export', params, {}, 'download')
  }

  saveWorkSpace(params) {
    return this.post('SaveWorkSpace', params)
  }
}

export default new SmartScreeningProxy(
  ServiceProxyConfig.Tool.SmartScreening.ServiceUrl,
)
