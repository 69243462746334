import PropTypes from 'prop-types'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { getPriceColor } from '../../../../utils/Color'
import {
  formatChange,
  formatVal,
  valByKeyWithDot,
  valDivThousand,
  valToPercent,
} from '../../../../utils/Value'
import { Span } from '../../../html/Span'
import { PriceIcon } from '../../../priceIcon'
import { MAP_STOCKS_SYMBOL } from '../../constant'
import { TextBlink } from '../textBlink'

const getColor = (data) => {
  if (!valByKeyWithDot(data, MAP_STOCKS_SYMBOL.matchPrice)) {
    return '#ececec'
  }

  if (data?.isTicker) {
    return getPriceColor(
      valByKeyWithDot(data, MAP_STOCKS_SYMBOL.matchPrice),
      valByKeyWithDot(data, MAP_STOCKS_SYMBOL.referencePrice),
      valByKeyWithDot(data, MAP_STOCKS_SYMBOL.ceilingPrice),
      valByKeyWithDot(data, MAP_STOCKS_SYMBOL.floorPrice),
    )
  }

  return getPriceColor(
    valByKeyWithDot(data, MAP_STOCKS_SYMBOL.matchPrice),
    valByKeyWithDot(data, MAP_STOCKS_SYMBOL.referencePrice),
  )
}

const SummaryInfoPrice = ({ data }) => {
  const valueMatchPrice = valByKeyWithDot(data, MAP_STOCKS_SYMBOL.matchPrice)

  const matchPrice = !valueMatchPrice
    ? EMPTY_VALUE
    : data?.isTicker
    ? formatVal(valDivThousand(valueMatchPrice, 2))
    : formatVal(valueMatchPrice, 2)

  return (
    <>
      <div className="group-text">
        <Span style={{ color: getColor(data), fontSize: 21 }}>
          <TextBlink
            value={matchPrice}
            otherBlinkValue={matchPrice}
            tradingDate={valByKeyWithDot(data, MAP_STOCKS_SYMBOL.tradingDate)}
          />
        </Span>
        <div>
          <Span style={{ color: '#fff', lineHeight: 1.75 }}>VND</Span>
        </div>
      </div>
      <div className="group-text-second d-flex ali-center">
        <PriceIcon
          item={{
            isTicker: data?.isTicker,
            matchPrice: valByKeyWithDot(data, MAP_STOCKS_SYMBOL.matchPrice),
            referencePrice: valByKeyWithDot(
              data,
              MAP_STOCKS_SYMBOL.referencePrice,
            ),
            ceilingPrice: data?.isTicker
              ? valByKeyWithDot(data, MAP_STOCKS_SYMBOL.ceilingPrice)
              : null,
            floorPrice: data?.isTicker
              ? valByKeyWithDot(data, MAP_STOCKS_SYMBOL.floorPrice)
              : null,
          }}
        />
        <Span style={{ fontSize: 16 }}>
          <strong style={{ color: getColor(data) }}>
            <TextBlink
              value={
                !valueMatchPrice
                  ? EMPTY_VALUE
                  : data?.isTicker
                  ? formatChange(
                      formatVal(
                        valDivThousand(
                          valByKeyWithDot(data, MAP_STOCKS_SYMBOL.priceChange),
                        ),
                      ),
                    )
                  : formatChange(
                      formatVal(
                        valByKeyWithDot(data, MAP_STOCKS_SYMBOL.priceChange),
                      ),
                    )
              }
              tradingDate={valByKeyWithDot(data, MAP_STOCKS_SYMBOL.tradingDate)}
            />
          </strong>
        </Span>
        <Span style={{ fontSize: 16 }}>
          <TextBlink
            value={
              !valueMatchPrice
                ? EMPTY_VALUE
                : valToPercent(
                    valByKeyWithDot(data, MAP_STOCKS_SYMBOL.percentPriceChange),
                  )
            }
            tradingDate={valByKeyWithDot(data, MAP_STOCKS_SYMBOL.tradingDate)}
          />
        </Span>
      </div>
    </>
  )
}

SummaryInfoPrice.propTypes = {
  data: PropTypes.object.isRequired,
}

export default SummaryInfoPrice
