import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import ChartComponent from './ChartComponent'
import { STATE_BUDGET_CHART_TYPE } from './constants'
import Header from './Header'
import {
  getLeftFrameValue,
  getRightFrameValue,
  getStackChartData,
  getStackChartLoading,
  selectIds,
  selectRadioValue,
} from './store/slice'
import {
  getStateBudgetBalanceChart,
  getStateBudgetExpenditureChart,
  getStateBudgetRevenueChart,
} from './store/thunk'

const Container = ({ height, width }) => {
  const dispatch = useDispatch()

  const stackChartData = useSelector(getStackChartData)
  const stackChartLoading = useSelector(getStackChartLoading)
  const leftFrameValue = useSelector(getLeftFrameValue)
  const ids = useSelector(selectIds)
  const rightFrameValue = useSelector(getRightFrameValue)
  const radioValue = useSelector(selectRadioValue)
  const locale = useSelector((state) => state.i18n.locale)

  const isBalanceChart = leftFrameValue === STATE_BUDGET_CHART_TYPE.BALANCE
  const isRevenueChart = leftFrameValue === STATE_BUDGET_CHART_TYPE.REVENUE

  useEffect(() => {
    if (isBalanceChart) {
      dispatch(
        getStateBudgetBalanceChart({
          TimeRange: rightFrameValue,
        }),
      )
    } else if (isRevenueChart) {
      dispatch(
        getStateBudgetRevenueChart({
          TimeRange: rightFrameValue,
        }),
      )
    } else {
      dispatch(
        getStateBudgetExpenditureChart({
          TimeRange: rightFrameValue,
        }),
      )
    }
  }, [leftFrameValue, rightFrameValue, radioValue, locale])

  if (stackChartLoading) {
    return <Loading />
  }

  if (!stackChartData.length) {
    return <NoData />
  }

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header isBalanceChart={isBalanceChart} width={width} />
            {typeof sizes.height === 'number' && (
              <div id="REVENUE_STRUCTURE">
                <ChartComponent
                  width={width}
                  height={height - sizes.height}
                  data={stackChartData}
                  keyXAxis={'time'}
                  leftFrameValue={leftFrameValue}
                  ids={ids}
                  radioValue={radioValue}
                />
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Container
