import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { getMaxDate } from '../../averageTradingValue/store/thunk'
import { getQuarterAndYearKey } from '../../common/helper'
import { getMarginLeverageRatio } from './thunk'

const slice = createSlice({
  name: 'market/marginLoans/getMarginLeverageRatio',
  initialState: {
    isLoading: true,
    data: [],
    origData: [],
    filter: {
      currentYear: null,
      currentQuarter: null,
    },
    maxDate: {
      year: null,
      quarter: null,
    },
  },
  reducers: {
    setLoadingChart: (state, action) => {
      state.isLoading = action.payload
    },
    changeCurrentYear: (state, action) => {
      state.filter.currentYear = action.payload
    },
    changeCurrentQuarter: (state, action) => {
      state.filter.currentQuarter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMarginLeverageRatio.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getMarginLeverageRatio.fulfilled, (state, action) => {
      state.isLoading = false
      const payloadData =
        action.payload?.map((item) => ({
          ...item,
          time: getQuarterAndYearKey(item.quarter, item.year),
          margin: item.nS239 / 1000000000,
          ratio: item.ratio * 100,
        })) || []
      state.origData = payloadData
      state.data = payloadData
        .filter((item) => item.margin && item.ratio)
        .reverse()
    })
    builder.addCase(getMarginLeverageRatio.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
    builder.addCase(getMaxDate.pending, (state) => {
      state.maxDate = {}
    })
    builder.addCase(getMaxDate.fulfilled, (state, action) => {
      state.maxDate.quarter = action.payload?.realQuarter
      state.maxDate.year = action.payload?.realYear
    })
    builder.addCase(getMaxDate.rejected, (state) => {
      state.maxDate = {}
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectOrigData = (state) => state[slice.name].origData
export const selectFilter = (state) => state[slice.name].filter
export const selectMaxDate = (state) => state[slice.name].maxDate

export const { changeCurrentQuarter, changeCurrentYear, setLoadingChart } =
  slice.actions

register(slice.name, slice.reducer)
