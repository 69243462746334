import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Span } from '../../../../../common/html/Span'
import { valToPercent } from '../../../../../utils/Value'
import style from '../index.module.css'

export const PieChartWithHover = ({ data, width, height }) => {
  // Render tooltip
  const getTooltipContent = () => {
    return (
      <div className={style.chartTooltip}>
        <ul>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  <Translate value={item.text} />:
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <PieChartWithFooter
      data={data}
      height={height}
      pieChartProps={{ radius: 35 }}
      renderTooltip={() => getTooltipContent()}
      isUseContainerFooter
      width={width}
    />
  )
}

PieChartWithHover.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
