import { chain } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { VALUE_TIME } from './constants'

export const renderTimeType = (timeType) => {
  switch (timeType) {
    case VALUE_TIME.MONTHLY:
      return I18n.t('bond.overview.issuers.MTD')
    case VALUE_TIME.QUARTERLY:
      return I18n.t('bond.overview.issuers.QTD')
    default:
      return I18n.t('bond.overview.issuers.YTD')
  }
}

export const renderTimeValueBond = (timeType) => {
  switch (timeType) {
    case VALUE_TIME.MONTHLY:
      return I18n.t('bond.overview.issuers.MOM')
    case VALUE_TIME.QUARTERLY:
      return I18n.t('bond.overview.issuers.QOQ')
    default:
      return I18n.t('bond.overview.issuers.YOY')
  }
}
export const formatDataTable = (data) => {
  if (!data || data.length === 0) return []
  return data.map((item) => ({
    ...item,
    name: item.organizationShortName,
    totalValue: item.totalValue,
    id: item.organizationId,
    level: 1,
    parentId: item.organizationId,
    childIds: [],
  }))
}
export const formatDataTableByBon = (data) => {
  if (!data || data.length === 0) return []
  return data.map((item) => ({
    ...item,
    name: item.organizationShortName,
    averageRemainLength: item.remainLength,
    totalValue: item.totalValue,
    id: item.bondId,
    level: 1,
    parentId: item.bondId,
    childIds: [],
  }))
}
export const formatDataTableAverageYtm = (data) => {
  if (!data || data.length === 0) return []

  return chain(data)
    .groupBy('organizationId')
    .map((value) => {
      const newValue = value
        .map((item) => ({
          ...item,
          [item.remainLengthGroup]: item.remainLengthValue,
        }))
        .reduce((object, item) => ({ ...object, ...item }), {})

      return {
        id: newValue.organizationId,
        name: newValue.organizationShortName,
        ...newValue,
      }
    })
    .value()
}

export const formatDataTableTopBid = (data) => {
  if (!data || data.length === 0) return []
  return data.map((item) => ({
    ...item,
    id: item.bondId,
    level: 1,
    parentId: item.bondId,
    childIds: [],
  }))
}

export const formatPrice = (price, rbD0002, isCleanPrice) => {
  if (isNaN(price)) return undefined

  if (price === 0) return 0

  return price - (isCleanPrice && !isNaN(rbD0002) ? rbD0002 : 0)
}

export const getMinMaxdataAverage = (data, listkeyTable) => {
  if (data.length <= 0) {
    return {}
  }
  let res = {}
  for (const key in listkeyTable) {
    data.forEach((element) => {
      if (!res[listkeyTable[key]]) {
        if (element?.[listkeyTable[key]]) {
          res[listkeyTable[key]] = {
            min: element?.[listkeyTable[key]] || null,
            max: element?.[listkeyTable[key]] || null,
          }
        }
      } else {
        if (element?.[listkeyTable[key]]) {
          res[listkeyTable[key]] = {
            min: res?.[listkeyTable[key]].min
              ? res?.[listkeyTable[key]].min > element?.[listkeyTable[key]]
                ? element?.[listkeyTable[key]]
                : res?.[listkeyTable[key]].min
              : element?.[listkeyTable[key]],
            max: res?.[listkeyTable[key]].max
              ? res?.[listkeyTable[key]].max < element?.[listkeyTable[key]]
                ? element?.[listkeyTable[key]]
                : res?.[listkeyTable[key]].max
              : element?.[listkeyTable[key]],
          }
        }
      }
    })
  }
  return res
}

export const calculateSegment = (value, min, max) => {
  const segmentCount = 8
  const segmentSize = (max - min) / segmentCount
  const segmentIndex = Math.floor((value - min) / segmentSize)

  return Math.max(0, Math.min(segmentIndex, segmentCount - 1))
}
