import { useSelector } from 'react-redux'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../common/html/Span'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { formatVal, valToPercent } from '../../../utils/Value'
import { selectListCPISector } from '../cpiAndInflationTable/store/slice'
import style from '../style.module.css'
import { COLORS_LINE_CHART, GROWTH_SECTOR_DATA } from './constants'
import { selectActiveCPISector } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 7,
}

const MARGIN = { ...MARGIN_RECHARTS, top: 20, left: 20, right: 40, bottom: 20 }

export const ChartComponent = ({
  width,
  height,
  keyXAxis,
  lineKeys,
  data,
  currentDateType,
}) => {
  const listCPISector = useSelector(selectListCPISector)
  const activeCPISector = useSelector(selectActiveCPISector)

  const growthSectorTooltip = (valueTooltip) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic' }} className="mb-8">
          <span style={{ fontSize: 11 }}>{valueTooltip.time}</span>
        </div>
        <table>
          <tbody>
            {listCPISector.map((item) => (
              <tr className={style.trTable} key={item.cpivnTypeId}>
                <td className={style.pdLabel}>
                  <div className={style.mr12}>
                    <Span style={{ fontSize: 11 }}>{item.cpivnTypeName}</Span>:
                  </div>
                </td>
                <td
                  style={{
                    textAlign: 'right',
                  }}
                  className={`${style.pdLabel} ${style.fontWeight500}`}
                >
                  <Span
                    style={{
                      fontSize: 11,
                      color:
                        valueTooltip[item.cpivnTypeId] < 0 ? '#ff4752' : '',
                    }}
                  >
                    {valToPercent(valueTooltip[item.cpivnTypeId])}
                  </Span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      {width && height && (
        <>
          <ChartContainer
            data={data}
            width={width}
            height={height}
            keyXAxis={keyXAxis}
            margin={MARGIN}
            xTickNum={SETTINGS.xTickNum}
            yAxis={[
              {
                id: 'left',
                keys: lineKeys,
                isLineChart: true,
                orientation: 'left',
                labelPosition: AXIS_LABEL_POSITION.LEFT,
                tickFormatter: (val) =>
                  formatVal(valToPercent(val, false, true)),
                unitYAxis: '%',
              },
            ]}
            renderCustomTooltip={(payload, decimalLengths) =>
              growthSectorTooltip(payload, decimalLengths)
            }
            timeFrame={
              currentDateType !== GROWTH_SECTOR_DATA.TIME_RANGE[0].value &&
              TIME_RANGES.CUSTOM
            }
            isNotFormatXAxis={
              currentDateType !== GROWTH_SECTOR_DATA.TIME_RANGE[0].value
            }
          >
            {listCPISector.map((item) => (
              <Line
                key={item.cpivnTypeId}
                yAxisId="left"
                dataKey={item.cpivnTypeId}
                type="linear"
                stroke={COLORS_LINE_CHART[item.colorId]}
                dot={false}
                activeDot={false}
                strokeOpacity={
                  !activeCPISector
                    ? 1
                    : activeCPISector === item.cpivnTypeId
                    ? 1
                    : 0.1
                }
                isAnimationActive={false}
                strokeWidth={
                  !activeCPISector
                    ? 1.5
                    : activeCPISector === item.cpivnTypeId
                    ? 2.5
                    : 1.5
                }
              />
            ))}
          </ChartContainer>
        </>
      )}
    </>
  )
}
