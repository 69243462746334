const currentYear = new Date().getFullYear()
const MIN_YEAR_DEFAULT = 2011

export const YEAR_OPTIONS = [
  ...Array(currentYear - MIN_YEAR_DEFAULT + 1).keys(),
].map((item) => {
  return {
    name: `${currentYear - item}`,
    value: currentYear - item,
  }
})
