import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MonetaryService from '../../../../../../core/services/economy/MonetaryService'
import { handleExport } from '../../../../../utils/Export'

export const getMoneySupplyOutstandingLoan = createAsyncThunk(
  'economy/monetary/moneySuppliesCredit/panelMoneySupplyCredit/getMoneySupplyOutstandingLoan',
  async (params, { rejectWithValue }) => {
    try {
      const res = await MonetaryService.getMoneySupplyOutstandingLoan(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMonetaryTypeName = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/getMonetaryTypeName',
  async (params, { rejectWithValue }) => {
    try {
      const res = await MonetaryService.getMonetaryTypeName(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadMoneySupplyOutstandingLoan = createAsyncThunk(
  'economy/monetary/openMarket/panelOpenMarket/downloadMoneySupplyOutstandingLoan',
  async (data) => {
    const res = await MonetaryService.downloadMoneySupplyOutstandingLoan(data)
    handleExport(res.data, res.filename)
  },
)
