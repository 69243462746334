export const tableConstants = [
  {
    id: 'NB121',
    name: 'sector.financialAnalysis.bank.capitalStructure.CURRENT_DEPOSIT',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'CDYG',
    name: 'sector.financialAnalysis.bank.capitalStructure.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NB122',
    name: 'sector.financialAnalysis.bank.capitalStructure.TERM_DEPOSIT',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'TDYG',
    name: 'sector.financialAnalysis.bank.capitalStructure.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NB72',
    name: 'sector.financialAnalysis.bank.capitalStructure.SHORT_TERM_LOAN',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'SLYG',
    name: 'sector.financialAnalysis.bank.capitalStructure.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NB73',
    name: 'sector.financialAnalysis.bank.capitalStructure.MEDIUM_TERM_LOAN',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'MLYG',
    name: 'sector.financialAnalysis.bank.capitalStructure.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'NB74',
    name: 'sector.financialAnalysis.bank.capitalStructure.LONG_TERM_LOAN',
    isYoyGrowth: false,
    isPercent: false,
  },
  {
    id: 'LLYG',
    name: 'sector.financialAnalysis.bank.capitalStructure.YOY_GROWTH',
    isYoyGrowth: true,
    isPercent: false,
  },
  {
    id: 'RT0277',
    name: 'sector.financialAnalysis.bank.capitalStructure.LDR',
    isYoyGrowth: false,
    isPercent: true,
  },
]

export const tableFormat = {
  NB121: 10 ** 9,
  CDYG: 1,
  NB122: 10 ** 9,
  TDYG: 1,
  NB72: 10 ** 9,
  SLYG: 1,
  NB73: 10 ** 9,
  MLYG: 1,
  NB74: 10 ** 9,
  LLYG: 1,
  RT0277: 1,
}
