import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { formatVal, valDivBillion, valToPercent } from '../../../../utils/Value'
import { changeLocaleGrowthChart } from '../growthCategory/store/slice'
import { changeLocaleTypeCommodityChart } from '../outstandingTarget/commodityChart/store/slice'
import { getMaxDateOverview } from '../store/thunk'
import { TdSelect } from './TdSelect'
import { NUM_OF_PERIOD, TIME_TYPE } from './constants'
import {
  addCategoryToList,
  changeIsYearly,
  changeLocale,
  changePeriodNum,
  keepCurrentItems,
  removeCategoryFromList,
  selectCategoryDataTableById,
  selectCategoryIds,
  selectCurrentTimeType,
  selectDataTable,
  selectDataWithColorId,
  selectGroupColumns,
  selectIsChangingTab,
  selectListCategory,
  selectLoadingTableContentCPI,
  selectMinCurrentMonth,
  selectMinCurrentYear,
  selectPeriodNum,
} from './store/slice'
import { balanceOfTradeTableContent } from './store/thunk'

const HORIZONTAL_TRACK_LEFT = 28

export const BalanceOfTrade = ({ width, height }) => {
  const dispatch = useDispatch()

  const dataTable = useSelector(selectDataTable)
  const categoryIds = useSelector(selectCategoryIds)
  const isContentLoading = useSelector(selectLoadingTableContentCPI)
  const groupColumns = useSelector(selectGroupColumns)
  const listCategory = useSelector(selectListCategory)
  const minCurrentMonth = useSelector(selectMinCurrentMonth)
  const minCurrentYear = useSelector(selectMinCurrentYear)
  const periodNum = useSelector(selectPeriodNum)
  const isChangingTab = useSelector(selectIsChangingTab)
  const dataWithColorId = useSelector(selectDataWithColorId)
  const currentTime = useSelector(selectCurrentTimeType)
  const locale = useSelector((state) => state.i18n.locale)

  const [isFirstRender, setIsFirstRender] = useState(true)
  const [newMaxMonth, setNewMaxMonth] = useState(minCurrentMonth)
  const [newMaxYear, setNewMaxYear] = useState(minCurrentYear)

  const isYearly = currentTime === TIME_TYPE.YEARLY

  const getItemById = (data, id) => {
    return data.length && data?.find((item) => item.id === id)
  }

  const onRowClick = (rowId) => {
    if (
      !listCategory?.some((item) => item.id === rowId) &&
      !dataTable
        .filter((item) => item.isSubCategory)
        .find((item) => item.id === rowId)
    ) {
      const category = {
        id: rowId,
        name: getItemById(dataTable, rowId)?.name,
        colorId: getItemById(dataWithColorId, rowId)?.colorId,
      }
      dispatch(addCategoryToList(category))
    } else {
      dispatch(removeCategoryFromList(rowId))
    }
  }

  const scrollToLeftCallback = () => {
    dispatch(changePeriodNum(NUM_OF_PERIOD))
    if (isYearly) {
      setNewMaxYear(minCurrentYear)
    } else {
      setNewMaxMonth(minCurrentMonth)
    }
    setIsFirstRender(false)
  }

  //called only first time, fetch 20 periods
  useEffect(() => {
    setIsFirstRender(true)
    dispatch(
      balanceOfTradeTableContent({
        TimeFrequency: currentTime,
        Limit: periodNum,
      }),
    )
  }, [currentTime, locale])

  //called when is Monthly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxMonth && !isYearly) {
      dispatch(
        balanceOfTradeTableContent({
          TimeFrequency: currentTime,
          Year: minCurrentYear,
          Month: minCurrentMonth,
          Limit: periodNum,
        }),
      )
    }
  }, [newMaxMonth, isFirstRender, locale])

  //called when is Yearly, scroll to left and fetch 5 more periods each time
  useEffect(() => {
    if (newMaxYear && isYearly) {
      dispatch(
        balanceOfTradeTableContent({
          TimeFrequency: currentTime,
          Year: minCurrentYear,
          Month: null,
          Limit: periodNum,
        }),
      )
    }
  }, [newMaxYear, isFirstRender, locale])

  useEffect(() => {
    if (isYearly) {
      dispatch(changeIsYearly(true))
    } else {
      dispatch(changeIsYearly(false))
    }
  }, [currentTime])

  useEffect(() => {
    dispatch(changeLocale(locale))
    dispatch(changeLocaleGrowthChart(locale))
    dispatch(keepCurrentItems(listCategory?.map((item) => item.colorId)))
    dispatch(changeLocaleTypeCommodityChart(locale))
  }, [locale])

  useEffect(() => {
    dispatch(getMaxDateOverview())
  }, [])

  return (
    width &&
    height && (
      <SizeTracker>
        {(size) => (
          <div className="h-100">
            <div className="d-flex j-b">
              <Span style={{ color: '#75797f', fontStyle: 'italic' }}>
                {`${I18n.t('economy.importExport.importExport.UNIT')}: ${I18n.t(
                  'economy.importExport.importExport.BILLION_USD',
                )}`}
              </Span>
            </div>
            {size.height && (
              <div style={{ paddingTop: '10px', height: height - size.height }}>
                {isChangingTab && <Loading />}
                {!isChangingTab && (
                  <Table
                    ids={categoryIds}
                    getDataFromRedux={selectCategoryDataTableById}
                    isLoading={isContentLoading}
                    columnDraggable={false}
                    hasTooltip={false}
                    horizontalTrackLeft={HORIZONTAL_TRACK_LEFT}
                    hasFooter={false}
                    onRowClick={onRowClick}
                    stickyFirstColumn
                    schema={groupColumns.map((item, index) => {
                      const title = item.title
                      const colId = item.key
                      let result = {
                        colId,
                        title,
                        isI18n: false,
                        thStyle: {
                          textAlign: 'right',
                        },
                        tdStyle: {
                          textAlign: 'right',
                        },
                      }
                      if (index === 0) {
                        result = {
                          ...result,
                          isI18n: false,
                          thStyle: {
                            textAlign: 'left',
                          },
                          tdStyle: {
                            textAlign: 'left',
                          },
                          render: (val, rowId) => {
                            return <TdSelect val={val} rowId={rowId} />
                          },
                        }
                      }
                      if (index !== 0) {
                        result = {
                          ...result,
                          render: (val, rowId) => {
                            return (
                              <Span
                                style={{
                                  color:
                                    val < 0
                                      ? '#ff4752'
                                      : !String(rowId).includes('_')
                                      ? ''
                                      : '#75797f',
                                  fontStyle: String(rowId).includes('_')
                                    ? 'italic'
                                    : '',
                                }}
                              >
                                {!String(rowId).includes('_')
                                  ? formatVal(valDivBillion(val))
                                  : valToPercent(val)}
                              </Span>
                            )
                          },
                        }
                      }
                      return result
                    })}
                    scrollToLeftCallback={scrollToLeftCallback}
                    isLazyLoadLeft={true}
                    defaultScrollToRight={isFirstRender}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </SizeTracker>
    )
  )
}
