import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { Link } from '../../../../common/Link'
import { EMPTY_VALUE } from '../../../../constants/Common'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { TABLE_KEYS, TABLE_SCHEMAS } from './constants'

export const getTableSchema = ({ dataById }) => {
  return ['title', 'value'].map((key) => {
    if (key === 'title') {
      return {
        colId: key,
        isI18n: false,
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        render: (data) => <Translate value={data} />,
      }
    }

    return {
      colId: key,
      tdStyle: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
      render: (value, rowId) =>
        dataById[rowId].render ? dataById[rowId].render(value) : value,
    }
  })
}

export const formatApiData = ({ data, locale }) => {
  return TABLE_SCHEMAS.map((item) => {
    switch (item.id) {
      case TABLE_KEYS.FUND_NAME:
        return {
          ...item,
          value: `${data.organizationShortName} - ${data.ticker}`,
        }
      case TABLE_KEYS.TOTAL_NAV:
        return {
          ...item,
          value: formatVal(data[item.id] / 10 ** 9),
          render: (value) => (
            <Span>
              {value} <Translate value="common.UNIT.BILLION_VND" />
            </Span>
          ),
        }
      case TABLE_KEYS.NAV_CCQ:
        return {
          ...item,
          value: formatVal(data[item.id], 0),
          render: (value) => (
            <Span>
              {value} <Translate value="common.UNIT.VND" />
            </Span>
          ),
        }
      case TABLE_KEYS.FUND_TYPE_NAME:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.FUND_STRUCTURE_NAME:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.REGISTER_DATE:
        return {
          ...item,
          value: data[item.id]
            ? formatDateTime(data[item.id], FORMAT.DATE, locale)
            : EMPTY_VALUE,
        }
      case TABLE_KEYS.FUND_MANAGEMENT:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.SUPERVISOR_BANK:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.SCHEDULE_NAME:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.FOREIGN_OWNERSHIP:
        return {
          ...item,
          value: valToPercent(data[item.id]),
        }
      case TABLE_KEYS.MIN_INVEST:
        return {
          ...item,
          value: formatVal(data[item.id], 0),
          render: (value) => (
            <Span>
              {value} <Translate value="common.UNIT.VND" />
            </Span>
          ),
        }
      case TABLE_KEYS.ADDRESS:
        return {
          ...item,
          value: data[item.id],
        }
      case TABLE_KEYS.WEBSITE:
        return {
          ...item,
          value: data[item.id],
          render: (value) => <Link url={value} style={{ color: '#2f9aee' }} />,
        }
      default:
        return {
          ...item,
          value: EMPTY_VALUE,
        }
    }
  })
}
