import { useMemo } from 'react'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import { getRadiusOfScatter } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { formatVal } from '../../../../../utils/Value'
import { DATA_TYPE } from '../peersComparison/constant'
import { COLOR } from './constant'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 5,
}

const YAXIS_ID = 'left'

const ChartLine = ({ data, width, height, dataType, keyChart }) => {
  const keysArray = Object.keys(data)
  const dataChart = useMemo(() => {
    const result = {}
    keysArray.forEach((key) => {
      data[key].forEach((enterpriseItem) => {
        const { dataYear, dataValue, isTTM } = enterpriseItem
        if (result[dataYear]) {
          result[dataYear][key] =
            dataType === DATA_TYPE.BANK &&
            (keyChart === '3' || keyChart === '4')
              ? dataValue * 100
              : dataValue
        } else {
          result[dataYear] = {
            dataYear: isTTM ? 'YTD' : dataYear,
            [key]: dataType === DATA_TYPE.BANK ? dataValue * 100 : dataValue,
          }
        }
      })
    })
    return Object.values(result)
  }, [data])

  const renderTooltip = (tooltipData) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{tooltipData?.dataYear}</Span>
        </div>
        {Object.keys(data).map((key, index) => {
          return (
            <div key={index} className="d-flex j-b">
              <div>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {I18n.t(`bond.comparable.chart.lengend.${key}`) + ': '}
                </Span>
              </div>
              <div className="ml-8">
                <Span style={{ fontSize: 11, fontWeight: 500 }}>
                  {`${formatVal(tooltipData?.[key])} ${
                    dataType === DATA_TYPE.BANK &&
                    (keyChart === '3' || keyChart === '4')
                      ? '%'
                      : I18n.t('bond.comparable.common.times')
                  }`}
                </Span>
              </div>
            </div>
          )
        })}
      </>
    )
  }

  return (
    <ChartContainer
      data={dataChart}
      width={width}
      height={height}
      keyXAxis={'dataYear'}
      timeFrame="CUSTOM"
      xTickNum={SETTINGS.xTickNum}
      tickFormatter={(d) => d}
      hasBarChart={keysArray.length ? true : false}
      // timeFrame="1M"
      margin={{ left: 10, right: 20, top: 20, bottom: 10 }}
      renderCustomTooltip={(payload) => renderTooltip(payload)}
      yAxis={[
        {
          id: YAXIS_ID,
          keys: keysArray,
          isLineChart: true,
          orientation: 'left',
          tickNum: SETTINGS.yTickNum,
        },
      ]}
    >
      {({ chartContentWidth }) => (
        <>
          {keysArray.map((key, index) => (
            <Line
              key={index}
              yAxisId={YAXIS_ID}
              dataKey={key}
              stroke={COLOR[index]}
              activeDot={false}
              isAnimationActive={false}
              type="linear"
              fill={COLOR[index]}
              dot={{
                strokeWidth: getRadiusOfScatter(
                  chartContentWidth,
                  dataChart.length,
                ),
                strokeDasharray: '',
                r: 1,
              }}
            />
          ))}
        </>
      )}
    </ChartContainer>
  )
}

export default ChartLine
