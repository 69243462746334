const CrossHairCursorX = (props) => {
  const {
    height,
    data,
    activeCoordinate,
    isTooltipActive,
    offset: { top, bottom },
  } = props
  if (!isTooltipActive || data.length === 0) return <></>

  return (
    <>
      <g
        stroke="#555555"
        strokeDasharray="3 3"
        strokeWidth={1}
        pointerEvents="none"
      >
        <line
          x1={activeCoordinate.x}
          y1={top}
          x2={activeCoordinate.x}
          y2={height - bottom}
        />
      </g>
    </>
  )
}

export default CrossHairCursorX
