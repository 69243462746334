import moment from 'moment'
import React from 'react'
import { Translate } from 'react-redux-i18n'
import {
  formatVal,
  valByKeyWithDot,
  valDivThousand,
} from '../../../../utils/Value'
import { Span } from '../../../html/Span'
import { MAP_STOCKS_SYMBOL } from '../../constant'
import { TextBlink } from '../textBlink'
import style from './index.module.css'

const ItemInfo = ({ data }) => {
  const mapData = [
    { key: MAP_STOCKS_SYMBOL.openPrice, label: 'corporate.common.LABEL1' },
    { key: MAP_STOCKS_SYMBOL.highPrice, label: 'corporate.common.LABEL2' },
    { key: MAP_STOCKS_SYMBOL.lowPrice, label: 'corporate.common.LABEL3' },
    ...(data.isTicker
      ? [
          {
            key: MAP_STOCKS_SYMBOL.best1Bid,
            label: 'corporate.common.LABEL4',
          },
          {
            key: MAP_STOCKS_SYMBOL.best1Offer,
            label: 'corporate.common.LABEL5',
          },
        ]
      : []),
  ]

  const getAtcAto = () => {
    if (
      moment().unix() >= moment().set('hour', 9).set('minute', 0).unix() &&
      moment().unix() <= moment().set('hour', 9).set('minute', 15).unix()
    ) {
      return 'ATO'
    }

    if (moment().unix() >= moment().set('hour', 14).set('minute', 30).unix()) {
      return 'ATC'
    }
  }

  const getValue = (key) => {
    if (
      [MAP_STOCKS_SYMBOL.best1Bid, MAP_STOCKS_SYMBOL.best1Offer].includes(
        key,
      ) &&
      getAtcAto()
    ) {
      return typeof valByKeyWithDot(data, key) === 'number' &&
        !valByKeyWithDot(data, key)
        ? getAtcAto()
        : data?.isTicker
        ? formatVal(valDivThousand(valByKeyWithDot(data, key)))
        : formatVal(valByKeyWithDot(data, key))
    }

    return data?.isTicker
      ? formatVal(valDivThousand(valByKeyWithDot(data, key)))
      : formatVal(valByKeyWithDot(data, key))
  }

  return mapData.map((item) => {
    return (
      <div
        style={{ marginBottom: 2 }}
        className={style.container}
        key={item.key}
      >
        <Span style={{ fontSize: 11 }}>
          <Translate value={item.label} className={style.title} />
        </Span>
        <Span style={{ fontSize: 11 }}>
          <TextBlink
            value={getValue(item.key)}
            tradingDate={valByKeyWithDot(data, MAP_STOCKS_SYMBOL.tradingDate)}
          />
        </Span>
      </div>
    )
  })
}

export default ItemInfo
