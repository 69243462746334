import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import {
  getMonthAndYearInDataRange,
  getMonthAndYearKey,
} from '../../common/helper'
import { growthSectorChart } from './thunk'

const slice = createSlice({
  name: 'economy/cpiAndInflation/growthSector',
  initialState: {
    isLoading: true,
    data: [],
    activeCPISector: null,
  },
  reducers: {
    changeActiveCPISector: (state, action) => {
      state.activeCPISector = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(growthSectorChart.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(growthSectorChart.fulfilled, (state, action) => {
      const payloadData = action.payload || []
      state.data = convertData(payloadData)
      state.isLoading = false
    })
    builder.addCase(growthSectorChart.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectActiveCPISector = (state) =>
  state[slice.name].activeCPISector

export const { changeActiveCPISector } = slice.actions

register(slice.name, slice.reducer)

const convertData = (data) => {
  //convert months and years to date keys
  const getDataKeyFromRange = getMonthAndYearInDataRange(data).map((item) =>
    getMonthAndYearKey(item.month, item.year),
  )

  //get all cpiVNId and corresponding value from specified data range
  const dataWithMonthAndYear = (month, year) => {
    return data
      .filter((item) => item.month === month && item.year === year)
      .map((item) => ({
        value: item.value ?? 0,
        cpivnTypeId: item.cpivnTypeId ?? 0,
      }))
  }

  const getDataValueFromRange = getMonthAndYearInDataRange(data).map((item) => [
    ...dataWithMonthAndYear(item.month, item.year),
  ])

  const getDataKeyIdValueFromRange = getDataValueFromRange.length
    ? getDataValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.cpivnTypeId]: e.value }
        }),
      )
    : []

  //convert to array of objects, each object contain date keys and pairs of cpiVNId-corresponding value
  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: getDataKeyFromRange[i],
  }))
}
