import { Translate } from 'react-redux-i18n'
import Row from '../../common/Row'
import ToiTableHead from '../../common/ToiTableHead'
import style from '../../index.module.css'

const ValueAssetYieldRow = () => {
  return (
    <Row className={style.trHeader}>
      <ToiTableHead
        colSpan={2}
        styleProps={{
          borderLeft: '1px solid #3e485a',
          borderRight: '1px solid #1b2029',
        }}
      >
        <Translate value="corporate.businessModel.segmentalAnalysis.VALUE" />
      </ToiTableHead>
      <ToiTableHead
        colSpan={1}
        styleProps={{ borderRight: '1px solid #3e485a' }}
      >
        <Translate value="corporate.businessModel.segmentalAnalysis.PROPORTION" />
      </ToiTableHead>
      <ToiTableHead colSpan={2}>
        <Translate value="corporate.businessModel.segmentalAnalysis.VALUE" />
      </ToiTableHead>
      <ToiTableHead
        colSpan={1}
        styleProps={{
          borderLeft: '1px solid #1b2029',
          borderRight: '1px solid #3e485a',
          whiteSpace: 'nowrap',
        }}
      >
        <Translate value="corporate.businessModel.segmentalAnalysis.PROPORTION" />
      </ToiTableHead>
    </Row>
  )
}

export default ValueAssetYieldRow
