import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import style from '../index.module.css'
import { selectIsShowTickerFilter, setIsShowTickerFilter } from '../store/slice'

const ButtonShowTicker = () => {
  const dispatch = useDispatch()

  const isShowTickerFilter = useSelector(selectIsShowTickerFilter)

  const handleShowAddTicker = () => dispatch(setIsShowTickerFilter(true))

  return (
    <div className={`d-flex j-b ali-center ${style.mb8}`}>
      <div className={style.w30}>
        <Button
          onClick={handleShowAddTicker}
          className={`btn btn-blue-w disable h-20 d-flex ali-center ${style.btnShowTicker} ${style.mr150}`}
          type="button"
          style={{ opacity: isShowTickerFilter ? 0.4 : 1 }}
          disabled={isShowTickerFilter}
        >
          <div>
            <i className="icon-plus" />
          </div>
          <Translate value="watchlistBond.watchlistDetail.BTN_ADD_TICKER" />
        </Button>
      </div>
      <p className="note">
        <Translate value="watchlistBond.watchlistDetail.NOTE" />
      </p>
    </div>
  )
}

export default ButtonShowTicker
