import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetStore } from '../store/slice'

const withWrapperPopupIndicator = (PopupIndicator) => (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return <PopupIndicator {...props} />
}

export default withWrapperPopupIndicator
