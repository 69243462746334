import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../../common/html/Span'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import DispatchActionTab from '../../../../../../common/tabs/DispatchActionTab'
import { selectSectorInfo } from '../../../../../../common/topInfo/sectorTopInfo/store/slice'
import { SelectType } from '../../../common/SelectType'
import { chartYearOptions, quarterTypeOptions } from '../../../constants'
import {
  formatDataPieChart,
  getQuarterYearParams,
  getTranslateSourceNote,
} from '../../../helps'
import { ChartComponent } from './ChartComponent'
import { chartFormat } from './constants'
import {
  changeQuarterType,
  changeRetryFlag,
  changeYearType,
  selectChartData,
  selectChartLoading,
  selectQuarterType,
  selectRetryFlag,
  selectYearType,
} from './store/slice'
import { getLoanStructureChartData } from './store/thunk'

export const LoanStructureChart = ({ width, height, dataType }) => {
  const dispatch = useDispatch()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const sectorInfo = useSelector(selectSectorInfo)
  const loading = useSelector(selectChartLoading)
  const data = useSelector(selectChartData)
  const retryFlag = useSelector(selectRetryFlag)
  const yearType = useSelector(selectYearType)
  const quarterType = useSelector(selectQuarterType)

  // Get data
  const getData = useMemo(() => {
    if (data.length) {
      return formatDataPieChart(data[0], chartFormat)
    }
  }, [data])

  const getSourceNote = useMemo(() => {
    return getTranslateSourceNote([getData], true)
  }, [locale, getData])

  // Actions
  const onChangeYear = (value) => {
    dispatch(changeYearType(value))
  }

  const onChangeQuarterType = (item) => {
    dispatch(changeQuarterType(item.value))
  }

  // Use effect
  useEffect(() => {
    if (sectorInfo && sectorInfo.icbId && retryFlag) {
      const paramsArr = getQuarterYearParams(5, true).map(
        ({ year, quarter }) => ({
          IcbId: sectorInfo.icbId,
          ExchangeCode: dataType,
          Year: year,
          Quarter: quarter,
        }),
      )
      dispatch(getLoanStructureChartData(paramsArr))
    }
  }, [sectorInfo, retryFlag])

  useEffect(() => {
    if (!sectorInfo || !sectorInfo.icbId || retryFlag) return
    dispatch(
      getLoanStructureChartData({
        IcbId: sectorInfo.icbId,
        ExchangeCode: dataType,
        Year: yearType,
        Quarter: quarterType,
      }),
    )
  }, [yearType, quarterType, sectorInfo])

  useEffect(() => {
    dispatch(changeRetryFlag(true))
  }, [dataType])

  return (
    <div id="capitalLoanStructureChart">
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex" data-html2canvas-ignore="true">
              <SelectType
                options={chartYearOptions}
                activeTab={yearType}
                onChangeTab={onChangeYear}
                style={{ width: 86, marginRight: 8 }}
              />
              <DispatchActionTab
                data={quarterTypeOptions}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={quarterType}
                onChangeTab={onChangeQuarterType}
              />
            </div>
            {size.height && (
              <ChartComponent
                data={getData}
                width={width}
                height={height - size.height}
                loading={loading}
              />
            )}
            <Span
              className="canvas-chart-title t-left"
              style={{ fontSize: 11, fontStyle: 'italic', opacity: 0.4 }}
            >
              {getSourceNote}
            </Span>
          </>
        )}
      </SizeTracker>
    </div>
  )
}
