import PropTypes from 'prop-types'
import { EMPTY_VALUE } from '../../constants/Common'
import { getThreeColorByValue, getTwoColorByValue } from '../../utils/Color'

export const CellWithColor = ({ val, format, color, isPercent }) => {
  return (
    <div
      style={{
        color: color
          ? color
          : isNaN(val)
          ? ''
          : isPercent
          ? getThreeColorByValue(+val, true)
          : getTwoColorByValue(+val),
      }}
    >
      {val === 0 ? EMPTY_VALUE : format ? format(val) : val}
    </div>
  )
}

CellWithColor.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  format: PropTypes.func,
}
