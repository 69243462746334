import NewsAndEvents from '.'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'

const PanelNewsAndEvents = ({ panelRefs, panelKey, sizes, setSizes }) => {
  return (
    <Panel
      title={'sector.sectorConstituents.overview.NEWS_AND_EVENTS'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
    >
      <NewsAndEvents
        width={formatNumber(sizes[panelKey].width)}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
      />
    </Panel>
  )
}

export default PanelNewsAndEvents
