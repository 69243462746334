import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import MasterService from '../../../../core/services/common/MasterService'
import WatchlistService from '../../../../core/services/watchlist/WatchlistService'
import {
  setIsShowPopup,
  setIsShowWatchlistDetail,
} from '../../../myWatchlist/store/slice'
import { CREATE_WATCHLIST } from '../../../myWatchlist/store/thunk'
import { keyBy } from '../../../utils/Common'
import EventEmitter, { LOADING_TABLE_DE } from '../../../utils/EventEmitter'
import { default as UseI18n, default as useI18n } from '../../hooks/useI18n'
import { Input } from '../../html/Input'
import { Span } from '../../html/Span'
import { Loading } from '../../loading'
import { SizeTracker } from '../../sizeTracker'
import Tree from '../../tree'
import { getAllChildrenItem } from '../../tree/helper'
import { encodeWatchlistId } from '../helper'
import { keys, selectLoading, selectWatchlist } from '../store/slice'
import { getWatchListThunk } from '../store/thunk'
import PopupNoticeAddSector from './PopupNoticeAddSector'
import style from './index.module.css'

const NoWatchlist = () => {
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setIsShowPopup(true))
    dispatch(setIsShowWatchlistDetail(true))
  }

  return (
    <div className={style.noWatchlistContainer}>
      <div className={style.noWatchlist}>
        <Span style={{ fontSize: 10 }}>
          <Translate value="market.event.NO_WATCHLIST" />
        </Span>
      </div>
      <div className={style.createWL}>
        <i className={`icon-arrow-right-bold ${style.iconGotoWl}`} />
        &nbsp;
        <Span style={{ fontSize: 11, opacity: 0.4 }}>
          <Translate value="market.event.GO_TO" />
        </Span>
        &nbsp;
        <Span onClick={handleClick}>
          <Translate
            value="market.event.CREATE_WATCHLIST"
            className={style.linkCreate}
          />
        </Span>
      </div>
    </div>
  )
}

const PADDING_TOP = 12
const PADDING_BOTTOM = 15

const GroupStock = ({
  exchange,
  icb,
  handleAddTicker,
  isIndicesSectors,
  isOnlyEnableLastExchange,
  inputClass,
}) => {
  const dispatch = useDispatch()

  const loadingWatchlist = useSelector(selectLoading(keys.WATCHLIST))
  const watchlist = useSelector(selectWatchlist)

  const [treeData, setTreeData] = useState({})
  const [valueSearch, setValueSearch] = useState('')
  const [isShowPopupNotice, setIsShowPopupNotice] = useState(false)
  const [icbSelected, setIcbSelected] = useState({})

  const exchangeText = useI18n('tool.dataExplorer.EXCHANGE')
  const icbText = useI18n('tool.dataExplorer.SECTOR')
  const watchlistText = useI18n('tool.dataExplorer.WATCHLIST')

  useEffect(() => {
    if (!isIndicesSectors) {
      dispatch(getWatchListThunk())
    }
  }, [])

  useEffect(() => {
    const getWatchlist = () => dispatch(getWatchListThunk())
    EventEmitter.subscribe(CREATE_WATCHLIST, getWatchlist)
    return () => EventEmitter.unsubscribe(CREATE_WATCHLIST, getWatchlist)
  }, [])

  useEffect(() => {
    if (!loading) {
      const newTree = {
        exchange: {
          groupName: exchangeText,
          children: exchange.exchangesTree,
        },
        icb: {
          icbName: icbText,
          children: icb.ICBsTree,
        },
      }
      if (!isIndicesSectors) {
        const watchlistHandled = watchlist.length
          ? watchlist
          : [
              {
                watchListId: 'null',
                watchListName: <NoWatchlist />,
                isNotTextEllipsis: true,
              },
            ]
        newTree.watchlist = {
          watchListName: watchlistText,
          children: keyBy(
            watchlistHandled.map((watchlist, index) => {
              return {
                ...watchlist,
                children: {},
                watchlistHandleId: encodeWatchlistId(watchlist, index),
              }
            }),
            'watchlistHandleId',
          ),
        }
      }
      setTreeData(newTree)
    }
  }, [exchange.exchangesTree, icb.ICBsTree, watchlist])

  const handleDoubleClickItem = async (item) => {
    if (!isIndicesSectors) {
      EventEmitter.dispatch(LOADING_TABLE_DE, true)
      if (item.watchListId && watchlist.length) {
        const response = await WatchlistService.getWatchlistDetail({
          watchListId: item.watchListId,
        })
        handleAddTicker(response?.data?.details ? response?.data?.details : [])
      } else if (item.groupId) {
        if (isOnlyEnableLastExchange && Object.keys(item.children).length) {
          EventEmitter.dispatch(LOADING_TABLE_DE, false)
          return
        }
        const response = await MasterService.getOrganizationsByExchange({
          GroupId: item.groupId,
        })
        handleAddTicker(response.success ? response.data : [])
      } else if (item.icbId) {
        const response = await MasterService.getOrganizationsByIcb({
          ICBId: item.icbId,
        })
        handleAddTicker(response.success ? response.data : [])
      }
      EventEmitter.dispatch(LOADING_TABLE_DE, false)
    } else {
      if (item.groupId) {
        handleAddTicker(getAllChildrenItem(item))
      } else if (item.icbId) {
        if (!Object.keys(item.children).length) {
          handleAddTicker([item])
        } else {
          setIcbSelected(item)
          setIsShowPopupNotice(true)
        }
      }
    }
  }

  const handleChangeSearch = (e) => setValueSearch(e.target.value)

  const handleClosePopup = () => setIsShowPopupNotice(false)

  const loading = !isIndicesSectors
    ? exchange.loading || icb.loading || loadingWatchlist
    : exchange.loading || icb.loading

  const search = UseI18n('tool.dataExplorer.SEARCH')

  return (
    <div
      className={isIndicesSectors ? 'h-100' : style.groupStockContainer}
      style={{ paddingTop: PADDING_TOP }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Span className={style.titleSearch} style={{ fontSize: 10 }}>
              {isIndicesSectors ? (
                <Translate value="tool.dataExplorer.INDEX_SECTOR" />
              ) : (
                <Translate value="tool.dataExplorer.GROUP_STOCK" />
              )}
            </Span>
            <div
              className={`form-search-inbox ${style.search} ${style.pb18px} ${inputClass}`}
            >
              <Input
                type="text"
                className="search-inbox bg-grey h-20"
                placeholder={search}
                onChange={handleChangeSearch}
              />
              <button type="button">
                <i className="icon-search-2" />
              </button>
            </div>
            {size.height && (
              <div
                className="position-relative"
                style={{
                  height: `calc(100% - ${
                    size.height + PADDING_TOP + PADDING_BOTTOM
                  }px)`,
                }}
              >
                {loading ? (
                  <Loading />
                ) : (
                  <Tree
                    valueSearch={valueSearch}
                    treeData={treeData}
                    nameKey={['groupName', 'icbName', 'watchListName']}
                    doubleClickItem={handleDoubleClickItem}
                    sortKey="groupOrder"
                  />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
      {isIndicesSectors && isShowPopupNotice && (
        <PopupNoticeAddSector
          handleClose={handleClosePopup}
          handleSubmit={handleAddTicker}
          icb={icbSelected}
        />
      )}
    </div>
  )
}

GroupStock.propTypes = {
  exchange: PropTypes.object.isRequired,
  icb: PropTypes.object.isRequired,
  isIndicesSectors: PropTypes.bool,
  isOnlyEnableLastExchange: PropTypes.bool,
  inputClass: PropTypes.string,
}

GroupStock.defaultProps = {
  isIndicesSectors: false,
  isOnlyEnableLastExchange: false,
}

export default GroupStock
