import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import { ReactComponent as IconRename } from '../../icon/icon-rename.svg'
import PopupAddIndicator from '../../popup/popupAddIndicator'
import { removeIndicator } from '../../popup/popupAddIndicator/store/slice'
import { selectIndicators } from '../../store/slice'
import { keyToolHeaderSettings, VIEW_TYPE } from '../constants'
import { getNameIndicator } from '../helper'
import {
  changeSettings,
  changeTreeSecuritiesIndicator,
  selectTreeSecuritiesIndicator,
  selectViewType,
} from '../store/slice'
import style from '../style.module.css'
import TimeSecurities from './timeSecurities'

const IndicatorItemCard = ({
  indicatorItem,
  index,
  typeIndicator,
  itemType,
  indicatorLatestPeriod,
  isViewByLatestPeriod,
}) => {
  const dispatch = useDispatch()

  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const indicators = useSelector(selectIndicators)
  const indicator = indicators.find((i) => i.id === +indicatorItem.id)
  const viewType = useSelector(selectViewType)

  const [isShow, setIsShow] = useState(false)

  const handleRemoveIndicator = () => {
    dispatch(
      removeIndicator({
        indicatorId: +indicatorItem.id,
        indicatorTimeFrequency: indicatorItem.timeFrequency,
        typeIndicator: typeIndicator,
        uniqueID: indicatorItem?.uniqueID,
      }),
    )
    const newObject = JSON.parse(JSON.stringify(treeSecuritiesIndicator))
    Object.values(newObject[itemType]).forEach((element) => {
      element.splice(index, 1)
    })
    dispatch(changeTreeSecuritiesIndicator(newObject))
  }

  const handleChangeSettingViewByLatestPeriod = () => {
    dispatch(
      changeSettings({
        [keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD]: false,
      }),
    )
  }

  const onEdit = () => {
    setIsShow(true)
  }

  return (
    <>
      <div key={indicator?.uniqueID ?? indicator?.id} className={style.card}>
        <div className={style.cardHeader}>
          <Span
            style={{ fontSize: 11, width: 'calc(100% - 60px)' }}
            className={style.cardTitleIndicator}
          >
            <TextEllipsis
              isI18n={false}
              text={getNameIndicator(indicator, indicatorItem?.timeFrequency)}
            />
          </Span>
          <div className="align-center">
            <IconRename
              className={[style.iconRename, style.iconContainer].join(' ')}
              onClick={onEdit}
            />
            <i
              className={[
                'icon-delete-stroke',
                style.iconDeleteStroke,
                style.iconContainer,
              ].join(' ')}
              onClick={handleRemoveIndicator}
            />
          </div>
        </div>
      </div>
      {viewType === VIEW_TYPE.BY_SECURITIES && (
        <TimeSecurities
          typeIndicator={typeIndicator}
          indicatorItem={indicatorItem}
          indicatorLatestPeriod={indicatorLatestPeriod}
          isViewByLatestPeriod={isViewByLatestPeriod}
          onChange={handleChangeSettingViewByLatestPeriod}
        />
      )}
      {isShow && (
        <PopupAddIndicator
          setIsShow={setIsShow}
          typeIndicator={typeIndicator}
          indicatorItem={indicatorItem}
        />
      )}
    </>
  )
}

export default IndicatorItemCard
