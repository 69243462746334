import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { CHART_TYPE } from '../constant'
import { getColumnChart, getLineChart } from './thunk'

const initialState = {
  chartType: CHART_TYPE.COLUMN,
  data: [],
  loading: true,
}

const slice = createSlice({
  name: 'corporate/peerValuation/overview/valuation',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeChartType: (state, action) => {
      state.chartType = action.payload
    },
  },
  extraReducers: (builder) => {
    //Get ColumnChart
    builder.addCase(getColumnChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getColumnChart.fulfilled, (state, action) => {
      state.loading = false
      const data = [...(action.payload || [])]
      state.data = data.reverse().map((item) => {
        const rs = { ...item }
        Object.keys(rs).forEach((key) => {
          if (rs[key] === null) rs[key] = undefined
        })
        return rs
      })
    })
    builder.addCase(getColumnChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    //Get LineChart
    builder.addCase(getLineChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getLineChart.fulfilled, (state, action) => {
      state.loading = false
      const data = [...(action.payload || [])]
      state.data = data.sort(
        (a, b) =>
          new Date(a.tradingDateId).getTime() -
          new Date(b.tradingDateId).getTime(),
      )
    })
    builder.addCase(getLineChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartType = (state) => state[slice.name].chartType
export const selectChartData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

export const { resetStore, changeChartType } = slice.actions

register(slice.name, slice.reducer)
