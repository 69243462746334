export const COLORS_LINE_CHART = [
  '#c7ff58',
  '#9c54e4',
  '#185999',
  '#ecffdf',
  '#f57f07',
  '#c52e14',
  '#975000',
  '#1a36a9',
  '#57426d',
  '#f9c105',
  '#a103fc',
  '#fc6203',
  '#03fca5',
  '#03d7fc',
  '#fc03f4',
  '#90fc03',
  '#628200',
  '#3452eb',
  '#eb3462',
  '#9b8e9e',
]
