import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { COMPONENT } from '../../../../configs/Layout'
import useComGroup from '../../../common/hooks/useComGroup'
import PanelFilter from '../../../common/panelFilter'
import { selectBasicInfo } from '../../../common/topInfo/stockTopInfo/store/slice'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { PanelFirstChart } from './chart/PanelFirstChart'
import { PanelSecondChart } from './chart/PanelSecondChart'
import { FinancialStatementFilter } from './filter'
import { resetIndicatorMapping, selectStatement } from './store/slice'
import { PanelTable } from './table/PanelTable'

const MAP_KEY = {
  FINANCIAL_FILTER: 'FINANCIAL_FILTER',
  FINANCIAL_TABLE: 'FINANCIAL_TABLE',
  FINANCIAL_CHART_1: 'FINANCIAL_CHART_1',
  FINANCIAL_CHART_2: 'FINANCIAL_CHART_2',
}

const FILTER_WIDTH = 270

const MAP_SIZE = {
  [`${MAP_KEY.FINANCIAL_FILTER}`]: {
    width: FILTER_WIDTH - COMPONENT.MARGIN,
    height: `100%`,
    top: 0,
    left: 0,
    minWidth: FILTER_WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    minTop: 0,
    minLeft: 0,
    filterResize: [
      {
        key: MAP_KEY.FINANCIAL_TABLE,
        ratioWidth: 1,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_TABLE}`]: {
    width: `calc(65% - ${FILTER_WIDTH * 0.65 + COMPONENT.MARGIN}px)`,
    height: `100%`,
    top: 0,
    left: FILTER_WIDTH + COMPONENT.MARGIN,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT * 2,
    minTop: 0,
    minLeft: FILTER_WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.FINANCIAL_CHART_1,
        ratioWidth: 1,
        ratioLeft: 1,
      },
      {
        key: MAP_KEY.FINANCIAL_CHART_2,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.FINANCIAL_CHART_1}`]: {
    width: `calc(35% - ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(65% + ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px`,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.35 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: 0,
    minLeft:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 +
      FILTER_WIDTH +
      COMPONENT.MARGIN * 2,
    horizontalResize: [
      {
        key: MAP_KEY.FINANCIAL_CHART_2,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.FINANCIAL_CHART_2}`]: {
    width: `calc(35% - ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(65% + ${FILTER_WIDTH * 0.35 + COMPONENT.MARGIN * 2}px`,
    minWidth:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.35 -
      COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft:
      (COMPONENT.MIN_SIZE.WIDTH * 3.3 - FILTER_WIDTH) * 0.65 +
      FILTER_WIDTH +
      COMPONENT.MARGIN * 2,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)
  const statement = useSelector(selectStatement)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(resetIndicatorMapping())
    }
  }, [basicInfo?.organizationId])

  return (
    <>
      <PanelFilter
        title="corporate.financialData.common.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FINANCIAL_FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <FinancialStatementFilter
          height={formatNumber(
            sizes[MAP_KEY.FINANCIAL_FILTER].height - HEADER_PANEL_HEIGHT,
          )}
        />
      </PanelFilter>

      <PanelTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
      />

      <PanelFirstChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        statement={statement}
        statementItem={comGroup}
      />

      <PanelSecondChart
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        statement={statement}
        statementItem={comGroup}
      />
    </>
  )
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
