import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import SelectBorderBlue from '../../../common/selectCustom/SelectBorderBlue'
import { SELECT_TIME_OPTIONS } from '../constants'
import { changeFilterTime, selectFilter } from '../store/slice'

const DropdownVolume = () => {
  const dispatch = useDispatch()

  const filter = useSelector(selectFilter)

  const setFilterTime = (data) => {
    dispatch(changeFilterTime(data))
  }

  return (
    <div className="w-100px ml-3">
      <SelectCustom
        isI18n
        renderSelect={SelectBorderBlue}
        selectData={SELECT_TIME_OPTIONS}
        value={filter.times}
        handleChange={setFilterTime}
      />
    </div>
  )
}

export default DropdownVolume
