import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { CRITERIAS, EXCHANGES, SIZE_BYES } from '../constants'
import { getHeatMapChart, refreshHeatMapChart } from './thunk'

const getTickersById = (data) => {
  const tickers = data.reduce((result, item) => {
    return [...result, ...item.tickers]
  }, [])

  return tickers.reduce((result, ticker) => {
    return {
      ...result,
      [ticker.organizationId]: ticker,
    }
  }, {})
}

const FILTER_INITIAL = {
  exchange: EXCHANGES.ALL,
  sizeBy: SIZE_BYES.MARKET_CAPITALIZATION,
  criteria: CRITERIAS['1_DAY_PERFORMANCE'],
}

const slice = createSlice({
  name: 'market/heatmap',
  initialState: {
    tickerById: {},
    data: [],
    filter: FILTER_INITIAL,
    isFullNode: false,
    isLoading: true,
  },
  reducers: {
    changeExchange: (state, action) => {
      state.filter.exchange = action.payload
    },
    changeSizeBy: (state, action) => {
      state.filter.sizeBy = action.payload
    },
    changeCriteria: (state, action) => {
      state.filter.criteria = action.payload
    },
    changeIsFullNode: (state, action) => {
      state.isFullNode = action.payload
    },
    resetFilter: (state) => {
      state.filter = FILTER_INITIAL
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHeatMapChart.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getHeatMapChart.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
      state.tickerById = getTickersById(action.payload)
      state.isFullNode = false
    })
    builder.addCase(getHeatMapChart.rejected, (state) => {
      state.isLoading = true
    })

    builder.addCase(refreshHeatMapChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.tickerById = getTickersById(action.payload)
      state.isFullNode = false
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectIsLoading = (state) => state[slice.name].isLoading
export const selectTicker = (id) => (state) => state[slice.name].tickerById[id]
export const selectValueTicker = (id, attr) => (state) =>
  state[slice.name].tickerById[id][attr]
export const selectExchange = (state) => state[slice.name].filter.exchange
export const selectSizeBy = (state) => state[slice.name].filter.sizeBy
export const selectCriteria = (state) => state[slice.name].filter.criteria
export const selectIsFullNode = (state) => state[slice.name].isFullNode

export const {
  changeCriteria,
  changeExchange,
  changeSizeBy,
  changeIsFullNode,
  resetFilter,
} = slice.actions

register(slice.name, slice.reducer)
