export const CONTAINER_CHART_TYPES = {
  PIE: 'PieChart',
  COMPOSED: 'ComposedChart',
}

export const CHART_LAYOUT = {
  HORIZONTAL: 'horizontal',
  VERTICAL: 'vertical',
}

export const YAXIS_ORIENTATION = {
  RIGHT: 'right',
  LEFT: 'left',
}

export const YAXIS_TYPES = {
  NUMBER: 'number',
  CATEGORY: 'category',
}

export const YAXIS_TICK_COUNT = 5

export const DEFAULT_XAXIS_DATA_KEY = 'xAxis'

export const DEFAULT_PRE_VALUE_KEY = 'column'

export const DEFAULT_COLORS = [
  '#ecffdf',
  '#c7ff58',
  '#75d4ff',
  '#930037',
  '#006d8c',
  '#ff2500',
  '#56c10a',
  '#185999',
  '#f57f07',
  '#744d23',
  '#f9c105',
  '#009fe6',
  '#f4327b',
  '#9c54e4',
  '#4bd9be',
  '#00763e',
  '#975000',
  '#c52e14',
  '#1a36a9',
  '#9d9e9e',
  '#42b9e9',
  '#27699b',
  '#35a087',
  '#f1ffeb',
  '#f98e30',
  '#f1c594',
  '#b7cadb',
  '#fad9e6',
  '#74f4ff',
  '#ffd365',
]
