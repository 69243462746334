import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

ItemScroll.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export default ItemScroll
