import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectCriteria } from '../store/slice'
import { MIN_FONT_SIZE } from './constants'
import { getDisplayValue } from './helper'
import style from './sector.module.css'
import { TickerTreeMap } from './TickerTreeMap'

export const Sector = ({
  width,
  height,
  data,
  bgColor,
  fontSize,
  rootRef,
  node,
  rootSize,
}) => {
  const [isHideTicker, setIsHideTicker] = useState(false)
  const name = data.name.toUpperCase()
  const [size, setSize] = useState({
    left: node.x0,
    top: node.y0,
    width,
    height,
  })
  const criteria = useSelector(selectCriteria)

  useEffect(() => {
    setSize({
      left: node.x0,
      top: node.y0,
      width,
      height,
    })
  }, [node])

  const changeFullNode = (isFullNode) => {
    setSize(
      isFullNode
        ? {
            left: 0,
            top: 0,
            ...rootSize,
            zIndex: 999,
          }
        : {
            left: node.x0,
            top: node.y0,
            width,
            height,
          },
    )
  }

  const onClick = () => {
    setIsHideTicker(!isHideTicker)
  }

  const getTickerTreeMapData = (data) => {
    const { tickers, name, icbCode } = data

    return {
      name,
      icbCode,
      children: tickers,
    }
  }

  return (
    <div
      className={style.node}
      style={{
        ...size,
        backgroundColor: bgColor,
      }}
    >
      <div className={style.container} onClick={onClick}>
        {!isHideTicker && (
          <TickerTreeMap
            sectorName={name}
            title={data.name}
            parentRate={data.rate}
            width={size.width}
            height={size.height}
            bgColor={bgColor}
            data={getTickerTreeMapData(data)}
            rootRef={rootRef}
            changeFullNode={changeFullNode}
          />
        )}
        {isHideTicker && (
          <div style={{ fontSize }}>
            {(data.rate === 0 || data.rate) && fontSize > MIN_FONT_SIZE && (
              <>
                <div className="text-center">{name}</div>
                <div className="text-center">
                  {getDisplayValue(data.rate, criteria)}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

Sector.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  data: PropTypes.object.isRequired,
  bgColor: PropTypes.string.isRequired,
  fontSize: PropTypes.number.isRequired,
  rootRef: PropTypes.object.isRequired,
  node: PropTypes.object.isRequired,
  rootSize: PropTypes.object.isRequired,
}
