export const maxTicker = 4000

export const typePopupIndicator = {
  DE01_SC07: 'DE01_SC07',
  DE01_SC08: 'DE01_SC08',
  DE01_SC09: 'DE01_SC09',
  DE01_SC10: 'DE01_SC10',
  DE01_SC11: 'DE01_SC11',
  DE01_SC12: 'DE01_SC12',
  DE01_SC13: 'DE01_SC13',
  DE01_SC14: 'DE01_SC14',
  DE01_SC15: 'DE01_SC15',
  DE01_SC16: 'DE01_SC16',
  DE01_SC17: 'DE01_SC17',
  DE01_SC18: 'DE01_SC18',
  DE01_SC19: 'DE01_SC19',
  DE01_SC20: 'DE01_SC20',
  DE01_SC21: 'DE01_SC21',
  DE01_SC26: 'DE01_SC26',
  DE01_SC27: 'DE01_SC27',
  DE01_SC28: 'DE01_SC28',
  DE01_SC29: 'DE01_SC29',
  DE01_SC30: 'DE01_SC30',
  DE01_SC31: 'DE01_SC31',
  DE01_SC32: 'DE01_SC32',
  DE01_SC33: 'DE01_SC33',
  DE01_SC34: 'DE01_SC34',
  DE01_SC35: 'DE01_SC35',
  DE01_SC36: 'DE01_SC36',
}

export const typePopupTemplate = {
  FS_COMPANY: 'FSCompany',
  SHARE_ISSUANCE: 'ShareIssuance',
  CASH_DIVIDEND_PAYOUT: 'CashDividendPayout',
  OVERVIEW: 'Overview',
  VALUATION: 'Valuation',
  CREDIT: 'Credit',
  PERFORMANCE: 'Performance',
  CORPORATE_BOND_ISSUANCE: 'CorporateBondIssuance',
  INITIAL_PUBLIC_OFFERING: 'InitialPublicOffering',
  BOARD_OF_DIRECTORS: 'BoardofDirectors',
  MAJOR_SHAREHOLDERS: 'MajorShareholders',
}
