import { useSelector } from 'react-redux'
import { selectStockInfoById } from '../../../../../common/masterData/store/slice'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import DeleteOrganization from '../DeleteOrganization'
import { NAME_COL_ID } from '../constant'

const ThCell = ({ colId }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const organizationName = useSelector(selectStockInfoById(colId, 'ticker'))
  const taxCode = useSelector(selectStockInfoById(colId, 'taxCode'))

  return (
    <div className="d-flex j-b">
      {colId === NAME_COL_ID ? (
        <div />
      ) : (
        <DeleteOrganization organizationId={Number(colId)} />
      )}
      <div
        style={{
          color: colId === basicInfo.organizationId ? '#f9a900' : 'inherit',
        }}
      >
        {colId === NAME_COL_ID ? '' : organizationName || taxCode}
      </div>
    </div>
  )
}

export default ThCell
