import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import SelectCustom from '../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../common/sizeTracker'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab'
import ChartComponent from '../../common/chartCompanyGrowth/ChartComponent'
import { FooterChartCompanyGrowth } from '../../common/chartCompanyGrowth/Footer'
import {
  COMPANY_GROWTH_FILTER,
  TIME_RANGE,
} from '../../common/chartCompanyGrowth/constants'
import { getTimeKey } from '../../common/chartCompanyGrowth/helpers'
import { SearchBox } from '../../common/searchBox/SearchBox'
import { DEFAULT_COMPANY_ID } from '../../common/tableStatistics/constants'
import { getOrganizations } from '../../construction/companyGrowth/store/thunk'
import style from '../../index.module.css'
import {
  changeActiveCompany,
  selectActiveCompany,
  selectIdsCompanyOnly,
  selectIsAcc,
  selectIsYearly,
} from '../steelStatistics/store/slice'
import { COMPANY_GROWTH_LINE_KEYS, TITLE_LINE_KEYS } from './constants'
import {
  changeCurrentCategoryFilter,
  changeCurrentCompany,
  changeCurrentDateType,
  changeCurrentDateTypeYearly,
  selectCurrentCompany,
  selectDataChart,
  selectListCompany,
  selectLoading,
} from './store/slice'
import { getSteelPipeCompanyGrowth } from './store/thunk'

const tableId = 'steelPipeStatistics'

export const CompanyGrowth = ({ width, height }) => {
  const dispatch = useDispatch()

  const data = useSelector(selectDataChart)
  const isLoading = useSelector(selectLoading)
  const currentCompanyInChart = useSelector(selectCurrentCompany)
  const companyIds = useSelector(selectIdsCompanyOnly)
  const companiesDropdownList = useSelector(selectListCompany)
  const isAcc = useSelector(selectIsAcc)
  const isYearly = useSelector(selectIsYearly)
  const activeCompany = useSelector(selectActiveCompany)
  const locale = useSelector((state) => state.i18n.locale)

  const [currentDateType, setCurrentDateType] = useState(
    TIME_RANGE.MONTHLY[0].value,
  )
  const [currentDateTypeYearly, setCurrentDateTypeYearly] = useState(
    TIME_RANGE.YEARLY[0].value,
  )
  const [currentCategory, setCurrentCategory] = useState(
    COMPANY_GROWTH_FILTER[0].value,
  )

  const dataChart = data.map((item) => ({
    ...item,
    time: getTimeKey(isAcc, locale, item.month, item.year),
  }))

  const getLineKeysByCategory = (category) => {
    switch (category) {
      case 0:
        return COMPANY_GROWTH_LINE_KEYS.slice(0, 3)
      case 1:
        return COMPANY_GROWTH_LINE_KEYS.slice(3, 6)
      case 2:
        return COMPANY_GROWTH_LINE_KEYS.slice(6, 9)
      default:
        return COMPANY_GROWTH_LINE_KEYS.slice(0, 3)
    }
  }

  const [currentLineKeys, setCurrentLineKeys] = useState(
    COMPANY_GROWTH_LINE_KEYS.slice(0, 3),
  )

  const currentOrganizationId =
    currentCompanyInChart?.organizationId ?? DEFAULT_COMPANY_ID

  const listCompanies = companiesDropdownList?.filter((item) =>
    companyIds.includes(item.organizationId),
  )

  const handleFilterChange = (item) => {
    setCurrentCategory(item)
    dispatch(changeCurrentCategoryFilter(item))
    setCurrentLineKeys(getLineKeysByCategory(item))
  }

  const handleChangeDateType = (item) => {
    if (isYearly) {
      setCurrentDateTypeYearly(item.value)
      dispatch(changeCurrentDateTypeYearly(item.value))
    } else {
      setCurrentDateType(item.value)
      dispatch(changeCurrentDateType(item.value))
    }
  }

  useEffect(() => {
    dispatch(
      getSteelPipeCompanyGrowth({
        TimeFrequency: isYearly ? 'Yearly' : 'Monthly',
        OrganizationId: currentOrganizationId,
        TimeRange: 'AllTime',
        Accumulated: isAcc,
      }),
    )
  }, [
    currentOrganizationId,
    isAcc,
    currentDateType,
    currentDateTypeYearly,
    isYearly,
  ])

  useEffect(() => {
    dispatch(getOrganizations({}))
  }, [locale])

  return width && height && isLoading ? (
    <Loading />
  ) : (
    <div style={{ height: height }}>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <div className="d-flex j-b">
                <div className="row">
                  <div
                    className={`${style.inputDropdown}`}
                    style={{ width: '150px', marginRight: '10px' }}
                  >
                    <SelectCustom
                      value={currentCategory}
                      isI18n={true}
                      selectData={COMPANY_GROWTH_FILTER}
                      handleChange={handleFilterChange}
                    />
                  </div>
                  <SearchBox
                    listCompanies={listCompanies}
                    activeItem={activeCompany}
                    currentItemInChart={currentCompanyInChart}
                    changeActiveItem={changeActiveCompany}
                    changeCurrentItem={changeCurrentCompany}
                    tableId={tableId}
                  />
                </div>
                <DispatchActionTab
                  data={isYearly ? TIME_RANGE.YEARLY : TIME_RANGE.MONTHLY}
                  activeTab={isYearly ? currentDateTypeYearly : currentDateType}
                  onChangeTab={handleChangeDateType}
                />
              </div>
              <div id="pipeCompanyGrowth">
                {size.height && (
                  <div style={{ height: height - size.height }}>
                    {!dataChart.length ? (
                      <NoData />
                    ) : (
                      <ChartComponent
                        data={dataChart}
                        width={width}
                        height={height - size.height}
                        keyXAxis="time"
                        lineKeys={currentLineKeys}
                        titleLineKeys={TITLE_LINE_KEYS}
                        isYearly={isYearly}
                      />
                    )}
                  </div>
                )}
                <FooterChartCompanyGrowth
                  key={width}
                  titleLineKeys={TITLE_LINE_KEYS}
                />
              </div>
            </>
          )
        }}
      </SizeTracker>
    </div>
  )
}
