import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import {
  getEfficiencyChart,
  getGrowthChart,
  getProfitabilityChart,
  getStrengthChart,
  getValuationChart,
} from './thunk'

export const slice = createSlice({
  name: 'sector/sectorConstituents/financialAnalysis/corporate/chart',
  initialState: {
    data: [],
    loading: true,
  },
  reducers: {
    reset: (state) => {
      state.data = []
      state.loading = true
    },
  },
  extraReducers: (builder) => {
    // getEfficiencyChart
    builder.addCase(getEfficiencyChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getEfficiencyChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getEfficiencyChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    // getGrowthChart
    builder.addCase(getGrowthChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getGrowthChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getGrowthChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    // getProfitabilityChart
    builder.addCase(getProfitabilityChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getProfitabilityChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getProfitabilityChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    // getStrengthChart
    builder.addCase(getStrengthChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getStrengthChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getStrengthChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
    // getValuationChart
    builder.addCase(getValuationChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getValuationChart.fulfilled, (state, action) => {
      state.data = action.payload
      state.loading = false
    })
    builder.addCase(getValuationChart.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectChartData = (state) => state[slice.name].data
export const selectChartLoading = (state) => state[slice.name].loading
export const { reset } = slice.actions

register(slice.name, slice.reducer)
