import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'
import { addColor, removeColor } from '../panelStatisticalInterestRate/helper'
import { FIXED_ROW_ID } from './config'
import style from './index.module.css'
import {
  getCheckboxChecked,
  getLineChartColor,
  handleCheckboxChecked,
  handleIsShowAlert,
  handleLineChartColor,
} from './store/slice'

const MAX_TICK = 20

const FirstCellColumn = ({ val, props, rowId, isFixedRow }) => {
  const dispatch = useDispatch()
  const checkboxChecked = useSelector(getCheckboxChecked)
  const lineChartColor = useSelector(getLineChartColor)

  const handleCheckbox = (id) => {
    const isChecked = checkboxChecked.includes(id)
    let newCheckboxChecked = checkboxChecked

    if (isChecked) {
      newCheckboxChecked = checkboxChecked.filter((item) => item !== id)
    } else {
      if (checkboxChecked.length > MAX_TICK) {
        dispatch(handleIsShowAlert(true))
      } else {
        newCheckboxChecked = [...checkboxChecked, id]
      }
    }

    dispatch(handleCheckboxChecked(newCheckboxChecked))
    if (!FIXED_ROW_ID.includes(id)) {
      setListColorWithoutMonotone(isChecked, id)
    }
  }

  const setListColorWithoutMonotone = (isChecked, id) => {
    if (isChecked) {
      dispatch(handleLineChartColor(removeColor(lineChartColor, id)))
    } else {
      dispatch(handleLineChartColor(addColor(lineChartColor, id)))
    }
  }

  return (
    <td style={{ position: 'relative' }} {...props}>
      <div
        className={`d-flex align-center ${isFixedRow && style.fixedRow}`}
        style={{ cursor: 'pointer' }}
        onClick={() => handleCheckbox(rowId)}
      >
        <input
          type="checkbox"
          checked={checkboxChecked.includes(rowId)}
          readOnly={true}
          className={`checkbox mr-8`}
          style={{ cursor: 'pointer', minWidth: 12 }}
        />
        <TextEllipsisStaticLine val={val} isI18n={false} />
        {rowId === '0' && (
          <div className={style.borderTop} style={{ top: -4 }} />
        )}
      </div>
      {FIXED_ROW_ID.includes(`${rowId}`) && (
        <div className={style.borderLeft} />
      )}
    </td>
  )
}

FirstCellColumn.propTypes = {
  val: PropTypes.string,
  props: PropTypes.object,
  rowId: PropTypes.string.isRequired,
  isFixedRow: PropTypes.bool,
}

export default FirstCellColumn
