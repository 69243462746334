import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { I18n } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Input } from '../../../../common/html/Input'
import { TAB_LIST } from '../../constants'

const InputEditName = ({ handleClickOutside, item, handleSubmit }) => {
  const dropdownRef = useRef()
  const inputRef = useRef()

  const [name, setName] = useState('')

  const handleEnterKey = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      handleSubmit(name, item.templateId)
    }
  }

  useEffect(() => {
    if (!item) return

    setName(
      TAB_LIST.includes(item.templateId)
        ? I18n.t(item.templateName)
        : item.templateName,
    )
  }, [item])

  useEffect(() => {
    inputRef?.current?.focus()
  }, [])

  return (
    <div
      style={{
        width: 130,
        boxSizing: 'border-box',
        height: 24,
      }}
      ref={dropdownRef}
    >
      <HandleClickOutside
        handleClickOutside={handleClickOutside}
        exceptRef={dropdownRef}
      >
        <div
          className="d-flex"
          style={{ alignItems: 'flex-end', paddingTop: 1 }}
        >
          <div
            className="d-flex ali-center"
            style={{
              padding: '3px 6px',
              width: '100%',
              height: '100%',
              boxSizing: 'border-box',
              background: '#323744',
              gap: 8,
              borderRadius: 4,
            }}
          >
            <Input
              maxLength="100"
              value={name}
              onChange={(e) => setName(e?.target?.value)}
              style={{ color: '#ECECEC', width: 'calc(100% - 22px)' }}
              onKeyDown={handleEnterKey}
              ref={inputRef}
            />
            <i
              className="icon-true cursor-pointer"
              style={{ color: '#ECECEC', fontSize: 14 }}
              onClick={() => handleSubmit(name, item.templateId)}
            />
          </div>
        </div>
      </HandleClickOutside>
    </div>
  )
}

InputEditName.propTypes = {
  handleClickOutside: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}

export default InputEditName
