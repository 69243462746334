export const chartFillColor = {
  rT0277: '#facc5c',
  rT0297_A: '#df5a49',
  rT0296_A: '#45b29d',
}

export const chartBarKeys = ['rT0277']

export const chartLineKeys = ['rT0297_A', 'rT0296_A']

export const chartMappingDisplayName = {
  rT0277: 'sector.financialAnalysis.bank.capitalStructure.LDR_RATIO',
  rT0297_A: 'sector.financialAnalysis.bank.capitalStructure.DEPOSIT_GROWTH',
  rT0296_A: 'sector.financialAnalysis.bank.capitalStructure.LOAN_GROWTH',
}

export const chartFormat = {
  rT0277: 0.01,
  rT0297_A: 0.01,
  rT0296_A: 0.01,
}
