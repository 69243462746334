import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SameIndustryBonds from '../../../../../core/services/bond/valuation/sameIndusTryBonds'
import { handleExport } from '../../../../utils/Export'

export const getData = createAsyncThunk(
  'bond/company/sameIndustryBond/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SameIndustryBonds.getDataSameIndustry(params)
      if (response.success) {
        const dataList = response?.data || []
        const data = dataList.map((e, index) => ({
          ...e,
          id: index,
        }))

        return data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const exportFile = createAsyncThunk(
  'demo/company/exportFile',
  async (data) => {
    const response = await SameIndustryBonds.exportFile(data)
    handleExport(response.data, response.filename)
  },
)
