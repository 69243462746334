import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class InfoValuationProxy extends ProxyBase {
  getPrice(params) {
    return this.post('PriceYTM', params, {}, 'post', true)
  }
  getAccruedInterestProxy(params) {
    return this.get('AccruedInterest', params, true)
  }
  exportFileProxy(params) {
    return this.post('DownloadBondCashFlowScenario', params, {}, 'download')
  }
}

export default new InfoValuationProxy(
  ServiceProxyConfig.Bond.BondAnalysis.ServiceUrl,
)
