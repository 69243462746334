export const valueTab = {
  new: 'new',
  file: 'file',
}

export const listTab = [
  {
    title: 'bond.bondSecurity.information.filter.News',
    value: valueTab.new,
  },
  {
    title: 'bond.bondSecurity.information.filter.Files',
    value: valueTab.file,
  },
]

export const groupColumn = (active) => [
  {
    index: 'publicDate',
    key: 'publicDate',
    title: 'bond.bondSecurity.information.table.Date',
    attrs: {},
  },
  {
    index: 'title',
    key: 'title',
    title:
      active === valueTab.new
        ? 'bond.bondSecurity.information.table.News'
        : 'bond.bondSecurity.information.table.event',
    attrs: {},
  },
  {
    index: 'sourceUrl',
    key: 'sourceUrl',
    title: 'bond.bondSecurity.information.table.Detail',
    attrs: {},
  },
]
