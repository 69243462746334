import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { getLatestYearData } from '../../helper'
import { changeIsGetData } from '../tableSector/store/slice'
import { changeYear, selectYear } from './store/slice'

export const YearSelectOption = () => {
  const dispatch = useDispatch()
  const years = getLatestYearData(10).reverse()
  const year = useSelector(selectYear)
  const onChange = (val) => {
    dispatch(changeYear(val))
    dispatch(changeIsGetData(true))
  }
  return (
    <div className="w-80 ml-8">
      <SelectCustom
        value={year}
        selectData={years.map((year) => ({
          name: year,
          value: year,
        }))}
        handleChange={onChange}
      />
    </div>
  )
}
