import { valByKeyWithDot } from '../../../../utils/Value'
import { LINE_CHART_COLORS } from '../../constants'
import { MAX_ITEM } from './config'
import { NUMBER_OF_PERIOD_FIRST_TIME } from './TableComponent'

export const getAllKeyNotDuplicate = (data, keyName) => {
  const allKey = []
  data.forEach((item) => {
    if (!allKey.includes(item[keyName])) {
      allKey.push(item[keyName])
    }
  })
  return allKey
}

const handleDuplicate = (arr) => {
  return arr.filter(
    (item, index, arr) =>
      index ===
      arr.findIndex((i) => item.year === i.year && item.quarter === i.quarter),
  )
}

export const getYearlyQuarterNotDuplicate = (data) => {
  const arrYearQuarter = data.map((item) => {
    return {
      year: item.year,
      quarter: item.quarter,
    }
  })

  return handleDuplicate(arrYearQuarter)
}

export const defineGroupColumns = (
  columns,
  key,
  prevColumns,
  numberOfPeriod,
) => {
  const columnsNumberOfPeriod =
    numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME ? [] : prevColumns
  let groupColumns
  switch (key) {
    case 'Yearly':
      groupColumns = columns.map((column) => ({
        key: `column${column.year}`,
        title: column.year,
      }))
      break
    case 'Quarterly': {
      groupColumns = columns.map((column) => ({
        key: `column${column.quarter}${column.year}`,
        title: `Q${column.quarter}-${column.year}`,
      }))
      break
    }
    default: {
      groupColumns = columns.map((column) => ({
        key: `column${column.quarter}${column.year}`,
        title:
          column.quarter === 1
            ? `Q${column.quarter}-${column.year}`
            : `${column.quarter}Q-${column.year}`,
      }))
      break
    }
  }
  return [...groupColumns.reverse(), ...columnsNumberOfPeriod]
}

export const keyByArray = (
  array,
  key,
  formatKey,
  typeColumn,
  prevData,
  numberOfPeriod,
) => {
  const isYearTypeColumn = typeColumn === 'Yearly'
  const result = {}
  array.forEach((item) => {
    const keyObj = valByKeyWithDot(item, key)
    if (!result[keyObj]) {
      result[formatKey ? formatKey(keyObj) : keyObj] = {
        [isYearTypeColumn
          ? `column${item.year}`
          : `column${item.quarter}${item.year}`]: item?.value,
      }
    } else {
      result[formatKey ? formatKey(keyObj) : keyObj][
        isYearTypeColumn
          ? `column${item.year}`
          : `column${item.quarter}${item.year}`
      ] = item?.value
    }
  })

  return mergePrevCurrentData(prevData, result, numberOfPeriod)
}

const mergePrevCurrentData = (prev, current, numberOfPeriod) => {
  if (numberOfPeriod === NUMBER_OF_PERIOD_FIRST_TIME) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })
  return prevCurrentData
}

export const getIdByLevel = (
  dataTypeName,
  indexChecked,
  isGetIndicatorName = false,
) => {
  if (dataTypeName.length < MAX_ITEM) {
    return []
  }

  const defaultChecked = indexChecked.map((index) => dataTypeName[index])

  if (isGetIndicatorName) {
    return defaultChecked.map((item) => ({
      id: item?.nationalAccountVNTypeId,
      indicatorName: item?.nationalAccountVNTypeName,
    }))
  }
  return defaultChecked.map((item) => item?.nationalAccountVNTypeId)
}

export const initLineColor = (checkboxChecked) => {
  return LINE_CHART_COLORS.map((color, index) => {
    return {
      color,
      author: checkboxChecked[index]?.id,
      indicatorName: checkboxChecked[index]?.indicatorName,
    }
  })
}

export const handleLineColor = (id, lineChartColor, isChecked) => {
  if (isChecked) {
    return lineChartColor.map((color) => {
      if (color.author === id) {
        return {
          ...color,
          author: undefined,
        }
      }
      return color
    })
  } else {
    let count = 0
    return lineChartColor.map((color) => {
      if (count === 0 && color.author === undefined) {
        count++
        return {
          ...color,
          author: id,
        }
      }
      return color
    })
  }
}

export const handleLevels = (data) => {
  return data.map((item) => {
    return {
      id: item.nationalAccountVNTypeId,
      parentId: item.parentNationalAccountVNTypeId,
      level: item.nationalAccountVNTypeLevel,
    }
  })
}

export const findMinQuarter = (payload, tab) => {
  if (!payload.length) {
    return { minYear: 0, minQuarter: 0 }
  }

  const minYear = Math.min.apply(
    Math,
    payload.map((item) => item.year),
  )
  if (tab === 'Yearly') return { minYear, minQuarter: 0 }

  const quarterWithMinYear = payload
    .filter((item) => item.year === minYear)
    .map((item) => item.quarter)

  return {
    minYear,
    minQuarter: Math.min.apply(Math, quarterWithMinYear),
  }
}
