import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../../proxies/ProxyBase'

export class ConsumptionProxy extends ProxyBase {
  getCompareByTimeRange(params) {
    return this.get('CompareByTimeRange', params)
  }

  getListCriteriaByTimeRange(params) {
    return this.get('GetListCriteriaByTimeRange', params)
  }

  downloadCompareByTimeRange(params) {
    return this.get('DownloadCompareByTimeRange', params, 'download')
  }

  getGrowthByTimeRange(params) {
    return this.get('GrowthByTimeRange', params)
  }

  getRetailGrowthYbY(params) {
    return this.get('RetailGrowthYbY', params)
  }

  getChangesInRetailSalesComposition(params) {
    return this.get('ChangesInRetailSalesComposition', params)
  }

  getMaxDate(params) {
    return this.get('MaxDate', params)
  }
}

export default new ConsumptionProxy(
  ServiceProxyConfig.Economy.Consumption.ServiceUrl,
)
