import PropTypes from 'prop-types'
import { Span } from '../../../../../common/html/Span'
import { TextEllipsisStaticLine } from '../../../../../common/textEllipsis/TextEllipsisStaticLine'
import { getFontSize } from '../../../../../utils/FontSize'
import { formatVal } from '../../../../../utils/Value'
import style from '../index.module.css'

const Tooltip = ({ payload, item }) => {
  const fontSize = getFontSize(11)

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <TextEllipsisStaticLine
        appendStyle={{ fontSize, fontWeight: 400 }}
        lineClamp={2}
        isI18n={false}
        val={`${item.title}:`}
      />
      <Span
        style={{ marginLeft: 10, fontSize: 11 }}
        className={`${style.toolTipContentValue}
      ${
        typeof payload[item.id] === 'number' &&
        payload[item.id] < 0 &&
        style.redColor
      }
      `}
      >
        {formatVal(payload[item.id])}
        {typeof payload[item.id] === 'number' && '%'}
      </Span>
    </div>
  )
}

Tooltip.propTypes = {
  payload: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
}

export default Tooltip
