import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { ScrollComponent } from '../../../../../../common/ScrollComponent'
import { Button } from '../../../../../../common/html/Button'
import { Loading } from '../../../../../../common/loading'
import { NoData } from '../../../../../../common/noData'
import SelectCustom from '../../../../../../common/selectCustom'
import { SizeTracker } from '../../../../../../common/sizeTracker'
import TextEllipsis from '../../../../../../common/textEllipsis'
import { selectBasicInfo } from '../../../../../../common/topInfo/stockTopInfo/store/slice'
import { EXCHANGE_CODE } from '../constant'
import { getExchangeNameByValue } from '../helper'
import { changeIsFirst, selectRowIds, updateValuation } from '../store/slice'
import style from './index.module.css'
import SelectSector from './selectSector'
import {
  changeSelectedExchange,
  selectListSectorChecked,
  selectLoading,
  selectSelectedExchange,
  selectTickers,
} from './store/slice'
import { getOrgIdsByIcb } from './store/thunk'

const MAX_TICKER = 100

const AddByExchange = ({ keyword, handleClosePopup }) => {
  const dispatch = useDispatch()

  const isFirstMount = useRef(true)

  const basicInfo = useSelector(selectBasicInfo)
  const tickers = useSelector(selectTickers)
  const loading = useSelector(selectLoading)
  const selectedOrgIds = useSelector(selectRowIds)

  const listSectorChecked = useSelector(selectListSectorChecked)
  const exchange = useSelector(selectSelectedExchange)

  const [filteredTickers, setFilteredTickers] = useState([])
  const [orgIds, setOrgIds] = useState([])

  const uniqueSet = new Set(orgIds.filter((e) => e !== 'total'))

  useEffect(() => {
    if (listSectorChecked.length === 0 && isFirstMount.current) {
      return
    }
    isFirstMount.current = false
    dispatch(
      getOrgIdsByIcb({ exchangeCode: exchange, icbId: listSectorChecked }),
    )
  }, [exchange, listSectorChecked, isFirstMount.current])

  useEffect(() => {
    const orgIds = [...selectedOrgIds]
    if (!orgIds.includes(basicInfo.organizationId)) {
      orgIds.push(basicInfo.organizationId)
    }
    setOrgIds(orgIds)
  }, [selectedOrgIds])

  useEffect(() => {
    const sortedTickers = [...tickers].sort((a, b) => {
      return orgIds.includes(a.organizationId) &&
        !orgIds.includes(b.organizationId)
        ? -1
        : 1
    })

    setFilteredTickers(
      sortedTickers
        .filter((ticker) =>
          keyword
            ? ticker.ticker?.toLowerCase().includes(keyword.toLowerCase()) ||
              ticker.taxCode?.toLowerCase().includes(keyword.toLowerCase()) ||
              ticker.groupCode?.toLowerCase().includes(keyword.toLowerCase()) ||
              ticker.organizationName
                ?.toLowerCase()
                .includes(keyword.toLowerCase())
            : true,
        )
        .slice(0, MAX_TICKER),
    )
  }, [tickers, keyword, basicInfo.organizationId])

  const handleCancel = () => {
    handleClosePopup()
  }

  const handleApply = () => {
    const uniqueArray = []
    const seenValues = new Set()

    for (const item of orgIds) {
      if (!seenValues.has(item)) {
        seenValues.add(item)
        uniqueArray.push(item)
      }
    }

    dispatch(
      updateValuation(
        uniqueArray
          .filter((e) => e !== 'total')
          .map((e) => ({ organizationId: e })),
      ),
    )

    dispatch(changeIsFirst(false))
    handleClosePopup()
  }

  const onCheckboxChange = (e) => {
    const orgId = +e.target.value
    if (orgId !== basicInfo.organizationId) {
      if (e.target.checked) {
        setOrgIds((prev) => [...prev, orgId])
      } else {
        setOrgIds((prev) => prev.filter((ticker) => ticker !== orgId))
      }
    }
  }

  const handleCheckboxChange = (value) => {
    const orgId = +value.organizationId
    if (orgId !== basicInfo.organizationId) {
      const condition = orgIds.some((item) => item === orgId)
      if (!condition) {
        setOrgIds((prev) => [...prev, orgId])
      } else {
        setOrgIds((prev) => prev.filter((ticker) => ticker !== orgId))
      }
    }
  }

  const onCheckAllChange = (e) => {
    const filteredOrgIds = filteredTickers
      .map((ticker) => ticker.organizationId)
      .filter((id) => id !== basicInfo.organizationId)
    if (e.target.checked) {
      const orgIdsAdded = filteredOrgIds.filter((id) => !orgIds.includes(id))
      setOrgIds([...orgIds, ...orgIdsAdded])
    } else {
      setOrgIds(orgIds.filter((id) => !filteredOrgIds.includes(id)))
    }
  }

  const isCheckedAll = filteredTickers.every((ticker) =>
    orgIds.includes(ticker.organizationId),
  )

  const renderTickers = () => (
    <ScrollComponent>
      {filteredTickers.map((ticker) => {
        return (
          <div
            key={ticker.organizationId}
            className={style.itemTicker}
            style={{
              opacity:
                ticker.organizationId === basicInfo.organizationId ? 0.4 : 1,
            }}
            onClick={() => handleCheckboxChange(ticker)}
          >
            <div className={style.tickerInfo}>
              <div className={style.tickerCode}>
                <TextEllipsis
                  isI18n={false}
                  text={ticker.ticker || ticker.taxCode}
                />
              </div>
              <div className={style.orgName}>
                <TextEllipsis
                  isI18n={false}
                  text={ticker.organizationName || ticker.organizationShortName}
                />
              </div>
              <div className={style.exchange}>
                <Translate value={getExchangeNameByValue(ticker.groupCode)} />
              </div>
            </div>
            <div className={style.checkBox}>
              <label>
                <input
                  type="checkbox"
                  className={`checkbox line ${
                    ticker.organizationId === basicInfo.organizationId
                      ? style.disabledCheckbox
                      : ''
                  }`}
                  checked={orgIds.includes(ticker.organizationId)}
                  value={ticker.organizationId}
                  onChange={onCheckboxChange}
                  disabled={ticker.organizationId === basicInfo.organizationId}
                />
              </label>
            </div>
          </div>
        )
      })}
    </ScrollComponent>
  )

  return (
    <>
      <div className={style.modalContent}>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <div className={style.topFilter}>
                  <div className={style.filterExchange}>
                    <div>
                      <SelectCustom
                        value={exchange}
                        isI18n={true}
                        selectData={Object.keys(EXCHANGE_CODE).map((key) => ({
                          name:
                            'corporate.peerValuation.overview.exchanges.' + key,
                          value: EXCHANGE_CODE[key],
                        }))}
                        handleChange={(data) => {
                          dispatch(changeSelectedExchange(data))
                        }}
                      />
                    </div>
                    <SelectSector />
                  </div>
                  <div className={style.checkBox}>
                    <label>
                      <input
                        type="checkbox"
                        className={`checkbox line`}
                        checked={isCheckedAll}
                        onChange={onCheckAllChange}
                      />
                    </label>
                  </div>
                </div>
                {size.height && (
                  <div style={{ height: `calc(100% - ${size.height}px)` }}>
                    {loading ? (
                      <Loading />
                    ) : filteredTickers.length ? (
                      renderTickers()
                    ) : (
                      <NoData />
                    )}
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </div>
      <div className={style.selectedCount}>
        <Translate value="corporate.peerValuation.overview.SELECTED_COUNT" />
        {` ${Array.from(uniqueSet).length}/${MAX_TICKER}`}
      </div>
      {orgIds.length > MAX_TICKER && (
        <div className={style.maxItemText}>
          <Translate value="corporate.peerValuation.overview.MAX_ITEMS_TEXT" />
        </div>
      )}
      <div className={style.groupButtonModal}>
        <Button className="btn bg-grey-1 w-80 h-20" onClick={handleCancel}>
          <Translate value={'common.button.BUTTON_CANCEL'} />
        </Button>
        <Button
          disabled={orgIds.length > MAX_TICKER}
          onClick={handleApply}
          className={`btn btn-blue w-80 h-20 ${
            orgIds.length > MAX_TICKER ? style.buttonDisabled : ''
          } `}
        >
          <Translate value={'common.button.BUTTON_APPLY'} />
        </Button>
      </div>
    </>
  )
}

export default AddByExchange
