import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize, getLineHeightP } from '../../utils/FontSize'

export const DangerouslySetInnerHTML = ({ html, style, ...others }) => {
  return (
    <div
      {...others}
      style={{
        ...style,
        fontSize: getFontSize(style?.fontSize),
        lineHeight: getLineHeightP(),
      }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

DangerouslySetInnerHTML.propTypes = {
  html: PropTypes.string,
  style: PropTypes.object,
}

DangerouslySetInnerHTML.defaultProps = {
  html: '',
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
