import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getChartData } from './thunk'

const initialState = {
  isLoading: true,
  dataChart: [],
}

export const slice = createSlice({
  name: 'economy/fdi/overview/keyIndicatorProvince',
  initialState,
  reducers: {
    changeTimeFilter: (state, action) => {
      state.timeFilter = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartData.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChartData.fulfilled, (state, action) => {
      state.isLoading = false
      state.dataChart = convertData(action.payload)
    })
    builder.addCase(getChartData.rejected, (state, action) => {
      state.isLoading = action.Loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectTimeFilter = (state) => state[slice.name].timeFilter
export const selectChartData = (state) => state[slice.name].dataChart

export const { changeTimeFilter } = slice.actions

register(slice.name, slice.reducer)

const convertData = (data = []) => {
  return data.slice(0, 10).map((item) => ({
    ...item,
    value: item.value / 1000000,
    prevValue: item.prevValue / 1000000,
  }))
}
