import MarginLoansProxy from '../../proxies/market/MarginLoansProxy'
import { ServiceBase } from '../ServiceBase'

class MarginLoansService extends ServiceBase {
  getAverageTradingValue(params) {
    return this.getData(() => MarginLoansProxy.getAverageTradingValue(params))
  }

  getMarginLeverageRatio(params) {
    return this.getData(() => MarginLoansProxy.getMarginLeverageRatio(params))
  }

  getMarginLoans(params) {
    return this.getData(() => MarginLoansProxy.getMarginLoans(params))
  }

  getMaxDate(params) {
    return this.getData(() => MarginLoansProxy.getMaxDate(params))
  }

  getMarginGrowth(params) {
    return this.getData(() => MarginLoansProxy.getMarginGrowth(params))
  }

  getTopCompany(params) {
    return this.getData(() => MarginLoansProxy.getTopCompany(params))
  }

  downloadMarginGrowth(params) {
    return this.getFileDownload(() =>
      MarginLoansProxy.downloadMarginGrowth(params),
    )
  }
}

export default new MarginLoansService()
