export const quartersMonthly = [
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_1',
    value: 1,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_2',
    value: 2,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_3',
    value: 3,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_4',
    value: 4,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_5',
    value: 5,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_6',
    value: 6,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_7',
    value: 7,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_8',
    value: 8,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_9',
    value: 9,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_10',
    value: 10,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_11',
    value: 11,
  },
  {
    name: 'economy.productionAndConsumption.production.MONTHLY_OPTION.MONTH_12',
    value: 12,
  },
]

export const quartersAccMonthly = [
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_1',
    value: 1,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_2',
    value: 2,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_3',
    value: 3,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_4',
    value: 4,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_5',
    value: 5,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_6',
    value: 6,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_7',
    value: 7,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_8',
    value: 8,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_9',
    value: 9,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_10',
    value: 10,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_11',
    value: 11,
  },
  {
    name: 'economy.productionAndConsumption.production.TIME_DATA_TYPE.MONTH_12',
    value: 12,
  },
]
