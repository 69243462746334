import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { Loading } from '../../../../common/loading'
import useGetICB from '../../../../common/masterData/useGetICB'
import useGetIndicesV2 from '../../../../common/masterData/useGetIndicesV2'
import useGetStock from '../../../../common/masterData/useGetStock'
import { NoData } from '../../../../common/noData'
import TextEllipsis from '../../../../common/textEllipsis'
import {
  LIST_TYPE,
  LIST_TYPE_SHORT_TEXT,
  MAX_SECURITIES_ITEM,
} from '../../../common/constants'
import { selectIsLoading, selectWatchList } from '../../common/store/slice'
import style from '../style.module.css'
import useGetIndicator from '../useGetIndicator'
import { ItemCheckAll } from './ItemCheckAll'

const ListAll = ({ valueSearch, securitiesState, setSecuritiesState }) => {
  const stocks = useGetStock()
  const indices = useGetIndicesV2()
  const ICBs = useGetICB(true, true)
  const { deepestEconomies } = useGetIndicator()
  const watchLists = useSelector(selectWatchList)
  const companiesChecked = securitiesState.companies
  const indicesChecked = securitiesState.indices
  const sectorChecked = securitiesState.sector
  const economyChecked = securitiesState.economy
  const watchListChecked = securitiesState.watchList
  const { loadingEconomy } = useGetIndicator(false)
  const loadingWatchList = useSelector(selectIsLoading('watchList'))

  const loading =
    stocks.loading ||
    indices.loading ||
    ICBs.loading ||
    loadingEconomy ||
    loadingWatchList

  const truncateArray = (array) => {
    return array
      .filter((item) =>
        valueSearch
          ? item.ticker?.toLowerCase().includes(valueSearch.toLowerCase()) ||
            item.organizationShortName
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.groupName?.toLowerCase().includes(valueSearch.toLowerCase()) ||
            `${item.icbName} L${item.icbLevel}`
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.secNameDisplay
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase()) ||
            item.watchListName
              ?.toLowerCase()
              .includes(valueSearch.toLowerCase())
          : true,
      )
      .slice(0, MAX_SECURITIES_ITEM)
  }

  const isCheckAll = useMemo(() => {
    const checkAllCompanies = truncateArray(stocks.stocks).every((item) =>
      companiesChecked.includes(item.organizationId),
    )
    const checkAllIndices = truncateArray(indices.indices).every((item) =>
      indicesChecked.includes(item.groupId),
    )
    const checkAllSector = truncateArray(ICBs.ICBs).every((item) =>
      sectorChecked.includes(item.icbId),
    )
    const checkAllEconomy = truncateArray(deepestEconomies).every((item) =>
      economyChecked
        .map((economy) => `${economy.id}_${economy.timeFrequency}`)
        .includes(`${item.parentId}_${item.timeFrequency}`),
    )
    const checkAllWatchList = truncateArray(watchLists).every((item) =>
      watchListChecked.includes(item.watchListId),
    )
    return (
      checkAllCompanies &&
      checkAllIndices &&
      checkAllSector &&
      checkAllEconomy &&
      checkAllWatchList
    )
  }, [
    companiesChecked,
    indicesChecked,
    sectorChecked,
    economyChecked,
    watchListChecked,
    valueSearch,
  ])

  const handleCheckAll = () => {
    if (isCheckAll) {
      setSecuritiesState({
        [LIST_TYPE.COMPANIES]: [],
        [LIST_TYPE.INDICES]: [],
        [LIST_TYPE.SECTOR]: [],
        [LIST_TYPE.ECONOMY]: [],
        [LIST_TYPE.WATCH_LIST]: [],
      })
    } else {
      const companiesId = truncateArray(stocks.stocks).map(
        (item) => +item.organizationId,
      )
      const indicesId = truncateArray(indices.indices).map(
        (item) => +item.groupId,
      )
      const sectorId = truncateArray(ICBs.ICBs).map((item) => +item.icbId)
      const economy = truncateArray(deepestEconomies).map((item) => ({
        id: item.parentId,
        timeFrequency: item.timeFrequency,
      }))
      const watchListId = truncateArray(watchLists).map(
        (item) => +item.watchListId,
      )
      setSecuritiesState({
        [LIST_TYPE.COMPANIES]: companiesId,
        [LIST_TYPE.INDICES]: indicesId,
        [LIST_TYPE.SECTOR]: sectorId,
        [LIST_TYPE.ECONOMY]: economy,
        [LIST_TYPE.WATCH_LIST]: watchListId,
      })
    }
  }

  const handleCheckboxChange = (value, type) => {
    switch (type) {
      case LIST_TYPE.COMPANIES:
        const orgId = +value.organizationId
        if (!companiesChecked.some((org) => org === orgId)) {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...companiesChecked, orgId],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...companiesChecked.filter((ticker) => ticker !== orgId)],
          })
        }
        break
      case LIST_TYPE.INDICES:
        const groupId = +value.groupId
        if (!indicesChecked.some((group) => group === groupId)) {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...indicesChecked, groupId],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...indicesChecked.filter((item) => item !== groupId)],
          })
        }
        break
      case LIST_TYPE.SECTOR:
        const icbId = +value.icbId
        if (!sectorChecked.some((sector) => sector === icbId)) {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...sectorChecked, icbId],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...sectorChecked.filter((item) => item !== icbId)],
          })
        }
        break
      case LIST_TYPE.ECONOMY:
        if (
          !economyChecked.some(
            (economy) =>
              economy.id === value.parentId &&
              economy.timeFrequency === value.timeFrequency,
          )
        ) {
          setSecuritiesState({
            ...securitiesState,
            [type]: [
              ...economyChecked,
              { id: value.parentId, timeFrequency: value.timeFrequency },
            ],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            [type]: [
              ...economyChecked.filter(
                (economy) =>
                  !(
                    economy.id === value.parentId &&
                    economy.timeFrequency === value.timeFrequency
                  ),
              ),
            ],
          })
        }
        break
      case LIST_TYPE.WATCHLIST:
        const watchListId = +value.watchListId
        if (!sectorChecked.some((watchList) => watchList === watchListId)) {
          setSecuritiesState({
            ...securitiesState,
            [type]: [...watchListChecked, watchListId],
          })
        } else {
          setSecuritiesState({
            ...securitiesState,
            [type]: [
              ...watchListChecked.filter((item) => item !== watchListId),
            ],
          })
        }
        break
      default:
        break
    }
  }

  return (
    <div style={{ height: `calc(100% )` }}>
      {loading ? (
        <Loading isBlack />
      ) : (
        <ScrollComponent bgColor="#D9D9D9">
          <ItemCheckAll checked={isCheckAll} onChange={handleCheckAll} />
          {truncateArray(stocks.stocks).length ? (
            <>
              {truncateArray(stocks.stocks).map((ticker) => {
                return (
                  <div
                    key={ticker.organizationId}
                    className={style.itemTicker}
                    onClick={() =>
                      handleCheckboxChange(ticker, LIST_TYPE.COMPANIES)
                    }
                  >
                    <div className={`ml-8 ${style.checkBox}`}>
                      <label>
                        <input
                          type="checkbox"
                          className={`checkbox line `}
                          checked={companiesChecked.includes(
                            ticker.organizationId,
                          )}
                          value={ticker.organizationId}
                          readOnly
                        />
                      </label>
                    </div>
                    <div className={style.tickerInfo}>
                      <div className={style.tickerCode}>
                        <TextEllipsis
                          isI18n={false}
                          text={ticker.ticker || ticker.taxCode}
                        />
                      </div>
                      <div className={style.orgName}>
                        <TextEllipsis
                          isI18n={false}
                          text={
                            ticker.organizationShortName ||
                            ticker.organizationName
                          }
                        />
                      </div>
                      <div className={style.securitiesType}>
                        <TextEllipsis
                          isI18n={false}
                          text={LIST_TYPE_SHORT_TEXT.COMPANIES}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(indices.indices).length ? (
            <>
              {truncateArray(indices.indices).map((item) => {
                return (
                  <div
                    key={item.groupId}
                    className={style.itemTicker}
                    onClick={() =>
                      handleCheckboxChange(item, LIST_TYPE.INDICES)
                    }
                  >
                    <div className={`ml-8 ${style.checkBox}`}>
                      <label>
                        <input
                          type="checkbox"
                          className={`checkbox line `}
                          checked={indicesChecked.includes(item.groupId)}
                          value={item.groupId}
                          readOnly
                        />
                      </label>
                    </div>
                    <div className={style.tickerInfo}>
                      <div className={style.securitiesName}>
                        <TextEllipsis isI18n={false} text={item.groupName} />
                      </div>
                      <div className={style.securitiesType}>
                        <TextEllipsis
                          isI18n={false}
                          text={LIST_TYPE_SHORT_TEXT.INDICES}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(ICBs.ICBs).length ? (
            <>
              {truncateArray(ICBs.ICBs).map((sector) => {
                return (
                  <div
                    key={sector.icbId}
                    className={style.itemTicker}
                    onClick={() =>
                      handleCheckboxChange(sector, LIST_TYPE.SECTOR)
                    }
                  >
                    <div className={`ml-8 ${style.checkBox}`}>
                      <label>
                        <input
                          type="checkbox"
                          className={`checkbox line `}
                          checked={sectorChecked.includes(sector.icbId)}
                          value={sector.icbId}
                          readOnly
                        />
                      </label>
                    </div>
                    <div className={style.tickerInfo}>
                      <div className={style.securitiesName}>
                        <TextEllipsis
                          isI18n={false}
                          text={sector.icbName + ' L' + sector.icbLevel}
                        />
                      </div>
                      <div className={style.securitiesType}>
                        <TextEllipsis
                          isI18n={false}
                          text={LIST_TYPE_SHORT_TEXT.SECTOR}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(deepestEconomies).length ? (
            <>
              {truncateArray(deepestEconomies).map((economy) => {
                return (
                  <div
                    key={economy.id}
                    className={style.itemTicker}
                    onClick={() =>
                      handleCheckboxChange(economy, LIST_TYPE.ECONOMY)
                    }
                  >
                    <div className={`ml-8 ${style.checkBox}`}>
                      <label>
                        <input
                          type="checkbox"
                          className={`checkbox line `}
                          checked={economyChecked
                            .map((item) => `${item.id}_${item.timeFrequency}`)
                            .includes(economy.id)}
                          value={economy.id}
                          readOnly
                        />
                      </label>
                    </div>
                    <div className={style.tickerInfo}>
                      <div className={style.securitiesName}>
                        <TextEllipsis
                          isI18n={false}
                          text={economy.secNameDisplay}
                        />
                      </div>
                      <div className={style.securitiesType}>
                        <TextEllipsis
                          isI18n={false}
                          text={LIST_TYPE_SHORT_TEXT.ECONOMY}
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
              <br />
            </>
          ) : null}
          {truncateArray(watchLists).map((watchList) => {
            return (
              <div
                key={watchList.watchListId}
                className={style.itemTicker}
                onClick={() =>
                  handleCheckboxChange(watchList, LIST_TYPE.WATCHLIST)
                }
              >
                <div className={`ml-8 ${style.checkBox}`}>
                  <label>
                    <input
                      type="checkbox"
                      className={`checkbox line `}
                      checked={watchListChecked.includes(watchList.watchListId)}
                      value={watchList.watchListId}
                      readOnly
                    />
                  </label>
                </div>
                <div className={style.tickerInfo}>
                  <div className={style.securitiesName}>
                    <TextEllipsis
                      isI18n={false}
                      text={watchList.watchListName}
                    />
                  </div>
                  <div className={style.securitiesType}>
                    <TextEllipsis
                      isI18n={false}
                      text={LIST_TYPE_SHORT_TEXT.WATCH_LIST}
                    />
                  </div>
                </div>
              </div>
            )
          })}
          {!truncateArray(stocks.stocks).length &&
          !truncateArray(indices.indices).length &&
          !truncateArray(ICBs.ICBs).length &&
          !truncateArray(deepestEconomies).length &&
          !truncateArray(watchLists).length ? (
            <NoData />
          ) : null}
        </ScrollComponent>
      )}
    </div>
  )
}

export default ListAll
