import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CompanyBondDebtAndLoanService from '../../../../../../core/services/bond/corporateBond/issuers/CompanyBondDebtAndLoanService'
import { handleExport } from '../../../../../utils/Export'

export const getDebtRelationInBsData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_DEBT_RELATION_IN_BS_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CompanyBondDebtAndLoanService.getDebtRelationInBsData(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getScaleOfDebtByObjects = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_SCALE_OF_DEBT_BY_OBJECTS_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CompanyBondDebtAndLoanService.getScaleOfDebtByObjects(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getCreditRatio = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_CREDIT_RATIO_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondDebtAndLoanService.getCreditRatio(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getDebtStatisticData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_DEBT_STATISTICS_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondDebtAndLoanService.getDebtStatisticData(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getDebtDetailsData = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_DEBT_DETAILS_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await CompanyBondDebtAndLoanService.getDebtDetailsData(
        params,
      )

      if (response.success) {
        return { data: response.data, type: params?.TimeFrequency }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTooltipLendingCompany = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_TOOLTIP_LENDING_COM',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CompanyBondDebtAndLoanService.getTooltipLendingCompany(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTooltipLoanCompany = createAsyncThunk(
  'bond/corporateBond/companyBond/GET_TOOLTIP_LOAN_COM',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await CompanyBondDebtAndLoanService.getTooltipLoanCompany(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadDebtDetailsData = createAsyncThunk(
  'bond/corporateBond/companyBond/DOWNLOAD_DEBT_DETAILS',
  async (data) => {
    const response =
      await CompanyBondDebtAndLoanService.downloadDebtDetailsData(data)
    handleExport(response.data, response.filename)
  },
)
export const downloadTooltipLendingCompany = createAsyncThunk(
  'bond/corporateBond/companyBond/DOWNLOAD_LENDING_COM',
  async (data) => {
    const response =
      await CompanyBondDebtAndLoanService.downloadTooltipLendingCompany(data)
    handleExport(response.data, response.filename)
  },
)
