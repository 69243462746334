import PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { getFontSize } from '../../../utils/FontSize'
import { Span } from '../../html/Span'
import { placeholderDateCalendar } from '../helper'
import styleCommon from '../index.module.css'
import InputDate from '../inputs/InputDate'

const Range = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  handleChange,
  btnDeleteDate,
}) => {
  const locale = useSelector((state) => state.i18n.locale)
  const dateFontSize = getFontSize(12)

  const handleStartChange = (date) => {
    if (date) {
      handleChange([date, endDate])
    } else {
      handleChange([null, null])
    }
  }

  const handleEndChange = (date) => {
    handleChange([startDate, date])
  }

  const handleClearDate = () => {
    handleChange([null, null])
  }

  return (
    <div className={styleCommon.contentDateContainer}>
      <div className={styleCommon.dayContainer} style={{ width: '50%' }}>
        <label>
          <Span style={{ fontSize: 11, opacity: 0.4 }}>
            <Translate value={'common.calendar.FROM'} />
          </Span>
        </label>
        <div className={styleCommon.contentDayOneCalendarRange}>
          <InputDate
            date={startDate}
            minDate={minDate}
            maxDate={endDate || new Date(2050, 0, 0)}
            onChange={handleStartChange}
            placeholder={placeholderDateCalendar(locale)}
            style={{ fontSize: dateFontSize }}
          />
        </div>
      </div>
      <div
        className={styleCommon.dayContainer}
        style={{ width: '50%', marginLeft: 8 }}
      >
        <label>
          <Span style={{ fontSize: 11, opacity: 0.4 }}>
            <Translate value={'common.calendar.TO'} />
          </Span>
        </label>
        <div className={styleCommon.contentDayOneCalendarRange}>
          <InputDate
            date={endDate}
            minDate={startDate || new Date(1950, 0, 1)}
            maxDate={maxDate}
            onChange={handleEndChange}
            placeholder={placeholderDateCalendar(locale)}
            style={{ fontSize: dateFontSize }}
          />
        </div>
      </div>
      {btnDeleteDate && (
        <div className="cursor-pointer ml-8" onClick={handleClearDate}>
          <i className="icon-trash" style={{ color: '#aebcd2' }} />
        </div>
      )}
    </div>
  )
}

Range.propTypes = {
  handleChange: PropTypes.func.isRequired,
  startDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  endDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  minDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  maxDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
}

export default Range
