import rankingProxy from '../../../proxies/sector/sectorConstituents/RankingProxy'
import { ServiceBase } from '../../ServiceBase'

class RankingService extends ServiceBase {
  getDataTable(params) {
    return this.applyMemoryCache(
      'RankingService.getDataTable',
      params,
    )(() => rankingProxy.getDataTable(params))
  }

  getTooltipScoreResult(params) {
    return this.applyMemoryCache(
      'RankingService.getTooltipScoreResult',
      params,
    )(() => rankingProxy.getTooltipScoreResult(params))
  }

  getTooltipTicker(params) {
    return this.applyMemoryCache(
      'RankingService.getTooltipTicker',
      params,
    )(() => rankingProxy.getTooltipTicker(params))
  }

  downloadDataTable(params) {
    return this.getFileDownload(() => rankingProxy.downloadDataTable(params))
  }
}

export default new RankingService()
