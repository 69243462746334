import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../utils/ReducerRegistry'
import { SYMBOL_TYPES } from '../dataFeed/constants'
import { getChartLayout, getSearchData } from './thunk'

const initialState = {
  chartLayoutLibs: [],
  chartLayoutCustoms: [],
  currentChartLayout: {},
  currentTab: 0,
  futures: [],
  economies: [],
  coveredWarrants: [],
  isSearchDataLoading: true,
}

const slice = createSlice({
  name: 'common/tradingView',
  initialState,
  reducers: {
    changeCurrentChartLayout: (state, action) => {
      state.currentChartLayout = action.payload
    },
    resetCurrentChartLayout: (state, action) => {
      if (action.payload === 0) {
        state.currentChartLayout = state.chartLayoutLibs[0]
      } else {
        state.currentChartLayout = state.chartLayoutCustoms[0]
      }
    },
    changeCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    resetState: (state) => {
      state.currentTab = initialState.currentTab
      state.chartLayoutCustoms = initialState.chartLayoutCustoms
      state.chartLayoutLibs = initialState.chartLayoutLibs
      state.currentChartLayout = initialState.currentChartLayout
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartLayout.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getChartLayout.fulfilled, (state, action) => {
      const data = action.payload || []
      state.isLoading = false
      state.chartLayoutLibs = data.filter((item) => !item.isCustom)
      state.chartLayoutCustoms = data.filter((item) => item.isCustom)
      if (state.currentTab === 0) {
        state.currentChartLayout = state.chartLayoutLibs[0]
      } else {
        state.currentChartLayout = state.chartLayoutCustoms[0]
      }
    })
    builder.addCase(getChartLayout.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getSearchData.pending, (state) => {
      state.isSearchDataLoading = true
    })
    builder.addCase(getSearchData.fulfilled, (state, action) => {
      state.isSearchDataLoading = false
      state.economies = [
        ...(action.payload?.economies?.map((item) => ({
          id: item.indicatorId,
          code: item.indicatorCode,
          name: item.indicatorName,
          type: SYMBOL_TYPES.ECONOMY,
        })) || []),
        ...(action.payload?.commodities?.map((item) => ({
          id: item.commodityId,
          code: item.commodityName,
          name: item.commodityName,
          type: SYMBOL_TYPES.COMMODITY,
        })) || []),
      ]
      state.coveredWarrants = action.payload?.coveredWarrants || []
      state.futures = action.payload?.futures || []
    })
    builder.addCase(getSearchData.rejected, (state) => {
      state.isSearchDataLoading = false
    })
  },
})

export const selectChartLayoutLibs = (state) =>
  state[slice.name].chartLayoutLibs
export const selectChartLayoutCustoms = (state) =>
  state[slice.name].chartLayoutCustoms
export const selectCurrentChartLayout = (state) =>
  state[slice.name].currentChartLayout
export const selectCurrentTab = (state) => state[slice.name].currentTab
export const selectIsLoading = (state) => state[slice.name].isLoading
export const selectIsSearchDataLoading = (state) =>
  state[slice.name].isSearchDataLoading

export const {
  changeCurrentChartLayout,
  resetCurrentChartLayout,
  changeCurrentTab,
  resetState,
} = slice.actions

register(slice.name, slice.reducer)
