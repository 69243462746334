import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getNews, getReports } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/fishery/overviewFishery/newsAndReport',
  initialState: {
    isLoading: false,
    data: [],
    isNews: true,
  },
  reducers: {
    changeIsNews: (state, action) => {
      state.isNews = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReports.pending, (state) => {
      state.isLoading = true
      state.data = []
    })
    builder.addCase(getReports.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || []
      state.data = data
    })
    builder.addCase(getReports.rejected, (state) => {
      state.isLoading = false
    })

    builder.addCase(getNews.pending, (state) => {
      state.isLoading = true
      state.data = []
    })
    builder.addCase(getNews.fulfilled, (state, action) => {
      state.isLoading = false
      const data = action.payload || []
      state.data = data
    })
    builder.addCase(getNews.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectData = (state) => state[slice.name].data
export const selectIsNews = (state) => state[slice.name].isNews

export const { changeIsNews } = slice.actions

register(slice.name, slice.reducer)
