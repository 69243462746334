import { LIST_TYPE } from '../../common/constants'
import { TIME_FREQUENCY } from '../constants'

export const equationIndicatorLimit = 26

export const listTab = [
  {
    title: 'financialChart.COMPANIES',
    value: LIST_TYPE.COMPANIES,
  },
  {
    title: 'financialChart.WATCH_LIST',
    value: LIST_TYPE.WATCH_LIST,
  },
]

export const equationType = {
  SUM: 'Sum',
  AVERAGE: 'Average',
  COUNT: 'Count',
}

export const listEquationTypeTab = [
  {
    title: 'financialChart.SUM',
    value: equationType.SUM,
  },
  {
    title: 'financialChart.AVG',
    value: equationType.AVERAGE,
  },
  {
    title: 'financialChart.COUNT',
    value: equationType.COUNT,
  },
]

export const indicatorKeyByTimeFrequency = {
  [TIME_FREQUENCY.DAILY.toLowerCase()]: 'date',
  [TIME_FREQUENCY.WEEKLY.toLowerCase()]: 'week',
  [TIME_FREQUENCY.MONTHLY.toLowerCase()]: 'month',
  [TIME_FREQUENCY.QUARTERLY.toLowerCase()]: 'quarter',
  [TIME_FREQUENCY.YEARLY.toLowerCase()]: 'year',
}

export const indicatorDefaultData = {
  year: null,
  quarter: null,
  month: null,
  week: null,
  date: null,
  valueFrom: null,
  valueTo: null,
}

export const listWeekly = [...new Array(52)].map((_, index) => ({
  name: index + 1,
  value: index + 1,
}))

export const listMonthly = [...new Array(20)].map((_, index) => ({
  name: index + 1,
  value: index + 1,
}))

export const listQuarterly = [
  {
    name: 'Q1',
    value: 1,
  },
  {
    name: 'Q2',
    value: 2,
  },
  {
    name: 'Q3',
    value: 3,
  },
  {
    name: 'Q4',
    value: 4,
  },
]

export const listYearly = [...new Array(20)].map((_, index) => ({
  name: (new Date().getFullYear() - index).toString(),
  value: new Date().getFullYear() - index,
}))

export const equationIndicatorColWidth = {
  alias: 40,
  valueFrom: 110,
  divider: 20,
  valueTo: 110,
  unit: 80,
  timeGroup: 200,
  iconDelete: 22,
}

export const equationAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
export const equationIndicatorAliasAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

export const equationFormatUnitConstants = {
  EQUATION_FORMAT_VND: 'vnd',
  EQUATION_FORMAT_TIMES: 'times',
  EQUATION_FORMAT_PERCENT: 'percent',
}

export const equationFormatUnit = {
  [equationFormatUnitConstants.EQUATION_FORMAT_PERCENT]: {
    vi: '%',
    en: '%',
  },
  [equationFormatUnitConstants.EQUATION_FORMAT_VND]: {
    vi: 'VND',
    en: 'VND',
  },
  [equationFormatUnitConstants.EQUATION_FORMAT_TIMES]: {
    vi: 'Lần',
    en: 'Times',
  },
}
