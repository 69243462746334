import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'

import { PreOpenMarket } from '../../../../common/preOpenMarket/PreOpenMarket'
import { selectWarrantInfo } from '../../../../common/topInfo/warrantTopInfo/store/slice'
import { ChartFilter } from './ChartFilter'
import { tableChartSwitch } from './constants'
import HorizontalStackBarComponent from './HorizontalStackBarComponent'
import {
  resetState,
  selectLoading,
  selectPageSize,
  selectScrollId,
} from './store/slice'
import {
  getCoveredWarrantTimeAndSales,
  getCoveredWarrantTimeAndSalesChart,
} from './store/thunk'
import TableComponent from './TableComponent'

const TimeAndSalesPanel = ({
  width,
  height,
  viewType,
  setViewType,
  dateType,
  setDateType,
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(selectLoading)
  const topInfo = useSelector(selectWarrantInfo)
  const pageSize = useSelector(selectPageSize)
  const scrollId = useSelector(selectScrollId)

  const [rowTimes, setRowTimes] = useState(1)

  useEffect(() => {
    if (
      topInfo?.coveredWarrantId &&
      viewType === tableChartSwitch.HORIZONTAL_STACK_BAR
    ) {
      dispatch(
        getCoveredWarrantTimeAndSalesChart({
          CoveredWarrantId: topInfo?.coveredWarrantId,
          TimeRange: dateType,
        }),
      )
    }
  }, [topInfo?.coveredWarrantId, dateType, viewType])

  useEffect(() => {
    dispatch(
      getCoveredWarrantTimeAndSales({
        CoveredWarrantId: topInfo?.coveredWarrantId,
        PageSize: pageSize,
        ScrollId: scrollId,
      }),
    )
  }, [rowTimes])

  useEffect(() => {
    if (topInfo?.coveredWarrantId && viewType === tableChartSwitch.TABLE) {
      dispatch(resetState())
      dispatch(
        getCoveredWarrantTimeAndSales({
          CoveredWarrantId: topInfo?.coveredWarrantId,
          PageSize: pageSize,
        }),
      )
    }
  }, [viewType, topInfo?.coveredWarrantId])

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <ChartFilter
            viewType={viewType}
            dateType={dateType}
            setViewType={setViewType}
            setDateType={setDateType}
          />
          {/* ChartFilter height = 34px */}
          <div style={{ height: `calc(100% - 34px)` }}>
            <PreOpenMarket>
              {viewType === tableChartSwitch.HORIZONTAL_STACK_BAR ? (
                <HorizontalStackBarComponent
                  width={width}
                  height={height - 34}
                />
              ) : (
                <TableComponent setRowTimes={setRowTimes} />
              )}
            </PreOpenMarket>
          </div>
        </>
      )}
    </>
  )
}

export default TimeAndSalesPanel
