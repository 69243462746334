import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { HEADER_VALUE } from '../../constants'

const { NOMINAL } = HEADER_VALUE

const initialState = {
  nominalRealValue: NOMINAL,
}

const slice = createSlice({
  name: 'economy/gdp/gdpBySpendingMethod',
  initialState,
  reducers: {
    handleNominalRealValue: (state, action) => {
      state.nominalRealValue = action.payload
    },
  },
})

export const { handleNominalRealValue } = slice.actions
export const getNominalRealValue = (state) => state[slice.name].nominalRealValue

register(slice.name, slice.reducer)
