import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import {
  formatVal,
  valDivBillion,
  valToPercent,
} from '../../../../../utils/Value'
import style from '../index.module.css'

const ChartTooltip = ({ data, date }) => {
  return (
    <div className={style.tooltip}>
      <Span
        className={style.tooltipTitle}
        style={{ fontSize: 10, fontWeight: 400 }}
      >
        {date}
      </Span>
      {data.map((item, index) => {
        return (
          <div
            key={index}
            className="d-flex ali-center justify-content-space-between"
          >
            <Span style={{ fontSize: 11, fontWeight: 400, marginRight: 8 }}>
              {index < 3
                ? item.text
                : I18n.t('bond.corporateBond.issuanceValueByCouponType.OTHERS')}
            </Span>
            <Span style={{ fontSize: 11, fontWeight: 400 }}>
              {`${formatVal(valDivBillion(item.issueValue), 2)} ${I18n.t(
                'bond.corporateBond.issuanceValueByCouponType.BILLION',
              )}, ${valToPercent(item.value, false, false, 2)}
              `}
            </Span>
          </div>
        )
      })}
    </div>
  )
}

ChartTooltip.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      issueValue: PropTypes.number,
      value: PropTypes.number,
    }),
  ).isRequired,
  date: PropTypes.string,
}

export default ChartTooltip
