export const xAxisKey = 'label'
export const leftUnit = '%'

export const leftLabel =
  'economy.productionAndConsumption.consumption.CHART_PERCENT'

export const fillColor = ['#9c54e4', '#176491', '#3cabeb', '#fe7e6d', '#fff6df']

export const dataTypeConstants = {
  VALUE: 'value',
  GROWTH: 'growth',
}

export const dataTypeOptions = [
  {
    title: 'economy.productionAndConsumption.consumption.VALUE',
    value: dataTypeConstants.VALUE,
  },
  {
    title: 'economy.productionAndConsumption.consumption.GROWTH_YOY',
    value: dataTypeConstants.GROWTH,
  },
]

export const timeTypeConstants = {
  SIX_MONTHS: 'SixMonths',
  NINE_MONTHS: 'NineMonths',
  ONE_YEAR: 'OneYear',
  TWO_YEARS: 'TwoYears',
  THREE_YEARS: 'ThreeYears',
  FIVE_YEARS: 'FiveYears',
  SEVEN_YEARS: 'SevenYears',
  TEN_YEARS: 'TenYears',
  TWENTY_YEARS: 'TwentyYears',
  ALL: 'AllTime',
}

export const timeTypeOptions = [
  {
    title: '6M',
    value: timeTypeConstants.SIX_MONTHS,
  },
  {
    title: '9M',
    value: timeTypeConstants.NINE_MONTHS,
  },
  {
    title: '1Y',
    value: timeTypeConstants.ONE_YEAR,
  },
  {
    title: '3Y',
    value: timeTypeConstants.THREE_YEARS,
  },
  {
    title: 'economy.productionAndConsumption.consumption.ALL',
    value: timeTypeConstants.ALL,
  },
]
export const timeTypeOptionsYear = [
  {
    title: '5Y',
    value: timeTypeConstants.FIVE_YEARS,
  },
  {
    title: '7Y',
    value: timeTypeConstants.SEVEN_YEARS,
  },
  {
    title: '10Y',
    value: timeTypeConstants.TEN_YEARS,
  },
  {
    title: '20Y',
    value: timeTypeConstants.TWENTY_YEARS,
  },
  {
    title: 'economy.productionAndConsumption.consumption.ALL',
    value: timeTypeConstants.ALL,
  },
]
