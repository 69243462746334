import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { selectFundInfo } from '../../../../common/topInfo/fundTopInfo/store/slice'
import { MONTH_OPTIONS } from '../../../../constants/Datetime'
import { TABS } from './constants'
import {
  changeFilter,
  changeIsDisableCallApi,
  selectFilters,
} from './store/slice'

export const Filter = () => {
  const dispatch = useDispatch()

  const filters = useSelector(selectFilters)
  const fundInfo = useSelector(selectFundInfo)

  const getYears = useMemo(() => {
    return new Array(new Date().getFullYear() - 2000 + 1)
      .fill(null)
      .map((_, index) => ({
        name: String(new Date().getFullYear() - index),
        value: new Date().getFullYear() - index,
      }))
  }, [])

  const getMonths = useMemo(() => {
    return MONTH_OPTIONS
  }, [])

  const handleChangeFilter = (value) => {
    dispatch(changeIsDisableCallApi(false))
    dispatch(changeFilter({ ...value, page: 1 }))
  }

  useEffect(() => {
    if (filters.month && filters.year) {
      handleChangeFilter({
        month: 0,
        year: 0,
      })
    }
  }, [fundInfo.organizationId])

  return (
    <div className="d-flex justify-content-space-between ali-center mb-8">
      <DispatchActionTab
        onChangeTab={({ value }) => handleChangeFilter({ type: value })}
        data={Object.keys(TABS).map((key) => ({
          title: 'fund.fundProfile.portfolio.' + key,
          value: TABS[key],
        }))}
        activeTab={filters.type}
        textSize={11}
      />
      <div className="d-flex ali-center">
        <div style={{ width: 85 }} className="ml-8">
          <SelectCustom
            value={filters.month}
            selectData={getMonths}
            handleChange={(value) => handleChangeFilter({ month: value })}
            isI18n
          />
        </div>
        <div style={{ width: 85 }} className="ml-8">
          <SelectCustom
            value={filters.year}
            selectData={getYears}
            handleChange={(value) => handleChangeFilter({ year: value })}
            isI18n
          />
        </div>
      </div>
    </div>
  )
}
