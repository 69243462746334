import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import TextEllipsis from '../../../../../common/textEllipsis'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import {
  changeBasicInfo,
  changeBondTicker,
} from '../../../bondSecurity/bondSecurity/Filter/store/slice'
import TableHeader from './TableHeader'
import styles from './index.module.css'
import {
  selectData,
  selectGroupColumns,
  selectIds,
  selectInitialData,
  selectLoading,
  sortData,
} from './store/slice'

const TopIssuerTable = ({ width }) => {
  const locale = useSelector((state) => state.i18n.locale)
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()

  const ids = useSelector(selectIds)
  const isLoading = useSelector(selectLoading)
  const groupColumns = useSelector(selectGroupColumns)
  const data = useSelector(selectInitialData)

  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }
  if (isLoading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }

  const redirectToSecurityMaster = (bond) => {
    dispatch(changeBondTicker(bond))
    dispatch(changeBasicInfo(null))
    navigate('/bond/corporate-bond/security-master')
  }

  const redirectToBondIssuer = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  const renderTable = () => {
    return (
      <Table
        ids={ids}
        columnDraggable={false}
        getDataFromRedux={selectData}
        isLoading={isLoading}
        rowDraggable={false}
        sort={sortData}
        handleSortField={(e) =>
          dispatch(
            sortData({
              ...e,
              locale,
            }),
          )
        }
        renderHeader={(stateSort, sortColumn) =>
          renderThead(groupColumns, stateSort, sortColumn)
        }
        schema={groupColumns.map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key
          const result = {
            colId,
            title,
          }
          switch (index) {
            case 0:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatDateTime(value, FORMAT.DATE, locale)}
                      </Span>
                    </td>
                  )
                },
              }
            case 1:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td>
                      <div
                        style={{
                          ...item?.attrs?.style,
                        }}
                      >
                        <Span
                          style={{ ...item?.attrs?.style }}
                          className={styles.link}
                          onClick={() => redirectToSecurityMaster(rowId)}
                        >
                          {value}
                        </Span>
                      </div>
                    </td>
                  )
                },
              }
            case 2:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span
                        style={{ ...item?.attrs?.style }}
                        className={styles.link}
                        onClick={() =>
                          redirectToBondIssuer(data?.[rowId]?.organizationId)
                        }
                      >
                        <TextEllipsis text={value} isI18n={false} />
                      </Span>
                    </td>
                  )
                },
              }
            case 3:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(valDivBillion(value))}
                      </Span>
                    </td>
                  )
                },
              }
            case 4:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(value)}
                      </Span>
                    </td>
                  )
                },
              }
            case 5:
              return {
                ...result,
                canCustomTd: true,
                render: (value, rowId, props) => {
                  return (
                    <td
                      style={{
                        ...item?.attrs?.style,
                      }}
                    >
                      <Span style={{ ...item?.attrs?.style }}>
                        {formatVal(value * 100)}
                      </Span>
                    </td>
                  )
                },
              }
            default:
              return {}
          }
        })}
        stickyFirstColumn={false}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        // defaultScrollToRight
      />
    )
  }

  return renderTable()
}

export default TopIssuerTable
