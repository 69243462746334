export const valueTab = {
  BOND_DEFAULT: 'BOND_DEFAULT',
  BOND_CREATED: 'BOND_CREATED',
}

export const tabOption = [
  {
    label: 'bond.valuation.infoSec.filter.tab.bondDefault',
    value: valueTab.BOND_DEFAULT,
  },
  {
    label: 'bond.valuation.infoSec.filter.tab.bondCreated',
    value: valueTab.BOND_CREATED,
  },
]
