import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { keyBy } from '../../utils/Common'
import {
  loadingKey,
  selectExchanges,
  selectLoading,
  selectLocale,
} from './store/slice'
import { getExchangeThunk } from './store/thunk'

const firstLevel = 2

const useGetExchange = (
  disableCallApi = true,
  isTree = false,
  filterFunc,
  isGetTreeNotDeleteChildren = false,
) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.EXCHANGE))
  const exchanges = useSelector(selectExchanges)

  const treeRef = useRef(null)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getExchangeThunk())
    }
    treeRef.current = null
  }, [locale])

  const handleExchanges = (isDeleteChildren = true) => {
    if (treeRef.current && isDeleteChildren) {
      return treeRef.current
    }
    const filteredExchanges = filterFunc
      ? exchanges.filter(filterFunc)
      : exchanges
    const exchangesSorted = [...filteredExchanges]?.sort(
      (exchange1, exchange2) => exchange2?.groupLevel - exchange1?.groupLevel,
    )
    const exchangesById = keyBy(
      filteredExchanges.map((exchange) => {
        return { ...exchange, children: {} }
      }),
      'groupId',
    )
    exchangesSorted.forEach((exchange) => {
      if (
        exchangesById[exchange.parentGroupId] &&
        exchange.groupLevel !== firstLevel
      ) {
        exchangesById[exchange.parentGroupId].children[exchange.groupId] =
          exchangesById[exchange.groupId]
        if (isDeleteChildren) {
          delete exchangesById[exchange.groupId]
        }
      }
    })

    Object.keys(exchangesById).forEach((exchangeId) => {
      const newKey = `${exchangesById[exchangeId].groupOrder} - ${exchangesById[exchangeId].groupId}`
      exchangesById[newKey] = exchangesById[exchangeId]
      delete exchangesById[exchangeId]
    })
    if (isDeleteChildren) {
      treeRef.current = exchangesById
    }
    return exchangesById
  }

  return {
    loading,
    exchanges: exchanges,
    exchangesTree:
      isTree && !loading && exchanges.length ? handleExchanges() : {},
    exchangeTreeNotDeleteChildren:
      isTree && !loading && exchanges.length && isGetTreeNotDeleteChildren
        ? handleExchanges(false)
        : {},
  }
}

export default useGetExchange
