import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Footer } from '../../../common/chart/footer'
import { Loading } from '../../../common/loading'
import { NoData } from '../../../common/noData'
import SelectCustom from '../../../common/selectCustom'
import { SizeTracker } from '../../../common/sizeTracker'
import { SizeTrackerContainer } from '../../../common/sizeTracker/SizeTrackerContainer'
import { TYPE_CODE } from '../cpiAndInflationTable/constants'
import {
  selectDataTable,
  selectIsChangeTypeCode,
  selectMaxMonth,
  selectMaxYear,
  selectTypeCode,
} from '../cpiAndInflationTable/store/slice'
import style from '../style.module.css'
import ChartComponent from './ChartComponent'
import {
  MONTH_LIST_FILTER_EN,
  MONTH_LIST_FILTER_VI,
  YEAR_LIST_FILTER,
} from './constants'
import {
  changeIsYTD,
  changeMonth,
  changeYear,
  selectCurrentMonth,
  selectCurrentYear,
  selectDataContributionChart,
  selectIsYTD,
  selectLoadingContributionChart,
} from './store/slice'
import { contributionChart } from './store/thunk'

export const Contribution = ({ width, height }) => {
  const dispatch = useDispatch()

  const typeCode = useSelector(selectTypeCode)
  const contributionData = useSelector(selectDataContributionChart)
  const cpiAndInflationData = useSelector(selectDataTable)
  const isLoadingContributionChart = useSelector(selectLoadingContributionChart)
  const currentMonthSelected = useSelector(selectCurrentMonth)
  const currentYearSelected = useSelector(selectCurrentYear)
  const defaultMaxMonth = useSelector(selectMaxMonth)
  const defaultMaxYear = useSelector(selectMaxYear)
  const isChangeTypeCode = useSelector(selectIsChangeTypeCode)
  const locale = useSelector((state) => state.i18n.locale)
  const isYTDCheck = useSelector(selectIsYTD)

  //get list of cpivnTypeName
  const getCPINameById = (id) => {
    return cpiAndInflationData?.find((item) => item.cpivnTypeId === id)
      ?.cpivnTypeName
  }

  const contributionDataChart = contributionData?.map((item) => ({
    ...item,
    name: getCPINameById(item.cpivnTypeId),
  }))

  const getMonthListFilterByLocale = (locale) => {
    return locale === 'en' ? MONTH_LIST_FILTER_EN : MONTH_LIST_FILTER_VI
  }

  const handleMonthChange = (val) => {
    dispatch(changeMonth(val))
  }

  const handleYearChange = (val) => {
    dispatch(changeYear(val))
  }

  const handleChangeChecked = (e) => {
    dispatch(changeIsYTD(e.target.checked))
  }

  const handleClick = () => {
    dispatch(changeIsYTD(!isYTDCheck))
  }

  const currentMonth = currentMonthSelected ?? defaultMaxMonth
  const currentYear = currentYearSelected ?? defaultMaxYear

  useEffect(() => {
    if (currentMonth && currentYear) {
      dispatch(
        contributionChart({
          CPIVNTypeCode: typeCode,
          Year: currentYear,
          Month: currentMonth,
          IsYTD: typeCode === TYPE_CODE.YoY ? isYTDCheck : false,
        }),
      )
    }
  }, [typeCode, currentMonth, currentYear, isYTDCheck])

  return isLoadingContributionChart || isChangeTypeCode ? (
    <Loading />
  ) : (
    <SizeTrackerContainer>
      <SizeTracker>
        {(size) => {
          return (
            <div className="h-100">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '84px', marginRight: '4px' }}
                >
                  <SelectCustom
                    value={currentMonthSelected ?? defaultMaxMonth}
                    isI18n={true}
                    selectData={getMonthListFilterByLocale(locale)}
                    handleChange={handleMonthChange}
                    isDisable={isYTDCheck}
                  />
                </div>
                <div
                  className={`${style.inputDropdown}`}
                  style={{ width: '84px' }}
                >
                  <SelectCustom
                    value={currentYearSelected ?? defaultMaxYear}
                    isI18n={true}
                    selectData={YEAR_LIST_FILTER}
                    handleChange={handleYearChange}
                    isDisable={isYTDCheck}
                  />
                </div>
                <div
                  style={
                    typeCode === 'YoY'
                      ? { marginLeft: '15px' }
                      : { display: 'none' }
                  }
                  className="align-center"
                >
                  <input
                    type="checkbox"
                    className={`checkbox ${style.checkbox}`}
                    checked={isYTDCheck}
                    onChange={handleChangeChecked}
                  />
                  <span
                    style={{ marginLeft: '5px', fontSize: '12px' }}
                    onClick={handleClick}
                  >
                    <Translate value="economy.cpiAndInflation.cpiAndInflationTable.GROWTH_YTD" />
                  </span>
                </div>
              </div>
              <div id="contribution">
                {size.height &&
                cpiAndInflationData.length &&
                contributionDataChart.length ? (
                  <ChartComponent
                    data={contributionDataChart}
                    width={width}
                    height={height - size.height}
                    keyXAxis={'name'}
                    barKey={'value'}
                  />
                ) : (
                  size.height && (
                    <div
                      style={{
                        height: height - size.height,
                      }}
                    >
                      <NoData />
                    </div>
                  )
                )}
              </div>
              {<Footer list={[]} />}
            </div>
          )
        }}
      </SizeTracker>
    </SizeTrackerContainer>
  )
}
