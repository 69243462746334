export const MIN_YEAR = 2013
export const currentYearByDateJS = new Date().getFullYear()

export const COLORS_PIE_CHART_SIX_ITEMS = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#0096eb',
  '#ecffdf',
]

export const COLORS_PIE_CHART_FIVE_ITEMS = [
  '#45b29d',
  '#facc5c',
  '#df5a49',
  '#185999',
  '#ecffdf',
]

const YEARS_LIST = [...Array(currentYearByDateJS - MIN_YEAR + 1)].map(
  (_, index) => currentYearByDateJS - index,
)

export const YEARS_FILTER = YEARS_LIST.map((item) => ({
  name: item,
  value: item,
}))
