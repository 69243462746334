import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../html/Span'
import { ScrollComponent } from '../../../ScrollComponent'
import style from '../index.module.css'
import {
  checkPopupIndicator,
  selectIndicatorByIdPopupIndicator,
  selectIndicatorPopupIndicator,
  selectIndicatorPopupIndicatorCell,
  selectIsEdit,
  selectIsGroup,
  selectMainIndicator,
  uncheckPopupIndicator,
} from '../store/slice'
import CheckAllIndicator from './CheckAllIndicator'

const CheckBox = ({ id }) => {
  const dispatch = useDispatch()

  const indicatorById = useSelector(selectIndicatorByIdPopupIndicator)

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      dispatch(checkPopupIndicator(id))
    } else {
      dispatch(uncheckPopupIndicator(id))
    }
  }

  return (
    <input
      onChange={handleChangeCheckbox}
      checked={indicatorById[id].check}
      type="checkbox"
      className="checkbox"
    />
  )
}

CheckBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

const ItemIndicator = ({ id, index }) => {
  const indicator = useSelector(
    selectIndicatorPopupIndicatorCell(id, 'indicatorName'),
  )
  const isEdit = useSelector(selectIsEdit)
  const isGroup = useSelector(selectIsGroup)
  const mainIndicator = useSelector(selectMainIndicator)

  return (
    <tr
      className={
        isEdit && !isGroup && id !== mainIndicator ? style.disabled : ''
      }
    >
      <td>{index + 1}</td>
      <td>{indicator}</td>
      <td>
        <div className="d-flex justify-content-center">
          <p>
            <CheckBox id={id} />
          </p>
        </div>
      </td>
    </tr>
  )
}

ItemIndicator.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
}

const MapIndicator = () => {
  const indicator = useSelector(selectIndicatorPopupIndicator)

  return (
    <>
      {indicator.map((id, index) => (
        <ItemIndicator key={id} id={id} index={index} />
      ))}
    </>
  )
}

const TableIndicator = () => {
  return (
    <div className={`col ${style.colTable}`}>
      <div className={`col-header ${style.h37px}`}>
        <h6>
          <Span>
            <Translate
              className={style.disabled}
              value="common.popup.SELECT_ITEM"
            />
          </Span>
        </h6>
        <CheckAllIndicator />
      </div>
      <div
        className={`col-content ${style.colContent} ${style.tableIndicatorContainer}`}
      >
        <ScrollComponent>
          <table className={`w-100 ${style.tableSettingIndicator}`}>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <MapIndicator />
            </tbody>
          </table>
        </ScrollComponent>
      </div>
    </div>
  )
}

export default TableIndicator
