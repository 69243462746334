import { I18n, Translate } from 'react-redux-i18n'
import { lengthChartInLayout } from '../../chart/common/useGetLayoutDashboard'
import { ReactComponent as IconLayout1Choose } from '../../icon/icon-layout1-choose.svg'
import { ReactComponent as IconLayout1 } from '../../icon/icon-layout1.svg'
import { ReactComponent as IconLayout2Choose } from '../../icon/icon-layout2-choose.svg'
import { ReactComponent as IconLayout2 } from '../../icon/icon-layout2.svg'
import { ReactComponent as IconLayout3Choose } from '../../icon/icon-layout3-choose.svg'
import { ReactComponent as IconLayout3 } from '../../icon/icon-layout3.svg'
import { ReactComponent as IconLayout4Choose } from '../../icon/icon-layout4-choose.svg'
import { ReactComponent as IconLayout4 } from '../../icon/icon-layout4.svg'
import style from '../style.module.css'

export const LayoutTabs = ({ layout, lengthChart, changeLayout }) => {
  return (
    <div className={style.chooseLayout}>
      <div className={style.textOrange}>
        <Translate value="financialChart.CHOOSE_LAYOUT" />
      </div>
      <div className={style.layout}>
        {layout === 'layout_4' ? (
          <IconLayout4Choose />
        ) : (
          <>
            {lengthChart > lengthChartInLayout.layout_4 ? (
              <IconLayout4
                title={I18n.t('financialChart.TEXT_DISABLE_LAYOUT')}
                className={style.disable}
              />
            ) : (
              <IconLayout4 onClick={() => changeLayout('layout_4')} />
            )}
          </>
        )}

        {layout === 'layout_2' ? (
          <IconLayout2Choose />
        ) : (
          <>
            {lengthChart > lengthChartInLayout.layout_2 ? (
              <IconLayout2
                title={I18n.t('financialChart.TEXT_DISABLE_LAYOUT')}
                className={style.disable}
              />
            ) : (
              <IconLayout2 onClick={() => changeLayout('layout_2')} />
            )}
          </>
        )}

        {layout === 'layout_3' ? (
          <IconLayout3Choose />
        ) : (
          <>
            {lengthChart > lengthChartInLayout.layout_3 ? (
              <IconLayout3
                title={I18n.t('financialChart.TEXT_DISABLE_LAYOUT')}
                className={style.disable}
              />
            ) : (
              <IconLayout3 onClick={() => changeLayout('layout_3')} />
            )}
          </>
        )}

        {layout === 'layout_1' ? (
          <IconLayout1Choose />
        ) : (
          <>
            {lengthChart > lengthChartInLayout.layout_1 ? (
              <IconLayout1
                title={I18n.t('financialChart.TEXT_DISABLE_LAYOUT')}
                className={style.disable}
              />
            ) : (
              <IconLayout1 onClick={() => changeLayout('layout_1')} />
            )}
          </>
        )}
      </div>
    </div>
  )
}
