import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TableDataExplorer from '../../../../common/dataExplorer/table'
import { pageSize } from '../../../../common/dataExplorer/table/constant'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { SizeTracker } from '../../../../common/sizeTracker'
import { EXPORT_TYPE, GROUP_BY, VIEW_BY } from '../constant'
import {
  selectFilter,
  selectListCheckedIndicatorsById,
  selectMostRecentSettingDate,
  selectViewBy,
} from '../store/slice'
import { ContentPagination } from './ContentPagination'
import GroupButton from './GroupButton'
import { TableSettings } from './TableSettings'
import ThTable from './ThTable'
import { getParamsQuery, getRowSpanSchema, getTableHeadRowTop } from './helps'
import {
  changeDataById,
  changeDataDisplay,
  resetDefault,
  selectCellInfos,
  selectColumnByIdCell,
  selectColumnId,
  selectColumns,
  selectData,
  selectDataDisplay,
  selectFullColumnIds,
  selectFullDataDisplay,
  selectIds,
  selectIndicatorsChecked,
  selectLoading,
  selectTableHeaderAttr,
  selectTickersChecked,
  selectValueById,
} from './store/slice'
import {
  getCorporateResultThunk,
  getIndexSectorResultThunk,
} from './store/thunk'

const Preview = ({ setIsLoadingDownloadCsv }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const filter = useSelector(selectFilter)
  const viewBy = useSelector(selectViewBy)
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const data = useSelector(selectData(viewBy))
  const mostRecentDate = useSelector(selectMostRecentSettingDate)
  const tickersChecked = useSelector(selectTickersChecked)
  const indicatorsChecked = useSelector(selectIndicatorsChecked)
  const columns = useSelector(selectColumns(viewBy))
  const tableHeaderAttr = useSelector(selectTableHeaderAttr)
  const cellInfos = useSelector(selectCellInfos(viewBy))

  // Get data
  const isGroupByDate = useMemo(() => {
    return (
      filter.groupBy === GROUP_BY.DATE ||
      filter.exportType === EXPORT_TYPE.MULTIPLE_FILES
    )
  }, [filter.groupBy, filter.exportType])

  const isMultiFile = useMemo(() => {
    return filter.exportType === EXPORT_TYPE.MULTIPLE_FILES
  }, [filter.exportType])

  const getKey = useMemo(() => {
    return new Date().getTime()
  }, [filter.groupBy, filter.exportType, checkedIndicatorsById, viewBy, locale])

  // Actions
  const handleLoadData = () => {
    if (!isLoading && tickersChecked.length && indicatorsChecked.length) {
      const params = getParamsQuery(
        tickersChecked,
        indicatorsChecked,
        filter,
        viewBy,
        timeZone,
        mostRecentDate,
      )

      if (viewBy === VIEW_BY.CORPORATE) {
        dispatch(getCorporateResultThunk(params))
      } else {
        dispatch(getIndexSectorResultThunk(params))
      }
    }
  }

  const changeIdDisplay = (ids) => dispatch(changeDataDisplay(ids))

  // Use effect
  useEffect(() => {
    dispatch(
      changeDataById({
        schema: columns,
        groupBy: filter.groupBy,
        exportType: filter.exportType,
        viewBy,
      }),
    )
  }, [data, columns, filter.groupBy, filter.exportType])

  useEffect(() => {
    dispatch(resetDefault(viewBy))
  }, [viewBy, filter.time, columns])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <GroupButton
              onLoadData={handleLoadData}
              setIsLoadingDownloadCsv={setIsLoadingDownloadCsv}
            />
            <ContentPagination
              groupBy={filter.groupBy}
              isMultiFile={isMultiFile}
              viewBy={viewBy}
            />
            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                <TableDataExplorer
                  key={cellInfos}
                  isLoading={isLoading}
                  maxColumn={250}
                  selectColumnId={selectColumnId(viewBy)}
                  selectColumnCell={selectColumnByIdCell(viewBy)}
                  maxRow={pageSize}
                  selectTickerCell={selectValueById(viewBy)}
                  selectId={selectDataDisplay(viewBy)}
                  selectFullId={selectIds(viewBy)}
                  selectFullColumnId={selectFullColumnIds(viewBy)}
                  changeIdDisplay={changeIdDisplay}
                  selectIdDisplay={selectFullDataDisplay(viewBy)}
                  cellInfo={cellInfos}
                  renderTh={(_, colId, column) => (
                    <ThTable colId={colId} thData={column} />
                  )}
                  stateResetDefaultPage={getKey}
                  tableThRowSpan={getRowSpanSchema(
                    tableHeaderAttr,
                    isGroupByDate,
                    filter.groupBy,
                  )}
                  tableThTop={getTableHeadRowTop(
                    tableHeaderAttr,
                    isGroupByDate,
                  )}
                  noDataText=""
                  isSwap={false}
                  isSort={false}
                />
              </div>
            )}
            <TableSettings
              isGroupByDate={isGroupByDate}
              isMultiFile={isMultiFile}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

export default Preview
