export const dividendType = {
  CASH_DIVIDEND: 'cashDividend',
  STOCK_DIVIDEND: 'stockDividend',
}

export const dividendTypeTab = [
  {
    title: 'corporate.financialData.financialDividend.CASH_DIVIDEND',
    value: dividendType.CASH_DIVIDEND,
  },
  {
    title: 'corporate.financialData.financialDividend.STOCK_DIVIDEND',
    value: dividendType.STOCK_DIVIDEND,
  },
]

export const cashDividendTable = {
  DECLARATION_DATE: 'DECLARATION_DATE.value',
  EX_DEVIDEND_DATE: 'EX_DEVIDEND_DATE.value',
  RECORD_DATE: 'RECORD_DATE.value',
  PAYMENT_DATE: 'PAYMENT_DATE.value',
  EXERCISE_RATE: 'EXERCISE_RATE.value',
  DPS: 'DPS.value',
  CURRENCY: 'CURRENCY.value',
  DIVIDEND_YEAR: 'DIVIDEND_YEAR.value',
  DURATION: 'DURATION.value',
}

export const stockDividendTable = {
  DECLARATION_DATE: 'DECLARATION_DATE.value',
  EX_DEVIDEND_DATE: 'EX_DEVIDEND_DATE.value',
  RECORD_DATE: 'RECORD_DATE.value',
  PAYMENT_DATE: 'PAYMENT_DATE.value',
  EXERCISE_RATE: 'EXERCISE_RATE.value',
  PLAN_VOLUME: 'PLAN_VOLUME.value',
  ISSUE_VOLUME: 'ISSUE_VOLUME.value',
  CURRENCY: 'CURRENCY.value',
  DIVIDEND_YEAR: 'DIVIDEND_YEAR.value',
  DURATION: 'DURATION.value',
}

export const dividendTableTitle = {
  DECLARATION_DATE:
    'corporate.financialData.financialDividend.DECLARATION_DATE',
  EX_DEVIDEND_DATE:
    'corporate.financialData.financialDividend.EX_DEVIDEND_DATE',
  RECORD_DATE: 'corporate.financialData.financialDividend.RECORD_DATE',
  PAYMENT_DATE: 'corporate.financialData.financialDividend.PAYMENT_DATE',
  EXERCISE_RATE: 'corporate.financialData.financialDividend.EXERCISE_RATE',
  PLAN_VOLUME: 'corporate.financialData.financialDividend.PLAN_VOLUME',
  ISSUE_VOLUME: 'corporate.financialData.financialDividend.ISSUE_VOLUME',
  DPS: 'corporate.financialData.financialDividend.DPS',
  CURRENCY: 'corporate.financialData.financialDividend.CURRENCY',
  DIVIDEND_YEAR: 'corporate.financialData.financialDividend.DIVIDEND_YEAR',
  DURATION: 'corporate.financialData.financialDividend.DURATION',
}

export const DividendTableConstant = {
  index: undefined,
  DECLARATION_DATE: {
    key: 'publicDateId',
    value: undefined,
    isFormatValue: false,
  },
  EX_DEVIDEND_DATE: {
    key: 'exRightDateId',
    value: undefined,
    isFormatValue: false,
  },
  RECORD_DATE: {
    key: 'recordDateId',
    value: undefined,
    isFormatValue: false,
  },
  PAYMENT_DATE: {
    key: 'paymentDate',
    value: undefined,
    isFormatValue: false,
  },
  EXERCISE_RATE: {
    key: 'exerciseRate',
    value: undefined,
    isFormatValue: true,
    formatType: 'percent',
  },
  PLAN_VOLUME: {
    key: 'planVolume',
    value: undefined,
    isFormatValue: true,
  },
  ISSUE_VOLUME: {
    key: 'issueVolume',
    value: undefined,
    isFormatValue: true,
  },
  DPS: {
    key: 'valuePerShare',
    value: undefined,
    isFormatValue: true,
    formatType: 'noFixed',
  },
  CURRENCY: {
    key: 'currencyCode',
    value: undefined,
    isFormatValue: false,
  },
  DIVIDEND_YEAR: {
    key: 'sourceDividendYear',
    value: undefined,
    isFormatValue: false,
  },
  DURATION: {
    key: 'durationName',
    value: undefined,
    isFormatValue: false,
  },
}
