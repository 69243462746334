import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import { HEADER_VALUE } from '../../constants'
import { getNominalRealValue } from '../store/slice'
import GDPProduct from './GDPProduct'
import {
  getLocationId,
  getMinYear,
  getValueGrowthNominal,
  getValueGrowthReal,
} from './store/slice'
import { downloadGDPByProvince } from './store/thunk'

const { NOMINAL } = HEADER_VALUE

const PanelGDPProvince = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const locationId = useSelector(getLocationId)
  const minYear = useSelector(getMinYear)
  const valueGrowthNominal = useSelector(getValueGrowthNominal)
  const valueGrowthReal = useSelector(getValueGrowthReal)
  const nominalRealValue = useSelector(getNominalRealValue)
  const radioValue =
    nominalRealValue === NOMINAL ? valueGrowthNominal : valueGrowthReal

  const downloadCsv = () => {
    const params = {
      LocationId: locationId,
      IsNominal: nominalRealValue === NOMINAL,
      GDPTypeCode: radioValue,
      Year: minYear,
    }

    return downloadGDPByProvince(params)
  }

  return (
    <Panel
      title={'economy.gdp.gdp.GDP_BY_PROVINCE'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <GDPProduct
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelGDPProvince
