export const primaryMarket = {
  TITLE: 'Thị trường sơ cấp',
  OVERVIEW: 'Tổng quan',
  PRIMARY_MARKET: 'Thị trường sơ cấp',
  MARKET_STATISTICS: 'Thống kê thị trường',
  ALL: 'All',
  ALL_SECTOR: 'All sector L2',
  PUBLIC: 'Công chúng',
  PRIVATE: 'Riêng lẻ',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quaterly',
  YEARLY: 'Yearly',
}

export const optionMethod = {
  all: 'All',
  private: 'Public offering',
  PublicL: 'Private placement',
}

export const topIssuerRelease = {
  TITLE: 'Top 20 Bond Issuers by Value',
  tab: {
    Issue: 'Issue',
    Outstanding: 'Outstanding',
  },
  table: {
    STT: 'No',
    organizationShortName: 'Issuer',
    icbName: 'Sector L2',
    issueValue: 'Issue Value',
    issueValue2: 'Outstanding Value',
    unitMoney: '(Bil.VND)',
    averageDuration: 'Avg Duration',
    unitYear: '(Year)',
    averageCouponInterestRate: 'Avg Coupon',
    unitPercent: '(%)',
    averageRemain: 'Avg Remaining Duration',
    unitMonth: '(Months)',
  },
}

// giá trị phiếu phát hành
export const ValueOfBonds = {
  TITLE: 'Bond Issuance Value',
  fiter: {
    method: {
      issueMethod: 'Issue method',
      collateral: 'Collateral',
    },
  },
  chart: {
    labelLeft: 'Value (Bil. VND)',
    labelRight: 'Unit (%)',
    privatePlacement: 'Private',
    publicPlacement: ' Public',
    undefined: 'Undefined',
    rateOfChange: '% Growth ',
    tooltip: {
      label: ', Issue Method',
      privatePlacement: 'Private placement',
      publicPlacement: 'Public offering',
      undefined: 'Undefined',
      rateOfChange: 'Growth',
      unitMoney: 'Bil. VND',
      unitPercent: '% YoY',
    },
  },
  chartCollateral: {
    labelLeft: 'Value (Bil. VND)',
    labelRight: 'Unit (%)',
    privatePlacement: 'Collateral',
    publicPlacement: 'No Collateral',
    rateOfChange: '% Growth',
    tooltip: {
      label: ', Collateral',
      privatePlacement: 'Collateral',
      publicPlacement: 'No Collateral',
      rateOfChange: 'Growth',
      unitMoney: 'Bil. VND',
      unitPercent: '% YoY',
    },
  },
}

// kế hoạch phát hành
export const outStandingBons = {
  TITLE: 'Oustanding Bond Value',
  fiter: {
    method: {
      all: 'All',
      allLv2: 'All L2 sector',
    },
  },
  chart: {
    unitMoney: '(Bil. VND)',
    labelLeft: 'Value (Bil. VND)',
    tooltiplabel: ', Giá trị trái phiếu phát hành',
    outstandingBond: 'Outstanding bond Value',
    issueValue: 'Issue Value',
    redemptionValue: 'Maturity Value',
    valueOfCancelBond: 'Buyback Value',
    tooltipOutstandingBond: 'GT trái phiếu lưu hành cuối kì',
  },
}

export const issuesPlan = {
  TITLE: 'Issuance Schedule',
  table: {
    expectedDate: 'Date',
    bondCode: 'Bond ticker',
    organizationShortName: 'Issuer',
    issuePlanValue: 'Issue Value',
    planDuration: 'Duration',
    couponInterestRate: 'Coupon',
    additionTextIssuePlanValue: '(Bil. VND)',
    additionPlanDuration: '(Year)',
    additionCouponInterestRate: '(%)',
  },
}

// Trái phiếu trả chậm gốc, lãi theo ngành
export const latePayment = {
  TITLE: 'Late Payment by Sector',
  filter: {
    latePaymentType: {
      all: 'All',
      origin: 'Principal',
      interest: 'Coupon',
    },
  },
  chart: {
    unitMoney: '(Bil. VND)',
    tooltiplabel: ', all',
    labelLeft: 'Value (Bil. VND)',
    bank: 'Banks',
    RealEstate: 'Real Estate',
    financialServices: 'Financial Services',
    build: 'Construction & Materials',
    other: 'Others',
    numberDelay: 'Number of Issuer',
    valueDelay: 'Late Payment',
    numberJobDelay: 'Number of corporate paid late',
  },
}

export const expectedCash = {
  TITLE: 'Bond Repayment Schedule',
  filter: {
    methodType: {
      all: 'All',
      job: 'Sector',
    },
    CouponType: {
      all: 'All',
      origin: 'Principal',
      interest: 'Coupon',
    },
  },
  chart: {
    unitMoney: '(Bil. VND)',
    tooltiplabel: ', Giá trị trái phiếu phát hành',
    tooltiplabelJobAll: ', Expected cash flow value',
    tooltiplabelJobCoupon: ', Expected coupon value',
    tooltiplabelJobAllPrincipal: ', Expected principal value',
    labelLeft: 'Value (Bil. VND)',
    bank: 'Banks',
    RealEstate: 'Real Estate',
    financialServices: 'Financial Services',
    build: 'Construction & Materials',
    other: 'Others',
    valueCashAll: 'Expected cash flow value:',
    valueCashCoupon: 'Expected principal value:',
    valueCashPrincipal: 'Expected coupon value:',
    debtDueAll: 'Expected paid',
    debtDueCoupon: 'Expected principal paid',
    debtDuePrincipal: 'Expected coupon paid',
    total: 'Total',
  },
  tooltip:
    'The cash flow is updated based on the new payment dates of bonds subject to late payment and restructuring',
}

export const issuers = {
  TITLE: 'Issuers',
  SYNTHETIC: 'Synthetic',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  MOM: '(MoM)',
  QOQ: '(QoQ)',
  YOY: '(YoY)',
}
