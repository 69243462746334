import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { VIEW_BY } from '../constants'
import { convertType } from '../helper'
import { selectQuarter, selectTypeFilter, selectYear } from '../store/slice'
import style from '../style.module.css'
import ChartComponent from './ChartComponent'
import { getBarChartName } from './helper'
import {
  selectChangeInMarketShareChartData,
  selectLoading,
} from './store/slice'
import { getChangeInMarketShareChartData } from './store/thunk'

const ChangeInBrokerageMarketShare = ({ width, height }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const stockInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectChangeInMarketShareChartData)
  const typeFilter = useSelector(selectTypeFilter)
  const filterYear = useSelector(selectYear)
  const filterQuarter = useSelector(selectQuarter)

  useEffect(() => {
    if (stockInfo.organizationId && filterYear)
      dispatch(
        getChangeInMarketShareChartData({
          organizationId: stockInfo.organizationId,
          BusinessActivity: convertType(typeFilter),
          Quarter: filterQuarter ? filterQuarter : undefined,
          Year: filterYear,
        }),
      )
  }, [locale, stockInfo, typeFilter, filterYear, filterQuarter])

  const getBarKeyChart = () => {
    if (typeFilter === VIEW_BY.MARGIN_LENDING) {
      return 'c1'
    } else if (typeFilter === VIEW_BY.PROPRIETARY) {
      return 'c2'
    } else {
      return 'c3'
    }
  }

  return (
    <div id="changeInBrokerageMarketShareId" className="h-100">
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          {dataChart.length ? (
            <SizeTracker>
              {(size) => {
                return (
                  <>
                    <div className="position-relative">
                      <div className={style.titleHeader}>
                        <Translate value={getBarChartName(typeFilter)} />
                      </div>
                      {size.height && (
                        <ChartComponent
                          data={dataChart}
                          width={width}
                          height={height - size.height}
                          keyXAxis={'ti'}
                          barKey={getBarKeyChart()}
                        />
                      )}
                      <div style={{ height: 10 }} />
                    </div>
                  </>
                )
              }}
            </SizeTracker>
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
}

export default ChangeInBrokerageMarketShare
