import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../hooks/useI18n'
import { PopupHeaderCommon } from '../../popup/PopupHeaderCommon'
import Tabs, { TAB_TYPE } from '../../tabs'
import {
  changeCurrentTab,
  resetCurrentChartLayout,
  selectChartLayoutCustoms,
  selectChartLayoutLibs,
  selectCurrentTab,
} from '../store/slice'
import { getChartLayout } from '../store/thunk'
import { Content } from './Content'

export const LoadTemplate = ({ changePopUpStatus }) => {
  const dispatch = useDispatch()
  const chartLayoutCustoms = useSelector(selectChartLayoutCustoms)
  const chartLayoutLibs = useSelector(selectChartLayoutLibs)
  const currentTab = useSelector(selectCurrentTab)
  const templateLibraryLabel = UseI18n('common.tradingView.TEMPLATE_LIBRARY')
  const myTemplateLabel = UseI18n('common.tradingView.MY_TEMPLATE')

  useEffect(() => {
    dispatch(getChartLayout())
  }, [])

  const saveDataActionTab = (index) => {
    dispatch(resetCurrentChartLayout(index))
    dispatch(changeCurrentTab(index))
  }

  return (
    <PopupHeaderCommon
      setIsShow={changePopUpStatus}
      title="common.tradingView.LOAD_TEMPLATE"
    >
      <Tabs
        active={currentTab}
        itemStyle={{ padding: '0 20px 0 0' }}
        tabType={TAB_TYPE.LINK}
        parentStyle={{ marginBottom: 10 }}
        saveDataActionTab={saveDataActionTab}
      >
        <div title={templateLibraryLabel}>
          <Content
            data={chartLayoutLibs}
            changePopUpStatus={changePopUpStatus}
          />
        </div>
        <div title={myTemplateLabel}>
          <Content
            data={chartLayoutCustoms}
            changePopUpStatus={changePopUpStatus}
            hasDeleteTemplate
          />
        </div>
      </Tabs>
    </PopupHeaderCommon>
  )
}
