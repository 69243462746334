import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SelectSearchMultiple } from '../../../../common/selectCustom/SelectSearchMultiple'
import { selectFundManagement } from '../store/slice'
import { getFundManagementCompanyThunk } from '../store/thunk'

export const FundManagement = ({ value, onChange }) => {
  const dispatch = useDispatch()

  const fundManagements = useSelector(selectFundManagement)

  useEffect(() => {
    dispatch(getFundManagementCompanyThunk())
  }, [])

  return (
    <div className="mr-16">
      <SelectSearchMultiple
        values={value}
        width={250}
        isSearch
        isOptionAll
        isDisableUnSelect={value.length >= 10}
        options={fundManagements}
        textEmpty="fund.fundCenter.ALL_MANAGEMENT"
        textAll="fund.fundCenter.ALL_MANAGEMENT"
        onChange={(value) => onChange({ management: value })}
      />
    </div>
  )
}
