export const addedGrowthChildren = (arr) => {
  const getGrowthChildrenByIndex = (item) => {
    return {
      id: `${item.id}_`,
      name: 'economy.importExport.importExport.YOY_GROWTH',
      year: item.year,
      month: item.month,
      value: item.growth,
      isSubCategory: true,
    }
  }

  return []
    .concat(...arr.map((item) => [item, getGrowthChildrenByIndex(item)]))
    .slice(0, -1)
}

export const getUniqueCategories = (data) => {
  const allCategories = data?.map((item) => ({
    id: item.id,
    name: item.name,
    isSubCategory: item.isSubCategory,
  }))

  const uniqueCategories = Object.values(
    allCategories.reduce((acc, curr) => {
      acc[curr.id + '|' + curr.name] = curr
      return acc
    }, {}),
  )

  return uniqueCategories
}

export const reOrderByMonthAndYear = (data) => {
  const sorted = data?.sort((month, year) => {
    month = month?.split('-')
    year = year?.split('-')
    return new Date(month[1], month[0], 1) - new Date(year[1], year[0], 1)
  })
  return sorted
}

export const addColorIdToData = (data) => {
  return data.map((item, index) => ({
    ...item,
    colorId: index,
  }))
}

export const getRandColor = (data, listCurrentCheckedItems) => {
  const colorIdsArr = [
    ...Array(data.filter((item) => !item.isSubCategory).length).keys(),
  ]

  const colorIdsInListCategory = listCurrentCheckedItems.map(
    (item) => item.colorId,
  )

  const remainColors = colorIdsArr.filter(
    (item) => !colorIdsInListCategory.includes(item),
  )

  return remainColors[Math.floor(Math.random() * remainColors.length)]
}

export const keepCurrentListByIndex = (indexArr, data) => {
  const addedItems =
    indexArr.length && data.length
      ? indexArr?.map((index) => data[index])
      : data

  return addedItems
}
