import React from 'react'
import { COMPONENT } from '../../../configs/Layout'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import Content from './Content'

const MarginLoans = () => {
  return (
    <div
      style={{
        marginTop: COMPONENT.MARGIN_TOP,
        height: `calc(100% - ${COMPONENT.MARGIN_TOP}px)`,
      }}
    >
      <ScrollGroupComponent>
        <div className="h-100">
          <Content />
        </div>
      </ScrollGroupComponent>
    </div>
  )
}

export default MarginLoans
