import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { CellWithColor } from '../../../../common/table/CellWithColor'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import {
  TABLE_ASSET_ALLOCATION_KEYS,
  TABLE_ASSET_ALLOCATION_TH_ROW_KEYS,
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  VALUE_TYPES,
} from '../constants'

export const assetAllocationSchema = ({ valueType, locale }) => {
  return TABLE_ASSET_ALLOCATION_KEYS.map((item) => {
    const result = {
      ...item,
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
      return {
        ...result,
        render: (val) => formatDateTime(val, FORMAT.DATE, locale),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_STOCK_VALUE,
        TABLE_COL_KEYS.COL_STOCK_MOST_RECENT_CHANGE,
        TABLE_COL_KEYS.COL_BOND_VALUE,
        TABLE_COL_KEYS.COL_BOND_MOST_RECENT_CHANGE,
        TABLE_COL_KEYS.COL_CASH_AND_ORDER_VALUE,
        TABLE_COL_KEYS.COL_CASH_AND_ORDER_MOST_RECENT_CHANGE,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        colId: result.colId + valueType,
        render: (val) => (
          <CellWithColor
            val={val}
            format={(val) =>
              valueType === VALUE_TYPES.ABSOLUTE
                ? formatVal(val / TABLE_COL_FORMATS.COL_STOCK_VALUE, 2, true)
                : valToPercent(val)
            }
            isPercent={valueType === VALUE_TYPES.RELATIVE}
          />
        ),
      }
    }

    return result
  })
}

export const assetAllocationTableThSchema = ({ valueType }) => {
  return TABLE_ASSET_ALLOCATION_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      if (col.colId === TABLE_COL_KEYS.COL_UPDATE_DATE_NAV) {
        return {
          ...col,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
          },
          thTooltipCustom: 'fund.fundCenter.statistic.COL_FREQUENCY_NOTE',
          rowSpan: 2,
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK,
          TABLE_COL_KEYS.COL_BOND,
          TABLE_COL_KEYS.COL_CASH_AND_ORDER,
        ].includes(col.colId)
      ) {
        return {
          ...col,
          isI18n: false,
          thStyle: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
          },
          colSpan: 2,
          renderTh: () => (
            <div style={{ textAlign: 'center' }}>
              <Span className="mr-4">
                <Translate value={col.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (
                {valueType === VALUE_TYPES.ABSOLUTE ? (
                  <Translate value="common.UNIT.BILLION_VND" />
                ) : (
                  <Translate value="common.UNIT.PERCENT" />
                )}
                )
              </Span>
            </div>
          ),
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK_VALUE,
          TABLE_COL_KEYS.COL_STOCK_MOST_RECENT_CHANGE,
          TABLE_COL_KEYS.COL_BOND_VALUE,
          TABLE_COL_KEYS.COL_BOND_MOST_RECENT_CHANGE,
          TABLE_COL_KEYS.COL_CASH_AND_ORDER_VALUE,
          TABLE_COL_KEYS.COL_CASH_AND_ORDER_MOST_RECENT_CHANGE,
        ].includes(col.colId)
      ) {
        return {
          ...col,
          colId: col.colId + valueType,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
          },
        }
      }

      return {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
        },
      }
    }),
  )
}

export const assetAllocationThCellTopById = (valueType) => ({
  [TABLE_COL_KEYS.COL_STOCK_VALUE + valueType]: [TABLE_COL_KEYS.COL_STOCK],
  [TABLE_COL_KEYS.COL_STOCK_MOST_RECENT_CHANGE + valueType]: [
    TABLE_COL_KEYS.COL_STOCK,
  ],
  [TABLE_COL_KEYS.COL_BOND_VALUE + valueType]: [TABLE_COL_KEYS.COL_BOND],
  [TABLE_COL_KEYS.COL_BOND_MOST_RECENT_CHANGE + valueType]: [
    TABLE_COL_KEYS.COL_BOND,
  ],
  [TABLE_COL_KEYS.COL_CASH_AND_ORDER_VALUE + valueType]: [
    TABLE_COL_KEYS.COL_CASH_AND_ORDER,
  ],
  [TABLE_COL_KEYS.COL_CASH_AND_ORDER_MOST_RECENT_CHANGE + valueType]: [
    TABLE_COL_KEYS.COL_CASH_AND_ORDER,
  ],
})
