import { useState } from 'react'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import GDPGrowthBySector from './GDPGrowthBySector'

export const GDP_GROWTH_BY_SECTOR = 'GDPGrowthBySector'

const PanelGDPGrowthBySector = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)
  const nameScreen = UseI18n('constants.route.economy.gdp.totalGDP.LABEL')
  const chartName = UseI18n('economy.gdp.gdp.GDP_GROWTH_BY_SECTOR')
  const tickerName = basicInfo.ticker

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title={'economy.gdp.gdp.GDP_GROWTH_BY_SECTOR'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: GDP_GROWTH_BY_SECTOR,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
      handleCustom={setIsFullScreen}
    >
      <GDPGrowthBySector
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}

export default PanelGDPGrowthBySector
