import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { groupScrollComponentClass } from '../../common/ScrollGroupComponent'
import { Z_INDEX } from '../../constants/Common'
import EventEmitter, {
  CLOSE_FULL_COMPONENT,
  COMPONENT_RESIZE,
  COMPONENT_RESIZE_START,
  FULL_COMPONENT,
} from '../../utils/EventEmitter'
import useWindowDevicePixelRatio from '../hooks/useWindowDevicePixelRatio'
import { COMPONENT } from './../../../configs/Layout'

let newWidth = null
let diff = null
const DEFAULT_POSITION = -6

export const Resize = ({
  setSizes,
  sizes,
  panelKey,
  resizeClassName,
  size,
}) => {
  const { formatWithZoom } = useWindowDevicePixelRatio()

  const [isShow, setIsShow] = useState(false)
  const [disable, setDisable] = useState(false)
  const [position, setPosition] = useState(DEFAULT_POSITION)

  useEffect(() => {
    const disableResize = () => {
      setDisable(true)
    }
    EventEmitter.subscribe(FULL_COMPONENT, disableResize)
    return () => EventEmitter.unsubscribe(FULL_COMPONENT, disableResize)
  }, [])

  useEffect(() => {
    const allowResize = () => {
      setDisable(false)
    }
    EventEmitter.subscribe(CLOSE_FULL_COMPONENT, allowResize)
    return () => EventEmitter.unsubscribe(CLOSE_FULL_COMPONENT, allowResize)
  }, [])

  const onMouseEnter = () => {
    if (!disable) {
      setIsShow(true)
    }
  }

  const onMouseLeave = () => {
    if (position === DEFAULT_POSITION) {
      setIsShow(false)
    }
  }

  const ref = useRef({})
  const onResizeColumn = (e) => {
    const minWidth = sizes[panelKey].minWidth || COMPONENT.MIN_SIZE.WIDTH

    const width =
      sizes[panelKey].width +
      (formatWithZoom(e.clientX || e.touches?.[0]?.clientX || 0, true) -
        ref.current.clientX)
    if (width < minWidth) {
      return
    }

    newWidth = Math.max(width, minWidth)
    diff = newWidth - sizes[panelKey].width

    sizes[panelKey].filterResize.forEach(({ key, ratioWidth }) => {
      const width = sizes[key].width - ratioWidth * diff
      const itemMinWidth = sizes[key].minWidth || COMPONENT.MIN_SIZE.WIDTH
      if (width <= itemMinWidth) {
        diff = (sizes[key].width - itemMinWidth) / ratioWidth
      }
    })

    newWidth = sizes[panelKey].width + diff
    setPosition(position - diff)
  }

  const onStopResizeColumn = () => {
    if (diff) {
      setPosition(DEFAULT_POSITION)

      const obj = {}
      sizes[panelKey].filterResize.forEach(({ key, ratioWidth, ratioLeft }) => {
        obj[key] = {
          ...sizes[key],
          width: sizes[key].width - ratioWidth * diff,
          left: sizes[key].left - ratioLeft * diff,
        }
      })

      setSizes({
        ...sizes,
        [panelKey]: {
          ...sizes[panelKey],
          width: newWidth,
        },
        ...obj,
      })

      newWidth = null
      diff = null
      EventEmitter.dispatch(COMPONENT_RESIZE)
    }
    setIsShow(false)
    if (
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      )
    ) {
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.overflow = 'scroll'
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.marginBottom = '-17px'
    }
    window.removeEventListener('mousemove', onResizeColumn)
    window.removeEventListener('mouseup', onStopResizeColumn)
    window.removeEventListener('touchmove', onResizeColumn)
    window.removeEventListener('touchend', onStopResizeColumn)
  }

  const onResizeStart = (e) => {
    EventEmitter.dispatch(COMPONENT_RESIZE_START)
    setIsShow(true)
    ref.current.clientX = formatWithZoom(
      e.clientX || e.touches?.[0]?.clientX || 0,
      true,
    )
    ref.current.clientY = formatWithZoom(
      e.clientY || e.touches?.[0]?.clientY || 0,
      true,
    )
    if (
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      )
    ) {
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.overflow = 'hidden'
      document.querySelector(
        `#${groupScrollComponentClass} .scrollbars div:first-child`,
      ).style.marginBottom = '0'
    }
    window.addEventListener('mousemove', onResizeColumn)
    window.addEventListener('mouseup', onStopResizeColumn)
    window.addEventListener('touchmove', onResizeColumn)
    window.addEventListener('touchend', onStopResizeColumn)
  }

  const getStyle = () => {
    const styles = {
      backgroundColor: isShow ? '#454b56' : '',
      zIndex: Z_INDEX.PANEL_RESIZE,
    }

    return {
      ...styles,
      right: position,
      height: size.height,
      cursor: isShow ? 'ew-resize' : 'default',
    }
  }

  return (
    <div
      className={resizeClassName}
      style={getStyle()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onTouchStart={onResizeStart}
      onMouseDown={onResizeStart}
    />
  )
}

Resize.propTypes = {
  setSizes: PropTypes.func.isRequired,
  sizes: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  resizeClassName: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
}
