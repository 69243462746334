import { useDispatch, useSelector } from 'react-redux'
import { dashboardTypes, screenTypes } from '../../constants'
import { ReactComponent as IconChartOfTheDay } from '../../icon/icon-chart-of-the-day.svg'
import {
  changeDashboard,
  selectChartOfTheDay,
  selectDashboard,
} from '../../store/slice'
import { RootButton } from './common/RootButton'

const ChartOfTheDay = () => {
  const dispatch = useDispatch()
  const activeData = useSelector(selectDashboard)
  const data = useSelector(selectChartOfTheDay)

  const redirect = () => {
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.CHART_OF_THE_DAY,
        data,
      }),
    )
  }

  return (
    data && (
      <RootButton
        isActive={activeData.dashboardType === dashboardTypes.CHART_OF_THE_DAY}
        redirect={redirect}
        renderIcon={() => <IconChartOfTheDay style={{ height: 14 }} />}
        title={data.name}
      />
    )
  )
}

export default ChartOfTheDay
