import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { TIME_RANGE, VALUE_ORDER } from '../../../constants'
import { pricePerformanceAbsoluteChart } from './thunk'

const slice = createSlice({
  name: 'sector/sectorStatistics/overview/pricePerformance/chartPricePerformance',
  initialState: {
    isLoading: true,
    timeRange: TIME_RANGE['1Y'],
    order: VALUE_ORDER.RELATIVE,
    sectorActive: null,
    dataChart: [],
    showLabelExactly: false,
  },
  reducers: {
    changeTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    changeOrderValue: (state, action) => {
      state.order = action.payload
    },
    changeSectorActive: (state, action) => {
      state.sectorActive = action.payload
    },
    changeShowLabelExactly: (state, action) => {
      state.showLabelExactly = action.payload
    },
    changeDataChart: (state, action) => {
      const mewArray = [...state.dataChart]
      mewArray.forEach((object) => {
        delete object[action.payload]
      })
      state.dataChart = mewArray
    },
  },
  extraReducers: (builder) => {
    builder.addCase(pricePerformanceAbsoluteChart.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      pricePerformanceAbsoluteChart.fulfilled,
      (state, action) => {
        state.dataChart = convertData(
          action.payload.data,
          'closeIndex',
          VALUE_ORDER.ABSOLUTE,
        )
        state.isLoading = false
      },
    )
    builder.addCase(pricePerformanceAbsoluteChart.rejected, (state, action) => {
      state.isLoading = action.payload.loading
    })
  },
})

export const {
  changeTimeRange,
  changeOrderValue,
  changeSectorActive,
  changeShowLabelExactly,
  changeDataChart,
} = slice.actions

export const selectLoading = (state) => state[slice.name].isLoading
export const selectTimeRange = (state) => state[slice.name].timeRange
export const selectOrderValue = (state) => state[slice.name].order
export const selectSectorActive = (state) => state[slice.name].sectorActive
export const selectPricePerformanceChart = (state) =>
  state[slice.name].dataChart
export const selectShowLabelExactly = (state) =>
  state[slice.name].showLabelExactly

register(slice.name, slice.reducer)

const convertData = (data, keyData, order) => {
  let newArray = [...data]
  const result = []
  let object = {}
  while (newArray.length) {
    const min = getMinData(newArray, 'tradingDateId')
    const arr = []
    newArray.forEach((item) => {
      if (item.tradingDateId === min) {
        arr.push(item)
      }
    })
    newArray = newArray.filter((i) => i.tradingDateId !== min)
    object = arr.reduce(
      (obj, item) =>
        Object.assign(obj, {
          tradingDateId: item.tradingDateId,
          [item.icbId]:
            order === VALUE_ORDER.RELATIVE
              ? item[keyData] * 100
              : item[keyData],
        }),
      {},
    )
    result.push(object)
  }
  return result
}

const getMinData = (data, keyMin) => {
  const sortData = data
    .map((value) => (value[keyMin] ? value[keyMin] : undefined))
    .sort()
  return sortData[0]
}
