import { maxBy, minBy } from 'lodash'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber, uuid } from '../../../../../utils/Common'
import { renderTimeType } from '../../../primaryMarket/marketStatistics/helper'
import { BAR_COLOR_CREDIT_RATIO, TIME_FREQUENCY } from '../constants'
import {
  keys,
  selectCreditRatio,
  selectFilterDebtAndLoan,
  selectLoading,
} from '../store/slice'
import { getCreditRatio } from '../store/thunk'
import ChartTooltip from './ChartTooltip'
import ChartTooltip2 from './ChartTooltip_2'

const CreditRatio = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading(keys.CREDIT_RATIO))
  const { data } = useSelector(selectCreditRatio)
  const basicInfo = useSelector(selectBasicInfo)
  const activeTab = useSelector(selectFilterDebtAndLoan)

  const formatDataBarChart = useMemo(() => {
    return data.map((item, id) => ({
      ...item,
      date:
        activeTab === TIME_FREQUENCY.QUARTER
          ? `Q${item.realQuarter}-${item.realYear}${renderTimeType(
              activeTab,
              `Q${item.realQuarter}-${item.realYear}`,
            )}`
          : `${item.realYear}${renderTimeType(activeTab, item.realYear)}`,
      rT0037: item.rT0037,
      rT0328: item.rT0328,
      rT0046: item.rT0046,
      rT0044: item.rT0044,
    }))
  }, [data])
  const minTop = useMemo(() => {
    const rT0328 = minBy(formatDataBarChart, 'rT0328')?.rT0328
    const rT0037 = minBy(formatDataBarChart, 'rT0037')?.rT0037
    return rT0328 > rT0037 ? rT0037 : rT0328
  }, [formatDataBarChart])
  const maxTop = useMemo(() => {
    const rT0328 = maxBy(formatDataBarChart, 'rT0328')?.rT0328
    const rT0037 = maxBy(formatDataBarChart, 'rT0037')?.rT0037
    return rT0328 > rT0037 ? rT0328 : rT0037
  }, [formatDataBarChart])
  const minBot = useMemo(() => {
    const rT0328 = minBy(formatDataBarChart, 'rT0046')?.rT0046
    const rT0037 = minBy(formatDataBarChart, 'rT0044')?.rT0044
    return rT0328 > rT0037 ? rT0037 : rT0328
  }, [formatDataBarChart])
  const maxBot = useMemo(() => {
    const rT0328 = maxBy(formatDataBarChart, 'rT0046')?.rT0046
    const rT0037 = maxBy(formatDataBarChart, 'rT0044')?.rT0044
    return rT0328 > rT0037 ? rT0328 : rT0037
  }, [formatDataBarChart])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => <ChartTooltip dataChart={dataChart} />
  const renderTooltip2 = (dataChart) => <ChartTooltip2 dataChart={dataChart} />

  useEffect(() => {
    if (basicInfo.organizationId) {
      const payload = {
        TimeFrequency: activeTab,
        OrganizationId: basicInfo.organizationId,
        Top: 5,
      }

      dispatch(getCreditRatio(payload))
    }
  }, [locale, basicInfo.organizationId, activeTab])

  return (
    <Panel
      title="bond.corporateBond.creditRatio.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={I18n.t('bond.corporateBond.creditRatio.TITLE')}
      downloadJpgParams={{
        domId: 'creditRatioChartId',
        nameScreen: I18n.t('bond.corporateBond.issuers.TITLE'),
        chartName: I18n.t('bond.corporateBond.creditRatio.TITLE'),
        tickerName: '',
      }}
      // bodyClass={style.creditRatio}
    >
      <div id="creditRatioChartId">
        {isLoading ? (
          <Loading />
        ) : !!formatDataBarChart.length ? (
          <>
            <ChartContainer
              data={formatDataBarChart}
              width={width}
              height={height / 2 - 40}
              keyXAxis="date"
              isHasCartesianGrid={true}
              yAxis={[
                {
                  id: 'left',
                  keys: ['rT0037', 'rT0328'],
                  orientation: 'left',
                  isStackedBar: true,
                  label: '',
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  tickNum: 5,
                  isBarChart: true,
                  min: minTop,
                  max: maxTop,
                },
              ]}
              margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
              renderCustomTooltip={renderTooltip}
            >
              {({ chartContentWidth }) => (
                <>
                  <Scatter
                    yAxisId="left"
                    dataKey="rT0037"
                    isAnimationActive={false}
                    line
                    fill={BAR_COLOR_CREDIT_RATIO[0]}
                    shape="circle"
                  />
                  <Scatter
                    yAxisId="left"
                    dataKey="rT0328"
                    isAnimationActive={false}
                    line
                    fill={BAR_COLOR_CREDIT_RATIO[1]}
                    shape="circle"
                  />
                </>
              )}
            </ChartContainer>
            <Footer
              key={width}
              list={[
                {
                  text: 'bond.corporateBond.creditRatio.TOTAL_DEBT',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR_CREDIT_RATIO[0],
                  },
                },
                {
                  text: 'bond.corporateBond.creditRatio.NET_DEBT',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR_CREDIT_RATIO[1],
                  },
                },
              ]}
              appendStyle={{
                height: 16,
                marginBottom: 24,
                marginTop: 8,
              }}
            />
            <ChartContainer
              data={formatDataBarChart}
              width={uuid()}
              height={height / 2 - 44}
              keyXAxis="date"
              yAxis={[
                {
                  id: 'left',
                  keys: ['rT0046', 'rT0044'],
                  orientation: 'left',
                  isStackedBar: true,
                  label: '',
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  tickNum: 5,
                  isBarChart: true,
                  min: minBot,
                  max: maxBot,
                },
              ]}
              margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
              renderCustomTooltip={renderTooltip2}
            >
              {({ chartContentWidth }) => (
                <>
                  <Scatter
                    yAxisId="left"
                    dataKey="rT0046"
                    isAnimationActive={false}
                    line
                    fill={BAR_COLOR_CREDIT_RATIO[0]}
                    shape="circle"
                  />
                  <Scatter
                    yAxisId="left"
                    dataKey="rT0044"
                    isAnimationActive={false}
                    line
                    fill={BAR_COLOR_CREDIT_RATIO[1]}
                    shape="circle"
                  />
                </>
              )}
            </ChartContainer>
            <Footer
              key={uuid()}
              list={[
                {
                  text: 'bond.corporateBond.creditRatio.DEBT_EXPENSE',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR_CREDIT_RATIO[0],
                  },
                },
                {
                  text: 'bond.corporateBond.creditRatio.DEBT_EXPENSE_EDIT',
                  type: TYPE.SQUARE,
                  before: {
                    bgColor: BAR_COLOR_CREDIT_RATIO[1],
                  },
                },
              ]}
              appendStyle={{
                height: 16,
                marginBottom: 4,
                marginTop: 8,
              }}
            />
          </>
        ) : (
          <NoData />
        )}
      </div>
    </Panel>
  )
}

export default CreditRatio
