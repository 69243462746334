import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import { selectDataFDF } from '../../filter/store/slice'
import style from '../../preview/style.module.css'
import SizeComponent from '../SizeComponent'

const LatestOwnershipStructureTable = () => {
  const data = useSelector(selectDataFDF)
  const locale = useSelector((state) => state.i18n.locale)

  const mappingTable = [
    {
      title: 'corporate.companySnapshot.TOTAL_SHARES',
      valueVolume: data.majorShareHolder.outstandingShare,
      valuePercentage: 1,
    },
    {
      title: 'corporate.companySnapshot.MAJOR_SHAREHOLDER_OWNERSHIP',
      valueVolume: data.majorShareHolder.major,
      valuePercentage: data.majorShareHolder.majorPercent,
    },
    {
      title: 'corporate.companySnapshot.DIRECTOR_AND_RELATED_PERSON_OWNERSHIP',
      valueVolume: data.majorShareHolder.director,
      valuePercentage: data.majorShareHolder.directorPercent,
    },
    {
      title: 'corporate.companySnapshot.OTHER',
      valueVolume: data.majorShareHolder.others,
      valuePercentage: data.majorShareHolder.otherPercent,
    },
  ]

  return (
    <SizeComponent>
      <div
        className={style.latestOwnershipStructureTable}
        style={{ marginBottom: 8 }}
      >
        <table>
          <thead>
            <tr>
              <th>
                <Translate value="corporate.companySnapshot.LASTEST_OWNERSHIP" />
                <br />
                <Translate value="corporate.companySnapshot.LASTEST_OWNERSHIP2" />
                {formatDateTime(new Date(), FORMAT.DATE, locale)}
                {')'}
              </th>
              <th style={{ textAlign: 'end' }}>
                <Translate value="corporate.companySnapshot.VOLUME" />
              </th>
              <th style={{ textAlign: 'end' }}>%</th>
            </tr>
          </thead>
          <tbody>
            {mappingTable.map((item, index) => (
              <tr key={index}>
                <td>
                  <Translate value={item.title} />
                </td>
                <td style={{ textAlign: 'end' }}>
                  {formatVal(item.valueVolume, 0)}
                </td>
                <td style={{ textAlign: 'end' }}>
                  {valToPercent(item.valuePercentage)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </SizeComponent>
  )
}

export default LatestOwnershipStructureTable
