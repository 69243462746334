import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorStatisticsOverviewService from '../../../../../../../core/services/sector/sectorStatistics/OverviewService'

export const getValuation = createAsyncThunk(
  'sector/sectorStatistics/overview/pricePerformance/chartValuation/VALUATION',
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await SectorStatisticsOverviewService.getValuation(
        params,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
