import PropTypes from 'prop-types'
import { TextEllipsisStaticLine } from '../../../../common/textEllipsis/TextEllipsisStaticLine'

const FirstCellColumn = ({ val, props, level }) => {
  const getStyle = (level) => {
    if (level === 2) {
      return { marginLeft: 32 }
    }

    return { marginLeft: 8, color: '#fecf2f' }
  }

  return (
    <td {...props}>
      <TextEllipsisStaticLine
        appendStyle={getStyle(level)}
        val={val}
        isI18n={false}
      />
    </td>
  )
}

FirstCellColumn.propTypes = {
  val: PropTypes.string,
  props: PropTypes.object,
  level: PropTypes.number,
}

export default FirstCellColumn
