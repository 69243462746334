import React, { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import useGetExchangeV2 from '../../../../common/masterData/useGetExchangeV2'
import { LIST_TYPE } from '../../../common/constants'
import style from '../style.module.css'
import useGetIndicator from '../useGetIndicator'
import ListAll from './ListAll'
import ListCompanies from './ListCompanies'
import ListEconomy from './ListEconomy'
import ListIndices from './ListIndices'
import ListSector from './ListSectors'
import ListWatchList from './ListWatchList'

const ListSecurities = ({
  valueSearch,
  securitiesState,
  setSecuritiesState,
}) => {
  const { indicatorsTree, loadingEconomy } = useGetIndicator(false)
  const { exchangesTree } = useGetExchangeV2(true, true)

  const [listType, setListType] = useState(LIST_TYPE.ALL)

  const getListSecurities = () => {
    const props = {
      valueSearch,
      securitiesState,
      setSecuritiesState,
      exchangesTree,
    }

    switch (listType) {
      case LIST_TYPE.COMPANIES:
        return <ListCompanies {...props} />
      case LIST_TYPE.SECTOR:
        return <ListSector {...props} />
      case LIST_TYPE.ECONOMY:
        return (
          <ListEconomy
            {...props}
            indicatorsTree={indicatorsTree}
            loadingEconomy={loadingEconomy}
          />
        )
      case LIST_TYPE.INDICES:
        return <ListIndices {...props} />
      case LIST_TYPE.WATCH_LIST:
        return <ListWatchList {...props} />
      default:
        return <ListAll {...props} />
    }
  }

  return (
    <div className={style.listSecurities}>
      <div className={style.headerListSecurities}>
        {Object.keys(LIST_TYPE).map((key) => {
          return (
            <div
              key={key}
              onClick={() => setListType(LIST_TYPE[key])}
              className={`${style.listTab} ${
                listType === LIST_TYPE[key] ? style.active : ''
              }`}
            >
              <Translate value={`financialChart.${key}`} />
            </div>
          )
        })}
      </div>
      <div
        className={style.itemList}
        style={{ borderRight: '1px solid #e6e6e6' }}
      >
        {getListSecurities()}
      </div>
    </div>
  )
}

export default ListSecurities
