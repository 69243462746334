import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import useWrapper from '../../../../../common/hooks/useWrapper'
import SearchBox from '../../../../../common/topInfo/components/SearchBox'
import CurrentState from '../../../../../utils/CurrentState'
import { DropdownItem } from './DropdownItem'
import {
  changeBasicInfo,
  selectBasicInfo,
  selectBondTicker,
  selectList,
  selectLoadingSearch,
} from './store/slice'
import { getSearchData } from './store/thunk'

const TopInfo = (props) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { width: wrapperWidth } = useWrapper()
  const [searchString, setSearchString] = useState('')
  const [limitScroll, setlimitScroll] = useState(19)
  const basicInfo = useSelector(selectBasicInfo)
  const loadingSearch = useSelector(selectLoadingSearch)
  const listCompany = useSelector(selectList)
  const bondId = useSelector(selectBondTicker)
  // const { data } = CurrentState.getCurrentState()

  useEffect(() => {
    dispatch(getSearchData({ bondId }))
  }, [bondId])

  // useEffect(() => {
  //   const bondIdSelect = data?.headerSearch?.bondId
  //   if (bondIdSelect !== bondId) {
  //     dispatch(getSearchData({ bondId: bondIdSelect }))
  //     dispatch(changeBasicInfo(bondIdSelect))
  //   }
  // }, [])

  const options = useMemo(() => {
    const data = listCompany.map((e) => ({ id: e.bondId, value: { ...e } }))
    if (searchString) {
      return data.filter((e) =>
        e.value.ticker.toLowerCase().includes(searchString.toLowerCase()),
      )
    }
    return data
  }, [listCompany, loadingSearch, searchString])

  const handleSearch = (keyword) => {
    setSearchString(keyword)
  }

  const handleFocusInputSearch = () => {
    setSearchString('')
  }

  const handleSelect = (bond) => {
    const key = CurrentState.getCurrentState().key
    CurrentState.delete(key)
    CurrentState.addRoute({
      key,
      data: {
        route: location.pathname,
        disableRecentVisited: true,
        headerSearch: {
          organizationId: basicInfo?.organizationId,
          bondId: bond.bondId,
          ticker: bond.ticker,
        },
      },
    })
    dispatch(changeBasicInfo(bond))
  }

  const handleChangeLoading = () => {
    // dispatch(changeLoadingSearch(true))
  }

  const scrollToBottomCallback = () => {
    setlimitScroll(limitScroll + 20)
  }

  return (
    <div
      className="top-info"
      style={{
        width: wrapperWidth || '100%',
        height: props.height ?? '48px',
      }}
    >
      <SearchBox
        title={basicInfo?.ticker}
        description={
          basicInfo
            ? basicInfo?.ticker +
              ' / ' +
              (basicInfo?.organizationShortName ??
                basicInfo?.organizationName ??
                '')
            : ''
        }
        onFocus={handleFocusInputSearch}
        onTextChange={handleSearch}
        options={options.slice(0, limitScroll)}
        loading={loadingSearch}
        onSelect={props.handleSelect || handleSelect}
        renderOption={(value, isCurrentItem) => (
          <DropdownItem value={value} isCurrentItem={isCurrentItem} />
        )}
        classNameInputSearch={props?.style}
        onChangeLoading={handleChangeLoading}
        scrollToBottomCallback={scrollToBottomCallback}
      />
    </div>
  )
}

export default TopInfo
