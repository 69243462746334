import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleComboChartData, handleDuplicateId } from '../helper'
import { getContributionToCreditGrowth } from './thunk'

const initialState = {
  timeRange: 'OneYear',
  radioValue: 'VALUE',
  stackChartData: [],
  stackChartLoading: true,
  keyNotDuplicate: [],
}

const slice = createSlice({
  name: 'economy/monetary/moneySuppliesCredit/panelContributionCreditGrowth',
  initialState,
  reducers: {
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
    handleRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContributionToCreditGrowth.pending, (state) => {
      state.stackChartLoading = true
    })
    builder.addCase(
      getContributionToCreditGrowth.fulfilled,
      (state, action) => {
        state.stackChartLoading = false
        state.stackChartData = handleComboChartData(action.payload || [])
        state.keyNotDuplicate = handleDuplicateId(action.payload || [])
      },
    )
    builder.addCase(getContributionToCreditGrowth.rejected, (state, action) => {
      state.stackChartLoading = action.payload.isLoading
    })
  },
})

export const { handleTimeRange, handleRadioValue } = slice.actions

export const getTimeRange = (state) => state[slice.name].timeRange
export const getRadioValue = (state) => state[slice.name].radioValue
export const getStackChartData = (state) => state[slice.name].stackChartData
export const getStackChartLoading = (state) =>
  state[slice.name].stackChartLoading
export const getKeyNotDuplicate = (state) => state[slice.name].keyNotDuplicate

register(slice.name, slice.reducer)
