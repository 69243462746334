export const overview = {
  //Valuation
  NAME: 'Overview',
  VALUATION: 'Valuation',
  LATEST_COMPANY_RATIO: 'Latest Company Ratio',
  LATEST_SECTOR_RATIO: 'Latest Sector Ratio',
  COMPANY_RATIO_RANGE: 'Company Ratio Range',
  CURRENT_TOOLTIP:
    'P/E in previous years are at the last trading date; while P/E in current year is at the latest date.',
  chart: {
    PE: {
      title: 'P/E',
      min: 'P/E Min',
      max: 'P/E Max',
      current: 'Current P/E',
      endYear: 'Ending P/E',
      currentPeer: "Current Peer's P/E",
      endYearPeer: "Ending Peer's P/E",
    },
    PB: {
      title: 'P/B',
      min: 'P/B Min',
      max: 'P/B Max',
      current: 'Current P/B',
      endYear: 'Ending P/B',
      currentPeer: "Current Peer's P/B",
      endYearPeer: "Ending Peer's P/B",
    },
    PS: {
      title: 'P/S',
      min: 'P/S Min',
      max: 'P/S Max',
      current: 'Current P/S',
      endYear: 'Ending P/S',
      currentPeer: "Current Peer's P/S",
      endYearPeer: "Ending Peer's P/S",
    },
    EVSALE: {
      title: 'EV/SALE',
      min: 'EV/SALE Min',
      max: 'EV/SALE Max',
      current: 'Current EV/SALE',
      endYear: 'Ending EV/SALE',
      currentPeer: "Current Peer's EV/SALE",
      endYearPeer: "Ending Peer's EV/SALE",
    },
  },
  //Peer Comparison
  PEERS_COMPARISON: 'Peers Comparison',
  ADD_TICKER: 'Add Ticker',
  PERFORMANCE: 'Performance',
  OWNERSHIP: 'Ownership',
  CREDIT: 'Credit',
  ASSET_QUALITY: 'Asset Quality',
  PROFITABILITY: 'Profitability',
  BALANCE_SHEET: 'Balance Sheet',
  INCOME_STATEMENT: 'Income Statement',
  exchanges: {
    ALL_EXCHANGE: 'All Exchanges',
    HOSE: 'HOSE',
    HNX: 'HNX',
    UPCOM: 'UPCOM',
    OTC: 'OTC',
    PRIVATE: 'Private',
  },
  SELECT_SECTOR: 'Sectors',
  ALL_SECTOR: 'All Sectors',
  SELECTED_COUNT: 'Selected:',
  MAX_ITEMS_TEXT: 'The number of ticker selected is no more than 100.',
  USE_WL: 'Use your Watchlists',
  TITLE: 'Title',
  DESCRIPTION: 'Description',
  DELETE_ALL_TICKER: 'Delete All Ticker',
  DELETE_MESSAGE: 'Do you want to delete <b><i>All Tickers</i></b>?',
  QUARTER_1: 'Quarter 1',
  QUARTER_2: 'Quarter 2',
  QUARTER_3: 'Quarter 3',
  QUARTER_4: 'Quarter 4',
  ANNUAL: 'Annual',
  VND: 'VND',
  MILLION_VND: 'Million VND',
  BILLION_VND: 'Billion VND',
  USD: 'USD',
  THOUSAND_USD: 'Thousand USD',
  MILLION_USD: 'Million USD',
  table: {
    mean: 'Mean',
    delete: ' ',
    ticker: 'TICKER',
    companyName: 'COMPANY NAME',
    marketCap: 'MARKET CAP<p>(Billion VND)</p>',
    lastPrice: 'LATEST PRICE<p>(VND)</p>',
    //Performance
    percent1DPriceChange: '% 1D PRICE<br />CHANGE',
    percent1WPriceChange: '% 1W PRICE<br />CHANGE',
    percent1MPriceChange: '% 1M PRICE<br />CHANGE',
    percent3MPriceChange: '% 3M PRICE<br />CHANGE',
    percent6MPriceChange: '% 6M PRICE<br />CHANGE',
    percent9MPriceChange: '% 9M PRICE<br />CHANGE',
    percent1YPriceChange: '% 1Y PRICE<br />CHANGE',
    percentYTDPriceChange: '% YTD PRICE<br />CHANGE',
    //Valuation
    ev: 'EV<p>(Billion VND)</p>',
    evEbitda: 'EV/EBITDA',
    pS: 'P/S',
    eps: 'EPS',
    pE: 'P/E<p>&nbsp;</p>',
    bvps: 'BVPS<p>&nbsp;</p>',
    pB: 'P/B<p>&nbsp;</p>',
    dividendYield: 'Dividend Yield<p>(%)</p>',
    //Ownership
    stateHolding: 'STATE HOLDING<p>(%)</p>',
    foreignHolding: 'FOREIGN HOLDING<p>(%)</p>',
    otherHolding: 'OTHER HOLDING<p>(%)</p>',
    foreignRemainRoom: 'FOREIGN REMAIN ROOM<p>(%)</p>',
    maximumForeignHolding: 'MAXIMUM FOREIGN HOLDING<p>(%)</p>',
    managementAffiliateHolding: 'MANAGEMENT & AFFILIATE HOLDING<p>(%)</p>',
    institutionHolding: 'INSTITUTION HOLDING<p>(%)</p>',
    //Credit
    fiinRating: 'FIIN<br />RATING',
    netDebtEquity: 'NET DEBT/<br />EQUITY',
    longDebtEquity: 'LT DEBT/<br />EQUITY',
    debtEquity: 'DEBT/<br />EQUITY',
    shortDebtEquity: 'ST DEBTS/<br />EQUITY',
    liabilitiesEquity: 'LIABILITIES/<br />EQUITY',
    ebitdaInterestCorverage: 'EBITDA<br />INTEREST COVERAGE',
    financialLeverage: 'FINANCIAL<br />LEVERAGE',
    equityLoans: 'EQUITY/<br />LOANS',
    loanDepositRatio: 'LOAN/<br />DEPOSITS RATIO <i>(LDR)</i>',
    nonperformingLoanRatio: 'NONPERFORMING<br />LOAN RATIO<i>(NPL)</i>',
    loanLossReservesNPLs: 'LOAN LOSS RESERVES/<br />NPLs',
    loanLossReservesLoans: 'LOAN LOSS RESERVES/<br />LOANS',
    provisionOutstandingLoans: 'PROVISION/<br />OUTSTANDING LOANS',
    sPRating: 'S&P<br />RATING',
    moodyRating: "MOODY'S<br />RATING",
    fitchRanking: 'FITCH<br />RANKING',
    //Profit
    revenueGrowth: 'REVENUE (TTM) GROWTH YoY',
    grossMargin: 'GROSS PROFIT MARGIN',
    netMargin: 'NET PROFIT MARGIN',
    ebitdaMargin: 'EBITDA MARGIN',
    nim: 'NIM',
    pretaxProfitMargin: 'PRETAX PROFIT MARGIN',
    roa: 'ROA',
    roe: 'ROE',
    roic: 'ROIC',
  },
  ALL: 'All',
  MY_WATCHLIST: 'My watchlist',
  DATA_CALCULATE_NOTE: 'Data is calculated on',
}

export const consensus = {
  NAME: 'Consensus',
  CONSENSUS_HISTORY: 'CONSENSUS HISTORY',
  CONSENSUS_TARGET_PRICE: 'CONSENSUS TARGET PRICE',
  RECOMMENDATION: 'RECOMMENDATION',
  BUY_PERCENTAGE: 'Buy',
  SELL_PERCENTAGE: 'Sell',
  HOLD_PERCENTAGE: 'Hold',
  NEUTRAL_PERCENTAGE: 'Neutral',
  NO_RECOMMENDATION_PERCENTAGE: 'No Recommendation',
  ACTUAL_PRICE: 'Actual Price',
  TARGET_PRICE: 'Target Price',
  TICKER: 'TICKER',
  DATE: 'RECOMMENDATION DATE',
  REPORT: 'REPORT',
  RECOMMENDATION_TYPE_NAME: 'RECOMMENDATION',
  PRICE_TARGET: 'PRICE TARGET',
  DFCP: '% PRICE CHANGE FROM\nREPORT DATE PRICE',
  DOWNLOAD_REPORT: 'DOWNLOAD',
  CHART_THOUSAND_VND: 'Thousand VND',
  LATEST_RECOMMEND: 'Latest Recommendation:',
  BUY: 'Buy*',
  SELL: 'Sell*',
  HOLD: 'Hold*',
  NEUTRAL: 'Neutral*',
  NO_RECOMMENDATION: 'No Recommendation*',
  HIGHEST: 'Highest',
  AVERAGE: 'Average',
  LOWEST: 'Lowest',
  UPSIDE: 'Upside',
  DOWNSIDE: 'Downside',
  CHART_UNIT_THOUSAND: 'Price (Thousand VND)',
  DISCLAIMER:
    '*Disclaimer: This recommendation has been automatically calculated by FiinProX based on publicly available reports, internally developed data and other sources believed to be reliable, but has not been independently verified by FiinGroup. FiinGroup makes no representations or warranties with respect to the accuracy, correctness or completeness of the information contained herein. This recommendation is provided only for information purposes of FiinProX’s users, and does not constitute any offers or solicitation to buy or sell any securities discussed herein. Past performance, if any, is not guide to the future. Investors must make their investment decisions based upon independent opinions subject to their particular financial situations and investment objectives.',
}
