import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { formatApiData } from '../helpers'
import { getInvestmentStrategyThunk } from './thunk'

const initialState = {
  loading: false,
  ids: [],
  data: [],
  dataById: {},
}

export const slice = createSlice({
  name: 'fund/fundProfile/profile/investmentStrategy',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Get table data
    builder.addCase(getInvestmentStrategyThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getInvestmentStrategyThunk.fulfilled, (state, action) => {
      const data = formatApiData(action.payload.data)
      state.data = data
      state.ids = data.map((e) => e.id)
      state.dataById = keyBy(data, 'id')
      state.loading = false
    })
    builder.addCase(getInvestmentStrategyThunk.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectDataById = (state) => state[slice.name].dataById
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)

register(slice.name, slice.reducer)
