import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar, Line, Scatter } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import {
  getColumnSizeInBarChart,
  getRadiusOfScatter,
} from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import { STACK_COLORS } from './constants'
import { getTooltipContentData } from './helper'
import style from './index.module.css'

const SETTINGS = {
  yTickNum: 5,
}
const MAX_STACK_KEY = 6
const DEFAULT_COLOR = '#45b29d'
const HEADER_CHART = 20
const FOOTER_CHART = 34

const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  lineKey,
  keyNotDuplicate,
  isValue,
  timeRange,
}) => {
  const [chartHeight, setChartHeight] = useState(0)

  const stackKeys = keyNotDuplicate.map(
    (item) => item.sbvTotalLiquidityVNTypeId,
  )
  const proportionKeys = stackKeys.map((item) => `${item}-proportionValue`)
  const keys = isValue ? stackKeys : proportionKeys

  const keyYAxis = isValue
    ? [
        {
          id: 'left',
          keys: [...stackKeys, lineKey],
          orientation: 'left',
          isBarChart: true,
          label: I18n.t('economy.monetary.monetary.TRILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
        },
      ]
    : [
        {
          id: 'right',
          keys: proportionKeys,
          orientation: 'right',
          isStackedBar: true,
          labelPosition: AXIS_LABEL_POSITION.RIGHT,
          tickNum: SETTINGS.yTickNum,
          tickValues: [0, 25, 50, 75, 100],
          unitYAxis: '%',
        },
        {
          id: 'left',
          keys: [lineKey],
          orientation: 'left',
          isLineChart: true,
          label: I18n.t('economy.monetary.monetary.TRILLION_VND'),
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          tickNum: SETTINGS.yTickNum,
        },
      ]

  const renderUnit = (value, key) => {
    if (typeof value !== 'number') {
      return ''
    }

    if (!isValue && !key) {
      return '%'
    }

    return <Translate value={'economy.monetary.monetary.TRILLION_TOOLTIP'} />
  }

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(payload, keys, keyNotDuplicate, isValue).map(
          (item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <Span
                  style={{ fontSize: 11 }}
                  className={style.tooltipContentKey}
                >
                  {item.label}:
                </Span>
                <Span
                  style={{ marginLeft: 25, fontSize: 11 }}
                  className={style.toolTipContentValue}
                >
                  {formatVal(item.value)}
                  {renderUnit(item.value, item.key)}
                </Span>
              </div>
            )
          },
        )}
      </>
    )
  }

  useEffect(() => {
    setChartHeight(height - HEADER_CHART - FOOTER_CHART)
  }, [height])

  return (
    <div style={{ flex: 1 }}>
      <ChartContainer
        data={data}
        width={width}
        height={chartHeight}
        keyXAxis={keyXAxis}
        yAxis={keyYAxis}
        hasBarChart
        renderCustomTooltip={renderTooltip}
        margin={{ ...MARGIN_RECHARTS, right: 30 }}
        timeFrame={
          timeRange === 'ThreeYears' || timeRange === 'FiveYears'
            ? TIME_RANGES.CUSTOM
            : ''
        }
        isNotFormatXAxis
      >
        {keys.length > 0 &&
          keys.map((key, index) => {
            return (
              <React.Fragment key={index}>
                <Bar
                  key={index}
                  barSize={getColumnSizeInBarChart(width, keys.length)}
                  stackId="a"
                  dataKey={`${key}`}
                  fill={
                    keys.length <= MAX_STACK_KEY
                      ? STACK_COLORS[keys.length][index]
                      : DEFAULT_COLOR
                  }
                  yAxisId={isValue ? 'left' : 'right'}
                  isAnimationActive={false}
                />
                <Line
                  isAnimationActive={false}
                  yAxisId={'left'}
                  dataKey={lineKey}
                  stroke={'#f7959c'}
                  strokeWidth={1.5}
                  dot={false}
                  activeDot={false}
                />
                <Scatter
                  yAxisId={'left'}
                  dataKey={lineKey}
                  fill="#f7959c"
                  radius={getRadiusOfScatter(width, data.length)}
                  isAnimationActive={false}
                />
              </React.Fragment>
            )
          })}
      </ChartContainer>
    </div>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  keyXAxis: PropTypes.string.isRequired,
  lineKey: PropTypes.string.isRequired,
  keyNotDuplicate: PropTypes.array,
  isValue: PropTypes.bool,
  timeRange: PropTypes.string,
}

export default ChartComponent
