export const directorAttr = {
  name: 'name',
  title: 'title',
  quantity: 'quantity',
  percentage: 'percentage',
  publicDateId: 'publicDateId',
  shareAcquire: 'shareAcquire',
  estimateValue: 'estimateValue',
  startDateId: 'startDateId',
}

export const tableHeaderColAttr = [
  [
    {
      name: 'NAME',
      value: [],
      keySort: 'name',
      hideIconSort: true,
    },
    {
      name: 'TITLE',
      value: [],
      keySort: 'title',
      hideIconSort: true,
    },
    {
      name: 'SHARES',
      value: [],
      keySort: 'quantity',
      hideIconSort: true,
    },
    {
      name: 'PERCENTAGE',
      value: [],
      keySort: 'percentage',
      hideIconSort: true,
    },
    {
      name: 'LAST_UPDATE',
      value: [],
      keySort: 'publicDateId',
      hideIconSort: true,
    },
    {
      name: 'LAST_TRANSACTION',
      value: ['corporate.ownership.director.LATEST_TRANSACTION'],
      attrs: {
        colSpan: 3,
        style: { textAlign: 'center' },
      },
      disableSort: true,
      justifyContent: 'justify-content-center',
    },
  ],
  [
    {
      name: 'NAME',
      value: ['corporate.ownership.director.TICKER'],
      attrs: {
        rowSpan: 2,
        style: { textAlign: 'left' },
      },
      keySort: 'name',
      justifyContent: 'justify-content-start',
    },
    {
      name: 'TITLE',
      value: ['corporate.ownership.director.TITLE'],
      attrs: {
        rowSpan: 2,
        style: { textAlign: 'left' },
      },
      keySort: 'title',
      justifyContent: 'justify-content-start',
    },
    {
      name: 'SHARES',
      value: ['corporate.ownership.director.SHARES'],
      attrs: {
        rowSpan: 2,
      },
      keySort: 'quantity',
    },
    {
      name: 'PERCENTAGE',
      value: ['corporate.ownership.director.PERCENTAGE'],
      attrs: {
        rowSpan: 2,
      },
      keySort: 'percentage',
    },
    {
      name: 'LAST_UPDATE',
      value: ['corporate.ownership.director.LAST_UPDATE'],
      attrs: {
        rowSpan: 2,
      },
      keySort: 'publicDateId',
    },
    {
      name: 'SHARE_VOLUME',
      value: ['corporate.ownership.director.SHARE_VOLUME'],
      keySort: 'shareAcquire',
    },
    {
      name: 'PRICE_RANGE',
      value: ['corporate.ownership.director.PRICE_RANGE'],
      additionText: 'corporate.ownership.director.BILLION_VND',
      info: 'corporate.ownership.director.EST_VALUE_TOOLTIP',
      keySort: 'estimateValue',
    },
    {
      name: 'TRANSACTION_DATE',
      value: ['corporate.ownership.director.TRANSACTION_DATES'],
      keySort: 'startDateId',
    },
  ],
]
