export const priceDataTableCol = {
  DATE: 'tradingDateId',
  PERCENT_CHANGE: 'percentPriceChange',
  OPEN: 'openPriceAdjusted',
  HIGH: 'highPriceAdjusted',
  LOW: 'lowPriceAdjusted',
  CLOSE: 'closePriceAdjusted',
  MATCHED_VOLUME: 'totalMatchVolume',
  MATCHED_VALUE: 'totalMatchValue',
  PUT_THROUGH_VOLUME: 'totalDealVolume',
  PUT_THROUGH_VALUE: 'totalDealValue',
  TOTAL_VOL: 'totalVolume',
  TOTAL_VALUE: 'totalValue',
  MARKET_CAP: 'marketCap',
}

export const tableFormatValue = {
  percentPriceChange: 0.01,
  openIndex: 1,
  highIndex: 1,
  lowIndex: 1,
  closeIndex: 1,
  openPriceAdjusted: 10 ** 3,
  highPriceAdjusted: 10 ** 3,
  lowPriceAdjusted: 10 ** 3,
  closePriceAdjusted: 10 ** 3,
  totalMatchVolume: 10 ** 3,
  totalMatchValue: 10 ** 6,
  totalDealVolume: 10 ** 3,
  totalDealValue: 10 ** 6,
  totalVolume: 10 ** 3,
  totalValue: 10 ** 6,
  marketCap: 10 ** 6,
}
