import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import Radio from '../../../common/html/Radio'
import { Span } from '../../../common/html/Span'
import { equationFormatUnitConstants } from './constants'
import {
  changeEquationFormatUnit,
  selectEquationFormatUnit,
} from './store/slice'
import style from './style.module.css'

export const RadioUnit = () => {
  const dispatch = useDispatch()

  const formatUnit = useSelector(selectEquationFormatUnit)

  const handleChange = (value) => {
    dispatch(changeEquationFormatUnit(value))
  }

  return (
    <div className="align-center" style={{ marginTop: 12 }}>
      <Span
        className={style.textBold}
        style={{ whiteSpace: 'nowrap', marginRight: 8, width: 150 }}
      >
        <Translate value="financialChart.EQUATION_UNIT" />
      </Span>
      <Radio
        data={Object.keys(equationFormatUnitConstants).map((key) => ({
          title: 'financialChart.' + key,
          value: equationFormatUnitConstants[key],
        }))}
        value={formatUnit}
        onChange={handleChange}
        containerClass="d-flex"
        itemClass={style.equationRadio}
      />
    </div>
  )
}
