import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { ChartContainer } from '../../common/ChartContainer'
import { GroupBarLineChart } from '../../common/GroupBarLineChart'
import { chartFillColor, earningAgainstMappingDisplay } from './constants'
import {
  selectEarningAgainstChartData,
  selectEarningAgainstChartLoading,
} from './store/slice'
import { getEarningsAgainstDividendsChartData } from './store/thunk'

export const EarningAgainstChart = ({ height, width }) => {
  const dispatch = useDispatch()

  // Use selector
  const basicInfo = useSelector(selectBasicInfo)
  const isLoading = useSelector(selectEarningAgainstChartLoading)
  const data = useSelector(selectEarningAgainstChartData)

  // Use effect
  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getEarningsAgainstDividendsChartData({
          OrganizationId: basicInfo.organizationId,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <div id="earningAgainstChart" className="h-100">
      <ChartContainer
        data={data}
        height={height}
        width={width}
        isLoading={isLoading}
      >
        {(resizeSize) => (
          <GroupBarLineChart
            data={data}
            width={resizeSize.width}
            height={resizeSize.height}
            fillColor={chartFillColor}
            xAxisKey="label"
            barKeys={['rtD0011', 'rtD0044']}
            lineKeys={['rtD0046']}
            lineUnit="%"
            mappingDisplayName={earningAgainstMappingDisplay}
            leftLabel="corporate.financialData.financialDividend.EARNING_AGAINST_DIVIDEND_VND"
            rightLabel="corporate.financialData.financialDividend.DIVIDEND_PAYOUT_RATIO_PERCENT"
            isXAxisDiv
            isBreakNewLine
            isYAxisPercentRight={true}
          />
        )}
      </ChartContainer>
    </div>
  )
}

EarningAgainstChart.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}
