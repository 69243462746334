import PropTypes from 'prop-types'
import { getPriceColor } from '../../utils/Color'
import { Icon } from '../html/Icon'

export const PriceIcon = ({ item, iconStyle }) => {
  const getIcon = (matchPrice, referencePrice) => {
    if (matchPrice === referencePrice || !matchPrice) {
      return <Icon style={{ ...iconStyle, fontSize: 8 }} className="icon-bi" />
    } else if (matchPrice > referencePrice) {
      return (
        <Icon style={{ ...iconStyle, fontSize: 8 }} className="icon-caret-up" />
      )
    }

    return <Icon style={{ ...iconStyle, fontSize: 8 }} className="icon-caret" />
  }

  const getColor = (item) => {
    if (!item.matchPrice) {
      return '#cecece'
    }
    if (item.isTicker) {
      return getPriceColor(
        item.matchPrice,
        item.referencePrice,
        item.ceilingPrice,
        item.floorPrice,
      )
    }

    return getPriceColor(item.matchPrice, item.referencePrice)
  }

  return (
    <span style={{ color: getColor(item), margin: 0, display: 'inline-flex' }}>
      {getIcon(item.matchPrice, item.referencePrice)}
    </span>
  )
}

PriceIcon.propTypes = {
  item: PropTypes.shape({
    isTicker: PropTypes.bool,
    matchPrice: PropTypes.any.isRequired,
    referencePrice: PropTypes.any.isRequired,
    ceilingPrice: PropTypes.any,
    floorPrice: PropTypes.any,
  }).isRequired,
  iconStyle: PropTypes.object,
}

PropTypes.defaultProps = {
  iconStyle: {},
}
