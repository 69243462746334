import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectStockBasicInfo } from './store/slice'
import { getBasicInfo } from './store/thunk'

const useStockBasicInfo = (organizationId) => {
  const dispatch = useDispatch()
  const stockBasicInfo = useSelector(selectStockBasicInfo(organizationId))

  useEffect(() => {
    if (stockBasicInfo || !organizationId) return
    dispatch(getBasicInfo({ OrganizationId: organizationId }))
  }, [organizationId, stockBasicInfo])

  return stockBasicInfo
}

export default useStockBasicInfo
