import StatisticProxy from '../../../../proxies/bond/corporateBond/secondaryMarket/StatisticProxy'
import { ServiceBase } from '../../../ServiceBase'

export class StatisticService extends ServiceBase {
  getTradingStatistics(params) {
    return this.getData(() => StatisticProxy.getTradingStatistics(params))
  }
  getLiquidityData(params) {
    return this.getData(() => StatisticProxy.getLiquidityData(params))
  }
  getValueChartData(params) {
    return this.getData(() => StatisticProxy.getValueChartData(params))
  }
  downloadStatisticInformation(params) {
    return this.getFileDownload(() =>
      StatisticProxy.downloadStatisticInformation(params),
    )
  }
}

export default new StatisticService()
