import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Bar, ReferenceLine, Scatter } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { Footer } from '../../../../../common/chart/footer'
import { TYPE } from '../../../../../common/chart/footer/Item'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { ScatterDiamondShape } from '../../../../../common/chart/scatterDiamond/ScatterDiamondShape'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { valDivBillion } from '../../../../../utils/Value'
import { BAR_COLOR_REMAINING_MATURITIES } from '../constants'
import { renderNameChart } from '../helper'
import {
  keys,
  selectDataCouponInterestRate,
  selectLoading,
} from '../store/slice'
import CouponInterestRateChartTooltip from './CouponInterestRateChartTooltip'

const CouponInterestRateChart = ({ sizeHeight, width, height }) => {
  const isLoading = useSelector(selectLoading(keys.COUPON_INTEREST_RATE))
  const { data, list } = useSelector(selectDataCouponInterestRate)

  const formatDataBarChart = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        valueOfOutstandingBond: valDivBillion(item.valueOfOutstandingBond),
        couponType: I18n.t(renderNameChart(item.couponType)),
      })),
    [data],
  )

  const renderTooltip = (dataChart) => (
    <CouponInterestRateChartTooltip dataChart={dataChart} />
  )

  const firstItemWithPositiveValue = data.find(
    (item) => item.valueOfOutstandingBond > 0,
  )

  return (
    <>
      {sizeHeight && (
        <div
          id="remainingMaturitiesOrCouponInterestRateChartId"
          style={{ height: height - sizeHeight }}
        >
          {isLoading ? (
            <Loading />
          ) : !!formatDataBarChart.length ? (
            <>
              <div
                className="d-flex ali-center justify-content-center"
                style={{ paddingBottom: 15, textAlign: 'center' }}
              >
                <Span
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    textTransform: 'uppercase',
                  }}
                  className="canvas-chart-title"
                >
                  <Translate value="bond.corporateBond.remainingMaturities.COUPON_INTEREST_RATE" />
                </Span>
              </div>
              <div style={{ height: height - sizeHeight - 64 }}>
                <ChartContainer
                  data={formatDataBarChart}
                  width={width}
                  height={height - sizeHeight - 64}
                  keyXAxis="couponType"
                  isUseXAxisDiv
                  showAllTick
                  yAxis={[
                    {
                      id: 'left',
                      keys: list.slice(0, 1),
                      orientation: 'left',
                      label: I18n.t(
                        'bond.corporateBond.remainingMaturities.VALUE',
                      ),
                      labelPosition: AXIS_LABEL_POSITION.LEFT,
                      isBarChart: true,
                      tickNum: 5,
                    },
                    {
                      id: 'right',
                      keys: list.slice(1),
                      orientation: 'right',
                      isLineChart: true,
                      label: I18n.t(
                        'bond.corporateBond.CouponInterestRateChart.COUPON',
                      ),
                      labelPosition: AXIS_LABEL_POSITION.RIGHT,
                      tickNum: 5,
                      min: 0,
                    },
                  ]}
                  margin={{ left: 0, right: 16, top: 20, bottom: 10 }}
                  renderCustomTooltip={renderTooltip}
                >
                  {({ chartContentWidth }) => (
                    <>
                      <Bar
                        isAnimationActive={false}
                        yAxisId="left"
                        dataKey={list[0]}
                        fill={BAR_COLOR_REMAINING_MATURITIES[1]}
                        barSize={30}
                      />
                      <Scatter
                        yAxisId="right"
                        dataKey={list[1]}
                        isAnimationActive={false}
                        shape={
                          <ScatterDiamondShape
                            dataKey={list[1]}
                            color={BAR_COLOR_REMAINING_MATURITIES[2]}
                          />
                        }
                      />
                      {firstItemWithPositiveValue?.averageAllDuration > 0 && (
                        <ReferenceLine
                          yAxisId="right"
                          y={
                            !!data.length
                              ? firstItemWithPositiveValue?.averageAllDuration
                              : 0
                          }
                          stroke={BAR_COLOR_REMAINING_MATURITIES[1]}
                          strokeWidth={1}
                          strokeDasharray="5 5"
                        />
                      )}
                    </>
                  )}
                </ChartContainer>
              </div>
              <Footer
                key={width}
                list={[
                  {
                    text: 'bond.corporateBond.CouponInterestRateChart.VALUE_OF_OUTSTANDING_BONDS_LEGEND',
                    type: TYPE.SQUARE,
                    before: {
                      bgColor: BAR_COLOR_REMAINING_MATURITIES[1],
                    },
                  },
                  {
                    text: 'bond.corporateBond.CouponInterestRateChart.AVG_COUPON_LEGEND',
                    renderIcon: (
                      <ScatterDiamondShape
                        style={{ marginRight: 5 }}
                        color={BAR_COLOR_REMAINING_MATURITIES[2]}
                      />
                    ),
                  },
                  {
                    text: 'bond.corporateBond.CouponInterestRateChart.AVG_COUPON_ALL_LEGEND',
                    type: TYPE.DASHED_LINE,
                    dashedLine: {
                      bgColor: BAR_COLOR_REMAINING_MATURITIES[1],
                    },
                  },
                ]}
              />
            </>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </>
  )
}

CouponInterestRateChart.propTypes = {
  sizeHeight: PropTypes.number,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
}

export default CouponInterestRateChart
