import { createSlice } from '@reduxjs/toolkit'
import { cloneDeep } from 'lodash'
import { getIdsFromProps } from '../../../../common/table/helper'
import { keyBy } from '../../../../utils/Common'
import {
  FORMAT,
  getCurrentDateTime,
  getDateTimeFrom,
} from '../../../../utils/Datetime'
import { register } from '../../../../utils/ReducerRegistry'
import {
  formatVal,
  valByKeyWithDot,
  valToPercent,
} from '../../../../utils/Value'
import { MARKET_STATISTIC, MARKET_STATISTIC_TABS } from '../constants'
import { formatBondList, formatDataTable, formatPrice } from '../helper'
import {
  fetchMoreBondListData,
  fetchMorePriceBoardData,
  getBondCashFlowScenarioData,
  getBondDetailData,
  getBondListData,
  getPriceBoardData,
  getPriceBoardWithBookmarkData,
  getPriceYtmData,
  updateBookmarkData,
} from './thunk'

const defaultFilter = {
  bondId: [],
  watchListIds: [],
  icbIds: [],
  tradingStatusId: [2, 4],
  issueMethodId: [5, 6],
  issueOrganization: null,
  durationFrom: '',
  durationTo: '',
  price: 'All',
  isCleanPrice: false,
  isTrading: true,
  page: 1,
  pageSize: 100,
  interestFrom: '',
  interestTo: '',
}

const defaultModal = {
  tradingDate: getCurrentDateTime(FORMAT.DATE),
  paymentDate: getDateTimeFrom(new Date(), +1, 'day', FORMAT.DATE),
  volume: '',
  totalParValue: '',
  ytm: '',
  dirtyPrice: '',
  cleanPrice: '',
}

const initialState = {
  loading: false,
  loadingTable: false,
  loadingBondList: false,
  loadingIcbIds: true,
  loadingBond: true,
  loadingBondInfo: true,
  activeTab: MARKET_STATISTIC_TABS[0].value,
  filter: defaultFilter,
  isExchangePrice: false,
  data: {
    data: [],
    ids: [],
    dataById: {},
    initialIds: [],
    levels: [],
    markIds: [],
    bondList: [],
    tickers: [],
  },
  enableInfinity: true,
  keySort: {
    bidPrice: 'bidYtm',
    bidPrice2: 'bidYtm2',
    bidPrice3: 'bidYtm3',
    askPrice: 'askYtm',
    askPrice2: 'askYtm2',
    askPrice3: 'askYtm3',
    lowPrice: 'lowYtm',
    highPrice: 'highYtm',
    avgPrice: 'avgYtm',
    matchedPrice: 'matchedYtm',
  },
  item: null,
  detail: null,
  modal: defaultModal,
  bondInfo: [],
  search: {
    data: [],
    initialData: [],
    page: 1,
    pageSize: 10,
    enableInfinity: true,
  },
  bookmarkStatus: false,
}

export const slice = createSlice({
  name: 'bond/corporateBond/secondaryMarket/tradeStatistic',
  initialState,
  reducers: {
    // restore to default state
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeActiveTab(state, action) {
      state.activeTab = action.payload
    },

    sort: (state, action) => {
      const { ids, dataById, initialIds, levels } = state.data

      const idsFromProps = getIdsFromProps(
        ids,
        dataById,
        action.payload,
        initialIds,
        0,
        levels,
      )

      state.data = {
        ...state.data,
        ids: idsFromProps,
      }
    },
    changeFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      }
    },
    changeIsExchangePrice: (state, action) => {
      state.isExchangePrice = action.payload
    },
    resetFilter(state, action) {
      state.filter = cloneDeep({
        ...defaultFilter,
        isCleanPrice: state.filter.isCleanPrice,
        icbIds: action.payload,
      })
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    changeFilterIcbIds: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.key]: action.payload.value,
      }
      state.loadingIcbIds = false
    },
    changeLoadingIcbIds: (state, action) => {
      state.loadingIcbIds = action.payload
    },
    changeKeySort: (state, action) => {
      state.keySort = {
        ...state.keySort,
        [action.payload.key]: action.payload.value,
      }
    },
    resetIds: (state) => {
      state.data = {
        ...state.data,
        ids: state.data.initialIds,
      }
    },
    changeItemSelected: (state, action) => {
      state.item = action.payload
    },
    changeBondDetail: (state, action) => {
      state.detail = action.payload
    },
    changeCouponByBondId: (state, action) => {
      const indexOfBondData = state.data.data.findIndex(
        (item) => (item.bondId = action.payload.id),
      )

      if (indexOfBondData !== -1) {
        state.data = {
          ...state.data,
          data: [
            ...state.data.data.slice(0, indexOfBondData),
            {
              ...state.data.data[indexOfBondData],
              couponValue: action.payload.couponValue,
            },
            ...state.data.data.slice(indexOfBondData + 1),
          ],
        }
      }
    },
    changeModalData: (state, action) => {
      state.modal = {
        ...state.modal,
        [action.payload.key]: action.payload.value,
      }
    },
    resetModalData: (state) => {
      state.modal = cloneDeep(defaultModal)
    },
    changeLoadingBondList: (state, action) => {
      state.loadingBondList = action.payload
    },
    resetSearchBondList: (state) => {
      state.search = {
        data: [],
        initialData: [],
        page: 1,
        pageSize: 10,
        enableInfinity: true,
      }
    },
    subscribeBondBidAskId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1 && state.activeTab === MARKET_STATISTIC.ORDER_MATCHING) {
        const newData = [
          ...state.data.data.slice(0, index),
          {
            ...state.data.data[index],
            bidPrice: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[3])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            bidYtm: parseFloat(realtimeData?.[4] ?? 0),
            bidVolume: parseFloat(realtimeData?.[5] ?? 0),
            bidPrice2: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[6])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            bidYtm2: parseFloat(realtimeData?.[7] ?? 0),
            bidVolume2: parseFloat(realtimeData?.[8] ?? 0),
            bidPrice3: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[9])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            bidYtm3: parseFloat(realtimeData?.[10] ?? 0),
            bidVolume3: parseFloat(realtimeData?.[11] ?? 0),
            askPrice: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[12])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            askYtm: parseFloat(realtimeData?.[13] ?? 0),
            askVolume: parseFloat(realtimeData?.[14] ?? 0),
            askPrice2: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[15])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            askYtm2: parseFloat(realtimeData?.[16] ?? 0),
            askVolume2: parseFloat(realtimeData?.[17] ?? 0),
            askPrice3: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[18])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            askYtm3: parseFloat(realtimeData?.[19] ?? 0),
            askVolume3: parseFloat(realtimeData?.[20] ?? 0),
          },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: ids,
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
          tickers: newData.map((item) => item.ticker),
        }
      }
    },
    subscribeBondBidAskDealId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1 && state.activeTab === MARKET_STATISTIC.PUT_THROUGH) {
        const newData = [
          ...state.data.data.slice(0, index),
          {
            ...state.data.data[index],
            bidPrice: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[3])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            bidYtm: parseFloat(realtimeData?.[4] ?? 0),
            bidVolume: parseFloat(realtimeData?.[5] ?? 0),
            askPrice: formatPrice(
              state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                ? parseFloat(realtimeData?.[12])
                : undefined,
              state.data.data[index]?.rbD0002,
              state.data.data[index]?.parValue,
              state.filter.isCleanPrice,
              state.isExchangePrice,
            ),
            askYtm: parseFloat(realtimeData?.[13] ?? 0),
            askVolume: parseFloat(realtimeData?.[14] ?? 0),
            totalHighVolumeBid: parseFloat(realtimeData?.[21] ?? 0),
            totalHighVolumeAsk: parseFloat(realtimeData?.[22] ?? 0),
          },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    subscribeBondId: (state, action) => {
      const realtimeData = action.payload?.[0]?.split('|')

      const index = state.data.data.findIndex(
        (item) => item.ticker === realtimeData?.[1],
      )

      if (index !== -1) {
        const newData = [
          ...state.data.data.slice(0, index),
          state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
            ? {
                ...state.data.data[index],
                referencePrice: parseFloat(realtimeData?.[3] ?? 0),
                ytm:
                  parseFloat(realtimeData?.[8] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                matchedPrice: formatPrice(
                  state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                    ? parseFloat(realtimeData?.[7])
                    : undefined,
                  state.data.data[index]?.rbD0002,
                  state.data.data[index]?.parValue,
                  state.filter.isCleanPrice,
                  state.isExchangePrice,
                ),
                matchedYtm: parseFloat(realtimeData?.[8] ?? 0),
                matchedVolume: parseFloat(realtimeData?.[19] ?? 0),
                matchedValue: parseFloat(realtimeData?.[20] ?? 0),
                highPrice: formatPrice(
                  state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                    ? parseFloat(realtimeData?.[13])
                    : undefined,
                  state.data.data[index]?.rbD0002,
                  state.data.data[index]?.parValue,
                  state.filter.isCleanPrice,
                  state.isExchangePrice,
                ),
                highYtm: parseFloat(realtimeData?.[14] ?? 0),
                lowPrice: formatPrice(
                  state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                    ? parseFloat(realtimeData?.[15])
                    : undefined,
                  state.data.data[index]?.rbD0002,
                  state.data.data[index]?.parValue,
                  state.filter.isCleanPrice,
                  state.isExchangePrice,
                ),
                lowYtm: parseFloat(realtimeData?.[16] ?? 0),
                totalMatchedValue: parseFloat(realtimeData?.[21] ?? 0),
                avgPrice: formatPrice(
                  state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                    ? parseFloat(realtimeData?.[33])
                    : undefined,
                  state.data.data[index]?.rbD0002,
                  state.data.data[index]?.parValue,
                  state.filter.isCleanPrice,
                  state.isExchangePrice,
                ),
                avgYtm: parseFloat(realtimeData?.[34] ?? 0),
              }
            : {
                ...state.data.data[index],
                referencePrice: parseFloat(realtimeData?.[3] ?? 0),
                ytm:
                  parseFloat(realtimeData?.[24] ?? 0) -
                  parseFloat(realtimeData?.[4] ?? 0),
                matchedPrice: formatPrice(
                  state.activeTab === MARKET_STATISTIC.ORDER_MATCHING
                    ? parseFloat(realtimeData?.[23])
                    : undefined,
                  state.data.data[index]?.rbD0002,
                  state.data.data[index]?.parValue,
                  state.filter.isCleanPrice,
                  state.isExchangePrice,
                ),
                matchedYtm: parseFloat(realtimeData?.[24] ?? 0),
                matchedVolume: parseFloat(realtimeData?.[25] ?? 0),
                matchedValue:
                  parseFloat(realtimeData?.[23] ?? 0) *
                  parseFloat(realtimeData?.[25] ?? 0),
                totalDealVolume: parseFloat(realtimeData?.[26] ?? 0),
                totalDealValue: parseFloat(realtimeData?.[27] ?? 0),
              },
          ...state.data.data.slice(index + 1),
        ]

        const ids = newData.map((item) => item.id)

        state.data = {
          ...state.data,
          data: newData,
          ids: newData.map((item) => item.id),
          initialIds: ids,
          dataById: keyBy(newData, 'id'),
          levels: newData,
        }
      }
    },
    resetBondInfo: (state) => {
      state.bondInfo = []
    },
    changeBookmarkStatus: (state, action) => {
      state.bookmarkStatus = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPriceBoardData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getPriceBoardData.fulfilled, (state, action) => {
      const { data, type, hasNextPage } = action.payload

      const dataFormatted = formatDataTable({
        data,
        type,
        isCleanPrice: state.filter.isCleanPrice,
        isExchangePrice: state.isExchangePrice,
      })
      const ids = dataFormatted.map((item) => item.id)
      const markIds = dataFormatted
        .filter((item) => item?.isBookmark)
        .map((item) => item?.bondId)

      state.data = {
        ...state.data,
        data: dataFormatted,
        ids,
        initialIds: ids,
        dataById: keyBy(dataFormatted, 'id'),
        levels: dataFormatted,
        markIds,
        tickers: dataFormatted.map((item) => item.ticker),
      }
      state.filter = {
        ...state.filter,
        page: 1,
      }
      state.enableInfinity = hasNextPage
      state.loading = false
    })
    builder.addCase(getPriceBoardData.rejected, (state, action) => {
      state.loading = action.payload
    })
    builder.addCase(getPriceBoardWithBookmarkData.pending, (state) => {
      state.loadingTable = true
    })
    builder.addCase(
      getPriceBoardWithBookmarkData.fulfilled,
      (state, action) => {
        const { data, type, hasNextPage } = action.payload

        const dataFormatted = formatDataTable({
          data,
          type,
          isCleanPrice: state.filter.isCleanPrice,
          isExchangePrice: state.isExchangePrice,
        })
        const ids = dataFormatted.map((item) => item.id)
        const markIds = dataFormatted
          .filter((item) => item?.isBookmark)
          .map((item) => item?.bondId)

        state.data = {
          ...state.data,
          data: dataFormatted,
          ids,
          initialIds: ids,
          dataById: keyBy(dataFormatted, 'id'),
          levels: dataFormatted,
          markIds,
          tickers: dataFormatted.map((item) => item.ticker),
        }
        state.filter = {
          ...state.filter,
          page: 1,
        }
        state.enableInfinity = hasNextPage
        state.loadingTable = false
      },
    )
    builder.addCase(getPriceBoardWithBookmarkData.rejected, (state, action) => {
      state.loadingTable = action.payload
    })
    builder.addCase(fetchMorePriceBoardData.pending, (state) => {
      state.loadingTable = true
    })
    builder.addCase(fetchMorePriceBoardData.fulfilled, (state, action) => {
      const { data, type, hasNextPage } = action.payload
      const dataFormatted = formatDataTable({
        data,
        type,
        isCleanPrice: state.filter.isCleanPrice,
        isExchangePrice: state.isExchangePrice,
      })
      const ids = dataFormatted.map((item) => item.id)
      const markIds = dataFormatted
        .filter((item) => item?.isBookmark)
        .map((item) => item?.bondId)

      state.data = {
        ...state.data,
        data: [...state.data.data, ...dataFormatted],
        ids: [...state.data.ids, ...ids],
        initialIds: [...state.data.ids, ...ids],
        dataById: keyBy([...state.data.data, ...dataFormatted], 'id'),
        levels: [...state.data.data, ...dataFormatted],
        tickers: [
          ...state.data.tickers,
          ...dataFormatted.map((item) => item.ticker),
        ],
        markIds: [...state.data.markIds, ...markIds],
      }
      state.filter = {
        ...state.filter,
        page: state.filter.page + 1,
      }
      state.enableInfinity = hasNextPage
      state.loadingTable = false
    })
    builder.addCase(fetchMorePriceBoardData.rejected, (state, action) => {
      state.loadingTable = action.payload
    })
    builder.addCase(getBondListData.pending, (state, action) => {})
    builder.addCase(getBondListData.fulfilled, (state, action) => {
      const { data, hasNextPage, isDefault } = action.payload

      const newData = formatBondList(data)

      state.search = {
        ...state.search,
        data: newData,
        page: 2,
        enableInfinity: hasNextPage,
        initialData: isDefault ? newData : [],
      }
      state.loadingBondList = false
    })
    builder.addCase(getBondListData.rejected, (state, action) => {
      state.loadingBondList = action.payload
    })
    builder.addCase(fetchMoreBondListData.pending, (state, action) => {})
    builder.addCase(fetchMoreBondListData.fulfilled, (state, action) => {
      const { data, hasNextPage } = action.payload

      const tickers = state.search.data
        .map((item) => item?.ticker ?? null)
        .filter((item) => item)

      const newData = formatBondList(
        data.filter((item) => !tickers.includes(item?.ticker)),
      )

      state.search = {
        ...state.search,
        data: [...state.search.data, ...newData],
        page: state.search.page + 1,
        enableInfinity: hasNextPage,
      }
    })
    builder.addCase(fetchMoreBondListData.rejected, (state, action) => {})
    builder.addCase(getBondDetailData.pending, (state) => {
      state.loadingBond = true
    })
    builder.addCase(getBondDetailData.fulfilled, (state, action) => {
      state.detail = action.payload
      state.loadingBond = false
    })
    builder.addCase(getBondDetailData.rejected, (state, action) => {
      state.loadingBond = action.payload
    })
    builder.addCase(getBondCashFlowScenarioData.pending, (state) => {
      state.loadingBondInfo = true
    })
    builder.addCase(getBondCashFlowScenarioData.fulfilled, (state, action) => {
      state.bondInfo = action.payload
      state.loadingBondInfo = false
    })
    builder.addCase(getBondCashFlowScenarioData.rejected, (state, action) => {
      state.loadingBondInfo = action.payload
    })
    builder.addCase(getPriceYtmData.pending, (state) => {})
    builder.addCase(getPriceYtmData.fulfilled, (state, action) => {
      const { data } = action.payload

      state.modal = {
        ...state.modal,
        ytm: valToPercent(data.ytm),
        dirtyPrice: data?.price ? formatVal(data.price, 0, false, '', 0) : '',
        cleanPrice: data?.price
          ? formatVal(
              data.price - (state.bondInfo?.[0]?.accInterest ?? 0),
              0,
              false,
              '',
              0,
            )
          : '',
      }
    })
    builder.addCase(getPriceYtmData.rejected, (state, action) => {})
    builder.addCase(updateBookmarkData.pending, (state) => {})
    builder.addCase(updateBookmarkData.fulfilled, (state, action) => {
      state.bookmarkStatus = action.payload
    })
    builder.addCase(updateBookmarkData.rejected, (state, action) => {
      state.loadingBondInfo = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectLoadingTable = (state) => state[slice.name].loadingTable
export const selectLoadingIcbIds = (state) => state[slice.name].loadingIcbIds
export const selectLoadingBondList = (state) =>
  state[slice.name].loadingBondList
export const selectActiveTab = (state) => state[slice.name].activeTab
export const selectFilterPriceBoard = (state) => state[slice.name].filter
export const selectIsExchangePrice = (state) =>
  state[slice.name].isExchangePrice
export const selectDataTradingStatistics = (state) => state[slice.name].data
export const selectDataTableById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].data.dataById[id], attr)
export const selectEnableInfinity = (state) => state[slice.name].enableInfinity
export const selectKeySort = (state) => state[slice.name].keySort
export const selectItemSelected = (state) => state[slice.name].item
export const selectBondDetailSelected = (state) => state[slice.name].detail
export const selectModalData = (state) => state[slice.name].modal
export const selectBondInfo = (state) => state[slice.name].bondInfo
export const selectSearchFilter = (state) => state[slice.name].search
export const selectLoadingBondInfo = (state) =>
  state[slice.name].loadingBondInfo
export const selectBookmarkStatus = (state) => state[slice.name].bookmarkStatus

export const {
  resetStore,
  sort,
  changeActiveTab,
  changeFilter,
  changeIsExchangePrice,
  resetFilter,
  setLoading,
  changeFilterIcbIds,
  changeKeySort,
  resetIds,
  changeItemSelected,
  changeBondDetail,
  changeCouponByBondId,
  changeModalData,
  resetModalData,
  changeLoadingBondList,
  resetSearchBondList,
  subscribeBondBidAskId,
  subscribeBondId,
  changeLoadingIcbIds,
  subscribeBondBidAskDealId,
  resetBondInfo,
  changeBookmarkStatus,
} = slice.actions

register(slice.name, slice.reducer)
