export const FIRST_COL_ID = 'firstColId'
export const TOTAL_ID = 'total'
export const CHART_ID = 'chart'

export const NOT_SECTOR_INDEX = [4, 6, 8, 9, 10, 11, 14, 15, 12, 46, 47, 50]

export const ICB_LEVEL = 2

export const SECTOR_COLOR = {
  2: '#763851',
  10: '#271396',
  14: '#45b29d',
  29: '#facc5c',
  33: '#1c8398',
  59: '#7a5a9c',
  64: '#69d1c5',
  72: '#717171',
  89: '#fdf0a5',
  98: '#f9f51a',
  108: '#f57404',
  113: '#c70d0d',
  122: '#7f2a2a',
  128: '#0096eb',
  136: '#185999',
  140: '#e9a3a6',
  148: '#df5a49',
  154: '#632d9d',
  164: '#296a33',
}

export const VALUES_TYPE = {
  BUY_VAL: 'buyValue',
  SELL_VAL: 'sellValue',
  NET_VAL: 'netValue',
}

export const CHART_TYPE = {
  BUY: 'buyValue',
  SELL: 'sellValue',
}
