import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingCreateChart from '../chart/settingChart'
import { selectChartTabActive } from '../chart/store/slice'
import { screenTypes } from '../constants'
import { changeDashboard } from '../store/slice'
import { LayoutDashboardTab } from './LayoutDashboardTab'
import { KEY_DASHBOARD_TABS } from './constants'
import withWrapper from './withWrapper'

const EditDashboard = () => {
  const dispatch = useDispatch()

  const chartTabActive = useSelector(selectChartTabActive)

  useEffect(() => {
    if (chartTabActive !== KEY_DASHBOARD_TABS) {
      dispatch(changeDashboard({ screenType: screenTypes.EDIT_CHART }))
    }
  }, [chartTabActive])

  return chartTabActive === KEY_DASHBOARD_TABS ? (
    <LayoutDashboardTab />
  ) : (
    <SettingCreateChart isEditChart />
  )
}

export default withWrapper(EditDashboard)
