import { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown from '../../../../../common/dropdown'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from './index.module.css'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

export const SearchDropdown = ({
  listItems,
  selectedItem,
  changeCurrentSelectedItem,
  currentIds,
}) => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const defaultItemName = listItems[0] && I18n.t(listItems[0]?.name)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [eventPressKey, setEventPressKey] = useState()

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const getPlaceHolder = () => {
    return !selectedItem.includes(0)
      ? listItems
          .filter((item) => selectedItem.includes(item.value))
          .map((item) => item.ticker ?? I18n.t(item.name))
          .toString()
      : defaultItemName
  }

  return (
    <div
      ref={exceptOutsideRef}
      className={`form-search-inbox ${style.inputSearch}`}
    >
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        type="text"
        className="search-inbox bg-grey"
        placeholder={getPlaceHolder()}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        ref={inputRef}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <DropdownItems
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listItems={listItems?.filter(
          (item) =>
            String(item.id)?.includes(keySearch) ||
            I18n.t(item.name)?.toLowerCase().includes(keySearch.toLowerCase()),
        )}
        setKeySearch={setKeySearch}
        eventPressKey={eventPressKey}
        changeCurrentSelectedItem={changeCurrentSelectedItem}
        selectedItem={selectedItem}
        currentIds={currentIds}
      />
    </div>
  )
}

const DropdownItems = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listItems,
  exceptOutsideRef,
  setKeySearch,
  changeCurrentSelectedItem,
  selectedItem,
  currentIds,
}) => {
  const dispatch = useDispatch()

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthItems = listItems.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listItems[activeIndex])
      else if (listItems[0]) handleClickItem(listItems[0])
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthItems - 1) setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthItems - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (item) => {
    const orgId = +item.value
    const condition = selectedItem.some((item) => item === orgId)
    if (!condition) {
      dispatch(changeCurrentSelectedItem([...selectedItem, orgId]))
    } else {
      dispatch(
        changeCurrentSelectedItem(
          selectedItem?.filter((item) => item !== orgId && item !== 0),
        ),
      )
    }
  }

  const handleCheckAll = (item) => {
    const orgId = +item.value
    const condition = selectedItem.some((item) => item === orgId)
    if (!condition) {
      const selectedIdsAdded = currentIds.filter(
        (id) => !selectedItem.includes(id),
      )
      dispatch(
        changeCurrentSelectedItem([...selectedItem, ...selectedIdsAdded]),
      )
    } else {
      dispatch(
        changeCurrentSelectedItem(
          selectedItem.filter((id) => !currentIds.includes(id)),
        ),
      )
    }
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listItems.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listItems.map((item, index) => {
                return (
                  <div
                    key={item.value}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() =>
                      item.value === 0
                        ? handleCheckAll(item)
                        : handleClickItem(item)
                    }
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <label className={style.labelCheckbox}>
                        <input
                          type="checkbox"
                          className={`checkbox line cursor-pointer ${style.checkbox}`}
                          checked={selectedItem.includes(item.value)}
                          value={item.value}
                          readOnly
                        />
                      </label>
                      <div className={style.textEllipsis}>
                        <TextEllipsis text={item.name} zIndexTooltip={999} />
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()
  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
