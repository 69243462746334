import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../common/textEllipsis'
import style from './index.module.css'
import { selectDataTable, selectListCategory } from './store/slice'

export const TdSelect = ({ val, rowId }) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const listCategory = useSelector(selectListCategory)
  const dataTable = useSelector(selectDataTable)

  const categoryDataTableItem = dataTable?.find((item) => item.id === rowId)
  const handleChecked = () => {
    return listCategory?.some((category) => category.id === rowId)
  }
  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <div>
        {!categoryDataTableItem?.isSubCategory ? (
          <input
            type="checkbox"
            className={`checkbox ${style.checkbox}`}
            checked={handleChecked()}
            readOnly
          />
        ) : (
          <div style={{ width: 15 }}></div>
        )}
      </div>
      <div
        className="d-flex"
        style={{
          marginLeft: categoryDataTableItem?.isSubCategory ? MARGIN_LEFT : 0,
        }}
      >
        <div className={style.pointer}>
          <TextEllipsis
            isI18n={true}
            text={val}
            appendStyle={{
              color: categoryDataTableItem?.isSubCategory ? '#75797f' : '',
              fontStyle: categoryDataTableItem?.isSubCategory
                ? 'italic'
                : 'none',
            }}
          />
        </div>
      </div>
    </div>
  )
}
