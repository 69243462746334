import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { EXPORT_TYPE, GROUP_BY } from '../constant'
import {
  selectFilter,
  selectListCheckedIndicatorsById,
  selectListTickersById,
  selectViewBy,
} from '../store/slice'
import { stickyColumnsKey } from './constants'
import {
  getTableHeaderGroupByCompany,
  getTableHeaderGroupByDate,
  getTableHeaderGroupByIndicator,
} from './helps'
import {
  changeCellInfos,
  changeColumnIds,
  changeColumns,
  changeColumnsById,
  changeIndicatorsChecked,
  changeTableHeaderAttr,
  changeTableSettingsViewBy,
  changeTickersChecked,
  selectIndicatorsChecked,
  selectTableHeaderAttr,
  selectTickersChecked,
} from './store/slice'

export const TableSettings = ({ isGroupByDate, isMultiFile }) => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const filter = useSelector(selectFilter)
  const viewBy = useSelector(selectViewBy)
  const tickersById = useSelector(selectListTickersById(viewBy))
  const checkedIndicatorsById = useSelector(
    selectListCheckedIndicatorsById(viewBy),
  )
  const tickersChecked = useSelector(selectTickersChecked)
  const indicatorsChecked = useSelector(selectIndicatorsChecked)
  const tableHeaderAttr = useSelector(selectTableHeaderAttr)

  // Use effect
  useEffect(() => {
    dispatch(changeTableSettingsViewBy(viewBy))
  }, [viewBy])

  useEffect(() => {
    let columns = []
    let cellInfos = []

    if (isGroupByDate) {
      tableHeaderAttr.forEach((item) => {
        if (Object.values(stickyColumnsKey).includes(item.id)) {
          cellInfos.push(item)
        } else {
          columns.push(item)
        }
      })
    } else {
      tableHeaderAttr
        .flat()
        .filter((col) => !col.isNoData)
        .forEach((item) => {
          if (Object.values(stickyColumnsKey).includes(item.id)) {
            cellInfos.push(item)
          } else {
            columns.push(item)
          }
        })
    }

    const columnIds = []
    const columnsById = columns.reduce((obj, item) => {
      columnIds.push(item.id)
      return { ...obj, [item.id]: item }
    }, {})

    dispatch(changeCellInfos(cellInfos))
    dispatch(changeColumns(columns))
    dispatch(changeColumnIds(columnIds))
    dispatch(changeColumnsById(columnsById))
  }, [tableHeaderAttr, filter.exportType])

  useEffect(() => {
    const indicators = Object.values(checkedIndicatorsById)
    const indicatorsCheck = indicators.filter((ticker) => ticker.isCheck)
    const data = indicatorsCheck.length
      ? indicatorsCheck.splice(0, 10)
      : indicators.splice(0, 10)

    dispatch(changeIndicatorsChecked(data))
  }, [checkedIndicatorsById, isGroupByDate])

  useEffect(() => {
    const tickers = Object.values(tickersById)
    const tickersCheck = tickers.filter((ticker) => ticker.isCheck)
    const data = tickersCheck.length
      ? tickersCheck.splice(0, isMultiFile ? 1 : isGroupByDate ? 10 : 2)
      : tickers.splice(0, isMultiFile ? 1 : isGroupByDate ? 10 : 2)

    dispatch(changeTickersChecked(data))
  }, [tickersById, isGroupByDate, isMultiFile])

  useEffect(() => {
    let data = []
    if (filter.exportType === EXPORT_TYPE.A_FILE) {
      switch (filter.groupBy) {
        case GROUP_BY.DATE:
          data = getTableHeaderGroupByDate(
            viewBy,
            indicatorsChecked,
            locale,
            timeZone,
          )
          break
        case GROUP_BY.COMPANY:
          data = getTableHeaderGroupByCompany(
            indicatorsChecked,
            tickersChecked,
            locale,
            timeZone,
          )
          break
        case GROUP_BY.INDICATOR:
          data = getTableHeaderGroupByIndicator(
            indicatorsChecked,
            tickersChecked,
            locale,
            timeZone,
          )
          break
        default:
          data = []
      }
    } else {
      data = getTableHeaderGroupByDate(
        viewBy,
        indicatorsChecked,
        locale,
        timeZone,
      )
    }

    dispatch(changeTableHeaderAttr(data))
  }, [
    filter.groupBy,
    filter.exportType,
    tickersChecked,
    indicatorsChecked,
    viewBy,
    locale,
  ])

  return <></>
}
