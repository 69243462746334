import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { defaultPage } from '../constant'

const initialState = {
  sort: {},
  currentPage: defaultPage,
  displayColumn: {},
  displayRow: {},
  isScrolling: false,
}

export const slice = createSlice({
  name: 'dataExplorer/table',
  initialState,
  reducers: {
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeSort(state, action) {
      state.sort = action.payload
    },
    changeCurrentPage(state, action) {
      state.currentPage = action.payload
    },
    changeDisplayColumn(state, action) {
      state.displayColumn = action.payload
    },
    changeDisplayRow(state, action) {
      state.displayRow = action.payload
    },
    changeIsScrolling(state, action) {
      state.isScrolling = action.payload
    },
  },
})

export const {
  resetStore,
  changeSort,
  changeCurrentPage,
  changeDisplayColumn,
  changeDisplayRow,
  changeIsScrolling,
} = slice.actions

export const selectSort = (id) => (state) => state[slice.name].sort[id]

export const selectCurrentPage = (state) => state[slice.name].currentPage

export const selectDisplayColumn = (index) => (state) =>
  state[slice.name].displayColumn?.[index]
export const selectDisplayColumns = (state) => state[slice.name].displayColumn

export const selectDisplayRow = (index) => (state) =>
  state[slice.name].displayRow?.[index]
export const selectDisplayRows = (state) => state[slice.name].displayRow

export const selectIsScrolling = (state) => state[slice.name].isScrolling

register(slice.name, slice.reducer)
