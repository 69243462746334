import PropTypes from 'prop-types'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { Footer } from '../../../../common/chart/footer'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { SizeTracker } from '../../../../common/sizeTracker'
import { formatVal } from '../../../../utils/Value'
import { STACK_COLORS } from '../../constants'
import { getTooltipContentData, renderListFooter } from './helper'
import style from './index.module.css'

const SETTINGS = {
  yTickNum: 5,
}

const ChartComponent = ({ data, width, height, keyXAxis, stackKey }) => {
  const stackKeyIds = Object.keys(stackKey)

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        {getTooltipContentData(stackKeyIds, payload, stackKey).map(
          (item, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginTop: 10,
                }}
              >
                <div className={style.wrapContent}>
                  <Span
                    style={{ fontSize: 11 }}
                    className={style.tooltipContentKey}
                  >
                    {item.label}:
                  </Span>
                </div>
                <Span
                  style={{ marginLeft: 25, fontSize: 11 }}
                  className={style.toolTipContentValue}
                >
                  {formatVal(item.value)}%
                </Span>
              </div>
            )
          },
        )}
      </>
    )
  }

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            {size.height && (
              <ChartContainer
                data={data}
                width={width}
                height={height - size.height}
                keyXAxis={keyXAxis}
                isUseXAxisDiv
                showAllTick
                yAxis={[
                  {
                    id: 'left',
                    keys: stackKeyIds,
                    orientation: 'left',
                    isStackedBar: true,
                    labelPosition: AXIS_LABEL_POSITION.LEFT,
                    tickNum: SETTINGS.yTickNum,
                    tickValues: [0, 25, 50, 75, 100],
                    unitYAxis: '%',
                  },
                ]}
                hasBarChart
                renderCustomTooltip={renderTooltip}
              >
                {stackKeyIds.length > 0 &&
                  stackKeyIds.map((item, index) => {
                    return (
                      <Bar
                        key={index}
                        barSize={getColumnSizeInBarChart(width, data.length)}
                        stackId="a"
                        dataKey={item}
                        fill={STACK_COLORS[stackKeyIds.length][index]}
                        yAxisId="left"
                        isAnimationActive={false}
                      />
                    )
                  })}
              </ChartContainer>
            )}
            <Footer
              key={width}
              list={renderListFooter(Object.values(stackKey))}
            />
          </>
        )
      }}
    </SizeTracker>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  keyXAxis: PropTypes.string.isRequired,
  stackKey: PropTypes.object.isRequired,
}

export default ChartComponent
