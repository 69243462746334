import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown from '../../../../common/dropdown'
import { HandleClickOutside } from '../../../../common/HandleClickOutside'
import { Input } from '../../../../common/html/Input'
import SelectCustom from '../../../../common/selectCustom'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import Tree from '../../../../common/tree'
import { Z_INDEX } from '../../../../constants/Common'
import { listViewBy, TABLE_ROW_LEVEL, TABLE_SECTOR_ID } from './constant'
import {
  changeFilterStatus,
  changeLocationChecked,
  changeViewBy,
  resetDataTable,
  selectFilterStatus,
  selectListFDITypeName,
  selectLocationName,
  selectViewBy,
  setIsSpreadTable,
} from './store/slice'
import { getFDITypeName } from './store/thunk'
import style from './style.module.css'

const Filter = forwardRef(({ setIsFirstTime }, ref) => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()

  const locale = useSelector((state) => state.i18n.locale)
  const viewBy = useSelector(selectViewBy)
  const filterStatus = useSelector(selectFilterStatus)
  const listFDITypeName = useSelector(selectListFDITypeName)
  const listLocationName = useSelector(selectLocationName)

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const onChangeViewBy = ({ value }) => {
    dispatch(resetDataTable())
    dispatch(changeViewBy(value))
  }

  const onChangeFilterStatus = (value) => {
    dispatch(resetDataTable())
    dispatch(changeFilterStatus(value))
  }

  const getListTrue = () => {
    const listTrue = []
    let object = {}
    listLocationName.forEach((i) => {
      const level =
        i.locationLevel === 1
          ? TABLE_ROW_LEVEL.LEVEL_1
          : TABLE_ROW_LEVEL.LEVEL_2
      if (level === TABLE_ROW_LEVEL.LEVEL_1) {
        const children = listLocationName
          .filter(
            (v) => v.parentLocationId === i.locationId && v.locationLevel !== 1,
          )
          .map((e) => ({
            ...e,
            groupName: e.locationName,
            children: {},
          }))
        listTrue.push({
          [i.locationName]: {
            ...i,
            groupName: i.locationName,
            children: children,
          },
        })
      }
    })
    listTrue.forEach((i) => (object = { ...object, ...i }))
    return object
  }

  const handleClick = (item) => {
    dispatch(setIsSpreadTable(true))
    setTimeout(() => {
      const scrollTable = document.querySelector(
        `#${TABLE_SECTOR_ID} .scrollbars div:first-child`,
      )
      const headerTable = scrollTable?.querySelector('thead')
      const activeRow = scrollTable?.querySelector(`[id="${item.locationId}"]`)
      if (scrollTable && headerTable && activeRow) {
        setIsFirstTime(false)
        scrollTable.scrollTo({
          top: activeRow.offsetTop - headerTable.offsetHeight,
          behavior: 'smooth',
        })
      }
      dispatch(setIsSpreadTable(false))
    }, 500)
    dispatch(changeLocationChecked(item))
    handleHide()
    setKeySearch(item.locationName)
  }

  useEffect(() => {
    dispatch(getFDITypeName({ IsCapital: viewBy }))
  }, [viewBy, locale])

  return (
    <div className="d-flex justify-content-space-between mb-8" ref={ref}>
      <div className={'d-flex'}>
        <DispatchActionTab
          data={listViewBy.map((item) => {
            return {
              title: item.title,
              value: item.value,
            }
          })}
          activeTab={viewBy}
          onChangeTab={onChangeViewBy}
          containerStyle={{ marginRight: '8px' }}
        />
        <div className="d-flex" style={{ paddingTop: 0 }}>
          {listFDITypeName.length ? (
            <div className="w-200">
              <SelectCustom
                value={filterStatus}
                selectData={listFDITypeName}
                handleChange={onChangeFilterStatus}
              />
            </div>
          ) : null}
          <div className="input-dropdown">
            <div
              className="ml-8 form-search-inbox w-200"
              ref={parentDropdownRef}
            >
              <Input
                style={{
                  fontSize: 11,
                  backgroundColor: '#3c424b',
                }}
                type="text"
                className="search-inbox"
                placeholder={I18n.t('economy.fdi.fdiByCountries.SEARCH')}
                onChange={(e) => setKeySearch(e.target.value?.toLowerCase())}
                onFocus={handleShow}
                value={keySearch}
                onClick={handleShow}
              />
              <button type="button">
                <i className="icon-search-2" style={{ color: '#8b8b8b' }} />
              </button>
            </div>
            <Dropdown parentRef={parentDropdownRef} isShow={isShowDropdown}>
              <HandleClickOutside
                handleClickOutside={handleHide}
                exceptRef={dropdownRef}
              >
                <div className="input-dropdown" style={{ marginTop: 2 }}>
                  <div
                    className={`dropdown-container ${style.dropdownContainerSearch}`}
                    style={{ width: dropdownRef?.current?.clientWidth }}
                  >
                    <div style={{ height: 300, paddingLeft: 8 }}>
                      <Tree
                        valueSearch={keySearch}
                        treeData={getListTrue()}
                        nameKey={['groupName']}
                        defaultOpenLevel1
                        disabledCheckDbClick
                        onClickItem={handleClick}
                        doubleClickItem={undefined}
                        isSearchAbsolute={false}
                        zIndexTextEllipsis={Z_INDEX.DROPDOWN}
                      />
                    </div>
                  </div>
                </div>
              </HandleClickOutside>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Filter
