import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Z_INDEX } from '../../constants/Common'
import useHeightTdTable from '../hooks/useHeightTdTable'
import { Span } from '../html/Span'
import { calcLeftOfSticky } from './helper'
import style from './index.module.css'

export const Cell = ({
  getDataFromRedux,
  rowId,
  colId,
  render,
  tdStyle,
  canCustomTd,
  isActive,
  isCollapse,
  isShowCollapseIcon,
  onRowCollapse,
  widths,
  sticky,
  schema,
  colIndex,
  stickyBottom,
  stickyBgColor,
  rowIndex,
  stickyBottomRLeft,
}) => {
  const buttonRef = useRef()
  const data = useSelector(getDataFromRedux(rowId, colId))
  const heightTd = useHeightTdTable()
  const [isIconCollapse, setIsIconCollapse] = useState(false)

  const onCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse && onRowCollapse(rowId, !isIconCollapse)
    setIsIconCollapse(!isIconCollapse)
  }

  const getTdWidth = () => {
    const buttonCollapseWidth = buttonRef?.current?.offsetWidth || 0
    return isCollapse && isShowCollapseIcon
      ? `calc(100% - ${buttonCollapseWidth}px)`
      : '100%'
  }

  const getStyle = () => {
    let style = {
      height: heightTd,
    }

    if (sticky) {
      style = {
        ...style,
        left: calcLeftOfSticky(widths, schema, colIndex),
        position: 'sticky',
        backgroundColor: stickyBgColor,
        zIndex: Z_INDEX.STICKY_CELL,
      }
    }

    if (stickyBottom || stickyBottom === 0) {
      style = {
        ...style,
        position: 'sticky',
        bottom: stickyBottom,
        left: sticky ? calcLeftOfSticky(widths, schema, colIndex) : 'unset',
        backgroundColor: stickyBgColor,
        zIndex: sticky
          ? Z_INDEX.TH_TABLE_STICKY_FIRST_COLUMN
          : Z_INDEX.STICKY_THEAD,
      }
    }

    return style
  }

  const getClass = () => {
    const className = []
    if (sticky && !(stickyBottom || stickyBottom === 0)) {
      className.push(style.stickyColumnBorder)
    } else if (stickyBottom || stickyBottom === 0) {
      if (stickyBottomRLeft) {
        className.push(style.stickyRowBorderLeft)
      } else {
        className.push(style.stickyRowBorder)
      }
    }

    if (isActive) {
      className.push(style.active)
    }

    return className.join(' ')
  }

  if (canCustomTd) {
    return render(
      data,
      rowId,
      {
        style: { ...getStyle(), ...tdStyle },
        className: getClass(),
      },
      colId,
    )
  }

  const renderContent = () => {
    return (
      <div style={{ ...tdStyle, width: getTdWidth() }}>
        {render ? render(data, rowId, rowIndex) : data}
      </div>
    )
  }

  return (
    <td className={getClass()} colid={colId} style={getStyle()}>
      {isCollapse && isShowCollapseIcon ? (
        <div className="align-center">
          <button
            style={{ color: 'inherit' }}
            className="align-center cursor-pointer"
            onClick={onCollapse}
          >
            <Span style={{ fontSize: 8 }}>
              <i
                className={`${
                  isIconCollapse ? 'icon-caret-down' : 'icon-caret-top'
                }`}
              />
            </Span>
          </button>
          {renderContent()}
        </div>
      ) : (
        renderContent()
      )}
    </td>
  )
}

Cell.propTypes = {
  getDataFromRedux: PropTypes.func.isRequired,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  colId: PropTypes.string.isRequired,
  render: PropTypes.func,
  tdStyle: PropTypes.object,
  canCustomTd: PropTypes.bool,
  isActive: PropTypes.bool,
  isCollapse: PropTypes.bool,
  isShowCollapseIcon: PropTypes.bool,
  onRowCollapse: PropTypes.func,
  stickyBottom: PropTypes.number,
  stickyBgColor: PropTypes.string.isRequired,
  rowIndex: PropTypes.number.isRequired,
}

Cell.defaultProps = {
  tdStyle: {},
  render: null,
  canCustomTd: false,
  isActive: false,
  isCollapse: false,
  isShowCollapseIcon: false,
}
