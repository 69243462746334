import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import FinancialDataService from '../../../../../core/services/corporate/FinancialDataService'
import { handleExport } from '../../../../utils/Export'

export const getFinancialDataRatio = createAsyncThunk(
  'corporate/financialData/financialRatio/GET_FINANCIAL_DATA_RATIO',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getFinancialDataRatio(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const getIndicatorMapping = createAsyncThunk(
  'corporate/financialData/financialRatio/GET_INDICATOR_MAPPING',
  async (data, { rejectWithValue }) => {
    try {
      const response = await FinancialDataService.getIndicatorMapping(data)
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)

export const downloadRatio = createAsyncThunk(
  'corporate/financialData/financialRatio/DOWNLOAD_RATIO',
  async (data) => {
    const response = await FinancialDataService.downloadRatio(data)
    handleExport(response.data, response.filename)
  },
)
