import { createSlice } from '@reduxjs/toolkit'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime'
import { register } from '../../../../../utils/ReducerRegistry'
import { CHART_TYPE } from '../../../common/constants'
import { getDataColumnChart, getDataPieChart } from './thunk'

const initialState = {
  isLoading: true,
  dataPieChart: [],
  dataColumnChart: [],
  month: +getCurrentDateTime(FORMAT.MONTH_NUMBER) - 1,
  year: +getCurrentDateTime(FORMAT.YEAR),
  yearByYearly: +getCurrentDateTime(FORMAT.YEAR),
  typeChart: CHART_TYPE.PIE_CHART,
  retryFlag: true,
}

const slice = createSlice({
  name: 'economy/fdi/fdiByCountries/pieChartData',
  initialState,
  reducers: {
    changeFilterMonth: (state, action) => {
      state.month = action.payload
    },
    changeFilterYear: (state, action) => {
      state.year = action.payload
    },
    changeTypeChart: (state, action) => {
      state.typeChart = action.payload
    },
    changeRetryFlag: (state, action) => {
      state.retryFlag = action.payload
    },
    changeFilterYearByYearly: (state, action) => {
      state.yearByYearly = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataPieChart.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataPieChart.fulfilled, (state, action) => {
      state.dataPieChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getDataPieChart.rejected, (state, action) => {
      state.isLoading = action.payload
    })
    builder.addCase(getDataColumnChart.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getDataColumnChart.fulfilled, (state, action) => {
      state.dataColumnChart = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getDataColumnChart.rejected, (state, action) => {
      state.isLoading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataPieChart = (state) => state[slice.name].dataPieChart
export const selectDataColumnChart = (state) =>
  state[slice.name].dataColumnChart
export const selectFilterMonth = (state) => state[slice.name].month
export const selectFilterYear = (state) => state[slice.name].year
export const selectChartType = (state) => state[slice.name].typeChart
export const selectRetryFlag = (state) => state[slice.name].retryFlag
export const selectYearByYearly = (state) => state[slice.name].yearByYearly

export const {
  changeFilterMonth,
  changeFilterYear,
  changeTypeChart,
  changeRetryFlag,
  changeFilterYearByYearly,
} = slice.actions

register(slice.name, slice.reducer)
