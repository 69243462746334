import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom/index.js'
import { FORMAT, getCurrentDateTime } from '../../../../../utils/Datetime.js'

import { range } from 'd3-array'
import useGetMaxMinTable from '../../../../../common/masterData/useGetMaxMinTable.js'
import DispatchActionTab from '../../../../../common/tabs/DispatchActionTab.js'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice.js'
import { MIN_DATE_FILTER } from '../../../../../economy/fdi/common/constants.js'
import { QUARTER_TABS, TIME_FREQUENCY } from '../constants.js'
import {
  changeFilter,
  keys,
  selectFilterDebtAndLoan,
  selectFilterDebtStatistic,
} from '../store/slice.js'
import { getDebtStatisticData } from '../store/thunk.js'

const Filter = () => {
  const dispatch = useDispatch()
  const { maxMinTableById } = useGetMaxMinTable()
  const commonTopIssuer = maxMinTableById['FGFB_COFS_DR_OrganizationLoan']

  // Use selector
  const { year, quarter } = useSelector(selectFilterDebtStatistic)
  const activeTab = useSelector(selectFilterDebtAndLoan)
  const basicInfo = useSelector(selectBasicInfo)
  const locale = useSelector((state) => state.i18n.locale)

  const YEARS = range(
    MIN_DATE_FILTER,
    getCurrentDateTime(FORMAT.YEAR) * 1 + 1,
  ).reverse()

  const handleChangeYear = (value) => {
    dispatch(
      changeFilter({
        label: keys.DEBT_STATISTIC,
        key: 'year',
        value,
      }),
    )
  }
  const handleChangeActiveTab = ({ value }) => {
    dispatch(
      changeFilter({
        label: keys.DEBT_STATISTIC,
        key: 'quarter',
        value,
      }),
    )
  }

  useEffect(() => {
    if (basicInfo.organizationId) {
      const payload = {
        Quarter: activeTab === TIME_FREQUENCY.QUARTER ? quarter : 5,
        OrganizationId: basicInfo.organizationId,
        Year: year,
      }
      dispatch(getDebtStatisticData(payload))
    }
  }, [locale, basicInfo.organizationId, quarter, year, activeTab])

  useEffect(() => {
    if (activeTab === TIME_FREQUENCY.QUARTER) {
      dispatch(
        changeFilter({
          label: keys.DEBT_STATISTIC,
          key: 'quarter',
          value: commonTopIssuer?.max_LengthReport || 1,
        }),
      )
      dispatch(
        changeFilter({
          label: keys.DEBT_STATISTIC,
          key: 'year',
          value:
            commonTopIssuer?.max_YearOfLengthReport || new Date().getFullYear(),
        }),
      )
    } else {
      dispatch(
        changeFilter({
          label: keys.DEBT_STATISTIC,
          key: 'year',
          value: commonTopIssuer?.max_YearReport || new Date().getFullYear(),
        }),
      )
    }
  }, [activeTab])

  return (
    <div className="d-flex justify-content-end ali-center mb-8">
      {activeTab === TIME_FREQUENCY.QUARTER && (
        <div className="mr-8">
          <DispatchActionTab
            data={QUARTER_TABS}
            activeTab={quarter}
            onChangeTab={handleChangeActiveTab}
            containerStyle={{ width: '250px' }}
            itemStyle={{
              width: '50%',
              fontWeight: 600,
            }}
          />
        </div>
      )}
      <div className="w-80">
        <SelectCustom
          value={year}
          selectData={YEARS.map((item) => ({
            name: item,
            value: item,
          }))}
          handleChange={handleChangeYear}
        />
      </div>
    </div>
  )
}

export default Filter
