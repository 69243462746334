import PropTypes from 'prop-types'
import { Span } from '../../../../common/html/Span'
import style from './RootButton.module.css'

export const RootButton = ({
  redirect,
  renderIcon,
  title,
  isActive,
  renderRight,
}) => {
  return (
    <>
      <div
        className={style.container}
        onClick={redirect}
        style={{ backgroundColor: isActive ? '#e2edff' : '' }}
      >
        <div className={style.left}>
          <span className={style.icon}>{renderIcon()}</span>
          <Span style={{ fontSize: 12 }}>{title}</Span>
        </div>
        {renderRight()}
      </div>
    </>
  )
}

RootButton.propTypes = {
  redirect: PropTypes.func,
  renderIcon: PropTypes.func.isRequired,
  title: PropTypes.string,
  isActive: PropTypes.bool,
  renderRight: PropTypes.func,
}

RootButton.defaultProps = {
  redirect: () => {},
  title: '',
  isActive: false,
  renderRight: () => {},
}
