import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { getFontSize } from '../../../../utils/FontSize'
import { getRoundedTickValues } from '../../helper'
import { ChartContainer } from './ChartContainer'
import { ChartContent } from './ChartContent'
import { STACK_HORIZONTAL_BAR_CHART_LABEL_PADDING_RIGHT } from './ChartContentItem'
import style from './index.module.css'

const DEFAULT_MARGIN = { top: 0, left: 0, right: 0, bottom: 0 }

export const HorizontalStackedBarSeries = ({
  data,
  width,
  height,
  yAxisCompareKey,
  yAxisMaxKey,
  yAxisMinKey,
  barKeys,
  margin,
  isShowTotal,
  isFillColorYAxis,
  is100Percent,
  tooltipContentData,
  fillColor,
  tickNum,
  tickFormat,
  formatYAxisLabel,
  formatTotal,
  unitXAxis,
}) => {
  const getChartData = () => {
    return [...data].sort((a, b) => b[yAxisMaxKey] - a[yAxisMaxKey])
  }

  const getValueStackBar = () => {
    const sumItemArr = data.map((v) =>
      barKeys
        .map((key) => (v[key] ? v[key] : 0))
        .reduce((prevValue, currValue) => prevValue + currValue, 0),
    )
    return [Math.min(...sumItemArr), Math.max(...sumItemArr)]
  }

  const getTickValues = () => {
    if (is100Percent) {
      return [0, '20%', '40%', '60%', '80%', '100%']
    } else {
      return getRoundedTickValues(getValueStackBar(), tickNum, true)
    }
  }

  return (
    <div className="w-100 h-100 position-relative">
      <ChartContainer
        width={width}
        tickValues={getTickValues()}
        tickFormat={tickFormat}
        margin={{
          ...DEFAULT_MARGIN,
          ...margin,
          left: margin.left + STACK_HORIZONTAL_BAR_CHART_LABEL_PADDING_RIGHT,
        }}
        is100Percent={is100Percent}
      />
      <ChartContent
        data={getChartData()}
        width={width}
        height={height - margin.bottom}
        yAxisCompareKey={yAxisCompareKey}
        yAxisMaxKey={yAxisMaxKey}
        yAxisMinKey={yAxisMinKey}
        barKeys={barKeys}
        tickValues={getTickValues()}
        margin={{
          ...DEFAULT_MARGIN,
          ...margin,
          left: margin.left + STACK_HORIZONTAL_BAR_CHART_LABEL_PADDING_RIGHT,
        }}
        isShowTotal={isShowTotal}
        isFillColorYAxis={isFillColorYAxis}
        is100Percent={is100Percent}
        tooltipContentData={tooltipContentData}
        fillColor={fillColor}
        formatYAxisLabel={formatYAxisLabel}
        formatTotal={formatTotal}
      />
      <div
        className={[style.unitXAxis, 'canvas-chart-title'].join(' ')}
        style={{ fontSize: getFontSize(11) }}
      >
        <Translate value={unitXAxis || ''} />
      </div>
    </div>
  )
}

HorizontalStackedBarSeries.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  yAxisCompareKey: PropTypes.string.isRequired,
  yAxisMaxKey: PropTypes.string,
  yAxisMinKey: PropTypes.string,
  barKeys: PropTypes.array.isRequired,
  margin: PropTypes.object,
  isShowTotal: PropTypes.bool,
  isFillColorYAxis: PropTypes.bool,
  is100Percent: PropTypes.bool,
  tooltipContentData: PropTypes.array,
  fillColor: PropTypes.object,
  tickNum: PropTypes.number,
  tickFormat: PropTypes.func,
  formatYAxisLabel: PropTypes.func,
}

HorizontalStackedBarSeries.defaultProps = {
  margin: {
    left: 100,
    bottom: 40,
    right: 0,
  },
  isShowTotal: false,
  isFillColorYAxis: false,
  is100Percent: false,
  tickNum: 6,
}
