import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { withWrapper } from '../../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import PanelNetValue from './PanelNetValue'
import PanelOwnership from './PanelOwnership'
import PanelSectorSegmentation from './PanelSectorSegmentation'
import PanelTopNetValue from './PanelTopNetValue'

const MAP_KEY = {
  NET_VALUE: 'NET_VALUE',
  OWNERSHIP: 'OWNERSHIP',
  TOP_NET_VALUE: 'TOP_NET_VALUE',
  VALUE_BY_SECTOR_SEGMENTATION: 'VALUE_BY_SECTOR_SEGMENTATION',
}

const MIN_WIDTH = 500
const MIN_HEIGHT = 600

const MAP_SIZE = {
  [`${MAP_KEY.NET_VALUE}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(42% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minTop: 0,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 42) / 100 - COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.OWNERSHIP,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.OWNERSHIP,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.TOP_NET_VALUE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.VALUE_BY_SECTOR_SEGMENTATION,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.OWNERSHIP}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(42% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 2 + ${COMPONENT.MARGIN}px)`,
    minTop: 0,
    minLeft: MIN_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 42) / 100 - COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.TOP_NET_VALUE}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(58% - ${COMPONENT.MARGIN}px)`,
    top: `calc(42% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minTop: (600 * 42) / 100 + COMPONENT.MARGIN,
    minLeft: 0,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 58) / 100 - COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.VALUE_BY_SECTOR_SEGMENTATION,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.VALUE_BY_SECTOR_SEGMENTATION}`]: {
    width: `calc(100% / 2 - ${COMPONENT.MARGIN}px)`,
    height: `calc(58% - ${COMPONENT.MARGIN}px)`,
    top: `calc(42% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 2 + ${COMPONENT.MARGIN}px)`,
    minTop: (MIN_HEIGHT * 42) / 100 + COMPONENT.MARGIN,
    minLeft: MIN_WIDTH + 2 * COMPONENT.MARGIN,
    minWidth: MIN_WIDTH,
    minHeight: (MIN_HEIGHT * 58) / 100 - COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelNetValue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.NET_VALUE}
          sizes={sizes}
          setSizes={setSizes}
          width={formatNumber(sizes[MAP_KEY.NET_VALUE].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[MAP_KEY.NET_VALUE].height) - HEADER_PANEL_HEIGHT
          }
        />

        <PanelOwnership
          panelRefs={panelRefs}
          panelKey={MAP_KEY.OWNERSHIP}
          sizes={sizes}
          setSizes={setSizes}
          width={formatNumber(sizes[MAP_KEY.OWNERSHIP].width) - PANEL_PADDING}
          height={
            formatNumber(sizes[MAP_KEY.OWNERSHIP].height) - HEADER_PANEL_HEIGHT
          }
        />

        <PanelTopNetValue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.TOP_NET_VALUE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelSectorSegmentation
          panelRefs={panelRefs}
          panelKey={MAP_KEY.VALUE_BY_SECTOR_SEGMENTATION}
          sizes={sizes}
          setSizes={setSizes}
          height={
            formatNumber(sizes[MAP_KEY.VALUE_BY_SECTOR_SEGMENTATION].height) -
            HEADER_PANEL_HEIGHT
          }
        />
      </>
    )
  }

  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
