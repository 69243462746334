import { useState } from 'react'
import { useSelector } from 'react-redux'
import UseI18n from '../../../../common/hooks/useI18n'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'

export const GROWTH_GDP_BY_SPENDING_METHOD = 'GROWTH_GDP_BY_SPENDING_METHOD'

const GDPGrowth = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const nameScreen = UseI18n('economy.gdp.gdp.GDP_BY_SPENDING_METHOD')
  const chartName = UseI18n('economy.gdp.gdp.GROWTH_GDP_BY_SPENDING_METHOD')
  const tickerName = basicInfo.ticker

  const [isFullScreen, setIsFullScreen] = useState(false)

  return (
    <Panel
      title={'economy.gdp.gdp.GROWTH_GDP_BY_SPENDING_METHOD'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: GROWTH_GDP_BY_SPENDING_METHOD,
        nameScreen,
        chartName,
        tickerName,
      }}
      titleJpg={chartName}
      handleCustom={setIsFullScreen}
    >
      <Container
        width={formatNumber(sizes[panelKey].width - PANEL_PADDING)}
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
        isFullScreen={isFullScreen}
      />
    </Panel>
  )
}

export default GDPGrowth
