import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import Dropdown from '../../../../../common/dropdown'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Input } from '../../../../../common/html/Input'
import { Span } from '../../../../../common/html/Span'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { TABLE_TYPE } from './constants'
import style from './index.module.css'
import {
  changeTableType,
  resetGroupColumns,
  selectFilter,
  setChangeFilter,
} from './store/slice'

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

export const SearchDropdown = ({
  listItems,
  selectedItem,
  changeCurrentSelectedItem,
}) => {
  const inputRef = useRef()
  const exceptOutsideRef = useRef()

  const dispatch = useDispatch()

  const defaultItemName = listItems[0] && I18n.t(listItems[0]?.name)

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [keySearch, setKeySearch] = useState('')
  const [eventPressKey, setEventPressKey] = useState()

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
  }

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  const handleChangeSearch = (e) => {
    setKeySearch(e.target.value)
    if (!e.target.value && selectedItem) {
      dispatch(changeCurrentSelectedItem(listItems[0]))
    }
  }

  return (
    <div
      ref={exceptOutsideRef}
      className={`form-search-inbox ${style.inputSearch}`}
    >
      <Input
        style={{
          fontSize: 11,
          backgroundColor: '#3c424b',
        }}
        type="text"
        className="search-inbox bg-grey"
        placeholder={selectedItem?.value ? selectedItem?.name : defaultItemName}
        onChange={handleChangeSearch}
        onFocus={handleShow}
        onKeyDown={handlePressKey}
        ref={inputRef}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <DropdownItems
        isShowDropdown={isShowDropdown}
        parentRef={inputRef}
        handleHide={handleHide}
        exceptOutsideRef={exceptOutsideRef}
        listItems={listItems?.filter(
          (item) =>
            String(item.id)?.includes(keySearch) ||
            I18n.t(item.name)?.toLowerCase().includes(keySearch.toLowerCase()),
        )}
        setKeySearch={setKeySearch}
        eventPressKey={eventPressKey}
        changeCurrentSelectedItem={changeCurrentSelectedItem}
      />
    </div>
  )
}

const DropdownItems = ({
  isShowDropdown,
  parentRef,
  handleHide,
  eventPressKey,
  listItems,
  exceptOutsideRef,
  setKeySearch,
  changeCurrentSelectedItem,
}) => {
  const dispatch = useDispatch()

  const filterData = useSelector(selectFilter)

  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    const lengthItems = listItems.length
    if (eventPressKey?.keyCode === keyCode.ENTER) {
      if (activeIndex || activeIndex === 0)
        handleClickItem(listItems[activeIndex])
      else if (listItems[0]) handleClickItem(listItems[0])
      eventPressKey.preventDefault()
      eventPressKey.target.blur()
    }
    if (eventPressKey?.keyCode === keyCode.DOWN_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
      else if (activeIndex < lengthItems - 1) setActiveIndex(activeIndex + 1)
    }
    if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthItems > 0) {
      if (!activeIndex && activeIndex !== 0) setActiveIndex(lengthItems - 1)
      else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
    }
  }, [eventPressKey])

  const handleClickItem = (item) => {
    if (item.value !== filterData?.currentCW.value) {
      dispatch(setChangeFilter())
      dispatch(changeCurrentSelectedItem(item))
      if (item.value !== 0) {
        dispatch(changeTableType(TABLE_TYPE.TICKER))
        setKeySearch(item.name)
      } else {
        dispatch(changeTableType(TABLE_TYPE.OVERVIEW))
        setKeySearch(I18n.t(item.name))
      }
      dispatch(resetGroupColumns(filterData?.tableType))
    }

    handleHide()
  }

  return (
    <Dropdown isShow={isShowDropdown} parentRef={parentRef}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOutsideRef}
      >
        <div
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="search-ticker"
        >
          {listItems.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="common.NO_DATA" />
              </Span>
            </div>
          ) : (
            <ScrollComponent>
              {listItems.map((item, index) => {
                return (
                  <div
                    key={item.value}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleClickItem(item)}
                  >
                    <ItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter}`}>
                      <div className={style.textEllipsis}>
                        {index === 0 ? (
                          <Translate value={item.name} />
                        ) : (
                          item.name
                        )}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

const ItemScroll = ({ isActive }) => {
  const itemRef = useRef()
  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#search-ticker .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}
