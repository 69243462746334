import React, { useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { positionDropdown } from '../../../../common/dropdown'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { SEC_GROUP_INDICATOR } from '../../../common/constants'
import MenuChartSettings from '../../../common/menuChartSettings'
import { ReactComponent as IconRename } from '../../../icon/icon-rename.svg'
import { ReactComponent as IconVisibilityOff } from '../../../icon/icon-visibility-off.svg'
import { ReactComponent as IconVisibilityOn } from '../../../icon/icon-visibility-on.svg'
import {
  changeSecurities,
  selectSecuritiesByName,
} from '../../../popup/common/store/slice'
import { MAP_NAME_BY_TIME_FREQUENCY } from '../../../popup/constants'
import PopupQuickAddSecurities from '../../../popup/popupQuickAddSecurities'
import { selectIndicatorByTypeSecurities } from '../../../store/slice'
import { VIEW_TYPE, keyToolHeaderSettings } from '../../constants'
import {
  changeDetailTreeSecuritiesIndicator,
  changeFullTreeSecuritiesEconomy,
  changeSettings,
  changeTreeSecuritiesIndicator,
  selectChartTabSecurities,
  selectTreeSecuritiesIndicator,
  selectViewType,
} from '../../store/slice'
import style from '../../style.module.css'
import TimeSecurities from '../timeSecurities'

const SecuritiesChildrenEconomy = ({
  economies,
  itemType = 'economy',
  indicatorLatestPeriod,
  isViewByLatestPeriod,
}) => {
  return (
    <div className={style.securitiesChildren}>
      {Object.keys(economies)
        .sort((a, b) => a.localeCompare(b))
        .map((key) => (
          <SecuritiesChildrenEconomyItem
            key={key}
            economyKey={key}
            economy={economies[key]}
            itemType={itemType}
            indicatorLatestPeriod={indicatorLatestPeriod}
            isViewByLatestPeriod={isViewByLatestPeriod}
          />
        ))}
    </div>
  )
}

const SecuritiesChildrenEconomyItem = ({
  economyKey,
  economy,
  itemType,
  indicatorLatestPeriod,
  isViewByLatestPeriod,
}) => {
  const dispatch = useDispatch()
  const itemIndicatorRef = useRef()
  const parentRef = useRef()

  const itemChecked = useSelector(selectSecuritiesByName(itemType))
  const treeSecuritiesIndicator = useSelector(selectTreeSecuritiesIndicator)
  const viewType = useSelector(selectViewType)
  const listSecuritiesEconomy = useSelector(
    selectIndicatorByTypeSecurities(SEC_GROUP_INDICATOR.ECONOMY),
  )
  const securities = useSelector(selectChartTabSecurities)
  const { economy: economyIndicators } = securities

  const [visibility, setVisibility] = useState(true)
  const [isOpenSettings, setIsOpenSettings] = useState(false)
  const [isShow, setIsShow] = useState(false)

  const getEconomyIndicator = useMemo(() => {
    return economyIndicators.find(
      (item) =>
        item.id === economy.indicatorId &&
        item.timeFrequency === economy.indicatorTimeFrequency,
    )
  }, [economy, economyIndicators])

  const getValueItem = () => {
    return `${
      listSecuritiesEconomy.find((i) => i.id === economy.indicatorId)?.secName
    } ${MAP_NAME_BY_TIME_FREQUENCY[economy.indicatorTimeFrequency]}`
  }

  const handleVisibility = () => {
    setVisibility(!visibility)
    dispatch(
      changeDetailTreeSecuritiesIndicator({
        typeSecurities: itemType,
        securitiesId: economyKey,
        keyUpdate: 'visibility',
        valueUpdate: !visibility,
      }),
    )
  }

  const handleRemoveSecurities = () => {
    const value = itemChecked.filter((item) => item.id !== economy.indicatorId)
    dispatch(changeSecurities({ typeSecurities: itemType, value: value }))
    const newObject = JSON.parse(JSON.stringify(treeSecuritiesIndicator))
    delete newObject[itemType][economyKey]
    dispatch(changeTreeSecuritiesIndicator(newObject))
  }

  const handleOpenSettings = () => {
    setIsOpenSettings(true)
  }

  const handleCloseSettings = () => {
    setIsOpenSettings(false)
  }

  const handleClosePopup = (data) => {
    dispatch(
      changeFullTreeSecuritiesEconomy({
        securitiesId: economyKey,
        data,
      }),
    )
    handleCloseSettings()
  }

  const handleChangeSettingViewByLatestPeriod = () => {
    dispatch(
      changeSettings({
        [keyToolHeaderSettings.VIEW_BY_LATEST_PERIOD]: false,
      }),
    )
  }

  const handleEdit = () => {
    setIsShow(true)
  }

  const handleClose = () => {
    setIsShow(false)
  }

  return (
    <>
      <div className={style.card}>
        <div className={style.cardHeader}>
          <div className="cursor-pointer d-flex ali-center w-100">
            {visibility ? (
              <IconVisibilityOn
                className={style.iconVisibility}
                onClick={handleVisibility}
              />
            ) : (
              <IconVisibilityOff
                className={style.iconVisibility}
                onClick={handleVisibility}
              />
            )}
            <div
              className={style.colorIndicator}
              style={{ backgroundColor: economy.color }}
            />
            <Span
              className={style.cardTitle}
              style={{ fontSize: 11, width: 'calc(100% - 116px)' }}
            >
              <TextEllipsis text={getValueItem()} isI18n={false} />
            </Span>
            <div className="align-center">
              <IconRename
                style={{ margin: 'unset' }}
                className={`${style.iconRename} ${
                  isShow && style.iconRenameActive
                }`}
                onClick={handleEdit}
                ref={parentRef}
              />
              <i
                ref={itemIndicatorRef}
                className={[
                  'icon-setting-bold',
                  style.iconTrack,
                  style.iconContainer,
                ].join(' ')}
                style={{ fontSize: 12 }}
                onClick={handleOpenSettings}
              />
              <i
                className={[
                  'icon-delete-stroke',
                  style.iconTrack,
                  style.iconContainer,
                ].join(' ')}
                onClick={handleRemoveSecurities}
                style={{ fontSize: 12 }}
              />
            </div>
          </div>
        </div>
        <MenuChartSettings
          parentRef={itemIndicatorRef}
          item={economy}
          isBySecurities={viewType === VIEW_TYPE.BY_SECURITIES}
          isShow={isOpenSettings}
          onClose={handleClosePopup}
        />
        {isShow && (
          <PopupQuickAddSecurities
            isShow={isShow}
            onClose={handleClose}
            parentRef={parentRef}
            position={positionDropdown.BOTTOM_LEFT_RIGHT}
            itemId={economyKey}
            typeSelect={itemType}
            viewType={viewType}
            isShowInputSearch
          />
        )}
      </div>
      {viewType === VIEW_TYPE.BY_SECURITIES && (
        <TimeSecurities
          typeIndicator={SEC_GROUP_INDICATOR.ECONOMY}
          indicatorItem={getEconomyIndicator}
          onChange={handleChangeSettingViewByLatestPeriod}
          indicatorLatestPeriod={indicatorLatestPeriod}
          isViewByLatestPeriod={isViewByLatestPeriod}
        />
      )}
    </>
  )
}

SecuritiesChildrenEconomy.propTypes = {}

export default SecuritiesChildrenEconomy
