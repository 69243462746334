import { createSlice, current } from '@reduxjs/toolkit'
import { Translate } from 'react-redux-i18n'
import { typeWorkSpace } from '../../../../common/dataExplorer/constant'
import { keyBy } from '../../../../utils/Common'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import {
  EXPORT_TYPE,
  GROUP_BY,
  LOADING_KEYS,
  MAX_INDICATOR,
  MAX_TICKER,
  TIME_FRAME_TRADING_DATA,
  VIEW_BY,
} from '../constant'
import {
  getIndicatorThunk,
  getMostRecentSettingDate,
  getShareTemplateThunk,
  getTemplateThunk,
  getTemplatesThunk,
  getWorkSpaceThunk,
} from './thunk'

const initialState = {
  viewBy: VIEW_BY.CORPORATE,
  filter: {
    time: TIME_FRAME_TRADING_DATA.THREE_MONTH,
    exportType: EXPORT_TYPE.A_FILE,
    groupBy: GROUP_BY.DATE,
    dateFrom: null,
    dateTo: null,
  },
  tickers: { [VIEW_BY.CORPORATE]: [], [VIEW_BY.INDEX_AND_SECTOR]: [] },
  tickersById: { [VIEW_BY.CORPORATE]: {}, [VIEW_BY.INDEX_AND_SECTOR]: {} },
  checkedIndicators: {
    [VIEW_BY.CORPORATE]: [],
    [VIEW_BY.INDEX_AND_SECTOR]: [],
  },
  checkedIndicatorsById: {
    [VIEW_BY.CORPORATE]: {},
    [VIEW_BY.INDEX_AND_SECTOR]: {},
  },
  tickersId: {
    [VIEW_BY.CORPORATE]: [],
    [VIEW_BY.INDEX_AND_SECTOR]: [],
  },
  checkedIndicatorsId: {
    [VIEW_BY.CORPORATE]: [],
    [VIEW_BY.INDEX_AND_SECTOR]: [],
  },
  indicators: [],
  templates: [],
  loading: {
    [LOADING_KEYS.FILTER]: true,
    [LOADING_KEYS.PREVIEW]: false,
    addTicker: false,
    addIndicator: false,
    mostRecentSettingDate: false,
  },
  isLoadWorkSpace: false,
  mostRecentSettingDate: {},
  templateId: {
    [VIEW_BY.CORPORATE]: null,
    [VIEW_BY.INDEX_AND_SECTOR]: null,
  },
}

export const slice = createSlice({
  name: 'dataExplorer/tradingData',
  initialState,
  reducers: {
    resetData: (state) => {
      const viewBy = state.viewBy
      const unResetKey = [
        'viewBy',
        'loading',
        'indicators',
        'templates',
        'mostRecentSettingDate',
      ]
      Object.keys(initialState).forEach((key) => {
        if (!unResetKey.includes(key)) {
          if (initialState[key][viewBy]) {
            state[key][viewBy] = initialState[key][viewBy]
          } else {
            state[key] = initialState[key]
          }
        }
      })
    },
    changeViewBy: (state, action) => {
      state.viewBy = action.payload
    },
    changeFilterTime: (state, action) => {
      state.filter.time = action.payload
    },
    changeFilterExportType: (state, action) => {
      state.filter.exportType = action.payload
    },
    changeFilterGroupBy: (state, action) => {
      state.filter.groupBy = action.payload
    },
    changeFilterDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload
    },
    changeFilterDateTo: (state, action) => {
      state.filter.dateTo = action.payload
    },
    changeLoading: (state, action) => {
      state.loading = {
        ...state.loading,
        ...action.payload,
      }
    },
    changeCheckedIndicatorsByLanguage: (state, action) => {
      const viewBy = state.viewBy
      state.checkedIndicators[viewBy] = action.payload.map((item) => ({
        ...item,
        index: undefined,
        actionIndex: undefined,
        isCheck: undefined,
      }))
      state.checkedIndicatorsById[viewBy] = keyBy(action.payload, 'index')
    },
    changeTickersByLanguage: (state, action) => {
      const viewBy = state.viewBy
      state.tickers[viewBy] = action.payload.map((item) => ({
        ...item,
        index: undefined,
        actionIndex: undefined,
        isCheck: undefined,
      }))
      state.tickersById[viewBy] = keyBy(action.payload, 'index')
    },
    changeCheckTickerItem: (state, action) => {
      const viewBy = state.viewBy
      const tickersById = state.tickersById[viewBy]
      const item = tickersById[action.payload]
      if (item) {
        if (item.isCheck) {
          tickersById[action.payload].isCheck = false
        } else {
          const numberItemChecked = Object.values(tickersById).filter(
            (item) => item.isCheck,
          ).length

          if (numberItemChecked < MAX_TICKER) {
            tickersById[action.payload].isCheck = true
          }
        }
      }

      const newTickers = Object.values(tickersById)
        .sort((a, b) => (a.isCheck === b.isCheck ? 0 : a.isCheck ? -1 : 1))
        .map((item, index) => ({ ...item, index, actionIndex: index }))

      state.tickersById[viewBy] = keyBy(newTickers, 'index')
      state.tickersId[viewBy] = newTickers.map((item) => item.index)
    },
    changeCheckIndicatorItem: (state, action) => {
      const viewBy = state.viewBy
      const checkedIndicatorsById = state.checkedIndicatorsById[viewBy]
      const item = checkedIndicatorsById[action.payload]
      if (item) {
        if (item.isCheck) {
          checkedIndicatorsById[action.payload].isCheck = false
        } else {
          const numberItemChecked = Object.values(checkedIndicatorsById).filter(
            (item) => item.isCheck,
          ).length

          if (numberItemChecked < MAX_INDICATOR) {
            checkedIndicatorsById[action.payload].isCheck = true
          }
        }
      }

      const newIndicators = Object.values(checkedIndicatorsById)
        .sort((a, b) => (a.isCheck === b.isCheck ? 0 : a.isCheck ? -1 : 1))
        .map((item, index) => ({ ...item, index, actionIndex: index }))

      state.checkedIndicatorsById[viewBy] = keyBy(newIndicators, 'index')
      state.checkedIndicatorsId[viewBy] = newIndicators.map(
        (item) => item.index,
      )
    },
    changeCheckAllTicker: (state, action) => {
      const viewBy = state.viewBy
      let numberItemChecked = Object.values(state.tickersById[viewBy]).filter(
        (item) => item.isCheck,
      ).length
      if (action.payload) {
        Object.keys(state.tickersById[viewBy]).forEach((key) => {
          if (numberItemChecked < MAX_TICKER) {
            numberItemChecked++
            state.tickersById[viewBy][key].isCheck = true
          }
        })
      } else {
        Object.keys(state.tickersById[viewBy]).forEach(
          (key) => (state.tickersById[viewBy][key].isCheck = false),
        )
      }
    },
    changeCheckAllIndicator: (state, action) => {
      const viewBy = state.viewBy
      let numberItemChecked = Object.values(
        state.checkedIndicatorsById[viewBy],
      ).filter((item) => item.isCheck).length

      if (action.payload) {
        Object.keys(state.checkedIndicatorsById[viewBy]).forEach((key) => {
          if (numberItemChecked < MAX_TICKER) {
            numberItemChecked++
            state.checkedIndicatorsById[viewBy][key].isCheck = true
          }
        })
      } else {
        Object.keys(state.checkedIndicatorsById[viewBy]).forEach(
          (key) => (state.checkedIndicatorsById[viewBy][key].isCheck = false),
        )
      }
    },
    addCheckedIndicator: (state, action) => {
      const viewBy = state.viewBy
      const data = Object.values(state.checkedIndicatorsById[viewBy])
      const itemChecked = data.filter((item) => item.isCheck)
      const isExist = data.find(
        (item) => item.indicatorId === action.payload.indicatorId,
      )

      if (!isExist) {
        data.push(action.payload)
      }

      state.checkedIndicators[viewBy] = data.map((item) => ({
        ...item,
        index: undefined,
        actionIndex: undefined,
        isCheck: undefined,
      }))
      state.checkedIndicatorsById[viewBy] = keyBy(
        data.map((item, index) => ({
          ...item,
          index,
          actionIndex: index,
          isCheck:
            itemChecked.length >= MAX_INDICATOR ? item.isCheck || false : true,
        })),
        'index',
      )
      state.checkedIndicatorsId[viewBy] = data.map((_, index) => index)
      state.loading.addIndicator = false
    },
    addTicker: (state, action) => {
      const viewBy = state.viewBy
      let data = Object.values(state.tickersById[viewBy])

      if (action.payload.length > 1) {
        let tickersExist = data

        if (viewBy === VIEW_BY.CORPORATE) {
          tickersExist = data.filter((item) => item.isCheck)
        }

        const tickerExistIds = tickersExist.map(
          (item) =>
            item.organizationId ||
            (item.groupId && item.groupId + item.groupCode) ||
            (item.icbId && item.icbId + item.icbCode),
        )
        const tickerNotExist = action.payload.filter(
          (item) =>
            !tickerExistIds.includes(
              item.organizationId ||
                (item.groupId && item.groupId + item.groupCode) ||
                (item.icbId && item.icbId + item.icbCode),
            ),
        )

        data = [...tickersExist, ...tickerNotExist]
      } else {
        const tickerExistIds = data.map(
          (item) =>
            item.organizationId ||
            (item.groupId && item.groupId + item.groupCode) ||
            (item.icbId && item.icbId + item.icbCode),
        )
        const ticker = action.payload[0]
        const isTickerExist =
          ticker &&
          tickerExistIds.includes(
            ticker.organizationId ||
              (ticker.groupId && ticker.groupId + ticker.groupCode) ||
              (ticker.icbId && ticker.icbId + ticker.icbCode),
          )

        if (ticker && !isTickerExist) {
          data = [...data, { ...ticker, isCheck: true }]
        }

        if (ticker && isTickerExist) {
          if (ticker.ticker) {
            EventEmitter.dispatch(SHOW_POPUP_ERROR, [
              <Translate value="tool.dataExplorer.tradingData.EXIST_TICKER_SECTOR" />,
            ])
          } else {
            EventEmitter.dispatch(SHOW_POPUP_ERROR, [
              <Translate value="tool.dataExplorer.tradingData.EXIST_INDEX_SECTOR" />,
            ])
          }
        }
      }

      data = [...data].map((item, index) => ({
        ...item,
        index,
        actionIndex: index,
        isCheck: item.isCheck || false,
        indexSectorName:
          viewBy === VIEW_BY.INDEX_AND_SECTOR
            ? item.groupName || item.icbName
            : undefined,
      }))

      state.tickers[viewBy] = data.map((item) => ({
        ...item,
        index: undefined,
        actionIndex: undefined,
        isCheck: undefined,
      }))
      state.tickersById[viewBy] = keyBy(data, 'index')
      state.tickersId[viewBy] = data.map((item) => item.index)
      state.loading.addTicker = false
    },
    removeCheckedIndicator: (state) => {
      const viewBy = state.viewBy
      const itemsCheckedId = []

      Object.values(state.checkedIndicatorsById[viewBy]).forEach((item) => {
        if (item.isCheck) {
          itemsCheckedId.push(item.indicatorId)
        }
      })

      const data = state.checkedIndicators[viewBy].filter(
        (item) => !itemsCheckedId.includes(item.indicatorId),
      )

      state.checkedIndicators[viewBy] = data
      state.checkedIndicatorsById[viewBy] = keyBy(
        data.map((item, index) => ({
          ...item,
          index,
          actionIndex: index,
          isCheck: false,
        })),
        'index',
      )
      state.checkedIndicatorsId[viewBy] = data.map((_, index) => index)
    },
    removeTicker: (state) => {
      const viewBy = state.viewBy
      const itemsCheckedId = []

      Object.values(current(state.tickersById[viewBy])).forEach((item) => {
        if (item.isCheck) {
          const id =
            item.organizationId ||
            (item.groupId && item.groupId + item.groupCode) ||
            (item.icbId && item.icbId + item.icbCode)
          itemsCheckedId.push(id)
        }
      })

      const data = state.tickers[viewBy].filter((item) => {
        const id =
          item.organizationId ||
          (item.groupId && item.groupId + item.groupCode) ||
          (item.icbId && item.icbId + item.icbCode)
        return !itemsCheckedId.includes(id)
      })

      state.tickers[viewBy] = data
      state.tickersById[viewBy] = keyBy(
        data.map((item, index) => ({
          ...item,
          index,
          actionIndex: index,
          isCheck: false,
        })),
        'index',
      )
      state.tickersId[viewBy] = data.map((_, index) => index)
    },
    openTemplate(state, action) {
      const rawParameter = action.payload.parameters?.rawParameter

      if (rawParameter) {
        Object.keys(rawParameter).forEach((key) => {
          if (['viewBy', 'filter'].includes(key)) {
            state[key] = rawParameter[key]
          } else {
            state[key][rawParameter.viewBy] = rawParameter[key]
          }
        })
        ;[
          'tickers',
          'tickersId',
          'checkedIndicators',
          'checkedIndicatorsId',
        ].forEach((key) => (state[key][rawParameter.viewBy] = []))
      }
    },
    changeTemplateId: (state, action) => {
      state.templateId[state.viewBy] = action.payload
    },
    changeTickersWhenLoadTemplate: (state, action) => {
      const viewBy = state.viewBy
      const { tickers, tickersById, tickersId } = action.payload

      state.tickers[viewBy] = tickers
      state.tickersById[viewBy] = tickersById
      state.tickersId[viewBy] = tickersId
    },
    changeCheckedIndicatorsWhenLoadTemplate: (state, action) => {
      const viewBy = state.viewBy
      const { checkedIndicators, checkedIndicatorsById, checkedIndicatorsId } =
        action.payload

      state.checkedIndicators[viewBy] = checkedIndicators
      state.checkedIndicatorsById[viewBy] = checkedIndicatorsById
      state.checkedIndicatorsId[viewBy] = checkedIndicatorsId
    },
  },
  extraReducers: (builder) => {
    // get indicator
    builder.addCase(getIndicatorThunk.pending, (state) => {
      state.loading[LOADING_KEYS.FILTER] = true
    })
    builder.addCase(getIndicatorThunk.fulfilled, (state, action) => {
      state.indicators = action.payload
      state.loading[LOADING_KEYS.FILTER] = false
    })
    builder.addCase(getIndicatorThunk.rejected, (state, action) => {
      state.loading[LOADING_KEYS.FILTER] = action.payload
    })
    // template
    builder.addCase(getTemplatesThunk.fulfilled, (state, action) => {
      state.templates = action.payload
    })
    builder.addCase(getTemplateThunk.pending, (state) => {
      state.loading[LOADING_KEYS.PREVIEW] = true
    })
    builder.addCase(getTemplateThunk.rejected, (state) => {
      state.loading[LOADING_KEYS.PREVIEW] = false
    })
    builder.addCase(getShareTemplateThunk.pending, (state) => {
      state.loading[LOADING_KEYS.PREVIEW] = true
    })
    builder.addCase(getShareTemplateThunk.rejected, (state) => {
      state.loading[LOADING_KEYS.PREVIEW] = false
    })
    // get work space
    builder.addCase(getWorkSpaceThunk.pending, (state) => {
      state.loading[LOADING_KEYS.PREVIEW] = true
    })
    builder.addCase(getWorkSpaceThunk.fulfilled, (state, action) => {
      const workSpaces = action.payload.filter(
        (item) => item.dataType === typeWorkSpace.TRADING_DATA,
      )

      workSpaces.forEach((workSpace, index) => {
        const { data } = workSpace.parameters?.rawParameter

        if (data) {
          Object.keys(data).forEach((key) => {
            if (['viewBy', 'filter'].includes(key)) {
              state[key] = data[key]
            } else {
              state[key][data.viewBy] = data[key]
            }
          })
        }
      })

      state.isLoadWorkSpace = true

      state.loading[LOADING_KEYS.PREVIEW] = false
    })
    builder.addCase(getWorkSpaceThunk.rejected, (state) => {
      state.isLoadWorkSpace = true

      state.loading[LOADING_KEYS.PREVIEW] = false
    })
    // get most recent setting date
    builder.addCase(getMostRecentSettingDate.pending, (state) => {
      state.loading.mostRecentSettingDate = true
    })
    builder.addCase(getMostRecentSettingDate.fulfilled, (state, action) => {
      state.mostRecentSettingDate = action.payload
      state.loading.mostRecentSettingDate = false
    })
    builder.addCase(getMostRecentSettingDate.rejected, (state) => {
      state.loading.mostRecentSettingDate = false
    })
  },
})

export const selectViewBy = (state) => state[slice.name].viewBy
export const selectFilter = (state) => state[slice.name].filter
export const selectTickers = (type) => (state) =>
  state[slice.name].tickers[type]
export const selectTickersById = (id, attr) => (state) => {
  const viewBy = state[slice.name].viewBy
  return valByKeyWithDot(state[slice.name].tickersById[viewBy][id], attr)
}
export const selectListTickersById = (type) => (state) =>
  state[slice.name].tickersById[type]
export const selectTickersId = (type) => (state) =>
  state[slice.name].tickersId[type]
export const selectCheckedIndicators = (type) => (state) =>
  state[slice.name].checkedIndicators[type]
export const selectCheckedIndicatorsById = (id, attr) => (state) => {
  const viewBy = state[slice.name].viewBy
  return valByKeyWithDot(
    state[slice.name].checkedIndicatorsById[viewBy][id],
    attr,
  )
}
export const selectListCheckedIndicatorsById = (type) => (state) =>
  state[slice.name].checkedIndicatorsById[type]
export const selectCheckedIndicatorsId = (type) => (state) =>
  state[slice.name].checkedIndicatorsId[type]
export const selectIndicators = (state) => state[slice.name].indicators
export const selectTemplates = (state) => state[slice.name].templates
export const selectLoading = (key) => (state) => state[slice.name].loading[key]
export const selectIsLoadWorkSpace = (state) =>
  state[slice.name].isLoadWorkSpace
export const selectMostRecentSettingDate = (state) =>
  state[slice.name].mostRecentSettingDate
export const selectMostRecentSettingDateLoading = (state) =>
  state[slice.name].loading.mostRecentSettingDate
export const selectTemplateId = (state) => {
  const viewBy = state[slice.name].viewBy
  return state[slice.name].templateId[viewBy]
}

export const {
  changeViewBy,
  changeFilterTime,
  changeFilterExportType,
  changeFilterGroupBy,
  changeFilterDateFrom,
  changeFilterDateTo,
  addCheckedIndicator,
  addTicker,
  removeCheckedIndicator,
  removeTicker,
  changeCheckIndicatorItem,
  changeCheckTickerItem,
  changeCheckAllIndicator,
  changeCheckAllTicker,
  changeLoading,
  resetData,
  changeCheckedIndicatorsByLanguage,
  changeTickersByLanguage,
  openTemplate,
  changeTemplateId,
  changeTickersWhenLoadTemplate,
  changeCheckedIndicatorsWhenLoadTemplate,
} = slice.actions

register(slice.name, slice.reducer)
