import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { HeaderCommon } from '../HeaderCommon'
import { selectLatest, selectLatestLoading } from '../store/slice'
import { getLatestNews } from '../store/thunk'

export const Latest = () => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const basicInfo = useSelector(selectBasicInfo)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(getLatestNews({ OrganizationId: basicInfo.organizationId }))
    }
  }, [locale, basicInfo.organizationId])

  const data = useSelector(selectLatest)
  const isLoading = useSelector(selectLatestLoading)

  return <HeaderCommon data={data} isLoading={isLoading} />
}
