import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { Table } from '../../../../common/table'
import { keyBy } from '../../../../utils/Common'
import { formatVal } from '../../../../utils/Value'
import FirstCellColumn from './FirstCellColumn'
import {
  selectDataTable,
  selectDataTableById,
  selectGroupColumns,
  selectIds,
  selectLoadingTable,
  selectTimeRange,
  sort,
} from './store/slice'
import { getTotalSocialInvesmentCapital } from './store/thunk'

const TableComponent = ({ height }) => {
  const dispatch = useDispatch()
  const [rowCollapse, setRowCollapse] = useState([])

  const isLoading = useSelector(selectLoadingTable)
  const ids = useSelector(selectIds)
  const groupColumns = useSelector(selectGroupColumns)
  const dataTable = useSelector(selectDataTable)
  const currentTimeRange = useSelector(selectTimeRange)
  const locale = useSelector((state) => state.i18n.locale)

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowCollapse.indexOf(rowId)
      if (index === -1) {
        setRowCollapse((old) => [...old, rowId])
      }
    } else {
      setRowCollapse((old) => old.filter((item) => item !== rowId))
    }
  }

  const levelCollapse = keyBy(
    dataTable?.map((item) => ({
      id: item.id,
      level: item.level,
      isCollapsible: item.childrenIds.length ? true : false,
    })),
    'id',
  )

  useEffect(() => {
    dispatch(
      getTotalSocialInvesmentCapital({
        TimeFrequency: currentTimeRange,
      }),
    )
  }, [currentTimeRange, locale])

  return (
    <div style={{ height: height }}>
      <Table
        ids={ids}
        columnDraggable={false}
        sort={sort}
        getDataFromRedux={selectDataTableById}
        rowDraggable={false}
        isLoading={isLoading}
        schema={groupColumns.map((item, index) => {
          const title = item.title
          const colId = item.key
          const result = {
            colId,
            title,
          }
          if (index === 0) {
            return {
              ...result,
              thStyle: {
                textAlign: 'left',
              },
              tdStyle: {
                minWidth: 240,
              },
              canCustomTd: true,
              render: (val, rowId, props) => {
                const tableHorizontalHead = dataTable.find(
                  (item) => item.id === rowId,
                )
                return (
                  <FirstCellColumn
                    props={props}
                    val={tableHorizontalHead?.name}
                    level={tableHorizontalHead?.level}
                    childrenIds={tableHorizontalHead?.childrenIds}
                    index={ids.indexOf(rowId)}
                    rowId={rowId}
                    onRowCollapse={onRowCollapse}
                  />
                )
              },
            }
          }

          return {
            ...result,
            isI18n: true,
            tdStyle: {
              textAlign: 'right',
              fontSize: 12,
            },
            thStyle: {
              textAlign: 'right',
              fontSize: 10,
              fontWeight: 600,
            },
            canCustomTd: true,
            render: (val, rowId, props) => {
              return (
                <td {...props}>
                  <Span>{formatVal(val)}</Span>
                </td>
              )
            },
          }
        })}
        stickyFirstColumn={true}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        defaultScrollToRight={true}
        isCollapse={true}
        levelCollapse={levelCollapse}
        rowsCollapse={rowCollapse}
      />
    </div>
  )
}

export default TableComponent
