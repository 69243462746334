import PropTypes from 'prop-types'
import React from 'react'
import { SORT_TYPES } from './constants'
import style from './sort.module.css'

const Sort = ({ type }) => {
  const getSortUpClassName = () => {
    return SORT_TYPES.ASC === type
      ? [style.sortUp, style.sortUpActive].join(' ')
      : style.sortUp
  }

  const getSortDownClassName = () => {
    return SORT_TYPES.DESC === type
      ? [style.sortDown, style.sortDownActive].join(' ')
      : style.sortDown
  }

  return (
    <div className={style.sortStatus}>
      <div className={getSortUpClassName()} />
      <div className={getSortDownClassName()} />
    </div>
  )
}

Sort.propTypes = {
  type: PropTypes.string,
}

Sort.defaultProps = {
  type: '',
}

export default Sort
