import moment from 'moment'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import style from './index.module.css'

export const CalendarSearch = ({
  dateFrom,
  dateTo,
  onDateFromChange,
  onDateToChange,
  limitDatepicker,
}) => {
  const currentDate = [new Date()]
  const timeZone = UseTimeZone()
  const currentYear = new Date().getFullYear()
  const previousYear = new Date()
  previousYear.setFullYear(previousYear.getFullYear() - 1)

  // Use state
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false)
  const [isOpenDateTo, setIsOpenDateTo] = useState(false)

  const clearInputDate = () => {
    if (
      (!dateFrom && !dateTo) ||
      (moment(previousYear).format('YYYY-MM-DD') ===
        moment(getDateWithTimeZone(dateFrom)).format('YYYY-MM-DD') &&
        moment(new Date()).format('YYYY-MM-DD') ===
          moment(getDateWithTimeZone(dateTo)).format('YYYY-MM-DD'))
    ) {
      return
    } else {
      onDateFromChange(previousYear)
      onDateToChange(new Date())
    }
  }

  const onClickDateFrom = () => {
    setIsOpenDateFrom(true)
  }

  const onClickDateTo = () => {
    setIsOpenDateTo(true)
  }

  const onDateFromClose = () => {
    setIsOpenDateFrom(false)
  }

  const onDateToClose = () => {
    setIsOpenDateTo(false)
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  return (
    <div className="form-date-select">
      <Span style={{ fontSize: 12 }}>
        <Translate value="market.marketInDepth.indexStatistics.priceData.FROM_DATE" />
      </Span>
      <div className="date-select">
        <CalendarPopper
          date={dateFrom ? getDateWithTimeZone(dateFrom) : ''}
          minDate={getDateWithTimeZone(limitDatepicker.minDate)}
          maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
          handleChange={onDateFromChange}
          CustomInput={InputDateCalendar}
          hightlightDates={currentDate}
          customHeader={HeaderCalendarPopper({
            startYear: limitDatepicker.minYear || currentYear - 10,
            endYear: limitDatepicker.maxYear || currentYear,
            zIndex: 11,
          })}
          isOpen={isOpenDateFrom}
          onClose={onDateFromClose}
        />
        <span
          className={`btn-select-date ${style.iconCaret}`}
          onClick={onClickDateFrom}
        >
          <i className="icon-caret-down" />
        </span>
      </div>
      <Span style={{ fontSize: 12 }}>
        <Translate value="market.marketInDepth.indexStatistics.priceData.TO_DATE" />
      </Span>
      <div className="date-select">
        <CalendarPopper
          date={dateTo ? getDateWithTimeZone(dateTo) : ''}
          minDate={getDateWithTimeZone(limitDatepicker.minDate)}
          maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
          handleChange={onDateToChange}
          CustomInput={InputDateCalendar}
          hightlightDates={currentDate}
          customHeader={HeaderCalendarPopper({
            startYear: limitDatepicker.minYear || currentYear - 10,
            endYear: limitDatepicker.maxYear || currentYear,
            zIndex: 11,
          })}
          isOpen={isOpenDateTo}
          onClose={onDateToClose}
        />
        <span
          className={`btn-select-date ${style.iconCaret}`}
          onClick={onClickDateTo}
        >
          <i className="icon-caret-down" />
        </span>
      </div>
      <button
        className={style.buttonClear}
        type="button"
        disabled={(!dateFrom === dateTo) === null}
        onClick={() => clearInputDate()}
      >
        <Span style={{ fontSize: 10 }}>
          <Translate value="market.marketInDepth.indexStatistics.priceData.CLEAR_DATE" />
        </Span>
      </button>
    </div>
  )
}

CalendarSearch.propTypes = {
  dateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  dateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  limitDatepicker: PropTypes.object,
}
