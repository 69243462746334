import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { resetFilter } from '../store/slice'
import style from './index.module.css'
import { Button } from '../../../common/html/Button'

export const ResetButton = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(resetFilter())
  }

  return (
    <Button
      style={{ fontSize: 11 }}
      className={['btn bg-grey-1 w-80 h-20 mr-8', style.resetBtn].join(' ')}
      onClick={onClick}
    >
      <Translate value="common.button.BUTTON_RESET" />
    </Button>
  )
}
