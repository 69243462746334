import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { STATISTICAL_INTEREST_RATE } from '.'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { FIXED_ROW_ID } from '../panelDepositRateCommercial/config'
import {
  getCheckboxChecked,
  getTab,
  getTableDataLoading,
} from '../panelDepositRateCommercial/store/slice'
import Header from './Header'
import LineChart from './LineChart'
import {
  getDropdownValue,
  getLineChartData,
  getLineChartLoading,
  getTimeRange,
} from './store/slice'
import { getCompareOtherBank } from './store/thunk'

const ChartComponent = ({ height, width, isFullScreen }) => {
  const dispatch = useDispatch()

  const lineChartData = useSelector(getLineChartData)
  const tableLoading = useSelector(getTableDataLoading)
  const lineChartLoading = useSelector(getLineChartLoading)
  const timeRange = useSelector(getTimeRange)
  const locale = useSelector((state) => state.i18n.locale)
  const dropdownValue = useSelector(getDropdownValue)
  const checkboxChecked = useSelector(getCheckboxChecked)
  const tab = useSelector(getTab)

  const lineKey = checkboxChecked.filter((item) => !FIXED_ROW_ID.includes(item))
  const monotoneKey = checkboxChecked.filter((item) =>
    FIXED_ROW_ID.includes(item),
  )

  useEffect(() => {
    dispatch(
      getCompareOtherBank({
        TimeRange: timeRange,
        DurationId: dropdownValue,
        OrganizationIds: checkboxChecked.toString(),
        IsIndividual: tab,
      }),
    )
  }, [timeRange, locale, dropdownValue, checkboxChecked, tab])

  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <Header />
            {(lineChartLoading || tableLoading) && (
              <div
                style={{
                  height:
                    typeof sizes.height === 'number'
                      ? height - sizes.height
                      : 'auto',
                }}
              >
                <Loading />
              </div>
            )}
            {!lineChartLoading && !tableLoading && (
              <>
                {lineChartData.length === 0 && (
                  <div
                    style={{
                      height:
                        typeof sizes.height === 'number'
                          ? height - sizes.height
                          : 'auto',
                    }}
                  >
                    <NoData />
                  </div>
                )}
                {lineChartData.length > 0 && (
                  <>
                    {typeof sizes.height === 'number' && (
                      <div id={STATISTICAL_INTEREST_RATE}>
                        <LineChart
                          width={width}
                          height={height - sizes.height}
                          data={lineChartData}
                          keyXAxis={'day'}
                          lineKey={lineKey}
                          monotoneKey={monotoneKey}
                          isFullScreen={isFullScreen}
                        />
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartComponent
