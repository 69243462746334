import { useSelector } from 'react-redux'
import { Cell, LabelList, Scatter, ScatterChart, ZAxis } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainerWithZoom } from '../../../../common/chart/rechart/ChartContainerWithZoom'
import { shadeColor } from '../../../../utils/Color'
import { selectIsShowTicker } from '../store/slice'
import ChartTooltip from './ChartTooltip'
import LabelCustom from './LabelCustom'

const Chart = ({
  width,
  height,
  data,
  xAxisId,
  yAxisId,
  zAxisId,
  zAxisRange,
  xLabel,
  yLabel,
  xUnit,
  yUnit,
}) => {
  const isShowTicker = useSelector(selectIsShowTicker)

  const renderTooltip = (data, decimalLengths, xAxisDecimalLength) => (
    <ChartTooltip
      data={data}
      yAxisDecimalLength={decimalLengths[yAxisId]}
      xAxisDecimalLength={xAxisDecimalLength}
      xLabel={xLabel}
      yLabel={yLabel}
    />
  )

  return (
    <ChartContainerWithZoom
      chartType={ScatterChart}
      data={data}
      width={width}
      height={height}
      keyXAxis={xAxisId}
      xAxisType="number"
      xTickNum={7}
      xAxisLabel={xLabel}
      isScatterChart={true}
      xAxisUnit={xUnit}
      yAxis={[
        {
          id: yAxisId,
          keys: [yAxisId],
          orientation: 'left',
          tickNum: 7,
          isScatterChart: true,
          label: yLabel,
          labelPosition: AXIS_LABEL_POSITION.LEFT,
          unitYAxis: yUnit,
        },
      ]}
      zoomLeftYBarPosition="right"
      marginZoomY={{ bottom: 28, left: 0 }}
      margin={{ ...MARGIN_RECHARTS, left: 0, right: 16, bottom: 28 }}
      renderCustomTooltip={renderTooltip}
    >
      <ZAxis zAxisId={zAxisId} dataKey={zAxisId} range={zAxisRange} />
      <Scatter
        data={data}
        yAxisId={yAxisId}
        zAxisId={zAxisId}
        isAnimationActive={false}
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${index}`}
            fill={entry.color}
            fillOpacity={0.86}
            stroke={shadeColor(entry.color)}
          />
        ))}
        {isShowTicker && (
          <LabelList content={<LabelCustom />} dataKey="ticker" />
        )}
      </Scatter>
    </ChartContainerWithZoom>
  )
}

export default Chart
