import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { getFontSize } from '../../../../utils/FontSize'
import { Span } from '../../../html/Span'
import { getTextWidth } from '../../helper'
import { getPositionLeft, getTickValuesDisplay } from './helps'
import style from './index.module.css'

const border = '1px solid #5c5c5c'

export const ChartContainer = ({
  width,
  tickValues,
  margin,
  is100Percent,
  tickFormat,
}) => {
  const fontSize12 = getFontSize(12)

  // Get data
  const getWidthTickBlock = () => {
    if (width) {
      if (is100Percent) {
        return width / 10
      } else {
        return width / (tickValues.length * 2 - 1)
      }
    } else {
      return 0
    }
  }

  const getMaxTextWidth = useMemo(() => {
    return Math.max(
      ...tickValues.map(
        (val) => getTextWidth(tickFormat ? tickFormat(val) : val),
        fontSize12,
      ),
    )
  }, [tickValues])

  const getTickValues = useMemo(() => {
    return getTickValuesDisplay(
      width,
      getMaxTextWidth,
      getWidthTickBlock(),
      tickValues,
    )
  }, [width, getMaxTextWidth, tickValues])

  // Render
  const renderFirstBlock = () => {
    return (
      <div
        className="h-100 position-relative"
        style={{
          width: getWidthTickBlock(),
          borderLeft: border,
        }}
      >
        <div
          className={['w-100', style.positionAbsolute].join(' ')}
          style={{
            bottom: -margin.bottom + 10,
            left: -5,
          }}
        >
          <Span className="canvas-chart-title t-left">0</Span>
        </div>
      </div>
    )
  }

  const renderPercentLatestBlock = (tickValue) => {
    const valueFormat = getTickValues.includes(tickValue)
      ? tickFormat
        ? tickFormat(tickValue)
        : tickValue
      : ''

    return (
      <div
        className="h-100 position-relative"
        style={{
          width: getWidthTickBlock(),
          borderRight: border,
        }}
      >
        <div
          className={['t-right w-100', style.positionAbsolute].join(' ')}
          style={{
            bottom: -margin.bottom + 10,
            right: -10,
          }}
        >
          <div className="w-100" style={{ position: 'relative' }}>
            <div
              className={style.positionAbsolute}
              style={{
                left: getPositionLeft(
                  valueFormat,
                  getWidthTickBlock(),
                  fontSize12,
                ),
              }}
            >
              <Span className="canvas-chart-title">{valueFormat}</Span>
            </div>
          </div>
          <span style={{ opacity: 0 }}>{valueFormat}</span>
        </div>
      </div>
    )
  }

  const renderOtherBlock = (tickValue) => {
    const valueFormat = getTickValues.includes(tickValue)
      ? tickFormat
        ? tickFormat(tickValue)
        : tickValue
      : ''

    return (
      <div
        className="h-100 d-flex position-relative justify-content-center"
        style={{
          width: 2 * getWidthTickBlock(),
        }}
      >
        <div style={{ borderRight: border }}></div>
        <div
          className={['t-center w-100', style.positionAbsolute].join(' ')}
          style={{
            bottom: -margin.bottom + 10,
          }}
        >
          <div className="w-100" style={{ position: 'relative' }}>
            <div
              className={style.positionAbsolute}
              style={{
                left: getPositionLeft(
                  valueFormat,
                  getWidthTickBlock() * 2,
                  fontSize12,
                ),
              }}
            >
              <Span className="canvas-chart-title">{valueFormat}</Span>
            </div>
          </div>
          <span style={{ opacity: 0 }}>{valueFormat}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={['w-100 h-100 d-flex', style.positionAbsolute].join(' ')}>
      <div style={{ width: margin.left }}></div>
      <div style={{ width: `calc(100% - ${margin.left + margin.right}px)` }}>
        <div
          className="d-flex"
          style={{
            height: `calc(100% - ${margin.bottom}px)`,
            borderBottom: border,
          }}
        >
          {tickValues.map((tickValue, index) => (
            <React.Fragment key={index}>
              {index === 0 ? (
                renderFirstBlock()
              ) : (
                <>
                  {is100Percent && index === tickValues.length - 1
                    ? renderPercentLatestBlock(tickValue)
                    : renderOtherBlock(tickValue)}
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div style={{ width: margin.right }} />
    </div>
  )
}

ChartContainer.propTypes = {
  width: PropTypes.number.isRequired,
  tickValues: PropTypes.array.isRequired,
  margin: PropTypes.object,
  is100Percent: PropTypes.bool,
  tickFormat: PropTypes.func,
}
