import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { TIME_RANGES } from '../../../../../common/tabs/DispatchActionTab'
import { convertSortKeyToNumber, keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import {
  dateTypes,
  defaultSortTable,
  statusAll,
  transactionTypes,
  typesAll,
} from '../constants'
import { getActionStatus, getActionTypes, getDirectorDeals } from './thunk'

const initialState = {
  ids: [],
  dealsById: {},
  initialIds: [],
  data: [],

  filter: {
    page: 1,
    totalPage: 1,
    stateTypes: typesAll.actionTypeId,
    stateStatus: statusAll.activeStatusId,
    dateFrom: '',
    dateTo: '',
    sortField: Object.keys(defaultSortTable)[0],
    sortOrder: convertSortKeyToNumber(Object.values(defaultSortTable)[0]),
    owner: '',
    transactionType: transactionTypes.ALL_TRANSACTION,
    dateType: dateTypes.TYPE_PUBLIC_DATE,
    timeRange: TIME_RANGES.All,
  },

  loading: true,
  loadingDataTable: true,
  dataActionTypes: [],
  dataActionStatus: [],
  isDisableCheckedAll: false,
  reCalcWidths: false,
  limitDatepicker: {
    minDate: '',
    minYear: '',
    maxDate: '',
    maxYear: '',
  },
}

export const slice = createSlice({
  name: 'market/marketInDepth/deal',
  initialState,
  reducers: {
    changeStateTypes: (state, action) => {
      state.filter.stateTypes = action.payload
      state.filter.page = 1
    },
    changeStateStatus: (state, action) => {
      state.filter.stateStatus = action.payload
      state.filter.page = 1
    },
    changeDateFrom: (state, action) => {
      state.filter.dateFrom = action.payload
      state.filter.page = 1
    },
    changeDateTo: (state, action) => {
      state.filter.dateTo = action.payload
      state.filter.page = 1
    },
    changePage: (state, action) => {
      state.filter.page = action.payload
    },
    changePageTotal: (state, action) => {
      state.filter.totalPage = action.payload
      state.filter.page = 1
    },
    changeSortField: (state, action) => {
      state.filter.sortField = action.payload
    },
    changeSortOrder: (state, action) => {
      state.filter.sortOrder = action.payload
    },
    changeOwner: (state, action) => {
      state.filter.owner = action.payload
    },
    changeTransaction: (state, action) => {
      state.filter.transactionType = action.payload
      state.filter.page = 1
    },
    changeDateType: (state, action) => {
      state.filter.dateType = action.payload
      state.filter.sortField =
        action.payload === dateTypes.TYPE_PUBLIC_DATE
          ? 'publicDateId'
          : 'endDateId'
      state.filter.page = 1
    },
    changeTimeRange: (state, action) => {
      state.filter.timeRange = action.payload
      state.filter.page = 1
    },
    subscribeIndexData(state, action) {
      action.payload.forEach((item) => {
        if (state.dealsById[item.ticker]) {
          state.dealsById[item.ticker] = item
        }
      })
    },
    sort: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dealsById,
        action.payload,
        state.initialIds,
      )
    },
    clearDataDealTable: (state) => {
      state.ids = []
      state.dealsById = {}
    },
    changeIsDisableCheckedAll: (state, action) => {
      state.isDisableCheckedAll = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectorDeals.pending, (state) => {
      state.loadingDataTable = true
    })
    builder.addCase(getDirectorDeals.fulfilled, (state, action) => {
      state.loadingDataTable = false
      const value = action.payload.data[0]
      state.limitDatepicker = {
        ...state.limitDatepicker,
        minDate: value?.minDate || '',
        minYear: new Date(value?.minDate).getFullYear(),
        maxDate: value?.maxDate || '',
        maxYear: new Date(value?.maxDate).getFullYear(),
      }

      const dataPayload = action.payload.data.map((e, index) => ({
        ...e,
        dealsPayload: index + e.ticker,
      }))
      state.ids = dataPayload.map((e) => e.dealsPayload)
      state.dealsById = keyBy(dataPayload, 'dealsPayload')
      state.data = action.payload.data

      state.filter.page = action.payload.page
      state.filter.totalPage = action.payload.totalPage
      state.reCalcWidths = !state.reCalcWidths
    })
    builder.addCase(getDirectorDeals.rejected, (state, action) => {
      if (state.isDisableCheckedAll) {
        state.loadingDataTable = false
      } else {
        state.loadingDataTable = action.payload.loading
      }
    })

    builder.addCase(getActionTypes.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getActionTypes.fulfilled, (state, action) => {
      state.loading = false
      state.dataActionTypes = action.payload
      state.dataActionTypes.unshift(typesAll)
    })
    builder.addCase(getActionTypes.rejected, (state) => {
      state.loading = false
    })

    builder.addCase(getActionStatus.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getActionStatus.fulfilled, (state, action) => {
      state.loading = false
      state.dataActionStatus = action.payload
      state.dataActionStatus.unshift(statusAll)
    })
    builder.addCase(getActionStatus.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectFilter = (state) => state[slice.name].filter

export const selectDealIds = (state) => state[slice.name].ids
export const selectDealValue = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dealsById[id], attr)

export const selectActionTypes = (state) => state[slice.name].dataActionTypes
export const selectActionStatus = (state) => state[slice.name].dataActionStatus

export const selectLoadingDataTable = (state) =>
  state[slice.name].loadingDataTable
export const selectData = (state) => state[slice.name].data
export const selectReCalcWidthsDealTable = (state) =>
  state[slice.name].reCalcWidths
export const selectLimitDatepicker = (state) =>
  state[slice.name].limitDatepicker

export const {
  changeStateTypes,
  changeStateStatus,
  changeDateFrom,
  changeDateTo,
  changeSortField,
  changeSortOrder,
  changeOwner,
  changeTransaction,
  changeDateType,
  changeTimeRange,

  subscribeIndexData,
  sort,
  clearDataDealTable,
  changePage,
  changePageTotal,
  changeIsDisableCheckedAll,
} = slice.actions

register(slice.name, slice.reducer)
