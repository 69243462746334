import { Translate } from 'react-redux-i18n'
import { useNavigate } from 'react-router'
import { Button } from '../common/html/Button'
import { Span } from '../common/html/Span'
import Popup from '../common/popup'
import CurrentState from '../utils/CurrentState'
import styles from './style.module.css'

export const PopupCanNotAccess = ({
  title,
  isHideButton,
  isPopup,
  onCancel,
}) => {
  const navigate = useNavigate()

  const goToPreviousPage = () => {
    navigate(CurrentState.getCurrentState().data.route)
  }

  const renderContent = () => {
    return (
      <div className="centerItems h-100">
        <div
          style={{
            padding: '24px 20px',
            backgroundColor: '#1f2633',
            borderRadius: 4,
          }}
        >
          <Translate value="error.YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_FEATURE" />
          <br />
          <br />
          <Translate value="error.PLEASE_CONTACT_THE_ADMIN" />
          <br />
          <Translate value="error.ADMIN_CONTACT" />
          <br />
          <Span className="d-flex">
            <Translate value="Email:" />
            <Span
              style={{
                textDecoration: 'underline',
                color: '#40abff',
                marginLeft: 4,
              }}
            >
              fiinpro.support@fiingroup.vn
            </Span>
          </Span>
          {!isHideButton && (
            <>
              <br />
              <div className="t-center">
                <Button
                  onClick={goToPreviousPage}
                  style={{
                    padding: '4px 8px',
                    backgroundColor: '#323744',
                    borderRadius: 4,
                    width: 'fit-content',
                    color: '#ffffff',
                    cursor: 'pointer',
                  }}
                >
                  <Translate value="error.GO_TO_PREVIOUS_PAGE" />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    )
  }

  if (!isPopup) {
    return renderContent()
  }

  return (
    <Popup>
      <div className={`modal ${styles.popup}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={onCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        {renderContent()}
      </div>
    </Popup>
  )
}

PopupCanNotAccess.defaultProps = {
  title: '',
  isHideButton: false,
  isPopup: false,
  onCancel: () => {},
}
