import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Table } from '../../../../../common/table'
import { calendarTabKey } from '../../../../../common/tabs/DispatchActionTabWithCalendar'
import { selectIndexInfo } from '../../../../../common/topInfo/indexTopInfo/store/slice'
import { convertSortKeyToNumber } from '../../../../../utils/Common'
import { getISOStartOrEndOfDay } from '../../../../../utils/Datetime'
import {
  changeTableFilter,
  changeTableReCalcWidths,
  keys,
  resetTableState,
  selectLoading,
  selectTableDataCell,
  selectTableDataId,
  selectTableDataReCalcWidth,
  selectTableFilter,
  sortTableData,
} from '../../store/slice'
import { getIndexEODThunk } from '../../store/thunk'
import { getTableSchema } from './helpers'

export const TableEOD = () => {
  const dispatch = useDispatch()
  const timeZone = UseTimeZone()
  const ref = useRef({ timeout: null })

  // Use selector
  const locale = useSelector((state) => state.i18n.locale)
  const tableFilter = useSelector(selectTableFilter)
  const ids = useSelector(selectTableDataId)
  const loading = useSelector(selectLoading(keys.TABLE_INTRADAY))
  const indexInfo = useSelector(selectIndexInfo)
  const reCalcWidth = useSelector(selectTableDataReCalcWidth)

  // Actions
  const getTableEOD = () => {
    let params = {
      GroupId: indexInfo.groupId,
      Page: tableFilter.page,
      PageSize: tableFilter.pageSize,
      StartDate: tableFilter.dateFrom
        ? getISOStartOrEndOfDay(tableFilter.dateFrom, timeZone, true)
        : '',
      EndDate: tableFilter.dateTo
        ? getISOStartOrEndOfDay(tableFilter.dateTo, timeZone, false)
        : '',
      SortField: tableFilter.sortField,
      SortOrder: tableFilter.sortOrder,
    }

    if (tableFilter.timeRange !== calendarTabKey) {
      params = {
        ...params,
        TimeRange: tableFilter.timeRange,
      }
    }

    dispatch(getIndexEODThunk(params))
  }

  const handleChangePage = (page) => {
    if (page) {
      dispatch(changeTableFilter({ page }))
    }
  }

  const handleChangeSortField = (data) => {
    if (Object.values(data)[0]) {
      dispatch(
        changeTableFilter({
          sortField: Object.keys(data)[0],
          sortOrder: convertSortKeyToNumber(Object.values(data)[0]),
        }),
      )
    } else {
      dispatch(changeTableFilter({ sortField: 'tradingDate', sortOrder: 1 }))
    }
  }

  // Use effect
  useEffect(() => {
    if (indexInfo.groupId) {
      if (ref.current.timeout) {
        clearTimeout(ref.current.timeout)
      }

      ref.current.timeout = setTimeout(() => {
        getTableEOD()
        clearTimeout(ref.current.timeout)
      }, 500)
    }

    return () => {
      clearTimeout(ref.current.timeout)
    }
  }, [
    indexInfo.groupId,
    tableFilter.timeRange,
    tableFilter.dateFrom,
    tableFilter.dateTo,
    tableFilter.page,
    tableFilter.sortField,
    tableFilter.sortOrder,
  ])

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(resetTableState())
    }
  }, [
    indexInfo.groupId,
    tableFilter.timeRange,
    tableFilter.dateFrom,
    tableFilter.dateTo,
  ])

  useEffect(() => {
    dispatch(changeTableReCalcWidths())
  }, [ids])

  return (
    <Table
      ids={ids}
      getDataFromRedux={selectTableDataCell}
      isLoading={loading}
      schema={getTableSchema(
        tableFilter,
        locale,
        timeZone,
        selectTableDataCell,
        false,
      )}
      isLargeHead={true}
      resizable={false}
      hasFooter={false}
      hasPagination={true}
      currentPage={tableFilter.page}
      totalPage={tableFilter.totalPage}
      defaultSort={{ tradingDate: 'desc' }}
      sort={sortTableData}
      handleChangePage={handleChangePage}
      handleSortField={handleChangeSortField}
      hasTooltip={false}
      reCalcWidths={reCalcWidth}
    />
  )
}
