import { useEffect, useState } from 'react'

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const RESPONSIVE = {
  SMALL: {
    MIN_WIDTH: 0,
    TD_HEIGHT: 13, // real 23px because padding 10px
  },
  LARGE: {
    MIN_WIDTH: 1440,
    TD_HEIGHT: 17, // real 27px because padding 10px
  },
}

const getHeightTd = (width) => {
  return Object.values(RESPONSIVE).reduce((result, item) => {
    return width > item.MIN_WIDTH ? item.TD_HEIGHT : result
  }, 0)
}

const useHeightTdTable = () => {
  const [heightTd, setHeightTd] = useState(
    getHeightTd(getWindowDimensions().width),
  )

  useEffect(() => {
    const handleResize = () => {
      setHeightTd(getHeightTd(getWindowDimensions().width))
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return heightTd
}

export default useHeightTdTable
