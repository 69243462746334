export const PEERS_COMPARISON_KEYS = [
  'totalProduction',
  'totalConsumption',
  'totalInventory',

  'productionHotCoil',
  'consumptionHotCoil',
  'inventoryHotCoil',

  'productionCoolCoil',
  'consumptionCoolCoil',
  'inventoryCoolCoil',
]

export const PEERS_COMPARISON_FILTER = [
  {
    name: 'sector.sectorSpecific.steelCoil.TOTAL',
    value: 0,
  },
  {
    name: 'sector.sectorSpecific.steelCoil.HOT_ROLL',
    value: 1,
  },
  {
    name: 'sector.sectorSpecific.steelCoil.COOL_ROLL',
    value: 2,
  },
]
