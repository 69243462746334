import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import History from '../../../../../core/services/bond/valuation/history'
import { FORMAT, formatDateTime } from '../../../../utils/Datetime'
import { handleExport } from '../../../../utils/Export'

export const getDataHistory = createAsyncThunk(
  'bond/valuation/history/GET_DATA',
  async (params, { rejectWithValue, getState }) => {
    try {
      const response = await History.getDataHistory(params)
      if (response.success) {
        const allState = getState()
        const state = allState?.i18n?.locale
        const dataList = response?.data || []
        const data = dataList.map((e, index) => ({
          ...e,
          id: index,
          paymentDate: formatDateTime(e.paymentDate, FORMAT.DATE, state),
        }))
        return data
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const exportFile = createAsyncThunk(
  'demo/valuation/history/exportFile',
  async (data) => {
    const response = await History.exportFile(data)
    handleExport(response.data, response.filename)
  },
)
