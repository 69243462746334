import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  addIndicator,
  addTicker,
  keys,
  openPopupTemplate,
  selectActiveSheet,
  selectFiinXTemplates,
  selectListSheet,
  selectLoading,
  selectTemplates,
} from '../../../common/dataExplorer/corporate/store/slice'
import {
  deleteTemplateThunk,
  getFiinXTemplatesThunk,
  getTemplateThunk,
  getTemplatesThunk,
  renameTemplateThunk,
} from '../../../common/dataExplorer/corporate/store/thunk'
import useGetIndicator from '../../../common/dataExplorer/corporate/useGetIndicator'
import Filter from '../../../common/dataExplorer/filter'

const CorporateFilter = () => {
  const dispatch = useDispatch()

  const { indicatorsTree, loading } = useGetIndicator(false)

  const locale = useSelector((state) => state.i18n.locale)
  const loadingTable = useSelector(selectLoading(keys.TABLE_RESULT))
  const templates = useSelector(selectTemplates)
  const listSheet = useSelector(selectListSheet)
  const activeSheet = useSelector(selectActiveSheet)
  const fiinXTemplates = useSelector(selectFiinXTemplates)

  useEffect(() => {
    dispatch(getTemplatesThunk())
  }, [])

  useEffect(() => {
    dispatch(getFiinXTemplatesThunk())
  }, [locale])

  const handleAddTicker = (tickers) => {
    dispatch(addTicker(tickers))
  }

  const handleAddIndicator = (indicator) => {
    if (!Object.keys(indicator.children).length) {
      dispatch(addIndicator(indicator))
    }
  }

  const renameTemplate = ({ newName, item }) => {
    dispatch(
      renameTemplateThunk({
        templateId: item.templateId,
        oldTemplateName: item.templateName,
        newTemplateName: newName,
      }),
    )
  }

  const deleteTemplate = (item) => {
    dispatch(deleteTemplateThunk({ templateId: item.templateId }))
  }

  const getDetailTemplate = (item) => {
    dispatch(getTemplateThunk({ TemplateId: item.templateId }))
  }

  const getDetailFiinXTemplate = (template) => {
    dispatch(openPopupTemplate(template))
  }

  const isFiinXTemplate = !!listSheet.find(
    (sheet) => sheet.value === activeSheet,
  )?.templateGroup

  return (
    <>
      <Filter
        loading={loadingTable}
        handleAddTicker={handleAddTicker}
        handleAddIndicator={handleAddIndicator}
        indicatorTree={indicatorsTree}
        loadingIndicator={loading}
        templates={templates}
        handleRenameTemplate={renameTemplate}
        handleDeleteTemplate={deleteTemplate}
        handleGetTemplate={getDetailTemplate}
        handleGetFiinXTemplate={getDetailFiinXTemplate}
        isHasFiinXTemplate={true}
        isFiinXTemplate={isFiinXTemplate}
        fiinXTemplates={fiinXTemplates}
        isOnlyEnableLastExchange={true}
      />
    </>
  )
}

export default CorporateFilter
