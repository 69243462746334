import React, { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { Loading } from '../../common/loading'
import { SizeTracker } from '../../common/sizeTracker'
import useGetLayoutDashboard, {
  lengthChartInLayout,
} from '../chart/common/useGetLayoutDashboard'
import { changeChartTabsData, resetChartTabData } from '../chart/store/slice'
import {
  resetDataChartSetting,
  selectDownloadingDashboardChart,
} from '../common/chartInPanel/store/slice'
import useDownloadAllChart from '../common/chartInPanel/useDownloadAllChart'
import { Header } from '../common/header'
import { PanelContainer } from '../common/panel/PanelContainer'
import { dashboardTypes } from '../constants'
import { KEY_DASHBOARD_TABS } from '../editDashboard/constants'
import {
  selectDashboard,
  selectDashboardChartData,
  selectDashboardChartLoading,
} from '../store/slice'
import { getCharts, getIndicators } from '../store/thunk'
import ChartPanel from './ChartPanel'
import style from './index.module.css'

export const Content = ({ isHaveDataChartPreview, handlePreviewChart }) => {
  const dispatch = useDispatch()

  const downloadingMessage = UseI18n('financialChart.DOWNLOADING')

  const locale = useSelector((state) => state.i18n.locale)
  const { data = {} } = useSelector(selectDashboard)
  const dashboardChartData = useSelector(selectDashboardChartData)

  const dashboardChartDataSort = useMemo(
    () => [...dashboardChartData].sort((a, b) => a.order - b.order),
    [JSON.stringify(dashboardChartData)],
  )

  const dashboardID = useSelector(selectDownloadingDashboardChart)
  const [isDownloading] = useDownloadAllChart(
    dashboardID,
    data?.name,
    dashboardChartDataSort,
  )

  useEffect(() => {
    if (data.id) {
      dispatch(getCharts({ dashboardId: data.id, isTemplate: false }))
    }
  }, [data])

  useEffect(() => {
    dispatch(getIndicators())
  }, [locale])

  useEffect(() => {
    dispatch(resetChartTabData())
    dispatch(
      changeChartTabsData({
        ...{ [KEY_DASHBOARD_TABS]: data },
        ...Object.assign(
          {},
          ...dashboardChartDataSort.map((item, index) => ({
            [index + 1 + KEY_DASHBOARD_TABS]: {
              ...JSON.parse(item.detail),
              chartID: item.id,
            },
          })),
        ),
      }),
    )
  }, [dashboardChartDataSort, data])

  useEffect(() => {
    return () => {
      dispatch(resetDataChartSetting())
    }
  }, [])

  return (
    <>
      {isDownloading && (
        <div className={style.downloadContainer}>{downloadingMessage}</div>
      )}
      <ContentComponent
        dashboardChartDataSort={dashboardChartDataSort}
        isHaveDataChartPreview={isHaveDataChartPreview}
        handlePreviewChart={handlePreviewChart}
      />
    </>
  )
}

const ContentComponent = memo(
  ({ dashboardChartDataSort, isHaveDataChartPreview, handlePreviewChart }) => {
    const { dashboardType, data = {} } = useSelector(selectDashboard)
    const dashboardChartLoading = useSelector(selectDashboardChartLoading)

    const { layoutDisplay } = useGetLayoutDashboard(data.layout)

    const hasAddNewChart = useMemo(() => {
      return (
        !data.isTemplate &&
        dashboardChartDataSort.length < lengthChartInLayout[data.layout]
      )
    }, [data.isTemplate, data.layout, JSON.stringify(dashboardChartDataSort)])

    return (
      <SizeTracker>
        {(size) => {
          return (
            <>
              <Header data={data} dashboardType={dashboardType} />
              {(size.height || size.height === 0) && (
                <div
                  className="position-relative"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  {dashboardChartLoading && <Loading isBlack />}
                  {!dashboardChartLoading && data.id && (
                    <PanelContainer
                      id={`dashboardChart${data.id}`}
                      hasAddNewChart={hasAddNewChart}
                      widthPanelAddNewChart={
                        layoutDisplay[dashboardChartDataSort.length]?.width
                      }
                    >
                      {dashboardChartDataSort.map((item, index) => (
                        <ChartPanel
                          key={item.id}
                          data={data}
                          index={index}
                          item={item}
                          handlePreviewChart={handlePreviewChart}
                          isShowViews={
                            dashboardType === dashboardTypes.DASHBOARD_TEMPLATE
                          }
                          isHaveDataChartPreview={isHaveDataChartPreview}
                        />
                      ))}
                    </PanelContainer>
                  )}
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    )
  },
)
