import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import AdditionalInfoPrice from '../components/AdditionalInfoPrice'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import {
  selectPriceData,
  selectWarrantInfo,
  subscribeBidAsk,
  subscribeWarrant,
} from './store/slice'
import { getLatestPrice } from './store/thunk'

export const PriceData = () => {
  const dispatch = useDispatch()
  const warrantInfo = useSelector(selectWarrantInfo)
  const priceData = useSelector(selectPriceData)

  useEffect(() => {
    if (warrantInfo.coveredWarrantId) {
      dispatch(
        getLatestPrice({
          Ids: warrantInfo.coveredWarrantId,
          GroupType: GROUP_TYPE.COVERED_WARRANT,
        }),
      )
    }
  }, [warrantInfo.coveredWarrantId])

  return (
    <div className="info-box">
      <Realtime
        channel={
          ChannelConfig.CoveredWarrantChannel + warrantInfo.coveredWarrantId
        }
        action={subscribeWarrant}
      >
        <Realtime
          channel={
            ChannelConfig.CoveredWarrantBidAskChannel +
            warrantInfo.coveredWarrantId
          }
          action={subscribeBidAsk}
        >
          <SummaryInfoPrice data={{ ...priceData, isTicker: true }} />
          <AdditionalInfoPrice data={{ ...priceData, isTicker: true }} />
        </Realtime>
      </Realtime>
    </div>
  )
}
