import { Translate } from 'react-redux-i18n'
import { DateTimeSpan } from '../../../../common/DateTimeSpan'
import { Span } from '../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../utils/Color'
import { FORMAT } from '../../../../utils/Datetime'
import { formatVal, valToPercent } from '../../../../utils/Value'
import style from './index.module.css'

export const DirectorDealForm = ({ data }) => {
  return (
    <>
      <Span style={{ fontSize: 14 }} className={style.title}>
        {data.organizationName}
      </Span>
      <Span style={{ fontSize: 12, marginBottom: 12 }} className={style.time}>
        {data?.date && (
          <DateTimeSpan
            date={data.date}
            formatDate={FORMAT.NEWS_WITHOUT_TIME}
          />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.AN_DATE"
          className="mr-4"
        />
        {data?.date && (
          <DateTimeSpan date={data.date} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.START_DATE"
          className="mr-4"
        />
        {data?.startDateId && (
          <DateTimeSpan date={data.startDateId} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.END_DATE"
          className="mr-4"
        />
        {data?.endDateId && (
          <DateTimeSpan date={data.endDateId} formatDate={FORMAT.DATE} />
        )}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.VOL_BEFORE"
          className="mr-4"
        />
        <Span style={{ color: getTwoColorByValue(data.shareBeforeTrade) }}>
          {formatVal(data.shareBeforeTrade, 0)}
        </Span>
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.VOL_REGISTER"
          className="mr-4"
        />
        <Span style={{ color: getTwoColorByValue(data.shareRegister) }}>
          {formatVal(data.shareRegister, 0)}
        </Span>
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.ACTION_TYPE"
          className="mr-4"
        />
        {data.actionTypeName}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.VOL_EXECUTED"
          className="mr-4"
        />
        <Span style={{ color: getTwoColorByValue(data.shareAcquire) }}>
          {formatVal(data.shareAcquire, 0)}
        </Span>
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.VOL_AFTER"
          className="mr-4"
        />
        <Span style={{ color: getTwoColorByValue(data.shareAfterTrade) }}>
          {formatVal(data.shareAfterTrade, 0)}
        </Span>
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.OWNERSHIP_AFTER_TRADING"
          className="mr-4"
        />
        {valToPercent(data.ownedPercentage)}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.STATUS"
          className="mr-4"
        />
        {data.status}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.POSITION_NAME"
          className="mr-4"
        />
        {data.position}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.RELATED_PERSON"
          className="mr-4"
        />
        {data.roleOwner}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.RELATIVE"
          className="mr-4"
        />
        {data.relative}
      </Span>
      <Span className={style.normalText}>
        <Translate
          value="market.event.directorDealForm.EVENT_TYPE"
          className="mr-4"
        />
        {data.event}
      </Span>
    </>
  )
}
