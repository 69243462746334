import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class MonetaryProxy extends ProxyBase {
  // Money Supply Credit
  getMoneySupplyOutstandingLoan(params) {
    return this.get('MoneysupplyOutstandingLoan', params)
  }

  downloadMoneySupplyOutstandingLoan(params) {
    return this.get('DownloadMoneysupplyOutstandingLoan', params, 'download')
  }

  getMonetaryTypeName(params) {
    return this.get('MonetaryTypeName', params)
  }

  getMoneySupplyOutstandingLoanGrowth(data) {
    return this.post('MoneySupplyOutstandingLoanGrowth', data)
  }

  getContributionToCreditGrowth(params) {
    return this.get('ContributionToCreditGrowth', params)
  }

  getGrowthOverYears(params) {
    return this.get('GrowthOverYears', params)
  }

  // Exchange Rate
  getCurrencyExchangeRate(params) {
    return this.get('Currency/ExchangeRate', params)
  }

  downloadCurrencyExchangeRate(params) {
    return this.get('Currency/DownloadExchangeRate', params, 'download')
  }

  getChangeOfExchangeRate(data) {
    return this.post('Currency/ChangeOfExchangeRate', data)
  }
}

export default new MonetaryProxy(ServiceProxyConfig.Economy.Monetary.ServiceUrl)
