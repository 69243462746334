import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PopupEvent from '../../common/popup/PopupEvent'
import { zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'
import {
  addChecked,
  addCheckedAll,
  changeTickerIds,
  changeTickersById,
  selectChecked,
  selectTickerIds,
  selectTickersById,
  unchecked,
  uncheckedAll,
} from '../store/slice'

const ButtonDelete = () => {
  const dispatch = useDispatch()

  const checkedList = useSelector(selectChecked)
  const tickersById = useSelector(selectTickersById)
  const tickerIds = useSelector(selectTickerIds)

  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false)

  const handleDelete = (e) => {
    e.stopPropagation()
    const newTickerIds = tickerIds.filter((tickerId) => {
      return !checkedList.includes(tickerId)
    })
    const newTickersById = { ...tickersById }
    checkedList.map((tickerId) => delete newTickersById[tickerId])
    dispatch(changeTickersById(newTickersById))
    dispatch(changeTickerIds([...newTickerIds]))
    dispatch(uncheckedAll())
    closePopupDelete()
  }

  const openPopupDelete = () => {
    if (checkedList.length > 0) setIsShowPopupDelete(true)
  }

  const closePopupDelete = () => setIsShowPopupDelete(false)

  return (
    <>
      <button onClick={openPopupDelete} type="button">
        <i className="icon-trash" />
      </button>
      <PopupEvent
        title={
          <Translate value="watchlist.watchlistDetail.TITLE_DELETE_TICKER" />
        }
        message={
          <div>
            <Translate value="watchlist.watchlistDetail.ASK_DELETE" />{' '}
            <span style={{ fontWeight: 'bold' }}>
              {checkedList?.length === 1 ? (
                tickersById[checkedList[0]]?.tickerOrTaxCode
              ) : (
                <>
                  {checkedList.length}{' '}
                  <Translate value="watchlist.watchlistDetail.TICKERS" />
                </>
              )}
            </span>
            ?
          </div>
        }
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
        isShow={isShowPopupDelete}
        handleClose={closePopupDelete}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: closePopupDelete,
        }}
        btnSubmit={{
          btnName: <Translate value="watchlist.watchlistDetail.DELETE" />,
          event: handleDelete,
        }}
      />
    </>
  )
}

const TickerCheckbox = ({ id }) => {
  const dispatch = useDispatch()

  const checkedList = useSelector(selectChecked)
  const tickerIds = useSelector(selectTickerIds)

  const handleChangeCheckbox = (e) => {
    if (id) {
      if (e.target.checked) dispatch(addChecked(id))
      else dispatch(unchecked(id))
    } else {
      if (e.target.checked) dispatch(addCheckedAll(tickerIds))
      else dispatch(uncheckedAll())
    }
  }

  const handleCaptureClick = (e) => {
    e.stopPropagation()
  }

  const handleUncheckAll = () => dispatch(uncheckedAll())

  return (
    <>
      {!id ? (
        <div className="group-btn-action d-flex justify-content-center ali-center">
          {checkedList.length === 0 ? (
            <label className="d-flex justify-content-center">
              <input
                onChange={handleChangeCheckbox}
                onClick={handleCaptureClick}
                checked={
                  tickerIds.length === checkedList.length &&
                  tickerIds.length > 0
                }
                type="checkbox"
                className={`checkbox line ${style.checkboxAll}`}
              />
            </label>
          ) : (
            <div className={style.padding0} onClick={handleUncheckAll}>
              <i className={`icon-minus-stroke ${style.btnUncheck}`} />
            </div>
          )}
          <ButtonDelete />
        </div>
      ) : (
        <label className="d-flex justify-content-center">
          <input
            onChange={handleChangeCheckbox}
            onClick={handleCaptureClick}
            checked={checkedList.includes(id)}
            type="checkbox"
            className={`checkbox line ${style.checkCus}`}
          />
        </label>
      )}
    </>
  )
}

TickerCheckbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default TickerCheckbox
