import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import authProvider from '../../../core/AuthenticationProvider'
import { Button } from '../../common/html/Button'

export const EditProfileBtn = () => {
  const [token, setToken] = useState('')

  useEffect(() => {
    const asyncFn = async () => {
      const user = await authProvider.getUser()
      setToken(user.access_token)
    }
    asyncFn()
  }, [])

  return (
    <Button
      style={{ fontSize: 11 }}
      className="btn bg-grey-3 h-20"
      type="button"
      onClick={() =>
        window.open(
          process.env.REACT_APP_CHANGE_PROFILE + '?token=' + token,
          '_blank',
        )
      }
    >
      <Translate value="setting.settingProfile.EDIT_PROFILE" />
    </Button>
  )
}
