import moment from 'moment'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import {
  COLLAPSE_ID_4,
  COLLAPSE_ID_5,
  DEFAULT_COLUMN_COLOR,
  DEFAULT_COLUMN_KEY,
} from '../../config'
import { formatMonthYear } from '../../panelOpenMarket/helper'

export const handleComboChartData = (data, ids, timeZone, locale) => {
  if (!data.length) {
    return []
  }

  const dataFiltered = filterDataById(data, ids)
  const publicDateIdNotDuplicates = handleDuplicateByKey(dataFiltered, [
    'publicDateId',
  ])

  const dataByKey = publicDateIdNotDuplicates.map((item) => {
    const dataWithPublicDateId = dataFiltered.filter(
      (i) => i.publicDateId === item.publicDateId,
    )

    return dataWithPublicDateId.reduce((previous, current) => {
      const key = `${current.stt}-${current.typeId}-${current.childType}`
      const val = isPercentFormat(key)
        ? current.value * 100
        : current.value / 1000000000

      return {
        ...previous,
        [key]: val,
        day: item.publicDateId,
      }
    }, {})
  })

  return handleTime(dataByKey, timeZone, locale)
}

const handleTime = (chartData, timeZone, locale) => {
  return chartData
    .map((item) => ({
      ...item,
      day: formatDateTime(item.day, FORMAT.DATE, locale, timeZone),
      monthYear: formatMonthYear(new Date(item.day)),
    }))
    .sort((a, b) =>
      moment(a.day, FORMAT.DATE[locale]).diff(
        moment(b.day, FORMAT.DATE[locale]),
      ),
    )
}

export const handleComboChartKey = (ids) => {
  const lineKey = []
  const columnKey = []

  ids.forEach((id) => {
    if (Object.values(DEFAULT_COLUMN_KEY).includes(id)) {
      columnKey.push(id)
    } else {
      lineKey.push(id)
    }
  })

  return { lineKey, columnKey }
}

export const handleLineChartHasAuthor = (lineChartData, idsWithInfo) => {
  return lineChartData
    .filter((item) => item.author !== undefined)
    .map((item) => {
      const itemWithId = idsWithInfo.find(
        (element) => `${element.id}` === item.author,
      )
      return {
        ...item,
        indicatorName: itemWithId?.title,
      }
    })
}

export const handleTooltipTwoColumn = (idChecked) => {
  const MAX_ITEM_OF_COLUMN = 8
  if (idChecked.length < MAX_ITEM_OF_COLUMN) {
    return { leftColumn: idChecked, rightColumn: [] }
  }

  const leftColumnLength = Math.ceil(idChecked.length / 2)
  return {
    leftColumn: idChecked.concat().splice(0, leftColumnLength),
    rightColumn: idChecked.concat().splice(leftColumnLength),
  }
}

const filterDataById = (data, ids) => {
  return data.filter((item) =>
    ids.includes(`${item.stt}-${item.typeId}-${item.childType}`),
  )
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const isPercentFormat = (str) => {
  return str.startsWith(COLLAPSE_ID_4) || str.startsWith(COLLAPSE_ID_5)
}

export const getColor = (id, lineColor) => {
  if (id === DEFAULT_COLUMN_KEY.sellOutright) {
    return DEFAULT_COLUMN_COLOR.sellOutright
  }

  if (id === DEFAULT_COLUMN_KEY.reverseRepo) {
    return DEFAULT_COLUMN_COLOR.reverseRepo
  }

  return lineColor
}
