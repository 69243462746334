import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useComGroup from '../../../../../common/hooks/useComGroup'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { Table } from '../../../../../common/table'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { keyBy } from '../../../../../utils/Common'
import { NAME_COL_ID, TABLE_TYPE } from '../constant'
import { selectOrgIds, selectSelectedWL } from '../header/store/slice'
import { dispatchGetDataByTableType } from '../helper'
import {
  changeLoading,
  resetIndicatorMapping,
  selectExchangeRates,
  selectFilter,
  selectLoading,
  selectReCalcWidths,
  selectResponseOrgIds,
  selectRowIds,
  selectTableConstants,
  selectValueById,
} from '../store/slice'
import {
  getExchangeRates,
  getIndicatorMapping,
  getMaxPeriod,
} from '../store/thunk'
import Filter from './Filter'
import { FirstColumnCell } from './FirstColumnCell'
import { OtherColumnsCell } from './OtherColumnsCell'
import ThCell from './ThCell'

const TableTree = ({ tableType }) => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const comGroup = useComGroup(basicInfo?.organizationId)

  const locale = useSelector((state) => state.i18n.locale)
  const orgIds = useSelector(selectOrgIds)
  const selectedWL = useSelector(selectSelectedWL)
  const responseOrgIds = useSelector(selectResponseOrgIds)
  const loading = useSelector(selectLoading)
  const reCalcWidths = useSelector(selectReCalcWidths)

  const exchangeRates = useSelector(selectExchangeRates)
  const rowIds = useSelector(selectRowIds)
  const tableConstants = useSelector(selectTableConstants)
  const filter = useSelector(selectFilter)

  const [rowsCollapse, setRowsCollapse] = useState([])

  const handleCallData = () => {
    if (
      basicInfo.organizationId &&
      Object.values(rowIds).length &&
      filter.quarter &&
      filter.year
    ) {
      dispatchGetDataByTableType(dispatch, tableType, {
        organizationId: basicInfo.organizationId,
        peerOrganizationIds: orgIds,
        Quarter: filter.quarter,
        Year: filter.year,
      })
    }
  }

  useEffect(() => {
    if (basicInfo.organizationId && !filter.quarter && !filter.year) {
      dispatch(getMaxPeriod({ OrganizationId: basicInfo.organizationId }))
    }
  }, [basicInfo.organizationId, filter.quarter, filter.year])

  useEffect(() => {
    if (selectedWL) handleCallData()
  }, [selectedWL])

  useEffect(() => {
    handleCallData()
    setRowsCollapse([])
  }, [locale, filter.quarter, filter.year, rowIds])

  useEffect(() => {
    setRowsCollapse([])
  }, [orgIds])

  useEffect(() => {
    dispatch(changeLoading(true))
    if (comGroup) {
      dispatch(resetIndicatorMapping())
      dispatch(
        getIndicatorMapping({
          Statement:
            tableType === TABLE_TYPE.BALANCE_SHEET
              ? 'BalanceSheet'
              : 'IncomeStatement',
          ComGroup: comGroup,
          Language: locale,
        }),
      )
    }
  }, [comGroup, locale, tableType])

  useEffect(() => {
    if (!Object.keys(exchangeRates).length) {
      dispatch(getExchangeRates({ CurrencyCode: 'USD' }))
    }
  }, [exchangeRates])

  const onRowCollapse = (rowId, value) => {
    if (value) {
      const index = rowsCollapse.indexOf(rowId)
      if (index === -1) {
        setRowsCollapse((old) => [...old, rowId])
      }
    } else {
      setRowsCollapse((old) => old.filter((v) => v !== rowId))
    }
  }

  const levelCollapse = keyBy(
    tableConstants.map((v) => ({
      index: v.index,
      level: v.level,
      isRowCollapse: v.isCollapse,
    })),
    'index',
  )

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Filter />
            {size.height && (
              <div
                style={{
                  height: `calc(100% - ${size.height}px)`,
                  position: 'relative',
                }}
              >
                {loading ? (
                  <Loading />
                ) : rowIds.length && responseOrgIds.length ? (
                  <Table
                    ids={rowIds}
                    getDataFromRedux={selectValueById}
                    isLoading={loading}
                    schema={[
                      NAME_COL_ID,
                      basicInfo.organizationId,
                      ...responseOrgIds.filter(
                        (id) => id !== basicInfo.organizationId,
                      ),
                    ].map((id, index) => {
                      const result = {
                        colId: String(id),
                        isI18n: false,
                        thStyle: {
                          textAlign: index === 0 ? 'left' : 'right',
                          fontSize: 10,
                        },
                        renderTh: () => <ThCell colId={id} />,
                      }

                      if (index === 0) {
                        return {
                          ...result,
                          canCustomTd: true,
                          render: (val, rowId, { style, className }) => {
                            return (
                              <FirstColumnCell
                                val={val}
                                rowId={rowId}
                                style={style}
                                className={className}
                                onRowCollapse={onRowCollapse}
                              />
                            )
                          },
                        }
                      }

                      return {
                        ...result,
                        canCustomTd: true,
                        render: (val, rowId, { style, className }) => {
                          return (
                            <OtherColumnsCell
                              val={val}
                              rowId={rowId}
                              className={className}
                              style={style}
                            />
                          )
                        },
                      }
                    })}
                    reCalcWidths={reCalcWidths}
                    hasFooter={false}
                    hasTooltip={false}
                    stickyFirstColumn
                    hasBorderTHead
                    isCollapse={true}
                    rowsCollapse={rowsCollapse}
                    levelCollapse={levelCollapse}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default TableTree
