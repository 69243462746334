import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import style from './index.module.css'
import { getTemplateRaw } from './store/slice'
import { putTemplates } from './store/thunk'

const EditName = ({ id, title, setIsShowEdit }) => {
  const dispatch = useDispatch()

  const [valueInput, setValueInput] = useState(title)

  const templateRaw = useSelector(getTemplateRaw)
  const template = templateRaw.find((item) => item.templateId === id)

  const handleBlur = () => {
    setIsShowEdit(false)
  }

  const handleSubmit = () => {
    dispatch(
      putTemplates({
        templateId: id,
        templateName: valueInput,
        templateParameters: template.templateParameters,
        publicDateId: template.publicDateId,
      }),
    )
  }

  const handleChangeInput = (e) => {
    const value = e.target.value
    setValueInput(value)
  }

  return (
    <div className={style.inputForm}>
      <input
        type="text"
        value={valueInput}
        onChange={handleChangeInput}
        className={style.inputTemplate}
        autoFocus
        onBlur={handleBlur}
      />
      <button
        onMouseDown={handleSubmit}
        className={`btn-ok cursor-pointer ${style.btnOk}`}
      >
        <i className="icon-true" />
      </button>
    </div>
  )
}

EditName.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  setIsShowEdit: PropTypes.func.isRequired,
}

export default EditName
