export const WIDTH_DROPDOWN = 250
export const HEIGHT_DROPDOWN = 350

export const KEY_ACTION = {
  SECTOR_OVERVIEW: 'SECTOR_OVERVIEW',
  FINANCIAL_ANALYSIS: 'FINANCIAL_ANALYSIS',
  SEGMENTATION: 'SEGMENTATION',
  RANKING: 'RANKING',
  RATIO: 'RATIO',
  VALUATION: 'VALUATION',
}
