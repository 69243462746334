import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import { SIZE_BYES } from '../constants'
import { changeSizeBy, selectSizeBy } from '../store/slice'
import style from './index.module.css'
import { Title } from './Title'

export const SizeBy = () => {
  const dispatch = useDispatch()
  const sizeBy = useSelector(selectSizeBy)
  return (
    <div className={style.item}>
      <Title value="market.heatmap.SIZE_BY" />
      <SelectCustom
        isI18n={true}
        value={sizeBy}
        selectData={Object.keys(SIZE_BYES).map((key) => {
          return {
            name: `market.heatmap.sizeByes.${key}`,
            value: SIZE_BYES[key],
          }
        })}
        handleChange={(value) => {
          dispatch(changeSizeBy(value))
        }}
      />
    </div>
  )
}
