import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Header from '../common/Header'
import Content from './Content'

const MoneySuppliesCredit = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(sizes) => {
          return (
            <>
              <Header title="economy.monetary.monetary.MONEY_SUPPLIES_CREDIT" />
              {sizes.height && (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: `calc(100% - ${sizes.height}px)`,
                  }}
                >
                  <Content />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}

export default MoneySuppliesCredit
