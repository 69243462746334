import PropTypes from 'prop-types'

const PREFIX_LINKS = ['http://', 'https://']

const isStartsWithMatch = (url, prefixLinks) => {
  let result = false
  prefixLinks.forEach((prefixLink) => {
    if (url.startsWith(prefixLink)) {
      result = true
    }
  })

  return result
}

export const Link = ({ url, className, style }) => {
  const getLabelAndHref = () => {
    const result = {
      href: url,
      label: url,
    }

    if (!isStartsWithMatch(url, PREFIX_LINKS)) {
      result.href = PREFIX_LINKS[PREFIX_LINKS.length - 1] + url
    }

    PREFIX_LINKS.forEach((prefixLink) => {
      if (url.startsWith(prefixLink)) {
        result.label = url.slice(prefixLink.length)
      }
    })

    return result
  }

  const { href, label } = getLabelAndHref()
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      style={style}
    >
      {label}
    </a>
  )
}

Link.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
}

Link.defaultProps = {
  url: '',
  className: '',
  style: {},
}
