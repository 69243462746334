import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { getTargetPrice } from './thunk'

const initialState = {
  data: {},
  loading: true,
}

export const slice = createSlice({
  name: 'corporate/peersValuation/consensus/consensusTargetPrice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTargetPrice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getTargetPrice.fulfilled, (state, action) => {
      state.loading = false
      state.data =
        action.payload && action.payload.length ? action.payload[0] : {}
    })
    builder.addCase(getTargetPrice.rejected, (state) => {
      state.loading = true
    })
  },
})

export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
