import { LINE_CHART_COLORS } from '../../constants'
import { NUMBER_OF_PERIOD_FIRST_TIME } from './tableGeneralIndex'

export const handleCollapseLevel = (data) => {
  if (!data.length) {
    return {}
  }

  return data.reduce((prev, cur) => {
    return {
      ...prev,
      [getRowId(cur)]: {
        level: cur.vnTypeLevel,
        isRowCollapse: cur.vnTypeLevel === 1,
      },
    }
  }, {})
}

export const handleIds = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => getRowId(item))
}

export const handleLevels = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.map((item) => {
    return {
      id: getRowId(item),
      parentId: `${item.parentVNTypeId}-${item.cType}`,
      level: item.vnTypeLevel,
    }
  })
}

export const handleTitleData = (payload) => {
  if (!payload.length) {
    return {}
  }

  return payload.reduce((previous, current) => {
    return {
      ...previous,
      [getRowId(current)]: current.vnTypeName,
    }
  }, {})
}

export const initChecked = (payload) => {
  if (!payload.length) {
    return []
  }

  return payload.slice(0, 5).map((item) => getRowId(item))
}

export const initLineColor = (checkboxChecked, tableMapping) => {
  return LINE_CHART_COLORS.map((color, index) => {
    return {
      color,
      author: checkboxChecked[index],
      indicatorName: tableMapping[checkboxChecked[index]],
    }
  })
}

export const handleLineColor = (id, lineChartColor, isChecked) => {
  if (isChecked) {
    return lineChartColor.map((color) => {
      if (color.author === id) {
        return {
          ...color,
          author: undefined,
        }
      }
      return color
    })
  } else {
    let count = 0
    return lineChartColor.map((color) => {
      if (count === 0 && color.author === undefined) {
        count++
        return {
          ...color,
          author: id,
        }
      }
      return color
    })
  }
}

export const keyById = (payload, args, prevData) => {
  const { DataType: yearlyQuarterlyValue, Limit: limit } = args
  const result = {}

  payload.forEach((item) => {
    const rowId = getRowId(item)
    const columnId = getColumnKey(yearlyQuarterlyValue, item)

    if (!result[rowId]) {
      result[rowId] = { [columnId]: item.value * 100 }
    } else {
      result[rowId][columnId] = item.value * 100
    }
  })

  return mergePrevCurrentData(prevData, result, limit)
}

const mergePrevCurrentData = (prev, current, limit) => {
  if (limit === NUMBER_OF_PERIOD_FIRST_TIME) {
    return current
  }

  const prevKeys = Object.keys(prev)
  const curKeys = Object.keys(current)
  const keys = [...new Set([...prevKeys, ...curKeys])]
  if (!curKeys.length) {
    return prev
  }

  let prevCurrentData = {}
  keys.forEach((key) => {
    prevCurrentData = {
      ...prevCurrentData,
      [key]: { ...prev[key], ...current[key] },
    }
  })
  return prevCurrentData
}

export const defineGroupColumns = (payload, args, prevColumns) => {
  const { DataType: yearlyQuarterlyValue, Limit: limit } = args

  const quarterYearNotDuplicates = addQuarter(
    handleDuplicateByKey(payload, ['realQuarter', 'realYear']),
    yearlyQuarterlyValue,
  ).sort(sortByQuarterYear)

  const columnsNumberOfPeriod =
    limit === NUMBER_OF_PERIOD_FIRST_TIME ? [] : prevColumns

  const currentColumns = quarterYearNotDuplicates.map((item) => {
    return {
      key: getColumnKey(yearlyQuarterlyValue, item),
      title: getColumnKey(yearlyQuarterlyValue, item),
    }
  })

  return [...currentColumns, ...columnsNumberOfPeriod]
}

const addQuarter = (data, yearlyQuarterlyValue) => {
  const reCalculateQuarter = {
    1: 1,
    6: 2,
    9: 3,
    5: 4,
  }
  const isAcc = yearlyQuarterlyValue === 'AccQuarterly'
  return data.map((item) => ({
    ...item,
    quarter: isAcc
      ? reCalculateQuarter[item.realQuarter] ?? item.realQuarter
      : item.realQuarter,
  }))
}

const getColumnKey = (yearlyQuarterlyValue, { realQuarter, realYear }) => {
  if (yearlyQuarterlyValue === 'AccQuarterly') {
    return formatAccMonthly(realQuarter, realYear)
  }

  if (yearlyQuarterlyValue === 'Yearly') {
    if (realQuarter < 4) {
      return `Q${realQuarter}-${realYear}`
    }

    return `${realYear}`
  }

  return `Q${realQuarter}-${realYear}`
}

const formatAccMonthly = (realQuarter, realYear) => {
  return (
    {
      1: `Q${realQuarter}-${realYear}`,
      6: `2Q-${realYear}`,
      9: `3Q-${realYear}`,
      5: `4Q-${realYear}`,
    }[realQuarter] ?? `Q${realQuarter}-${realYear}`
  )
}

const sortByQuarterYear = (a, b) => {
  if (a.realYear > b.realYear) {
    return 1
  }

  if (a.realYear < b.realYear) {
    return -1
  }

  if (a.realYear === b.realYear) {
    if (a.quarter > b.quarter) {
      return 1
    }

    if (a.quarter < b.quarter) {
      return -1
    }
  }

  return 0
}

const handleDuplicateByKey = (payload, keyArray) => {
  return payload.filter((item, index, array) => {
    return (
      index ===
      array.findIndex((element) =>
        keyArray.every((key) => item[key] === element[key]),
      )
    )
  })
}

export const getRowId = (item) => `${item.vnTypeId}-${item.cType}`

export const findMinQuarter = (payload, tab) => {
  if (!payload.length) {
    return { minYear: 0, minQuarter: 0 }
  }

  const minYear = Math.min.apply(
    Math,
    payload.map((item) => item.realYear),
  )
  if (tab === 'Yearly') return { minYear, minQuarter: 0 }

  const quarterWithMinYear = payload
    .filter((item) => item.realYear === minYear)
    .map((item) => item.realQuarter)

  return {
    minYear,
    minQuarter: Math.min.apply(Math, quarterWithMinYear),
  }
}

export const findMaxQuarter = (payload, tab) => {
  if (!payload.length) {
    return { maxYear: 0, maxQuarter: 0 }
  }

  const maxYear = Math.max.apply(
    Math,
    payload.map((item) => item.realYear),
  )
  if (tab === 'Yearly') return { maxYear, maxQuarter: 0 }

  const quarterWithMaxYear = payload
    .filter((item) => item.realYear === maxYear)
    .map((item) => item.realQuarter)

  return {
    maxYear,
    maxQuarter: Math.max.apply(Math, quarterWithMaxYear),
  }
}
