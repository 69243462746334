import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Z_INDEX } from '../../../../../constants/Common'
import { TIME_FREQUENCY, sortStateMap } from '../constants'
import { selectFilterDebtAndLoan } from '../store/slice'
import Sort from './Sort'
import style from './index.module.css'

const TableHeader = ({ tableHeaderColAttr, stateSort, sortColumn, width }) => {
  const activeTab = useSelector(selectFilterDebtAndLoan)

  const renderTitle = (text, row) => {
    return (
      <Span
        className={`${style.theadContent} ${row.isHover && style.withIcon}`}
        style={{
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'baseline',
          gap: 4,
          justifyContent: row.key !== 'name' ? 'flex-end' : 'flex-start',
        }}
      >
        {text}
        {new Date().getFullYear() === parseInt(text) &&
          activeTab === TIME_FREQUENCY.YEARLY &&
          '(YTD)'}
      </Span>
    )
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.POPUP_INFO_USER }}
    >
      <tr>
        {tableHeaderColAttr.map((row, index) => {
          return (
            <th
              key={`key-${index}`}
              {...row?.attrs}
              className={`${!row.value?.length && style.borderBottomNone} ${
                !row.additionText && style.verticalAlignInitial
              } ${index === 0 && style.cellLeft} ${style.firstCellHeader} ${
                row.alignRight && style.textRight
              } ${style.noPadding}`}
              onClick={() => {
                if (row?.disableSort) return

                sortColumn({
                  [row.keySort]: sortStateMap[stateSort[row.keySort]],
                })
              }}
            >
              <div
                className={stateSort[row?.keySort] ? 'mr-5' : undefined}
                style={{ padding: '5px 8px', boxSizing: 'border-box' }}
              >
                {renderTitle(row.title, row)}

                {row.additionText && (
                  <div
                    className={style.additionText}
                    style={{ fontSize: 10, fontWeight: 'normal' }}
                  >
                    <Translate value={row.additionText} />
                  </div>
                )}

                {!row?.disableSort && !row?.hideIconSort && (
                  <Sort type={stateSort[row.keySort]} />
                )}
              </div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

export default TableHeader
