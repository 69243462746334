import { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import { SelectSearch } from '../../../../common/selectCustom/SelectSearch'
import TextEllipsis from '../../../../common/textEllipsis'
import { removeAccents, removeSpecialCharacter } from '../../../../utils/Search'
import { selectAssetById, selectAssets } from '../store/slice'

export const StockCode = ({ value, onChange }) => {
  const assets = useSelector(selectAssets)
  const assetById = useSelector(selectAssetById)

  const [search, setSearch] = useState('')

  // Get data
  const getAssets = useMemo(() => {
    if (!search) return assets

    return assets.filter((item) => {
      return (
        removeSpecialCharacter(removeAccents(item.ticker))
          .toLowerCase()
          .includes(
            removeSpecialCharacter(removeAccents(search)).trim().toLowerCase(),
          ) ||
        removeSpecialCharacter(removeAccents(item.name))
          .toLowerCase()
          .includes(
            removeSpecialCharacter(removeAccents(search)).trim().toLowerCase(),
          )
      )
    })
  }, [search, JSON.stringify(assets)])

  return (
    <div className="mr-16">
      <SelectSearch
        value={value}
        width={220}
        isSearch
        options={getAssets}
        selectedName={assetById[value]?.ticker}
        renderName={(item) => (
          <div className="w-100 d-flex ali-center justify-content-space-between">
            <div className="mr-16" style={{ width: '10%' }}>
              <Span>{item.ticker}</Span>
            </div>
            <div style={{ width: '70%' }}>
              <TextEllipsis
                text={item.name}
                zIndexTooltip={999}
                isI18n={false}
              />
            </div>
          </div>
        )}
        onSearch={setSearch}
        onChange={(value) => onChange({ assetId: value })}
      />
    </div>
  )
}
