import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import {
  getQuarterAndYearKey,
  sortObjByQuarterAndYear,
} from '../../../common/helper'
import {
  QUARTER_SELECTION_MONTHLY,
  RADIO_VALUE,
  RIGHT_FRAME_OPTIONS,
  RIGHT_FRAME_OPTIONS_YEARLY,
  TYPE_CHART_SOCIAL_INVESTMENT,
} from '../constants'
import {
  getMaxPeriod,
  getTotalInvesmentCapitalOfTheStateSectorChart,
  getTotalInvesmentCapitalUnderTheStateBudgetChart,
  getTotalSocialInvesmentCapitalChart,
} from './thunk'

const initialState = {
  typeChartValue: TYPE_CHART_SOCIAL_INVESTMENT.TOTAL_SOCIAL,
  timeFrame: RIGHT_FRAME_OPTIONS[0].value,
  timeFrameYearly: RIGHT_FRAME_OPTIONS_YEARLY[0].value,
  radioValue: RADIO_VALUE.VALUE,
  yearDropdownValue: null,
  stackChartData: [],
  stackChartLoading: true,
  ids: [],
  nameById: [],
  currentQuarter: null,
  stateSectorKeys: [],
  isYearly: true,
  isAccQuarterly: false,
  maxDate: null,
}

const slice = createSlice({
  name: 'economy/fiscal/stateBudget/investmentStructure',
  initialState,
  reducers: {
    handleTypeChartValue: (state, action) => {
      state.typeChartValue = action.payload
    },
    handleTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
    handleTimeFrameYearly: (state, action) => {
      state.timeFrameYearly = action.payload
    },
    handleRadioValue: (state, action) => {
      state.radioValue = action.payload
    },
    handleYearDropdownValue: (state, action) => {
      state.yearDropdownValue = action.payload
    },
    handleCurrentQuarter: (state, action) => {
      state.currentQuarter = action.payload
    },
    changeIsYearlyInvestmentStructureChart: (state, action) => {
      state.isYearly = action.payload
    },
    changeIsAccQuarterlyInvestmentStructureChart: (state, action) => {
      state.isAccQuarterly = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTotalSocialInvesmentCapitalChart.pending, (state) => {
      state.stackChartLoading = true
    })
    builder.addCase(
      getTotalSocialInvesmentCapitalChart.fulfilled,
      (state, action) => {
        state.stackChartLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.investmentConstructionVNTypeId,
            name: item.investmentConstructionVNTypeName,
            value:
              state.radioValue === RADIO_VALUE.VALUE
                ? item.value / 1000000000
                : item.percentage * 100,
          })) || []
        state.stackChartData = convertData(
          payloadData,
          state.isYearly,
          state.isAccQuarterly,
        )
        state.nameById = Object.values(
          payloadData
            .map((item) => ({ name: item.name, id: item.id }))
            .reduce((acc, curr) => {
              acc[curr.name + '|' + curr.id] = curr
              return acc
            }, {}),
        )
        state.ids = [...new Set(payloadData.map((item) => item.id))]
      },
    )
    builder.addCase(getTotalSocialInvesmentCapitalChart.rejected, (state) => {
      state.stackChartLoading = false
    })

    builder.addCase(
      getTotalInvesmentCapitalUnderTheStateBudgetChart.pending,
      (state) => {
        state.stackChartLoading = true
      },
    )
    builder.addCase(
      getTotalInvesmentCapitalUnderTheStateBudgetChart.fulfilled,
      (state, action) => {
        state.stackChartLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.investmentConstructionVNTypeId,
            name: item.investmentConstructionVNTypeName,
            value: item.value / 1000000000,
          })) || []
        state.stackChartData = payloadData
      },
    )
    builder.addCase(
      getTotalInvesmentCapitalUnderTheStateBudgetChart.rejected,
      (state) => {
        state.stackChartLoading = false
      },
    )

    builder.addCase(
      getTotalInvesmentCapitalOfTheStateSectorChart.pending,
      (state) => {
        state.stackChartLoading = true
      },
    )
    builder.addCase(
      getTotalInvesmentCapitalOfTheStateSectorChart.fulfilled,
      (state, action) => {
        state.stackChartLoading = false
        const payloadData =
          action.payload?.map((item) => ({
            ...item,
            id: item.investmentConstructionVNTypeId,
            name: item.investmentConstructionVNTypeName,
            value: item.value / 1000000000,
          })) || []

        const allQuartersAndYears = payloadData?.map((item) => ({
          quarter: item.quarter,
          year: item.year,
        }))
        const uniqueQuartersAndYears = Object.values(
          allQuartersAndYears.reduce((acc, curr) => {
            acc[curr.quarter + '|' + curr.year] = curr
            return acc
          }, {}),
        )
        const isYearlyKey =
          state.isYearly ||
          state.currentQuarter === QUARTER_SELECTION_MONTHLY[0].value
        const uniqueQuartersAndYearsKeys = sortObjByQuarterAndYear(
          uniqueQuartersAndYears,
          true,
        ).map((item) =>
          getQuarterAndYearKey(
            item.quarter,
            item.year,
            isYearlyKey,
            state.isAccQuarterly,
          ),
        )
        state.stateSectorKeys = uniqueQuartersAndYearsKeys
        state.stackChartData = convertStateSectorData(
          payloadData,
          state.isYearly,
          state.currentQuarter,
          state.isAccQuarterly,
        )
      },
    )
    builder.addCase(
      getTotalInvesmentCapitalOfTheStateSectorChart.rejected,
      (state) => {
        state.stackChartLoading = false
      },
    )

    builder.addCase(getMaxPeriod.pending, (state) => {
      state.maxDate = null
    })
    builder.addCase(getMaxPeriod.fulfilled, (state, action) => {
      state.maxDate = action.payload
    })
    builder.addCase(getMaxPeriod.rejected, (state) => {
      state.maxDate = null
    })
  },
})

export const {
  handleTypeChartValue,
  handleTimeFrame,
  handleRadioValue,
  handleYearDropdownValue,
  handleCurrentQuarter,
  changeIsYearlyInvestmentStructureChart,
  changeIsAccQuarterlyInvestmentStructureChart,
  handleTimeFrameYearly,
} = slice.actions

export const getTypeChartValue = (state) => state[slice.name].typeChartValue
export const getTimeFrame = (state) => state[slice.name].timeFrame
export const getTimeFrameYearly = (state) => state[slice.name].timeFrameYearly
export const getRadioValue = (state) => state[slice.name].radioValue
export const getStackChartData = (state) => state[slice.name].stackChartData
export const getYearDropdownValue = (state) =>
  state[slice.name].yearDropdownValue
export const getStackChartLoading = (state) =>
  state[slice.name].stackChartLoading
export const selectIds = (state) => state[slice.name].ids
export const selectNameById = (state) => state[slice.name].nameById
export const selectCurrentQuarter = (state) => state[slice.name].currentQuarter
export const selectStateSectorKeys = (state) =>
  state[slice.name].stateSectorKeys
export const selectMaxDate = (state) => state[slice.name].maxDate

register(slice.name, slice.reducer)

const convertData = (data, isYearly, isAccQuarterly) => {
  const allQuartersAndYears = data?.map((item) => ({
    quarter: item.quarter,
    year: item.year,
  }))

  const uniqueQuartersAndYears = Object.values(
    allQuartersAndYears.reduce((acc, curr) => {
      acc[curr.quarter + '|' + curr.year] = curr
      return acc
    }, {}),
  )

  const uniqueQuartersAndYearsKeys = uniqueQuartersAndYears.map((item) =>
    getQuarterAndYearKey(item.quarter, item.year, isYearly, isAccQuarterly),
  )

  const getDataByQuarterAndYear = (quarter, year) => {
    return data
      .filter((item) => item.quarter === quarter && item.year === year)
      .map((item) => ({
        value: item.value,
        id: item.id,
        name: item.name,
      }))
  }

  const getYearValueFromRange = uniqueQuartersAndYears.map((item) => [
    ...getDataByQuarterAndYear(item.quarter, item.year),
  ])

  const getDataKeyIdValueFromRange = getYearValueFromRange.length
    ? getYearValueFromRange.map((item) =>
        item.map((e) => {
          return { [e.id]: e.value }
        }),
      )
    : []

  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...item,
    time: uniqueQuartersAndYearsKeys[i],
  }))
}

const convertStateSectorData = (
  data,
  isYearly,
  currentQuarterSelected,
  isAccQuarterly,
) => {
  const allId = [...new Set(data?.map((item) => item.id))]

  const getNameById = (id) => {
    return data?.find((item) => item.id === id).name || ''
  }

  const isYearlyKey =
    isYearly || currentQuarterSelected === QUARTER_SELECTION_MONTHLY[0].value
  const getDataById = (id) => {
    return data
      .filter((item) => item.id === id)
      .map((item) => ({
        value: item.value,
        quarter: item.quarter,
        year: item.year,
        time: getQuarterAndYearKey(
          item.quarter,
          item.year,
          isYearlyKey,
          isAccQuarterly,
        ),
      }))
  }

  const getValueOfAllId = allId.map((item) => [...getDataById(item)])

  const getDataKeyIdValueFromRange = getValueOfAllId.length
    ? getValueOfAllId.map((item) =>
        item.map((e) => {
          return { [e.time]: e.value }
        }),
      )
    : []

  const allQuartersAndYears = data?.map((item) => ({
    quarter: item.quarter,
    year: item.year,
  }))

  const uniqueQuartersAndYears = Object.values(
    allQuartersAndYears.reduce((acc, curr) => {
      acc[curr.quarter + '|' + curr.year] = curr
      return acc
    }, {}),
  )
  const uniqueQuartersAndYearsKeys = sortObjByQuarterAndYear(
    uniqueQuartersAndYears,
    true,
  ).map((item) =>
    getQuarterAndYearKey(item.quarter, item.year, isYearlyKey, isAccQuarterly),
  )

  const arrOfDataKeyIdValueFromRange = getDataKeyIdValueFromRange.map((item) =>
    Object.assign({}, ...item),
  )

  const reOrderedObj =
    uniqueQuartersAndYearsKeys.length === 2
      ? {
          [uniqueQuartersAndYearsKeys[0]]: null,
          [uniqueQuartersAndYearsKeys[1]]: null,
        }
      : {}

  return arrOfDataKeyIdValueFromRange.map((item, i) => ({
    ...Object.assign(reOrderedObj, item),
    id: allId[i],
    name: getNameById(allId[i]),
  }))
}
