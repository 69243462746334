import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { TIME_SELECT, VALUE_TYPE } from '../../constants'
import { getStatisticsValueByInvestorChart } from './thunk'

const slice = createSlice({
  name: 'market/marketInDepth/indexStatistics/summary/valueInvestor',
  initialState: {
    data: [],
    valueType: VALUE_TYPE.NET_VALUE,
    dateTime: TIME_SELECT.ONE_WEEK,
    loading: true,
  },
  reducers: {
    changeValueType: (state, action) => {
      state.valueType = action.payload
    },
    changeDateTime: (state, action) => {
      state.dateTime = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStatisticsValueByInvestorChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getStatisticsValueByInvestorChart.fulfilled,
      (state, action) => {
        state.loading = false
        state.data = [...action.payload.data]
          .sort(
            (a, b) =>
              new Date(a.tradingDateId).getTime() -
              new Date(b.tradingDateId).getTime(),
          )
          .filter(
            (item, index, arr) =>
              arr.findIndex((el) => el.tradingDateId === item.tradingDateId) ===
              index,
          )
      },
    )
    builder.addCase(
      getStatisticsValueByInvestorChart.rejected,
      (state, action) => {
        state.loading = action.payload.loading
      },
    )
  },
})

export const { changeValueType, changeDateTime } = slice.actions
export const selectValueType = (state) => state[slice.name].valueType
export const selectDateTime = (state) => state[slice.name].dateTime
export const selectData = (state) => state[slice.name].data
export const selectLoading = (state) => state[slice.name].loading

register(slice.name, slice.reducer)
