import { ServiceProxyConfig } from '../../../configs/ServiceProxy'
import ProxyBase from '../ProxyBase'

class OverviewProxy extends ProxyBase {
  getBasicInfo(params) {
    return this.get('GetBasicInfo', params)
  }

  getSummaryInfo(params) {
    return this.get('GetSummaryInfo', params)
  }

  getNewsInfo(params) {
    return this.get('GetNewsInfo', params)
  }

  getFinancialMetricsDataArea(params) {
    return this.get('GetFinancialMetricsDataArea', params)
  }

  getKeyIndicators(params) {
    return this.get('GetKeyIndicators', params)
  }

  getFinancialMetricsChartData(params) {
    return this.get('GetFinancialMetricsChartData', params)
  }

  getFinancialMetricsComparisonChartData(params) {
    return this.get('GetFinancialMetricsComparisonChartData', params)
  }

  downloadSummaryInfo(params) {
    return this.get('DownloadSummaryInfo', params, 'download')
  }

  downloadKeyIndicators(params) {
    return this.get('DownloadKeyIndicators', params, 'download')
  }

  downloadFinancialMetrics(params) {
    return this.get('DownloadFinancialMetrics', params, 'download')
  }
}

export default new OverviewProxy(ServiceProxyConfig.Corporate.ServiceUrl)
