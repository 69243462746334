import UserBondCashFlowProxy from '../../../proxies/bond/valuationProxy/userBondCashFlowProxy'
import { ServiceBase } from '../../ServiceBase'

export class UserBondCashFlow extends ServiceBase {
  getList(params) {
    return this.getData(() =>
      UserBondCashFlowProxy.getListUserBondCashFlow(params),
    )
  }
  checkName(params) {
    return this.getData(
      () => UserBondCashFlowProxy.checkNameProxy(params),
      true,
    )
  }
  addNew(params) {
    return this.getData(() => UserBondCashFlowProxy.addNewProxy(params), true)
  }
  updateList(params) {
    return this.getData(() => UserBondCashFlowProxy.updateProxy(params), true)
  }
  deleteRow(params) {
    return this.getData(
      () => UserBondCashFlowProxy.deleteRowProxy(params),
      true,
    )
  }
}

export default new UserBondCashFlow()
