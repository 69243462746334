export const EMPTY_VALUE = '--'

export const Z_INDEX = {
  STICKY_CELL: 1,
  STICKY_THEAD: 2,
  TH_TABLE_STICKY_FIRST_COLUMN: 3,
  PANEL_RESIZE: 4,
  SCROLL_COMPONENT: 5,
  MODAL: 6,
  DATE_PICKER: 7,
  LOADING: 10,
  ZOOM_WINDOW: 11,
  DROPDOWN: 12,
  TOOLTIP_TABLE_HEAD: 3,
  COMMON_CSS_OVERLAY: 9999,
  POPUP_INFO_USER: 101,
}

export const LOCAL_STORAGE_KEY = {
  OPEN_WINDOW: 'openWindow',
  KEY_LANGUAGE: 'language',
  RECENT_SEARCH_INDEX: 'recentSearchIndex',
  KEY_TIMEZONE: 'timeZone',
  DEVICE_KEY: 'fiinx.deviceKey.',
  AUTH_TIME: 'fiinx.authTime.',
  KEY_FONT_SIZE: 'fontSize',
  RETURN_URL: 'returnUrl',
  SIGNIN_COUNT: 'signinCount',
}

export const HEADER_PANEL_HEIGHT = 41 // include header panel + PANEL_PADDING
export const PANEL_PADDING = 16 // padding left + padding right === padding top + padding bottom

export const screens = {
  MY_WATCHLIST: 'my_watchlist',
  OVERVIEW: 'overview',
  TOOL_SMART_SCREENING: 'tool_smart_screening',
  CORPORATE_EVENT: 'corporate_event',
  DE_CORPORATE: 'de_corporate',
  SECTOR_IN_DEPTH: 'sector_in_depth',
}

export const GROUP_TYPE = {
  STOCK: 'Stock',
  INDEX: 'Index',
  FUTURE: 'Future',
  COVERED_WARRANT: 'CoveredWarrant',
  SECTOR: 'Sector',
  FUND: 'Fund',
}

export const MONTH_OPTIONS = [
  { name: 'common.MONTHLY_OPTION.MONTH_1', value: 1 },
  { name: 'common.MONTHLY_OPTION.MONTH_2', value: 2 },
  { name: 'common.MONTHLY_OPTION.MONTH_3', value: 3 },
  { name: 'common.MONTHLY_OPTION.MONTH_4', value: 4 },
  { name: 'common.MONTHLY_OPTION.MONTH_5', value: 5 },
  { name: 'common.MONTHLY_OPTION.MONTH_6', value: 6 },
  { name: 'common.MONTHLY_OPTION.MONTH_7', value: 7 },
  { name: 'common.MONTHLY_OPTION.MONTH_8', value: 8 },
  { name: 'common.MONTHLY_OPTION.MONTH_9', value: 9 },
  { name: 'common.MONTHLY_OPTION.MONTH_10', value: 10 },
  { name: 'common.MONTHLY_OPTION.MONTH_11', value: 11 },
  { name: 'common.MONTHLY_OPTION.MONTH_12', value: 12 },
]
