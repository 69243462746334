import { rgbaToAlpha } from '../../../utils/Color'
import { getFontSize } from '../../../utils/FontSize'
import { formatVal } from '../../../utils/Value'
import { Span } from '../../html/Span'
import TextEllipsis from '../../textEllipsis'
import { adjustPosition, getTextHeight } from '../helper'
import { getLatestData } from '../rechart/helper'
import {
  REF_LABEL_WIDTH,
  SPACE_BETWEEN_REF_AND_YAXIS_LABEL,
  TICK_MARGIN_YAXIS,
} from './constant'
import style from './referenceLabel.module.css'

const ReferenceLabel = (props) => {
  const { data, yAxisMap, charts, typeFontSize, typeLegendYAxis, yAxisLabel } =
    props

  const { fontSize, bold } = yAxisLabel

  const textFontSize = getFontSize(10, typeFontSize)
  const labelHeight =
    typeLegendYAxis === 'TYPE_2' ? (textFontSize + 6) * 2 : textFontSize + 6
  const statisticalMethodYAxisIds = charts.reduce(
    (arr, item) => (item.statisticalMethods ? [...arr, item.yAxisId] : arr),
    [],
  )

  return Object.values(yAxisMap)
    .filter(
      ({ orientation, yAxisId }) =>
        orientation === 'right' || statisticalMethodYAxisIds.includes(yAxisId),
    )
    .map((yAxis) => {
      const {
        x,
        dataKeys,
        height,
        domain,
        scale,
        decimalLength,
        width,
        orientation,
        yAxisId,
      } = yAxis
      const formatDecimalLength =
        typeof decimalLength === 'number' ? decimalLength : 2

      let acceptDataKeys = []

      if (orientation === 'left') {
        acceptDataKeys = charts.reduce(
          (arr, item) =>
            item.yAxisId === yAxisId &&
            item.referenceLineYAxisId &&
            !item.statisticalMethods
              ? [...arr, item.dataKey]
              : arr,
          [],
        )
      } else {
        acceptDataKeys = [
          ...dataKeys,
          ...charts.reduce(
            (arr, item) =>
              item.yAxisId === yAxisId &&
              item.referenceLineYAxisId &&
              !item.statisticalMethods
                ? [...arr, item.dataKey]
                : arr,
            [],
          ),
        ]
      }
      const latestData = getLatestData({ data, keys: acceptDataKeys.flat() })
      const sortData = Object.keys(latestData)
        .map((key) => ({ key, val: latestData[key] }))
        .sort((a, b) => a.val - b.val)
      const adjustedData = adjustPosition(
        sortData,
        height,
        labelHeight,
        domain[1],
        domain[0],
      )

      return adjustedData.map(({ key, val }) => {
        const chart = charts.find((chart) => chart.dataKey === key)
        const isStatisticalMethod =
          chart?.referenceLineYAxisId && !chart?.statisticalMethods
        const bgColor = chart?.color
        const name = chart?.formatName

        const getStrStyle = (labelHeight, bgColor, strIdKey) => {
          const styles = []
          styles.push('#' + strIdKey + ':after {')
          styles.push(
            `border-color: transparent ${bgColor} transparent transparent;`,
          )
          styles.push(
            `border-width: ${labelHeight / 2}px ${labelHeight / 2}px ${
              labelHeight / 2
            }px 0px`,
          )
          styles.push('}')
          return styles.join('\n')
        }

        const widthLabel =
          Math.max(width, REF_LABEL_WIDTH) -
          getTextHeight(name, fontSize, bold ? 600 : 400) -
          TICK_MARGIN_YAXIS -
          SPACE_BETWEEN_REF_AND_YAXIS_LABEL

        const render = (strIdKey) => {
          if (isStatisticalMethod) {
            return (
              <div
                id={strIdKey}
                className="h-100"
                style={{
                  paddingRight: orientation === 'left' ? 16 : 3,
                  paddingLeft: orientation === 'left' ? 16 : 3,
                  color: bgColor,
                }}
              >
                <div
                  className="h-100 d-flex j-b"
                  style={{
                    width: Math.max(width, REF_LABEL_WIDTH) - 6,
                    lineHeight: labelHeight + 'px',
                    gap: 3,
                  }}
                >
                  <Span style={{ fontSize: 12, fontWeight: 600 }}>
                    {formatVal(latestData[key], formatDecimalLength)}
                  </Span>
                </div>
              </div>
            )
          }

          switch (typeLegendYAxis) {
            case 'TYPE_1':
              return (
                <div
                  id={strIdKey}
                  className="h-100 w-100"
                  style={{
                    backgroundColor: bgColor,
                    padding: '0px 3px',
                    color: getColorTextByBackgroundColor(bgColor),
                  }}
                >
                  <div
                    className="h-100 d-flex j-b"
                    style={{
                      width: widthLabel - 6,
                      lineHeight: labelHeight + 'px',
                      gap: 3,
                    }}
                  >
                    <div className="flex-1" style={{ minWidth: 0 }}>
                      <Span style={{ fontSize: 10 }}>
                        <TextEllipsis text={name} />
                      </Span>
                    </div>
                    <Span style={{ fontSize: 10 }}>
                      {formatVal(latestData[key], formatDecimalLength)}
                    </Span>
                  </div>
                </div>
              )
            case 'TYPE_2':
              return (
                <div
                  id={strIdKey}
                  className="h-100 w-100"
                  style={{
                    backgroundColor: bgColor,
                    padding: '0px 3px',
                    color: getColorTextByBackgroundColor(bgColor),
                  }}
                >
                  <div
                    className="h-100"
                    style={{
                      width: widthLabel - 6,
                      textAlign: 'center',
                    }}
                  >
                    <div
                      style={{
                        minWidth: 0,
                        lineHeight: labelHeight / 2 + 'px',
                      }}
                    >
                      <Span style={{ fontSize: 10 }}>
                        <TextEllipsis text={name} />
                      </Span>
                    </div>
                    <Span style={{ fontSize: 10 }}>
                      {formatVal(latestData[key], formatDecimalLength)}
                    </Span>
                  </div>
                </div>
              )
            case 'TYPE_3':
              return (
                <div
                  id={strIdKey}
                  className="h-100 w-100"
                  style={{
                    backgroundColor: bgColor,
                    padding: '0px 3px',
                    color: getColorTextByBackgroundColor(bgColor),
                  }}
                >
                  <div
                    className="h-100 d-flex j-b"
                    style={{
                      width: widthLabel - 6,
                    }}
                  >
                    <div className="flex-1 " style={{ minWidth: 0 }}>
                      <Span style={{ fontSize: 10 }}>
                        <TextEllipsis text={name} />
                      </Span>
                    </div>
                  </div>
                </div>
              )
            case 'TYPE_4':
              return (
                <div
                  id={strIdKey}
                  className="h-100 w-100"
                  style={{
                    backgroundColor: bgColor,
                    padding: '0px 3px',
                    color: getColorTextByBackgroundColor(bgColor),
                  }}
                >
                  <div
                    className="h-100 d-flex j-b"
                    style={{
                      width: Math.max(width, REF_LABEL_WIDTH) - 6,
                      lineHeight: labelHeight + 'px',
                    }}
                  >
                    <div className="flex-1" style={{ minWidth: 0 }}>
                      <Span style={{ fontSize: 10 }}>
                        {formatVal(latestData[key], formatDecimalLength)}
                      </Span>
                    </div>
                  </div>
                </div>
              )
            case 'TYPE_5':
              return (
                <>
                  <div
                    id={strIdKey}
                    className={`h-100 w-100 special-div ${style.triangle}`}
                    style={{
                      color: getColorTextByBackgroundColor(bgColor),
                    }}
                  >
                    <div
                      className="d-flex"
                      style={{
                        float: 'right',
                        width: widthLabel + 4 - textFontSize,
                        height: labelHeight + 'px',
                        backgroundColor: bgColor,
                      }}
                    >
                      <div
                        className="align-center"
                        style={{ minWidth: 0, paddingLeft: 6 }}
                      >
                        <Span style={{ fontSize: 10 }}>
                          {formatVal(latestData[key], formatDecimalLength)}
                        </Span>
                      </div>
                    </div>
                  </div>
                  <style
                    dangerouslySetInnerHTML={{
                      __html: getStrStyle(labelHeight, bgColor, strIdKey),
                    }}
                  />
                </>
              )
            default:
              return <></>
          }
        }
        return (
          <g key={key}>
            <foreignObject
              x={x + TICK_MARGIN_YAXIS}
              y={scale(val) - labelHeight / 2}
              height={labelHeight}
              width={widthLabel}
            >
              {render('id-' + key)}
            </foreignObject>
          </g>
        )
      })
    })
}

export default ReferenceLabel

const getColorTextByBackgroundColor = (hex) => {
  let r = 0,
    g = 0,
    b = 0,
    a = 1

  if (hex.length === 3) {
    r = parseInt(hex.slice(1, 2), 16)
    g = parseInt(hex.slice(2, 3), 16)
    b = parseInt(hex.slice(3, 4), 16)
  } else if (hex.length === 7) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
  } else if (hex.length === 9) {
    r = parseInt(hex.slice(1, 3), 16)
    g = parseInt(hex.slice(3, 5), 16)
    b = parseInt(hex.slice(5, 7), 16)
    a = rgbaToAlpha(parseInt(hex.slice(7, 9), 16))
  }

  if ((r * 0.299 + g * 0.587 + b * 0.114) * a > 186) {
    return '#000000'
  } else {
    return '#ffffff'
  }
}
