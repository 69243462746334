import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Z_INDEX } from '../../constants/Common'

const Popup = (props) => {
  const [modalRootNode, setModalRootNode] = useState()

  useEffect(() => {
    const newModalRoot = document.createElement('div')
    document.body.appendChild(newModalRoot)
    setModalRootNode(newModalRoot)
    return () => {
      document.body.removeChild(newModalRoot)
    }
  }, [])

  const onClick = (e) => {
    e.stopPropagation()
  }

  const onMouseEnter = (e) => {
    e.stopPropagation()
  }

  return (
    <>
      {modalRootNode &&
        createPortal(
          <div
            style={{
              zIndex: props.zIndexOverlay || undefined,
              ...props.appendStyle,
            }}
            className={`overlay ${props.overlayCustom}`}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
          >
            <div style={{ zIndex: props.zIndex }} className="modal-wrapper">
              {props.children}
            </div>
          </div>,
          modalRootNode,
        )}
    </>
  )
}

Popup.propTypes = {
  zIndex: PropTypes.number,
  children: PropTypes.element.isRequired,
  zIndexOverlay: PropTypes.number,
  appendStyle: PropTypes.object,
  overlayCustom: PropTypes.string,
}

Popup.defaultProps = {
  zIndex: Z_INDEX.MODAL,
  appendStyle: {},
}

export default Popup
