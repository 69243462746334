import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../common/ScrollComponent'
import { STATEMENT_CONSTANTS } from '../constants'
import { selectStatement } from '../store/slice'
import { FILTER_OPTIONS } from './constants'
import { FilterInputRatioItem } from './FilterInputRatioItem'
import FilterOptionItem from './FilterOptionItem'
import style from './index.module.css'

export const FinancialStatementFilter = ({ height }) => {
  const statement = useSelector(selectStatement)

  // Check value
  const isDisabledRatio = (field, value) => {
    return (
      statement.value === STATEMENT_CONSTANTS.NOTE &&
      field === 'commonSize' &&
      value === 'true'
    )
  }

  // Use effect
  useEffect(() => {
    if (statement.value === STATEMENT_CONSTANTS.NOTE) {
      document.getElementById('commonSize-false').click()
    }
  }, [statement.value])

  return (
    <ScrollComponent autoHeight={true} autoHeightMax={height || 0}>
      <div className="h-100">
        {FILTER_OPTIONS.map((option) => (
          <div key={option.field} className={style['filter-item']}>
            {option.type === 'ratio' ? (
              <FilterInputRatioItem
                key={option.field}
                data={option}
                disabled={(value) => isDisabledRatio(option.field, value)}
              />
            ) : (
              <FilterOptionItem key={option.field} data={option} />
            )}
          </div>
        ))}
      </div>
    </ScrollComponent>
  )
}

FinancialStatementFilter.propTypes = {
  height: PropTypes.number.isRequired,
}
