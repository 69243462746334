import moment from 'moment'
import { useMemo } from 'react'
import { Area, AreaChart } from 'recharts'

export const RankStatusChart = ({ data }) => {
  // Get data
  const getData = useMemo(() => {
    return [...data]
      .sort((a, b) => {
        if (a.realQuarter && b.realQuarter) {
          return (
            moment().set('year', a.realYear).quarter(a.realQuarter).unix() -
            moment().set('year', b.realYear).quarter(b.realQuarter).unix()
          )
        }

        return a.periodId - b.periodId
      })
      .map((item) => ({
        label: item.periodId,
        value: item.rankValue,
      }))
  }, [data])

  return (
    <div className="h-100 centerItems">
      <AreaChart
        width={70}
        height={25}
        data={getData}
        margin={{ top: 5, right: 0, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="25%" stopColor="#80ff6d" stopOpacity={1} />
            <stop offset="100%" stopColor="#2d6175" stopOpacity={1} />
          </linearGradient>
        </defs>
        <Area
          type="basis"
          dataKey="value"
          stroke="#96ff34"
          fillOpacity={0.8}
          fill="url(#color)"
          activeDot={false}
        />
      </AreaChart>
    </div>
  )
}
