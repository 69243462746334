import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SecondaryOverviewService from '../../../../../../core/services/bond/corporateBond/secondaryMarket/SecondaryOverviewService'
import { handleExport } from '../../../../../utils/Export'

export const getTopLiquidityDataByIssuer = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_LIQUIDITY_BY_ISSUER_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SecondaryOverviewService.getTopLiquidityDataByIssuer(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTopLiquidityDataByBond = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_LIQUIDITY_BY_BOND_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopLiquidityDataByBond(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getAverageYTMofIssuer = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_AVERAGE_YTM_OF_ISSUER_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getAverageYTMofIssuer(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTopInterestRate = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_INTEREST_RATE_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopInterestRate(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTopInterestRateAsk = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_INTEREST_RATE_ASK_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopInterestRateAsk(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getMarketLiquidityChartData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_MARKET_LIQUIDITY_CHART_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getMarketLiquidityChart(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadTopLiquidityByIssuer = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_TOP_LIQUIDITY_DATA_BY_ISSUER',
  async (data) => {
    const response =
      await SecondaryOverviewService.downloadTopLiquidityByIssuer(data)
    handleExport(response.data, response.filename)
  },
)
export const downloadTopLiquidityByBond = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_TOP_LIQUIDITY_DATA_BY_BOND',
  async (data) => {
    const response = await SecondaryOverviewService.downloadTopLiquidityByBond(
      data,
    )
    handleExport(response.data, response.filename)
  },
)
export const downloadAverageYTMofIssuer = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_AVERAGE_YTM_DATA_BY_ISSUER',
  async (data) => {
    const response = await SecondaryOverviewService.downloadAverageYTMofIssuer(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const downloadTopInterestRateData = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/DOWNLOAD_AVERAGE_YTM_DATA_BY_ISSUER',
  async (data) => {
    const response = await SecondaryOverviewService.downloadTopInterestRate(
      data,
    )
    handleExport(response.data, response.filename)
  },
)

export const getTopLiquidityDataByIssuerByReload = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_LIQUIDITY_BY_ISSUER_DATA_BY_RELOAD',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SecondaryOverviewService.getTopLiquidityDataByIssuer(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTopLiquidityDataByBondByReload = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_LIQUIDITY_BY_BOND_DATA_BY_RELOAD',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopLiquidityDataByBond(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getTopInterestRateByReload = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_INTEREST_RATE_DATA_BY_RELOAD',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopInterestRate(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
export const getTopInterestRateAskByReload = createAsyncThunk(
  'bond/corporateBond/secondaryMarket/GET_TOP_INTEREST_RATE_ASK_DATA_BY_RELOAD',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SecondaryOverviewService.getTopInterestRateAsk(
        params,
      )

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
