import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InterconnectionMapSv from '../../../../../../../core/services/bond/interconnectionMap'

export const getDataThunk = createAsyncThunk(
  'bond/interConnectionMap/filter/GET_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await InterconnectionMapSv.getInterConnectionMapSv(
        params,
      )
      if (response.success) {
        const data = response?.data || {}
        return data
      }
      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)
