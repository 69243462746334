import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { selectMoreCategories } from '../store/slice'

export const Title = () => {
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const type = params.get('type')
  const newsCategories = useSelector(selectMoreCategories)

  const [category] = newsCategories.filter(
    (item) => item.newsCategoryId.toString() === type,
  )

  return <span>{category?.newsCategoryName}</span>
}
