export const indicator = {
  NAME: 'name',
  MWG: 'MWG.value',
  INDUSTRY: 'INDUSTRY.value',
}

export const IndicatorTableConstant = {
  bank: [
    {
      index: 'market_cap',
      name: 'corporate.overview.keyIndicators.bank.Market Cap (Billion VND)',
      MWG: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/E',
      name: 'corporate.overview.keyIndicators.bank.P/E',
      MWG: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/B',
      name: 'corporate.overview.keyIndicators.bank.P/B',
      MWG: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'ROE',
      name: 'corporate.overview.keyIndicators.bank.ROE',
      MWG: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROA',
      name: 'corporate.overview.keyIndicators.bank.ROA',
      MWG: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'preprovision_roa',
      name: 'corporate.overview.keyIndicators.bank.Preprovision ROA',
      MWG: {
        key: 'rT0288',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0288',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'EPS',
      name: 'corporate.overview.keyIndicators.bank.EPS',
      MWG: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'BVPS',
      name: 'corporate.overview.keyIndicators.bank.BVPS',
      MWG: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'NIM',
      name: 'corporate.overview.keyIndicators.bank.NIM',
      MWG: {
        key: 'rT0285',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0285',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'NPL',
      name: 'corporate.overview.keyIndicators.bank.NPL',
      MWG: {
        key: 'rT0269',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0269',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
  ],
  securities: [
    {
      index: 'market_cap',
      name: 'corporate.overview.keyIndicators.security.Market Cap (Billion VND)',
      MWG: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'EV/EBITDA',
      name: 'corporate.overview.keyIndicators.security.EV/EBITDA',
      MWG: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/E',
      name: 'corporate.overview.keyIndicators.security.P/E',
      MWG: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/B',
      name: 'corporate.overview.keyIndicators.security.P/B',
      MWG: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'ROE',
      name: 'corporate.overview.keyIndicators.security.ROE',
      MWG: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROA',
      name: 'corporate.overview.keyIndicators.security.ROA',
      MWG: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROIC',
      name: 'corporate.overview.keyIndicators.security.ROIC',
      MWG: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'EPS',
      name: 'corporate.overview.keyIndicators.security.EPS',
      MWG: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'BVPS',
      name: 'corporate.overview.keyIndicators.security.BVPS',
      MWG: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'div_yield',
      name: 'corporate.overview.keyIndicators.security.Div Yield',
      MWG: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
  ],
  corporate: [
    {
      index: 'market_cap',
      name: 'corporate.overview.keyIndicators.corporate.Market Cap (Billion VND)',
      MWG: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'EV/EBITDA',
      name: 'corporate.overview.keyIndicators.corporate.EV/EBITDA',
      MWG: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/E',
      name: 'corporate.overview.keyIndicators.corporate.P/E',
      MWG: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/B',
      name: 'corporate.overview.keyIndicators.corporate.P/B',
      MWG: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'ROE',
      name: 'corporate.overview.keyIndicators.corporate.ROE',
      MWG: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROA',
      name: 'corporate.overview.keyIndicators.corporate.ROA',
      MWG: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROIC',
      name: 'corporate.overview.keyIndicators.corporate.ROIC',
      MWG: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'EPS',
      name: 'corporate.overview.keyIndicators.corporate.EPS',
      MWG: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'BVPS',
      name: 'corporate.overview.keyIndicators.corporate.BVPS',
      MWG: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'div_yield',
      name: 'corporate.overview.keyIndicators.corporate.Div Yield',
      MWG: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
  ],
  insurance: [
    {
      index: 'market_cap',
      name: 'corporate.overview.keyIndicators.insurance.Market Cap (Billion VND)',
      MWG: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0005',
        isFormatValue: 10 ** 9,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'EV/EBITDA',
      name: 'corporate.overview.keyIndicators.insurance.EV/EBITDA',
      MWG: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0027',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/E',
      name: 'corporate.overview.keyIndicators.insurance.P/E',
      MWG: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0022',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'P/B',
      name: 'corporate.overview.keyIndicators.insurance.P/B',
      MWG: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0019',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'ROE',
      name: 'corporate.overview.keyIndicators.insurance.ROE',
      MWG: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0060',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROA',
      name: 'corporate.overview.keyIndicators.insurance.ROA',
      MWG: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0059',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'ROIC',
      name: 'corporate.overview.keyIndicators.insurance.ROIC',
      MWG: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rT0064',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
    {
      index: 'EPS',
      name: 'corporate.overview.keyIndicators.insurance.EPS',
      MWG: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0011',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'BVPS',
      name: 'corporate.overview.keyIndicators.insurance.BVPS',
      MWG: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
      INDUSTRY: {
        key: 'rtD0007',
        isFormatValue: 1,
        isFormatValToPercent: false,
      },
    },
    {
      index: 'div_yield',
      name: 'corporate.overview.keyIndicators.insurance.Div Yield',
      MWG: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
      INDUSTRY: {
        key: 'rtD0045',
        isFormatValue: 1,
        isFormatValToPercent: true,
      },
    },
  ],
}
