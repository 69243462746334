import { forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../../../../common/hooks/useI18n'
import { Input } from '../../../../../common/html/Input'
import style from '../../style.module.css'
import {
  addCompareChecked,
  searchData,
  selectListCompareChecked,
  selectListTicker,
} from './store/slice'

export const DropdownInput = forwardRef((props, ref) => {
  const dispatch = useDispatch()

  const listTicker = useSelector(selectListTicker)
  const listCompareChecked = useSelector(selectListCompareChecked)

  const handleSearchItem = (e) => {
    const searchString = e.target.value.toLowerCase()
    dispatch(searchData(searchString))
  }

  const handleKeyEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      const searchString = e.target.value.toLowerCase()
      const listDataSearch = listTicker
        .filter((item) =>
          item.tickerOrTaxCode?.toLowerCase().includes(searchString),
        )
        .sort((a, b) => a.tickerOrTaxCode.localeCompare(b.tickerOrTaxCode))
      if (
        !listCompareChecked.some(
          (e) => e.tickerOrTaxCode === listDataSearch[0].tickerOrTaxCode,
        )
      ) {
        dispatch(addCompareChecked(listDataSearch[0]))
        props.setShowDropdown(false)
        dispatch(searchData(''))
      }
    }
  }

  const placeHolder = UseI18n('common.SEARCH')

  return (
    <form className="form-search-inbox" style={{ marginTop: 2 }}>
      <Input
        style={{ fontSize: 11 }}
        type="text"
        className={`search-inbox ${style.inputSearch}`}
        placeholder={placeHolder}
        onChange={(e) => handleSearchItem(e)}
        ref={ref}
        onKeyDown={(e) => handleKeyEnter(e)}
      />
      <button type="button">
        <i className="icon-search-2" />
      </button>
    </form>
  )
})

export default DropdownInput
