import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getSteelPipePeersComparison } from './thunk'

const slice = createSlice({
  name: 'sector/sectorSpecific/steel/steelPipe/peersComparison',
  initialState: {
    isLoading: true,
    data: [],
    currentCategory: 0,
    isChangeLocale: false,
  },
  reducers: {
    changeCategory: (state, action) => {
      state.currentCategory = action.payload
    },
    changeLocale: (state) => {
      state.isChangeLocale = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSteelPipePeersComparison.pending, (state) => {
      state.data = []
      state.isLoading = true
    })
    builder.addCase(getSteelPipePeersComparison.fulfilled, (state, action) => {
      state.isChangeLocale = false
      state.data = action.payload || []
      state.isLoading = false
    })
    builder.addCase(getSteelPipePeersComparison.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const selectLoading = (state) => state[slice.name].isLoading
export const selectDataChart = (state) => state[slice.name].data
export const selectCurrentCategory = (state) =>
  state[slice.name].currentCategory
export const selectIsChangeLocale = (state) => state[slice.name].isChangeLocale

export const { changeCategory, changeLocale } = slice.actions

register(slice.name, slice.reducer)
