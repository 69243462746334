import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { range } from '../../../utils/Common'
import { Button } from '../../html/Button'
import { Span } from '../../html/Span'
import Popup from '../../popup'
import ButtonSubmit from './common/ButtonSubmit'
import Count from './common/Count'
import ItemCheckbox from './common/ItemCheckbox'
import ItemRadio from './common/ItemRadio'
import RadioCalendar from './common/RadioCalendar'
import TableIndicator from './common/TableIndicator'
import TreeIndicator from './common/TreeIndicator'
import TTM from './common/TTM'
import useAddDefaultDetail from './common/useAddDefaultDetail'
import useAddDefaultIndicator from './common/useAddDefaultIndicator'
import withWrapperPopupIndicator from './common/withWrapperPopupIndicator'
import Year from './common/Year'
import YearFromTo from './common/YearFromTo'
import { deepCopy } from './helper'
import style from './index.module.css'
import { selectValueDetailPopupIndicator } from './store/slice'

const deleteKey = (obj) => {
  const newObj = deepCopy(obj)
  Object.keys(newObj).forEach((key) => {
    if (key === newObj.type) {
      Object.keys(newObj[key]).forEach((subKey) => {
        if (subKey === 'date') {
          newObj.date = newObj[key].date
        } else if (subKey === 'quarters') {
          if (typeof newObj[key].quarters === 'object') {
            newObj.quarters = newObj[key].quarters
          } else {
            newObj.quarters = [newObj[key].quarters]
          }
        } else if (subKey === 'years') {
          newObj.years = [newObj[key].years]
        } else if (subKey === 'from') {
          newObj.years = range(newObj[key].from, newObj[key].to)
        }
      })
      delete newObj[key]
    } else if (key !== 'type') {
      delete newObj[key]
    }
  })
  return newObj
}

const FinancialRatio = ({
  handleSubmit,
  handleCancel,
  title,
  maxDate,
  maxYear,
  minDate,
  minYear,
  ttmMaxQuarter,
  ttmMaxYear,
  ttmMinYear,
  maxIndicator,
  lengthIndicator,
  tree,
  defaultIndicators,
  defaultDetail,
}) => {
  useAddDefaultIndicator(defaultIndicators)
  useAddDefaultDetail(defaultDetail)

  const typeTtm = useSelector(selectValueDetailPopupIndicator('typeTtm'))
  const typeTtmSettings = useSelector(
    selectValueDetailPopupIndicator('ttmSettings.type'),
  )
  const quartersTtmSettings = useSelector(
    selectValueDetailPopupIndicator('ttmSettings.MultipleReporting.quarters'),
  )
  const fromTtmSettings = useSelector(
    selectValueDetailPopupIndicator('ttmSettings.MultipleReporting.from'),
  )
  const toTtmSettings = useSelector(
    selectValueDetailPopupIndicator('ttmSettings.MultipleReporting.to'),
  )

  const typeYearly = useSelector(selectValueDetailPopupIndicator('typeYearly'))
  const typeYearlySettings = useSelector(
    selectValueDetailPopupIndicator('yearlySettings.type'),
  )
  const fromYearlySettings = useSelector(
    selectValueDetailPopupIndicator('yearlySettings.MultipleReporting.from'),
  )
  const toYearlySettings = useSelector(
    selectValueDetailPopupIndicator('yearlySettings.MultipleReporting.to'),
  )

  const convertData = (data) => {
    const detail = deepCopy(data.detail)
    if (!detail.typeTtm) {
      delete detail.ttmSettings
    }
    if (!detail.typeYearly) {
      delete detail.yearlySettings
    }
    delete detail.typeTtm
    delete detail.typeYearly
    Object.keys(detail).forEach((key) => (detail[key] = deleteKey(detail[key])))
    handleSubmit({ ...data, detail })
  }

  const getErrors = () => {
    const errors = []
    if (!typeTtm && !typeYearly) {
      errors.push(<Translate value="common.popup.ERROR_TYPE" />)
    }
    if (
      typeTtm &&
      typeTtmSettings === 'MultipleReporting' &&
      !quartersTtmSettings.length
    ) {
      errors.push(<Translate value="common.popup.ERROR_QUARTER" />)
    }
    return errors
  }

  const getRatio = () => {
    let ratio = 0
    if (typeTtm) {
      if (typeTtmSettings === 'MultipleReporting') {
        ratio +=
          quartersTtmSettings.length * (toTtmSettings - fromTtmSettings + 1)
      } else {
        ratio += 1
      }
    }
    if (typeYearly) {
      if (typeYearlySettings === 'MultipleReporting') {
        ratio += toYearlySettings - fromYearlySettings + 1
      } else {
        ratio += 1
      }
    }
    return ratio
  }

  const isCheckboxError =
    typeTtm &&
    typeTtmSettings === 'MultipleReporting' &&
    !quartersTtmSettings.length
  const isTypeError = !typeTtm && !typeYearly

  return (
    <Popup>
      <div className={`modal tool-page ${style.fitContent}`}>
        <div className="modal-title">
          <h3>
            <Span>{title}</Span>
          </h3>
          <a className="close-modal" onClick={handleCancel}>
            <i className="icon-delete-stroke" />
          </a>
        </div>
        <div className={`modal-content ${style.modalContent}`}>
          <div className={style.frContent}>
            <ItemRadio
              defaultCheck={true}
              keyRadio="typeTtm"
              valueRadio={true}
              valueUncheckRadio={false}
              textRadio={I18n.t('common.popup.TITLE_TTM')}
              isCheckbox={true}
              isError={isTypeError}
              renderContent={(isDisabled) => (
                <div className="d-flex j-b w-100">
                  <div className={style.fr40}>
                    <ItemRadio
                      defaultCheck={true}
                      keyRadio="ttmSettings.type"
                      valueRadio="MostRecent"
                      textRadio={I18n.t('common.popup.MOST_RECENT_2')}
                      disabled={isDisabled}
                    />
                    <ItemRadio
                      keyRadio="ttmSettings.type"
                      valueRadio="TTM"
                      textRadio="TTM:"
                      disabled={isDisabled}
                      isJbContent={true}
                      renderContent={(isDisabled) => (
                        <TTM
                          keyQuarter="ttmSettings.TTM.quarters"
                          keyYear="ttmSettings.TTM.years"
                          disabled={isDisabled}
                          ttmMaxQuarter={ttmMaxQuarter}
                          ttmMaxYear={ttmMaxYear}
                          ttmMinYear={ttmMinYear}
                        />
                      )}
                    />
                    <ItemRadio
                      keyRadio="ttmSettings.type"
                      valueRadio="Date"
                      textRadio={I18n.t('common.popup.DATE') + ':'}
                      disabled={isDisabled}
                      isJbContent={true}
                      renderContent={(isDisabled) => (
                        <RadioCalendar
                          keySelect="ttmSettings.Date.date"
                          disabled={isDisabled}
                          defaultValue={maxDate}
                          maxDate={maxDate}
                          minDate={minDate}
                        />
                      )}
                    />
                  </div>
                  <div className={style.fr40}>
                    <ItemRadio
                      keyRadio="ttmSettings.type"
                      valueRadio="MultipleReporting"
                      textRadio={I18n.t('common.popup.MULTIPLE_REPORTING')}
                      disabled={isDisabled}
                      renderContent={(isDisabled) => (
                        <div className="w-100">
                          <div
                            className={`d-flex j-b ali-center ${style.itemMultiple}`}
                          >
                            <span className={isDisabled ? style.disabled : ''}>
                              <Translate value="common.popup.YEAR" />:
                            </span>
                            <YearFromTo
                              keyFrom="ttmSettings.MultipleReporting.from"
                              keyTo="ttmSettings.MultipleReporting.to"
                              disabled={isDisabled}
                              minYear={ttmMinYear}
                              maxYear={ttmMaxYear}
                            />
                          </div>
                          <div
                            className={`d-flex j-b ali-center ${style.itemMultiple}`}
                          >
                            <span
                              className={`${isDisabled ? style.disabled : ''} ${
                                isCheckboxError ? style.error : ''
                              }`}
                            >
                              <Translate value="common.popup.QUARTER" />:
                            </span>
                            <div className={`d-flex j-b ${style.checkboxs}`}>
                              <ItemCheckbox
                                keyCheckbox="ttmSettings.MultipleReporting.quarters"
                                valueCheckbox={1}
                                textCheckbox="Q1"
                                disabled={isDisabled}
                                defaultCheck={ttmMaxQuarter === 1}
                                isError={isCheckboxError}
                              />
                              <ItemCheckbox
                                keyCheckbox="ttmSettings.MultipleReporting.quarters"
                                valueCheckbox={2}
                                textCheckbox="Q2"
                                disabled={isDisabled}
                                isError={isCheckboxError}
                                defaultCheck={ttmMaxQuarter === 2}
                              />
                              <ItemCheckbox
                                keyCheckbox="ttmSettings.MultipleReporting.quarters"
                                valueCheckbox={3}
                                textCheckbox="Q3"
                                disabled={isDisabled}
                                isError={isCheckboxError}
                                defaultCheck={ttmMaxQuarter === 3}
                              />
                              <ItemCheckbox
                                keyCheckbox="ttmSettings.MultipleReporting.quarters"
                                valueCheckbox={4}
                                textCheckbox="Q4"
                                disabled={isDisabled}
                                isError={isCheckboxError}
                                defaultCheck={ttmMaxQuarter === 4}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
            />
            <ItemRadio
              keyRadio="typeYearly"
              valueRadio={true}
              valueUncheckRadio={false}
              textRadio={I18n.t('common.popup.TITLE_YEAR')}
              isCheckbox={true}
              isError={isTypeError}
              renderContent={(isDisabled) => (
                <div className="d-flex j-b w-100">
                  <div className={style.fr40}>
                    <ItemRadio
                      defaultCheck={true}
                      keyRadio="yearlySettings.type"
                      valueRadio="MostRecent"
                      textRadio={I18n.t('common.popup.MOST_YEAR_RECENT')}
                      disabled={isDisabled}
                    />
                    <ItemRadio
                      keyRadio="yearlySettings.type"
                      valueRadio="Year"
                      textRadio={I18n.t('common.popup.YEAR') + ':'}
                      disabled={isDisabled}
                      isJbContent={true}
                      renderContent={(isDisabled) => (
                        <div className={style.yearSelect}>
                          <Year
                            keyYear="yearlySettings.Year.years"
                            disabled={isDisabled}
                            minYear={minYear}
                            maxYear={maxYear}
                          />
                        </div>
                      )}
                    />
                  </div>
                  <div className={style.fr40}>
                    <ItemRadio
                      keyRadio="yearlySettings.type"
                      valueRadio="MultipleReporting"
                      textRadio={I18n.t('common.popup.MULTIPLE_REPORTING')}
                      disabled={isDisabled}
                      renderContent={(isDisabled) => (
                        <div className="w-100">
                          <div
                            className={`d-flex j-b ali-center ${style.itemMultiple}`}
                          >
                            <span className={isDisabled ? style.disabled : ''}>
                              <Translate value="common.popup.YEAR" />:
                            </span>
                            <YearFromTo
                              keyFrom="yearlySettings.MultipleReporting.from"
                              keyTo="yearlySettings.MultipleReporting.to"
                              disabled={isDisabled}
                              minYear={minYear}
                              maxYear={maxYear}
                            />
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </div>
              )}
            />
          </div>
          <div className="content">
            <TreeIndicator tree={tree} />
            <TableIndicator />
          </div>
          <Count
            lengthIndicator={lengthIndicator}
            maxIndicator={maxIndicator}
            errors={getErrors()}
            ratio={getRatio()}
          />
          <div className="group-btn justify-content-end mt-9">
            <Button
              style={{ fontSize: 11 }}
              className={`btn w-80 h-20 ${style.btnCancel}`}
              onClick={handleCancel}
            >
              <Translate value="common.button.BUTTON_CANCEL" />
            </Button>
            <ButtonSubmit
              lengthIndicator={lengthIndicator}
              maxIndicator={maxIndicator}
              submit={convertData}
              isError={getErrors().length > 0}
              ratio={getRatio()}
            />
          </div>
        </div>
      </div>
    </Popup>
  )
}

FinancialRatio.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  lengthIndicator: PropTypes.number.isRequired,
  maxIndicator: PropTypes.number.isRequired,
}

export default withWrapperPopupIndicator(FinancialRatio)
