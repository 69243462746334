import { ServiceProxyConfig } from '../../../../configs/ServiceProxy'
import ProxyBase from '../../ProxyBase'

export class ProductionProxy extends ProxyBase {
  getMaxDate(params) {
    return this.get('MaxDate', params, undefined, true)
  }

  getVNTypeIds(params) {
    return this.get('GetVNTypeIds', params)
  }

  getDataIIPCompareByTimeRange(params) {
    return this.get('IIP/CompareByTimeRange', params)
  }

  getDataChartGrowthIIP(params) {
    return this.get('IIP/GrowthByTimeRange', params)
  }

  getDataChartTopChangeIIP(params) {
    return this.get('IIP/TopChange', params)
  }

  downloadCompareByTimeRange(params) {
    return this.get('IIP/DownloadCompareByTimeRange', params, 'download')
  }

  getDataIICCompareByTimeRange(params) {
    return this.get('IIC/CompareByTimeRange', params)
  }

  getDataChartGrowthIIC(params) {
    return this.get('IIC/GrowthByTimeRange', params)
  }

  getDataChartTopChangeIIC(params) {
    return this.get('IIC/TopChange', params)
  }

  downloadCompareByTimeRangeIIC(params) {
    return this.get('IIC/DownloadCompareByTimeRange', params, 'download')
  }

  getDataIIICompareByTimeRange(params) {
    return this.get('III/CompareByTimeRange', params)
  }

  getDataChartGrowthIII(params) {
    return this.get('III/GrowthByTimeRange', params)
  }

  getDataChartTopChangeIII(params) {
    return this.get('III/TopChange', params)
  }

  downloadCompareByTimeRangeIII(params) {
    return this.get('III/DownloadCompareByTimeRange', params, 'download')
  }

  getGeneralProductionIndexName(params) {
    return this.get('PPI/GeneralProductionIndexName', params)
  }

  getGeneralProductionIndex(params) {
    return this.get('PPI/GeneralProductionIndex', params)
  }

  downloadGeneralProductionIndex(params) {
    return this.get('PPI/DownloadGeneralProductionIndex', params, 'download')
  }

  getGrowthByTimeRange(params) {
    return this.get('PPI/GrowthByTimeRange', params)
  }

  getPurchasingManagersIndex(params) {
    return this.get('PPI/PurchasingManagersIndex', params)
  }
}

export default new ProductionProxy(
  ServiceProxyConfig.Economy.Production.ServiceUrl,
)
