import { useEffect, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import Popup from '../../../common/popup'
import { Search } from '../../../common/search'
import styles from '../index.module.css'
import { OrganizationTickers } from './OrganizationTickers'

export const AddTicker = () => {
  const [isShow, setIsShow] = useState(false)
  const [keyword, setKeyword] = useState('')

  useEffect(() => {
    if (isShow) {
      setKeyword('')
    }
  }, [isShow])

  const handleClosePopup = () => {
    setIsShow(false)
  }

  const handleOpenPopup = () => {
    setIsShow(true)
  }

  return (
    <div>
      <button
        onClick={handleOpenPopup}
        className={`btn btn-add-more ${styles.addTickerButton}`}
      >
        <Span style={{ fontSize: 11, fontWeight: 'normal' }}>
          <Translate value="market.marginLoans.ADD_TICKER" />
        </Span>
        <i className="icon-plus" />
      </button>
      {isShow && (
        <Popup>
          <div className="modal modal-medium" style={{ width: 594 }}>
            <div className="modal-title">
              <h3 className="text-up">
                <Translate value="market.marginLoans.ADD_TICKER" />
              </h3>
              <a onClick={handleClosePopup} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content" style={{ paddingTop: 8 }}>
              <Search
                onChange={(val) => setKeyword(val.trim())}
                margin={'0px'}
                width={212}
              />
              <OrganizationTickers
                handleClosePopup={handleClosePopup}
                keyword={keyword}
              />
            </div>
          </div>
        </Popup>
      )}
    </div>
  )
}
