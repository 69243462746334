import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Span } from '../../../../common/html/Span'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { Table } from '../../../../common/table'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import {
  FORMAT,
  compareTwoDateTime,
  formatDateTime,
  getCurrentDateTime,
  getDateTimeFrom,
  getISOStartOrEndOfDay,
} from '../../../../utils/Datetime'
import { formatVal, valDivMillion } from '../../../../utils/Value'
import { getDateListFrom } from '../../common/helper'
import {
  CHART_ID,
  FIRST_COL_ID,
  ICB_LEVEL,
  NOT_SECTOR_INDEX,
  TOTAL_ID,
  VALUES_TYPE,
} from './constants'
import styles from './index.module.css'
import {
  changeFilterDate,
  resetStore,
  selectDates,
  selectEndDate,
  selectIds,
  selectLimitDatepicker,
  selectLoading,
  selectReCalcWidths,
  selectStartDate,
  selectValueById,
  sort,
} from './store/slice'
import { getSectorSegmentation } from './store/thunk'
import ChartCell from './table/ChartCell'
import { FirstColumnCell, TABLE_SECTOR_ID } from './table/FirstColumnCell'
import { TotalCell } from './table/TotalCell'

const BORDER_LIGHT = '1px solid #3e485a'
const BORDER_DARK = '1px solid #1b2029'

const DAYS_PER_PAGE = 3

const SectorSegmentation = ({ height, dates, setDates }) => {
  const dispatch = useDispatch()
  const currentYear = new Date().getFullYear()

  const locale = useSelector((state) => state.i18n.locale)
  const timeZone = UseTimeZone()
  const indexInfo = useSelector(selectIndexInfo)
  const loading = useSelector(selectLoading)
  const ids = useSelector(selectIds)
  const startDate = useSelector(selectStartDate)
  const endDate = useSelector(selectEndDate)
  const allDates = useSelector(selectDates)
  const reCalcWidths = useSelector(selectReCalcWidths)
  const limitDatepicker = useSelector(selectLimitDatepicker)

  const [from, setFrom] = useState(null)
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [activeRowId, setActiveRowId] = useState(null)
  const [isFetchNextData, setIsFetchNextData] = useState(false)

  const rowIds = ids.length > 0 ? [...ids, TOTAL_ID, CHART_ID] : []

  useEffect(() => {
    setPage(1)
  }, [allDates])

  useEffect(() => {
    setTotalPage(
      isFetchNextData && endDate === getCurrentDateTime(FORMAT.DATE)
        ? Math.ceil(allDates.length / DAYS_PER_PAGE) || 1
        : Math.floor(allDates.length / DAYS_PER_PAGE) || 1,
    )
  }, [allDates, isFetchNextData, endDate])

  useEffect(() => {
    let dataDates = isFetchNextData
      ? allDates.slice(DAYS_PER_PAGE * (page - 1), DAYS_PER_PAGE * page)
      : allDates.slice(
          -DAYS_PER_PAGE * page,
          -DAYS_PER_PAGE * (page - 1) || undefined,
        )
    if (!dataDates.length) {
      dataDates = isFetchNextData
        ? getDateListFrom({
            from: endDate,
            dateCount: DAYS_PER_PAGE,
            isGetNext: false,
          })
        : getDateListFrom({
            from: startDate,
            dateCount: DAYS_PER_PAGE,
            isGetNext: true,
          })
    }
    setDates(dataDates)
  }, [allDates, isFetchNextData, page, startDate, endDate])

  const handleChangePrevPage = () => {
    if (page === (isFetchNextData ? 1 : totalPage)) {
      setIsFetchNextData(false)
      setFrom(null)
      dispatch(
        changeFilterDate({
          startDate: getDateTimeFrom(dates[0], -1, 'y', FORMAT.DATE),
          endDate: getDateTimeFrom(dates[0], -1, 'd', FORMAT.DATE),
        }),
      )
    } else {
      setPage(isFetchNextData ? page - 1 : page + 1)
    }
  }

  const handleChangeNextPage = () => {
    if (page === (isFetchNextData ? totalPage : 1)) {
      setIsFetchNextData(true)
      const nextYear = getDateTimeFrom(
        dates[DAYS_PER_PAGE - 1],
        1,
        'y',
        FORMAT.DATE,
      )
      const currentDate = getCurrentDateTime(FORMAT.DATE)
      const isMaxDate = compareTwoDateTime(nextYear, currentDate) === 1
      dispatch(
        changeFilterDate({
          startDate: getDateTimeFrom(
            dates[DAYS_PER_PAGE - 1],
            1,
            'd',
            FORMAT.DATE,
          ),
          endDate: isMaxDate ? currentDate : nextYear,
        }),
      )
    } else {
      setPage(isFetchNextData ? page + 1 : page - 1)
    }
  }

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(
        getSectorSegmentation({
          GroupId: indexInfo.groupId,
          IcbLevel: ICB_LEVEL,
          StartDate: getISOStartOrEndOfDay(startDate, timeZone, true),
          EndDate: getISOStartOrEndOfDay(endDate, timeZone, false),
        }),
      )
    }
  }, [indexInfo?.groupId, startDate, endDate, locale, timeZone])

  useEffect(() => {
    if (from) {
      setIsFetchNextData(true)
      const nextYear = getDateTimeFrom(from, 1, 'y', FORMAT.DATE)
      const currentDate = getCurrentDateTime(FORMAT.DATE)
      const isMaxDate = compareTwoDateTime(nextYear, currentDate) === 1
      dispatch(
        changeFilterDate({
          startDate: from,
          endDate: isMaxDate ? currentDate : nextYear,
        }),
      )
    }
  }, [from])

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  useEffect(() => {
    const scrollTable = document.querySelector(
      `#${TABLE_SECTOR_ID} .scrollbars div:first-child`,
    )
    const headerTable = scrollTable?.querySelector('thead')
    const activeRow = scrollTable?.querySelector(`[id="${activeRowId}"]`)

    if (scrollTable && headerTable && activeRow)
      scrollTable.scrollTo({
        top: activeRow.offsetTop - headerTable.offsetHeight,
        behavior: 'smooth',
      })
  }, [ids, activeRowId])

  const schema = [
    {
      colId: FIRST_COL_ID,
      canCustomTd: true,
      tdStyle: {
        borderRight: BORDER_LIGHT,
      },
      render: (val, rowId, { style, className }) => (
        <FirstColumnCell
          val={val}
          rowId={rowId}
          style={style}
          className={className}
        />
      ),
    },
    ...dates
      .map((date, index) =>
        Object.values(VALUES_TYPE).map((value) => {
          if (value === VALUES_TYPE.BUY_VAL) {
            return {
              colId: date + '.' + value,
              canCustomTd: true,
              tdStyle: {
                textAlign: 'right',
                borderRight: '1px solid #2b303a',
              },
              render: (val, rowId, { style, className }) => {
                if (rowId === CHART_ID) {
                  return (
                    <ChartCell
                      activeRowId={activeRowId}
                      setActiveRowId={setActiveRowId}
                      style={{
                        ...style,
                        borderRight: BORDER_LIGHT,
                      }}
                      className={className}
                      data={val}
                      colSpan={Object.values(VALUES_TYPE).length}
                      height={height}
                    />
                  )
                } else if (rowId === TOTAL_ID) {
                  return (
                    <TotalCell val={val} style={style} className={className} />
                  )
                } else {
                  return (
                    <td style={style} className={className}>
                      {formatVal(valDivMillion(val), 1)}
                    </td>
                  )
                }
              },
            }
          }

          if (value === VALUES_TYPE.SELL_VAL) {
            return {
              colId: date + '.' + value,
              canCustomTd: true,
              tdStyle: {
                textAlign: 'right',
                borderRight: '1px solid #2b303a',
              },
              render: (val, rowId, { style, className }) => {
                if (rowId === CHART_ID) {
                  return <></>
                } else if (rowId === TOTAL_ID) {
                  return (
                    <TotalCell
                      val={val}
                      style={style}
                      className={className}
                      noBorderLeft
                    />
                  )
                } else {
                  return (
                    <td style={style} className={className}>
                      {formatVal(valDivMillion(val), 1)}
                    </td>
                  )
                }
              },
            }
          }

          if (value === VALUES_TYPE.NET_VAL) {
            return {
              colId: date + '.' + value,
              canCustomTd: true,
              tdStyle: {
                textAlign: 'right',
                borderRight: BORDER_LIGHT,
              },
              render: (val, rowId, { style, className }) => {
                if (rowId === CHART_ID) {
                  return <></>
                } else if (rowId === TOTAL_ID) {
                  return (
                    <TotalCell
                      val={val}
                      style={style}
                      className={className}
                      formatColor
                      noBorderLeft
                    />
                  )
                } else {
                  return (
                    <td style={style} className={className}>
                      <span style={{ color: val >= 0 ? '#59c761' : '#ff4752' }}>
                        {formatVal(valDivMillion(val), 1)}
                      </span>
                    </td>
                  )
                }
              },
            }
          }

          return {}
        }),
      )
      .flat(),
  ]

  const rowSpanSchema = [
    [
      {
        title: 'market.marketInDepth.localInstitution.SECTOR',
        colId: FIRST_COL_ID,
        rowSpan: 2,
        thStyle: {
          textAlign: 'left',
          verticalAlign: 'baseline',
        },
        renderTh: () => (
          <div className={styles.sectorCell}>
            <Span style={{ fontSize: 10 }}>
              <Translate value="market.marketInDepth.localInstitution.SECTOR" />
            </Span>
          </div>
        ),
      },
      ...dates.map((date, index) => {
        return {
          title: date,
          colId: date,
          colSpan: Object.keys(VALUES_TYPE).length,
          disableSort: true,
          renderTh: () => (
            <div
              style={{ textAlign: 'center' }}
              className={styles.tableHeadGroupCell}
            >
              {index === 0 && (
                <>
                  <div
                    onClick={handleChangePrevPage}
                    className={styles.iconCaretLeft}
                  >
                    <i className="icon-arrow-left" />
                  </div>
                  <div
                    onClick={handleChangeNextPage}
                    className={`${styles.iconCaretRight} ${
                      page === (isFetchNextData ? totalPage : 1) &&
                      endDate ===
                        getCurrentDateTime(FORMAT.DATE, locale, timeZone)
                        ? styles.disabled
                        : ''
                    }`}
                  >
                    <i className="icon-arrow-right" />
                  </div>
                </>
              )}
              <Span style={{ fontSize: 10 }}>
                {formatDateTime(date, FORMAT.DATE, locale, timeZone)}
              </Span>
            </div>
          ),
        }
      }),
    ],
    dates
      .map((date) =>
        Object.keys(VALUES_TYPE).map((key, index) => ({
          title: 'market.marketInDepth.localInstitution.' + key,
          colId: date + '.' + VALUES_TYPE[key],
          thStyle: {
            fontSize: 10,
            textAlign: 'right',
          },
        })),
      )
      .flat(),
  ]

  const getTableHeadRowTop = () => {
    let result = {}
    dates.forEach((date) =>
      Object.values(VALUES_TYPE).forEach(
        (val) => (result[`${date}.${val}`] = [date]),
      ),
    )
    return result
  }

  const clearInputDate = () => {
    setFrom(null)
    setIsFetchNextData(false)
    dispatch(
      changeFilterDate({
        startDate: getDateTimeFrom(new Date(), -1, 'y', FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
      }),
    )
  }

  const handleFromChange = (date) => {
    if (!date) {
      clearInputDate()
    } else {
      setFrom(date)
    }
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  return (
    <div style={{ height: '100%' }} id={TABLE_SECTOR_ID}>
      <SizeTracker>
        {(size) => (
          <>
            <div className="d-flex j-b mb-8">
              <div className="form-date-select">
                <Translate value="market.marketInDepth.localInstitution.LB_FROM" />
                <div className="date-select">
                  <CalendarPopper
                    date={from ? getDateWithTimeZone(from) : ''}
                    handleChange={handleFromChange}
                    CustomInput={InputDateCalendar}
                    minDate={getDateWithTimeZone(limitDatepicker.minDate)}
                    maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
                    customHeader={HeaderCalendarPopper({
                      startYear: limitDatepicker.minYear || currentYear - 10,
                      endYear: limitDatepicker.maxYear || currentYear,
                    })}
                  />
                  <span className={`btn-select-date ${styles.iconCaret}`}>
                    <i className="icon-caret-down" />
                  </span>
                </div>
                <div onClick={clearInputDate} className={styles.buttonClear}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="market.marketInDepth.localInstitution.CLEAR" />
                  </Span>
                </div>
              </div>
              <div>
                <Span
                  style={{ fontSize: 11, opacity: 0.4, fontStyle: 'italic' }}
                >
                  <Translate value="market.marketInDepth.localInstitution.NOTE_SECTOR" />
                </Span>
              </div>
            </div>

            {size.height && (
              <div style={{ height: `calc(100% - ${size.height}px)` }}>
                {!indexInfo.groupId ? (
                  <Loading />
                ) : NOT_SECTOR_INDEX.includes(indexInfo.groupId) ? (
                  <Table
                    ids={rowIds}
                    getDataFromRedux={selectValueById}
                    schema={schema}
                    rowSpanSchema={rowSpanSchema}
                    isLoading={loading}
                    hasFooter={false}
                    hasTooltip={false}
                    reCalcWidths={reCalcWidths}
                    tableHeadRowTop={getTableHeadRowTop()}
                    stickies={{ [FIRST_COL_ID]: true }}
                    stickyBottomRowCount={2}
                    defaultActiveRowId={activeRowId}
                    disableRowHovered={{ [CHART_ID]: true, [TOTAL_ID]: true }}
                    hasUpdatedBorder={false}
                    sort={sort}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </div>
  )
}

export default SectorSegmentation
