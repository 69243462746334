import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import WarrantTopInfo from '../../../common/topInfo/warrantTopInfo'
import Content from './Content'

export const CoveredWarrant = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <WarrantTopInfo />
            {size.height && (
              <div
                className="w-100 position-relative"
                style={{ height: `calc(100% - ${size.height}px)` }}
              >
                <Content />
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
