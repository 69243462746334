import { Translate } from 'react-redux-i18n'
import style from './NewDashboardGroup.module.css'

export const NewDashboardGroup = ({ onClick }) => {
  return (
    <div className={style.container} onClick={onClick}>
      <div className={style.content}>
        <i
          className="icon-plus-circle-line"
          style={{ marginRight: 6, fontSize: 10 }}
        />
        <Translate value="financialChart.NEW_DASHBOARD_GROUP" />
      </div>
    </div>
  )
}
