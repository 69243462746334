import React, { useEffect, useState } from 'react'
import boldFont from '../../../styles/fonts/font-poppins/SVN-Poppins-Bold.ttf'
import lightFont from '../../../styles/fonts/font-poppins/SVN-Poppins-Light.ttf'
import mediumFont from '../../../styles/fonts/font-poppins/SVN-Poppins-Medium.ttf'
import regularFont from '../../../styles/fonts/font-poppins/SVN-Poppins-Regular.ttf'
import semiBoldFont from '../../../styles/fonts/font-poppins/SVN-Poppins-SemiBold.ttf'
import style from './style.module.css'

const LoadingScreen = ({ children }) => {
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    let timeOut = null
    Promise.all([
      new FontFace('Poppins', `url(${lightFont}) format("truetype")`, {
        weight: 300,
      }).load(),
      new FontFace('Poppins', `url(${regularFont}) format("truetype")`, {
        weight: 400,
      }).load(),
      new FontFace('Poppins', `url(${mediumFont}) format("truetype")`, {
        weight: 500,
      }).load(),
      new FontFace('Poppins', `url(${semiBoldFont}) format("truetype")`, {
        weight: 600,
      }).load(),
      new FontFace('Poppins', `url(${boldFont}) format("truetype")`, {
        weight: 700,
      }).load(),
    ]).then((fonts) => {
      fonts.forEach((font) => {
        document.fonts.add(font)
      })
      document.documentElement.classList.add('fonts-loaded')
      timeOut = setTimeout(() => {
        setIsShow(true)
      }, 100)
    })

    return () => clearTimeout(timeOut)
  }, [])

  if (isShow) {
    return children
  }

  return (
    <div className={style.background}>
      <div className={style.wrapper}>
        <div className={style.logo}>
          <img src="/logoFiinProX.svg" alt="Logo" width={222} height={62} />
        </div>
        <div className={style.loadingBox}>
          <div className={style.loader} />
        </div>
      </div>
    </div>
  )
}

export default LoadingScreen
