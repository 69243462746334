import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../utils/ReducerRegistry'
import { DEFAULT_INDEX } from '../constants'
import { getLatestPrice } from './thunk'

const defaultState = {
  topInfo: DEFAULT_INDEX,
  priceData: {},
  displayInfo: true,
}

const initialState = {
  topInfo: {
    groupId: '',
    groupName: '',
    groupCode: '',
    exchangeCode: '',
  },
  priceData: {},
  displayInfo: true,
  isLoadStocks: true,
}

const slice = createSlice({
  name: 'common/indexSectorTickerTopInfo',
  initialState,
  reducers: {
    resetDefault(state) {
      Object.keys(defaultState).forEach((key) => {
        if (key !== 'priceData') {
          state[key] = defaultState[key]
        }
      })
    },
    resetStore(state) {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeIndexSectorTickerInfo: (state, action) => {
      state.topInfo = action.payload
      state.displayInfo = true
    },
    subscribeIndexSector: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.priceInfo = action.payload[0]
      }
    },
    subscribeTicker: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData.bidAskInfo = action.payload[0]
      }
    },

    changeDisplayInfo: (state, action) => {
      state.displayInfo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLatestPrice.fulfilled, (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        state.priceData = action.payload[0]
      } else {
        state.priceData = defaultState.priceData
      }
    })
  },
})

export const selectIndexSectorTickerInfo = (state) => state[slice.name].topInfo
export const selectPriceData = (state) => state[slice.name].priceData
export const selectDisplayInfo = (state) => state[slice.name].displayInfo
export const selectStocks = (state) => state[slice.name].stocks

export const {
  changeIndexSectorTickerInfo,
  subscribeTicker,
  subscribeIndexSector,
  changeDisplayInfo,
  resetDefault,
  resetStore,
} = slice.actions

register(slice.name, slice.reducer)
