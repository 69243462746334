import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import PopupEvent from '../../common/popup/PopupEvent'
import { maxWatchlist, zIndexOverlayPopup } from '../constants'
import style from '../index.module.css'
import { selectWatchlistIds } from '../store/slice'
import PopupSaveAs from './PopupSaveAs'

const GroupButtonSubmit = ({ watchlistId, form }) => {
  const watchlistIds = useSelector(selectWatchlistIds)
  const error = form.useValueError('watchListName')

  const isMaxWatchlist = watchlistIds.length >= maxWatchlist

  const [isShowTooltip, setIsShowTooltip] = useState(false)
  const [isShowPopupSaveAs, setIsShowPopupSaveAs] = useState(false)
  const [isShowPopupCreate, setIsShowPopupCreate] = useState(false)

  const handleOpenTooltip = () => setIsShowTooltip(true)

  const handleCloseTooltip = () => setIsShowTooltip(false)

  const handleClickSaveAs = () => {
    form.changeValues('isSaveAs', true)
    setIsShowPopupSaveAs(true)
  }

  const handleHidePopupSaveAs = () => {
    form.changeValues('isSaveAs', false)
    form.changeValues('watchListNameSaveAs', '')
    form.addTouch('watchListNameSaveAs', false)
    setIsShowPopupSaveAs(false)
  }

  const handleOpenPopupCreate = () => setIsShowPopupCreate(true)

  const handleClosePopupCreate = () => setIsShowPopupCreate(false)

  return (
    <div className="d-flex justify-content-end">
      {watchlistId ? (
        <>
          {isMaxWatchlist && isShowTooltip && (
            <div className="box-notice w-200">
              <Translate value="watchlist.watchlistDetail.EXCEEDED_WL" />
            </div>
          )}
          <Button
            type="button"
            onClick={!isMaxWatchlist ? handleClickSaveAs : undefined}
            onMouseEnter={handleOpenTooltip}
            onMouseLeave={handleCloseTooltip}
            className={isMaxWatchlist ? 'btn normal diasble' : 'btn normal'}
          >
            <Translate value="watchlist.watchlistDetail.BTN_SAVE_AS" />
          </Button>
          <Button
            type="button"
            onClick={error ? undefined : form.handleSubmit}
            disabled={error ? true : false}
            className={error ? 'btn btn-blue diasble' : 'btn btn-blue'}
          >
            <Translate value="common.button.BUTTON_SAVE" />
          </Button>
        </>
      ) : (
        <>
          {isMaxWatchlist && isShowTooltip && (
            <div className={`box-notice w-100 ${style.boxNotice2}`}>
              <Translate value="watchlist.watchlistDetail.EXCEEDED_WL" />
            </div>
          )}
          <Button
            type="button"
            onClick={
              isMaxWatchlist || error ? undefined : handleOpenPopupCreate
            }
            onMouseEnter={handleOpenTooltip}
            onMouseLeave={handleCloseTooltip}
            className={
              isMaxWatchlist || error ? 'btn btn-blue diasble' : 'btn btn-blue'
            }
          >
            <Translate value="watchlist.watchlistDetail.BTN_CREATE" />
          </Button>
        </>
      )}
      {isShowPopupSaveAs && (
        <PopupSaveAs
          form={form}
          handleHidePopupSaveAs={handleHidePopupSaveAs}
        />
      )}
      <PopupEvent
        title={<Translate value="watchlist.watchlistDetail.TITLE_CREATE_2" />}
        message={
          <div>
            <Translate value="watchlist.watchlistDetail.ASK_CREATE" />
          </div>
        }
        zIndexOverlay={zIndexOverlayPopup.alertEvent}
        isShow={isShowPopupCreate}
        handleClose={handleClosePopupCreate}
        btnCancel={{
          btnName: <Translate value="common.button.BUTTON_CANCEL" />,
          event: handleClosePopupCreate,
        }}
        btnSubmit={{
          btnName: <Translate value="watchlist.watchlistDetail.BTN_OK" />,
          event: form.handleSubmit,
        }}
      />
    </div>
  )
}

GroupButtonSubmit.propTypes = {
  watchlistId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  form: PropTypes.object.isRequired,
}

export default GroupButtonSubmit
