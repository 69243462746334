import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import {
  changeEquationIndicatorName,
  selectEquationIndicatorName,
} from './store/slice'
import style from './style.module.css'

const IndicatorName = () => {
  const dispatch = useDispatch()

  const equationIndicatorName = useSelector(selectEquationIndicatorName)

  const handleChange = (e) => {
    dispatch(changeEquationIndicatorName(e.target.value))
  }

  return (
    <div className="align-center" style={{ marginTop: 24 }}>
      <Span
        className={style.textBold}
        style={{ whiteSpace: 'nowrap', marginRight: 8, width: 150 }}
      >
        <Translate value="financialChart.INDICATOR_NAME" />
      </Span>
      <Input
        value={equationIndicatorName}
        className={style.inputIndicatorName}
        onChange={handleChange}
      />
    </div>
  )
}

export default IndicatorName
