import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import useI18n from '../../common/hooks/useI18n'
import { Z_INDEX } from '../../constants/Common'
import DropdownTooltip from './DropdownTooltip'

const TextEllipsis = ({
  text,
  isI18n,
  zIndexTooltip,
  className,
  appendStyle,
}) => {
  const translateText = useI18n(text)

  const containerRef = useRef()
  const textRef = useRef()

  const [isOpenTooltip, setIsOpenTooltip] = useState(false)

  const handleOpenTooltip = () => {
    if (
      textRef.current.getBoundingClientRect().width >
      containerRef.current.getBoundingClientRect().width
    ) {
      setIsOpenTooltip(true)
    }
  }

  const handleCloseTooltip = () => setIsOpenTooltip(false)

  return (
    <>
      <div
        onMouseOver={handleOpenTooltip}
        onMouseOut={handleCloseTooltip}
        ref={containerRef}
        style={{
          ...appendStyle,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <span className={className}>{isI18n ? translateText : text}</span>
      </div>
      <div
        ref={textRef}
        style={{ position: 'absolute', top: -9999, left: -9999, opacity: 0 }}
      >
        {isI18n ? translateText : text}
      </div>
      {isOpenTooltip && (
        <DropdownTooltip
          containerRef={containerRef}
          isOpenTooltip={isOpenTooltip}
          zIndexTooltip={zIndexTooltip}
          isI18n={isI18n}
          text={text}
        />
      )}
    </>
  )
}

TextEllipsis.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  isI18n: PropTypes.bool,
  zIndexTooltip: PropTypes.number,
  className: PropTypes.string,
  appendStyle: PropTypes.object,
}

TextEllipsis.defaultProps = {
  isI18n: true,
  zIndexTooltip: Z_INDEX.MODAL + 1,
  className: '',
  appendStyle: {},
}

export default TextEllipsis
