import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleChartData, handleKeyWithTitle } from '../helper'
import { getOutstandingVolume } from './thunk'

const initialState = {
  chartData: [],
  chartLoading: true,
  dailyValue: 'OneMonth',
  weeklyValue: 'ThreeMonths',
  monthlyValue: 'OneYear',
  keyWithTitle: {},
}

const slice = createSlice({
  name: 'economy/monetary/openMarket/panelShareOutstanding',
  initialState,
  reducers: {
    handleDailyValue: (state, action) => {
      state.dailyValue = action.payload
    },
    handleWeeklyValue: (state, action) => {
      state.weeklyValue = action.payload
    },
    handleMonthlyValue: (state, action) => {
      state.monthlyValue = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOutstandingVolume.pending, (state) => {
      state.chartLoading = true
    })
    builder.addCase(getOutstandingVolume.fulfilled, (state, action) => {
      state.chartLoading = false
      state.chartData = handleChartData(action.payload || [])
      state.keyWithTitle = handleKeyWithTitle(action.payload || [])
    })
    builder.addCase(getOutstandingVolume.rejected, (state, action) => {
      state.chartLoading = action.payload.isLoading
    })
  },
})

export const { handleDailyValue, handleWeeklyValue, handleMonthlyValue } =
  slice.actions

export const getDailyValue = (state) => state[slice.name].dailyValue
export const getWeeklyValue = (state) => state[slice.name].weeklyValue
export const getMonthlyValue = (state) => state[slice.name].monthlyValue
export const getChartData = (state) => state[slice.name].chartData
export const getChartLoading = (state) => state[slice.name].chartLoading
export const getKeyWithTitle = (state) => state[slice.name].keyWithTitle

register(slice.name, slice.reducer)
