export const chartFillColor = {
  rT0285: '#facc5c',
  rT0286: '#df5a49',
  rT0287: '#45b29d',
}

export const chartBarKeys = ['rT0285']

export const chartLineKeys = ['rT0286', 'rT0287']

export const chartMappingDisplayName = {
  rT0285: 'sector.financialAnalysis.bank.profitability.NIM',
  rT0286: 'sector.financialAnalysis.bank.profitability.YOEA',
  rT0287: 'sector.financialAnalysis.bank.profitability.COF',
}

export const chartFormat = {
  rT0285: 0.01,
  rT0286: 0.01,
  rT0287: 0.01,
}
