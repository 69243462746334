export const SIMPLE_SEARCH_PARAMS = {
  page: 1,
  pageSize: 25,
}

export const DEFAULT_WARRANT = {
  coveredWarrantId: '',
  coveredWarrantName: '',
  ticker: '',
}
