import { COLORS, INDICATOR_OPTION, ITEM_TYPE, TIME_FRAME } from './constant'

export const getColor = (items) => {
  const usedColor = items.map((item) => item.color)
  for (let i = 0; i < COLORS.length; i++) {
    if (!usedColor.includes(i)) {
      return i
    }
  }
  return 0
}

export const getTimeFrameTitleByValue = (value) => {
  return Object.values(TIME_FRAME).find((item) => item.value === value)?.title
}

export const getIndicatorNameByValue = (value) => {
  return Object.values(INDICATOR_OPTION).find((item) => item.value === value)
    ?.name
}

export const setLevels = (rowIds) => {
  let sectorIdNearest = ''
  return rowIds.map((rowId) => {
    const isSector = rowId.startsWith(ITEM_TYPE.SECTOR)
    if (isSector) {
      sectorIdNearest = rowId
      return {
        id: rowId,
        level: 1,
        parentId: rowId,
      }
    }

    return {
      id: rowId,
      level: 2,
      parentId: sectorIdNearest,
    }
  })
}
