import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { Button } from '../../../../common/html/Button'
import { maxWatchlist } from '../../../../myWatchlist/constants'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import {
  selectWatchlistInformation,
  selectWatchlists,
  selectWatchlistTickerByIds,
  selectWatchlistTickerIds,
} from '../../store/slice'
import { createWatchlistThunk, updateWatchlistThunk } from '../../store/thunk'
import { keyWatchlistInformation, listRadio } from './WatchlistInformation'

const ButtonSubmit = ({ handleHidePopup }) => {
  const dispatch = useDispatch()

  const watchlists = useSelector(selectWatchlists)
  const tickerIds = useSelector(selectWatchlistTickerIds)
  const tickerByIds = useSelector(selectWatchlistTickerByIds)
  const watchlistInformation = useSelector(selectWatchlistInformation)

  const handleSubmit = () => {
    const watchListId =
      watchlistInformation[keyWatchlistInformation.WATCHLIST_ID]
    const watchListName =
      watchlistInformation[keyWatchlistInformation.WATCHLIST_NAME].trim()
    const type = watchlistInformation[keyWatchlistInformation.TYPE]

    if (type === listRadio.CREATE) {
      if (!watchListName) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('watchlist.watchlistDetail.validate.NAME_REQUIRE'),
        ])
      } else if (watchListName.length > 100) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('watchlist.watchlistDetail.validate.NAME_MAX'),
        ])
      } else if (
        watchlists.some((item) => item.watchListName === watchListName)
      ) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('watchlist.watchlistDetail.validate.NAME_DUPLICATE'),
        ])
      } else if (watchlists.length >= maxWatchlist) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('watchlist.watchlistDetail.EXCEEDED_WL'),
        ])
      } else {
        handleHidePopup()
        dispatch(
          createWatchlistThunk({
            watchListName,
            description: '',
            watchListDetails: tickerIds
              .filter((id) => tickerByIds[id].checked)
              .map((id, index) => {
                return {
                  organizationId: id,
                  organizationOrder: index + 1,
                  tickerOrTaxCode: tickerByIds[id].ticker,
                }
              }),
          }),
        )
      }
    } else {
      if (!watchListId) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('tool.smartScreening.WATCHLIST_ID_REQUIRE'),
        ])
      } else {
        const watchlist = watchlists.find(
          (item) => item.watchListId + '' === watchListId + '',
        )
        handleHidePopup()
        dispatch(
          updateWatchlistThunk({
            watchListId: watchlist.watchListId,
            watchListName: watchlist.watchListName,
            description: watchlist.description,
            watchListDetails: tickerIds
              .filter((id) => tickerByIds[id].checked)
              .map((id, index) => {
                return {
                  organizationId: id,
                  organizationOrder: index + 1,
                  tickerOrTaxCode: tickerByIds[id].ticker,
                }
              }),
          }),
        )
      }
    }
  }

  return (
    <Button
      style={{ fontSize: 11 }}
      onClick={handleSubmit}
      className="btn btn-blue w-80 h-20"
    >
      {watchlistInformation[keyWatchlistInformation.TYPE] ===
      listRadio.CREATE ? (
        <Translate value="tool.smartScreening.CREATE" />
      ) : (
        <Translate value="tool.smartScreening.UPDATE" />
      )}
    </Button>
  )
}

ButtonSubmit.propTypes = {
  handleHidePopup: PropTypes.func.isRequired,
}

export default ButtonSubmit
