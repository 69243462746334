import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import DirectorDealService from '../../../../../../core/services/market/marketInDepth/DirectorDealService'

export const getNetValueInternalTrading = createAsyncThunk(
  'market/marketInDepth/netValueOfInternalTrading/GET_DIRECTOR_DEAL_SERVICE',
  async (data, { rejectWithValue }) => {
    try {
      const response = await DirectorDealService.getNetValueInternalTrading(
        data,
      )
      if (response.success) {
        return response.data
      }
      return rejectWithValue({ loading: false })
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
