import {
  chromeVersion,
  latestChromeVersionAffectToScale,
} from '../common/hooks/useWindowDevicePixelRatio'
import { LOCAL_STORAGE_KEY } from '../constants/Common'

export const isWindowsOs = () => {
  return getOs() === 'Windows'
}

export const getOs = () => {
  const os = ['Windows', 'Linux', 'Mac'] // add your OS values
  return os.find((v) => navigator.appVersion.indexOf(v) >= 0)
}

export const getInnerHeight = () => {
  return window.devicePixelRatio && isWindowsOs()
    ? window.innerHeight * window.devicePixelRatio
    : window.innerHeight
}

export const getUserAgent = () => {
  return navigator.userAgent
}

export const getLocationUser = () => {
  if (navigator.geolocation) {
    let pos = null
    navigator.geolocation.getCurrentPosition((position) => {
      pos = position
    })
    return pos
  }

  return null
}

export const setDeviceKey = (username, value) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.DEVICE_KEY + username, value)
}

export const getDeviceKey = (username) => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.DEVICE_KEY + username) || null
}

export const setAuthTime = (username, value) => {
  localStorage.setItem(LOCAL_STORAGE_KEY.AUTH_TIME + username, value)
}

export const getAuthTime = (username) => {
  return localStorage.getItem(LOCAL_STORAGE_KEY.AUTH_TIME + username) || null
}

export const formatWithDevicePixelRatio = (val) => {
  if (+chromeVersion < latestChromeVersionAffectToScale) return val
  return isWindowsOs() ? val * window.devicePixelRatio : val
}
