import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { range } from '../../../../utils/Common'
import style from '../index.module.css'
import { selectValueDetailPopupTemplate } from '../store/slice'
import ItemSelect from './ItemSelect'

const thisYear = new Date().getFullYear()

const YearFromTo = ({ keyFrom, keyTo }) => {
  const valueFrom =
    useSelector(selectValueDetailPopupTemplate(keyFrom)) || thisYear
  const valueTo = useSelector(selectValueDetailPopupTemplate(keyTo)) || thisYear

  const selectDataFrom = range(2000, valueTo)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })
  const selectDataTo = range(valueFrom, thisYear)
    .reverse()
    .map((year) => {
      return { value: year, name: year }
    })

  return (
    <>
      <div className={style.from}>
        <span>
          <Translate value="tool.dataExplorer.FROM" />
        </span>
        <div className={style.width110}>
          <ItemSelect
            defaultValue={thisYear - 1}
            keySelect={keyFrom}
            selectData={selectDataFrom}
          />
        </div>
      </div>
      <div className={style.to}>
        <span>
          <Translate value="tool.dataExplorer.TO" />
        </span>
        <div className={style.width110}>
          <ItemSelect
            defaultValue={thisYear}
            keySelect={keyTo}
            selectData={selectDataTo}
          />
        </div>
      </div>
    </>
  )
}

YearFromTo.propTypes = {
  keyFrom: PropTypes.string.isRequired,
  keyTo: PropTypes.string.isRequired,
}

export default YearFromTo
