import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { MOUSE_OUT_TH } from '../../../../common/dataExplorer/table/Thead'
import { Span } from '../../../../common/html/Span'
import EventEmitter from '../../../../utils/EventEmitter'
import style from '../index.module.css'
import {
  removeColumn,
  selectActiveSheet,
  selectColumnCell,
  selectIndicatorById,
} from '../store/slice'

const TitleColumn = ({ colId }) => {
  const activeSheet = useSelector(selectActiveSheet)
  const column = useSelector(selectColumnCell(activeSheet)(colId))
  const indicatorById = useSelector(selectIndicatorById)

  return (
    <>
      <div className={style.indicatorName}>
        {indicatorById[column.indicatorId]?.indicatorName}
      </div>
      {indicatorById[column.indicatorId]?.unit && (
        <div className={style.unit}>
          <Translate value="tool.dataExplorer.corporate.UNIT" />:{' '}
          {indicatorById[column.indicatorId].unit}
        </div>
      )}
    </>
  )
}

const ThTable = ({ colId }) => {
  const dispatch = useDispatch()

  const handleRemoveColumn = (e) => {
    e.stopPropagation()
    dispatch(removeColumn([colId]))
    EventEmitter.dispatch(MOUSE_OUT_TH)
  }

  return (
    <div className="d-flex j-b">
      <div className={style.contentTh}>
        <Span style={{ fontSize: 10 }}>
          <TitleColumn colId={colId} />
        </Span>
      </div>
      <i
        onClick={handleRemoveColumn}
        className={`icon-delete-stroke cursor-pointer ${style.iconTh}`}
      />
    </div>
  )
}

export default ThTable
