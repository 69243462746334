import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import InterestRateService from '../../../../../../core/services/economy/InterestRateService'
import { handleExport } from '../../../../../utils/Export'

export const getStatisticOtherBank = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateCommercial/getStatisticOtherBank',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getStatisticOtherBank(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const getMaxDate = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateCommercial/getMaxDate',
  async (params, { rejectWithValue }) => {
    try {
      const res = await InterestRateService.getMaxDate(params)
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)

export const downloadStatisticOtherBank = createAsyncThunk(
  'economy/monetary/interestRate/panelDepositRateCommercial/downloadStatisticOtherBank',
  async (data) => {
    const res = await InterestRateService.downloadStatisticOtherBank(data)
    handleExport(res.data, res.filename)
  },
)
