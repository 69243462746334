import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../common/ScrollGroupComponent'
import { SizeTracker } from '../../common/sizeTracker'
import { IndexSectorTickerTopInfo } from '../../common/topInfo/indexSectorTickerTopInfo'
import {
  changeDisplayInfo,
  selectIndexSectorTickerInfo,
} from '../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import Content from './Content'
import {
  changeCheckedExchange,
  changeCheckedSector,
  changeParentCheckedExchange,
  changeParentCheckedSector,
  changeSelectedWatchList,
  key,
  resetFilter,
  selectFilter,
  selectIsCheckedAll,
} from './store/slice'
import { getEventsThunk, getFiinXEventTypesThunk } from './store/thunk'

export const Events = () => {
  const dispatch = useDispatch()

  const onSelect = () => {
    dispatch(changeSelectedWatchList(null))
    dispatch(resetFilter())
  }

  return (
    <>
      <ScrollGroupComponent>
        <SizeTracker>
          {(size) => {
            return (
              <>
                <IndexSectorTickerTopInfo onSelect={onSelect} />
                {size.height && (
                  <div
                    style={{
                      height: `calc(100% - ${size.height}px)`,
                      position: 'relative',
                    }}
                  >
                    <Content />
                  </div>
                )}
              </>
            )
          }}
        </SizeTracker>
      </ScrollGroupComponent>
      <ListenGetEvents />
    </>
  )
}

const ListenGetEvents = () => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const filter = useSelector(selectFilter)
  const isCheckedAllEventType = useSelector(selectIsCheckedAll(key.EVENT_TYPE))
  const isCheckedAllCompany = useSelector(selectIsCheckedAll(key.COMPANY))
  const indexSectorTickerInfo = useSelector(selectIndexSectorTickerInfo)

  const params = {
    StartDate: filter.startDate,
    EndDate: filter.endDate,
    StartRecordDate: filter.startRecordDate,
    EndRecordDate: filter.endRecordDate,
    StartExrightDate: filter.startExrightDate,
    EndExrightDate: filter.endExrightDate,
    StartExerciseDate: filter.startExerciseDate,
    EndExerciseDate: filter.endExerciseDate,
    SortField: filter.sortField,
    SortOrder: filter.sortOrder,
    Page: filter.page,
    PageSize: filter.pageSize,
    language: locale,
  }

  useEffect(() => {
    dispatch(getFiinXEventTypesThunk({ language: locale }))
    dispatch(changeCheckedExchange(filter.groupId))
    dispatch(changeCheckedSector(filter.icbId))
    dispatch(changeParentCheckedExchange(filter.groupId))
    dispatch(changeParentCheckedSector(filter.icbId))
  }, [locale])

  useEffect(() => {
    if (
      (indexSectorTickerInfo.organizationId ||
        indexSectorTickerInfo.groupId ||
        (indexSectorTickerInfo.icbId &&
          !indexSectorTickerInfo.organizationId)) &&
      filter.organizationId.length === 0 &&
      filter.groupId.length === 0 &&
      filter.icbId.length === 0 &&
      filter.watchlistId.length === 0
    ) {
      dispatch(
        getEventsThunk({
          GroupId: indexSectorTickerInfo.groupId
            ? [indexSectorTickerInfo.groupId]
            : [],
          OrganizationId: indexSectorTickerInfo.organizationId
            ? [indexSectorTickerInfo.organizationId]
            : [],
          IcbId:
            indexSectorTickerInfo.icbId && !indexSectorTickerInfo.organizationId
              ? [indexSectorTickerInfo.icbId]
              : [],
          WatchListId: [],
          FiinXEventTypeIds: isCheckedAllEventType
            ? []
            : filter.fiinXEventTypeIds,
          ...params,
        }),
      )
    } else if (
      filter.organizationId.length > 0 ||
      filter.groupId.length > 0 ||
      filter.icbId.length > 0 ||
      filter.watchlistId.length > 0
    ) {
      dispatch(
        getEventsThunk({
          GroupId: filter.groupId,
          OrganizationId: isCheckedAllCompany ? [] : filter.organizationId,
          IcbId: filter.icbId,
          WatchListId: filter.watchlistId,
          FiinXEventTypeIds: isCheckedAllEventType
            ? []
            : filter.fiinXEventTypeIds,
          ...params,
        }),
      )
    }
  }, [
    JSON.stringify(filter.organizationId),
    JSON.stringify(filter.groupId),
    JSON.stringify(filter.watchlistId),
    JSON.stringify(filter.icbId),
    JSON.stringify(filter.fiinXEventTypeIds),
    filter.startDate,
    filter.endDate,
    filter.startRecordDate,
    filter.endRecordDate,
    filter.startExrightDate,
    filter.endExrightDate,
    filter.startExerciseDate,
    filter.endExerciseDate,
    filter.sortField,
    filter.sortOrder,
    filter.page,
    filter.pageSize,
    indexSectorTickerInfo.organizationId,
    indexSectorTickerInfo.groupId,
    !indexSectorTickerInfo.organizationId && indexSectorTickerInfo.icbId,
    locale,
  ])

  useEffect(() => {
    if (
      filter.organizationId.length ||
      filter.groupId.length ||
      filter.watchlistId.length ||
      filter.icbId.length
    ) {
      dispatch(changeDisplayInfo(false))
    }
  }, [
    JSON.stringify(filter.organizationId),
    JSON.stringify(filter.groupId),
    JSON.stringify(filter.watchlistId),
    JSON.stringify(filter.icbId),
    locale,
  ])

  return null
}
