import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import Title from '../../../common/topInfo/components/Title'
import Content from './Content'
import Filter from './Filter'
import Footer from './Footer'
import PopupDetail from './PopUpDetail'
import { resetStore, selectBondDetailSelected } from './store/slice'

export const PriceBoard = () => {
  const dispatch = useDispatch()

  const item = useSelector(selectBondDetailSelected)

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => (
          <>
            <div className="top-info">
              <Title title="bond.corporateBond.priceBoard.TITLE" />
            </div>
            <Filter />
            {size.height && (
              <div
                style={{
                  position: 'relative',
                  flex: 1,
                  height: `calc(100% - ${size.height + 28}px)`,
                }}
              >
                <Content />
              </div>
            )}
            {item && <PopupDetail />}
            <Footer />
          </>
        )}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
