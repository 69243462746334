import { differenceWith, isEqual } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../common/hooks/useTimeZone'
import { Button } from '../../../../common/html/Button'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import PopupAlert, {
  POPUP_ICON_STATUS,
} from '../../../../common/popup/PopupAlert'
import PopupAlertSuccess from '../../../../common/popup/PopupAlertSuccess'
import { TooltipInfo } from '../../../../common/tooltipInfo'
import { getISOStartOrEndOfDay } from '../../../../utils/Datetime'
import PopupCreateBond from '../../../bondScreening/BondList/Popup/PopupCreateBond'
import PopupAddToWatchlist from '../../Popup/PopupAddToWatchlist'
import PopupConfirmValuationDate from '../../Popup/PopupConfirmValuationDate'
import PopupDeletePortfolio from '../../Popup/PopupDeletePortfolio'
import PopupDeleteWatchlist from '../../Popup/PopupDeleteWatchlist'
import PopupSavePortfolio from '../../Popup/PopupSavePortfolio'
import { FILTER_BOND_INFORMATION_TYPES, MAX_LENGTH } from '../../constants'
import { getDateWithTimezone } from '../../helper'
import {
  changeErrorMaxLength,
  changeFilterBondInformation,
  changeSaveStatus,
  changeStatusCreateWatchlist,
  changeStatusUpdateWatchlist,
  changeUpdateStatus,
  keys,
  resetDataBondInformation,
  selectCreateBondStatus,
  selectCreateWatchlistStatus,
  selectDataBondInformation,
  selectErrorMaxLength,
  selectFilterBondInformation,
  selectLoading,
  selectPortfolioBondInformation,
  selectSavePortfolioStatus,
  selectUpdatePortfolioStatus,
  selectUpdateWatchlistStatus,
  selectWatchlistBondInformation,
} from '../../store/slice'
import {
  addPortfolioData,
  addPortfolioDataWithClearData,
  changeValuationDatePortfolioInvestmentData,
  createBondPortfolio,
  createWatchlistData,
  deletePortfolioData,
  deleteWatchlistData,
  getPortfolioInvestmentByIdData,
  getPortfolioInvestmentData,
  getWatchListDetailData,
  renamePortfolioData,
  renameWatchListData,
  updatePortfolioData,
  updatePortfolioDataWithClearData,
  updateWatchlistData,
} from '../../store/thunk'
import { FilterBond } from './FilterBond'
import FilterPortfolio from './FilterPortfolio'
import FilterWatchlist from './FilterWatchlist'
import style from './index.module.css'

const Filter = () => {
  const dispatch = useDispatch()

  const timeZone = UseTimeZone()

  const locale = useSelector((state) => state.i18n.locale)
  const portfolio = useSelector(selectPortfolioBondInformation)
  const watchList = useSelector(selectWatchlistBondInformation)
  const { valuationDate, activeTab, ...restFilter } = useSelector(
    selectFilterBondInformation,
  )
  const { data, initialData } = useSelector(selectDataBondInformation)
  const saveStatus = useSelector(selectSavePortfolioStatus)
  const updateStatus = useSelector(selectUpdatePortfolioStatus)
  const errorMaxLength = useSelector(selectErrorMaxLength)
  const createWatchlistStatus = useSelector(selectCreateWatchlistStatus)
  const updateWatchlistStatus = useSelector(selectUpdateWatchlistStatus)
  const isLoading = useSelector(selectLoading(keys.BOND_INFORMATION))
  const isCreateBond = useSelector(selectCreateBondStatus)

  const [isShowDate, setIsShowDate] = useState(false)
  const [isCheckValuationDate, setIsCheckValuationDate] = useState(false)
  const [isShowPopupSavePortfolio, setIsShowPopupSavePortfolio] =
    useState(false)
  const [isShowPopupSaveSuccess, setIsShowPopupSuccess] = useState(false)
  const [isShowPopupUpdateSuccess, setIsShowPopupUpdateSuccess] =
    useState(false)
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false)
  const [
    isShowPopupChangePortfolioConfirm,
    setIsShowPopupChangePortfolioConfirm,
  ] = useState(false)
  const [isShowPopupDeletePortfolio, setIsShowPopupDeletePortfolio] =
    useState(null)
  const [isShowPopupRenamePortfolio, setIsShowPopupRenamePortfolio] =
    useState(false)
  const [isShowPopupDeleteWatchlist, setIsShowPopupDeleteWatchlist] =
    useState(null)
  const [isShowPopupRenameWatchlist, setIsShowPopupRenameWatchlist] =
    useState(false)
  const [isShowPopupAddToWatchlist, setIsShowPopupAddToWatchlist] =
    useState(false)
  const [
    isShowPopupCreateWatchlistSuccess,
    setIsShowPopupCreateWatchlistSuccess,
  ] = useState(false)
  const [
    isShowPopupUpdateWatchlistSuccess,
    setIsShowPopupUpdateWatchlistSuccess,
  ] = useState(false)
  const [errorWatchlist, setErrorWatchlist] = useState(false)
  const [date, setDate] = useState(null)
  const [portfolioId, setPortfolioId] = useState(null)
  const [isChangePortfolio, setIsChangePortfolio] = useState(false)
  const [isClear, setIsClear] = useState(false)
  const [showPopupCreate, setIsShowPopupCreate] = useState(false)

  const dataNotTotal = useMemo(
    () => data?.filter((item) => item.id !== 'total'),
    [data],
  )

  const handleChangePortfolio = (value) => {
    setPortfolioId(value)

    if (
      !restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID] &&
      !!dataNotTotal?.length
    ) {
      setIsShowPopupChangePortfolioConfirm(true)
      return
    }

    if (restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]) {
      const dataFormatted = dataNotTotal.map((item) => {
        const {
          bondId,
          interestBasisTypeId,
          tradingDateId,
          valuationDate,
          bondVolume,
          buyPrice,
          ytm,
          dirtyPrice,
          cleanPrice,
        } = item

        return {
          bondId,
          interestBasisTypeId,
          tradingDateId,
          valuationDate,
          bondVolume,
          buyPrice,
          ytm,
          dirtyPrice,
          cleanPrice,
        }
      })

      const check = differenceWith(initialData, dataFormatted, isEqual)
      const check2 = differenceWith(dataFormatted, initialData, isEqual)

      if (!check?.length && !check2?.length) {
        const params = {
          [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: value,
        }

        dispatch(
          changeFilterBondInformation({
            key: FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID,
            value: value,
          }),
        )

        dispatch(getPortfolioInvestmentByIdData(params))
        return
      }

      setIsShowPopupChangePortfolioConfirm(true)
      return
    }

    const params = {
      [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: value,
    }

    dispatch(
      changeFilterBondInformation({
        key: FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID,
        value: value,
      }),
    )

    dispatch(getPortfolioInvestmentData(params))
  }

  const handleChangeWatchlist = (value) => {
    const params = {
      [FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]: value,
    }

    dispatch(getWatchListDetailData(params))
  }

  const handleApplyValuationDate = () => {
    if (!data?.length) return

    const check = data
      .filter((item) => item.id !== 'total')
      .every((item) => {
        const tradingDateUnix = moment(item?.tradingDateId).unix()
        const valuationDateUnix = moment(date).unix()
        const issueDateUnix = moment(item?.issueDateId).unix()
        const maturityDateUnix = moment(item?.maturityDateId).unix()

        return (
          valuationDateUnix - tradingDateUnix > 0 &&
          valuationDateUnix - issueDateUnix > 0 &&
          valuationDateUnix - maturityDateUnix < 0
        )
      })

    if (!check) {
      setIsCheckValuationDate(true)
      setIsShowDate(false)
      return
    }

    const newData = data
      .filter((item) => item.id !== 'total')
      .map((item) => {
        const {
          isUserBond,
          bondId,
          ytm,
          dirtyPrice,
          cleanPrice,
          bondVolume,
          tradingDateId,
          interestBasisTypeId,
          buyPrice,
        } = item

        return {
          bondId,
          isUserBond: isUserBond ?? false,
          tradingDate: tradingDateId,
          valuationDate: getISOStartOrEndOfDay(date, timeZone, true),
          bondVolume: parseInt(bondVolume),
          interestBasisTypeId: interestBasisTypeId,
          ytm: ytm ?? null,
          dirtyPrice: dirtyPrice ?? null,
          cleanPrice: cleanPrice ?? null,
          buyPrice,
        }
      })

    const params = {
      bonds: newData,
    }

    dispatch(changeValuationDatePortfolioInvestmentData(params))

    dispatch(
      changeFilterBondInformation({
        key: 'valuationDate',
        value: getISOStartOrEndOfDay(date, timeZone, true),
      }),
    )

    setIsShowDate(false)
  }

  const handleChangeValuationDate = (value) => {
    setDate(getISOStartOrEndOfDay(value, timeZone, true))

    if (!data?.length) return

    setIsShowDate(true)
  }

  const handleNotSaveToPortfolio = () => {
    dispatch(resetDataBondInformation())
    dispatch(
      changeFilterBondInformation({
        key: FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID,
        value: null,
      }),
    )
    dispatch(
      changeFilterBondInformation({
        key: FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID,
        value: null,
      }),
    )
    setIsShowPopupConfirm(false)
  }

  const handleNotSaveWhileChangePortfolio = () => {
    const params = {
      [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]: portfolioId,
    }

    dispatch(getPortfolioInvestmentByIdData(params))

    dispatch(
      changeFilterBondInformation({
        key: FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID,
        value: portfolioId,
      }),
    )
    dispatch(
      changeFilterBondInformation({
        key: FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID,
        value: null,
      }),
    )
    setIsShowPopupChangePortfolioConfirm(false)
  }

  const handleSavePortfolio = (name) => {
    const params = {
      portfolioName: name,
      description: '',
      portfolioDetails: data
        .filter((item) => item.id !== 'total')
        .map((item) => {
          const {
            isUserBond,
            bondId,
            ytm,
            dirtyPrice,
            cleanPrice,
            bondVolume,
            tradingDateId,
            interestBasisTypeId,
            valuationDate,
            buyPrice,
            id,
          } = item

          return {
            bondId,
            isUserBond: isUserBond ?? false,
            tradingDateId,
            valuationDateId: valuationDate,
            bondVolume: parseInt(bondVolume),
            interestBasisTypeId,
            ytm: ytm ?? 0,
            dirtyPrice: dirtyPrice ?? 0,
            cleanPrice: cleanPrice ?? 0,
            buyPrice,
            bondOrder: id - 1,
          }
        }),
    }

    if (isClear) {
      dispatch(addPortfolioData({ params }))
    } else {
      dispatch(addPortfolioDataWithClearData({ params }))
    }

    if (isChangePortfolio) {
      setIsChangePortfolio(false)
      handleNotSaveWhileChangePortfolio()
    } else if (!isClear) {
      handleNotSaveToPortfolio()
    }

    setIsClear(false)
    setIsShowPopupSavePortfolio(false)
  }

  const handleUpdatePortfolio = ({ isChange, isClearData }) => {
    const newPortfolioId = isChange
      ? restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]
      : portfolioId

    if (!newPortfolioId) {
      setIsShowPopupSavePortfolio(true)
      setIsClear(isClearData)
      return
    }

    const portfolioSelected = portfolio.find(
      (item) => item?.portfolioId === newPortfolioId,
    )

    if (!portfolioSelected) return

    const params = {
      portfolioId: newPortfolioId,
      portfolioDetails: data
        .filter((item) => item.id !== 'total')
        .map((item) => {
          const {
            isUserBond,
            bondId,
            ytm,
            dirtyPrice,
            cleanPrice,
            bondVolume,
            tradingDateId,
            interestBasisTypeId,
            valuationDate,
            buyPrice,
            id,
          } = item

          return {
            bondId,
            isUserBond: isUserBond ?? false,
            tradingDateId,
            valuationDateId: valuationDate,
            bondVolume: parseInt(bondVolume),
            interestBasisTypeId,
            ytm: ytm ?? 0,
            dirtyPrice: dirtyPrice ?? 0,
            cleanPrice: cleanPrice ?? 0,
            buyPrice,
            bondOrder: id - 1,
          }
        }),
    }

    dispatch(
      changeFilterBondInformation({
        key: [FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID],
        value: portfolioId,
      }),
    )

    if (isClearData) {
      dispatch(updatePortfolioData({ params }))
      setIsClear(false)
    } else {
      dispatch(updatePortfolioDataWithClearData({ params }))

      if (isChange) {
        handleNotSaveWhileChangePortfolio()
      } else {
        handleNotSaveToPortfolio()
      }
    }
  }

  const handleDeletePortfolio = () => {
    if (!isShowPopupDeletePortfolio?.portfolioId) return

    const params = {
      portfolioIds: [isShowPopupDeletePortfolio?.portfolioId],
    }

    dispatch(deletePortfolioData(params))
    setIsShowPopupDeletePortfolio(null)
  }

  const handleDeleteWatchlist = () => {
    if (!isShowPopupDeleteWatchlist?.bondWatchListId) return

    const params = {
      bondWatchlistId: [isShowPopupDeleteWatchlist?.bondWatchListId],
    }

    dispatch(deleteWatchlistData(params))
    setIsShowPopupDeleteWatchlist(null)
  }

  const handleRenamePortfolio = (portfolioName, portfolioId) => {
    if (!portfolioId) return

    const check = portfolio.some(
      (item) => item?.portfolioName?.trim() === portfolioName?.trim(),
    )

    if (check) {
      setIsShowPopupRenamePortfolio(true)
      return
    }

    const params = {
      portfolioId,
      portfolioName,
    }

    dispatch(renamePortfolioData(params))
  }

  const handleRenameWatchlist = (watchlistName, watchListId) => {
    if (!watchListId) return

    const check = watchList.some(
      (item) => item?.bondWatchListName?.trim() === watchlistName?.trim(),
    )

    if (check) {
      setIsShowPopupRenameWatchlist(true)
      return
    }

    const params = {
      bondWatchListId: watchListId,
      bondWatchListName: watchlistName,
      description: '',
    }

    dispatch(renameWatchListData(params))
  }

  const handleCreateWatchlist = (watchlistName, items) => {
    const check = watchList.some(
      (item) => item?.bondWatchListName?.trim() === watchlistName?.trim(),
    )

    if (check) {
      setIsShowPopupRenameWatchlist(true)
      return
    }

    const params = {
      watchlistName,
      watchlistDescription: '',
      bondWatchlistDetailsList: items.map((item, index) => ({
        ...item,
        bondOrder: index + 1,
      })),
    }

    dispatch(createWatchlistData(params))
    setIsShowPopupAddToWatchlist(false)
  }

  const handleUpdateWatchlist = (watchlistId, items) => {
    if (!watchlistId) {
      setErrorWatchlist(true)
      return
    }

    const watchlist = watchList.find(
      (item) => item?.bondWatchListId === watchlistId,
    )

    if (!watchlist) return

    const params = {
      bondWatchlistId: watchlist.bondWatchListId,
      bondWatchlistDetails: items.map((item, index) => ({
        ...item,
        bondOrder: index + 1,
      })),
    }

    dispatch(updateWatchlistData(params))
    setIsShowPopupAddToWatchlist(false)
  }

  const handleCreateBond = (params) => {
    dispatch(createBondPortfolio({ params, locale }))
  }

  const textPlaceholderPortfolio = useMemo(
    () => I18n.t('bond.portfolio.bondInformation.CHOOSE_PORTFOLIO'),
    [locale],
  )

  const textPlaceholderWatchlist = useMemo(
    () => I18n.t('bond.portfolio.bondInformation.CHOOSE_WATCHLIST'),
    [locale],
  )

  const year = new Date().getFullYear()

  const customHeader = HeaderOneCalendarRange({
    startYear: new Date(new Date().setFullYear(year - 10)).getFullYear(),
    endYear: new Date(new Date().setFullYear(year + 3)).getFullYear(),
  })

  useEffect(() => {
    if (!!saveStatus?.length) {
      setIsShowPopupSuccess(true)
    }
  }, [saveStatus])

  useEffect(() => {
    if (!!updateStatus?.length) {
      setIsShowPopupUpdateSuccess(true)
    }
  }, [updateStatus])

  useEffect(() => {
    setDate(valuationDate)
  }, [valuationDate])

  useEffect(() => {
    if (!!createWatchlistStatus?.length) {
      setIsShowPopupCreateWatchlistSuccess(true)
    }
  }, [createWatchlistStatus])

  useEffect(() => {
    if (!!updateWatchlistStatus?.length) {
      setIsShowPopupUpdateWatchlistSuccess(true)
    }
  }, [updateWatchlistStatus])

  useEffect(() => {
    setPortfolioId(restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID])
  }, [restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]])

  useEffect(() => {
    if (isCreateBond) {
      setIsShowPopupCreate(false)
    }
  }, [isCreateBond])

  return (
    <div
      className="d-flex ali-center mb-8"
      style={{ flexWrap: 'wrap', gap: 8 }}
    >
      <div className="d-flex ali-center" style={{ gap: 16, flex: 1 }}>
        <FilterBond width={200} />
        <FilterPortfolio
          data={portfolio}
          value={restFilter[FILTER_BOND_INFORMATION_TYPES.PORTFOLIO_ID]}
          handleClick={(value) => handleChangePortfolio(value)}
          handleDelete={(portfolio) => setIsShowPopupDeletePortfolio(portfolio)}
          handleEdit={handleRenamePortfolio}
          placeholder={textPlaceholderPortfolio}
          styleContainer={{ width: 150 }}
        />
        <FilterWatchlist
          data={watchList}
          value={restFilter[FILTER_BOND_INFORMATION_TYPES.WATCHLIST_ID]}
          handleClick={(value) => handleChangeWatchlist(value)}
          handleDelete={(watchlist) => setIsShowPopupDeleteWatchlist(watchlist)}
          handleEdit={handleRenameWatchlist}
          placeholder={textPlaceholderWatchlist}
          styleContainer={{ width: 150 }}
        />
        <div style={{ minWidth: 100 }}>
          <Button
            className="btn btn-blue h-20"
            style={{
              padding: '0 4px',
              fontWeight: 600,
              boxSizing: 'border-box',
            }}
            onClick={() => setIsShowPopupCreate(true)}
          >
            <Translate value="bond.portfolio.bondInformation.CREATE_BOND" />
          </Button>
        </div>
        <div className="d-flex ali-center">
          <Span style={{ fontWeight: 600, flexShrink: 0 }}>
            <Translate value="bond.portfolio.bondInformation.VALUATION_DATE" />
          </Span>
          <TooltipInfo
            style={{ marginTop: 3, marginLeft: 3, marginRight: 3 }}
            fontSize={12}
            tooltip="bond.portfolio.bondInformation.VALUATION_DATE_TOOLTIP"
          />
          <Span style={{ fontWeight: 600 }}>:</Span>
          <div
            className="form-control calendar mb-0"
            style={{ marginLeft: 8, width: 120 }}
          >
            <div className={`${style.bgGrey} w-100`}>
              <CalendarPopper
                date={valuationDate ? getDateWithTimezone(valuationDate) : null}
                handleChange={(value) => {
                  handleChangeValuationDate(value)
                }}
                customHeader={customHeader}
                CustomInput={InputDateCalendar}
              />
              <Icon
                className="icon-date"
                style={{ fontSize: 12, pointerEvents: 'none' }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex ali-center">
        <Button
          className="btn btn-yellow h-20"
          style={{
            padding: '0 4px',
            fontWeight: 600,
            boxSizing: 'border-box',
            minWidth: 185,
            marginRight: 16,
            opacity: !dataNotTotal?.length || isLoading ? 0.5 : 1,
          }}
          disabled={!dataNotTotal?.length || isLoading}
          onClick={() => {
            setIsShowPopupConfirm(true)
          }}
        >
          <Icon className="icon-plus" style={{ padding: 0, marginRight: 8 }} />
          <Translate value="bond.portfolio.bondInformation.CREATE_PORTFOLIO" />
        </Button>
        <Button
          className="btn btn-blue h-20"
          style={{
            padding: '0 4px',
            fontWeight: 600,
            boxSizing: 'border-box',
            minWidth: 175,
            marginRight: 16,
            opacity: !dataNotTotal?.length || isLoading ? 0.5 : 1,
          }}
          disabled={!dataNotTotal?.length || isLoading}
          onClick={() => setIsShowPopupAddToWatchlist(true)}
        >
          <Translate value="bond.portfolio.bondInformation.ADD_TO_WATCHLIST" />
        </Button>
        <Button
          className="btn btn-blue h-20"
          style={{
            padding: '0 4px',
            fontWeight: 600,
            boxSizing: 'border-box',
            marginRight: 16,
            minWidth: 100,
            opacity: !dataNotTotal?.length || isLoading ? 0.5 : 1,
          }}
          disabled={!dataNotTotal?.length || isLoading}
          onClick={() => {
            setIsShowPopupSavePortfolio(true)
            setIsClear(true)
          }}
        >
          <Translate value="bond.portfolio.bondInformation.SAVE_AS" />
        </Button>
        <Button
          className="btn btn-blue h-20"
          style={{
            padding: '0 4px',
            fontWeight: 600,
            boxSizing: 'border-box',
            minWidth: 70,
            opacity: !dataNotTotal?.length || isLoading ? 0.5 : 1,
          }}
          disabled={!dataNotTotal?.length || isLoading}
          onClick={() =>
            handleUpdatePortfolio({ isChange: false, isClearData: true })
          }
        >
          <Translate value="bond.portfolio.bondInformation.SAVE" />
        </Button>
      </div>
      {isShowDate && (
        <PopupConfirmValuationDate
          handleClose={() => setIsShowDate(false)}
          handleSubmit={handleApplyValuationDate}
        />
      )}
      <PopupAlert
        message={I18n.t('bond.portfolio.bondInformation.MESSAGE_DATE_4')}
        isShow={isCheckValuationDate}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsCheckValuationDate(false)}
      />
      {isShowPopupSavePortfolio && (
        <PopupSavePortfolio
          handleClose={() => setIsShowPopupSavePortfolio(false)}
          handleSubmit={handleSavePortfolio}
        />
      )}
      <PopupAlertSuccess
        message={I18n.t('bond.portfolio.bondInformation.MESSAGE_PORTFOLIO_3', {
          name: saveStatus,
        })}
        isShow={isShowPopupSaveSuccess}
        handleClose={() => {
          dispatch(changeSaveStatus(''))
          setIsShowPopupSuccess(false)
        }}
      />
      <PopupAlertSuccess
        message={I18n.t('bond.portfolio.bondInformation.MESSAGE_PORTFOLIO_4', {
          name: updateStatus,
        })}
        isShow={isShowPopupUpdateSuccess}
        handleClose={() => {
          dispatch(changeUpdateStatus(''))
          setIsShowPopupUpdateSuccess(false)
        }}
      />
      <PopupAlert
        message={I18n.t(
          'bond.portfolio.bondInformation.CREATE_PORTFOLIO_CONTENT',
        )}
        isShow={isShowPopupConfirm}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShowPopupConfirm(false)}
        zIndex={15}
        zIndexOverlay={14}
        customButton={
          <div
            className="d-flex ali-center justify-content-space-between mb-10"
            style={{ marginTop: '20%' }}
          >
            <div className="">
              <Button
                onClick={handleNotSaveToPortfolio}
                className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_NO_SAVE" />
              </Button>
            </div>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={() => {
                  setIsShowPopupSavePortfolio(true)
                  setIsShowPopupConfirm(false)
                }}
                className={`btn btn-yellow w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_SAVE_AS" />
              </Button>
              <Button
                onClick={() => {
                  handleUpdatePortfolio({ isChange: false, isClearData: false })
                  setIsShowPopupConfirm(false)
                }}
                className={`btn btn-blue w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_SAVE" />
              </Button>
            </div>
          </div>
        }
      />
      <PopupAlert
        message={I18n.t(
          'bond.portfolio.bondInformation.CREATE_PORTFOLIO_CONTENT_2',
        )}
        isShow={isShowPopupChangePortfolioConfirm}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShowPopupChangePortfolioConfirm(false)}
        zIndex={15}
        zIndexOverlay={14}
        customButton={
          <div
            className="d-flex ali-center justify-content-space-between mb-10"
            style={{ marginTop: '20%' }}
          >
            <div className="">
              <Button
                onClick={handleNotSaveWhileChangePortfolio}
                className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_NO_SAVE" />
              </Button>
            </div>
            <div className="d-flex ali-center" style={{ gap: 8 }}>
              <Button
                onClick={() => {
                  setIsShowPopupSavePortfolio(true)
                  setIsShowPopupChangePortfolioConfirm(false)
                  setIsChangePortfolio(true)
                }}
                className={`btn btn-yellow w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_SAVE_AS" />
              </Button>
              <Button
                onClick={() => {
                  handleUpdatePortfolio({ isChange: true, isClearData: false })
                  setIsShowPopupChangePortfolioConfirm(false)
                }}
                className={`btn btn-blue w-80 h-20 ${style.fontWeightNormal}`}
                style={{ fontSize: 11 }}
              >
                <Translate value="common.button.BUTTON_SAVE" />
              </Button>
            </div>
          </div>
        }
      />
      {isShowPopupDeletePortfolio && (
        <PopupDeletePortfolio
          handleSubmit={handleDeletePortfolio}
          handleClose={() => setIsShowPopupDeletePortfolio(null)}
          data={isShowPopupDeletePortfolio}
        />
      )}
      <PopupAlert
        message={I18n.t(
          'bond.portfolio.bondInformation.RENAME_PORTFOLIO_CONTENT',
        )}
        isShow={isShowPopupRenamePortfolio}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShowPopupRenamePortfolio(false)}
      />
      <PopupAlert
        message={I18n.t('bond.portfolio.bondInformation.MAX_LENGTH', {
          number: MAX_LENGTH,
        })}
        isShow={errorMaxLength}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => dispatch(changeErrorMaxLength(false))}
        zIndex={15}
        zIndexOverlay={14}
      />
      {isShowPopupDeleteWatchlist && (
        <PopupDeleteWatchlist
          handleSubmit={handleDeleteWatchlist}
          handleClose={() => setIsShowPopupDeleteWatchlist(null)}
          data={isShowPopupDeleteWatchlist}
        />
      )}
      <PopupAlert
        message={I18n.t(
          'bond.portfolio.bondInformation.RENAME_WATCHLIST_CONTENT',
        )}
        isShow={isShowPopupRenameWatchlist}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setIsShowPopupRenameWatchlist(false)}
      />
      {isShowPopupAddToWatchlist && (
        <PopupAddToWatchlist
          handleClose={() => setIsShowPopupAddToWatchlist(false)}
          handleCreate={handleCreateWatchlist}
          handleUpdate={handleUpdateWatchlist}
        />
      )}
      <PopupAlert
        message={I18n.t('bond.portfolio.bondInformation.CHOOSE_WATCHLIST')}
        isShow={errorWatchlist}
        iconStatus={POPUP_ICON_STATUS.WARNING}
        handleClose={() => setErrorWatchlist(false)}
      />
      <PopupAlertSuccess
        message={I18n.t('bond.portfolio.bondInformation.MESSAGE_WATCHLIST_1', {
          name: createWatchlistStatus,
        })}
        isShow={isShowPopupCreateWatchlistSuccess}
        handleClose={() => {
          dispatch(changeStatusCreateWatchlist(false))
          setIsShowPopupCreateWatchlistSuccess(false)
        }}
      />
      <PopupAlertSuccess
        message={I18n.t('bond.portfolio.bondInformation.MESSAGE_WATCHLIST_2', {
          name: updateWatchlistStatus,
        })}
        isShow={isShowPopupUpdateWatchlistSuccess}
        handleClose={() => {
          dispatch(changeStatusUpdateWatchlist(false))
          setIsShowPopupUpdateWatchlistSuccess(false)
        }}
      />
      {showPopupCreate && (
        <PopupCreateBond
          handleClose={() => setIsShowPopupCreate(false)}
          handleSubmit={handleCreateBond}
        />
      )}
    </div>
  )
}

export default Filter
