import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../common/chart/pieChart/pieChartWithFooter/index.js'
import { Span } from '../../../../common/html/Span.js'
import { Loading } from '../../../../common/loading/index.js'
import { NoData } from '../../../../common/noData/index.js'
import { valToPercent } from '../../../../utils/Value.js'
import { formatChartData, titleData } from '../../common/helper.js'
import {
  selectFilterMonth,
  selectFilterYear,
} from '../keyIndicator/store/slice.js'
import { selectLoading, selectTopAttractiveInvestment } from './store/slice.js'
import { getTopAttractiveInvestment } from './store/thunk.js'
import style from './style.module.css'

const FDIIndustry = ({ height, width }) => {
  const dispatch = useDispatch()

  const locale = useSelector((state) => state.i18n.locale)
  const isLoading = useSelector(selectLoading)
  const dataChart = useSelector(selectTopAttractiveInvestment)
  const filterMonth = useSelector(selectFilterMonth)
  const filterYear = useSelector(selectFilterYear)

  useEffect(() => {
    if (filterYear && filterYear)
      dispatch(
        getTopAttractiveInvestment({ Month: filterMonth, Year: filterYear }),
      )
  }, [locale, filterMonth, filterYear])

  const formatDataChart = formatChartData(
    dataChart,
    'percentage',
    'vsicName',
    5,
    'economy.fdi.overview.OTHERS',
  )

  const renderTooltip = (data) => {
    return (
      <div className={style.chartTooltip}>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>
            {titleData(filterMonth, filterYear)}
          </Span>
        </div>
        <ul>
          {data.map((item, index) => {
            return (
              <li key={index}>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {item.isI18n ? <Translate value={item.text} /> : item.text}
                  {':'}
                </Span>
                <Span
                  style={{ fontSize: 11, fontWeight: 500 }}
                  className={style.chartTooltipText}
                >
                  {valToPercent(item.value)}
                </Span>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

  return (
    <div id="fdiIndustryId" style={{ height: height }}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {formatDataChart.length ? (
            <PieChartWithFooter
              data={formatDataChart}
              height={height}
              isUseContainerFooter
              footerProps={{ numRow: 2 }}
              renderTooltip={renderTooltip}
              pieChartProps={{ radius: 28 }}
              width={width}
            />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  )
}

export default FDIIndustry
