import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { selectIndexSectorTickerInfo } from '../../../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import { SEARCH_INDICATOR } from '../../constants'
import { clearCompareChecked } from '../popupValuation/store/slice'
import {
  selectFilter,
  selectListCompareSearch,
  setListCompareSearch,
} from '../store/slice'
import CompareItem from './CompareItem'
import style from './style.module.css'

const CompareList = () => {
  const dispatch = useDispatch()

  const compareList = useSelector(selectListCompareSearch)
  const currentInfo = useSelector(selectIndexSectorTickerInfo)
  const filter = useSelector(selectFilter)
  const nameCompare =
    currentInfo.ticker || currentInfo.icbNameCustom || currentInfo.groupName

  const handleClearAll = () => {
    dispatch(setListCompareSearch([]))
    dispatch(clearCompareChecked())
  }

  return (
    <div className={`h-100 d-flex ${style.marginFooter}`}>
      <div data-html2canvas-ignore="true">
        {compareList?.length > 0 && (
          <div
            className="h-100"
            style={{ paddingRight: '8px', borderRight: 'solid 1px #353537' }}
          >
            <button
              className={`btn ${style.buttonClearCompare}`}
              onClick={handleClearAll}
            >
              <i className="icon-trash"></i>
              <Span
                style={{
                  fontSize: 11,
                  fontWeight: 'normal',
                  whiteSpace: 'nowrap',
                }}
              >
                <Translate value="market.marketInDepth.valuation.CLEAR_ALL" />
              </Span>
            </button>
          </div>
        )}
      </div>
      <div
        className="list-stocks d-flex justify-content-start"
        style={{ flexWrap: 'wrap' }}
      >
        <CompareItem
          compare={{
            nameCompare: nameCompare,
            bgColor: '#009fe6',
            hideBtnClose: true,
            idCompare:
              filter.stateIndicator === SEARCH_INDICATOR.priceToBook
                ? 'pb'
                : 'pe',
          }}
        />
        {compareList?.length > 0 &&
          compareList.map((compare) => {
            return (
              <CompareItem
                key={compare.id + '_' + compare.groupType}
                compare={{
                  ...compare,
                  idCompare:
                    filter.stateIndicator === SEARCH_INDICATOR.priceToBook
                      ? compare.idCompare + '_pb'
                      : compare.idCompare + '_pe',
                }}
              />
            )
          })}
      </div>
    </div>
  )
}

export default CompareList
