import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { HEADER_PANEL_HEIGHT } from '../../../../constants/Common'
import { formatNumber } from '../../../../utils/Common'
import Container from './Container'
import { selectMinQuarter, selectMinYear, selectTimeRange } from './store/slice'
import { getDownloadTotalSocialInvesmentCapital } from './store/thunk'

const PanelSocialInvestment = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const timeRange = useSelector(selectTimeRange)
  const minYear = useSelector(selectMinYear)
  const minQuarter = useSelector(selectMinQuarter)

  return (
    <Panel
      title={'economy.fiscal.stateBudget.SOCIAL_INVESTMENT'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={() =>
        getDownloadTotalSocialInvesmentCapital({
          TimeFrequency: timeRange,
          Year: minYear,
          Quarter: minQuarter,
        })
      }
    >
      <Container
        height={formatNumber(sizes[panelKey].height - HEADER_PANEL_HEIGHT)}
      />
    </Panel>
  )
}

export default PanelSocialInvestment
