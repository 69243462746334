import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { idTableAbsolute } from '.'
import EventEmitter from '../../../utils/EventEmitter'
import ThePagination from '../../pagination/ThePagination'
import { RESET_COLUMN, RESET_ROW } from './ListenerScrollTable'
import { EMPTY_ROW_TOP_TABLE } from './Tbody'
import { EMPTY_COLUMN_LEFT_TABLE } from './Thead'
import { defaultPage, heightTr, pageSize, widthColumn } from './constant'
import { changeCurrentPage, selectCurrentPage } from './store/slice'

const Pagination = ({
  selectFullId,
  changeIdDisplay,
  renderContentPagination,
  stateResetDefaultPage,
  isHide = true,
  changePage,
  pageSizePagination,
}) => {
  const dispatch = useDispatch()

  const currentPage = useSelector(selectCurrentPage)
  const fullId = useSelector(selectFullId)

  useEffect(() => {
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
    if (elTableAbsolute) {
      elTableAbsolute.style.left =
        -(EMPTY_COLUMN_LEFT_TABLE * widthColumn) + 'px'
      elTableAbsolute.style.top = -(EMPTY_ROW_TOP_TABLE * heightTr) + 'px'
    }
    setCurrentPage(defaultPage)
    EventEmitter.dispatch(RESET_COLUMN)
    EventEmitter.dispatch(RESET_ROW)
  }, [stateResetDefaultPage])

  useEffect(() => {
    const newIdDisplay = fullId.slice(
      (currentPage - 1) * (pageSizePagination ? pageSizePagination : pageSize),
      currentPage * (pageSizePagination ? pageSizePagination : pageSize),
    )
    dispatch(changeIdDisplay(newIdDisplay))

    if (!newIdDisplay.length && currentPage !== defaultPage) {
      setCurrentPage(currentPage - 1)
    }
  }, [fullId, currentPage])

  useEffect(() => {
    const elTableAbsolute = document.querySelector(`#${idTableAbsolute}`)
    if (elTableAbsolute) {
      elTableAbsolute.style.top = -(EMPTY_ROW_TOP_TABLE * heightTr) + 'px'
    }
    EventEmitter.dispatch(RESET_ROW)
  }, [currentPage])

  const setCurrentPage = (page) => dispatch(changeCurrentPage(page))

  const totalPage =
    Math.ceil(
      fullId?.length / (pageSizePagination ? pageSizePagination : pageSize),
    ) || 1

  return (
    <ThePagination
      currentPage={currentPage}
      totalPages={totalPage}
      changeCurrentPage={setCurrentPage}
      renderContentPagination={renderContentPagination}
      pageSizePagination={pageSizePagination}
      isHide={isHide}
      changePage={changePage}
    />
  )
}

export default Pagination
