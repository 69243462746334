import React from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import useComGroup from '../../../../common/hooks/useComGroup'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { COM_GROUP } from '../../../../constants/Group'
import { selectSetting } from '../../filter/store/slice'
import style from '../../preview/style.module.css'
import BalanceSheetNextTable from './BalanceSheetNextTable'
import BalanceSheetTable from './BalanceSheetTable'
import IncomeStatementNextTable from './IncomeStatementNextTable'
import IncomeStatementTable from './IncomeStatementTable'

const FinancialStatements = () => {
  const dataTopInfo = useSelector(selectBasicInfo)
  const setting = useSelector(selectSetting)

  const comGroup = useComGroup(dataTopInfo.organizationId)

  return (
    <>
      {!setting.inComeStatement && !setting.balanceSheet
        ? null
        : !setting.inComeStatement && (
            <div className={style.titleGroup}>
              <Translate value="corporate.companySnapshot.FINANCIAL_STATEMENTS" />
            </div>
          )}
      {setting.inComeStatement && (
        <IncomeStatementTable isIns={comGroup === COM_GROUP.INSURANCE} />
      )}
      {setting.inComeStatement && comGroup === COM_GROUP.INSURANCE && (
        <IncomeStatementNextTable />
      )}
      {setting.balanceSheet && (
        <BalanceSheetTable isIns={comGroup === COM_GROUP.INSURANCE} />
      )}
      {setting.balanceSheet && comGroup === COM_GROUP.INSURANCE && (
        <BalanceSheetNextTable />
      )}
    </>
  )
}

export default FinancialStatements
