import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../../../configs/Layout'
import { withWrapper } from '../../../../../common/withWrapper'
import { PanelCost } from './cost/PanelCost'
import { PanelDataTable } from './dataTable/PanelDataTable'
import { PanelIncomeStructure } from './incomeStructure/PanelIncomeStructure'
import { PanelNetInterest } from './netInterest/PanelNetInterest'
import { PanelNonInterest } from './nonInterest/PanelNonInterest'
import { PanelOperatingIncome } from './operatingIncome/PanelOperatingIncome'

const MAP_KEY = {
  DATA_TABLE: 'DATA_TABLE',
  INCOME_STRUCTURE: 'INCOME_STRUCTURE',
  OPERATING_INCOME: 'OPERATING_INCOME',
  NET_INTEREST: 'NET_INTEREST',
  NON_INTEREST: 'NON_INTEREST',
  COST: 'COST',
}

const MAP_SIZE = {
  [`${MAP_KEY.INCOME_STRUCTURE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.OPERATING_INCOME,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.OPERATING_INCOME,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        isResizeWithParent: true,
        key: MAP_KEY.NET_INTEREST,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.DATA_TABLE,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.NON_INTEREST,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.COST,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.OPERATING_INCOME}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.NET_INTEREST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NET_INTEREST}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: 0,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.DATA_TABLE}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.NON_INTEREST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.NON_INTEREST}`]: {
    width: `calc(100% / 3 - ${2 * COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(100% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - 2 * COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    verticalResize: [
      {
        key: MAP_KEY.COST,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.COST}`]: {
    width: `calc(100% / 3 - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(200% / 3 + ${COMPONENT.MARGIN}px)`,
    minWidth: COMPONENT.MIN_SIZE.WIDTH - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 2 * COMPONENT.MIN_SIZE.WIDTH + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const ProfitabilityContent = ({ sizes, setSizes, dataType }) => {
  return (
    <>
      <PanelIncomeStructure
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelOperatingIncome
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelNetInterest
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelDataTable
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelNonInterest
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />

      <PanelCost
        panelRefs={panelRefs}
        mapKey={MAP_KEY}
        sizes={sizes}
        setSizes={setSizes}
        dataType={dataType}
      />
    </>
  )
}

ProfitabilityContent.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(ProfitabilityContent)
