import { createAsyncThunk } from '@reduxjs/toolkit'
import OwnershipService from '../../../../../core/services/corporate/OwnershipService'
import { handleExport } from '../../../../utils/Export'

export const getSubsidiaries = createAsyncThunk(
  'corporate/ownership/subsidiaries/GET_SUBSIDIARIES',
  async (data, { rejectWithValue }) => {
    const response = await OwnershipService.getSubsidiaries(data)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const downloadSubsidiaries = createAsyncThunk(
  'corporate/ownership/subsidiaries/DOWNLOAD_SUBSIDIARIES',
  async (data) => {
    const response = await OwnershipService.downloadSubsidiaries(data)
    handleExport(response.data, response.filename)
  },
)
