import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../../common/chart/constants'
import { getColumnSizeInBarChart } from '../../../../../common/chart/helper'
import { ChartContainer } from '../../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../../common/html/Span'
import { getTwoColorByValue } from '../../../../../utils/Color'
import { formatVal } from '../../../../../utils/Value'
import { latePaymentTypeValue } from './constants'
import { selectCouponTypeTab } from './store/slice'

const SETTINGS = {
  yTickNum: 5,
  xTickNum: 10,
  fontSize: 11,
}

export const ChartComponent = ({
  data,
  width,
  height,
  keyXAxis,
  displayData,
  ids,
  listfooter,
}) => {
  const textTooltip = (timeType) => {
    switch (timeType) {
      case latePaymentTypeValue.ALL:
        return I18n.t('bond.overview.expectedCash.chart.tooltiplabelJobAll')
      case latePaymentTypeValue.Origin:
        return I18n.t(
          'bond.overview.expectedCash.chart.tooltiplabelJobAllPrincipal',
        )
      default:
        return I18n.t('bond.overview.expectedCash.chart.tooltiplabelJobCoupon')
    }
  }
  const couponTypeTab = useSelector(selectCouponTypeTab)

  const renderTooltip = (tooltipData) => {
    const tooltipContentData = (key) => {
      const parts = key.split('-')
      const id = parseInt(parts[0])
      return {
        label: tooltipData?.[`${id}-name`],
        value: formatVal(tooltipData?.[`${id}-value`], 0),
        unit: 'bond.overview.expectedCash.chart.unitMoney',
      }
    }
    return (
      <>
        <div style={{ fontStyle: 'italic' }}>
          <Span style={{ fontSize: 10 }}>
            {tooltipData.XAxis} {textTooltip(couponTypeTab)}
          </Span>
        </div>
        {ids
          .filter((e) => {
            return displayData.includes(e)
          })
          .map((key, index) => (
            <div key={index} className="d-flex j-b">
              <div>
                <Span style={{ fontSize: 11, fontWeight: 400 }}>
                  {I18n.t(tooltipContentData(key)?.label)}
                </Span>
              </div>
              <div className="ml-8">
                <Span
                  style={{
                    fontSize: 11,
                    fontWeight: 500,
                    color: getTwoColorByValue(tooltipData[key] || 0),
                  }}
                >
                  {tooltipContentData(key).value}
                </Span>
                <Span style={{ fontSize: 11, fontWeight: 500 }}>
                  {' ' + I18n.t(tooltipContentData(key).unit)}
                </Span>
              </div>
            </div>
          ))}
      </>
    )
  }

  const yAxisLeft = [
    {
      id: 'left',
      keys: displayData,
      orientation: 'left',
      tickNum: SETTINGS.yTickNum,
      label: I18n.t('bond.overview.expectedCash.chart.labelLeft'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
    },
  ]

  const getColor = (key) => {
    const item = listfooter.find((e) => e.dataKey === key)
    return item?.before?.bgColor || ''
  }

  return (
    <ChartContainer
      data={data}
      width={width}
      height={height || 0}
      keyXAxis={keyXAxis}
      xTickNum={SETTINGS.xTickNum}
      // timeFrame="1M"
      timeFrame="CUSTOM"
      tickFormatter={(d) => d}
      margin={{
        top: 20,
        bottom: 0,
        left: 0,
        right: 0,
      }}
      hasBarChart={displayData.length ? true : false}
      yAxis={yAxisLeft}
      renderCustomTooltip={(dataFormat) => {
        return renderTooltip(dataFormat)
      }}
    >
      {({ chartContentWidth }) => {
        const barSize = getColumnSizeInBarChart(
          chartContentWidth,
          data.length,
          null,
          displayData.length,
        )

        return (
          <>
            {ids
              .filter((e) => {
                return displayData.includes(e)
              })
              .map((e) => {
                return (
                  <Bar
                    isAnimationActive={false}
                    dataKey={e}
                    yAxisId="left"
                    fill={getColor(e)}
                    barSize={barSize}
                  />
                )
              })}
          </>
        )
      }}
    </ChartContainer>
  )
}

ChartComponent.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  keyXAxis: PropTypes.string.isRequired,
}
