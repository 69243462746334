import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ScrollComponent } from '../../../../../common/ScrollComponent'
import { Loading } from '../../../../../common/loading'
import useGetICB from '../../../../../common/masterData/useGetICB'
import { NoData } from '../../../../../common/noData'
import TextEllipsis from '../../../../../common/textEllipsis'
import { selectSectorInfo } from '../../../../../common/topInfo/sectorTopInfo/store/slice'
import { LIST_TYPE } from '../../constant'
import {
  LOADING_TYPE,
  selectFilterIcbLevel,
  selectLoading,
} from '../../store/slice'
import { getOrganizationsByIcb } from '../../store/thunk'
import style from './company.module.css'
import { getValueInTrillion, randomColor, randomCompanyColor } from './helper'

const SCROLL_SECTOR = 'SCROLL_SECTOR'

const SectorList = ({
  organizations,
  setOrganizations,
  groups,
  setGroups,
  filteredOrgIds,
  setFilteredOrgIds,
  exchanges,
  marketCap,
  keyword,
  handleOpenAlert,
}) => {
  const dispatch = useDispatch()

  const sectorInfo = useSelector(selectSectorInfo)
  const icbLevel = useSelector(selectFilterIcbLevel)
  const loadingAddGroup = useSelector(selectLoading(LOADING_TYPE.ADD_GROUP))
  const { loading, ICBs } = useGetICB()

  const onCheckboxChange = (e, { icbId }) => {
    const checked = e.target.checked

    if (checked) {
      dispatch(getOrganizationsByIcb({ ICBId: icbId }))
        .unwrap()
        .then((orgs) => {
          const validOrgIds = orgs
            .filter(
              (org) =>
                exchanges.includes(org.exchangeCode) &&
                (getValueInTrillion(marketCap.from) || 0) <=
                  (org.marketCap || 0) &&
                (getValueInTrillion(marketCap.to) || Infinity) >=
                  (org.marketCap || 0),
            )
            .map((org) => org.organizationId)

          if (validOrgIds.length === 0) {
            handleOpenAlert()
            return
          }

          const color = randomColor(organizations)
          const newGroups = JSON.parse(JSON.stringify(groups))
          newGroups.push({
            type: LIST_TYPE.SECTOR,
            id: icbId,
            color,
          })
          setGroups(newGroups)

          const newOrganizations = JSON.parse(JSON.stringify(organizations))
          orgs.forEach((org) => {
            let prevGroups = []
            const oldOrg = newOrganizations[org.organizationId]
            if (oldOrg) {
              prevGroups = [
                ...oldOrg.prevGroups,
                {
                  type: oldOrg.type,
                  id: oldOrg.typeId,
                  color: oldOrg.color,
                },
              ]
            }

            newOrganizations[org.organizationId] = {
              type: LIST_TYPE.SECTOR,
              typeId: icbId,
              organizationId: org.organizationId,
              color,
              companyColor: randomCompanyColor(newOrganizations),
              prevGroups,
              marketCap: org.marketCap || 0,
              exchange: org.exchangeCode,
            }
          })
          setOrganizations(newOrganizations)
          setFilteredOrgIds([
            ...filteredOrgIds.filter((id) => !validOrgIds.includes(id)),
            ...validOrgIds,
          ])
        })
    } else {
      const newOrganizations = JSON.parse(JSON.stringify(organizations))
      let newFilteredOrgIds = [...filteredOrgIds]
      Object.keys(organizations).forEach((orgId) => {
        const org = newOrganizations[orgId]

        if (org?.type === LIST_TYPE.SECTOR && org?.typeId === icbId) {
          if (org.prevGroups?.length) {
            const lastGroup = org.prevGroups.pop()
            org.type = lastGroup.type
            org.typeId = lastGroup.id
            org.color = lastGroup.color

            if (
              !newFilteredOrgIds.includes(org.organizationId) &&
              exchanges.includes(org.exchangeCode) &&
              (getValueInTrillion(marketCap.from) || 0) <=
                (org.marketCap || 0) &&
              (getValueInTrillion(marketCap.to) || Infinity) >=
                (org.marketCap || 0)
            ) {
              newFilteredOrgIds.push(org.organizationId)
            }
          } else {
            delete newOrganizations[orgId]
            newFilteredOrgIds = newFilteredOrgIds.filter(
              (id) => id !== Number(orgId),
            )
          }
        } else if (org.prevGroups?.length) {
          const groupIndex = org.prevGroups.findIndex(
            (group) => group.type === LIST_TYPE.SECTOR && group.id === icbId,
          )
          if (groupIndex !== -1) {
            org.prevGroups.splice(groupIndex, 1)
          }
        }
      })
      setOrganizations(newOrganizations)
      setFilteredOrgIds(newFilteredOrgIds)

      const newGroups = groups.filter(
        (group) => !(group.type === LIST_TYPE.SECTOR && group.id === icbId),
      )
      setGroups(newGroups)
    }
  }

  useEffect(() => {
    if (sectorInfo.icbId) {
      const scrollSector = document.querySelector(
        `#${SCROLL_SECTOR} .scrollbars div:first-child`,
      )
      const activeSector = scrollSector?.querySelector(
        `[id="${sectorInfo.icbId}"]`,
      )
      if (
        scrollSector &&
        activeSector &&
        activeSector.offsetTop + activeSector.offsetHeight >
          scrollSector.offsetHeight
      ) {
        scrollSector.scrollTo({
          top: activeSector.offsetTop - 30,
          behavior: 'smooth',
        })
      }
    }
  }, [sectorInfo.icbId])

  return (
    <div id={SCROLL_SECTOR} className="h-100">
      {loading ? (
        <Loading />
      ) : ICBs.length === 0 ? (
        <NoData />
      ) : (
        <ScrollComponent>
          <ul>
            {[...ICBs]
              .filter((icb) => icb.icbLevel === icbLevel)
              .sort((a, b) =>
                keyword
                  ? b.icbName?.toLowerCase().indexOf(keyword.toLowerCase()) -
                    a.icbName?.toLowerCase().indexOf(keyword.toLowerCase())
                  : 1,
              )
              .map((icb) => {
                const isCheck =
                  groups.findIndex(
                    (group) =>
                      group.type === LIST_TYPE.SECTOR && group.id === icb.icbId,
                  ) === -1
                    ? false
                    : true
                const disabled = loadingAddGroup

                return (
                  <li
                    key={icb.icbId}
                    id={icb.icbId}
                    className={`${style.tickerItem} ${
                      disabled ? style.disabled : ''
                    }`}
                  >
                    <label>
                      <div className={style.checkBox}>
                        <input
                          type="checkbox"
                          className="checkbox line"
                          checked={isCheck}
                          disabled={disabled}
                          onChange={(e) => onCheckboxChange(e, icb)}
                        />
                      </div>
                      <div className="flex-1">
                        <TextEllipsis
                          isI18n={false}
                          text={icb.icbName + ' L' + icbLevel}
                        />
                      </div>
                    </label>
                  </li>
                )
              })}
          </ul>
        </ScrollComponent>
      )}
    </div>
  )
}

export default SectorList
