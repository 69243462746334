import PropTypes from 'prop-types'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../common/html/Span'
import style from './common.module.css'

const Unit = ({ title, isI18n }) => {
  return (
    <div>
      <Span style={{ fontSize: 11 }} className={style.unit}>
        {isI18n ? <Translate value={title} /> : title}
      </Span>
    </div>
  )
}

Unit.propType = {
  title: PropTypes.string.isRequired,
  isI18n: PropTypes.bool,
}

Unit.defaultProps = {
  isI18n: true,
}

export default Unit
