import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { pageSize } from './constant'
import { selectCurrentPage } from './store/slice'

const CellOrder = ({ index }) => {
  const currentPage = useSelector(selectCurrentPage)

  return (currentPage - 1) * pageSize + index + 1
}

CellOrder.propTypes = {
  index: PropTypes.number.isRequired,
}

export default CellOrder
