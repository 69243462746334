import { createAsyncThunk } from '@reduxjs/toolkit'
import SteelService from '../../../../../../../core/services/sector/sectorSpecific/SteelService'
import { handleExport } from '../../../../../../utils/Export'

export const getSteelStatistics = createAsyncThunk(
  'sector/sectorSpecific/steel/construction/steelStatistics',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getConstructionStatistics(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)

export const downloadConstructionStatistic = createAsyncThunk(
  'sector/sectorSpecific/steel/construction/downloadConstructionStatistic',
  async (data) => {
    const response = await SteelService.getDownloadConstructionStatistic(data)
    handleExport(response.data, response.filename)
  },
)

export const getLatestPeriods = createAsyncThunk(
  'sector/sectorSpecific/steel/construction/getLatestPeriods',
  async (params, { rejectWithValue }) => {
    const response = await SteelService.getLatestPeriods(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue(response.errors)
  },
)
