import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Line } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../../common/chart/constants'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { Span } from '../../../../common/html/Span'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import {
  getLineChartColor,
  getTableMappingData,
} from '../panelGDPSpendingMethod/store/slice'
import Tooltip from './Tooltip'
import { handleTooltipTwoColumn } from './helper'
import style from './index.module.css'
import { getActiveLine } from './store/slice'

const YAXIS_LEFT = 'left'
const SETTINGS = {
  yTickNum: 5,
  strokeWidth: 1.5,
  strokeActiveWidth: 2.5,
  normalOrHover: 1,
  blur: 0.1,
}

const LineChart = ({
  data,
  height,
  width,
  keyXAxis,
  keyYAxisProps,
  heightListIndicator,
}) => {
  const activeLine = useSelector(getActiveLine)
  const lineChartColor = useSelector(getLineChartColor)
  const GDPTypeName = useSelector(getTableMappingData)

  const { leftColumn, rightColumn } = handleTooltipTwoColumn(
    keyYAxisProps,
    GDPTypeName,
  )
  const lineChartColorHasAuthor = lineChartColor.filter(
    (item) => item.author !== undefined,
  )

  const renderTooltip = (payload) => {
    return (
      <>
        <div style={{ fontStyle: 'italic', marginBottom: 5 }}>
          <Span style={{ fontSize: 10 }}>{payload[keyXAxis]}</Span>
        </div>
        <div className="d-flex">
          <div className={style.tooltipMaxWidth}>
            {leftColumn.length > 0 &&
              leftColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
          <div className={`${style.tooltipMaxWidth} ml-8`}>
            {rightColumn.length > 0 &&
              rightColumn.map((item, index) => {
                return <Tooltip key={index} item={item} payload={payload} />
              })}
          </div>
        </div>
      </>
    )
  }

  const keyYAxis = [
    {
      id: YAXIS_LEFT,
      keys: keyYAxisProps,
      orientation: YAXIS_LEFT,
      isLineChart: true,
      label: I18n.t('economy.gdp.gdp.TRILLION'),
      labelPosition: AXIS_LABEL_POSITION.LEFT,
      tickNum: SETTINGS.yTickNum,
    },
  ]

  return (
    <ChartContainer
      data={data}
      height={height - heightListIndicator}
      width={width}
      keyXAxis={keyXAxis}
      yAxis={keyYAxis}
      renderCustomTooltip={renderTooltip}
      showAllTick
      timeFrame={TIME_RANGES.CUSTOM}
      isNotFormatXAxis
      margin={{
        ...MARGIN_RECHARTS,
        right: 16,
      }}
    >
      {lineChartColorHasAuthor.map((item, index) => {
        return (
          <Line
            key={index}
            isAnimationActive={false}
            yAxisId={YAXIS_LEFT}
            dataKey={item.author}
            stroke={item.color}
            strokeWidth={
              activeLine === item.author
                ? SETTINGS.strokeActiveWidth
                : SETTINGS.strokeWidth
            }
            dot={false}
            strokeOpacity={
              activeLine !== item.author && activeLine
                ? SETTINGS.blur
                : SETTINGS.normalOrHover
            }
            activeDot={false}
          />
        )
      })}
    </ChartContainer>
  )
}

export default LineChart
