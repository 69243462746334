import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../common/loading'
import { Panel } from '../../../common/panel'
import { SizeTracker } from '../../../common/sizeTracker'
import DispatchActionTab from '../../../common/tabs/DispatchActionTab'
import { Toggle } from '../../../common/toggle'
import Table from './Table'
import { PRICE_TABS } from './constants'
import {
  changeFilter,
  changeIsExchangePrice,
  selectFilterPriceBoard,
  selectIsExchangePrice,
  selectLoading,
} from './store/slice'

const PanelPrice = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const dispatch = useDispatch()

  const { isCleanPrice } = useSelector(selectFilterPriceBoard)
  const isExchangePrice = useSelector(selectIsExchangePrice)
  const isLoading = useSelector(selectLoading)

  const handleChangePriceType = (item) => {
    dispatch(
      changeFilter({
        key: 'isCleanPrice',
        value: item.value,
      }),
    )
  }

  const handleChangeExchangePrice = () => {
    dispatch(changeIsExchangePrice(!isExchangePrice))
  }

  return (
    <Panel
      title="bond.corporateBond.secondaryMarket.TRADE_STATISTICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
    >
      <div style={{ height: `calc(100%)` }}>
        <SizeTracker>
          {(size) => (
            <>
              <div className="d-flex justify-content-space-between mb-8">
                <DispatchActionTab
                  data={PRICE_TABS}
                  activeTab={isCleanPrice}
                  onChangeTab={handleChangePriceType}
                  itemStyle={{
                    width: '50%',
                    fontWeight: 600,
                  }}
                />

                <div>
                  <Toggle
                    handleClick={handleChangeExchangePrice}
                    label="bond.corporateBond.priceBoard.EXCHANGE_TRANSACTION_PRICE"
                    isOn={isExchangePrice}
                  />
                </div>
              </div>
              {size.height && (
                <div
                  style={{
                    height: `calc(100% - ${+size.height}px)`,
                  }}
                >
                  {isLoading ? <Loading /> : <Table />}
                </div>
              )}
            </>
          )}
        </SizeTracker>
      </div>
    </Panel>
  )
}

PanelPrice.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default PanelPrice
