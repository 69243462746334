import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import MenuChartSettings from '../../common/menuChartSettings'
import { ReactComponent as IconVisibilityOff } from '../../icon/icon-visibility-off.svg'
import { ReactComponent as IconVisibilityOn } from '../../icon/icon-visibility-on.svg'
import { selectIndicators } from '../../store/slice'
import { VIEW_TYPE } from '../constants'
import { getTimeFrequencyIndicator } from '../helper'
import {
  changeDetailTreeSecuritiesIndicator,
  changeFullTreeSecuritiesIndicatorBySecurities,
  changeFullTreeSecuritiesIndicatorByTime,
  changeListYAxisCustom,
  selectListLegendCustom,
  selectViewType,
  selectYAxisCustom,
} from '../store/slice'
import style from '../style.module.css'
import { useGetNameSecuritiesIndicator } from './useGetNameSecuritiesIndicator'

const SecuritiesItemIndicator = ({ itemId, item, itemType, index }) => {
  const dispatch = useDispatch()
  const itemIndicatorRef = useRef()

  const viewType = useSelector(selectViewType)
  const listLegendCustom = useSelector(selectListLegendCustom)
  const listYAxisCustom = useSelector(selectYAxisCustom)
  const indicators = useSelector(selectIndicators)
  const indicator = indicators.find((i) => i.id === item.indicatorId)

  const [visibility, setVisibility] = useState(item.visibility)
  const [isOpenSettings, setIsOpenSettings] = useState(false)

  const { getNameSecuritiesIndicator } = useGetNameSecuritiesIndicator()

  const handleVisibility = () => {
    setVisibility(!visibility)
    dispatch(
      changeDetailTreeSecuritiesIndicator({
        typeSecurities: itemType,
        securitiesId: itemId,
        indexIndicator: index,
        keyUpdate: 'visibility',
        valueUpdate: !visibility,
      }),
    )
  }

  const handleOpenSettings = () => {
    setIsOpenSettings(true)
  }

  const handleCloseSettings = () => {
    setIsOpenSettings(false)
  }

  const handleClosePopup = (data) => {
    if (viewType === VIEW_TYPE.BY_TIME) {
      dispatch(
        changeFullTreeSecuritiesIndicatorByTime({
          typeSecurities: itemType,
          securitiesId: itemId,
          indexIndicator: index,
          data,
        }),
      )
    } else {
      dispatch(
        changeFullTreeSecuritiesIndicatorBySecurities({
          typeSecurities: itemType,
          indexIndicator: index,
          data,
        }),
      )
    }

    if (
      item.multiplier !== data.multiplier ||
      item.dataFormatType !== data.dataFormatType
    ) {
      const newListYAxisCustom = Object.keys(listYAxisCustom).reduce(
        (obj, key) => {
          if (key.includes(item.dataKey)) {
            return { ...obj, [key]: { ...listYAxisCustom[key], labelText: '' } }
          } else {
            return { ...obj, [key]: listYAxisCustom[[key]] }
          }
        },
        {},
      )
      dispatch(changeListYAxisCustom(newListYAxisCustom))
    }

    handleCloseSettings()
  }

  return (
    <div className={style.cardIndicator}>
      <div className="align-center w-100">
        {visibility ? (
          <IconVisibilityOn
            className={style.iconVisibility}
            onClick={handleVisibility}
          />
        ) : (
          <IconVisibilityOff
            className={style.iconVisibility}
            onClick={handleVisibility}
          />
        )}
        <div
          className={style.colorIndicator}
          style={{ backgroundColor: item.color }}
        />
        <div style={{ width: 'calc(100% - 75px)' }}>
          <Span>
            <TextEllipsis
              isI18n={false}
              text={getNameSecuritiesIndicator(
                item.dataKeyDuplicate || item.dataKey,
                listLegendCustom,
              )}
            />
          </Span>
        </div>
        <div
          ref={itemIndicatorRef}
          className="align-center"
          onClick={handleOpenSettings}
        >
          <i
            className={['icon-setting-bold', style.iconContainer].join(' ')}
            style={{ fontSize: 12 }}
          />
        </div>
      </div>
      <MenuChartSettings
        parentRef={itemIndicatorRef}
        item={item}
        isShow={isOpenSettings}
        isBySecurities={viewType === VIEW_TYPE.BY_SECURITIES}
        onClose={handleClosePopup}
        typeTimeFrequency={getTimeFrequencyIndicator(indicator)}
      />
    </div>
  )
}

export default SecuritiesItemIndicator
