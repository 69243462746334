import { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown from '../../../common/dropdown'
import { Input } from '../../../common/html/Input'
import { Span } from '../../../common/html/Span'
import { Loading } from '../../../common/loading'
import TextEllipsis from '../../../common/textEllipsis'
import {
  selectFilterParams,
  selectInitialCreateBondList,
  setFilterParameters,
} from '../store/slice'
import style from './index.module.css'

export const FilterCreateBond = ({ width }) => {
  const dispatch = useDispatch()

  const dropdownRef = useRef()

  const { ticker } = useSelector(selectFilterParams)
  const locale = useSelector((state) => state.i18n.locale)
  const createBondList = useSelector(selectInitialCreateBondList)

  const [keySearch, setKeySearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const handleShow = () => {
    setIsShowDropdown(true)
  }

  const handleHide = () => {
    setIsShowDropdown(false)
    setKeySearch('')
  }

  const handleClickItem = (id) => {
    if (!ticker.includes(id)) {
      dispatch(setFilterParameters({ key: 'ticker', value: [...ticker, id] }))
      return
    }

    dispatch(
      setFilterParameters({
        key: 'ticker',
        value: ticker.filter((item) => item !== id),
      }),
    )
  }

  useEffect(() => {
    if (isShowDropdown) {
      setLoading(true)

      const timeout = setTimeout(() => {
        const list = createBondList.filter((item) =>
          item?.bondTicker?.toLowerCase()?.includes(keySearch?.toLowerCase()),
        )

        setData(!!keySearch.length ? list : createBondList)
        setLoading(false)
      }, 500)

      return () => clearTimeout(timeout)
    }
  }, [keySearch.length, isShowDropdown, createBondList])

  const placeholderInput = useMemo(
    () => I18n.t('bond.bondScreening.createBond.SEARCH_PLACEHOLDER'),
    [locale],
  )

  return (
    <div
      ref={dropdownRef}
      className={`form-search-inbox ${style.inputBondSearch}`}
      style={{ width }}
    >
      <Input
        style={{
          fontSize: 12,
          backgroundColor: '#50545f ',
          padding: '0 20px 0px 8px',
        }}
        type="text"
        className="search-inbox"
        placeholder={placeholderInput}
        onChange={(e) => setKeySearch(e.target.value)}
        onFocus={handleShow}
        value={keySearch}
      />
      <button type="button">
        <i className={`icon-search-2 ${style.colorIcon}`} />
      </button>
      <Dropdown isShow={isShowDropdown} parentRef={dropdownRef}>
        <HandleClickOutside
          handleClickOutside={handleHide}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {loading ? (
                <div style={{ height: 29 }}>
                  <Loading />
                </div>
              ) : !!data?.length ? (
                <ScrollComponent autoHeight={true} autoHeightMax={200}>
                  <ul className="list-check">
                    {data.map((item, index) => {
                      return (
                        item.bondTicker && (
                          <li key={`key-${index}`}>
                            <a
                              style={{
                                cursor: 'pointer',
                              }}
                            >
                              <label className="d-flex ali-center">
                                <input
                                  type="checkbox"
                                  className="checkbox mr-8"
                                  checked={
                                    ticker?.includes(item.bondTicker) ?? false
                                  }
                                  onChange={() =>
                                    handleClickItem(item.bondTicker)
                                  }
                                />
                                <TextEllipsis
                                  text={item?.bondTicker ?? ''}
                                  zIndexTooltip={999}
                                  isI18n={false}
                                />
                              </label>
                            </a>
                          </li>
                        )
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
