import { DATE_TYPES } from '../constants'

export const GROUP_COLUMNS = (type) => [
  {
    key: 'name',
    title: 'bond.corporateBond.topLiquidity.ISSUER',
    attrs: {
      style: { minWidth: 140, maxWidth: 140 },
    },
    keySort: 'name',
    alignRight: false,
  },
  {
    key: 'totalValue',
    title: 'bond.corporateBond.topLiquidity.TRADING_VALUE',
    additionalText: 'bond.corporateBond.averageYtmInterest.UNIT',
    attrs: {
      style: { minWidth: 65 },
    },
    keySort: 'totalValue',
    alignRight: true,
  },
  {
    key: 'averageRemainLength',
    title: 'bond.corporateBond.topLiquidity.AVERAGE_REMAIN_LENGTH',
    additionalText: 'bond.corporateBond.averageYtmInterest.MONTH',
    keySort: 'averageRemainLength',
    alignRight: true,
  },
  {
    key: 'averageYTM',
    title:
      type === DATE_TYPES.ONE_DAY
        ? 'bond.corporateBond.topLiquidity.CHANGE_OF_YTM'
        : 'bond.corporateBond.topLiquidity.CHANGE_OF_YTM_2',
    additionalText: 'bond.corporateBond.topLiquidity.PERCENT',
    keySort: 'averageYTM',
    alignRight: true,
  },
  {
    key: 'averageCleanPrice',
    title:
      type === DATE_TYPES.ONE_DAY
        ? 'bond.corporateBond.topLiquidity.CLEAN_PRICE'
        : 'bond.corporateBond.topLiquidity.CLEAN_PRICE_2',
    additionalText: 'bond.corporateBond.averageYtmInterest.VND',
    keySort: 'averageCleanPrice',
    alignRight: true,
  },
  // {
  //   key: 'changeofCleanPrice',
  //   title: 'bond.corporateBond.topLiquidity.CHANGE_CLEAN_PRICE',
  //   additionalText: 'bond.corporateBond.topLiquidity.PERCENT',
  //   keySort: 'changeofCleanPrice',
  //   alignRight: true,
  // },
  {
    key: 'averageDirtyPrice',
    title:
      type === DATE_TYPES.ONE_DAY
        ? 'bond.corporateBond.topLiquidity.DIRTY_PRICE'
        : 'bond.corporateBond.topLiquidity.DIRTY_PRICE_2',
    additionalText: 'bond.corporateBond.averageYtmInterest.VND',
    keySort: 'averageDirtyPrice',
    alignRight: true,
  },
  // {
  //   key: 'changeofDirtyPrice',
  //   title: 'bond.corporateBond.topLiquidity.CHANGE_DIRTY_PRICE',
  //   additionalText: 'bond.corporateBond.topLiquidity.PERCENT',
  //   keySort: 'changeofDirtyPrice',
  //   alignRight: true,
  // },
]
