import DerivationProxy from '../../proxies/market/DerivativeProxy'
import { ServiceBase } from '../ServiceBase'

class DerivativeService extends ServiceBase {
  getSummary(params) {
    return this.applyMemoryCache(
      'DerivativeService.getSummary',
      params,
    )(() => DerivationProxy.getSummary(params))
  }

  downloadSummary(params) {
    return this.getFileDownload(() => DerivationProxy.downloadSummary(params))
  }

  getTimeAndSales(params) {
    return this.getData(() => DerivationProxy.getTimeAndSales(params))
  }

  getTimeAndSalesChart(params) {
    return this.getData(() => DerivationProxy.getTimeAndSalesChart(params))
  }

  getDerivative(params) {
    return this.getData(() => DerivationProxy.getDerivative(params))
  }

  //Derivative
  getProprietaryOverview(params) {
    return this.getData(() => DerivationProxy.getProprietaryOverview(params))
  }
  downloadProprietaryOverview(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadProprietaryOverview(params),
    )
  }
  getProprietaryDetail(params) {
    return this.getData(() => DerivationProxy.getProprietaryDetail(params))
  }
  downloadProprietaryDetail(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadProprietaryDetail(params),
    )
  }
  getProprietaryTicker(params) {
    return this.getData(() => DerivationProxy.getProprietaryTicker(params))
  }
  downloadProprietaryTicker(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadProprietaryTicker(params),
    )
  }

  //Order Statistics
  getOrderStatisticsOverview(params) {
    return this.getData(() =>
      DerivationProxy.getOrderStatisticsOverview(params),
    )
  }
  getOrderStatisticsTicker(params) {
    return this.getData(() => DerivationProxy.getOrderStatisticsTicker(params))
  }
  getOrderStatisticsDetail(params) {
    return this.getData(() => DerivationProxy.getOrderStatisticsDetail(params))
  }
  downloadOrderStatisticsOverview(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadOrderStatisticsOverview(params),
    )
  }
  downloadStatisticsTicker(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadStatisticsTicker(params),
    )
  }
  downloadStatisticsDetail(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadStatisticsDetail(params),
    )
  }

  //Historical Price
  getHistoricalOverview(params) {
    return this.getData(() => DerivationProxy.getHistoricalOverview(params))
  }
  getHistoricalTicker(params) {
    return this.getData(() => DerivationProxy.getHistoricalTicker(params))
  }
  getHistoricalDetail(params) {
    return this.getData(() => DerivationProxy.getHistoricalDetail(params))
  }
  downloadHistoricalOverview(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadHistoricalOverview(params),
    )
  }
  downloadHistoricalTicker(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadHistoricalTicker(params),
    )
  }
  downloadHistoricalDetail(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadHistoricalDetail(params),
    )
  }

  //Foreign
  getForeignOverview(params) {
    return this.getData(() => DerivationProxy.getForeignOverview(params))
  }
  getForeignTicker(params) {
    return this.getData(() => DerivationProxy.getForeignTicker(params))
  }
  getForeignDetail(params) {
    return this.getData(() => DerivationProxy.getForeignDetail(params))
  }
  downloadForeignOverview(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadForeignOverview(params),
    )
  }
  downloadForeignTicker(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadForeignTicker(params),
    )
  }
  downloadForeignDetail(params) {
    return this.getFileDownload(() =>
      DerivationProxy.downloadForeignDetail(params),
    )
  }
}

export default new DerivativeService()
