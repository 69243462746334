import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize } from '../../utils/FontSize'

export const Icon = ({ style, ...others }) => {
  return (
    <i
      style={{ ...style, fontSize: getFontSize(style?.fontSize) }}
      {...others}
    />
  )
}

Icon.propTypes = {
  style: PropTypes.object,
}

Icon.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
  },
}
