import { Translate } from 'react-redux-i18n'
import LinkTicker from '../../../../../common/dropdownLink/LinkTicker'
import { Span } from '../../../../../common/html/Span'
import { CellTornado } from '../../../../../common/table/CellTornado'
import { CellWithColor } from '../../../../../common/table/CellWithColor'
import TextEllipsis from '../../../../../common/textEllipsis'
import { formatVal, valToPercent } from '../../../../../utils/Value'
import {
  TABLE_COL_FORMATS,
  TABLE_COL_KEYS,
  TABLE_ROW_LEVEL,
  TABLE_STOCK_CURRENT_SECURITY_HOLDING_KEYS,
  TABLE_STOCK_CURRENT_SECURITY_HOLDING_TH_ROW_KEYS,
} from '../constants'
import { FirstColumnCell } from '../table/FirstColumnCell'

export const stockCurrentSecurityHoldingSchema = ({
  maxMinByColId,
  dataById,
  isGroupByIndustry,
  onRowCollapse,
}) => {
  return TABLE_STOCK_CURRENT_SECURITY_HOLDING_KEYS.map((item) => {
    let result = {
      ...item,
      isI18n: false,
      tdStyle: {
        textAlign: 'left',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_STOCK_TICKER) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
        },
        render: (val, rowId) =>
          isGroupByIndustry ? (
            <FirstColumnCell
              val={val}
              rowId={rowId}
              onRowCollapse={onRowCollapse}
            />
          ) : (
            <LinkTicker organizationId={dataById[rowId]?.assetId}>
              {val}
            </LinkTicker>
          ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_STOCK_NAME) {
      return {
        ...result,
        canCustomTd: true,
        render: (val, rowId, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: 250,
            }}
            className={className}
          >
            {!dataById[rowId]?.isCollapse ? (
              <TextEllipsis isI18n={false} text={val} />
            ) : (
              ''
            )}
          </td>
        ),
      }
    }

    result = {
      ...result,
      tdStyle: {
        textAlign: 'right',
      },
    }

    if ([TABLE_COL_KEYS.COL_STOCK_CLOSE_PRICE].includes(item.colId)) {
      return {
        ...result,
        render: (val, rowId) =>
          !dataById[rowId]?.isCollapse
            ? formatVal(Math.round(val), 0, true)
            : '',
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_STOCK_VOLUME,
        TABLE_COL_KEYS.COL_STOCK_VOLUME_CHANGE,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val, rowId) =>
          !dataById[rowId]?.isCollapse ? (
            <CellWithColor
              val={val}
              format={(val) => formatVal(val, 0, true)}
            />
          ) : (
            ''
          ),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_STOCK_PERCENT_CHANGE,
        TABLE_COL_KEYS.COL_STOCK_OUTSTANDING,
        TABLE_COL_KEYS.COL_STOCK_FUND_NAV,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        render: (val, rowId) =>
          !dataById[rowId]?.isCollapse ? (
            <CellWithColor
              val={val}
              format={(val) => valToPercent(val)}
              isPercent
            />
          ) : (
            ''
          ),
      }
    }

    if (
      [
        TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE,
        TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE_ICB,
      ].includes(item.colId)
    ) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'center',
        },
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: 200,
            }}
            className={className}
          >
            <CellTornado
              value={val}
              colId={item.colId}
              transformValue={(val) =>
                val / TABLE_COL_FORMATS.COL_STOCK_FUND_OWNER_CHANGE
              }
              maxMinByColId={maxMinByColId}
              isPercent={false}
            />
          </td>
        ),
      }
    }

    return {
      ...result,
      render: (val) => (
        <CellWithColor
          val={val}
          format={(val) => formatVal(val / TABLE_COL_FORMATS.COL_STOCK_VALUE)}
        />
      ),
    }
  })
}

export const stockCurrentSecurityHoldingTableThSchema = () => {
  return TABLE_STOCK_CURRENT_SECURITY_HOLDING_TH_ROW_KEYS.map((row) =>
    row.map((col) => {
      let result = {
        ...col,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK_TICKER,
          TABLE_COL_KEYS.COL_STOCK_NAME,
        ].includes(col.colId)
      ) {
        return {
          ...result,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
          },
          rowSpan: 2,
        }
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK_PERCENT_CHANGE,
          TABLE_COL_KEYS.COL_STOCK_CLOSE_PRICE,
        ].includes(col.colId)
      ) {
        return {
          ...result,
          thStyle: {
            textAlign: 'right',
            fontSize: 12,
            fontWeight: 600,
            maxWidth: 130,
            minWidth: 120,
            whiteSpace: 'normal',
          },
          rowSpan: 2,
        }
      }

      result = {
        ...result,
        thStyle: {
          textAlign: 'right',
          fontSize: 12,
          fontWeight: 600,
          minWidth: 100,
        },
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK_VOLUME,
          TABLE_COL_KEYS.COL_STOCK_VOLUME_CHANGE,
          TABLE_COL_KEYS.COL_STOCK_OUTSTANDING,
          TABLE_COL_KEYS.COL_STOCK_FUND_NAV,
        ].includes(col.colId)
      ) {
        return result
      }

      if (
        [
          TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE,
          TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE_ICB,
          TABLE_COL_KEYS.COL_STOCK_VALUE,
          TABLE_COL_KEYS.COL_STOCK_VALUE_ICB,
        ].includes(col.colId)
      ) {
        return {
          ...result,
          isI18nThTooltip: [
            TABLE_COL_KEYS.COL_STOCK_VALUE,
            TABLE_COL_KEYS.COL_STOCK_VALUE_ICB,
          ].includes(col.colId),
          thTooltipCustom: [
            TABLE_COL_KEYS.COL_STOCK_VALUE,
            TABLE_COL_KEYS.COL_STOCK_VALUE_ICB,
          ].includes(col.colId)
            ? 'fund.fundProfile.portfolio.portfolioTable.COL_STOCK_VALUE_NOTE'
            : undefined,
          renderTh: () => (
            <div style={{ textAlign: 'right' }} className="flex-column">
              <Span>
                <Translate value={result.title} />
              </Span>
              <Span className="t-italic" style={{ fontWeight: 400 }}>
                (<Translate value="common.UNIT.BILLION_VND" />)
              </Span>
            </div>
          ),
        }
      }

      if (col.colId === TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING) {
        return {
          ...result,
          thStyle: {
            textAlign: 'center',
            fontSize: 12,
            fontWeight: 600,
          },
          colSpan: 6,
        }
      }

      return result
    }),
  )
}

export const stockCurrentSecurityHoldingThCellTopById = {
  [TABLE_COL_KEYS.COL_STOCK_VOLUME]: [TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING],
  [TABLE_COL_KEYS.COL_STOCK_VOLUME_CHANGE]: [
    TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_STOCK_OUTSTANDING]: [
    TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_STOCK_VALUE]: [TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING],
  [TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE]: [
    TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_STOCK_VALUE_ICB]: [TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING],
  [TABLE_COL_KEYS.COL_STOCK_FUND_OWNER_CHANGE_ICB]: [
    TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING,
  ],
  [TABLE_COL_KEYS.COL_STOCK_FUND_NAV]: [TABLE_COL_KEYS.COL_STOCK_FUND_HOLDING],
}

export const formatStockCurrentSecurityHoldingGroupByIndustry = (rawData) => {
  const sectorIndexById = {}
  const dataBySector = []

  rawData.forEach((item) => {
    if (typeof sectorIndexById[item.icbId] !== 'number') {
      sectorIndexById[item.icbId] = dataBySector.length
      dataBySector.push({
        id: item.icbId,
        key: item.icbId,
        ticker: item.icbName,
        level: TABLE_ROW_LEVEL.LEVEL_1,
        isCollapse: true,
        value: item.valueICB,
        valueChange: item.valueChangeICB,
        childrenId: [
          {
            ...item,
            id: item.assetId,
            key: item.assetId,
            parentId: item.icbId,
            level: TABLE_ROW_LEVEL.LEVEL_2,
          },
        ],
        reportMonth: item.reportMonth,
        reportYear: item.reportYear,
      })
    } else {
      dataBySector[sectorIndexById[item.icbId]].childrenId.push({
        ...item,
        id: item.assetId,
        key: item.assetId,
        parentId: item.icbId,
        level: TABLE_ROW_LEVEL.LEVEL_2,
      })
    }
  })

  return dataBySector.reduce((array, item) => {
    const childrens = [...item.childrenId]
    const parent = {
      ...item,
      childrenId: item.childrenId.map((child) => child.id),
    }
    return [...array, parent, ...childrens]
  }, [])
}
