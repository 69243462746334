export const primaryMarket = {
  TITLE: 'Thị trường sơ cấp',
  OVERVIEW: 'Tổng quan',
  PRIMARY_MARKET: 'Thị trường sơ cấp',
  MARKET_STATISTICS: 'Thống kê thị trường',
  ALL: 'Tất cả',
  ALL_SECTOR: 'Tất cả ngành',
  PUBLIC: 'Công chúng',
  PRIVATE: 'Riêng lẻ',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
}

export const optionMethod = {
  all: 'Tất cả',
  private: 'Công chúng',
  PublicL: 'Riêng lẻ',
}

export const topIssuerRelease = {
  TITLE: 'Top 20 TCPH theo giá trị trái phiếu',
  tab: {
    Issue: 'Phát hành',
    Outstanding: 'Lưu hành',
  },
  table: {
    STT: 'STT',
    organizationShortName: 'Tổ chức phát hành',
    icbName: 'Ngành L2',
    issueValue: 'GTPH',
    issueValue2: 'GTLH',
    unitMoney: '(Tỷ VND)',
    averageDuration: 'Kỳ hạn BQ',
    unitYear: '(Năm)',
    averageCouponInterestRate: 'Coupon BQ',
    unitPercent: '(%)',
    averageRemain: 'KHCL BQ',
    unitMonth: '(Tháng)',
  },
}

// giá trị phiếu phát hành
export const ValueOfBonds = {
  TITLE: 'Giá trị Trái phiếu phát hành',
  fiter: {
    method: {
      issueMethod: 'Phương thức phát hành',
      collateral: 'Tài sản đảm bảo',
    },
  },
  chart: {
    labelLeft: 'Giá trị (Tỷ VND)',
    labelRight: 'Đơn vị (%)',
    privatePlacement: 'Riêng lẻ',
    publicPlacement: 'Công chúng',
    rateOfChange: 'Tăng trưởng ',
    undefined: 'Không xác định',
    tooltip: {
      label: ', Giá trị trái phiếu phát hành',
      privatePlacement: 'Phát hành riêng lẻ',
      publicPlacement: 'Phát hành công chúng',
      undefined: 'Không xác định',
      rateOfChange: 'Tăng trưởng',
      unitMoney: 'Tỷ VND',
      unitPercent: '% YoY',
    },
  },
  chartCollateral: {
    labelLeft: 'Giá trị (Tỷ VND)',
    labelRight: 'Đơn vị (%)',
    privatePlacement: 'Có tài sản đảm bảo',
    publicPlacement: 'Không tài sản đảm bảo',
    rateOfChange: 'Tăng trưởng ',
    tooltip: {
      label: ', Giá trị trái phiếu phát hành',
      privatePlacement: 'Có tài sản đảm bảo',
      publicPlacement: 'Không tài sản đảm bảo',
      rateOfChange: 'Tăng trưởng',
      unitMoney: 'Tỷ VND',
      unitPercent: '% YoY',
    },
  },
}
export const issuesPlan = {
  TITLE: 'Kế hoạch phát hành',
  table: {
    expectedDate: 'Thời gian',
    bondCode: 'Mã TP',
    organizationShortName: 'TCPH',
    issuePlanValue: 'GTPH',
    planDuration: 'Kỳ hạn',
    couponInterestRate: 'Coupon',
    additionTextIssuePlanValue: '(Tỷ VND)',
    additionPlanDuration: '(Năm)',
    additionCouponInterestRate: '(%)',
  },
}

export const outStandingBons = {
  TITLE: 'Giá trị trái phiếu lưu hành',
  fiter: {
    method: {
      all: 'Toàn thị trường',
      allLv2: 'Tất cả ngành L2',
    },
  },
  chart: {
    unitMoney: '(Tỷ VND)',
    labelLeft: 'Giá trị (Tỷ VND)',
    tooltiplabel: ', Toàn thị trường',
    outstandingBond: 'Lưu hành cuối kì',
    issueValue: 'Phát hành',
    redemptionValue: 'Đáo hạn',
    valueOfCancelBond: 'Mua lại',
    tooltipOutstandingBond: 'GT trái phiếu lưu hành cuối kì',
  },
}

export const latePayment = {
  TITLE: 'Trái phiếu trả chậm gốc, lãi theo ngành',
  filter: {
    latePaymentType: {
      all: 'Tất cả',
      origin: 'Gốc',
      interest: 'Lãi',
    },
  },
  chart: {
    unitMoney: '(Tỷ VND)',
    tooltiplabel: ', tất cả',
    labelLeft: 'Giá trị (Tỷ VND)',
    bank: 'Ngân hàng',
    RealEstate: 'Bất động sản',
    financialServices: 'Dịch vụ tài chính',
    build: 'Xây dựng',
    other: 'Khác',
    numberDelay: 'Số doanh nghiệp trả chậm',
    valueDelay: 'Giá trị trả chậm',
    numberJobDelay: 'Số DN trả chậm',
  },
}

// Trái phiếu trả chậm gốc, lãi theo ngành
export const expectedCash = {
  TITLE: 'Dòng tiền phải trả từ trái phiếu dự kiến',
  filter: {
    methodType: {
      all: 'Toàn thị trường',
      job: 'Ngành',
    },
    CouponType: {
      all: 'Tất cả',
      origin: 'Gốc',
      interest: 'Lãi',
    },
  },
  chart: {
    unitMoney: '(tỷ VND)',
    tooltiplabel: ', Giá trị trái phiếu phát hành',
    tooltiplabelJobAll: ', Nợ phải trả dự kiến',
    tooltiplabelJobCoupon: ', Lãi phải trả dự kiến',
    tooltiplabelJobAllPrincipal: ', Gốc phải trả dự kiến',
    labelLeft: 'Giá trị (Tỷ VND)',
    bank: 'Ngân hàng',
    RealEstate: 'Bất động sản',
    financialServices: 'Dịch vụ tài chính',
    build: 'Xây dựng',
    other: 'Khác',
    valueCashAll: 'Nợ phải trả dự kiến:',
    valueCashCoupon: 'Gốc phải trả dự kiến:',
    valueCashPrincipal: 'Lãi phải trả dự kiến:',
    debtDueAll: 'Nợ đã trả dự kiến',
    debtDueCoupon: 'Gốc đã trả dự kiến',
    debtDuePrincipal: 'Lãi đã trả dự kiến',
    total: 'Tổng',
  },
  tooltip:
    'Dòng tiền phải/đã trả dự kiến được cập nhật theo ngày thanh toán mới của các trái phiếu trả chậm và tái cấu trúc',
}

export const issuers = {
  TITLE: 'Tổ chức phát hành',
  SYNTHETIC: 'Tổng hợp',
  MONTHLY: 'Hàng tháng',
  QUARTERLY: 'Hàng quý',
  YEARLY: 'Hàng năm',
  MTD: '(MTD)',
  QTD: '(QTD)',
  YTD: '(YTD)',
  MOM: '(MoM)',
  QOQ: '(QoQ)',
  YOY: '(YoY)',
}
