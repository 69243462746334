import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import MasterService from '../../../../../../../core/services/common/MasterService'

const VN_INDEX_ID = 11

export const getVNIndex = createAsyncThunk(
  'newsReport/dailyReport/setting/companyNews/corporateNews/getVNIndex',
  async (params, { rejectWithValue }) => {
    try {
      const res = await MasterService.getOrganizationsByExchange({
        GroupId: VN_INDEX_ID,
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ isLoading: true })
      }
      return rejectWithValue({ isLoading: false })
    }
  },
)
