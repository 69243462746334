import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../../utils/ReducerRegistry'
import { getOpenMarketDisplay } from './thunk'

const initialState = {
  dailyValue: 'OneMonth',
  weeklyValue: 'ThreeMonths',
  monthlyValue: 'OneYear',
  chartData: [],
  chartLoading: true,
  activeLine: undefined,
}

const slice = createSlice({
  name: 'economy/monetary/openMarket/panelOpenMarketOperations/chartTop',
  initialState,
  reducers: {
    handleDailyValue: (state, action) => {
      state.dailyValue = action.payload
    },
    handleWeeklyValue: (state, action) => {
      state.weeklyValue = action.payload
    },
    handleMonthlyValue: (state, action) => {
      state.monthlyValue = action.payload
    },
    handleActiveLine: (state, action) => {
      state.activeLine = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOpenMarketDisplay.pending, (state) => {
      state.chartLoading = true
    })
    builder.addCase(getOpenMarketDisplay.fulfilled, (state, action) => {
      state.chartLoading = false
      state.chartData = action.payload || []
    })
    builder.addCase(getOpenMarketDisplay.rejected, (state, action) => {
      state.chartLoading = action.payload.isLoading
    })
  },
})

export const {
  handleDailyValue,
  handleActiveLine,
  handleWeeklyValue,
  handleMonthlyValue,
} = slice.actions

export const getDailyValue = (state) => state[slice.name].dailyValue
export const getWeeklyValue = (state) => state[slice.name].weeklyValue
export const getMonthlyValue = (state) => state[slice.name].monthlyValue
export const getChartData = (state) => state[slice.name].chartData
export const getChartLoading = (state) => state[slice.name].chartLoading
export const getActiveLine = (state) => state[slice.name].activeLine

register(slice.name, slice.reducer)
