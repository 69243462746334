import { useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import style from '../../index.module.css'
import AddedPeriod from './AddedPeriod'
import TreeIndicator from './TreeIndicator'
import useGetFinancialStatementIndicators from '../../useGetFinancialStatementIndicators'
import useGetRatioIndicators from '../../useGetRatioIndicators'
import useQuickSelectIndicator from '../../useQuickSelectIndicator'

export const tabs = {
  quickSelect: {
    value: 1,
    name: <Translate value="tool.smartScreening.QUICK_SELECT" />,
    class: `${style.w30} ${style.pointer} ${style.fontWeight600}`,
  },
  keyRatios: {
    value: 2,
    name: <Translate value="tool.smartScreening.RATIO_2" />,
    class: `${style.w28} ${style.pointer} ${style.fontWeight600}`,
  },
  financialStatement: {
    value: 3,
    name: <Translate value="tool.smartScreening.FINANCIAL_2" />,
    class: `${style.w42} ${style.pointer} ${style.fontWeight600}`,
  },
}

const FilterCondition = () => {
  const { quickSelectIndicatorsTree } = useQuickSelectIndicator()
  const { ratioIndicatorsTree } = useGetRatioIndicators(true)
  const { financialStatementIndicatorsTree } =
    useGetFinancialStatementIndicators(true)

  const [activeTab, setActiveTab] = useState(tabs.quickSelect.value)

  return (
    <div className="col">
      <div
        className={`col-header pl-12-pr-16 ${style.headerCondition} ${style.h37px}`}
      >
        <nav className="tab-in-panel w-100">
          {Object.keys(tabs).map((key) => (
            <a
              key={tabs[key].value}
              className={`${activeTab === tabs[key].value ? 'active' : ''} ${
                tabs[key].class
              }`}
              onClick={() => setActiveTab(tabs[key].value)}
            >
              <Span style={{ fontSize: 11 }}>{tabs[key].name}</Span>
            </a>
          ))}
        </nav>
      </div>
      {activeTab === tabs.financialStatement.value && <AddedPeriod />}
      <TreeIndicator
        activeTab={activeTab}
        quickSelectIndicatorsTree={quickSelectIndicatorsTree}
        ratioIndicatorsTree={ratioIndicatorsTree}
        financialStatementIndicatorsTree={financialStatementIndicatorsTree}
      />
    </div>
  )
}

export default FilterCondition
