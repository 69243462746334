import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import {
  keys,
  selectActiveMethodType,
  selectFilterSectorStatistic,
  selectLoading,
} from '../store/slice'
import { downloadIssuanceValueData } from '../store/thunk'
import Filter from './Filter'
import TableContent from './Table'

const SectorStatistics = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const methodType = useSelector(selectActiveMethodType)
  const { timeRange, From, To } = useSelector(selectFilterSectorStatistic)

  const isLoading = useSelector(selectLoading(keys.SECTOR_STATISTIC))

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const handleExportData = () => {
    const params =
      timeRange === 'Calendar'
        ? {
            IssueMethodId: methodType,
            // TimeRange: timeRange,
            From,
            To,
          }
        : {
            IssueMethodId: methodType,
            TimeRange: timeRange,
          }

    return downloadIssuanceValueData(params)
  }

  return (
    <Panel
      title="bond.corporateBond.sectorStatistic.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={() => handleExportData()}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            {size.height && (
              <div style={{ height: height - size.height, width: width }}>
                {isLoading ? <Loading /> : <TableContent />}
              </div>
            )}
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

export default SectorStatistics
