import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { YEAR_OPTIONS } from '../../../constants'
import { handleBarChartData } from '../helper'
import { getProvincesByGRDP } from './thunk'

const initialState = {
  year: new Date().getFullYear(),
  rangeYear: YEAR_OPTIONS,
  barChartLoading: true,
  barChartData: [],
}

const slice = createSlice({
  name: 'economy/gdp/gdpByProvince/panelTop10Provinces',
  initialState,
  reducers: {
    handleYear: (state, action) => {
      state.year = action.payload
    },
    handleRangeYear: (state, action) => {
      state.rangeYear = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProvincesByGRDP.pending, (state) => {
      state.barChartLoading = true
    })
    builder.addCase(getProvincesByGRDP.fulfilled, (state, action) => {
      state.barChartLoading = false
      state.barChartData = handleBarChartData(
        action.payload || [],
        action.meta.arg,
      )
    })
    builder.addCase(getProvincesByGRDP.rejected, (state, action) => {
      state.barChartLoading = action.payload.isLoading
    })
  },
})

export const { handleYear, handleRangeYear } = slice.actions
export const getYear = (state) => state[slice.name].year
export const getBarChartLoading = (state) => state[slice.name].barChartLoading
export const getBarChartDataInSlice = (state) => state[slice.name].barChartData
export const getRangeYear = (state) => state[slice.name].rangeYear

register(slice.name, slice.reducer)
