import { useDispatch, useSelector } from 'react-redux'
import { Span } from '../../../../../common/html/Span'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { selectRowIds, updateRowids } from './store/slice'

const DeleteOrganization = ({ organizationId }) => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const ids = useSelector(selectRowIds)
  const handleClick = () => {
    dispatch(updateRowids(ids.filter((e) => e !== organizationId)))
  }

  return organizationId !== basicInfo.organizationId ? (
    <div className="cursor-pointer">
      <Span onClick={handleClick} style={{ fontSize: 7, color: '#AEBCD2' }}>
        <i className="icon-delete-stroke" />
      </Span>
    </div>
  ) : (
    <div />
  )
}

export default DeleteOrganization
