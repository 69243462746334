import { CellRedirect } from '../../../../common/table/CellRedirect'
import TextEllipsis from '../../../../common/textEllipsis'
import { FUND_STRUCTURE_OPTIONS, FUND_TYPE_OPTIONS } from '../../constants'
import {
  ACTIVE_STATUS_TYPES,
  TABLE_COL_KEYS,
  TABLE_DEFAULT_BOTTOM_KEYS,
  TABLE_DEFAULT_KEYS,
  TABLE_TABS,
  TIME_FREQUENCY_OPTIONS,
  TIME_RANGE_TYPES,
  VALUE_TYPES,
} from '../constants'
import {
  assetAllocationSchema,
  assetAllocationTableThSchema,
  assetAllocationThCellTopById,
} from './assetAllocationHelpers'
import {
  fundFlowSchema,
  fundFlowTableThSchema,
  fundFlowThCellTopById,
} from './fundFlowHelpers'
import { fundIndexSchema } from './fundIndexHelpers'
import { keyMetricsSchema } from './keyMetricsHelpers'
import {
  performanceSchema,
  performanceTableThSchema,
  performanceThCellTopById,
} from './perfomanceHelpers'
import { sectorAllocationSchema, sectorSort } from './sectorAllocationHelpers'

export const getTableSchema = ({
  tableTab,
  valueType,
  locale,
  maxMinByColId,
  sectorAllocationColumns,
  onClick,
}) => {
  const defaultSchema = TABLE_DEFAULT_KEYS.map((item) => {
    const result = {
      ...item,
      isI18n: false,
      thStyle: {
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 600,
      },
      tdStyle: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_INDEX) {
      return {
        ...result,
        disableSort: true,
        render: (_, __, index) => index + 1,
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_TICKER) {
      return {
        ...result,
        tdStyle: {
          textAlign: 'left',
          whiteSpace: 'nowrap',
          width: '10%',
          maxWidth: 110,
        },
        canCustomTd: true,
        render: (val, rowId, { style, className }) => (
          <CellRedirect
            val={val}
            rowId={rowId}
            style={style}
            className={className}
            onClick={onClick}
          />
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_NAME) {
      return {
        ...result,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: [
                TABLE_TABS.FUND_FLOW,
                TABLE_TABS.ASSET_ALLOCATION,
              ].includes(tableTab)
                ? 250
                : 320,
            }}
            className={className}
          >
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    return result
  })

  let otherSchema = []

  switch (tableTab) {
    case TABLE_TABS.KEY_METRICS:
      otherSchema = keyMetricsSchema({ locale })
      break
    case TABLE_TABS.PERFORMANCE:
      otherSchema = performanceSchema({ locale })
      break
    case TABLE_TABS.FUND_FLOW:
      otherSchema = fundFlowSchema({ maxMinByColId, locale })
      break
    case TABLE_TABS.SECTOR_ALLOCATION:
      otherSchema = sectorAllocationSchema({
        sectorAllocationColumns,
        valueType,
        locale,
      })
      break
    case TABLE_TABS.ASSET_ALLOCATION:
      otherSchema = assetAllocationSchema({ valueType, locale })
      break
    case TABLE_TABS.FUND_INDEX:
      otherSchema = fundIndexSchema({ locale })
      break
    default:
      break
  }

  const defaultBottomSchema = TABLE_DEFAULT_BOTTOM_KEYS.map((item) => {
    const result = {
      ...item,
      isI18n: false,
      thStyle: {
        textAlign: 'left',
        fontSize: 12,
        fontWeight: 600,
      },
      tdStyle: {
        textAlign: 'left',
        whiteSpace: 'nowrap',
      },
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_STRUCTURE) {
      return {
        ...result,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
          minWidth: 125,
          maxWidth: 160,
        },
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_TYPE) {
      return {
        ...result,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
          minWidth: 120,
          maxWidth: 160,
        },
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_MANAGEMENT) {
      return {
        ...result,
        canCustomTd: true,
        render: (val, _, { style, className }) => (
          <td
            style={{
              ...style,
              width: '20%',
              maxWidth: 300,
            }}
            className={className}
          >
            <TextEllipsis isI18n={false} text={val} />
          </td>
        ),
      }
    }

    if (item.colId === TABLE_COL_KEYS.COL_FUND_FUND_STATUS) {
      return result
    }

    if (item.colId === TABLE_COL_KEYS.COL_FREQUENCY) {
      return {
        ...result,
        isI18nThTooltip: true,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
        thTooltipCustom: 'fund.fundCenter.statistic.COL_FREQUENCY_NOTE',
      }
    }

    return result
  })

  return [...defaultSchema, ...otherSchema, ...defaultBottomSchema]
}

export const getTableThSchema = (tableTab, valueType) => {
  if (
    [
      TABLE_TABS.PERFORMANCE,
      TABLE_TABS.FUND_FLOW,
      TABLE_TABS.ASSET_ALLOCATION,
    ].includes(tableTab)
  ) {
    const defaultSchema = TABLE_DEFAULT_KEYS.map((item) => {
      const result = {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
        rowSpan: 2,
      }

      if (item.colId === TABLE_COL_KEYS.COL_INDEX) {
        return {
          ...result,
          disableSort: true,
        }
      }

      if (item.colId === TABLE_COL_KEYS.COL_FUND_NAME) {
        return {
          ...result,
          canCustomTd: true,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            maxWidth: [
              TABLE_TABS.FUND_FLOW,
              TABLE_TABS.ASSET_ALLOCATION,
            ].includes(tableTab)
              ? 250
              : 320,
          },
        }
      }

      return result
    })

    let otherSchema = [[], []]

    switch (tableTab) {
      case TABLE_TABS.PERFORMANCE:
        otherSchema = performanceTableThSchema()
        break
      case TABLE_TABS.FUND_FLOW:
        otherSchema = fundFlowTableThSchema()
        break
      case TABLE_TABS.ASSET_ALLOCATION:
        otherSchema = assetAllocationTableThSchema({ valueType })
        break
      default:
        break
    }

    const defaultBottomSchema = TABLE_DEFAULT_BOTTOM_KEYS.map((item) => {
      const result = {
        ...item,
        isI18n: false,
        thStyle: {
          textAlign: 'left',
          fontSize: 12,
          fontWeight: 600,
        },
        rowSpan: 2,
      }

      if (item.colId === TABLE_COL_KEYS.COL_FUND_STRUCTURE) {
        return {
          ...result,
          canCustomTd: true,
          thStyle: {
            textAlign: 'left',
            fontSize: 12,
            fontWeight: 600,
            minWidth: 125,
            maxWidth: 160,
          },
        }
      }

      if (item.colId === TABLE_COL_KEYS.COL_FREQUENCY) {
        return {
          ...result,
          isI18nThTooltip: true,
          thTooltipCustom: 'fund.fundCenter.statistic.COL_FREQUENCY_NOTE',
        }
      }

      return result
    })

    return [
      [...defaultSchema, ...otherSchema[0], ...defaultBottomSchema],
      otherSchema[1],
    ]
  }
}

export const getTableThCellTopById = ({ tableTab, valueType }) => {
  switch (tableTab) {
    case TABLE_TABS.PERFORMANCE:
      return performanceThCellTopById
    case TABLE_TABS.FUND_FLOW:
      return fundFlowThCellTopById
    case TABLE_TABS.ASSET_ALLOCATION:
      return assetAllocationThCellTopById(valueType)
    default:
      return
  }
}

export const getMaxMinDataByColId = (data, keys) => {
  const result = {}
  keys.forEach((id) => (result[id] = { max: 0, min: 0 }))
  data.forEach((item) => {
    Object.keys(result).forEach((key) => {
      result[key].max = Math.max(result[key].max, item[key])
      result[key].min = Math.min(result[key].min, item[key])
    })
  })
  return result
}

export const getParams = ({
  filters,
  tableTab,
  isDownload = false,
  funds,
  fundManagements,
}) => {
  let params = {
    FundIds:
      !filters.fund.length || filters.fund.length === funds.length
        ? undefined
        : filters.fund.join(','),
    FundStructureIds:
      filters.structure.join(',') ||
      FUND_STRUCTURE_OPTIONS.map((item) => item.value).join(','),
    FundTypeIds:
      filters.type.join(',') ||
      FUND_TYPE_OPTIONS.map((item) => item.value).join(','),
    ScheduleIds:
      filters.frequency.join(',') ||
      TIME_FREQUENCY_OPTIONS.map((item) => item.value).join(','),
    FundManagementIds:
      !filters.management.length ||
      fundManagements.length === filters.management.length
        ? undefined
        : filters.management.join(','),
    ActiveStatusIds:
      filters.status.join(',') ||
      ACTIVE_STATUS_TYPES.map((item) => item.value).join(','),
    MostRecent: filters.timeRange === TIME_RANGE_TYPES.MOST_RECENT,
    Month:
      filters.timeRange === TIME_RANGE_TYPES.SELECT_TIME_PERIOD
        ? filters.month
        : undefined,
    Year:
      filters.timeRange === TIME_RANGE_TYPES.SELECT_TIME_PERIOD
        ? filters.year
        : undefined,
  }

  if (!(tableTab === TABLE_TABS.SECTOR_ALLOCATION && !isDownload)) {
    let sortField = filters.sortField

    if (tableTab === TABLE_TABS.SECTOR_ALLOCATION) {
      if (
        sortField &&
        Object.values(VALUE_TYPES).some((val) => sortField.includes(val))
      ) {
        sortField = Number(
          sortField
            .replace(VALUE_TYPES.ABSOLUTE, '')
            .replace(VALUE_TYPES.RELATIVE, ''),
        )
      }

      params = { ...params, IcbIds: sectorSort }
    }

    params = {
      ...params,
      SortField: sortField,
      SortOrder: filters.sortOrder,
    }
  }

  if (!isDownload) {
    params = {
      ...params,
      Page: filters.page,
      PageSize: 1000,
    }
  }

  return params
}

export const getSortFieldDefaultByTableTab = (tableTab, valueType) => {
  switch (tableTab) {
    case TABLE_TABS.KEY_METRICS:
      return TABLE_COL_KEYS.COL_CHANGE_NAV_YTD
    case TABLE_TABS.PERFORMANCE:
      return TABLE_COL_KEYS.COL_NAV_CCQ_PERFORMANCE_YTD
    case TABLE_TABS.FUND_FLOW:
      return TABLE_COL_KEYS.COL_TOTAL_FUND_FLOW_YTD
    case TABLE_TABS.SECTOR_ALLOCATION:
      return valueType + sectorSort[0]
    case TABLE_TABS.ASSET_ALLOCATION:
      return TABLE_COL_KEYS.COL_STOCK_VALUE + valueType
    case TABLE_TABS.FUND_INDEX:
      return TABLE_COL_KEYS.COL_FOREIGN_OWNED
    default:
      return ''
  }
}
