import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadingKey,
  selectLoading,
  selectLocale,
  selectStockById,
  selectStocks,
} from './store/slice'
import { getStockThunk } from './store/thunk'

const useGetStock = (disableCallApi = true) => {
  const dispatch = useDispatch()

  const locale = useSelector(selectLocale)
  const loading = useSelector(selectLoading(loadingKey.STOCK))
  const stocks = useSelector(selectStocks)
  const stockById = useSelector(selectStockById)

  useEffect(() => {
    if (!disableCallApi && locale) {
      dispatch(getStockThunk())
    }
  }, [locale])

  return {
    loading,
    stocks,
    stockById,
  }
}

export default useGetStock
