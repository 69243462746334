import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../../common/html/Span'
import { Search } from '../../../../../common/search'
import style from '../../style.module.css'
import { setListCompareSearch } from '../store/slice'
import { clearCompareChecked, searchData } from './store/slice'

const SearchCompare = () => {
  const dispatch = useDispatch()

  const handleClearAll = () => {
    dispatch(clearCompareChecked())
    dispatch(setListCompareSearch([]))
  }

  const handleTextChange = (value) => {
    dispatch(searchData(value))
  }

  return (
    <div className="d-flex justify-content-space-between">
      <Search onChange={handleTextChange} margin={'0px'} width={246} />
      <button
        className={`btn ${style.buttonClearCompare}`}
        onClick={handleClearAll}
      >
        <i className="icon-trash"></i>
        <Span
          style={{ fontSize: 11, fontWeight: 'normal', whiteSpace: 'nowrap' }}
        >
          <Translate value="market.marketInDepth.valuation.CLEAR_ALL" />
        </Span>
      </button>
    </div>
  )
}

export default SearchCompare
