export const ServiceProxyConfig = {
  Common: {
    Macro: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/Macro/`,
    },
    Master: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/MasterData/`,
    },
    ChartLayout: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/ChartLayout/`,
    },
    SimpleSearch: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/SimpleSearch/`,
    },
    AdvancedSearch: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/AdvancedSearch/`,
    },
    Charting: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/TradingView/`,
    },
    AuthDevice: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/AuthDevice/`,
    },
    Package: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/Package/`,
    },
  },
  WatchList: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/WatchList/`,
  },
  WatchListBond: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/MyWatchlist/`,
  },
  UserSetting: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/project/v1/UserSetting/`,
  },
  Corporate: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/Corporate/`,
    PenetrationAnalysis: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/Corporate/PenetrationAnalysis/`,
    },
    SegmentalAnalysis: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/BusinessModel/`,
    },
    Company: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/CompanySnapshot/`,
    },
    CompanySnapshot: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/CompanySnapshot/Company/`,
    },
  },
  Market: {
    NewsReport: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/NewsAndReport/`,
    },
    Derivative: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/Derivative/`,
    },
    CoveredWarrant: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/CoverdWarrant/`,
    },
    TopMover: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/TopMover/`,
    },
    SectorInDepth: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/SectorInDepth/`,
    },
    EquityTrading: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/EquityTrading/`,
    },
    PriceData: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/PriceData/`,
    },
    MarketInDepth: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/MarketInDepth/`,
    },
    MarketInDepth_Valuation: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/MarketInDepth/valuation/`,
    },
    MarginLoans: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/market/v1/MarginLoans/`,
    },
  },
  Sector: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/`,
    SectorStatistics: {
      Overview: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/Summary/`,
      },
      Detail: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/SectorRatio/`,
      },
    },
    SectorConstituents: {
      Overview: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/Constituent/Overview/`,
      },
      NewsReports: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/NewsAndReports/`,
      },
      Ranking: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/Ranking/`,
      },
      SectorFinancialAnalysis: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/FinancialAnalysis/`,
      },
      Segmentation: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/Sector/Segmentation/`,
      },
    },
    SectorSpecific: {
      Steel: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/SectorSpecific/Steel/`,
      },
      Fishery: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/sector/v1/SectorSpecific/Fishery/`,
      },
    },
  },
  Economy: {
    CPIAndInflation: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/CPI/`,
    },
    FDI: {
      Overview: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/FDI/Overview/`,
      },
      FDIByIndustries: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/FDI/Industry/`,
      },
      FDIByProvinces: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/FDI/Province/`,
      },
      FDIByCountries: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/FDI/Country/`,
      },
    },
    Production: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/ProductionComsumption/`,
    },
    ImportExport: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/ExportImport/`,
    },
    Consumption: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/ProductionComsumption/Retails/`,
    },
    Monetary: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/Monetary/`,
    },
    OpenMarket: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/OpenMarket/`,
    },
    InterestRate: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/InterestRate/`,
    },
    BalanceOfPayment: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/BallancePayment/`,
    },
    GDP: {
      Sector: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/GDP/Sector/`,
      },
      Province: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/GDP/Province/`,
      },
      SpendingMethod: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/GDP/SpendingMethod/`,
      },
    },
    Fiscal: {
      StateBudget: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/Fiscal/SBB/`,
      },
    },
    Event: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/economy/v1/Economy/NewsEvents/`,
    },
  },
  Tool: {
    SmartScreening: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/Screening/`,
    },
    DataExplorer: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/DataExplorer/`,
      Corporate: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/DataExplorer/Corporate/`,
      },
      IndicesSector: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/DataExplorer/IndexSector/`,
      },
      Economy: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/DataExplorer/Economy/`,
      },
      TradingData: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/DataExplorer/TradingData/`,
      },
    },
  },
  FinancialChart: {
    ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/search/v1/FinancialChart/`,
  },
  NewsReport: {
    DailyReport: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/profile/v1/ReportDaily/`,
    },
  },
  Bond: {
    PrimaryMarket: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/PrimaryMarket/`,
    },
    PriceBoard: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/PriceBoard/`,
    },
    CompanyBond: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/CompanyBond/`,
    },
    BondAnalysis: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/BondAnalysis/`,
    },
    UserBondCashFlow: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/UserBondCashFlow/`,
    },
    MasterDataApi: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/api/v1/MasterData/`,
    },
    BondList: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/BondList/`,
    },
    Statistic: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/BondCorporateTradingStatistic/`,
    },
    SecurityMasterCb: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/SecurityMasterCb/`,
    },
    MyWatchlist: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/MyWatchlist/`,
    },
    BenchmarkingSecondary: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/BenchmarkingSecondary/`,
    },
    ComparableAnalysis: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/ComparableAnalysis/`,
    },
    CompanyBondDebtAndLoan: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/DebtAndLoan/`,
    },
    Portfolio: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/Portfolio/`,
    },
    InterConnectionMap: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/InterConnectionMap/`,
    },
    SecondaryMarketOverview: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/SecondaryMarketOverview/`,
    },
    UserBond: {
      ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/bond/v1/UserBond/`,
    },
  },
  Fund: {
    FundCenter: {
      FundOverview: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundOverview/`,
      },
      FundStatistic: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundStatistics/`,
      },
      FundHolding: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundHolding/`,
      },
    },
    FundProfile: {
      FundProfile: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundProfile/`,
      },
      FundPortfolio: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundCategory/`,
      },
      FundNavData: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundNAV/`,
      },
      FundReport: {
        ServiceUrl: `${process.env.REACT_APP_SERVICE_ENDPOINT}/fund/v1/FundReport/`,
      },
    },
  },
}
