import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import CreateBondService from '../../../../core/services/bond/bondScreening/CreateBondService'
import PortfolioService from '../../../../core/services/bond/portfolio/PortfolioService'
import SimpleSearchService from '../../../../core/services/common/SimpleSearchService'
import WatchlistService from '../../../../core/services/watchlistBond/WatchlistService'
import { handleExport } from '../../../utils/Export'
import { MAX_LENGTH } from '../constants'
import { keys } from './slice'

export const getBondAndUserBondData = createAsyncThunk(
  'bond/portfolio/GET_BOND_AND_USER_BOND',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBondAndUserBondNotCache(
        params,
      )

      if (response.success) {
        return {
          data: response.data,
          hasNextPage: response.hasNextPage,
          page: params.Page,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const fetchBondAndUserBondData = createAsyncThunk(
  'bond/portfolio/FETCH_MORE_BOND_AND_USER_BOND',
  async (params, { rejectWithValue }) => {
    try {
      const response = await SimpleSearchService.searchBondAndUserBondNotCache(
        params,
      )

      if (response.success) {
        return { data: response.data, hasNextPage: response.hasNextPage }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getWatchListData = createAsyncThunk(
  'bond/portfolio/GET_WATCH_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.getWatchlist(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getWatchListDetailData = createAsyncThunk(
  'bond/portfolio/GET_WATCH_LIST_DETAIL',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const response = await WatchlistService.getWatchlistDetail({
        bondWatchlistId: params?.WatchListId,
      })

      if (response.success) {
        const state = getState()
        const portfolioState = state['bond/portfolio']
        const data = portfolioState.data[keys.BOND_INFORMATION].data

        if ((response?.data?.length ?? 0) + (data?.length ?? 0) > MAX_LENGTH) {
          return true
        }

        dispatch(getPortfolioInvestmentData(params))
        return false
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getBondDetailData = createAsyncThunk(
  'bond/portfolio/GET_BOND_DETAIL_DATA',
  async (params, { rejectWithValue, dispatch, getState }) => {
    try {
      const state = getState()
      const portfolioState = state['bond/portfolio']
      const data = portfolioState.data[keys.BOND_INFORMATION].data

      if ((data?.length ?? 0) + 1 > MAX_LENGTH) {
        return true
      }

      dispatch(getPortfolioInvestmentData(params))
      return false
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPortfolioData = createAsyncThunk(
  'bond/portfolio/GET_PORTFOLIO_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.getPortfolio(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPortfolioInvestmentData = createAsyncThunk(
  'bond/portfolio/GET_PORTFOLIO_INVESTMENT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.getPortfolioInvestment(params)

      if (response.success) {
        return { data: response.data, watchListId: params?.WatchListId ?? null }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getPortfolioInvestmentByIdData = createAsyncThunk(
  'bond/portfolio/GET_PORTFOLIO_INVESTMENT_BY_ID',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.getPortfolioInvestment(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const postPortfolioInvestmentData = createAsyncThunk(
  'bond/portfolio/POST_PORTFOLIO_INVESTMENT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const { bonds, id } = params

      const response = await PortfolioService.postPortfolioInvestment({ bonds })

      if (response.success) {
        return {
          data: response.data?.[0] ?? undefined,
          id,
          bondVolume: bonds?.[0]?.bondVolume ?? undefined,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const changeValuationDatePortfolioInvestmentData = createAsyncThunk(
  'bond/portfolio/CHANGE_VALUATION_DATE_PORTFOLIO_INVESTMENT_LIST',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.postPortfolioInvestment(params)

      if (response.success) {
        return response.data
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const getExpectedCashflowData = createAsyncThunk(
  'bond/portfolio/GET_EXPECTED_CASHFLOW_LIST',
  async (params, { rejectWithValue, getState }) => {
    try {
      const response = await PortfolioService.getExpectedCashflowData(params)

      if (response.success) {
        const state = getState()

        return {
          data: response.data,
          type: params.periodTakeBy,
          locale: state.i18n.locale,
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadExpectedCashflowData = createAsyncThunk(
  'bond/portfolio/DOWNLOAD_EXPECTED_CASHFLOW_LIST',
  async (data) => {
    const response = await PortfolioService.downloadExpectedCashflowData(data)
    handleExport(response.data, response.filename)
  },
)

export const addPortfolioData = createAsyncThunk(
  'bond/portfolio/ADD_PORTFOLIO_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params } = data

      const response = await PortfolioService.addPortfolio(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          const portfolio = responsePortfolio.data.find(
            (item) =>
              item?.portfolioName?.trim() === params?.portfolioName?.trim(),
          )

          return { data: responsePortfolio.data, portfolio }
        }

        return rejectWithValue(false)
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const addPortfolioDataWithClearData = createAsyncThunk(
  'bond/portfolio/ADD_PORTFOLIO_DATA_CLEAR_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params } = data

      const response = await PortfolioService.addPortfolio(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          const portfolio = responsePortfolio.data.find(
            (item) =>
              item?.portfolioName?.trim() === params?.portfolioName?.trim(),
          )

          return { data: responsePortfolio.data, portfolio }
        }

        return rejectWithValue(false)
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updatePortfolioData = createAsyncThunk(
  'bond/portfolio/UPDATE_PORTFOLIO_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params, isClear } = data

      const response = await PortfolioService.updatePortfolioDetails(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          const portfolio = responsePortfolio.data.find(
            (item) => item?.portfolioId === params?.portfolioId,
          )

          return { data: responsePortfolio.data, portfolio, isClear }
        }

        return rejectWithValue(false)
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updatePortfolioDataWithClearData = createAsyncThunk(
  'bond/portfolio/UPDATE_PORTFOLIO_DATA_WITH_CLEAR_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params } = data

      const response = await PortfolioService.updatePortfolioDetails(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          const portfolio = responsePortfolio.data.find(
            (item) => item?.portfolioId === params?.portfolioId,
          )

          return { data: responsePortfolio.data, portfolio }
        }

        return rejectWithValue(false)
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const renamePortfolioData = createAsyncThunk(
  'bond/portfolio/RENAME_PORTFOLIO_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.updatePortfolio(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          return {
            data: responsePortfolio.data,
            portfolioId: params?.portfolioIds ?? null,
          }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const deletePortfolioData = createAsyncThunk(
  'bond/portfolio/DELETE_PORTFOLIO_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await PortfolioService.deletePortfolio(params)

      if (response.success) {
        const responsePortfolio = await PortfolioService.getPortfolio(params)

        if (responsePortfolio.success) {
          return {
            data: responsePortfolio.data,
            portfolioId: params?.portfolioIds?.[0] ?? null,
          }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const renameWatchListData = createAsyncThunk(
  'bond/portfolio/RENAME_WATCHLIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.updateMasterWatchlist(params)

      if (response.success) {
        const responseWatchlist = await WatchlistService.getWatchlist()

        if (responseWatchlist.success) {
          return {
            data: responseWatchlist.data,
            watchlistId: params?.bondWatchListId ?? null,
          }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const deleteWatchlistData = createAsyncThunk(
  'bond/portfolio/DELETE_WATCHLIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.deleteWatchlist(params)

      if (response.success) {
        const responseWatchlist = await WatchlistService.getWatchlist()

        if (responseWatchlist.success) {
          return {
            data: responseWatchlist.data,
            watchlistId: params?.bondWatchlistId?.[0] ?? null,
          }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const createWatchlistData = createAsyncThunk(
  'bond/portfolio/CREATE_WATCHLIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.createWatchlist(params)

      if (response.success) {
        const responseWatchlist = await WatchlistService.getWatchlist()

        if (responseWatchlist.success) {
          const watchlist = responseWatchlist.data.find(
            (item) =>
              item?.bondWatchListName?.trim() === params?.watchlistName?.trim(),
          )

          return { data: responseWatchlist.data, watchlist }
        }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const updateWatchlistData = createAsyncThunk(
  'bond/portfolio/UPDATE_WATCHLIST_DATA',
  async (params, { rejectWithValue }) => {
    try {
      const response = await WatchlistService.updateWatchlist(params)

      if (response.success) {
        const responseWatchlist = await WatchlistService.getWatchlist()

        const watchlist = responseWatchlist.data.find(
          (item) => item?.bondWatchListId === params?.bondWatchlistId,
        )

        return { data: responseWatchlist.data, watchlist }
      }

      return rejectWithValue(false)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(true)
      }
      return rejectWithValue(false)
    }
  },
)

export const downloadPortfolioInvestmentData = createAsyncThunk(
  'bond/portfolio/DOWNLOAD_PORTFOLIO_INVESTMENT_DATA',
  async (data) => {
    const response = await PortfolioService.downloadPortfolioInvestment(data)
    handleExport(response.data, response.filename)
  },
)

export const createBondPortfolio = createAsyncThunk(
  'bond/portfolio/CREATE_BOND_DATA',
  async (data, { rejectWithValue }) => {
    try {
      const { params, locale } = data

      const response = await CreateBondService.createBond(params)

      if (response.success) {
        const payload = {
          Language: locale,
          Page: 1,
          PageSize: 10,
          Term: params?.ticker ?? '',
        }

        const responseBond = await SimpleSearchService.searchBondAndUserBond(
          payload,
        )

        if (responseBond.success) {
          return responseBond?.data[0] ?? null
        }
      }

      return rejectWithValue(null)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue(null)
      }
      return rejectWithValue(null)
    }
  },
)
