import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../../../common/textEllipsis'
import style from '../index.module.css'
import { selectListItems } from './store/slice'

export const TdSelect = ({
  val,
  rowId,
  onRowCollapse,
  dataTableItem,
  isRowCollapse,
}) => {
  const GAP_ITEM = 8
  const MARGIN_LEFT = 20

  const listItems = useSelector(selectListItems)

  const [isCollapse, setIsCollapse] = useState(false)

  const onClickCollapse = (e) => {
    e.stopPropagation()
    onRowCollapse(rowId, !isCollapse)
    setIsCollapse(!isCollapse)
  }

  const handleChangeListCategory = () => {}

  const handleChecked = () => {
    return listItems?.some((item) => item.id === rowId)
  }

  const handleDisableCheckbox = () => {}

  useEffect(() => {
    setIsCollapse(isRowCollapse)
  }, [isRowCollapse])

  return (
    <div className="d-flex align-center" style={{ gap: GAP_ITEM }}>
      <div>
        <input
          type="checkbox"
          className={`checkbox ${style.checkbox}`}
          onChange={handleChangeListCategory}
          checked={handleChecked()}
          disabled={handleDisableCheckbox()}
        />
      </div>
      <div
        className="d-flex"
        style={{
          marginLeft: (dataTableItem?.level - 1) * MARGIN_LEFT || 0,
        }}
      >
        {dataTableItem?.level === 1 ? (
          <button
            style={{ cursor: 'pointer' }}
            className="align-center"
            onClick={onClickCollapse}
          >
            <i
              className={`${
                isCollapse ? 'icon-caret-right' : 'icon-caret-down'
              }`}
              style={{ fontSize: '8px', color: '#8c9194' }}
            ></i>
          </button>
        ) : (
          <div
            style={{
              marginLeft: (dataTableItem?.level - 1) * MARGIN_LEFT || 0,
            }}
          ></div>
        )}
        <div className={dataTableItem?.level === 1 ? style.pointer : ''}>
          <TextEllipsis isI18n={false} text={val} />
        </div>
      </div>
    </div>
  )
}
