import { COMPONENT } from '../../../configs/Layout'
import PanelFilter from '../../common/panelFilter'
import { withWrapper } from '../../common/withWrapper'
import Filter from './filter'
import PanelEventTable from './PanelEventTable'

const LARGE_SCREEN_BREAKPOINT = 1440 - 16 //viewport padding = 16

const MAP_KEY = {
  FILTER: 'FILTER',
  EVENTS: 'EVENTS',
}

const FILTER_WIDTH = 309
const MIN_WIDTH = 500

const CALENDAR_MIN_HEIGHT = 600
const TABLE_MIN_HEIGHT = 300

const MAP_SIZE = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: `100%`,
    top: 0,
    left: 0,
    filterResize: [
      {
        key: MAP_KEY.EVENTS,
        ratioWidth: 0,
        ratioLeft: -1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },

  [`${MAP_KEY.EVENTS}`]: {
    width: `calc((100% - ${FILTER_WIDTH + 4 * COMPONENT.MARGIN}px))`,
    height: `100%`,
    top: 0,
    left: FILTER_WIDTH + 2 * COMPONENT.MARGIN,
    minLeft: FILTER_WIDTH + 4 * COMPONENT.MARGIN + MIN_WIDTH,
    minWidth: MIN_WIDTH,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const MAP_SIZE_SMALL_SCREEN = {
  [`${MAP_KEY.FILTER}`]: {
    width: FILTER_WIDTH,
    height: CALENDAR_MIN_HEIGHT,
    top: 0,
    left: 0,
    minWidth: FILTER_WIDTH,
    minHeight: CALENDAR_MIN_HEIGHT,
    filterResize: [
      {
        key: MAP_KEY.EVENTS,
        ratioWidth: 0,
        ratioLeft: -1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.EVENTS,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.EVENTS,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: true,
    disableHorizontalResize: false,
  },

  [`${MAP_KEY.EVENTS}`]: {
    width: `100%`,
    height: `calc(60% - ${COMPONENT.MARGIN}px)`,
    top: CALENDAR_MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    left: 0,
    minTop: CALENDAR_MIN_HEIGHT + 2 * COMPONENT.MARGIN,
    minWidth: FILTER_WIDTH + 500 + 2 * COMPONENT.MARGIN,
    minHeight: TABLE_MIN_HEIGHT,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelFilter
        title="market.event.FILTER"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.FILTER}
        sizes={sizes}
        setSizes={setSizes}
      >
        <Filter />
      </PanelFilter>

      <PanelEventTable
        title="market.event.EVENT"
        panelRefs={panelRefs}
        panelKey={MAP_KEY.EVENTS}
        sizes={sizes}
        setSizes={setSizes}
      />
    </>
  )
}

export default withWrapper(
  MAP_SIZE,
  panelRefs,
  MAP_SIZE_SMALL_SCREEN,
  LARGE_SCREEN_BREAKPOINT,
)(Content)
