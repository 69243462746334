const MIN_LEVEL = 3
const MAX_LEVEL = 1

export const applyTemplateSetting = (listChecked, setting) => {
  const keys = Object.keys(listChecked)
  const result = {}
  keys.forEach((key) => {
    result[key] = setting.includes(key)
  })

  return result
}

export const tickTemplate = (listDisplay, templateDisplay) => {
  const keys = Object.keys(listDisplay)
  const result = {}
  keys.forEach((key) => {
    result[key] = {
      isDisplay: templateDisplay.includes(key),
      isChecked: templateDisplay.includes(key),
    }
  })

  return result
}

export const addManyTicker = (listDisplay, listId) => {
  listId.forEach((id) => {
    listDisplay[id].isDisplay = true
  })
}

export const addDisplayValues = (payload, vnIndexId) => {
  if (!Object.keys(payload).length) {
    return {}
  }
  const initByVNIndex = Object.keys(vnIndexId).reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: {
        isDisplay: true,
        isChecked: true,
      },
    }
  }, {})

  const allTicker = Object.keys(payload).reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: {
        isDisplay: false,
        isChecked: false,
      },
    }
  }, {})

  return {
    ...allTicker,
    ...initByVNIndex,
  }
}

export const handleCheckedAllItem = (listChecked, isCheckAll) => {
  return Object.keys(listChecked).reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: !isCheckAll,
    }
  }, {})
}

export const checkedAllItem = (listDisplay, payload) => {
  const { isCheckedAll, displayedItemIds } = payload
  const newChecked = displayedItemIds.reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: { ...listDisplay[cur], isChecked: !isCheckedAll },
    }
  }, {})

  return {
    ...listDisplay,
    ...newChecked,
  }
}

export const initChecked = (ids, dataById) => {
  if (!ids.length) {
    return {}
  }

  const idWithLevel = ids.map((id) => ({
    id,
    level: dataById[id].level,
  }))

  return idWithLevel.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: true,
    }
  }, {})
}

export const resetNewsChecked = (listChecked) => {
  return Object.keys(listChecked).reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: false,
    }
  }, {})
}

export const handleIdWithAllChild = (ids, dataById) => {
  if (!ids.length) {
    return {}
  }

  const idWithLevel = ids.map((id) => ({
    id,
    level: dataById[id].level,
  }))

  return idWithLevel.reduce((prev, cur) => {
    return {
      ...prev,
      [cur.id]: {
        listChild: findAllChild(cur, idWithLevel).filter(
          (item) => item !== cur.id,
        ),
        listParent: findAllParent(cur, idWithLevel),
      },
    }
  }, {})
}

const findAllChild = (item, idWithLevel) => {
  if (item.level >= MIN_LEVEL) {
    return []
  }

  const currentIndex = idWithLevel.findIndex(
    (element) => item.id === element.id,
  )
  let nearestIndex = idWithLevel.findIndex(
    (element, index) => index > currentIndex && item.level >= element.level,
  )

  nearestIndex = nearestIndex === -1 ? idWithLevel.length : nearestIndex

  return idWithLevel.slice(currentIndex, nearestIndex).map((item) => item.id)
}

const findAllParent = (item, idWithLevel) => {
  if (item.level === MAX_LEVEL) {
    return []
  }

  const currentIndex = idWithLevel.findIndex(
    (element) => item.id === element.id,
  )
  const parentContainerArray = idWithLevel.slice(0, currentIndex)
  return [...Array(item.level - MAX_LEVEL)]
    .map((_, index) => {
      const parent = parentContainerArray.findLast(
        (element) => element.level === index + 1,
      )
      return parent?.id
    })
    .filter((item) => item !== undefined)
    .reverse()
}

export const keyById = (payload) => {
  if (!payload.length) {
    return {}
  }

  const result = {}
  payload.forEach((item) => {
    result[item.newsCategoryId] = {
      ...item,
      isOpen: true,
      hasCollapse: item.newsCategoryLevel === 1 || item.newsCategoryLevel === 2,
      listDisable: [],
      level: item.newsCategoryLevel,
      title: item.newsCategoryName,
    }
  })

  return result
}

export const removeAllItemListDisplay = (listDisplay) => {
  return Object.keys(listDisplay).reduce((prev, cur) => {
    return {
      ...prev,
      [cur]: {
        isDisplay: false,
        isChecked: false,
      },
    }
  }, {})
}

export const sortIdByLevel = (payload) => {
  if (!payload.length) {
    return []
  }

  const maxLevel = getMaxLevel(payload)
  const minLevel = getMinLevel(payload)
  let newIds = []
  let count = maxLevel - 1

  do {
    if (count === maxLevel - 1) {
      newIds = getRowByLevel(payload, maxLevel)
    } else {
      const rowWithLevel = getRowByLevel(payload, count)

      // eslint-disable-next-line no-loop-func
      rowWithLevel.forEach((item) => {
        const allChildren = payload.filter(
          (children) =>
            children.parentNewsCategoryId === item &&
            children.newsCategoryLevel === count + 1,
        )
        const itemIndex = newIds.findIndex((children) => children === item)
        if (allChildren.length && itemIndex !== -1) {
          const childrenIds = allChildren.map((item) => item.newsCategoryId)
          newIds.splice(itemIndex + 1, 0, childrenIds)
        }
      })
    }
    newIds = newIds.flat()
    count++
  } while (count <= minLevel)

  return newIds
}

// Default level 1 > level 2
const getMaxLevel = (levels) => {
  return Math.min.apply(
    Math,
    levels.map((item) => item.newsCategoryLevel),
  )
}

const getMinLevel = (levels) => {
  return Math.max.apply(
    Math,
    levels.map((item) => item.newsCategoryLevel),
  )
}

const getRowByLevel = (data, level) => {
  return data
    .filter((item) => item.newsCategoryLevel === level)
    .map((item) => item.newsCategoryId)
}
