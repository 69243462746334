import PropTypes from 'prop-types'
import { I18n } from 'react-redux-i18n'
import { Bar } from 'recharts'
import {
  AXIS_LABEL_POSITION,
  MARGIN_RECHARTS,
} from '../../../common/chart/constants'
import { FooterContainer } from '../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../common/chart/helper'
import { ChartContainer } from '../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../common/sizeTracker'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'

export const GroupStackedBarChart = ({
  data,
  width,
  height,
  xAxisKey,
  barKeys,
  margin,
  unit,
  fillColor,
  mappingDisplayName,
  leftLabel,
  allowEscapeViewBoxY,
  isYAxisPercentLeft,
  isYAxisPercentRight,
}) => {
  // Footer
  const footerData = () => {
    return [
      ...barKeys.flat().map((key) => ({
        text: mappingDisplayName[key] ?? key,
        type: FOOTER_TYPE.SQUARE,
        color: fillColor[key],
      })),
    ]
  }

  // Tooltip
  const getContent = () => {
    return [
      {
        keys: barKeys.flat(),
        yAxisId: 'barChart',
        unit: unit,
        decimalLength: 2,
      },
    ]
  }

  // Render
  const renderBarCharts = (chartContentWidth) => {
    const barCharts = []

    barKeys.forEach((keys, index) =>
      keys.forEach((key) =>
        barCharts.push(
          <Bar
            key={key}
            yAxisId="barChart"
            dataKey={key}
            stackId={index}
            barSize={getColumnSizeInBarChart(
              chartContentWidth,
              data.length,
              null,
              barKeys.length,
            )}
            fill={fillColor[key]}
            isAnimationActive={false}
          />,
        ),
      ),
    )

    return barCharts
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              yAxis={[
                {
                  id: 'barChart',
                  keys: barKeys,
                  orientation: 'left',
                  yAxisWidth: margin?.left + 8 || undefined,
                  isStackedBar: true,
                  isGroupBar: true,
                  label: leftLabel ? I18n.t(leftLabel) : undefined,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                  unitYAxis: isYAxisPercentLeft ? '%' : '',
                },
                {
                  id: 'dummyChart',
                  keys: [],
                  orientation: 'right',
                  yAxisWidth: margin?.right + 8 || undefined,
                  unitYAxis: isYAxisPercentRight ? '%' : '',
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              allowEscapeViewBoxY={allowEscapeViewBoxY}
              tickFormatter={(val) => val}
              tooltipSchema={{
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {({ chartContentWidth }) => renderBarCharts(chartContentWidth)}
            </ChartContainer>
          )}
          <FooterContainer key={width} data={footerData()} />
        </>
      )}
    </SizeTracker>
  )
}

GroupStackedBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  xAxisKey: PropTypes.string.isRequired,
  barKeys: PropTypes.array.isRequired,
  margin: PropTypes.object,
  unit: PropTypes.string,
  fillColor: PropTypes.object.isRequired,
  mappingDisplayName: PropTypes.object.isRequired,
  leftLabel: PropTypes.string,
  isYAxisPercentLeft: PropTypes.bool,
  isYAxisPercentRight: PropTypes.bool,
}

GroupStackedBarChart.defaultProps = {
  data: [],
  margin: MARGIN_RECHARTS,
  allowEscapeViewBoxY: false,
}
