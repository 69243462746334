import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { ChannelConfig } from '../../../../../../configs/Global'
import { BondRealtime } from '../../../../../common/BondRealtime'
import { Span } from '../../../../../common/html/Span'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Table } from '../../../../../common/table'
import { TextBlink } from '../../../../../common/table/TextBlink'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valDivMillion } from '../../../../../utils/Value'
import { selectBasicInfo } from '../Filter/store/slice'
import TableHeader from './TableHeader'
import { groupColumn, groupColumnHistory, valueTab } from './constants'
import {
  selecTradingType,
  selectData,
  selectDataValue,
  selectIds,
  selectLoading,
  selectPageSize,
  selectScrollId,
  selectTabActive,
  subscribeBondId,
} from './store/slice'
import { fetchMoreDataThunk } from './store/thunk'

const checkColor = (value, valueCheck) => {
  if (value < valueCheck) {
    return '#ff4752'
  }
  if (value > valueCheck) {
    return '#59c761'
  }
  return '#ffc31e'
}

const checkColorYtm = (value) => {
  if (value < 0) {
    return '#ff4752'
  }
  if (value > 0) {
    return '#59c761'
  }
  return '#ffc31e'
}

const Content = () => {
  const dispatch = useDispatch()
  const ids = useSelector(selectIds)
  const locale = useSelector((state) => state.i18n.locale)
  const activeTab = useSelector(selectTabActive)
  const bondInfo = useSelector(selectBasicInfo)
  const data = useSelector(selectData)
  const scrollId = useSelector(selectScrollId)
  const tradingType = useSelector(selecTradingType)
  const pageSize = useSelector(selectPageSize)

  const loading = useSelector(selectLoading)
  const renderThead = (tableHeaderColAttr, stateSort, sortColumn) => {
    return (
      <TableHeader
        tableHeaderColAttr={tableHeaderColAttr}
        stateSort={stateSort}
        sortColumn={sortColumn}
      />
    )
  }

  const getItem = (rowId) => {
    return data?.[rowId]
  }

  const fetchMoreData = (params) => {
    dispatch(fetchMoreDataThunk(params))
  }

  const scrollToBottomCallback = () => {
    if (scrollId) {
      fetchMoreData({
        BondId: bondInfo?.bondId,
        TradingType: tradingType || undefined,
        PageSize: pageSize,
        ScrollId: scrollId,
        IsRealtime: activeTab === valueTab.Realtime ? true : false,
      })
    }
  }
  if (loading) {
    return <Loading />
  }

  if (ids.length === 0) {
    return <NoData />
  }

  const getColumn = () => {
    if (activeTab === valueTab.Realtime) {
      return groupColumn
    }
    return groupColumnHistory
  }
  return (
    <>
      <Table
        ids={ids}
        columnDraggable={false}
        getDataFromRedux={selectDataValue}
        isLoading={loading}
        rowDraggable={false}
        renderHeader={(stateSort, sortColumn) =>
          renderThead(getColumn(), stateSort, sortColumn)
        }
        schema={getColumn().map((item, index) => {
          const title = I18n.t(item.title)
          const colId = item.key
          const result = {
            colId,
            title,
            canCustomTd: true,
            render: (value, rowId, props) => {
              const currentRow = getItem(rowId)
              if (colId === 'tradingDate') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span>
                      {formatDateTime(
                        value,
                        activeTab === valueTab.Realtime
                          ? FORMAT.TIME
                          : FORMAT.DATE,
                        locale,
                      )}
                    </Span>
                  </td>
                )
              }
              if (colId === 'dirtyPrice') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColor(
                          value,
                          currentRow?.referencePrice || 0,
                        ),
                      }}
                    >
                      {formatVal(value, 0)}
                    </Span>
                  </td>
                )
              }
              if (colId === 'cleanPrice') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColor(
                          currentRow?.dirtyPrice,
                          currentRow?.referencePrice || 0,
                        ),
                      }}
                    >
                      {formatVal(value, 0)}
                    </Span>
                  </td>
                )
              }
              if (colId === 'priceChange') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColorYtm(value),
                      }}
                    >
                      {formatVal(value, 0)}
                    </Span>
                  </td>
                )
              }
              if (colId === 'percentPriceChange') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColorYtm(value),
                      }}
                    >
                      {formatVal(value * 100)}%
                    </Span>
                  </td>
                )
              }
              if (colId === 'ytm') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColorYtm(value),
                      }}
                    >
                      {formatVal(value * 100)}%
                    </Span>
                  </td>
                )
              }
              if (colId === 'ytmChange') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span
                      style={{
                        color: checkColorYtm(value),
                      }}
                    >
                      {formatVal(value * 100)}%
                    </Span>
                  </td>
                )
              }
              if (colId === 'volume') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <TextBlink value={value}>
                      <Span>{formatVal(value, 0)}</Span>
                    </TextBlink>
                  </td>
                )
              }
              if (colId === 'totalVolume') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span>{formatVal(value, 0)}</Span>
                  </td>
                )
              }
              if (colId === 'value') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <TextBlink value={value}>
                      <Span>{formatVal(valDivMillion(value))}</Span>
                    </TextBlink>
                  </td>
                )
              }
              if (colId === 'totalValue') {
                return (
                  <td style={{ ...item?.attrs?.style }}>
                    <Span>{formatVal(valDivMillion(value))}</Span>
                  </td>
                )
              }
            },
          }

          return result
        })}
        stickyFirstColumn={false}
        isPagination={false}
        hasFooter={false}
        resizable={false}
        scrollToBottomCallback={scrollToBottomCallback}
      />
      {activeTab === valueTab.Realtime && (
        <BondRealtime
          channel={ChannelConfig.BondChannel + bondInfo?.ticker}
          action={subscribeBondId}
          // key={'6197'}
        >
          <></>
        </BondRealtime>
      )}
    </>
  )
}

export default Content
