import { SizeTracker } from '../../../../common/sizeTracker'
import FilterGroup from './FilterGroup'
import TableComponent from './TableComponent'

const GDPProduct = ({ height }) => {
  return (
    <SizeTracker>
      {(sizes) => {
        return (
          <>
            <FilterGroup />
            {(sizes.height || sizes.height === 0) && (
              <TableComponent height={height - sizes.height} />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default GDPProduct
