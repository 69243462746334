import {
  getThreeColorByValue,
  getTwoColorByValue,
} from '../../../../../utils/Color'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import { formatVal, valDivThousand } from '../../../../../utils/Value'
import { LinkCWDetail } from '../../common/LinkCWDetail'
import {
  GROUP_COLUMNS_DETAIL,
  GROUP_COLUMNS_OVERVIEW,
  TABLE_TYPE,
} from './constants'

export const getGroupColumnsByTableType = (
  filterData,
  locale,
  timeZone,
  dataTable,
) => {
  let result = {}
  switch (filterData?.tableType) {
    case TABLE_TYPE.OVERVIEW:
      result.schema = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (item.key.includes('Volume')) {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (item.key === 'netBuySellVolume') {
          result = {
            ...result,
            isI18n: false,
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getThreeColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (item.key.includes('Order') && !item.key.includes('Volume')) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(val, 0)}
              </span>
            ),
          }
        }
        return result
      })
      break
    case TABLE_TYPE.DETAIL:
      let schemaDetail = GROUP_COLUMNS_DETAIL.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (index === 1) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val, rowId) => {
              const organizationId = dataTable[rowId]?.coveredWarrantId || 0
              const activeId = dataTable[rowId]?.activeStatusId || 0
              return activeId !== 1 ? (
                val
              ) : (
                <LinkCWDetail organizationId={organizationId} val={val} />
              )
            },
          }
        }
        if (index === 6 || index === 9) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => (
              <span
                style={{
                  color: getTwoColorByValue(val),
                }}
              >
                {formatVal(valDivThousand(val))}
              </span>
            ),
          }
        }
        if (![0, 1, 6, 9].includes(index)) {
          if (item.key.includes('Volume') && !item.key.includes('Order')) {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getTwoColorByValue(val),
                  }}
                >
                  {formatVal(valDivThousand(val))}
                </span>
              ),
            }
          }
          if (item.key === 'netBuySellVolume') {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getThreeColorByValue(val),
                  }}
                >
                  {formatVal(valDivThousand(val))}
                </span>
              ),
            }
          }
          if (item.key.includes('Order')) {
            result = {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getTwoColorByValue(val),
                  }}
                >
                  {formatVal(val, 0)}
                </span>
              ),
            }
          }
        }
        return result
      })
      result.schema = schemaDetail
      break
    case TABLE_TYPE.TICKER:
      let schemaTicker = GROUP_COLUMNS_OVERVIEW.map((item, index) => {
        const title = item.title
        const colId = item.key
        let result = {
          colId,
          title,
          isI18n: false,
          thStyle: {
            textAlign: 'right',
            textTransform: 'uppercase',
          },
          tdStyle: {
            textAlign: 'right',
          },
        }
        if (index === 0) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'left',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'left',
            },
            render: (val) => formatDateTime(val, FORMAT.DATE, locale, timeZone),
          }
        }
        if (index === 5 || index === 8) {
          result = {
            ...result,
            isI18n: false,
            thStyle: {
              textAlign: 'right',
              textTransform: 'uppercase',
            },
            tdStyle: {
              textAlign: 'right',
            },
            render: (val) => {
              return formatVal(valDivThousand(val))
            },
          }
        }
        if (![0, 5, 8].includes(index)) {
          if (item.key.includes('Volume') && !item.key.includes('Order')) {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getTwoColorByValue(val),
                  }}
                >
                  {formatVal(valDivThousand(val))}
                </span>
              ),
            }
          }
          if (item.key === 'netBuySellVolume') {
            result = {
              ...result,
              isI18n: false,
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getThreeColorByValue(val),
                  }}
                >
                  {formatVal(valDivThousand(val))}
                </span>
              ),
            }
          }
          if (item.key.includes('Order')) {
            result = {
              ...result,
              isI18n: false,
              thStyle: {
                textAlign: 'right',
                textTransform: 'uppercase',
              },
              tdStyle: {
                textAlign: 'right',
              },
              render: (val) => (
                <span
                  style={{
                    color: getTwoColorByValue(val),
                  }}
                >
                  {formatVal(val, 0)}
                </span>
              ),
            }
          }
        }
        return result
      })
      result.schema = schemaTicker
      break
    default:
  }

  return result
}
