import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../common/html/Button'
import { dashboardTypes, screenTypes } from '../constants'
import { changeDashboard } from '../store/slice'
import style from './EditMenu.module.css'

const SaveBtn = () => {
  const dispatch = useDispatch()

  const onClick = () => {
    dispatch(
      changeDashboard({
        screenType: screenTypes.DASHBOARD,
        dashboardType: dashboardTypes.MY_DASHBOARD,
      }),
    )
  }

  return (
    <>
      <Button className={style.btnYellow} onClick={onClick}>
        <Translate value="financialChart.SAVE" />
      </Button>
    </>
  )
}

SaveBtn.propTypes = {}

export default SaveBtn
