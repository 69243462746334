import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../common/html/Span'
import { Z_INDEX } from '../constants/Common'
import style from './index.module.css'
import SettingProfile from './settingProfile'
import { changePopup, selectIsShow } from './slice'

export const Setting = () => {
  const dispatch = useDispatch()

  const isShow = useSelector(selectIsShow)

  const closePopup = () => {
    dispatch(changePopup({ isShow: false }))
  }

  return (
    isShow && (
      <div className="overlay" style={{ zIndex: Z_INDEX.MODAL }}>
        <div className="modal-wrapper">
          <div className="modal modal-large setting-page">
            <div className="modal-title">
              <h3 className={style.headerPopupSetting}>
                <i
                  className={`${style.iconSettingHeader} icon-setting-stroke`}
                />
                <Span style={{ fontSize: 14 }}>
                  <Translate value={'setting.common.label.SETTING'} />
                </Span>
              </h3>
              <a className="close-modal" onClick={closePopup}>
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <div className="modal-tab">
                <div className="tab">
                  <button className={`tablinks active`}>
                    <Span>
                      <Translate value={'setting.common.label.PROFILE'} />
                    </Span>
                  </button>
                </div>
                <div className={`${style.tabContent} tab-content`}>
                  <SettingProfile />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  )
}
