import { isArray } from 'lodash'
import { EMPTY_VALUE } from '../constants/Common'

const formatValWithNegativeZero = (val) => {
  return val === '-0.00' ? '0.00' : val
}

export const formatVal = (
  val,
  count = 2,
  displayNullWhenValueEqualZero = false,
  displayEmptyValue = EMPTY_VALUE,
  minimumFractionDigits,
) => {
  val = typeof val === 'string' ? parseFloat(val) : val
  const minimumCount =
    minimumFractionDigits !== undefined ? minimumFractionDigits : count

  if (val) {
    return formatValWithNegativeZero(
      val.toLocaleString('en', {
        minimumFractionDigits: minimumCount,
        maximumFractionDigits: count,
      }),
    )
  }

  if (val === 0 && !displayNullWhenValueEqualZero) {
    return formatValWithNegativeZero(parseFloat(val).toFixed(minimumCount))
  }

  return displayEmptyValue
}

// price, volume, value
export const valDivThousand = (val) => {
  const realVal = val / 1000
  return !isNaN(realVal) ? realVal : EMPTY_VALUE
}

export const valDivMillion = (val) => {
  const realVal = val / 1000000
  return !isNaN(realVal) ? realVal : EMPTY_VALUE
}

export const valDivBillion = (val) => {
  const realVal = val / 1000000000
  return !isNaN(realVal) ? realVal : EMPTY_VALUE
}

export const valDivTrillion = (val) => {
  const realVal = val / 1000000000000
  return !isNaN(realVal) ? realVal : EMPTY_VALUE
}

export const valToPercent = (
  val,
  isResWithout100 = false,
  isResNumber = false,
  decimalLength = 2,
  suffix = '%',
) => {
  if (val === null) {
    return EMPTY_VALUE
  }

  const realVal = isResWithout100 ? val : val * 100
  return !isNaN(realVal) && isResNumber
    ? realVal
    : !isNaN(realVal)
    ? formatValWithNegativeZero(realVal.toFixed(decimalLength)) + suffix
    : EMPTY_VALUE
}

export const formatChange = (val) => {
  return parseFloat(val) > 0 ? '+' + val : val
}

export const isInValidValue = (val) => {
  return val === undefined || val === null
}

export const valByKeyWithDot = (data, keyWithDot) => {
  if (isInValidValue(data)) {
    return EMPTY_VALUE
  }

  return keyWithDot.split('.').reduce((result, key) => {
    return isInValidValue(result[key]) ? EMPTY_VALUE : result[key]
  }, data)
}

export const changeValueWithDot = (value, key, newValue) => {
  const deepCloneValue = JSON.parse(JSON.stringify(value))
  const arrKey = key.split('.')

  return arrKey.reduce((result, key, index) => {
    if (index === arrKey.length - 1) {
      result[key] = newValue
      return deepCloneValue
    }

    if (!result[key]) {
      result[key] = {}
    }
    return result[key]
  }, deepCloneValue)
}

export const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ]
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value
    })
  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : '0'
}

export const getValueByKeyInArray = (
  data,
  valueCompare,
  keyCompare,
  keyResult,
) => {
  const findItem = data.find((item) => item[keyCompare] === valueCompare)
  if (findItem) {
    if (isArray(keyResult)) {
      return keyResult.map((key) => findItem[key]).filter((val) => !!val)[0]
    } else {
      return findItem[keyResult]
    }
  }
}

export const getStandardDeviation = (numbers = []) => {
  if (!numbers.length) return 0

  const mean =
    numbers.reduce((acc, curr) => {
      return acc + curr
    }, 0) / numbers.length

  // Assigning (value - mean) ^ 2 to every array item
  numbers = numbers.map((k) => {
    return (k - mean) ** 2
  })

  // Calculating the sum of updated array
  const sum = numbers.reduce((acc, curr) => acc + curr, 0)

  // Calculating the variance
  const variance = sum / numbers.length

  // Returning the standard deviation
  return Math.sqrt(variance)
}

export const getMedian = (numbers = []) => {
  if (!numbers.length) return 0

  const middle = (numbers.length + 1) / 2

  // Avoid mutating when sorting
  const sorted = [...numbers].sort((a, b) => a - b)
  const isEven = sorted.length % 2 === 0

  return isEven
    ? (sorted[middle - 1.5] + sorted[middle - 0.5]) / 2
    : sorted[middle - 1]
}

export const getMean = (numbers = []) => {
  if (!numbers.length) return 0

  return numbers.reduce((sum, number) => sum + number, 0) / numbers.length
}

export const getLowest = (numbers = []) => {
  if (!numbers.length) return 0

  const sortNumbers = numbers.sort((a, b) => a - b)
  return sortNumbers[0]
}

export const getHighest = (numbers = []) => {
  if (!numbers.length) return 0

  const sortNumbers = numbers.sort((a, b) => a - b)
  return sortNumbers[sortNumbers.length - 1]
}

export const getMaxMinByObjKey = (data) => {
  if (!data?.length) {
    return {}
  }

  const maxMinByObjKey = {}
  data.forEach((item) => {
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === 'number') {
        if (!maxMinByObjKey[key]) {
          maxMinByObjKey[key] = { min: item[key], max: item[key] }
        } else {
          if (maxMinByObjKey[key].min > item[key]) {
            maxMinByObjKey[key].min = item[key]
          }
          if (maxMinByObjKey[key].max < item[key]) {
            maxMinByObjKey[key].max = item[key]
          }
        }
      }
    })
  })

  return maxMinByObjKey
}
