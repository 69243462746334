import PropTypes from 'prop-types'
import { Z_INDEX } from '../../constants/Common'
import { Button } from '../html/Button'
import { Span } from '../html/Span'
import Popup from './index'
import style from './index.module.css'

const PopupEvent = ({
  title,
  message,
  isShow,
  btnCancel,
  btnSubmit,
  handleClose,
  zIndexOverlay,
  zIndex,
  contentCustomStyle,
}) => {
  return (
    <>
      {isShow && (
        <Popup zIndex={zIndex} zIndexOverlay={zIndexOverlay}>
          <div className="modal modal-small">
            <div className="modal-title">
              <h3>
                <Span>{title ? title : ''}</Span>
              </h3>
              <a onClick={handleClose} className="close-modal">
                <i className="icon-delete-stroke" />
              </a>
            </div>
            <div className="modal-content">
              <div className={`content ${contentCustomStyle ?? ''}`}>
                {message}
              </div>
              <div className="group-btn justify-content-end">
                <Button
                  onClick={btnCancel.event}
                  className={`btn normal w-80 h-20 ${style.fontWeightNormal}`}
                  style={{ fontSize: 11 }}
                >
                  {btnCancel.btnName}
                </Button>
                <Button
                  onClick={btnSubmit.event}
                  className={`btn btn-blue w-80 h-20 ${style.fontWeightNormal}`}
                  style={{ fontSize: 11 }}
                >
                  {btnSubmit.btnName}
                </Button>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  )
}

PopupEvent.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  isShow: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  zIndex: PropTypes.number,
  zIndexOverlay: PropTypes.number,
  btnCancel: PropTypes.shape({
    btnName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    event: PropTypes.func.isRequired,
  }),
  btnSubmit: PropTypes.shape({
    btnName: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
      .isRequired,
    event: PropTypes.func.isRequired,
  }),
  contentCustomStyle: PropTypes.string,
}

PopupEvent.defaultProps = {
  zIndex: Z_INDEX.MODAL,
}

export default PopupEvent
