import style from './style.module.css'

const CellType = ({ val }) => {
  const mapTypeText = {
    BU_VOLUME: 'BU',
    SD_VOLUME: 'SD',
  }

  const getTypeText = (val) => {
    if (val === 2) {
      return mapTypeText.SD_VOLUME
    } else if (val === 1) {
      return mapTypeText.BU_VOLUME
    } else {
      return ''
    }
  }

  const value = getTypeText(val)
  return (
    <div
      className={
        value === mapTypeText.BU_VOLUME ? style.colorBuy : style.colorSell
      }
    >
      {value}
    </div>
  )
}

export default CellType
