import { getDateWithTimezone } from '../../../../components/utils/Datetime'
import DataExplorerProxy from '../../../proxies/tool/dataExplorer/DataExplorerProxy'
import { ServiceBase } from '../../ServiceBase'

class DataExplorerService extends ServiceBase {
  getMostRecentDate(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.getMostRecentDate',
      params,
      new Date(getDateWithTimezone(new Date(), 7).setHours(23, 59, 59)),
    )(() => DataExplorerProxy.getMostRecentDate(params))
  }

  getMostRecent(params) {
    return this.getData(() => DataExplorerProxy.getMostRecent(params))
  }

  getTemplates(params) {
    return this.getData(() => DataExplorerProxy.getTemplates(params))
  }

  saveTemplate(params) {
    return this.getData(() => DataExplorerProxy.saveTemplate(params))
  }

  updateTemplate(params) {
    return this.getData(() => DataExplorerProxy.updateTemplate(params))
  }

  renameTemplate(params) {
    return this.getData(() => DataExplorerProxy.renameTemplate(params))
  }

  getTemplate(params) {
    return this.getData(() => DataExplorerProxy.getTemplate(params))
  }

  getShareTemplate(params) {
    return this.getData(() => DataExplorerProxy.getShareTemplate(params))
  }

  deleteTemplate(params) {
    return this.getData(() => DataExplorerProxy.deleteTemplate(params))
  }

  getFiinXTemplates(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.getFiinXTemplates',
      params,
    )(() => DataExplorerProxy.getFiinXTemplates(params))
  }

  getIndicatorFiinXTemplate(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.getIndicatorFiinXTemplate',
      params,
    )(() => DataExplorerProxy.getIndicatorFiinXTemplate(params))
  }

  getTickerFiinXTemplate(params) {
    return this.getData(() => DataExplorerProxy.getTickerFiinXTemplate(params))
  }

  getFiinXTemplateShareIssueMethod(params) {
    return this.applyMemoryCache(
      'tool.dataExplorer.getFiinXTemplateShareIssueMethod',
      params,
    )(() => DataExplorerProxy.getFiinXTemplateShareIssueMethod(params))
  }

  saveWorkSpace(params) {
    return this.getData(() => DataExplorerProxy.saveWorkSpace(params))
  }

  getWorkSpace(params) {
    return this.getData(() => DataExplorerProxy.getWorkSpace(params))
  }
}

export default new DataExplorerService()
