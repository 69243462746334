import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { MarginLoansChart } from '.'
import { Panel } from '../../../common/panel'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { DEFAULT_ALL_VALUE } from './constants'
import {
  selectCurrentOrganizationId,
  selectListOrganizations,
} from './store/slice'

export const PanelMarginLoansChart = ({
  panelRefs,
  sizes,
  setSizes,
  panelKey,
}) => {
  const listOrganizations = useSelector(selectListOrganizations)
  const filteredOrganizationId = useSelector(selectCurrentOrganizationId)

  const currentOrganizationId =
    filteredOrganizationId ||
    (listOrganizations.length ? listOrganizations[0]?.value : '')

  const getTitleChart = () => {
    const currentOrganization =
      listOrganizations?.find((item) => item.value === currentOrganizationId) ||
      {}

    return `${I18n.t('market.marginLoans.MARGIN_LOANS_OF')} ${
      currentOrganizationId === DEFAULT_ALL_VALUE
        ? I18n.t('market.marginLoans.ALL')
        : currentOrganization?.name
    }`
  }

  const titlePanel = 'market.marginLoans.MARGIN_LOANS'
  const nameScreen = I18n.t('market.marginLoans.MARGIN_LOANS')
  const chartName = getTitleChart()

  return (
    <Panel
      title={titlePanel}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadJpg
      downloadJpgParams={{
        domId: 'marginLoans',
        nameScreen,
        chartName,
      }}
    >
      <MarginLoansChart
        width={formatNumber(sizes[panelKey].width) - PANEL_PADDING}
        height={formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT}
        titleChart={getTitleChart()}
      />
    </Panel>
  )
}
