import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../common/loading'
import NewChart from './chart/createChart'
import SettingCreateChart from './chart/settingChart'
import { FCLoading } from './common/loading'
import { useGetUserSettings } from './common/useGetUserSettings'
import { screenTypes } from './constants'
import { Dashboard } from './dashboard'
import EditDashboard from './editDashboard'
import {
  selectDashboardLoading,
  selectIsLoadingDownloadExcel,
  selectScreenType,
} from './store/slice'
import { getDashboard, getIndicators } from './store/thunk'

const mapComponent = {
  [screenTypes.DASHBOARD]: <Dashboard />,
  [screenTypes.NEW_CHART]: <NewChart />,
  [screenTypes.EDIT_CHART]: <EditDashboard />,
  [screenTypes.SETTING_CREATE_CHART]: <SettingCreateChart />,
}

export const FinancialChart = () => {
  const dispatch = useDispatch()

  const { getUserSettings } = useGetUserSettings()

  const dashboardLoading = useSelector(selectDashboardLoading)
  const isLoadingDownloadExcel = useSelector(selectIsLoadingDownloadExcel)
  const screenType = useSelector(selectScreenType)
  const locale = useSelector((state) => state.i18n.locale)

  const [isFirstTime, setIsFirstTime] = useState(true)

  useEffect(() => {
    dispatch(getDashboard())
    dispatch(getIndicators())
  }, [locale])

  useEffect(() => {
    getUserSettings()
  }, [])

  useEffect(() => {
    if (!dashboardLoading && isFirstTime) {
      setIsFirstTime(false)
    }
  }, [dashboardLoading])

  if (
    dashboardLoading &&
    (screenType !== screenTypes.DASHBOARD || isFirstTime)
  ) {
    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          backgroundColor: 'white',
          margin: '18px -8px 0',
        }}
      >
        <Loading isBlack />
      </div>
    )
  }

  if (!screenType) {
    return (
      <div
        style={{
          position: 'relative',
          height: '100%',
          backgroundColor: 'white',
          margin: '18px -8px 0',
        }}
      />
    )
  }

  return (
    <div className="position-relative w-100 h-100">
      <FCLoading
        title="financialChart.DOWNLOADING_EXCEL"
        isLoading={isLoadingDownloadExcel}
      />
      {mapComponent[screenType]}
    </div>
  )
}
