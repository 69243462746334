const financialChart = {
  setting: {
    SET_AS_DEFAULT_SETTING: 'Set as Default Setting',
    GRID_DISPLAY: 'Grid Display',
    VIEW_BY_LATEST_PERIOD: 'View by latest period',
    Y_AXIS_LABEL: 'Y-Axis Label:',
    Y_AXIS_POSITION: 'Y-Axis Position:',
  },
  chartSettings: {
    MEAN: 'Mean',
    PLUS_ONE_SD: '+1 Standard Deviation',
    MINUS_ONE_SD: '-1 Standard Deviation',
    PLUS_TWO_SD: '+2 Standard Deviation',
    MINUS_TWO_SD: '-2 Standard Deviation',
    MEDIAN: 'Median',
    HIGHEST: 'Highest',
    LOWEST: 'Lowest',
    GROWTH_PERCENT: 'Growth (%):',
    GROWTH_NOT_COUNT: 'No',
    GROWTH_ORIGINAL_PERIOD: 'Original',
    GROWTH_PREVIOUS_PERIOD: 'Previous',
    MULTIPLIER: 'Multiplier',
    DECIMAL_PLACES: 'Decimal places:',
    DATA_FREQUENCY: 'Data Frequency:',
    CHART_COLOR: 'Chart Color:',
    FAVORITE_COLOR: 'Favorite Color:',
    STATISTICAL_METHODS: 'Statistical Methods',
    DATA_LABEL: 'Data Label',
  },
  table: {
    UNIT: 'Unit: ',
  },
  RAW: 'Raw',
  THOUSAND: 'Thousand',
  MILLION: 'Mil.',
  BILLION: 'Bil.',
  TRILLION: 'Tril.',
  VALUE: 'Value',
  PERCENT: 'Relative',
  LINE_STYLE: 'Line style',
  LINE_WIDTH: 'Line width',
  DOTS_ON_LINE: 'Dots on line',
  SMOOTH_LINE: 'Smooth line',
  SOLID: 'Solid',
  DASHED: 'Dashed',
  DOTTED: 'Dotted',
  THIN: 'Thin',
  NORMAL: 'Normal',
  THICK: 'Thick',
  YES: 'Yes',
  NO: 'No',
  SUGGEST_COLOR: 'Suggest color',
  FAVORITE_COLOR: 'Favorite color',
  NEW_DASHBOARD_NAME: 'New dashboard',
  NEW_GROUP_DASHBOARD_NAME: 'New group dashboard',
  FINANCIAL_CHART: 'Financial Chart',
  DOWNLOADING: 'Downloading chart, please wait...',
  DOWNLOADING_EXCEL: 'Downloading excel, please wait...',
  ADD_EQUATION: 'Add Equation',
  SUM_AVG_COUNT: 'SUM/AVG/COUNT',
  ADD_SECURITIES: 'Add Securities',
  MULTIPLE_CHOICE: 'Multiple Choice',
  LIST_SECURITIES: 'List Securities',
  ADD_INDICATORS: 'Add Indicators',
  LIST_INDICATORS: 'List Indicators',
  APPLY: 'Apply',
  CANCEL: 'Cancel',
  SUM: 'SUM',
  AVG: 'AVG',
  COUNT: 'COUNT',
  SECURITIES_1: 'Securities:',
  INDICATORS_1: 'Indicator:',
  SEARCH: 'Search',
  INDICATOR_NAME: 'Indicator name:',
  ALL: 'All',
  COMPANIES: 'Companies',
  INDICES: 'Indices',
  SECTOR: 'Sectors',
  ECONOMY: 'Economy',
  BOND: 'Bond',
  WATCH_LIST: 'Watchlist',
  SEARCH_SECURITIES: 'Search securities',
  ALERT_DELETE_CHART: 'Do you want to Delete Chart?',
  BTN_DELETE: 'Delete',
  BTN_CANCEL: 'Cancel',
  BTN_RESET: 'Reset',
  SHARE_CHART: 'Share chart',
  COPY_URL: 'Copy',
  COPIED_URL: 'Copied',
  DELETE_CHART: 'Delete Chart',
  SECURITIES: 'Securities',
  MESS_CHART_SPACE: 'Start building your Chart!',
  MESS_CHART_SPACE_1:
    "Before we can create any chart, we'll first need to get some data in here!",
  HISTORICAL_PRICE: 'Historical Price',
  MORE_INDICATORS: 'More Indicators',
  NEW_CHART: 'New Chart',
  NEW_DASHBOARD: 'New Dashboard',
  X_AXIS: 'X-Axis:',
  BY_TIME: 'by Time',
  BY_SECURITIES: 'by Securities',
  FROM: 'From',
  TO: 'To',
  CLEAR: 'Clear',
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  YEARLY: 'Yearly',
  SAVE: 'Save',
  SELECTED: 'Selected: ',
  WANNING_ADD_SECURITIES: 'The number of selected items is no more than 30',
  SELECTED_ITEMS: 'Selected items',
  SEARCH_INDICATORS: 'Search indicator',
  NEW_DASHBOARD_GROUP: 'New Dashboard Group',
  CHART: 'Chart',
  ALL_EXCHANGE: 'All Exchanges',
  EQUATION: 'Equation',
  EQUATION_FROM_VALUE: 'From value',
  EQUATION_TO_VALUE: 'To value',
  EQUATION_UNIT: 'Unit',
  EQUATION_DATE: 'Period',
  EDIT_CHART: ' Edit chart',
  EQUATION_SELECTED_ITEM_NO_MORE_THAN:
    'The number of selected items is no more than 30.',
  EQUATION_INDICATORS_NOT_SAME_UNIT:
    'These indicators selected are not same at unit. Please try again!',
  EQUATION_SECURITIES_INDICATORS_REQUIRED:
    'Please choose security and indicator before applying equations.',
  SAVE_AS: 'Save as',
  WARNING_CANCEL_EDIT_DASHBOARD:
    'Do you want to save your changes before moving to another function?',
  NUMBER_OF_CHART: 'Number of charts: ',
  TEXT_DISABLE_LAYOUT:
    'The current number of charts exceeds the layout you selected. Please delete some of charts before changing layout.',
  EDIT_LAYOUT: 'Edit layout',
  CHOOSE_LAYOUT: 'Choose Layout:',
  REPOSITION_CHART: 'Reposition Chart:',
  ICB_LEVEL_1: 'ICB Level 1',
  ICB_LEVEL_2: 'ICB Level 2',
  ICB_LEVEL_3: 'ICB Level 3',
  ICB_LEVEL_4: 'ICB Level 4',
  ICB_LEVEL_5: 'ICB Level 5',
  COPY_CHART_TO_NEW_DASHBOARD: 'Copy Chart to new dashboard',
  CHART_TITLE: 'Chart Title',
  NEW_DASHBOARD_1: 'New Dashboard',
  OK: 'OK',
  COPY_CHART_TO_NEW_DASHBOARD_SUCCESS: 'Chart has saved to ',
  COPY_CHART_TO_NEW_DASHBOARD_SUCCESS_1: ' successfully',
  WARNING_DB_IS_OUT_OF_SPACE:
    'Dashboard is out of space. Please delete one inhere or try another Dashboard.',
  DUPLICATE_DASHBOARD: 'Duplicate dashboard',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_CHART:
    'Please save your changes before downloading Chart.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_CHART:
    'Please save your changes before sharing Chart.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DUPLICATING_DASHBOARD:
    'Please save your changes before duplicating Dashboard.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_DOWNLOADING_DASHBOARD:
    'Please save your changes before downloading Dashboard.',
  PLEASE_SAVE_YOUR_CHANGES_BEFORE_SHARING_DASHBOARD:
    'Please save your changes before sharing Dashboard.',
  EDIT: 'Edit',
  LINK_CHART: 'Link chart',
  ADD_CHART: 'Add chart',
  REPLACE_CHART: 'Replace Chart',
  RENAME_CHART: 'Rename chart',
  DUPLICATE_CHART: 'Duplicate Chart',
  COPY_TO: 'Copy to',
  DOWNLOAD_CHART: 'Download Chart',
  SHARE_CHART1: 'Share Chart',
  DELETE_CHART1: 'Delete Chart',
  CONFIRM_DELETE: 'Do you want to delete ',
  UNLINK_CHART: 'Unlink chart',
  LINK_CHART_DESC:
    'Charts containing a single company is able to link for syncing',
  SEARCH_COMPANY: 'Search company',
  LINKED_SECURITIES: 'Linked securities',
  REPLACE: 'Replace',
  ADD: 'Add',
  MY_CHART_LIBRARY: 'My chart library',
  SELECT: 'Select',
  SELECT_ALL: 'Select all',
  SHARE: 'Share',
  QUARTER_1: 'Quarter 1',
  QUARTER_2: 'Quarter 2',
  QUARTER_3: 'Quarter 3',
  QUARTER_4: 'Quarter 4',
  MONTH_1: 'M1',
  MONTH_2: 'M2',
  MONTH_3: 'M3',
  MONTH_4: 'M4',
  MONTH_5: 'M5',
  MONTH_6: 'M6',
  MONTH_7: 'M7',
  MONTH_8: 'M8',
  MONTH_9: 'M9',
  MONTH_10: 'M10',
  MONTH_11: 'M11',
  MONTH_12: 'M12',
  Y_AXIS: 'Y Axis:',
  LEGEND_FOOTER: 'X-axis Legend:',
  Y_AXIS_LABEL: 'Y-Axis:',
  GROUP_BY_YEAR: 'Group by Year',
  GROUP_AXIS: 'Group axis',
  Y_AXIS_POSITION: 'Y-Axis:',
  SET_DEFAULT_COLOR: 'Set default color:',
  TITLE_CHART: 'Title Chart:',
  LEGEND_Y_AXIS: 'Y-axis Legend:',
  RIGHT: 'Right',
  LEFT: 'Left',
  CUSTOM: 'Custom',
  NEXT_TO_Y_AXIS: 'Next to Y-axis',
  ABOVE_Y_AXIS: 'Above Y-axis',
  SAVE_AS_DASHBOARD: 'Save as dashboard',
  CHANGE_POSITION_YAXIS: 'Change position Y-axis',
  NAME_OF_DASHBOARD: 'Name of dashboard:',
  ADD_SECURITIES_AND_INDICATORS:
    'Add Securities and Indicators to start building your Graph!',
  VIEW: ' Views',
  EXCHANGE: 'Exchange',
  ALL_SECTOR: 'All sector',
  SAVE_CHART: 'Save chart',
  DOWNLOAD_DASHBOARD: 'Download dashboard',
  SHARE_DASHBOARD: 'Share dashboard',
  DELETE_DASHBOARD: 'Delete dashboard',
  RENAME: 'Rename',
  DUPLICATE: 'Duplicate',
  DELETE: 'Delete',
  UNSET_AS_DEFAULT: 'Unset as default',
  SET_AS_DEFAULT: 'Set as default',
  NONE: 'None',
  MOVE_TO: 'Move to',
  DB_IS_OUT_OF_SPACE:
    'Dashboard is out of space. Please delete one inhere or try another Dashboard.',
  CHART_TYPE: 'Chart type:',
  DOT_TYPE: 'Dot type:',
  X_AXIS_SETTING: 'X-Axis:',
  ADD_MORE_SECURITIES: 'Add more securities',
  SHOW_TABLE: 'Show table',
  PIN_TOOLTIP: 'Pin Tooltip',
  GENERAL_SETTING: 'General Setting',
  DOWNLOAD: 'Download',
  DOWNLOAD_CSV: 'Download Excel',
  DOWNLOAD_CSV_NOTE: 'This feature is not supported with custom formulas',
  DONT_SAVE: "Don't Save",
  ROTATE_TEXT: 'Rotate Text',
  UNIT_FIRMS: 'Firms',
  EQUATION_FORMULA: '*Formula:',
  EQUATION_FORMULA_SUPPORT:
    '(*) The formula supports the following calculations: + , - , / , * , ( , ). For example: (A+B)/C-D or A+B*C-D*2',
  EQUATION_FORMULA_NOTE:
    'Note: Each indicator will be SUM (all selected tickers) before applying the above formula.',
  EQUATION_FORMULA_INVALID:
    'Formula contains invalid indicator, please try again.',
  MESS_SAVE_CHART: 'Chart has been saved to ',
  MESS_SAVE_CHART_1: ' successfully!',
  EQUATION_FORMAT_VND: 'VND',
  EQUATION_FORMAT_PERCENT: '%',
  EQUATION_FORMAT_TIMES: 'Times',
  EQUATION_COUNT_UNIT_FIRMS: 'Firms',
  EQUATION_NAME_REQUIRED: 'Please fill the equation name before applying.',
  BACK: 'Back',
  RESET_MESSAGE_CONFIRM: 'Do you want to delete all the configurations?',
  MESS_INFO_QUARTERLY_MONTHLY:
    'Quarterly unit: -4 periods mean same quarter last year.\nMonthly unit: -12 periods mean same month last year.',
  EQUATION_INDICATOR_LIMIT: 'The number of indicators is no more than 26.',

  BOND_TYPE: 'Bond Type',
  ISSUE_METHOD: 'Issue Method',
  ACTIVE_STATUS: 'Active status',
  STOCK: 'Stock',

  BOND_TICKER: 'Bond Ticker',
  TICKER: 'Ticker',
  ISSUER: 'Issuer',
  BOND_SECTOR: 'Sector',
  TRADING_STATUS: 'Trading status',
}

export default financialChart
