import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import style from './textBlink.module.css'

const ANIMATION_TIME = 700

export const TextBlink = ({ children, value, className }) => {
  const valRef = useRef(value)
  const divRef = useRef()

  useEffect(() => {
    const removeBlinkClass = (className) => {
      if (divRef.current && divRef.current.parentNode) {
        divRef.current.parentNode.classList.remove(className)
      }
    }

    const addClassParent = (colorClass) => {
      if (!divRef.current) {
        return
      }

      divRef.current.parentNode.classList.add(colorClass)
      divRef.current.parentNode.classList.add(style.transition)
      if (className) {
        divRef.current.classList.remove(className)
      }

      divRef.current.classList.add(style.textWhite)
    }

    let classColor = null
    if (value > valRef.current) {
      classColor = style.textIncrease
    }

    if (value < valRef.current) {
      classColor = style.textDecrease
    }

    if (classColor) {
      addClassParent(classColor)

      setTimeout(() => {
        removeBlinkClass(classColor)
        removeBlinkClass(style.transition)

        if (divRef.current) {
          divRef.current.classList.remove(style.textWhite)
          if (className) {
            divRef.current.classList.add(className)
          }
        }
      }, ANIMATION_TIME)
    }

    valRef.current = value
  }, [value, className])

  return (
    <div ref={divRef} className={className}>
      {children}
    </div>
  )
}

TextBlink.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}

TextBlink.defaultProps = {
  className: '',
}
