import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n, Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../common/HandleClickOutside'
import { ScrollComponent } from '../../common/ScrollComponent'
import Dropdown from '../../common/dropdown'
import { Input } from '../../common/html/Input'
import { Span } from '../../common/html/Span'
import useSearchCompany from '../../common/simpleSearch/useSearchCompany'
import { Z_INDEX, screens } from '../../constants/Common'
import style from '../index.module.css'
import {
  changeTickerIds,
  changeTickersById,
  selectTickerIds,
  selectTickersById,
} from '../store/slice'

const pageSize = 10
const pageSizeDefault = 100

const keyCode = {
  ENTER: 13,
  DOWN_ARROW: 40,
  UP_ARROW: 38,
}

const FilterTaxCode = () => {
  const inputRef = useRef()
  const exceptOusideRef = useRef()

  const [searchParams, setSearchParams] = useState()
  const [valueSearch, setValueSearch] = useState('')
  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [eventPressKey, setEventPressKey] = useState()
  const [isLoadingSearch, setIsLoadingSearch] = useState(false)

  const { companies, loading } = useSearchCompany(
    screens.MY_WATCHLIST,
    searchParams,
  )

  useEffect(() => {
    if (valueSearch.trim())
      setSearchParams({
        Term: valueSearch.trim(),
        Page: 0,
        PageSize: pageSize,
      })
    else
      setSearchParams({
        Page: 0,
        PageSize: pageSizeDefault,
      })
  }, [valueSearch])

  useEffect(() => {
    if (!loading) {
      setIsLoadingSearch(false)
    }
  }, [loading])

  const handleChangeTaxcodeInput = (e) => {
    setIsLoadingSearch(true)
    setValueSearch(e.target.value)
  }

  const handleHide = () => setIsShowDropdown(false)

  const handleShow = () => setIsShowDropdown(true)

  const handlePressKey = (e) => {
    setEventPressKey(e)
  }

  return (
    <div className="filter-box">
      <p className="name">
        <Span style={{ fontSize: 10 }}>
          <Translate value="watchlist.watchlistDetail.TITLE_SEARCH_TICKER" />
        </Span>
      </p>
      <div className="form-search-inbox m-0">
        <div className="w-100" ref={exceptOusideRef}>
          <Input
            ref={inputRef}
            onChange={handleChangeTaxcodeInput}
            onFocus={handleShow}
            onKeyDown={handlePressKey}
            type="text"
            value={valueSearch}
            className="search-inbox h-20"
            placeholder={I18n.t('watchlist.watchlistDetail.SEARCH_PLH')}
            style={{ fontSize: 10 }}
          />
          <button onClick={() => inputRef.current.focus()} type="button">
            <i className="icon-search-2" />
          </button>
        </div>
        <DropdownContainer
          isShow={isShowDropdown}
          parentRef={inputRef}
          handleHide={handleHide}
          inputRef={inputRef}
          exceptOusideRef={exceptOusideRef}
          companies={companies}
          eventPressKey={eventPressKey}
          setValueSearch={setValueSearch}
          loading={isLoadingSearch}
        />
      </div>
    </div>
  )
}

const DropdownContainer = ({
  isShow,
  parentRef,
  handleHide,
  inputRef,
  companies,
  eventPressKey,
  exceptOusideRef,
  setValueSearch,
  loading,
}) => {
  const dispatch = useDispatch()

  const tickerIds = useSelector(selectTickerIds)
  const tickersById = useSelector(selectTickersById)

  const [companiesHandled, setCompaniesHandled] = useState([])
  const [activeIndex, setActiveIndex] = useState(null)

  useEffect(() => {
    setCompaniesHandled(
      companies.filter((ticker) => !tickersById[ticker.organizationId]),
    )
  }, [companies, tickersById])

  useEffect(() => {
    if (!loading) {
      const lengthCompanies = companiesHandled.length
      if (eventPressKey?.keyCode === keyCode.ENTER) {
        if (activeIndex || activeIndex === 0)
          handleAddTicker(companiesHandled[activeIndex])
        else if (companiesHandled[0]) {
          handleAddTicker(companiesHandled[0])
        }
      }
      if (
        eventPressKey?.keyCode === keyCode.DOWN_ARROW &&
        lengthCompanies > 0
      ) {
        if (!activeIndex && activeIndex !== 0) setActiveIndex(0)
        else if (activeIndex < lengthCompanies - 1)
          setActiveIndex(activeIndex + 1)
      }
      if (eventPressKey?.keyCode === keyCode.UP_ARROW && lengthCompanies > 0) {
        if (!activeIndex && activeIndex !== 0)
          setActiveIndex(lengthCompanies - 1)
        else if (activeIndex > 0) setActiveIndex(activeIndex - 1)
      }
    }
  }, [eventPressKey])

  useEffect(() => {
    setActiveIndex(null)
  }, [companiesHandled])

  const handleAddTicker = (ticker) => {
    const newTickersById = { ...tickersById }
    newTickersById[ticker.organizationId] = ticker
    dispatch(changeTickersById(newTickersById))
    dispatch(changeTickerIds([...tickerIds, ticker.organizationId]))
    inputRef.current.focus()

    if (companiesHandled.length === 1) setValueSearch('')
  }

  return (
    <Dropdown isShow={isShow} parentRef={parentRef} zIndex={Z_INDEX.MODAL + 1}>
      <HandleClickOutside
        handleClickOutside={handleHide}
        exceptRef={exceptOusideRef}
      >
        <div
          onClick={() => inputRef.current.focus()}
          style={{ width: parentRef.current?.offsetWidth + 'px' }}
          className={style.dropdownFilter}
          id="filter-ticker-scroll"
        >
          {companiesHandled.length === 0 ? (
            <div className={style.noData}>
              <Span style={{ fontSize: 10 }}>
                <Translate value="watchlist.watchlistDetail.NO_RESULT" />
              </Span>
            </div>
          ) : (
            <ScrollComponent verticalTrackWidth={5} horizontalTrackWidth={5}>
              {companiesHandled.map((ticker, index) => {
                return (
                  <div
                    key={ticker.organizationId}
                    className={`${style.contentFilter} ${
                      activeIndex === index ? style.contentFilterActive : ''
                    }`}
                    onClick={() => handleAddTicker(ticker)}
                  >
                    <CompanyItemScroll isActive={activeIndex === index} />
                    <div className={`${style.itemFilter} ${style.pointer}`}>
                      <div className={style.textEllipsis}>
                        {ticker.tickerOrTaxCode}
                      </div>
                      <div className={style.textEllipsis}>
                        {ticker.organizationShortName}
                      </div>
                    </div>
                  </div>
                )
              })}
            </ScrollComponent>
          )}
        </div>
      </HandleClickOutside>
    </Dropdown>
  )
}

DropdownContainer.propTypes = {
  isShow: PropTypes.bool.isRequired,
  parentRef: PropTypes.object.isRequired,
  handleHide: PropTypes.func.isRequired,
  inputRef: PropTypes.object.isRequired,
  exceptOusideRef: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  eventPressKey: PropTypes.object,
  setValueSearch: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

const CompanyItemScroll = ({ isActive }) => {
  const itemRef = useRef()

  useEffect(() => {
    if (isActive)
      document
        .querySelector(`#filter-ticker-scroll .scrollbars div:first-child`)
        .scroll({
          top: itemRef.current?.offsetTop - 5,
        })
  }, [isActive])

  return <div ref={itemRef} />
}

CompanyItemScroll.propTypes = {
  isActive: PropTypes.bool.isRequired,
}

export default FilterTaxCode
