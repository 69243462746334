import { createAsyncThunk } from '@reduxjs/toolkit'
import WatchlistService from '../../../../../../../core/services/watchlist/WatchlistService'

export const getTickerByExchange = createAsyncThunk(
  'market/directorDeal/GET_TICKER_BY_EXCHANGE',
  async (params, { rejectWithValue }) => {
    const response = await WatchlistService.getTickerByExchange({
      GroupId: params.GroupId,
    })
    if (response.success)
      return { data: response.data, tickerRedirect: params.tickerRedirect }
    return rejectWithValue(response.errors)
  },
)
