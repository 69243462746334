import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import UseI18n from '../../common/hooks/useI18n'
import { SearchCollapseBlock } from '../SearchCollapseBlock'
import { selectSearchCompany } from '../store/slice'
import { getSearchCompanyData } from '../store/thunk'
import { CorOverviewItem } from './CorOverviewItem'

export const CorOverview = (props) => {
  const { searchStr, open, onClickCollapse, getBasicInfo } = props
  const dispatch = useDispatch()
  const company = useSelector(selectSearchCompany)

  const onClickPrevPage = () => {
    if (company.data.page === 1) {
      return
    } else {
      dispatch(
        getSearchCompanyData({
          Term: searchStr,
          Page: company.data.page - 1,
          PageSize: company.data.pageSize,
        }),
      )
    }
  }
  const onClickNextPage = () => {
    if (company.data.page === company.data.totalPage) {
      return
    } else {
      dispatch(
        getSearchCompanyData({
          Term: searchStr,
          Page: company.data.page + 1,
          PageSize: company.data.pageSize,
        }),
      )
    }
  }

  return (
    <SearchCollapseBlock
      id="cor-over"
      title={`${UseI18n('search.fullSearch.COR_OVERVIEW')} (${
        company.data.totalRecords
      })`}
      blockData={company}
      open={open}
      onClickCollapse={onClickCollapse}
      onClickNextPage={onClickNextPage}
      onClickPrevPage={onClickPrevPage}
    >
      {!!company.data.items.length &&
        company.data.items.map((item) => (
          <CorOverviewItem
            key={item.organizationId}
            searchStr={searchStr}
            data={item}
            getBasicInfo={getBasicInfo}
          />
        ))}
    </SearchCollapseBlock>
  )
}

CorOverview.propTypes = {
  searchStr: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  onClickCollapse: PropTypes.func.isRequired,
}
