import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import SectorStatisticsOverviewService from '../../../../../../../core/services/sector/sectorStatistics/OverviewService'

export const pricePerformanceAbsoluteChart = createAsyncThunk(
  'sector/sectorStatistics/overview/pricePerformance/chartPricePerformance/PRICE_PERFORMANCE_ABSOLUTE_CHART',
  async (params, { rejectWithValue }) => {
    try {
      const response =
        await SectorStatisticsOverviewService.pricePerformanceAbsoluteChart(
          params,
        )
      if (response.success) {
        return response
      }
      return rejectWithValue(response.errors)
    } catch (error) {
      if (axios.isCancel(error)) {
        return rejectWithValue({ loading: true })
      }
      return rejectWithValue({ loading: false })
    }
  },
)
