import PropTypes from 'prop-types'
import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import { PanelAvgTradingValue } from './averageTradingValue/PanelAvgTradingValue'
import { PanelLeverageRatio } from './marginLeverageRatio/PanelLeverageRatio'
import { PanelMarginLoansChart } from './marginLoansChart/PanelMarginLoansChart'
import { PanelMarginGrowth } from './tableMarginGrowth/PanelMarginGrowth'

const MAP_KEY = {
  AVG_TRADING_VALUE: 'AVG TRADING VALUE',
  MARGIN_LEVERAGE_RATIO: 'MARGIN LEVERAGE RATIO',
  MARGIN_GROWTH: 'MARGIN GROWTH',
  MARGIN_LOANS: 'MARGIN LOANS',
}

const MIN_SIZE = {
  [`${MAP_KEY.AVG_TRADING_VALUE}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.MARGIN_LEVERAGE_RATIO}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 - COMPONENT.MARGIN * 2,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.MARGIN_GROWTH}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5.5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
  [`${MAP_KEY.MARGIN_LOANS}`]: {
    minWidth: (COMPONENT.MIN_SIZE.WIDTH * 3 * 4.5) / 10 - COMPONENT.MARGIN,
    minHeight: COMPONENT.MIN_SIZE.HEIGHT - COMPONENT.MARGIN,
  },
}

const MAP_SIZE = {
  [`${MAP_KEY.AVG_TRADING_VALUE}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.AVG_TRADING_VALUE}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.AVG_TRADING_VALUE}`].minHeight,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.MARGIN_LEVERAGE_RATIO,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    horizontalResize: [
      {
        isResizeWithParent: true,
        key: MAP_KEY.MARGIN_LEVERAGE_RATIO,
        ratioHeight: -1,
        ratioTop: 0,
      },
      {
        key: MAP_KEY.MARGIN_LOANS,
        ratioHeight: 1,
        ratioTop: 1,
      },
      {
        key: MAP_KEY.MARGIN_GROWTH,
        ratioHeight: 1,
        ratioTop: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: false,
  },
  [`${MAP_KEY.MARGIN_LEVERAGE_RATIO}`]: {
    width: `calc(50% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(50% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.MARGIN_LEVERAGE_RATIO}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.MARGIN_LEVERAGE_RATIO}`].minHeight,
    minTop: 0,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARGIN_GROWTH}`]: {
    width: `calc(55% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: 0,
    minWidth: MIN_SIZE[`${MAP_KEY.MARGIN_GROWTH}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.MARGIN_GROWTH}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.MARGIN_LOANS,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.MARGIN_LOANS}`]: {
    width: `calc(45% - ${COMPONENT.MARGIN}px)`,
    height: `calc(50% - ${COMPONENT.MARGIN}px)`,
    top: `calc(50% + ${COMPONENT.MARGIN}px)`,
    left: `calc(55% + ${COMPONENT.MARGIN}px)`,
    minWidth: MIN_SIZE[`${MAP_KEY.MARGIN_LOANS}`].minWidth,
    minHeight: MIN_SIZE[`${MAP_KEY.MARGIN_LOANS}`].minHeight,
    minTop: COMPONENT.MIN_SIZE.HEIGHT + COMPONENT.MARGIN,
    minLeft: (COMPONENT.MIN_SIZE.WIDTH * 3 * 5.5) / 10 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  const renderListPanel = () => {
    return (
      <>
        <PanelAvgTradingValue
          panelRefs={panelRefs}
          panelKey={MAP_KEY.AVG_TRADING_VALUE}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelLeverageRatio
          panelKey={MAP_KEY.MARGIN_LEVERAGE_RATIO}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelMarginGrowth
          panelKey={MAP_KEY.MARGIN_GROWTH}
          panelRefs={panelRefs}
          sizes={sizes}
          setSizes={setSizes}
        />

        <PanelMarginLoansChart
          panelRefs={panelRefs}
          panelKey={MAP_KEY.MARGIN_LOANS}
          sizes={sizes}
          setSizes={setSizes}
        />
      </>
    )
  }
  return renderListPanel()
}

Content.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
