import { useState } from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { DEFAULT_ORGANIZATION } from '../../../configs/Common'
import CurrentState from '../../utils/CurrentState'
import EventEmitter, { UPDATE_CURRENT_STATE } from '../../utils/EventEmitter'

const useGetHeaderSearch = () => {
  const location = useLocation()

  const getData = () =>
    CurrentState.getCurrentState().data?.headerSearch ||
    location.state?.headerSearch ||
    DEFAULT_ORGANIZATION

  const [headerSearch, setHeaderSearch] = useState(getData)

  const changeHeaderSearch = () => {
    setHeaderSearch(getData())
  }

  useEffect(() => {
    EventEmitter.subscribe(UPDATE_CURRENT_STATE, changeHeaderSearch)
    return () => {
      EventEmitter.unsubscribe(UPDATE_CURRENT_STATE, changeHeaderSearch)
    }
  }, [])

  return headerSearch
}

export default useGetHeaderSearch
