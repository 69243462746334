import Tree from '../../../../common/tree'
import { getAllChildrenItem } from '../../../../common/tree/helper'
import style from '../style.module.css'

const ListIndices = ({
  valueSearch,
  securitiesState,
  setSecuritiesState,
  exchangesTree,
}) => {
  const setListChecked = (value) => {
    setSecuritiesState({
      ...securitiesState,
      indices: value,
    })
  }

  const handleDoubleClickItem = (item) => {
    const dataAdd = getAllChildrenItem(item).map((el) => el.groupId)

    setSecuritiesState((oldState) => {
      const dataIndices = [...oldState.indices, ...dataAdd].reduce(
        (unique, o) => {
          if (!unique.includes(o)) {
            unique.push(o)
          }
          return unique
        },
        [],
      )
      return {
        ...oldState,
        indices: dataIndices,
      }
    })
  }

  return (
    <>
      <div
        style={{
          listStyle: 'none',
          margin: 0,
          paddingLeft: 8,
          height: '100%',
        }}
      >
        {Object.keys(exchangesTree).length > 0 ? (
          <Tree
            valueSearch={valueSearch}
            treeData={exchangesTree}
            idKey="groupId"
            nameKey="groupName"
            sortKey="groupOrder"
            doubleClickItem={handleDoubleClickItem}
            isHaveCheckbox={true}
            listChecked={securitiesState.indices}
            setListChecked={setListChecked}
            classItemStyle={style.itemStyle}
            classCheckboxStyle={style.checkboxStyle}
            bgColorScroll="#D9D9D9"
            isHaveCheckboxAll
            isCheckAllByValueSearch
            titleCheckboxAll="financialChart.ALL"
            isCheckedWhenClickText
          />
        ) : null}
      </div>
    </>
  )
}

export default ListIndices
