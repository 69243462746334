import { isEmpty } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import ForceGraph2D from 'react-force-graph-2d'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import useCustomNavigate from '../../../../../common/hooks/useCustomNavigate'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import { formatVal, valDivBillion } from '../../../../../utils/Value'
import { selectListNodeHide } from '../../corporateInfo/store/slice'
import { ValuePosition } from '../../filter/FilterOption/constants'
import {
  selectData,
  selectFurtherInformation,
  selectLimitNode,
  selectLoading,
  selectPosition,
  selectRelation,
  selectShareHolders,
  selectfilterLevel,
} from '../../filter/FilterOption/store/slice'
import {
  checkLinkShareHolder,
  checkLinkWith,
  checkNodeColor,
  checkNodeShareHolder,
  checkNodeVal,
  filterDataPositionCeo,
  filterDataPositionCeoAndChairman,
  filterDataPositionCharmain,
  renderLine,
  renderNode,
  transformData,
} from './helper'
import iconNode from './iconNode.svg'

const img = new Image()
const imgNode = new Image()

const Chart = ({ width, height }) => {
  const graphRef = useRef()
  const basicInfo = useSelector(selectBasicInfo)
  const idsCompany = useSelector(selectListNodeHide)
  const limitNode = useSelector(selectLimitNode)
  const level = useSelector(selectfilterLevel)
  const dataChart = useSelector(selectData)
  const loading = useSelector(selectLoading)
  const navigate = useCustomNavigate()
  const shareHolders = useSelector(selectShareHolders)
  const position = useSelector(selectPosition)
  const relation = useSelector(selectRelation)
  const furtherInformations = useSelector(selectFurtherInformation)
  const [popUp, setpopUp] = useState(null)
  const [dataPopup, setdataPopup] = useState({
    nodes: [],
    links: [],
  })
  const [hover, setHover] = useState()
  const [stopAnimation, setstopAnimation] = useState(false)
  const [initZoom, setinitZoom] = useState(false)

  const data = useMemo(() => {
    if (isEmpty(position)) {
      return dataChart
        ? transformData(
            filterDataPositionCeoAndChairman(dataChart),
            basicInfo?.organizationId,
            width,
            height,
            shareHolders,
            relation,
            level,
            idsCompany,
          )
        : { nodes: [], links: [] }
    }
    if (!position.includes(ValuePosition.Chairman)) {
      return dataChart
        ? transformData(
            filterDataPositionCharmain(dataChart),
            basicInfo?.organizationId,
            width,
            height,
            shareHolders,
            relation,
            level,
            idsCompany,
          )
        : { nodes: [], links: [] }
    }
    if (!position.includes(ValuePosition.CEO)) {
      return dataChart
        ? transformData(
            filterDataPositionCeo(dataChart),
            basicInfo?.organizationId,
            width,
            height,
            shareHolders,
            relation,
            level,
            idsCompany,
          )
        : { nodes: [], links: [] }
    }

    return dataChart
      ? transformData(
          dataChart,
          basicInfo?.organizationId,
          width,
          height,
          shareHolders,
          relation,
          level,
          idsCompany,
        )
      : { nodes: [], links: [] }
  }, [
    dataChart,
    position,
    shareHolders,
    relation,
    level,
    basicInfo?.organizationId,
    limitNode,
    idsCompany,
  ])
  const dataFormart = popUp
    ? { nodes: dataPopup.nodes, links: dataPopup.links }
    : data

  useEffect(() => {
    if (graphRef.current) {
      graphRef.current
        .d3Force('link')
        .iterations(10)
        .distance((e) => {
          return checkLinkWith(e, data, limitNode, idsCompany)
        })
      graphRef.current.d3Force('charge').strength(-2000).distanceMax(2000)
    }
  }, [graphRef.current, dataFormart, limitNode, idsCompany, data])

  img.src = dataChart?.avatar || ''
  img.onload = () => {
    if (!img.src) {
      img.alt = 'Hình ảnh không có sẵn'
    }
  }

  imgNode.src = iconNode
  imgNode.width = 15
  imgNode.height = 20

  useEffect(() => {
    setpopUp(null)
    setstopAnimation(false)
    setinitZoom(false)
  }, [basicInfo?.organizationId])

  if (loading) {
    return <Loading />
  }

  if (!dataChart?.id) {
    return <NoData />
  }

  const handleSelectBond = (id) => {
    navigate('/bond/interconnection-map', {
      organizationId: id,
    })
  }

  const handleChangeTab = (id) => {
    navigate('/bond/corporate-bond/issuers', {
      organizationId: id,
    })
  }

  return (
    <ForceGraph2D
      ref={graphRef}
      graphData={dataFormart}
      width={width}
      height={height}
      nodeRelSize={4}
      nodeLabel={(node) => {
        if (node?.outstandingBond) {
          return [
            node?.name,
            formatVal(valDivBillion(node.outstandingBond)) +
              ' ' +
              I18n.t('bond.interconnectionMap.common.unit2'),
          ].join(': ')
        }
      }}
      nodeVal={(node) => checkNodeVal(node)}
      nodeColor={(node) => checkNodeColor(node)}
      onNodeClick={(node) => {
        if (popUp) {
          setstopAnimation(false)
          setpopUp(null)
          selectData(data.nodes)
          if (node?.type === 'popup') {
            if (node?.typeAction === 'view') {
              handleChangeTab(node?.organizationId)
            }
            if (node?.typeAction === 'change') {
              handleSelectBond(node?.organizationId)
            }
          }
        } else {
          if (node && node?.relationType !== 'IndividualOwner') {
            setpopUp(node)
            setstopAnimation(true)
            const dataChart = data.nodes
            const dataChartLink = data.links
            const contentPopup = [
              {
                id: 'view',
                text: I18n.t('bond.interconnectionMap.chart.btnView'),
                typeAction: 'view',
                type: 'popup',
                parentX: node.x,
                parentY: node.y,
                organizationId: node?.id,
              },
            ]
            if (node.isIssuer) {
              contentPopup.push({
                id: 'change',
                text: I18n.t('bond.interconnectionMap.chart.btnView'),
                typeAction: 'change',
                type: 'popup',
                parentX: node.x,
                parentY: node.y,
                organizationId: node?.id,
              })
            }
            setdataPopup({
              nodes: dataChart.concat(contentPopup),
              links: dataChartLink,
            })
          }
        }
      }}
      onNodeHover={(node) => {
        if (node?.type === 'popup') {
          setHover(node?.typeAction)
        } else {
          setHover()
        }
      }}
      nodeCanvasObjectMode={() => 'after'}
      nodeCanvasObject={renderNode(
        img,
        I18n,
        hover,
        furtherInformations,
        imgNode,
      )}
      linkCanvasObject={renderLine(
        I18n,
        furtherInformations,
        dataFormart,
        relation,
        shareHolders,
      )}
      linkCanvasObjectMode={() => 'after'}
      nodeVisibility={checkNodeShareHolder(
        level,
        shareHolders,
        relation,
        dataFormart,
      )}
      linkVisibility={checkLinkShareHolder(
        level,
        shareHolders,
        relation,
        dataFormart,
      )}
      onBackgroundClick={() => {
        setpopUp(null)
        setstopAnimation(false)
      }}
      enableNodeDrag={!stopAnimation}
      enablePanInteraction={!stopAnimation}
      enableZoomInteraction={!stopAnimation}
      onEngineStop={() => {
        if (!initZoom) {
          graphRef.current.zoomToFit(400)
          setinitZoom(true)
        }
      }}
    />
  )
}

export default Chart
