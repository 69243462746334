import CPIAndInflationProxy from '../../proxies/economy/CPIAndInflationProxy'
import { ServiceBase } from '../ServiceBase'

class CPIAndInflationService extends ServiceBase {
  getCPIAndInflationTable(params) {
    return this.getData(() =>
      CPIAndInflationProxy.getCPIAndInflationTable(params),
    )
  }

  getCPIAndInflationTableContent(params) {
    return this.getData(() =>
      CPIAndInflationProxy.getCPIAndInflationTableContent(params),
    )
  }

  downloadCPIAndInflationTableContent(params) {
    return this.getFileDownload(() =>
      CPIAndInflationProxy.downloadCPIAndInflationTableContent(params),
    )
  }

  getGrowthYearly(params) {
    return this.getData(() => CPIAndInflationProxy.getGrowthOverYears(params))
  }

  getContribution(params) {
    return this.getData(() => CPIAndInflationProxy.getContribution(params))
  }

  getGrowthSectorChart(params) {
    return this.getData(() => CPIAndInflationProxy.getGrowthSector(params))
  }
}

export default new CPIAndInflationService()
