import PropTypes from 'prop-types'
import { COMPONENT } from '../../../../configs/Layout'
import { Panel } from '../../../common/panel'
import { withWrapper } from '../../../common/withWrapper'
import { PANEL_PADDING } from '../../../constants/Common'
import { formatNumber } from '../../../utils/Common'
import { MarketNews } from './marketNews'
import { MarketReports } from './marketReports'

const MAP_KEY = {
  MARKET_NEWS: 'MARKET_NEWS',
  MARKET_REPORTS: 'MARKET_REPORTS',
}

const MAP_SIZE = {
  [MAP_KEY.MARKET_NEWS]: {
    width: `calc(300% / 4 - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: 0,
    minWidth: 750,
    minHeight: 700,
    minTop: 0,
    minLeft: 0,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
  [MAP_KEY.MARKET_REPORTS]: {
    width: `calc(100% / 4 - ${COMPONENT.MARGIN}px)`,
    height: '100%',
    top: 0,
    left: `calc(300% / 4 + ${COMPONENT.MARGIN}px)`,
    minWidth: 750 / 3,
    minHeight: 700,
    minTop: 0,
    minLeft: 750 + COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const List = ({ sizes, setSizes }) => {
  return (
    <>
      <Panel
        title={'market.newsOrReport.ALL_NEWS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_NEWS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <MarketNews
          width={formatNumber(sizes[MAP_KEY.MARKET_NEWS].width) - PANEL_PADDING}
        />
      </Panel>
      <Panel
        title={'market.newsOrReport.ANALYTICAL_REPORTS'}
        panelRefs={panelRefs}
        panelKey={MAP_KEY.MARKET_REPORTS}
        sizes={sizes}
        setSizes={setSizes}
        windowZoom
      >
        <MarketReports
          width={
            formatNumber(sizes[MAP_KEY.MARKET_REPORTS].width) - PANEL_PADDING
          }
        />
      </Panel>
    </>
  )
}

List.propTypes = {
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default withWrapper(MAP_SIZE, panelRefs)(List)
