import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Footer } from '../../../../common/chart/footer'
import { TYPE } from '../../../../common/chart/footer/Item'
import { Loading } from '../../../../common/loading'
import { NoData } from '../../../../common/noData'
import { SizeTracker } from '../../../../common/sizeTracker'
import { selectIndexInfo } from '../../../../common/topInfo/indexTopInfo/store/slice'
import ChartComponent from './ChartComponent'
import TimeFrame from './TimeFrame'
import {
  resetStore,
  selectData,
  selectLoading,
  selectTimeFrame,
} from './store/slice'
import { getNetValueChartData } from './store/thunk'

const NetValue = ({ width, height }) => {
  const dispatch = useDispatch()
  const locale = useSelector((state) => state.i18n.locale)
  const indexInfo = useSelector(selectIndexInfo)
  const timeFrame = useSelector(selectTimeFrame)
  const loadingChartData = useSelector(selectLoading)
  const data = useSelector(selectData)

  useEffect(() => {
    if (indexInfo.groupId) {
      dispatch(
        getNetValueChartData({
          GroupId: indexInfo.groupId,
          Period: timeFrame,
        }),
      )
    }
  }, [timeFrame, indexInfo?.groupId, locale])

  useEffect(() => {
    return () => dispatch(resetStore())
  }, [])

  const renderChart = (height) => (
    <SizeTracker>
      {(size) => {
        return (
          <div id="market-foreign-net-value">
            {size.height && (
              <div style={{ position: 'relative' }}>
                <ChartComponent
                  data={data}
                  width={width}
                  height={height - size.height}
                  keyXAxis={'tradingDateId'}
                  minTradingDateIdKey={'minTradingDateId'}
                  timeFrame={timeFrame}
                  lineKey={'closeIndex'}
                  barKey={'foreignNetValueTotal'}
                  index={indexInfo?.groupName}
                  locale={locale}
                />
              </div>
            )}
            <Footer
              key={width}
              list={[
                {
                  text: 'market.marketInDepth.foreign.FOREIGN_NET_VALUE',
                  type: TYPE.SQUARE_TWO_COLOR,
                  before: {
                    bgColor: '#3da967',
                  },
                  after: {
                    bgColor: '#e0505b',
                  },
                },
                {
                  text: indexInfo?.groupName,
                  type: TYPE.CIRCLE_MARKER_LINE,
                  before: {
                    bgColor: '#f7b80c',
                  },
                  after: {
                    bgColor: '#f7b80c',
                  },
                },
              ]}
            />
          </div>
        )
      }}
    </SizeTracker>
  )

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <TimeFrame />
            {size.height && (
              <div style={{ height: height - size.height }}>
                {loadingChartData ? (
                  <Loading />
                ) : data.length === 0 ? (
                  <NoData />
                ) : (
                  renderChart(height - size.height)
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default NetValue
