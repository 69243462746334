export const maxIndicator = 250

export const typeSettingEconomy = {
  SINGLE: 'single',
  MULTIPLE: 'multiple',
}

export const periodSettingEconomy = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
}
