import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { valDivBillion } from '../../../../../utils/Value'
import { TIME_FRAME_NET_VALUE } from '../constants'
import { getNetValueChartData } from './thunk'

const initialState = {
  timeFrame: TIME_FRAME_NET_VALUE.ONE_MONTH.value,
  loading: true,
  data: [],
}

const slice = createSlice({
  name: 'market/marketInDepth/foreign/netValue',
  initialState,
  reducers: {
    resetStore: (state) => {
      Object.keys(initialState).forEach((key) => {
        state[key] = initialState[key]
      })
    },
    changeTimeFrame: (state, action) => {
      state.timeFrame = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNetValueChartData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNetValueChartData.fulfilled, (state, action) => {
      state.loading = false
      state.data = action.payload
        .sort((a, b) => new Date(a.tradingDateId) - new Date(b.tradingDateId))
        .map((item) => ({
          ...item,
          foreignNetValueTotal: valDivBillion(item.foreignNetValueTotal),
        }))
    })
    builder.addCase(getNetValueChartData.rejected, (state) => {
      state.loading = false
    })
  },
})

export const selectTimeFrame = (state) => state[slice.name].timeFrame
export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data

export const { resetStore, changeTimeFrame } = slice.actions

register(slice.name, slice.reducer)
