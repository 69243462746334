import { createAsyncThunk } from '@reduxjs/toolkit'
import ValuationService from '../../../../../../core/services/market/marketInDepth/ValuationService'

export const getValuation = createAsyncThunk(
  'market/marketInDepth/valuation/GET_VALUATION',
  async (params, { rejectWithValue }) => {
    const response = await ValuationService.getValuation(params)
    if (response.success) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)

export const getCompareValuation = createAsyncThunk(
  'market/marketInDepth/valuation/GET_COMPARE_VALUATION',
  async (array, { rejectWithValue }) => {
    const promises = []
    array.forEach((element) => {
      promises.push(
        ValuationService.getCompareValuation({
          Id: element.id,
          GroupType: element.groupType,
        }),
      )
    })

    const response = await Promise.all(promises)

    if (response) {
      const result = {}
      array.forEach((element, index) => {
        result[element.idCompare] = response[index].data
      })
      return result
    }
    return rejectWithValue('Error')
  },
)
