import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { externalLink } from '../../../configs/ExternalLink'
import EventEmitter, {
  COMPONENT_RESIZE,
  COMPONENT_RESIZE_START,
} from '../../utils/EventEmitter'
import useI18n from '../hooks/useI18n'
import { STOCK_EVENT_TYPE } from './constants'
import { Provider } from './context'
import { getDataFeed } from './dataFeed'
import { SYMBOL_TYPES } from './dataFeed/constants'
import { commonConfig, getOverrides } from './helper'
import { LoadTemplate } from './loadTemplate'
import SaveTemplate from './saveTemplate'
import ShareTemplate from './shareTemplate'

const DEFAULT_SYMBOL = 'HPG'

export const Content = (props) => {
  const [isShowLoadTemplatePopup, changeLoadPopupStatus] = useState(false)
  const [isShowSaveTemplate, changeSaveTemplateStatus] = useState(false)
  const [isShowShare, changeSharePopupStatus] = useState(false)
  const [isDisableHover, setIsDisableHover] = useState(false)
  const locale = useSelector((state) => state.i18n.locale)
  const ref = useRef({})
  const loadTemplateLabel = useI18n('common.tradingView.LOAD_TEMPLATE')
  const saveTemplateLabel = useI18n('common.tradingView.SAVE_TEMPLATE')
  const shareLabel = useI18n('common.tradingView.SHARE')

  useEffect(() => {
    initTradingView()
  }, [locale, props.defaultSymbol])

  useEffect(() => {
    const disableHoverChart = () => {
      setIsDisableHover(true)
    }
    const enableHoverChart = () => {
      setIsDisableHover(false)
    }
    EventEmitter.subscribe(COMPONENT_RESIZE_START, disableHoverChart)
    EventEmitter.subscribe(COMPONENT_RESIZE, enableHoverChart)
    return () => {
      EventEmitter.unsubscribe(COMPONENT_RESIZE_START, disableHoverChart)
      EventEmitter.unsubscribe(COMPONENT_RESIZE, enableHoverChart)
    }
  }, [])

  const initTradingView = () => {
    const {
      interval,
      libraryPath,
      chartsStorageUrl,
      chartsStorageApiVersion,
      clientId,
      userId,
      fullscreen,
      defaultSymbol,
      PriceType,
      Type,
      Id,
    } = props

    const savedData = getSavedData()
    let widgetOptions = {
      ...commonConfig,
      locale,
      symbol: defaultSymbol,
      indicators_file_name: `/charting_library/indicators.js`,
      datafeed: getDataFeed(props.stockEventType, PriceType, Type, Id),
      interval: interval,
      container_id: getContainerId(),
      library_path: libraryPath,
      charts_storage_url: chartsStorageUrl,
      charts_storage_api_version: chartsStorageApiVersion,
      client_id: clientId,
      user_id: userId,
      fullscreen: fullscreen,
      snapshot_url: externalLink.tradingViewSnapshotUrl,
      overrides: getOverrides(),
      loading_screen: {
        backgroundColor: '#181C26',
      },
      theme: 'Dark',
      ...(savedData ? { saved_data: savedData } : {}),
    }
    if (props.disabledFeatures) {
      widgetOptions.disabled_features = [
        ...widgetOptions.disabled_features,
        ...props.disabledFeatures,
      ]
    }

    ref.current.tvWidget = new window.TradingView.widget(widgetOptions)

    ref.current.tvWidget.onChartReady(() => {
      ref.current.tvWidget.applyOverrides(getOverrides())
      if (!widgetOptions.disabled_features.includes('header_widget')) {
        addLoadTemplateBtn()
        addSaveTemplateBtn()
        addShareBtn()
      }

      // Subscribe
      ref.current.tvWidget.subscribe('onAutoSaveNeeded', onAutoSaveNeeded)
      ref.current.tvWidget
        .chart()
        .onSymbolChanged()
        .subscribe(null, onSymbolChanged)
    })
  }

  const getSavedData = () => {
    const { containerId, savedData, isNewWindow, defaultSymbol } = props

    if (isNewWindow) {
      return savedData
    }

    const symbol = localStorage.getItem('chartState/symbol/' + containerId)
    if (defaultSymbol && defaultSymbol !== symbol) {
      return null
    }
    const chartStateString = localStorage.getItem('chartState/' + containerId)
    if (!chartStateString) {
      return null
    }

    return JSON.parse(chartStateString)
  }

  const getContainerId = () => {
    return props.containerId
  }

  const onAutoSaveNeeded = () => {
    const { containerId, isNewWindow, defaultSymbol } = props
    if (isNewWindow) {
      return
    }
    ref.current.tvWidget.save((chartState) => {
      localStorage.setItem(
        'chartState/' + containerId,
        JSON.stringify(chartState),
      )
      localStorage.setItem('chartState/symbol/' + containerId, defaultSymbol)
    })
  }

  const onSymbolChanged = (symbol) => {
    const overrides = {
      'mainSeriesProperties.style': 1,
    }
    if ([SYMBOL_TYPES.ECONOMY, SYMBOL_TYPES.COMMODITY].includes(symbol.type)) {
      overrides['mainSeriesProperties.style'] = 2
    }
    ref.current.tvWidget.applyOverrides(overrides)
  }

  const addSaveTemplateBtn = () => {
    const button = ref.current.tvWidget
      .createButton()
      .attr('title', saveTemplateLabel)
      .addClass('apply-common-tooltip')
      .on('click', () => {
        changeSaveTemplateStatus(true)
      })

    button[0].innerHTML = saveTemplateLabel
  }

  const addLoadTemplateBtn = () => {
    const button = ref.current.tvWidget
      .createButton()
      .attr('title', loadTemplateLabel)
      .addClass('apply-common-tooltip')
      .on('click', () => {
        changeLoadPopupStatus(true)
      })

    button[0].innerHTML = loadTemplateLabel
  }

  const addShareBtn = () => {
    const button = ref.current.tvWidget
      .createButton({ align: 'right' })
      .attr('title', shareLabel)
      .addClass('apply-common-tooltip')
      .on('click', () => {
        changeSharePopupStatus(true)
      })

    button[0].innerHTML = shareLabel
  }

  return (
    <Provider value={ref.current.tvWidget}>
      <div
        className="h-100 position-relative"
        style={{
          pointerEvents: isDisableHover ? 'none' : 'all',
          ...props.appendStyle,
        }}
      >
        <div id={getContainerId()} className="h-100" />

        {isShowLoadTemplatePopup && (
          <LoadTemplate changePopUpStatus={changeLoadPopupStatus} />
        )}
        {isShowSaveTemplate && (
          <SaveTemplate changeSaveTemplateStatus={changeSaveTemplateStatus} />
        )}
        {isShowShare && (
          <ShareTemplate changeSharePopupStatus={changeSharePopupStatus} />
        )}
      </div>
    </Provider>
  )
}

Content.defaultProps = {
  interval: 'D',
  libraryPath: '/charting_library/',
  fullscreen: false,
  studiesOverrides: {},
  containerId: 'charting',
  isNewWindow: false,
  defaultSymbol: DEFAULT_SYMBOL,
  chartsStorageApiVersion: '1.3',
  clientId: 'tradingview.com',
  userId: 'public_user_id',
  appendStyle: {},
  stockEventType: STOCK_EVENT_TYPE.ALL,
}
