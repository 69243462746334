import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import { Input } from '../../../../common/html/Input'
import Tree from '../../../../common/tree'
import { getAllChildrenItem } from '../../../../common/tree/helper'
import EventEmitter, { SHOW_POPUP_ERROR } from '../../../../utils/EventEmitter'
import style from '../../index.module.css'
import {
  changeListCheckIndicatorPopup,
  pushAddMoreCondition,
  selectAddMoreConditionByIds,
  selectAddMoreConditionIds,
  selectCheckConditionLastApply,
  selectListCheckIndicatorPopup,
  sliceAddMoreCondition,
} from '../../store/slice'
import { tabs } from './FilterCondition'
import { idScrollTable } from './TableCondition'

const heightAddedPeriod = 53
let isDisableAddCondition = false

const TreeIndicator = ({
  activeTab,
  quickSelectIndicatorsTree,
  ratioIndicatorsTree,
  financialStatementIndicatorsTree,
}) => {
  const dispatch = useDispatch()

  const addMoreConditionIds = useSelector(selectAddMoreConditionIds)
  const addMoreConditionByIds = useSelector(selectAddMoreConditionByIds)
  const checkConditionLastApply = useSelector(selectCheckConditionLastApply)
  const listChecked = useSelector(selectListCheckIndicatorPopup)

  const lastListChecked = useRef(listChecked)

  const [valueSearch, setValueSearch] = useState('')

  useEffect(() => {
    setValueSearch('')
  }, [activeTab])

  useEffect(() => {
    if (isDisableAddCondition) {
      isDisableAddCondition = false
    } else {
      if (listChecked.length > lastListChecked.current.length) {
        const arrPush =
          quickSelectIndicatorsTree[
            listChecked.filter((id) => !lastListChecked.current.includes(id))[0]
          ]?.items
        dispatch(pushAddMoreCondition(arrPush))
      } else if (listChecked.length < lastListChecked.current.length) {
        const arrSlice =
          quickSelectIndicatorsTree[
            lastListChecked.current.filter((id) => !listChecked.includes(id))[0]
          ]?.items
        dispatch(sliceAddMoreCondition(arrSlice))
      }
    }
    lastListChecked.current = listChecked
  }, [listChecked])

  useEffect(() => {
    if (activeTab === tabs.quickSelect.value) {
      let newListChecked = []
      Object.keys(quickSelectIndicatorsTree).forEach((id) => {
        const parent = quickSelectIndicatorsTree[id]
        if (
          parent.items &&
          parent.items.every(
            (child) => addMoreConditionByIds[child.indicatorId]?.checked,
          )
        ) {
          newListChecked.push(+id)
        }
      })
      setListChecked(newListChecked)
      isDisableAddCondition = true
    }
  }, [addMoreConditionByIds])

  const setListChecked = (checked) =>
    dispatch(changeListCheckIndicatorPopup(checked))

  const handleChangeSearch = (e) => setValueSearch(e.target.value)

  const handleDoubleClickItemTree = (item) => {
    if (Object.keys(item.children).length === 0) {
      if (addMoreConditionIds.includes(item.indicatorId)) {
        EventEmitter.dispatch(SHOW_POPUP_ERROR, [
          I18n.t('tool.smartScreening.DUPLICATE_INDICATOR'),
        ])
      } else {
        dispatch(pushAddMoreCondition([item]))
      }
    } else {
      dispatch(pushAddMoreCondition(getAllChildrenItem(item)))
    }
    const scrollTableEl = document.querySelector(
      `#${idScrollTable} .scrollbars div:first-child`,
    )
    setTimeout(
      () =>
        scrollTableEl.scroll({
          top: scrollTableEl.scrollHeight,
        }),
      0,
    )
  }

  const listDisabled = { ...checkConditionLastApply }
  addMoreConditionIds.forEach((indicatorId) => {
    listDisabled[indicatorId] = true
  })

  return (
    <>
      <div
        className={`col-header pl-12-pr-16 ${style.headerCondition} ${style.headerPopup}`}
      >
        <div className="form-search-inbox m-0">
          <Input
            type="text"
            className={`search-inbox w-248 ${style.search}`}
            placeholder={I18n.t('tool.smartScreening.SEARCH')}
            value={valueSearch}
            onChange={handleChangeSearch}
          />
          <button type="button">
            <i className="icon-search-2" />
          </button>
        </div>
      </div>
      <div className="col-content">
        <div
          className="content-wrapper"
          style={{
            height:
              activeTab === tabs.financialStatement.value
                ? `calc(300px - ${heightAddedPeriod}px)`
                : '300px',
          }}
        >
          {activeTab === tabs.quickSelect.value && (
            <div className={`${style.treeQuickSelect}`}>
              <Tree
                treeData={quickSelectIndicatorsTree}
                idKey="indicatorId"
                nameKey="indicatorName"
                isHaveCheckbox={true}
                listChecked={listChecked}
                setListChecked={setListChecked}
                valueSearch={valueSearch}
                isCheckedWhenClickText={true}
              />
            </div>
          )}
          {activeTab === tabs.keyRatios.value && (
            <div className={`${style.treeRatio}`}>
              <Tree
                treeData={ratioIndicatorsTree}
                nameKey="indicatorName"
                idKey="indicatorId"
                valueSearch={valueSearch}
                doubleClickItem={handleDoubleClickItemTree}
                listDisabled={listDisabled}
                sortKey="orderNumber"
              />
            </div>
          )}
          {activeTab === tabs.financialStatement.value && (
            <div className={`${style.treeRatio}`}>
              <Tree
                treeData={financialStatementIndicatorsTree}
                nameKey="indicatorName"
                idKey="indicatorId"
                valueSearch={valueSearch}
                doubleClickItem={handleDoubleClickItemTree}
                listDisabled={listDisabled}
                sortKey="orderNumber"
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

TreeIndicator.propTypes = {
  activeTab: PropTypes.number.isRequired,
}

export default TreeIndicator
