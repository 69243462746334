export const COLORS_LINE_CHART = [
  '#c7ff58',
  '#9c54e4',
  '#185999',
  '#ecffdf',
  '#f57f07',
  '#c52e14',
  '#975000',
  '#1a36a9',
  '#57426d',
  '#f9c105',
  '#a103fc',
  '#fc6203',
  '#03fca5',
  '#03d7fc',
  '#fc03f4',
  '#90fc03',
  '#628200',
  '#3452eb',
  '#eb3462',
  '#9b8e9e',
]

export const TIME_RANGE = {
  YEARLY: [
    {
      value: 5,
      title: '5Y',
    },
    {
      value: 7,
      title: '7Y',
    },
    {
      value: 10,
      title: '10Y',
    },
    {
      value: 20,
      title: '20Y',
    },
    {
      value: 30,
      title: 'sector.sectorSpecific.fishery.ALL',
    },
  ],
  MONTHLY: [
    {
      value: 1,
      title: '1Y',
    },
    {
      value: 3,
      title: '3Y',
    },
    {
      value: 5,
      title: '5Y',
    },
    {
      value: 10,
      title: '10Y',
    },
    {
      value: 30,
      title: 'sector.sectorSpecific.fishery.ALL',
    },
  ],
}
