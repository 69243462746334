import { COMPONENT } from '../../../configs/Layout'
import { randomOneColor } from '../../utils/Color'
import { getTimeFrequencyByIndicator } from '../common/helpers'
import {
  dataFormatTypes,
  suggestColors,
} from '../common/menuChartSettings/constants'
import { MAP_NAME_BY_TIME_FREQUENCY, TIME_FREQUENCY } from '../popup/constants'

const backgroundThColor = '#273040'

export const randomColor = (ignoreColors = [], favoriteColors = []) => {
  const lowerCaseIgnoreColors = ignoreColors.map((color) => color.toLowerCase())
  const priorityColors = [...favoriteColors, ...suggestColors]
    .map((color) => color.toLowerCase())
    .filter(
      (color, index, arr) =>
        arr.findIndex((val) => val === color) === index &&
        !lowerCaseIgnoreColors.includes(color),
    )
  return priorityColors.length
    ? priorityColors[0]
    : randomOneColor({
        ignoreColors: lowerCaseIgnoreColors.concat([backgroundThColor]),
      })
}

export const getNameIndicator = (
  indicator = {},
  time = TIME_FREQUENCY.DAILY,
) => {
  if (Object.keys(indicator).length) {
    let nameIndicator = ''
    Object.keys(indicator).forEach((i) => {
      if (Object.values(TIME_FREQUENCY).includes(i) && indicator[i]) {
        nameIndicator = [indicator.name, MAP_NAME_BY_TIME_FREQUENCY[time]].join(
          ' ',
        )
      }
    })
    return nameIndicator
  }
  return ''
}

export const getTimeFrequencyIndicator = (indicator) => {
  let timeFrequency = ''
  if (indicator) {
    Object.keys(indicator).forEach((i) => {
      if (Object.values(TIME_FREQUENCY).includes(i) && indicator[i]) {
        timeFrequency = i
      }
    })
  }
  return timeFrequency
}

export const checkSmallScreen = (
  width,
  compareWidth = COMPONENT.MIN_SIZE.WIDTH,
) => {
  return width < compareWidth
}

export const setSyncTreeSecuritiesIndicatorsBySecurities = (state, action) => {
  const { typeSecurities, indexIndicator, data } = action.payload

  Object.keys(
    state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
      typeSecurities
    ],
  ).forEach((securityKey) => {
    const item = {
      ...state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
        typeSecurities
      ][securityKey][indexIndicator],
    }

    state.chartTabs[state.chartTabActive].treeSecuritiesIndicator[
      typeSecurities
    ][securityKey][indexIndicator] = {
      ...item,
      ...Object.keys(data).reduce((obj, key) => {
        if (key === 'dataFormatType') {
          // Remove after add relative by securities
          return { ...obj, [key]: dataFormatTypes.VALUE }
        }

        if (key === 'dataKey' || key === 'dataKeyDuplicate') {
          return obj
        }

        if (key === 'chartParameters') {
          return {
            ...obj,
            [key]: {
              ...item[key],
              ...Object.keys(data[key]).reduce((childObj, childKey) => {
                if (
                  [
                    'dataKeys',
                    'yAxisId',
                    'dataKeysDuplicate',
                    'yAxisIdDuplicate',
                  ].includes(childKey)
                ) {
                  return childObj
                } else {
                  return { ...childObj, [childKey]: data[key][childKey] }
                }
              }, {}),
            },
          }
        }

        return { ...obj, [key]: data[key] }
      }, {}),
    }
  })
}

export const getSecuritiesIndicatorsWithLatestPeriod = (
  indicators,
  indicatorsLatestPeriod,
) => {
  return Object.keys(indicators).reduce(
    (obj, key) => ({
      ...obj,
      [key]: indicators[key].map((indicator) => {
        if (Object.keys(indicator.dataLatestPeriod).length) {
          return indicator
        } else {
          const keyLatestPeriod = `${
            indicator.id
          }_${getTimeFrequencyByIndicator(indicator)}`
          const dataLatestPeriod = indicatorsLatestPeriod[keyLatestPeriod] || {}
          return { ...indicator, dataLatestPeriod }
        }
      }),
    }),
    {},
  )
}

export const getEconomyWithLatestPeriod = (economy, indicatorsLatestPeriod) => {
  return economy.map((item) => {
    if (item.dataLatestPeriod && Object.keys(item.dataLatestPeriod).length) {
      return item
    } else {
      const keyLatestPeriod = `${item.id}_${getTimeFrequencyByIndicator(item)}`
      const dataLatestPeriod = indicatorsLatestPeriod[keyLatestPeriod] || {}
      return { ...item, dataLatestPeriod }
    }
  })
}

export const getEquationsWithLatestPeriod = (
  equations,
  indicatorsLatestPeriod,
) => {
  const timeKeys = ['date', 'week', 'month', 'quarter', 'year']
  return Object.keys(equations).reduce(
    (obj, key) => ({
      ...obj,
      [key]: {
        ...equations[key],
        equationIndicators: Object.keys(
          equations[key].equationIndicators,
        ).reduce(
          (childObj, childKey) => ({
            ...childObj,
            [childKey]: {
              ...equations[key].equationIndicators[childKey],
              data: Object.keys(
                equations[key].equationIndicators[childKey].data,
              ).reduce((dataObj, dataKey) => {
                const item =
                  equations[key].equationIndicators[childKey].data[dataKey]
                if (
                  Object.keys(item).some(
                    (itemKey) =>
                      timeKeys.includes(itemKey) && item[itemKey] !== null,
                  )
                ) {
                  return { ...dataObj, [dataKey]: item }
                } else {
                  const indicator = equations[key].equationIndicators[childKey]
                  const keyLatestPeriod = `${
                    indicator.indicatorId
                  }_${getTimeFrequencyByIndicator(indicator)}`
                  const dataLatestPeriod =
                    indicatorsLatestPeriod[keyLatestPeriod] || {}
                  return {
                    ...dataObj,
                    [dataKey]: { ...item, ...dataLatestPeriod },
                  }
                }
              }, {}),
            },
          }),
          {},
        ),
      },
    }),
    {},
  )
}

export const checkDefaultChartSettings = (
  settings,
  styleSetting,
  defaultChartSettings,
) => {
  if (
    !defaultChartSettings ||
    !defaultChartSettings?.settings ||
    !defaultChartSettings?.styleSetting ||
    !settings ||
    !styleSetting
  ) {
    return false
  }

  const isSameSettings = Object.keys(defaultChartSettings.settings).every(
    (key) => defaultChartSettings.settings[key] === settings[key],
  )

  const isSameStyleSetting = Object.keys(
    defaultChartSettings.styleSetting,
  ).every(
    (key) =>
      (!defaultChartSettings.styleSetting[key] && !styleSetting[key]) ||
      (defaultChartSettings.styleSetting[key] &&
        styleSetting[key] &&
        Object.keys(defaultChartSettings.styleSetting[key]).every(
          (childKey) =>
            defaultChartSettings.styleSetting[key][childKey] ===
            styleSetting[key][childKey],
        )),
  )

  return isSameSettings && isSameStyleSetting
}
