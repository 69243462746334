export const valueTab = {
  BOND_DEFAULT: 0,
  BOND_CREATED: 1,
}

export const tabOption = [
  {
    title: 'bond.valuation.sameIndusTryBond.tabDefault.default',
    value: valueTab.BOND_DEFAULT,
  },
  {
    title: 'bond.valuation.sameIndusTryBond.tabDefault.created',
    value: valueTab.BOND_CREATED,
  },
]

export const valueInterrest = {
  ACT: 1,
  ACT360: 2,
  ACT365: 3,
  EURPOAN: 4,
}

export const tabInterrestOption = [
  {
    name: 'Act/Act',
    value: valueInterrest.ACT,
  },
  {
    name: 'Act/360',
    value: valueInterrest.ACT360,
  },
  {
    name: 'Act/365',
    value: valueInterrest.ACT365,
  },
  {
    name: 'Eurpoan',
    value: valueInterrest.EURPOAN,
  },
]

export const valueCheckBox = {
  YTM: 'YTM',
  DIRTY_PRICE: 'DIRTY_PRICE',
  CLEAN_PRICE: 'CLEAN_PRICE',
}

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const columns = [
  {
    key: 'bondPaymentTypeId',
    title:
      'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.CashFlow',
    attrs: {
      style: { textAlign: 'left' },
    },
  },
  {
    key: 'tradingDateId',
    title:
      'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.dateTrading',
    attrs: {
      style: { textAlign: 'center' },
    },
  },
  {
    key: 'couponInterestRate',
    title: 'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.coupon',
    additionText:
      'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.unitMoney',

    attrs: {
      style: { textAlign: 'center' },
    },
  },
  {
    key: 'estimatedValue',
    title: 'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.value',
    additionText:
      'bond.valuation.sameIndusTryBond.BondCashFlowScenario.table.unit',
    attrs: {
      style: { textAlign: 'center' },
    },
  },
]

export const typePopup = {
  DELETE_ROW: 'DELETE_ROW',
  ADD_ROW: 'ADD_ROW',
  WARNING: 'WARNING',
}
