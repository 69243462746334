import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../utils/Common'
import { register } from '../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../utils/Value'
import { getTimeAndSales, getTimeAndSalesChart } from './thunk'

const initialState = {
  isLoadingChart: true,
  isLoadingTable: true,
  ids: [],
  initialIds: [],
  data: [],
  dataByIds: {},
  scrollId: null,
  pageSize: 100,
  isFirstTime: true,
  dataChart: [],
}

const slice = createSlice({
  name: 'market/derivative/timeAndSales',
  initialState,
  reducers: {
    changePageSize: (state, action) => {
      state.pageSize = action.payload
    },
    resetState: (state) => {
      state.ids = []
      state.initialIds = []
      state.data = []
      state.dataByIds = {}
      state.isLoadingTable = true
      state.scrollId = null
    },
    subscribeMatchingOrder: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
        const newData = action.payload[0]
        if (newData.matchVolume && newData.matchVolume > 0) {
          const newDataRealTime = {
            tradingDate: newData.tradingDate,
            matchType: Number(newData.matchType),
            matchPrice: Number(newData.matchPrice),
            priceChange: Number(newData.priceChange),
            percentPriceChange: Number(newData.percentPriceChange),
            matchVolume: Number(newData.matchVolume),
            totalMatchVolume: Number(newData.totalMatchVolume),
          }
          const data = [newDataRealTime, ...state.data]
            .map((item, index) => ({
              ...item,
              id: index,
            }))
            .sort(
              (a, b) =>
                new Date(b.tradingDate).getTime() -
                new Date(a.tradingDate).getTime(),
            )
          state.data = data
          state.initialIds = data.map((v) => v.id)
          state.dataByIds = keyBy(data, 'id')
          state.ids = data.map((v) => v.id)
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTimeAndSales.pending, (state) => {
      if (state.isFirstTime) {
        state.isLoadingTable = true
      }
    })
    builder.addCase(getTimeAndSales.fulfilled, (state, action) => {
      state.isLoadingTable = false
      const lastLength = state.data.length
      const newData = action.payload.data
        .map((item, index) => ({
          ...item,
          id: index + lastLength,
        }))
        .sort(
          (a, b) =>
            new Date(b.tradingDate).getTime() -
            new Date(a.tradingDate).getTime(),
        )
      const data = [...state.data, ...newData]
      state.data = data
      state.initialIds = data.map((v) => v.id)
      state.dataByIds = keyBy(data, 'id')
      state.ids = data.map((v) => v.id)
      state.scrollId = action.payload.scrollId
      if (state.isFirstTime) {
        state.isFirstTime = false
      }
    })
    builder.addCase(getTimeAndSales.rejected, (state, action) => {
      state.isLoadingTable = action.payload.loading
    })

    builder.addCase(getTimeAndSalesChart.pending, (state) => {
      state.isLoadingChart = true
    })
    builder.addCase(getTimeAndSalesChart.fulfilled, (state, action) => {
      state.dataChart = action.payload.data
      state.isLoadingChart = false
    })
    builder.addCase(getTimeAndSalesChart.rejected, (state, action) => {
      state.isLoadingChart = action.payload.loading
    })
  },
})

export const selectLoadingTable = (state) => state[slice.name].isLoadingTable
export const selectLoadingChart = (state) => state[slice.name].isLoadingChart
export const { changePageSize, subscribeMatchingOrder, resetState } =
  slice.actions
export const selectDataMatchingOrder = (colId, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataByIds[colId], attr)
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectPageSize = (state) => state[slice.name].pageSize
export const selectScrollId = (state) => state[slice.name].scrollId
export const selectDataChart = (state) => state[slice.name].dataChart

register(slice.name, slice.reducer)
