import { uniq } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadCsv } from '../../../common/downloadCsv'
import { Span } from '../../../common/html/Span'
import { PanelHeader } from '../../../common/panelHeader'
import { TIME_RANGES } from '../../../common/tabs/DispatchActionTab'
import { calendarTabKey } from '../../../common/tabs/DispatchActionTabWithCalendar'
import TextEllipsis from '../../../common/textEllipsis'
import { TAB_TYPES } from '../constants'
import {
  changeActiveTab,
  selectFilter,
  selectFilterParams,
  selectInitialParentSectors,
  selectInitialSectors,
  selectParentSectors,
  setFilter,
} from '../store/slice'
import { exportBondListData } from '../store/thunk'
import Header from './Header'
import TableContent from './Table'
import { changeLoadingTable } from './Table/store/slice'
import style from './index.module.css'

const BondList = ({ redirectToBondIssuer }) => {
  const dispatch = useDispatch()
  const bondListRef = useRef()

  const { visible } = useSelector(selectFilter)
  const params = useSelector(selectFilterParams)
  const initialSectors = useSelector(selectInitialSectors)
  const initialParentSectors = useSelector(selectInitialParentSectors)
  const listParent = useSelector(selectParentSectors)

  const { indicatorGroup, sectors } = params

  const [loadingDownloadCsv, setLoadingDownloadCsv] = useState(false)

  const downloadCsv = () => {
    const newParams = {
      ...params,
      sectors:
        uniq([...listParent, ...sectors])?.length ===
        uniq([...initialSectors, ...initialParentSectors])?.length
          ? []
          : uniq([...listParent, ...sectors]),
      timeRange:
        params.timeRange === calendarTabKey
          ? TIME_RANGES.All
          : params.timeRange,
    }

    return exportBondListData(newParams)
  }
  useEffect(() => {
    dispatch(changeActiveTab(TAB_TYPES.ALL))
    dispatch(setFilter({ key: 'visible', value: false }))
    dispatch(changeLoadingTable(true))
  }, [indicatorGroup])

  return (
    <div
      ref={bondListRef}
      className={`window ${style.windowRelative}`}
      style={{ flex: 1 }}
    >
      <PanelHeader>
        <div className="d-flex w-100 j-b ali-center">
          <div className="window-header-title" style={{ gap: 4 }}>
            <Span>
              <TextEllipsis text="bond.bondScreening.bondList.TITLE" />
            </Span>
          </div>
          <div className="d-flex ali-center">
            <DownloadCsv
              downloadCsv={downloadCsv}
              setLoadingDownloadCsv={setLoadingDownloadCsv}
            />
          </div>
        </div>
      </PanelHeader>
      <div className="window-body h-100" style={{ padding: 8 }}>
        <div className="flex-column h-100">
          <Header
            containerRef={bondListRef}
            redirectToBondIssuer={redirectToBondIssuer}
          />
          <div
            style={{
              flex: 1,
            }}
          >
            <TableContent redirectToBondIssuer={redirectToBondIssuer} />
          </div>
        </div>
        {loadingDownloadCsv && (
          <div className={style.spinLoading}>
            <div className={style.loadingBox}>
              <div className={style.loader}></div>
            </div>
          </div>
        )}
      </div>
      {visible && <div className={style.overlay}></div>}
    </div>
  )
}

export default BondList
