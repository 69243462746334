import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../../../../utils/ReducerRegistry'
import { handleAreaChartDate, handleKeyWithTitle } from '../helper'
import { getMovementDepositAndLending } from './thunk'

const initialState = {
  timeRange: 'SixMonths',
  lineChartData: [],
  lineChartLoading: true,
  keyWithTitle: {},
}

const slice = createSlice({
  name: 'economy/monetary/interestRate/panelAverageFundingCost',
  initialState,
  reducers: {
    handleTimeRange: (state, action) => {
      state.timeRange = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMovementDepositAndLending.pending, (state) => {
      state.lineChartLoading = true
    })
    builder.addCase(getMovementDepositAndLending.fulfilled, (state, action) => {
      state.lineChartLoading = false
      state.lineChartData = handleAreaChartDate(action.payload || [])
      state.keyWithTitle = handleKeyWithTitle(action.payload || [])
    })
    builder.addCase(getMovementDepositAndLending.rejected, (state, action) => {
      state.lineChartLoading = action.payload.isLoading
    })
  },
})

export const { handleTimeRange, handleActiveLine } = slice.actions

export const getTimeRange = (state) => state[slice.name].timeRange
export const getLineChartData = (state) => state[slice.name].lineChartData
export const getKeyWithTitle = (state) => state[slice.name].keyWithTitle
export const getLineChartLoading = (state) => state[slice.name].lineChartLoading

register(slice.name, slice.reducer)
