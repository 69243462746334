import { EMPTY_VALUE } from '../../constants/Common'
import { valByKeyWithDot } from './../../utils/Value'
import { SORT_TYPES } from './constants'

const sort = ({
  ids,
  data,
  valueFromItem,
  type,
  countStickiesBottom,
  levels,
}) => {
  if (levels.length) {
    return sortWithLevel({ levels, valueFromItem, type, data })
  }

  if (countStickiesBottom) {
    const newIds = ids.concat()
    const listIdsSort = newIds.slice(0, newIds.length - countStickiesBottom)
    const listIdsNotSort = newIds.slice(-countStickiesBottom)

    if (levels.length) {
      const listLevelSort = levels.filter(
        (item) => !listIdsNotSort.includes(item.id),
      )
      return sortWithLevel({
        levels: listLevelSort,
        valueFromItem,
        type,
        data,
      }).concat(listIdsNotSort)
    }

    return listIdsSort
      .sort(sortByValueFromItem(valueFromItem, type, data))
      .concat(listIdsNotSort)
  }

  return ids.concat().sort(sortByValueFromItem(valueFromItem, type, data))
}

const sortWithLevel = ({ levels, valueFromItem, type, data }) => {
  const maxLevel = getMaxLevel(levels)
  const minLevel = getMinLevel(levels)
  let newIds = []
  let count = maxLevel - 1

  do {
    if (count === maxLevel - 1) {
      const rowWithMaxLevel = getRowByLevel(levels, maxLevel)
      newIds = [
        ...rowWithMaxLevel.sort(sortByValueFromItem(valueFromItem, type, data)),
      ]
    } else {
      const rowWithLevel = getRowByLevel(levels, count)

      // eslint-disable-next-line no-loop-func
      rowWithLevel.forEach((item) => {
        const allChildren = levels.filter(
          (children) =>
            children.parentId === item && children.level === count + 1,
        )
        const itemIndex = newIds.findIndex((children) => children === item)
        if (allChildren.length && itemIndex !== -1) {
          const childrenSortByValue = allChildren
            .map((item) => item.id)
            .sort(sortByValueFromItem(valueFromItem, type, data))
          newIds.splice(itemIndex + 1, 0, childrenSortByValue)
        }
      })
    }
    newIds = newIds.flat()
    count++
  } while (count <= minLevel)

  return newIds
}

// Default level 1 > level 2
const getMaxLevel = (levels) => {
  return Math.min(...levels.map((item) => item.level))
}

const getMinLevel = (levels) => {
  return Math.max(...levels.map((item) => item.level))
}

const getRowByLevel = (data, level) => {
  return data.filter((item) => item.level === level).map((item) => item.id)
}

const sortByValueFromItem = (valueFromItem, type, data) => (a, b) => {
  if (valueFromItem(data[b]) === EMPTY_VALUE) {
    return -1
  }

  if (valueFromItem(data[a]) < valueFromItem(data[b])) {
    return type === SORT_TYPES.ASC ? -1 : 1
  }

  if (valueFromItem(data[a]) > valueFromItem(data[b])) {
    return type === SORT_TYPES.ASC ? 1 : -1
  }

  return 0
}

const getValueFromItem = (stateSort) => {
  const [attr] = Object.keys(stateSort)
  return (item) => valByKeyWithDot(item, attr)
}

export const getIdsFromProps = (
  ids,
  data,
  stateSort,
  initialIds,
  countStickiesBottom,
  levels = [],
) => {
  const [sortType] = Object.values(stateSort)

  if (!sortType) {
    return initialIds
  }

  if (!Object.keys(stateSort).length) {
    return ids
  }

  const [type] = Object.values(stateSort)
  return sort({
    ids,
    data,
    valueFromItem: getValueFromItem(stateSort),
    type,
    countStickiesBottom,
    levels,
  })
}

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const getNextIndex = (schema, index) => {
  const nextCol = schema[index + 1]
  if (!nextCol) {
    return null
  }

  if (nextCol.disableResize) {
    return getNextIndex(schema, index + 1)
  }

  return index + 1
}

export const calculateTotalWidth = (widths) => {
  return widths.reduce((prev, current) => {
    return prev + current
  }, 0)
}

export const calcLeftOfSticky = (widths, schema, colIndex) => {
  let total = 0

  for (let index = 0; index < colIndex; index++) {
    const prevItem = schema[index]
    total += widths[prevItem.colId] || 0
  }

  return total
}
