export const BOND_TYPES = {
  GOVERNMENT_BOND: [4, 5, 6],
  CORPORATE_BOND: [1, 2],
}

export const BOND_VALUES = {
  GOVERNMENT_BOND: 1,
  CORPORATE_BOND: 2,
}

export const EXPECTED_TYPES = {
  CASHFLOW_TABLE: 1,
  CASHFLOW_CHART: 2,
}

export const BOND_TABS = [
  {
    title: 'bond.portfolio.common.GOVERNMENT_BOND',
    value: BOND_VALUES.GOVERNMENT_BOND,
    type: BOND_TYPES.GOVERNMENT_BOND,
  },
  {
    title: 'bond.portfolio.common.CORPORATE_BOND',
    value: BOND_VALUES.CORPORATE_BOND,
    type: BOND_TYPES.CORPORATE_BOND,
  },
]

export const FILTER_BOND_INFORMATION_TYPES = {
  PORTFOLIO_ID: 'PortfolioId',
  WATCHLIST_ID: 'WatchListId',
  BOND_ID: 'Bond.BondId',
  IS_USER_BOND: 'Bond.IsUserBond',
  BOND_VOLUME: 'Bond.BondVolume',
}

export const BOND_INFORMATION_TAB_TYPES = {
  BOND_INFORMATION: 1,
  TRADING_INFORMATION: 2,
  PORTFOLIO_ANALYSIS: 3,
}

export const BOND_INFORMATION_TABS = [
  {
    name: 'bond.portfolio.common.BOND_INFORMATION',
    value: BOND_INFORMATION_TAB_TYPES.BOND_INFORMATION,
  },
  {
    name: 'bond.portfolio.bondInformation.TRADING_INFORMATION',
    value: BOND_INFORMATION_TAB_TYPES.TRADING_INFORMATION,
  },
  {
    name: 'bond.portfolio.bondInformation.PORTFOLIO_ANALYSIS',
    value: BOND_INFORMATION_TAB_TYPES.PORTFOLIO_ANALYSIS,
  },
]

export const INTEREST_BASIS_TYPES = {
  ACT: 1,
  ACT360: 2,
  ACT365: 3,
  EURPOAN: 4,
}

export const EXPECTED_MOMENT_TYPES = {
  DAY: 'Day',
  MONTH: 'Month',
  QUARTER: 'Quarter',
  YEAR: 'Year',
}

export const EXPECTED_MOMENT_TABS = [
  {
    title: 'bond.portfolio.expected.DAY',
    value: EXPECTED_MOMENT_TYPES.DAY,
  },
  {
    title: 'bond.portfolio.expected.MONTH',
    value: EXPECTED_MOMENT_TYPES.MONTH,
  },
  {
    title: 'bond.portfolio.expected.QUARTER',
    value: EXPECTED_MOMENT_TYPES.QUARTER,
  },
  {
    title: 'bond.portfolio.expected.YEAR',
    value: EXPECTED_MOMENT_TYPES.YEAR,
  },
]

export const INTEREST_BASIS_OPTIONS = [
  {
    name: 'Act/Act',
    value: INTEREST_BASIS_TYPES.ACT,
  },
  {
    name: 'Act/360',
    value: INTEREST_BASIS_TYPES.ACT360,
  },
  {
    name: 'Act/365',
    value: INTEREST_BASIS_TYPES.ACT365,
  },
  {
    name: 'Eurpoan',
    value: INTEREST_BASIS_TYPES.EURPOAN,
  },
]

export const SORT_TYPES = {
  ASC: 'asc',
  DESC: 'desc',
}

export const sortStateMap = {
  [undefined]: SORT_TYPES.ASC,
  [SORT_TYPES.ASC]: SORT_TYPES.DESC,
  [SORT_TYPES.DESC]: undefined,
}

export const OVERVIEW_OF_PORTFOLIO_TYPES = {
  CONTRIBUTION: 1,
  INTEREST: 2,
  DURATION: 3,
}

export const OVERVIEW_OF_PORTFOLIO_TABS = [
  {
    title: 'bond.portfolio.overviewOfPortfolioSize.CONTRIBUTION',
    value: OVERVIEW_OF_PORTFOLIO_TYPES.CONTRIBUTION,
  },
  {
    title: 'bond.portfolio.overviewOfPortfolioSize.INTEREST',
    value: OVERVIEW_OF_PORTFOLIO_TYPES.INTEREST,
  },
  {
    title: 'bond.portfolio.overviewOfPortfolioSize.REMAINING_DURATION',
    value: OVERVIEW_OF_PORTFOLIO_TYPES.DURATION,
  },
]

export const CASHFLOW_KEYS = {
  TRADING_DATE: 'tradingDateId',
  COUPON: 'couponValue',
  PRINCIPAL: 'principalValue',
  TOTAL: 'total',
}

export const GROUP_BOND_INFORMATION_COLUMNS = [
  {
    name: 'ticker',
    value: 'bond.portfolio.bondInformation.BOND_TICKER',
    keySort: 'ticker',
    isDelete: true,
    attrs: {
      style: {
        minWidth: 200,
      },
    },
  },
  {
    name: 'bondTypeName',
    value: 'bond.portfolio.bondInformation.BOND_TYPE',
    keySort: 'bondTypeName',
    attrs: {
      style: {
        minWidth: 155,
      },
    },
  },
  {
    name: 'organizationShortName',
    value: 'bond.portfolio.bondInformation.ISSUER',
    keySort: 'organizationShortName',
    attrs: {
      style: {
        minWidth: 155,
        maxWidth: 155,
      },
    },
  },
  {
    name: 'icbName',
    value: 'bond.portfolio.bondInformation.INDUSTRY',
    keySort: 'icbName',
    attrs: {
      style: {
        minWidth: 155,
      },
    },
  },
  {
    name: 'issueDateId',
    value: 'bond.portfolio.bondInformation.ISSUE_DATE',
    keySort: 'issueDateId',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'maturityDateId',
    value: 'bond.portfolio.bondInformation.MATURITY_DATE',
    keySort: 'maturityDateId',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'nextPaymentDate',
    value: 'bond.portfolio.bondInformation.NEXT_PAYMENT_DATE',
    keySort: 'nextPaymentDate',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'parValue',
    value: 'bond.portfolio.bondInformation.PAR_VALUE',
    unit: 'bond.portfolio.bondInformation.PAR_VALUE_UNIT',
    keySort: 'parValue',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 155,
      },
    },
  },
  {
    name: 'couponInterestRate',
    value: 'bond.portfolio.bondInformation.COUPON',
    unit: 'bond.portfolio.bondInformation.COUPON_UNIT',
    keySort: 'couponInterestRate',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 150,
      },
    },
  },
  {
    name: 'rbD0008',
    value: 'bond.portfolio.bondInformation.CREDIT_SPREAD',
    keySort: 'rbD0008',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 150,
      },
    },
  },
  {
    name: 'paymentCalendarMonth',
    value: 'bond.portfolio.bondInformation.INTEREST_PAYMENT_PERIOD',
    unit: 'bond.portfolio.bondInformation.INTEREST_PAYMENT_PERIOD_UNIT',
    keySort: 'paymentCalendarMonth',
    justifyContent: 'justify-content-end',

    attrs: {
      style: {
        minWidth: 180,
      },
    },
  },
  {
    name: 'interestBasisTypeId',
    value: 'bond.portfolio.bondInformation.INTEREST_BASIS',
    keySort: 'interestBasisTypeId',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 110,
      },
    },
  },
  {
    name: 'tradingDateId',
    value: 'bond.portfolio.bondInformation.TRADING_DATE',
    keySort: 'tradingDateId',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'valuationDate',
    value: 'bond.portfolio.bondInformation.VALUATION_DATE',
    keySort: 'valuationDate',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'bondVolume',
    value: 'bond.portfolio.bondInformation.BOND_VOLUME',
    keySort: 'bondVolume',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'buyPrice',
    value: 'bond.portfolio.bondInformation.BUY_PRICE',
    unit: 'bond.portfolio.bondInformation.BUY_PRICE_UNIT',
    tooltip: 'bond.portfolio.bondInformation.TOOLTIP_1',
    justifyContent: 'justify-content-end',
    keySort: 'buyPrice',
    attrs: {
      style: {
        minWidth: 130,
      },
    },
  },
  {
    name: 'buyValue',
    value: 'bond.portfolio.bondInformation.BUY_VALUE',
    unit: 'bond.portfolio.bondInformation.BUY_VALUE_UNIT',
    keySort: 'buyValue',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'extraDiscounts',
    value: 'bond.portfolio.bondInformation.EXTRA_DISCOUNTS',
    keySort: 'extraDiscounts',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 135,
      },
    },
  },
  {
    name: 'unallocatedExtra',
    value: 'bond.portfolio.bondInformation.UNALLOCATED_EXTRA',
    keySort: 'unallocatedExtra',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 135,
      },
    },
  },
  {
    name: 'lastPaymentDate',
    value: 'bond.portfolio.bondInformation.LAST_PAYMENT_DATE',
    keySort: 'lastPaymentDate',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'accruedInterest',
    value: 'bond.portfolio.bondInformation.ACCRUED_INTEREST',
    keySort: 'accruedInterest',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 135,
      },
    },
  },
  {
    name: 'bookValue',
    value: 'bond.portfolio.bondInformation.BOOK_VALUE',
    keySort: 'bookValue',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 135,
      },
    },
  },
  {
    name: 'ytm',
    value: 'bond.portfolio.bondInformation.YTM',
    keySort: 'ytm',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 100,
      },
    },
  },
  {
    name: 'dirtyPrice',
    value: 'bond.portfolio.bondInformation.DIRTY_PRICE',
    unit: 'bond.portfolio.bondInformation.BUY_PRICE_UNIT',
    keySort: 'dirtyPrice',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'cleanPrice',
    value: 'bond.portfolio.bondInformation.CLEAN_PRICE',
    unit: 'bond.portfolio.bondInformation.BUY_PRICE_UNIT',
    keySort: 'cleanPrice',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'totalValue',
    value: 'bond.portfolio.bondInformation.TOTAL_ESTIMATED_VALUE',
    keySort: 'totalValue',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 135,
      },
    },
  },
  {
    name: 'profitLoss',
    value: 'bond.portfolio.bondInformation.PROFIT_LOSS',
    keySort: 'profitLoss',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 145,
      },
    },
  },
  {
    name: 'remainDuration',
    value: 'bond.portfolio.bondInformation.REMAINING_DURATION',
    keySort: 'remainDuration',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 100,
      },
    },
  },
  {
    name: 'lastTradingDateId',
    value: 'bond.portfolio.bondInformation.LAST_TRADING_DATE',
    keySort: 'lastTradingDateId',
    attrs: {
      style: {
        minWidth: 160,
      },
    },
  },
  {
    name: 'lastRBD0001',
    value: 'bond.portfolio.bondInformation.LAST_YTM',
    keySort: 'lastRBD0001',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 60,
      },
    },
  },
  {
    name: 'lastDirtyPrice',
    value: 'bond.portfolio.bondInformation.LAST_DIRTY_PRICE',
    keySort: 'nextPaymentDate',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 140,
      },
    },
  },
  {
    name: 'lastCleanPrice',
    value: 'bond.portfolio.bondInformation.LAST_CLEAN_PRICE',
    keySort: 'nextPaymentDate',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 140,
      },
    },
  },
  {
    name: 'rbD0003',
    value: 'bond.portfolio.bondInformation.MACAULAY_DURATION',
    keySort: 'rbD0003',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'rbD0004',
    value: 'bond.portfolio.bondInformation.MODIFIED_DURATION',
    keySort: 'rbD0004',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'rbD0005',
    value: 'bond.portfolio.bondInformation.CONVEXITY',
    unit: 'bond.portfolio.bondInformation.CONVEXITY_UNIT',
    keySort: 'rbD0005',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'rbD0006',
    value: 'bond.portfolio.bondInformation.PVBP',
    keySort: 'rbD0006',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 80,
      },
    },
  },
  {
    name: 'creditQ',
    value: 'bond.portfolio.bondInformation.CREDIT_Q',
    keySort: 'creditQ',
    tooltip: 'bond.portfolio.bondInformation.TOOLTIP_2',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 120,
      },
    },
  },
  {
    name: 'weighted',
    value: 'bond.portfolio.bondInformation.WEIGHTED',
    keySort: 'weighted',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 100,
      },
    },
  },
  {
    name: 'bondEventTypeName',
    value: 'bond.portfolio.bondInformation.CREDIT_EVENT',
    keySort: 'bondEventTypeName',
    justifyContent: 'justify-content-end',
    attrs: {
      style: {
        minWidth: 165,
      },
    },
  },
]

export const COLORS = [
  '#45B29D',
  '#E8B714',
  '#F58D49',
  '#DF5A49',
  '#3CABEB',
  '#185999',
  '#906251',
  '#3DA967',
  '#FA9AA7',
  '#9C54E4',
  '#ECFFDF',
]

export const MAX_LENGTH = 100

export const WATCHLIST_TYPES = {
  CREATE: 1,
  UPDATE: 2,
}
