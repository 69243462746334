export const filing = {
  FILTER: 'Bộ lọc',
  FILING: 'Tài liệu cổ đông',
  CATEGORY: 'Loại hồ sơ',
  SUB_CATEGORY: 'Loại hồ sơ chi tiết',
  ALL_CATEGORY: 'Tất cả',
  ALL_SOURCES: 'Tất cả',
  TIME: 'Thời gian',
  selectTime: {
    SEVEN_DAYS: '7 ngày trước',
    LAST_MONTH: 'Tháng trước',
    LAST_THREE_MONTH: '3 tháng trước',
    LAST_SIX_MONTH: '6 tháng trước',
    LAST_YEAR: 'Năm trước',
    ALL: 'Tất cả',
    CUSTOM: 'Tùy chọn',
  },
  indicators: {
    FILING_DATE: 'Ngày Công bố Thông tin',
    DOCUMENT_TITLE: 'Tiêu đề hồ sơ',
    CATEGORY: 'Loại hồ sơ',
    SUB_CATEGORY: 'Loại hồ sơ chi tiết',
    YEAR: 'Năm',
    QUARTER: 'Quý',
    DOWNLOAD: 'Tải tệp tin',
  },
}
