import { createAsyncThunk } from '@reduxjs/toolkit'
import FinancialChartDataService from '../../../../../core/services/financialChart/FinancialChartDataService'

import { LIST_TYPE, VIEW_TYPE } from '../../constants'
import {
  getEconomyData,
  getEconomyPayloadAndPromises,
  getSecuritiesData,
  getSecuritiesPayloadAndPromises,
  getTimeFrequencyByIndicator,
} from '../../helpers'

export const getDataCompany = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_COMPANY',
  async (data, { rejectWithValue }) => {
    const payloads = getSecuritiesPayloadAndPromises(data, 'OrganizationId')
    const promises = payloads.map((payload) =>
      FinancialChartDataService.getDataCompany(payload),
    )
    const response = await Promise.allSettled(promises)

    if (response) {
      return getSecuritiesData(
        response,
        payloads,
        data.ViewType,
        LIST_TYPE.COMPANIES,
        'OrganizationId',
      )
    }
    return rejectWithValue('Error')
  },
)

export const getDataEconomy = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_ECONOMY',
  async (data, { rejectWithValue }) => {
    const payloads = getEconomyPayloadAndPromises(data)
    const promises = payloads.map((payload) =>
      FinancialChartDataService.getDataEconomy(payload),
    )
    const response = await Promise.allSettled(promises)

    if (response) {
      return getEconomyData(response, payloads, data.ViewType)
    }
    return rejectWithValue('Error')
  },
)

export const getDataSector = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_SECTOR',
  async (data, { rejectWithValue }) => {
    const payloads = getSecuritiesPayloadAndPromises(data, 'ICBId')
    const promises = payloads.map((payload) =>
      FinancialChartDataService.getDataSector(payload),
    )
    const response = await Promise.allSettled(promises)

    if (response) {
      return getSecuritiesData(
        response,
        payloads,
        data.ViewType,
        LIST_TYPE.SECTOR,
        'ICBId',
      )
    }
    return rejectWithValue('Error')
  },
)

export const getDataIndex = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_INDEX',
  async (data, { rejectWithValue }) => {
    const payloads = getSecuritiesPayloadAndPromises(data, 'GroupId')
    const promises = payloads.map((payload) =>
      FinancialChartDataService.getDataIndex(payload),
    )
    const response = await Promise.allSettled(promises)

    if (response) {
      return getSecuritiesData(
        response,
        payloads,
        data.ViewType,
        LIST_TYPE.INDICES,
        'GroupId',
      )
    }

    return rejectWithValue('Error')
  },
)

export const getDataAggregate = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_AGGREGATE',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDataService.getDataAggregate(data)
    if (response.success) {
      if (data.viewType === VIEW_TYPE.BY_TIME) {
        return response.data
      } else {
        return { [data.equationId]: { ...(response.data[0] || {}) } }
      }
    }
    return rejectWithValue('Error')
  },
)

export const getDataCount = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_COUNT',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDataService.getDataCount(data)
    if (response.success) {
      if (data.viewType === VIEW_TYPE.BY_TIME) {
        return response.data
      } else {
        return { [data.equationId]: { ...(response.data[0] || {}) } }
      }
    }
    return rejectWithValue('Error')
  },
)

export const getListIndicatorLatestPeriod = createAsyncThunk(
  'financialChart/dashboard/data/LIST_INDICATOR_LATEST_PERIOD',
  async (data, { rejectWithValue }) => {
    const promises = []
    data.forEach((item) =>
      promises.push(FinancialChartDataService.getDataLatestPeriod(item)),
    )
    const responses = await Promise.allSettled(promises)

    if (responses) {
      const results = {}
      responses.forEach((res, index) => {
        const item = data[index]
        if (res.status === 'fulfilled' && res.value.success) {
          results[`${item.indicatorId}_${getTimeFrequencyByIndicator(item)}`] =
            res.value.data
        }
      })
      return results
    }
    return rejectWithValue('Error')
  },
)

export const getDataQuery = createAsyncThunk(
  'financialChart/dashboard/data/GET_DATA_QUERY',
  async (data, { rejectWithValue }) => {
    const response = await FinancialChartDataService.getDataQuery(data)

    if (response) {
      return response.data
    }
    return rejectWithValue('Error')
  },
)
