import { COMPONENT } from '../../../configs/Layout'
import { withWrapper } from '../../common/withWrapper'
import { HEADER_PANEL_HEIGHT, PANEL_PADDING } from '../../constants/Common'
import { formatNumber } from '../../utils/Common'
import PanelDetailOwnership from './PanelDetailOwnership'
import PanelStructureOwnership from './PanelStructureOwnership'

const MAP_KEY = {
  CHARTS: 'CHARTS',
  INFO: 'INFO',
}

const MAP_SIZE = {
  [`${MAP_KEY.CHARTS}`]: {
    width: `calc(100% / 4 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: 0,
    minWidth: 280,
    minHeight: 600,
    minTop: 0,
    minLeft: 0,
    verticalResize: [
      {
        key: MAP_KEY.INFO,
        ratioWidth: 1,
        ratioLeft: 1,
      },
    ],
    disableVerticalResize: false,
    disableHorizontalResize: true,
  },
  [`${MAP_KEY.INFO}`]: {
    width: `calc(300% / 4 - ${COMPONENT.MARGIN}px)`,
    height: `calc(100% - ${COMPONENT.MARGIN}px)`,
    top: 0,
    left: `calc(100% / 4 + ${COMPONENT.MARGIN}px)`,
    minWidth: 280 * 3,
    minHeight: 600,
    minTop: 0,
    minLeft: 280 + 2 * COMPONENT.MARGIN,
    disableVerticalResize: true,
    disableHorizontalResize: true,
  },
}

const panelRefs = {}

const Content = ({ sizes, setSizes }) => {
  return (
    <>
      <PanelStructureOwnership
        panelRefs={panelRefs}
        panelKey={MAP_KEY.CHARTS}
        sizes={sizes}
        setSizes={setSizes}
      />
      <PanelDetailOwnership
        panelRefs={panelRefs}
        panelKey={MAP_KEY.INFO}
        sizes={sizes}
        setSizes={setSizes}
        width={formatNumber(sizes[MAP_KEY.INFO].width) - PANEL_PADDING}
        height={formatNumber(sizes[MAP_KEY.INFO].height) - HEADER_PANEL_HEIGHT}
      />
    </>
  )
}

export default withWrapper(MAP_SIZE, panelRefs)(Content)
