import { chain, sumBy } from 'lodash'
import { I18n } from 'react-redux-i18n'
import { uuid } from '../../../../utils/Common'
import { valDivBillion } from '../../../../utils/Value'
import { TIME_FREQUENCY } from './constants'

export const renderNameChart = (value) => {
  switch (value) {
    case 1:
      return 'bond.corporateBond.remainingMaturities.UNDER_SIX_PERCENTS'
    case 2:
      return 'bond.corporateBond.remainingMaturities.SIX_TO_NINE_PERCENTS'
    case 3:
      return 'bond.corporateBond.remainingMaturities.NINE_TO_TWELVE_PERCENTS'
    case 4:
      return 'bond.corporateBond.remainingMaturities.TWELVE_TO_FIFTEEN_PERCENTS'
    case 5:
      return 'bond.corporateBond.remainingMaturities.OVER_FIFTEEN_PERCENTS'
    default:
      return ''
  }
}

export const renderName = (value) => {
  switch (value) {
    case 1:
      return 'bond.corporateBond.remainingMaturities.UNDER_ONE_YEAR'
    case 2:
      return 'bond.corporateBond.remainingMaturities.ONE_TWO_THREE_YEARS'
    case 3:
      return 'bond.corporateBond.remainingMaturities.THREE_TWO_FIVE_YEARS'
    case 4:
      return 'bond.corporateBond.remainingMaturities.FIVE_TO_SEVEN_YEARS'
    case 5:
      return 'bond.corporateBond.remainingMaturities.OVER_SEVEN_YEARS'
    default:
      return ''
  }
}

export const renderTimeType = (timeType) => {
  switch (timeType) {
    case TIME_FREQUENCY.MONTHLY:
      return I18n.t('bond.corporateBond.issuers.MTD')
    case TIME_FREQUENCY.QUARTER:
      return I18n.t('bond.corporateBond.issuers.QTD')
    default:
      return I18n.t('bond.corporateBond.issuers.YTD')
  }
}

export const transferText = (text) => {
  switch (text) {
    case 'Private placement':
    case 'Phát hành riêng lẻ':
      return I18n.t('bond.corporateBond.issuers.PRIVATE')
    case 'Public placement':
    case 'Phát hành công chúng':
      return I18n.t('bond.corporateBond.issuers.PUBLIC')
    case 'Đối tượng chính':
    case 'Main subject':
      return '--'
    default:
      return text
  }
}

export const formatDataTable = ({ data, type }) => {
  if (!data || !data.length) return []

  const newData = data.map((item) => ({
    ...item,
    year:
      type === TIME_FREQUENCY.QUARTER
        ? `Q${item.realQuarter}/${item.realYear}`
        : `${item.realYear}`,
  }))

  const temp = newData.map((item) => item.year).filter((year) => year)

  const years = temp.filter((item, index) => temp.indexOf(item) === index)

  let bond = {}
  let short = {}
  let long = {}

  years.forEach((year) => {
    const find = newData.find((item) => item.year === year)

    bond = {
      ...bond,
      [find.year]: find.bond,
    }
    short = {
      ...short,
      [find.year]: find.bS76 - find.nC198,
    }
    long = {
      ...long,
      [find.year]: find.bS89 + find.bS90 - find.nC115,
    }
  })

  const shortTerm = chain(newData.filter((item) => item.typeTerm === 1))
    .groupBy('organizationShortName')
    .map((value, key) => ({
      ...value[0],
      ...chain(value).keyBy('year').mapValues('loanValue').value(),
    }))
    .value()

  const longTerm = chain(newData.filter((item) => item.typeTerm === 2))
    .groupBy('organizationShortName')
    .map((value, key) => ({
      ...value[0],
      ...chain(value).keyBy('year').mapValues('loanValue').value(),
    }))
    .value()

  const array = [
    {
      ...bond,
      id: 111,
      name: 'bond.corporateBond.debtDetails.BOND',
      level: 1,
      parentId: 111,
      childIds: [],
    },
    {
      ...short,
      id: 222,
      name: 'bond.corporateBond.debtDetails.SHORT_TERM',
      level: 1,
      parentId: 222,
      childIds: shortTerm,
    },
    {
      ...long,
      id: 333,
      name: 'bond.corporateBond.debtDetails.LONG_TERM',
      level: 1,
      parentId: 333,
      childIds: longTerm,
    },
  ]

  shortTerm.forEach((item) => {
    const {
      organizationShortName,
      loanValue,
      typeTerm,
      lendingOrganizationId,
      ...rest
    } = item

    array.push({
      ...rest,
      loanValue: loanValue,
      id: `${lendingOrganizationId === -1 ? 'ABC' : lendingOrganizationId}-222`,
      name: organizationShortName,
      level: 2,
      parentId: 222,
      childIds: [],
      lendingOrganizationId,
    })
  })
  longTerm.forEach((item) => {
    const {
      organizationShortName,
      loanValue,
      typeTerm,
      lendingOrganizationId,
      ...rest
    } = item

    array.push({
      ...rest,
      loanValue: loanValue,
      id: `${lendingOrganizationId === -1 ? 'ABC' : lendingOrganizationId}-333`,
      name: organizationShortName,
      level: 2,
      parentId: 333,
      childIds: [],
      lendingOrganizationId,
    })
  })

  const half111 = array.filter((item) => item.parentId === 111)
  const half222 = array.filter((item) => item.parentId === 222)
  const index222 = half222.findIndex((item) => item.id === 'ABC-222')
  half222.push(half222.splice(index222, 1)[0])

  const half333 = array.filter((item) => item.parentId === 333)
  const index333 = half333.findIndex((item) => item.id === 'ABC-333')
  half333.push(half333.splice(index333, 1)[0])

  const yearsValue = years.map((year) => ({
    [year]: sumBy(
      array.filter((item) => typeof item.id === 'number'),
      (item) => parseFloat(item?.[year] ?? 0),
    ),
  }))

  const totalItem = {
    name: 'bond.corporateBond.bondIssueInformation.SUMMARY',
    level: 1,
    parentId: 444,
    id: 444,
  }

  yearsValue.forEach((item) => {
    Object.keys(item).forEach((key) => {
      totalItem[key] = item[key]
    })
  })

  array.push(totalItem)

  return [...half111, ...half222, ...half333, totalItem]
}

export const formatDataTableLending = (data) => {
  if (!data || !data.length) return []

  const array = []

  data.forEach((item) => {
    const {
      lendingOrganizationId,
      loanOrganizationShortName,
      organizationShortName,
      loanValue,
      ...rest
    } = item

    array.push({
      ...rest,
      id: uuid(),
      loanName: loanOrganizationShortName,
      name: organizationShortName,
      loanValue: valDivBillion(loanValue),
    })
  })

  return array
}
export const formatDataTableLoan = (data) => {
  if (!data || !data.length) return []

  const array = []

  data.forEach((item) => {
    const {
      lendingOrganizationId,
      loanOrganizationShortName,
      organizationShortName,
      loanValue,
      ...rest
    } = item

    array.push({
      ...rest,
      id: uuid(),
      loanName: loanOrganizationShortName,
      name: organizationShortName,
      loanValue: valDivBillion(loanValue),
    })
  })

  return array
}
