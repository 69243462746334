import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import Dropdown from '../../../../../common/dropdown'
import { HandleClickOutside } from '../../../../../common/HandleClickOutside'
import { Input } from '../../../../../common/html/Input'
import TextEllipsis from '../../../../../common/textEllipsis'
import Tree from '../../../../../common/tree'
import { EMPTY_VALUE, Z_INDEX } from '../../../../../constants/Common'
import { changeFilter } from '../../../fdiByIndustries/investmentStructure/store/slice'

import {
  TABLE_ROW_LEVEL,
  TABLE_SECTOR_ID,
} from '../../totalRegisteredFDI/constant'
import {
  changeLocationChecked,
  selectLocationChecked,
  selectLocationName,
  setIsSpreadTable,
} from '../../totalRegisteredFDI/store/slice'
import style from './style.module.css'

const FilterCountry = () => {
  const dispatch = useDispatch()
  const parentDropdownRef = useRef()
  const dropdownRef = useRef()
  const listLocationName = useSelector(selectLocationName)
  const locationChecked = useSelector(selectLocationChecked)

  const [isShow, setIsShow] = useState(false)
  const [keySearch, setKeySearch] = useState('')

  const toggleDropSearch = () => {
    setIsShow(!isShow)
  }

  const hideDropSearch = () => {
    setIsShow(false)
    setKeySearch('')
  }

  const getListTrue = () => {
    const listTrue = []
    let object = {}
    listLocationName.forEach((i) => {
      const level =
        i.locationLevel === 1
          ? TABLE_ROW_LEVEL.LEVEL_1
          : TABLE_ROW_LEVEL.LEVEL_2
      if (level === TABLE_ROW_LEVEL.LEVEL_1) {
        const children = listLocationName
          .filter(
            (v) => v.parentLocationId === i.locationId && v.locationLevel !== 1,
          )
          .map((e) => ({
            ...e,
            groupName: e.locationName,
            children: {},
          }))
        listTrue.push({
          [i.locationName]: {
            ...i,
            groupName: i.locationName,
            children: children,
          },
        })
      }
    })
    listTrue.forEach((i) => (object = { ...object, ...i }))
    return object
  }

  const onChangeFilterStatus = (value) => {
    dispatch(setIsSpreadTable(true))
    setTimeout(() => {
      const scrollTable = document.querySelector(
        `#${TABLE_SECTOR_ID} .scrollbars div:first-child`,
      )
      const headerTable = scrollTable?.querySelector('thead')
      const activeRow = scrollTable?.querySelector(`[id="${value.locationId}"]`)
      if (scrollTable && headerTable && activeRow)
        scrollTable.scrollTo({
          top: activeRow.offsetTop - headerTable.offsetHeight,
          behavior: 'smooth',
        })
      dispatch(setIsSpreadTable(false))
    }, 500)
    dispatch(changeFilter(value))
    hideDropSearch()
  }

  const handleClick = (item) => {
    dispatch(changeLocationChecked(item))
    hideDropSearch()
    onChangeFilterStatus(item)
  }

  return (
    <div className="input-dropdown" ref={parentDropdownRef}>
      <span
        className={`span-input w-200 ${style.inputDropdown} `}
        style={{ padding: '2px 20px 2px 8px' }}
        ref={dropdownRef}
        onClick={toggleDropSearch}
      >
        <TextEllipsis
          isI18n={false}
          text={locationChecked.locationName || EMPTY_VALUE}
        />
        <i className={!isShow ? 'icon-arrow-down' : 'icon-arrow-up'} />
      </span>
      <Dropdown parentRef={parentDropdownRef} isShow={isShow}>
        <HandleClickOutside
          handleClickOutside={hideDropSearch}
          exceptRef={dropdownRef}
        >
          <div className="input-dropdown" style={{ marginTop: 2 }}>
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width: dropdownRef?.current?.clientWidth }}
            >
              <div
                className="form-search-inbox"
                style={{ width: 'auto', margin: '4px 8px 8px 8px' }}
              >
                <Input
                  style={{
                    fontSize: 11,
                  }}
                  type="text"
                  className={`search-inbox ${style.inputSearch}`}
                  placeholder={I18n.t('common.SEARCH')}
                  onChange={(e) => setKeySearch(e.target.value?.toLowerCase())}
                />
                <button type="button">
                  <i className="icon-search-2" />
                </button>
              </div>
              <div style={{ height: 300, paddingLeft: 8 }}>
                <Tree
                  valueSearch={keySearch}
                  treeData={getListTrue()}
                  nameKey={['groupName']}
                  defaultOpenLevel1
                  disabledCheckDbClick
                  onClickItem={handleClick}
                  doubleClickItem={undefined}
                  zIndexTextEllipsis={Z_INDEX.DROPDOWN}
                />
              </div>
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </div>
  )
}
export default FilterCountry
