import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import { HandleClickOutside } from '../../../common/HandleClickOutside'
import { ScrollComponent } from '../../../common/ScrollComponent'
import Dropdown, { positionDropdown } from '../../../common/dropdown'
import { Span } from '../../../common/html/Span'
import TextEllipsis from '../../../common/textEllipsis'
import EventEmitter, {
  DROPDOWN_RESET_POSITION,
} from '../../../utils/EventEmitter'
import { ALL_VALUE } from '../Screening/constants'
import style from './index.module.css'

const FilterMultiSelect = ({
  data,
  width,
  initialValue,
  handleChange,
  isHaveAllValue,
  maxItemDropdown,
  heightItem,
}) => {
  const parentRef = useRef()

  const isDropdownTop =
    window.innerHeight - parentRef.current?.getBoundingClientRect()?.bottom <
    maxItemDropdown * heightItem

  const [isShowDropdown, setIsShowDropdown] = useState(false)
  const [list, setList] = useState([])

  const handleDropdown = () => {
    EventEmitter.dispatch(DROPDOWN_RESET_POSITION)
    setIsShowDropdown(!isShowDropdown)
  }

  const handleHideDropdown = () => setIsShowDropdown(false)

  const handleClickAllItem = () => {
    handleChange(
      !list?.length
        ? isHaveAllValue
          ? [ALL_VALUE]
          : data?.map((item) => item.value)
        : [],
      initialValue?.indicatorId,
    )
  }

  const handleClickItem = (value) => {
    const filter = !list.includes(value)
      ? [...list, value]
      : list.filter((item) => item !== value)

    handleChange(
      filter?.length === data?.length && isHaveAllValue ? [ALL_VALUE] : filter,
      initialValue?.indicatorId,
    )
  }

  useEffect(() => {
    setList(
      !!initialValue?.conditionValues?.length
        ? initialValue?.conditionValues.includes(ALL_VALUE)
          ? data?.map((item) => item.value)
          : initialValue?.conditionValues
        : [],
    )
  }, [initialValue?.conditionValues])

  return (
    <>
      <div className={`input-dropdown ${style.input}`} ref={parentRef}>
        <span
          className={`span-input ${style.inputDropdown} `}
          style={{
            padding: '2px 0',
            width: '100%',
            gap: 8,
            height: '100%',
            boxSizing: 'border-box',
          }}
          onClick={handleDropdown}
        >
          <div style={{ flex: 1, overflow: 'hidden' }}>
            {!!list?.length ? (
              list.length === data?.length ? (
                <Translate value="bond.corporateBond.priceBoard.ALL" />
              ) : (
                <TextEllipsis
                  text={data
                    .filter((item) => list.includes(item.value))
                    .map((item) => item.text)
                    .join(', ')}
                  zIndexTooltip={999}
                />
              )
            ) : (
              <Span style={{ opacity: 0 }}>
                <Translate value="bond.corporateBond.priceBoard.ALL" />
              </Span>
            )}
          </div>
          <i
            className={!isShowDropdown ? 'icon-arrow-down' : 'icon-arrow-up'}
            style={{ position: 'static' }}
          />
        </span>
      </div>
      <Dropdown
        isShow={isShowDropdown}
        parentRef={parentRef}
        position={
          isDropdownTop
            ? positionDropdown.TOP_LEFT_RIGHT
            : positionDropdown.BOTTOM_LEFT_RIGHT
        }
      >
        <HandleClickOutside
          handleClickOutside={handleHideDropdown}
          exceptRef={parentRef}
        >
          <div
            className={`${
              isDropdownTop ? style.dropdownTop : style.dropdownBottom
            }`}
            style={{
              width: `${parentRef.current?.getBoundingClientRect()?.width}px`,
            }}
          >
            <div
              className={`dropdown-container ${style.dropdownContainerSearch}`}
              style={{ width }}
            >
              {!!data?.length ? (
                <ScrollComponent autoHeight={true} autoHeightMax={200}>
                  <ul className="list-check">
                    <li style={{ margin: 0 }} className={style.liItem}>
                      <a
                        style={{ display: 'block', padding: '4px 8px' }}
                        className="w-100"
                      >
                        <label
                          className="d-flex ali-center"
                          style={{
                            cursor: 'pointer',
                          }}
                        >
                          <input
                            type="checkbox"
                            className="checkbox mr-8"
                            checked={list?.length === data?.length}
                            onChange={handleClickAllItem}
                          />
                          <TextEllipsis text="common.ALL" zIndexTooltip={999} />
                        </label>
                      </a>
                    </li>
                    {data.map((item, index) => {
                      return (
                        <li
                          key={`key-${index}`}
                          style={{ margin: 0 }}
                          className={style.liItem}
                        >
                          <a
                            style={{ display: 'block', padding: '4px 8px' }}
                            className="w-100"
                          >
                            <label
                              className="d-flex ali-center"
                              style={{
                                cursor: item.value ? 'pointer' : 'default',
                              }}
                            >
                              {item.value && (
                                <input
                                  type="checkbox"
                                  className="checkbox mr-8"
                                  checked={list?.includes(item.value) ?? false}
                                  onChange={() => handleClickItem(item.value)}
                                />
                              )}
                              <TextEllipsis
                                text={item.text}
                                zIndexTooltip={999}
                                isI18n={false}
                              />
                            </label>
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </ScrollComponent>
              ) : (
                <div className={style.noData}>
                  <Span style={{ fontSize: 10 }}>
                    <Translate value="common.NO_DATA" />
                  </Span>
                </div>
              )}
            </div>
          </div>
        </HandleClickOutside>
      </Dropdown>
    </>
  )
}

FilterMultiSelect.defaultProps = {
  maxItemDropdown: 6,
  heightItem: 24,
}

export default FilterMultiSelect
