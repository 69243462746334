import {
  COLORS_PIE_CHART_FIVE_ITEMS,
  COLORS_PIE_CHART_SIX_ITEMS,
} from './constants'

export const formatChartData = (data, dataKey, dataName) => {
  const dataLength = data.length

  const result = data.map((item, index) => ({
    text: item[dataName],
    value: item[dataKey],
    color:
      dataLength === 6
        ? COLORS_PIE_CHART_SIX_ITEMS[index]
        : COLORS_PIE_CHART_FIVE_ITEMS[index],
  }))

  return result
}
