export const SEARCH_TYPE = {
  ALL: 'all',
  NEW: 'new',
  COMPANY: 'company',
  SECTOR: 'sector',
  FUNCTION: 'function',
  INDICE: 'indice',
  BOND: 'bond',
  DERIVATIVE: 'derivative',
}

const COMPANY_GROUP_CODE_OPTIONS = (features) => ({
  ownership: {
    label: 'search.fullSearch.OWNERSHIP',
    url: features.corporate.components.ownership.route,
  },
  segmentalAnalysis: {
    label: 'search.fullSearch.SEGMENTAL_ANALYSIS',
    url: features.corporate.components['business-model'].components[
      'segmental-analysis'
    ].route,
  },
  penetrationAnalysis: {
    label: 'search.fullSearch.PENETRATION_ANALYSIS',
    url: features.corporate.components['business-model'].components[
      'penetration-analysis'
    ].route,
  },
  costOperation: {
    label: 'search.fullSearch.COST_OF_OPERATION',
    url: features.corporate.components['business-model'].components[
      'cost-of-operation'
    ].route,
  },
  financialRatios: {
    label: 'search.fullSearch.FINANCIAL_RATIOS',
    url: features.corporate.components['financial-data'].components[
      'financial-ratios'
    ].route,
  },
  financialStatement: {
    label: 'search.fullSearch.FINANCIAL_STATEMENT',
    url: features.corporate.components['financial-data'].components[
      'financial-statement'
    ].route,
  },
  dividend: {
    label: 'search.fullSearch.DIVIDEND',
    url: features.corporate.components['financial-data'].components.dividend
      .route,
  },
  peersValuation: {
    label: 'search.fullSearch.PEERS_VALUATION',
    url: features.corporate.components['peers-valuation'].components.overview
      .route,
  },
  deepTransaction: {
    label: 'search.fullSearch.DEEP_TRANSACTION',
    url: features.market.components['equity-trading'].components[
      'deep-transaction'
    ].route,
  },
  priceStatistics: {
    label: 'search.fullSearch.PRICE_STATISTICS',
    url: features.market.components['equity-trading'].components[
      'price-statistics'
    ].route,
  },
  technicalChart: {
    label: 'search.fullSearch.TECHNICAL_CHART',
    url: features['technical-chart'].route,
  },
})

export const COMPANY_HOHAUP_OPTIONS = (features) => {
  const options = COMPANY_GROUP_CODE_OPTIONS(features)

  return [
    options.ownership,
    // options.segmentalAnalysis,
    // options.penetrationAnalysis,
    // options.costOperation,
    options.financialRatios,
    options.financialStatement,
    options.dividend,
    options.peersValuation,
    options.deepTransaction,
    options.priceStatistics,
    options.technicalChart,
  ]
}

export const COMPANY_OTC_PRIVATE_OPTIONS = (features) => {
  const options = COMPANY_GROUP_CODE_OPTIONS(features)

  return [
    options.ownership,
    // options.segmentalAnalysis,
    // options.penetrationAnalysis,
    // options.costOperation,
    options.financialRatios,
    options.financialStatement,
    options.dividend,
    options.peersValuation,
  ]
}

export const SECTOR_OPTIONS = (features) => [
  {
    label: 'search.fullSearch.FINANCIAL_ANALYSIS',
    url: features.sector.components['sector-constituents'].components[
      'financial-analysis'
    ].route,
  },
  {
    label: 'search.fullSearch.SEGMENTATION',
    url: features.sector.components['sector-constituents'].components
      .segmentation.route,
    disabled: true,
  },
  {
    label: 'search.fullSearch.RANKING',
    url: features.sector.components['sector-constituents'].components.ranking
      .route,
  },
  {
    label: 'search.fullSearch.RATIO',
    url: features.sector.components['sector-constituents'].components.ratio
      .route,
  },
  {
    label: 'search.fullSearch.VALUATION',
    url: features.sector.components['sector-constituents'].components.valuation
      .route,
  },
]

const INDICE_GROUP_CODE_OPTIONS = (features) => ({
  foreign: {
    label: 'search.fullSearch.FOREIGN',
    url: features.market.components['market-in-depth'].components.foreign.route,
  },
  directorDeal: {
    label: 'search.fullSearch.DIRECTOR_DEAL',
    url: features.market.components['market-in-depth'].components[
      'director-deal'
    ].route,
  },
  indexStatistics: {
    label: 'search.fullSearch.INDEX_STATISTICS',
    url: features.market.components['market-in-depth'].components[
      'index-statistics'
    ].route,
  },
  valuation: {
    label: 'search.fullSearch.VALUATION',
    url: features.market.components['market-in-depth'].components.valuation
      .route,
  },
  sectorStatistics: {
    label: 'search.fullSearch.SECTOR_STATISTICS',
    url: features.market.components['sector-statistics'].route,
  },
  localInstitution: {
    label: 'search.fullSearch.LOCAL_INSTITUTION',
    url: features.market.components['market-in-depth'].components.proprietary
      .route,
  },
})

export const INDICE_INDEX_OPTIONS = (features) => {
  const options = INDICE_GROUP_CODE_OPTIONS(features)

  return [
    options.foreign,
    options.directorDeal,
    options.indexStatistics,
    options.valuation,
    options.sectorStatistics,
  ]
}

export const INDICE_HOSE_OPTIONS = (features) => {
  const options = INDICE_GROUP_CODE_OPTIONS(features)

  return [
    options.foreign,
    options.localInstitution,
    options.directorDeal,
    options.indexStatistics,
    options.valuation,
  ]
}

export const INDICE_HNX_UPCOM_OPTIONS = (features) => {
  const options = INDICE_GROUP_CODE_OPTIONS(features)

  return [
    options.foreign,
    options.directorDeal,
    options.indexStatistics,
    options.valuation,
  ]
}
