import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ChannelConfig } from '../../../../configs/Global'
import { GROUP_TYPE } from '../../../constants/Common'
import { Realtime } from '../../Realtime'
import AdditionalInfoPrice from '../components/AdditionalInfoPrice'
import SummaryInfoPrice from '../components/SummaryInfoPrice'
import {
  selectBasicInfo,
  selectPriceData,
  subscribeBidAsk,
  subscribeStock,
} from './store/slice'
import { getLatestPrice } from './store/thunk'

export const PriceData = () => {
  const dispatch = useDispatch()
  const basicInfo = useSelector(selectBasicInfo)
  const priceData = useSelector(selectPriceData)

  useEffect(() => {
    if (basicInfo.organizationId) {
      dispatch(
        getLatestPrice({
          Ids: basicInfo.organizationId,
          GroupType: GROUP_TYPE.STOCK,
        }),
      )
    }
  }, [basicInfo.organizationId])

  return (
    <div className="info-box">
      <Realtime
        channel={ChannelConfig.StockChannel + basicInfo.organizationId}
        action={subscribeStock}
      >
        <Realtime
          channel={ChannelConfig.BidAskChannel + basicInfo.organizationId}
          action={subscribeBidAsk}
        >
          <SummaryInfoPrice data={{ ...priceData, isTicker: true }} />
          <AdditionalInfoPrice data={{ ...priceData, isTicker: true }} />
        </Realtime>
      </Realtime>
    </div>
  )
}
