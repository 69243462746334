import { createSlice } from '@reduxjs/toolkit'
import { keyBy } from '../../../../../../utils/Common'
import { register } from '../../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../../utils/Value'
import { getDataThunk } from './thunk'

const name = 'bond/interConnectionMap/infomation'
const initialState = {
  loading: false,
  data: [],
  ids: [],
}

const slice = createSlice({
  name: name,
  initialState,
  reducers: {
    updateTab: (state, action) => {
      state.tabActive = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getDataThunk.fulfilled, (state, action) => {
      state.data = keyBy(action.payload, 'bondId')
      state.ids = action.payload.map((e) => e?.bondId)
      state.loading = false
    })
    builder.addCase(getDataThunk.rejected, (state, action) => {
      state.data = {}
      state.ids = []
      state.loading = action.payload
    })
  },
})

export const selectLoading = (state) => state[slice.name].loading
export const selectData = (state) => state[slice.name].data
export const selectIds = (state) => state[slice.name].ids
export const selectTabActive = (state) => state[slice.name].tabActive

export const selectDataValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name]?.data?.[id], attr)
}

export const { updateTab } = slice.actions

register(slice.name, slice.reducer)
