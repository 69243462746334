import { useSelector } from 'react-redux'
import SelectCustom from '../../../common/selectCustom'
import style from '../index.module.css'
import { selectMaxDate } from '../marginLoansChart/store/slice'
import { QUARTERS_FILTER, YEARS_FILTER } from './constants'

export const QuarterAndYearSelection = ({
  handleYearChange,
  handleQuarterChange,
  currentYear,
  currentQuarter,
}) => {
  const maxDate = useSelector(selectMaxDate)

  const maxYear = maxDate?.year
  const currentYearByDateJS = new Date().getFullYear()

  const maxYearFilter =
    currentYearByDateJS > maxYear && maxYear
      ? YEARS_FILTER.slice(currentYearByDateJS - maxYear, YEARS_FILTER.length)
      : YEARS_FILTER

  return (
    <div className="row">
      <div className={`${style.inputDropdown}`} style={{ width: 120 }}>
        <SelectCustom
          value={currentQuarter}
          isI18n={true}
          selectData={QUARTERS_FILTER}
          handleChange={handleQuarterChange}
        />
      </div>
      <div
        className={`${style.inputDropdown}`}
        style={{ width: 84, marginLeft: 5 }}
      >
        <SelectCustom
          value={currentYear}
          isI18n={false}
          selectData={maxYearFilter}
          handleChange={handleYearChange}
        />
      </div>
    </div>
  )
}
