import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import TextEllipsis from '../../../../common/textEllipsis'
import { keyBy } from '../../../../utils/Common'
import style from '../../index.module.css'
import { key, selectParentChecked } from '../../store/slice'

const NameChecked = ({ exchanges, listAllChecked, listChecked }) => {
  const listParentChecked = useSelector(selectParentChecked(key.EXCHANGE))

  const [exchangesById, setExchangesById] = useState({})

  useEffect(() => {
    setExchangesById(keyBy(exchanges, 'groupId'))
  }, [exchanges])

  return (
    <div className={style.w85}>
      {listAllChecked?.length > 0 &&
      listAllChecked?.length === listChecked?.length ? (
        <Translate value="tool.smartScreening.ALL_EXCHANGE" />
      ) : listParentChecked.length > 0 ? (
        <TextEllipsis
          isI18n={false}
          text={listParentChecked
            .map((key) => exchangesById[key]?.groupName)
            .join('; ')}
        />
      ) : (
        <span className={style.opacity0}>
          <Translate value="tool.smartScreening.ALL_EXCHANGE" />
        </span>
      )}
    </div>
  )
}

NameChecked.propTypes = {
  exchanges: PropTypes.array.isRequired,
  listAllChecked: PropTypes.array.isRequired,
  listChecked: PropTypes.array.isRequired,
}

export default NameChecked
