import { useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Span } from '../../../../common/html/Span'
import { selectFilterParams } from '../../store/slice'
import style from '../index.module.css'

export const FilterTypeRatio = ({ data, handleChangeParams }) => {
  const params = useSelector(selectFilterParams)

  const initialParams = params.conditions.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  return (
    <div className="w-100" key={data.indicatorId}>
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          {`${data?.indicatorName ?? ''} ${data.unit ?? ''}`}
        </Span>
      </label>
      <div
        className="d-flex ali-center justify-content-space-between"
        style={{ gap: 8 }}
      >
        {!!data.selectionValues?.length &&
          data.selectionValues?.map(({ value, text }) => {
            return (
              <div
                key={value}
                className={`d-flex align-center cursor-pointer ${
                  value === initialParams?.conditionValues?.[0] &&
                  style.radioActive
                }`}
                onClick={() => handleChangeParams([value], data.indicatorId)}
              >
                <input
                  type="radio"
                  className="radiobox radiobox2"
                  checked={value === initialParams?.conditionValues?.[0]}
                  readOnly={true}
                />
                <Span style={{ fontSize: 12, fontWeight: 340 }}>
                  <Translate value={text} />
                </Span>
              </div>
            )
          })}
      </div>
    </div>
  )
}
