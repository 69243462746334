import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { TABLE_ROW_LEVEL } from './constants'
import { selectItemData } from './store/slice'

const OtherColumnsCell = ({ val, rowId }) => {
  const item = useSelector(selectItemData(rowId))

  if (item.level === TABLE_ROW_LEVEL.LEVEL_1) {
    return <td style={{ textAlign: 'right' }}>{val}</td>
  } else {
    return (
      <td
        style={{ textAlign: 'right', color: '#ececec66', fontStyle: 'italic' }}
      >
        {val}
      </td>
    )
  }
}

export default OtherColumnsCell

OtherColumnsCell.propTypes = {
  val: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rowId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
}
