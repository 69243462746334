import { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import useGetExchange from '../../../common/masterData/useGetExchange'
import useGetICB from '../../../common/masterData/useGetICB'
import { changeDisplayInfo } from '../../../common/topInfo/indexSectorTickerTopInfo/store/slice'
import {
  applyFilter,
  key,
  resetFilter,
  selectChecked,
  selectDate,
  selectParentChecked,
} from '../store/slice'
import MyWatchList from './MyWatchList'
import Company from './company'
import DateFilter from './dateFilter/DateFilter'
import EventType from './eventType'
import Exchange from './exchange'
import Sector from './sector'

const Filter = () => {
  const { ICBsTree, ICBs, loading: loadingICB } = useGetICB(true, true)
  const {
    exchangesTree,
    exchanges,
    loading: loadingExchange,
  } = useGetExchange(true, true)
  const exchangesTreeMemo = useMemo(() => {
    return exchangesTree
  }, [JSON.stringify(exchangesTree)])

  const ICBsTreeMemo = useMemo(() => {
    return ICBsTree
  }, [JSON.stringify(ICBsTree)])

  const dispatch = useDispatch()
  const listSectorChecked = useSelector(selectChecked(key.SECTOR))
  const listExchangeChecked = useSelector(selectChecked(key.EXCHANGE))
  const listParentSectorChecked = useSelector(selectParentChecked(key.SECTOR))
  const listParentExchangeChecked = useSelector(
    selectParentChecked(key.EXCHANGE),
  )
  const eventTypeChecked = useSelector(selectChecked(key.EVENT_TYPE))
  const companyChecked = useSelector(selectChecked(key.COMPANY))
  const exchangeChecked = useSelector(selectChecked(key.EXCHANGE))
  const icbChecked = useSelector(selectChecked(key.SECTOR))
  const wlChecked = useSelector(selectChecked(key.WATCHLIST))
  const startDate = useSelector(selectDate('startDate'))
  const endDate = useSelector(selectDate('endDate'))
  const startRecordDate = useSelector(selectDate('startRecordDate'))
  const endRecordDate = useSelector(selectDate('endRecordDate'))
  const startExrightDate = useSelector(selectDate('startExrightDate'))
  const endExrightDate = useSelector(selectDate('endExrightDate'))
  const startExerciseDate = useSelector(selectDate('startExerciseDate'))
  const endExerciseDate = useSelector(selectDate('endExerciseDate'))

  const handleSearch = () => {
    let params = {
      organizationId: companyChecked,
      groupId: exchangeChecked,
      icbId: icbChecked,
      watchlistId: wlChecked,
      fiinXEventTypeIds: eventTypeChecked,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(startRecordDate && { startRecordDate }),
      ...(endRecordDate && { endRecordDate }),
      ...(startExrightDate && { startExrightDate }),
      ...(endExrightDate && { endExrightDate }),
      ...(startExerciseDate && { startExerciseDate }),
      ...(endExerciseDate && { endExerciseDate }),
      page: 1,
    }
    dispatch(applyFilter(params))
  }

  const handleReset = () => {
    dispatch(resetFilter())
    dispatch(changeDisplayInfo(true))
  }

  return (
    <div className="d-flex h-100" style={{ flexDirection: 'column' }}>
      <EventType />
      <Company />
      <Exchange
        exchanges={exchanges}
        exchangesTree={exchangesTreeMemo}
        listChecked={listExchangeChecked}
        listParentChecked={listParentExchangeChecked}
        loading={loadingICB}
      />
      <Sector
        ICBs={ICBs}
        ICBsTree={ICBsTreeMemo}
        listChecked={listSectorChecked}
        listParentChecked={listParentSectorChecked}
        loading={loadingExchange}
      />
      <MyWatchList />
      <DateFilter
        label={'market.event.ANNOUNCEMENT_DATE'}
        startDateKey={'startDate'}
        endDateKey={'endDate'}
      />
      <DateFilter
        label={'market.event.RECORD_DATE'}
        startDateKey={'startRecordDate'}
        endDateKey={'endRecordDate'}
      />
      <DateFilter
        label={'market.event.EXRIGHT_DATE'}
        startDateKey={'startExrightDate'}
        endDateKey={'endExrightDate'}
      />
      <DateFilter
        label={'market.event.EXCERCISE_DATE'}
        startDateKey={'startExerciseDate'}
        endDateKey={'endExerciseDate'}
      />

      <div
        style={{
          padding: 14,
          gap: 8,
          background: '#252A36',
          marginTop: 8,
        }}
        className="flex-column"
      >
        <div className="w-100">
          <Button
            className="btn btn-blue w-100"
            style={{ height: 20, fontWeight: 340 }}
            onClick={handleSearch}
          >
            <Translate value="common.button.BUTTON_SEARCH" />
          </Button>
        </div>
        <div className="w-100">
          <Button
            className="btn normal w-100"
            style={{ height: 20, fontWeight: 340 }}
            onClick={handleReset}
          >
            <Translate value="bond.bondScreening.common.RESET" />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Filter
