import { useSelector } from 'react-redux'
import { ConsumptionTable } from '.'
import { Panel } from '../../../../common/panel'
import {
  selectCountLimit,
  selectMaxTimeCallApi,
  selectTimeType,
} from './store/slice'
import { downloadConsumptionTable } from './store/thunk'

const PanelTable = ({ panelRefs, sizes, setSizes, panelKey }) => {
  const dataType = useSelector(selectTimeType)
  const maxTimeCallApi = useSelector(selectMaxTimeCallApi)
  const countLimit = useSelector(selectCountLimit)

  const downloadCsv = () => {
    const params = { DataType: dataType, Limit: countLimit }

    if (maxTimeCallApi.month) {
      params.Month = maxTimeCallApi.month
    }

    if (maxTimeCallApi.year) {
      params.Year = maxTimeCallApi.year
    }

    return downloadConsumptionTable(params)
  }

  return (
    <Panel
      title={'economy.productionAndConsumption.consumption.CONSUMPTION'}
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadCsv={true}
      downloadCsv={downloadCsv}
    >
      <ConsumptionTable />
    </Panel>
  )
}

export default PanelTable
