import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { DividendTableConstant } from '../constants'
import { getFinancialDataDividendTable } from './thunk'

const initialState = {
  dividendById: [],
  dividendData: [],
  cashDivIds: [],
  initialCashDivIds: [],
  stockDivIds: [],
  initialStockDivIds: [],
  loading: true,
}

export const slice = createSlice({
  name: 'corporate/financialData/dividend/table',
  initialState,
  reducers: {
    changeCashDivIds: (state, action) => {
      state.cashDivIds = action.payload
    },
    changeStockDivIds: (state, action) => {
      state.stockDivIds = action.payload
    },
    sortCashDivIds: (state, action) => {
      state.cashDivIds = getIdsFromProps(
        state.cashDivIds,
        state.dividendById,
        action.payload,
        state.initialCashDivIds,
      )
    },
    sortStockDivIds: (state, action) => {
      state.stockDivIds = getIdsFromProps(
        state.stockDivIds,
        state.dividendById,
        action.payload,
        state.initialStockDivIds,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFinancialDataDividendTable.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getFinancialDataDividendTable.fulfilled,
      (state, action) => {
        const cashDivIds = []
        const stockDivIds = []
        const data = action.payload || []
        state.dividendData = data
        state.dividendById = data.map((v, index) => {
          const dividend = {}
          if (v.paymentType === 'CashDividend') {
            cashDivIds.push(index)
          } else {
            stockDivIds.push(index)
          }
          Object.keys(DividendTableConstant).forEach((key) => {
            if (key === 'index') {
              dividend[key] = index
            } else {
              let value = v[DividendTableConstant[key].key]
              const keyValue = {
                ...DividendTableConstant[key],
                value: value,
              }
              dividend[key] = keyValue
            }
          })
          return dividend
        })

        state.initialCashDivIds = state.cashDivIds = cashDivIds
        state.initialStockDivIds = state.stockDivIds = stockDivIds
        state.loading = false
      },
    )
    builder.addCase(getFinancialDataDividendTable.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectCashDivIds = (state) => state[slice.name].cashDivIds
export const selectStockDivIds = (state) => state[slice.name].stockDivIds
export const selectDividendValue = (id, attr) => (state) => {
  return valByKeyWithDot(state[slice.name].dividendById[id], attr)
}
export const selectLoading = (state) => state[slice.name].loading
export const {
  changeCashDivIds,
  changeStockDivIds,
  sortCashDivIds,
  sortStockDivIds,
} = slice.actions

register(slice.name, slice.reducer)
