import FinancialChartDataProxy from '../../proxies/financialChart/FinancialChartDataProxy'
import { ServiceBase } from '../ServiceBase'

class FinancialChartDataService extends ServiceBase {
  getIndicators(params) {
    return this.applyMemoryCache(
      'FinancialChartDataService.getIndicators',
      params,
    )(() => FinancialChartDataProxy.getIndicators(params))
  }

  getDataCompany(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataCompany(convertPayloadData(params)),
    )
  }

  getDataEconomy(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataEconomy(convertPayloadData(params)),
    )
  }

  getDataSector(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataSector(convertPayloadData(params)),
    )
  }

  getDataIndex(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataIndex(convertPayloadData(params)),
    )
  }

  getDataAggregate(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataAggregate(
        convertPayloadAggregateCount(params),
      ),
    )
  }

  getDataCount(params) {
    return this.getData(() =>
      FinancialChartDataProxy.getDataCount(
        convertPayloadAggregateCount(params),
      ),
    )
  }

  getDataLatestPeriod(params) {
    return this.applyMemoryCache(
      'FinancialChartDataService.getDataLatestPeriod',
      params,
    )(() =>
      FinancialChartDataProxy.getDataLatestPeriod(convertPayloadData(params)),
    )
  }

  getDataQuery(params) {
    return this.getData(() => FinancialChartDataProxy.getDataQuery(params))
  }

  downloadDataExport(params) {
    return this.getFileDownload(() =>
      FinancialChartDataProxy.downloadDataExport(params),
    )
  }
}

export default new FinancialChartDataService()

const convertPayloadData = (data) => {
  if (data.timeFrequency === 'ttm') {
    return {
      ...data,
      timeFrequency: 'Quarterly',
    }
  }
  return data
}

const convertPayloadAggregateCount = (data) => {
  return {
    ...data,
    indicators: data.indicators.map((item) => convertPayloadData(item)),
  }
}
