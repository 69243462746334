import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { I18n } from 'react-redux-i18n'
import PieChartWithFooter from '../../../../../common/chart/pieChart/pieChartWithFooter'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { Panel } from '../../../../../common/panel'
import { SizeTracker } from '../../../../../common/sizeTracker'
import {
  HEADER_PANEL_HEIGHT,
  PANEL_PADDING,
} from '../../../../../constants/Common'
import { formatNumber } from '../../../../../utils/Common'
import { colorArray } from '../constants.js'
import { renderCouponName, renderDate, renderDateTime } from '../helper'
import {
  keys,
  selectActiveTimeType,
  selectFilterIssuanceValueByCouponType,
  selectIssuanceValueByCouponData,
  selectLoading,
} from '../store/slice'
import ChartTooltip from './ChartTooltip'
import Filter from './Filter'

const IssuanceValueByCouponType = ({
  panelRefs,
  panelKey,
  sizes,
  setSizes,
}) => {
  const isLoading = useSelector(selectLoading(keys.ISSUANCE_VALUE_BY_COUPON))
  const data = useSelector(selectIssuanceValueByCouponData)
  const { month, quarter, year } = useSelector(
    selectFilterIssuanceValueByCouponType,
  )
  const timeType = useSelector(selectActiveTimeType)
  const locale = useSelector((state) => state.i18n.locale)

  const formatDataPieChart = useMemo(() => {
    const topData = data.slice(0, 3)

    const totalDataValue = topData.reduce(
      (total, item) => total + item.issueValue,
      0,
    )

    const totalIssueValue = data.reduce(
      (total, item) => total + item.issueValue,
      0,
    )

    const result = topData.map((item, index) => ({
      text: renderCouponName(item.couponTypeId) ?? item.couponTypeName,
      value: item.rate,
      color: colorArray[index],
      locationId: item?.locationId,
      issueValue: item.issueValue,
    }))

    if (topData.length === data.length) return result

    const otherIssueValue = totalIssueValue - totalDataValue

    const otherRatio = 1 - topData.reduce((total, item) => total + item.rate, 0)

    return [
      ...result,
      {
        text: 'bond.corporateBond.issuanceValueByCouponType.OTHERS',
        value: otherRatio,
        color: '#ecffdf',
        isI18n: true,
        locationId: 'other',
        issueValue: otherIssueValue,
      },
    ]
  }, [data])

  const width = useMemo(
    () => formatNumber(sizes[panelKey].width) - PANEL_PADDING,
    [sizes, panelKey],
  )

  const height = useMemo(
    () => formatNumber(sizes[panelKey].height) - HEADER_PANEL_HEIGHT,
    [sizes, panelKey],
  )

  const renderTooltip = (dataChart) => (
    <ChartTooltip
      data={dataChart}
      date={renderDate(timeType, locale, { month, quarter, year })}
    />
  )
  const titleJpg = useMemo(() => {
    const title = I18n.t('bond.corporateBond.issuanceValueByCouponType.TITLE')

    const time = renderDateTime({
      timeType,
      month,
      quarter,
      year,
    })

    return `${title}-${time}`
  }, [timeType, month, quarter, year, locale])

  return (
    <Panel
      title="bond.corporateBond.issuanceValueByCouponType.TITLE"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom={true}
      isDownloadJpg={true}
      titleJpg={titleJpg}
      downloadJpgParams={{
        domId: 'issuanceValueByCouponTypeChartId',
        nameScreen: I18n.t('bond.corporateBond.primaryMarket.TITLE'),
        chartName: I18n.t('bond.corporateBond.issuanceValueByCouponType.TITLE'),
      }}
    >
      <SizeTracker>
        {(size) => (
          <>
            <Filter />
            <div id="issuanceValueByCouponTypeChartId">
              {size.height && (
                <div style={{ height: height - size.height }}>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {!!formatDataPieChart.length ? (
                        <PieChartWithFooter
                          data={formatDataPieChart}
                          height={height - size.height}
                          width={width}
                          renderTooltip={renderTooltip}
                          footerRight
                          footerProps={{
                            isOneColumn: true,
                            appendStyle: { gridGap: 8 },
                          }}
                          isI18n
                        />
                      ) : (
                        <NoData />
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </SizeTracker>
    </Panel>
  )
}

IssuanceValueByCouponType.propTypes = {
  panelRefs: PropTypes.object.isRequired,
  panelKey: PropTypes.string.isRequired,
  sizes: PropTypes.object.isRequired,
  setSizes: PropTypes.func.isRequired,
}

export default IssuanceValueByCouponType
