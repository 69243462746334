export const RETAIL_SERVICE_OPTION = {
  size: (resizeSize) => ({
    width: resizeSize.width,
    height: resizeSize.height,
  }),
  props: {
    barWidth: 15,
    xAxisKey: 'label',
  },
}

export const LIST_COLOR = [
  '#185999',
  '#3cabeb',
  '#fe7e6d',
  '#fff6df',
  '#ccc',
  '#000',
]
