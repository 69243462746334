import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Input } from '../../../../../common/html/Input'
import { ReactComponent as IconAlignCenterActive } from '../../../../icon/icon-aligh-center-active.svg'
import { ReactComponent as IconAlignCenterNonActive } from '../../../../icon/icon-aligh-center-non-active.svg'
import { ReactComponent as IconAlignLeftActive } from '../../../../icon/icon-aligh-left-active.svg'
import { ReactComponent as IconAlignLeftNonActive } from '../../../../icon/icon-aligh-left-non-active.svg'
import { ReactComponent as IconAlignRightActive } from '../../../../icon/icon-aligh-right-active.svg'
import { ReactComponent as IconAlignRightNonActive } from '../../../../icon/icon-aligh-right-non-active.svg'
import { ReactComponent as IconFontStyleBoldActive } from '../../../../icon/icon-font-style-bold-active.svg'
import { ReactComponent as IconFontStyleBoldNonActive } from '../../../../icon/icon-font-style-bold-non-active.svg'
import { ReactComponent as IconFontStyleItalicActive } from '../../../../icon/icon-font-style-italic-active.svg'
import { ReactComponent as IconFontStyleItalicNonActive } from '../../../../icon/icon-font-style-italic-non-active.svg'
import { ReactComponent as IconFontStyleUnderlineActive } from '../../../../icon/icon-font-style-underline-active.svg'
import { ReactComponent as IconFontStyleUnderlineNonActive } from '../../../../icon/icon-font-style-underline-non-active.svg'
import { POSITION_LABEL } from '../../../constants'
import styles from './style.module.css'

const SettingStyle = ({
  style,
  setStyle,
  disableSetting,
  disablePosition,
  fontSizeMax,
  fontSizeMin = 6,
}) => {
  const [styleSetting, setStyleSetting] = useState(style)

  const changeStyleSetting = (value, keyChange) => {
    setStyleSetting({ ...styleSetting, [keyChange]: value })
  }

  useEffect(() => {
    setStyle(styleSetting)
  }, [styleSetting])

  return (
    <div
      className={`${styles.popupEditName} ${
        disableSetting && styles.disableSetting
      }`}
    >
      <div className={styles.groupIconEdit}>
        {styleSetting.bold ? (
          <IconFontStyleBoldActive
            onClick={() => changeStyleSetting(!styleSetting.bold, 'bold')}
          />
        ) : (
          <IconFontStyleBoldNonActive
            onClick={() => changeStyleSetting(!styleSetting.bold, 'bold')}
          />
        )}
        {styleSetting.italic ? (
          <IconFontStyleItalicActive
            onClick={() => changeStyleSetting(!styleSetting.italic, 'italic')}
          />
        ) : (
          <IconFontStyleItalicNonActive
            onClick={() => changeStyleSetting(!styleSetting.italic, 'italic')}
          />
        )}
        {styleSetting.underline ? (
          <IconFontStyleUnderlineActive
            onClick={() =>
              changeStyleSetting(!styleSetting.underline, 'underline')
            }
          />
        ) : (
          <IconFontStyleUnderlineNonActive
            onClick={() =>
              changeStyleSetting(!styleSetting.underline, 'underline')
            }
          />
        )}
      </div>
      <div className={styles.line14} />
      <div className={styles.groupIconEdit}>
        {styleSetting.position === POSITION_LABEL.LEFT ? (
          <IconAlignLeftActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() =>
              changeStyleSetting(POSITION_LABEL.CENTER, 'position')
            }
          />
        ) : (
          <IconAlignLeftNonActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() => changeStyleSetting(POSITION_LABEL.LEFT, 'position')}
          />
        )}
        {styleSetting.position === POSITION_LABEL.CENTER ? (
          <IconAlignCenterActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() =>
              changeStyleSetting(POSITION_LABEL.CENTER, 'position')
            }
          />
        ) : (
          <IconAlignCenterNonActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() =>
              changeStyleSetting(POSITION_LABEL.CENTER, 'position')
            }
          />
        )}
        {styleSetting.position === POSITION_LABEL.RIGHT ? (
          <IconAlignRightActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() =>
              changeStyleSetting(POSITION_LABEL.CENTER, 'position')
            }
          />
        ) : (
          <IconAlignRightNonActive
            className={disablePosition ? styles.disableSetting : ''}
            onClick={() => changeStyleSetting(POSITION_LABEL.RIGHT, 'position')}
          />
        )}
      </div>
      <div className={styles.line14} />
      <div className={styles.inputFontSize}>
        <i
          className={[
            'icon-caret-top',
            styles.iconCaretTop,
            styleSetting.fontSize >= fontSizeMax ? styles.disableSetting : '',
          ].join(' ')}
          onClick={() =>
            changeStyleSetting(styleSetting.fontSize + 1, 'fontSize')
          }
        />
        <Input readOnly type="string" value={styleSetting?.fontSize + 'px'} />
        <i
          className={[
            'icon-caret-down',
            styles.iconCaretDown,
            styleSetting.fontSize <= fontSizeMin ? styles.disableSetting : '',
          ].join(' ')}
          onClick={() =>
            changeStyleSetting(styleSetting.fontSize - 1, 'fontSize')
          }
        />
      </div>
    </div>
  )
}

SettingStyle.propTypes = {
  style: PropTypes.object,
  setStyle: PropTypes.func,
  disableSetting: PropTypes.bool,
  disablePosition: PropTypes.bool,
}

SettingStyle.defaultProps = {
  style: {
    fontSize: 11,
    bold: false,
    italic: false,
    underline: false,
    position: POSITION_LABEL.CENTER,
  },
  setStyle: () => {},
  disableSetting: false,
  disablePosition: false,
}

export default SettingStyle
