import Comparable from './comparable'
import { DebtAndLoan } from './debtAndLoan'
import { Synthetic } from './synthetic'

export const ISSUERS_TABS = {
  SYNTHETIC: 'synthetic',
  COMPARABLE: 'comparable',
  DEBT_LOAN: 'debtAndLoan',
  INTERCONNECTION_MAP: 'INTERCONNECTION_MAP',
}

export const NAVIGATION_ISSUERS = [
  {
    title: 'bond.corporateBond.issuers.SYNTHETIC',
    component: <Synthetic />,
    value: ISSUERS_TABS.SYNTHETIC,
  },
  {
    title: 'bond.comparable.common.title',
    component: <Comparable />,
    value: ISSUERS_TABS.COMPARABLE,
  },
  {
    title: 'bond.corporateBond.issuers.DEBT_LOAN',
    component: <DebtAndLoan />,
    value: ISSUERS_TABS.DEBT_LOAN,
  },
]
