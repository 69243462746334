export const TIME_TYPE = {
  YEARLY: 'Yearly',
  MONTHLY: 'Monthly',
  ACC_MONTHLY: 'AccMonthly',
}

export const TIME_BALANCE_TRADE_FILTER = [
  {
    title: 'economy.importExport.importExport.FILTER_YEARLY',
    value: TIME_TYPE.YEARLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY',
    value: TIME_TYPE.MONTHLY,
  },
  {
    title: 'economy.importExport.importExport.FILTER_MONTHLY_ACC',
    value: TIME_TYPE.ACC_MONTHLY,
  },
]

export const NUM_OF_PERIOD_FIRST_FETCH = 20
export const NUM_OF_PERIOD = 5
