import { createSlice } from '@reduxjs/toolkit'
import { ALL_TYPE, TIME } from '../../../../common/news/constants'
import { FORMAT, getCurrentDateTime } from '../../../../utils/Datetime'
import { register } from '../../../../utils/ReducerRegistry'
import {
  getLatestNews,
  getNewsAnalytic,
  getNewsBusinessPaged,
  getNewsById,
  getNewsCategories,
  getNewsCategoriesAndSourcesAnalytic,
  getNewsEarningPaged,
  getNewsOther,
  getNewsSources,
  getNewsTransactionsPaged,
} from './thunk'

const slice = createSlice({
  name: 'corporate/news',
  initialState: {
    newsAnalytics: [],
    earnings: { data: [] },
    transactions: { data: [] },
    businesses: { data: [] },
    other: [],
    latest: {},
    newsById: {},
    announcement: {
      sources: [],
      categories: [],
      moreFilterData: {
        newsType: ALL_TYPE,
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        search: '',
      },
    },
    analytic: {
      sources: [],
      categories: [],
      filterData: {
        category: ALL_TYPE,
        source: ALL_TYPE,
        time: TIME.All,
        startDate: getCurrentDateTime(FORMAT.DATE),
        endDate: getCurrentDateTime(FORMAT.DATE),
        search: '',
      },
    },
    newsEarningLoading: true,
    newsBusinessLoading: true,
    newsTransactionLoading: true,
    latestLoading: true,
    newsAnalyticLoading: true,
    newsOtherLoading: true,
  },
  reducers: {
    changeAnalyticFilter: (state, action) => {
      state.analytic.filterData = {
        ...state.analytic.filterData,
        ...action.payload,
      }
    },
    changeMoreFilter: (state, action) => {
      if (action.payload.newsType) {
        state.announcement.moreFilterData.category = ALL_TYPE
      }
      state.announcement.moreFilterData = {
        ...state.announcement.moreFilterData,
        ...action.payload,
      }
    },
    resetMore: (state) => {
      state.announcement.sources = []
      state.announcement.categories = []
    },
    resetAnalytic: (state) => {
      state.analytic.sources = []
      state.analytic.categories = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNewsAnalytic.pending, (state) => {
      state.newsAnalyticLoading = true
    })
    builder.addCase(getNewsAnalytic.fulfilled, (state, action) => {
      state.newsAnalyticLoading = false
      state.newsAnalytics = action.payload || []
    })
    builder.addCase(getNewsAnalytic.rejected, (state) => {
      state.newsAnalyticLoading = false
    })

    builder.addCase(getNewsEarningPaged.pending, (state) => {
      state.newsEarningLoading = true
    })
    builder.addCase(getNewsEarningPaged.fulfilled, (state, action) => {
      state.newsEarningLoading = false
      state.earnings = action.payload || []
    })
    builder.addCase(getNewsEarningPaged.rejected, (state) => {
      state.newsEarningLoading = false
    })

    builder.addCase(getNewsTransactionsPaged.pending, (state) => {
      state.newsTransactionLoading = true
    })
    builder.addCase(getNewsTransactionsPaged.fulfilled, (state, action) => {
      state.newsTransactionLoading = false
      state.transactions = action.payload || []
    })
    builder.addCase(getNewsTransactionsPaged.rejected, (state) => {
      state.newsTransactionLoading = false
    })

    builder.addCase(getNewsBusinessPaged.pending, (state) => {
      state.newsBusinessLoading = true
    })
    builder.addCase(getNewsBusinessPaged.fulfilled, (state, action) => {
      state.newsBusinessLoading = false
      state.businesses = action.payload || []
    })
    builder.addCase(getNewsBusinessPaged.rejected, (state) => {
      state.newsBusinessLoading = false
    })

    builder.addCase(getNewsOther.pending, (state) => {
      state.newsOtherLoading = true
    })
    builder.addCase(getNewsOther.fulfilled, (state, action) => {
      state.newsOtherLoading = false
      state.other = action.payload
        ? action.payload.reduce((result, currentItem) => {
            if (!result[currentItem.fiinXNewsCatName]) {
              result[currentItem.fiinXNewsCatName] = []
            }
            result[currentItem.fiinXNewsCatName].push(currentItem)
            return result
          }, {})
        : {}
    })
    builder.addCase(getNewsOther.rejected, (state) => {
      state.newsOtherLoading = false
    })

    builder.addCase(getLatestNews.pending, (state) => {
      state.latestLoading = true
    })
    builder.addCase(getLatestNews.fulfilled, (state, action) => {
      state.latestLoading = false
      const [latest = {}] = action.payload || []
      state.latest = latest
    })
    builder.addCase(getLatestNews.rejected, (state) => {
      state.latestLoading = false
    })

    builder.addCase(getNewsById.pending, (state) => {
      state.newsByIdLoading = true
    })
    builder.addCase(getNewsById.fulfilled, (state, action) => {
      state.newsByIdLoading = false
      state.newsById = action.payload || {}
    })
    builder.addCase(getNewsById.rejected, (state) => {
      state.newsByIdLoading = false
    })

    builder.addCase(getNewsCategoriesAndSourcesAnalytic.pending, (state) => {
      state.newsCategoriesAndSourcesAnalyticLoading = true
    })
    builder.addCase(
      getNewsCategoriesAndSourcesAnalytic.fulfilled,
      (state, action) => {
        state.newsCategoriesAndSourcesAnalyticLoading = false
        state.analytic.sources = action.payload?.sources || []
        state.analytic.categories = action.payload?.categories || []
      },
    )
    builder.addCase(getNewsCategoriesAndSourcesAnalytic.rejected, (state) => {
      state.newsCategoriesAndSourcesAnalyticLoading = false
    })

    builder.addCase(getNewsSources.pending, (state) => {
      state.newsSourcesLoading = true
    })
    builder.addCase(getNewsSources.fulfilled, (state, action) => {
      state.newsSourcesLoading = false
      state.announcement.sources = action.payload || []
    })
    builder.addCase(getNewsSources.rejected, (state) => {
      state.newsSourcesLoading = false
    })

    builder.addCase(getNewsCategories.pending, (state) => {
      state.newsCategoriesLoading = true
    })
    builder.addCase(getNewsCategories.fulfilled, (state, action) => {
      state.newsCategoriesLoading = false
      state.announcement.categories = action.payload || []
    })
    builder.addCase(getNewsCategories.rejected, (state) => {
      state.newsCategoriesLoading = false
    })
  },
})

export const selectNewsAnalytics = (state) => state[slice.name].newsAnalytics
export const selectNewsEarnings = (state) => state[slice.name].earnings
export const selectNewsTransactions = (state) => state[slice.name].transactions
export const selectNewsBusinesses = (state) => state[slice.name].businesses
export const selectNewsOther = (state) => state[slice.name].other
export const selectLatest = (state) => state[slice.name].latest
export const selectNewsById = (state) => state[slice.name].newsById
export const selectNewsBusinessLoading = (state) =>
  state[slice.name].newsBusinessLoading
export const selectNewsAnalyticLoading = (state) =>
  state[slice.name].newsAnalyticLoading
export const selectNewsEarningLoading = (state) =>
  state[slice.name].newsEarningLoading
export const selectNewsTransactionLoading = (state) =>
  state[slice.name].newsTransactionLoading
export const selectNewsOtherLoading = (state) =>
  state[slice.name].newsOtherLoading
export const selectLatestLoading = (state) => state[slice.name].latestLoading
export const selectNewsSourcesLoading = (state) =>
  state[slice.name].newsSourcesLoading
export const selectNewsCategoriesLoading = (state) =>
  state[slice.name].newsCategoriesLoading
export const selectNewsCategoriesAndSourcesAnalyticLoading = (state) =>
  state[slice.name].newsCategoriesAndSourcesAnalyticLoading
export const selectNewsByIdLoading = (state) =>
  state[slice.name].newsByIdLoading
export const selectAnalyticFilterData = (state) =>
  state[slice.name].analytic.filterData
export const selectMoreFilterData = (state) =>
  state[slice.name].announcement.moreFilterData
export const selectAnnouncementSources = (state) =>
  state[slice.name].announcement.sources
export const selectAnnouncementCategories = (state) =>
  state[slice.name].announcement.categories
export const selectAnalyticSources = (state) =>
  state[slice.name].analytic.sources
export const selectAnalyticCategories = (state) =>
  state[slice.name].analytic.categories

export const {
  changeAnalyticFilter,
  changeMoreFilter,
  resetMore,
  resetAnalytic,
} = slice.actions

register(slice.name, slice.reducer)
