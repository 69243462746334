import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Translate } from 'react-redux-i18n'
import CalendarPopper from '../../../../../common/calendar/CalendarPopper'
import HeaderCalendarPopper from '../../../../../common/calendar/headers/HeaderCalendarPopper'
import InputDateCalendar from '../../../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../../../common/hooks/useTimeZone'
import { Span } from '../../../../../common/html/Span'
import { FORMAT, formatDateTime } from '../../../../../utils/Datetime'
import style from '../index.module.css'

export const CalendarSearch = ({
  dateFrom,
  dateTo,
  disabled,
  onDateFromChange,
  onDateToChange,
  onOpenDateFrom,
  onOpenDateTo,
  limitDatepicker,
}) => {
  const ref = useRef({ dateFromInput: '', dateToInput: '' })

  const currentDate = [new Date()]
  const timeZone = UseTimeZone()
  const currentYear = new Date().getFullYear()

  // Use state
  const [isOpenDateFrom, setIsOpenDateFrom] = useState(false)
  const [isOpenDateTo, setIsOpenDateTo] = useState(false)

  // Actions
  const setDateFrom = (value) => {
    onDateFromChange(value || '')
  }

  const setDateTo = (value) => {
    onDateToChange(value || '')
  }

  const clearInputDate = () => {
    if ((!dateFrom && !dateTo) || disabled) {
      return
    } else {
      onDateFromChange('')
      onDateToChange('')
    }
  }

  const onClickDateFrom = () => {
    if (!disabled) {
      setIsOpenDateFrom(true)
    }
  }

  const onClickDateTo = () => {
    if (!disabled) {
      setIsOpenDateTo(true)
    }
  }

  const onDateFromOpen = () => {
    setIsOpenDateFrom(true)
    onOpenDateFrom(true)
  }

  const onDateToOpen = () => {
    setIsOpenDateTo(true)
    onOpenDateTo(true)
  }

  const onDateFromClose = () => {
    if (!new Date(ref.current.dateFromInput).getTime()) {
      onDateFromChange('')
    }
    setIsOpenDateFrom(false)
    onOpenDateFrom(false)
  }

  const onDateToClose = () => {
    if (!new Date(ref.current.dateToInput).getTime()) {
      onDateToChange('')
    }
    setIsOpenDateTo(false)
    onOpenDateTo(false)
  }

  const onDateFromInput = (e) => {
    ref.current.dateFromInput = e.target.value
  }

  const onDateToInput = (e) => {
    ref.current.dateToInput = e.target.value
  }

  const getDateWithTimeZone = (date) => {
    return new Date(formatDateTime(date, FORMAT.DATE_TIME, undefined, timeZone))
  }

  // Use effect
  useEffect(() => {
    if (dateFrom) {
      ref.current.dateFromInput = dateFrom
    }

    if (dateTo) {
      ref.current.dateToInput = dateTo
    }
  }, [dateFrom, dateTo])

  return (
    <>
      <div className="form-date-select">
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.marketInDepth.indexStatistics.priceData.FROM_DATE" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={dateFrom ? getDateWithTimeZone(dateFrom) : ''}
            minDate={getDateWithTimeZone(limitDatepicker.minDate)}
            maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
            handleChange={setDateFrom}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendarPopper({
              startYear: limitDatepicker.minYear || currentYear - 10,
              endYear: limitDatepicker.maxYear || currentYear,
              zIndex: 11,
            })}
            isOpen={isOpenDateFrom}
            disabled={disabled}
            onOpen={onDateFromOpen}
            onClose={onDateFromClose}
            onInput={onDateFromInput}
          />
          <span
            className={`btn-select-date ${style.iconCaret}`}
            onClick={onClickDateFrom}
          >
            <i className="icon-caret-down" />
          </span>
        </div>
        <Span style={{ fontSize: 12 }}>
          <Translate value="market.marketInDepth.indexStatistics.priceData.TO_DATE" />
        </Span>
        <div className="date-select">
          <CalendarPopper
            date={dateTo ? getDateWithTimeZone(dateTo) : ''}
            minDate={getDateWithTimeZone(limitDatepicker.minDate)}
            maxDate={getDateWithTimeZone(limitDatepicker.maxDate)}
            handleChange={(value) => setDateTo(value)}
            CustomInput={InputDateCalendar}
            hightlightDates={currentDate}
            customHeader={HeaderCalendarPopper({
              startYear: limitDatepicker.minYear || currentYear - 10,
              endYear: limitDatepicker.maxYear || currentYear,
              zIndex: 11,
            })}
            isOpen={isOpenDateTo}
            disabled={disabled}
            onOpen={onDateToOpen}
            onClose={onDateToClose}
            onInput={onDateToInput}
          />
          <span
            className={`btn-select-date ${style.iconCaret}`}
            onClick={onClickDateTo}
          >
            <i className="icon-caret-down" />
          </span>
        </div>
        <button
          className={style.buttonClear}
          type="button"
          disabled={(!dateFrom === dateTo) === null || disabled}
          onClick={() => clearInputDate()}
        >
          <Span style={{ fontSize: 10 }}>
            <Translate value="market.marketInDepth.indexStatistics.priceData.CLEAR_DATE" />
          </Span>
        </button>
      </div>
    </>
  )
}

CalendarSearch.propTypes = {
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  onDateFromChange: PropTypes.func.isRequired,
  onDateToChange: PropTypes.func.isRequired,
  onOpenDateFrom: PropTypes.func.isRequired,
  onOpenDateTo: PropTypes.func.isRequired,
  limitDatepicker: PropTypes.object,
}
