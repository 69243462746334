import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../../common/loading'
import { NoData } from '../../../../../common/noData'
import { SizeTracker } from '../../../../../common/sizeTracker'
import { selectBasicInfo } from '../../../../../common/topInfo/stockTopInfo/store/slice'
import PieChartComponent from '../../common/PieChartComponent'
import Title from '../../common/Title'
import {
  selectDataMarketShare,
  selectFilter,
  selectLoadingMarketShare,
} from '../store/slice'
import { getInsuMarketShare } from '../store/thunk'

const ChartContainer = ({ width, height }) => {
  const dispatch = useDispatch()

  const basicInfo = useSelector(selectBasicInfo)
  const dataChart = useSelector(selectDataMarketShare)
  const loading = useSelector(selectLoadingMarketShare)
  const filter = useSelector(selectFilter)

  useEffect(() => {
    if (basicInfo.organizationId && filter.year && filter.quarter) {
      dispatch(
        getInsuMarketShare({
          OrganizationId: basicInfo.organizationId,
          Year: filter.year,
          Quarter: filter.quarter,
        }),
      )
    }
  }, [filter, basicInfo.organizationId])

  return (
    <SizeTracker>
      {(size) => {
        return (
          <>
            <Title text="MARKET_SHARE" />
            {size.height && (
              <div
                style={{ position: 'relative', height: height - size.height }}
              >
                {loading ? (
                  <Loading />
                ) : dataChart.length === 0 ? (
                  <NoData />
                ) : (
                  <PieChartComponent
                    data={dataChart}
                    height={height - 2 * size.height}
                    datakey="tp"
                    currentTicker={basicInfo?.ticker}
                    width={width}
                  />
                )}
              </div>
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}

export default ChartContainer
