export const SIMPLE_SEARCH_PARAMS = {
  page: 1,
  pageSize: 25,
}

export const DEFAULT_FUND_VN = {
  ticker: 'BVFED',
  organizationId: 4561,
  organizationName: 'Quỹ Đầu tư Cổ phiếu Năng động Bảo Việt',
  organizationShortName: 'Quỹ Năng động Bảo Việt',
  icbId: 162,
  groupId: null,
}

export const DEFAULT_FUND_EN = {
  ticker: 'BVFED',
  organizationId: 4561,
  organizationName: 'BVFED',
  organizationShortName: 'BVFED',
  icbId: 162,
  groupId: null,
}
