import { useSelector } from 'react-redux'
import { Panel } from '../../../../common/panel'
import { selectBasicInfo } from '../../../../common/topInfo/stockTopInfo/store/slice'
import { downloadDetailMetrics } from '../store/thunk'
import TableDetailMetrics from './TableDetailMetrics'

const PanelDetailMetrics = ({ panelRefs, panelKey, sizes, setSizes }) => {
  const basicInfo = useSelector(selectBasicInfo)

  const downloadCsv = () =>
    downloadDetailMetrics({
      OrganizationId: basicInfo.organizationId,
    })

  return (
    <Panel
      title="corporate.businessModel.costOfOperation.DETAIL_METRICS"
      panelRefs={panelRefs}
      panelKey={panelKey}
      sizes={sizes}
      setSizes={setSizes}
      windowZoom
      isDownloadCsv
      downloadCsv={downloadCsv}
    >
      <TableDetailMetrics />
    </Panel>
  )
}

export default PanelDetailMetrics
