import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Button } from '../../../common/html/Button'
import { resetSecurities } from '../../popup/common/store/slice'
import { resetIndicators } from '../../popup/popupAddIndicator/store/slice'
import PopupConfirm from '../../popup/PopupConfirm'
import {
  resetChart,
  resetDataChart,
  resetDataChartEquation,
} from '../store/slice'
import style from '../style.module.css'

const ButtonResetChart = () => {
  const dispatch = useDispatch()

  const [isShow, setIsShow] = useState(false)

  const onClick = () => {
    setIsShow(true)
  }

  const onOk = () => {
    dispatch(resetChart())
    dispatch(resetDataChart())
    dispatch(resetDataChartEquation())
    dispatch(resetIndicators())
    dispatch(resetSecurities())
  }

  return (
    <>
      <div className={style.btnReset}>
        <Button style={{ fontSize: 11 }} onClick={onClick}>
          <Translate value="financialChart.BTN_RESET" />
        </Button>
      </div>
      <PopupConfirm
        titlePopup="financialChart.RESET_MESSAGE_CONFIRM"
        isShow={isShow}
        handleOk={onOk}
        handleShow={setIsShow}
      />
    </>
  )
}

export default ButtonResetChart
