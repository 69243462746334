import { createAsyncThunk } from '@reduxjs/toolkit'
import MasterService from '../../../../../core/services/common/MasterService'
import { addTickerPopupTemplate } from './slice'

const groupIdVn30 = 8

export const getVN30Thunk = createAsyncThunk(
  'dataExplorer/common/GET_VN_30',
  async (_, { rejectWithValue, dispatch }) => {
    const response = await MasterService.getOrganizationsByExchange({
      GroupId: groupIdVn30,
    })
    if (response.success) {
      return dispatch(addTickerPopupTemplate(response.data))
    }
    return rejectWithValue(response.errors)
  },
)
