import { SizeTracker } from '../../../../../common/sizeTracker'
import { ReactComponent as IconDashboard } from '../../../../icon/icon-dashboard.svg'
import iconFavorite from '../../../../icon/icon-favorite.svg'
import { Title } from '../../../Title'
import { useGetUserSettings } from '../../../useGetUserSettings'
import style from './Item.module.css'

export const ItemTitle = ({
  data,
  isHideCollapseIcon,
  isEdit,
  isNewDashboard,
  collapseOpen,
  setIsEdit,
  setIsActive,
  setIsHover,
  onSubmitUpdateTitle,
}) => {
  const { defaultDashboard } = useGetUserSettings()

  const renderIcon = () => {
    if (isHideCollapseIcon) {
      return null
    }

    return collapseOpen ? (
      <i className={`${'icon-caret-down'} ${style.iconCollapse}`} />
    ) : (
      <i className={`${'icon-caret-right'} ${style.iconCollapse}`} />
    )
  }

  const renderDashboardTypeIcon = () => {
    return (
      isHideCollapseIcon && (
        <IconDashboard
          className={style.iconDashboardType}
          height="8px"
          width="8px"
        />
      )
    )
  }

  return (
    <SizeTracker className={style.content}>
      {(size2) => {
        return (
          <>
            {defaultDashboard?.id && defaultDashboard?.id === data.id ? (
              <img
                alt="icon favorite"
                src={iconFavorite}
                height={11}
                width={11}
                className={style.iconHeart}
              />
            ) : (
              renderIcon()
            )}
            {renderDashboardTypeIcon()}
            {(size2.width || size2.width === 0) && (
              <Title
                className={style.title}
                data={data}
                isEdit={isEdit}
                isNewDashboard={isNewDashboard}
                maxWidth={`calc(100% - ${size2.width}px)`}
                setIsEdit={setIsEdit}
                setIsActive={setIsActive}
                setIsHover={setIsHover}
                onSubmit={onSubmitUpdateTitle}
                isI18n={false}
              />
            )}
          </>
        )
      }}
    </SizeTracker>
  )
}
