import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TextEllipsis from '../../../common/textEllipsis'
import { valueTab } from '../constants'
import IconGlobal from '../flagIcon/Icon_Global.svg'
import IconVNFlag from '../flagIcon/Icon_VN_Flag.svg'
import { getIdsWithParentId, getTab, selectItemData } from '../store/slice'
import { GLOBAL_ID, VN_ID } from './config'

const { VIET_NAM, OTHER } = valueTab

const EventColumnCell = ({ val, rowId }) => {
  const tab = useSelector(getTab)
  const item = useSelector(selectItemData(rowId))
  const idsWithParentId = useSelector(getIdsWithParentId)

  return (
    <td style={{ width: '30%', maxWidth: '0px' }}>
      <div className="d-flex">
        {(tab === OTHER ||
          idsWithParentId[item.parentMacroEventTypeId] === GLOBAL_ID) &&
          !item.isCollapse && (
            <img
              src={IconGlobal}
              alt={'IconGlobal'}
              style={{ marginRight: 5 }}
            />
          )}
        {(tab === VIET_NAM ||
          idsWithParentId[item.parentMacroEventTypeId] === VN_ID) &&
          !item.isCollapse && (
            <img
              src={IconVNFlag}
              alt={'IconVNFlag'}
              style={{ marginRight: 5 }}
            />
          )}
        {item.isCollapse ? '' : <TextEllipsis isI18n={false} text={val} />}
      </div>
    </td>
  )
}

EventColumnCell.propTypes = {
  val: PropTypes.string,
  rowId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default EventColumnCell
