import PropTypes from 'prop-types'
import { DEFAULT_FONT_SIZE } from '../../../configs/Layout'
import { getFontSize } from '../../utils/FontSize'

export const Span = ({ children, style, ...others }) => {
  return (
    <span
      style={{
        ...style,
        fontSize: style.fixedFontSize || getFontSize(style?.fontSize),
      }}
      {...others}
    >
      {children}
    </span>
  )
}

Span.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

Span.defaultProps = {
  style: {
    fontSize: DEFAULT_FONT_SIZE,
    fixedFontSize: null,
  },
}
