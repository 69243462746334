import { I18n } from 'react-redux-i18n'
import { formatVal, valToPercent } from '../../../../../../utils/Value'
import { tableConstants } from './constants'

export const getTableSchema = () => {
  return tableConstants.map((item) => {
    const result = {
      title: item.name,
      colId: item.id,
      isI18n: false,
      thStyle: {
        textAlign: 'right',
      },
      tdStyle: {
        textAlign: 'right',
      },
    }

    if (item.isPercent) {
      return {
        ...result,
        render: (val) => valToPercent(val),
        renderTh: () => (
          <span style={{ whiteSpace: 'pre' }}>{I18n.t(item.name)}</span>
        ),
      }
    }

    if (item.isDecimal) {
      return {
        ...result,
        render: (val) => formatVal(val / item.multiplier),
        renderTh: () => (
          <span style={{ whiteSpace: 'pre' }}>{I18n.t(item.name)}</span>
        ),
      }
    }

    const leftSchema = {
      title: item.name,
      colId: item.id,
      isI18n: false,
      thStyle: {
        textAlign: 'left',
      },
      tdStyle: {
        textAlign: 'left',
      },
      disableSort: ['ticker'].includes(item.id),
    }

    return leftSchema
  })
}
