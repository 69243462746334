import React from 'react'
import { useSelector } from 'react-redux'
import CalendarPopper from '../../../common/calendar/CalendarPopper'
import HeaderOneCalendarRange from '../../../common/calendar/headers/HeaderOneCalendarRange'
import InputDateCalendar from '../../../common/calendar/inputs/InputDateCalendar'
import UseTimeZone from '../../../common/hooks/useTimeZone'
import { Icon } from '../../../common/html/Icon'
import { Span } from '../../../common/html/Span'
import { getDateWithTimezone } from '../../portfolio/helper'
import FilterDropdown from '../Filter/FilterDropdown'
import {
  FILTER_FIELD_CODES,
  ISSUE_DATE_OPTIONS,
  MATURITY_DATE_OPTIONS,
} from './constants'
import style from './index.module.css'

const DateFilter = ({
  child,
  initialParams,
  handleChangeFromDate,
  handleChangeToDate,
  handleClick,
  startYear,
  endYear,
  placeholderFrom,
  placeholderTo,
  isShow,
  setIsShow,
  excludeDateIntervals,
}) => {
  const locale = useSelector((state) => state.i18n.locale)

  const timeZone = UseTimeZone()

  const customHeader = HeaderOneCalendarRange({
    startYear,
    endYear,
  })

  return (
    <div className="w-100" key={child.indicatorId}>
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          {`${child?.indicatorName ?? ''} ${child.unit ?? ''}`}
        </Span>
      </label>
      <div className="d-flex ali-center" style={{ gap: 4 }}>
        <div className="form-control calendar mb-0" style={{ flex: 1 }}>
          <div className={`${style.bgGrey} w-100`}>
            <CalendarPopper
              date={
                !!initialParams?.conditionValues?.[0]?.length
                  ? getDateWithTimezone(initialParams?.conditionValues?.[0])
                  : null
              }
              handleChange={(value) => handleChangeFromDate(value)}
              customHeader={customHeader}
              CustomInput={InputDateCalendar}
              popperClassName={style.popperClassNameLeft}
              placeholder={placeholderFrom}
              excludeDateIntervals={excludeDateIntervals}
            />
          </div>
          <Icon className={`icon-date`} style={{ fontSize: 12 }} />
        </div>
        <div className="form-control calendar mb-0" style={{ flex: 1 }}>
          <div className={`${style.bgGrey} w-100`}>
            <CalendarPopper
              date={
                !!initialParams?.conditionValues?.[1]?.length
                  ? getDateWithTimezone(initialParams?.conditionValues?.[1])
                  : null
              }
              handleChange={(value) => handleChangeToDate(value)}
              customHeader={customHeader}
              CustomInput={InputDateCalendar}
              popperClassName={style.popperClassName}
              placeholder={placeholderTo}
              excludeDateIntervals={excludeDateIntervals}
            />
          </div>
          <Icon className={`icon-date`} style={{ fontSize: 12 }} />
        </div>
        <FilterDropdown
          isShow={isShow}
          setIsShow={setIsShow}
          id={child.indicatorId}
          data={
            child.tableFieldCode === FILTER_FIELD_CODES.ISSUE_DATE ||
            child.tableFieldCode === FILTER_FIELD_CODES.REDEMPTION_DATE
              ? ISSUE_DATE_OPTIONS
              : MATURITY_DATE_OPTIONS
          }
          handleClick={handleClick}
          timeZone={timeZone}
          locale={locale}
          width={160}
        />
      </div>
    </div>
  )
}

export default DateFilter
