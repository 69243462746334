import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Translate } from 'react-redux-i18n'
import { Icon } from '../../../../common/html/Icon'
import { Span } from '../../../../common/html/Span'
import TextEllipsis from '../../../../common/textEllipsis'
import { TooltipInfo } from '../../../../common/tooltipInfo'
import { Z_INDEX } from '../../../../constants/Common'
import { BOND_INFORMATION_TAB_TYPES, sortStateMap } from '../../constants'
import { changeFilterBondInformation } from '../../store/slice'
import Sort from './Sort'
import style from './index.module.css'

const TableHeader = ({
  tableRow,
  stateSort,
  sortColumn,
  handleClick,
  scrollLeft,
  width,
  tickerRef,
  tradingDateRef,
  totalValueRef,
  bondEventRef,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const tickerLeft = tickerRef?.current?.offsetWidth
    const tradingDateLeft = tradingDateRef?.current?.offsetLeft
    const totalValueLeft = totalValueRef?.current?.offsetLeft
    const bondEventLeft = bondEventRef?.current?.offsetLeft

    dispatch(
      changeFilterBondInformation({
        key: 'activeTab',
        value:
          tradingDateLeft - tickerLeft < scrollLeft
            ? totalValueLeft - tickerLeft < scrollLeft ||
              bondEventLeft - width + bondEventRef?.current?.offsetWidth ===
                scrollLeft ||
              bondEventLeft - width + bondEventRef?.current?.offsetWidth + 1 ===
                scrollLeft
              ? BOND_INFORMATION_TAB_TYPES.PORTFOLIO_ANALYSIS
              : BOND_INFORMATION_TAB_TYPES.TRADING_INFORMATION
            : BOND_INFORMATION_TAB_TYPES.BOND_INFORMATION,
      }),
    )
  }, [scrollLeft, width])

  const renderRef = (name) => {
    switch (name) {
      case 'ticker':
        return tickerRef
      case 'tradingDateId':
        return tradingDateRef
      case 'totalValue':
        return totalValueRef
      case 'bondEventTypeName':
        return bondEventRef
      default:
        return undefined
    }
  }

  return (
    <thead
      className={style.tableHeader}
      style={{ zIndex: Z_INDEX.STICKY_THEAD }}
    >
      <tr>
        {tableRow.map((col, index2) => {
          return (
            <th
              onClick={() => {
                sortColumn({
                  [col.keySort]: sortStateMap[stateSort[col.keySort]],
                })
              }}
              key={index2}
              {...col?.attrs}
              className={`${index2 === 0 && style.cellLeft} ${
                index2 === 0 && style.firstTh
              }`}
              ref={renderRef(col.name)}
            >
              <div
                className={`d-flex ${
                  col?.justifyContent ?? 'justify-content-start'
                }`}
                style={{ position: 'relative' }}
              >
                <div
                  className="d-flex ali-center"
                  style={{ gap: 2, overflow: 'hidden' }}
                >
                  {col.isDelete && (
                    <Icon
                      className="icon-trash cursor-pointer"
                      style={{ color: '#AEBCD2', marginRight: 8 }}
                      onClick={(e) => {
                        e.stopPropagation()
                        handleClick()
                      }}
                    />
                  )}
                  {col.value && (
                    <Span
                      className={style.theadContent}
                      style={{
                        fontSize: 12,
                        fontWeight: 600,
                        overflow: 'hidden',
                      }}
                    >
                      <TextEllipsis text={col.value} />
                    </Span>
                  )}
                  {col?.unit && (
                    <Span
                      className={style.additionText}
                      style={{
                        fontSize: 12,
                        fontWeight: 400,
                      }}
                    >
                      <Translate value={col?.unit} />
                    </Span>
                  )}
                  {col?.tooltip && (
                    <div style={{ marginLeft: 2, marginTop: 2 }}>
                      <TooltipInfo tooltip={col.tooltip} fontSize={12} />
                    </div>
                  )}
                  {!col.isDisableSort && <Sort type={stateSort[col.keySort]} />}
                </div>
              </div>
              <div className={style.borderRight}></div>
            </th>
          )
        })}
      </tr>
    </thead>
  )
}

TableHeader.propTypes = {
  tableRow: PropTypes.any.isRequired,
  stateSort: PropTypes.any.isRequired,
  sortColumn: PropTypes.any.isRequired,
}

export default TableHeader
