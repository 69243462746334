import { useSelector } from 'react-redux'
import { Span } from '../../../../common/html/Span'
import FilterMultiSelect from '../../Filter/FilterMultiSelect'
import { selectAddInformatics, selectFilterParams } from '../../store/slice'
import { ALL_VALUE, TABLE_FIELD_CODES } from '../constants'
import style from '../index.module.css'

export const FilterTypeMultiSelect = ({ data, handleChangeParams }) => {
  const params = useSelector(selectFilterParams)
  const addInformatics = useSelector(selectAddInformatics)

  const initialParams = params.conditions.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  const addedInformation = addInformatics.find(
    (item) => item.indicatorId === data.indicatorId,
  )

  return (
    <div className="w-100" key={data.indicatorId}>
      <label className={style.labelInput}>
        <Span style={{ fontSize: 11 }}>
          {`${data?.indicatorName ?? ''} ${data.unit ?? ''}`}
        </Span>
      </label>
      <FilterMultiSelect
        data={
          data?.selectionValues?.filter((item) => item.value !== ALL_VALUE) ??
          []
        }
        width={258}
        initialValue={initialParams ?? addedInformation}
        handleChange={handleChangeParams}
        isHaveAllValue={
          (initialParams ?? addedInformation)?.tableFieldCode !==
          TABLE_FIELD_CODES.STATUS
        }
      />
    </div>
  )
}
