import ToolResizeChart from '../toolResizeChart'
import style from './style.module.css'

const FooterPreviewChart = ({ width, height, setDimensions, isShowTable }) => {
  return (
    <div className={style.footer}>
      <ToolResizeChart
        height={height}
        width={width}
        setDimensions={setDimensions}
        isShowTable={isShowTable}
        isPreview={true}
        widthDefault={1000}
      />
    </div>
  )
}

export default FooterPreviewChart
