import PropTypes from 'prop-types'
import { Span } from '../../../html/Span'
import { labelFormatterByTimeRange } from '../helper'
import Container from './Container'
import { separate } from './helper'
import style from './index.module.css'

const TooltipTwoColumn = ({
  payload,
  locale,
  timeZone,
  timeRange,
  label,
  tooltipSchema,
  isShowLabel,
}) => {
  if (
    !payload.length ||
    !payload.some(
      (item) => typeof item.value === 'number' && !isNaN(item.value),
    )
  )
    return null

  const { left, right } = separate(payload)

  return (
    <div className={['recharts-default-tooltip', style.textColor].join(' ')}>
      {isShowLabel && (
        <Span>
          {labelFormatterByTimeRange(timeRange, locale, timeZone)(label)}
        </Span>
      )}
      <div className="d-flex">
        <Container items={left} tooltipSchema={tooltipSchema} isLeft={true} />
        <Container items={right} tooltipSchema={tooltipSchema} />
      </div>
    </div>
  )
}

TooltipTwoColumn.propTypes = {
  payload: PropTypes.array,
  timeRange: PropTypes.string,
  label: PropTypes.string,
  tooltipSchema: PropTypes.array,
}

export default TooltipTwoColumn
