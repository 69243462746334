export const GROUP_COLUMNS_OVERVIEW = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.DATE',
  },
  {
    key: 'foreignInstitutionalBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.BUY_VOLUME',
  },
  {
    key: 'foreignInstitutionalBuyValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.BUY_VALUE',
  },
  {
    key: 'foreignInstitutionalSellVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.SELL_VOLUME',
  },
  {
    key: 'foreignInstitutionalSellValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.SELL_VALUE',
  },
  {
    key: 'netVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.NET_VOLUME',
  },
  {
    key: 'netValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.NET_VALUE',
  },
  {
    key: 'foreignIndividualBuyMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_BUY_VOL',
  },
  {
    key: 'foreignInstitutionalBuyMatchValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_BUY_VAL',
  },
  {
    key: 'foreignInstitutionalSellMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_SELL_VOL',
  },
  {
    key: 'foreignIndividualSellMatchValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_SELL_VAL',
  },
  {
    key: 'foreignInstitutionalBuyDealVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_BUY_VOL',
  },
  {
    key: 'foreignInstitutionalBuyDealValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_BUY_VAL',
  },
  {
    key: 'foreignInstitutionalSellDealVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_SELL_VOL',
  },
  {
    key: 'foreignInstitutionalSellDealValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_SELL_VAL',
  },
]

export const GROUP_COLUMNS_DETAIL = [
  {
    key: 'tradingDateId',
    title: 'market.coveredWarrant.cwStatistics.DATE',
  },
  {
    key: 'cwCode',
    title: 'market.coveredWarrant.cwStatistics.TICKER_TABLE',
  },
  {
    key: 'foreignInstitutionalBuyVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.BUY_VOLUME',
  },
  {
    key: 'foreignInstitutionalBuyValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.BUY_VALUE',
  },
  {
    key: 'foreignInstitutionalSellVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.SELL_VOLUME',
  },
  {
    key: 'foreignInstitutionalSellValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.SELL_VALUE',
  },
  {
    key: 'netVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.NET_VOLUME',
  },
  {
    key: 'netValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.NET_VALUE',
  },
  {
    key: 'foreignIndividualBuyMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_BUY_VOL',
  },
  {
    key: 'foreignInstitutionalBuyMatchValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_BUY_VAL',
  },
  {
    key: 'foreignInstitutionalSellMatchVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_SELL_VOL',
  },
  {
    key: 'foreignIndividualSellMatchValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.ORDER_MATCHING_SELL_VAL',
  },
  {
    key: 'foreignInstitutionalBuyDealVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_BUY_VOL',
  },
  {
    key: 'foreignInstitutionalBuyDealValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_BUY_VAL',
  },
  {
    key: 'foreignInstitutionalSellDealVolume',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_SELL_VOL',
  },
  {
    key: 'foreignInstitutionalSellDealValue',
    title: 'market.coveredWarrant.cwStatistics.foreign.PUT_THROUGH_SELL_VAL',
  },
]

export const TABLE_TYPE = {
  OVERVIEW: 'overview',
  DETAIL: 'detail',
  TICKER: 'ticker',
}

export const LIST_RADIO = [
  {
    label: 'market.coveredWarrant.cwStatistics.OVERVIEW',
    value: TABLE_TYPE.OVERVIEW,
  },
  {
    label: 'market.coveredWarrant.cwStatistics.DETAIL',
    value: TABLE_TYPE.DETAIL,
  },
]
