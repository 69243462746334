import { useEffect } from 'react'
import { HexAlphaColorPicker } from 'react-colorful'
import useWindowDevicePixelRatio from '../../../../../common/hooks/useWindowDevicePixelRatio'
import { isWindowsOs } from '../../../../../utils/Device'

const DEFAULT_COLORFUL_SATURATION_HEIGHT = 150
const DEFAULT_COLORFUL_HUE_HEIGHT = 10
const DEFAULT_COLORFUL_ALPHA_HEIGHT = 10
const DEFAULT_COLORFUL_POINTER_HEIGHT = 14
const DEFAULT_COLORFUL_POINTER_WIDTH = 14

export const ColorPickerComponent = ({ color, onChange }) => {
  const { devicePixelRatio } = useWindowDevicePixelRatio()

  const handleChange = (value) => {
    onChange(value)
  }

  const getDevicePixelRatioByOs = () => {
    return isWindowsOs() ? devicePixelRatio : 1
  }

  useEffect(() => {
    const saturationEl = document.querySelector(
      '.custom-react-colorful .react-colorful .react-colorful__saturation',
    )
    const hueEl = document.querySelector(
      '.custom-react-colorful .react-colorful .react-colorful__hue',
    )
    const alphaEl = document.querySelector(
      '.custom-react-colorful .react-colorful .react-colorful__alpha',
    )
    const pointerEls = document.querySelectorAll(
      '.custom-react-colorful .react-colorful .react-colorful__pointer',
    )

    if (saturationEl) {
      saturationEl.style.borderRadius = 4 / getDevicePixelRatioByOs() + 'px'
    }
    if (hueEl) {
      hueEl.style.height =
        DEFAULT_COLORFUL_HUE_HEIGHT / getDevicePixelRatioByOs() + 'px'
      hueEl.style.marginTop = 12 / getDevicePixelRatioByOs() + 'px'
      hueEl.style.borderRadius = 8 / getDevicePixelRatioByOs() + 'px'
    }
    if (alphaEl) {
      alphaEl.style.height =
        DEFAULT_COLORFUL_ALPHA_HEIGHT / getDevicePixelRatioByOs() + 'px'
      alphaEl.style.marginTop = 12 / getDevicePixelRatioByOs() + 'px'
      alphaEl.style.borderRadius = 8 / getDevicePixelRatioByOs() + 'px'
    }
    if (pointerEls.length) {
      for (let index = 0; index < pointerEls.length; index++) {
        const element = pointerEls[index]
        element.style.height =
          DEFAULT_COLORFUL_POINTER_HEIGHT / getDevicePixelRatioByOs() + 'px'
        element.style.width =
          DEFAULT_COLORFUL_POINTER_WIDTH / getDevicePixelRatioByOs() + 'px'
        element.style.borderWidth = 2 / getDevicePixelRatioByOs() + 'px'
      }
    }
  }, [devicePixelRatio])

  return (
    <div
      className="custom-react-colorful"
      style={{
        height: DEFAULT_COLORFUL_SATURATION_HEIGHT / getDevicePixelRatioByOs(),
        marginTop: 16 / getDevicePixelRatioByOs(),
        zoom: `${getDevicePixelRatioByOs() * 100}%`,
      }}
    >
      <HexAlphaColorPicker color={color} onChange={handleChange} />
    </div>
  )
}
