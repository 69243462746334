import { useDispatch, useSelector } from 'react-redux'
import SelectCustom from '../../../../../common/selectCustom'
import { transactionTypes } from '../constants'
import { changeTransaction, selectFilter } from '../store/slice'
import style from '../style.module.css'

export const DropdownSearchTransaction = () => {
  const dispatch = useDispatch()

  const filterData = useSelector(selectFilter)

  const onChange = (data) => {
    dispatch(changeTransaction(data))
  }

  return (
    <div className={`mr-8 ${style.spanInputSearch}`} style={{ width: 135 }}>
      <SelectCustom
        value={filterData.transactionType}
        isI18n={true}
        selectData={Object.keys(transactionTypes).map((key) => ({
          name: `market.marketInDepth.directorDeal.${key}`,
          value: transactionTypes[key],
        }))}
        handleChange={onChange}
      />
    </div>
  )
}

export default DropdownSearchTransaction
