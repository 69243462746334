import { I18n } from 'react-redux-i18n'
import style from '../style.module.css'
const ColCellType = ({ val }) => {
  return (
    <div
      className={
        val === I18n.t('market.marketInDepth.directorDeal.TYPE_BUY')
          ? style.colorBuy
          : style.colorSell
      }
    >
      {val}
    </div>
  )
}

export default ColCellType
