import { createSlice } from '@reduxjs/toolkit'
import { getIdsFromProps } from '../../../../../common/table/helper'
import { TIME_FREQUENCY } from '../../../../../constants/Datetime'
import { keyBy } from '../../../../../utils/Common'
import { register } from '../../../../../utils/ReducerRegistry'
import { valByKeyWithDot } from '../../../../../utils/Value'
import { getNavDataThunk } from './thunk'

const defaultFilter = {
  startDate: '',
  endDate: '',
  frequency: TIME_FREQUENCY.DAILY,
  page: 1,
  totalPage: 1,
  sortField: '',
  sortOrder: 1,
}

const initialState = {
  filters: defaultFilter,
  reCalcWidth: false,
  loading: false,
  data: [],
  ids: [],
  initialIds: [],
  dataById: {},
}
export const slice = createSlice({
  name: 'fund/fundProfile/navData',
  initialState,
  reducers: {
    changeFilter: (state, action) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      }
    },
    changeReCalcWidth: (state) => {
      state.reCalcWidth = !state.reCalcWidth
    },
    sortTableData: (state, action) => {
      state.ids = getIdsFromProps(
        state.ids,
        state.dataById,
        action.payload,
        state.initialIds,
      )
    },
  },
  extraReducers: (builder) => {
    // Get table data
    builder.addCase(getNavDataThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getNavDataThunk.fulfilled, (state, action) => {
      const data = action.payload.data.map((item, index) => ({
        ...item,
        realDate: item.endDateId,
        id: index,
      }))

      state.data = data
      state.ids = state.initialIds = data.map((e) => e.id)
      state.dataById = keyBy(data, 'id')
      state.filters.totalPage = action.payload.totalPage
      state.loading = false
      state.reCalcWidth = !state.reCalcWidth
    })
    builder.addCase(getNavDataThunk.rejected, (state, action) => {
      state.loading = action.payload.loading
    })
  },
})

export const selectFilters = (state) => state[slice.name].filters
export const selectLoading = (state) => state[slice.name].loading
export const selectIds = (state) => state[slice.name].ids
export const selectDataById = (state) => state[slice.name].dataById
export const selectDataCellById = (id, attr) => (state) =>
  valByKeyWithDot(state[slice.name].dataById[id], attr)
export const selectReCalcWidth = (state) => state[slice.name].reCalcWidth

export const { changeFilter, sortTableData } = slice.actions

register(slice.name, slice.reducer)
