import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loading } from '../../../../common/loading'
import { SizeTracker } from '../../../../common/sizeTracker'
import DispatchActionTab from '../../../../common/tabs/DispatchActionTab'
import { tabConstants } from '../table/constants'
import { ConvertLabel } from '../table/helpers'
import { selectTimeType } from '../table/store/slice'
import { ChartComponent } from './ChartComponent'
import {
  dataTypeConstants,
  fillColor,
  timeTypeOptions,
  timeTypeOptionsYear,
} from './constants'
import { DataType } from './DataType'
import {
  changeDataType,
  changeTimeType,
  changeTimeTypeYear,
  keepListCheckbox,
  selectData,
  selectDataType,
  selectKeysValue,
  selectLoading,
  selectTimeRange,
  selectTimeRangeYear,
} from './store/slice'
import { getConsumptionGrowthByTimeRange } from './store/thunk'

export const GrowthIndexChart = ({ width, height }) => {
  const dispatch = useDispatch()

  const loading = useSelector(selectLoading)
  const data = useSelector(selectData)
  const timeType = useSelector(selectTimeRange)
  const dataType = useSelector(selectDataType)
  const typeDataTime = useSelector(selectTimeType)
  const typeDataYearTime = useSelector(selectTimeRangeYear)
  const keys = useSelector(selectKeysValue)
  const locale = useSelector((state) => state.i18n.locale)

  const {
    leftKeysGrowth,
    leftKeysValue,
    mappingDisplayName,
    mappingDisplayNameGrowth,
  } = keys

  const getData = useMemo(() => {
    return data.map((item) => {
      const formatData = {
        label: ConvertLabel(item.time, typeDataTime, locale),
      }
      if (dataType === dataTypeConstants.VALUE) {
        leftKeysValue.forEach((key) => {
          formatData[key] = item[key]
        })
      }
      if (dataType === dataTypeConstants.GROWTH) {
        leftKeysGrowth.forEach((key) => {
          formatData[key] = item[key] * 100
        })
      }
      return formatData
    })
  }, [timeType, data, dataType, typeDataYearTime])

  const onChangeTimeType = (item) => {
    dispatch(changeTimeType(item.value))
    dispatch(keepListCheckbox(true))
  }

  const onChangeTimeTypeYear = (item) => {
    dispatch(changeTimeTypeYear(item.value))
    dispatch(keepListCheckbox(true))
  }

  const onChangeDataType = (value) => {
    dispatch(changeDataType(value))
    dispatch(keepListCheckbox(false))
  }

  useEffect(() => {
    dispatch(
      getConsumptionGrowthByTimeRange({
        DataType: typeDataTime,
        TimeRange:
          typeDataTime === tabConstants.YEARLY ? typeDataYearTime : timeType,
      }),
    )
  }, [timeType, typeDataTime, typeDataYearTime, locale])

  if (loading) return <Loading />
  return (
    <SizeTracker>
      {(size) => (
        <>
          <div className="mb-8 d-flex j-b align-center">
            <DataType onChange={onChangeDataType} dataType={dataType} />
            {typeDataTime !== tabConstants.YEARLY ? (
              <DispatchActionTab
                data={timeTypeOptions}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={timeType}
                onChangeTab={onChangeTimeType}
              />
            ) : (
              <DispatchActionTab
                data={timeTypeOptionsYear}
                itemStyle={{ padding: '0 12px', fontWeight: 600 }}
                activeTab={typeDataYearTime}
                onChangeTab={onChangeTimeTypeYear}
              />
            )}
          </div>
          {size.height && (
            <div
              id="growthIndex"
              style={{ height: `calc(100% - ${size.height}px)` }}
            >
              <ChartComponent
                data={getData}
                width={width}
                height={height - size.height}
                timeType={typeDataTime}
                timeRange={timeType}
                timeRangeForYear={typeDataYearTime}
                leftKeysValue={
                  dataType === dataTypeConstants.VALUE
                    ? leftKeysValue
                    : leftKeysGrowth
                }
                mappingDisplayName={
                  dataType === dataTypeConstants.VALUE
                    ? mappingDisplayName
                    : mappingDisplayNameGrowth
                }
                fillColor={fillColor}
                dataType={dataType}
              />
            </div>
          )}
        </>
      )}
    </SizeTracker>
  )
}
