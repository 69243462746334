import React from 'react'
import { useSelector } from 'react-redux'
import { selectStockInfoById } from '../../../common/masterData/store/slice'
import { selectTopMoverValue } from '../store/slice'

const CellExchange = ({ val, rowId }) => {
  const organizationId = useSelector(
    selectTopMoverValue(rowId, 'organizationId'),
  )
  const exchangeCode = useSelector(
    selectStockInfoById(organizationId, 'exchangeCode'),
  )

  return <div>{exchangeCode}</div>
}

export default CellExchange
