import { useSelector } from 'react-redux'
import { Bar } from 'recharts'
import { AXIS_LABEL_POSITION } from '../../../../common/chart/constants'
import { FooterContainer } from '../../../../common/chart/footer/FooterContainer'
import { TYPE as FOOTER_TYPE } from '../../../../common/chart/footer/Item'
import { getColumnSizeInBarChart } from '../../../../common/chart/helper'
import { ChartContainer } from '../../../../common/chart/rechart/ChartContainer'
import { SizeTracker } from '../../../../common/sizeTracker'
import { TIME_RANGES } from '../../../../common/tabs/DispatchActionTab'
import { formatVal } from '../../../../utils/Value'
import { selectListTitleChartDetails } from '../table/store/slice'
import { convertArrayMapName } from './helper'

export const RetailServiceChart = ({
  data,
  width,
  height,
  xAxisKey,
  margin,
  leftLabel,
}) => {
  const dataTitle = useSelector(selectListTitleChartDetails)

  const barKeys = dataTitle?.map((item) => item.id.toString())
  const fillColor = dataTitle?.map((item) => item.color)
  const mappingDisplayName = convertArrayMapName(dataTitle)

  const footerData = () => {
    return barKeys.map((key, i) => ({
      text: mappingDisplayName[key],
      type: FOOTER_TYPE.SQUARE,
      color: fillColor[i],
    }))
  }

  const getContent = () => {
    return [
      {
        keys: barKeys.flat(),
        unit: '%',
        yAxisId: 'barChart',
        formatValue: (val) => formatVal(val),
      },
    ]
  }

  const renderBarCharts = (chartContentWidth) => {
    const barCharts = []
    barKeys.forEach((key, index) =>
      barCharts.push(
        <Bar
          key={key}
          yAxisId="barChart"
          dataKey={key}
          stackId="a"
          barSize={getColumnSizeInBarChart(
            chartContentWidth,
            data.length,
            null,
            1,
          )}
          fill={fillColor[index]}
          isAnimationActive={false}
        />,
      ),
    )
    return barCharts
  }

  return (
    <SizeTracker>
      {(size) => (
        <>
          {size.height && (
            <ChartContainer
              data={data}
              width={width}
              height={height - size.height}
              keyXAxis={xAxisKey}
              yAxis={[
                {
                  id: 'barChart',
                  keys: [barKeys],
                  orientation: 'left',
                  yAxisWidth: margin?.left,
                  tickValues: [0, 20, 40, 60, 80, 100],
                  unitYAxis: '%',
                  isStackedBar: true,
                  isGroupBar: true,
                  label: leftLabel,
                  labelPosition: AXIS_LABEL_POSITION.LEFT,
                },
                {
                  id: 'dummyChart',
                  keys: [],
                  orientation: 'right',
                  yAxisWidth: margin?.right,
                },
              ]}
              timeFrame={TIME_RANGES.CUSTOM}
              isNotFormatXAxis
              hasBarChart={barKeys.length > 0}
              tickFormatter={(val) => val}
              tooltipSchema={{
                content: getContent(),
                mappingDisplayName: mappingDisplayName,
              }}
            >
              {({ chartContentWidth }) => renderBarCharts(chartContentWidth)}
            </ChartContainer>
          )}
          <FooterContainer
            key={width}
            numRow={1}
            numItemPerRow={5}
            data={footerData()}
          />
        </>
      )}
    </SizeTracker>
  )
}
