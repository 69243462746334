import { ScrollGroupComponent } from '../../../common/ScrollGroupComponent'
import { SizeTracker } from '../../../common/sizeTracker'
import TopInfo from '../../../common/topInfo/stockTopInfo'
import More from './more'

export const NewsMore = () => {
  return (
    <ScrollGroupComponent>
      <SizeTracker>
        {(size) => {
          return (
            <>
              <TopInfo title="corporate.news.NEWS" />
              {size.height && (
                <div
                  className="w-100 position-relative"
                  style={{ height: `calc(100% - ${size.height}px)` }}
                >
                  <More />
                </div>
              )}
            </>
          )
        }}
      </SizeTracker>
    </ScrollGroupComponent>
  )
}
