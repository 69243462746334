import { createSlice } from '@reduxjs/toolkit'
import { register } from '../../utils/ReducerRegistry'
import {
  getFullSearchData,
  getSearchBondData,
  getSearchCompanyData,
  getSearchCorNewsData,
  getSearchDerivativesData,
  getSearchFunctionData,
  getSearchIndicesData,
  getSearchMarNewsData,
  getSearchSecNewsData,
  getSearchSectorData,
} from './thunk'

const initialData = {
  page: 1,
  pageSize: 5,
  totalRecords: 0,
  totalPage: 1,
  hasNextPage: false,
  hasPreviousPage: false,
  items: [],
}

const initialState = {
  companies: { data: initialData, loading: false },
  sectors: { data: initialData, loading: false },
  functions: { data: initialData, loading: false },
  indices: { data: initialData, loading: false },
  derivatives: { data: initialData, loading: false },
  corNews: { data: initialData, loading: false },
  secNews: { data: initialData, loading: false },
  marNews: { data: initialData, loading: false },
  bonds: { data: initialData, loading: false },
  totalRecords: 0,
}

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Full Search
    builder.addCase(getFullSearchData.pending, (state) => {
      state.companies = { ...state.companies, loading: true }
      state.sectors = { ...state.sectors, loading: true }
      state.functions = { ...state.functions, loading: true }
      state.indices = { ...state.indices, loading: true }
      state.bonds = { ...state.bonds, loading: true }
      state.derivatives = { ...state.derivatives, loading: true }
      state.corNews = { ...state.corNews, loading: true }
      state.secNews = { ...state.secNews, loading: true }
      state.marNews = { ...state.marNews, loading: true }
    })
    builder.addCase(getFullSearchData.fulfilled, (state, action) => {
      state.companies = { loading: false, data: action.payload.companies }
      state.sectors = { loading: false, data: action.payload.sectors }
      state.functions = { loading: false, data: action.payload.functions }
      state.indices = { loading: false, data: action.payload.indices }
      state.bonds = { loading: false, data: action.payload.bonds }
      state.derivatives = { loading: false, data: action.payload.derivatives }
      state.corNews = { loading: false, data: action.payload.corNews }
      state.secNews = { loading: false, data: action.payload.secNews }
      state.marNews = { loading: false, data: action.payload.marNews }
      let totalRecords = 0
      Object.keys(action.payload).forEach(
        (key) => (totalRecords += action.payload[key].totalRecords),
      )
      state.totalRecords = totalRecords
    })
    builder.addCase(getFullSearchData.rejected, (state) => {
      state.companies = { ...state.companies, loading: false }
      state.sectors = { ...state.sectors, loading: false }
      state.functions = { ...state.functions, loading: false }
      state.indices = { ...state.indices, loading: false }
      state.bonds = { ...state.bonds, loading: false }
      state.derivatives = { ...state.derivatives, loading: false }
      state.corNews = { ...state.corNews, loading: false }
      state.secNews = { ...state.secNews, loading: false }
      state.marNews = { ...state.marNews, loading: false }
    })
    // Search Company
    builder.addCase(getSearchCompanyData.pending, (state) => {
      state.companies = { ...state.companies, loading: true }
    })
    builder.addCase(getSearchCompanyData.fulfilled, (state, action) => {
      state.companies = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchCompanyData.rejected, (state) => {
      state.companies = { ...state.companies, loading: false }
    })
    // Search Sector
    builder.addCase(getSearchSectorData.pending, (state) => {
      state.sectors = { ...state.sectors, loading: true }
    })
    builder.addCase(getSearchSectorData.fulfilled, (state, action) => {
      state.sectors = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchSectorData.rejected, (state) => {
      state.sectors = { ...state.sectors, loading: false }
    })
    // Search Function
    builder.addCase(getSearchFunctionData.pending, (state) => {
      state.functions = { ...state.functions, loading: true }
    })
    builder.addCase(getSearchFunctionData.fulfilled, (state, action) => {
      state.functions = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchFunctionData.rejected, (state) => {
      state.functions = { ...state.functions, loading: false }
    })
    // Search Indices
    builder.addCase(getSearchIndicesData.pending, (state) => {
      state.indices = { ...state.indices, loading: true }
    })
    builder.addCase(getSearchIndicesData.fulfilled, (state, action) => {
      state.indices = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchIndicesData.rejected, (state) => {
      state.indices = { ...state.indices, loading: false }
    })
    // Search Bonds
    builder.addCase(getSearchBondData.pending, (state) => {
      state.bonds = { ...state.bonds, loading: true }
    })
    builder.addCase(getSearchBondData.fulfilled, (state, action) => {
      state.bonds = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchBondData.rejected, (state) => {
      state.bonds = { ...state.bonds, loading: false }
    })
    // Search Derivatives
    builder.addCase(getSearchDerivativesData.pending, (state) => {
      state.derivatives = { ...state.derivatives, loading: true }
    })
    builder.addCase(getSearchDerivativesData.fulfilled, (state, action) => {
      state.derivatives = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchDerivativesData.rejected, (state) => {
      state.derivatives = { ...state.derivatives, loading: false }
    })
    // Search Cor News
    builder.addCase(getSearchCorNewsData.pending, (state) => {
      state.corNews = { ...state.corNews, loading: true }
    })
    builder.addCase(getSearchCorNewsData.fulfilled, (state, action) => {
      state.corNews = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchCorNewsData.rejected, (state) => {
      state.corNews = { ...state.corNews, loading: false }
    })
    // Search Sec News
    builder.addCase(getSearchSecNewsData.pending, (state) => {
      state.secNews = { ...state.secNews, loading: true }
    })
    builder.addCase(getSearchSecNewsData.fulfilled, (state, action) => {
      state.secNews = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchSecNewsData.rejected, (state) => {
      state.secNews = { ...state.secNews, loading: false }
    })
    // Search Mar News
    builder.addCase(getSearchMarNewsData.pending, (state) => {
      state.marNews = { ...state.marNews, loading: true }
    })
    builder.addCase(getSearchMarNewsData.fulfilled, (state, action) => {
      state.marNews = { loading: false, data: action.payload }
    })
    builder.addCase(getSearchMarNewsData.rejected, (state) => {
      state.marNews = { ...state.marNews, loading: false }
    })
  },
})

export const selectSearchCompany = (state) => state[slice.name].companies
export const selectSearchSector = (state) => state[slice.name].sectors
export const selectSearchFunction = (state) => state[slice.name].functions
export const selectSearchIndice = (state) => state[slice.name].indices
export const selectSearchBond = (state) => state[slice.name].bonds
export const selectSearchDerivative = (state) => state[slice.name].derivatives
export const selectSearchCorNew = (state) => state[slice.name].corNews
export const selectSearchSecNew = (state) => state[slice.name].secNews
export const selectSearchMarNew = (state) => state[slice.name].marNews
export const selectTotalRecords = (state) => state[slice.name].totalRecords

register(slice.name, slice.reducer)
