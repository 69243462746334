export const SignalrConfig = {
  Hubs: [
    {
      ServerUrl: `${process.env.REACT_APP_MARKET_SIGNALR_ENDPOINT}/RealtimeHub`,
      Prefix: 'Realtime',
      RegisteredRemoteChannels: [],
    },
  ],
}

export const BondSignalrConfig = {
  Hubs: [
    {
      ServerUrl: `${process.env.REACT_APP_BOND_SIGNALR_ENDPOINT}/RealtimeHub`,
      Prefix: 'Realtime',
      RegisteredRemoteChannels: [],
    },
  ],
}
